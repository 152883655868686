<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="actenckardex"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="actenckardexConf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 100%">
	<div class="p-col-12">
        <app-invmaebarra [botonNuevo]="actenckardexService.btnNuevo[indicador]" 
        [botonGuardar]="actenckardexService.btnGuardar[indicador]" [botonRegresar]="btnRegresar"
        [botonBorrar]="actenckardexService.btnBorrar[indicador]" (signal)="manejarSenales($event)" [botonAnterior]="botonAnterior"
        [botonPrimero]="botonPrimero" [botonUltimo]="botonUltimo" [botonSiguiente]="botonSiguiente"
        [barraBotones3]="true" [botonBuscar]="btnBuscar" [botonBinocular]="true"
        [botonAsiento]="false" [botonverAsiento]="false" [botonAlmacenardoc]="true">
        </app-invmaebarra>
	</div>
</div>
<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px; width: 100%;">
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-8"></div>
			<div class="p-col-2">
                <input type="text" pInputText
					   style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
					   disabled
					   [(ngModel)]="lblAsiNro">
			</div>
			<div class="p-col-1"></div>
			<div class="p-col-1">
				<input type="text" pInputText
					   style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
					   disabled
					   [(ngModel)]="lblUsuario">
			</div>
		</div>
	</div>
</div>

<p-tabView [style]="{'width':'100%'}" >
    <p-tabPanel header="Kardex">
		<div class="divgrups" id="divPrincipal">
			<div class="p-grid">
				<div class="p-col-1">
					<span>Número:</span>
				</div>
				<div class="p-col-1">
					<input id="numero" type="text" pInputText autocomplete="off"
						   [(ngModel)]="txtNume" disabled
						   (keydown.enter)="cambiarFoco($event)">
				</div>
				<div class="p-col-1"></div>
				<div class="p-col-1">
					<span>Fecha:</span>
				</div>
				<div class="p-col-1" style="text-align: left">
					<p-calendar id="fecha"  [inputStyle]="{'width': '100px'}"
								dateFormat="dd/mm/yy" [(ngModel)]="dateFecha"  
								[readonlyInput]="true">
					</p-calendar>
				</div>
				<div class="p-col-1"></div>
				<div class="p-col-1">
					<span>#Comprobante:</span>
				</div>
				<div class="p-col-1">
					<input id="comprobante" type="text" pInputText autocomplete="off"
						   [(ngModel)]="txtNumComp" 
						   (keydown.enter)="cambiarFoco($event)">
				</div>
				<div class="p-col-1"></div>
				<div class="p-col-1">
					<span>Origen:</span>
				</div>
				<div class="p-col-1">
					<input id="origen" type="text" pInputText autocomplete="off"
						   [(ngModel)]="txtOrig" disabled
						   (keydown.enter)="cambiarFoco($event)">
				</div>
				<!--LINE 2-->
				<div class="p-col-12" style="margin-top: -5px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Comentario:</span>
						</div>
						<div class="p-col-7" style="text-align: left">
							<input id="comentario" type="text" pInputText autocomplete="off"
								   [(ngModel)]="txtCome1" (keydown.enter)="cambiarFoco($event)"
								   (input)="cambio($event)">
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-1">
							<button (click)="aceptar()" pButton class="aceptar"
									style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">
								Aceptar
							</button>
						</div>
					</div>
				</div>
				<!--LINE 3-->
				<div class="p-col-12" style="margin-top: -10px;">
					<div class="p-grid">
						<div class="p-col-1">	
						</div>
						<div class="p-col-7" style="text-align: left">
							<input id="comentario2" type="text" pInputText autocomplete="off"
								   [(ngModel)]="txtCome2" (keydown.enter)="cambiarFoco($event)"
								   (input)="cambio($event)">
						</div>
					</div>
				</div>
				<!--LINE 3-->
				<div class="p-col-12" style="margin-top: -10px; margin-bottom: -15px;">
					<div class="p-grid">
						<div class="p-col-10">	
						</div>
						<div class="p-col-2">
							<p-checkbox label="Selecionar todo" binary="true"
									[(ngModel)]="btnSeleccionar"
									(onChange)="selectAll($event)">
						</p-checkbox>
						</div>
					</div>
				</div>
			</div>
		</div>
		<app-aggridsaci [width]=""
						[height]="largo"
						
						[enableFilter]="true"
						[rowData]="actenckardexService.detKardexArray[indicador] "
						[frameworkComponents]="frameworkComponents"
						[animateRows]="true"
						[rowSelection]="rowSelection"
						[columnDefs]="columnDefsDetKardex"
						[defaultColDef]="defaultActDetKardex"
						[rowStyle]="rowStyle"
						[singleClickEdit]="false"
						[botones]="false"
						[mostrarGuardar]="false"
						(selected)="seleccionDetKardex($event)"
						(cambios)="cambio($event)"
		></app-aggridsaci>
		<div class="divgrupstabla">
			<!--				<div class="p-col-12">-->
			<div class="p-grid">
				<div class="p-col-1">
					<span class="obligatorio">Activo: </span>
				</div>
				<div class="p-col-5">
					<input class="totales" type="text" pInputText [disabled]="true" style="text-align: left;"
						   [(ngModel)]="txtActPie">
				</div>
				<div class="p-col-2">
					<span class="obligatorio">Depreciaciòn Acum. Ini.: </span>
				</div>
				<div class="p-col-1">
					<input class="totales" type="text" pInputText [disabled]="true"
						   [(ngModel)]="txtDepIniAct">
				</div>
				<div class="p-col-2">
					<span class="obligatorio">Depreciaciòn Acum. Kardex.:</span>
				</div>
				<div class="p-col-1">
					<input class="totales" type="text" pInputText [disabled]="true"
						   [(ngModel)]="txtDepAcumKard">
				</div>
			</div>
			<!--				</div>-->
		</div>
    </p-tabPanel>
</p-tabView>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<div style=" 	position: fixed;
				z-index: 999;
				height: 2em;
				width: 2em;
				overflow: visible;
				margin: auto;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;

				">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
</div>
<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width:'80vw', height:'120vh'}"
		  [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="lblAsiNro" [abiertoDesde]="'actenckardex'"
					(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'ACTENCKARDEX'"
		[txtNumComprobante]="txtNume"
		(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>