import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { Cxctrnresumen } from '../cxcinterfaces/cxctrnresumen';
import { Cxctrncobro } from '../cxcinterfaces/cxctrncobro';
import { Cxctrnretencion } from '../cxcinterfaces/cxctrnretencion';
import { Cxctrnotrosg } from '../cxcinterfaces/cxctrnotrosg';
import { Cxctrnformapago } from '../cxcinterfaces/cxctrnformapago';
import { Cxctrnvarios } from '../cxcinterfaces/cxctrnvarios';
import { Cxctrndebitobancario } from '../cxcinterfaces/cxctrndebitobancario';
import { Cxctrnanticipos } from '../cxcinterfaces/cxctrnanticipos';
import { Cxctrnformapagodev } from '../cxcinterfaces/cxctrnformapagodev';
import { Cxctrnanexos } from '../cxcinterfaces/cxctrnanexos';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CxctrnabonoService {
  public way: string;

  public encabezadoCarteraActivaarray: Cxctrncobro[] = [];
  public tipoDocumentoarray: string[] = [];
  public cxctrnresumenarray: Cxctrnresumen[][] = [];
  public cxctrnfaccanceladasarray: Cxctrnresumen[][] = [];
  public cxctrnretencionarray: Cxctrnretencion[][] = [];
  public cxctrnotrosgarray: Cxctrnotrosg[][] = [];
  public cxctrnformapagoarray: Cxctrnformapago[][] = [];
  public cxctrnformapagodevarray: Cxctrnformapagodev[][] = [];
  public cxctrnvariosarray: Cxctrnvarios[][] = [];
  public cxcdebitobancarioarray: Cxctrndebitobancario[][] = [];
  public cxctrnanticiposarray: Cxctrnanticipos[][] = [];
  public dblTotalDocarray: number[] = [];
  public dblTotalRetarray: number[] = [];
  public dblTotalOtrosGarray: number[] = [];
  public dblTotalFormaPagoarray: number[] = [];
  public dblTotalFormaDevarray: number[] = [];
  public dblTotalVariosarray: number[] = [];
  public dblTotalNDarray: number[] = [];
  public dblTotalNCarray: number[] = [];
  public dblTotalCobrararray: number[] = [];


  public decimalesCantidadDevF: number;
  public decimalesPrecioDevF: number;
  public decimalesTotalDevF: number;
  public dblPorcIvaDevF = 12;
  constructor(private http: HttpClient, public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario, private confIniciales: ConfInicialesService,
    private message: MessageService, private init: NuevoComponentService,
    private utilitariosService: UtilitariosService, private datePipe: DatePipe) {
    this.way = sessionStorage.getItem('way');
  }
  calcularTotalDoc() {
    this.dblTotalDocarray[this.init.tabs[this.init.tabindex].indice] = 0;
    if (this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TRNCOBRO_TIPODOC !== 'NC') {
      if (this.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice].length !== 0) {
        for (let fila = 1; fila <= this.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
          const item = this.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice][fila - 1];
          if (item.SELECCIONADO === true && this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TRNCOBRO_NRODOC === '') {
            if (Number(item.TRNCOBRO_IMPORTE) > Number(item.TRNCOBRO_SALDOINI)) {
              this.message.add({
                key: 'cxctrnabono',
                severity: 'error',
                summary: 'Información',
                detail: 'El importe no puede ser mayor que el saldo'
              });
              item.TRNCOBRO_IMPORTE = item.TRNCOBRO_SALDOINI;
              item.TRNCOBRO_IMPORTE = Number(item.TRNCOBRO_IMPORTE).toFixed(2);
            } else {
              item.TRNCOBRO_IMPORTE = Number(item.TRNCOBRO_IMPORTE).toFixed(2);
            }
          } else if (item.SELECCIONADO === false) {
            item.TRNCOBRO_IMPORTE = 0;
          }
          this.dblTotalDocarray[this.init.tabs[this.init.tabindex].indice] += Number(item.TRNCOBRO_IMPORTE);
          this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALDOC = this.dblTotalDocarray[this.init.tabs[this.init.tabindex].indice].toFixed(2);
          this.calcularTotalCobrar();
        }
      } else {
        if (this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].SALDOANC === undefined) {
          this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].SALDOANC = 0;
        }
        this.dblTotalDocarray[this.init.tabs[this.init.tabindex].indice] += this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].SALDOANC;
        this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALDOC = Number(this.dblTotalDocarray[this.init.tabs[this.init.tabindex].indice]).toFixed(2);
        this.calcularTotalCobrar();
      }
    } else {
      this.calcularTotalNC();
    }
  }
  calcularTotalCobrar() {
    this.dblTotalCobrararray[this.init.tabs[this.init.tabindex].indice] = 0;
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALACOBRAR = 0;
    this.dblTotalCobrararray[this.init.tabs[this.init.tabindex].indice] = Number(this.dblTotalDocarray[this.init.tabs[this.init.tabindex].indice]) - Number(this.dblTotalNCarray[this.init.tabs[this.init.tabindex].indice]) - Number(this.dblTotalOtrosGarray[this.init.tabs[this.init.tabindex].indice]) - Number(this.dblTotalRetarray[this.init.tabs[this.init.tabindex].indice]);
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALACOBRAR = this.dblTotalCobrararray[this.init.tabs[this.init.tabindex].indice].toFixed(2);
  }
  calcularTotalNC() {
    this.dblTotalNCarray[this.init.tabs[this.init.tabindex].indice] = 0;
    for (let fila = 1; fila <= this.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      const item = this.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice][fila - 1];
      if (item.SELECCIONADO === true) {
        this.dblTotalNCarray[this.init.tabs[this.init.tabindex].indice] += Number(item.TRNCOBRO_IMPORTE);
      } else {
        item.TRNCOBRO_IMPORTE = 0;
      }
    }
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALNC = this.dblTotalNCarray[this.init.tabs[this.init.tabindex].indice].toFixed(2);
  }
  calcularTotalRetenido(intFilaActual) {
    let dblTotalFila = 0;
    let dblBase = 0;
    let dblPorcentaje = 0;
    this.dblTotalRetarray[this.init.tabs[this.init.tabindex].indice] = 0;
    // let dblTotalRetFac = 0;
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALRET = 0;
    console.log(this.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice]);
    for (let fila = 1; fila <= this.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      const itemR = this.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1];
      if (itemR.TRNRETENCION_NRO !== '') {
        if (itemR.TRNRETENCION_NRO.length !== 0) {
          if (itemR.TRNRETENCION_NRO.length < 9 && itemR.TRNRETENCION_NRO.length !== 0) {
            do {
              itemR.TRNRETENCION_NRO = '0' + itemR.TRNRETENCION_NRO;
            } while (itemR.TRNRETENCION_NRO.length < 9);
          } else if (itemR.TRNRETENCION_NRO.length < 9 && itemR.TRNRETENCION_NRO.length === 0) {
            itemR.TRNRETENCION_NRO = '1';
            do {
              itemR.TRNRETENCION_NRO = '0' + itemR.TRNRETENCION_NRO;
            } while (itemR.TRNRETENCION_NRO.length < 9);
          }
        }
      }
      dblBase = Number(itemR.TRNRETENCION_BASE);
      dblPorcentaje = Number(itemR.TRNRETENCION_PORCENTAJE);
      //dblTotalFila = dblBase * dblPorcentaje / 100;
       //CDPJ
       dblTotalFila = dblBase * (dblPorcentaje/100);
       //CDPJ
       console.log('ret*/*/*/',dblTotalFila)//GSRF
       console.log('ret*/*/*/',this.redondearDecimales(dblTotalFila) )//GSRF
      itemR.TRNRETENCION_BASE = Number(itemR.TRNRETENCION_BASE).toFixed(2);
      itemR.TRNRETENCION_TOTALRETENIDO = this.redondearDecimales(dblTotalFila);//GSRF
      this.dblTotalRetarray[this.init.tabs[this.init.tabindex].indice] += Number(itemR.TRNRETENCION_TOTALRETENIDO);
    }
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALRET = this.dblTotalRetarray[this.init.tabs[this.init.tabindex].indice].toFixed(2);
    this.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice].map((resu) => {
      if (this.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice].length !== 0) {
        if (resu.TRNCOBRO_NRODOC === this.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][0].ENCFAC_NUMERO && this.tipoDocumentoarray[this.init.tabs[this.init.tabindex].indice] === 'RT') {
          resu.TRNCOBRO_IMPORTE = this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALRET;
        }
      }
    });
    this.calcularTotalDoc();
    /*for (const fila = 1; fila <= listaRetFact.size(); fila++ )
    {
      SaciTrnRetencion itemR = listaRetFact.get(fila-1);
    
      dblTotalRetFac= dblTotalRetFac + itemR.getTrnretencionTotalretenido();
    }
  
    dlgCxC.lblTotalRetFac.setText( Validations.numberValidation("" + dblTotalRetFac, 15, dlgCxC.NUMDECRET,
      true, false) );

//		SI ES UNA RETENCION COLOCA EL VALOR TOTAL DE LA RETENCION EN EL IMPORTE DEL DOCUMENTO
    if (this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TRNCOBRO_TIPODOC === 'RT') {
      int filaSel = dlgCxC.getTablaDoc().getCellSelection()[0].y;
      if (dlgCxC.modeloDoc.listaDocPorCobrar.size()>0)
      {
        CxcTrnCobro item = dlgCxC.modeloDoc.listaDocPorCobrar.get(filaSel-1);
      
        item.setTrnCobroImporte(Double.parseDouble(dlgCxC.lblTotalRetFac.getText().replace(",", "")));
      
        dlgCxC.modeloDoc.calcularTotalDoc();
      }
    }*/
    this.calcularTotalCobrar();
  }
  redondearDecimales(num) {
    let val=0
    val=(Math.round((num + Number.EPSILON) *100)/100);
    val=Number(Number(val).toFixed(2));
    return val;
  }
  calcularTotalOtrosG() {
    this.dblTotalOtrosGarray[this.init.tabs[this.init.tabindex].indice] = 0;
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALOTROSG = 0;
    for (let fila = 1; fila <= this.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      const item = this.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice][fila - 1];
      this.dblTotalOtrosGarray[this.init.tabs[this.init.tabindex].indice] += Number(item.TRNCOMISION_TOTALRETENIDO);
      item.TRNCOMISION_TOTALRETENIDO = Number(item.TRNCOMISION_TOTALRETENIDO).toFixed(2);
    }
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALOTROSG = this.dblTotalOtrosGarray[this.init.tabs[this.init.tabindex].indice].toFixed(2);
    this.calcularTotalCobrar();
  }
  calcularTotalFormaPago() {
    this.dblTotalFormaPagoarray[this.init.tabs[this.init.tabindex].indice] = 0;
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALFORMAPAGO = 0;
    for (let fila = 1; fila <= this.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      if (this.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNPAGO_TIPO.length > 0) {
        this.dblTotalFormaPagoarray[this.init.tabs[this.init.tabindex].indice] += Number(this.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNPAGO_IMPORTE);
        this.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNPAGO_IMPORTE = Number(this.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNPAGO_IMPORTE).toFixed(2);
      }
    }
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALFORMAPAGO = Number(this.dblTotalFormaPagoarray[this.init.tabs[this.init.tabindex].indice]).toFixed(2);
  }
  async encontrarNumDoc(strNumComp) {
    let strNumDoc = '';
    try {
      const rs = await this.erTrncobronrodocProm(strNumComp);
      strNumDoc = rs[0].TRNCOBRO_NRODOC === null ? '' : rs[0].TRNCOBRO_NRODOC;
    } catch (err) {

      this.message.add({
        key: 'cxctrnabono',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede encontrar el número de documento ' + err.error
      });
    }

    return strNumDoc;
  }
  async obtenerSaldoFactura(strNumFact) {

    let strSql = 'SELECT VEN_FNC_SALDOFACTURA(\'01\', \'' + strNumFact + '\') as SALDO FROM DUAL';
    let dblSaldo = 0;

    try {
      const res = await this.obtenerSaldoANC(strNumFact);
      for (const rs of res) {
        dblSaldo = rs.SALDO;
      }
    } catch (err) {
      this.message.add({
        key: 'cxctrnabono',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede obtener el saldo de la factura ' + err.error
      });
    }
    return dblSaldo;
  }
  async obtenerCuentaCaja(strCaja) {
    let strCuenta = '';

    try {
      const rs = await this.erConcodigoProm(strCaja);
      console.log(rs);
      strCuenta = rs === null ? '' : (rs[0].CON_CODIGO === null ? '' : rs[0].CON_CODIGO);
    } catch (err) {
      this.message.add({
        key: 'cxctrnabono',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede consultar la cuenta contable de la caja ' + err.error
      });
    }
    return strCuenta;
  }
  async obtenerCodSRI(strTipoPago) {
    let strCodSri = '';

    try {
      const er = await this.erFormapagocodsriProm(strTipoPago);
      if (er !== null) {
        if (er[0] !== undefined) {
          strCodSri = er[0].FORMAPAGO_CODIGOSRI === null ? '' : er[0].FORMAPAGO_CODIGOSRI;
        }
      }
    } catch (error) {
      this.message.add({
        key: 'cxctrnabono',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede obtener el código SRI'
      });
    }
    return strCodSri;
  }

  async obtenerCaja(strTipoPago) {
    let strCaja = '';

    try {
      const rs = await this.erCajcodigoProm(strTipoPago);
      console.log(rs);
      strCaja = rs === null ? '' : (rs[0].CAJ_CODIGO === null ? '' : rs[0].CAJ_CODIGO);
    }
    catch (err) {
      this.message.add({
        key: 'cxctrnabono',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede obtener la caja ' + err.error
      });
    }
    return strCaja;
  }
  async controlarChequesPosf(item) {
    let bolControl = false;
    let lngDias = 0;
    let intDiasChequeP = await this.obtenerDiasChPosf(item.CLI_CODIGO);
    const dateActual = new Date();
    let dateHasta: any;

    try {

      const lngDesde = new Date(dateActual.getFullYear(), dateActual.getUTCMonth(), dateActual.getDate()).getTime();

      const arrFechaHasta = String(item.TRNPAGO_FECHA).split('/');
      dateHasta = new Date(Number(arrFechaHasta[2]), Number(arrFechaHasta[1]) - 1, Number(arrFechaHasta[0]));

      const lngHasta = new Date(Number(arrFechaHasta[2]), Number(arrFechaHasta[1]) - 1, Number(arrFechaHasta[0])).getTime();

      const dblDiferencia = lngHasta - lngDesde;
      lngDias = Math.round((dblDiferencia / (1000 * 60 * 60 * 24)));

    } catch (err) {
      this.message.add({
        key: 'cxctrnabono',
        severity: 'error',
        summary: 'Forma de Pago',
        detail: err.error
      });
    }

    if (lngDias > intDiasChequeP) {
      this.message.add({
        key: 'cxctrnabono',
        severity: 'info',
        summary: 'Información',
        detail: 'El Cliente tiene ' + intDiasChequeP + ' día(s) para los cheques posfechados'
      });

      return this.datePipe.transform(dateActual, 'dd/MM/yyyy');
    } else {
      return this.datePipe.transform(dateHasta, 'dd/MM/yyyy');
    }
  }
  async obtenerDiasChPosf(strCodCliente) {
    let intDias = 0;
    let strDias = '';
    try {
      const rs = await this.erClidiachposProm(strCodCliente);

      if (rs !== null) {
        strDias = rs[0].CLI_DIACHPOS;
      }

      if (String(strDias).length > 0) {
        intDias = Number(strDias);
      }

    }
    catch (err) {
      this.message.add({
        key: 'cxctrnabono',
        severity: 'error',
        summary: 'Forma de Pago',
        detail: 'No se puede obtener el número de días de los cheques posfechados ' + err.error
      });
    }
    console.log(intDias);
    return intDias;
  }
  async obtenerNombreBanco(strCodBanco) {
    let strNombre = '';

    try {
      const rs = this.erBanclinombreProm(strCodBanco);
      console.log(rs);
      strNombre = rs[0].BANCLI_NOMBRE === null ? '' : rs[0].BANCLI_NOMBRE;
    } catch (err) {
      this.message.add({
        key: 'cxctrnabono',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede consultar el nombre del banco ' + err.error
      });
    }
    return strNombre;
  }
  insertarCobroP(cobro: Cxctrncobro, tipoDoc): Promise<any[]> {
    console.log(cobro);
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/95f8edf54cc2f80', {
      elementos: {
        p_TRNCOBRO_nroDoc: cobro.TRNCOBRO_NRODOC,
        p_TRNCOBRO_nroComprobante: cobro.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_tipoDoc: tipoDoc,
        p_TRNCOBRO_fechaTrn: cobro.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_concepto: cobro.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_importe: Number(cobro.TRNCOBRO_IMPORTE),
        p_TRNCOBRO_fechavence: cobro.TRNCOBRO_FECHAVENCE,
        p_APLORG_codigo: cobro.APLORG_CODIGO,
        p_TRNCOBRO_referenciaExterna: cobro.TRNCOBRO_REFERENCIAEXTERNA,
        p_CLI_codigo: cobro.CLI_CODIGO,
        p_VEN_codigo: cobro.VEN_CODIGO,
        p_COM_codigo: '01',
        p_ASI_NRO: cobro.ASI_NRO,
        p_USU_IDENTIFICACION: cobro.USU_IDENTIFICACION,
        p_TRNCOBRO_FLAG: Number(cobro.TRNCOBRO_FLAG),
        p_BAN_CODIGO: cobro.BAN_CODIGO,
        p_TRNCOBRO_EFECTIVO: Number(cobro.TRNCOBRO_EFECTIVO),
        p_TRNCOBRO_CAMBIO: Number(cobro.TRNCOBRO_CAMBIO),
        p_CON_CODIGO: cobro.CON_CODIGO,
        p_TRNCOBRO_SALDOINI: Number(cobro.TRNCOBRO_SALDOINI),
        p_BOD_CODIGO: cobro.BOD_CODIGO,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerSaldoClienteP(cliCodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/617de2aadc32b0', {
      elementos: {
        CLI_CODIGO: cliCodigo,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  insertarFormadePagoProm(pago: Cxctrnformapago, nroDoc, nroComp): Promise<any[]> {
    if (pago.CAJ_CODIGO === '' || pago.CAJ_CODIGO === null) {
      pago.CAJ_CODIGO = pago.BANCLI_CODIGO;
    }
    console.log('NumComprobante' + nroComp);
    console.log(pago);
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/12bf1db7e376cb000', {
      elementos: {
        p_TRNPAGO_tipo_2: pago.TRNPAGO_TIPO,
        p_CAJ_codigo_3: pago.CAJ_CODIGO,
        p_TRNPAGO_numero_4: pago.TRNPAGO_NUMERO,
        p_TRNPAGO_fecha_5: pago.TRNPAGO_FECHA,
        p_BANCLI_CODIGO: pago.BANCLI_CODIGO,
        p_TRNPAGO_NROCTA: pago.TRNPAGO_NROCTA,
        p_TRNPAGO_concepto_6: pago.TRNPAGO_CONCEPTO,
        p_TRNPAGO_importe_7: Number(pago.TRNPAGO_IMPORTE),
        p_TRNPAGO_numero_anterior: '',
        p_CON_codigo_9: pago.CON_CODIGO,
        p_TRNCOBRO_nroDoc_1: nroDoc,
        p_COM_codigo_8: '01',
        p_TRNCOBRO_nroComprobante: nroComp,
        p_CLI_CODIGO: pago.CLI_CODIGO,
        p_TRNPAGO_CODSRI: pago.TRNPAGO_CODSRI,
        p_TRNCHEPOS_NUMDOC: pago.TRNCHEPOS_NUMDOC,
        p_TRNPAGO_TIPDIS: pago.TRNPAGO_TIPDIS
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  generarAsientoANCProm(cobro: Cxctrncobro) {
    console.log(cobro);
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/41d75724ebaaec', {
      elementos: {
        p_trncobro_nrodoc: cobro.TRNCOBRO_NRODOC,
        p_cli_codigo: cobro.CLI_CODIGO,
        p_com_codigo: '01',
        p_origen: cobro.APLORG_CODIGO,
        p_tipdia_codigo: 'CI',
        p_usuario: this.usuario.identificacion,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getAsientoProm(codigo: string) {
    return this.http.post<any>(this.way + '/cxc/carteraactiva/617d5724cc5fa8', {
      elementos: {
        TRNCOBRO_NRODOC: codigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarNumeroComprobante(strAsiNro, strNroDoc) {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/533bebfaccc5b40', {
      elementos: {
        ASI_NRO: strAsiNro,
        NRODOC: strNroDoc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  insertarDocPorCobrarP(cobro: Cxctrnresumen, nroDoc, nroComp): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/12bf1b8665985f000', {
      elementos: {
        p_TRNCOBRO_nroDoc_1: nroDoc,
        p_TRNDOCUMENTO_nroDoc_2: cobro.TRNCOBRO_NRODOC,
        p_TRNDOCUMENTO_importe_3: Number(cobro.TRNCOBRO_IMPORTE),
        p_COM_codigo_4: '01',
        p_CLI_codigo_5: cobro.CLI_CODIGO,
        p_TRNDOCUMENTO_REF: cobro.STRCUOTAFAC,
        p_TRNCOBRO_NROCOMPROBANTE: nroComp,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  insertarRetencionP(retencion: Cxctrnretencion, nroDoc, nroComp): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/4afceeefb775d800000', {
      elementos: {
        p_retencion_codigo_1: retencion.RETENCION_CODIGO,
        p_trnretencion_descripcion_4: retencion.TRNRETENCION_DESCRIPCION,
        p_trnretencion_nro_1: retencion.TRNRETENCION_NRO,
        p_trnretencion_base_5: Number(retencion.TRNRETENCION_BASE),
        p_trnretencion_porcentaje_6: Number(retencion.TRNRETENCION_PORCENTAJE),
        p_trnretencion_totalretenido_7: Number(retencion.TRNRETENCION_TOTALRETENIDO),
        p_trncobro_nrodoc_8: nroDoc,
        p_com_codigo_3: '01',
        p_trnretencion_origen: retencion.TRNRETENCION_ORIGEN,
        p_con_codigo: retencion.CON_CODIGO,
        p_cen_codigo: retencion.CEN_CODIGO,
        p_cli_codigo: retencion.CLI_CODIGO,
        p_ENCFAC_NUMERO: retencion.ENCFAC_NUMERO,
        p_TRNCOBRO_NROCOMPROBANTE: nroComp,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarTipoRetencionProm(retencion, tipo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/533bd9671b775c000000', {
      elementos: {
        trnretencion_tiporet: tipo,
        trnretencion_referencia: retencion.ENCFAC_NUMERO,
        cli_codigo: retencion.CLI_CODIGO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  insertarComisionProm(comision: Cxctrnotrosg, nroDoc, nroComp): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/257e3b7d662d2e0000', {
      elementos: {
        p_COMISION_codigo_1: comision.COMISION_CODIGO,
        p_TRNCOMISION_descripcion_4: comision.TRNCOMISION_DESCRIPCION,
        p_TRNCOMISION_nro_1: comision.TRNCOMISION_NRO,
        p_TRNCOMISION_base_5: 0,
        p_TRNCOMISION_porcentaje_6: 0,
        p_TRNCOMISION_totalretenido_7: Number(comision.TRNCOMISION_TOTALRETENIDO),
        p_TRNCOBRO_nroDoc_8: nroDoc,
        p_COM_codigo_3: '01',
        p_trncomision_origen: comision.TRNCOMISION_ORIGEN,
        p_con_codigo: comision.CON_CODIGO,
        p_TRNCOBRO_NROCOMPROBANTE: nroComp,
        p_CLI_CODIGO: comision.CLI_CODIGO,
        p_trncomision_flag: comision.TRNCOMISION_FLAG
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eliminarFormaPagoProm(numdoc) {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/756567e37656540', {
      elementos: {
        TRNCOBRO_NRODOC: numdoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarNotaDebitoAnticipoProm(strNumDoc, strNumAnticipo) {

    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/14cefaf8ea9ae60000', {
      elementos: {
        NumAnticipo: strNumAnticipo,
        NumDoc: strNumDoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarFormaPagoNDProm(strNumND, strNumPago, strNumDoc) {

    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/14cef9f8ddb2a80000', {
      elementos: {
        NumND: strNumND,
        NumDoc: strNumDoc,
        NumPago: strNumPago
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  generarAsientoCIProm(cobro: Cxctrncobro) {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/41d75724ebf590', {
      elementos: {
        p_trncobro_nrodoc: cobro.TRNCOBRO_NRODOC,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'CI',
        p_asi_comprobante: cobro.TRNCOBRO_NROCOMPROBANTE.toString().trim(),
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarNumeracion(serie): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/14cefafeb3b6a60000', {
      elementos: {
        p_NUM_clase: '',
        p_COM_codigo: '01',
        p_NUM_docref: 'CI',
        p_NUM_nemonico: '',
        p_NUM_serie: serie
      },
    }, this.confIniciales.httpOptions());
  }
  actualizarCobro(cobro: Cxctrncobro, tipoDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/14cef94cc2f78', {
      elementos: {
        p_TRNCOBRO_nroDoc: cobro.TRNCOBRO_NRODOC,
        p_TRNCOBRO_nroComprobante: cobro.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_tipoDoc: tipoDoc,
        p_TRNCOBRO_fechaTrn: cobro.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_concepto: cobro.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_importe: Number(cobro.TRNCOBRO_IMPORTE),
        p_TRNCOBRO_fechavence: cobro.TRNCOBRO_FECHAVENCE,
        p_APLORG_codigo: cobro.APLORG_CODIGO,
        p_TRNCOBRO_referenciaExterna: cobro.TRNCOBRO_REFERENCIAEXTERNA,
        p_CLI_codigo: cobro.CLI_CODIGO,
        p_VEN_codigo: cobro.VEN_CODIGO,
        p_COM_codigo: '01',
        p_ASI_NRO: cobro.ASI_NRO,
        p_USU_IDENTIFICACION: cobro.USU_IDENTIFICACION,
        p_TRNCOBRO_FLAG: Number(cobro.TRNCOBRO_FLAG),
        p_BAN_CODIGO: cobro.BAN_CODIGO,
        p_TRNCOBRO_EFECTIVO: Number(cobro.TRNCOBRO_EFECTIVO),
        p_TRNCOBRO_CAMBIO: Number(cobro.TRNCOBRO_CAMBIO),
        p_CON_CODIGO: cobro.CON_CODIGO,
        p_TRNCOBRO_SALDOINI: Number(cobro.TRNCOBRO_SALDOINI),
        p_BOD_CODIGO: cobro.BOD_CODIGO,
      },
    }, this.confIniciales.httpOptions());
  }
  
  actualizarestadoFacProm(doc) {
    console.log(doc);
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/299df73ad7a99c', {
      elementos: {
        p_com_codigo: '01',
        p_cli_codigo: doc.CLI_CODIGO,
        p_encfac_numero: doc.TRNCOBRO_NRODOC,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getcaja(caj_codigo: string){
    return this.http.post<any[]>(this.way + '/nom/transotregresos/u8974dsle3zx4hr', {
        elementos: {
          Caj_codigo: caj_codigo
        },
      },this.confIniciales.httpOptions()).toPromise();
  }

  eliminarTrnDocumento(strCondicion): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/75656eeeedc33c0', {
      elementos: {
        condicion: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }
  insertarDocPorCobrar(cobro: Cxctrnresumen, nroDoc, nroComp): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/12bf1b8665985f000', {
      elementos: {
        p_TRNCOBRO_nroDoc_1: nroDoc,
        p_TRNDOCUMENTO_nroDoc_2: cobro.TRNCOBRO_NRODOC,
        p_TRNDOCUMENTO_importe_3: Number(cobro.TRNCOBRO_IMPORTE),
        p_COM_codigo_4: '01',
        p_CLI_codigo_5: cobro.CLI_CODIGO,
        p_TRNDOCUMENTO_REF: cobro.STRCUOTAFAC,
        p_TRNCOBRO_NROCOMPROBANTE: nroComp,
      },
    }, this.confIniciales.httpOptions());
  }
  eliminarTrnRetencion(strCondicion): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/75656eeefb775c0', {
      elementos: {
        condicion: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }
  insertarRetencion(retencion: Cxctrnretencion, nroDoc, nroComp): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/4afceeefb775d800000', {
      elementos: {
        p_retencion_codigo_1: retencion.RETENCION_CODIGO,
        p_trnretencion_descripcion_4: retencion.TRNRETENCION_DESCRIPCION,
        p_trnretencion_nro_1: retencion.TRNRETENCION_NRO,
        p_trnretencion_base_5: Number(retencion.TRNRETENCION_BASE),
        p_trnretencion_porcentaje_6: Number(retencion.TRNRETENCION_PORCENTAJE),
        p_trnretencion_totalretenido_7: Number(retencion.TRNRETENCION_TOTALRETENIDO),
        p_trncobro_nrodoc_8: nroDoc,
        p_com_codigo_3: '01',
        p_trnretencion_origen: retencion.TRNRETENCION_ORIGEN,
        p_con_codigo: retencion.CON_CODIGO,
        p_cen_codigo: retencion.CEN_CODIGO,
        p_cli_codigo: retencion.CLI_CODIGO,
        p_ENCFAC_NUMERO: retencion.ENCFAC_NUMERO,
        p_TRNCOBRO_NROCOMPROBANTE: nroComp,
      },
    }, this.confIniciales.httpOptions());
  }
  actualizarTipoRetencion(retencion, tipo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/533bd9671b775c000000', {
      elementos: {
        trnretencion_tiporet: tipo,
        trnretencion_referencia: retencion.ENCFAC_NUMERO,
        cli_codigo: retencion.CLI_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }
  eliminarTrnComision(numDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/75656eeeecc5a40', {
      elementos: {
        p_TRNCOBRO_nroDoc_4: numDoc,
        p_COM_codigo_3: '01',
      },
    }, this.confIniciales.httpOptions());
  }
  insertarComision(comision: Cxctrnotrosg, nroDoc, nroComp): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/257e3b7d662d2e0000', {
      elementos: {
        p_COMISION_codigo_1: comision.COMISION_CODIGO,
        p_TRNCOMISION_descripcion_4: comision.TRNCOMISION_DESCRIPCION,
        p_TRNCOMISION_nro_1: comision.TRNCOMISION_NRO,
        p_TRNCOMISION_base_5: 0,
        p_TRNCOMISION_porcentaje_6: 0,
        p_TRNCOMISION_totalretenido_7: Number(comision.TRNCOMISION_TOTALRETENIDO),
        p_TRNCOBRO_nroDoc_8: nroDoc,
        p_COM_codigo_3: '01',
        p_trncomision_origen: comision.TRNCOMISION_ORIGEN,
        p_con_codigo: comision.CON_CODIGO,
        p_TRNCOBRO_NROCOMPROBANTE: nroComp,
        p_CLI_CODIGO: comision.CLI_CODIGO,
        p_trncomision_flag: comision.TRNCOMISION_FLAG
      },
    }, this.confIniciales.httpOptions());
  }
  insertarFormadePago(pago: Cxctrnformapago, nroDoc, nroComp): Observable<any[]> {
    if (pago.CAJ_CODIGO === '' || pago.CAJ_CODIGO === null) {
      pago.CAJ_CODIGO = pago.BANCLI_CODIGO;
    }
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/12bf1db7e376cb000', {
      elementos: {
        p_TRNPAGO_tipo_2: pago.TRNPAGO_TIPO,
        p_CAJ_codigo_3: pago.CAJ_CODIGO,
        p_TRNPAGO_numero_4: pago.TRNPAGO_NUMERO,
        p_TRNPAGO_fecha_5: pago.TRNPAGO_FECHA,
        p_BANCLI_CODIGO: pago.BANCLI_CODIGO,
        p_TRNPAGO_NROCTA: pago.TRNPAGO_NROCTA,
        p_TRNPAGO_concepto_6: pago.TRNPAGO_CONCEPTO,
        p_TRNPAGO_importe_7: Number(pago.TRNPAGO_IMPORTE),
        p_TRNPAGO_numero_anterior: '',
        p_CON_codigo_9: pago.CON_CODIGO,
        p_TRNCOBRO_nroDoc_1: nroDoc,
        p_COM_codigo_8: '01',
        p_TRNCOBRO_nroComprobante: nroComp,
        p_CLI_CODIGO: pago.CLI_CODIGO,
        p_TRNPAGO_CODSRI: pago.TRNPAGO_CODSRI,
        p_TRNCHEPOS_NUMDOC: pago.TRNCHEPOS_NUMDOC,
        p_TRNPAGO_TIPDIS: pago.TRNPAGO_TIPDIS
      },
    }, this.confIniciales.httpOptions());
  }
  actualizarCobroProm(cobro: Cxctrncobro, tipoDoc) {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/14cef94cc2f78', {
      elementos: {
        p_TRNCOBRO_nroDoc: cobro.TRNCOBRO_NRODOC,
        p_TRNCOBRO_nroComprobante: cobro.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_tipoDoc: tipoDoc,
        p_TRNCOBRO_fechaTrn: cobro.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_concepto: cobro.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_importe: Number(cobro.TRNCOBRO_IMPORTE),
        p_TRNCOBRO_fechavence: cobro.TRNCOBRO_FECHAVENCE,
        p_APLORG_codigo: cobro.APLORG_CODIGO,
        p_TRNCOBRO_referenciaExterna: cobro.TRNCOBRO_REFERENCIAEXTERNA,
        p_CLI_codigo: cobro.CLI_CODIGO,
        p_VEN_codigo: cobro.VEN_CODIGO,
        p_COM_codigo: '01',
        p_ASI_NRO: cobro.ASI_NRO,
        p_USU_IDENTIFICACION: cobro.USU_IDENTIFICACION,
        p_TRNCOBRO_FLAG: Number(cobro.TRNCOBRO_FLAG),
        p_BAN_CODIGO: cobro.BAN_CODIGO,
        p_TRNCOBRO_EFECTIVO: Number(cobro.TRNCOBRO_EFECTIVO),
        p_TRNCOBRO_CAMBIO: Number(cobro.TRNCOBRO_CAMBIO),
        p_CON_CODIGO: cobro.CON_CODIGO,
        p_TRNCOBRO_SALDOINI: Number(cobro.TRNCOBRO_SALDOINI),
        p_BOD_CODIGO: cobro.BOD_CODIGO,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getDP(TRNPAGO_NUMERO: string,BANCLI_CODIGO: string){
    return this.http.post<any[]>(this.way + '/nom/transotregresos/30bec9d7735d9', {
        elementos: {
          P_TRNBAN_NUMERO: TRNPAGO_NUMERO,
          P_BAN_CODIGO: BANCLI_CODIGO
        },
      },this.confIniciales.httpOptions()).toPromise();
  }
  verificarComprobanteUnico(parametro, numero, numeroFac): Promise<any[]> {
    if (parametro === 'N') {
      return this.http.post<any[]>(this.way + '/cxc/carteraactiva/1f76e4f662d9f6000', {
        elementos: {
          p_operacion: parametro,
          p_nrocomprobante: numero,
          p_com_codigo: '01',
          p_trncobro_nrodoc: ''
        },
      }, this.confIniciales.httpOptions()).toPromise();
    } else if (parametro === 'M') {
      return this.http.post<any[]>(this.way + '/cxc/carteraactiva/1f76e4f662d9f6000', {
        elementos: {
          p_operacion: parametro,
          p_nrocomprobante: numero,
          p_com_codigo: '01',
          p_trncobro_nrodoc: numeroFac
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
  }
  obtenermaecliente(strClicodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/617d7594e65650', {
      elementos: {
        CLI_CODIGO: strClicodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erClidiachposProm(strClicodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/edb2b26c94c8e800', {
      elementos: {
        CLI_CODIGO: strClicodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erConcodigo2(strCodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/76d98bb30d94300', {
      elementos: {
        CLI_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erCajcodigoProm(strFormapagotipo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/76d8a9b30d94300', {
      elementos: {
        FORMAPAGO_TIPO: strFormapagotipo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erFormapagocodsriProm(strtipo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/3b6fc6f2a87372', {
      elementos: {
        FORMAPAGO_TIPO: strtipo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erConcodigoProm(strCajcodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/3b6cc5d986ca18', {
      elementos: {
        CAJ_CODIGO: strCajcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerSaldoANC(strNumFact): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/617de2aadc2aec', {
      elementos: {
        NumFact: strNumFact,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erTrncobronrodocProm(strNrodoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/1dbeeeecc2f77e0000', {
      elementos: {
        TRNPAGO_NUMERO: strNrodoc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerSaldoCliente(cliCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/617de2aadc32b0', {
      elementos: {
        CLI_CODIGO: cliCodigo,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  
  getListaReferencias(codCli): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/10776b2e755b74000', {
      elementos: {
        CLI_CODIGO: codCli
      },
    }, this.confIniciales.httpOptions());
  }
  getEncCarteraProm(cobroNro): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/10775d76315beb000', {
      elementos: {
        TRNCOBRO_NRODOC: cobroNro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erMaeclienteProm(strClicodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/76eca732b275fc0', {
      elementos: {
        CLI_CODIGO: strClicodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerRetencionesProm(numeroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/185f76eebaec96000', {
      elementos: {
        TRNCOBRO_NRODOC: numeroDoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerOtrosGastosProm(numeroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/c2fb8eee0ae7700', {
      elementos: {
        p_opcion: 'DR',
        p_filtro: 'TRNCOBRO_NRODOC = \'' + numeroDoc + '\'',
        p_condicion: 'COM_CODIGO = \'01\''
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerFormadePagoProm(numeroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/617d7e376caa18', {
      elementos: {
        TRNCOBRO_NRODOC: numeroDoc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erBanclinombreProm(strClicodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/3b6b55d9595f16', {
      elementos: {
        BANCLI_CODIGO: strClicodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erTarDescripcion(strcodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/76faadb5dc66e40', {
      elementos: {
        TAR_CODIGO: strcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erBanbancoProm(strcodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/1db5aaeb55d98', {
      elementos: {
        BAN_CODIGO: strcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerDocumentosProm(opcion, numeroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/c2fadc33d675f80', {
      elementos: {
        p_opcion: opcion,
        p_filtro: 'TRNCOBRO_NRODOC = \'' + numeroDoc + '\'',
        p_condicion: 'COM_CODIGO = \'01\''
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erTrncajdepositonro2Prom(strNumero, strComprobante, strPagotipo, strCajcodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/edf777629b9bf000', {
      elementos: {
        TRNPAGO_NUMERO: strNumero,
        TRNCOBRO_NROCOMPROBANTE: strComprobante,
        TRNPAGO_TIPO: strPagotipo,
        CAJ_CODIGO: strCajcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erTrnbanconciliaProm(strNrodoc, strBanclicodigo, strPagotipo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/edf7775aaecc6000', {
      elementos: {
        TRNCOBRO_NRODOC: strNrodoc,
        BANCLI_CODIGO: strBanclicodigo,
        TRNPAGO_TIPO: strPagotipo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erTrncajdepositonroProm(strNumero, strBanclicodigo, strPagotipo, strDocnro): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/1dbeeeec54db9f0000', {
      elementos: {
        TRNPAGO_NUMERO: strNumero,
        BANCLI_CODIGO: strBanclicodigo,
        TRNPAGO_TIPO: strPagotipo,
        TRNCOBRO_NRODOC: strDocnro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erTrntarjetacodigoProm(strNumero, strComprobante, strPagotipo, strCajcodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/76faadcd986ca00', {
      elementos: {
        TRNPAGO_NUMERO: strNumero,
        TRNCOBRO_NROCOMPROBANTE: strComprobante,
        TRNPAGO_TIPO: strPagotipo,
        CAJ_CODIGO: strCajcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getOrigenProm(aplorgcodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/107771b941d7', {
      elementos: {
        APLORG_CODIGO: aplorgcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getRealizadoProm(usuidentificacion): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/83bbb72ab2', {
      elementos: {
        USUIDENTIFICACION: usuidentificacion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erTrncobrocuadreProm(strNrodoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/edf7776616cf2800', {
      elementos: {
        TRNCOBRO_NRODOC: strNrodoc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getAsiento(codigo: string): Observable<any> {
    return this.http.post<any>(this.way + '/cxc/cxctrnabono/617d5724cc5fa8', {
      elementos: {
        TRNCOBRO_NRODOC: codigo
      }
    }, this.confIniciales.httpOptions());
  }
  getDetConencasilProm(strAsiNro, strOrigen) {
    return this.http.post<any[]>(this.way + '/cont/conencasil/83bad77598bbb00', {
      elementos: {
        p_asi_nro: strAsiNro,
        p_com_codigo: '01',
        p_origen: strOrigen,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  consultarDocumentosACobrar(opcion, condicion): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/198bf3d56e18a60000', {
      elementos: {
        p_opcion: opcion,
        p_filtro: 'COM_CODIGO LIKE \'01\'',
        p_condicion: condicion
      },
    }, this.confIniciales.httpOptions());
  }
  leerConfiguracionDatosElectronicos(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/2ae76daaee18f80000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  getListaClientes(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/20eed65cea995a0000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  getOrigen(aplorgcodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/107771b941d7', {
      elementos: {
        APLORG_CODIGO: aplorgcodigo
      },
    }, this.confIniciales.httpOptions());
  }
  getRealizado(usuidentificacion): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/83bbb72ab2', {
      elementos: {
        USUIDENTIFICACION: usuidentificacion
      },
    }, this.confIniciales.httpOptions());
  }
  cxc_fnc_verificar_ant_util(strCobroNrodoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/fbb727aafdf7680', {
      elementos: {
        TrnCobro: strCobroNrodoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erTrnpagorefer(strNrodoc){
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/1dbeeef95431b70000', {
      elementos: {
        TRNCOBRO_NRODOC: strNrodoc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eliminarTrnCobroProm(docNro) {    
    return this.http.post<any>(this.way + '/cxc/cxctrnabono/eacad2eeeecc3000', {
      elementos: {
        TRNCOBRO_NRODOC: docNro,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eliminarNDATrnResumen(nroDoc): Observable<any[]> {
    console.log('eliminando nda', nroDoc);
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/1d595daaeeefb70000', {
      elementos: {
        p_trncobro_nrodoc: nroDoc,
        p_com_codigo: '01',
      },
    }, this.confIniciales.httpOptions());
  }
  anularAsiento(asi): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/2afeaab9275fb8', {
      elementos: {
        p_ASI_nro: asi,
        p_COM_codigo: '01',
      },
    }, this.confIniciales.httpOptions());
  }
  actualizarAsiento(cobro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/299df2b9275fb8', {
      elementos: {
        p_nro_doc: cobro.TRNCOBRO_NRODOC,
        p_asi_nro: cobro.ASI_NRO,
        p_asi_origen: 'CXC',
        p_com_codigo: '01',
      },
    }, this.confIniciales.httpOptions());
  }
  eliminarAsiento(asi): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/756565724cc5fc0', {
      elementos: {
        p_ASI_nro_1: asi,
        p_COM_codigo: '01',
      },
    }, this.confIniciales.httpOptions());
  }
  eliminarAntAsoc(nroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/7565655faae6180', {
      elementos: {
        p_trncobro_nrodoc: nroDoc,
        p_com_codigo: '01',
      },
    }, this.confIniciales.httpOptions());
  }
 eliminarMovBan (docNro) {    
  return this.http.post<any>(this.way + '/cxc/cxctrnabono/1d595a5756ed9000000000000000', {
    elementos: {
      p_TRNCOBRO_NRODOC: docNro,
      p_com_codigo:'01'
    },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  deleteimptable(auto) {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/1aeabbae95b3d50000', {
      elementos: {
        autorizacion: auto
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarPagadoCuota(doc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/299df655067b1c', {
      elementos: {
        p_ENCFAC_NUMERO: doc.TRNCOBRO_NRODOC,
        p_CLI_CODIGO: doc.CLI_CODIGO,
        p_COM_CODIGO: '01',
      },
    }, this.confIniciales.httpOptions());
  }
  actualizarestadoFac(doc): Observable<any[]> {
    console.log(doc);
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/299df73ad7a99c', {
      elementos: {
        p_com_codigo: '01',
        p_cli_codigo: doc.CLI_CODIGO,
        p_encfac_numero: doc.TRNCOBRO_NRODOC,
      },
    }, this.confIniciales.httpOptions());
  }
  actPagadoDETCUOTA(item): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/a6772a835dd67800', {
      elementos: {
        p_ENCFAC_NUMERO: item.ENCFAC_NUMERO,
        p_CLI_CODIGO: item.CLI_CODIGO,
        p_COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerClave(accion): Observable<any> {
    if (accion === 'activarXML') {
      return this.http.post<any>(this.way + '/cxc/cxctrnabono/30beb2aafa99d', {
        elementos: {},
      }, this.confIniciales.httpOptions());
    } else {
      return this.http.post<any>(this.way + '/cxc/cxctrnabono/c2facaabeaf7600', {
        elementos: {},
      }, this.confIniciales.httpOptions());
    }

  }
  anularDocumento(cobroNroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/2afeaab6dc33d6', {
      elementos: {
        TRNCOBRO_NRODOC: cobroNroDoc,
      }
    }, this.confIniciales.httpOptions());
  }
  anularAsientoDoc(asiNro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/55fd55724ebb700', {
      elementos: {
        asi_nro: asiNro,
      }
    }, this.confIniciales.httpOptions());
  }
  eliminarDocAnulado(documento): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/3ab2b370c55fd4', {
      elementos: {
        trncobro_nrodoc: documento.TRNCOBRO_NRODOC,
        cli_codigo: documento.CLI_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarDocAnuladoEnc(documento): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/eac9b862afeab800', {
      elementos: {
        trncobro_nrodoc: documento.TRNCOBRO_NRODOC,
        cli_codigo: documento.CLI_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarPagoAnulado(documento): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/eacb2a86157f5800', {
      elementos: {
        trncobro_nrodoc: documento.TRNCOBRO_NRODOC,
        cli_codigo: documento.CLI_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }
  eliminarRetencionAnulado(documento): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/7565b775d762b00', {
      elementos: {
        trncobro_nrodoc: documento.TRNCOBRO_NRODOC,
        cli_codigo: documento.CLI_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }
  asibangettrnpago(strTrnCobroNroDoc): Promise<any[]> {    
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/5724b55e0eef78000000', {
      elementos: {
        strTrnCobroNroDoc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  updateasientobantrnban(strAsiNro, strBanCodigo, strTrnBanTipo, strTrnBanNumero): Promise<any[]> {    
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/3d96ab1ae5724ec00000', {
      elementos: {
        strAsiNro, strBanCodigo, strTrnBanTipo, strTrnBanNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  updateasientobantrncaj(strAsiNro, strBanCodigo, strTrnBanTipo, strTrnBanNumero): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/c54fd96abae570000000', {
      elementos: {
        strAsiNro, strBanCodigo, strTrnBanTipo, strTrnBanNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  generarAsientoCI(cobro: Cxctrncobro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/41d75724ebf590', {
      elementos: {
        p_trncobro_nrodoc: cobro.TRNCOBRO_NRODOC,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'CI',
        p_asi_comprobante: cobro.TRNCOBRO_NROCOMPROBANTE.toString().trim(),
      },
    }, this.confIniciales.httpOptions());
  }
  erCountSacitrnretencion(strReferencia): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/198f5fbdddf6ef0000', {
      elementos: {
        TRNRETENCION_REFERENCIA: strReferencia
      },
    }, this.confIniciales.httpOptions());
  }
  obtenerRetenciones(numeroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/185f76eebaec96000', {
      elementos: {
        TRNCOBRO_NRODOC: numeroDoc
      },
    }, this.confIniciales.httpOptions());
  }
  eliminarCaja(docNro): Observable<any> {
    return this.http.post<any>(this.way + '/cxc/cxctrnabono/3ab2b4aea62a6a', {
      elementos: {
        TRNCAJ_DOCNRO: docNro,
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarBanco(docNro): Observable<any> {
    return this.http.post<any>(this.way + '/cxc/cxctrnabono/3ab2b4aea5aaec', {
      elementos: {
        TRNBAN_DOCNRO: docNro,
      }
    }, this.confIniciales.httpOptions());
  }
  obtenerOtrosGastos(numeroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/c2fb8eee0ae7700', {
      elementos: {
        p_opcion: 'DR',
        p_filtro: 'TRNCOBRO_NRODOC = \'' + numeroDoc + '\'',
        p_condicion: 'COM_CODIGO = \'01\''
      },
    }, this.confIniciales.httpOptions());
  }
  eliminarFormaPago(numdoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/756567e37656540', {
      elementos: {
        TRNCOBRO_NRODOC: numdoc
      }
    }, this.confIniciales.httpOptions());
  }
  eliminarDPBanco(pagoNumero): Observable<any> {
    return this.http.post<any>(this.way + '/cxc/cxctrnabono/756569372b55d80', {
      elementos: {
        TRNBAN_NUMERO: pagoNumero,
        TRNBAN_TIPO: 'DP'
      },
    }, this.confIniciales.httpOptions());
  }
  getDocCobrados(cliCodigo, numFac): Observable<any[]> {
    let sentencia = '';
    if (cliCodigo !== '') {
      sentencia += ' AND D.CLI_CODIGO=\'' + cliCodigo + '\'';
    }
    if (numFac !== '') {
      sentencia += ' AND UPPER(D.TRNDOCUMENTO_NRODOC) LIKE \'%' + numFac + '%\'';
    }
    return this.http.post<any[]>(this.way + '/cxc/cxctrnabono/83badc330bda980', {
      elementos: {
        strsentencia: sentencia
      },
    }, this.confIniciales.httpOptions());
  }
}
