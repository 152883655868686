<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="maeetiqueta"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmmaeetiqueta" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 99%">
  <div class="p-col-12">
    <app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
      [botonRegresar]="btnRegresar" [barraBotonesCRUD]="false" [barraBotonesDesplaz]="true" [barraBotones3]="true"
      [barraBotonesAsiCon]="true" [barraBotones5]="true" (signal)="manejarSenales($event)"></app-invmaebarra>
  </div>
  <div style="overflow: hidden" class="p-col-12">
    <p-tabView>
      <p-tabPanel header="Etiquetas">
        <app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
          [rowData]="tecmaeetiqueta" [frameworkComponents]="frameworkComponents" [animateRows]="true"
          [rowSelection]="rowSelection" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowStyle]="rowStyle"
          [singleClickEdit]="false" [botones]="false" (selected)="seleccion($event)" (cambios)="botonesModificar()">
        </app-aggridsaci>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>