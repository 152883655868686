import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Cxcedithisto} from '../cxcinterfaces/cxcedithisto';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class CxctrnedithistService {
  public way: string;
  private _edithisto: Cxcedithisto[];

  get cxctrnhisto(): Cxcedithisto[] {
    return this._edithisto;
  }
  
  set cxctrnhisto(value: Cxcedithisto[]) {
    this._edithisto = value;
  }
  
  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) { 
      this.way = sessionStorage.getItem('way');
    }
    listardatos(trncobro,clicodigo): Observable<any[]> {
      return this.http.post<any[]>(this.way+ '/cxc/cxctrnedithistorial/565ceab71973b800000', {
          elementos: {
            p_trncobronrodoc: trncobro,
            p_comcodigo:'01',
            p_clicodigo:clicodigo
          }
      }, this.confIniciales.httpOptions());
    }
    listaralldatos(clicodigo) {
      return this.http.post<any[]>(this.way+ '/cxc/cxctrnedithistorial/cc5f9eaf55b558000000000000', {
          elementos: {
            p_comcodigo:'01',
            p_clicodigo:clicodigo
          }
      }, this.confIniciales.httpOptions()).toPromise();
    }
    eliminarDetHis(trnhis): Observable<any[]> {
      return this.http.post<any[]>(this.way+ '/cxc/cxctrnedithistorial/7565695d5b6bbc0', {
          elementos: {
            p_trncobronrodoc: trnhis.TRNCOBRO_NRODOC,
            p_comcodigo:'01',
            p_clicodigo:trnhis.CLI_CODIGO,
            p_secuencial:trnhis.TRNHIS_SECUENCIAL
          }
      }, this.confIniciales.httpOptions());
    }
    insertarDetHis(trnhis) {
      return this.http.post<any[]>(this.way+ '/cxc/cxctrnedithistorial/95f8edf55b6bb80', {
          elementos: {
            p_trncobronrodoc: trnhis.TRNCOBRO_NRODOC,
            p_comcodigo:'01',
            p_clicodigo:trnhis.CLI_CODIGO,
            p_fechahis:trnhis.TRNHIS_FECHA,
            p_comentario:trnhis.TRNHIS_COMENTARIO
          }
      }, this.confIniciales.httpOptions()).toPromise();
    }
    actuatarDetHis(trnhis) {
      return this.http.post<any[]>(this.way+ '/cxc/cxctrnedithistorial/299df2ab2', {
          elementos: {
            p_trncobronrodoc: trnhis.TRNCOBRO_NRODOC,
            p_comcodigo:'01',
            p_clicodigo:trnhis.CLI_CODIGO,
            p_secuencial:trnhis.TRNHIS_SECUENCIAL,
            p_comentario:trnhis.TRNHIS_COMENTARIO
          }
      }, this.confIniciales.httpOptions()).toPromise();
    }
}
