import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { MessageService } from 'primeng';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActmaeactivoService {

  private way: string;

  constructor(private http: HttpClient, 
    private confIniciales: ConfInicialesService, 
    private init: NuevoComponentService,
    private message: MessageService) { 
      this.way = sessionStorage.getItem('way');
  }

  consultaDatos(parametros) {
    return this.http.post<any[]>(this.way + '/act/maeactivo/198bf3d5eb55d', {
      elementos: {
        p_com_codigo: parametros[0],
        p_act_codigo: parametros[1]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarRegistroPromise(strCampo, strTabla, strCondicion): Promise<any[]> {
    return this.http.post<[]>(this.way + '/act/maeactivo/3aecdba12e7778', {
      elementos: {
        campo: strCampo,
        tabla: strTabla,
        condicion: strCondicion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  consultarNovedades(strCodigo): Promise<any[]> {
    return this.http.post<[]>(this.way + '/act/maeactivo/198bf3d5be3ee', {
      elementos: {
        ACT_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  consultarKardex(strCodigo): Promise<any[]> {
    return this.http.post<[]>(this.way + '/act/maeactivo/198bf3cc56dae', {
      elementos: {
        ACT_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  navegarRegistro(parametros): Promise<any[]> {
    return this.http.post<[]>(this.way + '/act/maeactivo/5d5f7415bdba10', {
      elementos: {
        p_tipo: parametros[0],
        p_com_codigo: parametros[1],
        p_act_codigo: parametros[2]
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarActMaeGrupo(strParametro): Observable<any> {
    return this.http.post<any[]>(this.way + '/act/maeactivo/75d98bf6ca74380', {
        elementos: {
          parametro: strParametro
        },
      }, this.confIniciales.httpOptions()
    );
  }

  encontrarVenMaeCiudad(strParametro): Observable<any> {
    return this.http.post<any[]>(this.way + '/act/maeactivo/ebb317fbaf653000', {
        elementos: {
          parametro: strParametro
        },
      }, this.confIniciales.httpOptions()
    );
  }

  encontrarReferencia(strParametro, strTipo): Observable<any> {
    return this.http.post<any[]>(this.way + '/act/maeactivo/75d9c5325656dc0', {
        elementos: {
          parametro: strParametro,
          tipo: strTipo
        },
      }, this.confIniciales.httpOptions()
    );
  }

  encontrarNomMaeEmpleado(strParametro): Observable<any> {
    return this.http.post<any[]>(this.way + '/act/maeactivo/75d97c594e75b40', {
        elementos: {
          parametro: strParametro === '' ? 0 : strParametro,
        },
      }, this.confIniciales.httpOptions()
    );
  }

  encontrarVenMaeCliente(strParametro): Observable<any> {
    return this.http.post<any[]>(this.way + '/act/maeactivo/3aecfbaf6532b2', {
        elementos: {
          parametro: strParametro,
        },
      }, this.confIniciales.httpOptions()
    );
  }

  encontrarComMaeProveedor(strParametro): Observable<any> {
    return this.http.post<any[]>(this.way + '/act/maeactivo/3316b29d9de3e0', {
        elementos: {
          parametro: strParametro,
        },
      }, this.confIniciales.httpOptions()
    );
  }

  encontrarActMaePoliza(strParametro): Observable<any> {
    return this.http.post<any[]>(this.way + '/act/maeactivo/1d76594ece2b2', {
        elementos: {
          parametro: strParametro,
        },
      }, this.confIniciales.httpOptions()
    );
  }

  encontrarConMaeCon(strParametro): Observable<any> {
    return this.http.post<any[]>(this.way + '/act/maeactivo/1d763317b29cc', {
        elementos: {
          parametro: strParametro,
        },
      }, this.confIniciales.httpOptions()
    );
  }

  encontrarConMaeCen(strParametro): Observable<any> {
    return this.http.post<any[]>(this.way + '/act/maeactivo/1d7662ca731d7', {
        elementos: {
          parametro: strParametro,
        },
      }, this.confIniciales.httpOptions()
    );
  }

  guardarDatos(parametros) {
    return this.http.post<any[]>(this.way + '/act/maeactivo/257e6faa677f8', {
      elementos: {
        p_ACT_CODIGO: parametros[0],
        p_COM_CODIGO: parametros[1],
        p_ACT_GRUPO: parametros[2],
        p_ACT_NOMBRE: parametros[3],
        p_ACT_NOMBREC: parametros[4],
        p_ACT_PROCESO: parametros[5],
        p_ACT_ORIGEN: parametros[6],
        p_ACT_LUGARORG: parametros[7],
        p_ACT_ESTADO: parametros[8],
        p_CON_CODIGO: parametros[9],
        p_CIU_CODIGO: parametros[10],
        p_ACT_OFICINA: parametros[11],
        p_ACT_DEPARTAMENTO: parametros[12],
        p_ACT_AREA: parametros[13],
        p_ACT_TIPOUSUARIO: parametros[14],
        p_ACT_USUARIO: parametros[15],
        p_PRO_CODIGO: parametros[16],
        p_ACT_NUMCE: parametros[17],
        p_ACT_FECHACOMPRA: parametros[18],
        p_ACT_FECHAUSO: parametros[19],
        p_ACT_FECHAFAB: parametros[20],
        p_ACT_POLIZA: parametros[21],
        p_ACT_MARCA: parametros[22],
        p_ACT_MODELO: parametros[23],
        p_ACT_MOTORSERIE: parametros[24],
        p_ACT_CHASIS: parametros[25],
        p_ACT_PLACA: parametros[26],
        p_ACT_COLOR: parametros[27],
        p_ACT_COSTO: parametros[28],
        p_ACT_COSTOORG: parametros[29],
        p_ACT_MONEDA: parametros[30],
        p_ACT_COTIZACAMBIO: parametros[31],
        p_ACT_VIDAUTIL: parametros[32],
        p_PRO_CODIGOMANT: parametros[33],
        p_ACT_TIPOMANT: parametros[34],
        p_ACT_FRECUENCIAMANT: parametros[35],
        p_ACT_OBSERVACIONMANT: parametros[36],
        p_ACT_TIEMPOGAR: parametros[37],
        p_ACT_UNIDADTIEMPOGAR: parametros[38],
        p_ACT_UNIDADGAR: parametros[39],
        p_ACT_FECHAINIGAR: parametros[40],
        p_ACT_FECHAFINGAR: parametros[41],
        p_ACT_COBERTURAGAR: parametros[42],
        p_ACT_EXCEPCIONGAR: parametros[43],
        p_ACT_ADICIONALGAR: parametros[44],
        p_ACT_SELLOSEGUGAR: parametros[45],
        p_ACT_VALORGAR: parametros[46],
        p_ACT_OBSERVACIONGAR: parametros[47],
        p_CON_CODIGODEP: parametros[48],
        p_act_dprcacum: parametros[49],
        p_act_valrsdl: parametros[50],
        p_act_fechrgstr: parametros[51],
        p_act_comentario: parametros[52],
        p_act_usuario2: parametros[53],
        p_act_cmpbcompra: parametros[54],
        p_ACT_PROPIETARIO: parametros[55],
        p_con_codigogas: parametros[56],
        p_act_fecultmdprc: parametros[57],
        p_cen_codigoact: parametros[58],
        p_cen_codigodep: parametros[59],
        p_cen_codigogas: parametros[60]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarActivo(parametros) {
    return this.http.post<any[]>(this.way + '/act/maeactivo/14cef94cecbf8', {
      elementos: {
        p_ACT_CODIGO: parametros[0],
        p_ACT_CODIGO2: parametros[1],
        p_COM_CODIGO: parametros[2],
        p_ACT_GRUPO: parametros[3],
        p_ACT_NOMBRE: parametros[4],
        p_ACT_NOMBREC: parametros[5],
        p_ACT_PROCESO: parametros[6],
        p_ACT_ORIGEN: parametros[7],
        p_ACT_LUGARORG: parametros[8],
        p_ACT_ESTAD: parametros[9],
        p_CON_CODIGO: parametros[10],
        p_CIU_CODIGO: parametros[11],
        p_ACT_OFICINA: parametros[12],
        p_ACT_DEPARTAMENTO: parametros[13],
        p_ACT_AREA: parametros[14],
        p_ACT_TIPOUSUARIO: parametros[15],
        p_ACT_USUARIO: parametros[16],
        p_PRO_CODIGO: parametros[17],
        p_ACT_NUMCE: parametros[18],
        p_ACT_FECHACOMPRA: parametros[19],
        p_ACT_FECHAUSO: parametros[20],
        p_ACT_FECHAFAB: parametros[21],
        p_ACT_POLIZA: parametros[22],
        p_ACT_MARCA: parametros[23],
        p_ACT_MODELO: parametros[24],
        p_ACT_MOTORSERIE: parametros[25],
        p_ACT_CHASIS: parametros[26],
        p_ACT_PLACA: parametros[27],
        p_ACT_COLOR: parametros[28],
        p_ACT_COSTO: parametros[29],
        p_ACT_COSTOORG: parametros[30],
        p_ACT_MONEDA: parametros[31],
        p_ACT_COTIZACAMBIO: parametros[32],
        p_ACT_VIDAUTIL: parametros[33],
        p_PRO_CODIGOMANT: parametros[34],
        p_ACT_TIPOMANT: parametros[35],
        p_ACT_FRECUENCIAMANT: parametros[36],
        p_ACT_OBSERVACIONMANT: parametros[37],
        p_ACT_TIEMPOGAR: parametros[38],
        p_ACT_UNIDADTIEMPOGAR: parametros[39],
        p_ACT_UNIDADGAR: parametros[40],
        p_ACT_FECHAINIGAR: parametros[41],
        p_ACT_FECHAFINGAR: parametros[42],
        p_ACT_COBERTURAGAR: parametros[43],
        p_ACT_EXCEPCIONGAR: parametros[44],
        p_ACT_ADICIONALGAR: parametros[45],
        p_ACT_SELLOSEGUGAR: parametros[46],
        p_ACT_VALORGAR: parametros[47],
        p_ACT_OBSERVACIONGAR: parametros[48],
        p_CON_CODIGODEP: parametros[49],
        p_act_dprcacum: parametros[50],
        p_act_valrsdl: parametros[51],
        p_act_fechrgstr: parametros[52],
        p_act_comentario: parametros[53],
        p_act_usuario2: parametros[54],
        p_act_cmpbcompra: parametros[55],
        p_ACT_PROPIETARIO: parametros[56],
        p_con_codigogas: parametros[57],
        p_act_fecultmdprc: parametros[58],
        p_cen_codigoact: parametros[59],
        p_cen_codigodep: parametros[60],
        p_cen_codigogas: parametros[61],
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarActivo(parametros) {
    return this.http.post<any[]>(this.way + '/act/maeactivo/eacaca6765f', {
      elementos: {
        p_ACT_codigo_1: parametros[0],
        p_COM_codigo_2: parametros[1]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

}
