<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="trans"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarTrans" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 99%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
						 [botonRegresar]="btnRegresar" [botonPrimero]="btnPrimero" [botonAnterior]="btnAnterior"
						 [botonSiguiente]="btnSiguiente" [botonUltimo]="btnUltimo" [barraBotonesCRUD]="false"
						 [barraBotonesDesplaz]="false" [barraBotones3]="true" [barraBotonesAsiCon]="true"
						 [barraBotones5]="false" [botonBuscar]="false"
						 (signal)="manejarSenales($event)"></app-invmaebarra>
	</div>
	<div class="p-col-12">
		<p-tabView>
			<p-tabPanel header="Generales">
				<div class="divgrups">
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Código</span>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<input type="text" pInputText size="14"
										   [(ngModel)]="transportistaSeleccionado.TRANSPORT_CODIGO"
										   [disabled]="inputNum">
								</div>
								<div class="p-col-7"></div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Nombre</span>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-8">
									<input type="text" pInputText id="nombre" (input)="cambio()"
										   [(ngModel)]="transportistaSeleccionado.TRANSPORT_NOMBRE" [maxLength]="60"
										   (keydown.enter)="cambiarFoco($event)">
								</div>
								<div class="p-col-1"></div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Tipo RUC/ID</span>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<p-dropdown [style]="{'height':'95%', 'width': '200px'}" [options]="types1"
												[panelStyle]="{'width':'100%'}"></p-dropdown>
								</div>
								<div class="p-col-2"></div>
								<div class="p-col-1">
									<span>Ruc:</span>
								</div>
								<div class="p-col-3">
									<input type="text" id="RUC" pInputText (input)="cambio()"
										   [(ngModel)]="transportistaSeleccionado.TRANSPORT_RUC" [maxLength]="15"
										   (keydown.enter)="cambiarFoco($event)">
								</div>
								<div class="p-col-1"></div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Contacto</span>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-8">
									<input type="text" pInputText id="contacto" (input)="cambio()"
										   [(ngModel)]="transportistaSeleccionado.TRANSPORT_CONTACTO" [maxLength]="60"
										   (keydown.enter)="cambiarFoco($event)">
								</div>
								<div class="p-col-1"></div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Dirección</span>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-8">
									<input type="text" pInputText id="direccion" (input)="cambio()"
										   [(ngModel)]="transportistaSeleccionado.TRANSPORT_DIRECCION" [maxLength]="100"
										   (keydown.enter)="cambiarFoco($event)">
								</div>
								<div class="p-col-1"></div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Teléfonos</span>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-3">
									<input type="text" pInputText id="telefono" (input)="cambio()"
										   [(ngModel)]="transportistaSeleccionado.TRANSPORT_TELEFONO1" [maxLength]="15"
										   (keydown.enter)="cambiarFoco($event)">
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-4">
									<input type="text" pInputText id="telefono2" (input)="cambio()"
										   [(ngModel)]="transportistaSeleccionado.TRANSPORT_TELEFONO2" [maxLength]="15"
										   (keydown.enter)="cambiarFoco($event)">
								</div>
								<div class="p-col-1"></div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Auto</span>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-8">
									<input type="text" pInputText id="auto" (input)="cambio()"
										   [(ngModel)]="transportistaSeleccionado.TRANSPORT_AUTO" [maxLength]="60"
										   (keydown.enter)="cambiarFoco($event)">
								</div>
								<div class="p-col-1"></div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Placa</span>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<input type="text" id="placa" pInputText (input)="cambio()"
										   [(ngModel)]="transportistaSeleccionado.TRANSPORT_PLACA" [maxLength]="30"
										   (keydown.enter)="cambiarFoco($event)">
								</div>
								<div class="p-col-7"></div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Tonelaje</span>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<input id="tonelaje" type="number" pInputText (input)="cambio()"
										   [(ngModel)]="transportistaSeleccionado.TRANSPORT_TONELAJE">
								</div>
								<div class="p-col-7"></div>
							</div>
						</div>
					</div>
				</div>
			</p-tabPanel>
		</p-tabView>
	</div>
</div>
<p-dialog header="Búsqueda de datos" [(visible)]="displayDialogBus2" [responsive]="true"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'}">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloBus"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>
