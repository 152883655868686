import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Invprecalkardex} from '../invinterfaces/invprecalkardex';
import {InvprecalCOM} from '../invinterfaces/invprecalCOM';
import {InvprecalDVC} from '../invinterfaces/invprecalDVC';
import {InvprecalNR} from '../invinterfaces/invprecalNR';
import {InvprecalFAC} from '../invinterfaces/invprecalFAC';
import {InvprecalDVF} from '../invinterfaces/invprecalDVF';
import {InvprecalGRE} from '../invinterfaces/invprecalGRE';
import {InvprecalTRF} from '../invinterfaces/invprecalTRF';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { InvprecalPROD } from '../invinterfaces/invprecalPROD';

@Injectable({
  providedIn: 'root'
})
export class InvprrecalkardexService {
  get resultadosDVC(): InvprecalDVC[] {
    return this._resultadosDVC;
  }

  set resultadosDVC(value: InvprecalDVC[]) {
    this._resultadosDVC = value;
  }

  get resultadosNR(): InvprecalNR[] {
    return this._resultadosNR;
  }

  set resultadosNR(value: InvprecalNR[]) {
    this._resultadosNR = value;
  }

  get resultadosFAC(): InvprecalFAC[] {
    return this._resultadosFAC;
  }

  set resultadosFAC(value: InvprecalFAC[]) {
    this._resultadosFAC = value;
  }

  get resultadosDVF(): InvprecalDVF[] {
    return this._resultadosDVF;
  }

  set resultadosDVF(value: InvprecalDVF[]) {
    this._resultadosDVF = value;
  }

  get resultadosGRE(): InvprecalGRE[] {
    return this._resultadosGRE;
  }

  set resultadosGRE(value: InvprecalGRE[]) {
    this._resultadosGRE = value;
  }

  get resultadosTRF(): InvprecalTRF[] {
    return this._resultadosTRF;
  }

  set resultadosTRF(value: InvprecalTRF[]) {
    this._resultadosTRF = value;
  }
  get resultadosCOM(): InvprecalCOM[] {
    return this._resultadosCOM;
  }

  set resultadosCOM(value: InvprecalCOM[]) {
    this._resultadosCOM = value;
  }
  get resultados(): Invprecalkardex[] {
    return this._resultados;
  }

  set resultados(value: Invprecalkardex[]) {
    this._resultados = value;
  }

  
  public url: string;
  public way: string;
  private _resultados: Invprecalkardex[];
  private _resultadosCOM: InvprecalCOM[];
  private _resultadosDVC: InvprecalDVC[];
  private _resultadosNR: InvprecalNR[];
  private _resultadosFAC: InvprecalFAC[];
  private _resultadosDVF: InvprecalDVF[];
  private _resultadosGRE: InvprecalGRE[];
  private _resultadosTRF: InvprecalTRF[];
  public resultadosPROD: InvprecalPROD[] = [];

  constructor(private http: HttpClient, public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,private confIniciales: ConfInicialesService) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }
  getResultadosDocumento(fechaDesde, fechaHasta, Tipo): Observable<any[]> {
    return this.http.post<any>(this.way + '/inventario/recalkardex/a5t0xh9a4tx7etb', {
      elementos: {
        p_fechaInicial: fechaDesde,
        p_fechaFinal: fechaHasta,
        p_origen: Tipo,
      },
    }, this.confIniciales.httpOptions());
  }
  registrarKardex(nuevoKardex: any, caso: number): Observable<any[]> {
    switch (caso) {
      case 1:
        return this.http.post<any>(this.way + '/inventario/recalkardex/dc2p1j9t90vcr4d', {
          caso: caso,
          elementos: {
            p_numero: nuevoKardex.ENCFACPRO_NUMERO,
            p_com_codigo: '01'
          },
        }, this.confIniciales.httpOptions());
      case 2:
        return this.http.post<any>(this.way + '/inventario/recalkardex/yzjrv2axml1zirp', {
          elementos: {
            p_numero: nuevoKardex.ENCNOTREC_NUMERO,
            p_com_codigo: '01'
          },
        }, this.confIniciales.httpOptions());
      case 3:
        return this.http.post<any>(this.way + '/inventario/recalkardex/zieqgg8ujza9n2t', {
          elementos: {
            p_numero: nuevoKardex.ENCDEV_NUMERO,
            p_com_codigo: '01'
          },
        }, this.confIniciales.httpOptions());
      case 4:
        return this.http.post<any>(this.way + '/inventario/recalkardex/5jteeos7bcsze4t', {
          elementos: {
            p_encfac_numero: nuevoKardex.ENCFAC_NUMERO,
            p_com_codigo: '01'
          },
        }, this.confIniciales.httpOptions());
      case 5:
        return this.http.post<any>(this.way + '/inventario/recalkardex/zhubg6t9jevnrq7', {
          elementos: {
            p_encdvf_numero: nuevoKardex.ENCDVF_NUMERO,
            p_com_codigo: '01'
          },
        }, this.confIniciales.httpOptions());
      case 6:
        return this.http.post<any>(this.way + '/inventario/recalkardex/d07nbfvjhd9judc', {
          elementos: {
            p_encgre_codigo: nuevoKardex.ENCGRE_CODIGO
          },
        }, this.confIniciales.httpOptions());
      case 7:
        return this.http.post<any>(this.way + '/inventario/recalkardex/xhk01ubcjoh7om9', {
          elementos: {
            p_kar_nro: nuevoKardex.KAR_NRO,
            p_com_codigo: '01'
          },
        }, this.confIniciales.httpOptions());
      case 8:
        return this.http.post<any>(this.way + '/inventario/recalkardex/msamsmrh81v6lox', {
          elementos: {
            p_numero: nuevoKardex.TRANSFER_NRO,
            p_com_codigo: '01',
            p_FechaTransfer: nuevoKardex.TRANSFER_FECHATRANSFER
          },
        }, this.confIniciales.httpOptions());
      default:
        break;
    }
  }

  registrarKardexProm(nuevoKardex: any, caso: number) {
    switch (caso) {
      case 1:
        return this.http.post<any>(this.way + '/inventario/recalkardex/dc2p1j9t90vcr4d', {
          caso: caso,
          elementos: {
            p_numero: nuevoKardex.ENCFACPRO_NUMERO,
            p_com_codigo: '01'
          },
        }, this.confIniciales.httpOptions()).toPromise();
      case 2:
        return this.http.post<any>(this.way + '/inventario/recalkardex/yzjrv2axml1zirp', {
          elementos: {
            p_numero: nuevoKardex.ENCNOTREC_NUMERO,
            p_com_codigo: '01'
          },
        }, this.confIniciales.httpOptions()).toPromise();
      case 3:
        return this.http.post<any>(this.way + '/inventario/recalkardex/zieqgg8ujza9n2t', {
          elementos: {
            p_numero: nuevoKardex.ENCDEV_NUMERO,
            p_com_codigo: '01'
          },
        }, this.confIniciales.httpOptions()).toPromise();
      case 4:
        return this.http.post<any>(this.way + '/inventario/recalkardex/5jteeos7bcsze4t', {
          elementos: {
            p_encfac_numero: nuevoKardex.ENCFAC_NUMERO,
            p_com_codigo: '01'
          },
        }, this.confIniciales.httpOptions()).toPromise();
      case 5:
        return this.http.post<any>(this.way + '/inventario/recalkardex/zhubg6t9jevnrq7', {
          elementos: {
            p_encdvf_numero: nuevoKardex.ENCDVF_NUMERO,
            p_com_codigo: '01'
          },
        }, this.confIniciales.httpOptions()).toPromise();
      case 6:
        return this.http.post<any>(this.way + '/inventario/recalkardex/d07nbfvjhd9judc', {
          elementos: {
            p_encgre_codigo: nuevoKardex.ENCGRE_CODIGO
          },
        }, this.confIniciales.httpOptions()).toPromise();
      case 7:
        return this.http.post<any>(this.way + '/inventario/recalkardex/xhk01ubcjoh7om9', {
          elementos: {
            p_kar_nro: nuevoKardex.KAR_NRO,
            p_com_codigo: '01'
          },
        }, this.confIniciales.httpOptions()).toPromise();
      case 8:
        return this.http.post<any>(this.way + '/inventario/recalkardex/msamsmrh81v6lox', {
          elementos: {
            p_numero: nuevoKardex.TRANSFER_NRO,
            p_com_codigo: '01',
            p_FechaTransfer: nuevoKardex.TRANSFER_FECHATRANSFER
          },
        }, this.confIniciales.httpOptions()).toPromise();
      default:
        break;
    }
  }

  getResultadosDocumentoProd(strfechaDesde, strfechaHasta) {
    return this.http.post<any[]>(this.way + '/inventario/recalkardex/20eee36dcef0d', {
      elementos: {
        fechaDesde: strfechaDesde,
        fechaHasta: strfechaHasta,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  registrarKardexOrdProd(strNumero) {
    return this.http.post<any[]>(this.way + '/inventario/recalkardex/dba0c56dae09', {
      elementos: {
        p_encordpro_numero: strNumero,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
}
