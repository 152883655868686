<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="envioRol"></p-toast>
<div class="p-grid" style="width: 99%">
    <div class="p-col-12">
        <app-invmaebarra [barraBotonesCRUD]="true" [barraBotonesDesplaz]="true" [barraBotones3]="true"
        
         [barraBotonesAsiCon]="false" [barraBotones5]="true" [barraEnviarroles]="false" 
        [botonReenviarCorreo]="false"(signal)="manejarSenales($event)"></app-invmaebarra>
    </div>
</div> 

<div style="overflow: hidden" class="p-col-12">
    <p-tabView>
        <p-tabPanel header="Enviar Roles">
            <div class="divgrups">
                <div class="p-grid" style="margin-top: 10px;">
                    <div class="p-col-1">
						<span>Fecha Inicial:</span>
                    </div>
                    
                    <div class="p-col-2">
                        <p-calendar [(ngModel)]="fechaIni" [inputStyle]="{width: '100%', height: '20px'}" dateFormat="dd/mm/yy" ></p-calendar>
                    </div>
                    <div class="p-col-1">
						<span>Fecha Final:</span>
                    </div>
                    <div class="p-col-2">
                        <p-calendar [(ngModel)]="fechaFin" [inputStyle]="{width: '100%', height: '20px'}" dateFormat="dd/mm/yy" ></p-calendar>
                    </div>
                    <div class="p-col-3">
                        <button   
                            pButton class="aceptar" 
                            style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 49%;margin-left: 5px;margin-bottom: 5px;" 
                            (click)="getRoles()">Aceptar</button>
                    </div>
                    <div class="p-col-3">
                        <p-checkbox value="valSelect" (onChange)="selectTodos($event)"></p-checkbox>
                        <label for="">Seleccionar Todos</label>
                    </div>
                </div>
            </div>
            <app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="rolesPago"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefRolPagos"
							[defaultColDef]="defaultColDefRolPagos"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"

			></app-aggridsaci>
        </p-tabPanel>
    </p-tabView>
</div>
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	
</div>

