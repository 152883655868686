
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {SelectItem} from 'primeng/api';
//import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-invbusqueda',
  templateUrl: './invbusqueda.component.html',
  styleUrls: ['./invbusqueda.component.css']
})

export class InvbusquedaComponent implements OnInit, OnChanges {
  @Input() arregloConsulta: any[];
  @Input() arrayOptions: SelectItem[];
  @Output() opcionSeleccionada = new EventEmitter<any>();
  busquedaParams: string;
  tipoBusqueda: string;
  tipoSeleccionado: string;
  selectedItem: any;

  constructor() {
  
  }
  prueba() {
    document.getElementById('tr').tabIndex = -1;
    document.getElementById('tr').focus();
  }
  ngOnInit() {
    this.tipoBusqueda = 'contains';
    this.tipoSeleccionado = '';
  }
  ngOnChanges(changes: SimpleChanges): void {
    for (const valor in changes) {
      if (valor === 'arrayOptions') {
        if (this.arrayOptions !== undefined) {
          if (this.arrayOptions[0] !== undefined) {
            this.tipoSeleccionado = this.arrayOptions[0].value;
            this.busquedaParams = '';
          }
        }
      } else if (valor === 'arregloConsulta') {
        /*if (this.arregloConsulta !== undefined) {
          this.arregloConsulta.map((res1) => {
            if (res1.ENCCUADRECAJA_FECHA !== undefined) {
              res1.ENCCUADRECAJA_FECHA = this.datePipe.transform(res1.ENCCUADRECAJA_FECHA.toLocaleString().substr(0, 10),
                'dd/MM/yyyy');
            }
            if (res1.ENCCUADRECAJA_DESDE !== undefined) {
              res1.ENCCUADRECAJA_DESDE = this.datePipe.transform(res1.ENCCUADRECAJA_DESDE.toLocaleString().substr(0, 10),
                'dd/MM/yyyy');
            }
            if (res1.ENCCUADRECAJA_HASTA !== undefined) {
              res1.ENCCUADRECAJA_HASTA = this.datePipe.transform(res1.ENCCUADRECAJA_HASTA.toLocaleString().substr(0, 10),
                'dd/MM/yyyy');
            }
            if (res1.KAR_FECHA !== undefined) {
              res1.KAR_FECHA = this.datePipe.transform(res1.KAR_FECHA.toLocaleString().substr(0, 10),
                'dd/MM/yyyy');
            }
            if (res1.TRNTOMAFISICA_FECHA !== undefined) {
              res1.TRNTOMAFISICA_FECHA = this.datePipe.transform(res1.TRNTOMAFISICA_FECHA.toLocaleString().substr(0, 10),
                'dd/MM/yyyy');
            }
          });
        } */
      }
    }
  }

  selectedOption(opcion) {
    this.opcionSeleccionada.emit(opcion);
  }
}
