import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Anexmaecodsri} from '../anexinterfaces/anexmaecodsri';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class AnexmaecodsriService {
  
  get codSRI(): Anexmaecodsri[] {
    return this._anexmaecodsri;
  }
  
  set codSRI(value: Anexmaecodsri[]) {
    this._anexmaecodsri = value;
  }
  
 
  private way: string;
  private _anexmaecodsri: Anexmaecodsri[];
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService) {
   
    this.way = sessionStorage.getItem('way');
  }
  
  obtenerTipos(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/anex/maecodsri/c2faebbb7d96700', {
      elementos: {}
    }, this.confIniciales.httpOptions());
  }
  
  obtenerCodSRI(tipo: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/anex/maecodsri/617d75d986f370', {
      elementos: {
        p_CODSRI_tipo: tipo,
        p_CODSRI_orden: 'CODSRI_codigo',
        p_CODSRI_selecc: null ,
      }
    }, this.confIniciales.httpOptions());
  }
  insertarCodSRI(codsri: Anexmaecodsri): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/anex/maecodsri/95f8edf5986f380', {
      elementos: {
        p_codsri_tipo: codsri.Tipo,
        p_codsri_codigo: codsri.Código,
        p_codsri_descripcion: codsri.Descripción,
        p_codsri_anio: codsri.Año,
        p_codsri_ref: codsri.Ref,
        p_con_codigo: codsri.CodigoCon,
        //CDPJ
        p_codsri_fechaini:codsri.FechaIngreso,
        p_codsri_fechafin:codsri.FechaFin,
      }
    }, this.confIniciales.httpOptions());
  }
  actualizarCodSRI(codsri: Anexmaecodsri ): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/anex/maecodsri/f65aaeb9986f380', {
      elementos: {
        p_codsri_tipo: codsri.Tipo,
        p_codsri_codigo: codsri.Código,
        p_codsri_descripcion: codsri.Descripción,
        p_codsri_anio: codsri.Año,
        p_codsri_ref: codsri.Ref,
        p_con_codigo: codsri.CodigoCon,
         //CDPJ
         p_codsri_fechaini:codsri.FechaIngreso,
         p_codsri_fechafin:codsri.FechaFin,
      }
    }, this.confIniciales.httpOptions());
  }
  eliminarCodSRI(codsri: Anexmaecodsri): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/anex/maecodsri/3ab2b49986f372', {
      elementos: {
        p_codsri_tipo: codsri.Tipo,
        p_codsri_codigo: codsri.Código,
      }
    }, this.confIniciales.httpOptions());
  }
  
  
  
  
  
}
