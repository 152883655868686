<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="frmCorreos"></p-toast>
<p-confirmDialog [style]="{width: '41vw'}" [transitionOptions]="'0ms'" key="conffrmcorreosr" title="Correo"
    appendTo="body"></p-confirmDialog>

<app-invmaebarra [barraBotonesCRUD]="true" [barraBotonesDesplaz]="true" (signal)="manejarSenales($event)"
    [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true" [enviofrmcorreo]="true"></app-invmaebarra>
<div style="overflow: hidden" class="p-col-12">
            <app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
                [rowData]="frmcorreos" [frameworkComponents]="frameworkComponents" [animateRows]="true"
                [rowSelection]="rowSelection" [columnDefs]="columnDefFrmCorreos" [defaultColDef]="defaultColFrmCorreos"
                [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="false"></app-aggridsaci>
</div>
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	
</div>