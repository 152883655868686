<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="tranforped"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmartranforped" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 98%">
    <div class="p-col-12">
        <app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
            [botonBuscar]="btnBuscar" [botonRegresar]="btnRegresar" [botonPrimero]="btnPrimero"
            [botonAnterior]="btnAnterior" [botonSiguiente]="btnSiguiente" [botonUltimo]="btnUltimo"
            [barraBotonesCRUD]="false" [barraBotonesDesplaz]="false" [barraBotones3]="true" [barraBotones3Alt]="false"
            [botonVisto]="false" [barraBotones5]="false" [botonVisto]="false" (signal)="manejarSenales($event)">
        </app-invmaebarra>
    </div>
</div>
<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 185px)'}">
    <div class="divgrups">
        <div class="p-grid">
            <!--LINEA 1-->
            <div class="p-col-2">
                <span>Comprobante:</span>
            </div>
            <div class="p-col-2">
                <label for="COMPROBANTE"></label>
                <input class="frm" id="COMPROBANTE" type="text" pInputText disabled
                    [(ngModel)]="transforpedService.venEnctraped[indicador].ENCTRAPED_CODIGO"
                    (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
            </div>
            <div class="p-col-3">
            </div>
            <div class="p-col-2">
                <span>Pedidos Totales:</span>
            </div>
            <div class="p-col-1">
                <label for="PEDIDOSTOTALES"></label>
                <input class="frm" id="PEDIDOSTOTALES" type="text" pInputText [(ngModel)]="TxtPedidosTo" disabled
                    autocomplete="off">
            </div>
            <!--LINEA 2-->
            <div class="p-col-12" style="margin-top: -7px">
                <div class="p-grid">
                    <div class="p-col-2">
                        <span>Fecha Emisión:</span>
                    </div>
                    <div class="p-col-2">
                        <p-calendar id="FECHAEMISION" [inputStyle]="{width: '100%', height: '20px'}"
                            [(ngModel)]="transforpedService.venEnctraped[indicador].ENCTRAPED_FECHAEMISION"
                            [readonlyInput]="true" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
                            (keydown)="botonesmodificar()">
                        </p-calendar>
                    </div>
                    <div class="p-col-3">
                    </div>
                    <div class="p-col-2">
                        <span>Facturas Generadas:</span>
                    </div>
                    <div class="p-col-1">
                        <label for="FACTURASGEN"></label>
                        <input class="frm" id="FACTURASGEN" type="text" pInputText [(ngModel)]="TxtFacturasGen" disabled
                            autocomplete="off">
                    </div>
                </div>
            </div>
            <!--LINEA 2-->
            <div class="p-col-12" style="margin-top: -12px; margin-bottom: -10px">
                <div class="p-grid">
                    <div class="p-col-2">
                        <span>Comentario:</span>
                    </div>
                    <div class="p-col-5">
                        <label for="COMENTARIO"></label>
                        <input class="frm" id="COMENTARIO" type="text" pInputText
                            [(ngModel)]="transforpedService.venEnctraped[indicador].ENCTRAPED_COMENTARIO"
                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
                    </div>
                    <div class="p-col-2">
                        <span>Pedidos por Facturar:</span>
                    </div>
                    <div class="p-col-1">
                        <label for="PEDIDOSXFAC"></label>
                        <input class="frm" id="PEDIDOSXFAC" type="text" pInputText [(ngModel)]="TxtPedidosNo" disabled
                            autocomplete="off">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="divgrups">
        <div class="p-grid">
            <!--LINEA 3-->
            <div class="p-col-12" style="margin-top: -2px">
                <div class="p-grid">
                    <div class="p-col-1"></div>
                    <div class="p-col-1">
                        <span>Campo</span>
                    </div>
                    <div class="p-col-3">
                    </div>
                    <div class="p-col-2">
                        <span>Criterio de búsqueda</span>
                    </div>
                </div>
            </div>
            <div class="p-col-12" style="margin-top: -20px">
                <div class="p-grid">
                    <div class="p-col-1"></div>
                    <div class="p-col-2">
                        <p-dropdown [options]="cmbInCriteria" [style]="{'minWidth': '100%', 'width':'100%'}"
                            optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectcombo">
                        </p-dropdown>
                    </div>
                    <div class="p-col-2">
                    </div>
                    <div class="p-col-5">
                        <label for="CRITERIOBUSQ"></label>
                        <input class="frm" id="CRITERIOBUSQ" type="text" pInputText [(ngModel)]="txtCriteria"
                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
                    </div>
                    <div class="p-col-2">
                        <button class="sincss" pButton (click)="traerpedidos()">Traer Pedidos </button>
                    </div>
                </div>
            </div>
            <div class="p-col-12" style="margin-top: -15px; margin-bottom: -20px">
                <div class="p-grid">
                    <div class="p-col-2">
                        <div class="p-grid">
                            <div class="p-col-2">
                                <p-checkbox name="seleccionartodo" value="" label="" binary="{{chkseltodo}}"
                                    [(ngModel)]="chkseltodo" (onChange)="selectTodosFact()">
                                </p-checkbox>
                            </div>
                            <div class="p-col-10">
                                <span>Seleccionar Todo</span>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-6">
                    </div>
                    <div class="p-col-4">
                        <div class="p-grid">
                            <div class="p-col-2">
                                <p-checkbox name="seleccionarpedido" value="" label="" binary="{{chkselpedido}}"
                                    [(ngModel)]="chkselpedido" (onChange)="selectPedidos()">
                                </p-checkbox>
                            </div>
                            <div class="p-col-10">
                                <span>Seleccionar pedidos en estado Pendiente</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="contornodetalle">
        <app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
            [rowData]="transforpedService.lstVenEncPed[indicador]" [frameworkComponents]="frameworkComponents"
            [animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
            [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true" [mostrarGuardar]="false"
            [botonBorrar]="true" [solobotoneliminar]="true" (cambios)="cambio($event)"
            (selected)="selecciontabla($event)">
        </app-aggridsaci>
    </div>
</p-scrollPanel>

<p-dialog header="Búsqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
        [busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Clave Autorización" [(visible)]="displayDialogAutorizacion" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" (onHide)="onHideAutorizacion()" [style]="{width: '40vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="autorizacion"></p-toast>
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							(keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>