import {EventEmitter, Injectable, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Venmaetransportistas} from '../../ven/veninterfaces/venmaetransportistas';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Conmaetipodiario} from '../coninterfaces/conmaetipo-diario';
import {Observable} from 'rxjs';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class ConmaetipoDiarioService {
  
  
  get contmaeipodiario(): Conmaetipodiario[] {
    return this._contmaeipodiario;
  }
  
  set contmaeipodiario(value: Conmaetipodiario[]) {
    this._contmaeipodiario = value;
  }
  
  public way: string;
  private _contmaeipodiario: Conmaetipodiario[];
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }
  
  guardarTipoDiario(tipodiario: Conmaetipodiario): Observable<any[]> {
    return this.http.post<any>(this.way + '/cont/tipodiario/41ddbedd2b775d8', {
      elementos: {
        p_TIPDIA_codigo_1: tipodiario.TIPDIA_CODIGO,
        p_TIPDIA_nombre_2: tipodiario.TIPDIA_NOMBRE,
        p_TIPDIA_nroinicial_3: tipodiario.TIPDIA_NROACTUAL,
        p_TIPDIA_nroactual_5: tipodiario.TIPDIA_NROACTUAL,
        p_COM_codigo_4: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  
  actualizarTipoDiario(tipodiario: Conmaetipodiario, codigo): Observable<any[]> {
    return this.http.post<any>(this.way + '/cont/tipodiario//dd7df2ab2299eeed', {
      elementos: {
        p_TIPDIAcodigo_1: codigo,
        p_TIPDIAcodigo_2: tipodiario.TIPDIA_CODIGO,
        p_TIPDIAnombre_3: tipodiario.TIPDIA_NOMBRE,
        p_TIPDIA_nroinicial_4: tipodiario.TIPDIA_NROACTUAL,
        p_TIPDIA_nroactual_5: tipodiario.TIPDIA_NROACTUAL,
        p_COM_codigo_6: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  
  eliminarTipoDiario(tipodiario: Conmaetipodiario): Observable<any[]> {
    return this.http.post<any>(this.way + '/cont/tipodiario/ddd75d92c00a', {
      elementos: {
        p_TIPDIAcodigo_1: tipodiario.TIPDIA_CODIGO,
        p_COM_codigo_2: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  
  

  erFindCodTipDia(codigo) {    
    return this.http.post(this.way + '/cont/tipodiario/f10eeeb7b2b775d8' , {
      elementos: {
        TIPDIA_CODIGO: codigo
      },
    }, this.confIniciales.httpOptions());
  }
  
  getTipoDiario(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cont/tipodiario/eacad2b77800', {
        elementos: {},
      }, this.confIniciales.httpOptions());
  }
  
}



 

