import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import { NommaeEmpleado } from '../nominterfaces/nomempleado';
import { NommaedatosadiEmp } from '../nominterfaces/nonmaedatosadi';
import { Observable } from 'rxjs';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
@Injectable({
  providedIn: 'root'
})
export class NommatdatadiService {

  public way: string;
  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) { 
     
      this.way = sessionStorage.getItem('way');
      this.usuario.identificacion;
    }
  async selectDatosadi(){
    var strSentencia="select * from nom_maedatosadi where  DATADI_ESTADO='OK'";
    return await this.http.post<any[]>(this.way + '/nom/matdatadi/e3aae6abb853680', {
      elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  async selectDatosadimat(){
    var strSentencia="select * from nom_maedatosadi where  DATADI_ESTADO='OK' and DATADI_TIPODATO!='Clob'ORDER BY DATADI_ORDEN";
    return await this.http.post<any[]>(this.way + '/nom/matdatadi/e3aad5754d95980', {
      elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
 
  actualizar(strSente){
    var strSentencia=String(strSente);
    console.log(strSentencia);
    return this.http.post<any[]>(this.way + '/nom/matdatadi/299df355dc29b2', {
      elementos: {
        sentencia: strSentencia
      }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  async selectEmpl(strEmpCodigo){
    var strSentencia="select * from nom_maeempleado where emp_codigo="+strEmpCodigo;
    return await this.http.post<any[]>(this.way + '/nom/matdatadi/e3aae63ad9ab980', {
      elementos: {
        EMP_CODIGO: strEmpCodigo
      }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
}
