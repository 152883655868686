import {Component, OnInit, ViewChild} from '@angular/core';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {CxpenccuotaService} from '../cxpservicios/cxpenccuota.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {Usuario} from '../../usuario';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {DatePipe} from '@angular/common';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {CxpCuotaVar, CxpDetCuota} from '../cxpinterfaces/cxpenccuota';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {Cxctrncobro} from '../../cxc/cxcinterfaces/cxctrncobro';
import {Invdettomafisicavar} from '../../inv/invinterfaces/invdettomafisicavar';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';

@Component({
  selector: 'app-cxpenccuota',
  templateUrl: './cxpenccuota.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class CxpenccuotaComponent implements OnInit {
  
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnEliminar: boolean;
  btnGenerarCuot: boolean;
  btnBuscar: boolean;
  
  indicador: any;
  
  // Tabla
  public frameworkComponents;
  rowStyle;
  defaultConDetCuotas;
  defaultCuotaVar;
  rowSelection = 'multiple';
  largo: string;
  
  // Variables encabezado
  cmbTipInte: any[];
  strTipInte: any;
  dblTotalDoc;
  dblInteres;
  dblTotalFinanciado = 0;
  strFechaInicio = '' ;
  strFechaDoc = '';
  bolNuevo = false;
  NUMDECSIST = this.confIniciales.getNumDecSist();
  bolCuotasPagadas = false;
  strCodigoCuota = '';
  strNumDoc = '';
  strTrnCobroNroDoc = '';
  
  // Permisos
  permisos: SegMaePermiso;
  
  // Busqueda
  displayDialogBus: boolean;
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  where: string;
  opcion: string;
  
  lblPorc = true;
  lblPorc1 = false;
  btnNroDoc = false;
  disabledTxtNroDoc = false;
  
  // botones detalle
  btnNuevoDet = false;
  btnBorrarDet = false;
  btnCancelarDet = true;
  
  btnNuevoDetVar = false;
  btnBorrarDetVar = false;
  btnCancelarDetVar = true;
  
  detalleCuotaSel: CxpDetCuota = {};
  detalleVariosSel: CxpCuotaVar = {};
  activeIndex = 0;
  disabledTab0 = false;
  disabledTab1 = false;
  
  displayAsiContable: boolean;
  NumAsiRegCont: string;

  displayDialogDocumentos = false;
  
  columnDefsDetcuotas = [
    { // 0
      headerName: 'F. Vence', field: 'DETCUOTA_FECHAVENCE', cellEditor: 'cellPrueba', width: 100,
      // editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: true,
            tamanocelda: 80,
          }
        }
    },
    { // 1
      headerName: 'Concepto', field: 'DETCUOTA_CONCEPTO', cellEditor: 'cellPrueba', width: 250,
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 25,
            opcionbuscar: true
          }
        }
    },
    { // 2
      headerName: 'Referencia', field: 'DETCUOTA_REFERENCIA', cellEditor: 'cellPrueba', width: 150,
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 200
          }
        }
    },
    { // 3
      headerName: 'Importe', field: 'DETCUOTA_IMPORTE', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right'},
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        }
    },
    { // 4
      headerName: 'Pagado', field: 'DETCUOTA_PAGADO', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right'},
      editable: false,
    },
    { // 5
      headerName: 'Saldo', field: 'DETCUOTA_SALDO', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right'},
      editable: false,
    }];
  
  columnDefsCuotaVar = [
    {
      headerName: 'Ref. Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba',
      // editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            opcionbuscar: true,
            tamanocelda: 35
          }
        }
    },
    {
      headerName: 'Descripción', field: 'CON_NOMBRE', cellEditor: 'cellPrueba',  width: 120,
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 50
          }
        }
    },
    {
      headerName: 'Importe', field: 'TRNCUOTASVAR_IMPORTE', cellEditor: 'cellPrueba', cellStyle: {textAlign: 'right'},
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            int: true,
            tamanocelda: 32,
            tienecalculos: true
          }
        }
    },
    {
      headerName: 'Cnt. Costos', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
      editable: (params) => {
        const boolEdit = this.isEditCen(params.data) ;
        return boolEdit;
      },
      
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: true,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 35,
            opcionbuscar: true
          }
        }
    }];
  
  
  constructor(public cxpCuotaService: CxpenccuotaService, private init: NuevoComponentService,
              public busquedaSer: InvbusquedaService, private  auditoriaGralService: AuditoriagralService, private permisosService: PermisosService,
              private message: MessageService, public usuario: Usuario, private errorService: ErroresBaseDatosService,
              private datePipe: DatePipe, private confirmationService: ConfirmationService,
              private confIniciales: ConfInicialesService, private utilitariosService: UtilitariosService) {
    this.agTable();
    this.cxpCuotaService.detCuotaArray[this.indicador] = [];
    this.cxpCuotaService.detCuotaVarArray[this.indicador] = [];
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    
    this.rowStyle = (params) => {
      if (params.data.DETCUOTA_COBRADA === 'S') {
        return {background: 'rgb(255, 255, 170)'};
      }
    };
    
    this.defaultConDetCuotas = {
      editable: true,
      width: 100,
      objeto: 'cxpenccuota',
      resizable: true
    };
    this.defaultCuotaVar = {
      editable: true,
      width: 100,
      objeto: 'cxpcuotavar',
      resizable: true
    };
  }
  
  ngOnInit() {
    this.largo = '580';
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.habilitarBotones(false, true, true, false, false);
    this.cxpCuotaService.detCuotaArray[this.indicador] = [];
    this.cxpCuotaService.detCuotaVarArray[this.indicador] = [];
    this.getPermisos();
    this.cmbTipInte = [];
    this.cmbTipInte.push({name: 'Porcentaje'}, {name: 'Valor'});
    this.strTipInte = this.cmbTipInte[0];
    this.cxpCuotaService.encCuotaArray[this.indicador] = {
      COM_CODIGO: '01',
      ENCCUOTA_CODIGO: '',
      ENCCUOTA_DOC: '',
      ENCFACPRO_NUMERO: '',
      TRNCOBRO_FECHATRN: new Date(),
      ENCFAC_TOTAL: '.00',
      PRO_CODIGO: '',
      PRO_NOMBRE: '',
      CON_CODIGO: '',
      ENCCUOTA_SUBTOTAL: '',
      ENCCUOTA_INTERESANUAL: '.00',
      ENCCUOTA_NROCUOTAS: '',
      ENCCUOTA_VENCECDIA: '',
      ENCCUOTA_INTERESTIPO: '',
      ENCCUOTA_INTERESVAL: '.00',
      INTERES_CALC: '',
      ENCCUOTA_FECHAINICIO: new Date(),
      ENCCUOTA_FINANCIADO: '',
      TOTAL_CUOTAS: '',
      ASI_NR0: ''
    };
  }
      //CDPJ  
      ngOnDestroy(): void {
        this.confIniciales.instanciasCuotas--;
    }
  //CDPJ
  async manejarSenales(valor) {
    if (valor === 'Buscar') {
      this.buscarRegistro();
    }
    if (valor === 'Nuevo') {
      this.nuevoRegistro();
    }
    if (valor === 'Generar') {
      this.generarCuotas();
    }
    if (valor === 'Guardar') {
      if (this.activeIndex === 0) {
        this.guardarRegistro();
      }
  
      if (this.activeIndex === 1) {
        this.guardarRegistroVarios();
      }
     
    }
    if (valor === 'Borrar') {
      this.eliminarRegistro();
    }
    if (valor === 'Cancelar') {
      this.cancelarRegistro();
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  
    if (valor === 'Asiento') {
      this.generarAsientoContable();
    }
  
    if (valor === 'AsientoVer') {
      if (this.cxpCuotaService.encCuotaArray[this.indicador].ASI_NR0 !== '') {
        this.NumAsiRegCont = this.cxpCuotaService.encCuotaArray[this.indicador].ASI_NR0;
        this.displayAsiContable = true;
      } else {
        this.mensajeAlerta('info', 'Información', 'No ha generado el Asiento Contable');
      }
    }

    if (valor === 'Almacenardoc') {
      if (this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO !== '') {
        const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.message.add({
            key: 'cxpcuota',
            severity: 'info',
            summary: 'Información',
            detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
          });
        }
        
      }
    }
    
  }
  
  async generarAsientoContable() {
  
    let strFecha = this.cxpCuotaService.encCuotaArray[this.indicador].TRNCOBRO_FECHATRN;
    if (strFecha.toString().includes('-')) {
      strFecha  = this.datePipe.transform(strFecha, 'dd/MM/yyyy');
    }
    const dblTotalVarios = this.obtenerTotalVarios();
    const dblTotalDoc = Number(this.cxpCuotaService.encCuotaArray[this.indicador].INTERES_CALC);
    
    if (dblTotalDoc === 0) {
      return;
    }
    if (String(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO).length > 0) {
      if ( dblTotalDoc === dblTotalVarios ) {
        try {
          
          await this.cxpCuotaService.generarAsientoContable(
            this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO, 'AAI');
          // Recuperar el número de asiento
          const strSentencia = 'SELECT ASI_NRO FROM CXP_ENCCUOTA WHERE ENCCUOTA_CODIGO = \'' +
            String(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO).trim() + '\'';
          const rs = await this.cxpCuotaService.getAsiNrocuota(String(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO).trim());
          
          if (rs !== null) {
            this.cxpCuotaService.encCuotaArray[this.indicador].ASI_NR0 = rs[0].ASI_NRO === null ? '' : rs[0].ASI_NRO;
  
          }
  
          this.auditoriaGralService.registrarAuditoria('CON_ENCASI_L',
            this.cxpCuotaService.encCuotaArray[this.indicador].ASI_NR0 = rs[0].ASI_NRO + '/CXP/CUOTAS', 'I',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });
          
          if (String(this.cxpCuotaService.encCuotaArray[this.indicador].ASI_NR0 = rs[0].ASI_NRO).length > 0 ) {
            this.mensajeAlerta('info', 'Información', 'El Asiento Contable se generó correctamente');
          }
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', 'No se generó el Asiento Contable ' + mensaje);
        }
      } else {
        this.mensajeAlerta('info', 'Información', 'El total de Varios es diferente al interes, no se puede generar el Asiento Contable');
      }
    } else {
      this.mensajeAlerta('info', 'Información', 'Escoja un documento, para generar el Asiento Contable');
    }
  }
  
  async guardarRegistroVarios() {
    const dia = this.cxpCuotaService.encCuotaArray[this.indicador].TRNCOBRO_FECHATRN.getDate();
    const mes = this.cxpCuotaService.encCuotaArray[this.indicador].TRNCOBRO_FECHATRN.getMonth();
    const anio = this.cxpCuotaService.encCuotaArray[this.indicador].TRNCOBRO_FECHATRN.getFullYear();
    if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === true) {
      if (String(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO).length > 0 ) {
        this.aggrid.gridApi.stopEditing();
        if (await this.insertarVarios(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO)) {
          this.habilitarBotones(false, true, false, true, false);
          this.btnNuevoDetVar = false;
          this.btnBorrarDetVar = false;
          this.btnCancelarDetVar = true;
        }
      } else {
        this.mensajeAlerta('error', 'Error', 'Primero guarde el documento ya que el comprobante es obligatorio.');
        return;
      }
    }
  }
  
  async insertarVarios(strNumDoc) {
    let bolInsertar = true;
    let dblTotalDoc = 0;
  
    dblTotalDoc = Number(this.cxpCuotaService.encCuotaArray[this.indicador].INTERES_CALC);
    const dblTotalVarios = this.obtenerTotalVarios();
    let dblDiferencia = Math.abs(dblTotalDoc) - Math.abs(dblTotalVarios);
    dblDiferencia =  Number(Number(dblDiferencia).toFixed(2));
  
    if (this.cxpCuotaService.detCuotaVarArray[this.indicador].length > 0 &&
      String(this.cxpCuotaService.detCuotaVarArray[this.indicador][0].CON_CODIGO).length > 0 ) {
      if ( Math.abs(dblTotalDoc) === Math.abs(dblTotalVarios) ) {
        await this.eliminarVarios( strNumDoc );
        for (let fila = 0; fila < this.cxpCuotaService.detCuotaVarArray[this.indicador].length; fila++) {
          let item: CxpCuotaVar = {};
          item = this.cxpCuotaService.detCuotaVarArray[this.indicador][fila];
          
          try {
            
            await this.cxpCuotaService.insertarVarios(strNumDoc, Number(fila + 1), item.CON_CODIGO, item.CON_NOMBRE, item.CEN_CODIGO,
              Number(item.TRNCUOTASVAR_IMPORTE));
            bolInsertar = true;
          
            if ( fila === 0) {
              this.auditoriaGralService.registrarAuditoria('CXP_TRNCUOTASVAR', strNumDoc + '/CXP', 'I',
                this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
              });
            }
          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('error', 'Error', 'La información no se grabó correctamente.' + mensaje);
            bolInsertar = false;
          }
        }
      } else {
        this.aggrid.gridApi.stopEditing();
        this.mensajeAlerta('info', 'Información', 'El Total de Varios debe ser igual al valor de los intéreses ' + Math.abs(dblDiferencia));
        bolInsertar = false;
      }
    } else if (this.cxpCuotaService.detCuotaVarArray[this.indicador].length === 0 ) {
      const strSentencia = ' DELETE FROM CXP_TRNCUOTASVAR WHERE ENCCUOTA_CODIGO = \'' + strNumDoc + '\' AND COM_CODIGO = \'01\'';
      try {
        await this.cxpCuotaService.eliminarTrnCuotasVar(strNumDoc);
      } catch (err) {
        this.mensajeAlerta('info', 'Información', 'La información no se grabó correctamente. ');
        bolInsertar = false;
      
      }
    }
    return bolInsertar;
  }
  
  async eliminarVarios( strNumDoc ) {
    const strSql = 'DELETE FROM CXP_TRNCUOTASVAR WHERE ENCCUOTA_CODIGO = \'' + strNumDoc + '\' AND COM_CODIGO =\'01\'';
   
    try {
      await this.cxpCuotaService.eliminarTrnCuotasVar(strNumDoc);
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede eliminar los varios para la actualización ' + mensaje);
      
    }
  }
  
  obtenerTotalVarios(): number {
    let dblTotalVarios = 0;
  
    for (const item of this.cxpCuotaService.detCuotaVarArray[this.indicador]) {
    
      dblTotalVarios = dblTotalVarios + Number(item.TRNCUOTASVAR_IMPORTE);
    }
  
    dblTotalVarios = Number(Number(dblTotalVarios).toFixed(2));
  
    return  dblTotalVarios;
  }
  
  cancelarRegistro() {
    let strFiltro = '';
    
    if (String(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO) !== '') {
      strFiltro = 'CXP_ENCCUOTA.COM_CODIGO = \'01\'';
      this.navegarDocumento('R', strFiltro + ' AND CXP_ENCCUOTA.enccuota_codigo = \'' +
        this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO + '\'');
    } else {
      this.nuevoRegistro();
      this.habilitarBotones(false, true, false, true, false);
    }
  }
  
  async eliminarRegistro() {
    const dia = this.cxpCuotaService.encCuotaArray[this.indicador].TRNCOBRO_FECHATRN.getDate();
    const mes = this.cxpCuotaService.encCuotaArray[this.indicador].TRNCOBRO_FECHATRN.getMonth();
    const anio = this.cxpCuotaService.encCuotaArray[this.indicador].TRNCOBRO_FECHATRN.getFullYear();
    if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === true) {
      if (this.permisos !== null ) {
        if (this.permisos.PERTODO === 0 ) {
          if (this.permisos.PERELIMACION === 0 ) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ', no tiene permiso de eliminación. Consulte con el Administrador del Sistema.');
            return;
          }
        }
      }
      
      if (String(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO).length > 0 ) {
        
        this.confirmationService.confirm({
          message: '¿ Está seguro de eliminar el documento ?',
          header: 'Pregunta',
          icon: 'pi pi-exclamation-triangle',
          key: 'cxpcuotasconf',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.eliminar();
          },
          reject: () => {
          }
        });
      } else {
        this.mensajeAlerta('info', 'Información', 'No hay un documento para la eliminación');
      }
      
    }
  }
  
  async eliminar() {
    if (await this.eliminarDocumento()) {// eliminar las facturas generadas por cada cuota
      this.actualizarEstadoFacturaProv(this.cxpCuotaService.encCuotaArray[this.indicador].PRO_CODIGO,
        this.cxpCuotaService.encCuotaArray[this.indicador].ENCFACPRO_NUMERO);
      this.limpiarDatosDocumento();
      this.habilitarBotones(false, true, true, false, false);
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      this.mensajeAlerta('success', 'Eliminación', 'Documento eliminado exitosamente.... !!!');
    }
  }
  
  async eliminarDocumento() {
    
    try {
      await this.cxpCuotaService.eliminarDocumento();
      this.auditoriaGralService.registrarAuditoria('CXP_ENCCUOTA', this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO, 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso eliminar datos no terminó satisfactoriamente.... ! ' + mensaje);
      return false;
    }
    return true;
  }
  
  async guardarRegistro() {
    if (this.bolNuevo) { // nuevo documento
      if (this.permisos !== null ) {
        if (this.permisos.PERTODO === 0 ) {
          if (this.permisos.PERINSERCION === 0 ) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.');
            return;
          }
        }
      }
    } else { // modificar el documento
      if (this.permisos !== null ) {
        if (this.permisos.PERTODO === 0 ) {
          if (this.permisos.PERACTUALIZACION === 0 ) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
            return;
          }
        }
      }
    }
    
    const dia = this.cxpCuotaService.encCuotaArray[this.indicador].TRNCOBRO_FECHATRN.getDate();
    const mes = this.cxpCuotaService.encCuotaArray[this.indicador].TRNCOBRO_FECHATRN.getMonth();
    const anio = this.cxpCuotaService.encCuotaArray[this.indicador].TRNCOBRO_FECHATRN.getFullYear();
    if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
      this.mensajeAlerta('error', 'Error', 'La Fecha del Documento Debe estar dentro del Periodo Contable.');
      return;
    }
    
    if ( this.faltaInformacion() )  {
      return;
    }
    
    this.calcularTotalCuotas();
    
    if (Number(Number(this.dblTotalFinanciado).toFixed(2)) !== Number(Number(this.cxpCuotaService.encCuotaArray[this.indicador].TOTAL_CUOTAS).toFixed(2))) {
      this.mensajeAlerta('info', 'Información', 'El total financiado es distinto al total de las cuotas.');
      return;
    }
    
    if (this.cxpCuotaService.detCuotaArray[this.indicador].length === 0 ) {
      this.mensajeAlerta('error', 'Error', 'Genere la tabla de cuotas antes de guardar el documento.');
      return;
    }
    
    this.eliminarUltimaLineaCuota();
    this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_NROCUOTAS = this.cxpCuotaService.detCuotaArray[this.indicador].length;
    if (await this.insertarEncabezadoDoc()) {
      this.disabledTxtNroDoc = true;
      this.btnNroDoc = true;
      
      this.habilitarBotones(false, true, false, true, false);
      this.mensajeAlerta('success', 'Información', 'Documento guardado exitosamente.... !!!');
      
      // if ( DlgModulos.prefijo.equals("VEN") )
      //   shell.close();
    }
    
  }
  
  async insertarEncabezadoDoc() {
    let bolInsertar = false;
    const strProceso = 'CXP_PCK_ENCCUOTA.CXP_SP_insertarENCCUOTA';
    let strNuevo = '';
    let strAnio = '';
    
    const fecha = this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_FECHAINICIO;
    const fechaIni = fecha.toLocaleString('en-GB').split(',');
    
    const indexCmb = this.cmbTipInte.findIndex(index => index.name === this.strTipInte.name);
    let parametro5;
    if (indexCmb === 0) {
      parametro5 = Number(this.cxpCuotaService.encCuotaArray[this.indicador].INTERES_CALC);
    } else {
      parametro5 = Number(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_INTERESVAL);
    }
    let parametro6;
    if (indexCmb === 1) {
      parametro6 = Number(this.cxpCuotaService.encCuotaArray[this.indicador].INTERES_CALC);
    } else {
      parametro6 = Number(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_INTERESVAL);
    }
    
    strAnio = fecha.getFullYear();
    let parametro14;
    if (indexCmb === 0) {
      parametro14 = 'PORCE';
    } else {
      parametro14 = 'VAL';
    }
    
    try {
      const rs = await this.cxpCuotaService.insertarEncabezadoDoc(
        this.cxpCuotaService.encCuotaArray[this.indicador].ENCFACPRO_NUMERO,
        this.cxpCuotaService.encCuotaArray[this.indicador].PRO_CODIGO,
        fechaIni[0],
        this.dblTotalFinanciado,
        parametro5,
        parametro6,
        Number(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_FINANCIADO),
        Number(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_NROCUOTAS),
        Number(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_VENCECDIA),
        strAnio,
        0,
        Number(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_SUBTOTAL),
        Number(this.cxpCuotaService.encCuotaArray[this.indicador].ENCFAC_TOTAL),
        parametro14,
        0,
        this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO,
        Number(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_INTERESANUAL)
      );
      
      bolInsertar = true;
      
      if (rs !== null ) {
        // ELIMINA EL DETALLE DE LAS CUOTAS ANTES DE MODIFICAR
        if (String(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO).length > 0 ) {
          strNuevo = 'A';
          bolInsertar = await this.eliminarDetalleDoc( false );
          // ELIMINA LAS FACTURAS GENERADAS POR LAS CUOTAS
          if ( bolInsertar ) {
            bolInsertar = await this.eliminarFactCuotas();
          }
        } else {
          strNuevo = 'N';
        }
        
        if ( !bolInsertar ) {
          return bolInsertar;
        }
        
        this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO = rs[0].ENCCUOTA_CODIGO;
        bolInsertar = await this.insertarDetalleDoc(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO);
      }
      if (strNuevo !== 'A') {
        this.auditoriaGralService.registrarAuditoria('CXP_ENCCUOTA', this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_DOC
          + '/' + this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO, 'A',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
        this.actualizarEstadoFacturaProv(this.cxpCuotaService.encCuotaArray[this.indicador].PRO_CODIGO,
          this.cxpCuotaService.encCuotaArray[this.indicador].ENCFACPRO_NUMERO);
      }
      
      if (strNuevo !== 'N' ) {
        this.auditoriaGralService.registrarAuditoria('CXP_ENCCUOTA', this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_DOC
          + '/' + this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO, 'I',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
        
        this.actualizarEstadoFacturaProv(this.cxpCuotaService.encCuotaArray[this.indicador].PRO_CODIGO,
          this.cxpCuotaService.encCuotaArray[this.indicador].ENCFACPRO_NUMERO);
      }
      
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'La información no se grabó correctamente.' + mensaje);
    }
    return bolInsertar;
  }
  
  async insertarDetalleDoc(strCodigoCuota) {
    let bolInsertar = false;
    let strCodigoCXP = '';
    
    for (let fila = 0; fila < this.cxpCuotaService.detCuotaArray[this.indicador].length; fila++ ) {
      const item: CxpDetCuota = this.cxpCuotaService.detCuotaArray[this.indicador][fila];
      
      const fecha = this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_FECHAINICIO;
      const fechaV = item.DETCUOTA_FECHAVENCE;
      const fechaVence = fechaV.toLocaleString('en-GB').split(',');
      
      try {
        const rs = await this.cxpCuotaService.insertarDetalleDoc(
          this.cxpCuotaService.encCuotaArray[this.indicador].ENCFACPRO_NUMERO,
          this.cxpCuotaService.encCuotaArray[this.indicador].PRO_CODIGO,
          fecha.getFullYear(),
          item.DETCUOTA_NROLINEA,
          fechaVence[0],
          item.DETCUOTA_CONCEPTO,
          item.DETCUOTA_REFERENCIA === '' ? '*' : item.DETCUOTA_REFERENCIA,
          Number(item.DETCUOTA_IMPORTE),
          Number(item.DETCUOTA_PAGADO),
          Number(item.DETCUOTA_SALDO),
          Number(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_NROCUOTAS),
          item.DETCUOTA_NROLINEA,
          strCodigoCuota
        );
        
        bolInsertar = true;
        // crear la factura por cada cuota generada
        if ( bolInsertar ) {
          const itemFac: Cxctrncobro = this.llenarItem(item);
          strCodigoCXP = await this.guardarCuentaXPagar(itemFac);
          
          if (strCodigoCXP !== '') {
            bolInsertar = await this.actualizarNroFactura(strCodigoCXP, strCodigoCuota,
              item.DETCUOTA_NROLINEA); // actualiza el número de factura en las cuotas
            if ( !bolInsertar ) {
              break;
            } else {
              this.auditoriaGralService.registrarAuditoria('CXP_DETCUOTA', strCodigoCXP + '/' + item.DETCUOTA_NROLINEA, 'I',
                this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
              });
            }
          } else {
            break;
          }
        }
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', 'La información no se grabó correctamente. ' + mensaje);
        break;
      }
    }
    // INSERTAR LA CANCELACION DE LA FACTURA ORIGINAL
    if (this.bolNuevo) {
      const itemCA: Cxctrncobro = this.llenarItemCancelacionAbono();
      strCodigoCXP = await this.guardarCuentaXPagar(itemCA);
      
      if ( strCodigoCXP !== '') {
        itemCA.TRNCOBRO_NRODOC = this.cxpCuotaService.encCuotaArray[this.indicador].ENCFACPRO_NUMERO; // número de la factura
        if (await this.guardarTrnDocumento(strCodigoCXP)) {
          this.actualizarEstadoFacturaProv(this.cxpCuotaService.encCuotaArray[this.indicador].PRO_CODIGO,
            this.cxpCuotaService.encCuotaArray[this.indicador].ENCFACPRO_NUMERO);
          this.bolNuevo = false;
        }
      }
    }
    
    return bolInsertar;
  }
  
  async actualizarEstadoFacturaProv(strCodProv, strTrnCobroNroDoc) {
    try {
      await this.cxpCuotaService.actualizarEstadoFacturaProv(strCodProv, strTrnCobroNroDoc);
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede actualizar el número de la factura ' + mensaje);
    }
  }
  
  async guardarTrnDocumento(strTrnCobroNroDoc) {
    try {
      await this.cxpCuotaService.guardarTrnDocumento(strTrnCobroNroDoc, this.dblTotalDoc);
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede actualizar el número de la factura ' + mensaje);
      return false;
    }
    
    return true;
  }
  
  llenarItemCancelacionAbono() {
    const item: Cxctrncobro = {};
    let strFechaInicia = '';
    
    const fecha = this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_FECHAINICIO;
    strFechaInicia = fecha.toLocaleString('en-GB').split(',');
    
    item.APLORG_CODIGO = 'SISTEMA';
    item.ASI_NRO = '';
    item.BAN_CODIGO = '';
    item.CLI_CODIGO = this.cxpCuotaService.encCuotaArray[this.indicador].PRO_CODIGO;
    item.CLI_NOMBRE = this.cxpCuotaService.encCuotaArray[this.indicador].PRO_NOMBRE;
    item.COM_CODIGO = '01';
    item.TRNCOBRO_CONCEPTO = 'CANCELACION POR CUOTAS FACT. ' + this.strNumDoc;
    item.TRNCOBRO_FECHATRN = strFechaInicia[0];
    item.TRNCOBRO_FECHAVENCE = '';
    item.TRNCOBRO_FLAG = 1;
    item.TRNCOBRO_IMPORTE = this.dblTotalDoc;
    item.TRNCOBRO_NROCOMPROBANTE = '';
    item.TRNCOBRO_NRODOC = '';
    item.TRNCOBRO_REFERENCIAEXTERNA = this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO;
    item.USU_IDENTIFICACION = this.usuario.identificacion;
    item.VEN_CODIGO = '';
    item.TRNCOBRO_NRODOC_CXP = 'F';
    item.CON_CODIGO = this.cxpCuotaService.encCuotaArray[this.indicador].CON_CODIGO;
    
    // si el total del documento es distinto al saldo del documento, es un abono caso contrario es una cancelacion
    if ( Number(this.cxpCuotaService.encCuotaArray[this.indicador].TOTAL_CUOTAS) >=
      Number(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_SUBTOTAL)) {
      item.TRNCOBRO_TIPODOC = 'CA';
    } else {
      item.TRNCOBRO_TIPODOC = 'AB';
    }
    
    return item;
  }
  
  async actualizarNroFactura(strNumFact, strEncCuota, strLinea) {
    let bolAct = false;
    const strSql = 'UPDATE CXP_DETCUOTA SET TRNCOBRO_NRODOC = \'' + strNumFact + '\' ' +
      'WHERE COM_CODIGO = \'01\' AND ENCCUOTA_CODIGO = \'' + strEncCuota + '\'' +
      ' AND DETCUOTA_NROLINEA =\'' +  strLinea + '\'';
    
    try {
      await this.cxpCuotaService.actualizarNumFactura(strNumFact, strEncCuota, strLinea);
      bolAct = true;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede actualizar el número de la factura ' + mensaje);
    }
    
    return bolAct;
  }
  
  async guardarCuentaXPagar(item: Cxctrncobro) {
    let strNuevoCodigoCXP = '';
    try {
      const rs = await this.cxpCuotaService.guardarCuentaXPagar(item);
      strNuevoCodigoCXP = rs === null ? '' : rs[0][':B1'];
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso guardar Cuenta por Pagar no terminó satisfactoriamente.... ! ' + mensaje);
    }
    return strNuevoCodigoCXP;
  }
  
  llenarItem(itemC: CxpDetCuota) {
    const item: Cxctrncobro = {};
    let strFechaInicia = '';
    let strNumero = '';
    
    if (String(itemC.DETCUOTA_NROLINEA).length === 1) {
      strNumero = '0' + itemC.DETCUOTA_NROLINEA;
    } else {
      strNumero = String(itemC.DETCUOTA_NROLINEA);
    }
    
    const fecha = this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_FECHAINICIO;
    strFechaInicia = fecha.toLocaleString('en-GB').split(',');
    
    item.APLORG_CODIGO = 'SISTEMA';
    item.ASI_NRO = '';
    item.CLI_CODIGO = this.cxpCuotaService.encCuotaArray[this.indicador].PRO_CODIGO;
    item.COM_CODIGO = '01';
    item.TRNCOBRO_CONCEPTO = itemC.DETCUOTA_CONCEPTO;
    item.TRNCOBRO_FECHATRN = strFechaInicia[0];
    item.TRNCOBRO_FECHAVENCE = itemC.DETCUOTA_FECHAVENCE;
    item.TRNCOBRO_FLAG = 1;
    item.TRNCOBRO_NRODOC = this.cxpCuotaService.encCuotaArray[this.indicador].ENCFACPRO_NUMERO + '-' + strNumero + '/' +
      this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_NROCUOTAS;
    
    item.TRNCOBRO_NROCOMPROBANTE = this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_DOC + '-' + strNumero + '/' +
      this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_NROCUOTAS;
    item.TRNCOBRO_TIPODOC = 'FC';
    item.USU_IDENTIFICACION = this.usuario.identificacion;
    item.VEN_CODIGO = '';
    item.TRNCOBRO_SALDOINI = 0;
    item.TRNCOBRO_IMPORTE = itemC.DETCUOTA_IMPORTE;
    item.TRNCOBRO_REFERENCIAEXTERNA = this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO;
    item.CON_CODIGO = this.cxpCuotaService.encCuotaArray[this.indicador].CON_CODIGO;
    
    return item;
  }
  
  async eliminarFactCuotas() {
    let bolEliminar = true;
    let strCondicion = '';
    
    // eliminar las facturas creadas por cada línea del detalle de las cuotas
    
    strCondicion = 'delete from cxp_trncobro where ';
    strCondicion = strCondicion +  ' TRNCOBRO_REFERENCIAEXTERNA = \'' + this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO +
      '\' AND COM_CODIGO = \'01\' AND TRNCOBRO_TIPODOC = \'FC\'';
    
    try {
      await this.cxpCuotaService.eliminarFactCuotas(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO);
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
      bolEliminar = false;
    }
    
    return bolEliminar;
  }
  
  async eliminarDetalleDoc(rollback ) {
    let bolEliminar = true;
    
    try {
      const rs = await this.cxpCuotaService.eliminarDetalleDoc(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO);
      this.auditoriaGralService.registrarAuditoria('CXP_DETCUOTA', this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO, 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede eliminar la información ' + mensaje);
      bolEliminar = false;
    }
    return bolEliminar;
  }
  
  eliminarUltimaLineaCuota() {
    let intIndiceLista = 0;
    let item: CxpDetCuota = {};
    for (intIndiceLista = 0; intIndiceLista < this.cxpCuotaService.detCuotaArray[this.indicador].length; intIndiceLista++ ) {
      item = this.cxpCuotaService.detCuotaArray[this.indicador][intIndiceLista];
      if (Number(item.DETCUOTA_IMPORTE) === 0 ) {
        this.cxpCuotaService.detCuotaArray[this.indicador].splice(this.cxpCuotaService.detCuotaArray[this.indicador].findIndex(
          cuota => cuota.DETCUOTA_NROLINEA === this.detalleCuotaSel.DETCUOTA_NROLINEA),
          1);
        this.aggrid.refreshaggrid(this.cxpCuotaService.detCuotaArray[this.indicador], this.defaultConDetCuotas.objeto);
      }
    }
    if (this.cxpCuotaService.detCuotaArray[this.indicador].length === 0 ) {
      this.btnNuevoDet = false;
      this.btnCancelarDet = true;
      this.btnBorrarDet = false;
    }
  }
  
  
  async getPermisos() {
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisos = data[0];
        } else {
          this.permisos = null;
        }
      } else {
        this.permisos = null;
      }
    } catch (e) {
      this.permisos = null;
      this.mensajeAlerta('error', 'Error', 'No se pudo obtener los permisos del formulario');
    }
  }
  
  nuevoRegistro() {
    if (this.permisos !== null ) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERINSERCION === 0 ) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }
    
    this.limpiarTabla();
    
    this.limpiarDatosDocumento();
    document.getElementById('divPrincipal').style.pointerEvents = 'all';
    document.getElementById('documento').style.pointerEvents = 'all';
    document.getElementById('cuotas').style.pointerEvents = 'all';
    document.getElementById('ENCCUOTA_DOC').focus();
    this.habilitarBotones(true, false, true,  true, true);
    this.bolNuevo = true;
    this.activeIndex = 0;
  }
  
  limpiarTabla() {
    this.cxpCuotaService.detCuotaVarArray[this.indicador] = [];
  }
  
  limpiarDatosDocumento() {
    
    this.cxpCuotaService.encCuotaArray[this.indicador] = {
      COM_CODIGO: '01',
      ENCCUOTA_CODIGO: '',
      ENCCUOTA_DOC: '',
      ENCFACPRO_NUMERO: '',
      TRNCOBRO_FECHATRN: new Date(),
      ENCFAC_TOTAL: '',
      PRO_CODIGO: '',
      PRO_NOMBRE: '',
      CON_CODIGO: '',
      ENCCUOTA_SUBTOTAL: '',
      ENCCUOTA_INTERESANUAL: '.00',
      ENCCUOTA_NROCUOTAS: '',
      ENCCUOTA_VENCECDIA: '',
      ENCCUOTA_INTERESTIPO: '',
      ENCCUOTA_INTERESVAL: '.00',
      INTERES_CALC: '.00',
      ENCCUOTA_FECHAINICIO: new Date(),
      ENCCUOTA_FINANCIADO: '.00',
      TOTAL_CUOTAS: '',
      ASI_NR0: ''
    };
    
    this.strTipInte = this.cmbTipInte[0];
    this.cxpCuotaService.detCuotaArray[this.indicador] = [];
    this.disabledTxtNroDoc = false;
    this.btnNroDoc = false;
    this.bolCuotasPagadas = false;
    this.strCodigoCuota = '';
  
    this.cxpCuotaService.detCuotaVarArray[this.indicador] = [];
  }
  
  buscarRegistro() {
    if ( this.permisos !== null ) {
      if ( this.permisos.PERTODO === 0 ) {
        if ( this.permisos.PERSELECCION === 0 ) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion + ', ' +
            'no tiene permiso de consulta. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }
    
    this.opcion = 'BuscarCuotas';
    this.types = [
      {label: 'ENCFACPRO_NÚMERO', value: 'ENCFACPRO_NUMERO'},
      {label: 'PRO_CÓDIGO', value: 'PRO_CODIGO'},
      {label: 'PRO_NOMBRE', value: 'PRO_NOMBRE'},
      {label: 'ENCCUOTA_FECHAINICIO', value: 'ENCCUOTA_FECHAINICIO'},
      {label: 'ENCCUOTA_AÑO', value: 'ENCCUOTA_ANIO'},
      {label: 'ENCCUOTA_NROCUOTAS', value: 'ENCCUOTA_NROCUOTAS'},
      {label: 'ENCCUOTA_VENCECDIA', value: 'ENCCUOTA_VENCECDIA'},
      {label: 'ENCFAC_TOTAL', value: 'ENCFAC_TOTAL'},
      {label: 'ENCCUOTA_ABONO', value: 'ENCCUOTA_ABONO'},
      {label: 'ENCCUOTA_SUBTOTAL', value: 'ENCCUOTA_SUBTOTAL'},
      {label: 'ENCCUOTA_TOTAL', value: 'ENCCUOTA_TOTAL'},
      {label: 'ENCCUOTA_INTERESVAL', value: 'ENCCUOTA_INTERESVAL'},
      {label: 'ENCCUOTA_INTERESPORCE', value: 'ENCCUOTA_INTERESPORCE'},
      {label: 'ENCCUOTA_FINANCIADO', value: 'ENCCUOTA_FINANCIADO'},
      {label: 'ENCCUOTA_INTERESTIPO', value: 'ENCCUOTA_INTERESTIPO'},
      {label: 'ENCCUOTA_INTERESANUAL', value: 'ENCCUOTA_INTERESANUAL'},
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
    this.consulta = 'ENCCUOTA_CODIGO, ENCFACPRO_NUMERO, PRO_CODIGO,COM_FNC_NOMBRE_PROVEEDOR(PRO_CODIGO) as PRO_NOMBRE,ENCCUOTA_FECHAINICIO,' +
      '       ENCCUOTA_ANIO,ENCCUOTA_NROCUOTAS, ENCCUOTA_VENCECDIA,ENCFAC_TOTAL,ENCCUOTA_ABONO,ENCCUOTA_SUBTOTAL,' +
      '       ENCCUOTA_TOTAL,ENCCUOTA_INTERESVAL,ENCCUOTA_INTERESPORCE,ENCCUOTA_FINANCIADO,ENCCUOTA_INTERESTIPO,ENCCUOTA_INTERESANUAL';
    this.tabla = 'CXP_ENCCUOTA';
    this.where = '';
    
    this.busquedaSer.busquedaCXPEnccuota1().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.arregloCons.map((dato) => {
        if (dato.ENCCUOTA_FECHAINICIO !== null) {
          dato.ENCCUOTA_FECHAINICIO = this.datePipe.transform(dato.ENCCUOTA_FECHAINICIO, 'dd/MM/yyyy');
        } else {
          dato.ENCCUOTA_FECHAINICIO = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });
    
  }
  
  async buscarDocumentosPend() {
    // this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_DOC = '';
    
    let strNumDocPend = '';
    const strValorCajTexto = this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_DOC;
    
    
    if (strValorCajTexto.length > 0)	{ // si ingreso de datos en la caja de texto
      try {
        const rs = await this.cxpCuotaService.erTrnCobroNroDoc(strValorCajTexto);
        strNumDocPend = rs === null ? '' : rs[0].TRNCOBRO_NRODOC;
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', 'No se puede consultar los documentos pendientes.' + mensaje);
      }
    }
    
    this.limpiarDatosDocumento();
    this.buscarDocPendDlg(strNumDocPend, strValorCajTexto);
    // this.opcion = 'BuscarDocPend';
    // this.types = [
    //   {label: 'NUMERO', value: 'TAR_CODIGO'},
    //   {label: 'DESCRIPCION', value: 'TAR_DESCRIPCION'},
    //   {label: '%', value: 'TAR_PORCENTAJE'},
    //   {label: 'R.C.COMISION', value: 'CON_CODIGO'},
    //   {label: 'RET.FUENTE', value: 'CON_CODIGORFTE'},
    //   {label: 'RET.IVA', value: 'CON_CODIGOFIVA'},
    //   {label: 'RUC', value: 'TAR_RUC'},
    //   {label: 'CODSRI', value: 'TAR_CODSRI'},
    // ];
    // this.busquedacampos = [parametro, '', '', '', '', '', '', ''];
    // this.consulta = 'TRNCOBRO_NROCOMPROBANTE ,trncobro_nrodoc as REFERENCIA, pro_codigo,COM_FNC_NOMBRE_PROVEEDOR(PRO_CODIGO) as PRO_NOMBRE,' +
    //   'trncobro_importe, cxp_fnc_saldodocumento(trncobro_nrodoc,com_codigo,pro_codigo) as SALDO_DOC, trncobro_concepto,' +
    //   'trncobro_tipodoc,trncobro_nrodoc,APLORG_CODIGO';
    // this.tabla = 'cxp_trncobro';
    // this.where = '';
    //
    // this.cxpCuotaService.encontrarRegistroo(this.consulta, this.tabla, this.types[0].value +
    //   ' LIKE \'' + parametro + '\'').subscribe(eR => {
    //   if (eR !== null) {
    //     if (eR[0] !== undefined) {
    //       this.manejarSeleccion(eR[0]);
    //     } else {
    //       this.busquedaSer.busquedaTabla(this.consulta, this.tabla, 'ROWNUM < 100').subscribe((res: any[]) => {
    //         this.arregloCons = res;
    //         this.displayDialogBusquedaFast = true;
    //       });
    //     }
    //   } else {
    //     this.busquedaSer.busquedaTabla(this.consulta, this.tabla, 'ROWNUM < 100').subscribe((res: any[]) => {
    //       this.arregloCons = res;
    //       this.displayDialogBusquedaFast = true;
    //     });
    //   }
    // });
    
  }
  
  async buscarDocPendDlg(strNumDocPend, strValorCajTexto ) {
    
    if ( strNumDocPend.length === 0  ) { // si el valor que ingreso esta incorrecto
      
      this.opcion = 'BuscarDocPend';
      this.types = [
        {label: 'FACTURA', value: 'TRNCOBRO_NROCOMPROBANTE'},
        {label: 'REFERENCIA', value: 'REFERENCIA'},
        {label: 'CODIGO PROVEEDOR', value: 'PRO_CODIGO'},
        {label: 'NOMBRE PROVEEDOR', value: 'PRO_NOMBRE'},
        {label: 'IMPORTE', value: 'TRNCOBRO_IMPORTE'},
        {label: 'SALDO', value: 'SALDO'},
        {label: 'CONCEPTO', value: 'TRNCOBRO_CONCEPTO'},
        {label: 'TRNCOBRO_TIPODOC', value: 'TRNCOBRO_TIPODOC'},
        {label: 'TRNCOBRO_NRODOC', value: 'TRNCOBRO_NRODOC'},
        {label: 'APLORG_CODIGO', value: 'APLORG_CODIGO'}
      ];
      this.busquedacampos = ['', '', '', '', '', '', '', '', '', ''];
      this.consulta = 'TRNCOBRO_NROCOMPROBANTE ,trncobro_nrodoc  as REFERENCIA, pro_codigo,COM_FNC_NOMBRE_PROVEEDOR(PRO_CODIGO) AS PRO_NOMBRE, ' +
        '         trncobro_importe, cxp_fnc_saldodocumento(trncobro_nrodoc,com_codigo,pro_codigo) as SALDO, trncobro_concepto,' +
        '         trncobro_tipodoc,trncobro_nrodoc,APLORG_CODIGO,cxp_fnc_saldodocumento(trncobro_nrodoc,com_codigo,pro_codigo ) as SUMA';
      this.tabla = 'cxp_trncobro';
      this.where = 'SUMA > 0 AND aplorg_codigo <> \'SISTEMA\' AND trncobro_tipodoc in(\'FC\' ,\'ND\')';
      
      this.busquedaSer.busquedaCXPTrnCobro1().subscribe((res: any[]) => {
        this.arregloCons = res;
        this.displayDialogBusquedaFast = true;
      });
      
    } else {
      this.strNumDoc = String(strValorCajTexto).trim();
      this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_DOC = this.strNumDoc;
      this.cxpCuotaService.encCuotaArray[this.indicador].ENCFACPRO_NUMERO = strNumDocPend;
      if (String(this.strNumDoc).length > 0 ) {
        await this.completarDatosDoc(); // información adicional del documento
        this.calcularInteres();
      }
      if (String(this.strNumDoc).trim() !== '') { // Extrae el número de documento
        try {
          const rs = await this.cxpCuotaService.erTrnCobroNroDoc(this.strNumDoc);
          this.strTrnCobroNroDoc = rs === null ? '' : rs[0].TRNCOBRO_NRODOC;
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', 'No se puede consultar el número de documento. ' + mensaje);
        }
      }
    }
    
    
    
    
  }
  calcularInteres() {
    if ( this.dblTotalDoc === 0) {
      this.mensajeAlerta('info', 'Información', 'No se ha seleccionado ningún documento');
      return;
    }
    const indexCmb = this.cmbTipInte.findIndex(index => index.name === this.strTipInte.name);
    if (indexCmb === 0 ) { // porcentaje
      
      if (String(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_INTERESVAL).length > 0 ) {
        const dblPorcInteres = Number(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_INTERESVAL);
        this.dblInteres = (dblPorcInteres / 100) * this.dblTotalDoc;
        this.dblTotalFinanciado = this.dblTotalDoc + this.dblInteres;
      }
      
    } else {
      this.dblInteres = (Number(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_INTERESVAL) / this.dblTotalDoc) * 100;
      this.dblTotalFinanciado = this.dblTotalDoc + Number(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_INTERESVAL);
    }
    
    this.cxpCuotaService.encCuotaArray[this.indicador].INTERES_CALC = Number(this.dblInteres).toFixed(this.NUMDECSIST);
    // if ( this.dblInteres > 0 ) {
    this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_FINANCIADO = Number(this.dblTotalFinanciado).toFixed(this.NUMDECSIST);
    // }
  }
  
  async completarDatosDoc() {
    
    let strSentencia = '';
    strSentencia = ' select trncobro_nrodoc,trncobro_nrocomprobante, trncobro_importe,trncobro_fechatrn,a.pro_codigo,' +
      'pro_nombre,cxp_fnc_saldodocumento(trncobro_nrodoc,a.com_codigo,a.pro_codigo) as saldo,con_codigo1 from CXP_TRNCOBRO a, com_maeproveedor b ';
    strSentencia = strSentencia + ' where a.pro_codigo=b.pro_codigo and a.com_codigo=b.com_codigo' ;
    strSentencia = strSentencia + ' and trncobro_nrodoc=\'' + this.cxpCuotaService.encCuotaArray[this.indicador].ENCFACPRO_NUMERO + '\'';
    strSentencia = strSentencia + ' and a.com_codigo=\'01\'' ;
    
    try {
      const rs = await this.cxpCuotaService.completarDatosDoc(this.cxpCuotaService.encCuotaArray[this.indicador].ENCFACPRO_NUMERO);
      
      for (const item of rs) {
        // TOTAL DOCUMENTO
        this.dblTotalDoc = item.TRNCOBRO_IMPORTE;
        this.cxpCuotaService.encCuotaArray[this.indicador].ENCFAC_TOTAL = Number(this.dblTotalDoc).toFixed(this.NUMDECSIST);
        // FECHA
        
        this.cxpCuotaService.encCuotaArray[this.indicador].TRNCOBRO_FECHATRN = new Date(item.TRNCOBRO_FECHATRN);
        this.strFechaDoc = String(new Date(item.TRNCOBRO_FECHATRN));
        // proveedor
        this.cxpCuotaService.encCuotaArray[this.indicador].PRO_CODIGO = item.PRO_CODIGO;
        this.cxpCuotaService.encCuotaArray[this.indicador].PRO_NOMBRE = item.PRO_NOMBRE;
        
        // SALDO
        const dblSaldoDoc = await this.cxp_fnc_saldodocumento(this.cxpCuotaService.encCuotaArray[this.indicador].ENCFACPRO_NUMERO,
          this.cxpCuotaService.encCuotaArray[this.indicador].PRO_CODIGO);
        this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_SUBTOTAL = Number(dblSaldoDoc).toFixed(this.NUMDECSIST);
        this.dblTotalDoc = dblSaldoDoc;
        document.getElementById('ENCCUOTA_NROCUOTAS').focus();
        
        // TOTAL FINANCIADO
        this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_FINANCIADO = Number(dblSaldoDoc).toFixed(this.NUMDECSIST);
        this.cxpCuotaService.encCuotaArray[this.indicador].CON_CODIGO = item.CON_CODIGO1;
        
        
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso consultar Datos del documento no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }
  
  
  async cxp_fnc_saldodocumento(strTrnCobroNroDoc, strProCodigo ) {
    const strSql = 'SELECT cxp_fnc_saldodocumento(\'' + strTrnCobroNroDoc + '\',' + '\'01\', \'' + strProCodigo + '\') as SALDO FROM DUAL';
    let dblSaldo = 0;
    
    try {
      const rs = await this.cxpCuotaService.obtenerSaldoDocumento(strTrnCobroNroDoc, strProCodigo);
      if (rs !== null) {
        dblSaldo = rs[0].SALDO;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede obtener el saldo del documento ' + mensaje);
    }
    return dblSaldo;
  }
  
  
  async manejarSeleccion(valor) {
    if (this.opcion === 'BuscarCuotas') {
      if (valor.ENCCUOTA_CODIGO !== null) {
        this.navegarDocumento('R', ' CXP_ENCCUOTA.COM_CODIGO = \'01\' and  ENCFACPRO_NUMERO = \'' + valor.ENCFACPRO_NUMERO + '\'');
      }
      this.displayDialogBusquedaFast = false;
    }
    
    if (this.opcion === 'BuscarDocPend') {
      if (valor.TRNCOBRO_NROCOMPROBANTE !== null) {
        this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_DOC = valor.TRNCOBRO_NROCOMPROBANTE;
        this.cxpCuotaService.encCuotaArray[this.indicador].ENCFACPRO_NUMERO = valor.REFERENCIA;
        this.strNumDoc =  valor.TRNCOBRO_NROCOMPROBANTE;
        if (String(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_DOC).length > 0 ) {
          await this.completarDatosDoc(); // información adicional del documento
          this.calcularInteres();
        }
        if (String(this.strNumDoc).trim() !== '') { // Extrae el número de documento
          try {
            const rs = await this.cxpCuotaService.erTrnCobroNroDoc(this.strNumDoc);
            this.strTrnCobroNroDoc = rs === null ? '' : rs[0].TRNCOBRO_NRODOC;
          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('error', 'Error', 'No se puede consultar el número de documento. ' + mensaje);
          }
        }
      }
      this.displayDialogBusquedaFast = false;
    }
    this.displayDialogBusquedaFast = false;
  }
  
  async navegarDocumento(strOpcion, strCondicion ) {
    if (this.permisos !== null ) {
      if (this.permisos.PERTODO === 0 ) {
        if (this.permisos.PERSELECCION === 0 ) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion + ', ' +
            'no tiene permiso de consulta. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }
    await this.consultarDocumentos(strOpcion, strCondicion);
    await this.consultarVarios(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO);
  }
  
  async consultarVarios(strNumDoc) {
    this.cxpCuotaService.detCuotaVarArray[this.indicador] = [];
    const detalle: CxpCuotaVar[] = [];
    const strSentencia = 'select * from cxp_trncuotasvar where enccuota_codigo= \''  + strNumDoc + '\' and com_codigo=\'01\' order by trncuotasvar_linea asc ';
  
    try {
      const rs = await this.cxpCuotaService.consultarVarios(strNumDoc);
      let num = 0;
      for (const varios of rs) {
        num = num + 1;
        const item: CxpCuotaVar = {};
      
        item.ENCCUOTA_CODIGO = varios.ENCCUOTA_CODIGO;
        item.CON_CODIGO = varios.CON_CODIGO;
        item.CON_NOMBRE = varios.CON_NOMBRE;
        item.CEN_CODIGO = varios.CEN_CODIGO;
        item.COM_CODIGO = varios.COM_CODIGO;
        item.LINEA = num;
        item.TRNCUOTASVAR_LINEA = varios.TRNCUOTASVAR_LINEA;
        item.TRNCUOTASVAR_IMPORTE = Number(varios.TRNCUOTASVAR_IMPORTE).toFixed(2);
        detalle.push(item);
      }
      this.cxpCuotaService.detCuotaVarArray[this.indicador] = detalle;
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede recuperar la información de la pestaña Varios.' + mensaje);
    }
  }
  
  async consultarDocumentos(strOpcion, strCondicion ) {
    let bolEliminar = false;
    this.bolCuotasPagadas = false;
    this.bolNuevo = false;
    
    if ( await this.consultarEncabezadoCuotas( strOpcion, strCondicion ) ) {
      if ( await this.consultarDetalleCuotas(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO)) {
        this.calcularTotalCuotas();
        // verificar que no haya cuotas pagadas para permitir la edición del documento
        if ( this.bolCuotasPagadas ) {
          document.getElementById('divPrincipal').style.pointerEvents = 'all';
          document.getElementById('documento').style.pointerEvents = 'none';
          document.getElementById('cuotas').style.pointerEvents = 'none';
          bolEliminar = true;
          this.habilitarBotonesDet(true, true, true);
        } else {
          document.getElementById('divPrincipal').style.pointerEvents = 'all';
          document.getElementById('documento').style.pointerEvents = 'all';
          document.getElementById('cuotas').style.pointerEvents = 'all';
          this.habilitarBotonesDet(false, false, true);
        }
        this.disabledTxtNroDoc = true;
        this.btnNroDoc = true;
      }
      this.habilitarBotones(false, true, bolEliminar, bolEliminar === false ? true : false, false);
    }
  }
  
  calcularTotalCuotas() {
    let dblTotalCuotas = 0;
    
    for (let fila = 0; fila < this.cxpCuotaService.detCuotaArray[this.indicador].length; fila++ ) {
      dblTotalCuotas = dblTotalCuotas + Number(this.cxpCuotaService.detCuotaArray[this.indicador][fila].DETCUOTA_IMPORTE);
    }
    
    const strTotalCuotas = Number(dblTotalCuotas).toFixed(this.NUMDECSIST);
    this.cxpCuotaService.encCuotaArray[this.indicador].TOTAL_CUOTAS = strTotalCuotas;
  }
  
  async consultarDetalleCuotas(strEncCuotaCodigo) {
    let bolCorrecto = false;
    let strSentencia = '';
    strSentencia = 'select * from cxp_detcuota where enccuota_codigo=\'' + strEncCuotaCodigo + '\'';
    
    const listaCuotas: CxpDetCuota[] = [];
    let fila = 1;
    try {
      const rs = await this.cxpCuotaService.consultarDetalleCuotas(strEncCuotaCodigo);
      for (const cuota of rs) {
        const item: CxpDetCuota = {};
        item.PRO_CODIGO = cuota.PRO_CODIGO;
        item.DETCUOTA_CONCEPTO = cuota.DETCUOTA_CONCEPTO;
        if ( cuota.DETCUOTA_FECHAVENCE !== null ) {
          item.DETCUOTA_FECHAVENCE = cuota.DETCUOTA_FECHAVENCE;
          if (item.DETCUOTA_FECHAVENCE.toString().includes('-') && item.DETCUOTA_FECHAVENCE !== '') {
            item.DETCUOTA_FECHAVENCE = this.datePipe.transform(item.DETCUOTA_FECHAVENCE, 'dd/MM/yyyy');
          }
        }
        item.DETCUOTA_IMPORTE = Number(cuota.DETCUOTA_IMPORTE).toFixed(this.NUMDECSIST);
        item.DETCUOTA_NROLINEA = cuota.DETCUOTA_NROLINEA;
        item.DETCUOTA_PAGADO = Number(cuota.DETCUOTA_PAGADO).toFixed(this.NUMDECSIST);
        item.DETCUOTA_SALDO = Number(cuota.DETCUOTA_SALDO).toFixed(this.NUMDECSIST);
        item.DETCUOTA_REFERENCIA = cuota.DETCUOTA_REFERENCIA;
        item.ENCFACPRO_NUMERO = cuota.ENCFACPRO_NUMERO;
        item.ENCCUOTA_ANIO = cuota.ENCCUOTA_ANIO;
        item.TRNCOBRO_NRODOC = cuota.TRNCOBRO_NRODOC;
        if ( Number(item.DETCUOTA_SALDO) < Number(item.DETCUOTA_IMPORTE)) {
          item.DETCUOTA_COBRADA = 'S';
        } else {
          item.DETCUOTA_COBRADA = 'N';
        }
        
        listaCuotas.push(item);
        // verificar si existen cuotas pagadas
        
        if ( !this.bolCuotasPagadas && item.DETCUOTA_COBRADA === 'S' ) {
          this.bolCuotasPagadas = true;
        }
        
        fila = fila + 1;
        bolCorrecto = true;
      }
      this.cxpCuotaService.detCuotaArray[this.indicador] = listaCuotas;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede consultar el detalle de las cuotas' + mensaje);
    }
    
    return bolCorrecto;
  }
  
  async consultarEncabezadoCuotas(strOpcion, strCondicion) {
    let bolcorrecto = false;
    try {
      const rs = await this.cxpCuotaService.consultarEncabezadoCuotas(strOpcion, strCondicion);
      
      if (rs !== null) {
        this.cxpCuotaService.encCuotaArray[this.indicador].ENCFACPRO_NUMERO = rs[0].ENCFACPRO_NUMERO;
        this.cxpCuotaService.encCuotaArray[this.indicador].PRO_NOMBRE = rs[0].PRO_NOMBRE === null ? '' : rs[0].PRO_NOMBRE;
        this.cxpCuotaService.encCuotaArray[this.indicador].PRO_CODIGO = rs[0].PRO_CODIGO === null ? '' : rs[0].PRO_CODIGO;
        this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_NROCUOTAS = String(rs[0].ENCCUOTA_NROCUOTAS);
        this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_VENCECDIA = String(rs[0].ENCCUOTA_VENCECDIA);
        this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_CODIGO = rs[0].ENCCUOTA_CODIGO === null ? '' : rs[0].ENCCUOTA_CODIGO;
        this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_INTERESTIPO = rs[0].ENCCUOTA_INTERESTIPO;
        this.cxpCuotaService.encCuotaArray[this.indicador].ASI_NR0 = rs[0].ASI_NRO === null ? '' : rs[0].ASI_NRO;
        // tipo de interés
        if (rs[0].ENCCUOTA_INTERESTIPO === 'PORCE') {
          this.strTipInte = this.cmbTipInte[0];
        } else {
          this.strTipInte = this.cmbTipInte[1];
        }
        
        // valor del interés
        const indexCmb = this.cmbTipInte.findIndex(index => index.name === this.strTipInte.name);
        if (indexCmb === 0 ) {
          this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_INTERESVAL = Number(rs[0].ENCCUOTA_INTERESPORCE).toFixed(this.NUMDECSIST);
          this.cxpCuotaService.encCuotaArray[this.indicador].INTERES_CALC = Number(rs[0].ENCCUOTA_INTERESVAL).toFixed(this.NUMDECSIST);
          this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_INTERESANUAL = Number(rs[0].ENCCUOTA_INTERESANUAL).toFixed(this.NUMDECSIST);
          this.lblPorc = true;
          this.lblPorc1 = false;
        } else {
          this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_INTERESVAL = Number(rs[0].ENCCUOTA_INTERESVAL).toFixed(this.NUMDECSIST);
          this.cxpCuotaService.encCuotaArray[this.indicador].INTERES_CALC = Number(rs[0].ENCCUOTA_INTERESPORCE).toFixed(this.NUMDECSIST);
          this.lblPorc = false;
          this.lblPorc1 = true;
        }
        // TOTAL FACTURA
        this.cxpCuotaService.encCuotaArray[this.indicador].ENCFAC_TOTAL = Number(rs[0].ENCFAC_TOTAL).toFixed(this.NUMDECSIST);
        // SALDO FACTURA
        this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_SUBTOTAL = Number(rs[0].ENCCUOTA_SUBTOTAL).toFixed(this.NUMDECSIST);
        this.dblTotalDoc = rs[0].ENCCUOTA_SUBTOTAL;
        // TOTAL FINANCIADO
        this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_FINANCIADO = Number(rs[0].ENCCUOTA_FINANCIADO).toFixed(this.NUMDECSIST);
        this.dblTotalFinanciado = rs[0].ENCCUOTA_FINANCIADO;
        // FECHA INICIO COBROS
        this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_FECHAINICIO = new Date(rs[0].ENCCUOTA_FECHAINICIO);
        
        await this.traerDatosAdicionales();
        
        bolcorrecto = true;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede leer la información de la base de datos' + mensaje);
    }
    
    return bolcorrecto;
  }
  
  async traerDatosAdicionales() {
    let strSentencia = '';
    strSentencia = 'select TRNCOBRO_FECHATRN,TRNCOBRO_NROCOMPROBANTE,con_codigo from cxp_trncobro where TRNCOBRO_NRODOC=\'' +
      this.cxpCuotaService.encCuotaArray[this.indicador].ENCFACPRO_NUMERO + '\'';
    strSentencia = strSentencia + ' AND COM_CODIGO = \'01\'';
    
    try {
      const rs = await this.cxpCuotaService.obtenerDatosAdicionales(this.cxpCuotaService.encCuotaArray[this.indicador].ENCFACPRO_NUMERO);
      if (rs !== null) {
        this.cxpCuotaService.encCuotaArray[this.indicador].TRNCOBRO_FECHATRN = new Date(rs[0].TRNCOBRO_FECHATRN);
        this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_DOC = rs[0].TRNCOBRO_NROCOMPROBANTE;
        this.strNumDoc = this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_DOC;
        this.cxpCuotaService.encCuotaArray[this.indicador].CON_CODIGO = rs[0].CON_CODIGO === null ? '' : rs[0].CON_CODIGO;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede consultar el detalle de las cuotas' + mensaje);
    }
  }
  
  generarCuotas() {
    if (this.faltaInformacion()) {
      return;
    }
    
    this.calcularInteres();
    this.generarTablaCuotas();
    this.calcularTotalCuotas();
    this.activarBotMod();
  }
  
  activarBotMod() {
    if ( !this.bolCuotasPagadas ) {
      this.habilitarBotones(true, false, false, true, true);
    }
    
  }
  
  generarTablaCuotas() {
    const intNroCuotas = Number(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_NROCUOTAS);
    let dblValorCuota = this.dblTotalFinanciado / intNroCuotas;
    dblValorCuota = Number(Number(dblValorCuota).toFixed(this.NUMDECSIST));
    let dblTotalCuotas = 0;
    let dblDifCuotas = 0;
    let strFechaCuotas = new Date(this.strFechaInicio);
    let strNumero = '';
    
    const listaCuotas: CxpDetCuota[] = [];
    for (let cuota = 1; cuota <= intNroCuotas; cuota++ ) {
      const item: CxpDetCuota = {};
      
      if (String(cuota).length === 1) {
        strNumero = '0' + cuota;
      } else {
        strNumero = String(cuota);
      }
      
      item.PRO_CODIGO = this.cxpCuotaService.encCuotaArray[this.indicador].PRO_CODIGO;
      item.COM_CODIGO = '01';
      item.DETCUOTA_COBRADA = 'N';
      item.DETCUOTA_CONCEPTO = 'CUOTA ' +  strNumero   + '/' + intNroCuotas + ' FAC. ' + this.strNumDoc;
      item.DETCUOTA_FECHAVENCE = this.datePipe.transform(strFechaCuotas, 'dd/MM/yyyy');
      item.DETCUOTA_IMPORTE = Number(dblValorCuota).toFixed(this.NUMDECSIST);
      item.DETCUOTA_NROLINEA = String(cuota);
      item.DETCUOTA_PAGADO = Number(0).toFixed(this.NUMDECSIST);
      item.DETCUOTA_REFERENCIA = '';
      item.DETCUOTA_SALDO = Number(dblValorCuota).toFixed(this.NUMDECSIST);
      item.ENCCUOTA_ANIO = '';
      item.ENCCUOTA_NROCUOTAS = intNroCuotas;
      item.ENCFACPRO_NUMERO = this.strNumDoc;
      // totalizar el valor de las cuotas
      dblTotalCuotas = dblTotalCuotas + dblValorCuota;
      // FECHA VENCE CADA CUOTA
      
      
      strFechaCuotas = this.obtenerFechaVence(strFechaCuotas.getDate(), strFechaCuotas.getUTCMonth(), strFechaCuotas.getFullYear());
      listaCuotas.push(item);
      // console.log(item);
    }
    // verificar que el total de las cuotas sea igual al total financiado
    
    if ( dblTotalCuotas > this.dblTotalFinanciado ) {
      dblDifCuotas = dblTotalCuotas - this.dblTotalFinanciado;
      dblValorCuota = dblValorCuota - dblDifCuotas;
    } else if ( dblTotalCuotas < this.dblTotalFinanciado ) {
      dblDifCuotas = this.dblTotalFinanciado - dblTotalCuotas;
      dblValorCuota = dblValorCuota + dblDifCuotas;
    }
    
    const item1: CxpDetCuota = listaCuotas[listaCuotas.length - 1];
    item1.DETCUOTA_IMPORTE = Number(dblValorCuota).toFixed(this.NUMDECSIST);
    item1.DETCUOTA_SALDO = Number(dblValorCuota).toFixed(this.NUMDECSIST);
    
    this.cxpCuotaService.detCuotaArray[this.indicador] = listaCuotas;
    
  }
  
  obtenerFechaVence(dia, mes, anio ) {
    const fecha1 = new Date(anio, mes, dia);
    fecha1.setDate(fecha1.getDate() + Number(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_VENCECDIA));
    return fecha1;
  }
  
  faltaInformacion(): boolean {
    let bolFalta = false;
    // documento
    if (String(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_DOC).length === 0 ) {
      this.mensajeAlerta('info', 'Información', 'No ha seleccionado ningún documento.');
      document.getElementById('ENCCUOTA_DOC').focus();
      return bolFalta = true;
    }
    // número de cuotas
    if (String(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_NROCUOTAS).length === 0 ) {
      this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_NROCUOTAS = 0;
    }
    
    if (Number(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_NROCUOTAS) === 0 ) {
      this.mensajeAlerta('info', 'Información', 'Ingrese el número de cuotas.');
      document.getElementById('ENCCUOTA_NROCUOTAS').focus();
      return bolFalta = true;
      
    }
    // días de vencimiento de cada cuota
    if (String(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_VENCECDIA).length === 0 ) {
      this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_VENCECDIA = 0;
    }
    
    if (Number(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_VENCECDIA) === 0 ) {
      this.mensajeAlerta('info', 'Información', 'El número de días de plazo para cada cuota debe ser mayor a cero.');
      document.getElementById('ENCCUOTA_VENCECDIA').focus();
      return bolFalta = true;
    }
    
    this.strFechaInicio = this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_FECHAINICIO;
    
    // la fecha del inicio de cobros no puede ser menor a la fecha del documento
    
    const strFechaInicioGC = this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_FECHAINICIO;
    const strFechaDocumento = this.cxpCuotaService.encCuotaArray[this.indicador].TRNCOBRO_FECHATRN;
    
    this.strFechaDoc = this.cxpCuotaService.encCuotaArray[this.indicador].TRNCOBRO_FECHATRN;
    
    if (strFechaDocumento > strFechaInicioGC) {
      this.mensajeAlerta('info', 'Información', 'La fecha para el inicio de los cobros no puede ser menor a la fecha del' +
        ' documento');
      return bolFalta = true;
    }
    
    return bolFalta;
  }
  
  habilitarBotones(nuevo, guardar, eliminar, generar, busqueda) {
    this.btnNuevo = nuevo;
    this.btnGuardar = guardar;
    this.btnEliminar = eliminar;
    this.btnGenerarCuot = generar;
    this.btnBuscar = busqueda;
  }
  
  cambiarFoco(evento) {
    
    if (evento.target.id === 'ENCCUOTA_INTERESANUAL') {
      this.calculaPorcentajeInteres();
      document.getElementById('ENCCUOTA_NROCUOTAS').focus();
      this.activarBotMod();
    }
    if (evento.target.id === 'ENCCUOTA_NROCUOTAS') {
      this.calculaPorcentajeInteres();
      document.getElementById('ENCCUOTA_VENCECDIA').focus();
      this.activarBotMod();
    }
    if (evento.target.id === 'ENCCUOTA_VENCECDIA') {
      this.calcularFechaIniCobros();
      document.getElementById('tipoInteres').focus();
      this.activarBotMod();
    }
    if (evento.target.id === 'tipoInteres') {
      document.getElementById('ENCCUOTA_INTERESANUAL').focus();
    }
    if (evento.target.id === 'ENCCUOTA_INTERESEDIT') {
      this.calcularInteres();
      document.getElementById('ENCCUOTA_INTERESANUAL').focus();
      this.activarBotMod();
    }
    
  }
  
  cambioDropdown(event) {
    const indexCmb = this.cmbTipInte.findIndex(index => index.name === this.strTipInte.name);
    if (indexCmb === 0) {
      this.lblPorc = true;
      this.lblPorc1 = false;
    } else {
      this.lblPorc = false;
      this.lblPorc1 = true;
    }
    this.activarBotMod();
    
  }
  
  calcularFechaIniCobros() {
    const fechaIni = new Date(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_FECHAINICIO);
    const strFechaIniCobros = this.obtenerFechaVence(fechaIni.getDate(), fechaIni.getUTCMonth(), fechaIni.getFullYear());
    
    this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_FECHAINICIO = strFechaIniCobros;
  }
  
  calculaPorcentajeInteres() {
    let dblInteresAnual = 0;
    let dblNumeroMeses = 0;
    let dblInteresXNumMeses = 0;
    // Calcula el porcentaje de interés de acuerdo al interés anual y al número de meses
    dblInteresAnual = Number(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_INTERESANUAL);
    if (String(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_NROCUOTAS).trim() !== '') {
      dblNumeroMeses = Number(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_NROCUOTAS);
    } else {
      dblNumeroMeses = 0;
    }
    
    if (dblInteresAnual !== 0 && dblNumeroMeses !== 0 ) {
      dblInteresXNumMeses = (dblNumeroMeses * dblInteresAnual) / 12;
    }
    
    if (dblInteresXNumMeses !== 0) {
      this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_INTERESVAL = Number(dblInteresXNumMeses).toFixed(this.NUMDECSIST);
    }
  }
  
  habilitarBotonesDet(nuevo, eliminar, cancelar) {
    this.btnNuevoDet = nuevo;
    this.btnBorrarDet = eliminar;
    this.btnCancelarDet = cancelar;
  }
  
  seleccionDet(valor) {
    if (valor === 'nuevo') {
      this.nuevaLineaCuotas();
    }
    if (valor === 'eliminar') {
      this.eliminarLineaCuota();
    }
    if (valor === 'cancelar') {
      this.cancelarLineaOtr();
    }
    
    if (valor.object !== undefined) {
      this.detalleCuotaSel = valor.object;
    }
  }
  
  seleccionDetVar(valor) {
    if (valor === 'nuevo') {
      this.nuevaLineaVarios();
    }
    if (valor === 'eliminar') {
      this.eliminarLineaVarios();
    }
    if (valor === 'cancelar') {
      this.cancelarLineaVar();
    }
  
    if (valor.object !== undefined) {
      this.detalleVariosSel = valor.object;
    }
  }
  
  cancelarLineaVar() {
    this.cancelarAccionesVar();
    this.btnNuevoDetVar = false;
    this.btnBorrarDetVar = false;
    this.btnCancelarDetVar = true;
    
  }
  
  cancelarAccionesVar() {
    let intIndiceLista = 0;
    let item: Invdettomafisicavar = {};
    for (intIndiceLista = 0; intIndiceLista < this.cxpCuotaService.detCuotaVarArray[this.indicador].length; intIndiceLista++) {
      item = this.cxpCuotaService.detCuotaVarArray[this.indicador][intIndiceLista];
      if (item.CON_CODIGO === '') {
        this.cxpCuotaService.detCuotaVarArray[this.indicador].splice(this.cxpCuotaService.detCuotaVarArray[this.indicador].findIndex(
          tomFis => tomFis.LINEA === item.LINEA),
          1);
      }
    
    }
    this.aggrid.refreshaggrid(this.cxpCuotaService.detCuotaVarArray[this.indicador], this.defaultCuotaVar.objeto);
  }
  
  eliminarLineaVarios() {
  
    if (this.permisos !== null ) {

      if (this.permisos.PERTODO === 0 ) {
        if (this.permisos.PERACTUALIZACION === 0 ) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
          ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }
    
    if (Object.keys(this.detalleVariosSel).length > 0 && this.cxpCuotaService.detCuotaVarArray[this.indicador].length > 0) {
  
      this.confirmationService.confirm({
        message: '¿ Está seguro de eliminar el registro ?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'cxpcuotasconf',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmEliminarDetVar();
        },
        reject: () => {
        }
      });
      
    } else {
      this.mensajeAlerta('info', 'Información', 'Seleccione la fila que desea eliminar.');
    }
    
  }
  
  confirmEliminarDetVar() {
    const intIndex = this.cxpCuotaService.detCuotaVarArray[this.indicador].indexOf(this.detalleVariosSel);
    const item = this.cxpCuotaService.detCuotaVarArray[this.indicador][intIndex];
  
    this.cxpCuotaService.detCuotaVarArray[this.indicador].splice(this.cxpCuotaService.detCuotaVarArray[this.indicador].findIndex(
      tomFis => tomFis.LINEA === item.LINEA),
      1);
    this.aggrid.refreshaggrid(this.cxpCuotaService.detCuotaVarArray[this.indicador], this.defaultCuotaVar.objeto);

    this.btnNuevoDetVar = false;
    this.btnBorrarDetVar = false;
    this.btnCancelarDetVar = true;
    
    this.activarBotMod();
  }
  
  nuevaLineaVarios() {
    if (this.permisos !== null ) {
      if (this.permisos.PERTODO === 0 ) {
        if (this.permisos.PERACTUALIZACION === 0 ) {
          this.mensajeAlerta('info', 'Información', 'El usuario , no tiene permiso de actualización. Consulte con el ' +
            'Administrador del Sistema.');
          return;
        }
      }
    }
    
    if (this.cxpCuotaService.encCuotaArray[this.indicador].INTERES_CALC === '.00' || this.cxpCuotaService.encCuotaArray[this.indicador].INTERES_CALC == ''
    || Number(this.cxpCuotaService.encCuotaArray[this.indicador].INTERES_CALC) === 0) {
      this.mensajeAlerta('info', 'Información', 'Por favor, Ingrese primero el valor de los intereses.');
    } else {
      this.nuevaLineaVar();
      this.btnNuevoDetVar = true;
      this.btnCancelarDetVar = false;
      this.activarBotMod();
    }
  }
  
  nuevaLineaVar() {
    const item: CxpCuotaVar = {};
  
    let dblSaldoImpVar = 0;
  
    item.CON_CODIGO = '';
    item.CON_NOMBRE = '';
    item.CON_CENTRO = 'No';
    item.CEN_CODIGO = '';
    
    dblSaldoImpVar = this.calcularSaldoImporteVarios();
  
    item.TRNCUOTASVAR_IMPORTE = Number(dblSaldoImpVar).toFixed(2);
    item.LINEA = this.cxpCuotaService.detCuotaVarArray[this.indicador].length + 1;
  
    const indi =  this.cxpCuotaService.detCuotaVarArray[this.indicador].indexOf(this.detalleVariosSel);
    if (indi === -1) {
      this.cxpCuotaService.detCuotaVarArray[this.indicador].push(item);
      this.detalleVariosSel = this.cxpCuotaService.detCuotaVarArray[this.indicador]
        [this.cxpCuotaService.detCuotaVarArray[this.indicador].length - 1];
      this.aggrid.refreshaggrid(this.cxpCuotaService.detCuotaVarArray[this.indicador], this.defaultCuotaVar.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultCuotaVar.objeto].setFocusedCell(
        this.cxpCuotaService.detCuotaVarArray[this.indicador].length - 1, 'CON_CODIGO');
    } else {
      this.cxpCuotaService.detCuotaVarArray[this.indicador].splice(indi + 1, 0, item);
      this.aggrid.refreshaggridindex(this.cxpCuotaService.detCuotaVarArray[this.indicador], this.defaultCuotaVar.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultCuotaVar.objeto].setFocusedCell(
        indi + 1, 'CON_CODIGO');
    }
    
    this.btnNuevoDetVar = true;
    
  }
  
  calcularSaldoImporteVarios(): number {
    let dblInteres = 0;
    let dblTotalIngVar = 0;
    let dblSaldoVarios = 0;
    
    dblInteres = Number(this.cxpCuotaService.encCuotaArray[this.indicador].INTERES_CALC);
    
    for (let fila = 0; fila < this.cxpCuotaService.detCuotaVarArray[this.indicador].length; fila++) {
      let item: CxpCuotaVar = {};
      item = this.cxpCuotaService.detCuotaVarArray[this.indicador][fila];
      dblTotalIngVar = dblTotalIngVar + Number(item.TRNCUOTASVAR_IMPORTE);
    }
    
    dblTotalIngVar = Number(Number(dblTotalIngVar).toFixed(2));
    
    dblSaldoVarios = dblInteres - dblTotalIngVar;
    
    return dblSaldoVarios;
    
  }
  
  cancelarLineaOtr() {
    this.cancelarAcciones();
    this.habilitarBotonesDet(false, false, true);
  }
  
  cancelarAcciones() {
    let intIndiceLista = 0;
    let item: CxpDetCuota = {};
    for (intIndiceLista = 0; intIndiceLista < this.cxpCuotaService.detCuotaArray[this.indicador].length; intIndiceLista++ ) {
      item = this.cxpCuotaService.detCuotaArray[this.indicador][intIndiceLista];
      if (Number(item.DETCUOTA_IMPORTE) === 0 ) {
        this.cxpCuotaService.detCuotaArray[this.indicador].splice(this.cxpCuotaService.detCuotaArray[this.indicador].findIndex(
          cuota => cuota.DETCUOTA_NROLINEA === this.detalleCuotaSel.DETCUOTA_NROLINEA),
          1);
        
      }
    }
    this.aggrid.refreshaggrid(this.cxpCuotaService.detCuotaArray[this.indicador], this.defaultConDetCuotas.objeto);
  }
  
  eliminarLineaCuota() {
    if ( !this.bolNuevo ) {
      if (this.permisos !== null ) {
        if (this.permisos.PERTODO === 0 ) {
          if (this.permisos.PERACTUALIZACION === 0 ) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
            return;
          }
        }
      }
    }
    
    let bolEliminar = false;
    
    
    if (Object.keys(this.detalleCuotaSel).length > 0) {
      this.cxpCuotaService.detCuotaArray[this.indicador].splice(this.cxpCuotaService.detCuotaArray[this.indicador].findIndex(
        item => item.DETCUOTA_NROLINEA === this.detalleCuotaSel.DETCUOTA_NROLINEA),
        1);
      this.aggrid.refreshaggrid(this.cxpCuotaService.detCuotaArray[this.indicador], this.defaultConDetCuotas.objeto);
      bolEliminar = true;
      if (bolEliminar) {
        this.habilitarBotonesDet(false, false, true);
        this.calcularTotalCuotas();
        this.activarBotMod();
      }
    } else {
      this.mensajeAlerta('info', 'Información', 'Seleccione la fila que desea eliminar.');
    }
    
  }
  
  nuevaLineaCuotas() {
    const item: CxpDetCuota = {};
    let cuota = 1;
    
    if ( !this.bolNuevo ) {
      if (this.permisos !== null ) {
        if (this.permisos.PERTODO === 0 ) {
          if (this.permisos.PERACTUALIZACION === 0 ) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
            return;
          }
        }
      }
    }
    // verificar que se haya seleccionado un  documento
    if (String(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_DOC).length === 0 ) {
      this.mensajeAlerta('info', 'Información', 'Debe escoger un documento.');
      return;
    }
    
    // número de cuota a crear
    if (this.cxpCuotaService.detCuotaArray[this.indicador].length > 0 ) {
      cuota = this.cxpCuotaService.detCuotaArray[this.indicador].length + 1;
    }
    // total de cuotas generadas
    if (String(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_NROCUOTAS).length === 0) {
      this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_NROCUOTAS = 1;
    }
    
    if (Number(this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_NROCUOTAS) <=  this.cxpCuotaService.detCuotaArray[this.indicador].length) {
      this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_NROCUOTAS = this.cxpCuotaService.detCuotaArray[this.indicador].length + 1;
    }
    
    // fecha para nueva cuota
    const strFechaCuotas = new Date();
    
    item.PRO_CODIGO = this.cxpCuotaService.encCuotaArray[this.indicador].PRO_CODIGO;
    item.COM_CODIGO = '01';
    item.DETCUOTA_COBRADA = 'N';
    item.DETCUOTA_CONCEPTO = 'CUOTA ' + cuota + '/' + this.cxpCuotaService.encCuotaArray[this.indicador].ENCCUOTA_NROCUOTAS + ' FAC. ' + this.strNumDoc;
    item.DETCUOTA_FECHAVENCE = this.datePipe.transform(strFechaCuotas, 'dd/MM/yyyy');
    item.DETCUOTA_IMPORTE = Number(0).toFixed(this.NUMDECSIST);
    item.DETCUOTA_NROLINEA = String(cuota);
    item.DETCUOTA_PAGADO = Number(0).toFixed(this.NUMDECSIST);
    item.DETCUOTA_REFERENCIA = '';
    item.DETCUOTA_SALDO = Number(0).toFixed(this.NUMDECSIST);
    item.ENCCUOTA_ANIO = '';
    item.ENCCUOTA_NROCUOTAS = this.cxpCuotaService.detCuotaArray[this.indicador].length + 1;
    item.ENCFACPRO_NUMERO = this.strNumDoc;
    
    const indi =  this.cxpCuotaService.detCuotaArray[this.indicador].indexOf(this.detalleCuotaSel);
    if (indi === -1) {
      this.cxpCuotaService.detCuotaArray[this.indicador].push(item);
      this.detalleCuotaSel = this.cxpCuotaService.detCuotaArray[this.indicador]
        [this.cxpCuotaService.detCuotaArray[this.indicador].length - 1];
      this.aggrid.refreshaggrid(this.cxpCuotaService.detCuotaArray[this.indicador], this.defaultConDetCuotas.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultConDetCuotas.objeto].setFocusedCell(
        this.cxpCuotaService.detCuotaArray[this.indicador].length - 1, 'DETCUOTA_FECHAVENCE');
    } else {
      this.cxpCuotaService.detCuotaArray[this.indicador].splice(indi + 1, 0, item);
      this.aggrid.refreshaggridindex(this.cxpCuotaService.detCuotaArray[this.indicador], this.defaultConDetCuotas.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultConDetCuotas.objeto].setFocusedCell(
        indi + 1, 'DETCUOTA_FECHAVENCE');
    }
    this.habilitarBotonesDet(true, false, false);
  }
  
  cambio(event) {
    this.activarBotMod();
  }
  
  isEditCen(data): boolean {
    if (data.CON_CENTRO === 'Si') {
      return true;
    }
    return false;
  }
  
  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }


  
  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'cxpcuota',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos  = false;
    }
  }
  
}
