<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="actencsalida"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="actencsalidaConf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 100%">
	<div class="p-col-12">
        <app-invmaebarra [botonNuevo]="btnNuevo" 
        [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
        [botonBorrar]="btnBorrar" (signal)="manejarSenales($event)" [botonAnterior]="botonAnterior"
        [botonPrimero]="botonPrimero" [botonUltimo]="botonUltimo" [botonSiguiente]="botonSiguiente"
        [barraBotones3]="true" [botonBuscar]="btnBuscar" [botonBinocular]="true"
		[botonAsiento]="false" [botonverAsiento]="false" [barraBotones3Alt]="false"
		[botonVisto]="btnVisto" [btnRevActFijo] = "btnRevActFijo" [botonAlmacenardoc]="true">
        </app-invmaebarra>
	</div>
</div>

<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px; width: 100%;">
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-5"></div>
			<div class="p-col-1">
                <input type="text" pInputText
					   style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
					   disabled
					   [(ngModel)]="lblAsiNro">
			</div>
			<div class="p-col-1"></div>
			<div class="p-col-1">
				<div class="p-grid">
					<div class="p-col-5">
						<span>Estado:</span>
					</div>
					<div class="p-col-7">
						<input type="text"  pInputText [(ngModel)]="txtEsta" disabled>
					</div>
				</div>
			</div>
			<div class="p-col-1">
				<input type="text" pInputText [(ngModel)]="lblEstado" disabled>
			</div>
			<div class="p-col-1"></div>
			<div class="p-col-2">
                <input type="text" pInputText
					   style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
					   disabled
					   [(ngModel)]="lblConf">
			</div>
            
		</div>
	</div>
</div>

<p-tabView [style]="{'width':'100%'}" >
    <p-tabPanel header="Salidas">
		<div class="divgrups" id="divPrincipal">
			<div class="p-grid">
				<div class="p-col-1">
					<span>Número:</span>
				</div>
				<div class="p-col-2">
					<input id="numero" type="text" pInputText autocomplete="off"
						   [(ngModel)]="txtNume" disabled>
				</div>
				<div class="p-col-1"></div>
				<div class="p-col-1">
					<span>Referencia:</span>
				</div>
				<div class="p-col-2">
					<input id="referencia" type="text" pInputText autocomplete="off"
						   [(ngModel)]="txtRefe" (keydown.enter)="cambiarFoco($event)"
						   (input)="cambio($event)">
				</div>
				<div class="p-col-1"></div>
				<div class="p-col-1">
					<span>Comprobante:</span>
				</div>
				<div class="p-col-2">
					<input id="comprobante" type="text" pInputText autocomplete="off"
						   [(ngModel)]="txtComp" 
						   (keydown.enter)="cambiarFoco($event)">
				</div>
				<!--LINE 2-->
				<div class="p-col-12" style="margin-top: -5px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Fecha:</span>
						</div>
						<div class="p-col-2" >
							<p-calendar id="fecha"  [inputStyle]="{'width': '100%'}"
										dateFormat="dd/mm/yy" [(ngModel)]="dateFecha"  
										[readonlyInput]="true">
							</p-calendar>
						</div>
						<div class="p-col-1"></div>
						<div class="p-col-1">
							<span>TipoUsuario:</span>
						</div>
						<div class="p-col-2" >
							<p-dropdown [style]="{'minWidth': '100%', 'width':'100%'}"
                                            [(ngModel)]="strTipoUsu"
                                            optionLabel="name" [options]="cmbTipUsu"
                                            [panelStyle]="{'width':'100%'}" (onChange)="cambio($event)"
                                            inputId="tipousu"
                                            (keydown.enter)="cambiarFoco($event)">
                                </p-dropdown>
						</div>
					</div>
				</div>
				<div class="p-col-12"  style="margin-top: -12px;">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Usuario1:</span>
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-10">
									<input type="text" id="cliente1" pInputText [(ngModel)]="txtCodCli1" (input)="cambio($event)"
									(keydown.enter)="cambiarFoco($event)">
								</div>
								<div class="p-col-2">
									<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									class="littlebuttons"
									(click)="busquedaUsuario1(txtCodCli1)"></button>
								</div>
							</div>
						</div>
						<div class="p-col-4">
							<input type="text" pInputText [(ngModel)]="lblNomCli1" disabled>
						</div>
					</div>
				</div>
				<div class="p-col-12"  style="margin-top: -12px;">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Usuario2:</span>
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-10">
									<input type="text" id="cliente2" pInputText [(ngModel)]="txtCodCli2" (input)="cambio($event)"
									(keydown.enter)="cambiarFoco($event)">
								</div>
								<div class="p-col-2">
									<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									class="littlebuttons"
									(click)="busquedaUsuario2(txtCodCli2)"></button>
								</div>
							</div>
						</div>
						<div class="p-col-4">
							<input type="text" pInputText [(ngModel)]="lblNomCli2" disabled>
						</div>
					</div>
				</div>
				<div class="p-col-12"  style="margin-top: -12px;">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Ciudad:</span>
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-10">
									<input type="text" id="ciudad" pInputText [(ngModel)]="txtCiuCod" (input)="cambio($event)"
									(keydown.enter)="cambiarFoco($event)">
								</div>
								<div class="p-col-2">
									<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									class="littlebuttons"
									(click)="busquedaCiudad(txtCiuCod)"></button>
								</div>
							</div>
						</div>
						<div class="p-col-2">
							<input type="text" pInputText [(ngModel)]="lblCiuNomb" disabled>
						</div>
						<div class="p-col-1">
							<span>Oficina:</span>
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-10">
									<input type="text" id="oficina" pInputText [(ngModel)]="txtCodOfi" (input)="cambio($event)"
									(keydown.enter)="cambiarFoco($event)">
								</div>
								<div class="p-col-2">
									<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									class="littlebuttons"
									(click)="busquedaOficina(txtCodOfi)"></button>
								</div>
							</div>
						</div>
						<div class="p-col-2">
							<input type="text" pInputText [(ngModel)]="lblNomOfi" disabled>
						</div>
					</div>
				</div>
				<div class="p-col-12"  style="margin-top: -12px;">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Area:</span>
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-10">
									<input type="text" id="area" pInputText [(ngModel)]="txtCodArea" (input)="cambio($event)"
									(keydown.enter)="cambiarFoco($event)">
								</div>
								<div class="p-col-2">
									<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									class="littlebuttons"
									(click)="busquedaArea(txtCodArea)"></button>
								</div>
							</div>
						</div>
						<div class="p-col-2">
							<input type="text" pInputText [(ngModel)]="lblNomArea" disabled>
						</div>
						<div class="p-col-1">
							<span>Departamento:</span>
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-10">
									<input type="text" id="departamento" pInputText [(ngModel)]="txtCodDep" (input)="cambio($event)"
									(keydown.enter)="cambiarFoco($event)">
								</div>
								<div class="p-col-2">
									<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									class="littlebuttons"
									(click)="busquedaDepartamento(txtCodDep)"></button>
								</div>
							</div>
						</div>
						<div class="p-col-2">
							<input type="text" pInputText [(ngModel)]="lblNomDep" disabled>
						</div>
					</div>
				</div>
				<div class="p-col-12"  style="margin-top: -12px;">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Motivo:</span>
						</div>
						<div class="p-col-2">
							<p-dropdown [style]="{'minWidth': '100%', 'width':'100%'}"
                                            [(ngModel)]="strMotivo"
                                            optionLabel="name" [options]="cmbMoti"
                                            [panelStyle]="{'width':'100%'}" (onChange)="cambio($event)"
                                            inputId="motivo"
                                            (keydown.enter)="cambiarFoco($event)">
                                </p-dropdown>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-1">
							<span>Autorizado por:</span>
						</div>
						<div class="p-col-2">
							<input id="autorizado" type="text" pInputText autocomplete="off"
								   [(ngModel)]="txtAuto" (keydown.enter)="cambiarFoco($event)"
								   (input)="cambio($event)">
						</div>
					</div>
				</div>
				<div class="p-col-12"  style="margin-top: -12px;">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Observaciones:</span>
						</div>
						<div class="p-col-7">
							<input id="observacion1" type="text" pInputText autocomplete="off"
								   [(ngModel)]="txtObse1" (keydown.enter)="cambiarFoco($event)"
								   (input)="cambio($event)">
						</div>
					</div>
				</div>
				<!--LINE 3-->
				<div class="p-col-12" style="margin-top: -10px; margin-bottom: -15px;">
					<div class="p-grid">
						<div class="p-col-1">	
						</div>
						<div class="p-col-7">
							<input id="observacion2" type="text" pInputText autocomplete="off"
								   [(ngModel)]="txtObse2" (keydown.enter)="cambiarFoco($event)"
								   (input)="cambio($event)">
						</div>
					</div>
				</div>
			</div>
		</div>
		<p-tabView [style]="{'width':'100%'}" >
			<p-tabPanel header="Activos">
				<app-aggridsaci 
				[width]=""
				[height]="largo"
				[enableSorting]="false"
				[enableFilter]="true"
				[rowData]="actencsalidaService.detSalidaArray[indicador]"
				[frameworkComponents]="frameworkComponents"
				[animateRows]="true"
				[rowSelection]="rowSelection"
				[columnDefs]="columnDefsActDetSalida"
				[defaultColDef]="defaultActDetSalida"
				[rowStyle]="rowStyle"
				[singleClickEdit]="false"
				[botones]="true"
				[botonNuevo]="btnNuevoDetSal"
				[botonBorrar]="btnBorrarDetSal"
				[botonRegresar]="btnCancelarDetSal"
				[mostrarGuardar]="false"
				(selected)="seleccionDetSalida($event)"
				(cambios)="cambioDet($event)"> </app-aggridsaci>
			</p-tabPanel>
			<p-tabPanel header="Varios">
				<app-aggridsaci 
				[width]=""
				[height]="largo"
				[enableSorting]="false"
				[enableFilter]="true"
				[rowData]="actencsalidaService.detVariosSalidaArray[indicador]"
				[frameworkComponents]="frameworkComponents"
				[animateRows]="true"
				[rowSelection]="rowSelection"
				[columnDefs]="columnDefsActVariosSalida"
				[defaultColDef]="defaultActDetVarSal"
				[rowStyle]="rowStyle"
				[singleClickEdit]="false"
				[botones]="true"
				[botonNuevo]="btnNuevoDetVarSal"
				[botonBorrar]="btnBorrarDetVarSal"
				[botonRegresar]="btnCancelarDetVarSal"
				[mostrarGuardar]="false"
				(selected)="seleccionDetVarSalida($event)"
				(cambios)="cambioVar($event)"></app-aggridsaci>
			</p-tabPanel>
		</p-tabView>
		<div class="divgrupstabla">
			<!--				<div class="p-col-12">-->
			<div class="p-grid">
				<div class="p-col-1">
					<span class="obligatorio">Activo: </span>
				</div>
				<div class="p-col-5">
					<input class="totales" type="text" pInputText [disabled]="true" style="text-align: left;"
						   [(ngModel)]="txtActPie">
				</div>
				<div class="p-col-2">
					<span class="obligatorio">Depreciación Acumulada: </span>
				</div>
				<div class="p-col-1">
					<input class="totales" type="text" pInputText [disabled]="true"
						   [(ngModel)]="txtDepAcumKard">
				</div>
			</div>
			<!--				</div>-->
		</div>
	</p-tabPanel>
</p-tabView>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>
<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width:'80vw', height:'120vh'}"
		  [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="lblAsiNro" [abiertoDesde]="'actencsalida'"
					(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>

<p-dialog header="Clave de Autorización" [(visible)]="claveDialog"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '40vw', height: 'auto'} ">
		  <p-toast [style]="{marginTop: '41vh'}" position="top-center" key="actencsalidaclave"></p-toast>
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							   (keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>
<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'ACTENCSALIDA'"
		[txtNumComprobante]="txtNume"
		(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>
<!-- CDPJ -->
<div style="position: fixed;
z-index: 999;
height: 2em;
width: 2em;
overflow: visible;
margin: auto;
top: 0;
left: 0;
bottom: 0;
right: 0;">
<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
</p-progressSpinner>
<p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
[style]="{ opacity: 0.00}" position="top">
</p-dialog>
</div>
<!-- CDPJ -->