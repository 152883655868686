<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="nommaeprovtoast"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="NomMaeProvDialog" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 99%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="this.maeProvinciaService.btnNuevo" [botonGuardar]="this.maeProvinciaService.btnGuardar" [botonBorrar]="this.maeProvinciaService.btnBorrar"
						 [botonRegresar]="this.maeProvinciaService.btnRegresar" [barraBotonesCRUD]="false"
						 [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true"
						 (signal)="manejarSenales($event)"></app-invmaebarra>
	</div>

</div>
<div style="overflow: hidden" class="p-col-12">
	<p-tabView (onChange)="onTab1Change($event)" [(activeIndex)]="indexTab">
		<p-tabPanel header="Provincias"  >
			
			<app-aggridsaci [width]="" *ngIf="visibleGrid === true"
							[height]="largo"
							[enableSorting]="false"
							[enableFilter]="true"
							[rowData]="maeProvinciaService.nommaeprov_provincia"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefMaeProv_provincia"
							[defaultColDef]="defaultColDefMaeProv_provincia"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)="selectMaeProv_provincia($event)"
							(cambios)="cambio($event)"

			></app-aggridsaci>
		</p-tabPanel>
		<p-tabPanel header="Ciudades" [disabled]="disabledTab1">
			<div class="divgrups">
				<div class="p-grid" style="margin-top: 10px; margin-bottom: 10px">
					<div class="p-col-1">
						<span>Provincia: {{nombreProvincia}}</span>
					</div>
				</div>
			</div>
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="false"
							[enableFilter]="true"
							[rowData]="maeProvinciaService.nommaeprov_ciudades"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefMaeProv_ciudad"
							[defaultColDef]="defaultColDefMaeProv_ciudad"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)="selectMaeProv_ciudad($event)"
							(cambios)="cambioCiudad($event)"

			></app-aggridsaci>
		</p-tabPanel>
	</p-tabView>
</div>
