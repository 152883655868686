import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from 'src/app/usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import { DatePipe } from '@angular/common';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { nomdetImpuRent, nomcabImpuRent } from '../nominterfaces/nomrhucabimpurent';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class NomcabimpurentserviceService {

  public way: string;
  public detImpuRent:nomdetImpuRent[]=[];
  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService,
    private pipe: DatePipe) { 
      this.way = sessionStorage.getItem('way');
      this.usuario.identificacion;
    }
    async primerocabi(){
      return  await this.http.post(this.way + '/nom/cabimpurent/fjwst6yhft3pl0f', {
        elementos: {},
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async ultimocabi(){
      return  await this.http.post(this.way + '/nom/cabimpurent/q2iencrg5ddg6zk', {
        elementos: {},
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async anteriorcabi(strCABIMPURENT_CODIGO){
      console.log(strCABIMPURENT_CODIGO);
      if(isNullOrUndefined(strCABIMPURENT_CODIGO)){
        strCABIMPURENT_CODIGO=0;
      }
      return  await this.http.post(this.way + '/nom/cabimpurent/48zc92s4jqwif9f', {
        elementos: {
          CABIMPURENT_CODIGO: strCABIMPURENT_CODIGO
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async siguientecabi(strCABIMPURENT_CODIGO){
       if(isNullOrUndefined(strCABIMPURENT_CODIGO)){
        strCABIMPURENT_CODIGO=0;
      }
      return  await this.http.post(this.way + '/nom/cabimpurent/ufk03d8bkz7kp3k', {
        elementos: {
          CABIMPURENT_CODIGO: strCABIMPURENT_CODIGO
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async listcabimpurent(cabimpurent_codigo){
      return  await this.http.post(this.way + '/nom/cabimpurent/dfop5q5c0k4vkli', {
        elementos: {
          p_cabimpurent_codigo: cabimpurent_codigo
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async listdetimpurent(cabimpurent_codigo){
      return  await this.http.post(this.way + '/nom/cabimpurent/lmmdarxzm9whn3x', {
        elementos: {
          p_cabimpurent_codigo: cabimpurent_codigo
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async obtieneaniofinper(){
      return  await this.http.post(this.way + '/nom/cabimpurent/kzxcm4hb1pecr41', {
        elementos: {},
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async actuacabimpurent(cabImpuRent:nomcabImpuRent){
      console.log(cabImpuRent);
      //if()
      if(isNullOrUndefined(cabImpuRent.CABIMPURENT_DESCRIPCION)){
        cabImpuRent.CABIMPURENT_DESCRIPCION="";
      }
      return  await this.http.post(this.way + '/nom/cabimpurent/2dc1zl68ibgozqh', {
        elementos: {
          p_cabimpurent_codigo       :cabImpuRent.CABIMPURENT_CODIGO,
          p_cabimpurent_anio         :parseInt(cabImpuRent.CABIMPURENT_ANIO),
          p_cabimpurent_descripcion  :String(cabImpuRent.CABIMPURENT_DESCRIPCION),
          p_com_codigo               :'01',   
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async actuadetimpurent(cabdetRent:nomdetImpuRent){
      console.log(cabdetRent);
      return  await this.http.post(this.way + '/nom/cabimpurent/qb97lurvx4nj03j', {
        elementos: {
          p_cabimpurent_codigo         :parseInt(cabdetRent.CABIMPURENT_CODIGO),
          p_com_codigo                 :'01',
          p_detimpurent_numsec         :parseInt(cabdetRent.DETIMPURENT_NUMSEC),
          p_emp_codigo                 :parseInt(cabdetRent.EMP_CODIGO),
          p_detimpurent_sueldo         :parseFloat(cabdetRent.DETIMPURENT_SUELDO),
          p_detimpurent_otroingr       :parseFloat(cabdetRent.DETIMPURENT_OTROINGR),
          p_detimpurent_totaingr       :parseFloat(cabdetRent.DETIMPURENT_TOTAINGR),
          p_detimpurent_vivi           :parseFloat(cabdetRent.DETIMPURENT_VIVI),
          p_detimpurent_educ           :parseFloat(cabdetRent.DETIMPURENT_EDUC),
          p_detimpurent_alim           :parseFloat(cabdetRent.DETIMPURENT_ALIM),
          p_detimpurent_vest           :parseFloat(cabdetRent.DETIMPURENT_VEST),
          p_detimpurent_salu           :parseFloat(cabdetRent.DETIMPURENT_SALU),
          p_detimpurent_iess           :parseFloat(cabdetRent.DETIMPURENT_IESS),
          p_detimpurent_totaegre       :parseFloat(cabdetRent.DETIMPURENT_TOTAEGRE),
          p_detimpurent_baseimpo       :parseFloat(cabdetRent.DETIMPURENT_BASEIMPO),
          p_detimpurent_valoimpurent   :parseFloat(cabdetRent.DETIMPURENT_VALOIMPURENT),
          p_detimpurent_impurentasum   :parseFloat(cabdetRent.DETIMPURENT_IMPURENTASUM),
          p_detimpurent_orden          :parseFloat(cabdetRent.DETIMPURENT_ORDEN),
          p_detimpurent_sistsal        :parseFloat(cabdetRent.DETIMPURENT_SISTSAL),
          p_detimpurent_otroingr_ni    :parseFloat(cabdetRent.DETIMPURENT_OTROINGR_NI),
          p_detimpurent_ingrgrav_oe    :parseFloat(cabdetRent.DETIMPURENT_INGRGRAV_OE),
          p_detimpurent_ireasum        :parseFloat(cabdetRent.DETIMPURENT_IREASUM),
          p_detimpurent_uti            :parseFloat(cabdetRent.DETIMPURENT_UTI),
          p_detimpurent_d13            :parseFloat(cabdetRent.DETIMPURENT_D13),
          p_detimpurent_d14            :parseFloat(cabdetRent.DETIMPURENT_D14),
          p_detimpurent_fre            :parseFloat(cabdetRent.DETIMPURENT_FRE),
          p_detimpurent_saldigno       :parseFloat(cabdetRent.DETIMPURENT_SALDIGNO),
          p_detimpurent_otroingr_ngai  :parseFloat(cabdetRent.DETIMPURENT_OTROINGR_NGAI),
          p_detimpurent_otroingr_ngna  :parseFloat(cabdetRent.DETIMPURENT_OTROINGR_NGNA),
          p_detimpurent_iess_oe        :parseFloat(cabdetRent.DETIMPURENT_IESS_OE),
          p_detimpurent_deducdisca     :parseFloat(cabdetRent.DETIMPURENT_DEDUCDISCA),
          p_detimpurent_deducterce     :parseFloat(cabdetRent.DETIMPURENT_DEDUCTERCE),
          p_detimpurent_irerent_oe     :parseFloat(cabdetRent.DETIMPURENT_IRERENT_OE),
          p_detimpurent_ireasum_pp     :parseFloat(cabdetRent.DETIMPURENT_IREASUM_PP),
          p_detimpurent_ireret         :parseFloat(cabdetRent.DETIMPURENT_IRERET),
          p_detimpurent_ireret_pp      :parseFloat(cabdetRent.DETIMPURENT_IRERET_PP),
          p_detimpurent_otroingr_ngni  :parseFloat(cabdetRent.DETIMPURENT_OTROINGR_NGNI),
          P_detimpurent_ireporret      :parseFloat(cabdetRent.DETIMPURENT_IREPORRET),
          p_detimpurent_dedugpartycult :parseFloat(cabdetRent.DETIMPURENT_DEDUGPARTYCULT),  
        },
      }, this.confIniciales.httpOptions()).toPromise();
    } 
    
    async erBuscarRenta(){
      return  await this.http.post(this.way + '/nom/cabimpurent/mhhsu7yjbn81bos', {
        elementos: {},
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async erIDEmpl(emp_codigo){
      return  await this.http.post(this.way + '/nom/cabimpurent/4n2hccp6o7v31y1', {
        elementos: {
          p_emp_codigo:emp_codigo
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async btimpurent(){
      return  await this.http.post(this.way + '/nom/cabimpurent/cxfku8oa6zfuvgv', {
        elementos: {},
      }, this.confIniciales.httpOptions()).toPromise();
    }
    
    async eliminarcabimpurent(cabimpurent_codigo){
      return  await this.http.post(this.way + '/nom/cabimpurent/yk3ugy79qll9wh5', {
        elementos: {
          p_cabimpurent_codigo:cabimpurent_codigo
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async borrarimpurent(cabimpurent_codigo,detimpurent_numsec){
      return  await this.http.post(this.way + '/nom/cabimpurent/gw03tt00vdhzep4', {
        elementos: {
          p_cabimpurent_codigo:cabimpurent_codigo,
          p_detimpurent_numsec:detimpurent_numsec
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    //2s66s28lcdes8en
    async cambLiqui(CABIMPURENT_CODIGO){
      return  await this.http.post(this.way + '/nom/cabimpurent/2s66s28lcdes8en', {
        elementos: {
          lblCABIMPURENT_CODIGO:CABIMPURENT_CODIGO
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async reversarimpurent(CABIMPURENT_CODIGO){
      return  await this.http.post(this.way + '/nom/cabimpurent/zd38ssveu9oy0gd', {
        elementos: {
          strCABIMPURENT_CODIGO:CABIMPURENT_CODIGO
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async generarxml(anio,usuidentificacion){
      return  await this.http.post(this.way + '/nom/cabimpurent/4t6dssoanmbcnag', {
        elementos: {
          p_anio: parseInt(anio),
          p_com_codigo: '01',
          p_usuidentificacion: String(usuidentificacion)
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    //
    async recaimpurent(CABIMPURENT_ANIO){
      return  await this.http.post(this.way + '/nom/cabimpurent/bbhzws1oqs6ftbq', {
        elementos: {
          P_CABIMPURENT_ANIO: parseInt(CABIMPURENT_ANIO),
          p_com_codigo: '01',
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async genarchtexto(anio){
      return  await this.http.post(this.way + '/nom/cabimpurent/5upws1hh28xuj8y', {
        elementos: {
          p_anio:parseInt(anio)
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    cambiarfechanull(valor){
      let res="";
      if(isNullOrUndefined(valor)){
        return res;
      }else{
        try{
          if(valor.toString().includes('-')){
            res=this.pipe.transform(valor,'dd/MM/yyyy');
            return res;
          }else{
            return valor;
          }
        }catch(err){         
          return valor;
        } 
      }
    }
    cambiarFloatFixed(valor){
      let res="0.00";
      let resp;
      if(isNullOrUndefined(valor)){
        return res;
      }else{
        resp=(parseFloat(valor)).toFixed(2);
        if(isNaN(resp)){
          return res;
        }else{
          return resp;
        }
      }
    }
    cambiarFloat(valor){
      let res=0.00;
      let resp;
      if(isNullOrUndefined(valor)){
        return res;
      }else{
        resp=parseFloat(valor);
        if(isNaN(resp)){
          return res;
        }else{
          return resp;
        }
      }
    }

    nombreAletorio(){
      let largo = 10;
      let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
      let characterLength = characters.length;
      let result =  [];
      for( var i = 0; i < largo; i++){
          result.push(characters.charAt(Math.floor(Math.random() * characterLength )));
        }
      return result.join('');
      
    }
}
