import {Component, OnInit, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {VenmaeciudadService} from '../venservicios/venmaeciudad.service';
import {Venmaeciudad} from '../veninterfaces/venmaeciudad';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';


@Component({
  selector: 'app-venmaeciudad',
  templateUrl: './venmaeciudad.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class VenmaeciudadComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  actualizar: boolean;
  codigoAux: any;
  nuevoCampo: boolean;
  permisoCiudad: SegMaePermiso;
  largo: string;
  public frameworkComponents;
  ciudadSeleccionada: Venmaeciudad;
  rowStyle;
  defaultColDefCiudad;
  rowSelection = 'multiple';
  columnDefsCiudad = [
    {
      headerName: 'Código', field: 'CIU_CODIGO', cellEditor: 'cellPrueba', width: 150, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 5
          }
        }
    },
    {
      headerName: 'Nombre', field: 'CIU_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: false,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 30
          }
        }
    },
    {
      headerName: 'Nombre Corto', field: 'CIU_NOMBREC', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 12
          }
        }
    }];
  
  constructor(public ciudadServicio: VenmaeciudadService, private message: MessageService,
              private confirmationService: ConfirmationService, private errorService: ErroresBaseDatosService,
              private permisosService: PermisosService, private auditoriagral: AuditoriagralService,
              private init: NuevoComponentService) {
    this.agTable();
    this.permisoCiudad = {};
    this.ciudadServicio.venmaeciudades = [];
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefCiudad = {
      editable: true,
      width: 100,
      objeto: 'maeciudad'
    };
  }
  
  ngOnInit() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.actualizar = false;
    this.nuevoCampo = false;
    this.codigoAux = 0;
    this.permisosService.getListaPermisos('VEN', '1', '3604').subscribe((res) => {
      this.permisoCiudad = res[0];
    });
    this.ciudadServicio.getCiudad().subscribe((res) => {
      this.ciudadServicio.venmaeciudades = res;
    });
    this.largo = '258';
  }
  
  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      if (this.permisoCiudad.PERINSERCION === 1) {
        this.btnNuevo = true;
        this.btnGuardar = false;
        this.btnBorrar = false;
        this.btnRegresar = false;
        this.nuevoCampo = true;
        this.aggrid.isEditable(this.btnNuevo);
        this.ciudadSeleccionada = {
          CIU_NOMBRE: '', CIU_CODIGO: '', CIU_NOMBREC: '', COM_CODIGO: '01'
        };
        this.ciudadServicio.venmaeciudades.push(this.ciudadSeleccionada);
        this.aggrid.refreshaggrid(this.ciudadServicio.venmaeciudades, this.defaultColDefCiudad.objeto);
      } else if (this.permisoCiudad.PERINSERCION === 0) {
        this.message.add({
          key: 'ciu',
          severity: 'error',
          summary: 'Inserción de cidudad',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    }
    if (valor === 'Guardar') {
      if (this.permisoCiudad.PERACTUALIZACION === 1) {
        this.btnNuevo = false;
        this.btnGuardar = true;
        this.btnBorrar = false;
        this.btnRegresar = true;
        if (this.actualizar === true) {
          this.ciudadServicio.actualizarCiudad(this.ciudadSeleccionada, this.codigoAux).subscribe(() => {
            this.message.add({
              key: 'ciu',
              severity: 'success',
              summary: 'Actualización exitosa',
              detail: 'Se actualizó la ciudad correctamente'
            });
            this.aggrid.isEditable(this.btnNuevo);
            this.auditoriagral.registrarAuditoria('VEN_MAECIUDAD', String(this.ciudadSeleccionada.CIU_CODIGO), 'A', '',
              '01', '', '', '', '').subscribe(() => {});
            this.ciudadServicio.venmaeciudades = this.ciudadServicio.venmaeciudades.filter((val, j) => j !==
              this.ciudadServicio.venmaeciudades.indexOf(this.ciudadServicio.getCiudad()[this.ciudadServicio.venmaeciudades.length - 1]));
            this.aggrid.isEditable(false);
          }, error1 => {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.message.add({
              key: 'ciu',
              severity: 'error',
              summary: 'Actualización de Ciudad',
              detail: mensaje
            });
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
            this.btnRegresar = true;
            this.ciudadServicio.getCiudad().subscribe((res) => {
              this.ciudadServicio.venmaeciudades = res;
              this.aggrid.refreshaggrid(this.ciudadServicio.venmaeciudades, this.defaultColDefCiudad.objeto);
            });
          });
        } else if (this.actualizar === false) {
          this.ciudadServicio.insertarCiudad(this.ciudadSeleccionada).subscribe(() => {
            this.message.add({
              key: 'ciu',
              severity: 'success',
              summary: 'Inserción exitosa',
              detail: 'Se insertó la ciudad correctamente'
            });
            this.nuevoCampo = false;
            this.auditoriagral.registrarAuditoria('VEN_MAECIUDAD', String(this.ciudadSeleccionada.CIU_CODIGO), 'I', '',
              '01', '', '', '', '').subscribe(() => {});
            this.ciudadServicio.venmaeciudades = this.ciudadServicio.venmaeciudades.filter((val, j) => j !==
              this.ciudadServicio.venmaeciudades.indexOf(this.ciudadServicio.getCiudad()[this.ciudadServicio.venmaeciudades.length - 1]));
            this.aggrid.isEditable(false);
          }, error1 => {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.message.add({
              key: 'ciu',
              severity: 'error',
              summary: 'Inserción de Ciudad',
              detail: mensaje
            });
            this.ciudadServicio.getCiudad().subscribe((res) => {
              this.ciudadServicio.venmaeciudades = res;
              this.aggrid.refreshaggrid(this.ciudadServicio.venmaeciudades, this.defaultColDefCiudad.objeto);
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
            });
          });
        }
      } else if (this.permisoCiudad.PERACTUALIZACION === 0) {
        this.message.add({
          key: 'ciu',
          severity: 'error',
          summary: 'Actualización de Ciudad',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }
    }
    if (valor === 'Borrar') {
      if (this.permisoCiudad.PERELIMACION === 1) {
        if (this.ciudadSeleccionada === undefined) {
          this.message.add({
            key: 'ciu',
            severity: 'error',
            summary: 'Eliminacion de Retención',
            detail: 'Seleccion Incorrecta, no se ha seleccionado una Ciudad'
          });
        } else {
          this.btnNuevo = false;
          this.aggrid.isEditable(this.btnNuevo);
          this.confirmarCiudad();
        }
      } else if (this.permisoCiudad.PERELIMACION === 0) {
        this.message.add({
          key: 'ciu',
          severity: 'error',
          summary: 'Eliminación de Ciudad',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
      }
    }
    if (valor === 'Cancelar') {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.aggrid.isEditable(this.btnNuevo);
      this.ciudadServicio.getCiudad().subscribe((res) => {
        this.ciudadServicio.venmaeciudades = res;
        this.aggrid.refreshaggrid(this.ciudadServicio.venmaeciudades, this.defaultColDefCiudad.objeto);
      });
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
  seleccionCiudad(valor) {
    if (valor.object !== null) {
      this.ciudadSeleccionada = valor.object;
      if (this.nuevoCampo === true) {
        this.actualizar = false;
      } else if (this.nuevoCampo === false) {
        this.actualizar = true;
        this.codigoAux = this.ciudadSeleccionada.CIU_CODIGO;
      }
    }
  }
  
  confirmarCiudad() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar la ciudad',
      header: 'Eliminar Ciudad',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarCiu',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarciudad();
      },
      reject: () => {
      }
    });
  }
  
  eliminarciudad() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.ciudadServicio.eliminarCiudad(this.ciudadSeleccionada).subscribe(() => {
      this.message.add({
        key: 'ciu',
        severity: 'success',
        summary: 'Eliminación exitosa',
        detail: 'Se eliminó la ciudad correctamente'
      });
      this.ciudadServicio.venmaeciudades = this.ciudadServicio.venmaeciudades.filter((val, j) => j !==
        this.ciudadServicio.venmaeciudades.indexOf(this.ciudadSeleccionada));
      this.aggrid.refreshaggrid(this.ciudadServicio.venmaeciudades, this.defaultColDefCiudad.objeto);
      this.auditoriagral.registrarAuditoria('VEN_MAECIUDAD', String(this.ciudadSeleccionada.CIU_CODIGO), 'E', '',
        '01', '', '', '', '').subscribe(() => {});
      this.ciudadServicio.venmaeciudades = this.ciudadServicio.venmaeciudades.filter((val, j) => j !==
        this.ciudadServicio.venmaeciudades.indexOf(this.ciudadServicio.getCiudad()[this.ciudadServicio.venmaeciudades.length - 1]));
      this.aggrid.isEditable(false);
    });
  }
  
  cambio(cambio) {
    this.btnGuardar = false;
    this.btnRegresar = false;
  }
  
}
