import { Component, OnInit,ViewChild } from '@angular/core';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import { AggridBanComponent } from 'src/app/ban/aggrid-ban/aggrid-ban.component';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { SegmaenumeraciongService } from '../segservicios/segmaenumeraciong.service';
import { SegMaeNumeraciong } from '../seginterfaces/segmaenumeraciong';
import {DatePipe} from '@angular/common';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
@Component({
  selector: 'app-segmaenumeraciong',
  templateUrl: './segmaenumeraciong.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class SegmaenumeraciongComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  defaultColDefSegMaeNumeraciong;
  public frameworkComponents;
  rowStyle;
  largo = '258';
  rowSelection = 'multiple';
  selectedsegMaeNumeraciong: SegMaeNumeraciong; 
  btnGuardar: boolean;
  btnNuevo = true;
  btnBorrar: boolean;
  btnRegresar: boolean;
  btnBuscar: boolean;
  btnRecargar:boolean;

  columnDefMaeNumeraciong = [
   
    {
      headerName: 'Doc/Rec', field: 'D/R', cellEditor: 'cellPrueba', width: 90, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Serie', field: 'Serie', cellEditor: 'cellPrueba', width: 100, editable:true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 25,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Inicio', field: 'Inicio', cellEditor: 'cellPrueba', width: 100, editable:true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 14,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Final', field: 'Final', cellEditor: 'cellPrueba', width: 100, editable:true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 120,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Actual', field: 'Actual', cellEditor: 'cellPrueba', width: 100, editable:true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic:false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 14,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Autorización', field: 'Autorización', cellEditor: 'cellPrueba', width: 100, editable:true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic:true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 20,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Fecha Caducidad', field: 'NUM_FECHACAD', cellEditor: 'cellPrueba', width: 150, editable:true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: false,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: true,
            tamanocelda: 20,
            tienecalculos: true,
          }
        }
    },

  ]
  constructor( 
    public segmaenumeraciongService:SegmaenumeraciongService,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private confirmationService: ConfirmationService,
    private datePipe: DatePipe,
    private auditoriagral: AuditoriagralService,
    private init: NuevoComponentService) { }

  ngOnInit(): void {
    this.btnNuevo =  false;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.btnRegresar = false;
    this.btnBuscar = false;
    this.btnRecargar=false;
    this.segmaenumeraciongService.segMaeNumeraciong=[];
    this.segmaenumeraciongService.consultarNumeracionG().then(res=>{
      
      this.segmaenumeraciongService.segMaeNumeraciong=res;
     
      this.segmaenumeraciongService.segMaeNumeraciong.map(x=>{
        x.NUEVO=false;
        if(x.NUM_FECHACAD==null || x.NUM_FECHACAD==undefined){
          x.NUM_FECHACAD='';
        }else{
          if(x.NUM_FECHACAD.includes('-')){
            x.NUM_FECHACAD=this.datePipe.transform(x.NUM_FECHACAD, 'dd/MM/yyyy');
          }else{
            x.NUM_FECHACAD='';
          }
        } 
      });
    

    }).catch(e=>console.log(e.error));
    this.agTable();
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefSegMaeNumeraciong = {
      editable: true,
      width: 130,
      objeto: 'maeNumeraciong'
    };
  }
  cambioModulo(event){ 
    this.btnGuardar = false;
    this.btnRegresar = false;
    this.btnNuevo = true;
  }

  selectMaeSegMaeNumeraciog(elemento){ 
     
    if (elemento.object !== null) {
      this.selectedsegMaeNumeraciong = elemento.object;   
        if (this.selectedsegMaeNumeraciong.NUM_FECHACAD.toString().includes('-')) {
          this.selectedsegMaeNumeraciong.NUM_FECHACAD=this.datePipe.transform(this.selectedsegMaeNumeraciong.NUM_FECHACAD, 'dd/MM/yyyy');
        }
    }    
}

  manejarSenales(valor){ 
    switch (valor) {
      case 'Nuevo':
        this.opcionNuevo();
        break;
      case 'Guardar':
        this.opcionGuardar();
        break;
      case 'Borrar':
        this.opcionEliminar();
        break;
      case 'Cancelar':
       this.cargar();
        break;
      case 'Buscar':
       // this.buscarRegistro();
        break;
      case 'Salir':
       this.init.tabs.splice(this.init.tabindex, 1);
        break;
    
    }
  }
  opcionNuevo(){
    this.btnNuevo = true;
    this.btnBorrar = false;
    this.btnRegresar = false;
    this.aggrid.isEditable(true);
    this.nuevoModulo();
  }
  nuevoModulo(){
    const newModulo:  SegMaeNumeraciong= {  
      Actual:'0000001',
    Autorización:'',
    Compañia:'',
    'D/R':'',
    Final:'9999999',
    Inicio:'0000001',
    NUM_FECHACAD:'',
    Serie:'',
    NUEVO:true
  };
  
  this.segmaenumeraciongService.segMaeNumeraciong.push(newModulo); 
  this.aggrid.refreshaggrid(this.segmaenumeraciongService.segMaeNumeraciong, this.defaultColDefSegMaeNumeraciong.objeto ); 
  const indice= this.segmaenumeraciongService.segMaeNumeraciong.indexOf(newModulo);
  this.aggrid.gridApi.setFocusedCell(indice , 'D/R');
  }
  opcionGuardar(){
    let paquete = '';
  
    const modulo: SegMaeNumeraciong = {
      Actual:this.selectedsegMaeNumeraciong.Actual,
      Autorización:this.selectedsegMaeNumeraciong.Autorización,
     
      'D/R':this.selectedsegMaeNumeraciong["D/R"],
      Final:this.selectedsegMaeNumeraciong.Final,
      Inicio:this.selectedsegMaeNumeraciong.Inicio,
      NUM_FECHACAD:this.selectedsegMaeNumeraciong.NUM_FECHACAD,
      
      Serie:this.selectedsegMaeNumeraciong.Serie,
      NUEVO:this.selectedsegMaeNumeraciong.NUEVO
    };
       let valido = true;
      
    for ( const key in modulo ) { 
      if ( modulo[key] === null || modulo[key] === '') {
        valido = false;
      }
    }
    if ( valido ) {
      
      if(this.selectedsegMaeNumeraciong.NUEVO==true){
        this.aggrid.gridApi.stopEditing();
        paquete='SACI_PCK_MAENUMERACIONG.SACI_SP_insertarNumeracionG';
        console.log(this.selectedsegMaeNumeraciong);
        this.segmaenumeraciongService.insertarNumeracionG(paquete, this.selectedsegMaeNumeraciong).then(res=>{
          this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
          this.cargar();
          this.auditoriagral.registrarAuditoria('SACI_MAENUMERACION', String(this.selectedsegMaeNumeraciong["D/R"]+"/SEG"), 'I',
          '', '01', '', '', '', '').toPromise().then(() => {
          });
        }).catch(e=>{
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        });    
      }else if(this.selectedsegMaeNumeraciong.NUEVO==false){
        paquete='SACI_PCK_MAENUMERACIONG.SACI_SP_actualizarNumeracionG';
         this.aggrid.gridApi.stopEditing();
         this.segmaenumeraciongService.actualizarNumeracionG(paquete, this.selectedsegMaeNumeraciong).then(res=>{
          this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
          this.cargar();
          this.auditoriagral.registrarAuditoria('SACI_MAENUMERACION', String(this.selectedsegMaeNumeraciong["D/R"]+"/SEG"), 'A',
          '', '01', '', '', '', '').toPromise().then(() => {
          });
         }).catch(e=>{
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        });   
      }
    }else{
      this.mensajeAlerta('info', 'Información', 'Se han encontrado campos vacíos');
    }

  }
  opcionEliminar(){
    let paquete='SACI_PCK_MAENUMERACIONG.SACI_SP_eliminarNumeracionG';
  if ( this.selectedsegMaeNumeraciong !== undefined ) {
    this.confirmationService.confirm({
      message: '¿Está seguro que desea eliminar este registro?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'dsegNumeraciong',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        this.segmaenumeraciongService.eliminarNumeracionG(paquete, this.selectedsegMaeNumeraciong["D/R"]).then(res=>{
          this.mensajeAlerta('success', 'Información', '¡Numeración eliminada exitosamente!');
          this.cargar();
          this.auditoriagral.registrarAuditoria('SACI_MAENUMERACION', String(this.selectedsegMaeNumeraciong["D/R"]+"/SEG"), 'E',
          '', '01', '', '', '', '').toPromise().then(() => {
          });
        }).catch(e=>{
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        }); 
      },
      reject: () => {
      }
    })
  }
  }
  cargar(){
    this.btnNuevo =  false;
    this.btnGuardar = true;
    this.btnRegresar = false;
    this.btnBuscar = false;
    this.segmaenumeraciongService.consultarNumeracionG().then(res=>{
      
      this.segmaenumeraciongService.segMaeNumeraciong=res;
      this.segmaenumeraciongService.segMaeNumeraciong.map(x=>{
        x.NUEVO=false;
        if(x.NUM_FECHACAD==null || x.NUM_FECHACAD==undefined){
          x.NUM_FECHACAD='';
        }else{
          if(x.NUM_FECHACAD.includes('-')){
            x.NUM_FECHACAD=this.datePipe.transform(x.NUM_FECHACAD, 'dd/MM/yyyy');
          }else{
            x.NUM_FECHACAD='';
          }
        } 
      });
    

    }).catch(e=>console.log(e.error));
  }
  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'msegMaeNumeraciong',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
    
  }
}
