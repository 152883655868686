import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Usuario } from 'src/app/usuario';
import { ProdmaecostoindService } from '../prodservicios/prodmaecostoind.service';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { MessageService, ConfirmationService, SelectItem } from 'primeng';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { CostosIndirectos } from '../prodinterfaces/prodmaecostoind';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { NomtransotregresosService } from 'src/app/nom/nomservicios/nomtransotregresos.service';

@Component({
  selector: 'app-prodmaecostoind',
  templateUrl: './prodmaecostoind.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class ProdmaecostoindComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnGuardar = true;
  btnBorrar = true;
  btnRegresar = true;
  btnNuevo = false;

  botonPrimero = true;
  botonAnterior = true;
  botonSiguiente = true;
  botonUltimo = true;
  btnBuscar = true;

  fecha = new Date();
  identiUsuario = '';


  defaultColDefCostoInd;
  public frameworkComponents;
  rowStyle;
  largo = '258';
  rowSelection = 'multiple';

  costosInd = [];
  selectedCostoInd;


  types: SelectItem[];
  tabla: string;
  consulta: string;
  busquedacampos: any[];
  where: string;
  arregloCons: any[];
  arregloBus: any[];

  /*Cuenta Contable */
  arregloCuenCont =[];
  displayDialogBusquedaFastCuenCont = false;
  displayDialogBusquedaCentroCostos = false;


  columnDefDetCostoInd = [
    {
      headerName: 'Código', field: 'CIN_CODIGO', cellEditor: 'cellPrueba', width: 100,  cellStyle: {textAlign: 'right'},
      editable: (params) => {
        return (  params.data.nuevo ) ? true : false;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Nombre', field: 'CIN_NOMBRE', cellEditor: 'cellPrueba', width: 300,  cellStyle: {textAlign: 'right'},
      editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'CostoUnitario', field: 'CIN_COSTO_U', cellEditor: 'cellPrueba', width: 120,  cellStyle: {textAlign: 'right'},
      editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: false,
            int: true
          }
        }
    },
    {
      headerName: 'Ref.Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 120,  cellStyle: {textAlign: 'right'},
      editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: false
          }
        }, onCellValueChanged: (params) => {
         
          this.busCuentaCons();
       
        
      },
    },
    {
      headerName: 'CentroCostos', field: 'CEN_CODIGO', cellEditor: 'cellPrueba', width: 120,  cellStyle: {textAlign: 'right'},
      editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 400,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
         
        this.busquedaCentroCostos();
     
      
     },
    },


  ]

  constructor(
    private datePipe: DatePipe,
    public usuario: Usuario,
    private costoIndSrv: ProdmaecostoindService,
    private errorService: ErroresBaseDatosService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private init: NuevoComponentService,
    
    private otregresoSrv: NomtransotregresosService,
  ) {
    this.identiUsuario = this.usuario.identificacion;
    this.datePipe.transform(this.fecha, 'dd/MM/yy');
    this.agTable();
   }

   agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefCostoInd = {
      editable: true,
      width: 130,
      objeto: 'costosInd'
    };
  }

  ngOnInit(): void {
    this.llenarDatos();
  }

  async llenarDatos(){
    this.btnGuardar = true;
    this.btnBorrar = true;
    await this.costoIndSrv.llenarDatos().then( costos => {
     
      this.costosInd = [ ...costos ];
      this.costosInd.map ( costo => {
        costo.CIN_COSTO_U = costo.CIN_COSTO_U.toFixed(2);
      } )

      this.aggrid.refreshaggrid(this.costosInd, this.defaultColDefCostoInd.objeto);

    } ).catch( e => {
     
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    } );
  }

  manejarSenales(valor){
    switch (valor) {
      case 'Nuevo':
        this.btnBorrar = true;
        this.ingresarNuevo();
      break;
      case 'Guardar':
        this.guardar();
      break;
      case 'Cancelar':
        this.llenarDatos();
      break;
      case 'Borrar':
        this.confirmationService.confirm({
          message: '¿Está seguro que desea eliminar el registro?',
          header: 'Confirmación',
          icon: 'pi pi-exclamation-triangle',
          key: 'confirmCostoInd',
          acceptLabel: 'Sí',
          rejectLabel: 'No',
          accept: () => {
            this.borrar();
  
          },
          reject: () => {
          }
        })
      break;
      case 'Salir':
        this.init.tabs.splice(this.init.tabindex, 1);
      break;
    }
  }

  cambio(event){
    this.btnGuardar = false;
    this.selectedCostoInd.editado = true;
  }

  seleccion(valor){
    this.selectedCostoInd = valor.object;
    this.btnBorrar = false;
  }

  ingresarNuevo(){
    let newCostoInd  = {
      CEN_CODIGO: '',
      CIN_CODIGO: '',
      CIN_COSTO_U: '',
      CIN_NOMBRE: '',
      COM_CODIGO:  '01',
      CON_CODIGO: '',
      editado: false,
      nuevo: true,
    };
    newCostoInd.nuevo = true;
    this.costosInd.push(newCostoInd);
    this.aggrid.refreshaggrid(this.costosInd, this.defaultColDefCostoInd.objeto);
  }

  async guardar(){
    let resolve = true;
    this.aggrid.gridApi.stopEditing();
    for( let costo of this.costosInd ) {
      
      if(costo.editado && costo.nuevo ){
        console.log(costo, 'nuevo');
        
        resolve = await this.insCostoIndirecto(costo);
      }else if(costo.editado)  {
        console.log(costo, 'no nuevo');
        resolve = await this.actCostoIndirecto(costo);
      }
    }
    console.log('terminado')
    if(  resolve) {

      this.mensajeAlerta('success', 'Información', 'Datos guardados satisfactoriamente');
    }

    this.llenarDatos();
  }

  async insCostoIndirecto(costoInd :CostosIndirectos){
    let resolve = true;
    costoInd.CIN_COSTO_U = Number(costoInd.CIN_COSTO_U).toFixed(2)
    await this.costoIndSrv.insCostoIndirecto( costoInd ).catch( e => {
      resolve = false;
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    } );
    return resolve;
  }

  async actCostoIndirecto(costoInd :CostosIndirectos){
    let resolve = true;
    costoInd.CIN_COSTO_U = Number(costoInd.CIN_COSTO_U).toFixed(2)
    await this.costoIndSrv.actCostoIndirecto( costoInd ).catch( e => {
     
      resolve = false;
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    } );
    return resolve;
  }

  async borrar(){
    let resolve = true;
    await this.costoIndSrv.eliCostoIndirecto( this.selectedCostoInd ).then( () => {
      this.mensajeAlerta('success', 'Información', 'Datos eliminados satisfactoriamente');
      this.llenarDatos();
    } )
    
    .catch( e => {
      
      resolve = false;
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    } );
    return resolve;
  }

  busCuentaCons(){
    
    //this.opcion = 'BuscarCuentaCons';
    this.types = [
      {label: 'CODIGO', value: 'CON_CODIGO'},
      {label: 'NOMBRE', value: 'CON_NOMBRE'},
      {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
      //CNT. COSTOS
    ];
    this.busquedacampos = ['', '', ''];
    this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
    this.tabla = 'CON_MAECON';
    this.where = `COM_CODIGO = '01' and rownum < 200 ORDER BY CON_CODIGO`;
   
    this.otregresoSrv.busCuentaCons(this.consulta,  this.where ).then((res: any[]) => {
      if(res!=null){
        this.arregloCuenCont = res;
        this.displayDialogBusquedaFastCuenCont = true;
      }

      
    });
  }

  manejarSeleccionCuenCont(ctaContable){
    if( ctaContable.CON_CODIGO.charAt( ctaContable.CON_CODIGO.length -1  ) === '.'  ){
      this.mensajeAlerta('error', 'Error', `No puede seleccionar una cuenta mayor`);

    }else{
      this.selectedCostoInd.CON_CODIGO = ctaContable.CON_CODIGO;
      this.aggrid.refreshaggrid(this.costosInd, this.defaultColDefCostoInd.objeto);
    }
    
    this.displayDialogBusquedaFastCuenCont = false;
  }


  busquedaCentroCostos(){
    this.types = [
      {label: 'CÓDIGO', value: 'CODIGO'},
      {label: 'NOMBRE', value: 'NOMBRE'}
    ];

    this.busquedacampos = ['', ''];
    this.consulta= `CEN_codigo as Codigo,CEN_nombre as Nombre`;
    this.tabla = 'CON_MAECEN';
    this.where = '';


    this.otregresoSrv.busquedaCentroCostos(this.consulta,  this.where).then((res: any[]) => {
      
      
      this.arregloCons = [ ...res ];
      this.displayDialogBusquedaCentroCostos = true;
    }).catch( e => {
     
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    } );
  }

  manejarSeleccionCentroCost(centroCosto){
    this.selectedCostoInd.CEN_CODIGO = centroCosto.CODIGO;
    this.aggrid.refreshaggrid(this.costosInd, this.defaultColDefCostoInd.objeto);
    this.displayDialogBusquedaCentroCostos = false;
  }

  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'costInd',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
  }

}
