//
// SACI WEB
// Rutina: Pto Vta Calculadora
// Propietario: Victor Perez, Wilson Ramos
// Modificado por: VP, WR
// Fecha de creación: 2019
// Fecha de Modificación: 2019
//
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  DoCheck,
  Input,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  AfterViewChecked, ChangeDetectorRef
} from '@angular/core';
import {PdvfacturaService} from '../../servicios/pdvfactura.service';
import {VenEncfac} from '../../interfaces/venencfac';
import {Maearticulo} from '../../interfaces/maearticulo';
import {Maecliente} from '../../interfaces/maecliente';
import {Maevendedor} from '../../interfaces/maevendedor';
import {ConfirmationService, MessageService} from 'primeng/api';
import {CalculosServiceService} from '../../servicios/calculos-service.service';
import {VenDetfac} from '../../interfaces/vendetfac';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';

@Component({
  selector: 'app-pdvcalculadora',
  templateUrl: './pdvcalculadora.component.html',
  styleUrls: ['./pdvcalculadora.component.css'],
  preserveWhitespaces: false
})
export class PdvcalculadoraComponent implements OnInit, DoCheck, OnChanges, AfterViewInit, AfterViewChecked {
  @Output() valor = new EventEmitter<number>();
  @Output() bolcob = new EventEmitter<boolean>();
  @Output() codCliente = new EventEmitter<string>();
  @Output() numfact = new EventEmitter<string>();
  @Output() openTeclado = new EventEmitter<string>();
  @Output() estado = new EventEmitter<string>();
  @Output() asiento = new EventEmitter<string>();
  @Output() pagada = new EventEmitter<boolean>();
  @Input() inpcliente: Maecliente;
  @Input() inpvendedor: Maevendedor;
  @Input() auxiliar: any;
  @Input() factura: string;
  @Input() inpfactura: VenEncfac;
  @Input() inpchkfactura: boolean;
  @Input() inpDate: Date;
  @Input() nomArt: any;
  @Input() facPagada: boolean;
  @Input() initPrin: number;
  @Input() producto: Maearticulo;
  @Input() fechainicial: Date;
  @Input() fechafinal: Date;
  @Input() valorDscGlobalP: number;
  @Input() valorDscGlobalV: number;
  @Input() desenfoque: boolean;
  productobol = false;
  venencfac: VenEncfac;
  respuestadet: any;
  cantidad: string;
  prodSeleccionado: Maearticulo;
  indexProdSeleccionado: number;
  respuesta: any;
  string = '';
  stringTeclado = '';
  selectedArticulos: Maearticulo[];
  filteredArticuloSingle: any[];
  objsel: any[] = [];
  query: any = undefined;
  darFocus: [number] = [0];
  dscGlobal: boolean;
  dscGValor: boolean;
  dscGPorcentaje: boolean;
  dscSeleccionado: string;
  valorInvalidoV: boolean;
  valorInvalidoP: boolean;
  subtotal: [any];
  botonDsc: boolean;
  valorDscGV: number;
  valorDscGP: number;
  
  constructor(private servicePdv: PdvfacturaService, private confirmationService: ConfirmationService,
              private calc: CalculosServiceService, private messageService: MessageService, private cd: ChangeDetectorRef,
              private auditoriagral: AuditoriagralService) {
  }
  
  ngOnInit() {
    this.facPagada = false;
    this.darFocus[this.initPrin - 1] = 0;
    this.dscGlobal = false;
    this.dscGValor = true;
    this.dscGPorcentaje = true;
    this.valorDscGlobalP = undefined;
    this.valorDscGlobalV = undefined;
    this.subtotal = [0];
    this.valorInvalidoV = false;
    this.valorInvalidoP = false;
    this.botonDsc = true;
  }
  
  ngAfterViewInit(): void {
    this.cd.detach();
  }
  
  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }
  
  ngDoCheck(): void {
    this.prodSeleccionado = this.calc.recibirArt();
    this.indexProdSeleccionado = this.calc.recibirIndex();
    this.string = this.calc.returnCalcValue();
    this.darFocus = this.calc.getFocus();
    this.subtotal = this.calc.entregarSubtotal();
    if (this.darFocus[this.initPrin - 1] === 1 && this.facPagada === false) {
      this.enfocar();
    }
    this.calc.recibirValorTeclado(this.stringTeclado);
    if (this.dscSeleccionado === 'dscGValor') {
      this.dscGValor = false;
      this.dscGPorcentaje = true;
    } else if (this.dscSeleccionado === 'dscGPorcentaje') {
      this.dscGPorcentaje = false;
      this.dscGValor = true;
    }
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    for (const propertyName in changes) {
      if (propertyName === 'nomArt' && this.nomArt !== (undefined || null)) {
        if (this.nomArt !== (undefined || null || 9999999999999)) {
          this.filterArticuloSingle(this.nomArt);
        } else if (this.nomArt === 9999999999999) {
          this.nomArt = '';
          this.openTeclado.emit('cerrar');
        }
      } else if (propertyName === 'producto') {
        this.vaciarArreglo();
      } else if (propertyName === 'valorDscGlobalP') {
        if (this.valorDscGlobalP !== (undefined || null || 9999999999999)) {
          this.valorDscGP = this.valorDscGlobalP;
          this.comprobarValor();
        } else if (this.valorDscGlobalP === 9999999999999) {
          this.openTeclado.emit('cerrar');
        }
      } else if (propertyName === 'valorDscGlobalV') {
        if (this.valorDscGlobalV !== (undefined || null || 9999999999999)) {
          this.valorDscGV = this.valorDscGlobalV;
          this.comprobarValor();
        } else if (this.valorDscGlobalV === 9999999999999) {
          this.openTeclado.emit('cerrar');
        }
      } else if (propertyName === 'desenfoque') {
        if (this.desenfoque === true && this.darFocus[this.initPrin - 1] === 0) {
          this.desenfocar();
        }
      }
    }
  }
  
  teclado(valor: string) {
    this.calc.editFocus(0, this.initPrin);
    this.openTeclado.emit(valor);
  }
  
  enviarNumero(num: number) {
    this.productobol = false;
    this.cantidad = this.cantidad + '' + num;
    this.valor.emit(num);
    this.bolcob.emit(false);
  }
  
  aplicarDescuentoGlobal() {
    this.bolcob.emit(false);
    this.dscGlobal = true;
    this.darFocus[this.initPrin - 1] = 0;
    this.vaciarArreglo();
  }
  
  enviarDscGlobal() {
    this.dscGlobal = false;
    this.calc.descGlobalV2(this.valorDscGV, this.valorDscGP, this.initPrin, this.calc.recibirFacComun()[this.initPrin - 1]);
    this.valorDscGlobalP = undefined;
    this.valorDscGlobalV = undefined;
  }
  
  comprobarValor() {
    if (this.dscGValor === false) {
      this.valorDscGP = Number(this.valorDscGV * 100) / Number(this.subtotal[this.initPrin - 1]);
      this.valorDscGP = Number(this.valorDscGP.toFixed(2));
      if (this.valorDscGV > Number(this.subtotal[this.initPrin - 1])) {
        this.valorInvalidoV = true;
        this.botonDsc = true;
      } else {
        this.valorInvalidoV = false;
        this.botonDsc = false;
      }
    } else if (this.dscGPorcentaje === false) {
      this.valorDscGV = Number(this.valorDscGP * this.subtotal[this.initPrin - 1]) / 100;
      this.valorDscGV = Number(this.valorDscGV.toFixed(2));
      if (this.valorDscGP > 100) {
        this.valorInvalidoP = true;
        this.botonDsc = true;
      } else {
        this.valorInvalidoP = false;
        this.botonDsc = false;
      }
    }
  }
  
  confirmarEliminar() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar esta factura',
      header: 'Eliminar factura Actual',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarCalc',
      accept: () => {
        this.eliminarFactura();
      },
      reject: () => {
      }
    });
  }
  
  eliminarFactura() {
    if (this.inpfactura.ENCFAC_ESTADO === 'PAGADA') {
      this.messageService.add({
        key: 'calc',
        severity: 'warn',
        summary: 'No se pudo eliminar',
        detail: 'La factura cuenta con una forma de pago y no se puede eliminar',
      });
      return;
    }
    this.calc.eliminarFac(this.initPrin);
    const aux = this.eliminarDetalle();
    if (aux === 0) {
      const aux1 = this.eliminarFac();
      if (aux1 === 0) {
        this.messageService.add({
          key: 'calc',
          severity: 'success',
          summary: 'Factura Eliminada',
          detail: 'Se elimino la factura de la base de datos',
        });
        if (this.inpfactura.ASI_NRO !== undefined && this.inpfactura.ASI_NRO !== null && this.inpfactura.ASI_NRO !== '') {
          // console.log(this.inpfactura.ASI_NRO);
          this.servicePdv.anularAsiento(this.inpfactura.ASI_NRO).subscribe((response: any[]) => {
            this.respuestadet = response;
          });
        }
        
      } else {
        this.messageService.add({
          key: 'calc',
          severity: 'error',
          summary: 'Factura No Eliminada',
          detail: 'Ocurrio un error al eliminar la factura',
        });
      }
      this.codCliente.emit('9999999999999');
    }
  }
  
  eliminarDetalle(): number {
    let respuesta = [];
    this.servicePdv.borrarTodoDetalle(this.inpfactura.ENCFAC_NUMERO).subscribe((res) => {
      respuesta = res;
    });
    if (respuesta.length === 0) {
      return 0;
    } else {
      return 1;
    }
  }
  
  eliminarFac(): number {
    let response = [];
    this.servicePdv.eliminarFac(this.inpfactura.ENCFAC_NUMERO).subscribe((res) => {
      response = res;
    });
    if (response.length === 0) {
      return 0;
    } else {
      return 1;
    }
    
  }
  
  cobrar() {
    this.darFocus[this.initPrin - 1] = 0;
    
    
    if (this.validarcobrar() === false) {
      return;
    }
    
    if (this.inpfactura.ENCFAC_ESTADO === 'PAGADA') {
      this.bolcob.emit(true);
      return;
    }
    
    this.venencfac = {};
    if (this.inpfactura.ENCFAC_NUMERO === undefined) {
      this.venencfac.ENCFAC_NUMERO = null;
      this.venencfac.ENCFAC_DIRECCION = this.inpcliente.CLI_DIRECCION1;
      this.venencfac.ENCFAC_TELEFONO = '';
      this.venencfac.ENCFAC_RUCIDE = this.inpcliente.CLI_RUCIDE;
      this.venencfac.ENCFAC_AUTORIZACION = null;
      this.venencfac.ENCFAC_ESTADO = 'P';
      this.venencfac.ENCFAC_FORMAPAGO = '';
      this.messageService.add({
        key: 'calc',
        severity: 'success',
        summary: 'Factura Guardada',
        detail: 'Se guardo la factura',
      });
      this.auditoriagral.registrarAuditoria('VEN_ENCFAC', this.inpfactura.ENCFAC_NUMERO,
        'I', 'admin', '01', 'admin', '', '', '').subscribe(() => {
      });
    } else {
      this.servicePdv.borrarTodoDetalle(this.inpfactura.ENCFAC_NUMERO).subscribe(() => {
      });
      this.venencfac.ENCFAC_NUMERO = this.inpfactura.ENCFAC_NUMERO;
      this.venencfac.ENCFAC_DIRECCION = this.inpfactura.ENCFAC_DIRECCION || this.inpcliente.CLI_DIRECCION1;
      this.venencfac.ENCFAC_TELEFONO = this.inpfactura.ENCFAC_TELEFONO || '';
      this.venencfac.ENCFAC_RUCIDE = this.inpfactura.ENCFAC_RUCIDE || this.inpcliente.CLI_RUCIDE;
      this.venencfac.ENCFAC_AUTORIZACION = this.inpfactura.ENCFAC_AUTORIZACION;
      if (this.inpfactura.ENCFAC_FORMAPAGO === undefined || this.inpfactura.ENCFAC_FORMAPAGO === null ||
        this.inpfactura.ENCFAC_FORMAPAGO === '') {
        this.venencfac.ENCFAC_FORMAPAGO = '';
      } else {
        this.venencfac.ENCFAC_FORMAPAGO = this.inpfactura.ENCFAC_FORMAPAGO;
      }
      if (this.inpfactura.ENCFAC_ESTADO === 'PAGADA') {
        this.venencfac.ENCFAC_ESTADO = 'G';
      } else {
        this.venencfac.ENCFAC_ESTADO = 'P';
      }
      this.messageService.add({
        key: 'calc',
        severity: 'success',
        summary: 'Factura Guardada',
        detail: 'Se Actualizo la factura',
      });
      this.auditoriagral.registrarAuditoria('VEN_ENCFAC', this.inpfactura.ENCFAC_NUMERO,
        'A', '', '01', '', '', '', '').subscribe(() => {
      });
    }
    this.venencfac.ENCFAC_FECHAEMISION = this.inpDate.toLocaleString('en-GB', {timeZone: 'America/Bogota'}).replace(',', '').substr(0, 16);
    this.venencfac.ENCFAC_FECHAENTREGA = this.inpDate.toLocaleString('en-GB', {timeZone: 'UTC'}).substr(0, 10);
    this.venencfac.CLI_CODIGO = this.inpcliente.CLI_CODIGO;
    this.venencfac.CLI_NOMBRE = this.inpcliente.CLI_NOMBRE;
    this.venencfac.BOD_CODIGO = this.inpvendedor.BOD_CODIGO;
    this.venencfac.VEN_CODIGO = this.inpvendedor.VEN_CODIGO;
    this.venencfac.ENCFAC_LISTA = 'A';
    this.venencfac.ENCFAC_FECHAVENCIMIENTO = this.inpDate.toLocaleString('en-GB', {timeZone: 'UTC'}).substr(0, 10);
    this.venencfac.ENCFAC_TOTAL = Number(this.calc.entregarTotal()[this.initPrin - 1]);
    this.venencfac.ENCFAC_TOTALNETO = Number(this.calc.entregarSubtotal()[this.initPrin - 1]);
    this.venencfac.ENCFAC_PORCEIVA = '12';
    this.venencfac.ENCFAC_VALORIVA = Number(this.calc.entregarIVA()[this.initPrin - 1]);
    this.venencfac.ENCFAC_PORCECAR = 0;
    this.venencfac.ENCFAC_VALORCAR = 0;
    this.venencfac.ENCFAC_PORCEDES = Number(this.calc.entregarDescuentoP()[this.initPrin - 1]);
    this.venencfac.ENCFAC_VALORDES = Number(this.calc.entregarDescuento()[this.initPrin - 1]);
    this.venencfac.ENCFAC_VALORDEV = 0;
    this.venencfac.ENCFAC_PORCEINT = 0;
    this.venencfac.ENCFAC_VALORINT = 0;
    this.venencfac.ENCFAC_PORCEREC = 0;
    this.venencfac.ENCFAC_VALORREC = 0;
    this.venencfac.ENCFAC_VALORICE = 0;
    this.venencfac.ENCFAC_PORCEICE = 0;
    this.venencfac.ENCFAC_IMPRESO = 'N';
    this.venencfac.ENCFAC_COMENTARIO = 'VICTOR';
    this.venencfac.ENCFAC_TIPOFAC = 'S';
    this.venencfac.ENCFAC_GENERAFAC = 'N';
    this.venencfac.ENCFAC_CHOFER = '';
    this.venencfac.ENCFAC_CARRO = '';
    this.venencfac.ENCFAC_PLACA = '';
    this.venencfac.ENCFAC_IVA = '1';
    this.venencfac.ENCFAC_BASEIVA = Number(this.calc.entregarBaseIVA()[this.initPrin - 1]);
    this.venencfac.ENCFAC_BASEICE = 0;
    this.venencfac.ENCFAC_BASECERO = Number(this.calc.entregarBaseCero()[this.initPrin - 1]);
    this.venencfac.ENCFAC_OTROS = 0;
    this.venencfac.ENCFAC_GRUPO = null;
    this.venencfac.ENCFAC_DIASCRDT = 0;
    this.venencfac.ENCFAC_CLIDSCT = 0;
    this.venencfac.ENCFAC_ORIGEN = 'PTOVTA';
    this.venencfac.ENCFAC_TIPODSCTO = null;
    this.venencfac.ENCFAC_SECACTUAL = null;
    this.venencfac.ENCFAC_REFCLI = null;
    if (this.inpchkfactura === false) {
      this.venencfac.ENCFAC_NOTADEVENTA = 'S';
    } else {
      this.venencfac.ENCFAC_NOTADEVENTA = null;
    }
    this.venencfac.ENCFAC_PLACA_CLI = null;
    this.servicePdv.insertarFacturaPtoVta(this.venencfac).subscribe((respuesta: any[]) => {
      this.respuesta = respuesta;
      this.numfact.emit(this.respuesta['0'][':B2']);
      for (let i = 0; i < this.calc.recibirFacComun()[this.initPrin - 1].length; i++) {
        const vendetfac: VenDetfac = {};
        const detalle: Maearticulo = this.calc.recibirFacComun()[this.initPrin - 1][i];
        vendetfac.DETFAC_ORDEN = i + 1;
        vendetfac.DETFAC_LINEA = i + 1;
        vendetfac.ENCFAC_NUMERO = this.respuesta['0'][':B2'];
        vendetfac.DETFAC_LINEA_3 = 0;
        vendetfac.DETFAC_TIPODET = 'A';
        vendetfac.BOD_CODIGO = '001';
        vendetfac.DETFAC_CODIGO = detalle.ART_CODIGO;
        vendetfac.DETFAC_DESCRIPCION = detalle.ART_NOMBREC;
        vendetfac.DETFAC_TRIBIVA = detalle.ART_TRIBUTAIVA;
        vendetfac.DETFAC_TRIBICE = 'N';
        vendetfac.DETFAC_SIGNO = '+';
        vendetfac.DETFAC_CANTIDAD = Number(detalle.CANTIDAD);
        vendetfac.DETFAC_PRECIO = Number(detalle.ARTPRE_PRECIO);
        vendetfac.DETFAC_DESCUENTO = Number(detalle.DESCUENTO);
        vendetfac.DETFAC_TOTAL = Number(detalle.CANTIDAD) * Number(detalle.ARTPRE_PRECIO);
        if (detalle.ART_TRIBUTAIVA === 'S') {
          vendetfac.DETFAC_IVA = (detalle.ARTPRE_PRECIO * detalle.CANTIDAD
            - ((detalle.DESCUENTO / 100) * (detalle.ARTPRE_PRECIO * detalle.CANTIDAD))) * 12 / 100;
        } else {
          vendetfac.DETFAC_IVA = 0;
        }
        vendetfac.DETFAC_ICE = 0;
        vendetfac.DETFAC_LISTA = 'A';
        if (detalle.ART_TRIBUTAIVA === 'S') {
          vendetfac.DETFAC_BASEIVA = (detalle.ARTPRE_PRECIO * detalle.CANTIDAD
            - ((detalle.DESCUENTO / 100) * (detalle.ARTPRE_PRECIO * detalle.CANTIDAD)));
          vendetfac.DETFAC_BASECERO = 0;
        } else {
          vendetfac.DETFAC_BASEIVA = 0;
          vendetfac.DETFAC_BASECERO = (detalle.ARTPRE_PRECIO * detalle.CANTIDAD
            - ((detalle.DESCUENTO / 100) * (detalle.ARTPRE_PRECIO * detalle.CANTIDAD)));
        }
        vendetfac.DETFAC_BASEICE = 0;
        vendetfac.DETFAC_PORCEICE = 0;
        vendetfac.ENCCOT_NUMERO = null;
        vendetfac.DETCOT_LINEA = 0;
        vendetfac.ENCPED_NUMERO = null;
        vendetfac.DETPED_LINEA = i + 1;
        vendetfac.ENCGRE_NUMERO = null;
        vendetfac.DETGRE_LINEA = 0;
        vendetfac.UNI_SIMBOLO = detalle.ART_UNIDADVENTA;
        vendetfac.DETFAC_TIPO = null;
        vendetfac.DETFAC_CODIGOREF = null;
        vendetfac.ENCFAC_NUMERO = this.respuesta['0'][':B2'];
        vendetfac.DETFAC_PROMOCION = 0;
        vendetfac.DETFAC_ORDEN = i + 1;
        vendetfac.DETFAC_LINORGPROM = 0;
        vendetfac.MAEPRM_CODIGO = 0;
        vendetfac.DETFAC_FECHAREALIZACION = null;
        vendetfac.DETFAC_PRECIOC = 0;
        vendetfac.CEN_CODIGO = null;
        vendetfac.DETFAC_BULTO = null;
        vendetfac.DETFAC_CANTIDADB = 0;
        vendetfac.DETFAC_TIPOSRI = null;
        vendetfac.DETFAC_SIGNO = '+';
        vendetfac.DETFAC_ARTACAMBIO = null;
        vendetfac.ORDTRA_NUMERO = null;
        vendetfac.DETORDTRA_LINEA = 0;
        vendetfac.ENCREQ_NUMERO = null;
        vendetfac.DETREQ_LINEA = 0;
        vendetfac.DETFAC_BASENOOBJIVA = 0;
        vendetfac.DETFAC_TRIBASENOOBJIVA = null;
        vendetfac.ENCMAT_NUMERO = null;
        vendetfac.DETMAT_CODIGO = 0;
        vendetfac.DETFAC_ORDENLOTE = 0;
        this.servicePdv.insertarDetalle(vendetfac).subscribe((response: any[]) => {
          this.respuestadet = response;
        });
      }
      this.servicePdv.actualizarkardex(this.respuesta['0'][':B2']).subscribe(() => {
      });
      
      if (this.inpfactura.ASI_NRO !== undefined && this.inpfactura.ASI_NRO !== '' && this.inpfactura.ASI_NRO !== null) {
        this.servicePdv.generarAsientoContableCredito(this.inpfactura.ENCFAC_NUMERO).subscribe(() => {
          // console.log(respuesta3);
          this.messageService.add({
            key: 'calc',
            severity: 'success',
            summary: 'Asiento Contable',
            detail: 'Asiento generado correctamente',
          });
          let asinro = '';
          this.servicePdv.retornarAsiento(this.inpfactura.ENCFAC_NUMERO).subscribe((respuasi: any[]) => {
            for (const i of respuasi) {
              asinro = i.ASI_NRO;
            }
            this.estado.emit('PENDIENTE');
            this.asiento.emit(asinro);
            this.pagada.emit(false);
          });
        }, error1 => {
          console.log(error1.error);
        });
      }
    }, error1 => {
      console.log(error1.error);
    });
    this.bolcob.emit(true);
  }
  
  regresarPago() {
    this.bolcob.emit(false);
  }
  
  buscarProducto() {
    this.calc.editFocus(0, this.initPrin);
    this.productobol = true;
    /*this.servicePdv.recogerCodigoProd(this.stringTeclado).subscribe((respuesta: any[]) => {
      if (respuesta.length === 0) {
        this.messageService.add({
          key: 'calc',
          severity: 'info',
          summary: 'Productos Seleccionados',
          detail: 'No existe producto con ese nombre'
        });
        this.stringTeclado = undefined;
      } else {
        console.log(respuesta[0]);
        this.codProd = respuesta[0].ART_CODIGO;
        this.servicePdv.recogerProducto(this.codProd).subscribe((respuestas: any[]) => {
          this.selectedArticulos = respuestas;
          this.calc.llenarFac(this.selectedArticulos[0], '0');
          this.stringTeclado = undefined;
        });
      }
    });*/
  }
  
  borrarArreglo() {
    this.calc.reducirCant();
  }
  
  buscarArticulo() {
    this.servicePdv.recogerProducto(this.stringTeclado).subscribe((respuesta: any[]) => {
      if (respuesta.length === 0) {
        this.messageService.add({
          key: 'calc',
          severity: 'warn',
          summary: 'Productos Seleccionados',
          detail: 'No existe producto con ese codigo'
        });
        this.selectedArticulos = respuesta;
        this.stringTeclado = undefined;
      } else {
        this.selectedArticulos = respuesta;
        if (this.inpfactura.ENCFAC_NUMERO === undefined || this.inpfactura.ENCFAC_NUMERO === null || this.inpfactura.ENCFAC_NUMERO === '') {
          this.calc.llenarFac(this.selectedArticulos[0], '0', this.initPrin);
        } else {
          this.calc.llenarFac(this.selectedArticulos[0], '1', this.initPrin);
        }
        // this.calc.llenarFac(this.selectedArticulos[0], '0');
        this.stringTeclado = undefined;
      }
    });
  }
  
  vaciarArreglo() {
    this.stringTeclado = '';
    this.calc.vaciarCant();
  }
  
  validarcobrar() {
    const dateini = new Date(this.fechainicial);
    const datefin = new Date(this.fechafinal);
    
    if (this.inpDate >= datefin) {
      this.messageService.add({
        key: 'calc',
        severity: 'error',
        summary: 'Periodo Contable',
        detail: 'Fecha fuera del periodo contable'
      });
      return false;
    }
    
    if (this.inpDate <= dateini) {
      this.messageService.add({
        key: 'calc',
        severity: 'error',
        summary: 'Periodo Contable',
        detail: 'Fecha fuera del periodo contable'
      });
      return false;
    }
    
    
    if (this.inpcliente.CLI_CODIGO === undefined || this.inpcliente.CLI_CODIGO === null || this.inpcliente.CLI_CODIGO === '' ||
      this.inpcliente.CLI_NOMBRE === undefined || this.inpcliente.CLI_NOMBRE === null || this.inpcliente.CLI_CODIGO === '') {
      this.messageService.add({
        key: 'calc',
        severity: 'error',
        summary: 'Ingrese cliente',
        detail: 'No existe cliente'
      });
      return false;
    }
    
    if (this.inpvendedor.VEN_CODIGO === undefined || this.inpvendedor.VEN_CODIGO === null || this.inpvendedor.VEN_CODIGO === '') {
      this.messageService.add({
        key: 'calc',
        severity: 'error',
        summary: 'Ingrese vendedor',
        detail: 'No existe vendedor'
      });
      return false;
    }
    
    if (this.inpvendedor.CAJ_CODIGO === undefined || this.inpvendedor.CAJ_CODIGO === null || this.inpvendedor.CAJ_CODIGO === '') {
      this.messageService.add({
        key: 'calc',
        severity: 'warn',
        summary: 'Vendedor',
        detail: 'El vendedor seleccionado no tiene caja asignada'
      });
      return false;
    }
    
    if (this.inpvendedor.BOD_CODIGO === undefined || this.inpvendedor.BOD_CODIGO === null || this.inpvendedor.BOD_CODIGO === '') {
      this.messageService.add({
        key: 'calc',
        severity: 'warn',
        summary: 'Vendedor',
        detail: 'El vendedor seleccionado no tiene bodega asignada'
      });
      return false;
    }
    
    
    if (this.auxiliar === '0') {
      if (this.calc.entregarFactura()[this.initPrin - 1].length === 0) {
        this.messageService.add({
          key: 'calc',
          severity: 'error',
          summary: 'Ingrese artículos',
          detail: 'No existen productos en el detalle'
        });
        return false;
      }
    } else {
      if (this.calc.recibirDet()[this.initPrin - 1].length === 0) {
        this.messageService.add({
          key: 'calc',
          severity: 'error',
          summary: 'Ingrese artículos',
          detail: 'No existen productos en el detalle'
        });
        return false;
      }
    }
    return true;
    
  }
  
  onKeyPress() {
    this.buscarArticulo();
  }
  
  enfocar() {
    document.getElementById('search-bar').focus();
  }
  
  desenfocar() {
    document.getElementById('search-bar').blur();
  }
  
  /*ARTICULO*/
  
  filterArticuloSingle(event) {
    if (event.query === undefined) {
      this.query = event;
    } else {
      this.query = event.query;
    }
    this.servicePdv.recogerArticulosFilter().subscribe((respuesta: any[]) => {
      this.filteredArticuloSingle = this.filterArticulo(this.query, respuesta);
    });
  }
  
  filterArticulo(query, articulos: any[]): any[] {
    const filtered: any[] = [];
    for (let i = 0; i < articulos.length; i++) {
      const art = articulos[i];
      if (art.ART_NOMBREC.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(art.ART_NOMBREC);
        this.objsel.push(art);
      }
    }
    return filtered;
  }
  
  
  public onSelectArticulo(art): void {
    let codart = '';
    for (const i of this.objsel) {
      if (i.ART_NOMBREC === art) {
        codart = i.ART_CODIGO;
      }
    }
    
    this.servicePdv.recogerProducto(codart).subscribe((respuesta: any[]) => {
      if (respuesta.length === 0) {
        this.messageService.add({
          key: 'calc',
          severity: 'warn',
          summary: 'Productos Seleccionados',
          detail: 'No existe producto con ese codigo'
        });
        this.selectedArticulos = respuesta;
        this.stringTeclado = undefined;
      } else {
        this.selectedArticulos = respuesta;
        // console.log('articulo', this.selectedArticulos);
        if (this.inpfactura.ENCFAC_NUMERO === undefined || this.inpfactura.ENCFAC_NUMERO === null || this.inpfactura.ENCFAC_NUMERO === '') {
          this.calc.llenarFac(this.selectedArticulos[0], '0', this.initPrin);
        } else {
          this.calc.llenarFac(this.selectedArticulos[0], '1', this.initPrin);
        }
        this.stringTeclado = undefined;
      }
      this.nomArt = '';
    });
    this.productobol = false;
    this.openTeclado.emit('cerrar');
  }
  
}
