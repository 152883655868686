<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="devolucionCom"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="devolucionConfCom" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 100%;">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
			[botonBorrar]="btnBorrar" [botonverAsiento]="botonVerAsiento" (signal)="manejarSenales($event)"
			[botonAnterior]="botonAnterior" [botonPrimero]="botonPrimero" [botonUltimo]="botonUltimo"
			[botonBuscar]="botonBuscar" [botonAsiento]="botonAsiento" [botonSiguiente]="botonSiguiente"
			[barraGre]="false" [barraDevolucion]="false" [barraBotones3]="true" [botonAnular]="botonAnular"
			[botonAlmacenardoc]="true"></app-invmaebarra>
	</div>
</div>
<div class="p-grid" style="margin-top: -10px; margin-bottom: -5px; width: 99%;">
	<div class="p-col-2">
		<input type="text" pInputText style="background-color: deepskyblue"
			[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_NUMERO" [disabled]="true">
	</div>
	<div class="p-col-1"></div>
	<div class="p-col-2">
		<input type="text" pInputText style="background-color: deepskyblue"
			[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_ESTADO" [disabled]="true">
	</div>
	<div class="p-col-2">
		<input type="text" pInputText style="background-color: deepskyblue; color: black" [(ngModel)]="cobroCodigo"
			[disabled]="true">
	</div>
	<div class="p-col-1">
	</div>
	<div class="p-col-2">
		<input type="text" pInputText style="background-color: transparent; color: black"
			[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].ASI_NRO" [disabled]="true">
	</div>
</div>
<p-tabView [(activeIndex)]="activeIndex" (onChange)="changeTab()">
	<p-tabPanel header="Devolución" (keydown.f9)="abrirDetalleFactura()">
		<div class="divgrups" id="divPrincipal9">
			<div class="p-grid p-align-stretch">
				<div class="p-col-12">
					<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
						<div class="p-col-1">
							<span>Comprobante:</span>
						</div>
						<div class="p-col-1">
							<input type="text" id="comprobante" (input)="cambio($event)" oninput="this.value= this.value.replace(/[^0-9]/g, '');"
								(keydown.enter)="cambiarFoco($event)" pInputText style="width: 100%"
								[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_SERIE"
								[maxLength]="6" autocomplete="off">
						</div>
						<div class="p-col-1">
							<input type="text" id="comprobante2" (input)="cambio($event)" oninput="this.value= this.value.replace(/[^0-9]/g, '');"
								(keydown.enter)="cambiarFoco($event)" pInputText style="width: 100%"
								[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_REFERENCIA"
								[maxLength]="9" autocomplete="off">
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-2">
									<input type="radio" id="fac" name="botones" value="fac" [(ngModel)]="botonFacNR"
										[disabled]="booleanDatosPantalla">
								</div>
								<div class="p-col-2">
									<label for="fac">Factura</label>
								</div>
								<div class="p-col-2">
									<input type="radio" id="nr" name="botones" value="nr" [(ngModel)]="botonFacNR"
										[disabled]="booleanDatosPantalla">
								</div>
								<div class="p-col-6">
									<label for="nr">Nota de Recep.</label>
								</div>
							</div>
						</div>
						<div class="p-col-3">
							<input type="text" id="facturaNota" (input)="cambio($event)" [disabled]="true"
								(keydown.enter)="cambiarFoco($event)" pInputText style="width: 100%"
								[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_DETDEVNUMERO"
								[maxLength]="50" autocomplete="off">
						</div>
						<div class="p-col-1">
							<button type="button"
								style="background-image: url(../../../assets/images/iconos/buscar.png);"
								(click)="abrirFacturaNota()"
								[disabled]="booleanDatosPantalla"></button>
						</div>
						<div class="p-col-1">
							<span>Emisión:</span>
						</div>
						<div class="p-col-1">
							<p-calendar dateFormat="dd/mm/yy" [inputStyle]="{'width': '100%'}"
								[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_FECHAEMISION">
							</p-calendar>
						</div>
						<div class="p-col-1"></div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -18px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Proveedor:</span>
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-8">
									<input type="text" [disabled]="booleanDatosPantalla" pInputText id="proveedor"
										(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)"
										[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].PRO_CODIGO"
										[maxLength]="20" autocomplete="off">
								</div>
								<div class="p-col-4">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="abrirProveedor()" [disabled]="booleanDatosPantalla"></button>
								</div>
							</div>
						</div>
						<div class="p-col-2">
							<span>{{nombreProveedor}}</span>
						</div>
						<div class="p-col-1">
							<span>%I.V.A:</span>
						</div>
						<div class="p-col-2">
							<p-dropdown [options]="opcionIvaDesc" [style]="{'width': '100%'}"
								[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_IVA">
							</p-dropdown>
						</div>
						<!-- <div class="p-col-1">
							<div class="p-grid">
								<div class="p-col-9">
									<input type="text" id="iva" pInputText style="width: 100%" disabled
									[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_PORCEIVA"
									>
								</div>
								<div class="p-col-3">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="abrirbusquedaiva()"></button>
								</div>
							</div>
						</div> -->
						<!-- <div class="p-col-1">
							<input type="text" style="width: 100%" pInputText [value]="'12.0'" [disabled]="true"
								autocomplete="off">
						</div> -->
						<div class="p-col-1">
							<input type="text" style="width: 100%" pInputText  [disabled]="true"  id="valiva" 
							oninput="this.value= this.value.replace(/[^0-9]+(\.[^0-9]+)?$/g, '');"
							(keydown.enter)="cambiarFoco($event)"
							[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_PORCEIVA"
								autocomplete="off">
						</div>
						<div class="p-col-1">
							<span>Caducidad:</span>
						</div>
						<div class="p-col-1">
							<p-calendar dateFormat="dd/mm/yy" [inputStyle]="{'width': '100%'}"
								[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_FECHACADUCIDAD">
							</p-calendar>
						</div>
						<div class="p-col-1"></div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -23px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Contacto:</span>
						</div>
						<div class="p-col-2">
							<input type="text" id="contacto" (input)="cambio($event)"
								(keydown.enter)="cambiarFoco($event)" pInputText [maxLength]="20"
								[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_CONTACTO"
								autocomplete="off">
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-1">
							<span>Bodega:</span>
						</div>
						<div class="p-col-1">
							<input type="text" id="bodega" (input)="cambio($event)"
								(keydown.enter)="cambiarFoco($event)" pInputText
								[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].BOD_CODIGO"
								[maxLength]="20" autocomplete="off">
						</div>
						<div class="p-col-1">
							<button type="button"
								style="background-image: url(../../../assets/images/iconos/buscar.png);"
								(click)="abrirBodega()"></button>
						</div>
						<div class="p-col-4">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -18px; margin-bottom: -20px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Comentario:</span>
						</div>
						<div class="p-col-5">
							<input type="text" style="width: 100%" (input)="cambio($event)" id="comentario"
								(keydown.enter)="cambiarFoco($event)"
								[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_COMENTARIO"
								[maxLength]="250" autocomplete="off">
						</div>
						<div class="p-col-1">
							<span>N° Aut.</span>
						</div>
						<div class="p-col-4">
							<input type="text" style="width: 100%" (input)="cambio($event)" id="autorizacion"
								(keydown.enter)="cambiarFoco($event)" [maxLength]="49"
								[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_AUTORIZACION"
								autocomplete="off">
						</div>
						<div class="p-col-1"></div>
					</div>
				</div>
			</div>
		</div>
		<app-aggridsaci *ngIf="activeIndex === 0" [width]="1750" [height]="largo" [enableSorting]="false"
			[enableFilter]="true" [rowData]="comDevFacService.detdevFacarray[indicador]"
			[frameworkComponents]="frameworkComponents" [animateRows]="true" [rowSelection]="rowSelection"
			[columnDefs]="columnDefsDVFDetalle" [defaultColDef]="defaultColDefDevolucion" [rowStyle]="rowStyle"
			[singleClickEdit]="false" [botones]="true" [mostrarGuardar]="false" [botonesAdicionales]="true"
			[comboOpciones]="comboOpciones" (selected)="seleccionDetalleDvf($event)" (cambios)="cambio($event)"
			(rdbtipodet)="cambiarTipo($event)" [tipodetalle]="tipodetalle" [botonNuevo]="btnNuevoGrid"
			[botonGuardar]="btnGuardarGrid" [botonBorrar]="btnBorrarGrid" [botonRegresar]="btnCancelarGrid"
			[botonVerificarExis]="btnVerificarExis" [suppressCellSelection]="editarCeldas"
			[tipodetalleactivo]="tipodetalleactivo" [imagenProducto]="imagenProducto"
			(keydown.f9)="abrirDetalleFactura()"></app-aggridsaci>
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-12">
					<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
						<div class="p-col-2">
							<span style="color: red;">F9 => Traer Items Factura</span>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-7"></div>
						<div class="p-col-1"></div>
					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Estado de Cuenta">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Proveedor:</span>
				</div>
				<div class="p-col-6">
					<span
						style="color: blue">{{comDevFacService.encDevSeleccionadaarray[indicador].PRO_CODIGO + ' - ' + nombreProveedor}}</span>
				</div>
				<div class="p-col-4">
				</div>
			</div>
		</div>
		<app-aggridsaci *ngIf="activeIndex === 1" [width]="" [height]="largoestcta" [enableSorting]="true"
			[enableFilter]="true" [rowData]="cxctmptrnresumen" [frameworkComponents]="frameworkComponents"
			[animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefsCxctmptrnresumen"
			[defaultColDef]="defaultColDefCxctmptrnresumen" [rowStyle]="rowStyle" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Saldos">
		<div class="divgrups2">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Proveedor:</span>
				</div>
				<div class="p-col-6">
					<span
						style="color: blue">{{comDevFacService.encDevSeleccionadaarray[indicador].PRO_CODIGO + ' - ' + nombreProveedor}}</span>
				</div>
				<div class="p-col-4">
				</div>
			</div>
		</div>
		<app-aggridsaci *ngIf="activeIndex === 2" [width]="" [height]="largoestcta" [enableSorting]="true"
			[enableFilter]="true" [rowData]="cxctrnresumen" [frameworkComponents]="frameworkComponents"
			[animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefsCxctrnresumen"
			[defaultColDef]="defaultColDefCxctrnresumen" [rowStyle]="rowStyle" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Estadísticas" [disabled]="disabledEstadisticas">
		<div class="p-grid">
			<!--LINEA 1-->
			<div class="p-col-6">
				<div class="p-grid">
					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-4">
								<button pButton (click)="mostrarEstadisticas()"
									style="background-image: url(../../../assets/images/iconos/cogwheel.png); width: 40px; height: 40px; background-color: transparent;"
									title="Generar estadísticas">
								</button>
							</div>
							<div class="p-col-4">
								<span style="font-size: 16px; font-weight: bold; text-align: center">COMPRAS POR
									MESES</span>
							</div>
							<div class="p-col-4">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 20px;">
						<p-chart [options]="options" type="bar" [data]="dataComprasProveedor" height="415px"></p-chart>
					</div>
				</div>
			</div>
			<div class="p-col-6">
				<div class="p-grid">
					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-3">
							</div>
							<div class="p-col-6">
								<span style="font-size: 16px; font-weight: bold;">
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									PRODUCTOS MÁS COMPRADOS</span>
							</div>
							<div class="p-col-3">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 10px;">
						<div class="p-grid">
							<div class="p-col-3">
							</div>
							<div class="p-col-2">
								<span>Ordenar por:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="cantidad" (onClick)="orderByTopProd('cantidad')"
									inputId="orden1"></p-radioButton>
								<label> Cantidad </label>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="precio" (onClick)="orderByTopProd('total')"
									inputId="orden2"></p-radioButton>
								<label> Total </label>
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px;">
						<app-aggridsaci [width]="" [height]="'390'" [enableSorting]="true" [enableFilter]="true"
							[rowData]="detTopProductos" [frameworkComponents]="frameworkComponents" [animateRows]="true"
							[rowSelection]="rowSelection" [columnDefs]="columnDefsTopProductos"
							[defaultColDef]="defaultColTopProductos" [rowStyle]="rowStyleTopProd" [botones]="false"
							[singleClickEdit]="false">
						</app-aggridsaci>
					</div>
				</div>
			</div>
			<div class="p-col-12" style="margin-top: 20px;">
				<div class="p-grid">
					<div class="p-col-5">
					</div>
					<div class="p-col-2">
						<span style="font-size: 16px; font-weight: bold;">
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							FORMULARIO</span>
					</div>
					<div class="p-col-5">
					</div>

					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-1">
								<span>Artículo:</span>
							</div>

							<div class="p-col-2" style="text-align: left">
								<div class="p-grid">
									<div class="p-col-8">
										<input id="ART_CODIGO" type="text" pInputText autocomplete="off"
											[(ngModel)]="txtArtCodigo">
									</div>
									<div class="p-col-4">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="buscarArticulo()"></button>
									</div>
								</div>
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-1">
								<span>Unidad:</span>
							</div>
							<div class="p-col-1">
								<input id="ART_UNIDAD" type="text" pInputText disabled [(ngModel)]="txtArtUnidad"
									autocomplete="off">
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-7">
										<span>Existencia Máxima:</span>
									</div>
									<div class="p-col-5">
										<input id="ART_EXISTMAX" type="text" pInputText disabled autocomplete="off"
											[(ngModel)]="txtExistenciaMax">
									</div>
								</div>

							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -5px;">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-1">
								<span>Descripción:</span>
							</div>

							<div class="p-col-2" style="text-align: left">
								<input id="ART_DESCRIPCION" type="text" pInputText disabled autocomplete="off"
									[(ngModel)]="txtArtDescripcion">
							</div>

							<div class="p-col-2">
								<!--								<span>Rotación:</span>-->
							</div>
							<div class="p-col-1">
								<!--								<input id="ART_ROTACION" type="text" pInputText disabled [(ngModel)]="txtArtRotacion"-->
								<!--									   autocomplete="off">-->
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-7">
										<span>Existencia Mínima:</span>
									</div>
									<div class="p-col-5">
										<input id="ART_EXISTMIN" type="text" pInputText disabled
											[(ngModel)]="txtExistenciaMin" autocomplete="off">
									</div>
								</div>

							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 20px;">
						<p-table [value]="detalleExistencia">
							<ng-template pTemplate="header">
								<tr>
									<th>Existencia</th>
									<th>Ordenes de Compra</th>
									<th>Pedidos Clientes</th>
									<th>Saldo</th>
									<th>Existencia Mínima</th>
									<th>Comprar</th>
									<th>Últ. Costo Compra</th>
									<th>Total Compra</th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-existencia>
								<tr>
									<td style="text-align: right">{{existencia.EXISTENCIA}}</td>
									<td style="text-align: right">{{existencia.ORDENES_COMPRA}}</td>
									<td style="text-align: right">{{existencia.PEDIDOS_CLIENTES}}</td>
									<td style="text-align: right">{{existencia.SALDO}}</td>
									<td style="text-align: right">{{existencia.EXIST_MIN}}</td>
									<td style="text-align: right">{{existencia.COMPRAR}}</td>
									<td style="text-align: right">{{existencia.ULTCOSTO_COM}}</td>
									<td style="text-align: right">{{existencia.TOTAL_COMPRA}}</td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</div>
				<p-tabView>
					<p-tabPanel header="Movimientos">
						<div class="p-col-12" style="margin-top: 10px;">
							<div class="p-grid">
								<div class="p-col-3">
								</div>
								<div class="p-col-2">
									<span>Ordenar por:</span>
								</div>
								<div class="p-col-2" style="text-align: left">
									<p-radioButton name="orden" value="fecha" (onClick)="llenarMovByProvArt('fecha')"
										inputId="orden1"></p-radioButton>
									<label> Fecha </label>
								</div>
								<div class="p-col-2" style="text-align: left">
									<p-radioButton name="orden" value="costo" (onClick)="llenarMovByProvArt('costo')"
										inputId="orden2"></p-radioButton>
									<label> Costo </label>
								</div>
							</div>
						</div>
						<app-aggridsaci [width]="" [height]="'450'" [enableSorting]="true" [enableFilter]="true"
							[rowData]="detMovimientosProv" [frameworkComponents]="frameworkComponents"
							[animateRows]="true" [rowSelection]="rowSelection"
							[columnDefs]="columnDefsMovimientosByProv" [defaultColDef]="defaultColMovByProv"
							[rowStyle]="rowStyle" [botones]="false" [singleClickEdit]="false">
						</app-aggridsaci>


					</p-tabPanel>
					<p-tabPanel header="Existencia por Bodega">
						<app-aggridsaci [width]="" [height]="'450'" [enableSorting]="true" [enableFilter]="true"
							[rowData]="detExistenciaBodegas" [frameworkComponents]="frameworkComponents"
							[animateRows]="true" [rowSelection]="rowSelection"
							[columnDefs]="columnDefsExistenciaBodegas" [defaultColDef]="defaultColExistBod"
							[rowStyle]="rowStyle" [botones]="false" [singleClickEdit]="false">
						</app-aggridsaci>
					</p-tabPanel>
					<p-tabPanel header="Ventas vs Compras">
						<div class="p-grid">
							<!--LINEA 1-->
							<div class="p-col-6">
								<div class="p-grid">
									<div class="p-col-12">
										<p-chart type="bar" [data]="dataVentasVsCompras" height="415px"></p-chart>
									</div>
								</div>
							</div>
							<div class="p-col-6">
								<div class="p-grid">
									<div class="p-col-4">
									</div>
									<div class="p-col-4"><span style="font-size: 16px; font-weight: bold;">
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											Índice de Provisión</span>
									</div>
									<div class="p-col-4">
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Inventario inicial:</span>
											</div>
											<div class="p-col-2">
												<input id="INVENTARIO_INICIAL" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtInventarioIni" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>(+) Compras del periodo:</span>
											</div>
											<div class="p-col-2">
												<input id="COMPRAS_PERIODO" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtComprasPeriodo" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Total disponible:</span>
											</div>
											<div class="p-col-2">
												<input id="TOTAL_DISPONIBLE" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtTotalDisponible"
													style="text-align: right; background-color: yellow">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Ventas del último periodo:</span>
											</div>
											<div class="p-col-2">
												<input id="VENTA_ULTIMOPER" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtVenUltimoPer" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Ventas promedio 10 periodos anteriores:</span>
											</div>
											<div class="p-col-2">
												<input id="VENTASPROM_PERANT" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtVenPromPerAnt" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Índice de provisión último periodo:</span>
											</div>
											<div class="p-col-2">
												<input id="INDPROV_ULTPER" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtIndiceProvUltPer"
													style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Índice de provisión 10 últimos periodos:</span>
											</div>
											<div class="p-col-2">
												<input id="INDPROV" type="text" pInputText autocomplete="off" readonly
													[(ngModel)]="txtIndiceProvPeriodos" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Compras planificadas:</span>
											</div>
											<div class="p-col-2">
												<input id="COMPRAS_PLANIFICADAS" type="number" pInputText
													autocomplete="off" [(ngModel)]="txtComprasPlanificadas"
													style="text-align: right">
											</div>
											<div class="p-col-2">
												<button pButton (click)="calcularIndice()"
													style="background-image: url(../../../assets/images/iconos/cogwheel.png);background-color: transparent;"
													title="Calcular Índice">
												</button>
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Total disponible:</span>
											</div>
											<div class="p-col-2">
												<input id="totalDisponible" type="text" pInputText readonly
													autocomplete="off" [(ngModel)]="txtTotalDisponibleCom"
													style="text-align: right; background-color: yellow">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Índice de provisión último periodo:</span>
											</div>
											<div class="p-col-2">
												<input id="INDPROV_ULT" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtIndiceProvUltPer2"
													style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Índice de provisión 10 últimos periodos:</span>
											</div>
											<div class="p-col-2">
												<input id="INDPRO" type="text" pInputText autocomplete="off" readonly
													[(ngModel)]="txtIndiceProvPeriodos2" style="text-align: right">
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</p-tabPanel>
				</p-tabView>
			</div>
		</div>
	</p-tabPanel>
	<div class="divgrupstabla">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-4">
						<div class="p-grid">
							<div class="p-col-4">
								<span class="obligatorio">Neto</span>
							</div>
							<div class="p-col-4">
								<span class="obligatorio">Dscto (Valor)</span>
							</div>
							<div class="p-col-4">
								<span class="obligatorio">Dscto (%)</span>
							</div>
						</div>
					</div>
					<div class="p-col-4">
						<div class="p-grid">
							<div class="p-col-4">
								<span class="obligatorio">Base I.V.A.</span>
							</div>
							<div class="p-col-4">
								<span class="obligatorio">Valor I.V.A.</span>
							</div>
							<div class="p-col-4">
								<span class="obligatorio">Base I.C.E.</span>
							</div>
						</div>
					</div>
					<div class="p-col-4">
						<div class="p-grid">
							<div class="p-col-4">
								<span class="obligatorio">Valor I.C.E.</span>
							</div>
							<div class="p-col-4">
								<span class="obligatorio">Base Cero</span>
							</div>
							<div class="p-col-4">
								<span class="obligatorio">Total</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="p-col-12" style="margin-top: -20px">
				<div class="p-grid">
					<div class="p-col-4">
						<div class="p-grid">
							<div class="p-col-4">
								<input type="text" class="totales" pInputText
									[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_TOTALNETO"
									disabled>
							</div>
							<div class="p-col-4">
								<input type="text" class="totales" pInputText
									[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_VALORDES"
									disabled>
							</div>
							<div class="p-col-4">
								<input type="text" class="totales" pInputText
									[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_PORCEDES"
									disabled>
							</div>
						</div>
					</div>
					<div class="p-col-4">
						<div class="p-grid">
							<div class="p-col-4">
								<input type="text" class="totales" pInputText
									[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_BASEIVA"
									disabled>
							</div>
							<div class="p-col-4">
								<input type="text" class="totales" pInputText
									[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_VALORIVA"
									disabled>
							</div>
							<div class="p-col-4">
								<input type="text" class="totales" pInputText
									[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_BASEICE"
									disabled>
							</div>
						</div>
					</div>
					<div class="p-col-4">
						<div class="p-grid">
							<div class="p-col-4">
								<input type="text" class="totales" pInputText
									[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_VALORICE"
									disabled>
							</div>
							<div class="p-col-4">
								<input type="text" class="totales" pInputText
									[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_BASECERO"
									disabled>
							</div>
							<div class="p-col-4">
								<input type="text" class="totales" pInputText
									[(ngModel)]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_TOTAL"
									disabled>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</p-tabView>

<div class="layout-config">
	<div class="layout-config-content-wrapper">
		<a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones($event)">
			<i class="pi pi-bars" style="cursor: pointer"></i>
		</a>
		<p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
			[dismissible]="false" [showCloseIcon]="false">
			<p-tabView>
				<p-tabPanel header="Acciones">
					<div class="p-grid">
						<div class="p-col-12">
							<i class="pi pi-folder archivoOpc"></i><span (click)="abrirSeriales()"
								class="archivoOpc">Ingresar Seriales</span>
						</div>
						<div class="p-col-12">
							<i class="pi pi-caret-right archivoOpc"></i><span (click)="actbasesiva()"
								class="archivoOpc">Actualizar bases IVA</span>
						</div>
						<div class="p-col-12">
							<i class="pi pi-folder archivoOpc"></i><span (click)="verbasesiva()"
								class="archivoOpc">Visualizar bases IVA</span>
						</div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-sidebar>
	</div>
</div>
<p-dialog header="Ingreso de Seriales" [(visible)]="displayDialogSeriales" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-invtrnkardexotr *ngIf="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_NUMERO !== '' &&
						  comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_NUMERO !== undefined"
		[strNumFact]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_NUMERO"
		[strFechaSer]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_FECHAEMISION" [strTipoDoc]="'DVFCOM'"
		[strTipoTrn]="'SA'" [devBoolean]="true"
		[strNumFactDev]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_DETDEVNUMERO"
		(cerrar)="cerrarSeriales()"></app-invtrnkardexotr>
</p-dialog>
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla"
		[busquedaParams]="busquedacampos" [where]="where" (opcionSeleccionada)="manejarSeleccion($event)"
		[consulta]="consulta" [busquedaCompuesta]="busquedaCompuesta"></app-busquedadlg>
</p-dialog>
<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width:'90vw', height:'120vh'}" [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="asiento" [abiertoDesde]="'comencdevfac'"
		(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>

<p-dialog header="Descuento Global" [(visible)]="displayDialogDescuento" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '35vw', height: '35vh'}">
	<div class="p-grid">
		<div class="p-col-12">
			<button class="littlebuttons" pButton (click)="aplicarDescuento()"
				style="background-image: url(../../../assets/images/iconos/visto.png);">
			</button>
		</div>
		<div class="p-col-12">
			<p-tabView>
				<p-tabPanel header="Tipo Descuento">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-3">
							<p-radioButton name="opcionDescuento" label="Por Valor" value="dscValor"
								[(ngModel)]="dscSeleccionado" (ngModelChange)="cambioDescuento()"></p-radioButton>
						</div>
						<div class="p-col-3"></div>
						<div class="p-col-3">
							<p-radioButton name="opcionDescuento" label="En Porcentaje" value="dscPorcentaje"
								[(ngModel)]="dscSeleccionado" (ngModelChange)="cambioDescuento()"></p-radioButton>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-1"></div>
						<div class="p-col-4">
							<input type="text" pInputText pKeyFilter="num" (input)="comprobarValor()"
								(keydown.enter)="aplicarDescuento()" [(ngModel)]="valorDscValor"
								[disabled]="booleanValor" appTwoDigitDecimaNumber autocomplete="off">
							<p-message *ngIf="valorInvalidoV === true" severity="error"
								text="Valor de descuento no válido"></p-message>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-4">
							<input type="text" pInputText pKeyFilter="num" (input)="comprobarValor()"
								(keydown.enter)="aplicarDescuento()" [(ngModel)]="valorDscPorcentaje"
								[disabled]="booleanPorcentaje" appTwoDigitDecimaNumber autocomplete="off">
							<p-message *ngIf="valorInvalidoP === true" severity="error" text="Porcentaje no válido ">
							</p-message>
						</div>
						<div class="p-col-1"></div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</div>
	</div>
</p-dialog>
<p-dialog header="Seleccion de datos" [(visible)]="displayDialogDetFac" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width:'70vw', height:'60vh'}">
	<app-comdetfacpro *ngIf="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_DETDEVNUMERO !== '' && comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_DETDEVNUMERO !== undefined
						&& botonFacNR === 'fac'" [encfacNumero]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_DETDEVNUMERO"
		(detallesSeleccionados)="recibirDetalles($event)" (signalSalir)="recibirSenales($event)"></app-comdetfacpro>
	<app-comdetnotrec *ngIf="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_DETDEVNUMERO !== '' && comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_DETDEVNUMERO !== undefined
						&& botonFacNR === 'nr'" [notrecNumero]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_DETDEVNUMERO"
		(detallesSeleccionados)="recibirDetalles($event)" (signalSalir)="recibirSenales($event)"></app-comdetnotrec>
</p-dialog>

<p-dialog header="Reportes" [(visible)]="displayDialogReportes" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: '400px'}">
	<app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
		[strNumDoc]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_NUMERO"
		[strCampo]="'ENCDEV_NUMERO'" (cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>

<p-dialog header="Lotes" [(visible)]="displayDialogLotes" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<app-invtrnlote [auxiliar]="auxiliarlot" [detalle]="detalleDVFSeleccionado"
		[encabezado]="comDevFacService.encDevSeleccionadaarray[indicador]" [cadena]="'DEVCOM'" (salir)="cerrarLotes()">
	</app-invtrnlote>
</p-dialog>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'COMENCDEV'"
		[txtNumComprobante]="comDevFacService.encDevSeleccionadaarray[indicador].ENCDEV_NUMERO"
		(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>
<!-- CDPJ -->
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	<p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
	</p-dialog>
</div>
<p-dialog header="Bases IVA" [(visible)]="displaybasesiva" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '55vw', height: '55vh'} " [focusOnShow]="true" appendTo="body" >

	<app-aggridsaci [width]="-800" [height]="-800" [enableSorting]="true" [enableFilter]="true"
			[rowData]="basesiva" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsBasesIva"
			[defaultColDef]="defaultColBasesIva" [rowStyle]="rowStyle" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>
</p-dialog>
<!-- CDPJ -->