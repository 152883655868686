<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="grup"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarGrup" title="Confirmar Acción"
                 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 99%">
  <div class="p-col-12">
    <app-invmaebarra [botonNuevo]="botonNuevo" [botonGuardar]="botonGuardar" [botonRegresar]="botonRegresar" [botonBorrar]="false" (signal)="manejarSenales($event)"
                     [barraBotonesDesplaz]="barraBotones2" [barraBotones3]="barraBotones2"
                     [barraBotonesAsiCon]="barraBotones2" [barraBotones5]="true"></app-invmaebarra>
  </div>
  <div style="overflow: hidden" class="p-col-12">
    <p-tabView [(activeIndex)]="index" (activeIndexChange)="cambiarIndices()">
      <p-tabPanel header="Grupo">
        <app-aggridsaci [width]="1200"
                        [height]="largo"
                        [enableSorting]="false"
                        [enableFilter]="true"
                        [rowData]="grupoSer.invmaegrupos"
                        [frameworkComponents]="frameworkComponents"
                        [animateRows]="true"
                        [rowSelection]="rowSelection"
                        [columnDefs]="columnDefsGrupo"
                        [defaultColDef]="defaultColDefGrupo"
                        [rowStyle]="rowStyle"
                        [singleClickEdit]="false"
                        [botones]="false"
                        (selected)="seleccionGrupo($event)"
                        [indice]="index"
                        (cambios)="cambio($event)"

        ></app-aggridsaci>
      </p-tabPanel>
      <p-tabPanel header="SubGrupos" *ngIf="grupoSeleccionado !== undefined">
        <div class="p-grid">
          <div class="p-col-12">
            <strong>{{grupoSeleccionado.GRUP_CODIGO}} {{grupoSeleccionado.GRUP_NOMBRE}}</strong>
          </div>
        </div>
        <app-aggridsaci [width]="1200"
                        [height]="largo"
                        [enableSorting]="true"
                        [enableFilter]="true"
                        [rowData]="grupoSer.invmaesubgrupos"
                        [frameworkComponents]="frameworkComponents"
                        [animateRows]="true"
                        [rowSelection]="rowSelection"
                        [columnDefs]="columnDefsSubGrupo"
                        [defaultColDef]="defaultColDefSubGrupo"
                        [rowStyle]="rowStyle"
                        [singleClickEdit]="false"
                        [botones]="false"
                        (selected)="seleccionSubGrupo($event)"
                        [indice]="index"
                        (cambios)="cambio($event)"

        ></app-aggridsaci>
      </p-tabPanel>
      <p-tabPanel header="Garantias" *ngIf="grupoSeleccionado !== undefined">
        <div class="p-grid">
          <div class="p-col-12">
            <strong>{{grupoSeleccionado.GRUP_CODIGO}} {{grupoSeleccionado.GRUP_NOMBRE}}</strong>
          </div>
          <div class="p-col-2">
            <label for="garantia" style="font-weight: bold">Garantías: </label>
          </div>
          <div class="p-col-10">
            <textarea style="height: 180px !important;" (input)="cambio(true)" autoResize="autoResize" id="garantia" name="garantia" pInputTextarea [cols]="90" [rows]="8" [(ngModel)]="grupoSeleccionado.GRUP_GARANTIAS"></textarea>
          </div>
          <div class="p-col-2">
            <label for="otros" style="font-weight: bold">Otros Conceptos: </label>
          </div>
          <div class="p-col-10">
            <textarea style="height: 180px !important;" (input)="cambio(true)" autoResize="autoResize" id="otros" name="otros" pInputTextarea [cols]="90" [rows]="8" [(ngModel)]="grupoSeleccionado.GRUP_OTROSCON"></textarea>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
