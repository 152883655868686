import {AnexgenerarxmlService} from '../anexservicios/anexgenerarxml.service';
import {Injectable} from '@angular/core';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {MessageService} from 'primeng';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class XMLAnexoTransaccional {
  
  private actualiza;
  private root;
  private value;
  private strEncfacproRetserieEst;
  private strEncfacproRetserieptoemi;
  private strEncfacproRetsecuencial;
  private strEncfacproRetautorizacion;
  private strEncfacproRetfecha;
  private intRetElectronicas = 0;
  private intNCNOFAC = 0;
  private strMicroempreas = '';
  
  constructor(public generarXmlService: AnexgenerarxmlService, private errorService: ErroresBaseDatosService,
              public message: MessageService,  private datePipe: DatePipe) {
    
  }
  
  async XMLAnexoTransaccional(root, periodo, anio, strSoloFactFisicas,  intNCNOFAC, strMicroempresas) {
    this.root = root; // Nodo inicial del archivo;
    this.value = 0;
    this.intNCNOFAC = intNCNOFAC;
    let intExtVtas = 0;
    this.strMicroempreas = strMicroempresas;
    
    let data = await this.getCompras(anio, periodo);
    
    if (data.length > 0) {
      root.iva.compras = [data];
    }

    data = [];

    data = await this.getVentas(anio, periodo);

    if (data.length > 0) {
      root.iva.ventas = [data];
      intExtVtas = 1;
    }
    data = [];

    //ventas 
		data = await this.getEstablecimiento1(anio, periodo, strSoloFactFisicas);
		if (data.length > 0 && intExtVtas === 1) {
      root.iva.ventasEstablecimiento = [data];
    }
	
    data = [];
    
    data = await this.getExportacionesVentas(anio, periodo);
		
		if (data.length > 0) {
      root.iva.exportaciones = [data];
    }
		
    data = [];

    data = await this.getExportacionesDevVentas(anio, periodo);
		
		if (data.length > 0) {
      root.iva.exportaciones = [data];
    }

    data = [];
    
    data = await this.getAnulados(anio, periodo);
		if (data.length > 0) {
      root.iva.anulados = [data];
    }

		data = [];
    
    return root;
  }

  async getAnulados(anio, periodo) {
		let data = [];
    let anulado = [];
    
		let strMeses = this.cadenaMeses(anio, periodo, this.strMicroempreas);

		anulado = await this.consultaAnulados(strMeses, anio); 
          
    if (anulado.length <= 0) {
      return data;
    }
    
    for (const a of anulado) {
      const row: object = new Object();
      // @ts-ignore
      row.detalleAnulados = this.createAnulado(a);
      data.push(row);
    }
    
		return data;
  }

  createAnulado(venta) {
		const data = [];
    const row: object = new Object();

    // @ts-ignore
    row.tipoComprobante = this.autoCompletar(venta.anexosivaIdtipcom, 2);
    // @ts-ignore
		row.establecimiento = String(venta.anexosivaSercomp).substring(0, 3);
		// @ts-ignore
		row.puntoEmision = String(venta.anexosivaSercomp).substring(3);
		// @ts-ignore
		row.secuencialInicio = venta.anexosivaDesde;
		// @ts-ignore
		row.secuencialFin = venta.anexosivaDesde;
		// @ts-ignore
    row.autorizacion = venta.anexosivaAutorizacion;
    
    data.push(row);
    
		return data;
	}
  
  async consultaAnulados(strMeses, anio) {
    const params: any[] = [];
    let rs;

    try {
      rs = await this.generarXmlService.consultaAnulados(anio, strMeses);

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso consultar anulados no terminó satisfactoriamente.... ! ' + mensaje);		
    }

    const variables = ['anexosivaIdtipcom', 'anexosivaPerinf', 'anexosivaSercomp',
      'anexosivaDesde', 'anexosivaHasta', 'anexosivaAutorizacion', 'anexosivaFecanucom',
      'anexosivaAnio', 'anexosivaTipo', 'anexosivaRproc', 'anexosivaNumero', 'comCodigo',
      'anexosivaTipidcli', 'cliCodigo', 'anexosivaFecemicom', 'anexosivaNumcomp',
      'anexosivaSap', 'anexosivaEsquema', 'rowNum'];
		const columnas = ['ANEXOSIVA_IDTIPCOM', 'ANEXOSIVA_PERINF', 'ANEXOSIVA_SERCOMP',
      'ANEXOSIVA_DESDE', 'ANEXOSIVA_HASTA', 'ANEXOSIVA_AUTORIZACION',
      'ANEXOSIVA_FECANUCOM', 'ANEXOSIVA_ANIO', 'ANEXOSIVA_TIPO', 'ANEXOSIVA_RPROC',
      'ANEXOSIVA_NUMERO', 'COM_CODIGO', 'ANEXOSIVA_TIPIDCLI', 'CLI_CODIGO',
      'ANEXOSIVA_FECEMICOM', 'ANEXOSIVA_NUMCOMP', 'ANEXOSIVA_SAP', 'ANEXOSIVA_ESQUEMA',
      'ROWNUM'];

    return this.dataSource(rs, variables, columnas);

	}

  async getExportacionesDevVentas(intAnio, strMes) {
	  let strSentencia = '';
    const listaExp = [];
    let data = [];
  
    const fechaFin = this.leerFechaFinal(intAnio, strMes, this.strMicroempreas, strMes);
    const fechaIni = this.leerFechaInicial(intAnio, strMes, this.strMicroempreas, strMes);        

    strSentencia = 'select * from ven_encdvf ';
    strSentencia = strSentencia + ' where encdvf_estado<>\'A\' ';
    strSentencia = strSentencia + ' and to_date(to_char(encdvf_fechaemision,\'dd/mm/yyyy\'),\'dd/mm/yyyy\')>=to_date(\'' + fechaIni + '\',\'dd/mm/yyyy\')';
    strSentencia = strSentencia + ' and to_date(to_char(encdvf_fechaemision,\'dd/mm/yyyy\'),\'dd/mm/yyyy\')<=to_date(\'' + fechaFin + '\',\'dd/mm/yyyy\')';	
    strSentencia = strSentencia + ' and encdvf_referencia in (select encfac_numero from ven_encfac where encfac_tipofactura=\'01\')';
  
    
    try {
      const rs = await this.generarXmlService.getExportacionesDevVentas(fechaIni, fechaFin);
     for (const item of rs){	
        listaExp.push(item.ENCDVF_NUMERO);
      }
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso leer facturas de exportación no terminó satisfactoriamente.... ! ' + mensaje);		
    }
    
    
    data = [];
    let row: object = new Object();				
  
    for (const ENCDVF_NUMERO of listaExp) {
  
        if (await this.exportacionDeDev(ENCDVF_NUMERO) === '01') {
          row = new Object();
          // @ts-ignore
          row.detalleExportaciones = await this.getDatosExportacionConRefrendoDev(ENCDVF_NUMERO);
				  data.push(row);
        }
        if (await this.exportacionDeDev(ENCDVF_NUMERO) === '02') {
          row = new Object();
          // @ts-ignore
          row.detalleExportaciones = await this.getDatosExportacionSinRefrendoDev(ENCDVF_NUMERO);
          data.push(row);
        }
        if (await this.exportacionDeDev(ENCDVF_NUMERO) === '03') {
          row = new Object();
          // @ts-ignore
          row.detalleExportaciones = await this.getDatosExportacion03Dev(ENCDVF_NUMERO);
          data.push(row);
        }
      }
      
    return data;

  }

  async getDatosExportacion03Dev(strNumFactura) {
    const parametros: any[] = [];
    let data = [];
    
    parametros[0] = strNumFactura;
    parametros[1] = '01';

    try {
      const rs = await this.generarXmlService.getDatosExportacion03Dev(parametros);
      data = [];
      let row: object = new Object();		
    
      if (rs !== null) {
        if (rs.length > 0) {	
          // @ts-ignore
          row.tpIdClienteEx = rs[0].CLI_TIPOIDE === null ? 'NOEXISTE' : rs[0].CLI_TIPOIDE;
          // @ts-ignore
          row.idClienteEx = rs[0].CLI_RUCIDE === null ? 'NOEXISTE' : rs[0].CLI_RUCIDE;
          // @ts-ignore
          row.parteRelExp = rs[0].CLI_PARTEREL === null ? 'NOEXISTE' : rs[0].CLI_PARTEREL;
          // @ts-ignore
          row.tipoCli = rs[0].TIPOCLI === null ? 'NOTEXISTE' : rs[0].TIPOCLI;
          // @ts-ignore
          row.denoExpCli = rs[0].DENOEXPCLI === null ? 'NOEXISTE' : rs[0].DENOEXPCLI;
          // @ts-ignore
          row.tipoRegi = rs[0].TIPOREGI === null ? 'NOEXISTE' : rs[0].TIPOREGI;
          // @ts-ignore
          row.paisEfecPagoGen = rs[0].PAISEFECPAGOGEN === null ? 'NOEXISTE' : rs[0].PAISEFECPAGOGEN;
          // @ts-ignore
          row.paisEfecExp = rs[0].PAISEFECEXP === null ? 'NOEXISTE' : rs[0].PAISEFECEXP;
          // @ts-ignore
          row.exportacionDe = rs[0].EXPORTACIONDE === null ? 'NOEXISTE' : rs[0].EXPORTACIONDE;

          // Servicios del exterior
          // @ts-ignore
          row.tipIngExt = rs[0].TIPOINGRESO === null ? 'NOEXISTE' : rs[0].TIPOINGRESO;
          // @ts-ignore
          row.ingExtGravOtroPais = rs[0].INGGRAVADOEXT === null ? 'NOEXISTE' : rs[0].INGGRAVADOEXT;

          if (rs[0].INGGRAVADOEXT === 'SI') {
            // @ts-ignore
            row.impuestoOtroPais = rs[0].VALORIMPEXT === null ? 'NOEXISTE' : Number(rs[0].VALORIMPEXT).toFixed(2);
          }
          
          // @ts-ignore
          row.tipoComprobante = rs[0].TIPOCOMPROBANTE === null ? 'NOEXISTE' : rs[0].TIPOCOMPROBANTE;
          // @ts-ignore
          row.fechaEmbarque = rs[0].FECHAEMBARQUE === null ? 'NOEXISTE' : rs[0].FECHAEMBARQUE;
          // @ts-ignore
          row.valorFOB = rs[0].VALORFOB === null ? 'NOEXISTE' : Number(rs[0].VALORFOB).toFixed(2);
          // @ts-ignore
          row.valorFOBComprobante = rs[0].VALORFOBCOMPROBANTE === null ? 'NOEXISTE' : Number(rs[0].VALORFOBCOMPROBANTE).toFixed(2);
          // @ts-ignore
          row.establecimiento = rs[0].ESTABLECIMIENTO === null ? 'NOEXISTE' : rs[0].ESTABLECIMIENTO;		
          // @ts-ignore
          row.puntoEmision = rs[0].PUNTOEMISION === null ? 'NOEXISTE' : rs[0].PUNTOEMISION;		
          // @ts-ignore
          row.secuencial = rs[0].ENCDVF_SECACTUAL === null ? 'NOEXISTE' : rs[0].ENCDVF_SECACTUAL;		
          // @ts-ignore
          row.autorizacion = rs[0].ENCDVF_AUTORIZACION === null ? 'NOEXISTE' : rs[0].ENCDVF_AUTORIZACION;		
          // @ts-ignore
          row.fechaEmision = rs[0].ENCDVF_FECHAEMISION === null ? 'NOEXISTE' : rs[0].ENCDVF_FECHAEMISION;		
          
          data.push(row);
        }
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso leer datos de exportación no terminó satisfactoriamente.... ! ' + mensaje);				
    }
    
    return data;
  }

  async getDatosExportacionSinRefrendoDev(strNumFactura) {
    const parametros: any[] = [];
    let data = [];

    parametros[0] = strNumFactura;
    parametros[1] = '01';
    
    try {
      const rs = await this.generarXmlService.getDatosExportacionSinRefrendoDev(parametros);
      data = [];
      let row: object = new Object();		
    
      if (rs !== null) {
        if (rs.length > 0) {	
          
          // @ts-ignore
          row.tpIdClienteEx = rs[0].CLI_TIPOIDE === null ? 'NOEXISTE' : rs[0].CLI_TIPOIDE;
          // @ts-ignore
          row.idClienteEx = rs[0].CLI_RUCIDE === null ? 'NOEXISTE' : rs[0].CLI_RUCIDE;
          // @ts-ignore
          row.parteRelExp = rs[0].CLI_PARTEREL === null ? 'NOEXISTE' : rs[0].CLI_PARTEREL;
          // @ts-ignore
          row.tipoCli = rs[0].TIPOCLI === null ? 'NOTEXISTE ' : rs[0].TIPOCLI;
          // @ts-ignore
          row.denoExpCli = rs[0].DENOEXPCLI === null ? 'NOEXISTE' : rs[0].DENOEXPCLI;
          // @ts-ignore
          row.tipoRegi = rs[0].TIPOREGI === null ? 'NOEXISTE' : rs[0].TIPOREGI;
          // @ts-ignore
          row.paisEfecPagoGen = rs[0].PAISEFECPAGOGEN === null ? 'NOEXISTE' : rs[0].PAISEFECPAGOGEN;
          // @ts-ignore
          row.paisEfecExp = rs[0].PAISEFECEXP === null ? 'NOEXISTE' : rs[0].PAISEFECEXP;
          // @ts-ignore
          row.exportacionDe = rs[0].EXPORTACIONDE === null ? 'NOEXISTE' : rs[0].EXPORTACIONDE;
          // @ts-ignore
          row.tipoComprobante = rs[0].TIPOCOMPROBANTE === null ? 'NOEXISTE' : rs[0].TIPOCOMPROBANTE;
          // @ts-ignore
          row.fechaEmbarque = rs[0].FECHAEMBARQUE === null ? 'NOEXISTE' : rs[0].FECHAEMBARQUE;
          // @ts-ignore
          row.valorFOB = rs[0].VALORFOB === null ? 'NOEXISTE' : Number(rs[0].VALORFOB).toFixed(2);
          // @ts-ignore
          row.valorFOBComprobante = rs[0].VALORFOBCOMPROBANTE === null ? 'NOEXISTE' :  Number(rs[0].VALORFOBCOMPROBANTE).toFixed(2);;
          // @ts-ignore
          row.establecimiento = rs[0].ESTABLECIMIENTO === null ? 'NOEXISTE' : rs[0].ESTABLECIMIENTO;		
          // @ts-ignore
          row.puntoEmision = rs[0].PUNTOEMISION === null ? 'NOEXISTE' : rs[0].PUNTOEMISION;		
          // @ts-ignore
          row.secuencial = rs[0].ENCDVF_SECACTUAL === null ? 'NOEXISTE' : rs[0].ENCDVF_SECACTUAL;		
          // @ts-ignore
          row.autorizacion = rs[0].ENCFAC_AUTORIZACION === null ? 'NOEXISTE' : rs[0].ENCFAC_AUTORIZACION;		
          // @ts-ignore
          row.fechaEmision = rs[0].ENCDVF_FECHAEMISION === null ? 'NOEXISTE' : rs[0].ENCDVF_FECHAEMISION;		
      
          data.push(row);
      
        }
      }
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso leer datos de exportación no terminó satisfactoriamente.... ! ' + mensaje);			
    }
      
    return data;
  }

  async getDatosExportacionConRefrendoDev(strNumFactura) {
    const parametros: any[] = [];
    let data = [];

    parametros[0] = strNumFactura;
    parametros[1] = '01';
    
    try {

      const rs = await this.generarXmlService.getDatosExportacionConRefrendoDev(parametros);	
      data = [];
      let row: object = new Object();		
      if (rs !== null) {	
        if (rs.length > 0){	
          // @ts-ignore
          row.tpIdClienteEx = rs[0].CLI_TIPOIDE === null ? 'NOEXISTE' : rs[0].CLI_TIPOIDE;
          // @ts-ignore
          row.idClienteEx = rs[0].CLI_RUCIDE === null ? 'NOEXISTE' : rs[0].CLI_RUCIDE;
          // @ts-ignore
          row.parteRelExp = rs[0].CLI_PARTEREL === null ? 'NOEXISTE' : rs[0].CLI_PARTEREL;
          // @ts-ignore
          row.tipoCli = rs[0].TIPOCLI === null ? 'NOTEXISTE ' : rs[0].TIPOCLI;
          // @ts-ignore
          row.denoExpCli = rs[0].DENOEXPCLI === null ? 'NOEXISTE' : rs[0].DENOEXPCLI;
          // @ts-ignore
          row.tipoRegi = rs[0].TIPOREGI === null ? 'NOEXISTE' : rs[0].TIPOREGI;
          // @ts-ignore
          row.paisEfecPagoGen = rs[0].PAISEFECPAGOGEN === null ? 'NOEXISTE' : rs[0].PAISEFECPAGOGEN;
          // @ts-ignore
          row.paisEfecExp = rs[0].PAISEFECEXP === null ? 'NOEXISTE' : rs[0].PAISEFECEXP;
          // @ts-ignore
          row.exportacionDe = rs[0].EXPORTACIONDE === null ? 'NOEXISTE' : rs[0].EXPORTACIONDE;
          // @ts-ignore
          row.tipoComprobante = rs[0].TIPOCOMPROBANTE === null ? 'NOEXISTE' : rs[0].TIPOCOMPROBANTE;
          // @ts-ignore
          row.distAduanero = rs[0].DISTADUANERO === null ? 'NOEXISTE' : rs[0].DISTADUANERO;
          // @ts-ignore
          row.anio = rs[0].ANIO === null ? 'NOEXISTE' : rs[0].ANIO;
          // @ts-ignore
          row.regimen = rs[0].REGIMEN === null ? 'NOEXISTE' : rs[0].REGIMEN;
          // @ts-ignore
          row.correlativo = rs[0].CORRELATIVO === null ? 'NOEXISTE' : rs[0].CORRELATIVO;
          // @ts-ignore
          row.docTransp = rs[0].DOCTRANSP === null ? 'NOEXISTE' : rs[0].DOCTRANSP;
          // @ts-ignore
          row.fechaEmbarque = rs[0].FECHAEMBARQUE === null ? 'NOEXISTE' : rs[0].FECHAEMBARQUE;
          // @ts-ignore
          row.valorFOB = rs[0].VALORFOB === null ? 'NOEXISTE' : Number(rs[0].VALORFOB).toFixed(2);
          // @ts-ignore
          row.valorFOBComprobante = rs[0].VALORFOBCOMPROBANTE === null ? 'NOEXISTE' : Number(rs[0].VALORFOBCOMPROBANTE).toFixed(2);
          // @ts-ignore
          row.establecimiento = rs[0].ESTABLECIMIENTO === null ? 'NOEXISTE' : rs[0].ESTABLECIMIENTO;		
          // @ts-ignore
          row.puntoEmision = rs[0].PUNTOEMISION === null ? 'NOEXISTE' : rs[0].PUNTOEMISION;		
          // @ts-ignore
          row.secuencial = rs[0].ENCDVF_SECACTUAL === null ? 'NOEXISTE' : rs[0].ENCDVF_SECACTUAL;		
          // @ts-ignore
          row.autorizacion = rs[0].ENCDVF_AUTORIZACION === null ? 'NOEXISTE' : rs[0].ENCDVF_AUTORIZACION;		
          // @ts-ignore
          row.fechaEmision = rs[0].ENCDVF_FECHAEMISION === null ? 'NOEXISTE' : rs[0].ENCDVF_FECHAEMISION;		
      
          data.push(row);

        }
      }
    
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso leer datos de exportación no terminó satisfactoriamente.... ! ' + mensaje);		
    }
      
      
    return data;
  }

  async exportacionDeDev(strNumFactura) {
    let expor = '';
    const parametros: any[] = [];
  
    parametros[0] = strNumFactura;
    parametros[1] = '01';
    
    try {
      const rs = await this.generarXmlService.exportacionDeDev(parametros);

      if (rs !== null) {
        if (rs.length > 0) {	
          expor = rs[0].EXPORTACIONDE === null ? 'NOEXISTE' : rs[0].EXPORTACIONDE;
        }	
      }
      
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso leer facturas de exportación no terminó satisfactoriamente.... ! ' + mensaje);			
    }

    return expor;
  }

  async getExportacionesVentas(intAnio, strMes) {
    let strSentencia = '';
	
    const listaExp = [];
    let data = [];

    let strMeses = this.cadenaMeses(intAnio, strMes, this.strMicroempreas);
    strSentencia = 'select  * from ven_encfac ';
    strSentencia = strSentencia + ' where encfac_estado<>\'A\' and to_char(encfac_fechaemision,\'yyyy\')=\'' + intAnio + '\' ';
    strSentencia = strSentencia + ' and to_char(encfac_fechaemision,\'mm\') in ' + strMeses;
    strSentencia = strSentencia + ' and encfac_tipofactura=\'01\'';
	

	
    try {
      const rs = await this.generarXmlService.getExportacionesVentas(intAnio, strMeses);
      for (const item of rs) {
        listaExp.push(item.ENCFAC_NUMERO);
      }
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso leer facturas de exportación no terminó satisfactoriamente.... ! ' + mensaje);
    }
	
	
    data = [];
    let row: object = new Object();						

	  for (const numero of listaExp) {

			if (await this.exportacionDe(numero) === '01') {
        row = new Object();
        // @ts-ignore
        row.detalleExportaciones = await this.getDatosExportacionConRefrendo(numero);
				data.push(row);
			}
			if (await this.exportacionDe(numero) === '02') {
        row = new Object();
        // @ts-ignore
        row.detalleExportaciones = await this.getDatosExportacionSinRefrendo(numero);
				data.push(row);
			}
		  if (await this.exportacionDe(numero) === '03') {
        row = new Object();
        // @ts-ignore
        row.detalleExportaciones = await this.getDatosExportacion03(numero);
				data.push(row);
      }
      
		}
		
	  return data;
  }

  async getDatosExportacion03(strNumFactura) {
    const parametros: any[] = [];
    let data = [];
    
    parametros[0] = strNumFactura;
    parametros[1] = '01';

    try {
      const rs = await this.generarXmlService.getDatosExportacion03(parametros);	
      data = [];
      let row: object = new Object();
    
      if (rs !== null) {
        if (rs.length > 0)  {	
          // @ts-ignore
          row.tpIdClienteEx = rs[0].CLI_TIPOIDE === null ? 'NOEXISTE' : rs[0].CLI_TIPOIDE;

          // @ts-ignore
          row.idClienteEx = rs[0].CLI_RUCIDE === null ? 'NOEXISTE' : rs[0].CLI_RUCIDE;

          // @ts-ignore
          row.parteRelExp = rs[0].CLI_PARTEREL === null ? 'NOEXISTE' : rs[0].CLI_PARTEREL;

          // @ts-ignore
          row.tipoCli = rs[0].TIPOCLI === null ? 'NOTEXISTE' : rs[0].TIPOCLI;

          // @ts-ignore
          row.denoExpCli = rs[0].DENOEXPCLI === null ? 'NOEXISTE' : rs[0].DENOEXPCLI;

          // @ts-ignore
          row.tipoRegi = rs[0].TIPOREGI === null ? 'NOEXISTE' : rs[0].TIPOREGI;

          // @ts-ignore
          row.paisEfecPagoGen = rs[0].PAISEFECPAGOGEN === null ? 'NOEXISTE' : rs[0].PAISEFECPAGOGEN;

          // @ts-ignore
          row.paisEfecExp = rs[0].PAISEFECEXP === null ? 'NOEXISTE' : rs[0].PAISEFECEXP;

          // @ts-ignore
          row.exportacionDe = rs[0].EXPORTACIONDE === null ? 'NOEXISTE' : rs[0].EXPORTACIONDE;
    
          // Servicios del exterior
          // @ts-ignore
          row.tipIngExt = rs[0].TIPOINGRESO === null ? 'NOEXISTE' : rs[0].TIPOINGRESO;

          // @ts-ignore
          row.ingExtGravOtroPais = rs[0].INGGRAVADOEXT === null ? 'NOEXISTE' : rs[0].INGGRAVADOEXT;

          if (rs[0].INGGRAVADOEXT === 'SI') {
          // @ts-ignore
            row.impuestoOtroPais = rs[0].VALORIMPEXT === null ? 'NOEXISTE' : Number(rs[0].VALORIMPEXT).toFixed(2);
          }
          
          // @ts-ignore
          row.tipoComprobante = rs[0].TIPOCOMPROBANTE === null ? 'NOEXISTE' : rs[0].TIPOCOMPROBANTE;

          // @ts-ignore
          row.fechaEmbarque = rs[0].FECHAEMBARQUE === null ? 'NOEXISTE' : rs[0].FECHAEMBARQUE;

          // @ts-ignore
          row.valorFOB = rs[0].VALORFOB === null ? 'NOEXISTE' : Number(rs[0].VALORFOB).toFixed(2);

          // @ts-ignore
          row.valorFOBComprobante = rs[0].VALORFOBCOMPROBANTE === null ? 'NOEXISTE' : Number(rs[0].VALORFOBCOMPROBANTE).toFixed(2);			

          // @ts-ignore
          row.establecimiento = rs[0].ESTABLECIMIENTO === null ? 'NOEXISTE' : rs[0].ESTABLECIMIENTO;		

          // @ts-ignore
          row.puntoEmision = rs[0].PUNTOEMISION === null ? 'NOEXISTE' : rs[0].PUNTOEMISION;		

          // @ts-ignore
          row.secuencial = rs[0].ENCFAC_SECACTUAL === null ? 'NOEXISTE' : rs[0].ENCFAC_SECACTUAL;		

          // @ts-ignore
          row.autorizacion = rs[0].ENCFAC_AUTORIZACION  === null ? 'NOEXISTE' : rs[0].ENCFAC_AUTORIZACION;		

          // @ts-ignore
          row.fechaEmision = rs[0].ENCFAC_FECHAEMISION  === null ? 'NOEXISTE' : rs[0].ENCFAC_FECHAEMISION;		
          
          data.push(row);
        }
      }
    
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso leer datos de exportación no terminó satisfactoriamente.... ! ' + mensaje);	
    } 
    return data;
  }

  async getDatosExportacionSinRefrendo(strNumFactura) {
    const parametros: any[] = [];
    let data = [];

    parametros[0] = strNumFactura;
    parametros[1] = '01';
    
    try {
      const rs = await this.generarXmlService.getDatosExportacionSinRefrendo(parametros)
      data = [];
      let row: object = new Object();

      if (rs !== null) {
        if (rs.length > 0) {	
          // @ts-ignore
          row.tpIdClienteEx = rs[0].CLI_TIPOIDE === null ? 'NOEXISTE' : rs[0].CLI_TIPOIDE;

          // @ts-ignore
          row.idClienteEx = rs[0].CLI_RUCIDE === null ? 'NOEXISTE' : rs[0].CLI_RUCIDE;

          // @ts-ignore
          row.parteRelExp = rs[0].CLI_PARTEREL === null ? 'NOEXISTE' : rs[0].CLI_PARTEREL;

          // @ts-ignore
          row.tipoCli = rs[0].TIPOCLI === null ? 'NOTEXISTE' : rs[0].TIPOCLI;

          // @ts-ignore
          row.denoExpCli = rs[0].DENOEXPCLI === null ? 'NOEXISTE' : rs[0].DENOEXPCLI;

          // @ts-ignore
          row.tipoRegi = rs[0].TIPOREGI === null ? 'NOEXISTE' : rs[0].TIPOREGI;

          // @ts-ignore
          row.paisEfecPagoGen = rs[0].PAISEFECPAGOGEN === null ? 'NOEXISTE' : rs[0].PAISEFECPAGOGEN;

          // @ts-ignore
          row.paisEfecExp = rs[0].PAISEFECEXP === null ? 'NOEXISTE' : rs[0].PAISEFECEXP;

          // @ts-ignore
          row.exportacionDe = rs[0].EXPORTACIONDE === null ? 'NOEXISTE' : rs[0].EXPORTACIONDE;

          // @ts-ignore
          row.tipoComprobante = rs[0].TIPOCOMPROBANTE === null ? 'NOEXISTE' : rs[0].TIPOCOMPROBANTE;

          // @ts-ignore
          row.fechaEmbarque = rs[0].FECHAEMBARQUE === null ? 'NOEXISTE' : rs[0].FECHAEMBARQUE;

          // @ts-ignore
          row.valorFOB = rs[0].VALORFOB === null ? 'NOEXISTE' : Number(rs[0].VALORFOB).toFixed(2);
  
          // @ts-ignore
          row.valorFOBComprobante = rs[0].VALORFOBCOMPROBANTE === null ? 'NOEXISTE' : Number(rs[0].VALORFOBCOMPROBANTE).toFixed(2);

          // @ts-ignore
          row.establecimiento = rs[0].ESTABLECIMIENTO === null ? 'NOEXISTE' : rs[0].ESTABLECIMIENTO;		

          // @ts-ignore
          row.puntoEmision = rs[0].PUNTOEMISION === null ? 'NOEXISTE' : rs[0].PUNTOEMISION;		

          // @ts-ignore
          row.secuencial = rs[0].ENCFAC_SECACTUAL === null ? 'NOEXISTE' : rs[0].ENCFAC_SECACTUAL;		

          // @ts-ignore
          row.autorizacion = rs[0].ENCFAC_AUTORIZACION === null ? 'NOEXISTE' : rs[0].ENCFAC_AUTORIZACION;		

          // @ts-ignore
          row.fechaEmision = rs[0].ENCFAC_FECHAEMISION === null ? 'NOEXISTE' : rs[0].ENCFAC_FECHAEMISION;		

          data.push(row);
        }	
      }
      
    
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso leer datos de exportación no terminó satisfactoriamente.... ! ' + mensaje);
    }
      
      
    return data;
  }

  async getDatosExportacionConRefrendo(strNumFactura) {
    const parametros: any[] = [];
    let data = [];
    
    
    parametros[0] = strNumFactura;
    parametros[1] = '01';
    
    try {

      const rs = await this.generarXmlService.getDatosExportacionConRefrendo(parametros);
      data = [];
      let row: object = new Object();

      if (rs !== null) {
        if (rs.length > 0) {	
          // @ts-ignore
          row.tpIdClienteEx = rs[0].CLI_TIPOIDE === null ? 'NOEXISTE' : rs[0].CLI_TIPOIDE;

          // @ts-ignore
          row.idClienteEx = rs[0].CLI_RUCIDE === null ? 'NOEXISTE' : rs[0].CLI_RUCIDE;

          // @ts-ignore
          row.parteRelExp = rs[0].CLI_PARTEREL === null ? 'NOEXISTE' : rs[0].CLI_PARTEREL;

          // @ts-ignore
          row.tipoCli = rs[0].TIPOCLI === null ? 'NOTEXISTE' : rs[0].TIPOCLI;

          // @ts-ignore
          row.denoExpCli = rs[0].DENOEXPCLI === null ? 'NOEXISTE' :  rs[0].DENOEXPCLI;

          // @ts-ignore
          row.tipoRegi = rs[0].TIPOREGI === null ? 'NOEXISTE' : rs[0].TIPOREGI;

          // @ts-ignore
          row.paisEfecPagoGen = rs[0].PAISEFECPAGOGEN === null ? 'NOEXISTE' : rs[0].PAISEFECPAGOGEN;

          // @ts-ignore
          row.paisEfecExp = rs[0].PAISEFECEXP === null ? 'NOEXISTE' : rs[0].PAISEFECEXP;

          // @ts-ignore
          row.exportacionDe = rs[0].EXPORTACIONDE === null ? 'NOEXISTE' : rs[0].EXPORTACIONDE;

          // @ts-ignore
          row.tipoComprobante = rs[0].TIPOCOMPROBANTE === null ? 'NOEXISTE' : rs[0].TIPOCOMPROBANTE;

          // @ts-ignore
          row.distAduanero = rs[0].DISTADUANERO === null ? 'NOEXISTE' : rs[0].DISTADUANERO;

          // @ts-ignore
          row.anio = rs[0].ANIO === null ? 'NOEXISTE' : rs[0].ANIO;

          // @ts-ignore
          row.regimen = rs[0].REGIMEN === null ? 'NOEXISTE' : rs[0].REGIMEN;

          // @ts-ignore
          row.correlativo = rs[0].CORRELATIVO === null ? 'NOEXISTE' :  rs[0].CORRELATIVO;

          // @ts-ignore
          row.docTransp = rs[0].DOCTRANSP === null ? 'NOEXISTE' :rs[0].DOCTRANSP;

          // @ts-ignore
          row.fechaEmbarque = rs[0].FECHAEMBARQUE === null ? 'NOEXISTE' : rs[0].FECHAEMBARQUE;

          // @ts-ignore
          row.valorFOB = rs[0].VALORFOB === null ? 'NOEXISTE' : Number(rs[0].VALORFOB).toFixed(2);

          // @ts-ignore
          row.valorFOBComprobante = rs[0].VALORFOBCOMPROBANTE === null ? 'NOEXISTE' : Number(rs[0].VALORFOBCOMPROBANTE).toFixed(2);

          // @ts-ignore
          row.establecimiento = rs[0].ESTABLECIMIENTO === null ? 'NOEXISTE' : rs[0].ESTABLECIMIENTO;		

          // @ts-ignore
          row.puntoEmision = rs[0].PUNTOEMISION === null ? 'NOEXISTE' : rs[0].PUNTOEMISION;		

          // @ts-ignore
          row.secuencial = rs[0].ENCFAC_SECACTUAL === null ? 'NOEXISTE' : rs[0].ENCFAC_SECACTUAL;		

          // @ts-ignore
          row.autorizacion = rs[0].ENCFAC_AUTORIZACION === null ? 'NOEXISTE' : rs[0].ENCFAC_AUTORIZACION;		

          // @ts-ignore
          row.fechaEmision = rs[0].ENCFAC_FECHAEMISION === null ? 'NOEXISTE' : rs[0].ENCFAC_FECHAEMISION;		
      
          data.push(row);
      
        }
      }
      
    
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso leer datos de exportación no terminó satisfactoriamente.... ! ' + mensaje);
    }
     
    return data;
  }

  async exportacionDe(strNumFactura) {
    let expor = '';
    const parametros: any[] = [];
    parametros[0] = strNumFactura;
    parametros[1] = '01';
    
    
    try {
      const rs = await this.generarXmlService.exportacionDe(parametros);	

      if (rs !== null) {
        if (rs.length > 0) {
          expor= rs[0].EXPORTACIONDE === null ? 'NOEXISTE' : rs[0].EXPORTACIONDE;
        }
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso leer datos de exportación no terminó satisfactoriamente.... ! ' + mensaje);
    
    }

    return expor;
  }



  async getEstablecimiento1(intAnio, strMes, strSoloFacFisicas) {
    let strSentencia = '';
		let strNCNOFAC = String(this.intNCNOFAC);
		let sw = 0;
		
		const listaEst = [];
    let data = [];

    const  fechaFin = this.leerFechaFinal(intAnio,strMes, this.strMicroempreas, strMes);
    const fechaIni = this.leerFechaInicial(intAnio,strMes, this.strMicroempreas, strMes);
    
    if (strNCNOFAC === '0')	{
			if (strSoloFacFisicas === '0') {
				strSentencia = 'select distinct  substr(encfac_serie,1,3) as encfac_serie from ven_encfac';  
				strSentencia = strSentencia + ' where encfac_estado<>\'A\' ';
				strSentencia = strSentencia + ' and to_date(to_char(encfac_fechaemision,\'dd/mm/yyyy\'),\'dd/mm/yyyy\')>=to_date(\'' + fechaIni + '\',\'dd/mm/yyyy\')';
				strSentencia = strSentencia + ' and to_date(to_char(encfac_fechaemision,\'dd/mm/yyyy\'),\'dd/mm/yyyy\')<=to_date(\'' + fechaFin + '\',\'dd/mm/yyyy\')';
				
				strSentencia=strSentencia + ' union ';
				strSentencia=strSentencia + ' select distinct ANEX_PCK_IVAVEN_C.ANEX_FC_ConsultarSerieDev(encdvf_numero,com_codigo)   from ven_encdvf';  
				strSentencia=strSentencia + ' where encdvf_estado<>\'A\' ';
				strSentencia=strSentencia + ' and to_date(to_char(encdvf_fechaemision,\'dd/mm/yyyy\'),\'dd/mm/yyyy\')>=to_date(\'' + fechaIni + '\',\'dd/mm/yyyy\')';
				strSentencia=strSentencia + ' and to_date(to_char(encdvf_fechaemision,\'dd/mm/yyyy\'),\'dd/mm/yyyy\')<=to_date(\'' + fechaFin + '\',\'dd/mm/yyyy\')';			
			}
		} else {

      if (strSoloFacFisicas === '0')	{

				strSentencia = 'select distinct  substr(encfac_serie,1,3) as encfac_serie from ven_encfac';  
				strSentencia = strSentencia + ' where encfac_estado<>\'A\' ';
				strSentencia = strSentencia + ' and to_date(to_char(encfac_fechaemision,\'dd/mm/yyyy\'),\'dd/mm/yyyy\')>=to_date(\'' + fechaIni + '\',\'dd/mm/yyyy\')';
				strSentencia = strSentencia + ' and to_date(to_char(encfac_fechaemision,\'dd/mm/yyyy\'),\'dd/mm/yyyy\')<=to_date(\'' + fechaFin + '\',\'dd/mm/yyyy\')';
				
				strSentencia =strSentencia + ' union ';
				strSentencia = strSentencia + ' select distinct  substr(encdvf_serie,1,3)   from ven_encdvf';  
				strSentencia = strSentencia + ' where encdvf_estado<>\'A\' ';
				strSentencia = strSentencia + ' and to_date(to_char(encdvf_fechaemision,\'dd/mm/yyyy\'),\'dd/mm/yyyy\')>=to_date(\'' + fechaIni + '\',\'dd/mm/yyyy\')';
				strSentencia = strSentencia + ' and to_date(to_char(encdvf_fechaemision,\'dd/mm/yyyy\'),\'dd/mm/yyyy\')<=to_date(\'' + fechaFin + '\',\'dd/mm/yyyy\')';				
			
			}

    }

    try {
      let rs;
      if (strNCNOFAC === '0')	{
        if (strSoloFacFisicas === '0') {
          rs = await this.generarXmlService.ejecutarSentencia1(fechaIni, fechaFin);
        }
      } else {
  
        if (strSoloFacFisicas === '0')	{
          rs = await this.generarXmlService.ejecutarSentencia2(fechaIni, fechaFin);
          
        }
  
      }

			for (const item of rs)	{	
				listaEst.push(item.ENCFAC_SERIE);
				sw = 1;
      }
      
		} catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso leer series no terminó satisfactoriamente.... ! ' + mensaje);				
    }
    
    //si no exiten datos de ventas en este rango de fechas
		
		if (sw === 0)	{
			if (strNCNOFAC === '0')	{
				if (strSoloFacFisicas === '0') {
          
					strSentencia = 'select distinct  substr(encfac_serie,1,3) as encfac_serie from ven_encfac';  
					strSentencia = strSentencia + ' where encfac_estado<>\'A\' ';

					strSentencia = strSentencia + ' union ';
					strSentencia = strSentencia + ' select distinct ANEX_PCK_IVAVEN_C.ANEX_FC_ConsultarSerieDev(encdvf_numero,com_codigo)   from ven_encdvf';  
					strSentencia = strSentencia + ' where encdvf_estado<>\'A\'';
					
				}
			}	else {
				if (strSoloFacFisicas === '0')	{
					strSentencia = 'select distinct  substr(encfac_serie,1,3) as encfac_serie from ven_encfac';  
					strSentencia = strSentencia + ' where encfac_estado<>\'A\' ';
					
					strSentencia = strSentencia + ' union ';
					strSentencia = strSentencia + ' select distinct  substr(encdvf_serie,1,3)   from ven_encdvf';  
					strSentencia = strSentencia + ' where encdvf_estado<>\'A\' ';
					
				}
			}		
			
			try {
        let rs;
        if (strNCNOFAC === '0')	{
          if (strSoloFacFisicas === '0') {
            rs = await this.generarXmlService.ejecutarSentencia3();
          }
        }	else {
          if (strSoloFacFisicas === '0')	{
            rs = await this.generarXmlService.ejecutarSentencia4();
            
          }
        }	
        for (const item of rs)	{	
          listaEst.push(item.ENCFAC_SERIE);
          sw = 1;
        }
		
			} catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', '! Proceso leer series no terminó satisfactoriamente.... ! ' + mensaje);		
			}	

		} // si se despliegan datos de ventas				
		

		data = [];
    let row: object = new Object();			

		for (const item of listaEst)	{
      row = new Object();
      
      if ( sw === 1) {
        // @ts-ignore
        row.ventaEst = await this.getEstablecimiento(item, intAnio, strMes, strSoloFacFisicas);
      } else {
        // @ts-ignore
        row.ventaEst = await this.getEstablecimientoConCero(item);
      }
    
      data.push(row);
		}

		return data;
  }

  async getEstablecimientoConCero(strSerie) {

    let dblBaseIva = 0;
    let data = [];

		dblBaseIva = 0;
		
    data = [];

    let row: object = new Object();
    // @ts-ignore		
    row.codEstab = strSerie;
    // @ts-ignore
    row.ventasEstab = Number(dblBaseIva).toFixed(2);
    data.push(row);
	
		return data;
	}

  async getEstablecimiento(strSerie, intAnio, strMes, strSoloFacFisicas) {
    let dblBaseIva = 0;
    let data = [];
		dblBaseIva = await this.totalVentasXSerie(strMes, String(intAnio), strSerie, strSoloFacFisicas);
		
    data = [];
    let row: object = new Object();	
    // @ts-ignore		
    row.codEstab = strSerie;
    // @ts-ignore		
    row.ventasEstab = Number(dblBaseIva).toFixed(2);
    data.push(row);
	 
		return data;
  }
  
  async totalVentasXSerie(strPerInf, strAnio, strSerie, strSoloFacturasFisicas) {
		const parametros: any[] = [];
		let dblTotalVentas = 0;

		parametros[0] = strPerInf;
		parametros[1] = strAnio;
		parametros[2] = strSerie;
		parametros[3] = '01';
		parametros[4] = Number(strSoloFacturasFisicas);
		parametros[5] = this.strMicroempreas;

		try {
      const rs = await this.generarXmlService.totalVentasXSerie(parametros);

      if (rs !== null) {
        if (rs.length > 0) {
          dblTotalVentas = rs[0]['NVL(:B1,0)'];
        }
      }

		} catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso leer ventas totales por serie de facturación)no terminó satisfactoriamente.... ! ' + mensaje);		
    }
    
		return dblTotalVentas;
	}

  leerFechaFinal(intAnio, strMes, strMicroempresas, periodo) {
    let fechaFin = '';
    if (strMicroempresas === 'SI') {
      if (strMes === '01' || strMes === '02' || strMes === '03' || strMes === '04' || strMes === '05'
        || strMes === '06') {
        fechaFin = this.getNexDate('06', intAnio);
      } else {
        fechaFin = this.getNexDate('12', intAnio);
      }
    } else {
      fechaFin = this.getNexDate(periodo, intAnio);
    }
    
    return fechaFin;
  }

  
  getNexDate(periodo, anio) {
    const fecha = new Date(anio, Number(periodo), 0);
    const strFecha = this.datePipe.transform(fecha, 'dd/MM/yyyy');
    return strFecha;
  }
  
  
  leerFechaInicial(intAnio, strMes, strMicroempresas, periodo) {
    let fechaIni = '';
    if (strMicroempresas === 'SI') {
      if (strMes === '01' || strMes === '02' || strMes === '03' || strMes === '04' || strMes === '05'
        || strMes === '06') {
        fechaIni = '01/' + '01' + '/' + intAnio;
      } else {
        fechaIni = '01/' + '07' + '/' + intAnio;
      }
    } else {
      fechaIni = '01/' + periodo + '/' + intAnio;
    }
    
    return fechaIni;
  }

  async getVentas(anio, periodo) {
    const data = [];
    let ventas = [];

    const strMeses = this.cadenaMeses(anio, periodo, this.strMicroempreas);

    ventas = await this.consultarTrnVentas(anio, strMeses);

    if (ventas.length <= 0) {
			return data;
    }
    
    for (const a of ventas) {
      const row: object = new Object();
      // @ts-ignore
      row.detalleVentas = await this.createVenta(a);
      data.push(row);
    }
    
		return data;
  }

  async createVenta(venta) {
    const data = [];
    const row: object = new Object();
    let strTipoDenClie = '';

		let idCliente = venta.cliRucIde === null ? '' : venta.cliRucIde; 
		idCliente = String(idCliente).trim().length > 13 ? String(idCliente).substring(0, 13) : String(idCliente).trim();
		strTipoDenClie = venta.anexIvaVenTipIdCli;
		if(idCliente.startsWith('99999999') && idCliente.endsWith('99999999')){
      idCliente = '9999999999999';
      // @ts-ignore
      row.tpIdCliente = '07';
			strTipoDenClie = '07';
		} else {
      // @ts-ignore
      row.tpIdCliente = venta.anexIvaVenTipIdCli;
    }

    // @ts-ignore
    row.idCliente = idCliente;

    if (strTipoDenClie === '04' ||strTipoDenClie === '05'|| strTipoDenClie === '06') {
			if (venta.RetTarjeta === '' || venta.RetTarjeta === null) {
        // @ts-ignore
        row.parteRelVtas = venta.anexIvaVenParteRelacionada;
			}	else	{
        // @ts-ignore
        row.parteRelVtas = 'NO';
			}
    }
    
    //SI ES PASAPORTE DESPLIEGA EL TIPO DE DENOMINACION
		if (strTipoDenClie === '06')	{
      // @ts-ignore
      row.tipoCliente = venta.anexIvaVenParteRelacionada;

			if (venta.cliTipoDen === 'Natural') {
        // @ts-ignore
        row.tipoCliente = '01';
      } else {
        // @ts-ignore
        row.tipoCliente = '02';
      }

      // @ts-ignore
      row.denoCli = String(venta.cliNombre).trim();
    }

    let comp = this.autoCompletar(venta.anexIvaVenIdTipCom,2);
    // @ts-ignore
    row.tipoComprobante = comp;

    // @ts-ignore
    row.tipoEmision = venta.facFisica;

    // @ts-ignore
    row.numeroComprobantes = venta.anexIvaVenNumComp;

    // @ts-ignore
    row.baseNoGraIva = Number(venta.anexIvaVenBaseNoObjIva).toFixed(2);

    // @ts-ignore
    row.baseImponible = Number(venta.anexIvaVenBaseTarCero).toFixed(2);

    // @ts-ignore
    row.baseImpGrav = Number(venta.anexIvaVenBaseIva).toFixed(2);

    // @ts-ignore
    row.montoIva = Number(venta.anexIvaVenMontoIva).toFixed(2);

    // @ts-ignore
    row.montoIce = Number(venta.anexIvaVenMontoIce).toFixed(2);

    // @ts-ignore
    row.valorRetIva = Number(venta.anexIvaVenValIvaRet).toFixed(2);

    // @ts-ignore
    row.valorRetRenta = Number(venta.anexIvaVenValRentaRet).toFixed(2);

    if (venta.anexIvaVenIdTipCom === '18' || venta.anexIvaVenIdTipCom === '41' || venta.anexIvaVenIdTipCom === '01' || 
      venta.anexIvaVenIdTipCom === '371' || venta.anexIvaVenIdTipCom === '370') {
      // @ts-ignore
      row.valorRetRenta = Number(venta.anexIvaVenValRentaRet).toFixed(2);

      if (venta.RetTarjeta === '' || venta.RetTarjeta === null) {
         // @ts-ignore
        row.formasDePago = await this.getFormaPagoVentas(venta);
      } else {
         // @ts-ignore
        row.formasDePago = await this.getFormaPagoVentas_retTar(venta);
      }
    }
    
    data.push(row);
    
    return data;

  }
  
  async getFormaPagoVentas(venta) {
    let strSentencia = '';
    let strCodFormaPago = '';
    let data = [];
		let sw = 0;
		
		strSentencia = 'select  distinct tranformpag_formapago from anex_tranformpago';
		strSentencia = strSentencia + ' where cli_rucide= \'' + venta.cliRucIde + '\'';		
    
    try {
      const rs = await this.generarXmlService.getFormaPagoVentas(venta.cliRucIde);
      
      data = [];
      
     // if (rs.length > 0) {
        for (const item of rs ) {
          const row: object = new Object();
          strCodFormaPago = '';
          strCodFormaPago = item.TRANFORMPAG_FORMAPAGO === null ? '' : item.TRANFORMPAG_FORMAPAGO;
          console.log(strCodFormaPago);
					// @ts-ignore
          row.formaPago = strCodFormaPago;
          console.log(row);
      
          data.push(row);
					sw = 1;
        }
     // }
     

			if (sw === 0) {
        const row: object = new Object();
        console.log('ingresa sw===0');
        // @ts-ignore
        row.formaPago = '20';
        data.push(row);
			}
					
		} catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso leer formas de pago de ventas no terminó satisfactoriamente.... ! ' + mensaje);			
    }

    console.log(data);
    
    return data;
  }

  async getFormaPagoVentas_retTar(venta) {
    const data = [];
    const row: object = new Object();
    // @ts-ignore
    row.formaPago = '01';
    data.push(row);
   
    return data;
  }

  async consultarTrnVentas(anio, meses) {
    let rs;
    try {
      rs = await this.generarXmlService.consultarTrnVentas(anio, meses);
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error',  mensaje);
    }

    const variablesven = ['anexIvaVenNumero', 'comCodigo', 'anexIvaVenPerInf',
    'anexIvaVenTipIdCli', 'anexIvaVenIdTipCom', 'anexIvaVenFecEmiCom', 'cliCodigo',
    'anexIvaVenNumComp', 'anexIvaVenSAP', 'anexIvaVenBaseTarCero', 'anexIvaVenBaseIva',
    'anexIvaVenBaseNoObjIva', 'anexIvaVenMontoIva', 'anexIvaVenValIvaRet',
    'anexIvaVenValRentaRet', 'anexIvaVenEsquema', 'anexIvaVenAnio', 'rowNum',
    'cliNombre' ,'cliRucIde','anexIvaVenParteRelacionada','cliTipoDen','facFisica','RetTarjeta','anexIvaVenMontoIce'];
    
    const columnsven = ['ANEXOSIVA_NUMERO', 'COM_CODIGO', 'ANEXOSIVA_PERINF',
    'ANEXOSIVA_TIPIDCLI', 'ANEXOSIVA_IDTIPCOM', 'ANEXOSIVA_FECEMICOM', 'CLI_CODIGO',
    'ANEXOSIVA_NUMCOMP', 'ANEXOSIVA_SAP', 'ANEXOSIVA_BASETARCERO', 'ANEXOSIVA_BASEIVA',
    'ANEXOSIVA_BASENOOBJIVA', 'ANEXOSIVA_MONTOIVA', 'ANEXOSIVA_VALIVARET',
    'ANEXOSIVA_VALRENTARET', 'ANEXOSIVA_ESQUEMA', 'ANEXOSIVA_ANIO', 'ROWNUM',
    'CLI_NOMBRE','CLI_RUCIDE','ANEXOSIVA_PARTEREL','CLI_TIPODEN' ,'ANEXOSIVA_FACFISICA',
    'ANEXOSIVA_RETTAR','ANEXOSIVA_MONTOICE'];
    
    return this.dataSource(rs, variablesven, columnsven);
  }
  
  async getCompras(anio, periodo) {
    const data = [];
    let compras = [];
    this.intRetElectronicas = 0;
    const strMeses = this.cadenaMeses(anio, periodo, this.strMicroempreas);
    
    
    compras = await this.consultarTrnCompras(anio, strMeses);
    
    
    if (compras.length <= 0) {
      return data;
    }
    
    try {
      const rs = await this.generarXmlService.tipoAmbienteFactEle();
      if (rs.length > 0) {
        if (rs[0].COM_TIPOAMBFACTELEC === '1' || rs[0].COM_TIPOAMBFACTELEC === '2') { // Si esta en ambiente producción
          this.intRetElectronicas = 1;
        } else {
          this.intRetElectronicas = 0;
        }
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error',  mensaje);
    }
    
    for (const a of compras) {
      const row: object = new Object();
      // @ts-ignore
      row.detalleCompras = await this.createCompra(a);
      data.push(row);
    }
    
    return data;
  }
  
  cadenaMeses(intAnio, strMes, strMicroempresas) {
    let strCadena = '';
    if (strMicroempresas === 'SI') {
      if (strMes === '01' || strMes === '02' || strMes === '03' || strMes === '04' || strMes === '05' || strMes === '06') {
        strCadena = '(\'01\',\'02\',\'03\',\'04\',\'05\',\'06\')';
      } else {
        strCadena = '(\'07\',\'08\',\'09\',\'10\',\'11\',\'12\')';
      }
    } else {
      strCadena = '(\'' + strMes + '\')';
    }
    return strCadena;
  }
  
  async consultarTrnCompras(anio, meses) {

    let rs;

    try {
      rs = await this.generarXmlService.consultarTrnCompras(anio, meses);
      console.log(rs);
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error',  mensaje);
    }
    
    
    const variablescom = ['anexIvaComNumero', 'comCodigo', 'anexIvaComPerInf',
      'anexIvaComIdSusTrib', 'anexIvaComTipIdProv', 'anexIvaComIdTipComp',
      'proCodigo', 'anexIvaComSAP', 'anexIvaComFecRegCon', 'anexIvaComSerVtaEst',
      'anexIvaComSecVtaEst', 'anexIvaComFecEmiComVta', 'anexIvaComAutComVta',
      'anexIvaComTipComNDNC', 'anexIvaComSerComEst', 'anexIvaComSecComMod',
      'anexIvaComAutComMod', 'anexIvaComBaseTarCero', 'anexIvaComBaseIva',
      'anexIvaComMonIva', 'anexIvaComBaseNoObjIva', 'anexIvaComConcepRet1',
      'anexIvaComCodRet1', 'anexIvaComImpRenta1', 'anexIvaComMonRet1',
      'anexIvaComConcepRet2', 'anexIvaComCodRet2', 'anexIvaComImpRenta2',
      'anexIvaComMonRet2', 'anexIvaComConcepRet3', 'anexIvaComCodRet3',
      'anexIvaComImpRenta3', 'anexIvaComMonRet3', 'anexIvaComConcepRet4',
      'anexIvaComCodRet4', 'anexIvaComImpRenta4', 'anexIvaComMonRet4',
      'anexIvaComMonRetIvaB', 'anexIvaComMonRetIvaS', 'anexIvaComMonRetIvaT',
      'anexIvaComNumSer1', 'anexIvaComNumRet1', 'anexIvaComNumAutoriza1',
      'anexIvaComFecEmiRet1', 'anexIvaComNumSer2', 'anexIvaComNumRet2',
      'anexIvaComNumAutoriza2', 'anexIvaComFecEmiRet2', 'anexIvaComFecEmiCom',
      'anexIvaComEsquema', 'anexIvaComMonIce', 'rowNum',
      'anexosIvaFechaPagoDiv', 'anexosIvaImpRenPagado', 'anexosIvaAnioUtil',
      'proNombre', 'anexIvaComAnio' , 'proRucID', 'TipoProveedor', 'parteRelacionada', 'anexosIvaConcepto',
      'anexIvaComMonRetIvaB10', 'anexIvaComMonRetIvaS20', 'proveedorFactElect', 'anexIvaComMonRetIvaS50', 'anexTipoDedominacion'];
    
    const columnscom = ['ANEXOSIVA_NUMERO', 'COM_CODIGO', 'ANEXOSIVA_PERINF',
      'ANEXOSIVA_IDSUSTRIB', 'ANEXOSIVA_TIPIDPROV', 'ANEXOSIVA_IDTIPCOMP',
      'PRO_CODIGO', 'ANEXOSIVA_SAP', 'ANEXOSIVA_FECREGCON',
      'ANEXOSIVA_SERVTAEST', 'ANEXOSIVA_SECVTAEST', 'ANEXOSIVA_FECEMICOMVTA',
      'ANEXOSIVA_AUTCOMVTA', 'ANEXOSIVA_TIPCOMNDNC', 'ANEXOSIVA_SERCOMEST',
      'ANEXOSIVA_SECCOMPMOD', 'ANEXOSIVA_AUTCOMMOD', 'ANEXOSIVA_BASETARCERO',
      'ANEXOSIVA_BASEIVA', 'ANEXOSIVA_MONIVA', 'ANEXOSIVA_BASENOOBJIVA',
      'ANEXOSIVA_CONCEPRET1', 'ANEXOSIVA_CODRET1', 'ANEXOSIVA_IMPRENTA1',
      'ANEXOSIVA_MONRET1', 'ANEXOSIVA_CONCEPRET2', 'ANEXOSIVA_CODRET2',
      'ANEXOSIVA_IMPRENTA2', 'ANEXOSIVA_MONRET2', 'ANEXOSIVA_CONCEPRET3',
      'ANEXOSIVA_CODRET3', 'ANEXOSIVA_IMPRENTA3', 'ANEXOSIVA_MONRET3',
      'ANEXOSIVA_CONCEPRET4', 'ANEXOSIVA_CODRET4', 'ANEXOSIVA_IMPRENTA4',
      'ANEXOSIVA_MONRET4', 'ANEXOSIVA_MONRETIVAB', 'ANEXOSIVA_MONRETIVAS',
      'ANEXOSIVA_MONRETIVAT', 'ANEXOSIVA_NUMSER1', 'ANEXOSIVA_NUMRET1',
      'ANEXOSIVA_NUMAUTORIZA1', 'ANEXOSIVA_FECEMIRET1', 'ANEXOSIVA_NUMSER2',
      'ANEXOSIVA_NUMRET2', 'ANEXOSIVA_NUMAUTORIZA2', 'ANEXOSIVA_FECEMIRET2',
      'ANEXOSIVA_FECEMICOM', 'ANEXOSIVA_ESQUEMA', 'ANEXOSIVA_MONICE', 'ROWNUM',
      'ANEXOSIVA_FECHAPAGODIV', 'ANEXOSIVA_IMPRENPAGADO', 'ANEXOSIVA_ANIOUTIL',
      'PRO_NOMBRE', 'ANEXOSIVA_ANIO', 'PRO_RUCID', 'PRO_TIPOA', 'ANEXOSIVA_PARTEREL',
      'ANEXOSIVA_CONCEPTO', 'ANEXOSIVA_MONRETB10', 'ANEXOSIVA_MONRETS20', 'PRO_FACTURAELECT',
      'ANEXOSIVA_MONRETS50', 'PRO_TIPODEN'];
    
    
    return this.dataSource(rs, variablescom, columnscom);
    
  }
  
  dataSource(rs, variables, columnas) {
    if (rs === null) {
      return [];
    }
    
    const obj = [];
    for (const item of rs) {
      const objDet: object = new Object();
      for (const label in item) {
        for (let i = 0; i < columnas.length; i++) {
          if (label === columnas[i]) {
            objDet[variables[i]] = item[label];
          }
        }
      }
      obj.push(objDet);
    }
    
    return obj;
  }
  
  async createCompra(compra) {
    
    const data = [];
    const row: object = new Object();
    
    // @ts-ignore
    row.codSustento = compra.anexIvaComIdSusTrib;
    // @ts-ignore
    row.tpIdProv = compra.anexIvaComTipIdProv;
    // @ts-ignore
    row.idProv = String(compra.proRucID).trim().length > 13 ? String(compra.proRucID).substring(0, 13) : String(compra.proRucID).trim();
    // @ts-ignore
    row.tipoComprobante = this.autoCompletar(compra.anexIvaComIdTipComp, 2);
    // data.push(row);
    
    // SI ES PASAPORTE DESPLIEGA EL TIPO DE DENOMINACION
    if (compra.anexIvaComTipIdProv === '03') {
      // @ts-ignore
      row.tipoProv = compra.anexTipoDedominacion === 'Natural' ? '01' : '02';
      // @ts-ignore
      row.denoProv = String(compra.proNombre).trim();//CDPJ
    }
    
    
    if (compra.anexIvaComTipIdProv === '01' || compra.anexIvaComTipIdProv === '02' || compra.anexIvaComTipIdProv === '03') {
      // @ts-ignore
      row.parteRel = compra.parteRelacionada;
    }
    
    // @ts-ignore
    row.fechaRegistro = this.dateValidation(compra.anexIvaComFecEmiComVta);
    // @ts-ignore
    row.establecimiento = String(compra.anexIvaComSerVtaEst).substring(0, 3);
    // @ts-ignore
    row.puntoEmision = String(compra.anexIvaComSerVtaEst).substring(3);
    // @ts-ignore
    row.secuencial = compra.anexIvaComSecVtaEst;
    // @ts-ignore
    row.fechaEmision = this.dateValidation(compra.anexIvaComFecEmiComVta);
    // @ts-ignore
    row.autorizacion = compra.anexIvaComAutComVta;
    
    // @ts-ignore
    row.baseNoGraIva = Number(compra.anexIvaComBaseNoObjIva).toFixed(2);
    // @ts-ignore
    row.baseImponible = Number(compra.anexIvaComBaseTarCero).toFixed(2);
    // @ts-ignore
    row.baseImpGrav = Number(compra.anexIvaComBaseIva).toFixed(2);
    // @ts-ignore
    row.baseImpExe = Number(0).toFixed(2);
    // @ts-ignore
    row.montoIce = Number(compra.anexIvaComMonIce).toFixed(2);
    // @ts-ignore
    row.montoIva = Number(compra.anexIvaComMonIva).toFixed(2);
    // @ts-ignore
    row.valRetBien10 = Number(compra.anexIvaComMonRetIvaB10).toFixed(2);
    // @ts-ignore
    row.valRetServ20 = Number(compra.anexIvaComMonRetIvaS20).toFixed(2);
    // @ts-ignore
    row.valorRetBienes = Number(compra.anexIvaComMonRetIvaB).toFixed(2);
    // @ts-ignore
    row.valRetServ50 = Number(compra.anexIvaComMonRetIvaS50).toFixed(2);
    // @ts-ignore
    row.valorRetServicios = Number(compra.anexIvaComMonRetIvaS).toFixed(2);
    // @ts-ignore
    row.valRetServ100 = Number(compra.anexIvaComMonRetIvaT).toFixed(2);
    // @ts-ignore
    row.totbasesImpReemb = compra.anexIvaComIdTipComp === '41' ? Number(compra.anexIvaComBaseNoObjIva +
      compra.anexIvaComBaseTarCero  + compra.anexIvaComBaseIva).toFixed(2) : Number(0).toFixed(2);
    
    // ******nuevo
    // pago al exterior
    // @ts-ignore
    row.pagoExterior = await this.pagoExterior(compra);
    
    // forma de pago
    if (compra.anexIvaComIdTipComp === '05') {
      if (await this.verificaFormaPagoCXP(compra) !== 0) {
        // @ts-ignore
        row.formasDePago = await this.getFormaPagoCXP(compra);
      }
    } else {
      if (await this.verificaFormaPago(compra) !== 0) {
        // @ts-ignore
        row.formasDePago = await this.getFormaPago(compra);
      }
    }
    
    // retenciones de forma de pago
    
    let intNumRetNormales = await this.verifcarExistenciaRetencionesEsp(compra);
    
    
    if (this.strEncfacproRetserieEst !== '' && compra.anexIvaComIdTipComp === '01' && intNumRetNormales !== 0) {
      let strNumeroAutorizacion = '';
      let strNumeroSecuencia = '';
      const parametros = await this.autorizacionElectronica(String(compra.anexIvaComPerInf), compra.anexIvaComNumero);
      
      if (parametros[1] !== null) {
        strNumeroAutorizacion = parametros[1];
      }
      if (parametros[2] !== null) {
        strNumeroSecuencia = parametros[2];
      }
      
      // @ts-ignore
      row.estabRetencion1 = this.strEncfacproRetserieEst;
      // @ts-ignore
      row.ptoEmiRetencion1 = this.strEncfacproRetserieptoemi;
      
      // @ts-ignore
      row.secRetencion1 =  this.intRetElectronicas === 1 ? (strNumeroSecuencia !== '' ?
        strNumeroSecuencia : this.strEncfacproRetsecuencial) : this.strEncfacproRetsecuencial;
      // @ts-ignore
      row.autRetencion1 = this.strEncfacproRetautorizacion;
      // @ts-ignore
      row.fechaEmiRet1 = this.strEncfacproRetfecha;
    }
    
    // NUEVO CAMBIO
    // Aca se agrega el detalle de retenciones
    const air = await this.getAir(compra);
    
    if (air.length > 0 && compra.anexIvaComIdTipComp !== '41') {
      // @ts-ignore
      row.air = air;
    }
    
    // Reembolsos
    
    if (await this.verificaReembolsos(compra) !== 0) {
      // @ts-ignore
      row.reembolsos = await this.getReembolos1(compra);
    }
    
    // /se continua con los demas datos(comprobantes de retencion y notas de
    // Debito o credito)
    intNumRetNormales = await this.verifcarExistenciaRetencionesEsp(compra);
    
    if (compra.anexIvaComNumSer1 !== null && compra.anexIvaComIdTipComp === '01' && intNumRetNormales !== 0 ) {
      let parametros = [];
      let strNumeroAutorizacion = '';
      let strNumeroSecuencia = '';
      parametros = await this.autorizacionElectronica(compra.anexIvaComPerInf, compra.anexIvaComNumero);
      
      if (parametros[1] !== null) {
        strNumeroAutorizacion = parametros[1];
      }
      
      if (parametros[2] !== null) {
        strNumeroSecuencia = parametros[2];
      }
      
      // @ts-ignore
      row.estabRetencion1 = String(compra.anexIvaComNumSer1).substring(0, 3);
      // @ts-ignore
      row.ptoEmiRetencion1 = String(compra.anexIvaComNumSer1).substring(3);
      
      // @ts-ignore
      row.secRetencion1 =  this.intRetElectronicas === 1 ? (strNumeroSecuencia !== '' ?
        strNumeroSecuencia : compra.anexIvaComNumRet1) : compra.anexIvaComNumRet1;
      // @ts-ignore
      row.autRetencion1 = this.intRetElectronicas === 1 ? (strNumeroAutorizacion !== '' ? strNumeroAutorizacion :
        compra.anexIvaComNumAutoriza1) : compra.anexIvaComNumAutoriza1;
      
      // @ts-ignore
      row.fechaEmiRet1 = compra.anexIvaComFecEmiRet1 === null ? this.dateValidation(compra.anexIvaComFecEmiComVta) :
        this.dateValidation(compra.anexIvaComFecEmiRet1);
      
    }
    
    if (compra.anexIvaComNumSer2 !== null) {
      
      let parametros = [];
      let strNumeroAutorizacion = '';
      let strNumeroSecuencia = '';
      
      parametros = await this.autorizacionElectronica(compra.anexIvaComPerInf, compra.anexIvaComNumero);
      
      if (parametros[1] !== null) {
        strNumeroAutorizacion = parametros[1];
      }
      
      if (parametros[2] !== null) {
        strNumeroSecuencia = parametros[2];
      }
      
      // @ts-ignore
      row.estabRetencion2 = String(compra.anexIvaComNumSer2).substring(0, 3);
      // @ts-ignore
      row.ptoEmiRetencion2 = String(compra.anexIvaComNumSer2).substring(3);
      // @ts-ignore
      row.secRetencion2 =  this.intRetElectronicas === 1 ? (strNumeroSecuencia !== '' ?
        strNumeroSecuencia : compra.anexIvaComNumRet2) : compra.anexIvaComNumRet2;
      // @ts-ignore
      row.autRetencion2 = this.intRetElectronicas === 1 ? (strNumeroAutorizacion !== '' ? strNumeroAutorizacion :
        compra.anexIvaComNumAutoriza2) : compra.anexIvaComNumAutoriza2;
      // @ts-ignore
      row.fechaEmiRet2 = compra.anexIvaComFecEmiRet2 === null ? this.dateValidation(compra.anexIvaComFecEmiComVta) :
        this.dateValidation(compra.anexIvaComFecEmiRet2);
      
    }
    
    if (compra.anexIvaComIdTipComp === '04' || compra.anexIvaComIdTipComp === '05') {
      // @ts-ignore
      row.docModificado =  String(compra.anexIvaComTipComNDNC).trim() === '' ? '00' : this.autoCompletar(compra.anexIvaComTipComNDNC, 2);
      // @ts-ignore
      row.estabModificado =  String(compra.anexIvaComSerComEst).trim() === '' ? '000' : String(compra.anexIvaComSerComEst).substring(0, 3);
      // @ts-ignore
      row.ptoEmiModificado =  String(compra.anexIvaComSerComEst).trim() === '' ? '000' : String(compra.anexIvaComSerComEst).substring(3);
      // @ts-ignore
      row.secModificado =  String(compra.anexIvaComSecComMod).trim() === '' ? '0000000' : compra.anexIvaComSecComMod;
      // @ts-ignore
      row.autModificado =  String(compra.anexIvaComAutComMod).trim() === '' ? '0000000000' : compra.anexIvaComAutComMod;
      
    }
    
    data.push(row);
    
    return data;
  }
  
  async pagoExterior(compra) {
    let strSentencia = '';
    let strNumeroFactura = '';
    let strEncfacproRegFiscal = 'NA';
    let rs;
    
    let strPagoLocExt = 'NA';
    let strPaisPago = 'NA';
    let strConvDobTri = 'NA';
    let strPagoExt = 'NA';
    
    let strEncfacproTiporegfiscal = '';
    let strEncfacproCodpaisreggen = '';
    let strEncfacproDenominacion = '';
    
    let dblImporte = 0;
    
    if (compra.anexIvaComIdTipComp === '04') {
      if (String(compra.anexosIvaConcepto).substring(0, 3) === 'DVC') {
        strSentencia = 'SI';
        try {
          rs = await this.generarXmlService.pagoExteriorDvc(String(compra.anexosIvaConcepto).trim());
          if (rs.length > 0) {
            strNumeroFactura = rs[0].DETDEV_NUMERO === null ? '' : rs[0].DETDEV_NUMERO;
          }
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        }
        
      } else {
        strNumeroFactura = String(compra.anexosIvaConcepto).trim();
      }
      
      if (String(strNumeroFactura).substring(0, 2) === 'FP') {
        strSentencia = 'SI';
        try {
          rs = await this.generarXmlService.pagoExteriorFP(strNumeroFactura);
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        }
        
      } else { // si es un ´´p
        strSentencia = 'SI';
        try {
          dblImporte = await this.encontrarRegistroDouble(String(compra.anexIvaComSecVtaEst).trim());
          rs = await this.generarXmlService.obtenerTrnCobro(String(compra.anexIvaComSecVtaEst).trim());
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        }
        
      }
    } else {
      if (compra.anexIvaComIdTipComp === '05' ) {
        strSentencia = 'SI';
        try {
          rs = await this.generarXmlService.obtenerTrnCobro(String(compra.anexIvaComSecVtaEst).trim());
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        }
        
      } else {
        strSentencia = 'SI';
        try {
          rs = await this.generarXmlService.obtenerFactPro(compra.anexIvaComSecVtaEst, compra.anexIvaComSerVtaEst, compra.proCodigo);
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        }
        
      }
      
    }
    
    this.strEncfacproRetserieEst = '';
    this.strEncfacproRetserieptoemi = '';
    this.strEncfacproRetsecuencial = '';
    this.strEncfacproRetautorizacion = '';
    let strEncfacproRetfecha = '';
    strEncfacproRegFiscal = '';
    strEncfacproTiporegfiscal = '';
    strEncfacproCodpaisreggen = '';
    strEncfacproDenominacion = '';
    
    if (strSentencia !== '') {
      
      if (rs.length > 0) {
        if (rs[0].ENCFACPRO_PAGOLOCALEXT === null) {
          strPagoLocExt = 'A';
        }
        
        strPagoLocExt = rs[0].ENCFACPRO_PAGOLOCALEXT === null ? 'NA' : rs[0].ENCFACPRO_PAGOLOCALEXT;
        strPaisPago = rs[0].ENCFACPRO_PAISPAGO === null ? 'NA' : rs[0].ENCFACPRO_PAISPAGO;
        strConvDobTri = rs[0].ENCFACPRO_CONVDOBLETRIB === null ? 'NA' : rs[0].ENCFACPRO_CONVDOBLETRIB;
        strPagoExt = rs[0].ENCFACPRO_PAGOEXTERIOR === null ? 'NA' : rs[0].ENCFACPRO_PAGOEXTERIOR;
        
        this.strEncfacproRetserieEst = rs[0].ENCFACPRO_RETSERIEEST === null ? '' : rs[0].ENCFACPRO_RETSERIEEST;
        this.strEncfacproRetserieptoemi = rs[0].ENCFACPRO_RETSERIEPTOEMI === null ? '' : rs[0].ENCFACPRO_RETSERIEPTOEMI;
        this.strEncfacproRetsecuencial = rs[0].ENCFACPRO_RETSECUENCIAL === null ? '' : rs[0].ENCFACPRO_RETSECUENCIAL;
        this.strEncfacproRetautorizacion = rs[0].AUTRET === null ? '' : rs[0].AUTRET;
        strEncfacproRetfecha = rs[0].ENCFACPRO_RETFECHA === null ? '' : rs[0].ENCFACPRO_RETFECHA;
        strEncfacproRegFiscal = rs[0].ENCFACPRO_PAGOREGFISCAL === null ? 'NA' : rs[0].ENCFACPRO_PAGOREGFISCAL;
        
        strEncfacproTiporegfiscal = rs[0].ENCFACPRO_TIPOREGFISCAL === null ? '' : rs[0].ENCFACPRO_TIPOREGFISCAL;
        strEncfacproCodpaisreggen = rs[0].ENCFACPRO_CODPAISREGGEN === null ? '' : rs[0].ENCFACPRO_CODPAISREGGEN;
        strEncfacproDenominacion = rs[0].ENCFACPRO_DENOMINACION === null ? '' : rs[0].ENCFACPRO_DENOMINACION;
      }
      
    }
    
    if (strPagoLocExt === 'NA') {
      if ( dblImporte !== 0) {
        if ( dblImporte >= 100) {
          strPagoLocExt = '02';
        } else {
          strPagoLocExt = '01';
        }
        
      }
    }
    
    const data = [];
    const row: object = new Object();
    
    // @ts-ignore
    row.pagoLocExt = strPagoLocExt;
    
    if (strPagoLocExt === '02')  {  // si es pago al exterior
      // @ts-ignore
      row.tipoRegi = strEncfacproTiporegfiscal;
      
      if (strEncfacproTiporegfiscal === '03') {
        // @ts-ignore
        row.denopagoRegFis = strEncfacproDenominacion;
      } else {
        // @ts-ignore
        row.paisEfecPagoGen = strEncfacproCodpaisreggen;
      }
    }
    // @ts-ignore
    row.paisEfecPago = strPaisPago;
    // @ts-ignore
    row.aplicConvDobTrib = strConvDobTri;
    // @ts-ignore
    row.pagExtSujRetNorLeg = strPagoExt;
    
    
    data.push(row);
    
    return data;
  }
  
  dateValidation(fecha): string {
    const date = this.datePipe.transform(fecha, 'dd/MM/yyyy');
    // const dia = this.completarFecha(String(fecha.getDate()));
    // const mes = this.completarFecha(String(fecha.getMonth() + 1));
    // const anio = this.completarFecha(String(fecha.getFullYear()));
    return date;
  }
  
  completarFecha(strFecha): string {
    strFecha = strFecha.toString();
    if (strFecha.length === 1) {
      return '0' + strFecha;
    } else {
      return strFecha;
    }
  }
  
  autoCompletar(strCadena, intTamanioCadena) {
    let intAux = 0;
    let indice = 0;
    let strAutoCompletar = '';
    
    intAux = String(strCadena).length;
    for (indice = 1; indice <= intTamanioCadena - intAux; indice++) {
      strAutoCompletar = '0' + strAutoCompletar;
    }
    
    strAutoCompletar = strAutoCompletar + strCadena;
    
    return strAutoCompletar;
  }
  
  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'anexgenxml',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
  
  async encontrarRegistroDouble(numeroDoc) {
    let dblResultado = 0;
    let rs;
    
    try {
      rs = await this.generarXmlService.obtenerCobroImporte(numeroDoc);
      if (rs !== null) {
        dblResultado = rs[0].TRNCOBRO_IMPORTE;
      } else {
        dblResultado = 0;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
    
    return dblResultado;
  }
  
  async verificaFormaPagoCXP(compra) {
    
    let rs;
    let intNumReg = 0;
    
    
    try {
      rs = await this.generarXmlService.verificaFormaPagoCXP(String(compra.anexosIvaConcepto).trim());
      if (rs.length > 0) {
        intNumReg = rs[0].NUM;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
    
    return intNumReg;
  }
  
  async verificaFormaPago(compra) {
    
    let rs;
    let intNumReg = 0;
    
    try {
      rs = await this.generarXmlService.verificaFormaPago(compra.anexIvaComSecVtaEst, compra.anexIvaComSerVtaEst, compra.proCodigo);
      if (rs.length > 0) {
        intNumReg = rs[0].NUM;
      }
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
    
    return intNumReg;
    
  }
  
  async verifcarExistenciaRetencionesEsp(compra) {
    let rs;
    let intNumReg = 0;
    
    
    try {
      rs = await this.generarXmlService.verificaRetencionesEsp(String(compra.anexIvaComSecVtaEst).trim(),
        String(compra.anexIvaComSerVtaEst).trim(), String(compra.proCodigo).trim());
      if (rs.length > 0) {
        intNumReg = rs[0].NUM;
      }
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
    
    return intNumReg;
    
  }
  
  async autorizacionElectronica(strPerInf, intNumeroLinea) {
    let rs;
    const parametros = [];
    
    try {
      rs = await this.generarXmlService.autorizacionElectronica(intNumeroLinea, strPerInf);
      if (rs.length > 0) {
        parametros[1] = (rs[0].ENCFACPRO_AUTORIZACION_FE === null ? '' : rs[0].ENCFACPRO_AUTORIZACION_FE);
        parametros[2] = (rs[0].NUMSECRET === null ? '' : rs[0].NUMSECRET);
      } else {
        parametros[1] = null;
        parametros[2] = null;
      }
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
    
    return parametros;
    
  }
  
  async getAir(compra) {
    const air = [];
    let code = '';
    let strCodSri = '';
    let row: object = new Object();
    
    
    if (compra.anexIvaComConcepRet1 !== null && String(compra.anexIvaComConcepRet1).trim() !== '000') {
      code = String(compra.anexIvaComConcepRet1).trim();
      let detAir: object = new Object();
      strCodSri = '';
      
      try {
        const rs = await this.generarXmlService.obtenerRetCodsri(String(compra.anexIvaComConcepRet1));
        
        if (rs !== null) {
          strCodSri = rs[0].RETENCION_CODSRI;
        }
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      }
      
      // @ts-ignore
      row.codRetAir = strCodSri;
      // @ts-ignore
      row.baseImpAir = Number(compra.anexIvaComImpRenta1).toFixed(2);
      // @ts-ignore
      row.porcentajeAir = code === '322' ? Number(compra.anexIvaComCodRet1).toFixed(2) : Number(compra.anexIvaComCodRet1).toFixed(2);
      // @ts-ignore
      row.valRetAir = Number(compra.anexIvaComMonRet1).toFixed(2);
      
      if (compra.anexosIvaFechaPagoDiv !== null) {
        // @ts-ignore
        row.fechaPagoDiv = this.dateValidation(compra.anexosIvaFechaPagoDiv);
        // @ts-ignore
        row.imRentaSoc =  Number(compra.anexosIvaImpRenPagado).toFixed(2);
        // @ts-ignore
        row.anioUtDiv =  compra.anexosIvaAnioUtil;
      }
      
      detAir = row;
      row = new Object();
      // @ts-ignore
      row.detalleAir = detAir;
      air.push(row);
    }
    
    row = {};
    if (compra.anexIvaComConcepRet2 !== null && compra.anexIvaComConcepRet2 !== '000') {
      code = String(compra.anexIvaComConcepRet2).trim();
      let detAir = new Object();
      strCodSri = '';
      
      try {
        const rs = await this.generarXmlService.obtenerRetCodsri(String(compra.anexIvaComConcepRet2));
        if (rs !== null) {
          strCodSri = rs[0].RETENCION_CODSRI;
        }
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      }
      
      // @ts-ignore
      row.codRetAir = strCodSri;
      // @ts-ignore
      row.baseImpAir = Number(compra.anexIvaComImpRenta2).toFixed(2);
      // @ts-ignore
      row.porcentajeAir = code === '322' ? Number(compra.anexIvaComCodRet2).toFixed(2) : Number(compra.anexIvaComCodRet2).toFixed(2);
      // @ts-ignore
      row.valRetAir = Number(compra.anexIvaComMonRet2).toFixed(2);
      
      if (compra.anexosIvaFechaPagoDiv !== null) {
        // @ts-ignore
        row.fechaPagoDiv = this.dateValidation(compra.anexosIvaFechaPagoDiv);
        // @ts-ignore
        row.imRentaSoc =  Number(compra.anexosIvaImpRenPagado).toFixed(2);
        // @ts-ignore
        row.anioUtDiv =  compra.anexosIvaAnioUtil;
      }
      
      detAir = row;
      row = new Object();
      // @ts-ignore
      row.detalleAir = detAir;
      air.push(row);
    }
    row = {};
    if (compra.anexIvaComConcepRet3 !== null && compra.anexIvaComConcepRet3 !== '000') {
      code = String(compra.anexIvaComConcepRet3).trim();
      let detAir = new Object();
      strCodSri = '';
      
      try {
        const rs = await this.generarXmlService.obtenerRetCodsri(String(compra.anexIvaComConcepRet3));
        if (rs !== null) {
          strCodSri = rs[0].RETENCION_CODSRI;
        }
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      }
      
      // @ts-ignore
      row.codRetAir = strCodSri;
      // @ts-ignore
      row.baseImpAir = Number(compra.anexIvaComImpRenta3).toFixed(2);
      // @ts-ignore
      row.porcentajeAir = code === '322' ? Number(compra.anexIvaComCodRet3).toFixed(2) : Number(compra.anexIvaComCodRet3).toFixed(2);
      // @ts-ignore
      row.valRetAir = Number(compra.anexIvaComMonRet3).toFixed(2);
      
      
      if (compra.anexosIvaFechaPagoDiv !== null) {
        // @ts-ignore
        row.fechaPagoDiv = this.dateValidation(compra.anexosIvaFechaPagoDiv);
        // @ts-ignore
        row.imRentaSoc =  Number(compra.anexosIvaImpRenPagado).toFixed(2);
        // @ts-ignore
        row.anioUtDiv =  compra.anexosIvaAnioUtil;
      }
      
      detAir = row;
      row = new Object();
      // @ts-ignore
      row.detalleAir = detAir;
      air.push(row);
      
    }
    row = {};
    if (compra.anexIvaComConcepRet4 !== null && compra.anexIvaComConcepRet4 !== '000') {
      code = String(compra.anexIvaComConcepRet4).trim();
      let detAir = new Object();
      strCodSri = '';
      
      try {
        const rs = await this.generarXmlService.obtenerRetCodsri(String(compra.anexIvaComConcepRet4));
        if (rs !== null) {
          strCodSri = rs[0].RETENCION_CODSRI;
        }
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      }
      
      // @ts-ignore
      row.codRetAir = strCodSri;
      // @ts-ignore
      row.baseImpAir = Number(compra.anexIvaComImpRenta4).toFixed(2);
      // @ts-ignore
      row.porcentajeAir = code === '322' ? Number(compra.anexIvaComCodRet4).toFixed(2) : Number(compra.anexIvaComCodRet4).toFixed(2);
      // @ts-ignore
      row.valRetAir = Number(compra.anexIvaComMonRet4).toFixed(2);
      
      if (compra.anexosIvaFechaPagoDiv !== null) {
        // @ts-ignore
        row.fechaPagoDiv = this.dateValidation(compra.anexosIvaFechaPagoDiv);
        // @ts-ignore
        row.imRentaSoc =  Number(compra.anexosIvaImpRenPagado).toFixed(2);
        // @ts-ignore
        row.anioUtDiv =  compra.anexosIvaAnioUtil;
      }
      
      detAir = row;
      row = new Object();
      // @ts-ignore
      row.detalleAir = detAir;
      air.push(row);
    }
    
    return air;
  }
  
  async verificaReembolsos(compra) {
    
    let rs;
    let intNumReg = 0;
    
    try {
      rs = await this.generarXmlService.verificarReembolsos(compra.anexIvaComSecVtaEst, compra.anexIvaComSerVtaEst, compra.proCodigo);
      if (rs.length > 0) {
        intNumReg = rs[0].NUM;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
    
    return intNumReg;
  }
  
  async getReembolos1(compra) {
    
    let rs;
    
    const listaReembolsos = [];
    let data = [];
    
    try {
      rs = await this.generarXmlService.obtenerReembolsos(compra.anexIvaComSecVtaEst, String(compra.anexIvaComSerVtaEst), String(compra.proCodigo));
      if (rs.length > 0) {
        for (const item of rs) {
          listaReembolsos.push({trnreeIdepro: item.TRNREE_IDEPRO, trnreeSerieest: item.TRNREE_SERIEEST,
            trnreeSerieptoemi: item.TRNREE_SERIEPTOEMI, trnreeSeccompvta: item.TRNREE_SECCOMPVTA});
        }
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
    
    
    data = [];
    
    for (const item of listaReembolsos) {
      const row: object = new Object();
      // @ts-ignore
      row.reembolso = await this.getReembolos(compra, item.trnreeIdepro, item.trnreeSerieest, item.trnreeSerieptoemi, item.trnreeSeccompvta);
      data.push(row);
    }
    
    return data;
    
  }
  
  async getReembolos(compra, strIdePro, strSerieEst, strSeriePtoVta, strSecComp) {
    
    let rs;
    let data = [];
    
    
    
    try {
      rs = await this.generarXmlService.obtenerReembolsosFp(compra.anexIvaComSecVtaEst, String(compra.anexIvaComSerVtaEst),
        String(compra.proCodigo), strIdePro, strSerieEst, strSeriePtoVta, strSecComp);
      if (rs.length > 0) {
        data = [];
        const row: object = new Object();
        
        // @ts-ignore
        row.tipoComprobanteReemb = rs[0].TRNREE_TIPCOMP === null ? '' : rs[0].TRNREE_TIPCOMP;
        // @ts-ignore
        row.tpIdProvReemb = rs[0].TRNREE_TIPOIDEPRO === null ? '' : rs[0].TRNREE_TIPOIDEPRO;
        // @ts-ignore
        row.idProvReemb = rs[0].TRNREE_IDEPRO === null ? '' : rs[0].TRNREE_IDEPRO;
        // @ts-ignore
        row.establecimientoReemb = rs[0].TRNREE_SERIEEST === null ? '' : rs[0].TRNREE_SERIEEST;
        // @ts-ignore
        row.puntoEmisionReemb = rs[0].TRNREE_SERIEPTOEMI === null ? '' : rs[0].TRNREE_SERIEPTOEMI;
        // @ts-ignore
        row.secuencialReemb = rs[0].TRNREE_SECCOMPVTA === null ? '' : rs[0].TRNREE_SECCOMPVTA;
        // @ts-ignore
        row.fechaEmisionReemb = rs[0].TRNREE_FECHAEMISION === null ? '' : rs[0].TRNREE_FECHAEMISION;
        // @ts-ignore
        row.autorizacionReemb = rs[0].TRNREE_AUTORIZACION === null ? '' : rs[0].TRNREE_AUTORIZACION;
        // @ts-ignore
        row.baseImponibleReemb = rs[0].TRNREE_BASECERO === null ? Number(0).toFixed(2) : Number(rs[0].TRNREE_BASECERO).toFixed(2);
        // @ts-ignore
        row.baseImpGravReemb = rs[0].TRNREE_BASEIVA === null ? Number(0).toFixed(2) : Number(rs[0].TRNREE_BASEIVA).toFixed(2);
        // @ts-ignore
        row.baseNoGraIvaReemb = rs[0].TRNREE_BASENOIVA === null ? Number(0).toFixed(2) : Number(rs[0].TRNREE_BASENOIVA).toFixed(2);
        // @ts-ignore
        row.baseImpExeReemb = rs[0].TRNREE_BASEIMPEXEREEMB === null ? Number(0).toFixed(2) : Number(rs[0].TRNREE_BASEIMPEXEREEMB).toFixed(2);
        // @ts-ignore
        row.montoIceRemb = rs[0].TRNREE_MONTOICE === null ? Number(0).toFixed(2) : Number(rs[0].TRNREE_MONTOICE).toFixed(2);
        // @ts-ignore
        row.montoIvaRemb = rs[0].TRNREE_MONTOIVA === null ? Number(0).toFixed(2) : Number(rs[0].TRNREE_MONTOIVA).toFixed(2);
        
        data.push(row);
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
    
    return data;
    
  }
  
  async getFormaPagoCXP(compra) {
    
    let rs;
    let strCodFormaPago = '';
    const data = [];
    
    
    try {
      rs = await this.generarXmlService.getFormaPagoCXP(String(compra.anexosIvaConcepto).trim());
      if (rs.length > 0) {
        const row: object = new Object();
        for (const item of rs) {
          strCodFormaPago = item.TRNFORMAPAGO_CODIGO === null ? '' : item.TRNFORMAPAGO_CODIGO;
          // @ts-ignore
          row.formaPago = strCodFormaPago;
          data.push(row);
        }
        
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
    
    
    return data;
    
  }
  
  async getFormaPago(compra) {
    
    let rs;
    let strCodFormaPago = '';
    const data = [];
    
    
    try {
      rs = await this.generarXmlService.getFormaPago(compra.anexIvaComSecVtaEst, compra.anexIvaComSerVtaEst, compra.proCodigo);
      if (rs.length > 0) {
        const row: object = new Object();
        for (const item of rs) {
          strCodFormaPago = item.TRNFORMAPAGO_CODIGO === null ? '' : item.TRNFORMAPAGO_CODIGO;
          // @ts-ignore
          row.formaPago = strCodFormaPago;
          data.push(row);
        }
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
    
    return data;
  }
}