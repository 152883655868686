import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {MessageService} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {DatePipe} from '@angular/common';
import {Observable} from 'rxjs';
import {Commaeprovedor} from '../cominterfaces/commaeproveedor';
import {CommaeADM} from '../cominterfaces/tablaADM';
import {CommaeEC} from '../cominterfaces/proveedorTablaEC';
import {CommaeMovimientos} from '../cominterfaces/proveedorMovimientos';
import {CommaeRetencion} from '../cominterfaces/proveedorRetencion';
import {Comtrnformapago} from '../cominterfaces/proveedorFormapago';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class CommaeproveedorService {
  
  public way: string;
  public commaeproveedor: Commaeprovedor[];
  public commaeproveedorADM: CommaeADM[];
  public commaeproveedorEC: CommaeEC[];
  public commaeproveedorSALDO: CommaeEC[];
  public commaeproveedorMov: CommaeMovimientos[];
  public commaeproveedorRt: CommaeRetencion[];
  public commaeproveedorFP: Comtrnformapago[];
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private messageService: MessageService,
              private errorService: ErroresBaseDatosService,
              private datePipe: DatePipe,
              private confIniciales: ConfInicialesService) {
   
    this.way = sessionStorage.getItem('way');
  }
  
  seleccionarRegistro(tipo, proveedor: Commaeprovedor): Observable<any> {
    
    return this.http.post<any[]>(this.way + '/compras/proveedor/zwzgce0xx4fnl2t', {      
      elementos: {
        p_tipo: tipo,
        p_com_codigo: '01',
        p_pro_codigo: proveedor.PRO_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }
  
  consultarMovimientos(proveedor): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/compras/proveedor/ollfhqz830wblp3', {      
      elementos: {
        PRO_CODIGO: proveedor.PRO_CODIGO,
      },
    }, this.confIniciales.httpOptions());
  }
  
  consultarMovimientosF(proveedor, fechadesde, fechahasta): Observable<any[]> {
 
    return this.http.post<any[]>(this.way + '/compras/proveedor/j65hn8f139ti52r', {      
      elementos: {
        PRO_CODIGO: proveedor.PRO_CODIGO,
        FECHA_DESDE: fechadesde,
        FECHA_HASTA: fechahasta
      },
    }, this.confIniciales.httpOptions());
  }
  
  ejecutarProcesoM(proveedor): Observable<any[]> {
 
    return this.http.post<any[]>(this.way + '/compras/proveedor/c5dc8x0hznk4wq7', {      
      elementos: {
        p_pro_codigo: proveedor.PRO_CODIGO,
        p_com_codigo: '01',
      },
    }, this.confIniciales.httpOptions());
  }
  
  getRetenciones(proveedor): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/compras/proveedor/eo9gg8xrxwcsg7x', {      
      elementos: {
        PRO_CODIGO: proveedor.PRO_CODIGO,
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  getProveedor(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/proveedor/rxfhsqwgby5m3ih', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  obtenerSaldo(codigo): Promise<any[]> {
  
    return this.http.post<any[]>(this.way + '/compras/proveedor/sgkjv8xctoyjh50', {      
      elementos: {
        NUM_DOC: codigo,
        COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  getSaldos(proveedor): Promise<any[]> {
  
    return this.http.post<any[]>(this.way + '/compras/proveedor/t2xfu9q1v4q7nka', {      
      elementos: {
        COM_CODIGO: '01',
        PRO_CODIGO: proveedor
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  
  ejecutarEstadocuentaP(proveedor): Promise<any[]> {
 
    return this.http.post<any[]>(this.way + '/compras/proveedor/e7qp5hy3qo56hp3', {      
      elementos: {
        p_fecha_inicial: null,
        p_fecha_final: null,
        p_com_codigo: '01',
        p_pro_codigo: proveedor,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  getEstadoCuentaP(): Promise<any[]> {
  
    return this.http.post<any[]>(this.way + '/compras/proveedor/fxbpcgmp0p5tz01', {      
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  getNombreG(grupCodigo: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/proveedor/0n9cboax6s6cunn', {      
      elementos: {
        GRU_CODIGO: grupCodigo
      },
    }, this.confIniciales.httpOptions());
  }
  
  getCen(codigo: string): Observable<any[]> {
    
    return this.http.post<any[]>(this.way + '/compras/proveedor/bgih9no3et34ze0', {      
      elementos: {
        CEN_CODIGO: codigo
      },
    }, this.confIniciales.httpOptions());
  }
  
  getCuenta(codigo: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/proveedor/ws376gk1jjrxm8h', {      
      elementos: {
        CON_CODIGO: codigo
      },
    }, this.confIniciales.httpOptions());
  }
  
  getProPagoLocaloExterior(codigo: string): Observable<any[]> {
    
    return this.http.post<any[]>(this.way + '/compras/proveedor/nwpfgmo8cp9t5v8', {      
      elementos: {
        CODIGO: codigo,
        TIPO: 'LE'
      },
    }, this.confIniciales.httpOptions());
  }
  
  getZona(zonCodigo: string): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/compras/proveedor/3q4ioq5cpsip6oa', {      
      elementos: {
        PRO_ZONA: zonCodigo
      },
    }, this.confIniciales.httpOptions());
  }
  
  seleccionarCuenta(): Observable<any[]> {
   
    return this.http.post<any[]>(this.way + '/compras/proveedor/bn2gd47qusw3g67', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  erarticulosnotasrec(strDETNOTREC_CODIGO){
    return this.http.post<any[]>(this.way + '/compras/proveedor/041na6gjizkfmmh', {      
      elementos: {
        DETNOTREC_CODIGO:strDETNOTREC_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }
  erbuscarProveedor(strPRO_CODIGO){
    return this.http.post<any[]>(this.way + '/compras/proveedor/engb7j9iqxc1wjp', {      
      elementos: {
        PRO_CODIGO:strPRO_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }
  erbodega(strauxBodCod){
    return this.http.post<any[]>(this.way + '/compras/proveedor/5gkgcljwq7mhrdvs', {      
      elementos: {
        auxBodCod:strauxBodCod
      },
    }, this.confIniciales.httpOptions());
  }
  erProvcodig(strPRO_CODIGO){
    return this.http.post<any[]>(this.way + '/compras/proveedor/466wfah9t2bfs8g', {      
      elementos: {
        PRO_CODIGO:strPRO_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }
  ervalidarIdeExiste(strrucide,strcodproveedor){
    return this.http.post<any[]>(this.way + '/compras/proveedor/ljpeogyzjqfim25', {      
      elementos: {
        rucide:strrucide,
        codproveedor:strcodproveedor
      },
    }, this.confIniciales.httpOptions());
  }
  erbusquedagrupo(strGRU_CODIGO){
    return this.http.post<any[]>(this.way + '/compras/proveedor/j3wzd01gstsdh6v', {      
      elementos: {
        GRU_CODIGO:strGRU_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }
  erZona(strPRO_ZONA){
    return this.http.post<any[]>(this.way + '/compras/proveedor/hytpnr7dzwijqri', {      
      elementos: {
        PRO_ZONA:strPRO_ZONA
      },
    }, this.confIniciales.httpOptions());
  }
  ertipopcodigo(strPRO_TIPOA){
    return this.http.post<any[]>(this.way + '/compras/proveedor/ogacob6rtf96t9k', {      
      elementos: {
        PRO_TIPOA:strPRO_TIPOA
      },
    }, this.confIniciales.httpOptions());
  }
  erconCodigo(strCON_CODIGO1){
    return this.http.post<any[]>(this.way + '/compras/proveedor/6v9o2pmqyojigne', {      
      elementos: {
        CON_CODIGO1:strCON_CODIGO1
      },
    }, this.confIniciales.httpOptions());
  }
  ercon2Codigo(strCON_CODIGO2){
    return this.http.post<any[]>(this.way + '/compras/proveedor/4sbtij53qg9mejo', {      
      elementos: {
        CON_CODIGO2:strCON_CODIGO2
      },
    }, this.confIniciales.httpOptions());
  }
  ercencodigo(strCEN_CODIGO){
    return this.http.post<any[]>(this.way + '/compras/proveedor/ujn1g7hvav38z1i', {      
      elementos: {
        CEN_CODIGO:strCEN_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }
  erpagoLocaloExterior(strPRO_PAGOLOCALEXT){
    return this.http.post<any[]>(this.way + '/compras/proveedor/ja2svrerkyw9g4s', {      
      elementos: {
        PRO_PAGOLOCALEXT:strPRO_PAGOLOCALEXT
      },
    }, this.confIniciales.httpOptions());
  }
  erPagoPais(strconpaiscod){
    return this.http.post<any[]>(this.way + '/compras/proveedor/rrlxaheob797a05', {      
      elementos: {
        conpaiscod:strconpaiscod
      },
    }, this.confIniciales.httpOptions());
  }
  erpagoParaiso(strconparaisocod){
    return this.http.post<any[]>(this.way + '/compras/proveedor/o1m12m3jf77a4ju', {      
      elementos: {
        conparaisocod:strconparaisocod
      },
    }, this.confIniciales.httpOptions());
  }
  erpagoEfectuadoPais(strconepaiscod){
    return this.http.post<any[]>(this.way + '/compras/proveedor/8zn7r7zjgyw73oe', {      
      elementos: {
        conepaiscod :strconepaiscod 
      },
    }, this.confIniciales.httpOptions());
  }
  erProveedorcod(strPRO_RUCIDE,strcodproveedor){
    return this.http.post<any[]>(this.way + '/compras/proveedor/0d31g5265oddr1j', {      
      elementos: {
        PRO_RUCIDE :strPRO_RUCIDE,
        codproveedor:strcodproveedor 
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  guardarProveedor(proveedor: Commaeprovedor, fecha): Observable<any> {

    return this.http.post<any[]>(this.way + '/compras/proveedor/o33okudzpsg1hdv', {      
      elementos: {
        p_PRO_CODIGO_1: proveedor.PRO_CODIGO,
        p_GRU_CODIGO_2: proveedor.GRU_CODIGO,
        p_PRO_NOMBREC_4: proveedor.PRO_NOMBREC,
        p_PRO_NOMBRE_5: proveedor.PRO_NOMBRE,
        p_PRO_RUCIDE_6: proveedor.PRO_RUCIDE,
        p_PRO_DIRECCION1_7: proveedor.PRO_DIRECCION1,
        p_PRO_DIRECCION2_8: proveedor.PRO_DIRECCION2,
        p_PRO_CODPOSTAL_9: proveedor.PRO_CODPOSTAL,
        p_PRO_TELEFONO1_10: proveedor.PRO_TELEFONO1,
        p_PRO_TELEFONO2_11: proveedor.PRO_TELEFONO2,
        p_PRO_FAX_12: proveedor.PRO_FAX,
        p_PRO_CORREO_13: proveedor.PRO_CORREO,
        p_PRO_CONTACTO_14: proveedor.PRO_CONTACTO,
        p_PRO_FECING_15: fecha,
        p_PRO_LIMCREDIT_16: proveedor.PRO_LIMCREDIT,
        p_PRO_DIACREDIT_17: proveedor.PRO_DIACREDIT,
        p_PRO_DESCUENTO_18: proveedor.PRO_DESCUENTO,
        p_PRO_IVA_19: proveedor.PRO_IVA,
        p_PRO_CONTRIBUYENTE_20: proveedor.PRO_CONTRIBUYENTE,
        p_PRO_LISTA_21: proveedor.PRO_LISTA,
        P_CON_CODIGO1_22: proveedor.CON_CODIGO1,
        p_CON_CODIGO2_23: proveedor.CON_CODIGO2,
        p_PRO_ZONA_24: proveedor.PRO_ZONA,
        p_PRO_OBSERVACION_25: proveedor.PRO_OBSERVACION,
        p_PRO_NUMSERIE_26: proveedor.PRO_NUMSERIE,
        p_PRO_NUMAUTORIZACION_27: proveedor.PRO_NUMAUTORIZACION,
        p_PRO_TIPOIDE: proveedor.PRO_TIPOIDE,
        p_CEN_CODIGO: proveedor.CEN_CODIGO,
        p_COM_CODIGO: '01',
        p_PRO_REGCOND: proveedor.PRO_REGCOND,
        p_PRO_tipoa: proveedor.PRO_TIPOA,
        p_PRO_parterel: proveedor.PRO_PARTEREL,
        P_con_codigo_ret332: proveedor.CON_CODIGO_RET332,
        p_PRO_correoElect2: proveedor.PRO_CORREO2,
        p_PRO_facturaElect: proveedor.PRO_FACTURAELECT,
        p_pro_tipoden: proveedor.PRO_TIPODEN,
        p_pro_transporte: proveedor.PRO_TRANSPORTE,
        P_PRO_PAGOLOCALEXT: proveedor.PRO_PAGOLOCALEXT,
        P_PRO_tiporegfiscal: proveedor.PRO_TIPOREGFISCAL,
        P_PRO_codpaisreggen: proveedor.PRO_CODPAISREGGEN,
        P_PRO_codpaisparfis: proveedor.PRO_CODPAISPARFIS,
        P_PRO_denominacion: proveedor.PRO_DENOMINACION,
        P_PRO_PAISPAGO: proveedor.PRO_PAISPAGO,
        P_PRO_convdobletrib: proveedor.PRO_CONVDOBLETRIB,
        P_PRO_pagoexterior: proveedor.PRO_PAGOEXTERIOR,
        P_PRO_pagoregfiscal: proveedor.PRO_PAGOREGFISCAL,
        P_PRO_conregmic: proveedor.PRO_CONREGMIC,
        P_PRO_agenteret: proveedor.PRO_AGENTERET,
        P_PRO_conregRimpe: proveedor.PRO_CONREGRIMPE
      },
    }, this.confIniciales.httpOptions());
  }
  
  listarProveedorAdm(proveedor: Commaeprovedor): Observable<any> {
    
    return this.http.post<any[]>(this.way + '/compras/proveedor/x2oxbdicc2ki03d', {      
      elementos: {
        TIPO: 'TC',
        CODIGO: proveedor.PRO_CODIGO,
        COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
    
  }
  
  getcodsridescripcion(codigo): Observable<any[]> {
   
    return this.http.post<any[]>(this.way + '/compras/proveedor/arrsi6j2j2k0q2t', {      
      elementos: {
        p_condicion: codigo
      },
    }, this.confIniciales.httpOptions());
    
  }
  
  eliminarMaeautsri(proveedorADM) {
   
    return this.http.post<any[]>(this.way + '/compras/proveedor/flbrmc0d8ru79so', {      
      elementos: {
        p_PRO_CODIGO_1: proveedorADM.PRO_CODIGO,
        p_COM_codigo: '01',
        p_autsri_codigo: proveedorADM.AUTSRI_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }
  
  actualizarmaeautsri(proveedorADM, codigo): Observable<any> {
    /* proveedorADM.AUTSRI_FECHAINI = this.datePipe.transform(proveedorADM.AUTSRI_FECHAINI.toLocaleString()
      .substr(0, 10), 'dd/MM/yyyy'); */
    // proveedorADM.AUTSRI_FECHAFIN = this.datePipe.transform(proveedorADM.AUTSRI_FECHAFIN.toLocaleString()
    // .substr(0, 10), 'dd/MM/yyyy');
   
    return this.http.post<any[]>(this.way + '/compras/proveedor/y1eevl5hhkmvfg7', {      
      elementos: {
        p_AUTSRI_CODIGO: proveedorADM.AUTSRI_CODIGO,
        p_PRO_CODIGO: proveedorADM.PRO_CODIGO,
        p_AUTSRI_TIPODOC: codigo,
        p_AUTSRI_FECHAINI: proveedorADM.AUTSRI_FECHAINI,
        p_AUTSRI_FECHAFIN: proveedorADM.AUTSRI_FECHAFIN,
        p_AUTSRI_SERIEESTAB: proveedorADM.AUTSRI_SERIEESTAB,
        p_AUTSRI_SERIEPTOEMI: proveedorADM.AUTSRI_SERIEPTOEMI,
        p_AUTSRI_SECUENCIALINI: proveedorADM.AUTSRI_SECUENCIALINI,
        p_AUTSRI_SECUENCIALFIN: proveedorADM.AUTSRI_SECUENCIALFIN,
        p_AUTSRI_AUTORIZASRI: proveedorADM.AUTSRI_AUTORIZASRI,
        p_AUTSRI_AUTORIZAIMP: proveedorADM.AUTSRI_AUTORIZAIMP,
        p_COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  consultarCash(codigo): Observable<any[]> {
   
    return this.http.post<any[]>(this.way + '/compras/proveedor/bfni5w3rgoij7o6', {      
      elementos: {
        REF: codigo,
        COM: '01',
      },
    }, this.confIniciales.httpOptions());
  }
  
  insertarmaeautsri(proveedorADM, codigo): Observable<any> {
    console.log('estoy en insertar');
    console.log(proveedorADM.AUTSRI_FECHAINI, proveedorADM.AUTSRI_FECHAFIN);
    /* proveedorADM.AUTSRI_FECHAINI = this.datePipe.transform(proveedorADM.AUTSRI_FECHAINI.toLocaleString()
      .substr(0, 10), 'dd/MM/yyyy');
    proveedorADM.AUTSRI_FECHAFIN = this.datePipe.transform(proveedorADM.AUTSRI_FECHAFIN.toLocaleString()
      .substr(0, 10), 'dd/MM/yyyy'); */
    
    return this.http.post<any[]>(this.way + '/compras/proveedor/aq3nnevqyjcd3iu', {      
      elementos: {
        p_PRO_CODIGO: proveedorADM.PRO_CODIGO,
        p_AUTSRI_TIPODOC: codigo,
        p_AUTSRI_FECHAINI: proveedorADM.AUTSRI_FECHAINI,
        p_AUTSRI_FECHAFIN: proveedorADM.AUTSRI_FECHAFIN,
        p_AUTSRI_SERIEESTAB: proveedorADM.AUTSRI_SERIEESTAB,
        p_AUTSRI_SERIEPTOEMI: proveedorADM.AUTSRI_SERIEPTOEMI,
        p_AUTSRI_SECUENCIALINI: proveedorADM.AUTSRI_SECUENCIALINI,
        p_AUTSRI_SECUENCIALFIN: proveedorADM.AUTSRI_SECUENCIALFIN,
        p_AUTSRI_AUTORIZASRI: proveedorADM.AUTSRI_AUTORIZASRI,
        p_AUTSRI_AUTORIZAIMP: proveedorADM.AUTSRI_AUTORIZAIMP,
        p_COM_CODIGO: '01'
        
      },
    }, this.confIniciales.httpOptions());
  }
  
  listaCodsri(): Observable<any> {
   
    return this.http.post<any[]>(this.way + '/compras/proveedor/lew138gi1qjtk1j', {      
      elementos: {
        TIPO: 'TC'
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  actualizarProveedor(proveedor: Commaeprovedor, fecha): Observable<any> {
    
    return this.http.post<any[]>(this.way + '/compras/proveedor/75yijjq9qym9jhd', {      
      elementos: {
        p_PRO_CODIGO_1: proveedor.PRO_CODIGO,
        p_GRU_CODIGO_2: proveedor.PRO_CODIGO,
        p_GRU_codigo_3: proveedor.GRU_CODIGO,
        p_PRO_NOMBREC_5: proveedor.PRO_NOMBREC,
        p_PRO_NOMBRE_6: proveedor.PRO_NOMBRE,
        p_PRO_RUCIDE_7: proveedor.PRO_RUCIDE,
        p_PRO_DIRECCION1_8: proveedor.PRO_DIRECCION1,
        p_PRO_DIRECCION2_9: proveedor.PRO_DIRECCION2,
        p_PRO_CODPOSTAL_10: proveedor.PRO_CODPOSTAL,
        p_PRO_TELEFONO1_11: proveedor.PRO_TELEFONO1,
        p_PRO_TELEFONO2_12: proveedor.PRO_TELEFONO2,
        p_PRO_FAX_13: proveedor.PRO_FAX,
        p_PRO_CORREO_14: proveedor.PRO_CORREO,
        p_PRO_CONTACTO_15: proveedor.PRO_CONTACTO,
        p_PRO_FECING_16: fecha,
        p_PRO_LIMCREDIT_17: proveedor.PRO_LIMCREDIT,
        p_PRO_DIACREDIT_18: proveedor.PRO_DIACREDIT,
        p_PRO_DESCUENTO_19: proveedor.PRO_DESCUENTO,
        p_PRO_IVA_20: proveedor.PRO_IVA,
        p_PRO_CONTRIBUYENTE_21: proveedor.PRO_CONTRIBUYENTE,
        p_PRO_LISTA_22: proveedor.PRO_LISTA,
        P_CON_CODIGO1_23: proveedor.CON_CODIGO1,
        p_CON_CODIGO2_24: proveedor.CON_CODIGO2,
        p_PRO_ZONA_25: proveedor.PRO_ZONA,
        p_PRO_OBSERVACION_26: proveedor.PRO_OBSERVACION,
        p_PRO_NUMSERIE_27: proveedor.PRO_NUMSERIE,
        p_PRO_NUMAUTORIZACION_28: proveedor.PRO_NUMAUTORIZACION,
        p_PRO_TIPOIDE: proveedor.PRO_TIPOIDE,
        p_CEN_CODIGO: proveedor.CEN_CODIGO,
        p_COM_CODIGO: proveedor.COM_CODIGO,
        p_PRO_REGCOND: proveedor.PRO_REGCOND,
        p_PRO_tipoa: proveedor.PRO_TIPOA,
        p_PRO_parterel: proveedor.PRO_PARTEREL,
        P_con_codigo_ret332: proveedor.CON_CODIGO_RET332,
        p_PRO_correoElect2: proveedor.PRO_CORREO2,
        p_PRO_facturaElect: proveedor.PRO_FACTURAELECT,
        p_pro_tipoden: proveedor.PRO_TIPODEN,
        p_pro_transporte: proveedor.PRO_TRANSPORTE,
        P_PRO_PAGOLOCALEXT: proveedor.PRO_PAGOLOCALEXT,
        P_PRO_tiporegfiscal: proveedor.PRO_TIPOREGFISCAL,
        P_PRO_codpaisreggen: proveedor.PRO_CODPAISREGGEN,
        P_PRO_codpaisparfis: proveedor.PRO_CODPAISPARFIS,
        P_PRO_denominacion: proveedor.PRO_DENOMINACION,
        P_PRO_PAISPAGO: proveedor.PRO_PAISPAGO,
        P_PRO_convdobletrib: proveedor.PRO_CONVDOBLETRIB,
        P_PRO_pagoexterior: proveedor.PRO_PAGOEXTERIOR,
        P_PRO_pagoregfiscal: proveedor.PRO_PAGOREGFISCAL,
        P_PRO_conregmic: proveedor.PRO_CONREGMIC,
        P_PRO_agenteret: proveedor.PRO_AGENTERET,
        P_PRO_conregRimpe: proveedor.PRO_CONREGRIMPE
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  guardarDatosCashManagement(proveedor: Commaeprovedor): Observable<any> {
    
    return this.http.post<any[]>(this.way + '/compras/proveedor/871v3m8hbchuyf2', {      
      elementos: {
        p_pro_codigo: proveedor.PRO_CODIGO,
        p_com_bancodigocm: proveedor.COM_BANCODIGOCM,
        p_com_tipoctacm: proveedor.COM_TIPOCTACM,
        p_com_numctacm: proveedor.COM_NUMCTACM,
        p_com_forpagcm: proveedor.COM_FORPAGCM,
        p_com_benfcm: proveedor.COM_BENFCM,
        p_com_difbenfcm: proveedor.COM_DIFBENFCM,
        p_com_codigo: '01',
        p_com_telfbenfcm: proveedor.COM_TELFBENFCM,
        p_pro_tipoidentifcm: proveedor.PRO_TIPOIDENTIFCM,
        p_pro_idcm: proveedor.PRO_IDCM,
        p_pro_ciudadcm: proveedor.PRO_CIUDADCM,
        p_pro_locpagcm: proveedor.PRO_LOCPAGCM,
        p_pro_emailcm: proveedor.PRO_EMAILCM
      },
    }, this.confIniciales.httpOptions());
  }
  
  eliminarProveedor(proveedor: Commaeprovedor): Observable<any> {
  
    return this.http.post<any[]>(this.way + '/compras/proveedor/vww7tm0acg1lfop', {      
      elementos: {
        p_PRO_CODIGO_1: proveedor.PRO_CODIGO,
        p_COM_codigo: '01',
      },
    }, this.confIniciales.httpOptions());
  }
  
  /// Retenciones
  
  eliminarRetencion(retencion, codigo): Observable<any> {
 
    return this.http.post<any[]>(this.way + '/compras/proveedor/rh4fu10f71gip9c', {      
      elementos: {
        p_PRO_CODIGO: codigo,
        p_COM_codigo: '01',
        p_retencion_codigo: retencion.RETENCION_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }
  
  insertarRetencion(retencion, codigo): Observable<any> {
 
    return this.http.post<any[]>(this.way + '/compras/proveedor/mr6suklelsr6uza', {      
      elementos: {
        p_pro_codigo: codigo,
        p_com_codigo: '01',
        p_retencion_codigo: retencion.RETENCION_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }
  
  // anexos forma pago
  consultarFormas(codigo): Observable<any[]> {
   
    return this.http.post<any[]>(this.way + '/compras/proveedor/pst3zylryfk93qk', {      
      elementos: {
        PRO_CODIGO: codigo,
      },
    }, this.confIniciales.httpOptions());
  }
  
  // anexos forma pago
  
  insertarFormapago(forma, codigo): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/compras/proveedor/wq3nx1vghhh4o8g', {      
      elementos: {
        p_pro_codigo: codigo,
        p_com_codigo: '01',
        p_trnformapago_codigo: forma.TRNFORMAPAGO_CODIGO,
        p_trnformapago_descripcion: forma.TRNFORMAPAGO_DESCRIPCION
      },
    }, this.confIniciales.httpOptions());
  }
  
  eliminarFormapago(forma, codigo): Observable<any[]> {
    
  
    return this.http.post<any[]>(this.way + '/compras/proveedor/qizzk99q4i7sbal', {      
      elementos: {
        p_pro_codigo: codigo,
        p_com_codigo: '01',
        p_trnformapago_codigo: forma.TRNFORMAPAGO_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }
  
  async llenarEstadoCuenta(procodigo) {
    let auxContEC = 0;
    let debeTotal = 0;
    let haberTotal = 0;
    const listaEstCta = [];
    
    await this.ejecutarEstadocuentaP(procodigo);
    const respuesta = await this.getEstadoCuentaP();
    
    respuesta.map((detalle) => {
      detalle.TRNCOBRO_FECHATRN = this.datePipe.transform(detalle.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
      detalle.TRNCOBRO_TIPODOC = detalle.TRNCOBRO_TIPDOC;
      if (detalle.TRNCOBRO_IMPORTE > 0) {
        detalle.DEBE = 0;
        detalle.HABER = detalle.TRNCOBRO_IMPORTE;
      } else {
        detalle.DEBE = detalle.TRNCOBRO_IMPORTE * (-1);
        detalle.HABER = 0;
      }
      detalle.DEBE = detalle.DEBE.toFixed(this.confIniciales.getNumDecSist());
      detalle.HABER = detalle.HABER.toFixed(this.confIniciales.getNumDecSist());
      debeTotal = debeTotal + Number(detalle.HABER);
      haberTotal = haberTotal + Number(detalle.DEBE);
      auxContEC = auxContEC + Number(detalle.HABER) - Number(detalle.DEBE);
      detalle.SALDO = Number(auxContEC).toFixed(2);
      
      if (detalle.TRNCOBRO_IMPORTE !== 0) {
        listaEstCta.push(detalle);
      }
    });
    
    const nuevo = {
      TRNCOBRO_FECHATRN: '',
      TRNCOBRO_TIPDOC: '',
      TRNCOBRO_NROCOMPROBANTE: '',
      TRNCOBRO_REFERENCIA: '',
      TRNCOBRO_FORMAPAGO: '',
      TRNCOBRO_FECHAVENCE: '',
      TRNCOBRO_CONCEPTO: '',
      TRNCOBRO_IMPORTE: '',
      TRNCOBRO_TIPO: '',
      HABER: '',
      DEBE: '',
      SALDO: ''
    };
    
    nuevo.DEBE = String(haberTotal.toFixed(2));
    nuevo.HABER = String(debeTotal.toFixed(2));
    nuevo.SALDO = String(auxContEC.toFixed(2));
    listaEstCta.push(nuevo);
    
    return listaEstCta;
  }
  
  async llenarTablaSaldos(procodigo) {
    let totalimporte = 0;
    let totalpagado = 0;
    const listaSaldos = [];
    const res = await this.getSaldos(procodigo);
    if (res.length !== 0) {
      for (const aux of res) {
        aux.TRNCOBRO_FECHATRN = this.datePipe.transform(aux.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
        aux.TRNCOBRO_FECHAVENCE = this.datePipe.transform(aux.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
        /*const cont = await this.obtenerSaldo(aux.TRNCOBRO_NRODOC);
        if (cont[0].CODIGO === 0) {
        } else {
          aux.SALDO = cont[0].CODIGO.toFixed(2);
          aux.PAGADO = Number(aux.TRNCOBRO_IMPORTE) - Number(cont[0].CODIGO);
          totalimporte = totalimporte + Number(aux.TRNCOBRO_IMPORTE);
          totalpagado = totalpagado + cont[0].CODIGO;
          aux.TRNCOBRO_IMPORTE = Number(aux.TRNCOBRO_IMPORTE).toFixed(2);
          aux.PAGADO = Number(aux.PAGADO).toFixed(2);
          if (Number(aux.SALDO) !== 0) {
            listaSaldos.push(aux);
          }
        }*/
        if (Number(aux.SALDO) !== 0) {
          // aux.PAGADO = Number(aux.TRNCOBRO_IMPORTE) - Number(aux.SALDO);
          // totalimporte = totalimporte + Number(aux.TRNCOBRO_IMPORTE);
          // totalpagado = totalpagado + Number(aux.SALDO);
          // aux.TRNCOBRO_IMPORTE = Number(aux.TRNCOBRO_IMPORTE).toFixed(2);
          // aux.PAGADO = Number(aux.PAGADO).toFixed(2);
          // aux.SALDO = Number(aux.SALDO).toFixed(2);
          // listaSaldos.push(aux);
          //aux.PAGADO = Number(aux.TRNCOBRO_IMPORTE) - Number(aux.SALDO);
          //CDPJ
          if (Number(aux.SALDO) < 0) {
            aux.PAGADO = Number(aux.TRNCOBRO_IMPORTE) - (Number(aux.SALDO) * -1);
          } else {
            aux.PAGADO = Number(aux.TRNCOBRO_IMPORTE) - Number(aux.SALDO);
          }
          //CDPJ
          totalimporte = totalimporte + Number(aux.TRNCOBRO_IMPORTE);
          totalpagado = totalpagado + Number(aux.SALDO);
          aux.TRNCOBRO_IMPORTE = Number(aux.TRNCOBRO_IMPORTE).toFixed(2);
          aux.PAGADO = Number(aux.PAGADO).toFixed(2);
          aux.SALDO = Number(aux.SALDO).toFixed(2);
          listaSaldos.push(aux);
        }
      }
      
      const nuevo = {
        TRNCOBRO_NRODOC: '',
        COM_CODIGO: '',
        PRO_CODIGO: '',
        TRNCOBRO_TIPODOC: '',
        TRNCOBRO_FECHATRN: '',
        TRNCOBRO_NROCOMPROBANTE: '',
        TRNCOBRO_CONCEPTO: '',
        TRNCOBRO_IMPORTE: '',
        TRNCOBRO_FECHAVENCE: '',
        APLORG_CODIGO: '',
        TRNCOBRO_REFERENCIAEXTERNA: '',
        VEN_CODIGO: '',
        ASI_NRO: '',
        TIPDIA_CODIGO: '',
        TRNCOBRO_FECHA_ACT: '',
        TRNCOBRO_USUARIO_ACT: '',
        TRNBAN_BENEFICIARIO: '',
        TRNCOBRO_FECHARECEPCION: '',
        TRNCOBRO_FECCADATRZ: '',
        TRNCOBRO_IMPRTATRZ: '',
        CEN_CODIGO: '',
        TRNCOBRO_REFFC: '',
        TRNCOBRO_FLAG: '',
        CON_CODIGO: '',
        TRNCOBRO_CUADRE: '',
        TRNCOBRO_ESTADO: '',
        TRNCOBRO_SALDOINICIAL: '',
        TRNCOBRO_SERIE: '',
        TRNCOBRO_IMPRESO: '',
        ASI_NROCHPOSFH: '',
        ENCREQ_NUMERO: '',
        DETREQ_LINEA: '',
        TRNCOBRO_PAGOELECTRONICO: '',
        TRNCOBRO_CODFORPAG: '',
        TRNCOBRO_PAGOLOCALEXT: '',
        TRNCOBRO_PAISPAGO: '',
        TRNCOBRO_CONVDOBLETRIB: '',
        TRNCOBRO_PAGOEXTERIOR: '',
        TRNCOBRO_RETSERIEEST: '',
        TRNCOBRO_RETSERIEPTOEMI: '',
        TRNCOBRO_RETSECUENCIAL: '',
        TRNCOBRO_RETAUTORIZACION: '',
        TRNCOBRO_PAGOREGFISCAL: '',
        TRNCOBRO_RETFECHA: '',
        TRNCOBRO_TIPOREGFISCAL: '',
        TRNCOBRO_CODPAISREGGEN: '',
        TRNCOBRO_CODPAISPARFIS: '',
        TRNCOBRO_DENOMINACION: '',
        SALDO: '',
        PAGADO: ''
      };
      nuevo.TRNCOBRO_IMPORTE = String(totalimporte.toFixed(2));
      nuevo.SALDO = String(Number(totalpagado).toFixed(2));
      nuevo.PAGADO = String(Number(Number(totalimporte.toFixed(2)) -
        totalpagado).toFixed(2));
      listaSaldos.push(nuevo);
      
      return listaSaldos;
    }
  }

  actualizarValidacion(valida, procodigo): Observable<any> {
    return this.http.post<any>(this.way + '/compras/proveedor/299df2ab256fea0000000000', {
      elementos: {      
        valida, procodigo
      }
    }, this.confIniciales.httpOptions());
  }
//CDPJ
EncontrarCnta(concodigo) {
  return this.http.post<any[]>(this.way + '/inventario/articulo/ebb317eed5b66000', {
    elementos: {
      con_codigo: concodigo
    }
  }, this.confIniciales.httpOptions()).toPromise();
}
//CDPJ
}
