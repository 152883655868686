import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class TecmaeetiquetaService {

  public way: string;

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }

  getetiquetas() {
    return this.http.post(this.way + '/serviciotecnico/etiquetas/83baeecb5e77580', {
      elementos: {
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontraretiqueta(codigo) {
    return this.http.post(this.way + '/serviciotecnico/etiquetas/ebb317eed5b7780000000', {
      elementos: {
        codigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertaretiqueta(item) {
    return this.http.post(this.way + '/serviciotecnico/etiquetas/95f8edf55b7768000000', {
      elementos: {
        p_eti_codigo: item.ETI_CODIGO,
        p_eti_nombre: item.ETI_NOMBRE
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizaretiqueta(item) {
    return this.http.post(this.way + '/serviciotecnico/etiquetas/a677caac95b7780000000', {
      elementos: {
        p_eti_codigo: item.ETI_CODIGO,
        p_eti_nombre: item.ETI_NOMBRE
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminaretiqueta(item) {
    return this.http.post(this.way + '/serviciotecnico/etiquetas/7565695d5b7764000000', {
      elementos: {
        p_eti_codigo: item.ETI_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }


}
