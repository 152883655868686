<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="actprefp"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="actprefpconf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<app-invmaebarra (signal)="manejarSenales($event)" [barraBotonesCRUD]="true" [barraBotonesDesplaz]="true"
				 [barraBotones3]="true" [botonVisto]="false" [barraBotonesAsiCon]="true" [barraBotones3Alt]="false"
				 [barraBotones5]="true"></app-invmaebarra>
<div class="p-grid" style="margin-top: -5px; margin-bottom: -5px; width: 99%;">
	<!--LINEA 1-->
	<div class="p-col-7">
	</div>
	<div class="p-col-2">
		<label for="ENCFACPRO_NUMERO"></label>
		<input class="frm" id="ENCFACPRO_NUMERO" type="text" pInputText
			   style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
			   [disabled]="true" [(ngModel)]="strNumFact" autocomplete="off">
	</div>
</div>
<div class="divgrups">
	<div class="p-grid">
		<div class="p-col-12" style="margin-top: -10px">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="listdetalle"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsDet"
							[defaultColDef]="defaultColDefDet"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)="selecciondetalle($event)"
							(click)="clickclick()"
							align="left"
							style="justify-items: end"
			></app-aggridsaci>
		</div>
		<div class="p-col-12">
			<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
				<div class="p-col-1">
					<span>Artículo:</span>
				</div>
				<div class="p-col-2">
					<input class="frm" id="DETFACPRO_CODIGO" type="text" pInputText
						   style="background-color: #EFFFF7; text-align: left; font-weight: bold"
						   [disabled]="true" [(ngModel)]="detalleSeleccionado.DETFACPRO_CODIGO" autocomplete="off">
				</div>
				<div class="p-col-3">
					<input class="frm" id="DETFACPRO_DESCRIPCION" type="text" pInputText
						   style="background-color: #EFFFF7; text-align: left; font-weight: bold"
						   [disabled]="true" [(ngModel)]="detalleSeleccionado.DETFACPRO_DESCRIPCION" autocomplete="off">
				</div>
				<div class="p-col-2">
					<span>Código Alterno:</span>
				</div>
				<div class="p-col-2">
					<input class="frm" id="DETFACPRO_CODIGOALT" type="text" pInputText
						   style="background-color: #EFFFF7; text-align: left; font-weight: bold"
						   [(ngModel)]="detalleSeleccionado.DETFACPRO_CODIGOALT" autocomplete="off">
				</div>
				<div class="p-col-2">
					<p-checkbox id="chkgasto"
								name="chkgasto" value="" label="" binary="{{chkseleccionartodo}}"
								[(ngModel)]="chkseleccionartodo" (onChange)="seleccionarTodo()"></p-checkbox>
					&nbsp;
					<span>Seleccionar Todo</span>
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
				<div class="p-col-8">
				</div>
				<div class="p-col-3">
					<button class="sincss" pButton (click)="actualizaDatosArticulos()">Actualizar Código Alterno
					</button>
				</div>
			</div>
		</div>
		<div class="p-col-12" style="margin-top: -10px; margin-bottom: -7px">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="actpreService.listdetalleprecios"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsDetPrecios"
							[defaultColDef]="defaultColDefDetPrecios"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)="selecciondetalleprecio($event)"
							(click)="selecciondetalleprecio($event)"
							align="left"
							style="justify-items: end"
			></app-aggridsaci>
		</div>
	</div>
</div>
