import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Observable} from 'rxjs';
import {Eletrndocumento} from '../veninterfaces/eletrndocumento';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class EletrndocumentoService {
  public url: string;
  public way: string;
  constructor(private http: HttpClient,
    private confIniciales: ConfInicialesService,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }
  
  getDocumentosElectronicos(opcion, fechaDesde, fechaHasta): Observable<any[]> {
   
    return this.http.post<any[]>(this.way + '/ventas/documelectronicos/sxhue4tk8wekq8t', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  getEleDoc( opcion, compania, fechaDesde, fechaHasta, codigo, estado): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/documelectronicos/qjnidbpzj3l320u', {      
      elementos: {
        p_opcion: opcion,
        p_compania: compania,
        p_fechaInicial: fechaDesde,
        p_fechaFinal: fechaHasta,
        p_codigoPersona: codigo,
        p_estadoDocumento: estado,
      },
    }, this.confIniciales.httpOptions());
  }
  
  reenviarDoc(reenviar: Eletrndocumento): Observable<any[]> {
 
    return this.http.post<any[]>(this.way + '/ventas/documelectronicos/4yiwp81vn0bvtg4', {      
      elementos: {
        p_com_codigo: reenviar.COM_CODIGO,
        p_trndoc_numdoc: reenviar.TRNDOC_NUMDOC,
      },
    }, this.confIniciales.httpOptions());
  }
  
  autorizarDoc(autorizar: Eletrndocumento): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/documelectronicos/b6f7xqk4ru0e01l', {      
      elementos: {
        p_com_codigo: autorizar.COM_CODIGO,
        p_trndoc_numdoc: autorizar.TRNDOC_NUMDOC,
      },
    }, this.confIniciales.httpOptions());
  }
  
  refirmarDoc(refirmar: Eletrndocumento): Observable<any[]> {
   
    return this.http.post<any[]>(this.way + '/ventas/documelectronicos/n2u75lsfobb1x00', {      
      elementos: {
        p_com_codigo: refirmar.COM_CODIGO,
        p_trndoc_numdoc: refirmar.TRNDOC_NUMDOC,
      },
    }, this.confIniciales.httpOptions());
  }
  
}
