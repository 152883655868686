import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {Observable} from 'rxjs';
import {NomMaedepartamento} from '../nominterfaces/nommaedepartamento';
import { MessageService } from 'primeng';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';

@Injectable({
  providedIn: 'root'
})
export class NommaedepartamentoService {
  
  public way: string;

  private _nommaedepartamento: NomMaedepartamento[];
  private _btnGuardar:boolean ;
  private _btnNuevo:boolean ;
  private _btnBorrar:boolean ;
  private _btnRegresar:boolean ;
  
  
  get nommaedepartamento(): NomMaedepartamento[] {
    return this._nommaedepartamento;
  }

  get btnNuevo() {
    return this._btnNuevo;
  } 
  get btnGuardar() {
    return this._btnGuardar;
  } 
  get btnBorrar() {
    return this._btnBorrar;
  } 
  get btnRegresar() {
    return this._btnRegresar;
  } 
  
  set nommaedepartamento(value: NomMaedepartamento[]) {
    this._nommaedepartamento = value;
  }

  set btnNuevo(value: boolean) {
    this._btnNuevo = value;
  } 
  set btnGuardar(value: boolean) {
    this._btnGuardar  = value;
  } 
  set btnBorrar(value: boolean) {
    this._btnBorrar = value;
  } 
  set btnRegresar(value: boolean) {
    this._btnRegresar = value;
  } 
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, 
              private confIniciales: ConfInicialesService,
              private messageService: MessageService,
              private errorService: ErroresBaseDatosService) {
    this.way = sessionStorage.getItem('way');
  }
  
  cargaDatos() {
    let strSentencia = 'select * from nom_maedepartamento ';
  
    return this.http.post<any[]>(this.way + '/nom/departamento/c2fad5771c6e780', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  insertar( nommaedepartamento: NomMaedepartamento ) {
    //insert into table (value1, value2) values( exp1, exp2)
    
    let strSentencia = 'insert into nom_maedepartamento ( dep_codigo, dep_nombre )' +
      'VALUES (' +
      '\'' + nommaedepartamento.DEP_CODIGO + '\',' +
      '\'' + nommaedepartamento.DEP_NOMBRE + '\')';
    
  
    return this.http.post<any[]>(this.way + '/nom/departamento/95f8edf5aecab80', {
        elementos: {
          DEP_CODIGO: nommaedepartamento.DEP_CODIGO,
          DEP_NOMBRE: nommaedepartamento.DEP_NOMBRE
        }
      }, this.confIniciales.httpOptions()
    ).toPromise().then( async () => {
      
      this.menssajeAlerta('success', 'Información', ' ¡Departamento creado exitosamente!');

      await  this.cargaDatos().then(  res => {
        
        this.nommaedepartamento = res;
        this.initBotones();
      });
    })
      .catch(e => { 
        console.log(e);
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.menssajeAlerta('error', 'Error', mensaje);
       } );
  }
  
  actualizar( nommaedepartamento: NomMaedepartamento ) {
    let strSentencia = 'update nom_maedepartamento  ' +
      'set dep_codigo = \'' + nommaedepartamento.DEP_CODIGO + '\',' +
      ' dep_nombre = \'' + nommaedepartamento.DEP_NOMBRE + '\' ' +
      'where dep_codigo = \'' + nommaedepartamento.old_DEP_CODIGO + '\'';
      
    return this.http.post<any[]>(this.way + '/nom/departamento/14ceb5d956faab0000', {
        elementos: {
          DEP_CODIGO: nommaedepartamento.DEP_CODIGO,
          DEP_NOMBRE: nommaedepartamento.DEP_NOMBRE,
          old_DEP_CODIGO: nommaedepartamento.old_DEP_CODIGO
        }
      }, this.confIniciales.httpOptions()
    ).toPromise().then( async () => {
      
      this.menssajeAlerta('success', 'Información', '¡Departamento actualizado exitosamente!');
      await  this.cargaDatos().then(  res => {
        
        this.nommaedepartamento = res;
        this.initBotones();
      });
    })
      .catch(e => { 
        console.log(e);
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.menssajeAlerta('error', 'Error', mensaje);
      } );
  }
  
  borrar(  nommaedepartamento: NomMaedepartamento ) {
    let strSentencia = 'delete from nom_maedepartamento  ' +
      'where dep_codigo = \'' + nommaedepartamento.old_DEP_CODIGO  + '\'';
    
    return this.http.post<any[]>(this.way + '/nom/departamento/eacacd7655bea800', {
        elementos: {
          old_DEP_CODIGO: nommaedepartamento.old_DEP_CODIGO
        }
      }, this.confIniciales.httpOptions()
    ).toPromise().then( async () => {
      
      this.menssajeAlerta('success', 'Información', ' ¡Departamento eliminado exitosamente!');
      await  this.cargaDatos().then(  res => {
        
        this.nommaedepartamento = res;
        this.initBotones();
      });
    })
      .catch(e => { 
        console.log(e);
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.menssajeAlerta('error', 'Error', mensaje);
       } );
  }

  menssajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'nommaedeptoast',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
    
  }

  initBotones(){
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = false;
  }
}
