import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';//GSRF

@Injectable({
  providedIn: 'root'
})
export class CxcenccuotaService {

  private _auxsalto: boolean;
  public CAJXVEN: number;
  public way: string;
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private utilitariosService: UtilitariosService,//GSRF
              private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }
  
  
  get auxsalto(): boolean {
    return this._auxsalto;
  }
  
  set auxsalto(value: boolean) {
    this._auxsalto = value;
  }


  erClinombre(strCodigo) {
    return this.http.post(this.way + '/cxc/cuotaclientes/76d9593af8b2f80', {
      elementos: {
        CLI_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erConnombre(strCodigo) {
    return this.http.post(this.way + '/cxc/cuotaclientes/3b6cc5ef8b2f6e', {
      elementos: {
        CON_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erCuentacontable(strCodigo) {
    return this.http.post(this.way + '/cxc/cuotaclientes/76d9e75faa66300', {
      elementos: {
        parametro: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }
  
  insertarEncabezadoDoc(enccuota): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cuotaclientes/12bf1dbedd8a5b000', {
      elementos: {
        p_encfac_numero: enccuota.ENCFAC_NUMERO,
        p_com_codigo: enccuota.COM_CODIGO,
        p_cli_codigo: enccuota.CLI_CODIGO,
        p_enccuota_fechainicio: enccuota.ENCCUOTA_FECHAINICIO,
        p_enccuota_total: enccuota.ENCCUOTA_FINANCIADO,
        p_enccuota_interesval: enccuota.ENCCUOTA_INTERESVAL,
        p_enccuota_interesporce: enccuota.ENCCUOTA_INTERESPORCE,
        p_enccuota_financiado: enccuota.ENCCUOTA_FINANCIADO,
        p_enccuota_nrocuotas: enccuota.ENCCUOTA_NROCUOTAS,
        p_enccuota_vencecdia: enccuota.ENCCUOTA_VENCECDIA,
        p_enccuota_anio: enccuota.ENCCUOTA_ANIO,
        p_enccuota_abono: enccuota.ENCCUOTA_ABONO,
        p_enccuota_subtotal: enccuota.ENCCUOTA_SUBTOTAL,
        p_encfac_total: enccuota.ENCCUOTA_TOTAL,
        p_enccuota_interestipo: enccuota.ENCCUOTA_INTERESTIPO,
        p_enccuota_doc: enccuota.ENCCUOTA_DOC,
        p_enccuota_codigo: enccuota.ENCCUOTA_CODIGO,
        p_enccuota_interesanual: enccuota.ENCCUOTA_INTERESANUAL,
        p_con_codigo: enccuota.CON_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }
  
  eliminarDetalleDoc(enccuota): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cuotaclientes/756566bbaaab700', {
      elementos: {
        p_ENCFAC_NUMERO: enccuota.ENCFAC_NUMERO,
        p_CLI_CODIGO: enccuota.CLI_CODIGO,
        p_COM_CODIGO: enccuota.COM_CODIGO,
        p_ENCCUOTA_ANIO: enccuota.ENCCUOTA_ANIO,
        p_ENCCUOTA_CODIGO: enccuota.ENCCUOTA_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }
  
  eliminarTrnCobro(strTrnCobroNroDoc): Observable<any> {
    /* let strSentencia = 'DELETE FROM CXC_TRNCOBRO WHERE TRNCOBRO_NRODOC=\'' + strTrnCobroNroDoc + '\'';
    strSentencia = strSentencia + ' AND COM_CODIGO=\'' + '01' + '\''; */
    
    return this.http.post<any[]>(this.way + '/cxc/cuotaclientes/75656eeeecc2f80', {
      elementos: {
        TrnCobroNroDoc: strTrnCobroNroDoc
      }
    }, this.confIniciales.httpOptions());
  }
  
  
  eliminarNDATrnResumen(strTrnCobroNroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cuotaclientes/1d595aed57777e0000', {
      elementos: {
        p_trncobro_nrodoc: strTrnCobroNroDoc,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  insertarDetalleDoc(detcuota): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cuotaclientes/95f8edf5aeeb700', {
      elementos: {
        p_ENCFAC_NUMERO: detcuota.ENCFAC_NUMERO,
        p_CLI_CODIGO: detcuota.CLI_CODIGO,
        p_COM_CODIGO: detcuota.COM_CODIGO,
        p_ENCCUOTA_ANIO: detcuota.ENCCUOTA_ANIO,
        p_DETCUOTA_NROLINEA: detcuota.DETCUOTA_NROLINEA,
        p_DETCUOTA_FECHAVENCE: detcuota.DETCUOTA_FECHAVENCE,
        p_DETCUOTA_CONCEPTO: detcuota.DETCUOTA_CONCEPTO,
        p_DETCUOTA_REFERENCIA: detcuota.DETCUOTA_REFERENCIA,
        p_DETCUOTA_IMPORTE: detcuota.DETCUOTA_IMPORTE,
        p_DETCUOTA_PAGADO: detcuota.DETCUOTA_PAGADO,
        p_DETCUOTA_SALDO: detcuota.DETCUOTA_SALDO,
        p_ENCCUOTA_NROCUOTAS: detcuota.ENCCUOTA_NROCUOTAS,
        p_DETCUOTA_NROLINEA_ACT: detcuota.DETCUOTA_NROLINEA,
        p_ENCCUOTA_CODIGO: detcuota.ENCCUOTA_CODIGO,
      },
    }, this.confIniciales.httpOptions());
  }
  
  actualizarNroFactura(strNumFact, itemdet) {
    /* const strSql = 'UPDATE CXC_DETCUOTA SET TRNCOBRO_NRODOC = \'' + strNumFact + '\' ' +
      'WHERE COM_CODIGO = \'' + '01' + '\' AND ENCFAC_NUMERO = \'' + itemdet.ENCFAC_NUMERO +
      '\' AND CLI_CODIGO = \'' + itemdet.CLI_CODIGO + '\' AND ENCCUOTA_ANIO = \'' +
      itemdet.ENCCUOTA_ANIO + '\' AND DETCUOTA_NROLINEA = \'' + itemdet.DETCUOTA_NROLINEA + '\''; */
    
    return this.http.post<any[]>(this.way + '/cxc/cuotaclientes/533bebface7a980', {
      elementos: {
        NumFact: strNumFact,
        ENCFAC_NUMERO: itemdet.ENCFAC_NUMERO,
        CLI_CODIGO: itemdet.CLI_CODIGO,
        ENCCUOTA_ANIO: itemdet.ENCCUOTA_ANIO,
        DETCUOTA_NROLINEA: itemdet.DETCUOTA_NROLINEA
      },
    }, this.confIniciales.httpOptions());
  }
  
  consultarEncabezadoCuotas(encfacnumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cuotaclientes/cc5f9e75d8cf6000', {
      elementos: {
        ENCFAC_NUMERO: encfacnumero
      },
    }, this.confIniciales.httpOptions());
  }
  
  consultarDetalleCuotas(encfacnumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cuotaclientes/662fc6bbacf63c0', {
      elementos: {
        ENCFAC_NUMERO: encfacnumero
      }
    }, this.confIniciales.httpOptions());
  }
  
  seleccionarRegistro(tipo, cadena, condicion): Observable<any> {
    return this.http.post<any>(this.way + '/cxc/cuotaclientes/1c755cc66dd097000', {
      elementos: {
        p_tipo: tipo,
        p_Cadena: cadena,
        p_condicion: condicion
      }
    }, this.confIniciales.httpOptions());
  }
  
  eliminarDocumento(cuotacodigo) {
    return this.http.post<any>(this.way + '/cxc/cuotaclientes/eacacdc33d676000', {
      elementos: {
        p_ENCCUOTA_CODIGO: cuotacodigo,
        p_COM_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  //GSRF
  registrarAuditoria(strObjeto: string, strDato: string, strOperacion: string,
    usuIdentificacion: string, comCodigo: string, audQuien: string,
    audTipoElimina: string, audIdEquipo: string, audFechaHora: string): Observable<any[]> {
    if (strDato === '' || strDato === null || strDato === undefined) {
      strDato = 'NOHAYDATO';
    }
    return this.http.post<any>(this.way + '/reporte/auditoria/af365dc6e4a', {
      elementos: {
        p_AUD_objeto_1: strObjeto + '_W',
        p_AUD_dato_2: strDato,
        p_AUD_operacion_3: strOperacion,
        p_USU_identificacion_4: this.usuario.identificacion,
        p_COM_codigo_5: comCodigo,
        p_AUD_quien_6: this.usuario.identificacion,
        p_AUD_tipoelimina_7: audTipoElimina,
        P_AUD_Serial: audIdEquipo,
        p_AUD_fecha_hora: audFechaHora,
        p_AUD_equipo: this.utilitariosService.getHostname(),
        p_AUD_ippublica: this.utilitariosService.getIpFrontend()
      }
    }, this.confIniciales.httpOptions());
  }
}



