import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class VentrnreembolsosService {

  public url: string;
  public way: string;
  public hmpConfiguracion: any[];
  public strTipoIva: string;

  public grids: any[] = [];

  constructor(private http: HttpClient,
    private confIniciales: ConfInicialesService,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
  ) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }

  insertarReembolso(item): Observable<any> {
    return this.http.post<any[]>(this.way + '/ventas/venreembolsos/577t7kydg01uq0r', {
      elementos: {
        p_encfac_numero: item.ENCFAC_NUMERO,
        p_com_codigo: item.COM_CODIGO,
        p_trnree_tipoidepro: item.TRNREE_TIPOIDEPRO,
        p_trnree_idepro: item.TRNREE_IDEPRO,
        p_trnree_tipcomp: item.TRNREE_TIPCOMP,
        p_trnree_serieest: item.TRNREE_SERIEEST,
        p_trnree_serieptoemi: item.TRNREE_SERIEPTOEMI,
        p_trnree_seccompvta: item.TRNREE_SECCOMPVTA,
        p_trnree_fechaemision: item.TRNREE_FECHAEMISION,
        p_trnree_autorizacion: item.TRNREE_AUTORIZACION,
        p_trnree_basecero: item.TRNREE_BASECERO,
        p_trnree_baseiva: item.TRNREE_BASEIVA,
        p_trnree_basenoiva: item.TRNREE_BASENOIVA,
        p_trnree_porcentajeiva: item.TRNREE_PORCENTAJEIVA,
        p_trnree_montoice: item.TRNREE_MONTOICE,
        p_trnree_montoiva: item.TRNREE_MONTOIVA,
        p_trnree_baseimpexeReemb: item.TRNREE_BASEIMPEXEREEMB,
        p_trnree_paispago: item.TRNREE_CODPAISPAGO,
        p_trnree_protipo: item.TRNREE_PROTIPO,
        p_trnree_codreemb: item.TRNREE_CODREEMB//GSRF 
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarReembolso(item): Observable<any> {

    return this.http.post<any[]>(this.way + '/ventas/venreembolsos/pzhd9m26tkm841k', {
      elementos: {
        p_encfac_numero: item.ENCFAC_NUMERO,
        p_com_codigo: item.COM_CODIGO,
        p_trnree_idepro: item.TRNREE_IDEPRO,
        p_trnree_serieest: item.TRNREE_SERIEEST,
        p_trnree_serieptoemi: item.TRNREE_SERIEPTOEMI,
        p_trnree_seccompvta: item.TRNREE_SECCOMPVTA
      },
    }, this.confIniciales.httpOptions());
  }

  getReembolsos(encfacnumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/venreembolsos/tqa2t2k3fy5hw87', {
      elementos: {
        ENCFAC_NUMERO: encfacnumero
      },
    }, this.confIniciales.httpOptions());
  }

  erabrirTIPOIDEPRO(strTRNREE_TIPOIDEPRO) {
    return this.http.post<any[]>(this.way + '/ventas/venreembolsos/5qwdllpivodl1qs', {
      elementos: {
        TRNREE_TIPOIDEPRO: strTRNREE_TIPOIDEPRO
      },
    }, this.confIniciales.httpOptions());
  }

  erabrirPROTIPO(strTRNREE_PROTIPO) {
    return this.http.post<any[]>(this.way + '/ventas/venreembolsos/xc0cbzg06qvcml2', {
      elementos: {
        TRNREE_PROTIPO: strTRNREE_PROTIPO
      },
    }, this.confIniciales.httpOptions());
  }

  erabrirPAISPAGO(strTRNREE_PAISPAGO) {
    return this.http.post<any[]>(this.way + '/ventas/venreembolsos/9nkwzxksvi6hol5', {
      elementos: {
        TRNREE_PAISPAGO: strTRNREE_PAISPAGO
      },
    }, this.confIniciales.httpOptions());

  }

  erabrirTIPCOMP(strTRNREE_TIPCOMP) {
    return this.http.post<any[]>(this.way + '/ventas/venreembolsos/vrpdq4mp19nufww', {
      elementos: {
        TRNREE_TIPCOMP: strTRNREE_TIPCOMP
      },
    }, this.confIniciales.httpOptions());

  }

  erabrirEMPLEADO(strTRNREE_EMPLEADO) {
    return this.http.post<any[]>(this.way + '/ventas/venreembolsos/fqmw5nr6l0unv6x', {
      elementos: {
        TRNREE_EMPLEADO: strTRNREE_EMPLEADO
      },
    }, this.confIniciales.httpOptions());

  }

  ermostrarDatos(strTRNREE_EMPLEADO) {
    return this.http.post<any[]>(this.way + '/ventas/venreembolsos/5n326lp2pagejtm', {
      elementos: {
        TRNREE_EMPLEADO: strTRNREE_EMPLEADO
      },
    }, this.confIniciales.httpOptions());
  }

  ercodsriTIPOIDEPRO(strTRNREE_TIPOIDEPRO) {
    return this.http.post<any[]>(this.way + '/ventas/venreembolsos/inozjjiaz2v6bo0', {
      elementos: {
        TRNREE_TIPOIDEPRO: strTRNREE_TIPOIDEPRO
      },
    }, this.confIniciales.httpOptions());
  }

  erpaispagocodsri(strTRNREE_PAISPAGO) {
    return this.http.post<any[]>(this.way + '/ventas/venreembolsos/h3b4jkfaejplf30', {
      elementos: {
        TRNREE_PAISPAGO: strTRNREE_PAISPAGO
      },
    }, this.confIniciales.httpOptions());
  }

  ertipcompcodsri(strTRNREE_TIPCOMP) {
    return this.http.post<any[]>(this.way + '/ventas/venreembolsos/wh3r9o4et7i0wgs', {
      elementos: {
        TRNREE_TIPCOMP: strTRNREE_TIPCOMP
      },
    }, this.confIniciales.httpOptions());
  }

  erprotipocodsri(strTRNREE_PROTIPO) {
    return this.http.post<any[]>(this.way + '/ventas/venreembolsos/jdi0u06ogf129vt', {
      elementos: {
        TRNREE_PROTIPO: strTRNREE_PROTIPO
      },
    }, this.confIniciales.httpOptions());
  }

  ecllenarConsulta() {
    return this.http.post<any[]>(this.way + '/ventas/venreembolsos/sqmwftaxg0b5w48', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }


}



