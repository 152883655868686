import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Observable} from 'rxjs';
import {CxcbancosCliente} from '../cxcinterfaces/cxcbancos-cliente';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class CxcbancosClienteService {
  get cxcmaebancli(): CxcbancosCliente[] {
    return this._cxcmaebancli;
  }
  
  set cxcmaebancli(value: CxcbancosCliente[]) {
    this._cxcmaebancli = value;
  }
  
  public way: string;
  private _cxcmaebancli: CxcbancosCliente[];
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }
  
  getbancoClient(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/bancoscliente/83bab55d9865680', {
        elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  insertarBanco(banco: CxcbancosCliente): Observable<any[]> {
    if (banco.BANCLI_ESTADO === 'ACTIVO') {
      banco.AUX = 1;
    } else if (banco.BANCLI_ESTADO === 'NO ACTIVO') {
      banco.AUX = 0;
    }
    return this.http.post<any>(this.way + '/cxc/bancoscliente/12bf1dbead5765000', {
      elementos: {
        p_BANCLI_CODIGO: banco.BANCLI_CODIGO,
        p_BANCLI_NOMBRE: banco.BANCLI_NOMBRE,
        p_BANCLI_ESTADO: banco.AUX,
        p_COM_CODIGO: '01',
      }
    }, this.confIniciales.httpOptions());
  }
  
  actualizarBanco(banco: CxcbancosCliente): Observable<any[]> {
    console.log('compila', banco );
    if (banco.BANCLI_ESTADO === 'ACTIVO') {
      banco.BANCLI_ESTADO = 1;
    } else if (banco.BANCLI_ESTADO === 'NO ACTIVO') {
      banco.BANCLI_ESTADO = 0;
    }
    
    return this.http.post<any>(this.way + '/cxc/bancoscliente/299df2d576564e', {
      elementos: {
        p_BANCLI_CODIGO_1: banco.BANCLI_CODIGO,
        p_BANCLI_CODIGO_2: banco.BANCLI_CODIGO,
        p_BANCLI_NOMBRE: banco.BANCLI_NOMBRE,
        p_BANCLI_ESTADO: banco.BANCLI_ESTADO,
        p_COM_CODIGO: '01',
      }
    }, this.confIniciales.httpOptions());
  }
  
  getTnrPago(banco: CxcbancosCliente): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/bancoscliente/20eef777caa18', {
        elementos: {
          BANCLI_CODIGO: banco.BANCLI_CODIGO
        }
    }, this.confIniciales.httpOptions());
  }
  
  eliminarBanco(banco: CxcbancosCliente): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/bancoscliente/eacad2bab6b56000', {
      elementos: {
        p_BANCLI_CODIGO: banco.BANCLI_CODIGO,
        p_COM_CODIGO: banco.COM_CODIGO,
      }
    }, this.confIniciales.httpOptions());
  }
  
  erBanclicodigo(strCodigo) {
    return this.http.post(this.way + '/cxc/bancoscliente/76d6abb2b2661c0', {
      elementos: {
        BANCLI_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }
}
