
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import {Cxctrncobranzas} from '../cxcinterfaces/cxctrncobranzas'
import {Observable, of} from 'rxjs';
import { CxctrnCobresumen } from '../cxcinterfaces/cxctrncobrresumen';
@Injectable({
  providedIn: 'root'
})
export class CxctrncobranzasService {

    get trncobranza(): Cxctrncobranzas[] {
      return this._trncobranza;
    }
    
    set trncobranza(value: Cxctrncobranzas[]) {
      this._trncobranza = value;
    }
    get cxctrcobnresumen(): CxctrnCobresumen[] {
      return this._cxctrcobnresumen;
    }
    
    set cxctrcobnresumen(value: CxctrnCobresumen[]) {
      this._cxctrcobnresumen = value;
    }
    public _trncobranza: Cxctrncobranzas[];
    public _cxctrcobnresumen

    private way: string;
    public url: string;
  constructor(private http: HttpClient, public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario, private confIniciales: ConfInicialesService) {
      this.way = sessionStorage.getItem('way');
     }
    llenartrnccobranza(usuidentifica) {
      return this.http.post<any[]>(this.way + '/cxc/cxctrncobranzas/15abaeadf54bab00000000', {
          elementos: {
            p_usuidentificacion: usuidentifica,
            p_comcodigo:'01'
          }
        }, this.confIniciales.httpOptions()
        
      ).toPromise();
    }
    consulttrnccobranza(usuidentifica) {
      return this.http.post<any[]>(this.way + '/cxc/cxctrncobranzas/3317e7abd5754c0000000000', {
          elementos: {
            p_usuidentificacion: usuidentifica,
            p_comcodigo:'01'
          }
        }, this.confIniciales.httpOptions()
        
      ).toPromise();
    }
    consulttrnccobranzaorder(usuidentifica,orderby) {
      return this.http.post<any[]>(this.way + '/cxc/cxctrncobranzas/3317e7abd56fbc0000000000', {
          elementos: {
            p_usuidentificacion: usuidentifica,
            p_comcodigo:'01',
            p_orderby:orderby
          }
        }, this.confIniciales.httpOptions()
        
      ).toPromise();
    }
    consulttrnchistorial(trncobro,clicodigo,secuencial) {
      return this.http.post<any[]>(this.way + '/cxc/cxctrncobranzas/198bf3d5eabaa600000000000000', {
          elementos: {
            p_trncobronrodoc: trncobro,
            p_comcodigo:'01',
            p_secuencial:secuencial,
            p_clicodigo:clicodigo
          }
        }, this.confIniciales.httpOptions()
        
      ).toPromise();
    }
    informacioncli(clicodigo) {
      return this.http.post<any[]>(this.way + '/cxc/cxctrncobranzas/662fcf57aadcb0000000', {
          elementos: {
            p_clicodigo:clicodigo,
            p_comcodigo:'01'
          }
        }, this.confIniciales.httpOptions()
        
      ).toPromise();
    }
    informacionven(vencodigo) {
      return this.http.post<any[]>(this.way + '/cxc/cxctrncobranzas/cc5f9eaf55b960000000000000', {
          elementos: {
            p_vencodigo:vencodigo,
            p_comcodigo:'01'
          }
        }, this.confIniciales.httpOptions()
        
      ).toPromise();
    }
    eliminarDetCob(trnhis,usuidentifica): Observable<any[]> {
      return this.http.post<any[]>(this.way+ '/cxc/cxctrncobranzas/7565695d5b6bbc000000', {
          elementos: {
            p_trncobronrodoc: trnhis.TRNCOBRO_NRODOC,
            p_comcodigo:'01',
            p_clicodigo:trnhis.CLI_CODIGO,
            p_usuidentificacion:usuidentifica
          }
      }, this.confIniciales.httpOptions());
    }
    consultarcomdet(trncobronrodoc) {
      return this.http.post<any[]>(this.way + '/cxc/cxctrncobranzas/3317e7abd56d8c000000000000000', {
          elementos: {
            p_comcodigo:'01',
            p_trncobronrodoc: trncobronrodoc,
          }
        }, this.confIniciales.httpOptions()
        
      ).toPromise();
    }
    ervrtotcxc(usuidentificacion){
      return this.http.post<any[]>(this.way + '/cxc/cxctrncobranzas/107772edda5cc70000000000000', {
        elementos: {
          p_usuidentificacion:usuidentificacion
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
}
