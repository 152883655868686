<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="fac"></p-toast>
<div class="p-grid">
	<div class="p-col-9">
		<div class="p-grid">
			<div class="p-col-12">
				<p-scrollPanel id="scroll" [style]="{'width':'100%', 'height':'calc(100vh - 460px)'}" >
					<p-table selectionMode="single" editMode="row" [selection]="pedSeleccionado[instanciaPrin - 1]"
							 [value]="prodPedido[instanciaPrin - 1]">
						<ng-template pTemplate="body" let-prod let-rowIndex="rowIndex"
									 let-rowData>
							<tr [pSelectableRow]="rowData" (click)="selectedFac(rowData, rowIndex)">
								<td>
									<span class="productoNombre">{{prod.ART_NOMBREC}}</span>
									<span class="precio"
										  style="float: right">${{moneyValidation((prod.CANTIDAD * prod.ARTPRE_PRECIO - ((prod.DESCUENTO / 100) * (prod.CANTIDAD * prod.ARTPRE_PRECIO))).toFixed(2) + '')}}</span>
									<ul>
										<li style="list-style: none">
											<span>{{prod.CANTIDAD}}&nbsp;Unidad(es)&nbsp;</span>
											<span>en&nbsp;${{moneyValidation(prod.ARTPRE_PRECIO + '')}}</span>
										</li>
										<li style="list-style: none" *ngIf="prod.DESCUENTO > 0 && prod.DESCUENTO !== undefined">
											<span>Con&nbsp;un&nbsp;descuento&nbsp;de&nbsp;{{prod.DESCUENTO}}%</span>
										</li>
									</ul>
								</td>
							</tr>
						</ng-template>
					</p-table>

					<!--<div *ngIf="total[instanciaPrin -1] !== 0 && total[instanciaPrin -1] !== undefined"
						 style="text-align: right; display: block;">
					  <div class="p-grid">
						<div class="p-col-6 text-left">
						  <span>Subtotal: ${{moneyValidation(subtotal[instanciaPrin - 1] + '')}}</span><br>
						  <span>Descuento: ${{moneyValidation(descuento[instanciaPrin - 1] + '')}}</span><br>
						  <span>Base IVA: ${{moneyValidation(baseiva[instanciaPrin - 1] + '')}}</span><br>
						</div>
						<div class="p-col-6 text-right">
						  <span>Iva: ${{moneyValidation(iva[instanciaPrin - 1] + '')}}</span><br>
						  <span>Base Cero: ${{moneyValidation(basecero[instanciaPrin - 1] + '')}}</span><br>
						  <span>Total: ${{moneyValidation(total[instanciaPrin - 1] + '')}}</span>
						</div>
					  </div>
					</div>-->
				</p-scrollPanel>
			</div>
			<div class="p-col-12">
				<div *ngIf="total[instanciaPrin -1] !== 0 && total[instanciaPrin -1] !== undefined"
					 style="text-align: right; display: block; margin-top: -16px; margin-bottom: -8px">
					<div class="p-grid">
						<div class="p-col-6 text-left">
							<span>Subtotal: ${{moneyValidation(subtotal[instanciaPrin - 1] + '')}}</span><br>
							<span>Descuento: ${{moneyValidation(descuento[instanciaPrin - 1] + '')}}</span><br>
							<span>Base IVA: ${{moneyValidation(baseiva[instanciaPrin - 1] + '')}}</span><br>
						</div>
						<div class="p-col-6 text-right">
							<span>Iva: ${{moneyValidation(iva[instanciaPrin - 1] + '')}}</span><br>
							<span>Base Cero: ${{moneyValidation(basecero[instanciaPrin - 1] + '')}}</span><br>
							<span>Total: ${{moneyValidation(total[instanciaPrin - 1] + '')}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
	<div class="p-col-3 text-left">
		<table style="width: 100%; height: calc(100vh - 410px)">
			<tr>
				<td style="width: 100%; height: 14.28%" >
					<button *ngIf="prodSeleccionado !== undefined" [disabled]="facPagada" class="button_largos"
							style="background-image: url(../../assets/images/iconspdv/eliminar-producto-2.png);"
							(click)="eliminarProd()" title="Borrar producto seleccionado">
					</button>
					<button *ngIf="prodSeleccionado === undefined" [disabled]="facPagada" class="button_largos" type="button"
							style="background-image: url(../../assets/images/iconspdv/eliminar-total-gris.png);"
							(click)="eliminarProd()" title="Borrar producto seleccionado">
					</button>
				</td>
			</tr>
			<tr>
				<td style="width: 100%; height: 14.28%" >
					<button *ngIf="!facPagada" [disabled]="facPagada" class="button_largos" (click)="buscarProducto()"
							type="button" style="background-image: url(../../assets/images/iconspdv/buscar.png);" title="Buscar producto">
					</button>
					<button *ngIf="facPagada" [disabled]="facPagada" class="button_largos" (click)="buscarProducto()"
							type="button"
							style="background-image: url(../../assets/images/iconspdv/buscar-gris.png); width: 100%"
							title="Buscar producto">
					</button>
				</td>
			</tr>
			<tr>
				<td style="width: 100%; height: 14.28%" >
					<button *ngIf="prodSeleccionado !== undefined" [disabled]="facPagada" class="button_largos"
							(click)="enviarDescuento()"
							type="button"
							style="background-image: url(../../assets/images/iconspdv/boton-largo-en-blanco.png);"
							title="Aplicar descuento al producto seleccionado">Desc. %
					</button>
					<button *ngIf="prodSeleccionado === undefined" [disabled]="facPagada" class="button_largos"
							(click)="enviarDescuento()"
							type="button"
							style="background-image: url(../../assets/images/iconspdv/boton-largo-en-blanco-gris.png);"
							title="Aplicar descuento al producto seleccionado">Desc. %
					</button>
				</td>
			</tr>
			<tr>
				<td style="width: 100%; height: 14.28%" >
					<button *ngIf="prodSeleccionado !== undefined" [disabled]="facPagada" class="button_largos"
							(click)="enviarPrecio()" type="button"
							style="background-image: url(../../assets/images/iconspdv/boton-largo-en-blanco.png);"
							title="Aplicar descuento por precio del producto seleccionado">Desc. $
					</button>
					<button *ngIf="prodSeleccionado === undefined" [disabled]="facPagada" class="button_largos"
							(click)="enviarPrecio()" type="button"
							style="background-image: url(../../assets/images/iconspdv/boton-largo-en-blanco-gris.png);"
							title="Aplicar descuento por precio del producto seleccionado">Desc. $
					</button>
				</td>
			</tr>
			<tr>
				<td style="width: 100%; height: 14.28%" >
					<button *ngIf="prodSeleccionado !== undefined" [disabled]="facPagada" class="button_largos" type="button"
							(click)="enviarCantidad()"
							style="background-image: url(../../assets/images/iconspdv/boton-largo-en-blanco.png);"
							title="Cambiar cantidad del producto seleccionado">Cantidad
					</button>
					<button *ngIf="prodSeleccionado === undefined" [disabled]="facPagada" class="button_largos" type="button"
							(click)="enviarCantidad()"
							style="background-image: url(../../assets/images/iconspdv/boton-largo-en-blanco-gris.png);"
							title="Cambiar cantidad del producto seleccionado">Cantidad
					</button>
				</td>
			</tr>
			<tr>
				<td style="width: 100%; height: 14.28%" >
					<button *ngIf="prodSeleccionado !== undefined" [disabled]="facPagada" class="button_largos" type="button"
							style="background-image: url(../../assets/images/iconspdv/boton-largo-en-blanco.png);"
							title="Cambiar precio del producto seleccionado" (click)="enviarPrecioValor()">Precio
					</button>
					<button *ngIf="prodSeleccionado === undefined" [disabled]="facPagada" class="button_largos" type="button"
							style="background-image: url(../../assets/images/iconspdv/boton-largo-en-blanco-gris.png);"
							title="Cambiar precio del producto seleccionado" (click)="enviarPrecioValor()">Precio
					</button>
				</td>
			</tr>
			<tr>
				<td style="width: 100%; height: 14.28%" >
					<button *ngIf="prodSeleccionado !== undefined" [disabled]="facPagada" class="button_largos" type="button"
							style="background-image: url(../../assets/images/iconspdv/boton-largo-en-blanco.png);"
							title="Cambiar peso del producto seleccionado" (click)="enviarCantidad()">Peso
					</button>
					<button *ngIf="prodSeleccionado === undefined" [disabled]="facPagada" class="button_largos" type="button"
							style="background-image: url(../../assets/images/iconspdv/boton-largo-en-blanco-gris.png);"
							title="Cambiar peso del producto seleccionado" (click)="enviarCantidad()">Peso
					</button>
				</td>
			</tr>
		</table>
	</div>
</div>
