
import { Venexpofac } from '../../ven/veninterfaces/venexpofac';
import { InvmaearticuloService } from '../../inv/invservicios/invmaearticulo.service';
import { CxcconsulfacvenService } from "../cxcservicios/cxcconsulfacven.service";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { VenDetfacZ } from '../../ven/veninterfaces/vendetfacz';
import { DatePipe } from '@angular/common';
import { CalculosServiceService } from '../../servicios/calculos-service.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { UtilitariosService } from '../../advantage/advantageservices/utilitarios.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { Router } from '@angular/router';
import { VenmaeclienteService } from '../../ven/venservicios/venmaecliente.service';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
@Component({
  selector: 'app-cxcconsulfacven',
  templateUrl: './cxcconsulfacven.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class CxcconsulfacvenComponent implements OnInit {
  @Input() stnumfac:string
  @Output() salir = new EventEmitter<boolean>();
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  hmpConfiguracion: any[];
  indicador: any;
  index: number;

  numeroPedidos: string[];
  numeroOrdtra: string[];
 // Pedidos con componentes 
 intACTPEDVAL = 0;
  // dropdowns
  cmblistasprecio: any[];
  selectlistasprecio: any;
  cmbivaaux: any[];
  selectiva: any;
  cmbbodega: any[];
  selectbodega: any;
  cmbporceiva: any[];
  selectporceiva: any;
  cmbtipofactura: any[];
  selecttipofactura: any;
  disabledEstadisticas = true;
  spin=false;
 // barra
 barraBotones2: boolean;
 botonNuevo: boolean;
 botonGuardar: boolean;
 botonBorrar: boolean;
 botonRegresar: boolean;
 botonFactura: boolean;
 botonAnular: boolean;
 botonXML: boolean;
 botonPrimero: boolean;
 botonAnterior: boolean;
 botonSiguiente: boolean;
 botonUltimo: boolean;
 descglobaconfig:any;//CDPJ
  descGlobal:number=0;//CDPJ
  CNAFEPV:number//CDPJ
  ACCCFV:number//CDPJ
  boolfacturaElec//CDPJ
  displayDialogEst:boolean//CDPJ
  estfirma:string//CDPJ
  asiento: any;
  strNumFact: any;
  strFechaSer: any;
  chknotadeventa: boolean;
  labelPromocion: boolean;
  rbtFECCUO: string;
  FEC: boolean;
  CUO: boolean;
  bolNuevo: boolean;
  bolNuevodet: boolean;//GSRF
  listarefrescada: boolean;
  bolCliente: boolean;
  razonanular: string;
  tipodetalle: string;
  logResultado: string;
  tmpdatoscliente: any;
  bolestcta: boolean;
  bolsaldo: boolean;
  boolfeh=false;//CDPJ
  numfac:string;//CDPJ
  bolean:boolean;//CDPJ
  
  // descuento global
  booleanValor: boolean;
  booleanPorcentaje: boolean;
  valorDscValor: number;
  valorDscPorcentaje: number;
  dscClienteActual: number;
  dscSeleccionado: string;
  valorotrdsctos:number=0;//CDPJ
  valorotrdsDif:number=0;
  valorotrdsBacero:number=0;//CDPJ
  // configuraciones
  CONSIDNV: number;
  REFMAE: number;
  REFLOCMED: number;
  PUNVTAS: number;
  STIPIVACLI: number;
  NVNOIVA: number;
  NUMITEMFAC: number;
  FACCC: number;
  FACLIGREQ: number;
  FACCANUND: number;
  BLFHFAC:number;//CDPJ
  GUINOKARD:number;//CDPJ
  // aggrid
  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  rowStyleEC;
  rowStyleSaldo;
  defaultColDef;
  defaultColDefCxctmptrnresumen;
  defaultColDefCxctrnresumen;
  defaultColDefInfoAdic;
  comboOpciones: any[];
  largo: string;
  largoestcta: string;
  txtCorreo1: string;
  txtCorreo2: string;
// Existencia por bodega
  displayDialogExistBod = false;
  txtArtCodigo = '';
  txtArtDescripcion = '';
  txtArtUnidad = '';
  txtListaPrecio = '';
  txtObservacionRes = '';
  txtIva;
  txtBodegaRes = '';
  choosedColorExisMin: string;
  choosedColorPreBajoCosto: string;
  choosedColorTotalIncluEnDscto:string;//CDPJ
  disablediva: boolean = true;
  numfacntg: Number;
  detfacSelected: VenDetfacZ;


exportacionNuevoSeleccionado: Venexpofac = {};
booleanImpuestoRenta: boolean;
booleanRefrendo: boolean;
opcionesIngresoExterior: SelectItem[];
opcionesTipoIngresoExterior: SelectItem[];
opcionesRegimen: SelectItem[];
booleanRegimen: boolean;
booleanParaiso: boolean;
booleanRegimenP: boolean;
booleanRegimenP1: boolean;
codigoPaisRegimen: string;
codigoPaisParaiso: string;
nombrePaisSeleccionado: string;
nombrePaisSeleccionado1: string;
opcionesExportacion: SelectItem[];
opcionesDistrito: SelectItem[];
opcionesRegRefrendo: SelectItem[];
chkactivo: boolean;
edita: boolean;
expo = false;
SELECTIPOREGI: any;
SELECTEXPORTACIONDE: any;
SELECTIPOINGRESO: any;
SELECTINGGRAVADOEXT: any;
bloqbdfac :  boolean;
auxreporte: number = 0;
strNombreForma: string;
displayDialogReportes: boolean;
  columnDefsDetfac = [
    { // 0
      headerName: 'Bodega', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 80,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Código', field: 'DETFAC_CODIGO', cellEditor: 'cellPrueba',
      editable: (params) => {
        params.data.ENCPED_NUMERO = params.data.ENCPED_NUMERO === null ? '' : params.data.ENCPED_NUMERO;
        return params.data.ENCPED_NUMERO.length === 0;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 25,
          opcionbuscar: true
        }
      }
    },
    { // 2
      headerName: 'Descripción', field: 'DETFAC_DESCRIPCION', cellEditor: 'cellPrueba', width: 200,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 200
        }
      }
    },
    { // 3
      headerName: 'Iva', field: 'DETFAC_TRIBIVA', cellEditor: 'cellPrueba', width: 60,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tienecalculos: true,
          regexp: /^[S]$|^[N]$|^[s]$|^[n]$/,
          noblanco: true
        }
      }
    },
    { // 4
      headerName: 'Ice', field: 'DETFAC_TRIBICE', cellEditor: 'cellPrueba', width: 60,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: true,
          tienecalculos: true,
          regexp: /^[S]$|^[N]$|^[s]$|^[n]$/,
          noblanco: true
        }
      }
    },
    { // 5
      headerName: 'Unidad', field: 'DETFAC_UNIDAD', cellEditor: 'agSelectCellEditor', editable: true, width: 80,
      // onCellValueChanged: (params) => {
      //   this.cambiarPrecioUnidad(params);
      // },
      
      cellEditorParams:
      {
        values: []
      }
    },
    { // 6
      headerName: 'Cantidad', field: 'DETFAC_CANTIDAD', cellEditor: 'cellPrueba', width: 90,
      // onCellClicked: (params) => {
      //   this.abrirLotes(params.data);
      // },onCellValueChanged: (params) => {
      //   const FOCCANLIN = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FOCCANLIN').CFG_VALOR1;
      //   if(FOCCANLIN===1){
      //     this.nuevodetalle();
      //     console.log('enter----');
      //   }
       
      // },
      cellStyle: (params) => {
        const color = this.colorearCantidad(params.data);
        return { background: color, textAlign: 'right' };
      },
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 7
      headerName: 'Precio', field: 'DETFAC_PRECIO', cellEditor: 'cellPrueba', width: 90,
      // , editable: true,
      cellStyle: (params) => {
        const color = this.colorearPrecio(params.data);
        return { background: color, textAlign: 'right' };
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true,
          autorizacion: true
        }
      }
    },
    { // 8
      headerName: 'Dc (%)', field: 'DETFAC_DESCUENTO', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 90,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 9
      headerName: 'Total', field: 'DETFAC_TOTAL', cellEditor: 'cellPrueba', width: 90,
      // , editable: true,
      cellStyle: (params) => {
        const color = this.ColorearTotal(params.data);
        return { background: color, textAlign: 'right' };
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // 10
      headerName: 'Tipo', field: 'DETFAC_TIPOSRI', cellEditor: 'cellPrueba', width: 70,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          opcionbuscar: true
        }
      }
    },
    { // 11
      headerName: 'Lista', field: 'DETFAC_LISTA', cellEditor: 'agSelectCellEditor', editable: true, width: 80,
      // onCellValueChanged: (params) => {
      //   this.changelistaprecios(params.data);
      // },
      cellEditorParams: {
        values: []
      }
    },
    { // 12
      headerName: 'C.Costo', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          opcionbuscar: true
        }
      }
    },
    { // 13
      headerName: 'Requisición', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // 14
      headerName: 'Código', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // 15
      headerName: 'Descripción', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // 16
      headerName: 'Partida', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // 17
      headerName: 'BaseNoObjIv', field: 'DETFAC_TRIBASENOOBJIVA', cellEditor: 'cellPrueba', width: 80,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true,
          regexp: /^[S]$|^[N]$|^[s]$|^[n]$/,
        }
      }
    },
    { // 18
      headerName: 'Cant.Und', field: 'DETFAC_CANTIDADUND', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // GSRFR
      headerName: 'irbpnr', field: 'DETFAC_IRBPNR', hide: true ,cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 90,
      editable: (params) => {
        return false;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // GSRF
      headerName: 'Total irbpnr',field: 'DETFAC_VALORIRBPNR', hide: true , cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 90,
      editable: (params) => {
        return false;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
  ];
  constructor(
    public encfacService: CxcconsulfacvenService,
    private calc: CalculosServiceService, private datePipe: DatePipe,
    private errorService: ErroresBaseDatosService,
    public cliService: VenmaeclienteService,
    private confIniciales: ConfInicialesService,
    private utilitariosService: UtilitariosService,
    public init: NuevoComponentService,
    public route: Router, 
    private busquedaVeh: InvmaearticuloService, 
    private auditoriagral: AuditoriagralService,
  ) {
    this.agTable();

   }
   agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.rowStyle = (params) => {
      if (params.data.DETFAC_TIPODET === 'Y') {
        return { background: 'rgb(255, 204, 255)' };
      }
    };
    //GSRF
    this.rowStyle = (params) => {
      this.bolNuevodet = true
      console.log('******',this.detfacSelected)
      console.log(params.data.DETFAC_DESCRIPCION)
      if(this.detfacSelected !== undefined ){
        
        if (this.detfacSelected.DETFAC_TIPODET !== '*' && params.data.DETFAC_CODIGO !== '') {
          this.bolNuevodet = false;
        if(params.data.DETFAC_DESCRIPCION !== ''){
          return  this.bolNuevodet = false;
        }else{
          return  this.bolNuevodet = true;
        }
        
        }else if (this.detfacSelected.DETFAC_TIPODET === '*' && params.data.DETFAC_DESCRIPCION !== '') {
          return  this.bolNuevodet = false;
        }
      }else{
        return this.bolNuevodet = false;
      }
      
    };
    //GSRF

    this.rowStyleEC = (params) => {
      if (params.data.TRNCOBRO_TIPO !== 'A') {
        if (params.data.TRNCOBRO_FECHATRN === '') {
          return { background: 'rgb( 255, 255, 170)' };
        } else {
          return { background: 'rgb( 196, 225, 255)' };
        }
      }
    };

    this.rowStyleSaldo = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if (params.data.TRNCOBRO_FECHATRN === '') {
        return { background: 'rgb( 255, 255, 170)' };
      }
    };

    this.defaultColDef = {
      editable: true,
      width: 100,
      objeto: 'detfac',
      resizable: true
    };

    this.defaultColDefCxctmptrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctmptrnresumen',
      resizable: true
    };

    this.defaultColDefCxctrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctrnresumen',
      resizable: true
    };

    this.defaultColDefInfoAdic = {
      editable: true,
      width: 100,
      objeto: 'infoadicfac',
      resizable: true
    };

 

  }


  async ngOnInit() {
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;

    this.encfacService.refrescarURL();
    this.index = 0;
    this.barraBotones2 = true;
    this.botonNuevo = true;
    this.botonGuardar = true;
    this.botonBorrar = true;
    this.botonRegresar = true;
    this.botonFactura = true;
    this.botonAnular = true;
    this.botonXML = true;
    this.botonPrimero = false;
    this.botonAnterior = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
    this.encfacService.encfacarray[this.indicador] = {};
    this.encfacService.detfacarray[this.indicador] = [];
    this.detfacSelected = {};
    this.largo = '-80';

    this.choosedColorExisMin = 'rgb(128, 128, 255)';
    this.choosedColorPreBajoCosto = 'rgb(255, 98, 98)';
    this.choosedColorTotalIncluEnDscto='rgb(234, 250, 93)';
    await this.getConfiguracion();
   await this.llenarCombos();
    await this.nuevaFactura();
    
    this.encontrarFactura(this.stnumfac,'')
  }
  async getConfiguracion() {
    this.auditoriagral.obtenerConfiguracion1().subscribe((conf) => {
      this.hmpConfiguracion = conf;
      this.encfacService.hmpConfiguracion = conf;
      this.CONSIDNV = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CONSIDNV').CFG_VALOR1;
      this.REFMAE = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'REFMAE').CFG_VALOR1;
      this.REFLOCMED = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'REFLOCMED').CFG_VALOR1;
      this.PUNVTAS = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PUNVTAS').CFG_VALOR1;
      this.STIPIVACLI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'STIPIVACLI').CFG_VALOR1;
      this.NVNOIVA = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NVNOIVA').CFG_VALOR1;
      this.NUMITEMFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMITEMFAC').CFG_VALOR2;
      this.FACCC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FACCC').CFG_VALOR1;
      this.FACLIGREQ = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FACLIGREQ').CFG_VALOR1;
      this.FACCANUND = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FACCANUND').CFG_VALOR1;
      const LISTPREC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'LISTPREC').CFG_VALOR1;
      this.intACTPEDVAL = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ACTPEDVAL').CFG_VALOR1;
       //CDPJ
       this.BLFHFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'BLFHFAC').CFG_VALOR1;
       this.GUINOKARD=this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'GUINOKARD').CFG_VALOR1;
       if (this.BLFHFAC === 1) {
         this.boolfeh=true;
 
       }
       this.descglobaconfig=this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DESGLOFP').CFG_VALOR1;
       this.CNAFEPV=this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CNAFEPV').CFG_VALOR1;
       this.ACCCFV=this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ACCCFV').CFG_VALOR1;
       //CDPJ
      if (this.FACCANUND !== 1) {
        this.columnDefsDetfac.splice(18, 1);
      }

      if (this.FACLIGREQ !== 1) {
        this.columnDefsDetfac.splice(13, 4);
      }

      if (this.FACCC !== 1) {
        this.columnDefsDetfac.splice(12, 1);
      }

      if (LISTPREC !== 1) {
        this.columnDefsDetfac[11] = { // 11
          headerName: 'Lista', field: 'DETFAC_LISTA', cellEditor: 'cellPrueba', width: 80,
          // , editable: true,
          cellEditorParams:
          {
            values: {
              mayusculas: true,
              numeros: true,
              alphabetic: true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 0,
              obligatorio: true,
              fecha: false,
              clave: false,
              tamanocelda: 32
            }
          }
        };
      }
      this.aggrid.refreshColumnDefs();
    });
  }
  nuevaFactura() {
    let lblNumeroDoc = '';

    if (this.encfacService.encfacarray[this.indicador] !== undefined) {
      lblNumeroDoc = this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO;
    }

    let bodega = '';
    if (this.selectbodega !== undefined ||
      this.selectbodega !== null) {
      bodega = this.selectbodega.codigo;
    }

    this.encfacService.encfacarray[this.indicador] = {
      ENCFAC_NUMERO: '',
      COM_CODIGO: '',
      ENCFAC_FECHAEMISION: '',
      ENCFAC_FECHAENTREGA: '',
      ENCFAC_IVA: '',
      ENCFAC_ESTADO: '',
      CLI_CODIGO: '',
      CLI_NOMBRE: '',
      BOD_CODIGO: bodega,
      VEN_CODIGO: '',
      ENCFAC_REFERENCIA: '',
      ENCFFA_CODIGO: '',
      ENCFAC_FORMAPAGO: '',
      ENCFAC_TIPO: '',
      ENCFAC_LISTA: '',
      ENCFAC_FECHAVENCIMIENTO: '',
      ENCFAC_TOTAL: '',
      ENCFAC_TOTALNETO: '',
      ENCFAC_VALORDES: '',
      ENCFAC_PORCEDES: '',
      ENCFAC_VALORIVA: '',
      ENCFAC_PORCEIVA: '',
      ENCFAC_VALORICE: '',
      ENCFAC_PORCEICE: '',
      ENCFAC_BASEIVA: '',
      ENCFAC_BASEICE: '',
      ENCFAC_BASECERO: '',
      ENCFAC_PORCECAR: '',
      ENCFAC_VALORCAR: '',
      ENCFAC_PORCERTC: '',
      ENCFAC_VALORRTC: '',
      ENCFAC_VALORDEV: '',
      ENCFAC_BASEIMP: '',
      ENCFAC_PORCEINT: '',
      ENCFAC_VALORINT: '',
      ENCFAC_PORCEREC: '',
      ENCFAC_VALORREC: '',
      ENCFAC_IMPRESO: '',
      ENCFAC_COMENTARIO: '',
      ENCFAC_TIPOFAC: '',
      ENCFAC_GENERAFAC: '',
      ENCFAC_NUMERODOB: '',
      ENCFAC_DIRECCION: '',
      ENCFAC_TELEFONO: '',
      ENCFAC_RUCIDE: '',
      ENCFAC_CHOFER: '',
      ENCFAC_CARRO: '',
      ENCFAC_PLACA: '',
      ENCFAC_GRUPO: lblNumeroDoc,
      ENCFAC_OTROS: '',
      ENCFAC_NEMONICO: '',
      ENCFAC_SERIE: '',
      ENCFAC_SECACTUAL: '',
      ASI_NRO: '',
      ENCFAC_DIASCRDT: '',
      ENCFAC_CLIDSCT: '',
      USU_IDENTIFICACION: '',
      ENCFAC_OBSERVACION: '',
      ENCFAC_FLAG: '',
      ENCFAC_TIPODSCTO: '',
      ENCFAC_ORIGEN: '',
      ENCFAC_REFCLI: '',
      ENCFAC_DIRECCION2: '',
      ENCFAC_TELEFONO2: '',
      ENCFAC_FAX: '',
      ENCFAC_CORREO: '',
      ENCFAC_FLAGMOD: '',
      ENCFAC_FECHASERVIDOR: '',
      ENCFAC_NOTADEVENTA: '',
      MAEMOTIVO_CODIGO: '',
      ENCFAC_VALORSERCLI: '',
      ENCFAC_PORCESERCLI: '',
      ENCFAC_AUTORIZACION: '',
      ENCFAC_ORGIMP: '',
      ENCFAC_NOMBRE: '',
      ENCFAC_JEFEZONA: '',
      ENCFAC_CAMPANIA: '',
      ENCFAC_NUMPUNTOS: '',
      ENCFAC_ESTADO_FE: '',
      ENCFAC_AUTORIZACION_FE: '',
      ENCFAC_CLAVEACCESO_FE: '',
      ENCFAC_FECHAAUT_FE: '',
      ENCFAC_NUMFACTELEC: '',
      COM_TIPOAMBFACTELEC: '',
      ENCFAC_PATHXMLNOAUTO_FE: '',
      ENCFAC_PATHPDF_FE: '',
      ENCFAC_FACTEXPO: '',
      ENCFAC_BASENOOBJIVA: '',
      ENCFAC_TIPOFACTURA: '',
      ENCFAC_CODMEDIGO: '',
      ENCFAC_NOMMEDICO: '',
      ENCFAC_DESCUENTO: '',
      ENCFAC_CODMEDICO: '',
      ENCFAC_PLACA_CLI: '',
      ENCFAC_SALDOCLIENTE: '',
      ENCFAC_HORAEMISION: '',
      VEN_NOMBRE: '',
      ENCFAC_REFERENCIANOMBRE: '',
      ENCFAC_ITEMSDISPONIBLES: this.NUMITEMFAC,
       ///GSRF
       COMERCIOEXTERIOR: '',          
       INCOTERMFACTURA: '',          
       LUGARINCOTERM: '',            
       PAISORIGEN: '',              
       PUERTOEMBARQUE: '',           
       PUERTODESTINO: '',           
       PAISDESTINO: '',              
       PAISADQUISICION: '',          
       DIRCOMPRADOR: '',             
       INCOTERMTOTALSINIMPUESTOS: '',
       FLETEINTERNACIONAL : '',       
       SEGUROINTERNACIONAL: '',       
       GASTOSADUANEROS: '',           
       GASTOSTRANSPORTEOTROS: '',    
       FORMAPAGO: '',
       CLI_TIPOIDE: '',          
       CLI_RUCIDE : '',          
       CLI_PARTEREL: '',             
       CLI_TIPO: '',                 
       CLI_NOMBREXP: '',
 
       ENCFAC_FECHAEMBARQUE: '',
       ENCFAC_FECHAEMISIONEXP: '',
       PUNTOEMISION: '',
       ESTABLECIMIENTO: '',
 
       TPIDCLIENTEEX: '',
       PARTERELEXP: '',
       DENOEXPCLI: '',
       TIPOREGI: '',
       PAISEFECPAGOGEN: '',
       PAISEFECEXP: '',
       EXPORTACIONDE: '',
       DISTADUANERO: '',
       ANIO: '',
       REGIMEN: '',
       CORRELATIVO: '',
       DOCTRANSP: '',
       FECHAEMBARQUE: '',
       VALORFOB: '',
       VALORFOBCOMPROBANTE: '',
       TIPOINGRESO: '',
       INGGRAVADOEXT: '',
       VALORIMPEXT: '',
       TIPOCOMPROBANTE: '',
       codigoPaisRegimen: '',
       codigoPaisParaiso: '',
       nombrePaisSeleccionado: '',
       nombrePaisSeleccionado1: '',
       ENCFAC_OTRDSCTOS:'',
       ENCFAC_OTRDSCTOSBASEDIF:'',
       ENCFAC_OTRDSCTOSBASECERO:''
   ///GSRF
    };
    this.SELECTIPOREGI ='';
    this.SELECTEXPORTACIONDE = '';
    this.encfacService.detfacarray[this.indicador] = [];
    console.log(this.SELECTIPOREGI);
    console.log(this.SELECTEXPORTACIONDE);
  }
  manejarSenales(valor){
    if (valor === 'Imprimir') {
      this.strNombreForma = 'frmVEN_ENCFAC';
      this.auxreporte++;
      this.displayDialogReportes = true;
    }
    if (valor === 'Salir') {
      this.salir.emit(true);
    }
  }
  cerrarReportes() {
    this.displayDialogReportes = false;
  }
  async llenarCombos() {
    this.encfacService.obtenerListasPrecios().subscribe((olp) => {
      this.cmblistasprecio = [];
      for (const lp of olp) {
        this.cmblistasprecio.push({ codigo: lp.ARTPRE_CODIGO, name: lp.ARTPRE_CODIGO });
      }
    });
    this.cmbivaaux = [{ codigo: '1', name: 'Neto + Iva' },
    { codigo: '2', name: 'Incluído Iva' },
    { codigo: '0', name: 'Exento' }];
    const bods: any = await this.encfacService.obtenerBodegasInternas();
    this.cmbbodega = [];
    for (const b of bods) {
      this.cmbbodega.push({ codigo: b.BOD_CODIGO, name: b.BOD_NOMBRE + ' (' + b.BOD_CODIGO + ')' });
    }
    this.selectbodega = this.cmbbodega[0];
    this.cmbporceiva = [{ codigo: '12', name: '12' },
    { codigo: '8', name: '8' }];
    this.cmbtipofactura = [{ codigo: '18', name: '18-Venta Normal' },
    { codigo: '01', name: '01-Factura de Exportación' },
    { codigo: '41', name: '41-Factura de Reembolsos' },
    { codigo: '370', name: '370-Factura Operadora Transporte / Socio' },
    { codigo: '371', name: '371-Comprobante Socio A Operadora De Transporte' }];
  }
  setFocus(event){ }
  botonesmodificar(){}
  busqlistasprecio(evento){}
  busqiva(evento){}
  busqporceiva(evento){}
  busqtipofactura(evento){}
  rdbtipodet(evento){}
  busqbodega(evento){}
  disminuirPrecioIva(){}
  busquedacliente(evento){}
  busquedavendedor(evento){}
  selecciondetfac(evento){}
  changeTabs(){}
  cambio(evento){}
  async encontrarFactura(encfaccodigo, tipo) {
    this.init.disabledtab = true;
    this.detfacSelected = undefined;
    this.bolestcta = false;
    this.bolsaldo = false;
    let resp = [];
    //GSRF
    this.chkactivo = false;
    this.limpiaexport();
    //GSRF
    if (tipo === 'sR') {
      resp.push(encfaccodigo);
    } else {
      //this.spin = true;
      resp = await this.encfacService.getEncfac(encfaccodigo);
      //this.spin = false;
    }
    if (resp === null) {
      this.init.disabledtab = false;
      return;
    }
    for (const data of resp) {
      data.ENCFAC_FECHAEMISION = new Date(data.ENCFAC_FECHAEMISION);
      data.ENCFAC_FECHAENTREGA = new Date(data.ENCFAC_FECHAENTREGA);
      data.ENCFAC_FECHASERVIDOR = new Date(data.ENCFAC_FECHASERVIDOR);
      data.ENCFAC_FECHAVENCIMIENTO = new Date(data.ENCFAC_FECHAVENCIMIENTO);
      this.bolNuevo = false;
      this.bolCliente = true;
      this.encfacService.encfacarray[this.indicador] = data;
      this.encfacService.encfacarray[this.indicador].ENCFAC_HORAEMISION = new Date(data.ENCFAC_FECHAEMISION);
 //GSRF
 this.encfacService.getfacexp(data.ENCFAC_NUMERO).subscribe( (res) => {
  for (const data1 of res) {
    console.log(data1);
    if(data1.length === 0){
    this.chkactivo = false;
    this.limpiaexport();
    }else{
    this.chkactivo = true;
    this.limpiaexport();
    console.log( data1.COMERCIOEXTERIOR);
    console.log( this.encfacService.encfacarray[this.indicador].COMERCIOEXTERIOR = data1.COMERCIOEXTERIOR);
    this.encfacService.encfacarray[this.indicador].COMERCIOEXTERIOR = data1.COMERCIOEXTERIOR;         
    this.encfacService.encfacarray[this.indicador].INCOTERMFACTURA = data1.INCOTERMFACTURA;  
    this.encfacService.encfacarray[this.indicador].LUGARINCOTERM = data1.LUGARINCOTERM;             
    this.encfacService.encfacarray[this.indicador].PAISORIGEN = data1.PAISORIGEN;                
    this.encfacService.encfacarray[this.indicador].PUERTOEMBARQUE = data1.PUERTOEMBARQUE;            
    this.encfacService.encfacarray[this.indicador].PUERTODESTINO = data1.PUERTODESTINO;            
    this.encfacService.encfacarray[this.indicador].PAISDESTINO  = data1.PAISDESTINO;             
    this.encfacService.encfacarray[this.indicador].PAISADQUISICION = data1.PAISADQUISICION;           
    this.encfacService.encfacarray[this.indicador].DIRCOMPRADOR = data1.DIRCOMPRADOR;              
    this.encfacService.encfacarray[this.indicador].INCOTERMTOTALSINIMPUESTOS = data1.INCOTERMTOTALSINIMPUESTOS;  
    this.encfacService.encfacarray[this.indicador].FLETEINTERNACIONAL = data1.FLETEINTERNACIONAL;        
    this.encfacService.encfacarray[this.indicador].SEGUROINTERNACIONAL = data1.SEGUROINTERNACIONAL;       
    this.encfacService.encfacarray[this.indicador].GASTOSADUANEROS = data1.GASTOSADUANEROS;           
    this.encfacService.encfacarray[this.indicador].GASTOSTRANSPORTEOTROS = data1.GASTOSTRANSPORTEOTROS;     
    this.encfacService.encfacarray[this.indicador].FORMAPAGO = data1.FORMAPAGO;
    //////////// EXPORTACION NUEVO
    this.encfacService.encfacarray[this.indicador].CLI_TIPO = data1. TPIDCLIENTEEX;
    this.encfacService.encfacarray[this.indicador].CLI_PARTEREL = data1.PARTERELEXP;
    this.SELECTIPOREGI = data1.TIPOREGI;
    if(data1.TIPOREGI === '01'){
      this.encfacService.encfacarray[this.indicador].codigoPaisRegimen = data1.PAISEFECPAGOGEN;
    }else if(data1.TIPOREGI === '02'){
      this.encfacService.encfacarray[this.indicador].codigoPaisParaiso = data1.PAISEFECPAGOGEN;
    }
    this.encfacService.encfacarray[this.indicador].PAISEFECEXP = data1.PAISEFECEXP;
    this.encfacService.encfacarray[this.indicador].DENOEXPCLI = data1.DENOEXPCLI;
    this.encfacService.encfacarray[this.indicador].DOCTRANSP = data1.DOCTRANSP;
    this.encfacService.encfacarray[this.indicador].CORRELATIVO = data1.CORRELATIVO;
    this.encfacService.encfacarray[this.indicador].ANIO = data1.ANIO;
    this.encfacService.encfacarray[this.indicador].REGIMEN = data1.REGIMEN;
    this.encfacService.encfacarray[this.indicador].DISTADUANERO = data1.DISTADUANERO;
    this.encfacService.encfacarray[this.indicador].VALORFOB = data1.VALORFOB;
    this.encfacService.encfacarray[this.indicador].VALORIMPEXT = data1.VALORIMPEXT;
    if(data1.INGGRAVADOEXT === null){
      this.SELECTINGGRAVADOEXT = '';
    }else{
      this.SELECTINGGRAVADOEXT = data1.INGGRAVADOEXT;
    }
    if(data1.TIPOINGRESO === null){
      this.SELECTIPOINGRESO = '';
    }else{
      this.SELECTIPOINGRESO = data1.TIPOINGRESO;
    }
    this.SELECTEXPORTACIONDE = data1.EXPORTACIONDE;
    data1.FECHAEMBARQUE = new Date();
    this.encfacService.encfacarray[this.indicador].FECHAEMBARQUE = data1.FECHAEMBARQUE;
    this.encfacService.encfacarray[this.indicador].VALORFOBCOMPROBANTE = data1.VALORFOBCOMPROBANTE;
      if (this.SELECTEXPORTACIONDE === '01') {
        this.booleanRefrendo = true;
        this.booleanImpuestoRenta = true;
      } else if (this.SELECTEXPORTACIONDE === '02') {
        this.booleanRefrendo = true;
        this.booleanImpuestoRenta = true;
      } else if (this.SELECTEXPORTACIONDE === '03') {
        this.booleanRefrendo = false;
        this.booleanImpuestoRenta = false;
      } else if (this.SELECTEXPORTACIONDE === '') {
        this.booleanRefrendo = true;
        this.booleanImpuestoRenta = true;
      }

      if (this.SELECTIPOREGI === '01') {
          this.booleanRegimen = false;
          this.booleanParaiso = true;
          this.booleanRegimenP = true;
          this.booleanRegimenP1 = true;
      } else if (this.SELECTIPOREGI === '02') {
          this.booleanRegimen = true;
          this.booleanParaiso = false;
          this.booleanRegimenP = true;
          this.booleanRegimenP1 = false;
      } else if (this.SELECTIPOREGI === '03') {
          this.booleanRegimen = true;
          this.booleanParaiso = true;
          this.booleanRegimenP = false;
          this.booleanRegimenP1 = false;
      } else if (this.SELECTIPOREGI === '') {
          this.booleanRegimen = true;
          this.booleanParaiso = true;
          this.booleanRegimenP = true;
          this.booleanRegimenP1 = true;
      }

      if (this.SELECTINGGRAVADOEXT === 'SI') {
          this.booleanImpuestoRenta = false;
      } else if (this.SELECTINGGRAVADOEXT === 'NO') {
          this.booleanImpuestoRenta = true;
      } else if (this.SELECTINGGRAVADOEXT === '') {
          this.booleanImpuestoRenta = true;
      };
    }
  }
})
  //GSRF
      switch (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO) {
        case 'P':
          this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO = 'PENDIENTE';
          // document.getElementById('contorno').style.pointerEvents = 'all';
          // // document.getElementById('contornodetalle').style.pointerEvents = 'all';
          // this.aggrid.habilitarGrid();
          // this.botonXML = false;
          document.getElementById('contorno').style.pointerEvents = 'none';
          // document.getElementById('contornodetalle').style.pointerEvents = 'none';
          this.aggrid.bloquearGrid();
          this.botonXML = false;
          if(this.CNAFEPV === 1){
            const data= await this.encfacService.obtenerNumFE(this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO)
             if(data !== null && data !== undefined){
               for(const rs of data){
                 if(Number(rs.NUM_FE) === 1){
                   this.botonXML = false;
                 }else if(Number(rs.NUM_FE) === 0) {
                   this.botonXML = true;
                 }
               }
             }else{
               this.botonXML = false;
             }
           }
          break;
        case 'G':
          this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO = 'PAGADA';
          document.getElementById('contorno').style.pointerEvents = 'none';
          // document.getElementById('contornodetalle').style.pointerEvents = 'none';
          this.aggrid.bloquearGrid();
          this.botonXML = false;
          if(this.CNAFEPV === 1){
            const data= await this.encfacService.obtenerNumFE(this.encfacService.encfacarray[this.indicador].ENCFAC_GRUPO)
             if(data !== null && data !== undefined){
               for(const rs of data){
                 if(Number(rs.NUM_FE) === 1){
                   this.botonXML = false;
                 }else if(Number(rs.NUM_FE) === 0) {
                   this.botonXML = true;
                 }
               }
             }else{
               this.botonXML = false;
             }
           }
          break;
        case 'A':
          this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO = 'ANULADA';
          document.getElementById('contorno').style.pointerEvents = 'none';
          // document.getElementById('contornodetalle').style.pointerEvents = 'none';
          this.aggrid.bloquearGrid();
          this.botonXML = true;
          break;
        default:
          break;
      }
      switch (this.encfacService.encfacarray[this.indicador].ENCFAC_IMPRESO) {
        case 'S':
          this.encfacService.encfacarray[this.indicador].ENCFAC_IMPRESO = 'IMPRESA';
          break;
        case 'N':
          this.encfacService.encfacarray[this.indicador].ENCFAC_IMPRESO = 'N';
          break;
        default:
          break;
      }

      for (const j of this.cmblistasprecio) {
        if (this.encfacService.encfacarray[this.indicador].ENCFAC_LISTA === j.codigo) {
          this.selectlistasprecio = { codigo: j.codigo, name: j.name };
          this.encfacService.encfacarray[this.indicador].ENCFAC_LISTA = j.codigo;
        }
      }

      for (const j of this.cmbivaaux) {
        if (this.encfacService.encfacarray[this.indicador].ENCFAC_IVA === j.codigo) {
          this.selectiva = { codigo: j.codigo, name: j.name };
        }
      }

      for (const j of this.cmbporceiva) {
        if (this.encfacService.encfacarray[this.indicador].ENCFAC_PORCEIVA.toString() === j.codigo) {
          this.selectporceiva = { codigo: j.codigo, name: j.name };
        }
      }

      for (const j of this.cmbbodega) {
        if (this.encfacService.encfacarray[this.indicador].BOD_CODIGO === j.codigo) {
          this.selectbodega = { codigo: j.codigo, name: j.name };
        }
      }

      for (const j of this.cmbtipofactura) {
        if (this.encfacService.encfacarray[this.indicador].ENCFAC_TIPOFACTURA === j.codigo) {
          this.selecttipofactura = { codigo: j.codigo, name: j.name };
          //GSRF
          console.log(j.codigo);
          if( j.codigo === "01"){
            this.expo = false;
            console.log(data.ENCFAC_SERIE);
            this.encfacService.encfacarray[this.indicador].PUNTOEMISION = data.ENCFAC_SERIE.substr(1,3);
            this.encfacService.encfacarray[this.indicador].ESTABLECIMIENTO = data.ENCFAC_SERIE.substr(4,6);
            //this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISIONEXP = new Date();
            //this.encfacService.encfacarray[this.indicador].FECHAEMBARQUE = new Date();
            this.cliService.getCliente(this.encfacService.encfacarray[this.indicador].CLI_CODIGO).subscribe((res1) => {
              for (const i1 of res1) {
                this.encfacService.encfacarray[this.indicador].CLI_TIPOIDE = i1.CLI_TIPOIDE;      
                this.encfacService.encfacarray[this.indicador].CLI_RUCIDE = i1.CLI_RUCIDE;            
                this.encfacService.encfacarray[this.indicador].CLI_PARTEREL = i1.CLI_PARTEREL;             
                this.encfacService.encfacarray[this.indicador].CLI_TIPO  = i1.CLI_TIPO;                
                this.encfacService.encfacarray[this.indicador].CLI_NOMBREXP = i1.CLI_NOMBRE; 
                }
              });
          }else{
            this.expo = true;
          }
          //GSRF
        }
      }
      this.encfacService.encfacarray[this.indicador].ENCFAC_SALDOCLIENTE = data.SALDO;
      if (this.NUMITEMFAC > 0) {
        this.encfacService.encfacarray[this.indicador].ENCFAC_ITEMSDISPONIBLES = Number(this.NUMITEMFAC);
      }
      this.valorDscValor = this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES;
      this.encfacService.dblValorDescGlb = this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES;
      this.encfacService.dblPorcDescGlb = this.encfacService.encfacarray[this.indicador].ENCFAC_PORCEDES;
      this.encfacService.dblValorotrdscto=this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOS;//CDPJ
      this.encfacService.dblValorotrdsctoDif=this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOSBASEDIF;//CDPJ
      this.encfacService.dblValorotrdsctoBcero=this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOSBASECERO;//CDPJ

      this.encfacService.encfacarray[this.indicador].ENCFAC_TOTALNETO =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTALNETO).toFixed(this.confIniciales.getNumDecSist());
      this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_VALORDES).toFixed(3);
         //CDPJ
        this.encfacService.encfacarray[this.indicador].ENCFAC_PORCEDES =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_PORCEDES).toFixed(2);
        this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOS =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOS).toFixed(2);

        this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOSBASEDIF =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOSBASEDIF).toFixed(2);
        this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOSBASECERO =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOSBASECERO).toFixed(2);

        this.valorotrdsctos= this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOS;
        this.valorotrdsDif = this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOSBASEDIF;
        this.valorotrdsBacero = this.encfacService.encfacarray[this.indicador].ENCFAC_OTRDSCTOSBASECERO;

        //CDPJ
      this.encfacService.encfacarray[this.indicador].ENCFAC_VALORICE =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_VALORICE).toFixed(this.confIniciales.getNumDecSist());
      this.encfacService.encfacarray[this.indicador].ENCFAC_BASEICE =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_BASEICE).toFixed(this.confIniciales.getNumDecSist());
      this.encfacService.encfacarray[this.indicador].ENCFAC_BASECERO =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_BASECERO).toFixed(this.confIniciales.getNumDecSist());
      this.encfacService.encfacarray[this.indicador].ENCFAC_BASEIVA =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_BASEIVA).toFixed(this.confIniciales.getNumDecSist());
      this.encfacService.encfacarray[this.indicador].ENCFAC_VALORIVA =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_VALORIVA).toFixed(this.confIniciales.getNumDecSist());
      this.encfacService.encfacarray[this.indicador].ENCFAC_TOTAL =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_TOTAL).toFixed(this.confIniciales.getNumDecSist());
      this.encfacService.encfacarray[this.indicador].ENCFAC_BASENOOBJIVA =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_BASENOOBJIVA).toFixed(this.confIniciales.getNumDecSist());
        this.encfacService.encfacarray[this.indicador].ENCFAC_VALORIRBPNR =
        Number(this.encfacService.encfacarray[this.indicador].ENCFAC_VALORIRBPNR).toFixed(this.confIniciales.getNumDecSist());

      if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE !== '' &&
        this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE !== null) {
        document.getElementById('contorno').style.pointerEvents = 'none';
        // document.getElementById('contornodetalle').style.pointerEvents = 'none';
        this.aggrid.bloquearGrid();
        //this.botonAnular = true;
      } else if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE === '' ||
        this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE === null) {
        //this.botonAnular = false;
      }
      console.log(this.encfacService.encfacarray[this.indicador])
      //this.encfacService.comas();

      if (this.encfacService.encfacarray[this.indicador].ENCFAC_SERVICIO !== undefined
        && this.encfacService.encfacarray[this.indicador].ENCFAC_SERVICIO !== null
        && this.encfacService.encfacarray[this.indicador].ENCFAC_SERVICIO !== '') {
        this.encfacService.encfacarray[this.indicador].ENCFAC_SERVICIO =
          Number(this.encfacService.encfacarray[this.indicador].ENCFAC_SERVICIO).toFixed(this.confIniciales.getNumDecSist());
        this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_SERVICIO =
          this.init.moneyValidation(this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_SERVICIO);
      }

      const strFecha = this.datePipe.transform(this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
      const strHora = this.datePipe.transform(this.encfacService.encfacarray[this.indicador].ENCFAC_HORAEMISION, 'HH:mm');
      this.spin = true;
      const r2 = await this.encfacService.getDetfac(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, strFecha, strHora);
      this.spin = false;
      if (r2 === null) {
        this.encfacService.detfacarray[this.indicador] = [];
      } else {
        this.encfacService.detfacarray[this.indicador] = r2;
      }
            //CDPJ
            const descGlobal= await this.encfacService.desglobalCliente(this.encfacService.encfacarray[this.indicador].CLI_CODIGO);
            if(descGlobal !== null && descGlobal !== undefined){
              for (const data of descGlobal){
                this.descGlobal=data.DESCUENTO
              }
              
            }else{
             this.descGlobal=0;
           }
           //CDPJ
      this.encfacService.detfacarray[this.indicador].map(async (deta, index) => {
        this.encfacService.detfacarray[this.indicador][index].DETFAC_LINEA_3 = deta.DETFAC_LINEA;
        this.encfacService.detfacarray[this.indicador][index].DETFAC_TRIBIVAAUX = deta.DETFAC_TRIBIVA;

        this.encfacService.detfacarray[this.indicador][index].DETFAC_CANTIDAD =
          Number(this.encfacService.detfacarray[this.indicador][index].DETFAC_CANTIDAD).toFixed(this.encfacService.decimalesCantidadFac);
        this.encfacService.detfacarray[this.indicador][index].DETFAC_PRECIO =
          Number(this.encfacService.detfacarray[this.indicador][index].DETFAC_PRECIO).toFixed(this.encfacService.decimalesPrecioFac);
        this.encfacService.detfacarray[this.indicador][index].DETFAC_DESCUENTO =
          Number(this.encfacService.detfacarray[this.indicador][index].DETFAC_DESCUENTO).toFixed(this.encfacService.decimalesDescFac);
        this.encfacService.detfacarray[this.indicador][index].DETFAC_TOTAL =
          Number(this.encfacService.detfacarray[this.indicador][index].DETFAC_TOTAL).toFixed(this.encfacService.decimalesTotalFac);

        // this.encfacService.detfacarray[this.indicador][index].ART_MULTIUNIDAD = deta.ART_MULTIUNIDAD;
        // this.encfacService.detfacarray[this.indicador][index].UNIDAD_VENTA = deta.ART_UNIDADVENTA;
        // this.encfacService.detfacarray[this.indicador][index].ART_CADUCA = deta.CADUCA;

        const itemdisponibles = Number(this.encfacService.encfacarray[this.indicador].ENCFAC_ITEMSDISPONIBLES);
        this.encfacService.encfacarray[this.indicador].ENCFAC_ITEMSDISPONIBLES = itemdisponibles - 1;
        await this.comprobarPrecio(deta);
        await this.comprobarExistencia(deta);
      });
      this.aggrid.refreshaggrid(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto);
      this.extraerNumeroPedidos(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO, 'S');
    }

    if (this.encfacService.encfacarray[this.indicador].CLI_CODIGO === '') {
      this.disabledEstadisticas = true;
    } else {
      this.encfacService.erGetClienteCodigo(this.encfacService.encfacarray[this.indicador].CLI_CODIGO).subscribe((tipoCli) => {
        if (String(tipoCli[0].CLI_TIPOIDE) === '6') {
          this.disabledEstadisticas = true;
        } else {
          this.disabledEstadisticas = false;
        }
      });
    }
    this.init.disabledtab = false;
  }
  limpiaexport(){
    console.log(this.chkactivo )
    //this.edita = true;
    if(this.chkactivo !== true){
      this.edita = true;
      this.encfacService.encfacarray[this.indicador].COMERCIOEXTERIOR= '';           
    this.encfacService.encfacarray[this.indicador].INCOTERMFACTURA = '';           
    this.encfacService.encfacarray[this.indicador].LUGARINCOTERM = '';             
    this.encfacService.encfacarray[this.indicador].PAISORIGEN = '';                 
    this.encfacService.encfacarray[this.indicador].PUERTOEMBARQUE= '';             
    this.encfacService.encfacarray[this.indicador].PUERTODESTINO = '';            
    this.encfacService.encfacarray[this.indicador].PAISDESTINO = '';              
    this.encfacService.encfacarray[this.indicador].PAISADQUISICION = '';           
    this.encfacService.encfacarray[this.indicador].DIRCOMPRADOR = '';              
    this.encfacService.encfacarray[this.indicador].INCOTERMTOTALSINIMPUESTOS = ''; 
    this.encfacService.encfacarray[this.indicador].FLETEINTERNACIONAL = '';        
    this.encfacService.encfacarray[this.indicador].SEGUROINTERNACIONAL = '';       
    this.encfacService.encfacarray[this.indicador].GASTOSADUANEROS = '';           
    this.encfacService.encfacarray[this.indicador].GASTOSTRANSPORTEOTROS = '';     
    this.encfacService.encfacarray[this.indicador].FORMAPAGO = '';
    }else{
      this.edita = false;
      this.chkactivo = true;
    }
  }
  async comprobarPrecio(item) {
    let dblCostoProm = item.COSTO;
    const strFecha = this.datePipe.transform(this.encfacService.encfacarray[this.indicador].ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
    const strHora = this.datePipe.transform(this.encfacService.encfacarray[this.indicador].ENCFAC_HORAEMISION, 'HH:mm');


    const MARPREBCOS = this.encfacService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'MARPREBCOS').CFG_VALOR1;
    if (MARPREBCOS === 1) { // marcar el precio bajo el costo
      if (item.DETFAC_TIPODET === 'A') { // si es artículo cambiar el color del precio y la cantidad
        // colorear el precio bajo el costo
        if (item.DETFAC_CODIGO.length > 0) {
          if (item.DETFAC_TIPODET !== 'Y') {
            /*const data = await this.encfacService.obtenerCostoPromedio(item
              .DETFAC_CODIGO, strFecha, strHora);
            for (const rs of data) {
              dblCostoProm = rs.COSTO;
            }*/
            item.COSTOPROM = dblCostoProm;
            this.aggrid.refreshaggrid(this.encfacService.detfacarray[this.indicador], this.defaultColDef.objeto);
          }
        }
      }
    }

    const PREBAJCOM = this.encfacService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PREBAJCOM').CFG_VALOR1;

    // Si esta activado Marcar los precios que estan bajo el costo de compra
    if (PREBAJCOM === 1) { // marcar el precio bajo el costo

      if (item.DETFAC_TIPODET === 'A') { // si es artículo cambiar el color del precio y la cantidad

        // colorear el precio bajo el costo
        if (item.DETFAC_CODIGO.length > 0) {
          if (item.DETFAC_TIPODET !== 'Y') {
            const data = await this.encfacService.obtenerCostoCompraGen(item
              .DETFAC_CODIGO, strFecha, strHora);
            for (const rs of data) {
              dblCostoProm = rs.COSTO;
            }
            item.COSTOPROM = dblCostoProm;
          }
        }
      }
    }
  }
  async comprobarExistencia(item) {
    let dblCantMin = 0;
    let dblExist = 0;

    if (item.DETFAC_TIPODET === 'A') { // si es artículo cambiar el color del precio y la cantidad
      if (item.DETFAC_TIPODET !== 'Y') {
        const data = await this.encfacService.obtenerCantMinArt(item
          .DETFAC_CODIGO, item.BOD_CODIGO);
        for (const rs of data) {
          dblCantMin = rs.ARTEXT_MINIMA;
          // dblExist = rs.EXISTENCIA;
        }
        item.ART_CANTMIN = dblCantMin;
        //item.EXISTENCIA = dblExist;
      }
    }
  }
  extraerNumeroPedidos(strNumFact, tipo) {
    this.numeroPedidos = [];
    this.encfacService.ecExtraerNumeroPedidos(strNumFact).subscribe(data => {
      for (const rs of data) {
        this.numeroPedidos.push(rs.ENCPED_NUMERO);
      }

      if (tipo === 'A' || tipo === 'G') {
        if (this.numeroPedidos.length > 0) {
          this.verificarActualizarEstPed(); // actualiza el estado del pedido
          this.actualizarFechaEntregaPed();
          this.actualizarDespachoPed();

          if (this.intACTPEDVAL === 1) {
            this.verificarActualizarEstPedFactu(); // actualiza el estado del pedido
            this.actualizarFacturadoPed();
          }
        }
      }

      if (tipo === 'S') {
        for (const pedido of this.numeroPedidos) {
          this.cantidadsobrantepedidos(pedido);
        }
      }
    });
  }
  actualizarDespachoPed() {
    for (let fila = 1; fila <= this.numeroPedidos.length; fila++) {
      this.encfacService.actualizaDespachoPedido(this.numeroPedidos[fila - 1]).subscribe();
    }
  }
  actualizarFacturadoPed() {
    for (let fila = 1; fila <= this.numeroPedidos.length; fila++) {
      this.encfacService.actualizaFacturadoPedido(this.numeroPedidos[fila - 1]).subscribe();
    }
  }
  verificarActualizarEstPed() {
    for (const pedido of this.numeroPedidos) {
      this.encfacService.verificarActualizarEstPed(pedido).subscribe();
    }
  }
  verificarActualizarEstPedFactu() {
    for (const pedido of this.numeroPedidos) {
      this.encfacService.verificarActualizarEstPedFact(pedido).subscribe();
    }
  }
  actualizarFechaEntregaPed() {
    for (const pedido of this.numeroPedidos) {
      // extraer la fecha de entrega

      let strFechaEnt = '';
      this.encfacService.ecActualizarFechaEntregaPed(pedido).subscribe(data => {
        for (const rs of data) {
          strFechaEnt = this.datePipe.transform(rs.FECHAEMISION, 'dd/MM/yyyy');
        }
        this.encfacService.ActualizaFechaEntrega(pedido, strFechaEnt).subscribe();
      });
    }
  }
  cantidadsobrantepedidos(pedido) {
    this.encfacService.consultarPedido(pedido).subscribe(dataped => {
      for (const det of this.encfacService.detfacarray[this.indicador]) {
        if (det.ENCPED_NUMERO !== null && det.ENCPED_NUMERO !== undefined && det.ENCPED_NUMERO !== '') {
          for (const rs of dataped) {
            if (rs.DETPED_LINEA === det.DETPED_LINEA && rs.DETPED_CODIGO === det.DETFAC_CODIGO) {
              let dblCantPend = 0;
              const dblCantFac = rs.CANTARTFACDELPEDIDO;
              const dblCantGuiaR = rs.CANTARTGUIADELPEDIDO;

              dblCantPend = rs.DETPED_CANTIDAD - dblCantFac
                - dblCantGuiaR + Number(det.DETFAC_CANTIDAD); // TOTAL DESPACHADO
              const dblCantFacActual = this.obtenerCantFacActual(rs.DETPED_CODIGO, rs.DETPED_LINEA,
                0, '', rs.ENCPED_NUMERO); // cantidad en los otros
              // items de la factura
              if (dblCantPend + dblCantFacActual > 0 || rs.DETPED_TIPODET === '*') {
                det.DETPED_CANTIDAD = rs.DETPED_CANTIDAD
                  - dblCantFac - dblCantGuiaR + dblCantFacActual;
              }
            }
          }
        }
      }
    });
  }
  obtenerCantFacActual(strArtCodigo, intLineaPedido,
    intLineaGuia, strEncGreCodigo, strEncPedNumero): number {
    let dblCant = 0;

    for (let fila = 1; fila <= this.encfacService.detfacarray[this.indicador].length; fila++) {
      const item = this.encfacService.detfacarray[this.indicador][fila - 1];

      if (item.DETFAC_TIPODET === 'A' || item.DETFAC_TIPODET === 'S' || item.DETFAC_TIPODET === 'C') {
        if (item.DETFAC_CODIGO === strArtCodigo) {
          if (intLineaPedido !== 0) {
            if (item.DETPED_LINEA === intLineaPedido
              && item.ENCPED_NUMERO === strEncPedNumero) {
              dblCant = dblCant + Number(item.DETFAC_CANTIDAD);
            }
          } else if (intLineaGuia !== 0) {
            if (item.DETGRE_LINEA === intLineaGuia
              && item.ENCGRE_NUMERO === strEncGreCodigo) {
              dblCant = dblCant + Number(item.DETFAC_CANTIDAD);
            }
          }
        }
      }
    }

    return dblCant;
  }
  colorearPrecio(precio) {
    if (precio.DETFAC_CODIGO !== '') {
      if (Number(precio.DETFAC_PRECIO) < Number(precio.COSTOPROM)) {
        return this.choosedColorPreBajoCosto;
      } else {
        return 'transparent';
      }
    } else {
      return 'transparent';
    }
  }

  ColorearTotal(total){
    if (total.DETFAC_CODIGO !== '' && total.DETFAC_TIPODET !=='*') {
      if (total.DETFAC_INCLUENDSCTO === 'S') {
        return this.choosedColorTotalIncluEnDscto;
      } else {
        return 'transparent';
      }
    } else {
      return 'transparent';
    }
  }
  colorearCantidad(existencia) {
    if (existencia.DETFAC_CODIGO !== '') {
      // console.log('Existencia ', existencia.EXISTENCIA);
      if (Number(existencia.EXISTENCIA) < Number(existencia.ART_CANTMIN)) {
        return this.choosedColorExisMin;
      } else {
        return 'transparent';
      }
    } else {
      return 'transparent';
    }
  }
}
