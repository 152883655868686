//
// SACI WEB
// Rutina: Maestro de Grupos
// Propietario: Wilson Ramos
// Modificado por: WR
// Fecha de creación: 2019
// Fecha de Modificación: 29-11-2019
//
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Invmaegrupo} from '../invinterfaces/invmaegrupo';
import {ConfirmationService, MessageService} from 'primeng/api';
import {InvgrupoService} from '../invservicios/invgrupo.service';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Component({
  selector: 'app-invmaegrupos',
  templateUrl: './invmaegrupos.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css'],
  preserveWhitespaces: false
})
export class InvmaegruposComponent implements OnInit, OnDestroy {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  gruposAux: Invmaegrupo[];
  grupoSeleccionado: Invmaegrupo;
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonRegresar: boolean;
  barraBotones2: boolean;
  subgrupoSeleccionado: Invmaegrupo;
  permisoGrupo: SegMaePermiso;
  subgruposAux: Invmaegrupo[];
  index: number;
  largo: string;
  public frameworkComponents;
  rowStyle;
  defaultColDefGrupo;
  defaultColDefSubGrupo;
  rowSelection = 'multiple';
  columnDefsGrupo = [
    {
      headerName: 'Código', field: 'GRUP_CODIGO', cellEditor: 'cellPrueba',  editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      // editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 30
          }
        }
    },
    {
      headerName: 'Nombre', field: 'GRUP_NOMBRE', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 50
          }
        }
    },
    {
      headerName: 'Valor 1', field: 'GRUP_VALOR1', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 10
          }
        }
    },
    {
      headerName: 'Valor 2', field: 'GRUP_VALOR2', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 10
            // regexp: /^[/]$|^[*]$/
          }
        }
    },
    {
      headerName: 'Valor 3', field: 'GRUP_VALOR3', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 10
            // regexp: /^[/]$|^[*]$/
          }
        }
    },
    {
      headerName: 'Valor 4', field: 'GRUP_VALOR4', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 10
            // regexp: /^[/]$|^[*]$/
          }
        }
    },
    {
      headerName: 'Factor', field: 'GRUP_FACTOR', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 10
            // regexp: /^[/]$|^[*]$/
          }
        }
    },
    {
      headerName: 'Inventario', field: 'CON_CODIGOACT', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 40
            // regexp: /^[/]$|^[*]$/
          }
        }
    },
    {
      headerName: 'Costo Ventas', field: 'CON_CODIGOGAS', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 40,
            opcionbuscar: true
            // regexp: /^[/]$|^[*]$/
          }
        }
    },
    {
      headerName: 'Ventas', field: 'CON_CODIGOING', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: true,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 40,
            opcionbuscar: true
            // regexp: /^[/]$|^[*]$/
          }
        }
    }];
  columnDefsSubGrupo = [
    {
      headerName: 'Código', field: 'GRUP_CODIGO', cellEditor: 'cellPrueba',  editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 30
          }
        }
    },
    {
      headerName: 'Nombre', field: 'GRUP_NOMBRE', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 50
          }
        }
    },
    {
      headerName: 'Factor', field: 'GRUP_FACTOR', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 10
          }
        }
    },
    {
      headerName: 'Inventario', field: 'CON_CODIGOACT', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 40
            // regexp: /^[/]$|^[*]$/
          }
        }
    },
    {
      headerName: 'Costo Ventas', field: 'CON_CODIGOGAS', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 40,
            opcionbuscar: true
            // regexp: /^[/]$|^[*]$/
          }
        }
    },
    {
      headerName: 'Ventas', field: 'CON_CODIGOING', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: true,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 40,
            opcionbuscar: true
            // regexp: /^[/]$|^[*]$/
          }
        }
    }];
  
  constructor(public grupoSer: InvgrupoService, private confirmationService: ConfirmationService,
              private messageService: MessageService, private errorService: ErroresBaseDatosService,
              private permisosService: PermisosService, private auditoriagral: AuditoriagralService,
              private confIniciales: ConfInicialesService, private init: NuevoComponentService) {
    this.agTable();
    this.grupoSer.invmaegrupos = [];
    this.grupoSer.invmaesubgrupos = [];
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefGrupo = {
      editable: true,
      width: 200,
      objeto: 'maegrupo'
    };
    this.defaultColDefSubGrupo = {
      editable: true,
      width: 200,
      objeto: 'maesubgrupo'
    };
  }
  
  ngOnInit() {
    this.index = 0;
    this.permisosService.getListaPermisos('INV', '1', '24').subscribe((res) => {
      this.permisoGrupo = res[0];
    });
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonRegresar = true;
    this.largo = '400';
    this.cargarGrupos();
    
    this.barraBotones2 = true;
    this.grupoSeleccionado = {};
  }
  
  ngOnDestroy(): void {
    this.confIniciales.instanciaGrupo--;
  }
  
  cargarGrupos() {
    this.grupoSer.getGrupo().subscribe((res) => {
      if (res !== null) {
        this.grupoSer.invmaegrupos = res;
        this.grupoSer.invmaegrupos.map(item => {
          item.ESTADO = 'A'
        });
        console.log(this.columnDefsGrupo);
        this.grupoSeleccionado = this.grupoSer.invmaegrupos[this.grupoSer.invmaegrupos.length - 1];
        /* if (this.grupoSer.invmaegrupos.length !== 0) {
           this.grupoSer.getSubgrupo(this.grupoSer.invmaegrupos[this.grupoSer.invmaegrupos.length - 1].GRUP_CODIGO).subscribe(
             (respuesta) => { this.grupoSer.invmaesubgrupos = respuesta;
             });
         }*/
      }
    });
  }
  
  seleccionGrupo(params) {
    if (params.object !== undefined) {
      this.grupoSeleccionado = params.object;
    }
    
    /*if (this.grupoSeleccionado !== undefined) {
      this.grupoSer.getSubgrupo(this.grupoSeleccionado.GRUP_CODIGO).subscribe((res) => {
        this.grupoSer.invmaesubgrupos = res;
      });
    } else {
      return;
    }*/
  }
  
  seleccionSubGrupo(params) {
    if (params.object !== undefined) {
      this.subgrupoSeleccionado = params.object;
    }
  }
  
  cambio(cambio) {
    if (cambio === true) {
      this.botonGuardar = false;
      this.botonRegresar = false;
      this.botonNuevo = true;
    }
  }
  
  restablecerButtons() {
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonRegresar = true;
    
  }
  
  
  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      if (this.permisoGrupo.PERINSERCION === 1) {
        if (this.index === 0) {
          this.nuevoGrupo();
          console.log('nuevo 0 ');
        }
        if (this.index === 1) {
          if (this.grupoSeleccionado !== undefined) {
            this.nuevoSubgrupo();
            console.log('nuevo 1 ');
            
          }
        }
        
      } else if (this.permisoGrupo.PERINSERCION === 0) {
        this.messageService.add({
          key: 'grup',
          severity: 'error',
          summary: 'Inserción de Grupo',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    } else if (valor === 'Guardar') {
      if (this.permisoGrupo.PERACTUALIZACION === 1) {
        if (this.index === 0) {
          this.botonNuevo = false;
          this.botonRegresar = true;
          this.botonGuardar = true;
          // this.aggrid.isEditable(false);
          this.grupoSer.saveGrupo(this.grupoSeleccionado);
        } else if (this.index === 1) {
          this.botonNuevo = false;
          this.botonRegresar = true;
          this.botonGuardar = true;
          this.grupoSer.saveSubGrupo(this.subgrupoSeleccionado, this.grupoSeleccionado);
          // this.aggrid.isEditable(false);
          // this.aggrid.refreshaggrid(this.grupoSer.invmaesubgrupos, this.defaultColDefSubGrupo.objeto);
          // console.log('refresh subgr');
        } else if (this.index === 2) {
          this.botonNuevo = false;
          this.botonRegresar = true;
          this.botonGuardar = true;
          this.guardarGrupo(this.grupoSeleccionado);
          // this.aggrid.isEditable(false);
        }
      } else if (this.permisoGrupo.PERACTUALIZACION === 0) {
        this.messageService.add({
          key: 'grup',
          severity: 'error',
          summary: 'Actualización de Grupo',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }
    } else if (valor === 'Borrar') {
      if (this.permisoGrupo.PERELIMACION === 1) {
        if (this.grupoSeleccionado === undefined) {
          this.messageService.add({
            key: 'grup',
            severity: 'error',
            summary: 'Eliminacion de Grupo',
            detail: 'Selección Incorrecta, no se ha seleccionado un grupo'
          });
        } else {
          this.confirmarBorrar();
          // this.aggrid.isEditable(false);
        }
      } else if (this.permisoGrupo.PERELIMACION === 0) {
        this.messageService.add({
          key: 'grup',
          severity: 'error',
          summary: 'Eliminación de Grupo',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
      }
    }
    
    if (valor === 'Cancelar') {
      this.cancelarCambios();
      // this.aggrid.isEditable(false);
    }
    
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
    
    
  }
  
  confirmarBorrar() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el grupo',
      header: 'Eliminar Grupo',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarGrup',
      accept: () => {
        this.borrarGrupo();
      },
      reject: () => {
      }
    });
  }
  
  nuevoGrupo() {
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonRegresar = false;
    
    /* let numeroGrupo: number;
     if (this.grupoSer.invmaegrupos.length === 0) {
       numeroGrupo = 0;
     } else {
       numeroGrupo = Number(this.grupoSer.invmaegrupos[this.grupoSer.invmaegrupos.length - 1].GRUP_CODIGO);
     }*/
    
    const grupoNuevo: Invmaegrupo = {
      GRUP_CODIGO: '', GRUP_NOMBRE: '', CON_CODIGO: '', GRUP_CODIGOP: ''
      , CEN_CODIGO: '', GRUP_VALOR1: '', GRUP_VALOR2: '', GRUP_VALOR3: '', GRUP_VALOR4: '', GRUP_FACTOR: '', CON_CODIGOACT: '',
      CON_CODIGOGAS: '', CON_CODIGOING: '', GRUP_GARANTIAS: '', GRUP_OTROSCON: '', ESTADO: 'N'
    };
    
    this.grupoSer.invmaegrupos.push(grupoNuevo);
    this.aggrid.refreshaggrid(this.grupoSer.invmaegrupos, this.defaultColDefGrupo.objeto);
    this.grupoSeleccionado = this.grupoSer.invmaegrupos[this.grupoSer.invmaegrupos.length - 1];
    
    
    // this.aggrid.isEditable(true);
  }
  
  nuevoSubgrupo() {
    
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonRegresar = false;
    
    const subgrupoNuevo: Invmaegrupo = {
      GRUP_CODIGO: '', GRUP_NOMBRE: '', CON_CODIGO: '', GRUP_CODIGOP: this.grupoSeleccionado.GRUP_CODIGO
      , CEN_CODIGO: '', GRUP_VALOR1: 0, GRUP_VALOR2: 0, GRUP_VALOR3: 0, GRUP_VALOR4: 0, GRUP_FACTOR: '', CON_CODIGOACT: '',
      CON_CODIGOGAS: '', CON_CODIGOING: '', GRUP_GARANTIAS: '', GRUP_OTROSCON: '', ESTADO: 'N'
    };

    const indi =  this.grupoSer.invmaesubgrupos.indexOf(this.subgrupoSeleccionado);
   
    if (indi === -1) {
      this.grupoSer.invmaesubgrupos.push(subgrupoNuevo);
      this.subgrupoSeleccionado= this.grupoSer.invmaesubgrupos
        [this.grupoSer.invmaesubgrupos.length - 1];
      this.aggrid.refreshaggrid(this.grupoSer.invmaesubgrupos, this.defaultColDefSubGrupo.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefSubGrupo.objeto].setFocusedCell(
        this.grupoSer.invmaesubgrupos.length - 1, 'GRUP_CODIGO');
    } else {
      this.grupoSer.invmaesubgrupos.splice(indi + 1, 0, subgrupoNuevo);
      this.aggrid.refreshaggridindex(this.grupoSer.invmaesubgrupos, this.defaultColDefSubGrupo.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefSubGrupo.objeto].setFocusedCell(
        indi + 1, 'GRUP_CODIGO');
    }
    
    
   /*  this.grupoSer.invmaesubgrupos.push(subgrupoNuevo);
    this.aggrid.refreshaggrid(this.grupoSer.invmaesubgrupos, this.defaultColDefSubGrupo.objeto); */
    // this.aggrid.refreshColumnDefs();
    
    // this.aggrid.isEditable(true);
    
  }
  
  cancelarCambios() {
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonRegresar = true;
    if (this.index === 0) {
      this.grupoSer.getGrupo().subscribe((respuesta) => {
        this.gruposAux = respuesta;
        this.grupoSer.invmaegrupos = this.gruposAux;
        this.aggrid.refreshaggrid(this.grupoSer.invmaegrupos, this.defaultColDefGrupo.objeto);
        
      });
    } else if (this.index === 1) {
      this.grupoSer.getSubgrupo(this.grupoSeleccionado.GRUP_CODIGO).subscribe(res => {
        this.subgruposAux = res;
        this.grupoSer.invmaesubgrupos = this.subgruposAux;
        this.aggrid.refreshaggrid(this.grupoSer.invmaesubgrupos, this.defaultColDefSubGrupo.objeto);
      });
    }
  }
  
  guardarGrupo(grupo) {
    this.botonNuevo = false;
    this.botonRegresar = true;
    this.botonGuardar = true;
    this.grupoSer.insertarExtras(grupo).subscribe(() => {
      this.messageService.add({
        key: 'grup',
        severity: 'success',
        summary: 'Inserción de Extras',
        detail: 'El valor se insertó correctamente'
      });
    });
  }
  
  borrarGrupo() {
    if (this.index === 0) {
      if (this.grupoSeleccionado === undefined) {
        this.grupoSer.getSubgrupo(this.grupoSer.invmaegrupos[this.grupoSer.invmaegrupos.length - 1].GRUP_CODIGO).subscribe((res) => {
          if (res.length === 0) {
            this.grupoSer.borrarGrupo(this.grupoSer.invmaegrupos[this.grupoSer.invmaegrupos.length - 1].GRUP_CODIGO,
              this.grupoSer.invmaegrupos[this.grupoSer.invmaegrupos.length - 1].GRUP_CODIGOP)
              .subscribe(() => {
                this.messageService.add({
                  key: 'grup',
                  severity: 'success',
                  summary: 'Eliminación de Grupo',
                  detail: 'El grupo se eliminó correctamente'
                });
                this.auditoriagral.registrarAuditoria('INV_MAEGRUPOS', String(this.grupoSeleccionado.GRUP_CODIGO), 'E', '',
                  '01', '', '', '', '').subscribe(() => {
                });
                this.grupoSer.invmaegrupos = this.grupoSer.invmaegrupos.filter((val, j) => j !==
                  this.grupoSer.invmaegrupos.indexOf(this.grupoSer.invmaegrupos[this.grupoSer.invmaegrupos.length - 1]));
              });
            return;
          } else {
            this.messageService.add({
              key: 'grup',
              severity: 'error',
              summary: 'Eliminación de Grupo',
              detail: 'El grupo tiene subgrupos y no se puede eliminar'
            });
          }
        }, error1 => {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
          this.messageService.add({
            key: 'grup',
            severity: 'error',
            summary: 'Eliminación de SubGrupo',
            detail: mensaje
          });
        });
      }
      this.botonNuevo = false;
      this.botonRegresar = true;
      this.botonGuardar = true;
      let contador = 0;
      let i = 0;
      do {
        if (this.grupoSeleccionado.GRUP_CODIGO === this.grupoSer.invmaegrupos[i].GRUP_CODIGO) {
          contador++;
        }
        i++;
      } while (this.grupoSer.invmaegrupos.length > i);
      if (contador === 1) {
        this.grupoSer.getSubgrupo(this.grupoSeleccionado.GRUP_CODIGO).subscribe((res) => {
          if (res.length === 0) {
            this.grupoSer.borrarGrupo(this.grupoSeleccionado.GRUP_CODIGO, this.grupoSeleccionado.GRUP_CODIGOP).subscribe(() => {
              this.messageService.add({
                key: 'grup',
                severity: 'success',
                summary: 'Eliminación de Grupo',
                detail: 'El grupo se eliminó correctamente'
              });
              this.auditoriagral.registrarAuditoria('INV_MAEGRUPO', String(this.grupoSeleccionado.GRUP_CODIGO), 'E', '',
                '01', '', '', '', '').subscribe(() => {
              });
              this.grupoSer.invmaegrupos = this.grupoSer.invmaegrupos.filter((val, j) => j !==
                this.grupoSer.invmaegrupos.indexOf(this.grupoSeleccionado));
            });
          } else {
            this.messageService.add({
              key: 'grup',
              severity: 'error',
              summary: 'Eliminación de Grupo',
              detail: 'El grupo tiene subgrupos y no se puede eliminar'
            });
          }
        }, error1 => {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
          this.messageService.add({
            key: 'grup',
            severity: 'error',
            summary: 'Eliminación de SubGrupo',
            detail: mensaje
          });
        });
      } else if (contador !== 1) {
        this.grupoSer.invmaegrupos = this.grupoSer.invmaegrupos.filter((val, j) => j !==
          this.grupoSer.invmaegrupos.indexOf(this.grupoSeleccionado));
      }
    } else if (this.index === 1) {
      if (this.subgrupoSeleccionado === undefined) {
        this.grupoSer.borrarGrupo(this.grupoSer.invmaesubgrupos[this.grupoSer.invmaesubgrupos.length - 1].GRUP_CODIGOP,
          this.grupoSer.invmaesubgrupos[this.grupoSer.invmaesubgrupos.length - 1].GRUP_CODIGO).subscribe(() => {
          this.messageService.add({
            key: 'grup',
            severity: 'success',
            summary: 'Eliminación de SubGrupo',
            detail: 'El Subgrupo se eliminó correctamente'
          });
          this.grupoSer.invmaesubgrupos = this.grupoSer.invmaesubgrupos.filter((val, j) =>
            j !== this.grupoSer.invmaesubgrupos.indexOf(this.grupoSer.invmaesubgrupos[this.grupoSer.invmaesubgrupos.length - 1]));
        }, error1 => {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
          this.messageService.add({
            key: 'grup',
            severity: 'error',
            summary: 'Eliminación de SubGrupo',
            detail: mensaje
          });
        });
        return;
      }
      this.botonNuevo = false;
      this.botonRegresar = true;
      this.botonGuardar = true;
      let contador = 0;
      let i = 0;
      do {
        if (this.subgrupoSeleccionado.GRUP_CODIGO === this.grupoSer.invmaesubgrupos[i].GRUP_CODIGO) {
          contador++;
        }
        i++;
      } while (this.grupoSer.invmaesubgrupos.length > i);
      if (contador === 1) {
        this.grupoSer.borrarGrupo(this.subgrupoSeleccionado.GRUP_CODIGOP, this.subgrupoSeleccionado.GRUP_CODIGO).subscribe(
          () => {
            this.messageService.add({
              key: 'grup',
              severity: 'success',
              summary: 'Eliminación de SubGrupo',
              detail: 'El Subgrupo se eliminó correctamente'
            });
            this.grupoSer.invmaesubgrupos = this.grupoSer.invmaesubgrupos.filter((val, j) =>
              j !== this.grupoSer.invmaesubgrupos.indexOf(this.subgrupoSeleccionado));
          },
          error1 => {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.messageService.add({
              key: 'grup',
              severity: 'error',
              summary: 'Eliminación de SubGrupo',
              detail: mensaje
            });
          }
        );
      } else if (contador !== 1) {
        this.grupoSer.invmaesubgrupos = this.grupoSer.invmaesubgrupos.filter((val, j) =>
          j !== this.grupoSer.invmaesubgrupos.indexOf(this.subgrupoSeleccionado));
      }
    }
  }
  
  cambiarIndices() {
    if (this.index === 0) {
      this.restablecerButtons();
      this.cargarGrupos();
      this.grupoSeleccionado = {};
      this.aggrid.refreshaggrid(this.grupoSer.invmaegrupos, this.defaultColDefGrupo.objeto);
      console.log('com 1.1 ');
    }
    if (this.index === 1) {
      if (this.grupoSeleccionado !== undefined) {
        this.grupoSer.getSubgrupo(this.grupoSeleccionado.GRUP_CODIGO).subscribe((res) => {
          console.log('subgru', res);
          this.grupoSer.invmaesubgrupos = res;
          this.grupoSer.invmaesubgrupos.map(item => {
            item.ESTADO = 'A'
          });
          this.aggrid.refreshaggrid(this.grupoSer.invmaesubgrupos, this.defaultColDefSubGrupo.objeto);
          
        });
        console.log('com 2.0 ');
        this.restablecerButtons();
        
      }
    }
    if (this.index === 2) {
      this.restablecerButtons();
      console.log('com 3 ');
    }
  }

  isEdit(data): boolean {
    if (data.ESTADO === 'N') {
      return true;
    } else {
      return false;
    }
    
    
  }
  
}
