import { Component, OnInit } from '@angular/core';
import { ImportadatosexcelService } from '../nomservicios/importadatosexcel.service';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { MessageService, ConfirmationService } from 'primeng';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';

@Component({
  selector: 'app-importadatosexcel',
  templateUrl: './importadatosexcel.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class ImportadatosexcelComponent implements OnInit {
  btnVisto = true;
  cmbTipoCarga = [];
  selectCarga;
  descripciones: string[] = [];
  datosJson;
  constructor( 
    private importExcelSrv: ImportadatosexcelService,
    private errorService: ErroresBaseDatosService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private datePipe: DatePipe,
    private init: NuevoComponentService,
   ) { }

  ngOnInit(): void {
    this.funListaMaestroCargas();
  }




  async funListaMaestroCargas(){
    let carga = {
      COM_CODIGO: null,
      MAECARGEXCE_CODIGO: null,
      MAECARGEXCE_NOMBRE: null,
    }
    await this.importExcelSrv.funListaMaestroCargas().then( res => {
        
        console.log(res);
        this.cmbTipoCarga = [carga, ...res];

    } ).catch( e=>  {
      let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);

    });
  }

  seleccionarCarga({value}){
    console.log(value);
  }

  manejarSenales(valor){
    console.log(valor);
    switch (valor) {
      case 'Salir':
       this.init.tabs.splice(this.init.tabindex, 1);
      break;
      case 'Visto':
        if(  this.selectCarga === undefined ){
          this.mensajeAlerta('error', 'Error', 'Seleccione una opción');
        }else if (  this.selectCarga.MAECARGEXCE_CODIGO === null)  {
          this.mensajeAlerta('error', 'Error', 'Seleccione una opción');
        } else {
          this.eliminarDatosAnteriors();
        }
      break;
      default:
      break;
    }
  }

  onBasicUpload(ev){
    let validacionVacio = true;
    console.log(ev.files[0]);

    let workBook = null;
    let jsonData = null;
    //let aprobado = t;
    
    const reader = new FileReader();

    const file = ev.files[0];

    reader.onload = async (event) => {
      const data = reader.result;
      workBook = XLSX.read( data, { type: 'binary', cellDates: true, cellNF: false, cellText: false} );

      jsonData = workBook.SheetNames.reduce( (initial, name) => {
        console.log(workBook.Sheets[name]);
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet, {defval: ''});
        return initial;
      } , {});
      console.log(jsonData.Hoja1);
      


      for( let data of jsonData.Hoja1 ){
        
        delete data.__EMPTY;
        for(let key in data){ // se revisa los campos del json generado del excel,
                              // si uno de esos campos no existe o está vacío no se activará el boton de guardar
          if( data[key] === '' ){
            validacionVacio = false;
          }
        
        }
        
        
      }

      /*if( !validacionVacio ){
        this.confirmationService.confirm({
          message: 'Existen una o más celdas vacías.¿Desea continuar?',
          header: 'Confirmación',
          icon: 'pi pi-exclamation-triangle',
          key: 'importDatosExc',
          acceptLabel: 'Sí',
          rejectLabel: 'No',
          accept: () => {
            
            this.datosJson  =  jsonData.Hoja1;
            this.btnVisto = false; 
          
           
          },
          reject: () => {
          }
        })
      } else {
        this.datosJson  =  jsonData.Hoja1;
      }*/
      this.datosJson  =  jsonData.Hoja1;

      /* if( !aprobado){ 
        this.btnVisto = true; 
      }else {
        this.datosJson  =  jsonData.Hoja1;
        this.btnVisto = false; 
      } */
    }

    reader.readAsBinaryString(file);

  }

  async eliminarDatosAnteriors(){
     return await this.importExcelSrv.eliminarDatosAnteriors().then( () => {
       this.leerDatosJson();
     } ) .catch( e=>  {
      this.mensajeAlerta('error', 'Error', 'Hubo un problema');

    });
  }

  async leerDatosJson( ){
    let resolve = true;
    let fila = 1;
    for( let data of this.datosJson ){
      let descripcionArray = [];
      let descripcion = '';
      delete data.__EMPTY;
      /* let nuevoJson = {
        tipoId: '',
        codigo: '',
        rubro: '',
        valor: '',
        tipo_det: '',
        fecha: '',
        descripcion: ''
      } */
      
      /* 
      console.log('-------------------')
      for(let key in data){
        if( key.toLocaleUpperCase() === 'FECHA' ){
          console.log('fecha', this.convertDate(data[key]));
          descripcion = descripcion + this.convertDate(data[key]) + ';'
        }else if(data.hasOwnProperty(key)){
            console.log(data[key])
            descripcion = descripcion + data[key] + ';'
          } 
      } */

      for(let key in data){
        if( String(data[key]).includes('GMT-0500 (hora de Ecuador)') ){// si contiene un dato de fecha
          descripcion = descripcion + this.convertDate(data[key])+ ';';
        }else {

          descripcion = descripcion + String(data[key])+ ';';
        }
        
      }

      
      
      //descripcion = `${nuevoJson.tipoId}${nuevoJson.codigo}${nuevoJson.rubro}${nuevoJson.valor}${nuevoJson.tipo_det}${nuevoJson.fecha}${nuevoJson.descripcion}`;
      console.log(descripcion, fila);

      await this.importExcelSrv.insertar( this.stringFechaNowLarga(), descripcion, fila, this.selectCarga.MAECARGEXCE_CODIGO ).catch( e=>  {
        let mensaje = `Hubo un problema guardando la fila ${fila}`;
        this.mensajeAlerta('error', 'Error', mensaje);
        resolve = false;
      });

      fila++;
    }

    if(resolve){
      this.funDistrDatosImportados();
    }
  
  }

  stringFechaNowLarga(){
    let fecha = new Date();
    let date = this.datePipe.transform(fecha, 'dd/MM/yyyy');
    let hours = this.datePipe.transform(fecha, 'hh:mm:ss');
    let dateSplit = date.split("/");
    let hoursSplit = hours.split(":");
    return `${dateSplit[0]}${dateSplit[1]}${dateSplit[2]}${hoursSplit[0]}${hoursSplit[1]}${hoursSplit[2]}`;
  }

  convertDate(date: string) {
    if( date === '' || date === undefined || date === null){
      
      return '';
      
    } 
      

      const fecha = new Date( date );
  
      let month = '' + (fecha.getMonth() + 1);
      let day = '' + fecha.getDate();
      let year = fecha.getFullYear();
  
      if( month.length < 2 ) { month = '0' + month }
      if( day.length < 2 ) { day = '0' + day }
  
      return `${day}/${month}/${year}`;
  }

  async guardar(  descri: string,fila: number, opctab ){
   return ;

  }


  async funDistrDatosImportados(){
    await this.importExcelSrv.funDistrDatosImportado(this.selectCarga.MAECARGEXCE_CODIGO).then( () =>{
      console.log('TODO OK');
      this.mensajeAlerta('success', 'Éxito', `La importación terminó con exito`);
      this.btnVisto = true;
    } ) .catch( e=>  {
      console.log(e)
      let mensaje = 'La importación no terminó con exito ';
      this.mensajeAlerta('error', 'Error', mensaje);
    });
  }

  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'importExcel',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
  }


}
