//
// SACI WEB
// Rutina: Maestro de proveedores
// Propietario: Andrés Ruiz
// Modificado por: AR
// Fecha de creación: 27-11-2019
// Fecha de Modificación: 29-11-2019
//
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { Commaeprovedor } from '../cominterfaces/commaeproveedor';
import { CommaeADM } from '../cominterfaces/tablaADM';
import { CommaeEC } from '../cominterfaces/proveedorTablaEC';
import { CommaeproveedorService } from '../comservicios/commaeproveedor.service';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { DatePipe } from '@angular/common';
import { CommaeRetencion } from '../cominterfaces/proveedorRetencion';
import { Comtrnformapago } from '../cominterfaces/proveedorFormapago';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';

// Ag-grid
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { ComExistenciaBodegas, ComExistencias, ComMovimientoProv, ComTopProductos } from '../cominterfaces/comestadisticas';
import { Cxctmptrnresumen } from '../../ven/veninterfaces/cxctmptrnresumen';
import { Cxctrnresumen } from '../../ven/veninterfaces/cxctrnresumen';
import { ComestadisticasService } from '../comservicios/comestadisticas.service';
import { Usuario } from 'src/app/usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';

@Component({
  selector: 'app-commaeproveedor',
  templateUrl: './commaeproveedor.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class CommaeproveedorComponent implements OnInit {
  index: number;
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  barraBotones2: boolean;
  btnNuevo: boolean;
  btnBorrar: boolean;
  btnBuscar: boolean;
  btnRegresar: boolean;
  regpais: boolean;
  flagCashM: boolean;
  paraisofiscal: boolean;
  denominacion: boolean;
  codnombre: string;
  FECHA_DESDE?;
  FECHA_HASTA?;
  strFecha?;

  cmbcampoaux: any[];
  banderaCheks: boolean;
  auxContEC: number;
  debeTotal: number;
  haberTotal: number;
  codigoTipo: number;
  totalimporte: number;
  totalpagado: number;
  totalsaldo: number;


  cmbTipoRegFiscal: any[];
  selecttiporegfis: any;

  cmbdeno: any[];
  selectdeno: any;

  cmbparteR: any[];
  selectparter: any;

  cmbcontri: any[];
  selectcontri: any;

  listaSaldos: CommaeEC[];
  retencionSeleccionada: CommaeRetencion;
  nuevoCampo: boolean;
  opcionbusqueda: string;
  btnGuardar: boolean;
  bandera: boolean;
  btnPrimero: boolean;
  btnAnterior: boolean;
  btnSiguiente: boolean;
  btnUltimo: boolean;
  inputNum: boolean;
  btnNuevoGrid: boolean;
  btnGuardarGrid: boolean;
  btnCancelarGrid: boolean;
  indice: number;
  opcion: string;
  nuevoproveedor: boolean;

  lblvalida: string = '';
  cmbide: any[];
  cmbidecash:any[];
  selectide: any;
  selectideti: any;
  selectideticash:any;
  cmbformap: any[];
  cmbformapaux: any[];
  cmbentiF: any[];
  cmbentiFaux: any[];
  cmbanex1: any[];
  cmbanex2: any[];
  cmbanexaux: any[];
  etiquetasaudi:any[];
  cmbtipoC: any[];
  cmbtipoCaux: any[];
  descripcion: string[];
  actualizar: boolean;
  listaNomForm: any[];
  listaNomEnti: any[];
  listaNomTipoC: any[];
  valorRucI: boolean;
  chkfacelectro: boolean;
  chktransporte: boolean;
  chkconregmic: boolean;
  chkconregrimpe: boolean;
  chkagenteret: boolean;
  flagFormadePago: boolean;

  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];
  tabla: string;
  grupnombre: string;
  zonanombre: string;
  cennombre: string;
  connombre: string;
  conlnombre: string;
  conpaiscod: string;
  conpaisnombre: string;
  conepaiscod: string;
  conepaisnombre: string;
  conparaisocod: string;
  conparaisonombre: string;
  con2nombre: string;
  COM_DIFBENFCMAUX: string;
  PRO_IDCMAUX: string;
  COM_BENFCMAUX: string;
  displayDialogBusqueda: boolean;
  displayDialogBusquedaFast: boolean;
  boolcmbtiporegimen: boolean;//CDPJ
  selectconvdobletrib: any;
  selectpagoexterior: any;
  regdeno: boolean;
  //CDPJ
  rowSelection = 'multiple';
  codigoAnt: string;
  valorcedulaI: boolean;
  permisoProveedor: SegMaePermiso;
  cxctmptrnresumen: Cxctmptrnresumen[];
  cxctrnresumen: Cxctrnresumen[];
  bolestcta: boolean;
  bolsaldo: boolean;
  proveedorSeleccionado: Commaeprovedor;
  proveedorSeleccionadoAux: Commaeprovedor;
  proveedorADM: CommaeADM;
  proveedorFormapago: Comtrnformapago;

  public frameworkComponents;
  rowStyle;
  tipodetalle = '';
  defaultProv;
  defaultProvS;
  defaultProvEC;
  defaultProvMv;
  defaultProvRt;
  defaultProvFp;

  largo: string;
  largo1: string;

  // ESTADISTICAS

  detTopProductos: ComTopProductos[] = [];
  dataComprasProveedor: any;
  dataVentasVsCompras: any;
  disabledEstadisticas = true;
  options = {
    legend: {
      display: false
    }
  };
  defaultColTopProductos;
  defaultColMovByProv;
  defaultColExistBod;
  rowStyleTopProd;
  detalleExistencia: ComExistencias[] = [];
  detMovimientosProv: ComMovimientoProv[] = [];
  detExistenciaBodegas: ComExistenciaBodegas[] = [];
  txtArtCodigo = '';
  txtArtDescripcion = '';
  txtArtUnidad = '';
  txtArtRotacion = '';
  txtExistenciaMin: any = '';
  txtExistenciaMax: any = '';
  txtInventarioIni: any = '';
  txtComprasPeriodo: any = '';
  txtTotalDisponible: any = '';
  txtVenUltimoPer: any = '';
  txtVenPromPerAnt: any = '';
  txtIndiceProvUltPer: any = '';
  txtIndiceProvPeriodos: any = '';
  txtComprasPlanificadas: any = '';
  txtTotalDisponibleCom: any = '';
  txtIndiceProvUltPer2: any = '';
  txtIndiceProvPeriodos2: any = '';

  displayDialogDocumentos = false;

  // autorizacion
  claveAuth: string;
  autorizacionCodigo: string;
  bolAutorizacion: boolean;
  displayDialogAutorizacion: boolean;

  columnDefsProv = [
    {
      headerName: 'TIPO', field: 'CODSRI_DESCRIPCION', cellEditor: 'agSelectCellEditor',
      // , editable: true,
      cellEditorParams:
      {
        values: []


      }
    },
    {
      headerName: 'Fechaini', field: 'AUTSRI_FECHAINI', cellEditor: 'cellPrueba', editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: true,
          tamanocelda: 10,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'FechaFin', field: 'AUTSRI_FECHAFIN', cellEditor: 'cellPrueba', editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: true,
          tamanocelda: 10
        }
      }
    },
    {
      headerName: 'Estab.', field: 'AUTSRI_SERIEESTAB', cellEditor: 'cellPrueba', editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 3
        }
      }
    },
    {
      headerName: 'PtoEmisión', field: 'AUTSRI_SERIEPTOEMI', cellEditor: 'cellPrueba', editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 3,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Desde', field: 'AUTSRI_SECUENCIALINI', cellEditor: 'cellPrueba', editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 9,
          tienecalculos: true

        }
      }
    },
    {
      headerName: 'Hasta', field: 'AUTSRI_SECUENCIALFIN', cellEditor: 'cellPrueba', editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 9,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Autorización', field: 'AUTSRI_AUTORIZASRI', cellEditor: 'cellPrueba', editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 50
        }
      }
    },
    {
      headerName: 'AutoImpreso', field: 'AUTSRI_AUTORIZAIMP', cellEditor: 'cellPrueba', editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 5
        }
      }
    }
  ];

  columnDefsCxctmptrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC', width: 100,
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Debe', field: 'DEBE', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Haber', field: 'HABER', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: { textAlign: 'right' },
    },
  ];

  columnDefsCxctrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC'
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Importe', field: 'TRNCOBRO_IMPORTE', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Pagado', field: 'PAGADO', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: { textAlign: 'right' },
    },
  ];

  columnDefsMovimiento = [
    {
      headerName: 'Fecha', field: 'FECHA_DOC', cellEditor: 'cellPrueba', width: 100,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: true,
          clave: true
        }
      }
    },
    {
      headerName: 'TIPO', field: 'ORIGEN_DOC', cellEditor: 'cellPrueba', width: 100,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: true
        }
      }
    },
    {
      headerName: 'Número Doc.', field: 'NUMERO_DOC', cellEditor: 'cellPrueba', width: 140,
      // , editable: true, CODSRI_DESCRIPCION AUTSRI_FECHAINI AUTSRI_FECHAFIN AUTSRI_SERIESTAB
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: true
        }
      }
    },
    {
      headerName: 'Bodega', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 140,
      // , editable: true, CODSRI_DESCRIPCION AUTSRI_FECHAINI AUTSRI_FECHAFIN AUTSRI_SERIESTAB
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: true
        }
      }
    },
    {
      headerName: 'Código Art.', field: 'ART_CODIGO', cellEditor: 'cellPrueba', width: 100,
      // , editable: true, CODSRI_DESCRIPCION AUTSRI_FECHAINI AUTSRI_FECHAFIN AUTSRI_SERIESTAB
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: true
        }
      }
    },
    {
      headerName: 'Nombre Art.', field: 'ART_NOMBRE', cellEditor: 'cellPrueba', width: 250,
      //
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: true
        }
      }
    },
    {
      headerName: 'Grupo', field: 'GRUP_CODIGO', cellEditor: 'cellPrueba', width: 100,
      //
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: true
        }
      }
    },
    {
      headerName: 'Cantidad', field: 'CANTIDAD', cellEditor: 'cellPrueba', width: 100,
      //
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: true
        }
      }
    },
    {
      headerName: 'Costo', field: 'COSTO', cellEditor: 'cellPrueba', width: 100,
      //
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: true
        }
      }
    },
    {
      headerName: 'Costo Total', field: 'COSTO_TOT', cellEditor: 'cellPrueba', width: 100,
      //
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: true
        }
      }
    }
  ];
  columnDefsRetencion = [
    {
      headerName: 'Código', field: 'RETENCION_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: true,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: true,
          tamanocelda: 5,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Nombre-Retención', field: 'RETENCION_NOMBRE', cellEditor: 'cellPrueba', width: 250, editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: true,
          tamanocelda: 25
        }
      }
    }
  ];
  columnDefsAnexos = [
    {
      headerName: 'Código', field: 'TRNFORMAPAGO_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: true,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: true,
          tamanocelda: 5,
          opcionbuscar: true,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Descripción', field: 'TRNFORMAPAGO_DESCRIPCION', cellEditor: 'cellPrueba', width: 250, editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: true,
          tamanocelda: 25
        }
      }
    }
  ];

  columnDefsTopProductos = [
    {
      headerName: 'Producto', field: 'DETFACPRO_CODIGO', width: 115, editable: false
    },
    {
      headerName: 'Descripción', field: 'DETFACPRO_DESCRIPCION', width: 190, editable: false
    },
    {
      headerName: 'Cantidad', field: 'CANTIDAD_TOTAL', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Precio', field: 'DETFACPRO_COSTO', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Total', field: 'TOTAL', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Stock', field: 'STOCK', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];

  columnDefsMovimientosByProv = [
    {
      headerName: 'Proveedor', field: 'PRO_CODIGO', width: 115, editable: false
    },
    {
      headerName: 'Nombre', field: 'PRO_NOMBRE', width: 190, editable: false
    },
    {
      headerName: 'Fecha Compra', field: 'ENCFACPRO_FECHAEMISION', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Costo', field: 'DETFACPRO_COSTO', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];

  columnDefsExistenciaBodegas = [
    {
      headerName: 'Código', field: 'BOD_CODIGO', width: 115, editable: false
    },
    {
      headerName: 'Nombre', field: 'BOD_NOMBRE', width: 190, editable: false
    },
    {
      headerName: 'Existencia', field: 'EXISTENCIA', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Pedidos', field: 'PEDIDO', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Saldo', field: 'SALDO', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];

  constructor(public proveedorServicio: CommaeproveedorService,
    private busqService: InvbusquedaService, private messageService: MessageService,
    private errorService: ErroresBaseDatosService, private permisosService: PermisosService,
    private auditoriagral: AuditoriagralService, private datePipe: DatePipe,
    private confirmationService: ConfirmationService, private init: NuevoComponentService,
    private venEstadisticasService: ComestadisticasService, public usuario: Usuario,
    private confIniciales: ConfInicialesService, private utilitariosService: UtilitariosService) {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.rowStyleTopProd = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if (params.data.DETFACPRO_CODIGO === '' || params.data.DETFACPRO_CODIGO === null) {
        return { background: 'rgb( 164, 255, 167)' };
      }
    };
    this.defaultProv = {
      editable: true,
      width: 130,
      objeto: 'commaeproveedorADM'
    };
    this.defaultProvEC = {
      editable: false,
      width: 100,
      objeto: 'commaeproveedorEC'
    };
    this.defaultProvS = {
      editable: false,
      width: 100,
      objeto: 'commaeproveedorS'
    };
    this.defaultProvMv = {
      editable: false,
      width: 100,
      objeto: 'commaeproveedorMv'
    };
    this.defaultProvRt = {
      editable: true,
      width: 100,
      objeto: 'commaeproveedorRt'
    };
    this.defaultProvFp = {
      editable: true,
      width: 100,
      objeto: 'commaeproveedorFP'
    };
    this.defaultColTopProductos = {
      editable: true,
      width: 100,
      objeto: 'topProductos',
      resizable: true
    };

    this.defaultColMovByProv = {
      editable: true,
      width: 100,
      objeto: 'movproveedores',
      resizable: true
    };

    this.defaultColExistBod = {
      editable: true,
      width: 100,
      objeto: 'existenciabodegas',
      resizable: true
    };
  }

  ngOnInit() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.btnPrimero = false;
    this.btnAnterior = false;
    this.btnSiguiente = false;
    this.btnUltimo = false;
    this.regpais = true;
    this.btnBuscar = false;
    this.paraisofiscal = true;
    this.denominacion = true;
    this.indice = 0;
    this.inputNum = true;
    this.largo = '350';
    this.largo1 = '650';
    this.index = 0;
    this.flagCashM = false;
    this.flagFormadePago = false;
    this.banderaCheks = false;
    this.proveedorSeleccionado = {};
    this.proveedorADM = {};
    this.cmbformapaux = [];
    this.cmbentiFaux = [];
    this.cmbtipoCaux = [];
    this.retencionSeleccionada = {};
    this.llenarCombos();
    this.nuevoproveedor = false;

    this.selectcontri = { codigo: 'N', name: 'Normal' };

    this.obtenerEntidadF();
    this.obtenerFormap();
    this.obtenerTiposC();
    this.cxctrnresumen = [];
    this.cxctmptrnresumen = [];
    this.bolestcta = false;
    this.bolsaldo = false;
    this.FECHA_HASTA = new Date();
    this.FECHA_DESDE = new Date();
    this.strFecha = new Date();
    this.codigoAnt = '';
    this.nuevoProveedor();
    const lista = [];
    this.permisosService.getListaPermisos('COM', '1', '23').subscribe((res) => {
      this.permisoProveedor = res[0];
    });
    this.proveedorSeleccionadoAux = {};
    this.proveedorServicio.getProveedor().subscribe((res) => {
      this.proveedorServicio.commaeproveedor = res;
    });
    this.proveedorServicio.commaeproveedorADM = [];
    this.proveedorServicio.commaeproveedorFP = [];
    this.proveedorServicio.listaCodsri().subscribe(res => {
      res.map(aux => {
        lista.push(aux.CODSRI_DESCRIPCION);
        if (res.length - 1 === res.indexOf(aux)) {
          this.columnDefsProv[0].cellEditorParams = {
            values: lista
          };
          this.aggrid.refreshColumnDefs();
        }
      });
    });
  }
  //CDPJ
  ngOnDestroy(): void {
    this.confIniciales.instanciasProveedores--;
  }
//CDPJ
  llenarCash() {
    if (this.proveedorSeleccionado.COM_BENFCM === '' || this.proveedorSeleccionado.COM_BENFCM === null) {
      this.COM_BENFCMAUX = this.proveedorSeleccionado.PRO_NOMBRE;
    } else {
      this.COM_BENFCMAUX = this.proveedorSeleccionado.COM_BENFCM;
    }
    if (this.proveedorSeleccionado.COM_DIFBENFCM === '' || this.proveedorSeleccionado.COM_DIFBENFCM === null) {
      this.COM_DIFBENFCMAUX = this.proveedorSeleccionado.PRO_DIRECCION1;
    } else {
      this.COM_DIFBENFCMAUX = this.proveedorSeleccionado.COM_DIFBENFCM;
    }
    if (this.proveedorSeleccionado.PRO_IDCM === '' || this.proveedorSeleccionado.PRO_IDCM === null) {
      this.PRO_IDCMAUX = this.proveedorSeleccionado.PRO_CODIGO;
    } else {
      this.PRO_IDCMAUX = this.proveedorSeleccionado.PRO_IDCM;
    }
    if (this.proveedorSeleccionado.PRO_EMAILCM === '' || this.proveedorSeleccionado.PRO_EMAILCM === null) {
      this.proveedorSeleccionado.PRO_EMAILCM = this.proveedorSeleccionado.PRO_CORREO;
    }
    if (this.proveedorSeleccionado.PRO_TIPOIDENTIFCM === null || this.proveedorSeleccionado.PRO_TIPOIDENTIFCM === '') {
      this.proveedorSeleccionado.PRO_TIPOIDENTIFCM = '1';
    }
    // if (this.proveedorSeleccionado.PRO_TIPOIDENTIFCM.length === 0 || this.proveedorSeleccionado.PRO_TIPOIDENTIFCM.length === null
    //   || this.proveedorSeleccionado.PRO_TIPOIDENTIFCM.length === undefined) {
    //   this.proveedorSeleccionado.PRO_TIPOIDENTIFCM = '1';
    // }
    for (const j of this.cmbidecash) {
      if (this.proveedorSeleccionado.PRO_TIPOIDENTIFCM === j.codigo) {
        this.selectideticash = { codigo: j.codigo, name: j.name };
      }
    }
  }

  llenarlabels() {
    //CDPJ
    console.log(this.proveedorSeleccionado.PRO_FECING);
    if (this.proveedorSeleccionado.PRO_FECING === null || this.proveedorSeleccionado.PRO_FECING === undefined
      || this.proveedorSeleccionado.PRO_FECING === '') {
      this.proveedorSeleccionado.PRO_FECING = new Date()
      this.proveedorSeleccionado.PRO_FECING = this.datePipe.transform(this.proveedorSeleccionado.PRO_FECING, 'dd/MM/yyyy')
    }
    console.log('FECHA', this.proveedorSeleccionado.PRO_FECING);
    //CDPJ
    const fecha = this.proveedorSeleccionado.PRO_FECING.split('/');
    this.strFecha = new Date(fecha[2], fecha[1] - 1, fecha[0]);

    if (this.proveedorSeleccionado.GRU_CODIGO != null) {
      this.proveedorServicio.getNombreG(this.proveedorSeleccionado.GRU_CODIGO).subscribe((res) => {
        this.grupnombre = res[0].GRU_NOMBRE;
      });
    } else {
      this.grupnombre = this.proveedorSeleccionado.GRU_CODIGO;
    }

    if (this.proveedorSeleccionado.PRO_ZONA != null) {
      this.proveedorServicio.getZona(this.proveedorSeleccionado.PRO_ZONA).subscribe((res) => {
        this.zonanombre = res[0].REF_NOMBRE;
      });
    } else {
      this.zonanombre = this.proveedorSeleccionado.PRO_ZONA;
    }

    if (this.proveedorSeleccionado.CEN_CODIGO !== null) {
      this.proveedorServicio.getCen(this.proveedorSeleccionado.CEN_CODIGO).subscribe((res) => {

        this.cennombre = res[0].CEN_NOMBRE;


      });
    } else {
      this.cennombre = this.proveedorSeleccionado.CEN_CODIGO;
    }
    if (this.proveedorSeleccionado.CON_CODIGO1 != null) {
      this.proveedorServicio.getCuenta(this.proveedorSeleccionado.CON_CODIGO1).subscribe((res) => {
        this.connombre = res[0].CON_NOMBRE;
      });
    } else {
      this.connombre = this.proveedorSeleccionado.CON_CODIGO1;
    }
    if (this.proveedorSeleccionado.PRO_PAGOLOCALEXT != null) {
      this.proveedorServicio.getProPagoLocaloExterior(this.proveedorSeleccionado.PRO_PAGOLOCALEXT).subscribe((res) => {
        this.conlnombre = res[0].CODSRI_DESCRIPCION;

      });
    } else {
      this.conlnombre = this.proveedorSeleccionado.PRO_PAGOLOCALEXT;
    }
    if (this.proveedorSeleccionado.CON_CODIGO2 != null) {
      this.proveedorServicio.getCuenta(this.proveedorSeleccionado.CON_CODIGO2).subscribe((res) => {

        this.con2nombre = res[0].CON_NOMBRE;
      });
    } else {
      this.con2nombre = this.proveedorSeleccionado.CON_CODIGO2;
    }
    this.codnombre = ' ' + this.proveedorSeleccionado.PRO_CODIGO + ' - ' + this.proveedorSeleccionado.PRO_NOMBRE;

    this.conepaiscod = this.proveedorSeleccionado.PRO_PAISPAGO;
    // if (this.proveedorSeleccionado.PRO_PAISPAGO !== null) {
    //   this.proveedorServicio.getcodsridescripcion(this.proveedorSeleccionado.PRO_PAISPAGO).subscribe(res => {
    //     this.conepaisnombre = res[0].CODSRI_DESCRIPCION;
    //   });

    //   if (this.proveedorSeleccionado.PRO_TIPOREGFISCAL === '01') {
    //     this.conpaiscod = this.conepaiscod;
    //     this.proveedorServicio.getcodsridescripcion(this.proveedorSeleccionado.PRO_PAISPAGO).subscribe(res => {
    //       this.conpaisnombre = res[0].CODSRI_DESCRIPCION;
    //     });

    //   } else if (this.proveedorSeleccionado.PRO_TIPOREGFISCAL === '02') {
    //     this.conparaisocod = this.conepaiscod;
    //     this.proveedorServicio.getcodsridescripcion(this.proveedorSeleccionado.PRO_PAISPAGO).subscribe(res => {
    //       this.conparaisonombre = res[0].CODSRI_DESCRIPCION;
    //     });
    //   }
    // }
    //CDPJ
    if (this.proveedorSeleccionado.PRO_PAISPAGO !== null) {
      this.proveedorServicio.getcodsridescripcion('CODSRI_CODIGO=\'' +
        this.proveedorSeleccionado.PRO_PAISPAGO + '\'' + ' AND CODSRI_TIPO=\'PA\'').subscribe(res => {
          this.conepaisnombre = res[0].CODSRI_DESCRIPCION;
        });

      if (this.proveedorSeleccionado.PRO_TIPOREGFISCAL === '01') {
        this.conpaiscod = this.proveedorSeleccionado.PRO_CODPAISREGGEN;
        this.proveedorServicio.getcodsridescripcion('CODSRI_CODIGO=\'' +
          this.proveedorSeleccionado.PRO_CODPAISREGGEN + '\'' + ' AND CODSRI_TIPO=\'RG\'').subscribe(res => {
            this.conpaisnombre = res[0].CODSRI_DESCRIPCION;
          });

      } else if (this.proveedorSeleccionado.PRO_TIPOREGFISCAL === '02') {
        this.conparaisocod = this.proveedorSeleccionado.PRO_CODPAISPARFIS;
        this.proveedorServicio.getcodsridescripcion('CODSRI_CODIGO=\'' +
          this.proveedorSeleccionado.PRO_CODPAISPARFIS + '\'' + ' AND CODSRI_TIPO=\'PG\'').subscribe(res => {
            this.conparaisonombre = res[0].CODSRI_DESCRIPCION;
          });
      }
    }
    if (this.proveedorSeleccionado.PRO_DENOMINACION === null || this.proveedorSeleccionado.PRO_DENOMINACION === undefined) {
      this.proveedorSeleccionado.PRO_DENOMINACION = ''
    } else {
      this.proveedorSeleccionado.PRO_DENOMINACION = this.proveedorSeleccionado.PRO_DENOMINACION;
    }
    //CDPJ
    this.bolestcta = false;
    this.bolsaldo = false;
    this.lblvalida = this.proveedorSeleccionado.PRO_VALIDACION;
  }

  // tablaADM
  llenartablADM() {
    this.proveedorServicio.listarProveedorAdm(this.proveedorSeleccionado).subscribe((res) => {

      if (res.length !== 0) {
        const lista = [];
        this.proveedorServicio.listaCodsri().subscribe(res1 => {

          res1.map(aux => {
            lista.push(aux.CODSRI_DESCRIPCION);
            if (res.length - 1 === res.indexOf(aux)) {


              this.columnDefsProv[0].cellEditorParams = {
                values: lista
              };
              this.aggrid.refreshColumnDefs();
              this.aggrid.refreshaggrid(this.proveedorServicio.commaeproveedorADM, this.defaultProv.objeto);

            }
          });
          res.map(aux => {

            aux.AUTSRI_FECHAINI = this.datePipe.transform(aux.AUTSRI_FECHAINI, 'dd/MM/yyyy');
            aux.AUTSRI_FECHAFIN = this.datePipe.transform(aux.AUTSRI_FECHAFIN, 'dd/MM/yyyy');

          });
          this.proveedorServicio.commaeproveedorADM = res;


          this.proveedorADM = this.proveedorServicio.commaeproveedorADM[0];
          this.aggrid.refreshaggrid(this.proveedorServicio.commaeproveedorADM, this.defaultProv.objeto);


        });


      } else {
        this.proveedorServicio.commaeproveedorADM = [];
        this.proveedorServicio.commaeproveedorEC = [];
        this.proveedorServicio.commaeproveedorSALDO = [];


      }
      //  this.llenartablESTC();
    });
  }

  llenartablaMOV() {
    this.proveedorServicio.ejecutarProcesoM(this.proveedorSeleccionado).subscribe(res1 => {
      this.proveedorServicio.consultarMovimientos(this.proveedorSeleccionado).subscribe((res) => {
        if (res.length !== 0) {
          res.map(aux => {
            aux.FECHA_DOC = this.datePipe.transform(aux.FECHA_DOC, 'dd-MM-yyyy');
          });
          this.proveedorServicio.commaeproveedorMov = res;
          this.aggrid.refreshaggrid(this.proveedorServicio.commaeproveedorMov, this.defaultProvMv.objeto);
        } else {
          this.proveedorServicio.commaeproveedorMov = [];
          this.aggrid.refreshaggrid(this.proveedorServicio.commaeproveedorMov, this.defaultProvMv.objeto);
        }
      });
    });

  }

  llenartablamovF() {


    const FECHA_DES = this.datePipe.transform(this.FECHA_DESDE, 'dd/MM/yyyy');
    const FECHA_HAS = this.datePipe.transform(this.FECHA_HASTA, 'dd/MM/yyyy');

    this.proveedorServicio.consultarMovimientosF(this.proveedorSeleccionado, FECHA_DES, FECHA_HAS).subscribe((res) => {
      if (res.length !== 0) {
        res.map(aux => {
          aux.FECHA_DOC = this.datePipe.transform(aux.FECHA_DOC, 'dd-MM-yyyy');
        });
        this.proveedorServicio.commaeproveedorMov = res;
        this.aggrid.refreshaggrid(this.proveedorServicio.commaeproveedorMov, this.defaultProvMv.objeto);

      }
    });


  }

  llenartablaRetenciones() {
    this.proveedorServicio.getRetenciones(this.proveedorSeleccionado).subscribe(res => {
      if (res.length !== 0) {
        this.proveedorServicio.commaeproveedorRt = res;

      } else {
        this.proveedorServicio.commaeproveedorRt = [];
      }

    });
  }

  llenartablaAnexos() {
    this.proveedorServicio.consultarFormas(this.proveedorSeleccionado.PRO_CODIGO).subscribe(res => {
      if (res.length !== 0) {
        this.proveedorServicio.commaeproveedorFP = res;
        this.proveedorServicio.commaeproveedorFP.map(aux => {
          // aux.LINEA = this.proveedorServicio.commaeproveedorFP.indexOf(aux) + 1;
        });
      } else {
        this.proveedorServicio.commaeproveedorFP = [];
      }
    });
  }

  guardarAnexos() {
    if (this.proveedorServicio.commaeproveedorFP.length > 0) {
      this.proveedorServicio.commaeproveedorFP.map(cont => {
        //CDPJ
        this.proveedorSeleccionado.PRO_CONVDOBLETRIB = this.selectconvdobletrib.codigo;
        this.proveedorSeleccionado.PRO_PAGOEXTERIOR = this.selectpagoexterior.codigo;

        this.proveedorSeleccionado.PRO_TIPOREGFISCAL = this.selecttiporegfis.codigo;
        //CDPJ
        this.proveedorServicio.eliminarFormapago(cont, this.proveedorSeleccionado.PRO_CODIGO).subscribe(res => {
        });
        if (this.proveedorServicio.commaeproveedorFP.length - 1 === this.proveedorServicio.commaeproveedorFP.indexOf(cont)) {

          this.proveedorServicio.commaeproveedorFP.map(aux => {
            this.proveedorServicio.insertarFormapago(aux, this.proveedorSeleccionado.PRO_CODIGO).subscribe(ok => {
            });
            if (this.proveedorServicio.commaeproveedorFP.length - 1 === this.proveedorServicio.commaeproveedorFP.indexOf(aux)) {
              this.messageService.add({
                key: 'commaeproveedor',
                severity: 'success',
                summary: 'Actualización de Proveedor',
                detail: 'Autorización Forma de pago'
              });
            }
          });
        }
      });
    }
  }


  guardarADM() {

    this.proveedorADM.PRO_CODIGO = this.proveedorSeleccionado.PRO_CODIGO;
    if (this.proveedorADM.AUTSRI_AUTORIZAIMP === null) {
      this.proveedorADM.AUTSRI_AUTORIZAIMP = '';
    }

    this.proveedorServicio.listaCodsri().subscribe(res => {
      res.map(aux => {
        if (aux.CODSRI_DESCRIPCION === this.proveedorADM.CODSRI_DESCRIPCION) {
          this.proveedorADM.AUTSRI_TIPODOC = aux.CODSRI_CODIGO;
          this.codigoTipo = aux.CODSRI_CODIGO;
        }
      });
      if (this.proveedorADM.AUTSRI_AUTORIZASRI === '') {
        this.messageService.add({
          key: 'commaeproveedor',
          severity: 'error',
          summary: 'Actualización de Proveedor',
          detail: 'Autorización Sri incorrecta'
        });
      } else if (this.proveedorADM.AUTSRI_SECUENCIALFIN === '') {
        this.messageService.add({
          key: 'commaeproveedor',
          severity: 'error',
          summary: 'Actualización de Proveedor',
          detail: 'Numeración final incorrecta'
        });
      } else if (this.proveedorADM.AUTSRI_SECUENCIALINI === '') {
        this.messageService.add({
          key: 'commaeproveedor',
          severity: 'error',
          summary: 'Actualización de Proveedor',
          detail: 'Numeración inicial incorrecta'
        });
      } else if (this.proveedorADM.AUTSRI_SERIEPTOEMI === '') {
        this.messageService.add({
          key: 'commaeproveedor',
          severity: 'error',
          summary: 'Actualización de Proveedor',
          detail: 'Punto de emisión incorrecta'
        });
      } else if (this.proveedorADM.AUTSRI_SERIEESTAB === '') {
        this.messageService.add({
          key: 'commaeproveedor',
          severity: 'error',
          summary: 'Actualización de Proveedor',
          detail: 'Establecimiento incorrecta'
        });
      } else {
        if (this.proveedorADM.AUTSRI_CODIGO === '') {
          this.proveedorServicio.insertarmaeautsri(this.proveedorADM, this.codigoTipo).subscribe((res1) => {
            this.llenartablADM();
            this.messageService.add({
              key: 'commaeproveedor',
              severity: 'success',
              summary: 'Actualización de Registro',
              detail: 'Registro guardado exitosamente'
            });
          });
        } else {
          // actualizar
          this.proveedorServicio.actualizarmaeautsri(this.proveedorADM, this.codigoTipo).subscribe(() => {
            // this.llenartablADM();
            this.messageService.add({
              key: 'commaeproveedor',
              severity: 'success',
              summary: 'Actualización de Registro',
              detail: 'Registro actualizado exitosamente'
            });
          });
        }
      }
    });
  }

  cambio(cambio) {
    this.botonesmodificar();
  }

  // falta llenar señales
  senialestablaADM(valor) {
    //
    if (valor === 'nuevo') {
      const nuevo = {
        AUTSRI_CODIGO: '',
        PRO_CODIGO: '', AUTSRI_TIPODOC: '', AUTSRI_FECHAINI: '',
        AUTSRI_FECHAFIN: '', AUTSRI_SERIEESTAB: '', AUTSRI_SERIEPTOEMI: '', AUTSRI_SECUENCIALINI: '', AUTSRI_SECUENCIALFIN: '',
        AUTSRI_AUTORIZASRI: '', AUTSRI_AUTORIZAIMP: null, COM_CODIGO: '01', CODSRI_DESCRIPCION: ''
      };
      this.bandera = true;
      this.proveedorADM = nuevo;
      this.proveedorADM.AUTSRI_FECHAINI = new Date();
      this.proveedorADM.AUTSRI_FECHAFIN = new Date();
      this.proveedorADM.AUTSRI_FECHAINI = this.datePipe.transform(this.proveedorADM.AUTSRI_FECHAINI, 'dd/MM/yyyy');
      this.proveedorADM.AUTSRI_FECHAFIN = this.datePipe.transform(this.proveedorADM.AUTSRI_FECHAFIN, 'dd/MM/yyyy');
      this.proveedorServicio.commaeproveedorADM.push(this.proveedorADM);
      this.aggrid.refreshaggrid(this.proveedorServicio.commaeproveedorADM, this.defaultProv.objeto);
      this.btnNuevoGrid = false;
      this.btnCancelarGrid = true;
      this.botonesmodificar();

    } else if (valor === 'eliminar') {
      if (this.proveedorADM !== undefined) {
        this.confirmarRegistro();
      }
    } else if (valor === 'cancelar') {
      this.llenartablADM();
      this.botonesInicio();
    }
    if (valor.object !== undefined) {
      this.proveedorADM = valor.object;
    }
  }

  botonesInicio() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.btnPrimero = false;
    this.btnAnterior = false;
    this.btnSiguiente = false;
    this.btnUltimo = false;
    this.btnNuevoGrid = false;
  }


  senialestablaFormapago(valor) {
    let numero;
    if (valor === 'nuevo') {
      if (this.proveedorServicio.commaeproveedorRt === undefined || this.proveedorServicio.commaeproveedorRt === null) {
        numero = 0;
      } else {
        numero = this.proveedorServicio.commaeproveedorRt.length;
      }

      const nuevo = {
        PRO_CODIGO: this.proveedorSeleccionado.PRO_CODIGO,
        COM_CODIGO: '01',
        TRNFORMAPAGO_CODIGO: '',
        TRNFORMAPAGO_DESCRIPCION: '',
        LINEA: Number(numero + 1)
      };
      this.proveedorFormapago = nuevo;
      this.proveedorServicio.commaeproveedorFP.push(this.proveedorFormapago);
      this.aggrid.refreshaggrid(this.proveedorServicio.commaeproveedorFP, this.defaultProvFp.objeto);
      this.botonesmodificar();
    } else if (valor === 'eliminar') {
      if (this.proveedorFormapago !== undefined) {
        this.confirmarForpago();
      }
    } else if (valor === 'cancelar') {
      this.llenartablADM();
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.btnPrimero = false;
      this.btnAnterior = false;
      this.btnSiguiente = false;
      this.btnUltimo = false;
      this.btnNuevoGrid = false;
    }
    if (valor.object !== undefined) {
      this.proveedorFormapago = valor.object;
    }
  }

  confirmarForpago() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el registro',
      header: 'Eliminar registro',
      icon: 'pi pi-exclamation-triangle',
      key: 'commaeproveedorConfirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.proveedorServicio.eliminarFormapago(this.proveedorFormapago, this.proveedorSeleccionado.PRO_CODIGO).subscribe(() => {
          this.messageService.add({
            key: 'commaeproveedor',
            severity: 'success',
            summary: 'Eliminación exitosa',
            detail: 'Se eliminó el registro correctamente'
          });
        });
      },
      reject: () => {
      }
    });
  }

  confirmarRegistro() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el registro',
      header: 'Eliminar registro',
      icon: 'pi pi-exclamation-triangle',
      key: 'commaeproveedorConfirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.proveedorServicio.eliminarMaeautsri(this.proveedorADM).subscribe(() => {
          this.messageService.add({
            key: 'commaeproveedor',
            severity: 'success',
            summary: 'Eliminación exitosa',
            detail: 'Se eliminó el registro correctamente'
          });
          this.llenartablADM();
        });
      },
      reject: () => {
      }
    });
  }

  seleccionarRegistro(valor) {
    this.index = 0;
    if (valor === 'Primero') {
      this.btnAnterior = true;
      this.btnPrimero = true;
      this.btnUltimo = false;
      this.btnSiguiente = false;
      this.btnRegresar = false;
      this.indice = 0;
      this.nuevoproveedor = true;

      this.proveedorSeleccionado = this.proveedorServicio.commaeproveedor[this.indice];
      this.proveedorSeleccionadoAux = this.proveedorServicio.commaeproveedor[this.indice];
      this.codigoAnt = this.proveedorSeleccionado.PRO_CODIGO;
      this.seleccionarCheks();
      this.seleccionarCombo();
      this.llenarlabels();
      this.llenarCash();

    }

    if (valor === 'Anterior') {
      this.btnRegresar = false;
      this.nuevoproveedor = true;

      if (!this.btnPrimero && !this.btnAnterior && !this.btnSiguiente && !this.btnUltimo && this.indice === 0) {
        this.indice = 0;
        this.btnPrimero = true;
        this.btnAnterior = true;
        this.btnSiguiente = false;
        this.btnUltimo = false;
      } else {
        this.indice--;
        this.btnPrimero = false;
        this.btnAnterior = false;
        this.btnSiguiente = false;
        this.btnUltimo = false;
        if (this.indice === 0) {
          this.btnPrimero = true;
          this.btnAnterior = true;
        }
      }
      this.proveedorSeleccionado = this.proveedorServicio.commaeproveedor[this.indice];
      this.proveedorSeleccionadoAux = this.proveedorServicio.commaeproveedor[this.indice];
      this.codigoAnt = this.proveedorSeleccionado.PRO_CODIGO;
      this.seleccionarCombo();
      this.seleccionarCheks();
      this.llenarlabels();
      this.llenarCash();
    }

    if (valor === 'Siguiente') {
      this.nuevoproveedor = true;

      if (!this.btnPrimero && !this.btnAnterior && !this.btnSiguiente && !this.btnUltimo && this.indice === 0) {
        this.indice = 0;
        this.btnPrimero = true;
        this.btnAnterior = true;
        this.btnSiguiente = false;
        this.btnUltimo = false;

      } else {
        this.indice++;
        this.btnPrimero = false;
        this.btnAnterior = false;
        this.btnSiguiente = false;
        this.btnUltimo = false;
        if (this.indice === this.proveedorServicio.commaeproveedor.length - 1) {
          this.btnSiguiente = true;
          this.btnUltimo = true;
        }
      }
      this.btnRegresar = false;
      this.proveedorSeleccionado = this.proveedorServicio.commaeproveedor[this.indice];
      this.proveedorSeleccionadoAux = this.proveedorServicio.commaeproveedor[this.indice];
      this.codigoAnt = this.proveedorSeleccionado.PRO_CODIGO;
      this.seleccionarCombo();
      this.seleccionarCheks();
      this.llenarlabels();
      this.llenarCash();
    }

    if (valor === 'Ultimo') {
      this.btnUltimo = true;
      this.btnSiguiente = true;
      this.btnPrimero = false;
      this.btnAnterior = false;
      this.btnRegresar = false;
      this.nuevoproveedor = true;
      this.indice = this.proveedorServicio.commaeproveedor.length - 1;
      this.proveedorSeleccionado = this.proveedorServicio.commaeproveedor[this.indice];
      this.proveedorSeleccionadoAux = this.proveedorServicio.commaeproveedor[this.indice];
      this.codigoAnt = this.proveedorSeleccionado.PRO_CODIGO;
      this.seleccionarCheks();
      this.seleccionarCombo();
      this.llenarlabels();
      this.llenarCash();
    }

    if (this.proveedorSeleccionado.PRO_CODIGO !== '') {
      this.disabledEstadisticas = false;
    } else {
      this.disabledEstadisticas = true;
    }

  }

  nuevo() {
    if (this.permisoProveedor.PERINSERCION === 1) {
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.btnRegresar = false;
      this.btnPrimero = true;
      this.btnSiguiente = true;
      this.btnAnterior = true;
      this.btnUltimo = true;
      this.flagCashM = true;
      this.index = 0;
      this.grupnombre = '';
      this.zonanombre = '';
      this.connombre = '';
      this.con2nombre = '';
      this.cennombre = '';
      this.codnombre = '';
      this.COM_BENFCMAUX = '';
      this.PRO_IDCMAUX = '';
      this.COM_DIFBENFCMAUX = '';
      this.conlnombre = '';
      this.conpaiscod = '';
      this.conpaisnombre = '';
      this.conparaisocod = '';
      this.conparaisonombre = '';
      this.conepaiscod = '';
      this.conepaisnombre = '';
      this.nuevoproveedor = false;

      const codigo = this.proveedorServicio.commaeproveedor[this.proveedorServicio.commaeproveedor.length - 1].PRO_CODIGO;
      this.nuevoProveedor();
      this.codigoAnt = this.proveedorSeleccionado.PRO_CODIGO;
      this.llenartablADM();
    } else if (this.permisoProveedor.PERINSERCION === 0) {
      this.messageService.add({
        key: 'commaeproveedor',
        severity: 'error',
        summary: 'Inserción de Proveedor',
        detail: 'El usuario no tiene permisos de inserción, acción denegada'
      });
    }
  }

  cancelar() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.btnPrimero = false;
    this.btnAnterior = false;
    this.btnSiguiente = false;
    this.btnUltimo = false;
    this.btnNuevoGrid = false;
    this.btnBuscar = false;
    this.nuevoproveedor = true;
    this.flagCashM = false;

    if (this.codigoAnt.length > 0) {
      this.proveedorServicio.getProveedor().subscribe((res) => {
        this.proveedorServicio.commaeproveedor = res;
        if (this.indice === 0) {
          this.btnAnterior = true;
          this.btnPrimero = true;
          this.btnUltimo = false;
          this.btnSiguiente = false;
          this.btnRegresar = false;
        }
        if (this.indice === this.proveedorServicio.commaeproveedor.length - 1) {
          this.btnAnterior = false;
          this.btnPrimero = false;
          this.btnUltimo = true;
          this.btnSiguiente = true;
          this.btnRegresar = false;
        }
        this.proveedorSeleccionado = this.proveedorServicio.commaeproveedor[this.indice];
        this.proveedorSeleccionadoAux = this.proveedorServicio.commaeproveedor[this.indice];
        this.codigoAnt = this.proveedorSeleccionado.PRO_CODIGO;
        this.seleccionarCheks();
        this.seleccionarCombo();
        this.llenarlabels();
        this.llenarCash();

      });
    } else if (this.codigoAnt.length === 0) {
      const codigo = this.proveedorServicio.commaeproveedor[this.proveedorServicio.commaeproveedor.length - 1].PRO_CODIGO;
      this.nuevoProveedor();
      this.llenartablADM();
    }
  }

  guardar() {
let control=0;
    //GSRF
    if(this.proveedorSeleccionado.CON_CODIGO1 !== null){
    if (this.proveedorSeleccionado.CON_CODIGO1.substr(-1, 1) === '.') {
      this.messageService.add({
        key: 'commaeproveedor',
        severity: 'error',
        summary: 'Cuenta Contable',
        detail: 'No se puede elegir esa cuenta contable en Cuentas por pagar'
      });
      this.proveedorSeleccionado.CON_CODIGO1 = '';
      this.connombre = '';
      this.index=1;
      return;
    }
  }
  if(this.proveedorSeleccionado.CON_CODIGO2 !== null){
    if (this.proveedorSeleccionado.CON_CODIGO2.substr(-1, 1) === '.') {
      this.messageService.add({
        key: 'commaeproveedor',
        severity: 'error',
        summary: 'Cuenta Contable',
        detail: 'No se puede elegir esa cuenta contable en Cuentas por pagar'
      });
      this.proveedorSeleccionado.CON_CODIGO2 = '';
      this.con2nombre = '';
      this.index=1;
      return;
    }
  }
    //GSRF
    if (this.lblvalida === '' || this.lblvalida === 'Mal') {
      this.messageService.add({
        key: 'commaeproveedor',
        severity: 'success',
        summary: 'Información',
        detail: 'Identificación Incorrecta'
      });
      return;
    }
    const fecha = this.datePipe.transform(this.strFecha, 'dd/MM/yyyy');
    if (this.permisoProveedor.PERACTUALIZACION === 1) {
      this.proveedorServicio.erProvcodig(this.proveedorSeleccionado.PRO_CODIGO).subscribe(async existe => {
        if (existe !== null) {
          // this.btnNuevo = false;
          // this.btnGuardar = true;
          // this.btnBorrar = false;
          // this.btnRegresar = true;
          // this.btnPrimero = false;
          // this.btnSiguiente = false;
          // this.btnAnterior = false;
          // this.btnUltimo = false;
          // this.btnBuscar = false;
          //CDPJ
          if (this.proveedorSeleccionado.GRU_CODIGO.length === 0 ||
            this.proveedorSeleccionado.PRO_NOMBRE.length === 0 ||
            this.proveedorSeleccionado.PRO_NOMBRE === null) {
            this.messageService.add({
              key: 'commaeproveedor',
              severity: 'error',
              summary: 'Inserción de Proveedor',
              detail: 'Ingrese los campos obligatorios '
            });
            //   this.btnNuevo = true;
            // this.btnGuardar = false;
          } else if (this.proveedorSeleccionado.PRO_PARTEREL === '' ||
            this.proveedorSeleccionado.PRO_PARTEREL === null ||
            this.proveedorSeleccionado.PRO_PARTEREL === undefined) {
            this.messageService.add({
              key: 'commaeproveedor',
              severity: 'error',
              summary: 'Inserción de Proveedor',
              detail: 'Seleccione la parte relacionada'
            });
          } else {//CDPJ
            this.guardarCheks();
            //CDPJ
            if (Number(await this.validaCntaCont()) !== 0) {
              return;
            }
            //CDPJ
            if (this.banderaCheks === true) {
              if (this.index === 4) {
                this.proveedorSeleccionado.PRO_IDCM = this.PRO_IDCMAUX;
                this.proveedorSeleccionado.COM_BENFCM = this.COM_BENFCMAUX;
                this.proveedorSeleccionado.PRO_TIPOIDENTIFCM = this.selectideticash.codigo;
                try {
                  await this.proveedorServicio.guardarDatosCashManagement(this.proveedorSeleccionado).toPromise()
                } catch (error1) {
                  let mensaje: string;
                mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
                this.messageService.add({
                  key: 'commaeproveedor',
                  severity: 'error',
                  summary: 'Actualización de Proveedor',
                  detail: mensaje
                });
                this.btnNuevo = false;
                this.btnGuardar = true;
                this.btnBorrar = false;
                this.btnRegresar = true;
                this.btnPrimero = false;
                this.btnSiguiente = false;
                this.btnAnterior = false;
                this.btnUltimo = false;
                control=1;
                return;
                }
              }
              console.log(control)
              if(control === 1){
                return;
              }
              this.proveedorServicio.actualizarProveedor(this.proveedorSeleccionado, fecha).subscribe((res) => {
                this.disabledEstadisticas = false;
                if (this.proveedorServicio.commaeproveedorADM.length > 0) {
                  this.guardarADM();
                }
                if (this.proveedorServicio.commaeproveedorFP.length > 0) {
                  this.guardarAnexos();
                }
                this.banderaCheks = false;
                this.btnNuevoGrid = false;
                this.messageService.add({
                  key: 'commaeproveedor',
                  severity: 'success',
                  summary: 'Actualización de Proveedor',
                  detail: 'Se ha actualizado el proveedor'
                });
                this.botonesInicio();
                this.proveedorServicio.actualizarValidacion(this.lblvalida, this.proveedorSeleccionado.PRO_CODIGO).subscribe();
                this.auditoriagral.registrarAuditoria('COM_MAEPROVEEDOR', this.proveedorSeleccionado.PRO_CODIGO, 'A',
                  '', '01', '', '', '', '').subscribe(() => {
                  });
                  for(const data of this.etiquetasaudi){
                    if(data.name !== ''){
                      if(data.codigo === 'PRO_VALIDACION'){
                        this.auditoriagral.registrarAuditoria('COM_MAEPROVEEDOR', this.proveedorSeleccionado.PRO_CODIGO+'/'+
                        data.name+'/'+ (this.lblvalida === null || this.lblvalida === undefined?'':this.lblvalida)
                        , 'A',
                        '', '01', '', '', '', '').subscribe();
                      }else{
                        if(data.codigo === 'PRO_FECING'){
                          this.auditoriagral.registrarAuditoria('COM_MAEPROVEEDOR', this.proveedorSeleccionado.PRO_CODIGO+'/'+
                          data.name+'/'+ (this.proveedorSeleccionado[data.codigo] === null || this.proveedorSeleccionado[data.codigo] === undefined?'': this.proveedorSeleccionado[data.codigo])
                          , 'A',
                          '', '01', '', '', '', '').subscribe();
                        }else{
                          this.auditoriagral.registrarAuditoria('COM_MAEPROVEEDOR', this.proveedorSeleccionado.PRO_CODIGO+'/'+
                          data.name+'/'+ (this.proveedorSeleccionado[data.codigo] === null || this.proveedorSeleccionado[data.codigo] === undefined?'':this.proveedorSeleccionado[data.codigo])
                          , 'A',
                          '', '01', '', '', '', '').subscribe();
                        }
                       
                      }
                    }
                    
                  }

              }, error1 => {
                let mensaje: string;
                mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                this.messageService.add({
                  key: 'commaeproveedor',
                  severity: 'error',
                  summary: 'Actualización de Proveedor',
                  detail: 'Ingrese correctamente  los campos'
                });
                this.btnNuevo = false;
                this.btnGuardar = true;
                this.btnBorrar = false;
                this.btnRegresar = true;
                this.btnPrimero = false;
                this.btnSiguiente = false;
                this.btnAnterior = false;
                this.btnUltimo = false;
              });
            }
          }

        } else if (existe === null) {
          // this.btnNuevo = false;
          // this.btnGuardar = true;
          // this.btnBorrar = false;
          // this.btnRegresar = true;
          // this.btnPrimero = false;
          // this.btnSiguiente = false;
          // this.btnAnterior = false;
          // this.btnUltimo = false;

          this.guardarCheks();
          this.valoresporDefecto();
          //CDPJ
          if (Number(await this.validaCntaCont()) !== 0) {
            return;
          }
          //CDPJ
          if (this.proveedorSeleccionado.GRU_CODIGO.length === 0 ||
            this.proveedorSeleccionado.PRO_NOMBRE.length === 0 ||
            this.proveedorSeleccionado.PRO_NOMBRE === null) {
            this.messageService.add({
              key: 'commaeproveedor',
              severity: 'error',
              summary: 'Inserción de Proveedor',
              detail: 'Ingrese los campos obligatorios '
            });
          } else if (this.proveedorSeleccionado.PRO_PARTEREL === '' ||//CDPJ
            this.proveedorSeleccionado.PRO_PARTEREL === null ||
            this.proveedorSeleccionado.PRO_PARTEREL === undefined) {
            this.messageService.add({
              key: 'commaeproveedor',
              severity: 'error',
              summary: 'Inserción de Proveedor',
              detail: 'Seleccione la parte relacionada'
            });
          }//CDPJ
          else {
            this.proveedorServicio.guardarProveedor(this.proveedorSeleccionado, fecha).subscribe(() => {
              this.disabledEstadisticas = false;
              if (this.proveedorServicio.commaeproveedorADM.length > 0) {
                this.guardarADM();

                this.btnNuevoGrid = false;
              }
              this.actualizarIndice();

              this.messageService.add({
                key: 'commaeproveedor',
                severity: 'success',
                summary: 'Inserción de Proveedor',
                detail: 'Se ha insertado el proveedor'
              });
              this.botonesInicio();
              this.flagCashM = false;
              this.llenarCash();
              this.nuevoproveedor = true;
              this.proveedorServicio.actualizarValidacion(this.lblvalida, this.proveedorSeleccionado.PRO_CODIGO).subscribe();
              this.auditoriagral.registrarAuditoria('COM_MAEPROVEEDOR', this.proveedorSeleccionado.PRO_CODIGO, 'I',
                '', '01', '', '', '', '').subscribe(() => {
                });
                for(const data of this.etiquetasaudi){
                  if(data.name !== ''){
                    if(data.codigo === 'PRO_VALIDACION'){
                      this.auditoriagral.registrarAuditoria('COM_MAEPROVEEDOR', this.proveedorSeleccionado.PRO_CODIGO+'/'+
                      data.name+'/'+ (this.lblvalida === null || this.lblvalida === undefined?'':this.lblvalida)
                      , 'I',
                      '', '01', '', '', '', '').subscribe();
                    }else{
                      if(data.codigo === 'PRO_FECING'){
                        this.auditoriagral.registrarAuditoria('COM_MAEPROVEEDOR', this.proveedorSeleccionado.PRO_CODIGO+'/'+
                        data.name+'/'+ (this.proveedorSeleccionado[data.codigo] === null || this.proveedorSeleccionado[data.codigo] === undefined?'': this.proveedorSeleccionado[data.codigo])
                        , 'I',
                        '', '01', '', '', '', '').subscribe();
                      }else{
                        this.auditoriagral.registrarAuditoria('COM_MAEPROVEEDOR', this.proveedorSeleccionado.PRO_CODIGO+'/'+
                        data.name+'/'+ (this.proveedorSeleccionado[data.codigo] === null || this.proveedorSeleccionado[data.codigo] === undefined?'':this.proveedorSeleccionado[data.codigo])
                        , 'I',
                        '', '01', '', '', '', '').subscribe();
                      }
                     
                    }
                  }
                  
                }
            }, error1 => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.messageService.add({
                key: 'commaeproveedor',
                severity: 'error',
                summary: 'Inserción de Proveedor',
                detail: mensaje
              });
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
              this.btnPrimero = false;
              this.btnSiguiente = false;
              this.btnAnterior = false;
              this.btnUltimo = false;
            });
          }

        }
      });

    } else if (this.permisoProveedor.PERACTUALIZACION === 0) {
      this.messageService.add({
        key: 'commaeproveedor',
        severity: 'error',
        summary: 'Actualización de Proveedor',
        detail: 'El usuario no tiene permisos de actualización, acción denegada'
      });
    }
  }
//CDPJ
async validaCntaCont() {
  let errores = 0;
  if (this.proveedorSeleccionado.CON_CODIGO1 !== null && this.proveedorSeleccionado.CON_CODIGO1 !== undefined && this.proveedorSeleccionado.CON_CODIGO1 !== '') {
    const data = await this.proveedorServicio.EncontrarCnta(this.proveedorSeleccionado.CON_CODIGO1);
    if (data !== null && data !== undefined) {
      for (const data1 of data) {
        if (Number(data1.NUMERO) === 0) {
          this.messageService.add({
            key: 'commaeproveedor',
            severity: 'error',
            summary: 'Insertar artículo',
            detail: 'Error, la cuenta contable ' + this.proveedorSeleccionado.CON_CODIGO1 + ', no existe'
          });
          errores++;
        }
      }
    }
  }
  if (this.proveedorSeleccionado.CON_CODIGO2 !== null && this.proveedorSeleccionado.CON_CODIGO2 !== undefined && this.proveedorSeleccionado.CON_CODIGO2 !== '') {
    const data = await this.proveedorServicio.EncontrarCnta(this.proveedorSeleccionado.CON_CODIGO2);
    if (data !== null && data !== undefined) {
      for (const data1 of data) {
        if (Number(data1.NUMERO) === 0) {
          this.messageService.add({
            key: 'commaeproveedor',
            severity: 'error',
            summary: 'Insertar artículo',
            detail: 'Error, la cuenta contable ' + this.proveedorSeleccionado.CON_CODIGO2 + ', no existe'
          });
          errores++;
        }
      }
    }
  }
  
  return errores;
}
//CDPJ
  eliminar() {

    if (this.permisoProveedor.PERELIMACION === 1) {
      if (this.proveedorSeleccionado === undefined) {
        this.messageService.add({
          key: 'commaeproveedor',
          severity: 'error',
          summary: 'Eliminacion de Proveedor',
          detail: 'Seleccion Incorrecta, no se ha seleccionado un proveedor'
        });
      } else {
        this.confirmarProveedor();
      }
    } else if (this.permisoProveedor.PERELIMACION === 0) {
      this.messageService.add({
        key: 'commaeproveedor',
        severity: 'error',
        summary: 'Eliminación de Proveedor',
        detail: 'El usuario no tiene permisos de eliminación, acción denegada'
      });
    }
  }

  async manejarSenales(valor) {

    if (this.index === 5) {
      return;
    }

    if (this.index !== 6) {
      if (valor === 'Primero') {
        this.seleccionarRegistro(valor);
      }
      if (valor === 'Anterior') {
        this.seleccionarRegistro(valor);
      }
      if (valor === 'Siguiente') {
        this.seleccionarRegistro(valor);
      }
      if (valor === 'Ultimo') {
        this.seleccionarRegistro(valor);
      }
      if (valor === 'Buscar') {
        this.buscarProveedor();
      }
      if (valor === 'Nuevo') {
        this.nuevo();
      }
      if (valor === 'Cancelar') {
        this.cancelar();
      }
      if (valor === 'Guardar') {
        this.guardar();
      }
      if (valor === 'Borrar') {
        this.eliminar();
      }
    } else {
      this.accionesRetencion(valor);
    }

    if (valor === 'Almacenardoc') {
      if (this.proveedorSeleccionado.PRO_CODIGO !== '') {
        const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.messageService.add({
            key: 'commaeproveedor',
            severity: 'info',
            summary: 'Información',
            detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
          });
        }

      }
    }

    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }

  accionesRetencion(valor) {

    if (valor === 'Nuevo') {
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.btnRegresar = false;
      this.nuevoCampo = true;
      const numero = this.proveedorServicio.commaeproveedorRt.length;
      this.retencionSeleccionada = {
        PRO_CODIGO: this.proveedorSeleccionado.PRO_CODIGO,
        RETENCION_CODIGO: '',
        RETENCION_NOMBRE: '',
        LINEA: Number(numero + 1)

      };

      this.proveedorServicio.commaeproveedorRt.push(this.retencionSeleccionada);
      this.aggrid.refreshaggrid(this.proveedorServicio.commaeproveedorRt, this.defaultProvRt.objeto);
    }

    if (valor === 'Guardar') {

      this.proveedorServicio.commaeproveedorRt.map(aux => {
        if (aux.RETENCION_NOMBRE === this.retencionSeleccionada.RETENCION_NOMBRE) {
          if (this.proveedorServicio.commaeproveedorRt.length - 1 !== this.proveedorServicio.commaeproveedorRt.indexOf(aux)) {

            this.messageService.add({
              key: 'commaeproveedor',
              severity: 'error',
              summary: 'Retención Ingresada',
              detail: 'La retención ya existe'
            });
          }
          this.manejarSenales('Cancelar');

        }
        if (this.proveedorServicio.commaeproveedorRt.length - 1 === this.proveedorServicio.commaeproveedorRt.indexOf(aux)) {
          this.proveedorServicio.insertarRetencion(this.retencionSeleccionada, this.proveedorSeleccionado.PRO_CODIGO).subscribe(
            res => {
              this.messageService.add({
                key: 'commaeproveedor',
                severity: 'success',
                summary: 'Retención Ingresada',
                detail: 'Se ingresó correctamente la retención'
              });
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.manejarSenales('Cancelar');
            });
        }

      });
      const fecha = this.datePipe.transform(this.strFecha, 'dd/MM/yyyy');
      this.proveedorServicio.actualizarProveedor(this.proveedorSeleccionado, fecha).subscribe(() => {

      });
    }
    if (valor === 'Cancelar') {
      this.llenartablaRetenciones();
      this.btnNuevo = false;
    }
    if (valor === 'Borrar') {
      // eliminarRetencion
      if (this.retencionSeleccionada === undefined) {
        this.messageService.add({
          key: 'commaeproveedor',
          severity: 'error',
          summary: 'Eliminacion de Retencion',
          detail: 'Seleccion Incorrecta, no se ha seleccionado una retencion'
        });
      } else {
        this.confirmarRetencion();
      }
    }

    if (valor.object !== undefined) {
      this.retencionSeleccionada = valor.object;

    }
  }
  booleanosAnexos(accion) {
    if (this.selecttiporegfis.codigo === '01') {
      //CDPJ
      this.regpais = false;
      this.paraisofiscal = true;
      this.denominacion = false;
      this.regdeno = true;//CDPJ
    } else if (this.selecttiporegfis.codigo === '02') {
      this.regpais = true;
      this.paraisofiscal = false;
      this.denominacion = false;
      this.regdeno = true;//CDPJ

    } else if (this.selecttiporegfis.codigo === '03') {
      //CDPJ
      this.regpais = true;
      this.paraisofiscal = true;
      this.denominacion = false;
      this.regdeno = false;//CDPJ
    } else {
      //CDPJ
      this.regpais = true;
      this.paraisofiscal = true;
      this.denominacion = true;
      this.regdeno = true;//CDPJ
      this.conparaisocod = '';
      this.conparaisonombre = '';
      this.conepaisnombre = '';
      this.conepaiscod = '';
      this.conpaisnombre = '';
      this.conpaiscod = '';
    }
    if (accion === 'CMB') {
      this.conparaisocod = '';
      this.conparaisonombre = '';
      this.conepaisnombre = '';
      this.conepaiscod = '';
      this.conpaisnombre = '';
      this.conpaiscod = '';
      this.selectconvdobletrib = { codigo: '', name: '' };//DEPENDERÁ DE LO QUE INDIQUE EL SRI
      this.selectpagoexterior = { codigo: '', name: '' };//DEPENDERÁ DE LO QUE INDIQUE EL SRI
      this.proveedorSeleccionado.PRO_DENOMINACION = '';
    }
  }
  confirmarRetencion() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar la retencion',
      header: 'Eliminar Retencion',
      icon: 'pi pi-exclamation-triangle',
      key: 'commaeproveedorConfirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarRetencion();
      },
      reject: () => {
      }
    });
  }

  confirmarProveedor() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el Proveedor',
      header: 'Eliminar Proveedor',
      icon: 'pi pi-exclamation-triangle',
      key: 'commaeproveedorConfirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarProveedor();
      },
      reject: () => {
      }
    });
  }

  actualizarIndice() {
    this.proveedorServicio.getProveedor().subscribe((res) => {

      this.proveedorServicio.commaeproveedor = res;
      this.proveedorServicio.commaeproveedor.map((prove) => {
        if (prove.PRO_CODIGO === this.proveedorSeleccionado.PRO_CODIGO) {
          this.indice = this.proveedorServicio.commaeproveedor.indexOf(prove);
        }
      });
      this.proveedorSeleccionado = this.proveedorServicio.commaeproveedor[this.indice];
      this.proveedorSeleccionadoAux = this.proveedorServicio.commaeproveedor[this.indice];
      this.codigoAnt = this.proveedorSeleccionado.PRO_CODIGO;
    });
  }

  seleccionarCuenta() {
    if (this.proveedorSeleccionado.CON_CODIGO1.length === 0) {
      this.proveedorServicio.seleccionarCuenta().subscribe(res => {
        if (res !== null) {
          res.map(aux => {
            this.proveedorSeleccionado.CON_CODIGO1 = aux.CON_CODIGO1 == null ? '' : aux.CON_CODIGO1;
          });
        } else {
        }
      });
    }
  }

  valoresporDefecto() {

    if (this.proveedorSeleccionado.PRO_CONTRIBUYENTE.length === 0) {
      this.proveedorSeleccionado.PRO_CONTRIBUYENTE = 'N';
    }
    if (this.proveedorSeleccionado.PRO_LIMCREDIT.length === 0) {
      this.proveedorSeleccionado.PRO_LIMCREDIT = '0';
    }
    if (this.proveedorSeleccionado.PRO_DIACREDIT.length === 0) {
      this.proveedorSeleccionado.PRO_DIACREDIT = '0';
    }

    if (this.proveedorSeleccionado.PRO_DESCUENTO.length === 0) {
      this.proveedorSeleccionado.PRO_DESCUENTO = '0';
    }
    if (this.proveedorSeleccionado.PRO_IVA.length === 0) {
      this.proveedorSeleccionado.PRO_IVA = '1';
    }

    if (this.proveedorSeleccionado.PRO_TIPOIDE.length === 0) {
      this.proveedorSeleccionado.PRO_TIPOIDE = '1';
      for (const j of this.cmbide) {
        if (this.proveedorSeleccionado.PRO_TIPOIDE === j.codigo) {
          this.selectide = { codigo: j.codigo, name: j.name };
          this.selectideti = { codigo: j.codigo, name: j.name };
        }
      }
    }
  }

  eliminarRetencion() {
    this.proveedorServicio.eliminarRetencion(this.retencionSeleccionada, this.proveedorSeleccionado.PRO_CODIGO).subscribe(res => {
      this.messageService.add({
        key: 'commaeproveedor',
        severity: 'success',
        summary: 'Eliminación de la retención',
        detail: 'Se ha eliminado correctamente la retención'
      });
      this.llenartablaRetenciones();
    });
  }

  eliminarProveedor() {
    if (this.proveedorSeleccionado.PRO_CODIGO === '') {
      this.messageService.add({
        key: 'commaeproveedor',
        severity: 'error',
        summary: 'Eliminación de Proveedor',
        detail: 'No se ha seleccionado un proveedor para eliminar'
      });
    } else if (this.proveedorSeleccionado.PRO_CODIGO !== '') {
      this.proveedorServicio.eliminarProveedor(this.proveedorSeleccionado).subscribe(() => {
        this.proveedorServicio.getProveedor().subscribe((res) => {

          this.proveedorServicio.commaeproveedor = res;
          this.btnAnterior = true;
          this.btnPrimero = true;
          this.btnUltimo = false;
          this.btnSiguiente = false;
          this.btnRegresar = false;
          this.indice = 0;
          this.proveedorSeleccionado = this.proveedorServicio.commaeproveedor[this.indice];
          this.proveedorSeleccionadoAux = this.proveedorServicio.commaeproveedor[this.indice];
          this.codigoAnt = this.proveedorSeleccionado.PRO_CODIGO;
          this.seleccionarCheks();
          this.seleccionarCombo();
          this.llenarlabels();
          this.llenarCash();
          this.messageService.add({
            key: 'commaeproveedor',
            severity: 'success',
            summary: 'Eliminación de Proveedor',
            detail: 'Se ha eliminado el proveedor'
          });

        });


        this.auditoriagral.registrarAuditoria('COM_MAEPROVEEDOR', this.proveedorSeleccionado.PRO_CODIGO, 'E',
          '', '01', '', '', '', '').subscribe(() => {
          });
        this.btnNuevo = false;
        this.btnGuardar = true;
        this.btnBorrar = false;
        this.btnRegresar = true;
        this.nuevoProveedor();
      }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'commaeproveedor',
          severity: 'error',
          summary: 'Eliminación de Proveedor',
          detail: mensaje
        });
        this.btnNuevo = false;
        this.btnGuardar = true;
        this.btnBorrar = false;
        this.btnRegresar = true;
      });
    }
  }

  setFocus(event) {
    let element: any;
    switch (event.target.id) {
      case 'PRO_RUCIDE': {
        element = document.getElementById('PRO_NOMBRE');
        break;
      }
      case 'PRO_NUMAUTORIZACION': {
        element = document.getElementById('PRO_OBSERVACION');
        break;
      }
      case 'PRO_NUMSERIE': {
        element = document.getElementById('PRO_NUMAUTORIZACION');
        break;
      }
      case 'PRO_DESCUENTO': {
        element = document.getElementById('PRO_LIMCREDIT');
        break;
      }
      case 'PRO_LIMCREDIT': {
        element = document.getElementById('PRO_DIACREDIT');
        break;
      }
      case 'PRO_DIACREDIT': {
        element = document.getElementById('CON_CODIGO1');
        break;
      }
      case 'COM_NUMCTACM': {
        element = document.getElementById('COM_BENFCM');
        break;
      }
      case 'COM_DIFBENFCM': {
        element = document.getElementById('PRO_CIUDADCM');
        break;
      }
      case 'PRO_CIUDADCM': {
        element = document.getElementById('COM_TELFBENFCM');
        break;
      }
      case 'COM_TELFBENFCM': {
        element = document.getElementById('PRO_LOCPAGCM');
        break;
      }
      case 'PRO_LOCPAGCM': {
        element = document.getElementById('PRO_EMAILCM');
        break;
      }
      case 'PRO_CODIGO': {
        element = document.getElementById('PRO_NOMBRE');
        break;
      }
      case 'PRO_NOMBRE': {
        if (this.proveedorSeleccionado.PRO_NOMBRE.length > 0) {
          element = document.getElementById('PRO_NOMBREC');
          this.proveedorSeleccionado.PRO_NOMBREC = this.proveedorSeleccionado.PRO_NOMBRE.substr(0, 12);
        }
        break;
      }
      case 'PRO_NOMBREC': {
        element = document.getElementById('PRO_CONTACTO');
        break;
      }
      case 'PRO_CONTACTO': {
        element = document.getElementById('GRU_CODIGO');
        break;
      }
      case 'busquedagrupo': {
        element = document.getElementById('PRO_DIRECCION1');
        break;
      }
      case 'PRO_DIRECCION1': {
        element = document.getElementById('PRO_DIRECCION2');
        break;
      }
      case 'PRO_DIRECCION2': {
        element = document.getElementById('PRO_TELEFONO1');
        break;
      }
      case 'PRO_TELEFONO1': {
        element = document.getElementById('PRO_TELEFONO2');
        break;
      }
      case 'PRO_TELEFONO2': {
        element = document.getElementById('PRO_FAX');
        break;
      }
      case 'PRO_FAX': {
        element = document.getElementById('PRO_CODPOSTAL');
        break;
      }
      case 'PRO_CODPOSTAL': {
        element = document.getElementById('PRO_CORREO');
        break;
      }
      case 'PRO_CORREO': {
        element = document.getElementById('PRO_CORREO2');
        break;
      }
      case 'PRO_CORREO2': {
        element = document.getElementById('PRO_ZONA');
        break;
      }


      default: {
        break;
      }
    }

    if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }
  }

  botonesmodificar() {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = true;
    this.btnRegresar = false;
    this.btnPrimero = true;
    this.btnAnterior = true;
    this.btnSiguiente = true;
    this.btnUltimo = true;
    this.btnBuscar = true;
    this.disabledEstadisticas = true;

  }

  botmodclirucide() {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = true;
    this.btnRegresar = false;
    this.btnPrimero = true;
    this.btnAnterior = true;
    this.btnSiguiente = true;
    this.btnUltimo = true;
    this.btnBuscar = true;
    this.disabledEstadisticas = true;
    this.lblvalida = '';
  }

  busquedagrupo() {
    this.opcionbusqueda = 'busquedagrupo';
    this.types = [
      { label: 'Código', value: 'GRU_CODIGO' },
      { label: 'Nombre', value: 'GRU_NOMBRE' }
    ];
    this.tabla = 'VEN_MAEGRUPO';


    this.proveedorServicio.erbusquedagrupo(this.proveedorSeleccionado.GRU_CODIGO).subscribe(eR => {
      if (eR !== null) {
        this.grupnombre = eR[0].GRU_NOMBRE;
        document.getElementById('PRO_DIRECCION1').focus();

      } else {
        this.busqService.busquedaVenMaeGrupo1().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusqueda = true;
        });
      }
    });

  }

  busquedazona() {
    this.opcionbusqueda = 'busquedazona';
    this.types = [
      { label: 'CÓDIGO', value: 'REF_CODIGO' },
      { label: 'NOMBRE', value: 'REF_NOMBRE' }
    ];
    this.tabla = 'SACI_MAEREFERENCIA';

    this.proveedorServicio.erZona(this.proveedorSeleccionado.PRO_ZONA).subscribe(eR => {
      if (eR !== null) {
        this.zonanombre = eR[0].REF_NOMBRE;

      } else {
        this.busqService.busquedaSaciMaeReferencia1().subscribe((res: any[]) => {
          this.arregloCons = res;
          this.displayDialogBusqueda = true;
        });
      }
    });
  }

  busquedatipopcodigo() {

    this.opcionbusqueda = 'busquedatipop';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Anio', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'CodigoCon', value: 'CON_CODIGO' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.proveedorServicio.ertipopcodigo(this.proveedorSeleccionado.PRO_TIPOA).subscribe(eR => {
      if (eR !== null) {
        this.manejarSeleccion(eR[0]);

      } else {
        this.busqService.busquedaAnexMaeCodSri7().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }

    });
  }

  busquedaconcodigo(concodigo) {
    this.opcionbusqueda = 'busquedaconcodigo' + concodigo;
    this.types = [
      { label: 'Código', value: 'CON_CODIGO' },
      { label: 'Nombre', value: 'CON_NOMBRE' },
      { label: 'Cnt.Costos', value: 'CON_CENTRO' },
      { label: 'Cod.Sri', value: 'CON_CODSRI' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'CON_MAECON';
    if (concodigo === '1') {
      this.proveedorServicio.erconCodigo(this.proveedorSeleccionado.CON_CODIGO1).subscribe(eR => {
        if (eR !== null) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaConMaeCon3().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }

      });
    }
    if (concodigo === '2') {
      this.proveedorServicio.ercon2Codigo(this.proveedorSeleccionado.CON_CODIGO2).subscribe(eR => {

        if (eR !== null) {
          this.manejarSeleccion(eR[0]);

        } else {
          this.busqService.busquedaConMaeCon3().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }

      });

    }

  }

  busquedacencodigo() {
    this.opcionbusqueda = 'busquedacencodigo';
    this.types = [
      { label: 'Código', value: 'CEN_CODIGO' },
      { label: 'Nombre', value: 'CEN_NOMBRE' }
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'CON_MAECEN';
    this.proveedorServicio.ercencodigo(this.proveedorSeleccionado.CEN_CODIGO).subscribe(eR => {

      if (eR !== null) {
        this.cennombre = eR[0].CEN_NOMBRE;

        document.getElementById('PRO_NUMSERIE').focus();
      } else {
        this.busqService.busquedaConMaeCen3().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });

  }

  buscarProveedor() {
    this.opcionbusqueda = 'busquedaproveedor';
    this.types = [
      { label: 'Código', value: 'PRO_CODIGO' },
      { label: 'Nombre', value: 'PRO_NOMBRE' },
      { label: 'Nombre Corto', value: 'PRO_NOMBREC' },
      { label: 'RUC', value: 'PRO_RUCIDE' },
      { label: 'Grupo', value: 'GRU_CODIGO' },
      { label: 'RUC', value: 'PRO_RUCIDE' },
      { label: 'Cuenta', value: 'CON_CODIGO1' }
      ,
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'COM_MAEPROVEEDOR';

    this.busqService.busquedaComMaeProveedor7().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }


  busquedapagoLocaloExterior() {
    this.opcionbusqueda = 'busquedapLocaloExterior';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Anio', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'CodigoCon', value: 'CON_CODIGO' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.proveedorServicio.erpagoLocaloExterior(this.proveedorSeleccionado.PRO_PAGOLOCALEXT).subscribe(eR => {
      if (eR !== null) {
        this.conlnombre = eR[0].CODSRI_DESCRIPCION;
        document.getElementById('cmbTipoRegFiscal').focus();


      } else {
        this.busqService.busquedaAnexMaeCodSri8().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });


  }

  busquedapagoPais() {
    this.opcionbusqueda = 'busquedapagoPais';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Anio', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'CodigoCon', value: 'CON_CODIGO' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';

    this.proveedorServicio.erPagoPais(this.conpaiscod).subscribe(eR => {
      if (eR !== null) {
        this.conpaisnombre = eR[0].CODSRI_DESCRIPCION;


      } else {
        this.busqService.busquedaAnexMaeCodSri9().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });

  }

  busquedapagoParaiso() {
    this.opcionbusqueda = 'busquedapagoParaiso';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Anio', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'CodigoCon', value: 'CON_CODIGO' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';

    this.proveedorServicio.erpagoParaiso(this.conparaisocod).subscribe(eR => {
      if (eR !== null) {
        this.conparaisonombre = eR[0].CODSRI_DESCRIPCION;


      } else {
        this.busqService.busquedaAnexMaeCodSri10().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });

  }

  busquedapagoEfectuadoPais() {
    this.opcionbusqueda = 'busquedapagoEfectuadoPais';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Anio', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'CodigoCon', value: 'CON_CODIGO' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';

    this.proveedorServicio.erpagoEfectuadoPais(this.conepaiscod).subscribe(eR => {
      if (eR !== null) {
        this.conepaisnombre = eR[0].CODSRI_DESCRIPCION;


      } else {
        this.busqService.busquedaAnexMaeCodSri11().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }


  manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'busquedagrupo') {
      this.proveedorSeleccionado.GRU_CODIGO = opcion.GRU_CODIGO;
      this.grupnombre = opcion.GRU_NOMBRE;
      this.botonesmodificar();
      document.getElementById('PRO_DIRECCION1').focus();
    }
    if (this.opcionbusqueda === 'busquedaproveedor') {
      this.proveedorServicio.commaeproveedor.map((prove) => {
        if (prove.PRO_CODIGO === opcion.PRO_CODIGO) {
          this.indice = this.proveedorServicio.commaeproveedor.indexOf(prove);
        }
      });
      this.proveedorSeleccionado = this.proveedorServicio.commaeproveedor[this.indice];
      this.proveedorSeleccionadoAux = this.proveedorServicio.commaeproveedor[this.indice];
      this.codigoAnt = this.proveedorSeleccionado.PRO_CODIGO;
      //CDPJ
      this.proveedorSeleccionado.PRO_PARTEREL = this.proveedorSeleccionado.PRO_PARTEREL === null ? '' : this.proveedorSeleccionado.PRO_PARTEREL
      this.proveedorSeleccionado.PRO_TIPOIDE = this.proveedorSeleccionado.PRO_TIPOIDE === null ? '' : this.proveedorSeleccionado.PRO_TIPOIDE
      this.proveedorSeleccionado.PRO_CONTRIBUYENTE = this.proveedorSeleccionado.PRO_CONTRIBUYENTE === null ? '' : this.proveedorSeleccionado.PRO_CONTRIBUYENTE
      this.proveedorSeleccionado.PRO_DENOMINACION = this.proveedorSeleccionado.PRO_DENOMINACION === null ? '' : this.proveedorSeleccionado.PRO_DENOMINACION
      this.proveedorSeleccionado.PRO_CONVDOBLETRIB = this.proveedorSeleccionado.PRO_CONVDOBLETRIB === null ? '' : this.proveedorSeleccionado.PRO_CONVDOBLETRIB
      this.proveedorSeleccionado.PRO_PAGOEXTERIOR = this.proveedorSeleccionado.PRO_PAGOEXTERIOR === null ? '' : this.proveedorSeleccionado.PRO_PAGOEXTERIOR
      this.proveedorSeleccionado.COM_FORPAGCM = this.proveedorSeleccionado.COM_FORPAGCM === null ? '' : this.proveedorSeleccionado.COM_FORPAGCM
      this.proveedorSeleccionado.COM_BANCODIGOCM = this.proveedorSeleccionado.COM_BANCODIGOCM === null ? '' : this.proveedorSeleccionado.COM_BANCODIGOCM
      this.proveedorSeleccionado.COM_TIPOCTACM = this.proveedorSeleccionado.COM_TIPOCTACM === null ? '' : this.proveedorSeleccionado.COM_TIPOCTACM
      this.proveedorSeleccionado.PRO_TIPOREGFISCAL = this.proveedorSeleccionado.PRO_TIPOREGFISCAL === null ? '' : this.proveedorSeleccionado.PRO_TIPOREGFISCAL
      //CDPJ
      this.seleccionarCombo();
      this.seleccionarCheks();
      this.llenarlabels();
      this.llenarCash();
      this.nuevoproveedor = true;

      this.disabledEstadisticas = false;
    }
    if (this.opcionbusqueda === 'busquedazona') {
      this.proveedorSeleccionado.PRO_ZONA = opcion.REF_CODIGO;
      this.zonanombre = opcion.REF_NOMBRE;
      this.botonesmodificar();
    }
    if (this.opcionbusqueda === 'busquedapagoPais') {
      this.conpaiscod = opcion.CODSRI_CODIGO;
      //this.proveedorSeleccionado.PRO_PAISPAGO = opcion.CODSRI_CODIGO;
      this.proveedorSeleccionado.PRO_CODPAISREGGEN = opcion.CODSRI_CODIGO;
      this.proveedorSeleccionado.PRO_CODPAISPARFIS = '';
      this.proveedorSeleccionado.PRO_DENOMINACION = '';
      //this.conepaiscod = opcion.CODSRI_CODIGO;
      //this.conepaisnombre = opcion.CODSRI_DESCRIPCION;
      this.conpaisnombre = opcion.CODSRI_DESCRIPCION;
      this.botonesmodificar();
    }
    if (this.opcionbusqueda === 'busquedapagoEfectuadoPais') {
      this.proveedorSeleccionado.PRO_CODPAISREGGEN = '';
      this.proveedorSeleccionado.PRO_CODPAISPARFIS = '';
      this.proveedorSeleccionado.PRO_PAISPAGO = opcion.CODSRI_CODIGO;
      this.conepaisnombre = opcion.CODSRI_DESCRIPCION;
      this.conepaiscod = opcion.CODSRI_CODIGO;
      this.botonesmodificar();
    }
    if (this.opcionbusqueda === 'busquedapagoParaiso') {
      //this.proveedorSeleccionado.PRO_PAISPAGO = opcion.CODSRI_CODIGO;
      this.conparaisocod = opcion.CODSRI_CODIGO;
      this.conparaisonombre = opcion.CODSRI_DESCRIPCION;
      this.proveedorSeleccionado.PRO_CODPAISREGGEN = '';
      this.proveedorSeleccionado.PRO_CODPAISPARFIS = opcion.CODSRI_CODIGO;
      this.proveedorSeleccionado.PRO_DENOMINACION = '';
      //this.conepaiscod = opcion.CODSRI_CODIGO;
      //this.conepaisnombre = opcion.CODSRI_DESCRIPCION;
      this.botonesmodificar();
    }

    if (this.opcionbusqueda === 'busquedacencodigo') {
      this.proveedorSeleccionado.CEN_CODIGO = opcion.CEN_CODIGO;
      this.cennombre = opcion.CEN_NOMBRE;
      this.botonesmodificar();
      document.getElementById('PRO_NUMSERIE').focus();
    }

    if (this.opcionbusqueda === 'busquedapLocaloExterior') {
      this.proveedorSeleccionado.PRO_PAGOLOCALEXT = opcion.CODSRI_CODIGO;
      this.conlnombre = opcion.CODSRI_DESCRIPCION;
      this.botonesmodificar();
      //CDPJ
      if (this.proveedorSeleccionado.PRO_PAGOLOCALEXT === '01') {
        this.boolcmbtiporegimen = true;
        this.selecttiporegfis = { codigo: '', name: '' };
        this.selectconvdobletrib = { codigo: '', name: '' };//DEPENDERÁ DE LO QUE INDIQUE EL SRI
        this.selectpagoexterior = { codigo: '', name: '' };//DEPENDERÁ DE LO QUE INDIQUE EL SRI
        this.proveedorSeleccionado.PRO_DENOMINACION = '';
        this.booleanosAnexos('CARGAR');
        //this.habilitar('CARGAR');
      } else {
        this.boolcmbtiporegimen = false;
      }
      //CDPJ
    }
    if (this.opcionbusqueda === 'busquedaconcodigo1') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'commaeproveedor',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
        this.proveedorSeleccionado.CON_CODIGO1 = '';
        this.connombre = '';
      } else {
        this.proveedorSeleccionado.CON_CODIGO1 = opcion.CON_CODIGO;
        this.connombre = opcion.CON_NOMBRE;
        document.getElementById('CON_CODIGO2').focus();
      }
      this.botonesmodificar();
    }
    if (this.opcionbusqueda === 'busquedaconcodigo2') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'commaeproveedor',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
        this.proveedorSeleccionado.CON_CODIGO2 = '';
        this.con2nombre = '';
      } else {
        this.proveedorSeleccionado.CON_CODIGO2 = opcion.CON_CODIGO;
        this.con2nombre = opcion.CON_NOMBRE;
        document.getElementById('CEN_CODIGO').focus();
      }
      this.botonesmodificar();
    }
    if (this.opcionbusqueda === 'busquedatipop') {
      this.proveedorSeleccionado.PRO_TIPOA = opcion.CODSRI_CODIGO;
      document.getElementById('PRO_DESCUENTO').focus();
      this.botonesmodificar();
    }

    if (this.opcionbusqueda === 'articulo') {
      this.txtArtCodigo = opcion.ART_CODIGO;
      this.txtArtDescripcion = opcion.ART_NOMBRE;
      this.txtArtUnidad = opcion.ART_UNIDADCOSTEO;
      this.txtExistenciaMin = opcion.ART_CANTMIN === null ? 0 : opcion.ART_CANTMIN;
      this.txtExistenciaMax = opcion.ART_CANTMAX === null ? 0 : opcion.ART_CANTMAX;
      this.llenarTablaExistencia();
      this.llenarMovByProvArt('fecha');
      this.llenarExistenciaBodegas();
      this.ventasVsCompras();
      this.llenarIndiceProvisiones();
    }

    this.displayDialogBusqueda = false;
    this.displayDialogBusquedaFast = false;
  }

  nuevoProveedor() {
    this.lblvalida = '';
    this.proveedorSeleccionado = {
      PRO_CODIGO: '', GRU_CODIGO: '', GRU_NOMBRE: '', PRO_NOMBREC: '',
      PRO_NOMBRE: '', PRO_RUCIDE: '', PRO_DIRECCION1: '', PRO_DIRECCION2: '',
      PRO_CODPOSTAL: '', PRO_TELEFONO1: '', PRO_TELEFONO2: '', PRO_FAX: '',
      PRO_CORREO: '', PRO_CONTACTO: '', PRO_FECING: '', PRO_LIMCREDIT: '',
      PRO_DIACREDIT: '', PRO_DESCUENTO: '', PRO_IVA: '', PRO_CONTRIBUYENTE: '',
      PRO_LISTA: '', CON_CODIGO1: '', CON_CODIGO2: '', PRO_ZONA: '',
      PRO_OBSERVACION: '', PRO_NUMSERIE: '', PRO_NUMAUTORIZACION: '', PRO_TIPOIDE: '',
      CEN_CODIGO: '', PRO_TIPO: '', PRO_FLAG: '', PRO_BONIFICACION: '',
      COM_CODIGO: '', GRU_TIPO: '', COM_BANCODIGOCM: '', COM_TIPOCTACM: '',
      COM_NUMCTACM: '', COM_FORPAGCM: '', COM_BENFCM: '',
      COM_DIFBENFCM: '', COM_TELFBENFCM: '', PRO_TIPOIDENTIFCM: '', PRO_IDCM: '',
      PRO_CIUDADCM: '', PRO_LOCPAGCM: '', PRO_EMAILCM: '',
      PRO_REGCOND: '', PRO_TIPOA: '',
      PRO_PARTEREL: '', CON_CODIGO_RET332: '', PRO_CORREO2: '', PRO_FACTURAELECT: '',
      PRO_TIPODEN: '', PRO_TRANSPORTE: '', PRO_PAGOLOCALEXT: '', PRO_TIPOREGFISCAL: '',
      PRO_CODPAISREGGEN: '', PRO_CODPAISPARFIS: '', PRO_DENOMINACION: '', PRO_PAISPAGO: '',
      PRO_CONVDOBLETRIB: '', PRO_PAGOEXTERIOR: '', PRO_PAGOREGFISCAL: '', PRO_TIPOIDENOMBRE: '',
      PRO_CONREGMIC: '', PRO_AGENTERET: ''
    };
    this.proveedorSeleccionado.PRO_FECING = new Date();
    this.strFecha = new Date();
    this.valoresporDefecto();
    this.seleccionarCuenta();
    this.seleccionarCheks();
    this.proveedorServicio.commaeproveedorADM = [];
    this.proveedorServicio.commaeproveedorEC = [];
    this.proveedorServicio.commaeproveedorSALDO = [];
  }

  async changeTabs() {
    this.flagCashM = false;
    if (this.index === 1) {
      this.llenartablADM();
    }
    if (this.index === 2) {
      if (this.bolestcta === false) {
        this.cxctmptrnresumen = [];
        this.bolestcta = true;
        this.cxctmptrnresumen = await this.proveedorServicio.llenarEstadoCuenta(this.proveedorSeleccionado.PRO_CODIGO);
      }
    }
    if (this.index === 3) {
      if (this.bolsaldo === false) {
        this.cxctrnresumen = [];
        this.bolsaldo = true;
        this.cxctrnresumen = await this.proveedorServicio.llenarTablaSaldos(this.proveedorSeleccionado.PRO_CODIGO);
      }
    }
    if (this.index === 5) {
      this.llenartablaMOV();
    }
    if (this.index === 6) {

      this.btnNuevo = false;
      this.llenartablaRetenciones();
    }
    if (this.index === 7) {
      this.llenartablaAnexos();
    }

    if (this.index === 5) {

      this.dataComprasProveedor = {
        labels: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
        datasets: [{
          label: 'Ventas',
          backgroundColor: '#42A5F5',
          borderColor: '#1E88E5',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
        ]
      };

      this.dataVentasVsCompras = {
        labels: ['', ''],
        datasets: [
          {
            label: 'Ventas',
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [0]
          },
          {
            label: 'Compras',
            backgroundColor: '#9CCC65',
            borderColor: '#7CB342',
            data: [0]
          }
        ]
      };

      this.detTopProductos = [];
      this.detalleExistencia = [];
      this.detMovimientosProv = [];
      this.detExistenciaBodegas = [];
      this.txtArtCodigo = '';
      this.txtArtDescripcion = '';
      this.txtExistenciaMax = '';
      this.txtExistenciaMin = '';
      this.txtArtUnidad = '';
      this.txtArtRotacion = '';
      this.txtInventarioIni = '';
      this.txtComprasPeriodo = '';
      this.txtTotalDisponible = '';
      this.txtVenUltimoPer = '';
      this.txtVenPromPerAnt = '';
      this.txtIndiceProvUltPer = '';
      this.txtIndiceProvPeriodos = '';
      this.txtComprasPlanificadas = '';
      this.txtTotalDisponibleCom = '';
      this.txtIndiceProvUltPer2 = '';
      this.txtIndiceProvPeriodos2 = '';

      const existencias: ComExistencias = {};
      existencias.COMPRAR = '';
      existencias.EXIST_MIN = '';
      existencias.EXISTENCIA = '';
      existencias.ORDENES_COMPRA = '';
      existencias.PEDIDOS_CLIENTES = '';
      existencias.SALDO = '';
      existencias.TOTAL_COMPRA = '';
      existencias.ULTCOSTO_COM = '';
      this.detalleExistencia.push(existencias);

    }
  }

  // COMBOS
  llenarcomboanex1() {
    this.cmbanex1 = this.cmbanexaux;
  }

  llenarcomboanex2() {
    this.cmbanex2 = this.cmbanexaux;
  }


  llenarCombos() {
    // this.cmbide = [{ codigo: ' ', name: ' ' }, { codigo: '1', name: 'R.U.C.' },
    // { codigo: '2', name: 'Cédula' },
    // { codigo: '3', name: 'Pasaporte' }];

    // this.cmbcontri = [{ codigo: 'E', name: 'Especial' },
    // { codigo: 'N', name: 'Normal' }];
    // this.cmbparteR = [{ codigo: 'SI', name: 'SI' },
    // { codigo: 'NO', name: 'NO' },
    // { codigo: ' ', name: ' ' }];
    // this.cmbdeno = [
    //   { codigo: 'N', name: 'Natural' },
    //   { codigo: 'S', name: 'Sociedad' },
    //   { codigo: ' ', name: '  ' }];
    // this.cmbcampoaux = [{ codigo: '1', name: 'Fecha' },
    // { codigo: '2', name: 'Tipo' },
    // { codigo: '3', name: 'Número' },
    // { codigo: '4', name: 'Concepto' },
    // { codigo: '5', name: 'Debe' },
    // { codigo: '6', name: 'Haber' },
    // { codigo: '7', name: 'Número' }];
    // this.cmbTipoRegFiscal =
    //   [{ codigo: '  ', name: '  ' }, { codigo: '01', name: '01-Regimen General' },
    //   { codigo: '02', name: '02-Paraiso Físcal' },
    //   { codigo: '03', name: '03-Regimen Físcal preferente juridicción de menos imposición' }];
    // this.cmbanexaux = [{ codigo: '', name: '' },
    // { codigo: 'SI', name: 'SI' },
    // { codigo: 'NO', name: 'NO' }];
    //CDPJ
    this.cmbide = [{ codigo: '', name: '' }, { codigo: '1', name: 'R.U.C.' },
    { codigo: '2', name: 'Cédula' },
    { codigo: '3', name: 'Pasaporte' }];

    this.cmbidecash = [{ codigo: '', name: '' }, { codigo: 'R', name: 'R.U.C.' },
    { codigo: 'C', name: 'Cédula' },
    { codigo: 'P', name: 'Pasaporte' }];

    this.cmbcontri = [{ codigo: 'E', name: 'Especial' },
    { codigo: 'N', name: 'Normal' }];
    this.cmbparteR = [{ codigo: 'SI', name: 'SI' },
    { codigo: 'NO', name: 'NO' },
    { codigo: '', name: '' }];
    this.cmbdeno = [
      { codigo: 'N', name: 'Natural' },
      { codigo: 'S', name: 'Sociedad' },
      { codigo: ' ', name: '  ' }];
    this.cmbcampoaux = [{ codigo: '1', name: 'Fecha' },
    { codigo: '2', name: 'Tipo' },
    { codigo: '3', name: 'Número' },
    { codigo: '4', name: 'Concepto' },
    { codigo: '5', name: 'Debe' },
    { codigo: '6', name: 'Haber' },
    { codigo: '7', name: 'Número' }];
    this.cmbTipoRegFiscal =
      [{ codigo: '', name: '' }, { codigo: '01', name: '01-Regimen General' },
      { codigo: '02', name: '02-Paraiso Físcal' },
      { codigo: '03', name: '03-Regimen Físcal preferente juridicción de menos imposición' }];
    this.cmbanexaux = [{ codigo: '', name: '' },
    { codigo: 'SI', name: 'SI' },
    { codigo: 'NO', name: 'NO' }];
    this.etiquetasaudi=[
      {codigo:'PRO_TIPOIDE',name:'TIPO ID'},
      {codigo:'PRO_RUCIDE',name:'RUC / IDE'},{codigo:'PRO_CODIGO',name:'Código'},{codigo:'PRO_NOMBRE',name:'Nombre:'},
      {codigo:'PRO_NOMBREC',name:'Nombre Corto'},{codigo:'PRO_CONTACTO',name:'Contacto'},{codigo:'PRO_FECING',name:'Fecha Emisión'},
      {codigo:'GRU_CODIGO',name:'Grupo'},
      {codigo:'PRO_DIRECCION1',name:'Direccion1'},{codigo:'PRO_DIRECCION2',name:'Direccion2'},
      {codigo:'PRO_TELEFONO1',name:'Teléfono1'},{codigo:'PRO_TELEFONO2',name:'Teléfono2'},{codigo:'PRO_FAX',name:'Fax'},
      {codigo:'PRO_CODPOSTAL',name:'Cód. Postal'},{codigo:'PRO_CORREO',name:'Email 1'},
      {codigo:'PRO_CORREO2',name:'Email 2'},
      {codigo:'PRO_ZONA',name:'Zona'},
      {codigo:'PRO_OBSERVACION',name:'Observación'},{codigo:'PRO_CONTRIBUYENTE',name:'Contribuyente'},
      {codigo:'PRO_TIPO',name:'Tipo DE PROVEEDOR'},
      {codigo:'PRO_PARTEREL',name:'Parte Relacionada'},
      {codigo:'PRO_DENOMINACION',name:'Denominación'},
      {codigo:'PRO_DESCUENTO',name:'Descuento'},{codigo:'PRO_LIMCREDIT',name:'Límite de Crédito'},{codigo:'PRO_DIACREDIT',name:'Días Crédito'},
      {codigo:'CON_CODIGO1',name:'Cuentas por pagar'},
      {codigo:'CON_CODIGO2',name:'Anticipos'},
      {codigo:'CEN_CODIGO',name:'Centro de Costos'},
      {codigo:'PRO_NUMSERIE',name:'Número de Serie'},
      {codigo:'PRO_NUMAUTORIZACION',name:'Número de Autorización'},
      {codigo:'PRO_FACTURAELECT',name:'Facturación Electrónica'},
      {codigo:'PRO_TRANSPORTE',name:'Empresa de transporte con socios'},
      {codigo:'PRO_CONREGRIMPE',name:'Régimen RIMPE'},{codigo:'PRO_AGENTERET',name:'Agente de retención'},{codigo:'PRO_VALIDACION',name:'Validación'},{codigo:'',name:''},
    ]
    //CDPJ
  }

  obtenerFormap() {

    this.proveedorServicio.consultarCash('CMFP').subscribe((res) => {
      this.cmbformap = [];
      this.cmbformapaux = [];
      this.listaNomForm = res;
      this.cmbformapaux.push({ label: '', value: '' });
      this.listaNomForm.map((aux) => {
        let nuevoItem: any;
        nuevoItem = { codigo: aux.REF_CODIGO, name: aux.REF_NOMBRE };
        this.cmbformapaux.push(nuevoItem);
      });
      this.cmbformap = this.cmbformapaux;
    });
  }

  obtenerEntidadF() {
    this.proveedorServicio.consultarCash('CMBCO').subscribe((res) => {
      this.cmbentiFaux = [];
      this.listaNomEnti = res;
      this.cmbentiFaux.push({ label: '', value: '' });
      this.listaNomEnti.map((aux) => {
        let nuevoItem: any;
        nuevoItem = { codigo: aux.REF_CODIGO, name: aux.REF_NOMBRE };
        this.cmbentiFaux.push(nuevoItem);
      });
      this.cmbentiF = this.cmbentiFaux;
    });
  }

  obtenerTiposC() {
    this.proveedorServicio.consultarCash('CMTCTA').subscribe((res) => {
      this.cmbtipoCaux = [];
      this.listaNomTipoC = res;
      this.cmbtipoCaux.push({ label: '', value: '' });
      this.listaNomTipoC.map((aux) => {
        let nuevoItem: any;
        nuevoItem = { codigo: aux.REF_CODIGO, name: aux.REF_NOMBRE };
        this.cmbtipoCaux.push(nuevoItem);
      });
      this.cmbtipoC = this.cmbtipoCaux;
    });
  }


  seleccionarCombo() {
    for (const j of this.cmbide) {
      if (this.proveedorSeleccionado.PRO_TIPOIDE === j.codigo) {
        this.selectide = { codigo: j.codigo, name: j.name };
        this.proveedorSeleccionado.PRO_TIPOIDE = j.codigo;
      }
    }
    for (const j of this.cmbcontri) {
      if (this.proveedorSeleccionado.PRO_CONTRIBUYENTE === j.codigo) {
        this.selectcontri = { codigo: j.codigo, name: j.name };
        this.proveedorSeleccionado.PRO_CONTRIBUYENTE = j.codigo;
      }
    }
    for (const j of this.cmbparteR) {
      this.proveedorSeleccionado.PRO_PARTEREL = this.proveedorSeleccionado.PRO_PARTEREL === null || this.proveedorSeleccionado.PRO_PARTEREL === undefined ? '' : this.proveedorSeleccionado.PRO_PARTEREL;//CDPJ
      if (this.proveedorSeleccionado.PRO_PARTEREL === j.codigo) {
        this.selectparter = { codigo: j.codigo, name: j.name };
        this.proveedorSeleccionado.PRO_PARTEREL = j.codigo;
      }
    }
    for (const j of this.cmbdeno) {
      if (this.proveedorSeleccionado.PRO_DENOMINACION === j.codigo) {
        this.selectdeno = { codigo: j.codigo, name: j.name };
        this.proveedorSeleccionado.PRO_DENOMINACION = j.codigo;
      }
    }
    for (const j of this.cmbanexaux) {
      if (this.proveedorSeleccionado.PRO_CONVDOBLETRIB === j.codigo) {
        this.cmbanex1 = [{ codigo: j.codigo, name: j.name }];
        this.proveedorSeleccionado.PRO_CONVDOBLETRIB = j.codigo;
        break;
      } else {
        this.cmbanex1 = [{ codigo: '', name: '' }];
      }
    }
    for (const j of this.cmbanexaux) {
      if (this.proveedorSeleccionado.PRO_PAGOEXTERIOR === j.codigo) {
        this.cmbanex2 = [{ codigo: j.codigo, name: j.name }];
        this.proveedorSeleccionado.PRO_PAGOEXTERIOR = j.codigo;
        break;

      } else {
        this.cmbanex2 = [{ codigo: '', name: '' }];
      }
    }

    for (const j of this.cmbformapaux) {
      if (this.proveedorSeleccionado.COM_FORPAGCM === j.codigo) {
        this.cmbformap = [{ codigo: j.codigo, name: j.name }];

        this.proveedorSeleccionado.COM_FORPAGCM = j.codigo;
        break;
      } else if (this.proveedorSeleccionado.COM_FORPAGCM === null || this.proveedorSeleccionado.COM_FORPAGCM === '') {
        this.cmbformap = [{ codigo: '', name: '' }];
        //  this.proveedorSeleccionado.COM_FORPAGCM = '';
      }
    }

    for (const j of this.cmbentiFaux) {
      if (this.proveedorSeleccionado.COM_BANCODIGOCM === j.codigo) {

        this.cmbentiF = [{ codigo: j.codigo, name: j.name }];


        this.proveedorSeleccionado.COM_BANCODIGOCM = j.codigo;
        break;
      } else if (this.proveedorSeleccionado.COM_BANCODIGOCM === null || this.proveedorSeleccionado.COM_BANCODIGOCM === '') {
        this.cmbentiF = [{ codigo: '', name: '' }];

        // this.proveedorSeleccionado.COM_BANCODIGOCM = '';
      }
    }


    for (const j of this.cmbtipoCaux) {
      if (this.proveedorSeleccionado.COM_TIPOCTACM === j.codigo) {
        this.cmbtipoC = [{ codigo: j.codigo, name: j.name }];
        this.proveedorSeleccionado.COM_TIPOCTACM = j.codigo;
        break;
      } else if (this.proveedorSeleccionado.COM_TIPOCTACM === null || this.proveedorSeleccionado.COM_TIPOCTACM === '') {
        this.cmbtipoC = [{ codigo: '', name: '' }];
        // this.proveedorSeleccionado.COM_TIPOCTACM = '';
      }
    }

    for (const j of this.cmbTipoRegFiscal) {
      if (this.proveedorSeleccionado.PRO_TIPOREGFISCAL === j.codigo) {
        this.selecttiporegfis = { codigo: j.codigo, name: j.name };
        this.proveedorSeleccionado.PRO_TIPOREGFISCAL = j.codigo;
        this.booleanosAnexos('CARGAR');
        break;
      } else if (this.proveedorSeleccionado.PRO_TIPOREGFISCAL === undefined || this.proveedorSeleccionado.PRO_TIPOREGFISCAL === ''
        || this.proveedorSeleccionado.PRO_TIPOREGFISCAL === null) {
        this.selecttiporegfis = { codigo: '', name: '' };
        this.booleanosAnexos('CARGAR');

      }
    }

  }

  busqide(id) {
    this.lblvalida = '';
    if (!this.nuevoproveedor) {
      this.proveedorSeleccionado.PRO_RUCIDE = '';
      this.proveedorSeleccionado.PRO_CODIGO = '';
    }

    this.proveedorSeleccionado.PRO_TIPOIDE = id.value.codigo;
    this.botonesmodificar();
  }

  busqideCM(id) {
    this.proveedorSeleccionado.PRO_IDCM = id.value.codigo;
    this.botonesmodificar();
  }

  busqcontri(id) {
    this.proveedorSeleccionado.PRO_CONTRIBUYENTE = id.value.codigo;
    this.botonesmodificar();
  }

  busqparte(id) {
    this.proveedorSeleccionado.PRO_PARTEREL = id.value.codigo;
    this.botonesmodificar();
  }

  busqdeno(id) {
    this.proveedorSeleccionado.COM_FORPAGCM = id.value.codigo;
    this.botonesmodificar();
  }

  guardarEnters(cambio) {
    if (this.index === 6) {
      this.manejarSenales('Guardar');
    }
  }

  busqformap(id) {
    this.proveedorSeleccionado.COM_FORPAGCM = id.value.codigo;
    if (this.proveedorSeleccionado.COM_FORPAGCM === 'CTA') {
      this.flagFormadePago = false;
    } else {
      this.flagFormadePago = true;
      this.cmbentiF = [];
      this.cmbtipoC = [];
    }
    this.botonesmodificar();
  }

  busqentiF(id) {
    this.proveedorSeleccionado.COM_BANCODIGOCM = id.value.codigo;
    this.botonesmodificar();
  }

  busqTipoRegFiscal(id) {
    this.proveedorSeleccionado.PRO_TIPOREGFISCAL = id.value.codigo;
    //this.booleanosAnexos();
    this.booleanosAnexos('CMB');
    this.botonesmodificar();
  }

  // booleanosAnexos() {
  //   if (this.proveedorSeleccionado.PRO_TIPOREGFISCAL === '01') {
  //     this.regpais = false;
  //     this.paraisofiscal = true;
  //     this.denominacion = true;
  //     this.conparaisocod = '';
  //     this.conparaisonombre = '';
  //     this.conepaisnombre = '';
  //     this.conepaiscod = '';
  //     this.conpaisnombre = '';
  //     this.conpaiscod = '';
  //     this.proveedorSeleccionado.PRO_DENOMINACION = '';
  //   } else if (this.proveedorSeleccionado.PRO_TIPOREGFISCAL === '02') {
  //     this.regpais = true;
  //     this.paraisofiscal = false;
  //     this.denominacion = true;
  //     this.conparaisocod = '';
  //     this.conparaisonombre = '';
  //     this.conepaisnombre = '';
  //     this.conepaiscod = '';
  //     this.conpaisnombre = '';
  //     this.conpaiscod = '';
  //     this.proveedorSeleccionado.PRO_DENOMINACION = '';
  //   } else if (this.proveedorSeleccionado.PRO_TIPOREGFISCAL === '03') {
  //     this.regpais = true;
  //     this.paraisofiscal = true;
  //     this.denominacion = false;
  //     this.conparaisocod = '';
  //     this.conparaisonombre = '';
  //     this.conepaisnombre = '';
  //     this.conepaiscod = '';
  //     this.conpaisnombre = '';
  //     this.conpaiscod = '';
  //     this.proveedorSeleccionado.PRO_DENOMINACION = '';
  //   } else {
  //     this.regpais = true;
  //     this.paraisofiscal = true;
  //     this.denominacion = true;
  //     this.conparaisocod = '';
  //     this.conparaisonombre = '';
  //     this.conepaisnombre = '';
  //     this.conepaiscod = '';
  //     this.conpaisnombre = '';
  //     this.conpaiscod = '';
  //     this.proveedorSeleccionado.PRO_DENOMINACION = '';
  //   }
  // }

  busqetipoC(id) {
    this.proveedorSeleccionado.COM_TIPOCTACM = id.value.codigo;
    this.botonesmodificar();
  }

  busqanex1(id) {
    this.proveedorSeleccionado.PRO_CONVDOBLETRIB = id.value.codigo;
    this.botonesmodificar();
  }

  busqanex2(id) {
    this.proveedorSeleccionado.PRO_PAGOEXTERIOR = id.value.codigo;
    this.botonesmodificar();
  }

  // checks
  seleccionarCheks() {
    this.chkfacelectro = this.proveedorSeleccionado.PRO_FACTURAELECT === 'S';
    this.chktransporte = this.proveedorSeleccionado.PRO_TRANSPORTE === 'S';
    this.chkconregmic = this.proveedorSeleccionado.PRO_CONREGMIC === 'S';
    this.chkagenteret = this.proveedorSeleccionado.PRO_AGENTERET === 'S';
    this.chkconregrimpe = this.proveedorSeleccionado.PRO_CONREGRIMPE === 'S';
  }

  guardarCheks() {
    if (this.chkfacelectro === true) {
      this.proveedorSeleccionado.PRO_FACTURAELECT = 'S';
    } else {
      this.proveedorSeleccionado.PRO_FACTURAELECT = '';
    }
    if (this.chktransporte === true) {
      this.proveedorSeleccionado.PRO_TRANSPORTE = 'S';
    } else {
      this.proveedorSeleccionado.PRO_TRANSPORTE = '';
    }
    if (this.chkconregmic === true) {
      this.proveedorSeleccionado.PRO_CONREGMIC = 'S';
    } else {
      this.proveedorSeleccionado.PRO_CONREGMIC = '';
    }
    if (this.chkagenteret === true) {
      this.proveedorSeleccionado.PRO_AGENTERET = 'S';
    } else {
      this.proveedorSeleccionado.PRO_AGENTERET = '';
    }
    if (this.chkconregrimpe === true) {
      this.proveedorSeleccionado.PRO_CONREGRIMPE = 'S';
    } else {
      this.proveedorSeleccionado.PRO_CONREGRIMPE = '';
    }

    this.banderaCheks = true;
  }

  mostrarEstadisticas() {
    this.comprasRealizadas();
    this.topProductos();
  }

  async comprasRealizadas() {
    const fechaInicial = new Date();
    fechaInicial.setMonth(fechaInicial.getMonth() - 12);
    fechaInicial.setDate(1);

    const fechaAct = new Date();
    const rangoFechas: any[] = [];

    for (let i = 1; fechaInicial <= fechaAct; i++) {
      rangoFechas.push(this.datePipe.transform(fechaInicial, 'dd/MM/yyyy'));
      fechaInicial.setMonth(fechaInicial.getMonth() + 1);
    }



    try {

      const rs = await this.venEstadisticasService.comprasAcumuladas(this.proveedorSeleccionado.PRO_CODIGO);


      if (rs !== null) {

        const index1 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[0]);
        const valor1 = index1 >= 0 ? rs[index1].VENTA_TOTAL : 0;
        const index2 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[1]);
        const valor2 = index2 >= 0 ? rs[index2].VENTA_TOTAL : 0;
        const index3 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[2]);
        const valor3 = index3 >= 0 ? rs[index3].VENTA_TOTAL : 0;
        const index4 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[3]);
        const valor4 = index4 >= 0 ? rs[index4].VENTA_TOTAL : 0;
        const index5 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[4]);
        const valor5 = index5 >= 0 ? rs[index5].VENTA_TOTAL : 0;
        const index6 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[5]);
        const valor6 = index6 >= 0 ? rs[index6].VENTA_TOTAL : 0;
        const index7 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[6]);
        const valor7 = index7 >= 0 ? rs[index7].VENTA_TOTAL : 0;
        const index8 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[7]);
        const valor8 = index8 >= 0 ? rs[index8].VENTA_TOTAL : 0;
        const index9 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[8]);
        const valor9 = index9 >= 0 ? rs[index9].VENTA_TOTAL : 0;
        const index10 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[9]);
        const valor10 = index10 >= 0 ? rs[index10].VENTA_TOTAL : 0;
        const index11 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[10]);
        const valor11 = index11 >= 0 ? rs[index11].VENTA_TOTAL : 0;
        const index12 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[11]);
        const valor12 = index12 >= 0 ? rs[index12].VENTA_TOTAL : 0;
        const index13 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[12]);
        const valor13 = index13 >= 0 ? rs[index13].VENTA_TOTAL : 0;

        this.dataComprasProveedor = {
          labels: [
            String(rangoFechas[0]).substring(8, 10) + '/' + String(rangoFechas[0]).substring(3, 5),
            String(rangoFechas[1]).substring(8, 10) + '/' + String(rangoFechas[1]).substring(3, 5),
            String(rangoFechas[2]).substring(8, 10) + '/' + String(rangoFechas[2]).substring(3, 5),
            String(rangoFechas[3]).substring(8, 10) + '/' + String(rangoFechas[3]).substring(3, 5),
            String(rangoFechas[4]).substring(8, 10) + '/' + String(rangoFechas[4]).substring(3, 5),
            String(rangoFechas[5]).substring(8, 10) + '/' + String(rangoFechas[5]).substring(3, 5),
            String(rangoFechas[6]).substring(8, 10) + '/' + String(rangoFechas[6]).substring(3, 5),
            String(rangoFechas[7]).substring(8, 10) + '/' + String(rangoFechas[7]).substring(3, 5),
            String(rangoFechas[8]).substring(8, 10) + '/' + String(rangoFechas[8]).substring(3, 5),
            String(rangoFechas[9]).substring(8, 10) + '/' + String(rangoFechas[9]).substring(3, 5),
            String(rangoFechas[10]).substring(8, 10) + '/' + String(rangoFechas[10]).substring(3, 5),
            String(rangoFechas[11]).substring(8, 10) + '/' + String(rangoFechas[11]).substring(3, 5),
            String(rangoFechas[12]).substring(8, 10) + '/' + String(rangoFechas[12]).substring(3, 5),
          ],
          datasets: [{
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [valor1, valor2, valor3, valor4, valor5, valor6, valor7,
              valor8, valor9, valor10, valor11, valor12, valor13]
          }
          ]
        };

      } else {
        this.dataComprasProveedor = {
          labels: [
            String(rangoFechas[0]).substring(8, 10) + '/' + String(rangoFechas[0]).substring(3, 5),
            String(rangoFechas[1]).substring(8, 10) + '/' + String(rangoFechas[1]).substring(3, 5),
            String(rangoFechas[2]).substring(8, 10) + '/' + String(rangoFechas[2]).substring(3, 5),
            String(rangoFechas[3]).substring(8, 10) + '/' + String(rangoFechas[3]).substring(3, 5),
            String(rangoFechas[4]).substring(8, 10) + '/' + String(rangoFechas[4]).substring(3, 5),
            String(rangoFechas[5]).substring(8, 10) + '/' + String(rangoFechas[5]).substring(3, 5),
            String(rangoFechas[6]).substring(8, 10) + '/' + String(rangoFechas[6]).substring(3, 5),
            String(rangoFechas[7]).substring(8, 10) + '/' + String(rangoFechas[7]).substring(3, 5),
            String(rangoFechas[8]).substring(8, 10) + '/' + String(rangoFechas[8]).substring(3, 5),
            String(rangoFechas[9]).substring(8, 10) + '/' + String(rangoFechas[9]).substring(3, 5),
            String(rangoFechas[10]).substring(8, 10) + '/' + String(rangoFechas[10]).substring(3, 5),
            String(rangoFechas[11]).substring(8, 10) + '/' + String(rangoFechas[11]).substring(3, 5),
            String(rangoFechas[12]).substring(8, 10) + '/' + String(rangoFechas[12]).substring(3, 5),
          ],
          datasets: [{
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
          ]
        };
      }
    } catch (err) {

    }
  }

  async topProductos() {
    this.detTopProductos = [];
    let totalCantidad = 0;
    let totalPrecio = 0;
    let total = 0;
    const fechaActual = new Date();
    const strFecha = this.datePipe.transform(fechaActual, 'dd/MM/yyyy');
    const time = new Date();
    const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
    const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
    const strHora = hora + ':' + minutos;

    const detalle: ComTopProductos[] = [];


    try {
      const rs = await this.venEstadisticasService.topProductos(this.proveedorSeleccionado.PRO_CODIGO, 'cantidad');
      if (rs !== null) {
        for (const item of rs) {
          const det: ComTopProductos = {};
          det.DETFACPRO_CODIGO = item.DETFACPRO_CODIGO;
          det.DETFACPRO_DESCRIPCION = item.DETFACPRO_DESCRIPCION;
          det.CANTIDAD_TOTAL = Number(item.CANTIDAD_TOTAL).toFixed(2);
          totalCantidad = totalCantidad + Number(item.CANTIDAD_TOTAL);
          det.DETFACPRO_COSTO = Number(item.DETFACPRO_COSTO).toFixed(2);
          totalPrecio = totalPrecio + Number(item.DETFACPRO_COSTO);
          det.TOTAL = Number(item.TOTAL).toFixed(2);
          total = total + Number(item.TOTAL);
          det.STOCK = Number(item.STOCK).toFixed(2);
          detalle.push(det);
        }
        const totales: ComTopProductos = {};
        totales.DETFACPRO_CODIGO = '';
        totales.DETFACPRO_DESCRIPCION = 'TOTAL';
        totales.CANTIDAD_TOTAL = Number(totalCantidad).toFixed(2);
        totales.DETFACPRO_COSTO = Number(totalPrecio).toFixed(2);
        totales.TOTAL = Number(total).toFixed(2);
        totales.STOCK = '';
        detalle.push(totales);

        this.detTopProductos = detalle;
      }

    } catch (err) {

    }
  }

  changeTab() {
    if (this.index === 5) {

      this.dataComprasProveedor = {
        labels: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
        datasets: [{
          label: 'Ventas',
          backgroundColor: '#42A5F5',
          borderColor: '#1E88E5',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
        ]
      };

      this.dataVentasVsCompras = {
        labels: ['', ''],
        datasets: [
          {
            label: 'Ventas',
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [0]
          },
          {
            label: 'Compras',
            backgroundColor: '#9CCC65',
            borderColor: '#7CB342',
            data: [0]
          }
        ]
      };

      this.detTopProductos = [];
      this.detalleExistencia = [];
      this.detMovimientosProv = [];
      this.detExistenciaBodegas = [];
      this.txtArtCodigo = '';
      this.txtArtDescripcion = '';
      this.txtExistenciaMax = '';
      this.txtExistenciaMin = '';
      this.txtArtUnidad = '';
      this.txtArtRotacion = '';
      this.txtInventarioIni = '';
      this.txtComprasPeriodo = '';
      this.txtTotalDisponible = '';
      this.txtVenUltimoPer = '';
      this.txtVenPromPerAnt = '';
      this.txtIndiceProvUltPer = '';
      this.txtIndiceProvPeriodos = '';
      this.txtComprasPlanificadas = '';
      this.txtTotalDisponibleCom = '';
      this.txtIndiceProvUltPer2 = '';
      this.txtIndiceProvPeriodos2 = '';

      const existencias: ComExistencias = {};
      existencias.COMPRAR = '';
      existencias.EXIST_MIN = '';
      existencias.EXISTENCIA = '';
      existencias.ORDENES_COMPRA = '';
      existencias.PEDIDOS_CLIENTES = '';
      existencias.SALDO = '';
      existencias.TOTAL_COMPRA = '';
      existencias.ULTCOSTO_COM = '';
      this.detalleExistencia.push(existencias);

    }
  }

  async orderByTopProd(tipo) {
    this.detTopProductos = [];
    let totalCantidad = 0;
    let totalPrecio = 0;
    let total = 0;
    const detalle: ComTopProductos[] = [];
    let rs;


    try {

      if (tipo === 'total') {
        rs = await this.venEstadisticasService.topProductos(this.proveedorSeleccionado.PRO_CODIGO, 'total');
      }

      if (tipo === 'cantidad') {
        rs = await this.venEstadisticasService.topProductos(this.proveedorSeleccionado.PRO_CODIGO, 'cantidad');
      }

      if (rs !== null) {
        for (const item of rs) {
          const det: ComTopProductos = {};
          det.DETFACPRO_CODIGO = item.DETFACPRO_CODIGO;
          det.DETFACPRO_DESCRIPCION = item.DETFACPRO_DESCRIPCION;
          det.CANTIDAD_TOTAL = Number(item.CANTIDAD_TOTAL).toFixed(2);
          totalCantidad = totalCantidad + Number(item.CANTIDAD_TOTAL);
          det.DETFACPRO_COSTO = Number(item.DETFACPRO_COSTO).toFixed(2);
          totalPrecio = totalPrecio + Number(item.DETFACPRO_COSTO);
          det.TOTAL = Number(item.TOTAL).toFixed(2);
          total = total + Number(item.TOTAL);
          det.STOCK = Number(item.STOCK).toFixed(2);
          detalle.push(det);
        }
        const totales: ComTopProductos = {};
        totales.DETFACPRO_CODIGO = '';
        totales.DETFACPRO_DESCRIPCION = 'TOTAL';
        totales.CANTIDAD_TOTAL = Number(totalCantidad).toFixed(2);
        totales.DETFACPRO_COSTO = Number(totalPrecio).toFixed(2);
        totales.TOTAL = Number(total).toFixed(2);
        totales.STOCK = '';
        detalle.push(totales);

        this.detTopProductos = detalle;
      }

    } catch (err) {

    }
  }

  buscarArticulo() {
    this.detalleExistencia = [];
    this.detMovimientosProv = [];
    this.detExistenciaBodegas = [];

    this.txtArtCodigo = '';
    this.txtArtDescripcion = '';
    this.txtExistenciaMax = '';
    this.txtExistenciaMin = '';
    this.txtArtUnidad = '';
    this.txtArtRotacion = '';
    this.txtInventarioIni = '';
    this.txtComprasPeriodo = '';
    this.txtTotalDisponible = '';
    this.txtVenUltimoPer = '';
    this.txtVenPromPerAnt = '';
    this.txtIndiceProvUltPer = '';
    this.txtIndiceProvPeriodos = '';
    this.txtComprasPlanificadas = '';
    this.txtTotalDisponibleCom = '';
    this.txtIndiceProvUltPer2 = '';
    this.txtIndiceProvPeriodos2 = '';

    const existencias: ComExistencias = {};
    existencias.COMPRAR = '';
    existencias.EXIST_MIN = '';
    existencias.EXISTENCIA = '';
    existencias.ORDENES_COMPRA = '';
    existencias.PEDIDOS_CLIENTES = '';
    existencias.SALDO = '';
    existencias.TOTAL_COMPRA = '';
    existencias.ULTCOSTO_COM = '';
    this.detalleExistencia.push(existencias);

    const grafico = {
      labels: ['', ''],
      datasets: [
        {
          label: 'Ventas',
          backgroundColor: '#42A5F5',
          borderColor: '#1E88E5',
          data: [0]
        },
        {
          label: 'Compras',
          backgroundColor: '#9CCC65',
          borderColor: '#7CB342',
          data: [0]
        }
      ]
    };

    this.dataVentasVsCompras = grafico;


    this.opcionbusqueda = 'articulo';
    this.types = [
      { label: 'Código', value: 'ART_CODIGO' },
      { label: 'Nombre', value: 'ART_NOMBRE' },
      { label: 'Grupo', value: 'GRUP_CODIGO' },
      { label: 'NombreCorto', value: 'ART_NOMBREC' },
      { label: 'Laboratorio', value: 'ART_LABORATORIO' },
      { label: 'Ubicación', value: 'ART_UBICACION' },
      { label: 'CodigoBarras', value: 'ART_CODIGOALT1' },
      { label: 'Alterno3', value: 'ART_CODIGOALT3' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', ''];
    // this.consulta = '*';
    this.tabla = 'INV_MAEARTICULO';
    // this.where = 'ART_ACTIVO=\'S\'';

    this.busqService.busquedaInvMaeArticulo1().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  async llenarMovByProvArt(tipo) {
    this.detMovimientosProv = [];
    const detalle: ComMovimientoProv[] = [];
    let rs;


    try {

      if (tipo === 'fecha') {
        rs = await this.venEstadisticasService.movimientoArtProv(this.txtArtCodigo, 'fecha');
      }

      if (tipo === 'costo') {
        rs = await this.venEstadisticasService.movimientoArtProv(this.txtArtCodigo, 'costo');
      }

      if (rs !== null) {
        for (const item of rs) {
          const det: ComMovimientoProv = {};
          det.PRO_CODIGO = item.PRO_CODIGO;
          det.PRO_NOMBRE = item.PRO_NOMBRE;
          det.ENCFACPRO_FECHAEMISION = this.datePipe.transform(item.ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
          det.DETFACPRO_COSTO = Number(item.DETFACPRO_COSTO).toFixed(2);

          detalle.push(det);
        }
        this.detMovimientosProv = detalle;
      }

    } catch (err) {

    }
  }

  async llenarTablaExistencia() {
    let rs1;
    let rs2;
    let rs3;
    let rs4;

    try {
      rs1 = this.venEstadisticasService.ultimoCostoCompra(this.txtArtCodigo);
      rs2 = this.venEstadisticasService.existenciaArticulo(this.txtArtCodigo);
      rs3 = this.venEstadisticasService.cantidadOrdCompra(this.txtArtCodigo);
      rs4 = this.venEstadisticasService.cantidadPedidos(this.txtArtCodigo);
    } catch (err) {

    }

    rs1 = await rs1;
    rs2 = await rs2;
    rs3 = await rs3;
    rs4 = await rs4;

    this.detalleExistencia = [];
    const existencias: ComExistencias = {};
    existencias.ULTCOSTO_COM = Number(rs1[0].ULTIMO_COSTO).toFixed(2);
    existencias.EXISTENCIA = Number(rs2[0].EXISTENCIA);
    existencias.ORDENES_COMPRA = Number(rs3[0].TOTAL_ORDCOM);
    existencias.EXIST_MIN = this.txtExistenciaMin;
    existencias.PEDIDOS_CLIENTES = Number(rs4[0].TOTAL_PEDIDOS);
    existencias.SALDO = Number(rs2[0].EXISTENCIA) + Number(rs3[0].TOTAL_ORDCOM) - Number(rs4[0].TOTAL_PEDIDOS);
    existencias.COMPRAR = existencias.SALDO < Number(this.txtExistenciaMin) ? Math.abs(existencias.SALDO) + Number(this.txtExistenciaMin) : 0;
    existencias.TOTAL_COMPRA = this.init.moneyValidation(Number(existencias.COMPRAR * Number(existencias.ULTCOSTO_COM)).toFixed(2));


    this.detalleExistencia.push(existencias);
  }

  async llenarExistenciaBodegas() {
    this.detExistenciaBodegas = [];
    const detalle: ComExistenciaBodegas[] = [];


    try {
      const rs = await this.venEstadisticasService.llenarExistenciaBod(this.txtArtCodigo);
      if (rs !== null) {
        for (const item of rs) {
          const det: ComExistenciaBodegas = {};
          det.BOD_CODIGO = item.BOD_CODIGO;
          det.BOD_NOMBRE = item.BOD_NOMBRE;
          det.EXISTENCIA = Number(item.EXISTENCIA).toFixed(2);
          det.PEDIDO = Number(item.PEDIDOS).toFixed(2);
          det.SALDO = Number(item.SALDO).toFixed(2);


          detalle.push(det);
        }
        this.detExistenciaBodegas = detalle;
      }

    } catch (err) {

    }
  }

  async ventasVsCompras() {

    let rs1;
    let rs2;

    try {

      rs1 = this.venEstadisticasService.ventasVsCompras(this.txtArtCodigo, 'V');
      rs2 = this.venEstadisticasService.ventasVsCompras(this.txtArtCodigo, 'C');

    } catch (err) {

    }
    rs1 = await rs1;
    rs2 = await rs2;

    const grafico = {
      labels: ['', ''],
      datasets: [
        {
          label: 'Ventas',
          backgroundColor: '#42A5F5',
          borderColor: '#1E88E5',
          data: [rs1[0].TOTALVENTAS]
        },
        {
          label: 'Compras',
          backgroundColor: '#9CCC65',
          borderColor: '#7CB342',
          data: [rs2[0].TOTALCOMPRAS]
        }
      ]
    };

    this.dataVentasVsCompras = grafico;

  }

  async llenarIndiceProvisiones() {

    let rs1;
    let rs2;
    let rs3;
    let rs4;

    try {
      rs1 = this.venEstadisticasService.obtenerExistenciaArtIndProv(this.txtArtCodigo);
      rs2 = this.venEstadisticasService.comprasPeriodo(this.txtArtCodigo);
      rs3 = this.venEstadisticasService.comprasUltPeriodo(this.txtArtCodigo);
      rs4 = this.venEstadisticasService.ventasPeriodo(this.txtArtCodigo);
    } catch (err) {

    }

    rs1 = await rs1;
    rs2 = await rs2;
    rs3 = await rs3;
    rs4 = await rs4;

    this.txtInventarioIni = Number(rs1[0].EXISTENCIA_ACTUAL).toFixed(2);
    this.txtComprasPeriodo = Number(rs2[0].COMPRAS_PERIODO).toFixed(2);
    this.txtTotalDisponible = Number(rs1[0].EXISTENCIA_ACTUAL + rs2[0].COMPRAS_PERIODO).toFixed(2);

    this.txtVenUltimoPer = Number(rs3[0].VENTAS_ULTIMOPER).toFixed(2);
    this.txtVenPromPerAnt = Number(rs4[0].VENTAS_PERIODOS / 10).toFixed(2);


    this.txtIndiceProvUltPer = Number(this.txtVenUltimoPer) !== 0 ? Number(this.txtTotalDisponible) / Number(this.txtVenUltimoPer) :
      0;
    this.txtIndiceProvUltPer = Number(this.txtIndiceProvUltPer).toFixed(2);
    this.txtIndiceProvPeriodos = Number(this.txtVenPromPerAnt) !== 0 ? Number(this.txtTotalDisponible) / Number(this.txtVenPromPerAnt) :
      0;
    this.txtIndiceProvPeriodos = Number(this.txtIndiceProvPeriodos).toFixed(2);

  }

  calcularIndice() {
    this.txtTotalDisponibleCom = Number(this.txtTotalDisponible) + Number(this.txtComprasPlanificadas);
    this.txtTotalDisponibleCom = Number(this.txtTotalDisponibleCom).toFixed(2);

    this.txtIndiceProvUltPer2 = Number(this.txtVenUltimoPer) !== 0 ? Number(this.txtTotalDisponibleCom) / Number(this.txtVenUltimoPer) : 0;
    this.txtIndiceProvUltPer2 = Number(this.txtIndiceProvUltPer2).toFixed(2);
    this.txtIndiceProvPeriodos2 = Number(this.txtVenPromPerAnt) !== 0 ? Number(this.txtTotalDisponibleCom) / Number(this.txtVenPromPerAnt) :
      0;
    this.txtIndiceProvPeriodos2 = Number(this.txtIndiceProvPeriodos2).toFixed(2);
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos = false;
    }
  }

  async validar(rucide) {
    this.validaEnviaRespuesta(this.selectide.name, rucide);
  }

  async validaEnviaRespuesta(rucIdeTipo, rucIde) {

    if (rucIdeTipo === 'R.U.C.') {
      const vria = await this.validaConsultaRespuesta(rucIde);

      if (vria === 1) {
        await this.validaColocarLabels(1);
      } else if (vria === 3) {
        this.claveAuth = '';
        this.autorizacionCodigo = 'AUTRUCID';
        this.displayDialogAutorizacion = true;
      } else if (vria === 4) {
        await this.obtenerDatosCliente(rucIde);
      } else {
        await this.validaColocarLabels(2);
        this.proveedorSeleccionado.PRO_RUCIDE = '';

      }

    }
    if (rucIdeTipo === 'Cédula') {
      const vria = await this.validaConsultaRespuesta(rucIde);

      if (vria == 1) {
        await this.validaColocarLabels(1);
      } else if (vria == 3) {
        this.claveAuth = '';
        this.autorizacionCodigo = 'AUTRUCID';
        this.displayDialogAutorizacion = true;
      } else {
        await this.validaColocarLabels(2);
        this.proveedorSeleccionado.PRO_RUCIDE = '';
      }
    }
    if (rucIdeTipo === 'Pasaporte') {
      await this.validaColocarLabels(4);
    }
  }

  async validaConsultaRespuesta(rucIde) {

    let valRucIDe = 0;

    if (this.selectide.name === 'R.U.C.') {
      const resp = await this.utilitariosService.ValidaRucAutorizacion(rucIde);

      if (resp == 1) {
        valRucIDe = 1;
      } else {
        if (resp == 4) {
          valRucIDe = 4;
        } else if (resp == 3) {
          valRucIDe = 3;
        } else {
          valRucIDe = 0;
        }
      }
    }
    if (this.selectide.name === 'Cédula') {
      if (rucIde !== '9999999999') {
        const resp = await this.utilitariosService.ValidaCedulaAutorizacion(rucIde, 1);
        if (resp == 1) {
          valRucIDe = 1;
        } else {
          if (resp == 3) {
            valRucIDe = 3;
          } else {
            valRucIDe = 0;
          }
        }
      } else {
        valRucIDe = 0;
      }
    }

    if (this.selectide.name === 'Pasaporte') {
      valRucIDe = 1;
    }

    return valRucIDe;

  }


  async validaColocarLabels(tipo) {
    if (tipo === 1) {
      this.lblvalida = "Bien";
    }
    if (tipo === 2) {
      this.lblvalida = "Mal";
    }

    if (tipo === 3) {
      this.lblvalida = "Autoriza";
    }

    // /PARA PASAPORTES
    if (tipo === 4) {
      this.lblvalida = "Bien";
    }

    if (tipo === 6) {
      this.lblvalida = "SRI";
    }

    let codproveedor = '';
    if (this.nuevoproveedor === false) {
      codproveedor = this.proveedorSeleccionado.PRO_RUCIDE;
    } else {
      codproveedor = this.proveedorSeleccionado.PRO_RUCIDE;
    }
    this.proveedorServicio.erProveedorcod(this.proveedorSeleccionado.PRO_RUCIDE, codproveedor).subscribe(formsricom => {
      if (formsricom[0] !== undefined) {
        if (Number(formsricom[0].COUNT) > 0) {
          this.messageService.add({
            key: 'commaeproveedor',
            severity: 'warn',
            summary: 'Información',
            detail: 'Proveedor ya existe'
          });
          this.lblvalida = "Mal";
        } else {
          if (this.nuevoproveedor === false) {
            this.proveedorSeleccionado.PRO_CODIGO = this.proveedorSeleccionado.PRO_RUCIDE;
          }
        }
      }
    });
  }

  async obtenerDatosCliente(rucIde) {
    let r = null;

    try {
      r = await this.utilitariosService.getrucsri(rucIde);
    } catch  {
    }
    // console.log(r);
    if (r != null) {
      this.proveedorSeleccionado.PRO_NOMBRE = r.RAZON_SOCIAL;
      this.proveedorSeleccionado.PRO_NOMBREC = r.RAZON_SOCIAL.substr(0, 12);
      this.proveedorSeleccionado.PRO_CONTACTO = r.NOMBRE_COMERCIAL === "null" ? "" : r.NOMBRE_COMERCIAL;
      this.proveedorSeleccionado.PRO_DIRECCION1 = r.DESCRIPCION_PROVINCIA + '-' + r.DESCRIPCION_CANTON + '-' + r.DESCRIPCION_PARROQUIA;
      this.proveedorSeleccionado.PRO_OBSERVACION = r.TIPO_CONTRIBUYENTE + " - "
        + r.ESTADO_CONTRIBUYENTE + " - "
        + r.CLASE_CONTRIBUYENTE + " - " + r.OBLIGADO;
      this.auditoriagral.registrarAuditoria('COM_MAEPROVEEDOR', 'SRI/' + rucIde, 'I',
        '', '01', '', '', '', '').subscribe();
      this.validaColocarLabels(6);
    }
  }

  comprobarClave() {
    this.utilitariosService.obtenerClave(this.autorizacionCodigo).subscribe((clave) => {
      let claveReal;
      claveReal = clave[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        this.accionesAutorizacion();
      } else if (clave !== this.claveAuth) {
        this.messageService.add({
          key: 'autorizacion',
          severity: 'error',
          summary: 'Clave Autorización',
          detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
        });
      }
    });
  }

  accionesAutorizacion() {
    if (this.autorizacionCodigo === 'AUTRUCID') {
      this.validaColocarLabels(3);
    }

    this.displayDialogAutorizacion = false;
  }

  onHideAutorizacion() {
    if (this.bolAutorizacion === false) {
      if (this.autorizacionCodigo === 'AUTRUCID') {
        this.messageService.add({
          key: 'cliente',
          severity: 'error',
          summary: 'Información',
          detail: 'Debe ingresar correctamente'
            + ' la clave para ingresar el Ruc/Ide'
        });
        this.proveedorSeleccionado.PRO_RUCIDE = '';
        document.getElementById('CLI_RUCIDE').focus();
      }
    }
  }
}

