<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="encordtra"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmencordtra" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 99%">
    <div class="p-col-12">
        <app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
            [botonRegresar]="btnRegresar" [barraBotonesCRUD]="false" [barraBotonesDesplaz]="false"
            [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true" (signal)="manejarSenales($event)"
            [barraBotones5]="false" [botonBuscar]="false" [botonBinocular]="true" [botonAlmacenardoc]="true">
        </app-invmaebarra>
    </div>
    <div class="p-col-12" style="margin-top: -10px; margin-bottom: -18px;">
        <div class="p-grid">
            <div class="p-col-1">
                <span>Orden Número:</span>
            </div>
            <div class="p-col-2">
                <label for="ORDTRA_NUMERO"></label>
                <input class="frm" id="ORDTRA_NUMERO" type="text" pInputText
                    style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
                    [disabled]="true" [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_NUMERO" autocomplete="off">
            </div>
            <div class="p-col-2"></div>
            <div class="p-col-1">
                <span>ESTADO:</span>
            </div>
            <div class="p-col-2">
                <label for="ORDTRA_ESTADO"></label>
                <input class="frm" id="ORDTRA_ESTADO" type="text" pInputText
                    style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
                    [disabled]="true" [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_ESTADO" autocomplete="off">
            </div>
        </div>
    </div>
    <div class="p-col-12" style="margin-bottom: -20px;">
        <div class="divgrups">
            <div class="p-grid" style="margin-bottom: -15px;">
                <div class="p-col-12">
                    <div class="p-grid">
                        <div class="p-col-1">
                            <span>Cliente:</span>
                        </div>
                        <div class="p-col-2">
                            <label for="CLI_CODIGO"></label>
                            <input class="frm" id="CLI_CODIGO" type="text" pInputText [(ngModel)]="encordtraService.encordtra[indicador].CLI_CODIGO"
                                (keydown.enter)="setFocus($event)" (keydown)="botonesModificar()" autocomplete="off">
                        </div>
                        <div class="p-col-1">
                            <button type="button"
                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                (click)="busquedacliente(encordtraService.encordtra[indicador].CLI_CODIGO,'1')"></button>
                        </div>
                        <div class="p-col-4">
                            <label for="CLI_NOMBRE"></label>
                            <input class="frm" id="CLI_NOMBRE" type="text" pInputText disabled
                                [(ngModel)]="encordtraService.encordtra[indicador].CLI_NOMBRE" autocomplete="off">
                        </div>
                        <div class="p-col-1">
                            <span>Est.Ord.Tra.:</span>
                        </div>
                        <div class="p-col-2">
                            <p-dropdown [style]="{'minWidth': '100%', 'width': '100%'}" [panelStyle]="{'width':'100%'}"
                                [options]="opcionesEstOrdTra" [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_ESTADOOT"
                                (keydown.enter)="setFocus($event)" (onChange)="botonesModificar()"
                                (keydown)="botonesModificar()" editable="true">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="p-col-12" style="margin-top: -20px;">
                    <div class="p-grid">
                        <div class="p-col-1">
                            <span>Fecha:</span>
                        </div>
                        <div class="p-col-2">
                            <p-calendar id="ORDTRA_FECHA" [inputStyle]="{width: '100%', height: '20px'}"
                                [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_FECHA" [readonlyInput]="true" dateFormat="dd/mm/yy"
                                (keydown.enter)="setFocus($event)" (keydown)="botonesModificar()">
                            </p-calendar>
                        </div>
                        <div class="p-col-1">
                            <span>Dirección:</span>
                        </div>
                        <div class="p-col-4">
                            <label for="CLI_DIRECCION1"></label>
                            <input class="frm" id="CLI_DIRECCION1" type="text" pInputText disabled
                                [(ngModel)]="encordtraService.encordtra[indicador].CLI_DIRECCION1" (keydown.enter)="setFocus($event)"
                                autocomplete="off">
                        </div>
                        <div class="p-col-1">
                            <span>Est.del Equipo:</span>
                        </div>
                        <div class="p-col-2">
                            <p-dropdown [style]="{'minWidth': '100%', 'width': '100%'}" [panelStyle]="{'width':'100%'}"
                                [options]="opcionesEstEquipo" [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_ESTEQUIPO"
                                (keydown.enter)="setFocus($event)" (onChange)="botonesModificar()"
                                (keydown)="botonesModificar()" editable="true">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="p-col-12" style="margin-top: -15px;">
                    <div class="p-grid">
                        <div class="p-col-1">
                            <span>Teléfono:</span>
                        </div>
                        <div class="p-col-2">
                            <label for="CLI_TELEFONO1"></label>
                            <input class="frm" id="CLI_TELEFONO1" type="text" pInputText disabled
                                [(ngModel)]="encordtraService.encordtra[indicador].CLI_TELEFONO1" (keydown.enter)="setFocus($event)"
                                autocomplete="off">
                        </div>
                        <div class="p-col-1">
                            <span>Correo:</span>
                        </div>
                        <div class="p-col-4">
                            <label for="CLI_CORREO"></label>
                            <input class="frm" id="CLI_CORREO" type="text" pInputText disabled
                                [(ngModel)]="encordtraService.encordtra[indicador].CLI_CORREO" (keydown.enter)="setFocus($event)"
                                autocomplete="off">
                        </div>
                        <div class="p-col-1">
                            <span>Teléfono 2:</span>
                        </div>
                        <div class="p-col-2">
                            <label for="CLI_TELEFONO2"></label>
                            <input class="frm" id="CLI_TELEFONO2" type="text" pInputText disabled
                                [(ngModel)]="encordtraService.encordtra[indicador].CLI_TELEFONO2" (keydown.enter)="setFocus($event)"
                                autocomplete="off">
                        </div>
                    </div>
                </div>
                <div class="p-col-12" style="margin-top: -15px;">
                    <div class="p-grid">
                        <div class="p-col-1">
                            <span>Facturar A:</span>
                        </div>
                        <div class="p-col-2">
                            <label for="CLI_CODIGO_FAC"></label>
                            <input class="frm" id="CLI_CODIGO_FAC" type="text" pInputText
                                [(ngModel)]="encordtraService.encordtra[indicador].CLI_CODIGO_FAC" (keydown.enter)="setFocus($event)"
                                (keydown)="botonesModificar()" autocomplete="off">
                        </div>
                        <div class="p-col-1">
                            <button type="button"
                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                (click)="busquedacliente(encordtraService.encordtra[indicador].CLI_CODIGO_FAC,'2')"></button>
                        </div>
                        <div class="p-col-4">
                            <label for="CLI_CODIGO_FACNOMBRE"></label>
                            <input class="frm" id="CLI_CODIGO_FACNOMBRE" type="text" pInputText disabled
                                style="color: blue;font-weight: bold;" [(ngModel)]="encordtraService.encordtra[indicador].CLI_CODIGO_FACNOMBRE"
                                autocomplete="off">
                        </div>
                        <div class="p-col-1">
                            <span>Entregado:</span>
                        </div>
                        <div class="p-col-2">
                            <p-calendar id="ORDTRA_FECHAENTREGADO" [inputStyle]="{width: '100%', height: '20px'}"
                                [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_FECHAENTREGADO" [readonlyInput]="true"
                                dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)" (keydown)="botonesModificar()">
                            </p-calendar>
                        </div>
                        <div class="p-col-1">
                            <p-calendar id="ORDTRA_HORAENTREGADO" [inputStyle]="{width: '80%', height: '20px'}"
                                [timeOnly]="true" [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_HORAENTREGADO" [readonlyInput]="true"
                                dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)" (keydown)="botonesModificar()">
                            </p-calendar>
                        </div>
                    </div>
                </div>
                <div class="p-col-12" style="margin-top: -20px;">
                    <div class="p-grid">
                        <div class="p-col-1">
                            <span>Nro. Factura:</span>
                        </div>
                        <div class="p-col-2">
                            <label for="NROFACTURA"></label>
                            <input class="frm" id="NROFACTURA" type="text" pInputText disabled
                                [(ngModel)]="encordtraService.encordtra[indicador].CLI_CODIGO" (keydown)="botonesModificar()" autocomplete="off">
                        </div>
                        <div class="p-col-1">
                            <span>Monto:</span>
                        </div>
                        <div class="p-col-1">
                            <label for="MONTO"></label>
                            <input class="frm" id="MONTO" type="text" pInputText disabled
                                [(ngModel)]="encordtraService.encordtra[indicador].CLI_CODIGO" (keydown)="botonesModificar()" autocomplete="off">
                        </div>
                        <div class="p-col-3">
                        </div>
                        <div class="p-col-1">
                            <span>Máx. de garantía:</span>
                        </div>
                        <div class="p-col-2">
                            <p-calendar id="ORDTRA_FECHAMAXIMAGAR" [inputStyle]="{width: '100%', height: '20px'}"
                                [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_FECHAMAXIMAGAR" [readonlyInput]="true"
                                dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)" (keydown)="botonesModificar()">
                            </p-calendar>
                        </div>
                    </div>
                </div>
                <div class="p-col-12" style="margin-top: -15px;">
                    <div class="p-grid">
                        <div class="p-col-1">
                            <span>Problema:</span>
                        </div>
                        <div class="p-col-11">
                            <label for="ORDTRA_PROBLEMA"></label>
                            <input class="frm" id="ORDTRA_PROBLEMA" type="text" pInputText
                                [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_PROBLEMA" (keydown)="botonesModificar()" autocomplete="off">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="overflow: hidden" class="p-col-12">
        <p-tabView [(activeIndex)]="index" (onChange)="changeTabs($event)">
            <p-tabPanel header="Equipo">
                <div class="divgrups">
                    <div class="p-grid" style="margin-bottom: -15px;">
                        <div class="p-col-12">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Equipo:</span>
                                </div>
                                <div class="p-col-1">
                                    <label for="ORDTRA_PRODUCTOCLI"></label>
                                    <input class="frm" id="ORDTRA_PRODUCTOCLI" type="text" pInputText
                                        [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_PRODUCTOCLI" (keydown.enter)="setFocus($event)"
                                        (keydown)="botonesModificar()" autocomplete="off">
                                </div>
                                <div class="p-col-1">
                                    <button type="button"
                                        style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                        (click)="busquedaequipo(encordtraService.encordtra[indicador].ORDTRA_PRODUCTOCLI)"></button>
                                </div>
                                <div class="p-col-3">
                                    <label for="ORDTRA_PRODUCTOCLINOMBRE"></label>
                                    <input class="frm" id="ORDTRA_PRODUCTOCLINOMBRE" type="text" pInputText disabled
                                        [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_PRODUCTOCLINOMBRE" (keydown)="botonesModificar()"
                                        autocomplete="off">
                                </div>
                                <div class="p-col-1">
                                    <span>Marca:</span>
                                </div>
                                <div class="p-col-2">
                                    <label for="ORDTRA_MARCA"></label>
                                    <input class="frm" id="ORDTRA_MARCA" type="text" pInputText
                                        [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_MARCA" (keydown.enter)="setFocus($event)"
                                        (keydown)="botonesModificar()"
                                        autocomplete="off">
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12" style="margin-top: -20px;">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Modelo:</span>
                                </div>
                                <div class="p-col-2">
                                    <label for="ORDTRA_MODELO"></label>
                                    <input class="frm" id="ORDTRA_MODELO" type="text" pInputText
                                        [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_MODELO" (keydown.enter)="setFocus($event)"
                                        (keydown)="botonesModificar()" autocomplete="off">
                                </div>
                                <div class="p-col-1">
                                    <span>Serie:</span>
                                </div>
                                <div class="p-col-2">
                                    <label for="ORDTRA_SERIE"></label>
                                    <input class="frm" id="ORDTRA_SERIE" type="text" pInputText
                                        [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_SERIE" (keydown.enter)="setFocus($event)"
                                        (keydown)="botonesModificar()" autocomplete="off">
                                </div>
                                <div class="p-col-1">
                                    <span>Origen:</span>
                                </div>
                                <div class="p-col-2">
                                    <label for="ORDTRA_ORIGEN"></label>
                                    <input class="frm" id="ORDTRA_ORIGEN" type="text" pInputText
                                        [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_ORIGEN" (keydown.enter)="setFocus($event)"
                                        (keydown)="botonesModificar()" autocomplete="off">
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12" style="margin-top: -15px;">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Ubicación:</span>
                                </div>
                                <div class="p-col-2">
                                    <label for="ORDTRA_UBICACION"></label>
                                    <input class="frm" id="ORDTRA_UBICACION" type="text" pInputText
                                        [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_UBICACION" (keydown.enter)="setFocus($event)"
                                        (keydown)="botonesModificar()" autocomplete="off">
                                </div>
                                <div class="p-col-9">
                                    <div class="p-grid">
                                        <div class="p-col-2">
                                            <span>Días mantenimiento </span>
                                        </div>
                                        <div class="p-col-2">
                                            <label for="MANT_DIAS_MANTE"></label>
                                            <input class="frm" id="MANT_DIAS_MANTE" type="number" pInputText
                                                [(ngModel)]="encordtraService.encordtra[indicador].MANT_DIAS_MANTE"
                                                (keydown.enter)="setFocus($event)" (keydown)="botonesModificar()"
                                                autocomplete="off">
                                        </div>
                                        <div class="p-col-2">
                                            <span>Último mantenimiento</span>
                                        </div>
                                        <div class="p-col-2">
                                            <p-calendar id="MANT_ULT_MANTE"
                                                [inputStyle]="{width: '100%', height: '20px'}"
                                                [(ngModel)]="encordtraService.encordtra[indicador].MANT_ULT_MANTE" [readonlyInput]="true"
                                                dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
                                                (keydown)="botonesModificar()">
                                            </p-calendar>
                                        </div>
                                        <div class="p-col-2">
                                            <span>Próximo mantenimiento</span>
                                        </div>
                                        <div class="p-col-2">
                                            <p-calendar id="MANT_PRX_MANTE"
                                                [inputStyle]="{width: '100%', height: '20px'}"
                                                [(ngModel)]="encordtraService.encordtra[indicador].MANT_PRX_MANTE" [readonlyInput]="true"
                                                dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
                                                (keydown)="botonesModificar()">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12" style="margin-top: -20px;">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Técnico:</span>
                                </div>
                                <div class="p-col-1">
                                    <label for="VEN_CODIGO"></label>
                                    <input class="frm" id="VEN_CODIGO" type="text" pInputText
                                        [(ngModel)]="encordtraService.encordtra[indicador].VEN_CODIGO" (keydown.enter)="setFocus($event)"
                                        autocomplete="off">
                                </div>
                                <div class="p-col-1">
                                    <button type="button"
                                        style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                        (click)="busquedavendedor(encordtraService.encordtra[indicador].VEN_CODIGO)"></button>
                                </div>
                                <div class="p-col-3">
                                    <label for="VEN_CODIGONOMBRE"></label>
                                    <input class="frm" id="VEN_CODIGONOMBRE" type="text" pInputText disabled
                                        [(ngModel)]="encordtraService.encordtra[indicador].VEN_CODIGO" autocomplete="off">
                                </div>
                                <div class="p-col-1">
                                    <span>Asignación</span>
                                </div>
                                <div class="p-col-2">
                                    <p-calendar id="ORDTRA_FECHAASIGNACIONTEC"
                                        [inputStyle]="{width: '100%', height: '20px'}"
                                        [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_FECHAASIGNACIONTEC" [readonlyInput]="true"
                                        dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
                                        (keydown)="botonesModificar()">
                                    </p-calendar>
                                </div>
                                <div class="p-col-1">
                                    <p-calendar id="ORDTRA_HORASIGNACION" [inputStyle]="{width: '80%', height: '20px'}"
                                        [timeOnly]="true" [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_HORASIGNACION"
                                        [readonlyInput]="true" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
                                        (keydown)="botonesModificar()">
                                    </p-calendar>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12" style="margin-top: -20px;">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Observaciones:</span>
                                </div>
                                <div class="p-col-11">
                                    <label for="ORDTRA_OBSPRO"></label>
                                    <input class="frm" id="ORDTRA_OBSPRO" type="text" pInputText
                                        [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_OBSPRO" autocomplete="off">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin-top: -20px;">
                    <app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
                        [rowData]="encordtraService.tableEtiquetas[indicador]"
                        [frameworkComponents]="frameworkComponents" [animateRows]="true" [rowSelection]="rowSelection"
                        [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowStyle]="rowStyle"
                        [singleClickEdit]="false" [botones]="false" (cambios)="botonesModificar()"
                        [mostrarGuardar]="true" (selected)="seleccionEtiqueta($event)">
                    </app-aggridsaci>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Datos Adicionales">
                <app-aggridsaci [width]="'50'" [height]="largogen" [margintop]="'0'" [enableSorting]="true"
                    [enableFilter]="true" [rowData]="tableDatosTecnicos" [frameworkComponents]="frameworkComponents"
                    [animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefsDatosTecnicos"
                    [defaultColDef]="defaultColDefDatosTecnicos" [rowStyle]="rowStyle" [singleClickEdit]="false"
                    [botones]="false">
                </app-aggridsaci>
            </p-tabPanel>
            <p-tabPanel header="Detalle">
                <app-aggridsaci [width]="" [height]="largogen" [enableSorting]="true" [enableFilter]="true"
                    [rowData]="encordtraService.tableDetalle[indicador]" [frameworkComponents]="frameworkComponents"
                    [animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefsDetalle"
                    [defaultColDef]="defaultColDefDetalle" [rowStyle]="rowStyle" [singleClickEdit]="false"
                    [botones]="true" (cambios)="botonesModificar()" [mostrarGuardar]="true"
                    (selected)="seleccionDetalle($event)" (rdbtipodet)="rdbtipodet($event)" 
                    [comboOpciones]="comboOpciones" [tipodetalle]="tipodetalle">
                </app-aggridsaci>
            </p-tabPanel>
            <p-tabPanel header="Registro de Llamadas">
                <app-aggridsaci [width]="" [height]="largogen" [enableSorting]="true" [enableFilter]="true"
                    [rowData]="encordtraService.tableRegistroLLamadas[indicador]"
                    [frameworkComponents]="frameworkComponents" [animateRows]="true" [rowSelection]="rowSelection"
                    [columnDefs]="columnDefsRegistroLLamadas" [defaultColDef]="defaultColDefRegistroLLamadas"
                    [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true" (cambios)="botonesModificar()"
                    [mostrarGuardar]="true" (selected)="seleccionRegLlam($event)">
                </app-aggridsaci>
            </p-tabPanel>
            <p-tabPanel header="Control de Notificaciones">
                <app-aggridsaci [width]="" [height]="largogen" [enableSorting]="true" [enableFilter]="true"
                    [rowData]="encordtraService.tableEnvioCorreos[indicador]"
                    [frameworkComponents]="frameworkComponents" [animateRows]="true" [rowSelection]="rowSelection"
                    [columnDefs]="columnDefsEnvioCorreos" [defaultColDef]="defaultColDefEnvioCorreos"
                    [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true" (cambios)="botonesModificar()"
                    [mostrarGuardar]="true" (selected)="seleccionContNotif($event)">
                </app-aggridsaci>
            </p-tabPanel>
            <p-tabPanel header="Historial del Cliente">
                <app-aggridsaci [width]="" [height]="largogen" [enableSorting]="true" [enableFilter]="true"
                    [rowData]="tableHistorial" [frameworkComponents]="frameworkComponents" [animateRows]="true"
                    [rowSelection]="rowSelection" [columnDefs]="columnDefsHistorial"
                    [defaultColDef]="defaultColDefHistorial" [rowStyle]="rowStyle" [singleClickEdit]="false"
                    [botones]="false">
                </app-aggridsaci>
            </p-tabPanel>
        </p-tabView>
    </div>
    <div class="p-col-12" style="margin-top: -10px;">
        <div class="divgrups">
            <div class="p-grid" style="margin-bottom: -5px;">
                <div class="p-col-1"></div>
                <div class="p-col-1">
                    <span style="text-align: right;">Neto</span>
                </div>
                <div class="p-col-1">
                    <label for="ORDTRA_NETO"></label>
                    <input class="totales" id="ORDTRA_NETO" type="text" pInputText disabled
                        [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_NETO" autocomplete="off">
                </div>
                <div class="p-col-1">
                    <span style="text-align: right;">Base I.V.A.</span>
                </div>
                <div class="p-col-1">
                    <label for="ORDTRA_BASEIVA"></label>
                    <input class="totales" id="ORDTRA_BASEIVA" type="text" pInputText disabled
                        [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_BASEIVA" autocomplete="off">
                </div>
                <div class="p-col-1">
                    <span style="text-align: right;">Valor I.V.A.</span>
                </div>
                <div class="p-col-1">
                    <label for="ORDTRA_IVA"></label>
                    <input class="totales" id="VALORIVA" type="text" pInputText disabled
                        [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_IVA" autocomplete="off">
                </div>
                <div class="p-col-1">
                    <span style="text-align: right;">Base Cero</span>
                </div>
                <div class="p-col-1">
                    <label for="ORDTRA_BASECERO"></label>
                    <input class="totales" id="ORDTRA_BASECERO" type="text" pInputText disabled
                        [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_BASECERO" autocomplete="off">
                </div>
                <div class="p-col-1">
                    <span style="text-align: right;">Total</span>
                </div>
                <div class="p-col-1">
                    <label for="ORDTRA_TOTAL"></label>
                    <input class="totales" id="ORDTRA_TOTAL" type="text" pInputText disabled
                        [(ngModel)]="encordtraService.encordtra[indicador].ORDTRA_TOTAL" autocomplete="off">
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Búsqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
        [where]="where" [busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)">
    </app-busquedadlg>
</p-dialog>

<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
    <p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
    </p-progressSpinner>
</div>
<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'TECENCORDTRA'"
		[txtNumComprobante]="encordtraService.encordtra[indicador].ORDTRA_NUMERO"
		(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>