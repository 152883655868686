import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {InvCodigoAlterno} from '../invinterfaces/invcodigoalterno';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {InvtrndatosaltService} from '../invservicios/invtrndatosalt.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {ConfirmationService, MessageService} from 'primeng';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';

@Component({
  selector: 'app-invtrndatosalt',
  templateUrl: './invtrndatosalt.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class InvtrndatosaltComponent implements OnInit {
  
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  
  @Input() strCodigo: any;
  @Input() strDescripcion: any;
  @Input() strTipo: any;
  @Output() signalSalir = new EventEmitter<boolean>();
  
  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  defaultColDefDetalle;
  
  detalle: InvCodigoAlterno[] = [];
  detalleSeleccionado: InvCodigoAlterno = {};
  
  columnDefsDetalle = [
    { // 3
      headerName: 'Código Alterno', field: 'TRNDATOS_CODIGOALT', width: 150, editable: (params) => {
        const boolEdit = this.isEdit(params.data) ;
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 30,
            opcionbuscar: false
          }
        }
    }
  ];
  
  constructor(public invtrndatosaltService: InvtrndatosaltService,  private init: NuevoComponentService,
              private message: MessageService, private confirmationService: ConfirmationService,
              private errorService: ErroresBaseDatosService) {
    this.agTable();
    this.detalle = [];
  }
  
  ngOnInit() {
    this.habilitarBotones(false, true, false);
    this.mostrarDatos();
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    
    this.defaultColDefDetalle = {
      editable: true,
      width: 80,
      objeto: 'detalleDatosAlt'
    };
    
  }
  
  async mostrarDatos() {
    let strSql = '';
     const rs = await this.invtrndatosaltService.getmostrarDatos(this.strTipo,this.strCodigo);
    console.log(rs);
    if (rs.length > 0) {
      this.detalle = rs;
      this.detalle.map(item => {
        item.NUEVO = false;
      });
    } else {
      this.detalle = [];
    }
    
  }
  
  
  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      this.nuevoDetalle();
    }
    
    if (valor === 'Guardar') {
      this.guardarDetalle();
    }
    
    if (valor === 'Borrar') {
      this.eliminarDetalle();
    }
    
    if (valor === 'Cancelar') {
      this.mostrarDatos();
      this.habilitarBotones(false, true, false);
    }
    
    if (valor === 'Salir') {
      this.signalSalir.emit(true);
    }
    
  }
  
  nuevoDetalle() {
    const item: InvCodigoAlterno = {};
    
    item.COM_CODIGO = '01';
    item.TRNDATOS_CODIGOALT = '';
    item.TRNDATOS_TIPO = this.strTipo;
    item.NUEVO = true;
    
    if (this.strTipo === 'A') {
      item.ART_CODIGO = this.strCodigo;
      item.SER_CODIGO = '';
    } else if (this.strTipo === 'S') {
      item.ART_CODIGO = '';
      item.SER_CODIGO = this.strCodigo;
    }
    
    const indi =  this.detalle.indexOf(this.detalleSeleccionado);
    if (indi === -1) {
      this.detalle.push(item);
      this.detalleSeleccionado = this.detalle[this.detalle.length - 1];
      this.aggrid.refreshaggrid(this.detalle, this.defaultColDefDetalle.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefDetalle.objeto].setFocusedCell(
        this.detalle.length - 1, 'TRNDATOS_CODIGOALT');
    } else {
      this.detalle.splice(indi + 1, 0, item);
      this.aggrid.refreshaggridindex(this.detalle, this.defaultColDefDetalle.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefDetalle.objeto].setFocusedCell(
        indi + 1, 'TRNDATOS_CODIGOALT');
    }
    this.habilitarBotones(true, false, true);
  }
  
  async guardarDetalle() {
    if (this.detalleSeleccionado.TRNDATOS_CODIGOALT === '') {
      this.mensajeAlerta('warn', 'Información', 'Ingrese el código alterno');
    } else {
      try {
        const rs = await this.invtrndatosaltService.erartcoddatosalt(this.detalleSeleccionado.TRNDATOS_CODIGOALT);
        if (rs === null) {
          await this.invtrndatosaltService.guardarCodigo(this.detalleSeleccionado);
          this.mensajeAlerta('success', 'Información', 'Registro guardado exitosamente...!!!');
          this.habilitarBotones(false, true, false);
        } else {
          if (rs[0].ART_CODIGO !== null) {
            const art = await this.invtrndatosaltService.erartnomdatosalt( rs[0].ART_CODIGO);
            this.mensajeAlerta('error', 'Error', 'El código alterno ' + this.detalleSeleccionado.TRNDATOS_CODIGOALT +
              ' ya se encuentra en uso en el artículo ' + rs[0].ART_CODIGO + ' - ' + art[0].ART_NOMBRE);
          }
          if (rs[0].SER_CODIGO !== null) {
            const ser = await this.invtrndatosaltService.erdescripservicio( rs[0].SER_CODIGO );
            this.mensajeAlerta('error', 'Error', 'El código alterno ' + this.detalleSeleccionado.TRNDATOS_CODIGOALT +
              ' ya se encuentra en uso en el servicio ' + rs[0].SER_CODIGO + ' - ' + ser[0].SERCOM_DESCRIPCION);
          }
        }
        
        
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      }
    }
  }
  
  eliminarDetalle() {
    if (Object.keys(this.detalleSeleccionado).length > 0) {
      this.confirmationService.confirm({
        message: '¿ Está seguro de eliminar el registro ?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'datosaltconf',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmarElimDet();
        },
        reject: () => {
        }
      });
      
    } else {
      this.mensajeAlerta('error', 'Error', '! Seleccione una fila.... !');
    }
  }
  
  async confirmarElimDet() {
    try {
      
      await this.invtrndatosaltService.eliminarCodigo(this.detalleSeleccionado);
      
      this.detalle.splice(this.detalle.findIndex(
        item => item.TRNDATOS_CODIGOALT === this.detalleSeleccionado.TRNDATOS_CODIGOALT &&
          item.COM_CODIGO === this.detalleSeleccionado.COM_CODIGO),
        1);
      this.aggrid.refreshaggrid(this.detalle, this.defaultColDefDetalle.objeto);
      this.mensajeAlerta('success', 'Información', 'Código alterno eliminado exitosamente...!!!');
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  }
  
  selecciondetalle(params) {
    if (params.object === undefined) {
      return;
    }
    
    this.detalleSeleccionado = params.object;
  }
  
  habilitarBotones(nuevo, guardar, eliminar) {
    this.botonNuevo = nuevo;
    this.botonGuardar = guardar;
    this.botonBorrar = eliminar;
  }
  
  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'datosalt',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
  
  isEdit(data): boolean {
    if (data.NUEVO === true) {
      return true;
    }
    
    if (data.NUEVO === false) {
      return false;
    }
  }

}
