import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class SacimaecfngimprService {
 
  public way: string;
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, private confIniciales: ConfInicialesService) {
  
    this.way = sessionStorage.getItem('way');
  }
  
  CargarReportesPantalla(codigo, empresa, opcion): Observable<any> {
    console.log('saci_pck_maecfngimpr_c.saci_sp_consultarMaeCfngImprT(' + codigo +
      ',' + empresa +
      ',' + opcion + ')');
    return this.http.post<any>(this.way + '/advantage/reportes/18adc36ece37950000', {
      elementos: {
        p_codigo: codigo,
        p_empresa: empresa,
        p_opcion: opcion
      },
    }, this.confIniciales.httpOptions());
  }
  
  consultaPrmtCnfgImprT(codigo, empresa): Observable<any> {
    return this.http.post<any>(this.way + '/advantage/reportes/198bf3d5cab6ab0000', {
      elementos: {
        p_codigo: codigo,
        p_empresa: empresa,
      },
    }, this.confIniciales.httpOptions());
  }
  
  EjecutaSentenciaUpdate(sentencia): Observable<any> {
    return this.http.post<any>(this.way + '/advantage/reportes/38ebf5d767b2d', {
      elementos: {
        p_CADENA: sentencia
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  obtenerNumeracionTipo(tipo, docRef): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/advantage/reportes/30bedfd6ecb38', {
      elementos: {
        NUM_DOCREF: docRef,
        NUM_CLASE: tipo
      }
    }, this.confIniciales.httpOptions());
  }
}
