//import { Component, OnInit } from '@angular/core';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {AggridBanComponent} from '../../ban/aggrid-ban/aggrid-ban.component';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {DatePipe} from '@angular/common';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {EletrndocumentoService} from 'src/app/ven/venservicios/eletrndocumento.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import  {arraytxt,codalte} from '../anexinterfaces/anexgenerararchivos';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import {AneximpdocsriService}from'../anexservicios/aneximpdocsri.service';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import { from } from 'rxjs';
import { ComEncfacpro } from 'src/app/com/cominterfaces/comencfacpro';
import { ComDetfacpro } from 'src/app/com/cominterfaces/comdetfacpro';
import { ComencfacproService } from 'src/app/com/comservicios/comencfacpro.service';
import { async } from '@angular/core/testing';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';

@Component({
  selector: 'app-aneximpdocsri',
  templateUrl: './aneximpdocsri.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class AneximpdocsriComponent implements OnInit {
  public frameworkComponents;
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  indicador: any;
  arreglotxt: arraytxt[]= []; 
  guararreglotxt: arraytxt[]= [];
  selecteditem: arraytxt;
  arreglocodalt: codalte[]= [];
  selecteditemcodalt: codalte;
  documentos: any[];
  codalt: any[];
  encfacdesc: ComEncfacpro;
  defaultColDefDocimpsri;
  defaultDefscodalter;
  rowStyle;
  rowStyle1;
  choosedColorR;
  largo: string;
  types: SelectItem[];
  arregloCons: any[];
  tabla: string;
  consulta: string;
  where: string;
  opcionbusqueda;
  busquedacampos: string[];
  activeIndex;
  filesImportXML = [];
  desSelect: boolean;
  spin = false;
  rowSelection;
  resultado;
  displayDialogBusquedaFast;
  displaycodalt;
  botonesBoolean: boolean;
  newnumfac:'';
  // LECTURA TXT
  fileReader: FileReader;
  fileTXT: any;
  localizacionxml2: string;
  bodcodigoxml;
  botonesmodificar;
  bodnombrexml;
  idcreditoxml;
  idcreditonombrexml;
  selectlinea: boolean;
  actbotones1: boolean;
  actbotones2: boolean;
  actbotones3: boolean;
  //GSRF
  columnDefsDocimpsri = [
    {
      headerName: '', field: 'VISTO', editable: false, width: 50,
      cellRendererFramework: AggridBanComponent
    },
    {
      headerName: 'Fecha Emision', field: 'TRNIMPDOC_FECHAEMISION', editable: false, width: 100
    },
    {
      headerName: 'Tipo documento', field: 'TRNIMPDOC_TIPODOC', editable: false, width: 100
    },
    {
      headerName: 'Serie', field: 'TRNIMPDOC_SERIE', editable: false, width: 90
    },
    {
      headerName: 'Comprobante', field: 'TRNIMPDOC_COMPROBANTE', editable: false, width: 140
    },
    {
      headerName: 'R.U.C.', field: 'TRNIMPDOC_RUC', editable: false, width: 140
    },
    {
      headerName: 'Razón Social', field: 'TRNIMPDOC_NOMBRE', editable: false, width: 250
    },
    {
      headerName: 'Fecha Autorización', field: 'TRNIMPDOC_FECHAAUT', editable: false, width: 180
    },
    {
      headerName: 'Referencia', field: 'TRNIMPDOC_REFERENCIA', editable: false, width: 140
    },
    {
      headerName: 'total', field: 'TRNIMPDOC_IMPORTE', editable: false, width: 80
    },
    {
      headerName: 'Clave de accesso', field: 'TRNIMPDOC_CLAVEACCE', editable: false, width: 350
    },
    {
      headerName: 'existepro', field: 'EXISTE_RUC', editable: false, width: 50 , hide: true 
    }];

  columnDefscodalter = [
      {
        headerName: 'Item', field: 'CODALT_ITEM', editable: false, width: 100
      },
      {
        headerName: 'Tipo', field: 'CODALT_TIPO', width: 70, cellEditor: 'agSelectCellEditor',
        // , editable: true,
        cellEditorParams: 
        {
          values: ['','A','S','C']
        }
      },
      {
        headerName: 'Codigo', field: 'CODALT_CODIGO', cellEditor: 'cellPrueba', width: 100,
        // , editable: true,
        cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 25,
            opcionbuscar: true
          }
        }
      },
      {
        headerName: 'Descripción', field: 'CODALT_DESCRIPCION', editable: false, width: 200
      },
      {
        headerName: 'Unidad', field: 'CODALT_UNIDAD', editable: false, width: 90 , cellStyle: { textAlign: 'center' }
      },
      {
        headerName: 'Cantidad', field: 'CODALT_CANTIDAD', editable: false, width: 100, cellStyle: { textAlign: 'right' }
      },
      {
        headerName: 'Costo', field: 'CODALT_COSTO', editable: false, width: 100, cellStyle: { textAlign: 'right' }
      },
      { 
        headerName: 'Total', field: 'CODALT_TOTAL', editable: false, width: 100, cellStyle: { textAlign: 'right' }
      },
      {
        headerName: 'Cen. Costos', field: 'CODALT_CENCOS', cellEditor: 'cellPrueba', width: 100,
        // , editable: true,
        cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 25,
            opcionbuscar: true
          }
        }
      },
    ];

  constructor(public eletrndocumentoService: EletrndocumentoService,
    private busqService: InvbusquedaService,
    private datePipe: DatePipe, private errorService: ErroresBaseDatosService,
    private message: MessageService, private confirmationService: ConfirmationService,
    private init: NuevoComponentService, private utilitariosService: UtilitariosService,
    public AneximpdocsriService: AneximpdocsriService,public encfacproService: ComencfacproService,
    private auditoriagral: AuditoriagralService
    ) {
this.agTable();
this.AneximpdocsriService.arreglocodalt[this.indicador] = [];
}
agTable() {
  this.frameworkComponents = {
    cellPrueba: CellEditorPrueba
  };

  this.rowStyle1 = (params) => {
    if (params.node.rowIndex % 2 !== 0) {
      return {background: '#D6EAF8'};
    }
  };
  this.rowStyle = (params) => {
    if (params.node.data.EXISTE_RUC  !== true) {
      return {background: '#FF6666'};
    } else if (params.node.rowIndex % 2 !== 0) {
      return {background: '#D6EAF8'};
    }
  };
  this.defaultColDefDocimpsri = {
    editable: true,
    objeto: 'maecierre'
  };
  this.defaultDefscodalter = {
    editable: true,
      width: 100,
      objeto: 'detfac',
      resizable: true
  };
}

  ngOnInit(): void {
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.largo = '500';
    this.fileTXT = '';
    this.selectlinea = true;
    this.actbotones1= true;
    this.actbotones2= true;
    this.actbotones3= true;
    this.arreglotxt = [];
    this.guararreglotxt = [];
    this.selecteditem = {};
    this.bodcodigoxml='';
    this.bodnombrexml='';
    this.idcreditoxml='';
    this.idcreditonombrexml='';
    this.AneximpdocsriService.arreglocodalt[this.indicador] = [];
    this.choosedColorR = '#FF0000';
  }
  setFocus(event){
    let element: any;
    switch (event.target.id) {
    case 'BOD_CODIGOXML': {
      this.busquedaBodega(event.target.value, 'bodegaxml');
      document.getElementById('IDCREDITOXML').focus();
      if(this.bodcodigoxml!=='' && this.idcreditoxml!==''){
        this.actbotones2= false;
      }
      break;
    }case 'IDCREDITOXML': {
      this.busquedaidcretri(event.target.value, 'cretrixml');
      document.getElementById('DESSELECT').focus();
      if(this.bodcodigoxml!=='' && this.idcreditoxml!==''){
        this.actbotones2= false;
      }
      break;
    }
    }
  }
  procesar() {
    //console.log('+++++',this.fileTXT)
          if(this.fileTXT === ''){
            this.message.add({
              key: 'docimpsri',
              severity: 'error',
              summary: 'Error',
              detail: 'Elija un documento para procesar'
            });
            return;
          }else{
      this.confirmationService.confirm({
        message: 'Está seguro de ejecutar el proceso?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'docimpsri',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          // this.transformarArchivo();
          //this.listarDatosXMLSubir();
          this.transformaArray();
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    }
  }

  salir() {
    this.init.tabs.splice(this.init.tabindex, 1);
  }
  sal() {
    
    this.displaycodalt= false;
  }
  
  genretencion() {
    
  }
  SeleccionarDirectorio(event) {
    this.fileReader = new FileReader();
    for (const file of event.files) {
      this.localizacionxml2 = file.name;
      this.fileTXT = file;
    }
  }
  habilitarBotones(){
 
  }



  manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'bodegaxml') {
      this.bodcodigoxml = opcion.BOD_CODIGO;
      this.bodnombrexml = opcion.BOD_NOMBRE;
      document.getElementById('IDCREDITOXML').focus();
      this.displayDialogBusquedaFast = false;
      if(this.bodcodigoxml!=='' && this.idcreditoxml!==''){
        this.actbotones2= false;
      }
    }
    if (this.opcionbusqueda === 'cretrixml') {
      this.idcreditoxml = opcion.CODSRI_CODIGO;
      this.idcreditonombrexml = opcion.CODSRI_DESCRIPCION;
      this.displayDialogBusquedaFast = false;
      if(this.bodcodigoxml!=='' && this.idcreditoxml!==''){
        this.actbotones2= false;
      }
    }
  }

  transformaArray() {
    let strTexto = '';
    let strCadena = '';
    let intIni = 0;
    let intFin = 0;

    let strDatos:  arraytxt[]= [];
    this.fileReader.readAsText(this.fileTXT);
    this.fileReader.onload = () => {
      strTexto = this.fileReader.result.toString();
      const numeroAutorizacion = strTexto.split(/[\t\n]/g);
      let numfin=numeroAutorizacion.length - 12 ;
      const txt = numeroAutorizacion.splice(12,numfin);
      console.log('/////*****////',txt)
      console.log('/////*****////',strCadena)
      let i = 0;
      let j = 0;
      let VISTO = false;
      let TRNIMPDOC_TIPODOC = '';
      let TRNIMPDOC_SERIE = '';
      let TRNIMPDOC_COMPROBANTE = '';
      let TRNIMPDOC_RUC = '';
      let TRNIMPDOC_NOMBRE = '';
      let TRNIMPDOC_FECHAEMISION = '';
      let TRNIMPDOC_FECHAAUT = '';
      let TRNIMPDOC_TIPEMISION = '';
      let TRNIMPDOC_REFERENCIA = '';
      let TRNIMPDOC_IDERECEP = '';
      let TRNIMPDOC_CLAVEACCE = '';
      let TRNIMPDOC_NUMAUTORIZACION = '';
      let TRNIMPDOC_IMPORTE = '';
      let EXISTE_RUC = false;
       txt.forEach(async(res) => {
        const arreglo: arraytxt = {};
        console.log('----',res)
        console.log('----',i)
        console.log('----',j)
              if(j === 0){
                j=j+1;
                VISTO=false;
                TRNIMPDOC_TIPODOC =  res.split(' ')[0];
              } else if(j === 1) {
                j=j+1;
                // aux=res.replace(/[-]/g,'');
                TRNIMPDOC_SERIE = res.slice(0,7).replace(/[-]/g,'') ;
                TRNIMPDOC_COMPROBANTE =  res.slice(7,17).replace(/[-]/g,'');
              } else if(j === 2) {
                j=j+1;
                TRNIMPDOC_RUC = res;
              } else if(j === 3) {
                j=j+1;
                TRNIMPDOC_NOMBRE  =  res.replace(/[\u0300-\u036f]/g,'');
              } else if(j === 4) {
                j=j+1;
                TRNIMPDOC_FECHAEMISION  = res;
              } else if(j === 5) {
                j=j+1;
                TRNIMPDOC_FECHAAUT = res;
              } else if(j === 6) {
                j=j+1;
                TRNIMPDOC_TIPEMISION  =  res;
              } else if(j === 7) {
                j=j+1;
                TRNIMPDOC_REFERENCIA =   res.replace(/[-]/g,'');
              } else if(j === 8) {
                j=j+1;
                TRNIMPDOC_IDERECEP =  res;
              } else if(j === 9) {
                j=j+1;
                TRNIMPDOC_CLAVEACCE  =  res;
              } else if(j === 10) {
                j=j+1;
                TRNIMPDOC_NUMAUTORIZACION  =  res;
              } else if(j === 11) {
                TRNIMPDOC_IMPORTE = res;
                arreglo.VISTO = VISTO;
                arreglo.TRNIMPDOC_TIPODOC =  TRNIMPDOC_TIPODOC;
                arreglo.TRNIMPDOC_SERIE = TRNIMPDOC_SERIE;
                arreglo.TRNIMPDOC_COMPROBANTE =  TRNIMPDOC_COMPROBANTE;
                arreglo.TRNIMPDOC_RUC =  TRNIMPDOC_RUC;
                arreglo.TRNIMPDOC_NOMBRE =  TRNIMPDOC_NOMBRE;
                arreglo.TRNIMPDOC_FECHAEMISION =  TRNIMPDOC_FECHAEMISION;
                arreglo.TRNIMPDOC_FECHAAUT =  TRNIMPDOC_FECHAAUT;
                arreglo.TRNIMPDOC_TIPEMISION =  TRNIMPDOC_TIPEMISION;
                arreglo.TRNIMPDOC_REFERENCIA =  TRNIMPDOC_REFERENCIA;
                arreglo.TRNIMPDOC_IDERECEP =  TRNIMPDOC_IDERECEP;
                arreglo.TRNIMPDOC_CLAVEACCE =  TRNIMPDOC_CLAVEACCE;
                arreglo.TRNIMPDOC_NUMAUTORIZACION =  TRNIMPDOC_NUMAUTORIZACION;
                arreglo.TRNIMPDOC_IMPORTE =  TRNIMPDOC_IMPORTE;
                arreglo.EXISTE_RUC = EXISTE_RUC;
                console.log('----',arreglo)
                if(TRNIMPDOC_TIPODOC==='Factura'){
                  strDatos = [...strDatos, arreglo];
                }
                  
                i=i+1;
                j=0;
                TRNIMPDOC_TIPODOC = '';
                TRNIMPDOC_COMPROBANTE = '';
                TRNIMPDOC_RUC = '';
                TRNIMPDOC_NOMBRE = '';
                TRNIMPDOC_FECHAEMISION = '';
                TRNIMPDOC_FECHAAUT = '';
                TRNIMPDOC_TIPEMISION = '';
                TRNIMPDOC_REFERENCIA = '';
                TRNIMPDOC_IDERECEP = '';
                TRNIMPDOC_CLAVEACCE = '';
                TRNIMPDOC_NUMAUTORIZACION = '';
                TRNIMPDOC_IMPORTE = '';
              }
              
      }); 
      this.guararreglotxt  = strDatos; 
      this.existefac(strDatos);
    };
    
  }
    async existefac(datos){
      this.spin = true;
      let exisDatos:  arraytxt[]= []
      for(const res of datos){
        const exispro = await this.AneximpdocsriService.existenpro(res.TRNIMPDOC_RUC);
          if(exispro.length !==0){
            res.EXISTE_RUC = true
          }
        const existe = await this.AneximpdocsriService.conexistencfacpro(res.TRNIMPDOC_RUC,res.TRNIMPDOC_COMPROBANTE,res.TRNIMPDOC_SERIE); 
        const rucompnia = await this.AneximpdocsriService.conruccompania(res.TRNIMPDOC_IDERECEP); 
        console.log('----ruc empresa----',rucompnia)
        if(existe.length ===0 && rucompnia.length !==0){
          exisDatos = [...exisDatos, res];
        }
      }
      this.arreglotxt = exisDatos;
      this.documentos = exisDatos;
      console.log('----',this.arreglotxt)
      this.spin = false;
      if(this.arreglotxt.length ===  0){
        this.message.add({
          key: 'docimpsri',
          severity: 'warn',
          summary: 'Información',
          detail: 'Todos los documentos ya existen en el sistema'
        });
        return;
      }
    }
    cleardoc(){    
      console.log('--LIMPIA--') 
      this.fileTXT = '';
      this.arreglotxt = [];
      this.selecteditem = {};
      this.documentos = [];
      this.selectlinea = true;
      this.actbotones1= true;
      this.actbotones2= true;
      this.actbotones3= true;
      this.bodcodigoxml='';
      this.bodnombrexml='';
    } 
    busquedaBodega(parametro, opcionbusqueda) {
      if (parametro === undefined) {
        parametro = '';
      }
  
      this.opcionbusqueda = opcionbusqueda;
      this.types = [
        { label: 'Código', value: 'BOD_CODIGO' },
        { label: 'Nombre', value: 'BOD_NOMBRE' }
      ];
      this.busquedacampos = [parametro, ''];
      this.consulta = '';
      this.tabla = 'inv_maebodega';
      this.where = '';
  
      this.encfacproService.erBodNombre1(parametro).subscribe(eR => {
        if (eR !== null) {
          if (eR[0] !== undefined) {
            if (opcionbusqueda === 'bodegaxml') {
              this.bodnombrexml = eR[0].BOD_NOMBRE;
            }
            //this.displayDialogBusquedaFast = true;
          } else {
            this.busqService.busquedaInvMaeBodega2(parametro).subscribe((datos: any[]) => {
              this.arregloCons = datos;
              this.displayDialogBusquedaFast = true;
            });
          }
        } else {
          this.busqService.busquedaInvMaeBodega2(parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      });
    }
    busquedaidcretri(parametro, opcionbusqueda) {
      //const strFechaEmision = this.datePipe.transform(this.selecteditem.TRNIMPDOC_FECHAEMISION, 'dd/MM/yyyy');
      const strFechaEmision = this.selecteditem.TRNIMPDOC_FECHAEMISION;
      let strSentencia = '';
      strSentencia = strSentencia + ' to_date( \'' + strFechaEmision + '\' ,\'dd/mm/yyyy\')>= codsri_fechaini';
      strSentencia = strSentencia + ' and to_date( \'' + strFechaEmision + '\' ,\'dd/mm/yyyy\')<= codsri_fechafin';
  
      if (parametro === null) {
        parametro = '';
      }
      this.opcionbusqueda = opcionbusqueda;
      this.types = [
        { label: 'Código', value: 'CODSRI_CODIGO' },
        { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
        { label: 'Tipo', value: 'CODSRI_TIPO' },
        { label: 'Año', value: 'CODSRI_ANIO' },
        { label: 'Ref.', value: 'CODSRI_REF' },
        { label: 'Código Con.', value: 'CON_CODIGO' },
      ];
      this.busquedacampos = [parametro, '', '', '', '', ''];
      this.consulta = '';
      this.tabla = 'ANEX_MAECODSRI';
      this.where = 'CODSRI_TIPO=\'CT\' AND ' + strSentencia;
  
      this.encfacproService.erCodSriDescripcion1(strFechaEmision, parametro).subscribe(eR => {
        if (eR !== null) {
          if (eR[0] !== undefined) {
            this.idcreditonombrexml = eR[0].CODSRI_DESCRIPCION;
          } else {
            this.busqService.busquedaAnexMaeCodSri2(strSentencia, parametro).subscribe((datos: any[]) => {
              this.arregloCons = datos;
              this.displayDialogBusquedaFast = true;
            });
          }
        } else {
          this.busqService.busquedaAnexMaeCodSri2(strSentencia, parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      });
    }

    seleccionFil() {
      let num=0;
      this.selectlinea = true;
      this.actbotones1= true;
      this.actbotones2= true;
      this.actbotones3= true;
      // this.bodcodigoxml='';
      // this.bodnombrexml='';
      // this.idcreditoxml='';
      // this.idcreditonombrexml='';
      this.selecteditem = {};
      this.encfacdesc = {};
      this.codalt= [];
      this.AneximpdocsriService.arreglocodalt[this.indicador] = [];
        this.documentos.map(variable => {
            if (variable.VISTO === true && num === this.aggrid.gridApi.getFocusedCell().rowIndex ) {
              if(variable.EXISTE_RUC===false){
                variable.VISTO = false;
                this.message.add({
                  key: 'docimpsri',
                  severity: 'warn',
                  summary: 'Información',
                  detail: 'Cree el proveedor en el sistema'
                });
                return;
              }
              variable.VISTO = true;
              this.selecteditem = variable;
              console.log('++++++', this.selecteditem)
              this.actbotones1= false;
            } else { 
              variable.VISTO = false;
            }
            num=num+1;
        });
        this.aggrid.refreshColumnDefs();
    }
    seleccionFilcodalt(valor){
      this.selecteditem = {};
    }
    async descargarsri(){
      console.log('/*/*/ selecionado',this.selecteditem)
      this.spin= true;
      let descar = await this.AneximpdocsriService.descargarxmlsri(this.selecteditem.TRNIMPDOC_CLAVEACCE);
      console.log('87978797----', descar)
      let comprobante = await this.readComprobante({ descarga: descar })
      console.log('87978797----', comprobante)
      if(comprobante === null){
        this.spin= false;
        this.message.add({
          key: 'docimpsri',
          severity: 'error',
          summary: 'Error',
          detail: 'Hubo un error al descargar el archivo'
        });
        return;
      }
      // comprobante={
      //   providerIdentification: '1790819515001',
      //   providerSocialReason: 'DISTRIBUIDORA DE LIBROS Y PAPELERIA,DILIPA C.LTDA',
      //   providerIdentificationType: 'ruc',
      //   address: 'SANTO DOMINGO DE LOS COLORADOS/ GUAYAQUIL S/N Y AMBATO',
      //   authorization: '0302202301170598422500120011000000000498755431510',
      //   serie: '001100',
      //   sequential: '000000049',
      //   purchaseReceiptDate: '11/04/2023',
      //   emissionDate: '11/04/2023',
      //   subTotalWithoutTaxes: 434.86,
      //   totalDiscount: 0,
      //   total: 487.04,
      //   enumeration: '001100000000049',
      //   subTotalIva: 434.86,
      //   subTotalZeroIva: 0,
      //   subTotalNoObjectIva: 0,
      //   subTotalNoExemptIva: 0,
      //   iceValue: 0,
      //   ivaValue: 52.18,
      //   porcent: 12,
      //   voucherType: '01',
      //   comment: 'Importado desde el SRI',
      //   details: [
      //     { 
      //       CODALT_ITEM: 'PR-002',
      //       CODALT_TIPO:''
      //       ,CODALT_CODIGO:'',
      //       CODALT_DESCRIPCION: 'ADEREZO SALSA DE TOMATE ROMERY X 3600 G',
      //       CODALT_UNIDAD:'',
      //       CODALT_CANTIDAD: 50,
      //       CODALT_COSTO: 2.37,
      //       CODALT_DESC: 0,
      //       CODALT_TOTAL: 118.5,
      //       CODALT_IVAVAL: 14.22,//
      //       CODALT_BASEIMPO: 118.5,//
      //       CODALT_IVACOD: 2,//
      //       CODALT_IVARATE: 12,
      //       CODALT_CENCOS:'',
      //     },
      //     {
      //       CODALT_ITEM: 'PR-003',
      //       CODALT_TIPO:''
      //       ,CODALT_CODIGO:'',
      //       CODALT_DESCRIPCION: 'ADEREZO SALSA DE TOMATE ROMERY X 2000 G',
      //       CODALT_UNIDAD:'',
      //       CODALT_CANTIDAD: 12,
      //       CODALT_COSTO: 1.65,
      //       CODALT_DESC: 0,
      //       CODALT_TOTAL: 19.8,
      //       CODALT_IVAVAL: 2.38,
      //       CODALT_BASEIMPO: 19.8,
      //       CODALT_IVACOD: 2,
      //       CODALT_IVARATE: 12,
      //       CODALT_CENCOS:'',
      //     },
      //     {
      //       CODALT_ITEM: 'PM-014',
      //       CODALT_TIPO:''
      //       ,CODALT_CODIGO:'',
      //       CODALT_DESCRIPCION: 'SALSA DE TOMATE MINEÑA GALON',
      //       CODALT_UNIDAD:'',
      //       CODALT_CANTIDAD: 50,
      //       CODALT_COSTO: 1.7,
      //       CODALT_DESC: 0,
      //       CODALT_TOTAL: 85,
      //       CODALT_IVAVAL: 10.2,
      //       CODALT_BASEIMPO: 85,
      //       CODALT_IVACOD: 2,
      //       CODALT_IVARATE: 12,
      //       CODALT_CENCOS:'',
      //     },
      //     {
      //       CODALT_ITEM: 'PM-006',
      //       CODALT_TIPO:''
      //       ,CODALT_CODIGO:'',
      //       CODALT_DESCRIPCION: 'SALSA DE TOMATE MINEÑA 2 KG',
      //       CODALT_UNIDAD:'',
      //       CODALT_CANTIDAD: 12,
      //       CODALT_COSTO: 0.8,
      //       CODALT_DESC: 0,
      //       CODALT_TOTAL: 9.6,
      //       CODALT_IVAVAL: 1.15,
      //       CODALT_BASEIMPO: 9.6,
      //       CODALT_IVACOD: 2,
      //       CODALT_IVARATE: 12,
      //       CODALT_CENCOS:'',
      //     },
      //     {
      //       CODALT_ITEM: 'PM-005',
      //       CODALT_TIPO:''
      //       ,CODALT_CODIGO:'',
      //       CODALT_DESCRIPCION: 'SALSA DE TOMATE MINEÑA 1 KG',
      //       CODALT_UNIDAD:'',
      //       CODALT_CANTIDAD: 1,
      //       CODALT_COSTO: 0.9,
      //       CODALT_DESC: 0,
      //       CODALT_TOTAL: 0.9,
      //       CODALT_IVAVAL: 0.11,
      //       CODALT_BASEIMPO: 0.9,
      //       CODALT_IVACOD: 2,
      //       CODALT_IVARATE: 12,
      //       CODALT_CENCOS:'',
      //     },
      //     {
      //       CODALT_ITEM: 'PB',
      //       CODALT_TIPO:''
      //       ,CODALT_CODIGO:'',
      //       CODALT_DESCRIPCION: 'GALON SALSA TOMATE LA IDEAL',
      //       CODALT_UNIDAD:'',
      //       CODALT_CANTIDAD: 50,
      //       CODALT_COSTO: 1.56,
      //       CODALT_DESC: 0,
      //       CODALT_TOTAL: 78,
      //       CODALT_IVAVAL: 9.36,
      //       CODALT_BASEIMPO: 78,
      //       CODALT_IVACOD: 2,
      //       CODALT_IVARATE: 12,
      //       CODALT_CENCOS:'',
      //     },
      //     {
      //       CODALT_ITEM: 'PM-001',
      //       CODALT_TIPO:''
      //       ,CODALT_CODIGO:'',
      //       CODALT_DESCRIPCION: 'MAYONESA MINEÑA 1 KG',
      //       CODALT_UNIDAD:'',
      //       CODALT_CANTIDAD: 12,
      //       CODALT_COSTO: 2.32,
      //       CODALT_DESC: 0,
      //       CODALT_TOTAL: 27.84,
      //       CODALT_IVAVAL: 3.34,
      //       CODALT_BASEIMPO: 27.84, 
      //       CODALT_IVACOD: 2,
      //       CODALT_IVARATE: 12,
      //       CODALT_CENCOS:'',
      //     },
      //     {
      //       CODALT_ITEM: 'PR-015',
      //       CODALT_TIPO:''
      //       ,CODALT_CODIGO:'',
      //       CODALT_DESCRIPCION: 'VINAGRE BLANCO ROMERY X 3200 G',
      //       CODALT_UNIDAD:'',
      //       CODALT_CANTIDAD: 12,
      //       CODALT_COSTO: 1.65,
      //       CODALT_DESC: 0,
      //       CODALT_TOTAL: 19.8,
      //       CODALT_IVAVAL: 2.38,
      //       CODALT_BASEIMPO: 19.8,
      //       CODALT_IVACOD: 2,
      //       CODALT_IVARATE: 12,
      //       CODALT_CENCOS:'',
      //     }
      //   ]
      // }
     
    const item: ComEncfacpro = {};
    const exispro = await this.AneximpdocsriService.existenpro(comprobante.providerIdentification);
    console.log('/*/* proveedor ',exispro[0].PRO_CODIGO) 
    item.ENCFACPRO_NUMERO = '';
    item.COM_CODIGO = '01';
    item.ENCFACPRO_FECHAEMISION = comprobante.purchaseReceiptDate;
    item.ENCFACPRO_FECHAVENCIMIENTO = comprobante.purchaseReceiptDate;
    item.ENCFACPRO_FECHAREC = comprobante.purchaseReceiptDate;
    item.ENCFACPRO_IVA = '2';
    item.ENCFACPRO_ESTADO = 'P';
    item.PRO_CODIGO = exispro[0].PRO_CODIGO;
    item.ENCFACPRO_CONTACTO = comprobante.providerSocialReason;
    item.ENCFACPRO_REFERENCIA = comprobante.sequential
    item.ENCFACPRO_TOTAL = Number(comprobante.total);
    item.ENCFACPRO_TOTALNETO = Number(comprobante.subTotalWithoutTaxes);
    item.ENCFACPRO_VALORDES = Number(comprobante.totalDiscount);
    item.ENCFACPRO_PORCEDES = 0;
    item.ENCFACPRO_VALORIVA = Number(comprobante.ivaValue);
    item.ENCFACPRO_PORCEIVA = Number(comprobante.porcent);
    item.ENCFACPRO_VALORICE = 0;
    item.ENCFACPRO_BASEIVA = Number(comprobante.subTotalIva);
    item.ENCFACPRO_BASEICE = 0;
    item.ENCFACPRO_BASECERO = Number(comprobante.subTotalZeroIva);
    item.ENCFACPRO_COMENTARIO = comprobante.comment;
    item.ENCFACPRO_OTROS = 0;
    item.ENCFACPRO_DISTCARGOS = 'N';
    item.ENCFACPRO_NUMDIASPLAZO = '';
    item.ENCFACPRO_IDCRETRI = this.idcreditoxml;
    item.ENCFACPRO_SERIE = comprobante.serie;
    item.ENCFACPRO_AUTORIZACION = comprobante.authorization;
    item.ENCFACPRO_TIPCOM = '01';
    item.MAEMOTIVO_CODIGO = '';
    item.ENCFACPRO_FECHACADFAC = comprobante.purchaseReceiptDate;
    item.ENCFACPRO_NUMINGRESO = '';
    item.ENCFACPRO_NUMLIQUIDACION = '';
    item.ENCFACPRO_TIPODES = 'V';
    item.ENCFACPRO_GASTO = '';
    item.ENCORDCOM_NUMERO = '';
    item.ENCFACPRO_NOOBJETOIVA = Number(comprobante.subTotalNoObjectIva);
    item.ENCFACPRO_BASENOOBJIVA = 0;
    item.TRNFORMAPAGO_CODIGO = '';
    item.ENCFACPRO_FLAG=10;
      this.encfacdesc=item;
      console.log('/-/-/insertar enc',this.encfacdesc);
      this.AneximpdocsriService.arreglocodalt[this.indicador]=comprobante.details;
      for(const res of this.AneximpdocsriService.arreglocodalt[this.indicador] ){
        console.log('/*-', res.CODALT_ITEM)
        const art = await this.AneximpdocsriService.busqarti(res.CODALT_ITEM);
        console.log('/*-', art)
        if(art.length !== 0){
          res.CODALT_CODIGO = art[0].ART_CODIGO
          console.log('/*-', res.CODALT_CODIGO)
          res.CODALT_TIPO = 'A'
          res.CODALT_UNIDAD= art[0].ART_UNIDADCOSTEO
          console.log('/*-', res.CODALT_UNIDAD)
        }else{
          const codalt = await this.AneximpdocsriService.busqcodalt(res.CODALT_ITEM);
          console.log('/*-', codalt)
          if(codalt.length !== 0){
          res.CODALT_CODIGO = codalt[0].ART_CODIGO
          console.log('/*-', res.CODALT_CODIGO)
          res.CODALT_TIPO = 'A'
          res.CODALT_UNIDAD= codalt[0].ART_UNIDADCOSTEO
          console.log('/*-', res.CODALT_UNIDAD)
          }
        }
        
      }
      console.log('87978797----', comprobante.details);
      this.selectlinea = false;
      if(this.bodcodigoxml!=='' && this.idcreditoxml!==''){
        this.actbotones2= false;
      }
      this.spin= false; 
      this.actbotones1=true;
    }
    revisaralt(){
      this.aggrid.refreshaggrid(this.AneximpdocsriService.arreglocodalt[this.indicador], this.defaultDefscodalter.objeto);
      this.aggrid.refreshColumnDefs();
      this.displaycodalt= true;
      this.actbotones3= false;
      this.actbotones1= true;
    }
    async importarsri(){
      console.log('', this.AneximpdocsriService.arreglocodalt[this.indicador]);
      for(const res of  this.AneximpdocsriService.arreglocodalt[this.indicador]){
        if(res.CODALT_CODIGO ===''){
          this.message.add({
            key: 'docimpsri',
            severity: 'error',
            summary: 'Error',
            detail: 'Ingrese los codigos alternos'
          });
          return;
        }
        if( res.CODALT_TIPO===''){
          this.message.add({
            key: 'docimpsri',
            severity: 'error',
            summary: 'Error',
            detail: 'Ingrese los tipos de codigos alternos'
          });
          return;
        }

      }
      if(this.idcreditoxml ===''){
        this.message.add({
          key: 'docimpsri',
          severity: 'error',
          summary: 'Error',
          detail: 'Ingrese Id. Crédito Tributario'
        });
        return;
      }
      if(this.bodcodigoxml ===''){
        this.message.add({
          key: 'docimpsri',
          severity: 'error',
          summary: 'Error',
          detail: 'Ingrese Id. Crédito Tributario'
        });
        return;
      }
      this.spin=true;
      try{
      this.encfacdesc.ENCFACPRO_IDCRETRI=this.idcreditoxml;
      const encs = await this.AneximpdocsriService.insertarEncabezadoFactPromise(this.encfacdesc);
      console.log('insrte enc',encs[0].V_ENCFACPRO_NUMERO);
      this.newnumfac=encs[0].V_ENCFACPRO_NUMERO;
      for (const det of this.AneximpdocsriService.arreglocodalt[this.indicador]) {
        const itemdet: ComDetfacpro  = {};
        itemdet.ENCFACPRO_NUMERO = encs[0].V_ENCFACPRO_NUMERO;
        itemdet.DETFACPRO_LINEA = 0;
        itemdet.DETFACPRO_TIPODET = det.CODALT_TIPO;
        itemdet.BOD_CODIGO = this.bodcodigoxml;
        itemdet.DETFACPRO_CODIGO = det.CODALT_CODIGO;
        itemdet.DETFACPRO_DESCRIPCION = det.CODALT_DESCRIPCION;
        itemdet.DETFACPRO_UNIDAD = det.CODALT_UNIDAD;
        itemdet.DETFACPRO_CANTIDAD = Number(det.CODALT_CANTIDAD);
        itemdet.DETFACPRO_ENTREGADO = 0;
        itemdet.DETFACPRO_COSTO = Number(det.CODALT_COSTO);
        itemdet.DETFACPRO_VALDES = Number(det.CODALT_DESC);
        itemdet.DETFACPRO_PORDES = 0;
        itemdet.DETFACPRO_TOTAL = Number(det.CODALT_TOTAL);
        itemdet.DETFACPRO_PORIVA = Number(det.CODALT_IVARATE);
        itemdet.DETFACPRO_IVA = Number(det.CODALT_IVAVAL);
        itemdet.DETFACPRO_TRIBICE = "N";
        itemdet.DETFACPRO_ICE = 0;
        itemdet.DETFACPRO_PORCEICE = 0;
        itemdet.DETFACPRO_BASEIVA = Number(det.CODALT_BASEIMPO);
        itemdet.DETFACPRO_BASEICE = 0;
        itemdet.DETFACPRO_BASECERO = 0;
        itemdet.ENCORDCOM_NUMERO = null;
        itemdet.DETORDCOM_LINEA = null;
        itemdet.ENCNOTREC_NUMERO = null;
        itemdet.DETNOTREC_LINEA = null;
        itemdet.DETFACPRO_PROMOCION = null;
        itemdet.DETFACPRO_TOTALINCP = null;
        itemdet.DETFACPRO_PORDES2 = null;
        itemdet.DETFACPRO_PORDES3 = null;
        itemdet.CEN_CODIGO = det.CODALT_CENCOS;
        itemdet.DETFACPRO_FACTOR = null;
        itemdet.DETFACPRO_PRECIOFOB = null;
        itemdet.DETFACPRO_ESQUEMADOC = null;
        itemdet.DETFACPRO_TIPOSRI = null;
        itemdet.DETFACPRO_PRECIOA = null;
        itemdet.DETFACPRO_PRECIOB = null;
        itemdet.DETFACPRO_PRECIOC = null;
        itemdet.TRNSOLFAC_CODIGO = null;
        itemdet.TRNSOLFAC_LINEA = null;
        itemdet.ENCREQ_NUMERO = null;
        itemdet.DETREQ_LINEA = null;
        itemdet.ENCGRE_CODIGO = null;
        itemdet.DETGRE_LINEA = null;
        itemdet.DETFACPRO_BASENOOBJIVA = null;
        itemdet.DETFACPRO_TRIBASENOOBJIVA = null;
        itemdet.DETFACPRO_CANTIDADUND = null;
        itemdet.TIPGAS_CODIGO= null;
        await this.AneximpdocsriService.insertarDetalleFacturaPromise(itemdet);
      }
      this.spin=false;
    } catch (err) {
      this.spin=false;
      const error = this.errorService.generarMensajeError(err.error.substr(0, 9));
      this.message.add({
        key: 'docimpsri',
        severity: 'error',
        summary: 'Error',
        detail: error
      });
      return;
    }
    ///--------------------------------------------
      this.actbotones2= true;
      await this.existefac(this.guararreglotxt);
      this.message.add({
        key: 'docimpsri',
        severity: 'success',
        summary: 'Información',
        detail: 'Documento importado con exito, número de documento es: ' + this.newnumfac
      });
      this.auditoriagral.registrarAuditoria('COM_ENCFACPRO',
      this.newnumfac + '/' +
            '', 'I',
            '', '01', '', '', '', '').subscribe();
      this.seleccionFil();
      this.AneximpdocsriService.arreglocodalt[this.indicador] = [];
    }

    ///////// lectura de web service /////////
     readComprobante({ descarga }) {
      try {
        const {
          factura: { infoTributaria, infoFactura, detalles },
        } = descarga.comprobante
        const total = this.getTotals({ totalConImpuestos: infoFactura[0].totalConImpuestos })
        const details = this.getDetails({ detalles })
        const header = {
          providerIdentification: infoTributaria[0].ruc[0],
          providerSocialReason: infoTributaria[0].razonSocial[0],
          providerIdentificationType: `ruc`,
          address: infoTributaria[0].dirMatriz[0],
          authorization: infoTributaria[0].claveAcceso[0],
          serie: infoTributaria[0].estab[0] + infoTributaria[0].ptoEmi[0],
          sequential: infoTributaria[0].secuencial[0],
          //purchaseReceiptDate: this.datePipe.transform(infoFactura[0].fechaEmision[0], 'dd/MM/yyyy'),
          purchaseReceiptDate: infoFactura[0].fechaEmision[0],
          emissionDate: this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss'),
          subTotalWithoutTaxes: parseFloat(infoFactura[0].totalSinImpuestos[0]),
          totalDiscount: parseFloat(infoFactura[0].totalDescuento[0]),
          total: parseFloat(infoFactura[0].importeTotal[0]),
          enumeration:
            infoTributaria[0].estab[0] + infoTributaria[0].ptoEmi[0] + infoTributaria[0].secuencial[0],
          ...total,
          voucherType: `01`,
          comment: `Importado desde el SRI`,
          details,
        }
        console.log('------CABECERA------', header)
        return header
      } catch (error) {
        console.error(error)
        return null
      }
    }
    
     getTotals({ totalConImpuestos }) {
      const totals = {
        subTotalIva: 0,
        subTotalZeroIva: 0,
        subTotalNoObjectIva: 0,
        subTotalNoExemptIva: 0,
        iceValue: 0,
        ivaValue: 0,
        porcent: 0,
      }
      const totalImpuesto = totalConImpuestos[0].totalImpuesto
      totalImpuesto.forEach((impuesto) => {
        const { codigoPorcentaje, baseImponible, valor, codigo } = impuesto
        if (codigo[0] === `3`) { //ICE
          totals.iceValue += parseFloat(valor)
        }
        switch (codigoPorcentaje[0]) {
          case `2`: ///iva 12
            totals.subTotalIva += parseFloat(baseImponible[0])
            totals.ivaValue += parseFloat(valor[0])
            totals.porcent = 12;
            break
          case `0`:/// 0 de iv
            totals.subTotalZeroIva += parseFloat(baseImponible[0])
            totals.porcent = 0;
            break 
          case `6`:///// NO OBJETO DE IVA
            totals.subTotalNoObjectIva += parseFloat(baseImponible[0])
            totals.porcent = 0;
            break
          case `7`:////
            totals.subTotalNoExemptIva += parseFloat(baseImponible[0])
            totals.porcent = 0;
            break
          case `8`:////
            totals.subTotalNoExemptIva += parseFloat(baseImponible[0])
            totals.porcent = 8;
            break
          case `3`:////
            totals.subTotalNoExemptIva += parseFloat(baseImponible[0])
            totals.porcent = 14;
            break
          default:
            break
        }
      })
      console.log('------TOTALES------', totals)
      return totals
    }
     getDetails({ detalles }) {
      try {
        //console.log(JSON.stringify(detalles, null, 2))
        //generate a code random number
        const details = detalles[0].detalle
        const detailsParsed = details.map((detail) => {
          const {
            codigoPrincipal,
            //codigoAuxiliar,
            descripcion,
            cantidad,
            precioUnitario,
            descuento,
            precioTotalSinImpuesto,
            impuestos,
          } = detail
          const { baseImponible, valor, codigoPorcentaje } = impuestos[0].impuesto[0]
          return {
            CODALT_ITEM: codigoPrincipal ? codigoPrincipal[0] : `NOCODE`,
            CODALT_TIPO:'',
            CODALT_CODIGO:'',
            CODALT_DESCRIPCION: descripcion[0],
            CODALT_UNIDAD:'',
            CODALT_CANTIDAD: parseFloat(cantidad[0]),
            CODALT_COSTO: parseFloat(precioUnitario[0]),
            CODALT_DESC: parseFloat(descuento[0]),
            CODALT_TOTAL: parseFloat(precioTotalSinImpuesto[0]),
            CODALT_IVAVAL: parseFloat(valor[0]),
            CODALT_BASEIMPO: parseFloat(baseImponible[0]),
            CODALT_IVACOD: parseFloat(codigoPorcentaje[0]),
            CODALT_IVARATE: codigoPorcentaje[0] === `2` ? 12 : 0,
            CODALT_CENCOS:'',
          }
          
        })
    
        console.log('------DETALLES------', detailsParsed)
        return detailsParsed
      } catch (error) {
        console.error(error)
        return null
      }
    }
    ///////////////////////////////////////////////////////////////////////

}
