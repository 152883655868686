import {Component, OnInit, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {CxcMaeTarjetaCredito} from '../../interfaces/cxcmaetarjetacredito';
import {CxctarjetaCreditoService} from '../cxcservicios/cxctarjeta-credito.service';
import {CalculosServiceService} from '../../servicios/calculos-service.service';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';


@Component({
  selector: 'app-cxctarjeta-credito',
  templateUrl: './cxctarjeta-credito.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class CxctarjetaCreditoComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  actualizar: boolean;
  permisosTarjetas: SegMaePermiso;
  codigoAux: any;
  arregloCons: any[];
  nuevoCampo: boolean;
  desCodigo: boolean;
  largo: string;
  tarjetaCreditoSeleccionada: CxcMaeTarjetaCredito;
  public frameworkComponents;
  rowStyle;
  defaultColDeTarjetaCredito;
  columnDefs;
  rowSelection = 'multiple';
  columnDefsTarjetaCredito = [
    {
      headerName: 'Número', field: 'TAR_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 35
          }
        }
    },
    {
      headerName: 'Descripción', field: 'TAR_DESCRIPCION', cellEditor: 'cellPrueba', width: 250, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 200
          }
        }
    },
    {
      headerName: '%', field: 'TAR_PORCENTAJE', cellEditor: 'cellPrueba', width: 200, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        }
    },
    {
      headerName: 'R.C. Interés', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            opcionbuscar: true,
            tamanocelda: 35
          }
        }
    },
    {
      headerName: 'R.C. Comisión', field: 'CON_CODIGOCOM', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            opcionbuscar: true,
            tamanocelda: 35
          }
        }
    },
    {
      headerName: 'Ret.Fuente', field: 'CON_CODIGORFTE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            opcionbuscar: true,
            tamanocelda: 35
          }
        }
    },
    {
      headerName: 'Ret.Iva', field: 'CON_CODIGOFIVA', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            opcionbuscar: true,
            tamanocelda: 35
          }
        }
    },
    {
      headerName: 'Ruc', field: 'TAR_RUC', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 15
          }
        }
    },
    {
      headerName: 'Cod.Sri', field: 'TAR_CODSRI', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10
          }
        }
    },
  ];
  
  
  /*this.calc.getDecimalesConfig('CANTIDAD'),
  
   */
  
  constructor(public tarjetaService: CxctarjetaCreditoService, private message: MessageService,
              private errorService: ErroresBaseDatosService,
              private confirmationService: ConfirmationService, private calc: CalculosServiceService,
              private permisosService: PermisosService, private busqService: InvbusquedaService,
              private auditoriagral: AuditoriagralService, private cuentaContableServicio: CxctarjetaCreditoService,
              private init: NuevoComponentService  ) {
    this.agTable();
    this.permisosTarjetas = {};
    this.tarjetaService.cxcmaeTarjetaCredito = [];
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDeTarjetaCredito = {
      editable: true,
      width: 100,
      objeto: 'cxcmaetarjetacredito'
    };
  }
  
  ngOnInit() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.actualizar = false;
    this.nuevoCampo = false;
    this.buscarCon();
    this.desCodigo = true;
    this.codigoAux = 0;
    
    this.permisosService.getListaPermisos('CXC', '1', '103').subscribe((res) => {
      this.permisosTarjetas = res[0];
    });
    this.cargarTarjetasCred();
    this.largo = '430';
  }
  
  cargarTarjetasCred() {
    
    this.tarjetaService.getTarjetasCreditoClient().subscribe((res) => {
      if (res !== null) {
        this.tarjetaService.cxcmaeTarjetaCredito = res;
        
        let linea = 0;
        this.tarjetaService.cxcmaeTarjetaCredito = [];
        res.map(fp => {
          linea += 1;
          let objTC: CxcMaeTarjetaCredito;
          objTC = {};
          objTC.TAR_CODIGO = fp.TAR_CODIGO;
          objTC.TAR_DESCRIPCION = fp.TAR_DESCRIPCION;
          objTC.TAR_PORCENTAJE = fp.TAR_PORCENTAJE;
          objTC.CON_CODIGO = fp.CON_CODIGO;
          objTC.COM_CODIGO = fp.COM_CODIGO;
          objTC.CON_CODIGOCOM = fp.CON_CODIGOCOM;
          objTC.CON_CODIGORFTE = fp.CON_CODIGORFTE;
          objTC.CON_CODIGOFIVA = fp.CON_CODIGOFIVA;
          objTC.TAR_RUC = fp.TAR_RUC;
          objTC.TAR_CODSRI = fp.TAR_CODSRI;
          objTC.LINEA = String(linea);
          this.tarjetaService.cxcmaeTarjetaCredito.push(objTC);
          
        });
        this.aggrid.refreshaggrid(this.tarjetaService.cxcmaeTarjetaCredito, this.defaultColDeTarjetaCredito.objeto);
      }
    });
    
    
  }
  
  actualizarTarjetaC() {
    this.tarjetaService.actualizarTarjetaCredito(this.tarjetaCreditoSeleccionada, this.codigoAux).subscribe(() => {
      this.message.add({
        key: 'cxcmaetarjetacredito',
        severity: 'success',
        summary: 'Actualización exitosa',
        detail: 'Se actualizó Tarjeta de Credito  correctamente'
      });
      this.auditoriagral.registrarAuditoria('cxc_maetarjeta', this.tarjetaCreditoSeleccionada.TAR_CODIGO, 'A', ''
        , '01', '', '', '', '').subscribe(() => {
      });
      
      this.aggrid.isEditable(false);
    }, error1 => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.message.add({
        key: 'cxcmaetarjetacredito',
        severity: 'error',
        summary: 'Actualización de Tarjeta de Credito',
        detail: mensaje
      });
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.nuevoCampo = false;
      this.cargarTarjetasCred();
    });
  }
  
  insertarTarjetaT() {
    this.tarjetaService.insertarTarjetaCredito(this.tarjetaCreditoSeleccionada).subscribe(() => {
      this.message.add({
        key: 'cxcmaetarjetacredito',
        severity: 'success',
        summary: 'Inserción exitosa',
        detail: 'Se insertó tarjeta de credito correctamente'
      });
      this.auditoriagral.registrarAuditoria('cxc_maetarjeta', this.tarjetaCreditoSeleccionada.TAR_CODIGO, 'I', ''
        , '01', '', '', '', '').subscribe(() => {
      });
      
      this.aggrid.isEditable(false);
      this.nuevoCampo = false;
    }, error1 => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.message.add({
        key: 'cxcmaetarjetacredito',
        severity: 'error',
        summary: 'Inserción de la tarjeta de credito',
        detail: mensaje
      });
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.nuevoCampo = false;
      this.cargarTarjetasCred();
    });
  }
  
  
  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      if (this.permisosTarjetas.PERINSERCION === 1) {
        this.btnNuevo = true;
        this.btnGuardar = false;
        this.btnBorrar = false;
        this.btnRegresar = false;
        this.nuevoCampo = true;
        this.aggrid.isEditable(true);
        
        const linea = this.tarjetaService.cxcmaeTarjetaCredito.length;
        this.tarjetaCreditoSeleccionada = {
          TAR_CODIGO: '',
          TAR_DESCRIPCION: '',
          TAR_PORCENTAJE: '',
          CON_CODIGO: '',
          COM_CODIGO: '01',
          CON_CODIGOCOM: '',
          CON_CODIGORFTE: '',
          CON_CODIGOFIVA: '',
          TAR_RUC: '',
          TAR_CODSRI: '',
          LINEA: String(linea + 1)
        };
        this.tarjetaService.cxcmaeTarjetaCredito.push(this.tarjetaCreditoSeleccionada);
        this.aggrid.refreshaggrid(this.tarjetaService.cxcmaeTarjetaCredito, this.defaultColDeTarjetaCredito.objeto);
      } else if (this.permisosTarjetas.PERINSERCION === 0) {
        this.message.add({
          key: 'cxcmaetarjetacredito',
          severity: 'error',
          summary: 'Inserción de Tarjeta De Credito',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    }
    if (valor === 'Guardar') {
      if (this.permisosTarjetas.PERACTUALIZACION === 1) {
        this.guardarTC();
      } else if (this.permisosTarjetas.PERACTUALIZACION === 0) {
        this.message.add({
          key: 'cxcmaetarjetacredito',
          severity: 'error',
          summary: 'Actualizacion de Tarjeta de Credito',
          detail: 'El usuario no tiene permisos de Actualizacion, acción denegada'
        });
      }
    }
    if (valor === 'Borrar') {
      if (this.permisosTarjetas.PERACTUALIZACION === 1) {
        this.btnNuevo = false;
        this.aggrid.isEditable(false);
        if (this.tarjetaCreditoSeleccionada === undefined) {
          this.message.add({
            key: 'cxcmaetarjetacredito',
            severity: 'error',
            summary: 'Eliminacion de Tarjeta Credito',
            detail: ' Seleccion Incorrecta, no se ha selecionado una Tarjeta De Credito'
          });
        } else {
          this.confirmarTarjetaCredito();
        }
      } else if (this.permisosTarjetas.PERELIMACION === 0) {
        this.message.add({
          key: 'cxcmaetarjetacredito',
          severity: 'error',
          summary: 'Eliminacion de Tarjeta Credito',
          detail: ' El usuario no tiene permisos de Eliminacion, acción denega'
        });
      }
    }
    if (valor === 'Cancelar') {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.nuevoCampo = false ;
      this.cargarTarjetasCred();
      this.aggrid.isEditable(false);
  
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
  guardarTC() {
    
    this.btnGuardar = true;
    this.btnNuevo = false;
    this.btnBorrar = false;
    this.btnRegresar = true;
    
    let coincidencias1: number;
    let coincidencias2: number;
    let coincidencias3: number;
    let coincidencias4: number;
    coincidencias1 = 0;
    coincidencias2 = 0;
    coincidencias3 = 0;
    coincidencias4 = 0;
    
    this.arregloCons.map((con) => {
      if (this.tarjetaCreditoSeleccionada.CON_CODIGO === con.CON_CODIGO) {
        coincidencias1++;
      }
      if (this.tarjetaCreditoSeleccionada.CON_CODIGOCOM === con.CON_CODIGO) {
        coincidencias2++;
      }
      if (this.tarjetaCreditoSeleccionada.CON_CODIGORFTE === con.CON_CODIGO) {
        coincidencias3++;
      }
      if (this.tarjetaCreditoSeleccionada.CON_CODIGOFIVA === con.CON_CODIGO) {
        coincidencias4++;
      }
    });
    
    if (this.tarjetaCreditoSeleccionada.CON_CODIGO === null) {
      this.tarjetaCreditoSeleccionada.CON_CODIGO = '';
    }
    if (this.tarjetaCreditoSeleccionada.CON_CODIGOCOM === null) {
      this.tarjetaCreditoSeleccionada.CON_CODIGOCOM = '';
    }
    if (this.tarjetaCreditoSeleccionada.CON_CODIGORFTE === null) {
      this.tarjetaCreditoSeleccionada.CON_CODIGORFTE = '';
    }
    if (this.tarjetaCreditoSeleccionada.CON_CODIGOFIVA === null) {
      this.tarjetaCreditoSeleccionada.CON_CODIGOFIVA = '';
    }
    
    if (this.nuevoCampo === true) {
      if (this.tarjetaCreditoSeleccionada.CON_CODIGO.length === 0 &&
        this.tarjetaCreditoSeleccionada.CON_CODIGOCOM.length === 0 &&
        this.tarjetaCreditoSeleccionada.CON_CODIGORFTE.length === 0 &&
        this.tarjetaCreditoSeleccionada.CON_CODIGOFIVA.length === 0) {
        this.insertarTarjetaT();
      } else {
        if (coincidencias1 !== 0 || coincidencias2 !== 0 || coincidencias3 !== 0 || coincidencias4 !== 0) {
          this.insertarTarjetaT();
          
        } else if (coincidencias1 === 0) {
          this.message.add({
            key: 'cxcmaetarjetacredito',
            severity: 'error',
            summary: 'Error en cuenta Contable Interes',
            detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
          });
          this.tarjetaCreditoSeleccionada.CON_CODIGO = '';
          this.aggrid.refreshaggrid(this.cuentaContableServicio.cxcmaeTarjetaCredito, this.defaultColDeTarjetaCredito.objeto);
        } else if (coincidencias2 === 0) {
          this.message.add({
            key: 'cxcmaetarjetacredito',
            severity: 'error',
            summary: 'Error en cuenta Contable Comision',
            detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
          });
          this.tarjetaCreditoSeleccionada.CON_CODIGOCOM = '';
          this.aggrid.refreshaggrid(this.cuentaContableServicio.cxcmaeTarjetaCredito, this.defaultColDeTarjetaCredito.objeto);
        } else if (coincidencias3 === 0) {
          this.message.add({
            key: 'cxcmaetarjetacredito',
            severity: 'error',
            summary: 'Error en cuenta Contable Ret Fuente',
            detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
          });
          this.tarjetaCreditoSeleccionada.CON_CODIGORFTE = '';
          this.aggrid.refreshaggrid(this.cuentaContableServicio.cxcmaeTarjetaCredito, this.defaultColDeTarjetaCredito.objeto);
        } else if (coincidencias4 === 0) {
          this.message.add({
            key: 'cxcmaetarjetacredito',
            severity: 'error',
            summary: 'Error en cuenta Contable Ret Iva',
            detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
          });
          this.tarjetaCreditoSeleccionada.CON_CODIGOFIVA = '';
          this.aggrid.refreshaggrid(this.cuentaContableServicio.cxcmaeTarjetaCredito, this.defaultColDeTarjetaCredito.objeto);
        }
        
      }
      
    } else if (this.nuevoCampo === false) {
      this.tarjetaService.erTarcodigo(this.tarjetaCreditoSeleccionada.TAR_CODIGO).subscribe(existe => {
        if (existe !== null) {
          
          if (this.tarjetaCreditoSeleccionada.CON_CODIGO.length === 0 &&
            this.tarjetaCreditoSeleccionada.CON_CODIGOCOM.length === 0 &&
            this.tarjetaCreditoSeleccionada.CON_CODIGORFTE.length === 0 &&
            this.tarjetaCreditoSeleccionada.CON_CODIGOFIVA.length === 0) {
            
            this.actualizarTarjetaC();
          } else {
            if (coincidencias1 !== 0 || coincidencias2 !== 0 || coincidencias3 !== 0 || coincidencias4 !== 0) {
              this.actualizarTarjetaC();
              
            } else if (this.tarjetaCreditoSeleccionada.CON_CODIGO.length === ' ' &&
              this.tarjetaCreditoSeleccionada.CON_CODIGOCOM.length === 0) {
              if (coincidencias1 === 0) {
                this.message.add({
                  key: 'cxcmaetarjetacredito',
                  severity: 'error',
                  summary: 'Error en cuenta Contable Interes',
                  detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
                });
                this.tarjetaCreditoSeleccionada.CON_CODIGO = '';
                this.aggrid.refreshaggrid(this.cuentaContableServicio.cxcmaeTarjetaCredito, this.defaultColDeTarjetaCredito.objeto);
              } else if (coincidencias2 === 0) {
                this.message.add({
                  key: 'cxcmaetarjetacredito',
                  severity: 'error',
                  summary: 'Error en cuenta Contable Comision',
                  detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
                });
                this.tarjetaCreditoSeleccionada.CON_CODIGOCOM = '';
                this.aggrid.refreshaggrid(this.cuentaContableServicio.cxcmaeTarjetaCredito, this.defaultColDeTarjetaCredito.objeto);
              } else if (coincidencias3 === 0) {
                this.message.add({
                  key: 'cxcmaetarjetacredito',
                  severity: 'error',
                  summary: 'Error en cuenta Contable Ret Fuente',
                  detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
                });
                this.tarjetaCreditoSeleccionada.CON_CODIGORFTE = '';
                this.aggrid.refreshaggrid(this.cuentaContableServicio.cxcmaeTarjetaCredito, this.defaultColDeTarjetaCredito.objeto);
              } else if (coincidencias4 === 0) {
                this.message.add({
                  key: 'cxcmaetarjetacredito',
                  severity: 'error',
                  summary: 'Error en cuenta Contable Ret Iva',
                  detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
                });
                this.tarjetaCreditoSeleccionada.CON_CODIGOFIVA = '';
                this.aggrid.refreshaggrid(this.cuentaContableServicio.cxcmaeTarjetaCredito, this.defaultColDeTarjetaCredito.objeto);
              }
            }
          }
          
        }
        
      });
    }
    
    this.aggrid.isEditable(this.btnNuevo);
  }
  
  selectedTarjetaCredito(valor) {
    if (valor.object !== null) {
      this.tarjetaCreditoSeleccionada = valor.object;
      if (this.nuevoCampo === true) {
        this.actualizar = false;
      } else if (this.nuevoCampo === false) {
        this.actualizar = true;
        if (this.tarjetaCreditoSeleccionada !== undefined) {
          this.codigoAux = this.tarjetaCreditoSeleccionada.TAR_CODIGO;
        }
      }
    }
  }
  
  confirmarTarjetaCredito() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar Tarjeta de Credito',
      header: 'Eliminar Tarjeta de Credito',
      icon: 'pi pi-exclamation-triangle',
      key: 'Cxcmaetarjetacredito',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarTarjetaCredito();
      },
      reject: () => {
      }
    });
  }
  
  eliminarTarjetaCredito() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.tarjetaService.eliminarTarjetaCredito(this.tarjetaCreditoSeleccionada)
      .subscribe(() => {
        this.message.add({
          key: 'cxcmaetarjetacredito',
          severity: 'success',
          summary: 'Eliminación exitosa',
          detail: 'Se eliminó la tarjeta de Credito correctamente'
        });
        this.auditoriagral.registrarAuditoria('cxc_maetarjeta', this.tarjetaCreditoSeleccionada.TAR_CODIGO, 'E', ''
          , '01', '', '', '', '').subscribe(() => {
        });
        this.tarjetaService.cxcmaeTarjetaCredito = this.tarjetaService.cxcmaeTarjetaCredito.filter((val, j) => j !==
          this.tarjetaService.cxcmaeTarjetaCredito.indexOf(this.tarjetaCreditoSeleccionada));
        this.aggrid.refreshaggrid(this.tarjetaService.cxcmaeTarjetaCredito, this.defaultColDeTarjetaCredito.objeto);
        this.nuevoCampo = false;
      });
  }
  
  cambio(cambio) {
    this.btnGuardar = false;
    this.btnRegresar = false;
  }
  
  buscarCon() {
    this.busqService.busquedaConMaeCon().subscribe((res: any[]) => {
      this.arregloCons = res;
    });
  }
  
  controlGuardar(valor) {
    this.manejarSenales('Guardar');
  }
}

