<p-toast [style]="{marginTop: '30px'}" position="top-center" key="generaCierre"></p-toast>

<div class="p-grid" style="width: 99%">
    <div class="p-col-12">
        <app-invmaebarra [barraBotonesCRUD]="true" [barraBotonesDesplaz]="true" [barraBotones3]="true"
            [barraBotonesAsiCon]="true" [barraBotones5]="true" (signal)="manejarSenales($event)"></app-invmaebarra>
    </div>
</div> 


<div class="divgrups">
    <div class="p-grid">
        <div class="p-col-2">
            <span style="margin-left: 20px; font-size: 150%;">Periodo: <b>{{perCodigo}}</b></span>
        </div>
    </div>
    <div class="p-grid"  >
        <div class="p-col-2"></div>
        <div class="p-col-2">
            <span style="margin-left: 20px; font-size: 200%;">Desde:</span>
        </div>
        <div class="p-col-2">
            <input class="frm"  type="text" pInputText style="font-size: 22px;" [(ngModel)]="periodoInicio" readonly="readonly">
            
        </div>


        <div class="p-col-2">
            <span style="margin-left: 20px; font-size: 200%;">Hasta:</span>
        </div>
        <div class="p-col-2">
            <input class="frm"  type="text" pInputText style="font-size: 22px;" [(ngModel)]="periodoFin" readonly="readonly">
            	
        </div>
        <div class="p-col-2"></div>
    </div>

    <div class="p-grid"  style="margin-top: 30px;">
        <div class="p-col-6">
        </div>
        <div class="p-col-2">
        </div>
        <div class="p-col-2">
            <button  pButton class="aceptar" 
                style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 49% ;margin-bottom: 5px;" 
                (click)="cancelar()">Cancelar</button>	
    
        </div>
        <div class="p-col-2">
            <button  pButton class="aceptar" 
                style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 49%;margin-left: 5px;margin-bottom: 5px;" 
                (click)="funGeneraCierrePeriodo()">Aceptar</button>
        </div>
    </div>
</div>
