import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EncabezadoCierreCaja} from '../baninterfaces/encabezadoCierreCaja';
import {BancierrecajaService} from '../banservicios/bancierrecaja.service';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {CamposBusquedas} from '../baninterfaces/camposBusquedas';
import {DatePipe} from '@angular/common';
import {Consulta} from '../baninterfaces/consulta';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {AggridBanComponent} from '../aggrid-ban/aggrid-ban.component';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {CamposMovimiento} from '../baninterfaces/camposMovimiento';
import {CamposMovimientosVarios} from '../baninterfaces/camposMovimientosVarios';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {CamposCierreCaja} from '../baninterfaces/camposCierreCaja';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {Usuario} from '../../usuario';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';//CDPJ

@Component({
  selector: 'app-bancierre-caja',
  templateUrl: './bancierre-caja.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class BancierreCajaComponent implements OnInit, OnDestroy {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  encabezadosCierreCaja: EncabezadoCierreCaja[];
  sumaTotalMov: number;
  sumatoria: any[];
  encabezadosCierreCajaSeleccionada: EncabezadoCierreCaja;
  date1: any;
  date2: any;
  date3: any;
  horadesde: any;
  horahasta: any;
  types: SelectItem[];
  opcion: string;
  arregloBusCaja: CamposBusquedas[];
  arregloBus: any[];
  displayDialogBus: boolean;
  displayDialogBus2: boolean;
  displayDialogSuma: boolean;
  displayDialogReportes: boolean;
  btnBuscarDes: boolean;
  btnNuevo: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  btnGuardar: boolean;
  actualizar: boolean;
  btnPrimero: boolean;
  movimientoGuardado: boolean;
  btnAnterior: boolean;
  btnSiguiente: boolean;
  btnUltimo: boolean;
  btnBuscar: boolean;
  checkDes: boolean;
  inputDes: boolean;
  horaDes: boolean;
  desSelect: boolean;
  spin: boolean;
  total: string;
  indice: number;
  opcSelectCaj: any;
  opcSelectOri: any;
  opcSelectVen: any;
  consulta: Consulta;
  cajas: CamposBusquedas[];
  origenes: CamposBusquedas[];
  vendedores: CamposBusquedas[];
  vendedorSelec: CamposBusquedas;
  consultaCaj: string;
  consultaOri: string;
  consultaVen: string;
  valor: boolean;
  activeIndex: number;
  opcionDropDown: string;
  movimientoSeleccionado: CamposMovimiento;
  movimientosVariosSeleccionado: CamposMovimientosVarios;
  checkCierre: boolean;
  claveDialog: boolean;
  largo: string;
  panelMov: boolean;
  claveAutorizacion: string;
  public frameworkComponents;
  rowStyle;
  defaultColDefCierre;
  defaultColDefMovimiento;
  rowSelection = 'multiple';
  
  
  busquedacampos: any[];
  displayDialogBusquedaFast: boolean;
  tabla: string;
  where: string;
  disabledCmb: boolean;
  permisos: SegMaePermiso;
  
  auxreporte: number =0;
  strNombreForma: string;
  
  columnDefsCierre = [
    {
      headerName: 'Num', field: 'NUMERO', editable: false, width: 50
    },
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', editable: false, width: 100
    },
    {
      headerName: 'Tipo', field: 'FORMAPAGO_TIPO', editable: false, width: 100
    },
    {
      headerName: 'Origen', field: 'APLORG_CODIGO', editable: false, width: 100
    },
    {
      headerName: 'Documento', field: 'TRNCOBRO_NROCOMPROBANTE', editable: false, width: 150
    },
    {
      headerName: 'Vendedor', field: 'VEN_CODIGO', editable: false, width: 130
    },
    {
      headerName: 'Débitos', field: 'DETCUADRECAJA_DEBITO', editable: false, width: 125
    },
    {
      headerName: 'Créditos', field: 'DETCUADRECAJA_CREDITO', editable: false, width: 125
    },
    {
      headerName: 'S/N', cellRendererFramework: AggridBanComponent, field: 'SNBOOLEAN', editable: false, checkCierre: true, width: 80,
      onCellClicked: (params) => {
        this.setSelectItemDoc(params.data);
      }
    },
    {
      headerName: 'Documentos', field: 'TRNDOCUMENTO_NRODOC', editable: false, width: 225
    }];
  columnDefsMovimiento = [
    {
      headerName: 'Num', field: 'TRNCAJ_NUMERO', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false
          }
        }
    },
    {
      headerName: 'Fecha', field: 'TRNCAJ_FECHA', cellEditor: 'cellPrueba', width: 250,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: false,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: true
          }
        }
    },
    {
      headerName: 'Autorizado Por', field: 'TRNCAJ_AUTORIZADO', cellEditor: 'cellPrueba', width: 300,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: false,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false
          }
        }
    },
    {
      headerName: 'Descripción', field: 'TRNCAJ_DESCRIPCION', cellEditor: 'cellPrueba', width: 400,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: false,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
          }
        }
    },
    {
      headerName: 'Importe', field: 'TRNCAJ_IMPORTE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            int: true
          }
        }
    },
    {
      headerName: 'Cuentas Contables', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: true,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false
          }
        }
    }
  ];
  
  constructor(public cajaservice: BancierrecajaService, private busquedaSer: InvbusquedaService, private datePipe: DatePipe,
              private message: MessageService, private confirmationService: ConfirmationService, private init: NuevoComponentService,
              private permisosService: PermisosService,  public usuario: Usuario,  private errorService: ErroresBaseDatosService,
              private auditoriagral: AuditoriagralService,
              private confIniciales: ConfInicialesService) {
    this.agTable();
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefCierre = {
      editable: true,
      objeto: 'maecierre'
    };
    this.defaultColDefMovimiento = {
      editable: true,
      objeto: 'maemovimiento'
    };
    
  }
  
  ngOnInit() {
    this.date1 = new Date();
    this.date2 = new Date();
    this.date3 = new Date();
    this.horadesde = new Date();
    this.horahasta = new Date();
    this.indice = 0;
    this.sumaTotalMov = 0;
    this.activeIndex = 0;
    this.displayDialogBus = false;
    this.displayDialogBus2 = false;
    this.displayDialogSuma = false;
    this.btnBuscarDes = true;
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.btnPrimero = false;
    this.btnAnterior = false;
    this.btnSiguiente = false;
    this.btnUltimo = false;
    this.btnBuscar = false;
    this.checkDes = true;
    this.actualizar = false;
    this.inputDes = true
    this.disabledCmb = false;
    this.horaDes = true;
    this.valor = true;
    this.movimientoGuardado = false;
    this.desSelect = false;
    this.spin = false;
    this.cajas = [];
    this.origenes = [];
    this.vendedores = [];
    this.vendedorSelec = {};
    this.sumatoria = [];
    this.consulta = {};
    this.opcionDropDown = '';
    this.opcSelectCaj = '';
    this.opcSelectOri = '';
    this.opcSelectVen = '';
    this.consultaCaj = '';
    this.consultaOri = '';
    this.consultaVen = '';
    this.checkCierre = true;
    this.panelMov = true;
    this.claveDialog = false;
    this.claveAutorizacion = '';
    this.largo = '530';
    this.encabezadosCierreCajaSeleccionada = {};
    this.movimientoSeleccionado = {};
    this.movimientosVariosSeleccionado = {};
    this.cajaservice.getEncabezadosCierreCaja().subscribe((res) => {
      this.encabezadosCierreCaja = res;
    });
    this.cajaservice.movimientos = [];
    this.cajaservice.movimientosVarios = [];
    this.permisosService.getListaPermisos('BAN', '3', '3750').subscribe((permiso) => {
      this.permisos = permiso[0];
    });   
    
  }
  
  ngOnDestroy(): void {
    this.cajaservice.camposCierreCaja = [];
    this.cajaservice.movimientos = [];
    this.cajaservice.movimientosVarios = [];
    this.confIniciales.instanciasCierreCaja--;//CDPJ
  }
  
  async manejarSenales(valor) {
    
    if (valor === 'Primero') {
      this.btnBuscar = false;
      this.btnAnterior = true;
      this.btnPrimero = true;
      this.checkDes = true;
      this.btnUltimo = false;
      this.btnSiguiente = false;
      this.btnRegresar = false;
      this.panelMov = false;
      this.opcionDropDown = 'FILTROCUADRECAJA_DESCRIPCION';
      this.encabezadosCierreCajaSeleccionada = this.encabezadosCierreCaja[0];
      this.date1 = new Date(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_FECHA);
      this.date2 = new Date(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_DESDE);
      this.date3 = new Date(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HASTA);
      this.horadesde = new Date(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_FECHA);
      if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO !== null) {
        if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO.toString().length !== 5) {
          this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO = '0' + this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO.toString();
        }
        this.horadesde.setHours(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO.substr(0, 2));
        this.horadesde.setMinutes(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO.substr(-1, 2));
      } else if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO === null) {
        this.horadesde = null;
      }
      this.horahasta = new Date(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_FECHA);
      if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN !== null) {
        if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN.toString().length !== 5) {
          this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN = '0' + this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN.toString();
        }
        this.horahasta.setHours(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN.substr(0, 2));
        this.horahasta.setMinutes(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN.substr(-1, 2));
      } else if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN === null) {
        this.horahasta = null;
      }
      
      try {
        // Obtener opciones
        const opcion = await this.cajaservice.getOpcionesProm(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO);
        this.cajas = [];
        this.origenes = [];
        this.vendedores = [];
        if (opcion.length > 0) {
          for (const item of opcion) {
            if (item.FILTROCUADRECAJA_TIPO === 'C') {
              this.cajas.push({CAJ_CODIGO: item.FILTROCUADRECAJA_CODIGO, CAJ_DESCRIPCION: item.FILTROCUADRECAJA_DESCRIPCION,
                LABEL: item.FILTROCUADRECAJA_CODIGO + '-' + item.FILTROCUADRECAJA_DESCRIPCION});
            }
            if (item.FILTROCUADRECAJA_TIPO === 'O') {
              this.origenes.push({APLORG_CODIGO: item.FILTROCUADRECAJA_CODIGO, APLORG_NOMBRE: item.FILTROCUADRECAJA_DESCRIPCION,
                LABEL: item.FILTROCUADRECAJA_CODIGO + '-' + item.FILTROCUADRECAJA_DESCRIPCION});
            }
            if (item.FILTROCUADRECAJA_TIPO === 'V') {
              this.vendedores.push({VEN_CODIGO: item.FILTROCUADRECAJA_CODIGO, VEN_NOMBRE: item.FILTROCUADRECAJA_DESCRIPCION,
                LABEL: item.FILTROCUADRECAJA_CODIGO + '-' + item.FILTROCUADRECAJA_DESCRIPCION});
            }
          }
        }
        // Obtener Detalle
        const res = await this.cajaservice.getDetalleCierreCajaProm(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO);
        this.cajaservice.camposCierreCaja = res;
        this.cargarDatos();
        this.cajaservice.camposCierreCaja.map((caja) => {
          caja.TRNCOBRO_FECHATRN = caja.TRNCOBRO_FECHATRN.toLocaleString().substr(0, 10);
          caja.TRNCOBRO_FECHATRN = this.datePipe.transform(caja.TRNCOBRO_FECHATRN,
            'dd/MM/yyyy');
          caja.DETCUADRECAJA_DEBITO = caja.DETCUADRECAJA_DEBITO.toFixed(2);
          caja.DETCUADRECAJA_CREDITO = caja.DETCUADRECAJA_CREDITO.toFixed(2);
          caja.NUMERO = this.cajaservice.camposCierreCaja.indexOf(caja) + 1;
          caja.SNBOOLEAN = true;
        });
        
        await this.getDocAsociados(this.cajaservice.camposCierreCaja);
        this.indice = 0;
        this.columnDefsCierre.map((res) => {
          if (res.headerName === 'S/N') {
            res.checkCierre = true;
          }
        });
        
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('info', 'Error', mensaje);
      }
      
    } else if (valor === 'Anterior') {
      this.btnBuscar = false;
      this.panelMov = false;
      if (this.btnUltimo === true && this.btnSiguiente === true) {
        this.btnUltimo = false;
        this.btnSiguiente = false;
      }
      this.btnRegresar = false;
      this.checkDes = true;
      this.opcionDropDown = 'FILTROCUADRECAJA_DESCRIPCION';
      if (this.indice === 0) {
        return;
      } else {
        this.indice -= 1;
        this.encabezadosCierreCajaSeleccionada = this.encabezadosCierreCaja[this.indice];
        this.date1 = new Date(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_FECHA);
        this.date2 = new Date(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_DESDE);
        this.date3 = new Date(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HASTA);
        this.horadesde = new Date(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_FECHA);
        if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO !== null) {
          if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO.toString().length !== 5) {
            this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO = '0' + this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO.toString();
          }
          this.horadesde.setHours(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO.substr(0, 2));
          this.horadesde.setMinutes(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO.substr(-1, 2));
        } else if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO === null) {
          this.horadesde = null;
        }
        this.horahasta = new Date(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_FECHA);
        if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN !== null) {
          if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN.toString().length !== 5) {
            this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN = '0' + this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN.toString();
          }
          this.horahasta.setHours(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN.substr(0, 2));
          this.horahasta.setMinutes(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN.substr(-1, 2));
        } else if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN === null) {
          this.horahasta = null;
        }
        
        try {
          // Obtener opciones
          const opcion = await this.cajaservice.getOpcionesProm(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO);
          this.cajas = [];
          this.origenes = [];
          this.vendedores = [];
          if (opcion.length > 0) {
            for (const item of opcion) {
              if (item.FILTROCUADRECAJA_TIPO === 'C') {
                this.cajas.push({CAJ_CODIGO: item.FILTROCUADRECAJA_CODIGO, CAJ_DESCRIPCION: item.FILTROCUADRECAJA_DESCRIPCION,
                  LABEL: item.FILTROCUADRECAJA_CODIGO + '-' + item.FILTROCUADRECAJA_DESCRIPCION});
              }
              if (item.FILTROCUADRECAJA_TIPO === 'O') {
                this.origenes.push({APLORG_CODIGO: item.FILTROCUADRECAJA_CODIGO, APLORG_NOMBRE: item.FILTROCUADRECAJA_DESCRIPCION,
                  LABEL: item.FILTROCUADRECAJA_CODIGO + '-' + item.FILTROCUADRECAJA_DESCRIPCION});
              }
              if (item.FILTROCUADRECAJA_TIPO === 'V') {
                this.vendedores.push({VEN_CODIGO: item.FILTROCUADRECAJA_CODIGO, VEN_NOMBRE: item.FILTROCUADRECAJA_DESCRIPCION,
                  LABEL: item.FILTROCUADRECAJA_CODIGO + '-' + item.FILTROCUADRECAJA_DESCRIPCION});
              }
            }
          }
          // Obtener Detalle
          const res = await this.cajaservice.getDetalleCierreCajaProm(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO);
          this.cajaservice.camposCierreCaja = res;
          this.cargarDatos();
          this.cajaservice.camposCierreCaja.map((caja) => {
            caja.TRNCOBRO_FECHATRN = caja.TRNCOBRO_FECHATRN.toLocaleString().substr(0, 10);
            caja.TRNCOBRO_FECHATRN = this.datePipe.transform(caja.TRNCOBRO_FECHATRN,
              'dd/MM/yyyy');
            caja.DETCUADRECAJA_DEBITO = caja.DETCUADRECAJA_DEBITO.toFixed(2);
            caja.DETCUADRECAJA_CREDITO = caja.DETCUADRECAJA_CREDITO.toFixed(2);
            caja.NUMERO = this.cajaservice.camposCierreCaja.indexOf(caja) + 1;
            caja.SNBOOLEAN = true;
          });
          
          await this.getDocAsociados(this.cajaservice.camposCierreCaja);
          this.columnDefsCierre.map((res) => {
            if (res.headerName === 'S/N') {
              res.checkCierre = true;
            }
          });
          
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('info', 'Error', mensaje);
        }
      }
      
    } else if (valor === 'Siguiente') {
      this.panelMov = false;
      this.btnBuscar = false;
      if (this.btnPrimero === true && this.btnAnterior === true) {
        this.btnPrimero = false;
        this.btnAnterior = false;
      }
      this.btnRegresar = false;
      this.checkDes = true;
      this.opcionDropDown = 'FILTROCUADRECAJA_DESCRIPCION';
      this.indice += 1;
      this.encabezadosCierreCajaSeleccionada = this.encabezadosCierreCaja[this.indice];
      this.date1 = new Date(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_FECHA);
      this.date2 = new Date(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_DESDE);
      this.date3 = new Date(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HASTA);
      this.horadesde = new Date(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_FECHA);
      if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO !== null) {
        if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO.toString().length !== 5) {
          this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO = '0' + this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO.toString();
        }
        this.horadesde.setHours(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO.substr(0, 2));
        this.horadesde.setMinutes(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO.substr(-1, 2));
      } else if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO === null) {
        this.horadesde = null;
      }
      this.horahasta = new Date(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_FECHA);
      if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN !== null) {
        if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN.toString().length !== 5) {
          this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN = '0' + this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN.toString();
        }
        this.horahasta.setHours(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN.substr(0, 2));
        this.horahasta.setMinutes(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN.substr(-1, 2));
      } else if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN === null) {
        this.horahasta = null;
      }
      
      try {
        // Obtener opciones
        const opcion = await this.cajaservice.getOpcionesProm(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO);
        this.cajas = [];
        this.origenes = [];
        this.vendedores = [];
        if (opcion.length > 0) {
          for (const item of opcion) {
            if (item.FILTROCUADRECAJA_TIPO === 'C') {
              this.cajas.push({CAJ_CODIGO: item.FILTROCUADRECAJA_CODIGO, CAJ_DESCRIPCION: item.FILTROCUADRECAJA_DESCRIPCION,
                LABEL: item.FILTROCUADRECAJA_CODIGO + '-' + item.FILTROCUADRECAJA_DESCRIPCION});
            }
            if (item.FILTROCUADRECAJA_TIPO === 'O') {
              this.origenes.push({APLORG_CODIGO: item.FILTROCUADRECAJA_CODIGO, APLORG_NOMBRE: item.FILTROCUADRECAJA_DESCRIPCION,
                LABEL: item.FILTROCUADRECAJA_CODIGO + '-' + item.FILTROCUADRECAJA_DESCRIPCION});
            }
            if (item.FILTROCUADRECAJA_TIPO === 'V') {
              this.vendedores.push({VEN_CODIGO: item.FILTROCUADRECAJA_CODIGO, VEN_NOMBRE: item.FILTROCUADRECAJA_DESCRIPCION,
                LABEL: item.FILTROCUADRECAJA_CODIGO + '-' + item.FILTROCUADRECAJA_DESCRIPCION});
            }
          }
        }
        // Obtener Detalle
        const res = await this.cajaservice.getDetalleCierreCajaProm(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO);
        this.cajaservice.camposCierreCaja = res;
        this.cargarDatos();
        this.cajaservice.camposCierreCaja.map((caja) => {
          caja.TRNCOBRO_FECHATRN = caja.TRNCOBRO_FECHATRN.toLocaleString().substr(0, 10);
          caja.TRNCOBRO_FECHATRN = this.datePipe.transform(caja.TRNCOBRO_FECHATRN,
            'dd/MM/yyyy');
          caja.DETCUADRECAJA_DEBITO = caja.DETCUADRECAJA_DEBITO.toFixed(2);
          caja.DETCUADRECAJA_CREDITO = caja.DETCUADRECAJA_CREDITO.toFixed(2);
          caja.NUMERO = this.cajaservice.camposCierreCaja.indexOf(caja) + 1;
          caja.SNBOOLEAN = true;
        });
        
        await this.getDocAsociados(this.cajaservice.camposCierreCaja);
        this.columnDefsCierre.map((res) => {
          if (res.headerName === 'S/N') {
            res.checkCierre = true;
          }
        });
        
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('info', 'Error', mensaje);
      }
      
    } else if (valor === 'Ultimo') {
      this.btnBuscar = false;
      this.panelMov = false;
      this.btnUltimo = true;
      this.btnSiguiente = true;
      this.btnPrimero = false;
      this.btnAnterior = false;
      this.btnRegresar = false;
      this.checkDes = true;
      this.opcionDropDown = 'FILTROCUADRECAJA_DESCRIPCION';
      this.indice = this.encabezadosCierreCaja.length - 1;
      this.encabezadosCierreCajaSeleccionada = this.encabezadosCierreCaja[this.indice];
      
      this.date1 = new Date(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_FECHA);
      this.date2 = new Date(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_DESDE);
      this.date3 = new Date(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HASTA);
      this.horadesde = new Date(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_FECHA);
      if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO !== null) {
        if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO.toString().length !== 5) {
          this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO = '0' + this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO.toString();
        }
        this.horadesde.setHours(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO.substr(0, 2));
        this.horadesde.setMinutes(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO.substr(-1, 2));
      } else if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAINICIO === null) {
        this.horadesde = null;
      }
      this.horahasta = new Date(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_FECHA);
      if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN !== null) {
        if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN.toString().length !== 5) {
          this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN = '0' + this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN.toString();
        }
        this.horahasta.setHours(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN.substr(0, 2));
        this.horahasta.setMinutes(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN.substr(-1, 2));
      } else if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HORAFIN === null) {
        this.horahasta = null;
      }
      
      try {
        // Obtener opciones
        const opcion = await this.cajaservice.getOpcionesProm(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO);
        this.cajas = [];
        this.origenes = [];
        this.vendedores = [];
        if (opcion.length > 0) {
          for (const item of opcion) {
            if (item.FILTROCUADRECAJA_TIPO === 'C') {
              this.cajas.push({CAJ_CODIGO: item.FILTROCUADRECAJA_CODIGO, CAJ_DESCRIPCION: item.FILTROCUADRECAJA_DESCRIPCION,
                LABEL: item.FILTROCUADRECAJA_CODIGO + '-' + item.FILTROCUADRECAJA_DESCRIPCION});
            }
            if (item.FILTROCUADRECAJA_TIPO === 'O') {
              this.origenes.push({APLORG_CODIGO: item.FILTROCUADRECAJA_CODIGO, APLORG_NOMBRE: item.FILTROCUADRECAJA_DESCRIPCION,
                LABEL: item.FILTROCUADRECAJA_CODIGO + '-' + item.FILTROCUADRECAJA_DESCRIPCION});
            }
            if (item.FILTROCUADRECAJA_TIPO === 'V') {
              this.vendedores.push({VEN_CODIGO: item.FILTROCUADRECAJA_CODIGO, VEN_NOMBRE: item.FILTROCUADRECAJA_DESCRIPCION,
                LABEL: item.FILTROCUADRECAJA_CODIGO + '-' + item.FILTROCUADRECAJA_DESCRIPCION});
            }
          }
        }
        // Obtener Detalle
        const res = await this.cajaservice.getDetalleCierreCajaProm(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO);
        this.cajaservice.camposCierreCaja = res;
        this.cargarDatos();
        this.cajaservice.camposCierreCaja.map((caja) => {
          caja.TRNCOBRO_FECHATRN = caja.TRNCOBRO_FECHATRN.toLocaleString().substr(0, 10);
          caja.TRNCOBRO_FECHATRN = this.datePipe.transform(caja.TRNCOBRO_FECHATRN,
            'dd/MM/yyyy');
          caja.DETCUADRECAJA_DEBITO = caja.DETCUADRECAJA_DEBITO.toFixed(2);
          caja.DETCUADRECAJA_CREDITO = caja.DETCUADRECAJA_CREDITO.toFixed(2);
          caja.NUMERO = this.cajaservice.camposCierreCaja.indexOf(caja) + 1;
          caja.SNBOOLEAN = true;
        });
        
        await this.getDocAsociados(this.cajaservice.camposCierreCaja);
        this.columnDefsCierre.map((res) => {
          if (res.headerName === 'S/N') {
            res.checkCierre = true;
          }
        });
        
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('info', 'Error', mensaje);
      }
      
    } else if (valor === 'Buscar') {
      this.checkDes = true;
      this.opcionDropDown = 'FILTROCUADRECAJA_DESCRIPCION';
      this.columnDefsCierre.map((res) => {
        if (res.headerName === 'S/N') {
          res.checkCierre = false;
        }
      });
      this.buscarCierresCaja();
    } else if (valor === 'Nuevo') {
      this.btnBuscar = true;
      this.inputDes = false;
      this.disabledCmb = false;
      this.horaDes = false;
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.btnRegresar = false;
      this.btnPrimero = true;
      this.btnSiguiente = true;
      this.btnAnterior = true;
      this.btnUltimo = true;
      this.columnDefsCierre.map((res) => {
        if (res.headerName === 'S/N') {
          res.checkCierre = false;
        }
      });
      this.encabezadosCierreCajaSeleccionada = {
        ENCCUADRECAJA_NUMERO: '', ENCCUADRECAJA_FECHA: '',
        ENCCUADRECAJA_DESDE: '', ENCCUADRECAJA_HASTA: '', COM_CODIGO: '01', ENCCUADRECAJA_HORAINICIO: '',
        ENCCUADRECAJA_HORAFIN: ''
      };
      this.date1 = new Date();
      this.date2 = new Date();
      this.date3 = new Date();
      this.cajas = [];
      this.origenes = [];
      this.vendedores = [];
      this.cajaservice.camposCierreCaja = [];
      this.cajaservice.movimientos = [];
      this.cajaservice.movimientosVarios = [];
      this.btnBuscarDes = false;
    } else if (valor === 'Cancelar') {
      this.btnBuscar = false;
      this.inputDes = true;
      this.disabledCmb = false;
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.btnBuscarDes = true;
      this.btnPrimero = false;
      this.btnAnterior = false;
      this.btnSiguiente = false;
      this.btnUltimo = false;
      this.encabezadosCierreCajaSeleccionada = {
        ENCCUADRECAJA_NUMERO: '', ENCCUADRECAJA_FECHA: '',
        ENCCUADRECAJA_DESDE: '', ENCCUADRECAJA_HASTA: '', COM_CODIGO: '01', ENCCUADRECAJA_HORAINICIO: '',
        ENCCUADRECAJA_HORAFIN: ''
      };
      this.date1 = new Date();
      this.date2 = new Date();
      this.date3 = new Date();
      this.cajas = [];
      this.origenes = [];
      this.vendedores = [];
      this.cajaservice.camposCierreCaja = [];
      this.btnBuscarDes = true;
    } else if (valor === 'Guardar') {
      this.guardarCierreCaja();
      
    } else if (valor === 'Borrar') {
      this.confirmarCierre();
      this.date1 = new Date();
      this.date2 = new Date();
      this.date3 = new Date();
    }
    
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
    
    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }
  }
  
  abrirCajas() {
    
    this.opcion = 'CAJAS';
    this.types = [
      {label: 'CÓDIGO', value: 'CAJ_CODIGO'},
      {label: 'DESCRIPCIÓN', value: 'CAJ_DESCRIPCION'}
    ];
    this.busquedaSer.busquedaBanMaeCaj().subscribe((res: any[]) => {
      this.arregloBusCaja = res;
      let indice = 0;
      if (this.arregloBusCaja.length !== 0) {
        if (this.cajas.length !== 0) {
          for (let i = 0; i < this.arregloBusCaja.length; i++) {
            for (let j = 0; j <= this.cajas.length - 1; j++) {
              if (this.arregloBusCaja[i].CAJ_CODIGO === this.cajas[j].CAJ_CODIGO) {
                indice = this.arregloBusCaja.indexOf(this.arregloBusCaja[i]);
                this.arregloBusCaja.splice(indice, 1);
              }
            }
          }
        }
      }
      this.displayDialogBus = true;
    });
  }
  
  abrirOrigenes() {
    this.opcion = 'ORIGENES';
    this.types = [
      {label: 'CÓDIGO', value: 'APLORG_CODIGO'},
      {label: 'NOMBRE', value: 'APLORG_NOMBRE'}
    ];
    this.busquedaSer.busquedaSaciMaeAplicacionorg().subscribe((res: any[]) => {
      this.arregloBusCaja = res;
      let indice = 0;
      if (this.arregloBusCaja.length !== 0) {
        if (this.origenes.length !== 0) {
          for (let i = 0; i < this.arregloBusCaja.length; i++) {
            for (let j = 0; j <= this.origenes.length - 1; j++) {
              if (this.arregloBusCaja[i].APLORG_CODIGO === this.origenes[j].APLORG_CODIGO) {
                indice = this.arregloBusCaja.indexOf(this.arregloBusCaja[i]);
                this.arregloBusCaja.splice(indice, 1);
              }
            }
          }
        }
      }
      this.displayDialogBus = true;
    });
  }
  
  abrirVendedores() {
    this.opcion = 'VENDEDORES';
    this.types = [
      {label: 'CÓDIGO', value: 'VEN_CODIGO'},
      {label: 'NOMBRE', value: 'VEN_NOMBRE'}
    ];
    this.busquedaSer.busquedaVenMaeVendedor().subscribe((res: any[]) => {
      this.arregloBusCaja = res;
      let indice = 0;
      if (this.arregloBusCaja.length !== 0) {
        if (this.vendedores.length !== 0) {
          for (let i = 0; i < this.arregloBusCaja.length; i++) {
            for (let j = 0; j <= this.vendedores.length - 1; j++) {
              if (this.arregloBusCaja[i].VEN_CODIGO === this.vendedores[j].VEN_CODIGO) {
                indice = this.arregloBusCaja.indexOf(this.arregloBusCaja[i]);
                this.arregloBusCaja.splice(indice, 1);
              }
            }
          }
        }
      }
      this.displayDialogBus = true;
    });
  }
  
  buscarCierresCaja() {
    this.opcion = 'BUSCAR';
    this.types = [
      {label: 'NÚMERO', value: 'ENCCUADRECAJA_NUMERO'},
      {label: 'FECHA DE CUADRE', value: 'ENCCUADRECAJA_FECHA'},
      {label: 'DESDE', value: 'ENCCUADRECAJA_DESDE'},
      {label: 'HASTA', value: 'ENCCUADRECAJA_HASTA'}
    ];
    this.busquedacampos = ['', '', '', ''];
    this.tabla = 'cxc_enccuadrecaja';
    this.where = '';
    this.busquedaSer.busquedaCXCEncCuadreCaja().subscribe((res: any[]) => {
      this.arregloBus = res;
      this.arregloBus.map((caja) => {
        caja.ENCCUADRECAJA_FECHA = this.datePipe.transform(caja.ENCCUADRECAJA_FECHA, 'dd/MM/yyyy');
        caja.ENCCUADRECAJA_DESDE = this.datePipe.transform(caja.ENCCUADRECAJA_DESDE, 'dd/MM/yyyy');
        caja.ENCCUADRECAJA_HASTA = this.datePipe.transform(caja.ENCCUADRECAJA_HASTA, 'dd/MM/yyyy');
      });
      this.displayDialogBusquedaFast = true;
      // this.displayDialogBus2 = true;
    });
  }
  
  async manejarSeleccion(valor) {
    if (this.opcion === 'BUSCAR') {
      
      this.inputDes = true;
      this.disabledCmb = false;
      this.checkDes = false;
      this.encabezadosCierreCajaSeleccionada = valor;
      this.date1 = this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_FECHA;
      this.date2 = this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_DESDE;
      this.date3 = this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HASTA;
      
      try {
        // Obtener opciones
        const opcion = await this.cajaservice.getOpcionesProm(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO);
        this.cajas = [];
        this.origenes = [];
        this.vendedores = [];
        if (opcion.length > 0) {
          for (const item of opcion) {
            if (item.FILTROCUADRECAJA_TIPO === 'C') {
              this.cajas.push({CAJ_CODIGO: item.FILTROCUADRECAJA_CODIGO, CAJ_DESCRIPCION: item.FILTROCUADRECAJA_DESCRIPCION,
                LABEL: item.FILTROCUADRECAJA_CODIGO + '-' + item.FILTROCUADRECAJA_DESCRIPCION});
            }
            if (item.FILTROCUADRECAJA_TIPO === 'O') {
              this.origenes.push({APLORG_CODIGO: item.FILTROCUADRECAJA_CODIGO, APLORG_NOMBRE: item.FILTROCUADRECAJA_DESCRIPCION,
                LABEL: item.FILTROCUADRECAJA_CODIGO + '-' + item.FILTROCUADRECAJA_DESCRIPCION});
            }
            if (item.FILTROCUADRECAJA_TIPO === 'V') {
              this.vendedores.push({VEN_CODIGO: item.FILTROCUADRECAJA_CODIGO, VEN_NOMBRE: item.FILTROCUADRECAJA_DESCRIPCION,
                LABEL: item.FILTROCUADRECAJA_CODIGO + '-' + item.FILTROCUADRECAJA_DESCRIPCION});
            }
          }
        }
        // Obtener Detalle
        const res = await this.cajaservice.getDetalleCierreCajaProm(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO);
        this.cajaservice.camposCierreCaja = res;
        this.cargarDatos();
        this.cajaservice.camposCierreCaja.map((caja) => {
          caja.TRNCOBRO_FECHATRN = caja.TRNCOBRO_FECHATRN.toLocaleString().substr(0, 10);
          caja.TRNCOBRO_FECHATRN = this.datePipe.transform(caja.TRNCOBRO_FECHATRN,
            'dd/MM/yyyy');
          caja.DETCUADRECAJA_DEBITO = caja.DETCUADRECAJA_DEBITO.toFixed(2);
          caja.DETCUADRECAJA_CREDITO = caja.DETCUADRECAJA_CREDITO.toFixed(2);
          caja.NUMERO = this.cajaservice.camposCierreCaja.indexOf(caja) + 1;
          caja.SNBOOLEAN = true;
        });
        
        await this.getDocAsociados(this.cajaservice.camposCierreCaja);
        this.displayDialogBusquedaFast = false;
        this.displayDialogBus2 = false;
        this.columnDefsCierre.map((res) => {
          if (res.headerName === 'S/N') {
            res.checkCierre = true;
          }
        });
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('info', 'Error', mensaje);
      }
      
    } else if (this.opcion === 'CAJAS') {
      this.opcionDropDown = 'CAJ_DESCRIPCION';
      let oldCajas: any[] = [];
      if (this.cajas.length > 0) {
        this.cajas.splice(this.cajas.length - 1, 1);
        for (const item of this.cajas) {
          oldCajas.push({CAJ_CODIGO: item.CAJ_CODIGO, CAJ_DESCRIPCION: item.CAJ_DESCRIPCION,
            LABEL: item.CAJ_CODIGO + '-' + item.CAJ_DESCRIPCION});
        }
      }
      // oldCajas = this.cajas;
      if (valor.length > 0){
        for (const item of valor) {
          oldCajas.push({CAJ_CODIGO: item.CAJ_CODIGO, CAJ_DESCRIPCION: item.CAJ_DESCRIPCION,
            LABEL: item.CAJ_CODIGO + '-' + item.CAJ_DESCRIPCION});
        }
      }
      
      this.cajas = oldCajas;
      // this.cajas = valor;
      let i = 0;
      if (this.cajas.length !== 0) {
        this.consultaCaj = '(CAJ_CODIGO = ';
        do {
          if (this.cajas[i].CAJ_CODIGO !== null) {
            this.consultaCaj += ' \'' + this.cajas[i].CAJ_CODIGO + '\' ' + ' OR CAJ_CODIGO = ';
          }
          i++;
        } while (i < this.cajas.length);
        this.consultaCaj = this.consultaCaj.slice(0, -17);
        this.consultaCaj += ')';
      } else if (this.cajas.length === 0) {
        this.consultaCaj = '';
      }
      this.cajas.push({CAJ_DESCRIPCION: 'Quitar todo', LABEL:'Quitar todo'});
      this.opcSelectCaj = this.cajas[0];
      this.consulta.cuadreCajaNum = this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO;
      if (this.consultaCaj !== '' && this.consultaOri !== '' && this.consultaVen !== '') {
        this.consulta.filtro = this.consultaCaj + ' AND ' + this.consultaOri + ' AND ' + this.consultaVen;
      } else if (this.consultaOri !== '' && this.consultaCaj !== '' && this.consultaVen === '') {
        this.consulta.filtro = this.consultaCaj + ' AND ' + this.consultaOri;
      } else if (this.consultaCaj !== '' && this.consultaOri === '' && this.consultaVen !== '') {
        this.consulta.filtro = this.consultaOri + ' AND ' + this.consultaVen;
      } else if (this.consultaCaj !== '' && this.consultaOri === '' && this.consultaVen === '') {
        this.consulta.filtro = this.consultaCaj;
      }
      
      try {
        const res = await this.cajaservice.consultarItemsBan_trnpago(this.consulta, this.date2.toLocaleString('en-GB').split(','),
          this.date3.toLocaleString('en-GB').split(','));
        this.cajaservice.camposCierreCaja = res;
        this.cargarDatos();
        if (this.cajaservice.camposCierreCaja !== null) {
          this.cajaservice.camposCierreCaja.map((campos) => {
            campos.TRNCOBRO_FECHATRN = this.datePipe.transform(campos.TRN_FECHA.toLocaleString().substr(0, 10),
              'dd/MM/yyyy');
            campos.TRNCOBRO_NROCOMPROBANTE = campos.TRN_NROCOMPROBANTE;
            campos.DETCUADRECAJA_DEBITO = campos.DEBITOS.toFixed(2);
            campos.DETCUADRECAJA_CREDITO = campos.CREDITOS.toFixed(2);
            campos.NUMERO = this.cajaservice.camposCierreCaja.indexOf(campos) + 1;
            campos.SNBOOLEAN = false;
          });
          await this.getDocAsociados(this.cajaservice.camposCierreCaja);
          this.checkDes = true;
        }
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('info', 'Error', mensaje);
      }
      
      this.displayDialogBus = false;
      this.panelMov = false;
    } else if (this.opcion === 'ORIGENES') {
      this.opcionDropDown = 'APLORG_NOMBRE';
      
      let oldOrigen: any[] = [];
      if (this.origenes.length > 0) {
        this.origenes.splice(this.origenes.length - 1, 1);
        for (const item of this.origenes) {
          oldOrigen.push({APLORG_CODIGO: item.APLORG_CODIGO, APLORG_NOMBRE: item.APLORG_NOMBRE,
            LABEL: item.APLORG_CODIGO + '-' + item.APLORG_NOMBRE});
        }
      }
      if (valor.length > 0){
        for (const item of valor) {
          oldOrigen.push({APLORG_CODIGO: item.APLORG_CODIGO, APLORG_NOMBRE: item.APLORG_NOMBRE,
            LABEL: item.APLORG_CODIGO + '-' + item.APLORG_NOMBRE});
        }
      }
      
      this.origenes = oldOrigen;
      
      let j = 0;
      if (this.origenes.length !== 0) {
        this.consultaOri = '(APLORG_CODIGO = ';
        do {
          if (this.origenes[j].APLORG_CODIGO !== null) {
            this.consultaOri += ' \'' + this.origenes[j].APLORG_CODIGO + '\' ' + ' OR APLORG_CODIGO = ';
          }
          j++;
        } while (j < this.origenes.length);
        this.consultaOri = this.consultaOri.slice(0, -20);
        this.consultaOri += ')';
      } else if (this.origenes.length === 0) {
        this.consultaOri = '';
      }
      this.origenes.push({APLORG_NOMBRE: 'Quitar todo', LABEL:'Quitar todo'});
      this.opcSelectOri = this.origenes[0];
      this.consulta.cuadreCajaNum = this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO;
      if (this.consultaCaj !== '' && this.consultaOri !== '' && this.consultaVen !== '') {
        this.consulta.filtro = this.consultaCaj + ' AND ' + this.consultaOri + ' AND ' + this.consultaVen;
      } else if (this.consultaOri !== '' && this.consultaCaj !== '' && this.consultaVen === '') {
        this.consulta.filtro = this.consultaCaj + ' AND ' + this.consultaOri;
      } else if (this.consultaOri !== '' && this.consultaCaj === '' && this.consultaVen !== '') {
        this.consulta.filtro = this.consultaOri + ' AND ' + this.consultaVen;
      } else if (this.consultaOri !== '' && this.consultaCaj === '' && this.consultaVen === '') {
        this.consulta.filtro = this.consultaOri;
      }
      try {
        const res = await this.cajaservice.consultarItemsBan_trnpago(this.consulta, this.date2.toLocaleString('en-GB').split(','),
          this.date3.toLocaleString('en-GB').split(','));
        this.cajaservice.camposCierreCaja = res;
        this.cargarDatos();
        if (this.cajaservice.camposCierreCaja !== null) {
          this.cajaservice.camposCierreCaja.map((campos) => {
            campos.TRNCOBRO_FECHATRN = this.datePipe.transform(campos.TRN_FECHA.toLocaleString().substr(0, 10),
              'dd/MM/yyyy');
            campos.TRNCOBRO_NROCOMPROBANTE = campos.TRN_NROCOMPROBANTE;
            campos.DETCUADRECAJA_DEBITO = campos.DEBITOS.toFixed(2);
            campos.DETCUADRECAJA_CREDITO = campos.CREDITOS.toFixed(2);
            campos.NUMERO = this.cajaservice.camposCierreCaja.indexOf(campos) + 1;
            campos.SNBOOLEAN = false;
          });
          await this.getDocAsociados(this.cajaservice.camposCierreCaja);
          this.checkDes = false;
        }
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('info', 'Error', mensaje);
      }
      this.displayDialogBus = false;
    } else if (this.opcion === 'VENDEDORES') {
      this.opcionDropDown = 'VEN_NOMBRE';
      
      let oldVendedores: any[] = [];
      if (this.vendedores.length > 0) {
        this.vendedores.splice(this.vendedores.length - 1, 1);
        for (const item of this.vendedores) {
          oldVendedores.push({VEN_CODIGO: item.VEN_CODIGO, VEN_NOMBRE: item.VEN_NOMBRE,
            LABEL: item.VEN_CODIGO + '-' + item.VEN_NOMBRE});
        }
      }
      if (valor.length > 0){
        for (const item of valor) {
          oldVendedores.push({VEN_CODIGO: item.VEN_CODIGO, VEN_NOMBRE: item.VEN_NOMBRE,
            LABEL: item.VEN_CODIGO + '-' + item.VEN_NOMBRE});
        }
      }
      
      this.vendedores = oldVendedores;
      let k = 0;
      if (this.vendedores.length !== 0) {
        this.consultaVen = '(VEN_CODIGO = ';
        do {
          if (this.vendedores[k].VEN_CODIGO !== null) {
            this.consultaVen += ' \'' + this.vendedores[k].VEN_CODIGO + '\' ' + ' OR VEN_CODIGO = ';
          }
          k++;
        } while (k < this.vendedores.length);
        this.consultaVen = this.consultaVen.slice(0, -17);
        this.consultaVen += ')';
      } else if (this.vendedores.length === 0) {
        this.consultaVen = '';
      }
      this.vendedores.push({VEN_NOMBRE: 'Quitar todo', LABEL:'Quitar todo'});
      this.opcSelectVen = this.vendedores[0];
      this.consulta.cuadreCajaNum = this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO;
      if (this.consultaCaj !== '' && this.consultaOri !== '' && this.consultaVen !== '') {
        this.consulta.filtro = this.consultaCaj + ' AND ' + this.consultaOri + ' AND ' + this.consultaVen;
      } else if (this.consultaOri !== '' && this.consultaCaj !== '' && this.consultaVen === '') {
        this.consulta.filtro = this.consultaCaj + ' AND ' + this.consultaOri;
      } else if (this.consultaVen !== '' && this.consultaCaj === '' && this.consultaOri !== '') {
        this.consulta.filtro = this.consultaOri + ' AND ' + this.consultaVen;
      } else if (this.consultaVen !== '' && this.consultaCaj === '' && this.consultaOri === '') {
        this.consulta.filtro = this.consultaVen;
      }
      try {
        const res = await this.cajaservice.consultarItemsBan_trnpago(this.consulta, this.date2.toLocaleString('en-GB').split(','),
          this.date3.toLocaleString('en-GB').split(','));
        this.cajaservice.camposCierreCaja = res;
        this.cargarDatos();
        if (this.cajaservice.camposCierreCaja !== null) {
          this.cajaservice.camposCierreCaja.map((campos) => {
            campos.TRNCOBRO_FECHATRN = this.datePipe.transform(campos.TRN_FECHA.toLocaleString().substr(0, 10),
              'dd/MM/yyyy');
            campos.TRNCOBRO_NROCOMPROBANTE = campos.TRN_NROCOMPROBANTE;
            campos.DETCUADRECAJA_DEBITO = campos.DEBITOS.toFixed(2);
            campos.DETCUADRECAJA_CREDITO = campos.CREDITOS.toFixed(2);
            campos.NUMERO = this.cajaservice.camposCierreCaja.indexOf(campos) + 1;
            campos.SNBOOLEAN = false;
          });
          await this.getDocAsociados(this.cajaservice.camposCierreCaja);
          this.checkDes = false;
        }
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('info', 'Error', mensaje);
      }
      this.displayDialogBus = false;
    }
  }
  
  async llenarTabla() {
    if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO === '') {
      this.consulta.cuadreCajaNum = this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO;
      if (this.consultaCaj !== '' && this.consultaOri !== '' && this.consultaVen !== '') {
        this.consulta.filtro = this.consultaCaj + ' AND ' + this.consultaOri + ' AND ' + this.consultaVen;
      } else if (this.consultaOri !== '' && this.consultaCaj !== '' && this.consultaVen === '') {
        this.consulta.filtro = this.consultaCaj + ' AND ' + this.consultaOri;
      } else if (this.consultaVen !== '' && this.consultaCaj === '' && this.consultaOri !== '') {
        this.consulta.filtro = this.consultaOri + ' AND ' + this.consultaVen;
      } else if (this.consultaVen !== '' && this.consultaCaj === '' && this.consultaOri === '') {
        this.consulta.filtro = this.consultaVen;
      } else if (this.consultaCaj === '' && this.consultaOri === '' && this.consultaVen === '') {
        this.consulta.filtro = '';
      } else if (this.consultaCaj === '' && this.consultaOri !== '' && this.consultaVen !== '') {
        this.consulta.filtro = this.consultaOri + ' AND ' + this.consultaVen;
      } else if (this.consultaCaj === '' && this.consultaOri === '' && this.consultaVen !== '') {
        this.consulta.filtro = this.consultaVen;
      } else if (this.consultaCaj === '' && this.consultaOri !== '' && this.consultaVen === '') {
        this.consulta.filtro = this.consultaOri;
      }
      
      try {
        const res = await this.cajaservice.consultarItemsBan_trnpago(this.consulta, this.date2.toLocaleString('en-GB').split(','),
          this.date3.toLocaleString('en-GB').split(','));
        this.cajaservice.camposCierreCaja = res;
        this.cargarDatos();
        if (this.cajaservice.camposCierreCaja !== null) {
          this.panelMov = false;
          this.cajaservice.camposCierreCaja.map((campos) => {
            campos.TRNCOBRO_FECHATRN = this.datePipe.transform(campos.TRN_FECHA.toLocaleString().substr(0, 10),
              'dd/MM/yyyy');
            campos.TRNCOBRO_NROCOMPROBANTE = campos.TRN_NROCOMPROBANTE;
            campos.DETCUADRECAJA_DEBITO = campos.DEBITOS.toFixed(2);
            campos.DETCUADRECAJA_CREDITO = campos.CREDITOS.toFixed(2);
            campos.NUMERO = this.cajaservice.camposCierreCaja.indexOf(campos) + 1;
            campos.SNBOOLEAN = false;
          });
          await this.getDocAsociados(this.cajaservice.camposCierreCaja);
          this.checkDes = false;
        }
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('info', 'Error', mensaje);
      }
      
      
    }
    
  }
  
  async getDocAsociados(filas) {
    const data: CamposCierreCaja [] = filas;
    let codigos = '';
    
    if (data.length > 0) {
      
      for (const fila of data) {
        codigos = '\'' + fila.TRNCOBRO_NROCOMPROBANTE + '\'' + ',' + codigos;
      }
    }
    if (codigos.length > 0) {
      codigos = '(' + codigos;
      codigos = codigos.substring(0, codigos.length - 1);
      codigos = codigos + ')';
    }
    
    // const sentencia = ' select TRNCOBRO_NRODOC,trndocumento_nrodoc   from  cxc_trndocumento  where  trncobro_nrodoc IN '
    //  + codigos + ' AND COM_CODIGO=\'01\'';
    
    if(codigos.length > 0) {
      try {
        const rs = await this.cajaservice.obtenerTrnDocumentos(codigos);
        if (rs !== null) {
          const dataDocumentos: any [] = [];
          for (const doc of rs) {
            const dataDocumento = {TRNCOBRO_NRODOC: '', TRNDOCUMENTO_NRODOC: ''};
            dataDocumento.TRNCOBRO_NRODOC = String(doc.TRNCOBRO_NRODOC).trim();
            dataDocumento.TRNDOCUMENTO_NRODOC = String(doc.TRNDOCUMENTO_NRODOC).trim();
            dataDocumentos.push(dataDocumento);
          }
          
          if (data.length > 0){
            this.cajaservice.camposCierreCaja.map(fila => {
              let strdocumentos = '';
              for(const documento of dataDocumentos){
                if(fila.TRNCOBRO_NROCOMPROBANTE === documento.TRNCOBRO_NRODOC){
                  strdocumentos = documento.TRNDOCUMENTO_NRODOC + ' - ' + strdocumentos;
                }
              }
              if(String(strdocumentos).trim().length > 0){
                strdocumentos= strdocumentos.substring(0, String(strdocumentos).trim().length - 1);
              }
              fila.TRNDOCUMENTO_NRODOC = strdocumentos;
            });
            // this.aggrid.refreshColumnDefs();
          }
        }
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('info', 'Error', mensaje);
        return false;
      }
    }
  }
  
  selectAll() {
    let i = 0;
    
    if (this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO === '') {
      if (this.desSelect === true) {
        do {
          this.cajaservice.camposCierreCaja[i].SNBOOLEAN = true;
          i++;
        } while (i < this.cajaservice.camposCierreCaja.length);
        this.aggrid.refreshaggrid(this.cajaservice.camposCierreCaja, this.defaultColDefCierre.objeto);
      } else if (this.desSelect === false) {
        do {
          this.cajaservice.camposCierreCaja[i].SNBOOLEAN = false;
          i++;
        } while (i < this.cajaservice.camposCierreCaja.length);
        this.aggrid.refreshaggrid(this.cajaservice.camposCierreCaja, this.defaultColDefCierre.objeto);
      }
    }
  }
  
  cargarDatos() {
    this.spin = true;
    if (this.cajaservice.camposCierreCaja !== null) {
      if (this.cajaservice.camposCierreCaja.length >= 200) {
        setTimeout(() => {
          this.spin = false;
        }, 200);
      } else if (this.cajaservice.camposCierreCaja.length >= 100 && this.cajaservice.camposCierreCaja.length < 200) {
        setTimeout(() => {
          this.spin = false;
        }, 200);
      } else if (this.cajaservice.camposCierreCaja.length < 100 && this.cajaservice.camposCierreCaja.length > 50) {
        setTimeout(() => {
          this.spin = false;
        }, 200);
      } else if (this.cajaservice.camposCierreCaja.length < 50 && this.cajaservice.camposCierreCaja.length > 25) {
        setTimeout(() => {
          this.spin = false;
        }, 200);
      } else if (this.cajaservice.camposCierreCaja.length <= 25) {
        setTimeout(() => {
          this.spin = false;
        }, 200);
      } else if (this.cajaservice.camposCierreCaja.length === 0) {
        this.spin = false;
      }
    } else {
      this.spin = false;
    }
  }
  
  cerrarDialogo(valor) {
    if (valor === true) {
      this.displayDialogBus = false;
    }
  }
  
  cambio() {
    this.btnGuardar = false;
    this.btnRegresar = false;
    this.btnUltimo = true;
    this.btnAnterior = true;
    this.btnSiguiente = true;
    this.btnPrimero = true;
  }
  
  async guardarCierreCaja() {
    let aux = 0;
    for (let l = 0; l < this.cajaservice.camposCierreCaja.length; l++) {
      if (this.cajaservice.camposCierreCaja[l].SNBOOLEAN === true) {
        aux++;
      }
    }
    if (aux === 0) {
      this.mensajeAlerta('error', 'Error', 'No selecionó ninguno campo en la tabla detalle');
      return;
    } else {
      if (await this.guardarConciliacion()) {
        this.mensajeAlerta('success', 'Información', 'Registro guardado exitosamente... !!!');
        try {
          const opcion = await this.cajaservice.getOpcionesProm(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO);
          this.cajas = [];
          this.origenes = [];
          this.vendedores = [];
          if (opcion.length > 0) {
            for (const item of opcion) {
              if (item.FILTROCUADRECAJA_TIPO === 'C') {
                this.cajas.push({CAJ_CODIGO: item.FILTROCUADRECAJA_CODIGO, CAJ_DESCRIPCION: item.FILTROCUADRECAJA_DESCRIPCION,
                  LABEL: item.FILTROCUADRECAJA_CODIGO + '-' + item.FILTROCUADRECAJA_DESCRIPCION});
              }
              if (item.FILTROCUADRECAJA_TIPO === 'O') {
                this.origenes.push({APLORG_CODIGO: item.FILTROCUADRECAJA_CODIGO, APLORG_NOMBRE: item.FILTROCUADRECAJA_DESCRIPCION,
                  LABEL: item.FILTROCUADRECAJA_CODIGO + '-' + item.FILTROCUADRECAJA_DESCRIPCION});
              }
              if (item.FILTROCUADRECAJA_TIPO === 'V') {
                this.vendedores.push({VEN_CODIGO: item.FILTROCUADRECAJA_CODIGO, VEN_NOMBRE: item.FILTROCUADRECAJA_DESCRIPCION,
                  LABEL: item.FILTROCUADRECAJA_CODIGO + '-' + item.FILTROCUADRECAJA_DESCRIPCION});
              }
            }
          }
          
          const res = await this.cajaservice.getDetalleCierreCajaProm(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO);
          this.cajaservice.camposCierreCaja = res;
          this.cargarDatos();
          this.cajaservice.camposCierreCaja.map((caja) => {
            caja.TRNCOBRO_FECHATRN = caja.TRNCOBRO_FECHATRN.toLocaleString().substr(0, 10);
            caja.TRNCOBRO_FECHATRN = this.datePipe.transform(caja.TRNCOBRO_FECHATRN,
              'dd/MM/yyyy');
            caja.DETCUADRECAJA_DEBITO = caja.DETCUADRECAJA_DEBITO.toFixed(2);
            caja.DETCUADRECAJA_CREDITO = caja.DETCUADRECAJA_CREDITO.toFixed(2);
            caja.NUMERO = this.cajaservice.camposCierreCaja.indexOf(caja) + 1;
            caja.SNBOOLEAN = true;
          });
          await this.getDocAsociados(this.cajaservice.camposCierreCaja);
          this.displayDialogBusquedaFast = false;
          this.displayDialogBus2 = false;
          
          this.columnDefsCierre.map((res) => {
            if (res.headerName === 'S/N') {
              res.checkCierre = true;
            }
          });
          
          this.inputDes = true;
          this.disabledCmb = false;
          this.checkDes = false;
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnAnterior = false;
          this.btnPrimero = false;
          this.btnSiguiente = false;
          this.btnUltimo = false;
          this.btnBuscar = false;
          this.cajaservice.getEncabezadosCierreCaja().subscribe((res) => {
            this.encabezadosCierreCaja = res;
          });
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('info', 'Error', mensaje);
        }
      }
    }
    
    
  }
  
  async guardarConciliacion() {
    if (this.permisos != null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERINSERCION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de inserción. Consulte con el Administrador del Sistema.');
          return false;
        }
      }
    }
    const numero = await this.insertarActualizaCierreCaja();
    this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO = numero;
    
    await this.guardarFiltros(numero);
    if (numero === null) {
      this.mensajeAlerta('info', 'Información', 'No se a podido guardar el cuadre de caja correctamente.');
      return false;
    } else {
      if (!await this.guardarDetalle(numero)) {
        this.mensajeAlerta('error', 'Error', 'Una o más líneas de detalle no se pudieron guardar correctamente, ' +
          'por favor revise los datos');
        return false;
      } else {
        this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO = numero;
      }
    }
    return true;
  }
  
  async guardarDetalle (encnumero) {
    let state = true;
    
    for (let i = 0; i < this.cajaservice.camposCierreCaja.length; i++) {
      const detalleTrnPago: CamposCierreCaja = this.cajaservice.camposCierreCaja[i];
      
      if (detalleTrnPago.SNBOOLEAN === true) {
        if (!this.insertarDetCuaCaja(encnumero, detalleTrnPago)) {
          state = false;
        }
      } else {
        // this.eliminarDetConciliacion(encnumero, detalleTrnPago);
      }
    }
    return state;
  }
  
  async eliminarDetConciliacion(numero, detalle) {
    try {
      const rs = await this.cajaservice.eliminarDetConciliacion(numero, detalle);
      this.auditoriagral.registrarAuditoria('CXC_DETCUADRECAJA', numero, 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
      return false;
    }
    
    return true;
  }
  
  async insertarDetCuaCaja(numCuadreCaja, detalle) {
    try {
      
      const rs = await this.cajaservice.insertarDetCuaCaja(numCuadreCaja, detalle);
      this.auditoriagral.registrarAuditoria('CXC_DETCUADRECAJA', numCuadreCaja, 'I',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
      return false;
    }
    
    return true;
  }
  
  async guardarFiltros(encCuadCajNumero) {
    let state = true;
    
    // Para el ingreso de los filtros de el cirre de caja items de caja
    // (C)
    for (let i = 0; i < this.cajas.length - 1; i++) {
      if (! await this.insertarFiltro(encCuadCajNumero, 'C', this.cajas[i].CAJ_CODIGO, this.cajas[i].CAJ_DESCRIPCION)) {
        state = false;
      }
    }
    // //guardo filtros de origen (O)
    for (let i = 0; i < this.origenes.length - 1; i++) {
      if (! await this.insertarFiltro(encCuadCajNumero, 'O', this.origenes[i].APLORG_CODIGO, this.origenes[i].APLORG_NOMBRE)) {
        state = false;
      }
    }
    
    // para insertar los fitros de vendedores para el cirre de caja (V)
    for (let i = 0; i < this.vendedores.length - 1; i++) {
      if (! await this.insertarFiltro(encCuadCajNumero, 'V', this.vendedores[i].VEN_CODIGO, this.vendedores[i].VEN_NOMBRE)) {
        state = false;
      }
    }
    
    // / para insertar los fitros de vendedores para el cirre de caja (V)
    return state;
  }
  
  async insertarFiltro(encCierreCajaNum, filTipo, filCodigo, filDescrip) {
    try {
      
      const rs = await this.cajaservice.insertarFiltro(encCierreCajaNum, filTipo, filCodigo, filDescrip);
      this.auditoriagral.registrarAuditoria('CXC_FILTROCUADRECAJA', encCierreCajaNum, 'I',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
      return false;
    }
    
    return true;
  }
  
  async insertarActualizaCierreCaja() {
    let value = '';
    try {
      this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_FECHA = this.date1.toLocaleString('en-GB').split(',');
      this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_DESDE = this.date2.toLocaleString('en-GB').split(',');
      this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_HASTA = this.date3.toLocaleString('en-GB').split(',');
      const rs = await this.cajaservice.insertarActualizaCierreCaja(this.encabezadosCierreCajaSeleccionada);
      value = rs === null ? '' : rs[0].ENCCUADRECAJA_NUMERO;
      this.auditoriagral.registrarAuditoria('CXC_ENCCUADRECAJA', value, 'I',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
      return value;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
      return null;
    }
    
  }
  
  
  async eliminarCaudreCaja() {
    const numero = this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO;
    if (await this.actulizaTrnPago()) {
      if (await this.eliminarCabecera()) {
        this.cajas = [];
        this.origenes = [];
        this.vendedores = [];
        this.mensajeAlerta('success', 'Eliminación', 'Cuadre de caja eliminado satisfactoriamente.... !!!');
        this.cajaservice.getEncabezadosCierreCaja().subscribe((res2) => {
          this.encabezadosCierreCaja = res2;
        });
      }
    }
  }
  
  async eliminarCabecera() {
    if (! await this.eliminarCierreCaja()) {
      this.mensajeAlerta('error', 'Error', 'No se pudo eliminar el registro actual indicado');
      return false;
    } else {
      this.encabezadosCierreCajaSeleccionada = {
        ENCCUADRECAJA_NUMERO: '', ENCCUADRECAJA_FECHA: '',
        ENCCUADRECAJA_DESDE: '', ENCCUADRECAJA_HASTA: '', COM_CODIGO: '01', ENCCUADRECAJA_HORAINICIO: '',
        ENCCUADRECAJA_HORAFIN: ''
      };
      this.cajaservice.camposCierreCaja = [];
      this.date1 = new Date();
      this.date2 = new Date();
      this.date3 = new Date();
    }
    return true;
  }
  
  async eliminarCierreCaja() {
    try {
      await this.cajaservice.eliminarCierreCaja(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO);
      if(String(this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO).trim().length > 0) {
        this.auditoriagral.registrarAuditoria('CXC_ENCCUADRECAJA',this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO,
          'E', this.usuario.identificacion, '01', '', '', '',
          '').subscribe(() => {
        });
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
      return false;
    }
    return true;
  }
  
  async actulizaTrnPago() {
    let state = true;
    
    for (let i = 0; i < this.cajaservice.camposCierreCaja.length; i++) {
      const actual: CamposCierreCaja = this.cajaservice.camposCierreCaja[i];
      
      if (! await this.actualizatrnpago(actual.TRNCOBRO_NROCOMPROBANTE))
        state = false;
    }
    return state;
  }
  
  async actualizatrnpago(trnNumero) {
    try {
      //const sql  = 'update  CXC_TRNCOBRO set  trncobro_cuadre=\'0\' where  com_codigo=\'01\' and trncobro_nrocomprobante=\'' + trnNumero + '\'';
      await this.cajaservice.actualizarTrnPago(trnNumero);
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
      return false;
    }
    return true;
  }
  
  confirmarCierre() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el Cierre de Caja',
      header: 'Eliminar Cierre de Caja',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarCierre',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarCaudreCaja();
      },
      reject: () => {
      }
    });
  }
  
  abrirDialogoSuma() {
    let sumEfectivo = 0; /*EF*/
    let sumCheque = 0;  /*CH*/
    let sumDepositos = 0; /*DP*/
    let sumRetenciones = 0; /*RT*/
    let sumaTarjetas = 0; /*TA*/
    let sumaBandejaCobros = 0; /*BAND*/
    let sumaCanjes = 0; /*CANJ*/
    let sumaAnticipos = 0; /*ANCL*/
    let sumaMovimientos = 0; /*MOV*/
    let sumaTotales: number;
    this.sumatoria = [];
    this.cajaservice.camposCierreCaja.map((cuadre) => {
      if (cuadre.SNBOOLEAN === true) {
        if (cuadre.FORMAPAGO_TIPO === 'EF') {
          sumEfectivo += Number(cuadre.DETCUADRECAJA_DEBITO);
        } else if (cuadre.FORMAPAGO_TIPO === 'CH') {
          sumCheque += Number(cuadre.DETCUADRECAJA_DEBITO);
        } else if (cuadre.FORMAPAGO_TIPO === 'DP') {
          sumDepositos += Number(cuadre.DETCUADRECAJA_DEBITO);
        } else if (cuadre.FORMAPAGO_TIPO === 'RT') {
          sumRetenciones += Number(cuadre.DETCUADRECAJA_DEBITO);
        } else if (cuadre.FORMAPAGO_TIPO === 'TA') {
          sumaTarjetas += Number(cuadre.DETCUADRECAJA_DEBITO);
        } else if (cuadre.FORMAPAGO_TIPO === 'BAND') {
          sumaBandejaCobros += Number(cuadre.DETCUADRECAJA_DEBITO);
        } else if (cuadre.FORMAPAGO_TIPO === 'CANJ') {
          sumaCanjes += Number(cuadre.DETCUADRECAJA_DEBITO);
        } else if (cuadre.FORMAPAGO_TIPO === 'ANCL') {
          sumaAnticipos += Number(cuadre.DETCUADRECAJA_DEBITO);
        }
      }
    });
    this.cajaservice.movimientos.map((movimientos) => {
      sumaMovimientos += Number(movimientos.TRNCAJ_IMPORTE);
      this.sumaTotalMov = sumaMovimientos;
      this.sumaTotalMov = Number(this.sumaTotalMov.toFixed(2));
    });
    if (sumEfectivo !== 0) {
      this.sumatoria.push({TipoPago: 'EF', Total: this.init.moneyValidation(sumEfectivo.toFixed(2))});
    }
    if (sumCheque !== 0) {
      this.sumatoria.push({TipoPago: 'CH', Total: this.init.moneyValidation(sumCheque.toFixed(2))});
    }
    if (sumDepositos !== 0) {
      this.sumatoria.push({TipoPago: 'DP', Total: this.init.moneyValidation(sumDepositos.toFixed(2))});
    }
    if (sumRetenciones !== 0) {
      this.sumatoria.push({TipoPago: 'RT', Total: this.init.moneyValidation(sumRetenciones.toFixed(2))});
    }
    if (sumaTarjetas !== 0) {
      this.sumatoria.push({TipoPago: 'TA', Total: this.init.moneyValidation(sumaTarjetas.toFixed(2))});
    }
    if (sumaBandejaCobros !== 0) {
      this.sumatoria.push({TipoPago: 'BAND', Total: this.init.moneyValidation(sumaBandejaCobros.toFixed(2))});
    }
    if (sumaCanjes !== 0) {
      this.sumatoria.push({TipoPago: 'CANJ', Total: this.init.moneyValidation(sumaCanjes.toFixed(2))});
    }
    if (sumaAnticipos !== 0) {
      this.sumatoria.push({TipoPago: 'ANCL', Total: this.init.moneyValidation(sumaAnticipos.toFixed(2))});
    }
    if (sumaMovimientos !== 0) {
      this.sumatoria.push({TipoPago: 'Movimientos', Total: this.init.moneyValidation(sumaMovimientos.toFixed(2))});
    }
    sumaTotales = sumEfectivo + sumCheque + sumDepositos + sumRetenciones + sumaTarjetas + sumaBandejaCobros +
      sumaCanjes + sumaAnticipos - sumaMovimientos;
    this.total = this.init.moneyValidation(sumaTotales.toFixed(2));
    
    this.displayDialogSuma = true;
  }
  
  async cambioDropCaj() {
    if (this.opcSelectCaj.CAJ_DESCRIPCION === 'Quitar todo' && this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO === '') {
      this.cajas = [];
      this.consultaCaj = '';
      this.consulta.cuadreCajaNum = this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO;
      if (this.consultaCaj !== '' && this.consultaOri !== '' && this.consultaVen !== '') {
        this.consulta.filtro = this.consultaCaj + ' AND ' + this.consultaOri + ' AND ' + this.consultaVen;
      } else if (this.consultaOri !== '' && this.consultaCaj !== '' && this.consultaVen === '') {
        this.consulta.filtro = this.consultaCaj + ' AND ' + this.consultaOri;
      } else if (this.consultaCaj !== '' && this.consultaOri === '' && this.consultaVen !== '') {
        this.consulta.filtro = this.consultaOri + ' AND ' + this.consultaVen;
      } else if (this.consultaCaj !== '' && this.consultaOri === '' && this.consultaVen === '') {
        this.consulta.filtro = this.consultaOri;
      } else if (this.consultaCaj === '' && this.consultaOri === '' && this.consultaVen === '') {
        this.consultaCaj = '';
      }
      
      try {
        const res = await this.cajaservice.consultarItemsBan_trnpago(this.consulta, this.date2.toLocaleString('en-GB').split(','),
          this.date3.toLocaleString('en-GB').split(','));
        this.cajaservice.camposCierreCaja = res;
        this.cargarDatos();
        if (this.cajaservice.camposCierreCaja !== null) {
          this.cajaservice.camposCierreCaja.map((campos) => {
            campos.TRNCOBRO_FECHATRN = this.datePipe.transform(campos.TRN_FECHA.toLocaleString().substr(0, 10),
              'dd/MM/yyyy');
            campos.TRNCOBRO_NROCOMPROBANTE = campos.TRN_NROCOMPROBANTE;
            campos.DETCUADRECAJA_DEBITO = campos.DEBITOS.toFixed(2);
            campos.DETCUADRECAJA_CREDITO = campos.CREDITOS.toFixed(2);
            campos.NUMERO = this.cajaservice.camposCierreCaja.indexOf(campos) + 1;
            campos.SNBOOLEAN = false;
          });
          await this.getDocAsociados(this.cajaservice.camposCierreCaja);
          this.checkDes = false;
          this.cajaservice.camposCierreCaja = [];
        }
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('info', 'Error', mensaje);
      }
    }
  }
  
  async cambioDropOri() {
    if (this.opcSelectOri.APLORG_NOMBRE === 'Quitar todo' && this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO === '') {
      this.origenes = [];
      this.consultaOri = '';
      this.consulta.cuadreCajaNum = this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO;
      if (this.consultaCaj !== '' && this.consultaOri !== '' && this.consultaVen !== '') {
        this.consulta.filtro = this.consultaCaj + ' AND ' + this.consultaOri + ' AND ' + this.consultaVen;
      } else if (this.consultaOri !== '' && this.consultaCaj !== '' && this.consultaVen === '') {
        this.consulta.filtro = this.consultaCaj + ' AND ' + this.consultaOri;
      } else if (this.consultaCaj !== '' && this.consultaOri === '' && this.consultaVen !== '') {
        this.consulta.filtro = this.consultaOri + ' AND ' + this.consultaVen;
      } else if (this.consultaCaj !== '' && this.consultaOri === '' && this.consultaVen === '') {
        this.consulta.filtro = this.consultaOri;
      } else if (this.consultaCaj === '' && this.consultaOri === '' && this.consultaVen === '') {
        this.consultaOri = '';
      }
      try {
        const res = await this.cajaservice.consultarItemsBan_trnpago(this.consulta, this.date2.toLocaleString('en-GB').split(','),
          this.date3.toLocaleString('en-GB').split(','));
        this.cajaservice.camposCierreCaja = res;
        this.cargarDatos();
        if (this.cajaservice.camposCierreCaja !== null) {
          this.cajaservice.camposCierreCaja.map((campos) => {
            campos.TRNCOBRO_FECHATRN = this.datePipe.transform(campos.TRN_FECHA.toLocaleString().substr(0, 10),
              'dd/MM/yyyy');
            campos.TRNCOBRO_NROCOMPROBANTE = campos.TRN_NROCOMPROBANTE;
            campos.DETCUADRECAJA_DEBITO = campos.DEBITOS.toFixed(2);
            campos.DETCUADRECAJA_CREDITO = campos.CREDITOS.toFixed(2);
            campos.NUMERO = this.cajaservice.camposCierreCaja.indexOf(campos) + 1;
            campos.SNBOOLEAN = false;
          });
          await this.getDocAsociados(this.cajaservice.camposCierreCaja);
          this.checkDes = false;
          this.cajaservice.camposCierreCaja = [];
        }
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('info', 'Error', mensaje);
      }
    }
  }
  
  async cambioDropVen() {
    if (this.opcSelectVen.VEN_NOMBRE === 'Quitar todo' && this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO === '') {
      this.vendedores = [];
      this.consultaVen = '';
      this.consulta.cuadreCajaNum = this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO;
      if (this.consultaCaj !== '' && this.consultaOri !== '' && this.consultaVen !== '') {
        this.consulta.filtro = this.consultaCaj + ' AND ' + this.consultaOri + ' AND ' + this.consultaVen;
      } else if (this.consultaOri !== '' && this.consultaCaj !== '' && this.consultaVen === '') {
        this.consulta.filtro = this.consultaCaj + ' AND ' + this.consultaOri;
      } else if (this.consultaCaj !== '' && this.consultaOri === '' && this.consultaVen !== '') {
        this.consulta.filtro = this.consultaOri + ' AND ' + this.consultaVen;
      } else if (this.consultaCaj !== '' && this.consultaOri === '' && this.consultaVen === '') {
        this.consulta.filtro = this.consultaOri;
      } else if (this.consultaCaj === '' && this.consultaOri === '' && this.consultaVen === '') {
        this.consultaVen = '';
      }
      try {
        const res = await this.cajaservice.consultarItemsBan_trnpago(this.consulta, this.date2.toLocaleString('en-GB').split(','),
          this.date3.toLocaleString('en-GB').split(','));
        this.cajaservice.camposCierreCaja = res;
        this.cargarDatos();
        if (this.cajaservice.camposCierreCaja !== null) {
          this.cajaservice.camposCierreCaja.map((campos) => {
            campos.TRNCOBRO_FECHATRN = this.datePipe.transform(campos.TRN_FECHA.toLocaleString().substr(0, 10),
              'dd/MM/yyyy');
            campos.TRNCOBRO_NROCOMPROBANTE = campos.TRN_NROCOMPROBANTE;
            campos.DETCUADRECAJA_DEBITO = campos.DEBITOS.toFixed(2);
            campos.DETCUADRECAJA_CREDITO = campos.CREDITOS.toFixed(2);
            campos.NUMERO = this.cajaservice.camposCierreCaja.indexOf(campos) + 1;
            campos.SNBOOLEAN = false;
          });
          await this.getDocAsociados(this.cajaservice.camposCierreCaja);
          this.checkDes = false;
          this.cajaservice.camposCierreCaja = [];
        }
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('info', 'Error', mensaje);
      }
    }
  }
  
  seleccionMovimiento(valor) {
    if (valor === 'nuevo') {
      if (this.cajas.length === 0) {
        this.message.add({
          key: 'cierre',
          severity: 'error',
          summary: 'Creación de movimiento',
          detail: 'No se puede crear un movimiento porque no se seleccionó una caja'
        });
      } else {
        this.nuevoMovimiento();
      }
    } else if (valor === 'eliminar') {
      this.cajaservice.deletMovimiento(this.movimientoSeleccionado, this.movimientosVariosSeleccionado);
      this.aggrid.refreshaggrid(this.cajaservice.movimientos, this.defaultColDefMovimiento.objeto);
    } else if (valor === 'editar') {
      this.claveDialog = true;
    } else if (valor === 'cancelar') {
    } else if (valor.object !== null) {
      
      this.movimientoSeleccionado = valor.object;
      if (this.movimientoSeleccionado !== undefined) {
        this.cajaservice.getMovimientoVarios(this.movimientoSeleccionado).subscribe((res1) => {
          this.movimientosVariosSeleccionado = res1[0];
        });
      }
    }
  }
  
  nuevoMovimiento() {
    let numero = 0;
    if (this.cajaservice.movimientos[this.cajaservice.movimientos.length - 1] !== undefined) {
      if (this.cajaservice.movimientos[this.cajaservice.movimientos.length - 1].CAJ_CODIGO !== undefined) {
        numero = Number(this.cajaservice.movimientos[this.cajaservice.movimientos.length - 1].TRNCAJ_NUMERO);
        const newMovimiento: CamposMovimiento = {
          TRNCAJ_DOCNRO: this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO,
          COM_CODIGO: '01',
          TRNCAJ_FECHA: '',
          TRNCAJ_AUTORIZADO: '',
          TRNCAJ_DESCRIPCION: '',
          TRNCAJ_IMPORTE: '',
          TRNCAJ_TIPO: 'EF',
          TRNCAJ_NUMERO: String(numero + 1),
          TRNCAJ_ORIGEN: 'CDC',
          CAJ_CODIGO: this.cajaservice.movimientos[this.cajaservice.movimientos.length - 1].CAJ_CODIGO,
        };
        const newVarios: CamposMovimientosVarios = {
          TRNCAJ_TIPO: 'EF',
          TRNCAJ_NUMERO: String(numero + 1),
          CAJ_CODIGO: this.cajaservice.movimientos[this.cajaservice.movimientos.length - 1].CAJ_CODIGO,
          TRNCAJ_DOCNRO: this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO,
          COM_CODIGO: '01',
          CON_CODIGO: '',
          CEN_CODIGO: '',
          TRNCAJVAR_IMPORTE: '',
          TRNCAJVAR_CODSEC: '',
        };
        this.movimientoSeleccionado = newMovimiento;
        this.cajaservice.movimientos.push(newMovimiento);
        this.aggrid.refreshaggrid(this.cajaservice.movimientos, this.defaultColDefMovimiento.objeto);
        this.movimientosVariosSeleccionado = newVarios;
        this.cajaservice.movimientosVarios.push(newVarios);
      } else {
        numero = Number(this.cajaservice.movimientos[this.cajaservice.movimientos.length - 1].TRNCAJ_NUMERO);
        const newMovimiento: CamposMovimiento = {
          TRNCAJ_DOCNRO: this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO,
          COM_CODIGO: '01',
          TRNCAJ_FECHA: '',
          TRNCAJ_AUTORIZADO: '',
          TRNCAJ_DESCRIPCION: '',
          TRNCAJ_IMPORTE: '',
          TRNCAJ_TIPO: 'EF',
          TRNCAJ_NUMERO: String(numero + 1),
          TRNCAJ_ORIGEN: 'CDC',
          CAJ_CODIGO: this.cajas[0].CAJ_CODIGO,
        };
        const newVarios: CamposMovimientosVarios = {
          TRNCAJ_TIPO: 'EF',
          TRNCAJ_NUMERO: String(numero + 1),
          CAJ_CODIGO: this.cajas[0].CAJ_CODIGO,
          TRNCAJ_DOCNRO: this.encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO,
          COM_CODIGO: '01',
          CON_CODIGO: '',
          CEN_CODIGO: '',
          TRNCAJVAR_IMPORTE: '',
          TRNCAJVAR_CODSEC: '',
        };
        this.movimientoSeleccionado = newMovimiento;
        this.cajaservice.movimientos.push(newMovimiento);
        this.aggrid.refreshaggrid(this.cajaservice.movimientos, this.defaultColDefMovimiento.objeto);
        this.movimientosVariosSeleccionado = newVarios;
        this.cajaservice.movimientosVarios.push(newVarios);
      }
    }
    
  }
  
  comprobarClave() {
    this.cajaservice.getClave().subscribe((clave) => {
      let claveVerdadera;
      claveVerdadera = clave[0].AUTORIZACION_CLAVE;
      if (claveVerdadera === this.claveAutorizacion) {
        this.claveDialog = false;
        this.confirmationService.confirm({
          message: 'Desea modificar el movimiento del cierre de caja',
          header: 'Actualizar Movimiento',
          icon: 'pi pi-exclamation-trianlge',
          key: 'confirmarCierre',
          accept: () => {
            // document.getElementById('movimientoVisible').style.pointerEvents = 'all';
            this.actualizar = true;
            this.btnGuardar = false;
            this.btnNuevo = true;
            this.btnRegresar = false;
          },
          reject: () => {
          }
        });
      } else if (claveVerdadera !== this.claveAutorizacion) {
        this.message.add({
          key: 'cierre',
          severity: 'error',
          summary: 'Clave Autorización',
          detail: 'La clave ingresada es incorrecta, intente nuevamente'
        });
      }
    });
  }
  
  calcularTotalMov() {
    this.sumaTotalMov = 0;
    this.cajaservice.movimientos.map((movimientos) => {
      this.sumaTotalMov += Number(movimientos.TRNCAJ_IMPORTE);
      this.sumaTotalMov = Number(this.sumaTotalMov.toFixed(2));
    });
  }
  
  seleccionCierre(event) {
  }
  
  setSelectItemDoc(actual: CamposCierreCaja) {
    this.cajaservice.camposCierreCaja.map((row) => {
      if (String(actual.TRN_NROCOMPROBANTE).trim() === String(row.TRN_NROCOMPROBANTE).trim()) {
        row.SNBOOLEAN = actual.SNBOOLEAN;
      }
    });
    this.aggrid.refreshColumnDefs();
  }
  
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'F9') {
      this.abrirDialogoSuma();
    }
  }
  
  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'cierre',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
  
  cerrarReportes() {
    this.displayDialogReportes = false;
  }
  
}



