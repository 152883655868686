import { Component} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import { MenuItem} from 'primeng/primeng';

@Component({
    selector: 'app-right-menu',
    templateUrl: './app.right-menu.component.html'
})
export class AppRightMenuComponent {
    statusActive = true;
    model: MenuItem[];
    messagesActive: boolean;

    constructor(public app: AppMainComponent) {
      this.model = [
        {
          label: 'Options',
          items: [{label: 'New', icon: 'pi pi-fw pi-plus'},
            {label: 'Delete', icon: 'pi pi-fw pi-trash', url: 'http://primetek.com.tr'}]
        },
        {
          label: 'Account',
          items: [{label: 'Options', icon: 'pi pi-fw pi-cog'},
            {label: 'Sign Out', icon: 'pi pi-fw pi-power-off', routerLink: ['/login']} ]
        }
      ];
    }
  
  messagesClick() {
        this.statusActive = false;
        this.messagesActive = true;
    }

    statusClick() {
        this.statusActive = true;
        this.messagesActive = false;
    }
    click(evento) {
      if (evento.target.innerText === 'Estado Especial del pedido') {
      
      }
    }
}
