import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from 'src/app/usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';


@Injectable({
  providedIn: 'root'
})
export class ImportadatosexcelService {

  private way: string;
 

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) { 
      this.way = sessionStorage.getItem('way');
  
    }


    

    funListaMaestroCargas(){
  
    return this.http.post<any[]>(this.way + '/nom/impdatexcel/61mmijkub6p7fbr', {
        
        elementos: {
        },
      },this.confIniciales.httpOptions()).toPromise();
    }




    eliminarDatosAnteriors(){
  
      return this.http.post<any[]>(this.way + '/nom/impdatexcel/6qeb7zwa2061isf', {
         
          elementos: {
          },
        },this.confIniciales.httpOptions()).toPromise();
      
    }



    insertar(codigo, descri, fila, opctab){
  
      return this.http.post<any[]>(this.way + '/nom/impdatexcel/uyo2un3o1vrmc2x', {
          elementos: {
            Codigo: codigo, 
            Descri: descri, 
            Fila: fila, 
            Opctab: opctab
          },
        },this.confIniciales.httpOptions()).toPromise();
    }

    funDistrDatosImportado(codigo){
      return  this.http.post<any[]>(this.way + '/nom/impdatexcel/nbv64r10njtwd1e', {
        elementos: {
          p_maecargexce_codigo:codigo,
          p_com_codigo: '01'
        },
      },this.confIniciales.httpOptions()).toPromise();
    }
}
