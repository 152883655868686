import {Component, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';
import {ConcierrecontableService} from '../conservicios/concierrecontable.service';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {ErroresBaseDatosService} from 'src/app/servicios/errores-base-datos.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';


@Component({
  selector: 'app-concierrecontable',
  templateUrl: './concierrecontable.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class ConcierrecontableComponent implements OnInit {
  
  // variables de fecha
  dateTimeDesde: any;
  dateTimeHasta: any;
  
  opcionbusqueda: string;
  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];
  tabla: string;
  
  CONCODIGO: string;
  CONNOMBRE: string;
  
  inputDes: boolean;
  displayDialogBusquedaFast: boolean;
  
  constructor(
    private cierreContableService: ConcierrecontableService,
    private busqService: InvbusquedaService,
    private datePipe: DatePipe,
    private message: MessageService,
    private confirmationService: ConfirmationService,
    private errorService: ErroresBaseDatosService,
    private messageService: MessageService,
    private auditoriagral: AuditoriagralService,
    private init: NuevoComponentService
  ) {
  }
  
  ngOnInit(): void {
    this.dateTimeDesde = new Date();
    this.dateTimeHasta = new Date();
    this.inputDes = false;
  }
  
  ejecutaAsientoContable() {
    const desde = this.datePipe.transform(this.dateTimeDesde, 'dd/MM/yyyy');
    const hasta = this.datePipe.transform(this.dateTimeHasta, 'dd/MM/yyyy');
    
    this.cierreContableService.ejecutaAsientoContable(desde, hasta, this.CONCODIGO).subscribe(res => {
      console.log(res);
      this.auditoriagral.registrarAuditoria('SACI_MAEPERIODO', desde + '/' + hasta, 'A',
        '', '01', '', '', '', '').subscribe(
        res => {
          console.log(res);
        }
      );
    }, error1 => {
      const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'encfacpropago',
        severity: 'error',
        summary: 'Error',
        detail: error
      });
    });
    this.message.add({
      key: 'Cierre Contable',
      severity: 'success',
      summary: 'CierreContable',
      detail: 'El Periodo Contable se guardo correctamente'
    });
  }
  
  async confirmarAsientoContable() {
    if (this.CONCODIGO === null || this.CONCODIGO === undefined || this.CONCODIGO === '') {
      this.message.add({
        key: 'Cierre Contable',
        severity: 'success',
        summary: 'Información',
        detail: 'Ingrese la cuenta contable'
      });
      return;
    }
    
    const er = await this.cierreContableService.erConCodigo(this.CONCODIGO);
    if (er === null || er === undefined) {
      this.message.add({
        key: 'Cierre Contable',
        severity: 'success',
        summary: 'Información',
        detail: 'Cuenta Contable no existe'
      });
      return;
    }
    
    this.confirmationService.confirm({
      message: 'Está seguro de generar el asiento de cierre?',
      header: 'Asiento de cierre',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarAsientoContable',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.ejecutaAsientoContable();
      },
      reject: () => {
      }
    });
  }
  
  manejarSenales(valor) {
    switch (valor) {
      case 'Salir':
        this.salir();
        break;
    }
  }
  
  busquedaconcodigo() {
    this.opcionbusqueda = 'busquedaconcodigo';
    this.types = [
      {label: 'Código', value: 'CON_CODIGO'},
      {label: 'Nombre', value: 'CON_NOMBRE'},
      {label: 'Cnt.Costos', value: 'CON_CENTRO'},
      {label: 'Cod.Sri', value: 'CON_CODSRI'},
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'CON_MAECON';
    this.cierreContableService.erConNombre(this.CONCODIGO).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.CONNOMBRE = eR[0].CON_NOMBRE;
          document.getElementById('CONCODIGO').focus();
        } else {
          this.busqService.busquedaConMaeCon2().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaConMaeCon2().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }
  
  manejarSeleccion(opcion) {
    if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
      this.messageService.add({
        key: 'Cierre Contable',
        severity: 'error',
        summary: 'Cuenta Contable',
        detail: 'No se puede elegir esa cuenta contable'
      });
    } else {
      this.CONCODIGO = opcion.CON_CODIGO;
      this.CONNOMBRE = opcion.CON_NOMBRE;
      document.getElementById('CONCODIGO').focus();
    }
    this.displayDialogBusquedaFast = false;
  }
  
  salir() {
    this.init.tabs.splice(this.init.tabindex, 1);
  }
}
