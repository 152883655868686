import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-reporte-pdf',
  templateUrl: './reporte-pdf.component.html',
  styleUrls: ['./reporte-pdf.component.css']
})
export class ReportePdfComponent implements OnInit {
  
  NOMBREREPORTE: string;
  urlreporte: string;
  usuarioEsquema: any;
  esquema: string;
  src: string;
  
  constructor(private rutaActiva: ActivatedRoute, private http: HttpClient) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }
  
  ngOnInit() {
    this.urlreporte = sessionStorage.getItem('urlreporte');
    this.usuarioEsquema = JSON.parse(sessionStorage.getItem('usuarioEsquema'));
    this.esquema = this.usuarioEsquema.esquema;
    this.NOMBREREPORTE = '';
    this.NOMBREREPORTE = this.rutaActiva.snapshot.params.nombre;
    this.NOMBREREPORTE = this.NOMBREREPORTE.substr(0, this.NOMBREREPORTE.length - 7);
    this.src = this.urlreporte + this.NOMBREREPORTE + '.pdf?j_username=' + this.esquema + '&j_password=jasperadmin';
    console.log(this.src);
    
    /*fetch(this.src).then(res => res.blob()).then(blob => {
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });*/
    /*const file = new Blob([this.src], {type: 'application/pdf'});
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);*/
    
    // pdfMake.createPdf(this.src).open();
    
    /*setTimeout(() => {
      const link = document.createElement('a');
      link.setAttribute('type', 'hidden');
      link.href = this.src;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }, 2000);*/
    
    /*const url = this.src;
    this.http.get(url, {
      responseType: 'arraybuffer'
    }).subscribe((response) => {
      const blob = new Blob([response], {type: 'application/pdf'});
      const blobUrl = URL.createObjectURL(blob);
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    });*/
    
  }
  
}
