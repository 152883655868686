import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {Observable} from 'rxjs';
import { comdatosimp ,comdatos,ComEncfacpro,comdathomologa} from '../cominterfaces/comencfacpro';
import { Cxctrnretencion } from 'src/app/cxc/cxcinterfaces/cxctrnretencion';
import { Usuario } from 'src/app/usuario';
@Injectable({
  providedIn: 'root'
})
export class ComimpdoceleService {
  private way: string;
  public arreglohomo: comdathomologa[][]= [];
  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) { 
      this.way = sessionStorage.getItem('way');
    }
  cambiarpro(codigo){
      return  this.http.post<any[]>(this.way + '/compras/impdocelec/31565c95bcef20i', {
        elementos: {
          p_PRO_CODIGO: codigo
        }
      }, this.confIniciales.httpOptions()).toPromise();
  }
  buscaprov(){
      return  this.http.post<any[]>(this.way + '/compras/impdocelec/17ee3159de3ee7000i', {
        elementos: {
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }
  buscahomologa(){
      return  this.http.post<any[]>(this.way + '/compras/impdocelec/5fb9a8e2d8ae200i', {
        elementos: {
        }
      }, this.confIniciales.httpOptions()).toPromise();
    } 
  deleteencfacpro(){
      return  this.http.post<any[]>(this.way + '/compras/impdocelec/35d5775cebb1ea0000i', {
        elementos: {
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }
  deletedetfacpro(){
      return  this.http.post<any[]>(this.way + '/compras/impdocelec/35d5775cd775ea0000i', {
        elementos: {
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }     
  conruccompania(){
      return  this.http.post<any[]>(this.way + '/compras/impdocelec/198befcc662d95000i', {
        elementos: {
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }
    existenpro(codigo){
      return  this.http.post<any[]>(this.way + '/compras/impdocelec/1d2e75d9de3ee7000i', {
        elementos: {
          p_PRO_CODIGO: codigo
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }
    existenprocod(codigo){
      return  this.http.post<any[]>(this.way + '/compras/impdocelec/e973aecef1f738000000i', {
        elementos: {
          p_PRO_CODIGO: codigo
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }
    guardarProveedor(RUC,NUMRUC,DIREC,NOMBRE,NOMBREC,GRU_CODIGO,CUENTA,ANTICIPO, fecha) {
          return this.http.post<any[]>(this.way + '/compras/impdocelec/o33okudzpsg1hdvi', {      
            elementos: {
              p_PRO_CODIGO_1: RUC,//ruc
              p_GRU_CODIGO_2: GRU_CODIGO,//000 pregunta ing
              p_PRO_NOMBREC_4: NOMBREC ,
              p_PRO_NOMBRE_5: NOMBRE,
              p_PRO_RUCIDE_6: RUC,//RUC
              p_PRO_DIRECCION1_7: DIREC,//si
              p_PRO_DIRECCION2_8: '',
              p_PRO_CODPOSTAL_9: '',
              p_PRO_TELEFONO1_10: '',//si
              p_PRO_TELEFONO2_11: '',
              p_PRO_FAX_12: '',
              p_PRO_CORREO_13: '',//si
              p_PRO_CONTACTO_14: '',
              p_PRO_FECING_15: fecha,
              p_PRO_LIMCREDIT_16: '0',
              p_PRO_DIACREDIT_17: '0',
              p_PRO_DESCUENTO_18: '0',
              p_PRO_IVA_19: '1',//1
              p_PRO_CONTRIBUYENTE_20: 'N',//N
              p_PRO_LISTA_21: '',
              P_CON_CODIGO1_22: CUENTA,//misma
              p_CON_CODIGO2_23: ANTICIPO,
              p_PRO_ZONA_24: '',
              p_PRO_OBSERVACION_25:'',
              p_PRO_NUMSERIE_26: '',
              p_PRO_NUMAUTORIZACION_27: '',
              p_PRO_TIPOIDE: NUMRUC,
              p_CEN_CODIGO: '',
              p_COM_CODIGO: '01',
              p_PRO_REGCOND: '',
              p_PRO_tipoa: '',
              p_PRO_parterel: 'NO',
              P_con_codigo_ret332: '',
              p_PRO_correoElect2: '',
              p_PRO_facturaElect: 'S',
              p_pro_tipoden: '',
              p_pro_transporte: '',
              P_PRO_PAGOLOCALEXT: '',
              P_PRO_tiporegfiscal: '',
              P_PRO_codpaisreggen: '',
              P_PRO_codpaisparfis: '',
              P_PRO_denominacion: '',
              P_PRO_PAISPAGO: '',
              P_PRO_convdobletrib: '',
              P_PRO_pagoexterior: '',
              P_PRO_pagoregfiscal: '',
              P_PRO_conregmic: '',
              P_PRO_agenteret: '',
              P_PRO_conregRimpe: ''
            },
          }, this.confIniciales.httpOptions()).toPromise();;
        }

        insertarEncabezadoFactPromise(item): Promise<any[]> {
          console.log('-----guardar facpro------',item)
          return this.http.post<any>(this.way + '/compras/impdocelec/4afc76faadbaec000000000i', {
            elementos: {
              p_encfacpro_numero: item.ENCFACPRO_NUMERO,
              p_com_codigo: item.COM_CODIGO,
              p_encfacpro_fechaemision: item.ENCFACPRO_FECHAEMISION,
              p_encfacpro_fechavencimiento: item.ENCFACPRO_FECHAVENCIMIENTO,
              p_encfacpro_fecharec: item.ENCFACPRO_FECHAREC,
              p_encfacpro_iva: item.ENCFACPRO_IVA,
              p_encfacpro_estado: item.ENCFACPRO_ESTADO,
              p_pro_codigo: item.PRO_CODIGO,
              p_encfacpro_contacto: item.ENCFACPRO_CONTACTO,
              p_encfacpro_referencia: item.ENCFACPRO_REFERENCIA,
              p_encfacpro_total: Number(item.ENCFACPRO_TOTAL.toFixed(2)),
              p_encfacpro_totalneto: item.ENCFACPRO_TOTALNETO,
              p_encfacpro_valordes: item.ENCFACPRO_VALORDES,
              p_encfacpro_porcedes: item.ENCFACPRO_PORCEDES,
              p_encfacpro_valoriva: item.ENCFACPRO_VALORIVA,
              p_encfacpro_porceiva: item.ENCFACPRO_PORCEIVA,
              p_encfacpro_valorice: item.ENCFACPRO_VALORICE,
              p_encfacpro_baseiva: item.ENCFACPRO_BASEIVA,
              p_encfacpro_baseice: item.ENCFACPRO_BASEICE,
              p_encfacpro_basecero: item.ENCFACPRO_BASECERO,
              p_encfacpro_comentario: item.ENCFACPRO_COMENTARIO,
              p_encfacpro_otros: item.ENCFACPRO_OTROS,
              p_encfacpro_distcargos: item.ENCFACPRO_DISTCARGOS,
              p_encfacpro_numdiasplazo: item.ENCFACPRO_NUMDIASPLAZO,
              p_encfacpro_idcretri: item.ENCFACPRO_IDCRETRI,
              p_encfacpro_serie: item.ENCFACPRO_SERIE,
              p_encfacpro_autorizacion: item.ENCFACPRO_AUTORIZACION,
              p_encfacpro_tipcom: item.ENCFACPRO_TIPCOM,
              p_maemotivo_codigo: item.MAEMOTIVO_CODIGO,
              P_encfacpro_fechacadfac: item.ENCFACPRO_FECHACADFAC,
              p_encfacpro_numingreso: item.ENCFACPRO_NUMINGRESO,
              p_encfacpro_numliquidacion: item.ENCFACPRO_NUMLIQUIDACION,
              p_encfacpro_tipodes: item.ENCFACPRO_TIPODES,
              p_usu_identificacion: this.usuario.identificacion,
              p_encfacpro_gasto: item.ENCFACPRO_GASTO,
              p_encordcom_numero: item.ENCORDCOM_NUMERO,
              p_encfacpro_noobjetoiva: item.ENCFACPRO_NOOBJETOIVA,
              P_encfacpro_basenoobjiva: item.ENCFACPRO_BASENOOBJIVA,
              p_trnformapago_codigo: item.TRNFORMAPAGO_CODIGO,
              p_encfacpro_origen: '',
              p_encfacpro_flag: item.ENCFACPRO_FLAG,//GSRF
              p_encfacpro_formapago: 'C'//GSRF
            }
          }, this.confIniciales.httpOptions()).toPromise();
        }
        insertarDetalleFacturaPromise(item): Promise<any[]> {
          console.log('INSERTA DETALLE',item);
          return this.http.post<any>(this.way + '/compras/impdocelec/4afc735dd53d4i', {
            elementos: {
              p_encfacpro_numero: item.ENCFACPRO_NUMERO,
              p_com_codigo: '01',
              p_detfacpro_linea: item.DETFACPRO_LINEA,
              p_detfacpro_tipodet: item.DETFACPRO_TIPODET,
              p_bod_codigo: item.BOD_CODIGO,
              p_detfacpro_codigo: item.DETFACPRO_CODIGO,
              p_detfacpro_descripcion: item.DETFACPRO_DESCRIPCION,
              p_detfacpro_unidad: item.DETFACPRO_UNIDAD,
              p_detfacpro_cantidad: item.DETFACPRO_CANTIDAD,
              p_detfacpro_entregado: item.DETFACPRO_ENTREGADO,
              p_detfacpro_costo: item.DETFACPRO_COSTO,
              p_detfacpro_valdes: item.DETFACPRO_VALDES,
              p_detfacpro_pordes: item.DETFACPRO_PORDES,
              p_detfacpro_total: item.DETFACPRO_TOTAL,
              p_detfacpro_poriva: item.DETFACPRO_PORIVA,
              p_detfacpro_iva: Number(item.DETFACPRO_IVA.toFixed(2)),
              p_detfacpro_tribice: item.DETFACPRO_TRIBICE,
              p_detfacpro_ice: item.DETFACPRO_ICE,
              p_detfacpro_porceice: item.DETFACPRO_PORCEICE,
              p_detfacpro_baseiva: item.DETFACPRO_BASEIVA,
              p_detfacpro_baseice: item.DETFACPRO_BASEICE,
              p_detfacpro_basecero: item.DETFACPRO_BASECERO,
              p_encordcom_numero: item.ENCORDCOM_NUMERO,
              p_detordcom_linea: item.DETORDCOM_LINEA,
              p_encnotrec_numero: item.ENCNOTREC_NUMERO,
              p_detnotrec_linea: item.DETNOTREC_LINEA,
              p_detfacpro_promocion: item.DETFACPRO_PROMOCION,
              p_detfacpro_totalincp: item.DETFACPRO_TOTALINCP,
              p_detfacpro_pordes2: item.DETFACPRO_PORDES2,
              p_detfacpro_pordes3: item.DETFACPRO_PORDES3,
              p_cen_codigo: item.CEN_CODIGO,
              p_detfacpro_factor: item.DETFACPRO_FACTOR,
              p_detfacpro_preciofob: item.DETFACPRO_PRECIOFOB,
              p_detfacpro_esquemadoc: item.DETFACPRO_ESQUEMADOC,
              p_detfacpro_tiposri: item.DETFACPRO_TIPOSRI,
              p_detfacpro_precioa: item.DETFACPRO_PRECIOA,
              p_detfacpro_preciob: item.DETFACPRO_PRECIOB,
              p_detfacpro_precioc: item.DETFACPRO_PRECIOC,
              p_trnsolfac_codigo: item.TRNSOLFAC_CODIGO,
              p_trnsolfac_linea: item.TRNSOLFAC_LINEA,
              p_encreq_numero: item.ENCREQ_NUMERO,
              p_detreq_linea: item.DETREQ_LINEA,
              p_encgre_codigo: item.ENCGRE_CODIGO,
              p_detgre_linea: item.DETGRE_LINEA,
              p_detfacpro_basenoobjiva: item.DETFACPRO_BASENOOBJIVA,
              p_detfacpro_tribasenoobjiva: item.DETFACPRO_TRIBASENOOBJIVA,
              p_detfacpro_cantidadund: item.DETFACPRO_CANTIDADUND,
              p_tipgas_codigo: item.TIPGAS_CODIGO,
              p_detfacpro_po: '',
              p_detfacpro_ajuste: '',
              p_tariiva_codigo: item.TARIIVA_CODIGO
            }
          }, this.confIniciales.httpOptions()).toPromise();
        }
        insertarhomologacion(item): Promise<any[]> {
          console.log('INSERTA HOMOLOGACION',item);
          return this.http.post<any>(this.way + '/compras/impdocelec/95f8edf55b8e3000000i', {
            elementos: {
              p_detfac_codigo: item.DETFAC_CODIGO,
              p_detfac_descripcion: item.DETFAC_DESCRIPCION,
              p_detfac_cuentacontable: item.DETFAC_CODIGOTIPO,
              p_detfac_homologado:item.DETFAC_HOMOLOGADO,
              p_com_codigo:item.COM_CODIGO,
              p_detfac_tipo:item.DETFAC_TIPO,
              p_pro_codigo:item.PRO_CODIGO,
              p_pro_nombre:item.PRO_NOMBRE,
              p_detfac_nombretipo:item.DETFAC_NOMBRETIPO,
            }
          }, this.confIniciales.httpOptions()).toPromise();
        }
        updatehomologacion(item): Promise<any[]> {
          console.log('UPDATE HOMOLOGACION',item);
          return this.http.post<any>(this.way + '/compras/impdocelec/3d96abae8e2d8a0000i', {
            elementos: {
              p_detfac_procodigo:item.PRO_CODIGO,
              p_detfac_codigo:item.DETFAC_CODIGO, 
              p_detfac_nombretipo:item.DETFAC_NOMBRETIPO,
              p_detfac_cuentacontable: item.DETFAC_CODIGOTIPO,
              p_detfac_homologado: item.DETFAC_HOMOLOGADO,
              p_detfac_unidad:item.DETFACPRO_UNIDAD,
              p_detfac_tipo:item.DETFAC_TIPO,
             
            }
          }, this.confIniciales.httpOptions()).toPromise();
        }
        updatehomovsfacimp(): Promise<any[]> {
          return this.http.post<any>(this.way + '/compras/impdocelec/3d96abae8e2d9000000000i', {
            elementos: {
              p_COM_CODIGO:'01',
            }
          }, this.confIniciales.httpOptions()).toPromise();
        }
        generablodnum(codigo){
          return  this.http.post<any[]>(this.way + '/compras/impdocelec/5d71a56d9197f00i', {
            elementos: {
              p_codigo: codigo
            }
          }, this.confIniciales.httpOptions()).toPromise();
        }
        insertarRetencionP(retencion: Cxctrnretencion): Promise<any[]> {
          return this.http.post<any[]>(this.way + '/compras/impdocelec/4afceeefb775d80000i', {
            elementos: {
              p_retencion_codigo_1: retencion.RETENCION_CODIGO,
              p_trnretencion_descripcion_4: retencion.TRNRETENCION_DESCRIPCION,
              p_trnretencion_nro_1: retencion.TRNRETENCION_NRO,
              p_trnretencion_base_5: Number(retencion.TRNRETENCION_BASE),
              p_trnretencion_porcentaje_6: Number(retencion.TRNRETENCION_PORCENTAJE),
              p_trnretencion_totalretenido_7: Number(retencion.TRNRETENCION_TOTALRETENIDO),
              p_com_codigo_3: '01',
              p_trnretencion_origen: retencion.TRNRETENCION_ORIGEN,
              p_con_codigo: retencion.CON_CODIGO,
              p_cen_codigo: '',
              p_cli_codigo: retencion.CLI_CODIGO,
              p_ENCFAC_NUMERO: retencion.ENCFAC_NUMERO,
              p_TRNRETENCION_FLAG:retencion.TRNRETENCION_FLAG,
              p_TRNRETENCION_NROAUTORIZACION: retencion.TRNRETENCION_NROAUTORIZACION,
              p_TRNRETENCION_FECHAEMISION: retencion.TRNRETENCION_FECHAEMISION
            },
          }, this.confIniciales.httpOptions()).toPromise();
        }
        deleteret(){
          return  this.http.post<any[]>(this.way + '/compras/impdocelec/35d5775db775d80000i', {
            elementos: {
            }
          }, this.confIniciales.httpOptions()).toPromise();
        }
        updateablodnum(codigo,bloqnum){
          return  this.http.post<any[]>(this.way + '/compras/impdocelec/3d96abae5d71a60000i', {
            elementos: {
              p_codigo: codigo,
              p_bloqnum:bloqnum
            }
          }, this.confIniciales.httpOptions()).toPromise();
        }
        impencandet(): Promise<any[]> {
          return this.http.post<any>(this.way + '/compras/impdocelec/95b2f531576b600i', {
            elementos: {
              p_COM_CODIGO:'01',
            }
          }, this.confIniciales.httpOptions()).toPromise();
        }
        impretencion(bloqnum){
          return  this.http.post<any[]>(this.way + '/compras/impdocelec/95b3b775d764b00i', {
            elementos: {
              p_usuarioidentf: this.usuario.identificacion,
              p_com_codigo:'01',
              p_trnretencion_bloque:bloqnum
            }
          }, this.confIniciales.httpOptions()).toPromise();
        }
        deletebloqfac(bloqnum): Promise<any[]> {
          return this.http.post<any>(this.way + '/compras/impdocelec/d755dd72eb864000i', {
            elementos: {
              p_ENCFACPRO_BLOQUEFACXML:bloqnum,
             
            }
          }, this.confIniciales.httpOptions()).toPromise();
        }
        selectfac(serie,secu){
          return  this.http.post<any[]>(this.way + '/compras/impdocelec/17ee31ea67dd7i', {
            elementos: {
              p_serie: serie,
              p_secu:secu
            }
          }, this.confIniciales.httpOptions()).toPromise();
        }
        ///////////////////////////////////////////////////////////////////////////////////////////////////
        buscnodedu(){
          return  this.http.post<any[]>(this.way + '/compras/impdocelec/17ee32f86b9bei', {
            elementos: {
            }
          }, this.confIniciales.httpOptions()).toPromise();
        }
        insertarnodedu(clave, ESTADO): Promise<any[]> {
          return this.http.post<any[]>(this.way + '/compras/impdocelec/12bf1dbedf0d730000000i', {
            elementos: {
              p_TRNCLAVEDOC_CLACESSO: clave,
              p_TRNCLAVEDOC_ESTADO: ESTADO
            },
          }, this.confIniciales.httpOptions()).toPromise();
        }
        deletenodedu(clave): Promise<any[]> {
          return this.http.post<any[]>(this.way + '/compras/impdocelec/6baaeebaf86b9c0i', {
            elementos: {
              p_clave: clave,
            },
          }, this.confIniciales.httpOptions()).toPromise();
        }
        buscnodeduOK(clave){
          return  this.http.post<any[]>(this.way + '/compras/impdocelec/5fb8cbe1ae6fb00', {
            elementos: {
              p_clave:clave
            }
          }, this.confIniciales.httpOptions()).toPromise();
        }
        buscfacpro(clave){
          return  this.http.post<any[]>(this.way + '/compras/impdocelec/17ee31ea66778', {
            elementos: {
              p_clave:clave
            }
          }, this.confIniciales.httpOptions()).toPromise();
        }
        
  
}
