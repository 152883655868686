import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-nomencrol1',
  templateUrl: './nomencrol1.component.html',
  styleUrls: ['../../../../advantage/styles/saciwebmae.css']
})
export class Nomencrol1Component implements OnInit {
  @Output() salirFechaFin= new EventEmitter<any>();
  fechaFin;

  constructor() { }

  ngOnInit(): void {
  }

  setFechFin(valor){
    this.fechaFin = this.convertDate(valor);
  }

  aceptar(){
    this.salirFechaFin.emit(
        this.fechaFin
    );
  }

  cancelar(){
    this.salirFechaFin.emit(
      this.fechaFin = ''
  );
  }

  convertDate(date: string) {
    if( date !== '' || date !== undefined || date !== null){

      const fecha = new Date( date );
      
  
      let month = '' + (fecha.getMonth() + 1);
      let day = '' + fecha.getDate();
      let year = fecha.getFullYear();
  
      if( month.length < 2 ) { month = '0' + month }
      if( day.length < 2 ) { day = '0' + day }
  
      return `${day}/${month}/${year}`;
    } 

    return '';
    
  }

}
