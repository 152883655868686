<div class="p-grid" style="width: 97%">
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-10">
			</div>
			<div class="p-col-2">
				<button style="background-image: url(../../../assets/images/iconos/salir.png);"
						(click)="salir()"></button>
			</div>
		</div>
	</div>
	<div class="p-col-12">
		<p-tabView [(activeIndex)]="activeIndex">
			<p-tabPanel header="Categorías" leftIcon="pi pi-sign-in"
						[style]="{'width':'100%', 'height':'calc(100vh - 250px)'}">
				<app-maecategoria (subCat)="irPantallSubCat($event)"></app-maecategoria>
			</p-tabPanel>
			<p-tabPanel header="Subcategorías" [disabled]="hideSubCategoria" leftIcon="pi pi-folder-open"
						[style]="{'width':'100%', 'height':'calc(100vh - 250px)'}">
				<app-maesubcategoria *ngIf="subcats" (productos)="irPantallaProd($event)"
									 [selectedSubCat]="subcats"></app-maesubcategoria>
			</p-tabPanel>
			<p-tabPanel header="Pantalla" [disabled]="hidePantalla" leftIcon="pi pi-shopping-cart"
						[style]="{'width':'100%', 'height':'calc(100vh - 250px)'}">
				<app-maepantalla *ngIf="pantallas" [selectedPantalla]="pantallas"></app-maepantalla>
			</p-tabPanel>
		</p-tabView>
	</div>
</div>
