import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {AggridBanComponent} from '../../ban/aggrid-ban/aggrid-ban.component';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {VenpedidosService} from '../venservicios/venpedidos.service';
import {DatePipe} from '@angular/common';
import {VenDetped} from '../veninterfaces/vendetped';
import {VenenguiaRemisionService} from '../venservicios/venenguia-remision.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Component({
  selector: 'app-venpedidos',
  templateUrl: './venpedidos.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class VenpedidosComponent implements OnInit, OnChanges {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  largo: string;
  allSelected: boolean;
  rowSelection = 'multiple';
  public frameworkComponents;
  arregloaEnviar: [VenDetped[]];
  @Input() cliCodigo: string;
  @Input() indicadorGuia: number;
  @Output() detallesSeleccionados = new EventEmitter<[VenDetped[]]>();
  @Output() signalSalir = new EventEmitter<boolean>();
  rowStyle;
  defaultColDef;
  
  columnDefsDetfac = [
    {
      headerName: '', field: 'SELECCIONADO', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    { // 0
      headerName: 'Número', field: 'ENCPED_NUMERO', width: 150, editable: false,
    },
    { // 1
      headerName: 'Comprobante', field: 'ENCPED_REFERENCIA', editable: false, width: 150
 
    },
    { // 2
      headerName: 'Fec. Emisión', field: 'ENCPED_FECHAEMISION', width: 200, editable: false
    },
    { // 3
      headerName: 'Fec. Entrega', field: 'ENCPED_FECHAENTREGA', width: 200, editable: false
      // , editable: true,
    },
    { // 4
      headerName: 'Estado', field: 'ENCPED_ESTADO', width: 60, editable: false
      // , editable: true,
    },
    {
      headerName: 'Nombre Cliente', field: 'CLI_NOMBRE', editable: false, width: 150,
    },
    { // 6
      headerName: 'Cod Cliente', field: 'CLI_CODIGO', width: 100, editable: false
      // , editable: true,
    },
    { // 7
      headerName: 'Cod Vendedor', field: 'VEN_CODIGO', width: 90, editable: false
      // , editable: true,
    },
    { // 8
      headerName: 'Observación', field: 'ENCPED_OBSERVACION', width: 200, editable: false
      // , editable: true,
    }
  ];
  constructor(public pedidosgreService: VenpedidosService, private datePipe: DatePipe,
              private venenguiaRemisionService: VenenguiaRemisionService, private init: NuevoComponentService) {
    this.agTable();
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDef = {
      editable: true,
      width: 100,
      objeto: 'detpedgre'
    };
  }
  ngOnInit() {
    this.allSelected = false;
    this.largo = '400';
    this.arregloaEnviar = [[]];
  }
  ngOnChanges(changes: SimpleChanges): void {
    //this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    if (this.cliCodigo !== undefined) {
      let encpedetG
      this.pedidosgreService.getEncPed(this.cliCodigo).subscribe((res) => {
        this.pedidosgreService.encped = res;
        this.pedidosgreService.encped.map((encped) => {
          encpedetG = this.venenguiaRemisionService.detguiaRemisionarray[this.indicadorGuia].find(element => element.ENCPED_NUMERO === encped.ENCPED_NUMERO);
          if( encpedetG !== undefined){
            encped.SELECCIONADO = true;
            this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicadorGuia].ENCGRE_CODIGO
          }else{
            encped.SELECCIONADO = false;
          }
          
          encped.ENCPED_FECHAEMISION = this.datePipe.transform(encped.ENCPED_FECHAEMISION, 'dd/MM/yyyy');
          encped.ENCPED_FECHAENTREGA = this.datePipe.transform(encped.ENCPED_FECHAENTREGA, 'dd/MM/yyyy');
        });
      });
    }
  }
 seleccionar() {
   console.log(this.allSelected);
   if (this.allSelected === true) {
     this.pedidosgreService.encped.map((encped) => {
       encped.SELECCIONADO = true;
     });
     this.aggrid.refreshaggrid(this.pedidosgreService.encped, this.defaultColDef.objeto);
   } else if (this.allSelected === false) {
     this.pedidosgreService.encped.map((encped) => {
       encped.SELECCIONADO = false;
     });
     this.aggrid.refreshaggrid(this.pedidosgreService.encped, this.defaultColDef.objeto);
   }
 }
   enviarDetalles() {
     let encpedetG;
     this.pedidosgreService.encped.map((encped) => {
       //if (this.venenguiaRemisionService.detguiaRemisionarray[this.indicadorGuia].length !== 0) {
        // encpedetG = this.venenguiaRemisionService.detguiaRemisionarray[this.indicadorGuia].find(element => element.ENCPED_NUMERO === encped.ENCPED_NUMERO);
       //}
       if (encped.SELECCIONADO === true ){//&& encpedetG === undefined) {
         this.pedidosgreService.getDetPedidos(encped.ENCPED_NUMERO).subscribe((res) => {
           this.arregloaEnviar.push(res);
           
         });
       }
     });
     setTimeout(() => {
       this.detallesSeleccionados.emit(this.arregloaEnviar);
     }, 1000);
   }
   enviarSalir() {
     this.signalSalir.emit(true);
   }

}
