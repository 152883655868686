<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="nommaetiproltoast"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="NomMaeTipRolDialog" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 99%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
						 [botonRegresar]="btnRegresar" [barraBotonesCRUD]="false"
						 [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true"
						 (signal)="manejarSenales($event)"></app-invmaebarra>
	</div>

</div>
<div style="overflow: hidden" class="p-col-12">
	<p-tabView>
		<p-tabPanel header="Mantenimiento Tipo Rol">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="false"
							[enableFilter]="true"
							[rowData]="nommaeTipoRol"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefMaeTipoRol"
							[defaultColDef]="defaultColDefMaeTipoRol"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)="selectMaeTipoRol($event)"
							(cambios)="cambio($event)"

			></app-aggridsaci>
		</p-tabPanel>
	</p-tabView>
</div>

