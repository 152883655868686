<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="pagcon"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="pagconconf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<app-invmaebarra [botonNuevo]="botonNuevo" [botonGuardar]="botonGuardar" [botonBorrar]="botonBorrar"
				 [botonRegresar]="botonRegresar"
				 (signal)="manejarSenales($event)" [barraBotonesDesplaz]="barraBotones2" [barraBotones3]="barraBotones2"
				 [barraBotonesAsiCon]="barraBotones2" [barraBotones5]="true" [noNuevo]="true"
				 [barraFormaPago]="true"></app-invmaebarra>
<div class="divgrups">
	<div class="p-grid">
		<div class="p-col-1">
			<span>Num.Fact.:</span>
		</div>
		<div class="p-col-2">
			<label for="NUMFACT"></label>
			<input class="frm" id="NUMFACT" type="text" pInputText disabled
				   [(ngModel)]="strNumFact" autocomplete="off">
		</div>
		<div class="p-col-1">
			<span>Cliente:</span>
		</div>
		<div class="p-col-2">
			<label for="CLIENTE"></label>
			<input class="frm" id="CLIENTE" type="text" pInputText disabled
				   [(ngModel)]="encfac.CLI_CODIGO" autocomplete="off">
		</div>
		<div class="p-col-1">
			<span>Vendedor:</span>
		</div>
		<div class="p-col-1">
			<label for="VENDEDOR"></label>
			<input class="frm" id="VENDEDOR" type="text" pInputText disabled
				   [(ngModel)]="encfac.VEN_CODIGO" autocomplete="off">
		</div>
		<div class="p-col-2">
			<label for="NUMCOMP"></label>
			<input class="frm" id="NUMCOMP" type="text" pInputText disabled
				   style="background-color: rgb(232, 242, 255);;"
				   [(ngModel)]="trncobronrodoc" autocomplete="off">
		</div>
		<div class="p-col-2">
			<label for="ASIENTO"></label>
			<input class="frm" id="ASIENTO" type="text" pInputText disabled
				   [(ngModel)]="encfac.ASI_NRO" autocomplete="off">
		</div>
		<div class="p-col-12">
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-6">
						<div class="p-grid">
							<div class="p-col-4">
								<span class="contado">Total:</span>
							</div>
							<div class="p-col-6">
								<label for="TOTAL"></label>
								<input class="contado" id="TOTAL" type="text" pInputText disabled
									   [(ngModel)]="encfac.ENCFAC_TOTAL" style="color: blue;"
									   (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
									   autocomplete="off">
							</div>
							<div class="p-col-2">
							</div>
							<div class="p-col-4">
								<span class="contado">Efectivo:</span>
							</div>
							<div class="p-col-6">
								<label for="EFECTIVO"></label>
								<input class="contado" id="EFECTIVO" type="text" pInputText
									   [(ngModel)]="efectivo"
									   (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
									   autocomplete="off" appTwoDigitDecimaNumber [disabled]="btnefectivo">
							</div>
							<div class="p-col-2">
							</div>
						</div>
					</div>
					<div class="p-col-6">
						<div class="p-grid">
							<div class="p-col-4">
								<span class="contado">Saldo:</span>
							</div>
							<div class="p-col-6">
								<label for="SALDO"></label>
								<input class="contado" id="SALDO" type="text" pInputText disabled
									   [(ngModel)]="saldo"
									   (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
									   autocomplete="off">
							</div>
							<div class="p-col-2">
							</div>
							<div class="p-col-4">
								<span class="contado">Cambio:</span>

							</div>
							<div class="p-col-6">
								<label for="CAMBIO"></label>
								<input class="contado" id="CAMBIO" type="text" pInputText disabled
									   [(ngModel)]="cambio"
									   (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
									   autocomplete="off">
							</div>
							<div class="p-col-2">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<p-tabView [(activeIndex)]="index" (onChange)="gridsnull()" [style]="{'width':'98%'}">
				<p-tabPanel header="Retenciones">
					<app-aggridsaci [width]=""
									[height]="largo"
									[enableSorting]="true"
									[enableFilter]="true"
									[rowData]="pagconservice.reten"
									[frameworkComponents]="frameworkComponents"
									[animateRows]="true"
									[rowSelection]="rowSelection"
									[columnDefs]="columnDefsRetencion"
									[defaultColDef]="defaultColDefRet"
									[rowStyle]="rowStyle"
									[singleClickEdit]="false"
									[botones]="true"
									(selected)="seleccionretencion($event)"
									[mostrarGuardar]="false"
									[botonNuevo]="botonNuevoGrid"
									[botonBorrar]="botonBorrarGrid"
									[botonRegresar]="botonRegresarGrid">
					</app-aggridsaci>
					<div class="p-grid">
						<div class="p-col-7">
							<span style="color: rgb(0, 0, 255); font-weight: bold">{{lblAplicaRegMicroempresas}}</span>
						</div>
					</div>
				</p-tabPanel>
				<p-tabPanel header="Forma de Pago">
					<div class="p-grid">
						<div class="p-col-12">
							<app-aggridsaci [width]="900"
											[height]="largo2"
											[enableSorting]="true"
											[enableFilter]="true"
											[rowData]="pagconservice.pagos"
											[frameworkComponents]="frameworkComponents"
											[animateRows]="true"
											[rowSelection]="rowSelection"
											[columnDefs]="columnDefsPago"
											[defaultColDef]="defaultColDef"
											[rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="true"
											(selected)="seleccionpago($event)"
											[mostrarGuardar]="false"
											[botonNuevo]="botonNuevoGrid"
											[botonBorrar]="botonBorrarGrid"
											[botonRegresar]="botonRegresarGrid"
											(cambios)="cambiosbotones($event)">
							</app-aggridsaci>
						</div>
						<div class="p-col-3">
							<label style="background-color: rgb(255, 207, 185);">___</label>
							<span>Documentos depositados</span>
						</div>
						<div class="p-col-3">
							<label style="background-color: rgb(135, 234, 130);">___</label>
							<span>Documentos conciliados</span>
						</div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</div>
	</div>
</div>


<p-dialog header="Clave de Autorización" [(visible)]="claveDialog"
		  [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '40vw', height: 'auto'} ">
	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-4">
					<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-4">
					<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
						   (keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
				</div>
				<div class="p-col-1"></div>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width:'90vw', height:'120vh'}"
		  [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="asiento" [abiertoDesde]="'venencfac'"
					(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>
<!-- CDPJ -->
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	<p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
	</p-dialog>
</div>
<!-- CDPJ -->