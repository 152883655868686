//
// SACI WEB
// Rutina: Inventario , Recalcular costo promedio
// Propietario: Francisco Cevallos
// Modificado por: FC
// Fecha de creación: 10-01-2020
// Fecha de Modificación: 23-01-2020
//
import {Component, OnInit, ViewChild} from '@angular/core';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {AggridBanComponent} from '../../ban/aggrid-ban/aggrid-ban.component';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {DatePipe} from '@angular/common';
import {InvrecalcostopmdServiceService} from '../invservicios/invrecalcostopmdService.service';
import {InvbusquedaService} from '../invservicios/invbusqueda.service';
import {InvrecalCostoPMD} from '../invinterfaces/invrecalCostoPMD';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { ConcontabilizadocService } from 'src/app/cont/conservicios/concontabilizadoc.service';
import { Usuario } from 'src/app/usuario';

@Component({
  selector: 'app-invrecalcularcostopmd',
  templateUrl: './invrecalcularcostopmd.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class InvrecalcularcostopmdComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  selectAll: boolean;
  showallinv: boolean;
  fechadesde: any;
  fechahoy: any;
  largo: string;
  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  defaultColRecal;
  opcion: string;
  listaArticulos: any[];
  listaArticulosAux: any[];
  
  // tabla busqueda fast
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  arregloBus: any[];
  tabla: string;
  busquedacampos: string[];
  busGRUPCODIGO: string;
  busGRUPNOMBRE: string;
  busARTCODIGO: string;
  busARTNOMBRE: string;
  filtroBusqueda: string;
  enableinputs: boolean;
  porcentaje = 0;
  spin = false; //GSRF
  
  columnDefsRecal = [
    {
      headerName: '', field: 'CHECKRECALPMD', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    {
      headerName: 'Grupo', field: 'GRUP_CODIGO', editable: false, width: 100
    },
    {
      headerName: 'Código', field: 'ART_CODIGO', editable: false, width: 200
    },
    {
      headerName: 'Nombre Artículo', field: 'ART_NOMBRE', editable: false, width: 350
    },
    {
      headerName: 'Nombre Grupo', field: 'GRUP_NOMBRE', editable: false, width: 250
    }];
  
  
  constructor(public invrecalcostopmdService: InvrecalcostopmdServiceService, private messageService: MessageService,
              private confirmationService: ConfirmationService, private busquedaSer: InvbusquedaService,
              private init: NuevoComponentService, private utilitariosService: UtilitariosService,
              private auditoriagral: AuditoriagralService, public usuario: Usuario,public contabilizadocService: ConcontabilizadocService,) {
    this.agTable();
    this.invrecalcostopmdService.costosArticulos = [];
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColRecal = {
      editable: true,
      objeto: 'maecalckardexcom'
    };
  }
  
  ngOnInit() {
    this.largo = '400';
    this.selectAll = false;
    this.showallinv = false;
    this.fechadesde = new Date();
    this.displayDialogBusquedaFast = false;
    this.busGRUPCODIGO = '';
    this.busARTCODIGO = '';
    this.listaArticulosAux = [];
    this.listaArticulos = [];
    this.enableinputs = true;
    
  }
  
  verificarFecha(): boolean {
    let comprobador = false;
    const fechahoy = new Date();
    if (this.fechadesde !== null) {
      if (this.fechadesde <= fechahoy) {
        comprobador = true;
      } else {
        comprobador = false;
        this.messageService.add({
          key: 'recalcstpmd',
          severity: 'error',
          summary: 'Recalcular Costo',
          detail: 'Fecha incorrecta'
        });
      }
    } else {
      this.messageService.add({
        key: 'recalcstpmd',
        severity: 'error',
        summary: 'Recalcular Costo',
        detail: 'Para la operación es necesaria una fecha'
      });
    }
    return comprobador;
  }
  
  contadorChecks(): number {
    let contadorCheck = 0;
    if (this.invrecalcostopmdService.costosArticulos.length !== 0) {
      this.invrecalcostopmdService.costosArticulos.map((res) => {
        if (res.CHECKRECALPMD === true) {
          contadorCheck += 1;
        }
      });
    }
    return contadorCheck;
  }
  
  seleccionarTodos() {
    
    if (this.selectAll === true) {
      if (this.invrecalcostopmdService.costosArticulos !== null) {
        this.invrecalcostopmdService.costosArticulos.map((res) => {
          res.CHECKRECALPMD = true;
          this.aggrid.refreshaggrid(this.invrecalcostopmdService.costosArticulos, this.defaultColRecal.objeto);
        });
      }
    }
    if (this.selectAll === false) {
      if (this.invrecalcostopmdService.costosArticulos !== null) {
        this.invrecalcostopmdService.costosArticulos.map((res) => {
          res.CHECKRECALPMD = false;
          this.aggrid.refreshaggrid(this.invrecalcostopmdService.costosArticulos, this.defaultColRecal.objeto);
        });
      }
    }
  }
  
  limpiartabla() {
    this.invrecalcostopmdService.costosArticulos = [];
  }
  
  mostrartodos() {
    this.limpiartabla();
    if (this.showallinv === true) {
      this.invrecalcostopmdService.obtenerCostoArticulos(this.filtroBusqueda = '').subscribe((res) => {
        this.invrecalcostopmdService.costosArticulos = res;
        this.invrecalcostopmdService.costosArticulos.map((res1) => {
          res1.CHECKRECALPMD = false;
        });
      });
    }
    if (this.showallinv === false) {
      this.limpiartabla();
    }
  }
  
  confirmarRecalCostoPMD() {
    if (this.contadorChecks() !== 0) {
      if (this.verificarFecha() === true) {
        this.confirmationService.confirm({
          message: 'Esta seguro que desea recalcular el costo',
          header: 'Recalcular el costo',
          icon: 'pi pi-exclamation-triangle',
          key: 'confirmarrecalcstpmd',
          accept: () => {
            this.recalCostoPMD();
          },
          reject: () => {
          }
        });
      }
    } else {
      this.messageService.add({
        key: 'recalcstpmd',
        severity: 'error',
        summary: 'Recalcular Costo',
        detail: 'No hay artículos que recalcular'
      });
    }
  }
  
  async recalCostoPMD() {
    this.spin = true;
    if (this.invrecalcostopmdService.costosArticulos.length !== 0) {
      this.porcentaje = 0;
      for (const res of this.invrecalcostopmdService.costosArticulos) {
        this.porcentaje = this.porcentaje + Number(100 /this.invrecalcostopmdService.costosArticulos.length);
        if (res.CHECKRECALPMD === true) {
          if (res.ART_PRODUCTOPROD === null || res.ART_PRODUCTOPROD === 'N') {//CDPJ SE AUMENTO || res.ART_PRODUCTOPROD === 'N'
            //CDPJ
            let fecha=this.fechadesde.toLocaleString().substr(0, 9)
            fecha=fecha.replace(',','')
            //CDPJ
            await this.invrecalcostopmdService.recalArtProdProm(fecha
              , res.ART_CODIGO);
              res.CHECKRECALPMD = false;

          }
          if (res.ART_PRODUCTOPROD === 'S') {
            //CDPJ
            let fecha=this.fechadesde.toLocaleString().substr(0, 9)
            fecha=fecha.replace(',','')
            //CDPJ
            await this.invrecalcostopmdService.recalArtNoProdProm(fecha
              , res.ART_CODIGO);
              res.CHECKRECALPMD = false;

          }
          this.auditoriagral.registrarAuditoria('INV_RECALCULAR_COSTOPROM', this.busGRUPCODIGO + '/' + res.ART_CODIGO + '//' +  this.fechadesde.toLocaleString().substr(0, 9), 'I',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
        }
      }

      /*this.invrecalcostopmdService.costosArticulos.map((res) => {
        if (res.CHECKRECALPMD === true) {
          if (res.ART_PRODUCTOPROD === null) {
            this.invrecalcostopmdService.recalArtProd(this.fechadesde.toLocaleString().substr(0, 9)
              , res.ART_CODIGO).subscribe(() => {
              res.CHECKRECALPMD = false;
            });
          }
          if (res.ART_PRODUCTOPROD === 'S') {
            this.invrecalcostopmdService.recalArtNoProd(this.fechadesde.toLocaleString().substr(0, 9)
              , res.ART_CODIGO).subscribe(() => {
              res.CHECKRECALPMD = false;
            });
          }
        }
      });*/
      this.mensajeExito();
      this.porcentaje = 0;
      this.invrecalcostopmdService.costosArticulos.map((res) => {
        res.CHECKRECALPMD = false;
      });
      this.aggrid.refreshaggrid(this.invrecalcostopmdService.costosArticulos, this.defaultColRecal.objeto);
    }
    this.spin = false;
  }
  
  mensajeExito() {
    this.messageService.add({
      key: 'recalcstpmd',
      severity: 'success',
      summary: 'Recalcular Costo',
      detail: 'La operación se realizó con éxito'
    });
  }
  
  
  setFocus(event) {
    switch (event.target.id) {
      
      case 'grupo': {
        this.abrirGrupos(event.target.value);
        document.getElementById('articulo').focus();
        break;
      }
      case 'articulo': {
        this.abrirArticulos(event.target.value);
        document.getElementById('desdefec').focus();
        break;
      }
      
      default: {
        break;
      }
    }
  }
  
  
  abrirGrupos(parametro) {
    
    if (parametro === null) {
      parametro = '';
    }
    
    this.opcion = 'GRUPO';
    this.types = [
      {label: 'CÓDIGO', value: 'GRUP_CODIGO'},
      {label: 'NOMBRE', value: 'GRUP_NOMBRE'}
    ];
    this.busquedacampos = ['', ''];
    this.tabla = 'INV_MAEGRUPO';
    
    this.invrecalcostopmdService.erGrupoCodnom(parametro).subscribe((res: any) => {
      if (res !== null) {
        res.map((grup) => {
          this.busGRUPNOMBRE = grup.GRUP_NOMBRE;
        });
      } else {
        this.busquedaSer.busquedaInvMaeGrupo2().subscribe((res1: any[]) => {
          this.arregloBus = res1;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
    
  }
  
  abrirArticulos(parametro) {
    
    if (parametro === null) {
      parametro = '';
    }
    
    this.opcion = 'ARTICULO';
    this.types = [
      {label: 'CÓDIGO', value: 'ART_CODIGO'},
      {label: 'NOMBRE', value: 'ART_NOMBRE'},
      {label: 'GRUPO', value: 'GRUP_CODIGO'},
      {label: 'NOMBRECORTO', value: 'ART_NOMBREC'},
      {label: 'LABORATORIO', value: 'ART_LABORATORIO'},
      {label: 'UBICACIÓN', value: 'ART_UBICACION'},
      {label: 'CODIGOBARRAS', value: 'ART_CODIGOALT1'},
      {label: 'ALT2_UBICACIÓNF', value: 'ART_CODIGOALT2'},
      {label: 'ALT3', value: 'ART_CODIGOALT3'},
      {label: 'ALT4', value: 'ART_CODIGOALT4'}
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', '', ''];
    this.tabla = 'INV_MAEARTICULO';
    
    this.invrecalcostopmdService.erArtCodNom(parametro).subscribe((res: any) => {
      if (res !== null) {
        res.map((grup) => {
          this.busARTNOMBRE = grup.ART_NOMBRE;
        });
      } else {
        this.busquedaSer.busquedaInvMaeArticulo3().subscribe((res1: any[]) => {
          this.arregloBus = res1;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
    
  }
  
  manejarSeleccion(opcion) {
    if (this.opcion === 'GRUPO') {
      this.busGRUPCODIGO = opcion.GRUP_CODIGO;
      this.busGRUPNOMBRE = opcion.GRUP_NOMBRE;
      this.displayDialogBusquedaFast = false;
    }
    if (this.opcion === 'ARTICULO') {
      this.busARTCODIGO = opcion.ART_CODIGO;
      this.busARTNOMBRE = opcion.ART_NOMBRE;
      this.displayDialogBusquedaFast = false;
    }
  }
  
  reemplazarLista() {
    
    if (this.busGRUPCODIGO !== '') {
      this.invrecalcostopmdService.obtenerCostoArticulos(this.filtroBusqueda = 'and GRUP_CODIGO LIKE \'' +
        this.busGRUPCODIGO + '\'').subscribe((res) => {
        console.log('reemplazar', res);
        if (res !== null) {
          this.limpiartabla();
          this.listaArticulos = res;
          for (const articulo of this.listaArticulos) {
            this.invrecalcostopmdService.costosArticulos.push(articulo);
          }
          this.invrecalcostopmdService.costosArticulos.map((res1) => {
            res1.CHECKRECALPMD = false;
          });
          this.aggrid.refreshaggrid(this.invrecalcostopmdService.costosArticulos, this.defaultColRecal.objeto);
        } else {
          this.messageService.add({
            key: 'recalcstpmd',
            severity: 'error',
            summary: 'Informacion',
            detail: 'El grupo seleccionado no posee articulos que agregar'
          });
        }
      });
    }
    if (this.busGRUPCODIGO === '' && this.busARTCODIGO !== '') {
      this.invrecalcostopmdService.obtenerCostoArticulos(this.filtroBusqueda = 'and art_codigo like \'' +
        this.busARTCODIGO + '\'').subscribe((res) => {
        if (res !== null) {
          this.limpiartabla();
          this.listaArticulos = res;
          for (const articulo of this.listaArticulos) {
            this.invrecalcostopmdService.costosArticulos.push(articulo);
          }
          this.invrecalcostopmdService.costosArticulos.map((res1) => {
            res1.CHECKRECALPMD = false;
          });
          this.aggrid.refreshaggrid(this.invrecalcostopmdService.costosArticulos, this.defaultColRecal.objeto);
        } else {
          this.messageService.add({
            key: 'recalcstpmd',
            severity: 'error',
            summary: 'Informacion',
            detail: 'El articulo seleccionado no posee costos que recalcular'
          });
        }
      });
    }
  }
  
  verificarExistenciaArtGrupo() {
    if (this.busGRUPCODIGO !== '') {
      this.invrecalcostopmdService.obtenerCostoArticulos(this.filtroBusqueda = 'and GRUP_CODIGO LIKE \'' +
        this.busGRUPCODIGO + '\'').subscribe((res) => {
        console.log('reemplazar', res);
        if (res === null) {
          this.messageService.add({
            key: 'recalcstpmd',
            severity: 'error',
            summary: 'Informacion',
            detail: 'El grupo seleccionado no posee articulos que agregar'
          });
        }
      });
    }
  }
  
  verificarExistenciaArt() {
    if (this.busGRUPCODIGO === '' && this.busARTCODIGO !== '') {
      this.invrecalcostopmdService.obtenerCostoArticulos(this.filtroBusqueda = 'and art_codigo like \'' +
        this.busARTCODIGO + '\'').subscribe((res) => {
        if (res === null) {
          this.messageService.add({
            key: 'recalcstpmd',
            severity: 'error',
            summary: 'Informacion',
            detail: 'El articulo a agregar no posee costos que recalcular'
          });
        }
      });
    }
  }
  
  agregarElementosTable() {
    
    if (this.invrecalcostopmdService.costosArticulos.length === 0) {
      this.reemplazarLista();
    } else {
      console.log('entro al adicionar');
      if (this.busGRUPCODIGO !== '') {
        this.verificarExistenciaArtGrupo();
        this.invrecalcostopmdService.obtenerCostoArticulos(this.filtroBusqueda = 'and (GRUP_CODIGO LIKE \'' +
          this.busGRUPCODIGO + '\' or ' + this.cadenaArticulos() + ')').subscribe((res) => {
          console.log(this.filtroBusqueda);
          console.log('adicionar', res);
          
          if (res !== null) {
            this.invrecalcostopmdService.costosArticulos = res;
            this.invrecalcostopmdService.costosArticulos.map((res1) => {
              res1.CHECKRECALPMD = false;
            });
            this.aggrid.refreshaggrid(this.invrecalcostopmdService.costosArticulos, this.defaultColRecal.objeto);
          }
        });
      }
      if (this.busGRUPCODIGO === '' && this.busARTCODIGO !== '') {
        this.verificarExistenciaArt();
        this.invrecalcostopmdService.obtenerCostoArticulos(this.filtroBusqueda = 'and (art_codigo like \'' +
          this.busARTCODIGO + '\' or ' + this.cadenaArticulos() + ')').subscribe((res) => {
          if (res !== null) {
            this.invrecalcostopmdService.costosArticulos = res;
            this.invrecalcostopmdService.costosArticulos.map((res1) => {
              res1.CHECKRECALPMD = false;
            });
            this.aggrid.refreshaggrid(this.invrecalcostopmdService.costosArticulos, this.defaultColRecal.objeto);
          }
        });
      }
    }
    
  }
  
  adicionarLista() {
    if (this.showallinv === true) {
      this.showallinv = false;
      this.agregarElementosTable();
    } else {
      this.agregarElementosTable();
    }
    
  }
  
  cadenaArticulos(): string {
    
    let strCadenaArt = 'art_codigo in (';
    let indiceLista;
    let sw = 0;
    
    for (indiceLista = 0; indiceLista < this.invrecalcostopmdService.costosArticulos.length; indiceLista++) {
      sw = 1;
      if (indiceLista === 0) {
        strCadenaArt = strCadenaArt + '\'' + this.invrecalcostopmdService.costosArticulos[indiceLista].ART_CODIGO + '\'';
      } else {
        strCadenaArt = strCadenaArt + ',\'' + this.invrecalcostopmdService.costosArticulos[indiceLista].ART_CODIGO + '\'';
      }
    }
    if (sw === 0) {
      strCadenaArt = '';
      
    } else {
      strCadenaArt = strCadenaArt + ')';
    }
    return strCadenaArt;
  }
  
  async manejarSenales(valor) {
    if (valor !== 'Nuevo' && valor !== 'Cancelar'
      && valor !== 'Primero' && valor !== 'Anterior' && valor !== 'Siguiente' && valor !== 'Ultimo'
      && valor !== 'Buscar' && valor !== 'Imprimir' && valor !== 'AsientoVer' && valor !== 'Salir') {
      // Verifica el periodo contable
      const dia = this.fechadesde.getDate();
      const mes = this.fechadesde.getMonth();
      const anio = this.fechadesde.getFullYear();


      if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
        this.messageService.add({
          key: 'recalcstpmd',
          severity: 'info',
          summary: 'Información',
          detail: 'Error, fecha debe de estar dentro del período contable'
        });
        return;
      }
    }

    if (valor === 'Visto') {
      const dia = this.fechadesde.getDate();
      const mes = this.fechadesde.getMonth();
     const anio = this.fechadesde.getFullYear();
      const errperio = await this.contabilizadocService.verificarPeriodoContablenew(dia, mes, anio);
      console.log('4654546++++',errperio)
      if(errperio[0].CONTADOR === 0){
        this.messageService.add({
          key: 'precal',
          severity: 'warn',
          summary: 'Búsqueda',
          detail: 'La Fecha debe estar dentro del periodo contable.'
        });
        return;
      }
      this.confirmarRecalCostoPMD();
    }
    if (valor === 'Salir') {
      this.salir();
    }
  }
  
  salir() {
    this.init.tabs.splice(this.init.tabindex, 1);
  }
  
}
