<p-confirmDialog [transitionOptions]="'0ms'" key="NomMaePerDialog" title="Confirmar Acción"
                 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 99%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
						 [botonRegresar]="btnRegresar" [barraBotonesCRUD]="false"
						 [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true"
						 (signal)="manejarSenales($event)"></app-invmaebarra>
	</div>

</div>
<div class="layout-config">
	<div class="layout-config-content-wrapper">
		<a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones()">
			<i class="pi pi-bars" style="cursor: pointer"></i>
		</a>
		<p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
				   [dismissible]="true" [showCloseIcon]="false">
			<br>
			<br>
			<br>
			<p-tabView>
				<p-tabPanel header="Acciones">
					<div class="p-grid">
						<div class="p-col-12">
							<i class="pi pi-caret-right archivoOpc"></i><span (click)="agregarPeriodo()"
																			  class="archivoOpc">Genera periodos Automáticos</span>
						</div>
					
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-sidebar>
	</div>
</div>
<div style="overflow: hidden" class="p-col-12">

	<p-tabView>
		<p-tabPanel header="Periodo">
			<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 270px)'}">
				<p-toast [style]="{marginTop: '30px'}" position="top-center" key="nommaepertoast"></p-toast>
				<div class="divgrups">
					<div class="p-grid" style="margin-top: 10px; margin-bottom: 10px">
						<div class="p-col-1">
							<span>Año:</span>
						</div>
						<div class="p-col-1">
							<input class="frm"  type="text"  [(ngModel)]="anio" (keydown.enter)="cargar()" style="text-align: right;">
						</div>
					</div>
				</div>
				<app-aggridsaci [width]=""
								[height]="largo"
								[enableSorting]="false"
								[enableFilter]="true"
								[rowData]="nommaeperiodo"
								[frameworkComponents]="frameworkComponents"
								[animateRows]="true"
								[rowSelection]="rowSelection"
								[columnDefs]="columnDefMaePeriodo"
								[defaultColDef]="defaultColDefMaePeriodo"
								[rowStyle]="rowStyle"
								[singleClickEdit]="false"
								[botones]="false"
								(selected)=selectMaePeriodo($event)
								(cambios)="cambio($event)"

				></app-aggridsaci>
			</p-scrollPanel>
		</p-tabPanel>
	</p-tabView>
</div>
<p-dialog header="Períodos" [(visible)]="displayDialogPerido"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  (onHide)="onHideAutorizacion()"
		  [style]="{width: '40vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="rubDialog"></p-toast>

	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
						<div class="p-col-2"></div>
						<div class="p-col-2">
							<span>Año:</span>
						</div>
						
						<div class="p-col-4">
							<input  pInputText  type="text"  [(ngModel)]="anioD" (keydown.enter)="cargar()" style="text-align: right; width: 100%">
						</div>
						<div class="p-col-4">	
						</div>
				</div>
			</div>
			<div class="p-col-12" >
				<div class="p-grid" >
				<div class="p-col-4">	
				</div>
				<div class="p-field-radiobutton" style="display: flex; flex-flow: row nowrap;">
					
							<p-radioButton value="MEN30D" [(ngModel)]="valor"></p-radioButton>
							<div style="margin-left: 5px;">
							<label>Mensual (30 días)</label>
						</div>
					
				</div>	
				<div class="p-col-4">	
				</div>
			</div>
			</div>
			<div class="p-col-12" >
				<div class="p-grid" >
				<div class="p-col-4">	
				</div>
				<div class="p-field-radiobutton" style="display: flex; flex-flow: row nowrap;">
					<p-radioButton value="MENSREAL" [(ngModel)]="valor" ></p-radioButton>
					<div style="margin-left: 5px;">
						<label>Mensual Real</label>
					</div>
				</div>	
				<div class="p-col-4">	
				</div>
			</div>
			</div>
			<div class="p-col-12" >
				<div class="p-grid" >
				<div class="p-col-4">	
				</div>
				<div class="p-field-radiobutton" style="display: flex; flex-flow: row nowrap;">
					<p-radioButton value="QUINDIAS" [(ngModel)]="valor" ></p-radioButton>
					<div style="margin-left: 5px;">
					<label >Quincenal</label>
				</div>
				</div>	
				<div class="p-col-4">	
				</div>
			</div>
			</div>
			<div class="p-col-12" >
				<div class="p-grid" >
					<div class="p-col-2">	
					</div>
					<div class="p-col-4">
						<button  pButton class="aceptar" style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100% " (click)="opcionCancelar()">Cancelar</button>	
					</div>
					<div class="p-col-4">
						<button  pButton class="aceptar" style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100% " (click)=" opcionAceptar()">Aceptar</button>	
					</div>
					<div class="p-col-2">	
					</div>
					
				</div>
			</div>
		</div>
	</div>
</p-dialog>