import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SacimaeperiodoService } from '../conservicios/sacimaeperiodo.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';//CDPJ

@Component({
  selector: 'app-sacimaeperiodo',
  templateUrl: './sacimaeperiodo.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class SacimaeperiodoComponent implements OnInit {

  //caja de fecha
  dateInit: any;
  dateFinal: any;

  //variable habilitacion
  inputDes: boolean;

  constructor(private periodoService: SacimaeperiodoService, private datePipe: DatePipe,
              private message: MessageService, private confirmationService: ConfirmationService,
              private init: NuevoComponentService,
              private auditoriaGralService: AuditoriagralService,) { }

  ngOnInit(): void {
    this.dateInit = new Date();
    this.dateFinal = new Date();
    this.inputDes = false;

    this.cargarDatos();
    
  }

  manejarSenales(valor){
    if(valor === 'Guardar' ){
      this.confirmarPeriodo();
    }
    if(valor === 'Cancelar'){
      this.cargarDatos();
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
    
  }

  cargarDatos(){
    this.periodoService.loadData().subscribe((res)=>{
      for (const result of res){
        this.dateInit = new Date(result.PER_FECHAINICIAL);
        this.dateFinal = new Date(result.PER_FECHAFINAL);
      }
    })
  }

  guardarPeriodo (){
    const inicio = this.datePipe.transform(this.dateInit, 'dd/MM/yyyy');
    const final = this.datePipe.transform(this.dateFinal, 'dd/MM/yyyy')
    this.periodoService.guardaPeriodo(inicio,final).subscribe(
    );
    this.message.add({
      key: 'Periodo',
      severity: 'success',
      summary: 'Periodo',
      detail: 'El Periodo Contable se guardo correctamente'
    });
    //CDPJ
    this.auditoriaGralService.registrarAuditoria('cont_maeperiodo','PeriodoContable-'+ inicio+'-'+final, 'A',
    '', '01', '', '', '', '').toPromise().then(() => {
    });
    //CDPJ
  }

  confirmarPeriodo(){
    this.confirmationService.confirm({
      message: 'Está seguro de guardar el Periodo Contable?',
      header: 'Periodo Contable',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarPeriodo',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.guardarPeriodo();
      },
      reject: () => {
      }
    });
  }

}
