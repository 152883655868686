<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="segencviocorreo"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="segENCENVIOCOR" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 98%">
    <div class="p-col-12">
        <app-invmaebarra (signal)="manejarSenales($event)" [botonNuevo]="btnnuevo" [botonGuardar]="btnguardar"
            [botonBorrar]="btneliminar" [botonRegresar]="false" [barraBotonesDesplaz]="true"
            [botonBuscar]="false" [botonBuscarPermanente]="true"
            [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true"></app-invmaebarra>
    </div>
</div>

<p-tabView [(activeIndex)]="index" (onChange)="changeTabs()" [style]="{'width':'98%'}">
    <p-tabPanel header="General">
        <p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 270px)'}">
            <div class="contorno" id="contorno">
                <div class="divgrups">
                    <div class="p-grid">
                        <div class="p-col-6">
                            <div class="p-grid">
                                <div class="p-col-12" style="margin-top: 10px;">
                                    <div class="p-grid">
                                        <div class="p-col-2">
                                            <span>Nombre:</span>
                                        </div>
                                        <div class="p-col-3">
                                            <label for="ENCENVIOCOR_CODIGO"></label>
                                            <input class="frm" id="ENCENVIOCOR_CODIGO" type="text" pInputText disabled [(ngModel)]="encenviocorreo.ENCENVIOCOR_CODIGO"
                                                style="color: blue; font-weight: bold;" autocomplete="off">
                                        </div>
                                        
                                        <div class="p-col-6">
                                            <label for="ENCENVIOCOR_NOMBRE"></label>
                                            <input class="frm" id="ENCENVIOCOR_NOMBRE" type="text" pInputText  [(ngModel)]="encenviocorreo.ENCENVIOCOR_NOMBRE"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                        </div>
                                    </div>
                                </div>
                                
                                <!--LINEA 2-->
                                <div class="p-col-12" style="margin-top: -10px;">
                                    <div class="p-grid">
                                        <div class="p-col-2">
                                            <span>Formulario:</span>
                                        </div>
                                        <div class="p-col-3">
                                            <label for="ENVIOCORVAR_CODIGO"></label>
                                            <input class="frm" id="ENVIOCORVAR_CODIGO" type="text" pInputText [(ngModel)]="encenviocorreo.ENVIOCORVAR_CODIGO"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"     
                                            autocomplete="off">
                                        </div>
                                        <div class="p-col-1">
                                            <button type="button"
                                                        style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                        (click)="busquedaformulario(encenviocorreo.ENVIOCORVAR_CODIGO)"></button>
                                        </div>
                                        <div class="p-col-2">
                                            <span>Nombre Formulario:</span>
                                        </div>
                                        <div class="p-col-3">
                                            <label for="ENVIOCORVAR_NOMBRE"></label>
                                            <input class="frm" id="ENVIOCORVAR_NOMBRE" type="text" pInputText disabled
                                                [(ngModel)]="encenviocorreo.ENVIOCORVAR_NOMBRE" style="color: blue; font-weight: bold;"
                                                autocomplete="off">
                                        </div>
                                    </div>
                                      
                                </div>
                                <!--LINEA 3-->
                                <div class="p-col-12" style="margin-top: -10px;">
                                    <div class="p-grid">
                                        <div class="p-col-2">
                                            <span>Tipo Reporte:</span>
                                        </div>
                                        <div class="p-col-3">
                                        <p-dropdown [options]="cmbtipodereportes" [style]="{'width':'100%'}"
                                        optionLabel="name" [panelStyle]="{'width':'110%'}"
                                        (onChange)="busqtiporegimen($event)"
										[(ngModel)]="selectcombtiporeporte"
										
							            ></p-dropdown>
                                        </div>
                                    </div> 
                                </div>
                                <!--LINEA 4-->
                                <div class="p-col-12" style="margin-top: -10px;">
                                    <div class="p-grid">
                                        <div class="p-col-2">
                                            <span>Reporte:</span>
                                        </div>
                                        <div class="p-col-1">
                                            <label for="ENCENVIOCOR_REPCODIGO"></label>
                                            <input class="frm" id="ENCENVIOCOR_REPCODIGO" type="text" pInputText [disabled]="false"
                                                [(ngModel)]="encenviocorreo.ENCENVIOCOR_REPCODIGO"
                                                (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                                [disabled]="boolreporte"
                                                autocomplete="off">
                                        </div>
                                        <div class="p-col-1">
                                            <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busquedasreporte(encenviocorreo.ENCENVIOCOR_REPCODIGO)"
                                                [disabled]="boolreporte"></button>
                                        </div>
                                        <div class="p-col-1">
                                            
                                        </div>
                                        <div class="p-col-5">
                                            <label for="ENCENVIOCOR_REPNOMBRE"></label>
                                            <input class="frm" id="ENCENVIOCOR_REPNOMBRE" type="text" pInputText disabled
                                                style="color: blue;"
                                                [(ngModel)]="encenviocorreo.ENCENVIOCOR_REPNOMBRE"
                                                autocomplete="off">
                                        </div> 
                                    </div>                                   
                                </div>
                                <!--LINEA 5-->
                                <div class="p-col-12" style="margin-top: -10px;">
                                    <div class="p-grid">
                                        <div class="p-col-2">
                                            <span>Descripción:</span>
                                        </div>
                                        <div class="p-col-6">
                                            <label for="ENCENVIOCOR_DESCRIPCION"></label>
                                            <input class="frm" id="ENCENVIOCOR_DESCRIPCION" type="text" pInputText 
                                                [(ngModel)]="encenviocorreo.ENCENVIOCOR_DESCRIPCION" 
                                                (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                                autocomplete="off">
                                        </div>
                                    </div>                      
                                </div>
                                <!--LINEA 6-->
                                <div class="p-col-12" style="margin-top: -10px;">
                                    <div class="p-grid">
                                        <div class="p-col-2">
                                            <span>Nombre del archivo:</span>
                                        </div>
                                        <div class="p-col-6">
                                            <label for="ENCENVIOCOR_NOMARCHIVO"></label>
                                            <input class="frm" id="ENCENVIOCOR_NOMARCHIVO" type="text" pInputText 
                                                 [(ngModel)]="encenviocorreo.ENCENVIOCOR_NOMARCHIVO"
                                                 (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                                autocomplete="off">
                                        </div> 
                                    </div>                  
                                </div>
                                <!--LINEA 7-->
                                <div class="p-col-12" style="margin-top: -10px;">
                                    <div class="p-grid">
                                        <div class="p-col-2">
                                            <span>Asunto:</span>
                                        </div>
                                        <div class="p-col-6">
                                            <label for="ENCENVIOCOR_ASUNTO"></label>
                                            <input class="frm" id="ENCENVIOCOR_ASUNTO" type="text" pInputText 
                                                 [(ngModel)]="encenviocorreo.ENCENVIOCOR_ASUNTO"
                                                 (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                                autocomplete="off">
                                        </div> 
                                    </div>                  
                                </div>
                                <!--LINEA 8-->
                                <div class="p-col-12" style="margin-top: -10px;">
                                    <div class="p-grid">
                                        <div class="p-col-2">
                                            <span>Remite:</span>
                                        </div>
                                        <div class="p-col-6">
                                            <label for="ENCENVIOCOR_REMITENTE"></label>
                                            <input class="frm" id="ENCENVIOCOR_REMITENTE" type="text" pInputText 
                                                [(ngModel)]="encenviocorreo.ENCENVIOCOR_REMITENTE"
                                                (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                                autocomplete="off">
                                        </div>
                                    </div>                          
                                </div>
                                <!--LINEA 9-->
                                <div class="p-col-12" style="margin-top: -10px;">
                                    <div class="p-grid">
                                        <div class="p-col-2">
                                            <span>Cuerpo01:</span>
                                        </div>
                                        <div class="p-col-6">
                                            <label for="ENCENVIOCOR_CUERPO1"></label>
                                            <textarea style="width: 100%; height: 100px" id="ENCENVIOCOR_CUERPO1" type="text" pInputTextarea
                                            [(ngModel)]="encenviocorreo.ENCENVIOCOR_CUERPO1" (keydown)="botonesmodificar()" autocomplete="off"></textarea>
                                        </div>
                                    </div>                         
                                </div>
                                <!--LINEA 10-->
                                <div class="p-col-12" style="margin-top: -10px;">
                                    <div class="p-grid">
                                        <div class="p-col-2">
                                            <span>Cuerpor02:</span>
                                        </div>
                                        <div class="p-col-6">
                                            <label for="ENCENVIOCOR_CUERPO2"></label>
                                            <textarea style="width: 100%; height: 100px" id="ENCENVIOCOR_CUERPO2" type="text" pInputTextarea
                                            [(ngModel)]="encenviocorreo.ENCENVIOCOR_CUERPO2" (keydown)="botonesmodificar()" autocomplete="off"></textarea>
                                        </div>
                                    </div>                         
                                </div>
                                <!--LINEA 11-->
                                <div class="p-col-12" style="margin-top: -10px;">
                                    <div class="p-grid">
                                        <div class="p-col-2">
                                            <span>Pie de correo:</span>
                                        </div>
                                        <div class="p-col-6">
                                            <label for="ENCENVIOCOR_PIECORREO"></label>
                                            <textarea style="width: 100%; height: 100px" id="ENCENVIOCOR_PIECORREO" type="text" pInputTextarea
                                            [(ngModel)]="encenviocorreo.ENCENVIOCOR_PIECORREO" (keydown)="botonesmodificar()" autocomplete="off"></textarea>
                                        </div>
                                    </div>                         
                                </div>
                                <!--LINEA 12-->
                                <div class="p-col-12" style="margin-top: -10px;">
                                    <div class="p-grid">
                                        <div class="p-col-2">
                                        </div>
                                        <div class="p-col-3">
                                            <p-checkbox label="Inlcuir logo" binary="true"
                                                (click)="botonesmodificar()" [(ngModel)]="chkincluyelogo" >
                                            </p-checkbox>
                                        </div>
                                        <div class="p-col-3">
                                            <p-checkbox label="Incluir firma" binary="true"
                                                (click)="botonesmodificar()" [(ngModel)]="chkincluyefirma" >
                                            </p-checkbox>
                                        </div> 
                                    </div>                         
                                </div>
                            </div>
                        </div>
                        <!-- TABLA DE VARIABLES -->
                        <div class="p-col-6">
                            <div class="p-grid">                                
                                <div class="p-col-12" style="margin-top: -15px;">
                                    <app-aggridsaci [width]="'550'" [height]="'650'" [enableSorting]="true" [enableFilter]="true"
                                        [rowData]="enviocorreovar" [frameworkComponents]="frameworkComponents" [animateRows]="true"
                                        [rowSelection]="rowSelection" [columnDefs]="columnDefsEncioCorreoVar"
                                        [defaultColDef]="defaultColEnvcioCorreoVar" [rowStyle]="rowStyle" [botones]="false"
                                        [singleClickEdit]="false">
                                    </app-aggridsaci>
                                </div>
                                <!--LINEA 2-->
                                <div class="p-col-12" style="margin-top: -5px;">
                                    <div class="p-grid">                            
                                        <div class="p-col-3">
                                            <span style="font-weight: bold">Vista previa</span>
                                        </div>     
                                    </div>
                                </div>
                                <!--LINEA 3-->
                                <div class="p-col-12" style="margin-top: -5px;">
                                    <div class="p-grid">                            
                                        <div class="p-col-12">
                                            <img *ngIf="booleanslogo" src="{{comlogo}}" width=15%
                                            style=" position:absolute;  margin-top: 5px; right:-100 ; pointer-events:none;">
                                            <textarea style="z-index: 1; width: 90%; height: 500px" id="vistaprevia" type="text" pInputTextarea disabled
                                            [(ngModel)]="vistaprevia"  autocomplete="off"></textarea>


                                        </div>                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <!-- <app-aggridsaci 
            [width]=""
            [height]="largo" 
            [enableSorting]="true" 
            [enableFilter]="true"
            [rowData]="cxctrncobranzasService.trncobranza" 
            [frameworkComponents]="frameworkComponents"
            [animateRows]="true" 
            [rowSelection]="rowSelection" 
            [columnDefs]="columnDefsCxcTrnCob"
            [defaultColDef]="defaultCxcTrnCob" 
            [rowStyle]="rowStyle" 
            [singleClickEdit]="false" 
            [botones]="false"
            [mostrarGuardar]="false" 
            [botonesAdicionales]="false" 
            [comboOpciones]=""
            (selected)="seleccionItem($event)" 
            [tipodetalle]="">
            </app-aggridsaci> -->
        </p-scrollPanel>
    </p-tabPanel>

</p-tabView>
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloBus" [tabla]="tabla" [where]="where"
					 [busquedaParams]="busquedacampos"
                     (opcionSeleccionada)="manejarSeleccion($event)"
                     [consulta]="consulta"></app-busquedadlg>
</p-dialog>