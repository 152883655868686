import { AfterContentInit, AfterViewInit, Component, NgZone, OnInit, ViewChild } from '@angular/core';

import { UsuarioEsquema } from '../usuarioEsquema';
import { Usuario } from '../usuario';
import { UsuarioDescargas } from '../usuarioDescargas';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BackendService } from '../servicios/backend.service';
import { IpServiceService } from '../ip-service.service';
import { UtilitariosService } from '../advantage/advantageservices/utilitarios.service';
import { ConfInicialesService } from '../servicios/conf-iniciales.service';

@Component({
  selector: 'app-login',
  templateUrl: './app.loginmarca.component.html',
  providers: [MessageService],
  styleUrls: ['./app.loginmarca.component.css'],
})

export class AppLoginMarcaComponent implements OnInit {
  ipFrontend = '127.0.0.1';
  ipAddress = '127.0.0.1';
  hostname = 'localhost';
  iplocal = '192.168.1.1';
  mac = '00:00:00:00:00:00';
  domain = 'domain';

  public tipologin: any;
  empresasel: any;
  empresas: any;
  empresasContador: any;
  url: any;
  way: any;
  urlreporte: any;
  usuario: string;
  pass: string;
  usuariores: any;
  public loginsel = 0; // permite saber que tipo de login es 0: ninguno; 1: Descargas, 2: Soy Empresa y 3: Soy contador
  public loginactivo = true;

  //  para el login de contador
  idcontador: string; // el usuario del contador para desplegar las empresas que tiene
  passcontador: string; // la contraseña del contador
  existecontador = false;

  // para login descargas
  public idDescargas: string;
  public passDescargas: string;

  // para la captcha
  public captchaexiste = false;
  public secretKeyCaptcha = '';
  private token = '';

  //test empresas end point
  resultado = '';
  ruc = '';
  servicio = '';
  user = '';

  imagenes: any[];
  displayDescargas: boolean;
  displayEmpresa: boolean;
  displayEndpoint: boolean;
  displayToken: boolean;
  private ipRegex = new RegExp(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/);

  tokenusuario = '';
  auxtoken = '';

  items: MenuItem[];
  activeItem: MenuItem;

  constructor(
    private http: HttpClient,
    private ip: IpServiceService,
    private usuarioEsquema: UsuarioEsquema,
    public router: Router,
    public usuarioGlob: Usuario,
    public usuarioDescargas: UsuarioDescargas,
    public service: MessageService,
    public backService: BackendService,
    private utilitariosService: UtilitariosService,
    private confirmationService: ConfirmationService,
    private zone: NgZone,
    private confIniciales: ConfInicialesService
  ) {
    this.tipologin = [
      {
        id: 1,
        nombre: 'Descargas'
      },
      {
        id: 2,
        nombre: 'Soy Empresa'
      },
      {
        id: 3,
        nombre: 'Soy Contador'
      }
    ];
    this.imagenes = [
      {
        image: 'banner1.png',
        name: '1'
      },
      {
        image: 'banner2.png',
        name: '2'
      },
      {
        image: 'banner3.png',
        name: '3'
      }
    ];
    this.imagenes = [
      {
        image: 'banner1.jpg',
        name: '1'
      },
      {
        image: 'banner2.jpg',
        name: '2'
      },
      {
        image: 'banner3.jpg',
        name: '3'
      }
    ];
  }

  async getIP() {
    try {
      const res: any = await this.ip.getIPAddresshttps();
      if (res.ip !== undefined) {
        this.ipFrontend = res.ip;
      }
    } catch (e) {
    }

    try {
      const data = await this.utilitariosService.hostname();
      console.log(data);
      this.hostname = data['hostname'];
      this.utilitariosService.saveStorageIp(this.hostname, this.ipFrontend);
    } catch (e) {
    }
  }

  /*GetComputerName() {
    console.log('GetComputerName');
    try {
      var network = new ActiveXObject('WScript.Network');
      console.log(network.computerName);
    } catch (e) {
      console.log(e);
    }
  }*/

  ngOnInit(): void {
    // this.displayToken = true;
    this.usuarioEsquema.clearStorage();
    this.getIP();
    this.utilitariosService.saveStorageMarca('BLANCA', 101);
    // this.utilitariosService.saveStorageMarca('BLANCA', 101);
    // this.utilitariosService.saveStorageMarca('BLANCA', 102);

    this.backService.getUrl((resp: any) => {
      this.url = resp;
      this.way = sessionStorage.getItem('way');
      this.getServer(this.way + '/esquemas').subscribe(respuesta => {
        console.log(respuesta.length);
        this.empresas = respuesta;
        // this.empresasel = this.empresas[0];
      }, error => {
        this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'Refresque la página' });
      });
      // resuelve si se usa captcha
      this.cargarCaptcha();
    });

    this.displayDescargas = false;
    this.displayEmpresa = false;

    this.items = [
      {
        label: 'Ingresar',
        icon: 'pi pi-user',
        command: (event) => {
          this.openLogin(2);
        }
      }, {
        label: 'Descargar Documentos',
        icon: 'pi pi-user',
        command: (event) => {
          this.openLogin(1);
        }
      }, {
        label: 'Aula Virtual',
        icon: 'pi pi-user',
        command: (event) => {
          this.openLogin(3);
        }
      }, {
        label: 'Soporte Técnico',
        icon: 'pi pi-user',
        command: (event) => {
          this.openLogin(4);
        }
      },
    ];

    this.activeItem = this.items[0];
  }


  showResponse(event: { response: string; }) {
    console.log(event.response);
    this.token = event.response;
  }

  getServer(peticion: string): Observable<any> {
    return this.http.get(peticion);
  }

  getlogotipo() {
    this.utilitariosService.getLogotipo().subscribe(data => {
      for (const rs of data) {
        sessionStorage.setItem('logotipo',rs.COM_LOGO);
      }      
    });
  }

  // login para Empresas
  async login() {
    if (this.empresasel === undefined) {
      this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'Digite su Empresa' });
      this.back();
    } else {
      if (this.usuario === undefined) {
        this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'Ingrese su usuario y contraseña' });
        this.back();
      } else {
        // se verifica si existe la empresa
        this.empresasel = this.empresasel.toUpperCase();
        const auxempresa = this.empresas.find((emp: { EMPRESA_USUARIO: any; }) => emp.EMPRESA_USUARIO === this.empresasel);
        // se verifica la empresa
        if (auxempresa === undefined) {
          this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'La empresa no existe' });
          this.back();
        } else {
          this.usuarioEsquema.esquema = auxempresa.EMPRESA_ESQUEMA;
          this.usuarioEsquema.usuario = auxempresa.EMPRESA_USUARIO;
          this.usuarioEsquema.password = auxempresa.EMPRESA_USUARIO;
          this.usuarioEsquema.saveStorage();
          this.http.post(this.url + '/usuario', {
            usuario: this.usuario,
            password: this.pass,
            esquema: this.usuarioEsquema.esquema,
            key: this.token // verifica la captcha enviada si esta desactivada no lo toma en cuenta
          }, { withCredentials: true }).subscribe(async respuesta => {
            // se obtiene el logotipo de la empresa
            const aux: any = respuesta;
            if (aux.length === 0) {
              // this.snackbar.open('Usuario o contraseña incorrecta', 'cerrar', {duration: 3000});
              this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'Usuario o Contraseña Incorrecta' });
              this.back();
            } else {
              this.usuariores = respuesta;
              let enusu = 0;
              let usuarioactivo = 'N';
              for (const i of this.usuariores) {
                enusu = i.USU_ENUSO;
                this.usuarioGlob.login = true;
                this.usuarioGlob.nombre = i.USUNOMBRE;
                this.usuarioGlob.apellido = i.USUAPELLIDO;
                this.usuarioGlob.identificacion = i.USUIDENTIFICACION;
                this.usuarioGlob.ruc = i.USURUCI;
                this.usuarioGlob.usuperfil = i.USUPERFIL;
                this.usuarioGlob.codperfil = i.PERFIL_CODIGO;
                this.usuarioGlob.ipfrontend = i.USU_IPPUBLICA;
                this.usuarioGlob.autenticacion = i.USUAUTENTICACION;
                this.usuarioGlob.saveStorage();
                const data = await this.http.post(this.url + '/login', {
                  usuario: this.usuarioGlob.identificacion,
                  nombre: this.usuarioGlob.nombre
                }).toPromise();

                this.utilitariosService.updateDatosIps(this.ipFrontend, this.hostname, 1, this.usuario);
                this.utilitariosService.timeLeft = 5 * 60;
                // const r: any = data;
                // sessionStorage.setItem('jjwwtt', r.token);
                // this.utilitariosService.updateDatosIps2(this.ipFrontend, this.hostname, 1, this.usuario);
              }
              // this.omitirConformacion();
              // se realiza consulta a la tabla sacijava para verificar si esta activo el usuario o si es nuevo
              this.http.post(this.way + '/sesiones/533b2fe38ee4b00', {
                USUIDENTIFICACION: this.usuario,
                ESQUEMA: this.usuarioEsquema.usuario
              }, { withCredentials: true }).subscribe(respuestaactivo => {
                const auxact: any = respuestaactivo;
                if (auxact === 'usuario nuevo') {
                  // this.omitirConformacion();
                  // this.sacianexLoginTest();
                  this.sacianexLogin();
                } else {
                  usuarioactivo = auxact[0].ACTIVO;
                  if (usuarioactivo === 'S') {
                    this.confirmationService.confirm({
                      message: 'Existe una sesión activa con el usuario ' + this.usuarioGlob.nombre + ' '
                        + this.usuarioGlob.apellido + ' en "' + this.usuarioGlob.ipfrontend + '" Host "' + this.hostname +
                        '", está seguro de cerrar la sesión?',
                      header: 'Pregunta',
                      icon: 'pi pi-exclamation-triangle',
                      key: 'confirmlogin',
                      acceptLabel: 'Si',
                      rejectLabel: 'No',
                      accept: () => {
                        this.utilitariosService.enviarCorreoToken(this.empresasel, this.usuario).subscribe(data => {
                          if (data === false) {
                            this.service.add({ severity: 'error', summary: 'Error', detail: 'El usuario no existe' });
                            return;
                          } else {
                            if (this.usuario === 'admin' || this.usuarioGlob.autenticacion === 1) {
                              this.auxtoken = 'usuadmin'
                              this.displayToken = true;
                            } else {
                              this.auxtoken = 'sesionactiva'
                              this.displayToken = true;
                            }
                          }
                        });
                      }
                    });
                  } else {
                    // this.omitirConformacion();
                    // this.sacianexLoginTest();
                    this.sacianexLogin();
                  }
                }
              }, error => {
                this.service.add({ severity: 'error', summary: 'Error /activosesion', detail: error.error });
                // this.snackbar.open('Error al conectarse a la base de datos', 'cerrar', {duration: 3000});
              });
            }
          }, error => {
            this.service.add({ severity: 'error', summary: 'Error de base de datos', detail: error.error });
            // this.snackbar.open('Error al conectarse a la base de datos', 'cerrar', {duration: 3000});
          });
        }
      }
    }
  }

  loginwithEnter(event: any) {
    // console.log(event.keyCode);
    if (event.keyCode === 13) {
      this.login();
    }
  }

  loginwithEnterDescargas(event: any) {
    // console.log(event.keyCode);
    if (event.keyCode === 13) {
      this.loginDescargas();
    }
  }

  iniciaElSistema() {
    this.router.navigate(['']);
  }

  async enterToken(event: any) {
    if (event.keyCode === 13) {
      const resp = await this.utilitariosService.validarToken(this.tokenusuario, this.usuarioGlob.identificacion);
      if (resp === true) {
        if (this.auxtoken === 'usuadmin') {
          this.auxtoken = "tokenverificado";
        }
        // this.omitirConformacion();
        // this.sacianexLoginTest();
        this.sacianexLogin();
      } else {
        this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'Token ingresado no coincide. Verifique su correo' });
      }
    }
  }

  // Login para Descargas
  loginDescargas() {
    if (this.idDescargas === undefined) {
      this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'Ingrese su ID y contraseña' });
      // this.snackbar.open('Ingrese su usuario y contraseña', 'cerrar', {duration: 3000});
    } else {
      this.usuarioEsquema.esquema = 'SACIANEX';
      this.usuarioEsquema.usuario = 'SACIANEX';
      this.usuarioEsquema.password = 'SACIANEX';
      this.usuarioEsquema.saveStorage();

      this.http.post(this.url + '/usuarioDescargas', {
        usuario: this.idDescargas,
        password: this.passDescargas,
        esquema: this.usuarioEsquema,
        key: this.token // verifica la captcha enviada si esta desactivada no lo toma en cuenta
      }).subscribe(respuesta => {
        // console.log(respuesta);
        const aux: any = respuesta;
        if (aux.length === 0) {
          // this.snackbar.open('Usuario o contraseña incorrecta', 'cerrar', {duration: 3000});
          this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'ID o Contraseña Incorrecta' });
        } else {
          this.usuariores = respuesta;
          for (const i of this.usuariores) {
            this.usuarioDescargas.login = true;
            this.usuarioDescargas.CLI_RUCIDE = i.CLI_RUCIDE;
            this.usuarioDescargas.CLI_NOMBRE = i.CLI_NOMBRE;
            this.usuarioDescargas.CLI_CORREO = i.CLI_CORREO;
            this.usuarioDescargas.saveStorage();
          }

          // console.log('usuario correto');
          this.router.navigate(['descargas']);
          const el = document.documentElement;
          // el.requestFullscreen(); //enra en modo pantalla completa
        }

      },
        error => {
          this.service.add({ severity: 'error', summary: 'Error de base de datos', detail: error.error });
          // this.snackbar.open('Error al conectarse a la base de datos', 'cerrar', {duration: 3000});
        });
    }
  }

  openLogin(opcion: number) {
    this.loginsel = opcion;
    this.loginactivo = false;
    if (opcion === 1) {
      this.displayDescargas = true;
    }
    if (opcion === 2) {
      this.displayEmpresa = true;
    }
    if (opcion === 3) {
      const url = 'https://www.saci-erp.com/aula-virtual';
      window.open(url);
    }
    if (opcion === 4) {
      const url = 'http://www.saci-soporte.com';
      window.open(url);
    }
    if (opcion === 5) {
      this.displayEndpoint = true;
    }
    if (document.getElementById('login-panel') !== null) {
      document.getElementById('login-panel').classList.add('login-panel-hide');
    }
  }

  back() {
    this.loginactivo = true;
    this.loginsel = 0;
    this.existecontador = false;
    this.displayDescargas = false;
    this.displayEmpresa = false;
    // resuelve si se usa captcha
    this.captchaexiste = false;
    this.cargarCaptcha();
    document.getElementById('login-panel').classList.remove('login-panel-hide');
  }

  cargarCaptcha() {
    this.getServer(this.url + '/captcha').subscribe(cap => {
      if (cap.captcha === true) {
        this.captchaexiste = true;
        this.secretKeyCaptcha = cap.secretKeyCliente;
      } else {
        this.captchaexiste = false;
      }
    });
  }

  loginSoyContador(event: any) {
    // console.log(event.keyCode);
    if (event.keyCode === 13) {
      // se realiza un metodo para comprobar si existe el contador y cargar los datos al formulario
      this.loginContador();
    }
  }

  loginContador() {
    // compueba si existe el contador en la base de datos y muestra las empresa.
    this.http.post(this.url + '/contador', {
      usuario: this.idcontador,
      pass: this.passcontador

    }).subscribe(respuesta => {
      console.log(respuesta);
      const aux: any = respuesta;
      if (aux === null) {
        // this.snackbar.open('Usuario o contraseña incorrecta', 'cerrar', {duration: 3000});
        this.existecontador = false;
        this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'ID o contraseña del Contador Incorrecta' });
      } else {
        // this.usuariores = respuesta;
        this.empresasContador = respuesta;
        this.existecontador = true;
        // console.log('usuario correto');
      }

    },
      error => {
        console.log(error);
        this.service.add({ severity: 'error', summary: 'Error de base de datos', detail: error.error });
        // this.snackbar.open('Error al conectarse a la base de datos', 'cerrar', {duration: 3000});
      });

  }

  animacion() {
    const textWrapper = document.querySelector('.ml2');
    textWrapper.innerHTML = textWrapper.textContent.replace(
      /\S/g, '<span class=\'letter\'>$&</span>'
    );
    /*
    anime.timeline({loop: true}).add({
      targets: '.ml2 .letter',
      scale: [4,1],
      opacity: [0,1],
      translateZ: 0,
      easing: "easeOutExpo",
      duration: 950,
      delay: (el, i) => 70*i
    }).add({
      targets: '.ml2',
      opacity: 0,
      duration: 1000,
      easing: "easeOutExpo",
      delay: 1000
    });
    
     */
  }

  links(link) {
    let url;
    if (link === 'facebook') {
      url = 'https://www.facebook.com/SACIJAVA';
      window.open(url);
    }
    if (link === 'instagram') {
      url = 'https://www.instagram.com/softwarecontable_saci/';
      window.open(url);
    }
    if (link === 'twitter') {
      url = 'https://twitter.com/SaciJava';
      window.open(url);
    }
    if (link === 'youtube') {
      url = 'https://www.youtube.com/channel/UC1if0OQS3h4XRrh_CyUmi7Q/videos?view=1';
      window.open(url);
    }
    if (link === 'whatsapp') {
      url = 'https://bit.ly/2V0BWk5';
      window.open(url);
    }
  }

  testEmpresasEndPoint() {
    if (this.ruc) {
      let endPointUrl = `${this.url}/empresas/${this.ruc}/verificar`;
      endPointUrl = `${endPointUrl}?servicio=${this.servicio}&user=${this.user}`;
      this.getServer(endPointUrl).subscribe(respuesta => {
        if (respuesta.length > 0) {
          let oEmpresa = respuesta[0];
          this.resultado = `Resultado: Estado: ${oEmpresa.EMP_STD} , Forma Pago: ${oEmpresa.EMP_FP}`;
        } else
          this.resultado = '';
      });
    }
  }

  async sacianexLoginTest() {
    const respuestav = await this.confIniciales.verificarSesion(this.usuario, this.usuarioEsquema.usuario, this.ipFrontend);
    sessionStorage.setItem('session', respuestav.toString());
    this.getlogotipo();
    let ruc;
    const data = await this.confIniciales.getRuc();
    for (const rs of data) {
      ruc = rs.COMRUCI;
    }
    this.http.post(this.way + '/conformacion/1f76e4f9315b660000000000000', {
      ruci: ruc,
      conforma: 'QfJhBWJ2y3kUSbiuSqZDvtD031ie3q0fPlQtu9KpXhxpnPrU5ufuzNA4IGHFvaO0EQ2aFP1Sr1ljRcccU720501X8cPaZ/NqdYDTE8u5TnnViLDLuexgVUyj2J+DGypvsFQRdB7iMHA8uEvVyy6/fucrCSX1l5fZQcVpFuJCqWfI9sn5aBhVq5CoiJ5GRcAGAlFlLPOXhqiUBIIkbIGaZQAl0PoiREt/56172Aa3gdeTdapTwzuD60IrvJPVmeFomBMr4SSOsEWJCNMnw1ANw/yFDqn1afNUdU1vcOhPYGsoQ6HEllPllm+BC7FGcYwy9ns3rD2crKFRZVjgakNkotvAtkQMzL4AqpR8wm+Hk33FN/EGBNJxUsEjrqULlblw9OlSQInKquOsbaQghCH4A/29VCpUoEPXl2TbxRkz8Xr5VIZ4qkvZgWRMT1uB5mlu14Tm5AAKeVOuW1lIX8wPRjRPa3QcM4I3pYAkQLbaItXsHoazW3ZQptghzZQo0cCjAheaMmcjLHBmLMPecEKILdz6/21fNTA30CSgLucJHdM0XCsNH08ZPavgcRdf9BQJ+oVeE+t+z/5XzO28Kurt8iM1uzbxo1kKnpSBFIKd2AONhs/1TJNwrncJydsq09Fa45YqhEp8SxeCMmWHjuvFbZUG+rVClgXG4xxZ1cbr9oE='
    }, this.confIniciales.httpOptions()).subscribe(async resp => {
      if (resp === true) {
        this.utilitariosService.saveActivacion(true);
      } else {
        this.utilitariosService.saveActivacion(false);
      }
      if (this.usuario === 'admin' || this.usuarioGlob.autenticacion === 1) {
        // generación de token     
        if (this.auxtoken !== 'tokenverificado') {
          this.utilitariosService.enviarCorreoToken(this.empresasel, this.usuario).subscribe(data => {
            if (data === false) {
              this.service.add({ severity: 'error', summary: 'Error', detail: 'El usuario no existe' });
              return;
            } else {
              this.auxtoken = 'usuadmin';
              this.displayToken = true;
            }
          });
        } else {
          this.iniciaElSistema();
        }
      } else {
        this.iniciaElSistema();
      }

      const el = document.documentElement;
      // el.requestFullscreen(); //entra en modo pantalla completa
    });
  }

  async sacianexLogin() {
    const respuestav = await this.confIniciales.verificarSesion(this.usuario, this.usuarioEsquema.usuario, this.ipFrontend);
    sessionStorage.setItem('session', respuestav.toString());
    this.getlogotipo();
    let ruc;
    const data = await this.confIniciales.getRuc();
    for (const rs of data) {
      ruc = rs.COMRUCI;
    }
    let servicio = '007';
    let user = this.usuarioEsquema.esquema;
    if (ruc) {
      let endPointUrl = `${this.url}/empresas/${ruc}/verificar`;
      endPointUrl = `${endPointUrl}?servicio=${servicio}&user=${user}`;
      this.getServer(endPointUrl).subscribe(respuesta => {
        if (respuesta.length > 0) {
          let oEmpresa = respuesta[0];
          this.resultado = `Resultado: Estado: ${oEmpresa.EMP_STD} , Forma Pago: ${oEmpresa.EMP_FP}`;
          this.http.post(this.way + '/conformacion/1f76e4f9315b660000000000000', {
            ruci: ruc,
            conforma: `${oEmpresa.EMP_FP}`
          }, this.confIniciales.httpOptions()).subscribe(async resp => {
            if (resp === true) {
              this.utilitariosService.saveActivacion(true);
            } else {
              this.utilitariosService.saveActivacion(false);
            }
            if (this.usuario === 'admin' || this.usuarioGlob.autenticacion === 1) {
              // generación de token
              this.utilitariosService.enviarCorreoToken(this.empresasel, this.usuario).subscribe(data => {
                if (data === false) {
                  this.service.add({ severity: 'error', summary: 'Error', detail: 'El usuario no existe' });
                  return;
                } else {
                  this.auxtoken = 'usuadmin';
                  this.displayToken = true;
                }
              });
            } else {
              this.iniciaElSistema();
            }

            const el = document.documentElement;
            // el.requestFullscreen(); // entra en modo pantalla completa
          }, () => {
            this.service.add({ severity: 'error', summary: 'Error', detail: 'No existe empresa' });
          });
        } else {
          this.service.add({ severity: 'error', summary: 'Información', detail: 'No existe empresa' });
        }
      }, () => {
        this.service.add({ severity: 'error', summary: 'Error', detail: 'No existe empresa' });
      });
    }
  }

  async omitirConformacion() {
    const respuestav = await this.confIniciales.verificarSesion(this.usuario, this.usuarioEsquema.usuario, this.ipFrontend);
    sessionStorage.setItem('session', respuestav.toString());
    this.utilitariosService.saveActivacion(true);
    this.getlogotipo();
    this.iniciaElSistema();
    const el = document.documentElement;
  }

  olvidocontrasena() {
    if (this.empresasel !== undefined && this.empresasel !== '') {
      this.empresasel = this.empresasel.toUpperCase();
      const auxempresa = this.empresas.find((emp: { EMPRESA_USUARIO: any; }) => emp.EMPRESA_USUARIO === this.empresasel);
      // se verifica la empresa
      if (auxempresa === undefined) {
        this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'La empresa no existe' });
        this.back();
      } else {
        if (this.usuario !== undefined && this.usuario !== '') {
          this.utilitariosService.enviarCorreo(this.empresasel, this.usuario).subscribe(data => {
            if (data === false) {
              this.service.add({ severity: 'error', summary: 'Error', detail: 'El usuario no existe' });
              return;
            } else {
              this.service.add({
                severity: 'success', summary: 'Información',
                detail: 'Verifique su correo electrónico ' + data
              });
            }
          });
        } else {
          this.service.add({ severity: 'error', summary: 'Error', detail: 'Ingrese el usuario' });
          return;
        }
      }
    } else {
      this.service.add({ severity: 'error', summary: 'Error', detail: 'Ingrese la empresa' });
      return;
    }
  }

}
