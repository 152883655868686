import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Cxpdocpagados } from '../cxpinterfaces/cxpdocpagados';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { SelectItem, MessageService } from 'primeng';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { DatePipe } from '@angular/common';
import { CxptrnpagodocumentoService } from 'src/app/com/comservicios/cxptrnpagodocumento.service';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';


@Component({
  selector: 'app-cxpbuscardocpagados',
  templateUrl: './cxpbuscardocpagados.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class CxpbuscardocpagadosComponent implements OnInit {
  
  txtProCodigo = '';
  txtProNombre = '';
  txtNumFactura = '';

  @Output() detallesSeleccionado = new EventEmitter<Cxpdocpagados>();
  @Output() signalSalir = new EventEmitter<boolean>();

  detalleDoc: Cxpdocpagados[] = [];
  public frameworkComponents;
  rowStyle;
  defaultDocPagados;
  rowSelection = 'multiple';
  largo: string;

  opcion: string;
  displayDialogBus: boolean;
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  where: string;

  columnDefsDocPagados = [
    {
      headerName: 'Nro.Documento', field: 'COMPDOC', cellEditor: 'cellPrueba', width: 150, onCellDoubleClicked: (params) => {
       this.visualizarSeleccion(params.data);
      }, editable: false,
     
    },
    {
      headerName: 'Com. CA/AB', field: 'COMPROABCA', cellEditor: 'cellPrueba', width: 150, editable: false, onCellDoubleClicked: (params) => {
        this.visualizarSeleccion(params.data);
       }
     
    },
    {
      headerName: 'Fecha ', field: 'TRNCOBRO_FECHATRN', cellEditor: 'cellPrueba', editable: false
      , width: 150, onCellDoubleClicked: (params) => {
        this.visualizarSeleccion(params.data);
       }
     
    },
    {
      headerName: 'Importe CA/AB', field: 'TRNDOCUMENTO_IMPORTE', cellEditor: 'cellPrueba', editable: false, width: 150, 
      onCellDoubleClicked: (params) => {
        this.visualizarSeleccion(params.data);
       }
  
    },
    {
      headerName: 'Cod.Proveedor', field: 'PRO_CODIGO', cellEditor: 'cellPrueba', editable: false, width: 150, 
      onCellDoubleClicked: (params) => {
        this.visualizarSeleccion(params.data);
       }
  
    },
  ];

  constructor(private busquedaSer: InvbusquedaService, private datePipe: DatePipe, 
    private trnpagodocService:CxptrnpagodocumentoService, 
    private message: MessageService, private errorService: ErroresBaseDatosService) { 
    this.agTable();
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    
   
    
    this.defaultDocPagados = {
      editable: false,
      width: 100,
      objeto: 'cxpdocpagados',
      resizable: true
    };
  }

  ngOnInit(): void {
    this.largo = '580';
  }

  manejarSenales(valor) {
    if (valor === 'Buscar') {
      this.listarDatos(this.txtProCodigo, this.txtNumFactura);
    }

    if (valor === 'Salir') {
      this.signalSalir.emit(true);
    }
  }

  async listarDatos(StrCodigoProveedor: string, strNumeroFactura: string){
    this.detalleDoc = [];
    const detalle: Cxpdocpagados[] = [];
    let dblImporte=0;
		
		let strSentencia = 'select a.*,b.trncobro_nroComprobante as ComproAbCA,b.trncobro_nrodoc as NroDocAbCA,';
		strSentencia = strSentencia + ' c.trncobro_nrocomprobante as CompDoc,b.trncobro_fechatrn';
		strSentencia = strSentencia + ' from cxp_trndocumento a, cxp_trncobro b,cxp_trncobro c';
		strSentencia = strSentencia + ' where a.trncobro_nrodoc=b.trncobro_nrodoc and a.com_codigo=b.com_codigo';
		strSentencia = strSentencia + ' and b.pro_codigo like nvl(' + '\''+ StrCodigoProveedor + '\'' + ',\'%%\')';
		strSentencia = strSentencia + ' and a.trndocumento_nrodoc=c.trncobro_nrodoc';
		strSentencia = strSentencia + ' and a.com_codigo=c.com_codigo';
		if (strNumeroFactura === '') {
			strSentencia=strSentencia + ' and c.trncobro_nrocomprobante like nvl(' + '\'' + strNumeroFactura + '\'' + ',\'%%\')';
    }else{
      strSentencia=strSentencia + ' and c.trncobro_nrocomprobante like ' + '\'%' + strNumeroFactura + '%\'';
    }
			

		try {
      const rs = await this.trnpagodocService.consultarDatosDocPagados(StrCodigoProveedor, strNumeroFactura);
			for (const doc of rs){
				const item: Cxpdocpagados = {};

				dblImporte  = doc.TRNDOCUMENTO_IMPORTE === 0 ? 0 : doc.TRNDOCUMENTO_IMPORTE ;
				dblImporte = Number(Number(dblImporte).toFixed(2));
				item.TRNDOCUMENTO_IMPORTE = dblImporte;
				
				item.PRO_CODIGO = (doc.PRO_CODIGO === null ? '':doc.PRO_CODIGO);
        item.TRNCOBRO_FECHATRN = this.datePipe.transform(doc.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
        item.COMPROABCA = doc.COMPROABCA === null ? '' : doc.COMPROABCA;
        item.COMPDOC = doc.COMPDOC === null ? '' : doc.COMPDOC;
        item.NRODOCABCA = doc.NRODOCABCA  === null ? '' : doc.NRODOCABCA ;
				
			    detalle.push(item);
      }
      
      this.detalleDoc = detalle;
		
			
		} catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso listar documentos cancelados no terminó satisfactoriamente.... ! ');		
		}
		
	}//fin listar datos

  desplegarDatosProveedor (parametro) {
    this.detalleDoc = [];
    this.opcion = 'BUSCAR_PROV';
    this.types = [
      {label: 'CODIGO', value: 'PRO_CODIGO'},
      {label: 'NOMBRE', value: 'PRO_NOMBRE'},
      {label: 'NOMBRE CORTO', value: 'PRO_NOMBREC'},
      {label: 'GRUPO', value: 'GRU_CODIGO'},
      {label: 'RUC', value: 'PRO_RUCIDE'},
      {label: 'CUENTA', value: 'CON_CODIGO1'},
    ];
    this.busquedacampos = [parametro, '', '', '', '', ''];
    this.consulta = 'PRO_nombre, PRO_codigo,PRO_nombrec, GRU_codigo , PRO_rucide,CON_codigo1'
    this.tabla = 'com_maeproveedor';
    this.where = 'ROWNUM < 50';
    this.trnpagodocService.erMaeProveedor(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaComMaeProveedor8().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaComMaeProveedor8().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  manejarSeleccion(valor) {
    
    if (this.opcion === 'BUSCAR_PROV') {
     
      this.txtProNombre = valor.PRO_NOMBRE;
      this.txtProCodigo = valor.PRO_CODIGO;
      document.getElementById('FAC_NUMERO').focus();
    }
    this.displayDialogBus = false;
    this.displayDialogBusquedaFast = false;
  }

  visualizarSeleccion(item) {
    this.detallesSeleccionado.emit(item);
  }

  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'busdocpagados',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

}
