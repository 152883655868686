<div class="exception-body error">
    <div class="exception-panel">
            <div class="exception-content">
                <img src="assets/layout/images/pages/icon-error.svg">
                <h1>Error Occured</h1>
                <p>Something went wrong.</p>
                <button type="button" [routerLink]="['/']" class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only">
                    <span class="ui-button-text ui-c">Go to Dashboard</span>
                </button>
            </div>
    </div>
</div>