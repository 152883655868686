<div class="p-grid">
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-10"></div>
			<div class="p-col-1">
				<button style="background-image: url(../../../assets/images/iconos/salir.png);" (click)="enviarSalir()"></button>
			</div>
		</div>
	</div>
</div>
<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="maEmplPer"></p-toast>
<app-aggridsaci [width]=""
				[height]="largo"
				[enableSorting]="true"
				[enableFilter]="true"
				[rowData]="perservice.nomPerEmpl"
				[frameworkComponents]="frameworkComponents"
				[animateRows]="true"
				[rowSelection]="rowSelection"
				[columnDefs]="columnDefsDetPerEmpl"
				[defaultColDef]="defaultColDef"
				[rowStyle]="rowStyle"
				[singleClickEdit]="false"
				[botones]="false"
				[mostrarGuardar]="false"
				(selected)="selectPeriodo($event)"
                (cambios)="cambioPeriodo($event)"
				[botonesAdicionales]="true"
></app-aggridsaci>
<div class="divgrups">
	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-1">	
					
				</div>
				<div class="p-col-1">	
					<span>{{cuota}}</span>
				</div>
				<div class="p-col-1">	
					
				</div>
				<div class="p-col-8">
					<button  pButton class="aceptar" style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 49% ;margin-bottom: 5px;" (click)="aceptar()">Aceptar</button>	
					<button  pButton class="aceptar" style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 49%;margin-left: 5px;margin-bottom: 5px;" (click)="enviarSalir()">Cancelar</button>
				</div>	
				
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-1">	
					
				</div>
				<div class="p-col-2">	
					<span>{{fechaImp}}</span>
				</div>
			</div>
		</div>
	</div>
</div>