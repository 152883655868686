import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Observable} from 'rxjs';
import {EncabezadoCierreCaja} from '../baninterfaces/encabezadoCierreCaja';
import {CamposCierreCaja} from '../baninterfaces/camposCierreCaja';
import {CamposBusquedas} from '../baninterfaces/camposBusquedas';
import {Consulta} from '../baninterfaces/consulta';
import {CamposMovimiento} from '../baninterfaces/camposMovimiento';
import {MessageService} from 'primeng/api';
import {CamposMovimientosVarios} from '../baninterfaces/camposMovimientosVarios';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class BancierrecajaService {
  get movimientosVarios(): CamposMovimientosVarios[] {
    return this._movimientosVarios;
  }
  
  set movimientosVarios(value: CamposMovimientosVarios[]) {
    this._movimientosVarios = value;
  }
  
  get movimientos(): CamposMovimiento[] {
    return this._movimientos;
  }
  
  set movimientos(value: CamposMovimiento[]) {
    this._movimientos = value;
  }
  
  get camposCierreCaja(): CamposCierreCaja[] {
    return this._camposCierreCaja;
  }
  
  set camposCierreCaja(value: CamposCierreCaja[]) {
    this._camposCierreCaja = value;
  }
  
  private way: string;
  private _movimientosVarios: CamposMovimientosVarios[];
  private _camposCierreCaja: CamposCierreCaja[];
  private _movimientos: CamposMovimiento[];
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private message: MessageService,
              private confIniciales: ConfInicialesService
  ) {
    this.way = sessionStorage.getItem('way');
  
  }
  
  getEncabezadosCierreCaja(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ban/cierrecaja/83baebb19276f80', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    );
  }
  
  guardarDetalle(detalle: CamposCierreCaja): Observable<any[]> {
    return this.http.post<any>(this.way + '/ban/cierrecaja/43cadb5dd556b0', {
      elementos: {
        p_ENCCUADRECAJA_NUMERO: detalle.ENCCUADRECAJA_NUMERO,
        p_COM_CODIGO: '01',
        p_TRNCOBRO_FECHATRN: detalle.TRNCOBRO_FECHATRN,
        p_FORMAPAGO_TIPO: detalle.FORMAPAGO_TIPO,
        p_APLORG_CODIGO: detalle.APLORG_CODIGO,
        p_TRNCOBRO_NROCOMPROBANTE: detalle.TRNCOBRO_NROCOMPROBANTE,
        p_VEN_CODIGO: detalle.VEN_CODIGO,
        p_DETCUADRECAJA_DEBITO: Number(detalle.DETCUADRECAJA_DEBITO),
        p_DETCUADRECAJA_CREDITO: Number(detalle.DETCUADRECAJA_CREDITO)
      }
    }, this.confIniciales.httpOptions());
  }
  
  
  guardarMovimiento(movimiento: CamposMovimiento): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ban/cierrecaja/43cadb55bb63f0', {
        elementos: {
          TRNCAJ_DOCNRO: movimiento.TRNCAJ_DOCNRO,
          COM_CODIGO: '01',
          TRNCAJ_FECHA: movimiento.TRNCAJ_FECHA,
          TRNCAJ_AUTORIZADO: movimiento.TRNCAJ_AUTORIZADO,
          TRNCAJ_DESCRIPCION: movimiento.TRNCAJ_DESCRIPCION,
          TRNCAJ_IMPORTE: Number(movimiento.TRNCAJ_IMPORTE),
          TRNCAJ_TIPO: 'EF',
          TRNCAJ_NUMERO: movimiento.TRNCAJ_NUMERO,
          TRNCAJ_ORIGEN: 'CDC',
          CAJ_CODIGO: movimiento.CAJ_CODIGO
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  
  getMovimientos(numero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ban/cierrecaja/83bb6c7e5693b00', {
      elementos: {
          TRNCAJ_DOCNRO: numero
      }
      }, this.confIniciales.httpOptions()
    );
  }
  
  actualizar(trnnumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ban/cierrecaja/533beeeeecc2f80', {
        elementos: {
          p_COM_CODIGO: '01',
          p_ENCCUADRECAJA_NUMERO: trnnumero
        },
      }, this.confIniciales.httpOptions()
    );
  }
  
  eliminarMovimiento(mov: CamposMovimiento): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ban/cierrecaja/3ab2b4aeaddb20', {
        elementos: {
          TRNCAJ_NUMERO: mov.TRNCAJ_NUMERO
        },
      }, this.confIniciales.httpOptions()
    );
  }
  
  eliminarMovVarios(vari: CamposMovimientosVarios): Observable<any[]> {
    return this.http.post<any>(this.way + '/ban/cierrecaja/7565695ed8ffd40', {
      elementos: {
        p_trncaj_tipo: 'EF',
        p_trncaj_numero: vari.TRNCAJ_NUMERO,
        p_caj_codigo: vari.CAJ_CODIGO,
        p_trncaj_docnro: vari.TRNCAJ_DOCNRO,
        p_com_codigo: '01',
      }
    }, this.confIniciales.httpOptions());
  }
  
  deletMovimiento(movi, moviVarios) {
    this.eliminarMovVarios(moviVarios).subscribe((res) => {
      this.eliminarMovimiento(movi).subscribe((res1) => {
        this.getMovimientos(movi.ENCCUADRECAJA_NUMERO).subscribe((res2) => {
          this.movimientos = res2;
          this.movimientos.map((mov) => {
            mov.TRNCAJ_FECHA = mov.TRNCAJ_FECHA.toLocaleString().substr(0, 10);
            this.getMovimientoVarios(mov).subscribe((respuesta) => {
              mov.CON_CODIGO = respuesta[0].CON_CODIGO;
            });
          });
        });
        this.message.add({
          key: 'cierre',
          severity: 'success',
          summary: 'Eliminación de Movimiento',
          detail: 'El movimiento se eliminó correctamente'
        });
      });
    });
  }
  
  getMovimientoVarios(movi) {
    return this.http.post<any[]>(this.way + '/ban/cierrecaja/41ddb63f2b7d5c', {
        elementos: {
          TRNCAJ_NUMERO: movi.TRNCAJ_NUMERO,
          TRNCAJ_DOCNRO: movi.TRNCAJ_DOCNRO
        },
      }, this.confIniciales.httpOptions()
    );
  }
  
  getClave(): Observable<any> {
    return this.http.post<any>(this.way + '/ban/cierrecaja/617d6555f72bdc', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    );
  }
  
  actualizarMovimiento(actualizarMov: CamposMovimiento): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ban/cierrecaja/299df2ab6c7e56', {
        elementos: {
          TRNCAJ_FECHA: actualizarMov.TRNCAJ_FECHA,
          TRNCAJ_DOCNRO: actualizarMov.TRNCAJ_DOCNRO,
          TRNCAJ_AUTORIZADO: actualizarMov.TRNCAJ_AUTORIZADO,
          TRNCAJ_DESCRIPCION: actualizarMov.TRNCAJ_DESCRIPCION,
          TRNCAJ_IMPORTE: Number(actualizarMov.TRNCAJ_IMPORTE),
          TRNCAJ_NUMERO: actualizarMov.TRNCAJ_NUMERO
        },
      }, this.confIniciales.httpOptions()
    );
  }
  
  actualizarMovimientosVarios(actualizarVarios: CamposMovimientosVarios): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ban/cierrecaja/533be556d8ffd40', {
        elementos: {
          CON_CODIGO: actualizarVarios.CON_CODIGO,
          TRNCAJ_DOCNRO: actualizarVarios.TRNCAJ_DOCNRO,
          TRNCAJVAR_IMPORTE: Number(actualizarVarios.TRNCAJVAR_IMPORTE),
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  updateMovimiento(actualizarMov, actualizarVarios) {
    this.actualizarMovimiento(actualizarMov).subscribe((res) => {
      this.actualizarMovimientosVarios(actualizarVarios).subscribe((res1) => {
        this.message.add({
          key: 'cierre',
          severity: 'success',
          summary: 'Actualización de Movimiento',
          detail: 'El movimiento se actualizó correctamente'
        });
      }, error1 => {
        console.log('error actualizarrmovarios');
      });
    }, error1 => {
      console.log('error actualizarmov');
    });
  }
  
  setSelectItemDoc(actual: CamposCierreCaja) {
    console.log('cambia estado');
    this.camposCierreCaja.map((row) => {
      console.log('cambia estado' + actual.SNBOOLEAN
        + 'nro' + actual.TRN_NROCOMPROBANTE + 'nro1'
        + row.TRN_NROCOMPROBANTE);
      if (String(actual.TRN_NROCOMPROBANTE).trim() === String(row.TRN_NROCOMPROBANTE).trim()) {
        row.SNBOOLEAN = actual.SNBOOLEAN;
        console.log('seteo');
      }
    });
    // for (let i = 0; i < this.camposCierreCaja.length; i++) {
    //   const row: CamposCierreCaja = this.camposCierreCaja[i];
    //   console.log('cambia estado' + i + actual.SNBOOLEAN
    //     + 'nro' + actual.TRN_NROCOMPROBANTE + 'nro1'
    //     + row.TRN_NROCOMPROBANTE);
    //   if (String(actual.TRN_NROCOMPROBANTE).trim() === String(row.TRN_NROCOMPROBANTE).trim()) {
    //     this.camposCierreCaja[i].SNBOOLEAN = actual.SNBOOLEAN;
    //     console.log('seteo');
    //   }
    //
    // }
  }
  
  insertarActualizaCierreCaja(encabezado: EncabezadoCierreCaja) {
    return this.http.post<any>(this.way + '/ban/cierrecaja/95f8ebb19276f80', {
      elementos: {
        p_ENCCUADRECAJA_NUMERO: encabezado.ENCCUADRECAJA_NUMERO,
        p_ENCCUADRECAJA_FECHA: encabezado.ENCCUADRECAJA_FECHA[0],
        p_ENCCUADRECAJA_DESDE: encabezado.ENCCUADRECAJA_DESDE[0],
        p_ENCCUADRECAJA_HASTA: encabezado.ENCCUADRECAJA_HASTA[0],
        p_COM_CODIGO: '01',
        p_TRNCIE_CODIGO: ''
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  insertarFiltro(numero, cajTipo, cajCodigo, cajDescripcion) {
    return this.http.post<any>(this.way + '/ban/cierrecaja/12bf1dbea9f2af000', {
      elementos: {
        p_ENCCUADRECAJA_NUMERO: numero,
        p_com_codigo: '01',
        p_filtroCuadreCaja_tipo: cajTipo,
        p_filtroCuadreCaja_codigo: cajCodigo,
        p_filtroCuadreCaja_descripcion: cajDescripcion
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  insertarDetCuaCaja(numcuadrecaja, detalle: CamposCierreCaja) {
    return this.http.post<any>(this.way + '/ban/cierrecaja/95f8d7759e53780', {
      elementos: {
        p_ENCCUADRECAJA_NUMERO: numcuadrecaja,
        p_COM_CODIGO: '01',
        p_TRNCOBRO_FECHATRN: detalle.TRNCOBRO_FECHATRN,
        p_FORMAPAGO_TIPO: detalle.FORMAPAGO_TIPO,
        p_APLORG_CODIGO: detalle.APLORG_CODIGO,
        p_TRNCOBRO_NROCOMPROBANTE: detalle.TRNCOBRO_NROCOMPROBANTE,
        p_VEN_CODIGO: detalle.VEN_CODIGO,
        p_DETCUADRECAJA_DEBITO: Number(detalle.DETCUADRECAJA_DEBITO),
        p_DETCUADRECAJA_CREDITO: Number(detalle.DETCUADRECAJA_CREDITO)
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarDetConciliacion(numCuadreCaja, detalle: CamposCierreCaja) {
    return this.http.post<any>(this.way + '/ban/cierrecaja/7564d77598bb240', {
      elementos: {
        p_ENCCUADRECAJA_NUMERO: detalle.ENCCUADRECAJA_NUMERO,
        p_COM_CODIGO: detalle.COM_CODIGO,
        p_FORMAPAGO_TIPO: detalle.FORMAPAGO_TIPO,
        p_APLORG_CODIGO: '01',
        p_TRNCOBRO_NROCOMPROBANTE: detalle.TRNCOBRO_NROCOMPROBANTE
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerTrnDocumentos(strCodigos) {
    return this.http.post<any>(this.way + '/ban/cierrecaja/617deeeedc33d8', {
      elementos: {
        codigos: strCodigos
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  actualizarTrnPago(trnnumero) {
    return this.http.post<any>(this.way + '/ban/cierrecaja/299df7777caa18', {
      elementos: {
        trncobro_nrocomprobante: trnnumero
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarCierreCaja(numCuadreCaja) {
    return this.http.post<any>(this.way + '/ban/cierrecaja/7564c93b7b73140', {
      elementos: {
        p_ENCCUADRECAJA_NUMERO: numCuadreCaja,
        p_COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  getOpcionesProm(numero) {
    return this.http.post<any[]>(this.way + '/ban/cierrecaja/41ddc6592c5ddc', {
        elementos: {
          ENCCUADRECAJA_NUMERO: numero
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  getDetalleCierreCajaProm(numero) {
    return this.http.post<any>(this.way + '/ban/cierrecaja/83bad7759276f80', {
      elementos: {
        p_Opcion: 'A',
        p_Filtro: '',
        p_Condicion: 'ENCCUADRECAJA_NUMERO=' + numero,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  consultarItemsBan_trnpago(consulta: Consulta, fechaDesde, fechaHasta) {
    return this.http.post<any>(this.way + '/ban/cierrecaja/107765d75bbbbe000', {
      elementos: {
        p_Opcion: 'A',
        p_cuadrecajaNumero: consulta.cuadreCajaNum,
        p_Condicion: 'TRN_FECHA BETWEEN TO_DATE (\'' + fechaDesde[0] + '\', \' dd/mm/yyyy \') ' +
          'AND TO_DATE (\'' + fechaHasta[0] + '\', \' dd/mm/yyyy \')',
        p_Filtro: consulta.filtro,
        p_Orden: '',
        p_COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
    
  }
  
  
}


