<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="Periodo">
	<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarPeriodo" title="Confirmar Acción" appendTo="body">
	</p-confirmDialog>
</p-toast>

<app-invmaebarra [botonGuardar]="false" [botonRegresar]="false" [botonPrimero]="true" [botonAnterior]="true"
				 [botonSiguiente]="true" [botonUltimo]="true" [barraBotonesCRUD]="false" [barraBotonesDesplaz]="true"
				 [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true"
				 (signal)="manejarSenales($event)">
</app-invmaebarra>

<p-tabView>
	<p-tabPanel header="Periodo Contable">
		<div class="divgrups">
			<div style="height: 350px">
			<div class="p-grid">
				<div class="p-col-12">
					<div class="p-col-1">
						<span>Fecha</span>
					</div>
					<div class="p-col-12" style="margin-top: 15px; margin-bottom: -15px">
						<div class="divgrups">
							<div class="p-grid">
								<div class="p-col-12">
									<div class="p-grid">
										<div class="p-col-2"></div>
										<div class="p-col-2">
											<span>Fecha Inicial:</span>
										</div>
										<div class="p-col-2">
											<p-calendar id="fechaInicial" [inputStyle]="{width: '100%'}"
														dateFormat="dd/mm/yy" [(ngModel)]="dateInit"
														[disabled]="inputDes"></p-calendar>

										</div>
										<div class="p-col-2">
											<span> dd/MM/yyyy</span>
										</div>
										<div class="p-col-2"></div>
									</div>
								</div>
								<div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px">
									<div class="p-grid">
										<div class="p-col-2"></div>
										<div class="p-col-2">
											<span>Fecha Final:</span>
										</div>
										<div class="p-col-2">
											<p-calendar id="fechaFinal" [inputStyle]="{width: '100%'}"
														dateFormat="dd/mm/yy" [(ngModel)]="dateFinal"
														[disabled]="inputDes"></p-calendar>
										</div>
										<div class="p-col-2">
											<span>dd/MM/yyyy</span>
										</div>
										<div class="p-col-2"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			</div>
		</div>
	</p-tabPanel>
</p-tabView>
