<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="bancashmngedit"></p-toast>
<div class="p-grid">
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-11"></div>
				<div class="p-col-1">
					<button style="background-image: url(../../../assets/images/iconos/salir.png);"  (click)="salir()"></button>
				</div>
			</div>
		</div>
</div>
<div class="divgrups">
	<div class="p-grid">
		<!--LINE 1-->
		<div class="p-col-1">
		</div>
		<div class="p-col-4" style="text-align: left">
			<span>Forma de Pago:</span>
		</div>
		<div class="p-col-6" style="text-align: left">
			<p-dropdown [style]="{'width': '60%', 'min-width': '60%'}" [(ngModel)]="strFormaPago"
						optionLabel="name"  [options]="cmbFormaPago" inputId="inputFormaPago" (onChange)="cambioFormaPago($event)"
						(keydown.enter)="cambiarFoco($event)"></p-dropdown>
		</div>
		<div class="p-col-1">
		</div>
		<!--LINE 2-->
		<div class="p-col-1">
		</div>
		<div class="p-col-4" style="text-align: left">
			<span>Entidad Financiera:</span>
		</div>
		<div class="p-col-6" style="text-align: left">
			<p-dropdown [style]="{'width': '90%', 'min-width': '90%'}" [(ngModel)]="strEntidadFin"
						optionLabel="name" [options]="cmbEntidadFin" inputId="inputEntidadFin" [readonly]="disabledCmbEntfin" (keydown.enter)="cambiarFoco($event)"></p-dropdown>
		</div>
		<div class="p-col-1">
		</div>
		<!--LINE 3-->
		<div class="p-col-1">
		</div>
		<div class="p-col-4"  style="text-align: left">
			<span>Tipo de Cuenta:</span>
		</div>
		<div class="p-col-6" style="text-align: left">
			<p-dropdown [style]="{'width': '60%', 'min-width': '60%'}" [(ngModel)]="strTipoCuenta"
						optionLabel="name" [options]="cmbTipoCuenta" inputId="inputTipoCta" [readonly]="disabledCmbTipCta" (keydown.enter)="cambiarFoco($event)"></p-dropdown>
		</div>
		<div class="p-col-1">
		</div>
		<!--LINE 4-->
		<div class="p-col-1">
		</div>
		<div class="p-col-4"  style="text-align: left">
			<span >Nro. de Cuenta:</span>
		</div>
		<div class="p-col-4" style="text-align: left">
			<input  id="numeroCuenta" type="text" pInputText autocomplete="off" [(ngModel)]="txtNumeroCuenta" [disabled]="disabledNumCta" (keydown.enter)="cambiarFoco($event)">
		</div>
		<div class="p-col-3">
		</div>
		<!--LINE 5-->
		<div class="p-col-1">
		</div>
		<div class="p-col-4"  style="text-align: left">
			<span >Beneficiario:</span>
		</div>
		<div class="p-col-6" style="text-align: left">
			<input  id="beneficiario" type="text" pInputText autocomplete="off" [(ngModel)]="txtBeneficiario" (keydown.enter)="cambiarFoco($event)">
		</div>
		<div class="p-col-1">
		</div>
		<!--LINE 5-->
		<div class="p-col-1">
		</div>
		<div class="p-col-4"  style="text-align: left">
			<span >Tipo de Identificación:</span>
		</div>
		<div class="p-col-6" style="text-align: left">
			<p-dropdown [style]="{'width': '60%', 'min-width': '60%'}" [(ngModel)]="strTipoId"
						optionLabel="name" [options]="cmbTipoId" inputId="inputTipoId" (keydown.enter)="cambiarFoco($event)"></p-dropdown>
		</div>
		<div class="p-col-1">
		</div>
		<!--LINE 5-->
		<div class="p-col-1">
		</div>
		<div class="p-col-4"  style="text-align: left">
			<span >Id. Identificación:</span>
		</div>
		<div class="p-col-4" style="text-align: left">
			<input  id="idIdentificacion" type="text" pInputText autocomplete="off" [(ngModel)]="txtIdIdentificacion" (keydown.enter)="cambiarFoco($event)">
		</div>
		<div class="p-col-3">
		</div>
		<!--LINE 6-->
		<div class="p-col-1">
		</div>
		<div class="p-col-4"  style="text-align: left">
			<span >Dirección:</span>
		</div>
		<div class="p-col-6" style="text-align: left">
			<input  id="direccion" type="text" pInputText autocomplete="off" [(ngModel)]="txtDireccion" (keydown.enter)="cambiarFoco($event)">
		</div>
		<div class="p-col-1">
		</div>
		<!--LINE 6-->
		<div class="p-col-1">
		</div>
		<div class="p-col-4"  style="text-align: left">
			<span >Ciudad:</span>
		</div>
		<div class="p-col-4" style="text-align: left">
			<input  id="ciudad" type="text" pInputText autocomplete="off" [(ngModel)]="txtCiudad" (keydown.enter)="cambiarFoco($event)">
		</div>
		<div class="p-col-3">
		</div>
		<!--LINE 6-->
		<div class="p-col-1">
		</div>
		<div class="p-col-4"  style="text-align: left">
			<span >Teléfono(celular):</span>
		</div>
		<div class="p-col-4" style="text-align: left">
			<input  id="telefono" type="text" pInputText autocomplete="off" [(ngModel)]="txtTelefono" (keydown.enter)="cambiarFoco($event)">
		</div>
		<div class="p-col-3">
		</div>
		<!--LINE 6-->
		<div class="p-col-1">
		</div>
		<div class="p-col-4"  style="text-align: left">
			<span >Localidad de Pago:</span>
		</div>
		<div class="p-col-4" style="text-align: left">
			<input  id="localidad" type="text" pInputText autocomplete="off" [(ngModel)]="txtLocalidadPago" (keydown.enter)="cambiarFoco($event)">
		</div>
		<div class="p-col-3">
		</div>
		<!--LINE 6-->
		<div class="p-col-1">
		</div>
		<div class="p-col-4"  style="text-align: left">
			<span >Email:</span>
		</div>
		<div class="p-col-6" style="text-align: left">
			<input  id="email" type="text" pInputText autocomplete="off" [(ngModel)]="txtEmail">
		</div>
		<div class="p-col-1">
		</div>
		<!--LINE 6-->
		<br>
		<div class="p-col-6">
			<p-button label="Grabar Ficha Proveedor" (click)="guardarDatosMaeProv()"> </p-button>
		</div>
		<div class="p-col-3">
			<p-button label="Cancelar" (click)="salir()"> </p-button>
		</div>
		<div class="p-col-3">
			<p-button label="Aceptar" (click)="guardarDatosCashManagement()"> </p-button>
		</div>
	</div>
</div>