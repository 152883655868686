import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {InvtrnreservasService} from '../invservicios/invtrnreservas.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {InvTrnReservas} from '../invinterfaces/invtrnreservas';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {Usuario} from '../../usuario';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {DatePipe} from '@angular/common';
import {InvbusquedaService} from '../invservicios/invbusqueda.service';
import {UtilitariosService} from '../../advantage/advantageservices/utilitarios.service';

@Component({
  selector: 'app-invtrnreservas',
  templateUrl: './invtrnreservas.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class InvtrnreservasComponent implements OnInit {
  
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  @Input() abiertoDesde: any;
  @Output() signalSalir = new EventEmitter<boolean>();
  
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  botonBuscar: boolean;
  
  indicador: any;
  
  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  defaultColDefDetalle;
  largo: string;
  
  detalleSeleccionado: InvTrnReservas = {};
  permisos: SegMaePermiso;
  
  displayDialogBusquedaFast: boolean;
  displayDialogBusquedaFast1: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  condicion: string;
  opcion: string;
  
  txtBodCodigo = '';
  txtBodNombre = '';
  where: string;
  
  chkcaducados = false;
  chkentregados = false;
  
  columnDefsDetalle = [
    { // 3
      headerName: 'Fecha', field: 'TRNRES_FECHA', width: 120 , editable: false
    },
    { // 3
      headerName: 'Caduca', field: 'TRNRES_CADUCA', width: 120 , editable: false
    },
    { // 3
      headerName: 'Usuario', field: 'TRNRES_USUARIO', width: 120, editable: false,
    },
    { // 3
      headerName: 'Bodega Destino', field: 'BOD_CODIGODES', width: 130, cellEditor: 'cellPrueba', editable: (params) => {
        const boolEdit = this.isEdit(params.data) ;
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 30,
            opcionbuscar: true
          }
        }
    },
    { // 3
      headerName: 'Artículo', field: 'ART_CODIGO', width: 130, cellEditor: 'cellPrueba', editable: (params) => {
        const boolEdit = this.isEdit(params.data) ;
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 30,
            opcionbuscar: true
          }
        }
    },
    { // 3
      headerName: 'Descripción', field: 'ART_NOMBRE', width: 250, editable: false,
    },
    {
      headerName: 'Unidad', field: 'ART_UNIDAD', width: 90, cellEditor: 'cellPrueba'
    },
    { // 3
      headerName: 'Cantidad', field: 'TRNRES_CANTIDAD', width: 100, cellEditor: 'cellPrueba', cellStyle: {textAlign: 'right'}, editable: (params) => {
        const boolEdit = this.isEdit(params.data) ;
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 2,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 30,
            opcionbuscar: false,
            tienecalculos: true
          }
        }
    },
    {
      headerName: 'Despachado', field: 'TRNRES_ENTREGADO', cellEditor: 'cellPrueba', editable: false,  width: 110,
      cellStyle: {textAlign: 'right'}
    },
    { // 3
      headerName: 'Observación', field: 'TRNRES_OBSERVACION', width: 370, cellEditor: 'cellPrueba' , editable: (params) => {
        const boolEdit = this.isEdit(params.data) ;
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 200,
            opcionbuscar: false,
            tienecalculos: false
          }
        }
    }
  ];
  
  constructor(public reservasService: InvtrnreservasService, private init: NuevoComponentService,
              private message: MessageService, private confirmationService: ConfirmationService,
              private  auditoriaGralService: AuditoriagralService,  public usuario: Usuario,
              private permisosService: PermisosService,  private errorService: ErroresBaseDatosService,
              private datePipe: DatePipe, private busquedaSer: InvbusquedaService, private utilitariosService: UtilitariosService) {
    this.agTable();
    this.reservasService.detalleReservas[this.indicador] = [];
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    
    this.defaultColDefDetalle = {
      editable: true,
      width: 80,
      objeto: 'invtrnreservas'
    };
    
  }
  
  ngOnInit() {
    this.displayDialogBusquedaFast = false;
    this.displayDialogBusquedaFast1 = false;
    this.largo = '300';
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.reservasService.detalleReservas[this.indicador] = [];
    this.habilitarBotones(false, true, false, false);
    this.getPermisos();
    // this.listarReservas();
  }
  
  async getPermisos() {
    console.log(this.init.tabs[this.init.tabindex - 1].component);
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisos = data[0];
        } else {
          this.permisos = null;
        }
      } else {
        this.permisos = null;
      }
    } catch (e) {
      this.permisos = null;
      this.mensajeAlerta('error', 'Error', 'No se pudo obtener los permisos del formulario');
    }
  }
  
  async manejarSenales(valor) {
    
    if (valor === 'Nuevo') {
      
      if (this.txtBodCodigo !== '' && this.txtBodNombre !== '') {
        this.nuevaReserva();
      } else {
        this.mensajeAlerta('warn', 'Información', 'Seleccione una bodega');
      }
      
    }
    
    if (valor === 'Guardar') {
      this.guardarReserva();
    }
    
    if (valor === 'Borrar') {
      this.confEliminarReserva();
    }
    
    if (valor === 'Cancelar') {
      this.listarReservas('T', this.txtBodCodigo);
      this.chkentregados = false;
      this.chkcaducados = false;
      this.habilitarBotones(false, true, false, false);
    }
    
    if (valor === 'Buscar') {
      this.listarReservas('T', this.txtBodCodigo);
      this.buscarRegistro();
    }
    
    if (valor === 'Salir') {
      if (this.abiertoDesde === undefined) {
        this.init.tabs.splice(this.init.tabindex, 1);
      } else {
        this.signalSalir.emit(true);
      }
    }
    
  }
  
  async listarReservas(tipo, bodega) {
    const detalle: InvTrnReservas[] = [];
    this.reservasService.detalleReservas[this.indicador] = [];
    try {
      const rs = await this.reservasService.consultarReserva(tipo, bodega);
      let num = 0;
      for (const res of rs) {
        const item: InvTrnReservas = {};
        
        item.TRNRES_NUMERO = res.TRNRES_NUMERO;
        item.COM_CODIGO = res.COM_CODIGO;
        item.TRNRES_USUARIO = res.TRNRES_USUARIO;
        item.BOD_CODIGO = res.BOD_CODIGO;
        item.ART_CODIGO = res.ART_CODIGO;
        item.ART_NOMBRE = res.ART_NOMBRE;
        item.TRNRES_CANTIDAD = Number(res.TRNRES_CANTIDAD).toFixed(2);
        item.TRNRES_FECHA = this.datePipe.transform(res.TRNRES_FECHA, 'dd/MM/yyyy HH:mm');
        item.TRNRES_CADUCA = this.datePipe.transform(res.TRNRES_CADUCA, 'dd/MM/yyyy HH:mm');
        item.TRNRES_ENTREGADO = res.TRNRES_ENTREGADO === null ? '' : Number(res.TRNRES_ENTREGADO).toFixed(2);
        item.LINEA = num ++;
        item.TRNRES_OBSERVACION = res.TRNRES_OBSERVACION;
        item.ART_UNIDAD = res.ART_UNIDAD;
        item.BOD_CODIGODES = res.BOD_CODIGODES;
        item.TRNRES_ESTADO = res.TRNRES_ESTADO === null ? '' : res.TRNRES_ESTADO;
        detalle.push(item);
      }
      this.reservasService.detalleReservas[this.indicador] = detalle;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso listar reservas no termino satisfactoriamente ! ' +  mensaje);
    }
  }
  
  async nuevaReserva() {
    if (this.permisos !== null ) {
      if (this.permisos.PERTODO === 0 ) {
        if (this.permisos.PERACTUALIZACION === 0 ) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }
    const diaCaduca = await this.reservasService.erNuevareserva();
    const numDias = diaCaduca === null ? 1 : Number(diaCaduca[0].CFG_VALOR2);
    const item: InvTrnReservas = {};
    
    item.BOD_CODIGO = this.txtBodCodigo;
    item.BOD_CODIGODES = '';
    item.ART_CODIGO = '';
    item.TRNRES_USUARIO = this.usuario.identificacion;
    const fecha1 = new Date();
    fecha1.setDate(fecha1.getDate() + numDias);
    item.TRNRES_CADUCA = this.datePipe.transform(fecha1, 'dd/MM/yyyy HH:mm');
    item.TRNRES_FECHA = this.datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm');
    item.TRNRES_CANTIDAD = Number(0).toFixed(2);
    item.ART_NOMBRE = '';
    item.LINEA = this.reservasService.detalleReservas[this.indicador].length + 1;
    item.TRNRES_ENTREGADO = '';
    item.TRNRES_OBSERVACION = '';
    item.TRNRES_NUMERO = '';
    item.COM_CODIGO = '01';
    item.ART_UNIDAD = '';
    item.TRNRES_ESTADO = '';
    
    const indi =  this.reservasService.detalleReservas[this.indicador].indexOf(this.detalleSeleccionado);
    if (indi === -1) {
      this.reservasService.detalleReservas[this.indicador].push(item);
      this.detalleSeleccionado = this.reservasService.detalleReservas[this.indicador]
        [this.reservasService.detalleReservas[this.indicador].length - 1];
      this.aggrid.refreshaggrid(this.reservasService.detalleReservas[this.indicador], this.defaultColDefDetalle.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefDetalle.objeto].setFocusedCell(
        this.reservasService.detalleReservas[this.indicador].length - 1, 'BOD_CODIGODES');
    } else {
      this.reservasService.detalleReservas[this.indicador].splice(indi + 1, 0, item);
      this.aggrid.refreshaggridindex(this.reservasService.detalleReservas[this.indicador], this.defaultColDefDetalle.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefDetalle.objeto].setFocusedCell(
        indi + 1, 'BOD_CODIGODES');
    }
    
    this.habilitarBotones(true, false, true, true);
  }
  
  async guardarReserva() {
    if (this.permisos !== null ) {
      if (this.permisos.PERTODO === 0 ) {
        if (this.permisos.PERINSERCION === 0 ) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }
    
    for (const item of this.reservasService.detalleReservas[this.indicador]) {
      if (item.TRNRES_NUMERO === '') {
        this.detalleSeleccionado = item;
      }
    }
    
    if (String(this.detalleSeleccionado.BOD_CODIGO).length === 0) {
      this.mensajeAlerta('warn', 'Información', 'Ingrese la bodega');
      return;
    }
    if (String(this.detalleSeleccionado.BOD_CODIGODES).length === 0) {
      this.mensajeAlerta('warn', 'Información', 'Ingrese la bodega de destino');
      return;
    }
    if (String(this.detalleSeleccionado.ART_CODIGO).length === 0) {
      this.mensajeAlerta('warn', 'Información', 'Ingrese el artículo');
      return;
    }
    
    if (Number(this.detalleSeleccionado.TRNRES_CANTIDAD) <= 0) {
      this.mensajeAlerta('warn', 'Información', 'La cantidad de la reserva no puede ser menor o igual a cero');
      return;
    }
    
    
    
    try {
      
      
      if (this.detalleSeleccionado.TRNRES_NUMERO !== '') {
        await this.reservasService.actualizarReserva(this.detalleSeleccionado);
        this.auditoriaGralService.registrarAuditoria('INV_TRNRESERVA',  this.detalleSeleccionado.TRNRES_NUMERO + '/' +
          this.detalleSeleccionado.ART_CODIGO, 'A',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
        this.mensajeAlerta('success', 'Información', 'Reserva actualizada exitosamente.. !!!');
      } else {
        const rs = await this.reservasService.guardarReserva(this.detalleSeleccionado);
        this.reservasService.detalleReservas[this.indicador].map(item => {
          if (this.detalleSeleccionado.LINEA === item.LINEA && item.TRNRES_NUMERO === this.detalleSeleccionado.TRNRES_NUMERO) {
            item.TRNRES_NUMERO = rs[0].NUMERO;
          }
        });
        this.detalleSeleccionado.TRNRES_NUMERO = rs[0].NUMERO;
        
        this.auditoriaGralService.registrarAuditoria('INV_TRNRESERVA',  this.detalleSeleccionado.TRNRES_NUMERO + '/' +
          this.detalleSeleccionado.ART_CODIGO, 'I',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
        this.mensajeAlerta('success', 'Información', 'Reserva guardada exitosamente.. !!!');
      }
      
      this.habilitarBotones(false, true, false, false);
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso guardar reserva no terminó satisfactoriamente ! ' +  mensaje);
    }
    
    
  }
  
  confEliminarReserva() {
    if (Object.keys(this.detalleSeleccionado).length > 0) {
      if (this.detalleSeleccionado.TRNRES_USUARIO !== this.usuario.identificacion) {
        this.mensajeAlerta('info', 'Información', 'No se puede eliminar una reserva generada por otro usuario');
        return;
      }
      
      if (this.detalleSeleccionado.TRNRES_ENTREGADO !== '') {
        this.mensajeAlerta('info', 'Información', 'No se puede eliminar una reserva que ha sido despachada');
        return;
      }
      
      if (this.detalleSeleccionado.TRNRES_ESTADO !== '') {
        this.mensajeAlerta('info', 'Información', 'La reserva ya se encuentra en transferencias');
        return;
      }
      
      this.confirmationService.confirm({
        message: 'Está seguro de eliminar la reserva ?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'invreservaconf',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          this.eliminarReserva();
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
      
    } else {
      this.mensajeAlerta('warn', 'Información', 'Seleccione una reserva');
    }
  }
  
  async eliminarReserva() {
    try {
      
      await this.reservasService.eliminarReserva(this.detalleSeleccionado);
      
      this.reservasService.detalleReservas[this.indicador].splice(this.reservasService.detalleReservas[this.indicador].findIndex(
        item => item.LINEA === this.detalleSeleccionado.LINEA &&
          item.TRNRES_NUMERO === this.detalleSeleccionado.TRNRES_NUMERO),
        1);
      this.aggrid.refreshaggrid(this.reservasService.detalleReservas[this.indicador], this.defaultColDefDetalle.objeto);
      this.auditoriaGralService.registrarAuditoria('INV_TRNRESERVA', this.detalleSeleccionado.TRNRES_NUMERO + '/' +
        String(this.detalleSeleccionado.ART_CODIGO) , 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
      this.mensajeAlerta('success', 'Información', 'Reserva eliminada exitosamente...!!!');
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  }
  
  async selecciondetalle(params) {
    if (params.object === undefined) {
      return;
    }
    
    this.detalleSeleccionado = params.object;
    
  }
  
  manejarSeleccion(valor) {
    if (this.opcion === 'BUSCAR') {
      this.displayDialogBusquedaFast = false;
      this.llenarTablaBusqueda(valor);
    }
    if (this.opcion === 'BuscarBodega') {
      this.displayDialogBusquedaFast1 = false;
      this.txtBodNombre = valor.BOD_NOMBRE;
      this.txtBodCodigo = valor.BOD_CODIGO;
      this.listarReservas('T', valor.BOD_CODIGO);
    }
  }
  
  buscarRegistro() {
    this.opcion = 'BUSCAR';
    this.types = [
      {label: 'FECHA RESERVA', value: 'TRNRES_FECHA'},
      {label: 'FECHA CADUCA', value: 'TRNRES_CADUCA'},
      {label: 'USUARIO', value: 'TRNRES_USUARIO'},
      {label: 'ARTÍCULO', value: 'ART_CODIGO'},
    ];
    
    this.busquedacampos = ['', '', '', '', ''];
    this.consulta = ' r.*, INV_FNC_OBTENER_NOMARTICULO(ART_CODIGO, COM_CODIGO)  as ART_NOMBRE, (select trnres_numero from inv_dettransfer ' +
      ' where trnres_numero = r.trnres_numero) as TRANSFERENCIA ';
    this.tabla = 'INV_TRNRESERVA r';
    this.condicion = ' bod_codigo = \'' + this.txtBodCodigo + '\'';
    this.arregloCons = [];
    this.displayDialogBusquedaFast = true;
  }
  
  llenarTablaBusqueda(valor) {
    const detalle: InvTrnReservas[] = [];
    let num = 0;
    for (const res of valor) {
      const item: InvTrnReservas = {};
      
      item.TRNRES_NUMERO = res.TRNRES_NUMERO;
      item.COM_CODIGO = res.COM_CODIGO;
      item.TRNRES_USUARIO = res.TRNRES_USUARIO;
      item.BOD_CODIGO = res.BOD_CODIGO;
      item.ART_CODIGO = res.ART_CODIGO;
      item.ART_NOMBRE = res.ART_NOMBRE;
      item.TRNRES_CANTIDAD = Number(res.TRNRES_CANTIDAD).toFixed(2);
      item.TRNRES_FECHA = this.datePipe.transform(res.TRNRES_FECHA, 'dd/MM/yyyy HH:mm');
      item.TRNRES_CADUCA = this.datePipe.transform(res.TRNRES_CADUCA, 'dd/MM/yyyy HH:mm');
      item.TRNRES_ENTREGADO = res.TRNRES_ENTREGADO === null ? '' : Number(res.TRNRES_ENTREGADO).toFixed(2);
      item.LINEA = num ++;
      item.TRNRES_OBSERVACION = res.TRNRES_OBSERVACION;
      item.ART_UNIDAD = res.ART_UNIDAD;
      item.BOD_CODIGODES = res.BOD_CODIGODES;
      item.TRNRES_ESTADO = res.TRNRES_ESTADO === null ? '' : res.TRNRES_ESTADO;
      detalle.push(item);
    }
    this.reservasService.detalleReservas[this.indicador] = detalle;
    
  }
  
  habilitarBotones(nuevo, guardar, eliminar, buscar) {
    this.botonNuevo = nuevo;
    this.botonGuardar = guardar;
    this.botonBorrar = eliminar;
    this.botonBuscar = buscar;
  }
  
  isEdit(data): boolean {
    if (data.TRNRES_ENTREGADO !== '') {
      return false;
    }
    if (data.TRNRES_USUARIO !== this.usuario.identificacion) {
      return false;
    }
    
    if (data.TRNRES_ESTADO !== '') {
      return false;
    }
    return true;
  }
  
  cambio(event) {
    this.habilitarBotones(true, false, true, true);
  }
  
  buscarBodega(parametro) {
    this.opcion = 'BuscarBodega';
    this.types = [
      {label: 'Codigo', value: 'BOD_CODIGO'},
      {label: 'Nombre', value: 'BOD_NOMBRE'}
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = 'BOD_CODIGO, BOD_NOMBRE';
    this.tabla = 'INV_MAEBODEGA';
    this.where = '';
    
    this.reservasService.erBuscarBodega(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaInvMaeBodega4().subscribe((res: any[]) => {
            this.arregloCons = res;
            this.displayDialogBusquedaFast1 = true;
          });
        }
      } else {
        this.busquedaSer.busquedaInvMaeBodega4().subscribe((res: any[]) => {
          this.arregloCons = res;
          this.displayDialogBusquedaFast1 = true;
        });
      }
    });
  }
  
  ocultarCaducados() {
    if (this.chkcaducados) {
      this.chkentregados = false;
      this.listarReservas('NC', this.txtBodCodigo);
    } else {
      this.listarReservas('T', this.txtBodCodigo);
    }
  }
  
  visualizarDespachados() {
    if (this.chkentregados) {
      this.chkcaducados = false;
      this.listarReservas('E', this.txtBodCodigo);
    } else {
      this.listarReservas('T', this.txtBodCodigo);
    }
  }
  
  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'invreserva',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
  
}
