import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {VenEncfacZ} from '../veninterfaces/venencfacz';
import {VenDetfacZ} from '../veninterfaces/vendetfacz';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {DatePipe} from '@angular/common';
import {MessageService} from 'primeng/api';
import {VenencfacService} from '../venservicios/venencfac.service';
import {DatosVentaArt} from '../veninterfaces/datosventaart';

@Component({
  selector: 'app-datosventaarticulo',
  templateUrl: './datosventaarticulo.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class DatosventaarticuloComponent implements OnInit, OnChanges {
  
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  @Input() detalle: VenDetfacZ;
  @Input() encfac: VenEncfacZ;
  @Output() cerrar = new EventEmitter<boolean>();
  
  largo: string;
  datos: DatosVentaArt[];
  
  strCodigo: string;
  strDescripcion: string;
  
  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  defaultColDefDetalle;
  columnDefsDetalle = [
    { // 0
      headerName: 'Fecha', field: 'ENCFAC_FECHA', width: 150,
    }, { // 1
      headerName: 'Nro.Factura', field: 'ENCFAC_NUMERO', width: 110,
    }, { // 2
      headerName: 'Código', field: 'DETFAC_CODIGO', width: 110,
    }, { // 3
      headerName: 'Nombre', field: 'DETFAC_DESCRIPCION', width: 110,
    }, { // 4
      headerName: 'Unidad', field: 'DETFAC_UNIDAD', width: 110,
    }, { // 5
      headerName: 'Cantidad', field: 'DETFAC_CANTIDAD', width: 110,
    }, { // 6
      headerName: 'Precio', field: 'DETFAC_PRECIO', width: 110,
    }, { // 7
      headerName: 'Dsc.', field: 'DETFAC_DESCUENTO', width: 110,
    }, { // 8
      headerName: 'Total', field: 'DETFAC_TOTAL', width: 110,
    }];
  
  
  constructor(private encfacservice: VenencfacService, private datePipe: DatePipe, private messageService: MessageService) {
    this.agTable();
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    
    this.defaultColDefDetalle = {
      editable: true,
      width: 80,
      objeto: 'datosvtaart'
    };
  }
  
  
  ngOnInit() {
    this.largo = '700';
    
    if (this.detalle !== undefined) {
      this.detalle = {DETFAC_CODIGO: ''};
    }
    
    this.strCodigo = '';
    this.strDescripcion = '';
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    for (const e in changes) {
      if (e === 'detalle' && this.detalle !== null && this.detalle !== undefined) {
        this.strCodigo = this.detalle.DETFAC_CODIGO;
        this.strDescripcion = this.detalle.DETFAC_DESCRIPCION;
        this.mostrarDatos();
      }
    }
  }
  
  
  mostrarDatos() {
    if (this.detalle.DETFAC_CODIGO !== undefined) {
      this.encfacservice.listarDatosArticulos(this.encfac.CLI_CODIGO, this.strCodigo).subscribe(data => {
        const dato = [];
        for (const rs of data) {
          dato.push(rs);
        }
        this.datos = dato;
      });
    }
  }
  
}
