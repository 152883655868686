import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
@Injectable({
  providedIn: 'root'
})
export class ConinicontadorService {
  private way: string;
  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario, private confIniciales: ConfInicialesService) {
      this.way = sessionStorage.getItem('way');
     }
     obtenerClave(){
      let cla="AUTTIPD";
      /* let sql1= 'select AUTORIZACION_CLAVE from SEG_MAEAUTORIZACION where AUTORIZACION_CODIGO = \'' + cla + '\'';
      console.log(sql1); */
      return this.http.post<any>(this.way + '/cont/inicontadores/30bebaecaabee', {
        elementos: {
          clave: cla
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }

    inicializarContadores(ref:string, opcion:string){
      return this.http.post<any[]>(this.way + '/cont/inicontadores/4af2662fd5371c', {
        elementos: {
          p_referencia:ref,
          p_com_codigo:'01',
          p_opcion:opcion,
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    cambiarNumeracion(ref:string){
      return this.http.post<any[]>(this.way + '/cont/inicontadores/31565cafeb3b6a', {
        elementos: {
          p_referencia:ref,
          p_com_codigo:'01'
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
}
