import { Component, EventEmitter,OnInit,Input, Output,ViewChild } from '@angular/core';
import {DatePipe} from '@angular/common';
import {AggridBanComponent} from '../../ban/aggrid-ban/aggrid-ban.component';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import { nomPerEmpl } from '../nominterfaces/nomperemple';
import { NomperemplService } from '../nomservicios/nomperempl.service';
import { isNullOrUndefined } from 'util';
import { MessageService } from 'primeng';
@Component({
  selector: 'app-nomempleadoperiodo',
  templateUrl: './nomempleadoperiodo.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NomempleadoperiodoComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  @Input() cuotas: string;
  @Input() fechareg: string;
  @Output() salirclo= new EventEmitter<string>();
  @Output() resp= new EventEmitter<string[]>();
  respuesta:string[]=[];
  largo: string;
  allSelected: boolean;
  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  fechaImp:string="";
  cuota:number=0;
  selectednomPerEmpl:nomPerEmpl;
  defaultColDef;
  columnDefsDetPerEmpl = [
    {
      headerName: '', field: 'CHECK', cellRendererFramework: AggridBanComponent, editable: false, width: 50, checkCierre: false, onCellClicked: (params) => {
        this.seleccionItem(params.data);  
      },
    },
    { 
      headerName: 'Descripción', field: 'PERIODO_DESPRICION', width: 200, editable: false,
    },
    { 
      headerName: 'Fec.Ini.', field: 'PERIODO_FECINI', editable: false, width: 200
    },
    { 
      headerName: 'Fec.Fin.', field: 'PERIODO_FECFIN', editable: false, width: 200
    },
   
  ];
  constructor(private datePipe: DatePipe,
              public init: NuevoComponentService,
              private messageService: MessageService,
              public perservice: NomperemplService) { 
    this.agTable();
  }

  ngOnInit(): void {
    this.largo = '500';
    this.fechaImp=this.perservice.cambiarfechanull(this.fechareg);
    this.parseCouta(this.cuotas);
    this.perservice.empleadoPeriodo().then((res:any)=>{
      this.perservice.nomPerEmpl=res;
      this.perservice.nomPerEmpl.map(x=>{
        x.CHECK=false;
        x.PERIODO_FECFIN=this.datePipe.transform(x.PERIODO_FECFIN,'dd/MM/yyyy');
        x.PERIODO_FECINI=this.datePipe.transform(x.PERIODO_FECINI,'dd/MM/yyyy');
      });
    })
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDef = {
      editable: true,
      width: 100,
      objeto: 'nomPerEmple'
    };
  }
  parseCouta(cout){
    if(isNullOrUndefined(cout)||cout==""){
      this.cuota=0;
    }else{
      this.cuota=parseInt(cout);
    }
  }
  
  selectPeriodo(elemento){
    if (elemento.object !== null) {
      this.selectednomPerEmpl = elemento.object;
    }
  }
  aceptar(){
    let vart:string[]=[];
    let index= this.perservice.nomPerEmpl.map(x=>{return x.CHECK}).indexOf(true);
    let fechaperini=this.perservice.cambiarfechanull(this.selectednomPerEmpl.PERIODO_FECINI);
    let fechaperfin=this.perservice.cambiarfechanull(this.selectednomPerEmpl.PERIODO_FECFIN);
    let mayor:boolean=this.perservice.validarMayor(this.fechareg,fechaperini, fechaperfin);
    let dis=this.perservice.validaPeriodosDisponible(this.selectednomPerEmpl.PERIODO_CODIGO,this.cuota)
    let valor=this.perservice.validarSoloSelectarUno();
    switch(valor){
      case 1:
        this.mensajeAlerta('error', 'Error','Debe seleccionar un período');
        break;
      case 2:
        this.mensajeAlerta('error', 'Error','Solo debe seleccionar un período');
        break;
      case 3:
       
        if(mayor==true){
          if(dis==true){
            let fechafin=this.perservice.fechaFinal(this.selectednomPerEmpl.PERIODO_CODIGO,this.cuota);
            this.salirclo.emit('salir');
            vart.push(this.selectednomPerEmpl.PERIODO_FECINI);
            vart.push(fechafin);
            vart.push(this.selectednomPerEmpl.PERIODO_CODIGO);
            this.resp.emit(vart);
            this.salirclo.emit('salir');
          }else{
            this.mensajeAlerta('error', 'Error','No existen suficientes periodos para el número de cuotas');
          }
        }else{
          this.mensajeAlerta('error', 'Error','Error, la fecha del período no puede ser menor a '+this.fechaImp);
        }
        break;
    }
  
  }
  cambioPeriodo(event){

  }
  enviarSalir(){
    this.salirclo.emit('salir');
  }
  seleccionItem(data){
    let index= this.perservice.nomPerEmpl.map(x=>{return x.PERIODO_CODIGO}).indexOf(data.PERIODO_CODIGO);
    this.perservice.nomPerEmpl[index].CHECK=data.CHECK;
  }
  mensajeAlerta(tipo, titulo, msg) {
    this. messageService.add({
      key: 'maEmplPer',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
}
