import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from 'src/app/usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';


@Injectable({
  providedIn: 'root'
})
export class NomencrolgenecabeService {


  private way: string;
 
  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) {
      this.way = sessionStorage.getItem('way');
      
     }

 

    buscarPeriodo() {

      return this.http.post<any[]>(this.way + '/nom/liquidacionrol/poia6cwo7pz8qw3', {
        elementos: {
          p_com_codigo: '01'
        },
      }, this.confIniciales.httpOptions()).toPromise();
      
    }


    erEmpleadoRucide(strCampo, strTabla, strCondicion){
      
      return this.http.post(this.way + '/nom/liquidacionrol/h41qk5pr1edpl0d', {  
        datos: {
          Campo: strCampo,
          Valor: strTabla,
          Condicion: strCondicion
        },  
      }, this.confIniciales.httpOptions()).toPromise();

    }

//GSRF
buscatodosvac(perCodigo) {

  return this.http.post<any[]>(this.way + '/nom/liquidacionrol/1dc371cfa98a', {
    elementos: {
      PER_CODIGO: perCodigo
    },
  }, this.confIniciales.httpOptions()).toPromise();
  
}
buscaalgunosvac(perCodigo,emp1,emp2) {

  return this.http.post<any[]>(this.way + '/nom/liquidacionrol/2ab0f5f1cfa98a', {
    elementos: {
      PER_CODIGO: perCodigo,
      EMP_CODIGO_1:emp1,
      EMP_CODIGO_2:emp2
    },
  }, this.confIniciales.httpOptions()).toPromise();
  
}

//GSRF


    genCabeceraDetalle( perCodigo, tipRolCodIni, tipRolCodFin, empNombreIni, empNombreFin,  procAntquinc ){
      return this.http.post(this.way + '/nom/liquidacionrol/jtwd1enbv64r10n', {
        elementos: {

          p_periodo_codigo: perCodigo,
          p_tiprol_codigo_ini: tipRolCodIni,
          p_tiprol_codigo_fin: tipRolCodFin,
          p_emp_nombre_ini: empNombreIni,
          p_emp_nombre_fin: empNombreFin,
          p_com_codigo: '01',
          p_usuidentificacion: this.usuario.identificacion,
          p_proc_antiquinc: procAntquinc

        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
}
