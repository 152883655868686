//
// SACI WEB
// Rutina: Ingreso de Pedidos
// Propietario: Wilson Ramos
// Modificado por: WR
// Fecha de creación: 14-01-2020
// Fecha de Modificación: 20-01-2020
//
import { Component, OnInit, ViewChild } from '@angular/core';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { Comdetordcom } from '../cominterfaces/comdetordcom';
import { Comencordcom } from '../cominterfaces/comencordcom';
import { ComencordcomService } from '../comservicios/comencordcom.service';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { Usuario } from '../../usuario';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { DatePipe } from '@angular/common';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { UtilitariosService } from '../../advantage/advantageservices/utilitarios.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { ComExistenciaBodegas, ComExistencias, ComMovimientoProv, ComTopProductos } from '../cominterfaces/comestadisticas';
import { VenestadisticaService } from '../../ven/venservicios/venestadistica.service';
import { ComestadisticasService } from '../comservicios/comestadisticas.service';
import { Comencordcombasesiba } from "../cominterfaces/comencordcombasesiva";//CDPJ

@Component({
  selector: 'app-comencordcom',
  templateUrl: './comencordcom.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class ComencordcomComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;
  btnNuevoGrid: boolean;
  btnGuardarGrid: boolean;
  btnBorrarGrid: boolean;
  btnCancelarGrid: boolean;
  btnImagenGrid: boolean;
  btnVerificarExis: boolean;
  botonAnular: boolean;//GSRF
  largo: string;
  archivoSubido: string;
  dscProveedorActual: number;
  decimalesCantidadOrd: number;
  decimalesPrecioOrd: number;
  decimalesTotalOrd: number;
  numdecsist: number;
  imagenProducto: string;
  valorInvalidoV: boolean;
  valorInvalidoP: boolean;
  consulta: string;
  where: string;
  nombreProveedor: string;
  displayDialogDescuento: boolean;
  configuracionExistencia: number;
  permisoOrden: SegMaePermiso;
  busquedacampos: any[];
  arregloCons: any[];
  tabla: string;
  bodCodigo: string;
  indice: number;
  claveAuth: string;
  claveDialog: boolean;
  dscSeleccionado: string;
  valorDscValor: number;
  valorDscPorcentaje: number;
  opcion: string;
  types: SelectItem[];
  fpValor: string;
  opcionTipoCompra: SelectItem[];
  opcionEstadoCompra: SelectItem[];
  fechaBoolean: boolean;
  booleanValor: boolean;
  displayAcciones: boolean;
  booleanPorcentaje: boolean;
  public frameworkComponents;
  encabezadosOrdenes: Comencordcom[];
  detalleOrdenSeleccionada: Comdetordcom;
  detalleOrdenSeleccionadaAux: Comdetordcom;
  encabezadoOrdenSeleccionadaAux: Comencordcom;
  comboOpciones: any[];
  arregloBus: any[];
  displayDialogBus: boolean;
  displayDialogBusquedaFast: boolean;
  rowStyle;
  defaultOrdenDetalle;
  rowSelection = 'multiple';
  tipodetalle = '';
  indicador: any;

  // ESTADISTICAS

  detTopProductos: ComTopProductos[] = [];
  dataComprasProveedor: any;
  dataVentasVsCompras: any;
  disabledEstadisticas = true;
  options = {
    legend: {
      display: false
    }
  };
  defaultColTopProductos;
  defaultColMovByProv;
  defaultColExistBod;
  rowStyleTopProd;
  detalleExistencia: ComExistencias[] = [];
  detMovimientosProv: ComMovimientoProv[] = [];
  detExistenciaBodegas: ComExistenciaBodegas[] = [];
  txtArtCodigo = '';
  txtArtDescripcion = '';
  txtArtUnidad = '';
  txtArtRotacion = '';
  txtExistenciaMin: any = '';
  txtExistenciaMax: any = '';
  txtInventarioIni: any = '';
  txtComprasPeriodo: any = '';
  txtTotalDisponible: any = '';
  txtVenUltimoPer: any = '';
  txtVenPromPerAnt: any = '';
  txtIndiceProvUltPer: any = '';
  txtIndiceProvPeriodos: any = '';
  txtComprasPlanificadas: any = '';
  txtTotalDisponibleCom: any = '';
  txtIndiceProvUltPer2: any = '';
  txtIndiceProvPeriodos2: any = '';
  index: number;
  spin = false;//GSRF
  //CDPJ
  displaycambioestado = false;
  codigogre = '';
  numerogre = '';
  fechagre = '';
  cligre = '';
  totgre = '';
  codigofac = '';
  fechafac = '';
  clifac = '';
  clinomfac = '';
  totfac = '';
  accion='';
  boolIva:boolean=true;
  cmbtipoIva: any[];
  selectcombtipoIva: any;
  iva:number
  displaybasesiva:boolean=false;
  basesiva:Comencordcombasesiba[]=[]
  defaultColBasesIva;
  //CDPJ
  displayDialogReportes: boolean;
  auxreporte: number = 0;
  strNombreForma: string;

  displayDialogDocumentos = false;

  columnDefsOrdenDetalle = [
    { // 0
      headerName: 'Bodega', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Código', field: 'DETORDCOM_CODIGO', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 20,
          opcionbuscar: true
        }
      }
    },
    { // 2
      headerName: 'Descripción', field: 'DETORDCOM_DESCRIPCION', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 80
        }
      }
    },
    { // 3
      headerName: 'IVA', field: 'DETORDCOM_TRIBIVA', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          regexp:/^[S]$|^[N]$|^[s]$|^[n]$/,
          tamanocelda: 1,
          tienecalculos: true
        }
      }
    },
    { // 4
      headerName: 'ICE', field: 'DETORDCOM_TRIBICE', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          regexp: /^[S]$|^[N]$/,
          tamanocelda: 1,
          tienecalculos: true
        }
      }
    },
    { // 5
      headerName: 'Unidad', field: 'DETORDCOM_UNIDAD', cellEditor: 'agSelectCellEditor', editable: true, width: 100,
      cellEditorParams:
      {
        values: []
      }
    },
    { // 6
      headerName: 'Cantidad', field: 'DETORDCOM_CANTIDAD', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 2,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    { // 7
      headerName: 'Recibido', field: 'DETORDCOM_DESPACHO', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      //GSRF     
      editable: (params) => {
        return false;
      },
      //GSRF
      /*cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            int: true
          }
        }*/
    },
    { // 8
      headerName: 'Costo', field: 'DETORDCOM_COSTO', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 5,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    { // 9
      headerName: 'Total', field: 'DETORDCOM_TOTAL', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          int: true
        }
      }
      
    },
    { // 10
      headerName: '%IVA', field: 'DETORDCOM_PORIVA', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          int: true,
          opcionbuscar: true
        }
      }
    }
  ];

  columnDefsTopProductos = [
    {
      headerName: 'Producto', field: 'DETFACPRO_CODIGO', width: 115, editable: false
    },
    {
      headerName: 'Descripción', field: 'DETFACPRO_DESCRIPCION', width: 190, editable: false
    },
    {
      headerName: 'Cantidad', field: 'CANTIDAD_TOTAL', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Precio', field: 'DETFACPRO_COSTO', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Total', field: 'TOTAL', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Stock', field: 'STOCK', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];

  columnDefsMovimientosByProv = [
    {
      headerName: 'Proveedor', field: 'PRO_CODIGO', width: 115, editable: false
    },
    {
      headerName: 'Nombre', field: 'PRO_NOMBRE', width: 190, editable: false
    },
    {
      headerName: 'Fecha Compra', field: 'ENCFACPRO_FECHAEMISION', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Costo', field: 'DETFACPRO_COSTO', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];

  columnDefsExistenciaBodegas = [
    {
      headerName: 'Código', field: 'BOD_CODIGO', width: 115, editable: false
    },
    {
      headerName: 'Nombre', field: 'BOD_NOMBRE', width: 190, editable: false
    },
    {
      headerName: 'Existencia', field: 'EXISTENCIA', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Pedidos', field: 'PEDIDO', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Saldo', field: 'SALDO', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];
  //CDPJ
  columnDefsBasesIva = [
    {
      headerName: '%IVA', field: 'TARIIVA_DESCRIPCION', width: 115, editable: false
    },
    {
      headerName: 'Base', field: 'IVA_BASE', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Valor IVA', field: 'IVA_VALOR', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];
  //CDPJ

  constructor(public encordService: ComencordcomService, public usuario: Usuario, private errorService: ErroresBaseDatosService,
    private message: MessageService, private confIniciales: ConfInicialesService, private busquedaSer: InvbusquedaService,
    private datePipe: DatePipe, private permisosService: PermisosService, private auditoriagral: AuditoriagralService,
    private confirmationService: ConfirmationService, private utilitariosService: UtilitariosService,
    private init: NuevoComponentService, private venEstadisticasService: ComestadisticasService) {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.rowStyleTopProd = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if (params.data.DETFACPRO_CODIGO === '' || params.data.DETFACPRO_CODIGO === null) {
        return { background: 'rgb( 164, 255, 167)' };
      }
    };
    this.defaultOrdenDetalle = {
      editable: true,
      width: 100,
      objeto: 'comdetordcom'
    };
    this.defaultColTopProductos = {
      editable: true,
      width: 100,
      objeto: 'topProductos',
      resizable: true
    };

    this.defaultColMovByProv = {
      editable: true,
      width: 100,
      objeto: 'movproveedores',
      resizable: true
    };

    this.defaultColExistBod = {
      editable: true,
      width: 100,
      objeto: 'existenciabodegas',
      resizable: true
    };
    this.defaultColBasesIva = {
      editable: false,
      width: 100,
      objeto: 'basesiva',
    };
  }

  async ngOnInit() {
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.encordService.encOrdSeleccionadaarray[this.indicador] = {};
    this.encordService.detordenesarray[this.indicador] = [];
    this.detalleOrdenSeleccionada = {};
    this.detalleOrdenSeleccionadaAux = {};
    this.encabezadoOrdenSeleccionadaAux = {};
    this.opcionTipoCompra = [];
    this.opcionEstadoCompra = [];
    this.encordService.refrescarURL();
    this.botonAnular= false;
    this.permisosService.getListaPermisos('COM', '6', '667').subscribe((permiso) => {
      this.permisoOrden = permiso[0];
    });
    this.largo = '-5800';
    this.archivoSubido = '';
    this.indice = 0;
    this.nombreProveedor = '';
    this.bodCodigo = '';
    this.fpValor = '';
    this.dscProveedorActual = 0;
    this.imagenProducto = '';
    this.displayDialogDescuento = false;
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.btnNuevoGrid = true;
    this.btnGuardarGrid = true;
    this.btnCancelarGrid = true;
    this.btnBorrarGrid = true;
    this.btnImagenGrid = false;
    this.btnVerificarExis = false;
    this.botonPrimero = false;
    this.botonAnterior = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
    this.fechaBoolean = false;
    this.claveDialog = false;
    this.displayAcciones = false;//CDPJ
    this.claveAuth = '';
    this.displayDialogBusquedaFast = false;
    this.iva = await this.confIniciales.obtenerImpuesto('I', 'A');
    await this.confIniciales.obtenerSrifeTariIva()
    this.numdecsist = this.confIniciales.getNumDecSist();
    await this.llenarcombo()//CDPJ
    this.selectcombtipoIva={codigo:'12',nombre:'12'}
    this.encordService.dblPorcIvaPed=Number(this.selectcombtipoIva.codigo)
    this.opcionTipoCompra.push({ label: '', value: null });
    this.opcionEstadoCompra.push({ label: '', value: null });
    this.encordService.obtenerOpcionesDrop('TIPOOC').subscribe((opciones) => {
      opciones.map((opc) => {
        this.opcionTipoCompra.push({ label: opc.REF_NOMBRE, value: opc.REF_NOMBRE });
      });
    });
    this.encordService.obtenerOpcionesDrop('ESTOC').subscribe((opciones) => {
      opciones.map((opc) => {
        this.opcionEstadoCompra.push({ label: opc.REF_NOMBRE, value: opc.REF_NOMBRE });
      });
    });
    this.encordService.getEncOrden().subscribe((res) => {
      this.encabezadosOrdenes = res;
    });
    this.tipodetalle = 'A';
    this.comboOpciones = [{ label: 'A', value: 'A' },
    { label: 'S', value: 'S' }, { label: '*', value: '*' }];

    const dec: any = await this.confIniciales.getDecimalesP('frmCOM_ENCORDCOM');
    if (dec !== null) {
      dec.map((decimales) => {
        if (decimales.NUMDEC_CAMPO === 'COSTO') {
          this.decimalesPrecioOrd = Number(decimales.NUMDEC_NUMDEC);
          this.encordService.decimalesPrecioOrd = this.decimalesPrecioOrd;
        } else if (decimales.NUMDEC_CAMPO === 'COSTOTOTAL') {
          this.decimalesTotalOrd = Number(decimales.NUMDEC_NUMDEC);
          this.encordService.decimalesTotalOrd = this.decimalesTotalOrd;
        } else if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
          this.decimalesCantidadOrd = Number(decimales.NUMDEC_NUMDEC);
          this.encordService.decimalesCantidadOrd = this.decimalesCantidadOrd;
        }
        //CDPJ
        this.columnDefsOrdenDetalle[6].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 2,
            newrow: false,
            numerodecimales: this.encordService.decimalesCantidadOrd,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        };
        this.columnDefsOrdenDetalle[8].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 2,
            newrow: false,
            numerodecimales: this.encordService.decimalesPrecioOrd,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        };
        this.columnDefsOrdenDetalle[9].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 2,
            newrow: false,
            numerodecimales: this.encordService.decimalesTotalOrd,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        };
        //CDPJ
      });
    }

    // this.columnDefsOrdenDetalle[6].cellEditorParams = {
    //   values: {
    //     mayusculas: false,
    //     numeros: true,
    //     alphabetic: false,
    //     saltoslinea: 2,
    //     newrow: false,
    //     numerodecimales: this.encordService.decimalesCantidadOrd,
    //     obligatorio: false,
    //     fecha: false,
    //     tamanocelda: 32,
    //     tienecalculos: true,
    //     int: true
    //   }
    // };

    setTimeout(() => {
      this.aggrid.refreshColumnDefsIndice(this.indicador, this.defaultOrdenDetalle.objeto, this.columnDefsOrdenDetalle);
    }, 2000);
  }
  //CDPJ
  ngOnDestroy(): void {
    this.confIniciales.instanciasOrdCom--;
  }
  llenarcombo(){
    // this.cmbtipoIva = [
    // { codigo: '12', name: '12' },
    // { codigo: '8', name: '8' }
    // ];
    //CDPJ
    this.cmbtipoIva=[];
    for(let i=0;  this.confIniciales.srifetariiva[i];i++){
      let item=this.confIniciales.srifetariiva[i]
      if(item.TARIIVA_CODIGO !=='6' &&  item.TARIIVA_CODIGO !=='7'  && item.TARIIVA_CODIGO !== '-1'){
        this.cmbtipoIva.push({codigo:item.TARIIVA_CODIGO,name:item.TARIIVA_PORCENTAJE.toString()})
      }
       
    }
    console.log(this.cmbtipoIva)
    for(const data of this.cmbtipoIva){
      if(Number(data.name) === Number(this.iva)){
        this.selectcombtipoIva={codigo:data.codigo,name:data.name}
        this.encordService.porcetariiva=Number(this.selectcombtipoIva.name)
        this.encordService.codtariiva=this.selectcombtipoIva.codigo
        this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_PORCEIVA=this.encordService.porcetariiva
      }
    }
    
    //CDPJ
  }
  cambiavalorIva(select){
    this.selectcombtipoIva.codigo=select.value.codigo
    this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_PORCEIVA=Number(select.value.name);
    //console.log(this.encordService.dblPorcIvaPed)
    this.encordService.porcetariiva=Number(select.value.name)
    this.encordService.codtariiva=select.value.codigo
  }
//CDPJ
  async manejarSenales(valor) {
    if (this.index === 2) {
      return;
    }
    if (valor === 'Primero') {
      this.indice = 0;
      if (this.botonUltimo === true) {
        this.botonUltimo = !this.botonUltimo;
      }
      if (this.botonSiguiente === true) {
        this.botonSiguiente = !this.botonSiguiente;
      }
      this.botonPrimero = true;
      this.botonAnterior = true;
      this.encordService.encOrdSeleccionadaarray[this.indicador] = this.encabezadosOrdenes[this.indice];
      this.obtenerDatos();
    }

    if (valor === 'Siguiente') {
      this.indice++;
      this.encordService.encOrdSeleccionadaarray[this.indicador] = this.encabezadosOrdenes[this.indice];
      if (this.botonPrimero === true && this.botonAnterior === true) {
        this.botonPrimero = false;
        this.botonAnterior = false;
      }
      if (this.indice >= this.encabezadosOrdenes.length - 1) {
        this.botonUltimo = true;
        this.botonSiguiente = true;
        if (this.indice === this.encabezadosOrdenes.length - 1) {
          this.obtenerDatos();
        } else {
          return;
        }
      } else {
        this.obtenerDatos();
      }
    }

    if (valor === 'Anterior') {
      this.indice--;
      this.encordService.encOrdSeleccionadaarray[this.indicador] = this.encabezadosOrdenes[this.indice];
      if (this.botonUltimo === true && this.botonSiguiente === true) {
        this.botonUltimo = false;
        this.botonSiguiente = false;
      }
      if (this.indice <= 0) {
        this.botonPrimero = true;
        this.botonAnterior = true;
        if (this.indice === 0) {
          this.obtenerDatos();
        } else {
          return;
        }
      } else {
        this.obtenerDatos();
      }
    }

    if (valor === 'Ultimo') {
      this.indice = this.encabezadosOrdenes.length - 1;
      this.encordService.encOrdSeleccionadaarray[this.indicador] = this.encabezadosOrdenes[this.indice];
      if (this.botonPrimero === true) {
        this.botonPrimero = !this.botonPrimero;
      }
      if (this.botonAnterior === true) {
        this.botonAnterior = !this.botonAnterior;
      }
      this.botonSiguiente = true;
      this.botonUltimo = true;
      this.obtenerDatos();
    }

    if (valor === 'Buscar') {
      this.buscarOrdenes();
    }

    if (valor === 'Nuevo') {
      if (this.permisoOrden.PERINSERCION === 1) {
        this.nuevaOrden('N');
      } else if (this.permisoOrden.PERINSERCION === 0) {
        this.message.add({
          key: 'ordenCom',
          severity: 'error',
          summary: 'Inserción de Orden',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    }

    if (valor === 'Guardar') {
      this.guardar();
    }

    if (valor === 'Borrar') {
      if (this.permisoOrden.PERELIMACION === 1) {
        this.confirmarOrden();
      } else if (this.permisoOrden.PERELIMACION === 0) {
        this.message.add({
          key: 'ordenCom',
          severity: 'error',
          summary: 'Eliminación de Orden',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
      }
    }

    if (valor === 'Cancelar') {
      this.cancelarEdicionOrden();
    }

    if (valor === 'Descuento') {
      if (this.encordService.encOrdSeleccionadaarray[this.indicador] !== undefined) {
        if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO === 'PENDIENTE') {
          this.displayDialogDescuento = true;
        }
      }
    }

    if (valor === 'Anular') {
      //GSRF
      if (this.encordService.encOrdSeleccionadaarray[this.indicador] !== undefined) {
        if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO === 'PENDIENTE') {
          let errores = 0;
          console.log('+-+-',this.encordService.detordenesarray[this.indicador])
          await Promise.all(this.encordService.detordenesarray[this.indicador].map(async (ped) => {
            if (Number(ped.DETORDCOM_DESPACHO) !== Number(0)) {
              errores ++;
            } 
          })
          );
          if (errores === 0) {
            this.confirmarAnular();
          } else {
            this.message.add({
              key: 'ordenCom',
              severity: 'error',
              summary: 'Anular detalle de la orden',
              detail: 'No se puede Anular por que el detalle ya tiene recepción'
            });
          }
        }
      }
      
      //GSRF
    }

    if (valor === 'Autorizar') {
      this.claveDialog = true;
      this.accion = 'autorizar';//CDPJ
    }
    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }
    if (valor === 'Almacenardoc') {
      if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO !== '') {
        const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.message.add({
            key: 'ordenCom',
            severity: 'info',
            summary: 'Información',
            detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
          });
        }
        
      }
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }

  async guardar() {
    this.spin=true;//GSRF
    if (this.permisoOrden.PERACTUALIZACION === 1) {
      console.log(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_FECHAEMISION);
      const data = await this.encordService.EncontrarProvedor(this.encordService.encOrdSeleccionadaarray[this.indicador].PRO_CODIGO);
      if (this.encordService.encOrdSeleccionadaarray[this.indicador].PRO_CODIGO === '' ||
        this.encordService.detordenesarray[this.indicador].length === 0) {
        this.message.add({
          key: 'ordenCom',
          severity: 'error',
          summary: 'Inserción de Orden',
          detail: 'No existe un proveedor o el detalle está vacio'
        });
        this.spin=false;
      } else if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_FECHAEMISION === '' ||
        this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_FECHAEMISION === null) {
        this.message.add({
          key: 'ordenCom',
          severity: 'error',
          summary: 'Inserción de Orden',
          detail: 'Ingrese fechas válidas para guardar la orden'
        });
        this.spin=false;
      }else if (data === null || data === undefined || data === '') {
        this.message.add({
          key: 'ordenCom',
          severity: 'error',
          summary: 'Inserción de Orden',
          detail: 'No existe el proveedor '+ this.encordService.encOrdSeleccionadaarray[this.indicador].PRO_CODIGO
        });
        this.spin=false;
      return;
    } else {
        if (this.encordService.detordenesarray[this.indicador].length !== 0) {
          let errores = 0;
          this.aggrid.refreshaggrid(this.encordService.detordenesarray[this.indicador], this.defaultOrdenDetalle.objeto);
          await Promise.all(this.encordService.detordenesarray[this.indicador].map(async (ped) => {
            if (ped.DETORDCOM_TIPODET === '*') {
              errores += 0;
            } else {
              const rsdata: any = await this.encordService.encontrarArtCntaySer(ped.DETORDCOM_CODIGO, ped.DETORDCOM_TIPODET);
              const rsdata1: any = await this.encordService.encontrarBodCodigo(ped.BOD_CODIGO);
              const rsdata2: any = await this.encordService.encontrarArttribIva(ped.DETORDCOM_CODIGO,ped.DETORDCOM_TIPODET);
              let strCodigo = 0;
              if (rsdata !== null) {
                for (const rs of rsdata) {
                  strCodigo = rs.NUMERO;
                }
              }
              let strBodCodigo = 0;
              if (rsdata1 !== null) {
                for (const rs1 of rsdata1) {
                  strBodCodigo = rs1.NUMERO;
                }
              }
              let strCodigoIVA = '';
              if (rsdata2 !== null) {
                for (const rs2 of rsdata2) {
                  strCodigoIVA = rs2.ART_TRIBUTAIVA;
                }
              }
              if (ped.DETORDCOM_CODIGO === '') {
                this.encordService.detordenesarray[this.indicador] = this.encordService.detordenesarray[this.indicador].filter((val, j) => j !==
                  this.encordService.detordenesarray[this.indicador].indexOf(ped));
                this.aggrid.refreshaggrid(this.encordService.detordenesarray[this.indicador], this.defaultOrdenDetalle.objeto);
              } else if (ped.BOD_CODIGO === '') {
                this.message.add({
                  key: 'ordenCom',
                  severity: 'error',
                  summary: 'Inserción de Orden',
                  detail: 'Ingrese una bodega al detalle de la orden'
                });
                
                errores++;
              }else if(strBodCodigo === 0){
                this.message.add({
                  key: 'ordenCom',
                  severity: 'error',
                  summary: 'Inserción de Orden',
                  detail: 'La bodega '+ ped.BOD_CODIGO+' no existe'
                });
                
                errores++;
              } else if (Number(ped.DETORDCOM_CANTIDAD) <= 0) {
                this.message.add({
                  key: 'ordenCom',
                  severity: 'error',
                  summary: 'Inserción de Orden',
                  detail: 'Ingrese una cantidad válida al detalle de la orden'
                });
                
                errores++;
              } else if (Number(ped.DETORDCOM_COSTO) <= 0) {
                this.message.add({
                  key: 'ordenCom',
                  severity: 'error',
                  summary: 'Inserción de Orden',
                  detail: 'Ingrese un costo válido al detalle de la orden'
                });
                
                errores++;
              }else if(ped.DETORDCOM_DESCRIPCION.length > 80){//CDPJ
                this.message.add({
                  key: 'ordenCom',
                  severity: 'error',
                  summary: 'Inserción de Orden',
                  detail: 'La descricpcion en el detalle no debe ser mayor a 80 caracteres.'
                });
                
                errores++;
              //CDPJ
            }else if(ped.DETORDCOM_TIPODET === 'A' && strCodigo === 0){
              this.message.add({
                key: 'ordenCom',
                severity: 'error',
                summary: 'Inserción de Orden',
                detail: 'El artículo '+ ped.DETORDCOM_CODIGO +' no existe'
              });
              
              errores++;
            }else if(ped.DETORDCOM_TIPODET === 'S' && strCodigo === 0){
              this.message.add({
                key: 'ordenCom',
                severity: 'error',
                summary: 'Inserción de Orden',
                detail: 'El servicio '+ ped.DETORDCOM_CODIGO +' no existe'
              });
              
              errores++;
            }else if(ped.DETORDCOM_TIPODET === 'A' && (ped.DETORDCOM_TRIBIVA === '' || ped.DETORDCOM_TRIBIVA === null || ped.DETORDCOM_TRIBIVA === undefined)){
              if(strCodigoIVA === null || strCodigoIVA === undefined || strCodigoIVA === ''){
                this.message.add({
                  key: 'notrec',
                  severity: 'error',
                  summary: 'Ingreso Notas de Recepción',
                  detail: 'Ingrese N o S en el IVA del artículo '+ ped.DETORDCOM_CODIGO
                });
                
                errores++;
               
              }else{
                ped.DETORDCOM_TRIBIVA=strCodigoIVA;
              }
              this.aggrid.refreshaggrid(this.encordService.detordenesarray[this.indicador], this.defaultOrdenDetalle.objeto);
              this.encordService.calcularTotal();
              this.aggrid.refreshaggrid(this.encordService.detordenesarray[this.indicador], this.defaultOrdenDetalle.objeto);            
            }else if(ped.DETORDCOM_TIPODET === 'S' && (ped.DETORDCOM_TRIBIVA === '' || ped.DETORDCOM_TRIBIVA === null || ped.DETORDCOM_TRIBIVA === undefined)){
              console.log(ped.DETORDCOM_TIPODET)
              console.log(strCodigoIVA)
              if(strCodigoIVA === null || strCodigoIVA === undefined || strCodigoIVA === ''){
    
                this.message.add({
                  key: 'notrec',
                  severity: 'error',
                  summary: 'Ingreso Notas de Recepción',
                  detail: 'Ingrese N o S en el IVA del servicio '+ ped.DETORDCOM_CODIGO
                });
                
                errores++;
               
              }else{
                ped.DETORDCOM_TRIBIVA=strCodigoIVA;
              }
              this.aggrid.refreshaggrid(this.encordService.detordenesarray[this.indicador], this.defaultOrdenDetalle.objeto);
              this.encordService.calcularTotal();
              this.aggrid.refreshaggrid(this.encordService.detordenesarray[this.indicador], this.defaultOrdenDetalle.objeto);            
            }
            if(ped.TARIIVA_CODIGO === null || ped.TARIIVA_CODIGO === undefined || ped.TARIIVA_CODIGO === ''){
              
             
              this.message.add({
                key: 'ordenCom',
                severity: 'error',
                summary: 'Error en IVA',
                detail: 'Falta seleccionar el tipo de porcentaje del código '+ped.DETORDCOM_CODIGO
              });
              errores++;             
            
          }
            else if(ped.DETORDCOM_PORIVA === null || ped.DETORDCOM_PORIVA === undefined || ped.DETORDCOM_PORIVA === ''){
              
             
              this.message.add({
                key: 'ordenCom',
                severity: 'error',
                summary: 'Error en IVA',
                detail: 'El campo %IVA no puede estar vacio, a menos que sea un comentario'
              });
              errores++;             
            
          }else if(ped.DETORDCOM_TRIBIVA === 'S' && ped.TARIIVA_CODIGO !== null && ped.TARIIVA_CODIGO !== undefined && ped.TARIIVA_CODIGO !== ''){
            let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === ped.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) !== 0 && Number(ped.DETORDCOM_PORIVA) !== 0 &&  Number(element.TARIIVA_PORCENTAJE) === Number(ped.DETORDCOM_PORIVA))
            if(data === undefined || data === null){
              this.message.add({
                key: 'ordenCom',
                severity: 'error',
                summary: 'Error en IVA',
                detail: 'El %IVA con el porcentaje '+ped.DETORDCOM_PORIVA+' no es parte de los porcentajes que puede seleccionar cuando se tributa IVA.'
              });
              errores++;             
            }
          }else if(ped.DETORDCOM_TRIBIVA === 'N'
          && ped.TARIIVA_CODIGO !== null && ped.TARIIVA_CODIGO !== undefined && ped.TARIIVA_CODIGO !== ''){
            let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === ped.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) === 0 && Number(ped.DETORDCOM_PORIVA) === 0 &&  Number(element.TARIIVA_PORCENTAJE) === Number(ped.DETORDCOM_PORIVA))
            console.log(data)
            console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === ped.TARIIVA_CODIGO))
            if(data === undefined || data === null){
              this.message.add({
                key: 'ordenCom',
                severity: 'error',
                summary: 'Error en IVA',
                detail: 'El %IVA con el porcentaje '+ped.DETORDCOM_PORIVA+' no es parte de los porcentajes que puede seleccionar cuando no se tributan IVA.'
              });
              errores++;
            }
          }
            //CDPJ
            
            }
          }));
          this.spin=false;//GSRF
          if (errores === 0) {
            this.guardarOrden();
          }
        }
      }
    }
  }

  nuevaOrden(parametro) {
    if (parametro === 'N') {
      this.btnNuevo = true;
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      // document.getElementById('divPrincipal2').style.pointerEvents = 'all';
      document.getElementById('divPrincipal').style.opacity = '1.0';
      this.aggrid.habilitarGrid();

      this.btnGuardar = false;
      this.btnBorrar = false;
      this.btnRegresar = false;
      this.botonUltimo = true;
      this.botonAnterior = true;
      this.botonSiguiente = true;
      this.botonPrimero = true;
      document.getElementById('proveedor').focus();
      this.indice = -1;
    } else if (parametro === 'C') { // C para cancelar
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.botonUltimo = false;
      this.botonAnterior = false;
      this.botonPrimero = false;
      this.botonSiguiente = false;
    }
    this.llenarcombo()
    const nuevaOrden = {
      ENCORDCOM_NUMERO: null, COM_CODIGO: '01', ENCORDCOM_CONTACTO: '', PRO_CODIGO: '',
      ENCORDCOM_FECHAEMISION: new Date(), ENCORDCOM_FECHAENTREGA: new Date(), ENCORDCOM_IVA: '1', ENCORDCOM_ESTADO: 'PENDIENTE',
      ENCORDCOM_ESTADOOC: '', ENCORDCOM_ARCHIVO: '', ENCORDCOM_OBSERVACION: '', ENCORDCOM_TOTAL: 0, ENCORDCOM_TOTALNETO: 0,
      ENCORDCOM_VALORDES: 0, ENCORDCOM_PORCEDES: 0, ENCORDCOM_VALORIVA: 0, ENCORDCOM_PORCEIVA: Number(this.encordService.porcetariiva), ENCORDCOM_VALORICE:
        0, ENCORDCOM_BASEIVA: 0, ENCORDCOM_BASEICE: 0, ENCORDCOM_BASECERO: 0, ENCORDCOM_OTROS:
        '', ENCORDCOM_REFERENCIA: '', ENCORDCOM_TIPODES: '', ENCORDCOM_TIPOOC:
        '', ENCORDCOM_DOCUMENTACION: '', ENCORDCOM_NUMNOTREC: '', ENCORDCOM_REFERENCIA_ADI: '', COMRUCI: '', USUIDENTIFICACION:
        this.usuario.identificacion, ADQUISICION_NUMERO: '', ENCADQNAEX_NUMERO: ''
    };
    this.nombreProveedor = '';
    this.bodCodigo = '';
    this.fpValor = '';
    this.encordService.encOrdSeleccionadaarray[this.indicador] = nuevaOrden;
    this.encordService.dblPorcDescGlbOrdarray[this.indicador] = this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_PORCEDES;
    this.encordService.dblValorDescGlbOrdarray[this.indicador] = this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_VALORDES;
    this.fechaBoolean = false;
    this.encordService.detordenesarray[this.indicador] = [];
    
  }

  async guardarOrden() {
    this.spin=true;//GSRF
    if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_FECHAEMISION.toString().includes('-')) {
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_FECHAEMISION = this.datePipe.transform(
        this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_FECHAEMISION, 'dd/MM/yyyy');
    } else if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_FECHAEMISION.toString().includes('/')) {
    }
    if (this.fechaBoolean === true) {
      if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_FECHAENTREGA.toString().includes('-')) {
        this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_FECHAENTREGA = this.datePipe.transform(
          this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_FECHAENTREGA, 'dd/MM/yyyy');
      } else if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_FECHAENTREGA.toString().includes('/')) {
      }
    } else {
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_FECHAENTREGA = '';
    }
    if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO === 'PENDIENTE') {
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO = 'P';
    }
    if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_DOCUMENTACION === null) {
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_DOCUMENTACION = '';
    }
    if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMNOTREC === null) {
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMNOTREC = '';
    }
    if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_REFERENCIA_ADI === null) {
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_REFERENCIA_ADI = '';
    }
    if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_TIPODES === null) {
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_TIPODES = '';
    }
    if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_TIPOOC === null) {
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_TIPOOC = '';
    }
    if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADOOC === null) {
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADOOC = '';
    }
    if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCADQNAEX_NUMERO === null) {
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCADQNAEX_NUMERO = '';
    }
    if (this.encordService.encOrdSeleccionadaarray[this.indicador].ADQUISICION_NUMERO === null) {
      this.encordService.encOrdSeleccionadaarray[this.indicador].ADQUISICION_NUMERO = '';
    }
    if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ARCHIVO === null) {
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ARCHIVO = '';
    }
    this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_PORCEIVA=Number(this.encordService.porcetariiva);
    console.log('enc',this.encordService.encOrdSeleccionadaarray[this.indicador])
    console.log('det',this.encordService.detordenesarray[this.indicador]);
    const res = await this.encordService.guardarENCORD(this.encordService.encOrdSeleccionadaarray[this.indicador]).toPromise().catch(error1=>{
           let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.message.add({
        key: 'ordenCom',
        severity: 'error',
        summary: 'Inserción de Orden',
        detail: mensaje
      });
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.spin=false;
    });
    this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO = res[0].v_ENCORDCOM_numero
    this.disabledEstadisticas = false;
    if (this.indice !== -1) {
      
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO = res[0].v_ENCORDCOM_numero;
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_REFERENCIA =
        this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO.substr(2, 10);
        await this.guardarDetalle();
      this.encordService.insertarUsuidentificacion(this.encordService.encOrdSeleccionadaarray[this.indicador]).subscribe(() => {
      });
      this.encordService.actualizarEstado(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO,
        this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADOOC).subscribe(() => {
        });
      this.encordService.actualizarTipoOC(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO,
        this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_TIPOOC).subscribe(() => {
        });
        

      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.botonPrimero = false;
      this.botonAnterior = false;
      this.botonSiguiente = false;
      this.botonUltimo = false;
      //GSRF
      this.auditoriagral.registrarAuditoria('COM_ENCORDCOM',
      String(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO)
      +'/'+this.encordService.encOrdSeleccionadaarray[this.indicador].PRO_CODIGO
      +'/FEMI: '+this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_FECHAEMISION
      +'/FENT: '+this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_FECHAENTREGA
      +'/BOD: '+this.bodCodigo
      , 'I', '', '01', '', '', '',
      '').subscribe(() => {
      });
      //GSRF
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO = 'PENDIENTE';
    } else if (this.indice === -1) {
      
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO = res[0].v_ENCORDCOM_numero;
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_REFERENCIA =
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO.substr(2, 10);
      await this.guardarDetalle();
      this.encordService.insertarUsuidentificacion(this.encordService.encOrdSeleccionadaarray[this.indicador]).subscribe(() => {
      });
      this.encordService.actualizarEstado(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO,
        this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADOOC).subscribe(() => {
        });
      this.encordService.actualizarTipoOC(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO,
        this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_TIPOOC).subscribe(() => {
        });
       

      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.botonPrimero = false;
      this.botonAnterior = false;
      this.botonSiguiente = false;
      this.botonUltimo = false;
      this.indice = this.encabezadosOrdenes.length + 1;
      //GSRF
      this.auditoriagral.registrarAuditoria('COM_ENCORDCOM',
        String(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO)
        +'/'+this.encordService.encOrdSeleccionadaarray[this.indicador].PRO_CODIGO
        +'/FEMI: '+this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_FECHAEMISION
        +'/FENT: '+this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_FECHAENTREGA
        +'/BOD: '+this.bodCodigo
        , 'I', '', '01', '', '', '',
        '').subscribe(() => {
        });
        //GSRF
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO = 'PENDIENTE';
    }
    this.encordService.getEncOrden().subscribe((res1) => {
      this.encabezadosOrdenes = res1;
    });
    //CDPJ




    // this.encordService.guardarENCORD(this.encordService.encOrdSeleccionadaarray[this.indicador]).subscribe((res) => {
    //   if (res !== null) {
    //     this.disabledEstadisticas = false;
    //     if (this.indice !== -1) {
          
    //       this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO = res[0].v_ENCORDCOM_numero;
    //       this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_REFERENCIA =
    //         this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO.substr(2, 10);
    //       this.encordService.insertarUsuidentificacion(this.encordService.encOrdSeleccionadaarray[this.indicador]).subscribe(() => {
    //       });
    //       this.encordService.actualizarEstado(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO,
    //         this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADOOC).subscribe(() => {
    //         });
    //       this.encordService.actualizarTipoOC(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO,
    //         this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_TIPOOC).subscribe(() => {
    //         });
    //       this.encordService.detordenesarray[this.indicador].map(async(orden) => {
    //         orden.ENCORDCOM_NUMERO = this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO;
    //         this.encordService.insertarDetalle(orden).subscribe(() => {
    //           if (orden.DETORDCOM_LINEA === 0) {
    //             orden.DETORDCOM_LINEA = orden.LINEA;
    //             this.auditoriagral.registrarAuditoria('COM_DETORDCOM', String(orden.ENCORDCOM_NUMERO), 'I',
    //               '', '01', '', '', '', '').subscribe(() => {
    //               });
    //           } else if (orden.DETORDCOM_LINEA !== 0) {
    //             this.auditoriagral.registrarAuditoria('COM_DETORDCOM', String(orden.ENCORDCOM_NUMERO), 'A',
    //               '', '01', '', '', '', '').subscribe(() => {
    //               });
    //           }
    //         }, error1 => {
    //         });//CDPJ LO COMENTÉ
    //         // //CDPJ
    //         // await this.encordService.insertarDetalle(orden)
    //         // if (orden.DETORDCOM_LINEA === 0) {
    //         //   orden.DETORDCOM_LINEA = orden.LINEA;
    //         //   this.auditoriagral.registrarAuditoria('COM_DETORDCOM', String(orden.ENCORDCOM_NUMERO), 'I',
    //         //     '', '01', '', '', '', '').subscribe(() => {
    //         //     });
    //         // } else if (orden.DETORDCOM_LINEA !== 0) {
    //         //   this.auditoriagral.registrarAuditoria('COM_DETORDCOM', String(orden.ENCORDCOM_NUMERO), 'A',
    //         //     '', '01', '', '', '', '').subscribe(() => {
    //         //     });
    //         // }
    //         // //CDPJ
    //       });
    //       this.message.add({
    //         key: 'ordenCom',
    //         severity: 'success',
    //         summary: 'Actualización de Orden',
    //         detail: 'La Orden se actualizó correctamente'
    //       });

    //       this.btnNuevo = false;
    //       this.btnGuardar = true;
    //       this.btnBorrar = false;
    //       this.btnRegresar = true;
    //       this.botonPrimero = false;
    //       this.botonAnterior = false;
    //       this.botonSiguiente = false;
    //       this.botonUltimo = false;
    //       this.auditoriagral.registrarAuditoria('COM_ENCORDCOM', String(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO)
    //         , 'A', '', '01', '', '', '', '').subscribe(() => {
    //         });
    //       this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO = 'PENDIENTE';
    //     } else if (this.indice === -1) {
          
    //       this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO = res[0].v_ENCORDCOM_numero;
    //       this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_REFERENCIA =
    //         this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO.substr(2, 10);
    //       this.encordService.insertarUsuidentificacion(this.encordService.encOrdSeleccionadaarray[this.indicador]).subscribe(() => {
    //       });
    //       this.encordService.actualizarEstado(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO,
    //         this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADOOC).subscribe(() => {
    //         });
    //       this.encordService.actualizarTipoOC(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO,
    //         this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_TIPOOC).subscribe(() => {
    //         });
    //       this.encordService.detordenesarray[this.indicador].map(async(orden) => {
    //         orden.ENCORDCOM_NUMERO = this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO;
    //         this.encordService.insertarDetalle(orden).subscribe(() => {
    //           if (orden.DETORDCOM_LINEA === 0) {
    //             orden.DETORDCOM_LINEA = orden.LINEA;
    //             this.auditoriagral.registrarAuditoria('COM_DETORDCOM', String(orden.ENCORDCOM_NUMERO), 'I',
    //               '', '01', '', '', '', '').subscribe(() => {
    //               });
    //           } else if (orden.DETORDCOM_LINEA !== 0) {
    //             this.auditoriagral.registrarAuditoria('COM_DETORDCOM', String(orden.ENCORDCOM_NUMERO), 'A',
    //               '', '01', '', '', '', '').subscribe(() => {
    //               });
    //           }
    //         });//CDPJ LO COMENTÉ
    //         // await this.encordService.insertarDetalle(orden)//CDPJ
    //         //   if (orden.DETORDCOM_LINEA === 0) {
    //         //     orden.DETORDCOM_LINEA = orden.LINEA;
    //         //     this.auditoriagral.registrarAuditoria('COM_DETORDCOM', String(orden.ENCORDCOM_NUMERO), 'I',
    //         //       '', '01', '', '', '', '').subscribe(() => {
    //         //       });
    //         //   } else if (orden.DETORDCOM_LINEA !== 0) {
    //         //     this.auditoriagral.registrarAuditoria('COM_DETORDCOM', String(orden.ENCORDCOM_NUMERO), 'A',
    //         //       '', '01', '', '', '', '').subscribe(() => {
    //         //       });
    //         //   }//CDPJ
    //       });
    //       this.message.add({
    //         key: 'ordenCom',
    //         severity: 'success',
    //         summary: 'Inserción de Orden',
    //         detail: 'La Orden se insertó correctamente'
    //       });

    //       this.btnNuevo = false;
    //       this.btnGuardar = true;
    //       this.btnBorrar = false;
    //       this.btnRegresar = true;
    //       this.botonPrimero = false;
    //       this.botonAnterior = false;
    //       this.botonSiguiente = false;
    //       this.botonUltimo = false;
    //       this.indice = this.encabezadosOrdenes.length + 1;
    //       this.auditoriagral.registrarAuditoria('COM_ENCORDCOM',
    //         String(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO), 'I', '', '01', '', '', '',
    //         '').subscribe(() => {
    //         });
    //       this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO = 'PENDIENTE';
    //     }
    //   }
    //   this.encordService.getEncOrden().subscribe((res1) => {
    //     this.encabezadosOrdenes = res1;
    //   });
    // }, error1 => {
    //   let mensaje: string;
    //   mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
    //   this.message.add({
    //     key: 'ordenCom',
    //     severity: 'error',
    //     summary: 'Inserción de Orden',
    //     detail: mensaje
    //   });
    //   this.btnNuevo = false;
    //   this.btnGuardar = true;
    //   this.btnBorrar = false;
    //   this.btnRegresar = true;
    // });
    this.spin=false;//GSRF
  }
//CDPJ
async guardarDetalle(){
  //await Promise.all(this.encordService.detordenesarray[this.indicador].map(async(orden) => {
  for (const orden of this.encordService.detordenesarray[this.indicador]){  
  orden.ENCORDCOM_NUMERO = this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO;
    console.log(orden.ENCORDCOM_NUMERO)
    // this.encordService.insertarDetalle(orden).subscribe(() => {
    //   if (orden.DETORDCOM_LINEA === 0) {
    //     orden.DETORDCOM_LINEA = orden.LINEA;
    //     this.auditoriagral.registrarAuditoria('COM_DETORDCOM', String(orden.ENCORDCOM_NUMERO), 'I',
    //       '', '01', '', '', '', '').subscribe(() => {
    //       });
    //   } else if (orden.DETORDCOM_LINEA !== 0) {
    //     this.auditoriagral.registrarAuditoria('COM_DETORDCOM', String(orden.ENCORDCOM_NUMERO), 'A',
    //       '', '01', '', '', '', '').subscribe(() => {
    //       });
    //   }
    // }, error1 => {
    // });
    //CDPJ LO COMENTÉ
    //CDPJ
    if(orden.DETORDCOM_TIPODET === '*'){
      orden.DETORDCOM_PORIVA=0;
      orden.TARIIVA_CODIGO='-1';
    }
    await this.encordService.insertarDetalle(orden).catch(error2=>{
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error2.error.substr(0, 9));
      this.message.add({
        key: 'ordenCom',
        severity: 'error',
        summary: 'Inserción de Orden',
        detail: mensaje
      });
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.spin=false;
    })
    if (orden.DETORDCOM_LINEA === 0) {
      orden.DETORDCOM_LINEA = orden.LINEA;
      this.auditoriagral.registrarAuditoria('COM_DETORDCOM', String(orden.ENCORDCOM_NUMERO)+ '/' + orden.DETORDCOM_CODIGO, 'I', //GSRF
        '', '01', '', '', '', '').subscribe(() => {
        });
        this.auditoriagral.registrarAuditoria('COM_DETORDCOM', String(orden.ENCORDCOM_NUMERO)+ '/' + orden.DETORDCOM_CODIGO+ '/' 
        +'PorIva:'+ orden.DETORDCOM_PORIVA + '/' + 'CodTarIva:'+ orden.TARIIVA_CODIGO, 'I', 
        '', '01', '', '', '', '').subscribe(() => {
        });
    } else if (orden.DETORDCOM_LINEA !== 0) {
      this.auditoriagral.registrarAuditoria('COM_DETORDCOM', String(orden.ENCORDCOM_NUMERO)+ '/' + orden.DETORDCOM_CODIGO, 'A',//GSRF
        '', '01', '', '', '', '').subscribe(() => {
        });
        this.auditoriagral.registrarAuditoria('COM_DETORDCOM', String(orden.ENCORDCOM_NUMERO)+ '/' + orden.DETORDCOM_CODIGO+ '/' 
        +'PorIva:'+ orden.DETORDCOM_PORIVA + '/' + 'CodTarIva:'+ orden.TARIIVA_CODIGO, 'A', 
        '', '01', '', '', '', '').subscribe(() => {
        });
    }
    //CDPJ
  }
  //}));
  this.message.add({
    key: 'ordenCom',
    severity: 'success',
    summary: 'Actualización de Orden',
    detail: 'La Orden se insertó correctamente'
  });
}
//CDPJ
  confirmarOrden() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar la orden',
      header: 'Eliminar Orden',
      icon: 'pi pi-exclamation-triangle',
      key: 'ordenComConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarOrden();
      },
      reject: () => {
      }
    });
  }

  async eliminarOrden() {
    if (this.encordService.encOrdSeleccionadaarray[this.indicador] !== undefined) {
      //CDPJ
      let num=0;
      let num2=0;
      let num3=0;
      let error=0;
      const count=await this.encordService.consulexisEncNotrec(this.encordService.encOrdSeleccionadaarray[this.indicador])
      const count2=await this.encordService.consulexisEncFacpro(this.encordService.encOrdSeleccionadaarray[this.indicador])
      const count3=await this.encordService.consulexisEncLiquiPro(this.encordService.encOrdSeleccionadaarray[this.indicador])
      if(count !== null || count !== undefined){
        for (const data1 of count){
          num=data1.NUMERO === null || data1.NUMERO === undefined ? 0:data1.NUMERO;
        }
      }
      if(count2 !== null || count2 !== undefined){
        for (const data2 of count2){
          num2=data2.NUMERO === null || data2.NUMERO === undefined ? 0:data2.NUMERO;
        }
      }
      if(count3 !== null || count3 !== undefined){
        for (const data3 of count3){
          num3=data3.NUMERO === null || data3.NUMERO === undefined ? 0:data3.NUMERO;
        }
      }
      if (num > 0){
        this.message.add({
          key: 'ordenCom',
          severity: 'error',
          summary: 'Eliminación de Orden',
          detail: 'No se puede eliminar la orden porque está atada a una nota de recepción'
        });
        error++;
      }else if(num2){
        this.message.add({
          key: 'ordenCom',
          severity: 'error',
          summary: 'Eliminación de Orden',
          detail: 'No se puede eliminar la orden porque está atada a una factura de proveedores'
        });
        error++;
      }else if (num3>0){
        this.message.add({
          key: 'ordenCom',
          severity: 'error',
          summary: 'Eliminación de Orden',
          detail: 'No se puede eliminar la orden porque está atada a una liquidación de compras'
        });
        error++;
      }
      console.log(error);
      //CDPJ
      if (error === 0){
        this.encordService.eliminarEnc(this.encordService.encOrdSeleccionadaarray[this.indicador]).subscribe(() => {
          this.message.add({
            key: 'ordenCom',
            severity: 'success',
            summary: 'Eliminación de Orden',
            detail: 'La orden se eliminó correctamente'
          });
          this.auditoriagral.registrarAuditoria('COM_ENCORDCOM', String(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO),
            'E',
            '', '01', '', '', '', '').subscribe(() => {
            });
          this.encordService.detordenesarray[this.indicador].map((ord) => {
            this.auditoriagral.registrarAuditoria('COM_DETORDCOM', String(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO)
              , 'E',
              '', '01', '', '', '', '').subscribe(() => {
              });
          });
          this.encordService.encOrdSeleccionadaarray[this.indicador] = {
            ENCORDCOM_NUMERO: null, COM_CODIGO: '01', ENCORDCOM_CONTACTO: '', PRO_CODIGO: '',
            ENCORDCOM_FECHAEMISION: new Date(), ENCORDCOM_FECHAENTREGA: new Date(), ENCORDCOM_IVA: '1', ENCORDCOM_ESTADO: '',
            ENCORDCOM_ESTADOOC: '', ENCORDCOM_ARCHIVO: '', ENCORDCOM_OBSERVACION: '', ENCORDCOM_TOTAL: 0, ENCORDCOM_TOTALNETO: 0,
            ENCORDCOM_VALORDES: 0, ENCORDCOM_PORCEDES: 0, ENCORDCOM_VALORIVA: 12, ENCORDCOM_PORCEIVA: Number(this.encordService.porcetariiva), ENCORDCOM_VALORICE:
              0, ENCORDCOM_BASEIVA: 0, ENCORDCOM_BASEICE: 0, ENCORDCOM_BASECERO: 0, ENCORDCOM_OTROS:
              '', ENCORDCOM_REFERENCIA: '', ENCORDCOM_TIPODES: '', ENCORDCOM_TIPOOC:
              '', ENCORDCOM_DOCUMENTACION: '', ENCORDCOM_NUMNOTREC: '', ENCORDCOM_REFERENCIA_ADI: '', COMRUCI: '', USUIDENTIFICACION:
              this.usuario.identificacion, ADQUISICION_NUMERO: '', ENCADQNAEX_NUMERO: ''
          };
          this.encordService.detordenesarray[this.indicador] = [];
          this.nombreProveedor = '';
          this.fpValor = '';
          this.bodCodigo = '';
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.btnRegresar = true;
          this.encordService.getEncOrden().subscribe((res1) => {
            this.encabezadosOrdenes = res1;
          });
        });
      }
      
    } else {
      this.message.add({
        key: 'ordenCom',
        severity: 'error',
        summary: 'Eliminación de Orden',
        detail: 'Seleccione una orden para eliminarla'
      });
    }
  }

  cancelarEdicionOrden() {
    // if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO === '' && this.btnNuevo === true) {
    if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO === ''|| this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO === null && this.btnNuevo === true) {
        this.nuevaOrden('C');
    // } else if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO !== '') {
    } else if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO !== '' || this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO !== null) {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.pedidoCancelado();
    }
  }

  pedidoCancelado() {
    this.encordService.getEncOrden().subscribe((res) => {
      res.map((orden) => {
        if (orden.ENCORDCOM_NUMERO === this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO) {
          this.encabezadoOrdenSeleccionadaAux = orden;
        }
      });
      this.encordService.encOrdSeleccionadaarray[this.indicador] = this.encabezadoOrdenSeleccionadaAux;
      this.obtenerDatos();
    });
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
  }

  async obtenerDatos() {
    const data = await this.encordService.getEncabezado(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO);
    for (const dd of data) {
      this.encordService.encOrdSeleccionadaarray[this.indicador] = dd;
    }

    if (this.encordService.encOrdSeleccionadaarray[this.indicador].PRO_CODIGO !== '') {
      this.disabledEstadisticas = false;
    } else {
      this.disabledEstadisticas = true;
    }

    if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_FECHAENTREGA === null) {
      this.fechaBoolean = false;
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_FECHAENTREGA = new Date();
    } else if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_FECHAENTREGA !== '') {
      this.fechaBoolean = true;
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_FECHAENTREGA =
        new Date(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_FECHAENTREGA);
    }
    //CDPJ
    for (const j of this.cmbtipoIva) {
      if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_PORCEIVA === Number(j.name)) {
        this.selectcombtipoIva = { codigo: j.codigo, name: j.name };
        this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_PORCEIVA=Number(this.selectcombtipoIva.name);
      }
    }
    let porcentaje=this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_PORCEIVA
    let data1=this.confIniciales.srifetariiva.find(element => Number(element.TARIIVA_PORCENTAJE) === Number(porcentaje))
    this.encordService.porcetariiva=Number(porcentaje);
    this.encordService.codtariiva=data1.TARIIVA_CODIGO;
    //CDPJ
    if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO === 'F') {
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO = 'FACTURADA';
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      //document.getElementById('divPrincipal2').style.pointerEvents = 'none';
      document.getElementById('divPrincipal').style.opacity = '0.7';
      this.aggrid.bloquearGrid();
      this.btnNuevoGrid = true;
      this.btnGuardarGrid = true;
      this.btnCancelarGrid = true;
      this.btnBorrarGrid = true;
      this.botonAnular= true;//GSRF
    } else if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO === 'E') {
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO = 'ENTREGADA';
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      //document.getElementById('divPrincipal2').style.pointerEvents = 'none';
      document.getElementById('divPrincipal').style.opacity = '0.7';
      this.aggrid.bloquearGrid;
      this.btnNuevoGrid = true;
      this.btnGuardarGrid = true;
      this.btnCancelarGrid = true;
      this.btnBorrarGrid = true;
      this.botonAnular= true;//GSRF
    }
    if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO === 'P') {
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO = 'PENDIENTE';
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      // document.getElementById('divPrincipal2').style.pointerEvents = 'all';
      document.getElementById('divPrincipal').style.opacity = '1.0';
      this.aggrid.habilitarGrid();
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnCancelarGrid = false;
      this.btnBorrarGrid = false;
      this.botonAnular= false;//GSRF
    } else if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO === 'T') {
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO = 'AUTORIZADO';
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      // document.getElementById('divPrincipal2').style.pointerEvents = 'none';
      document.getElementById('divPrincipal').style.opacity = '0.7';
      this.aggrid.bloquearGrid();
      this.btnNuevoGrid = true;
      this.btnGuardarGrid = true;
      this.btnCancelarGrid = true;
      this.btnBorrarGrid = true;
      this.botonAnular= true;//GSRF
    }
    //GSRF
    else if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO === 'A') {
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO = 'ANULADA';
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      //document.getElementById('divPrincipal2').style.pointerEvents = 'none';
      document.getElementById('divPrincipal').style.opacity = '0.7';
      this.aggrid.bloquearGrid();
      this.btnNuevoGrid = true;
      this.btnGuardarGrid = true;
      this.btnCancelarGrid = true;
      this.btnBorrarGrid = true;
      this.botonAnular= true;//GSRF
    }
    //GSRF
    this.valorDscValor = this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_VALORDES;
    this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_VALORDES = Number(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_VALORDES).toFixed(this.numdecsist);
    this.encordService.dblValorDescGlbOrdarray[this.indicador] = this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_VALORDES;
    this.valorDscPorcentaje = this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_PORCEDES;
    this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_PORCEDES = Number(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_PORCEDES).toFixed(this.numdecsist);
    this.encordService.dblPorcDescGlbOrdarray[this.indicador] = this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_PORCEDES;
    this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_VALORIVA = Number(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_VALORIVA).toFixed(this.numdecsist);
    this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_BASEIVA = Number(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_BASEIVA).toFixed(this.numdecsist);
    this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_VALORICE = Number(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_VALORICE).toFixed(this.numdecsist);
    this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_BASEICE = Number(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_BASEICE).toFixed(this.numdecsist);
    this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_BASECERO = Number(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_BASECERO).toFixed(this.numdecsist);
    this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_TOTALNETO = Number(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_TOTALNETO).toFixed(this.numdecsist);
    this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_TOTAL =
      Number(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_TOTAL).toFixed(this.numdecsist);
    this.encordService.obtenerNombrePro(this.encordService.encOrdSeleccionadaarray[this.indicador].PRO_CODIGO).subscribe((res) => {
      this.nombreProveedor = res[0].PRO_NOMBRE;
    });
    this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_FECHAEMISION = new Date(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_FECHAEMISION);
    if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO !== '') {
      const sqlCommand = 'select distinct a.encfacpro_referencia from com_encfacpro a, com_detfacpro b where a.encfacpro_numero=' +
        'b.encfacpro_numero and a.com_codigo=b.com_codigo and nvl(a.encfacpro_estado,\'x\')<>\'A\' and b.encordcom_numero= \'' +
        this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO + '\'';
      this.encordService.ejecutarComando(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO).subscribe((fp) => {
        fp.map((facturaprov) => {
          this.fpValor += '/' + facturaprov.ENCFACPRO_REFERENCIA + ' ';
        });
      });
      this.encordService.getDetOrden(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO).subscribe((res) => {
        if (res !== null) {
          this.encordService.detordenesarray[this.indicador] = res;
          this.encordService.detordenesarray[this.indicador].map((orden) => {
            const opcOrden = [];
            opcOrden.push(orden.DETORDCOM_UNIDAD);
            this.columnDefsOrdenDetalle[5].cellEditorParams = {
              values: opcOrden
            };
            this.aggrid.refreshColumnDefs();
            orden.DETORDCOM_COSTO = orden.DETORDCOM_COSTO.toFixed(this.decimalesPrecioOrd);
            orden.DETORDCOM_CANTIDAD = orden.DETORDCOM_CANTIDAD.toFixed(this.decimalesCantidadOrd);
            orden.DETORDCOM_DESPACHO = orden.DETORDCOM_DESPACHO.toFixed(this.numdecsist);
            orden.DETORDCOM_TOTAL = Number(orden.DETORDCOM_TOTAL).toFixed(this.decimalesTotalOrd);
          });
          this.detalleOrdenSeleccionada = this.encordService.detordenesarray[this.indicador][0];
          this.bodCodigo = this.encordService.detordenesarray[this.indicador][0].BOD_CODIGO;
        }
      });
    }
  }

  buscarOrdenes() {
    this.opcion = 'BUSCAR';
    this.types = [
      { label: 'NÚMERO', value: 'ENCORDCOM_NUMERO' },
      { label: 'REFERENCIA', value: 'ENCORDCOM_REFERENCIA' },
      { label: 'FEC. EMISIÓN', value: 'ENCORDCOM_FECHAEMISION' },
      { label: 'FEC. ENTREGA', value: 'ENCORDCOM_FECHAENTREGA' },
      { label: 'ESTADO', value: 'ENCORDCOM_ESTADO' },
      { label: 'NOMBRE PROVEEDOR', value: 'PRO_NOMBRE' },
      { label: 'COD PROVEEDOR', value: 'PRO_CODIGO' },
      { label: 'CONTACTO', value: 'ENCORDCOM_CONTACTO' },
      { label: 'TOTAL', value: 'ENCORDCOM_TOTAL' },
      { label: 'TOT.NETO', value: 'ENCORDCOM_TOTALNETO' }
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', '', ''];
    this.tabla = 'COM_ENCORDCOM';
    this.consulta = 'ENCORDCOM_NUMERO, ENCORDCOM_REFERENCIA, ENCORDCOM_FECHAEMISION, ENCORDCOM_FECHAENTREGA,' +
      'ENCORDCOM_ESTADO, PRO_CODIGO, ENCORDCOM_TOTAL, ENCORDCOM_TOTALNETO, COM_FNC_NOMBRE_PROVEEDOR(PRO_CODIGO) AS PRO_NOMBRE, ' +
      'ENCORDCOM_CONTACTO';
    this.where='ENCORDCOM_NUMERO IS NOT NULL ORDER BY ENCORDCOM_FECHAEMISION DESC'
    this.busquedaSer.busquedaComEncOrdCom2().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.arregloCons.map((registro) => {
        if (registro.ENCORDCOM_FECHAEMISION !== null) {
          registro.ENCORDCOM_FECHAEMISION = this.datePipe.transform(registro.ENCORDCOM_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          registro.ENCORDCOM_FECHAEMISION = '';
        }
        if (registro.ENCORDCOM_FECHAENTREGA !== null) {
          registro.ENCORDCOM_FECHAENTREGA = this.datePipe.transform(registro.ENCORDCOM_FECHAENTREGA, 'dd/MM/yyyy');
        } else {
          registro.ENCORDCOM_FECHAENTREGA = '';
        }
        registro.ENCORDCOM_TOTAL = registro.ENCORDCOM_TOTAL.toFixed(this.numdecsist);
        registro.ENCORDCOM_TOTALNETO = registro.ENCORDCOM_TOTALNETO.toFixed(this.numdecsist);
      });
      this.displayDialogBusquedaFast = true;
    });
  }
  abrirbusquedaiva() {
    this.opcion = 'busquedatariiva';
    this.types = [
      { label: 'Código', value: 'TARIIVA_CODIGO' },
      { label: 'Descripcion', value: 'TARIIVA_DESCRIPCION' },
      { label: 'Porcentaje', value: 'TARIIVA_PORCENTAJE' }
    ];

    this.busquedacampos = ['', '', ''];
    this.tabla = 'SRIFE_TARIIVA';
    this.where = 'TARIIVA_ACTIVO=\'S\'';
    this.busquedaSer.busquedaSrifeTariIva(this.where).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      // this.displayDialogBusqueda = true;
      this.displayDialogBusquedaFast = true;
    });
  }

  manejarSeleccion(opcion) {
    if (this.opcion === 'BUSCAR') {
      this.encabezadosOrdenes.map((enca) => {
        if (enca.ENCORDCOM_NUMERO === opcion.ENCORDCOM_NUMERO) {
          this.indice = this.encabezadosOrdenes.indexOf(enca);
        }
      });
      if (this.indice === this.encabezadosOrdenes.length - 1) {
        this.botonUltimo = true;
        this.botonSiguiente = true;
        this.encordService.encOrdSeleccionadaarray[this.indicador] = this.encabezadosOrdenes[this.indice];
        this.obtenerDatos();
      } else if (this.indice === 0) {
        this.botonPrimero = true;
        this.botonAnterior = true;
        this.encordService.encOrdSeleccionadaarray[this.indicador] = this.encabezadosOrdenes[this.indice];
        this.obtenerDatos();
      } else {
        this.botonPrimero = false;
        this.botonAnterior = false;
        this.botonSiguiente = false;
        this.botonUltimo = false;
        this.encordService.encOrdSeleccionadaarray[this.indicador] = this.encabezadosOrdenes[this.indice];
        this.obtenerDatos();
      }
      this.displayDialogBusquedaFast = false;
    } else if (this.opcion === 'busquedatariiva'){
      this.encordService.porcetariiva=opcion.TARIIVA_PORCENTAJE;
      this.encordService.codtariiva=opcion.TARIIVA_CODIGO;
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_PORCEIVA=Number(this.encordService.porcetariiva);
      this.displayDialogBusquedaFast = false;
    }else if (this.opcion === 'BUSQUEDAPROV') {
      this.encordService.encOrdSeleccionadaarray[this.indicador].PRO_CODIGO = opcion.PRO_CODIGO;
      this.nombreProveedor = opcion.PRO_NOMBRE;
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_CONTACTO = opcion.PRO_CONTACTO;
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_IVA = opcion.PRO_IVA;
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_PORCEDES = opcion.PRO_DESCUENTO;
      if (opcion.PRO_DESCUENTO !== null) {
        this.dscProveedorActual = opcion.CLI_DESCUENTO;
      } else if (opcion.PRO_DESCUENTO === null) {
        this.dscProveedorActual = 0;
      }
      this.displayDialogBusquedaFast = false;
      this.btnNuevoGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.botonPrimero = true;
      this.botonSiguiente = true;
      this.botonAnterior = true;
      this.botonUltimo = true;
      document.getElementById('comentario').focus();
    } else if (this.opcion === 'BUSQUEDABOD') {
      this.bodCodigo = opcion.BOD_CODIGO;
      this.displayDialogBusquedaFast = false;
      this.nuevoDetalleOrden();
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.botonPrimero = true;
      this.botonSiguiente = true;
      this.botonAnterior = true;
      this.botonUltimo = true;
    } else if (this.opcion === 'BUSQUEDANT') {
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMNOTREC = opcion.ENCNOTREC_NUMERO;
      this.displayDialogBusquedaFast = false;
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.botonPrimero = true;
      this.botonSiguiente = true;
      this.botonAnterior = true;
      this.botonUltimo = true;
    }
    if (this.opcion === 'articulo') {
      this.txtArtCodigo = opcion.ART_CODIGO;
      this.txtArtDescripcion = opcion.ART_NOMBRE;
      this.txtArtUnidad = opcion.ART_UNIDADCOSTEO;
      this.txtExistenciaMin = opcion.ART_CANTMIN === null ? 0 : opcion.ART_CANTMIN;
      this.txtExistenciaMax = opcion.ART_CANTMAX === null ? 0 : opcion.ART_CANTMAX;
      this.displayDialogBusquedaFast = false;
      this.llenarTablaExistencia();
      this.llenarMovByProvArt('fecha');
      this.llenarExistenciaBodegas();
      this.ventasVsCompras();
      this.llenarIndiceProvisiones();
    }
  }

  cambiarFoco(evento) {
    if (evento.target.id === 'proveedor') {
      this.busquedaSer.encontrarRegistro39(this.encordService.encOrdSeleccionadaarray[this.indicador].PRO_CODIGO).subscribe((resProv) => {
        if (resProv !== null) {
          this.encordService.encOrdSeleccionadaarray[this.indicador].PRO_CODIGO = resProv[0].PRO_CODIGO;
          this.nombreProveedor = resProv[0].PRO_NOMBRE;
          this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_CONTACTO = resProv[0].PRO_CONTACTO;
          this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_IVA = resProv[0].PRO_IVA;
          this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_PORCEDES = resProv[0].PRO_DESCUENTO;
          if (resProv[0].PRO_DESCUENTO !== null) {
            this.dscProveedorActual = resProv[0].CLI_DESCUENTO;
          } else if (resProv[0].PRO_DESCUENTO === null) {
            this.dscProveedorActual = 0;
          }
          this.displayDialogBusquedaFast = false;
          this.btnNuevoGrid = false;
          this.btnBorrarGrid = false;
          this.btnCancelarGrid = false;
          this.btnNuevo = true;
          this.btnGuardar = false;
          this.btnBorrar = false;
          this.botonPrimero = true;
          this.botonSiguiente = true;
          this.botonAnterior = true;
          this.botonUltimo = true;
          document.getElementById('comentario').focus();
        } else {
          this.abrirProveedores();
        }
      });
    } else if (evento.target.id === 'contacto') {
      document.getElementById('comentario').focus();
    } else if (evento.target.id === 'comentario') {
      document.getElementById('referencia').focus();
    } else if (evento.target.id === 'referencia') {
      document.getElementById('bodega').focus();
    } else if (evento.target.id === 'bodega') {
      this.busquedaSer.encontrarRegistro40(this.bodCodigo).subscribe((resBod) => {
        if (resBod !== null) {
          this.bodCodigo = resBod[0].BOD_CODIGO;
          this.displayDialogBusquedaFast = false;
          this.nuevoDetalleOrden();
          this.btnNuevo = true;
          this.btnGuardar = false;
          this.btnBorrar = false;
          this.botonPrimero = true;
          this.botonSiguiente = true;
          this.botonAnterior = true;
          this.botonUltimo = true;
        } else {
          this.abrirBodega();
        }
      });
    } else if (evento.target.id === 'numRef') {
      this.busquedaSer.encontrarRegistro41(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMNOTREC).subscribe((resRef) => {
        if (resRef !== null) {
          this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMNOTREC = resRef[0].ENCNOTREC_NUMERO;
          this.displayDialogBusquedaFast = false;
          this.btnNuevo = true;
          this.btnGuardar = false;
          this.btnBorrar = false;
          this.botonPrimero = true;
          this.botonSiguiente = true;
          this.botonAnterior = true;
          this.botonUltimo = true;
        } else {
          this.abrirNotRec();
        }
      });
    }
  }

  abrirProveedores() {
    this.opcion = 'BUSQUEDAPROV';
    this.types = [
      { label: 'Codigo', value: 'PRO_CODIGO' },
      { label: 'Nombre', value: 'PRO_NOMBRE' },
      { label: 'Nombre Corto', value: 'PRO_NOMBREC' },
      { label: 'Grupo', value: 'GRU_CODIGO' },
      { label: 'RUC', value: 'PRO_RUCIDE' },
      { label: 'Cuenta', value: 'CON_CODIGO1' }
    ];
    // CAMPOS NECESARIOS BUSQUEDA FAST
    this.busquedacampos = [this.encordService.encOrdSeleccionadaarray[this.indicador].PRO_CODIGO, '', '', '', '', ''];
    this.tabla = 'COM_MAEPROVEEDOR';
    this.consulta = 'PRO_CODIGO, PRO_NOMBRE, PRO_NOMBREC, GRU_CODIGO, PRO_RUCIDE, CON_CODIGO1, PRO_CONTACTO, PRO_IVA, PRO_DESCUENTO';
    this.busquedaSer.busquedaComMaeProveedor4(this.encordService.encOrdSeleccionadaarray[this.indicador].PRO_CODIGO).subscribe((datos: any[]) => {
        this.arregloCons = datos;
        this.displayDialogBusquedaFast = true;
      });
  }

  abrirBodega() {
    this.opcion = 'BUSQUEDABOD';
    this.types = [
      { label: 'Codigo', value: 'BOD_CODIGO' },
      { label: 'Nombre', value: 'BOD_NOMBRE' }
    ];
    this.busquedacampos = [this.bodCodigo, ''];
    this.tabla = 'inv_maebodega';
    this.consulta = '*';
    this.busquedaSer.busquedaInvMaeBodega3(this.bodCodigo).subscribe((datos: any[]) => {
        this.arregloCons = datos;
        this.displayDialogBusquedaFast = true;
      });
  }

  abrirNotRec() {
    this.opcion = 'BUSQUEDANT';
    this.types = [
      { label: 'Número', value: 'ENCNOTREC_NUMERO' },
      { label: 'Referencia', value: 'ENCNOTREC_REFERENCIA' },
      { label: 'Fec Emisión', value: 'ENCNOTREC_FECHAEMISION' },
      { label: 'Estado', value: 'ENCNOTREC_ESTADO' },
      { label: 'Nombre Proveedor', value: 'PRO_NOMBRE' },
      { label: 'Cod Proveedor', value: 'PRO_CODIGO' },
      { label: 'Contacto', value: 'ENCNOTREC_CONTACTO' },
      { label: 'Total', value: 'ENCNOTREC_TOTAL' },
      { label: 'Total Neto', value: 'ENCNOTREC_TOTALNETO' },
    ];
    this.busquedacampos = [this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMNOTREC, '', '', '', '', '', '', '', ''];
    this.tabla = 'com_encnotrec';
    this.consulta = 'ENCNOTREC_NUMERO, ENCNOTREC_REFERENCIA, ENCNOTREC_FECHAEMISION, ENCNOTREC_ESTADO, COM_FNC_NOMBRE_PROVEEDOR' +
      '(PRO_CODIGO) AS PRO_NOMBRE, PRO_CODIGO, ENCNOTREC_CONTACTO, ENCNOTREC_TOTAL, ENCNOTREC_TOTALNETO';
    this.busquedaSer.busquedaComEncNotRec2(this.encordService.encOrdSeleccionadaarray[this.indicador].PRO_CODIGO).subscribe((datos: any[]) => {
        if (datos !== null) {
          this.arregloCons = datos;
          this.arregloCons.map((nota) => {
            nota.ENCNOTREC_FECHAEMISION = this.datePipe.transform(nota.ENCNOTREC_FECHAEMISION, 'dd/MM/yyyy');
          });
        } else {
          this.arregloCons = [];
        }
        this.displayDialogBusquedaFast = true;
      });
  }

  seleccionDetalleOrd(valor) {
    if (valor === 'nuevo') {
      this.nuevoDetalleOrden();
    } else if (valor === 'eliminar') {
      //GSRF 
      console.log('aqui elimina--',this.detalleOrdenSeleccionada)
      if (this.detalleOrdenSeleccionada !== undefined) {
        console.log('aqui elimina--',this.detalleOrdenSeleccionada.DETORDCOM_DESPACHO)
        if (Number(this.detalleOrdenSeleccionada.DETORDCOM_DESPACHO) === Number(0)) {
          this.confirmarEliminarDetOrden();
        }else{
          this.message.add({
            key: 'ordenCom',
            severity: 'error',
            summary: 'Eliminar detalle de la orden',
            detail: 'No se puede eliminar un detalle que ya tiene recepción'
          });
        }
      }
      //GSRF
    } else if (valor === 'cancelar') {
      this.cancelarDetOrden();
    } else if (valor === 'requisicion') {
      this.abrirRequisicion();
    }
    if (valor.object !== undefined) {
      this.detalleOrdenSeleccionada = valor.object;
      if (this.aggrid.gridApi.getFocusedCell().column.colId === 'DETORDCOM_UNIDAD') {
        if (this.detalleOrdenSeleccionada.ART_MULTIUNIDAD === 'S') {
          const opciones = [];
          opciones.push(this.detalleOrdenSeleccionada.DETORDCOM_UNIDAD);
          this.utilitariosService.getUnidadesEqui(this.detalleOrdenSeleccionada.DETORDCOM_UNIDAD).subscribe((res) => {
            if (res !== null) {
              res.map((unidades) => {
                opciones.push(unidades.UNI_CODIGODESTINO);
              });
            }
          });
          this.encordService.detordenesarray[this.indicador][this.encordService.detordenesarray[this.indicador].indexOf(this.detalleOrdenSeleccionada)].DETORDCOM_UNIDAD =
            this.detalleOrdenSeleccionada.DETORDCOM_UNIDAD;
          this.columnDefsOrdenDetalle[5].cellEditor = 'agSelectCellEditor';
          this.columnDefsOrdenDetalle[5].editable = true;
          this.columnDefsOrdenDetalle[5].cellEditorParams = {
            values: opciones
          };
          this.aggrid.refreshColumnDefs();
          this.aggrid.gridApi.setFocusedCell(this.encordService.detordenesarray[this.indicador].indexOf(this.detalleOrdenSeleccionada), 'DETORDCOM_UNIDAD');
        } else if (this.detalleOrdenSeleccionada.ART_MULTIUNIDAD === 'N') {
          const opciones = [];
          opciones.push(this.detalleOrdenSeleccionada.DETORDCOM_UNIDAD);
          this.encordService.detordenesarray[this.indicador][this.encordService.detordenesarray[this.indicador].indexOf(this.detalleOrdenSeleccionada)].DETORDCOM_UNIDAD =
            this.detalleOrdenSeleccionada.DETORDCOM_UNIDAD;
          this.columnDefsOrdenDetalle[5].cellEditor = 'cellPrueba';
          this.columnDefsOrdenDetalle[5].editable = false;
          this.aggrid.refreshColumnDefs();
          this.aggrid.gridApi.setFocusedCell(this.encordService.detordenesarray[this.indicador].indexOf(this.detalleOrdenSeleccionada), 'DETORDCOM_UNIDAD');
        }
      }
      if (this.detalleOrdenSeleccionada.DETORDCOM_CODIGO !== null && this.detalleOrdenSeleccionada.DETORDCOM_CODIGO !== '') {
        this.encordService.consultarImagen(this.detalleOrdenSeleccionada.DETORDCOM_CODIGO).subscribe((res) => {
          if (res.length !== 0) {
            this.imagenProducto = res[0].ART_IMAGEN;
          } else if (res.length === 0) {
            this.imagenProducto = '';
          }
        });
      }
    }
  }

  nuevoDetalleOrden() {
    if (this.bodCodigo === '' ||
      this.bodCodigo === undefined ||
      this.bodCodigo === null) {
      this.message.add({
        key: 'ordenCom',
        severity: 'error',
        summary: 'Inserción de detalle de la orden',
        detail: 'Primero seleccione una bodega '
          + 'para ingresar el detalle'
      });
      return;
    }
    if (this.encordService.encOrdSeleccionadaarray[this.indicador].PRO_CODIGO !== '') {
      this.btnNuevoGrid = true;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      const linea = this.encordService.detordenesarray[this.indicador].length;
      let nuevodetOrden
      // const nuevodetOrden = {
      //   ENCORDCOM_NUMERO: '', COM_CODIGO: '01', LINEA: Number(linea + 1), DETORDCOM_LINEA: 0, DETORDCOM_TIPODET: this.tipodetalle,
      //   DETORDCOM_CODIGO: '', DETORDCOM_DESCRIPCION: '', DETORDCOM_UNIDAD: '', DETORDCOM_CANTIDAD: '', DETORDCOM_DESPACHO: '',
      //   DETORDCOM_COSTO: '', DETORDCOM_DESCUENTO: 0, DETORDCOM_TOTAL: 0, DETORDCOM_TRIBIVA: '', DETORDCOM_IVA: 0,
      //   DETORDCOM_TRIBICE: '', DETORDCOM_ICE: 0, DETORDCOM_PORCEICE: '', DETORDCOM_BASEIVA: 0, DETORDCOM_BASEICE: 0, DETORDCOM_BASECERO: 0,
      //   BOD_CODIGO: this.bodCodigo, DETORDCOM_CANTIDADUND: '', DETADQNAEX_NUMSEC: '',
      //   ENCADQNAEX_NUMERO: '', DETREQ_LINEA: '', ENCREQ_NUMERO: '', DETORDCOM_PORCEDES: 0, DETORDCOM_VALORDES: 0,
      //   DETORDCOM_DESCUENTO3: '', DETORDCOM_DESCUENTO2: ''
      // };
      //CDPJ
      if(this.tipodetalle !== '*'){
        nuevodetOrden= {
          ENCORDCOM_NUMERO: '', COM_CODIGO: '01', LINEA: Number(linea + 1), DETORDCOM_LINEA: 0, DETORDCOM_TIPODET: this.tipodetalle,
          DETORDCOM_CODIGO: '', DETORDCOM_DESCRIPCION: '', DETORDCOM_UNIDAD: '', DETORDCOM_CANTIDAD: '', DETORDCOM_DESPACHO: '',
          DETORDCOM_COSTO: '', DETORDCOM_DESCUENTO: 0, DETORDCOM_TOTAL: 0, DETORDCOM_TRIBIVA: '', DETORDCOM_IVA: 0,
          DETORDCOM_TRIBICE: '', DETORDCOM_ICE: 0, DETORDCOM_PORCEICE: '', DETORDCOM_BASEIVA: 0, DETORDCOM_BASEICE: 0, DETORDCOM_BASECERO: 0,
          BOD_CODIGO: this.bodCodigo, DETORDCOM_CANTIDADUND: '', DETADQNAEX_NUMSEC: '',
          ENCADQNAEX_NUMERO: '', DETREQ_LINEA: '', ENCREQ_NUMERO: '', DETORDCOM_PORCEDES: 0, DETORDCOM_VALORDES: 0,
          DETORDCOM_DESCUENTO3: '', DETORDCOM_DESCUENTO2: '',DETORDCOM_PORIVA:Number(this.selectcombtipoIva.name), TARIIVA_CODIGO:this.selectcombtipoIva.codigo
        };
      }else{
        nuevodetOrden = {
          ENCORDCOM_NUMERO: '', COM_CODIGO: '01', LINEA: Number(linea + 1), DETORDCOM_LINEA: 0, DETORDCOM_TIPODET: this.tipodetalle,
          DETORDCOM_CODIGO: '', DETORDCOM_DESCRIPCION: '', DETORDCOM_UNIDAD: '', DETORDCOM_CANTIDAD: '', DETORDCOM_DESPACHO: '',
          DETORDCOM_COSTO: '', DETORDCOM_DESCUENTO: 0, DETORDCOM_TOTAL: 0, DETORDCOM_TRIBIVA: '', DETORDCOM_IVA: 0,
          DETORDCOM_TRIBICE: '', DETORDCOM_ICE: 0, DETORDCOM_PORCEICE: '', DETORDCOM_BASEIVA: 0, DETORDCOM_BASEICE: 0, DETORDCOM_BASECERO: 0,
          BOD_CODIGO: this.bodCodigo, DETORDCOM_CANTIDADUND: '', DETADQNAEX_NUMSEC: '',
          ENCADQNAEX_NUMERO: '', DETREQ_LINEA: '', ENCREQ_NUMERO: '', DETORDCOM_PORCEDES: 0, DETORDCOM_VALORDES: 0,
          DETORDCOM_DESCUENTO3: '', DETORDCOM_DESCUENTO2: '',DETORDCOM_PORIVA:0, TARIIVA_CODIGO:'-1'
        };
      }
      //CDPJ
      if (this.detalleOrdenSeleccionada !== undefined && this.detalleOrdenSeleccionada !== null) {
        const indi = this.encordService.detordenesarray[this.indicador].indexOf(this.detalleOrdenSeleccionada);
        if (indi === -1) {
          this.encordService.detordenesarray[this.indicador].push(nuevodetOrden);
          this.detalleOrdenSeleccionada = this.encordService.detordenesarray[this.indicador][this.encordService.detordenesarray[this.indicador].length - 1];
          this.aggrid.refreshaggrid(this.encordService.detordenesarray[this.indicador], this.defaultOrdenDetalle.objeto);
          //this.aggrid.gridApi.setFocusedCell(this.encordService.detordenesarray[this.indicador].length - 1, 'DETORDCOM_CODIGO');
          //CDPJ
          if(this.detalleOrdenSeleccionada.DETORDCOM_TIPODET !== '*'){
            this.aggrid.gridApi.setFocusedCell(this.encordService.detordenesarray[this.indicador].length - 1, 'DETORDCOM_CODIGO');
          }else{
            this.aggrid.gridApi.setFocusedCell(this.encordService.detordenesarray[this.indicador].length - 1, 'DETORDCOM_DESCRIPCION');
          }
          //CDPJ
        } else {
          this.encordService.detordenesarray[this.indicador].splice(indi + 1, 0, nuevodetOrden);
          this.detalleOrdenSeleccionada=nuevodetOrden;//CDPJ
          this.aggrid.refreshaggridindex(this.encordService.detordenesarray[this.indicador], this.defaultOrdenDetalle.objeto, indi);
         // this.aggrid.gridApi.setFocusedCell(indi + 1, 'DETORDCOM_CODIGO');
         //CDPJ
         if(this.detalleOrdenSeleccionada.DETORDCOM_TIPODET !== '*'){
          this.aggrid.gridApi.setFocusedCell(indi + 1, 'DETORDCOM_CODIGO');
        }else{
          this.aggrid.gridApi.setFocusedCell(indi + 1, 'DETORDCOM_DESCRIPCION');
        }
         //CDPJ
        }
      }
    } else {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      this.message.add({
        key: 'ordenCom',
        severity: 'error',
        summary: 'Inserción de detalle de la orden',
        detail: 'Primero ingrese un proveedor'
      });
      document.getElementById('proveedor').focus();
    }
  }

  confirmarEliminarDetOrden() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el registro seleccionado',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'ordenComConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminardetOrd();
      },
      reject: () => {
      }
    });
  }

  eliminardetOrd() {
    if (this.detalleOrdenSeleccionada !== undefined) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      if (this.detalleOrdenSeleccionada.DETORDCOM_LINEA === 0) {
        this.encordService.detordenesarray[this.indicador] = this.encordService.detordenesarray[this.indicador].filter((val, j) => j !==
          this.encordService.detordenesarray[this.indicador].indexOf(this.detalleOrdenSeleccionada));
        this.aggrid.refreshaggrid(this.encordService.detordenesarray[this.indicador], this.defaultOrdenDetalle.objeto);
        this.encordService.calcularTotal();
      } else {
        this.encordService.eliminarDetOrd(this.detalleOrdenSeleccionada).subscribe(() => {
          this.encordService.detordenesarray[this.indicador] = this.encordService.detordenesarray[this.indicador].filter((val, j) => j !==
            this.encordService.detordenesarray[this.indicador].indexOf(this.detalleOrdenSeleccionada));
          this.aggrid.refreshaggrid(this.encordService.detordenesarray[this.indicador], this.defaultOrdenDetalle.objeto);
          //GSRF
          this.auditoriagral.registrarAuditoria('COM_DETORDCOM', String(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO)+ '/' + this.detalleOrdenSeleccionada.DETORDCOM_CODIGO,
           'E', '', '01', '', '', '',
            '').subscribe(() => {
            });
          //GSRF
            this.encordService.calcularTotal();
            this.guardar();//CDPJ
        });
      }
    }
  }

  cancelarDetOrden() {
    if (this.detalleOrdenSeleccionada.DETORDCOM_LINEA === 0) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = true;
      this.encordService.detordenesarray[this.indicador] = this.encordService.detordenesarray[this.indicador].filter((val, j) => j !==
        this.encordService.detordenesarray[this.indicador].indexOf(this.detalleOrdenSeleccionada));
      this.detalleOrdenSeleccionada = {};
    } else if (this.detalleOrdenSeleccionada.DETORDCOM_LINEA !== 0) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = true;
      this.encordService.getDetOrden(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO).subscribe((res) => {
        this.encordService.detordenesarray[this.indicador] = res;
        this.encordService.detordenesarray[this.indicador].map((detalle) => {
          if (detalle.DETORDCOM_LINEA === this.detalleOrdenSeleccionada.DETORDCOM_LINEA) {
            this.detalleOrdenSeleccionadaAux = detalle;
          }
        });
        this.detalleOrdenSeleccionada = this.detalleOrdenSeleccionadaAux;
        const opcOrden = [];
        opcOrden.push(this.detalleOrdenSeleccionada.DETORDCOM_UNIDAD);
        this.columnDefsOrdenDetalle[5].cellEditorParams = {
          values: opcOrden
        };
        this.aggrid.refreshColumnDefs();
        this.aggrid.refreshaggrid(this.encordService.detordenesarray[this.indicador], this.defaultOrdenDetalle.objeto);
        this.encordService.calcularTotal();
      });
    }
  }

  cambio(cambio) {
    if (this.encordService.encOrdSeleccionadaarray[this.indicador] !== undefined) {
      if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO === 'PENDIENTE') {
        this.btnGuardar = false;
        this.btnRegresar = false;
        this.btnNuevo = true;
        this.btnNuevoGrid = false;
      }
    }
    this.disabledEstadisticas = true;
  }

  comprobarValor() {
    if (this.booleanValor === false) {
      this.valorDscPorcentaje = Number(this.valorDscValor * 100) / Number(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_TOTALNETO);
      this.valorDscPorcentaje = Number(this.valorDscPorcentaje.toFixed(2));
      if (this.valorDscValor > Number(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_TOTALNETO)) {
        this.valorInvalidoV = true;
      } else {
        this.valorInvalidoV = false;
      }
    } else if (this.booleanPorcentaje === false) {
      this.valorDscValor = Number(this.valorDscPorcentaje * this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_TOTALNETO) / 100;
      this.valorDscValor = Number(this.valorDscValor.toFixed(2));
      if (this.valorDscPorcentaje > 100) {
        this.valorInvalidoP = true;
      } else {
        this.valorInvalidoP = false;
      }
    }
  }

  cambioDescuento() {
    if (this.dscSeleccionado === 'dscValor') {
      this.booleanValor = false;
      this.booleanPorcentaje = true;
    } else if (this.dscSeleccionado === 'dscPorcentaje') {
      this.booleanPorcentaje = false;
      this.booleanValor = true;
    }
  }

  aplicarDescuento() {
    let tipo = '';
    if (this.booleanValor === false) {
      if (this.dscProveedorActual < (this.valorDscValor * 100 / Number(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_TOTAL))) {
        tipo = 'valor';
        this.confirmarDescuento(tipo);
      } else {
        tipo = 'valor';
        this.descuentoAplicado(tipo);
      }
    }
    if (this.booleanPorcentaje === false) {
      if (this.dscProveedorActual < this.valorDscPorcentaje) {
        tipo = 'porcentaje';
        this.confirmarDescuento(tipo);
      } else {
        tipo = 'porcentaje';
        this.descuentoAplicado(tipo);
      }
    }
  }

  confirmarDescuento(tipo) {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea aplicar un descuento mayor',
      header: 'El proveedor tiene un descuento menor',
      icon: 'pi pi-exclamation-triangle',
      key: 'ordenComConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.descuentoAplicado(tipo);
      },
      reject: () => {
      }
    });
  }

  confirmarAnular() {
    this.confirmationService.confirm({
      message: 'Realmente desea anular la orden actual',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'ordenComConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.anularOrden();
      },
      reject: () => {
      }
    });
  }

  anularOrden() {
    this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO = 'ANULADA';
    this.encordService.cambiarEstadoOrden(this.encordService.encOrdSeleccionadaarray[this.indicador], 'A').subscribe(() => {
      this.message.add({
        key: 'ordenCom',
        severity: 'success',
        summary: 'Anular Orden',
        detail: 'La orden fue anulada'
      });
    });
  }

  descuentoAplicado(tipo) {
    this.displayDialogDescuento = false;
    this.btnGuardar = false;
    this.btnNuevo = true;
    if (tipo === 'valor') {
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_VALORDES = this.valorDscValor;
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_PORCEDES =
        (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_VALORDES
          * 100) / this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_TOTALNETO;
      this.valorDscPorcentaje = this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_PORCEDES;
      this.encordService.dblPorcDescGlbOrdarray[this.indicador] = this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_PORCEDES;
      this.encordService.dblValorDescGlbOrdarray[this.indicador] = this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_VALORDES;
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_TIPODES = 'V';
      this.encordService.calcularTotal();
    } else if (tipo === 'porcentaje') {
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_PORCEDES = this.valorDscPorcentaje;
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_VALORDES = Number(
        this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_TOTALNETO * this.valorDscPorcentaje / 100);
      this.valorDscValor = this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_VALORDES;
      this.encordService.dblPorcDescGlbOrdarray[this.indicador] = this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_PORCEDES;
      this.encordService.dblValorDescGlbOrdarray[this.indicador] = this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_VALORDES;
      this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_TIPODES = 'P';
      this.encordService.calcularTotal();
    }
  }

  comprobarClave() {
    if(this.accion === 'autorizar'){//CDPJ
      this.encordService.obtenerClave().subscribe((clave) => {
        let claveReal;
        claveReal = clave[0].AUTORIZACION_CLAVE;
        if (claveReal === this.claveAuth) {
          this.claveDialog = false;
          if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO === 'AUTORIZADO') {
            const consultaFac = 'select count(*) as numreg from com_encfacpro a,com_detfacpro b where a.encfacpro_numero=b.encfacpro_numero '
              + 'and a.com_codigo=b.com_codigo and nvl(a.encfacpro_estado,\'x\')<>\'A\' and b.encordcom_numero= \'' +
              this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO + '\'';
            const consultaNota = 'select count(*) as numreg from com_encnotrec a,com_detnotrec b where a.encnotrec_numero=b.encnotrec_numero '
              + 'and a.com_codigo=b.com_codigo and nvl(a.ENCNOTREC_ESTADO,\'x\')<>\'A\' and b.encordcom_numero= \'' +
              this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO + '\'';
            let regAfectados = 0;
            this.encordService.registrosRelacionadosFAC(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO).subscribe((reg) => {
              regAfectados += Number(reg[0].NUMREG);
              this.encordService.registrosRelacionadosNOTA(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO).subscribe((reg2) => {
                regAfectados += Number(reg2[0].NUMREG);
                if (regAfectados !== 0) {
                  this.message.add({
                    key: 'ordenCom',
                    severity: 'error',
                    summary: 'Autorizar Orden',
                    detail: 'No puede reversar la autorización ya que está relacionada con facturas de proveedores o con Notas de Recepción'
                  });
                } else if (regAfectados === 0) {
                  this.encordService.cambiarEstadoOrden(this.encordService.encOrdSeleccionadaarray[this.indicador], 'P').subscribe(() => {
                    this.message.add({
                      key: 'ordenCom',
                      severity: 'success',
                      summary: 'Autorizar Orden',
                      detail: 'Se reverso la autorización'
                    });
                    this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO = 'PENDIENTE';
                    document.getElementById('divPrincipal').style.pointerEvents = 'all';
                    // document.getElementById('divPrincipal2').style.pointerEvents = 'all';
                    document.getElementById('divPrincipal').style.opacity = '1.0';
                    this.aggrid.habilitarGrid();
                    this.btnNuevoGrid = false;
                    this.btnGuardarGrid = false;
                    this.btnCancelarGrid = false;
                    this.btnBorrarGrid = false;
                  });
                }
              });
            });
          } else if (this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO === 'PENDIENTE') {
            this.encordService.cambiarEstadoOrden(this.encordService.encOrdSeleccionadaarray[this.indicador], 'T').subscribe(() => {
              this.message.add({
                key: 'ordenCom',
                severity: 'success',
                summary: 'Autorizar Orden',
                detail: 'Se Autorizó la orden'
              });
              this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO = 'AUTORIZADO';
              document.getElementById('divPrincipal').style.pointerEvents = 'none';
              // document.getElementById('divPrincipal2').style.pointerEvents = 'none';
              document.getElementById('divPrincipal').style.opacity = '0.7';
              this.aggrid.bloquearGrid();
              this.btnNuevoGrid = true;
              this.btnGuardarGrid = true;
              this.btnCancelarGrid = true;
              this.btnBorrarGrid = true;
            });
          }
          this.claveAuth = '';
        } else if (clave !== this.claveAuth) {
          this.claveAuth = '';
          this.message.add({
            key: 'ordenComclave',
            severity: 'error',
            summary: 'Clave Autorización',
            detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
          });
        }
      });
    }//CDPJ
    if(this.accion === 'modporceiva'){
      this.encordService.obtenerClaveIva().subscribe((clave) => {
        let claveReal;
        claveReal = clave[0].AUTORIZACION_CLAVE;
        if (claveReal === this.claveAuth) {
          this.claveDialog = false;
          this.boolIva=false
          this.claveAuth = '';
        } else if (clave !== this.claveAuth) {
          this.claveAuth = '';
          this.message.add({
            key: 'ordenComclave',
            severity: 'error',
            summary: 'Clave Autorización',
            detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
          });
        }
      });
    }
  }

  cambiarTipo(valor) {
    /*this.encordService.detordenesarray[this.indicador].map((orden) => {
      if (orden.DETORDCOM_LINEA === this.detalleOrdenSeleccionada.DETORDCOM_LINEA) {
        orden.DETORDCOM_TIPODET = valor;
        this.detalleOrdenSeleccionada.DETORDCOM_TIPODET = valor;
      }
    });*/
    this.tipodetalle = valor;
  }

  subirArchivo() {
    this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ARCHIVO = this.archivoSubido;
  }

  abrirRequisicion() {
    /*this.opcion = 'BUSQUEDAREQ';
    this.types = [
      {label: 'Codigo', value: 'PRO_CODIGO'},
      {label: 'Nombre', value: 'PRO_NOMBRE'},
      {label: 'Nombre Corto', value: 'PRO_NOMBREC'},
      {label: 'Grupo', value: 'GRU_CODIGO'},
      {label: 'RUC', value: 'PRO_RUCIDE'},
      {label: 'Cuenta', value: 'CON_CODIGO1'}
    ];
    // CAMPOS NECESARIOS BUSQUEDA FAST
    this.busquedacampos = [this.encordService.encOrdSeleccionadaarray[this.indicador].PRO_CODIGO, '', '', '', '', ''];
    this.tabla = 'COM_MAEPROVEEDOR';
    this.consulta = 'PRO_CODIGO, PRO_NOMBRE, PRO_NOMBREC, GRU_CODIGO, PRO_RUCIDE, CON_CODIGO1, PRO_CONTACTO, PRO_IVA, PRO_DESCUENTO';
    this.busquedaSer.busquedaTabla(this.consulta, 'COM_MAEPROVEEDOR', 'rownum <= 50 AND ' + this.types[0].value +
      ' LIKE \'%' + this.encordService.encOrdSeleccionadaarray[this.indicador].PRO_CODIGO + '%\'').subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });*/
  }

  mostrarEstadisticas() {
    this.comprasRealizadas();
    this.topProductos();
  }

  async comprasRealizadas() {
    const fechaInicial = new Date();
    fechaInicial.setMonth(fechaInicial.getMonth() - 12);
    fechaInicial.setDate(1);

    const fechaAct = new Date();
    const rangoFechas: any[] = [];

    for (let i = 1; fechaInicial <= fechaAct; i++) {
      rangoFechas.push(this.datePipe.transform(fechaInicial, 'dd/MM/yyyy'));
      fechaInicial.setMonth(fechaInicial.getMonth() + 1);
    }


    try {

      const rs = await this.venEstadisticasService.comprasAcumuladas(this.encordService.encOrdSeleccionadaarray[this.indicador].PRO_CODIGO);


      if (rs !== null) {

        const index1 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[0]);
        const valor1 = index1 >= 0 ? rs[index1].VENTA_TOTAL : 0;
        const index2 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[1]);
        const valor2 = index2 >= 0 ? rs[index2].VENTA_TOTAL : 0;
        const index3 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[2]);
        const valor3 = index3 >= 0 ? rs[index3].VENTA_TOTAL : 0;
        const index4 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[3]);
        const valor4 = index4 >= 0 ? rs[index4].VENTA_TOTAL : 0;
        const index5 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[4]);
        const valor5 = index5 >= 0 ? rs[index5].VENTA_TOTAL : 0;
        const index6 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[5]);
        const valor6 = index6 >= 0 ? rs[index6].VENTA_TOTAL : 0;
        const index7 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[6]);
        const valor7 = index7 >= 0 ? rs[index7].VENTA_TOTAL : 0;
        const index8 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[7]);
        const valor8 = index8 >= 0 ? rs[index8].VENTA_TOTAL : 0;
        const index9 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[8]);
        const valor9 = index9 >= 0 ? rs[index9].VENTA_TOTAL : 0;
        const index10 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[9]);
        const valor10 = index10 >= 0 ? rs[index10].VENTA_TOTAL : 0;
        const index11 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[10]);
        const valor11 = index11 >= 0 ? rs[index11].VENTA_TOTAL : 0;
        const index12 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[11]);
        const valor12 = index12 >= 0 ? rs[index12].VENTA_TOTAL : 0;
        const index13 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[12]);
        const valor13 = index13 >= 0 ? rs[index13].VENTA_TOTAL : 0;

        this.dataComprasProveedor = {
          labels: [
            String(rangoFechas[0]).substring(8, 10) + '/' + String(rangoFechas[0]).substring(3, 5),
            String(rangoFechas[1]).substring(8, 10) + '/' + String(rangoFechas[1]).substring(3, 5),
            String(rangoFechas[2]).substring(8, 10) + '/' + String(rangoFechas[2]).substring(3, 5),
            String(rangoFechas[3]).substring(8, 10) + '/' + String(rangoFechas[3]).substring(3, 5),
            String(rangoFechas[4]).substring(8, 10) + '/' + String(rangoFechas[4]).substring(3, 5),
            String(rangoFechas[5]).substring(8, 10) + '/' + String(rangoFechas[5]).substring(3, 5),
            String(rangoFechas[6]).substring(8, 10) + '/' + String(rangoFechas[6]).substring(3, 5),
            String(rangoFechas[7]).substring(8, 10) + '/' + String(rangoFechas[7]).substring(3, 5),
            String(rangoFechas[8]).substring(8, 10) + '/' + String(rangoFechas[8]).substring(3, 5),
            String(rangoFechas[9]).substring(8, 10) + '/' + String(rangoFechas[9]).substring(3, 5),
            String(rangoFechas[10]).substring(8, 10) + '/' + String(rangoFechas[10]).substring(3, 5),
            String(rangoFechas[11]).substring(8, 10) + '/' + String(rangoFechas[11]).substring(3, 5),
            String(rangoFechas[12]).substring(8, 10) + '/' + String(rangoFechas[12]).substring(3, 5),
          ],
          datasets: [{
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [valor1, valor2, valor3, valor4, valor5, valor6, valor7,
              valor8, valor9, valor10, valor11, valor12, valor13]
          }
          ]
        };

      } else {
        this.dataComprasProveedor = {
          labels: [
            String(rangoFechas[0]).substring(8, 10) + '/' + String(rangoFechas[0]).substring(3, 5),
            String(rangoFechas[1]).substring(8, 10) + '/' + String(rangoFechas[1]).substring(3, 5),
            String(rangoFechas[2]).substring(8, 10) + '/' + String(rangoFechas[2]).substring(3, 5),
            String(rangoFechas[3]).substring(8, 10) + '/' + String(rangoFechas[3]).substring(3, 5),
            String(rangoFechas[4]).substring(8, 10) + '/' + String(rangoFechas[4]).substring(3, 5),
            String(rangoFechas[5]).substring(8, 10) + '/' + String(rangoFechas[5]).substring(3, 5),
            String(rangoFechas[6]).substring(8, 10) + '/' + String(rangoFechas[6]).substring(3, 5),
            String(rangoFechas[7]).substring(8, 10) + '/' + String(rangoFechas[7]).substring(3, 5),
            String(rangoFechas[8]).substring(8, 10) + '/' + String(rangoFechas[8]).substring(3, 5),
            String(rangoFechas[9]).substring(8, 10) + '/' + String(rangoFechas[9]).substring(3, 5),
            String(rangoFechas[10]).substring(8, 10) + '/' + String(rangoFechas[10]).substring(3, 5),
            String(rangoFechas[11]).substring(8, 10) + '/' + String(rangoFechas[11]).substring(3, 5),
            String(rangoFechas[12]).substring(8, 10) + '/' + String(rangoFechas[12]).substring(3, 5),
          ],
          datasets: [{
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
          ]
        };
      }
    } catch (err) {

    }
  }

  async topProductos() {
    this.detTopProductos = [];
    let totalCantidad = 0;
    let totalPrecio = 0;
    let total = 0;
    const fechaActual = new Date();
    const strFecha = this.datePipe.transform(fechaActual, 'dd/MM/yyyy');
    const time = new Date();
    const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
    const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
    const strHora = hora + ':' + minutos;

    const detalle: ComTopProductos[] = [];


    try {
      const rs = await this.venEstadisticasService.topProductos(this.encordService.encOrdSeleccionadaarray[this.indicador].PRO_CODIGO,
        'cantidad');
      if (rs !== null) {
        for (const item of rs) {
          const det: ComTopProductos = {};
          det.DETFACPRO_CODIGO = item.DETFACPRO_CODIGO;
          det.DETFACPRO_DESCRIPCION = item.DETFACPRO_DESCRIPCION;
          det.CANTIDAD_TOTAL = Number(item.CANTIDAD_TOTAL).toFixed(2);
          totalCantidad = totalCantidad + Number(item.CANTIDAD_TOTAL);
          det.DETFACPRO_COSTO = Number(item.DETFACPRO_COSTO).toFixed(2);
          totalPrecio = totalPrecio + Number(item.DETFACPRO_COSTO);
          det.TOTAL = Number(item.TOTAL).toFixed(2);
          total = total + Number(item.TOTAL);
          det.STOCK = Number(item.STOCK).toFixed(2);
          detalle.push(det);
        }
        const totales: ComTopProductos = {};
        totales.DETFACPRO_CODIGO = '';
        totales.DETFACPRO_DESCRIPCION = 'TOTAL';
        totales.CANTIDAD_TOTAL = Number(totalCantidad).toFixed(2);
        totales.DETFACPRO_COSTO = Number(totalPrecio).toFixed(2);
        totales.TOTAL = Number(total).toFixed(2);
        totales.STOCK = '';
        detalle.push(totales);

        this.detTopProductos = detalle;
      }

    } catch (err) {

    }
  }

  changeTab() {
    if (this.index === 2) {

      this.dataComprasProveedor = {
        labels: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
        datasets: [{
          label: 'Ventas',
          backgroundColor: '#42A5F5',
          borderColor: '#1E88E5',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
        ]
      };

      this.dataVentasVsCompras = {
        labels: ['', ''],
        datasets: [
          {
            label: 'Ventas',
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [0]
          },
          {
            label: 'Compras',
            backgroundColor: '#9CCC65',
            borderColor: '#7CB342',
            data: [0]
          }
        ]
      };

      this.detTopProductos = [];
      this.detalleExistencia = [];
      this.detMovimientosProv = [];
      this.detExistenciaBodegas = [];
      this.txtArtCodigo = '';
      this.txtArtDescripcion = '';
      this.txtExistenciaMax = '';
      this.txtExistenciaMin = '';
      this.txtArtUnidad = '';
      this.txtArtRotacion = '';
      this.txtInventarioIni = '';
      this.txtComprasPeriodo = '';
      this.txtTotalDisponible = '';
      this.txtVenUltimoPer = '';
      this.txtVenPromPerAnt = '';
      this.txtIndiceProvUltPer = '';
      this.txtIndiceProvPeriodos = '';
      this.txtComprasPlanificadas = '';
      this.txtTotalDisponibleCom = '';
      this.txtIndiceProvUltPer2 = '';
      this.txtIndiceProvPeriodos2 = '';

      const existencias: ComExistencias = {};
      existencias.COMPRAR = '';
      existencias.EXIST_MIN = '';
      existencias.EXISTENCIA = '';
      existencias.ORDENES_COMPRA = '';
      existencias.PEDIDOS_CLIENTES = '';
      existencias.SALDO = '';
      existencias.TOTAL_COMPRA = '';
      existencias.ULTCOSTO_COM = '';
      this.detalleExistencia.push(existencias);

    }
  }

  async orderByTopProd(tipo) {
    this.detTopProductos = [];
    let totalCantidad = 0;
    let totalPrecio = 0;
    let total = 0;

    const fechaActual = new Date();
    const strFecha = this.datePipe.transform(fechaActual, 'dd/MM/yyyy');
    const time = new Date();
    const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
    const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
    const strHora = hora + ':' + minutos;

    const detalle: ComTopProductos[] = [];
    let rs;

    try {
      if (tipo === 'total') {
        rs = await this.venEstadisticasService.topProductos(this.encordService.encOrdSeleccionadaarray[this.indicador].PRO_CODIGO,
          'total');
      }

      if (tipo === 'cantidad') {
        rs = await this.venEstadisticasService.topProductos(this.encordService.encOrdSeleccionadaarray[this.indicador].PRO_CODIGO,
          'cantidad');
      }
      if (rs !== null) {
        for (const item of rs) {
          const det: ComTopProductos = {};
          det.DETFACPRO_CODIGO = item.DETFACPRO_CODIGO;
          det.DETFACPRO_DESCRIPCION = item.DETFACPRO_DESCRIPCION;
          det.CANTIDAD_TOTAL = Number(item.CANTIDAD_TOTAL).toFixed(2);
          totalCantidad = totalCantidad + Number(item.CANTIDAD_TOTAL);
          det.DETFACPRO_COSTO = Number(item.DETFACPRO_COSTO).toFixed(2);
          totalPrecio = totalPrecio + Number(item.DETFACPRO_COSTO);
          det.TOTAL = Number(item.TOTAL).toFixed(2);
          total = total + Number(item.TOTAL);
          det.STOCK = Number(item.STOCK).toFixed(2);
          detalle.push(det);
        }
        const totales: ComTopProductos = {};
        totales.DETFACPRO_CODIGO = '';
        totales.DETFACPRO_DESCRIPCION = 'TOTAL';
        totales.CANTIDAD_TOTAL = Number(totalCantidad).toFixed(2);
        totales.DETFACPRO_COSTO = Number(totalPrecio).toFixed(2);
        totales.TOTAL = Number(total).toFixed(2);
        totales.STOCK = '';
        detalle.push(totales);

        this.detTopProductos = detalle;
      }

    } catch (err) {

    }
  }

  buscarArticulo() {
    this.detalleExistencia = [];
    this.detMovimientosProv = [];
    this.detExistenciaBodegas = [];

    this.txtArtCodigo = '';
    this.txtArtDescripcion = '';
    this.txtExistenciaMax = '';
    this.txtExistenciaMin = '';
    this.txtArtUnidad = '';
    this.txtArtRotacion = '';
    this.txtInventarioIni = '';
    this.txtComprasPeriodo = '';
    this.txtTotalDisponible = '';
    this.txtVenUltimoPer = '';
    this.txtVenPromPerAnt = '';
    this.txtIndiceProvUltPer = '';
    this.txtIndiceProvPeriodos = '';
    this.txtComprasPlanificadas = '';
    this.txtTotalDisponibleCom = '';
    this.txtIndiceProvUltPer2 = '';
    this.txtIndiceProvPeriodos2 = '';

    const existencias: ComExistencias = {};
    existencias.COMPRAR = '';
    existencias.EXIST_MIN = '';
    existencias.EXISTENCIA = '';
    existencias.ORDENES_COMPRA = '';
    existencias.PEDIDOS_CLIENTES = '';
    existencias.SALDO = '';
    existencias.TOTAL_COMPRA = '';
    existencias.ULTCOSTO_COM = '';
    this.detalleExistencia.push(existencias);

    const grafico = {
      labels: ['', ''],
      datasets: [
        {
          label: 'Ventas',
          backgroundColor: '#42A5F5',
          borderColor: '#1E88E5',
          data: [0]
        },
        {
          label: 'Compras',
          backgroundColor: '#9CCC65',
          borderColor: '#7CB342',
          data: [0]
        }
      ]
    };

    this.dataVentasVsCompras = grafico;


    this.opcion = 'articulo';
    this.types = [
      { label: 'Código', value: 'ART_CODIGO' },
      { label: 'Nombre', value: 'ART_NOMBRE' },
      { label: 'Grupo', value: 'GRUP_CODIGO' },
      { label: 'NombreCorto', value: 'ART_NOMBREC' },
      { label: 'Laboratorio', value: 'ART_LABORATORIO' },
      { label: 'Ubicación', value: 'ART_UBICACION' },
      { label: 'CodigoBarras', value: 'ART_CODIGOALT1' },
      { label: 'Alterno3', value: 'ART_CODIGOALT3' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'INV_MAEARTICULO';


    this.busquedaSer.busquedaInvMaeArticulo1().subscribe((datos: any[]) => {
        this.arregloCons = datos;
        this.displayDialogBusquedaFast = true;
      });
  }

  async llenarMovByProvArt(tipo) {
    this.detMovimientosProv = [];
    const detalle: ComMovimientoProv[] = [];

    let rs;

    try {
      if (tipo === 'fecha') {
        rs = await this.venEstadisticasService.movimientoArtProv(this.txtArtCodigo, 'fecha');
      }

      if (tipo === 'costo') {
        rs = await this.venEstadisticasService.movimientoArtProv(this.txtArtCodigo, 'costo');
      }
      if (rs !== null) {
        for (const item of rs) {
          const det: ComMovimientoProv = {};
          det.PRO_CODIGO = item.PRO_CODIGO;
          det.PRO_NOMBRE = item.PRO_NOMBRE;
          det.ENCFACPRO_FECHAEMISION = this.datePipe.transform(item.ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
          det.DETFACPRO_COSTO = Number(item.DETFACPRO_COSTO).toFixed(2);


          detalle.push(det);
        }
        this.detMovimientosProv = detalle;
      }

    } catch (err) {

    }
  }

  async llenarTablaExistencia() {


    let rs1;
    let rs2;
    let rs3;
    let rs4;

    try {
      rs1 = this.venEstadisticasService.ultimoCostoCompra(this.txtArtCodigo);
      rs2 = this.venEstadisticasService.existenciaArticulo(this.txtArtCodigo);
      rs3 = this.venEstadisticasService.cantidadOrdCompra(this.txtArtCodigo);
      rs4 = this.venEstadisticasService.cantidadPedidos(this.txtArtCodigo);
    } catch (err) {

    }

    rs1 = await rs1;
    rs2 = await rs2;
    rs3 = await rs3;
    rs4 = await rs4;

    this.detalleExistencia = [];
    const existencias: ComExistencias = {};
    existencias.ULTCOSTO_COM = Number(rs1[0].ULTIMO_COSTO).toFixed(2);
    existencias.EXISTENCIA = Number(rs2[0].EXISTENCIA);
    existencias.ORDENES_COMPRA = Number(rs3[0].TOTAL_ORDCOM);
    existencias.EXIST_MIN = this.txtExistenciaMin;
    existencias.PEDIDOS_CLIENTES = Number(rs4[0].TOTAL_PEDIDOS);
    existencias.SALDO = Number(rs2[0].EXISTENCIA) + Number(rs3[0].TOTAL_ORDCOM) - Number(rs4[0].TOTAL_PEDIDOS);
    existencias.COMPRAR = existencias.SALDO < Number(this.txtExistenciaMin) ? Math.abs(existencias.SALDO) + Number(this.txtExistenciaMin) : 0;
    existencias.TOTAL_COMPRA = this.init.moneyValidation(Number(existencias.COMPRAR * Number(existencias.ULTCOSTO_COM)).toFixed(2));


    this.detalleExistencia.push(existencias);
  }

  async llenarExistenciaBodegas() {
    this.detExistenciaBodegas = [];
    const detalle: ComExistenciaBodegas[] = [];

    const fechaActual = new Date();
    const strFecha = this.datePipe.transform(fechaActual, 'dd/MM/yyyy');
    const time = new Date();
    const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
    const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
    const strHora = hora + ':' + minutos;



    try {
      const rs = await this.venEstadisticasService.llenarExistenciaBod(this.txtArtCodigo);
      if (rs !== null) {
        for (const item of rs) {
          const det: ComExistenciaBodegas = {};
          det.BOD_CODIGO = item.BOD_CODIGO;
          det.BOD_NOMBRE = item.BOD_NOMBRE;
          det.EXISTENCIA = Number(item.EXISTENCIA).toFixed(2);
          det.PEDIDO = Number(item.PEDIDOS).toFixed(2);
          det.SALDO = Number(item.SALDO).toFixed(2);


          detalle.push(det);
        }
        this.detExistenciaBodegas = detalle;
      }

    } catch (err) {

    }
  }

  async ventasVsCompras() {

    let rs1;
    let rs2;

    try {

      rs1 = this.venEstadisticasService.ventasVsCompras(this.txtArtCodigo, 'V');
      rs2 = this.venEstadisticasService.ventasVsCompras(this.txtArtCodigo, 'C');

    } catch (err) {

    }
    rs1 = await rs1;
    rs2 = await rs2;

    const grafico = {
      labels: ['', ''],
      datasets: [
        {
          label: 'Ventas',
          backgroundColor: '#42A5F5',
          borderColor: '#1E88E5',
          data: [rs1[0].TOTALVENTAS]
        },
        {
          label: 'Compras',
          backgroundColor: '#9CCC65',
          borderColor: '#7CB342',
          data: [rs2[0].TOTALCOMPRAS]
        }
      ]
    };

    this.dataVentasVsCompras = grafico;

  }

  async llenarIndiceProvisiones() {

    let rs1;
    let rs2;
    let rs3;
    let rs4;

    try {
      rs1 = this.venEstadisticasService.obtenerExistenciaArtIndProv(this.txtArtCodigo);
      rs2 = this.venEstadisticasService.comprasPeriodo(this.txtArtCodigo);
      rs3 = this.venEstadisticasService.comprasUltPeriodo(this.txtArtCodigo);
      rs4 = this.venEstadisticasService.ventasPeriodo(this.txtArtCodigo);
    } catch (err) {

    }

    rs1 = await rs1;
    rs2 = await rs2;
    rs3 = await rs3;
    rs4 = await rs4;

    this.txtInventarioIni = Number(rs1[0].EXISTENCIA_ACTUAL).toFixed(2);
    this.txtComprasPeriodo = Number(rs2[0].COMPRAS_PERIODO).toFixed(2);
    this.txtTotalDisponible = Number(rs1[0].EXISTENCIA_ACTUAL + rs2[0].COMPRAS_PERIODO).toFixed(2);

    this.txtVenUltimoPer = Number(rs3[0].VENTAS_ULTIMOPER).toFixed(2);
    this.txtVenPromPerAnt = Number(rs4[0].VENTAS_PERIODOS / 10).toFixed(2);


    this.txtIndiceProvUltPer = Number(this.txtVenUltimoPer) !== 0 ? Number(this.txtTotalDisponible) / Number(this.txtVenUltimoPer) :
      0;
    this.txtIndiceProvUltPer = Number(this.txtIndiceProvUltPer).toFixed(2);
    this.txtIndiceProvPeriodos = Number(this.txtVenPromPerAnt) !== 0 ? Number(this.txtTotalDisponible) / Number(this.txtVenPromPerAnt) :
      0;
    this.txtIndiceProvPeriodos = Number(this.txtIndiceProvPeriodos).toFixed(2);

  }

  calcularIndice() {
    this.txtTotalDisponibleCom = Number(this.txtTotalDisponible) + Number(this.txtComprasPlanificadas);
    this.txtTotalDisponibleCom = Number(this.txtTotalDisponibleCom).toFixed(2);

    this.txtIndiceProvUltPer2 = Number(this.txtVenUltimoPer) !== 0 ? Number(this.txtTotalDisponibleCom) / Number(this.txtVenUltimoPer) : 0;
    this.txtIndiceProvUltPer2 = Number(this.txtIndiceProvUltPer2).toFixed(2);
    this.txtIndiceProvPeriodos2 = Number(this.txtVenPromPerAnt) !== 0 ? Number(this.txtTotalDisponibleCom) / Number(this.txtVenPromPerAnt) :
      0;
    this.txtIndiceProvPeriodos2 = Number(this.txtIndiceProvPeriodos2).toFixed(2);
  }

  cerrarReportes() {
    this.displayDialogReportes = false;
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos  = false;
    }
  }
   //CDPJ
   acciones(valor) {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 0; i < elemento.length; i++) {
        elemento[i].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 0; i < elemento.length; i++) {
        elemento[i].className = 'layout-config';
      }
    }
  }
  closeSidebar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }
  async cambioestado() {
    this.codigogre = '';
    this.numerogre = '';
    this.fechagre = '<--- Sin Notas de recepción --->';
    this.cligre = '';
    this.totgre = '';
    this.codigofac = '';
    this.fechafac = '';
    this.clifac = '<--- Sin Facturas de proveedor --->';
    this.clinomfac = '';
    this.totfac = '';
    const data = await this.encordService.obtenernotrec(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO);
    for (const rs of data) {
      this.codigogre = rs.ENCNOTREC_REFERENCIA === null ? '' : rs.ENCNOTREC_REFERENCIA;
      this.numerogre = rs.ENCNOTREC_NUMERO === null ? '' : rs.ENCNOTREC_NUMERO;
      this.fechagre = rs.ENCNOTREC_FECHAEMISION === null ? '' : this.datePipe.transform(rs.ENCNOTREC_FECHAEMISION, 'dd/MM/yyyy');
      this.cligre = rs.PRO_CODIGO === null ? '' : rs.PRO_CODIGO;
      this.totgre = rs.ENCNOTREC_TOTAL === null ? '' : rs.ENCNOTREC_TOTAL;
    };

    const data1 = await this.encordService.obtenerfacturaprov(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO);
    for (const rs1 of data1) {
      this.codigofac = rs1.ENCFACPRO_NUMERO === null ? '' : rs1.ENCFACPRO_NUMERO;
      this.fechafac = rs1.ENCFACPRO_FECHAEMISION === null ? '' : this.datePipe.transform(rs1.ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
      this.clifac = rs1.PRO_CODIGO === null ? '' : rs1.PRO_CODIGO;
      this.clinomfac = rs1.PRO_NOMBRE === null ? '' : rs1.PRO_NOMBRE;
      this.totfac = rs1.ENCFACPRO_TOTAL === null ? '' : rs1.ENCFACPRO_TOTAL;
    };
    this.closeSidebar();
    this.displaycambioestado = true;
  }
  async cambiarestadopen() {

    //console.log('+++++++',this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO);
    if(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO === 'ENTREGADA'
      || this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO === 'AUTORIZADO'
      || this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO === 'FACTURADA'){
        console.log('1',this.fechagre)
        console.log('2',this.clifac)
      if (this.fechagre === '<--- Sin Notas de recepción --->' && this.clifac === '<--- Sin Facturas de proveedor --->') {
        this.encordService.verificaEstadoOrdcom(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO).subscribe(() => {
        });
        await this.encordService.actualizarEstado(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO,
          'P').toPromise()
      
        this.displaycambioestado = false;
         this.cancelarEdicionOrden();
         this.cancelarEdicionOrden();
      } else {
        this.displaycambioestado = false;
         this.cancelarEdicionOrden();
         this.cancelarEdicionOrden();
  
         this.message.add({
          key: 'ordenCom',
          severity: 'info',
          summary: 'Información',
          detail: 'No se puede cambiar estado a pendiente porque la orden de compra tiene notas de recepción o facturas de proveedores'
        });
  
      }
    } else {
      console.log('AN',this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO)
      if( this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_ESTADO === 'ANULADA'){
        this.displaycambioestado = false;
         this.cancelarEdicionOrden();
         this.cancelarEdicionOrden();
      }else{
        this.encordService.verificaEstadoOrdcom(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO).subscribe(() => {
        });
        await this.encordService.actualizarEstado(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO,
          'P').toPromise()        
        this.displaycambioestado = false;
         this.cancelarEdicionOrden();
         this.cancelarEdicionOrden();
      }
         
      }

    //this.pedidoCancelado();
     this.cancelarEdicionOrden();
  }
  modporceiva(){
    this.closeSidebar();
    this.claveDialog = true;
    this.accion='modporceiva'
  }
  //cdpj
  async verbasesiva(){
    this.closeSidebar()
    this.basesiva=[]
    const data = await this.encordService.obtenerbasesiva(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO);
    if(data !== null && data !== undefined){
     this.basesiva=data
     for (const data2 of  this.basesiva){
       data2.TARIIVA_PORCENTAJE=isNaN(Number(data2.TARIIVA_PORCENTAJE))?Number(0).toFixed(2):Number(data2.TARIIVA_PORCENTAJE).toFixed(2)
       data2.IVA_BASE=isNaN(Number(data2.IVA_BASE))?Number(0).toFixed(2):Number(data2.IVA_BASE).toFixed(2)
       data2.IVA_VALOR=isNaN(Number(data2.IVA_VALOR))?Number(0).toFixed(2):Number(data2.IVA_VALOR).toFixed(2)
     }
    }
    this.displaybasesiva=true;

  }
  async actbasesiva(){
    this.spin=true;
    this.closeSidebar();
    try {
      await this.encordService.actualizabasesiva(this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO);
      this.message.add({
        key: 'ordenCom',
        severity: 'success',
        summary: 'Información',
        detail: 'Bases IVA actualizadas correctamente'
      });
      this.auditoriagral.registrarAuditoria('COM_ENCORDCOM',this.encordService.encOrdSeleccionadaarray[this.indicador].ENCORDCOM_NUMERO+'/'+'ACCIONES_ACT_VALORES_BASES_IVA', 'A', 
      '', '01', '', '', '', '').subscribe(() => {
      });
      this.spin=false;
    } catch (error) {
      this.message.add({
        key: 'ordenCom',
        severity: 'error',
        summary: 'Información',
        detail: 'Error al actualiza las bases IVA'+ error
      });
      this.spin=false;
    }
    
  }
  //cdpj
  //CDPJ
}
