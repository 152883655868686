import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Observable} from 'rxjs';
import {Invnumtransferencia} from '../invinterfaces/invnumtransferencia';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class InvnumtransferenciaService {
  get commaenumtrans(): Invnumtransferencia[] {
    return this._commaenumtrans;
  }

  set commaenumtrans(value: Invnumtransferencia[]) {
    this._commaenumtrans = value;
  }
  public url: string;
  public way: string;
  private _commaenumtrans: Invnumtransferencia[];

  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              private confIniciales: ConfInicialesService,
              public usuario: Usuario) {
                this.url = sessionStorage.getItem('url');
                this.way = sessionStorage.getItem('way');
  }

  getNumTrans(): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/inventario/numtransferencia/41ddbfadddaafc', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  insertarNumTrans(numtrans: Invnumtransferencia): Observable<any[]> {
  
    return this.http.post<any>(this.way + '/inventario/numtransferencia/95f8edf55bbfb0000000', {            
      elementos: {
        p_com_codigo: numtrans.COM_CODIGO,
        p_numtra_sigla: numtrans.NUMTRA_SIGLA,
        p_numtra_descripcion: numtrans.NUMTRA_DESCRIPCION,
        p_numtra_cliente: numtrans.NUMTRA_CLIENTE,
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarNumTrans(numtrans: Invnumtransferencia, codigo): Observable<any[]> {
    //299df2ab2a
  
    return this.http.post<any>(this.way + '/inventario/numtransferencia/299df2ab2a', {            
      elementos: {
        p_com_codigo: numtrans.COM_CODIGO,
        p_numtra_sigla: numtrans.NUMTRA_SIGLA,
        p_numtra_descripcion: numtrans.NUMTRA_DESCRIPCION,
        p_numtra_cliente: numtrans.NUMTRA_CLIENTE,
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarNumTrans(numtrans: Invnumtransferencia): Observable<any[]> {
   
    return this.http.post<any>(this.way + '/inventario/numtransferencia/2dc84d8256dbba0000000', {            
      elementos: {
        p_com_codigo: numtrans.COM_CODIGO,
        p_numtra_sigla: numtrans.NUMTRA_SIGLA,
      }
    }, this.confIniciales.httpOptions());
  }
}
