import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-recuperar-passwd',
  templateUrl: './recuperar-passwd.component.html',
  styleUrls: ['./recuperar-passwd.component.css']
})
export class RecuperarPasswdComponent implements OnInit {

  
  constructor() {}
  
  ngOnInit() {
  
  }
  
  
  
 }
