<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="invUtilExistoast"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarinvUtilExist" title="Pregunta" appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonGuardar]="true" [botonBorrar]="" [barraBotonesDesplaz]="true" [barraBotonesCRUD]="true"
			[barraBotones3]="true" [barraBotones5]="true" [botonBuscar]="btnBuscar" [botonBinocular]="true"
			[excel]="true"
			[kardex]="true" (signal)="manejarSenales($event)">
		</app-invmaebarra>
	</div>
</div>

<!-- <div class="divgrups">
	<div class="p-grid">
		<div class="p-col-1">
			<span>Bodega Articulos:</span>
		</div>
		<div class="p-col-1" style="text-align: left">
			<input id="BOD_VENTAS" type="text" pInputText autocomplete="off" maxlength="10" [(ngModel)]="txtCodBodega"
				[disabled]="desplegarCheck">
		</div>
		<div class="p-col-1">
			<button type="button" style="background-image: url(../../../assets/images/iconos/buscar.png);"
				class="littlebuttons" (click)="buscarBodegas(txtCodBodega)" [disabled]="desplegarCheck"></button>
		</div>
		<div class="p-col-1">
			<p-checkbox binary="true" (onChange)="cambio($event)">
			</p-checkbox>
		</div>
		<div class="p-col-6">

			<span>Desplegar existencias de todas las bodegas</span>
		</div>
	</div>
</div> -->
<div *ngIf="boolconbod === false" class="divgrups">
	<div class="p-grid">
		<div class="p-col-1">
			<span>Bodega Articulos:</span>
		</div>
		<div  class="p-col-1" style="text-align: left">
			<input id="BOD_VENTAS" type="text" pInputText autocomplete="off" maxlength="10" [(ngModel)]="txtCodBodega"
				[disabled]="desplegarCheck">
		</div>
		<div  class="p-col-1">
			<button type="button" style="background-image: url(../../../assets/images/iconos/buscar.png);"
				class="littlebuttons" (click)="buscarBodegas(txtCodBodega)" [disabled]="desplegarCheck"></button>
		</div>
		<div *ngIf="boolconbod === false" class="p-col-1">
			<p-checkbox binary="true" (onChange)="cambio($event)">
			</p-checkbox>
		</div>
		<div class="p-col-6">

			<span>Desplegar existencias de todas las bodegas</span>
		</div>
	</div>
</div>
<!-- CDPJ -->
<div *ngIf="boolconbod === true" class="divgrups">
	<div class="p-grid">
		<div class="p-col-1">
			<span>Bodega Articulos:</span>
		</div>
		<!-- CDPJ -->
		<div *ngIf="boolconbod === true" class="p-col-1" style="text-align: left">
			<input id="BOD_VENTAS" type="text" pInputText autocomplete="off" maxlength="10" [(ngModel)]="txtCodBodega"
				[disabled]="boolblockbod">
		</div>
		<div *ngIf="boolconbod === true" class="p-col-1">
			<button type="button" style="background-image: url(../../../assets/images/iconos/buscar.png);"
				class="littlebuttons" (click)="buscarBodegas(txtCodBodega)" [disabled]="boolblockbod"></button>
		</div>
		<div *ngIf="boolconbod === true" class="p-col-1">
			<p-checkbox binary="true" (onChange)="cambio($event)" [disabled]="boolblockbod">
			</p-checkbox>
		</div>
		<!-- CDPJ -->
		<div class="p-col-6">

			<span>Desplegar existencias de todas las bodegas</span>
		</div>
	</div>
</div>
<!-- CDPJ -->
<div style="overflow: hidden; margin-top: -20px;" class="p-col-12">
	<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true" [rowData]="invExistencias"
		[frameworkComponents]="frameworkComponents" [animateRows]="true" [rowSelection]="rowSelection"
		[columnDefs]="columnDefInvExistencias" [defaultColDef]="defaultColDefInvutilExtencias" [rowStyle]="rowStyle"
		[singleClickEdit]="false" [botones]="false" (selected)=selectArticulo($event) (cambios)="cambio($event)"
		*ngIf="arrayCompleto"></app-aggridsaci>
	<div class="divgrups" *ngIf="showBar">
		<div class="p-grid">
			<div class="p-col-12">
				<p-progressBar [value]="porcentaje" [showValue]="false"></p-progressBar>
			</div>
		</div>
	</div>
</div>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
		[consulta]="consulta" [busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)">
	</app-busquedadlg>
</p-dialog>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusqueda" [responsive]="true" [transitionOptions]="'0ms'"
	[modal]="true" appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedabdg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta"
		[consulta1]="consulta1" [consulta2]="consulta2" [tabla]="tabla" [where]="condicion"
		[busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccionArticulo($event)"
		[busquedaCompuesta]="false"></app-busquedabdg>
</p-dialog>

<p-dialog header="Kardex" [(visible)]="displayDialogKardex" [responsive]="true" [transitionOptions]="'0ms'"
	[modal]="true" appendTo="body" [style]="{width: '70vw', height: '70vh'} ">

	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-2">
				<span>Artículo:</span>
			</div>
			<div class="p-col-6">
				<span>{{ seletedInvExistencia === undefined ?  '' : seletedInvExistencia.ART_CODIGO  }}
					{{ seletedInvExistencia === undefined ?  '' : seletedInvExistencia.ART_NOMBRE  }}</span>
			</div>
		</div>
	</div>
	<app-aggridsaci [width]="" [height]="largokardex" [enableSorting]="true" [enableFilter]="true"
		[rowData]="trnkardexp" [frameworkComponents]="frameworkComponents" [animateRows]="true"
		[rowSelection]="rowSelection" [columnDefs]="columnDefsKardex" [defaultColDef]="defaultColDefKardex"
		[rowStyle]="rowStyle" [botones]="false" [singleClickEdit]="false">

	</app-aggridsaci>
</p-dialog>

<div style=" 	position: fixed;
				z-index: 999;
				height: 2em;
				width: 2em;
				overflow: visible;
				margin: auto;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;

				">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
</div>

<!-- <div style=" 	position: fixed;
				z-index: 999;
				height: 2em;
				width: 50em;
				overflow: visible;
				margin: auto;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;

				" >
	<p-progressBar [value]="porcentaje" *ngIf="showBar"></p-progressBar>
</div>-->