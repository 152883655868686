import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';


@Injectable({
  providedIn: 'root'
})
export class SegmaetiprefService {
  
  private way: string;
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
    this.usuario.identificacion;
  }

  consultaRef(where) {
    const parametro1 = 'A';
    
    let condicion =  ' com_codigo='+ '01' +' and  tip_Codigo is not null ';
    if (where != null) {
      condicion += ' AND ' + where;
    }
    const parametro3 = '';
    
    return this.http.post<any[]>(this.way + '/seg/tiporeferencia/662fcf57b2cedc0', {
      elementos: {
        p_TipRef_opcion:parametro1 , // varchar,    
        p_TipRef_filtro: condicion, //  varchar,  
        p_TipRef_condicion:  parametro3, //  varchar,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
   ingresarTipoRef(paquete:string, TIP_CODIGO:string, TIP_DESCRIPCION:string, TIP_EDITABLE:boolean){
    let valor:number;
    console.log(TIP_EDITABLE);
    if(TIP_EDITABLE==false){
      valor=0;
    }  else{
      valor=1;
    }

 
    return this.http.post<any[]>(this.way + '/seg/tiporeferencia/95e1b773b2cee00', {
        elementos: {
          p_tip_codigo:TIP_CODIGO,
          p_tip_descripcion:TIP_DESCRIPCION,
          p_tip_editable:valor,
          p_comcodigo:'01'
        },
      }, this.confIniciales.httpOptions()).toPromise();
   }

   actualizarTipoRef(paquete:string, TIP_CODIGO:string, TIP_DESCRIPCION:string, TIP_EDITABLE:boolean){
    let valor:number;
    if(TIP_EDITABLE==false){
      valor=0;
    }  else{
      valor=1;
    }
 
    
    return this.http.post<any[]>(this.way + '/seg/tiporeferencia/a677dd9671b74000', {
        elementos: {
          p_tip_codigo:TIP_CODIGO,
          p_tip_codigo1:TIP_CODIGO,
          p_tip_descripcion:TIP_DESCRIPCION,
          p_tip_editable:Number(valor),
          p_comcodigo:'01'
        },
      }, this.confIniciales.httpOptions()).toPromise(); 
   }
  

   
   eliminarTipoRef(paquete:string,tip_codigo:string){
    return this.http.post<any[]>(this.way + '/seg/tiporeferencia/7565695fb2cedc0', {
      elementos: {
        p_tip_codigo:tip_codigo,
        p_comcodigo:'01'
      },
    }, this.confIniciales.httpOptions()).toPromise(); 
 }
   
}
