import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import {Segencenviocorreo} from '../seginterfaces/segencenviocorreo';
import {Segencenviocorreovar} from '../seginterfaces/segencviocorvar';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import {SegencenviocorreoService} from '../segservicios/segencenviocorreo.service';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { UtilitariosService } from '../../advantage/advantageservices/utilitarios.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
@Component({
  selector: 'app-segencenviocorreo',
  templateUrl: './segencenviocorreo.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class SegencenviocorreoComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  encenviocorreo: Segencenviocorreo = {};
  enviocorreovar: Segencenviocorreovar[] = [];
  // aggrid
  rowSelection = 'multiple';
  public frameworkComponents;
//paneles
index: number;
//rowstyle
rowStyle;
//botonesinvmaebarra
btnnuevo;
btnguardar;
btneliminar;
//busquedas
opcionbusqueda:string;
types: SelectItem[];
arregloBus: any[];
busquedacampos: string[];
tabla;
where;
displayDialogBusquedaFast: boolean;
consulta:string;
//cheks
chkincluyelogo:boolean;
chkincluyefirma:boolean;
booleanslogo:boolean;
//
comlogo='';
//
vistaprevia='';
// TIPO DE REPORTES
cmbtipodereportes: any[];
selectcombtiporeporte: any;
boolreporte:boolean=true;

defaultColEnvcioCorreoVar;
columnDefsEncioCorreoVar = [
  {
    headerName: 'Variables', field: 'ENVIOCORVAR_VARIABLE', width: 140,  editable: (params) => {
      return false;
    }
  },
  {
    headerName: 'Descripcion', field: 'ENVIOCORVAR_DESCRIPCION', width: 240, editable: (params) => {
      return false;
    }
  },
  {
    headerName: 'Valor', field: 'ENVIOCORVAR_VALOR', width: 240, editable: (params) => {
      return false;
    }
  },
  
];
  constructor(private confIniciales: ConfInicialesService,private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private init: NuevoComponentService, private segencenviocorreoService:SegencenviocorreoService,
    private busqService: InvbusquedaService,private utilitariosService:UtilitariosService, private auditoriaGralService: AuditoriagralService,
     ) {
    this.agTable();
   }

  async ngOnInit() {
    this.btnnuevo=false;
    this.btnguardar=true;
    this.btneliminar=false;
    this.getlogotipo();
    this.limpiarvalores();
    this.llenarcombo();
    setTimeout(() => {
      document.getElementById('contorno').style.pointerEvents = 'none';
    }, 1000);
  }
  ngOnDestroy(): void {
    this.confIniciales.instanciasCorreos--;
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
  this.rowStyle = (params) => {
    if (params.node.rowIndex % 2 !== 0) {
      return { background: '#D6EAF8' };
    }
  };
  this.defaultColEnvcioCorreoVar = {
    editable: true,
    width: 50,
    objeto: 'envioCorreoVar',
    resizable: true
  };
};
  limpiarvalores(){
    this.nuevoformcorreo();
  }
  llenarcombo(){
    this.cmbtipodereportes = [{ codigo: '', name: '' },
    { codigo: '01', name: 'Reportes específicos de pantalla' },
    { codigo: '02', name: 'Listados y Reportes' }
    ];
  }
  busqtiporegimen(select) {
    this.botonesmodificar();
    this.encenviocorreo.ENCENVIOCOR_TIPOREPORTE = select.value.codigo;
    this.encenviocorreo.ENCENVIOCOR_REPCODIGO='';
    this.encenviocorreo.ENCENVIOCOR_REPNOMBRE='';    
    if(select.value.codigo === ''){
      this.boolreporte=true;
    }else{
      this.boolreporte=false;
    }
    
  }
  nuevoformcorreo(){
    this.enviocorreovar = [];
    this.encenviocorreo={
      ENCENVIOCOR_CODIGO:'',
      ENCENVIOCOR_NOMBRE:'',
      ENCENVIOCOR_DESCRIPCION:'',
      ENCENVIOCOR_ASUNTO:'',
      ENCENVIOCOR_REMITENTE:'',
      ENCENVIOCOR_CUERPO1:'',
      ENCENVIOCOR_CUERPO2:'',
      ENCENVIOCOR_PIECORREO:'',
      ENCENVIOCOR_REPCODIGO:'',
      ENCENVIOCOR_REPNOMBRE:'',
      ENVIOCORVAR_CODIGO:'',
      ENVIOCORVAR_NOMBRE:'',
      ENCENVIOCOR_FIRMA:'',
      ENCENVIOCOR_LOGO:'',
      ENCENVIOCOR_TIPOREPORTE:'',
      ENCENVIOCOR_NOMARCHIVO:''
    }
    this.chkincluyelogo=false;
    this.chkincluyefirma=false;
    this.vistaprevia='';
    this.selectcombtiporeporte={codigo:'',nombre:''}
  }
  
  async manejarSenales(valor) {
    if(valor === 'Nuevo'){
      this.nuevoformcorreo();
      this.botonesmodificar();
      document.getElementById('contorno').style.pointerEvents = 'all';
      document.getElementById('ENCENVIOCOR_NOMBRE').focus();
    }
    if (valor === 'Cancelar') {
      this.cancelar();
    }
    if (valor === 'Guardar') {
      this.validaciones();
    }
    if(valor === 'Buscar'){
      this.busquedadormulariocorreo();
    }
    if(valor === 'Borrar'){
      this.eliminarFormulariocorreos();
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  botonesmodificar(){
    this.btnnuevo=true;
    this.btnguardar=false;
    this.btneliminar=true;
    this.vistaprevia='';
    this.booleanslogo=false;
  }
  busquedadormulariocorreo() {
    this.opcionbusqueda = 'FORMUALARIOCO';
    this.types = [
      { label: 'CÓDIGO', value: 'ENCENVIOCOR_CODIGO' },
      { label: 'NOMBRE', value: 'ENCENVIOCOR_NOMBRE' },
    ];
    this.consulta='';
    this.busquedacampos = ['', ''];
    this.tabla = 'SEG_ENCENVIOCOR';
    this.where = '';

    this.busqService.busquedaSaciformCorreos().subscribe((datos: any[]) => {
      this.arregloBus = datos;
      this.displayDialogBusquedaFast = true;
    });
  }
  async busquedaformulario(parametro){
    if (parametro === null) {
      parametro = '';
    }
    this.opcionbusqueda = 'BUSFORMULARIO';
    this.types = [
      { label: 'Código', value: 'ENVIOCORVAR_CODIGO' },
      { label: 'Nombre', value: 'ENVIOCORVAR_NOMBRE' },
      
    ];
    this.consulta='distinct ENVIOCORVAR_CODIGO,ENVIOCORVAR_NOMBRE';
    this.busquedacampos = [parametro, '', ''];
    this.tabla = 'SEG_ENVIOCORVAR';
    this.where = '';

    if (parametro !== '' && parametro !== undefined && parametro !== null) {
      const eR = await this.segencenviocorreoService.consultarFormulario(this.tabla, this.types[0].value, parametro);
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
          return;
        }else{
          this.busqService.busquedaSaciFormulario(parametro).subscribe((datos: any[]) => {
            this.arregloBus = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      }else{
        this.busqService.busquedaSaciFormulario(parametro).subscribe((datos: any[]) => {
          this.arregloBus = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    }else{
      this.busqService.busquedaSaciFormulario1().subscribe((datos: any[]) => {
        this.arregloBus = datos;
        this.displayDialogBusquedaFast = true;
      });
    }
  }
  busquedasreporte(parametro){
    if(this.encenviocorreo.ENCENVIOCOR_TIPOREPORTE === '02'){
      this.busquedareporte(parametro);
    }else if(this.encenviocorreo.ENCENVIOCOR_TIPOREPORTE === '01') {
      this.busquedareportepantalla(parametro);
    }
  }
  async busquedareportepantalla(parametro){
 
    if (parametro === null) {
      parametro = '';
    }
    this.opcionbusqueda = 'BUSREPORTEPANTALLA';
    this.types = [
      { label: 'Código', value: 'CNFGIMPR_CODIGO' },
      { label: 'Nombre', value: 'CNFGIMPR_NOMBRE' },
    ];
    this.consulta='';
    this.busquedacampos = [parametro, '', ''];
    this.tabla = 'SACI_MAECFNGIMPR';
    this.where = '';

    if (parametro !== '' && parametro !== undefined && parametro !== null) {
      const eR = await this.segencenviocorreoService.consultarReporte(this.tabla, this.types[0].value, parametro);
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
          return;
        }else{
          this.busqService.busquedaSaciMaeCFNGIMPRCo(parametro).subscribe((datos: any[]) => {
            this.arregloBus = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      }else{
        this.busqService.busquedaSaciMaeCFNGIMPRCo(parametro).subscribe((datos: any[]) => {
          this.arregloBus = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    }else{
      this.busqService.busquedaSaciMaeCFNGIMPR().subscribe((datos: any[]) => {
        this.arregloBus = datos;
        this.displayDialogBusquedaFast = true;
      });
    }

}
  async busquedareporte(parametro){
 
      if (parametro === null) {
        parametro = '';
      }
      this.opcionbusqueda = 'BUSREPORTE';
      this.types = [
        { label: 'Código', value: 'RL_CODIGO' },
        { label: 'Nombre', value: 'RL_NOMBRE' },
      ];
      this.consulta='';
      this.busquedacampos = [parametro, '', ''];
      this.tabla = 'SACI_MAERL';
      this.where = '';
  
      if (parametro !== '' && parametro !== undefined && parametro !== null) {
        const eR = await this.segencenviocorreoService.consultarReporte(this.tabla, this.types[0].value, parametro);
        if (eR !== null) {
          if (eR[0] !== undefined) {
            this.manejarSeleccion(eR[0]);
            return;
          }else{
            this.busqService.busquedaSaciMaeRlCo(parametro).subscribe((datos: any[]) => {
              this.arregloBus = datos;
              this.displayDialogBusquedaFast = true;
            });
          }
        }else{
          this.busqService.busquedaSaciMaeRlCo(parametro).subscribe((datos: any[]) => {
            this.arregloBus = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      }else{
        this.busqService.busquedaSaciMaeRl1().subscribe((datos: any[]) => {
          this.arregloBus = datos;
          this.displayDialogBusquedaFast = true;
        });
      }

  }
  manejarSeleccion(opcion){
    if (this.opcionbusqueda === 'FORMUALARIOCO') {
      this.encontrarformulario(opcion.ENCENVIOCOR_CODIGO);
    }
    if(this.opcionbusqueda === 'BUSREPORTE'){
      this.encenviocorreo.ENCENVIOCOR_REPCODIGO=opcion.RL_CODIGO;
      this.encenviocorreo.ENCENVIOCOR_REPNOMBRE=opcion.RL_NOMBRE;
      document.getElementById('ENCENVIOCOR_DESCRIPCION').focus();
    }
    if(this.opcionbusqueda === 'BUSREPORTEPANTALLA'){
      this.encenviocorreo.ENCENVIOCOR_REPCODIGO=opcion.CNFGIMPR_CODIGO;
      this.encenviocorreo.ENCENVIOCOR_REPNOMBRE=opcion.CNFGIMPR_NOMBRE;
      document.getElementById('ENCENVIOCOR_DESCRIPCION').focus();
    }
    if(this.opcionbusqueda === 'BUSFORMULARIO'){
      this.encenviocorreo.ENVIOCORVAR_CODIGO=opcion.ENVIOCORVAR_CODIGO;
      this.encenviocorreo.ENVIOCORVAR_NOMBRE=opcion.ENVIOCORVAR_NOMBRE;
      document.getElementById('ENCENVIOCOR_REPCODIGO').focus();
      this.cargarvariables();
    }
    this.displayDialogBusquedaFast = false;

  }
  setFocus(event) {
    let element: any;
    switch (event.target.id) {
      case 'ENCENVIOCOR_NOMBRE': {
        //this.busquedacliente(event.target.value);
        element = document.getElementById('ENVIOCORVAR_CODIGO');
        break;
      }
      case 'ENVIOCORVAR_CODIGO': {
        this.busquedaformulario(event.target.value);
        break;
      }
      case 'ENCENVIOCOR_REPCODIGO': {       
       this.busquedasreporte(event.target.value);
        break;
      }
      case 'ENCENVIOCOR_DESCRIPCION': {
        //this.nuevodetalle();
        element = document.getElementById('ENCENVIOCOR_ASUNTO');
        break;
      }
      case 'ENCENVIOCOR_ASUNTO': {
        //this.calcularFechaVencimiento();
        element = document.getElementById('ENCENVIOCOR_REMITENTE');
        break;
      }///GSRF
      case 'ENCENVIOCOR_REMITENTE': {
        element = document.getElementById('ENCENVIOCOR_CUERPO1');
        break;
      }
      default: {
        break;
      }
    }
    if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }

  }
  changeTabs(){
  }
  async cancelar(){
    if (this.encenviocorreo.ENCENVIOCOR_CODIGO === ''){
      this.limpiarvalores();
      this.botonesiniciales();
    }else if(this.encenviocorreo.ENCENVIOCOR_CODIGO !== ''){
      await this.encontrarformulario(this.encenviocorreo.ENCENVIOCOR_CODIGO)
    }
  }
  botonesiniciales(){
    this.btnnuevo=false;
    this.btnguardar=true;
    this.btneliminar=false;
  }
  async cargarvariables(){
    try {
      const data = await this.segencenviocorreoService.consultarVariables(this.encenviocorreo.ENVIOCORVAR_CODIGO);
      this.enviocorreovar = data 
    } catch (error) {
      this.messageService.add({
        key: 'segencviocorreo',
        severity: 'error',
        summary: 'Error',
        detail: 'Ocurrió un error al cargar las variables disponibles para el formulario'
      });
      console.log(error);
    }
  }
  async validaciones(){
    let countrep=0;
    let countform=0;
    try {
    const data1 = await this.segencenviocorreoService.consulExisreporte(this.encenviocorreo.ENCENVIOCOR_REPCODIGO,this.encenviocorreo.ENCENVIOCOR_TIPOREPORTE);
    const data2 = await this.segencenviocorreoService.consulExisformulario(this.encenviocorreo.ENVIOCORVAR_CODIGO);
    if (data1 !== null) {
      if (data1[0] !== undefined) {
        countrep = data1[0].NUMERO;
      }
    }
    if (data2 !== null) {
      if (data2[0] !== undefined) {
        countform = data2[0].NUMERO;
      }
    }
    let error=0
    if (this.encenviocorreo.ENCENVIOCOR_NOMBRE === ''){
      this.messageService.add({
        key: 'segencviocorreo',
        severity: 'error',
        summary: 'Error',
        detail: 'El campo Nombre está vacío.'
      });
      error++;
    }
    if (this.encenviocorreo.ENVIOCORVAR_CODIGO === ''){
      this.messageService.add({
        key: 'segencviocorreo',
        severity: 'error',
        summary: 'Error',
        detail: 'El campo Formulario está vacío.'
      });
      error++;
    }
    if (this.encenviocorreo.ENCENVIOCOR_REPCODIGO === ''){
      this.messageService.add({
        key: 'segencviocorreo',
        severity: 'error',
        summary: 'Error',
        detail: 'El campo Reporte está vacío.'
      });
      error++;
    }
    if (this.encenviocorreo.ENCENVIOCOR_DESCRIPCION === ''){
      this.messageService.add({
        key: 'segencviocorreo',
        severity: 'error',
        summary: 'Error',
        detail: 'El campo Descripción está vacío.'
      });
      error++;
    }
    if (this.encenviocorreo.ENCENVIOCOR_ASUNTO === ''){
      this.messageService.add({
        key: 'segencviocorreo',
        severity: 'error',
        summary: 'Error',
        detail: 'El campo Asunto está vacío.'
      });
      error++;
    }
    if (this.encenviocorreo.ENCENVIOCOR_REMITENTE === ''){
      this.messageService.add({
        key: 'segencviocorreo',
        severity: 'error',
        summary: 'Error',
        detail: 'El campo Remitente está vacío.'
      });
      error++;
    }
    if (this.encenviocorreo.ENCENVIOCOR_CUERPO1 === '' && this.encenviocorreo.ENCENVIOCOR_CUERPO2 === ''){
      this.messageService.add({
        key: 'segencviocorreo',
        severity: 'error',
        summary: 'Error',
        detail: 'Ingrese información en almenos uno de los campos Cuerpo01 o Cuerpo02.'
      });
      error++;
    }
    if(countrep === 0){
        this.messageService.add({
          key: 'segencviocorreo',
          severity: 'error',
          summary: 'Error',
          detail: 'El reporte seleccionado no existe.'
        });
        error++;
    }
      if(countform === 0){
          this.messageService.add({
            key: 'segencviocorreo',
            severity: 'error',
            summary: 'Error',
            detail: 'El formulario seleccionado no existe.'
          });
          error++;
      }

      if(error === 0){
        this.guardar();
      }
    } catch (error) {
      this.messageService.add({
        key: 'segencviocorreo',
        severity: 'error',
        summary: 'Error',
        detail: 'Ocurrió un error en la validación de los datos.'
      });
      console.log(error);
    }
  }
  getlogotipo() {
    this.utilitariosService.getLogotipo().subscribe(data => {
      for (const rs of data) {
        this.comlogo=rs.COM_LOGO;
      }      
    });
  }
  async guardar(){
    try {
      let encf: any;
      await this.cargarchecks();
      this.encenviocorreo.ENCENVIOCOR_TIPOREPORTE=this.selectcombtiporeporte.codigo;
      if (this.encenviocorreo.ENCENVIOCOR_CODIGO === ''){
      encf=await this.segencenviocorreoService.insertarFormulario(this.encenviocorreo);
      for (const encfr of encf) {
        this.encenviocorreo.ENCENVIOCOR_CODIGO = encfr[':B1'];
      }
      
      this.botonesiniciales();
      this.cancelar();

      this.messageService.add({
        key: 'segencviocorreo',
        severity: 'success',
        summary: 'Formulario guardado',
        detail: 'El formulario se guardó con éxito.'
      });
      this.auditoriaGralService.registrarAuditoria('SEG_MAEENCENVIOCOR', this.encenviocorreo.ENCENVIOCOR_CODIGO + '/' + this.encenviocorreo.ENCENVIOCOR_NOMBRE, 'I',
      this.segencenviocorreoService.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
    }else{
        encf=await this.segencenviocorreoService.insertarFormulario(this.encenviocorreo);
        for (const encfr of encf) {
          this.encenviocorreo.ENCENVIOCOR_CODIGO = encfr[':B1'];
        }
        
        this.botonesiniciales();
        this.cancelar();

        this.messageService.add({
          key: 'segencviocorreo',
          severity: 'success',
          summary: 'Formulario guardado',
          detail: 'El formulario se actualizó con éxito.'
        });
        this.auditoriaGralService.registrarAuditoria('SEG_MAEENCENVIOCOR', this.encenviocorreo.ENCENVIOCOR_CODIGO + '/' + this.encenviocorreo.ENCENVIOCOR_NOMBRE, 'A',
        this.segencenviocorreoService.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
      }
    } catch (error) {
      this.messageService.add({
        key: 'segencviocorreo',
        severity: 'error',
        summary: 'Error',
        detail: 'Ocurrió un error al guardar el formulario'
      });
      console.log(error);
    }
  }
  async cargarchecks(){
    if(this.chkincluyelogo === true){
      this.encenviocorreo.ENCENVIOCOR_LOGO=1
    }else{
      this.encenviocorreo.ENCENVIOCOR_LOGO=0
    }
    if(this.chkincluyefirma=== true){
      this.encenviocorreo.ENCENVIOCOR_FIRMA=1
    }else{
      this.encenviocorreo.ENCENVIOCOR_FIRMA=0
    }
  }
 async  encontrarformulario(ENCENVIOCOR_CODIGO){
   try {
    const data = await this.segencenviocorreoService.consultformulariocorreo(ENCENVIOCOR_CODIGO);
    if(data !== null){
      if(data[0]!== undefined){
        this.encenviocorreo=data[0];
        document.getElementById('contorno').style.pointerEvents = 'all';
        if(data[0].ENCENVIOCOR_FIRMA === 1){
          this.chkincluyefirma=true
        }else {
          this.chkincluyefirma=false
        }
        if(data[0].ENCENVIOCOR_LOGO === 1){
          this.chkincluyelogo=true
        }else {
          this.chkincluyelogo=false
        }
        const data1 = await this.segencenviocorreoService.consultnomformulario(this.encenviocorreo.ENVIOCORVAR_CODIGO);
        const data2 = await this.segencenviocorreoService.consultnomreporte(this.encenviocorreo.ENCENVIOCOR_REPCODIGO,this.encenviocorreo.ENCENVIOCOR_TIPOREPORTE);
        if(data1[0] !== null && data1[0] !== undefined){
          this.encenviocorreo.ENVIOCORVAR_NOMBRE=data1[0].ENVIOCORVAR_NOMBRE
        }else{
          this.encenviocorreo.ENVIOCORVAR_NOMBRE='';
        }
        if(data2[0] !== null && data2[0] !== undefined){
          this.encenviocorreo.ENCENVIOCOR_REPNOMBRE=data2[0].ENCENVIOCOR_REPNOMBRE
        }else{
          this.encenviocorreo.ENCENVIOCOR_REPNOMBRE='';
        }
        if (this.encenviocorreo.ENCENVIOCOR_TIPOREPORTE === null) {
          this.encenviocorreo.ENCENVIOCOR_TIPOREPORTE = '';
        }
        if (this.encenviocorreo.ENCENVIOCOR_NOMARCHIVO === null) {
          this.encenviocorreo.ENCENVIOCOR_NOMARCHIVO = '';
        }
        for (const j of this.cmbtipodereportes) {
          if (this.encenviocorreo.ENCENVIOCOR_TIPOREPORTE === j.codigo) {
            this.selectcombtiporeporte = { codigo: j.codigo, name: j.name };
          }
        }
        if(this.selectcombtiporeporte.codigo ===''){
          this.boolreporte=true;
        }else{
          this.boolreporte=false;
        }
        await this.cargarvariables();
        await this.generarvistaprevia();
      }
    }
   } catch (error) {
    this.messageService.add({
      key: 'segencviocorreo',
      severity: 'error',
      summary: 'Error',
      detail: 'Ocurrió un error al cargar los datos del formulario'
    });
    console.log(error);
   }
  }
  eliminarFormulariocorreos(){
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el formulario ' + this.encenviocorreo.ENCENVIOCOR_CODIGO,
      header: 'Eliminar Formulario',
      icon: 'pi pi-exclamation-triangle',
      key: 'segENCENVIOCOR',
      accept: async () => {
        try {
          let corcodigo=this.encenviocorreo.ENCENVIOCOR_CODIGO;
          let cornombre=this.encenviocorreo.ENCENVIOCOR_NOMBRE;
          await this.segencenviocorreoService.eliminarformulariocorreo(this.encenviocorreo.ENCENVIOCOR_CODIGO);
          this.encenviocorreo.ENCENVIOCOR_CODIGO='';
          await this.cancelar();
          this.messageService.add({
            key: 'segencviocorreo',
            severity: 'success',
            summary: 'Formulario eliminado',
            detail: 'El formulario se eliminó con éxito.'
          });
          this.auditoriaGralService.registrarAuditoria('SEG_MAEENCENVIOCOR', corcodigo + '/' + cornombre, 'E',
          this.segencenviocorreoService.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });

        } catch (error) {
          this.messageService.add({
            key: 'segencviocorreo',
            severity: 'error',
            summary: 'Error',
            detail: 'Ocurrió un error al eliminar el formulario'
          });
        }
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }
  async generarvistaprevia(){
    try {
      let cuerpo01='';
      let cuerpo02='';
      let piecoreo='';
      this.vistaprevia='';
      const data = await this.segencenviocorreoService.consultvistaprevia(this.encenviocorreo.ENCENVIOCOR_CODIGO,this.encenviocorreo.ENVIOCORVAR_CODIGO);
      console.log(data);
      if(this.chkincluyelogo === true){
        this.booleanslogo=true;
        for(let i=0;i<12;i++){
          this.vistaprevia=this.vistaprevia+'\n';
        } 
        cuerpo01=data[0].CUERPO01 === null || data[0].CUERPO01 === undefined?'':data[0].CUERPO01;
        cuerpo02=data[0].CUERPO02 === null || data[0].CUERPO02 === undefined?'':data[0].CUERPO02;
        piecoreo=data[0].PIECORREO === null || data[0].PIECORREO === undefined?'':data[0].PIECORREO;
        this.vistaprevia=this.vistaprevia+cuerpo01+ '\n'+cuerpo02+ '\n\n'+piecoreo;   
      }else{
        cuerpo01=data[0].CUERPO01 === null || data[0].CUERPO01 === undefined?'':data[0].CUERPO01;
        cuerpo02=data[0].CUERPO02 === null || data[0].CUERPO02 === undefined?'':data[0].CUERPO02;
        piecoreo=data[0].PIECORREO === null || data[0].PIECORREO === undefined?'':data[0].PIECORREO;
        this.booleanslogo=false;
        this.vistaprevia=cuerpo01+ '\n'+cuerpo02+ '\n\n'+piecoreo; 
        console.log(this.vistaprevia);
      }
      
      
    } catch (error) {
      console.log(error)
    }
    
  }

}
