<div class="exception-body accessdenied">
    <div class="exception-panel">
            <div class="exception-content">
            <img src="assets/layout/images/pages/icon-access.svg">
                <h1>Access Denied</h1>
                <p>You do not have the necessary permissons.</p>
                <button [routerLink]="['/']" type="button" class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only">
                    <span class="ui-button-text ui-c">Go to Dashboard</span>
                </button>
            </div>
    </div>
</div>