import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ComTrnformapago} from '../cominterfaces/comtrnformapago';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { ComEncfacpro } from '../cominterfaces/comencfacpro';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DatosanexosService {
  
  public way: string;
  
  public formapago: ComTrnformapago[];
  public hmpConfiguracion: any[];
  public strTipoIva: string;
  
  private _auxsalto: boolean;
  
  public grids: any[] = [];
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService,
              private datePipe: DatePipe
  ) {
    this.way= sessionStorage.getItem('way');
  }
  
  insertarTrnFormaPago(item): Observable<any> {
    return this.http.post<any>(this.way + '/compras/datosanexos/95f9beeeefcaa00', {
      elementos: {
        p_encfacpro_numero: item.ENCFACPRO_NUMERO,
        p_com_codigo: '01',
        p_trnformapago_codigo: item.TRNFORMAPAGO_CODIGO,
        p_trnformapago_descripcion: item.TRNFORMAPAGO_DESCRIPCION
      }
    }, this.confIniciales.httpOptions());
  }
  
  eliminarFactura(strNumFact): Observable<any> {
    return this.http.post<any>(this.way + '/compras/datosanexos/7565695d5b7a980', {
      elementos: {
        p_ENCFAC_numero_1: strNumFact,
        p_COM_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  
  getEncfacpro(encfacnumero): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/datosanexos/83baebb1ea66780', {
        elementos: {
          ENCFACPRO_NUMERO: encfacnumero
        }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  //CDPJ
  getEncCxp2(encfacnumero): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/datosanexos/41dd75d8c', {
        elementos: {
          ENCFACPRO_NUMEROcxp: encfacnumero
        }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getAnexospro(procodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/datosanexos/20eeaaee', {
        elementos: {
          p_procodigo: procodigo
        }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  ejecutarConsultaFpPro(fpprocodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/datosanexos/3a6e67baadb31800000000000000000000', {
      elementos: {
        p_fpprocodigo: fpprocodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  insertarTrnFormaPagocxp(item): Observable<any> {
    return this.http.post<any>(this.way + '/compras/datosanexos/12bf1dbeab7dde0000000000000', {
      elementos: {
        p_trncobro_nrodoc: item.ENCFACPRO_NUMERO,
        p_com_codigo: '01',
        p_trnformapago_codigo: item.TRNFORMAPAGO_CODIGO,
        p_trnformapago_descripcion: item.TRNFORMAPAGO_DESCRIPCION
      }
    }, this.confIniciales.httpOptions());
  }
  //CDPJ
  encontrarCodSriCodigo(strCondicion) {
    return this.http.post(this.way + '/compras/datosanexos/3b6cc379b9330c', {
      elementos: {
        condicion: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarCodSriDescPromise(strCondicion) {
    return this.http.post(this.way + '/compras/datosanexos/76d986f3726bb80', {
      elementos: {
        condicion: strCondicion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  ejecutarConsulta1(strTipo, strTexto): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/datosanexos/3a6e662fcf57a0', {
        elementos: {
          codsritipo: strTipo,
          texto: strTexto
        },
    }, this.confIniciales.httpOptions());
  }

  ejecutarConsulta2(item: ComEncfacpro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/datosanexos/3a6e662fcf57a2', {
        elementos: {
          ENCFACPRO_PAGOLOCALEXT: item.ENCFACPRO_PAGOLOCALEXT,
          ENCFACPRO_PAISPAGO: item.ENCFACPRO_PAISPAGO,
          ENCFACPRO_CONVDOBLETRIB: item.ENCFACPRO_CONVDOBLETRIB,
          ENCFACPRO_PAGOEXTERIOR: item.ENCFACPRO_PAGOEXTERIOR,
          ENCFACPRO_RETSERIEEST: item.ENCFACPRO_RETSERIEEST,
          ENCFACPRO_RETSERIEPTOEMI: item.ENCFACPRO_RETSERIEPTOEMI,
          ENCFACPRO_RETSECUENCIAL: item.ENCFACPRO_RETSECUENCIAL,
          ENCFACPRO_RETAUTORIZACION: item. ENCFACPRO_RETAUTORIZACION,
          ENCFACPRO_PAGOREGFISCAL: item.ENCFACPRO_PAGOREGFISCAL,
          ENCFACPRO_RETFECHA: item.ENCFACPRO_RETFECHA !== null ? this.datePipe.transform(item.ENCFACPRO_RETFECHA, 'dd/MM/yyyy') : null,
          ENCFACPRO_TIPOREGFISCAL: item.ENCFACPRO_TIPOREGFISCAL,
          ENCFACPRO_CODPAISREGGEN: item.ENCFACPRO_CODPAISREGGEN,
          ENCFACPRO_CODPAISPARFIS: item.ENCFACPRO_CODPAISPARFIS,
          ENCFACPRO_DENOMINACION: item.ENCFACPRO_DENOMINACION,
          ENCFACPRO_NUMERO: item.ENCFACPRO_NUMERO
        },
    }, this.confIniciales.httpOptions());
  }
//CDPJ
ejecutarConsultacxp2(item: ComEncfacpro): Observable<any[]> {
  return this.http.post<any[]>(this.way + '/compras/datosanexos/e9b99eeab6cc600000000', {
      elementos: {
        TRNCOBRO_PAGOLOCALEXT: item.ENCFACPRO_PAGOLOCALEXT,
        TRNCOBRO_PAISPAGO: item.ENCFACPRO_PAISPAGO,
        TRNCOBRO_CONVDOBLETRIB: item.ENCFACPRO_CONVDOBLETRIB,
        TRNCOBRO_PAGOEXTERIOR: item.ENCFACPRO_PAGOEXTERIOR,
        TRNCOBRO_RETSERIEEST: item.ENCFACPRO_RETSERIEEST,
        TRNCOBRO_RETSERIEPTOEMI: item.ENCFACPRO_RETSERIEPTOEMI,
        TRNCOBRO_RETSECUENCIAL: item.ENCFACPRO_RETSECUENCIAL,
        TRNCOBRO_RETAUTORIZACION: item. ENCFACPRO_RETAUTORIZACION,
        TRNCOBRO_PAGOREGFISCAL: item.ENCFACPRO_PAGOREGFISCAL,
        TRNCOBRO_RETFECHA: item.ENCFACPRO_RETFECHA !== null ? this.datePipe.transform(item.ENCFACPRO_RETFECHA, 'dd/MM/yyyy') : null,
        TRNCOBRO_TIPOREGFISCAL: item.ENCFACPRO_TIPOREGFISCAL,
        TRNCOBRO_CODPAISREGGEN: item.ENCFACPRO_CODPAISREGGEN,
        TRNCOBRO_CODPAISPARFIS: item.ENCFACPRO_CODPAISPARFIS,
        TRNCOBRO_DENOMINACION: item.ENCFACPRO_DENOMINACION,
        TRNCOBRO_NRODOC: item.ENCFACPRO_NUMERO
      },
  }, this.confIniciales.httpOptions());
}
ejecutarConsulta3cxp(strNumero): Observable<any[]> {
  return this.http.post<any[]>(this.way + '/compras/datosanexos/1d3733dd56d98c000000000', {
      elementos: {
        ENCFACPRO_NUMERO: strNumero
      },
  }, this.confIniciales.httpOptions());
}
//CDPJ
  ejecutarConsulta3(strNumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/datosanexos/3a6e662fcf57a4', {
        elementos: {
          ENCFACPRO_NUMERO: strNumero
        },
    }, this.confIniciales.httpOptions());
  }

  ejecutarConsultaPromise(strNumero): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/datosanexos/3a6e662fccef16', {
      elementos: {
        ENCFACPRO_NUMERO: strNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
  ejecutarConsultaPromisecxpfpago(strNumero): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/datosanexos/3317e7abd56df80000000000', {
      elementos: {
        ENCFACPRO_NUMERO: strNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  ejecutarConsultaPromisecxp(strNumero): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/datosanexos/74dccf755b66300000000000000000', {
      elementos: {
        ENCFACPRO_NUMERO: strNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  //CDPJ
}



