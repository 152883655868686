import { Component, OnInit } from '@angular/core';
import {EsquemasAnex} from '../anexinterfaces/anexgenerararchivos';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {SegMaeCompania} from '../../seg/seginterfaces/segmaecompania';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {Usuario} from '../../usuario';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {DatePipe} from '@angular/common';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {SRIXMLGenerator} from '../anexinterfaces/SRIXMLGenerator';
import {AnexgenerarxmlService} from '../anexservicios/anexgenerarxml.service';

@Component({
  selector: 'app-anexgenerarxml',
  templateUrl: './anexgenerarxml.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class AnexgenerarxmlComponent implements OnInit {
  
  cmbMicroempresas: any[];
  selectRegMicroemp: any;
  txtPerMes = '';
  lblPerMes = '';
  cmbAnio: any[];
  selectAnio: any;
  
  porcentaje = 0;
  
  detalleEsquema: EsquemasAnex[] = [];
  
  public frameworkComponents;
  rowStyle;
  defaultColEsquema;
  rowSelection = 'multiple';
  largo: string;
  
  displayDialogBus: boolean;
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  where: string;
  opcion: string;
  
  displayDialogEmpresas = false;
  query = '';
  
  detalleSeleccionado: EsquemasAnex = {};
  datosfe: SegMaeCompania;
  
  displayFactNoAuto = false;
  factuNoAuto: any[] = [];
  bolGenerate = false;
  
  intFECRECEMI = 0;
  strSerie1 = '';
  strSerie2 = '';
  strSerie3 = '';
  strSerie4 = '';
  intNCNOFAC = 0;
  
  
  btnRadio = '';
  
  glbstrcodigo = '';
  
  
  constructor(public generarxmlService: AnexgenerarxmlService, private permisosService: PermisosService, public usuario: Usuario,
              private message: MessageService, private busquedaSer: InvbusquedaService,
              private datePipe: DatePipe, private auditoriagral: AuditoriagralService,
              private init: NuevoComponentService,  private confirmationService: ConfirmationService,
              private errorService: ErroresBaseDatosService, public sriXmlGenerador: SRIXMLGenerator) {
  }
  
  
  async ngOnInit() {
    
    this.largo = '580';
    
    // Regimen de microempresas
    this.cmbMicroempresas = [];
    this.cmbMicroempresas.push({codigo: 0, label: ''}, {codigo: 1, label: 'SI'}, {codigo: 2, label: 'NO'});
    this.selectRegMicroemp = this.cmbMicroempresas[0];
    
    // Anio
    this.cmbAnio = [];
    const anio = new Date();
    let max = anio.getFullYear();
    max += 2;
    for (let i = max; i > max - 100; i--) {
      this.cmbAnio.push({anio: '' + i});
    }
    
    this.selectAnio = this.cmbAnio[2];
    
    this.generarxmlService.proceso = false;
    
    
  }
  
  async manejarSenales(valor) {
    if (valor === 'Visto') {
      this.bolGenerate = await this.generar();
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
  async generar() {
    
    this.generarxmlService.proceso = true;
    const op = this.btnRadio === 'AnexTrans' ? this.sriXmlGenerador.ANEXO_TRANSACCIONAL : -1;
    
    if (op === -1) {
      this.mensajeAlerta('error', 'Error', 'Por favor seleccione el tipo de documento a generar');
      this.generarxmlService.proceso = false;
      return false;
    }
    
    let periodo = String(this.txtPerMes).trim();
    
    const rs = await this.generarxmlService.obtenerCodSri(periodo);
    
    periodo = rs === null ? '' : rs[0].CODSRI_CODIGO;
    if (periodo === '') {
      this.mensajeAlerta('error', 'Error', 'El periodo informado no existe, por favor seleccione un periodo valido');
      document.getElementById('PER_INFORMADO').focus();
      this.generarxmlService.proceso = false;
      return false;
    }
    
    const anio = Number(this.selectAnio.anio);
    const strSoloFacturasFisicas = '0';
    
    switch (op) {
      case this.sriXmlGenerador.ANEXO_TRANSACCIONAL:
        this.sriXmlGenerador.SRIXMLGenerator('', op, this.txtPerMes, anio, this, strSoloFacturasFisicas, this.intNCNOFAC, this.selectRegMicroemp.label);
        break;
      
    }
    
    
  }
  
  
  
  leerFechaFinal(intAnio, strMes, strMicroempresas, periodo) {
    let fechaFin = '';
    if (strMicroempresas === 'SI') {
      if (strMes === '01' || strMes === '02' || strMes === '03' || strMes === '04' || strMes === '05'
        || strMes === '06') {
        fechaFin = this.getNexDate('06', intAnio);
      } else {
        fechaFin = this.getNexDate('12', intAnio);
      }
    } else {
      fechaFin = this.getNexDate(periodo, intAnio);
    }
    
    return fechaFin;
  }
  
  getNexDate(periodo, anio) {
    const fecha = new Date(anio, Number(periodo), 0);
    const strFecha = this.datePipe.transform(fecha, 'dd/MM/yyyy');
    return strFecha;
  }
  
  
  
  
  
  
  busquedaSimple(tipe) {
    if (tipe === 1) {
      if (this.detalleEsquema.length > 0) {
        this.mensajeAlerta('info', 'Información', 'No esta permitido ingresar mas de un esquema');
        return;
      }
      
      this.query = 'SELECT EMPRESA_CODIGO, EMPRESA_ESQUEMA, EMPRESA_NOMBRE FROM SACIJAVA.SACI_MAEEMPRESAS '
        + this.getCondition()
        + ' ORDER BY EMPRESA_ESQUEMA';
      
      this.displayDialogEmpresas = true;
      
    } else if (tipe === 2) {
      
      this.txtPerMes = '';
      this.lblPerMes = '';
      
      const strMes = this.cadenaMeses(this.selectRegMicroemp.label);
      
      this.opcion = 'BuscarPeriodo';
      this.types = [
        {label: 'CÓDIGO', value: 'CODSRI_CODIGO'},
        {label: 'DESCRIPCIÓN', value: 'CODSRI_DESCRIPCION'},
        {label: 'TIPO', value: 'CODSRI_TIPO'},
        {label: 'AÑO', value: 'CODSRI_ANIO'},
        {label: 'REF', value: 'CODSRI_REF'},
        {label: 'CODIGOCON', value: 'CON_CODIGO'},
      ];
      this.busquedacampos = ['', '', '', '', '', ''];
      this.tabla = ' ANEX_MAECODSRI';
      this.where = ' CODSRI_TIPO=\'PI\' AND CODSRI_CODIGO IN ' + strMes;
      this.consulta = ' CODSRI_CODIGO, CODSRI_DESCRIPCION, CODSRI_TIPO, CODSRI_ANIO, CODSRI_REF, CON_CODIGO ';
      this.busquedaSer.busquedaAnexCodSri(strMes).subscribe((res: any[]) => {
        this.arregloCons = res;
        this.displayDialogBusquedaFast = true;
      });
    }
  }
  manejarSeleccion(valor) {
    if (this.opcion === 'BuscarPeriodo') {
      this.txtPerMes = valor.CODSRI_CODIGO;
      this.lblPerMes = valor.CODSRI_DESCRIPCION;
      
    }
    this.displayDialogBusquedaFast = false;
  }
  
  
  cadenaMeses(strMicroempresas) {
    let strCadena = '';
    if (strMicroempresas === 'SI') {
      strCadena = '(\'06\',\'12\')';
    } else {
      strCadena = '(\'01\',\'02\',\'03\',\'04\',\'05\',\'06\',\'07\',\'08\',\'09\',\'10\',\'11\',\'12\')';
    }
    return strCadena;
  }
  
  getCondition(): string {
    if (this.detalleEsquema.length <= 0 ) {
      return '';
    }
    
    let condicion = ' WHERE EMPRESA_CODIGO NOT IN(';
    for (const e of this.detalleEsquema) {
      condicion += '' + e.ESQUEMA_CODIGO + ',';
    }
    
    if (condicion.endsWith(',')) {
      condicion = condicion.substring(0, condicion.length - 1);
    }
    
    condicion += ')';
    return condicion;
  }
  
  cambioRegimen() {
    this.txtPerMes = '';
    this.lblPerMes = '';
  }
  
  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'anexgenxml',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
  
  
  
  
  
}
