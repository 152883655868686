import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { MessageService } from 'primeng';
import { ActMaePoliza } from '../actinterfaces/actmaepoliza';

@Injectable({
  providedIn: 'root'
})
export class ActmaepolizaService {
  private way: string;

  public btnNuevo:boolean[] = [];
  public btnGuardar:boolean[] = [];
  public btnBorrar:boolean[] = [];
  public btnBuscar:boolean[] = [];

  public detmaePolizaArray: ActMaePoliza[][] = [];

  constructor(private http: HttpClient, 
    private confIniciales: ConfInicialesService, 
    private init: NuevoComponentService,
    private message: MessageService) { 
      this.way = sessionStorage.getItem('way');
  }

  listarDatos() {
    return this.http.post<any[]>(this.way + '/act/maepoliza/2b2e754d5771c', {
      elementos: {}
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarRegistro(params) {
    return this.http.post<any[]>(this.way + '/act/maepoliza/43cadb5dd556b0', {
      elementos: {
        p_pol_codigo: params[0],
        p_pol_numero: params[1],
        p_pol_empresa: params[2],
        p_pol_fechaini: params[3],
        p_pol_fechafin: params[4],
        p_com_codigo: params[5]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarRegistro(params) {
    return this.http.post<any[]>(this.way + '/act/maepoliza/14ced94ece2b2', {
      elementos: {
        p_pol_codigo: params[0],
        p_pol_numero: params[1],
        p_pol_empresa: params[2],
        p_pol_fechaini: params[3],
        p_pol_fechafin: params[4],
        p_com_codigo: params[5]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarRegistro(params) {
    return this.http.post<any[]>(this.way + '/act/maepoliza/3ab2b4b6e84b9c', {
      elementos: {
        p_pol_codigo: params[0]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }


}
