<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="anexgenarch"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="anexgenarchConf" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>

<div class="p-grid" style="width: 100%">
	<div class="p-col-12">
		<app-invmaebarra [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"
						 [barraBotones5]="true" [botonVisto]="false" [barraBotonesCRUD]="true"
						 [barraBotones3Alt]="false"
						 (signal)="manejarSenales($event)"
						 style="text-align: left;">
		</app-invmaebarra>
	</div>
</div>
<p-steps [model]="items"[(activeIndex)] ="activeIndex" [readonly] ="false" ></p-steps>
<div class="divgrups" *ngIf="activeIndex === 0">
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-4">
				<span>Es un Regimen Impositivo Microempresas que desea declarar semestral?</span>
			</div>
			<div class="p-col-1">
				<p-dropdown [style]="{'width': '100%', 'min-width': '100%'}" optionLabel="label"
							inputId="cmbOptionsGe" [options]="cmbRegMicroempresas" [(ngModel)]="selectRegMicroemp" (onChange)="cambioRegimen()">
				</p-dropdown>
			</div>
			<div class="p-col-1">
			</div>
			<div class="p-col-1">
				<button pButton
						style="background-image: url(../../../assets/images/iconos/db_remove.png );
								width: 30px; height: 30px; background-color: transparent;"
						title="Eliminar datos de tablas temporales" (click)="eliminarDatosTablasTemporales()">
				</button>
			</div>
		</div>
	</div>
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-2">
				<span>Periodo Informado:</span>
			</div>
			<div class="p-col-1">
				<input id="BOD_VENTAS" type="text" pInputText autocomplete="off" readonly maxlength="10"
					   style="float: left"
					   [(ngModel)]="txtPerMes">
			</div>
			<div class="p-col-1">
				<button type="button"
						style="background-image: url(../../../assets/images/iconos/buscar.png); "
						class="littlebuttons" (click)="busquedaSimple(2)"></button>
			</div>
			<div class="p-col-1">
				<input id="MES" type="text" pInputText autocomplete="off" disabled
					   [(ngModel)]="lblPerMes">

			</div>

		</div>
	</div>
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-2">
				<span>Año:</span>
			</div>
			<div class="p-col-1" style="text-align: left">
				<p-dropdown [style]="{'width': '100%', 'min-width': '100%'}" optionLabel="anio"
							inputId="cmbAniosGe" [options]="cmbAnio" [(ngModel)]="selectAnio">
				</p-dropdown>
			</div>
			<div class="p-col-2">
			</div>
		</div>
	</div>
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-2">
				<p-radioButton name="radio1" value="Compras" inputId="orden1" [(ngModel)]="btnRadio"
				></p-radioButton>
				<label> Compras </label>
			</div>
			<div class="p-col-2">
				<p-radioButton name="radio2" value="VentasRes" inputId="orden2" [(ngModel)]="btnRadio"
				></p-radioButton>
				<label> Ventas Resumidas </label>
			</div>
			<div class="p-col-2">
				<p-radioButton name="radio3" value="CompAnulados" inputId="orden3" [(ngModel)]="btnRadio"
				></p-radioButton>
				<label> Comprobantes Anulados </label>
			</div>
		</div>
	</div>
	<div class="p-col-12" style="margin-top: 20px">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-12">
						<label>Esquemas Seleccionados</label>
					</div>
				</div>
			</div>
			<div class="p-col-6">
				<app-aggridsaci [width]=""
								[height]="largo"
								[enableSorting]="true"
								[enableFilter]="true"
								[rowData]="detalleEsquema"
								[frameworkComponents]="frameworkComponents"
								[animateRows]="true"
								[rowSelection]="rowSelection"
								[columnDefs]="columnDefsEsquema"
								[defaultColDef]="defaultColEsquema"
								[rowStyle]="rowStyle"
								[singleClickEdit]="false"
								[botones]="true"
								[mostrarGuardar]="false"
								(selected)="seleccionDetEsquema($event)"
								[botonNuevo]="false"
								[botonBorrar]="false"
								[botonRegresar]="true">
				</app-aggridsaci>
			</div>
		</div>
		<div class="p-col-2">
		</div>
	</div>
</div>
<div class="divgrups" *ngIf="activeIndex === 1">
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-4">
				<span>Es un Regimen Impositivo Microempresas que desea declarar semestral?</span>
			</div>
			<div class="p-col-1">
				<p-dropdown [style]="{'width': '100%', 'min-width': '100%'}" optionLabel="label"
							inputId="cmbOptionsGe" [options]="cmbMicroempresas" [(ngModel)]="selectRegMicroempx" (onChange)="cambioRegimenx()">
				</p-dropdown>
			</div>
			<div class="p-col-1">
			</div>
		</div>
	</div>
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-2">
				<span>Periodo Informado:</span>
			</div>
			<div class="p-col-1">
				<input id="PER_INFORMADO" type="text" pInputText autocomplete="off" maxlength="10"
					   style="float: left"
					   [(ngModel)]="txtPerMesx">
			</div>
			<div class="p-col-1">
				<button type="button"
						style="background-image: url(../../../assets/images/iconos/buscar.png); "
						class="littlebuttons" (click)="busquedaSimple(2)"></button>
			</div>
			<div class="p-col-1">
				<input id="MES" type="text" pInputText autocomplete="off" disabled
					   [(ngModel)]="lblPerMesx" style="color: #2a13ac">

			</div>

		</div>
	</div>
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-2">
				<span>Año:</span>
			</div>
			<div class="p-col-1" style="text-align: left">
				<p-dropdown [style]="{'width': '100%', 'min-width': '100%'}" optionLabel="anio"
							inputId="cmbAniosGe" [options]="cmbAniox" [(ngModel)]="selectAniox">
				</p-dropdown>
			</div>
			<div class="p-col-2">
			</div>
		</div>
	</div>
	<div class="p-col-12" style="margin-bottom: 100px">
		<div class="p-grid">
			<div class="p-col-2">
				<p-radioButton name="radio1" value="AnexTrans" inputId="orden1" [(ngModel)]="btnRadiox"
				></p-radioButton>
				<label> Anexo Transaccional </label>
			</div>
		</div>
	</div>
	<!--	<div class="p-col-12" style="margin-top: 20px">-->
	<!--		<div class="p-grid">-->
	<!--			<div class="p-col-12">-->
	<!--				<p-progressBar [value]="porcentaje"></p-progressBar>-->
	<!--			</div>-->
	<!--		</div>-->
	<!--		<div class="p-col-2">-->
	<!--		</div>-->
	<!--	</div>-->
</div>

<!-- <p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog> -->

<div style=" 	position: fixed;
				z-index: 999;
				height: 2em;
				width: 2em;
				overflow: visible;
				margin: auto;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;

				">
	<p-progressSpinner *ngIf="generarxmlService.proceso" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
</div>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Selección de datos" [(visible)]="displayDialogEmpresas"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width:'70vw', height:'auto'}">
	<app-sacimaeempresas *ngIf="displayDialogEmpresas" [sentencia]="query"
						 (detallesSeleccionados)="recibirEmpresas($event)" (signalSalir)="recibirSenalesEmpresas($event)"
	></app-sacimaeempresas>
</p-dialog>

<p-dialog header="Facturas no autorizadas" [(visible)]="displayFactNoAuto"
		  [transitionOptions]="'0ms'"
		  [modal]="false"
		  appendTo="body"
		  [draggable]="false"
		  [style]="{width: '310px', height: '70vh'} "
		  (onHide)="procesoCerrarAplicacion()">
	<p-confirmDialog [transitionOptions]="'0ms'" key="factnoautConf" title="Confirmar Acción" appendTo="body">
	</p-confirmDialog>
	<div>
		<div class="divgrups">
			<div class="p-col-12" *ngFor="let valor of factuNoAuto;let i=index" style="margin-top: -15px">
				<div class="p-grid">
					<div class="p-col-1">
						<span >{{i + 1}}&nbsp;&nbsp;{{valor.ENCFAC_NUMERO}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-4">
			</div>
			<div class="p-col-2">
				<button pButton class="aceptar" style="line-height: 2.5em; font-family: Arial, serif; height: 30px; width: 100px" (click)="procesoCerrarAplicacion()">Aceptar</button>
			</div>
		</div>
	</div>

</p-dialog>