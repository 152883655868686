<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="compania"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="compania" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="" [botonGuardar]="btnGuardar" [botonBorrar]="" [botonRegresar]=""
			[barraBotonesCRUD]="" [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="false"
			[barraBotones5]="true" (signal)="manejarSenales($event)">

		</app-invmaebarra>
	</div>
</div>
<p-tabView [(activeIndex)]="index">
	<p-tabPanel header="Datos Generales">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-7">
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-12">
								</div>
							</div>
						</div>
						<div class="p-col-4">
							<span>Código: </span>
						</div>
						<div class="p-col-2">
							<input id="codigoCompania" type="text" pInputText disabled [(ngModel)]="txtCodigo">
						</div>
						<div class="p-col-1" style="text-align: right">
							<span>RUC:</span>
						</div>
						<div class="p-col-3">
							<input id="rucCompania" type="text" pInputText [(ngModel)]="txtRuc" (input)="cambio($event)"
								(keydown.enter)="cambiarFoco($event)">
						</div>
						<div class="p-col-12" style="margin-top: -7px">
							<div class="p-grid">
								<div class="p-col-4">
									<span>Razón Social:</span>
								</div>
								<div class="p-col-6">
									<input id="razonSocial" type="text" pInputText [(ngModel)]="txtRazSoc"
										(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-4">
									<span>Nombre Comercial:</span>
								</div>
								<div class="p-col-3">
									<input id="nombreComercial" type="text" pInputText [(ngModel)]="txtNombreCorto"
										(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
								</div>

							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-4">
									<span>Provincia:</span>
								</div>
								<div class="p-col-3">
									<p-dropdown [style]="{'width': '100%', 'min-width': '100%'}"
										optionLabel="REF_NOMBRE" inputId="cmbProvincia" [(ngModel)]="strCmbProvin"
										[options]="cboProvin" (onChange)="cambio($event)"
										(keydown.enter)="cambiarFoco($event)">
									</p-dropdown>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-4">
									<span>Dirección:</span>
								</div>
								<div class="p-col-6">
									<input id="direccion" type="text" pInputText [(ngModel)]="txtDirecc"
										(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-4">
									<span>Teléfono 1:</span>
								</div>
								<div class="p-col-2">
									<input id="telefono1" type="text" pInputText [(ngModel)]="txtTelf1"
										(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
								</div>
								<div class="p-col-2">
									<span>Teléfono 2:</span>
								</div>
								<div class="p-col-2">
									<input id="telefono2" type="text" pInputText [(ngModel)]="txtTelf2"
										(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-4">
									<span>Correo Electrónico:</span>
								</div>
								<div class="p-col-3">
									<input id="correo" type="text" pInputText [(ngModel)]="txtEmail"
										(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-4">
									<span>Autorización Retención:</span>
								</div>
								<div class="p-col-3">
									<input id="autoRetencion" type="text" pInputText [(ngModel)]="txtAutRet"
										(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-4">
									<span>Número de Establecimientos:</span>
								</div>
								<div class="p-col-1">
									<input id="numEstab" type="text" pInputText [(ngModel)]="txtNumeroEst"
										(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-4">
									<span>Establecimiento:</span>
								</div>
								<div class="p-col-1">
									<input id="establecimiento" type="text" pInputText [(ngModel)]="txtEstablecimiento"
										(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
								</div>
								<div class="p-col-2" style="text-align: right">
									<span>Punto de Venta:</span>
								</div>
								<div class="p-col-1">
									<input id="puntoventa" type="text" pInputText [(ngModel)]="txtPuntoVenta"
										(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-4">
									<span>Contribuyente Especial:</span>
								</div>
								<div class="p-col-1">
									<input id="contriEsp" type="text" pInputText [(ngModel)]="txtConEspecial"
										(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
								</div>
								<div class="p-col-5">
									<span>000 ---> No es contribuyente especial</span>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-4">
									<span>Obligado a llevar contabilidad:</span>
								</div>
								<div class="p-col-1">
									<p-dropdown [style]="{'width': '100%', 'min-width': '100%'}" optionLabel="name"
										inputId="llevaContabilidad" [options]="cmbObligadoLLevarCont"
										[(ngModel)]="strCmbObligadoCont" (onChange)="cambio($event)">
									</p-dropdown>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px" *ngIf="true != true">
							<div class="p-grid">
								<div class="p-col-4">
									<span>Contribuyente régimen Microempresas:</span>
								</div>
								<div class="p-col-4">
									<p-checkbox binary="true" [(ngModel)]="chkbtnCONREGMIC" (onChange)="cambio($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-4">
									<span>RIMPE:</span>
								</div>
								<div class="p-col-4">
									<!-- <p-checkbox binary="true" [(ngModel)]="chkbtnCONRIMPE" (onChange)="cambio($event)">
									</p-checkbox> -->
									<!-- CDPJ -->
									<p-dropdown [options]="cmbregimen" [style]="{'width':'100%'}"
										optionLabel="name" [panelStyle]="{'width':'110%'}"
										[(ngModel)]="selectregimen"
										(onChange)="cambio($event)"
							></p-dropdown>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-4">
									<span>Agente de Retención:</span>
								</div>
								<div class="p-col-1">
									<p-checkbox binary="true" [(ngModel)]="chkbtnAgenteret" (onChange)="cambio($event)">
									</p-checkbox>
								</div>
								<div class="p-col-2" style="text-align: right">
									<span>Num. Resolución:</span>
								</div>
								<div class="p-col-1">
									<input id="ag2" type="text" pInputText [(ngModel)]="txtresolucion"
										(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-4">
									<span>Empresa de Transporte con Socios: </span>
								</div>
								<div class="p-col-4">
									<p-checkbox binary="true" [(ngModel)]="chkbtnTransporte"
										(onChange)="cambioChkT($event)">
									</p-checkbox>
								</div>

							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-4">
									<span>Subsidio:</span>
								</div>
								<div class="p-col-4">
									<p-checkbox binary="true" [(ngModel)]="chkbtnSubsidio" (onChange)="cambio($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-4">
									<span>Almacenamiento de documentos:</span>
								</div>
								<div class="p-col-2">
									<input id="almacendoc" type="text" pInputText [(ngModel)]="txtAlmacenamiento"
										disabled>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="p-col-5">
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-12">
								</div>
							</div>
						</div>
						<div class="p-col-3">

						</div>
						<div class="p-col-8">
							<img src="{{strLogoCom}}" style="width: 20vw; height: 100px;">
						</div>
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-12">
								</div>
							</div>
						</div>
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-12">
								</div>
							</div>
						</div>
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-12">
								</div>
							</div>
						</div>
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-12">
								</div>
							</div>
						</div>
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-12">
									<span style="font-weight: bold">Representante legal</span>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-3">
									<span>ID:</span>
								</div>
								<div class="p-col-3">
									<input id="idRepLegal" type="text" pInputText [(ngModel)]="txtCICoordinador"
										(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-3">
									<span>Nombres y Apellidos:</span>
								</div>
								<div class="p-col-7">
									<input id="nomRepLegal" type="text" pInputText [(ngModel)]="txtRepreLegal"
										(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-2">
									<span style="font-weight: bold">Contador</span>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-3">
									<span>RUC:</span>
								</div>
								<div class="p-col-3">
									<input id="rucContador" type="text" pInputText [(ngModel)]="txtRucContador"
										(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-3">
									<span>Nombres y Apellidos:</span>
								</div>
								<div class="p-col-7">
									<input id="nomContador" type="text" pInputText [(ngModel)]="txtContador"
										(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12">
							<div class="p-grid">
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-4">
									<span>Aceptar todas las conexiones:</span>
								</div>
								<div class="p-col-1">
									<p-checkbox binary="true" [(ngModel)]="chkAceptarConexiones"
										(onChange)="cambioAceptConexion($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-4">
									<span>Usar ping de acceso:</span>
								</div>
								<div class="p-col-1" style="text-align: left">
									<p-checkbox binary="true" [(ngModel)]="chkUsarPingAcceso"
										(onChange)="cambioUsarPing($event)">
									</p-checkbox>
								</div>
								<div class="p-col-1" *ngIf="disabledEmailPing">
									<span>Correo:</span>
								</div>
								<div class="p-col-4" *ngIf="disabledEmailPing">
									<input id="correoAcceso" type="text" pInputText [(ngModel)]="txtCorreoAcceso"
										(input)="cambio($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-4">

								</div>
								<div class="p-col-1" style="text-align: left">

								</div>
								<div class="p-col-1" *ngIf="disabledEmailPing">
									<span>Celular:</span>
								</div>
								<div class="p-col-4" *ngIf="disabledEmailPing">
									<input id="telefonoAcceso" type="text" pInputText [(ngModel)]="txtTelefonoAcceso"
										(input)="cambio($event)">
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Datos FE - Datos Generales">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Tipo de ambiente de la F.E.: </span>
				</div>
				<div class="p-col-1">
					<p-dropdown [style]="{'width': '100%', 'min-width': '100%'}" optionLabel="name"
						inputId="cmbTipoAmbiente" [(ngModel)]="strCmbTipoAmbiente" [options]="cmbTipoAmbiente"
						(onChange)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
					</p-dropdown>
				</div>
				<div class="p-col-12" style="margin-top: -7px">
					<div class="p-grid">
						<div class="p-col-3">
							<span style="font-weight: bold">Correos Copia:</span>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Facturas:</span>
						</div>
						<div class="p-col-3">
							<input id="correoFact" type="text" pInputText [(ngModel)]="txtCorreoFacturas"
								(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Notas de Crédito:</span>
						</div>
						<div class="p-col-3">
							<input id="correoNotaCred" type="text" pInputText [(ngModel)]="txtCorreoNotasCredito"
								(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Guías de Remisión:</span>
						</div>
						<div class="p-col-3">
							<input id="correoGuiaRem" type="text" pInputText [(ngModel)]="txtCorreoGuiasRemision"
								(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Notas de débito:</span>
						</div>
						<div class="p-col-3">
							<input id="correoNotaDeb" type="text" pInputText [(ngModel)]="txtCorreoNotasDebito"
								(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Retenciones:</span>
						</div>
						<div class="p-col-3">
							<input id="correoReten" type="text" pInputText [(ngModel)]="txtCorreoRetenciones"
								(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Liquid. de Compra:</span>
						</div>
						<div class="p-col-3">
							<input id="correoLiqCom" type="text" pInputText [(ngModel)]="txtCorreoLiquidaciones"
								(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -7px">
					<div class="p-grid">
						<div class="p-col-3">
							<span style="font-weight: bold">Activación:</span>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Generar Archivo Para Activación</span>
						</div>
						<div class="p-col-3">
							<div class="p-grid">
								<div class="p-col-6">
									<button pButton class="aceptar" (click)="activacion($event)"
										style=" line-height: 2.5em; font-family: Arial, serif; height: 30px; width: 80%">
										Activación
									</button>
								</div>
								<div class="p-col-6">
									<button pButton class="aceptar" (click)="correo($event)"
										style=" line-height: 2.5em; font-family: Arial, serif; height: 30px; width: 80%">
										Correo
									</button>
								</div>
							</div>
						</div>	
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Activación</span>
						</div>
						<div class="p-col-2">
							<input id="txtConformacion" type="text" pInputText [(ngModel)]="txtConformacion" [disabled]="!txtConformacionsetEnabled"
								(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
						</div>
						<div class="p-col-1">
							<div class="p-grid">
								<div class="p-col-6">
									<button style="background-image: url(../../../assets/images/iconos/visto.png);"
										(click)="check($event)" title="Aceptar"></button>
								</div>
								<div class="p-col-6">
									<button style="background-image: url(../../../assets/images/iconos/borrar.png);"
										(click)="equis($event)" title="Eliminar"></button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Fecha caducidad firma:</span>
						</div>
						<div class="p-col-3">
							<span>{{fechacaducidad}}</span>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Firma:</span>
						</div>
						<div class="p-col-3">
							<p-fileUpload id="com_firma" name="myfile[]" auto="auto" customUpload="true"
							accept=".p12"
								chooseLabel="Seleccionar" (uploadHandler)="onBasicUploadP($event)"
								(onRemove)="onRemove($event)"
								>
							</p-fileUpload>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Contraseña:</span>
						</div>
						<div class="p-col-3">
							<input id="contraseña" type="password" pInputText [(ngModel)]="txtpwd1" 
							(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Confirmar contraseña:</span>
						</div>
						<div class="p-col-3">
							<input id="comfirmContraseña" type="password" pInputText 
							pInputText [(ngModel)]="txtpwd2" 
							(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)"
							>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-2">
						</div>
						<div class="p-col-1">
							<button pButton class="aceptar" (click)="uploadFirma()"
								style="line-height: 2.5em; font-family: Arial, serif; height: 30px; width: 100px">
								Guardar
							</button>
						</div>
						<!-- <div class="p-col-1">
							<button pButton class="aceptar"
								style="line-height: 2.5em; font-family: Arial, serif; height: 30px; width: 100px">
								Desactivar
							</button>
						</div> -->
					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Datos FE - Datos Adicionales">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-1">
				</div>
				<div class="p-col-2">
					<span style="font-weight: bold">Sucursales:</span>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-7">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
								[rowData]="segmaecompaniaService.detSucursalArray[indicador]"
								[frameworkComponents]="frameworkComponents" [animateRows]="true"
								[rowSelection]="rowSelection" [columnDefs]="columnDefsSegMaeSucursal"
								[defaultColDef]="defaultSegMaeSuc" [rowStyle]="rowStyle" [singleClickEdit]="false"
								[botones]="true" [mostrarGuardar]="false" [botonNuevo]="false" [botonRegresar]="false"
								[botonBorrar]="false" (selected)="seleccionDetSucursal($event)">
							</app-aggridsaci>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-4">
							<span style="font-weight: bold">Información Adicional Facturas:</span>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-7">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
								[rowData]="segmaecompaniaService.detInfoFactArray[indicador]"
								[frameworkComponents]="frameworkComponents" [animateRows]="true"
								[rowSelection]="rowSelection" [columnDefs]="columnDefsSegInfFact"
								[defaultColDef]="defaultSegInfFact" [rowStyle]="rowStyle" [singleClickEdit]="false"
								[botones]="true" [mostrarGuardar]="false" [botonNuevo]="false" [botonRegresar]="false"
								[botonBorrar]="false" (selected)="seleccionDetInfoFact($event)">
							</app-aggridsaci>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-10">
							<span style="font-weight: bold">Información Adicional Guias de Remisión:</span>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-7">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
								[rowData]="segmaecompaniaService.detInfoGuiaRemArray[indicador]"
								[frameworkComponents]="frameworkComponents" [animateRows]="true"
								[rowSelection]="rowSelection" [columnDefs]="columnDefsSegInfGuiRem"
								[defaultColDef]="defaultSegInfGuiaRem" [rowStyle]="rowStyle" [singleClickEdit]="false"
								[botones]="true" [mostrarGuardar]="false" [botonNuevo]="false" [botonRegresar]="false"
								[botonBorrar]="false" (selected)="seleccionDetInfoGuiaRem($event)">
							</app-aggridsaci>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-10">
							<span style="font-weight: bold">Información Adicional Liquidación de Compras:</span>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-7">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
								[rowData]="segmaecompaniaService.detInfoLiqComArray[indicador]"
								[frameworkComponents]="frameworkComponents" [animateRows]="true"
								[rowSelection]="rowSelection" [columnDefs]="columnDefsSegInfLiqCom"
								[defaultColDef]="defaultSegInfLiqCom" [rowStyle]="rowStyle" [singleClickEdit]="false"
								[botones]="true" [mostrarGuardar]="false" [botonNuevo]="false" [botonRegresar]="false"
								[botonBorrar]="false" (selected)="seleccionDetInfoLiqCom($event)">
							</app-aggridsaci>
						</div>
					</div>
				</div>
				<!-- GSRF -->
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-10">
							<span style="font-weight: bold">Información Adicional Devolución de Ventas:</span>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-7">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
								[rowData]="segmaecompaniaService.detInfoDevfacArray[indicador]"
								[frameworkComponents]="frameworkComponents" [animateRows]="true"
								[rowSelection]="rowSelection" [columnDefs]="columnDefsSegInfdevfac"
								[defaultColDef]="defaultSegInfdevfac" [rowStyle]="rowStyle" [singleClickEdit]="false"
								[botones]="true" [mostrarGuardar]="false" [botonNuevo]="false" [botonRegresar]="false"
								[botonBorrar]="false" (selected)="seleccionDetInfoDevFac($event)">
							</app-aggridsaci>
						</div>
					</div>
				</div>
				<!-- GSRF -->
			</div>
		</div>
	</p-tabPanel>
</p-tabView>

<div class="layout-config">
	<div class="layout-config-content-wrapper" style="width: 25em">
		<a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones($event)">
			<i class="pi pi-bars" style="cursor: pointer"></i>
		</a>
		<p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
			[dismissible]="false" [showCloseIcon]="false" [style]="{width: '32em'}">
			<br>
			<br>
			<br>
			<p-tabView>
				<p-tabPanel header="Logo">
					<div class="p-grid">
						<div class="p-col-12">
							<p-fileUpload id="com_logo" name="myfile[]" accept="image/*" auto="auto" customUpload="true"
								chooseLabel="Seleccionar" (uploadHandler)="onBasicUpload($event)">
							</p-fileUpload>

						</div>
						<div class="p-col-12" style="text-align: center">
							<button type="button"
								style="background-image: url(../../../assets/images/iconos/visto.png);"
								(click)="cargar()"></button>
						</div>
						<div class="p-col-12">Ruta Logo Reportes</div>
						<div class="p-col-10">
							<input id="rutalogo" type="text" pInputText [(ngModel)]="rutalogo" (input)="cambio($event)"
								(keydown.enter)="cambiarFoco($event)">
						</div>
						<div class="p-col-2" style="text-align: center">
							<button type="button"
								style="background-image: url(../../../assets/images/iconos/visto.png);"
								(click)="actrutalogo()"></button>
						</div>
					</div>
				</p-tabPanel>
				<p-tabPanel header="Conexiones" *ngIf="pestaniaconexiones">
					<div class="p-grid">
						<div class="p-col-12">
							<app-aggridsaci [width]="ancho" [height]="'650'" [enableSorting]="true"
								[enableFilter]="true" [rowData]="segmaecompaniaService.detConexionArray[indicador]"
								[frameworkComponents]="frameworkComponents" [animateRows]="true"
								[rowSelection]="rowSelection" [columnDefs]="columnDefsSegComConexion"
								[defaultColDef]="defaultSegComConexion" [rowStyle]="rowStyle" [singleClickEdit]="false"
								[botones]="true" [mostrarGuardar]="false" [botonNuevo]="btnNuevoConexion"
								[botonRegresar]="btnCancelarConexion" [botonBorrar]="btnEliminarConexion"
								(selected)="seleccionDetConexion($event)">
							</app-aggridsaci>
						</div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-sidebar>
	</div>
</div>

<p-dialog header="Clave Autorización" [(visible)]="displayDialogAutorizacion" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" (onHide)="onHideAutorizacion()" [style]="{width: '40vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="autorizacion"></p-toast>
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							(keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>
<!-- CDPJ -->
<p-dialog header="Clave Autorización" [(visible)]="displayDialogAutorizacionRuc" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" (onHide)="onHideAutorizacionRuc()" [style]="{width: '40vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="autorizacion"></p-toast>
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							(keydown.enter)="comprobarClaveRuc()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>
<!-- CDPJ -->

<!--<p>Cargar logo de la compañia</p>-->
<!--<p-fileUpload id="ART_IMAGEN" name="myfile[]" multiple="multiple" accept="image/*"-->
<!--			  auto="auto" customUpload="true" (uploadHandler)="onBasicUpload($event)"></p-fileUpload>-->
<!--<button type="button"-->
<!--		style="background-image: url(../../../assets/images/iconos/visto.png);"-->
<!--		(click)="cargar()"></button>-->