import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import { nommaeRubro } from '../nominterfaces/nommaerubro';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { MessageService } from 'primeng';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
@Injectable({
  providedIn: 'root'
})
export class NommaerubroService {


  public way: string;
  public nommaeRubro:nommaeRubro[]=[];
  
  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public init: NuevoComponentService,
    private messageService: MessageService,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) { 
    
      this.way = sessionStorage.getItem('way');
    }
      
  selectRubros(){
    let strSentencia = "select * from nom_maerubro where com_codigo = '01' order by maerubro_tipoorde,maerubro_rubrorde";
    
    return this.http.post<any[]>(this.way + '/nom/rubros/e3aae6777e5ef00', {
        elementos: {}
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  selectDiseñoTable(){
    let strSentencia = "select INITCAP(tiprol_nombre) AS tiprol_nombre,tiprol_codigo from nom_maetiporol";
    
    return this.http.post<any[]>(this.way + '/nom/rubros/71d56cb9d52ea8', {
        elementos: {}
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  guardarRubro(datos:nommaeRubro){ 
    console.log(datos); 
    datos.MAERUBRO_TIPOORDE= (datos.MAERUBRO_TIPO=='INGRESO')?1:(datos.MAERUBRO_TIPO=='DESCUENTO')?2:3;
    return this.http.post<any[]>(this.way + '/nom/rubros/43cadb55bf2f78', {
      elementos: {
          p_maerubro_codigo  :  datos.MAERUBRO_CODIGO,
          p_maerubro_tipo    :  datos.MAERUBRO_TIPO,
          p_maerubro_codrubr :  datos.MAERUBRO_CODRUBR,
          p_maerubro_nombre  :  datos.MAERUBRO_NOMBRE,
          p_maerubro_calcula :  (datos.MAERUBRO_CALCULA==true)?'Si':'No',
          p_maerubro_unidad  :  datos.MAERUBRO_UNIDAD,
          p_maerubro_gravable:  (datos.MAERUBRO_GRAVABLE==true)?'Si':'No',
          p_maerubro_aporrent:  (datos.MAERUBRO_APORRENT==true)?'Si':'No',
          p_maerubro_proyrent:  (datos.MAERUBRO_PROYRENT==true)?'Si':'No',
          p_maerubro_fecini    :datos.MAERUBRO_FECINI,
          p_maerubro_fecfin    :datos.MAERUBRO_FECFIN,
          p_con_codigo         :datos.CON_CODIGO,
          p_con_comcodigo      :'01',
          p_com_codigo         :'01',
          p_maerubro_activo    :'1',
          p_maerubro_tipoorde  :datos.MAERUBRO_TIPOORDE,
          p_maerubro_rubrorde  :datos.MAERUBRO_RUBRORDE,
          p_con_codigo_2       :datos.CON_CODIGO_2 ,
          p_con_codigo_3       :datos.CON_CODIGO_3,
          p_con_codigo_4       :datos.CON_CODIGO_4,
          p_con_codigohabe         :datos.CON_CODIGOHABE,
          p_con_codigohabe_2       :datos.CON_CODIGOHABE_2,
          p_con_codigohabe_3       :datos.CON_CODIGOHABE_3,
          p_con_codigohabe_4       :datos.CON_CODIGOHABE_4,
          p_con_codigo_5           :datos.CON_CODIGO_5,
          p_con_codigo_6           :datos.CON_CODIGO_6,
          p_con_codigo_7           :datos.CON_CODIGO_7,
          p_con_codigo_8           :datos.CON_CODIGO_8,
          p_con_codigo_9           :datos.CON_CODIGO_9,
          p_con_codigo_10          :datos.CON_CODIGO_10,
          p_con_codigohabe_5       :datos.CON_CODIGOHABE_5,
          p_con_codigohabe_6       :datos.CON_CODIGOHABE_6,
          p_con_codigohabe_7       :datos.CON_CODIGOHABE_7,
          p_con_codigohabe_8       :datos.CON_CODIGOHABE_8,
          p_con_codigohabe_9       :datos.CON_CODIGOHABE_9,
          p_con_codigohabe_10      :datos.CON_CODIGO_10,
      },
    }, this.confIniciales.httpOptions()).toPromise(); 
  }
  
  eliminarRubro(parametro1:string){
    //
    return this.http.post<any[]>(this.way + '/nom/rubros/3ab2b4afbf2f78', {
      elementos: {
        p_maerubro_codigo:parametro1
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  
}
