<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="maecentroc"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="Maecentroc" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>

<div class="p-grid" style="width: 99%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
						 [botonRegresar]="btnRegresar" [barraBotonesCRUD]="false"
						 [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"
						 [barraBotones5]="true"
						 (signal)="manejarSenales($event)"></app-invmaebarra>
	</div>
</div>

<div style="overflow: hidden" class="p-col-12">
	<p-tabView>
		<p-tabPanel header="Centro de Costos">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="false"
							[enableFilter]="true"
							[rowData]="ConmaeconServices.conmaecentroCostos"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsConCuenta"
							[defaultColDef]="defaultColDefCuentaContable"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)="selectedCuentaCosto($event)"
							(cambios)="cambio ($event)"
			></app-aggridsaci>
		</p-tabPanel>
		<!-- <p-tabPanel header="Mayor General">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="ConmaeconServices.conmaeMayorGeneral"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefMayorGeneral"
							[defaultColDef]="defaultColDefMayorGeneral"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"

			></app-aggridsaci>
		</p-tabPanel> -->
	</p-tabView>
</div>



