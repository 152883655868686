import { Component, OnInit } from '@angular/core';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { MessageService } from 'primeng';

@Component({
  selector: 'app-sacimaeempresa',
  templateUrl: './sacimaeempresa.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class SacimaeempresaComponent implements OnInit {

  // Botones
  empresanombre: string;
  empresaesquema: string;
  empresausuario: string;
  empresapassword: string;

  constructor(private confIniciales: ConfInicialesService, private messageService: MessageService, private init: NuevoComponentService) { }

  ngOnInit(): void {
  }

  cambiarFoco(evento) {
    if (evento.target.id === 'empresanombre') {
      document.getElementById('empresaesquema').focus();
    }
    if (evento.target.id === 'empresaesquema') {
      document.getElementById('empresausuario').focus();
    }
    if (evento.target.id === 'empresausuario') {
      document.getElementById('empresapassword').focus();
    }
    if (evento.target.id === 'empresapassword') {
      document.getElementById('empresanombre').focus();
    }
  }

  manejarSenales(valor) {
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }

  async ingresarempresa() {
    if (this.empresanombre === '' || this.empresanombre === null || this.empresanombre === undefined) {
      this.mensajeAlerta('error', 'Error', 'Ingrese el nombre de la empresa');
      return;
    }

    if (this.empresaesquema === '' || this.empresaesquema === null || this.empresaesquema === undefined) {
      this.mensajeAlerta('error', 'Error', 'Ingrese el nombre del esquema');
      return;
    }

    if (this.empresausuario === '' || this.empresausuario === null || this.empresausuario === undefined) {
      this.mensajeAlerta('error', 'Error', 'Ingrese el nombre del usuario');
      return;
    }

    if (this.empresapassword === '' || this.empresapassword === null || this.empresapassword === undefined) {
      this.mensajeAlerta('error', 'Error', 'Ingrese el password');
      return;
    }

    const existeempresa = await this.confIniciales.verificarNombreEmpresa(this.empresanombre);

    if (existeempresa) {
      this.mensajeAlerta('error', 'Error', 'El nombre de la empresa existe, elimine la empresa para poder ingresarla nuevamente');
      return;
    }

    const inserempresa = this.confIniciales.insertarEmpresa(this.empresanombre, this.empresaesquema,
      this.empresausuario, this.empresapassword);

    if (inserempresa) {
      this.mensajeAlerta('success', 'Empresas', 'La empresa se ha ingresado correctamente');
    }
  }

  async actualizarempresa() {
    if (this.empresanombre === '' || this.empresanombre === null || this.empresanombre === undefined) {
      this.mensajeAlerta('error', 'Error', 'Ingrese el nombre de la empresa');
      return;
    }

    if (this.empresaesquema === '' || this.empresaesquema === null || this.empresaesquema === undefined) {
      this.mensajeAlerta('error', 'Error', 'Ingrese el nombre del esquema');
      return;
    }

    if (this.empresausuario === '' || this.empresausuario === null || this.empresausuario === undefined) {
      this.mensajeAlerta('error', 'Error', 'Ingrese el nombre del usuario');
      return;
    }

    if (this.empresapassword === '' || this.empresapassword === null || this.empresapassword === undefined) {
      this.mensajeAlerta('error', 'Error', 'Ingrese el password');
      return;
    }

    const existeempresa = await this.confIniciales.verificarNombreEmpresa(this.empresanombre);
    if (existeempresa === false) {
      this.mensajeAlerta('error', 'Error', 'El nombre de la empresa no existe, no es posible actualizar');
      return;
    }

    const actempresa = this.confIniciales.actualizarEmpresa(this.empresanombre, this.empresaesquema,
      this.empresausuario, this.empresapassword);

    if (actempresa) {
      this.mensajeAlerta('success', 'Empresas', 'La empresa se ha actualizado correctamente');
    }
  }

  async eliminarempresa() {
    if (this.empresanombre === '' || this.empresanombre === null || this.empresanombre === undefined) {
      this.mensajeAlerta('error', 'Error', 'Ingrese el nombre de la empresa');
      return;
    }

    const existeempresa = await this.confIniciales.verificarNombreEmpresa(this.empresanombre);
    if (existeempresa === false) {
      this.mensajeAlerta('error', 'Error', 'El nombre de la empresa no existe, no es posible eliminar');
      return;
    }

    const actempresa = this.confIniciales.eliminarEmpresa(this.empresanombre);

    if (actempresa) {
      this.mensajeAlerta('success', 'Empresas', 'La empresa se ha eliminado correctamente');
    }
  }

  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'maeempresa',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });

  }
}