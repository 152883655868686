import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { SelectItem } from 'primeng';
import { VenencfacService } from '../venservicios/venencfac.service';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';

@Component({
  selector: 'app-venexportacionnuevo',
  templateUrl: './venexportacionnuevo.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})

export class VenexportacionnuevoComponent implements OnInit {
  @Input() auxiliar: any;
  @Input() strNumFact: string;
  @Output() cerrar = new EventEmitter<boolean>();

   // dropdowns
   opcionIva: SelectItem[];
   opcionIvaDesc: SelectItem[];
   opcionesBodega: SelectItem[];
   opcionesRegimen: SelectItem[];
   opcionesExportacion: SelectItem[];
   opcionesDistrito: SelectItem[];
   opcionesRegRefrendo: SelectItem[];
   opcionesIngresoExterior: SelectItem[];
   opcionesTipoIngresoExterior: SelectItem[];
   opcionesprecio: SelectItem[];

  // dialogs  
  displayDialogBusquedaFast: boolean;

  // busqueda
  opcionbusqueda: string;
  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];
  tabla: string;
  where: string;
  consulta: string;

  datosexportacion: any;

  booleanRefrendo: boolean;
  booleanImpuestoRenta: boolean;
  booleanPorcentaje: boolean;
  booleanRegimen: boolean;
  booleanParaiso: boolean;
  booleanRegimenP: boolean;

  nombrePaisSeleccionado: string;
  codigoPaisRegimen: string;
  codigoPaisParaiso: string;

  constructor(public encfacService: VenencfacService, private busqService: InvbusquedaService) { }

  ngOnInit(): void {
    this.datosexportacion = {};
  }

  setFocus(event) {
    let element: any;
    switch (event.target.id) {
      case 'ENCFAC_DIASCRDT': {
        element = document.getElementById('ENCFACPRO_NUMINGRESO');
        break;
      }
    }

    if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }
  }

  abrirPaisesRegimen() {
    this.opcionbusqueda = 'BUSQUEDARG';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código CON', value: 'CON_CODIGO' }
    ];
    this.busquedacampos = [this.codigoPaisRegimen, '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.consulta = '*';
    this.where = ''; 

    this.busqService.busquedaAnexMaeCodSri9().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  abrirPaisesParaiso() {
    this.opcionbusqueda = 'BUSQUEDAPF';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código CON', value: 'CON_CODIGO' }
    ];
    this.busquedacampos = [this.codigoPaisParaiso, '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.consulta = '*';
    this.where = '';
    this.busqService.busquedaAnexMaeCodSri10().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  abrirPaisesExportacion() {
    this.opcionbusqueda = 'BUSQUEDAEX';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código CON', value: 'CON_CODIGO' }
    ];
    this.busquedacampos = [this.datosexportacion.PAISEFECEXP, '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.consulta = '*';
    this.where = '';
    this.busqService.busquedaAnexMaeCodSri11().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  habilitarImpuestoRenta() {
    if (this.datosexportacion.INGGRAVADOEXT === 'SI') {
      this.booleanImpuestoRenta = false;
      document.getElementById('valorImpuestoR').focus();
    } else if (this.datosexportacion.INGGRAVADOEXT === 'NO') {
      this.booleanImpuestoRenta = true;
      document.getElementById('valorfobC').focus();
    } else if (this.datosexportacion.INGGRAVADOEXT === '') {
      this.booleanImpuestoRenta = true;
    }
  }

  manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'factura') {
    }
  }
}
