import { Component, EventEmitter, Input, Output, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CxctrnedithistService } from '../cxcservicios/cxctrnedithist.service'
import { Cxcedithisto } from '../cxcinterfaces/cxcedithisto';
import { DatePipe } from '@angular/common';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { isNullOrUndefined } from 'util';
@Component({
  selector: 'app-cxctrnedithist',
  templateUrl: './cxctrnedithist.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class CxctrnedithistComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  @Input() strnumfac: any;
  @Input() clicodigo: any;
  @Input() secuencial: any;
  @Output() signalSalir = new EventEmitter<boolean>();
  hmpConfiguracion: any[];
  indicador: any;
  largo: string;
  public frameworkComponents;
  rowSelection = 'multiple';
  rowStyle;
  defaultCxcTrnedithis;
  cxxedithisSelected: Cxcedithisto;

  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  oculto: boolean;

  buscarallfac:boolean;

  spin=false;
  columnDefsCxcTrnediHis = [
    {
      headerName: 'Numero Factura', field: 'TRNCOBRO_NRODOC', cellEditor: 'cellPrueba', width: 150,
      editable: (params) => {
        return false;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 40,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Fecha Llamada', field: 'TRNHIS_FECHA', cellEditor: 'cellPrueba', width: 150,
      editable: (params) => {
        return false;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: true,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Comentario', field: 'TRNHIS_COMENTARIO', cellEditor: 'cellPrueba', width: 300, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: true
        }
      }
    },
  ];

  constructor(public cxctrnedithistService: CxctrnedithistService,
    private datePipe: DatePipe, private confirmationService: ConfirmationService,
    private auditoriagral: AuditoriagralService, private messageService: MessageService, ) {
    this.agTable();

  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultCxcTrnedithis = {
      editable: false,
      objeto: 'cxctrnedithis',
      resizable: true
    };
  }
  async ngOnInit() {
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
    this.oculto = true;
    this.largo = '250';
    this.buscarallfac=false;
    await this.mostrarHistorial();
  }
inicarbotenes(){
  this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
    this.oculto = true;
    this.buscarallfac=false;
}
  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      this.botonGuardar = false;
      this.botonRegresar = false;
      this.botonNuevo = true;
      this.nuevo();
    }
    if (valor === 'Borrar') {
      this.eliminar();
    }
    if (valor === 'Guardar') {
      this.guardar();
    }
    if (valor === 'Cancelar') {
      this.cancelar();
    }

    if (valor === 'Salir') {
      //this.init.tabs.splice(this.init.tabindex, 1);
      this.signalSalir.emit(true);
    }
  }
  cancelar(){
    this.inicarbotenes();
    this.mostrarHistorial();
  }
  seleccionedithistor(params) {
    if (params.object !== undefined) {
      this.cxxedithisSelected = params.object;
      console.log('select', this.cxxedithisSelected )
    }
  }
  async mostrarHistorial() {
    this.spin=true;
    try {
      this.cxctrnedithistService.listardatos(this.strnumfac, this.clicodigo).subscribe(data => {
        console.log(data)
        this.cxctrnedithistService.cxctrnhisto = data;
        this.cxctrnedithistService.cxctrnhisto.map((his) => {
          his.TRNHIS_FECHA = this.cambiarfechanull(his.TRNHIS_FECHA);
          console.log(his.TRNHIS_FECHA);
          his.CLAVE = 1 + this.cambiarfechanull(his.TRNHIS_FECHA);
          his.LINEA=his.TRNHIS_SECUENCIAL;
          this.cxxedithisSelected =his
        });

      });
    this.aggrid.refreshColumnDefs();
    } catch (error) {
      this.spin=false
    }
    this.spin=false
  }
  cambiarfechanull(valor){
    let res="";
    if(isNullOrUndefined(valor)){
      return res;
    }else{
      try{
        if(valor.toString().includes('-')){
          res=this.datePipe.transform(valor,'dd/MM/yyyy HH:mm');
          return res;
        }else{
          return valor;
        }
      }catch(err){         
        return valor;
      } 
    }
  }
  cambio(cambio) {
    if (cambio === true) {
      this.botonGuardar = false;
      this.botonRegresar = false;
      this.botonNuevo = true;
    }
  }
  nuevo() {
    const edithisto: Cxcedithisto = {
      TRNCOBRO_NRODOC: this.strnumfac,
      CLI_CODIGO: this.clicodigo,
      TRNHIS_FECHA: this.cambiarfechanull(new Date()), //this.datePipe.transform(new Date(), 'ddMMyyyy HH:mm'),
      TRNHIS_COMENTARIO: '',
      TRNHIS_SECUENCIAL: '',
      CLAVE: '1' + this.datePipe.transform(new Date(),'dd/MM/yyyy HH:mm:ss'),//this.datePipe.transform(new Date(), 'ddMMyyyy HH:mm')
      LINEA:0
    };
    const indi = this.cxctrnedithistService.cxctrnhisto.indexOf(this.cxxedithisSelected);
    if (indi === -1) {
      this.cxctrnedithistService.cxctrnhisto.push(edithisto);
      this.cxxedithisSelected = edithisto;
      this.aggrid.refreshaggrid(this.cxctrnedithistService.cxctrnhisto, this.defaultCxcTrnedithis.objeto);
      this.aggrid.gridApi.setFocusedCell(0, 'TRNHIS_COMENTARIO');
    } else {
      this.cxctrnedithistService.cxctrnhisto.splice(indi + 1, 0, edithisto);
      this.cxxedithisSelected = edithisto;
      this.aggrid.refreshaggridindex(this.cxctrnedithistService.cxctrnhisto, this.defaultCxcTrnedithis.objeto, indi);
      this.aggrid.gridApi.setFocusedCell(indi + 1, 'TRNHIS_COMENTARIO');
    }
  }
  eliminar() {

    // const intIndiceFila = this.cxctrnedithistService.cxctrnhisto.findIndex(edithis =>
    //   edithis.CLAVE === this.cxxedithisSelected.CLAVE);
    //   console.log('ind',intIndiceFila)
    // let item: Cxcedithisto;
    let intIndiceFila=0
    if(this.cxxedithisSelected.LINEA === 0){
        intIndiceFila = this.cxctrnedithistService.cxctrnhisto.findIndex(distri =>
          distri.CLAVE === this.cxxedithisSelected.CLAVE);
    }else{
      intIndiceFila = this.cxctrnedithistService.cxctrnhisto.findIndex(distri =>
        distri.TRNHIS_SECUENCIAL === this.cxxedithisSelected.TRNHIS_SECUENCIAL);
    }
    let item: Cxcedithisto;
    if (intIndiceFila !== -1) {
      item = this.cxctrnedithistService.cxctrnhisto[intIndiceFila];
    } else {
      this.messageService.add({
        key: 'edittrn',
        severity: 'error',
        summary: 'Error',
        detail: '! Seleccione una fila.... !'
      });
      return;
    }

    this.confirmationService.confirm({
      message: 'Está seguro de eliminar el registro?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'edittrnhis',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        if (item.TRNHIS_SECUENCIAL === '' || item.TRNHIS_SECUENCIAL === null || item.TRNHIS_SECUENCIAL === undefined) {
          this.cxctrnedithistService.cxctrnhisto.splice(intIndiceFila, 1);
          this.aggrid.refreshaggrid(this.cxctrnedithistService.cxctrnhisto, this.defaultCxcTrnedithis.objeto);
        } else {
          this.cxctrnedithistService.eliminarDetHis(item).subscribe(data => {
            this.auditoriagral.registrarAuditoria('CXC_TRNHISTORIAL', item.TRNCOBRO_NRODOC + '/' + item.CLI_CODIGO + '/' + item.TRNHIS_FECHA + '/' + item.TRNHIS_COMENTARIO, 'E',
              '', '01', '', '', '', '').subscribe();
            this.cxctrnedithistService.cxctrnhisto.splice(intIndiceFila, 1);
            this.aggrid.refreshaggrid(this.cxctrnedithistService.cxctrnhisto, this.defaultCxcTrnedithis.objeto);
          });
        }
        if(this.cxctrnedithistService.cxctrnhisto.length>0){
         
          this.cxxedithisSelected=this.cxctrnedithistService.cxctrnhisto[intIndiceFila-1];
        }
      },
      reject: () => {
      }
    });
  }
  async guardar() {
    let error = 0;
    this.spin=true;
    for (const his of this.cxctrnedithistService.cxctrnhisto) {
      if (his.TRNHIS_COMENTARIO === '') {
        this.messageService.add({
          key: 'edittrn',
          severity: 'error',
          summary: 'Error',
          detail: 'El comentario de una de las líneas está vacío.'
        });
        error++
        this.spin=false;
        return;
      }
    }
    if (error === 0) {
      await this.finalmenteguardar()
    }
  }
  async finalmenteguardar() {
    let intIndiceLista = 0;
    let item: Cxcedithisto;
    try {
      for (intIndiceLista = 0; intIndiceLista < this.cxctrnedithistService.cxctrnhisto.length; intIndiceLista++) {
        item = this.cxctrnedithistService.cxctrnhisto[intIndiceLista];
        if (item.TRNHIS_SECUENCIAL === '') { // Inserta un nuevo registro
          const data1 = await this.cxctrnedithistService.insertarDetHis(item)
          // registrarAuditoria
          this.auditoriagral.registrarAuditoria('CXC_TRNHISTORIAL', item.TRNCOBRO_NRODOC + '/' + item.CLI_CODIGO + '/' + item.TRNHIS_FECHA + '/' + item.TRNHIS_COMENTARIO, 'I',
            '', '01', '', '', '', '').subscribe();

        } else { // fin inserta información
          if (item.TRNHIS_SECUENCIAL !== '') { // actualiza información
            const data2 = await this.cxctrnedithistService.actuatarDetHis(item)
            this.auditoriagral.registrarAuditoria('CXC_TRNHISTORIAL', item.TRNCOBRO_NRODOC + '/' + item.CLI_CODIGO + '/' + item.TRNHIS_FECHA + '/' + item.TRNHIS_COMENTARIO, 'A',
              '', '01', '', '', '', '').subscribe();
          } // fin actualiza información
        }
      }
      this.messageService.add({
        key: 'edittrn',
        severity: 'success',
        summary: 'Información',
        detail: 'El comentario se guardó con éxito'
      });
      this.spin=false;
      await this.mostrarHistorial();
      this.botonesinicial();
      
    } catch (error) {
      this.spin=false;
      console.log(error)
    }
  }
  botonesinicial(){
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
  }
  bloquearbotones(){
    this.botonNuevo = true;
    this.botonGuardar = true;
    this.botonBorrar = true;
    this.botonRegresar = true;
  }
  async cambiobloc(evento){
      if(this.buscarallfac === true){
        this.bloquearbotones();
        await this.mostrarallHistorial();
      }
      if(this.buscarallfac === false){
        this.botonesinicial()
        await this.mostrarHistorial()
      }
  }
  async mostrarallHistorial() {
    this.spin=true;
    try {
      const data = await this.cxctrnedithistService.listaralldatos(this.clicodigo);
    this.cxctrnedithistService.cxctrnhisto = data;
    for (const his of this.cxctrnedithistService.cxctrnhisto) {
      his.TRNHIS_FECHA = this.cambiarfechanull(his.TRNHIS_FECHA)//this.datePipe.transform(his.TRNHIS_FECHA, 'dd/MM/yyyy HH:mm');
      his.CLAVE = 1 + this.cambiarfechanull(his.TRNHIS_FECHA)//this.datePipe.transform(his.TRNHIS_FECHA, 'dd/MM/yyyy HH:mm');
      this.cxxedithisSelected =his
    }
    this.aggrid.refreshColumnDefs();
    } catch (error) {
      this.spin=false
    }
    this.spin=false
  }
}

