import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Observable} from 'rxjs';
import {Commaeservicio} from '../cominterfaces/commaeservicio';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class CommaeservicioService {
  
  
  public way: string;
  private _commaeservicio: Commaeservicio[];
  
  get commaeservicio(): Commaeservicio[] {
    return this._commaeservicio;
  }
  
  set commaeservicio(value: Commaeservicio[]) {
    this._commaeservicio = value;
  }
  constructor(private http: HttpClient,
              private confIniciales: ConfInicialesService,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario) {
   
    this.way = sessionStorage.getItem('way');
  }

  getServicio(): Observable<any[]> {
    
    return this.http.post<any[]>(this.way + '/compras/serviciocompras/bw2r99mqijcmcri', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  actualizarServicio(servicio: Commaeservicio): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/compras/serviciocompras/dn8iecdcbwm0kph', {      
      elementos: {
        p_SERCOM_codigo: servicio.SERCOM_CODIGO,
        p_COM_codigo: '01',
        p_SERCOM_descripcion: servicio.SERCOM_DESCRIPCION,
        p_CON_codigo: servicio.CON_CODIGO,
        p_SERCOM_precio: Number(servicio.SERCOM_PRECIO),
        p_SERCOM_tributaiva: servicio.SERCOM_TRIBUTAIVA,
        p_CEN_codigo: servicio.CEN_CODIGO,
        p_SER_formsricom: servicio.SER_FORMSRICOM,
        p_CON_codigo2: servicio.CON_CODIGO2,
        p_SERCOM_basenoobjiva: servicio.SERCOM_BASENOOBJIVA,
        p_SERCOM_PORCEIVA:servicio.SERCOM_PORCEIVA,
        p_TARIIVA_CODIGO:servicio.TARIIVA_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }

  insertarServicio(servicio: Commaeservicio): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/compras/serviciocompras/kjrurodb4j7rdyf', {      
      elementos: {
        p_SERCOM_codigo: servicio.SERCOM_CODIGO,
        p_COM_codigo: '01',
        p_SERCOM_descripcion: servicio.SERCOM_DESCRIPCION,
        p_CON_codigo: servicio.CON_CODIGO,
        p_SERCOM_precio: Number(servicio.SERCOM_PRECIO),
        p_SERCOM_tributaiva: servicio.SERCOM_TRIBUTAIVA,
        p_CEN_codigo: servicio.CEN_CODIGO,
        p_SER_formsricom: servicio.SER_FORMSRICOM,
        p_CON_codigo2: servicio.CON_CODIGO2,
        p_SERCOM_basenoobjiva: servicio.SERCOM_BASENOOBJIVA,
        p_SERCOM_PORCEIVA:servicio.SERCOM_PORCEIVA,
        p_TARIIVA_CODIGO:servicio.TARIIVA_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }
  eliminarServicio(servicio: Commaeservicio): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/compras/serviciocompras/gu84hnq19m3yjhy', {      
      elementos: {
        p_SERCOM_codigo: servicio.SERCOM_CODIGO,
        p_COM_codigo: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  erServicioCod(strSERCOM_CODIGO){
    return this.http.post<any[]>(this.way + '/compras/serviciocompras/r0i9nfq2w9ibe1g', {      
      elementos: {
        SERCOM_CODIGO: strSERCOM_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }
 //CDPJ
 encontrarArtCntaySer(strArtCodigo, strtipo) {
  return this.http.post<any[]>(this.way + '/ventas/facturas/75d98bf76adab8000000', {
    elementos: {
      ArtCodigo: strArtCodigo,
      strTIPO: strtipo
    }
  }, this.confIniciales.httpOptions()).toPromise();
}
obtenerClaveIva(): Observable<any> {
  return this.http.post<any>(this.way + '/compras/serviciocompras/c2faebbb6caac00', {
    elementos: {},
  }, this.confIniciales.httpOptions());
}
actualizarporceiva(tariivacod,tariivaporce) {
  return this.http.post<any[]>(this.way + '/compras/serviciocompras/299df2ab2', {
    elementos: {
  p_tariiva_codigo: tariivacod,
  p_tariiva_porcentaje: tariivaporce
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
//CDPJ

}
