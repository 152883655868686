import { Component, OnInit, ViewChild } from '@angular/core';
import { UsuarioDescargas } from '../../usuarioDescargas';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { EletrndocumentosService } from '../descargasServicios/eletrndocumentos.service';
import { EleTrnDocs } from '../descargasInterfaces/eletrndocumentos';
import { DatePipe } from '@angular/common';
import { Table } from 'primeng/table';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Menu } from 'primeng';
/*import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as XLSXStyle from 'xlsx-style';
import { catchError, map, tap } from 'rxjs/operators';*/

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-descargas-home',
  templateUrl: './descargas-home.component.html',
  styleUrls: ['./descargas-home.component.css']
})
export class DescargasHomeComponent implements OnInit {
  public test = '';
  index: number;


  items: MenuItem[];

  newpsw: string;
  cmbdocumentos: any[];
  cmbdocumentosaux: any[];
  tipodoc: string;
  clave: string;
  numdoc: string;
  proveedor: string;
  fecha: string;
  fechah: string;
  currentpsw: string;
  newspsw: string;
  documentos: EleTrnDocs[];
  selectedDocumentos: EleTrnDocs[];
  documentosBusqueda: EleTrnDocs[];
  documentoSeleccionado: EleTrnDocs;

  displayDialogAcciones: boolean;
  itemMenuXml: MenuItem[];
  itemMenuPdf: MenuItem[];
  // @ts-ignore
  @ViewChild('dt') table: Table;

  constructor(public descargasServicio: EletrndocumentosService,
    public usuarioDescargas: UsuarioDescargas,
    public route: Router, private datePipe: DatePipe, private messageService: MessageService,
  ) {
    this.documentos = [];
    this.documentosBusqueda = [];
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  ngOnInit() {
    this.index = 0;
    this.usuarioDescargas.loadStorage();

    if (this.usuarioDescargas.login === false) {
      this.route.navigate(['login']);
    } else {
      this.iniciarMenu();
      this.descargasServicio.eletrndocs = [];
      this.newpsw = '';
      this.newspsw = '';
      this.currentpsw = '';
      this.clave = '';
      this.numdoc = '';
      this.proveedor = '';
      this.fecha = '';
      this.fechah = '';
      this.tipodoc = '';
      this.cargarComprobantes();
      this.llenarCombos();
    }

    this.itemMenuXml = [
      { label: 'Ver Xml' },
      { label: 'Descargar Xml' },
    ];
    this.itemMenuPdf = [
      { label: 'Ver Pdf' },
      { label: 'Descargar Pdf' },
    ];
    this.displayDialogAcciones = false;
  }


  iniciarMenu() {
    this.items = [
      { label: 'Mis comprobantes', id: 'comprobantes' },
      { label: 'Búsqueda de Comprobante', id: 'busqcomprobantes' },
      { label: 'Cambiar contraseña', id: 'change' },
      { label: 'Salir', id: 'exit' },
    ];

  }

  cargarComprobantes() {
    this.descargasServicio.getDocumentos(this.usuarioDescargas).subscribe(resp => {
      var docs: any = resp;
      docs.map(aux => {
        aux.TRNDOC_FECHA = this.datePipe.transform(aux.TRNDOC_FECHA, 'dd/MM/yyyy');
        aux.TRNDOC_NUMDOC = aux.TRNDOC_NUMDOC.substr(6, 9);
        aux.TRNDOC_TIPODES = aux.TRNDOC_TIPODES.toLowerCase();
        aux.TRNDOC_FECHA = aux.TRNDOC_FECHA.toString();
        aux.TRNDOC_NUMDOC = aux.TRNDOC_NUMDOC.toString();
        aux.TRNDOC_PRO_RUCIDE = aux.TRNDOC_PRO_RUCIDE.toString();
        aux.TRNDOC_CLAVEACCESO_FE = aux.TRNDOC_CLAVEACCESO_FE.toString();

      });
      this.documentos = docs;
    });

  }

  llenarCombos() {
    this.cmbdocumentosaux = [{ codigo: '', name: '(TODOS)' },
    { codigo: 'fcn', name: 'FÁCTURA' },
    { codigo: 'lc', name: 'LIQUIDACIÓN DE COMPRA' },
    { codigo: 'nc', name: 'NOTA DE CRÉDITO' },
    { codigo: 'nd', name: 'NOTA DE DÉBITO' },
    { codigo: 'gr', name: 'GUIA DE REMISIÓN' },
    { codigo: 'cr', name: 'COMPROBANTE DE RETENCIÓN' }];

  }

  llenarcmbDocs() {

    this.cmbdocumentos = this.cmbdocumentosaux;

  }

  busqdoc(id) {
    this.tipodoc = id.value.codigo;

  }

  seleccionarTabs(opcion) {

    switch (opcion.id) {
      case 'comprobantes':
        this.cargarComprobantes();

        setTimeout(() => {
          this.index = 0;
        }, 500);


        break;
      case 'busqcomprobantes':
        this.index = 1;
        this.documentosBusqueda = [];
        break;
      case 'change':
        this.index = 2;

        break;
      case 'exit':
        this.salir();

        break;
    }
  }

  salir() {
    this.route.navigate(['login']);
  }

  consultarComprobantes() {
    this.descargasServicio.consultaComprobantes(this.usuarioDescargas.CLI_RUCIDE,
      this.clave, this.numdoc, this.proveedor, this.datePipe.transform(this.fecha, 'dd/MM/yyyy'),
      this.datePipe.transform(this.fechah, 'dd/MM/yyyy'), this.tipodoc).subscribe(res => {
        const docs: any = res;
        console.log(docs);
        docs.map(aux => {
          aux.TRNDOC_FECHA = this.datePipe.transform(aux.TRNDOC_FECHA, 'dd/MM/yyyy');
          aux.TRNDOC_NUMDOC = aux.TRNDOC_NUMDOC.substr(6, 9);
          aux.TRNDOC_TIPODES = aux.TRNDOC_TIPODES.toLowerCase();
          aux.TRNDOC_FECHA = aux.TRNDOC_FECHA.toString();
          aux.TRNDOC_NUMDOC = aux.TRNDOC_NUMDOC.toString();
          aux.TRNDOC_PRO_RUCIDE = aux.TRNDOC_PRO_RUCIDE.toString();
          aux.TRNDOC_CLAVEACCESO_FE = aux.TRNDOC_CLAVEACCESO_FE.toString();
        });
        this.documentosBusqueda = docs;
      });
  }

  changePSW() {
    let psw = '';


    this.descargasServicio.getPass(this.usuarioDescargas).subscribe(res => {
      psw = res[0].PSW;
      if (this.newpsw === '' || this.newspsw === '' || this.currentpsw === '') {
        this.messageService.add({
          key: 'descargas',
          severity: 'error',
          summary: 'ERROR',
          detail: 'Debe ingresar todos los campos solicitados'
        });
      } else if (this.newspsw !== this.newpsw) {
        this.messageService.add({
          key: 'descargas',
          severity: 'error',
          summary: 'ERROR',
          detail: 'Las contraseñas deben coincidir'
        });
      } else if (this.newpsw.length < 6) {
        this.messageService.add({
          key: 'descargas',
          severity: 'error',
          summary: 'ERROR',
          detail: 'La longitud de la contraseña debe ser mayor a 6 digitos.'
        });
      } else if (psw !== this.currentpsw) {
        this.messageService.add({
          key: 'descargas',
          severity: 'error',
          summary: 'ERROR',
          detail: 'Ingrese la contraseña actual correctamente.'
        });
      } else {
        this.descargasServicio.actualizarPass(this.usuarioDescargas, this.newpsw.toString()).subscribe(() => {
          this.messageService.add({
            key: 'descargas',
            severity: 'success',
            summary: 'Exito',
            detail: 'Se actualico la contraseña correctamente.'
          });
        });
      }
    });
  }

  accionContextMenuXml(event, documentos) {
    if (event.target.outerText === this.itemMenuXml[0].label) {
      this.verXML(documentos);
    }
    if (event.target.outerText === this.itemMenuXml[1].label) {
      this.descargarXML(documentos);
    }
  }

  accionContextMenuPdf(event, documentos) {
    if (event.target.outerText === this.itemMenuPdf[0].label) {
      this.verPDF(documentos);
    }
    if (event.target.outerText === this.itemMenuPdf[1].label) {
      this.descargPdf(documentos);
    }
  }

  verXML(documentos) {
    const codigo = this.getCodAutorizacion(documentos.TRNDOC_UBICAARCH);
    this.descargasServicio.obtenerXML(codigo).subscribe(data => {
      const file = new Blob([data], { type: 'application/xml' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }, () => {
      alert('No se encontro el archivo XML');
    });
  }

  descargarXML(documentos) {
    const codigo = this.getCodAutorizacion(documentos.TRNDOC_UBICAARCH);
    this.descargasServicio.obtenerXML(codigo).subscribe(data => {
      const file = new Blob([data], { type: 'application/xml' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(file);
      link.download = codigo;
      link.click();
    }, () => {
      alert('No se encontro el archivo XML');
    });
  }

  acciones(documentos) {
    this.documentoSeleccionado = documentos;
  }

  verPDF(documentos) {
    if (documentos.length === 0) {
      alert('Debe seleccionar un registro');
    } else {

      if (documentos.TRNDOC_ESTADO === 'NT' || documentos.TRNDOC_ESTADO === 'AT') {
        const codigo = this.getCodAutorizacion(documentos.TRNDOC_UBICAARCH);
        this.descargasServicio.obtenerDataXML(codigo).subscribe(async data => {
          console.log(data);
          let dd: any;
          if (documentos.TRNDOC_TIPO === '01') {
            dd = await this.descargasServicio.armarPDFFactura(data,documentos.TRNDOC_PRO_RUCIDE);
          }
          if (documentos.TRNDOC_TIPO === '03') {
            dd = await this.descargasServicio.armarPDFLiquidacion(data,documentos.TRNDOC_PRO_RUCIDE);
          }
          if (documentos.TRNDOC_TIPO === '04') {
            dd = await this.descargasServicio.armarPDFNotaCredito(data,documentos.TRNDOC_PRO_RUCIDE);
          }
          if (documentos.TRNDOC_TIPO === '05') {
            dd = await this.descargasServicio.armarPDFNotaDebito(data,documentos.TRNDOC_PRO_RUCIDE);
          }
          if (documentos.TRNDOC_TIPO === '06') {
            dd = await this.descargasServicio.armarPDFGuiaRemision(data,documentos.TRNDOC_PRO_RUCIDE);
          }
          if (documentos.TRNDOC_TIPO === '07') {
            dd = await this.descargasServicio.armarPDFRetebcion(data,documentos.TRNDOC_PRO_RUCIDE);
          }
          pdfMake.createPdf(dd).open();
        }, error => {
          console.log(error);
          alert('No se encontró el archivo XML');
        });
      } else {
        alert('El documento debe estar Notificado o Autorizado');
      }
    }
  }

  descargPdf(documentos) {
    if (documentos.length === 0) {
      alert('Debe seleccionar un registro');
    } else {
      if (documentos.TRNDOC_ESTADO === 'NT' || documentos.TRNDOC_ESTADO === 'AT') {
        const codigo = this.getCodAutorizacion(documentos.TRNDOC_UBICAARCH);
        this.descargasServicio.obtenerDataXML(codigo).subscribe(async data => {
          console.log(data);
          let dd: any;
          if (documentos.TRNDOC_TIPO === '01') {
            dd = await this.descargasServicio.armarPDFFactura(data,documentos.TRNDOC_PRO_RUCIDE);
          }
          if (documentos.TRNDOC_TIPO === '03') {
            dd = await this.descargasServicio.armarPDFLiquidacion(data,documentos.TRNDOC_PRO_RUCIDE);
          }
          if (documentos.TRNDOC_TIPO === '04') {
            dd = await this.descargasServicio.armarPDFNotaCredito(data,documentos.TRNDOC_PRO_RUCIDE);
          }
          if (documentos.TRNDOC_TIPO === '05') {
            dd = await this.descargasServicio.armarPDFNotaDebito(data,documentos.TRNDOC_PRO_RUCIDE);
          }
          if (documentos.TRNDOC_TIPO === '06') {
            dd = await this.descargasServicio.armarPDFGuiaRemision(data,documentos.TRNDOC_PRO_RUCIDE);
          }
          if (documentos.TRNDOC_TIPO === '07') {
            dd = await this.descargasServicio.armarPDFRetebcion(data,documentos.TRNDOC_PRO_RUCIDE);
          }
          pdfMake.createPdf(dd).download(codigo.substr(0, 49));
        }, error => {
          console.log(error);
          alert('No se encontró el archivo XML');
        });
      } else {
        alert('El documento debe estar Notificado o Autorizado');
      }
    }
  }

  getCodAutorizacion(txt): string {
    return txt.substr(-53);
  }

  exportarExcel() {
  }

  /*
  exportarExcel() {
    
    const datostabla: any = [];
    // this.gridApi.forEachNodeAfterFilter(function (rowNode, rowindex) {
    
    for (const rowNode of this.documentos) {
      const obj: any = {};
      obj.Documento = rowNode.TRNDOC_NUMDOC;
      obj.Tipo = rowNode.TRNDOC_TIPO;
      obj.Estado = rowNode.TRNDOC_ESTADO;
      obj.Resultado = rowNode.TRNDOC_RESULTADO;
      obj.Ubicacion = rowNode.TRNDOC_UBICAARCH;
      obj.Emision = rowNode.TRNDOC_FECHA;
      obj['Clave Acceso'] = rowNode.TRNDOC_CLAVEACCESO_FE;
      obj.Autorizacion = rowNode.TRNDOC_FECHAAUT_FE;
      obj.Ambiente = rowNode.TRNDOC_TIPOAMB_FE;
      obj.Beneficiario = rowNode.TRNDOC_CLI_RUCIDE;
      datostabla.push(obj);
    }
    // });
    
    console.log(datostabla);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(datostabla);
    // this.wrapAndCenterCell(worksheet.B2);
    const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    // Use XLSXStyle instead of XLSX write function which property writes cell styles.
    const excelBuffer: any = XLSXStyle.write(workbook, {bookType: 'xlsx', type: 'buffer'});
    this.saveAsExcelFile(excelBuffer, 'factelectronica');
  }
  
  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
  */

}
