import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {DatePipe} from '@angular/common';
import {MessageService} from 'primeng';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {InvReservas, InvTrnReservas} from '../invinterfaces/invtrnreservas';
import {Observable} from 'rxjs';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class InvtrnreservasService {
  
  public detalleReservas: InvTrnReservas[][] = [];
  public detalleReservaTransfer: InvReservas[] = [];
  
 // private url: string;
  public way: string;
  constructor(
    private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService,
    private init: NuevoComponentService,
    private datePipe: DatePipe, private messageService: MessageService, private errorService: ErroresBaseDatosService,
    private auditoriagral: AuditoriagralService,
    private message: MessageService
  ) {
   // this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }
 
  consultarReserva(opcion, bodCodigo) {

    return this.http.post<any[]>(this.way + '/ventas/reservas/gc7kk5cxj9dpklj', {      
      elementos: {
        p_opcion: opcion,
        p_bod_codigo_1: bodCodigo,
        p_com_codigo_2: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();;
  }
  
 

  
  erNuevareserva(){
    return this.http.post<any[]>(this.way + '/ventas/reservas/rncjiybl0ha7y80', {      
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erconfiguracionClave(){
    return this.http.post<any[]>(this.way + '/ventas/reservas/wh7mllhpvxbfjn0', {      
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  guardarReserva(item: InvTrnReservas) {
    
    return this.http.post<any[]>(this.way + '/ventas/reservas/565ceab6d5771c', {      
      elementos: {
        p_COM_codigo_1: '01',
        p_TRNRES_usuario_2: item.TRNRES_USUARIO,
        p_BOD_codigo_3: item.BOD_CODIGO,
        p_ART_codigo_4: item.ART_CODIGO,
        p_ART_unidad: item.ART_UNIDAD,
        p_TRNRES_cantidad_5: Number(item.TRNRES_CANTIDAD),
        p_TRNRES_fecha_6: item.TRNRES_FECHA,
        p_TRNRES_caduca_7: item.TRNRES_CADUCA,
        p_TRNRES_entregado_8: item.TRNRES_ENTREGADO,
        p_TRNRES_observacion_9: item.TRNRES_OBSERVACION,
        p_BOD_codigodes_3: item.BOD_CODIGODES
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  actualizarReserva(item: InvTrnReservas) {

    return this.http.post<any[]>(this.way + '/ventas/reservas/m00rjovvbh16mz1', {      
      elementos: {
        p_TRNRES_numero: item.TRNRES_NUMERO,
        p_COM_codigo_1: '01',
        p_ART_codigo_4: item.ART_CODIGO,
        p_ART_unidad: item.ART_UNIDAD,
        p_TRNRES_cantidad_5: Number(item.TRNRES_CANTIDAD),
        p_TRNRES_observacion_9: item.TRNRES_OBSERVACION,
        p_BOD_codigodes: item.BOD_CODIGODES
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarReserva(item: InvTrnReservas) {
 
    return this.http.post<any[]>(this.way + '/ventas/reservas/m3hyqjtql695fg3', {      
      elementos: {
        p_TRNRES_numero: item.TRNRES_NUMERO,
        p_COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  existenciaActualArticulo(item: InvTrnReservas): Observable<any[]> {
    
    const fecha = new Date();
    const hora = fecha.getHours();
    const min = fecha.getMinutes();
    const horaAct = hora + ':' + min;
    
    item.TRNRES_FECHA = this.datePipe.transform(fecha, 'dd/MM/yyyy');

    
    
    return this.http.post<any[]>(this.way + '/ventas/reservas/bnae7s7oqdmbrla', {      
      elementos: {
        ART_CODIGO: item.ART_CODIGO,
        BOD_CODIGO:  item.BOD_CODIGO,
        TRNRES_FECHA: item.TRNRES_FECHA,
        horaact:horaAct
      },
    }, this.confIniciales.httpOptions());
    
  }
  
  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'invreserva',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
  

  erBuscarBodega(strparametro){
    return this.http.post<any[]>(this.way + '/ventas/reservas/zz41x66o1cojlzt', {      
      elementos: {
        parametro:strparametro
      },
    }, this.confIniciales.httpOptions());
  }
  reservasTranfer(bodCodigo, bodDestino, caduca) {
    
   
    return this.http.post<any>(this.way + '/ventas/reservas/0vqfxprv284lqqs', {
      elementos: {
        p_bod_codigo_1: bodCodigo,
        p_bod_destino_2: bodDestino,
        p_trnres_caduca_3: caduca,
        p_com_codigo_2: '01'
      },
     
    }, this.confIniciales.httpOptions()
  ).toPromise();
  }
  
}
