<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="maepro"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="MaePro" title="Confirmar Acción"
                 appendTo="body"></p-confirmDialog>

<div class="p-grid" style="width: 99%">
  <div class="p-col-12">
    <app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
                     [botonRegresar]="btnRegresar" [barraBotonesCRUD]="false"
                     [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true"
                     (signal)="manejarSenales($event)"></app-invmaebarra>
  </div>

</div>
<div style="overflow: hidden" class="p-col-12">
  <p-tabView>
    <p-tabPanel header="Promociones">
      <app-aggridsaci [width]=""
                      [height]="largo"
                      [enableSorting]="true"
                      [enableFilter]="true"
                      [rowData]="ServicioPromo.venmaepromo"
                      [frameworkComponents]="frameworkComponents"
                      [animateRows]="true"
                      [rowSelection]="rowSelection"
                      [columnDefs]="columnDefsPromo"
                      [defaultColDef]="defaultColDefPromo"
                      [rowStyle]="rowStyle"
                      [singleClickEdit]="false"
                      [botones]="false"
                      (selected)="selectedPromo($event)"
                      (cambios)="cambio($event)"

      ></app-aggridsaci>
    </p-tabPanel>
  </p-tabView>
</div>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [responsive]="true"
		  [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSenales($event)"></app-busquedadlg>
</p-dialog>