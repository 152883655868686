<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="reporte">
</p-toast>
<div class="divgrups">
	<div class="p-grid">
		<div class="p-col-4">
			<h1>{{titulo}}</h1>
		</div>
		<div class="p-col-5">
		</div>
		<div class="p-col-1" style="margin-top: 10px">
			<button *ngIf="spin===false && datawarehouse!=='Data Warehouse'" class="medium" style="background-image: url(../../assets/images/iconos/023-pdf-1.png)"
				(click)="descargarReporte('parametros')">
			</button>
			<button *ngIf="spin===true && datawarehouse!=='Data Warehouse'" class="medium" style="background-image: url(../../assets/images/iconos/023-pdf-1-d.png)">
			</button>
			<button *ngIf="spin===false && datawarehouse==='Data Warehouse'" class="medium" style="background-image: url(../../assets/images/iconos/visto.png)"
			(click)="descargarReporte('parametrosdatawarehouse')">
			</button>
			<button *ngIf="spin===true && datawarehouse==='Data Warehouse'" class="medium" style="background-image: url(../../assets/images/iconos/visto-d.png)">
			</button>
		</div>
		<div class="p-col-1" style="margin-top: 10px">
			<button *ngIf="spin===false && datawarehouse!=='Data Warehouse'" class="medium" style="background-image: url(../../assets/images/iconos/077-xls_2.png)"
				(click)="descargarReporte('parametrosexcel')">
			</button>
			<button *ngIf="spin===true && datawarehouse!=='Data Warehouse'" class="medium" style="background-image: url(../../assets/images/iconos/077-xls_d.png)">
			</button>
			<button *ngIf="spin===false && datawarehouse==='Data Warehouse'" class="medium" style="background-image: url(../../assets/images/iconos/exportar.png)"
			(click)="descargarReporte('exportdatawarehouse')">
			</button>
			<button *ngIf="spin===true && datawarehouse==='Data Warehouse'" class="medium" style="background-image: url(../../assets/images/iconos/exportar_d.png)">
			</button>
		</div>
		<div class="p-col-1" style="margin-top: 10px">
			<button class="medium" style="background-image: url(../../assets/images/iconos/salir.png);"
				(click)="cerrar()"></button>
		</div>
		<div class="p-col-4">
			<div class="p-grid">
				<div class="p-col-12 fondo">
					<div style="height: 350px">
						<p-table [value]="reporte" selectionMode="single" scrollable="true" scrollHeight="300px">
							<ng-template pTemplate="colgroup" let-columns>
								<colgroup>
									<col style="width:100px">
									NOMBRE
								</colgroup>
							</ng-template>
							<ng-template pTemplate="header" let-columns>
								<tr>
									<th>NOMBRE</th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-reporte let-rowData>
								<tr [pSelectableRow]="rowData || reporteSeleccionado"
									(click)="mandarinforeporte(rowData)">
									<td *ngIf="reporte !== undefined">{{reporte.RL_NOMBRESUBSTR}}</td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</div>
			</div>
		</div>
		<div class="p-col-8">
			<p-tabView [(activeIndex)]="index">
				<p-tabPanel header="Criterios">
					<div style="height: 300px">
						<p-table [value]="etiquetas2">
							<ng-template pTemplate="header" let-columns>
								<tr class="ui-table-scrollable-wrapper">
									<th style="width: 150px">Etiqueta</th>
									<th style="width: 260px">Desde</th>
									<th style="width: 50px"></th>
									<th style="width: 260px">Hasta</th>
									<th style="width: 50px"></th>
								</tr>
							</ng-template>

							<ng-template let-etiqueta pTemplate="body" let-rowData let-rowIndex="rowIndex">
								<tr (click)="seleccion(rowData)">
									<td>{{rowData.label}}</td>
									<td pEditableColumn>
										<p-cellEditor *ngIf="rowData.boton1 === 'I'">
											<ng-template pTemplate="input">
												<div style="text-align: center">
													<input style="width: 80%" type="number" pInputText
														placeholder="Ingrese">
												</div>
											</ng-template>
											<ng-template pTemplate="output">
												{{rowData.campo1}}
											</ng-template>
										</p-cellEditor>
										<p-cellEditor *ngIf="rowData.boton1 === 'S'">
											<ng-template pTemplate="input">
												<div style="text-align: center">
													<input style="width: 80%" type="text" [(ngModel)]="rowData.campo1"
														pInputText placeholder="Ingrese">
												</div>
											</ng-template>
											<ng-template pTemplate="output">
												{{rowData.campo1}}
											</ng-template>
										</p-cellEditor>
										<p-cellEditor *ngIf="rowData.boton1 === 'SC'">
											<ng-template pTemplate="input">
												<div style="text-align: center">
													<input style="width: 80%" type="text" [(ngModel)]="rowData.campo1"
														pInputText placeholder="Ingrese">
												</div>
											</ng-template>
											<ng-template pTemplate="output">
												{{rowData.campo1}}
											</ng-template>
										</p-cellEditor>
										<p-cellEditor *ngIf="rowData.boton1 === 'IC'">
											<ng-template pTemplate="input">
												<div style="text-align: center">
													<input style="width: 80%" type="text" [(ngModel)]="rowData.campo1"
														pInputText placeholder="Ingrese">
												</div>
											</ng-template>
											<ng-template pTemplate="output">
												{{rowData.campo1}}
											</ng-template>
										</p-cellEditor>
										<label *ngIf="rowData.boton1 === 'DC'">{{rowData.campo1}}</label>
										<div style="text-align: center">
											<p-calendar [inputStyle]="{width: '92%'}" [style]="{width: '92%'}"
												dateFormat="dd/mm/yy" *ngIf="rowData.boton1 === 'D'"
												[(ngModel)]="rowData.campo1" dataType="string" [monthNavigator]="true"
												[yearNavigator]="true" yearRange="2000:2030"></p-calendar>
										</div>
									</td>
									<td class="text-center">
										<button
											*ngIf="rowData.boton1 === 'DC'||rowData.boton1 === 'IC' || rowData.boton1 === 'SC'"
											(click)="busquedadatos(rowData, rowData.boton1)"
											style="background-image: url(../../assets/images/iconos/buscar.png); margin-left: -3px"></button>

									</td>
									<td pEditableColumn>
										<p-cellEditor *ngIf="rowData.boton2=== 'I2'">
											<ng-template pTemplate="input">
												<div style="text-align: center">
													<input style="width: 80%" type="number" [(ngModel)]="rowData.campo2"
														pInputText placeholder="Ingrese">
												</div>
											</ng-template>
											<ng-template pTemplate="output">
												{{rowData.campo2}}
											</ng-template>
										</p-cellEditor>
										<p-cellEditor *ngIf="rowData.boton2 === 'S2'">
											<ng-template pTemplate="input">
												<div style="text-align: center">
													<input style="width: 80%" type="text" [(ngModel)]="rowData.campo2"
														pInputText placeholder="Ingrese">
												</div>
											</ng-template>
											<ng-template pTemplate="output">
												{{rowData.campo2}}
											</ng-template>
										</p-cellEditor>
										<p-cellEditor *ngIf="rowData.boton2 === 'SC2'">
											<ng-template pTemplate="input">
												<div style="text-align: center">
													<input style="width: 80%" type="text" [(ngModel)]="rowData.campo2"
														pInputText placeholder="Ingrese">
												</div>
											</ng-template>
											<ng-template pTemplate="output">
												{{rowData.campo2}}
											</ng-template>
										</p-cellEditor>
										<p-cellEditor *ngIf="rowData.boton2 === 'IC2'">
											<ng-template pTemplate="input">
												<div style="text-align: center">
													<input style="width: 80%" type="text" [(ngModel)]="rowData.campo2"
														pInputText placeholder="Ingrese">
												</div>
											</ng-template>
											<ng-template pTemplate="output">
												{{rowData.campo2}}
											</ng-template>
										</p-cellEditor>
										<label *ngIf="rowData.boton2 === 'DC2'">{{rowData.campo2}}</label>
										<div style="text-align: center">
											<p-calendar [inputStyle]="{width: '92%'}" [style]="{width: '92%'}"
												dateFormat="dd/mm/yy" *ngIf="rowData.boton2 === 'D2'"
												[(ngModel)]="rowData.campo2" dataType="string" [monthNavigator]="true"
												[yearNavigator]="true" yearRange="2000:2030"></p-calendar>
										</div>
									</td>
									<td class="text-center">
										<button
											*ngIf="rowData.boton2 === 'DC2'||rowData.boton2 === 'IC2' || rowData.boton2 === 'SC2'"
											(click)="busquedadatos(rowData, rowData.boton2)"
											style="background-image: url(../../assets/images/iconos/buscar.png); margin-left: -3px"></button>
									</td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</p-tabPanel>
				<p-tabPanel header="Parametros">
					<div style="height: 300px">
						<p-table [value]="etiquetas">
							<ng-template pTemplate="header" let-columns>
								<tr class="ui-table-scrollable-wrapper">
									<th style="width: 150px">Etiqueta</th>
									<th style="width: 260px">Desde</th>
									<th style="width: 50px"></th>
								</tr>
							</ng-template>

							<ng-template let-etiqueta pTemplate="body" let-rowData let-rowIndex="rowIndex">
								<tr (click)="seleccion(rowData)">
									<td>{{rowData.label}}</td>
									<td pEditableColumn>
										<p-cellEditor *ngIf="rowData.boton1 === 'I'">
											<ng-template pTemplate="input">
												<div style="text-align: center">
													<input style="width: 80%" type="number" [(ngModel)]="rowData.campo1"
														pInputText placeholder="Ingrese">
												</div>
											</ng-template>
											<ng-template pTemplate="output">
												{{rowData.campo1}}
											</ng-template>
										</p-cellEditor>
										<p-cellEditor *ngIf="rowData.boton1 === 'S'">
											<ng-template pTemplate="input">
												<div style="text-align: center">
													<input style="width: 80%" type="text" [(ngModel)]="rowData.campo1"
														pInputText placeholder="Ingrese">
												</div>
											</ng-template>
											<ng-template pTemplate="output">
												{{rowData.campo1}}
											</ng-template>
										</p-cellEditor>
										<p-cellEditor *ngIf="rowData.boton1 === 'SC'">
											<ng-template pTemplate="input">
												<div style="text-align: center">
													<input style="width: 80%" type="text" [(ngModel)]="rowData.campo1"
														pInputText placeholder="Ingrese">
												</div>
											</ng-template>
											<ng-template pTemplate="output">
												{{rowData.campo1}}
											</ng-template>
										</p-cellEditor>
										<p-cellEditor *ngIf="rowData.boton1 === 'IC'">
											<ng-template pTemplate="input">
												<div style="text-align: center">
													<input style="width: 80%" type="number" [(ngModel)]="rowData.campo1"
														pInputText placeholder="Ingrese">
												</div>
											</ng-template>
											<ng-template pTemplate="output">
												{{rowData.campo1}}
											</ng-template>
										</p-cellEditor>
										<label *ngIf="rowData.boton1 === 'DC'">{{rowData.campo1}}</label>
										<div style="text-align: center">
											<p-calendar [inputStyle]="{width: '92%'}" [style]="{width: '92%'}"
												dateFormat="dd/mm/yy" *ngIf="rowData.boton1 === 'D'"
												[(ngModel)]="rowData.campo1" dataType="string" [monthNavigator]="true"
												[yearNavigator]="true" yearRange="2000:2030"></p-calendar>
										</div>
									</td>
									<td style="justify-content: center">
										<button
											*ngIf="rowData.boton1 === 'DC'||rowData.boton1 === 'IC' || rowData.boton1 === 'SC'"
											(click)="busquedadatos(rowData, rowData.boton1)"
											style="background-image: url(../../assets/images/iconos/buscar.png)"></button>
									</td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</p-tabPanel>
			</p-tabView>
		</div>
		<div class="p-col-12">
			<p-messages [(value)]="msgs"></p-messages>
		</div>
	</div>
</div>

<p-dialog header="Busqueda" [(visible)]="displayDialogBus" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '70vw', height: '70vh'}" (onHide)="salirBus()" appendTo="body">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloBus" (opcionSeleccionada)="recibirParam($event)">
	</app-invbusqueda>
</p-dialog>


<p-dialog header="Búsqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloBus" (opcionSeleccionada)="recibirParam($event)"
		[tabla]="tabla" [where]="where" [busquedaParams]="busquedacampos"></app-busquedadlg>
</p-dialog>

<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
    <p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
    </p-progressSpinner>
</div>