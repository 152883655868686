<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="cxptrncobro"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarcxptrncobro" title="Pregunta" appendTo="body">
</p-confirmDialog>
<div class="layout-config">
	<div class="layout-config-content-wrapper">
		<a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones()">
			<i class="pi pi-bars" style="cursor: pointer"></i>
		</a>
		<p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
			[dismissible]="true" [showCloseIcon]="false">
			<br>
			<br>
			<br>
			<p-tabView>
				<p-tabPanel header="Acciones">
					<div class="p-grid">
						<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 200px)'}">
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="activarAnulado()"
									class="archivoOpc">Activar Documento Anulado</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="datosAnexos()"
									class="archivoOpc">Datos Anexos</span>
							</div>
						</p-scrollPanel>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-sidebar>
	</div>
</div>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra (signal)="manejarSenales($event)" [barraBotones3]="barraBotones2" [barraBotonesAsiCon]="false"
			[botonBorrar]="botonBorrar" [botonNuevo]="botonNuevo" [botonGuardar]="botonGuardar"
			[botonRegresar]="botonRegresar" [barraBotones5]="false" [botonPrimero]="botonesNav"
			[botonAnterior]="botonesNav" [botonSiguiente]="botonesNav" [botonUltimo]="botonesNav"
			[barraCxpTrnCobro]="false" [botonAnular]="botonAnular" [botonAsiento]="botonesNav"
			[botonverAsiento]="botonesNav" [botonBuscar]="botonesNav" [botonBinocular]="true"
			[botonFrmCorreos]="false" [barraCartera]="false"></app-invmaebarra>
	</div>
</div>
<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px; width: 99%;">
	<!--LINEA 1-->
	<div class="p-col-2">
		<label for="lblNroCobro"></label>
		<input class="frm" id="lblNroCobro" type="text" pInputText disabled
			style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
			[disabled]="true" [(ngModel)]="cxptrncobroService.trncobroarray[indicador].TRNCOBRO_NRODOC"
			autocomplete="off">
	</div>
	<div class="p-col-3">
	</div>
	<div class="p-col-2">
		<label for="lblAnular"></label>
		<input class="frm" id="lblAnular" type="text" pInputText disabled
			style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
			[disabled]="true" [(ngModel)]="cxptrncobroService.trncobroarray[indicador].ANULADO" autocomplete="off">
	</div>
	<div class="p-col-1">
	</div>
	<div class="p-col-2">
		<div class="p-grid">
			<div class="p-col-9">
				<label for="lblAsiNro"></label>
				<input class="frm" id="lblAsiNro" type="text" pInputText style="background-color: #EFFFF7" disabled
					[disabled]="true" [(ngModel)]="cxptrncobroService.trncobroarray[indicador].ASI_NRO"
					autocomplete="off">
			</div>
		</div>
	</div>
	<div class="p-col-2">
		<label for="lblImpreso"></label>
		<input class="frm" id="lblImpreso" type="text" pInputText disabled
			style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
			[disabled]="true" [(ngModel)]="impreso" autocomplete="off">
	</div>
</div>
<p-tabView [(activeIndex)]="index" (onChange)="tabDatos()">
	<p-tabPanel header="General">
		<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 280px)'}">
			<div class="contorno" id="contorno">
				<div class="divgrups">
					<div class="p-grid">
						<!--LINEA 1-->
						<div class="p-col-12" style="margin-bottom: -15px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Proveedor:</span>
								</div>
								<div class="p-col-2">
									<label for="txtCodProveedor"></label>
									<input class="frm" id="txtCodProveedor" type="text" pInputText
										[disabled]="!boltxtCodProveedor"
										[(ngModel)]="cxptrncobroService.trncobroarray[indicador].PRO_CODIGO"
										(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="busquedaproveedor(cxptrncobroService.trncobroarray[indicador].PRO_CODIGO)"></button>
								</div>
								<div class="p-col-4">
									<label for="lblNomProveedor"></label>
									<input class="frm" id="lblNomProveedor" type="text" pInputText disabled
										style="color: rgb(0, 0, 255)"
										[(ngModel)]="cxptrncobroService.trncobroarray[indicador].PRO_NOMBRE"
										autocomplete="off">
								</div>
								<div class="p-col-1">
									<span>Emisión:</span>
								</div>
								<div class="p-col-1">
									<p-calendar id="dateTimeFechaEmision" [inputStyle]="{width: '100%', height: '20px'}"
										[(ngModel)]="cxptrncobroService.trncobroarray[indicador].TRNCOBRO_FECHATRN"
										[readonlyInput]="false" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" [disabled]="!boldateTimeFechaEmi">
									</p-calendar>
								</div>

										<div class="p-col-1" *ngIf="tabItemFPAnt === true">
											<span>Asiento:</span>
										</div>
										<div class="p-col-1" *ngIf="tabItemFPAnt === true">
											<p-dropdown [options]="cmbasiento" [style]="{'minWidth': '100%', 'width':'100%'}"
												optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectasiento">
											</p-dropdown>
										</div>
								
							</div>
						</div>
					</div>
				</div>
				<div class="divgrups">
					<div class="p-grid">
						<!--LINEA 2-->
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Tipo:</span>
								</div>
								<div class="p-col-2">
									<p-dropdown id="cmbTipo" [options]="cmbtipo" [disabled]="!bolcmbTipo"
										[style]="{'minWidth': '100%', 'width':'100%'}" optionLabel="name"
										[panelStyle]="{'width':'110%'}" [(ngModel)]="selecttipo"
										(keydown.enter)="setFocus($event)" (onChange)="seleccionarTipo('combo')">
									</p-dropdown>
								</div>
								<div class="p-col-5">
									<div class="p-grid">
										<div class="p-col-2">
											<span>Comprobante:</span>
										</div>
										<div class="p-col-2">
											<label for="txtSerie"></label>
											<input class="frm" id="txtSerie" type="text" pInputText
												[disabled]="!boltxtSerie"
												[(ngModel)]="cxptrncobroService.trncobroarray[indicador].TRNCOBRO_SERIE"
												(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
												autocomplete="off" maxlength="6">
										</div>
										<div class="p-col-4">
											<label for="txtNumero"></label>
											<input class="frm" id="txtNumero" type="text" pInputText
												[maxLength]="txtNumeroTextLimit" [disabled]="!boltxtNumero"
												[(ngModel)]="cxptrncobroService.trncobroarray[indicador].TRNCOBRO_NROCOMPROBANTE"
												(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
												autocomplete="off" maxlength="9">
										</div>
										<div class="p-col-1">
											<p-checkbox [disabled]="!bolchkSaldoInicial" name="chkSaldoInicial" value=""
												label="" binary="{{chkSaldoInicial}}" [(ngModel)]="chkSaldoInicial"
												(click)="botonesmodificar()" (keydown.enter)="setFocus($event)">
											</p-checkbox>
										</div>
										<div class="p-col-3">
											<span>Saldo Inicial</span>
										</div>
									</div>
								</div>
								<div class="p-col-1">
									<span>Vencimiento:</span>
								</div>
								<div class="p-col-1">
									<p-calendar id="dateTimeVencimiento" [disabled]="!boldateTimeVencimiento"
										[inputStyle]="{width: '100%', height: '20px'}"
										[(ngModel)]="cxptrncobroService.trncobroarray[indicador].TRNCOBRO_FECHAVENCE"
										[readonlyInput]="false" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()">
									</p-calendar>
								</div>
								<div class="p-col-1">
									<span>Recepción:</span>
								</div>
								<div class="p-col-1">
									<p-calendar id="dateTimeRecepcion" [disabled]="!boldateTimeRecepcion"
										[inputStyle]="{width: '100%', height: '20px'}"
										[(ngModel)]="cxptrncobroService.trncobroarray[indicador].TRNCOBRO_FECHARECEPCION"
										[readonlyInput]="false" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()">
									</p-calendar>
								</div>
							</div>
						</div>
						<!--LINEA 3-->
						<div class="p-col-12" style="margin-top: -18px; margin-bottom: -20px">
							<div class="p-grid">
								<div class="p-col-3">
									<div class="p-grid">
										<div class="p-col-6">
											<span>Número Documento:</span>
										</div>
										<div class="p-col-6">
											<label for="txtNumeroDocumento"></label>
											<input class="frm" id="txtNumeroDocumento" type="text" pInputText
												[disabled]="!boltxtNumeroDocumento"
												[(ngModel)]="cxptrncobroService.trncobroarray[indicador].TRNCOBRO_REFERENCIAEXTERNA"
												(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
												autocomplete="off">
										</div>
									</div>
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-4">
											<button type="button" [disabled]="!bolbtnBuscarDocumento"
												style="background-image: url(../../../assets/images/iconos/buscar.png);"
												(click)="listarDocumentos(cxptrncobroService.trncobroarray[indicador].TRNCOBRO_REFERENCIAEXTERNA)"></button>
										</div>
										<div class="p-col-8">
											<label for="lblComprobanteDoc"></label>
											<input class="frm" id="lblComprobanteDoc" type="text" pInputText disabled
												style="color: rgb(0, 0, 255)"
												[(ngModel)]="cxptrncobroService.trncobroarray[indicador].ENCFACPRO_REFERENCIA"
												autocomplete="off">
										</div>
									</div>
								</div>
								<div class="p-col-3">
									<div class="p-grid">
										<div class="p-col-2">
											<span>F.P.</span>
										</div>
										<div class="p-col-4">
											<label for="txtFormaPagoSri"></label>
											<input class="frm" id="txtFormaPagoSri" type="text" pInputText
												[disabled]="!boltxtFormaPagoSri"
												[(ngModel)]="cxptrncobroService.trncobroarray[indicador].TRNCOBRO_CODFORPAG"
												(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
												autocomplete="off">
										</div>
										<div class="p-col-2">
											<button type="button" [disabled]="!bolbtnFormaPagoSRI"
												style="background-image: url(../../../assets/images/iconos/buscar.png);"
												(click)="buscarFPSri(cxptrncobroService.trncobroarray[indicador].PRO_CODIGO)"></button>
										</div>
										<div class="p-col-4">
											<label for="lblDesFormaPago"></label>
											<input class="frm" id="lblDesFormaPago" [disabled]="!bollblDesFormaPago"
												type="text" pInputText disabled
												[(ngModel)]="cxptrncobroService.trncobroarray[indicador].TRNCOBRO_DESFORPAG"
												autocomplete="off">
										</div>
									</div>
								</div>
								<div class="p-col-1">
									<span>Importe:</span>
								</div>
								<div class="p-col-1">
									<label for="txtImporte"></label>
									<input class="frm" id="txtImporte" type="text" pInputText
										[disabled]="!boltxtImporte" style="text-align: right"
										[(ngModel)]="cxptrncobroService.trncobroarray[indicador].TRNCOBRO_IMPORTE"
										(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										autocomplete="off" appTwoDigitDecimaNumber>
								</div>
								<div class="p-col-1">
									<span>Saldo:</span>
								</div>
								<div class="p-col-1">
									<label for="lblSaldoDocumento"></label>
									<input class="frm" id="lblSaldoDocumento" type="text" pInputText
										[disabled]="!bollblSaldoDocumento" style="text-align: right"
										[(ngModel)]="cxptrncobroService.trncobroarray[indicador].TRNCOBRO_SALDOINICIAL"
										(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										autocomplete="off">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="divgrups">
					<div class="p-grid">
						<!--LINEA 4-->
						<div class="p-col-12" style="margin-bottom: -15px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Concepto:</span>
								</div>
								<div class="p-col-11">
									<label for="txtConcepto"></label>
									<input class="frm" id="txtConcepto" type="text" pInputText
										[disabled]="discodproveedor"
										[(ngModel)]="cxptrncobroService.trncobroarray[indicador].TRNCOBRO_CONCEPTO"
										(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										autocomplete="off">
								</div>
							</div>
						</div>
					</div>
				</div>
				<!--<div class="divgrups">
					<div class="p-grid">
						LINEA 5
						<div class="p-col-12" style="margin-bottom: -15px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Requisición::</span>
								</div>
								<div class="p-col-1">
									<label for="txtNumRequisicion"></label>
									<input class="frm" id="txtNumRequisicion" type="text" pInputText
										   [disabled]="discodproveedor"
										   [(ngModel)]="cxptrncobroService.trncobroarray[indicador].ENCREQ_NUMERO"
										   (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										   autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedaproveedor(cxptrncobroService.trncobroarray[indicador].PRO_CODIGO)"></button>
								</div>
								<div class="p-col-2">
									<button class="sincss" pButton (click)="seleccionarItem()">Seleccionar Item
									</button>
								</div>
								<div class="p-col-1">
									<label for="lblPartida"></label>
									<input class="frm" id="lblPartida" type="text" pInputText disabled
										   style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
										   [disabled]="true"
										   [(ngModel)]="cxptrncobroService.trncobroarray[indicador].TRNCOBRO_NRODOC"
										   autocomplete="off">
								</div>
								<div class="p-col-1">
									<label for="lblCodigo"></label>
									<input class="frm" id="lblCodigo" type="text" pInputText disabled
										   style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
										   [disabled]="true"
										   [(ngModel)]="cxptrncobroService.trncobroarray[indicador].TRNCOBRO_NRODOC"
										   autocomplete="off">
								</div>
								<div class="p-col-3">
									<label for="lblDescripcion"></label>
									<input class="frm" id="lblDescripcion" type="text" pInputText disabled
										   style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
										   [disabled]="true"
										   [(ngModel)]="cxptrncobroService.trncobroarray[indicador].TRNCOBRO_NRODOC"
										   autocomplete="off">
								</div>
								<div class="p-col-1">
									<label for="lblImporte"></label>
									<input class="frm" id="lblImporte" type="text" pInputText disabled
										   style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
										   [disabled]="true"
										   [(ngModel)]="cxptrncobroService.trncobroarray[indicador].TRNCOBRO_NRODOC"
										   autocomplete="off">
								</div>
							</div>
						</div>
					</div>
				</div>-->
				<p-tabView [(activeIndex)]="tabDocumentos">
					<p-tabPanel *ngIf="tabItemVarios === true" header="Varios">
						<div class="p-grid">
							<div class="p-col-12">
								<app-aggridsaci [width]="" [height]="largovar" 
									[enableFilter]="true" [rowData]="cxptrncobroService.variosarray[indicador]"
									[frameworkComponents]="frameworkComponents" [animateRows]="true"
									[rowSelection]="rowSelection" [columnDefs]="columnDefsVarios"
									[defaultColDef]="defaultColDefVarios" [rowStyle]="rowStyle"
									[singleClickEdit]="false" [botones]="true" [mostrarGuardar]="false"
									(selected)="seleccionVarios($event)" (cambios)="cambio($event)"
									[botonNuevo]="!botonNuevoVarios" [botonRegresar]="!botonRegresarVarios"
									[botonBorrar]="!botonBorrarVarios" align="left" style="justify-items: end">
								</app-aggridsaci>
							</div>
						</div>
					</p-tabPanel>
					<p-tabPanel *ngIf="tabItemFPAnt === true" header="Forma de Pago">
						<div class="p-grid">
							<div class="p-col-12">
								<app-aggridsaci [width]="" [height]="largofp" 
									[enableFilter]="true" [rowData]="cxptrncobroService.fpantarray[indicador]"
									[frameworkComponents]="frameworkComponents" [animateRows]="true"
									[rowSelection]="rowSelection" [columnDefs]="columnDefsFPAnt"
									[defaultColDef]="defaultColDefFPAnt" [rowStyle]="rowStyleFpant"
									[singleClickEdit]="false" [botones]="true" [mostrarGuardar]="false"
									(selected)="seleccionFpant($event)" (cambios)="cambio($event)" align="left"
									style="justify-items: end"></app-aggridsaci>
							</div>
							<div class="p-col-12" style="margin-top: -20px">
								<div class="p-grid">
									<div class="p-col-1"
										style="margin-top: 10px; height: 25%; background-color: rgb(135, 234, 130)">
									</div>
									<div class="p-col-3">
										<span>Documentos conciliados</span>
									</div>
								</div>
							</div>
						</div>
					</p-tabPanel>
					<p-tabPanel *ngIf="tabItemECAnt === true" header="Estado de Cuenta Anticipo">
						<div class="p-grid">
							<div class="p-col-12">
								<app-aggridsaci [width]="" [height]="largovar" [enableSorting]="true"
									[enableFilter]="true" [rowData]="cxptrncobroService.ecantarray[indicador]"
									[frameworkComponents]="frameworkComponents" [animateRows]="true"
									[rowSelection]="rowSelection" [columnDefs]="columnDefsECAnt"
									[defaultColDef]="defaultColDefECAnt" [rowStyle]="rowStyleEcant"
									[singleClickEdit]="false" [botones]="false" (selected)="seleccionECAnt($event)"
									(cambios)="cambio($event)" align="left" style="justify-items: end"></app-aggridsaci>
							</div>
						</div>
					</p-tabPanel>
					<p-tabPanel *ngIf="tabItemDoc === true" header="Documentos">
						<div class="p-grid">
							<div class="p-col-12">
								<app-aggridsaci [width]="" [height]="largofp" 
									[enableFilter]="true" [rowData]="cxptrncobroService.docarray[indicador]"
									[frameworkComponents]="frameworkComponents" [animateRows]="true"
									[rowSelection]="rowSelection" [columnDefs]="columnDefsDoc"
									[defaultColDef]="defaultColDefDoc" [rowStyle]="rowStyle" [singleClickEdit]="false"
									[botones]="false" [mostrarGuardar]="false" (selected)="seleccionDocumentos($event)"
									(cambios)="cambio($event)" align="left" style="justify-items: end"></app-aggridsaci>
							</div>
							<div class="p-col-12" style="margin-top: -10px">
								<div class="p-grid">
									<div class="p-col-7">
									</div>
									<div class="p-col-2">
										<span>Total Documentos:</span>
									</div>
									<div class="p-col-2">
										<input class="frm" id="lblTotalDoc" type="text" pInputText disabled
											[(ngModel)]="cxptrncobroService.trncobroarray[indicador].TOTALES"
											autocomplete="off">
									</div>
								</div>
							</div>
						</div>
					</p-tabPanel>
					<p-tabPanel *ngIf="tabItemFPDev === true" header="Forma de Pago">
						<div class="p-grid">
							<div class="p-col-12">
								<app-aggridsaci [width]="" [height]="largofp" 
									[enableFilter]="true" [rowData]="cxptrncobroService.fpdevarray[indicador]"
									[frameworkComponents]="frameworkComponents" [animateRows]="true"
									[rowSelection]="rowSelection" [columnDefs]="columnDefsFPDev"
									[defaultColDef]="defaultColDefFPDev" [rowStyle]="rowStyleFpdev"
									[singleClickEdit]="false" [botones]="true" [mostrarGuardar]="false"
									(selected)="seleccionFpDev($event)" (cambios)="cambio($event)" align="left"
									style="justify-items: end"></app-aggridsaci>
							</div>
							<div class="p-col-12" style="margin-top: -20px">
								<div class="p-grid">
									<div class="p-col-1"
										style="margin-top: 10px; height: 25%; background-color: rgb(255, 207, 185)">
									</div>
									<div class="p-col-3">
										<span>Documentos depositados</span>
									</div>
									<div class="p-col-1"
										style="margin-top: 10px; height: 25%; background-color: rgb(135, 234, 130)">
									</div>
									<div class="p-col-3">
										<span>Documentos conciliados</span>
									</div>
								</div>
							</div>
						</div>
					</p-tabPanel>
				</p-tabView>
			</div>
		</p-scrollPanel>
	</p-tabPanel>
	<p-tabPanel header="Anexos"
		[disabled]="selecttipo.name !== 'Nota de Crédito' && selecttipo.name !== 'Nota de Débito' ">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-12">
					<div style="text-align: left;"><span style="font-weight: bold">Datos generales</span></div>
					<div class="divgrups" aria-valuetext="Datos generales">
						<div class="p-grid">
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-3">
										<span>Id. del Sustento Tributario:</span>
									</div>
									<div class="p-col-1">
										<label for="txtIdSustentoTributario"></label>
										<input class="frm" id="txtIdSustentoTributario" type="text" pInputText
											[disabled]="discodproveedor"
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].TRNANEX_IDCRETRI"
											(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
											autocomplete="off">
									</div>
									<div class="p-col-1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedaidcretri(cxptrncobroService.trndatosanexarray[indicador].TRNANEX_IDCRETRI, 'IDCRETRI')"></button>
									</div>
									<div class="p-col-2">
										<label for="lblIdSustentoTrib"></label>
										<input class="frm" id="lblIdSustentoTrib" type="text" pInputText disabled
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].DESCIDCRETRI"
											autocomplete="off">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -20px">
								<div class="p-grid">
									<div class="p-col-3">
										<span>Tipo de Id. del Proveedor:</span>
									</div>
									<div class="p-col-1">
										<label for="lblTipoIdProv"></label>
										<input class="frm" id="lblTipoIdProv" type="text" pInputText
											[disabled]="discodproveedor"
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].IDSUSTRIB"
											(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
											autocomplete="off">
									</div>
									<div class="p-col-1">
									</div>
									<div class="p-col-2">
										<label for="lblTipoIdProveedor"></label>
										<input class="frm" id="lblTipoIdProveedor" type="text" pInputText disabled
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].DESIDSUSTRIB"
											autocomplete="off">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-3">
										<span>C. de Tipo de Comprobante:</span>
									</div>
									<div class="p-col-1">
										<label for="txtTipoComprobante"></label>
										<input class="frm" id="txtTipoComprobante" type="text" pInputText
											[disabled]="discodproveedor"
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].TRNANEX_TIPCOM"
											(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
											maxLength="6" autocomplete="off">
									</div>
									<div class="p-col-1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedatipcom(cxptrncobroService.trndatosanexarray[indicador].TRNANEX_TIPCOM)"></button>
									</div>
									<div class="p-col-2">
										<label for="lblTipoComprobante"></label>
										<input class="frm" id="lblTipoComprobante" type="text" pInputText disabled
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].DESCTIPOCOMP"
											autocomplete="off">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-3">
										<span>No. de Id. del Proveedor RUC:</span>
									</div>
									<div class="p-col-5">
										<label for="lblIdProveedor"></label>
										<input class="frm" id="lblIdProveedor" type="text" pInputText
											[disabled]="discodproveedor"
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].IDPROVEEDOR"
											(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
											autocomplete="off">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px; margin-bottom: -20px">
								<div class="p-grid">
									<div class="p-col-3">
										<span>Periodo o Mes Informado:</span>
									</div>
									<div class="p-col-5">
										<div class="p-grid">
											<div class="p-col-3">
												<label for="lblPeriodoInformado"></label>
												<input class="frm" id="lblPeriodoInformado" type="text" pInputText
													[disabled]="true"
													[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].PERIODOINF"
													(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
													autocomplete="off">
											</div>
											<div class="p-col-1">
											</div>
											<div class="p-col-8">
												<label for="lblPeriodoMesInformado"></label>
												<input class="frm" id="lblPeriodoMesInformado" type="text" pInputText
													disabled
													[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].DESPERIODOINF"
													autocomplete="off">
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="p-col-6" style="margin-top: -15px">
					<div style="text-align: left;"><span style="font-weight: bold">Datos del Documento</span></div>
					<div class="divgrups" aria-valuetext="Datos del Documento">
						<div class="p-grid">
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-7">
										<span>Fecha del Registro Contable:</span>
									</div>
									<div class="p-col-3">
										<label for="lblFechaRegContable"></label>
										<input class="frm" id="lblFechaRegContable" type="text" pInputText disabled
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].FECHACONTAB"
											autocomplete="off">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-7">
										<span>Nro. de Serie del Comp.:</span>
									</div>
									<div class="p-col-3">
										<label for="lblSerieComp"></label>
										<input class="frm" id="lblSerieComp" type="text" pInputText disabled
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].SERIECOMP"
											autocomplete="off">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-7">
										<span>Nro. de Secuencia del Comp.:</span>
									</div>
									<div class="p-col-3">
										<label for="lblNroSecComp"></label>
										<input class="frm" id="lblNroSecComp" type="text" pInputText disabled
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].SECUENCIALCOMP"
											autocomplete="off">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-7">
										<span>Fecha de Emisión del Comp.:</span>
									</div>
									<div class="p-col-3">
										<label for="lblFechaEmisionComp"></label>
										<input class="frm" id="lblFechaEmisionComp" type="text" pInputText disabled
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].FECHAEMISIONCOMP"
											autocomplete="off">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px">
								<div class="p-grid">
									<div class="p-col-7">
										<span>Nro. de Autorización del Comp.:</span>
									</div>
									<div class="p-col-4">
										<label for="txtNroAutorizacion"></label>
										<input class="frm" id="txtNroAutorizacion" type="text" pInputText
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].TRNANEX_AUTORIZACION"
											autocomplete="off" maxlength="49" (keydown)="botonesmodificar()">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="p-col-6" style="margin-top: -15px">
					<div class="divgrups" aria-valuetext="Datos del Documento Modificado">
						<div class="p-grid">
							<div class="p-col-12">
								<div style="text-align: left;"><span style="font-weight: bold">Datos del Documento
										Modificado</span>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-7">
										<span>Tipo de Comprobante Modificado:</span>
									</div>
									<div class="p-col-2">
										<label for="txtTipoCompModif"></label>
										<input class="frm" id="txtTipoCompModif" type="text" pInputText
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].TIPOCOMPMODIF"
											autocomplete="off" maxLength="2">
									</div>
									<div class="p-col-3">
										<label for="txtTipoCompModifDes"></label>
										<input class="frm" id="txtTipoCompModifDes" type="text" pInputText disabled
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].DESCTIPOCOMPMODIFICADO"
											autocomplete="off">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-7">
										<span>Nro de Serie:</span>
									</div>
									<div class="p-col-4">
										<label for="txtNroSerieDocModif"></label>
										<input class="frm" id="txtNroSerieDocModif" type="text" pInputText
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].SERIEDOCMODIF"
											autocomplete="off" maxLength="6">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-7">
										<span>Nro. de Secuencia:</span>
									</div>
									<div class="p-col-4">
										<label for="txtNroSecDocModif"></label>
										<input class="frm" id="txtNroSecDocModif" type="text" pInputText
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].SECUENCIADOCMODIF"
											autocomplete="off" maxLength="9">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px; margin-bottom: -10px">
								<div class="p-grid">
									<div class="p-col-7">
										<span>Nro. de Autorización:</span>
									</div>
									<div class="p-col-4">
										<label for="txtNroAutorizacionDocModif"></label>
										<input class="frm" id="txtNroAutorizacionDocModif" type="text" pInputText
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].AUTORIZACIONDOCMODIF"
											autocomplete="off" maxlength="49">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div style="text-align: left;"><span style="font-weight: bold">Impuestos</span></div>
					<div class="divgrups" aria-valuetext="Impuestos">
						<div class="p-grid">
							<div class="p-col-5">
								<span style="font-weight: bold">Impuestos</span>
							</div>
							<div class="p-col-1">
								<span style="font-weight: bold">Tipos SRI</span>
							</div>
							<div class="p-col-6">
							</div>
							<div class="p-col-12" style="margin-top: -7px">
								<div class="p-grid">
									<div class="p-col-3">
										<span>Base Imp. Tarifa 0%:</span>
									</div>
									<div class="p-col-2">
										<label for="txtBaseImpTartifa0"></label>
										<input class="frm" id="txtBaseImpTartifa0" type="text" pInputText
											style="text-align: right"
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].TRNANEX_BASEIMPTARCERO"
											autocomplete="off" appTwoDigitDecimaNumber>
									</div>
									<div class="p-col-1">
										<div class="p-grid">
											<div class="p-col-8">
												<label for="txtTipoSriTarCero"></label>
												<input class="frm" id="txtTipoSriTarCero" type="text" pInputText
													[disabled]="discodproveedor"
													[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].TRNANEX_TIPOSRITARCERO"
													(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
													autocomplete="off">
											</div>
											<div class="p-col-4">
												<button type="button"
													style="background-image: url(../../../assets/images/iconos/buscar.png);"
													(click)="asignarTipoSRi('txtTipoSriTarCero', cxptrncobroService.trndatosanexarray[indicador].TRNANEX_TIPOSRITARCERO)"></button>
											</div>
										</div>
									</div>
									<div class="p-col-6">
										<span style="font-weight: bold">Retenciones IVA</span>
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -20px">
								<div class="p-grid">
									<div class="p-col-3">
										<span>Base Imp. Grabada de IVA:</span>
									</div>
									<div class="p-col-2">
										<label for="txtBaseImpGrabadaIva"></label>
										<input class="frm" id="txtBaseImpGrabadaIva" type="text" pInputText
											style="text-align: right"
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].TRNANEX_BASEIMPGRABADAIVA"
											autocomplete="off" appTwoDigitDecimaNumber>
									</div>
									<div class="p-col-1">
										<div class="p-grid">
											<div class="p-col-8">
												<label for="txtTipoSriGravada"></label>
												<input class="frm" id="txtTipoSriGravada" type="text" pInputText
													[disabled]="discodproveedor"
													[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].TRNANEX_TIPOSRIGRAVADA"
													(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
													autocomplete="off">
											</div>
											<div class="p-col-4">
												<button type="button"
													style="background-image: url(../../../assets/images/iconos/buscar.png);"
													(click)="asignarTipoSRi('txtTipoSriGravada', cxptrncobroService.trndatosanexarray[indicador].TRNANEX_TIPOSRIGRAVADA)"></button>
											</div>
										</div>
									</div>
									<div class="p-col-4">
										<span>Monto Retención IVA-Bienes:</span>
									</div>
									<div class="p-col-2">
										<label for="txtMontoRetenIvaBienes"></label>
										<input class="frm" id="txtMontoRetenIvaBienes" type="text" pInputText
											style="text-align: right"
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].TRNANEX_MONTORETIVABIE"
											autocomplete="off" appTwoDigitDecimaNumber>
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -25px">
								<div class="p-grid">
									<div class="p-col-3">
										<span>Monto IVA:</span>
									</div>
									<div class="p-col-2">
										<label for="txtMontoIva"></label>
										<input class="frm" id="txtMontoIva" type="text" pInputText
											style="text-align: right"
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].TRNANEX_MONTOIVA"
											autocomplete="off" appTwoDigitDecimaNumber>
									</div>
									<div class="p-col-1">
									</div>
									<div class="p-col-4">
										<span>Monto Retencion de IVA-Servicios:</span>
									</div>
									<div class="p-col-2">
										<label for="txtMontoRetenIvaServicios"></label>
										<input class="frm" id="txtMontoRetenIvaServicios" type="text" pInputText
											style="text-align: right"
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].TRNANEX_MONTORETIVASER"
											autocomplete="off" appTwoDigitDecimaNumber>
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-3">
										<span>Base Imp. no Objeto de IVA:</span>
									</div>
									<div class="p-col-2">
										<label for="txtBaseImpNoObjetoIva"></label>
										<input class="frm" id="txtBaseImpNoObjetoIva" type="text" pInputText
											style="text-align: right"
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].TRNANEX_BASEIMPNOOBJETOIVA"
											autocomplete="off" appTwoDigitDecimaNumber>
									</div>
									<div class="p-col-1">
									</div>
									<div class="p-col-4">
										<span>Retención IVA 100%:</span>
									</div>
									<div class="p-col-2">
										<label for="txtRetencionIva100"></label>
										<input class="frm" id="txtRetencionIva100" type="text" pInputText
											style="text-align: right"
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].TRNANEX_RETVACIEN"
											autocomplete="off" appTwoDigitDecimaNumber>
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px">
								<div class="p-grid">
									<div class="p-col-3">
										<span>Monto ICE:</span>
									</div>
									<div class="p-col-2">
										<label for="txtMontoIce"></label>
										<input class="frm" id="txtMontoIce" type="text" pInputText
											style="text-align: right"
											[(ngModel)]="cxptrncobroService.trndatosanexarray[indicador].TRNANEX_MONTOICE"
											autocomplete="off" appTwoDigitDecimaNumber>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
</p-tabView>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusqueda" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [closable]="false" [style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
		(opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
		[busquedaParams]="busquedacampos" [busquedaCompuesta]="busquedaCompuesta" [consulta]="consulta"
		(opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Clave Autorización" [(visible)]="displayDialogAutorizacion" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" (onHide)="onHideAutorizacion()" [style]="{width: '40vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="autorizacion"></p-toast>
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							(keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width:'90vw', height:'120vh'}" [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="asiento" [abiertoDesde]="'cxptrncobro'"
		(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>

<p-dialog header="Datos Anexos" [(visible)]="displayDialogDatosAnexos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'}" (onHide)="verificarDatosAnexos()">
	<app-datosanexos [strnumfac]="cxptrncobroService.trncobroarray[indicador].TRNCOBRO_NRODOC"
	[strOrigen]="'CXP'"
	 (salir)="salirDialog()">
	</app-datosanexos>
</p-dialog>
<!-- CDPJ -->
<p-dialog header="Reportes" [(visible)]="displayDialogReportes" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: '400px'}">
	<app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
		[strNumDoc]="cxptrncobroService.trncobroarray[this.indicador].ASI_NRO" [strCampo]="'ASI_NRO'"
		(cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>
<p-dialog header="Formularios de Correos" [(visible)]="displayDialogfrmcorreos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: '400px'}">
	<app-sacifrmenviocorreos [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
	[strNumDoc]="cxptrncobroService.trncobroarray[indicador].ASI_NRO" [strCampo]="'ASI_NRO'"
	[strCliProvCod]="cxptrncobroService.trncobroarray[indicador].PRO_CODIGO"
		(cerrar)="cerrarfrmcorreos()"></app-sacifrmenviocorreos>
</p-dialog>
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="cxptrncobroService.spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	<p-dialog [(visible)]="cxptrncobroService.spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
	</p-dialog>	
</div>
<!-- CDPJ -->