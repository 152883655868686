import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import { MessageService } from 'primeng';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';


@Injectable({
  providedIn: 'root'
})
export class ConcierrecontableService {

  public way: string;

  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private message: MessageService,
              private confIniciales: ConfInicialesService) { 

      this.way = sessionStorage.getItem('way');
    }


  ejecutaAsientoContable(fechaDesde, fechaHasta, concodigo): Observable<any>{
    return this.http.post<any>(this.way + '/cont/cierrecontable/e9b98ae4998bf800', {
      elementos: {
        P_FECHAEMISION_INICIO: fechaDesde,
        P_FECHAEMISION_FIN: fechaHasta,
        P_CON_CODIGO: concodigo,
        P_COM_CODIGO: '01',
        P_USUIDENTIFICACION: this.usuario.identificacion,
      }
    }, this.confIniciales.httpOptions());
  }

  erConNombre(strConcodigo) {
    return this.http.post(this.way + '/cont/cierrecontable/3b6cc5ef8b2f6e', {
      elementos: {
        CONCODIGO: strConcodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erConCodigo(strConcodigo) {
    return this.http.post(this.way + '/cont/cierrecontable/3b6cc5d986ca18', {
      elementos: {
        CONCODIGO: strConcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
}
