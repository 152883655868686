import {Component, OnInit, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {VenmaegruposService} from '../venservicios/venmaegrupos.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {Venmaegrupos} from '../veninterfaces/venmaegrupos';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Component({
  selector: 'app-venmaegrupos',
  templateUrl: './venmaegrupos.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class VenmaegruposComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  actualizar: boolean;
  codigoAux: any;
  nuevoCampo: boolean;
  permisoGrupo: SegMaePermiso;
  arregloCons: any[];
  desCodigo: boolean;
  largo: string;
  grupoSeleccionado: Venmaegrupos;
  public frameworkComponents;
  rowStyle;
  defaultColDefGrupos;
  rowSelection = 'multiple';
  
  columnDefsGrupos = [
    {
      headerName: 'Código', field: 'GRU_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 5
          }
        }
    },
    {
      headerName: 'Nombre', field: 'GRU_NOMBRE', cellEditor: 'cellPrueba', width: 250,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 35
          }
        }
    },
    {
      headerName: 'Cta.Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: true,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 35
          }
        }
    },
  ];
  
  constructor(public grupoService: VenmaegruposService, private message: MessageService, private errorService: ErroresBaseDatosService,
              private confirmationService: ConfirmationService, private busqService: InvbusquedaService,
              private permisosService: PermisosService, private auditoriagral: AuditoriagralService,
              private init: NuevoComponentService) {
    
    this.grupoService.venmaegrupo = [];
    this.agTable();
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefGrupos = {
      editable: true,
      width: 100,
      objeto: 'maegroup'
    };
    this.permisoGrupo = {};
  }
  
  ngOnInit() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.buscarCon();
    this.btnRegresar = true;
    this.actualizar = false;
    this.nuevoCampo = false;
    this.codigoAux = 0;
    this.permisosService.getListaPermisos('VEN', '1', '16').subscribe((res) => {
      this.permisoGrupo = res[0];
    });
    this.desCodigo = true;
    this.grupoService.getGrupos().subscribe((res) => {
      this.grupoService.venmaegrupo = res;
    });
    this.buscarCon();
    this.largo = '258';
  }
  
  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      if (this.permisoGrupo.PERINSERCION === 1) {
        this.btnNuevo = true;
        this.btnGuardar = false;
        this.btnBorrar = false;
        this.btnRegresar = false;
        this.nuevoCampo = true;
        this.actualizar = false;//CDPJ
        this.aggrid.isEditable(true);
        this.desCodigo = false;
        this.grupoSeleccionado = {
          CEN_CODIGO: '',
          CON_CODIGO: '',
          CON_CODIGODES: '',
          GRU_CODIGO: '',
          GRU_NOMBRE: '',
          GRU_PORDES: 0,
          GRU_TIPO: 'CLI',
        };
        this.grupoService.venmaegrupo.push(this.grupoSeleccionado);
        this.grupoSeleccionado=this.grupoSeleccionado;//CDPJ
        this.aggrid.refreshaggrid(this.grupoService.venmaegrupo, this.defaultColDefGrupos.objeto);
      } else if (this.permisoGrupo.PERINSERCION === 0) {
        this.message.add({
          key: 'maegroup',
          severity: 'error',
          summary: 'Inserción de Grupo',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    }
    if (valor === 'Guardar') {
      if (this.permisoGrupo.PERACTUALIZACION === 1) {
        this.btnNuevo = false;
        this.btnGuardar = true;
        this.btnBorrar = false;
        this.btnRegresar = true;
        this.grupoService.erFindCodGrupo(this.grupoSeleccionado.GRU_CODIGO).subscribe( async existe => {
          if (existe !== null) {
            let coincidencias: number;
            coincidencias = 0;
            // this.arregloCons.map((con) => {
            //   if (this.grupoSeleccionado.CON_CODIGO === con.CON_CODIGO) {
            //     coincidencias++;
            //   }
            //   console.log(this.arregloCons);
            // });
            const rsdata: any = await this.grupoService.encontrarArtCntaySer(this.grupoSeleccionado.CON_CODIGO, 'C');
          //let strCodigo = 0;
          if (rsdata !== null) {
            for (const rs of rsdata) {
              coincidencias = rs.NUMERO;
            }
          }
            if (coincidencias !== 0 || this.grupoSeleccionado.CON_CODIGO === '') {
              this.grupoService.actualizarGrupo(this.grupoSeleccionado, this.codigoAux).subscribe(() => {
                this.message.add({
                  key: 'maegroup',
                  severity: 'success',
                  summary: 'Actualización exitosa',
                  detail: 'Se actualizó Grupo correctamente'
                });
                this.auditoriagral.registrarAuditoria('VEN_MAEGRUPO', String(this.grupoSeleccionado.GRU_CODIGO), 'A', '',
                  '01', '', '', '', '').subscribe(() => {});
                this.grupoService.venmaegrupo = this.grupoService.venmaegrupo.filter((val, j) => j !==
                  this.grupoService.venmaegrupo.indexOf(this.grupoService.getGrupos()[this.grupoService.venmaegrupo.length - 1]));
                this.aggrid.isEditable(false);
              }, error1 => {
                let mensaje: string;
                mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                this.message.add({
                  key: 'maegroup',
                  severity: 'error',
                  summary: 'Actualización de Grupo',
                  detail: mensaje
                });
                this.btnNuevo = false;
                this.btnGuardar = true;
                this.btnBorrar = false;
                this.btnRegresar = true;
                this.grupoService.getGrupos().subscribe((res) => {
                  this.grupoService.venmaegrupo = res;
                  this.aggrid.refreshaggrid(this.grupoService.venmaegrupo, this.defaultColDefGrupos.objeto);
                });
              });
            } else {
              this.message.add({
                key: 'maegroup',
                severity: 'error',
                summary: 'Error en Cuenta Contable',
                detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
              });
              this.grupoSeleccionado.CON_CODIGO = '';
              this.aggrid.refreshaggrid(this.grupoService.venmaegrupo, this.defaultColDefGrupos.objeto);
            }
          } else if (this.actualizar === false) {
            let coincidencias: number;
            coincidencias = 0;
            // this.arregloCons.map((con) => {
            //   if (this.grupoSeleccionado.CON_CODIGO === con.CON_CODIGO) {
            //     coincidencias++;
            //   }
            // });
            const rsdata: any = await this.grupoService.encontrarArtCntaySer(this.grupoSeleccionado.CON_CODIGO, 'C');
          //let strCodigo = 0;
          if (rsdata !== null) {
            for (const rs of rsdata) {
              coincidencias = rs.NUMERO;
            }
          }
            if (coincidencias !== 0 || this.grupoSeleccionado.CON_CODIGO === '') {
              this.grupoService.insertarGrupo(this.grupoSeleccionado).subscribe(() => {
                this.message.add({
                  key: 'maegroup',
                  severity: 'success',
                  summary: 'Inserción exitosa',
                  detail: 'Se insertó el grupo correctamente'
                });
                this.auditoriagral.registrarAuditoria('VEN_MAEGRUPO', String(this.grupoSeleccionado.GRU_CODIGO), 'I', '',
                  '01', '', '', '', '').subscribe(() => {});
                this.grupoService.venmaegrupo = this.grupoService.venmaegrupo.filter((val, j) => j !==
                  this.grupoService.venmaegrupo.indexOf(this.grupoService.getGrupos()[this.grupoService.venmaegrupo.length - 1]));
                this.aggrid.isEditable(false);
                this.nuevoCampo = false;
              }, error1 => {
                let mensaje: string;
                mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                this.message.add({
                  key: 'maegroup',
                  severity: 'error',
                  summary: 'Inserción de Grupo',
                  detail: mensaje
                });
                this.btnNuevo = false;
                this.btnGuardar = true;
                this.btnBorrar = false;
                this.btnRegresar = true;
                this.grupoService.getGrupos().subscribe((res) => {
                  this.grupoService.venmaegrupo = res;
                  this.aggrid.refreshaggrid(this.grupoService.venmaegrupo, this.defaultColDefGrupos.objeto);
                });
              });
            } else {
              this.message.add({
                key: 'maegroup',
                severity: 'error',
                summary: 'Error en Cuenta contable',
                detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
              });
              this.grupoSeleccionado.GRU_CODIGO = '';
              this.aggrid.refreshaggrid(this.grupoService.venmaegrupo, this.defaultColDefGrupos.objeto);
            }
          }
        });
      } else if (this.permisoGrupo.PERACTUALIZACION === 0) {
        this.message.add({
          key: 'maegroup',
          severity: 'error',
          summary: 'Actualización de Grupo',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }
    }
    if (valor === 'Borrar') {
      if (this.permisoGrupo.PERELIMACION === 1) {
        this.aggrid.isEditable(false);
        if (this.grupoSeleccionado === undefined) {
          this.message.add({
            key: 'maegroup',
            severity: 'error',
            summary: 'Eliminacion de Grupo',
            detail: 'Seleccion Incorrecta, no se ha seleccionado un grupo'
          });
        } else {
          this.confirmarGrupo();
        }
      } else if (this.permisoGrupo.PERELIMACION === 0) {
        this.message.add({
          key: 'maegroup',
          severity: 'error',
          summary: 'Eliminación de Grupo',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
      }
    }
    
    if (valor === 'Cancelar') {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.aggrid.isEditable(false);
      this.grupoService.getGrupos().subscribe((res) => {
        this.grupoService.venmaegrupo = res;
        this.aggrid.refreshaggrid(this.grupoService.venmaegrupo, this.defaultColDefGrupos.objeto);
      });
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
  selectedGrupo(valor) {
    if (valor.object !== null) {
      this.grupoSeleccionado = valor.object;
      if (this.nuevoCampo === true) {
        this.actualizar = false;
      } else if (this.nuevoCampo === false) {
        this.actualizar = true;
        if (this.grupoSeleccionado !== undefined) {
          this.codigoAux = this.grupoSeleccionado.GRU_CODIGO;
        }
      }
    }
  }
  
  buscarCon() {
    this.busqService.busquedaConMaeCon().subscribe((res: any[]) => {
      this.arregloCons = res;
    });
  }
  
  confirmarGrupo() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el grupo',
      header: 'Eliminar Grupo',
      icon: 'pi pi-exclamation-triangle',
      key: 'MaeGroup',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminargrupo();
      },
      reject: () => {
      }
    });
  }
  
  eliminargrupo() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.grupoService.eliminarGrupo(this.grupoSeleccionado).subscribe(() => {
      this.message.add({
        key: 'maegroup',
        severity: 'success',
        summary: 'Eliminación exitosa',
        detail: 'Se eliminó el grupo correctamente'
      });
      this.auditoriagral.registrarAuditoria('VEN_MAEGRUPO', String(this.grupoSeleccionado.GRU_CODIGO), 'E', '',
        '01', '', '', '', '').subscribe(() => {});
      this.grupoService.venmaegrupo = this.grupoService.venmaegrupo.filter((val, j) => j !==
        this.grupoService.venmaegrupo.indexOf(this.grupoService.getGrupos()[this.grupoService.venmaegrupo.length - 1]));
      this.grupoService.venmaegrupo = this.grupoService.venmaegrupo.filter((val, j) => j !==
        this.grupoService.venmaegrupo.indexOf(this.grupoSeleccionado));
      this.aggrid.refreshaggrid(this.grupoService.venmaegrupo, this.defaultColDefGrupos.objeto);
    }, error1 => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.message.add({
        key: 'maegroup',
        severity: 'error',
        summary: 'Eliminación de el grupo',
        detail: mensaje
      });
    });
  }
  
  cambio(cambio) {
    this.btnGuardar = false;
    this.btnRegresar = false;
  }
}
