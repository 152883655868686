<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="cierre">
</p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarCierre" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
						 [botonRegresar]="btnRegresar" [botonPrimero]="btnPrimero" [botonAnterior]="btnAnterior"
						 [botonSiguiente]="btnSiguiente" [botonUltimo]="btnUltimo" [barraBotonesCRUD]="false"
						 [botonBuscar]="btnBuscar" [barraBotones3]="true" [botonBinocular]="true"
						 (signal)="manejarSenales($event)">
		</app-invmaebarra>
	</div>
</div>

<p-tabView [(activeIndex)]="activeIndex">
	<p-tabPanel header="Cierre de Caja">
		<div class="divgrups">
			<!-- Primera Separación -->
			<div class="p-grid">
				<div class="p-col-1">
					<span>Número:</span>
				</div>
				<div class="p-col-1">
					<input pInputText type="text"
						   [(ngModel)]="encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO"
						   disabled>
				</div>
				<div class="p-col-1"></div>
				<div class="p-col-1">
					<span>Fecha cierre:</span>
				</div>
				<div class="p-col-1">
					<p-calendar id="fechaCierre"
								[inputStyle]="{width: '100%'}"
								dateFormat="dd/mm/yy" [(ngModel)]="date1"
								[disabled]="inputDes"></p-calendar>
				</div>
				<div class="p-col-1"></div>
				<div class="p-col-1">
					<span>Fecha desde:</span>
				</div>
				<div class="p-col-1">
					<p-calendar id="fechaDesde" [inputStyle]="{width: '100%'}"
								dateFormat="dd/mm/yy"
								[(ngModel)]="date2" [disabled]="inputDes"></p-calendar>
				</div>
				<div class="p-col-1"></div>
				<div class="p-col-1">
					<span>Fecha hasta:</span>
				</div>
				<div class="p-col-1">
					<p-calendar id="fechaHasta" [inputStyle]="{width: '100%'}"
								dateFormat="dd/mm/yy"
								[(ngModel)]="date3" [disabled]="inputDes"></p-calendar>

				</div>
				<div class="p-col-1"></div>
				<div class="p-col-12" style="margin-top: -9px; margin-bottom: -20px">
					<div class="p-grid">
						<div class="p-col-3">
							<!--							<div class="p-col-12">-->
							<div class="p-grid">
								<div class="p-col-2">
									<span style="float: left">Cajas: &nbsp;&nbsp;&nbsp; </span>
								</div>
								<div class="p-col-8">
									<p-dropdown [style]="{'minWidth':'100%'}" [options]="cajas"
												optionLabel="LABEL" [panelStyle]="{'width':'100%'}"
												[disabled]="inputDes && disabledCmb"
												(onChange)="cambioDropCaj()"
												[(ngModel)]="opcSelectCaj"></p-dropdown>
								</div>
								<div class="p-col-2">
									<button *ngIf="btnBuscarDes === false" class="littlebuttons"
											style="background-image: url(../../../assets/images/iconos/buscar.png)"
											(click)="abrirCajas()"
											[disabled]="btnBuscarDes"></button>
									<button *ngIf="btnBuscarDes === true" class="littlebuttons"
											style="background-image: url(../../../assets/images/iconos/buscar-d.png)"
											(click)="abrirCajas()"
											[disabled]="btnBuscarDes"></button>
								</div>
							</div>
							<!--							</div>-->
						</div>
						<div class="p-col-3">
							<!--							<div class="p-col-12">-->
							<div class="p-grid">
								<div class="p-col-2">
									<span>Orígenes:</span>
								</div>
								<div class="p-col-8">
									<p-dropdown [style]="{'minWidth':'100%'}" [options]="origenes"
												optionLabel="LABEL" [panelStyle]="{'width':'100%'}"
												[disabled]="inputDes && disabledCmb"
												(onChange)="cambioDropOri()"
												[(ngModel)]="opcSelectOri"></p-dropdown>
								</div>
								<div class="p-col-2">
									<button *ngIf="btnBuscarDes === false" class="littlebuttons"
											style="background-image: url(../../../assets/images/iconos/buscar.png)"
											(click)="abrirOrigenes()" [disabled]="btnBuscarDes"></button>
									<button *ngIf="btnBuscarDes === true" class="littlebuttons"
											style="background-image: url(../../../assets/images/iconos/buscar-d.png)"
											(click)="abrirOrigenes()" [disabled]="btnBuscarDes"></button>
								</div>
							</div>
							<!--							</div>-->
						</div>
						<div class="p-col-3">
							<!--							<div class="p-col-12">-->
							<div class="p-grid">
								<div class="p-col-3">
									<span>Vendedores:</span>
								</div>
								<div class="p-col-8">
									<p-dropdown [style]="{'minWidth':'100%'}" [options]="vendedores"
												optionLabel="LABEL" [panelStyle]="{'width':'100%'}"
												[disabled]="inputDes && disabledCmb"
												(onChange)="cambioDropVen()"
												[(ngModel)]="opcSelectVen"></p-dropdown>
								</div>
								<div class="p-col-1">
									<button *ngIf="btnBuscarDes === false" class="littlebuttons"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="abrirVendedores()" [disabled]="btnBuscarDes"></button>
									<button *ngIf="btnBuscarDes === true" class="littlebuttons"
											style="background-image: url(../../../assets/images/iconos/buscar-d.png);"
											(click)="abrirVendedores()" [disabled]="btnBuscarDes"></button>
								</div>
							</div>
							<!--							</div>-->
						</div>
						<div class="p-col-1">
						</div>
						<div class="p-col-1">
							<button (click)="llenarTabla()" pButton class="aceptar"
									style="line-height: 2.5em; font-family: Arial, serif; margin-top: -2px; height: 30px; width: 100px; ">
								Aceptar
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="divgrups">
			<!-- Segunda separación -->
			<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
				<div class="p-col-4"></div>
				<div class="p-col-2">
					<!--									<p-progressSpinner *ngIf="spin" [style]="{width: '20px', height: '20px'}"-->
					<!--													   animationDuration=".5s">-->
					<!--									</p-progressSpinner>-->
				</div>
				<div class="p-col-4"></div>
				<div class="p-col-2">
					<p-checkbox label="Seleccionar Todos" (click)="selectAll()" [(ngModel)]="desSelect"
								binary="true"
								[disabled]="inputDes"></p-checkbox>
				</div>
			</div>
		</div>

		<app-aggridsaci *ngIf="!spin" [width]="" [height]="largo" [enableSorting]="true"
						[enableFilter]="true"
						[rowData]="cajaservice.camposCierreCaja" [frameworkComponents]="frameworkComponents"
						[animateRows]="true"
						[rowSelection]="rowSelection" [columnDefs]="columnDefsCierre"
						[defaultColDef]="defaultColDefCierre"
						[rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="false"
						(selected)="seleccionCierre($event)">
		</app-aggridsaci>
		<div class="p-col-12"></div>
		<div class="p-col-12">
			<span style="font-weight: bold;color: #002752;font-size: 1em">F9 -- &gt; para ver sumatorias por tipo</span>
		</div>
		<div class="p-col-12"></div>
	</p-tabPanel>
</p-tabView>
<!--<div class="p-grid" *ngIf="activeIndex === 1">-->
<!--	<div class="p-col-8"></div>-->
<!--	<div class="p-col-4">-->
<!--		<div class="p-grid">-->
<!--			<div class="p-col-4"></div>-->
<!--			<div class="p-col-2">-->
<!--				<label for="total" style="font-weight: bold;">Total:</label>-->
<!--			</div>-->
<!--			<div class="p-col-2">-->
<!--				<input type="text" id="total" [disabled]="true" [(ngModel)]="sumaTotalMov">-->
<!--			</div>-->
<!--			<div class="p-col-2"></div>-->
<!--		</div>-->
<!--	</div>-->
<!--</div>-->


<p-dialog header="Selección de datos" [(visible)]="displayDialogBus"
		  [transitionOptions]="'0ms'"
		  [modal]="false" [style]="{width: '70vw', height: '70vh'}">
	<app-banbusqueda *ngIf="displayDialogBus === true" [arregloConsulta]="arregloBusCaja" [arrayOptions]="types"
					 (opcionSeleccionada)="manejarSeleccion($event)"
					 (salirsignal)="cerrarDialogo($event)"></app-banbusqueda>
</p-dialog>

<p-dialog header="Búsqueda de datos" [(visible)]="displayDialogBus2"
		  [transitionOptions]="'0ms'"
		  [modal]="false" [style]="{width: '70vw', height: '70vh'}">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloBus"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloBus" [consulta]="consulta" [tabla]="tabla"
					 [busquedaParams]="busquedacampos" [where]="where"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Totales de cuadre de caja" [(visible)]="displayDialogSuma"
		  [transitionOptions]="'0ms'" [modal]="false" [style]="{width: '45vw', minWidth:'45vw'}"
		  [minY]="80"
		  [positionTop]="100">
	<p-tabView>
		<p-tabPanel header="Totales">
			<p-table [value]="sumatoria">
				<ng-template pTemplate="header">
					<tr>
						<th style="width: 80px">
							Tipo de Pago
						</th>
						<th style="width: 80px">
							Total
						</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-rowData>
					<tr>
						<td>
							<label>{{rowData.TipoPago}}</label>
						</td>
						<td style="text-align: -webkit-right">
							<label>{{rowData.Total}}</label>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="footer" let-rowData>
					<tr>
						<td style="background-color: powderblue">Totales</td>
						<td style="background-color: powderblue; text-align: -webkit-right">
							{{total}}
						</td>
					</tr>
				</ng-template>
			</p-table>
		</p-tabPanel>
	</p-tabView>
</p-dialog>

<p-dialog header="Clave de Autorización" [(visible)]="claveDialog" [transitionOptions]="'0ms'"
		  [modal]="false" [style]="{width: '60vw', height: 'auto'}">
	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-4">
					<label for="clave" style="font-weight: bold"> Clave de autorizaión: </label>
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-4">
					<input type="text" pInputText id="clave" [(ngModel)]="claveAutorizacion"
						   (keydown.enter)="comprobarClave()"
						   placeholder="Ingrese la clave">
				</div>
				<div class="p-col-1"></div>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog header="Reportes" [(visible)]="displayDialogReportes"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '40vw', height: '400px'}">
	<app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
						 [strNumDoc]="encabezadosCierreCajaSeleccionada.ENCCUADRECAJA_NUMERO"
						 [strCampo]="'ENCCUADRECAJA_NUMERO'" (cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>
