import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { TecDetmant } from '../tecinterfaces/tecdetmant';
import { VenDetordtra } from '../tecinterfaces/vendetordtra';
import { DatePipe } from '@angular/common';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { VenEncordtra } from '../tecinterfaces/venencordtra';
import { VenTrnregllamadasot } from '../tecinterfaces/ventrnregllamadasot';
import { VenTrncorreosot } from '../tecinterfaces/ventrncorreosot';

@Injectable({
  providedIn: 'root'
})
export class TecencordtraService {

  public way: string;
  public tableEtiquetas: TecDetmant[][] = [];
  public tableDetalle: VenDetordtra[][] = [];
  public tableRegistroLLamadas: VenTrnregllamadasot[][] = [];
  public tableEnvioCorreos: VenTrncorreosot[][] = [];
  public encordtra: VenEncordtra[] = [];

  public hmpConfiguracion: any[];
  public decimalesTotal: number;

  public iva: number;
  public cmbIva: string;

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private datePipe: DatePipe,
    private init: NuevoComponentService,
    private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }

  getOrdenTrabajo(ordtranumero) {
    return this.http.post(this.way + '/serviciotecnico/ordentrabajo/41ddc6daebf76c00000', {
      elementos: {
        ordtranumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erpBusquedaCliente(strValor, strValue, strParams) {
    return this.http.post(this.way + '/ventas/facturas/tq8cgrtwkhkurwk', {
      elementos: {
        Valor: strValor,
        Value: strValue,
        Params: strParams
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDropEstEquipo() {
    return this.http.post(this.way + '/serviciotecnico/ordentrabajo/83badde32ee760000000', {
      elementos: {
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDatosTecnicos() {
    return this.http.post(this.way + '/serviciotecnico/ordentrabajo/83bad5771ceb98000000', {
      elementos: {
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDatosTecnicosOrdenTrabajo(strNumOrdTra) {
    return this.http.post(this.way + '/serviciotecnico/ordentrabajo/20eeb55dc73ae600000000000000', {
      elementos: {
        strNumOrdTra
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getequiposcliente(clicodigo) {
    return this.http.post(this.way + '/serviciotecnico/equiposcliente/10775daf4b38e300000000', {
      elementos: {
        clicodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getencmant(item) {
    return this.http.post(this.way + '/serviciotecnico/equiposcliente/20eebaecb2afd', {
      elementos: {
        clicodigo: item.CLI_CODIGO,
        equicodigo: item.EQUI_CODIGO,
        mantserie: item.MANT_SERIE,
        comcodigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getdetmant(item) {
    return this.http.post(this.way + '/serviciotecnico/equiposcliente/20eeb5ddb2afd', {
      elementos: {
        clicodigo: item.CLI_CODIGO,
        equicodigo: item.EQUI_CODIGO,
        mantserie: item.MANT_SERIE,
        comcodigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  /*encontrarequipo(codigo) {
    return this.http.post(this.way + '/serviciotecnico/equipos/3aecc5fbb56dda00000', {
      elementos: {
        codigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }*/

  erBusquedaVendedor(strCampo, strTabla, strValue, strParam) {
    return this.http.post(this.way + '/ventas/facturas/3rw6uc890a4nleo', {
      elementos: {
        CAMPO: strCampo,
        VALOR: strTabla,
        VALUE: strValue,
        PARAM: strParam
      },
    }, this.confIniciales.httpOptions()).toPromise();

  }

  getDetordtra(strEncOrdTraNro) {
    return this.http.post(this.way + '/serviciotecnico/ordentrabajo/83bad7771b6f780', {
      elementos: {
        strEncOrdTraNro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getRegllamada(strOT) {
    return this.http.post(this.way + '/serviciotecnico/ordentrabajo/107776e856aab3000', {
      elementos: {
        strOT
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getCorreos(strOT) {
    return this.http.post(this.way + '/serviciotecnico/ordentrabajo/20eeb31bdbb1c', {
      elementos: {
        strOT
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getHistorial(strCodCliente) {
    return this.http.post(this.way + '/serviciotecnico/ordentrabajo/83bb1973b8dc980', {
      elementos: {
        strCodCliente
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarOrdenTrabajo(venencordtra) {
    return this.http.post(this.way + '/serviciotecnico/ordentrabajo/12bf1dbeab6ebb00000000', {
      elementos: {
        p_ordtra_numero: venencordtra.ORDTRA_NUMERO,
        p_com_codigo: venencordtra.COM_CODIGO,
        p_ordtra_fecha: venencordtra.ORDTRA_FECHA,
        p_ordtra_fechavence: venencordtra.ORDTRA_FECHAVENCE,
        p_ordtra_estado: venencordtra.ORDTRA_ESTADO,
        p_usu_identificacion: venencordtra.USU_IDENTIFICACION,
        p_ordtra_estequipo: venencordtra.ORDTRA_ESTEQUIPO,
        p_ordtra_fechaingreso: venencordtra.ORDTRA_FECHAINGRESO,
        p_ordtra_fechadiagnostico: venencordtra.ORDTRA_FECHADIAGNOSTICO,
        p_ordtra_problema: venencordtra.ORDTRA_PROBLEMA,
        p_ordtra_fechadiag: venencordtra.ORDTRA_FECHADIAG,
        p_ordtra_diagnostico: venencordtra.ORDTRA_DIAGNOSTICO,
        p_ordtra_fechaproc: venencordtra.ORDTRA_FECHAPROC,
        p_ordtra_procedimiento: venencordtra.ORDTRA_PROCEDIMIENTO,
        p_ordtra_productocli: venencordtra.ORDTRA_PRODUCTOCLI,
        p_ordtra_serie: venencordtra.ORDTRA_SERIE,
        p_ordtra_dddesc: '',
        p_ordtra_ddserie: '',
        p_ordtra_memdesc: '',
        p_ordtra_memserie: '',
        p_ordtra_uniopt: venencordtra.ORDTRA_UNIOPT,
        p_ordtra_adaptador: '',
        p_ordtra_bateria: '',
        p_ordtra_obspro: venencordtra.ORDTRA_OBSPRO,
        p_ordtra_iva: venencordtra.ORDTRA_IVA,
        p_ordtra_neto: venencordtra.ORDTRA_NETO,
        p_ordtra_total: venencordtra.ORDTRA_TOTAL,
        p_cli_codigo: venencordtra.CLI_CODIGO,
        p_ordtra_baseiva: venencordtra.ORDTRA_BASEIVA,
        p_ordtra_basecero: venencordtra.ORDTRA_BASECERO,
        p_ordtra_idiva: '',
        p_ordtra_ubicacion: venencordtra.ORDTRA_UBICACION,
        p_ordtra_memadi: '',
        p_ordtra_memadiserie: '',
        p_cli_codigo_fac: venencordtra.CLI_CODIGO_FAC,
        p_ordtra_fechaentregado: venencordtra.ORDTRA_FECHAENTREGADO,
        p_ordtra_fechamaximagar: venencordtra.ORDTRA_FECHAMAXIMAGAR,
        p_ordtra_marca: venencordtra.ORDTRA_MARCA,
        p_ordtra_modelo: venencordtra.ORDTRA_MODELO,
        p_ven_codigo: venencordtra.VEN_CODIGO,
        p_ordtra_fechaasignaciontec: venencordtra.ORDTRA_FECHAASIGNACIONTEC,
        p_ordtra_ubicacionimagen: '',
        p_ordtra_fallareportada: venencordtra.ORDTRA_FALLAREPORTADA,
        p_ordtra_reportetecnico: venencordtra.ORDTRA_REPORTETECNICO,
        p_ordtra_horaentregado: venencordtra.ORDTRA_HORAENTREGADO,
        p_ordtra_horasignacion: venencordtra.ORDTRA_HORASIGNACION,
        p_ordtra_memoria2desc: '',
        p_ordtra_serie1memoria2: '',
        p_ordtra_serie2memoria2: '',
        p_ordtra_serie3memoria2: '',
        p_ordtra_estadoOT: venencordtra.ORDTRA_ESTADOOT,
        p_ordtra_fechaDatosTec: ''
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  EliminartodoRpttecnicoot(NroOrd) {
    return this.http.post(this.way + '/serviciotecnico/ordentrabajo/7565695d5bee1c0000000000000000', {
      elementos: {
        p_ordtra_numero: NroOrd,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  InsertarRpttecnicoot(item) {
    return this.http.post(this.way + '/serviciotecnico/ordentrabajo/95f8edf55bde7800000000000', {
      elementos: {
        p_ordtra_numero: item.ORDTRA_NUMERO,
        p_com_codigo: '01',
        p_ref_tipo: item.REF_TIPO,
        p_ref_codigo: item.REF_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  InsertarDetordtra(item) {
    return this.http.post(this.way + '/serviciotecnico/ordentrabajo/12bf1dbeab6d7700000000', {
      elementos: {
        p_ordtra_numero: item.ORDTRA_NUMERO,
        p_com_codigo: '01',
        p_detordtra_linea: item.DETORDTRA_LINEA,
        p_detordtra_tipodet: item.DETORDTRA_TIPODET,
        p_bod_codigo: item.BOD_CODIGO,
        p_detordtra_codigo: item.DETORDTRA_CODIGO,
        p_detordtra_descripcion: item.DETORDTRA_DESCRIPCION,
        p_detordtra_unidad: item.DETORDTRA_UNIDAD,
        p_detordtra_cantidad: item.DETORDTRA_CANTIDAD,
        p_detordtra_precio: item.DETORDTRA_PRECIO,
        p_detordtra_total: item.DETORDTRA_TOTAL,
        p_detordtra_tribiva: item.DETORDTRA_TRIBIVA,
        p_detordtra_iva: item.DETORDTRA_IVA,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarDetordtra(item) {
    return this.http.post(this.way + '/serviciotecnico/ordentrabajo/eacad2bab6d7780000000', {
      elementos: {
        p_ordtra_numero: item.ORDTRA_NUMERO,
        p_com_codigo: '01',
        p_detordtra_linea: item.DETORDTRA_LINEA,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  InsertarRegllam(item) {
    return this.http.post(this.way + '/serviciotecnico/ordentrabajo/4afc76faadedd0000000', {
      elementos: {
        p_ordtra_numero: item.ORDTRA_NUMERO,
        p_com_codigo: '01',
        p_trnregllamot_linea: item.TRNREGLLAMOT_LINEA,
        p_trnregllamot_fecha: item.TRNREGLLAMOT_FECHA,
        p_trnregllamot_hora: item.TRNREGLLAMOT_HORA,
        p_ven_codigo: item.VEN_CODIGO,
        p_trnregllamot_tipo: item.TRNREGLLAMOT_TIPO,
        p_trnregllamot_motivo: item.TRNREGLLAMOT_MOTIVO,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarRegllam(item) {
    return this.http.post(this.way + '/serviciotecnico/ordentrabajo/3ab2b4aeadedd0000', {
      elementos: {
        p_ordtra_numero: item.ORDTRA_NUMERO,
        p_com_codigo: '01',
        p_trnregllamot_linea: item.TRNREGLLAMOT_LINEA,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  InsertarCorreo(item) {
    return this.http.post(this.way + '/serviciotecnico/ordentrabajo/257e3b7d56d98e0000', {
      elementos: {
        p_ordtra_numero: item.ORDTRA_NUMERO,
        p_com_codigo: '01',
        p_trncorreosot_linea: item.TRNCORREOSOT_LINEA,
        p_trncorreosot_fecha: item.TRNCORREOSOT_FECHA,
        p_trncorreosot_hora: item.TRNCORREOSOT_HORA,
        p_ven_codigo: item.VEN_CODIGO,
        p_trncorreosot_motivo: item.TRNCORREOSOT_MOTIVO,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarCorreo(item) {
    return this.http.post(this.way + '/serviciotecnico/ordentrabajo/1d595a5756d98e0000', {
      elementos: {
        p_ordtra_numero: item.ORDTRA_NUMERO,
        p_com_codigo: '01',
        p_trncorreosot_linea: item.TRNCORREOSOT_LINEA,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }


  consultarArticuloSelProm(strCodArticulo) {
    const FPLOTSIM = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FPLOTSIM').CFG_VALOR1;
    const strFecha = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    const strHora = this.datePipe.transform(new Date(), 'HH:mm');

    return this.http.post<any[]>(this.way + '/ventas/facturas/w4fejsnym7i0pmt', {
      elementos: {
        LISTA: 'A',
        BOD_CODIGO: '001',
        strFecha: strFecha + '',
        strHora: strHora + '',
        ART_CODIGO: strCodArticulo,
        COM_CODIGO: '01'
      },
      datos: {
        FPLOTSIM: FPLOTSIM,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarEncordtra(ORDTRANUMERO) {
    return this.http.post(this.way + '/serviciotecnico/ordentrabajo/eacad2bab6ebb00000000', {
      elementos: {
        p_ordtra_numero: ORDTRANUMERO,
        p_com_codigo: '01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  seleccionarRegistro(tipo, ordtranumero) {
    return this.http.post(this.way + '/serviciotecnico/ordentrabajo/1c755cc64b17dc00000000', {
      elementos: {
        p_tipo: tipo,
        p_com_codigo: '01',
        p_ordtra_numero: ordtranumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  calcularTotales() {
    this.calcularPrecioTotal();
    this.SumarTotal();
  }

  calcularPrecioTotal() {

    let dblPrecioTotal = 0;
    let dblValor1 = 0;

    for (const item of this.tableDetalle[this.init.tabs[this.init.tabindex].indice]) {
      if (item.DETORDTRA_CODIGO !== '') {
        dblValor1 = 0;
        dblValor1 = Number(item.DETORDTRA_CANTIDAD) * Number(item.DETORDTRA_PRECIO);
        item.DETORDTRA_TOTAL = dblValor1.toFixed(this.decimalesTotal);
        item.DETORDTRA_CANTIDAD = Number(item.DETORDTRA_CANTIDAD).toFixed(this.decimalesTotal);
        item.DETORDTRA_PRECIO = Number(item.DETORDTRA_PRECIO).toFixed(this.decimalesTotal);
        dblPrecioTotal = dblPrecioTotal + dblValor1;
      }
    }
    this.encordtra[this.init.tabs[this.init.tabindex].indice].ORDTRA_NETO = dblPrecioTotal.toFixed(this.decimalesTotal);
    return dblPrecioTotal;
  }

  SumarTotal() {
    let dblBaseIva: number = 0;
    let dblTotal: number = 0;
    let dblBaseCero: number = 0;
    let dblValorIva: number = 0;
    let Iva: number = 0;
    let dblBaseTIva: number = 0;
    let dblBaseTCero: number = 0;
    let dblTotalDet: number = 0;
    let dblSumaIva: number = 0;
    let dblSumaTotal: number = 0;

    let strTipoIva = this.cmbIva;
    strTipoIva = 'Neto + Iva';

    for (const item of this.tableDetalle[this.init.tabs[this.init.tabindex].indice]) {
      if (item.DETORDTRA_CODIGO !== '') { // servicio,articulo			
        dblTotalDet = Number(item.DETORDTRA_TOTAL);

        if (strTipoIva === "Exento") {
          dblSumaIva = 0;
          dblBaseTIva = 0;
          dblBaseTCero = dblBaseTCero + dblTotalDet;
          dblTotal = dblTotal + dblTotalDet;
          dblSumaTotal = dblSumaTotal + dblTotalDet;
        }

        if (strTipoIva === "Incluido Iva") {
          if (item.DETORDTRA_TRIBIVA !== "N") {
            Iva = ((dblTotalDet * (this.iva / 100)) / (1 + (this.iva / 100)));
            item.DETORDTRA_IVA = Number(Iva.toFixed(this.decimalesTotal));
            dblBaseIva = (dblTotalDet / (1 + (this.iva / 100)));
            dblValorIva = Iva;
            dblBaseCero = 0;
          } else {
            dblBaseIva = 0;
            dblValorIva = 0;
            dblBaseCero = dblTotalDet;
          }

          dblBaseTIva = dblBaseTIva + dblBaseIva;
          dblSumaIva = dblSumaIva + dblValorIva;
          dblBaseTCero = dblBaseTCero + dblBaseCero;

          dblTotal = dblTotal + dblBaseIva + dblValorIva + dblBaseCero;
          dblSumaTotal = dblSumaTotal + dblBaseIva + dblBaseCero;
        }

        if (strTipoIva === "Neto + Iva") {// Neto + Iva

          if (item.DETORDTRA_TRIBIVA !== "N") {
            Iva = (dblTotalDet * (this.iva / 100));

            item.DETORDTRA_IVA = Number(Iva.toFixed(this.decimalesTotal));
            dblBaseIva = dblTotalDet;
            dblValorIva = Iva;
            dblBaseCero = 0;
          } else {
            dblBaseIva = 0;
            dblValorIva = 0;
            dblBaseCero = dblTotalDet;
          }

          dblBaseTIva = dblBaseTIva + dblBaseIva;
          dblSumaIva = dblSumaIva + dblValorIva;
          dblBaseTCero = dblBaseTCero + dblBaseCero;

          dblTotal = dblTotal + dblBaseIva + dblValorIva + dblBaseCero;
          dblSumaTotal = dblSumaTotal + dblBaseIva + dblBaseCero;
        }

        console.log(dblBaseTIva);
        console.log(dblSumaIva);
        console.log(dblBaseTCero);
        console.log(dblTotal);
        console.log(dblSumaTotal);
        this.encordtra[this.init.tabs[this.init.tabindex].indice].ORDTRA_BASEIVA = dblBaseTIva.toFixed(this.decimalesTotal);
        this.encordtra[this.init.tabs[this.init.tabindex].indice].ORDTRA_IVA = dblSumaIva.toFixed(this.decimalesTotal);
        this.encordtra[this.init.tabs[this.init.tabindex].indice].ORDTRA_BASECERO = dblBaseTCero.toFixed(this.decimalesTotal);
        this.encordtra[this.init.tabs[this.init.tabindex].indice].ORDTRA_TOTAL = dblTotal.toFixed(this.decimalesTotal);
        this.encordtra[this.init.tabs[this.init.tabindex].indice].ORDTRA_NETO = dblSumaTotal.toFixed(this.decimalesTotal);

      }
    }
  }

}