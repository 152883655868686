import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ComExistencias } from 'src/app/com/cominterfaces/comestadisticas';
import { SaciDocAlmacenado } from '../advantageinterfaces/uploadfiles';
import { UploadfilesService } from '../advantageservices/uploadfiles.service';
import { MessageService, ConfirmationService } from 'primeng';
import { Usuario } from 'src/app/usuario';

@Component({
  selector: 'app-uploadfiles',
  templateUrl: './uploadfiles.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class UploadfilesComponent implements OnInit {

  @Input() txtOrigen = '';
  @Input() txtNumComprobante = '';
  
  @Output() signalSalir = new EventEmitter<boolean>();
  documentName = '';
  documentSize = 0;
  documentbase64: any = '';
  documentType: any = '';
  numDocument = 0;
  detalleDocumentos: SaciDocAlmacenado[] = [];
  rucCompania: any = '';

  allPermisos = false;
  capacidadAlmacenamiento = 0;

  constructor(
    public uploadFileService: UploadfilesService,
    private message: MessageService, 
    private confirmationService: ConfirmationService,
    public usuario: Usuario
  ) { 

  }

  async ngOnInit() {
    const usu = await this.uploadFileService.obtenerPermisoUsuario(this.usuario.identificacion);
    if (usu[0].USU_ALMACENARDOC === 1) {
      this.allPermisos = true;
    } else {
      this.allPermisos = false;
    }
    
    const rs = await this.uploadFileService.obtenerDocumentos(this.txtOrigen, this.txtNumComprobante);
    this.numDocument = rs.length;
    if (rs.length > 0) {
      rs.map(item => {
        item.TAMANIO_DOCUMENTO = Number(item.TAMANIO_DOCUMENTO).toFixed(6) + ' MB';
      })
      this.detalleDocumentos = rs;
    } else {
      const documento: SaciDocAlmacenado = {};
      documento.ORIGEN_TRANSACCION = '';
      documento.NUMERO_COMPROBANTE = '';
      documento.NOMBRE_ORIGINAL = '';
      documento.NOMBRE_FISICO = '';
      this.detalleDocumentos.push(documento);
    }

    const com = await await this.uploadFileService.obtenerCompania();
    if (com !== null) {
      for (const emp of com) {
        this.rucCompania = emp.COMRUCI;
        this.capacidadAlmacenamiento = emp.COM_CAPACIDADOCUMENTOS === null ? 0 : emp.COM_CAPACIDADOCUMENTOS;
      }
    }
  }

  async onBasicUpload(event){
    this.documentName = event[0].name;
    this.documentSize = event[0].size;
    this.documentType = this.documentName.split('.').slice(-1);
   
    this.documentbase64 = await this.readfile2(event[0]);
    console.log(this.documentbase64);

  }

  async uploadDocument() {
    if (this.documentName === '' || this.documentbase64 === '') {
      this.mensajeAlerta('warn','Mensaje', 'Seleccione un archivo');  
    } else {
      if (String(this.documentType[0]).toLowerCase() !== 'pdf' 
      && String(this.documentType[0]).toLowerCase() !== 'png' 
      && String(this.documentType[0]).toLowerCase() !== 'jpg'
      && String(this.documentType[0]).toLowerCase() !== 'jpeg') {
        this.mensajeAlerta('warn', 'Mensaje', 'Tipo de archivo no admitido');
        return;
      }
      
      if (this.numDocument === 3) {
        this.mensajeAlerta('info', 'Información', 'No se puede subir más de 3 archivos');
        this.documentName = '';
        this.documentSize = 0;
        this.documentbase64 = '';
        return;
      }

      const tamKB = this.documentSize/1024;
      const tamMB = tamKB/1024;

      const capacidadUtilizada = await this.calcularMegas() ;
      const capacidadRestante = Number(this.capacidadAlmacenamiento - capacidadUtilizada).toFixed(5);
      
      if ((capacidadUtilizada + tamMB) > this.capacidadAlmacenamiento) {
        this.mensajeAlerta('warn', 'Mensaje', 'Superó el límite de almacenamiento, espacio libre: ' + capacidadRestante + ' MB');
        this.documentName = '';
        this.documentSize = 0;
        this.documentbase64 = '';
        return;
      }

      if (Number(tamMB) > 3) {
        this.mensajeAlerta('info', 'Información', 'El tamaño del archivo no puede ser mayor a 3 MB, tamaño actual: ' + Number(tamMB).toFixed(5) + ' MB');
        this.documentName = '';
        this.documentSize = 0;
        this.documentbase64 = '';
        return;
      }

      const newName = this.rucCompania + '_' + this.txtOrigen + '_' + this.txtNumComprobante + '_' + this.documentName;

      try {
        const res: any = await this.uploadFileService.uploadFiles(this.documentbase64, newName);
        if (res.resultado) {
          await this.uploadFileService.guardarDocAlmacenado(this.txtOrigen, this.txtNumComprobante, this.documentName, newName, Number(tamMB));
          const rs = await this.uploadFileService.obtenerDocumentos(this.txtOrigen, this.txtNumComprobante);
          this.numDocument = rs.length;
          if (rs.length > 0) {
            rs.map(item => {
              item.TAMANIO_DOCUMENTO = Number(item.TAMANIO_DOCUMENTO).toFixed(6) + ' MB';
            })
             this.detalleDocumentos = rs;
          }
          this.mensajeAlerta('success', 'Información', 'Archivo guardado exitosamente..!!!');
        } else {
          this.mensajeAlerta('warn', 'Información', 'El archivo que intenta guardar ya existe....!!!');

        }
        
        
      } catch (err) {
        this.mensajeAlerta('error', 'Error', err.error);
      }

    }
  }

  readfile2(event){
    return new Promise((res,rej)=>{
      let inp=event;
      let reader =new FileReader();
      reader.onload= function(e){
        res(reader.result);
      };
      reader.readAsBinaryString(inp);
     
    });

  }

  async descargar(docName, docNameOriginal) {
    try {
      const rs: any = await this.uploadFileService.getDocument(docName);
      this.downloadDocument(rs.respuesta,docNameOriginal);
    } catch (err) {
      this.mensajeAlerta('error', 'Error', err.error);
    }
    
  }

  downloadDocument (strBase64, filename) {
    const byteCharacters = atob(strBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i< byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: 'application/octet-stream'});
    
    const data = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = data;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTimeout(() => {
      window.URL.revokeObjectURL(data);
    },1000)
  }

  async visualizar(docName, docNameOriginal) {
    const extension = docNameOriginal.split('.').slice(-1);
    try {
      const rs: any = await this.uploadFileService.getDocument(docName);
      const byteCharacters = atob(rs.respuesta);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i< byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      if (String(extension[0]).toLowerCase() === 'pdf') {
        const blob = new Blob([byteArray], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      } else {
        const blob = new Blob([byteArray], {type: 'image/' +extension[0]});
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      }
      
    } catch (err) {
      this.mensajeAlerta('error', 'Error', err.error);
    }
    
    
  }

  confirmarEliminar(docName) {
    this.confirmationService.confirm({
      message: '¿ Esta seguro que desea eliminar el documento ?',
      header: 'Eliminar Documento',
      icon: 'pi pi-exclamation-triangle',
      key: 'uploadfilesconf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminar(docName);
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }


  async eliminar(docName) {
  
    try {

      await this.uploadFileService.deleteDocument(docName);
      await this.uploadFileService.eliminarDocumento(docName);
      this.mensajeAlerta('success', 'Información', 'Archivo eliminado exitosamente..!!!');
      const rs = await this.uploadFileService.obtenerDocumentos(this.txtOrigen, this.txtNumComprobante);
      this.numDocument = rs.length;
      this.detalleDocumentos = [];
      if (rs.length > 0) {
        this.detalleDocumentos = rs;
        rs.map(item => {
          item.TAMANIO_DOCUMENTO = Number(item.TAMANIO_DOCUMENTO).toFixed(6) + ' MB';
        })
      } else {
        const documento: SaciDocAlmacenado = {};
        documento.ORIGEN_TRANSACCION = '';
        documento.NUMERO_COMPROBANTE = '';
        documento.NOMBRE_ORIGINAL = '';
        documento.NOMBRE_FISICO = '';
        documento.TAMANIO_DOCUMENTO = '';
        this.detalleDocumentos.push(documento);
      }


    } catch (err) {
      this.mensajeAlerta('error', 'Error', err.error);
    }
  }

  salir() {
    this.signalSalir.emit(true);
  }

  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'uploadfiles',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

  async calcularMegas() {
    let tamanio = 0;
    try {
      const rs = await this.uploadFileService.obtenerMegasUitilizadas();
      tamanio = rs[0].TAMANIO;
    } catch (err) {
      this.mensajeAlerta('error', 'Error', err.error);
    }
    return tamanio;
  }

}
