import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {VenDetfacZ} from '../../ven/veninterfaces/vendetfacz';
import {AggridBanComponent} from '../../ban/aggrid-ban/aggrid-ban.component';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {ComdetfacproService} from '../comservicios/comdetfacpro.service';

@Component({
  selector: 'app-comdetfacpro',
  templateUrl: './comdetfacpro.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class ComdetfacproComponent implements OnInit, OnChanges {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  largo: string;
  allSelected: boolean;
  rowSelection = 'multiple';
  public frameworkComponents;
  @Input() encfacNumero: string;
  @Output() detallesSeleccionados = new EventEmitter<VenDetfacZ[]>();
  @Output() signalSalir = new EventEmitter<boolean>();
  rowStyle;
  defaultColDef;
  columnDefsDetfac = [
    {
      headerName: '', field: 'SELECCIONADO', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    { // 0
      headerName: 'Tipo', field: 'DETFACPRO_TIPODET', width: 80, editable: false,
      // editable: true,
    },
    { // 0
      headerName: 'Bodega', field: 'BOD_CODIGO', width: 80, editable: false,
      // editable: true,
    },
    { // 1
      headerName: 'Código', field: 'DETFACPRO_CODIGO', width: 150, editable: false,
    },
    { // 2
      headerName: 'Descripción', field: 'DETFACPRO_DESCRIPCION', width: 200, editable: false
    },
    { // 4
      headerName: 'Ice', field: 'DETFACPRO_TRIBICE', width: 60, editable: false
      // , editable: true,
    },
    {
      headerName: 'Unidad', field: 'DETFACPRO_UNIDAD',  editable: false, width: 80,
    },
    { // 6
      headerName: 'Cantidad', field: 'DETFACPRO_CANTIDAD', cellStyle: {textAlign: 'right'}, width: 90, editable: false
      // , editable: true,
    },
    { // 7
      headerName: 'Costo', field: 'DETFACPRO_COSTO', cellStyle: {textAlign: 'right'}, width: 90, editable: false
      // , editable: true,
    },
    { // 8
      headerName: 'Descuento', field: 'DETFACPRO_PORDES', cellStyle: {textAlign: 'right'}, width: 90, editable: false
      // , editable: true,
    },
    { // 8
      headerName: 'Dsct1(%)', field: 'DETFACPRO_PORDES', cellStyle: {textAlign: 'right'}, width: 90, editable: false
      // , editable: true,
    },
    { // 8
      headerName: 'Dsct2(%)', field: 'DETFACPRO_PORDES2', cellStyle: {textAlign: 'right'}, width: 90, editable: false
      // , editable: true,
    },
    { // 8
      headerName: 'Dsct3(%)', field: 'DETFACPRO_PORDES3', cellStyle: {textAlign: 'right'}, width: 90, editable: false
      // , editable: true,
    },
    { // 9
      headerName: 'Total', field: 'DETFACPRO_TOTAL', cellStyle: {textAlign: 'right'}, width: 90, editable: false
      // , editable: true,
    },
  ];
  constructor(public detfacService: ComdetfacproService) {
    this.agTable();
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDef = {
      editable: true,
      width: 100,
      objeto: 'detfacdev'
    };
  }
  ngOnInit() {
    this.allSelected = false;
    this.largo = '400';
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.encfacNumero !== undefined) {
      this.detfacService.getDetfacDev(this.encfacNumero).subscribe((res) => {
        this.detfacService.detfac = res;
        this.detfacService.detfac.map((detf) => {
          detf.SELECCIONADO = false;
         detf.DETFACPRO_CANTIDAD = detf.SUMA;
          detf.DETFACPRO_COSTO = Number(detf.DETFACPRO_COSTO).toFixed(7);
          detf.DETFACPRO_CANTIDAD = Number(detf.DETFACPRO_CANTIDAD).toFixed(4);
        });
      });
    }
  }
  seleccionar() {
    if (this.allSelected === true) {
      this.detfacService.detfac.map((detf) => {
        detf.SELECCIONADO = true;
      });
      this.aggrid.refreshaggrid(this.detfacService.detfac, this.defaultColDef.objeto);
    } else if (this.allSelected === false) {
      this.detfacService.detfac.map((detf) => {
        detf.SELECCIONADO = false;
      });
      this.aggrid.refreshaggrid(this.detfacService.detfac, this.defaultColDef.objeto);
    }
  }
  enviarDetalles() {
    let arregloaEnviar: VenDetfacZ[];
    arregloaEnviar = [];
    this.detfacService.detfac.map((detf) => {
      if (detf.SELECCIONADO === true) {
        arregloaEnviar.push(detf);
      }
    });
    this.detallesSeleccionados.emit(arregloaEnviar);
  }
  enviarSalir() {
    this.signalSalir.emit(true);
  }
  
  
}
