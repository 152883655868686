<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="BanTrnBanN"></p-toast>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="true" [botonBorrar]="true" [barraBotonesDesplaz]="true"
						 [barraBotones3]="true" [barraBotonesAsiCon]="false" [botonBuscar]="false"
						 [botonverAsiento]="false"
						 (signal)="manejarSenales($event)">
		</app-invmaebarra>
	</div>
</div>
<app-aggridsaci [width]=""
				[height]="largo"
				
				[enableFilter]="true"
				[rowData]="bantrnbanService.banTrnban"
				[frameworkComponents]="frameworkComponents"
				[animateRows]="true"
				[rowSelection]="rowSelection"
				[columnDefs]="columnDefsBanTrnBan"
				[defaultColDef]="defaultBanTrnBan"
				[rowStyle]="rowStyle"
				[singleClickEdit]="false"
				[botones]="false"
				[mostrarGuardar]="false"
				[botonesAdicionales]="false"
				[comboOpciones]=""
				(selected)="seleccionItem($event)"
				[tipodetalle]=""
>
</app-aggridsaci>

<p-dialog header="Movimiento de Bancos" [(visible)]="displayDialogBanTrnBan"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width:'80vw', height:'120vh'}"
		  [closable]="false"
		  [draggable]="false"
		  (onHide)="CerrarTrnBanEdit($event)">
	<app-bantrnbanedit *ngIf="displayDialogBanTrnBan"
					   [banCodigo]="banCodigoTrnBan" [fechaEmision]="fechaEmisionTrnBan" [org]="origenTrnBan"
					   [tipo]="tipoTrnBan"
					   [an]="anuladoTrnBan" [numero]="trnbanNumeroTrnBan" [referencia]="referenciaTrnBan"
					   [concepto]="conceptoTrnBan" [importe]="importeTrnBan" [beneficiario]="beneficiarioTrnBan"
					   [numDoc]="nroDocumentoTrnBan" [concilia]="conciliaTrnBan" [fechaconta]="fechaVencimientoTrnBan"
					   [asiNum]="asiNroTrnBan" [numConcilia]="numeroConciliacionTrnBan"
					   [registroContable]="regContableTrnBan"
					   [asiento]="asientoTrnBan" [isBandera]="isBanderaTrnBan"
					   (signalSalir)="recibirSenalesBanTrnban($event)"
					   [tipoAccion]="tipoAccionTrnBan"></app-bantrnbanedit>
</p-dialog>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlgmae [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
						[where]="condicion"
						[busquedaParams]="busquedacampos"
						(opcionSeleccionada)="manejarSeleccion($event)"
						[busquedaCompuesta]="false"></app-busquedadlgmae>
</p-dialog>

<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width:'80vw', height:'120vh'}"
		  [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="NumAsiRegCont" [abiertoDesde]="'banTrnBan'"
					(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	<p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
	</p-dialog>	
</div>