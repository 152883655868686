import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { MessageService } from 'primeng';
import { ActDetSalida } from '../actinterfaces/actdetsalida';
import { Observable } from 'rxjs';
import { ActDetVarSalida } from '../actinterfaces/actdetvarsalida';

@Injectable({
  providedIn: 'root'
})
export class ActencsalidaService {

  private way: string;
  public detSalidaArray: ActDetSalida[][] = [];
  public detVariosSalidaArray: ActDetVarSalida[][] = [];

  constructor(private http: HttpClient, 
    private confIniciales: ConfInicialesService, 
    private init: NuevoComponentService,
    private message: MessageService) { 
      this.way = sessionStorage.getItem('way');
  }

  depreciacionAcumulada(parametros) {
    return this.http.post<any[]>(this.way + '/act/enckardex/35d9da99eb7aaa', {
      elementos: {
        p_act_codigo: parametros[0],
        p_fecha: parametros[1]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarNovedadSalida(parametro) {
    return this.http.post<any[]>(this.way + '/act/encsalida/75656be3fc55640', {
      elementos: {
        p_encsalida_codigo: parametro[0]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  reversarActivoDadoBaja(parametro) {
    return this.http.post<any[]>(this.way + '/act/encsalida/36efb55b5aa6a', {
      elementos: {
        p_encsalida_codigo: parametro[0]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarTrnKardexRevSalida(parametro) {
    return this.http.post<any[]>(this.way + '/act/encsalida/6ddf76f8aac9a8', {
      elementos: {
        p_encsalida_codigo: parametro[0],
        p_com_codigo: parametro[1]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarKardex(parametro) {
    return this.http.post<any[]>(this.way + '/act/encsalida/14cec1d762b6d', {
      elementos: {
        p_encsalida_codigo: parametro[0],
        p_com_codigo: parametro[1],
        p_usuidentificacion: parametro[2]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarTrnTranf(parametro) {
    return this.http.post<any[]>(this.way + '/act/encsalida/299df776abbddc', {
      elementos: {
        p_encsalida_codigo: parametro[0],
        p_com_codigo: parametro[1]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  daBajaActivos(parametro) {
    return this.http.post<any[]>(this.way + '/act/encsalida/6ab6b54d4a67640', {
      elementos: {
        p_encsalida_codigo: parametro[0]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  confirmarSalida(parametro) {
    return this.http.post<any[]>(this.way + '/act/encsalida/33177cb76e2ab2', {
      elementos: {
        p_encsalida_codigo: parametro[0]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarEstadoDoc(strEstado, strCodigo) {
    return this.http.post<any[]>(this.way + '/act/encsalida/533be555dceb700', {
      elementos: {
        estado: strEstado,
        codigo: strCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  claveAutorizacion() {
    return this.http.post<any[]>(this.way + '/act/encsalida/32aafb95eee372', {
      elementos: {}
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarAsientoContable(parametro) {
    return this.http.post<any[]>(this.way + '/act/encsalida/1075d5c93317eb000', {
      elementos: {
        p_encsalida_codigo: parametro[0],
        p_com_codigo: parametro[1],
        p_usuidentificacion: parametro[2]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarSalidaActivo(parametro) {
    return this.http.post<any[]>(this.way + '/act/encsalida/3ab2b4b6e84b9c', {
      elementos: {
        p_encsalida_codigo: parametro[0],
        p_com_codigo: parametro[1],
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  guardarDetVariosSalida(parametro) {
    return this.http.post<any[]>(this.way + '/act/encsalida/43cadfd5b97154', {
      elementos: {
        p_encsalida_codigo: parametro[0],
        p_detvariossal_numsec: parametro[1],
        p_con_codigo: parametro[2],
        p_detvariossal_descripcion: parametro[3],
        p_detvariossal_importe: parametro[4],
        p_detvariossal_orden: parametro[5],
        p_cen_codigo:parametro[6]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  guardarDetSalida(parametro) {
    return this.http.post<any[]>(this.way + '/act/encsalida/43cadb5dde2ab0', {
      elementos: {
        p_encsalida_codigo: parametro[0],
        p_detsalida_numsec: parametro[1],
        p_act_codigo: parametro[2],
        p_com_codigo: parametro[3],
        p_detsalida_referencia: parametro[4],
        p_detsalida_comentario: parametro[5],
        p_detsalida_descripcion: parametro[6],
        p_detsalida_orden: parametro[7],
        p_detsalida_importe: parametro[8],
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  guardarEncSalida(parametro) {
    return this.http.post<any[]>(this.way + '/act/encsalida/43cadb78aac9a8', {
      elementos: {
        p_encsalida_codigo: parametro[0],
        p_com_codigo: parametro[1],
        p_encsalida_tipousuario: parametro[2],
        p_cli_codigo: parametro[3],
        p_ciu_codigo: parametro[4],
        p_encsalida_oficina: parametro[5],
        p_encsalida_departamento: parametro[6],
        p_encsalida_area: parametro[7],
        p_encsalida_autorizado: parametro[8],
        p_encsalida_referencia: parametro[9],
        p_encsalida_observ_1: parametro[10],
        p_encsalida_observ_2: parametro[11],
        p_encsalida_observ_3: parametro[12],
        p_encsalida_observ_4: parametro[13],
        p_encsalida_estado: parametro[14],
        p_cli_codigo2: parametro[15],
        p_encsalida_motivo: parametro[16],
        p_encsalida_fecha: parametro[17],
        p_encsalida_cmpbcompra: parametro[18]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  verificarActivoDadoBaja(parametro) {
    return this.http.post<any[]>(this.way + '/act/encsalida/7ddb93d4ceb568', {
      elementos: {
        p_act_codigo: parametro[0]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarDetVarSalida(parametro) {
    return this.http.post<any[]>(this.way + '/act/encsalida/756566bbbf56f80', {
      elementos: {
        p_encsalida_codigo: parametro[0],
        p_detvariossal_numsec: parametro[1]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarDetSalida(parametro) {
    return this.http.post<any[]>(this.way + '/act/encsalida/eacacd7778aac800', {
      elementos: {
        p_encsalida_codigo: parametro[0],
        p_detsalida_numsec: parametro[1]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  navegarRegistros(parametro) {
    return this.http.post<any[]>(this.way + '/act/encsalida/babee82b7b74280', {
      elementos: {
        p_tipo: parametro[0],
        p_com_codigo: parametro[1],
        p_encsalida_codigo: parametro[2],
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerDatosDetVarios(strCodigo) {
    return this.http.post<any[]>(this.way + '/act/encsalida/662fcfab72e2ac0', {
      elementos: {
        codigo: strCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerDatosDetSalida(parametro) {
    return this.http.post<any[]>(this.way + '/act/encsalida/3317e35dde2ab2', {
      elementos: {
        p_encsalida_codigo: parametro[0]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerDatos(strCodigo) {
    return this.http.post<any[]>(this.way + '/act/encsalida/3317e7aad5771c', {
      elementos: {
        codigo: strCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  consultarMotivo() {
    return this.http.post<any[]>(this.way + '/act/encsalida/3317e7ab6c7660', {
      elementos: {}
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarNomMaeEmpleado(strParametro): Observable<any> {
    return this.http.post<any[]>(this.way + '/act/maeactivo/75d97c594e75b40', {
        elementos: {
          parametro: strParametro === '' ? 0 : strParametro,
        },
      }, this.confIniciales.httpOptions()
    );
  }

  encontrarVenMaeCliente(strParametro): Observable<any> {
    return this.http.post<any[]>(this.way + '/act/maeactivo/3aecfbaf6532b2', {
        elementos: {
          parametro: strParametro,
        },
      }, this.confIniciales.httpOptions()
    );
  }

  encontrarVenMaeCiudad(strParametro): Observable<any> {
    return this.http.post<any[]>(this.way + '/act/maeactivo/ebb317fbaf653000', {
        elementos: {
          parametro: strParametro
        },
      }, this.confIniciales.httpOptions()
    );
  }

  encontrarReferencia(strParametro, strTipo): Observable<any> {
    return this.http.post<any[]>(this.way + '/act/maeactivo/75d9c5325656dc0', {
        elementos: {
          parametro: strParametro,
          tipo: strTipo
        },
      }, this.confIniciales.httpOptions()
    );
  }

  
  encontrarRegistroPromise(strCampo, strTabla, strCondicion): Promise<any[]> {
    return this.http.post<[]>(this.way + '/act/maeactivo/3aecdba12e7778', {
      elementos: {
        campo: strCampo,
        tabla: strTabla,
        condicion: strCondicion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
//CDPJ
ercentrocuenta(strCuenta) {
  return this.http.post<any[]>(this.way + '/ventas/facturas/1db63afdde19e7', {
    elementos: {
      strCuenta
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
//CDPJ

  
}
