import { Injectable } from '@angular/core';
import { Venencdevfac } from '../../ven/veninterfaces/venencdevfac';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { Venexpodvf } from '../../ven/veninterfaces/venexpodvf';
import { Comdetdevfac } from '../cominterfaces/comdetdevfac';
import { Comencdevfac } from '../cominterfaces/comencdevfac';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';

@Injectable({
  providedIn: 'root'
})
export class ComencdevfacService {


  public way: string;

  public encDevSeleccionadaarray: Comencdevfac[] = [];
  public detdevFacarray: Comdetdevfac[][] = [];
  public strFacturaExportadaarray: string[] = [];
  public strTipoDescuentoarray: string[] = [];
  public strTipoIvaarray: string[] = [];
  public lblTotalDscValorarray: string[] = [];
  public lblTotalDscPorarray: string[] = [];
  public dblPorcDescGlbDevFCarray: number[] = [];
  public dblValorDescGlbDevFCarray: number[] = [];

  public hmpConfiguracion: any[];
  public decimalesCantidadDevFC: number;
  public decimalesPrecioDevFC: number;
  public decimalesTotalDevFC: number;
  public dblPorcIvaDevF = 12;
  public idcelldevfacpro = '';//GSRF
    //CDPJ
    public tariivacod:string;
    public porceiva:number;
    //CDPJ

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService, private message: MessageService,
    private init: NuevoComponentService) {

    this.way = sessionStorage.getItem('way');
  }

  getEncDevF(): Observable<any[]> {
    this.way = sessionStorage.getItem('way');
    return this.http.post<any[]>(this.way + '/compras/devfacturas/83baebb1aefbd80', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  getEncDevFProm(): Promise<any[]> {
    this.way = sessionStorage.getItem('way');
    return this.http.post<any[]>(this.way + '/compras/devfacturas/83baebb1aefbd80', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDetDevF(devolucionCodigo: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/83bad775aefbd80', {
      elementos: {
        ENCDEV_NUMERO: devolucionCodigo
      }
    }, this.confIniciales.httpOptions());
  }

  guardarENCDEV(devEnc: Comencdevfac): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/10f2b6d56dd763000', {
      elementos: {
        p_encdev_numero: devEnc.ENCDEV_NUMERO,
        p_com_codig: '01',
        p_encdev_referencia: devEnc.ENCDEV_REFERENCIA,
        p_encdev_fechaemision: devEnc.ENCDEV_FECHAEMISION,
        p_encdev_estado: devEnc.ENCDEV_ESTADO,
        p_pro_codigo: devEnc.PRO_CODIGO,
        p_encdev_contacto: devEnc.ENCDEV_CONTACTO,
        p_encdev_total: Number(devEnc.ENCDEV_TOTAL),
        p_encdev_comentario: devEnc.ENCDEV_COMENTARIO,
        p_asi_nro: devEnc.ASI_NRO,
        p_encdev_totalneto: Number(devEnc.ENCDEV_TOTALNETO),
        p_encdev_valordes: Number(devEnc.ENCDEV_VALORDES),
        p_encdev_porcedes: Number(devEnc.ENCDEV_PORCEDES),
        p_encdev_valoriva: Number(devEnc.ENCDEV_VALORIVA),
        p_encdev_valorice: Number(devEnc.ENCDEV_VALORICE),
        p_encdev_baseiva: Number(devEnc.ENCDEV_BASEIVA),
        p_encdev_baseice: Number(devEnc.ENCDEV_BASEICE),
        p_encdev_basecero: Number(devEnc.ENCDEV_BASECERO),
        p_encdev_porceiva: Number(devEnc.ENCDEV_PORCEIVA),
        p_encdev_iva: devEnc.ENCDEV_IVA,
        p_encdev_serie: devEnc.ENCDEV_SERIE,
        p_encdev_autorizacion: devEnc.ENCDEV_AUTORIZACION,
        p_maemotivo_codigo: devEnc.MAEMOTIVO_CODIGO,
        p_encdev_fechacaducidad: devEnc.ENCDEV_FECHACADUCIDAD,
        p_encDev_otros: devEnc.DETDEVPRO_OTROS
      }
    }, this.confIniciales.httpOptions());
  }

  guardarENCDEVProm(devEnc: Comencdevfac): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/10f2b6d56dd763000', {
      elementos: {
        p_encdev_numero: devEnc.ENCDEV_NUMERO,
        p_com_codig: '01',
        p_encdev_referencia: devEnc.ENCDEV_REFERENCIA,
        p_encdev_fechaemision: devEnc.ENCDEV_FECHAEMISION,
        p_encdev_estado: devEnc.ENCDEV_ESTADO,
        p_pro_codigo: devEnc.PRO_CODIGO,
        p_encdev_contacto: devEnc.ENCDEV_CONTACTO,
        p_encdev_total: Number(devEnc.ENCDEV_TOTAL),
        p_encdev_comentario: devEnc.ENCDEV_COMENTARIO,
        p_asi_nro: devEnc.ASI_NRO,
        p_encdev_totalneto: Number(devEnc.ENCDEV_TOTALNETO),
        p_encdev_valordes: Number(devEnc.ENCDEV_VALORDES),
        p_encdev_porcedes: Number(devEnc.ENCDEV_PORCEDES),
        p_encdev_valoriva: Number(devEnc.ENCDEV_VALORIVA),
        p_encdev_valorice: Number(devEnc.ENCDEV_VALORICE),
        p_encdev_baseiva: Number(devEnc.ENCDEV_BASEIVA),
        p_encdev_baseice: Number(devEnc.ENCDEV_BASEICE),
        p_encdev_basecero: Number(devEnc.ENCDEV_BASECERO),
        p_encdev_porceiva: Number(devEnc.ENCDEV_PORCEIVA),
        p_encdev_iva: devEnc.ENCDEV_IVA,
        p_encdev_serie: devEnc.ENCDEV_SERIE,
        p_encdev_autorizacion: devEnc.ENCDEV_AUTORIZACION,
        p_maemotivo_codigo: devEnc.MAEMOTIVO_CODIGO,
        p_encdev_fechacaducidad: devEnc.ENCDEV_FECHACADUCIDAD,
        p_encDev_otros: devEnc.DETDEVPRO_OTROS
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }


  insertarDetalleProm(comdetDVF: Comdetdevfac): Promise<any> {
    return this.http.post(this.way + '/compras/devfacturas/4afc76faadb5dc', {
      elementos: {
        p_encdev_numero: comdetDVF.ENCDEV_NUMERO,
        p_com_codigo: '01',
        p_detdev_linea: comdetDVF.DETDEV_LINEA,
        p_detdev_tipodet: comdetDVF.DETDEV_TIPODET,
        p_bod_codigo: comdetDVF.BOD_CODIGO,
        p_detdev_codigo: comdetDVF.DETDEV_CODIGO,
        p_detdev_descripcion: comdetDVF.DETDEV_DESCRIPCION,
        p_uni_codigo: comdetDVF.UNI_CODIGO,
        p_detdev_cantidad: Number(comdetDVF.DETDEV_CANTIDAD),
        p_detdev_costo: Number(comdetDVF.DETDEV_COSTO),
        p_detdev_valdes: Number(comdetDVF.DETDEV_VALDES),
        p_detdev_pordes: Number(comdetDVF.DETDEV_PORDES),
        p_detdev_total: Number(comdetDVF.DETDEV_TOTAL),
        p_detdev_promocion: comdetDVF.DETDEV_PROMOCION,
        p_detdev_poriva: Number(comdetDVF.DETDEV_PORIVA),
        p_detdev_iva: Number(comdetDVF.DETDEV_IVA),
        p_detdev_tribice: comdetDVF.DETDEV_TRIBICE,
        p_detdev_ice: Number(comdetDVF.DETDEV_ICE),
        p_detdev_porceice: Number(comdetDVF.DETDEV_PORCEICE),
        p_detdev_baseiva: Number(comdetDVF.DETDEV_BASEIVA),
        p_detdev_baseice: Number(comdetDVF.DETDEV_BASEICE),
        p_detdev_basecero: Number(comdetDVF.DETDEV_BASECERO),
        p_detdev_numero: comdetDVF.DETDEV_NUMERO,
        p_cen_codigo: comdetDVF.CEN_CODIGO,
        p_detdev_pordes2: Number(comdetDVF.DETDEV_PORDES2),
        p_detdev_pordes3: Number(comdetDVF.DETDEV_PORDES3),
        p_doc_tipo: comdetDVF.DOC_TIPO,
        p_doc_linea: comdetDVF.DETDEVDOC_LINEA,
        p_detfacpro_tiposri: comdetDVF.DETFACPRO_TIPOSRI,
        p_detdev_cantidadund: Number(comdetDVF.DETDEV_CANTIDADUND),
        p_tipgas_codigo: comdetDVF.TIPGAS_CODIGO,
        p_tariiva_codigo:comdetDVF.TARIIVA_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarDetalle(comdetDVF: Comdetdevfac): Observable<any> {
    return this.http.post(this.way + '/compras/devfacturas/4afc76faadb5dc', {
      elementos: {
        p_encdev_numero: comdetDVF.ENCDEV_NUMERO,
        p_com_codigo: '01',
        p_detdev_linea: comdetDVF.DETDEV_LINEA,
        p_detdev_tipodet: comdetDVF.DETDEV_TIPODET,
        p_bod_codigo: comdetDVF.BOD_CODIGO,
        p_detdev_codigo: comdetDVF.DETDEV_CODIGO,
        p_detdev_descripcion: comdetDVF.DETDEV_DESCRIPCION,
        p_uni_codigo: comdetDVF.UNI_CODIGO,
        p_detdev_cantidad: Number(comdetDVF.DETDEV_CANTIDAD),
        p_detdev_costo: Number(comdetDVF.DETDEV_COSTO),
        p_detdev_valdes: Number(comdetDVF.DETDEV_VALDES),
        p_detdev_pordes: Number(comdetDVF.DETDEV_PORDES),
        p_detdev_total: Number(comdetDVF.DETDEV_TOTAL),
        p_detdev_promocion: comdetDVF.DETDEV_PROMOCION,
        p_detdev_poriva: Number(comdetDVF.DETDEV_PORIVA),
        p_detdev_iva: Number(comdetDVF.DETDEV_IVA),
        p_detdev_tribice: comdetDVF.DETDEV_TRIBICE,
        p_detdev_ice: Number(comdetDVF.DETDEV_ICE),
        p_detdev_porceice: Number(comdetDVF.DETDEV_PORCEICE),
        p_detdev_baseiva: Number(comdetDVF.DETDEV_BASEIVA),
        p_detdev_baseice: Number(comdetDVF.DETDEV_BASEICE),
        p_detdev_basecero: Number(comdetDVF.DETDEV_BASECERO),
        p_detdev_numero: comdetDVF.DETDEV_NUMERO,
        p_cen_codigo: comdetDVF.CEN_CODIGO,
        p_detdev_pordes2: Number(comdetDVF.DETDEV_PORDES2),
        p_detdev_pordes3: Number(comdetDVF.DETDEV_PORDES3),
        p_doc_tipo: comdetDVF.DOC_TIPO,
        p_doc_linea: comdetDVF.DETDEVDOC_LINEA,
        p_detfacpro_tiposri: comdetDVF.DETFACPRO_TIPOSRI,
        p_detdev_cantidadund: Number(comdetDVF.DETDEV_CANTIDADUND),
        p_tipgas_codigo: comdetDVF.TIPGAS_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }

  actualizarIva(devNumero, iva): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/533be5565c54c00', {
      elementos: {
        encdev_numero: devNumero,
        IVA: iva
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarIvaProm(devNumero, iva): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/533be5565c54c00', {
      elementos: {
        encdev_numero: devNumero,
        IVA: iva
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarEnc(devEnc: Comencdevfac): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/7565695dd7635c0', {
      elementos: {
        p_encdev_numero: devEnc.ENCDEV_NUMERO,
        p_com_codigo: '01',
        p_asi_nro: devEnc.ASI_NRO
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarEncProm(devEnc: Comencdevfac): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/7565695dd7635c0', {
      elementos: {
        p_encdev_numero: devEnc.ENCDEV_NUMERO,
        p_com_codigo: '01',
        p_asi_nro: devEnc.ASI_NRO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarDetDVF(detDVF: Comdetdevfac): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/7565695daeeb5c0', {
      elementos: {
        p_encdev_numero: detDVF.ENCDEV_NUMERO,
        p_com_codigo: '01',
        p_detdev_linea: detDVF.DETDEV_LINEA
      },
    }, this.confIniciales.httpOptions());
  }

  anularAsiento(asiNro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/abfaaadab9276000', {
      elementos: {
        p_ASI_nro: asiNro,
        p_COM_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarSeriales(numDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/75656e3b72555c0', {
      elementos: {
        p_trnkardexotr_numero: numDoc,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarSerialesProm(numDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/75656e3b72555c0', {
      elementos: {
        p_trnkardexotr_numero: numDoc,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarTrnDoc(numDoc, tipoDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/1d595a5756fbbc0000', {
      elementos: {
        TRNDOC_NUMDOC: numDoc,
        TRNDOC_TIPO: tipoDoc
      }
    }, this.confIniciales.httpOptions());
  }

  getEncDevolucionNum(numEncdvf): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/10775d7635dfc0000', {
      elementos: {
        ENCDEV_NUMERO: numEncdvf
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerClave(strAccion): Observable<any> {
    return this.http.post<any>(this.way + '/compras/devfacturas/c2faebbb6caac00', {
      elementos: {
        accion: strAccion
      },

    }, this.confIniciales.httpOptions());

  }


  buscarEncfacPro(strProCodigo, strEncDevDetDevNumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/5fb8ebb1ea66780', {
      elementos: {
        pro_codigo: strProCodigo,
        encdev_detdevnumero: strEncDevDetDevNumero
      }

    }, this.confIniciales.httpOptions());
  }

  buscarEncNotRec(strProCodigo, strEncDevDetDevNumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/5fb8ebb2f8eedc0', {
      elementos: {
        pro_codigo: strProCodigo,
        encdev_detdevnumero: strEncDevDetDevNumero
      }

    }, this.confIniciales.httpOptions());
  }


  insertarEleTrnDoc(docNum: string, tipoFac: string, usuario: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/95f8eabbbbbb700', {
      elementos: {
        p_trndoc_numdoc: docNum,
        p_trndoc_tipo: tipoFac,
        p_trndoc_estado: 'VA',
        p_trndoc_resultado: '',
        p_trndoc_ubicaarch: '',
        p_trndoc_secuencial: '',
        p_usuidentificacion: usuario,
        p_com_codigo: '01',
        p_trndoc_tipoamb_fe: '1'
      },
    }, this.confIniciales.httpOptions());
  }

  reenviarCorreo(docNum: string, fecha: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/1b75ff256d98df000', {
      elementos: {
        TRNDOC_NUMDOC: docNum,
        FECHA: fecha
      }
    }, this.confIniciales.httpOptions());
  }

  anularDev(encDev, estado): Observable<any> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/55fd556daefbd40', {
      elementos: {
        p_ENCDEV_NUMERO: encDev.ENCDEV_NUMERO,
        p_COM_CODIGO: '01',
        p_ENCDEV_estado: estado,
        p_asi_nro: encDev.ASI_NRO,
      },
    }, this.confIniciales.httpOptions());
  }

  actualizarAsiNro(encDev): Observable<any> {
    return this.http.post<any>(this.way + '/compras/devfacturas/a677caac95c96000', {
      elementos: {
        ENCDVF_NUMERO: encDev
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerSaldoFactura(encDev, encFac): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/c2fbc555b87a980', {
      elementos: {
        ENCDVF_NUMERO: encDev,
        ENCFAC_NUMERO: encFac
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarEstFac(numFac, cliCod): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/14cef9dcebd4cf0000', {
      elementos: {
        p_com_codigo: '01',
        p_cli_codigo: cliCod,
        p_encfac_numero: numFac,
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarKardex(numDev): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/533be5565456dc0', {
      elementos: {
        p_numero: numDev,
        p_com_codigo: '01',
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarKardexProm(numDev): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/533be5565456dc0', {
      elementos: {
        p_numero: numDev,
        p_com_codigo: '01',
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  recalcularCostoPromedioDoc(consulta, fecha): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/db98cc733bc3700', {
      elementos: {
        p_cadena: consulta,
        p_com_codigo: '01',
        p_fecha: fecha
      }
    }, this.confIniciales.httpOptions());
  }

  recalcularCostoPromedioDocProm(consulta, fecha): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/db98cc733bc3700', {
      elementos: {
        p_cadena: consulta,
        p_com_codigo: '01',
        p_fecha: fecha
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarAsiento(numDev): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/20ebbb6adab9280000', {
      elementos: {
        p_encdev_numero: numDev,
        p_com_codigo: '01',
        p_tipdia_codigo: 'ADC',
        p_origen: 'COM',
        p_usuario: this.usuario.identificacion
      }
    }, this.confIniciales.httpOptions());
  }

  generarAsientoProm(numDev): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/20ebbb6adab9280000', {
      elementos: {
        p_encdev_numero: numDev,
        p_com_codigo: '01',
        p_tipdia_codigo: 'ADC',
        p_origen: 'COM',
        p_usuario: this.usuario.identificacion
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarAsientoConsig(numDev): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/83aeae4998bf280', {
      elementos: {
        p_encdev_numero: numDev,
        p_com_codigo: '01',
        p_tipdia_codigo: 'ADC',
        p_origen: 'COM',
        p_usuario: this.usuario.identificacion
      }
    }, this.confIniciales.httpOptions());
  }

  generarAsientoConsigProm(numDev): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/83aeae4998bf280', {
      elementos: {
        p_encdev_numero: numDev,
        p_com_codigo: '01',
        p_tipdia_codigo: 'ADC',
        p_origen: 'COM',
        p_usuario: this.usuario.identificacion
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarAsientoRecep(numDev): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/41d75725b731d8', {
      elementos: {
        p_encdev_numero: numDev,
        p_com_codigo: '01',
        p_tipdia_codigo: 'ADC',
        p_origen: 'COM',
        p_usuario: this.usuario.identificacion
      }
    }, this.confIniciales.httpOptions());
  }

  generarAsientoRecepProm(numDev): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/41d75725b731d8', {
      elementos: {
        p_encdev_numero: numDev,
        p_com_codigo: '01',
        p_tipdia_codigo: 'ADC',
        p_origen: 'COM',
        p_usuario: this.usuario.identificacion
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getAsiento(codigo: string): Observable<any> {
    return this.http.post<any>(this.way + '/compras/devfacturas/20eeab9275fb8', {
      elementos: {
        ENCDEV_NUMERO: codigo
      }
    }, this.confIniciales.httpOptions());
  }

  getAsientoProm(codigo: string): Promise<any> {
    return this.http.post<any>(this.way + '/compras/devfacturas/20eeab9275fb8', {
      elementos: {
        ENCDEV_NUMERO: codigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarEstado(detalleDev): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/a677caac9dcea800', {
      elementos: {
        p_ENCORDCOM_NUMERO: detalleDev.ENCDEV_NUMERO,
        p_COM_CODIGO: '01',
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarEstadoProm(detalleDev): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/a677caac9dcea800', {
      elementos: {
        p_ENCORDCOM_NUMERO: detalleDev.ENCDEV_NUMERO,
        p_COM_CODIGO: '01',
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  recalcularCostosArtEliminados(documento, fecha): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/1b73198e2b7d75000', {
      elementos: {
        p_documento: documento,
        p_com_codigo: '01',
        p_fecha: fecha
      }
    }, this.confIniciales.httpOptions());
  }

  recalcularCostosArtEliminadosProm(documento, fecha): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/1b73198e2b7d75000', {
      elementos: {
        p_documento: documento,
        p_com_codigo: '01',
        p_fecha: fecha
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarNumeracion(encDev: Venencdevfac): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/a676feb3b6a64800', {
      elementos: {
        p_NUM_clase: encDev.ENCDVF_GRUPO,
        p_COM_codigo: '01',
        p_NUM_docref: 'DVF',
        p_NUM_nemonico: encDev.ENCDVF_NEMONICO,
        p_NUM_serie: encDev.ENCDVF_SERIE
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarEstadoDev(estado, devolucion) {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/533aee754dc35c0', {
      elementos: {
        ENCDVF_ESTADO: estado,
        ENCDVF_NUMERO: devolucion.ENCDVF_NUMERO,
        CLI_CODIGO: devolucion.CLI_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }


  getListaPrecios(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/83bb59676e64b00', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  getBodegas(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/20eeaf0d7415c', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  obtenerNumeracion(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/30bebaeeddfd6', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  obtenerSaldoCliente(cliCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/185f78aab70cad000', {
      elementos: {
        CLI_CODIGO: cliCodigo,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerNombreProv(proCodigo: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/c2fb7c597b76780', {
      elementos: {
        PRO_CODIGO: proCodigo
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerEstadoReferencia(refCodigo: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/30bebb9ddb9ee', {
      elementos: {
        ENCDVF_NUMERO: refCodigo
      }
    }, this.confIniciales.httpOptions());
  }

  reversarProcesoFE(docNum: string, tipoFac: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/6ddf76f3bc31f0', {
      elementos: {
        p_trndoc_numdoc: docNum,
        p_trndoc_tipo: tipoFac,
        p_com_codigo: '01',
      },
    }, this.confIniciales.httpOptions());
  }

  obtenerLogFE(docNum: string, tipoFac: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/c2faebbb75c4200', {
      elementos: {
        TRNDOC_NUMDOC: docNum,
        TRNDOC_TIPO: tipoFac
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerDistrito(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/617d6cb9ddcbb8', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  obtenerRegimenRefrendo(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/30beedd0db9fb', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  obtenerTipoIExterior(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/617d967127719c', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  guardarExportNuevo(expNuevo: Venexpodvf, clave: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/43ca77199edfd0', {
      elementos: {
        p_tpidclienteex: '',
        p_parterelexp: '',
        p_denoexpcli: expNuevo.DENOEXPCLI,
        p_tiporegi: expNuevo.TIPOREGI,
        p_paisefecpagogen: expNuevo.PAISEFECPAGOGEN,
        p_paisefecexp: expNuevo.PAISEFECEXP,
        p_exportacionde: expNuevo.EXPORTACIONDE,
        p_distaduanero: expNuevo.DISTADUANERO,
        p_anio: expNuevo.ANIO,
        p_regimen: expNuevo.REGIMEN,
        p_correlativo: expNuevo.CORRELATIVO,
        p_doctransp: expNuevo.DOCTRANSP,
        p_fechaembarque: expNuevo.FECHAEMBARQUE,
        p_valorfob: Number(expNuevo.VALORFOB),
        p_valorfobcomprobante: Number(expNuevo.VALORFOBCOMPROBANTE),
        p_tipoingreso: expNuevo.TIPOINGRESO,
        p_inggravadoext: expNuevo.INGGRAVADOEXT,
        p_valorimpext: Number(expNuevo.VALORIMPEXT),
        p_tipocomprobante: expNuevo.TIPOCOMPROBANTE,
        p_encdvf_numero: expNuevo.ENCDVF_NUMERO,
        p_com_codigo: '01',
        p_clave: clave,
      },
    }, this.confIniciales.httpOptions());
  }

  mostrarExportarNuevo(numero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/5b1ceeddc66700', {
      elementos: {
        ENCDVF_NUMERO: numero
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarExportNuevo(exportN: Venexpodvf): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/3ab2b3b8ccdfce', {
      elementos: {
        p_encdvf_numero: exportN.ENCDVF_NUMERO,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions());
  }


  insertarTrncobro(item): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/4afceeeecc2f78', {
      elementos: {
        p_trncobro_nrodoc: item.TRNCOBRO_NRODOC,
        p_com_codigo: item.COM_CODIGO,
        p_TRNCOBRO_FECHATRN: item.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_TIPODOC: item.TRNCOBRO_TIPODOC,
        p_TRNCOBRO_NROCOMPROBANTE: item.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_CONCEPTO: item.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_IMPORTE: item.TRNCOBRO_IMPORTE,
        p_TRNCOBRO_FECHAVENCE: item.TRNCOBRO_FECHAVENCE,
        p_pro_CODIGO: item.PRO_CODIGO,
        p_APLORG_CODIGO: item.APLORG_CODIGO,
        p_trncobro_usuario_act: item.TRNCOBRO_USUARIO_ACT,
        p_trncobro_fecharecepcion: item.TRNCOBRO_FECHARECEPCION,
        p_trncobro_referenciaexterna: item.TRNCOBRO_REFERENCIAEXTERNA,
        p_con_codigo: item.CON_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }

  insertarTrncobroProm(item): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/4afceeeecc2f78', {
      elementos: {
        p_trncobro_nrodoc: item.TRNCOBRO_NRODOC,
        p_com_codigo: item.COM_CODIGO,
        p_TRNCOBRO_FECHATRN: item.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_TIPODOC: item.TRNCOBRO_TIPODOC,
        p_TRNCOBRO_NROCOMPROBANTE: item.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_CONCEPTO: item.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_IMPORTE: item.TRNCOBRO_IMPORTE,
        p_TRNCOBRO_FECHAVENCE: item.TRNCOBRO_FECHAVENCE,
        p_pro_CODIGO: item.PRO_CODIGO,
        p_APLORG_CODIGO: item.APLORG_CODIGO,
        p_trncobro_usuario_act: item.TRNCOBRO_USUARIO_ACT,
        p_trncobro_fecharecepcion: item.TRNCOBRO_FECHARECEPCION,
        p_trncobro_referenciaexterna: item.TRNCOBRO_REFERENCIAEXTERNA,
        p_con_codigo: item.CON_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  calcularTotal() {
    console.log('ENTER---FAC', this.idcelldevfacpro )
    this.dblPorcIvaDevF = 12;

    for (let fila = 1; fila <= this.detdevFacarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {

      if (this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_TIPODET !== '*') {
        if (this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_CODIGO.length > 0
          && Number(this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_COSTO) > 0) {
          /*if (this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_VALDES === 'NaN') {
            this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_VALDES = 0;
          }
          if (this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_PORDES === 'NaN') {
            this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_PORDES = 0;
          }*/
          //if (Number(this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_VALDES) !== 0) {
            if (this.idcelldevfacpro === 'DETDEV_VALDES') {//GSRF
            let dblValor1 = 0;
            let dblValor2 = 0;
            dblValor1 = Number(this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_CANTIDAD) *
              Number(this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_COSTO);
            dblValor2 = (Number(this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_VALDES) * 100) / dblValor1;
            this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_PORDES = dblValor2;
          } else {
            //if (Number(this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_PORDES) !== 0) {
              if (this.idcelldevfacpro === 'DETDEV_PORDES') { 
              let dblValor1 = 0;
              let dblValor2 = 0;
              dblValor1 = Number(this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_CANTIDAD) *
                Number(this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_COSTO);
              dblValor2 = (Number(this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_PORDES) * dblValor1) / 100;
              this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_VALDES = dblValor2;
            }
          }
        }
      }
    }

    this.calcularCostoTotal();

    this.SumarTotal();

    // decimales del detalle de la factura
    for (let fila = 1; fila <= this.detdevFacarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_CANTIDAD = Number(this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_CANTIDAD).toFixed(this.decimalesCantidadDevFC);
      this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_COSTO = Number(this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_COSTO).toFixed(this.decimalesPrecioDevFC);
      this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_TOTAL = Number(this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_TOTAL).toFixed(this.decimalesTotalDevFC);
      this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_PORDES = Number(this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_PORDES).toFixed(3);//GSRF
      this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_VALDES = Number(this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_VALDES).toFixed(this.decimalesPrecioDevFC);//GSRF
      this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_PORDES2 = Number(this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_PORDES2).toFixed(this.confIniciales.getNumDecSist());
      this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_PORDES3 = Number(this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_PORDES3).toFixed(this.confIniciales.getNumDecSist());
      this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_PORIVA = Number(this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDEV_PORIVA).toFixed(this.confIniciales.getNumDecSist());
    }
    this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_TOTAL = Number(this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_TOTAL).toFixed
      (this.confIniciales.getNumDecSist());
    this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_TOTALNETO = Number(this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_TOTALNETO).toFixed
      (this.confIniciales.getNumDecSist());
    this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_BASEIVA = Number(this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_BASEIVA).toFixed
      (this.confIniciales.getNumDecSist());
    this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_BASEICE = Number(this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_BASEICE).toFixed
      (this.confIniciales.getNumDecSist());
    this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_BASECERO = Number(this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_BASECERO).toFixed
      (this.confIniciales.getNumDecSist());
    this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_VALORIVA = Number(this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_VALORIVA).toFixed
      (this.confIniciales.getNumDecSist());
    this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_VALORICE = Number(this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_VALORICE).toFixed
      (this.confIniciales.getNumDecSist());
    this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_VALORDES = Number(this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_VALORDES).toFixed
      (this.confIniciales.getNumDecSist());
    this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_PORCEDES = Number(this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_PORCEDES).toFixed
      (this.confIniciales.getNumDecSist());

  }

  calcularCostoTotal(): number {
    let intIndiceLista = 0;

    let dblCostoTotal = 0;
    let dblValor1d = 0;
    let dblValor2d = 0;

    let dblValor1 = 0;
    let dblValor2 = 0;
    let dblValor3 = 0;
    let dblValor4 = 0;
    let dblValor5 = 0;
    let dblValor6 = 0;
    let dblValor7 = 0;

    for (intIndiceLista = 0; intIndiceLista < this.detdevFacarray[this.init.tabs[this.init.tabindex].indice].length; intIndiceLista++) {
      const item = this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][intIndiceLista];
      {
        console.log(Number(item.DETDEV_CANTIDAD), ' >', Number(item.CANTIDAD));
        if (Number(item.DETDEV_CANTIDAD) > Number(item.CANTIDAD)) {
          this.message.add({
            key: 'devolucionCom',
            severity: 'info',
            summary: 'Cantidad Inválida',
            detail: 'No se puede ingresar una cantidad mayor a la disponible en la factura'
          });
          item.DETDEV_CANTIDAD = item.CANTIDAD;
        }
        if (item.DETDEV_CODIGO !== '') {

          dblValor1 = 0;
          dblValor2 = 0;
          dblValor3 = 0;
          dblValor4 = 0;
          dblValor5 = 0;
          dblValor6 = 0;
          dblValor7 = 0;

          if (item.DETDEV_VALDES !== 0) {// && Number(item.DETDEV_COSTO) > 0) { GSRF
            dblValor1d = 0;
            dblValor2d = 0;
            dblValor1d = item.DETDEV_CANTIDAD
              * item.DETDEV_COSTO;
            dblValor2d = (item.DETDEV_VALDES * 100)
              / dblValor1d;
            item.DETDEV_PORDES = dblValor2d;

          }


          dblValor1 = item.DETDEV_CANTIDAD * item.DETDEV_COSTO;
          //CDPJ
          let val=10 ** this.decimalesTotalDevFC;
          let total=Math.round(item.DETDEV_CANTIDAD * val * item.DETDEV_COSTO) / val;
          dblValor1 = Number(total.toFixed(this.decimalesTotalDevFC));
          console.log('TOTALITEM',dblValor1);
          //CDPJ
          dblValor2 = Number((item.DETDEV_PORDES / 100) * dblValor1);
          dblValor3 = Number(dblValor1) - Number(dblValor2);
          dblValor4 = Number((item.DETDEV_PORDES2 / 100) * dblValor3);
          dblValor5 = Number(dblValor3) - Number(dblValor4);
          dblValor6 = Number((item.DETDEV_PORDES3 / 100) * dblValor5);
          dblValor7 = Number(dblValor5) - Number(dblValor6);


          item.DETDEV_TOTAL = Number(dblValor7).toFixed(this.decimalesTotalDevFC);

          dblCostoTotal = Number(dblCostoTotal) + Number(dblValor7);

        }
      }
    }
    this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_TOTALNETO = dblCostoTotal.toFixed(this.confIniciales.getNumDecSist());


    return dblCostoTotal;

  }

  SumarTotal() {
    console.log('entra/*/*/*/*/',this.strTipoIvaarray[this.init.tabs[this.init.tabindex].indice])
    console.log('entra/*/*/*/*/',this.strTipoIvaarray)
    let dblBaseIva = 0;
    let dblBaseIce = 0;
    let dblBaseCero = 0;
    let dblOtros = 0;
    let dblBaseNoObjetoIva = 0;
    let dblBaseTIva = 0;
    let dblBaseTIce = 0;
    let dblBaseTCero = 0;
    let dblBaseTNoObjetoIva = 0;
    let dblSuma = 0;
    let dblSumaIva = 0;
    let dblSumaIce = 0;
    let dblTotalDet = 0;
    let dblVaDeIce = 0;
    let dblVaDeIva = 0;
    let dblVaDeCero = 0;
    let dblValNoObjetoIva = 0;
    let dblDescuentoP = 0;
    let dblDescuento = 0;
    let dblSumaTotal = 0;
    let intIndiceLista = 0;
    let dblTotalRetenciones = 0;
    let dblTotalCargos = 0;
    let dblSumaFac = 0;
    const ICEDESC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ICEDESC').CFG_VALOR1;
    const ICEBASEIVA = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ICEBASEIVA').CFG_VALOR1;

    if (this.strFacturaExportadaarray[this.init.tabs[this.init.tabindex].indice] === '2') {
      return;
    }

    if (this.strTipoDescuentoarray[this.init.tabs[this.init.tabindex].indice] === 'P') {
      this.dblValorDescGlbDevFCarray[this.init.tabs[this.init.tabindex].indice] = this.calcularCostoTotal()
        * this.dblPorcDescGlbDevFCarray[this.init.tabs[this.init.tabindex].indice] / 100;
    }

    if (this.strTipoDescuentoarray[this.init.tabs[this.init.tabindex].indice] === 'V') {
      this.dblPorcDescGlbDevFCarray[this.init.tabs[this.init.tabindex].indice] = this.dblValorDescGlbDevFCarray[this.init.tabs[this.init.tabindex].indice]
        * 100 / this.calcularCostoTotal();
    }

    dblDescuento = this.dblValorDescGlbDevFCarray[this.init.tabs[this.init.tabindex].indice]; // Descuento por valor
    dblDescuentoP = this.dblPorcDescGlbDevFCarray[this.init.tabs[this.init.tabindex].indice]; // Descuento por porcentaje

    // this.lblTotalDscValorarray[this.init.tabs[this.init.tabindex].indice] = dblDescuento.toFixed(this.decimalesTOTALDetFacPro);
    // this.lblTotalDscPorarray[this.init.tabs[this.init.tabindex].indice] = dblDescuentoP.toFixed(this.decimalesTOTALDetFacPro);
    this.lblTotalDscValorarray[this.init.tabs[this.init.tabindex].indice] = '0';
    this.lblTotalDscPorarray[this.init.tabs[this.init.tabindex].indice] = '0';

    for (intIndiceLista = 0; intIndiceLista < this.detdevFacarray[this.init.tabs[this.init.tabindex].indice].length; intIndiceLista++) {
      const item = this.detdevFacarray[this.init.tabs[this.init.tabindex].indice][intIndiceLista];
      if (item.DETDEV_CODIGO !== '') { // servicio,articulo,cuenta contable,activo,fijo
        if (item.DETDEV_TIPODET !== '*') {
          dblSumaTotal = dblSumaTotal + Number(item.DETDEV_TOTAL);
          if (this.strTipoIvaarray[this.init.tabs[this.init.tabindex].indice] === '0') {
            dblSumaIva = 0; // No suma iva
            if (item.DETDEV_TRIBICE === 'S') { // Si Tributa Ice

              if (ICEDESC === 1) {// Cálculo de la Base del ICE en compras sin que afecte el descuento
                dblBaseIce = Number(item.DETDEV_CANTIDAD) * Number(item.DETDEV_COSTO);
              } else {
                dblBaseIce = Number(item.DETDEV_TOTAL) * (1 - dblDescuentoP / 100);
              }

              if (Number(item.DETDEV_PORCEICE) !== 0) {
                dblVaDeIce = dblBaseIce
                  * Number(item.DETDEV_PORCEICE) / 100;
              } else {
                dblVaDeIce = Number(item.DETDEV_ICE) * Number(item.DETDEV_CANTIDAD);
              }
            } else {
              dblBaseIce = 0;
              dblVaDeIce = 0;
            }

            dblVaDeCero = Number(item.DETDEV_TOTAL) * (1 - dblDescuentoP / 100);
            dblBaseTIce = dblBaseTIce + Number(dblBaseIce);
            dblSumaIce = dblSumaIce + Number(dblVaDeIce);
            dblBaseTCero = dblBaseTCero + Number(dblVaDeCero);

          } // Fin si ex Exento

          if (this.strTipoIvaarray[this.init.tabs[this.init.tabindex].indice] === '2') {
            dblVaDeIce = 0;
            dblSumaIce = 0;
            dblTotalDet = Number(item.DETDEV_TOTAL)
              * (1 - dblDescuentoP / 100);
            if (Number(item.DETDEV_IVA) !== 0) { // Si aplica Iva
              dblBaseIva = Number(item.DETDEV_TOTAL)
                / (1 + Number(item.DETDEV_IVA) / 100);
              dblVaDeIva = dblBaseIva * Number(item.DETDEV_IVA)
                / 100;
              dblBaseCero = 0;
            }
            dblBaseTIva = dblBaseTIva + Number(dblBaseIva);
            dblSumaIva = dblSumaIva + Number(dblVaDeIva);
            dblBaseTCero = dblBaseTCero + Number(dblBaseCero);

          }// Fin Incluido

          if (this.strTipoIvaarray[this.init.tabs[this.init.tabindex].indice] === '1') {
            if (item.DETDEV_TRIBICE === 'S') { // Si Tributa Ice
              if (ICEDESC === 1) { // Cálculo de la Base del ICE en compras sin que afecte el descuento
                dblBaseIce = Number(item.DETDEV_CANTIDAD)
                  * Number(item.DETDEV_COSTO);
              } else {
                dblBaseIce = Number(item.DETDEV_TOTAL)
                  * (1 - dblDescuentoP / 100);
              }

              if (Number(item.DETDEV_PORCEICE) !== 0) { // si el porcentaje del ice es <>0
                dblVaDeIce = dblBaseIce
                  * Number(item.DETDEV_PORCEICE) / 100; // Valor del ice
              } else {
                dblVaDeIce = Number(item.DETDEV_ICE)
                  * Number(item.DETDEV_CANTIDAD);
              }
            } else { // Fin Si tributa Ice
              // Si no tributa Ice
              dblBaseIce = 0;
              dblVaDeIce = 0;
            } // Fin si no tributa Ice

            dblBaseTIce = dblBaseTIce + Number(dblBaseIce);
            dblSumaIce = dblSumaIce + Number(dblVaDeIce);

            if (ICEBASEIVA === 1) {
              dblBaseIva = Number(item.DETDEV_ICE)
                * (1 - dblDescuentoP / 100);
              dblBaseIva = dblBaseIva + Number(dblVaDeIce);
            } else {
              dblBaseIva = Number(item.DETDEV_TOTAL)
                * (1 - dblDescuentoP / 100);
            }

            if (Number(item.DETDEV_PORIVA) !== 0) { // Si aplica Iva
              if (item.DETDEV_PROMOCION === 'S') {// Si se trata de una promoción considera el iva
                dblBaseIva = Number(item.CANTIDAD) * Number(item.DETDEV_COSTO);
                if (ICEBASEIVA === 1) {
                  dblBaseIva = Number(item.DETDEV_TOTAL) * (1 - dblDescuentoP / 100);
                  dblBaseIva = dblBaseIva + Number(dblVaDeIce);
                } else {
                  dblBaseIva = dblBaseIva
                    * (1 - dblDescuentoP / 100);
                }
              }// Fin si es una promoción

              dblVaDeIva = dblBaseIva * Number(item.DETDEV_PORIVA)
                / 100;
              dblVaDeCero = 0;
              dblValNoObjetoIva = 0;
            }
             //GSRF  
             else { // Fin Si aplica Iva
              /* No tributa Iva */
              // si tributa base no objeto de iva
              
                dblVaDeIva = 0;
                dblVaDeCero = dblBaseIva;
                dblBaseIva = 0;
                dblValNoObjetoIva = 0;
                dblBaseNoObjetoIva = 0;
              
            }
            //GSRF 
            dblSumaIva = dblSumaIva + Number(dblVaDeIva);
            dblBaseTIva = dblBaseTIva + Number(dblBaseIva);
            dblBaseTCero = dblBaseTCero + Number(dblVaDeCero);
            dblBaseTNoObjetoIva = dblBaseTNoObjetoIva
              + Number(dblValNoObjetoIva);

          } // Fin Si Neto + Iva

          item.DETDEV_IVA = dblVaDeIva;
          item.DETDEV_ICE = dblVaDeIce;
          item.DETDEV_BASEIVA = dblBaseIva;
          item.DETDEV_BASEICE = dblBaseIce;
          item.DETDEV_BASECERO = dblVaDeCero;

        } else {// Fin si no Otros
          // Si es otros
          dblOtros = dblOtros + item.DETDEV_TOTAL;
          /*dblSumaTotal = dblSumaTotal + 0;
          dblBaseTIva = dblBaseTIva + 0;
          dblBaseTIce = dblBaseTIce + 0;
          dblBaseTCero = dblBaseTCero + 0;
          dblSumaIce = dblSumaIce + 0;
          dblBaseTNoObjetoIva = dblBaseTNoObjetoIva + 0;*/
        } // Fin si no es otros
      } // servicio,articulo,cuenta contable,activo fijo
    } // Fin de for

    /* dblTotalRetenciones = frmComEncFacPro.modeloRetenciones
      .totalRetencion();
    dblTotalCargos = frmComEncFacPro.modeloCargos.totalCargos(); */

    if (this.strTipoIvaarray[this.init.tabs[this.init.tabindex].indice] === '0') { // Neto

      this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_TOTALNETO = dblSumaTotal;
      this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_BASEIVA = 0;
      this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_BASEICE = dblBaseTIce;
      this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_BASECERO = dblBaseTCero;
      this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_VALORIVA = 0;
      this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_VALORICE = dblSumaIce;

      dblSuma = Number(dblSumaTotal) + Number(dblSumaIce) + Number(dblSumaIva) - Number(dblDescuento)
        + Number(dblTotalCargos) - Number(dblTotalRetenciones);
      dblSumaFac = Number(dblSumaTotal) + Number(dblSumaIce) + Number(dblSumaIva) - Number(dblDescuento)
        + Number(dblTotalCargos);

      this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_TOTAL = dblSuma;
    } // Fin si Exento

    if (this.strTipoIvaarray[this.init.tabs[this.init.tabindex].indice] === '1') { // Neto
      this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_TOTALNETO = dblSumaTotal;
      this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_BASEIVA = dblBaseTIva;
      this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_BASEICE = dblBaseTIce;
      this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_BASECERO = dblBaseTCero;
      this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_VALORIVA = dblSumaIva;
      this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_VALORICE = dblSumaIce;

      dblSuma = Number(dblSumaTotal) + Number(dblSumaIce) + Number(dblSumaIva) - Number(dblDescuento)
        + Number(dblTotalCargos) - Number(dblTotalRetenciones);
      dblSumaFac = Number(dblSumaTotal) + Number(dblSumaIce) + Number(dblSumaIva) - Number(dblDescuento)
        + Number(dblTotalCargos);

      this.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_TOTAL = dblSuma;

    }

  } // FIN DE SUMAR TOTAL

  obtenerPermisoUsuario(strIdUsuario) {
    return this.http.post<any[]>(this.way + '/compras/docalmacenados/c2fb976ed2f7400', {
        elementos: {
          USUARIO: strIdUsuario
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  eliminarTrnCobroProm(parametro): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/75656eed985ef00', {
      elementos: {
        p_trncobro_NRODOC: parametro[0],
        p_com_codigo: parametro[1],
        p_pro_codigo: parametro[2],
        p_tipo_doc: parametro[3],
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
  encontrarArtCntaySer(strArtCodigo, strtipo) {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/75d98bf76adab8000000', {
      elementos: {
        ArtCodigo: strArtCodigo,
        strTIPO: strtipo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erBodCodigo1(strCodigo) {
    return this.http.post(this.way + '/compras/devfacturas/76d786b30d94300', {
      elementos: {
        BOD_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erCencodigo(cencodigo) {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/1f5564d56d8ebf0000000000000', {
      elementos: {
        cen_codigo: cencodigo,
        con_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();

  }
  verificarDetalleDevFacpro(EncFacpro){
    return this.http.post<any[]>(this.way + '/compras/devfacturas/cc5f9eaf55b6b8000000000000000000000000000', {
      elementos: {
        p_numero:EncFacpro
      
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  verificarDetalleDevFacproArtCntaSer(EncFacpro){
    return this.http.post<any[]>(this.way + '/compras/devfacturas/41dd56facbf55c0000000000', {
      elementos: {
        p_numero:EncFacpro
      
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerbasesiva(numero) {
    return this.http.post<any>(this.way + '/compras/devfacturas/107756ae3b92fb00000000', {
      elementos: {
        p_devc_numero:numero
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getEncnotrecProm(strNumero): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/83baebb2f8eee00', {
      elementos: {
        EncNotRecNumero: strNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getEncfacproProm(numero) {
    return this.http.post<any>(this.way + '/compras/devfacturas/83baebb1ea66780', {
      elementos: {
        p_facpro_numero:numero
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizabasesiva(numero) {
    return this.http.post<any>(this.way + '/compras/devfacturas/a675aefb3165a80000000', {
      elementos: {
        p_encdev_numero:numero,
        p_com_codigo:'01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
  //GSRF
  existfacdev(refe,procod){
    return  this.http.post<any[]>(this.way + '/compras/devfacturas/ee325ceb9ea63800', {
      elementos: {
        p_referencia: refe,
        p_procodigo: procod,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //GSRF
}
