import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NumeracionService} from '../advantageservices/numeracion.service';
import {CellEditorPrueba} from '../config/cell-edit-prueba';

@Component({
  selector: 'app-numeracion',
  templateUrl: './numeracion.component.html',
  styleUrls: ['./numeracion.component.css']
})
export class NumeracionComponent implements OnInit, OnChanges {
  @Input() numDocRef: string;
  @Input() auxiliar: string;
  @Output() numeracionSeleccion = new EventEmitter<any>();
  numeraciones: any[];
  public frameworkComponents;
  rowStyle;
  rowSelection = 'single';
  defaultColDefNumeracion;
  columnDefsNumeracion = [
    {
      headerName: 'Descripción', field: 'NUM_CLASE', width: 150, editable: false,
    },
    {
      headerName: 'Nemónico', field: 'NUM_NEMONICO', width: 100, editable: false,
    },
    {
      headerName: 'Serie', field: 'NUM_SERIE', width: 100, editable: false,
    },
    {
      headerName: 'Actual', field: 'NUM_SECACTUAL', width: 100, editable: false,
    }
  ];
  constructor(public numService: NumeracionService) {
    this.numDocRef = '';
    this.numeraciones = [];
    this.agTable();
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefNumeracion = {
      editable: true,
      width: 100,
      objeto: 'numeracion'
    };
  }
  
  ngOnInit() {
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    for (const e in changes) {
      // if (e === 'asiento' && this.encasiento !== undefined && this.asiento !== null) {
      if (e === 'auxiliar' && this.numDocRef !== '') {
        this.numService.obtenerNumeracionC(this.numDocRef).subscribe((res) => {
          this.numeraciones = res;
        });
      }
    }
  }
  
  numeracionSeleccionada(valor) {
    if (valor.object !== undefined) {
      this.numeracionSeleccion.emit(valor.object);
    }
  }
  
}
