
import {Component, OnInit, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {VenmaedescuentosService} from '../venservicios/venmaedescuentos.service';
import {Venmaedescuentos} from '../veninterfaces/venmaedescuentos';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {DatePipe} from '@angular/common';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Component({
  selector: 'app-venmaedescuentos',
  templateUrl: './venmaedescuentos.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class VenmaedescuentosComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  largo: string;
  date1: any;
  descuentoSeleccionado: Venmaedescuentos;
  permisoDescuento: SegMaePermiso;
  public frameworkComponents;
  actualizar: boolean;
  nuevoCampo: boolean;
  rowStyle;
  defaultColDefDescuentos;
  rowSelection = 'multiple';
  columnDefsDescuentos = [
    {
      headerName: 'Nombre', field: 'MAEDSCTO_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: false,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
          }
        }
    },
    {
      headerName: 'Tipo', field: 'MAEDSCTO_TIPO', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: false,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            regexp: /^[S]$|^[A]$/,
            tamanocelda: 1
          }
        }
    },
    {
      headerName: 'Art/Svr', field: 'MAEDSCTO_ARTSVR', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 30,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: '%', field: 'MAEDSCTO_PORCENTAJE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 5
          }
        }
    },
    {
      headerName: 'Desde', field: 'MAEDSCTO_DESDE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: false,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: true
          }
        }
    },
    {
      headerName: 'Hasta', field: 'MAEDSCTO_HASTA', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: false,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: true
          }
        }
    }];
  
  constructor(public descuentoService: VenmaedescuentosService, private message: MessageService,
              private errorService: ErroresBaseDatosService, private confirmationService: ConfirmationService,
              private datePipe: DatePipe, private permisosService: PermisosService, private auditoriagral: AuditoriagralService,
              private init: NuevoComponentService) {
    this.descuentoService.venmaedescuentos = [];
    this.agTable();
   
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefDescuentos = {
      editable: true,
      width: 100,
      objeto: 'maedescuento'
    };
  }
  
  ngOnInit() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.actualizar = false;
    this.nuevoCampo = false;
    this.date1 = new Date();
    this.permisosService.getListaPermisos('VEN', '1', '694').subscribe((res) => {
      this.permisoDescuento = res [0];
    });
    this.descuentoService.getDescuentos().subscribe((res) => {
       res.map((res1) => {
        res1.MAEDSCTO_DESDE = this.datePipe.transform(res1.MAEDSCTO_DESDE,
          'dd/MM/yyyy');
        res1.MAEDSCTO_HASTA = this.datePipe.transform(res1.MAEDSCTO_HASTA,
          'dd/MM/yyyy');
      });
        this.descuentoService.venmaedescuentos = res;
    });
    
    this.largo = '258';
  }
  
  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      if (this.permisoDescuento.PERINSERCION === 1) {
        this.btnNuevo = true;
        this.btnGuardar = false;
        this.btnBorrar = false;
        this.btnRegresar = false;
        this.nuevoCampo = true;
        this.descuentoSeleccionado = {
          COM_CODIGO: '01', MAEDSCTO_ARTSVR: '', MAEDSCTO_CODIGO: '', MAEDSCTO_TIPO: '',
          MAEDSCTO_DESDE: this.datePipe.transform(this.date1, 'dd/MM/yyyy'), MAEDSCTO_HASTA: '',
          MAEDSCTO_NOMBRE: '', MAEDSCTO_PORCENTAJE: ''
        };
        this.descuentoService.venmaedescuentos.push(this.descuentoSeleccionado);
        this.aggrid.refreshaggrid(this.descuentoService.venmaedescuentos, this.defaultColDefDescuentos.objeto);
      } else if (this.permisoDescuento.PERINSERCION === 0) {
        this.message.add({
          key: 'desc',
          severity: 'error',
          summary: 'Inserción',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    }
    if (valor === 'Guardar') {
      this.btnGuardar = true;
      this.btnNuevo = false;
      this.btnBorrar = false;
      this.btnRegresar = true;
      if (this.descuentoSeleccionado.MAEDSCTO_DESDE.includes('/')) {
      } else if (this.descuentoSeleccionado.MAEDSCTO_DESDE.includes('-')) {
        this.descuentoSeleccionado.MAEDSCTO_DESDE =
          this.datePipe.transform(this.descuentoSeleccionado.MAEDSCTO_DESDE,
            'dd/MM/yyyy');
      }
      if (this.descuentoSeleccionado.MAEDSCTO_HASTA.includes('/')) {
      } else if (this.descuentoSeleccionado.MAEDSCTO_HASTA.includes('-')) {
        this.descuentoSeleccionado.MAEDSCTO_HASTA =
          this.datePipe.transform(this.descuentoSeleccionado.MAEDSCTO_HASTA,
            'dd/MM/yyyy');
      }
      if (this.actualizar === true) {
        this.descuentoService.actualizarDescuento(this.descuentoSeleccionado).subscribe((res1) => {
          console.log(res1);
          this.message.add({
            key: 'desc',
            severity: 'success',
            summary: 'Actualización exitosa',
            detail: 'Se actualizó el descuento correctamente'
          });
          this.auditoriagral.registrarAuditoria('VEN_MAEDSCTO', String(this.descuentoSeleccionado.MAEDSCTO_CODIGO), 'A', '',
            '01', '', '', '', '').subscribe(() => {
          });
          this.descuentoService.venmaedescuentos = this.descuentoService.venmaedescuentos.filter((val, j) => j !==
            this.descuentoService.venmaedescuentos.indexOf(this.descuentoService.getDescuentos()
              [this.descuentoService.venmaedescuentos.length - 1]));
          this.aggrid.isEditable(false);
        }, error1 => {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
          this.message.add({
            key: 'desc',
            severity: 'error',
            summary: 'Actualización de Descuento',
            detail: mensaje
          });
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.btnRegresar = true;
          this.descuentoService.getDescuentos().subscribe((res) => {
            this.descuentoService.venmaedescuentos = res;
            this.aggrid.refreshaggrid(this.descuentoService.venmaedescuentos, this.defaultColDefDescuentos.objeto);
          });
        });
      } else if (this.actualizar === false) {
        this.descuentoService.insertarDescuento(this.descuentoSeleccionado).subscribe(() => {
          this.message.add({
            key: 'desc',
            severity: 'success',
            summary: 'Inserción exitosa',
            detail: 'Se insertó el descuento correctamente'
          });
          this.nuevoCampo = false;
        }, error1 => {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
          this.message.add({
            key: 'desc',
            severity: 'error',
            summary: 'Inserción de Descuento',
            detail: mensaje
          });
          this.descuentoService.getDescuentos().subscribe((res) => {
            this.descuentoService.venmaedescuentos = res;
            this.aggrid.refreshaggrid(this.descuentoService.venmaedescuentos, this.defaultColDefDescuentos.objeto);
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
            this.btnRegresar = true;
          });
          });
      }
    }
    if (valor === 'Borrar') {
      if (this.permisoDescuento.PERELIMACION === 1) {
        if (this.descuentoSeleccionado === undefined) {
          this.message.add({
            key: 'desc',
            severity: 'error',
            summary: 'Eliminación de Descuentos',
            detail: 'Selección Incorrecta, no se ha seleccionado un descuento'
          });
        } else {
          this.confirmarDescuento();
        }
      } else if (this.permisoDescuento.PERELIMACION === 0) {
        this.message.add({
          key: 'desc',
          severity: 'error',
          summary: 'Eliminación de Descuento',
          detail: 'El usuario no tiene permisos de eliminación, acción denegaada'
        });
      }
    }
    if (valor === 'Cancelar') {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.descuentoService.getDescuentos().subscribe((res) => {
        res.map((desc) => {
          desc.MAEDSCTO_DESDE = this.datePipe.transform(desc.MAEDSCTO_DESDE,
            'dd/MM/yyyy');
          desc.MAEDSCTO_HASTA = this.datePipe.transform(desc.MAEDSCTO_HASTA,
            'dd/MM/yyyy');
        });
        this.descuentoService.venmaedescuentos = res;
        this.aggrid.refreshaggrid(this.descuentoService.venmaedescuentos, this.defaultColDefDescuentos.objeto);
      });
    }
  
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
  seleccionDescuento(valor) {
    if (valor.object !== null) {
      this.descuentoSeleccionado = valor.object;
    }
  }
  
  confirmarDescuento() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el descuento',
      header: 'Eliminar Descuento',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarDesc',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarDescuento();
      },
      reject: () => {
      }
    });
  }
  
  eliminarDescuento() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.descuentoService.eliminarDescuento(this.descuentoSeleccionado).subscribe(() => {
      this.message.add({
        key: 'desc',
        severity: 'success',
        summary: 'Eliminación exitosa',
        detail: 'Se eliminó el descuento correctamente'
      });
      this.descuentoService.venmaedescuentos = this.descuentoService.venmaedescuentos.filter((val, j) => j !==
        this.descuentoService.venmaedescuentos.indexOf(this.descuentoSeleccionado));
      this.aggrid.refreshaggrid(this.descuentoService.venmaedescuentos, this.defaultColDefDescuentos.objeto);
    });
  }
  
  cambio(cambio) {
    this.btnGuardar = false;
    this.btnRegresar = false;
  }
}
