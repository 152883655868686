<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="bantrncajatrn"></p-toast>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="true" [botonBorrar]="true" [barraBotonesDesplaz]="true"
						 [barraBotones3]="true"  [botonBuscar]="false"
						 (signal)="manejarSenales($event)">
		</app-invmaebarra>
	</div>
</div>
<app-aggridsaci [width]=""
				[height]="largo"
				
				[enableFilter]="true"
				[rowData]="bandetCajatrn"
				[frameworkComponents]="frameworkComponents"
				[animateRows]="true"
				[rowSelection]="rowSelection"
				[columnDefs]="columnDefsBanTrnCaja"
				[defaultColDef]="defaultBanTrnCaja"
				[rowStyle]="rowStyle"
				[singleClickEdit]="false"
				[botones]="false"
				[mostrarGuardar]="false"
				[botonesAdicionales]="false"
				[comboOpciones]=""
				(selected)="seleccionItem($event)"
				[tipodetalle]=""
>
</app-aggridsaci>
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlgmae [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
						[where]="condicion"
						[busquedaParams]="busquedacampos"
						(opcionSeleccionada)="manejarSeleccion($event)"
						[busquedaCompuesta]="false"></app-busquedadlgmae>
</p-dialog>

<p-dialog header="Movimiento de Caja" [(visible)]="displayDialogBanTrnCajaEdit"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width:'80vw', height:'120vh'}"
		  [draggable]="false"
		  (onHide)="CerrarTrnCajaEdit($event)">
	<app-banmodificarmovcaja *ngIf="displayDialogBanTrnCajaEdit" [bandera111]="bandera111" [bandera]="bandera" [ex]="ex"
							 [numDep]="numDep" [saldo]="saldo" [strBanCodigo]="strBanCodigo" [strCodCaja]="strCodCaja"
	                          [strTrnCajDocNro]="strTrnCajDocNro" [strTrnCajNumero]="strTrnCajNumero" [strTrnCajOrigen]="strTrnCajOrigen"
	                          [strTrnCajTipo]="strTrnCajTipo" (signalSalir)="recibirSenalesBanCaja($event)"></app-banmodificarmovcaja>
</p-dialog>
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	<p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
	</p-dialog>	
</div>