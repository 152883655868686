import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {MessageService} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {Observable} from 'rxjs';
import {Cxcpagoret} from '../cxcinterfaces/cxcpagoret';
import {Cxcpagoretdocumentos} from '../cxcinterfaces/cxcpagoretdocumentos';
import {Cxcpagoretretenciones} from '../cxcinterfaces/cxcpagoretretenciones';
import {Cxcpagoretformapago} from '../cxcinterfaces/cxcpagoretformapago';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class CxctrnpagoretService {
  
  get cxcpagoretdocumentos(): Cxcpagoretdocumentos[] {
    return this._cxcpagoretdocumentos;
  }
  
  set cxcpagoretdocumentos(value: Cxcpagoretdocumentos[]) {
    this._cxcpagoretdocumentos = value;
  }
  
  get cxcpagoretretenciones(): Cxcpagoretretenciones[] {
    return this._cxcpagoretretenciones;
  }
  
  set cxcpagoretretenciones(value: Cxcpagoretretenciones[]) {
    this._cxcpagoretretenciones = value;
  }
  
  get cxcpagoretformapago(): Cxcpagoretformapago[] {
    return this._cxcpagoretformapago;
  }
  
  set cxcpagoretformapago(value: Cxcpagoretformapago[]) {
    this._cxcpagoretformapago = value;
  }
  
  // public url: string;
  public way: string;

  private _cxcpagoretdocumentos: Cxcpagoretdocumentos[];
  private _cxcpagoretretenciones: Cxcpagoretretenciones[];
  private _cxcpagoretformapago: Cxcpagoretformapago[];
  
  
  public dblTotalFormaDev: number;
  public dblTotalCobrar: number;
  public dblTotalDoc: number;
  public dblTotalRet: number;
  
  
  public cxcpagoret: Cxcpagoret = {};
  
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private messageService: MessageService,
              private errorService: ErroresBaseDatosService,
              private confIniciales: ConfInicialesService) {
    //this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
    this.dblTotalFormaDev = 0;
    this.dblTotalCobrar = 0;
    this.dblTotalDoc = 0;
    this.dblTotalRet = 0;
    
    
  }
  
  // primero ultimo
  seleccionarRegistro(tipo, trncobronrodoc): Observable<any> {
    return this.http.post<any>(this.way + '/cxc/pagoretenciones/e3aae6776e84b80', {
      esquema: this.usuarioEsquema,
      elementoEjecutar: ' ',
      elementos: {
        p_tipo: tipo,
        p_com_codigo: '01',
        p_trncobro_nrodoc: trncobronrodoc
        
      }
    }, this.confIniciales.httpOptions());
  }

  erTrnbanConcilia1(strNumero, strBanclicodigo, strPagotipo): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/pagoretenciones/76fbbbad5766300', {
      elementos: {
        NUMERO : strNumero, 
        BANCLI_CODIGO: strBanclicodigo,
        TRNPAGO_TIPO: strPagotipo
      },
    }, this.confIniciales.httpOptions());
  }

  erTrncajdepositonro1(strNumero, strBanclicodigo, strPagotipo, strCobrocomprobante): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/pagoretenciones/3b6c54daece380', {
      elementos: {
        NUMERO : strNumero, 
        BANCLI_CODIGO: strBanclicodigo,
        TRNPAGO_TIPO: strPagotipo,
        TRNCOBRO_NROCOMPROBANTE: strCobrocomprobante
      },
    }, this.confIniciales.httpOptions());
  }

  erTrncajdepositonro2(strNumero, strPagotipo, strCajcodigo): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/pagoretenciones/3b6c54daece382', {
      elementos: {
        NUMERO : strNumero, 
        TRNPAGO_TIPO: strPagotipo,
        CAJ_CODIGO: strCajcodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erTrncobrocuadre1(strNroComprobante): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/pagoretenciones/edf7776616cf2800', {
      elementos: {
        TRNCOBRO_NROCOMPROBANTE: strNroComprobante
      },
    }, this.confIniciales.httpOptions());
  }

  erConcodigo1(strCajcodigo): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/pagoretenciones/76d98bb30d94300', {
      elementos: {
        CAJ_CODIGO: strCajcodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erConcodigo2(strBancodigo): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/pagoretenciones/3b6cc5d986ca02', {
      elementos: {
        BAN_CODIGO: strBancodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erBanbanco1(strBancodigo): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/pagoretenciones/3b6b55d6abb300', {
      elementos: {
        BAN_CODIGO: strBancodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erClinombre1(strClicodigo): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/pagoretenciones/76d9595f165edc0', {
      elementos: {
        CLI_CODIGO: strClicodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erMaeCliente1(strClicodigo): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/pagoretenciones/edd94e6564ebf800', {
      elementos: {
        CLI_CODIGO: strClicodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erVennombre1(strVencodigo): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/pagoretenciones/76feebdf165edc0', {
      elementos: {
        VEN_CODIGO: strVencodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erTrncobroNroDoc1(strNumComprobante): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/pagoretenciones/edf77766177db800', {
      elementos: {
        numComprobante: strNumComprobante
      },
    }, this.confIniciales.httpOptions());
  }

  erClinombre2(strClicodigo): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/pagoretenciones/3b6cacaf8b2f62', {
      elementos: {
        CLI_CODIGO: strClicodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erVennombre2(strVencodigo): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/pagoretenciones/3b7f75ef8b2f62', {
      elementos: {
        VEN_CODIGO: strVencodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erRetencionCodigo(strCodigo): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/pagoretenciones/3b7b775986ca18', {
      elementos: {
        RETENCION_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erFormapagotipo(strTipo): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/pagoretenciones/76df8ddb2a87640', {
      elementos: {
        TRNPAGO_TIPO: strTipo
      },
    }, this.confIniciales.httpOptions());
  }

  erAsinro(strNroDoc): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/pagoretenciones/76d5c95f78', {
      elementos: {
        TRNCOBRO_NRODOC: strNroDoc
      },
    }, this.confIniciales.httpOptions());
  }
  
  erAsinroProm(strNroDoc) {
    return this.http.post<any>(this.way + '/cxc/pagoretenciones/76d5c95f78', {
      elementos: {
        TRNCOBRO_NRODOC: strNroDoc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erFormapagotipoProm(strTipo){
    return this.http.post<any>(this.way + '/cxc/pagoretenciones/76df8ddb2a87640', {
      elementos: {
        TRNPAGO_TIPO: strTipo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  // erRetencionCodigoProm(strCodigo){
  //   return this.http.post<any>(this.way + '/cxc/pagoretenciones/3b7b775986ca18', {
  //     elementos: {
  //       RETENCION_CODIGO: strCodigo
  //     },
  //   }, this.confIniciales.httpOptions()).toPromise();
  // }

   //GSRF
   erRetencionCodigoProm(strCodigo) : Observable<any[]>{
    return this.http.post<any>(this.way + '/cxc/pagoretenciones/3b7b775986ca18', {
      elementos: {
        RETENCION_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }//GSRF

  erClinombre2Prom(strClicodigo) {
    return this.http.post<any>(this.way + '/cxc/pagoretenciones/3b6cacaf8b2f62', {
      elementos: {
        CLI_CODIGO: strClicodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erVennombre2Prom(strVencodigo) {
    return this.http.post<any>(this.way + '/cxc/pagoretenciones/3b7f75ef8b2f62', {
      elementos: {
        VEN_CODIGO: strVencodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  /*buscarCliente(): Observable<any> {
    return this.http.post<any>(this.url + '/ejecutarproceso', {
      esquema: this.usuarioEsquema,
      elementoEjecutar: 'VEN_PCK_MAECLIENTE_C.VEN_SP_ConsultarClientes_1 ',
      elementos: {
        
        p_opcion: 'CA',
        p_filtro: '',
        p_condicion: 'cli_codigo like \'%\' and rownum<=2',
        
      }
    });
  } */
  
  buscarVendedor(): Observable<any> {
    return this.http.post<any>(this.way + '/cxc/pagoretenciones/5fb8cfbaed73700', {
      elementos: {
        p_opcion: 'B',
        p_filtro: '',
        p_condicion: 'ven_codigo like \'%\'',
        
      }
    }, this.confIniciales.httpOptions());
  }
  
  obtenerDocumentos(opcion, filtro, condicion): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/c2fadc33d675f80', {
      elementos: {
        p_opcion: opcion,
        p_filtro: filtro,
        p_condicion: condicion
      },
    }, this.confIniciales.httpOptions());
  }
  
  obtenerRetenciones(nrodoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/30beeddd75d92c0000', {
      elementos: {
        TRNCOBRO_NRODOC: nrodoc
      }
    }, this.confIniciales.httpOptions());
  }
  
  consultarCajaFP(nrodoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/662fcf558a9aa00', {
      elementos: {
        TRNCAJ_DOCNRO: nrodoc
      }
    }, this.confIniciales.httpOptions());
  }
  
  consultarBancosFP(nrodoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/cc5f9eaad5766000', {
      elementos: {
        TRNBAN_DOCNRO: nrodoc
      },
    }, this.confIniciales.httpOptions());
  }
  
  consultarDocumentosACobrar(opcion, filtro, condicion): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/cc5f9eab70c53000', {
      elementos: {
        p_opcion: opcion,
        p_filtro: filtro,
        p_condicion: condicion
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  insertarTrnCobro(cxcpagoret: Cxcpagoret): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/12bf1dbef77766000', {
      elementos: {
        
        p_TRNCOBRO_nroDoc: cxcpagoret.TRNCOBRO_NRODOC, // null
        p_TRNCOBRO_nroComprobante: cxcpagoret.TRNCOBRO_NROCOMPROBANTE.trim(),
        p_TRNCOBRO_tipoDoc: cxcpagoret.TRNCOBRO_TIPODOC,
        p_TRNCOBRO_fechaTrn: cxcpagoret.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_concepto: cxcpagoret.TRNCOBRO_CONCEPTO, // vacio
        p_TRNCOBRO_importe: Number(cxcpagoret.TRNCOBRO_IMPORTE), // valor
        p_TRNCOBRO_fechavence: cxcpagoret.TRNCOBRO_FECHAVENCE, // vacio
        p_APLORG_codigo: cxcpagoret.APLORG_CODIGO,
        p_TRNCOBRO_referenciaExterna: cxcpagoret.TRNCOBRO_REFERENCIAEXTERNA,
        p_CLI_codigo: cxcpagoret.CLI_CODIGO.trim(),
        p_VEN_codigo: cxcpagoret.VEN_CODIGO.trim(), // vacio
        p_COM_codigo: cxcpagoret.COM_CODIGO,
        p_ASI_NRO: cxcpagoret.ASI_NRO, // vacio
        p_USU_IDENTIFICACION: this.usuario.identificacion.trim(),
        p_TRNCOBRO_FLAG: Number(cxcpagoret.TRNCOBRO_FLAG), // i
        p_BAN_CODIGO: cxcpagoret.BAN_CODIGO,
        p_TRNCOBRO_EFECTIVO: Number(cxcpagoret.TRNCOBRO_EFECTIVO), // d
        p_TRNCOBRO_CAMBIO: Number(cxcpagoret.TRNCOBRO_CAMBIO), // d
        p_CON_CODIGO: cxcpagoret.CON_CODIGO, // s
        p_TRNCOBRO_SALDOINI: Number(cxcpagoret.TRNCOBRO_SALDOINI), // i
        p_BOD_CODIGO: cxcpagoret.BOD_CODIGO, // s null
        
      },
    }, this.confIniciales.httpOptions());
  }
  
  actualizarTrnCobro(cxcpagoret: Cxcpagoret): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/533beeeeecc2f80', {
      elementos: {
        p_TRNCOBRO_nroDoc: cxcpagoret.TRNCOBRO_NRODOC, // null
        p_TRNCOBRO_nroComprobante: cxcpagoret.TRNCOBRO_NROCOMPROBANTE.trim(),
        p_TRNCOBRO_tipoDoc: cxcpagoret.TRNCOBRO_TIPODOC,
        p_TRNCOBRO_fechaTrn: cxcpagoret.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_concepto: cxcpagoret.TRNCOBRO_CONCEPTO, // vacio
        p_TRNCOBRO_importe: Number(cxcpagoret.TRNCOBRO_IMPORTE), // valor
        p_TRNCOBRO_fechavence: cxcpagoret.TRNCOBRO_FECHAVENCE, // vacio
        p_APLORG_codigo: cxcpagoret.APLORG_CODIGO,
        p_TRNCOBRO_referenciaExterna: cxcpagoret.TRNCOBRO_REFERENCIAEXTERNA,
        p_CLI_codigo: cxcpagoret.CLI_CODIGO.trim(),
        p_VEN_codigo: cxcpagoret.VEN_CODIGO, // vacio
        p_COM_codigo: cxcpagoret.COM_CODIGO,
        p_ASI_NRO: cxcpagoret.ASI_NRO, // vacio
        p_USU_IDENTIFICACION: this.usuario.identificacion.trim(),
        p_TRNCOBRO_FLAG: Number(cxcpagoret.TRNCOBRO_FLAG), // i
        p_BAN_CODIGO: cxcpagoret.BAN_CODIGO,
        p_TRNCOBRO_EFECTIVO: Number(cxcpagoret.TRNCOBRO_EFECTIVO), // d
        p_TRNCOBRO_CAMBIO: Number(cxcpagoret.TRNCOBRO_CAMBIO), // d
        p_CON_CODIGO: cxcpagoret.CON_CODIGO, // s
        p_TRNCOBRO_SALDOINI: Number(cxcpagoret.TRNCOBRO_SALDOINI), // i
        p_BOD_CODIGO: cxcpagoret.BOD_CODIGO, // s null
      },
    }, this.confIniciales.httpOptions());
  }
  
  insertarTrnDocumento(cxcpagoret: Cxcpagoret, doc: Cxcpagoretdocumentos): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/12bf1dbef7776e000', {
      elementos: {
        p_TRNCOBRO_nroDoc_1: cxcpagoret.TRNCOBRO_NRODOC,
        p_TRNDOCUMENTO_nroDoc_2: doc.TRNDOCUMENTO_NRODOC,
        p_TRNDOCUMENTO_importe_3: Number(doc.TRNDOCUMENTO_IMPORTE),
        p_COM_codigo_4: '01',
        p_CLI_codigo_5: cxcpagoret.CLI_CODIGO,
        p_TRNDOCUMENTO_REF: doc.TRNDOCUMENTO_REF,
        p_TRNCOBRO_NROCOMPROBANTE: cxcpagoret.TRNCOBRO_NROCOMPROBANTE,
      },
    }, this.confIniciales.httpOptions());
  }
  
  actualizarCuotasPagadas(doc: Cxcpagoretdocumentos): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/533be67b1dcaa00', {
      elementos: {
        p_ENCFAC_NUMERO: doc.TRNDOCUMENTO_IMPORTE,
        p_CLI_CODIGO: doc.CLI_CODIGO,
        p_COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  insertarTrnRetencion(ret: Cxcpagoretretenciones): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/12bf1dbef777dc000', {
      elementos: {
        p_retencion_codigo_1: ret.RETENCION_CODIGO,
        p_trnretencion_descripcion_4: ret.TRNRETENCION_DESCRIPCION,
        p_trnretencion_nro_1: ret.TRNRETENCION_NRO,
        p_trnretencion_base_5: Number(ret.TRNRETENCION_BASE),
        p_trnretencion_porcentaje_6: Number(ret.TRNRETENCION_PORCENTAJE),
        p_trnretencion_totalretenido_7: Number(ret.TRNRETENCION_TOTALRETENIDO),
        p_trncobro_nrodoc_8: ret.TRNCOBRO_NRODOC,
        p_com_codigo_3: ret.COM_CODIGO,
        p_trnretencion_origen: ret.TRNRETENCION_ORIGEN,
        p_con_codigo: ret.CON_CODIGO,
        p_cen_codigo: ret.CEN_CODIGO,
        p_cli_codigo: ret.CLI_CODIGO,
        p_ENCFAC_NUMERO: ret.ENCFAC_NUMERO,
        p_TRNCOBRO_NROCOMPROBANTE: ret.TRNCOBRO_NROCOMPROBANTE
      },
    }, this.confIniciales.httpOptions());
  }
  
  existeRetencionFact(ret: Cxcpagoretretenciones): Observable<any[]> {
    const strsql = 'SELECT * FROM SACI_TRNRETENCION WHERE TRNRETENCION_ORIGEN IN (\'FAC\',\'CXC\')' +
      ' AND RETENCION_CODIGO = \'' + ret.RETENCION_CODIGO + '\' AND TRNRETENCION_NRO = \'' + ret.TRNRETENCION_NRO +
      '\' AND COM_CODIGO = \'01\' AND TRNRETENCION_REFERENCIA = \'' + ret.ENCFAC_NUMERO + '\'';

    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/7719b775d77a980', {
      elementos: {
        RETENCION_CODIGO: ret.RETENCION_CODIGO,
        TRNRETENCION_NRO: ret.TRNRETENCION_NRO,
        ENCFAC_NUMERO: ret.ENCFAC_NUMERO
      },
    }, this.confIniciales.httpOptions());
  }
  
  generarND(cxcpagoret: Cxcpagoret): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/41d776d5bbb77c', {
      elementos: {
        p_trncobro_nrodoc: cxcpagoret.TRNCOBRO_NRODOC,
        p_com_codigo: cxcpagoret.COM_CODIGO,
        p_fecha: cxcpagoret.TRNCOBRO_FECHATRN,
        p_cli_codigo: cxcpagoret.CLI_CODIGO,
        p_ven_codigo: cxcpagoret.VEN_CODIGO,
        p_usu_identificacion: this.usuario.identificacion.trim()
      },
    }, this.confIniciales.httpOptions());
  }
  
  insertarCaja(cxcpagoret: Cxcpagoret, fp: Cxcpagoretformapago): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/257e3b7d62a76c0000', {
      elementos: {
        
        p_CAJ_codigo_1: fp.CAJ_CODIGO, //
        p_TRNCAJ_fecha_2: fp.FECHA, //
        p_TRNCAJ_tipo_3: fp.TRNPAGO_TIPO,
        p_TRNCAJ_numero_4: fp.NUMERO, //
        p_TRNCAJ_formapago_5: fp.TRNPAGO_TIPO, //
        p_TRNCAJ_docnro_6: cxcpagoret.TRNCOBRO_NRODOC, //
        p_TRNCAJ_importe_7: Number(fp.IMPORTE) * (-1), //
        p_TRNCAJ_descripcion_8: fp.CONCEPTO, //
        p_TRNCAJ_anulado: '0', //
        p_TRNCAJ_fechavenci_9: '', //
        p_TRNCAJ_origen_10: 'CXC', //
        p_COM_codigo_11: '01', // cxcpagoret.COM_CODIGO,
        p_BANCLI_codigo_12: '', //
        p_USU_IDENTIFICACION: this.usuario.identificacion.trim(), //
        p_CLI_codigo: cxcpagoret.CLI_CODIGO, //
        p_cen_codigo: '', //
      },
    }, this.confIniciales.httpOptions());
  }
  
  insertarBanco(cxcpagoret: Cxcpagoret, fp: Cxcpagoretformapago, beneficiario): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/4afc76fab55d98', {
      elementos: {
        
        p_BAN_codigo_1: fp.BANCLI_CODIGO, //
        p_TRNBAN_fecha_2: fp.FECHA, //
        p_TRNBAN_tipo_3: fp.TRNPAGO_TIPO,
        p_TRNBAN_numero_4: fp.NUMERO, //
        p_TRNBAN_docnro_5: cxcpagoret.TRNCOBRO_NRODOC, //
        p_TRNBAN_importe_6: Number(fp.IMPORTE) * (-1), //
        p_TRNBAN_concepto_7: fp.CONCEPTO, //
        p_TRNBAN_beneficiario_9: beneficiario,
        p_TRNBAN_anulado_10: '0',
        p_CON_codigo_11: fp.CON_CODIGO, //
        p_COM_codigo_12: '01', //
        p_TRNBAN_origen_13: 'CXC', //
        p_TRNBAN_NUMEROREFUSER: '', //
        p_TRNBAN_FECHAVENCE: fp.FECHA, //
        p_ENCREQ_NUMERO: '', //
        P_PRO_CODIGO: '', //
        p_TRNBAN_PAGOELEC: '', //
      },
    }, this.confIniciales.httpOptions());
  }
  
  cargarDatosRetencion(retencionCodigo): Observable<any[]> {
    const strsql = 'SELECT * FROM SACI_MAERETENCION WHERE RETENCION_CODIGO = \'' + retencionCodigo + '\'';
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/c56e0d5771b77800', {
      elementos: {
        RETENCION_CODIGO: retencionCodigo
      }
    }, this.confIniciales.httpOptions());
  }
  
  
  actualizaUltimoCheque(fp: Cxcpagoretformapago): Observable<any[]> {
    const strsql = 'UPDATE BAN_MAEBAN SET BAN_ULTIMOCHEQUE = \'' + fp.NUMERO + '\' ' +
      'WHERE BAN_CODIGO = \'' + fp.BANCLI_CODIGO + '\' AND COM_CODIGO =\'01\'';
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/533bef57ac8bb40', {
      elementos: {
        NUMERO: fp.NUMERO,
        BANCLI_CODIGO: fp.BANCLI_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }
  
  // validaciones de guardar
  
  
  verificarPeriodoContable(strfecha): Observable<any[]> {
    const strsql = 'SELECT COUNT(*)  FROM  SACI_MAEPERIODO  WHERE  TO_DATE(\'' + strfecha + '\',\'DD/MM/YYYY\' ) >=PER_FECHAINICIAL ' +
      'AND TO_DATE(\'' + strfecha + '\',\'DD/MM/YYYY\' ) <=PER_FECHAFINAL';
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/3eedc9f976d98c0000', {
      elementos: {
        fecha: strfecha
      }
    }, this.confIniciales.httpOptions());
  }
  
  
  verificarComprobanteUnico(opcion, cxcpagoret: Cxcpagoret): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/1f76e4f662d9f6000', {
        elementos: {
          p_operacion: opcion, //
          p_nrocomprobante: cxcpagoret.TRNCOBRO_NROCOMPROBANTE, //
          p_com_codigo: '01',
          p_trncobro_nrodoc: cxcpagoret.TRNCOBRO_NRODOC,
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  // eliminar
  
  eliminarDocumento(trncobronrodoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/eacacdc33d676000', {
      elementos: {
        p_trncobro_nrodoc: trncobronrodoc,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  // actualizar
  
  eliminarTrnDocumento(strcondicion): Observable<any[]> {
    const strsql = 'DELETE FROM CXC_TRNDOCUMENTO WHERE ' + strcondicion;
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/75656eeeedc33c0', {
      elementos: {
        condicion: strcondicion
      },
    }, this.confIniciales.httpOptions());
  }
  
  eliminarTrnRetencion(strcondicion): Observable<any[]> {
    const strsql = 'DELETE FROM SACI_TRNRETENCION WHERE ' + strcondicion;
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/eacaddddf6eeb800', {
      elementos: {
        condicion: strcondicion
      }
    }, this.confIniciales.httpOptions());
  }
  
  eliminarCaja(strNumDoc): Observable<any[]> {
    const strsql = 'DELETE FROM BAN_TRNCAJ WHERE TRNCAJ_DOCNRO = \'' + strNumDoc + '\' AND COM_CODIGO = \'01\' AND TRNCAJ_ORIGEN = \'CXC\'';
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/7565695d5b62a80', {
      elementos: {
        TRNCAJ_DOCNRO: strNumDoc
      }
    }, this.confIniciales.httpOptions());
  }
  
  eliminarBancos(strNumDoc): Observable<any[]> {
    const strsql = 'DELETE FROM BAN_TRNBAN WHERE TRNBAN_DOCNRO = \'' + strNumDoc + '\' AND COM_CODIGO = \'01\' AND TRNBAN_ORIGEN = \'CXC\'';
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/1d595a5756d6ac0000', {
      elementos: {
        TRNBAN_DOCNRO: strNumDoc
      }
    }, this.confIniciales.httpOptions());
  }
  
  // generar asiento contable
  
  generarAsientoRet(trncobronrodoc, comprobante): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/1075ddb5724ebf000', {
      elementos: {
        p_trncobro_nrodoc: trncobronrodoc,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion.trim(),
        p_tipdia_codigo: 'ARTC',
        p_asi_comprobante: comprobante
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  obtenerNumeroCheque(strCodBanco): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/617dbfacedb238', {
      elementos: {
        p_BAN_codigop: strCodBanco,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  // CALCULAR
  
  calcularTotalFormaPagoDev() {
    this.dblTotalFormaDev = 0;
    for (let fila = 1; fila <= this.cxcpagoretformapago.length; fila++) {
      if (this.cxcpagoretformapago[fila - 1].TRNPAGO_TIPO.length > 0) {
        this.dblTotalFormaDev += Math.abs(Number(this.cxcpagoretformapago[fila - 1].IMPORTE));
      } else {
        if (this.cxcpagoretformapago[fila - 1].IMPORTE.length > 0) {
          this.dblTotalFormaDev += Math.abs(Number(this.cxcpagoretformapago[fila - 1].IMPORTE));
        }
      }
    }
    this.cxcpagoret.TOTALFORMAPAGO = this.dblTotalFormaDev.toFixed(2);
  }
  
  
  calcularTotalRet() {
    
    this.dblTotalRet = 0;
    if (this.cxcpagoretretenciones.length !== 0) {
      for (let fila = 1; fila <= this.cxcpagoretretenciones.length; fila++) {
        const item = this.cxcpagoretretenciones[fila - 1];
        this.dblTotalRet += Number(item.TRNRETENCION_TOTALRETENIDO);
      }
    }
    
    this.cxcpagoret.TOTALRET = Number(this.dblTotalRet).toFixed(2);
    
    this.calcularTotalACobrar();
  }
  
  calcularTRetenido(index) {
    
    let tretenido = 0;
    
    tretenido = Number(this.cxcpagoretretenciones[index].TRNRETENCION_BASE) *
      (Number(this.cxcpagoretretenciones[index].TRNRETENCION_PORCENTAJE) / 100);
    
    this.cxcpagoretretenciones[index].TRNRETENCION_TOTALRETENIDO = tretenido.toFixed(2);
    
    this.calcularTotalRet();
  }
  
  
  calcularTotalDocumentos() {
    
    this.dblTotalDoc = 0;
    for (let fila = 1; fila <= this.cxcpagoretdocumentos.length; fila++) {
      const item = this.cxcpagoretdocumentos[fila - 1];
      if (item.SELECCIONADO !== null) {
        if (item.SELECCIONADO) {
          this.dblTotalDoc += Number(item.TRNDOCUMENTO_IMPORTE);
        } else {
          item.TRNDOCUMENTO_IMPORTE = Number(0);
        }
      } else {
        item.TRNDOCUMENTO_IMPORTE = Number(0);
      }
    }
    this.cxcpagoret.TOTALDOC = Number(this.dblTotalDoc).toFixed(2);
    this.calcularTotalACobrar();
  }
  
  calcularTotalACobrar() {
    this.dblTotalCobrar = 0;
    this.dblTotalCobrar = Number(this.dblTotalDoc) - Number(this.dblTotalRet);
    this.cxcpagoret.TOTALACOBRAR = Number(this.dblTotalCobrar).toFixed(2);
  }

  async verificarPeriodoContableN(strFecha) {
    let bolVerificar = false;
    let mesCerrado = 0;
    try {
      const val = await this.getPeriodoContable(strFecha);
      mesCerrado = val[0].CONTADOR;
      if (mesCerrado === 1) {
        bolVerificar = true;
      }
    } catch (e) {
      bolVerificar = false;
    }
    return bolVerificar;
  }

  async getPeriodoContable(strFecha) {
    const strsql = 'SELECT COUNT(*) as CONTADOR FROM  SACI_MAEPERIODO  WHERE  TO_DATE(\'' + strFecha + '\',\'DD/MM/YYYY\' ) >=PER_FECHAINICIAL ' +
      'AND TO_DATE(\'' + strFecha + '\',\'DD/MM/YYYY\' ) <=PER_FECHAFINAL';
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/20eee5db96198c0000', {
      elementos: {
        Fecha: strFecha
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  completarFecha(strFecha) {
    strFecha = strFecha.toString();
    if (strFecha.length === 1) {
      return '0' + strFecha;
    } else {
      return strFecha;
    }
  }

  verificarComprobanteUnicoProm(params: any[]) {
    console.log(params);
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/1f76e4f662d9f6000', {
        elementos: {
          p_operacion: params[0], //
          p_nrocomprobante: params[2], //
          p_com_codigo: '01',
          p_trncobro_nrodoc: params[1],
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  verificarExistenciaCaja(strcodigo) {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/fbb727bb8c62a80', {
        elementos: {
          p_CAJ_codigop: strcodigo
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  insertarTrnCobroProm(cxcpagoret: Cxcpagoret) {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/12bf1dbef77766000', {
      elementos: {
        
        p_TRNCOBRO_nroDoc: cxcpagoret.TRNCOBRO_NRODOC, // null
        p_TRNCOBRO_nroComprobante: cxcpagoret.TRNCOBRO_NROCOMPROBANTE.trim(),
        p_TRNCOBRO_tipoDoc: cxcpagoret.TRNCOBRO_TIPODOC,
        p_TRNCOBRO_fechaTrn: cxcpagoret.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_concepto: cxcpagoret.TRNCOBRO_CONCEPTO, // vacio
        p_TRNCOBRO_importe: Number(cxcpagoret.TRNCOBRO_IMPORTE), // valor
        p_TRNCOBRO_fechavence: cxcpagoret.TRNCOBRO_FECHAVENCE, // vacio
        p_APLORG_codigo: cxcpagoret.APLORG_CODIGO,
        p_TRNCOBRO_referenciaExterna: cxcpagoret.TRNCOBRO_REFERENCIAEXTERNA,
        p_CLI_codigo: cxcpagoret.CLI_CODIGO.trim(),
        p_VEN_codigo: cxcpagoret.VEN_CODIGO.trim(), // vacio
        p_COM_codigo: cxcpagoret.COM_CODIGO,
        p_ASI_NRO: cxcpagoret.ASI_NRO, // vacio
        p_USU_IDENTIFICACION: this.usuario.identificacion.trim(),
        p_TRNCOBRO_FLAG: Number(cxcpagoret.TRNCOBRO_FLAG), // i
        p_BAN_CODIGO: cxcpagoret.BAN_CODIGO,
        p_TRNCOBRO_EFECTIVO: Number(cxcpagoret.TRNCOBRO_EFECTIVO), // d
        p_TRNCOBRO_CAMBIO: Number(cxcpagoret.TRNCOBRO_CAMBIO), // d
        p_CON_CODIGO: cxcpagoret.CON_CODIGO, // s
        p_TRNCOBRO_SALDOINI: Number(cxcpagoret.TRNCOBRO_SALDOINI), // i
        p_BOD_CODIGO: cxcpagoret.BOD_CODIGO, // s null
        
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarTrnDocumentoProm(params: any[]) {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/12bf1dbef7776e000', {
      elementos: {
        p_TRNCOBRO_nroDoc_1: params[0],
        p_TRNDOCUMENTO_nroDoc_2: params[1],
        p_TRNDOCUMENTO_importe_3: params[2],
        p_COM_codigo_4: params[3],
        p_CLI_codigo_5: params[4],
        p_TRNDOCUMENTO_REF: params[5],
        p_TRNCOBRO_NROCOMPROBANTE: params[6],
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarCuotasPagadasProm(doc: Cxcpagoretdocumentos) {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/533be67b1dcaa00', {
      elementos: {
        p_ENCFAC_NUMERO: doc.TRNDOCUMENTO_NRODOC,
        p_CLI_CODIGO: doc.CLI_CODIGO,
        p_COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarTrnRetencionProm(ret: Cxcpagoretretenciones) {
    console.log(ret);
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/12bf1dbef777dc000', {
      elementos: {
        p_retencion_codigo_1: ret.RETENCION_CODIGO,
        p_trnretencion_descripcion_4: ret.TRNRETENCION_DESCRIPCION,
        p_trnretencion_nro_1: ret.TRNRETENCION_NRO,
        p_trnretencion_base_5: Number(ret.TRNRETENCION_BASE),
        p_trnretencion_porcentaje_6: Number(ret.TRNRETENCION_PORCENTAJE),
        p_trnretencion_totalretenido_7: Number(ret.TRNRETENCION_TOTALRETENIDO),
        p_trncobro_nrodoc_8: ret.TRNCOBRO_NRODOC,
        p_com_codigo_3: ret.COM_CODIGO,
        p_trnretencion_origen: ret.TRNRETENCION_ORIGEN,
        p_con_codigo: ret.CON_CODIGO,
        p_cen_codigo: ret.CEN_CODIGO,
        p_cli_codigo: ret.CLI_CODIGO,
        p_ENCFAC_NUMERO: ret.ENCFAC_NUMERO,
        p_TRNCOBRO_NROCOMPROBANTE: ret.TRNCOBRO_NROCOMPROBANTE
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
//CDPJ
actualizarTipoRetencionProm(retencion, tipo): Promise<any[]> {
  return this.http.post<any[]>(this.way + '/cxc/carteraactiva/533bd9671b775c000000', {
    elementos: {
      trnretencion_tiporet: tipo,
      trnretencion_referencia: retencion.ENCFAC_NUMERO,
      cli_codigo: retencion.CLI_CODIGO
    }
  }, this.confIniciales.httpOptions()).toPromise();
}
//CDPJ
  existeRetencionFactProm(ret: Cxcpagoretretenciones) {
    const strsql = 'SELECT * FROM SACI_TRNRETENCION WHERE TRNRETENCION_ORIGEN IN (\'FAC\',\'CXC\')' +
      ' AND RETENCION_CODIGO = \'' + ret.RETENCION_CODIGO + '\' AND TRNRETENCION_NRO = \'' + ret.TRNRETENCION_NRO +
      '\' AND COM_CODIGO = \'01\' AND TRNRETENCION_REFERENCIA = \'' + ret.ENCFAC_NUMERO + '\'';
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/7719b775d77a980', {
      elementos: {
        RETENCION_CODIGO: ret.RETENCION_CODIGO,
        TRNRETENCION_NRO: ret.TRNRETENCION_NRO,
        ENCFAC_NUMERO: ret.ENCFAC_NUMERO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarNDProm(cxcpagoret: Cxcpagoret) {
    console.log(cxcpagoret);
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/41d776d5bbb77c', {
      elementos: {
        p_trncobro_nrodoc: cxcpagoret.TRNCOBRO_NRODOC,
        p_com_codigo: '01',
        p_fecha: cxcpagoret.TRNCOBRO_FECHATRN,
        p_cli_codigo: cxcpagoret.CLI_CODIGO,
        p_ven_codigo: cxcpagoret.VEN_CODIGO,
        p_usu_identificacion: this.usuario.identificacion.trim()
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarCajaProm(cxcpagoret: Cxcpagoret, fp: Cxcpagoretformapago){
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/257e3b7d62a76c0000', {
      elementos: {
        
        p_CAJ_codigo_1: fp.CAJ_CODIGO, //
        p_TRNCAJ_fecha_2: fp.FECHA, //
        p_TRNCAJ_tipo_3: fp.TRNPAGO_TIPO,
        p_TRNCAJ_numero_4: fp.NUMERO, //
        p_TRNCAJ_formapago_5: fp.TRNPAGO_TIPO, //
        p_TRNCAJ_docnro_6: cxcpagoret.TRNCOBRO_NRODOC, //
        p_TRNCAJ_importe_7: Number(fp.IMPORTE) * (-1), //
        p_TRNCAJ_descripcion_8: fp.CONCEPTO, //
        p_TRNCAJ_anulado: '0', //
        p_TRNCAJ_fechavenci_9: '', //
        p_TRNCAJ_origen_10: 'CXC', //
        p_COM_codigo_11: '01', // cxcpagoret.COM_CODIGO,
        p_BANCLI_codigo_12: '', //
        p_USU_IDENTIFICACION: this.usuario.identificacion.trim(), //
        p_CLI_codigo: cxcpagoret.CLI_CODIGO, //
        p_cen_codigo: '', //
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarBancoProm(cxcpagoret: Cxcpagoret, fp: Cxcpagoretformapago, beneficiario) {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/4afc76fab55d98', {
      elementos: {
        p_BAN_codigo_1: fp.BANCLI_CODIGO, //
        p_TRNBAN_fecha_2: fp.FECHA, //
        p_TRNBAN_tipo_3: fp.TRNPAGO_TIPO,
        p_TRNBAN_numero_4: fp.NUMERO, //
        p_TRNBAN_docnro_5: cxcpagoret.TRNCOBRO_NRODOC, //
        p_TRNBAN_importe_6: Number(fp.IMPORTE) * (-1), //
        p_TRNBAN_concepto_7: fp.CONCEPTO, //
        p_TRNBAN_beneficiario_9: beneficiario,
        p_TRNBAN_anulado_10: '0',
        p_CON_codigo_11: fp.CON_CODIGO, //
        p_COM_codigo_12: '01', //
        p_TRNBAN_origen_13: 'CXC', //
        p_TRNBAN_NUMEROREFUSER: '', //
        p_TRNBAN_FECHAVENCE: fp.FECHA, //
        p_ENCREQ_NUMERO: '', //
        P_PRO_CODIGO: '', //
        p_TRNBAN_PAGOELEC: '', //
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarTrnCobroProm(cxcpagoret: Cxcpagoret) {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/533beeeeecc2f80', {
      elementos: {
        p_TRNCOBRO_nroDoc: cxcpagoret.TRNCOBRO_NRODOC, // null
        p_TRNCOBRO_nroComprobante: cxcpagoret.TRNCOBRO_NROCOMPROBANTE.trim(),
        p_TRNCOBRO_tipoDoc: cxcpagoret.TRNCOBRO_TIPODOC,
        p_TRNCOBRO_fechaTrn: cxcpagoret.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_concepto: cxcpagoret.TRNCOBRO_CONCEPTO, // vacio
        p_TRNCOBRO_importe: Number(cxcpagoret.TRNCOBRO_IMPORTE), // valor
        p_TRNCOBRO_fechavence: cxcpagoret.TRNCOBRO_FECHAVENCE, // vacio
        p_APLORG_codigo: cxcpagoret.APLORG_CODIGO,
        p_TRNCOBRO_referenciaExterna: cxcpagoret.TRNCOBRO_REFERENCIAEXTERNA,
        p_CLI_codigo: cxcpagoret.CLI_CODIGO.trim(),
        p_VEN_codigo: cxcpagoret.VEN_CODIGO, // vacio
        p_COM_codigo: cxcpagoret.COM_CODIGO,
        p_ASI_NRO: cxcpagoret.ASI_NRO, // vacio
        p_USU_IDENTIFICACION: this.usuario.identificacion.trim(),
        p_TRNCOBRO_FLAG: Number(cxcpagoret.TRNCOBRO_FLAG), // i
        p_BAN_CODIGO: cxcpagoret.BAN_CODIGO,
        p_TRNCOBRO_EFECTIVO: Number(cxcpagoret.TRNCOBRO_EFECTIVO), // d
        p_TRNCOBRO_CAMBIO: Number(cxcpagoret.TRNCOBRO_CAMBIO), // d
        p_CON_CODIGO: cxcpagoret.CON_CODIGO, // s
        p_TRNCOBRO_SALDOINI: Number(cxcpagoret.TRNCOBRO_SALDOINI), // i
        p_BOD_CODIGO: cxcpagoret.BOD_CODIGO, // s null
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarTrnDocumentoProm(strcondicion) {
    const strsql = 'DELETE FROM CXC_TRNDOCUMENTO WHERE ' + strcondicion;
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/75656eeeedc33c0', {
      elementos: {
        condicion: strcondicion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarTrnRetencionProm(strcondicion) {
    const strsql = 'DELETE FROM SACI_TRNRETENCION WHERE ' + strcondicion;
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/eacaddddf6eeb800', {
      elementos: {
        condicion: strcondicion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarCajaProm(strNumDoc) {
    const strsql = 'DELETE FROM BAN_TRNCAJ WHERE TRNCAJ_DOCNRO = \'' + strNumDoc + '\' AND COM_CODIGO = \'01\' AND TRNCAJ_ORIGEN = \'CXC\'';
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/7565695d5b62a80', {
      elementos: {
        TRNCAJ_DOCNRO: strNumDoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarBancosProm(strNumDoc) {
    const strsql = 'DELETE FROM BAN_TRNBAN WHERE TRNBAN_DOCNRO = \'' + strNumDoc + '\' AND COM_CODIGO = \'01\' AND TRNBAN_ORIGEN = \'CXC\'';
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/1d595a5756d6ac0000', {
      elementos: {
        TRNBAN_DOCNRO: strNumDoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarEstadoFact(params: any[]) {
    console.log(params);
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/14cef9dcea9b880000', {
      elementos: {
        p_com_codigo: params[0],
        p_cli_codigo: params[1],
        p_encfac_numero: params[2],
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarAsientoRetProm(trncobronrodoc, comprobante) {
    return this.http.post<any[]>(this.way + '/cxc/pagoretenciones/1075ddb5724ebf000', {
      elementos: {
        p_trncobro_nrodoc: trncobronrodoc,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion.trim(),
        p_tipdia_codigo: 'ARTC',
        p_asi_comprobante: comprobante
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  
}


