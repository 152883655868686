import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from 'src/app/usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class NomexpliqubenesocarchtxtService {
  private way: string;
  public identiUsuario="";
  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) { 
      this.way = sessionStorage.getItem('way');
      this.identiUsuario = this.usuario.identificacion;
    }

    async listamaestrocargas(){
      return  await this.http.post(this.way + '/nom/nomliqudeciterc/vqrl22bwrpy9mzy', {
        elementos: {},
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async ejecmaestrocargas(proc:string,ENCLIQUBENESOCI_CODIGO){
      console.log(this.identiUsuario);
      let elementos= {
        p_ENCLIQUBENESOCI_CODIGO :parseInt(ENCLIQUBENESOCI_CODIGO),
        p_com_codigo             :'01',
        p_usu_identificacion     :this.identiUsuario
      }
      console.log(elementos)
      return  await this.http.post(this.way + '/nom/nomliqudeciterc/kvlbiiacql2aibu', {
        elementos: {
          p_ENCLIQUBENESOCI_CODIGO :parseInt(ENCLIQUBENESOCI_CODIGO),
          p_com_codigo             :'01',
          p_usu_identificacion     :String(this.identiUsuario)
        },
        proceso:{
          nombre:proc
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }
    //
    async funGenArchTexto(ENCLIQUBENESOCI_CODIGO){
      return  await this.http.post(this.way + '/nom/nomliqudeciterc/2lkwkp7wzibtnn9', {
        elementos: {
          p_ENCLIQUBENESOCI_CODIGO:parseInt(ENCLIQUBENESOCI_CODIGO)
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
}
