//
// SACI WEB
// Rutina: Proceso Recalcular Kardex
// Propietario: Wilson Ramos
// Modificado por: WR
// Fecha de creación: 2019
// Fecha de Modificación: 27-10-2019
//
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AggridBanComponent} from '../../ban/aggrid-ban/aggrid-ban.component';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {InvprrecalkardexService} from '../invservicios/invprrecalkardex.service';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {DatePipe} from '@angular/common';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { ConcontabilizadocService } from 'src/app/cont/conservicios/concontabilizadoc.service';
import { Usuario } from 'src/app/usuario';

@Component({
  selector: 'app-invprrecalkardex',
  templateUrl: './invprrecalkardex.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class InvprrecalkardexComponent implements OnInit, OnDestroy {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  largo =  '258';
  fechadesde: any;
  fechahasta: any;
  selectAll: boolean;
  opcionBus: string;
  opcionBus2: string;
  public frameworkComponents;
  rowStyle;
  disableBus: boolean;
  busquedaCriterio: string;
  defaultColCalcKardexCOM;
  defaultColCalcKardexDVC;
  defaultColCalcKardexNR;
  defaultColCalcKardexFAC;
  defaultColCalcKardexDVF;
  defaultColCalcKardexGRE;
  defaultColCalcKardexTRF;
  defaultColCalcKardexENSAINV;
  defaultColCalcKardexPROD;
  options: SelectItem[];
  options2: SelectItem[];
  options3: SelectItem[];
  opcionSeleccionada: any;
  rowSelection = 'multiple';
  RANGFECHA: number;//GSRF
  hmpConfiguracion: any[];//GSRF
  porcentaje = 0;

  columnDefsCalcKardexCOM = [
    {
      headerName: '', field: 'SNBOOLEAN', cellRendererFramework: AggridBanComponent, editable: false, checkCierre: false, width: 50
    },
    {
      headerName: 'Documento', field: 'ENCFACPRO_NUMERO', editable: false, width: 400
    },
    {
      headerName: 'Referencia', field: 'ENCFACPRO_REFERENCIA', editable: false, width: 400
    },
    {
      headerName: 'Fecha', field: 'ENCFACPRO_FECHAEMISION', editable: false, width: 200
    }];
  columnDefsCalcKardexDVC = [
    {
      headerName: '', field: 'SNBOOLEAN', cellRendererFramework: AggridBanComponent, editable: false, checkCierre: false, width: 50
    },
    {
      headerName: 'Documento', field: 'ENCDEV_NUMERO', editable: false, width: 400
    },
    {
      headerName: 'Referencia', field: 'ENCDEV_REFERENCIA', editable: false, width: 400
    },
    {
      headerName: 'Fecha', field: 'ENCDEV_FECHAEMISION', editable: false, width: 200
    }];
  columnDefsCalcKardexNR = [
    {
      headerName: '', field: 'SNBOOLEAN', cellRendererFramework: AggridBanComponent, editable: false, checkCierre: false, width: 50
    },
    {
      headerName: 'Documento', field: 'ENCNOTREC_NUMERO', editable: false, width: 400
    },
    {
      headerName: 'Referencia', field: 'ENCNOTREC_REFERENCIA', editable: false, width: 400
    },
    {
      headerName: 'Fecha', field: 'ENCNOTREC_FECHAEMISION', editable: false, width: 200
    }];
  columnDefsCalcKardexFAC = [
    {
      headerName: '', field: 'SNBOOLEAN', cellRendererFramework: AggridBanComponent, editable: false, checkCierre: false, width: 100
    },
    {
      headerName: 'Documento', field: 'ENCFAC_NUMERO', editable: false, width: 400
    },
    {
      headerName: 'Fecha', field: 'ENCFAC_FECHAEMISION', editable: false, width: 200
    }];
  columnDefsCalcKardexDVF = [
    {
      headerName: '', field: 'SNBOOLEAN', cellRendererFramework: AggridBanComponent, editable: false, checkCierre: false, width: 50
    },
    {
      headerName: 'Documento', field: 'ENCDVF_NUMERO', editable: false, width: 400
    },
    {
      headerName: 'Fecha', field: 'ENCDVF_FECHAEMISION', editable: false, width: 200
    }];
  columnDefsCalcKardexGRE = [
    {
      headerName: '', field: 'SNBOOLEAN', cellRendererFramework: AggridBanComponent, editable: false, checkCierre: false, width: 50
    },
    {
      headerName: 'Documento', field: 'ENCGRE_NUMERO', editable: false, width: 400
    },
    {
      headerName: 'Referencia', field: 'ENCGRE_CODIGO', editable: false, width: 400
    },
    {
      headerName: 'Fecha', field: 'ENCGRE_FECHAEMISION', editable: false, width: 200
    }];
  columnDefsCalcKardexTRF = [
    {
      headerName: '', field: 'SNBOOLEAN', cellRendererFramework: AggridBanComponent, editable: false, checkCierre: false, width: 50
    },
    {
      headerName: 'Documento', field: 'TRANSFER_NRO', editable: false, width: 400
    },
    {
      headerName: 'Referencia', field: 'TRANSFER_COMPROBANTE', editable: false, width: 400
    },
    {
      headerName: 'Fecha', field: 'TRANSFER_FECHATRANSFER', editable: false, width: 200
    }];
  columnDefsCalcKardexENSAINV = [
    {
      headerName: '', field: 'SNBOOLEAN', cellRendererFramework: AggridBanComponent, editable: false, checkCierre: false, width: 50
    },
    {
      headerName: 'Documento', field: 'KAR_NRO', editable: false, width: 400
    },
    {
      headerName: 'Referencia', field: 'KAR_NROCOMPROBANTE', editable: false, width: 400
    },
    {
      headerName: 'Fecha', field: 'KAR_FECHA', editable: false, width: 200
    }];

    columnDefsCalcKardexPROD = [
      {
        headerName: '', field: 'SNBOOLEAN', cellRendererFramework: AggridBanComponent, editable: false, checkCierre: false, width: 50
      },
      {
        headerName: 'Documento', field: 'ENCORDPRO_NUMERO', editable: false, width: 400
      },
      {
        headerName: 'Referencia', field: 'ENCORDPRO_REFERENCIA', editable: false, width: 400
      },
      {
        headerName: 'Fecha', field: 'ENCORDPRO_FECHAEMISION', editable: false, width: 200
      },
      {
        headerName: 'Comentario', field: 'ENCORDPRO_COMENTARIO', editable: false, width: 400
      },];
  
  constructor(public calcService: InvprrecalkardexService, private messageService: MessageService,
              private confirmationService: ConfirmationService, private datePipe: DatePipe,
              private confIniciales: ConfInicialesService, private init: NuevoComponentService,
              private utilitariosService: UtilitariosService, private auditoriagral: AuditoriagralService,
              public usuario: Usuario ,public contabilizadocService: ConcontabilizadocService,) {
    this.agTable();
    this.calcService.resultadosCOM = [];
    this.calcService.resultadosGRE = [];
    this.calcService.resultadosFAC = [];
    this.calcService.resultadosNR = [];
    this.calcService.resultadosDVC = [];
    this.calcService.resultadosDVF = [];
    this.calcService.resultadosTRF = [];
    this.calcService.resultadosPROD = [];
    this.calcService.resultados = [];
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColCalcKardexCOM = {
      editable: true,
      objeto: 'maecalckardexcom'
    };
    this.defaultColCalcKardexDVC = {
      editable: true,
      objeto: 'maecalckardexdvc'
    };
    this.defaultColCalcKardexNR = {
      editable: true,
      objeto: 'maecalckardexnr'
    };
    this.defaultColCalcKardexFAC = {
      editable: true,
      objeto: 'maecalckardexfac'
    };
    this.defaultColCalcKardexDVF = {
      editable: true,
      objeto: 'maecalckardexdvf'
    };
    this.defaultColCalcKardexGRE = {
      editable: true,
      objeto: 'maecalckardexgre'
    };
    this.defaultColCalcKardexTRF = {
      editable: true,
      objeto: 'maecalckardextrf'
    };
    this.defaultColCalcKardexENSAINV = {
      editable: true,
      objeto: 'maecalckardexensainv'
    };

    this.defaultColCalcKardexPROD = {
      editable: true,
      objeto: 'maecalckardexprod'
    };
  }
  
  ngOnInit() {
    // this.largo = '523';
    this.selectAll = false;
    this.disableBus = true;
    this.busquedaCriterio = '';
    this.fechadesde = new Date();
    this.fechahasta = new Date();
    this.opcionBus = 'Documento';
    this.opcionBus2 = 'Documento';
    this.opcionSeleccionada = 'COM';
    this.options = [{label: 'Factura de Compras', value: 'COM'}, {label: 'Devoluciones de Compra', value: 'DVC'},
      {label: 'Notas de Recepción', value: 'NR'}, {label: 'Facturas de Ventas', value: 'FAC'}, {
        label: 'Devoluciones de Ventas',
        value: 'DVF'
      }, {label: 'Guías de Remisión', value: 'GRE'}, {label: 'Transferencias', value: 'TRF'},
      {label: 'Entradas de Producción', value: 'ENPROD'}, {label: 'Salidas de Producción', value: 'SAPROD'},
      {label: 'Entradas y Salidas de Inventario', value: 'ENSAINV'},
     {label: 'Orden de Producción', value: 'ORDPRO'}];
    this.options2 = [{label: 'Documento', value: 'Documento'}, {label: 'Referencia', value: 'Referencia'},
      {label: 'Fecha', value: 'Fecha'}];
    this.options3 = [{label: 'Documento', value: 'Documento'}, {label: 'Fecha', value: 'Fecha'}];
    //GSRF
    this.auditoriagral.obtenerConfiguracion1().subscribe((conf) => {
      this.hmpConfiguracion = conf;
      this.RANGFECHA = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'RANGFECHA').CFG_VALOR1;
    })
    //GSRF
  }
  
  ngOnDestroy(): void {
    this.confIniciales.instanciaCalcKardex--;
  }
  
  async ingresarResultados() {
    this.porcentaje = 0;
    const fechadesde = this.datePipe.transform(this.fechadesde, 'dd/MM/yyyy');
    const fechahasta = this.datePipe.transform(this.fechahasta, 'dd/MM/yyyy');
    console.log( fechadesde, fechahasta, this.opcionSeleccionada );
    //GSRF
    const comdesde = this.datePipe.transform(this.fechadesde, 'MM/yyyy');
    const comhasta= this.datePipe.transform(this.fechahasta, 'MM/yyyy');
  
    console.log('989879898---', this.RANGFECHA)
    if(this.RANGFECHA === 1){
      if(comdesde !== comhasta){
        this.messageService.add({
          key: 'precal',
          severity: 'warn',
          summary: 'Búsqueda',
          detail: 'El rango de fechas no puede ser superior a un mes'
        });
        return;
      }
    }
    const dia = this.fechadesde.getDate();
    const mes = this.fechadesde.getMonth();
    const anio = this.fechadesde.getFullYear();
    const errperio = await this.contabilizadocService.verificarPeriodoContablenew(dia, mes, anio);
    console.log('4654546++++',errperio)
    if(errperio[0].CONTADOR === 0){
      this.messageService.add({
        key: 'precal',
        severity: 'warn',
        summary: 'Búsqueda',
        detail: 'La Fecha debe estar dentro del periodo contable.'
      });
      return;
    }
    console.log( '11111111',comdesde, comhasta, this.opcionSeleccionada );
    //GSRF
    if (this.opcionSeleccionada === 'COM') {
      this.calcService.getResultadosDocumento(fechadesde,
        fechahasta, this.opcionSeleccionada).subscribe((res) => {
        this.calcService.resultadosCOM = res;
        if (this.calcService.resultadosCOM !== null) {
          this.disableBus = false;
          this.calcService.resultadosCOM.map((resultado) => {
            console.log( resultado);
            /*resultado.ENCFACPRO_FECHAEMISION = resultado.ENCFACPRO_FECHAEMISION.substr(0, 9);
            console.log( resultado.ENCFACPRO_FECHAEMISION  );*/
            /*this.modificarFecha( resultado.ENCFACPRO_FECHAEMISION );
            if (resultado.ENCFACPRO_FECHAEMISION.includes('-')) {
              resultado.ENCFACPRO_FECHAEMISION = this.datePipe.transform(resultado.ENCFACPRO_FECHAEMISION,
                'dd/MM/yyyy');
            } else {
              resultado.ENCFACPRO_FECHAEMISION = resultado.ENCFACPRO_FECHAEMISION;
            }*/
            resultado.ENCFACPRO_FECHAEMISION = this.modificarFecha( resultado.ENCFACPRO_FECHAEMISION );
            resultado.SNBOOLEAN = false;
          });
        } else if (this.calcService.resultadosCOM === null) {
          this.messageService.add({
            key: 'precal',
            severity: 'warn',
            summary: 'Búsqueda',
            detail: 'No existen resultados para su búsqueda'
          });
        }
      });
    }
    if (this.opcionSeleccionada === 'DVC') {
      this.calcService.getResultadosDocumento(fechadesde,
        fechahasta, this.opcionSeleccionada).subscribe((res) => {
        this.calcService.resultadosDVC = res;
        if (this.calcService.resultadosDVC !== null) {
          this.disableBus = false;
          this.calcService.resultadosDVC.map((resultado) => {
            /*resultado.ENCDEV_FECHAEMISION = resultado.ENCDEV_FECHAEMISION.toLocaleString().substr(0, 9);
            resultado.ENCDEV_FECHAEMISION = this.datePipe.transform(resultado.ENCDEV_FECHAEMISION.toLocaleString(),
              'dd/MM/yy')*/
            resultado.ENCDEV_FECHAEMISION = this.modificarFecha(resultado.ENCDEV_FECHAEMISION);
            resultado.SNBOOLEAN = false;
          });
        } else if (this.calcService.resultadosDVC === null) {
          this.messageService.add({
            key: 'precal',
            severity: 'warn',
            summary: 'Búsqueda',
            detail: 'No existen resultados para su búsqueda'
          });
        }
      });
    }
    if (this.opcionSeleccionada === 'NR') {
      this.calcService.getResultadosDocumento(fechadesde,
        fechahasta, this.opcionSeleccionada).subscribe((res) => {
        this.calcService.resultadosNR = res;
        if (this.calcService.resultadosNR !== null) {
          this.disableBus = false;
          this.calcService.resultadosNR.map((resultado) => {
            
            /*resultado.ENCNOTREC_FECHAEMISION = resultado.ENCNOTREC_FECHAEMISION.toLocaleString().substr(0, 9);
            resultado.ENCNOTREC_FECHAEMISION = this.datePipe.transform(resultado.ENCNOTREC_FECHAEMISION.toLocaleString(),
              'dd/MM/yy');*/
            resultado.ENCNOTREC_FECHAEMISION = this.modificarFecha(resultado.ENCNOTREC_FECHAEMISION);
            resultado.SNBOOLEAN = false;
          });
        } else if (this.calcService.resultadosNR === null) {
          this.messageService.add({
            key: 'precal',
            severity: 'warn',
            summary: 'Búsqueda',
            detail: 'No existen resultados para su búsqueda'
          });
        }
      });
    }
    if (this.opcionSeleccionada === 'FAC') {
      this.calcService.getResultadosDocumento(fechadesde,
        fechahasta, this.opcionSeleccionada).subscribe((res) => {
        this.calcService.resultadosFAC = res;
        if (this.calcService.resultadosFAC !== null) {
          this.disableBus = false;
          this.calcService.resultadosFAC.map((resultado) => {
            /*console.log( resultado);
            resultado.ENCFAC_FECHAEMISION = resultado.ENCFAC_FECHAEMISION.toLocaleString().substr(0, 9);
            console.log( resultado.ENCFAC_FECHAEMISION  );
            resultado.ENCFAC_FECHAEMISION = this.datePipe.transform(resultado.ENCFAC_FECHAEMISION.toLocaleString(),
              'dd/MM/yy');
            console.log( resultado.ENCFAC_FECHAEMISION  );*/
            resultado.ENCFAC_FECHAEMISION = this.modificarFecha(resultado.ENCFAC_FECHAEMISION);
            resultado.SNBOOLEAN = false;
          });
        } else if (this.calcService.resultadosFAC === null) {
          this.messageService.add({
            key: 'precal',
            severity: 'warn',
            summary: 'Búsqueda',
            detail: 'No existen resultados para su búsqueda'
          });
        }
      });
    }
    if (this.opcionSeleccionada === 'DVF') {
      this.calcService.getResultadosDocumento(fechadesde,
        fechahasta, this.opcionSeleccionada).subscribe((res) => {
        this.calcService.resultadosDVF = res;
        if (this.calcService.resultadosDVF !== null) {
          this.disableBus = false;
          this.calcService.resultadosDVF.map((resultado) => {
            /*resultado.ENCDVF_FECHAEMISION = resultado.ENCDVF_FECHAEMISION.toLocaleString().substr(0, 9);
            resultado.ENCDVF_FECHAEMISION = this.datePipe.transform(resultado.ENCDVF_FECHAEMISION.toLocaleString(),
              'dd/MM/yy');*/
            resultado.ENCDVF_FECHAEMISION = this.modificarFecha(resultado.ENCDVF_FECHAEMISION);
            resultado.SNBOOLEAN = false;
          });
        } else if (this.calcService.resultadosDVF === null) {
          this.messageService.add({
            key: 'precal',
            severity: 'warn',
            summary: 'Búsqueda',
            detail: 'No existen resultados para su búsqueda'
          });
        }
      });
    }
    if (this.opcionSeleccionada === 'GRE') {
      this.calcService.getResultadosDocumento(fechadesde,
        fechahasta, this.opcionSeleccionada).subscribe((res) => {
        this.calcService.resultadosGRE = res;
        if (this.calcService.resultadosGRE !== null) {
          this.disableBus = false;
          this.calcService.resultadosGRE.map((resultado) => {
            /*resultado.ENCGRE_FECHAEMISION = resultado.ENCGRE_FECHAEMISION.toLocaleString().substr(0, 9);
            resultado.ENCGRE_FECHAEMISION = this.datePipe.transform(resultado.ENCGRE_FECHAEMISION.toLocaleString(),
              'dd/MM/yy');*/
            resultado.ENCGRE_FECHAEMISION = this.modificarFecha(resultado.ENCGRE_FECHAEMISION);
            resultado.SNBOOLEAN = false;
          });
        } else if (this.calcService.resultadosGRE === null) {
          this.messageService.add({
            key: 'precal',
            severity: 'warn',
            summary: 'Búsqueda',
            detail: 'No existen resultados para su búsqueda'
          });
        }
      });
    }
    if (this.opcionSeleccionada === 'TRF') {
      this.calcService.getResultadosDocumento(fechadesde,
        fechahasta, this.opcionSeleccionada).subscribe((res) => {
        this.calcService.resultadosTRF = res;
        if (this.calcService.resultadosTRF !== null) {
          this.disableBus = false;
          this.calcService.resultadosTRF.map((resultado) => {
            /*resultado.TRANSFER_FECHATRANSFER = this.datePipe.transform(resultado.TRANSFER_FECHATRANSFER.toLocaleString().substr(0, 9),
              'dd/MM/yy');*/
            resultado.TRANSFER_FECHATRANSFER = this.modificarFecha(resultado.TRANSFER_FECHATRANSFER);
            resultado.SNBOOLEAN = false;
          });
        } else if (this.calcService.resultadosTRF === null) {
          this.messageService.add({
            key: 'precal',
            severity: 'warn',
            summary: 'Búsqueda',
            detail: 'No existen resultados para su búsqueda'
          });
        }
      });
    }
    if (this.opcionSeleccionada === 'ENSAINV' || this.opcionSeleccionada === 'ENPROD' || this.opcionSeleccionada === 'SAPROD') {
      this.calcService.getResultadosDocumento(fechadesde,
        fechahasta, this.opcionSeleccionada).subscribe((res) => {
        this.calcService.resultados = res;
        if (this.calcService.resultados !== null) {
          this.disableBus = false;
          this.calcService.resultados.map((resultado) => {
            /*resultado.KAR_FECHA = resultado.KAR_FECHA.toLocaleString().substr(0, 9);
            resultado.KAR_FECHA = this.datePipe.transform(resultado.KAR_FECHA.toLocaleString(),
              'dd/MM/yy');*/
            resultado.KAR_FECHA = this.modificarFecha(resultado.KAR_FECHA);
            resultado.SNBOOLEAN = false;
          });
        } else if (this.calcService.resultados === null) {
          this.messageService.add({
            key: 'precal',
            severity: 'warn',
            summary: 'Búsqueda',
            detail: 'No existen resultados para su búsqueda'
          });
        }
      });
    }

    if (this.opcionSeleccionada === 'ORDPRO') {
      
      const res = await this.calcService.getResultadosDocumentoProd(fechadesde,
        fechahasta);
      this.calcService.resultadosPROD = res;
      if (this.calcService.resultadosPROD.length > 0) {
        this.disableBus = false;
        this.calcService.resultadosPROD.map((resultado) => {
          resultado.ENCORDPRO_FECHAEMISION = this.modificarFecha(resultado.ENCORDPRO_FECHAEMISION);
          resultado.SNBOOLEAN = false;
        });
      } else {
        this.messageService.add({
          key: 'precal',
          severity: 'warn',
          summary: 'Búsqueda',
          detail: 'No existen resultados para su búsqueda'
        });
      }
    }
  }
  
  confirmarRecalcularKardex() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea recalcular el kardex?',
      header: 'Recalcular el kardex',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarprecal',
      accept: () => {
        this.recalcularKardex();
      },
      reject: () => {
      }
    });
  }
  
  async recalcularKardex() {
    this.porcentaje = 0;
    if (this.opcionSeleccionada === 'COM') {
      if (this.calcService.resultadosCOM !== undefined) {
        for (const com of this.calcService.resultadosCOM) {
          this.porcentaje = this.porcentaje + Number(100 /this.calcService.resultadosCOM.length);
          if (com.SNBOOLEAN === true) {
            await this.calcService.registrarKardexProm(com, 1)
            com.SNBOOLEAN = false;
            this.auditoriagral.registrarAuditoria('INV_RECALCULAR_KARDEX', this.opcionSeleccionada+'/' + String(com.ENCFACPRO_NUMERO), 'I',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
          }
        }
        this.mensajeExito();
        this.porcentaje = 0;
        this.aggrid.refreshaggrid(this.calcService.resultadosCOM, this.defaultColCalcKardexCOM.objeto);
        /* this.calcService.resultadosCOM.map((com) => {
          if (com.SNBOOLEAN === true) {
            this.calcService.registrarKardex(com, 1).subscribe(() => {
              com.SNBOOLEAN = false;
            });
          }
        }); */
        
      }
    }
    if (this.opcionSeleccionada === 'NR') {
      if (this.calcService.resultadosNR !== undefined) {
        for (const nr of this.calcService.resultadosNR) {
          this.porcentaje = this.porcentaje + Number(100 /this.calcService.resultadosNR.length);
          if (nr.SNBOOLEAN === true) {
            await this.calcService.registrarKardexProm(nr, 2);
              nr.SNBOOLEAN = false;
              this.auditoriagral.registrarAuditoria('RECALCULAR_KARDEX', this.opcionSeleccionada+'/' + String(nr.ENCNOTREC_NUMERO), 'I',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
          }
        }
        /* this.calcService.resultadosNR.map((nr) => {
          if (nr.SNBOOLEAN === true) {
            this.calcService.registrarKardex(nr, 2).subscribe(() => {
              nr.SNBOOLEAN = false;
            });
          }
        }); */
        this.mensajeExito();
        this.porcentaje = 0;
        this.aggrid.refreshaggrid(this.calcService.resultadosNR, this.defaultColCalcKardexNR.objeto);
      }
    }
    if (this.opcionSeleccionada === 'DVC') {
      if (this.calcService.resultadosDVC !== undefined) {
        for (const dvc of this.calcService.resultadosDVC) {
          this.porcentaje = this.porcentaje + Number(100 /this.calcService.resultadosDVC.length);
          if (dvc.SNBOOLEAN === true) {
            await this.calcService.registrarKardexProm(dvc, 3);
            dvc.SNBOOLEAN = false;
            this.auditoriagral.registrarAuditoria('RECALCULAR_KARDEX', this.opcionSeleccionada+'/' + String(dvc.ENCDEV_NUMERO), 'I',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
          }
        }
        /* this.calcService.resultadosDVC.map((dvc) => {
          if (dvc.SNBOOLEAN === true) {
            this.calcService.registrarKardex(dvc, 3).subscribe(() => {
              dvc.SNBOOLEAN = false;
            });
          }
        }); */
        this.mensajeExito();
        this.porcentaje = 0;
        this.aggrid.refreshaggrid(this.calcService.resultadosDVC, this.defaultColCalcKardexDVC.objeto);
      }
    }
    if (this.opcionSeleccionada === 'FAC') {
      if (this.calcService.resultadosFAC !== undefined) {
        for (const fac of this.calcService.resultadosFAC) {
          this.porcentaje = this.porcentaje + Number(100 /this.calcService.resultadosFAC.length);
          if (fac.SNBOOLEAN === true) {
            await this.calcService.registrarKardexProm(fac, 4);
              fac.SNBOOLEAN = false;
              this.auditoriagral.registrarAuditoria('RECALCULAR_KARDEX', this.opcionSeleccionada+'/' + String(fac.ENCFAC_NUMERO), 'I',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
          }
        }
        /* this.calcService.resultadosFAC.map((fac) => {
          if (fac.SNBOOLEAN === true) {
            this.calcService.registrarKardex(fac, 4).subscribe(() => {
              fac.SNBOOLEAN = false;
            });
          }
        }); */
        this.mensajeExito();
        this.porcentaje = 0;
        this.aggrid.refreshaggrid(this.calcService.resultadosFAC, this.defaultColCalcKardexFAC.objeto);
      }
    }
    if (this.opcionSeleccionada === 'DVF') {
      if (this.calcService.resultadosDVF !== undefined) {
        for (const dvf of this.calcService.resultadosDVF) {
          this.porcentaje = this.porcentaje + Number(100 /this.calcService.resultadosDVF.length);
          if (dvf.SNBOOLEAN === true) {
            await this.calcService.registrarKardexProm(dvf, 5);
              dvf.SNBOOLEAN = false;
            this.auditoriagral.registrarAuditoria('RECALCULAR_KARDEX', this.opcionSeleccionada+'/' + String(dvf.ENCDVF_NUMERO), 'I',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
          }
        }
        /* this.calcService.resultadosDVF.map((dvf) => {
          if (dvf.SNBOOLEAN === true) {
            this.calcService.registrarKardex(dvf, 5).subscribe(() => {
              dvf.SNBOOLEAN = false;
            });
          }
        }); */
        this.mensajeExito();
        this.porcentaje = 0;
        this.aggrid.refreshaggrid(this.calcService.resultadosDVF, this.defaultColCalcKardexDVF.objeto);
      }
    }
    if (this.opcionSeleccionada === 'GRE') {
      if (this.calcService.resultadosGRE !== undefined) {
        for (const gre of this.calcService.resultadosGRE) {
          this.porcentaje = this.porcentaje + Number(100 /this.calcService.resultadosGRE.length);
          if (gre.SNBOOLEAN === true) {
            await this.calcService.registrarKardexProm(gre, 6);
              gre.SNBOOLEAN = false;
              this.auditoriagral.registrarAuditoria('RECALCULAR_KARDEX', this.opcionSeleccionada+'/' + String(gre.ENCGRE_CODIGO), 'I',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
          }
        }
        /* this.calcService.resultadosGRE.map((gre) => {
          if (gre.SNBOOLEAN === true) {
            this.calcService.registrarKardex(gre, 6).subscribe(() => {
              gre.SNBOOLEAN = false;
            });
          }
        }); */
        this.mensajeExito();
        this.porcentaje = 0;
        this.aggrid.refreshaggrid(this.calcService.resultadosGRE, this.defaultColCalcKardexGRE.objeto);
      }
    }
    if (this.opcionSeleccionada === 'ENPROD' || this.opcionSeleccionada === 'SAPROD' || this.opcionSeleccionada === 'ENSAINV') {
      if (this.calcService.resultados !== undefined) {
        for (const ensa of this.calcService.resultados) {
          this.porcentaje = this.porcentaje + Number(100 /this.calcService.resultados.length);
          if (ensa.SNBOOLEAN === true) {
            await this.calcService.registrarKardexProm(ensa, 7);
              ensa.SNBOOLEAN = false;
              this.auditoriagral.registrarAuditoria('RECALCULAR_KARDEX', this.opcionSeleccionada+'/' + String(ensa.KAR_NRO), 'I',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
          }
        }
        /* this.calcService.resultados.map((ensa) => {
          if (ensa.SNBOOLEAN === true) {
            this.calcService.registrarKardex(ensa, 7).subscribe(() => {
              ensa.SNBOOLEAN = false;
            });
          }
        }); */
        this.mensajeExito();
        this.porcentaje = 0;
        this.aggrid.refreshaggrid(this.calcService.resultados, this.defaultColCalcKardexENSAINV.objeto);
      }
    }
    if (this.opcionSeleccionada === 'TRF') {
      if (this.calcService.resultadosTRF !== undefined) {
        for (const trf of this.calcService.resultadosTRF) {
          this.porcentaje = this.porcentaje + Number(100 /this.calcService.resultadosTRF.length);
          if (trf.SNBOOLEAN === true) {
            const res = await this.calcService.registrarKardexProm(trf, 8);
            trf.SNBOOLEAN = false;
            this.auditoriagral.registrarAuditoria('RECALCULAR_KARDEX', this.opcionSeleccionada+'/' + String(trf.TRANSFER_NRO), 'I',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
          }
        }
        /* this.calcService.resultadosTRF.map((trf) => {
          if (trf.SNBOOLEAN === true) {
            this.calcService.registrarKardex(trf, 8).subscribe((res) => {
              trf.SNBOOLEAN = false;
              console.log(res);
            });
          }
        }); */
        this.mensajeExito();
        this.porcentaje = 0;
        this.aggrid.refreshaggrid(this.calcService.resultadosTRF, this.defaultColCalcKardexTRF.objeto);
      }
    }


    if (this.opcionSeleccionada === 'ORDPRO') {
      if (this.calcService.resultadosPROD !== undefined) {
        for (const prod of this.calcService.resultadosPROD) {
          this.porcentaje = this.porcentaje + Number(100 /this.calcService.resultadosPROD.length);
          if (prod.SNBOOLEAN === true) {
            await this.calcService.registrarKardexOrdProd(prod.ENCORDPRO_NUMERO);
            prod.SNBOOLEAN = false;
            this.auditoriagral.registrarAuditoria('RECALCULAR_KARDEX', this.opcionSeleccionada+'/' + String(prod.ENCORDPRO_NUMERO), 'I',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
          }
        }
        this.mensajeExito();
        this.porcentaje = 0;
        this.aggrid.refreshaggrid(this.calcService.resultadosNR, this.defaultColCalcKardexPROD.objeto);
      }
    }
  }
  
  mensajeExito() {
    this.messageService.add({
      key: 'precal',
      severity: 'success',
      summary: 'Recalcular Costo',
      detail: 'La operación se realizó con éxito'
    });
    this.ingresarResultados();
  }
  
  seleccionarTodos() {
    if (this.selectAll === true) {
      if (this.opcionSeleccionada === 'COM') {
        this.calcService.resultadosCOM.map((com) => {
          com.SNBOOLEAN = true;
        });
        this.aggrid.refreshaggrid(this.calcService.resultadosCOM, this.defaultColCalcKardexCOM.objeto);
      }
      if (this.opcionSeleccionada === 'DVC') {
        this.calcService.resultadosDVC.map((dvc) => {
          dvc.SNBOOLEAN = true;
        });
        this.aggrid.refreshaggrid(this.calcService.resultadosDVC, this.defaultColCalcKardexDVC.objeto);
      }
      if (this.opcionSeleccionada === 'NR') {
        this.calcService.resultadosNR.map((nr) => {
          nr.SNBOOLEAN = true;
        });
        this.aggrid.refreshaggrid(this.calcService.resultadosNR, this.defaultColCalcKardexNR.objeto);
      }
      if (this.opcionSeleccionada === 'FAC') {
        this.calcService.resultadosFAC.map((fac) => {
          fac.SNBOOLEAN = true;
        });
        this.aggrid.refreshaggrid(this.calcService.resultadosFAC, this.defaultColCalcKardexFAC.objeto);
      }
      if (this.opcionSeleccionada === 'DVF') {
        this.calcService.resultadosDVF.map((dvf) => {
          dvf.SNBOOLEAN = true;
        });
        this.aggrid.refreshaggrid(this.calcService.resultadosDVF, this.defaultColCalcKardexDVF.objeto);
      }
      if (this.opcionSeleccionada === 'GRE') {
        this.calcService.resultadosGRE.map((gre) => {
          gre.SNBOOLEAN = true;
        });
        this.aggrid.refreshaggrid(this.calcService.resultadosGRE, this.defaultColCalcKardexGRE.objeto);
      }
      if (this.opcionSeleccionada === 'TRF') {
        this.calcService.resultadosTRF.map((trf) => {
          trf.SNBOOLEAN = true;
        });
        this.aggrid.refreshaggrid(this.calcService.resultadosTRF, this.defaultColCalcKardexTRF.objeto);
      }
      if (this.opcionSeleccionada === 'ENPROD' || this.opcionSeleccionada === 'SAPROD' ||
        this.opcionSeleccionada === 'ENSAINV') {
        this.calcService.resultados.map((ensa) => {
          ensa.SNBOOLEAN = true;
        });
        this.aggrid.refreshaggrid(this.calcService.resultados, this.defaultColCalcKardexENSAINV.objeto);
      }

      if (this.opcionSeleccionada === 'ORDPRO') {
        this.calcService.resultadosPROD.map((prod) => {
          prod.SNBOOLEAN = true;
        });
        this.aggrid.refreshaggrid(this.calcService.resultadosPROD, this.defaultColCalcKardexPROD.objeto);
      }
    } else if (this.selectAll === false) {
      if (this.opcionSeleccionada === 'COM') {
        this.calcService.resultadosCOM.map((com) => {
          com.SNBOOLEAN = false;
        });
        this.aggrid.refreshaggrid(this.calcService.resultadosCOM, this.defaultColCalcKardexCOM.objeto);
      }
      if (this.opcionSeleccionada === 'DVC') {
        this.calcService.resultadosDVC.map((dvc) => {
          dvc.SNBOOLEAN = false;
        });
        this.aggrid.refreshaggrid(this.calcService.resultadosDVC, this.defaultColCalcKardexDVC.objeto);
      }
      if (this.opcionSeleccionada === 'NR') {
        this.calcService.resultadosNR.map((nr) => {
          nr.SNBOOLEAN = false;
        });
        this.aggrid.refreshaggrid(this.calcService.resultadosNR, this.defaultColCalcKardexNR.objeto);
      }
      if (this.opcionSeleccionada === 'FAC') {
        this.calcService.resultadosFAC.map((fac) => {
          fac.SNBOOLEAN = false;
        });
        this.aggrid.refreshaggrid(this.calcService.resultadosFAC, this.defaultColCalcKardexFAC.objeto);
      }
      if (this.opcionSeleccionada === 'DVF') {
        this.calcService.resultadosDVF.map((dvf) => {
          dvf.SNBOOLEAN = false;
        });
        this.aggrid.refreshaggrid(this.calcService.resultadosDVF, this.defaultColCalcKardexDVF.objeto);
      }
      if (this.opcionSeleccionada === 'GRE') {
        this.calcService.resultadosGRE.map((gre) => {
          gre.SNBOOLEAN = false;
        });
        this.aggrid.refreshaggrid(this.calcService.resultadosGRE, this.defaultColCalcKardexGRE.objeto);
      }
      if (this.opcionSeleccionada === 'TRF') {
        this.calcService.resultadosTRF.map((trf) => {
          trf.SNBOOLEAN = false;
        });
        this.aggrid.refreshaggrid(this.calcService.resultadosTRF, this.defaultColCalcKardexTRF.objeto);
      }
      if (this.opcionSeleccionada === 'ENPROD' || this.opcionSeleccionada === 'SAPROD' ||
        this.opcionSeleccionada === 'ENSAINV') {
        this.calcService.resultados.map((ensa) => {
          ensa.SNBOOLEAN = false;
        });
        this.aggrid.refreshaggrid(this.calcService.resultados, this.defaultColCalcKardexENSAINV.objeto);
      }
      if (this.opcionSeleccionada === 'ORDPRO') {
        this.calcService.resultadosPROD.map((prod) => {
          prod.SNBOOLEAN = false;
        });
        this.aggrid.refreshaggrid(this.calcService.resultadosPROD, this.defaultColCalcKardexPROD.objeto);
      }
    }
  }
  
  buscar() {
    if (this.opcionSeleccionada === 'COM') {
      this.calcService.resultadosCOM.map((com) => {
        if (this.opcionBus === 'Documento') {
          if (com.ENCFACPRO_NUMERO.toString().includes(this.busquedaCriterio)) {
            com.SNBOOLEAN = true;
          }
        }
        if (this.opcionBus === 'Referencia') {
          if (com.ENCFACPRO_REFERENCIA.toString().includes(this.busquedaCriterio)) {
            com.SNBOOLEAN = true;
          }
        }
        if (this.opcionBus === 'Fecha') {
          if (com.ENCFACPRO_FECHAEMISION.toString().includes(this.busquedaCriterio)) {
            com.SNBOOLEAN = true;
          }
        }
      });
      this.aggrid.refreshaggrid(this.calcService.resultadosCOM, this.defaultColCalcKardexCOM.objeto);
    }
    if (this.opcionSeleccionada === 'DVC') {
      this.calcService.resultadosDVC.map((com) => {
        if (this.opcionBus === 'Documento') {
          if (com.ENCDEV_NUMERO.toString().includes(this.busquedaCriterio)) {
            com.SNBOOLEAN = true;
          }
        }
        if (this.opcionBus === 'Referencia') {
          if (com.ENCDEV_REFERENCIA.toString().includes(this.busquedaCriterio)) {
            com.SNBOOLEAN = true;
          }
        }
        if (this.opcionBus === 'Fecha') {
          if (com.ENCDEV_FECHAEMISION.toString().includes(this.busquedaCriterio)) {
            com.SNBOOLEAN = true;
          }
        }
      });
      this.aggrid.refreshaggrid(this.calcService.resultadosDVC, this.defaultColCalcKardexDVC.objeto);
    }
    if (this.opcionSeleccionada === 'NR') {
      this.calcService.resultadosNR.map((com) => {
        if (this.opcionBus === 'Documento') {
          if (com.ENCNOTREC_NUMERO.toString().includes(this.busquedaCriterio)) {
            com.SNBOOLEAN = true;
          }
        }
        if (this.opcionBus === 'Referencia') {
          if (com.ENCNOTREC_REFERENCIA.toString().includes(this.busquedaCriterio)) {
            com.SNBOOLEAN = true;
          }
        }
        if (this.opcionBus === 'Fecha') {
          if (com.ENCNOTREC_FECHAEMISION.toString().includes(this.busquedaCriterio)) {
            com.SNBOOLEAN = true;
          }
        }
      });
      this.aggrid.refreshaggrid(this.calcService.resultadosNR, this.defaultColCalcKardexNR.objeto);
    }
    if (this.opcionSeleccionada === 'FAC') {
      this.calcService.resultadosFAC.map((com) => {
        if (this.opcionBus2 === 'Documento') {
          if (com.ENCFAC_NUMERO.toString().includes(this.busquedaCriterio)) {
            com.SNBOOLEAN = true;
          }
        }
        if (this.opcionBus2 === 'Fecha') {
          if (com.ENCFAC_FECHAEMISION.toString().includes(this.busquedaCriterio)) {
            com.SNBOOLEAN = true;
          }
        }
      });
      this.aggrid.refreshaggrid(this.calcService.resultadosFAC, this.defaultColCalcKardexFAC.objeto);
    }
    if (this.opcionSeleccionada === 'DVF') {
      this.calcService.resultadosDVF.map((com) => {
        if (this.opcionBus2 === 'Documento') {
          if (com.ENCDVF_NUMERO.toString().includes(this.busquedaCriterio)) {
            com.SNBOOLEAN = true;
          }
        }
        if (this.opcionBus2 === 'Fecha') {
          if (com.ENCDVF_FECHAEMISION.toString().includes(this.busquedaCriterio)) {
            com.SNBOOLEAN = true;
          }
        }
      });
      this.aggrid.refreshaggrid(this.calcService.resultadosDVF, this.defaultColCalcKardexDVF.objeto);
    }
    if (this.opcionSeleccionada === 'GRE') {
      this.calcService.resultadosGRE.map((com) => {
        if (this.opcionBus === 'Documento') {
          if (com.ENCGRE_NUMERO.toString().includes(this.busquedaCriterio)) {
            com.SNBOOLEAN = true;
          }
        }
        if (this.opcionBus === 'Referencia') {
          if (com.ENCGRE_CODIGO.toString().includes(this.busquedaCriterio)) {
            com.SNBOOLEAN = true;
          }
        }
        if (this.opcionBus === 'Fecha') {
          if (com.ENCGRE_FECHAEMISION.toString().includes(this.busquedaCriterio)) {
            com.SNBOOLEAN = true;
          }
        }
      });
      this.aggrid.refreshaggrid(this.calcService.resultadosGRE, this.defaultColCalcKardexGRE.objeto);
    }
    if (this.opcionSeleccionada === 'TRF') {
      this.calcService.resultadosTRF.map((com) => {
        if (this.opcionBus === 'Documento') {
          if (com.TRANSFER_NRO.toString().includes(this.busquedaCriterio)) {
            com.SNBOOLEAN = true;
          }
        }
        if (this.opcionBus === 'Referencia') {
          if (com.TRANSFER_COMPROBANTE.toString().includes(this.busquedaCriterio)) {
            com.SNBOOLEAN = true;
          }
        }
        if (this.opcionBus === 'Fecha') {
          if (com.TRANSFER_FECHATRANSFER.toString().includes(this.busquedaCriterio)) {
            com.SNBOOLEAN = true;
          }
        }
      });
      this.aggrid.refreshaggrid(this.calcService.resultadosTRF, this.defaultColCalcKardexTRF.objeto);
    }
    if (this.opcionSeleccionada === 'ENPROD' || this.opcionSeleccionada === 'SAPROD' || this.opcionSeleccionada === 'ENSAINV') {
      this.calcService.resultados.map((com) => {
        if (this.opcionBus === 'Documento') {
          if (com.KAR_NRO.toString().includes(this.busquedaCriterio)) {
            com.SNBOOLEAN = true;
          }
        }
        if (this.opcionBus === 'Referencia') {
          if (com.KAR_NROCOMPROBANTE.toString().includes(this.busquedaCriterio)) {
            com.SNBOOLEAN = true;
          }
        }
        if (this.opcionBus === 'Fecha') {
          if (com.KAR_FECHA.toString().includes(this.busquedaCriterio)) {
            com.SNBOOLEAN = true;
          }
        }
      });
      this.aggrid.refreshaggrid(this.calcService.resultados, this.defaultColCalcKardexENSAINV.objeto);
    }
  }
  
  async manejarSenales(valor) {
    if (valor !== 'Nuevo' && valor !== 'Cancelar'
      && valor !== 'Primero' && valor !== 'Anterior' && valor !== 'Siguiente' && valor !== 'Ultimo'
      && valor !== 'Buscar' && valor !== 'Imprimir' && valor !== 'AsientoVer' && valor !== 'Salir') {
      // Verifica el periodo contable
      const dia = this.fechadesde.getDate();
      const mes = this.fechadesde.getMonth();
      const anio = this.fechadesde.getFullYear();


      if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
        this.messageService.add({
          key: 'precal',
          severity: 'info',
          summary: 'Información',
          detail: 'Error, fecha debe de estar dentro del período contable'
        });
        return;
      }
    }

    if (valor === 'Visto') {
      this.confirmarRecalcularKardex();
    }
    if (valor === 'Salir') {
      this.salir();
    }
  }
  
  modificarFecha( fechaISO: string ) {
    const fecha = new Date(fechaISO);
    const anio =  fecha.getFullYear().toString();
    let mes =  (fecha.getMonth() + 1).toString();
    let dt  =  fecha.getDate().toString();
    if ( Number(dt) < 10 ) {  dt = '0' + dt; }
    if ( Number(mes) < 10 ) {  mes = '0' + mes; }
    return dt + '/' + mes + '/' + anio;
  }
  
  salir() {
    this.init.tabs.splice(this.init.tabindex, 1);
  }
  
}
