<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="uni"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarUni" title="Confirmar Acción"
                 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 99%">
  <div class="p-col-12">
    <app-invmaebarra [botonNuevo]="botonNuevo" [botonGuardar]="botonGuardar" [botonRegresar]="botonRegresar"
					 [botonBorrar]="botonBorrar"
					 (signal)="manejarSenales($event)"
                     [barraBotonesDesplaz]="barraBotones2" [barraBotones3]="barraBotones2"
                     [barraBotonesAsiCon]="barraBotones2" [barraBotones5]="true"></app-invmaebarra>
  </div>
  <div style="overflow: hidden" class="p-col-12">
  <p-tabView [(activeIndex)]="index" (activeIndexChange)="cargarTabla()">
    <p-tabPanel header="Unidades">
      <app-aggridsaci [width]=""
                      [height]="largo"
                      [enableSorting]="false"
                      [enableFilter]="true"
                      [rowData]="unidadService.maeunidades"
                      [frameworkComponents]="frameworkComponents"
                      [animateRows]="true"
                      [rowSelection]="rowSelection"
                      [columnDefs]="columnDefsUnidad"
                      [defaultColDef]="defaultColDefUnidad"
                      [rowStyle]="rowStyle"
                      [singleClickEdit]="false"
                      [botones]="false"
                      (selected)="seleccionUnidad($event)"
                      (cambios)="cambio($event)"

      ></app-aggridsaci>
    </p-tabPanel>
    <p-tabPanel header="Equivalencias">
      <div class="p-grid">
        <div class="p-col-12">
          <strong *ngIf="unidadSeleccionada !== undefined">{{unidadSeleccionada.UNI_CODIGO}} {{unidadSeleccionada.UNI_NOMBRE}}</strong>
        </div>
      </div>
      <app-aggridsaci [width]=""
                      [height]="largo"
                      [enableSorting]="false"
                      [enableFilter]="true"
                      [rowData]="unidadService.maeequivalencias"
                      [frameworkComponents]="frameworkComponents"
                      [animateRows]="true"
                      [rowSelection]="rowSelection"
                      [columnDefs]="columnDefsEquivalencia"
                      [defaultColDef]="defaultColDefEquivalencia"
                      [rowStyle]="rowStyle"
                      [singleClickEdit]="false"
                      [botones]="false"
                      (selected)="seleccionEquivalencia($event)"
                      (cambios)="cambio($event)"

      ></app-aggridsaci>
    </p-tabPanel>
  </p-tabView>
  </div>
</div>
