import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { VenEncfacZ } from '../veninterfaces/venencfacz';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { CxcEnccuota } from '../veninterfaces/cxcenccuota';
import { CxcDetcuota } from '../veninterfaces/cxcdetcuota';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { CxcenccuotaService } from '../venservicios/cxcenccuota.service';
import { CxcTrncobro } from '../../interfaces/cxctrncobro';
import { PagocontadoService } from '../venservicios/pagocontado.service';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { Usuario } from 'src/app/usuario';


@Component({
  selector: 'app-cxcenccuota',
  templateUrl: './cxcenccuota.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class CxcenccuotaComponent implements OnInit, OnChanges {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  @Input() auxiliar: any;
  @Input() auxNumFact: any;
  @Input() encfacdata: VenEncfacZ;
  @Output() cerrar = new EventEmitter<boolean>();

  strNumFact: any;
  strCliCodigo: any;
  encfac: VenEncfacZ = {};
  enccuota: CxcEnccuota;
  disnumero:boolean = false; //GSRF
  detcuotas: CxcDetcuota[];
  detcuotaSeleccionado: CxcDetcuota;

  index: number;
  largo: string;

  botonNuevo: boolean = false;
  botonGuardar: boolean = true;
  botonBorrar: boolean = true;
  botonRegresar: boolean = true;
  botonPrimero: boolean = false;
  botonAnterior: boolean = false;
  botonSiguiente: boolean = false;
  botonUltimo: boolean = false;
  oculto: boolean;

  cmbtipointeres: any[];
  cmbtipointeresaux: any[];

  strCodigoCuota = '';

  opcionbusqueda: string;
  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];
  tabla: string;

  displayDialogBusqueda: boolean;
  displayDialogBusquedaFast: boolean;

  displayDialogReportes: boolean;
  auxreporte: number = 0;
  strNombreForma: string;

  dateTimeFecha: any = '';
  lblTotalDoc: any = '';
  lblCliente: any = '';
  lblVendedor: any = '';
  lblSaldoDoc: any = '';

  consulta: string;

  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  defaultColDef;

  // Nuevo
  disabledNumDoc = false;
  disabledBtnBuscarDoc = false;
  displayDialogDocumentos = false;
  columnDefsDetcuotas = [
    { // 0
      headerName: 'F. Vence', field: 'DETCUOTA_FECHAVENCE', cellEditor: 'cellPrueba', width: 100,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: true,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Concepto', field: 'DETCUOTA_CONCEPTO', cellEditor: 'cellPrueba', width: 250,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 25,
          opcionbuscar: true
        }
      }
    },
    { // 2
      headerName: 'Referencia', field: 'DETCUOTA_REFERENCIA', cellEditor: 'cellPrueba', width: 150,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 200
        }
      }
    },
    { // 3
      headerName: 'Importe', field: 'DETCUOTA_IMPORTE', cellEditor: 'cellPrueba', width: 100,
      onCellValueChanged: () => {
        this.cambio1();
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tienecalculos: true,
          tamanocelda: 10,
        
        }
      }
    },
    { // 4
      headerName: 'Pagado', field: 'DETCUOTA_PAGADO', cellEditor: 'cellPrueba', width: 100,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: true,
          tienecalculos: true
        }
      }
    },
    { // 5
      headerName: 'Saldo', field: 'DETCUOTA_SALDO', cellEditor: 'cellPrueba', width: 100,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: true,
          tienecalculos: true
        }
      }
    }];


  constructor(private cuotaService: CxcenccuotaService, private messageService: MessageService,
    private datePipe: DatePipe, private confIniciales: ConfInicialesService,
    private pagconservice: PagocontadoService, private busqService: InvbusquedaService,
    private errorService: ErroresBaseDatosService, private confirmationService: ConfirmationService,
    private init: NuevoComponentService, public usuario: Usuario) {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.rowStyle = (params) => {
      if (params.data.DETCUOTA_PAGADO > 0) {
        return { background: 'rgb(255, 255, 170)' };
      }
    };

    this.defaultColDef = {
      editable: true,
      width: 100,
      objeto: 'detcuota'
    };
  }

  ngOnInit() {
    this.disnumero = false; //GSRF
    this.largo = '-3000';
    this.oculto = true;
    this.consulta = '';
    this.llenarCombos();
    this.llenarcmbtipointeres();
    this.nuevoenccuota();
    this.detcuotas = [];    
  }
 //CDPJ  
 ngOnDestroy(): void {
  if(this.auxNumFact === '' || this.auxNumFact === undefined || this.auxNumFact === null){
    this.confIniciales.instanciasCuotasCli--;
  }
}
//CDPJ
  ngOnChanges(changes: SimpleChanges): void {
    for (const e in changes) {
      if (e === 'auxiliar' && this.encfacdata !== null) {
        this.encfac = this.encfacdata;
        this.largo = '-3000';
        this.nuevoenccuota();
        this.detcuotas = [];
        this.cargarDatosFactura();
      }
    }
  }

  nuevoenccuota() {
    this.enccuota = {
      ENCFAC_NUMERO: '',
      COM_CODIGO: '01',
      CLI_CODIGO: '',
      ENCCUOTA_FECHAINICIO: new Date(),
      ENCCUOTA_TOTAL: '',
      ENCCUOTA_INTERESVAL: '0.00',
      ENCCUOTA_INTERESPORCE: '0.00',
      ENCCUOTA_FINANCIADO: '',
      ENCCUOTA_NROCUOTAS: '',
      ENCCUOTA_VENCECDIA: '',
      ENCCUOTA_ANIO: '',
      ENCCUOTA_ABONO: '',
      ENCCUOTA_SUBTOTAL: '',
      ENCFAC_TOTAL: '',
      ENCCUOTA_INTERESTIPO: 'PORCE',
      ENCCUOTA_FLAG: '',
      ENCCUOTA_DOC: '',
      ENCCUOTA_CODIGO: '',
      ENCCUOTA_INTERESANUAL: '0.00',
      ENCCUOTA_INTERESDIS: '0.00',
      ENCCUOTA_INTERESEDIT: '0.00',
      CON_NOMBRE: '',
      CON_CODIGO: ''
    };
    this.strCodigoCuota='';//CDPJ
    if (this.encfacdata === null || this.encfacdata === undefined || this.encfacdata == ''){
      
    }
    
  }

  nuevodetcuota() {
    const strNumDoc = this.strNumFact;
    const item: CxcDetcuota = {};

    const strFechaCuotas = this.enccuota.ENCCUOTA_FECHAINICIO;
    item.CLAVE = this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
    item.CLI_CODIGO = this.strCliCodigo;
    item.COM_CODIGO = '01';
    item.DETCUOTA_COBRADA = 'N';
    item.DETCUOTA_CONCEPTO = '';
    item.DETCUOTA_FECHAVENCE = this.datePipe.transform(strFechaCuotas, 'dd/MM/yyyy');
    item.DETCUOTA_IMPORTE = (0).toFixed(this.confIniciales.getNumDecSist());
    item.DETCUOTA_NROLINEA = String(0);
    item.DETCUOTA_PAGADO = 0;
    item.DETCUOTA_REFERENCIA = '';
    item.DETCUOTA_SALDO = (0).toFixed(this.confIniciales.getNumDecSist());
    item.ENCCUOTA_ANIO = '';
    item.ENCCUOTA_NROCUOTAS = 0;
    item.ENCFAC_NUMERO = strNumDoc;

    this.detcuotas.push(item);
    this.aggrid.refreshaggrid(this.detcuotas, this.defaultColDef.objeto);

  }

  eliminarDetCuota() {
    this.detcuotas.splice(this.detcuotas.findIndex(
      cuo => cuo.CLAVE === this.detcuotaSeleccionado.CLAVE),
      1);
    this.aggrid.refreshaggrid(this.detcuotas, this.defaultColDef.objeto);
  }

  cargarDatosFactura() {
    if (this.encfac === undefined) {
      this.encfac = {};
    } else {
      this.dateTimeFecha = new Date(this.encfac.ENCFAC_FECHAEMISION);
      this.strNumFact = this.encfac.ENCFAC_NUMERO;
      this.lblCliente = '';
      this.lblVendedor = '';
      this.lblSaldoDoc = '';
      this.botonesmodificar();
      this.botonPrimero = true;
      this.botonAnterior = true;
      this.botonSiguiente = true;
      this.botonUltimo = true;
      this.completarDatosDoc(this.strNumFact);
    }
    setTimeout(() => {
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
    }, 2000);
  }

  async completarDatosDoc(strNumDoc) {
    this.pagconservice.consultarTrnCobro('T', 'TRNCOBRO_NRODOC = \'' + strNumDoc + '\' AND C.COM_CODIGO = \'01\'', '').subscribe(canc => {
      if (canc != null) {
        if (canc[0] !== undefined) {
          this.lblTotalDoc = canc[0].TRNCOBRO_IMPORTE.toFixed(this.confIniciales.getNumDecSist());
          this.dateTimeFecha = new Date(canc[0].TRNCOBRO_FECHATRN);
          this.cuotaService.erClinombre(canc[0].CLI_CODIGO).subscribe(reg => {
              if (reg != null) {
                if (reg[0] !== undefined) {
                  this.lblCliente = canc[0].CLI_CODIGO + ' - ' + reg[0].CLI_NOMBRE;
                }
              } else {
                this.lblCliente = canc[0].CLI_CODIGO;
              }
            });
          this.lblVendedor = canc[0].VEN_CODIGO;
          this.strCliCodigo = canc[0].CLI_CODIGO;
          this.pagconservice.obtenerSaldoFactura(this.strNumFact).subscribe(saldo => {
            if (saldo != null) {
              if (saldo[0] !== undefined) {
                this.lblSaldoDoc = saldo[0].SALDO.toFixed(this.confIniciales.getNumDecSist());
                this.enccuota.ENCCUOTA_FINANCIADO = saldo[0].SALDO.toFixed(this.confIniciales.getNumDecSist());
              }
            }
          });
        } else {
          return;
        }
      } else {
        return;
      }
    });
  }

  async manejarSenales(valor) {

    if (valor === 'Nuevo') {
      this.disnumero = false; //GSRF
      this.botonesmodificar();
      this.limpiardatos();  
      document.getElementById('divPrincipal').style.pointerEvents = 'all';//CDPJ
    }

    if (valor === 'Guardar') {
      this.guardarRegistro();
    }

    if (valor === 'Borrar') {
      await this.navegarDocumento(this.strNumFact);
     await this.eliminarDocumento();
    }

    if (valor === 'Cancelar') {
      if (this.strNumFact !== undefined && this.strNumFact !== null) {
        if (this.strNumFact.length > 0) {
          await this.navegarDocumento(this.strNumFact);
        }
      }
    }

    if (valor === 'Generar') {
      this.generarCuotas();
      return;
    }

    if (valor === 'Buscar') {
      this.busquedaenccuota();
    }

    if (valor === 'facturasAbonadas') {
      this.busquedaenccuota();
    }

    if (valor === 'Primero') {
      this.seleccionarRegistro('P');
    }
    if (valor === 'Anterior') {
      this.seleccionarRegistro('A');
    }
    if (valor === 'Siguiente') {
      this.seleccionarRegistro('S');
    }
    if (valor === 'Ultimo') {
      this.seleccionarRegistro('U');
    }
    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }
    if (valor === 'Almacenardoc') {
      if (this.strNumFact !== '') {
        const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.messageService.add({
            key: 'cuotas',
            severity: 'info',
            summary: 'Información',
            detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
          });
        }
        
      }
    }
    if (valor === 'Salir') {
      if (this.auxNumFact === undefined) {
        this.init.tabs.splice(this.init.tabindex, 1);
      } else {
        this.cerrar.emit(false);
      }
    }
  }

  setFocus(event) {
    let element: any;
    switch (event.target.id) {
      case 'ENCCUOTA_INTERESANUAL': {
        element = document.getElementById('ENCCUOTA_NROCUOTAS');
        break;
      }
      case 'ENCCUOTA_NROCUOTAS': {
        this.calculaPorcentajeInteres();
        element = document.getElementById('ENCCUOTA_VENCECDIA');
        break;
      }
      case 'ENCCUOTA_VENCECDIA': {
        this.calcularFechaIniCobros();
        element = document.getElementById('ENCCUOTA_INTERESEDIT');
        break;
      }
      case 'ENCCUOTA_INTERESEDIT': {
        this.calcularInteres();
        element = document.getElementById('ENCCUOTA_FECHAINICIO');
        break;
      }
      case 'ENCCUOTA_FECHAINICIO': {
        element = document.getElementById('ENCCUOTA_INTERESANUAL');
        break;
      }
    }
    if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }
  }

  botonesmodificar() {
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = false;
  }

  botonesseleccionar() {
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = false;
  }

  llenarCombos() {
    this.cmbtipointeresaux = [{ codigo: 'PORCE', name: 'Porcentaje' },
    { codigo: 'VAL', name: 'Valor' }];
  }

  llenarcmbtipointeres() {
    this.cmbtipointeres = this.cmbtipointeresaux;
  }

  busqtipointeres(ti) {
    this.enccuota.ENCCUOTA_INTERESTIPO = ti.value.codigo;

  }

  selecciondetcuotas(valor) {

    if (valor === 'nuevo') {
      this.nuevodetcuota();
    }

    if (valor === 'eliminar') {
      this.eliminarDetCuota();
    }

    if (valor === 'cancelar') {

    }
    if (valor.object !== undefined) {
      this.detcuotaSeleccionado = valor.object;
    }
  }

  cambio($event) {

  }

  calcularInteres() {
    const dblTotalDoc = Number(this.lblTotalDoc);
    let dblInteres;
    let dblTotalFinanciado;
    if (dblTotalDoc === 0) {
      this.messageService.add({
        key: 'cuotas',
        severity: 'warn',
        summary: 'Información',
        detail: 'No se ha seleccionado ningún documento.'
      });
    }

    if (this.enccuota.ENCCUOTA_INTERESTIPO === 'PORCE') { // porcentaje
      if (this.enccuota.ENCCUOTA_INTERESEDIT.length > 0) {
        const dblPorcInteres = Number(this.enccuota.ENCCUOTA_INTERESEDIT);
        dblInteres = (dblPorcInteres / 100) * dblTotalDoc;
        dblTotalFinanciado = dblTotalDoc + dblInteres;
      }
    } else {
      dblInteres = (Number(this.enccuota.ENCCUOTA_INTERESEDIT) / dblTotalDoc) * 100;
      dblTotalFinanciado = dblTotalDoc + Number(this.enccuota.ENCCUOTA_INTERESEDIT);
    }

    this.enccuota.ENCCUOTA_INTERESDIS = dblInteres.toFixed(this.confIniciales.getNumDecSist());

    if (dblInteres > 0) {
      this.enccuota.ENCCUOTA_FINANCIADO = dblTotalFinanciado.toFixed(this.confIniciales.getNumDecSist());
    }
  }

  calcularFechaIniCobros() {
    const dateTimeIniCobros: Date = this.enccuota.ENCCUOTA_FECHAINICIO;
    this.enccuota.ENCCUOTA_FECHAINICIO = this.obtenerFechaVence(dateTimeIniCobros.getDate(),
      dateTimeIniCobros.getMonth(), dateTimeIniCobros.getFullYear());
  }

  obtenerFechaVence(dia, mes, anio): Date {
    let fecha1: Date;
    fecha1 = new Date(anio, mes, dia);

    fecha1.setDate(dia + (this.enccuota.ENCCUOTA_VENCECDIA === '' ? 0 : Number(this.enccuota.ENCCUOTA_VENCECDIA)));

    return fecha1;
  }

  calculaPorcentajeInteres() {
    let dblInteresAnual = 0;
    let dblNumeroMeses = 0;
    let dblInteresXNumMeses = 0;
    // Calcula el porcentaje de interés de acuerdo al interés anual y al número de meses

    dblInteresAnual = Number(this.enccuota.ENCCUOTA_INTERESANUAL);
    if (this.enccuota.ENCCUOTA_NROCUOTAS.length > 0) {
      dblNumeroMeses = Number(this.enccuota.ENCCUOTA_NROCUOTAS);
    } else {
      dblNumeroMeses = 0;
    }

    if (dblInteresAnual !== 0 && dblNumeroMeses !== 0) {
      dblInteresXNumMeses = (dblNumeroMeses * dblInteresAnual) / 12;
    }

    if (dblInteresXNumMeses !== 0) {
      this.enccuota.ENCCUOTA_INTERESEDIT = dblInteresXNumMeses.toFixed(this.confIniciales.getNumDecSist());
    }


  }

  generarCuotas() {
    if (this.faltaInformacion()) {
      return;
    }

    this.calcularInteres();

    this.generarTablaCuotas();

    this.calcularTotalCuotas();
    /*
        activarBotMod();
        
        shell.setCursor(getParent().getCursor());
      */
  }

  faltaInformacion(): boolean {
    let bolFalta = false;
    let fechainicio=new Date()//CDPJ
    let fechadoc=new Date()//CDPJ
    // documento
    if (this.strNumFact.length === 0) {
      this.messageService.add({
        key: 'cuotas',
        severity: 'warn',
        summary: 'Información',
        detail: 'No se ha seleccionado ningún documento.'
      });
      return bolFalta = true;
    }

    // número de cuotas
    if (this.enccuota.ENCCUOTA_NROCUOTAS.length === 0) {
      this.enccuota.ENCCUOTA_NROCUOTAS = 0;
    }


    if (Number(this.enccuota.ENCCUOTA_NROCUOTAS) === 0) {
      this.messageService.add({
        key: 'cuotas',
        severity: 'warn',
        summary: 'Información',
        detail: 'Ingrese el número de cuotas.'
      });
      return bolFalta = true;

    }

    // días de vencimiento de cada cuota
    if (this.enccuota.ENCCUOTA_VENCECDIA.length === 0) {
      this.enccuota.ENCCUOTA_VENCECDIA = 0;
    }

    if (Number(this.enccuota.ENCCUOTA_VENCECDIA) === 0) {
      this.messageService.add({
        key: 'cuotas',
        severity: 'warn',
        summary: 'Información',
        detail: 'El número de días de plazo para cada cuota debe ser mayor a cero.'
      });
      return bolFalta = true;
    }

    // if (this.enccuota.ENCCUOTA_FECHAINICIO < this.encfac.ENCFAC_FECHAEMISION) {
    //   this.messageService.add({
    //     key: 'cuotas',
    //     severity: 'warn',
    //     summary: 'Información',
    //     detail: 'La fecha para el inicio de los cobros no puede ser menor' +
    //       ' a la fecha del documento'
    //   });

    //   return bolFalta = true;
    // }
    //CDPJ
    console.log(this.datePipe.transform(this.enccuota.ENCCUOTA_FECHAINICIO,'dd/MM/yyyy'))
    console.log(this.datePipe.transform(this.dateTimeFecha,'dd/MM/yyyy'))
    fechainicio=new Date (this.enccuota.ENCCUOTA_FECHAINICIO);
    fechadoc=new Date (this.dateTimeFecha)
    fechainicio.setHours(0,0,0,0)
    fechadoc.setHours(0,0,0,0);
    if (fechainicio.getTime() < fechadoc.getTime()) {
      this.messageService.add({
        key: 'cuotas',
        severity: 'warn',
        summary: 'Información',
        detail: 'La fecha para el inicio de los cobros no puede ser menor' +
          ' a la fecha del documento'
      });

      return bolFalta = true;
    }
    //CDPJ

    return bolFalta;
  }

  generarTablaCuotas() {
    const strNumDoc = this.strNumFact;
    const intNroCuotas = Number(this.enccuota.ENCCUOTA_NROCUOTAS);
    let dblValorCuota = Number(this.enccuota.ENCCUOTA_FINANCIADO) / intNroCuotas;
    let dblTotalCuotas = 0;
    let dblDifCuotas = 0;
    let strFechaCuotas = this.enccuota.ENCCUOTA_FECHAINICIO;
    let strNumero = '';

    const listaCuotas: CxcDetcuota[] = [];

    for (let cuota = 1; cuota <= intNroCuotas; cuota++) {
      const item: CxcDetcuota = {};

      if (String(cuota).length === 1) {
        strNumero = '0' + cuota;
      } else {
        strNumero = String(cuota);
      }

      dblValorCuota = Number(dblValorCuota.toFixed(this.confIniciales.getNumDecSist()));

      item.CLI_CODIGO = this.strCliCodigo;
      item.COM_CODIGO = '01';
      item.DETCUOTA_COBRADA = 'N';
      item.DETCUOTA_CONCEPTO = 'CUOTA ' + strNumero + '/' + intNroCuotas + ' FAC. ' + strNumDoc;
      item.DETCUOTA_FECHAVENCE = this.datePipe.transform(strFechaCuotas, 'dd/MM/yyyy');
      item.DETCUOTA_IMPORTE = dblValorCuota.toFixed(this.confIniciales.getNumDecSist());
      item.DETCUOTA_NROLINEA = String(cuota);
      item.DETCUOTA_PAGADO = 0;
      item.DETCUOTA_REFERENCIA = '';
      item.DETCUOTA_SALDO = dblValorCuota.toFixed(this.confIniciales.getNumDecSist());
      item.ENCCUOTA_ANIO = '';
      item.ENCCUOTA_NROCUOTAS = intNroCuotas;
      item.ENCFAC_NUMERO = strNumDoc;

      // totalizar el valor de las cuotas
      dblTotalCuotas = dblTotalCuotas + dblValorCuota;

      strFechaCuotas = this.obtenerFechaVence(strFechaCuotas.getDate(), strFechaCuotas.getMonth(),
        strFechaCuotas.getFullYear());

      listaCuotas.push(item);
    }


    // verificar que el total de las cuotas sea igual al total financiado
    console.log('/**/', Number(this.enccuota.ENCCUOTA_FINANCIADO))//GSRF
    console.log('/**/', dblTotalCuotas)//GSRF
    if (dblTotalCuotas > Number(this.enccuota.ENCCUOTA_FINANCIADO)) {
      dblDifCuotas = dblTotalCuotas - Number(this.enccuota.ENCCUOTA_FINANCIADO);
      dblValorCuota = dblValorCuota - dblDifCuotas;
    } else if (dblTotalCuotas < Number(this.enccuota.ENCCUOTA_FINANCIADO)) {
      dblDifCuotas = Number(this.enccuota.ENCCUOTA_FINANCIADO) - dblTotalCuotas;
      dblValorCuota = dblValorCuota + dblDifCuotas;
    }
    console.log('/**/', dblValorCuota)//GSRF


     listaCuotas[listaCuotas.length - 1].DETCUOTA_IMPORTE = dblValorCuota.toFixed(this.confIniciales.getNumDecSist());//GSRF
     listaCuotas[listaCuotas.length - 1].DETCUOTA_SALDO = dblValorCuota.toFixed(this.confIniciales.getNumDecSist());//GSRF

    this.detcuotas = listaCuotas;

  }

  calcularTotalCuotas() {
    let dblTotalCuotas = 0;

    if (this.detcuotas !== undefined) {
      for (let fila = 1; fila <= this.detcuotas.length; fila++) {
        dblTotalCuotas = dblTotalCuotas + Number(this.detcuotas[fila - 1].DETCUOTA_IMPORTE);
      }
    }

    this.enccuota.ENCCUOTA_TOTAL = dblTotalCuotas.toFixed(this.confIniciales.getNumDecSist());

  }

  guardarRegistro() {
    if (this.faltaInformacion()) {
      return;
    }

    if (this.enccuota.ENCCUOTA_INTERESDIS !== '' && Number(this.enccuota.ENCCUOTA_INTERESDIS) > 0) {
      if (this.enccuota.CON_NOMBRE === '') {
        this.messageService.add({
          key: 'cuotas',
          severity: 'warn',
          summary: 'Cuenta Contable',
          detail: 'La cuenta contable esta incorrecta'
        });
        return;
      }

    }

    this.calcularTotalCuotas();

    if (this.enccuota.ENCCUOTA_FINANCIADO !== this.enccuota.ENCCUOTA_TOTAL) {
      this.messageService.add({
        key: 'cuotas',
        severity: 'warn',
        summary: 'Información',
        detail: 'El total financiado es distinto al total de' +
          ' las cuotas.'
      });
      return;
    }

    if (this.detcuotas !== undefined) {
      if (this.detcuotas.length === 0) {
        this.messageService.add({
          key: 'cuotas',
          severity: 'warn',
          summary: 'Información',
          detail: 'Genere la tabla de cuotas antes de guardar el documento.'
        });
        return;
      }
    } else {
      this.messageService.add({
        key: 'cuotas',
        severity: 'warn',
        summary: 'Información',
        detail: 'Genere la tabla de cuotas antes de guardar el documento.'
      });
      return;
    }

    this.enccuota.ENCCUOTA_NROCUOTAS = this.detcuotas.length;

    const item: CxcEnccuota = {};
    item.ENCFAC_NUMERO = this.strNumFact;
    item.COM_CODIGO = this.enccuota.COM_CODIGO;
    item.CLI_CODIGO = this.strCliCodigo;
    item.ENCCUOTA_FECHAINICIO = this.datePipe.transform(this.enccuota.ENCCUOTA_FECHAINICIO, 'dd/MM/yyyy');
    item.ENCCUOTA_FINANCIADO = Number(this.enccuota.ENCCUOTA_FINANCIADO);

    if (this.enccuota.ENCCUOTA_INTERESTIPO === 'PORCE') { // porcentaje
      item.ENCCUOTA_INTERESVAL = Number(this.enccuota.ENCCUOTA_INTERESDIS);
    } else {  // valor
      item.ENCCUOTA_INTERESVAL = Number(this.enccuota.ENCCUOTA_INTERESEDIT);
    }

    if (this.enccuota.ENCCUOTA_INTERESTIPO === 'VAL') { // valor
      item.ENCCUOTA_INTERESPORCE = Number(this.enccuota.ENCCUOTA_INTERESDIS);
    } else {  // porcentaje
      item.ENCCUOTA_INTERESPORCE = Number(this.enccuota.ENCCUOTA_INTERESEDIT);
    }

    item.ENCCUOTA_NROCUOTAS = this.enccuota.ENCCUOTA_NROCUOTAS;
    item.ENCCUOTA_VENCECDIA = this.enccuota.ENCCUOTA_VENCECDIA;
    item.ENCCUOTA_ANIO = this.enccuota.ENCCUOTA_FECHAINICIO.getFullYear();
    item.ENCCUOTA_ABONO = '0';
    item.ENCCUOTA_SUBTOTAL = Number(this.lblSaldoDoc);
    item.ENCCUOTA_TOTAL = Number(this.lblTotalDoc);
    item.ENCCUOTA_INTERESTIPO = this.enccuota.ENCCUOTA_INTERESTIPO;
    item.ENCCUOTA_DOC = '0';
    item.ENCCUOTA_CODIGO = this.strCodigoCuota;
    item.ENCCUOTA_INTERESANUAL = Number(this.enccuota.ENCCUOTA_INTERESANUAL);
    item.CON_CODIGO = String(this.enccuota.CON_CODIGO).trim();

    this.cuotaService.insertarEncabezadoDoc(item).subscribe(res => {
      if (this.strCodigoCuota.length > 0) {
        this.cuotaService.eliminarDetalleDoc(item).subscribe(eli => {
          this.eliminarFactCuotas(this.strNumFact);

        });
      }

      for (const rs of res) {
        this.strCodigoCuota = rs.ENCCUOTA_CODIGO;

        for (let fila = 1; fila <= this.detcuotas.length; fila++) {
          const itemdet: CxcDetcuota = this.detcuotas[fila - 1];

          itemdet.ENCCUOTA_ANIO = this.enccuota.ENCCUOTA_FECHAINICIO.getFullYear();
          itemdet.DETCUOTA_IMPORTE = Number(itemdet.DETCUOTA_IMPORTE);
          itemdet.DETCUOTA_PAGADO = Number(itemdet.DETCUOTA_PAGADO);
          itemdet.DETCUOTA_SALDO = Number(itemdet.DETCUOTA_SALDO);
          itemdet.ENCCUOTA_CODIGO = this.strCodigoCuota;

          if (itemdet.DETCUOTA_REFERENCIA === '') {
            itemdet.DETCUOTA_REFERENCIA = '*';
          }

          this.cuotaService.insertarDetalleDoc(itemdet).subscribe(resdet => {
            const itemcobro: CxcTrncobro = {};
            const strFechaInicia = this.datePipe.transform(this.enccuota.ENCCUOTA_FECHAINICIO, 'dd/MM/yyyy');
            let strNumero = '';

            if (itemdet.DETCUOTA_NROLINEA.length === 1) {
              strNumero = '0' + itemdet.DETCUOTA_NROLINEA;
            } else {
              strNumero = itemdet.DETCUOTA_NROLINEA;
            }

            itemcobro.APLORG_CODIGO = 'SISTEMA';
            itemcobro.ASI_NRO = '';
            itemcobro.CLI_CODIGO = itemdet.CLI_CODIGO;
            itemcobro.COM_CODIGO = '01';
            itemcobro.TRNCOBRO_CONCEPTO = itemdet.DETCUOTA_CONCEPTO;
            itemcobro.TRNCOBRO_FECHATRN = strFechaInicia;
            itemcobro.TRNCOBRO_FECHAVENCE = itemdet.DETCUOTA_FECHAVENCE;
            itemcobro.TRNCOBRO_FLAG = 1;
            itemcobro.TRNCOBRO_NRODOC = itemdet.ENCFAC_NUMERO + '-' + strNumero + '/' + itemdet.ENCCUOTA_NROCUOTAS;
            itemcobro.TRNCOBRO_NROCOMPROBANTE = itemdet.ENCFAC_NUMERO + '-' + strNumero + '/' + itemdet.ENCCUOTA_NROCUOTAS;
            itemcobro.TRNCOBRO_TIPODOC = 'FC';
            itemcobro.USU_IDENTIFICACION = '';
            itemcobro.VEN_CODIGO = this.lblVendedor;
            itemcobro.TRNCOBRO_SALDOINI = 0;
            itemcobro.TRNCOBRO_IMPORTE = itemdet.DETCUOTA_IMPORTE;
            itemcobro.TRNCOBRO_REFERENCIAEXTERNA = this.strCodigoCuota;
            itemcobro.BAN_CODIGO = '';
            itemcobro.TRNCOBRO_EFECTIVO = '';
            itemcobro.TRNCOBRO_CAMBIO = '';
            itemcobro.CON_CODIGO = '';
            itemcobro.BOD_CODIGO = '';

            this.pagconservice.insertarTrnCobro(itemcobro).subscribe(ins => {
              for (const i of ins) {
                this.cuotaService.actualizarNroFactura(i.TRNCOBRO_NRODOC, itemdet).subscribe();
                this.botonesseleccionar();
              }
            });
          });
        }

        // INSERTAR LA CANCELACION DE LA FACTURA ORIGINAL

        const itemCA: CxcTrncobro = {};

        const strFechaInicia2 = this.datePipe.transform(this.enccuota.ENCCUOTA_FECHAINICIO, 'dd/MM/yyyy');


        itemCA.APLORG_CODIGO = 'SISTEMA';
        itemCA.ASI_NRO = '';
        itemCA.BAN_CODIGO = '';
        itemCA.CLI_CODIGO = item.CLI_CODIGO;
        itemCA.COM_CODIGO = '01';
        itemCA.TRNCOBRO_CONCEPTO = 'CANCELACION POR CUOTAS FACT. ' + item.ENCFAC_NUMERO;
        itemCA.TRNCOBRO_FECHATRN = strFechaInicia2;
        itemCA.TRNCOBRO_FECHAVENCE = '';
        itemCA.TRNCOBRO_FLAG = 1;
        itemCA.TRNCOBRO_IMPORTE = Number(this.lblTotalDoc);
        itemCA.TRNCOBRO_NROCOMPROBANTE = '';
        itemCA.TRNCOBRO_NRODOC = item.ENCFAC_NUMERO;
        itemCA.TRNCOBRO_REFERENCIAEXTERNA = this.strCodigoCuota;
        itemCA.VEN_CODIGO = this.lblVendedor;
        itemCA.CUOTAFAC = 'F';
        itemCA.TRNCOBRO_SALDOINI = 0;
        itemCA.TRNCOBRO_EFECTIVO = 0;
        itemCA.TRNCOBRO_CAMBIO = 0;
        itemCA.CON_CODIGO = '';
        itemCA.BOD_CODIGO = '';

        // si el total del documento es distinto al saldo del documento, es un abono caso contrario es una cancelacion
        if (this.lblTotalDoc === this.enccuota.ENCCUOTA_TOTAL) {
          itemCA.TRNCOBRO_TIPODOC = 'AB';
        } else {
          itemCA.TRNCOBRO_TIPODOC = 'CA';
        }

        this.pagconservice.insertarTrnCobro(itemCA).subscribe(ins => {
          for (const i of ins) {
            itemCA.TRNCOBRO_NRODOC = this.strNumFact;
            this.pagconservice.insertarTrnDocumento(i.TRNCOBRO_NRODOC, i.TRNCOBRO_NROCOMPROBANTE, itemCA).subscribe(insdoc => {
              this.pagconservice.actualizaEstadoFactura(this.strNumFact, this.strCliCodigo).subscribe(act => {
                this.cerrar.emit(true);
              });
            });
          }
        });

        this.messageService.add({
          key: 'cuotas',
          severity: 'success',
          summary: 'Información',
          detail: 'Datos guardados correctamente'
        });
        //GSRF
        if (this.disnumero === false){
          this.cuotaService.registrarAuditoria('CXC_ENCCUOTA', this.strNumFact, 'I',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });
        }else if (this.disnumero === true){
          this.cuotaService.registrarAuditoria('CXC_ENCCUOTA', this.strNumFact, 'A',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });
        }
        if (this.strNumFact !== undefined && this.strNumFact !== null) {
          if (this.strNumFact.length > 0) {
            this.navegarDocumento(this.strNumFact);
          }
        }
          
        //GSRF

      }
    });
  }
  cambio1() {
    for(const item of this.detcuotas){
      if(item.DETCUOTA_SALDO !== item.DETCUOTA_IMPORTE){
        item.DETCUOTA_SALDO = Number(item.DETCUOTA_IMPORTE).toFixed(this.confIniciales.getNumDecSist());
        item.DETCUOTA_IMPORTE = Number(item.DETCUOTA_IMPORTE).toFixed(this.confIniciales.getNumDecSist());
      }
    }
    this.aggrid.refreshaggrid(this.detcuotas, this.defaultColDef.objeto);
    this.calcularTotalCuotas();
  }
  eliminarFactCuotas(strTrnCobroNroDoc) {

    this.cuotaService.eliminarNDATrnResumen(strTrnCobroNroDoc).subscribe(eli => {

    });
    this.cuotaService.eliminarTrnCobro(strTrnCobroNroDoc).subscribe(eli => {

    });

  }

  busquedaenccuota() {
    this.opcionbusqueda = 'enccuota';
    this.types = [
      { label: 'NRO.DOCUMENTO', value: 'ENCFAC_NUMERO' },
      { label: 'COD.CLIENTE', value: 'CLI_CODIGO' },
      { label: 'NOMBRE CLIENTE', value: 'CLI_NOMBRE' },
      { label: 'FECHA INICIO', value: 'ENCCUOTA_FECHAINICIO' },
      { label: 'AÑO', value: 'ENCCUOTA_ANIO' },
      { label: 'NRO. CUOTAS', value: 'ENCCUOTA_NROCUOTAS' },
      { label: 'DIAS VENC.', value: 'ENCCUOTA_VENCECDIA' },
      { label: 'TOTAL DOC.', value: 'ENCFAC_TOTAL' },
      { label: 'SUBTOTAL', value: 'ENCCUOTA_SUBTOTAL' },
      { label: 'TOTAL', value: 'ENCCUOTA_TOTAL' },
      { label: 'VALOR INTERES', value: 'ENCCUOTA_INTERESVAL' },
      { label: '% INTERES', value: 'ENCCUOTA_INTERESPORCE' },
      { label: 'FINANCIADO', value: 'ENCCUOTA_FINANCIADO' },
      { label: 'TIPO INTERES', value: 'ENCCUOTA_INTERESTIPO' },
      { label: 'ENCCUOTA_DOC', value: 'ENCCUOTA_DOC' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
    this.tabla = 'CXC_ENCCUOTA';
    this.consulta = 'ENCFAC_NUMERO, CLI_CODIGO, VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO,\'01\') AS CLI_NOMBRE, ENCCUOTA_FECHAINICIO, ' +
      'ENCCUOTA_ANIO, ENCCUOTA_NROCUOTAS, ENCCUOTA_VENCECDIA, ENCFAC_TOTAL, ENCCUOTA_SUBTOTAL, ENCCUOTA_TOTAL, ENCCUOTA_INTERESVAL, ' +
      'ENCCUOTA_INTERESPORCE, ENCCUOTA_FINANCIADO, ENCCUOTA_INTERESTIPO, ENCCUOTA_DOC';
    this.busqService.busquedaCXCEncCuota1().subscribe((datos: any[]) => {
        this.arregloCons = datos;
        this.arregloCons.map((registro) => {
          if (registro.ENCCUOTA_FECHAINICIO !== null) {
            registro.ENCCUOTA_FECHAINICIO = this.datePipe.transform(registro.ENCCUOTA_FECHAINICIO, 'dd/MM/yyyy');
          } else {
            registro.ENCCUOTA_FECHAINICIO = '';
          }
        });
        this.displayDialogBusquedaFast = true;
      });
  }

  manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'enccuota') {
      this.navegarDocumento(opcion.ENCFAC_NUMERO);
    }

    if (this.opcionbusqueda === 'docpend') {
      this.strNumFact = opcion.TRNCOBRO_NRODOC;
      this.completarDatosDoc(this.strNumFact);
    }

    if (this.opcionbusqueda === 'BuscarCuentaCont') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cuotas',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
        this.enccuota.CON_NOMBRE = '';
        this.enccuota.CON_CODIGO = '';
      } else {
        this.enccuota.CON_NOMBRE = opcion.CON_NOMBRE;
        this.enccuota.CON_CODIGO = opcion.CON_CODIGO;
      }

      this.botonesmodificar();
    }


    this.displayDialogBusqueda = false;
    this.displayDialogBusquedaFast = false;
  }

  async navegarDocumento(encfaccodigo) { 
    this.disnumero = true; //GSRF   
    this.strNumFact = encfaccodigo;
    const strNumDoc = this.strNumFact;
    this.pagconservice.consultarTrnCobro('T',
      'TRNCOBRO_NRODOC = \'' + strNumDoc
      + '\' AND C.COM_CODIGO = \'01\'', '').subscribe(canc => {
        if (canc != null) {
          if (canc[0] !== undefined) {
            this.dateTimeFecha = new Date(canc[0].TRNCOBRO_FECHATRN);
            this.cuotaService.erClinombre(canc[0].CLI_CODIGO).subscribe(reg => {
                if (reg != null) {
                  if (reg[0] !== undefined) {
                    this.lblCliente = canc[0].CLI_CODIGO + ' - ' + reg[0].CLI_NOMBRE;
                  }
                } else {
                  this.lblCliente = canc[0].CLI_CODIGO;
                }
              });
            this.strCliCodigo = canc[0].CLI_CODIGO;
            this.lblVendedor = canc[0].VEN_CODIGO;
          } else {
            return;
          }
        } else {
          return;
        }
      });
    this.cuotaService.consultarEncabezadoCuotas(encfaccodigo).subscribe(data => {
      for (const rs of data) {
        this.enccuota.ENCFAC_NUMERO = rs.ENCFAC_NUMERO;
        this.enccuota.COM_CODIGO = rs.COM_CODIGO;
        this.enccuota.CLI_CODIGO = rs.CLI_CODIGO;
        this.enccuota.ENCCUOTA_FECHAINICIO = new Date(rs.ENCCUOTA_FECHAINICIO);
        this.enccuota.ENCCUOTA_TOTAL = rs.ENCCUOTA_TOTAL;
        this.enccuota.ENCCUOTA_INTERESVAL = rs.ENCCUOTA_INTERESVAL;
        this.enccuota.ENCCUOTA_INTERESPORCE = rs.ENCCUOTA_INTERESPORCE;
        this.enccuota.ENCCUOTA_FINANCIADO = rs.ENCCUOTA_FINANCIADO;
        this.enccuota.ENCCUOTA_NROCUOTAS = rs.ENCCUOTA_NROCUOTAS;
        this.enccuota.ENCCUOTA_VENCECDIA = rs.ENCCUOTA_VENCECDIA;
        this.enccuota.ENCCUOTA_ANIO = rs.ENCCUOTA_ANIO;
        this.enccuota.ENCCUOTA_ABONO = rs.ENCCUOTA_ABONO;
        this.enccuota.ENCCUOTA_SUBTOTAL = rs.ENCCUOTA_SUBTOTAL;
        this.enccuota.ENCFAC_TOTAL = rs.ENCFAC_TOTAL;
        this.enccuota.ENCCUOTA_INTERESTIPO = rs.ENCCUOTA_INTERESTIPO;
        this.enccuota.ENCCUOTA_FLAG = rs.ENCCUOTA_FLAG;
        this.enccuota.ENCCUOTA_DOC = rs.ENCCUOTA_DOC;
        this.enccuota.ENCCUOTA_CODIGO = rs.ENCCUOTA_CODIGO;
        this.enccuota.ENCCUOTA_INTERESANUAL = rs.ENCCUOTA_INTERESANUAL;
        this.lblTotalDoc = rs.ENCFAC_TOTAL.toFixed(this.confIniciales.getNumDecSist());
        this.lblSaldoDoc = rs.ENCCUOTA_SUBTOTAL.toFixed(this.confIniciales.getNumDecSist());
        this.enccuota.ENCCUOTA_FINANCIADO = rs.ENCCUOTA_FINANCIADO.toFixed(this.confIniciales.getNumDecSist());
        this.enccuota.ENCCUOTA_INTERESANUAL = rs.ENCCUOTA_INTERESANUAL.toFixed(this.confIniciales.getNumDecSist());
        this.enccuota.ENCCUOTA_TOTAL = rs.ENCCUOTA_TOTAL.toFixed(this.confIniciales.getNumDecSist());
        if (rs.ENCCUOTA_INTERESTIPO === 'PORCE') {
          this.enccuota.ENCCUOTA_INTERESEDIT = rs.ENCCUOTA_INTERESPORCE.toFixed(this.confIniciales.getNumDecSist());
          this.enccuota.ENCCUOTA_INTERESDIS = rs.ENCCUOTA_INTERESVAL.toFixed(this.confIniciales.getNumDecSist());
        } else {
          this.enccuota.ENCCUOTA_INTERESDIS = rs.ENCCUOTA_INTERESPORCE.toFixed(this.confIniciales.getNumDecSist());
          this.enccuota.ENCCUOTA_INTERESEDIT = rs.ENCCUOTA_INTERESVAL.toFixed(this.confIniciales.getNumDecSist());
        }
        this.strCodigoCuota = rs.ENCCUOTA_CODIGO;
        this.enccuota.CON_CODIGO = rs.CON_CODIGO === null ? '' : rs.CON_CODIGO;
        this.enccuota.CON_NOMBRE = '';
        if (this.enccuota.CON_CODIGO !== '') {
          this.cuotaService.erConnombre(this.enccuota.CON_CODIGO).subscribe(con => {
              if (con !== null) {
                this.enccuota.CON_NOMBRE = con[0].CON_NOMBRE;
              }
            });
        }
        this.cuotaService.consultarDetalleCuotas(encfaccodigo).subscribe(datadet => {
          const listadetalle = [];
          for (const rs2 of datadet) {
            rs2.DETCUOTA_FECHAVENCE = this.datePipe.transform(rs2.DETCUOTA_FECHAVENCE, 'dd/MM/yyyy');
            rs2.DETCUOTA_IMPORTE = rs2.DETCUOTA_IMPORTE.toFixed(this.confIniciales.getNumDecSist());
            rs2.DETCUOTA_PAGADO = rs2.DETCUOTA_PAGADO.toFixed(this.confIniciales.getNumDecSist());
            rs2.DETCUOTA_SALDO = rs2.DETCUOTA_SALDO.toFixed(this.confIniciales.getNumDecSist());
            listadetalle.push(rs2);
          }
          this.detcuotas = listadetalle;
        });
        document.getElementById('divPrincipal').style.pointerEvents = 'none';//CDPJ
      }
    });
    this.botonesseleccionar();
  }

  seleccionarRegistro(strTipo) {
    let strCondicion = 'CC.COM_CODIGO = \'01\'';
    if (strTipo === 'S') {
      if (this.strNumFact.length > 0) {
        strCondicion = strCondicion + ' AND ENCFAC_NUMERO > \''
          + this.strNumFact + '\' AND ENCFAC_NUMERO LIKE \''
          + this.strNumFact.substr(0, 3) + '%\'';
      } else {
        strTipo = 'U';
      }
    }
    if (strTipo === 'A') {
      if (this.strNumFact.length > 0) {
        strCondicion = strCondicion + ' AND ENCFAC_NUMERO < \''
          + this.strNumFact + '\'';
      } else {
        strTipo = 'P';
      }
    }

    this.cuotaService.seleccionarRegistro(strTipo, ' ', strCondicion).subscribe((datos: any[]) => {
      if (datos !== null) {
        this.navegarDocumento(datos[0].ENCFAC_NUMERO);
      }
    }, error1 => {
      const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Error',
        detail: error
      });
    });
  }

  async eliminarDocumento() {
    this.confirmationService.confirm({
      message: 'Está seguro de eliminar el documento?',
      header: 'Eliminar Cuotas',
      icon: 'pi pi-exclamation-triangle',
      key: 'cuotasconf',
      accept: () => {
        // this.cuotaService.eliminarDocumento(this.strCodigoCuota).subscribe(eli => {
        //   this.pagconservice.actualizaEstadoFactura(this.strNumFact, this.strCliCodigo).subscribe(act => {
        //     this.limpiardatos();
        //   });
        //   this.messageService.add({
        //     key: 'encfac',
        //     severity: 'success',
        //     summary: 'Eliminar Cuotas',
        //     detail: 'Se eliminó correctamente'
        //   });
        // }, error1 => {
        //   let mensaje: string;
        //   mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        //   this.messageService.add({
        //     key: 'encfac',
        //     severity: 'error',
        //     summary: 'Eliminación de Cuotas',
        //     detail: mensaje
        //   });
        // });
        if(Number(this.validarPagosCuotas())>0){
          this.messageService.add({
            key: 'cuotas',
            severity: 'error',
            summary: 'Eliminar Cuotas',
            detail: 'No se pueden eliminar el documento, porque tiene registrado pagos'
          });
          return;
        }else{
          this.cuotaService.eliminarDocumento(this.strCodigoCuota).subscribe(eli => {
            this.pagconservice.actualizaEstadoFactura(this.strNumFact, this.strCliCodigo).subscribe(act => {
              this.limpiardatos();
            });
            this.messageService.add({
              key: 'encfac',
              severity: 'success',
              summary: 'Eliminar Cuotas',
              detail: 'Se eliminó correctamente'
            });
            //GSRF
              this.cuotaService.registrarAuditoria('CXC_ENCCUOTA', this.strNumFact, 'E',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
              });
            //GSRF
          }, error1 => {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.messageService.add({
              key: 'encfac',
              severity: 'error',
              summary: 'Eliminación de Cuotas',
              detail: mensaje
            });
          });
        }
      },
      reject: () => {
      }
    });
  }
//CDPJ
validarPagosCuotas(){
  let total=0
  
  for (let fila = 1; fila <= this.detcuotas.length; fila++) {
    const itemdet: CxcDetcuota = this.detcuotas[fila - 1];
    itemdet.DETCUOTA_PAGADO=itemdet.DETCUOTA_PAGADO === null || itemdet.DETCUOTA_PAGADO === '' || itemdet.DETCUOTA_PAGADO === undefined?0:itemdet.DETCUOTA_PAGADO
    total=total+Number(itemdet.DETCUOTA_PAGADO)
  }
  console.log('total',total)
  return total;
}
//CDPJ
  limpiardatos() {
    this.encfac = {};
    this.nuevoenccuota();
    this.detcuotas = [];
    this.strNumFact = '';
    this.dateTimeFecha = '';
    this.lblTotalDoc = '';
    this.lblCliente = '';
    this.lblVendedor = '';
    this.lblSaldoDoc = '';
  }


  buscarDocPend() {
    this.opcionbusqueda = 'docpend';
    this.types = [
      { label: 'TRNCOBRO_NRODOC', value: 'TRNCOBRO_NRODOC' },
      { label: 'DOCUMENTO', value: 'TRNCOBRO_NROCOMPROBANTE' },
      { label: 'COD.CLIENTE', value: 'CLI_CODIGO' },
      { label: 'NOMBRE CLIENTE', value: 'CLI_NOMBRE' },
      { label: 'IMPORTE', value: 'TRNCOBRO_IMPORTE' },
      { label: 'SALDO', value: 'SALDO' },
      { label: 'CONCEPTO', value: 'TRNCOBRO_CONCEPTO' },
      { label: 'TIPO', value: 'TRNCOBRO_TIPODOC' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', ''];
    const condicion = 'A.CLI_CODIGO = B.CLI_CODIGO AND VEN_FNC_SALDOFACTURA(A.COM_CODIGO,A.TRNCOBRO_NRODOC ) > 0 AND' +
      '  A.APLORG_CODIGO <> \'SISTEMA\' AND' +
      '  A.TRNCOBRO_TIPODOC IN(\'FC\',\'ND\')';
    this.tabla = 'CXC_TRNCOBRO A, VEN_MAECLIENTE B WHERE ' + condicion;
    this.consulta = 'A.TRNCOBRO_NRODOC,A.TRNCOBRO_NROCOMPROBANTE,A.CLI_CODIGO,B.CLI_NOMBRE,' +
      '  A.TRNCOBRO_IMPORTE, VEN_FNC_SALDOFACTURA(A.COM_CODIGO,A.TRNCOBRO_NRODOC ) AS SALDO,' +
      '  A.TRNCOBRO_CONCEPTO, A.TRNCOBRO_TIPODOC';
    this.busqService.busquedaCXCTrnCobro3().subscribe((datos: any[]) => {
        this.arregloCons = datos;
        this.displayDialogBusquedaFast = true;
      });
  }

  buscarCuentaCont(parametro) {
    this.opcionbusqueda = 'BuscarCuentaCont';
    this.types = [
      { label: 'Código', value: 'CON_CODIGO' },
      { label: 'Nombre', value: 'CON_NOMBRE' },
      { label: 'CNT. COSTOS', value: 'CON_CENTRO' }
    ];
    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.tabla = 'CON_MAECON';

    this.cuotaService.erCuentacontable(parametro).subscribe(eR => {
        if (eR !== null) {
          if (eR[0] !== undefined) {
            this.manejarSeleccion(eR[0]);
          } else {
            this.busqService.busquedaConMaeCon2().subscribe((res: any[]) => {
              this.arregloCons = res;
              this.displayDialogBusquedaFast = true;
            });
          }
        } else {
          this.busqService.busquedaConMaeCon2().subscribe((res: any[]) => {
            this.arregloCons = res;
            this.displayDialogBusquedaFast = true;
          });
        }
      });
  }

  limpiarCuentaCont() {
    this.enccuota.CON_NOMBRE = '';
  }

  cerrarReportes() {
    this.displayDialogReportes = false;
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos  = false;
    }
  }
}
