
<div class="p-grid" style="width: 99%">
    <div class="p-col-12">
        <app-invmaebarra [botonRegresar]="btnRegresar" [barraBotonesCRUD]="false"   [barraBotonesCRUD]="true"
                         [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true"
                         (signal)="manejarSenalesAdi($event)"></app-invmaebarra>
    </div>
</div>
<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="maeNomMatdatadit"></p-toast>
<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px; width: 99%;">
	<div class="p-col-12">
		<div class="p-grid">
			
			<div class="p-col-2">
				<input type="text" pInputText [(ngModel)]="inputcodigo" 
					   style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
					   disabled
					  >
			</div>
			<!-- <div class="p-col-4"></div> -->
			<div class="p-col-7">
				<input type="text" pInputText [(ngModel)]="inputanomape" 
					   style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
					   disabled
					   >
			</div>
		</div>
	</div>
</div>
<div style="overflow: hidden" class="p-col-12">
    <p-toast [style]="{marginTop: '41vh'}" position="top-center" key="docimgToast"></p-toast>
    <p-tabView>
        <p-tabPanel header="Documentos Adicionales">
            <div style="display: flex;align-items: center;justify-content: center;">
        
                <p-progressSpinner *ngIf="cargando==false" [style]="{width: '200px', height: '200px'}"
					   animationDuration=".5s"></p-progressSpinner>
            </div>
            <app-aggridsaci *ngIf="cargando==true" [width]=""
            [height]="largoAdi"
            [enableSorting]="true"
            [enableFilter]="true"
            [rowData]="nommaeDatAdi"
            [frameworkComponents]="frameworkComponents"
            [animateRows]="true"
            [rowSelection]="rowSelection"
            [columnDefs]="columnDefMaeDatAdi"
            [defaultColDef]="defaultColDefMaeDatAdi"
            [rowStyle]="rowStyle"
            [singleClickEdit]="false"
            [botones]="false"
            (selected)=selectMaeDatAdi($event)
            (cambios)="cambioDatAdi($event)"

            ></app-aggridsaci>
        </p-tabPanel>
    </p-tabView>
</div>
<p-dialog header="Ingreso de imagenes" [(visible)]="displaydatosAdiglob"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  (onHide)="onHideDatAdiDoc()"
		  [style]="{width: '70vw', height: 'auto'} ">
    
    <div class="p-grid">
        <div class="p-col-12">
            <p-fileUpload id="dat_adi" name="myfile[]"  accept="image/*,application/pdf"
                          auto="auto" customUpload="true" chooseLabel="Seleccionar"
                          (uploadHandler)="onBasicUpload($event)"
                          maxFileSize="2000000">
            </p-fileUpload>

        </div>
        <div class="p-col-12" style="text-align: center">
            <button type="button"
                    style="background-image: url(../../../assets/images/iconos/visto.png);"
                    (click)="cargara()"></button>
        </div>
    </div>
   
</p-dialog>

<p-dialog header="Visualización de documentos" [(visible)]="displaydatosImaDia"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  (onHide)="onHideDatAdiDoc()"
		  [style]="{width: '50vw', height: 'auto'} ">
    <p-toast [style]="{marginTop: '41vh'}" position="top-center" key="rubDialog"></p-toast>
    <button  pButton class="aceptar" style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 49% ;margin-bottom: 5px;" (click)="opcionActualizar()">Actualizar</button>	
    <button  pButton class="aceptar" style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 49%;margin-left: 5px;margin-bottom: 5px;" (click)="opcionEliminar()">Eliminar</button>	
    <div *ngIf="displaydatosImaDia==true" style="display: flex; justify-content: center;align-items: center;">
        <div >
            <div *ngIf="this.imgbool==true"  >
                <img [src]="ima_pdf" height="400px" width="250px" >
            </div>      
            <ng-container *ngIf="this.pdfbool==true">
                <div >
                    <object [data]="ima_pdf" type="application/pdf" height="600px" width="400px">
                        <embed [src]="ima_pdf" type="application/pdf">
                    </object>
                </div>
            </ng-container>
        </div>
    </div>
</p-dialog>
<p-confirmDialog [transitionOptions]="'0ms'" key="eliminardatDialog" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>