import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import  {arraytxt,codalte} from '../anexinterfaces/anexgenerararchivos';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class AneximpdocsriService {
  public arreglotxt: arraytxt[][]= [];
  public arreglocodalt: codalte[][]= [];
  private way: string;
  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) { 
      this.way = sessionStorage.getItem('way');
    }
    

   conexistencfacpro(codigo,referencia,serie){
    return  this.http.post<any[]>(this.way + '/anex/importadocsri/cc5ddc973aebb00000000', {
      elementos: {
        p_PRO_CODIGO: codigo,
        p_ENCFACPRO_REFERENCIA: referencia,
        p_ENCFACPRO_SERIE:serie
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  conruccompania(idreceptor){
    return  this.http.post<any[]>(this.way + '/anex/importadocsri/198befcc662d950000', {
      elementos: {
        p_COMRUCI: idreceptor,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  existenpro(codigo){
    return  this.http.post<any[]>(this.way + '/anex/importadocsri/1d2e75d9de3ee70000', {
      elementos: {
        p_PRO_CODIGO: codigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  busqarti(codigo){
    return  this.http.post<any[]>(this.way + '/anex/importadocsri/17ee6bce6aaca70000', {
      elementos: {
        p_ART_CODIGO: codigo,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  busqcodalt(codigo){
    return  this.http.post<any[]>(this.way + '/anex/importadocsri/17ee6bce6a99870000', {
      elementos: {
        p_ART_CODIGO: codigo,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  descargarxmlsri(clave){
    return  this.http.post<any[]>(this.way + '/anex/importadocsri/d7718adc15b', {
      elementos: {
        p_clave_acceso: clave,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  insertarEncabezadoFactPromise(item): Promise<any[]> {
    console.log('-----guardar facpro------',item)
    return this.http.post<any>(this.way + '/anex/importadocsri/4afc76faadbaec0000000000', {
      elementos: {
        p_encfacpro_numero: item.ENCFACPRO_NUMERO,
        p_com_codigo: item.COM_CODIGO,
        p_encfacpro_fechaemision: item.ENCFACPRO_FECHAEMISION,
        p_encfacpro_fechavencimiento: item.ENCFACPRO_FECHAVENCIMIENTO,
        p_encfacpro_fecharec: item.ENCFACPRO_FECHAREC,
        p_encfacpro_iva: item.ENCFACPRO_IVA,
        p_encfacpro_estado: item.ENCFACPRO_ESTADO,
        p_pro_codigo: item.PRO_CODIGO,
        p_encfacpro_contacto: item.ENCFACPRO_CONTACTO,
        p_encfacpro_referencia: item.ENCFACPRO_REFERENCIA,
        p_encfacpro_total: Number(item.ENCFACPRO_TOTAL.toFixed(2)),
        p_encfacpro_totalneto: item.ENCFACPRO_TOTALNETO,
        p_encfacpro_valordes: item.ENCFACPRO_VALORDES,
        p_encfacpro_porcedes: item.ENCFACPRO_PORCEDES,
        p_encfacpro_valoriva: item.ENCFACPRO_VALORIVA,
        p_encfacpro_porceiva: item.ENCFACPRO_PORCEIVA,
        p_encfacpro_valorice: item.ENCFACPRO_VALORICE,
        p_encfacpro_baseiva: item.ENCFACPRO_BASEIVA,
        p_encfacpro_baseice: item.ENCFACPRO_BASEICE,
        p_encfacpro_basecero: item.ENCFACPRO_BASECERO,
        p_encfacpro_comentario: item.ENCFACPRO_COMENTARIO,
        p_encfacpro_otros: item.ENCFACPRO_OTROS,
        p_encfacpro_distcargos: item.ENCFACPRO_DISTCARGOS,
        p_encfacpro_numdiasplazo: item.ENCFACPRO_NUMDIASPLAZO,
        p_encfacpro_idcretri: item.ENCFACPRO_IDCRETRI,
        p_encfacpro_serie: item.ENCFACPRO_SERIE,
        p_encfacpro_autorizacion: item.ENCFACPRO_AUTORIZACION,
        p_encfacpro_tipcom: item.ENCFACPRO_TIPCOM,
        p_maemotivo_codigo: item.MAEMOTIVO_CODIGO,
        P_encfacpro_fechacadfac: item.ENCFACPRO_FECHACADFAC,
        p_encfacpro_numingreso: item.ENCFACPRO_NUMINGRESO,
        p_encfacpro_numliquidacion: item.ENCFACPRO_NUMLIQUIDACION,
        p_encfacpro_tipodes: item.ENCFACPRO_TIPODES,
        p_usu_identificacion: this.usuario.identificacion,
        p_encfacpro_gasto: item.ENCFACPRO_GASTO,
        p_encordcom_numero: item.ENCORDCOM_NUMERO,
        p_encfacpro_noobjetoiva: item.ENCFACPRO_NOOBJETOIVA,
        P_encfacpro_basenoobjiva: item.ENCFACPRO_BASENOOBJIVA,
        p_trnformapago_codigo: item.TRNFORMAPAGO_CODIGO,
        p_encfacpro_origen: '',
        p_encfacpro_flag: item.ENCFACPRO_FLAG,//GSRF
        p_encfacpro_formapago: 'C'//GSRF
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarDetalleFacturaPromise(item): Promise<any[]> {
    console.log('INSERTA DETALLE',item);
    return this.http.post<any>(this.way + '/compras/factproveedores/4afc735dd53d4c', {
      elementos: {
        p_encfacpro_numero: item.ENCFACPRO_NUMERO,
        p_com_codigo: '01',
        p_detfacpro_linea: item.DETFACPRO_LINEA,
        p_detfacpro_tipodet: item.DETFACPRO_TIPODET,
        p_bod_codigo: item.BOD_CODIGO,
        p_detfacpro_codigo: item.DETFACPRO_CODIGO,
        p_detfacpro_descripcion: item.DETFACPRO_DESCRIPCION,
        p_detfacpro_unidad: item.DETFACPRO_UNIDAD,
        p_detfacpro_cantidad: item.DETFACPRO_CANTIDAD,
        p_detfacpro_entregado: item.DETFACPRO_ENTREGADO,
        p_detfacpro_costo: item.DETFACPRO_COSTO,
        p_detfacpro_valdes: item.DETFACPRO_VALDES,
        p_detfacpro_pordes: item.DETFACPRO_PORDES,
        p_detfacpro_total: item.DETFACPRO_TOTAL,
        p_detfacpro_poriva: item.DETFACPRO_PORIVA,
        p_detfacpro_iva: Number(item.DETFACPRO_IVA.toFixed(2)),
        p_detfacpro_tribice: item.DETFACPRO_TRIBICE,
        p_detfacpro_ice: item.DETFACPRO_ICE,
        p_detfacpro_porceice: item.DETFACPRO_PORCEICE,
        p_detfacpro_baseiva: item.DETFACPRO_BASEIVA,
        p_detfacpro_baseice: item.DETFACPRO_BASEICE,
        p_detfacpro_basecero: item.DETFACPRO_BASECERO,
        p_encordcom_numero: item.ENCORDCOM_NUMERO,
        p_detordcom_linea: item.DETORDCOM_LINEA,
        p_encnotrec_numero: item.ENCNOTREC_NUMERO,
        p_detnotrec_linea: item.DETNOTREC_LINEA,
        p_detfacpro_promocion: item.DETFACPRO_PROMOCION,
        p_detfacpro_totalincp: item.DETFACPRO_TOTALINCP,
        p_detfacpro_pordes2: item.DETFACPRO_PORDES2,
        p_detfacpro_pordes3: item.DETFACPRO_PORDES3,
        p_cen_codigo: item.CEN_CODIGO,
        p_detfacpro_factor: item.DETFACPRO_FACTOR,
        p_detfacpro_preciofob: item.DETFACPRO_PRECIOFOB,
        p_detfacpro_esquemadoc: item.DETFACPRO_ESQUEMADOC,
        p_detfacpro_tiposri: item.DETFACPRO_TIPOSRI,
        p_detfacpro_precioa: item.DETFACPRO_PRECIOA,
        p_detfacpro_preciob: item.DETFACPRO_PRECIOB,
        p_detfacpro_precioc: item.DETFACPRO_PRECIOC,
        p_trnsolfac_codigo: item.TRNSOLFAC_CODIGO,
        p_trnsolfac_linea: item.TRNSOLFAC_LINEA,
        p_encreq_numero: item.ENCREQ_NUMERO,
        p_detreq_linea: item.DETREQ_LINEA,
        p_encgre_codigo: item.ENCGRE_CODIGO,
        p_detgre_linea: item.DETGRE_LINEA,
        p_detfacpro_basenoobjiva: item.DETFACPRO_BASENOOBJIVA,
        p_detfacpro_tribasenoobjiva: item.DETFACPRO_TRIBASENOOBJIVA,
        p_detfacpro_cantidadund: item.DETFACPRO_CANTIDADUND,
        p_tipgas_codigo: item.TIPGAS_CODIGO,
        p_detfacpro_po: '',
        p_detfacpro_ajuste: '',
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
}
