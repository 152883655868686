<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="banCashMng"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="banCashMngConf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
						 [botonBorrar]="btnBorrar"
						 (signal)="manejarSenales($event)" [botonAnterior]="botonAnterior" [botonPrimero]="botonPrimero"
						 [botonUltimo]="botonUltimo"
						 [botonSiguiente]="botonSiguiente" [barraCashmng]="true" [barraBotones3]="true"
						 [botonBuscar]="btnBuscar">
		</app-invmaebarra>
	</div>
</div>
<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px; width: 99%;">
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-11"></div>
			<div class="p-col-1">
				<input type="text" pInputText
					   style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
					   disabled [(ngModel)]="encCashmng.ENCCASHMNG_CODIGO">
			</div>
		</div>
	</div>
</div>
<div class="divgrups" id="divPrincipal">
	<div class="p-grid">
		<!--LINE 2-->
		<div class="p-col-1">
			<span>Fecha Emisión:</span>
		</div>
		<div class="p-col-2" style="text-align: left">
			<p-calendar id="banCashMngFEmision" [inputStyle]="{width: '70%', height: '20px'}"
						dateFormat="dd/mm/yy" [(ngModel)]="encCashmng.ENCCASHMNG_FECHAEMISION"
						(ngModelChange)="cambio($event)">
			</p-calendar>
		</div>
		<div class="p-col-1">
			<span>Comprobante:</span>
		</div>
		<div class="p-col-1">
			<input id="nrocomprobante" type="text" pInputText [(ngModel)]="encCashmng.ENCCASHMNG_COMPROBANTE"
				   (input)="cambio($event)">
		</div>
		<div class="p-col-12" style="margin-top: -5px">
			<div class="p-grid">
				<div class="p-col-1">
					<span>Banco:</span>
				</div>
				<div class="p-col-1" style="text-align: left">
					<input id="BAN_CODIGO" type="text" pInputText autocomplete="off"
						   [(ngModel)]="encCashmng.BAN_CODIGO">
				</div>
				<div class="p-col-1">
					<button type="button" style="background-image: url(../../../assets/images/iconos/buscar.png);"
							class="littlebuttons" (click)="buscarBancos(encCashmng.BAN_CODIGO)"></button>
				</div>
				<div class="p-col-2" style="text-align: left">
					<input id="BAN_NOMBRE" type="text" pInputText disabled autocomplete="off"
						   [(ngModel)]="encCashmng.BAN_BANCO">
				</div>
				<div class="p-col-1">
				</div>
				<div class="p-col-3">
					<p-checkbox label="Incluir Detalle" binary="true" [(ngModel)]="chkIncluirDetalle">
					</p-checkbox>
				</div>
				<div class="p-col-1" style="text-align: right">
					<span>Número de Envío:</span>
				</div>
				<div class="p-col-1" style="text-align: left">
					<p-dropdown [style]="{'width': '60%', 'min-width': '60%'}" [(ngModel)]="strNumEnvio"
								optionLabel="label" [options]="cmbNumEnvio" inputId="inputNumEnvio"></p-dropdown>
				</div>
			</div>
		</div>
		<!--LINE 3-->

		<!--LINE 4-->
		<div class="p-col-12" style="margin-top: -12px; margin-bottom: -10px">
			<div class="p-grid">
				<div class="p-col-1">
					<span>Desde:</span>
				</div>
				<div class="p-col-2" style="text-align: left">
					<p-calendar id="banCashMngFDesde" [inputStyle]="{width: '70%', height: '20px'}"
								dateFormat="dd/mm/yy" [(ngModel)]="encCashmng.ENCCASHMNG_FECDESDE"
								(ngModelChange)="cambio($event)">
					</p-calendar>
				</div>
				<div class="p-col-1" style="text-align: left">
					<span>Hasta:</span>
				</div>
				<div class="p-col-2" style="text-align: left">
					<p-calendar id="banCashMngFHasta" [inputStyle]="{width: '70%', height: '20px'}"
								dateFormat="dd/mm/yy" [(ngModel)]="encCashmng.ENCCASHMNG_FECFIN"
								(ngModelChange)="cambio($event)">
					</p-calendar>
				</div>
				<div class="p-col-3" style="text-align: left">
					<p-checkbox *ngIf="visibleCashResumido" label="Resumido (Para generar el archivo plano)"
								binary="true" [(ngModel)]="chkCashResumido">
					</p-checkbox>
				</div>
				<div class="p-col-1" style="text-align: left">
					<button (click)="mostrarComprobantes()" pButton class="aceptar"
							style="line-height: 2.5em; font-family: Arial, serif; height: 30px; width: 100px">
						Aceptar
					</button>
				</div>
				<div class="p-col-2">
				</div>
			</div>
		</div>
		<!--LINE 5-->
		<div class="p-col-12" style="margin-top: -12px; margin-bottom: -15px;">
			<div class="p-grid">
				<div class="p-col-1">
					<span>Concepto:</span>
				</div>
				<div class="p-col-5">
					<input id="concepto" type="text" pInputText [(ngModel)]="encCashmng.ENCCASHMNG_CONCEPTO"
						   (input)="cambio($event)">
				</div>
				<div class="p-col-2" style="text-align: left">
					<p-checkbox label="Seleccionar Todos" binary="true" [(ngModel)]="boolSelectAll"
								(onChange)="selectAll($event)">
					</p-checkbox>
				</div>
				<div class="p-col-1"></div>
				<div class="p-col-1"></div>
			</div>
		</div>
	</div>
</div>
<div class="divg">
	<app-aggridsaci [width]=""
					[height]="largo"
					[enableSorting]="true"
					[enableFilter]="true"
					[rowData]="detCashmng"
					[frameworkComponents]="frameworkComponents"
					[animateRows]="true"
					[rowSelection]="rowSelection"
					[columnDefs]="columnDefsCashMng"
					[defaultColDef]="defaultColBanCashMng"
					[rowStyle]="rowStyle"
					[singleClickEdit]="false"
					[botones]="false"
					(selected)="seleccionDetCashmng($event)"
	>
	</app-aggridsaci>
</div>

<div class="divgrups">
	<div class="p-grid">
		<div class="p-col-1">
			<span>Ubicación Archivo:</span>
		</div>
		<div class="p-col-6">
			<input id="ENCCASHMNG_PATHARCHIVO" type="text" pInputText autocomplete="off"
				   [(ngModel)]="encCashmng.ENCCASHMNG_PATHARCHIVO">
		</div>
		<div class="p-col-4" style="text-align: left">
		</div>
	</div>
</div>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBus"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [closable]="false"
		  [style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>
<p-dialog header="Cash Managment" [(visible)]="visibleDialogEdit"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '40vw', height: 'auto'} ">
	<app-bancashmngedit *ngIf="visibleDialogEdit" [encCashmngCodigo]="encCashmng.ENCCASHMNG_CODIGO"
						[strCodProveedor]="detalleCashSelect.PRO_CODIGO"
						(signalGuardarCash)="guardaDetalleCashmng($event)" (signalSalir)="salirDialogEdit($event)"
						(signalGuardarProv)="guardarCashProv($event)"></app-bancashmngedit>
</p-dialog>