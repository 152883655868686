<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="banmaebanKey"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarCntBan" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
						 [botonRegresar]="btnRegresar" [botonPrimero]="btnPrimero" [botonAnterior]="btnAnterior"
						 [botonSiguiente]="btnSiguiente" [botonUltimo]="btnUltimo" [barraBotonesCRUD]="false"
						 [barraBotonesDesplaz]="false" [barraBotones3]="true" [barraBotonesAsiCon]="true"
						 [barraBotones5]="false" [botonBuscar]="false"
						 (signal)="manejarSenales($event)">
		</app-invmaebarra>
	</div>
</div>

<p-tabView>
	<p-tabPanel header="Cuentas Bancarias">
		<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 250px)'}">
			<div class="contorno" id="contorno">
				<div class="divgrups">
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-2">
									<span class="obligatorio"> Código: </span>
								</div>
								<div class="p-col-2">
									<label for="bancodigo"></label>
									<input class="frm" id="bancodigo" type="text" pInputText
										[(ngModel)]="cuentaBanSeleccionada.BAN_CODIGO" [disabled]="desabilitarCampos"
										(keydown)="botonesmodificar()" (keydown.enter)="setFocus($event)"
										style="text-transform: uppercase;">
								</div>
								<div class="p-col-1">
								</div>
								<div class="p-col-2">
									<span class="obligatorio"> Nombre: </span>
								</div>
								<div class="p-col-3">
									<!-- <p-dropdown id="bannombre" [style]="{'width': '300px'}"
										[panelStyle]="{'width':'300px'}" inputId="NOM_BAN"
										[(ngModel)]="cuentaBanSeleccionada.BAN_BANCO" [options]="opcionesNomBan"
										(ngModelChange)="obtenerSucBanModificar(cuentaBanSeleccionada)"
										(keydown.enter)="setFocus($event)" editable="false">
									</p-dropdown> -->
									<!-- CDPJ -->
									<input class="frm" id="NOM_BAN" type="text" pInputText
										[(ngModel)]="cuentaBanSeleccionada.BAN_BANCO" (keydown.enter)="setFocus($event)"
										style="text-transform: uppercase;" (keydown)="botonesmodificar()">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										[disabled]="desabilitarCampos" (click)="buscarCntsBanSd()">
									</button>
								</div>
								<!-- CDPJ -->	
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-2">
									<span>	Sucursal: </span>
								</div>
								<div class="p-col-2">
									<p-dropdown id="bansucursal"
												[style]="{'width': '220px'}"
												[panelStyle]="{'width':'300px'}" inputId="SUC_BAN"
												[(ngModel)]="cuentaBanSeleccionada.BAN_SUCURSAL"
												[options]="opcionesScrsBan"
												(ngModelChange)="botonesmodificar()"
												(keydown.enter)="setFocus($event)" editable="true">
									</p-dropdown>
								</div>
								<div class="p-col-1">
								</div>
								<div class="p-col-2">
									<span>	Teléfonos: </span>
								</div>
								<div class="p-col-2">
									<label for="bannum1"></label>
									<input class="frm" id="bannum1" type="text" pInputText
										   [(ngModel)]="cuentaBanSeleccionada.BAN_TELEFONO1"
										   (keydown)="botonesmodificar()"
										   (keydown.enter)="setFocus($event)">
								</div>
								<div class="p-col-2">
									<label for="bannum2"></label>
									<input class="frm" id="bannum2" type="text" pInputText
										   [(ngModel)]="cuentaBanSeleccionada.BAN_TELEFONO2"
										   (keydown)="botonesmodificar()"
										   (keydown.enter)="setFocus($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-2">
									<span>	Dirección: </span>
								</div>
								<div class="p-col-9">
									<label for="bandir"></label>
									<input class="frm" id="bandir" type="text" pInputText
										   [(ngModel)]="cuentaBanSeleccionada.BAN_DIRECCION"
										   (keydown)="botonesmodificar()"
										   (keydown.enter)="setFocus($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px">
							<div class="p-grid">
								<div class="p-col-2">
									<span>	Correo electrónico: </span>
								</div>
								<div class="p-col-5">
									<label for="bancorreo"></label>
									<input class="frm" id="bancorreo" type="text" pInputText
										   [(ngModel)]="cuentaBanSeleccionada.BAN_CORREO" (keydown)="botonesmodificar()"
										   (keydown.enter)="setFocus($event)">
								</div>
								<div class="p-col-1">
									<p-checkbox
											name="group1" [(ngModel)]="checkcheque" label="" binary="true"
											(ngModelChange)="botonesmodificar()">
									</p-checkbox>
								</div>
								<div class="p-col-1">
									<span>	Num. Cheque: </span>
								</div>
								<div class="p-col-2">
									<label for="bannumcheque"></label>
									<input class="frm" id="bannumcheque" type="text" pInputText
										   [(ngModel)]="cuentaBanSeleccionada.BAN_ULTIMOCHEQUE"
										   [disabled]="!checkcheque"
										   (keydown)="botonesmodificar()" (keydown.enter)="setFocus($event)">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="divgrups">
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-2">
									<span class="obligatorio">  Cuenta Nro.: </span>
								</div>
								<div class="p-col-3">
									<label for="numcuenta"></label>
									<input class="frm" id="numcuenta" type="text" pInputText
										   [(ngModel)]="cuentaBanSeleccionada.BAN_CTANRO" (keydown)="botonesmodificar()"
										   (keydown.enter)="setFocus($event)">
								</div>
								<div class="p-col-1">
								</div>
								<div class="p-col-2">
									<span class="obligatorio">	 Tipo de Cuenta: </span>
								</div>
								<div class="p-col-2">
									<p-dropdown inputId="tipocuenta"
												[style]="{'width': '260px'}"
												[panelStyle]="{'width':'300px'}" id="TIPO_CUENTA"
												[(ngModel)]="cuentaBanSeleccionada.BAN_TIPOCUENTA"
												[options]="opcionesTipoCnt"
												[disabled]="desabilitarCampos"
												(ngModelChange)="botonesmodificar()" (keydown.enter)="setFocus($event)">
									</p-dropdown>
								</div>
								<div class="p-col-1">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-2">
									<span class="obligatorio">  Referencia Contable: </span>
								</div>
								<div class="p-col-3">
									<label for="cuenta_refcont"></label>
									<input class="frm" id="cuenta_refcont" type="text" pInputText
										   [(ngModel)]="cuentaBanSeleccionada.CON_CODIGO" [disabled]="desabilitarCampos"
										   (keydown)="botonesmodificar()" (keydown.enter)="setFocus($event)">
								</div>
								<div class="p-col-1">
									<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											[disabled]="desabilitarCampos"
											(click)="abrirCuentas()">
									</button>
								</div>
								<div class="p-col-2">
									<span>	Jefe de Cuenta: </span>
								</div>
								<div class="p-col-3">
									<label for="jefecuenta"></label>
									<input class="frm" id="jefecuenta" type="text" pInputText
										   [(ngModel)]="cuentaBanSeleccionada.BAN_JEFECTA"
										   (keydown)="botonesmodificar()" (keydown.enter)="setFocus($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-2">
									<span>	Centro de Costo: </span>
								</div>
								<div class="p-col-3">
									<label for="CCOSTO_CUENTA"></label>
									<input class="frm" id="CCOSTO_CUENTA" type="text" pInputText
										   [(ngModel)]="cuentaBanSeleccionada.CEN_CODIGO" [disabled]="desabilitarCC"
										   (keydown)="botonesmodificar()">
								</div>
								<div class="p-col-1">
								</div>
								<div class="p-col-2">
									<span>	Saldo: </span>
								</div>
								<div class="p-col-3">
									<label for="SALDO_CUENTA"></label>
									<input class="frm" id="SALDO_CUENTA" type="text" pInputText
										   [(ngModel)]="cuentaBanSeleccionada.BAN_SALDO" [disabled]="desabilitarCC"
										   (keydown)="botonesmodificar()">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-2">
									<span>	Documentos por pagar: </span>
								</div>
								<div class="p-col-3">
									<label for="docxpagcuenta"></label>
									<input class="frm" id="docxpagcuenta" type="text" pInputText
										   [(ngModel)]="cuentaBanSeleccionada.CON_CODIGO_2"
										   (keydown)="botonesmodificar()" (keydown.enter)="setFocus($event)">
								</div>
								<div class="p-col-1">
									<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="abrirCuentas2()">
									</button>
								</div>
								<div class="p-col-2">
									<span>	Proceso CM: </span>
								</div>
								<div class="p-col-2">
									<p-dropdown id="procesocm"
												[style]="{ 'width': '260px'}"
												[panelStyle]="{'width':'300px'}" id="PROCCM_CUENTA"
												[(ngModel)]="cuentaBanSeleccionada.BAN_PROCESO_CM"
												[options]="opcionesProCM"
												(ngModelChange)="botonesmodificar()"
												(keydown.enter)="setFocus($event)">
									</p-dropdown>
								</div>
								<div class="p-col-1">
								</div>
							</div>
						</div>
						<!-- CDPJ -->
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-2">
									<span>	Otras cuentas: </span>
								</div>
								<div class="p-col-3">
									<label for="otrcuentas"></label>
									<input class="frm" id="otrcuentas" type="text" pInputText
										   [(ngModel)]="cuentaBanSeleccionada.CON_CODIGO_3"
										   (keydown)="botonesmodificar()" (keydown.enter)="setFocus($event)">
								</div>
								<div class="p-col-1">
									<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="abrirCuentas3()">
									</button>
								</div>
								
							</div>
						</div>
						<!-- CDPJ -->
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-2">
								</div>
								<div class="p-col-2">
									<p-checkbox
											name="group1" value="" label="Dinero Electrónico" binary="0"
											[(ngModel)]="cuentaBanSeleccionada.BAN_DINEROELEC"
											(ngModelChange)="botonesmodificar()">
									</p-checkbox>
								</div>
								<div class="p-col-2">
								</div>
								<div class="p-col-2">
									<span>	Directorio CM: </span>
								</div>
								<div class="p-col-3">
									<label for="directoriocm"></label>
									<input class="frm" id="directoriocm" type="text" pInputText
										   [(ngModel)]="cuentaBanSeleccionada.BAN_DIRECTORIO_CM"
										   (keydown)="botonesmodificar()" (keydown.enter)="setFocus($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px;">
							<div class="p-grid">
								<div class="p-col-2">
								</div>
								<div class="p-col-3">
									<label></label>
									<input id="bannumcel" class="frm" type="text" pInputText
										   [(ngModel)]="cuentaBanSeleccionada.BAN_TELEFONOCEL"
										   (keydown)="botonesmodificar()" (keydown.enter)="setFocus($event)">
								</div>
								<div class="p-col-1">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</p-scrollPanel>
	</p-tabPanel>
	<p-tabPanel header="Estado de Cuenta">
		<div class="divgrups">
			<div class="p-grid" style="margin-top: 10px; margin-bottom: 10px">
				<div class="p-col-2">
					<span>	BANCO :  </span>
				</div>
				<div class="p-col-3">
					<span>	{{cuentaBanSeleccionada.BAN_BANCO}}  </span>
				</div>
				<div class="p-col-2">
					<span>	Cuenta Numero : </span>
				</div>
				<div class="p-col-3">
					<span>	 {{cuentaBanSeleccionada.BAN_CTANRO}}  </span>
				</div>
			</div>
		</div>
		<div style="overflow: hidden" class="p-col-12">
			<p-tabView>
				<app-aggridsaci [width]=""
								[height]="largo"
								[enableSorting]="true"
								[enableFilter]="true"
								[rowData]="banmaebanService.banmaebanEstCnt"
								[frameworkComponents]="frameworkComponents"
								[animateRows]="true"
								[rowSelection]="rowSelection"
								[columnDefs]="columnDefsEstCnt"
								[defaultColDef]="defaultColDefEstCnt"
								[rowStyle]="rowStyle"
								[singleClickEdit]="false"
								[botones]="false"
				></app-aggridsaci>
			</p-tabView>
		</div>
	</p-tabPanel>
</p-tabView>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [responsive]="true"
		  [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloBus" [tabla]="tabla"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

