<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="cxceditcli"></p-toast>


		<app-invmaebarra [botonNuevo]="botonNuevo" [botonGuardar]="botonGuardar" [botonBorrar]="botonBorrar"
				 [botonRegresar]="botonRegresar" 
				 (signal)="manejarSenales($event)" [barraBotonesDesplaz]="barraBotones2" [barraBotones3]="barraBotones2"
				 [barraBotonesAsiCon]="barraBotones2" [barraBotones5]="true" [noNuevo]="true"
				 [barraBotones3Alt]="false"
				 [noEliminar]="true"></app-invmaebarra>



<p-tabView [(activeIndex)]="index" (onChange)="changeTabs()">
	<p-tabPanel header="Generales">
		<p-scrollPanel [style]="{'width':'100%', 'height':'410px'}">
				<div class="divgrups">
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span class="obligatorio">Tipo ID:</span>
								</div>
								<div class="p-col-2">
									<p-dropdown [options]="cmbide" [style]="{'minWidth': '100%', 'width':'100%'}"
										optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectide"
										 [disabled]="true"></p-dropdown>
								</div>
								<div class="p-col-1">
									<span class="obligatorio">RUC / IDE</span>
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-10">
											<label for="CLI_RUCIDE"></label>
											<input class="frm" id="CLI_RUCIDE" type="text" pInputText
												[(ngModel)]="cxceditdatoscli.CLI_RUCIDE" (keydown.enter)="setFocus($event)"
												 (focusout)="setFocus($event)"
                                                autocomplete="off"
                                                [disabled]="true"
												style="text-transform: uppercase;">
										</div>
										<div class="p-col-2">
											<!-- <button type="button"
												style="background-image: url(../../../assets/images/iconos/visto.png);"
												(click)="validar(cxceditdatoscli.CLI_RUCIDE)"></button> -->
										</div>
									</div>
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-3">
											<span>{{lblvalida}}</span>
										</div>
										<div class="p-col-9">
											<span class="obligatorio">Código</span>
										</div>
									</div>
								</div>
								<div class="p-col-3">
									<label for="CLI_CODIGO"></label>
									<input class="frm" id="CLI_CODIGO" type="text" pInputText
										[(ngModel)]="cxceditdatoscli.CLI_CODIGO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off" [disabled]="true"
										style="text-transform: uppercase;">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span class="obligatorio">Nombre:</span>
								</div>
								<div class="p-col-5">
									<label for="CLI_NOMBRE"></label>
									<input class="frm" id="CLI_NOMBRE" type="text" pInputText
                                        [(ngModel)]="cxceditdatoscli.CLI_NOMBRE" (keydown.enter)="setFocus($event)"
                                        [disabled]="true"
										(keydown)="botonesmodificar()" (focusout)="setFocus($event)" autocomplete="off">
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-3">
										</div>
										<div class="p-col-9">
											<span class="obligatorio">Nombre Corto:</span>
										</div>
									</div>
								</div>
								<div class="p-col-2">
									<label for="CLI_NOMBREC"></label>
									<input class="frm" id="CLI_NOMBREC" type="text" pInputText maxlength="12"
                                        [(ngModel)]="cxceditdatoscli.CLI_NOMBREC" (keydown.enter)="setFocus($event)"
                                        [disabled]="true"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="divgrups">
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span class="obligatorio">Dirección 1:</span>
								</div>
								<div class="p-col-8">
									<label for="CLI_DIRECCION1"></label>
									<input class="frm" id="CLI_DIRECCION1" type="text" pInputText
										[(ngModel)]="cxceditdatoscli.CLI_DIRECCION1" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Dirección 2:</span>
								</div>
								<div class="p-col-8">
									<label for="CLI_DIRECCION2"></label>
									<input class="frm" id="CLI_DIRECCION2" type="text" pInputText
										[(ngModel)]="cxceditdatoscli.CLI_DIRECCION2" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span class="obligatorio">Teléfono 1:</span>
								</div>
								<div class="p-col-2">
									<label for="CLI_TELEFONO1"></label>
									<input class="frm" id="CLI_TELEFONO1" type="text" pInputText
										[(ngModel)]="cxceditdatoscli.CLI_TELEFONO1" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<span>Teléfono 2:</span>
								</div>
								<div class="p-col-2">
									<label for="CLI_TELEFONO2"></label>
									<input class="frm" id="CLI_TELEFONO2" type="text" pInputText
										[(ngModel)]="cxceditdatoscli.CLI_TELEFONO2" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<span>Fax:</span>
								</div>
								<div class="p-col-2">
									<label for="CLI_FAX"></label>
									<input class="frm" id="CLI_FAX" type="text" pInputText
										[(ngModel)]="cxceditdatoscli.CLI_FAX" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2"></div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Cód. Postal:</span>
								</div>
								<div class="p-col-2">
									<label for="CLI_CODPOSTAL"></label>
									<input class="frm" id="CLI_CODPOSTAL" type="text" pInputText
										[(ngModel)]="cxceditdatoscli.CLI_CODPOSTAL" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<span>Cargo:</span>
								</div>
								<div class="p-col-2">
									<label for="CLI_CARGO"></label>
									<input class="frm" id="CLI_CARGO" type="text" pInputText
										[(ngModel)]="cxceditdatoscli.CLI_CARGO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="busquedacargo(cxceditdatoscli.CLI_CARGO)"></button>
								</div>
								<div class="p-col-2">
									<label for="CLI_CARGONOMBRE"></label>
									<input class="frm" id="CLI_CARGONOMBRE" type="text" pInputText disabled
										[(ngModel)]="cxceditdatoscli.CLI_CARGONOMBRE" autocomplete="off">
								</div>
								<div class="p-col-2"></div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -18px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span class="obligatorio">Email 1:</span>
								</div>
								<div class="p-col-3">
									<label for="CLI_CORREO"></label>
									<input class="frm" id="CLI_CORREO" type="text" pInputText
										[(ngModel)]="cxceditdatoscli.CLI_CORREO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
								</div>
								<div class="p-col-1">
									<span>Email 2:</span>
								</div>
								<div class="p-col-3">
									<label for="CLI_CORREO2"></label>
									<input class="frm" id="CLI_CORREO2" type="text" pInputText
										[(ngModel)]="cxceditdatoscli.CLI_CORREO2" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Provincia:</span>
								</div>
								<div class="p-col-1">
									<label for="CLI_PROVINCIA"></label>
									<input class="frm" id="CLI_PROVINCIA" type="text" pInputText
										[(ngModel)]="cxceditdatoscli.CLI_PROVINCIA" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-3">
											<button type="button"
												style="background-image: url(../../../assets/images/iconos/buscar.png);"
												(click)="busquedaprovincia(cxceditdatoscli.CLI_PROVINCIA)"></button>
										</div>
										<div class="p-col-9">
											<label for="CLI_PROVINCIANOMBRE"></label>
											<input class="frm" id="CLI_PROVINCIANOMBRE" type="text" pInputText disabled
												[(ngModel)]="cxceditdatoscli.CLI_PROVINCIANOMBRE" autocomplete="off">
										</div>
									</div>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Ciudad:</span>
								</div>
								<div class="p-col-1">
									<label for="CIU_CODIGO"></label>
									<input class="frm" id="CIU_CODIGO" type="text" pInputText
										[(ngModel)]="cxceditdatoscli.CIU_CODIGO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-3">
											<button type="button"
												style="background-image: url(../../../assets/images/iconos/buscar.png);"
												(click)="busquedaciudad(cxceditdatoscli.CIU_CODIGO)"></button>
										</div>
										<div class="p-col-9">
											<label for="CIU_NOMBRE"></label>
											<input class="frm" id="CIU_NOMBRE" type="text" pInputText disabled
												[(ngModel)]="cxceditdatoscli.CIU_NOMBRE" autocomplete="off">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -23px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Cantón:</span>
								</div>
								<div class="p-col-1">
									<label for="CAN_CODIGO"></label>
									<input class="frm" id="CAN_CODIGO" type="text" pInputText
										[(ngModel)]="cxceditdatoscli.CAN_CODIGO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-3">
											<button type="button"
												style="background-image: url(../../../assets/images/iconos/buscar.png);"
												(click)="busquedacanton(cxceditdatoscli.CAN_CODIGO)"></button>
										</div>
										<div class="p-col-9">
											<label for="CAN_NOMBRE"></label>
											<input class="frm" id="CAN_NOMBRE" type="text" pInputText disabled
												[(ngModel)]="cxceditdatoscli.CAN_NOMBRE" autocomplete="off">
										</div>
									</div>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Parroquia:</span>
								</div>
								<div class="p-col-1">
									<label for="PARR_CODIGO"></label>
									<input class="frm" id="PARR_CODIGO" type="text" pInputText
										[(ngModel)]="cxceditdatoscli.PARR_CODIGO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-3">
											<button type="button"
												style="background-image: url(../../../assets/images/iconos/buscar.png);"
												(click)="busquedaparroquia(cxceditdatoscli.PARR_CODIGO)"></button>
										</div>
										<div class="p-col-9">
											<label for="PARR_NOMBRE"></label>
											<input class="frm" id="PARR_NOMBRE" type="text" pInputText disabled
												[(ngModel)]="cxceditdatoscli.PARR_NOMBRE" autocomplete="off">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -23px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Zona:</span>
								</div>
								<div class="p-col-1">
									<label for="CLI_ZONA"></label>
									<input class="frm" id="CLI_ZONA" type="text" pInputText
										[(ngModel)]="cxceditdatoscli.CLI_ZONA" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-3">
											<button type="button"
												style="background-image: url(../../../assets/images/iconos/buscar.png);"
												(click)="busquedazona(cxceditdatoscli.CLI_ZONA)"></button>
										</div>
										<div class="p-col-9">
											<label for="CLI_ZONANOMBRE"></label>
											<input class="frm" id="CLI_ZONANOMBRE" type="text" pInputText disabled
												[(ngModel)]="cxceditdatoscli.CLI_ZONANOMBRE" autocomplete="off">
										</div>
									</div>
								</div>
								<div class="p-col-5"></div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -23px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>G. Maps:</span>
								</div>
								<div class="p-col-8">
									<label for="CLI_GMAPS"></label>
									<input class="frm" id="CLI_GMAPS" type="text" pInputText
										[(ngModel)]="cxceditdatoscli.CLI_GMAPS" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/067-binocular.png);"
										(click)="busquedagmaps(cxceditdatoscli.CLI_GMAPS)"></button>
								</div>
								<div class="p-col-2"></div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -28px; margin-bottom: -20px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Observación:</span>
								</div>
								<div class="p-col-8">
									<label for="CLI_OBSERVACION"></label>
									<input class="frm" id="CLI_OBSERVACION" type="text" pInputText
										[(ngModel)]="cxceditdatoscli.CLI_OBSERVACION" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
							</div>
						</div>
					</div>
				</div>
			
		</p-scrollPanel>
	</p-tabPanel>

</p-tabView>
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusqueda" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
		(opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla"
		[where]="where"
		[busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>