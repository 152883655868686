<div class="divgrups"  style="width: 50vw; height: 30vh;">
    <div class="p-grid" style="height: 80%;">
        <div class="p-col-4">

            <span style="font-size: medium;">Fecha Fin:</span>

        </div>


        <div class="p-col-8">
            <p-calendar id="inpFechaSali" [inputStyle]="{width: '100%', height: '30px'}"
                    [(ngModel)]="fechaFin" 
                    dateFormat="dd/mm/yy" 
                    (ngModelChange)="setFechFin($event)">
            </p-calendar>
        </div>
    </div>

    <div class="p-grid" style="margin-top: 5px;">
        <div class="p-col-1"></div>

        <div class="p-col-4">
            <button  pButton 
                class="aceptar" 
                style="padding: 0.5em; font-family: Arial, serif;"
                (click)="aceptar()">
                Aceptar
            </button>
        </div>

        <div class="p-col-2"></div>

        <div class="p-col-4">
            <button  pButton 
                class="aceptar" 
                style="padding: 0.5em; font-family: Arial, serif;"
                (click)="cancelar()">
                Cancelar
            </button>
        </div>

        <div class="p-col-1"></div>
    </div>
</div>
