import { Component, OnInit,ViewChild } from '@angular/core';
import { Nommaedatosadi } from '../nominterfaces/nonmaedatosadi';
import { NommaedatosadiService } from '../nomservicios/nommaedatosadi.service';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import { AggridBanComponent } from 'src/app/ban/aggrid-ban/aggrid-ban.component';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
@Component({
  selector: 'app-nommaedatosadi',
  templateUrl: './nommaedatosadi.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NommaedatosadiComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnGuardar: boolean;
  btnNuevo = true;
  btnBorrar: boolean;
  btnRegresar: boolean;

  public frameworkComponents;
  rowStyle;
  largo = '400';
  rowSelection = 'multiple';
  defaultColDefNomDatosadi;
  //nommaedatosadi:Nommaedatosadi[]=[];
  selectedNomMaeDatosAdi:Nommaedatosadi;
  columnDefNomDatosadi=[
    {
      headerName: 'Orden', field: 'DATADI_ORDEN', cellEditor: 'cellPrueba', editable: true, width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 120,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Tipo Dato', field: 'DATADI_TIPODATO', cellEditor: 'agSelectCellEditor', width: 100,
      onCellValueChanged: (params) => {
        this.cambioModulo(params);
      },
      cellEditorParams:
        {
          values: ['Etiqueta','Varchar2', 'Number','Float','Date','Clob'],
        
        },
    },
    {
      headerName: 'Campo', field: 'DATADI_NOMBRECAMPO', cellEditor: 'cellPrueba', width: 200, onCellValueChanged: (params) => {
        this.cambioModulo(params);
      },
      editable: (params) => {
        const boolEdit = this.isEdit(params.data) ;
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 120,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Etiqueta', field: 'DATADI_ETIQUETA', cellEditor: 'cellPrueba', width: 250, onCellValueChanged: (params) => {
        this.cambioModulo(params);
      },
      editable: (params) => {
        const boolEdit = this.isEdit(params.data) ;
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 120,
            tienecalculos: false,
          }
        }
    },
  
    {
      headerName: 'Tamaño', field: 'DATADI_TAMANIO', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEdit(params.data) ;
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 120,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Estado', field: 'DATADI_ESTADO', cellEditor: 'cellPrueba', width: 100, editable:false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 120,
            tienecalculos: false,
          }
        }
    },
    
  ]
  constructor(public nommaedatosadiService: NommaedatosadiService,
              private messageService: MessageService,
              private errorService: ErroresBaseDatosService,
              private confirmationService: ConfirmationService,
              private auditoriaGralService: AuditoriagralService,
              private init: NuevoComponentService ) {
                this.agTable();
               }

  ngOnInit(): void {
    this.btnNuevo =  false;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.btnRegresar = false;
    this.nommaedatosadiService.selectDatosadi().then(res=>{
       this.nommaedatosadiService.nommaedatosadi=res;
       this.nommaedatosadiService.nommaedatosadi.map(x=>{
         if( x.DATADI_TIPODATO=="Etiqueta"){
           x.DATADI_TAMANIO="";
         }
         if( x.DATADI_TIPODATO=="Clob"){
          x.DATADI_TAMANIO="";
        }
        x.NUEVO=false;
       })
    });
    
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefNomDatosadi = {
      editable: true,
      width: 130,
      objeto: 'nomMaeDatosadit'
    };
  }
  manejarSenales(valor){
    switch (valor) {
      case 'Nuevo':
        this.opcionNuevo();
        break;
      case 'Guardar':
        this.opcionGuardar();
        break;
      case 'Borrar':
        this.opcionEliminar();
        break;
      case 'Cancelar':
        this.cargar();
        break;
      case 'Salir':
       this.init.tabs.splice(this.init.tabindex, 1);
        break;
    
    }
  }
  cambioModulo(event){
    this.btnGuardar = false;
    this.btnRegresar = false;
    this.btnNuevo = true;
   
    var camtipo:Nommaedatosadi=event.data;
    var tipo=event.newValue;
    const indice= this.nommaedatosadiService.nommaedatosadi.indexOf(camtipo);
    switch(tipo){
      case 'Etiqueta':
            this.nommaedatosadiService.nommaedatosadi[indice].DATADI_NOMBRECAMPO='';
            this.nommaedatosadiService.nommaedatosadi[indice].DATADI_ESTADO='';
            this.nommaedatosadiService.nommaedatosadi[indice].DATADI_TAMANIO='';
            this.aggrid.refreshaggrid(this.nommaedatosadiService.nommaedatosadi, this.defaultColDefNomDatosadi.objeto ); 
              this.aggrid.gridApi.setFocusedCell(indice , 'DATADI_ETIQUETA');
           break;
         case 'Varchar2':
          
           this.nommaedatosadiService.nommaedatosadi[indice].DATADI_NOMBRECAMPO='EMP_';
           this.nommaedatosadiService.nommaedatosadi[indice].DATADI_ESTADO='PEN';
         
          this.aggrid.refreshaggrid(this.nommaedatosadiService.nommaedatosadi, this.defaultColDefNomDatosadi.objeto ); 
          this.aggrid.gridApi.setFocusedCell(indice , 'DATADI_NOMBRECAMPO');
          
           break;
         case 'Number':
          
          this.nommaedatosadiService.nommaedatosadi[indice].DATADI_NOMBRECAMPO='EMP_';
          this.nommaedatosadiService.nommaedatosadi[indice].DATADI_ESTADO='PEN';
        
         this.aggrid.refreshaggrid(this.nommaedatosadiService.nommaedatosadi, this.defaultColDefNomDatosadi.objeto ); 
         this.aggrid.gridApi.setFocusedCell(indice , 'DATADI_NOMBRECAMPO');
           break;
         case 'Float':
          
          this.nommaedatosadiService.nommaedatosadi[indice].DATADI_NOMBRECAMPO='EMP_';
          this.nommaedatosadiService.nommaedatosadi[indice].DATADI_ESTADO='PEN';
        
         this.aggrid.refreshaggrid(this.nommaedatosadiService.nommaedatosadi, this.defaultColDefNomDatosadi.objeto ); 
         this.aggrid.gridApi.setFocusedCell(indice , 'DATADI_NOMBRECAMPO');
          break;
         case 'Date':
         
          this.nommaedatosadiService.nommaedatosadi[indice].DATADI_NOMBRECAMPO='EMP_';
          this.nommaedatosadiService.nommaedatosadi[indice].DATADI_ESTADO='PEN';
         this.aggrid.refreshaggrid(this.nommaedatosadiService.nommaedatosadi, this.defaultColDefNomDatosadi.objeto ); 
         this.aggrid.gridApi.setFocusedCell(indice , 'DATADI_NOMBRECAMPO');
          break;
        case 'Clob':
            
            this.nommaedatosadiService.nommaedatosadi[indice].DATADI_NOMBRECAMPO='EMP_';
            this.nommaedatosadiService.nommaedatosadi[indice].DATADI_ESTADO='PEN';
           this.aggrid.refreshaggrid(this.nommaedatosadiService.nommaedatosadi, this.defaultColDefNomDatosadi.objeto ); 
           this.aggrid.gridApi.setFocusedCell(indice , 'DATADI_NOMBRECAMPO');
            break;
         default:
           break;
    }
  }
  selectNomDatosadi(elemento){
    if (elemento.object !== null) {
      this.selectedNomMaeDatosAdi = elemento.object;
     
    }
     
  }
  isEdit(event): boolean {

    this.selectedNomMaeDatosAdi=event
    if(this.selectedNomMaeDatosAdi.DATADI_TIPODATO=='Etiqueta'){    
      if (this.aggrid.gridApi.getFocusedCell().column.colId == 'DATADI_ORDEN') { 
        return true;
      }
      if (this.aggrid.gridApi.getFocusedCell().column.colId == 'DATADI_TIPODATO') { 
        return true;
      }
      if (this.aggrid.gridApi.getFocusedCell().column.colId == 'DATADI_NOMBRECAMPO') {
          return false;
      }
      if (this.aggrid.gridApi.getFocusedCell().column.colId == 'DATADI_ETIQUETA') {
        return true;
      }
      if (this.aggrid.gridApi.getFocusedCell().column.colId == 'DATADI_TAMANIO') { 
        return false;
      }
    }else if(this.selectedNomMaeDatosAdi.DATADI_TIPODATO=='Blob') {
      if (this.aggrid.gridApi.getFocusedCell().column.colId == 'DATADI_ORDEN') { 
        return true;
      }
      if (this.aggrid.gridApi.getFocusedCell().column.colId == 'DATADI_TIPODATO') { 
        return true;
      }
      if (this.aggrid.gridApi.getFocusedCell().column.colId == 'DATADI_NOMBRECAMPO') {
        if(this.selectedNomMaeDatosAdi.NUEVO==false){
          return false;
        }else if(this.selectedNomMaeDatosAdi.NUEVO==true){
          return true;
        }
      }
      if (this.aggrid.gridApi.getFocusedCell().column.colId == 'DATADI_ETIQUETA') { 
        return true;
      }
      if (this.aggrid.gridApi.getFocusedCell().column.colId == 'DATADI_TAMANIO') { 
        return false;
      }
    }else{
      if (this.aggrid.gridApi.getFocusedCell().column.colId == 'DATADI_ORDEN') { 
        return true;
      }
      if (this.aggrid.gridApi.getFocusedCell().column.colId == 'DATADI_TIPODATO') { 
        return true;
      }
      if (this.aggrid.gridApi.getFocusedCell().column.colId == 'DATADI_NOMBRECAMPO') {
        if(this.selectedNomMaeDatosAdi.NUEVO==false){
          return false;
        }else if(this.selectedNomMaeDatosAdi.NUEVO==true){
          return true;
        }
      }
      if (this.aggrid.gridApi.getFocusedCell().column.colId == 'DATADI_ETIQUETA') { 
        return true;
      }
      if (this.aggrid.gridApi.getFocusedCell().column.colId == 'DATADI_TAMANIO') { 
        return true;
      }
    }
  }
  opcionNuevo(){
    var num=this.nommaedatosadiService.nommaedatosadi.length;
    var ultimo=0;
    if(num!=0){
     ultimo=parseInt(this.nommaedatosadiService.nommaedatosadi[num-1].DATADI_ORDEN);
   
    }
    
    const newModulo:  Nommaedatosadi = {  
      DATADI_ORDEN:this.nommaedatosadiService.padnum(ultimo+1),
      DATADI_NOMBRECAMPO:'',
      DATADI_ETIQUETA:'',
      DATADI_TIPODATO:'',
      DATADI_ESTADO:'PEN',
      DATADI_TAMANIO:'',
      NUEVO:true
    };
    try{
      this.nommaedatosadiService.nommaedatosadi.push(newModulo); 
      this.aggrid.refreshaggrid(this.nommaedatosadiService.nommaedatosadi, this.defaultColDefNomDatosadi.objeto ); 
      const indice= this.nommaedatosadiService.nommaedatosadi.indexOf(newModulo);
      
      this.aggrid.gridApi.setFocusedCell(indice , 'DATADI_ORDEN');
    }catch(err){
      console.log(err)
    }
  }
  opcionGuardar(){  
    this.aggrid.gridApi.stopEditing();
    var modulo: Nommaedatosadi={};
    if(this.selectedNomMaeDatosAdi.DATADI_TIPODATO=='Etiqueta'){
      
      modulo= {
        DATADI_ORDEN:this.selectedNomMaeDatosAdi.DATADI_ORDEN,
        DATADI_ETIQUETA:this.selectedNomMaeDatosAdi.DATADI_ETIQUETA
      }; 
    }else if(this.selectedNomMaeDatosAdi.DATADI_TIPODATO=='Clob'){
      modulo= {
        DATADI_NOMBRECAMPO:this.selectedNomMaeDatosAdi.DATADI_NOMBRECAMPO,
        DATADI_ORDEN:this.selectedNomMaeDatosAdi.DATADI_ORDEN,
        DATADI_ETIQUETA:this.selectedNomMaeDatosAdi.DATADI_ETIQUETA
      };
    }else{
      
      modulo= {
        DATADI_ORDEN:this.selectedNomMaeDatosAdi.DATADI_ORDEN,
        DATADI_NOMBRECAMPO:this.selectedNomMaeDatosAdi.DATADI_NOMBRECAMPO,
        DATADI_ETIQUETA:this.selectedNomMaeDatosAdi.DATADI_ETIQUETA,
        DATADI_TAMANIO:this.selectedNomMaeDatosAdi.DATADI_TAMANIO
      }; 
    }
   
    let valido = true;
    for ( const key in modulo ) { 
      if ( modulo[key] === null || modulo[key] === '') {
        valido = false;
      }
    }
    if ( valido ) {
      if(this.selectedNomMaeDatosAdi.DATADI_ESTADO=='PEN'){
        this.nommaedatosadiService.guardarDatosadi(this.selectedNomMaeDatosAdi).then(res=>{
          
          this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
          this.cargar();
          this.auditoriaGralService.registrarAuditoria('NOM_MAEDATOSADI', String(this.selectedNomMaeDatosAdi.DATADI_NOMBRECAMPO), 'I',
          '', '01', '', '', '', '').toPromise().then(() => {
          });
        }).catch(e=>{
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
            this.mensajeAlerta('error', 'Error', mensaje);
          }); 
      }else{
        this.mensajeAlerta('error', 'Error', 'No se puede realizar esa opción');
        this.cargar();
      }
    
    }else{
      this.mensajeAlerta('info', 'Información', 'Se han encontrado campos vacíos');
      
    }
  }
  opcionEliminar(){
    if ( this.selectedNomMaeDatosAdi !== undefined ) {
      if(this.selectedNomMaeDatosAdi.DATADI_ESTADO=='PEN'){       
        this.confirmationService.confirm({
          message: '¿Está seguro que desea eliminar este dato?',
          header: 'Confirmación',
          icon: 'pi pi-exclamation-triangle',
          key: 'dnonmaedatosadi',
          acceptLabel: 'Sí',
          rejectLabel: 'No',
          accept: () => {
            this.nommaedatosadiService.eliminarDatosadi(this.selectedNomMaeDatosAdi).then(res=>{
              this.mensajeAlerta('success', 'Información', '¡Modulo eliminado!');
              this.cargar();
              this.auditoriaGralService.registrarAuditoria('NOM_MAEDATOSADI', String(this.selectedNomMaeDatosAdi.DATADI_NOMBRECAMPO), 'E',
              '', '01', '', '', '', '').toPromise().then(() => {
              });
            }).catch(e=>{
              let mensaje: string;
              mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
              this.mensajeAlerta('error', 'Error', mensaje);
            }); 
          },
          reject: () => {
          }
        })
      }else{
        this.mensajeAlerta('error', 'Error', 'No se puede realizar esa opción');
        this.cargar();
      }

    }
  }
  cargar(){
    
    this.btnNuevo =  false;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.nommaedatosadiService.selectDatosadi().then(res=>{
      this.nommaedatosadiService.nommaedatosadi=res;
      this.nommaedatosadiService.nommaedatosadi.map(x=>{
        if(x.DATADI_TIPODATO=="Etiqueta"){
          x.DATADI_TAMANIO="";
        }
        if( x.DATADI_TIPODATO=="Clob"){
          x.DATADI_TAMANIO="";
        }
      })
   });
  }
  
 
   opcionAdicionales(){
   
      this.confirmationService.confirm({
        message: '¿Está seguro que desea aumentar los campos que estan en estado pendiente?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'dnonmaedatosopt',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: async () => {
            await this.nommaedatosadiService.CamposNuevos();
            this.cargar();
        },
        reject: () => {
        }
      })
    
  }
  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'tnonmaedatosadi',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
  }
}
