<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="bod"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarBod" title="Confirmar Acción"
                 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 99%">
  <div class="p-col-12">
    <app-invmaebarra [botonNuevo]="botonNuevo" [botonGuardar]="botonGuardar" [botonRegresar]="botonRegresar"
                     (signal)="manejarSenales($event)" [barraBotonesDesplaz]="barraBotones2" [barraBotones3]="barraBotones2"
                     [barraBotonesAsiCon]="barraBotones2" [barraBotones5]="true" [botonBorrar]="false"></app-invmaebarra>
  </div>
  <div style="overflow: hidden" class="p-col-12">
    <p-tabView>
      <p-tabPanel header="Bodega">
        <app-aggridsaci [width]=""
                        [height]="largo"
                        [enableSorting]="false"
                        [enableFilter]="true"
                        [rowData]="bodegaSer.bodegas"
                        [frameworkComponents]="frameworkComponents"
                        [animateRows]="true"
                        [rowSelection]="rowSelection"
                        [columnDefs]="columnDefsBodega"
                        [defaultColDef]="defaultColDefBodega"
                        [rowStyle]="rowStyle"
                        [singleClickEdit]="false"
                        [botones]="false"
                        (selected)="seleccionBodega($event)"
                        (cambios)="cambio($event)"

        ></app-aggridsaci>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>

