<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="conDistriCC"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="conDistriCCconf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra (signal)="manejarSenales($event)" [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar"
						 [botonBorrar]="btnBorrar" [botonRegresar]="btnRegresar"
						 [barraBotonesDesplaz]="true" [barraBotones3]="true"
						 [barraBotonesAsiCon]="true" [barraBotones5]="false"
						 [check]="false"></app-invmaebarra>
	</div>
</div>
<div class="divgrups">
	<div class="p-grid">
		<div class="p-col-2">
			<label for="ARTICULO"></label>
			<input class="frm" id="ARTICULO" type="text" pInputText  readonly
				   autocomplete="off" [(ngModel)]="strCodigo">
		</div>
		<div class="p-col-4">
			<label for="NOMBRE"></label>
			<input class="frm" id="NOMBRE" type="text" pInputText readonly
				   autocomplete="off" [(ngModel)]="strDescripcion">
		</div>
		<div class="p-col-2">
		</div>
		<div class="p-col-1">
			<span>Plantilla:</span>
		</div>
		<div class="p-col-1">
			<input class="frm" id="PLANTILLA" type="text" pInputText
				   autocomplete="off" [(ngModel)]="TrnprocenCodigo">
		</div>
		<div class="p-col-1">
			<button type="button" style="background-image: url(../../../assets/images/iconos/buscar.png);"
					class="littlebuttons"  (click)= "buscarDatos()"></button>
		</div>
		<div class="p-col-1">
		</div>
		<div class="p-col-1" style="text-align: left">
			<span style="color:mediumblue;">Importe:</span>
		</div>
		<div class="p-col-1" style="text-align: left">
			<label for="IMPORTE"></label>
			<input class="frm" id="IMPORTE" type="text" pInputText readonly
				   autocomplete="off" [(ngModel)]="dblValor">
		</div>
		<div class="p-col-5">
		</div>
		<div class="p-col-3">
			<input type="button" value="Distribución Automática" (click)="listarDatosAutomatica()"  style="width: 150px; height: 25px" >
		</div>
		<div class="p-col-1">
		</div>
		<div class="p-col-12">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="condistribccService.detDistribcc"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsDistribCC"
							[defaultColDef]="defaultDistribCC"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							[mostrarGuardar]="false"
							(selected)="seleccionDetDistribCC($event)"
							(cambios)="cambio($event)">
			</app-aggridsaci>
		</div>
		<div class="p-col-8">
		</div>
		<div class="p-col-1">
			<span>Total:</span>
		</div>
		<div class="p-col-2">
			<label for="TOTAL"></label>
			<input class="frm" id="TOTAL" type="text" pInputText disabled
				   autocomplete="off" [(ngModel)]="condistribccService.totalDist">
		</div>
	</div>
</div>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBus"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [closable]="false"
		  [style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta] = "consulta" [tabla]="tabla" [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>
