import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { VenTrnreembolsos } from '../veninterfaces/ventrnreembolsos';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { VentrnreembolsosService } from '../venservicios/ventrnreembolsos.service';
import { DatePipe } from '@angular/common';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';


@Component({
  selector: 'app-ventrnreembolsos',
  templateUrl: './ventrnreembolsos.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})

export class VentrnreembolsosComponent implements OnInit, OnChanges {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  @Input() auxiliar: any;
  @Input() strNumFact: string;
  @Input() ivavigente:number;
  @Output() cerrar = new EventEmitter<boolean>();

  trnreembolsos: VenTrnreembolsos;
  listtrnreembolsos: VenTrnreembolsos[];

  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  barraBotones2: boolean;

  displayDialogBusqueda: boolean;
  displayDialogBusquedaFast: boolean;

  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];
  tabla: string;
  where: string;
  opcionbusqueda: string;
  consulta: string;

  cmbtipogasto: any[];
  selecttipogasto: any;

  largo: string;

  valida: boolean;
  strbienmal: string;
  valorRucI: boolean;
  valorcedulaI: boolean;

  TotalBaseIva: string;
  TotalBaseCero: string;
  TotalBaseNoGrab: string;
  TotalValorIva: string;

  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  defaultColDefReemb;

  columnDefsReemb = [
    {
      headerName: 'TId', field: 'TRNREE_TIPOIDEPRO', width: 100
    },
    {
      headerName: 'Núm.Ide', field: 'TRNREE_IDEPRO', width: 120
    },
    {
      headerName: 'TipoC', field: 'TRNREE_TIPCOMP', width: 50
    },
    {
      headerName: 'SEst', field: 'TRNREE_SERIEEST', width: 100
    },
    {
      headerName: 'SPto', field: 'TRNREE_SERIEPTOEMI', width: 100
    },
    {
      headerName: 'SecComp', field: 'TRNREE_SECCOMPVTA', width: 100
    },
    {
      headerName: 'EmisionC', field: 'TRNREE_FECHAEMISIONSTR', width: 200
    },
    {
      headerName: 'AutComp', field: 'TRNREE_AUTORIZACION', width: 100
    },
    {
      headerName: 'BaseImp', field: 'TRNREE_BASECERO', width: 100
    },
    {
      headerName: 'BaseImpGra', field: 'TRNREE_BASEIVA', width: 100
    },
    {
      headerName: 'BaseNoGraIv', field: 'TRNREE_BASENOIVA', width: 100
    },
    {
      headerName: 'PorIva', field: 'TRNREE_PORCENTAJEIVA', width: 100
    },
    {
      headerName: 'MontoIce', field: 'TRNREE_MONTOICE', width: 100
    },
    {
      headerName: 'MontoIva', field: 'TRNREE_MONTOIVA', width: 100
    },
    /*{
      headerName: 'Empleado', field: 'TRNREE_EMPLEADO', width: 200
    },
    {
      headerName: 'Tipo Gasto', field: 'TRNREE_TIPGAS', width: 200
    }*/
  ];


  constructor(private trnreembService: VentrnreembolsosService, private busqService: InvbusquedaService,
    private datePipe: DatePipe, private messageService: MessageService, private confirmationService: ConfirmationService,
    private errorService: ErroresBaseDatosService,
    private auditoriagral: AuditoriagralService, private confIniciales: ConfInicialesService, private init: NuevoComponentService) {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultColDefReemb = {
      editable: false,
      width: 100,
      objeto: 'trnreembolsos'
    };
  }

  async ngOnInit() {
    this.largo = '650';

    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = true;
    this.botonRegresar = true;
    this.barraBotones2 = true;

    await this.nuevoRegistro();
    await this.llenarCombos();

    this.TotalBaseIva = '';
    this.TotalBaseCero = '';
    this.TotalBaseNoGrab = '';
    this.TotalValorIva = '';
    this.valida = true;
    this.strbienmal = '';
  }

 async ngOnChanges(changes: SimpleChanges) {
    for (const e in changes) {
      if (e === 'auxiliar' && this.strNumFact !== undefined) {
        await this.nuevoRegistro()
        this.consultarDatos(this.strNumFact);
        this.botonNuevo = false;
        this.botonGuardar = true;
        this.botonBorrar = true;
        this.botonRegresar = true;
      }
    }
  }

  consultarDatos(encfacnumero) {
    this.trnreembService.getReembolsos(encfacnumero).subscribe(data => {
      this.listtrnreembolsos = data;
      for (const reembolso of this.listtrnreembolsos) {
        reembolso.TRNREE_FECHAEMISIONSTR = this.datePipe.transform(reembolso.TRNREE_FECHAEMISION, 'dd/MM/yyyy');
        reembolso.TRNREE_FECHAEMISION = new Date(reembolso.TRNREE_FECHAEMISION);

        reembolso.TRNREE_BASECERO = Number(reembolso.TRNREE_BASECERO).toFixed(this.confIniciales.getNumDecSist());
        reembolso.TRNREE_BASEIVA = Number(reembolso.TRNREE_BASEIVA).toFixed(this.confIniciales.getNumDecSist());
        reembolso.TRNREE_BASENOIVA = Number(reembolso.TRNREE_BASENOIVA).toFixed(this.confIniciales.getNumDecSist());
        reembolso.TRNREE_PORCENTAJEIVA = Number(reembolso.TRNREE_PORCENTAJEIVA).toFixed(this.confIniciales.getNumDecSist());
        reembolso.TRNREE_MONTOICE = Number(reembolso.TRNREE_MONTOICE).toFixed(this.confIniciales.getNumDecSist());
        reembolso.TRNREE_MONTOIVA = Number(reembolso.TRNREE_MONTOIVA).toFixed(this.confIniciales.getNumDecSist());
        reembolso.TRNREE_BASEIMPEXEREEMB = Number(reembolso.TRNREE_BASEIMPEXEREEMB).toFixed(this.confIniciales.getNumDecSist());

      }
      this.calculartotales();
    });
  }

  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      this.nuevoRegistro();
    }

    if (valor === 'Guardar') {
      this.guardarRegistro();
    }

    if (valor === 'Borrar') {
      this.eliminarRegistro();
    }

    if (valor === 'Cancelar') {
      this.cancelarRegistro();
    }

    if (valor === 'Salir') {
      this.cerrar.emit(true);
    }
  }

  cancelarRegistro() {
    this.consultarDatos(this.strNumFact);
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
  }

  eliminarRegistro() {
    this.confirmationService.confirm({
      message: 'Está seguro de eliminar el registro?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'reembconf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        const item: VenTrnreembolsos = {};

        item.ENCFAC_NUMERO = this.strNumFact;
        item.COM_CODIGO = '01';
        item.TRNREE_IDEPRO = this.trnreembolsos.TRNREE_IDEPRO;
        item.TRNREE_SERIEEST = this.trnreembolsos.TRNREE_SERIEEST;
        item.TRNREE_SERIEPTOEMI = this.trnreembolsos.TRNREE_SERIEPTOEMI;
        item.TRNREE_SECCOMPVTA = this.trnreembolsos.TRNREE_SECCOMPVTA;

        this.trnreembService.eliminarReembolso(item).subscribe(data => {
          this.nuevoRegistro();
          this.cancelarRegistro();

          this.messageService.add({
            key: 'reemb',
            severity: 'success',
            summary: 'Información',
            detail: 'Reembolso eliminado'
          });

        });

      },
      reject: () => {
        this.confirmationService.close();
      }
    });

  }

  async nuevoRegistro() {
    console.log('ivavigente',this.ivavigente)
    this.trnreembolsos = {
      ENCFAC_NUMERO: this.strNumFact,
      COM_CODIGO: '01',
      TRNREE_TIPOIDEPRO: '',
      TRNREE_IDEPRO: '',
      TRNREE_TIPCOMP: '',
      TRNREE_SERIEEST: '',
      TRNREE_SERIEPTOEMI: '',
      TRNREE_SECCOMPVTA: '',
      TRNREE_FECHAEMISION: new Date(),
      TRNREE_AUTORIZACION: '',
      TRNREE_BASECERO: '0.00',
      TRNREE_BASEIVA: '0.00',
      TRNREE_BASENOIVA: '0.00',
      TRNREE_PORCENTAJEIVA: Number(this.ivavigente).toFixed(2),
      TRNREE_MONTOICE: '0.00',
      TRNREE_MONTOIVA: '0.00',
      TRNREE_BASEIMPEXEREEMB: '0.00',
      TRNREE_CODPAISPAGO: '593',
      TRNREE_PROTIPO: '',
      // TRNREE_EMPLEADO: '',
      // TRNREE_TIPGAS: '',
      TRNREE_TIPOIDEPRONOMBRE: '',
      TRNREE_PROTIPONOMBRE: '',
      TRNREE_PAISPAGONOMBRE: '',
      TRNREE_TIPCOMPNOMBRE: '',
      TRNREE_EMPLEADONOMBRE: '',
    };

    this.selecttipogasto = { codigo: '', name: '' };
    this.cambio();
  }

  guardarRegistro() {

    if (this.trnreembolsos.TRNREE_TIPOIDEPRONOMBRE === '') {
      this.messageService.add({
        key: 'reemb',
        severity: 'warn',
        summary: 'Información',
        detail: 'Tipo de identificación incorrecto'
      });
      return;
    }

    if (this.trnreembolsos.TRNREE_TIPCOMPNOMBRE === '') {
      this.messageService.add({
        key: 'reemb',
        severity: 'warn',
        summary: 'Información',
        detail: 'Tipo de comprobante incorrecto'
      });
      return;
    }

    if (this.trnreembolsos.TRNREE_AUTORIZACION === '') {
      this.messageService.add({
        key: 'reemb',
        severity: 'warn',
        summary: 'Información',
        detail: 'Número de autorización incorrecto'
      });
      return;
    }
 //CDPJ
 if (Number(this.trnreembolsos.TRNREE_BASECERO) === 0 
 && Number(this.trnreembolsos.TRNREE_BASEIVA) === 0 &&
 Number(this.trnreembolsos.TRNREE_BASENOIVA) === 0) {
   this.messageService.add({
     key: 'reemb',
     severity: 'warn',
     summary: 'Información',
     detail: 'Los campos Base Imponible tarifa 0% IVA, Base Imponible tarifa IVA diferente de 0%,'+
     'Base Imponible no objeto de IVA. No pueden ser iguales a cero, debe ingresar un valor en almenos uno de ellos.'
   });
   return;
 }
 //CDPJ
    const item: VenTrnreembolsos = {};
    item.ENCFAC_NUMERO = this.strNumFact;
    item.COM_CODIGO = this.trnreembolsos.COM_CODIGO;
    item.TRNREE_TIPOIDEPRO = this.trnreembolsos.TRNREE_TIPOIDEPRO;
    item.TRNREE_IDEPRO = this.trnreembolsos.TRNREE_IDEPRO;
    item.TRNREE_TIPCOMP = this.trnreembolsos.TRNREE_TIPCOMP;
    item.TRNREE_SERIEEST = this.trnreembolsos.TRNREE_SERIEEST;
    item.TRNREE_SERIEPTOEMI = this.trnreembolsos.TRNREE_SERIEPTOEMI;
    item.TRNREE_SECCOMPVTA = this.trnreembolsos.TRNREE_SECCOMPVTA;
    item.TRNREE_FECHAEMISION = this.datePipe.transform(this.trnreembolsos.TRNREE_FECHAEMISION, 'dd/MM/yyyy');
    item.TRNREE_AUTORIZACION = this.trnreembolsos.TRNREE_AUTORIZACION;
    item.TRNREE_BASECERO = Number(this.trnreembolsos.TRNREE_BASECERO);
    item.TRNREE_BASEIVA = Number(this.trnreembolsos.TRNREE_BASEIVA);
    item.TRNREE_BASENOIVA = Number(this.trnreembolsos.TRNREE_BASENOIVA);
    item.TRNREE_PORCENTAJEIVA = Number(this.trnreembolsos.TRNREE_PORCENTAJEIVA);
    item.TRNREE_MONTOICE = Number(this.trnreembolsos.TRNREE_MONTOICE);
    item.TRNREE_MONTOIVA = Number(this.trnreembolsos.TRNREE_MONTOIVA);
    item.TRNREE_BASEIMPEXEREEMB = Number(this.trnreembolsos.TRNREE_BASEIMPEXEREEMB);
    item.TRNREE_CODPAISPAGO = this.trnreembolsos.TRNREE_CODPAISPAGO;
    item.TRNREE_PROTIPO = this.trnreembolsos.TRNREE_PROTIPO;
    item.TRNREE_CODREEMB = Number(this.trnreembolsos.TRNREE_CODREEMB);//GSRF
    // item.TRNREE_EMPLEADO = this.trnreembolsos.TRNREE_EMPLEADO;
    // item.TRNREE_TIPGAS = this.selecttipogasto.codigo; 


    this.trnreembService.insertarReembolso(item).subscribe(data => {

      for (const rs of data) {
        if (rs['NVL(:B1,0)'] === 0) {

        } else {

        }
      }

      this.messageService.add({
        key: 'reemb',
        severity: 'success',
        summary: 'Información',
        detail: 'Reembolso guardado correctamente'
      });
      //this.nuevoRegistro();//GSRF
      this.cancelarRegistro();
    });

  }

  abrirTIPOIDEPRO() {
    this.opcionbusqueda = 'TRNREE_TIPOIDEPRO';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código CON', value: 'CON_CODIGO' }
    ];
    this.busquedacampos = [this.trnreembolsos.TRNREE_TIPOIDEPRO, '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.consulta = 'CODSRI_CODIGO,CODSRI_DESCRIPCION,CODSRI_TIPO,CODSRI_ANIO,CODSRI_REF,CON_CODIGO';
    this.where = 'CODSRI_TIPO=\'TI\'';

    this.trnreembService.erabrirTIPOIDEPRO(this.trnreembolsos.TRNREE_TIPOIDEPRO).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.trnreembolsos.TRNREE_TIPOIDEPRONOMBRE = eR[0].CODSRI_DESCRIPCION;
          document.getElementById('TRNREE_IDEPRO').focus();
        } else {
          this.busqService.busquedaAnexMaeCodSri12(this.where, this.trnreembolsos.TRNREE_TIPOIDEPRO).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaAnexMaeCodSri12(this.where, this.trnreembolsos.TRNREE_TIPOIDEPRO).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });

  }

  abrirPROTIPO() {
    this.opcionbusqueda = 'TRNREE_PROTIPO';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código CON', value: 'CON_CODIGO' }
    ];
    this.busquedacampos = [this.trnreembolsos.TRNREE_PROTIPO, '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.consulta = 'CODSRI_CODIGO,CODSRI_DESCRIPCION,CODSRI_TIPO,CODSRI_ANIO,CODSRI_REF,CON_CODIGO';
    this.where = 'CODSRI_TIPO=\'TP\'';

    this.trnreembService.erabrirPROTIPO(this.trnreembolsos.TRNREE_PROTIPO).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaAnexMaeCodSri12(this.where, this.trnreembolsos.TRNREE_PROTIPO).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaAnexMaeCodSri12(this.where, this.trnreembolsos.TRNREE_PROTIPO).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }


  abrirPAISPAGO() {
    this.opcionbusqueda = 'TRNREE_CODPAISPAGO';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código CON', value: 'CON_CODIGO' }
    ];
    this.busquedacampos = [this.trnreembolsos.TRNREE_CODPAISPAGO, '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.consulta = 'CODSRI_CODIGO,CODSRI_DESCRIPCION,CODSRI_TIPO,CODSRI_ANIO,CODSRI_REF,CON_CODIGO';
    this.where = 'CODSRI_TIPO=\'PA\'';

    this.trnreembService.erabrirPAISPAGO(this.trnreembolsos.TRNREE_CODPAISPAGO).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaAnexMaeCodSri12(this.where, this.trnreembolsos.TRNREE_CODPAISPAGO).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaAnexMaeCodSri12(this.where, this.trnreembolsos.TRNREE_CODPAISPAGO).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  abrirTIPCOMP() {
    this.opcionbusqueda = 'TRNREE_TIPCOMP';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código CON', value: 'CON_CODIGO' }
    ];
    this.busquedacampos = [this.trnreembolsos.TRNREE_TIPCOMP, '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.consulta = 'CODSRI_CODIGO,CODSRI_DESCRIPCION,CODSRI_TIPO,CODSRI_ANIO,CODSRI_REF,CON_CODIGO';
    this.where = 'CODSRI_TIPO=\'TC\'';

    this.trnreembService.erabrirTIPCOMP(this.trnreembolsos.TRNREE_TIPCOMP).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaAnexMaeCodSri12(this.where, this.trnreembolsos.TRNREE_TIPCOMP).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaAnexMaeCodSri12(this.where, this.trnreembolsos.TRNREE_TIPCOMP).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  /*abrirEMPLEADO() {
    this.opcionbusqueda = 'TRNREE_EMPLEADO';
    this.types = [
      { label: 'CÓDIGO', value: 'REF_CODIGO' },
      { label: 'NOMBRE', value: 'REF_NOMBRE' }
    ];
    this.busquedacampos = [this.trnreembolsos.TRNREE_EMPLEADO, ''];
    this.tabla = 'SACI_MAEREFERENCIA';
    this.consulta = '*';
    this.where = 'REF_TIPO=\'EMPLE\'';
    this.trnreembService.erabrirEMPLEADO(this.trnreembolsos.TRNREE_EMPLEADO).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaSaciMaeReferencia3(this.trnreembolsos.TRNREE_EMPLEADO).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReferencia3(this.trnreembolsos.TRNREE_EMPLEADO).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }*/


  manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'TRNREE_TIPOIDEPRO') {
      this.trnreembolsos.TRNREE_TIPOIDEPRO = opcion.CODSRI_CODIGO;
      this.trnreembolsos.TRNREE_TIPOIDEPRONOMBRE = opcion.CODSRI_DESCRIPCION;
      document.getElementById('TRNREE_IDEPRO').focus();
    }

    if (this.opcionbusqueda === 'TRNREE_PROTIPO') {
      this.trnreembolsos.TRNREE_PROTIPO = opcion.CODSRI_CODIGO;
      this.trnreembolsos.TRNREE_PROTIPONOMBRE = opcion.CODSRI_DESCRIPCION;
      document.getElementById('TRNREE_CODPAISPAGO').focus();
    }

    if (this.opcionbusqueda === 'TRNREE_CODPAISPAGO') {
      this.trnreembolsos.TRNREE_CODPAISPAGO = opcion.CODSRI_CODIGO;
      this.trnreembolsos.TRNREE_PAISPAGONOMBRE = opcion.CODSRI_DESCRIPCION;
      document.getElementById('TRNREE_TIPCOMP').focus();
    }

    if (this.opcionbusqueda === 'TRNREE_TIPCOMP') {
      this.trnreembolsos.TRNREE_TIPCOMP = opcion.CODSRI_CODIGO;
      this.trnreembolsos.TRNREE_TIPCOMPNOMBRE = opcion.CODSRI_DESCRIPCION;
      document.getElementById('TRNREE_SERIEEST').focus();
    }

    /*if (this.opcionbusqueda === 'TRNREE_EMPLEADO') {
      this.trnreembolsos.TRNREE_EMPLEADO = opcion.REF_CODIGO;
      this.trnreembolsos.TRNREE_EMPLEADONOMBRE = opcion.REF_NOMBRE;
      document.getElementById('TRNREE_TIPGAS').focus();
    }*/

    this.displayDialogBusqueda = false;
    this.displayDialogBusquedaFast = false;
  }

  setFocus(event) {
    let element: any;

    switch (event.target.id) {
      case 'TRNREE_TIPOIDEPRO': {
        this.abrirTIPOIDEPRO();
        break;
      }
      case 'TRNREE_IDEPRO': {
        this.validarIdentificacion(event);
        element = document.getElementById('TRNREE_PROTIPO');
        break;
      }
      case 'TRNREE_PROTIPO': {
        this.abrirPROTIPO();
        break;
      }
      case 'TRNREE_CODPAISPAGO': {
        this.abrirPAISPAGO();
        break;
      }
      case 'TRNREE_TIPCOMP': {
        this.abrirTIPCOMP();
        break;
      }
      case 'TRNREE_SERIEEST': {
        element = document.getElementById('TRNREE_SERIEPTOEMI');
        break;
      }
      case 'TRNREE_SERIEPTOEMI': {
        element = document.getElementById('TRNREE_SECCOMPVTA');
        break;
      }
      case 'TRNREE_SECCOMPVTA': {
        this.trnreembolsos.TRNREE_SECCOMPVTA = this.autoCompletar(this.trnreembolsos.TRNREE_SECCOMPVTA, 9);
        element = document.getElementById('TRNREE_AUTORIZACION');
        break;
      }
      case 'TRNREE_FECHAEMISION': {
        element = document.getElementById('TRNREE_AUTORIZACION');
        break;
      }
      case 'TRNREE_AUTORIZACION': {
        element = document.getElementById('TRNREE_BASECERO');
        break;
      }
      case 'TRNREE_BASECERO': {
        element = document.getElementById('TRNREE_BASEIMPEXEREEMB');
        break;
      }
      case 'TRNREE_BASEIMPEXEREEMB': {
        element = document.getElementById('TRNREE_BASEIVA');
        break;
      }
      case 'TRNREE_BASEIVA': {
        this.calculariva();
        element = document.getElementById('TRNREE_BASENOIVA');
        break;
      }
      case 'TRNREE_BASENOIVA': {
        element = document.getElementById('TRNREE_PORCENTAJEIVA');
        break;
      }
      case 'TRNREE_PORCENTAJEIVA': {
        this.calculariva();
        element = document.getElementById('TRNREE_MONTOICE');
        break;
      }
      case 'TRNREE_MONTOICE': {
        element = document.getElementById('TRNREE_MONTOIVA');
        break;
      }
      case 'TRNREE_MONTOIVA': {
        element = document.getElementById('TRNREE_EMPLEADO');
        break;
      }
      /*case 'TRNREE_EMPLEADO': {
        this.abrirEMPLEADO();
        break;
      }*/
      case 'TRNREE_TIPGAS': {
        element = document.getElementById('TRNREE_TIPOIDEPRO');
        break;
      }
    }

    if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }
  }

  llenarCombos() {
    this.cmbtipogasto = [];
    this.trnreembService.ecllenarConsulta().subscribe(data => {
      for (const rs of data) {
        this.cmbtipogasto.push({ codigo: rs.REF_CODIGO, name: rs.REF_NOMBRE });
      }
    });
  }


  /*busqtipogasto(tg) {
    this.trnreembolsos.TRNREE_TIPGAS = tg.value.codigo;
  }*/

  async selecciontrnreembolsos(params) {
    if (params.object !== undefined) {
      await this.nuevoRegistro();
      this.trnreembolsos = params.object;

      this.mostrarDatos();
      this.botonNuevo = false;
      this.botonGuardar = true;
      this.botonBorrar = false;
      this.botonRegresar = false;
    }
  }

  mostrarDatos() {

    this.trnreembolsos.TRNREE_BASECERO = Number(this.trnreembolsos.TRNREE_BASECERO).toFixed(this.confIniciales.getNumDecSist());
    this.trnreembolsos.TRNREE_BASEIVA = Number(this.trnreembolsos.TRNREE_BASEIVA).toFixed(this.confIniciales.getNumDecSist());
    this.trnreembolsos.TRNREE_BASENOIVA = Number(this.trnreembolsos.TRNREE_BASENOIVA).toFixed(this.confIniciales.getNumDecSist());
    this.trnreembolsos.TRNREE_PORCENTAJEIVA = Number(this.trnreembolsos.TRNREE_PORCENTAJEIVA).toFixed(this.confIniciales.getNumDecSist());
    this.trnreembolsos.TRNREE_MONTOICE = Number(this.trnreembolsos.TRNREE_MONTOICE).toFixed(this.confIniciales.getNumDecSist());
    this.trnreembolsos.TRNREE_MONTOIVA = Number(this.trnreembolsos.TRNREE_MONTOIVA).toFixed(this.confIniciales.getNumDecSist());
    this.trnreembolsos.TRNREE_BASEIMPEXEREEMB = Number(this.trnreembolsos.TRNREE_BASEIMPEXEREEMB).toFixed(this.confIniciales.getNumDecSist());


    /*this.trnreembService.ermostrarDatos(this.trnreembolsos.TRNREE_EMPLEADO).subscribe(er => {
      if (er !== null) {
        if (er[0] !== undefined) {
          this.trnreembolsos.TRNREE_EMPLEADONOMBRE = er[0].REF_NOMBRE;
        }
      }
    });

    for (const combo of this.cmbtipogasto) {
      if (combo.name === this.trnreembolsos.TRNREE_TIPGAS) {
        this.selecttipogasto = { codigo: combo.codigo, name: combo.name };
      }
    }*/

    this.trnreembService.ercodsriTIPOIDEPRO(this.trnreembolsos.TRNREE_TIPOIDEPRO).subscribe(er => {
      if (er !== null) {
        if (er[0] !== undefined) {
          this.trnreembolsos.TRNREE_TIPOIDEPRONOMBRE = er[0].CODSRI_DESCRIPCION;
        }
      }
    });

    this.trnreembService.erpaispagocodsri(this.trnreembolsos.TRNREE_CODPAISPAGO).subscribe(er => {
      if (er !== null) {
        if (er[0] !== undefined) {
          this.trnreembolsos.TRNREE_PAISPAGONOMBRE = er[0].CODSRI_DESCRIPCION;
        }
      }
    });

    this.trnreembService.ertipcompcodsri(this.trnreembolsos.TRNREE_TIPCOMP).subscribe(er => {
      if (er !== null) {
        if (er[0] !== undefined) {
          this.trnreembolsos.TRNREE_TIPCOMPNOMBRE = er[0].CODSRI_DESCRIPCION;
        }
      }
    });

    this.trnreembService.erprotipocodsri(this.trnreembolsos.TRNREE_PROTIPO).subscribe(er => {
      if (er !== null) {
        if (er[0] !== undefined) {
          this.trnreembolsos.TRNREE_PROTIPONOMBRE = er[0].CODSRI_DESCRIPCION;
        }
      }
    });
    //CDPJ
   console.log(this.trnreembolsos.TRNREE_TIPOIDEPRO,this.trnreembolsos.TRNREE_IDEPRO);
   if(this.trnreembolsos.TRNREE_TIPOIDEPRO === '02'){
     this.validarIdeExiste(2,this.trnreembolsos.TRNREE_IDEPRO);
   }
   if(this.trnreembolsos.TRNREE_TIPOIDEPRO === '01'){
     this.validarIdeExiste(1,this.trnreembolsos.TRNREE_IDEPRO);
   }
   //CDPJ
    this.calculartotales();
  }


  validarIdeExiste(ide, codigocliente) {
    this.validarIde(ide, codigocliente);

    if (this.valorcedulaI === true && this.valorRucI === true) {
      this.messageService.add({
        key: 'reemb',
        severity: 'warn',
        summary: 'Información',
        detail: 'Identificación no valida'
      });

      this.valida = true;
      this.strbienmal = 'Mal';
    } else {
      this.valida = false;
      this.strbienmal = 'Bien';
    }
  }

  validarIde(ide, codigocliente) {
    this.valorRucI = true;
    this.valorcedulaI = true;

    if (ide === 2) {
      let total = 0;
      const longitud = codigocliente.length;
      const longcheck = longitud - 1;

      if (codigocliente !== '' && longitud <= 10) {
        for (let i = 0; i < longcheck; i++) {
          if (i % 2 === 0) {
            let aux = Number(codigocliente.charAt(i)) * 2;
            if (aux > 9) {
              aux -= 9;
            }
            total += aux;
          } else {
            total += parseInt(codigocliente.charAt(i)); // parseInt o concatenar� en lugar de sumar
          }
        }

        total = total % 10 ? 10 - total % 10 : 0;

        this.valorcedulaI = Number(codigocliente.charAt(longitud - 1)) !== total;
      }
    }
    if (ide === 1) {
      const numero = codigocliente;
      const long = numero.length;
      let valor;
      let acu = 0;
      if (numero === '') {
        this.valorRucI = true;
      } else {
        for (let i = 0; i < long; i++) {
          valor = numero.substr(i, i + 1);
          if (valor === 0 || 1 || 2 || 3 || 4 || 5 || 6 || 7
            || 8 || 9) {
            acu = acu + 1;
          }
        }
        if (acu === long) {
          while (numero.substr(10, 13) !== '001') {
            this.valorRucI = true;
            return;
          }
          while (Number(numero.substr(0, 2)) > 24) {
            this.valorRucI = true;
            return;
          }
          this.valorRucI = false;
          const porcion1 = numero.substr(2, 3);
          if (Number(porcion1) < 6) {
            this.valorRucI = false;
          } else {
            if (Number(porcion1) === 6) {
              this.valorRucI = false;
            } else {
              if (Number(porcion1) === 9) {
                this.valorRucI = false;
              }
            }
          }
        } else {
          this.valorRucI = true;
        }
      }
    }
  }


  autoCompletar(strCadena, intTamanioCadena): string {
    let intAux = 0;
    let indice = 0;
    let strAutoCompletar = '';

    intAux = strCadena.length;
    for (indice = 1; indice <= intTamanioCadena - intAux; indice++) {
      strAutoCompletar = '0' + strAutoCompletar;
    }

    strAutoCompletar = strAutoCompletar + strCadena;

    return strAutoCompletar;
  }

  calculariva() {
    let calc = 0.0;
    calc = (Number(this.trnreembolsos.TRNREE_BASEIVA)
      * Number(this.trnreembolsos.TRNREE_PORCENTAJEIVA) / 100);
    this.trnreembolsos.TRNREE_MONTOIVA = calc.toFixed(2);
  }

  calculartotales() {
    let dblTotalBaseIva = 0;
    let dblTotalBaseCero = 0;
    let dblTotalBaseNoGrab = 0;
    let dblTotalValorIva = 0;

    for (let i = 0; i < this.listtrnreembolsos.length; i++) {
      dblTotalBaseIva = dblTotalBaseIva
        + Number(this.listtrnreembolsos[i].TRNREE_BASEIVA);
      dblTotalBaseCero = dblTotalBaseCero
        + Number(this.listtrnreembolsos[i].TRNREE_BASECERO);
      dblTotalBaseNoGrab = dblTotalBaseNoGrab
        + Number(this.listtrnreembolsos[i].TRNREE_BASENOIVA);
      dblTotalValorIva = dblTotalValorIva
        + Number(this.listtrnreembolsos[i].TRNREE_MONTOIVA);
    }

    this.TotalBaseIva = dblTotalBaseIva.toFixed(2);
    this.TotalBaseCero = dblTotalBaseCero.toFixed(2);
    this.TotalBaseNoGrab = dblTotalBaseNoGrab.toFixed(2);
    this.TotalValorIva = dblTotalValorIva.toFixed(2);
  }


  cambio() {
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = false;
  }

  validarIdentificacion(event) {

    if (this.trnreembolsos.TRNREE_TIPOIDEPRO === '01') {
      this.validarIdeExiste(1, event.target.value);
    } else if (this.trnreembolsos.TRNREE_TIPOIDEPRO === '02') {
      this.validarIdeExiste(2, event.target.value);
    } else {
      this.valida = false;
      this.strbienmal = 'Bien';
    }
  }
}

