<p-toast [style]="{marginTop: '41px'}" position="top-center" key="cxpcuota"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="cxpcuotasconf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra (signal)="manejarSenales($event)" [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar"
						 [botonBorrar]="btnEliminar" [botonRegresar]="false"
						 [barraBotonesDesplaz]="true" [barraBotones3]="true"
						 [barraBotonesAsiCon]="false" [barraBotones5]="false"
						 [botonBuscar]="btnBuscar" [barraBotones3]="true"
						 [botonBinocular]="true"
						 [botonAsiento]="false"
						 [botonverAsiento]="false"
						 [check]="btnGenerarCuot"
						 [botonAlmacenardoc] = "true"></app-invmaebarra>
	</div>
</div>

<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px; width: 99%;">
	<div class="p-col-5"></div>
	<div class="p-col-1">
	</div>
	<div class="p-col-4"></div>
	<div class="p-col-2" style="margin-bottom: 5px;">
		<input  type="text" pInputText style="background-color: #EFFFF7"
				[disabled]="true"
				[(ngModel)]="cxpCuotaService.encCuotaArray[indicador].ASI_NR0"
				autocomplete="off">
	</div>
</div>

<div class="divgrups" id="divPrincipal">
	<div class="p-grid">
		<div class="p-col-1" style="margin-bottom: 5px;">
			<input type="text" pInputText
				   style=" text-align: center; color: rgb(0,0,255); font-weight: bold"
				   disabled [(ngModel)]="cxpCuotaService.encCuotaArray[indicador].ENCCUOTA_CODIGO">
		</div>
		<div class="p-col-12">
			<span>Documento</span>
		</div>
		<div class="p-col-12" style="margin-top: -15px">
			<div class="divgrups" id="documento" style="border: 1px solid #8c8c8c;">
				<div class="p-grid">
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
								<span>Nro. Documento:</span>
							</div>
							<div class="p-col-2">
								<input id="ENCCUOTA_DOC" type="text" pInputText
									   autocomplete="off" [(ngModel)]="cxpCuotaService.encCuotaArray[indicador].ENCCUOTA_DOC" [disabled]="disabledTxtNroDoc">
							</div>
							<div class="p-col-1">
								<button *ngIf="btnNroDoc === false" class="littlebuttons"
										style="background-image: url(../../../assets/images/iconos/buscar.png)"
										(click)="buscarDocumentosPend()"></button>
								<button *ngIf="btnNroDoc === true" class="littlebuttons"
										style="background-image: url(../../../assets/images/iconos/buscar-d.png)"
										[disabled]="btnNroDoc"></button>
							</div>
							<div class="p-col-2">
								<input id="COMPROBANTE" type="text" pInputText disabled
									   autocomplete="off" [(ngModel)]="cxpCuotaService.encCuotaArray[indicador].ENCFACPRO_NUMERO">
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-1">
								<span>Fecha:  </span>
							</div>
							<div class="p-col-1">
								<p-calendar id="ENCFAC_FECHAEMISION" [inputStyle]="{width: '100%', height: '20px'}"
											dateFormat="dd/mm/yy" disabled="true" [(ngModel)]="cxpCuotaService.encCuotaArray[indicador].TRNCOBRO_FECHATRN">
								</p-calendar>
							</div>
							<div class="p-col-1">
								<span>Total:  </span>
							</div>
							<div class="p-col-1">
								<input id="TOTAL" type="text" pInputText disabled
									   autocomplete="off" [(ngModel)]="cxpCuotaService.encCuotaArray[indicador].ENCFAC_TOTAL">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px">
						<div class="p-grid">
							<div class="p-col-1">
								<span>Proveedor:</span>
							</div>
							<div class="p-col-1">
								<input id="PRO_CODIGO" type="text" pInputText disabled
									   autocomplete="off" [(ngModel)]="cxpCuotaService.encCuotaArray[indicador].PRO_CODIGO">
							</div>
							<div class="p-col-4">
								<input id="PRO_NOMBRE" type="text" pInputText disabled
									   autocomplete="off" [(ngModel)]="cxpCuotaService.encCuotaArray[indicador].PRO_NOMBRE">
							</div>

							<div class="p-col-1">
								<input id="CON_CODIGO" type="text" pInputText disabled [(ngModel)]="cxpCuotaService.encCuotaArray[indicador].CON_CODIGO">
							</div>
							<div class="p-col-2">
							</div>
							<div class="p-col-1">
								<span>SubTotal:  </span>
							</div>
							<div class="p-col-1">
								<input id="SUBTOTAL" type="text" pInputText disabled
									   autocomplete="off" [(ngModel)]="cxpCuotaService.encCuotaArray[indicador].ENCCUOTA_SUBTOTAL">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="p-col-12" style="margin-top: -15px">
			<span>Cuotas</span>
		</div>
		<div class="p-col-12" style="margin-top: -15px">
			<div class="divgrups" style="border: 1px solid #8c8c8c;" id="cuotas">
				<div class="p-grid">
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-6">
								<div class="p-grid">
									<div class="p-col-2">
										<span>Interés Anual:  </span>
									</div>
									<div class="p-col-2">
										<input id="ENCCUOTA_INTERESANUAL" type="text" pInputText
											   style="text-align: right"
											   autocomplete="off" appTwoDigitDecimaNumber
											   [(ngModel)]="cxpCuotaService.encCuotaArray[indicador].ENCCUOTA_INTERESANUAL"
											   (keydown.enter)="cambiarFoco($event)"
											   (input)="cambio($event)">
									</div>
									<div class="p-col-2">
										<span>%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Número:</span>
									</div>
									<div class="p-col-2">
										<input id="ENCCUOTA_NROCUOTAS" type="text" pKeyFilter="pint"
											   pInputText
											   style="text-align: right"
											   autocomplete="off" [(ngModel)]="cxpCuotaService.encCuotaArray[indicador].ENCCUOTA_NROCUOTAS"
											   maxlength="9" (keydown.enter)="cambiarFoco($event)"
											   (input)="cambio($event)"
										>
									</div>
									<div class="p-col-2">
										<span style="float: right">Vence cada</span>
									</div>
									<div class="p-col-1">
										<input id="ENCCUOTA_VENCECDIA" type="text" pKeyFilter="pint"
											   pInputText
											   style="text-align: right"
											   autocomplete="off" [(ngModel)]="cxpCuotaService.encCuotaArray[indicador].ENCCUOTA_VENCECDIA"
											   maxlength="5" (keydown.enter)="cambiarFoco($event)"
											   (input)="cambio($event)">
									</div>
									<div class="p-col-1">
										<span>dias  </span>
									</div>
								</div>
							</div>
							<div class="p-col-1">
								<span>Tipo Interés:  </span>
							</div>
							<div class="p-col-5">
								<div class="p-grid">
									<div class="p-col-4">
										<p-dropdown [style]="{'width':'100%'}"
													optionLabel="name" [panelStyle]="{'width':'110%'}"
													[options]="cmbTipInte"
													[(ngModel)]="strTipInte"
													inputId="tipoInteres"
													(keydown.enter)="cambiarFoco($event)"
													(onChange)="cambioDropdown($event)"
										></p-dropdown>
									</div>
									<div class="p-col-2">
										<span style="float: right">Interés:  </span>
									</div>
									<div class="p-col-2">
										<input id="ENCCUOTA_INTERESEDIT" type="text" pInputText
											   style="text-align: right"
											   autocomplete="off" appTwoDigitDecimaNumber
											   [(ngModel)]="cxpCuotaService.encCuotaArray[indicador].ENCCUOTA_INTERESVAL"
											   (keydown.enter)="cambiarFoco($event)"
											   (input)="cambio($event)">
									</div>
									<div class="p-col-1">
										<div>
											<span *ngIf="lblPorc">%  </span>
										</div>
									</div>
									<div class="p-col-2">
										<input id="ENCCUOTA_INTERESDIS" type="text" pInputText disabled
											   style=" text-align: right"
											   autocomplete="off" [(ngModel)]="cxpCuotaService.encCuotaArray[indicador].INTERES_CALC">
									</div>
									<div class="p-col-1">
										<div>
											<span *ngIf="lblPorc1">%  </span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px">
						<div class="p-grid">
							<div class="p-col-1">
								<span>Fecha de inicio:  </span>
							</div>
							<div class="p-col-1">
								<p-calendar id="ENCCUOTA_FECHAINICIO" [inputStyle]="{width: '100%', height: '20px'}"
											dateFormat="dd/mm/yy"
											[(ngModel)]="cxpCuotaService.encCuotaArray[indicador].ENCCUOTA_FECHAINICIO"
								>
								</p-calendar>
							</div>
							<div class="p-col-4">
							</div>
							<div class="p-col-1">
								<span>Total Financiado:</span>
							</div>
							<div class="p-col-1">
								<input id="ENCCUOTA_FINANCIADO" type="text" pInputText disabled
									   style="color: blue; text-align: right"
									   autocomplete="off" [(ngModel)]="cxpCuotaService.encCuotaArray[indicador].ENCCUOTA_FINANCIADO">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<p-tabView [(activeIndex)]="activeIndex" >
	<p-tabPanel header="Detalle Cuotas" [disabled]="disabledTab0">
		<app-aggridsaci [width]=""
						[height]="largo"
						[enableSorting]="true"
						[enableFilter]="true"
						[rowData]="cxpCuotaService.detCuotaArray[indicador]"
						[frameworkComponents]="frameworkComponents"
						[animateRows]="true"
						[rowSelection]="rowSelection"
						[columnDefs]="columnDefsDetcuotas"
						[defaultColDef]="defaultConDetCuotas"
						[rowStyle]="rowStyle"
						[singleClickEdit]="false"
						[botones]="true"
						[mostrarGuardar]="false"
						[botonNuevo]="btnNuevoDet"
						[botonBorrar]="btnBorrarDet"
						[botonRegresar]="btnCancelarDet"
						(selected)="seleccionDet($event)"
		></app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Varios" [disabled]="disabledTab1">
		<app-aggridsaci [width]=""
						[height]="largo"
						[enableSorting]="true"
						[enableFilter]="true"
						[rowData]="cxpCuotaService.detCuotaVarArray[indicador]"
						[frameworkComponents]="frameworkComponents"
						[animateRows]="true"
						[rowSelection]="rowSelection"
						[columnDefs]="columnDefsCuotaVar"
						[defaultColDef]="defaultCuotaVar"
						[rowStyle]="rowStyle"
						[singleClickEdit]="false"
						[botones]="true"
						[mostrarGuardar]="false"
						[botonNuevo]="btnNuevoDetVar"
						[botonBorrar]="btnBorrarDetVar"
						[botonRegresar]="btnCancelarDetVar"
						(selected)="seleccionDetVar($event)"
		></app-aggridsaci>
	</p-tabPanel>
</p-tabView>
<div class="divgrupstabla">
	<div class="p-grid">
		<div class="p-col-1">
			<div class="cuadrado" style="width: 100px; height: 20px;background: #ffff99;"></div>
		</div>
		<div class="p-col-2">
			<span class="obligatorio" style="float: left">Cuotas Abonadas/Canceladas</span>
		</div>
		<div class="p-col-4">
		</div>
		<div class="p-col-3">
			<div class="p-grid">
				<div class="p-col-4">
					<span class="obligatorio">Total Cuotas:</span>
				</div>
				<div class="p-col-8">
					<input class="totales" id="TOTALPIE" type="text" pInputText disabled
						   style="font-weight: bold; text-align: right"
						   autocomplete="off" [(ngModel)]="cxpCuotaService.encCuotaArray[indicador].TOTAL_CUOTAS">
				</div>
			</div>
		</div>
	</div>
</div>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width:'80vw', height:'120vh'}"
		  [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="NumAsiRegCont" [abiertoDesde]="'cxpCuotas'"
					(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'CXCPENCCUOTA'" [txtNumComprobante] = "cxpCuotaService.encCuotaArray[indicador].ENCCUOTA_CODIGO"
	(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>