import {Component, OnInit, ViewChild} from '@angular/core';
import {SegmaereppantallaService} from '../segservicios/segmaereppantalla.service';
import {PrmtCnfgImpr, Segmaereppantalla} from '../seginterfaces/segmaereppantalla';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Component({
  selector: 'app-segmaereppantalla',
  templateUrl: './segmaereppantalla.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class SegmaereppantallaComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;
  btnBuscar: boolean;
  // btnCancelar: boolean;
  
  botonesGrid: boolean;
  
  defaultColDefPrmtCnfgImpr;
  public frameworkComponents;
  rowStyle;
  largo = '300';
  rowSelection = 'multiple';
  editable = false;
  
  prmtCnfgImprs: PrmtCnfgImpr[] = [];
  selectedPrmtCnfgImpr: PrmtCnfgImpr;
  segMaeRepPantallas: Segmaereppantalla[] = [];
  
  selectedSegMaeRepPantalla: Segmaereppantalla = {
    CNFGIMPR_CODIGO: null,
    CNFGIMPR_FORMA: '',
    CNFGIMPR_LOCALIZACION: '',
    CNFGIMPR_NOMBRE: '',
    CNFGIMPR_PARAMETRO: '',
    CNFGIMPR_REPORTE: '',
    CNFGIMPR_SP: '',
    CNFGIMPR_TABLA: '',
    COM_CODIGO: '',
  };
  
  types: SelectItem[];
  busquedacampos = [];
  tabla: string;
  consulta: string;
  arregloCons: any[] = [];
  arregloBus = [];
  where: string;
  opcion: string;
  condicion: string;
  displayDialogBusquedaFast: boolean;
  
  
  columnDefPrmtCnfgImpr = [
    {
      headerName: 'T', field: 'PRMTCNFGIMPR_TIPO', cellEditor: 'agSelectCellEditor', width: 100,
      cellEditorParams:
        {
          values: ['T', 'C']
        },
    },
    {
      headerName: 'Valor', field: 'PRMTCNFGIMPR_VALOR', cellEditor: 'cellPrueba', width: 600,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 100,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'TD', field: 'PRMTCNFGIMPR_TIPODATO', cellEditor: 'agSelectCellEditor', width: 100,
      cellEditorParams:
        {
          values: ['S', 'D', 'N']
        },
      
    },
  ];
  
  
  constructor(
    private repService: SegmaereppantallaService,
    private init: NuevoComponentService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private errorService: ErroresBaseDatosService,
    private auditoriagral: AuditoriagralService,
  ) {
    this.agTable();
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefPrmtCnfgImpr = {
      editable: true,
      width: 130,
      objeto: 'maeRepPantalla'
    };
  }
  
  
  ngOnInit(): void {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = true;
    this.btnRegresar = true;
    this.botonPrimero = false;
    this.botonAnterior = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
    this.btnBuscar = false;
    // this.btnCancelar = true;
    
    this.botonesGrid = true;
    
    // this.consultarGenCondicion('P');
  }
  
  manejarSenales(valor) {
    switch (valor) {
      case 'Primero':
        this.consultarGenCondicion('P');
        break;
      case 'Ultimo':
        this.consultarGenCondicion('U');
        break;
      case 'Siguiente':
        this.consultarGenCondicion('S', this.selectedSegMaeRepPantalla.CNFGIMPR_CODIGO);
        break;
      case 'Anterior':
        this.consultarGenCondicion('A', this.selectedSegMaeRepPantalla.CNFGIMPR_CODIGO);
        break;
      case 'Nuevo':
        this.opcionNuevaCondicion();
        break;
      case 'Guardar':
        this.guardarNuevaCondicion();
        break;
      case 'Borrar':
        this.borrarCondicion();
        break;
      case 'Buscar':
        this.buscarCondicion();
        break;
      case 'Cancelar':
        this.cargar();
        break;
      case 'Salir':
        this.init.tabs.splice(this.init.tabindex, 1);
        break;
    }
  }
  
  opcionNuevaCondicion() {
    this.editable = true;
    this.prmtCnfgImprs = [];
    this.selectedSegMaeRepPantalla = {
      CNFGIMPR_CODIGO: 0,
      CNFGIMPR_FORMA: '', //
      CNFGIMPR_LOCALIZACION: '', //
      CNFGIMPR_NOMBRE: '', // descripcion
      CNFGIMPR_PARAMETRO: '0',  // Reporte impreso
      CNFGIMPR_REPORTE: '', //
      CNFGIMPR_SP: '', // procedimiento
      CNFGIMPR_TABLA: '', //
      COM_CODIGO: '01',
      nuevo: true,
    };
    this.btnNuevo = true;
    this.prmtCnfgImprs = [];
    this.botonesGrid = false;
    this.btnBorrar = true;
    this.botonPrimero = true;
    this.botonAnterior = true;
    this.botonSiguiente = true;
    this.botonUltimo = true;
    this.btnBuscar = true;
    this.btnGuardar = false;
  }
  
  async guardarNuevaCondicion() {
    const strOperacion = ( this.selectedSegMaeRepPantalla.CNFGIMPR_CODIGO > 0 ? 'A' : 'I');
    if ( this.selectedSegMaeRepPantalla.CNFGIMPR_FORMA !== '' || this.selectedSegMaeRepPantalla.CNFGIMPR_REPORTE !== '' ||
      this.selectedSegMaeRepPantalla.CNFGIMPR_NOMBRE !== '' ) {
      await this.repService.guardarDatosGenCondicion( this.selectedSegMaeRepPantalla  ).then( async res => {
        
        this.selectedSegMaeRepPantalla.CNFGIMPR_CODIGO =  res[0][':B1'];
        
        return await this.auditoriagral.registrarAuditoria('saci_maecfngimpr',
          this.selectedSegMaeRepPantalla.CNFGIMPR_CODIGO + '/SEG', strOperacion,
          '', '01', '', '', '', '').toPromise().then(() => {
          
          if ( !this.selectedSegMaeRepPantalla.nuevo || this.selectedSegMaeRepPantalla.nuevo === undefined  ) {
            this.guardarParametro();
          } else {
            this.mensajeAlerta('success', 'Información', 'Condición guardada');
            
            this.cargar();
          }
          
        });
        
        
        
        
        //
      })
        .catch( e => {
          console.error(e);
          const mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        });
    } else {
      this.mensajeAlerta('info', 'Información', 'Forma, Reporte o Descripción vacíos');
      
    }
  }
  
  async eliminarCondicion() {
    await this.repService.eliminarDatosGenCondicion( this.selectedSegMaeRepPantalla  ).then( async () => {
      
      return await this.auditoriagral.registrarAuditoria('saci_maecfngimpr',
        this.selectedSegMaeRepPantalla.CNFGIMPR_CODIGO + '/SEG', 'E',
        '', '01', '', '', '', '').toPromise().then(() => {
        
        this.mensajeAlerta('success', 'Información', 'Registro eliminado');
        this.selectedSegMaeRepPantalla = {
          CNFGIMPR_CODIGO: null,
          CNFGIMPR_FORMA: '',
          CNFGIMPR_LOCALIZACION: '',
          CNFGIMPR_NOMBRE: '',
          CNFGIMPR_PARAMETRO: '',
          CNFGIMPR_REPORTE: '',
          CNFGIMPR_SP: '',
          CNFGIMPR_TABLA: '',
          COM_CODIGO: '',
        };
        this.prmtCnfgImprs = [];
        this.cargar();
        
      });
      
      
    })
      .catch( e => {
        console.error(e);
        const mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      });
  }
  
  async consultarGenCondicion( opcion: string, codigo?: string ) {
    await this.repService.consultarGenCondicion( opcion, codigo ).then(  r => {
      this.segMaeRepPantallas = r;
      if ( this.segMaeRepPantallas.length > 0 && this.segMaeRepPantallas !== null ) {
        this.selectedSegMaeRepPantalla = this.segMaeRepPantallas[0];
        this.consultarDatosParCondicion(this.selectedSegMaeRepPantalla.CNFGIMPR_CODIGO);
        this.btnBorrar = false;
        this.editable = true;
      }
      
      
    }).catch( e => {
      console.error(e);
      const mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    });
  }
  
  async consultarDatosParCondicion(cnfgimprCodigo: string) {
    await this.repService.consultarParametrosCondicion( cnfgimprCodigo ).then( parametros => {
      
      if ( parametros === null ) {
        this.prmtCnfgImprs = [];
      } else {
        this.prmtCnfgImprs = parametros;
      }
      
      this.botonesGrid = true;
    } ).catch( e => {
      console.error(e);
      const mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    });
  }
  
  cargar() {
    this.editable = false;
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.botonPrimero = false;
    this.botonAnterior = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
    this.btnBuscar = false;
    if ( this.selectedSegMaeRepPantalla.CNFGIMPR_CODIGO !== null ) {
      this.editable = true;
      this.consultarDatosParCondicion(this.selectedSegMaeRepPantalla.CNFGIMPR_CODIGO);
      
    }
  }
  apagarBotones() {
    this.btnNuevo = true;
    this.btnGuardar = true;
    this.btnBorrar = true;
    this.btnRegresar = true;
    this.botonPrimero = true;
    this.botonAnterior = true;
    this.botonSiguiente = true;
    this.botonUltimo = true;
    this.btnBuscar = true;
  }
  
  cambioInput(e) {
    this.btnRegresar = false;
    this.btnGuardar = false;
  }
  
  selectPrmtCnfgImpr(params) {
    if ( params === 'nuevo' ) {
      this.apagarBotones();
      if (  this.selectedSegMaeRepPantalla.CNFGIMPR_CODIGO !== null  ) {
        this.nuevoParametro();
      }
      
    }
    if ( params === 'guardar' ) {
      this.guardarParametro();
    }
    if ( params === 'eliminar' ) {
      this.apagarBotones();
      this.borrarParametro();
    }
    if ( params === 'cancelar' ) {
      /*if ( this.selectedSegMaeRepPantalla.CNFGIMPR_CODIGO ) {
        this.consultarDatosParCondicion(this.selectedSegMaeRepPantalla.CNFGIMPR_CODIGO);
    
      }*/
      this.cargar();
      
    }
    if ( params.object !== undefined ) {
      this.selectedPrmtCnfgImpr = params.object;
    }
    
  }
  
  cambioPrmtCnfgImpr(cambio) {
    this.btnGuardar = false;
  }
  
  nuevoParametro() {
    const nuevoParametro: PrmtCnfgImpr = {
      
      CNFGIMPR_CODIGO: this.selectedSegMaeRepPantalla.CNFGIMPR_CODIGO,
      COM_CODIGO: '01',
      PRMTCNFGIMPR_CODIGO: '0',
      PRMTCNFGIMPR_ORDEN: null,
      PRMTCNFGIMPR_ORIGEN: 'R',
      PRMTCNFGIMPR_TIPO: '',
      PRMTCNFGIMPR_TIPODATO: '',
      PRMTCNFGIMPR_VALOR: '',
      nuevo: true,
      
    };
    this.prmtCnfgImprs.push(nuevoParametro);
    this.aggrid.refreshaggrid(this.prmtCnfgImprs, this.defaultColDefPrmtCnfgImpr.objeto );
  }
  
  async guardarParametro() {
    
    
    this.prmtCnfgImprs.map( parametro => {
      parametro.PRMTCNFGIMPR_ORDEN = this.prmtCnfgImprs.indexOf( parametro ) + 1;
    } );
    if ( this.verificarParametros() ) {
      
      
      /**
       * ===================        DECLARAR PROMESAS   ============================
       */
      const promesas =  this.prmtCnfgImprs.map( async param => {
        return await this.repService.guardarParametrosCondicion( param ) .then( async (res) => {
          const strOperacion = ( param.PRMTCNFGIMPR_CODIGO > 0 ? 'A' : 'I');
          return await this.auditoriagral.registrarAuditoria('saci_maecnfgimprparam',
            param.PRMTCNFGIMPR_CODIGO + '/SEG', strOperacion,
            '', '01', '', '', '', '').toPromise().then(() => {
            
            return;
            
          });
          
        } );
        
      } );
      
      
      await Promise.all( promesas ).then( res => {
        this.mensajeAlerta('success', 'Información', 'Registros guardados');
        this.cargar();
      } )
        .catch( e => {
          console.error(e);
          const mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        } );
    }
  }
  
  verificarParametros(): boolean {
    let validacion = true;
    for ( const parametro of  this.prmtCnfgImprs ) {
      if  ( parametro.PRMTCNFGIMPR_VALOR === '' || parametro.PRMTCNFGIMPR_TIPO === '' ||
        parametro.PRMTCNFGIMPR_TIPODATO === '') {
        this.mensajeAlerta('info', 'Información', 'Verifique que no existan campos vacíos');
        validacion = false;
        break;
      }
    }
    return validacion;
  }
  
  async eliminarParametro() {
    await this.repService.eliminarParametrosCondicion(this.selectedPrmtCnfgImpr).then( async () => {
      
      return await this.auditoriagral.registrarAuditoria('saci_maecnfgimprparam',
        this.selectedPrmtCnfgImpr.PRMTCNFGIMPR_CODIGO + '/SEG', 'E',
        '', '01', '', '', '', '').toPromise().then(() => {
        
        this.mensajeAlerta('success', 'Información', 'Registro eliminado');
        this.cargar();
        
      });
      
    } )
      .catch(e  => {
        console.error(e);
        const mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      });
  }
  
  borrarCondicion() {
    
    this.confirmationService.confirm({
      message: '¿Está seguro que desea eliminar el registro?',
      header: 'Eliminar Grupo',
      icon: 'pi pi-exclamation-triangle',
      key: 'segRepPDialog',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        
        this.eliminarCondicion();
        
      },
      reject: () => {
      }
    });
    
  }
  
  borrarParametro() {
    if ( this.selectedPrmtCnfgImpr !== undefined ) {
      this.confirmationService.confirm({
        message: '¿Está seguro que desea eliminar el registro?',
        header: 'Eliminar Grupo',
        icon: 'pi pi-exclamation-triangle',
        key: 'segRepPDialog',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {
          
          this.eliminarParametro();
          
        },
        reject: () => {
        }
      });
    }
  }
  
  
  buscarCondicion() {
    this.types = [
      {label: 'CÓDIGO', value: 'CNFGIMPR_CODIGO'},
      {label: 'FORMA', value: 'CNFGIMPR_FORMA'},
      {label: 'DESCRIPCIÓN', value: 'CNFGIMPR_NOMBRE'},
      {label: 'REPORTE', value: 'CNFGIMPR_REPORTE'},
    ];
    this.busquedacampos = ['', '', '', ''];
    this.consulta = '';
    this.tabla = 'saci_maecfngimpr';
    this.where = '';
    
    this.repService.busquedaTabla('*', 'saci_maecfngimpr ', this.types[0].value + ' LIKE \'%' +
      '' + '%\'').then((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
    
  }
  
  manejarSeleccion(condicion) {
    this.displayDialogBusquedaFast = false;
    this.selectedSegMaeRepPantalla = {...condicion};
    this.consultarDatosParCondicion(this.selectedSegMaeRepPantalla.CNFGIMPR_CODIGO);
  }
  
  
  
  onBasicUpload(event) {
    
    
    this.selectedSegMaeRepPantalla.CNFGIMPR_REPORTE = event.files[0].name;
    
  }
  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'segMaeRepPtoast',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
    
  }
  
  
}
