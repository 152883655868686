import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { DatePipe } from '@angular/common';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class InvutilexistenciasService {
  private url: string;
  private way: string;
  private strPREINCIVAE;
  private strNODEEXNEG;
  constructor(
    private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService,
  ) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }


  erConfmainvptvta() {
    return this.http.post<any[]>(this.way + '/inventario/consultaexistencia/76d98bbeca9600000000', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }  
  erconfpreincivae() {
    return this.http.post<any[]>(this.way + '/inventario/consultaexistencia/76d98bbf3b74b0000000', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erconfnodeexneg() {
    return this.http.post<any[]>(this.way + '/inventario/consultaexistencia/3b6cc5df7c35ce', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erimpporcentaje() {
    return this.http.post<any[]>(this.way + '/inventario/consultaexistencia/3b72b6738db1d800000', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
  ervrlisprecios(usuidentificacion){
    return this.http.post<any[]>(this.way + '/inventario/consultaexistencia/edfddbacb9d5680000000', {
      elementos: {
        p_usuidentificacion:usuidentificacion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  ervrubicacionbod(){
    return this.http.post<any[]>(this.way + '/inventario/consultaexistencia/76ffbf2e4c5324000000', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  ervrboduser(usuidentificacion){
    return this.http.post<any[]>(this.way + '/inventario/consultaexistencia/3b7f76d786ba0a00000', {
      elementos: {
        p_usuidentificacion:usuidentificacion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  ervrconsboduser(){
    return this.http.post<any[]>(this.way + '/inventario/consultaexistencia/1dbfbb6cc5f92700000000000000', {
      elementos: {
        
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  insertarexisteciaspre(articulo,usuidentificacion){
    return this.http.post<any[]>(this.way + '/inventario/consultaexistencia/12bf1dbeedd9c7000', {
      elementos: {
       p_numlinea:articulo.NUMERO,
       p_art_codigo:articulo.ART_CODIGO, 
       p_art_nombre:articulo.ART_NOMBRE, 
       p_art_ubicacion:articulo.ART_UBICACION,
       p_art_unidadventa:articulo.ART_UNIDADVENTA,
       p_bod_codigo:articulo.BOD_CODIGO,
       p_costp:articulo.COSTO,
       p_existencia:articulo.EXISTENCIA,
       p_grup_codigo:articulo.GRUP_CODIGO,
       p_nombre:articulo.NOMBRE,
       p_prereservado:articulo.PRERESERVADO,
      p_usuidentificacion:usuidentificacion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eliminaexisteciasprep(){
  return this.http.post<any[]>(this.way + '/inventario/consultaexistencia/7565695d4e', {
    elementos: {
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
  //CDPJ
  generateSingle(bodCodigo, condicion, MAINVPTVTA: number,CUMBCE:number /* configuracion */) {
    const fechaActual = Date.now();
    const datePipe = new DatePipe('en-US');
    const fecha = datePipe.transform(fechaActual, 'dd/MM/yyyy');

    return this.http.post<any[]>(this.way + '/inventario/consultaexistencia/20ebbb6aebb92c0000', {
      elementos: {
        bodCodigo,
        condicion,
        MAINVPTVTA,
        fecha,
        CUMBCE
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  buscarBodegas(opcion: string, bod_codigo: string, p_condicion: string) {
    return this.http.post<any[]>(this.way + '/inventario/consultaexistencia/bf718adaf0d74000', {
      elementos: {
        p_opcion: opcion,
        p_bod_codigo: bod_codigo,
        p_condicion: p_condicion,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  busquedaBodega(bodega) {
    return this.http.post(this.way + '/inventario/consultaexistencia/17ee6bce6a97870000', {
      elementos: {
        bodega
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getBodegas() {
    return this.http.post(this.way + '/inventario/consultaexistencia/20eeaf0d7415c', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();

  }

  getPrecios(codArticulo: string, PREINCIVAE: number, dblPorIva: number) {
    return this.http.post<any[]>(this.way + '/inventario/consultaexistencia/20eee76e64b1c', {
      elementos: { PREINCIVAE, codArticulo, dblPorIva },
    }, this.confIniciales.httpOptions()).toPromise();

  }


}
