import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from 'src/app/usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class ProdrecalcularcostosindService {
  private way: string;

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) { 
      this.way = sessionStorage.getItem('way');
    }

    encontrarCenCosto(strParametro) {
      return this.http.post<any[]>(this.way + '/inventario/articulo/3b765398ebb31c', {
        elementos: {
          CEN_CODIGO: strParametro
        },
      }, this.confIniciales.httpOptions());
    }

    encontrarCenCostoProm(strParametro) {
      return this.http.post<any[]>(this.way + '/inventario/articulo/3b765398ebb31c', {
        elementos: {
          CEN_CODIGO: strParametro
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }

    recalcularCostosInd(parametro) {
      return this.http.post<any[]>(this.way + '/produccion/recalcostoind/365ceee4b6639c', {
        elementos: {
          p_mes: parametro[0],
          P_USUIDENTIFICACION: parametro[1],
          p_anio: parametro[2],
          p_centro_costos: parametro[3]
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }

    distribuirCostosIf(parametro) {
      return this.http.post<any[]>(this.way + '/produccion/recalcostoind/365ceb31cee250', {
        elementos: {
          p_mes: parametro[0],
          P_USUIDENTIFICACION: parametro[1],
          p_anio: parametro[2],
          p_centro_costos: parametro[3]
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }

    reversarDisCostos(parametro) {
      return this.http.post<any[]>(this.way + '/produccion/recalcostoind/dbbeedb65c66380', {
        elementos: {
          p_mes: parametro[0],
          P_USUIDENTIFICACION: parametro[1],
          p_anio: parametro[2],
          p_centro_costos: parametro[3]
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }

}
