import { Injectable } from '@angular/core';
import {Venmaeciudad} from '../veninterfaces/venmaeciudad';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Observable} from 'rxjs';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
@Injectable({
  providedIn: 'root'
})
export class VenmaeciudadService {
  public url: string;
  
  public way: string;
  get venmaeciudades(): Venmaeciudad[] {
    return this._venmaeciudades;
  }

  set venmaeciudades(value: Venmaeciudad[]) {
    this._venmaeciudades = value;
  }
  private _venmaeciudades: Venmaeciudad[];

  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }
  getCiudad(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/ciudad/1077592f354d', {
        elementos: {},
    }, this.confIniciales.httpOptions());
  }

  
  insertarCiudad(ciudad: Venmaeciudad): Observable<any[]> {
    return this.http.post<any>(this.way + '/ventas/ciudad/95f8edf592f3580', {
      elementos: {
        p_CIU_codigo_1: ciudad.CIU_CODIGO,
        p_CIU_nombre_2: ciudad.CIU_NOMBRE,
        p_CIU_nombrec_3: ciudad.CIU_NOMBREC,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }


  eliminarCiudad(ciudad: Venmaeciudad): Observable<any[]> {
    return this.http.post<any>(this.way + '/ventas/ciudad/1d595a5756d92f0000', {
      elementos: {
        p_CIU_codigo_1: ciudad.CIU_CODIGO,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }


  actualizarCiudad(ciudad: Venmaeciudad, codigoAux): Observable<any[]> {
    return this.http.post<any>(this.way + '/ventas/ciudad/299df2ab2', {
      elementos: {
        p_CIU_codigo_1: codigoAux,
        p_CIU_codigo_2: ciudad.CIU_CODIGO,
        p_CIU_nombre_3: ciudad.CIU_NOMBRE,
        p_CIU_nombrec_4: ciudad.CIU_NOMBREC,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }


}
