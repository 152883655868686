
<div class="p-grid" style="width: 99%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
						 [botonRegresar]="btnRegresar" [barraBotonesCRUD]="false"  
						 [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true"
													 
						
						 (signal)="manejarSenales($event)"></app-invmaebarra>
	</div>

</div>
<div style="overflow: hidden" class="p-col-12">
    <p-toast [style]="{marginTop: '30px'}" position="top-center" key="segMaeOrgtoast"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="nomEmpVac" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
	<p-tabView>
		<p-tabPanel header="Vacaciones">
            
                <app-aggridsaci [width]=""
                                [height]="largo"
                                [enableSorting]="true"
                                [enableFilter]="true"
                                [rowData]="nomempleadosService.nomcrudVaca"
                                [frameworkComponents]="frameworkComponents"
                                [animateRows]="true"
                                [rowSelection]="rowSelection"
                                [columnDefs]="columnDefVacaciones"
                                [defaultColDef]="defaultColDefVacaciones"
                                [rowStyle]="rowStyle"
                                [singleClickEdit]="false"
                                [botones]="false"
                                [mostrarGuardar]="false"
                                [botonesAdicionales]="false"
                                (selected)="selecVacaciones($event)"
                                (cambios)="cambioVacaciones($event)"
                ></app-aggridsaci> 
        
        </p-tabPanel>
	</p-tabView>
</div>
<!-- <p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [responsive]="true"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlgmae [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
						[where]="condicion"
						[busquedaParams]="busquedacampos"
						(opcionSeleccionada)="manejarSeleccion($event)"
						[busquedaCompuesta]="false"></app-busquedadlgmae>
</p-dialog> -->
