import {Component} from '@angular/core';
import {AgRendererComponent} from 'ag-grid-angular';
import {IAfterGuiAttachedParams} from 'ag-grid';
import {InvtomafisicaService} from '../../inv/invservicios/invtomafisica.service';
import {BantrnbaneditService} from '../banservicios/bantrnbanedit.service';
import {FirmardocumentoService} from '../../ven/venservicios/firmardocumento.service';
import {CxptrnpagodocumentoService  } from "../../com/comservicios/cxptrnpagodocumento.service";

@Component({
  selector: 'app-aggrid-ban',
  templateUrl: './aggrid-ban.component.html',
  styleUrls: ['./aggrid-ban.component.css']
})
export class AggridBanComponent implements AgRendererComponent {
  public  params: any;
  public  checkCierre: boolean;
  public checked: boolean;
  constructor(private tomFisService: InvtomafisicaService, private trnbaneditService: BantrnbaneditService,
              private firmaDocService: FirmardocumentoService,private cxptrnpagodocumentoService:CxptrnpagodocumentoService) {
  
  }
  agInit(params: any): void {
    this.params = params;
    this.checkCierre = params.colDef.checkCierre;
    this.checked = false;
  }
  afterGuiAttached(params?: IAfterGuiAttachedParams): void {
  }
  refresh(params: any): boolean {
    this.checked = !this.checked;
    if (params.data.SNBOOLEAN !== undefined) {
      params.data.SNBOOLEAN = params.value;
    }
    if (params.data.TRNTOMAFISICA_AJUSTE !== undefined) {
      params.data.TRNTOMAFISICA_AJUSTE = params.value;
      this.tomFisService.cargarTotalTomaFisica();
      //CDPJ
      this.tomFisService.habilitarBotones(true, false, true, false, true, true, true,
        true, true, true, true, true, true, true);
      //CDPJ
    }
    if (params.data.MAEPRM_TRIBIVA !== undefined) {
      params.data.MAEPRM_TRIBIVA = params.value;
    }
    if (params.data.VISTO !== undefined) {
      params.data.VISTO = params.value;
    }
    if (params.data.SELECCIONADO !== undefined) {
      params.data.SELECCIONADO = params.value;
    }
    if (params.data.CHECKRECALPMD !== undefined) {
      params.data.CHECKRECALPMD = params.value;
    }
    if (params.data.CHECKCONT !== undefined) {
      params.data.CHECKCONT = params.value;
    }
    if (params.data.CHECK_ITEM !== undefined) {
      params.data.CHECK_ITEM = params.value;
      const index = this.trnbaneditService.banTrnBanCaja.findIndex(i => i.TRNCAJ_NUMERO === params.data.TRNCAJ_NUMERO);
      this.trnbaneditService.valores(index);
    }
    if (params.data.CHECK !== undefined) {
      params.data.CHECK = params.value;
    }
    if (params.data.SEL_EXP !== undefined) {
      params.data.SEL_EXP = params.value;
    }
    if (params.data.SELECCION !== undefined) {
      params.data.SELECCION = params.value;
    }
    if (params.data.CONCIL_SELECT !== undefined) {
      params.data.CONCIL_SELECT = params.value;
    }
    
    if (params.data.DOC_SELECCIONADOF !== undefined) {
      params.data.DOC_SELECCIONADOF = params.value;
      this.firmaDocService.activarBtnFact();
    }
  
    if (params.data.DOC_SELECCIONADOR !== undefined) {
      params.data.DOC_SELECCIONADOR = params.value;
      this.firmaDocService.activarBtnRet();
    }
    if (params.data.DOC_SELECCIONADOG !== undefined) {
      params.data.DOC_SELECCIONADOG = params.value;
      this.firmaDocService.activarBtnGuia();
    }
    if (params.data.DOC_SELECCIONADOC !== undefined) {
      params.data.DOC_SELECCIONADOC = params.value;
      this.firmaDocService.activarBtnCred();
    }
  
    if (params.data.DOC_SELECCIONADOD !== undefined) {
      params.data.DOC_SELECCIONADOD = params.value;
      this.firmaDocService.activarBtnDeb();
    }
    if (params.data.DOC_SELECCIONADOL !== undefined) {
      params.data.DOC_SELECCIONADOL = params.value;
      this.firmaDocService.activarBtnLiq();
    }
  
    if (params.data.TRNRES_SELECCIONADO !== undefined) {
      params.data.TRNRES_SELECCIONADO = params.value;
    }

    if (params.data.PAGOELECTRONICO !== undefined) {
      params.data.PAGOELECTRONICO = params.value;
    }

    if (params.data.MAEINGRFIJO_APORIESS !== undefined) {
      params.data.MAEINGRFIJO_APORIESS = params.value;
    }
    if (params.data.EMPCARGFAMI_DISC !== undefined) {
      params.data.EMPCARGFAMI_DISC = params.value;
    }
    if (params.data.EDITABLE !== undefined) {
      params.data.EDITABLE = params.value;
    }
    if (params.data.KAR_SEL !== undefined) {
      params.data.KAR_SEL = params.value;
    }
    //CDPJ
    if (params.data.CINF_DISTRIBUCION !== undefined) {
      params.data.CINF_DISTRIBUCION = params.value;
    }
    if (params.data.EMVIAR_CORREO !== undefined) {
      params.data.EMVIAR_CORREO = params.value;
    }
    if(params.data.CONTABILIZAR !== undefined){
      params.data.CONTABILIZAR = params.value;
    }
    if(params.data.SELECCIONADOFPCOM !== undefined){
      params.data.SELECCIONADOFPCOM = params.value;
      this.cxptrnpagodocumentoService.sumarcheck(params.data.SELECCIONADOFPCOM )
    }
    if(params.data.TARIIVA_ACTIVO !== undefined){
      params.data.TARIIVA_ACTIVO = params.value;
    } 
    //CDPJ
    this.checkCierre = params.colDef.checkCierre;
    // params.api.refreshCells(params);
    return false;
  }

}
