//
// SACI WEB
// Rutina: Ingreso de Pago a Documento - Facturas de Proveedores
// Propietario: Victor Perez
// Modificado por: VP
// Fecha de creación: 31-03-2020
// Fecha de Modificación: 23-06-2020
//

import { Component, EventEmitter, Input, OnInit, Output, ViewChild,ElementRef } from '@angular/core';
import { CxpTrncobro } from '../cominterfaces/cxptrncobro';
import { CxpTrndocumento } from '../cominterfaces/cxptrndocumento';
import { CxpDettrncobro } from '../cominterfaces/cxpdettrncobro';
import { ComEncfacpro } from '../cominterfaces/comencfacpro';
import { CxpTrncobroPagodoc } from '../cominterfaces/cxptrncobropagodoc';
import { BanTrnban } from '../../ban/baninterfaces/bantrnban';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { CxptrnpagodocumentoService } from '../comservicios/cxptrnpagodocumento.service';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';

// Ag-grid
import { AggridBanComponent } from '../../ban/aggrid-ban/aggrid-ban.component';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { Cxctrncobro } from '../../cxc/cxcinterfaces/cxctrncobro';
import { UtilitariosService } from '../../advantage/advantageservices/utilitarios.service';
@Component({
  selector: 'app-cxptrnpagodocumento',
  templateUrl: './cxptrnpagodocumento.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class CxptrnpagodocumentoComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  @Input() auxiliar: any;
  @Input() auxNumFact: any;
  @Input() encfacpro: ComEncfacpro;
  @Output() cerrar = new EventEmitter<boolean>();

  hmpConfiguracion: any[];

  progress: boolean;
  barraBotones2: boolean;
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  botonFactura: boolean;
  botonAnular: boolean;
  botonXML: boolean;
  disBotonesDialog: boolean;
  btnNuevoGrid: boolean;
  btnBorrarGrid: boolean;
  btnCXCGrid: boolean;
  bolbanco: boolean;/// GSRF
  index: number;
  index1: number;

  largo: string;
  largovar: string;

  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];
  consulta: string;
  tabla: string;
  where: string;
  opcionbusqueda: string;


  displayDialogBusqueda: boolean;
  displayDialogBusquedaFast: boolean;
  displayDialogAsiento: boolean;
  displayAsiContable: boolean;

  cmbasiento: any[];
  selectasiento: any = {};

  cmbtipodoc: any[];
  selecttipodoc: any;

  cmbabono: any[];
  selectabono: any;

  cmbfp: any[];
  selectfp: any;

  cmbtd: any[];
  selecttd: any;

  chkpagoelectronico: boolean;

  documentoSelected: CxpTrndocumento;
  variosSelected: CxpDettrncobro;

  anulado: string;
  impreso: string;
  intCHEQIMP: any;
  strControlActivaOpciones: string;
  lblMensaje: string;
  strControlNuevoReg: string;
  strCodigoConPro: string;
  spin:boolean;//CDPJ
  control:number=0;

  // disable
  disasicomprobante: boolean;
  disnumcheque: boolean;
  discmbtipodiario: boolean;
  discodproveedor: boolean;
  disimporte: boolean;

  // booleanmetodos
  bolelimaTrnDocumento: boolean;
  listaTiposMovBancos: any[];

  asiento: any;
  auxiliar1: number;

  strNombreForma: string;
  auxreporte: number = 0;
  displayDialogReportes: boolean;
  displayDialogfrmcorreos:boolean;//CDPJ
  public frameworkComponents;
  rowStyle;
  desCodigo: boolean;
  defaultColDefDocumentos;
  defaultColDefVarios;
  rowSelection = 'multiple';

  // Buscar documentos pagados
  displayBusDocPagados = false;

  columnDefsDocumentos = [
    { // 0
      headerName: 'Sel.',
      field: 'SELECCIONADOFPCOM',
      cellRendererFramework: AggridBanComponent,
      editable: (params) => {
        return false;
      },
      width: 50,
      onCellClicked: (params) => {
        this.docSeleccionado(params);
      }
    },
    { // 1
      headerName: 'F.Trans.', field: 'TRNCOBRO_FECHATRANS', editable: false, cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false
        }
      }
    },
    { // 2
      headerName: 'Nro.Documento', field: 'TRNDOCUMENTO_NRODOC', editable: false, width: 120, cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false
        }
      }
    },
    { // 3
      headerName: 'Comprobante', field: 'TRNCOBRO_NROCOMPROBANTE', editable: false, width: 150, cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false
        }
      }
    },
    { // 4
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', editable: false, width: 200, cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false
        }
      }
    },
    { // 5
      headerName: 'F.Venc.', field: 'TRNCOBRO_FECHAVENCE', editable: false, cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false
        }
      }
    },
    { // 6
      headerName: 'Importe', field: 'TRNCOBRO_IMPORTE', editable: false, cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false
        }
      }
    },
    { // 7
      headerName: 'Pagado', field: 'PAGADO', editable: false, cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false
        }
      }
    },
    { // 8
      headerName: 'Saldo', field: 'SALDO', editable: false, cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false
        }
      }
    },
    { // 9
      headerName: 'Importe', field: 'TRNDOCUMENTO_IMPORTE', cellEditor: 'cellPrueba', editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 30,
          tienecalculos: true
        }
      }
    },
    { // 10
      headerName: 'Retención', field: 'RETENCION', editable: false, cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false
        }
      }
    },
    { // 11
      headerName: 'Dscto', field: 'TRNDOCUMENTO_DESCUENTO', cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 32
        }
      }
    },
    { // 12
      headerName: 'T.Dscto(F/V)', field: 'TRNDOCUMENTO_TIPODSCTO', cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 32
        }
      }
    },
    { // 13
      headerName: 'Devolución', field: 'DEVOLUCION', editable: false, cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false
        }
      }
    },
    { // 14
      headerName: 'Nro.Cuotas', field: 'NROCUOTAS', editable: false, cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false
        }
      }
    },
    { // 15
      headerName: 'T.Cuotas', field: 'TCUOTAS', editable: false, cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false
        }
      }
    },
    { // 16
      headerName: 'ID', field: 'TRNDOCUMENTO_REF', editable: false, cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false
        }
      }
    }
  ];

  columnDefsVarios = [
    { // 0
      headerName: 'Cuenta Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    }, { // 1
      headerName: 'Descripción', field: 'CON_NOMBRE', cellEditor: 'cellPrueba', width: 300,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    }, { // 2
      headerName: 'Cen.Costos', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    }, { // 3
      headerName: 'Debe', field: 'DEBE', cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 35,
          tienecalculos: true
        }
      }
    }, { // 4
      headerName: 'Haber', field: 'HABER', cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 35,
          tienecalculos: true
        }
      }
    }, { // 5
      headerName: 'Tipo', field: 'TRNBAN_TIPO', cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    }, { // 6
      headerName: 'T.Gasto', field: 'TIPGAS_CODIGO', cellEditor: 'cellPrueba', editable: (params) => {
        return this.comprobarTipoGasto(params.data);
      },
      // , editable: true,
      cellEditorParams: (params) => {
        return this.comprobarTipoGastocell(params.data);
      }
    },
  ];
  //CDPJ
  //check: number;
  constructor(public cxptrnpagodocService: CxptrnpagodocumentoService, private busqService: InvbusquedaService,
    private messageService: MessageService, private errorService: ErroresBaseDatosService,
    private auditoriagral: AuditoriagralService, private confIniciales: ConfInicialesService,
    private confirmationService: ConfirmationService, private datePipe: DatePipe,
    private init: NuevoComponentService, private utilitariosService: UtilitariosService) {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.rowStyle = (params) => {
      if (params.data.COLOR === 'CXC') {
        return { background: 'rgb(128, 255, 128)' };
      }
      if (params.data.COLOR === 'ANT') {
        return { background: 'rgb(255,255,0)' };
      }
    };

    this.defaultColDefDocumentos = {
      editable: true,
      width: 100,
      objeto: 'cxppagodoc'
    };

    this.defaultColDefVarios = {
      editable: true,
      width: 100,
      objeto: 'cxppagovarios'
    };
  }

  ngOnInit() {
    //CDPJ
    this.cxptrnpagodocService.check = 0;
    this.index = 0;
    this.largo = '600';
    this.largovar = '620';
    this.progress = false;
    this.barraBotones2 = true;
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
    this.botonFactura = false;
    this.botonAnular = false;
    this.botonXML = false;
    this.disBotonesDialog = false;

    this.llenarCombos();

    this.selectfp = { codigo: 'Cheque', name: 'Cheque' };


    this.anulado = '';
    this.impreso = '';

    this.intCHEQIMP = 0;
    this.strControlActivaOpciones = '';
    this.lblMensaje = '';
    this.strControlNuevoReg = '';
    this.strCodigoConPro = '';
    this.bolbanco = false;//GSRF
    this.disasicomprobante = false;
    this.disnumcheque = false;

    this.auditoriagral.obtenerConfiguracion1().subscribe((conf) => {
      this.hmpConfiguracion = conf;
    });

    this.confIniciales.getDecimales('frmCXP_TrnPagoDocumento').subscribe((dec3) => {
      if (dec3 !== null) {
        dec3.map((decimales) => {
          if (decimales.NUMDEC_CAMPO === 'GENERAL') {
            this.cxptrnpagodocService.decimalesdetpagoDoc = Number(decimales.NUMDEC_NUMDEC);
          }
          if (decimales.NUMDEC_CAMPO === 'TOTALIMPORTE') {
            this.cxptrnpagodocService.decimalesdetDocumento = Number(decimales.NUMDEC_NUMDEC);
          }
        });
      }
    });

    this.cxptrnpagodocService.trncobro = {
      TRNCOBRO_NRODOC: '',
      COM_CODIGO: '',
      TRNCOBRO_FECHATRN: new Date(),
      TRNCOBRO_TIPODOC: '',
      PRO_CODIGO: '',
      TRNCOBRO_IMPORTE: '',
      TRNCOBRO_CONCEPTO: '',
      TRNCOBRO_FECHAVENCE: '',
      APLORG_CODIGO: 'CXP',
      TRNCOBRO_REFERENCIAEXTERNA: '',
      TRNBAN_BENEFICIARIO: '',
      TRNCOBRO_FLAG: '',
      TRNCOBRO_USUARIO_ACT: '',
      TIPDIA_CODIGO: '',
      CON_CODIGO: '',
      TRNPAGO_NUMERO: '',
      TRNPAGO_TIPO: '',
      CAJ_CODIGO: '',
      TRNPAGO_FECHA: new Date(),
      BAN_CODIGO: '',
      TRNBAN_TIPO: '',
      TRNBAN_NUMERO: '',
      TRNCOBRO_PAGOELECTRONICO: '',
      ASI_NRO: ''
    };

    this.cxptrnpagodocService.documentos = [];
    this.cxptrnpagodocService.varios = [];

    this.btnNuevoGrid = false;
    this.btnBorrarGrid = false;
    this.btnCXCGrid = false;

    this.auxiliar1 = 0;

    this.facturadecompra();
    this.spin=false;//CDPJ
  }
  //CDPJ  
  ngOnDestroy(): void {
    if(this.auxNumFact === '' || this.auxNumFact === undefined || this.auxNumFact === null){
      this.confIniciales.instanciasPagoDoc--;
    }
  }
//CDPJ
  facturadecompra() {
    this.cargarDatosFactura();
    this.disBotonesDialog = true;
    this.botonAnular = true;
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = false;
    this.spin=false;//CDPJ
    this.control=0;//CDPJ
  }

  cargarDatosFactura() {
    this.cxptrnpagodocService.trncobro.PRO_CODIGO = this.encfacpro.PRO_CODIGO;
    this.cxptrnpagodocService.trncobro.PRO_NOMBRE = this.encfacpro.PRO_NOMBRE;
    this.cxptrnpagodocService.trncobro.TRNBAN_BENEFICIARIO = this.encfacpro.PRO_NOMBRE;
    this.completarDatosProveedor(this.cxptrnpagodocService.trncobro.PRO_CODIGO, 'nuevo');
    this.discodproveedor = true;
    this.disimporte = true;
    this.listarDatos(this.cxptrnpagodocService.trncobro.PRO_CODIGO);

    setTimeout(() => {
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
    }, 2000);
  }


  async manejarSenales(valor) {
    if (valor !== 'Nuevo' && valor !== 'Cancelar'
      && valor !== 'Primero' && valor !== 'Anterior' && valor !== 'Siguiente' && valor !== 'Ultimo'
      && valor !== 'Buscar' && valor !== 'Imprimir' && valor !== 'AsientoVer' && valor !== 'Salir' && 
      valor !== 'facturasAbonadas') {
      console.log(this.cxptrnpagodocService.trncobro.TRNCOBRO_FECHATRN);
      let dia = new Date().getDate();
      let mes = new Date().getMonth();
      let anio = new Date().getFullYear();
      // Verifica el periodo contable
      try {
        dia = this.cxptrnpagodocService.trncobro.TRNCOBRO_FECHATRN.getDate();
        mes = this.cxptrnpagodocService.trncobro.TRNCOBRO_FECHATRN.getMonth();
        anio = this.cxptrnpagodocService.trncobro.TRNCOBRO_FECHATRN.getFullYear();
      } catch (e) {
        const tmpfecha = this.cxptrnpagodocService.trncobro.TRNCOBRO_FECHATRN.substr(0, 10);
        const splfec = tmpfecha.split('/');
        const fecha = new Date(splfec[2], splfec[1] - 1, splfec[0]);
        dia = fecha.getDate();
        mes = fecha.getMonth();
        anio = fecha.getFullYear();
      }

      if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
        this.messageService.add({
          key: 'encfacpropago',
          severity: 'error',
          summary: 'Información',
          detail: 'Error, fecha debe de estar dentro del período contable.'
        });
        return;
      }
    }



    if (valor === 'Nuevo') {
      this.nuevo();
    }
    if (valor === 'Guardar') {
      //CDPJ  
      this.botonGuardar = true;
      this.spin=true;
      
      if (this.cxptrnpagodocService.check !== 0) {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefDocumentos.objeto].
        setFocusedCell(this.cxptrnpagodocService.documentos.length - 1, 'RETENCION');
        this.aggrid.refreshaggrid(this.cxptrnpagodocService.documentos, this.defaultColDefDocumentos.objeto);
        this.cxptrnpagodocService.calcularImporteTotal();
        console.log(this.defaultColDefDocumentos.objeto);
        this.spin=true;//CDPJ
      }
      //CDPJ
      if( this.control === 0){
        this.control=1;
           this.guardar();
      }
      //CDPJ
      // this.guardar();
    }
    if (valor === 'Borrar') {
      this.eliminar();
    }
    if (valor === 'Cancelar') {
      this.cancelar();
    }
    if (valor === 'Primero') {
      this.seleccionarRegistro('P');
    }
    if (valor === 'Anterior') {
      this.seleccionarRegistro('A');
    }
    if (valor === 'Siguiente') {
      this.seleccionarRegistro('S');
    }
    if (valor === 'Ultimo') {
      this.seleccionarRegistro('U');
    }
    if (valor === 'Buscar') {
      this.busquedaPagodocumento();
    }
    if (valor === 'Descuento') {
      // this.aplicarDescuentoGlobal();
    }
    if (valor === 'Pago') {
      // this.PagoDocumento();
    }
    if (valor === 'Credito') {
      // this.Credito();
    }
    if (valor === 'Asiento') {
      // if (this.encfacproService.encfacpro.ENCFACPRO_FORMAPAGO === 'Crédito') {
      // this.generarAsiContable();
      //  }
    }
    if (valor === 'AsientoVer') {
      // this.verAsiento();
      this.verAsientoContable();
    }
    if (valor === 'Anular') {
      this.anularDocumento();
    }
    if (valor === 'Firmar') {
      // this.validarFirmaElec();
    }
    if (valor === 'facturasAbonadas') {
      this.displayBusDocPagados = true;
    }
    if (valor === 'Imprimir') {
      this.asiento = this.selectasiento.codigo;
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }
     //CDPJ
     if (valor === 'FormularioCorreos'){
      this.asiento = this.selectasiento.codigo;
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogfrmcorreos=true
    }
    //CDPJ
    if (valor === 'Salir') {
      if (this.auxNumFact === undefined) {
        this.init.tabs.splice(this.init.tabindex, 1);
      } else {
        this.cerrar.emit(true);
      }
    }
  }

  cerrarReportes() {
    this.displayDialogReportes = false;
  }
  cerrarfrmcorreos(){
    this.displayDialogfrmcorreos=false;
  }

  busquedaPagodocumento() {
    this.opcionbusqueda = 'pagodocumento';
    this.types = [
      { label: 'NRODOC', value: 'TRNCOBRO_NRODOC' },
      { label: 'NROCOMPROBANTE', value: 'ASI_COMPROBANTE' },
      { label: 'FECHAEMISION', value: 'TRNCOBRO_FECHATRN' },
      { label: 'TIPODOC', value: 'TRNCOBRO_TIPODOC' },
      { label: 'CÓDIGOPROV', value: 'PRO_CODIGO' },
      { label: 'PRO_NOMBRE', value: 'PRO_NOMBRE' }
    ];
    this.busquedacampos = ['', '', '', '', '', ''];
    this.consulta = 'O.*, C.ASI_COMPROBANTE AS ASI_COMPROBANTE, COM_FNC_NOMBRE_PROVEEDOR(O.PRO_CODIGO) AS PRO_NOMBRE';
    this.tabla = 'CXP_TRNCOBRO O, CON_ENCASI_L C ';
    this.where = ' O.TRNCOBRO_NRODOC=C.CXP_TRNCOBRO_CODIGO AND TRNCOBRO_TIPODOC IN (\'AB\',\'CA\') order by O.TRNCOBRO_FECHATRN desc ';

    this.busqService.busquedaCXPTrnCobro4().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.arregloCons.map((registro) => {
        if (registro.TRNCOBRO_FECHATRN !== null) {
          registro.TRNCOBRO_FECHATRN = this.datePipe.transform(registro.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
        } else {
          registro.TRNCOBRO_FECHATRN = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });
  }

  setFocus(event) {
    let element: any;
    switch (event.target.id) {
      case 'PRO_CODIGO': {
        this.busquedaproveedor(event.target.value);
        break;
      }
      case 'TIPODOC': {
        element = document.getElementById('ENCFACPRO_COMENTARIO');
        break;
      }
      case 'CODBANCO': {
        this.busquedabanco(event.target.value);
        break;
      }
      case 'NUMCHEQUE': {
        element = document.getElementById('BENEFICIARIO');
        break;
      }
      case 'BENEFICIARIO': {
        element = document.getElementById('CONCEPTO');
        break;
      }
      case 'CAJ_CODIGO': {
        this.busquedacaja(event.target.value);
        break;
      }

      case 'CONCEPTO': {
        element = document.getElementById('IMPORTE');
        break;
      }
      case 'IMPORTE': {
        element = document.getElementById('COMPROBANTE');
        break;
      }


    }

    if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }

  }

  nuevo() {
    this.cxptrnpagodocService.trncobro = {
      TRNCOBRO_NRODOC: '',
      COM_CODIGO: '',
      TRNCOBRO_FECHATRN: new Date(),
      TRNCOBRO_TIPODOC: '',
      PRO_CODIGO: '',
      TRNCOBRO_IMPORTE: '',
      TRNCOBRO_CONCEPTO: '',
      TRNCOBRO_FECHAVENCE: '',
      APLORG_CODIGO: 'CXP',
      TRNCOBRO_REFERENCIAEXTERNA: '',
      TRNBAN_BENEFICIARIO: '',
      TRNCOBRO_FLAG: '',
      TRNCOBRO_USUARIO_ACT: '',
      TIPDIA_CODIGO: '',
      CON_CODIGO: '',
      TRNPAGO_NUMERO: '',
      TRNPAGO_TIPO: '',
      CAJ_CODIGO: '',
      TRNPAGO_FECHA: new Date(),
      BAN_CODIGO: '',
      TRNBAN_TIPO: '',
      TRNBAN_NUMERO: '',
      TRNCOBRO_PAGOELECTRONICO: '',
      ASI_NRO: ''
    };

    this.llenarCombos();
    this.control=0;//CDPJ
    this.cxptrnpagodocService.documentos = [];
    this.cxptrnpagodocService.varios = [];

    this.anulado = '';
    this.impreso = '';
    this.disasicomprobante = false;
    this.disnumcheque = false;
    this.discmbtipodiario = false;
    this.discodproveedor = false;
    this.disimporte = false;
    this.strControlActivaOpciones = '';
    this.lblMensaje = '';
    this.chkpagoelectronico = false;
    this.bolbanco = false;//GSRF
    document.getElementById('divPrincipal').style.pointerEvents = 'all';

    if (document.getElementById('PRO_CODIGO') !== null) {
      document.getElementById('PRO_CODIGO').focus();
    }
  }

  guardar() {
    this.botonGuardar = true;
    this.progress = true;
    this.spin=true;//CDPJ
    this.faltaCampos();
  }

  cancelar() {
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;

    this.encontrarPagoDoc(this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC);
  }

  eliminar() {
    this.confirmationService.confirm({
      message: 'Está seguro de eliminar el registro?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencfacpropago',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        if (this.cxptrnpagodocService.trncobro.APLORG_CODIGO !== 'CXP') {
          this.messageService.add({
            key: 'encfacpropago',
            severity: 'error',
            summary: 'Información',
            detail: 'No puede eliminar el registro porque es de otro origen.'
          });
          return;
        }

        if (this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC === '') {
          this.messageService.add({
            key: 'encfacpropago',
            severity: 'error',
            summary: 'Información',
            detail: 'Seleccione el registro a Eliminar.'
          });
          return;
        }

        this.cxp_fnc_verificar_trans_cash(this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC,
          this.cxptrnpagodocService.trncobro.PRO_CODIGO, 'PAGODOC', 'eliminar');

      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  async eliminar2() {
    let aux=this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC;
    await this.cxptrnpagodocService.eliminarTrnCobro_PagDoc(this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC);
    this.actualizarSaldoDocumento('eliminar');
    this.messageService.add({
      key: 'encfacpropago',
      severity: 'success',
      summary: 'Información',
      detail: 'Pago eliminado correctamente'
    });
    this.limpiarDatos();
    this.auditoriagral.registrarAuditoria('CXP_TRNDOCUMENTO',
    aux,
        'E', '', '01', '', '', '',
        '').subscribe();
  }

  async empezarGuardar(strTipoDoc, strFormaPago, strPagoElectronico, intPosGui) {
    const item: CxpTrncobroPagodoc = {};

    item.TRNCOBRO_NRODOC = this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC;
    item.COM_CODIGO = '01';
    item.TRNCOBRO_FECHATRN = this.datePipe.transform(this.cxptrnpagodocService.trncobro.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
    item.TRNCOBRO_TIPODOC = strTipoDoc;
    item.PRO_CODIGO = this.cxptrnpagodocService.trncobro.PRO_CODIGO;
    item.TRNCOBRO_IMPORTE = Number(this.cxptrnpagodocService.trncobro.TRNCOBRO_IMPORTE);
    item.TRNCOBRO_CONCEPTO = this.cxptrnpagodocService.trncobro.TRNCOBRO_CONCEPTO;
    item.TRNCOBRO_FECHAVENCE = '';
    item.APLORG_CODIGO = 'CXP';
    item.TRNCOBRO_REFERENCIAEXTERNA = this.cxptrnpagodocService.trncobro.TRNCOBRO_REFERENCIAEXTERNA;
    item.TRNBAN_BENEFICIARIO = this.cxptrnpagodocService.trncobro.TRNBAN_BENEFICIARIO;
    item.TRNCOBRO_FLAG = '0';
    item.TRNCOBRO_USUARIO_ACT = this.cxptrnpagodocService.usuario.identificacion;
    item.TIPDIA_CODIGO = this.selecttd.codigo;
    item.CON_CODIGO = this.strCodigoConPro;
    item.TRNPAGO_NUMERO = this.cxptrnpagodocService.trncobro.TRNPAGO_NUMERO;
    item.TRNPAGO_TIPO = strFormaPago;
    item.CAJ_CODIGO = this.cxptrnpagodocService.trncobro.CAJ_CODIGO;
    item.TRNPAGO_FECHA = this.datePipe.transform(this.cxptrnpagodocService.trncobro.TRNPAGO_FECHA, 'dd/MM/yyyy');
    item.BAN_CODIGO = this.cxptrnpagodocService.trncobro.BAN_CODIGO;
    item.TRNBAN_TIPO = strFormaPago;
    item.TRNBAN_NUMERO = this.cxptrnpagodocService.trncobro.TRNBAN_NUMERO;
    item.TRNCOBRO_PAGOELECTRONICO = strPagoElectronico;

    try  {

      const data = await this.cxptrnpagodocService.insert_trncobro_pagodocProm(item)
      for (const rs of data) {
        const strCadenaResul = rs[':B3||\'/\'||:B2'] === null ? '' : rs[':B3||\'/\'||:B2'];
        if (strCadenaResul !== '') {
          intPosGui = strCadenaResul.indexOf('/');
          this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC = strCadenaResul.substring(0, intPosGui);
          this.cxptrnpagodocService.trncobro.TRNPAGO_NUMERO = strCadenaResul.substring(intPosGui + 1);
          if (rs[2] !== null) {
            this.cxptrnpagodocService.trncobro.TRNBAN_NUMERO = rs[':B1'];
          }

          await this.guardarDetalle();
        }
      }

    } catch (err) {
      this.messageService.add({
        key: 'encfacpropago',
        severity: 'error',
        summary: 'Error',
        detail: this.errorService.generarMensajeErrorCompleto(err.error)
      });
      this.spin=false;//CDPJ
      this.progress = false;
    }
    this.progress = false;
    /*this.cxptrnpagodocService.insert_trncobro_pagodoc(item).subscribe(data => {
      for (const rs of data) {
        const strCadenaResul = rs[':B3||\'/\'||:B2'] === null ? '' : rs[':B3||\'/\'||:B2'];
        if (strCadenaResul !== '') {
          intPosGui = strCadenaResul.indexOf('/');
          this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC = strCadenaResul.substring(0, intPosGui);
          this.cxptrnpagodocService.trncobro.TRNPAGO_NUMERO = strCadenaResul.substring(intPosGui + 1);
          if (rs[2] !== null) {
            this.cxptrnpagodocService.trncobro.TRNBAN_NUMERO = rs[':B1'];
          }

          this.guardarDetalle();
        }
      }
    }, error1 => {
      this.messageService.add({
        key: 'encfacpropago',
        severity: 'error',
        summary: 'Error',
        detail: this.errorService.generarMensajeErrorCompleto(error1.error)
      });
      this.progress = false;
    });*/
  }

  async validarDetalleDoc(strTipoDoc, strFormaPago, strPagoElectronico, intPosGui) {

    if (this.faltanCamposDet() === false) {
      this.botonGuardar = false;
      this.progress = false;
      this.spin=false;//CDPJ
      this.control=0;//CDPJ
      return;
    }

    // Elimina los documentos
    await this.elimaTrnDocumento();
    if (this.bolelimaTrnDocumento === false) {
      this.botonGuardar = false;
      this.progress = false;
      this.spin=false;//CDPJ
      this.control=0;//CDPJ
      return;
    }

    await this.validarVarios(strTipoDoc, strFormaPago, strPagoElectronico, intPosGui);
  }

  async validarVarios(strTipoDoc, strFormaPago, strPagoElectronico, intPosGui) {
    await this.faltanCamposDetVarios(strTipoDoc, strFormaPago, strPagoElectronico, intPosGui);
  }

  async guardarDetalle() {

    let intIndiceLista = 0;
    let item: CxpTrndocumento;

    for (intIndiceLista = 0; intIndiceLista < this.cxptrnpagodocService.documentos.length; intIndiceLista++) {
      item = this.cxptrnpagodocService.documentos[intIndiceLista];

      if (item.SELECCIONADOFPCOM === true) { // si esta seleccionado
        const itemdoc: CxpTrndocumento = {};

        itemdoc.TRNCOBRO_NRODOC = this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC;
        itemdoc.PRO_CODIGO = this.cxptrnpagodocService.trncobro.PRO_CODIGO;
        itemdoc.COM_CODIGO = '01';
        itemdoc.TRNDOCUMENTO_NRODOC = item.TRNDOCUMENTO_NRODOC;
        itemdoc.TRNDOCUMENTO_TIPODOC = item.TRNDOCUMENTO_TIPODOC;
        itemdoc.TRNDOCUMENTO_IMPORTE = Number(item.TRNDOCUMENTO_IMPORTE);
        itemdoc.TRNDOCUMENTO_DESCUENTO = Number(item.TRNDOCUMENTO_DESCUENTO);
        itemdoc.TRNDOCUMENTO_TIPODSCTO = item.TRNDOCUMENTO_TIPODSCTO;
        itemdoc.TRNDOCUMENTO_REF = '';
        itemdoc.TRNDOCUMENTO_FLAG = 0;

        await this.cxptrnpagodocService.insertarTRNDOCUMENTO(itemdoc).catch(e=>{
          this.spin=false;//CDPJ
          this.control=0;//CDPJ
        });
        this.auditoriagral.registrarAuditoria('CXP_TRNDOCUMENTO',
          this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC+'/'+itemdoc.TRNDOCUMENTO_NRODOC, 'I',
          '', '01', '', '', '', '').subscribe();

      }
    } // Fin de for*/

    /*Guarda Varios*/
    await this.guardarDetalleVarios();


  } // Fin guardar detalle

  async guardarDetalleVarios() {
    let intIndiceLista = 0;
    let item: CxpDettrncobro;


    for (intIndiceLista = 0; intIndiceLista < this.cxptrnpagodocService.varios.length; intIndiceLista++) {
      item = this.cxptrnpagodocService.varios[intIndiceLista];
      const itemvar: CxpDettrncobro = {};

      itemvar.TRNCOBRO_NRODOC = this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC;
      itemvar.COM_CODIGO = '01';
      itemvar.DETTRNCOBRO_NROSEC = Number(item.DETTRNCOBRO_NROSEC);
      itemvar.CON_CODIGO = item.CON_CODIGO;
      if (Number(item.DEBE) !== 0) {
        itemvar.DETTRNCOBRO_IMPORTE = Number(item.DEBE);
      }
      if (Number(item.HABER) !== 0) {
        itemvar.DETTRNCOBRO_IMPORTE = Number(item.HABER) * -1;
      }
      // Si no es un anticipo guardar el numero del comprobante de egreso
      if (item.DETTRNCOBRO_TIPO !== 'A') {
        item.DETTRNCOBRO_REFERENCIA = this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC;
      }
      itemvar.DETTRNCOBRO_REFERENCIA = item.DETTRNCOBRO_REFERENCIA;
      itemvar.TRNRETENCION_NRO = item.TRNRETENCION_NRO;
      itemvar.RETENCION_CODIGO = item.RETENCION_CODIGO;
      itemvar.ENCFAC_NUMERO = item.ENCFAC_NUMERO;
      itemvar.ENCFAC_COMCODIGO = item.ENCFAC_COMCODIGO;
      itemvar.ENCFAC_CLICODIGO = item.ENCFAC_CLICODIGO;
      itemvar.BAN_CODIGO = item.BAN_CODIGO;
      itemvar.TRNBAN_TIPO = item.TRNBAN_TIPO;
      itemvar.TRNBAN_NUMERO = item.TRNBAN_NUMERO;
      itemvar.CEN_CODIGO = item.CEN_CODIGO;
      itemvar.PRO_CODIGO = item.PRO_CODIGO;
      itemvar.DETTRNCOBRO_TIPO = item.DETTRNCOBRO_TIPO;
      itemvar.DETTRNCOBRO_COMPROBANTE = item.DETTRNCOBRO_COMPROBANTE;
      itemvar.TIPGAS_CODIGO = item.TIPGAS_CODIGO;

      const data = await this.cxptrnpagodocService.insertar_DETTRNCOBRO(itemvar).catch(e=>{
        this.spin=false;//CDPJ
      this.control=0;//CDPJ
      });
      if (item.DETTRNCOBRO_NROSEC === 0) {
        this.auditoriagral.registrarAuditoria('CXP_DETTRNCOBRO',
          this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC + '/' + item.CON_CODIGO,
          'I', '', '01', '', '', '',
          '').subscribe();
      } else {
        this.auditoriagral.registrarAuditoria('CXP_DETTRNCOBRO',
          this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC + '/' + item.CON_CODIGO,
          'A', '', '01', '', '', '',
          '').subscribe();
      }
      for (const rs of data) {
        item.DETTRNCOBRO_NROSEC = rs.DETTRNCOBRO_NROSEC;
        item.TRNBAN_NUMERO = rs.TRNBAN_NUMERO === null ? '' : rs.TRNBAN_NUMERO;
      }
    }
    /*Hilo de tablas*/
    await this.generaAsientoContable();
    this.spin=false;//CDPJ
  } // Fin guardar detalle


  async faltaCampos() {
    let bolaux = true;
    // Verifica que documento este dentro del periodo contable
    /*if ( Utilidades.verificarPeriodoContableGuardar(dateTimeFechaEmision.getDay(),
      dateTimeFechaEmision.getMonth(), dateTimeFechaEmision.getYear())==false )
    {
      MessageDialog.openInformation(shell, "Información", "Error, fecha debe de estar dentro del período contable");
      return false;
    }*/

    if (this.verificarFechaEmision() === true) {
      this.botonGuardar = false;
      this.progress = false;
      this.spin=false;//CDPJ
      this.control=0;//CDPJ
      return;
    }

    // Controla si el importe es negativo
    if (this.selectfp.codigo !== 'Varios') {
      if (this.cxptrnpagodocService.trncobro.TRNCOBRO_IMPORTE === '' || Number(this.cxptrnpagodocService.trncobro.TRNCOBRO_IMPORTE) === 0) {
        this.messageService.add({
          key: 'encfacpropago',
          severity: 'error',
          summary: 'Información',
          detail: 'Error, el valor del Importe no puede ser cero.'
        });
        this.botonGuardar = false;
        this.progress = false;
        this.spin=false;//CDPJ
        this.control=0;//CDPJ
        return;
      }
    }


    // Verifica que exista una forma de pago del importe
    if (Number(this.cxptrnpagodocService.trncobro.TRNCOBRO_IMPORTE) > 0) {

      if (this.cxptrnpagodocService.trncobro.BAN_NOMBRE === undefined) {
        this.cxptrnpagodocService.trncobro.BAN_NOMBRE = '';
      }
      if (this.cxptrnpagodocService.trncobro.CAJ_NOMBRE === undefined) {
        this.cxptrnpagodocService.trncobro.CAJ_NOMBRE = '';
      }

      if (this.cxptrnpagodocService.trncobro.BAN_NOMBRE === '' && this.cxptrnpagodocService.trncobro.CAJ_NOMBRE === '') {
        this.messageService.add({
          key: 'encfacpropago',
          severity: 'error',
          summary: 'Información',
          detail: 'Error, No existe forma de pago.'
        });
        this.botonGuardar = false;
        this.progress = false;
        this.spin=false;//CDPJ
        this.control=0;//CDPJ
        return;
      }
    }

    // Número del comprobante
    if (this.verificarComprobante() === false) {
      this.botonGuardar = false;
      this.progress = false;
      this.spin=false;//CDPJ
      this.control=0;//CDPJ
      return;
    }

    // Concepto
    if (this.cxptrnpagodocService.trncobro.TRNCOBRO_CONCEPTO === '') {
      this.messageService.add({
        key: 'encfacpropago',
        severity: 'error',
        summary: 'Información',
        detail: 'Error, Ingrese el concepto.'
      });
      this.botonGuardar = false;
      this.progress = false;
      this.spin=false;//CDPJ
      this.control=0;//CDPJ
      return;
    }

    if (Number(this.cxptrnpagodocService.trncobro.TRNCOBRO_IMPORTE) < 0) {
      this.messageService.add({
        key: 'encfacpropago',
        severity: 'error',
        summary: 'Información',
        detail: 'Error, el valor del Importe no puede ser Negativo'
      });
      this.botonGuardar = false;
      this.progress = false;
      this.spin=false;//CDPJ
      this.control=0;//CDPJ
      return;
    }


    // Si es cheque
    if (this.selectfp.codigo === 'Cheque') {
      if (this.chkpagoelectronico === false) {
        if (this.cxptrnpagodocService.trncobro.TRNBAN_NUMERO === '') {
          this.messageService.add({
            key: 'encfacpropago',
            severity: 'error',
            summary: 'Información',
            detail: 'Error, ingrese el número del Cheque.'
          });
          this.botonGuardar = false;
          this.progress = false;
          this.spin=false;//CDPJ
          this.control=0;//CDPJ
          return;
        }
      }

      // Beneficiario
      if (this.cxptrnpagodocService.trncobro.TRNBAN_BENEFICIARIO === '') {
        this.messageService.add({
          key: 'encfacpropago',
          severity: 'error',
          summary: 'Información',
          detail: 'Error, Ingrese el beneficiario.'
        });
        this.botonGuardar = false;
        this.progress = false;
        this.spin=false;//CDPJ
        this.control=0;//CDPJ
        return;
      }


      if (this.cxptrnpagodocService.trncobro.TRNCOBRO_FECHATRN > this.cxptrnpagodocService.trncobro.TRNPAGO_FECHA) {
        this.messageService.add({
          key: 'encfacpropago',
          severity: 'error',
          summary: 'Información',
          detail: 'Error, La fecha de emisión no puede ser mayor a la fecha de cobro del Cheque.'
        });
        this.botonGuardar = false;
        this.progress = false;
        this.spin=false;//CDPJ
        this.control=0;//CDPJ
        return;
      }

      if (this.cxptrnpagodocService.trncobro.TRNPAGO_FECHA > this.cxptrnpagodocService.trncobro.TRNCOBRO_FECHATRN) {
        // verifica si tiene la cuenta contable de documento por pagar

        let strConCodigo = '';

        const eR = await this.cxptrnpagodocService.erConCodigo2Prom(this.cxptrnpagodocService.trncobro.BAN_CODIGO).catch(e=>{
          this.spin=false;//CDPJ
          this.control=0;//CDPJ
        });
        if (eR !== null) {
          if (eR[0] !== undefined) {
            strConCodigo = eR[0].CON_CODIGO_2 === null ? '' : eR[0].CON_CODIGO_2;
          }
        }

        if (strConCodigo === '') {
          this.messageService.add({
            key: 'encfacpropago',
            severity: 'error',
            summary: 'Información',
            detail: 'Error,  En el maestro de bancos debe ingresar la cuenta contable que afecte a Documentos por Pagar.'
          });
          bolaux = false;
          this.spin=false;//CDPJ
          this.control=0;//CDPJ
        }

      }
    }
    //CDPJ
    if (this.cxptrnpagodocService.check === 0) {

      this.messageService.add({
        key: 'encfacpropago',
        severity: 'error',
        summary: 'Información',
        detail: 'No se ha dado clic en ningún casillero del detalle de Documentos '
      });
      this.spin=false;//CDPJ
      bolaux = false;
      this.control=0;//CDPJ

    }
    if (bolaux === true) {
      this.faltaCampos2();
    } else {
      this.botonGuardar = false;
      this.progress = false;
    }
  }

  faltaCampos2() {

    let strTipoDoc = '';
    let strFormaPago = '';
    let strPagoElectronico = '';
    const intPosGui = 0;

    // Tipo de transacción
    if (this.selecttipodoc.codigo === 'Abono') {
      strTipoDoc = 'AB';
    } else {
      strTipoDoc = 'CA';
    }

    // Forma de pago
    if (this.selectfp.codigo === 'Efectivo') {
      strFormaPago = 'EF';
    }
    if (this.selectfp.codigo === 'Cheque') {
      strFormaPago = 'CH';
    }
    if (this.selectfp.codigo === 'Varios') {
      strFormaPago = 'VA';
    }

    if (strFormaPago !== 'CH') {
      this.cxptrnpagodocService.trncobro.TRNPAGO_FECHA = this.cxptrnpagodocService.trncobro.TRNCOBRO_FECHATRN;
    }

    if (this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC === '') {
      this.strControlNuevoReg = 'N';
    }

    if (this.chkpagoelectronico === true) {
      strPagoElectronico = 'S';
    }

    this.validarDetalleDoc(strTipoDoc, strFormaPago, strPagoElectronico, intPosGui);


  }

  botonesmodificar() {
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = false;
  }

  busquedaproveedor(parametro) {
    if (parametro === null) {
      parametro = '';
    }
    this.opcionbusqueda = 'proveedor';
    this.types = [
      { label: 'Código', value: 'PRO_CODIGO' },
      { label: 'Nombre', value: 'PRO_NOMBRE' },
      { label: 'Nombre Corto', value: 'PRO_NOMBREC' },
      { label: 'Grupo', value: 'GRU_CODIGO' },
      { label: 'Ruc', value: 'PRO_RUCIDE' },
      { label: 'Cuenta', value: 'CON_CODIGO1' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', ''];
    this.consulta = '';
    this.where = '';
    this.tabla = 'COM_MAEPROVEEDOR';


    this.cxptrnpagodocService.erProNombre(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          const opcion = {
            PRO_CODIGO: parametro,
            PRO_NOMBRE: eR[0].PRO_NOMBRE
          };
          this.seleccionproveedor(opcion);
        } else {
          this.busqService.busquedaComMaeProveedor3(parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaComMaeProveedor3(parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedabanco(parametro) {
    if (parametro === null) {
      parametro = '';
    }
    this.opcionbusqueda = 'banco';
    this.types = [
      { label: 'Código', value: 'BAN_CODIGO' },
      { label: 'Banco', value: 'BAN_BANCO' },
      { label: 'Cuenta Nro', value: 'BAN_CTANRO' },
      { label: 'Jefe Cuenta', value: 'BAN_JEFECTA' },
      { label: 'Sucursal', value: 'BAN_SUCURSAL' },
      { label: 'Direccion', value: 'BAN_DIRECCION' },
      { label: 'Telefono1', value: 'BAN_TELEFONO1' },
      { label: 'Ref. contable', value: 'CON_CODIGO' },
      { label: 'Centro', value: 'CEN_CODIGO' },
      { label: 'Tipo de Cuenta', value: 'BAN_TIPOCUENTA' },
      { label: 'Doc. por Pagar', value: 'CON_CODIGO_2' },
      { label: 'Proceso', value: 'BAN_PROCESO_CM' },
      { label: 'directorioCM', value: 'BAN_DIRECTORIO_CM' },
      { label: 'DineroElec', value: 'BAN_DINEROELEC' },
      { label: 'TelCel', value: 'BAN_TELEFONOCEL' },
      { label: 'Ult.Cheque', value: 'BAN_ULTIMOCHEQUE' },


    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
    this.consulta = '';
    this.where = '';
    this.tabla = 'BAN_MAEBAN';

    this.cxptrnpagodocService.erBanBanco(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          const opcion = {
            BAN_CODIGO: parametro,
            BAN_BANCO: eR[0].BAN_BANCO
          };
          this.seleccionbanco(opcion);
        } else {
          this.busqService.busquedaBanMaeBan3(parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaBanMaeBan3(parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });


  }


  busquedacaja(parametro) {
    if (parametro === null) {
      parametro = '';
    }
    this.opcionbusqueda = 'caja';
    this.types = [
      { label: 'Código', value: 'CAJ_CODIGO' },
      { label: 'Caja', value: 'CAJ_DESCRIPCION' },
      { label: 'Cuenta Nro', value: 'CON_CODIGO' },
      { label: 'Centro Costo', value: 'CEN_CODIGO' },


    ];
    this.busquedacampos = [parametro, '', '', ''];
    this.consulta = '';
    this.where = '';
    this.tabla = 'BAN_MAECAJ';

    this.cxptrnpagodocService.erCajDescripcion(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          const opcion = {
            CAJ_CODIGO: parametro,
            CAJ_DESCRIPCION: eR[0].CAJ_DESCRIPCION
          };
          this.seleccioncaja(opcion);
        } else {
          this.busqService.busquedaBanMaeCaj3(parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaBanMaeCaj3(parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });

  }


  llenarCombos() {
    this.cmbabono = [{ codigo: '', name: '' }];

    this.selectabono = { codigo: '', name: '' };

    this.cmbasiento = [{ codigo: '', name: '' }];

    this.selecttipodoc = { codigo: 'Cancelación', name: 'Cancelación' };
    this.cmbtipodoc = [{ codigo: 'Abono', name: 'Abono' },
    { codigo: 'Cancelación', name: 'Cancelación' }];


    this.selectfp = { codigo: 'Cheque', name: 'Cheque' };
    this.cmbfp = [{ codigo: 'Cheque', name: 'Cheque' },
    { codigo: 'Efectivo', name: 'Efectivo' },
    { codigo: 'Varios', name: 'Varios' },
    { codigo: 'Canje', name: 'Canje' },
    { codigo: 'ND', name: 'ND' }];

    this.selecttd = { codigo: 'CE', name: 'CE' };

    const strSentencia = 'select * from con_maetipdia order by tipdia_codigo';

    this.cxptrnpagodocService.consultarMaeTipDia().subscribe(data => {
      this.cmbtd = [];
      for (const rs of data) {
        this.cmbtd.push({ codigo: rs.TIPDIA_CODIGO, name: rs.TIPDIA_CODIGO });
      }
    });

  }

  busqfp() {
    this.cxptrnpagodocService.trncobro.CAJ_CODIGO = '';
    this.cxptrnpagodocService.trncobro.CAJ_NOMBRE = '';
    this.cxptrnpagodocService.trncobro.BAN_CODIGO = '';
    this.cxptrnpagodocService.trncobro.BAN_NOMBRE = '';
    this.cxptrnpagodocService.trncobro.TRNBAN_NUMERO = '';
    this.cxptrnpagodocService.trncobro.TRNBAN_BENEFICIARIO = '';
  }

  seleccionDocumento(params) {
    if (params.object !== undefined) {
      this.documentoSelected = params.object;
    }
  }

  cancelarvarios() {
    if (this.variosSelected.CON_CODIGO === '') {
      this.cxptrnpagodocService.varios.splice(this.cxptrnpagodocService.varios.findIndex(
        varios => varios.CLAVE === this.variosSelected.CLAVE),
        1);
      if (this.cxptrnpagodocService.varios.length > 0) {
        this.variosSelected = this.cxptrnpagodocService.varios[this.cxptrnpagodocService.varios.length - 1];
      }
      this.aggrid.refreshaggrid(this.cxptrnpagodocService.varios, this.defaultColDefVarios.objeto);
    }
  }

  async eliminarvarios() {
    try {
      /* if (this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC !== '') {
        await this.cxptrnpagodocService.eliminarLineaVarios(this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC, this.variosSelected.DETTRNCOBRO_NROSEC);
      } */

      if (this.variosSelected.DETTRNCOBRO_NROSEC !== 0) {
        await this.cxptrnpagodocService.eliminarLineaVarios(this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC, this.variosSelected.DETTRNCOBRO_NROSEC);
        this.cxptrnpagodocService.varios.splice(this.cxptrnpagodocService.varios.findIndex(
          item => item.CLAVE === this.variosSelected.CLAVE),
          1);
          this.auditoriagral.registrarAuditoria('CXP_DETTRNCOBRO',
          this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC + '/' +this.variosSelected.CON_CODIGO,
          'E', '', '01', '', '', '',
          '').subscribe();
      } else {
        this.cxptrnpagodocService.varios = this.cxptrnpagodocService.varios.filter((val, j) => j !==
          this.cxptrnpagodocService.varios.indexOf(this.variosSelected));
      }

      /* this.cxptrnpagodocService.varios.splice(this.cxptrnpagodocService.varios.findIndex(
        varios => varios.CLAVE === this.variosSelected.CLAVE),
        1); */
      if (this.cxptrnpagodocService.varios.length > 0) {
        this.variosSelected = this.cxptrnpagodocService.varios[this.cxptrnpagodocService.varios.length - 1];
      }
      this.cxptrnpagodocService.calcularImporteTotal();
      this.aggrid.refreshaggrid(this.cxptrnpagodocService.varios, this.defaultColDefVarios.objeto);
      this.botonNuevo = true;
      this.botonGuardar = false;
      this.botonBorrar = true;
      this.botonRegresar = false;

    } catch (err) {

    }

  }

  seleccionVarios(params) {
    if (params === 'nuevo') {
      this.nuevovarios();
      return;
    }

    if (params === 'cancelar') {
      this.cancelarvarios();
      return;
    }

    if (params === 'eliminar') {
      this.eliminarvarios();
      return;
    }

    if (params === 'btnDesplCXC') {
      this.listarFacPendientes();
      return;
    }

    if (params === 'btnAnticipo') {
      this.listarAntPendientes();
      return;
    }

    if (params.object !== undefined) {
      this.variosSelected = params.object;
    }
  }

  cambio(cambio) {
    if (cambio === true) {
      this.botonGuardar = false;
      this.botonRegresar = false;
      this.botonNuevo = false;
    }
  }

  manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'pagodocumento') {
      this.spin=true//CDPJ
      this.encontrarPagoDoc(opcion.TRNCOBRO_NRODOC);
    }

    if (this.opcionbusqueda === 'proveedor') {
      this.seleccionproveedor(opcion);
    }

    if (this.opcionbusqueda === 'banco') {
      this.seleccionbanco(opcion);
    }

    if (this.opcionbusqueda === 'caja') {
      this.seleccioncaja(opcion);
    }

    if (this.opcionbusqueda === 'facPend') {
      this.seleccionFactPend(opcion);
    }

    if (this.opcionbusqueda === 'antPend') {
      this.seleccionAntPend(opcion);
    }

    this.displayDialogBusqueda = false;
    this.displayDialogBusquedaFast = false;
  }

  completarDatosProveedor(strCodProv, aux) {
    this.cxptrnpagodocService.getDatosProveedor(strCodProv).subscribe(data => {
      for (const rs of data) {
        this.strCodigoConPro = rs.CON_CODIGO1;
      }
    });
  }

  docSeleccionado(valor) {
    // const indi = this.cxptrnpagodocService.documentos.indexOf(valor.data);
    // if (valor.data.SELECCIONADOFPCOM) {
    //   if (this.selecttipodoc.codigo === 'Cancelación') {
    //     valor.data.TRNDOCUMENTO_IMPORTE = valor.data.SALDO;
    //   }
    //   this.cxptrnpagodocService.calcularImporteTotal();
    //   this.aggrid.refreshaggrid(this.cxptrnpagodocService.documentos, this.defaultColDefDocumentos.objeto);
    //   this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefDocumentos.objeto].setFocusedCell(indi, 'SELECCIONADOFPCOM');
    // } else {
    //   valor.data.TRNDOCUMENTO_IMPORTE = 0;
    //   valor.data.TRNDOCUMENTO_IMPORTE = valor.data.TRNDOCUMENTO_IMPORTE.toFixed(this.confIniciales.getNumDecSist());
    //   valor.data.SALDO = valor.data.SALDOORIGINAL;
    //   this.cxptrnpagodocService.calcularImporteTotal();
    //   this.aggrid.refreshaggrid(this.cxptrnpagodocService.documentos, this.defaultColDefDocumentos.objeto);
    //   this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefDocumentos.objeto].setFocusedCell(indi, 'SELECCIONADOFPCOM');
    // }

    // // this.aggrid.refreshGridData(this.cxptrnpagodocService.documentos);
  
    // if (valor.data.SELECCIONADOFPCOM === true) {
    //   this.check = this.check + 1;
    // } else {
    //   this.check = this.check - 1;
    // }
    console.log('check', this.cxptrnpagodocService.check);
    // console.log('data',valor.data);
    // console.log('objeto',valor.object);
    const indi = this.cxptrnpagodocService.documentos.indexOf(valor.data);
      console.log(indi)
      console.log(valor.data.SELECCIONADOFPCOM)
      this.documentoSelected = valor.data;
    if (valor.data.SELECCIONADOFPCOM) {
      valor.data.TRNDOCUMENTO_IMPORTE = 0;//CDPJ
      if (this.selecttipodoc.codigo === 'Cancelación') {
        valor.data.TRNDOCUMENTO_IMPORTE = valor.data.SALDO;
      }
     
      // this.contenedor.nativeElement.style.overflow='hidden'
      //this.aggrid.refreshaggrid(this.cxptrnpagodocService.documentos, this.defaultColDefDocumentos.objeto);
      this.aggrid.refreshaggridindexSNMVSCROLL(this.cxptrnpagodocService.documentos,this.defaultColDefDocumentos.objeto,indi - 1)
      this.cxptrnpagodocService.calcularImporteTotal();
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefDocumentos.objeto].setFocusedCell(indi, 'SELECCIONADOFPCOM');
      // setTimeout(()=>{
      //   this.contenedor.nativeElement.style.overflow='auto';
      // },100)
    } else {
      valor.data.TRNDOCUMENTO_IMPORTE = 0;
      valor.data.TRNDOCUMENTO_IMPORTE = valor.data.TRNDOCUMENTO_IMPORTE.toFixed(this.confIniciales.getNumDecSist());
      valor.data.SALDO = valor.data.SALDOORIGINAL;
      
      //this.aggrid.refreshaggrid(this.cxptrnpagodocService.documentos, this.defaultColDefDocumentos.objeto);
      this.aggrid.refreshaggridindexSNMVSCROLL(this.cxptrnpagodocService.documentos,this.defaultColDefDocumentos.objeto,indi - 1)
      this.cxptrnpagodocService.calcularImporteTotal();
      //this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefDocumentos.objeto].setFocusedCell(indi + 1, 'SELECCIONADOFPCOM');
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefDocumentos.objeto].setFocusedCell(indi, 'SELECCIONADOFPCOM');
    }

     //this.aggrid.refreshGridData(this.cxptrnpagodocService.documentos);

  }

  encontrarPagoDoc(strTrnCobroNroDoc) {
    let strSentencia = '';
    let strTipoTrans = '';
    let strTrnPagoTipo = '';
    const strBeneficiario = '';
    let strPagoElectronico = '';
    let StrTipoDiario = '';

    let strControlAbrirDoc = '';


    strSentencia = 'select a.*,b.pro_codigo,b.pro_nombre from cxp_trncobro a,com_maeproveedor b ';
    strSentencia = strSentencia + ' where b.pro_codigo(+)=a.pro_codigo and trncobro_nrodoc= ' + '\'' + strTrnCobroNroDoc + '\'';
    strSentencia = strSentencia + ' and a.com_codigo=' + '\'01\'';

    this.limpiarDatos();

    this.cxptrnpagodocService.consultarPagoDoc(strTrnCobroNroDoc).subscribe(data => {
      for (const rs of data) {
        this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC = rs.TRNCOBRO_NRODOC === null ? '' : rs.TRNCOBRO_NRODOC;
        this.cxptrnpagodocService.trncobro.PRO_CODIGO = rs.PRO_CODIGO === null ? '' : rs.PRO_CODIGO;
        this.cxptrnpagodocService.trncobro.APLORG_CODIGO = rs.APLORG_CODIGO === null ? '' : rs.APLORG_CODIGO;
        this.cxptrnpagodocService.trncobro.PRO_NOMBRE = rs.PRO_NOMBRE === null ? '' : rs.PRO_NOMBRE;
        this.cxptrnpagodocService.trncobro.TRNBAN_BENEFICIARIO = rs.TRNBAN_BENEFICIARIO === null ? '' : rs.TRNBAN_BENEFICIARIO;
        this.cxptrnpagodocService.trncobro.TRNCOBRO_CONCEPTO = rs.TRNCOBRO_CONCEPTO === null ? '' : rs.TRNCOBRO_CONCEPTO;
        this.cxptrnpagodocService.trncobro.ASI_NRO = rs.ASI_NRO === null ? '' : rs.ASI_NRO;
        this.disasicomprobante = true;

        if (this.cxptrnpagodocService.trncobro.APLORG_CODIGO === 'BAN') {
          document.getElementById('divPrincipal').style.pointerEvents = 'none';
          this.botonBorrar = true;
        } else {
          document.getElementById('divPrincipal').style.pointerEvents = 'all';
          this.botonBorrar = false;
        }

        if ((rs.TRNCOBRO_ESTADO === null ? '' : rs.TRNCOBRO_ESTADO) === 'A') {
          this.anulado = 'ANULADO';
        }

        if ((rs.TRNCOBRO_IMPRESO === null ? '' : rs.TRNCOBRO_IMPRESO) === 'S') {
          this.impreso = 'IMPRESO';
          this.impreso = 'IMPRESO';
        } else {
          this.impreso = '';
        }

        this.strCodigoConPro = rs.CON_CODIGO === null ? '' : rs.CON_CODIGO;

        StrTipoDiario = rs.TIPDIA_CODIGO === null ? '' : rs.TIPDIA_CODIGO;

        this.actualizarTipDiario(StrTipoDiario);

        this.cxptrnpagodocService.trncobro.TRNCOBRO_IMPORTE = rs.TRNCOBRO_IMPORTE.toFixed(this.cxptrnpagodocService.decimalesdetpagoDoc);

        this.cxptrnpagodocService.trncobro.APLORG_CODIGO = rs.APLORG_CODIGO === null ? '' : rs.APLORG_CODIGO;

        this.cxptrnpagodocService.trncobro.TRNCOBRO_FECHATRN = new Date(rs.TRNCOBRO_FECHATRN);

        strTipoTrans = rs.TRNCOBRO_TIPODOC == null ? '' : rs.TRNCOBRO_TIPODOC;

        if (strTipoTrans === 'AB') {
          this.selecttipodoc = { codigo: 'Abono', name: 'Abono' };
        } else { // Cancelación
          this.selecttipodoc = { codigo: 'Cancelación', name: 'Cancelación' };
        }

        strPagoElectronico = rs.TRNCOBRO_PAGOELECTRONICO == null ? '' : rs.TRNCOBRO_PAGOELECTRONICO;

        if (strPagoElectronico === 'S') {
          this.chkpagoelectronico = true;
          this.disnumcheque = true;
        }

        this.desplegarAsientos();


        strControlAbrirDoc = '1';

        strSentencia = 'select * from cxp_trnpago';
        strSentencia = strSentencia + ' where  trncobro_nrodoc= ' + '\'' + strTrnCobroNroDoc + '\'';
        strSentencia = strSentencia + ' and  pro_codigo= ' + '\'' + this.cxptrnpagodocService.trncobro.PRO_CODIGO + '\'';

        this.cxptrnpagodocService.consultarCXPTrnPago(strTrnCobroNroDoc, this.cxptrnpagodocService.trncobro.PRO_CODIGO).subscribe(async (data2) => {
          for (const rs2 of data2) {
            // Forma de pago
            strTrnPagoTipo = rs2.TRNPAGO_TIPO === null ? '' : rs2.TRNPAGO_TIPO;

            if (strTrnPagoTipo === 'CH') {
              this.selectfp = { codigo: this.cmbfp[0].codigo, name: this.cmbfp[0].name };
            }
            if (strTrnPagoTipo === 'EF') {
              this.selectfp = { codigo: this.cmbfp[1].codigo, name: this.cmbfp[1].name };
            }
            if (strTrnPagoTipo === 'VA') {
              this.selectfp = { codigo: this.cmbfp[2].codigo, name: this.cmbfp[2].name };
            }
            if (strTrnPagoTipo === 'CJ') {
              this.selectfp = { codigo: this.cmbfp[3].codigo, name: this.cmbfp[3].name };
            }
            if (strTrnPagoTipo === 'ND') {
              this.selectfp = { codigo: this.cmbfp[4].codigo, name: this.cmbfp[4].name };
            }

            this.cxptrnpagodocService.trncobro.TRNPAGO_NUMERO = rs2.TRNPAGO_NUMERO === null ? '' : rs2.TRNPAGO_NUMERO;
            this.cxptrnpagodocService.trncobro.BAN_CODIGO = rs2.BAN_CODIGO === null ? '' : rs2.BAN_CODIGO;
            this.cxptrnpagodocService.trncobro.TRNBAN_NUMERO = rs2.TRNBAN_NUMERO === null ? '' : rs2.TRNBAN_NUMERO;
            this.cxptrnpagodocService.trncobro.CAJ_CODIGO = rs2.CAJ_CODIGO === null ? '' : rs2.CAJ_CODIGO;

            this.cxptrnpagodocService.trncobro.TRNPAGO_FECHA = new Date(rs2.TRNPAGO_FECHA);
          }

          // Trae datos del cheque
          if (this.anulado === 'ANULADO') {
            // TRAE  LOS DATOS DEL CHEQUE DE LA TABLA BAN_TRNBAN
            strSentencia = 'select * from ban_trnban';
            strSentencia = strSentencia + ' where  asi_nro= ' + '\'' + this.cxptrnpagodocService.trncobro.ASI_NRO + '\'';
            strSentencia = strSentencia + ' and  com_codigo= ' + '\'01\'';

            this.cxptrnpagodocService.consultarBantrnban(this.cxptrnpagodocService.trncobro.ASI_NRO).subscribe(data3 => {
              for (const rs3 of data3) {
                if (rs3.TRNBAN_TIPO === 'CH') {

                  this.cxptrnpagodocService.trncobro.TRNBAN_BENEFICIARIO = rs3.TRNBAN_BENEFICIARIO === null ? '' : rs3.TRNBAN_BENEFICIARIO;
                  this.cxptrnpagodocService.trncobro.TRNBAN_NUMERO = rs3.TRNBAN_NUMERO === null ? '' : rs3.TRNBAN_NUMERO;
                  this.cxptrnpagodocService.trncobro.BAN_CODIGO = rs3.BAN_CODIGO === null ? '' : rs3.BAN_CODIGO;
                  this.cxptrnpagodocService.trncobro.TRNBAN_NUMERO = rs3.TRNBAN_NUMERO === null ? '' : rs3.TRNBAN_NUMERO;
                }
              }
            });
          } // fin si la transaccion esta anulada


          // Nombre del banco
          if (this.selectfp.codigo === 'Cheque' || this.selectfp.codigo === 'ND') {
            this.cxptrnpagodocService.erBanBanco2(this.cxptrnpagodocService.trncobro.BAN_CODIGO).subscribe(er => {
              if (er !== null) {
                if (er[0] !== undefined) {
                  this.cxptrnpagodocService.trncobro.BAN_NOMBRE = er[0].BAN_BANCO;
                }
              }
            });
          }
          // Nombre de la caja
          if (this.selectfp.codigo === 'Efectivo') {
            this.cxptrnpagodocService.erCajDescripcion2(this.cxptrnpagodocService.trncobro.CAJ_CODIGO).subscribe(er => {
              if (er !== null) {
                if (er[0] !== undefined) {
                  this.cxptrnpagodocService.trncobro.CAJ_NOMBRE = er[0].CAJ_DESCRIPCION;
                }
              }
            });
          }

          // Número del comprobante
          this.cxptrnpagodocService.erAsiComprobante(this.cxptrnpagodocService.trncobro.ASI_NRO).subscribe(er => {
            if (er !== null) {
              if (er[0] !== undefined) {
                this.cxptrnpagodocService.trncobro.TRNCOBRO_REFERENCIAEXTERNA = er[0].ASI_COMPROBANTE;
              }
            }
          });

          this.mostrarDocumentos(strTrnCobroNroDoc);

          this.mostrarVarios(this.cxptrnpagodocService.trncobro.PRO_CODIGO);

          this.abonosCXC();

          strControlAbrirDoc = '0';
          this.bolbanco = true;///GSRF
          this.discmbtipodiario = true;
          this.discodproveedor = true;

          // this.verificarPeriodoContableGuardar();

          if (this.cxptrnpagodocService.trncobro.APLORG_CODIGO !== 'CXP' || this.anulado === 'ANULADO' ||
            (this.impreso === 'IMPRESO' && this.intCHEQIMP === 1)) {
            this.strControlActivaOpciones = 'NoModificar';
          }

          if (await this.VerificarChequeConciliado() === true) {
            this.strControlActivaOpciones = 'NoModificar';
            this.lblMensaje = 'Documento contiene un  cheque conciliado';
            this.botonBorrar = true;
          }

          this.cxp_fnc_verificar_trans_cash(this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC,
            this.cxptrnpagodocService.trncobro.PRO_CODIGO, 'PAGODOC', 'encontrar');

          if (this.strControlActivaOpciones === 'NoModificar') {
          } else {
          }

        });
        this.control=0;//CDPJ
        this.spin=false;//CDPJ
      }
    });
  }

  mostrarDocumentos(strTrnCobroNroDoc) {

    this.cxptrnpagodocService.documentos = [];

    let dblPagado = 0;
    let dblRetencion = 0;
    let dblSaldo = 0;
    let dblTrnCobroImporte = 0;
    let dblSaldoOriginal = 0;
    let dblTrnDocumentoDescuento = 0;
    let dblTrnDocumentoImporte = 0;
    let dblNotasCredito = 0;
    let intIndiceLista = 0;

    let strSentencia = 'select a.*,trncobro_importe as importe,b.trncobro_concepto,trncobro_fechatrn,trncobro_fechavence,';
    strSentencia = strSentencia + ' cxp_fnc_saldodocumento(b.trncobro_nrodoc,a.com_codigo,a.pro_codigo) as saldo,';
    strSentencia = strSentencia + ' cxp_fnc_ImporteXTipoTrans(b.trncobro_nrodoc,a.com_codigo,a.pro_codigo,\'NC\')*-1 as NotasCredito,';
    strSentencia = strSentencia + ' cxp_fnc_PagadoDocumento(b.trncobro_nrodoc,a.com_codigo,a.pro_codigo) as pagado, ';
    strSentencia = strSentencia + ' cxp_fnc_ImporteXTipoTrans(b.trncobro_nrodoc,a.com_codigo,a.pro_codigo,\'RT\')*-1 as retenciones,trncobro_nrocomprobante ';
    strSentencia = strSentencia + ' from cxp_trndocumento a,cxp_trncobro b ';
    strSentencia = strSentencia + ' where ';
    strSentencia = strSentencia + ' a.trncobro_nrodoc=' + '\'' + strTrnCobroNroDoc + '\'';
    strSentencia = strSentencia + ' and a.com_codigo=' + '\'01\'';
    strSentencia = strSentencia + ' and a.trndocumento_nrodoc=b.trncobro_nrodoc';
    strSentencia = strSentencia + ' and a.pro_codigo=b.pro_codigo';
    strSentencia = strSentencia + ' and a.trndocumento_tipodoc=b.trncobro_tipodoc';
    strSentencia = strSentencia + ' and a.com_codigo=b.com_codigo';
    strSentencia = strSentencia + ' order by trncobro_fechatrn asc ';

    this.cxptrnpagodocService.consultarDocumentos(strTrnCobroNroDoc).subscribe(data => {
      for (const rs of data) {
        const item: CxpTrndocumento = {};

        dblNotasCredito = rs.NOTASCREDITO;
        item.DEVOLUCION = dblNotasCredito.toFixed(this.confIniciales.getNumDecSist());

        dblPagado = rs.PAGADO;
        item.PAGADO = dblPagado.toFixed(this.confIniciales.getNumDecSist());

        dblRetencion = rs.RETENCIONES;
        item.RETENCION = dblRetencion.toFixed(this.confIniciales.getNumDecSist());

        dblSaldo = rs.SALDO;
        item.SALDO = dblSaldo.toFixed(this.confIniciales.getNumDecSist());

        dblTrnCobroImporte = rs.IMPORTE;
        item.TRNCOBRO_IMPORTE = dblTrnCobroImporte.toFixed(this.confIniciales.getNumDecSist());

        dblTrnDocumentoDescuento = rs.TRNDOCUMENTO_DESCUENTO;
        item.TRNDOCUMENTO_DESCUENTO = dblTrnDocumentoDescuento.toFixed(this.confIniciales.getNumDecSist());

        dblTrnDocumentoImporte = rs.TRNDOCUMENTO_IMPORTE;
        item.TRNDOCUMENTO_IMPORTE = dblTrnDocumentoImporte.toFixed(this.confIniciales.getNumDecSist());

        dblSaldoOriginal = dblSaldo + dblTrnDocumentoImporte;
        item.SALDOORIGINAL = dblSaldoOriginal.toFixed(this.confIniciales.getNumDecSist());

        item.NROCUOTAS = 0;
        item.TCUOTAS = 0;
        item.TRNCOBRO_CONCEPTO = rs.TRNCOBRO_CONCEPTO;

        item.TRNCOBRO_FECHATRANS = this.datePipe.transform(new Date(rs.TRNCOBRO_FECHATRN), 'dd/MM/yyyy');
        item.TRNCOBRO_FECHAVENCE = this.datePipe.transform(new Date(rs.TRNCOBRO_FECHAVENCE), 'dd/MM/yyyy');
        item.TRNDOCUMENTO_NRODOC = rs.TRNDOCUMENTO_NRODOC === null ? '' : rs.TRNDOCUMENTO_NRODOC;
        item.TRNDOCUMENTO_REF = rs.TRNDOCUMENTO_REF === null ? '' : rs.TRNDOCUMENTO_REF;
        item.TRNDOCUMENTO_TIPODOC = rs.TRNDOCUMENTO_TIPODOC === null ? '' : rs.TRNDOCUMENTO_TIPODOC;
        item.TRNDOCUMENTO_TIPODSCTO = rs.TRNDOCUMENTO_TIPODSCTO === null ? '' : rs.TRNDOCUMENTO_TIPODSCTO;

        item.TRNCOBRO_NROCOMPROBANTE = rs.TRNCOBRO_NROCOMPROBANTE === null ? '' : rs.TRNCOBRO_NROCOMPROBANTE;

        item.SELECCIONADOFPCOM = true;
        this.cxptrnpagodocService.check=1;//CDPJ

        intIndiceLista = intIndiceLista + 1;
        this.cxptrnpagodocService.documentos.push(item);
      }
      this.aggrid.refreshaggrid(this.cxptrnpagodocService.documentos, this.defaultColDefDocumentos.objeto);
    });
  }

  async mostrarVarios(StrCodigoProveedor) {

    this.cxptrnpagodocService.varios = [];
    let dblImporte = 0;

    const data = await this.cxptrnpagodocService.consultarDetTrnCobro(this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC);
    if (data !== null) {
      for (const rs of data) {
        const item: CxpDettrncobro = {};

        if (rs.DETTRNCOBRO_IMPORTE > 0) {
          dblImporte = rs.DETTRNCOBRO_IMPORTE;
          item.DEBE = dblImporte.toFixed(this.confIniciales.getNumDecSist());
          item.HABER = Number(0).toFixed(this.confIniciales.getNumDecSist());
        } else {
          dblImporte = rs.DETTRNCOBRO_IMPORTE * -1;
          item.HABER = dblImporte.toFixed(this.confIniciales.getNumDecSist());
          item.DEBE = Number(0).toFixed(this.confIniciales.getNumDecSist());
        }

        item.DETTRNCOBRO_NROSEC = rs.DETTRNCOBRO_NROSEC;
        item.BAN_CODIGO = rs.BAN_CODIGO === null ? '' : rs.BAN_CODIGO;
        item.CEN_CODIGO = rs.CEN_CODIGO === null ? '' : rs.CEN_CODIGO;
        item.COM_CODIGO = rs.COM_CODIGO;
        item.CON_CODIGO = rs.CON_CODIGO === null ? '' : rs.CON_CODIGO;
        item.CON_CENTRO = rs.BAN_CODIGO === null ? '' : rs.BAN_CODIGO;
        item.CON_NOMBRE = rs.CON_NOMBRE === null ? '' : rs.CON_NOMBRE;
        item.CXXTRNCOBRO_CLICODIGO = rs.CXXTRNCOBRO_CLICODIGO === null ? '' : rs.CXXTRNCOBRO_CLICODIGO;
        item.CXCTRNCOBRO_COMCODIGO = rs.CXCTRNCOBRO_COMCODIGO === null ? '' : rs.CXCTRNCOBRO_COMCODIGO;
        item.CXCTRNCOBRO_NRODOC = rs.CXCTRNCOBRO_NRODOC === null ? '' : rs.CXCTRNCOBRO_NRODOC;
        item.DETTRNCOBRO_REFERENCIA = rs.DETTRNCOBRO_REFERENCIA === null ? '' : rs.DETTRNCOBRO_REFERENCIA;
        item.ENCFAC_CLICODIGO = rs.ENCFAC_CLICODIGO === null ? '' : rs.ENCFAC_CLICODIGO;
        item.ENCFAC_COMCODIGO = rs.ENCFAC_COMCODIGO === null ? '' : rs.ENCFAC_COMCODIGO;
        item.ENCFAC_NUMERO = rs.ENCFAC_NUMERO === null ? '' : rs.ENCFAC_NUMERO;
        item.PRO_CODIGO = rs.PRO_CODIGO === null ? '' : rs.PRO_CODIGO;
        item.RETENCION_CODIGO = rs.RETENCION_CODIGO === null ? '' : rs.RETENCION_CODIGO;
        item.TRNBAN_NUMERO = rs.TRNBAN_NUMERO === null ? '' : rs.TRNBAN_NUMERO;
        item.TRNBAN_TIPO = rs.TRNBAN_TIPO === null ? '' : rs.TRNBAN_TIPO;
        item.TRNCOBRO_NRODOC = rs.TRNCOBRO_NRODOC === null ? '' : rs.TRNCOBRO_NRODOC;
        item.TRNRETENCION_NRO = rs.TRNRETENCION_NRO === null ? '' : rs.TRNRETENCION_NRO;
        item.DETTRNCOBRO_TIPO = rs.DETTRNCOBRO_TIPO === null ? '' : rs.DETTRNCOBRO_TIPO;
        item.DETTRNCOBRO_COMPROBANTE = rs.DETTRNCOBRO_COMPROBANTE === null ? '' : rs.DETTRNCOBRO_COMPROBANTE;
        item.TIPGAS_CODIGO = rs.TIPGAS_CODIGO === null ? '' : rs.TIPGAS_CODIGO;

        let strDato = '';

        const er = this.cxptrnpagodocService.erConTipGasProm(item.CON_CODIGO);
        if (er !== null) {
          if (er[0] !== undefined) {
            strDato = er[0].CON_TIPGAS;
          }
        }

        if (strDato === 'Si') {
          item.BOLTIPOGASTO = true;
        }

        this.cxptrnpagodocService.varios.push(item);
      }
      this.ponerColorAFilaxTipoCuenta();
    }
  }

  limpiarDatos() {
    this.nuevo();
  }

  actualizarTipDiario(strCodigoTipDia) {
    for (const rs of this.cmbtd) {
      if (rs.codigo === strCodigoTipDia) {
        this.selecttd = { codigo: rs.codigo, name: rs.name };

      }
    }
  }

  async desplegarAsientos() {
    let strSentencia = '';

    strSentencia = 'select asi_nro,asi_nrochposfh from cxp_trncobro where trncobro_nrodoc=' + '\'' + this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC + '\'';
    strSentencia = strSentencia + ' and com_codigo=' + '\'01\'';

    try {
      const data = await this.cxptrnpagodocService.consultarAsientos(this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC);
      this.cmbasiento = [];
      for (const rs of data) {
        if (rs.ASI_NRO !== null) {
          this.cmbasiento.push({ codigo: rs.ASI_NRO === null ? '' : rs.ASI_NRO, name: rs.ASI_NRO === null ? '' : rs.ASI_NRO });
          this.cmbasiento.push({
            codigo: rs.ASI_NROCHPOSFH === null ? '' : rs.ASI_NROCHPOSFH,
            name: rs.ASI_NROCHPOSFH === null ? '' : rs.ASI_NROCHPOSFH
          });
        }
      }

      if (this.cmbasiento.length > 0) {
        this.selectasiento = { codigo: this.cmbasiento[0].codigo, name: this.cmbasiento[0].name };
      }
    } catch (err) {
      this.spin=false;//CDPJ
      this.control=0;//CDPJ
    }
    /* this.cxptrnpagodocService.ejecutarConsulta(strSentencia).subscribe(data => {
      this.cmbasiento = [];
      for (const rs of data) {
        if (rs.ASI_NRO !== null) {
          this.cmbasiento.push({ codigo: rs.ASI_NRO === null ? '' : rs.ASI_NRO, name: rs.ASI_NRO === null ? '' : rs.ASI_NRO });
          this.cmbasiento.push({
            codigo: rs.ASI_NROCHPOSFH === null ? '' : rs.ASI_NROCHPOSFH,
            name: rs.ASI_NROCHPOSFH === null ? '' : rs.ASI_NROCHPOSFH
          });
        }
      }

      if (this.cmbasiento.length > 0) {
        this.selectasiento = { codigo: this.cmbasiento[0].codigo, name: this.cmbasiento[0].name };
      }
    }); */
  }

  comprobarTipoGasto(varios) {
    if (varios.BOLTIPOGASTO === true) {
      return true;
    } else {
      return false;
    }
  }

  comprobarTipoGastocell(varios) {
    if (varios.BOLTIPOGASTO === true) {
      return {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          opcionbuscar: true
        }
      };
    } else {
      return {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          opcionbuscar: false
        }
      };
    }
  }

  abonosCXC() {

    let strSentencia = '';

    strSentencia = 'select distinct cxctrncobro_nrodoc from cxp_dettrncobro where trncobro_nrodoc=' +
      '\'' + this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC + '\'';
    strSentencia = strSentencia + ' and com_codigo=' + '\'01\'';
    strSentencia = strSentencia + ' and not cxctrncobro_nrodoc is null';

    this.cxptrnpagodocService.consultarAbonos(this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC).subscribe(data => {
      this.cmbabono = [];
      for (const rs of data) {
        this.cmbabono.push({ codigo: rs.CXCTRNCOBRO_NRODOC, name: rs.CXCTRNCOBRO_NRODOC });
      }
    });
  }

  async VerificarChequeConciliado() {
    let bolresultado = false;
    if (this.selectfp.codigo === 'Cheque') {
      if (await this.documentoConciliado(this.cxptrnpagodocService.trncobro.TRNBAN_NUMERO,
        this.cxptrnpagodocService.trncobro.BAN_CODIGO, 'CH')) {
        bolresultado = true;
      }
    }
    await this.movimientosBancosEnVarios();
    for (const item of this.listaTiposMovBancos) {
      if (await this.documentoConciliado(item.TRNBAN_NUMERO, item.BAN_CODIGO, item.TRNBAN_TIPO) === true) {
        bolresultado = true;
      }
    }
    return bolresultado;
  }

  async movimientosBancosEnVarios() {
    this.listaTiposMovBancos = [];
    let strSentencia = 'select distinct ban_codigo,trnban_tipo,trnban_numero from cxp_dettrncobro where trncobro_nrodoc=' +
      '\'' + this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC + '\'';
    strSentencia = strSentencia + ' and com_codigo=' + '\'01\'';
    try {
      const data = await this.cxptrnpagodocService.consultarMovBancosVarios(this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC);
      for (const rs of data) {
        this.listaTiposMovBancos.push({ BAN_CODIGO: rs.BAN_CODIGO, TRNBAN_TIPO: rs.TRNBAN_TIPO, TRNBAN_NUMERO: rs.TRNBAN_NUMERO });
      }
    } catch (error) {
      this.messageService.add({
        key: 'encfacpropago',
        severity: 'error',
        summary: 'Error',
        detail: '! Proceso verificar conciliación de movimientos de banco en varios,' +
          '      no terminó satisfactoriamente.... !'
      });
    }
  }

  async documentoConciliado(strNumDoc, strCodBanco, strTipoDoc) {
    let strConcilia = 0;
    let bolconcilia = false;
    const er = await this.cxptrnpagodocService.erTrnBanConciliaProm(strNumDoc, strCodBanco, strTipoDoc);
    if (er !== null) {
      if (er[0] !== undefined) {
        strConcilia = er[0].TRNBAN_CONCILIA;
      }
    }

    if (strConcilia === 1) {
      bolconcilia = false;
    }

    return bolconcilia;
  }

  cxp_fnc_verificar_trans_cash(strDocumento, strProCodigo, strTipo, accion) {

    const strSql = 'SELECT cxp_fnc_verificar_trans_cash(\'' + strDocumento + '\',' + '\'' + strProCodigo + '\', \'' + strTipo + '\') AS SALDO FROM DUAL';

    let intSaldo = 0;

    this.cxptrnpagodocService.consultarVerifTransCash(strDocumento, strProCodigo, strTipo).subscribe(data => {
      for (const rs of data) {
        intSaldo = rs.SALDO;
      }
      if (intSaldo >= 1) {
        if (accion === 'encontrar') {
          this.strControlActivaOpciones = 'NoModificar';
          this.lblMensaje = 'Documento incluido en el Cash Management';
        }

        if (accion === 'eliminar') {
          this.messageService.add({
            key: 'encfacpropago',
            severity: 'error',
            summary: 'Información',
            detail: 'No puede eliminar ya que esta incluido en un documento del Cash Management.'
          });
        }
      } else {
        if (accion === 'eliminar') {
          this.eliminar2();
        }
        if (accion === 'anular') {
          this.anular2();
        }


      }
    });
  }

  seleccionarRegistro(strTipo) {
    if (this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC === undefined) {
      this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC = '';
    }

    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;

    this.irARegistro(strTipo);

  }


  irARegistro(strTipo) {
    this.cxptrnpagodocService.seleccionarRegistro(strTipo, this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC).subscribe((datos: any[]) => {

      if (datos !== null) {
        this.encontrarPagoDoc(datos[0].TRNCOBRO_NRODOC);
        // this.nuevafact = true;
      }
    }, error1 => {
      const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'encfacpropago',
        severity: 'error',
        summary: 'Error',
        detail: error
      });
    });
  }

  listarDatos(StrCodigoProveedor) {
    this.cxptrnpagodocService.documentos = [];
    let dblPagado = 0;
    let dblRetencion = 0;
    let dblSaldo = 0;
    let dblTrnCobroImporte = 0;
    let dblTrnDocumentoDescuento = 0;
    let dblTrnDocumentoImporte = 0;
    let dblNotasCredito = 0;
    let dblSaldoOriginal = 0;
    this.chkpagoelectronico = false;//CDPJ

    let strSentencia = 'select a.*,cxp_fnc_saldodocumento(a.trncobro_nrodoc,a.com_codigo,a.pro_codigo) as saldo,';
    strSentencia = strSentencia + ' cxp_fnc_PagadoDocumento(a.trncobro_nrodoc,a.com_codigo,a.pro_codigo) as pagado,';
    strSentencia = strSentencia + ' cxp_fnc_ImporteXTipoTrans(a.trncobro_nrodoc,a.com_codigo,a.pro_codigo,\'RT\')*-1 as retenciones,';
    strSentencia = strSentencia + ' cxp_fnc_ImporteXTipoTrans(a.trncobro_nrodoc,a.com_codigo,a.pro_codigo,\'NC\')*-1 as NotasCredito ';
    strSentencia = strSentencia + ' from cxp_trncobro a';
    strSentencia = strSentencia + ' where trncobro_tipodoc in (\'FC\',\'ND\')';
    strSentencia = strSentencia + ' and pro_codigo=' + '\'' + StrCodigoProveedor + '\'';
    strSentencia = strSentencia + ' and com_codigo=' + '\'01\'';
    strSentencia = strSentencia + ' and cxp_fnc_saldodocumento(a.trncobro_nrodoc,a.com_codigo,a.pro_codigo)<>0 ';
    strSentencia = strSentencia + ' order by trncobro_fechatrn asc ';

    this.cxptrnpagodocService.consultarDatos(StrCodigoProveedor).subscribe(data => {
      for (const rs of data) {
        const item: CxpTrndocumento = {};

        dblNotasCredito = rs.NOTASCREDITO;
        item.DEVOLUCION = dblNotasCredito.toFixed(this.confIniciales.getNumDecSist());

        dblPagado = rs.PAGADO;
        item.PAGADO = dblPagado.toFixed(this.confIniciales.getNumDecSist());

        dblRetencion = rs.RETENCIONES;
        item.RETENCION = dblRetencion.toFixed(this.confIniciales.getNumDecSist());

        dblSaldo = rs.SALDO;
        item.SALDO = dblSaldo.toFixed(this.confIniciales.getNumDecSist());

        dblTrnCobroImporte = rs.TRNCOBRO_IMPORTE;
        item.TRNCOBRO_IMPORTE = dblTrnCobroImporte.toFixed(this.confIniciales.getNumDecSist());

        dblTrnDocumentoDescuento = 0;
        item.TRNDOCUMENTO_DESCUENTO = dblTrnDocumentoDescuento.toFixed(this.confIniciales.getNumDecSist());

        dblTrnDocumentoImporte = 0;
        item.TRNDOCUMENTO_IMPORTE = dblTrnDocumentoImporte.toFixed(this.confIniciales.getNumDecSist());

        dblSaldoOriginal = dblSaldo + dblTrnDocumentoImporte;
        item.SALDOORIGINAL = dblSaldoOriginal.toFixed(this.confIniciales.getNumDecSist());

        item.NROCUOTAS = 0;
        item.TCUOTAS = 0;
        item.TRNCOBRO_CONCEPTO = rs.TRNCOBRO_CONCEPTO === null ? '' : rs.TRNCOBRO_CONCEPTO;

        item.TRNCOBRO_FECHATRANS = this.datePipe.transform(new Date(rs.TRNCOBRO_FECHATRN), 'dd/MM/yyyy');
        item.TRNCOBRO_FECHAVENCE = this.datePipe.transform(new Date(rs.TRNCOBRO_FECHAVENCE), 'dd/MM/yyyy');
        item.TRNDOCUMENTO_NRODOC = rs.TRNCOBRO_NRODOC === null ? '' : rs.TRNCOBRO_NRODOC;
        item.TRNDOCUMENTO_REF = rs.TRNCOBRO_TIPODOC === null ? '' : rs.TRNCOBRO_TIPODOC;
        item.TRNDOCUMENTO_TIPODOC = rs.TRNCOBRO_TIPODOC === null ? '' : rs.TRNCOBRO_TIPODOC;
        item.TRNDOCUMENTO_TIPODSCTO = '';

        item.TRNCOBRO_NROCOMPROBANTE = rs.TRNCOBRO_NROCOMPROBANTE === null ? '' : rs.TRNCOBRO_NROCOMPROBANTE;

        if (item.TRNDOCUMENTO_NRODOC === this.auxNumFact) {
          item.SELECCIONADOFPCOM = true;
          if(item.SELECCIONADOFPCOM === true){
            this.cxptrnpagodocService.check=1;//CDPJ
          }
          item.TRNDOCUMENTO_IMPORTE = item.SALDO;
        } else {
          item.SELECCIONADOFPCOM = false;
        }
        if (Number(item.SALDO) !== 0) {
          this.cxptrnpagodocService.documentos.push(item);
        }
      }

      this.aggrid.refreshaggrid(this.cxptrnpagodocService.documentos, this.defaultColDefDocumentos.objeto);
      this.cxptrnpagodocService.calcularImporteTotal();

    });
  } // fin listar datos

  nuevovarios() {
    const item: CxpDettrncobro = {};

    item.DEBE = 0;
    item.DETTRNCOBRO_IMPORTE = 0;
    item.HABER = 0;
    item.DETTRNCOBRO_NROSEC = 0;
    item.BAN_CODIGO = '';
    item.CEN_CODIGO = '';
    item.COM_CODIGO = '01';
    item.CON_CENTRO = '';
    item.CON_CODIGO = '';
    item.CON_NOMBRE = '';
    item.CXCTRNCOBRO_CLICODIGO = '';
    item.CXCTRNCOBRO_COMCODIGO = '';
    item.CXCTRNCOBRO_NRODOC = '';
    item.DETTRNCOBRO_REFERENCIA = '';
    item.ENCFAC_CLICODIGO = '';
    item.ENCFAC_COMCODIGO = '';
    item.ENCFAC_NUMERO = '';
    item.PRO_CODIGO = this.cxptrnpagodocService.trncobro.PRO_CODIGO;
    item.RETENCION_CODIGO = '';
    item.TRNBAN_NUMERO = '';
    item.TRNBAN_TIPO = '';
    item.TRNCOBRO_NRODOC = this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC;
    item.TRNRETENCION_NRO = '';
    item.DETTRNCOBRO_COMPROBANTE = '';
    item.DETTRNCOBRO_REFERENCIA = '';
    item.DETTRNCOBRO_TIPO = '';
    item.TIPGAS_CODIGO = '';


    const cxptrncobro: CxpDettrncobro = item;
    const indi = this.cxptrnpagodocService.varios.indexOf(this.variosSelected);

    if (indi === -1) {
      cxptrncobro.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.cxptrnpagodocService.varios.push(cxptrncobro);
      this.variosSelected = cxptrncobro;
      this.aggrid.refreshaggrid(this.cxptrnpagodocService.varios, this.defaultColDefVarios.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefVarios.objeto].setFocusedCell(indi + 1, 'CON_CODIGO');
    } else {
      cxptrncobro.CLAVE = (indi + 1) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.cxptrnpagodocService.varios.splice(indi + 1, 0, cxptrncobro);
      this.variosSelected = cxptrncobro;
      this.aggrid.refreshaggridindex(this.cxptrnpagodocService.varios, this.defaultColDefVarios.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefVarios.objeto].setFocusedCell(indi + 1, 'CON_CODIGO');
    }
    this.botonesmodificar();
  }

  verificarComprobante(): boolean {
    if (this.cxptrnpagodocService.trncobro.TRNCOBRO_REFERENCIAEXTERNA !== '') {
      if (this.selecttd.codigo !== this.cxptrnpagodocService.trncobro.TRNCOBRO_REFERENCIAEXTERNA.substring(0, this.selecttd.codigo.length)) {
        this.messageService.add({
          key: 'encfacpropago',
          severity: 'error',
          summary: 'Error',
          detail: 'Error, compruebe que el número de asiento empiece con ' + this.selecttd.codigo
        });
        return false;
      }
    }

    /*if (this.cxptrnpagodocService.trncobro.ASI_NRO !== '') {
      if (this.selecttd.codigo !== this.cxptrnpagodocService.trncobro.ASI_NRO.substring(0, this.selecttd.codigo.length)) {
        this.messageService.add({
          key: 'encfacpropago',
          severity: 'error',
          summary: 'Error',
          detail: 'Error, compruebe que el número de asiento empiece con ' + this.selecttd.codigo
        });
        return false;
      }
    }*/
    return true;

  }  // Fin verificar Comprobante

  verificarIngresoTG(): boolean {

    let intIndiceLista = 0;
    for (intIndiceLista = 0; intIndiceLista < this.cxptrnpagodocService.varios.length; intIndiceLista++) {
      const item = this.cxptrnpagodocService.varios[intIndiceLista];
      if (item.TIPGAS_CODIGO === '' && item.BOLTIPOGASTO === true) {
        this.messageService.add({
          key: 'encfacpropago',
          severity: 'error',
          summary: 'Error',
          detail: 'Ingrese el tipo de gasto en los items de la factura.'
        });
        return false;
      }
    }
    return true;
  }

  faltanCamposDet() {
    let intIndiceLista = 0;
    let item: CxpTrndocumento;
    let intSwControl = 0;

    for (intIndiceLista = 0; intIndiceLista < this.cxptrnpagodocService.documentos.length; intIndiceLista++) {
      item = this.cxptrnpagodocService.documentos[intIndiceLista];
      if (item.SELECCIONADOFPCOM === true) {// si esta seleccionado
        if (Number(item.TRNDOCUMENTO_IMPORTE) === 0) {
          this.messageService.add({
            key: 'encfacpropago',
            severity: 'error',
            summary: 'Información',
            detail: 'Error, Ingrese el valor de importe.'
          });
          /*frmCxpTrnpagoDoc.getTableDocumentos().setSelection(10, intIndiceLista + 1, true);
          frmCxpTrnpagoDoc.getTableDocumentos().redraw();*/
          this.botonGuardar = false;
        
        this.spin=false;//CDPJ
        this.control=0;//CDPJ
          return false;
        }
      }

      intSwControl = 1;

    } // fin de for

    if (intSwControl === 0) {
      this.messageService.add({
        key: 'encfacpropago',
        severity: 'error',
        summary: 'Información',
        detail: 'Error, No existen documentos en el detalle.'
      });
      this.botonGuardar = false;
        
        this.spin=false;//CDPJ
        this.control=0;//CDPJ
      return false;
    }

    return true;

  } // Fin faltan CamposDet

  async elimaTrnDocumento() {
    this.bolelimaTrnDocumento = true;
    let bolaux = true;

    try {
      await this.cxptrnpagodocService.eliminarTRNDOCUMENTO(this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC);
      // this.auditoriagral.registrarAuditoria('CXP_TRNDOCUMENTO',
      //   this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC,
      //   'I', '', '01', '', '', '',
      //   '').subscribe();
    } catch (error1) {
      bolaux = false;
      this.spin=false;//CDPJ
    }

    this.bolelimaTrnDocumento = bolaux;
  }


  async faltanCamposDetVarios(strTipoDoc, strFormaPago, strPagoElectronico, intPosGui) {
    let errores = 0;
    let errores1 = 0;
    let intIndiceLista = 0;
    let item: CxpDettrncobro;
    let strDato = '';

    for (intIndiceLista = 0; intIndiceLista < this.cxptrnpagodocService.varios.length; intIndiceLista++) {
      item = this.cxptrnpagodocService.varios[intIndiceLista];
      if (item.CON_CODIGO === '') {
        errores++;
      } else {
        // Verifica existencia de la cuenta contable
        const er = await this.cxptrnpagodocService.erConNombreProm(item.CON_CODIGO);
        if (er !== null) {
          if (er[0] !== undefined) {
            strDato = er[0].CON_NOMBRE;
          }
        }

        if (strDato === '') {
          errores1++;
          item.CON_CODIGO = '';
        }

      }

      if (item.DEBE === 0 && item.HABER === 0) {
        errores++;
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefVarios.objeto].setFocusedCell(intIndiceLista + 1, 'DEBE');
      }
    } // fin de for

    if (errores > 0) {
      this.messageService.add({
        key: 'encfacpropago',
        severity: 'error',
        summary: 'Información',
        detail: 'Error, Cuenta contable incorrecta.'
      });
      this.botonGuardar = false;
      this.progress = false;
      this.spin=false;//CDPJ
      this.control=0;//CDPJ
      return;
    }

    if (errores1 > 0) {
      this.messageService.add({
        key: 'encfacpropago',
        severity: 'error',
        summary: 'Información',
        detail: 'Error, Código de la cuenta contable incorrecta.'
      });
      this.botonGuardar = false;
      this.progress = false;
      this.spin=false;//CDPJ
      this.control=0;//CDPJ
      return;
    }

    await this.verificarIngresoCentroCostosXItem(strTipoDoc, strFormaPago, strPagoElectronico, intPosGui);
    return true;

  } // Fin faltan CamposDet

  async verificarIngresoCentroCostosXItem(strTipoDoc, strFormaPago, strPagoElectronico, intPosGui) {
    let intIndiceLista = 0;
    let item: CxpDettrncobro;
    let intExiste = true;
    let strAplicaCentroCostos = '';

    for (intIndiceLista = 0; intIndiceLista < this.cxptrnpagodocService.varios.length; intIndiceLista++) {
      item = this.cxptrnpagodocService.varios[intIndiceLista];
      // this.verificarCentroCostosCuenta(item.CON_CODIGO, '');
      try {
        const rs = await this.cxptrnpagodocService.erConCentroProm(item.CON_CODIGO);
        if (rs !== null) {
          strAplicaCentroCostos = rs[0].CON_CENTRO === null ? '' : rs[0].CON_CENTRO;
        }
      } catch (err) {

      }
      if (item.CEN_CODIGO === '' && strAplicaCentroCostos === 'Si') {
        intExiste = false;
      }
    }

    if (intExiste === false) {
      this.messageService.add({
        key: 'encfacpropago',
        severity: 'error',
        summary: 'Información',
        detail: 'Error, Ingrese el centro de costos'
      });
      this.botonGuardar = false;
      this.progress = false;
      this.spin=false;//CDPJ
      this.control=0;//CDPJ
      return;
    }
    // verificar Ingreso de tipo de gastos
    if (this.verificarIngresoTG() === false) {
      this.botonGuardar = false;
      this.progress = false;
      this.spin=false;//CDPJ
      return;
    }
    await this.empezarGuardar(strTipoDoc, strFormaPago, strPagoElectronico, intPosGui);
  }

  verificarCentroCostosCuenta(strCodigo, strTipo) {
    let strAplicaCentro = '';
    let strCuenta = '';

    // si es servicio
    if (strTipo === 'A' || strTipo === 'Y' || strTipo === 'Z') {
      this.cxptrnpagodocService.erConCodigoAct(strCodigo).subscribe(er => {
        if (er !== null) {
          if (er[0] !== undefined) {
            strCuenta = er[0].CON_CODIGOACT;
          }
        }
      });
    }

    if (strTipo === 'S') {
      this.cxptrnpagodocService.erConCodigo(strCodigo).subscribe(er => {
        if (er !== null) {
          if (er[0] !== undefined) {
            strCuenta = er[0].CON_CODIGOACT;
          }
        }
      });
    }

    if (strTipo === 'C' || strTipo === '') {
      strCuenta = strCodigo;
    }

    this.cxptrnpagodocService.erConCentro(strCuenta).subscribe(er => {
      if (er !== null) {
        if (er[0] !== undefined) {
          strAplicaCentro = er[0].CON_CODIGOACT;
        }
      }
    });
  }

  async reversaGrabacionTransacciones() {

    if (this.strControlNuevoReg !== 'N') {
      return;
    }

    await this.cxptrnpagodocService.eliminarTrnCobro_PagDoc(this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC);
    this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC = '';
    this.cxptrnpagodocService.trncobro.TRNPAGO_NUMERO = '';
    this.cxptrnpagodocService.trncobro.ASI_NRO = '';
  }

  async generaAsientoContable() {
    this.progress = false;
    let strAsiNro = '';

    try {
      const data = await this.cxptrnpagodocService.generaAsientoPagoProvProm(this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC,
        this.selecttd.codigo, this.cxptrnpagodocService.trncobro.TRNCOBRO_REFERENCIAEXTERNA);
      for (const rs of data) {
        strAsiNro = rs[':B1'] == null ? '' : rs[':B1'];
        this.cxptrnpagodocService.trncobro.ASI_NRO = strAsiNro;

        if (this.cxptrnpagodocService.trncobro.TRNCOBRO_REFERENCIAEXTERNA === '') {
          this.cxptrnpagodocService.trncobro.TRNCOBRO_REFERENCIAEXTERNA = strAsiNro;
        }
      }

      await this.desplegarAsientos();
      await this.generarAbonoCXC();

      this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
        this.cxptrnpagodocService.trncobro.ASI_NRO,
        'I', '', '01', '', '', '',
        '').subscribe();
        this.spin=false;//CDPJ
        this.control=0;//CDPJ
    } catch (err) {
      this.spin=false;//CDPJ
      this.botonGuardar = false;
      this.control=0;//CDPJ
      this.reversaGrabacionTransacciones();
    }

    /*this.cxptrnpagodocService.generaAsientoPagoProv(this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC,
      this.selecttd.codigo, this.cxptrnpagodocService.trncobro.TRNCOBRO_REFERENCIAEXTERNA).subscribe(data => {
        for (const rs of data) {
          strAsiNro = rs[':B1'] == null ? '' : rs[':B1'];
          this.cxptrnpagodocService.trncobro.ASI_NRO = strAsiNro;

          if (this.cxptrnpagodocService.trncobro.TRNCOBRO_REFERENCIAEXTERNA === '') {
            this.cxptrnpagodocService.trncobro.TRNCOBRO_REFERENCIAEXTERNA = strAsiNro;
          }
        }

        this.desplegarAsientos();
        this.generarAbonoCXC();

        this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
          this.cxptrnpagodocService.trncobro.ASI_NRO,
          'I', '', '01', '', '', '',
          '').subscribe();

      }, error => {
        this.botonGuardar = false;
        this.reversaGrabacionTransacciones();
      }); */

  }

  async generarAbonoCXC() {

    try {
      await this.cxptrnpagodocService.GENERA_ABONO_CANJEProm(this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC);
      await this.generaTransaccionesCaja();
    } catch (err) {
      this.botonGuardar = false;
      this.spin=false//CDPJ
      this.control=0;//CDPJ
      this.reversaGrabacionTransacciones();
    }

    /* this.cxptrnpagodocService.GENERA_ABONO_CANJE(this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC).subscribe(data => {
      this.generaTransaccionesCaja();
    }, error => {
      this.botonGuardar = false;
      this.reversaGrabacionTransacciones();
    }); */
  }

  async generaTransaccionesCaja() {
    try {
      await this.cxptrnpagodocService.generaTrnCajdsdAsntProm(this.cxptrnpagodocService.trncobro.ASI_NRO);
    await this.insertaDepositosYRetiros();
    } catch (error) {
      this.control=0;//CDPJ
      this.spin=false//CDPJ
    }
    
    /* this.cxptrnpagodocService.generaTrnCajdsdAsnt(this.cxptrnpagodocService.trncobro.ASI_NRO).subscribe(data => {
      this.insertaDepositosYRetiros();
    }); */
  }

  async insertaDepositosYRetiros() {
    let item: CxpDettrncobro;
    let intIndiceLista = 0;


    const strFechaEmision = this.datePipe.transform(this.cxptrnpagodocService.trncobro.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');


    for (intIndiceLista = 0; intIndiceLista < this.cxptrnpagodocService.varios.length; intIndiceLista++) {
      item = this.cxptrnpagodocService.varios[intIndiceLista];

      if (item.TRNBAN_TIPO === 'DP' || item.TRNBAN_TIPO === 'RE') {
        const itemban: BanTrnban = {};

        itemban.BAN_CODIGO = item.BAN_CODIGO;
        itemban.TRNBAN_TIPO = item.TRNBAN_TIPO;
        itemban.TRNBAN_NUMERO = item.TRNBAN_NUMERO;
        itemban.TRNBAN_FECHA = strFechaEmision;
        if (Number(item.HABER) !== 0) {
          itemban.TRNBAN_IMPORTE = Number(item.HABER) * -1;
        } else {
          itemban.TRNBAN_IMPORTE = Number(item.DEBE);
        }
        itemban.TRNBAN_CONCEPTO = this.cxptrnpagodocService.trncobro.TRNCOBRO_CONCEPTO;
        itemban.TRNBAN_BENEFICIARIO = this.cxptrnpagodocService.trncobro.TRNBAN_BENEFICIARIO;
        itemban.COM_CODIGO = '01';
        itemban.ASI_NRO = this.cxptrnpagodocService.trncobro.ASI_NRO;
        itemban.TRNBAN_ORIGEN = this.cxptrnpagodocService.trncobro.APLORG_CODIGO;
        itemban.TRNCOBRO_NRODOC = this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC;
        itemban.DETTRNCOBRO_NROSEC = Number(item.DETTRNCOBRO_NROSEC);


        const data = await this.cxptrnpagodocService.actualizaTrnBanProm(itemban);

        for (const rs of data) {
          item.TRNBAN_NUMERO = rs.TRNBAN_NUMERO === null ? '' : rs.TRNBAN_NUMERO;
        }

        /*this.cxptrnpagodocService.actualizaTrnBan(itemban).subscribe(data => {
          for (const rs of data) {
            item.TRNBAN_NUMERO = rs.TRNBAN_NUMERO === null ? '' : rs.TRNBAN_NUMERO;
          }
        });*/
      } /*Fin si es depósito o retiro*/
      //this.cxptrnpagodocService.actualizaEstadoFactura(item.ENCFAC_NUMERO, item.ENCFAC_CLICODIGO).subscribe();
      await this.cxptrnpagodocService.actualizaEstadoFactura(item.ENCFAC_NUMERO, item.ENCFAC_CLICODIGO).toPromise();//CDPJ
    }
    await this.actualizarSaldoDocumento('guardar');
  } // Fin insertar Depositos y Retiros

  async actualizarSaldoDocumento(accion) {
    let intIndiceLista = 0;
    let item: CxpTrndocumento;
    for (intIndiceLista = 0; intIndiceLista < this.cxptrnpagodocService.documentos.length; intIndiceLista++) {
      item = this.cxptrnpagodocService.documentos[intIndiceLista];
      if (item.TRNDOCUMENTO_TIPODOC === 'FC') {
        // Actualiza el estado de la factura
        //this.cxptrnpagodocService.actualizaEstadoFacpro(this.cxptrnpagodocService.trncobro.PRO_CODIGO, item.TRNDOCUMENTO_NRODOC).subscribe();
        await this.cxptrnpagodocService.actualizaEstadoFacpro(this.cxptrnpagodocService.trncobro.PRO_CODIGO, item.TRNDOCUMENTO_NRODOC).toPromise()
      }
    }

    if (accion === 'guardar') {
      await this.generarNDPorAnticipo(this.cxptrnpagodocService.trncobro.PRO_CODIGO,
        this.datePipe.transform(this.cxptrnpagodocService.trncobro.TRNCOBRO_FECHATRN, 'dd/MM/yyyy'));
    }
  }

  async generarNDPorAnticipo(strCodProveedor, strFechaEmision) {

    /*guarda Nota de debito por pago con anticipo*/

    let bolResultado = true;
    let strSentencia = '';


    let intIndiceLista = 0;
    let item: CxpDettrncobro;

    for (intIndiceLista = 0; intIndiceLista < this.cxptrnpagodocService.varios.length; intIndiceLista++) {

      item = this.cxptrnpagodocService.varios[intIndiceLista];

      if (item.DETTRNCOBRO_TIPO === 'A') { // Anticipo
        const itemvar: CxpTrncobro = {};

        itemvar.TRNCOBRO_NRODOC = item.DETTRNCOBRO_COMPROBANTE;
        itemvar.COM_CODIGO = '01';
        itemvar.TRNCOBRO_FECHATRN = strFechaEmision;
        itemvar.TRNCOBRO_TIPODOC = 'NDA';
        itemvar.TRNCOBRO_NROCOMPROBANTE = item.DETTRNCOBRO_COMPROBANTE;
        itemvar.TRNCOBRO_CONCEPTO = 'NOTA DE DEBITO POR ANTICIPO ' + item.DETTRNCOBRO_REFERENCIA; // Número del ancticipo
        itemvar.TRNCOBRO_IMPORTE = Number(item.HABER);
        itemvar.TRNCOBRO_FECHAVENCE = strFechaEmision;
        itemvar.PRO_CODIGO = strCodProveedor;
        itemvar.APLORG_CODIGO = 'SISTEMA';
        itemvar.TRNCOBRO_FECHARECEPCION = '';
        itemvar.TRNCOBRO_REFERENCIAEXTERNA = item.DETTRNCOBRO_REFERENCIA;
        itemvar.CON_CODIGO = '';

        try {
          const data = await this.cxptrnpagodocService.insertarNotaDebitoXAnt(itemvar);
          for (const rs of data) {
            if (item.DETTRNCOBRO_COMPROBANTE === '') {
              const strNumeroDoc = rs[':B1'] == null ? '' : rs[':B1'];
              if (strNumeroDoc !== '') {
                item.DETTRNCOBRO_COMPROBANTE = strNumeroDoc;
                // Actualiza en la tabla cxp_dettrncobro
                strSentencia = 'update CXP_DETTRNCOBRO set DETTRNCOBRO_COMPROBANTE=' + '\'' + strNumeroDoc + '\'';
                strSentencia = strSentencia + ' where TRNCOBRO_NRODOC=' + '\'' + this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC + '\'';
                strSentencia = strSentencia + ' and com_codigo= ' + '\'01\'';
                strSentencia = strSentencia + ' and DETTRNCOBRO_NROSEC= ' + item.DETTRNCOBRO_NROSEC;
                await this.cxptrnpagodocService.actualizarDetTrnCobro(strNumeroDoc, this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC, item.DETTRNCOBRO_NROSEC);
              }
            }
          }
        } catch (error1) {
          bolResultado = false;
          // si existen errores se borran las nota de débito
          for (intIndiceLista = 0; intIndiceLista < this.cxptrnpagodocService.varios.length; intIndiceLista++) {
            item = this.cxptrnpagodocService.varios[intIndiceLista];
            if (item.DETTRNCOBRO_TIPO === 'A') { // si es anticipo
              strSentencia = 'delete from cxp_trncobro where trncobro_nrodoc=' + '\'' + item.DETTRNCOBRO_COMPROBANTE + '\'';
              strSentencia = strSentencia + ' and com_codigo=' + '\'01\'';
              await this.cxptrnpagodocService.eliminarNotaDebito(item.DETTRNCOBRO_COMPROBANTE);
              item.DETTRNCOBRO_COMPROBANTE = '';
            } // fin del for
          }
        }
      } // Fin si es anticipo
    } // fin for
    this.messageService.add({
      key: 'encfacpropago',
      severity: 'success',
      summary: 'Información',
      detail: 'Pago guardado correctamente'
    });
    this.control=1;
    this.spin=false//CDPJ
    this.cancelar();
  }

  seleccionproveedor(opcion) {
    this.cxptrnpagodocService.trncobro.PRO_CODIGO = opcion.PRO_CODIGO;
    this.cxptrnpagodocService.trncobro.PRO_NOMBRE = opcion.PRO_NOMBRE;
    this.cxptrnpagodocService.trncobro.TRNBAN_BENEFICIARIO = opcion.PRO_NOMBRE;
    this.listarDatos(this.cxptrnpagodocService.trncobro.PRO_CODIGO);
    this.completarDatosProveedor(this.cxptrnpagodocService.trncobro.PRO_CODIGO, 'nuevo');
    document.getElementById('TIPODOC').focus();
  }

  seleccionbanco(opcion) {
    this.cxptrnpagodocService.trncobro.BAN_CODIGO = opcion.BAN_CODIGO;
    this.cxptrnpagodocService.trncobro.BAN_NOMBRE = opcion.BAN_BANCO;
    this.desplegarNumeroCheque(opcion.BAN_CODIGO);
    document.getElementById('NUMCHEQUE').focus();
  }

  seleccioncaja(opcion) {
    this.cxptrnpagodocService.trncobro.CAJ_CODIGO = opcion.CAJ_CODIGO;
    this.cxptrnpagodocService.trncobro.CAJ_NOMBRE = opcion.CAJ_DESCRIPCION;
    document.getElementById('CONCEPTO').focus();
  }

  desplegarNumeroCheque(txtCodBanco) {
    let lngNumBanco = 0;
    const intNUMCHEAUT = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMCHEAUT').CFG_VALOR1;
    if (intNUMCHEAUT === 0) { // si el número del cheque no es automático
      return;
    }

    this.cxptrnpagodocService.consultaUltimoCheque(txtCodBanco).subscribe(data => {
      for (const rs of data) {
        lngNumBanco = Number(rs.BAN_ULTIMOCHEQUE === null ? '0' : rs.BAN_ULTIMOCHEQUE);
        lngNumBanco = lngNumBanco + 1;
        this.cxptrnpagodocService.trncobro.TRNBAN_NUMERO = lngNumBanco;
      }
    });
  }

  listarFacPendientes() {
    /* if (this.permisosRegistrosCont != null) {
      if (this.permisosRegistrosCont.PERTODO === 0) {
        if (this.permisosRegistrosCont.PERINSERCION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de Selección. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    } */

    this.opcionbusqueda = 'facPend';

    this.types = [
      { label: 'Referencia', value: 'TRNCOBRO_REFERENCIA' },
      { label: 'Cliente', value: 'CLI_CODIGO' },
      { label: 'Nombre', value: 'CLI_NOMBRE' },
      { label: 'Fecha', value: 'TRNCOBRO_FECHATRN' }
    ];

    this.busquedacampos = ['', '', '', ''];
    this.consulta = 'TRNCOBRO_REFERENCIA, CLI_CODIGO, VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO, \'01\') AS CLI_NOMBRE, TRNCOBRO_FECHATRN,' +
      ' CXC_FNC_SALDODOCUMENTO_G(TRNCOBRO_REFERENCIA,\'01\',CLI_CODIGO) AS SUMA, TRNCOBRO_TIPODOC';
    this.tabla = 'cxc_trnresumen';
    this.where = ' TRNCOBRO_TIPODOC=\'FC\' and CXC_FNC_SALDODOCUMENTO_G(TRNCOBRO_REFERENCIA,\'01\',CLI_CODIGO)>0 and ROWNUM <50';

    this.busqService.busquedaCxcTrnResumen1().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.displayDialogBusquedaFast = true;
      if (this.arregloCons !== null) {
        this.arregloCons.map((registro) => {
          if (registro.TRNCOBRO_FECHATRN !== null) {
            registro.TRNCOBRO_FECHATRN = this.datePipe.transform(registro.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
          } else {
            registro.TRNCOBRO_FECHATRN = '';
          }
        });
      }
    });
  }

  listarAntPendientes() {
    /* if (this.permisosRegistrosCont != null) {
      if (this.permisosRegistrosCont.PERTODO === 0) {
        if (this.permisosRegistrosCont.PERINSERCION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de Selección. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    } */

    let sentencia = 'trncobro_tipodoc_r=\'ANP\'  and abs(CXP_FNC_SALDODOCUMENTO(TRNCOBRO_REFERENCIA,\'01\',PRO_CODIGO)) > 0 and pro_codigo=\'' + this.cxptrnpagodocService.trncobro.PRO_CODIGO + '\'';

    /*let sentencia = ' trncobro_tipodoc_r=\'ANP\'' +
      ' and abs((select nvl(sum(b.trncobro_importe),0) from cxp_trnresumen b where b.trncobro_referencia=trncobro_referencia ' +
      ' and b.pro_codigo=pro_codigo))>0 ';
    sentencia = sentencia + ' and pro_codigo=\'' + this.cxptrnpagodocService.trncobro.PRO_CODIGO + '\'';*/

    this.opcionbusqueda = 'antPend';
    this.types = [
      { label: 'Referencia', value: 'TRNCOBRO_REFERENCIA' },
      { label: 'Proveedor', value: 'PRO_CODIGO' },
      { label: 'Nombre', value: 'PRO_NOMBRE' },
      { label: 'Fecha', value: 'TRNCOBRO_FECHATRN' },
      { label: 'Importe', value: 'TRNCOBRO_IMPORTE' }
    ];

    this.busquedacampos = ['', '', '', '', ''];
    this.consulta = 'TRNCOBRO_FECHATRN,TRNCOBRO_REFERENCIA,PRO_CODIGO,COM_FNC_NOMBRE_PROVEEDOR(PRO_CODIGO) AS PRO_NOMBRE,ABS(TRNCOBRO_IMPORTE) AS TRNCOBRO_IMPORTE, TRNCOBRO_TIPODOC_R  ' +
      ', abs(CXP_FNC_SALDODOCUMENTO(TRNCOBRO_REFERENCIA,\'01\',PRO_CODIGO)) as suma ';
    /*this.consulta = 'TRNCOBRO_FECHATRN,TRNCOBRO_REFERENCIA,PRO_CODIGO,COM_FNC_NOMBRE_PROVEEDOR(PRO_CODIGO) AS PRO_NOMBRE,' +
      'ABS(TRNCOBRO_IMPORTE) AS TRNCOBRO_IMPORTE, TRNCOBRO_TIPODOC_R ';*/
    this.tabla = 'cxp_trnresumen a ';
    this.where = sentencia + ' and ROWNUM <50';

    this.busqService.busquedaCxcTrnResumen2(sentencia).subscribe((res: any[]) => {
      this.arregloCons = res;
      this.displayDialogBusquedaFast = true;
      if (this.arregloCons !== null) {
        this.arregloCons.map((registro) => {
          if (registro.TRNCOBRO_FECHATRN !== null) {
            registro.TRNCOBRO_FECHATRN = this.datePipe.transform(registro.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
          } else {
            registro.TRNCOBRO_FECHATRN = '';
          }
        });
      }
    });
  }

  seleccionFactPend(valor) {
    if (valor.TRNCOBRO_REFERENCIA !== '') {
      this.desplegarFacPorCobrar(valor.TRNCOBRO_REFERENCIA, valor.CLI_CODIGO);
    }
  }

  desplegarFacPorCobrar(strDocumento, strCodigoCli) {

    const item = this.variosSelected;
    let dblSaldo = '';

    if (this.cxptrnpagodocService.varios.length === 0) {
      this.messageService.add({
        key: 'encfacpropago',
        severity: 'info',
        summary: 'Información',
        detail: 'Error, No existen items en el detalle, por favor verifique'
      });
      return;
    }

    if (this.variosSelected.CON_CODIGO === '') {
      this.cxptrnpagodocService.buscarDetFacPendiente(strDocumento, strCodigoCli).subscribe(detFacPend => {
        if (detFacPend !== null) {
          if (detFacPend[0].CON_CODIGO1 === null) {
            this.messageService.add({
              key: 'encfacpropago',
              severity: 'info',
              summary: 'Información',
              detail: 'Error, Código de la cuenta contable del Cliente es incorrecta, por favor verifique'
            });
            return;
          }
          const rs = detFacPend[0];
          item.CON_CODIGO = rs.CON_CODIGO1;
          item.CON_NOMBRE = rs.CON_NOMBRE + '-' + rs.TRNCOBRO_TIPODOC + ':' + rs.TRNCOBRO_NROCOMPROBANTE;
          item.ENCFAC_NUMERO = rs.TRNCOBRO_NRODOC === null ? '' : rs.TRNCOBRO_NRODOC;
          dblSaldo = Number(rs.SALDO).toFixed(2);
          item.HABER = dblSaldo;
          item.ENCFAC_CLICODIGO = rs.CLI_CODIGO;
          item.ENCFAC_COMCODIGO = rs.COM_CODIGO;
          item.DETTRNCOBRO_TIPO = 'F';
          item.CON_CENTRO = rs.CON_CENTRO === null ? '' : rs.CON_CENTRO;
          item.COLOR = 'CXC';

          this.cxptrnpagodocService.calcularImporteTotal();
          this.aggrid.refreshaggrid(this.cxptrnpagodocService.varios, this.defaultColDefVarios.objeto);
          // this.activarBotonesDetalle(false, false, true);
        }
      });

    } else {
      this.messageService.add({
        key: 'encfacpropago',
        severity: 'info',
        summary: 'Información',
        detail: 'Error, Selecciona una línea en blanco por favor'
      });

    }
  }

  seleccionAntPend(valor) {
    if (valor.TRNCOBRO_REFERENCIA !== '') {
      this.desplegarAnticipo(valor.TRNCOBRO_REFERENCIA);
    }
  }

  desplegarAnticipo(StrCodigoAnticipo) {

    const item = this.variosSelected;
    if (this.cxptrnpagodocService.varios.length === 0) {
      this.messageService.add({
        key: 'encfacpropago',
        severity: 'info',
        summary: 'Información',
        detail: 'Error, No existen items en el detalle, por favor verifique'
      });
      return;
    }

    let strCodigoConAnt = '';
    let strNombreCuenta = '';
    let strCodigoProveedor = '';
    let dblSaldoDocumento = 0;
    if (this.variosSelected.CON_CODIGO === '') {
      this.cxptrnpagodocService.buscarAntPendiente(StrCodigoAnticipo).subscribe(detAnc => {
        if (detAnc !== null) {
          const rs = detAnc[0];
          if (rs.CON_CODIGO2 !== null) {
            strCodigoConAnt = rs.CON_CODIGO2;
          } else {
            strCodigoConAnt = '';
          }


          // Nombre de la cuenta contable
          if (rs.CON_NOMBRE !== null) {
            strNombreCuenta = rs.CON_NOMBRE;
          } else {
            strNombreCuenta = '';
          }


          strCodigoProveedor = rs.PRO_CODIGO;

          let strSentencia = 'select cxp_fnc_saldodocumento(\'' + StrCodigoAnticipo + '\',\'01\',\'' + strCodigoProveedor + '\') AS SALDO';
          strSentencia = strSentencia + ' from dual ';

          this.cxptrnpagodocService.consultarSaldoDocumento(StrCodigoAnticipo, strCodigoProveedor).subscribe(data => {
            for (const rs1 of data) {
              dblSaldoDocumento = Number(rs1.SALDO);
            }

            item.CON_CODIGO = strCodigoConAnt;
            item.CON_NOMBRE = strNombreCuenta + '-' + 'ANT' + ':' + StrCodigoAnticipo;
            item.ENCFAC_NUMERO = '';
            item.HABER = dblSaldoDocumento * -1;
            item.DETTRNCOBRO_REFERENCIA = StrCodigoAnticipo;
            item.DETTRNCOBRO_TIPO = 'A';
            item.COLOR = 'ANT';

            this.cxptrnpagodocService.calcularImporteTotal();
            this.aggrid.refreshaggrid(this.cxptrnpagodocService.varios, this.defaultColDefVarios.objeto);
            // this.activarBotonesDetalle(false, false, true);
          });
        }
      });
    } else {
      this.messageService.add({
        key: 'encfacpropago',
        severity: 'info',
        summary: 'Información',
        detail: 'Error, Selecciona una línea en blanco por favor'
      });
    }
  }

  activarBotonesDetalle(bolNuevoDetalle, bolEliminarDetalle, bolCancelarDetalle) {
    this.btnNuevoGrid = bolNuevoDetalle;
    this.btnBorrarGrid = bolEliminarDetalle;
    if (bolNuevoDetalle === true && bolEliminarDetalle === true && bolCancelarDetalle === true) {
      this.btnCXCGrid = true;
    } else {
      this.btnCXCGrid = false;
    }
  }

  verificarTipoDoc() {
    this.botonesmodificar();
    /*if (this.selecttipodoc.name === 'Abono') {
      this.columnDefsDocumentos[9] = { // 9
        headerName: 'Importe', field: 'TRNDOCUMENTO_IMPORTE', cellEditor: 'cellPrueba', editable: true,
        cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: false,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 2,
              obligatorio: true,
              fecha: false,
              tamanocelda: 32,
              tienecalculos: true,
            }
          }
      };
    }
    
    
    if (this.selecttipodoc.name === 'Cancelación') {
      this.columnDefsDocumentos[9] = { // 9
        headerName: 'Importe', field: 'TRNDOCUMENTO_IMPORTE', editable: false, cellEditor: 'cellPrueba',
        cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: false,
              alphabetic: false,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 0,
              obligatorio: false,
              fecha: false,
              tamanocelda: 0,
              tienecalculos: false
            }
          }
      };
    }
    this.aggrid.refreshColumnDefs(); */
  }

  verAsiento() {
    this.asiento = this.selectasiento.codigo;
    if (this.asiento === null) {
      this.messageService.add({
        key: 'encfacpropago',
        severity: 'error',
        summary: 'Información',
        detail: 'No ha generado el Asiento Contable'
      });
    } else {
      this.auxiliar1++;
      this.displayDialogAsiento = true;
    }
  }

  salirDialog() {
    this.displayDialogBusqueda = false;
    this.displayDialogBusquedaFast = false;
    this.displayDialogAsiento = false;
  }

  ponerColorAFilaxTipoCuenta() {
    let item: CxpDettrncobro;
    for (let intIndiceLista = 1; intIndiceLista <= this.cxptrnpagodocService.varios.length; intIndiceLista++) {
      item = this.cxptrnpagodocService.varios[intIndiceLista - 1];
      if (item.ENCFAC_NUMERO !== '') {  // Cuenta por cobrar
        item.COLOR = 'CXC';
      }
      if (item.DETTRNCOBRO_TIPO === 'A') {  // Anticipo
        item.COLOR = 'ANT';
      }
    }
    this.aggrid.refreshaggrid(this.cxptrnpagodocService.varios, this.defaultColDefVarios.objeto);
  }

  verAsientoContable() {
    if (this.selectasiento.codigo !== '' && this.selectasiento.codigo !== null) {
      this.asiento = this.selectasiento.codigo;
      this.displayAsiContable = true;
    } else {
      this.messageService.add({
        key: 'encfacpropago',
        severity: 'info',
        summary: 'Información',
        detail: 'No ha generado el Asiento Contable'
      });
    }
  }

  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }

  anularDocumento() {
    /*
    if (tabDatos.getSelectionIndex()==0)//generales
    {
      if ( listaPermisos != null )
      {
        SegMaePermiso item = new SegMaePermiso();
        item = listaPermisos.get(0);
        if ( item.getPerTodo() == 0 )
        {
          if ( item.getPerActualizacion() == 0 )
          {
            MessageDialog.openInformation(shell, "Información", "El usuario " +
              Conexion.usuarioConectado + ", no tiene permiso de actualización. Consulte con el Administrador del Sistema.");
            return;
          }
        }
      }
    }*/

    this.confirmationService.confirm({
      message: 'Está seguro de anular el registro?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencfacpropago',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        if (this.cxptrnpagodocService.trncobro.APLORG_CODIGO !== 'CXP') {
          this.messageService.add({
            key: 'encfacpropago',
            severity: 'error',
            summary: 'Información',
            detail: 'No puede anular el registro porque es de otro origen.'
          });
          return;
        }

        if (this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC === '') {
          this.messageService.add({
            key: 'encfacpropago',
            severity: 'error',
            summary: 'Información',
            detail: 'Seleccione el registro.'
          });
          return;
        }

        this.cxp_fnc_verificar_trans_cash(this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC,
          this.cxptrnpagodocService.trncobro.PRO_CODIGO, 'PAGODOC', 'anular');
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  anular2() {
    this.cxptrnpagodocService.anularTrnCobro(this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC).subscribe(async () => {
      this.auditoriagral.registrarAuditoria('CXP_TRNCOBRO',
        this.cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC + '/' + this.cxptrnpagodocService.trncobro.ASI_NRO,
        'AN', '', '01', '', '', '',
        '').subscribe();
      // actualiza el saldo de los documentos (FACTURAS DE PROVEEDORES)
      await this.actualizarSaldoDocumento('anular');
      await this.cancelar();
    });
  }

  verificarFechaEmision(): boolean {
    let intIndiceLista = 0;
    let item: CxpTrndocumento;

    // String strFechaVencimiento="";
    let strMes = '';
    let strDia = '';
    let strAnio = '';
    let strFechaEmisionDoc;

    let strMesEmi = '';
    let strDiaEmi = '';
    let strAnioEmi = '';

    let strFechaEmision = '';


    for (intIndiceLista = 0; intIndiceLista < this.cxptrnpagodocService.documentos.length; intIndiceLista++) {
      item = this.cxptrnpagodocService.documentos[intIndiceLista];
      console.log(item);
      if (item.SELECCIONADOFPCOM === true) {
        let fechaEmi: any;
        if ( this.cxptrnpagodocService.trncobro.TRNCOBRO_FECHATRN.toString().includes('-')) {
          strFechaEmision = this.datePipe.transform(this.cxptrnpagodocService.trncobro.TRNCOBRO_FECHATRN,
            'yyyy/MM/dd');
        } else {
          fechaEmi= String(this.cxptrnpagodocService.trncobro.TRNCOBRO_FECHATRN).split('/');
          strDiaEmi = fechaEmi[0];
        strMesEmi = fechaEmi[1];
        strAnioEmi = fechaEmi[2];
        strFechaEmision = strAnioEmi + '/' + strMesEmi + '/' + strDiaEmi;
        }

        console.log(strFechaEmision);
       /* const fechaEmi = this.cxptrnpagodocService.trncobro.TRNCOBRO_FECHATRN;
        strDiaEmi = fechaEmi.getDate();
        strMesEmi = fechaEmi.getMonth() + 1;
        strAnioEmi = fechaEmi.getFullYear();
        strFechaEmision = strAnioEmi + '/' + strMesEmi + '/' + strDiaEmi;*/
        const fechaEmiAct = new Date(strFechaEmision);

        const hoy = item.TRNCOBRO_FECHATRANS;

        const dataTemp = hoy.split('/');
        strDia = dataTemp[0];
        strMes = dataTemp[1];
        strAnio = dataTemp[2];
        strFechaEmisionDoc = strAnio + '/' + strMes + '/' + strDia;
        const fechaDoc = new Date(strFechaEmisionDoc);

        if (fechaEmiAct < fechaDoc) {
          this.messageService.add({
            key: 'encfacpropago',
            severity: 'error',
            summary: 'Información',
            detail: 'La fecha de emisión no puede ser menor a la fecha de recepción'
          });
          return true;
        }
      }
    }
    return false;
  }

  isEdit(data): boolean {
    if (this.selecttipodoc.name === 'Abono' && data.SELECCIONADOFPCOM === true) {//CDPJ
      return true;
    }

    if (this.selecttipodoc.name === 'Cancelación') {
      return false;
    }
  }

  cambioPagoElec(valor) {
    if (valor.checked) {
      this.disnumcheque = true;
      this.cxptrnpagodocService.trncobro.TRNBAN_NUMERO = '';
    } else {
      this.disnumcheque = false;
    }
  }

  recibirNumeroDoc(valores: any) {
    this.encontrarPagoDoc(valores.NRODOCABCA);
    this.displayBusDocPagados = false;
  }

  recibirSenalesDocPagado(valor) {
    if (valor === true) {
      this.displayBusDocPagados = false;
    }
  }
}
