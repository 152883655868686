<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="anexgenxml"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="anexgenxmlConf" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>

<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"
						 [barraBotones5]="true" [botonVisto]="false" [barraBotonesCRUD]="true"
						 [barraBotones3Alt]="false"
						 (signal)="manejarSenales($event)"
						 style="text-align: left;">
		</app-invmaebarra>
	</div>
</div>

<div class="divgrups">
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-4">
				<span>Es un Regimen Impositivo Microempresas que desea declarar semestral?</span>
			</div>
			<div class="p-col-1">
				<p-dropdown [style]="{'width': '100%', 'min-width': '100%'}" optionLabel="label"
							inputId="cmbOptionsGe" [options]="cmbMicroempresas" [(ngModel)]="selectRegMicroemp" (onChange)="cambioRegimen()">
				</p-dropdown>
			</div>
			<div class="p-col-1">
			</div>
		</div>
	</div>
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-2">
				<span>Periodo Informado:</span>
			</div>
			<div class="p-col-1">
				<input id="PER_INFORMADO" type="text" pInputText autocomplete="off" maxlength="10"
					   style="float: left"
					   [(ngModel)]="txtPerMes">
			</div>
			<div class="p-col-1">
				<button type="button"
						style="background-image: url(../../../assets/images/iconos/buscar.png); "
						class="littlebuttons" (click)="busquedaSimple(2)"></button>
			</div>
			<div class="p-col-1">
				<input id="MES" type="text" pInputText autocomplete="off" disabled
					   [(ngModel)]="lblPerMes" style="color: #2a13ac">

			</div>

		</div>
	</div>
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-2">
				<span>Año:</span>
			</div>
			<div class="p-col-1" style="text-align: left">
				<p-dropdown [style]="{'width': '100%', 'min-width': '100%'}" optionLabel="anio"
							inputId="cmbAniosGe" [options]="cmbAnio" [(ngModel)]="selectAnio">
				</p-dropdown>
			</div>
			<div class="p-col-2">
			</div>
		</div>
	</div>
	<div class="p-col-12" style="margin-bottom: 100px">
		<div class="p-grid">
			<div class="p-col-2">
				<p-radioButton name="radio1" value="AnexTrans" inputId="orden1" [(ngModel)]="btnRadio"
				></p-radioButton>
				<label> Anexo Transaccional </label>
			</div>
		</div>
	</div>
	<!--	<div class="p-col-12" style="margin-top: 20px">-->
	<!--		<div class="p-grid">-->
	<!--			<div class="p-col-12">-->
	<!--				<p-progressBar [value]="porcentaje"></p-progressBar>-->
	<!--			</div>-->
	<!--		</div>-->
	<!--		<div class="p-col-2">-->
	<!--		</div>-->
	<!--	</div>-->
</div>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<div style=" 	position: fixed;
				z-index: 999;
				height: 2em;
				width: 2em;
				overflow: visible;
				margin: auto;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;

				">
	<p-progressSpinner *ngIf="generarxmlService.proceso" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
</div>


