import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { nomPerEmpl } from '../nominterfaces/nomperemple';
import { isNullOrUndefined } from 'util';
import { DatePipe } from '@angular/common';
import { ConfirmationService, MessageService, SelectItem } from 'primeng';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
@Injectable({
  providedIn: 'root'
})
export class NomperemplService {
  nomPerEmpl:nomPerEmpl[]=[];
 
  public way: string;
  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private confIniciales: ConfInicialesService,
    public usuario: Usuario,
    private pipe: DatePipe) {
      this.way = sessionStorage.getItem('way');
      this.usuario.identificacion;
     }
  empleadoPeriodo(){
    return this.http.post(this.way + '/nom/empleados/mj6cb6bvh2kpzy6', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  validarSoloSelectarUno(){
    let con=0;
    for(let per of this.nomPerEmpl){
      if(per.CHECK==true){
        con++;
      }
    }
    if(con==0){
      return 1;
    }else if(con>1){
      return 2;
    }else{
      return 3;
    }
  }
  fechaFinal(codigo,cuota:number){
    let index= this.nomPerEmpl.map(x=>{return x.PERIODO_CODIGO}).indexOf(codigo);
    cuota=cuota==0?1:cuota;
    let per:nomPerEmpl=this.nomPerEmpl[(index)+(cuota-1)];
    return per.PERIODO_FECFIN;
  }
  validarMayor(fechentr, fechaperini, fechaperfin ){
    
    let as=String(fechaperini).split('/');
    let bs=String(this.cambiarfechanull(fechentr)).split('/');
    let ent=new Date(parseInt(bs[2]),(parseInt(bs[1])-1),parseInt(bs[0]));
    let repIni=new Date(parseInt(as[2]),(parseInt(as[1])-1),parseInt(as[0]));

    let cs=String(fechaperfin).split('/');
    let repFin=new Date(parseInt(cs[2]),(parseInt(cs[1])-1),parseInt(cs[0]));
   /* if(ent<repIni || ent>repFin  ){
      return false;
    }else{
      return true;
    }*/
    return true;
  }
  validaPeriodosDisponible(codigo:number,couta:number){
    let index= this.nomPerEmpl.map(x=>{return x.PERIODO_CODIGO}).indexOf(codigo);
    let num=this.nomPerEmpl.length;
    let total=index+couta;
    if(total>num){
      return false;
    }else{
      return true;
    }
  }
  cambiarfechanull(valor){
    let res="";
    if(isNullOrUndefined(valor)){
      return res;
    }else{
      try{
        if(valor.toString().includes('-')){
          res=this.pipe.transform(valor,'dd/MM/yyyy');
          return res;
        }else{
          return valor;
        }
      }catch(err){
        return valor;
      } 
    }
  }
}
