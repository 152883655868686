<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="sacimaeNumC"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="sacimaeNumC" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
						 [botonBorrar]="btnBorrar"
						 (signal)="manejarSenales($event)" [barraBotonesDesplaz] = "true"
						 [botonSiguiente]="botonSiguiente" [botonBuscar]="btnBuscar" [barraBotones3]="true" [barraBotones5]="true">
		</app-invmaebarra>
	</div>
</div>
<div style="overflow: hidden;margin-top: -10px;" class="p-col-12">
	<app-aggridsaci [width]=""
					[height]="largo"
					[enableSorting]="true"
					[enableFilter]="true"
					[rowData]="numCService.detNumeracionC"
					[frameworkComponents]="frameworkComponents"
					[animateRows]="true"
					[rowSelection]="rowSelection"
					[columnDefs]="columnDefMaeNumeracionC"
					[defaultColDef]="defaultColDefSaciMaeNumeracionC"
					[rowStyle]="rowStyle"
					[singleClickEdit]="false"
					[botones]="false"
					(selected)=selectMaeNumeracionC($event)
					(cambios)="cambio($event)"

	></app-aggridsaci>
</div>

