//
// SACI WEB
// Rutina: Maestro de Vendedores
// Propietario: Wilson Ramos
// Modificado por: WR
// Fecha de creación: 15-11-2019
// Fecha de Modificación: 29-11-2019
//
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {Venmaevendedor} from '../veninterfaces/venmaevendedor';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {VenmaevendedorService} from '../venservicios/venmaevendedor.service';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {Venmaerancom} from '../veninterfaces/venmaerancom';
import {Venmaepreciovend} from '../veninterfaces/venmaepreciovend';
import {DatePipe} from '@angular/common';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Component({
  selector: 'app-venmaevendedor',
  templateUrl: './venmaevendedor.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class VenmaevendedorComponent implements OnInit, OnDestroy {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  actualizar: boolean;
  codigoAux: any;
  nuevoCampo: boolean;
  largo: string;
  types: SelectItem[];
  opcion: string;
  activeIndex: number;
  permisosVendedor: SegMaePermiso;
  arregloBus: any[];
  conNombre: string;
  provNombre: string;
  ciuNombre: string;
  jefeZonaNombre: string;
  zonaNombre: string;
  cajaNombre: string;
  bodegaNombre: string;
  displayDialogBus: boolean;
  confirmacionClave: string;
  listaprecios: any[];
  fechaIngreso: any;
  fechaNacimiento: any;
  opcionesprecio: string[];
  public frameworkComponents;
  vendedorSeleccionado: Venmaevendedor;
  vendedorComSeleccionado: Venmaerancom;
  vendedorPrecioSeleccionado: Venmaepreciovend;
  rowStyle;
  defaultColDefVendedor;
  defaultColDefVendedorComisiones;
  defaultColDefVendedorPrecio;
  rowSelection = 'multiple';
  columnDefsVendedor = [
    {
      headerName: 'Código', field: 'VEN_CODIGO', cellEditor: 'cellPrueba', width: 200, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 5
          }
        }
    },
    {
      headerName: 'Nombre', field: 'VEN_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: false,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 100
          }
        }
    },
    {
      headerName: 'Comisión1', field: 'VEN_COMISION1', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32
          }
        }
    },
    {
      headerName: 'Comisión2', field: 'VEN_COMISION2', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32
          }
        }
    }];
  columnDefsVendedorComisiones = [
    {
      headerName: 'Código', field: 'RANCOM_CODIGO', cellEditor: 'cellPrueba', width: 130,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10
          }
        }
    },
    {
      headerName: 'Desde', field: 'RANCOM_DESDE', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 32
          }
        }
    },
    {
      headerName: 'Hasta', field: 'RANCOM_HASTA', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32
          }
        }
    },
    {
      headerName: 'Porcentaje', field: 'RANCOM_PORCENTAJE', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32
          }
        }
    }];
  columnDefsVendedorPrecio = [
    {
      headerName: 'Código Precio', cellEditor: 'agSelectCellEditor', field: 'ARTPRE_CODIGO', editable: true, width: 300,
      cellEditorParams: {
        values: []
      }
    }];
  
  constructor(public vendedorService: VenmaevendedorService, private message: MessageService,
              private confirmationService: ConfirmationService, private errorService: ErroresBaseDatosService,
              private busquedaSer: InvbusquedaService, private datePipe: DatePipe, private permisosService: PermisosService,
              private auditoriagral: AuditoriagralService, private confIniciales: ConfInicialesService,
              private init: NuevoComponentService) {
    this.agTable();
    this.vendedorSeleccionado = {};
    this.vendedorComSeleccionado = {};
    this.vendedorPrecioSeleccionado = {};
    this.opcionesprecio = [];
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefVendedor = {
      editable: true,
      width: 100,
      objeto: 'maevendedor'
    };
    this.defaultColDefVendedorComisiones = {
      editable: true,
      width: 100,
      objeto: 'maevendedorcom'
    };
    this.defaultColDefVendedorPrecio = {
      editable: true,
      width: 100,
      objeto: 'maevendedorprecio'
    };
  }
  
  ngOnInit() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.actualizar = false;
    this.permisosService.getListaPermisos('VEN', '1', '17').subscribe((permiso) => {
      this.permisosVendedor = permiso[0];
    });
    this.confirmacionClave = '';
    this.cajaNombre = '';
    this.bodegaNombre = '';
    this.ciuNombre = '';
    this.jefeZonaNombre = '';
    this.conNombre = '';
    this.provNombre = '';
    this.fechaIngreso = '';
    this.fechaNacimiento = '';
    this.zonaNombre = '';
    this.activeIndex = 0;
    this.nuevoCampo = false;
    this.codigoAux = 0;
    this.vendedorService.getListaPrecios().subscribe((res) => {
      this.listaprecios = res;
      this.listaprecios.map((precio) => {
        this.opcionesprecio.push(precio.ARTPRE_CODIGO);
      });
      console.log(this.opcionesprecio);
      this.columnDefsVendedorPrecio = [
        {
          headerName: 'Código Precio', cellEditor: 'agSelectCellEditor', field: 'ARTPRE_CODIGO', editable: true, width: 300,
          cellEditorParams: {
            values: this.opcionesprecio
          }
        }];
      this.aggrid.refreshColumnDefs();
    });
    this.vendedorService.venmaevendedorCom = [];
    this.vendedorService.venmaepreciovend = [];
    this.displayDialogBus = false;
    this.vendedorService.getVendedor().subscribe((res) => {
      this.vendedorService.venmaevendedor = res;
      this.vendedorSeleccionado = this.vendedorService.venmaevendedor[this.vendedorService.venmaevendedor.length - 1];
      this.vendedorService.getVendedorCOM(this.vendedorSeleccionado).subscribe((res2) => {
        this.vendedorService.venmaevendedorCom = res2;
      });
      this.vendedorService.getVendedorprecio(this.vendedorSeleccionado).subscribe((res3) => {
        this.vendedorService.venmaepreciovend = res3;
      });
      this.busquedaSer.busquedaInvMaeBodega4().subscribe((res1: any[]) => {
        if (res1.length !== 0) {
          res1.map((bodega) => {
            if (this.vendedorSeleccionado.BOD_CODIGO === bodega.BOD_CODIGO) {
              this.bodegaNombre = bodega.BOD_NOMBRE;
            }
          });
        }
      });
      this.busquedaSer.busquedaBanMaeCaj().subscribe((res2: any[]) => {
        if (res2.length !== 0) {
          res2.map((caja) => {
            if (this.vendedorSeleccionado.CAJ_CODIGO === caja.CAJ_CODIGO) {
              this.cajaNombre = caja.CAJ_DESCRIPCION;
            }
          });
        }
      });
    });
    this.largo = '320';
  }
  ngOnDestroy(): void {
    this.confIniciales.instanciaVendedor--;
  }
  
  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      if (this.permisosVendedor.PERINSERCION === 1) {
        if (this.activeIndex === 0) {
          this.btnNuevo = true;
          this.btnGuardar = false;
          this.btnBorrar = false;
          this.btnRegresar = false;
          this.nuevoCampo = true;
          this.vendedorSeleccionado = {
            BOD_CODIGO: '',
            CAJ_CODIGO: '',
            CIU_CODIGO: '',
            COM_CODIGO: '01',
            CON_CODIGO: '',
            VEN_CLAVE: '',
            VEN_CODIGO: '',
            VEN_COMISION1: '',
            VEN_COMISION2: '',
            VEN_CORREO: '',
            VEN_DIRECCION1: '',
            VEN_DIRECCION2: '',
            VEN_FAX: '',
            VEN_FECHAINGRESO: new Date(),
            VEN_FECHANACIMIENTO: '',
            VEN_IDE: '',
            VEN_JEFEZONA: '',
            VEN_NOMBRE: '',
            VEN_OBSERVACIONES: '',
            VEN_PROVINCIA: '',
            VEN_TELEFONO1: '',
            VEN_TELEFONO2: '',
            VEN_TRANSPORTE: '',
            VEN_ZONA: ''
          };
          this.vendedorService.venmaevendedor.push(this.vendedorSeleccionado);
          this.aggrid.isEditable(true);
          this.aggrid.refreshaggrid(this.vendedorService.venmaevendedor, this.defaultColDefVendedor.objeto);
        }
        if (this.activeIndex === 1) {
        }
        if (this.activeIndex === 2) {
        }
        if (this.activeIndex === 3) {
          this.btnNuevo = true;
          this.btnGuardar = false;
          this.btnBorrar = false;
          this.btnRegresar = false;
          this.nuevoCampo = true;
          let rancomCodigo: number;
          console.log(this.vendedorService.venmaevendedorCom);
          if (this.vendedorService.venmaevendedorCom.length !== 0) {
            rancomCodigo = Number(this.vendedorService.venmaevendedorCom[this.vendedorService.venmaevendedorCom.length - 1].RANCOM_CODIGO)
              + 1;
          } else {
            rancomCodigo = 1;
          }
          this.vendedorComSeleccionado = {
            COM_CODIGO: '01',
            RANCOM_CODIGO: '0' + rancomCodigo.toString(),
            RANCOM_DESDE: '',
            RANCOM_HASTA: '',
            RANCOM_PORCENTAJE: '',
            VEN_CODIGO: this.vendedorSeleccionado.VEN_CODIGO
          };
          this.vendedorService.venmaevendedorCom.push(this.vendedorComSeleccionado);
          this.aggrid.refreshaggrid(this.vendedorService.venmaevendedorCom, this.defaultColDefVendedorComisiones.objeto);
        }
        if (this.activeIndex === 4) {
          this.btnNuevo = true;
          this.btnGuardar = false;
          this.btnBorrar = false;
          this.btnRegresar = false;
          this.nuevoCampo = true;
          this.vendedorPrecioSeleccionado = {
            ARTPRE_CODIGO: '',
            COM_CODIGO: '01',
            VEN_CODIGO: this.vendedorSeleccionado.VEN_CODIGO
          };
          this.vendedorService.venmaepreciovend.push(this.vendedorPrecioSeleccionado);
          this.aggrid.refreshaggrid(this.vendedorService.venmaepreciovend, this.defaultColDefVendedorPrecio.objeto);
        }
      } else if (this.permisosVendedor.PERINSERCION === 0) {
        this.message.add({
          key: 'vend',
          severity: 'error',
          summary: 'Inserción de Vendedor',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    }
    if (valor === 'Guardar') {
      if (this.permisosVendedor.PERACTUALIZACION === 1) {
        if (this.activeIndex === 0) {
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.btnRegresar = true;
          this.vendedorService.frVenCodigo(this.vendedorSeleccionado.VEN_CODIGO).subscribe(existe => {
            if (existe !== null) {
              this.vendedorService.actualizarVendedor(this.vendedorSeleccionado, this.codigoAux).subscribe(() => {
                this.message.add({
                  key: 'vend',
                  severity: 'success',
                  summary: 'Actualización exitosa',
                  detail: 'Se actualizó el vendedor correctamente'
                });
                this.auditoriagral.registrarAuditoria('VEN_MAEVENDEDOR', String(this.vendedorSeleccionado.VEN_CODIGO), 'A',
                  '', '01', '', '', '', '').subscribe(() => {
                });
                this.aggrid.isEditable(false);
              }, error1 => {
                let mensaje: string;
                mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                this.message.add({
                  key: 'vend',
                  severity: 'error',
                  summary: 'Actualización de Vendedor',
                  detail: mensaje
                });
                this.btnNuevo = false;
                this.btnGuardar = true;
                this.btnBorrar = false;
                this.btnRegresar = true;
                this.vendedorService.getVendedor().subscribe((res) => {
                  this.vendedorService.venmaevendedor = res;
                  this.aggrid.refreshaggrid(this.vendedorService.venmaevendedor, this.defaultColDefVendedor.objeto);
                });
              });
            } else {
              this.vendedorService.insertarVendedor(this.vendedorSeleccionado).subscribe(() => {
                this.message.add({
                  key: 'vend',
                  severity: 'success',
                  summary: 'Inserción exitosa',
                  detail: 'Se insertó el vendedor correctamente'
                });
                this.auditoriagral.registrarAuditoria('VEN_MAEVENDEDOR', String(this.vendedorSeleccionado.VEN_CODIGO), 'I',
                  '', '01', '', '', '', '').subscribe(() => {
                });
                this.nuevoCampo = false;
                this.aggrid.isEditable(false);
              }, error1 => {
                let mensaje: string;
                mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                this.message.add({
                  key: 'vend',
                  severity: 'error',
                  summary: 'Inserción de Vendedor',
                  detail: mensaje
                });
                this.btnNuevo = false;
                this.btnGuardar = true;
                this.btnBorrar = false;
                this.btnRegresar = true;
                this.vendedorService.getVendedor().subscribe((res) => {
                  this.vendedorService.venmaevendedor = res;
                  this.aggrid.refreshaggrid(this.vendedorService.venmaevendedor, this.defaultColDefVendedor.objeto);
                });
              });
            }
          });
        } else if (this.activeIndex === 1) {
          if (this.vendedorSeleccionado.VEN_CLAVE === this.confirmacionClave) {
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
            this.btnRegresar = true;

            this.vendedorService.frVenclave(this.vendedorSeleccionado.VEN_CLAVE).subscribe(existec => {
                if (existec !== null) {
                  this.message.add({
                    key: 'vend',
                    severity: 'error',
                    summary: 'Actualización de Vendedor',
                    detail: 'La clave de vendedor ya existe, ingrese otra clave'
                  });
                } else {
                  this.vendedorService.guardarClave(this.vendedorSeleccionado).subscribe(() => {
                    this.message.add({
                      key: 'vend',
                      severity: 'success',
                      summary: 'Inserción exitosa',
                      detail: 'Se insertaron los campos correctamente'
                    });
                    this.auditoriagral.registrarAuditoria('VEN_MAEVENDEDOR', String(this.vendedorSeleccionado.VEN_CODIGO), 'A',
                      '', '01', '', '', '', '').subscribe(() => {
                    });
                  }, error1 => {
                    let mensaje: string;
                    mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                    this.message.add({
                      key: 'vend',
                      severity: 'error',
                      summary: 'Actualización de Vendedor',
                      detail: mensaje
                    });
                  });
                }
            });
          } else if (this.vendedorSeleccionado.VEN_CLAVE !== this.confirmacionClave) {
            this.message.add({
              key: 'vend',
              severity: 'warn',
              summary: 'Inserción',
              detail: 'La clave no coincide con su confirmación'
            });
          }
        } else if (this.activeIndex === 2) {
          // if (this.vendedorSeleccionado.VEN_FECHAINGRESO.includes('/')) {
          //   this.fechaIngreso = this.vendedorSeleccionado.VEN_FECHAINGRESO;
          // } else if (this.vendedorSeleccionado.VEN_FECHAINGRESO.includes('-')) {
          //   this.fechaIngreso = this.datePipe.transform(this.vendedorSeleccionado.VEN_FECHAINGRESO.toLocaleString().substr(0, 10),
          //     'dd/MM/yyyy');
          // }
          // if (this.vendedorSeleccionado.VEN_FECHANACIMIENTO.includes('/')) {
          //   this.fechaNacimiento = this.vendedorSeleccionado.VEN_FECHANACIMIENTO;
          // } else if (this.vendedorSeleccionado.VEN_FECHANACIMIENTO.includes('-')) {
          //   this.fechaNacimiento = this.datePipe.transform(this.vendedorSeleccionado.VEN_FECHANACIMIENTO.toLocaleString().substr(0, 10),
          //     'dd/MM/yyyy');
          // }
          // this.vendedorService.guardarCamposAdicionales(this.vendedorSeleccionado, this.fechaIngreso, this.fechaNacimiento).subscribe(
          //   () => {
          //   this.message.add({
          //     key: 'vend',
          //     severity: 'success',
          //     summary: 'Inserción exitosa',
          //     detail: 'Se insertaron los campos adicionales correctamente'
          //   });
          //   this.auditoriagral.registrarAuditoria('VEN_MAEVENDEDOR', String(this.vendedorSeleccionado.VEN_CODIGO), 'A',
          //     '', '01', '', '', '', '').subscribe(() => {
          //   });
          // }, error1 => {
          //   let mensaje: string;
          //   mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
          //   this.message.add({
          //     key: 'vend',
          //     severity: 'error',
          //     summary: 'Actualización de Vendedor',
          //     detail: mensaje
          //   });
          // });
          //CDPJ
          console.log('fecha',this.vendedorSeleccionado.VEN_FECHAINGRESO);
          console.log('fecha2',this.vendedorSeleccionado.VEN_FECHANACIMIENTO);
          if(this.vendedorSeleccionado.VEN_FECHANACIMIENTO === null || this.vendedorSeleccionado.VEN_FECHANACIMIENTO === undefined){
            this.vendedorSeleccionado.VEN_FECHANACIMIENTO ='';
          }
          //CDPJ

          console.log('entro1');
          if (this.vendedorSeleccionado.VEN_FECHAINGRESO.toString().includes('/')) {
            this.fechaIngreso = this.vendedorSeleccionado.VEN_FECHAINGRESO;
            this.fechaIngreso=this.fechaIngreso.replace(',','');//CDPJ

          } else if (this.vendedorSeleccionado.VEN_FECHAINGRESO.toString().includes('-')) {
            this.fechaIngreso = this.datePipe.transform(this.vendedorSeleccionado.VEN_FECHAINGRESO.toLocaleString().substr(0, 10),
              'dd/MM/yyyy');
              this.fechaIngreso=this.fechaIngreso.replace(',','');//CDPJ
          }
          if (this.vendedorSeleccionado.VEN_FECHANACIMIENTO.toString().includes('/')) {
            this.fechaNacimiento = this.vendedorSeleccionado.VEN_FECHANACIMIENTO;
          } else if (this.vendedorSeleccionado.VEN_FECHANACIMIENTO.toString().includes('-')) {
            this.fechaNacimiento = this.datePipe.transform(this.vendedorSeleccionado.VEN_FECHANACIMIENTO.toLocaleString().substr(0, 10),
              'dd/MM/yyyy');
          }
          this.vendedorService.guardarCamposAdicionales(this.vendedorSeleccionado, this.fechaIngreso, this.fechaNacimiento).subscribe(() => {
            console.log('entro2');
            this.message.add({
              key: 'vend',
              severity: 'success',
              summary: 'Inserción exitosa',
              detail: 'Se insertaron los campos adicionales correctamente'
            });
            this.auditoriagral.registrarAuditoria('VEN_MAEVENDEDOR', String(this.vendedorSeleccionado.VEN_CODIGO), 'A',
              '', '01', '', '', '', '').subscribe(() => {
            });
          }, error1 => {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.message.add({
              key: 'vend',
              severity: 'error',
              summary: 'Actualización de Vendedor',
              detail: mensaje
            });
          });
        } else if (this.activeIndex === 3) {
          this.vendedorService.frRamComCodigo( this.vendedorComSeleccionado.RANCOM_CODIGO ,this.vendedorComSeleccionado.VEN_CODIGO).subscribe(existe => {
            if (existe !== null) {
              this.vendedorService.actualizarRangosComisiones(this.vendedorComSeleccionado).subscribe(() => {
                this.message.add({
                  key: 'vend',
                  severity: 'success',
                  summary: 'Actualización de Rango de Comisiones',
                  detail: 'Se actualizó con éxito el rango de comisiones'
                });
                this.auditoriagral.registrarAuditoria('VEN_MAERANCOM.', String(this.vendedorComSeleccionado.RANCOM_CODIGO),
                  'A', '', '01', '', '', '', '').subscribe(() => {
                });
                this.btnNuevo = false;
                this.btnGuardar = true;
                this.btnBorrar = false;
                this.btnRegresar = true;
              }, error1 => {
                let mensaje: string;
                mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                this.message.add({
                  key: 'vend',
                  severity: 'error',
                  summary: 'Actualización de Comisión de Vendedor',
                  detail: mensaje
                });
              });
            } else {
              this.vendedorService.insertarRangosComisiones(this.vendedorComSeleccionado).subscribe(() => {
                this.message.add({
                  key: 'vend',
                  severity: 'success',
                  summary: 'Inserción de Rango de Comisiones',
                  detail: 'Se insertó con éxito el rango de comisionService'
                });
                this.auditoriagral.registrarAuditoria('VEN_MAERANCOM.', String(this.vendedorComSeleccionado.RANCOM_CODIGO),
                  'I', '', '01', '', '', '', '').subscribe(() => {
                });
                this.btnNuevo = false;
                this.btnGuardar = true;
                this.btnBorrar = false;
                this.btnRegresar = true;
              }, error1 => {
                let mensaje: string;
                mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                this.message.add({
                  key: 'vend',
                  severity: 'error',
                  summary: 'Inserción de Comisión',
                  detail: mensaje
                });
              });
            }
          });
        } else if (this.activeIndex === 4) {
          this.vendedorService.frCodAux(this.codigoAux,this.vendedorPrecioSeleccionado.VEN_CODIGO).subscribe(existe => {
            if (existe !== null) {
              this.vendedorService.actualizarPreciosVendedor(this.vendedorPrecioSeleccionado, this.codigoAux).subscribe(() => {
                this.message.add({
                  key: 'vend',
                  severity: 'success',
                  summary: 'Actualización de Precios de vendedor',
                  detail: 'Se actualizó con éxito el precio de vendedor'
                });
                this.auditoriagral.registrarAuditoria('VEN_PRECIOVEND.', String(this.vendedorPrecioSeleccionado.VEN_CODIGO),
                  'A', '', '01', '', '', '', '').subscribe(() => {
                });
                this.btnNuevo = false;
                this.btnGuardar = true;
                this.btnBorrar = false;
                this.btnRegresar = true;
              }, error1 => {
                let mensaje: string;
                mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                this.message.add({
                  key: 'vend',
                  severity: 'error',
                  summary: 'Actualización de Precio de Vendedor',
                  detail: mensaje
                });
              });
            } else if (existe === null) {
              this.vendedorService.insertarPreciosVendedor(this.vendedorPrecioSeleccionado).subscribe(() => {
                this.message.add({
                  key: 'vend',
                  severity: 'success',
                  summary: 'Inserción de Precio de vendedor',
                  detail: 'Se insertó con éxito el precio de vendedor'
                });
                this.auditoriagral.registrarAuditoria('VEN_PRECIOVEND.', String(this.vendedorPrecioSeleccionado.VEN_CODIGO),
                  'I', '', '01', '', '', '', '').subscribe(() => {
                });
                this.btnNuevo = false;
                this.btnGuardar = true;
                this.btnBorrar = false;
                this.btnRegresar = true;
              }, error1 => {
                let mensaje: string;
                mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                this.message.add({
                  key: 'vend',
                  severity: 'error',
                  summary: 'Inserción de Precio de vendedor',
                  detail: mensaje
                });
              });
            }
          });
        }
      } else if (this.permisosVendedor.PERACTUALIZACION === 0) {
        this.message.add({
          key: 'vend',
          severity: 'error',
          summary: 'Actualización de Vendedor',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }
    }
    if (valor === 'Borrar') {
      if (this.permisosVendedor.PERELIMACION === 1) {
        if (this.activeIndex === 0 || this.activeIndex === 1 || this.activeIndex === 2) {
          if (this.vendedorSeleccionado === undefined) {
            this.message.add({
              key: 'vend',
              severity: 'error',
              summary: 'Eliminacion de Vendedor',
              detail: 'Selección Incorrecta, no se ha seleccionado un vendedor'
            });
          } else {
            this.confirmarVendedor();
            this.aggrid.isEditable(false);
          }
        } else if (this.activeIndex === 3) {
          if (this.vendedorComSeleccionado === undefined) {
            this.message.add({
              key: 'vend',
              severity: 'error',
              summary: 'Eliminacion de Comisión',
              detail: 'Selección Incorrecta, no se ha seleccionado una comisión'
            });
          } else {
            this.confirmarVendedorCom();
          }
        } else if (this.activeIndex === 4) {
          if (this.vendedorPrecioSeleccionado === undefined) {
            this.message.add({
              key: 'vend',
              severity: 'error',
              summary: 'Eliminacion de Precio',
              detail: 'Selección Incorrecta, no se ha seleccionado un precio'
            });
          } else {
            this.confirmarVendedorPrecio();
          }
        }
      } else if (this.permisosVendedor.PERELIMACION === 0) {
        this.message.add({
          key: 'vend',
          severity: 'error',
          summary: 'Eliminación de Vendedor',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
      }
    }
    if (valor === 'Cancelar') {
      if (this.activeIndex === 0 || this.activeIndex === 1 || this.activeIndex === 2) {
        this.btnNuevo = false;
        this.btnGuardar = true;
        this.btnBorrar = false;
        this.btnRegresar = true;
        this.aggrid.isEditable(false);
        this.vendedorService.getVendedor().subscribe((res) => {
          this.vendedorService.venmaevendedor = res;
          this.aggrid.refreshaggrid(this.vendedorService.venmaevendedor, this.defaultColDefVendedor.objeto);
          this.vendedorSeleccionado = this.vendedorService.venmaevendedor[this.vendedorService.venmaevendedor.length - 1];
          this.vendedorSeleccionado.VEN_FECHAINGRESO = this.vendedorSeleccionado.VEN_FECHAINGRESO.toLocaleString().substr(0, 10);
          this.vendedorSeleccionado.VEN_FECHANACIMIENTO = this.vendedorSeleccionado.VEN_FECHANACIMIENTO.toLocaleString().substr(0, 10);
          this.vendedorService.getVendedorCOM(this.vendedorSeleccionado).subscribe((res2) => {
            this.vendedorService.venmaevendedorCom = res2;
          });
          this.vendedorService.getVendedorprecio(this.vendedorSeleccionado).subscribe((res3) => {
            this.vendedorService.venmaepreciovend = res3;
          });
          this.busquedaSer.busquedaInvMaeBodega4().subscribe((res1: any[]) => {
            res1.map((bodega) => {
              if (this.vendedorSeleccionado.BOD_CODIGO === bodega.BOD_CODIGO) {
                this.bodegaNombre = bodega.BOD_NOMBRE;
              }
            });
          });
          this.busquedaSer.busquedaBanMaeCaj().subscribe((res1: any[]) => {
            res1.map((caja) => {
              if (this.vendedorSeleccionado.CAJ_CODIGO === caja.CAJ_CODIGO) {
                this.cajaNombre = caja.CAJ_DESCRIPCION;
              }
            });
          });
          this.busquedaSer.busquedaSaciMaeReferencia6('REF_TIPO = \'PV\' ORDER BY REF_CODIGO')
            .subscribe((res1: any[]) => {
              res1.map((prov) => {
                if (this.vendedorSeleccionado.VEN_PROVINCIA === prov.REF_CODIGO) {
                  this.provNombre = prov.REF_NOMBRE;
                }
              });
            });
          this.busquedaSer.busquedaSaciMaeReferencia6('REF_TIPO = \'Z\' ORDER BY REF_CODIGO')
            .subscribe((res1: any[]) => {
              res1.map((zona) => {
                if (this.vendedorSeleccionado.VEN_ZONA === zona.REF_CODIGO) {
                  this.zonaNombre = zona.REF_NOMBRE;
                }
              });
            });
          this.busquedaSer.busquedaVenMaeCiudad1().subscribe((res1: any[]) => {
            res1.map((ciudad) => {
              if (this.vendedorSeleccionado.CIU_CODIGO === ciudad.CIU_CODIGO) {
                this.ciuNombre = ciudad.CIU_NOMBRE;
              }
            });
          });
          this.busquedaSer.busquedaConMaeCon().subscribe((res1: any[]) => {
            res1.map((cuenta) => {
              if (this.vendedorSeleccionado.CON_CODIGO === cuenta.CON_CODIGO) {
                this.conNombre = cuenta.CON_NOMBRE;
              }
            });
          });
        });
      } else if (this.activeIndex === 3) {
        this.vendedorService.getVendedorCOM(this.vendedorSeleccionado).subscribe((res2) => {
          this.vendedorService.venmaevendedorCom = res2;
          this.aggrid.refreshaggrid(this.vendedorService.venmaevendedorCom, this.defaultColDefVendedorComisiones.objeto);
        });
      } else if (this.activeIndex === 4) {
        this.vendedorService.getVendedorprecio(this.vendedorSeleccionado).subscribe((res3) => {
          this.vendedorService.venmaepreciovend = res3;
          this.aggrid.refreshaggrid(this.vendedorService.venmaepreciovend, this.defaultColDefVendedorPrecio.objeto);
        });
      }
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
  seleccionVendedor(valor) {
    if (valor.object !== null) {
      this.ciuNombre = '';
      this.zonaNombre = '';
      this.conNombre = '';
      this.provNombre = '';
      this.cajaNombre = '';
      this.bodegaNombre = '';
      this.vendedorSeleccionado = valor.object;
      if (this.vendedorSeleccionado.VEN_FECHAINGRESO !== null) {
        this.vendedorSeleccionado.VEN_FECHAINGRESO = this.vendedorSeleccionado.VEN_FECHAINGRESO.toLocaleString().substr(0, 10);
      } else if (this.vendedorSeleccionado.VEN_FECHAINGRESO === null) {
        this.vendedorSeleccionado.VEN_FECHAINGRESO = new Date().toLocaleString().substr(0, 10);
      }
      if (this.vendedorSeleccionado.VEN_FECHANACIMIENTO !== null) {
        this.vendedorSeleccionado.VEN_FECHANACIMIENTO = this.vendedorSeleccionado.VEN_FECHANACIMIENTO.toLocaleString().substr(0, 10);
      }
      this.vendedorService.getVendedorCOM(this.vendedorSeleccionado).subscribe((res2) => {
        this.vendedorService.venmaevendedorCom = res2;
      });
      this.vendedorService.getVendedorprecio(this.vendedorSeleccionado).subscribe((res3) => {
        this.vendedorService.venmaepreciovend = res3;
      });
      this.busquedaSer.busquedaInvMaeBodega6().subscribe((res1: any[]) => {
        res1.map((bodega) => {
          if (this.vendedorSeleccionado.BOD_CODIGO === bodega.BOD_CODIGO) {
            this.bodegaNombre = bodega.BOD_NOMBRE;
          }
        });
      });
      this.busquedaSer.busquedaBanMaeCaj().subscribe((res1: any[]) => {
        res1.map((caja) => {
          if (this.vendedorSeleccionado.CAJ_CODIGO === caja.CAJ_CODIGO) {
            this.cajaNombre = caja.CAJ_DESCRIPCION;
          }
        });
      });
      this.busquedaSer.busquedaSaciMaeReferencia6('REF_TIPO = \'PV\' ORDER BY REF_CODIGO').subscribe((res1: any[]) => {
        res1.map((prov) => {
          if (this.vendedorSeleccionado.VEN_PROVINCIA === prov.REF_CODIGO) {
            this.provNombre = prov.REF_NOMBRE;
          }
        });
      });
      this.busquedaSer.busquedaSaciMaeReferencia6('REF_TIPO = \'Z\' ORDER BY REF_CODIGO').subscribe((res1: any[]) => {
        res1.map((zona) => {
          if (this.vendedorSeleccionado.VEN_ZONA === zona.REF_CODIGO) {
            this.zonaNombre = zona.REF_NOMBRE;
          }
        });
      });
      this.busquedaSer.busquedaVenMaeCiudad1().subscribe((res1: any[]) => {
        res1.map((ciudad) => {
          if (this.vendedorSeleccionado.CIU_CODIGO === ciudad.CIU_CODIGO) {
            this.ciuNombre = ciudad.CIU_NOMBRE;
          }
        });
      });
      this.busquedaSer.busquedaConMaeCon().subscribe((res1: any[]) => {
        res1.map((cuenta) => {
          if (this.vendedorSeleccionado.CON_CODIGO === cuenta.CON_CODIGO) {
            this.conNombre = cuenta.CON_NOMBRE;
          }
        });
      });
      if (this.vendedorSeleccionado.VEN_CODIGO !== '') {
        this.codigoAux = this.vendedorSeleccionado.VEN_CODIGO;
      }
    }
  }
  
  seleccionVendedorCom(valor) {
    if (valor.object !== null) {
      this.vendedorComSeleccionado = valor.object;
      if (this.vendedorComSeleccionado !== undefined) {
        this.codigoAux = this.vendedorComSeleccionado.RANCOM_CODIGO;
      }
    }
  }
  
  seleccionVendedorPrecio(valor) {
    if (valor.object !== null) {
      this.vendedorPrecioSeleccionado = valor.object;
      if (this.vendedorPrecioSeleccionado !== undefined) {
        this.codigoAux = this.vendedorPrecioSeleccionado.ARTPRE_CODIGO;
      }
    }
  }
  
  confirmarVendedor() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el vendedor',
      header: 'Eliminar Vendedor',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarVend',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarVendedor();
      },
      reject: () => {
      }
    });
  }
  
  confirmarVendedorCom() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el rango de comisionService del vendedor',
      header: 'Eliminar Comisiones Vendedor',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarVend',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarVendedorCom();
      },
      reject: () => {
      }
    });
  }
  
  confirmarVendedorPrecio() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el precio del vendedor',
      header: 'Eliminar Precio Vendedor',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarVend',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarVendedorPrecio();
      },
      reject: () => {
      }
    });
  }
  
  eliminarVendedor() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.vendedorService.eliminarVendedor(this.vendedorSeleccionado).subscribe(() => {
      if (this.vendedorService.venmaevendedorCom.length !== 0) {
        this.vendedorService.venmaevendedorCom.map((vendedorCom) => {
          this.vendedorService.eliminarRangosComisiones(vendedorCom).subscribe(() => {
            this.auditoriagral.registrarAuditoria('VEN_MAERANCOM', String(vendedorCom.RANCOM_CODIGO),
              'E', '', '01', '', '', '', '').subscribe(() => {
            });
          });
        });
      }
      if (this.vendedorService.venmaepreciovend.length !== 0) {
        this.vendedorService.venmaepreciovend.map((vendedorprecio => {
          this.vendedorService.eliminarPreciosVendedor(vendedorprecio).subscribe(() => {
            this.auditoriagral.registrarAuditoria('VEN_PRECIOVEND', String(vendedorprecio.VEN_CODIGO),
              'E', '', '01', '', '', '', '').subscribe(() => {
            });
          });
        }));
      }
      this.message.add({
        key: 'vend',
        severity: 'success',
        summary: 'Eliminación exitosa',
        detail: 'Se eliminó el vendedor correctamente'
      });
      this.auditoriagral.registrarAuditoria('VEN_MAEVENDEDOR.', String(this.vendedorSeleccionado.VEN_CODIGO),
        'E', '', '01', '', '', '', '').subscribe(() => {
      });
      this.vendedorService.venmaevendedor = this.vendedorService.venmaevendedor.filter((val, j) => j !==
        this.vendedorService.venmaevendedor.indexOf(this.vendedorSeleccionado));
      this.aggrid.refreshaggrid(this.vendedorService.venmaevendedor, this.defaultColDefVendedor.objeto);
    });
  }
  
  eliminarVendedorCom() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.vendedorService.eliminarRangosComisiones(this.vendedorComSeleccionado).subscribe(() => {
      this.message.add({
        key: 'vend',
        severity: 'success',
        summary: 'Eliminación exitosa',
        detail: 'Se eliminó el rango de comisión correctamente'
      });
      this.auditoriagral.registrarAuditoria('VEN_MAERANCOM', String(this.vendedorComSeleccionado.RANCOM_CODIGO),
        'E', '', '01', '', '', '', '').subscribe(() => {
      });
      
      this.vendedorService.venmaevendedorCom = this.vendedorService.venmaevendedorCom.filter((val, j) => j !==
        this.vendedorService.venmaevendedorCom.indexOf(this.vendedorComSeleccionado));
      this.aggrid.refreshaggrid(this.vendedorService.venmaevendedorCom, this.defaultColDefVendedorComisiones.objeto);
    });
  }
  
  eliminarVendedorPrecio() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.vendedorService.eliminarPreciosVendedor(this.vendedorPrecioSeleccionado).subscribe(() => {
      this.message.add({
        key: 'vend',
        severity: 'success',
        summary: 'Eliminación exitosa',
        detail: 'Se eliminó el precio del vendedor correctamente'
      });
      this.auditoriagral.registrarAuditoria('VEN_PRECIOVEND', String(this.vendedorPrecioSeleccionado.VEN_CODIGO),
        'E', '', '01', '', '', '', '').subscribe(() => {
      });
      
      this.vendedorService.venmaepreciovend = this.vendedorService.venmaepreciovend.filter((val, j) => j !==
        this.vendedorService.venmaepreciovend.indexOf(this.vendedorPrecioSeleccionado));
      this.aggrid.refreshaggrid(this.vendedorService.venmaepreciovend, this.defaultColDefVendedorPrecio.objeto);
    });
  }
  
  cambio(cambio) {
    this.btnGuardar = false;
    this.btnRegresar = false;
  }
  
  cambioIndices() {
    if (this.activeIndex === 0 || this.activeIndex === 3) {
    } else {
      this.btnNuevo = false;
      this.btnGuardar = false;
      this.btnRegresar = false;
    }
  }
  
  abrirCajas() {
    this.opcion = 'CAJAS';
    this.types = [
      {label: 'CÓDIGO', value: 'CAJ_CODIGO'},
      {label: 'DESCRIPCIÓN', value: 'CAJ_DESCRIPCION'}
    ];
    this.busquedaSer.busquedaBanMaeCaj().subscribe((res: any[]) => {
      this.arregloBus = res;
      this.displayDialogBus = true;
    });
  }
  
  abrirBodegas() {
    this.opcion = 'BODEGAS';
    this.types = [
      {label: 'CÓDIGO', value: 'BOD_CODIGO'},
      {label: 'NOMBRE', value: 'BOD_NOMBRE'}
    ];
    this.busquedaSer.busquedaInvMaeBodega6().subscribe((res: any[]) => {
      this.arregloBus = res;
      this.displayDialogBus = true;
    });
  }
  
  abrirCuentas() {
    this.opcion = 'CUENTAS';
    this.types = [
      {label: 'CÓDIGO', value: 'CON_CODIGO'},
      {label: 'NOMBRE', value: 'CON_NOMBRE'}
    ];
    this.busquedaSer.busquedaConMaeCon().subscribe((res: any[]) => {
      this.arregloBus = res;
      this.displayDialogBus = true;
    });
  }
  
  abrirProvincias() {
    this.opcion = 'PROV';
    this.types = [
      {label: 'CÓDIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'}
    ];
    this.busquedaSer.busquedaSaciMaeReferencia6('REF_TIPO = \'PV\' ORDER BY REF_CODIGO').subscribe((res: any[]) => {
      this.arregloBus = res;
      this.displayDialogBus = true;
    });
  }
  
  abrirZonas() {
    this.opcion = 'ZONA';
    this.types = [
      {label: 'CÓDIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'}
    ];
    this.busquedaSer.busquedaSaciMaeReferencia6('REF_TIPO = \'Z\' ORDER BY REF_CODIGO').subscribe((res: any[]) => {
      this.arregloBus = res;
      this.displayDialogBus = true;
    });
  }
  
  abrirCiudades() {
    this.opcion = 'CIU';
    this.types = [
      {label: 'CÓDIGO', value: 'CIU_CODIGO'},
      {label: 'NOMBRE', value: 'CIU_NOMBRE'},
      {label: 'NOMBRE CORTO', value: 'CIU_NOMBREC'}
    ];
    this.busquedaSer.busquedaVenMaeCiudad1().subscribe((res: any[]) => {
      this.arregloBus = res;
      this.displayDialogBus = true;
    });
  }
  
  manejarSeleccion(opcion) {
    if (this.opcion === 'CAJAS') {
      this.vendedorSeleccionado.CAJ_CODIGO = opcion.CAJ_CODIGO;
      this.cajaNombre = opcion.CAJ_DESCRIPCION;
      this.displayDialogBus = false;
    }
    if (this.opcion === 'BODEGAS') {
      this.vendedorSeleccionado.BOD_CODIGO = opcion.BOD_CODIGO;
      this.bodegaNombre = opcion.BOD_NOMBRE;
      this.displayDialogBus = false;
    }
    if (this.opcion === 'CUENTAS') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.message.add({
          key: 'vend',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.vendedorSeleccionado.CON_CODIGO = opcion.CON_CODIGO;
        this.conNombre = opcion.CON_NOMBRE;
        this.displayDialogBus = false;
      }
    }
    if (this.opcion === 'PROV') {
      this.vendedorSeleccionado.VEN_PROVINCIA = opcion.REF_CODIGO;
      this.provNombre = opcion.REF_NOMBRE;
      this.displayDialogBus = false;
    }
    if (this.opcion === 'ZONA') {
      this.vendedorSeleccionado.VEN_ZONA = opcion.REF_CODIGO;
      this.zonaNombre = opcion.REF_NOMBRE;
      this.displayDialogBus = false;
    }
    if (this.opcion === 'CIU') {
      this.vendedorSeleccionado.CIU_CODIGO = opcion.CIU_CODIGO;
      this.ciuNombre = opcion.CIU_NOMBRE;
      this.displayDialogBus = false;
    }
  }
}
