import {Component, OnInit, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {NommaedepartamentoService} from '../nomservicios/nommaedepartamento.service';
import {MessageService, ConfirmationService} from 'primeng';
import {NomMaedepartamento} from '../nominterfaces/nommaedepartamento';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';

@Component({
  selector: 'app-nommaedepartamento',
  templateUrl: './nommaedepartamento.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NommaedepartamentoComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  btnGuardar: boolean;
  btnNuevo: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  
  defaultColDefMaeDepartamento;
  public frameworkComponents;
  rowStyle;
  largo = '258';
  rowSelection = 'multiple';
  nommaedepartamento: NomMaedepartamento[] = [];
  selected_maeDepartamento: NomMaedepartamento;
  columnDefMaeDepartamento = [
    {
      headerName: 'Código', field: 'DEP_CODIGO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: 'Descripción', field: 'DEP_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 200,
            tienecalculos: true
          }
        }
    },
  ];
  
  constructor(
    private messageService: MessageService,
    public maedepartamentoService: NommaedepartamentoService,
    private confirmationService: ConfirmationService,
    private init: NuevoComponentService,
    private errorService: ErroresBaseDatosService
  ) {
    this.maedepartamentoService.nommaedepartamento = [];
    this.maedepartamentoService.initBotones();
    this.agTable();
  }
  
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefMaeDepartamento = {
      editable: true,
      width: 130,
      objeto: 'maeDepartamento'
    };
  }
  
  ngOnInit(): void {
    this.btnNuevo = this.maedepartamentoService.btnNuevo;
    this.btnGuardar = this.maedepartamentoService.btnGuardar;
    this.btnBorrar  = this.maedepartamentoService.btnBorrar;
    this.btnRegresar = this.maedepartamentoService.btnRegresar;
    this.getDataMaeDepartamento();
  }
  
  async getDataMaeDepartamento() {
    
    
    await this.maedepartamentoService.cargaDatos().then(res => {
      this.maedepartamentoService.nommaedepartamento = res;
      // console.log(maedepart);
      return;
    })
      .catch(e => {
        console.log(e);
      });
    
  }
  
  selectMaeDepartamento(elemento) {
    if (elemento.object !== null) {
      this.selected_maeDepartamento = elemento.object;
      if (this.selected_maeDepartamento.old_DEP_CODIGO === undefined) {
        this.selected_maeDepartamento.old_DEP_CODIGO = this.selected_maeDepartamento.DEP_CODIGO;
      }
      //console.log(this.selected_maeDepartamento);
    }
  }
  
  cambio(cambio) {
    //console.log( cambio );
    this.maedepartamentoService.btnGuardar = false,
    this.btnRegresar = false;
    if (this.selected_maeDepartamento.nuevo === undefined) {
      this.selected_maeDepartamento.editado = true;
    }
  }
  
  manejarSenales(valor) {
    switch (valor) {
      case 'Nuevo':
        this.opcionNuevo();
        break;
      case 'Guardar':
        this.opcionGuardar();
        break;
      case 'Borrar':
        this.opcionBorrar();
        break;
      case 'Cancelar':
        this.opcionCancelar();
        break;
      case 'Salir':
        this.init.tabs.splice(  this.init.tabindex, 1);
        break;
      
    }
  }
  
  opcionNuevo() {
    
    this.maedepartamentoService.btnNuevo = true;
    this.maedepartamentoService.btnBorrar = false;
    this.maedepartamentoService.btnRegresar = false;
    this.aggrid.isEditable(true);
    
    const new_nommaedepart: NomMaedepartamento = {
      DEP_CODIGO: '',
      DEP_NOMBRE: '',
      nuevo: true,
    };
    this.maedepartamentoService.nommaedepartamento.push(new_nommaedepart);
    this.aggrid.refreshaggrid(this.maedepartamentoService.nommaedepartamento, this.defaultColDefMaeDepartamento.objeto);
    
  }
  
  opcionGuardar() {
    
    
    this.aggrid.gridApi.stopEditing();
    if (this.selected_maeDepartamento.nuevo && this.selected_maeDepartamento.DEP_NOMBRE !== ''
      && this.selected_maeDepartamento.DEP_CODIGO !== '') {
      this.maedepartamentoService.insertar(this.selected_maeDepartamento);
    } else if (this.selected_maeDepartamento.editado && this.selected_maeDepartamento.DEP_NOMBRE !== ''
      && this.selected_maeDepartamento.DEP_CODIGO !== '') {
      this.maedepartamentoService.actualizar(this.selected_maeDepartamento);
    }
    
  }
  
  opcionBorrar() {
    
    this.confirmationService.confirm({
      message: '¿Está seguro que desea eliminar el departamento?',
      header: 'Eliminar Departamento',
      icon: 'pi pi-exclamation-triangle',
      key: 'NomMaeDepDialog',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        this.borrarDepartamento();
      },
      reject: () => {
      }
    });
  }
  
  async opcionCancelar() {
    await this.maedepartamentoService.cargaDatos().then(res => {
      this.maedepartamentoService.nommaedepartamento = res;
      this.maedepartamentoService.initBotones();
    }).catch(e => {
      console.log(e);
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.menssajeAlerta('error', 'Error', mensaje);
    });
  }
  
  
  borrarDepartamento() {
    //console.log('borrar');
    /* this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true; */
    
    this.maedepartamentoService.borrar(this.selected_maeDepartamento);
    
  }


  menssajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'nommaedeptoast',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
    
  }

  

  
  
  
  
  
  
}
