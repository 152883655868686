import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Observable} from 'rxjs';
import {comnDETnotrec} from '../cominterfaces/comnDETnotrec';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class ComdetfacproService {
  get detfac(): comnDETnotrec[] {
    return this._detfac;
  }
  
  set detfac(value: comnDETnotrec[]) {
    this._detfac = value;
  }

  public way: string;

  private _detfac: comnDETnotrec[];
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService) {
    
    this.way= sessionStorage.getItem('way');
  }
  
  getDetfac(encfacnumero): Observable<any[]> {
    return this.http.post<any[]>(this.way+ '/compras/factproveedores/83bacc59aeebd80', {
        elementos: {
          ENCFACPRO_NUMERO: encfacnumero
        }
    }, this.confIniciales.httpOptions());
  }

  getDetfacDev(encfacnumero): Observable<any[]> {
    return this.http.post<any[]>(this.way+ '/compras/factproveedores/83bacc59aeeb600', {
        elementos: {
          p_ComCodigo: '\'' + '01' + '\'',
          p_CodFacPro: '\'' + encfacnumero + '\'',
          p_filto: null,
        }
    }, this.confIniciales.httpOptions());
  }
}
