import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {MessageService} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {Banmaeban} from '../baninterfaces/banmaeban';
import {BanmaebanEstCnt} from '../baninterfaces/banmaebanEstCnt';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class BanmaebanService {
  
  get banmaeban(): Banmaeban[] {
    return this._banmaeban;
  }
  set banmaeban(value: Banmaeban[]) {
    this._banmaeban = value;
  }
  
  
  get banmaebanEstCnt(): BanmaebanEstCnt[] {
    return this._banmaebanEstCnt;
  }
  set banmaebanEstCnt(value: BanmaebanEstCnt[]) {
    this._banmaebanEstCnt = value;
  }
  
  
 
  private way: string;
  private _banmaeban: Banmaeban[];
  private _banmaebanEstCnt: BanmaebanEstCnt[];

  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private messageService: MessageService,
              private errorService: ErroresBaseDatosService,
              private confIniciales: ConfInicialesService) {
   
    this.way = sessionStorage.getItem('way');
  }
  
  getCntBancarias(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ban/ctabancaria/20eeb3aa5aaec60000', {
        elementos: {}
      }, this.confIniciales.httpOptions()
    );
  }

  
  insertarCuentaBancaria(banmaeban: Banmaeban): Observable<any> {
    return this.http.post<any>(this.way + '/ban/ctabancaria/95f8edf59d52d80', {
      elementos: {
        p_BAN_codigo_1: banmaeban.BAN_CODIGO ,
        p_BAN_banco_2:  banmaeban.BAN_BANCO ,
        p_BAN_sucursal_3 : banmaeban.BAN_SUCURSAL ,
        p_BAN_direccion_4:  banmaeban.BAN_DIRECCION ,
        p_BAN_telefono1_5 : banmaeban.BAN_TELEFONO1 ,
        p_BAN_telefono2_6 : banmaeban.BAN_TELEFONO2 ,
        p_BAN_correo_7 : banmaeban.BAN_CORREO ,
        p_BAN_ctanro_8 : banmaeban.BAN_CTANRO ,
        p_BAN_jefecta_9 :  banmaeban.BAN_JEFECTA ,
        p_CON_codigo_10 : banmaeban.CON_CODIGO,
        p_COM_codigo_11 : '01' ,
        p_CEN_codigo_12 : banmaeban.CEN_CODIGO ,
        p_BAN_tipocta_13 : banmaeban.BAN_TIPOCUENTA ,
        p_CON_codigo_14 : banmaeban.CON_CODIGO_2 ,
        p_CON_codigo_15 : banmaeban.CON_CODIGO_3 ,
        p_BAN_PROCESO_CM   : banmaeban.BAN_PROCESO_CM ,
        p_BAN_DIRECTORIO_CM  : banmaeban.BAN_DIRECTORIO_CM ,
        p_BAN_DINEROELEC  : banmaeban.BAN_DINEROELEC ,
        p_BAN_TELEFONOCEL : banmaeban.BAN_TELEFONOCEL
      }
    }, this.confIniciales.httpOptions());
  }
  
  actualizarCuentaBancaria(banmaeban: Banmaeban): Observable<any> {
    return this.http.post<any>(this.way + '/ban/ctabancaria/299d6754b55d8a', {
      elementos: {
        p_BAN_codigo_1: banmaeban.BAN_CODIGO ,
        p_BAN_codigo_2: banmaeban.BAN_CODIGO ,
        p_BAN_banco_3:  banmaeban.BAN_BANCO ,
        p_BAN_sucursal_4 : banmaeban.BAN_SUCURSAL ,
        p_BAN_direccion_5:  banmaeban.BAN_DIRECCION ,
        p_BAN_telefono1_6 : banmaeban.BAN_TELEFONO1 ,
        p_BAN_telefono2_7 : banmaeban.BAN_TELEFONO2 ,
        p_BAN_correo_8 : banmaeban.BAN_CORREO ,
        p_BAN_ctanro_9 : banmaeban.BAN_CTANRO ,
        p_BAN_jefecta_10 :  banmaeban.BAN_JEFECTA ,
        p_CON_codigo_11 : banmaeban.CON_CODIGO,
        p_COM_codigo_12 : '01' ,
        p_CEN_codigo_13 : banmaeban.CEN_CODIGO ,
        p_BAN_tipocta_14 : banmaeban.BAN_TIPOCUENTA ,
        p_CON_codigo_15 : banmaeban.CON_CODIGO_2 ,
        p_CON_codigo_16 : banmaeban.CON_CODIGO_3 ,
        p_BAN_PROCESO_CM   : banmaeban.BAN_PROCESO_CM ,
        p_BAN_DIRECTORIO_CM  : banmaeban.BAN_DIRECTORIO_CM ,
        p_BAN_DINEROELEC  : banmaeban.BAN_DINEROELEC ,
        p_BAN_TELEFONOCEL : banmaeban.BAN_TELEFONOCEL
      }
    }, this.confIniciales.httpOptions());
  }
  
  eliminarCuentaBancaria(banmaeban: Banmaeban): Observable<any> {
    return this.http.post<any>(this.way + '/ban/ctabancaria/756566754b55d80', {
      elementos: {
        p_CUEBANcodigo_1: banmaeban.BAN_CODIGO ,
        p_COM_codigo : '01',
      }
    }, this.confIniciales.httpOptions());
  }
  
  obtenerSaldoTrBan(bancodigo: string ): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ban/ctabancaria/30bef1556e3bbc0000', {
        elementos: {
          p_BAN_codigop: bancodigo,
          p_COM_codigo: '01'
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  obtenerSaldoInicial(concodigo: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ban/ctabancaria/c2fbc555b895e80', {
        elementos: {
          CON_CODIGO: concodigo,
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  consultarBancos(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ban/ctabancaria/662fcf556abb300', {
        elementos: {}
      }, this.confIniciales.httpOptions()
    );
  }
  
  consultarProcesoCM(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ban/ctabancaria/83bb9de18ee6180', {
        elementos: {}
      }, this.confIniciales.httpOptions()
    );
  }
  
  consultarScrsBanco(banbanco: string ): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ban/ctabancaria/83bbc66f8b55d80', {
        elementos: {
          p_banco: banbanco
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  verificarMovimientoBanco(bancod: string ): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ban/ctabancaria/7ddb93ed8fad58', {
        elementos: {
          BAN_CODIGO: bancod,
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  actualizarNumCheque(banmaeban: Banmaeban ): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ban/ctabancaria/299df5fd6645da', {
       
        elementos: {
          p_CUEBANcodigo_1 : banmaeban.BAN_CODIGO,
          p_BAN_ULTIMOCHEQUE : banmaeban.BAN_ULTIMOCHEQUE,
          p_COM_codigo: '01'
  }
      }, this.confIniciales.httpOptions()
    );
  }
  
  encontrarBanCodigo(strBancodigo) {
    return this.http.post(this.way + '/ban/ctabancaria/75d8b55d986ca00', {
      elementos: {
        ban_codigo: strBancodigo
      },
    }, this.confIniciales.httpOptions());
  }
  
  obtenerEstCntBnc(banmaeban: Banmaeban): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ban/ctabancaria/c2fbceb59d52f00', {
        elementos: {
          p_BAN_codigop : banmaeban.BAN_CODIGO
        }
      }, this.confIniciales.httpOptions()
    );
  }

  
}
