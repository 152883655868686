import {Component, OnInit, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {SegmaeorganizacionMenu, SegmaeorganizacionModulo, SegmaeorganizacionOpcion} from '../seginterfaces/segmaeorganizacion';
import {SegmaeorganizacionService} from '../segservicios/segmaeorganizacion.service';


@Component({
  selector: 'app-segmaeorganizacion',
  templateUrl: './segmaeorganizacion.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class SegmaeorganizacionComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  btnGuardar: boolean;
  btnNuevo = true;
  btnBorrar: boolean;
  btnRegresar: boolean;
  btnBuscar: boolean;
  
  defaultColDefMaeOrgModulos;
  defaultColDefMaeOrgMenu;
  defaultColDefMaeOrgOpciones;
  public frameworkComponents;
  rowStyle;
  largo = '258';
  rowSelection = 'multiple';
  indexTab = 0;
  orden: number[] = [];
  
  segmaeorganizacionModulos: SegmaeorganizacionModulo[] = [];
  selectedMaeOrgModulo: SegmaeorganizacionModulo;
  segmaeorganizacionMenus: SegmaeorganizacionMenu[] = [];
  selectedMaeOrgMenu: SegmaeorganizacionMenu;
  segmaeorganizacionOpciones: SegmaeorganizacionOpcion[] = [];
  selectedMaeOrgOpcion: SegmaeorganizacionOpcion;
  
  moduloOpcion: SegmaeorganizacionModulo;
  menuOpcion: SegmaeorganizacionMenu;
  
  activateTabs = [false, false, false];
  visibleGridModulo = true;
  visibleGridMenu =  true;
  columnDefOrganizacionModulos = [
    {
      headerName: 'Prefijo', field: 'PREFIJO', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
  const boolEdit = this.isEdit(params.data) ;
  return boolEdit;
},
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Nombre', field: 'NOMBRE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 600,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Externo', field: 'EXTERNO', cellEditor: 'agSelectCellEditor', width: 100, onCellValueChanged: (params) => {
        this.cambioModulo(null);
      },
      cellEditorParams:
        {
          values: ['N', 'S']
        },
      
    },
    {
      headerName: 'Orden', field: 'ORDEN', cellEditor: 'agSelectCellEditor', width: 100, onCellValueChanged: (params) => {
        this.cambioModulo(null);
      },
      cellEditorParams:
        {
          values: this.orden
        }
    },
  
  ];
  
  columnDefOrganizacionMenus = [
    {
      headerName: 'Código', field: 'CODIGO', cellEditor: 'cellPrueba', width: 150, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Nombre', field: 'NOMBRE', cellEditor: 'cellPrueba', width: 300,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 600,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Orden', field: 'ORDEN', cellEditor: 'agSelectCellEditor', width: 100, onCellValueChanged: (params) => {
        this.cambioMenu(null);
      },
      cellEditorParams:
        {
          values: this.orden
        }
    },
  
  ];
  
  columnDefOrganizacionOpciones = [
    {
      headerName: 'Código', field: 'OPCCODIGO', cellEditor: 'cellPrueba', width: 90, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Nombre', field: 'OPCNOMBRE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 600,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Formulario', field: 'OPCOBJETO', cellEditor: 'cellPrueba', width: 300,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 600,
            tienecalculos:  false,
          }
        }
    },
    {
      headerName: 'Orden', field: 'OPCORDEN', cellEditor: 'agSelectCellEditor', width: 100, onCellValueChanged: (params) => {
        this.cambioOpcion(null);
      },
      cellEditorParams:
        {
          values: this.orden
        }
    },
  
  ];
  
  constructor(private messageService: MessageService,
              private init: NuevoComponentService,
              private errorService: ErroresBaseDatosService,
              private confirmationService: ConfirmationService,
              private segmaeOrganizacionService: SegmaeorganizacionService
  ) {
    this.agTable();
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefMaeOrgModulos = {
      editable: true,
      width: 130,
      objeto: 'maeOrgModulos'
    };
    this.defaultColDefMaeOrgMenu = {
      editable: true,
      width: 130,
      objeto: 'maeOrgMenus'
    };
    this.defaultColDefMaeOrgOpciones = {
      editable: true,
      width: 130,
      objeto: 'maeOrgOpciones'
    };
  }
  
  isEdit(data): boolean {
    if (data.nuevo === true ) {
      return true;
    }
    return false;
  }

  ngOnInit() {
  
    this.cargar();
    
  }
  
  async cargar() {
    this.btnNuevo =  false;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.btnRegresar = false;
    this.btnBuscar = false;
    this.activateTabs = [false, false, false];
    this.llenarOrden();
    await this.getModulos();
    await this.getMenus();
    this.getModuloMenuOpciones( this.moduloOpcion.PREFIJO, this.menuOpcion.CODIGO );
  }
  
  llenarOrden() {
    for (let i = 1; i < 100; i++ ) {
      this.orden.push(i);
    }
  }
  async getModulos() {
    await this.segmaeOrganizacionService.consultaModulos().then( res => {
      this.segmaeorganizacionModulos = res;
      this.moduloOpcion = this.segmaeorganizacionModulos[0];
      this.segmaeorganizacionModulos.map( x => {
        if ( x.EXTERNO === null ) {
          x.EXTERNO = 'N';
        }
      } );
      return;
    } )
      .catch(e => console.error(e));
  }
  
  async getMenus() {
    await this.segmaeOrganizacionService.consultaMenus().then( res => {
      // console.log( res );
      this.segmaeorganizacionMenus = res;
      this.menuOpcion = this.segmaeorganizacionMenus[0];
      return;
    } )
      .catch(e => console.error(e));
  }
  
  async getModuloMenuOpciones(prefijo, codigo) {
    // console.log(prefijo, codigo);
    await this.segmaeOrganizacionService.cargarOpcionMenuModulo(prefijo, codigo).then( res => {
      if ( res === null ) {
        this.segmaeorganizacionOpciones = [];
      } else {
        this.segmaeorganizacionOpciones = res;
      }
      
    } )
      .catch(e => console.error(e));
  }
  
  selectMaeOrgModulo(elemento) {
    if (elemento.object !== null) {
      this.selectedMaeOrgModulo = elemento.object;
      this.moduloOpcion = this.selectedMaeOrgModulo;
      if ( this.moduloOpcion.nuevo !== true  ) {
        this.getModuloMenuOpciones( this.moduloOpcion.PREFIJO, this.menuOpcion.CODIGO );
      }
      
      // console.log(this.selectedMaeOrgModulos);
    }
  }
  
  selectMaeOrgMenu(elemento) {
    if (elemento.object !== null) {
      this.selectedMaeOrgMenu = elemento.object;
      this.menuOpcion = this.selectedMaeOrgMenu;
      if ( this.menuOpcion.nuevo !== true  ) {
        this.getModuloMenuOpciones( this.moduloOpcion.PREFIJO, this.menuOpcion.CODIGO );
      }
    }
  }
  selectMaeOrgOpcion(elemento) {
    if (elemento.object !== null) {
      this.selectedMaeOrgOpcion = elemento.object;
    }
  }
  
  cambioModulo(cambio) {
    this.btnGuardar = false;
    this.btnRegresar = false;
    this.btnNuevo = true;
    this.innhabilitarTabs(this.indexTab);
    if (this.selectedMaeOrgModulo.nuevo === undefined) {
      this.selectedMaeOrgModulo.editado = true;
    }
  }
  
  cambioMenu(cambio) {
    this.btnGuardar = false;
    this.btnRegresar = false;
    this.btnNuevo = true;
    this.innhabilitarTabs(this.indexTab);
    if (this.selectedMaeOrgMenu.nuevo === undefined) {
      this.selectedMaeOrgMenu.editado = true;
    }
  }
  
  cambioOpcion(cambio) {
    this.btnGuardar = false;
    this.btnRegresar = false;
    this.btnNuevo = true;
    this.innhabilitarTabs(this.indexTab);
    if (this.selectedMaeOrgOpcion.nuevo === undefined) {
      this.selectedMaeOrgOpcion.editado = true;
    }
  }
  
  manejarSenales(valor) {
    switch (valor) {
      case 'Nuevo':
        this.opcionNuevo();
        break;
      case 'Guardar':
        this.opcionGuardar();
        break;
      case 'Borrar':
        this.opcionEliminar();
        break;
      case 'Cancelar':
        
        this.cargar();
        this.onTab1Change();
        break;
      case 'Salir':
        this.init.tabs.splice(this.init.tabindex, 1);
        break;
    
    }
  }
  
  opcionNuevo() {
    this.btnNuevo = true;
    this.btnBorrar = false;
    this.btnRegresar = false;
    this.aggrid.isEditable(true);
    
    switch ( this.indexTab ) {
      
      case 0:
        this.nuevoModulo();
        this.innhabilitarTabs(this.indexTab);
        break;
      case 1:
        this.nuevoMenu();
        this.innhabilitarTabs(this.indexTab);
        break;
      case 2:
        this.nuevaOpcion();
        this.innhabilitarTabs(this.indexTab);
        break;
      
    }
  }
  
  innhabilitarTabs(indice: number ) {
    this.activateTabs = [true, true, true];
    this.activateTabs[indice] = false;
    
  }
  
  nuevoModulo() {
    const newModulo: SegmaeorganizacionModulo = {
      PREFIJO:  '',
      NOMBRE:   '',
      EXTERNO:  '',
      ORDEN:    null,
      nuevo:    true,
    };
    
    this.segmaeorganizacionModulos.push(newModulo);
    this.aggrid.refreshaggrid(this.segmaeorganizacionModulos, this.defaultColDefMaeOrgModulos.objeto );
  }
  
  nuevoMenu() {
  
    const newMenu: SegmaeorganizacionMenu = {
      CODIGO: null,
      FECHA: null,
      NOMBRE: '',
      ORDEN: null,
      nuevo: true
    };
  
    this.segmaeorganizacionMenus.push(newMenu);
    this.aggrid.refreshaggrid(this.segmaeorganizacionMenus, this.defaultColDefMaeOrgMenu.objeto );
  
  }
  
  nuevaOpcion() {
    const newOpcion: SegmaeorganizacionOpcion = {
      MENCODIGO:  this.menuOpcion.CODIGO,
      MENNOMBRE:  '',
      MODNOMBRE:  '',
      MODPREFIJO: this.moduloOpcion.PREFIJO,
      OPCCODIGO:  null,
      OPCNOMBRE:  '',
      OPCOBJETO:  '',
      OPCORDEN:   '',
      nuevo:      true,
    };
  
    this.segmaeorganizacionOpciones.push(newOpcion);
    this.aggrid.refreshaggrid(this.segmaeorganizacionOpciones, this.defaultColDefMaeOrgOpciones.objeto );
  }
  
  onTab1Change() {
    switch ( this.indexTab ) {
  
      case 0:
        this.visibleGridModulo = true;
        this.visibleGridMenu   = false;
        break;
      case 1:
        this.visibleGridModulo = false;
        this.visibleGridMenu   = true;
        break;
      case 2:
        this.visibleGridModulo = false;
        this.visibleGridMenu   = false;
        break;
    }
  }
  
  opcionGuardar() {
    switch ( this.indexTab ) {
    
      case 0:
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefMaeOrgModulos.objeto].stopEditing();
        this.guardarModulo();
        break;
      case 1:
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefMaeOrgMenu.objeto].stopEditing();
        if ( this.selectedMaeOrgMenu.nuevo === true  ) {
          this.guardarMenu();
        } else
  
        if ( this.selectedMaeOrgMenu.editado === true  ) {
          this.actualizarMenu();
        }
        break;
      case 2:
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefMaeOrgOpciones.objeto].stopEditing();
        if ( this.selectedMaeOrgOpcion.nuevo === true  ) {
          this.guardarOpcion();
        } else
  
        if ( this.selectedMaeOrgOpcion.editado === true  ) {
          this.actualizarOpcion();
        }
        break;
    
    }
    
    
  }
  
  opcionEliminar() {
    switch ( this.indexTab ) {
      
      case 0:
        this.opcionBorrar(this.selectedMaeOrgModulo.NOMBRE);
        break;
      case 1:
        this.opcionBorrar(this.selectedMaeOrgMenu.NOMBRE);
        break;
      case 2:
        this.opcionBorrar(this.selectedMaeOrgOpcion.OPCNOMBRE);
        break;
      
    }
    
    
  }
  
  eliminarModulo() {
    this.segmaeOrganizacionService.eliminarModulo(this.selectedMaeOrgModulo.PREFIJO).then( () => {
      this.mensajeAlerta('success', 'Información', '¡Modulo eliminado!');
      this.cargar();
    } )
      .catch( e => {
        console.error(e);
        const mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      });
  }
  
  eliminarMenu() {
    this.segmaeOrganizacionService.eliminarMenu(this.selectedMaeOrgMenu.CODIGO).then( () => {
      this.mensajeAlerta('success', 'Información', '¡Menú eliminado!');
      this.cargar();
    } )
      .catch( e => {
        console.error(e);
        const mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      });
  }
  
  eliminarOpcion() {
    this.segmaeOrganizacionService.eliminarOpcion( this.menuOpcion.CODIGO, this.moduloOpcion.PREFIJO,
        this.selectedMaeOrgOpcion.OPCCODIGO ).then( () => {
      this.mensajeAlerta('success', 'Información', '¡Opción eliminada!');
      this.btnNuevo =  false;
      this.btnGuardar = true;
      this.btnBorrar  = false;
      this.btnRegresar = false;
      this.btnBuscar = false;
      this.activateTabs = [false, false, false];
      this.getModuloMenuOpciones( this.moduloOpcion.PREFIJO, this.menuOpcion.CODIGO );
      this.onTab1Change();
    } )
      .catch( e => {
        console.error(e);
        const mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      });
  }
  
  
  
  guardarModulo() {
    // onsole.log(this.selectedMaeOrgModulo);
    let paquete = '';
    const modulo: SegmaeorganizacionModulo = {
      EXTERNO: this.selectedMaeOrgModulo.EXTERNO,
      NOMBRE:  this.selectedMaeOrgModulo.NOMBRE,
      PREFIJO: this.selectedMaeOrgModulo.PREFIJO,
      ORDEN: this.selectedMaeOrgModulo.ORDEN
    };
    if ( this.selectedMaeOrgModulo.nuevo === true  ) {
       paquete = 'SEG_PCK_MAEMODULO.SEG_SP_insertarModulo';
    }
  
    if ( this.selectedMaeOrgModulo.editado === true  ) {
      paquete = 'SEG_PCK_MAEMODULO.SEG_SP_actualizarModulo';
    }
  
    let valido = true;
    for ( const key in modulo ) { // recorrer campos de un objeto
      if ( modulo[key] === null || modulo[key] === '') {
        valido = false;
      
      }
    }
    if ( valido ) {
       this.segmaeOrganizacionService.guardarModulo( this.selectedMaeOrgModulo.PREFIJO, this.selectedMaeOrgModulo.NOMBRE,
          this.selectedMaeOrgModulo.EXTERNO, this.selectedMaeOrgModulo.ORDEN, paquete  ).then(  () => {
         this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
         this.cargar();
       } )
         .catch( e => {
           console.error(e);
           const mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
           this.mensajeAlerta('error', 'Error', mensaje);
         });
    } else {
      this.mensajeAlerta('info', 'Información', 'Se han encontrado campos vacíos');
    }
  }
  
  guardarMenu() {
    const menu: SegmaeorganizacionMenu = {
      NOMBRE: this.selectedMaeOrgMenu.NOMBRE,
      ORDEN:  this.selectedMaeOrgMenu.ORDEN,
    };
    const valido = this.validarObjeto(menu);
    if ( valido ) {
      this.segmaeOrganizacionService.guardarMenu(this.selectedMaeOrgMenu.NOMBRE, Number(this.selectedMaeOrgMenu.ORDEN) ).then(  (res) => {
        this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
        this.cargar();
      } )
        .catch( e => {
          console.error(e);
          const mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        });
    } else {
      this.mensajeAlerta('info', 'Información', 'Se han encontrado campos vacíos');
    }
  }
  
  actualizarMenu() {
    const menu: SegmaeorganizacionMenu = {
      NOMBRE: this.selectedMaeOrgMenu.NOMBRE,
      ORDEN:  this.selectedMaeOrgMenu.ORDEN,
      CODIGO: this.selectedMaeOrgMenu.CODIGO
    };
    const valido = this.validarObjeto(menu);
    if ( valido ) {
      this.segmaeOrganizacionService.actualizarMenu( this.selectedMaeOrgMenu.CODIGO, this.selectedMaeOrgMenu.NOMBRE,
          Number(this.selectedMaeOrgMenu.ORDEN) ).then(  () => {
        this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
        this.cargar();
      } )
        .catch( e => {
          console.error(e);
          const mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        });
    } else {
      this.mensajeAlerta('info', 'Información', 'Se han encontrado campos vacíos');
    }
  }
  
  
  guardarOpcion() {
    const opcion: SegmaeorganizacionOpcion = {
      // MENCODIGO:  this.selectedMaeOrgOpcion.MENCODIGO, //
      MODPREFIJO: this.selectedMaeOrgOpcion.MODPREFIJO, //
      OPCNOMBRE:  this.selectedMaeOrgOpcion.OPCNOMBRE, //
      OPCOBJETO:  this.selectedMaeOrgOpcion.OPCOBJETO, //
      OPCORDEN:   this.selectedMaeOrgOpcion.OPCORDEN,   //
    };
    const valido = this.validarObjeto(opcion);
    if ( valido ) {
      this.segmaeOrganizacionService.guardarOpcion(this.selectedMaeOrgOpcion.MODPREFIJO, this.selectedMaeOrgOpcion.MENCODIGO,
          this.selectedMaeOrgOpcion.OPCNOMBRE, this.selectedMaeOrgOpcion.OPCOBJETO, this.selectedMaeOrgOpcion.OPCORDEN ).then(  async (res) => {
        this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
        // this.cargar();
        this.btnNuevo =  false;
        this.btnGuardar = true;
        this.btnBorrar  = false;
        this.btnRegresar = false;
        this.btnBuscar = false;
        await this.getModuloMenuOpciones( this.moduloOpcion.PREFIJO, this.menuOpcion.CODIGO );
        this.onTab1Change();
        await this.getMenus();
      } )
        .catch( e => {
          console.error(e);
          const mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        });
    } else {
      this.mensajeAlerta('info', 'Información', 'Se han encontrado campos vacíos');
    }
  }
  
  actualizarOpcion() {
    const opcion: SegmaeorganizacionOpcion = {
      MENCODIGO:  this.selectedMaeOrgOpcion.MENCODIGO,
      MODPREFIJO: this.selectedMaeOrgOpcion.MODPREFIJO, //
      OPCNOMBRE:  this.selectedMaeOrgOpcion.OPCNOMBRE, //
      OPCOBJETO:  this.selectedMaeOrgOpcion.OPCOBJETO, //
      OPCORDEN:   this.selectedMaeOrgOpcion.OPCORDEN,   //
    };
    
    const valido = this.validarObjeto(opcion);
    if ( valido ) {
      this.segmaeOrganizacionService.actualizarOpcion(this.selectedMaeOrgOpcion.OPCCODIGO, this.selectedMaeOrgOpcion.MODPREFIJO,
          this.selectedMaeOrgOpcion.MENCODIGO,
          this.selectedMaeOrgOpcion.OPCNOMBRE, this.selectedMaeOrgOpcion.OPCOBJETO, this.selectedMaeOrgOpcion.OPCORDEN ).then(  async (res) => {
        this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
        // this.cargar();
        this.btnNuevo =  false;
        this.btnGuardar = true;
        this.btnBorrar  = false;
        this.btnRegresar = false;
        this.btnBuscar = false;
        this.onTab1Change();
        await this.getModuloMenuOpciones( this.moduloOpcion.PREFIJO, this.menuOpcion.CODIGO );
        await this.getMenus();
      } )
        .catch( e => {
          console.error(e);
          const mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        });
    } else {
      this.mensajeAlerta('info', 'Información', 'Se han encontrado campos vacíos');
    }
  }
  
  
  opcionBorrar(nombre) {
    if ( this.selectedMaeOrgMenu !== undefined || this.selectedMaeOrgModulo !== undefined || this.selectedMaeOrgOpcion !== undefined ) {
      this.confirmationService.confirm({
        message: '¿Está seguro que desea eliminar ' + nombre +  '?',
        header: 'Eliminar Grupo',
        icon: 'pi pi-exclamation-triangle',
        key: 'segOrgDialog',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {
          switch ( this.indexTab ) {
    
            case 0:
              this.eliminarModulo();
              break;
            case 1:
              this.eliminarMenu();
              break;
            case 2:
              this.eliminarOpcion();
              break;
    
          }
        },
        reject: () => {
        }
      });
    }
  }
  
  
  validarObjeto( objeto ) {
    let valido = true;
    for ( const key in objeto ) { // recorrer campos de un objeto
      if ( objeto[key] === null || objeto[key] === '') {
        valido = false;
      }
    }
    return  valido;
  }
  
  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'segMaeOrgtoast',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
    
  }
  

}
