import {Injectable} from '@angular/core';
import {Banmaecaja} from '../baninterfaces/banmaecaja';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Observable} from 'rxjs';
import {Bantrncaja} from '../baninterfaces/bantrncaja';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';

@Injectable({
    providedIn: 'root'
})
export class BancajaService {
    get banmaecaja(): Banmaecaja[] {
        return this._banmaecaja;
    }
    set banmaecaja(value: Banmaecaja[]) {
        this._banmaecaja = value;
    }
    get bantrnmaecaja(): Bantrncaja[] {
        return this._bantrnmaecaja;
    }
    set bantrnmaecaja(value: Bantrncaja[]) {
        this._bantrnmaecaja = value;
    }
    
    private way: string;
    private _banmaecaja: Banmaecaja[];
    private _bantrnmaecaja: Bantrncaja[];
    constructor(private http: HttpClient,
                public usuarioEsquema: UsuarioEsquema,
                public usuario: Usuario,
                private confIniciales: ConfInicialesService) {
        this.way = sessionStorage.getItem('way');
    }
    getBanCaja(): Observable<any[]> {
      return this.http.post<any[]>(this.way + '/ban/bancaja/20eead5762a6a', {
          elementos: {},
        },
        this.confIniciales.httpOptions());
    }
    existeBanCaja(bancajacodigo): Observable<any[]> {
      return this.http.post<any[]>(this.way + '/ban/bancaja/3b8c973ae62a6a', {
        elementos: {
          caj_codigo: bancajacodigo
        },
        }, this.confIniciales.httpOptions());
    }
    insertarBanCaja(bancaja: Banmaecaja): Observable<any[]> {
        return this.http.post<any>(this.way + '/ban/bancaja/95f8edf56abb180', {
            elementos: {
                p_caja_codigo: bancaja.CAJ_CODIGO,
                p_caja_descripcion: bancaja.CAJ_DESCRIPCION,
                p_con_codigo: bancaja.CON_CODIGO,
                p_cen_codigo: bancaja.CEN_CODIGO,
                p_com_codigo: bancaja.COM_CODIGO,
            }
        }, this.confIniciales.httpOptions());
    }
    actualizarBanCaja(bancaja: Banmaecaja, codigo): Observable<any[]> {
        return this.http.post<any>(this.way + '/ban/bancaja/299df2d5762a6a', {
          
            elementos: {
                p_caja_codigo: bancaja.CAJ_CODIGO,
                p_caja_descripcion: bancaja.CAJ_DESCRIPCION,
                p_con_codigo: bancaja.CON_CODIGO,
                p_cen_codigo: bancaja.CEN_CODIGO,
                p_com_codigo: bancaja.COM_CODIGO,
            }
        }, this.confIniciales.httpOptions());
    }
    
    eliminarBanCaja(bancaja: Banmaecaja): Observable<any[]> {
        return this.http.post<any>(this.way + '/ban/bancaja/7565695d6abb140', {
            elementos: {
                p_caja_codigo: bancaja.CAJ_CODIGO,
            }
        }, this.confIniciales.httpOptions());
    }
    
  
  getTrnCajaProm(codigoCaja: string) {
    return this.http.post<any>(this.way + '/ban/bancaja/30bebb9d62a6a', {
      elementos: {
        p_CAJ_codigop: codigoCaja,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  existeBanCajaProm(bancajacodigo) {
    return this.http.post<any[]>(this.way + '/ban/bancaja/7ddb93d8a9b30c', {
      elementos: {
        CAJ_CODIGO: bancajacodigo
      },
      }, this.confIniciales.httpOptions()).toPromise();
  }
  
}
