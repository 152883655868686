import {Component, OnInit} from '@angular/core';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import { UtilitariosService } from '../../advantage/advantageservices/utilitarios.service';

@Component({
  selector: 'app-construccion',
  templateUrl: './construccion.component.html',
  styleUrls: ['./construccion.component.css']
})
export class ConstruccionComponent implements OnInit {
  imagenes: any[];
  sacim: boolean;
  constructor(private init: NuevoComponentService,
    private utilitariosService: UtilitariosService,) {
  }
  
  async ngOnInit() {
    const valor = await this.utilitariosService.esmarcasaci();

    if (valor === true) {
      this.sacim=true;
    this.imagenes = [
      {
        image: 'banner1.png',
        name: '1'
      },
      {
        image: 'banner2.png',
        name: '2'
      },
      {
        image: 'banner3.png',
        name: '3'
      },
      {
        image: 'banner4.png',
        name: '4'
      },
      {
        image: 'banner5.png',
        name: '5'
      },
      {
        image: 'banner6.png',
        name: '6'
      }
    ];}else{
      this.sacim=false;
    }
  }
  
  salir() {
    this.init.tabs.splice(this.init.tabindex, 1);
  }
}
