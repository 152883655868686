import { Component, OnInit, ViewChild } from '@angular/core';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { DatePipe } from '@angular/common';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { MessageService, SelectItem } from 'primeng';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { AggridBanComponent } from '../aggrid-ban/aggrid-ban.component';
import { BanTrnCajaTrn } from '../baninterfaces/bantrncajatrn';
import { BantrncajaService } from '../banservicios/bantrncaja.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';

@Component({
  selector: 'app-bantrncaja',
  templateUrl: './bantrncaja.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class BantrncajaComponent implements OnInit {

  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;
  btnBuscar: boolean;
  spin = false;
  largo: string;
  public frameworkComponents;
  rowSelection = 'multiple';
  rowStyle;
  defaultBanTrnCaja;

  bandetCajatrn: BanTrnCajaTrn[];

  displayDialogBus: boolean;
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  condicion: string;
  opcion: string;

  displayDialogBanTrnCajaEdit: boolean;

  strCodCaja;
  strTrnCajTipo;
  strTrnCajNumero;
  strTrnCajDocNro;
  strTrnCajOrigen;
  strBanCodigo;
  numDep;
  saldo;
  bandera;
  bandera111;
  ex;

  columnDefsBanTrnCaja = [
    {
      headerName: 'Código', field: 'CAJ_CODIGO', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'left' },
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnCajEdit(params.data);
      }
    },
    {
      headerName: 'Fecha', field: 'TRNCAJ_FECHA', cellEditor: 'cellPrueba', width: 130, cellStyle: { textAlign: 'left' },
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnCajEdit(params.data);
      }
    },
    {
      headerName: 'AN', field: 'BOOL_ANULADO', cellRendererFramework: AggridBanComponent, width: 60,
      editable: false, checkCierre: true, cellStyle: { textAlign: 'left' },
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnCajEdit(params.data);
      }
    },
    {
      headerName: 'DP', field: 'BOOL_DEPOSITADO', cellRendererFramework: AggridBanComponent, width: 60,
      editable: false, checkCierre: true, cellStyle: { textAlign: 'left' },
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnCajEdit(params.data);
      }
    },
    {
      headerName: 'ORIG', field: 'TRNCAJ_ORIGEN', cellEditor: 'cellPrueba', width: 80, cellStyle: { textAlign: 'left' },
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnCajEdit(params.data);
      }
    },
    {
      headerName: 'Tipo', field: 'TRNCAJ_TIPO', cellEditor: 'cellPrueba', width: 80, cellStyle: { textAlign: 'left' },
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnCajEdit(params.data);
      }
    },
    {
      headerName: 'Número', field: 'TRNCAJ_NUMERO', cellEditor: 'cellPrueba', width: 150, cellStyle: { textAlign: 'left' },
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnCajEdit(params.data);
      }
    },
    {
      headerName: 'Vencimiento', field: 'TRNCAJ_FECHAVENCI', cellEditor: 'cellPrueba', width: 130, cellStyle: { textAlign: 'left' },
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnCajEdit(params.data);
      }
    },
    {
      headerName: 'Descripción', field: 'TRNCAJ_DESCRIPCION', cellEditor: 'cellPrueba', width: 250, cellStyle: { textAlign: 'left' },
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnCajEdit(params.data);
      }
    },
    {
      headerName: 'Referencia', field: 'BAN_CODIGO', width: 110, cellEditor: 'cellPrueba', cellStyle: { textAlign: 'left' },
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnCajEdit(params.data);
      }
    },
    {
      headerName: 'Debe', field: 'TRNCAJ_DEBE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnCajEdit(params.data);
      }
    },
    {
      headerName: 'Haber', field: 'TRNCAJ_HABER', width: 100, cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnCajEdit(params.data);
      }
    }
  ];

  constructor(private bantrnCajaService: BantrncajaService, private init: NuevoComponentService,
    private confIniciales: ConfInicialesService, private datePipe: DatePipe,
    private busquedaSer: InvbusquedaService, private message: MessageService,
    private errorService: ErroresBaseDatosService) {
    this.agTable();
    this.bandetCajatrn = [];


  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultBanTrnCaja = {
      editable: false,
      objeto: 'bantrncajatrn',
      resizable: true
    };
  }

  ngOnInit() {
    this.btnNuevo = false;
    this.btnBorrar = true;
    this.spin = false;
    this.largo = '300';
    this.consultarMovCaj(null);
    this.displayDialogBus = false;
    this.arregloBus = [];
    this.displayDialogBanTrnCajaEdit = false;

  }
 //CDPJ
 ngOnDestroy(): void {
  this.confIniciales.instanciasMoviCaja--;
}
//CDPJ
  async manejarSenales(valor) {
    if (valor === 'Nuevo') {
      this.bandera = false;
      this.bandera111 = true;
      this.strCodCaja = '';
      this.strTrnCajTipo = '';
      this.strTrnCajNumero = '';
      this.strTrnCajDocNro = '';
      this.strTrnCajOrigen = 'CAJ';
      this.strBanCodigo = '';
      this.numDep = '';
      this.saldo = 0;

      this.displayDialogBanTrnCajaEdit = true;
    }

    if (valor === 'Guardar') {

    }

    if (valor === 'Cancelar') {
      this.consultarMovCaj(null);
    }

    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }


    if (valor === 'Buscar') {
      this.spin = true;
      await this.consultarMovCaj(null);
      await this.buscarRegistro();
      this.spin = false;
    }

  }

  CerrarTrnCajaEdit(evento) {
    this.consultarMovCaj(null);
  }

  async consultarMovCaj(where) {
    const detalle: BanTrnCajaTrn[] = [];
    try {
      const rsTrnCaja = await this.bantrnCajaService.consultarMovCaj(where, 'T', '');
      let cont = 0;
      if (rsTrnCaja.length > 0) {
        for (const dato of rsTrnCaja) {
          const itemTrnCajas: BanTrnCajaTrn = {};
          itemTrnCajas.CAJ_CODIGO = dato.CODIGO;
          itemTrnCajas.TRNCAJ_FECHA = dato.FECHA === null ? '' : dato.FECHA;
          if (itemTrnCajas.TRNCAJ_FECHA.toString().includes('-') && itemTrnCajas.TRNCAJ_FECHA !== '') {
            itemTrnCajas.TRNCAJ_FECHA = this.datePipe.transform(itemTrnCajas.TRNCAJ_FECHA, 'dd/MM/yyyy');
          }

          itemTrnCajas.TRNCAJ_TIPO = dato.TIPO;
          itemTrnCajas.TRNCAJ_NUMERO = dato.NUMERO;

          itemTrnCajas.TRNCAJ_FECHAVENCI = dato['Fecha Vencimiento'] === null ? '' : dato['Fecha Vencimiento'];
          if (itemTrnCajas.TRNCAJ_FECHAVENCI.toString().includes('-') && itemTrnCajas.TRNCAJ_FECHAVENCI !== '') {
            itemTrnCajas.TRNCAJ_FECHAVENCI = this.datePipe.transform(itemTrnCajas.TRNCAJ_FECHAVENCI, 'dd/MM/yyyy');
          }
          itemTrnCajas.TRNCAJ_IMPORTE = dato.IMPORTE;
          if (dato.IMPORTE > 0) {
            itemTrnCajas.TRNCAJ_DEBE = Number(dato.IMPORTE).toFixed(this.confIniciales.getNumDecSist());
            itemTrnCajas.TRNCAJ_HABER = Number(0).toFixed(this.confIniciales.getNumDecSist());
          }
          if (dato.IMPORTE < 0) {
            itemTrnCajas.TRNCAJ_HABER = Number(dato.IMPORTE * (-1)).toFixed(this.confIniciales.getNumDecSist());
            itemTrnCajas.TRNCAJ_DEBE = Number(0).toFixed(this.confIniciales.getNumDecSist());
          }
          itemTrnCajas.TRNCAJ_DOCNRO = dato['Documento Nro'];
          itemTrnCajas.TRNCAJ_DESCRIPCION = dato.DESCRIPCION;
          itemTrnCajas.TRNCAJ_ORIGEN = dato.ORIGEN;
          itemTrnCajas.TRNCAJ_ANULADO = dato.ANULADO;
          itemTrnCajas.BOOL_ANULADO = dato.ANULADO === 1 ? true : false;
          itemTrnCajas.BAN_CODIGO = dato['Codigo Banco'];
          itemTrnCajas.CEN_CODIGO = dato['Centro de Costos'];
          itemTrnCajas.TRNCAJ_SALDO = Number(dato['Saldo']).toFixed(this.confIniciales.getNumDecSist());
          itemTrnCajas.TRNCAJ_DEPOSITONRO = dato.TRNCAJ_DEPOSITONRO;
          if (Number(itemTrnCajas.TRNCAJ_SALDO) === 0) {
            itemTrnCajas.BOOL_DEPOSITADO = true;
          }
          if (Number(itemTrnCajas.TRNCAJ_SALDO) !== 0) {
            itemTrnCajas.BOOL_DEPOSITADO = false;
          }
          detalle.push(itemTrnCajas);
          cont++;
          if (cont >= 200) {
            break;
          }
        }
        this.bandetCajatrn = detalle;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', 'No se puede leer la información de la base de datos. ' + mensaje);
    }
  }

  buscarRegistro() {
    this.opcion = 'BUSCAR';
    this.types = [
      { label: 'CÓDIGO', value: 'CAJ_CODIGO' },
      { label: 'FECHA', value: 'TRNCAJ_FECHA' },
      { label: 'TIPO', value: 'TRNCAJ_TIPO' },
      { label: 'NÚMERO', value: 'TRNCAJ_NUMERO' },
      { label: 'FECHA VENCIMIENTO', value: 'TRNCAJ_FECHAVENCI' },
    ];

    this.busquedacampos = ['', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'BAN_TRNCAJ';
    this.condicion = '';
    this.arregloCons = [];
    this.displayDialogBusquedaFast = true;
  }

  manejarSeleccion(valor) {

    if (this.opcion === 'BUSCAR') {
      this.displayDialogBusquedaFast = false;
      this.llenarTabla(valor);
    }
  }

  llenarTabla(datos) {
    let cont = 0;
    const detalle: BanTrnCajaTrn[] = [];
    for (const dato of datos) {
      cont++;
      const itemTrnCajas: BanTrnCajaTrn = {};
      itemTrnCajas.CAJ_CODIGO = dato.CAJ_CODIGO;
      itemTrnCajas.TRNCAJ_FECHA = dato.TRNCAJ_FECHA === null ? '' : dato.TRNCAJ_FECHA;
      if (itemTrnCajas.TRNCAJ_FECHA.toString().includes('-') && itemTrnCajas.TRNCAJ_FECHA !== '') {
        itemTrnCajas.TRNCAJ_FECHA = this.datePipe.transform(itemTrnCajas.TRNCAJ_FECHA, 'dd/MM/yyyy');
      }

      itemTrnCajas.TRNCAJ_TIPO = dato.TRNCAJ_TIPO;
      itemTrnCajas.TRNCAJ_NUMERO = dato.TRNCAJ_NUMERO;

      itemTrnCajas.TRNCAJ_FECHAVENCI = dato.TRNCAJ_FECHAVENCI === null ? '' : dato.TRNCAJ_FECHAVENCI;
      if (itemTrnCajas.TRNCAJ_FECHAVENCI.toString().includes('-') && itemTrnCajas.TRNCAJ_FECHAVENCI !== '') {
        itemTrnCajas.TRNCAJ_FECHAVENCI = this.datePipe.transform(itemTrnCajas.TRNCAJ_FECHAVENCI, 'dd/MM/yyyy');
      }

      if (dato.TRNCAJ_IMPORTE > 0) {
        itemTrnCajas.TRNCAJ_DEBE = Number(dato.TRNCAJ_IMPORTE).toFixed(this.confIniciales.getNumDecSist());
        itemTrnCajas.TRNCAJ_HABER = Number(0).toFixed(this.confIniciales.getNumDecSist());
      }
      if (dato.TRNCAJ_IMPORTE < 0) {
        itemTrnCajas.TRNCAJ_HABER = Number(dato.TRNCAJ_IMPORTE * (-1)).toFixed(this.confIniciales.getNumDecSist());
        itemTrnCajas.TRNCAJ_DEBE = Number(0).toFixed(this.confIniciales.getNumDecSist());
      }
      itemTrnCajas.TRNCAJ_DOCNRO = dato.TRNCAJ_DOCNRO;
      itemTrnCajas.TRNCAJ_DESCRIPCION = dato.TRNCAJ_DESCRIPCION;
      itemTrnCajas.TRNCAJ_ORIGEN = dato.TRNCAJ_ORIGEN;
      itemTrnCajas.TRNCAJ_ANULADO = dato.TRNCAJ_ANULADO;
      itemTrnCajas.BOOL_ANULADO = dato.TRNCAJ_ANULADO === 1 ? true : false;
      itemTrnCajas.BAN_CODIGO = dato.BAN_CODIGO;
      itemTrnCajas.CEN_CODIGO = dato.CEN_CODIGO;
      itemTrnCajas.TRNCAJ_SALDO = Number(dato.TRNCAJ_SALDO).toFixed(this.confIniciales.getNumDecSist());
      itemTrnCajas.TRNCAJ_DEPOSITONRO = dato.TRNCAJ_DEPOSITONRO;
      if (Number(itemTrnCajas.TRNCAJ_SALDO) === 0) {
        itemTrnCajas.BOOL_DEPOSITADO = true;
      }
      if (Number(itemTrnCajas.TRNCAJ_SALDO) !== 0) {
        itemTrnCajas.BOOL_DEPOSITADO = false;
      }

      detalle.push(itemTrnCajas);
      if (cont >= 200) {
        break;
      }
    }
    this.bandetCajatrn = detalle;
  }

  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'bantrncajatrn',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

  async abrirBanTrnCajEdit(dato) {
    this.strCodCaja = dato.CAJ_CODIGO;
    this.strTrnCajTipo = dato.TRNCAJ_TIPO;
    this.strTrnCajNumero = dato.TRNCAJ_NUMERO;
    this.strTrnCajDocNro = dato.TRNCAJ_DOCNRO;
    this.strTrnCajOrigen = dato.TRNCAJ_ORIGEN;
    this.strBanCodigo = dato.BAN_CODIGO;
    this.numDep = dato.TRNCAJ_DEPOSITONRO;
    let rsSaldo;
    rsSaldo = this.bantrnCajaService.encontrarTrnCajSaldo( dato.CAJ_CODIGO, dato.TRNCAJ_TIPO, dato.TRNCAJ_NUMERO, dato.TRNCAJ_DOCNRO);
    rsSaldo = await rsSaldo;
    this.saldo = Number(rsSaldo[0].TRNCAJ_SALDO) !== 0 ? 0 : 1;
    this.bandera = true;
    this.bandera111 = false;
    this.ex = true;
    this.displayDialogBanTrnCajaEdit = true;

  }

  recibirSenalesBanCaja(valor) {
    if (valor === true) {
      this.consultarMovCaj(null);
      this.displayDialogBanTrnCajaEdit = false;
    }
  }

  seleccionItem(valor) {
    if (valor.object !== undefined) {
    }
  }



}
