import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from 'src/app/usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { DatePipe } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class InvconsultalotesService {
  public way: string;

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService,
    private init: NuevoComponentService,
    private datePipe: DatePipe) { 
      this.way = sessionStorage.getItem('way');
    }
  
    consultarArticulos(artCodigo) {

      return this.http.post<any[]>(this.way + '/ventas/consultalotes/662fcf57aadf640', {      
        elementos: {
          ART_CODIGO: artCodigo
        },
      }, this.confIniciales.httpOptions());
    }

    listarLotes(artCodigo) {
    return this.http.post<any[]>(this.way + '/ventas/consultalotes/2b2e7555c75dc', {      
        elementos: {
          ART_CODIGO: artCodigo
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }

    ConsultarLotes(strOpcion, numLote, strProCodigo, strCliCodigo) {
      return this.http.post<any[]>(this.way + '/ventas/consultalotes/662fcf57b5c75c0', {      
          elementos: {
            p_opcion_1: strOpcion,
            p_lote_numero: numLote,
            p_pro_codigo: strProCodigo,
            p_cli_codigo: strCliCodigo
          },
        }, this.confIniciales.httpOptions()).toPromise();
    }

    ConsultarDespacho(strOpcion, numLote, strCliCodigo, strFechaEmi, strVenCodigo, strCooler) {
      return this.http.post<any[]>(this.way + '/ventas/consultalotes/cc5f9eab5dcca800', {      
          elementos: {
            p_opcion_1: strOpcion,
            p_lote_numero: numLote,
            p_cli_codigo: strCliCodigo,
            p_fecha_emision: strFechaEmi,
            p_ven_codigo: strVenCodigo,
            p_cooler: strCooler
          },
        }, this.confIniciales.httpOptions()).toPromise();
    }
}
