import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from 'src/app/usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { DatePipe } from '@angular/common';
import { nomtrannovedad } from '../nominterfaces/nomtrannovedad';
import { isNullOrUndefined } from 'util';


@Injectable({
  providedIn: 'root'
})
export class NomtrannovedadService {

  public way: string;
  public nonnovedad:nomtrannovedad[]=[];
  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService,
    private pipe: DatePipe) { 
      this.way = sessionStorage.getItem('way');
      this.usuario.identificacion;
    }
    async llenardatosnovedad(){
      return  await this.http.post(this.way + '/nom/nomtrannovedad/bum1i54u1ws3aya', {
        elementos: {},
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async erIDEmpl(emp_codigo){
      return  await this.http.post(this.way + '/nom/cabimpurent/4n2hccp6o7v31y1', {
        elementos: {
          p_emp_codigo:emp_codigo
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async vernorepeatone(emp_codigo, fechaIn,TRANNOVEDAD_CODIGO){
      return  await this.http.post(this.way + '/nom/nomtrannovedad/o5yfbae8fxfq52x', {
        elementos: {
          fecIni:this.cambiarfechanull(fechaIn),
          EMP_CODIGO:parseInt(emp_codigo),
          dblTRANNOVEDAD_CODIGO:parseInt(TRANNOVEDAD_CODIGO)
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async vernorepeatwo(emp_codigo, fecFi,TRANNOVEDAD_CODIGO){
      return  await this.http.post(this.way + '/nom/nomtrannovedad/aksqsse90eyhmcv', {
        elementos: {
          fecFin:this.cambiarfechanull(fecFi),
          EMP_CODIGO:parseInt(emp_codigo),
          dblTRANNOVEDAD_CODIGO:parseInt(TRANNOVEDAD_CODIGO),
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async vernorepeatthree(emp_codigo, fecIn,TRANNOVEDAD_CODIGO){
      return  await this.http.post(this.way + '/nom/nomtrannovedad/nzh7w7y2zocx244', {
        elementos: {
          fecIni:this.cambiarfechanull(fecIn),
          EMP_CODIGO:parseInt(emp_codigo),
          dblTRANNOVEDAD_CODIGO:parseInt(TRANNOVEDAD_CODIGO)
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async vernorepeatfour(emp_codigo, fecFi,TRANNOVEDAD_CODIGO){
      return  await this.http.post(this.way + '/nom/nomtrannovedad/ovg64pd4vqq9tzr', {
        elementos: {
          fecFin:this.cambiarfechanull(fecFi),
          EMP_CODIGO:parseInt(emp_codigo),
          dblTRANNOVEDAD_CODIGO:parseInt(TRANNOVEDAD_CODIGO)
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async vermodfechinic(TRANNOVEDAD_CODIGO){
      return  await this.http.post(this.way + '/nom/nomtrannovedad/thdy225e956je1n', {
        elementos: {
          dblTRANNOVEDAD_CODIGO:TRANNOVEDAD_CODIGO
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    //
    async obtenerperiodo(){
      return  await this.http.post(this.way + '/nom/nomtrannovedad/41rgkq2v2rs41eg', {
        elementos: {
          p_com_codigo:'01'
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async eliminartrannovedad(trannovedad_codigo){
      return  await this.http.post(this.way + '/nom/nomtrannovedad/zaox2scg51thw2c', {
        elementos: {
          p_trannovedad_codigo:trannovedad_codigo
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async todastrannovedad(){
      return  await this.http.post(this.way + '/nom/nomtrannovedad/teuznvy5esow21v', {
        elementos: {},
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async guardartrannovedad(novedad:nomtrannovedad){
      return  await this.http.post(this.way + '/nom/nomtrannovedad/6n96l8qytytfbis', {
        elementos: {
          p_trannovedad_codigo: novedad.TRANNOVEDAD_CODIGO,
          p_com_codigo:         '01',
          p_maerubro_codigo:    novedad.MAERUBRO_CODIGO,
          p_maerubro_codrubr:   novedad.MAERUBRO_CODRUBR,
          p_trannovedad_fecini: this.cambiarfechanull(novedad.TRANNOVEDAD_FECINI),
          p_trannovedad_fecfin: this.cambiarfechanull(novedad.TRANNOVEDAD_FECFIN),
          p_emp_codigo:         this.cambiarfechanull(novedad.EMP_CODIGO),
          p_trannovedad_observacion: this.cambiarfechanull(novedad.TRANNOVEDAD_OBSERVACION),
          p_trannovedad_descripcion: this.cambiarfechanull(novedad.TRANNOVEDAD_DESCRIPCION)
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    async funCtrlNoveRepet(novedad:nomtrannovedad){
      let re:boolean=true;
      try{
        const cp1=await this.vernorepeatone(novedad.EMP_CODIGO,novedad.TRANNOVEDAD_FECINI,novedad.TRANNOVEDAD_CODIGO);
        console.log(cp1);
        //[0]["COUNT(TRANNOVEDAD_CODIGO)"]
        if(cp1[0]["COUNT(TRANNOVEDAD_CODIGO)"]>0){
          re=false;
          return re;
        };
        const cp2=await this.vernorepeatwo(novedad.EMP_CODIGO,novedad.TRANNOVEDAD_FECFIN,novedad.TRANNOVEDAD_CODIGO);
        if(cp2[0]["COUNT(TRANNOVEDAD_CODIGO)"]>0){
          re=false;
          return re;
        };
        console.log(cp2);
        const cp3=await this.vernorepeatthree(novedad.EMP_CODIGO,novedad.TRANNOVEDAD_FECINI,novedad.TRANNOVEDAD_CODIGO);
        if(cp3[0]["COUNT(TRANNOVEDAD_CODIGO)"]>0){
          re=false;
          return re;
        };
        console.log(cp3);
        const cp4=await this.vernorepeatfour(novedad.EMP_CODIGO,novedad.TRANNOVEDAD_FECFIN,novedad.TRANNOVEDAD_CODIGO);
        if(cp4[0]["COUNT(TRANNOVEDAD_CODIGO)"]>0){
          re=false;
          return re;
        };
        
        return re;
        
      }catch(e){

      }  
    }
    cambiarfechanull(valor){
      let res="";
      if(isNullOrUndefined(valor)){
        return res;
      }else{
        try{
          if(valor.toString().includes('-')){
            res=this.pipe.transform(valor,'dd/MM/yyyy');
            return res;
          }else{
            return valor;
          }
        }catch(err){         
          return valor;
        } 
      }
    }

    validarMayor(fechini, fechfin){
      console.log(fechini, fechfin);
      let af=String(this.cambiarfechanull(fechini)).split('/');//as
      let bf=String(this.cambiarfechanull(fechfin)).split('/');//bs
      let inicio=new Date(parseInt(af[2]),(parseInt(af[1])-1),parseInt(af[0]));
      let fin=new Date(parseInt(bf[2]),(parseInt(bf[1])-1),parseInt(bf[0]));
      console.log(inicio,fin);
      if(inicio>fin){
        return true;
      }else{
        return false;
      }
    }
    validarIgual(fechini, fechfin){
      console.log(fechini, fechfin);
      let af=String(this.cambiarfechanull(fechini)).split('/');
      let bf=String(this.cambiarfechanull(fechfin)).split('/');
      let inicio=new Date(parseInt(af[2]),(parseInt(af[1])-1),parseInt(af[0]));
      let fin=new Date(parseInt(bf[2]),(parseInt(bf[1])-1),parseInt(bf[0]));
      console.log(inicio,fin);
      if(inicio==fin){
        return true;
      }else{
        return false;
      }
    }
    validarMenor(fechini, fechfin){
      console.log(fechini, fechfin);
      let af=String(this.cambiarfechanull(fechini)).split('/');
      let bf=String(this.cambiarfechanull(fechfin)).split('/');
      let inicio=new Date(parseInt(af[2]),(parseInt(af[1])-1),parseInt(af[0]));
      let fin=new Date(parseInt(bf[2]),(parseInt(bf[1])-1),parseInt(bf[0]));
      console.log(inicio,fin);
      if(inicio<fin){
        return true;
      }else{
        return false;
      }
    }
}
