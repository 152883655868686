import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Invdettomafisicavar} from '../invinterfaces/invdettomafisicavar';
import {Invtrntomafisica, InvtrntomafisicaEnc} from '../invinterfaces/invtrntomafisica';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
@Injectable({
  providedIn: 'root'
})
export class InvtomafisicaService {
  public url: string;
  
  public detTomaFisicaArray: Invtrntomafisica[][] = [];
  public encTomaFisica: InvtrntomafisicaEnc[] = [];
  public detTomFisVarArray: Invdettomafisicavar[][] = [];
  public configDecimales: any[];
  public hmpConfiguracion: any[];
  
  public btnNuevoDet: boolean [] = [];
  public btnCancelarDet: boolean [] = [];
  public btnAgitems: boolean [] = [];//CDPJ
  public btnEliminarDet: boolean[] = [];
  public btnRecalcular: boolean[] = [];
  public btnCopiar: boolean[] = [];
  public btnNuevoDetVar: boolean [] = [];
  public btnCancelarDetVar: boolean [] = [];
  public btnEliminarDetVar: boolean[] = [];
  //CDPJ
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonRegresar: boolean;
  botonBorrar: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;
  btnVisto: boolean;
  btnReversar: boolean;
  btnBuscar: boolean;
  btnGenAsiento: boolean;
  btnVerAsiento: boolean;
  //CDPJ
  
  public decimalesCantTom: number;
  public decimalesPrecioTom: number;
  public decimalesTotTom: number;
  public way: string;
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, private confIniciales: ConfInicialesService, private init: NuevoComponentService) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }
  
  generarKardexProm(bodCodigo, artCodigo, tipo, fecha, numero, cantidad, costoTotal, hora, origen, costoProm ) {
    return this.http.post<any[]>(this.way + '/inventario/reservas/83aeedab7456d80', {      
      elementos: {
        p_BOD_codigo_1: bodCodigo,
        p_ART_codigo_2: artCodigo,
        p_trnart_tipo_3: tipo,
        p_TRNTOMAFISICA_fecha_4: fecha,
        p_TRNTOMAFISICA_nro_5: numero,
        p_TRNART_CANTIDAD_6: cantidad,
        p_TRNART_COSTOT_7: costoTotal,
        p_TRNART_HORA_8: hora,
        p_TRNART_ORIGEN_9: origen,
        p_TRNART_COSTOPROM_10: costoProm,
        p_COM_codigo_11: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eStrntomafisica( strNumDoc ){
    return this.http.post<any[]>(this.way + '/inventario/reservas/3b9dddfb8b29f200000', {      
      elementos: {
        NumDoc: strNumDoc,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eSCodigoKardex( artcodigo, tomafisica ){
    return this.http.post<any[]>(this.way + '/inventario/reservas/1dc661b28628ae0000', {      
      elementos: {
        ART_CODIGO: artcodigo,
        TRNTOMAFISICA_NRO: tomafisica
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eSdettomafvartomafisica( strNumTF  ){
    return this.http.post<any[]>(this.way + '/inventario/reservas/3b8d777b8b2a00000', {      
      elementos: {
        NumTF : strNumTF ,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eStomafisicaselecregistro(strOpcion, srtTRNTOMAFISICA_NRO  ){
    return this.http.post<any[]>(this.way + '/inventario/reservas/ee771653e5c930000', {      
      elementos: {
        opcion:strOpcion,
        TRNTOMAFISICA_NRO: srtTRNTOMAFISICA_NRO ,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eSeliminarkardex(srtTRNTOMAFISICA_NRO){
    return this.http.post<any[]>(this.way + '/inventario/reservas/mg257tk6ggi5hk1', {      
      elementos: {
        TRNTOMAFISICA_NRO: srtTRNTOMAFISICA_NRO ,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eSobtenercostopromedio(strArtCodigo, strFecha, strHora){
    return this.http.post<any[]>(this.way + '/inventario/reservas/ef6j5kckezaqjwg', {      
      elementos: {
        artCodigo: strArtCodigo ,
        fecha:strFecha,
        hora:strHora
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eScontrolkardex(strArtCodigo,strNumTF){
    return this.http.post<any[]>(this.way + '/inventario/reservas/ga17472rt8joays', {      
      elementos: {
        artCodigo: strArtCodigo ,
        numTF:strNumTF,
       
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eScantidadkardex(strArtCodigo,strNumTF){
    return this.http.post<any[]>(this.way + '/inventario/reservas/yskagl7n2wjvcvm', {      
      elementos: {
        artCodigo: strArtCodigo ,
        numTF:strNumTF,
       
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eSobtenerexistencia(strTipo, strArtCodigo, strBodCodigo, strFecha, strHora){
    return this.http.post<any[]>(this.way + '/inventario/reservas/ckc01z5caj3z8hg', {      
      elementos: {
        tipo: strTipo,
        artCodigo:strArtCodigo,
        bodcodigo:strBodCodigo,
        fecha:strFecha,
        hora:strHora,
       
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eStomafisicakardex(strArtCodigo,strNumTF){
    return this.http.post<any[]>(this.way + '/inventario/reservas/jb92g2hb6o00nhq', {      
      elementos: {
        artCodigo: strArtCodigo ,
        numTF:strNumTF,
       
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eSreperarnumasiento(strTRNTOMAFISICA_NRO){
    return this.http.post<any[]>(this.way + '/inventario/reservas/0a84k09grmp6zfa', {      
      elementos: {
        TRNTOMAFISICA_NRO: strTRNTOMAFISICA_NRO ,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  esverificarTomaFKardex(strNumTF){
    return this.http.post<any[]>(this.way + '/inventario/reservas/bbh997068pepbi6', {      
      elementos: {
        numTF:strNumTF ,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
 
  generarAsientoProm(strNumero) {
   
    return this.http.post<any[]>(this.way + '/inventario/reservas/ynoeyxg8spotpam', {      
      elementos: {
        p_trntomafisica_nro: strNumero,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'ATF',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  getDecimales(valor: string) {
  
    return this.http.post<any[]>(this.way + '/inventario/reservas/9xn5yuzxr8rh5xk', {      
      elementos: {
        NUMDEC_FORMA: valor,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  consultarTomaFisica(strOpcion, strFiltro, strCondicion) {
  
    return this.http.post<any[]>(this.way + '/inventario/reservas/wo60cxfgpwpq61u', {      
      elementos: {
        p_opcion: strOpcion,
        p_filtro: strFiltro,
        p_condicion: strCondicion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  frubicarCantidadPorUnidades(strART_CODIGO){
    return this.http.post<any[]>(this.way + '/inventario/reservas/7885j54j3h0toqg', {      
      elementos: {
        ART_CODIGO:strART_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
 
  
  obtenerConfig(campo, tabla): number {
    let numDecimales = 0;
    const decimales = this.configDecimales.find(element => element.NUMDEC_CAMPO === campo && element.NUMDEC_TIPDOC === tabla);
    if (decimales !== undefined) {
      numDecimales = decimales.NUMDEC_NUMDEC;
    } else {
      numDecimales = 2;
    }
    
    return numDecimales;
  }
  
  getConfiguraciones(codigo): number {
    const valorConfig = this.hmpConfiguracion.find(element => element.CFG_CODIGO === codigo).CFG_VALOR1;
    return valorConfig;
  }
  
  eliminarTomaFisica(tomFisNum, artCodigo, linea) {
    return this.http.post<any[]>(this.way + '/inventario/reservas/yts3lcchts1ac5h', {      
      elementos: {
        p_trntomafisica_nro: tomFisNum,
        p_art_codigo: artCodigo,
        p_trntomafisica_linea: linea,
        p_com_codigo: '01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  anularAsientoContable(asiNro) {
 
    return this.http.post<any[]>(this.way + '/inventario/reservas/wzf5un03c5hjdde', {      
      elementos: {
        p_ASI_nro: asiNro,
        p_COM_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  recalcularCostoPromedio(artCodigo, fecha) {
    
    return this.http.post<any[]>(this.way + '/inventario/reservas/t3zp4k69r0ds4j2', {      
      elementos: {
        p_art_codigo: artCodigo,
        p_com_codigo: '01',
        p_fecha: fecha
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  recalcularCostosArtEliminados(strDocumento, strFecha) {

    return this.http.post<any[]>(this.way + '/inventario/reservas/8yr768j0a02rmmh', {      
      elementos: {
        p_documento: strDocumento,
        p_com_codigo: '01',
        p_fecha: strFecha
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  cargarTotalTomaFisica() {
    let dblTotalTomaF = 0;
    let value = null;
    let dblTotalAjuste = 0;
    
    
    for (let index = 0; index < this.detTomaFisicaArray[this.init.tabs[this.init.tabindex].indice].length; index++) {
      let item: Invtrntomafisica = {};
      item =  this.detTomaFisicaArray[this.init.tabs[this.init.tabindex].indice][index];
      dblTotalTomaF = dblTotalTomaF + Number(item.TRNTOMAFISICA_TOTAL);
    }
    
    value = dblTotalTomaF;
    value = Number(value).toFixed(this.obtenerConfig('COSTO', 'trntomaf'));
    
    this.encTomaFisica[this.init.tabs[this.init.tabindex].indice].VALOR_TOTAL = value;
    
    dblTotalAjuste = this.obtenerTotalAjuste();
    value = dblTotalAjuste;
    value = Number(value).toFixed(this.obtenerConfig('COSTO', 'trntomaf'));
    
    this.encTomaFisica[this.init.tabs[this.init.tabindex].indice].VALOR_TOTALAJU = value;
  }
  
  obtenerTotalAjuste() {
    let dblTotalAjuste = 0;
    for (const item of this.detTomaFisicaArray[this.init.tabs[this.init.tabindex].indice]) {
      if (item.TRNTOMAFISICA_AJUSTE === true || item.TRNTOMAFISICA_AJUSTE === 'S') {
        dblTotalAjuste = dblTotalAjuste + Number(item.TRNTOMAFISICA_TOTAL);
      }
    }
    dblTotalAjuste = Number(Number(dblTotalAjuste).toFixed(2));
    
    return dblTotalAjuste;
  }
  
  insertarTomaFisica(artCodigo, artNombre, cantidadP, cantidadK, cantidadF, cantidad, bodCodigo, fecha, comCodigo, tomFisNro, linea,
                     ajuste, hora, costoProm, uniCosteo, comentario, cantidadUni) {
 
    return this.http.post<any[]>(this.way + '/inventario/reservas/jjnplm44ub18e38', {      
      elementos: {
        p_art_codigo: artCodigo,
        p_art_nombre: artNombre,
        p_trntomafisica_cantidadp: cantidadP,
        p_trntomafisica_cantidadk: cantidadK,
        p_trntomafisica_cantidadf: cantidadF,
        p_trntomafisica_cantidad: cantidad,
        p_bod_codigo: bodCodigo,
        p_trntomafisica_fecha: fecha,
        p_com_codigo: comCodigo,
        p_trntomafisica_nro: tomFisNro,
        p_trntomafisica_linea: linea,
        p_trntomafisica_ajuste: ajuste,
        p_trntomafisica_hora: hora,
        p_trntomafisica_costoprom: costoProm,
        p_art_unidadcosteo: uniCosteo,
        p_trntomafisica_comentario: comentario,
        p_trntomafisica_cantidadund: cantidadUni,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarTomaFKardex(strDocumento) {
    return this.http.post<any[]>(this.way + '/inventario/reservas/htmb1ncdkl3o73u', {      
      elementos: {
        p_TRNTOMAFISICA_NRO: strDocumento,
        p_COM_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerClaveProm() {
    
    return this.http.post<any[]>(this.way + '/inventario/reservas/gw64ctbdnqafkb3', {      
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  copiarCostos(strNumDoc) {
    
    return this.http.post<any[]>(this.way + '/inventario/reservas/mty4mvjqg1md40i', {      
      elementos: {
        p_trntomafisica_nro: strNumDoc,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  insertarVarios( tomFisNro, conCodigo, conNombre, cenCodigo, linea, importe) {
 
    return this.http.post<any[]>(this.way + '/inventario/reservas/q19khv8br9i7y2k', {      
      elementos: {
        p_trntomafisica_nro: tomFisNro,
        p_CON_CODIGO: conCodigo,
        p_CON_NOMBRE: conNombre,
        p_CEN_CODIGO: cenCodigo,
        p_COM_CODIGO: '01',
        p_DETTOMAFVAR_LINEA: linea,
        p_DETTOMAFVAR_IMPORTE: importe
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
  consultaitemstock(bod_codigo,strFecha,strHora,where):Observable<any> {
    return this.http.post<any>(this.way + '/inventario/reservas/2a1b7415b975d60000000000', {
      elementos: {
        p_bod_codigo:bod_codigo,
        p_strFecha:strFecha,
        p_strHora:strHora,
        p_where:where
      }
    }, this.confIniciales.httpOptions());
  }
  consultaitemstodos(bod_codigo,strFecha,strHora,where):Observable<any> {
    return this.http.post<any>(this.way + '/inventario/reservas/a86dd056e5d7580000000', {
      elementos: {
        p_bod_codigo:bod_codigo,
        p_strFecha:strFecha,
        p_strHora:strHora,
        p_where:where
      }
    }, this.confIniciales.httpOptions());
  }
  encontrarArtCntaySer(strArtCodigo, strtipo) {
    return this.http.post<any[]>(this.way + '/compras/notasrecepcion/75d98bf76adab8000000', {
      elementos: {
        ArtCodigo: strArtCodigo,
        strTIPO: strtipo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  habilitarBotones(nuevo, guardar, eliminar, cancelar, inicio, anterior, siguiente, ultimo, ajustar, elimTFKard, genAsiento, verAsiento,
    busqueda, imprimir) {
    this.botonNuevo = nuevo;
    this.botonGuardar = guardar;
    this.botonBorrar = eliminar;
    this.botonPrimero = inicio;
    this.botonAnterior = anterior;
    this.botonSiguiente = siguiente;
    this.botonUltimo = ultimo;
    this.btnVisto = ajustar;
    this.btnReversar = elimTFKard;
    this.btnBuscar = busqueda;
    this.btnGenAsiento = genAsiento;
    this.btnVerAsiento = verAsiento;
  }
  consultaitemskardex(strkarnro) {
    return this.http.post<any[]>(this.way + '/inventario/reservas/17ee6bce6aa5d7000000000', {
      elementos: {
        karnro: strkarnro,       
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actcantitemskardex(strkarnro) {
    return this.http.post<any[]>(this.way + '/inventario/reservas/299df2ab2', {
      elementos: {
        p_trntomafisica_nro: strkarnro,   
        p_com_codigo:'01'    
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
  
}
