import {Component, OnInit, ViewChild} from '@angular/core';
import {SegmaeautorizacionService} from '../segservicios/segmaeautorizacion.service';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {Segmaeautorizacion} from '../seginterfaces/segmaeautorizacion';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {DatePipe} from '@angular/common';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
@Component({
  selector: 'app-segmaeautorizacion',
  templateUrl: './segmaeautorizacion.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class SegmaeautorizacionComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  btnGuardar: boolean;
  btnNuevo = true;
  btnBorrar: boolean;
  btnRegresar: boolean;
  btnBuscar: boolean;
  
  defaultColDefMaeAutorizaciones;
  public frameworkComponents;
  rowStyle;
  largo = '258';
  rowSelection = 'multiple';
  segmaeAutorizaciones: Segmaeautorizacion[] = [];
  selectedMaeAutorizacion: Segmaeautorizacion;
  
  opcion: string;
  types: SelectItem[];
  
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  condicion: string;
  displayDialogBusquedaFast: boolean;
  
  
  
  columnDefMaeAutorizacion = [
    {
      headerName: 'Código', field: 'Código', cellEditor: 'cellPrueba', width: 100, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Clave', field: 'Clave', cellEditor: 'cellPrueba', width: 100, cellStyle: {textTransform: '*'},
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Descripción', field: 'Descripcion', cellEditor: 'cellPrueba', width: 500, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: false,
          }
        }
    },
  ];
  
  constructor(
    private segMaeAutorizacionService: SegmaeautorizacionService,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private init: NuevoComponentService,
    private errorService: ErroresBaseDatosService,
    private confirmationService: ConfirmationService,
    private auditoriagral: AuditoriagralService,
  ) {
    this.agTable();
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefMaeAutorizaciones = {
      editable: true,
      width: 130,
      objeto: 'maePeriodo'
    };
  }
  
  ngOnInit(): void {
    
    this.btnNuevo =  true;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.btnRegresar = false;
    this.btnBuscar = false;
    this.consultarAutorizacion();
  }
  
  consultarAutorizacion() {
    this.segMaeAutorizacionService.consultaAutorizacion(null).then( res => {
      this.segmaeAutorizaciones = res;
      // convertir claves en asteriscos
      this.segmaeAutorizaciones.map(  x => {
        const cadena = x.Clave.length;
        let nuevaClave = '';
        for (  let i = 0; i < cadena; i++ ) {
          nuevaClave += '*';
        }
        x.Clave = nuevaClave;
      } );
      
    })
      .catch(e => {
        console.error(e);
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
        
      });
    
  }
  
  selectMaePeriodo(elemento) {
    if (elemento.object !== null) {
      this.selectedMaeAutorizacion = elemento.object;
    }
  }
  
  cambio(event) {
    this.btnGuardar = false;
    this.btnRegresar = false;
    this.btnBuscar = true;
    if (this.selectedMaeAutorizacion.nuevo === undefined) {
      this.selectedMaeAutorizacion.editado = true;
    }
  }
  
  manejarSenales(valor) {
    
    switch (valor) {
      case 'Nuevo':
        // this.opcionNuevo();
        break;
      case 'Guardar':
        if (  this.selectedMaeAutorizacion !== undefined && this.selectedMaeAutorizacion.Clave !== null
          && this.selectedMaeAutorizacion.Clave.length > 0 ) {
          this.guardar();
        }
        break;
      case 'Buscar':
        this.buscarRegistro();
        break;
      case 'Borrar':
        // this.opcionBorrar();
        break;
      case 'Cancelar':
        this.cargar();
        break;
      case 'Salir':
        this.init.tabs.splice(  this.init.tabindex, 1);
        break;
      
    }
  }
  
  
  buscarRegistro() {
    this.opcion = 'BUSCAR';
    this.types = [
      {label: 'CODIGO', value: 'AUTORIZACION_CODIGO'},
      {label: 'CLAVE', value: 'AUTORIZACION_CLAVE'},
      {label: 'DESCRIPCION', value: 'AUTORIZACION_DESCRIPCION'},
    ];
    
    this.busquedacampos = ['', '', ''];
    this.consulta = '*';
    this.tabla = 'SEG_MAEAUTORIZACION';
    this.condicion = '';
    this.arregloCons = [];
    this.displayDialogBusquedaFast = true;
  }
  
  manejarSeleccion(valor) {
    
    if (this.opcion === 'BUSCAR') {
      this.displayDialogBusquedaFast = false;
      this.llenarTabla(valor);
    }
  }
  
  llenarTabla(datos) {
    let cont = 0;
    const detalle: Segmaeautorizacion[] = [];
    for (const dato of datos) {
      cont++;
      const itemSegAutor: Segmaeautorizacion = {};
      itemSegAutor.Código = dato.AUTORIZACION_CODIGO;
      
      itemSegAutor.Clave = dato.AUTORIZACION_CLAVE === null ? '' : dato.AUTORIZACION_CLAVE;
      
      itemSegAutor.Descripcion = dato.AUTORIZACION_DESCRIPCION === null ? '' : dato.AUTORIZACION_DESCRIPCION;
      
      detalle.push(itemSegAutor);
      if (cont >= 200) {
        break;
      }
      
      this.segmaeAutorizaciones = detalle;
      // convertir claves en asteriscos
      this.segmaeAutorizaciones.map(  x => {
        const cadena = x.Clave.length;
        let nuevaClave = '';
        for (  let i = 0; i < cadena; i++ ) {
          nuevaClave += '*';
        }
        x.Clave = nuevaClave;
      } );
    }
    
  }
  
  guardar() {
    this.aggrid.gridApi.stopEditing();
    this.segMaeAutorizacionService.actualizar(this.selectedMaeAutorizacion).then(  () => {
      this.mensajeAlerta('success', 'Información', '¡Clave actualizada exitosamente!');
      // this.cargar();
    }).then( async () => {
      await this.auditoriagral.registrarAuditoria('SEG_MAEAUTORIZACION',
        this.selectedMaeAutorizacion.Código, 'A',
        '', '01', '', '', '', '').toPromise().then(() => {
        this.cargar();
        return;
      });
    } )
      .catch(  e => {
        console.error(e);
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      });
  }
  
  
  cargar() {
    this.btnNuevo =  true;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.btnRegresar = false;
    this.btnBuscar = false;
    this.displayDialogBusquedaFast = false;
    this.consultarAutorizacion();
  }
  
  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'maeautortoast',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
    
  }
  
}
