
import { Component, OnInit, ViewChild } from '@angular/core';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { SegMaePermiso } from 'src/app/advantage/advantageinterfaces/segmaepermiso';
import { MessageService, ConfirmationService } from 'primeng';
import { PermisosService } from 'src/app/advantage/advantageservices/permisos.service';
import { TecMaeequipo } from '../tecinterfaces/tecmaeequipo';
import { TecEquipodet } from '../tecinterfaces/tecequipodet';
import { DatePipe } from '@angular/common';
import { TecmaeequipoService } from '../tecservicios/tecmaeequipo.service';

@Component({
  selector: 'app-tecmaeequipo',
  templateUrl: './tecmaeequipo.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})

export class TecmaeequipoComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  indicador: any;
  index: number;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;

  tecmaeequipo: TecMaeequipo[];
  equipoSeleccionado: TecMaeequipo = {};
  equipodetSeleccionado: TecEquipodet = {};

  permisoEquipo: SegMaePermiso;
  messagekey = 'maeequipo';
  confirmkey = 'confirmmaeequipo';

  largo: string;
  public frameworkComponents;
  rowStyle;
  defaultColDef;
  defaultColDefDet;
  rowSelection = 'multiple';

  columnDefs = [
    {
      headerName: 'Código', field: 'EQUI_CODIGO', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
        }
      }
    },
    {
      headerName: 'Nombre', field: 'EQUI_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 30
        }
      }
    }
  ]

  columnDefsDet = [
    {
      headerName: 'Código', field: 'ETI_CODIGO', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Nombre', field: 'ETI_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 100
        }
      }
    }
  ]

  constructor(public equipoService: TecmaeequipoService, public init: NuevoComponentService,
    private messageService: MessageService, private permisosService: PermisosService,
    private confirmationService: ConfirmationService, private datePipe: DatePipe) {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultColDef = {
      editable: true,
      width: 100,
      objeto: 'maeequipo'
    };

    this.defaultColDefDet = {
      editable: true,
      width: 100,
      objeto: 'maeequipodet'
    };
  }

  async ngOnInit() {
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.largo = '400';

    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;

    this.tecmaeequipo = [];
    this.equipoService.tecmaeequipodet[this.indicador] = [];

    await this.getPermisos();
    await this.cancelar();
  }

  async getPermisos() {
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisoEquipo = data[0];
        } else {
          this.permisoEquipo = null;
        }
      } else {
        this.permisoEquipo = null;
      }
    } catch (e) {
      this.permisoEquipo = null;
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Error',
        detail: 'No se pudo obtener los permisos del formulario'
      });
    }
  }

  botonesModificar() {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = false;
    this.btnRegresar = false;
  }

  seleccion(valor) {
    if (valor.object !== null) {
      this.equipoSeleccionado = valor.object;
      this.cancelardet();
    }
  }

  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      this.nuevo();
    }
    if (valor === 'Guardar') {
      this.guardar();
    }
    if (valor === 'Borrar') {
      this.eliminar();
    }
    if (valor === 'Cancelar') {
      this.cancelar();
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }

  nuevo() {
    if (this.permisoEquipo.PERINSERCION === 1) {
      this.botonesModificar();
      const equiponuevo = {
        EQUI_CODIGO: '',
        EQUI_NOMBRE: '',
        CLAVE: ''
      };
      const indi = this.tecmaeequipo.indexOf(this.equipoSeleccionado);
      if (indi === -1) {
        equiponuevo.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.tecmaeequipo.push(equiponuevo);
        this.equipoSeleccionado = equiponuevo;
        this.aggrid.refreshaggrid(this.tecmaeequipo, this.defaultColDef.objeto);
      } else {
        equiponuevo.CLAVE = (indi + 1) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.tecmaeequipo.splice(indi + 1, 0, equiponuevo);
        this.equipoSeleccionado = equiponuevo;
        this.aggrid.refreshaggridindex(this.tecmaeequipo, this.defaultColDef.objeto, indi);
      }
    } else if (this.permisoEquipo.PERINSERCION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de inserción, acción denegada'
      });
    }
  }

  async guardar() {
    const data: any = await this.equipoService.encontrarequipo(this.equipoSeleccionado.EQUI_CODIGO);
    if (data.length === 0) {
      this.insertar();
    } else {
      this.actualizar();
    }
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
  }

  async insertar() {
    if (this.permisoEquipo.PERINSERCION === 1) {
      await this.equipoService.insertarequipo(this.equipoSeleccionado);
      this.messageService.add({
        key: this.messagekey,
        severity: 'success',
        summary: 'Equipo',
        detail: 'Equipo guardado correctamente'
      });
    } else if (this.permisoEquipo.PERINSERCION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de inserción, acción denegada'
      });
    }
  }

  async actualizar() {
    if (this.permisoEquipo.PERACTUALIZACION === 1) {
      await this.equipoService.actualizarequipo(this.equipoSeleccionado);
      this.messageService.add({
        key: this.messagekey,
        severity: 'success',
        summary: 'Equipo',
        detail: 'Equipo actualizado correctamente'
      });
    } else if (this.permisoEquipo.PERACTUALIZACION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de inserción, acción denegada'
      });
    }
  }

  async verificareliminar() {
    const data = await this.equipoService.encontrarEquipoCliente(this.equipoSeleccionado);
    if (data !== null) {
      if (data[0] !== undefined) {
        this.messageService.add({
          key: this.messagekey,
          severity: 'error',
          summary: 'Equipo',
          detail: 'No se pudo eliminar. Equipo se encuentra agregado a un cliente.'
        });
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  eliminar() {
    if (this.permisoEquipo.PERELIMACION === 1) {
      if (this.equipoService.tecmaeequipodet[this.indicador].length === 0) {
        this.confirmationService.confirm({
          message: 'Esta seguro que desea eliminar la equipo',
          header: 'Eliminar equipo',
          icon: 'pi pi-exclamation-triangle',
          key: this.confirmkey,
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: async () => {
            this.confirmationService.close();
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
            if (await this.verificareliminar() === true) {
              this.equipoService.eliminarequipo(this.equipoSeleccionado);
              this.tecmaeequipo.splice(this.tecmaeequipo.findIndex(
                eti => eti.CLAVE === this.equipoSeleccionado.CLAVE), 1);
              this.aggrid.refreshaggrid(this.tecmaeequipo, this.defaultColDef.objeto);
              this.messageService.add({
                key: this.messagekey,
                severity: 'success',
                summary: 'Equipo',
                detail: 'Equipo eliminado correctamente'
              });
            }
          },
          reject: () => {
            this.confirmationService.close();
          }
        });
      } else {
        this.messageService.add({
          key: this.messagekey,
          severity: 'error',
          summary: 'Equipo',
          detail: 'No se puede eliminar el equipo. Tiene ingresado detalle'
        });
      }
    } else if (this.permisoEquipo.PERELIMACION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de inserción, acción denegada'
      });
    }
  }

  async cancelar() {
    if (this.permisoEquipo.PERSELECCION === 1) {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      const data: any = await this.equipoService.getequipos();
      for (const rs of data) {
        rs.CLAVE = rs.EQUI_CODIGO;
      }
      this.index = 0;
      this.tecmaeequipo = data;
    } else if (this.permisoEquipo.PERSELECCION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de selección, acción denegada'
      });
    }
  }

  async seleccionDet(valor) {
    if (valor === 'nuevo') {
      this.nuevodet();
    }
    if (valor === 'guardar') {
      this.guardardet();
    }
    if (valor === 'eliminar') {
      this.eliminardet();
    }
    if (valor === 'cancelar') {
      this.cancelardet();
    }
    if (valor.object !== null && valor.object !== undefined) {
      this.equipodetSeleccionado = valor.object;
    }
  }

  nuevodet() {
    if (this.equipoSeleccionado.EQUI_CODIGO === undefined) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'warn',
        summary: 'Detalle del equipo',
        detail: 'Seleccione un equipo'
      });
      return;
    }
    if (this.permisoEquipo.PERINSERCION === 1) {
      const nuevoequipodet = {
        EQUI_CODIGO: this.equipoSeleccionado.EQUI_CODIGO,
        ETI_CODIGO: '',
        ETI_NOMBRE: '',
        CLAVE: ''
      };
      const indi = this.equipoService.tecmaeequipodet[this.indicador].indexOf(this.equipodetSeleccionado);
      if (indi === -1) {
        nuevoequipodet.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.equipoService.tecmaeequipodet[this.indicador].push(nuevoequipodet);
        this.equipodetSeleccionado = nuevoequipodet;
        this.aggrid.refreshaggrid(this.equipoService.tecmaeequipodet[this.indicador], this.defaultColDefDet.objeto);
        if (this.equipoService.tecmaeequipodet[this.indicador].length === 1) {
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
            this.defaultColDefDet.objeto].setFocusedCell(0, 'ETI_CODIGO');
        } else {
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
            this.defaultColDefDet.objeto].setFocusedCell((this.equipoService.tecmaeequipodet[this.indicador].length) - 1, 'ETI_CODIGO');
        }
      } else {
        nuevoequipodet.CLAVE = (indi + 1) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.equipoService.tecmaeequipodet[this.indicador].splice(indi + 1, 0, nuevoequipodet);
        this.equipodetSeleccionado = nuevoequipodet;
        this.aggrid.refreshaggridindex(this.equipoService.tecmaeequipodet[this.indicador], this.defaultColDefDet.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefDet.objeto].setFocusedCell(indi + 1, 'ETI_CODIGO');

      }
    } else if (this.permisoEquipo.PERINSERCION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de inserción, acción denegada'
      });
    }
  }

  async guardardet() {
    let i = 0;
    for (const detequi of this.equipoService.tecmaeequipodet[this.indicador]) {
      const data: any = await this.equipoService.encontrarequipodet(detequi.EQUI_CODIGO, detequi.ETI_CODIGO);
      i++;
      if (data.length === 0) {
        this.insertardet(detequi, i);
      } else {
        this.actualizardet(detequi, i);
      }
    }
  }

  async insertardet(detequi, i) {
    if (this.permisoEquipo.PERINSERCION === 1) {
      await this.equipoService.insertarequipodet(detequi);
      if (i === this.equipoService.tecmaeequipodet[this.indicador].length) {
        this.messageService.add({
          key: this.messagekey,
          severity: 'success',
          summary: 'Detalle equipo',
          detail: 'Detalle del equipo guardado correctamente'
        });
      }
    } else if (this.permisoEquipo.PERINSERCION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de inserción, acción denegada'
      });
    }
  }

  async actualizardet(detequi, i) {
    if (this.permisoEquipo.PERACTUALIZACION === 1) {
      await this.equipoService.actualizarequipodet(detequi);
      if (i === this.equipoService.tecmaeequipodet[this.indicador].length) {
        this.messageService.add({
          key: this.messagekey,
          severity: 'success',
          summary: 'Detalle equipo',
          detail: 'Detalle del equipo actualizado correctamente'
        });
      }
    } else if (this.permisoEquipo.PERACTUALIZACION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de inserción, acción denegada'
      });
    }
  }

  eliminardet() {
    if (this.permisoEquipo.PERELIMACION === 1) {
      this.confirmationService.confirm({
        message: 'Esta seguro que desea eliminar la equipo',
        header: 'Eliminar equipo',
        icon: 'pi pi-exclamation-triangle',
        key: this.confirmkey,
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          this.equipoService.eliminarequipodet(this.equipodetSeleccionado);
          this.equipoService.tecmaeequipodet[this.indicador].splice(this.equipoService.tecmaeequipodet[this.indicador].findIndex(
            eti => eti.CLAVE === this.equipodetSeleccionado.CLAVE), 1);
          this.aggrid.refreshaggrid(this.equipoService.tecmaeequipodet[this.indicador], this.defaultColDefDet.objeto);
          this.messageService.add({
            key: this.messagekey,
            severity: 'success',
            summary: 'Detalle Equipo',
            detail: 'Detalle del equipo eliminado correctamente'
          });
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    } else if (this.permisoEquipo.PERELIMACION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de inserción, acción denegada'
      });
    }
  }

  async cancelardet() {
    this.equipoService.tecmaeequipodet[this.indicador] = [];
    const data: any = await this.equipoService.getequiposdet(this.equipoSeleccionado.EQUI_CODIGO);
    if (data.length > 0) {
      for (const rs of data) {
        rs.CLAVE = rs.ETI_CODIGO;
      }
      this.equipoService.tecmaeequipodet[this.indicador] = data;
    }
  }

  async changeTabs() {
    if (this.index > 0) {
      this.btnNuevo = true;
      this.btnGuardar = true;
      this.btnBorrar = true;
      this.btnRegresar = true;
    } else {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
    }
  }

}
