import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BanDetVarCaja, BanTrnCajaTrn} from '../baninterfaces/bantrncajatrn';
import {BantrncajaService} from '../banservicios/bantrncaja.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {DatePipe} from '@angular/common';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {Usuario} from '../../usuario';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {BanDetModeloVarios} from '../baninterfaces/bantrntarjeta';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';

@Component({
  selector: 'app-banmodificarmovcaja',
  templateUrl: './banmodificarmovcaja.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class BanmodificarmovcajaComponent implements OnInit {
  
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;
  btnBuscar: boolean;
  
  selectTab = 0;
  
  encCaja: BanTrnCajaTrn;
  
  // Variables
  strTipoCmb;
  lblTrnDepositoNro;
  txtComprobImporte;
  lblBanNombre;
  lblTrnCajOrigen;
  lblCc;
  lblTrnCajDocNro;
  dateTimeFechaVencimiento;
  dateTimeFecha;
  txtCodReferencia;
  txtTrnCajNumero;
  cmbTipo;
  txtCenCodigo;
  txtTrnCajDescripcion;
  txtTrnCajImporte;
  txtCajCodigo;
  intItemSelec = 0;
  guardaDatos = false;
  bolRespuesta = false;
  bolAnulaAsto = false;
  senalblock = false;
  @Input() bandera = true;
  @Input() ex = false;
  numSec = '';
  numSecAc = '';
  
  @Input() strCodCaja;
  @Input() strTrnCajTipo;
  @Input() strTrnCajNumero;
  @Input() strTrnCajDocNro;
  strSubcadTipo;
  strTrnCajNro;
  @Input() strTrnCajOrigen;
  strDato;
  strTipo = null;
  strTrnCajFormaPago = null;
  strCliCodigo = null;
  strCenCostos = null;
  fecha = null;
  fechaVen = null;
  strBanCod = null;
  strDescripcion = null;
  @Input() strBanCodigo = null;
  @Input() numDep = null;
  @Input() saldo;
  lblDato;
  lblDescripcionRef;
  lblAsiNro;
  strNomCaja;
  strNomCC;
  intAnulado;
  fltImporte = 0;
  item: BanTrnCajaTrn;
  fltImporteVar = 0;
  cont = 1;
  cajaNroLabel8;
  @Input() bandera111 = false;
  numPestaña = 0;
  estado = false;
  btnChkTrnCajAnulado;
  groupDatosOrigen = false;
  
  boolVisibleFechaVen = true;
  boolIndex1 = true;
  
  largo: string;
  public frameworkComponents;
  rowStyle;
  defaultBanCajVarios;
  rowSelection = 'multiple';
  
  btnNuevoGrid: boolean;
  btnGuardarGrid: boolean;
  btnBorrarGrid: boolean;
  btnCancelarGrid: boolean;
  
  fltDebe = 0;
  fltHaber = 0;
  fltSaldo = 0;
  fltAbsHaber = 0;
  fltSumaHaber = 0;
  fltSumaDebe = 0;
  fltsaldoActual = 0;
  
  disabledBanCod = true;
  
  displayDialogBus: boolean;
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  where: string;
  opcion: string;
  
  permisos: SegMaePermiso;
  
  strAsiNro = null;
  ErrorF: boolean;
  
  disabledCajCodigo = false;
  disabledTxtNumero = false;
  hiddenBtnCajCod = false;
  disabledCmbTipo = false;
  
  public hmpConfiguracion: any[];
  
  claveDialog: boolean;
  claveAuth: string;
  intNumReg = 0;
  accionClave: string;
  
  displayAsiContable: boolean;
  NumAsiRegCont: string;
  
  detalleVariosSel: BanDetVarCaja;
  indicador: any;
  
  @Output() signalSalir = new EventEmitter<boolean>();
  
  columnDefsBanCajVarios = [
    {
      headerName: 'Referencia Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 160, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 30,
            opcionbuscar: true
          }
        },onCellValueChanged: (params) => {
          this.btnGuardar=false;
         },
    },
    {
      headerName: 'Descripción', field: 'CON_NOMBRE', cellEditor: 'cellPrueba', editable: true, width: 180,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 25,
            opcionbuscar: false
          }
        },onCellValueChanged: (params) => {
          this.btnGuardar=false;
         },
    },
    {
      headerName: 'Debe', field: 'VARIOS_DEBE', cellEditor: 'cellPrueba', editable: true, width: 100, cellStyle: {textAlign: 'left'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        },onCellValueChanged: (params) => {
          this.btnGuardar=false;
         },
    },
    {
      headerName: 'Haber', field: 'VARIOS_HABER', editable: true, width: 100, cellEditor: 'cellPrueba', cellStyle: {textAlign: 'left'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        },onCellValueChanged: (params) => {
          this.btnGuardar=false;
         },
    },
    {
      headerName: 'Centro de Costos', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',  editable: (params) => {
        const boolEdit = this.isEdit(params.data) ;
        return boolEdit;
        },
      width: 150,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 10,
            opcionbuscar: true,
            tienecalculos: true
          }
        },onCellValueChanged: (params) => {
          this.btnGuardar=false;
         },
    }
  ];
  
  constructor(private init: NuevoComponentService, private confIniciales: ConfInicialesService, private datePipe: DatePipe,
              private busquedaSer: InvbusquedaService, private message: MessageService, public bantrnCajaService: BantrncajaService,
              private errorService: ErroresBaseDatosService, public usuario: Usuario, private permisosService: PermisosService,
              private auditoriagral: AuditoriagralService, private confirmationService: ConfirmationService,
              private utilitariosService: UtilitariosService) {
    this.item = {};
    this.agTable();
    
    this.bantrnCajaService.detModeloVarCaja = [];
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    
    this.defaultBanCajVarios = {
      editable: true,
      objeto: 'bancajatrnVarios',
      resizable: true
    };
    
  }

  async ngOnInit() {
    this.largo = '580';
    this.numSec = await this.consulta_max_docnum();
    this.item = {};
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.bantrnCajaService.detModeloVarCaja = [];
    
    this.dateTimeFechaVencimiento = new Date();
    this.dateTimeFecha = new Date();
  
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = true;
    this.btnRegresar = true;
    this.btnNuevoGrid = false;
    this.btnGuardarGrid = true;
    this.btnCancelarGrid = true;
    this.btnBorrarGrid = false;
    this.botonPrimero = false;
    this.botonAnterior = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
    this.btnBuscar = false;
  
    this.permisosService.getListaPermisos('BAN', '8', '29').subscribe((permiso) => {
      this.permisos = permiso[0];
    });
  
    const rs = await this.bantrnCajaService.obtenerConfiguracion();
    this.hmpConfiguracion = rs;
  
    this.claveDialog = false;
    this.claveAuth = '';
    this.accionClave = '';
  
    this.cmbTipo = [];
    this.cmbTipo = [{name: 'EFECTIVO', codigo: 0}, {name: 'CHEQUE', codigo: 1}, {name: 'TARJETA DE CREDITO', codigo: 2}, {name: '', codigo: 3}];
    this.strTipoCmb = this.cmbTipo[0];
  
    if (this.strCodCaja === '') {
      this.groupDatosOrigen = false;
      this.activarBotones('N');
      this.boolIndex1 = false;
      setTimeout(() => {
        document.getElementById('divPrincipal').style.pointerEvents = 'all';
      }, 1000);
    } else {
      this.groupDatosOrigen = true;
      await this.consultarCajMov(this.strCodCaja, this.strTrnCajTipo, this.strTrnCajNumero, this.strTrnCajDocNro);
      this.activarBotones('V');
      await this.mostrarDatos(this.item);
    }
  
    if (!this.bandera111 && (this.item.TRNCAJ_ANULADO === 1  || this.saldo === 1)) {
      this.btnBorrar = true;
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
    }
  
    this.bloqueo1();
    
  }
  
  async manejarSenales(valor) {
  
    if (valor === 'Guardar') {
     
      this.guardarRegistro();
    }
    
    if (valor === 'Borrar') {
  
      if (this.permisos != null) {
        if (this.permisos.PERTODO === 0) {
          if (this.permisos.PERELIMACION === 0) {
            this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible eliminar el registro seleccionado debido a ' +
              'que usted no pesee permisos de eliminación');
            return;
          }
        }
      }
      this.eliminarRegistro();
    }
    
    if (valor === 'Salir') {
      this.signalSalir.emit(true);
    }
    
    if (valor === 'Cancelar') {
      if (this.permisos != null) {
        if (this.permisos.PERTODO === 0) {
          if (this.permisos.PERACTUALIZACION === 0) {
            this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible actualizar el registro seleccionado debido a ' +
              'que usted no pesee permisos de actualización');
            return;
          }
        }
      }
     
      this.cancelarRegistro();
      if (this.selectTab === 0 ) {
        this.numPestaña = 1;
      } else {
        this.numPestaña = 0;
      }
  
      if (this.txtCajCodigo === '') {
        this.txtTrnCajImporte = Number(0).toFixed(this.confIniciales.getNumDecSist());
      }
    }
    
    if (valor === 'AsientoVer') {
      if (await this.verificarVisulizacionAsiento() === false) {
        return;
      }
      if (this.lblAsiNro !== '') {
        this.NumAsiRegCont = this.lblAsiNro;
        this.displayAsiContable = true;
      } else {
        this.mensajeAlerta('info', 'Información', 'No ha generado el Asiento Contable');
      }
      
    }
    
  }
  
  async consultarCajMov(strCodCaja, strTrnCajTipo, strTrnCajNumero,  strTrnCajDocNro) {
  
  const strFiltro = 'BAN_TRNCAJ.COM_codigo like \'01\'';
  const strCondicion = ' CAJ_codigo=\'' + strCodCaja + '\''
    + ' AND TRNCAJ_tipo=\'' + strTrnCajTipo + '\''
    + ' AND TRNCAJ_numero = \'' + strTrnCajNumero + '\''
    + ' AND TRNCAJ_docnro= \'' + strTrnCajDocNro + '\'';
  
  
  try {
    const rs = await this.bantrnCajaService.consultarMovCaj(null, 'R', strCondicion);
    if (rs !== null ) {
      for (const dato of rs) {
        this.item.CAJ_CODIGO = dato.CAJ_CODIGO;
        const objeto = rs[0].TRNCAJ_FECHA;
        if (objeto !== null) {
          this.item.TRNCAJ_FECHA = objeto;
        }
        this.item.TRNCAJ_TIPO = dato.TRNCAJ_TIPO;
        this.item.TRNCAJ_NUMERO = dato.TRNCAJ_NUMERO;
        const objeto2 = rs[0]["TO_CHAR(TRNCAJ_FECHAVENCI,'DD/MM/YYYY')"];
        if (objeto2 !== null) {
          this.item.TRNCAJ_FECHAVENCI = rs[0]["TO_CHAR(TRNCAJ_FECHAVENCI,'DD/MM/YYYY')"];
        } else {
          this.item.TRNCAJ_FECHAVENCI = null;
        }
        
        this.item.TRNCAJ_IMPORTE = dato.TRNCAJ_IMPORTE;
        this.item.TRNCAJ_DOCNRO = dato.TRNCAJ_DOCNRO;
        this.item.TRNCAJ_DESCRIPCION = dato.TRNCAJ_DESCRIPCION;
        this.item.TRNCAJ_ORIGEN = dato.TRNCAJ_ORIGEN;
        this.item.ASI_NRO = dato.ASI_NRO;
        if (dato.ASI_NRO === null) {
          this.item.ASI_NRO = '';
        } else {
          this.item.ASI_NRO = dato.ASI_NRO;
        }

        this.item.ASI_FECHA = dato.ASI_FECHA;
        this.item.TRNCAJ_ANULADO = dato.TRNCAJ_ANULADO;
        this.item.CLI_CODIGO = dato.BANCLI_CODIGO;
        this.item.CEN_CODIGO = dato.CEN_CODIGO;
        this.item.BAN_CODIGO = dato.BAN_CODIGO;
        this.item.TRNCAJ_DEPOSITONRO = dato.TRNCAJ_DEPOSITONRO;
      }
      
   }
  } catch (err) {
    let mensaje: string;
    mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
    this.mensajeAlerta('info', 'Error', 'No se puede leer la información de la base de datos. ' + mensaje);
  }
  return this.item;
  }
  
  async mostrarDatos(item: BanTrnCajaTrn) {
    if (item.CAJ_CODIGO != null) {
    
      if (item != null) { // limpiarCampos();
        
        if (item.ASI_NRO !== null) {
          this.lblAsiNro = item.ASI_NRO;
        }
        if (item.CAJ_CODIGO !== null) {
          this.txtCajCodigo = item.CAJ_CODIGO;
        }
        if (await this.verificarExistenciaCaja(this.txtCajCodigo)) {
          this.lblDato = this.strNomCaja;
        } else {
          this.lblDato = '';
        }
  
        if (item.TRNCAJ_IMPORTE !== null) {
          this.txtTrnCajImporte = Number(item.TRNCAJ_IMPORTE).toFixed(this.confIniciales.getNumDecSist());
          this.txtComprobImporte = Number(item.TRNCAJ_IMPORTE).toFixed(this.confIniciales.getNumDecSist());
        } else {
          this.txtTrnCajImporte = Number(0).toFixed(this.confIniciales.getNumDecSist());
          this.txtComprobImporte = Number(0).toFixed(this.confIniciales.getNumDecSist());
        }
  
        if (item.TRNCAJ_DESCRIPCION !== null) {
          this.txtTrnCajDescripcion = item.TRNCAJ_DESCRIPCION;
        }
        if (item.CEN_CODIGO !== null) {
          this.txtCenCodigo = item.CEN_CODIGO;
          try {
            const rs = await this.bantrnCajaService.encontrarCenNombre(String(item.CEN_CODIGO).trim());
            if (rs === null) {
              this.lblCc = '';
              this.strNomCC = '';
            } else {
              this.strNomCC = rs[0].CEN_NOMBRE;
              this.lblCc = this.strNomCC;
            }
          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('error', 'Error', mensaje);
          }
        }
  
        if (item.TRNCAJ_NUMERO !== null) {
          this.txtTrnCajNumero = item.TRNCAJ_NUMERO;
        }
  
        if (item.CLI_CODIGO !== null) {
          this.txtCodReferencia = item.CLI_CODIGO;//GSRF
        }
  
        if (item.TRNCAJ_ORIGEN !== null) {
          this.lblTrnCajOrigen = item.TRNCAJ_ORIGEN;
        } else {
          this.lblTrnCajOrigen = 'CAJ';
        }
  
        if ((String(item.TRNCAJ_ORIGEN) === 'CAJ' && item.TRNCAJ_ANULADO === 0)) {
          document.getElementById('divPrincipal').style.pointerEvents = 'all';
          this.boolIndex1 = false;
        } else {
          this.btnBorrar = true;
          document.getElementById('divPrincipal').style.pointerEvents = 'none';
          this.boolIndex1 = true;
        }
        if (item.TRNCAJ_DOCNRO !== null) {
          this.lblTrnCajDocNro = item.TRNCAJ_DOCNRO;
        }
  
        if (item.BAN_CODIGO !== null) {
          this.lblBanNombre = await this.consulta_nombreBanco();
        } else {
          this.lblBanNombre = '';
        }
  
        if (item.TRNCAJ_DEPOSITONRO !== null) {
          this.lblTrnDepositoNro = '' + item.TRNCAJ_DEPOSITONRO;
        } else {
          this.lblTrnDepositoNro = '';
        }
  
        if (this.lblTrnCajOrigen === 'CAJ' && this.lblTrnDepositoNro !== '') {
          document.getElementById('divPrincipal').style.pointerEvents = 'none';
        }
  
        if (item.TRNCAJ_ANULADO !== 0) {
          if (item.TRNCAJ_ANULADO === 1) {
            this.btnChkTrnCajAnulado = true;
          } else {
            this.btnChkTrnCajAnulado = false;
          }
        }
        this.estado = this.btnChkTrnCajAnulado;
        this.dateTimeFecha = item.TRNCAJ_FECHA === null ? new Date() : new Date(item.TRNCAJ_FECHA);
        this.dateTimeFechaVencimiento = item.TRNCAJ_FECHAVENCI === null ? new Date() : item.TRNCAJ_FECHAVENCI;
  
        if (this.txtComprobImporte !== null) {
          this.txtComprobImporte = this.txtTrnCajImporte === null ? '0' : this.txtTrnCajImporte;
        }
        
        if (String(item.TRNCAJ_TIPO) === 'EF') {
          this.strTipoCmb = this.cmbTipo[0];
        }
        if (String(item.TRNCAJ_TIPO) === 'CH') {
          this.strTipoCmb = this.cmbTipo[1];
        }
        if (String(item.TRNCAJ_TIPO) === 'TA') {
          this.strTipoCmb = this.cmbTipo[2];
        }
  
        if (item.TRNCAJ_TIPO === 'TA' || item.TRNCAJ_TIPO === 'EF') {
          this.boolVisibleFechaVen = true;
        }
        if (item.TRNCAJ_TIPO === 'CH') {
          this.boolVisibleFechaVen = false;
        }
        
        if (String(item.TRNCAJ_TIPO) === 'CH' || String(item.TRNCAJ_TIPO) === 'TA') {
          if (String(item.CLI_CODIGO) !== null) {
            if (String(item.TRNCAJ_TIPO) === 'CH') {
              try {
                const rs = await this.bantrnCajaService.encontrarBanCliNombre(String(item.CLI_CODIGO).trim());
                this.strDato = rs === null ? null : rs[0].BANCLI_NOMBRE;
              } catch (err) {
                let mensaje: string;
                mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
                this.mensajeAlerta('error', 'Error', mensaje);
              }
            }
            if (String(item.TRNCAJ_TIPO) === 'TA') {
              try {
                const rs = await this.bantrnCajaService.encontrarTarDescripcion(String(item.CLI_CODIGO).trim());
                this.strDato = rs === null ? null : rs[0].TAR_DESCRIPCION;
              } catch (err) {
                let mensaje: string;
                mensaje = await this.errorService.generarMensajeErrorCompleto(err.error);
                this.mensajeAlerta('error', 'Error', mensaje);
              }
            }
            if (this.strDato !== null) {
              this.lblDescripcionRef = this.strDato;
            }
          }
        }
        try {
          const rs  = await this.bantrnCajaService.encontrarTrnBanNumero(String(item.CAJ_CODIGO), String(item.TRNCAJ_TIPO),
           String(item.TRNCAJ_NUMERO), String(item.TRNCAJ_DOCNRO));
          this.strDato = rs === null ? null : rs[0].TRNBAN_NUMERO;
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        }
  
        if (this.strDato !== null) {
          try {
            const rs = await this.bantrnCajaService.encontrarBanCodigo( String(item.CAJ_CODIGO),
              String(item.TRNCAJ_TIPO), String(item.TRNCAJ_NUMERO), String(item.TRNCAJ_DOCNRO));
      
            this.strDato = rs === null ? null : rs[0].BAN_CODIGO;
            const strBanDeposito = this.strDato;
      
            const rs1 = await this.bantrnCajaService.encontrarBanBanco(strBanDeposito);
            this.strDato = rs1 === null ? null : rs1[0].BAN_BANCO;
      
          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('error', 'Error', mensaje);
          }
        }
  
        if ((String(item.TRNCAJ_ORIGEN) === 'CAJ') && item.TRNCAJ_ANULADO === 0) {
          this.boolIndex1 = false;
        } else {
          this.boolIndex1 = true;
        }
        
        this.txtComprobImporte = this.init.moneyValidation(this.txtComprobImporte);
      }
    } else {
      this.txtCajCodigo = '';
      this.txtCenCodigo = '';
      this.txtCodReferencia = '';
      this.txtTrnCajDescripcion = '';
      this.txtTrnCajImporte = '';
      this.txtTrnCajNumero = '';
      this.lblBanNombre = '';
      this.lblCc = '';
      this.txtComprobImporte = '';
      this.lblDato = '';
      this.lblDescripcionRef  = '';
      this.lblTrnCajDocNro  = '';
      this.lblTrnCajOrigen  = '';
      this.lblTrnDepositoNro  = '';
      this.strTipoCmb = this.cmbTipo[3];
    }
    
  }
  
  async consulta_nombreBanco() {
   
    try {
      const rs = await this.bantrnCajaService.encontrarBanBanco(this.strBanCodigo);
      if (rs !== null) {
        return rs[0].BAN_BANCO;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
    return null;
  }
  
  async verificarExistenciaCaja(strCodCaj) {
    let bolExisteCaja = false;
    
    
    if (strCodCaj.length > 0) {
      try {
        const rs = await this.bantrnCajaService.verificarExistenciaCaja(strCodCaj);
        if (rs !== null) {
          this.item.CAJ_DESCRIPCION = rs[0]["NVL(C.CAJ_DESCRIPCION,'')"];
          this.strNomCaja = String(this.item.CAJ_DESCRIPCION);
          bolExisteCaja = true;
        } else {
          this.item.CAJ_DESCRIPCION = '';
          bolExisteCaja = false;
        }
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      }
      return bolExisteCaja;
    } else {
      return bolExisteCaja;
    }
    
  }
  
  activarBotones(tipo) {
    if (tipo === 'N') {// Nuevo registro
    
      this.btnGuardar = true;
      this.btnBorrar = true;
      // btnVisualizaAsto.setEnabled(false);
      this.limpiarCampos();
    }
    if (tipo === 'V') { // Modificar registro
      this.btnGuardar = true;
      this.btnBorrar = false;
      // btnVisualizaAsto.setEnabled(true);
    }
    if (tipo === 'M') { // Modificar registro
      this.btnGuardar = false;
      this.btnBorrar = true;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      this.btnNuevoGrid = false;
    }
    if (tipo === 'C') {// Guardar registro
      this.btnGuardar = true;
      this.btnBorrar = false;
    }
    if (tipo === 'mod')  {// Guardar registro
      this.btnGuardar = false;
      this.btnBorrar = true;
      // btnVisualizaAsto.setEnabled(false);
    }
  
    if (tipo === 'E') { // Elimina registro
      this.limpiarCampos();
      this.btnGuardar = true;
      this.btnBorrar = true;
    }
  
    if (tipo === 'G') {
      this.btnGuardar = true;
      this.btnBorrar = false;
     // btnVisualizaAsto.setEnabled(true);
 
    }
  }
  
  limpiarCampos() {
    this.txtCajCodigo = '';
    this.dateTimeFecha = new Date();
    this.txtTrnCajNumero = '';
    this.txtTrnCajImporte = '';
    this.lblTrnCajDocNro = '';
    this.txtTrnCajDescripcion = '';
    this.lblBanNombre = '';
    this.lblDescripcionRef = '';
    this.lblCc = '';
    this.txtCenCodigo = '';
    this.lblTrnCajOrigen = '';
    this.lblTrnDepositoNro = '';
    this.lblDato = '';
    
    this.dateTimeFechaVencimiento = new Date();
    this.txtCodReferencia = '';
    this.txtCenCodigo = '';
    this.lblAsiNro = '';
    this.strTipoCmb = this.cmbTipo[3];
    this.txtComprobImporte = '';
  }

  onTab1Change(event) {
    this.selectTab = event.index;
    
    if (this.btnGuardar === false && this.selectTab === 0) {
      this.boolIndex1 = true;
    } else {
      this.boolIndex1 = false;
    }
  
    if (this.btnGuardar === true) {
    
      if (this.selectTab === 1) {
        
        if (!this.bandera111) {
        
          
          this.consultarVarios(this.strTipoCmb.codigo, String(this.txtTrnCajNumero).trim(), String(this.txtCajCodigo).trim());
          this.totalImporteDH();
     
          this.bandera111 = false;
        
        }
        this.bandera111 = false;
      
      
      
      } // fin del else
    }
  }
  
  async consultarVarios(strCmbCodigo, strTrnCajNumero, strCajCodigo) {
   
    try {
      const detalle: BanDetVarCaja[] = [];
      const rs = await this.bantrnCajaService.consultarVarios(strCmbCodigo, strTrnCajNumero, strCajCodigo);
      if (rs.length > 0) {
        let linea = 0;
        for (const dato of rs) {
          const itemTrnVar: BanDetVarCaja = {};
          itemTrnVar.CON_CODIGO = dato.CON_CODIGO;
          itemTrnVar.CEN_CODIGO = dato.CEN_CODIGO;
          if (Number(dato.TRNCAJVAR_IMPORTE) > 0) {
            itemTrnVar.VARIOS_DEBE = Number(dato.TRNCAJVAR_IMPORTE).toFixed(this.confIniciales.getNumDecSist());
            itemTrnVar.VARIOS_HABER = Number(0).toFixed(this.confIniciales.getNumDecSist());
            this.fltDebe = dato.TRNCAJVAR_IMPORTE;
          }
  
          if (Number(dato.TRNCAJVAR_IMPORTE) < 0) {
            itemTrnVar.VARIOS_HABER = Number(dato.TRNCAJVAR_IMPORTE * -1).toFixed(this.confIniciales.getNumDecSist());
            itemTrnVar.VARIOS_DEBE = Number(0).toFixed(this.confIniciales.getNumDecSist());
            this.fltHaber = Math.abs(dato.TRNCAJVAR_IMPORTE);
          }
  
          if (Number(dato.TRNCAJVAR_IMPORTE) === 0) {
            itemTrnVar.VARIOS_HABER = Number(0).toFixed(this.confIniciales.getNumDecSist());
            itemTrnVar.VARIOS_DEBE = Number(0).toFixed(this.confIniciales.getNumDecSist());
          }
          itemTrnVar.CON_NOMBRE = dato.CON_NOMBRE;
          itemTrnVar.CON_CENTRO = await this.verificarCentroCostosCuenta(itemTrnVar.CON_CODIGO);
          itemTrnVar.LINEA = linea ++;
          
          detalle.push(itemTrnVar);
        }
        this.bantrnCajaService.detModeloVarCaja = detalle;
        
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede leer la información de la base de datos.'  + mensaje);
    }
  }
  
  totalImporteDH(): number {
    this.fltDebe = 0;
    this.fltHaber = 0;
    this.fltSaldo = 0;
    this.fltsaldoActual = 0;
    
    for (const item of  this.bantrnCajaService.detModeloVarCaja) {
      let debe = 0;
      if (Number(item.VARIOS_DEBE) > 0 ) {
        debe = -item.VARIOS_DEBE;
      }
      
      this.fltDebe = item.VARIOS_DEBE === null ? 0 : Number(debe);
      this.fltHaber = item.VARIOS_HABER === null ? 0 : Number(item.VARIOS_HABER);
      this.fltSaldo = this.fltHaber + this.fltDebe;
      this.fltsaldoActual  += this.fltSaldo;
    }
    return this.fltsaldoActual;
  }
  
  cambio(event) {
      this.btnGuardar = false;
      this.btnRegresar = false;
      this.btnNuevo = true;
      if (this.selectTab === 0) {
        this.boolIndex1 = true;
      }
  }
  //CDPJ
  cambiogriddetvar(event){
    this.btnNuevoGrid = false;
  }
//CDPJ
  seleccionDetVarios(valor) {
    
    if (valor === 'nuevo') {
      
      if (this.permisos != null) {
        if (this.permisos.PERTODO === 0) {
          if (this.permisos.PERINSERCION === 0) {
            this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible '
              + 'insertar el registro seleccionado debido a que usted no pesee permisos de inserción');
            return;
          }
        }
      }
      this.nuevoImporte(this.txtCenCodigo);
      
      this.btnCancelarGrid = false;
      this.btnBorrarGrid = false;
      this.btnNuevoGrid = true;
      this.btnBorrar = true;
      this.btnGuardar = false;
    }
    if (valor === 'eliminar') {
      if (this.permisos != null) {
        if (this.permisos.PERTODO === 0) {
          if (this.permisos.PERELIMACION === 0) {
            this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible '
              + 'insertar el registro seleccionado debido a que usted no pesee permisos de eliminación');
            return;
          }
        }
      }
      this.eliminarRegistroVar();
      
      this.btnCancelarGrid = true;
      this.btnNuevoGrid = false;
      this.btnBorrar = true;
      this.btnGuardar = false;
      
    }
    if (valor === 'cancelar') {
      if (this.permisos != null) {
        if (this.permisos.PERTODO === 0) {
          if (this.permisos.PERACTUALIZACION === 0) {
            this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible '
              + 'insertar el registro seleccionado debido a que usted no pesee permisos de actualización');
            return;
          }
        }
      }
      this.btnNuevoGrid = false;
      this.btnGuardar = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = true;
      this.cancelarLineaVarios();
    }
    
    if (valor.object !== undefined) {
      this.detalleVariosSel = valor.object;
      //
      // if (this.aggrid.gridApi.getFocusedCell().column.colId === 'CON_CODIGO') {
      //
      // }
      
    }
    
  }
  
  cancelarLineaVarios() {
    let intIndiceLista = 0;
    let item: BanDetModeloVarios = {};
    for ( intIndiceLista = 0; intIndiceLista <  this.bantrnCajaService.detModeloVarCaja.length; intIndiceLista++ ) {
      item =  this.bantrnCajaService.detModeloVarCaja[intIndiceLista];
      if ( item.CON_CODIGO.length === 0 ) {
        this.bantrnCajaService.detModeloVarCaja.splice( this.bantrnCajaService.detModeloVarCaja.findIndex(
          detalle =>  detalle.LINEA === item.LINEA), 1);
        this.aggrid.refreshaggrid( this.bantrnCajaService.detModeloVarCaja, this.defaultBanCajVarios.objeto);
      }
    }
  }
  
  eliminarRegistroVar() {
    if (Object.keys(this.detalleVariosSel).length > 0) {
      // this.confirmEliminarDetAsi();
      this.confirmationService.confirm({
        message: '¿ Está seguro de eliminar el registro ?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'trnmodificarCajaConf',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
            if (this.detalleVariosSel.LINEA === 0) {
              this.bantrnCajaService.detModeloVarCaja =  this.bantrnCajaService.detModeloVarCaja.filter((val, j) => j !==
                this.bantrnCajaService.detModeloVarCaja.indexOf(this.detalleVariosSel));
              this.aggrid.refreshaggrid(this.bantrnCajaService.detModeloVarCaja, this.defaultBanCajVarios.objeto);
            } else {
              this.bantrnCajaService.detModeloVarCaja.splice( this.bantrnCajaService.detModeloVarCaja.findIndex(
                detalle => detalle.LINEA === this.detalleVariosSel.LINEA),
                1);
              this.aggrid.refreshaggrid( this.bantrnCajaService.detModeloVarCaja, this.defaultBanCajVarios.objeto);
            }
        }, reject: () => {
        }
      });
    } else {
      this.mensajeAlerta('error', 'Error', '! Seleccione una fila.... !');
    }
  }
  
  nuevoImporte(strCenCodigo) {
    const linea =  this.bantrnCajaService.detModeloVarCaja.length;
 
    const nuevodetVarios = {
      TRNCAJ_TIPO: '',
      TRNCAJ_NUMERO: '',
      CAJ_CODIGO: '',
      TRNCAJ_DOCNRO: '',
      TRNCAJVAR_CODSEC: '',
      CON_CODIGO: '',
      CEN_CODIGO: '',
      TRNCAJVAR_IMPORTE: 0,
      COM_CODIGO: '01',
      LINEA: linea + 1,
      VARIOS_DEBE: Number(0).toFixed(this.confIniciales.getNumDecSist()),
      VARIOS_HABER: Number(0).toFixed(this.confIniciales.getNumDecSist()),
      CON_NOMBRE: ''
    };
    console.log(this.detalleVariosSel)
    const indi =  this.bantrnCajaService.detModeloVarCaja.indexOf(this.detalleVariosSel);
    console.log(indi);
    if (indi === -1) {
      console.log('entra -1');
      this.bantrnCajaService.detModeloVarCaja.push(nuevodetVarios);
      this.detalleVariosSel =  this.bantrnCajaService.detModeloVarCaja[this.bantrnCajaService.detModeloVarCaja.length - 1];
      this.aggrid.refreshaggrid(this.bantrnCajaService.detModeloVarCaja, this.defaultBanCajVarios.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultBanCajVarios.objeto].setFocusedCell(
        this.bantrnCajaService.detModeloVarCaja.length - 1, 'CON_CODIGO');
    } else {
      console.log('entra despues');
      this.bantrnCajaService.detModeloVarCaja.splice(linea + 1, 0, nuevodetVarios);
      this.aggrid.refreshaggridindex( this.bantrnCajaService.detModeloVarCaja, this.defaultBanCajVarios.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
      this.defaultBanCajVarios.objeto].setFocusedCell(linea + 1, 'CON_CODIGO');
    }
  }
  
  cambioCmb(valor) {
    
    const indexCmb = this.cmbTipo.findIndex(index => index.name === this.strTipoCmb.name);
  
    if (indexCmb === 0) {
      if (this.strTipoCmb.name === 'EFECTIVO') {
        this.txtCodReferencia = '';
        this.lblDescripcionRef = '';
        this.disabledBanCod = true;
        this.boolVisibleFechaVen = true;
      }
    }
    if (indexCmb === 1) {
      if (this.strTipoCmb.name === 'CHEQUE') {
        this.disabledBanCod = false;
        this.txtCodReferencia = '';
        this.lblDescripcionRef = '';
        this.boolVisibleFechaVen = false;
      }
    }
    if (indexCmb === 2) {
      if (this.strTipoCmb.name === 'TARJETA DE CREDITO') {
        this.disabledBanCod = false;
        this.txtCodReferencia = '';
        this.lblDescripcionRef = '';
        this.boolVisibleFechaVen = true;
      }
    }
    this.txtTrnCajImporte = Number(0).toFixed(2);
  }
  
  buscarCaja(parametro) {
    if (this.permisos != null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERSELECCION === 0) {
          this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible consultar el registro seleccionado debido a ' +
            'que usted no pesee permisos de consulta');
          return;
        }
      }
    }
    this.opcion = 'BuscarCaja';
    this.types = [
      {label: 'CÓDIGO', value: 'CAJ_CODIGO'},
      {label: 'DESCRIPCIÓN', value: 'CAJ_DESCRIPCION'},
      {label: 'REF.CONTABLE', value: 'CON_CODIGO'},
    ];
    this.busquedacampos = [parametro, '', ''];
    this.consulta = 'CAJ_CODIGO, CAJ_DESCRIPCION, CON_CODIGO';
    this.tabla = 'BAN_MAECAJ';
    this.where = '';
    
    this.bantrnCajaService.encontrarCaja(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaBanMaeCaj2().subscribe((res: any[]) => {
            this.arregloCons = res;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaBanMaeCaj2().subscribe((res: any[]) => {
          this.arregloCons = res;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }
  
  manejarSeleccion(valor) {
    if (this.opcion === 'BuscarCaja') {
      this.txtCajCodigo = valor.CAJ_CODIGO;
      this.lblDato = valor.CAJ_DESCRIPCION;
    }
    
    if (this.opcion === 'BuscarBanco') {
      this.txtCodReferencia = valor.BANCLI_CODIGO;
      this.strNomCC = valor.BANCLI_NOMBRE;
      this.lblDescripcionRef = this.strNomCC;
    }
  
    if (this.opcion === 'BuscarTarjeta') {
      this.txtCodReferencia = valor.TAR_CODIGO;
      this.strNomCC = valor.TAR_DESCRIPCION;
      this.lblDescripcionRef = this.strNomCC;
    }
  
    if (this.opcion === 'BuscarCentroC') {
      this.txtCenCodigo = valor.CEN_CODIGO;
      this.lblCc = valor.CEN_NOMBRE;
    }
    
    this.displayDialogBus = false;
    this.displayDialogBusquedaFast = false;
  }
  
  buscarBancos(parametro) {
    if (this.permisos != null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERSELECCION === 0) {
          this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible consultar el registro seleccionado debido a ' +
            'que usted no pesee permisos de consulta');
          return;
        }
      }
    }
  
    if (this.strTipoCmb.codigo === 1) {
      if (this.strTipoCmb.name === 'CHEQUE') {
        this.opcion = 'BuscarBanco';
        this.types = [
          {label: 'CODIGO', value: 'BANCLI_CODIGO'},
          {label: 'BANCO', value: 'BANCLI_NOMBRE'},
        ];
        this.busquedacampos = [parametro, ''];
        this.consulta = 'BANCLI_CODIGO, BANCLI_NOMBRE';
        this.tabla = 'CXC_MAEBANCLI';
        this.where = '';
  
        this.bantrnCajaService.encontrarBanco(parametro).subscribe(eR => {
          if (eR !== null) {
            if (eR[0] !== undefined) {
              this.manejarSeleccion(eR[0]);
            } else {
              this.busquedaSer.busquedaCXCMaeBanCli().subscribe((res: any[]) => {
                this.arregloCons = res;
                this.displayDialogBusquedaFast = true;
              });
            }
          } else {
            this.busquedaSer.busquedaCXCMaeBanCli().subscribe((res: any[]) => {
              this.arregloCons = res;
              this.displayDialogBusquedaFast = true;
            });
          }
        });
      }
    }
    if (this.strTipoCmb.codigo === 2) {
      if (this.strTipoCmb.name === 'TARJETA DE CREDITO') {
        this.opcion = 'BuscarTarjeta';
        this.types = [
          {label: 'NUMERO', value: 'TAR_CODIGO'},
          {label: 'DESCRIPCION', value: 'TAR_DESCRIPCION'},
          {label: '%', value: 'TAR_PORCENTAJE'},
          {label: 'R.C.INTERES', value: 'CON_CODIGO'},
          {label: 'R.C.COMISION', value: 'CON_CODIGOCOM'},
          {label: 'RET.FUENTE', value: 'CON_CODIGORFTE'},
          {label: 'RET.IVA', value: 'CON_CODIGOFIVA'},
          {label: 'RUC', value: 'TAR_RUC'},
          {label: 'CODSRI', value: 'TAR_CODSRI'},
        ];
        this.busquedacampos = [parametro, '', '', '', '', '', '', '', ''];
        this.consulta = 'TAR_codigo , TAR_descripcion , TAR_porcentaje, CON_codigo,con_codigocom,con_codigorfte, ' +
          ' con_codigofiva,tar_ruc ,tar_codsri';
        this.tabla = 'cxc_maetarjeta';
        this.where = '';
  
        this.bantrnCajaService.encontrarTarjeta(parametro).subscribe(eR => {
          if (eR !== null) {
            if (eR[0] !== undefined) {
              this.manejarSeleccion(eR[0]);
            } else {
              this.busquedaSer.busquedaCXCMaeTarjeta().subscribe((res: any[]) => {
                this.arregloCons = res;
                this.displayDialogBusquedaFast = true;
              });
            }
          } else {
            this.busquedaSer.busquedaCXCMaeTarjeta().subscribe((res: any[]) => {
              this.arregloCons = res;
              this.displayDialogBusquedaFast = true;
            });
          }
        });
      }
    }
    
  }
  
  buscarCenCosto(parametro) {
    if (this.permisos != null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERSELECCION === 0) {
          this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible consultar el registro seleccionado debido a ' +
            'que usted no pesee permisos de consulta');
          return;
        }
      }
    }
    
    this.opcion = 'BuscarCentroC';
    this.types = [
      {label: 'CODIGO', value: 'CEN_CODIGO'},
      {label: 'NOMBRE', value: 'CEN_NOMBRE'},
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = 'CEN_CODIGO, CEN_NOMBRE';
    this.tabla = 'CON_MAECEN';
    this.where = '';
  
    this.bantrnCajaService.encontrarCentroC(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaConMaeCen().subscribe((res: any[]) => {
            this.arregloCons = res;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaConMaeCen().subscribe((res: any[]) => {
          this.arregloCons = res;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }
  
  cambiarFoco(evento) {
    if (evento.target.id === 'codCaja') {
      this.buscarCaja(this.txtCajCodigo);
      document.getElementById('cmbTipo').focus();
    }
    
    if (evento.target.id === 'cmbTipo') {
      document.getElementById('numero').focus();
    }
    if (evento.target.id === 'numero') {
      document.getElementById('importe').focus();
    }
    if (evento.target.id === 'importe') {
      document.getElementById('descripcion').focus();
    }
    
  }
  
  async guardarRegistro() {
    const dia = this.dateTimeFecha.getDate();
    const mes = this.dateTimeFecha.getMonth();
    const anio = this.dateTimeFecha.getFullYear();
  
    if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === true) {
      this.guardarDocumento();
    } else {
      this.mensajeAlerta('error', 'Error', 'La Fecha del documento debe estar dentro del periodo contable.');
    }
  }
  
  async guardarDocumento() {
    if (String(this.txtCajCodigo).trim() === '' || String(this.txtTrnCajNumero).trim() === '' ||  String(this.txtTrnCajDescripcion).trim() === '') {
      this.mensajeAlerta('error', 'Error', 'Los campos caja, importe, número de comprobante y descripción son necesarios ingreselos correctamente');
      this.activateButtons(false, true, false, true, true);
      return;
    }
   //CDPJ
   if(await this.verificarCntaVarios() === false){
    console.log('entro')
    this.activateButtons(false, true, false, true, true);
    return;
  }
  //CDPJ
    this.txtComprobImporte = this.txtTrnCajImporte;
    
    if (!this.bandera111) {
      if (this.permisos != null) {
        if (this.permisos.PERTODO === 0) {
          if (this.permisos.PERACTUALIZACION === 0) {
            this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible actualizar el registro seleccionado debido a ' +
              'que usted no pesee permisos de actualización');
           // this.cancelarRegistro();
            return;
          }
        }
      }
      this.actualizarRegistro();
      this.boolIndex1 = false;
      return;
    }
  
    if (this.bandera111) {
      if (this.permisos != null) {
        if (this.permisos.PERTODO === 0) {
          if (this.permisos.PERINSERCION === 0) {
            this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible insertar el registro seleccionado debido a ' +
              'que usted no pesee permisos de inserción');
            // this.cancelarRegistro();
            return;
          }
        }
      }
      
      if (! await this.verificarExistenciaCaja(this.txtCajCodigo)) {
        this.mensajeAlerta('error', 'Error', 'El código de caja no existe o no es el correcto');
        document.getElementById('codCaja').focus();
        return;
      }
      //GSRF
      if (await this.num_Exist()) {
        this.mensajeAlerta('error', 'ERROR', 'El número de comprobante ya existe, ingrese uno diferente');
        this.txtTrnCajNumero = '';
        this.activateButtons(false, true, false, true, true);
        return;
      }
    //GSRF
      await this.insertarRegistro();
      this.mensajeAlerta('success', 'Movimiento de caja', 'El movimiento ha sido guardada correctamente. ');//GSRF
      this.bandera111 = false;
      this.boolIndex1 = false;
      return;
    }
  }
   //CDPJ
   async verificarCntaVarios() {
    let boolvar = true
    console.log('length',this.bantrnCajaService.detModeloVarCaja.length)
    if (this.bantrnCajaService.detModeloVarCaja.length > 0) {
      for (let fila = 0; fila < this.bantrnCajaService.detModeloVarCaja.length; fila++) {
        let itemVar: BanDetVarCaja = {};
        itemVar = this.bantrnCajaService.detModeloVarCaja[fila];
        try {
          const data = await this.bantrnCajaService.verificarExistenciaCnta(itemVar.CON_CODIGO);
          if (data !== null && data !== undefined) {
            for (const data1 of data) {
              if (Number(data1.NUMERO) === 0) {
                this.mensajeAlerta('error', 'Error', 'La cuenta contable '+itemVar.CON_CODIGO+' no existe');
                boolvar = false
              }
            }
          } else {
            boolvar = false;
          }
        } catch (error) {
          boolvar = false;

        }
       
      }
    } else {
      return boolvar
    }
    return boolvar
  }

  //CDPJ  
  async insertarRegistro() {
    //GSRF
    // if (await this.num_Exist()) {
    //   this.mensajeAlerta('error', 'ERROR', 'El número de comprobante ya existe, ingrese uno diferente');
    //   this.txtTrnCajNumero = '';
    //   this.activateButtons(false, true, false, true, true);
    //   return;
    // }
   //GSRF
    const indexCmb = this.cmbTipo.findIndex(index => index.name === this.strTipoCmb.name);
    if (indexCmb === 0) {
      this.strTipo = 'EF';
    }
    if (indexCmb === 1) {
      this.strTipo = 'CH';
    }
    if (indexCmb === 2) {
      this.strTipo = 'TA';
    }
    if (this.btnChkTrnCajAnulado) {
      this.intAnulado = 1;
    } else {
      this.intAnulado = 0;
    }
  
    const strFechaTrnCaj =  this.dateTimeFecha.toLocaleString('en-GB').split(',');
    const strFechaTrnCajVenci = this.dateTimeFechaVencimiento.toLocaleString('en-GB').split(',');
  
    if (this.selectTab === 0 ) {
      this.guardaDatos = await this.insertar(this.txtCajCodigo, strFechaTrnCaj[0], this.strTipo, this.txtTrnCajNumero, this.strTrnCajFormaPago,
        String(this.numSec).trim(), this.txtTrnCajImporte, this.txtTrnCajDescripcion, this.intAnulado, strFechaTrnCajVenci[0], this.strTrnCajOrigen,
        this.txtCodReferencia, this.strCliCodigo, this.txtCenCodigo);
    
      await this.consultarCajMov(this.txtCajCodigo, this.envioDatostipo(), this.txtTrnCajNumero, String(this.numSec).trim());
      await this.mostrarDatos(this.item);
      this.bloqueo();
      this.numPestaña = 1;
    
    }
  
    if (this.selectTab === 1 ) {
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
    this.defaultBanCajVarios.objeto].stopEditing();
      this.eliminarUltimaLineaVarios();
      this.guardarRegistroVarios(String(this.numSec).trim());
      this.numPestaña = 0;
    }
    this.bandera111 = false;
    this.activarBotones('G');
  }
  
  bloqueo() {
    if (this.txtCajCodigo !== '') {
      this.disabledCajCodigo = true;
      this.disabledCmbTipo = true;
      this.disabledTxtNumero = true;
      this.hiddenBtnCajCod = true;
    }
  }
  
  envioDatostipo(): string {
    const indexCmb = this.cmbTipo.findIndex(index => index.name === this.strTipoCmb.name);
    if (indexCmb === 0) {
      return 'EF';
    }
    if (indexCmb === 1) {
      return 'CH';
    }
    if (indexCmb === 2) {
      return 'TA';
    }
    return '';
  }
  
  async insertar(strCajCodigo, strFechaTrnCaj, strTipo, strTrnCajNumero, strTrnCajFormaPago, strTrnCajDocNro, strTrnCajImporte, strTrnCajDescripcion,
                 intTrnCajAnulado, strFechaTrnCajVenci, strTrnCajTipo, strCodReferencia, strCliCodigo, strCenCodigo) {
    let guardaDatosOp = false;
    
    try {
      const rs = await this.bantrnCajaService.insertar(strCajCodigo, strFechaTrnCaj, strTipo, strTrnCajNumero, strTrnCajFormaPago, strTrnCajDocNro,
        Number(strTrnCajImporte), strTrnCajDescripcion, intTrnCajAnulado, strFechaTrnCajVenci, strTrnCajTipo, strCodReferencia, strCliCodigo, strCenCodigo);
      const strClave = strTipo + '\\' + strTrnCajNumero + '\\' ;
      this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', strClave, 'I',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se guardó correctamente ' + mensaje);
      return guardaDatosOp;
    }
    guardaDatosOp = true;
    return guardaDatosOp;
  }
  
  async num_Exist() {
    try {
      const rs = await this.bantrnCajaService.existenciaNum(String(this.txtTrnCajNumero).trim());
      if (rs.length > 0) {
        return true;
      }
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
    return false;
  }
  
  
  actualizarRegistro() {
    this.numSecAc = this.item.TRNCAJ_DOCNRO;
    
    const indexCmb = this.cmbTipo.findIndex(index => index.name === this.strTipoCmb.name);
    
    if (indexCmb === 0) {
      this.strTipo = 'EF';
    }
    if (indexCmb === 1) {
      this.strTipo = 'CH';
    }
    if (indexCmb === 2) {
      this.strTipo = 'TA';
    }
    if (this.btnChkTrnCajAnulado) {
      this.intAnulado = 1;
    } else {
      this.intAnulado = 0;
    }
    
    const strFechaTrnCaj =  this.dateTimeFecha.toLocaleString('en-GB').split(',');
    const strFechaTrnCajVenci = this.dateTimeFechaVencimiento.toLocaleString('en-GB').split(',');
  
    if (this.selectTab === 0) {
      this.actualizaRegistro(this.txtCajCodigo, strFechaTrnCaj[0], this.strTipo, this.txtTrnCajNumero, this.strTrnCajFormaPago,
        this.numSecAc, Number(this.txtTrnCajImporte), this.txtTrnCajDescripcion, this.intAnulado, strFechaTrnCajVenci[0], this.strTrnCajOrigen,
        this.txtCodReferencia, this.txtCenCodigo);
      this.numPestaña = 1;
    }
    if (this.selectTab === 1 ) {
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
    this.defaultBanCajVarios.objeto].stopEditing();
      this.eliminarUltimaLineaVarios();
      this.guardarRegistroVarios(this.numSecAc);
      this.numPestaña = 0;
    }
    this.activarBotones('G');
  }
  
  async actualizaRegistro(cajCodigo, trncajFecha, trncajTipo, trncajNumero, trncajFormaPago, trncajDocNro, trncajImporte, trncajDescripcion,
                          trncajAnulado, trncajFechaVen, trncajOrigen, bancliCodigo, cenCodigo) {
    try {
      await this.bantrnCajaService.actualizarRegistro(cajCodigo, trncajFecha, trncajTipo, trncajNumero, trncajFormaPago, trncajDocNro,
        Number(trncajImporte), trncajDescripcion, trncajAnulado, trncajFechaVen, trncajOrigen, bancliCodigo, cenCodigo);
      const strClave = this.strTipo + '\\' + this.strTrnCajNumero + '\\';
      this.mensajeAlerta('success', 'Movimiento de caja', 'El movimiento ha sido actualizado correctamente. ');//GSRF
      this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', strClave, 'A',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se actualizó correctamente '  + mensaje);
    }
    
  }
  
  async guardarRegistroVarios(doc) {
  
    const indexCmb = this.cmbTipo.findIndex(index => index.name === this.strTipoCmb.name);
    if (indexCmb === 0) {
      this.strTipo = 'EF';
    }
    if (indexCmb === 1) {
      this.strTipo = 'CH';
    }
    if (indexCmb === 2) {
      this.strTipo = 'TA';
    }
    if (this.btnChkTrnCajAnulado) {
      this.intAnulado = 1;
    } else {
      this.intAnulado = 0;
    }
    
    await this.guardarVarios(this.strTipo, this.txtTrnCajNumero, this.txtCajCodigo, doc, this.init.quitarComas(this.txtComprobImporte));
    const strNumAsi = this.strAsiNro;
    if (this.ErrorF) {
      if (strNumAsi != null) {
        this.lblAsiNro = strNumAsi;
      }
    }
}
  async guardarVarios(strTipo, strTrnCajNumero, strCajCodigo, strTrnCajDocNro, strImporte) {
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
    this.defaultBanCajVarios.objeto].stopEditing();
    const fltImporte = Number(strImporte);
    let bolGuardaVarios = false;
    let strTrnCajVarImporte = '';
    //GSRF
    const fltSaldoVarios = Number(this.totalImporteDH().toFixed(2));
    console.log(fltImporte);
    console.log(fltSaldoVarios);
    for (let fila = 0; fila <  this.bantrnCajaService.detModeloVarCaja.length; fila++) {
      if ( this.bantrnCajaService.detModeloVarCaja[fila].CON_CODIGO.substr(-1, 1) === '.') {
        this.mensajeAlerta('error', 'Cuenta Contable', 'No se puede elegir esa cuenta contable ' );
        this.bantrnCajaService.detModeloVarCaja[fila].CON_CODIGO = '';
        this.bantrnCajaService.detModeloVarCaja[fila].CON_NOMBRE = '';
        this.aggrid.refreshaggrid( this.bantrnCajaService.detModeloVarCaja, this.defaultBanCajVarios.objeto);
        return;
      } 
    }
    //GSRF
    if ((fltSaldoVarios - fltImporte) === 0 ) {
      this.ErrorF = true;
      this.eliminarTrnVarios(strTrnCajDocNro);
      for (let fila = 0; fila <  this.bantrnCajaService.detModeloVarCaja.length; fila++) {
       
        let itemVar: BanDetVarCaja = {};
        itemVar =  this.bantrnCajaService.detModeloVarCaja[fila];
        
        let suma = Number(itemVar.VARIOS_DEBE === null ? '' + this.confIniciales.getNumDecSist() :
          itemVar.VARIOS_DEBE.trim() === '' ? '' +  this.confIniciales.getNumDecSist() : itemVar.VARIOS_DEBE);
  
        suma = suma * -1;
        
        const aux = Number(itemVar.VARIOS_HABER === null ? '' + this.confIniciales.getNumDecSist() :
          itemVar.VARIOS_HABER.trim() === '' ? '' + this.confIniciales.getNumDecSist() : itemVar.VARIOS_HABER);
        
        strTrnCajVarImporte =  '' + (suma + aux ) * -1;
        
        try {
          await this.bantrnCajaService.guardarVarios(strTipo, strTrnCajNumero, strCajCodigo, strTrnCajDocNro, itemVar.CON_CODIGO,
            itemVar.CEN_CODIGO, Number(strTrnCajVarImporte));
          bolGuardaVarios = true;
          // nuevoItem = false;
          // filaNueva = -1;
          const strClave = strTipo + '\\' + strTrnCajNumero + '\\' + itemVar.CON_CODIGO;
  
          this.auditoriagral.registrarAuditoria('BAN_TRNCAJVAR', strClave, 'I',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });
          if (this.fltsaldoActual !== 0) {
            await this.generarAsiento(strTrnCajDocNro);
          }
          this.mensajeAlerta('success', 'Movimiento de caja', 'Varios ha sido guardada correctamente. ');//GSRF
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', 'No se guardo correctamente ' + mensaje);
          bolGuardaVarios = false;
        }
      } // cierre del for
    } else { // fin if compara Total Doc con Total Varios
      this.mensajeAlerta('error', 'Error', 'El valor del total del importe en Varios es distinto al valor del importe de caja.');
      this.ErrorF = false;
      bolGuardaVarios = false;
    }
  }
  
  async generarAsiento(doc) {
    this.cont = this.cont + 1;
    let bolGeneraAsto = false;
    const indexCmb = this.cmbTipo.findIndex(index => index.name === this.strTipoCmb.name);
    if (indexCmb === 0) {
      this.strTipo = 'EF';
    }
    if (indexCmb === 1) {
      this.strTipo = 'CH';
    }
    if (indexCmb === 2) {
      this.strTipo = 'TA';
    }
    
    bolGeneraAsto = await this.generarAsientoServ(this.strTipo, this.txtTrnCajNumero, this.txtCajCodigo, doc);
  
    if (bolGeneraAsto &&  this.bantrnCajaService.detModeloVarCaja.length === this.cont) {
      this.mensajeAlerta('info', 'Información', 'El Asiento Contable se generó correctamente');
      this.btnGuardar = true;
    }
  }
  
  async generarAsientoServ(strTipo, strTrnCajNumero, strCajCodigo, strTrnCajDocNro) {
    let bolGenAsto = false;
    try {
      const rs = await this.bantrnCajaService.generarAsiento(strTipo, strTrnCajNumero, strCajCodigo, strTrnCajDocNro);
      this.strAsiNro = rs[0][':B1'];
      return true;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
      bolGenAsto = false;
    }
  
    return false;
  }
  
  async eliminarTrnVarios(doc) {
    const indexCmb = this.cmbTipo.findIndex(index => index.name === this.strTipoCmb.name);
    if (indexCmb === 0) {
      this.strTipo = 'EF';
    }
    if (indexCmb === 1) {
      this.strTipo = 'CH';
    }
    if (indexCmb === 2) {
      this.strTipo = 'TA';
    }
    
    await this.eliminaTrnVarios(this.strTipo, this.txtTrnCajNumero, this.txtCajCodigo, doc, '01');
  
}
  async eliminaTrnVarios(strTipo, strTrnCajNumero, strCajCodigo, strTrnCajNumeroDoc, strCpia) {
    try {
      await this.bantrnCajaService.eliminaTrnVarios(strTipo, strTrnCajNumero, strCajCodigo, strTrnCajNumeroDoc);
      return true;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
    return false;
  }
  
  
  
  eliminarUltimaLineaVarios() {
    let intIndiceLista = 0;
    let item: BanDetVarCaja = {};
    for (intIndiceLista = 0; intIndiceLista <  this.bantrnCajaService.detModeloVarCaja.length; intIndiceLista++ ) {
      item =  this.bantrnCajaService.detModeloVarCaja[intIndiceLista];
      if (item.CON_CODIGO.length === 0 ) {
        this.bantrnCajaService.detModeloVarCaja.splice( this.bantrnCajaService.detModeloVarCaja.findIndex(
          detalle =>  detalle.LINEA === item.LINEA), 1);
        this.aggrid.refreshaggrid( this.bantrnCajaService.detModeloVarCaja, this.defaultBanCajVarios.objeto);
      }
    }
  
  }
  
  activateButtons(cancelar, eliminar, guardar, asiento, imprimir) {
    this.btnBorrar = eliminar;
    this.btnGuardar = guardar;
    this.btnRegresar = cancelar;
    // btnVisualizaAsto.setEnabled(asiento);
  }
  
  bloqueo1() {
    if (this.strCodCaja.length > 0) {
      this.disabledCajCodigo = true;
      this.disabledCmbTipo = true;
      this.disabledTxtNumero = true;
      this.hiddenBtnCajCod = true;
    }
  }
  
  async consulta_max_docnum() {
    
    try {
      const rs = await this.bantrnCajaService.consulta_max_docnum();
      if (rs !== null) {
        return rs[0]["TO_CHAR(NVL(MAX(TRNCAJ_DOCNRO),0)+1,'0000000000')"];
      }
    
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
    return '0';
  }
  
  async eliminarRegistro() {
    const dia = this.dateTimeFecha.getDate();
    const mes = this.dateTimeFecha.getMonth();
    const anio = this.dateTimeFecha.getFullYear();
  
    if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === true) {
      this.eliminaDatos();
    } else {
      this.mensajeAlerta('error', 'Error', 'La Fecha del documento debe estar dentro del periodo contable.');
    }
    
  }
  
  eliminaDatos() {
    if (this.permisos != null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERELIMACION === 0) {
          this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible eliminar el registro seleccionado debido a ' +
            'que usted no pesee permisos de eliminación');
          return;
        }
      }
    }
    const indexCmb = this.cmbTipo.findIndex(index => index.name === this.strTipoCmb.name);
    
    if (indexCmb === 0) {
      this.strTipo = 'EF';
    }
    if (indexCmb === 1) {
      this.strTipo = 'CH';
    }
    if (indexCmb === 2) {
      this.strTipo = 'TA';
    }
  
    this.confirmationService.confirm({
      message: '¿ Está seguro de eliminar el registro ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'trnmodificarCajaConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmarEliminarRegistro();
      },
      reject: () => {
      }
    });
    
  }
  
  async confirmarEliminarRegistro() {
    if (this.strTrnCajOrigen === 'CAJ') {
      if (await this.anularAsientoContable(String(this.lblAsiNro).trim())) {
        if (await this.actualizarNumAsientoDocxxx()) {
          if (await this.eliminaTrnVarios(this.strTipo, this.txtTrnCajNumero, this.txtCajCodigo, this.item.TRNCAJ_DOCNRO, '01')) {
            this.eliminaRegistro(this.strTipo, this.txtTrnCajNumero);
          }
        
          this.lblAsiNro = '';
          this.mensajeAlerta('info', 'Información', 'El Asiento Contable está ANULADO');
        }
      }
    
      this.activarBotones('E');
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      this.boolIndex1 = true;
      // tabFolderMovCajaModifica.setEnabled(false);
    
    } else {
      this.mensajeAlerta('error', 'Error', 'No se puede eliminar la transacción, porque pertenece a otro origen');
    }
    
  }
  
  async eliminaRegistro(strTipo, strTrnCajNumero) {
    try {
      await this.bantrnCajaService.eliminaRegistro(strTipo, strTrnCajNumero);
      const strClave = strTipo + '\\' + strTrnCajNumero + '\\';
  
      this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', strClave, 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se eliminó el registro correctamente ' + mensaje);
    }
  }
  
  async actualizarNumAsientoDocxxx() {
    let bolActualizar = false;
    const anulado = this.btnChkTrnCajAnulado === true ? 1 : 0;
    try {
      await this.bantrnCajaService.actualizarNumAsientoDocxxx(anulado, this.strTipo, this.txtTrnCajNumero, this.item.TRNCAJ_DOCNRO);
      bolActualizar = true;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede actualizar el número de asiento. ' + mensaje);
    }
    return bolActualizar;
  }
  
  async anularAsientoContable(strAsiNro) {
    let bolAnular = false;
    
    try {
      await this.bantrnCajaService.anularAsientoContable(strAsiNro);
      bolAnular = true;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede anular el asiento. ' + mensaje);
    }
  
    return bolAnular;
  }
  
  async cancelarRegistro() {
    await this.consultarCajMov(this.txtCajCodigo, this.envioDatostipo(), this.txtTrnCajNumero, String(this.numSec).trim());
    await this.mostrarDatos(this.item);
    if (this.txtCajCodigo === '') {
      this.activarBotones('N');
      document.getElementById('codCaja').focus();
    } else {
      this.activarBotones('V');
    }
  }
  
  async cambioAnulado(e) {
    const dia = this.dateTimeFecha.getDate();
    const mes = this.dateTimeFecha.getMonth();
    const anio = this.dateTimeFecha.getFullYear();
  
    if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === true) {
      this.anulacion();
    } else {
      this.btnChkTrnCajAnulado = false;
      this.mensajeAlerta('error', 'Error', 'La Fecha del documento debe estar dentro del periodo contable.');
    }
  }
  
  anulacion() {
    this.confirmationService.confirm({
      message: '¿ Está seguro de anular el documento ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'trnmodificarCajaConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmarAnulacion();
      },
      reject: () => {
        this.btnChkTrnCajAnulado = false;
      }
    });
  }
  
  async confirmarAnulacion() {
    this.btnChkTrnCajAnulado = true;
  
    if (this.btnChkTrnCajAnulado === true) {
      this.bolAnulaAsto = await this.anularAsientoContable(String(this.lblAsiNro).trim());
      if (this.bolAnulaAsto) {
        if (this.actualizarNumAsientoDoc()) {
          this.lblAsiNro = '';
          this.mensajeAlerta('info', 'Información', 'El Asiento Contable está ANULADO');
          this.btnGuardar = true;
        }
      }
    
    }
  }
  
  async actualizarNumAsientoDoc() {
    let bolActualizar = false;
    const anulado = this.btnChkTrnCajAnulado === true ? 1 : 0;
    
    try {
      await this.bantrnCajaService.actualizarNumAsientoDocxxx(anulado, this.strTipo , this.txtTrnCajNumero, this.numSec);
      bolActualizar = true;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede actualizar el número de asiento. ' + mensaje);
    }
    return bolActualizar;
  }
  
  async verificarVisulizacionAsiento() {
    const config = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CRLVISASI').CFG_VALOR1;
    const bolCRLVISASI = config === 0 ? false : true;
    
    if (bolCRLVISASI === false) {
      return true;
    }
    
    let bolVisAsi = false;
    this.claveDialog = true;
    
    if (await this.comprobarClave() === false) {
      this.mensajeAlerta('info', 'Información', 'Debe ingresar correctamente la clave para visualizar el asiento.');
      bolVisAsi = false;
    } else {
      bolVisAsi = true;
    }
    
    return bolVisAsi;
  }
  
  async comprobarClave() {
    try {
      const clave = await this.bantrnCajaService.obtenerClave();
      let claveReal;
      if (Object.keys(clave).length > 0) {
        claveReal = clave[0].AUTORIZACION_CLAVE;
        if (claveReal === this.claveAuth) {
          this.claveDialog = false;
          this.claveAuth = '';
          return true;
        } else if (clave !== this.claveAuth) {
          this.claveDialog = false;
          this.mensajeAlerta('error', 'Error', 'La clave está incorrecta');
          return false;
        }
      } else  {
        this.claveDialog = false;
        this.mensajeAlerta('error', 'Error', 'La clave está incorrecta');
        return false;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', 'No se puede validar la clave' + mensaje);
    }
  }
  
  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }
  
  isEdit(data): boolean {
    if (data.CON_CENTRO === 'Si') {
      return true;
    } else {
      return false;
    }
    return true;
  }
  
  async verificarCentroCostosCuenta(strCodCuenta) {
    try {
      const rs = await this.bantrnCajaService.verificarCentroCostos(strCodCuenta);
      if (rs.length > 0) {
        return rs[0].CON_CENTRO;
      } else {
        return 'No';
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', 'No se puede obtener la información de la base de datos ' + mensaje);
    }
  }
  
  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'trnmodificarCaja',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
  
}
