import { Injectable } from '@angular/core';
import { Prodcostfech } from '../prodinterfaces/costosfecha';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from 'src/app/usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import { DatePipe } from '@angular/common';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class ProdcostosfechaService {
  public costosfec: Prodcostfech[] = [];
  private url: string;
  public way: string;

  constructor(public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService,
    private http: HttpClient) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
    this.usuario.identificacion;
  }
  validar(cabe: Prodcostfech, det: Prodcostfech) {
    let errors = "";
    let err = [];
    if (isNullOrUndefined(cabe.CINF_DOCUMENTO) || cabe.CINF_DOCUMENTO == "") {
      errors = "Ingrese nombre de Documento";
      err.push(errors);
      return err;
    }
    if (isNullOrUndefined(cabe.CINF_MES) || cabe.CINF_MES == "") {
      errors = "Seleccione el mes";
      err.push(errors);
      return err;
    }
    if (isNullOrUndefined(cabe.CINF_ANIO) || cabe.CINF_ANIO == "") {
      errors = "Ingrese el año";
      err.push(errors);
      return err;
    }
    if (isNullOrUndefined(cabe.CEN_CODIGO) || cabe.CEN_CODIGO == "") {
      errors = "Ingrese un centro de costos correcto";
      err.push(errors);
      return err;
    }
    if (isNullOrUndefined(det.CIN_CODIGO_1) || det.CIN_CODIGO_1 == "") {
      errors = "El Código, y los costos son  obligatorios";
      err.push(errors);
      return err;
    }
    if (isNullOrUndefined(det.CINF_COSTO) || det.CINF_COSTO == "") {
      errors = "El Código, y los costos son  obligatorios";
      err.push(errors);
      return err;
    }
    return err;
  }
  validar2(cabe: Prodcostfech) {
    let errors = "";
    let err = [];
    if (isNullOrUndefined(cabe.CINF_DOCUMENTO) || cabe.CINF_DOCUMENTO == "") {
      errors = "Ingrese nombre de Documento";
      err.push(errors);
      return err;
    }
    if (isNullOrUndefined(cabe.CINF_MES) || cabe.CINF_MES == "") {
      errors = "Seleccione el mes";
      err.push(errors);
      return err;
    }
    if (isNullOrUndefined(cabe.CINF_ANIO) || cabe.CINF_ANIO == "") {
      errors = "Ingrese el año";
      err.push(errors);
      return err;
    }
    if (isNullOrUndefined(cabe.CEN_CODIGO) || cabe.CEN_CODIGO == "") {
      errors = "Ingrese un centro de costos correcto";
      err.push(errors);
      return err;
    }
    return err;

  }

  async obtenerCostosFech() {
    return await this.http.post(this.way + '/produccion/costoxfecha/b36jonz0llu78f8', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async guardarcostosfech(cosfec: Prodcostfech) {
    return await this.http.post(this.way + '/produccion/costoxfecha/aq0w5sgkk2t38nw', {
      elementos: {
        P_CINF_DOCUMENTO: String(cosfec.CINF_DOCUMENTO),
        P_CINF_MES: String(cosfec.CINF_MES),
        P_CIN_CODIGO: String(cosfec.CIN_CODIGO_1),
        P_COM_CODIGO: '01',
        P_CINF_COSTO: this.cambiarFloat(cosfec.CINF_COSTO),
        P_CEN_CODIGO: String(cosfec.CEN_CODIGO),
        p_CINF_ANIO: String(cosfec.CINF_ANIO),
        p_CINF_DISTRIBUCION: String(cosfec.CINF_DISTRIBUCION === true ? 1 : 0)
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async actucostosfech(cosfec: Prodcostfech) {
    return await this.http.post(this.way + '/produccion/costoxfecha/q4v6y26pq59u0xl', {
      elementos: {
        P_CINF_DOCUMENTO: cosfec.CINF_DOCUMENTO,
        P_CINF_MES: cosfec.CINF_MES,
        P_CIN_CODIGO: cosfec.CIN_CODIGO_1,
        P_COM_CODIGO: '01',
        P_CINF_COSTO: this.cambiarFloat(cosfec.CINF_COSTO),
        P_CEN_CODIGO: cosfec.CEN_CODIGO,
        p_CINF_ANIO: String(cosfec.CINF_ANIO),
        p_CINF_DISTRIBUCION: String(cosfec.CINF_DISTRIBUCION === true ? 1 : 0)
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async elimcostosfech(cosfec: Prodcostfech) {
    return await this.http.post(this.way + '/produccion/costoxfecha/zgbpo951apnuudq', {
      elementos: {
        P_CINF_DOCUMENTO: cosfec.CINF_DOCUMENTO,
        P_COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async detelimcostosfech(cosfec: Prodcostfech) {
    return await this.http.post(this.way + '/produccion/costoxfecha/3awn8fz4a881eeg', {
      elementos: {
        P_CINF_DOCUMENTO: cosfec.CINF_DOCUMENTO,
        P_CIN_CODIGO: cosfec.CIN_CODIGO_1,
        P_COM_CODIGO: '01',
        P_CEN_CODIGO: cosfec.CEN_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  async obtenerdetcostosfech(cosfec: Prodcostfech) {
    return await this.http.post(this.way + '/produccion/costoxfecha/elpj07kywsd50r3', {
      elementos: {
        doc: cosfec.CINF_DOCUMENTO,
        mes: cosfec.CINF_MES
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  busquedaTabla1() {
    return this.http.post(this.way + '/produccion/costoxfecha/17ee315bea961', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }

  busquedaTabla2(strParametro) {
    return this.http.post(this.way + '/produccion/costoxfecha/17ee315bea962', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarRegistro1(strParametro) {
    return this.http.post(this.way + '/produccion/costoxfecha/1d7662fddba01', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  cambiarFloatFixed(valor) {
    let res = "0.00";
    let resp;
    if (isNullOrUndefined(valor)) {
      return res;
    } else {
      resp = (parseFloat(valor)).toFixed(2);
      if (isNaN(resp)) {
        return res;
      } else {
        return resp;
      }
    }
  }
  cambiarFloat(valor) {
    let res = 0.00;
    let resp;
    if (isNullOrUndefined(valor)) {
      return res;
    } else {
      resp = parseFloat(valor);
      if (isNaN(resp)) {
        return res;
      } else {
        return resp;
      }
    }
  }

}
