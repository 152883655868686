<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="vend"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarVend" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>

<div class="p-grid" style="width: 99%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
						 [botonRegresar]="btnRegresar" [barraBotonesCRUD]="false"
						 [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"
						 [barraBotones5]="true"
						 (signal)="manejarSenales($event)"></app-invmaebarra>
	</div>

</div>
<div style="overflow: hidden" class="p-col-12">
	<p-tabView [(activeIndex)]="activeIndex" (activeIndexChange)="cambioIndices()">
		<p-tabPanel header="Vendedores">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="vendedorService.venmaevendedor"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsVendedor"
							[defaultColDef]="defaultColDefVendedor"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)="seleccionVendedor($event)"
							(cambios)="cambio($event)"

			></app-aggridsaci>

		</p-tabPanel>
		<p-tabPanel header="Datos Punto de Venta">
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-2"></div>
							<div class="p-col-2">
								<input type="text" pInputText [disabled]="true"
									   [(ngModel)]="vendedorSeleccionado.VEN_CODIGO">
							</div>
							<div class="p-col-1"></div>
							<div class="p-col-5">
								<input type="text" pInputText [disabled]="true"
									   [(ngModel)]="vendedorSeleccionado.VEN_NOMBRE">
							</div>
							<div class="p-col-1"></div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -5px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-2">
								<span>Clave</span>
							</div>
							<div class="p-col-3">
								<input type="password" pInputText [(ngModel)]="vendedorSeleccionado.VEN_CLAVE"
									   (input)="cambio($event)">
							</div>
							<div class="p-col-6"></div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-2">
								<span>Confirma Clave</span>
							</div>
							<div class="p-col-3">
								<input type="password" pInputText [(ngModel)]="confirmacionClave"
									   (input)="cambio($event)">
							</div>
							<div class="p-col-6"></div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-2">
								<span>Caja</span>
							</div>
							<div class="p-col-2">
								<input type="text" pInputText [(ngModel)]="vendedorSeleccionado.CAJ_CODIGO">
							</div>
							<div class="p-col-2">
								<button class="littlebuttons"
										style="background-image: url(../../../assets/images/iconos/buscar.png)"
										(click)="abrirCajas()"></button>
							</div>
							<div class="p-col-2">
								<span style="color: blue">{{cajaNombre}}</span>
							</div>
							<div class="p-col-3"></div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-2">
								<span>Bodega</span>
							</div>
							<div class="p-col-2">
								<input type="text" pInputText [(ngModel)]="vendedorSeleccionado.BOD_CODIGO">
							</div>
							<div class="p-col-2">
								<button class="littlebuttons"
										style="background-image: url(../../../assets/images/iconos/buscar.png)"
										(click)="abrirBodegas()"></button>
							</div>
							<div class="p-col-2">
								<span style="color: blue">{{bodegaNombre}}</span>
							</div>
							<div class="p-col-3"></div>
						</div>
					</div>
				</div>
			</div>
		</p-tabPanel>
		<p-tabPanel header="Datos vendedor">
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-3"></div>
							<div class="p-col-2">
								<input type="text" pInputText [disabled]="true"
									   [(ngModel)]="vendedorSeleccionado.VEN_CODIGO">
							</div>
							<div class="p-col-1"></div>
							<div class="p-col-5">
								<input type="text" pInputText [disabled]="true"
									   [(ngModel)]="vendedorSeleccionado.VEN_NOMBRE">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -5px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-2">
								<span>Ruc/CI:</span>
							</div>
							<div class="p-col-2">
								<input type="text" pInputText [(ngModel)]="vendedorSeleccionado.VEN_IDE">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-2">
								<span>Dirección 1:</span>
							</div>
							<div class="p-col-8">
								<input type="text" pInputText [(ngModel)]="vendedorSeleccionado.VEN_DIRECCION1">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-2">
								<span>Dirección 2:</span>
							</div>
							<div class="p-col-8">
								<input type="text" pInputText [(ngModel)]="vendedorSeleccionado.VEN_DIRECCION2">
							</div>
							<div class="p-col-2"></div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -25px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-2">
								<span>Teléfono 1:</span>
							</div>
							<div class="p-col-2">
								<input type="text" pInputText [(ngModel)]="vendedorSeleccionado.VEN_TELEFONO1">
							</div>
							<div class="p-col-1"></div>
							<div class="p-col-1">
								<span>Teléfono 2:</span>
							</div>
							<div class="p-col-2 text-left">
								<input type="text" pInputText [(ngModel)]="vendedorSeleccionado.VEN_TELEFONO2">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-2">
								<span>Fax:</span>
							</div>
							<div class="p-col-2 text-left">
								<input type="text" pInputText [(ngModel)]="vendedorSeleccionado.VEN_FAX">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-2">
								<span>Cnta Contable:</span>
							</div>
							<div class="p-col-2">
								<input type="text" pInputText [(ngModel)]="vendedorSeleccionado.CON_CODIGO">
							</div>
							<div class="p-col-2">
								<button class="littlebuttons"
										style="background-image: url(../../../assets/images/iconos/buscar.png)"
										(click)="abrirCuentas()"></button>
							</div>
							<div class="p-col-2">
								<input type="text" pInputText [(ngModel)]="conNombre" [disabled]="true">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-2">
								<span>Correo Electrónico:</span>
							</div>
							<div class="p-col-8">
								<input type="text" pInputText [(ngModel)]="vendedorSeleccionado.VEN_CORREO">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-2">
								<span>Provincia:</span>
							</div>
							<div class="p-col-1">
								<input type="text" pInputText [(ngModel)]="vendedorSeleccionado.VEN_PROVINCIA">
							</div>
							<div class="p-col-2">
								<button class="littlebuttons"
										style="background-image: url(../../../assets/images/iconos/buscar.png)"
										(click)="abrirProvincias()"></button>
							</div>
							<div class="p-col-3">
								<input type="text" pInputText [(ngModel)]="provNombre" [disabled]="true">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-2">
								<span>Zona:</span>
							</div>
							<div class="p-col-1">
								<input type="text" pInputText [(ngModel)]="vendedorSeleccionado.VEN_ZONA">
							</div>
							<div class="p-col-2">
								<button class="littlebuttons"
										style="background-image: url(../../../assets/images/iconos/buscar.png)"
										(click)="abrirZonas()"></button>
							</div>
							<div class="p-col-3">
								<input type="text" pInputText [(ngModel)]="zonaNombre" [disabled]="true">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-2">
								<span>Ciudad:</span>
							</div>
							<div class="p-col-1">
								<input type="text" pInputText [(ngModel)]="vendedorSeleccionado.CIU_CODIGO">
							</div>
							<div class="p-col-2">
								<button class="littlebuttons"
										style="background-image: url(../../../assets/images/iconos/buscar.png)"
										(click)="abrirCiudades()"></button>
							</div>
							<div class="p-col-3">
								<input type="text" pInputText [(ngModel)]="ciuNombre" [disabled]="true">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-2">
								<span>Ingreso:</span>
							</div>
							<div class="p-col-2">
								<input type="date" pInputText [(ngModel)]="vendedorSeleccionado.VEN_FECHAINGRESO">
							</div>
							<div class="p-col-1"></div>
							<div class="p-col-1">
								<span>Nacimiento:</span>
							</div>
							<div class="p-col-2">
								<input type="date" pInputText [(ngModel)]="vendedorSeleccionado.VEN_FECHANACIMIENTO">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-2">
								<span>Transporte:</span>
							</div>
							<div class="p-col-6 text-left">
								<input type="text" pInputText [(ngModel)]="vendedorSeleccionado.VEN_TRANSPORTE">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-2">
								<span>Observaciones:</span>
							</div>
							<div class="p-col-8">
								<input type="text" pInputText [(ngModel)]="vendedorSeleccionado.VEN_OBSERVACIONES">
							</div>
						</div>
					</div>
				</div>
			</div>
		</p-tabPanel>
		<p-tabPanel header="Rango de Comisiones">
			<div class="p-grid">
				<div class="p-col-12"></div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-1"></div>
						<div class="p-col-2 text-left">
							<input type="text" pInputText [disabled]="true"
								   [(ngModel)]="vendedorSeleccionado.VEN_CODIGO">
						</div>
						<div class="p-col-1"></div>
						<div class="p-col-5 text-left">
							<input type="text" pInputText [disabled]="true"
								   [(ngModel)]="vendedorSeleccionado.VEN_NOMBRE">
						</div>
						<div class="p-col-2"></div>
					</div>
				</div>
				<div class="p-col-12"></div>
			</div>
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="vendedorService.venmaevendedorCom"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsVendedorComisiones"
							[defaultColDef]="defaultColDefVendedorComisiones"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)="seleccionVendedorCom($event)"
							(cambios)="cambio($event)"
			></app-aggridsaci>
		</p-tabPanel>
		<p-tabPanel header="Precios de vendedor">
			<div class="p-grid">
				<div class="p-col-12"></div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-1"></div>
						<div class="p-col-2 text-left">
							<input type="text" pInputText [disabled]="true"
								   [(ngModel)]="vendedorSeleccionado.VEN_CODIGO">
						</div>
						<div class="p-col-1"></div>
						<div class="p-col-5 text-left">
							<input type="text" pInputText [disabled]="true"
								   [(ngModel)]="vendedorSeleccionado.VEN_NOMBRE">
						</div>
						<div class="p-col-2"></div>
					</div>
				</div>
				<div class="p-col-12"></div>
			</div>
			<app-aggridsaci *ngIf="opcionesprecio.length !== 0" [width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="vendedorService.venmaepreciovend"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsVendedorPrecio"
							[defaultColDef]="defaultColDefVendedorPrecio"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)="seleccionVendedorPrecio($event)"
							(cambios)="cambio($event)"

			></app-aggridsaci>
		</p-tabPanel>
	</p-tabView>
</div>
<p-dialog header="Búsqueda de datos" [(visible)]="displayDialogBus" [responsive]="true"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'}">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloBus"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>
