export class nomliqucabecera {
    ASI_NRO:any; 
    COM_CODIGO:any; 
    CON_COMCODIGO:any; 
    ENCLIQUBENESOCI_CODIGO:any; 
    ENCLIQUBENESOCI_ESTADO:any; 
    ENCLIQUBENESOCI_FECCNFR:any; 
    ENCLIQUBENESOCI_FECFIN:any; 
    ENCLIQUBENESOCI_FECFINPROV:any; 
    ENCLIQUBENESOCI_FECINI:any; 
    ENCLIQUBENESOCI_FECINIPROV:any; 
    ENCLIQUBENESOCI_FECREVERSO:any; 
    ENCLIQUBENESOCI_SALABASIUNIF:any; 
    MAERUBRO_CODIGO:any; 
    MAERUBRO_CODRUBR:any; 
    PERIODO_CODIGO:any; 
    PERIODO_FECFIN:any; 
    PERIODO_FECINI:any; 
    USU_IDENTIFICACION:any; 
    descRubro:any;
}
export class nomliqudetalle  {
    ETLIQUBENESOCI_ACUMBASPRPAG?;
    DETLIQUBENESOCI_ACUMBASPRTOT?;
    DETLIQUBENESOCI_ACUMDIAPAGA?;
    DETLIQUBENESOCI_ACUMDIAPRTOT?;
    DETLIQUBENESOCI_ACUMHORAPROV?;
    DETLIQUBENESOCI_ACUMVALPRPAG?;
    DETLIQUBENESOCI_ACUMVALPRTOT?;
    DETLIQUBENESOCI_AJUSPROV?;
    DETLIQUBENESOCI_ANTIPROV?;
    DETLIQUBENESOCI_BANCPAGOCOD?;
    DETLIQUBENESOCI_BANCPAGODESC?;
    DETLIQUBENESOCI_CARGIESSCOD?;
    DETLIQUBENESOCI_CARGIESSDESC?;
    DETLIQUBENESOCI_DESCPROV?;
    DETLIQUBENESOCI_DESCRETEJUDI?;
    DETLIQUBENESOCI_FORMPAGOCOD?;
    DETLIQUBENESOCI_FORMPAGODESC?;
    DETLIQUBENESOCI_HORATIEMPARC?;
    DETLIQUBENESOCI_NETOAPAGAR?;
    DETLIQUBENESOCI_NUMECUEN?;
    DETLIQUBENESOCI_NUMSEC?;
    DETLIQUBENESOCI_ORDEN?;
    DETLIQUBENESOCI_PORCDISC?;
    DETLIQUBENESOCI_SEXO?;
    DETLIQUBENESOCI_TIPOCUENCOD?;
    DETLIQUBENESOCI_TIPOCUENDESC?;
    DETLIQUBENESOCI_TRABJORNPARC?;
    DETLIQUBENESOCI_ACUMBASPRPAG?;
    EMP_CODIGO?;
    EMP_NOMBRE?;
    EMP_APELLIDO?;
    EMP_CODIGO2?;
    TRNBENESOCI_CODIGO?;
    dblTOTA_APAGAR?;
    NOMBRE?:String;
    NUMCAB?:number;
    AJUSTE_PROV?;
    NUEVO?;
}
export class nomliquvarios  {
    CEN_CODIGO?;
    COM_CODIGO?;
    CON_CODIGO?;
    CON_COMCODIGO?;
    DETLIQUBENSOCVAR_DEBE?;
    DETLIQUBENSOCVAR_DESCRIPCION?;
    DETLIQUBENSOCVAR_HABER?;
    DETLIQUBENSOCVAR_NUMSEC?;
    DETLIQUBENSOCVAR_ORDEN?;
    ENCLIQUBENESOCI_CODIGO?;
    NUMLIQV?:number;
}