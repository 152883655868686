import { Component, OnInit, ViewChild } from '@angular/core';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {ConfirmationService, MessageService} from 'primeng';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import { NommaerubroService } from '../nomservicios/nommaerubro.service';
import { AggridBanComponent } from 'src/app/ban/aggrid-ban/aggrid-ban.component';
import { nommaeRubro, nommaeRuNom } from '../nominterfaces/nommaerubro';
import {DatePipe} from '@angular/common';
import { PermisosService } from 'src/app/advantage/advantageservices/permisos.service';
import { SegMaePermiso } from 'src/app/advantage/advantageinterfaces/segmaepermiso';
import { Usuario } from 'src/app/usuario';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
@Component({
  selector: 'app-nommaerubro',
  templateUrl: './nommaerubro.component.html',
  styleUrls: ['./nommaerubro.component.css']
})
export class NommaerubroComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnGuardar: boolean;
  btnNuevo: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  rubronames:nommaeRuNom[]=[];
  str_nomRol1:string="";
  str_nomRol2:string="";
  str_nomRol3:string="";
  str_nomRol4:string="";
  str_nomRol5:string="";
  str_nomRol6:string="";
  str_nomRol7:string="";
  str_nomRol8:string="";
  str_nomRol9:string="";
  str_nomRol10:string="";
  tablarabol=false;
  defaultColDefNumMaeRub;
  public frameworkComponents;
  rowStyle;
  largo = '258';
  rowSelection = 'multiple';
  selectedNomMaeRub:nommaeRubro;
  permisoRubro: SegMaePermiso;
  columnDefNomMaeRub;
  
   constructor(   private messageService: MessageService,
    private init: NuevoComponentService,
    private errorService: ErroresBaseDatosService,
    public  nonmaeRub: NommaerubroService,
    private datePipe: DatePipe,
    public usuario: Usuario,
    private permisosService: PermisosService,
    private auditoriaGralService: AuditoriagralService,
    private confirmationService: ConfirmationService) {
    this.btnNuevo =  false;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.btnRegresar = false;
    
    this.agTable();
  
  }
 
  async ngOnInit() {
    await this.nombresTables(); 
    await this.nonmaeRub.selectRubros().then(res=>{
      console.log(res);
      this. nonmaeRub.nommaeRubro=res;
      let a=0;
      this. nonmaeRub.nommaeRubro.map(x=>{
        x.MAERUBRO_CODIGO=(x.MAERUBRO_CODIGO==null)?"":x.MAERUBRO_CODIGO;
        x.MAERUBRO_TIPO=(x.MAERUBRO_TIPO==null)?"":x.MAERUBRO_TIPO;
        x.MAERUBRO_CODRUBR=(x.MAERUBRO_CODRUBR==null)?"":x.MAERUBRO_CODRUBR;
        x.MAERUBRO_NOMBRE=(x.MAERUBRO_NOMBRE==null)?"":x.MAERUBRO_NOMBRE;
        x.MAERUBRO_UNIDAD=(x.MAERUBRO_UNIDAD==null)?"":x.MAERUBRO_UNIDAD;

        x.MAERUBRO_CALCULA= (x.MAERUBRO_CALCULA=='Si')?true:false;
        x.MAERUBRO_GRAVABLE= (x.MAERUBRO_GRAVABLE=='Si')?true:false;
        x.MAERUBRO_APORRENT= (x.MAERUBRO_APORRENT=='Si')?true:false;
        x.MAERUBRO_PROYRENT= (x.MAERUBRO_PROYRENT=='Si')?true:false;

        x.CON_CODIGO=(x.CON_CODIGO==null)?"":x.CON_CODIGO;
        x.CON_CODIGO_2=(x.CON_CODIGO_2==null)?"":x.CON_CODIGO_2;
        x.CON_CODIGO_3=(x.CON_CODIGO_3==null)?"":x.CON_CODIGO_3;
        x.CON_CODIGO_4=(x.CON_CODIGO_4==null)?"":x.CON_CODIGO_4;
        
        x.CON_CODIGO_5=(x.CON_CODIGO_5==null)?"":x.CON_CODIGO_5;
        x.CON_CODIGO_6=(x.CON_CODIGO_6==null)?"":x.CON_CODIGO_6;
        x.CON_CODIGO_7=(x.CON_CODIGO_7==null)?"":x.CON_CODIGO_7;
        x.CON_CODIGO_8=(x.CON_CODIGO_8==null)?"":x.CON_CODIGO_8;
        x.CON_CODIGO_9=(x.CON_CODIGO_9==null)?"":x.CON_CODIGO_9;
        x.CON_CODIGO_10=(x.CON_CODIGO_10==null)?"":x.CON_CODIGO_10;
        
        x.CON_CODIGOHABE=(x.CON_CODIGOHABE==null)?"":x.CON_CODIGOHABE;
        x.CON_CODIGOHABE_2=(x.CON_CODIGOHABE_2==null)?"":x.CON_CODIGOHABE_2;
        x.CON_CODIGOHABE_3=(x.CON_CODIGOHABE_3==null)?"":x.CON_CODIGOHABE_3;
        x.CON_CODIGOHABE_4=(x.CON_CODIGOHABE_4==null)?"":x.CON_CODIGOHABE_4;

         x.CON_CODIGOHABE_5=(x.CON_CODIGOHABE_5==null)?"":x.CON_CODIGOHABE_5;
        x.CON_CODIGOHABE_6=(x.CON_CODIGOHABE_6==null)?"":x.CON_CODIGOHABE_6;
        x.CON_CODIGOHABE_7=(x.CON_CODIGOHABE_7==null)?"":x.CON_CODIGOHABE_7;
        x.CON_CODIGOHABE_8=(x.CON_CODIGOHABE_8==null)?"":x.CON_CODIGOHABE_8;
        x.CON_CODIGOHABE_9=(x.CON_CODIGOHABE_9==null)?"":x.CON_CODIGOHABE_9;
        x.CON_CODIGOHABE_10=(x.CON_CODIGOHABE_10==null)?"":x.CON_CODIGOHABE_10;  
        
        if(x.MAERUBRO_FECFIN==null || x.MAERUBRO_FECFIN==undefined){
          x.MAERUBRO_FECFIN='';
        }else{
          if(x.MAERUBRO_FECFIN.includes('-')){
            x.MAERUBRO_FECFIN=this.datePipe.transform(x.MAERUBRO_FECFIN, 'dd/MM/yyyy');
          }else{
            x.MAERUBRO_FECFIN='';
          }
        } 
        if(x.MAERUBRO_FECINI==null || x.MAERUBRO_FECINI==undefined){
          x.MAERUBRO_FECINI='';
        }else{
          if(x.MAERUBRO_FECINI.includes('-')){
            x.MAERUBRO_FECINI=this.datePipe.transform(x.MAERUBRO_FECINI, 'dd/MM/yyyy');
          }else{
            x.MAERUBRO_FECINI='';
          }
        } ;
        x.MAERUBRO_TIPOORDE=(x.MAERUBRO_TIPOORDE==null)?"":x.MAERUBRO_TIPOORDE;
        x.MAERUBRO_RUBRORDE=(x.MAERUBRO_RUBRORDE==null)?"":x.MAERUBRO_RUBRORDE;
        x.NUEVO=false;
        x.NUMRUB=a;
        a++;
        console.log(a);
      })
    });
    this.columnDefNomMaeRub=[
      {
        headerName: 'Tipo', editable: true, field: 'MAERUBRO_TIPO', cellEditor: 'cellPrueba', width: 150,pinned: 'left', 
        cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 2,
              obligatorio: true,
              fecha: false,
              tamanocelda: 10,
              tienecalculos: false,
              opcionbuscar: true
            }
          }
      },
      {
        headerName: 'Código', editable: true, field: 'MAERUBRO_CODRUBR', cellEditor: 'cellPrueba', width: 80,pinned: 'left',  
        cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 2,
              obligatorio: true,
              fecha: false,
              tamanocelda: 5,
              tienecalculos: false,
            }
          }
      },
      {
        headerName: 'Nombre', editable: true, field: 'MAERUBRO_NOMBRE', cellEditor: 'cellPrueba', width: 150, pinned: 'left', 
        cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 2,
              obligatorio: true,
              fecha: false,
              tamanocelda: 80,
              tienecalculos: false,
            }
          }
      },
      {
        headerName: 'Calculado', field: 'MAERUBRO_CALCULA', cellRendererFramework: AggridBanComponent, editable:false, width: 100, onCellClicked: (params) => {
          this.seleccionItem(params);  
        }
      },
      {
        headerName: 'Unidad', field: 'MAERUBRO_UNIDAD', editable: true, cellEditor: 'cellPrueba', width: 150, 
        cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 2,
              obligatorio: true,
              fecha: false,
              tamanocelda: 10,
              tienecalculos: false,
              opcionbuscar: true
            }
          }
      },
    
      {
        headerName: 'Grava Iess', field: 'MAERUBRO_GRAVABLE',cellRendererFramework: AggridBanComponent, editable:false, width: 100, onCellClicked: (params) => {
          this.seleccionItem(params);  
        }
      },
      {
        headerName: 'Aportable Renta', field: 'MAERUBRO_APORRENT', cellRendererFramework: AggridBanComponent, editable:false, width: 100, onCellClicked: (params) => {
          this.seleccionItem(params);  
        }
      },
      {
        headerName: 'Proyectable de Renta', field: 'MAERUBRO_PROYRENT',cellRendererFramework: AggridBanComponent, editable:false, width: 100, onCellClicked: (params) => {
          this.seleccionItem(params);  
        }
      },
      {
        headerName: `Cta Contable ${this.str_nomRol1}`, field: 'CON_CODIGO',editable: true, cellEditor: 'cellPrueba', width: 150, 
        cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 2,
              obligatorio: true,
              fecha: false,
              tamanocelda: 35,
              tienecalculos: false,
              opcionbuscar: true
            }
          }
      },
      {
        headerName: `Cta Contable ${this.str_nomRol2}`, field: 'CON_CODIGO_2',editable: true, cellEditor: 'cellPrueba', width: 150,  
        cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 2,
              obligatorio: true,
              fecha: false,
              tamanocelda: 35,
              tienecalculos: false,
              opcionbuscar: true
            }
          }
      },
      {
        headerName: `Cta Contable ${this.str_nomRol3}`, field: 'CON_CODIGO_3',editable: true, cellEditor: 'cellPrueba', width: 150,  
        cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 2,
              obligatorio: true,
              fecha: false,
              tamanocelda: 35,
              tienecalculos: false,
              opcionbuscar: true
            }
          }
      },
      {
        headerName: `Cta Contable ${this.str_nomRol4}`, field: 'CON_CODIGO_4',editable: true, cellEditor: 'cellPrueba', width: 150, 
        cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 2,
              obligatorio: true,
              fecha: false,
              tamanocelda: 35,
              tienecalculos: false,
              opcionbuscar: true
            }
          }
      },
      {
        headerName: `Cta Contable ${this.str_nomRol5}`, field: 'CON_CODIGO_5', editable: true, cellEditor: 'cellPrueba', width: 150,  
        cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 2,
              obligatorio: true,
              fecha: false,
              tamanocelda: 35,
              tienecalculos: false,
              opcionbuscar: true
            }
          }
      },
      {
        headerName: `Cta Contable ${this.str_nomRol6}`, field: 'CON_CODIGO_6',editable: true, cellEditor: 'cellPrueba', width: 150, 
        cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 2,
              obligatorio: true,
              fecha: false,
              tamanocelda: 35,
              tienecalculos: false,
              opcionbuscar: true
            }
          }
      },
      {
        headerName: `Cta Contable ${this.str_nomRol7}`, field: 'CON_CODIGO_7', editable: true, cellEditor: 'cellPrueba', width: 150,  
        cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 2,
              obligatorio: true,
              fecha: false,
              tamanocelda: 35,
              tienecalculos: false,
              opcionbuscar: true
            }
          }
      },
      {
        headerName: `Cta Contable Haber Provisión ${this.str_nomRol1}`, field: 'CON_CODIGOHABE', editable: true , cellEditor: 'cellPrueba', width: 150,  
        cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: true,
              saltoslinea: 1,
              newrow: true,
              numerodecimales: 2,
              obligatorio: true,
              fecha: false,
              tamanocelda: 35,
              tienecalculos: false,
              opcionbuscar: true
            }
          }
      },
      {
        headerName:  `Cta Contable Haber Provisión ${this.str_nomRol2}`, field: 'CON_CODIGOHABE_2', editable: true, cellEditor: 'cellPrueba', width: 150, 
        cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 2,
              obligatorio: true,
              fecha: false,
              tamanocelda: 35,
              tienecalculos: false,
              opcionbuscar: true
            }
          }
      },
      {
        headerName: `Cta Contable Haber Provisión ${this.str_nomRol3}`, field: 'CON_CODIGOHABE_3', editable: true, cellEditor: 'cellPrueba', width: 150,  
        cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 2,
              obligatorio: true,
              fecha: false,
              tamanocelda: 35,
              tienecalculos: false,
              opcionbuscar: true
            }
          }
      },
      {
        headerName:  `Cta Contable Haber Provisión ${this.str_nomRol4}`, field: 'CON_CODIGOHABE_4', editable: true, cellEditor: 'cellPrueba', width: 150,  
        cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 2,
              obligatorio: true,
              fecha: false,
              tamanocelda: 35,
              tienecalculos: false,
              opcionbuscar: true
            }
          }
      },
      {
        headerName:  `Cta Contable Haber Provisión ${this.str_nomRol5}`, field: 'CON_CODIGOHABE_5', editable: true, cellEditor: 'cellPrueba', width: 150, 
        cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic:true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 2,
              obligatorio: true,
              fecha: false,
              tamanocelda: 35,
              tienecalculos: false,
              opcionbuscar: true
            }
          }
      },
      {
        headerName:  `Cta Contable Haber Provisión ${this.str_nomRol6}`, field: 'CON_CODIGOHABE_6', editable: true, cellEditor: 'cellPrueba', width: 150, 
        cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 2,
              obligatorio: true,
              fecha: false,
              tamanocelda: 35,
              tienecalculos: false,
              opcionbuscar: true
            }
          }
      },
      {
        headerName:  `Cta Contable Haber Provisión ${this.str_nomRol7}`, field: 'CON_CODIGOHABE_7', editable: true, cellEditor: 'cellPrueba', width: 150,  
        cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 2,
              obligatorio: true,
              fecha: false,
              tamanocelda: 35,
              tienecalculos: false,
              opcionbuscar: true
            }
          }
      },
      {
        headerName: 'Fecha Inicio Provisión', field: 'MAERUBRO_FECINI', editable: true, cellEditor: 'cellPrueba', width: 150,  
        cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: false,
              alphabetic: true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 0,
              obligatorio: true,
              fecha: true,
              tamanocelda: 20,
              tienecalculos: true,
              
            }
          }
      },
      {
        headerName: 'Fecha Final Provisión', field: 'MAERUBRO_FECFIN', editable: true, cellEditor: 'cellPrueba', width: 150, 
        cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: false,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 0,
              obligatorio: true,
              fecha: true,
              tamanocelda: 20,
              tienecalculos: true,
            }
          }
      },
      
      {
        headerName: 'Orden', field: 'MAERUBRO_RUBRORDE', cellEditor: 'cellPrueba', editable: true, width: 150,  
        cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic:false,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 0,
              obligatorio: true,
              fecha: false,
              tamanocelda: 20,
              tienecalculos: true,
            }
          }
      },
    ]
    ;
    this.tablarabol=true;
    console.log(this.nonmaeRub.nommaeRubro);
    setTimeout(() => {
      this.aggrid.refreshaggrid( this.nonmaeRub.nommaeRubro, this.defaultColDefNumMaeRub.objeto );
    
    }, 800);       
    
    
  }

  async nombresTables(){
    await this.nonmaeRub.selectDiseñoTable().then(res=>{
      console.log(res);
      this.rubronames=res;
    try{
      for(let item of this.rubronames){
        
        switch(item.TIPROL_CODIGO){
          case '01':
           this.str_nomRol1=item.TIPROL_NOMBRE;
           break;
         case '02':
           this.str_nomRol2=item.TIPROL_NOMBRE;
           break;
         case '03':
           this.str_nomRol3=item.TIPROL_NOMBRE ////
           break;
         case '04':
           this.str_nomRol4=item.TIPROL_NOMBRE;
           break;
         case '05':
           this.str_nomRol5=item.TIPROL_NOMBRE;
           break;
         case '06':
           this.str_nomRol6=item.TIPROL_NOMBRE;
           break;
         case '07':
           this.str_nomRol7=item.TIPROL_NOMBRE;
           break;
         case '08':
           this.str_nomRol8=item.TIPROL_NOMBRE;
           break;
         case '09':  
           this.str_nomRol9=item.TIPROL_NOMBRE; 
           break;
         case '10':   
           this.str_nomRol10=item.TIPROL_NOMBRE;
           break;  
        };
      }
        
      
      
    }catch(err){

    }
      
    });
  }
  seleccionItem(param){
    this.selectedNomMaeRub[param.column.colId]=!this.selectedNomMaeRub[param.column.colId];
    this.btnNuevo =  true;
    this.btnGuardar = false;
    this.btnBorrar  = false;
    this.btnRegresar = false;
  }
  async cargar(){
    this.btnNuevo =  false;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.btnRegresar = false;
    
    await this.nonmaeRub.selectRubros().then(res=>{
      console.log(res);

      this. nonmaeRub.nommaeRubro=res;
      let a=0;
      this. nonmaeRub.nommaeRubro.map(x=>{
        x.MAERUBRO_CODIGO=(x.MAERUBRO_CODIGO==null)?"":x.MAERUBRO_CODIGO;
        x.MAERUBRO_TIPO=(x.MAERUBRO_TIPO==null)?"":x.MAERUBRO_TIPO;
        x.MAERUBRO_CODRUBR=(x.MAERUBRO_CODRUBR==null)?"":x.MAERUBRO_CODRUBR;
        x.MAERUBRO_NOMBRE=(x.MAERUBRO_NOMBRE==null)?"":x.MAERUBRO_NOMBRE;
        x.MAERUBRO_UNIDAD=(x.MAERUBRO_UNIDAD==null)?"":x.MAERUBRO_UNIDAD;

        x.MAERUBRO_CALCULA= (x.MAERUBRO_CALCULA=='Si')?true:false;
        x.MAERUBRO_GRAVABLE= (x.MAERUBRO_GRAVABLE=='Si')?true:false;
        x.MAERUBRO_APORRENT= (x.MAERUBRO_APORRENT=='Si')?true:false;
        x.MAERUBRO_PROYRENT= (x.MAERUBRO_PROYRENT=='Si')?true:false;

        x.CON_CODIGO=(x.CON_CODIGO==null)?"":x.CON_CODIGO;
        x.CON_CODIGO_2=(x.CON_CODIGO_2==null)?"":x.CON_CODIGO_2;
        x.CON_CODIGO_3=(x.CON_CODIGO_3==null)?"":x.CON_CODIGO_3;
        x.CON_CODIGO_4=(x.CON_CODIGO_4==null)?"":x.CON_CODIGO_4;
        
        x.CON_CODIGO_5=(x.CON_CODIGO_5==null)?"":x.CON_CODIGO_5;
        x.CON_CODIGO_6=(x.CON_CODIGO_6==null)?"":x.CON_CODIGO_6;
        x.CON_CODIGO_7=(x.CON_CODIGO_7==null)?"":x.CON_CODIGO_7;
        x.CON_CODIGO_8=(x.CON_CODIGO_8==null)?"":x.CON_CODIGO_8;
        x.CON_CODIGO_9=(x.CON_CODIGO_9==null)?"":x.CON_CODIGO_9;
        x.CON_CODIGO_10=(x.CON_CODIGO_10==null)?"":x.CON_CODIGO_10;
        
        x.CON_CODIGOHABE=(x.CON_CODIGOHABE==null)?"":x.CON_CODIGOHABE;
        x.CON_CODIGOHABE_2=(x.CON_CODIGOHABE_2==null)?"":x.CON_CODIGOHABE_2;
        x.CON_CODIGOHABE_3=(x.CON_CODIGOHABE_3==null)?"":x.CON_CODIGOHABE_3;
        x.CON_CODIGOHABE_4=(x.CON_CODIGOHABE_4==null)?"":x.CON_CODIGOHABE_4;

         x.CON_CODIGOHABE_5=(x.CON_CODIGOHABE_5==null)?"":x.CON_CODIGOHABE_5;
        x.CON_CODIGOHABE_6=(x.CON_CODIGOHABE_6==null)?"":x.CON_CODIGOHABE_6;
        x.CON_CODIGOHABE_7=(x.CON_CODIGOHABE_7==null)?"":x.CON_CODIGOHABE_7;
        x.CON_CODIGOHABE_8=(x.CON_CODIGOHABE_8==null)?"":x.CON_CODIGOHABE_8;
        x.CON_CODIGOHABE_9=(x.CON_CODIGOHABE_9==null)?"":x.CON_CODIGOHABE_9;
        x.CON_CODIGOHABE_10=(x.CON_CODIGOHABE_10==null)?"":x.CON_CODIGOHABE_10;  
        
        if(x.MAERUBRO_FECFIN==null || x.MAERUBRO_FECFIN==undefined){
          x.MAERUBRO_FECFIN='';
        }else{
          if(x.MAERUBRO_FECFIN.includes('-')){
            x.MAERUBRO_FECFIN=this.datePipe.transform(x.MAERUBRO_FECFIN, 'dd/MM/yyyy');
          }else{
            x.MAERUBRO_FECFIN='';
          }
        } 
        if(x.MAERUBRO_FECINI==null || x.MAERUBRO_FECINI==undefined){
          x.MAERUBRO_FECINI='';
        }else{
          if(x.MAERUBRO_FECINI.includes('-')){
            x.MAERUBRO_FECINI=this.datePipe.transform(x.MAERUBRO_FECINI, 'dd/MM/yyyy');
          }else{
            x.MAERUBRO_FECINI='';
          }
        } ;
        x.MAERUBRO_TIPOORDE=(x.MAERUBRO_TIPOORDE==null)?"":x.MAERUBRO_TIPOORDE;
        x.MAERUBRO_RUBRORDE=(x.MAERUBRO_RUBRORDE==null)?"":x.MAERUBRO_RUBRORDE;
        x.NUEVO=false;
        x.NUMRUB=a;
        a++;
      })
    });
  }
  manejarSenales(valor) {
    switch (valor) {
      case 'Nuevo':
        this.opcionNuevo();
        break;
      case 'Guardar':
        this.opcionGuardar();
        break;
      case 'Borrar':
        this.opcionBorrar();
        break;
      case 'Cancelar':
        this.cargar();
        break;
      case 'Salir':
        this.init.tabs.splice(  this.init.tabindex, 1);
        break;
      
    }
    
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefNumMaeRub = {
      editable: true,
      width: 130,
      objeto: 'nomMaeRubros'
    };
  }
  selectNomMaeRub(elemento){
  
    if (elemento.object !== null) {
      console.log(elemento.object)
      this.selectedNomMaeRub = elemento.object;
     
    }
  }
  cambio($event){
    this.btnGuardar = false;
    this.btnRegresar = false;
    /* if (this.selected_TablitaRet.nuevo === undefined) {
      this.selected_TablitaRet.editado = true;
    } */
  }
  async opcionNuevo(){
    this.btnNuevo = true;
    this.btnBorrar = false;
    this.btnRegresar = false;
    this.aggrid.isEditable(true);
    await this.getPermisos();
      console.log(this.permisoRubro);
      if (this.permisoRubro != null) {
        if (this.permisoRubro.PERTODO === 0) {
          if (this.permisoRubro.PERSELECCION === 0) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ' , No posee el permiso de inserción. Consulte con el Administrador del Sistema. ');
            return;
          }
        }
      }
      const newModulo:  nommaeRubro= {  
        COM_CODIGO:'',
        CON_CODIGO:'',
        CON_CODIGOHABE:'',
        CON_CODIGOHABE_2:'',
        CON_CODIGOHABE_3:'',
        CON_CODIGOHABE_4:'',
        CON_CODIGOHABE_5:'',
        CON_CODIGOHABE_6:'',
        CON_CODIGOHABE_7:'',
        CON_CODIGOHABE_8:'',
        CON_CODIGOHABE_9:'',
        CON_CODIGOHABE_10:'',
        CON_CODIGO_2:'',
        CON_CODIGO_3:'',
        CON_CODIGO_4:'',
        CON_CODIGO_5:'',
        CON_CODIGO_6:'',
        CON_CODIGO_7:'',
        CON_CODIGO_8:'',
        CON_CODIGO_9:'',
        CON_CODIGO_10:'',
        CON_COMCODIGO:'',
        MAECONCPOTREGRE_CODIGO:'',
        MAERUBRO_ACTIVO:'',
        MAERUBRO_APORRENT:false,
        MAERUBRO_CALCULA:false,
        MAERUBRO_GRAVABLE:false,
        MAERUBRO_PROYRENT:false,
        MAERUBRO_CODIGO:'',
        MAERUBRO_CODRUBR:'',
        MAERUBRO_FECFIN:'',
        MAERUBRO_FECINI:'', 
        MAERUBRO_NOMBRE:'',
        MAERUBRO_NOVEDAD:'',       
        MAERUBRO_RUBRORDE:'',
        MAERUBRO_TIPO:'',
        MAERUBRO_TIPOORDE:'',
        MAERUBRO_UNIDAD:'',
        NUEVO:true,
        NUMRUB:this.nonmaeRub.nommaeRubro.length
    };
    try{
      this.nonmaeRub.nommaeRubro.push(newModulo); 
      this.aggrid.refreshaggrid(this.nonmaeRub.nommaeRubro, this.defaultColDefNumMaeRub.objeto ); 
      const indice= this.nonmaeRub.nommaeRubro.indexOf(newModulo);
      this.aggrid.gridApi.setFocusedCell(indice , 'MAERUBRO_TIPO');
    }catch(err){
      console.log(err)
    }
  
  }
  async opcionGuardar(){
    await this.getPermisos();
    
    if (this.permisoRubro != null) {
      if (this.permisoRubro.PERTODO === 0) {
        if (this.permisoRubro.PERSELECCION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , No posee el permiso de inserción. Consulte con el Administrador del Sistema. ');
          return;
        }
      }
    }
    const modulo: nommaeRubro = {
      MAERUBRO_TIPO:this.selectedNomMaeRub.MAERUBRO_TIPO,
      MAERUBRO_CODRUBR:this.selectedNomMaeRub.MAERUBRO_CODRUBR,
      MAERUBRO_NOMBRE:this.selectedNomMaeRub.MAERUBRO_NOMBRE
     };
     let valido = true;
    
  for ( const key in modulo ) { 
    if ( modulo[key] === null || modulo[key] === '') {
      valido = false;
    }
  }
  if ( valido ) {
    this.aggrid.gridApi.stopEditing();
    let val=(this.selectedNomMaeRub.MAERUBRO_CODIGO!=''||this.selectedNomMaeRub.MAERUBRO_CODIGO!=null)?'A':'I';
    this.nonmaeRub.guardarRubro(this.selectedNomMaeRub).then( async res=>{
      this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
      
      await this.auditoriaGralService.registrarAuditoria('nom_maerubro', this.selectedNomMaeRub.MAERUBRO_CODIGO, val,
          '', '01', '', '', '', '').toPromise();
              //CDPJ
      await this.auditoriaGralService.registrarAuditoria('nom_maerubro', this.selectedNomMaeRub.MAERUBRO_CODIGO+'/'+this.selectedNomMaeRub.MAERUBRO_CODRUBR
      +'CntaC'+this.selectedNomMaeRub.CON_CODIGO+'/'+this.selectedNomMaeRub.CON_CODIGO_2+'/'+this.selectedNomMaeRub.CON_CODIGO_3
      +'/'+this.selectedNomMaeRub.CON_CODIGO_4+'/'+this.selectedNomMaeRub.CON_CODIGO_5+'/'+this.selectedNomMaeRub.CON_CODIGO_6
      +'/'+this.selectedNomMaeRub.CON_CODIGO_7+'/'+this.selectedNomMaeRub.CON_CODIGO_8+'/'+this.selectedNomMaeRub.CON_CODIGO_9
      +'/'+this.selectedNomMaeRub.CON_CODIGO_10
      , val,
          '', '01', '', '', '', '').toPromise();
      await this.auditoriaGralService.registrarAuditoria('nom_maerubro', this.selectedNomMaeRub.MAERUBRO_CODIGO
      +'ChkR'+this.selectedNomMaeRub.MAERUBRO_CALCULA, val,
          '', '01', '', '', '', '').toPromise();
      await this.auditoriaGralService.registrarAuditoria('nom_maerubro', this.selectedNomMaeRub.MAERUBRO_CODIGO
      +'ChkGr'+this.selectedNomMaeRub.MAERUBRO_GRAVABLE, val,
          '', '01', '', '', '', '').toPromise();
          //CDPJ
      this.cargar();
    }).catch(e=>{
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    });  
  }else{
    this.mensajeAlerta('info', 'Información', 'Se han encontrado campos vacíos');
  }
  }
  async opcionBorrar(){
    await this.getPermisos();
    console.log(this.permisoRubro);
    if (this.permisoRubro != null) {
      if (this.permisoRubro.PERTODO === 0) {
        if (this.permisoRubro.PERSELECCION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , No posee el permiso de inserción. Consulte con el Administrador del Sistema. ');
          return;
        }
      }
    }
    if ( this.selectedNomMaeRub !== undefined ) {
      this.confirmationService.confirm({
        message: '¿Está seguro que desea eliminar?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'NomMaeTabRetDialog',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {
          this.nonmaeRub.eliminarRubro(this.selectedNomMaeRub.MAERUBRO_CODIGO).then(res=>{
            this.mensajeAlerta('success', 'Información', '¡Registro eliminado!');
            this.cargar();
            this.auditoriaGralService.registrarAuditoria('nom_maerubro', this.selectedNomMaeRub.MAERUBRO_CODIGO, 'E',
          '', '01', '', '', '', '').toPromise().then(() => {
          });
          }).catch(e=>{
            let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
          })
        },
        reject: () => {
        }
      })
    }
  }
  
  async getPermisos() {
    try {
      const data = await this.permisosService.getPermisosFrm('frmNOM_MAERUBRO');
      if (data !== null || data.length > 0) {
        if (data[0] !== undefined) {
          this.permisoRubro = data[0];
        } else {
          this.permisoRubro = null;
        }
      } else {
        this.permisoRubro = null;
      }
    } catch (e) {
      this.permisoRubro= null;
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Error',
        detail: 'No se pudo obtener los permisos del formulario'
      });
    }
  }
  mensajeAlerta(tipo, titulo, msg) {
    this. messageService.add({
      key: 'maeRubrotoast',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
}
