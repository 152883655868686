<p-toast [style]="{marginTop: '30px'}" position="top-center" key="importExcel"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="importDatosExc" title="Confirmar Acción"
                 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 99%">
    <div class="p-col-12">
        <app-invmaebarra [barraBotonesCRUD]="true" [barraBotonesDesplaz]="true" [barraBotones3]="true"
        [botonVisto]="!btnVisto" [barraBotonesAsiCon]="true" [barraBotones5]="true" [barraBotones3Alt]="false" (signal)="manejarSenales($event)" ></app-invmaebarra>
    </div>
</div> 


<div class="divgrups" style="height: 100vh;">
    
    <div class="p-grid">
        <div class="p-col-1"></div>
        <div class="p-col-4">
            <span style="font-size: 120%;">Archivo:</span>
            
        </div>
         <div class="p-col-6">
            <p-fileUpload (uploadHandler)="onBasicUpload($event)" accept=".xls, .xlsx" chooseLabel="Seleccionar archivo excel"
                        auto="false" customUpload="true" mode="advanced" name="miExcel[]">
            </p-fileUpload> 
            <!-- <input class="frm"  type="text" pInputText  style="width: 80%;" >
            <button type="button"
            class="littlebuttons" style="background-color: rgb(140, 199, 233);" >
            ...
            </button> -->
        </div>
        
    </div>

    <div class="p-grid" style="margin-top: 20px;">
        <div class="p-col-1"></div>
        <div class="p-col-4">
            <span style="font-size: 120%;">Carga:</span>
            
        </div>
        <div class="p-col-6">
            <p-dropdown 
                         
            [options]="cmbTipoCarga" 
            [style]="{'minWidth': '100%', 'width':'100%'}"
            optionLabel="MAECARGEXCE_NOMBRE" 
            optionValue="MAECARGEXCE_CODIGO"
            [panelStyle]="{'width':'110%'}"
            [(ngModel)]="selectCarga"
            (onChange)="seleccionarCarga($event)">
            </p-dropdown>
        </div>
    </div>
</div>

