import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {InvReservas} from '../../inv/invinterfaces/invtrnreservas';
import {AggridBanComponent} from '../../ban/aggrid-ban/aggrid-ban.component';
import {InvtrnreservasService} from '../../inv/invservicios/invtrnreservas.service';
import {DatePipe} from '@angular/common';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {InvenctransferService} from '../../inv/invservicios/invenctransfer.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {SaciEmpresas} from '../anexinterfaces/anexgenerararchivos';
import {AnexgenerararchivosService} from '../anexservicios/anexgenerararchivos.service';

@Component({
  selector: 'app-sacimaeempresas',
  templateUrl: './sacimaeempresas.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class SacimaeempresasComponent implements OnInit {
  
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  largo: string;
  allSelected: boolean;
  rowSelection = 'multiple';
  public frameworkComponents;
  arregloaEnviar: SaciEmpresas[] = [];
  @Input() sentencia: string;
  @Output() detallesSeleccionados = new EventEmitter<SaciEmpresas[]>();
  @Output() signalSalir = new EventEmitter<boolean>();
  rowStyle;
  defaultColDef;
  
  detalleEmpresas: SaciEmpresas[] = [];
  
  
  
  columnDefsEmpresas = [
    {
      headerName: 'SEL.', field: 'CHECK', cellRendererFramework: AggridBanComponent, editable: false, width: 50, checkCierre: false,
    },
    { // 0
      headerName: 'CÓDIGO', field: 'EMPRESA_CODIGO', width: 100, editable: false,
    },
    { // 1
      headerName: 'ESQUEMA', field: 'EMPRESA_ESQUEMA', editable: false, width: 110
    },
    { // 1
      headerName: 'EMPRESA', field: 'EMPRESA_NOMBRE', editable: false, width: 100
    }
  ];
  
  constructor(private datePipe: DatePipe,
              public init: NuevoComponentService, public anexgenarchivoService: AnexgenerararchivosService) {
    this.agTable();
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDef = {
      editable: true,
      width: 100,
      objeto: 'sacimaeempresas'
    };
  }
  
  async ngOnInit() {
    this.allSelected = false;
    this.largo = '500';
    this.arregloaEnviar = [];
    
    const rs = await this.anexgenarchivoService.buscarEmpresas(this.sentencia);
    const detalle: SaciEmpresas[] = [];
    if (rs.length > 0) {
      for (const emp of rs) {
        const item: SaciEmpresas = {};
        item.CHECK = false;
        item.EMPRESA_CODIGO = emp.EMPRESA_CODIGO;
        item.EMPRESA_ESQUEMA = emp.EMPRESA_ESQUEMA;
        item.EMPRESA_NOMBRE = emp.EMPRESA_NOMBRE;
        detalle.push(item);
      }
      this.detalleEmpresas = detalle;
    }
  }
  
  
  seleccionar() {
    
    if (this.allSelected === true) {
      this.detalleEmpresas.map((item) => {
        item.CHECK = true;
      });
      this.aggrid.refreshaggrid(this.detalleEmpresas, this.defaultColDef.objeto);
    } else if (this.allSelected === false) {
      this.detalleEmpresas.map((item) => {
        item.CHECK = false;
      });
      this.aggrid.refreshaggrid(this.detalleEmpresas, this.defaultColDef.objeto);
    }
  }
  enviarDetalles() {
    
    for (const item of this.detalleEmpresas) {
      if (item.CHECK === true) {
        this.arregloaEnviar.push(item);
      }
    }
    
    this.detallesSeleccionados.emit(this.arregloaEnviar);
  }
  enviarSalir() {
    this.signalSalir.emit(true);
  }
  
}

