import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { Observable } from 'rxjs';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class PermisosService {

  public way: string;

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) {
   
    this.way = sessionStorage.getItem('way');
  }

  async getPermisosFrm(opcobjeto) {
    let prefijo;
    let menu;
    let opcion;
    const data = await this.getDatosMaeOpcion(opcobjeto);
    for (const rs of data) {
      prefijo = rs.MODPREFIJO;
      menu = rs.MENCODIGO;
      opcion = rs.OPCCODIGO;
    }

    return this.http.post<any>(this.way + '/seg/permisos/41ddcbb769731c', {
      elementos: {
        p_prefijo: prefijo,
        p_menu: menu,
        p_opcion: opcion,
        p_usuario: this.usuario.identificacion,
        p_COMcodigop: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDatosMaeOpcion(opcobjeto): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/advantage/permisos/41dd6abb653b1800000', {
      elementos: {
        OBJETO: opcobjeto
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getListaPermisos(prefijo, menu, opcion): Observable<any> {
    return this.http.post<any>(this.way + '/advantage/permisos/10776b2e755977000', {
      elementos: {
        p_prefijo: prefijo,
        p_menu: menu,
        p_opcion: opcion,
        p_usuario: this.usuario.identificacion,
        p_COMcodigop: '01'
      },
    }, this.confIniciales.httpOptions());
  }

  verificarPermisosObjConClave(strDialogo, strObjeto, intPestania): Observable<any[]> {

    let strSql = 'SELECT COUNT(*) as RESULT FROM  SACI_MAEGUIOBJ O, SACI_MAEGUIOBJPER S WHERE  O.GUIOBJ_CODIGO = S.GUIOBJ_CODIGO AND ';
    strSql = strSql + 'O.GUIOBJ_FORMA = \'' + strDialogo.trim() + '\' AND O.GUIOBJ_NOMBOBJ = \'' + strObjeto.trim() + '\' AND';
    strSql = strSql + ' S.COM_CODIGO = \'01\' AND S.USU_IDENTIFICACION = \'' + this.usuario.identificacion + '\' AND S.GUIOBJPERSUP_ESTADO = 1';

    if (intPestania > 0) {
      strSql = strSql + ' AND O.GUIOBJ_TABCOL = ' + intPestania;
    }

    return this.http.post<any[]>(this.way + '/advantage/permisos/1f76f2ede17365000', {
      elementos: {
        GUIOBJ_FORMA: strDialogo.trim(),
        GUIOBJ_NOMBOBJ: strObjeto.trim(),
        USU_IDENTIFICACION: this.usuario.identificacion,
        PESTANIA: intPestania
      }
    }, this.confIniciales.httpOptions());


  }

  async getPermisosFrmPVorVEN(objeto) {
    let prefijo;
    let menu;
    let opcion;
    const data = await this.getDatosMaeOpcionPVorVEN(objeto);
    for (const rs of data) {
      prefijo = rs.MODPREFIJO;
      menu = rs.MENCODIGO;
      opcion = rs.OPCCODIGO;
    }

    return this.http.post<any>(this.way + '/advantage/permisos/41ddcbb767eed800000', {
      elementos: {
        p_prefijo: prefijo,
        p_menu: menu,
        p_opcion: opcion,
        p_usuario: this.usuario.identificacion,
        p_COMcodigop: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDatosMaeOpcionPVorVEN(objeto): Promise<any[]> {

    return this.http.post<any[]>(this.way + '/advantage/permisos/20eeb55dc659a00000', {
      elementos: {
        OPCOBJETO: objeto.component,
        MODPREFIJO: objeto.modprefijo,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }


}
