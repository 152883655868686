import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Observable} from 'rxjs';
import {ConmaecentroCostos} from '../coninterfaces/conmaecentro-costos';
import {ConmaeMayorGeneral} from '../coninterfaces/conmaemayorgeneral';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
@Injectable({
  providedIn: 'root'
})
export class ConmaecenService {
  get conmaecentroCostos(): ConmaecentroCostos[] {
    return this._conmaecentroCostos;
  }
  set conmaecentroCostos(value: ConmaecentroCostos[]) {
    this._conmaecentroCostos = value;
  }
  // otra tabla
  get conmaeMayorGeneral(): ConmaeMayorGeneral[] {
    return this._conmaemayorgeneral;
  }
  set conmaeMayorGeneral(value: ConmaeMayorGeneral[]) {
    this._conmaemayorgeneral = value;
  }
  
 
  public way: string;
  private _conmaecentroCostos: ConmaecentroCostos[];
  private _conmaemayorgeneral: ConmaeMayorGeneral[];
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }
  
  getCentroCostos(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cont/centrocostos/41dd63afdde1980', {
        elementos: {},
      }, this.confIniciales.httpOptions());
  }
  existeCentroCosto(centrocodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cont/centrocostos/77800eacad2b', {
        elementos: {CEN_CODIGO: centrocodigo},
      }, this.confIniciales.httpOptions()
    );
  }
  
  insertarCentroC(centroCosto: ConmaecentroCostos): Observable<any[]> {
    return this.http.post<any>(this.way + '/cont/centrocostos/4afc76faadb1d8', {
      elementos: {
        p_CEN_codigo_1: centroCosto.CEN_CODIGO,
        p_CEN_nombre_2: centroCosto.CEN_NOMBRE,
        p_COM_codigo_3: '01',
      }
    }, this.confIniciales.httpOptions());
  }
  actualizarCentroC(centroCosto: ConmaecentroCostos, codigo): Observable<any[]> {
    return this.http.post<any>(this.way + '/cont/centrocostos/dd7299df2ab2eeed', {
      elementos: {
        p_CEN_codigoa: codigo,
        p_CEN_codigo_1: centroCosto.CEN_CODIGO,
        p_CEN_codigo_2: centroCosto.CEN_NOMBRE,
        p_COM_codigo_3: '01',
      }
    }, this.confIniciales.httpOptions());
  }
  eliminarCentroCosto(centroCosto: ConmaecentroCostos): Observable<any[]> {
    return this.http.post<any>(this.way + '/cont/centrocostos/3ab2b4aeadb1d800', {
      elementos: {
        p_CEN_codigo_1: centroCosto.CEN_CODIGO,
        p_COM_codigo_2: '01',
       }
    }, this.confIniciales.httpOptions());
  }
  
  obtenerMayorGeneral(centroCosto: ConmaecentroCostos): Observable<any[]> {
    return this.http.post<any>(this.way + '/cont/centrocostos/10776ca2b775d8', {
      elementos: {
        p_CEN_codigop: centroCosto.CEN_CODIGO,
        p_COM_codigop: '01'
      }
    }, this.confIniciales.httpOptions());
    
  }
  
}
