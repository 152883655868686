<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="reemb"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="reembconf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<app-invmaebarra [botonNuevo]="botonNuevo" [botonGuardar]="botonGuardar" [botonBorrar]="botonBorrar"
				 [botonRegresar]="botonRegresar" (signal)="manejarSenales($event)" [barraBotonesDesplaz]="barraBotones2"
				 [barraBotones3]="barraBotones2" [barraBotonesAsiCon]="barraBotones2"
				 [barraBotones5]="true"></app-invmaebarra>
<div class="p-grid" style="margin-top: -5px; margin-bottom: -5px; width: 99%;">
	<!--LINEA 1-->
	<div class="p-col-2">
		<label for="ENCFACPRO_NUMERO"></label>
		<input class="frm" id="ENCFACPRO_NUMERO" type="text" pInputText
			   style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
			   [disabled]="true" [(ngModel)]="strNumFact" autocomplete="off">
	</div>
</div>
<div class="divgrups">
	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
				<div class="p-col-5" style="text-align: left">
					<span>Tipo de Identificación del Proveedor:</span>
				</div>
				<div class="p-col-1">
					<input type="text" id="TRNREE_TIPOIDEPRO" (input)="cambio()" style="width: 100%"
						   [maxLength]="10"
						   (keydown.enter)="setFocus($event)" [(ngModel)]="trnreembolsos.TRNREE_TIPOIDEPRO">
				</div>
				<div class="p-col-1">
					<button type="button"
							style="background-image: url(../../../assets/images/iconos/buscar.png);"
							class="littlebuttons" (click)="abrirTIPOIDEPRO()"></button>
				</div>
				<div class="p-col-3">
					<span>{{trnreembolsos.TRNREE_TIPOIDEPRONOMBRE}}</span>
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
				<div class="p-col-5" style="text-align: left">
					<span>No. de Identificación del Proveedor:</span>
				</div>
				<div class="p-col-3">
					<input type="text" id="TRNREE_IDEPRO" (input)="cambio()" style="width: 100%"
						   [maxLength]="20"
						   (keydown.enter)="setFocus($event)" [(ngModel)]="trnreembolsos.TRNREE_IDEPRO">
				</div>
				<div class="p-col-1">
					<!--<span *ngIf="valida">Mal</span>
					<span *ngIf="!valida">Bien</span>-->
					<span>{{strbienmal}}</span>
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
				<div class="p-col-5" style="text-align: left">
					<span>Tipo de Proveedor:</span>
				</div>
				<div class="p-col-1">
					<input type="text" id="TRNREE_PROTIPO" (input)="cambio()" style="width: 100%"
						   [maxLength]="10"
						   (keydown.enter)="setFocus($event)" [(ngModel)]="trnreembolsos.TRNREE_PROTIPO">
				</div>
				<div class="p-col-1">
					<button type="button"
							style="background-image: url(../../../assets/images/iconos/buscar.png);"
							class="littlebuttons" (click)="abrirPROTIPO()"></button>
				</div>
				<div class="p-col-3">
					<span>{{trnreembolsos.TRNREE_PROTIPONOMBRE}}</span>
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
				<div class="p-col-5" style="text-align: left">
					<span>Código País Pago:</span>
				</div>
				<div class="p-col-1">
					<input type="text" id="TRNREE_PAISPAGO" (input)="cambio()" style="width: 100%"
						   [maxLength]="10"
						   (keydown.enter)="setFocus($event)" [(ngModel)]="trnreembolsos.TRNREE_PAISPAGO">
				</div>
				<div class="p-col-1">
					<button type="button"
							style="background-image: url(../../../assets/images/iconos/buscar.png);"
							class="littlebuttons" (click)="abrirPAISPAGO()"></button>
				</div>
				<div class="p-col-3">
					<span>{{trnreembolsos.TRNREE_PAISPAGONOMBRE}}</span>
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
				<div class="p-col-5" style="text-align: left">
					<span>Código tipo de comprobante:</span>
				</div>
				<div class="p-col-1">
					<input type="text" id="TRNREE_TIPCOMP" (input)="cambio()" style="width: 100%"
						   [maxLength]="10"
						   (keydown.enter)="setFocus($event)" [(ngModel)]="trnreembolsos.TRNREE_TIPCOMP">
				</div>
				<div class="p-col-1">
					<button type="button"
							style="background-image: url(../../../assets/images/iconos/buscar.png);"
							class="littlebuttons" (click)="abrirTIPCOMP()"></button>
				</div>
				<div class="p-col-3">
					<span>{{trnreembolsos.TRNREE_TIPCOMPNOMBRE}}</span>
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
				<div class="p-col-5" style="text-align: left">
					<span>No. de serie del comprobante de venta establecimiento:</span>
				</div>
				<div class="p-col-2">
					<input type="text" id="TRNREE_SERIEEST" (input)="cambio()" style="width: 100%"
						   [maxLength]="3"
						   (keydown.enter)="setFocus($event)" [(ngModel)]="trnreembolsos.TRNREE_SERIEEST">
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
				<div class="p-col-5" style="text-align: left">
					<span>No.  de serie del comprobante de venta del punto de emisión:</span>
				</div>
				<div class="p-col-2">
					<input type="text" id="TRNREE_SERIEPTOEMI" (input)="cambio()" style="width: 100%"
						   [maxLength]="3"
						   (keydown.enter)="setFocus($event)" [(ngModel)]="trnreembolsos.TRNREE_SERIEPTOEMI">
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
				<div class="p-col-5" style="text-align: left">
					<span>No. secuencial del comprobante de venta:</span>
				</div>
				<div class="p-col-3">
					<input type="text" id="TRNREE_SECCOMPVTA" (input)="cambio()" style="width: 100%"
						   [maxLength]="9"
						   (keydown.enter)="setFocus($event)" [(ngModel)]="trnreembolsos.TRNREE_SECCOMPVTA">
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
				<div class="p-col-5" style="text-align: left">
					<span>Fecha de emisión del comprobante de venta:</span>
				</div>
				<div class="p-col-2">
					<p-calendar id="TRNREE_FECHAEMISION"
								[inputStyle]="{width: '100%', height: '20px'}"
								[(ngModel)]="trnreembolsos.TRNREE_FECHAEMISION"
								[readonlyInput]="true" dateFormat="dd/mm/yy"
								(keydown.enter)="setFocus($event)"
								(keydown)="cambio()">
					</p-calendar>
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
				<div class="p-col-5" style="text-align: left">
					<span>No. de autorización del comprobante de venta:</span>
				</div>
				<div class="p-col-5">
					<input type="text" id="TRNREE_AUTORIZACION" (input)="cambio()" style="width: 100%"
						   [maxLength]="49"
						   (keydown.enter)="setFocus($event)" [(ngModel)]="trnreembolsos.TRNREE_AUTORIZACION">
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
				<div class="p-col-5" style="text-align: left">
					<span>Base Imponible tarifa 0% IVA:</span>
				</div>
				<div class="p-col-2">
					<input type="text" id="TRNREE_BASECERO" (input)="cambio()" style="width: 100%; text-align: right"
						   [maxLength]="10" appTwoDigitDecimaNumber
						   (keydown.enter)="setFocus($event)" [(ngModel)]="trnreembolsos.TRNREE_BASECERO">
				</div>
				<div class="p-col-2" style="text-align: left">
					<span>Base Imp. ExeReemb:</span>
				</div>
				<div class="p-col-2">
					<input type="text" id="TRNREE_BASEIMPEXEREEMB" (input)="cambio()"
						   style="width: 100%; text-align: right"
						   [maxLength]="10" appTwoDigitDecimaNumber
						   (keydown.enter)="setFocus($event)" [(ngModel)]="trnreembolsos.TRNREE_BASEIMPEXEREEMB">
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
				<div class="p-col-5" style="text-align: left">
					<span>Base Imponible tarifa IVA diferente de 0%:</span>
				</div>
				<div class="p-col-2">
					<input type="text" id="TRNREE_BASEIVA" (input)="cambio()" style="width: 100%; text-align: right"
						   [maxLength]="10" appTwoDigitDecimaNumber
						   (keydown.enter)="setFocus($event)" [(ngModel)]="trnreembolsos.TRNREE_BASEIVA">
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
				<div class="p-col-5" style="text-align: left">
					<span>Base Imponible no objeto de IVA:</span>
				</div>
				<div class="p-col-2">
					<input type="text" id="TRNREE_BASENOIVA" (input)="cambio()" style="width: 100%; text-align: right"
						   [maxLength]="10" appTwoDigitDecimaNumber
						   (keydown.enter)="setFocus($event)" [(ngModel)]="trnreembolsos.TRNREE_BASENOIVA">
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
				<div class="p-col-5" style="text-align: left">
					<span>Porcentaje de IVA:</span>
				</div>
				<div class="p-col-2">
					<input type="text" id="TRNREE_PORCENTAJEIVA" (input)="cambio()"
						   style="width: 100%; text-align: right"
						   [maxLength]="10" appTwoDigitDecimaNumber
						   (keydown.enter)="setFocus($event)" [(ngModel)]="trnreembolsos.TRNREE_PORCENTAJEIVA">
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
				<div class="p-col-5" style="text-align: left">
					<span>Monto ICE:</span>
				</div>
				<div class="p-col-2">
					<input type="text" id="TRNREE_MONTOICE" (input)="cambio()" style="width: 100%; text-align: right"
						   [maxLength]="10" appTwoDigitDecimaNumber
						   (keydown.enter)="setFocus($event)" [(ngModel)]="trnreembolsos.TRNREE_MONTOICE">
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
				<div class="p-col-5" style="text-align: left">
					<span>Monto IVA:</span>
				</div>
				<div class="p-col-2">
					<input type="text" id="TRNREE_MONTOIVA" (input)="cambio()" style="width: 100%; text-align: right"
						   [maxLength]="10" appTwoDigitDecimaNumber
						   (keydown.enter)="setFocus($event)" [(ngModel)]="trnreembolsos.TRNREE_MONTOIVA">
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
				<div class="p-col-5" style="text-align: left">
					<span>Empleado:</span>
				</div>
				<div class="p-col-1">
					<input type="text" id="TRNREE_EMPLEADO" (input)="cambio()" style="width: 100%"
						   [maxLength]="10"
						   (keydown.enter)="setFocus($event)" [(ngModel)]="trnreembolsos.TRNREE_EMPLEADO">
				</div>
				<div class="p-col-1">
					<button type="button"
							style="background-image: url(../../../assets/images/iconos/buscar.png);"
							class="littlebuttons" (click)="abrirEMPLEADO()"></button>
				</div>
				<div class="p-col-3">
					<span>{{trnreembolsos.TRNREE_EMPLEADONOMBRE}}</span>
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
				<div class="p-col-5" style="text-align: left">
					<span>Tipo de Gasto:</span>
				</div>
				<div class="p-col-5">
					<p-dropdown id="TRNREE_TIPGAS" [options]="cmbtipogasto"
								[style]="{'minWidth': '100%', 'width':'100%'}"
								optionLabel="name" [panelStyle]="{'width':'110%'}"
								[(ngModel)]="selecttipogasto"
								(onChange)="busqtipogasto($event)"></p-dropdown>
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="listtrnreembolsos"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsReemb"
							[defaultColDef]="defaultColDefReemb"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)="selecciontrnreembolsos($event)"
							(click)="selecciontrnreembolsos($event)"
			></app-aggridsaci>
		</div>
		<div class="p-col-12">
			<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
				<div class="p-col-2" style="text-align: left">
				</div>
				<div class="p-col-2" style="text-align: left">
					<span>Base I.V.A.</span>
				</div>
				<div class="p-col-2" style="text-align: left">
					<span>Base Cero</span>
				</div>
				<div class="p-col-2" style="text-align: left">
					<span>Base No Grab.</span>
				</div>
				<div class="p-col-2" style="text-align: left">
					<span>Valor I.V.A.</span>
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
				<div class="p-col-2" style="text-align: left">
				</div>
				<div class="p-col-2" style="text-align: left">
					<span>{{TotalBaseIva}}</span>
				</div>
				<div class="p-col-2" style="text-align: left">
					<span>{{TotalBaseCero}}</span>
				</div>
				<div class="p-col-2" style="text-align: left">
					<span>{{TotalBaseNoGrab}}</span>
				</div>
				<div class="p-col-2" style="text-align: left">
					<span>{{TotalValorIva}}</span>
				</div>
			</div>
		</div>
	</div>
</div>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusqueda"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

