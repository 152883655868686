import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import { Nommaedatosadi, NommaedatosadiEmp } from '../nominterfaces/nonmaedatosadi';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
@Injectable({
  providedIn: 'root'
})
export class NommaedataglobService {
 
  public way: string;

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private confIniciales: ConfInicialesService,
    public usuario: Usuario) {
      
      this.way = sessionStorage.getItem('way');
      this.usuario.identificacion;

   }
   async selectDatosadimatglob() {
    var strSentencia="select * from nom_maedatosadi where  DATADI_ESTADO='OK' and DATADI_TIPODATO='Clob'ORDER BY DATADI_ORDEN";
    return await this.http.post<any[]>(this.way + '/nom/maedatosadi/e3aad57712b4180', {
      elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
   
  updateDonAdi(imagen,campo,deta){ // metodo para guardar una pantalla
    console.log(campo,deta);
    return this.http.post(this.way + '/nom/maedatosadi/533be6abb853640', {
      elementos: {
        COM_LOGO: imagen
      },
      elemento: {
        CAMPO: campo,
        EMP_CODIGO: deta
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  ejecutarSenten(setencia:string,cod){ // metodo para guardar una pantalla
    var sql1= `Select ${setencia} from NOM_MAEEMPLEADO where emp_codigo=${cod}`
    return this.http.post(this.way + '/nom/maedatosadi/74dcc662fcf57c0', {
      elementos: {
        CAMPOS: setencia,
        EMP_CODIGO: cod
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
}
