import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Maecategoria} from '../../../interfaces/maecategoria';
import {MaecategoriaService} from '../../../servicios/maecategoria.service';
import {Maesubcategoria} from '../../../interfaces/maesubcategoria';
import {MaesubcategoriaService} from '../../../servicios/maesubcategoria.service';
import {MessageService} from 'primeng/api';
import {Maearticulo} from '../../../interfaces/maearticulo';
import {ConfInicialesService} from '../../../servicios/conf-iniciales.service';
import {PedpedidoService} from '../../venservicios/pedpedido.service';
import {CalculospedidosService} from '../../venservicios/calculospedidos.service';
import {MaePantalla} from '../../../interfaces/maepantalla';
import {OverlayPanel, OverlayPanelModule} from 'primeng/primeng';



@Component({
  selector: 'app-pedproductos',
  templateUrl: './pedproductos.component.html',
  styleUrls: ['./pedproductos.component.css']
})

export class PedproductosComponent implements OnInit, OnChanges {

  @Output() producto = new EventEmitter<Maearticulo>();
  @Input() pagada: any;

  legendCat: string;
  previousLeg: string;
  totalCats: Maecategoria[];
  selectedSubcats: Maesubcategoria[];
  selectedArticulos: Maearticulo[];
  categoriaSeleccionada: Maecategoria;
  artSelected: Maearticulo;
  subSelected: Maesubcategoria;
  cantProd: string;
  seleccionoCat = false;
  seleccionoSubCat = false;
  index = 0;
  imagenUrl: string;
  spin: boolean;
  intFCPRINIVA = 0;
  decPrecio = 0;

  constructor(private catService: MaecategoriaService, private subCatService: MaesubcategoriaService,
              private messageService: MessageService, private calc: CalculospedidosService, private pedidoserv: PedpedidoService,
              private initConf: ConfInicialesService) {
  }

  ngOnInit() {
    this.catService.getMaeCategorias().subscribe((respuesta: any[]) => {
      this.totalCats = respuesta;
    });
    this.legends();
    this.artSelected = {};
    this.imagenUrl = '';

    // Precios de los artículos incluyen I.V.A
    this.initConf.getConfiguracion('FCPRINIVA').subscribe((respuesta) => {
      if (respuesta[0].CFG_VALOR1 === 1) {
        this.intFCPRINIVA = 1;
      } else if (respuesta[0].CFG_VALOR1 === 0) {
        this.intFCPRINIVA = 0;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.pagada === true) {
      this.seleccionoCat = false;
      this.seleccionoSubCat = false;
    }
  }
  mostrarSubcats(cat: Maecategoria) {
    this.seleccionoCat = true;
    this.spin = true;
    this.categoriaSeleccionada = cat;
    this.catService.recogerCategoria(cat).subscribe((respuesta: any[]) => {
      this.selectedSubcats = respuesta;
      this.spin = false;
    });
    this.index = 0;
    this.changeCatLeg(this.categoriaSeleccionada.CAT_NOMBRE);
  }

  mostrarArticulos(subCat: Maesubcategoria) {
    this.decPrecio = this.calc.entregarDecPrecio();
    this.seleccionoSubCat = true;
    this.spin = true;
    this.subCatService.recogerProductos(subCat).subscribe((respuesta: any[]) => {
      if (respuesta.length === 0) {
        this.messageService.add({
          key: 'message',
          severity: 'error',
          summary: 'Productos Seleccionados',
          detail: 'No existe productos para esa subcategoria'
        });
        this.selectedArticulos = respuesta;
        this.spin = false;
      } else {
        this.selectedArticulos = respuesta;
        this.selectedArticulos.map((art) => {
          art.ARTPRE_PRECIO = art.ARTPRE_PRECIO.toFixed(this.decPrecio);
        });
        this.spin = false;
      }
    });
    this.subSelected = subCat;
    this.index = 0;
    this.changeCatLeg(this.subSelected.SUB_NOMBRE);
  }

  artSeleccionado(prod: Maearticulo, overlay: OverlayPanel) {
    overlay.hide();
    const cantidad = this.calc.retornarValorTeclado();
    this.cantProd = this.calc.returnCalcValue();
    if (Number(this.cantProd) === (undefined || null || 0)) {
      this.setCantidad(Number(cantidad));
    } else {
      this.setCantidad(Number(this.cantProd));
    }
    this.pedidoserv.getPrecioArticulo(prod, this.intFCPRINIVA).subscribe((respuesta3: any[]) => {
      if (this.intFCPRINIVA === 0) {
        prod.ARTPRE_PRECIO = Number(respuesta3[0].PRECIO) / 1.12;
      }
      if (this.intFCPRINIVA === 1) {
        prod.ARTPRE_PRECIO = Number(respuesta3[0].PRECIO) / 1.12;
      }
      if (prod.CANTIDAD === undefined && this.artSelected.CANTIDAD === (undefined || 0)) {
        this.producto.emit(prod);
      } else {
        if (this.artSelected.CANTIDAD !== 0 && this.artSelected.CANTIDAD !== undefined) {
          prod.CANTIDAD = this.artSelected.CANTIDAD;
          this.producto.emit(prod);
          this.artSelected.CANTIDAD = undefined;
        } else {
          this.producto.emit(prod);
        }
      }
    });
  }

  setCantidad(cant: number) {
    if (isNaN(cant) === true) {
      this.messageService.add({
        key: 'message',
        severity: 'warn',
        summary: 'Cantidad Invalida',
        detail: 'La cantidad ingresada para el producto no es válida, se ingresó 1 como valor por defecto',
      });
    } else {
      this.artSelected.CANTIDAD = cant;
    }
  }

  legends() {
    this.legendCat = 'Categorias';
  }

  changeCatLeg(value: string) {
    if (this.legendCat === 'Categorias') {
      this.previousLeg = value;
      this.legendCat = value;
    } else if (value === '') {
      this.legendCat = this.previousLeg;
    } else {
      this.legendCat = this.legendCat + ' / ' + value;
    }
  }

  ontabopen(e) {
    this.index = e.index;
  }

  oncloseopen() {
    this.index = 0;
  }

  botonhome() {
    if (this.seleccionoCat === true && this.seleccionoSubCat === false) {
      this.seleccionoCat = false;
      this.legends();
    } else if (this.seleccionoSubCat === true && this.seleccionoCat === true) {
      this.seleccionoSubCat = false;
      this.changeCatLeg('');
    } else {
      this.seleccionoSubCat = false;
      this.seleccionoCat = false;
      this.legends();
    }
  }
  abrirImagen(event, producto: MaePantalla, overlayPanel: OverlayPanel) {
    this.imagenUrl = producto.ART_IMAGEN;
    overlayPanel.toggle(event);
  }
  cerrarImagen(overlay: OverlayPanel) {
    overlay.hide();
  }
}
