import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { MAERL , MAERL_BI} from '../interfaces/MAERL';
import { SEGMAEOPCION } from '../interfaces/SEGMAEOPCION';
import { MAERLPARAM } from '../interfaces/MAERLPARAM';
import { InvbusquedaService } from '../inv/invservicios/invbusqueda.service';
import { ReportesService } from '../servicios/reporte.service';
import { NuevoComponentService } from '../servicios/nuevo-component.service';
import { CellEditorPrueba } from '../advantage/config/cell-edit-prueba';
import { ErroresBaseDatosService } from '../servicios/errores-base-datos.service';
import { AggridsaciComponent } from '../advantage/aggridsaci/aggridsaci.component';
import { AuditoriagralService } from '../advantage/advantageservices/auditoriagral.service';

@Component({
  selector: 'app-mantenimiento-reportes',
  templateUrl: './mantenimiento-reportes.component.html',
  styleUrls: ['../advantage/styles/saciwebmae.css']
})
export class MantenimientoReportesComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  RLTIPO = 'Listado';
  RLPROCEDENCIA = '';
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnLimpiar: boolean;
  btnPrimero: boolean;
  btnAnterior: boolean;
  btnSiguiente: boolean;
  btnUltimo: boolean;
  btnBuscar: boolean;
  btnRegresar: boolean;
  index: number;
  types: SelectItem[];
  arregloBus: any[];
  displayDialogBus: boolean;
  displayDialogBusquedaFast: boolean;
  reporteSeleccionado: MAERL;
  grupo: SEGMAEOPCION;
  opcion: string;
  arregloGenerales: MAERL[];
  indice: number;
  tipo: MAERL[];
  procedencia: MAERL[];
  parametro: MAERLPARAM[];
  parametroAux: MAERLPARAM[];
  parametroSeleccionado: MAERLPARAM;
  tipovariable: MAERLPARAM[];
  tipoParametros: MAERLPARAM[];
  para: MAERLPARAM = {};


  busquedacampos: string[];
  tabla: string;
  where: string;
  /*TABLA*/
  public frameworkComponents;
  rowStyle;
  largo = '450';
  rowSelection = 'multiple';
  defaultColManteRep;
  segManteRep: MAERLPARAM[] = [];
  selectedManteRep: MAERLPARAM;
  segManbi: MAERL_BI[]=[]; //GSRF
  selectedManbi: MAERL_BI; //GSRF
  defaultColbi;//GSRF

  columnDefManteRep = [
    {
      headerName: 'Variable', field: 'RLP_PARAMETRO', cellEditor: 'cellPrueba', width: 150, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 30,
          tienecalculos: false,
        }
      }
    },
    {
      headerName: 'Para/Cr', field: 'RLP_TIPOVARIABLE', cellEditor: 'agSelectCellEditor', width: 100, editable: true,

      onCellValueChanged: (params) => {
        this.editar();
      },
      cellEditorParams:
      {
        values: ['P', 'C1', 'C2', 'C3',
          'C4', 'C5', 'C6', 'C7', 'C8',
          'C9', 'C10']
      },

    },
    {
      headerName: 'Etiqueta', field: 'RLP_ETIQUETA', cellEditor: 'cellPrueba', width: 150, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 50,
          tienecalculos: false,
        }
      }
    },
    {
      headerName: 'Tipo', field: 'RLP_TIPO', cellEditor: 'agSelectCellEditor', width: 100, editable: true,
      onCellValueChanged: (params) => {
        this.editar();
      },
      cellEditorParams:
      {
        values: ['S', 'D', 'I']
      },

    },
    {
      headerName: 'Ubicación', field: 'RLP_UBICACION', cellEditor: 'cellPrueba', width: 150, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: false,
        }
      }
    },

    {
      headerName: 'Tamaño', field: 'RLP_DIMENSION', cellEditor: 'cellPrueba', width: 150, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: false,
        }
      }
    },
    {
      headerName: 'Maximo', field: 'RLP_MAXIMO', cellEditor: 'cellPrueba', width: 150, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: false,
        }
      }
    }
    ,
    {
      headerName: 'Consulta', field: 'RLP_CONSULTA', cellEditor: 'cellPrueba', width: 350, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 400,
          tienecalculos: false,
        }
      }
    }
    ,
    {
      headerName: 'Condición', field: 'RLP_CONDICION', cellEditor: 'cellPrueba', width: 200, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 400,
          tienecalculos: false,
        }
      }
    },
    //RLP_CONDICION,RLP_CRITERIOS
    {
      headerName: 'Criterios', field: 'RLP_CRITERIOS', cellEditor: 'cellPrueba', width: 200, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 400,
          tienecalculos: false,
        }
      }
    },

    {
      headerName: 'Tabla', field: 'RLP_TABLA', cellEditor: 'cellPrueba', width: 200, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 400,
          tienecalculos: false,
        }
      }
    }
  ];

  columnDefbi = [
    {
      headerName: 'Consulta', field: 'RLP_CONSULTA', cellEditor: 'cellPrueba', width: 400, editable: true,
      cellEditorParams:
      {
        values: {
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 200,
          tienecalculos: false,
        }
      }
    },
    {
      headerName: 'Nombre Archivo', field: 'RLP_NOMBRE', cellEditor: 'cellPrueba', width: 200, editable: true,
      cellEditorParams:
      {
        values: {
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 200,
          tienecalculos: false,
        }
      }
    },

  ];
  //segmaeConsultaTipoRef: SegMaeConsultaTipoRef[] = [];
  //selectedsegmaeConsultaTipoRef: SegMaeConsultaTipoRef; 

  constructor(private reporteSer: ReportesService,
    private busqService: InvbusquedaService, private conf: ConfirmationService, private message: MessageService,
    private auditoriaGralService: AuditoriagralService,
    private init: NuevoComponentService, private errorService: ErroresBaseDatosService, private messageService: MessageService, ) {
    this.agTable();
  }

  ngOnInit() {
    this.reporteSeleccionado = {};
    this.index = 0;
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = true;
    this.btnLimpiar = true;
    this.btnPrimero = false;
    this.btnAnterior = false;
    this.btnSiguiente = false;
    this.btnUltimo = false;
    this.btnBuscar = false;
    this.btnRegresar = false;
    this.displayDialogBus = false;
    this.displayDialogBusquedaFast = false;
    this.grupo = {};
    this.grupo.OPCNOMBRE = '';
    this.arregloGenerales = [];
    this.opcion = '';
    this.tipo = [{ RL_TIPO: 'Reporte' }, { RL_TIPO: 'Listado' }];
    this.procedencia = [{ RL_PROCEDENCIA: 'Tabla' }, { RL_PROCEDENCIA: 'Procesos' }, { RL_PROCEDENCIA: 'Varios' }];
    this.reporteSer.busqueda().subscribe((respuesta) => {

      this.arregloGenerales = respuesta;
    });
    this.indice = 0;
    this.tipoParametros = [{ RLP_TIPO: 'S' }, { RLP_TIPO: 'D' }, { RLP_TIPO: 'I' }];
    this.tipovariable = [{ RLP_TIPOVARIABLE: 'P' }, { RLP_TIPOVARIABLE: 'C1' }, { RLP_TIPOVARIABLE: 'C2' }, { RLP_TIPOVARIABLE: 'C3' },
    { RLP_TIPOVARIABLE: 'C4' }, { RLP_TIPOVARIABLE: 'C5' }, { RLP_TIPOVARIABLE: 'C6' }, { RLP_TIPOVARIABLE: 'C7' }, { RLP_TIPOVARIABLE: 'C8' },
    { RLP_TIPOVARIABLE: 'C9' }, { RLP_TIPOVARIABLE: 'C10' }];
    // this.agTable()
    this.segManbi = [];//GSRF
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.defaultColManteRep = {
      editable: true,
      width: 130,
      objeto: 'maeSegManteRep'
    };
    //GSRF
    this.defaultColbi = {
      editable: true,
      width: 130,
      objeto: 'maeSegbi'
    };
    //GSRF
  }

  async manejarSenales(valor) {
    if (valor === 'Primero') {
      this.reporteSeleccionado = this.arregloGenerales[0];
      this.reporteSer.mandarSentenciaGrupoCodigo(this.reporteSeleccionado.OPC_CODIGO).subscribe((res) => {
       // this.grupo = res[0];
        //CDPJ
        if (res[0] !== undefined && res[0] !== null) {
          // this.grupo.OPCCODIGO=this.reporteSeleccionado.OPC_CODIGO;
          // this.grupo.OPCNOMBRE='';
          this.grupo = res[0];
        }
        console.log(this.grupo);
        //CDPJ

        this.indice = 0;
      });
      this.llenarParametros();
      document.getElementById('divGeneral').style.pointerEvents = 'all';
    } else if (valor === 'Anterior') {
      if (this.indice === 0) {
        return;
      } else {
        this.indice -= 1;
        this.reporteSeleccionado = this.arregloGenerales[this.indice];
        this.reporteSer.mandarSentenciaGrupoCodigo(this.reporteSeleccionado.OPC_CODIGO).subscribe((res) => {
          // this.grupo = res[0];
          // console.log(this.grupo);
          //CDPJ
          if (res[0] !== undefined && res[0] !== null) {
            // this.grupo.OPCCODIGO=this.reporteSeleccionado.OPC_CODIGO;
            // this.grupo.OPCNOMBRE='';
            this.grupo = res[0];
          }
          console.log(this.grupo);
          //CDPJ
        });
        this.llenarParametros();
      }
    } else if (valor === 'Siguiente') {
      this.indice += 1;
      this.reporteSeleccionado = this.arregloGenerales[this.indice];
      this.reporteSer.mandarSentenciaGrupoCodigo(this.reporteSeleccionado.OPC_CODIGO).subscribe((res) => {
       // this.grupo = res[0];
          // console.log(this.grupo);
          //CDPJ
          if (res[0] !== undefined && res[0] !== null) {
            // this.grupo.OPCCODIGO=this.reporteSeleccionado.OPC_CODIGO;
            // this.grupo.OPCNOMBRE='';
            this.grupo = res[0];
          }
          console.log(this.grupo);
          //CDPJ
      });
      this.llenarParametros();
    } else if (valor === 'Ultimo') {
      this.indice = this.arregloGenerales.length - 1;
      this.reporteSeleccionado = this.arregloGenerales[this.indice];
      this.reporteSer.mandarSentenciaGrupoCodigo(this.reporteSeleccionado.OPC_CODIGO).subscribe((res) => {
        // this.grupo = res[0];
          // console.log(this.grupo);
          //CDPJ
          if (res[0] !== undefined && res[0] !== null) {
            // this.grupo.OPCCODIGO=this.reporteSeleccionado.OPC_CODIGO;
            // this.grupo.OPCNOMBRE='';
            this.grupo = res[0];
          }
          console.log(this.grupo);
          //CDPJ
      });
      this.llenarParametros();
      document.getElementById('divGeneral').style.pointerEvents = 'all';
    } else if (valor === 'Buscar') {
      this.buscar();
    } else if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
    if (this.index === 0) {
      console.log('primero');
      if (valor === 'Nuevo') {
        this.nuevoGeneral();
      } else if (valor === 'Guardar') {
        this.btnNuevo = false;
        this.btnLimpiar = true;
        this.btnGuardar = true;
        this.guardarGenerales();
      } else if (valor === 'Borrar') {
        this.confirm();
      } else if (valor === 'Cancelar') {
        this.cancelarCambios();
      }
    } else if (this.index === 1) {
      console.log('segundo');
      if (valor === 'Nuevo') {
        this.nuevoParametro();
      } else if (valor === 'Guardar') {
        this.btnNuevo = false;
        this.btnLimpiar = true;
        this.btnGuardar = true;
        this.guardarParametro();

      } else if (valor === 'Borrar') {
        this.confirm();
      } else if (valor === 'Cancelar') {
        this.cancelarCambiosParametros();
      }

    }
    //GSRF
    else if (this.index === 2) {
      console.log('TRES');
      if (valor === 'Nuevo') {
        this.nuevoParametrobi();
      } else if (valor === 'Guardar') {
        this.btnNuevo = false;
        this.btnLimpiar = true;
        this.btnGuardar = false;
        this.guardarParametrobi();

      } else if (valor === 'Borrar') {
        this.confirm();
      } else if (valor === 'Cancelar') {
        await this.cancelarCambiosParametros();
        this.btnGuardar = false;
      }

    }
    //GSRF
  }

  editar() {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = false;
    this.btnLimpiar = false;
  }

  nuevoGeneral() {
    /*Version1*/
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnLimpiar = false;
    this.RLTIPO = 'Listado';
    this.RLPROCEDENCIA = 'Procesos';
    this.reporteSeleccionado = {
      RL_NOMBRE: '', RL_ARCHIVO: '', RL_TIPO: '', RL_PROCEDENCIA: '', OPC_CODIGO: '',
      RL_PROCESO: '', RL_OBJETO: '', RL_LOCALIZACION: '', RL_NROFORMULA: '', RL_DESCRIPCION: ''
    };
    this.grupo = {};
    this.grupo.OPCNOMBRE = '';
    document.getElementById('divGeneral').style.pointerEvents = 'all';
    this.parametro = [];


  }

  cambioModulo(elemento) {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = false;
    this.btnLimpiar = false;
  }

  selectMaeRep(elemento) {

    if (elemento.object !== null) {
      this.selectedManteRep = elemento.object;
      //this.selectedsegmaeConsultaTipoRef.TIP_EDITABLE=(this.selectedsegmaeConsultaTipoRef.EDITABLE==true)?1:0;
      //this.segmaeConsultaTipoRef[this.selectedsegmaeConsultaTipoRef.NUMERO-1].TIP_EDITABLE=(this.selectedsegmaeConsultaTipoRef.EDITABLE==true)?1:0;

    }
    //console.log(this.selectedManteRep);
  }

  buscar1() {
    this.opcion = 'BUSCAR';
    this.reporteSer.busqueda().subscribe((res: any[]) => {
      this.arregloBus = res;
      this.types = [
        { label: 'Código', value: 'RL_CODIGO' },
        { label: 'Nombre', value: 'RL_NOMBRE' },
        { label: 'Archivo', value: 'RL_ARCHIVO' },
        { label: 'Tipo', value: 'RL_TIPO' },
        { label: 'Procedencia', value: 'RL_PROCEDENCIA' },
        { label: 'Opción', value: 'OPC_CODIGO' },
        { label: 'Proceso', value: 'RL_PROCESO' },
        { label: 'Fórmula', value: 'RL_NROFORMULA' },
        { label: 'Descripción', value: 'RL_DESCRIPCION' }
      ];
      this.displayDialogBus = true;
    });
  }

  buscar() {
    this.opcion = 'BUSCAR';
    this.types = [
      { label: 'Código', value: 'RL_CODIGO' },
      { label: 'Nombre', value: 'RL_NOMBRE' },
      { label: 'Archivo', value: 'RL_ARCHIVO' },
      { label: 'Tipo', value: 'RL_TIPO' },
      { label: 'Procedencia', value: 'RL_PROCEDENCIA' },
      { label: 'Opción', value: 'OPC_CODIGO' },
      { label: 'Proceso', value: 'RL_PROCESO' },
      { label: 'Fórmula', value: 'RL_NROFORMULA' },
      { label: 'Descripción', value: 'RL_DESCRIPCION' }
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'SACI_MAERL';
    this.where = '';

    this.busqService.busquedaSaciMaeRl1().subscribe((datos: any[]) => {
        this.arregloBus = datos;
        this.displayDialogBusquedaFast = true;
      });
  }

  manejarSeleccion(opcion) {
    if (this.opcion === 'BUSCAR') {
      this.reporteSeleccionado = opcion;
      this.reporteSer.mandarSentenciaGrupoCodigo(this.reporteSeleccionado.OPC_CODIGO).subscribe((res) => {
         //CDPJ
         if (res[0] !== undefined && res[0] !== null) {
          // this.grupo.OPCCODIGO=this.reporteSeleccionado.OPC_CODIGO;
          // this.grupo.OPCNOMBRE='';
          this.grupo = res[0];
        }
        console.log(this.grupo);
        //CDPJ
        // this.grupo = res[0];
        // console.log(this.grupo);
      });
      let reporteAux: MAERL;
      reporteAux = {};
      this.arregloGenerales.map((rep) => {
        if (rep.RL_CODIGO === this.reporteSeleccionado.RL_CODIGO) {
          reporteAux = rep;
        }
      });
      this.indice = this.arregloGenerales.indexOf(reporteAux);
      console.log(this.indice);
      if (this.reporteSeleccionado.RL_TIPO === 'R') {
        this.RLTIPO = 'Reporte';
        console.log(this.RLTIPO);
      } else if (this.reporteSeleccionado.RL_TIPO === 'L') {
        this.RLTIPO = 'Listado';
      } else {
        this.RLTIPO = 'Listado';
        console.log(this.RLTIPO);
      }

      if (this.reporteSeleccionado.RL_PROCEDENCIA === 'V') {
        this.RLPROCEDENCIA = 'Varios';
        console.log(this.RLPROCEDENCIA);
      } else if (this.reporteSeleccionado.RL_PROCEDENCIA === 'P') {
        this.RLPROCEDENCIA = 'Procesos';
      } else if (this.reporteSeleccionado.RL_PROCEDENCIA === 'T') {
        this.RLPROCEDENCIA = 'Tabla';
      } else {
        this.RLPROCEDENCIA = 'Tabla';
        console.log(this.RLPROCEDENCIA);
      }
      this.displayDialogBus = false;
      this.llenarParametros();
      document.getElementById('divGeneral').style.pointerEvents = 'all';
    } else if (this.opcion === 'GRUPO') {
      this.reporteSeleccionado.OPC_CODIGO = opcion.OPCCODIGO;
      this.grupo = opcion;
      this.displayDialogBus = false;
      document.getElementById('divGeneral').style.pointerEvents = 'all';
    }
    this.displayDialogBusquedaFast = false;
  }

  busquedadatosgrupo() {
    this.opcion = 'GRUPO';
    this.reporteSer.mandarSentenciaGrupo().subscribe((respuesta) => {
      this.arregloBus = respuesta;
      this.types = [
        { label: 'Código', value: 'OPCCODIGO' },
        { label: 'Nombre', value: 'OPCNOMBRE' },
        { label: 'Prefijo', value: 'MODPREFIJO' },
        { label: 'Menú', value: 'MENCODIGO' },
        { label: 'Objeto', value: 'OPCOBJETO' },
      ];
      this.displayDialogBus = true;
    });
  }

  guardarGenerales() {
    if (this.reporteSeleccionado.RL_CODIGO === undefined) {
      if (this.reporteSeleccionado.RL_TIPO === '') {
        this.reporteSeleccionado.RL_TIPO = 'L';
      } else {
        this.reporteSeleccionado.RL_TIPO = this.reporteSeleccionado.RL_TIPO.RL_TIPO;
      }
      if (this.reporteSeleccionado.RL_PROCEDENCIA === '') {
        this.reporteSeleccionado.RL_PROCEDENCIA = 'T';
      } else {
        this.reporteSeleccionado.RL_PROCEDENCIA = this.reporteSeleccionado.RL_PROCEDENCIA.RL_PROCEDENCIA;
      }
      this.reporteSer.insertarReporte(this.reporteSeleccionado).subscribe((res) => {
        this.reporteSeleccionado.RL_CODIGO = res[0].CODIGO;
        this.message.add({
          key: 'mantenimiento',
          severity: 'success',
          summary: 'Insertado',
          detail: 'Se inserto el reporte con exito'
        });
      });
    } else {
      if (this.reporteSeleccionado.RL_TIPO.RL_TIPO === undefined) {
        if (this.reporteSeleccionado.RL_TIPO === 'Reporte') {
          this.reporteSeleccionado.RL_TIPO = 'R';
        } else if (this.reporteSeleccionado.RL_TIPO === 'Listado') {
          this.reporteSeleccionado.RL_TIPO = 'L';
        }
      } else {
        this.reporteSeleccionado.RL_TIPO = this.reporteSeleccionado.RL_TIPO.RL_TIPO;
      }
      if (this.reporteSeleccionado.RL_PROCEDENCIA.RL_PROCEDENCIA === undefined) {
        if (this.reporteSeleccionado.RL_PROCEDENCIA === 'Tabla') {
          this.reporteSeleccionado.RL_PROCEDENCIA = 'T';
        } else if (this.reporteSeleccionado.RL_PROCEDENCIA === 'Proceso') {
          this.reporteSeleccionado.RL_PROCEDENCIA = 'P';
        } else if (this.reporteSeleccionado.RL_PROCEDENCIA === 'Varios') {
          this.reporteSeleccionado.RL_PROCEDENCIA = 'V';
        }
      } else {
        this.reporteSeleccionado.RL_PROCEDENCIA = this.reporteSeleccionado.RL_PROCEDENCIA.RL_PROCEDENCIA;
      }
      this.reporteSer.actualizareporte(this.reporteSeleccionado).subscribe(() => {
        this.message.add({
          key: 'mantenimiento',
          severity: 'success',
          summary: 'Actualizado',
          detail: 'Se actualizó el reporte con exito'
        });
      });
    }
  }

  confirm() {
    if (this.index === 0) {
      this.conf.confirm({
        message: 'Esta Seguro de Eliminar el Registro?',
        accept: () => {
          this.eliminar();
        }
      });
    } else if (this.index === 1) {
      this.conf.confirm({
        message: 'Esta Seguro de Eliminar el Parametro',
        accept: () => {
          this.eliminarParametro();
        }
      });
    }
    //GSRF
    else if (this.index === 2) {
      this.conf.confirm({
        message: 'Esta Seguro de Eliminar el BI',
        accept: () => {
          this.eliminarParametrobi();
        }
      });
    }
    //GSRF
  }

  eliminar() {
    this.reporteSer.eliminareporte(this.reporteSeleccionado).subscribe((res) => {

      this.reporteSeleccionado = {
        RL_NOMBRE: '', RL_ARCHIVO: '', RL_TIPO: '', RL_PROCEDENCIA: '', OPC_CODIGO: '',
        RL_PROCESO: '', RL_OBJETO: '', RL_LOCALIZACION: '', RL_NROFORMULA: '', RL_DESCRIPCION: ''
      };
      this.grupo.OPCNOMBRE = '';
      this.message.add({
        key: 'mantenimiento',
        severity: 'success',
        summary: 'Eliminado',
        detail: 'Se eliminó el reporte con exito'
      });
    });
  }

  cancelarCambios() {
    if (this.reporteSeleccionado.RL_CODIGO === undefined) {
      this.reporteSeleccionado = {
        RL_NOMBRE: '', RL_ARCHIVO: '', RL_TIPO: '', RL_PROCEDENCIA: '', OPC_CODIGO: '',
        RL_PROCESO: '', RL_OBJETO: '', RL_LOCALIZACION: '', RL_NROFORMULA: '', RL_DESCRIPCION: ''
      };
      this.grupo.OPCNOMBRE = '';
    } else {
      this.reporteSer.mandarSentenciaBU(this.reporteSeleccionado.RL_CODIGO).subscribe((res: MAERL) => {
        console.log(res);
        this.reporteSeleccionado = res[0];
      });
    }
  }

  cambio() {
    this.btnGuardar = false;
    this.btnLimpiar = false;
    this.btnNuevo = true;
    this.btnBorrar = false;
  }

  async llenarParametros() {
    const res = await this.reporteSer.mandarSentenciaParametros(this.reporteSeleccionado);
    const res1 = await this.reporteSer.mandarSentenciabi(this.reporteSeleccionado);//GSRF
    // this.parametro = res;
    this.segManteRep = res;
    this.segManbi = res1;//GSRF
    this.segManteRep.map(x => {
      x.NUEVO = false;
    });

    this.aggrid.refreshaggrid(this.segManteRep, this.defaultColManteRep.objeto);
    this.aggrid.refreshaggrid(this.segManbi, this.defaultColbi.objeto);//GSRF
  }

  nuevoParametro() {
    /*
      //RLP_PARAMETRO,RLP_TIPOVARIABLE,RLP_ETIQUETA
     //RLP_TIPO,RLP_UBICACION,RLP_DIMENSION,RLP_MAXIMO,RLP_CONSULTA
     //RLP_CONDICION,RLP_CRITERIOS,RLP_TABLA"
     const nuevoParam: MAERLPARAM = {
       RLP_PARAMETRO: '', RLP_CRITERIOS: '', RLP_CONDICION: '', RLP_CONSULTA: '', RLP_ETIQUETA: '', RLP_DIMENSION:''
      , RL_CODIGO: this.reporteSeleccionado.RL_CODIGO, RLP_OBJETO: this.reporteSeleccionado.RL_OBJETO, RLP_TABLA: ''
     }; */
    //this.parametro.push(nuevoParam);
    /*V2*/
    const newParam: MAERLPARAM = {
      NUEVO: true, RLP_PARAMETRO: '', RLP_CRITERIOS: '', RLP_CONDICION: '', RLP_CONSULTA: '', RLP_ETIQUETA: '', RLP_MAXIMO: '1.00 ',
      RLP_DIMENSION: '1', RLP_TIPOVARIABLE: '', RLP_TIPO: '', RLP_UBICACION: '1',
      RL_CODIGO: this.reporteSeleccionado.RL_CODIGO, RLP_OBJETO: this.reporteSeleccionado.RL_OBJETO, RLP_TABLA: ''
    };
    this.segManteRep.push(newParam);
    this.aggrid.refreshaggrid(this.segManteRep, this.defaultColManteRep.objeto);

  }

  /*Aquiguarda*/
  guardarParametro() {
    console.log(this.selectedManteRep);
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
      this.defaultColManteRep.objeto].stopEditing();

    const modulo: MAERLPARAM = {
      RLP_PARAMETRO: this.selectedManteRep.RLP_PARAMETRO,
      RLP_ETIQUETA: this.selectedManteRep.RLP_ETIQUETA,
      RLP_TIPO: this.selectedManteRep.RLP_TIPO,
      RLP_UBICACION: this.selectedManteRep.RLP_UBICACION,
      RLP_DIMENSION: this.selectedManteRep.RLP_DIMENSION,
      RLP_MAXIMO: this.selectedManteRep.RLP_MAXIMO,
      RLP_CONSULTA: this.selectedManteRep.RLP_CONSULTA,
      RLP_CONDICION: this.selectedManteRep.RLP_CONDICION,
      RLP_CRITERIOS: this.selectedManteRep.RLP_CRITERIOS,
      RLP_TABLA: this.selectedManteRep.RLP_TABLA,
      RLP_TIPOVARIABLE: this.selectedManteRep.RLP_TIPOVARIABLE

    };
    let valido = true;

    for (const key in modulo) {
      if (key === 'RLP_CONDICION' ||
        key === 'RLP_CONSULTA' ||
        key === 'RLP_CRITERIOS' ||
        key === 'RLP_ETIQUETA' ||
        key === 'RLP_PASAFOR' ||
        key === 'RLP_TIPO' ||
        key === 'RLP_DIMENSION' ||
        key === 'RLP_MAXIMO' ||
        key === 'RLP_TABLA' || key === 'RLP_TIPOVARIABLE' ) {  

      } else {        
        if (modulo[key] === null || modulo[key] === '') {
          valido = false;
        }
      }
    }
    
    if (valido) {
      let aud = `${this.reporteSeleccionado.OPC_CODIGO}/${this.selectedManteRep.RL_CODIGO}`;
      console.log(this.selectedManteRep.NUEVO);
      if (this.selectedManteRep.NUEVO == true) {

        this.reporteSer.insertarparametro(this.selectedManteRep).toPromise().then(res => {
          this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
          this.auditoriaGralService.registrarAuditoria('SACI_MAERLPARAM', String(aud), 'I',
            '', '01', '', '', '', '').toPromise().then(() => {
            });
          this.cancelarCambiosParametros();
          this.selectedManteRep.NUEVO = false;
        }).catch(e => {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        });
      } else if (this.selectedManteRep.NUEVO == false) {

        this.reporteSer.actualizarparametro(this.selectedManteRep, this.selectedManteRep.RLP_UBICACION, this.selectedManteRep.RLP_PARAMETRO).toPromise().then(res => {

          this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
          this.auditoriaGralService.registrarAuditoria('SACI_MAERLPARAM', String(aud), 'A',
            '', '01', '', '', '', '').toPromise().then(() => {
            });
          this.cancelarCambiosParametros();
          this.selectedManteRep.NUEVO = false;
        }).catch(e => {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        });
      }
    } else {

      this.mensajeAlerta('info', 'Información', 'Se han encontrado campos vacíos');
    }

    /*
  
    this.reporteSer.mandarSentenciaParametros(this.reporteSeleccionado).subscribe((res) => {
      this.parametroAux = res;
      let i = 0;
      let contador = 0;
      let ubicacion = 0;
      let parametro = '';
      if (res.length === 0) {
        contador = 0;
      } else {
        do {
          if (this.parametroAux[i].RLP_UBICACION === this.parametroSeleccionado.RLP_UBICACION) {
            contador++;
            ubicacion = this.parametroAux[i].RLP_UBICACION;
            parametro = this.parametroAux [i].RLP_PARAMETRO;
          }
          i++;
        } while (i < this.parametroAux.length);
      }
      if (contador === 1) {
        if (this.parametroSeleccionado.RLP_TIPOVARIABLE === undefined) {
          this.parametroSeleccionado.RLP_TIPOVARIABLE = 'P';
        } else {
          if (this.parametroSeleccionado.RLP_TIPOVARIABLE.RLP_TIPOVARIABLE === undefined) {
          } else {
            this.parametroSeleccionado.RLP_TIPOVARIABLE = this.parametroSeleccionado.RLP_TIPOVARIABLE.RLP_TIPOVARIABLE;
          }
        }
        if (this.parametroSeleccionado.RLP_TIPO === undefined) {
          this.parametroSeleccionado.RLP_TIPO = 'S';
        } else {
          if (this.parametroSeleccionado.RLP_TIPO.RLP_TIPO === undefined) {
          } else {
            this.parametroSeleccionado.RLP_TIPO = this.parametroSeleccionado.RLP_TIPO.RLP_TIPO;
          }
        }
        Number(this.parametroSeleccionado.RLP_MAXIMO).toFixed(1);
        this.reporteSer.actualizarparametro(this.parametroSeleccionado, ubicacion, parametro).subscribe(() => {
          this.message.add({
            key: 'mantenimiento',
            severity: 'success',
            summary: 'Actualizado',
            detail: 'Se actualizo el parametro con exito'
          });
        }, error1 => {
          console.log(error1.error);
        });
      } else if (contador === 0) {
        if (this.parametroSeleccionado.RLP_TIPOVARIABLE === undefined) {
          this.parametroSeleccionado.RLP_TIPOVARIABLE = 'P';
        } else {
          if (this.parametroSeleccionado.RLP_TIPOVARIABLE.RLP_TIPOVARIABLE === undefined) {
          } else {
            this.parametroSeleccionado.RLP_TIPOVARIABLE = this.parametroSeleccionado.RLP_TIPOVARIABLE.RLP_TIPOVARIABLE;
          }
        }
        if (this.parametroSeleccionado.RLP_TIPO === undefined) {
          this.parametroSeleccionado.RLP_TIPO = 'S';
        } else {
          if (this.parametroSeleccionado.RLP_TIPO.RLP_TIPO === undefined) {
          } else {
            this.parametroSeleccionado.RLP_TIPO = this.parametroSeleccionado.RLP_TIPO.RLP_TIPO;
          }
        }
        Number(this.parametroSeleccionado.RLP_MAXIMO).toFixed(1);
        this.reporteSer.insertarparametro(this.parametroSeleccionado).subscribe(() => {
          this.message.add({
            key: 'mantenimiento',
            severity: 'success',
            summary: 'Insertado',
            detail: 'Se inserto el parametro con exito'
          });
        });
      }
    });
    */
  }

  seleccionParametro(param) {
    this.parametroSeleccionado = param;
  }

  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'segMAERLPARAM',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });

  }

  eliminarParametro() {

    if (this.selectedManteRep != undefined) {
      let aud = `${this.reporteSeleccionado.OPC_CODIGO}/${this.selectedManteRep.RL_CODIGO}`;
      this.reporteSer.eliminarParametro(this.selectedManteRep).toPromise().then((res) => {
        this.mensajeAlerta('success', 'Información', '¡Modulo eliminado!');
        this.auditoriaGralService.registrarAuditoria('SACI_MAERLPARAM', String(aud), 'E',
          '', '01', '', '', '', '').toPromise().then(() => {
          });
        this.cancelarCambiosParametros();
        //this.parametro = this.parametro.filter((val, i) => i !== this.parametro.indexOf(this.parametroSeleccionado));
      }).catch(e => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      });
    }
    /*   this.reporteSer.eliminarParametro(this.parametroSeleccionado).subscribe((res) => {
        console.log(res);
        this.parametro = this.parametro.filter((val, i) => i !== this.parametro.indexOf(this.parametroSeleccionado));
      }); */
  }

  async cancelarCambiosParametros() {
    const res = await this.reporteSer.mandarSentenciaParametros(this.reporteSeleccionado);
    const res1 = await this.reporteSer.mandarSentenciabi(this.reporteSeleccionado);//GSRF
    this.segManbi = res1;//GSRF
    this.parametroAux = res;
    this.segManteRep = res;
    this.parametro = this.parametroAux;
    this.btnGuardar = true;
    this.btnLimpiar = true;
    this.btnNuevo = false;
    this.btnBorrar = false;
    //CDPJ
    this.segManteRep.map(x => {
      x.NUEVO = false;
    });
    this.aggrid.refreshaggrid(this.segManteRep, this.defaultColManteRep.objeto);
    //CDPJ
    this.aggrid.refreshaggrid(this.segManbi, this.defaultColbi.objeto);//GSRF
  }

  inicializarBtn() {

    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnLimpiar = true;
    if(this.index === 2){
      this.btnGuardar = false;
    }
  }
  //GSRF
  selectbi(elemento) {

    if (elemento.object !== null) {
      this.selectedManbi = elemento.object;
    }
    console.log('++++++',this.selectedManbi)
  }
  nuevoParametrobi() {
   
    const newParam: MAERL_BI = {
      RL_CODIGO: this.reporteSeleccionado.RL_CODIGO,
      RLP_CONSULTA: '',
      RLP_NOMBRE:'',
    };
    this.segManbi.push(newParam);
    this.aggrid.refreshaggrid(this.segManbi, this.defaultColbi.objeto);
  }
  async guardarParametrobi() {
    console.log(this.segManbi);
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
    this.defaultColbi.objeto].stopEditing();
    for(let aux of this.segManbi ){
      console.log(aux)
      if(aux.RLP_NOMBRE === '' || aux.RLP_NOMBRE === null || aux.RLP_NOMBRE === undefined){
        this.mensajeAlerta('error', 'Error', 'Ingrese todos los campos');
        return
      }
      if(aux.RLP_CONSULTA  === '' || aux.RLP_CONSULTA  === null || aux.RLP_CONSULTA === undefined){
        this.mensajeAlerta('error', 'Error', 'Ingrese todos los campos');
        return
      }
    }
    const sql="delete from SACI_MAERLPARAM_BI WHERE RL_CODIGO='"+this.reporteSeleccionado.RL_CODIGO+"'";
    console.log(sql);
    await this.reporteSer.ejecutarsentcsv(sql);
    for(let aux of this.segManbi ){
      this.reporteSer.insertarparametrobi(aux).toPromise().then(res => {
        this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
        this.auditoriaGralService.registrarAuditoria('SACI_MAERLPARAM', String(aux.RLP_NOMBRE), 'I',
          '', '01', '', '', '', '').toPromise().then(() => {
          });
      }).catch(e => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      });
    }

    this.cancelarCambiosParametros();

  }
  eliminarParametrobi() {

    if (this.selectedManbi != undefined) {
      const sql="delete from SACI_MAERLPARAM_BI WHERE RL_CODIGO='"+this.reporteSeleccionado.RL_CODIGO+"' and RLP_NOMBRE='"+this.selectedManbi.RLP_NOMBRE+"'";
      this.reporteSer.ejecutarsentcsv(sql).then((res) => {
        this.mensajeAlerta('success', 'Información', '¡BI eliminado!');
        this.auditoriaGralService.registrarAuditoria('SACI_MAERLPARAM', String(this.selectedManbi.RLP_NOMBRE), 'E',
          '', '01', '', '', '', '').toPromise().then(() => {
          });
        this.cancelarCambiosParametros();
        
      }).catch(e => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      });
    }

  }
  //GSRF
}


