<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="segMaeOrgtoast"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="segOrgDialog" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 99%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
						 [botonRegresar]="btnRegresar" [barraBotonesCRUD]="false"
						 [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true"
						 (signal)="manejarSenales($event)"></app-invmaebarra>
	</div>

</div>
<div style="overflow: hidden; margin-top: -10px;" class="p-col-12">
	<p-tabView (onChange)="onTab1Change()" [(activeIndex)]="indexTab">
		<p-tabPanel header="Modulos" [disabled]="activateTabs[0]">
			<app-aggridsaci [width]="" *ngIf="visibleGridModulo"
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="segmaeorganizacionModulos"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefOrganizacionModulos"
							[defaultColDef]="defaultColDefMaeOrgModulos"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)=selectMaeOrgModulo($event)
							(cambios)="cambioModulo($event)"

			></app-aggridsaci>
		</p-tabPanel>
		<p-tabPanel header="Menús"  [disabled]="activateTabs[1]">
			<app-aggridsaci [width]="" *ngIf="visibleGridMenu"
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="segmaeorganizacionMenus"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefOrganizacionMenus"
							[defaultColDef]="defaultColDefMaeOrgMenu"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)=selectMaeOrgMenu($event)
							(cambios)="cambioMenu($event)"

			></app-aggridsaci>
		</p-tabPanel>
		<p-tabPanel header="Opciones" [disabled]="activateTabs[2]">
			<div class="divgrups">
				<div class="p-grid" style="margin-top: 10px; margin-bottom: 10px">
					<div class="p-col-4">
						<span *ngIf="moduloOpcion">Módulo: {{moduloOpcion.NOMBRE}}</span>
					</div>
					<div class="p-col-4">
						<span *ngIf="menuOpcion">Menú: {{menuOpcion.NOMBRE}}</span>
					</div>
				</div>
			</div>
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="segmaeorganizacionOpciones"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefOrganizacionOpciones"
							[defaultColDef]="defaultColDefMaeOrgOpciones"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)=selectMaeOrgOpcion($event)
							(cambios)="cambioOpcion($event)"

			></app-aggridsaci>
		</p-tabPanel>
	</p-tabView>
</div>
