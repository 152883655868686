import { Injectable } from '@angular/core';

@Injectable()
export class Usuario {
  identificacion: string;
  nombre: string;
  apellido: string;
  ruc: string;
  usuperfil: string;
  codperfil: string;
  ipfrontend: string;
  login: boolean = false;
  autenticacion: number;

  public saveStorage() {
    sessionStorage.setItem('usuario', JSON.stringify({
      identificacion: this.identificacion,
      nombre: this.nombre,
      apellido: this.apellido,
      ruc: this.ruc,
      usuperfil: this.usuperfil,
      codperfil: this.codperfil,
      login: this.login,
      autenticacion: this.autenticacion
    }));
  }
  
  public loadStorage() {
    var store = JSON.parse(sessionStorage.getItem('usuario'));
    if (store == null) {
      this.login = false;
    } else {
      this.identificacion = store.identificacion;
      this.nombre = store.nombre;
      this.apellido = store.apellido;
      this.ruc = store.ruc;
      this.usuperfil = store.usuperfil;
      this.codperfil = store.codperfil;
      this.login = store.login;
      this.autenticacion = store.autenticacion;
    }
  }

}
