import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Commaecargos} from '../cominterfaces/commaecargos';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class CommaecargosService {
  get auxsalto(): boolean {
    return this._auxsalto;
  }
  
  set auxsalto(value: boolean) {
    this._auxsalto = value;
  }
  
  get commaecargos(): Commaecargos[] {
    return this._commaecargos;
  }
  
  set commaecargos(value: Commaecargos[]) {
    this._commaecargos = value;
  }
  
  
 
  public way: string;
  private _auxsalto: boolean;
  private _commaecargos: Commaecargos[];
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService) {
    
    this.way = sessionStorage.getItem('way');
  }
  
  getCargos(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/cargo/107758adc31c', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  existeCargos(cargocodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/cargo/b2d5b6bb80299df2a', {
      elementos: {cargo_codigo: cargocodigo},
    }, this.confIniciales.httpOptions());
  }
  
  insertarCargo(cargo: Commaecargos): Observable<any[]> {
    return this.http.post<any>(this.way + '/compras/cargo/12bf1dbeab6c57000', {
      elementos: {
        p_cargo_codigo: cargo.CARGO_CODIGO,
        p_cargo_nombre: cargo.CARGO_NOMBRE,
        p_con_codigo: cargo.CON_CODIGO,
        p_cen_codigo: cargo.CEN_CODIGO,
        p_com_codigodes: cargo.COM_CODIGO,
      }
    }, this.confIniciales.httpOptions());
  }
  
  actualizarCargo(cargo: Commaecargos, codigo): Observable<any[]> {
    return this.http.post<any>(this.way + '/compras/cargo/299df2ab21b6c57000', {
      elementos: {
        p_cargo_codigo: cargo.CARGO_CODIGO,
        p_cargo_nombre: cargo.CARGO_NOMBRE,
        p_con_codigo: cargo.CON_CODIGO,
        p_cen_codigo: cargo.CEN_CODIGO,
        p_com_codigo: cargo.COM_CODIGO,
      }
    }, this.confIniciales.httpOptions());
  }
  
  eliminarCargo(cargo: Commaecargos): Observable<any[]> {
    return this.http.post<any>(this.way + '/compras/cargo/eacad2bab6c57000', {
      elementos: {
        p_cargo_codigo: cargo.CARGO_CODIGO,
      }
    }, this.confIniciales.httpOptions());
  }

  erFindCodCargo(codigo) {    
    return this.http.post(this.way + '/compras/cargo/edbe576b30d94000', {
      elementos: {
        CARGO_CODIGO: codigo
      },
    }, this.confIniciales.httpOptions());
  }
  //CDPJ
  encontrarArtCntaySer(strArtCodigo, strtipo) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/75d98bf76adab8000000', {
      elementos: {
        ArtCodigo: strArtCodigo,
        strTIPO: strtipo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
}
