import {Component, OnInit, ViewChild} from '@angular/core';
import {SegmaeperfilService} from '../segservicios/segmaeperfil.service';
import {Segmaeperfil} from '../seginterfaces/segmaeperfil';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
@Component({
  selector: 'app-segmaeperfil',
  templateUrl: './segmaeperfil.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class SegmaeperfilComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  selectedFiles2: any[] = [];
  treeData: any[] = [];
  arrayPermisos = [];
  
  btnGuardar: boolean;
  btnNuevo = false;
  btnBorrar: boolean;
  btnRegresar: boolean;
  btnBuscar: boolean;
  
  
  
  rowStyle;
  largo = '258';
  rowSelection = 'multiple';
  segmaePerfil: Segmaeperfil[] = [];
  selectedMaePerfil: Segmaeperfil;
  
  defaultColDefMaePerfiles;
  public frameworkComponents;
  activateTabs = [false, false];
  indexTab = 0;
  
  opcion: string;
  types: SelectItem[];
  arregloCons: any[];
  arregloBus: any[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  condicion: string;
  displayDialogBusquedaFast: boolean;
  spin = false;
  permisosEditados = [];
  
  columnDefMaePerfil = [
    {
      headerName: 'N', field: 'NUMERO', editable: false, width: 50,
    },
    {
      headerName: 'Código', field: 'Código', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Nombre', field: 'Nombre', cellEditor: 'cellPrueba', width: 300,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 100,
            tienecalculos: false,
          }
        }
    },
  ];
  
  
  constructor(
    private segmaePerfilService: SegmaeperfilService,
    private messageService: MessageService,
    private init: NuevoComponentService,
    private errorService: ErroresBaseDatosService,
    private confirmationService: ConfirmationService,
    private auditoriagral: AuditoriagralService,
  ) {
    this.agTable();
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefMaePerfiles = {
      editable: true,
      width: 130,
      objeto: 'maePerfil'
    };
  }
  
  async ngOnInit() {
    this.btnNuevo =  false;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.btnRegresar = false;
    this.btnBuscar = false;
    await this.consultarPerfil();
    
    
  }
  
  async consultarPerfil() {
    await this.segmaePerfilService.consultaPerfil(null).then( res => {
      
      // console.log(res);
      this.segmaePerfil = res;
      this.segmaePerfil.map( x => {
        x.NUMERO = this.segmaePerfil.indexOf( x ) + 1;
      }  );
      return;
    })
      .catch(e => {
        console.error(e);
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      });
  }
  
  selectMaePerfil(elemento) {
    if (elemento.object !== null ) {
      this.selectedMaePerfil = elemento.object;
      console.log(this.selectedMaePerfil);
      
    }
  }
  
  
  // ************************************************************************************
  // ========================== Llenar permisos en el arbol =============================
  
  async consultarPermisosPerfil() {
    this.spin = true;
    await this.segmaePerfilService.consultarPermisos(this.selectedMaePerfil).then( permisos => {
      // console.log( permisos );
      
      if ( permisos === null ) {
        this.arrayPermisos = [];
      } else {
        this.arrayPermisos = permisos;
      }
      
      this.crearNodosPermisosModulos();
      
    })
      
      .catch( e => {
        console.error(e);
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      });
  }
  
  async crearNodosPermisosModulos() {
    this.treeData = [];
    await this.segmaePerfilService.cargarModuloOpciones().then( res => {
      // console.log(res);
      for (const modulo of res) {
        const mod = {
          label: modulo.MODULO,
          data: modulo.PREFIJO,
          icon: 'pi pi-folder',
          tipo: 'MOD',
          modulo: modulo.PREFIJO,
          children: []
        };
        this.treeData.push(mod);
      }
      // console.log(this.treeData);
      this.crearNodosPermisosMenu();
      return;
      
      
    } ).catch( e => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);
      console.error(e);
    } );
    
  }
  
  async crearNodosPermisosMenu() {
    
    for ( const data of  this.treeData  ) {
      
      await this.segmaePerfilService.cargarMenuConOpciones( data.data ).then( res => {
        data.children = [];
        for ( const menu of res  ) {
          
          const men  = {
            label: menu.MENNOMBRE,
            data: menu.MENCODIGO,
            icon: 'pi pi-list',
            tipo: 'MEN',
            modulo: data.modulo,
            menu: menu.MENCODIGO,
            children: []
          };
          
          data.children.push( men  );
          
        }
        
        
      } ).catch( e => {
        console.error(e);
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      });
      
    }
    // console.log(this.treeData);
    this.consultarOpciones();
  }
  
  
  
  async consultarOpciones() {
    await this.segmaePerfilService.obtenerOpciones().then( opciones => {
      
      
      
      this.treeData.map( mod => {
        mod.children.map( men =>  {
          men.children = [];
          opciones.map( opc =>  {
            if ( opc.MENCODIGO === men.menu && opc.MODPREFIJO === mod.modulo ) {
              const opcion  = {
                label: opc.OPCNOMBRE,
                data: opc.OPCCODIGO,
                icon: 'pi pi-file-o',
                tipo: 'OPC',
                modulo: mod.modulo,
                menu: men.menu,
                opcion: opc.OPCCODIGO,
                children: [
                  {
                    label: 'Consulta',
                    data: 'SEL',
                    icon: 'pi pi-search',
                    tipo: 'SEL',
                    modulo: mod.modulo,
                    menu: men.menu,
                    opcion: opc.OPCCODIGO,
                    permiso: 0
                  },
                  {
                    label: 'Inserción',
                    data: 'INS',
                    icon: 'pi pi-plus',
                    tipo: 'INS',
                    modulo: mod.modulo,
                    menu: men.menu,
                    opcion: opc.OPCCODIGO,
                    permiso: 0
                  },
                  {
                    label: 'Actualización',
                    data: 'ACT',
                    icon: 'pi pi-pencil',
                    tipo: 'ACT',
                    modulo: mod.modulo,
                    menu: men.menu,
                    opcion: opc.OPCCODIGO,
                    permiso: 0
                  },
                  {
                    label: 'Eliminación',
                    data: 'ELI',
                    icon: 'pi pi-times',
                    tipo: 'ELI',
                    modulo: mod.modulo,
                    menu: men.menu,
                    opcion: opc.OPCCODIGO,
                    permiso: 0,
                    check: false
                  }
                ]
              };
              
              men.children.push( opcion );
            }
          });
        });
      });
      
      // console.log( this.treeData );
      this.setPermisos();
      return;
      
    }).catch( e => {
      console.error(e);
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    } );
  }
  
  
  setPermisos() {
    this.treeData.map( mod => {
      mod.children.map( men =>  {
        men.children.map( opc =>  {
          opc.children.map ( perm => {
            this.arrayPermisos.map( permiso => {
              
              if ( permiso.MENCODIGO ===  men.menu && permiso.MODPREFIJO === mod.modulo
                && permiso.OPCCODIGO === perm.opcion  ) {
                if (perm.tipo === 'INS') {
                  perm.permiso = permiso.PERINSERCION;
                  if (  perm.permiso ) {  perm.check = true; }
                }
                if (perm.tipo === 'SEL') {
                  perm.permiso = permiso.PERSELECCION;
                  if (  perm.permiso ) {  perm.check = true; }
                }
                if (perm.tipo === 'ACT') {
                  perm.permiso = permiso.PERACTUALIZACION;
                  if (  perm.permiso ) {  perm.check = true; }
                }
                if (perm.tipo === 'ELI') {
                  perm.permiso = permiso.PERELIMINACION;
                  if (  perm.permiso ) {  perm.check = true; }
                }
              }
              
              
            });
          });
        });
      });
    });
    this.checkArbol();
  }
  
  checkArbol() {
    this.treeData.map( mod => {
      mod.children.map( men =>  {
        men.children.map( opc =>  {
          opc.children.map ( perm => {
            if (  perm.permiso ) {
              const node = perm;
              opc.partialSelected = true;
              men.partialSelected = true;
              mod.partialSelected = true;
              this.selectedFiles2.push(...[node]);
              
            }
          });
        });
      });
    });
    this.spin = false;
  }
  
  // =======================================FIN Llenar permisos en el arbol==============================================================
  // *****************************************************************************************************
  
  manejarSenales(valor) {
    
    switch (valor) {
      case 'Nuevo':
        this.opcionNuevo();
        break;
      case 'Guardar':
        this.opcionGuardar();
        break;
      case 'Borrar':
        this.opcionBorrar();
        break;
      case 'Cancelar':
        this.cargar();
        break;
      case 'Buscar':
        this.buscarRegistro();
        break;
      case 'Salir':
        this.init.tabs.splice(this.init.tabindex, 1);
        break;
      
    }
  }
  opcionGuardar() {
    switch ( this.indexTab ) {
      case 0:
        if ( this.selectedMaePerfil.nuevo ) {
          this.guardarPerfil();
        }
        if ( this.selectedMaePerfil.editado ) {
          this.actualizarPerfil();
        }
        break;
      case 1:
        this.guardarPermisos();
        break;
    }
  }
  
  async cargar() {
    this.activateTabs = [false, false];
    this.permisosEditados = [];
    this.arrayPermisos  = [];
    switch ( this.indexTab ) {
      case 0:
        this.btnNuevo =  false;
        this.btnGuardar = true;
        this.btnBorrar  = false;
        this.btnRegresar = false;
        this.btnBuscar = false;
        await this.consultarPerfil();
        break;
      case 1:
        this.btnBorrar  = true;
        this.btnNuevo   = true;
        this.btnGuardar = true;
        
        this.consultarPermisosPerfil();
        break;
    }
    
  }
  
  opcionNuevo() {
    this.btnNuevo = true;
    this.btnBorrar = false;
    this.btnRegresar = false;
    this.innhabilitarTabs(this.indexTab);
    this.aggrid.isEditable(true);
    
    
    const nuevoPerfil: Segmaeperfil = {
      Código: '',
      Nombre: '',
      nuevo: true
    };
    
    this.segmaePerfil.push(nuevoPerfil);
    this.aggrid.refreshaggrid(this.segmaePerfil, this.defaultColDefMaePerfiles.objeto );
  }
  
  guardarPerfil() {
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
    this.defaultColDefMaePerfiles.objeto].stopEditing();
    const perfil: Segmaeperfil = {
      Nombre: this.selectedMaePerfil.Nombre,
      Código: this.selectedMaePerfil.Código,
    };
    if (  this.validarObjeto( perfil ) ) {
      this.segmaePerfilService.guardarPerfil( this.selectedMaePerfil.Código, this.selectedMaePerfil.Nombre )
        .then( async () => {
          return await this.auditoriagral.registrarAuditoria('SEG_MAEPERFIL',
            this.selectedMaePerfil.Código, 'I',
            '', '01', '', '', '', '').toPromise().then(() => {
            this.mensajeAlerta('success', 'Información', '¡Perfil guardado exitosamente!');
            this.cargar();
            return;
          });
          
          
        } )
        .catch( e => {
          console.error(e);
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        } );
    }
  }
  
  actualizarPerfil() {
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
    this.defaultColDefMaePerfiles.objeto].stopEditing();
    const perfil: Segmaeperfil = {
      Nombre: this.selectedMaePerfil.Nombre,
      Código: this.selectedMaePerfil.Código,
    };
    if (  this.validarObjeto( perfil ) ) {
      this.segmaePerfilService.actualizarPerfil( this.selectedMaePerfil.Código, this.selectedMaePerfil.Nombre, this.selectedMaePerfil.old_Código )
        .then( async () => {
          return await this.auditoriagral.registrarAuditoria('SEG_MAEPERFIL',
            this.selectedMaePerfil.Código, 'A',
            '', '01', '', '', '', '').toPromise().then(() => {
            this.mensajeAlerta('success', 'Información', '¡Perfil actualizado exitosamente!');
            this.cargar();
            return;
          });
          
        } )
        .catch( e => {
          console.error(e);
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        } );
    }
    
  }
  
  cambio(event) {
    
    this.btnGuardar = false;
    this.btnRegresar = false;
    this.btnNuevo = true;
    this.innhabilitarTabs(this.indexTab);
    if ( this.selectedMaePerfil.nuevo === undefined ) {
      this.selectedMaePerfil.editado = true;
      this.selectedMaePerfil.old_Código = this.selectedMaePerfil.Código;
    }
    
    
    
  }
  
  eliminarPerfil() {
    this.segmaePerfilService.eliminarPerfil( this.selectedMaePerfil.Código)
      .then( async () => {
        
        return await this.auditoriagral.registrarAuditoria('SEG_MAEPERFIL',
          this.selectedMaePerfil.Código, 'E',
          '', '01', '', '', '', '').toPromise().then(() => {
          this.mensajeAlerta('success', 'Información', '¡Perfil eliminado exitosamente!');
          this.cargar();
          return;
          
        });
        
        
      } )
      .catch( e => {
        console.error(e);
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      } );
  }
  
  innhabilitarTabs(indice: number ) {
    this.activateTabs = [true, true];
    this.activateTabs[indice] = false;
    
  }
  
  buscarRegistro() {
    this.opcion = 'BUSCAR';
    this.types = [
      {label: 'CODIGO', value: 'PERFIL_CODIGO'},
      {label: 'NOMBRE', value: 'PERFIL_NOMBRE'},
    ];
    
    this.busquedacampos = ['', '', ''];
    this.consulta = '*';
    this.tabla = 'SEG_MAEPERFIL';
    this.condicion = '';
    this.arregloCons = [];
    this.displayDialogBusquedaFast = true;
  }
  
  manejarSeleccion(valor) {
    
    if (this.opcion === 'BUSCAR') {
      this.displayDialogBusquedaFast = false;
      this.llenarTabla(valor);
    }
  }
  
  
  
  llenarTabla(datos) {
    let cont = 0;
    const detalle: Segmaeperfil[] = [];
    for (const dato of datos) {
      cont++;
      const itemSegPerfil: Segmaeperfil = {};
      itemSegPerfil.Código = dato.PERFIL_CODIGO;
      
      itemSegPerfil.Nombre = dato.PERFIL_NOMBRE === null ? '' : dato.PERFIL_NOMBRE;
      
      detalle.push(itemSegPerfil);
      if (cont >= 200) {
        break;
      }
      
      this.segmaePerfil = detalle;
    }
  }
  
  
  onTab1Change() {
    switch ( this.indexTab ) {
      
      case 0:
        this.btnBorrar  = false;
        this.btnNuevo   = false;
        break;
      case 1:
        this.btnBorrar  = true;
        this.btnNuevo   = true;
        if ( this.selectedMaePerfil.nuevo === undefined) {
          this.treeData = [];
          this.consultarPermisosPerfil();
        }
        break;
    }
  }
  
  permisoSelect(event) {
    this.innhabilitarTabs(this.indexTab);
    this.btnGuardar = false;
    const nodo = event.node;
    console.log(nodo);
    if ( nodo.menu !== undefined && nodo.modulo !== undefined && nodo.opcion !== undefined && nodo.permiso !== undefined) {
      
      const permiso = {
        data:   nodo.data,
        opcion: nodo.opcion,
        menu: nodo.menu,
        modulo: nodo.modulo,
        permiso: 1
      };
      this.agregarPermisoEditado(permiso);
    } else {
      this.recorrerArray( nodo.children, 1 );
    }
    
    /* else if ( nodo.menu !== undefined && nodo.modulo !== undefined && nodo.opcion !== undefined ) {
      console.log( 'check opcion' );
    } else if ( nodo.menu !== undefined && nodo.modulo !== undefined  ) {
      console.log( ' check menu' );
    } else if ( nodo.modulo !== undefined  ) {
      console.log( 'check modulo' );
      this.recorrerArray( nodo.children, 1 );
    }*/
    
    
    
  }
  
  permisoUnselect(event) {
    this.innhabilitarTabs(this.indexTab);
    this.btnGuardar = false;
    const nodo = event.node;
    console.log(nodo);
    if ( nodo.menu !== undefined && nodo.modulo !== undefined && nodo.opcion !== undefined && nodo.permiso !== undefined) {
      
      const permiso = {
        data:   nodo.data,
        opcion: nodo.opcion,
        menu: nodo.menu,
        modulo: nodo.modulo,
        permiso: 0
      };
      this.agregarPermisoEditado(permiso);
    } else {
      this.recorrerArray( nodo.children, 0 );
    }
    
    /*else if ( nodo.menu !== undefined && nodo.modulo !== undefined && nodo.opcion !== undefined ) {
      console.log( 'uncheck opcion' );
    } else if ( nodo.menu !== undefined && nodo.modulo !== undefined  ) {
      console.log( 'uncheck menu' );
    } else if ( nodo.modulo !== undefined  ) {
      console.log( 'uncheck modulo' );
      this.recorrerArray( nodo.children, 0 );
    }*/
    
  }
  
  
  recorrerArray( arr, i: number ) {
    arr.map( a => {
      if ( a.children) {
        this.recorrerArray(  a.children, i );
      } else {
        const permiso = {
          data:     a.data,
          opcion:   a.opcion,
          menu:     a.menu,
          modulo:   a.modulo,
          permiso:  i
        };
        this.agregarPermisoEditado(permiso);
      }
    });
  }
  
  agregarPermisoEditado(permiso) {
    
    console.log(permiso);
    // let perm;
    
    const perm1 = this.arrayPermisos.find( x =>  x.OPCCODIGO  === permiso.opcion
      &&  x.MENCODIGO  === permiso.menu  &&  x.MODPREFIJO  === permiso.modulo );
    console.log('perm1', perm1);
    
    if ( perm1 ) {
      const perm = this.permisosEditados.find( x =>   x.OPCCODIGO === perm1.OPCCODIGO
        && x.MENCODIGO ===  perm1.MENCODIGO && x.MODPREFIJO === perm1.MODPREFIJO );
      
      if ( perm ) {
        
        switch ( permiso.data   ) {
          
          case 'INS':
            perm.PERINSERCION = permiso.permiso;
            break;
          case 'SEL':
            perm.PERSELECCION = permiso.permiso;
            break;
          case 'ACT':
            perm.PERACTUALIZACION = permiso.permiso;
            break;
          case 'ELI':
            perm.PERELIMINACION = permiso.permiso;
            break;
        }
        
        perm.PERTODO = ( perm.PERINSERCION === 1 && perm.PERSELECCION === 1
          && perm.PERACTUALIZACION === 1 && perm.PERELIMINACION === 1  ) ? 1 : 0;
        
      } else {
        perm1.editado = true;
        switch ( permiso.data ) {
          case 'INS':
            perm1.PERINSERCION = permiso.permiso;
            break;
          case 'SEL':
            perm1.PERSELECCION = permiso.permiso;
            break;
          case 'ACT':
            perm1.PERACTUALIZACION = permiso.permiso;
            break;
          case 'ELI':
            perm1.PERELIMINACION = permiso.permiso;
            break;
        }
        console.log(perm1);
        this.permisosEditados.push(perm1);
      }
      
    } else {
      
      console.log('undefinido');
      
      
      const perm2 = this.permisosEditados.find( x =>  x.OPCCODIGO  === permiso.opcion
        &&  x.MENCODIGO  === permiso.menu  &&  x.MODPREFIJO  === permiso.modulo );
      
      if ( perm2 ) {
        switch ( permiso.data ) {
          case 'INS':
            perm2.PERINSERCION = permiso.permiso;
            break;
          case 'SEL':
            perm2.PERSELECCION = permiso.permiso;
            break;
          case 'ACT':
            perm2.PERACTUALIZACION = permiso.permiso;
            break;
          case 'ELI':
            perm2.PERELIMINACION = permiso.permiso;
            break;
        }
        perm2.PERTODO = ( perm2.PERINSERCION === 1 && perm2.PERSELECCION === 1
          && perm2.PERACTUALIZACION === 1 && perm2.PERELIMINACION === 1  ) ? 1 : 0;
      } else {
        const permisoPerfil = {
          MENCODIGO: permiso.menu,
          MODPREFIJO: permiso.modulo,
          OPCCODIGO: permiso.opcion,
          PERACTUALIZACION: 0,
          PERELIMINACION: 0,
          PERINSERCION: 0,
          PERSELECCION: 0,
          PERTODO: 0,
          nuevo: true
        };
        
        switch ( permiso.data ) {
          case 'INS':
            permisoPerfil.PERINSERCION = 1;
            break;
          case 'SEL':
            permisoPerfil.PERSELECCION = 1;
            break;
          case 'ACT':
            permisoPerfil.PERACTUALIZACION = 1;
            break;
          case 'ELI':
            permisoPerfil.PERELIMINACION = 1;
            break;
        }
        this.permisosEditados.push(permisoPerfil);
      }
      
      
      
      
    }
    
    console.log(this.permisosEditados);
  }
  
  async guardarPermisos() {
    this.spin = true;
    for ( const permiso of this.permisosEditados ) {
      
      if ( permiso.nuevo ) {
        await this.segmaePerfilService.guardarPermiso(this.selectedMaePerfil.Código, permiso).then( async () => {
          
          return  await this.auditoriagral.registrarAuditoria('SEG_MAEPERMISOPERFIL',
            this.selectedMaePerfil.Código, 'I',
            '', '01', '', '', '', '').toPromise().then(() => {
            return;
          });
          
        } )
          .catch( e => {
            
            console.error(e);
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
            this.mensajeAlerta('error', 'Error', mensaje);
          });
      }
      
      if ( permiso.editado ) {
        await this.segmaePerfilService.actualizarPermiso(this.selectedMaePerfil.Código, permiso).then( async () => {
          
          return  await this.auditoriagral.registrarAuditoria('SEG_MAEPERMISOPERFIL',
            this.selectedMaePerfil.Código, 'A',
            '', '01', '', '', '', '').toPromise().then(() => {
            return;
          });
          
        } )
          .catch( e => {
            console.error(e);
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
            this.mensajeAlerta('error', 'Error', mensaje);
          });
      }
      
    }
    
    //  this.mensajeAlerta('success', 'Información', '¡Permisos guardados exitosamente!');
    this.spin = false;
    this.cargar();
  }
  
  validarObjeto( objeto ) {
    let valido = true;
    for ( const key in objeto ) { // recorrer campos de un objeto
      if ( objeto[key] === null || objeto[key] === '') {
        valido = false;
      }
    }
    return  valido;
  }
  
  opcionBorrar() {
    if ( this.selectedMaePerfil !== undefined  ) {
      this.confirmationService.confirm({
        message: '¿Está seguro que desea eliminar el perfil?',
        header: 'Eliminar Grupo',
        icon: 'pi pi-exclamation-triangle',
        key: 'segPerfilDialog',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {
          switch ( this.indexTab ) {
            
            case 0:
              this.eliminarPerfil();
              break;
            case 1:
              break;
            
          }
        },
        reject: () => {
        }
      });
    }
  }
  
  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'segMaePerfiltoast',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
    
  }
  
}
