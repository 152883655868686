import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from 'src/app/usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class UploadfilesService {

  private way: string;

  constructor(
    private http: HttpClient,
     public usuarioEsquema: UsuarioEsquema,
     public usuario: Usuario,
     private confIniciales: ConfInicialesService ) { 
       this.way = sessionStorage.getItem('way');
  }

  obtenerDocumentos(origen, nroComprobante) {
    return this.http.post<any[]>(this.way + '/compras/docalmacenados/c2fad5771c55700', {
      elementos: {
        ORIGEN_TRANSACCION: origen,
        NUMERO_COMPROBANTE: nroComprobante
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  uploadFiles(archivo, nombre) {
    return this.http.post<any[]>(this.way + '/compras/docalmacenados/7b2d575cf955dc', {
      elementos: {
        file: archivo,
        name: nombre
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerCompania() {
    return this.http.post<any[]>(this.way + '/seg/compania/acb9d662d955e80', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  guardarDocAlmacenado(strOrigen, strComprobante, strNombreOriginal, strNombreFisico, intTamanio) {
    return this.http.post<any[]>(this.way + '/compras/docalmacenados/12bf1dbeb70c55000', {
      elementos: {
        ORIGEN: strOrigen,
        COMPROBANTE: strComprobante,
        NOMBRE_ORIGINAL: strNombreOriginal,
        NOMBRE_FISICO: strNombreFisico,
        TAMANIO: intTamanio
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDocument(nombre) {
    return this.http.post<any[]>(this.way + '/compras/docalmacenados/d7718dc315555800', {
      elementos: {
        filename: nombre
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  deleteDocument(nombre) {
    return this.http.post(this.way + '/compras/docalmacenados/eacacdc3155b2800', {
      elementos: {
        filename: nombre
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarDocumento(nombre) {
    return this.http.post(this.way + '/compras/docalmacenados/eacacdc33d676000', {
      elementos: {
        filename: nombre
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerPermisoUsuario(strIdUsuario) {
    return this.http.post<any[]>(this.way + '/compras/docalmacenados/c2fb976ed2f7400', {
        elementos: {
          USUARIO: strIdUsuario
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  obtenerMegasUitilizadas() {
    return this.http.post<any[]>(this.way + '/compras/docalmacenados/185f6ce82b9eed000', {
        elementos: {}
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

}
