import {Component, OnInit, ViewChild} from '@angular/core';
import {SacimaenumeracionCService} from '../segservicios/sacimaenumeracion-c.service';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {SacimaenumeracionC} from '../seginterfaces/sacimaenumeracionC';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {Usuario} from '../../usuario';
import {DatePipe} from '@angular/common';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {ConfirmationService, MessageService} from 'primeng';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {AggridBanComponent} from '../../ban/aggrid-ban/aggrid-ban.component';
@Component({
  selector: 'app-sacimaenumeracion-c',
  templateUrl: './sacimaenumeracion-c.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class SacimaenumeracionCComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;
  btnBuscar: boolean;
    //CONFIGURACIONES CDPJ
    hmpConfiguracion: any[];//CDPJ
    CNAFEPV:number

  defaultColDefSaciMaeNumeracionC;
  public frameworkComponents;
  rowStyle;
  largo = '258';
  rowSelection = 'multiple';
  saciMaeNumeracionC: SacimaenumeracionC [] = [];
  selectedSaciMaeNumeracionC: SacimaenumeracionC;
  
  docRef: string;
  
  columnDefMaeNumeracionC = [
    {
      headerName: 'Clase', field: 'NUM_CLASE', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: false,
            
          }
        }
    },
    {
      headerName: 'Doc/Ref', field: 'NUM_DOCREF', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            opcionbuscar: true,
          }
        }
    },
    {
      headerName: 'Nemónico', field: 'NUM_NEMONICO', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Serie', field: 'NUM_SERIE', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Inicio', field: 'NUM_SECINICIAL', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: 'Final', field: 'NUM_SECFINAL', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: 'Actual', field: 'NUM_SECACTUAL', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: 'Autorización', field: 'NUM_AUTORIZACION', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.habilitarAutorizacion(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Fecha Aut.', field: 'NUM_FECHAINI', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.habilitarCelda(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: true,
            tamanocelda: 10,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Fecha Cad.', field: 'NUM_FECHACAD', cellEditor: 'cellPrueba', /*valueGetter: params => {
        const fecha = this.setFecha(params) ;
        return fecha; },*/ width: 100,
      editable: (params) => {
        const boolEdit = this.habilitarCelda(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: true,
            tamanocelda: 10,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: 'Ati.', field: 'NUM_AUTOIMPRESOR', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.habilitarCelda(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 1,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'FE', field: 'NUM_FE', cellRendererFramework: AggridBanComponent,
      editable: false, width: 80,
      onCellClicked: (params) => {
        this.seleccionChkFacElect();
      }
    },
  ];
  
  constructor(
    public numCService: SacimaenumeracionCService,
    private init: NuevoComponentService,
    private usuario: Usuario,
    private datePipe: DatePipe,
    private auditoriagral: AuditoriagralService,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private confirmationService: ConfirmationService,
  ) {
    this.numCService.detNumeracionC = [];
    this.agTable();
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefSaciMaeNumeracionC = {
      editable: true,
      width: 130,
      objeto: 'maeNumeracionC'
    };
  }
  
  
  
  habilitarAutorizacion(params) {
    
    if (params.NUM_DOCREF === 'FAC' || params.NUM_DOCREF === 'NV' || params.NUM_DOCREF === 'DVF') {
      return true;
    }
    return false;
  }
  
  habilitarCelda(params) {
    
    if (params.NUM_DOCREF === 'FAC' || params.NUM_DOCREF === 'NV') {
      return true;
    }
    return false;
  }
  
  isEdit(data): boolean {
    if (data.nuevo === true) {
      return true;
    }
    return false;
  }
  
  ngOnInit(): void {
    
    this.cargar();
  }
  
  cargar() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.botonAnterior = true;
    this.botonPrimero = true;
    this.botonSiguiente = true;
    this.botonUltimo = true;
    this.btnBuscar = true;
    
    this.getConfiguracion();//CDPJ
    this.consultarNumeracionC();
  }
  //CDPJ
  seleccionChkFacElect(){
    this.cambio(event)
  }
  getConfiguracion() {
    this.auditoriagral.obtenerConfiguracion1().subscribe((conf) => {
      this.hmpConfiguracion = conf;
      this.CNAFEPV = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CNAFEPV').CFG_VALOR1;
      if (this.CNAFEPV !== 1) {
        this.columnDefMaeNumeracionC.splice(11, 1);
      }


      this.aggrid.refreshColumnDefs();
    });
  }
  //CDPJ
  
  async consultarNumeracionC() {
    await this.numCService.consultarNumeracionC().then(r => {
      this.numCService.detNumeracionC = r;
      //CDPJ
      this.numCService.detNumeracionC.map(er=>{
        er.NUM_FE=er.NUM_FE === null || er.NUM_FE === undefined?0:er.NUM_FE
        if(er.NUM_FE === 0){
          er.NUM_FE=false
        }else if(er.NUM_FE === 1){
          er.NUM_FE=true
        }
      })
      //CDPJ
    }).catch(e => console.error(e));
  }
  
  
  selectMaeNumeracionC(elemento) {
    if (elemento.object !== null) {
      this.selectedSaciMaeNumeracionC = elemento.object;
    }
  }
  
  cambio(event) {
    this.btnGuardar = false;
    this.btnRegresar = false;
    this.btnNuevo = true;
    if (  this.selectedSaciMaeNumeracionC.NUM_FECHACAD !== null ) {
      if (this.selectedSaciMaeNumeracionC.NUM_FECHACAD.includes('-')  ) {
        this.selectedSaciMaeNumeracionC.NUM_FECHACAD = this.datePipe.transform(this.selectedSaciMaeNumeracionC.NUM_FECHACAD, 'dd/MM/yyyy');
      }
      
    }
    if (  this.selectedSaciMaeNumeracionC.NUM_FECHAINI !== null ) {
      if (this.selectedSaciMaeNumeracionC.NUM_FECHAINI.includes('-')) {
        this.selectedSaciMaeNumeracionC.NUM_FECHAINI = this.datePipe.transform(this.selectedSaciMaeNumeracionC.NUM_FECHAINI, 'dd/MM/yyyy');
      }
    }
    
    if (this.selectedSaciMaeNumeracionC.nuevo === undefined) {
      this.selectedSaciMaeNumeracionC.editado = true;
    }
  }
  
  manejarSenales(valor) {
    switch (valor) {
      case 'Nuevo':
        this.opcionNuevo();
        break;
      case 'Guardar':
        this.opcionGuardar();
        break;
      case 'Borrar':
        this.opcionEliminar();
        break;
      case 'Cancelar':
        
        this.cargar();
        break;
      case 'Salir':
        
        this.init.tabs.splice(this.init.tabindex, 1);
        break;
      
    }
  }
  
  opcionNuevo() {
    this.btnNuevo = true;
    this.btnRegresar = false;
    this.aggrid.isEditable(true);
    
    const newMaeNumeracionC: SacimaenumeracionC = {
      COMNOMBRE: '',
      COM_CODIGO: '01',
      NUM_AUTOIMPRESOR: '',
      NUM_AUTORIZACION: '',
      NUM_AUTORIZACIONANT: '',
      NUM_CLASE: '',
      NUM_DOCREF: '',
      NUM_FECHACAD: '',
      NUM_FECHAINI: '',
      NUM_NEMONICO: '',
      NUM_SECACTUAL: '000000001',
      NUM_SECFINAL: '999999999',
      NUM_SECINICIAL: '000000001',
      NUM_SERIE: '',
      nuevo: true,
    };
    
    this.numCService.detNumeracionC.push(newMaeNumeracionC);
    this.aggrid.refreshaggrid(this.numCService.detNumeracionC, this.defaultColDefSaciMaeNumeracionC.objeto);
  }
  
  async opcionGuardar() {
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
    this.defaultColDefSaciMaeNumeracionC.objeto].stopEditing();
    if (this.selectedSaciMaeNumeracionC.NUM_FECHACAD !== null) {
      if (this.selectedSaciMaeNumeracionC.NUM_FECHACAD.includes('-')) {
        this.selectedSaciMaeNumeracionC.NUM_FECHACAD = this.datePipe.transform(this.selectedSaciMaeNumeracionC.NUM_FECHACAD, 'dd/MM/yyyy');
      }
    }
    if (this.selectedSaciMaeNumeracionC.NUM_FECHAINI !== null) {
      if (this.selectedSaciMaeNumeracionC.NUM_FECHAINI.includes('-')) {
        this.selectedSaciMaeNumeracionC.NUM_FECHAINI = this.datePipe.transform(this.selectedSaciMaeNumeracionC.NUM_FECHAINI, 'dd/MM/yyyy');
      }
    }

    
    if (this.selectedSaciMaeNumeracionC.nuevo) {
      //CDPJ
      console.log('FE',this.selectedSaciMaeNumeracionC.NUM_FE)
      if(this.selectedSaciMaeNumeracionC.NUM_FE === null || this.selectedSaciMaeNumeracionC.NUM_FE === undefined){
        this.selectedSaciMaeNumeracionC.NUM_FE=0
      }else{
        if(this.selectedSaciMaeNumeracionC.NUM_FE === true){
          this.selectedSaciMaeNumeracionC.NUM_FE=0
        }else if(this.selectedSaciMaeNumeracionC.NUM_FE === false){
          this.selectedSaciMaeNumeracionC.NUM_FE=1
        }
      }
      //CDPJ
      await this.numCService.insertarNumeracionC(this.selectedSaciMaeNumeracionC).then(async () => {
        return await this.auditoriagral.registrarAuditoria('SACI_MAENUMERACION',
          this.selectedSaciMaeNumeracionC.NUM_CLASE + '/' + this.selectedSaciMaeNumeracionC.NUM_DOCREF + '/SEG', 'I',
          '', '01', '', '', '', '').toPromise().then(() => {
          this.mensajeAlerta('success', 'Información', '¡Numeración creada exitosamente!');
          this.cargar();
        });
        
      }).catch(e => {
        console.error(e);
        const mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      });
    }
    
    
    if (this.selectedSaciMaeNumeracionC.editado) {
      //CDPJ
      console.log('FEeDIT',this.selectedSaciMaeNumeracionC.NUM_FE)
      if(this.selectedSaciMaeNumeracionC.NUM_FE === null || this.selectedSaciMaeNumeracionC.NUM_FE === undefined){
        this.selectedSaciMaeNumeracionC.NUM_FE=0
      }else{
        if(this.selectedSaciMaeNumeracionC.NUM_FE === true){
          this.selectedSaciMaeNumeracionC.NUM_FE=0
        }else if(this.selectedSaciMaeNumeracionC.NUM_FE === false){
          this.selectedSaciMaeNumeracionC.NUM_FE=1
        }

      }
      //CDPJ
      await this.numCService.actualizarNumeracionC(this.selectedSaciMaeNumeracionC).then(async () => {
        
        return await this.auditoriagral.registrarAuditoria('SACI_MAENUMERACION',
          this.selectedSaciMaeNumeracionC.NUM_CLASE + '/' + this.selectedSaciMaeNumeracionC.NUM_DOCREF + '/SEG', 'A',
          '', '01', '', '', '', '').toPromise().then(() => {
          this.mensajeAlerta('success', 'Información', '¡Numeración actulizada exitosamente!');
          this.cargar();
        });
      }).catch(e => {
        console.error(e);
        const mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      });
    }
    
    
  }
  
  async opcionEliminar() {
    if (this.selectedSaciMaeNumeracionC !== undefined && this.selectedSaciMaeNumeracionC.nuevo === undefined) {
      this.confirmationService.confirm({
        message: '¿Está seguro que desea eliminar la numeración por clase ' + this.selectedSaciMaeNumeracionC.NUM_CLASE +  '?',
        header: 'Eliminar Grupo',
        icon: 'pi pi-exclamation-triangle',
        key: 'sacimaeNumC',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {
          this.eliminarNumeracion();
        },
        reject: () => {
        }
      });
    }
    
  }
  
  async eliminarNumeracion() {
    await this.numCService.elimminarNumeracionC(this.selectedSaciMaeNumeracionC).then(async () => {
      
      return await this.auditoriagral.registrarAuditoria('SACI_MAENUMERACION',
        this.selectedSaciMaeNumeracionC.NUM_CLASE + '/' + this.selectedSaciMaeNumeracionC.NUM_DOCREF + '/SEG', 'E',
        '', '01', '', '', '', '').toPromise().then(() => {
        this.mensajeAlerta('success', 'Información', '¡Numeración creada exitosamente!');
        this.cargar();
      });
    }).catch(e => {
      console.error(e);
      const mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    });
  }
  
  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'sacimaeNumC',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
    
  }
  
  
}
