import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {BanDetModeloRetencion, BanDetModeloVarios} from '../baninterfaces/bantrntarjeta';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BantrntarjetaService {
  
  public detModeloRetencion: BanDetModeloRetencion[][] = [];
  public detModeloVarios: BanDetModeloVarios[][] = [];
  public lblTotalRetenciones: any;
  public lblTotalVarios: any;
  
  private way: string;

  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, private confIniciales: ConfInicialesService,
              private init: NuevoComponentService) {
    this.way = sessionStorage.getItem('way');
  }
  
  navegarRegistro(strTipo, codigoTarjeta) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/1757dd0dba12e7000', {
      elementos: {
        p_tipo: strTipo,
        p_com_codigo: '01',
        p_trntarjeta_codigo: codigoTarjeta,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerDatosEnc(trnTarjetaCodigo) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/185f5aaee38ebb000', {
        elementos: {
          trntarjeta_codigo: trnTarjetaCodigo
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  obtenerTRNTARJETADET(codigoTarjeta) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/c2fbdddfaadb600', {
      elementos: {
        p_opcion: 'REG',
        p_filtro: 'a.com_codigo = \'01\'',
        p_condicion: 'a.trntarjeta_codigo = ' + codigoTarjeta
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerDetalleRetencion(trnTarjetaCodigo) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/617d6bbbb775d8', {
        elementos: {
          trncobro_nrocomprobante: trnTarjetaCodigo
        },
      
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  obtenerDetalleVarios(trnTarjetaCodigo) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/c2fad777eadcb00', {
        elementos: {
          trntarjeta_codigo: trnTarjetaCodigo
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  obtenerEncConciliacion(strBanCodigo, strTipo, strNumero ) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/c2faebb198bb280', {
        elementos: {
          ban_codigo: strBanCodigo,
          trnban_tipo: strTipo,
          trnban_numero: strNumero
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  obtenerDetConciliacion(strBanCodigo, strTipo, strNumero ) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/c2fad77598bb280', {
        elementos: {
          ban_codigo: strBanCodigo,
          trnban_tipo: strTipo,
          trnban_numero: strNumero
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  encontrarTrnTarjetaCodigo(numero) {
    return this.http.post(this.way + '/ban/depositotarjeta/1dbeeefd56d9870000', {
      elementos: {
        trnban_numero: numero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  encontrarAsiNroRet(numero) {
    return this.http.post(this.way + '/ban/depositotarjeta/3b6ae4afbc6ddc', {
      elementos: {
        trntarjeta_codigo: numero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  encontrarRetencionCodigo(numero) {
    return this.http.post(this.way + '/ban/depositotarjeta/3b7b775986ca18', {
      elementos: {
        retencion_codigo: numero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  encontrarAsiNro(bancodigo, numero) {
    return this.http.post(this.way + '/ban/depositotarjeta/76d5c95f78', {
      elementos: {
        ban_codigo: bancodigo,
        trnban_numero: numero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  encontrarTrnBanConcilia(numero) {
    return this.http.post(this.way + '/ban/depositotarjeta/76fbbbad5766300', {
      elementos: {
        trnban_numero: numero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  encontrarTarPorcentaje(numero) {
    return this.http.post(this.way + '/ban/depositotarjeta/3b7d56f38db1d8', {
      elementos: {
        tar_codigo: numero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerDatosEncConcil(trnTarjetaCodigo) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/185f5aaee1d763000', {
        elementos: {
          trntarjeta_codigo: trnTarjetaCodigo
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  calcularTotalRetenciones() {
    let totalRetencion = 0;
    for (let i = 0; i < this.detModeloRetencion[this.init.tabs[this.init.tabindex].indice].length; i++) {
      totalRetencion = totalRetencion +
        Number(this.detModeloRetencion[this.init.tabs[this.init.tabindex].indice][i].TRNRETENCION_TOTALRETENIDO);
    }
    
    this.lblTotalRetenciones = Number(totalRetencion).toFixed(2);
  }
  
  calcularTotalVarios() {
    let intIndiceLista = 0;
    let item: BanDetModeloVarios = {};
    let dblSaldo = 0;
    let dblTotalDebitos = 0;
    let dblTotalHaber = 0;
    let value = null;
  
    for (intIndiceLista = 0; intIndiceLista < this.detModeloVarios[this.init.tabs[this.init.tabindex].indice].length; intIndiceLista++) {
      item = this.detModeloVarios[this.init.tabs[this.init.tabindex].indice][intIndiceLista];
      if (item.CON_CODIGO !== '') {
        dblTotalDebitos = dblTotalDebitos + Number(item.DEBE);
        dblTotalHaber = dblTotalHaber + Number(item.HABER);
      }
    }
  
    dblSaldo = dblTotalDebitos - dblTotalHaber;
    value = dblSaldo;
    this.lblTotalVarios = Number(value).toFixed(2);
  }
  
  verificarCentroCostos(strCodCuenta) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/1f76e4f63aecc7000', {
        elementos: {
          con_codigo: strCodCuenta
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  encontrarTarjeta(strparametro): Observable<any> {
    return this.http.post(this.way + '/ban/depositotarjeta/76faadf55b9bbc0', {
      elementos: {
        parametro: strparametro
      },
    }, this.confIniciales.httpOptions());
  }
  
  encontrarBanco(strparametro): Observable<any> {
    return this.http.post(this.way + '/ban/depositotarjeta/1db5aaeb55d98', {
      elementos: {
        parametro: strparametro
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  listarDatos(strTarCodigo) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/565ceab6d5771c', {
      elementos: {
        p_opcion: 'LTNC',
        p_filtro: 'a.com_codigo = \'01\'',
        p_condicion: 'a.bancli_codigo = \'' + strTarCodigo + '\''
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  listarDatosConcil() {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/2b2e755b6abb8e0000', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  guardarEncTarjeta(trntarjetaCodigo, trntarjetaNrorecap, trntarjetaFechatrn, trntarjetaImpcobrado, trntarjetaImcomision, trntarjetaImpretfuente,
                    trntarjetaImpretiva, banCodigo, tarCodigo, comCodigo, trntarjetaOrigen, trntarjetaObservacion, trntarjetaPorctar,
                    trntarjetaComision, trntarjetaTotimporte, trntarjetaVouchers, trntarjetaBasecomision, trntarjetaRetencion, trntarjetaAsimul) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/43cadb5d76755c', {
      elementos: {
        p_trntarjeta_codigo: trntarjetaCodigo,
        p_trntarjeta_nrorecap: trntarjetaNrorecap,
        p_trntarjeta_fechatrn: trntarjetaFechatrn,
        p_trntarjeta_impcobrado: trntarjetaImpcobrado,
        p_trntarjeta_imcomision: trntarjetaImcomision,
        p_trntarjeta_impretfuente: trntarjetaImpretfuente,
        p_trntarjeta_impretiva: trntarjetaImpretiva,
        p_ban_codigo: banCodigo,
        p_tar_codigo: tarCodigo,
        p_com_codigo: comCodigo,
        p_trntarjeta_origen: trntarjetaOrigen,
        p_trntarjeta_observacion: trntarjetaObservacion,
        p_trntarjeta_porctar: trntarjetaPorctar,
        p_trntarjeta_comision: trntarjetaComision,
        p_usuidentificacion: this.usuario.identificacion,
        p_trntarjeta_totimporte: trntarjetaTotimporte,
        p_trntarjeta_vouchers: trntarjetaVouchers,
        p_trntarjeta_basecomision: trntarjetaBasecomision,
        p_trntarjeta_retencion: trntarjetaRetencion,
        p_trntarjeta_asimul: trntarjetaAsimul,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  guardarDetalleDep(trntarjetaCodigo, trntarjetadetNrosec, trncajTipo, trncajNumero, cajCodigo, trncajDocnro, trntarjetadetImporte ) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/43cadb5dd6bb38', {
      elementos: {
        p_trntarjeta_codigo: trntarjetaCodigo,
        p_trntarjetadet_nrosec: trntarjetadetNrosec,
        p_trncaj_tipo: trncajTipo,
        p_trncaj_numero: trncajNumero,
        p_caj_codigo: cajCodigo,
        p_trncaj_docnro: trncajDocnro,
        p_com_codigo: '01',
        p_trntarjetadet_importe: trntarjetadetImporte,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarDetalleDep(trntarjetaCodigo, trntarjetadetNrosec) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/4ab2b35dd6bb38', {
      elementos: {
        p_trntarjeta_codigo: trntarjetaCodigo,
        p_trntarjetadet_nrosec: trntarjetadetNrosec
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizaBanTrnCaj(trntarjetaCodigo) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/a6756abf77762800', {
      elementos: {
        p_trntarjeta_codigo: trntarjetaCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarVarios(txtTrnTarjetaCodigo) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/3ab2b4beadcb1c', {
        elementos: {
          trntarjeta_codigo: txtTrnTarjetaCodigo
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  guardarDetVarios(trntarjetaCodigo, trntarjetadetNrosec, conCodigo, trntarvariosDescripcion, trntarvariosImporte, cenCodigo) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/43cadb54d777ec00000', {
      elementos: {
        p_trntarjeta_codigo: trntarjetaCodigo,
        p_trntarvarios_nrosec: trntarjetadetNrosec,
        p_con_codigo: conCodigo,
        p_con_comcodigo: '01',
        p_trntarvarios_descripcion: trntarvariosDescripcion,
        p_trntarvarios_importe: trntarvariosImporte,
        p_com_codigo: '01',
        p_cen_codigo: cenCodigo,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarRetencion(txtTrnTarjetaCodigo) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/7565695d5b775c0', {
        elementos: {
          trnretencion_referencia: txtTrnTarjetaCodigo
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  guardarDetRetencion(retencionCodigo, trnretencionDescripcion, trnretencionNro, trnretencionBase, trnretencionPorcentaje,
                      trnretencionTotalretenido, trnretencionOrigen, conCodigo, cenCodigo, trncobroNrocomprobante, retencionSerie) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/1d595a57535dde0000', {
      elementos: {
        p_RETENCION_codigo_1: retencionCodigo,
        p_TRNRETENCION_descripcion_4: trnretencionDescripcion,
        p_TRNRETENCION_nro_1: trnretencionNro,
        p_TRNRETENCION_base_5: trnretencionBase,
        p_TRNRETENCION_porcentaje_6: trnretencionPorcentaje,
        p_TRNRETENCION_totalretenido_7: trnretencionTotalretenido,
        p_COM_codigo: '01',
        p_trnretencion_origen: trnretencionOrigen,
        p_con_codigo: conCodigo,
        p_cen_codigo: cenCodigo,
        p_TRNCOBRO_NROCOMPROBANTE: trncobroNrocomprobante,
        p_trnretencion_serie: retencionSerie
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  generarAsientoRetExi(trntarjetaCodigo) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/41d75725b775d8', {
      elementos: {
        p_trntarjeta_codigo: trntarjetaCodigo,
        p_com_codigo: '01',
        p_usuidentificacion: this.usuario.identificacion
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  guardarEncConcil(banCodigo, TRNBANFecha, TRNBAN_TIPO, TRNBAN_NUMERO, TRNBAN_DOCNRO, TRNBAN_IMPORTE, TRNBAN_CONCEPTO, TRNBAN_BENEFICIARIO,
                   TRNBAN_ANULADO, CON_CODIGO, TRNBAN_ORIGEN, TRNBAN_NUMEROREFUSER, TRNBAN_FECHAVENCE, ENCREQ_NUMERO, PRO_CODIGO, TRNBAN_PAGOELEC,
                   TRNBAN_IMPORTE_NO_DP) {

                    console.log(banCodigo, TRNBANFecha, TRNBAN_TIPO, TRNBAN_NUMERO, TRNBAN_DOCNRO, TRNBAN_IMPORTE, TRNBAN_CONCEPTO, TRNBAN_BENEFICIARIO,
                      TRNBAN_ANULADO, CON_CODIGO, TRNBAN_ORIGEN, TRNBAN_NUMEROREFUSER, TRNBAN_FECHAVENCE, ENCREQ_NUMERO, PRO_CODIGO, TRNBAN_PAGOELEC,
                      TRNBAN_IMPORTE_NO_DP);
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/8795b6baec66300', {
      elementos: {
        p_BAN_codigo_1: banCodigo,
        p_TRNBAN_fecha_2: TRNBANFecha,
        p_TRNBAN_tipo_3: TRNBAN_TIPO,
        p_TRNBAN_numero_4: TRNBAN_NUMERO,
        p_TRNBAN_docnro_5: TRNBAN_DOCNRO,
        p_TRNBAN_importe_6: TRNBAN_IMPORTE,
        p_TRNBAN_concepto_7: TRNBAN_CONCEPTO,
        p_TRNBAN_beneficiario_9: TRNBAN_BENEFICIARIO,
        p_TRNBAN_anulado_10: TRNBAN_ANULADO,
        p_CON_codigo_11: CON_CODIGO,
        p_COM_codigo_12: '01',
        p_TRNBAN_origen_13: TRNBAN_ORIGEN,
        p_TRNBAN_NUMEROREFUSER: TRNBAN_NUMEROREFUSER,
        p_TRNBAN_FECHAVENCE:  TRNBAN_FECHAVENCE,
        p_ENCREQ_NUMERO: ENCREQ_NUMERO,
        P_PRO_CODIGO: PRO_CODIGO,
        p_TRNBAN_PAGOELEC: TRNBAN_PAGOELEC,
        p_TRNBAN_IMPORTE_NO_DP: TRNBAN_IMPORTE_NO_DP,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  actlzTrnBanEnBanTrnTarj(trntarjetaCodigo, banCodigo, trnbanNumero, trnbanTipo) {

    console.log(trntarjetaCodigo, banCodigo, trnbanNumero, trnbanTipo);
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/a677bbbad57ea800', {
      elementos: {
        p_trntajeta_codigo: trntarjetaCodigo,
        p_ban_codigo: banCodigo,
        p_trnban_numero: trnbanNumero,
        p_trnban_tipo: trnbanTipo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  actlzNullTrnBanTrnTarj(banCodigo, trnbanNumero, trnbanTipo) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/533b7f56bdddd8000000', {
      elementos: {
        p_ban_codigo: banCodigo,
        p_trnban_numero: trnbanNumero,
        p_trnban_tipo: trnbanTipo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  generaAsientoTar_dep_n(banCodigo, trnbanNumero, trnbanTipo) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/83aeae4baadb600', {
      elementos: {
        p_ban_codigo: banCodigo,
        p_trnban_numero: trnbanNumero,
        p_trnban_tipo: trnbanTipo,
        p_com_codigo: '01',
        p_usuidentificacion: this.usuario.identificacion
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  generaAsientoCntblTar_n(banCodigo, trnbanNumero, trnbanTipo) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/83aeae4baadb600', {
      elementos: {
        p_ban_codigo: banCodigo,
        p_trnban_numero: trnbanNumero,
        p_trnban_tipo: trnbanTipo,
        p_com_codigo: '01',
        p_usuidentificacion: this.usuario.identificacion
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  actualizarEncConcil(banCodigo, TRNBANFecha, TRNBAN_TIPO, TRNBAN_NUMERO, TRNBAN_DOCNRO, TRNBAN_IMPORTE, TRNBAN_CONCEPTO, TRNBAN_BENEFICIARIO,
                      TRNBAN_ANULADO, CON_CODIGO, TRNBAN_ORIGEN, TRNBAN_NUMEROREFUSER, TRNBAN_FECHAVENCE, ENCREQ_NUMERO, PRO_CODIGO, TRNBAN_PAGOELEC,
                      TRNBAN_IMPORTE_NO_DP) {

                        console.log(banCodigo, TRNBANFecha, TRNBAN_TIPO, TRNBAN_NUMERO, TRNBAN_DOCNRO, TRNBAN_IMPORTE, TRNBAN_CONCEPTO, TRNBAN_BENEFICIARIO,
                          TRNBAN_ANULADO, CON_CODIGO, TRNBAN_ORIGEN, TRNBAN_NUMEROREFUSER, TRNBAN_FECHAVENCE, ENCREQ_NUMERO, PRO_CODIGO, TRNBAN_PAGOELEC,
                          TRNBAN_IMPORTE_NO_DP);
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/533aebb198bb240', {
      elementos: {
        p_BAN_codigo_1: banCodigo,
        p_TRNBAN_tipo_2: TRNBAN_TIPO,
        p_TRNBAN_numero_3: TRNBAN_NUMERO,
        p_BAN_codigo_4: banCodigo,
        p_TRNBAN_fecha_5: TRNBANFecha,
        p_TRNBAN_tipo_6: TRNBAN_TIPO,
        p_TRNBAN_numero_7: TRNBAN_NUMERO,
        p_TRNBAN_docnro_8: TRNBAN_DOCNRO,
        p_TRNBAN_importe_9: TRNBAN_IMPORTE,
        p_TRNBAN_concepto_10: TRNBAN_CONCEPTO,
        p_TRNBAN_beneficiario_14: TRNBAN_BENEFICIARIO,
        p_TRNBAN_anulado_15: TRNBAN_ANULADO,
        p_CON_codigo_16: CON_CODIGO,
        p_COM_codigo_17: '01',
        p_TRNBAN_origen_18: TRNBAN_ORIGEN,
        p_TRNBAN_NUMEROREFUSER: TRNBAN_NUMEROREFUSER,
        p_TRNBAN_FECHAVENCE:  TRNBANFecha,
        p_ENCREQ_NUMERO: ENCREQ_NUMERO,
        P_PRO_CODIGO: PRO_CODIGO,
        p_TRNBAN_PAGOELEC: TRNBAN_PAGOELEC,
        p_TRNBAN_IMPORTE_NO_DP: TRNBAN_IMPORTE_NO_DP
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarAsientoContable(asiNro) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/756565724cc5fc0', {
      elementos: {
        p_ASI_nro_1: asiNro,
        p_COM_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminartrnTarjeta(tarjetaCodigo) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/1d595bbbbf55ba0000', {
      elementos: {
        p_trntarjeta_codigo: tarjetaCodigo,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarBancoMovimiento(banCodigo, trnbanTipo, trnbanNumero) {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/3ab2b2d57b63f2', {
      elementos: {
        p_BAN_codigo_1: banCodigo,
        p_TRNBAN_tipo_2: trnbanTipo,
        p_TRNBAN_numero_3: trnbanNumero,
        p_COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerConfiguracion() {
    return this.http.post<any[]>(this.way + '/ban/depositotarjeta/30beb3177ca1e', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  obtenerClave() {
    return this.http.post<any>(this.way + '/ban/depositotarjeta/c2faebbb6caac00', {
      elementos: {},
    }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  autoCompletar(strCadena, intNumCar ): string {
    const intTamanioCad = String(strCadena).trim().length;
    let strCadCeros = '';
    for (let i = 1; i <= intNumCar - intTamanioCad; i++ ) {
      strCadCeros = strCadCeros + '0';
    }
    return strCadCeros + strCadena;
  }
  //GSRF
  getcuentconta(cuenta) {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/10776ca773bbc300000000', {
      elementos: {
        con_codigo: cuenta
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //GSRF
  
}
