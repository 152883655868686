import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ComnotrecEC} from '../cominterfaces/comnotrec';
import {ComnotrecDetalle} from '../cominterfaces/ComnotrecDetalle';
import {DatePipe} from '@angular/common';
import {ComnotrecImp} from '../cominterfaces/comnotrecImp';
import {ComnotrecVar} from '../cominterfaces/comnotrecVar';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class ComnotrecService {
  
  public way: string;
  
  public comnotrecRecepcionECarray: ComnotrecEC[][] = [];
  public comnotrecRecepcionDETALLEarray: ComnotrecDetalle[][] = [];
  public comnotrecRecepcionSelectarray: ComnotrecEC[] = [];
  public comnotrecImportacionarray: ComnotrecImp[][] = [];
  public comnotrecVararray: ComnotrecVar[][] = [];
  public dblPorcDescGlbOrdarray: number[] = [];
  public dblValorDescGlbOrdarray: number[] = [];
  public bolConsideraIvaarray: boolean[] = [];
  
  public hmpConfiguracion: any[];
  public decimalesCosto: number;
  public decimalesCostoTotal: number;
  public decimalesCantidad: number;
  public decimalesTotal: number;
  public decimalesGenerales: number;
  
  //CDPJ
  public tariivacod:string;
  public porceiva:number;
  //CDPJ
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, private datePipe: DatePipe,
              private init: NuevoComponentService,
              private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }
  
  generarAsientoContable(numnotrec, asicomprobante): Observable<any> {
    
    return this.http.post<any>(this.way + '/compras/notasrecepcion/20ebab92662fd60000', {
      elementos: {
        p_encnotrec_numero: numnotrec,
        p_com_codigo: '01',
        p_tipdia_codigo: 'ANR',
        p_origen: 'COM',
        p_usuario: this.usuario.identificacion,
        
      }
    }, this.confIniciales.httpOptions());
  }
  
  getEncnotRec(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/notasrecepcion/83baebb2f8eee00', {
      elementos: {},
     
    }, this.confIniciales.httpOptions());
  }
  
  getDetnotRec(encnotrecnumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/notasrecepcion/83bad776f8eee00', {
      elementos: {
        NUMERO: encnotrecnumero
      }
    }, this.confIniciales.httpOptions());
  }
  
  getNombreProveedor(codigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/notasrecepcion/83bb7c5b3bc7e00', {
      elementos: {
        CODIGOP: codigo
      }
    }, this.confIniciales.httpOptions());
    
  }
  
  getDescripcionImportaciones(importacion): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/notasrecepcion/1aee3372ccad9c0000', {
      elementos: {
        CODIGO: importacion,
        IMP: 'IMP'
      }
    }, this.confIniciales.httpOptions());
    
  }
  
  getImportaciones(codigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/notasrecepcion/20eecad9c6faa60000', {
      elementos: {
        encnotrec_numero: codigo
      }
    }, this.confIniciales.httpOptions());
    
  }
  
  
  insertarEncabezado(encabezado): Observable<any[]> {
    
    return this.http.post<any>(this.way + '/compras/notasrecepcion/12bf1dbeab6ebb000', {
      elementos: {
        p_ENCNOTREC_NUMERO: encabezado.ENCNOTREC_NUMERO,
        p_COM_CODIGO: '01',
        p_PRO_CODIGO: encabezado.PRO_CODIGO,
        P_ENCNOTREC_REFERENCIA: encabezado.ENCNOTREC_REFERENCIA,
        p_ENCNOTREC_CONTACTO: encabezado.ENCNOTREC_CONTACTO,
        p_ENCNOTREC_FECHAEMISION: encabezado.ENCNOTREC_FECHAEMISION,
        p_ENCNOTREC_FECHAENTREGA: encabezado.ENCNOTREC_FECHAENTREGA,
        p_ENCNOTREC_IVA: '1', // ojo
        p_ENCNOTREC_ESTADO: encabezado.ENCNOTREC_ESTADO,
        p_ENCNOTREC_OBSERVACION: encabezado.ENCNOTREC_OBSERVACION,
        p_ENCNOTREC_TOTAL: Number(encabezado.ENCNOTREC_TOTAL),
        p_ENCNOTREC_TOTALNETO: Number(encabezado.ENCNOTREC_TOTALNETO),
        p_ENCNOTREC_VALORIVA: Number(encabezado.ENCNOTREC_VALORIVA),
        p_ENCNOTREC_PORCEIVA: Number(encabezado.ENCNOTREC_PORCEIVA),
        p_ENCNOTREC_VALORICE: Number(encabezado.ENCNOTREC_VALORICE),
        p_ENCNOTREC_BASEIVA: Number(encabezado.ENCNOTREC_BASEIVA),
        p_ENCNOTREC_BASEICE: Number(encabezado.ENCNOTREC_BASEICE),
        p_ENCNOTREC_BASECERO: Number(encabezado.ENCNOTREC_BASECERO),
        p_ENCNOTREC_OTROS: encabezado.ENCNOTREC_OTROS,
        p_ENCNOTREC_MERCONS: encabezado.ENCNOTREC_MERCONS,
        P_ENCNOTREC_REFERENCIA_ADI: encabezado.ENCNOTREC_REFERENCIA_ADI,
        p_maemotivo_codigo: encabezado.MAEMOTIVO_CODIGO,
        p_ENCNOTREC_VALORDES: Number(encabezado.ENCNOTREC_VALORDES),
        p_ENCNOTREC_PORCEDES: Number(encabezado.ENCNOTREC_PORCEDES),
        p_ENCNOTREC_TIPODES: encabezado.ENCNOTREC_TIPODES,
        p_ENCNOTREC_TASA_CAMBIO:Number(encabezado.ENCNOTREC_TASA_CAMBIO),
      }
    }, this.confIniciales.httpOptions());
    
  }
  
  insertarImportacion(importacion: ComnotrecImp, numero): Observable<any[]> {
    return this.http.post<any>(this.way + '/compras/notasrecepcion/257e3b7d56e56c0000', {
      elementos: {
        p_ENCNOTREC_NUMERO: importacion.ENCNOTREC_NUMERO,
        p_COM_CODIGO: '01',
        p_NOTRECIMP_NUMSEC: Number(importacion.NOTRECIMP_NUMSEC),
        p_REF_TIPO: importacion.REF_TIPO,
        p_REF_CODIGO: importacion.REF_CODIGO,
        p_NOTRECIMP_BASE: Number(importacion.NOTRECIMP_BASE),
        p_NOTRECIMP_PORCENTAJE: Number(importacion.NOTRECIMP_PORCENTAJE),
        p_NOTRECIMP_IMPORTE: Number(importacion.NOTRECIMP_IMPORTE),
        p_NOTRECIMP_TIPO: numero,
        p_PRO_NOMBRE: importacion.PRO_NOMBRE,
        p_NUM_DOCUMENTO: importacion.NUM_DOCUMENTO,
        
      }
    }, this.confIniciales.httpOptions());
    
  }
  
  insertarDetallePromise(detalle: ComnotrecDetalle, numero): Promise<any[]> {
   
    return this.http.post<any>(this.way + '/compras/notasrecepcion/4afc76faadb5dc00000', {
      elementos: {
        p_ENCNOTREC_numero: numero,
        p_COM_codigo: '01',
        p_DETNOTREC_linea: Number(detalle.DETNOTREC_LINEA),
        p_DETNOTREC_tipodet: detalle.DETNOTREC_TIPODET,
        p_BOD_codigo: detalle.BOD_CODIGO,
        p_DETNOTREC_codigo: detalle.DETNOTREC_CODIGO,
        p_DETNOTREC_descripcion: detalle.DETNOTREC_DESCRIPCION,
        p_DETNOTREC_unidad: detalle.DETNOTREC_UNIDAD,
        p_DETNOTREC_cantidad: Number(detalle.DETNOTREC_CANTIDAD),
        p_DETNOTREC_despacho: Number(detalle.DETNOTREC_DESPACHO),
        p_DETNOTREC_costo: Number(detalle.DETNOTREC_COSTO),
        p_DETNOTREC_descuento: Number(detalle.DETNOTREC_DESCUENTO),
        p_DETNOTREC_total: Number(detalle.DETNOTREC_TOTAL),
        p_DETNOTREC_tribiva: detalle.DETNOTREC_TRIBIVA,
        p_DETNOTREC_iva: Number(detalle.DETNOTREC_IVA),
        p_DETNOTREC_tribice: detalle.DETNOTREC_TRIBICE,
        p_DETNOTREC_ice: Number(detalle.DETNOTREC_ICE),
        p_DETNOTREC_porceice: Number(detalle.DETNOTREC_PORCEICE),
        p_DETNOTREC_baseiva: Number(detalle.DETNOTREC_BASEIVA),
        p_DETNOTREC_baseice: Number(detalle.DETNOTREC_BASEICE),
        p_DETNOTREC_basecero: Number(detalle.DETNOTREC_BASECERO),
        p_ENCORDCOM_numero: detalle.ENCORDCOM_NUMERO,
        p_COM_codigo1: '',
        p_DETORDCOM_linea: Number(detalle.DETORDCOM_LINEA),
        p_DETNOTREC_descuento2: Number(detalle.DETNOTREC_DESCUENTO2),
        p_DETNOTREC_descuento3: Number(detalle.DETNOTREC_DESCUENTO3),
        p_DETNOTREC_factor: Number(detalle.DETNOTREC_FACTOR),
        p_DETNOTREC_VALORDES: Number(detalle.DETNOTREC_VALORDES),
        p_DETNOTREC_PORCEDES: Number(detalle.DETNOTREC_PORCEDES),
        // COSTO FOB //
        p_DETNOTREC_PORFACTOR: Number(detalle.DETNOTREC_PORFACTOR),
        p_DETNOTREC_COSTOFOB: Number(detalle.DETNOTREC_COSTOFOB),
        p_DETNOTREC_ARTPESO: Number(detalle.DETNOTREC_ARTPESO),
        p_CEN_CODIGO: detalle.CEN_CODIGO,
        p_detnotrec_cantidadund: Number(detalle.DETNOTREC_CANTIDADUND),
        p_detnotrec_po: '',
        p_DETNOTREC_AJUSTE: detalle.DETNOTREC_AJUSTE,
        p_DETNOTREC_PORIVA: detalle.DETNOTREC_PORIVA,
        p_TARIIVA_CODIGO: detalle.TARIIVA_CODIGO,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  insertarDetalle(detalle: ComnotrecDetalle, numero): Observable<any[]> {
    return this.http.post<any>(this.way + '/compras/notasrecepcion/4afc76faadb5dc00000', {
      elementos: {
        p_ENCNOTREC_numero: numero,
        p_COM_codigo: '01',
        p_DETNOTREC_linea: Number(detalle.DETNOTREC_LINEA),
        p_DETNOTREC_tipodet: detalle.DETNOTREC_TIPODET,
        p_BOD_codigo: detalle.BOD_CODIGO,
        p_DETNOTREC_codigo: detalle.DETNOTREC_CODIGO,
        p_DETNOTREC_descripcion: detalle.DETNOTREC_DESCRIPCION,
        p_DETNOTREC_unidad: detalle.DETNOTREC_UNIDAD,
        p_DETNOTREC_cantidad: Number(detalle.DETNOTREC_CANTIDAD),
        p_DETNOTREC_despacho: Number(detalle.DETNOTREC_DESPACHO),
        p_DETNOTREC_costo: Number(detalle.DETNOTREC_COSTO),
        p_DETNOTREC_descuento: Number(detalle.DETNOTREC_DESCUENTO),
        p_DETNOTREC_total: Number(detalle.DETNOTREC_TOTAL),
        p_DETNOTREC_tribiva: detalle.DETNOTREC_TRIBIVA,
        p_DETNOTREC_iva: Number(detalle.DETNOTREC_IVA),
        p_DETNOTREC_tribice: detalle.DETNOTREC_TRIBICE,
        p_DETNOTREC_ice: Number(detalle.DETNOTREC_ICE),
        p_DETNOTREC_porceice: Number(detalle.DETNOTREC_PORCEICE),
        p_DETNOTREC_baseiva: Number(detalle.DETNOTREC_BASEIVA),
        p_DETNOTREC_baseice: Number(detalle.DETNOTREC_BASEICE),
        p_DETNOTREC_basecero: Number(detalle.DETNOTREC_BASECERO),
        p_ENCORDCOM_numero: detalle.ENCORDCOM_NUMERO,
        p_COM_codigo1: '',
        p_DETORDCOM_linea: Number(detalle.DETNOTREC_LINEA),
        p_DETNOTREC_descuento2: Number(detalle.DETNOTREC_DESCUENTO2),
        p_DETNOTREC_descuento3: Number(detalle.DETNOTREC_DESCUENTO3),
        p_DETNOTREC_factor: Number(detalle.DETNOTREC_FACTOR),
        p_DETNOTREC_VALORDES: Number(detalle.DETNOTREC_VALORDES),
        p_DETNOTREC_PORCEDES: Number(detalle.DETNOTREC_PORCEDES),
        // COSTO FOB //
        p_DETNOTREC_PORFACTOR: Number(detalle.DETNOTREC_PORFACTOR),
        p_DETNOTREC_COSTOFOB: Number(detalle.DETNOTREC_COSTOFOB),
        p_DETNOTREC_ARTPESO: Number(detalle.DETNOTREC_ARTPESO),
        p_CEN_CODIGO: detalle.CEN_CODIGO,
        p_detnotrec_cantidadund: Number(detalle.DETNOTREC_CANTIDADUND),
        p_detnotrec_po: '',
        p_DETNOTREC_AJUSTE:'',
      }
    }, this.confIniciales.httpOptions());
  }
  
  eliminarNotaRecepcion(encabezado): Observable<any[]> {
    return this.http.post<any>(this.way + '/compras/notasrecepcion/eacad7c755b73000', {
      elementos: {
        p_ENCNOTREC_NUMERO: encabezado.ENCNOTREC_NUMERO,
        p_COM_codigo: '01',
      }
    }, this.confIniciales.httpOptions());
    
  }
  
  recalcularCostosArtEliminados(encabezado, strFechaEmision): Observable<any[]> {
    return this.http.post<any>(this.way + '/compras/notasrecepcion/6dcc55598e770c00000', {
      elementos: {
        p_ENCNOTREC_NUMERO: encabezado.ENCNOTREC_NUMERO,
        p_COM_codigo: '01',
        p_fecha: strFechaEmision
        
      }
    }, this.confIniciales.httpOptions());
    
  }
  
  registrarKardex(encabezado): Observable<any[]> {
    return this.http.post<any>(this.way + '/compras/notasrecepcion/36e84b9ddab7460000', {
      elementos: {
        p_ENCNOTREC_NUMERO: encabezado.ENCNOTREC_NUMERO,
        p_COM_codigo: '01',
        
      }
    }, this.confIniciales.httpOptions());
    
  }
  
  // servicios varios
  consultarTrnnotVar(numero): Observable<any[]> {
    return this.http.post<any>(this.way + '/compras/notasrecepcion/3317e7abdddef800000', {
      elementos: {
        p_ENCNOTREC_NUMERO: numero.ENCNOTREC_NUMERO,
        p_COM_codigo: '01',
      }
    }, this.confIniciales.httpOptions());
    
  }
  
  eliminarTrnotVar(): Observable<any[]> {
    
    return this.http.post<any[]>(this.way + '/compras/notasrecepcion/1d595a5dddef8f0000', {
      elementos: {
        NUMERO: this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_NUMERO,
        COM: '01'
      }
    }, this.confIniciales.httpOptions());
    
  }
  
  consultarsumarVarios(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/notasrecepcion/cc5f9cf5beadc800', {
      elementos: {
        NUMERO: this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_NUMERO
      }
    }, this.confIniciales.httpOptions());
    
  }
  
  insertarTrnotVar(varios: ComnotrecVar): Observable<any[]> {
    
    return this.http.post<any>(this.way + '/compras/notasrecepcion/95f9dddef8efd80', {
      elementos: {
        p_ENCNOTREC_NUMERO: this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_NUMERO,
        p_CON_CODIGO: varios.CON_CODIGO,
        p_CON_NOMBRE: varios.CON_NOMBRE,
        p_CEN_CODIGO: varios.CEN_CODIGO,
        p_COM_CODIGO: '01',
        p_NOTRECVAR_LINEA: Number(varios.NOTRECVAR_LINEA),
        p_NOTRECVAR_IMPORTE: Number(varios.NOTRECVAR_IMPORTE) * (-1)
      }
    }, this.confIniciales.httpOptions());
    
  }
  
  consultarDetalleOrdenCompra(ordencompra): Promise<any[]> {
    
    return this.http.post<any>(this.way + '/compras/notasrecepcion/83bad7771b6b300', {
      elementos: {
        p_encordcom_numero: ordencompra.ENCORDCOM_NUMERO,
        p_COM_CODIGO: '01',
        
      }
    }, this.confIniciales.httpOptions()).toPromise();
    
  }
  
  consultarArticuloSel(strCodArticulo): Observable<any[]> {
    const FPLOTSIM = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FPLOTSIM').CFG_VALOR1;
    
    const strFecha = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    const strHora = this.datePipe.transform(new Date(), 'HH:mm');
    
    return this.http.post<any[]>(this.way + '/compras/notasrecepcion/662fcf555bef1c0', {
  
      elementos: {
        strLista: 'A',
        strBodCodigo: '001',
        strFecha: strFecha + '',
        strHora: strHora + '',
        strCodArticu: strCodArticulo,
        COM_CODIGO: '01'
      },
      config: {
        dato: FPLOTSIM
      }
    }, this.confIniciales.httpOptions());
  }
  
  
  eliminarImportacion(importacion): Observable<any[]> {
    return this.http.post<any>(this.way + '/compras/notasrecepcion/eacad9c6faa64800', {
      elementos: {
        p_ENCNOTREC_NUMERO: importacion.ENCNOTREC_NUMERO,
        p_COM_codigo: '01',
        p_NOTRECIMP_NUMSEC: importacion.NOTRECIMP_NUMSEC
      }
    }, this.confIniciales.httpOptions());
    
  }
  
  encontrarAsiNro(strNumero) {
    return this.http.post(this.way + '/compras/notasrecepcion/76df2bb55c95f80', {
      elementos: {
        numero: strNumero
      },
    }, this.confIniciales.httpOptions());
  }
  
  encontrarEncNotNumeroImp(strNumero, numSec) {
    return this.http.post(this.way + '/compras/notasrecepcion/76dd765fd674ac0', {
      elementos: {
        numero: strNumero,
        num_sec: numSec
      },
    }, this.confIniciales.httpOptions());
  }
  
  encontrarEncNotNumeroNot(strNumero, strArticulo) {
    return this.http.post(this.way + '/compras/notasrecepcion/3b6ebb2feb3b78', {
      elementos: {
        numero: strNumero,
        articulo: strArticulo
      },
    }, this.confIniciales.httpOptions());
  }
  
  encontrarArtSerialFlag(strArticulo) {
    return this.http.post(this.way + '/compras/notasrecepcion/76d5bef1db7d540', {
      elementos: {
        articulo: strArticulo
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  calcularImportacion(index) {
    let total = 0;
    
    if (index !== null) {
      
      if (Number(this.comnotrecImportacionarray[this.init.tabs[this.init.tabindex].indice][index].NOTRECIMP_PORCENTAJE) > 100) {
        this.comnotrecImportacionarray[this.init.tabs[this.init.tabindex].indice][index].NOTRECIMP_PORCENTAJE = Number(100);
      }
      this.comnotrecImportacionarray[this.init.tabs[this.init.tabindex].indice][index].NOTRECIMP_IMPORTE = Number(this.comnotrecImportacionarray[this.init.tabs[this.init.tabindex].indice][index].NOTRECIMP_BASE) *
        Number(this.comnotrecImportacionarray[this.init.tabs[this.init.tabindex].indice][index].NOTRECIMP_PORCENTAJE);
      if (this.comnotrecImportacionarray[this.init.tabs[this.init.tabindex].indice][index].NOTRECIMP_IMPORTE > 0) {
        this.comnotrecImportacionarray[this.init.tabs[this.init.tabindex].indice][index].NOTRECIMP_IMPORTE = this.comnotrecImportacionarray[this.init.tabs[this.init.tabindex].indice][index].NOTRECIMP_IMPORTE / 100;
        this.comnotrecImportacionarray[this.init.tabs[this.init.tabindex].indice][index].NOTRECIMP_IMPORTE = Number(this.comnotrecImportacionarray[this.init.tabs[this.init.tabindex].indice][index].NOTRECIMP_IMPORTE).toFixed(this.decimalesGenerales);
        
      } else {
        this.comnotrecImportacionarray[this.init.tabs[this.init.tabindex].indice][index].NOTRECIMP_IMPORTE = Number(0).toFixed(this.decimalesGenerales);
      }
      
    }
    this.comnotrecImportacionarray[this.init.tabs[this.init.tabindex].indice].map(aux => {
      total += Number(aux.NOTRECIMP_IMPORTE);
      aux.NOTRECIMP_BASE = Number(aux.NOTRECIMP_BASE).toFixed(this.decimalesGenerales);
      aux.NOTRECIMP_PORCENTAJE = Number(aux.NOTRECIMP_PORCENTAJE).toFixed(this.decimalesGenerales);
    });
    this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].TOTAL_DISTRIBUIR = Number(total).toFixed(this.decimalesGenerales);
    
    
  }
  
  
  realizarDistribucionxValor() {
    
    
    this.getDetnotRec(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_NUMERO).subscribe(res => {
      this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice] = res;
      let valTotalxLinea = 0;
      const valTotalNetoNotrec = this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_TOTALNETO;
      let valTotxLineaDist = 0;
      let descuento1 = 0;
      let descuento2 = 0;
      let descuento3 = 0;
      let auxilio = 0;
      
      if (this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAEMISION.length > 10) {
        this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAEMISION = this.datePipe.transform(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAEMISION, 'dd/MM/yyyy');
        this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAENTREGA = this.datePipe.transform(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAENTREGA, 'dd/MM/yyyy');
      }
      
      this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].map(aux => {
        if (aux.DETNOTREC_TIPODET === '*') {
        
        } else if (aux.DETNOTREC_TIPODET === 'A') {
          
          
          if (Number(aux.DETNOTREC_DESCUENTO) !== 0 || Number(aux.DETNOTREC_DESCUENTO2) !== 0 || Number(aux.DETNOTREC_DESCUENTO3) !== 0) {
            aux.DETNOTREC_COSTOFOB = Number(aux.DETNOTREC_COSTO) * Number(aux.DETNOTREC_CANTIDAD);
          } else {
            aux.DETNOTREC_COSTOFOB = Number(aux.DETNOTREC_TOTAL);
            
          }
          valTotalxLinea = Number(aux.DETNOTREC_TOTAL);
          valTotxLineaDist = Number(valTotalxLinea * 100);
          
          if (valTotxLineaDist > 0 && valTotalNetoNotrec > 0) {
            valTotxLineaDist = Number(valTotxLineaDist / valTotalNetoNotrec);
            valTotxLineaDist = Number(valTotxLineaDist / 100);
            valTotxLineaDist = Number(valTotxLineaDist * this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].TOTAL_DISTRIBUIR);
          } else {
            valTotxLineaDist = 0;
          }
          descuento1 = Number(aux.DETNOTREC_COSTOFOB) * Number(aux.DETNOTREC_DESCUENTO) / 100;
          descuento2 = (Number(aux.DETNOTREC_COSTOFOB) - descuento1) * Number(aux.DETNOTREC_DESCUENTO2) / 100;
          descuento3 = (Number(aux.DETNOTREC_COSTOFOB) - descuento2 - descuento1) * Number(aux.DETNOTREC_DESCUENTO3) / 100;
          if (aux.DETNOTREC_DESCUENTO !== 0 || aux.DETNOTREC_DESCUENTO2 !== 0 || aux.DETNOTREC_DESCUENTO3 !== 0) {
            aux.DETNOTREC_TOTAL = Number(aux.DETNOTREC_COSTOFOB) - descuento1 - descuento2 - descuento3 + valTotxLineaDist;
            aux.DETNOTREC_TOTAL = Number(aux.DETNOTREC_TOTAL).toFixed(this.decimalesTotal);
          } else {
            aux.DETNOTREC_TOTAL = Number(aux.DETNOTREC_COSTOFOB) + valTotxLineaDist;
            aux.DETNOTREC_TOTAL = Number(aux.DETNOTREC_TOTAL).toFixed(this.decimalesTotal);
            
          }
          auxilio = Number(aux.DETNOTREC_TOTAL) / Number(aux.DETNOTREC_CANTIDAD);
          descuento3 = auxilio * Number(aux.DETNOTREC_DESCUENTO3) / 100;
          descuento2 = (auxilio + descuento3) * Number(aux.DETNOTREC_DESCUENTO2) / 100;
          descuento1 = (auxilio + descuento2 + descuento3) * Number(aux.DETNOTREC_DESCUENTO) / 100;
          if (Number(aux.DETNOTREC_TOTAL) > 0
            && Number(aux.DETNOTREC_CANTIDAD) > 0) {
            if (aux.DETNOTREC_DESCUENTO !== 0 || aux.DETNOTREC_DESCUENTO2 !== 0 || aux.DETNOTREC_DESCUENTO3 !== 0) {
              aux.DETNOTREC_COSTO = (Number(aux.DETNOTREC_TOTAL) / Number(aux.DETNOTREC_CANTIDAD)) + descuento1 + descuento2 + descuento3;
            } else {
              aux.DETNOTREC_COSTO = (Number(aux.DETNOTREC_TOTAL) / Number(aux.DETNOTREC_CANTIDAD));
            }
            
            
          } else {
            aux.DETNOTREC_COSTO = 0;
          }
          //
          // no debo llamar a esta ya q el proceso hecho de los descuentos esta arriba y mi proceso los reinicia solucionar
          this.actualizarSumatorias();
        }
        // guardado nota recepcion nuevos valores (ACTUALIZAR)
        this.insertarDetalle(aux, this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_NUMERO).subscribe(res1 => {
        });
        
        if (this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].length - 1 === this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].indexOf(aux)) {
          
          this.insertarEncabezado(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice]).subscribe(() => {
          });
        }
        
      });
      
      
    });
    
    
  }
  
  realizarDistribucionxVolumen() {
    let canTotalxLinea = 0;
    let canTotalNotrec = 0;
    let valTotxLineaDist = 0;
    if (this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAEMISION.length > 10) {
      this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAEMISION = this.datePipe.transform(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAEMISION, 'dd/MM/yyyy');
      this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAENTREGA = this.datePipe.transform(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAENTREGA, 'dd/MM/yyyy');
    }
    
    this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].map(aux => {
      canTotalNotrec += Number(aux.DETNOTREC_CANTIDAD);
    });
    this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].map(aux => {
      if (aux.DETNOTREC_TIPODET === '*') {
      
      } else if (aux.DETNOTREC_TIPODET === 'A') {
        aux.DETNOTREC_COSTOFOB = Number(aux.DETNOTREC_TOTAL);
        canTotalxLinea = aux.DETNOTREC_CANTIDAD;
        valTotxLineaDist = Number(canTotalxLinea) * 100;
        if (Number(valTotxLineaDist) > 0 && Number(canTotalNotrec) > 0) {
          valTotxLineaDist = (valTotxLineaDist / canTotalNotrec);
          valTotxLineaDist = valTotxLineaDist / 100;
          valTotxLineaDist = valTotxLineaDist * Number(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].TOTAL_DISTRIBUIR);
          
        } else {
          valTotxLineaDist = 0;
        }
        aux.DETNOTREC_TOTAL = Number(aux.DETNOTREC_COSTOFOB) + valTotxLineaDist;
        aux.DETNOTREC_TOTAL = Number(aux.DETNOTREC_TOTAL).toFixed(this.decimalesTotal);
        if (aux.DETNOTREC_TOTAL > 0 && aux.DETNOTREC_CANTIDAD > 0) {
          
          aux.DETNOTREC_COSTO = Number(aux.DETNOTREC_TOTAL) / Number(aux.DETNOTREC_CANTIDAD);
          
        } else {
          aux.DETNOTREC_COSTO = 0;
        }
        
        this.actualizarSumatorias();
        
        
      }
      // guardado nota recepcion nuevos valores (ACTUALIZAR)
      
      
      this.insertarDetalle(aux, this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_NUMERO).subscribe(res1 => {
      
      });
      
      if (this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].length - 1 === this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].indexOf(aux)) {
        
        this.insertarEncabezado(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice]).subscribe(() => {
        });
      }
      
      
    });
  }
  
  realizarDistribucionxPeso() {
    let pesoTotalxLinea = 0;
    let pesoTotalNotrec = 0;
    let valTotxLineaDist = 0;
    
    if (this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAEMISION.length > 10) {
      this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAEMISION = this.datePipe.transform(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAEMISION, 'dd/MM/yyyy');
      this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAENTREGA = this.datePipe.transform(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAENTREGA, 'dd/MM/yyyy');
    }
    
    this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].map(aux => {
      
      
      pesoTotalNotrec += Number(aux.DETNOTREC_ARTPESO) * Number(aux.DETNOTREC_CANTIDAD);
      
      
    });
    this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].map(aux => {
      if (aux.DETNOTREC_TIPODET === '*') {
      
      } else if (aux.DETNOTREC_TIPODET === 'A') {
        aux.DETNOTREC_COSTOFOB = Number(aux.DETNOTREC_TOTAL);
        pesoTotalxLinea = Number(aux.DETNOTREC_ARTPESO) * Number(aux.DETNOTREC_CANTIDAD);
        valTotxLineaDist = (pesoTotalxLinea * 100);
        if (Number(valTotxLineaDist) > 0 && Number(pesoTotalNotrec) > 0) {
          valTotxLineaDist = (valTotxLineaDist / pesoTotalNotrec);
          valTotxLineaDist = valTotxLineaDist / 100;
          valTotxLineaDist = valTotxLineaDist * this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].TOTAL_DISTRIBUIR;
        } else {
          valTotxLineaDist = 0;
        }
        aux.DETNOTREC_TOTAL = Number(aux.DETNOTREC_COSTOFOB) + (valTotxLineaDist);
        aux.DETNOTREC_TOTAL = Number(aux.DETNOTREC_TOTAL).toFixed(this.decimalesTotal);
        
        if (Number(aux.DETNOTREC_TOTAL) > 0 && Number(aux.DETNOTREC_CANTIDAD) > 0) {
          aux.DETNOTREC_COSTO = Number(aux.DETNOTREC_TOTAL) / Number(aux.DETNOTREC_CANTIDAD);
        } else {
          aux.DETNOTREC_COSTO = 0;
        }
        
        
        this.actualizarSumatorias();
        
      }
      // guardado nota recepcion nuevos valores (ACTUALIZAR)
      
      this.insertarDetalle(aux, this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_NUMERO).subscribe(res1 => {
      
      });
      
      if (this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].length - 1 === this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].indexOf(aux)) {
        
        this.insertarEncabezado(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice]).subscribe(() => {
        });
      }
      
      
    });
    
  }
  
  realizarDistribucionxFactor() {
    let factorTotalxLinea = 0;
    let factorpesoTotalNotrec = 0;
    let valTotxLineaDist = 0;
    
    if (this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAEMISION.length > 10) {
      this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAEMISION = this.datePipe.transform(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAEMISION, 'dd/MM/yyyy');
      this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAENTREGA = this.datePipe.transform(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAENTREGA, 'dd/MM/yyyy');
    }
    
    this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].map(aux => {
      
      
      factorpesoTotalNotrec += Number(aux.DETNOTREC_PORFACTOR);
      
      
    });
    this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].map(aux => {
      if (aux.DETNOTREC_TIPODET === '*') {
      
      } else if (aux.DETNOTREC_TIPODET === 'A') {
        aux.DETNOTREC_COSTOFOB = Number(aux.DETNOTREC_TOTAL);
        factorTotalxLinea = Number(aux.DETNOTREC_PORFACTOR);
        valTotxLineaDist = (factorTotalxLinea * 100);
        if (Number(valTotxLineaDist) > 0 && Number(factorpesoTotalNotrec) > 0) {
          valTotxLineaDist = (valTotxLineaDist / factorpesoTotalNotrec);
          valTotxLineaDist = valTotxLineaDist / 100;
          valTotxLineaDist = valTotxLineaDist * this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].TOTAL_DISTRIBUIR;
        } else {
          valTotxLineaDist = 0;
        }
        
        aux.DETNOTREC_TOTAL = Number(aux.DETNOTREC_COSTOFOB) + valTotxLineaDist;
        
        if (Number(aux.DETNOTREC_TOTAL) > 0 && Number(aux.DETNOTREC_CANTIDAD) > 0) {
          aux.DETNOTREC_COSTO = Number(aux.DETNOTREC_TOTAL) / Number(aux.DETNOTREC_CANTIDAD);
        } else {
          aux.DETNOTREC_COSTO = 0;
        }
        
        this.actualizarSumatorias();
        
        
      }
      // guardado nota recepcion nuevos valores (ACTUALIZAR)
      
      this.insertarDetalle(aux, this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_NUMERO).subscribe(res1 => {
      
      });
      
      if (this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].length - 1 === this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].indexOf(aux)) {
        
        this.insertarEncabezado(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice]).subscribe(() => {
        });
      }
    });
    
  }
  
  reverzarDistribucion() {
    const valTotalNetoNotrec = this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_TOTALNETO;
    let descuento1 = 0;
    let descuento2 = 0;
    let descuento3 = 0;
    if (this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAEMISION.length > 10) {
      this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAEMISION = this.datePipe.transform(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAEMISION, 'dd/MM/yyyy');
      this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAENTREGA = this.datePipe.transform(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_FECHAENTREGA, 'dd/MM/yyyy');
    }
    
    this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].map(aux => {
      if (aux.DETNOTREC_TIPODET === '*') {
      
      } else if (aux.DETNOTREC_TIPODET === 'A') {
        
        descuento1 = Number(aux.DETNOTREC_COSTOFOB) * Number(aux.DETNOTREC_DESCUENTO) / 100;
        descuento2 = (Number(aux.DETNOTREC_COSTOFOB) - descuento1) * Number(aux.DETNOTREC_DESCUENTO2) / 100;
        descuento3 = (Number(aux.DETNOTREC_COSTOFOB) - descuento2 - descuento1) * Number(aux.DETNOTREC_DESCUENTO3) / 100;
        if (aux.DETNOTREC_DESCUENTO !== 0 || aux.DETNOTREC_DESCUENTO2 !== 0 || aux.DETNOTREC_DESCUENTO3 !== 0) {
          aux.DETNOTREC_TOTAL = Number(aux.DETNOTREC_COSTOFOB) - descuento1 - descuento2 - descuento3;
        } else {
          aux.DETNOTREC_TOTAL = Number(aux.DETNOTREC_COSTOFOB);
        }
        if (Number(aux.DETNOTREC_TOTAL) > 0
          && Number(aux.DETNOTREC_CANTIDAD) > 0) {
          if (aux.DETNOTREC_DESCUENTO !== 0 || aux.DETNOTREC_DESCUENTO2 !== 0 || aux.DETNOTREC_DESCUENTO3 !== 0) {
            aux.DETNOTREC_COSTO = Number(aux.DETNOTREC_COSTOFOB) / Number(aux.DETNOTREC_CANTIDAD);
          } else {
            aux.DETNOTREC_COSTO = Number(aux.DETNOTREC_TOTAL) / Number(aux.DETNOTREC_CANTIDAD);
          }
        } else {
          aux.DETNOTREC_COSTO = (0);
        }
        aux.DETNOTREC_COSTOFOB = (0);
        
        this.calcularTotales(this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].indexOf(aux));
      }
      // guardado nota recepcion nuevos valores (ACTUALIZAR)
      
      this.insertarDetalle(aux, this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_NUMERO).subscribe(res1 => {
      });
      
      if (this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].length - 1 === this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].indexOf(aux)) {
        
        this.insertarEncabezado(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice]).subscribe(() => {
        });
      }
      
      
    });
    
  }
  
  calcularVarios() {
    let total = 0;
    
    
    this.comnotrecVararray[this.init.tabs[this.init.tabindex].indice].map(aux => {
      total += Number(aux.NOTRECVAR_IMPORTE);
      
      
    });
    this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].TOTAL_VARIOS = Number(total).toFixed(this.decimalesGenerales);
    return total;
    
  }
  
  calcularVariosmodificacion(index) {
    this.comnotrecVararray[this.init.tabs[this.init.tabindex].indice][index].NOTRECVAR_IMPORTE = Number(this.comnotrecVararray[this.init.tabs[this.init.tabindex].indice][index].NOTRECVAR_IMPORTE).toFixed(this.decimalesGenerales);
    this.calcularVarios();
  }
  
  descuentoGlobal() {
    
    
    this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_PORCEDES = Number(this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice]).toFixed(this.decimalesGenerales);
    this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_VALORDES = Number(this.dblValorDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice]).toFixed(this.decimalesGenerales);
    
    this.actualizaValores(this.sumaTotales(), this.sumaBaseIva(), this.sumaBaseIce(),
      this.sumaIce(), this.sumaBaseCero());
  }
  
  calcularTotales(index) {
    if (index !== null) {
      let value = 0//this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][index].DETNOTREC_CANTIDAD;
      //value *= this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][index].DETNOTREC_COSTO;
      //CDPJ
     let val=10 ** this.decimalesCostoTotal;
     let total=Math.round(this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][index].DETNOTREC_CANTIDAD
       * val * this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][index].DETNOTREC_COSTO) / val;
       value = Number(total.toFixed(this.decimalesCostoTotal));
     console.log('TOTALITEM',value);
     //CDPJ
      let dscto = this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][index].DETNOTREC_DESCUENTO;
      dscto = 1 - ((dscto > 0.0) ? dscto / 100.0 : 0.0);
      value *= dscto;
      
      dscto = this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][index].DETNOTREC_DESCUENTO2;
      dscto = 1 - ((dscto > 0.0) ? dscto / 100.0 : 0.0);
      value *= dscto;
      
      dscto = this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][index].DETNOTREC_DESCUENTO3;
      dscto = 1 - ((dscto > 0.0) ? dscto / 100.0 : 0.0);
      value *= dscto;
      
      // //LINEAS COMENTADAS PARA QUE NO SE CONCIDERE
      // /EL FACTOR POR QUE SE QUITO LA CONFICURACION Y SE ACULTARA ERL
      // CAMPO
      
      /*
       if (this.bolConsideraFactor) { value *= det.getDetNotRecFactor(); }
       */
      this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][index].DETNOTREC_FACTOR = Number(0).toFixed(this.decimalesGenerales);
      this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][index].DETNOTREC_COSTO = Number(this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][index].DETNOTREC_COSTO).toFixed(this.decimalesCosto);
      this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][index].DETNOTREC_CANTIDAD = Number(this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][index].DETNOTREC_CANTIDAD).toFixed(this.decimalesCantidad);
      this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][index].DETNOTREC_DESCUENTO = Number(this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][index].DETNOTREC_DESCUENTO).toFixed(this.decimalesGenerales);
      this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][index].DETNOTREC_DESCUENTO2 = Number(this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][index].DETNOTREC_DESCUENTO2).toFixed(this.decimalesGenerales);
      this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][index].DETNOTREC_DESCUENTO3 = Number(this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][index].DETNOTREC_DESCUENTO3).toFixed(this.decimalesGenerales);
      this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][index].DETNOTREC_TOTAL = Number(value).toFixed(this.decimalesCostoTotal);
    }
    this.actualizarSumatorias();
    
  }
  
  
  
  ejecutarConsulta1(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/notasrecepcion/74dccc5f9eaf540', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  ejecutarConsulta2(strNumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/notasrecepcion/3a6e662fcf57a2', {
      elementos: {
        numero: strNumero
      },
    }, this.confIniciales.httpOptions());
  }
  
  ejecutarConsulta3(strNumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/notasrecepcion/3a6e662fcf57a4', {
      elementos: {
        numero: strNumero
      },
    }, this.confIniciales.httpOptions());
  }
  ejecutarConsulta4(strArtCodigo, strNumero, intLinea): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/notasrecepcion/3a6e662fcf57b4', {
      elementos: {
        art_codigo: strArtCodigo,
        numero: strNumero,
        linea: intLinea
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  ejecutarConsultaPromise(strNumero): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/notasrecepcion/e9b998bf3d5cf000', {
      elementos: {
        numero: strNumero
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  actualizarSumatorias() {
    this.actualizaValores(this.sumaTotales(), this.sumaBaseIva(), this.sumaBaseIce(),
      this.sumaIce(), this.sumaBaseCero());
  }
  
  // actualizaValores(totales, biva, bice, ice, bcero) {
  //   if (this.dblValorDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice] === undefined || this.dblValorDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice] === null) {
  //     this.dblValorDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice] = 0;
  //   }
  //   if (this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice] === undefined || this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice] === null) {
  //     this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice] = 0;
  //   }
  //   this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_BASEIVA = biva;
  //   this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_BASEIVA =
  //     Number(Number(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_BASEIVA) -
  //       (Number(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_BASEIVA) *
  //         (Number(this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice]) / 100))).toFixed(this.decimalesGenerales);
  //   /*this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_VALORIVA =
  //     Number(Number(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_BASEIVA *
  //     Number(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_PORCEIVA / 100)) -
  //     (Number(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_VALORIVA) *
  //       (Number(this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice]) / 100))).toFixed(this.decimalesGenerales);*/
    
  //   this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_VALORIVA =
  //     Number(Number(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_BASEIVA *
  //       Number(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_PORCEIVA / 100))
  //     ).toFixed(this.decimalesGenerales);
    
  //   this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_BASECERO = bcero;
  //   this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_BASECERO = Number(Number(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_BASECERO) -
  //     (Number(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_BASECERO) * (Number(this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice]) / 100))).toFixed(this.decimalesGenerales);
    
  //   this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_TOTALNETO = Number(totales).toFixed(this.decimalesGenerales);
    
  //   this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_BASEICE = Number(bice).toFixed(this.decimalesGenerales);
  //   this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_VALORICE = Number(ice).toFixed(this.decimalesGenerales);
    
  //   this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_TOTAL = Number(Number(totales) + Number(ice) +
  //     Number(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_VALORIVA) - this.dblValorDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice]).toFixed(this.decimalesTotal);
  // }
  actualizaValores(totales, biva, bice, ice, bcero) {
    if (this.dblValorDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice] === undefined || this.dblValorDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice] === null) {
      this.dblValorDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice] = 0;
    }
    if (this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice] === undefined || this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice] === null) {
      this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice] = 0;
    }
    if(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_TIPODES === 'V'){
      this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice]=(this.dblValorDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice] * 100)/totales
    }

    console.log('biva',biva)
    this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_BASEIVA = Number(biva).toFixed(this.decimalesGenerales);
    //CDPJ LO COMENTE PORQUE EL DESCUENTO LO HACE POR LINEA DE DETALLE
    // this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_BASEIVA =
    //   Number(Number(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_BASEIVA) -
    //     (Number(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_BASEIVA) *
    //       (Number(this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice]) / 100))).toFixed(this.decimalesGenerales);
//CDPJ
    /*this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_VALORIVA =
      Number(Number(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_BASEIVA *
      Number(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_PORCEIVA / 100)) -
      (Number(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_VALORIVA) *
        (Number(this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice]) / 100))).toFixed(this.decimalesGenerales);*/
    
     this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_VALORIVA =Number(Number(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_VALORIVA).toFixed(this.decimalesGenerales))
    //   Number(Number(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_BASEIVA *
    //     Number(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_PORCEIVA / 100))
    //   ).toFixed(this.decimalesGenerales);
    //CDPJ
    console.log('totales',totales)
    console.log('Number(this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice])',Number(this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice]))
    this.dblValorDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice] = totales * (Number(this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice]) / 100);
    this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_VALORDES =Number(this.dblValorDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice]).toFixed(2)
    //CDPJ
    this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_BASECERO = Number(bcero).toFixed(this.decimalesGenerales);
    //CDPJ LO COMENTE PORQUE EL DESCUENTO LO HACE POR LINEA DE DETALLE
    // this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_BASECERO = Number(Number(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_BASECERO) -
    //   (Number(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_BASECERO) * (Number(this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice]) / 100))).toFixed(this.decimalesGenerales);
    //CDPJ
    this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_TOTALNETO = Number(totales).toFixed(this.decimalesGenerales);
    
    this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_BASEICE = Number(bice).toFixed(this.decimalesGenerales);
    this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_VALORICE = Number(ice).toFixed(this.decimalesGenerales);
    
    this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_TOTAL = Number(Number(totales) + Number(ice) +
      Number(this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_VALORIVA) - this.dblValorDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice]).toFixed(this.decimalesTotal);
  }
  sumaTotales() {
    let suma = 0.0;
    this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].map(aux => {
      if (aux.DETNOTREC_TIPODET !== '*') {
        suma += Number(aux.DETNOTREC_TOTAL);
      }
    });
    return suma;
  }
  
  // sumaArticulos() {
  //   let suma = 0.0;
  //   this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].map(aux => {
  //     // if (aux.DETNOTREC_TIPODET === 'A') {
  //     if (aux.DETNOTREC_TIPODET !== '*') {
  //       suma += Number(aux.DETNOTREC_TOTAL);
  //     }
  //   });
  //   suma -= Number(suma * (this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice] / 100));
  //   return Number(suma.toFixed(this.decimalesGenerales));
  // }
  //CDPJ
  sumaArticulos() {
    let suma = 0.0;
    let porcentaje=0.0;
    this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].map(aux => {
      // if (aux.DETNOTREC_TIPODET === 'A') {
      if (aux.DETNOTREC_TIPODET !== '*') {
        suma += Number(aux.DETNOTREC_TOTAL);
        //console.log('total art',aux.DETNOTREC_TOTAL);
      }
    });
   // console.log('suma',suma);
     
    porcentaje=Number(suma * (this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice] / 100));
    suma=suma-porcentaje;
    //console.log('porcentaje',(this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice] / 100));
   /// console.log('suma',suma);
    return Number(suma.toFixed(this.decimalesGenerales));
    
  }
  sumaArticulosSNDG() {
    let suma = 0.0;
    let porcentaje=0.0;
    this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].map(aux => {
      // if (aux.DETNOTREC_TIPODET === 'A') {
      if (aux.DETNOTREC_TIPODET !== '*') {
        suma += Number(aux.DETNOTREC_TOTAL);
        //console.log('total art',aux.DETNOTREC_TOTAL);
      }
    });
   // console.log('suma',suma);
     
    porcentaje=Number(suma * (this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice] / 100));
    suma=suma//-porcentaje;
    //console.log('porcentaje',(this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice] / 100));
   /// console.log('suma',suma);
    return Number(suma.toFixed(this.decimalesGenerales));
    
  }
  // sumaBaseIva() {
  //   let suma = 0.0;
  //   this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_VALORIVA = 0;
  //   const iva = this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_PORCEIVA;
  //   this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].map(aux => {
  //     if (aux.DETNOTREC_TIPODET !== '*' && aux.DETNOTREC_TRIBIVA === 'S') {
  //       let auxTotal = Number(aux.DETNOTREC_TOTAL);
  //       suma += (this.bolConsideraIvaarray[this.init.tabs[this.init.tabindex].indice]) ? auxTotal : 0.0;
  //       auxTotal *= (iva / 100.0);
  //       aux.DETNOTREC_BASEIVA = (this.bolConsideraIvaarray[this.init.tabs[this.init.tabindex].indice]) ? auxTotal : 0.0;
  //       aux.DETNOTREC_IVA = (this.bolConsideraIvaarray[this.init.tabs[this.init.tabindex].indice]) ? auxTotal : 0.0;
  //       this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_VALORIVA = (this.bolConsideraIvaarray[this.init.tabs[this.init.tabindex].indice]) ? auxTotal : 0.0;
        
  //     }
  //   });
    
  //   return suma;
    
  // }
  //CDPJ
  sumaBaseIva() {
    let suma = 0.0;
    
    this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_VALORIVA = 0;
    const iva = this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_PORCEIVA;
    this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].map(aux => {
      if (aux.DETNOTREC_TIPODET !== '*' && aux.DETNOTREC_TRIBIVA === 'S') {
        console.log(aux.DETNOTREC_PORIVA)
        let auxTotal = Number(aux.DETNOTREC_TOTAL);
        let sumaivadet=0.0;
        auxTotal =Number(auxTotal * (1 - this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice] / 100));
        suma += (this.bolConsideraIvaarray[this.init.tabs[this.init.tabindex].indice]) ? auxTotal : 0.0;
        sumaivadet=auxTotal;
        auxTotal *= (aux.DETNOTREC_PORIVA / 100.0);
        //auxTotal *= (iva / 100.0);
        console.log('auxTotal',auxTotal)
        aux.DETNOTREC_BASEIVA = (this.bolConsideraIvaarray[this.init.tabs[this.init.tabindex].indice]) ? sumaivadet : 0.0;
        aux.DETNOTREC_IVA = (this.bolConsideraIvaarray[this.init.tabs[this.init.tabindex].indice]) ? auxTotal : 0.0;
        this.comnotrecRecepcionSelectarray[this.init.tabs[this.init.tabindex].indice].ENCNOTREC_VALORIVA += (this.bolConsideraIvaarray[this.init.tabs[this.init.tabindex].indice]) ? auxTotal : 0.0;
        
      }
    });
    
    return suma;
    
  }
  //CDPJ
  
  sumaBaseIce() {
    let suma = 0.0;
    this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].map(aux => {
      if (aux.DETNOTREC_TIPODET !== '*' && aux.DETNOTREC_TRIBICE === 'S') {
        const auxTotal = Number(aux.DETNOTREC_TOTAL);
        suma += auxTotal;
        aux.DETNOTREC_BASEICE = auxTotal;
        aux.DETNOTREC_ICE = Number(auxTotal) * (aux.DETNOTREC_ICE / 100);
      }
      
    });
    return suma;
  }
  
  sumaIce() {
    let suma = 0.0;
    this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].map(aux => {
      if (aux.DETNOTREC_TIPODET !== '*' && aux.DETNOTREC_TRIBIVA === 'S') {
        suma += Number(aux.DETNOTREC_ICE);
        
      }
      
    });
    
    
    return suma;
  }
  
  // sumaBaseCero() {
  //   let suma = 0.0;
  //   this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].map(aux => {
  //     if (aux.DETNOTREC_TIPODET !== '*' && aux.DETNOTREC_TRIBIVA === 'N') {
  //       suma += Number(aux.DETNOTREC_TOTAL);      
  //     }    
  //   });
  //   return suma; 
  // }
  sumaBaseCero() {
    let suma = 0.0;
    
    this.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].map(aux => {
      if (aux.DETNOTREC_TIPODET !== '*' && aux.DETNOTREC_TRIBIVA === 'N') {
        let basecerodes=Number(aux.DETNOTREC_TOTAL)
        basecerodes=Number(basecerodes * (1 - this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice] / 100))
        suma += Number(basecerodes);
        aux.DETNOTREC_BASECERO=Number(basecerodes)
      }
      
    });
    
    
    return suma;
    
  }
  
  cambiarEstado(encnotrec): Observable<any> {
    return this.http.post<any>(this.way + '/compras/notasrecepcion/c5597256ddcea800', {
      elementos: {
        p_ENCNOTREC_numero: encnotrec.ENCNOTREC_NUMERO,
        p_COM_codigo: '01',
        p_ENCNOTREC_estado: encnotrec.ENCNOTREC_ESTADO
      }
    }, this.confIniciales.httpOptions());
  }
  
  cambiarEstadoProm(encnotrec) {
    console.log('cambiarEstadoProm', encnotrec);
    return this.http.post<any>(this.way + '/compras/notasrecepcion/18ab2ddcea9b8d0000', {
      elementos: {
        p_ENCORDCOM_NUMERO: encnotrec.ENCORDCOM_NUMERO,
        p_COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  verificarFacProProm(encabezado) {
    console.log('verificarFacProProm', encabezado);
    return this.http.post<any>(this.way + '/compras/notasrecepcion/1f76e4f9315b7b000', {
      elementos: {
        p_ENCNOTREC_NUMERO: encabezado.ENCNOTREC_NUMERO,
        p_COM_codigo: '01',
        p_tipo: 'R'
      }
    }, this.confIniciales.httpOptions()).toPromise();
    
  }
  
  registrarKardexProm(encabezado) {
    console.log(' registrarKardexProm', encabezado);
    return this.http.post<any>(this.way + '/compras/notasrecepcion/36e84b9ddab7460000', {
      elementos: {
        p_ENCNOTREC_NUMERO: encabezado.ENCNOTREC_NUMERO,
        p_COM_codigo: '01',
        
      }
    }, this.confIniciales.httpOptions()).toPromise();
    
  }
  
  insertarEncabezadoProm(encabezado) {
    console.log('ENCABEZADO', encabezado);
    
    return this.http.post<any>(this.way + '/compras/notasrecepcion/12bf1dbeab6ebb000', {
      elementos: {
        p_ENCNOTREC_NUMERO: encabezado.ENCNOTREC_NUMERO,
        p_COM_CODIGO: '01',
        p_PRO_CODIGO: encabezado.PRO_CODIGO,
        P_ENCNOTREC_REFERENCIA: encabezado.ENCNOTREC_REFERENCIA,
        p_ENCNOTREC_CONTACTO: encabezado.ENCNOTREC_CONTACTO,
        p_ENCNOTREC_FECHAEMISION: encabezado.ENCNOTREC_FECHAEMISION,
        p_ENCNOTREC_FECHAENTREGA: encabezado.ENCNOTREC_FECHAENTREGA,
        p_ENCNOTREC_IVA: '1', // ojo
        p_ENCNOTREC_ESTADO: encabezado.ENCNOTREC_ESTADO,
        p_ENCNOTREC_OBSERVACION: encabezado.ENCNOTREC_OBSERVACION,
        p_ENCNOTREC_TOTAL: Number(encabezado.ENCNOTREC_TOTAL),
        p_ENCNOTREC_TOTALNETO: Number(encabezado.ENCNOTREC_TOTALNETO),
        p_ENCNOTREC_VALORIVA: Number(encabezado.ENCNOTREC_VALORIVA),
        p_ENCNOTREC_PORCEIVA: Number(encabezado.ENCNOTREC_PORCEIVA),
        p_ENCNOTREC_VALORICE: Number(encabezado.ENCNOTREC_VALORICE),
        p_ENCNOTREC_BASEIVA: Number(encabezado.ENCNOTREC_BASEIVA),
        p_ENCNOTREC_BASEICE: Number(encabezado.ENCNOTREC_BASEICE),
        p_ENCNOTREC_BASECERO: Number(encabezado.ENCNOTREC_BASECERO),
        p_ENCNOTREC_OTROS: encabezado.ENCNOTREC_OTROS,
        p_ENCNOTREC_MERCONS: encabezado.ENCNOTREC_MERCONS,
        P_ENCNOTREC_REFERENCIA_ADI: encabezado.ENCNOTREC_REFERENCIA_ADI,
        p_maemotivo_codigo: encabezado.MAEMOTIVO_CODIGO,
        p_ENCNOTREC_VALORDES: Number(encabezado.ENCNOTREC_VALORDES),
        p_ENCNOTREC_PORCEDES: Number(encabezado.ENCNOTREC_PORCEDES),
        p_ENCNOTREC_TIPODES: encabezado.ENCNOTREC_TIPODES,
        p_ENCNOTREC_TASA_CAMBIO:Number(encabezado.ENCNOTREC_TASA_CAMBIO)
      }
    }, this.confIniciales.httpOptions()).toPromise();
    
  }
  
  cambiarEstadoOrden(encabezado) {
    console.log('cambiarEstadoOrden', encabezado);
    return this.http.post<any>(this.way + '/compras/notasrecepcion/62acb773aa6e300', {
      elementos: {
        p_ENCNOTREC_NUMERO: encabezado.ENCNOTREC_NUMERO,
        p_COM_CODIGO: '01'
      
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarNotaRecepcionProm(encabezado) {
    return this.http.post<any>(this.way + '/compras/notasrecepcion/eacad7c755b73000', {
      elementos: {
        p_ENCNOTREC_NUMERO: encabezado.ENCNOTREC_NUMERO,
        p_COM_codigo: '01',
        
      }
    }, this.confIniciales.httpOptions()).toPromise();
    
  }
    //CDPJ
    obtenerClaveProm() {
      return this.http.post<any[]>(this.way + '/compras/notasrecepcion/c2faebbb6caac0000000', {      
        elementos: {},
      }, this.confIniciales.httpOptions()).toPromise();
    }
    encontrarArtCntaySer(strArtCodigo, strtipo) {
      return this.http.post<any[]>(this.way + '/compras/notasrecepcion/75d98bf76adab8000000', {
        elementos: {
          ArtCodigo: strArtCodigo,
          strTIPO: strtipo
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }
    encontrarArttribIva(strArtCodigo,strtipo) {
      return this.http.post<any[]>(this.way + '/compras/notasrecepcion/75d98bf76adab800000000000', {
        elementos: {
          ArtCodigo: strArtCodigo,
          strTIPOI: strtipo
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }
    erBodCodigo1(strCodigo) {
      return this.http.post(this.way + '/compras/notasrecepcion/76d786b30d94300', {
        elementos: {
          BOD_CODIGO: strCodigo
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    validarcencodigo(cencodigo) {

      return this.http.post<any[]>(this.way + '/ventas/guiaremision/1f5564d56d8ebf0000000000000', {
        elementos: {
          cen_codigo: cencodigo,
          con_codigo: '01'
        }
      }, this.confIniciales.httpOptions()).toPromise();
  
    }
    EncontrarProvedor(strprovecodigo) {
      return this.http.post(this.way + '/compras/ordencompra/ebb317eed5bcf00000000', {
        elementos: {
          pro_codigo: strprovecodigo,
          
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }
    obtenerbasesiva(numero) {
      return this.http.post<any>(this.way + '/compras/notasrecepcion/107756ae3b92fb00000000', {
        elementos: {
          p_notrec_numero:numero
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }
    actualizabasesiva(numero) {
      return this.http.post<any>(this.way + '/compras/notasrecepcion/a676f8eedcc5a80000000', {
        elementos: {
          p_encnotrec_numero:numero,
          p_com_codigo:'01'
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }
    //CDPJ

    //GSRF
    consultarfaccomatada(res) {
      return this.http.post<any>(this.way + '/compras/notasrecepcion/198bf3d57a98af0000', {
        elementos: {
          art: res.DETNOTREC_CODIGO,
          numero: res.ENCNOTREC_NUMERO
        }
      }, this.confIniciales.httpOptions()).toPromise();
      
    }
    eliminaimpall(ENCNOTREC_NUMERO) {
      return this.http.post<any>(this.way + '/compras/notasrecepcion/1aeabbae95b38e0000', {
        elementos: {
          p_ENCNOTREC_NUMERO: ENCNOTREC_NUMERO,
        }
      }, this.confIniciales.httpOptions()).toPromise();
      
    }
    anularAsiento(strAsiNro): Observable<any[]> {
     
      return this.http.post<any[]>(this.way + '/cont/conencasil/abfaaadab9276000', {
          elementos: {
            ASI_NRO: strAsiNro
          }
        }, this.confIniciales.httpOptions()
      );
    }
    //GSRF
}
