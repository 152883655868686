import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {Observable} from 'rxjs';
import {BanCashMngDet} from '../baninterfaces/bancashmng';

@Injectable({
  providedIn: 'root'
})
export class BancashmngService {
  
  private way: string;

  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }
  
  obtenerEncCashMng(tipo, codigo) {
    return this.http.post<any[]>(this.way + '/ban/bancashmng/185f5d76315c8e000', {
      elementos: {
        p_tipo: tipo,
        p_com_codigo: '01',
        p_codigo: codigo,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  mostrarDatos(strEncCash: number) {
   return this.http.post<any[]>(this.way + '/ban/bancashmng/b639ddab6d57700', {
     elementos: {
       enccashmng_codigo: strEncCash
     }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  listarDatos(strEncBandejaCodigo) {
    return this.http.post<any[]>(this.way + '/ban/bancashmng/565ceab6d5771c', {
      elementos: {
        enccashmng_codigo: strEncBandejaCodigo
      }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  autoCompletar(strCadena, intTamanioCadena) {
    let intAux = 0;
    let indice = 0;
    let strAutoCompletar = '';
    
    intAux = strCadena.length;
    for (indice = 1; indice <= intTamanioCadena - intAux; indice++) {
      strAutoCompletar = '0' + strAutoCompletar;
    }
    strAutoCompletar = strAutoCompletar + strCadena;
    return strAutoCompletar;
  }  
  
  verificarBancoCash(strCodBanco, strTipoBanco) {
    return this.http.post<any[]>(this.way + '/ban/bancashmng/fbb727ad5762b80', {
        elementos: {
          cfg_codigo: strTipoBanco,
          cfg_valor3: strCodBanco
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  encontrarBanco(strCondicion): Observable<any> {
    return this.http.post<any[]>(this.way + '/ban/bancashmng/3aecc5fab55d98', {
        elementos: {
          condicion: strCondicion
        },
        
      }, this.confIniciales.httpOptions()
    );
  }
  
  listarDatosAceptar(fechaInicio, fechaFin, banCodigo) {
    return this.http.post<any[]>(this.way + '/ban/bancashmng/acb9d6abb853200', {
      elementos: {
        p_fecha_inicial: fechaInicio,
        p_fecha_final: fechaFin,
        p_com_codigo: '01',
        p_ban_codigo: banCodigo,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  listarDatosAceptarG(fechaInicio, fechaFin, banCodigo) {
    return this.http.post<any[]>(this.way + '/ban/bancashmng/15973ad5770a64000', {
      elementos: {
        p_fecha_inicial: fechaInicio,
        p_fecha_final: fechaFin,
        p_com_codigo: '01',
        p_ban_codigo: banCodigo,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  encontrardirectoriocm(srtCondicion) {
    return this.http.post<any[]>(this.way + '/ban/bancashmng/3aecc5fad96d96', {
      elementos: {
        condicion: srtCondicion
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  guardarEncCashmng(codigo, comprobante, femision, banCodigo, fDesde, fFin, incret, inctod, concepto, total, numEnvio, path) {
    return this.http.post<any[]>(this.way + '/ban/bancashmng/8795b6baec62b80', {
      elementos: {
        p_enccashmng_codigo: codigo,
        p_enccashmng_comprobante: comprobante,
        p_enccashmng_fechaemision: femision,
        p_ban_codigo: banCodigo,
        p_enccashmng_fecdesde: fDesde,
        p_enccashmng_fecfin: fFin,
        p_enccashmng_incret: incret,
        p_enccashmng_inctod: inctod,
        p_enccashmng_concepto: concepto,
        p_com_codigo: '01',
        p_enccashmng_total: total,
        p_enccashmng_numenvio: numEnvio,
        p_usuidentificacion: this.usuario.identificacion,
        p_enccashmng_patharchivo: path,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarLineaDetalle(codigo) {
    return this.http.post<any[]>(this.way + '/ban/bancashmng/3ab2b4ab2bb5dc', {
      elementos: {
        p_enccashmng_codigo: codigo,
        p_com_codigo: '01',
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  insertarDetalleCash(codigo, item: BanCashMngDet) {
    return this.http.post<any[]>(this.way + '/ban/bancashmng/12bf1aeeb15c8e000', {
      elementos: {
        p_enccashmng_codigo: codigo,
        p_detcashmng_linea: 0,
        p_com_codigo: '01',
        p_detcashmng_fecha: item.DETCASHMNG_FECHA,
        p_detcashmng_comprobante: item.DETCASHMNG_COMPROBANTE,
        p_pro_codigo: item.PRO_CODIGO,
        p_detcashmng_importe: Number(item.DETCASHMNG_IMPORTE),
        p_detcashmng_tipo: item.DETCASHMNG_TIPO,
        p_ban_codigo: item.BAN_CODIGO,
        p_trn_bantipo: item.TRN_BANTIPO,
        p_trn_bannumero: item.TRN_BANNUMERO,
        p_com_bancodigocm: item.COM_BANCODIGOCM,
        p_com_tipoctacm: item.COM_TIPOCTACM,
        p_com_numctacm: item.COM_NUMCTACM,
        p_com_forpagcm: item.COM_FORPAGCM,
        p_com_benfcm: item.COM_BENFCM,
        p_com_difbenfcm: item.COM_DIFBENFCM,
        p_com_telfbenfcm: item.COM_TELFBENFCM,
        p_pro_tipoidentifcm: item.PRO_TIPOIDENTIFCM,
        p_pro_idcm: item.PRO_IDCM,
        p_pro_ciudadcm: item.PRO_CIUDADCM,
        p_pro_locpagcm: item.PRO_LOCPAGCM,
        p_pro_emailcm: item.PRO_EMAILCM,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarEncCashmng(codigo) {
    return this.http.post<any[]>(this.way + '/ban/bancashmng/3ab2b3aec62b90', {
      elementos: {
        p_enccashmng_codigo: codigo,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  formaPago() {
    return this.http.post<any[]>(this.way + '/ban/bancashmng/c2fafc6ecacaa00', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  entidadFinanciera() {
    
    return this.http.post<any[]>(this.way + '/ban/bancashmng/75fb26a9af95d40', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  tipoCuenta() {
    return this.http.post<any[]>(this.way + '/ban/bancashmng/ecb386b99e75f80', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  obtenerDatosCashmgnEdit(strProCodigo, intEnccashCodigo ) {
    return this.http.post<any[]>(this.way + '/ban/bancashmng/617d62b917365c', {
        elementos: {
          pro_codigo: strProCodigo,
          enccashmng_codigo: intEnccashCodigo
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  guardarDatosCashManagement(proCodigo, banCodigocm, tipoCtacm, numCtacm, forpagcm, benfcm, difbenfcm, telfbenfcm, tipoidentifcm, idcm,
                             ciudadcm, locpagcm, emailcm, encCashmngCodigo ) {
    return this.http.post<any[]>(this.way + '/ban/bancashmng/8795b6abb862b80', {
      elementos: {
        p_pro_codigo: proCodigo,
        p_detcashmng_bancodigocm: banCodigocm,
        p_detcashmng_tipoctacm: tipoCtacm,
        p_detcashmng_numctacm: numCtacm,
        p_detcashmng_forpagcm: forpagcm,
        p_detcashmng_benfcm: benfcm,
        p_detcashmng_difbenfcm: difbenfcm,
        p_com_codigo: '01',
        p_detcashmng_telfbenfcm: telfbenfcm,
        p_detcashmng_tipoidentifcm: tipoidentifcm,
        p_detcashmng_idcm: idcm,
        p_detcashmng_ciudadcm: ciudadcm,
        p_detcashmng_locpagcm: locpagcm,
        p_detcashmng_emailcm: emailcm,
        p_enccashmng_codigo: encCashmngCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  guardarDatosFichaProv(proCodigo, bancodigocm, tipoctacm, numctacm, forpagcm, benfcm, difbenfcm, telfbenfcm, tipoidentifcm, idcm, ciudadcm,
                        locpagcm, emailcm) {
    return this.http.post<any[]>(this.way + '/ban/bancashmng/8795b6abb87e780', {
      elementos: {
        p_pro_codigo: proCodigo,
        p_com_bancodigocm: bancodigocm,
        p_com_tipoctacm: tipoctacm,
        p_com_numctacm: numctacm,
        p_com_forpagcm: forpagcm,
        p_com_benfcm: benfcm,
        p_com_difbenfcm: difbenfcm,
        p_com_codigo: '01',
        p_com_telfbenfcm: telfbenfcm,
        p_pro_tipoidentifcm: tipoidentifcm,
        p_pro_idcm: idcm,
        p_pro_ciudadcm: ciudadcm,
        p_pro_locpagcm: locpagcm,
        p_pro_emailcm: emailcm
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  ejeSpAchivoCash(encCodigo) {
    return this.http.post<any[]>(this.way + '/ban/bancashmng/e9bb9956d9162800', {
      elementos: {
        p_ENCCASHMNG_CODIGO: encCodigo,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerDatosAchivoCashFormato3() {
    return this.http.post<any[]>(this.way + '/ban/bancashmng/c2fad5770fc6f00', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  obtenerDatosRubro(strDocumento) {
    return this.http.post<any[]>(this.way + '/ban/bancashmng/c2fad5771bf2f80', {
        elementos: {
          trncobro_nrodoc: strDocumento
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  ejeSpCashDatosDet(nrDoc, encCodigo, cashLinea) {
    return this.http.post<any[]>(this.way + '/ban/bancashmng/74ddccb15c8b5c0', {
      elementos: {
        p_TRNCOBRO_NRODOC: nrDoc,
        p_ENCCASHMNG_CODIGO: encCodigo,
        p_DETCASHMNG_LINEA: cashLinea,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  generarDetalleDoc() {
    return this.http.post<any[]>(this.way + '/ban/bancashmng/20ebb9aeeb70c', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  
}
