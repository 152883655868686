//
// SACI WEB
// Rutina: Ingreso de documentos en Cuentas por Cobrar
// Propietario: Francisco Cevallos
// Modificado por: FC
// Fecha de creación: 09-03-2020
// Fecha de Modificación: 09-03-2020
//

import {Component, OnInit, ViewChild} from '@angular/core';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {DatePipe} from '@angular/common';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {CxctrnpagoretService} from '../cxcservicios/cxctrnpagoret.service';
import {Cxcpagoret} from '../cxcinterfaces/cxcpagoret';
import {Cxcpagoretdocumentos} from '../cxcinterfaces/cxcpagoretdocumentos';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {AggridBanComponent} from '../../ban/aggrid-ban/aggrid-ban.component';
import {Cxcpagoretformapago} from '../cxcinterfaces/cxcpagoretformapago';
import {Cxcpagoretretenciones} from '../cxcinterfaces/cxcpagoretretenciones';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {ComencdevfacService} from '../../com/comservicios/comencdevfac.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { Usuario } from 'src/app/usuario';

@Component({
  selector: 'app-cxctrnpagoret',
  templateUrl: './cxctrnpagoret.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class CxctrnpagoretComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  btnGuardar: boolean;
  btnPrimero: boolean;
  btnAnterior: boolean;
  btnSiguiente: boolean;
  btnUltimo: boolean;
  btnAsiento: boolean;
  btnVerAsiento: boolean;
  
  asiento: any;
  displayDialogAsiento: boolean;
  displayAsiContable: boolean;
  
  displayDialogReportes: boolean;
  auxreporte: number = 0;
  strNombreForma: string;
  
  opcion: string;
  busCLINOMBRE: string;
  busVENNOMBRE: string;
  estado: string;
  activeIndex: number;
  cxcpagoret: Cxcpagoret = {};
  
  retencionSeleccionada: Cxcpagoretretenciones;
  fpSeleccionada: Cxcpagoretformapago;
  
  docselected: boolean;
  
  btnNuevoGridret: boolean;
  btnGuardarGridret: boolean;
  btnBorrarGridret: boolean;
  btnCancelarGridret: boolean;
  
  btnNuevoGridfp: boolean;
  btnGuardarGridfp: boolean;
  btnBorrarGridfp: boolean;
  btnCancelarGridfp: boolean;
  
  nuevoreg: boolean;
  
  where: string;
  
  countFP: number;
  
  
  public frameworkComponents;
  largo: string;
  rowSelection = 'multiple';
  rowStyle;
  defaultColDefDoc;
  defaultColDefRet;
  defaultColDefFormaPago;
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  arregloBus: any[];
  busquedacampos: string[];
  tabla: string;
  choosedColor: string;
  choosedColorFP: string;
  numeroFactura: string;
  detalleRetencionSeleccionado: Cxcpagoretretenciones;
  lblAplicaRegMicroempresas: string;
  
  enableinputs: boolean;
  
  permisoCXCpagoret: SegMaePermiso;
  
  auxiliar: number;
  
  // test
  
  bolfaltaDatosRet: boolean;
  bolfaltaDatosFP: boolean;
  
  chkretelectronica: boolean

  displayDialogDocumentos = false;
  spin:boolean;

  columnDefsDoc = [
    {
      headerName: 'S/N', field: 'SELECCIONADO', cellRendererFramework: AggridBanComponent, width: 50, editable: true,
      /*onCellClicked: (params) => {
        this.enableCeldaImporte(params);
      }*/
      
    },
    {
      headerName: 'Nro.Documento', field: 'TRNDOCUMENTO_NRODOC', width: 200, editable: false,
      
    },
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 100, editable: false,
      
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 250, editable: false,
      
    },
    {
      headerName: 'Saldo', field: 'SALDOFAC', width: 100, editable: false,
      
    },
    {
      headerName: 'Importe', field: 'TRNDOCUMENTO_IMPORTE', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 50,
            tienecalculos: true
          }
        }
      
    },
    {
      headerName: 'Vendedor ', field: 'VEN_CODIGO', width: 100, editable: false,
      
    },
  ];
  
  columnDefsRet = [
    {
      headerName: 'Código', field: 'RETENCION_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 50,
            opcionbuscar: true,
            tienecalculos: true
            
          }
        }
    },
    {
      headerName: 'Descripción', field: 'TRNRETENCION_DESCRIPCION', cellEditor: 'cellPrueba', width: 200, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 50
          }
        }
    },
    {
      headerName: 'Número', field: 'TRNRETENCION_NRO', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 15,
            tienecalculos: true
            
          }
        }
    },
    {
      headerName: 'Base', field: 'TRNRETENCION_BASE', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 50,
            tienecalculos: true
          }
        }
    },
    {
      headerName: '%', field: 'TRNRETENCION_PORCENTAJE', cellEditor: 'cellPrueba', width: 100, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 50
          }
        }
    },
    {
      headerName: 'T.Retenid', field: 'TRNRETENCION_TOTALRETENIDO', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 50
          }
        }
    }
  ];
  
  columnDefsFormaPago = [
    {
      headerName: 'Tipo', field: 'TRNPAGO_TIPO', cellEditor: 'cellPrueba', width: 75,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 4,
            tienecalculos: true
          }
        }
    },
    {
      headerName: 'Caja', field: 'CAJ_CODIGO', cellEditor: 'cellPrueba', width: 75,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 50,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Banco', field: 'BANCLI_CODIGO', cellEditor: 'cellPrueba', width: 75,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 50,
            opcionbuscar: true,
            tienecalculos: true
          }
        }
    },
    {
      headerName: 'Nombre Banco', field: 'BAN_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 50
          }
        }
    },
    {
      headerName: 'Número', field: 'NUMERO', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 50
          }
        }
    },
    {
      headerName: 'Fecha', field: 'FECHA', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: false,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: true,
            tamanocelda: 50
          }
        }
    },
    {
      headerName: 'Concepto', field: 'CONCEPTO', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 50
          }
        }
    },
    {
      headerName: 'Importe', field: 'IMPORTE', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 50,
            tienecalculos: true,
          }
        }
    }
  ];
  
  constructor(public cxctrnpagoretService: CxctrnpagoretService, private busquedaSer: InvbusquedaService, private datePipe: DatePipe,
              private message: MessageService, private confirmationService: ConfirmationService,
              private errorService: ErroresBaseDatosService, private auditoriagral: AuditoriagralService,
              private permisosService: PermisosService, private init: NuevoComponentService,
              private confIniciales: ConfInicialesService, public usuario: Usuario) {
    
    this.agTable();
    this.cxctrnpagoretService.cxcpagoretdocumentos = [];
    this.cxctrnpagoretService.cxcpagoretretenciones = [];
    this.cxctrnpagoretService.cxcpagoretformapago = [];
    
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    
    this.rowStyle = (params) => {
      if (params.data.APLORG_CODIGO === 'SISTEMA' && params.data.TRNCOBRO_TIPODOC === 'FC') {
        return {background: '#AAFF00'};
      }
    };
    
    this.rowStyle = (params) => {
      if (params.data.TRNDOCUMENTO_REF === 'C') {
        return {background: '#AAFF00'};
      }
    };
    
    this.defaultColDefDoc = {
      width: 100,
      objeto: 'cxcpagoretdoc'
    };
    this.defaultColDefRet = {
      editable: true,
      width: 100,
      objeto: 'cxcpagoretret'
    };
    this.defaultColDefFormaPago = {
      editable: true,
      width: 100,
      objeto: 'cxcpagoretformapago'
    };
  }
  
  ngOnInit() {
    this.largo = '600';
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = true;
    this.btnRegresar = true;
    this.btnPrimero = false;
    this.btnAnterior = true;
    this.btnSiguiente = true;
    this.btnUltimo = false;
    this.btnAsiento = true;
    this.btnVerAsiento = true;
    
    this.auxiliar = 0;
    
    
    this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC = '';
    this.displayDialogBusquedaFast = false;
    this.activeIndex = 0;
    this.choosedColor = '#AAFF00';
    this.choosedColorFP = '#00FF00';
    this.numeroFactura = '';
    this.docselected = false;
    this.enableinputs = true;
    
    this.btnNuevoGridret = false;
    this.btnGuardarGridret = false;
    this.btnBorrarGridret = false;
    this.btnCancelarGridret = false;
    
    this.btnNuevoGridfp = false;
    this.btnGuardarGridfp = false;
    this.btnBorrarGridfp = false;
    this.btnCancelarGridfp = false;
    
    
    this.cxctrnpagoretService.cxcpagoretdocumentos = [];
    this.cxctrnpagoretService.cxcpagoretretenciones = [];
    this.cxctrnpagoretService.cxcpagoretformapago = [];
    
    this.nuevoreg = false;
    
    this.detalleRetencionSeleccionado = {};
    
    
    this.asiento = '';
    this.displayDialogAsiento = false;
    this.displayAsiContable = false;
    
    this.permisosService.getListaPermisos('CXC', '6', '341').subscribe((permiso) => {
      this.permisoCXCpagoret = permiso[0];
    });
    this.lblAplicaRegMicroempresas = '';
    this.chkretelectronica = false;//CDPJ
    this.spin=false;//CDPJ
    this.cxctrnpagoretService.cxcpagoret = {
      CLI_CODIGO: '', VEN_CODIGO: '', TRNCOBRO_NRODOC: '', ASI_NRO: '', TRNCOBRO_NROCOMPROBANTE: '',
      TRNCOBRO_FECHATRN: this.fechaactual(), TRNCOBRO_CONCEPTO: '', TOTALDOC: Number(0).toFixed(2),
      TOTALRET: Number(0).toFixed(2), TOTALFORMAPAGO: Number(0).toFixed(2),
      TOTALACOBRAR: Number(0).toFixed(2)
    };
    //CDPJ
  }
  //CDPJ
  ngOnDestroy(): void {
    this.confIniciales.instanciasPagoRet--;
  }
//CDPJ
  setFocus(event) {
    
    switch (event.target.id) {
      
      case 'cliente': {
        this.abrirCliente(event.target.value);
        document.getElementById('vendedor').focus();
        break;
      }
      case 'vendedor': {
        this.abrirVendedor(event.target.value);
        document.getElementById('nro').focus();
        break;
      }
      case 'nro': {
        document.getElementById('fecha').focus();
        break;
      }
      
      case 'fecha': {
        document.getElementById('concepto').focus();
        break;
      }
      
      
      default: {
        break;
      }
    }
    
  }
  
  async manejarSenales(valor) {
    if (valor === 'Buscar') {
      this.buscarDoc();
    }
    if (valor === 'Nuevo') {
      
      if (this.permisoCXCpagoret.PERINSERCION === 1) {
        this.btnNuevo = true;
        this.btnGuardar = false;
        this.btnBorrar = true;
        this.btnRegresar = false;
        this.btnUltimo = true;
        this.btnAnterior = true;
        this.btnSiguiente = true;
        this.btnPrimero = true;
        this.nuevoreg = true;
        this.enableinputs = false;
        this.limpiarDatos();
      } else if (this.permisoCXCpagoret.PERINSERCION === 0) {
        this.message.add({
          key: 'banmaebanKey',
          severity: 'error',
          summary: 'Inserción',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
      
    }
    if (valor === 'Cancelar') {
      
      this.cancelarRegistro();
      
    }
    if (valor === 'Guardar') {
      this.spin=true;//CDPJ
      this.guardarRegistro();
      /*if (this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC === '') {
        if (this.permisoCXCpagoret.PERINSERCION === 1) {
          this.guardarRegistro();
        } else if (this.permisoCXCpagoret.PERINSERCION === 0) {
          this.message.add({
            key: 'banmaebanKey',
            severity: 'error',
            summary: 'Inserción',
            detail: 'El usuario no tiene permisos de inserción, acción denegada'
          });
        }
      } else {
        if (this.permisoCXCpagoret.PERINSERCION === 1) {
          this.guardarRegistro();
        } else if (this.permisoCXCpagoret.PERINSERCION === 0) {
          this.message.add({
            key: 'banmaebanKey',
            severity: 'error',
            summary: 'Actualización',
            detail: 'El usuario no tiene permisos de actualización, acción denegada'
          });
        }
      }*/
      
    }
    if (valor === 'Borrar') {
      if (this.permisoCXCpagoret.PERELIMACION === 1) {
        if (!this.verificarImpreso()) {
          this.eliminarRegistro(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC);
        } else {
          this.message.add({
            key: 'cxctrnpagoretKey',
            severity: 'error',
            summary: 'Error',
            detail: 'No se puede eliminar un documento que ya a sido impreso.'
          });
        }
      } else if (this.permisoCXCpagoret.PERELIMACION === 0) {
        this.message.add({
          key: 'banmaebanKey',
          severity: 'error',
          summary: 'Eliminación ',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
      }
      
      
    }
    if (valor === 'Primero') {
      
      if (this.permisoCXCpagoret.PERSELECCION === 1) {
        this.btnPrimero = true;
        this.btnAnterior = true;
        this.btnSiguiente = false;
        this.btnUltimo = false;
        this.btnRegresar = true;
        this.btnGuardar = true;
        this.btnBorrar = false;
        
        this.seleccionarRegistro('P');
        
      } else if (this.permisoCXCpagoret.PERSELECCION === 0) {
        this.message.add({
          key: 'banmaebanKey',
          severity: 'error',
          summary: 'Selección',
          detail: 'El usuario no tiene permisos de selección, acción denegada'
        });
      }
      
    }
    if (valor === 'Anterior') {
      
      if (this.permisoCXCpagoret.PERSELECCION === 1) {
        
        this.btnPrimero = false;
        this.btnAnterior = false;
        this.btnSiguiente = false;
        this.btnUltimo = false;
        this.btnRegresar = true;
        this.btnGuardar = true;
        this.btnBorrar = false;
        
        this.seleccionarRegistro('A');
      } else if (this.permisoCXCpagoret.PERSELECCION === 0) {
        this.message.add({
          key: 'banmaebanKey',
          severity: 'error',
          summary: 'Selección',
          detail: 'El usuario no tiene permisos de selección, acción denegada'
        });
      }
      
      
    }
    if (valor === 'Siguiente') {
      
      if (this.permisoCXCpagoret.PERSELECCION === 1) {
        
        this.btnPrimero = false;
        this.btnAnterior = false;
        this.btnSiguiente = false;
        this.btnUltimo = false;
        this.btnRegresar = true;
        this.btnGuardar = true;
        this.btnBorrar = false;
        
        this.seleccionarRegistro('S');
      } else if (this.permisoCXCpagoret.PERSELECCION === 0) {
        this.message.add({
          key: 'banmaebanKey',
          severity: 'error',
          summary: 'Selección',
          detail: 'El usuario no tiene permisos de selección, acción denegada'
        });
      }
      
      
    }
    if (valor === 'Ultimo') {
      
      if (this.permisoCXCpagoret.PERSELECCION === 1) {
        this.btnPrimero = false;
        this.btnAnterior = false;
        this.btnSiguiente = true;
        this.btnUltimo = true;
        this.btnRegresar = true;
        this.btnGuardar = true;
        this.btnBorrar = false;
        
        this.seleccionarRegistro('U');
        
      } else if (this.permisoCXCpagoret.PERSELECCION === 0) {
        this.message.add({
          key: 'banmaebanKey',
          severity: 'error',
          summary: 'Selección',
          detail: 'El usuario no tiene permisos de selección, acción denegada'
        });
      }
      
      
    }
    if (valor === 'Asiento') {
      
      this.generarAsientoContable(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC);
    }
    if (valor === 'AsientoVer') {
      this.verAsiento();
    }
    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
    if (valor === 'Almacenardoc') {
      if (this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC !== '') {
        const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.message.add({
            key: 'cxctrnpagoretKey',
            severity: 'info',
            summary: 'Información',
            detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
          });
        }
        
      }
    }
  }
  
  verificarImpreso(): boolean {
    
    if (this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_IMPRESO === 'IMPRESO') {
      return true;
    } else {
      return false;
    }
  }
  
  eliminarRegistro(trncobronrodoc) {
    if (trncobronrodoc !== '') {
      if (this.cxctrnpagoretService.cxcpagoretformapago.length !== 0) {
        this.validacionesFPEliminar();
        
      } else {
        this.documentoCierre();
      }
      
      // this.confirmarDocumento(trncobronrodoc);
    }
    
  }
  
  validacionesFPEliminar() {
    let bolVal = true;
    
    for (let indice = 0; indice <= this.cxctrnpagoretService.cxcpagoretformapago.length; indice++) {
      
      if (indice < this.cxctrnpagoretService.cxcpagoretformapago.length) {
        const fp = this.cxctrnpagoretService.cxcpagoretformapago[indice];
        if (fp.TRNPAGO_TIPO === 'CH') {
          
          
          this.cxctrnpagoretService.erTrnbanConcilia1(fp.NUMERO, fp.BANCLI_CODIGO, fp.TRNPAGO_TIPO).subscribe(chconsolidado => {
            if (chconsolidado !== null) {
              if (chconsolidado[0].TRNBAN_CONCILIA === 1) {
                this.message.add({
                  key: 'cxctrnpagoretKey',
                  severity: 'error',
                  summary: 'Error',
                  detail: 'No se puede eliminar el pago, el cheque ' + fp.NUMERO + ' esta consolidado.'
                  
                });
                bolVal = false;
              } else {
                
                // verificar que el cheque cobrado no haya sido depositado
                
                this.cxctrnpagoretService.erTrncajdepositonro1(fp.NUMERO, fp.BANCLI_CODIGO, fp.TRNPAGO_TIPO, this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NROCOMPROBANTE).subscribe(chdepositado => {
                  if (chdepositado !== null) {
                    this.message.add({
                      key: 'cxctrnpagoretKey',
                      severity: 'error',
                      summary: 'Error',
                      detail: 'No se puede eliminar el pago, el cheque ' + fp.NUMERO + ' esta está depositado. \n Nro. Depósito:'
                    });
                    bolVal = false;
                  }
                }, error1 => {
                  this.message.add({
                    key: 'cxctrnpagoretKey',
                    severity: 'error',
                    summary: 'Error',
                    detail: 'No se puede verificar si el cheque está depositado.'
                  });
                  bolVal = false;
                });
                
              }
            }
          }, error1 => {
            this.message.add({
              key: 'cxctrnpagoretKey',
              severity: 'error',
              summary: 'Error',
              detail: 'No se puede verificar si el cheque está conciliado.'
            });
            bolVal = false;
          });
        }
        // verificar que el efectivo no haya sido depositado
        if (fp.TRNPAGO_TIPO === 'EF') {
          
          this.cxctrnpagoretService.erTrncajdepositonro2(fp.NUMERO, fp.TRNPAGO_TIPO, fp.CAJ_CODIGO).subscribe(efdepositado => {
            if (efdepositado !== null) {
              this.message.add({
                key: 'cxctrnpagoretKey',
                severity: 'error',
                summary: 'Error',
                detail: 'No se puede eliminar el cobro, el efectivo ' + fp.NUMERO + ' esta está depositado. \n Nro. Depósito:'
              });
              bolVal = false;
            }
          }, error1 => {
            this.message.add({
              key: 'cxctrnpagoretKey',
              severity: 'error',
              summary: 'Error',
              detail: 'No se puede verificar si el efectivo está depositado.'
            });
          });
        }
      }
      
      if (this.cxctrnpagoretService.cxcpagoretformapago.length - 1 === Number(indice)) {
        if (bolVal === true) {
          this.documentoCierre();
        }
      }
    }
    
  }
  
  
  documentoCierre() {
    this.cxctrnpagoretService.erTrncobrocuadre1(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NROCOMPROBANTE).subscribe(doccierre => {
      
      if (doccierre !== null) {
        
        if (doccierre[0].TRNCOBRO_CUADRE === '1') {
          this.message.add({
            key: 'cxctrnpagoretKey',
            severity: 'error',
            summary: 'Información',
            detail: 'No puede  eliminar el pago, el documento está en el cierre de caja.'
          });
        } else {
          this.confirmarDocumento(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC);
        }
      } else if (doccierre === null) {
        this.confirmarDocumento(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC);
      }
      
      
    }, error1 => {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede verificar si el documento ya está en el cierre de caja.'
      });
    });
  }
  
  
  confirmarDocumento(trncobronrodoc) {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el documento',
      header: 'Eliminar Documento',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarcxctrnpagoret',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarDocumento(trncobronrodoc);
      },
      reject: () => {
      }
    });
  }
  
  
  eliminarDocumento(trncobronrodoc) {
    const comprobante = this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NROCOMPROBANTE;
    this.cxctrnpagoretService.eliminarDocumento(trncobronrodoc).subscribe(res => {
      
      this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', trncobronrodoc + '/' + comprobante + '/CXC', 'E',
        '', '01', '', '', '', '').subscribe(() => {
      });
      
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'success',
        summary: 'Eliminar',
        detail: 'Se ha eliminado el documento exitosamente'
      });
      this.limpiarDatos();
      this.btnGuardar = false;
      this.btnBorrar = true;
      this.btnRegresar = false;
      this.btnUltimo = false;
      this.btnAnterior = true;
      this.btnSiguiente = true;
      this.btnPrimero = false;
      this.nuevoreg = false;
      
    }, error1 => {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error',
        detail: 'Proceso eliminar Documento no terminó correctamente'
      });
    });
    
    
  }
  
  
  cancelarRegistro() {
    if (this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC !== '') {
      this.btnPrimero = false;
      this.btnAnterior = false;
      this.btnSiguiente = false;
      this.btnUltimo = false;
      
      this.btnRegresar = true;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnNuevo = false;
      
      this.cargarDatos(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC);
      
    } else {
      this.limpiarDatos();
      this.btnGuardar = true;
      this.btnBorrar = true;
      this.btnRegresar = true;
      this.btnNuevo = false;
      
      this.btnPrimero = false;
      this.btnAnterior = true;
      this.btnSiguiente = true;
      this.btnUltimo = false;
      
      this.nuevoreg = false;
    }
  }
  
  cargarDatos(trncobronrodoc) {
    
    this.btnAsiento = false;
    this.btnVerAsiento = false;
    
    let intAnulado = 0;
    
    this.nuevoreg = false;
    this.docselected = false;
    
    this.cxctrnpagoretService.consultarDocumentosACobrar('T',
      'COM_CODIGO = \'01\'', 'TRNCOBRO_NRODOC =\'' + trncobronrodoc + '\' AND C.COM_CODIGO = \'01\'').subscribe((datos: any[]) => {
      
      this.cxctrnpagoretService.cxcpagoret = datos[0];
      
      if (datos[0].TRNCOBRO_CONCEPTO === null) {
        this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_CONCEPTO = '';
      }
      if (datos[0].VEN_CODIGO === null) {
        this.cxctrnpagoretService.cxcpagoret.VEN_CODIGO = '';
      }
      if (datos[0].ASI_NRO === null) {
        this.cxctrnpagoretService.cxcpagoret.ASI_NRO = '';
      }
      if (datos[0].TRNCOBRO_IMPRESO === null) {
        this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_IMPRESO = '';
      } else if (datos[0].TRNCOBRO_IMPRESO === 'S') {
        this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_IMPRESO = 'IMPRESO';
      }
      
      this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_FECHAVENCE = this.datePipe.transform(datos[0].TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
      
      this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_FECHATRN = this.datePipe.transform(datos[0].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
      
      this.cxctrnpagoretService.cxcpagoret.TOTALDOC = Number(0).toFixed(2);
      
      this.cxctrnpagoretService.cxcpagoret.TOTALRET = Number(0).toFixed(2);
      
      this.cxctrnpagoretService.cxcpagoret.TOTALFORMAPAGO = Number(0).toFixed(2);
      
      this.cxctrnpagoretService.cxcpagoret.TOTALACOBRAR = Number(0).toFixed(2);
      
      
      intAnulado = datos[0].TRNCOBRO_ANULADO;
      if (intAnulado === 1) {
        this.estado = 'ANULADO';
      }
      this.cargarCliVen();
      this.cargarDoc();
      this.cargarTotales();
      this.cargarTabla();
      
      
    }, error1 => {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede consultar la información'
      });
    });
    
  }
  
  cargarTotales() {
    
    // Retenciones
    this.cxctrnpagoretService.obtenerRetenciones(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC).subscribe((res) => {
      if (res.length !== 0) {
        this.cxctrnpagoretService.cxcpagoretretenciones = [];
        res.map((ret) => {
          let objRet: Cxcpagoretretenciones;
          objRet = {};
          
          objRet.CEN_CODIGO = ret.CEN_CODIGO;
          objRet.CLI_CODIGO = ret.CLI_CODIGO;
          objRet.COM_CODIGO = ret.COM_CODIGO;
          objRet.CON_CODIGO = ret.CON_CODIGO;
          objRet.TRNRETENCION_REFERENCIA = ret.TRNRETENCION_REFERENCIA;
          objRet.RETENCION_CODIGO = ret.RETENCION_CODIGO;
          objRet.TRNCOBRO_NROCOMPROBANTE = ret.TRNCOBRO_NROCOMPROBANTE;
          objRet.TRNCOBRO_NRODOC = ret.TRNCOBRO_NRODOC;
          objRet.TRNRETENCION_AUTORIZACION = ret.TRNRETENCION_AUTORIZACION;
          objRet.TRNRETENCION_BASE = Number(ret.TRNRETENCION_BASE).toFixed(2);
          objRet.TRNRETENCION_DESCRIPCION = ret.TRNRETENCION_DESCRIPCION;
          objRet.TRNRETENCION_FLAG = ret.TRNRETENCION_FLAG;
          objRet.TRNRETENCION_ORIGEN = ret.TRNRETENCION_ORIGEN;
          objRet.TRNRETENCION_PORCENTAJE = Number(ret.TRNRETENCION_PORCENTAJE).toFixed(2);
          objRet.TRNRETENCION_SERIE = ret.TRNRETENCION_SERIE;
          objRet.TRNRETENCION_TOTALRETENIDO = Number(ret.TRNRETENCION_TOTALRETENIDO).toFixed(2);
          objRet.TRNRETENCION_NRO = ret.TRNRETENCION_NRO;
          //CDPJ
          objRet.TRNRETENCION_TIPORET=ret.TRNRETENCION_TIPORET === null || ret.TRNRETENCION_TIPORET === undefined?'':ret.TRNRETENCION_TIPORET;//CDPJ
          if( objRet.TRNRETENCION_TIPORET === 'E'){
            this.chkretelectronica = true
          }else{
            this.chkretelectronica = false
          }
          //CDPJ
          this.cxctrnpagoretService.cxcpagoretretenciones.push(objRet);
        });
        // calcular
        this.cxctrnpagoretService.calcularTotalRet();
      } else {
        this.cxctrnpagoretService.cxcpagoretretenciones = [];
        this.cxctrnpagoretService.calcularTotalRet();
      }
    });
    
    
    // FP
    this.cxctrnpagoretService.consultarCajaFP(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC).subscribe((res) => {
      
      this.cxctrnpagoretService.consultarBancosFP(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC).subscribe((res1) => {
        
        if (res.length !== 0 && res1.length === 0) {
          
          this.cxctrnpagoretService.cxcpagoretformapago = [];
          res.map((caja) => {
            let objFP: Cxcpagoretformapago;
            objFP = {};
            objFP.TRNPAGO_TIPO = caja.TRNCAJ_TIPO;
            objFP.CAJ_CODIGO = caja.CAJ_CODIGO;
            objFP.BANCLI_CODIGO = '';
            objFP.BAN_NOMBRE = '';
            objFP.NUMERO = caja.TRNCAJ_NUMERO;
            objFP.FECHA = this.datePipe.transform(caja.TRNCAJ_FECHA, 'dd/MM/yyyy');
            objFP.CONCEPTO = caja.TRNCAJ_DESCRIPCION;
            if (caja.TRNCAJ_IMPORTE < 0) {
              objFP.IMPORTE = Number(caja.TRNCAJ_IMPORTE * -1).toFixed(2);
            } else {
              objFP.IMPORTE = Number(caja.TRNCAJ_IMPORTE).toFixed(2);
            }
            objFP.CON_CODIGO = '';
            this.cxctrnpagoretService.erConcodigo1(caja.CAJ_CODIGO).subscribe((bannombre) => {
              objFP.CON_CODIGO = bannombre[0].CON_CODIGO;
            });
            // encontrar registro CAJ_CODIGO
            this.cxctrnpagoretService.cxcpagoretformapago.push(objFP);
          });
          this.cxctrnpagoretService.calcularTotalFormaPagoDev();
          
        } else if (res1.length !== 0 && res.length === 0) {
          
          this.cxctrnpagoretService.cxcpagoretformapago = [];
          res1.map((ban) => {
            let objFP: Cxcpagoretformapago;
            objFP = {};
            objFP.TRNPAGO_TIPO = ban.TRNBAN_TIPO;
            objFP.CAJ_CODIGO = '';
            objFP.BANCLI_CODIGO = ban.BAN_CODIGO;
            this.cxctrnpagoretService.erBanbanco1(ban.BAN_CODIGO).subscribe((bannombre) => {
              objFP.BAN_NOMBRE = bannombre[0].BAN_BANCO;
            });
            objFP.NUMERO = ban.TRNBAN_NUMERO;
            objFP.FECHA = this.datePipe.transform(ban.TRNBAN_FECHA, 'dd/MM/yyyy');
            objFP.CONCEPTO = ban.TRNBAN_CONCEPTO;
            
            if (ban.TRNBAN_IMPORTE < 0) {
              objFP.IMPORTE = Number(ban.TRNBAN_IMPORTE * -1).toFixed(2);
            } else {
              objFP.IMPORTE = Number(ban.TRNBAN_IMPORTE).toFixed(2);
            }
            
            this.cxctrnpagoretService.erConcodigo2(ban.BAN_CODIGO).subscribe((bannombre) => {
              objFP.CON_CODIGO = bannombre[0].CON_CODIGO;
            });
            
            this.cxctrnpagoretService.cxcpagoretformapago.push(objFP);
          });
          this.cxctrnpagoretService.calcularTotalFormaPagoDev();
          
        } else if (res1.length !== 0 && res.length !== 0) {
          
          this.cxctrnpagoretService.cxcpagoretformapago = [];
          
          res1.map((ban) => {
            let objFP: Cxcpagoretformapago;
            objFP = {};
            objFP.TRNPAGO_TIPO = ban.TRNBAN_TIPO;
            objFP.CAJ_CODIGO = '';
            objFP.BANCLI_CODIGO = ban.BAN_CODIGO;
            this.cxctrnpagoretService.erBanbanco1(ban.BAN_CODIGO).subscribe((bannombre) => {
              objFP.BAN_NOMBRE = bannombre[0].BAN_BANCO;
            });
            objFP.NUMERO = ban.TRNBAN_NUMERO;
            objFP.FECHA = this.datePipe.transform(ban.TRNBAN_FECHA, 'dd/MM/yyyy');
            objFP.CONCEPTO = ban.TRNBAN_CONCEPTO;
            
            if (ban.TRNBAN_IMPORTE < 0) {
              objFP.IMPORTE = Number(ban.TRNBAN_IMPORTE * -1).toFixed(2);
            } else {
              objFP.IMPORTE = Number(ban.TRNBAN_IMPORTE).toFixed(2);
            }
            
            this.cxctrnpagoretService.erConcodigo2(ban.BAN_CODIGO).subscribe((bannombre) => {
              objFP.CON_CODIGO = bannombre[0].CON_CODIGO;
            });
            
            this.cxctrnpagoretService.cxcpagoretformapago.push(objFP);
          });
          res.map((caja) => {
            let objFP: Cxcpagoretformapago;
            objFP = {};
            objFP.TRNPAGO_TIPO = caja.TRNCAJ_TIPO;
            objFP.CAJ_CODIGO = caja.CAJ_CODIGO;
            objFP.BANCLI_CODIGO = '';
            objFP.BAN_NOMBRE = '';
            objFP.NUMERO = caja.TRNCAJ_NUMERO;
            objFP.FECHA = this.datePipe.transform(caja.TRNCAJ_FECHA, 'dd/MM/yyyy');
            objFP.CONCEPTO = caja.TRNCAJ_DESCRIPCION;
            if (caja.TRNCAJ_IMPORTE < 0) {
              objFP.IMPORTE = Number(caja.TRNCAJ_IMPORTE * -1).toFixed(2);
            } else {
              objFP.IMPORTE = Number(caja.TRNCAJ_IMPORTE).toFixed(2);
            }
            objFP.CON_CODIGO = '';
            this.cxctrnpagoretService.erConcodigo1(caja.CAJ_CODIGO).subscribe((bannombre) => {
              objFP.CON_CODIGO = bannombre[0].CON_CODIGO;
            });
            // encontrar registro CAJ_CODIGO
            this.cxctrnpagoretService.cxcpagoretformapago.push(objFP);
          });
          this.cxctrnpagoretService.calcularTotalFormaPagoDev();
        } else {
          this.cxctrnpagoretService.cxcpagoretformapago = [];
        }
        
      });
    });
    
  }
  
  fechaactual(): string {
    
    const fecha = new Date();
    const strfecha = this.datePipe.transform(fecha, 'dd/MM/yyyy');
    
    return strfecha;
  }
  
  limpiarDatos() {
    this.cxctrnpagoretService.cxcpagoret = {
      CLI_CODIGO: '', VEN_CODIGO: '', TRNCOBRO_NRODOC: '', ASI_NRO: '', TRNCOBRO_NROCOMPROBANTE: '',
      TRNCOBRO_FECHATRN: this.fechaactual(), TRNCOBRO_CONCEPTO: '', TOTALDOC: Number(0).toFixed(2),
      TOTALRET: Number(0).toFixed(2), TOTALFORMAPAGO: Number(0).toFixed(2),
      TOTALACOBRAR: Number(0).toFixed(2)
    };
    this.estado = '';
    this.busCLINOMBRE = '';
    this.busVENNOMBRE = '';
    this.lblAplicaRegMicroempresas = '';
    this.numeroFactura=''//CDPJ
    this.cxctrnpagoretService.cxcpagoretdocumentos = [];
    this.cxctrnpagoretService.cxcpagoretretenciones = [];
    this.cxctrnpagoretService.cxcpagoretformapago = [];
    
    this.aggrid.refreshaggrid(this.cxctrnpagoretService.cxcpagoretdocumentos, this.defaultColDefDoc.objeto);
    this.aggrid.refreshaggrid(this.cxctrnpagoretService.cxcpagoretretenciones, this.defaultColDefRet.objeto);
    this.aggrid.refreshaggrid(this.cxctrnpagoretService.cxcpagoretformapago, this.defaultColDefFormaPago.objeto);
  }
  
  seleccionarRegistro(strTipo) {
    this.activeIndex = 0;
    this.btnAsiento = false;
    this.btnVerAsiento = false;
    
    let intAnulado = 0;
    
    this.nuevoreg = false;
    this.docselected = false;
    
    this.cxctrnpagoretService.seleccionarRegistro(strTipo, this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC).subscribe((datos: any[]) => {
      
      this.cxctrnpagoretService.cxcpagoret = datos[0];
      
      if (datos[0].TRNCOBRO_CONCEPTO === null) {
        this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_CONCEPTO = '';
      }
      if (datos[0].VEN_CODIGO === null) {
        this.cxctrnpagoretService.cxcpagoret.VEN_CODIGO = '';
      }
      if (datos[0].ASI_NRO === null) {
        this.cxctrnpagoretService.cxcpagoret.ASI_NRO = '';
      }
      if (datos[0].TRNCOBRO_IMPRESO === null) {
        this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_IMPRESO = '';
      } else if (datos[0].TRNCOBRO_IMPRESO === 'S') {
        this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_IMPRESO = 'IMPRESO';
      }
      
      this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_FECHAVENCE = this.datePipe.transform(datos[0].TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
      
      this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_FECHATRN = this.datePipe.transform(datos[0].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
      
      this.cxctrnpagoretService.cxcpagoret.TOTALDOC = Number(0).toFixed(2);
      
      this.cxctrnpagoretService.cxcpagoret.TOTALRET = Number(0).toFixed(2);
      
      this.cxctrnpagoretService.cxcpagoret.TOTALFORMAPAGO = Number(0).toFixed(2);
      
      this.cxctrnpagoretService.cxcpagoret.TOTALACOBRAR = Number(0).toFixed(2);
      
      
      intAnulado = datos[0].TRNCOBRO_ANULADO;
      if (intAnulado === 1) {
        this.estado = 'ANULADO';
      }
      this.cargarCliVen();
      this.cargarDoc();
      this.cargarTotales();
      this.cargarTabla();
      
      
    }, error1 => {
      const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error',
        detail: error
      });
    });
  }
  
  /*
  cargarTabla() {
    if (this.nuevoreg === false) {
      if (this.activeIndex === 0) {
        this.cargarDoc();
      } else if (this.activeIndex === 1) {
        this.cargarRet();
      } else if (this.activeIndex === 2) {
        this.cargarFPDev();
      }
    }
    
  }*/
  
  cargarTabla() {
    if (this.nuevoreg === false) {
      if (this.activeIndex === 0) {
        this.cargarDoc();
      } else if (this.activeIndex === 1) {
        this.aggrid.refreshaggrid(this.cxctrnpagoretService.cxcpagoretretenciones, this.defaultColDefRet.objeto);
        
      } else if (this.activeIndex === 2) {
        this.aggrid.refreshaggrid(this.cxctrnpagoretService.cxcpagoretformapago, this.defaultColDefFormaPago.objeto);
      }
    }
    
  }
  
  cargarCliVen() {
    if (this.cxctrnpagoretService.cxcpagoret.CLI_CODIGO !== '') {
      this.cxctrnpagoretService.erClinombre1(this.cxctrnpagoretService.cxcpagoret.CLI_CODIGO).subscribe((res: any) => {
        if (res !== null) {
          res.map((docs) => {
            this.busCLINOMBRE = docs.CLI_NOMBRE;
          });
        }
      });
      
      this.cxctrnpagoretService.erMaeCliente1(this.cxctrnpagoretService.cxcpagoret.CLI_CODIGO).subscribe((data: any) => {
        for (const resCli of data) {
          if (resCli.CLI_CONREGMIC === 'S'
            || resCli.CLI_AGENTERET === 'S') {
            if (resCli.CLI_CONREGMIC === 'S'
              && resCli.CLI_AGENTERET === 'S') {
              this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas y agente de retención';
            } else {
              if (resCli.CLI_CONREGMIC === 'S') {
                this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas';
              }
              if (resCli.CLI_AGENTERET === 'S') {
                this.lblAplicaRegMicroempresas = 'Cliente aplica agente de retención';
              }
            }
          } else {
            this.lblAplicaRegMicroempresas = '';
          }
        }
      });
    }
    if (this.cxctrnpagoretService.cxcpagoret.VEN_CODIGO !== '') {
      this.cxctrnpagoretService.erVennombre1(this.cxctrnpagoretService.cxcpagoret.VEN_CODIGO).subscribe((res: any) => {
        if (res !== null) {
          res.map((docs) => {
            this.busVENNOMBRE = docs.VEN_NOMBRE;
          });
        }
      });
    }
    if (this.cxctrnpagoretService.cxcpagoret.VEN_CODIGO === null) {
      this.busVENNOMBRE = '';
    }
    if (this.cxctrnpagoretService.cxcpagoret.VEN_CODIGO === '') {
      this.busVENNOMBRE = '';
    }
    
  }
  
  cargarDoc() {
    this.cxctrnpagoretService.obtenerDocumentos('DT', 'TRNCOBRO_NRODOC = \'' + this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC + '\'',
      'COM_CODIGO = \'01\'').subscribe((res) => {
      if (res !== null) {
        this.cxctrnpagoretService.cxcpagoretdocumentos = [];
        res.map((docs) => {
          let objDoc: Cxcpagoretdocumentos;
          objDoc = {};
          objDoc.TRNDOCUMENTO_NRODOC = docs.TRNDOCUMENTO_NRODOC;
          objDoc.TRNCOBRO_FECHATRN = this.datePipe.transform(docs.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
          objDoc.TRNCOBRO_FECHAVENCE = this.datePipe.transform(docs.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
          objDoc.TRNCOBRO_CONCEPTO = docs.TRNCOBRO_CONCEPTO;
          objDoc.SALDOFAC = Number(docs.SALDOFAC).toFixed(2);
          objDoc.TRNDOCUMENTO_IMPORTE = Number(docs.TRNDOCUMENTO_IMPORTE).toFixed(2);
          objDoc.VEN_CODIGO = docs.VEN_CODIGO;
          objDoc.TRNDOCUMENTO_REF = docs.TRNDOCUMENTO_REF;
          objDoc.SELECCIONADO = true;
          this.cxctrnpagoretService.cxcpagoretdocumentos.push(objDoc);
        });
        this.aggrid.refreshaggrid(this.cxctrnpagoretService.cxcpagoretdocumentos, this.defaultColDefDoc.objeto);
        // calcularc
        this.cxctrnpagoretService.calcularTotalDocumentos();
        if (this.docselected === false) {
          if (this.cxctrnpagoretService.cxcpagoretdocumentos.length !== 0) {
            this.numeroFactura = this.cxctrnpagoretService.cxcpagoretdocumentos[0].TRNDOCUMENTO_NRODOC;
          } else if (this.cxctrnpagoretService.cxcpagoretdocumentos.length === 0) {
            this.numeroFactura = '';
          }
          this.docselected = true;
        }
        
      } else {
        this.cxctrnpagoretService.cxcpagoretdocumentos = [];
        this.aggrid.refreshaggrid(this.cxctrnpagoretService.cxcpagoretdocumentos, this.defaultColDefDoc.objeto);
      }
      
    });
  }
  
  cargarRet() {
    this.cxctrnpagoretService.obtenerRetenciones(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC).subscribe((res) => {
      if (res.length !== 0) {
        this.cxctrnpagoretService.cxcpagoretretenciones = [];
        res.map((ret) => {
          let objRet: Cxcpagoretretenciones;
          objRet = {};
          
          objRet.CEN_CODIGO = ret.CEN_CODIGO;
          objRet.CLI_CODIGO = ret.CLI_CODIGO;
          objRet.COM_CODIGO = ret.COM_CODIGO;
          objRet.CON_CODIGO = ret.CON_CODIGO;
          objRet.TRNRETENCION_REFERENCIA = ret.TRNRETENCION_REFERENCIA;
          objRet.RETENCION_CODIGO = ret.RETENCION_CODIGO;
          objRet.TRNCOBRO_NROCOMPROBANTE = ret.TRNCOBRO_NROCOMPROBANTE;
          objRet.TRNCOBRO_NRODOC = ret.TRNCOBRO_NRODOC;
          objRet.TRNRETENCION_AUTORIZACION = ret.TRNRETENCION_AUTORIZACION;
          objRet.TRNRETENCION_BASE = Number(ret.TRNRETENCION_BASE).toFixed(2);
          objRet.TRNRETENCION_DESCRIPCION = ret.TRNRETENCION_DESCRIPCION;
          objRet.TRNRETENCION_FLAG = ret.TRNRETENCION_FLAG;
          objRet.TRNRETENCION_ORIGEN = ret.TRNRETENCION_ORIGEN;
          objRet.TRNRETENCION_PORCENTAJE = Number(ret.TRNRETENCION_PORCENTAJE).toFixed(2);
          objRet.TRNRETENCION_SERIE = ret.TRNRETENCION_SERIE;
          objRet.TRNRETENCION_TOTALRETENIDO = Number(ret.TRNRETENCION_TOTALRETENIDO).toFixed(2);
          objRet.TRNRETENCION_NRO = ret.TRNRETENCION_NRO;
          //CDPJ
          objRet.TRNRETENCION_TIPORET=ret.TRNRETENCION_TIPORET === null || ret.TRNRETENCION_TIPORET === undefined?'':ret.TRNRETENCION_TIPORET;//CDPJ
          if( objRet.TRNRETENCION_TIPORET === 'E'){
            this.chkretelectronica = true
          }else{
            this.chkretelectronica = false
          }
          //CDPJ
          //CDPJ
          this.cxctrnpagoretService.cxcpagoretretenciones.push(objRet);
        });
        this.aggrid.refreshaggrid(this.cxctrnpagoretService.cxcpagoretretenciones, this.defaultColDefRet.objeto);
        // calcular
        this.cxctrnpagoretService.calcularTotalRet();
      } else {
        this.cxctrnpagoretService.cxcpagoretretenciones = [];
        this.aggrid.refreshaggrid(this.cxctrnpagoretService.cxcpagoretretenciones, this.defaultColDefRet.objeto);
      }
      
      if (this.docselected === false) {
        if (this.cxctrnpagoretService.cxcpagoretdocumentos.length !== 0) {
          this.numeroFactura = this.cxctrnpagoretService.cxcpagoretdocumentos[0].TRNDOCUMENTO_NRODOC;
        } else if (this.cxctrnpagoretService.cxcpagoretdocumentos.length === 0) {
          this.numeroFactura = '';
        }
        this.docselected = true;
      }
      
    });
  }
  
  cargarFPDev() {
    
    this.cxctrnpagoretService.consultarCajaFP(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC).subscribe((res) => {
      
      this.cxctrnpagoretService.consultarBancosFP(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC).subscribe((res1) => {
        
        if (res.length !== 0 && res1.length === 0) {
          this.cxctrnpagoretService.cxcpagoretformapago = [];
          res.map((caja) => {
            let objFP: Cxcpagoretformapago;
            objFP = {};
            objFP.TRNPAGO_TIPO = caja.TRNCAJ_TIPO;
            objFP.CAJ_CODIGO = caja.CAJ_CODIGO;
            objFP.BANCLI_CODIGO = '';
            objFP.BAN_NOMBRE = '';
            objFP.NUMERO = caja.TRNCAJ_NUMERO;
            objFP.FECHA = this.datePipe.transform(caja.TRNCAJ_FECHA, 'dd/MM/yyyy');
            objFP.CONCEPTO = caja.TRNCAJ_DESCRIPCION;
            if (caja.TRNCAJ_IMPORTE < 0) {
              objFP.IMPORTE = Number(caja.TRNCAJ_IMPORTE * -1).toFixed(2);
            } else {
              objFP.IMPORTE = Number(caja.TRNCAJ_IMPORTE).toFixed(2);
            }
            objFP.CON_CODIGO = '';
            this.cxctrnpagoretService.erConcodigo1(caja.CAJ_CODIGO).subscribe((bannombre) => {
              objFP.CON_CODIGO = bannombre[0].CON_CODIGO;
            });
            // encontrar registro CAJ_CODIGO
            this.cxctrnpagoretService.cxcpagoretformapago.push(objFP);
          });
          
          this.aggrid.refreshaggrid(this.cxctrnpagoretService.cxcpagoretformapago, this.defaultColDefFormaPago.objeto);
          this.cxctrnpagoretService.calcularTotalFormaPagoDev();
          
        } else if (res1.length !== 0 && res.length === 0) {
          this.cxctrnpagoretService.cxcpagoretformapago = [];
          res1.map((ban) => {
            let objFP: Cxcpagoretformapago;
            objFP = {};
            objFP.TRNPAGO_TIPO = ban.TRNBAN_TIPO;
            objFP.CAJ_CODIGO = '';
            objFP.BANCLI_CODIGO = ban.BAN_CODIGO;
            this.cxctrnpagoretService.erBanbanco1(ban.BAN_CODIGO).subscribe((bannombre) => {
              objFP.BAN_NOMBRE = bannombre[0].BAN_BANCO;
            });
            objFP.NUMERO = ban.TRNBAN_NUMERO;
            objFP.FECHA = this.datePipe.transform(ban.TRNBAN_FECHA, 'dd/MM/yyyy');
            objFP.CONCEPTO = ban.TRNBAN_CONCEPTO;
            
            if (ban.TRNBAN_IMPORTE < 0) {
              objFP.IMPORTE = Number(ban.TRNBAN_IMPORTE * -1).toFixed(2);
            } else {
              objFP.IMPORTE = Number(ban.TRNBAN_IMPORTE).toFixed(2);
            }
            
            this.cxctrnpagoretService.erConcodigo2(ban.BAN_CODIGO).subscribe((bannombre) => {
              objFP.CON_CODIGO = bannombre[0].CON_CODIGO;
            });
            
            this.cxctrnpagoretService.cxcpagoretformapago.push(objFP);
          });
          
          this.aggrid.refreshaggrid(this.cxctrnpagoretService.cxcpagoretformapago, this.defaultColDefFormaPago.objeto);
          this.cxctrnpagoretService.calcularTotalFormaPagoDev();
          
        } else if (res1.length !== 0 && res.length !== 0) {
          this.cxctrnpagoretService.cxcpagoretformapago = [];
          
          res1.map((ban) => {
            let objFP: Cxcpagoretformapago;
            objFP = {};
            objFP.TRNPAGO_TIPO = ban.TRNBAN_TIPO;
            objFP.CAJ_CODIGO = '';
            objFP.BANCLI_CODIGO = ban.BAN_CODIGO;
            this.cxctrnpagoretService.erBanbanco1(ban.BAN_CODIGO).subscribe((bannombre) => {
              objFP.BAN_NOMBRE = bannombre[0].BAN_BANCO;
            });
            objFP.NUMERO = ban.TRNBAN_NUMERO;
            objFP.FECHA = this.datePipe.transform(ban.TRNBAN_FECHA, 'dd/MM/yyyy');
            objFP.CONCEPTO = ban.TRNBAN_CONCEPTO;
            
            if (ban.TRNBAN_IMPORTE < 0) {
              objFP.IMPORTE = Number(ban.TRNBAN_IMPORTE * -1).toFixed(2);
            } else {
              objFP.IMPORTE = Number(ban.TRNBAN_IMPORTE).toFixed(2);
            }
            
            this.cxctrnpagoretService.erConcodigo2(ban.BAN_CODIGO).subscribe((bannombre) => {
              objFP.CON_CODIGO = bannombre[0].CON_CODIGO;
            });
            
            this.cxctrnpagoretService.cxcpagoretformapago.push(objFP);
          });
          res.map((caja) => {
            let objFP: Cxcpagoretformapago;
            objFP = {};
            objFP.TRNPAGO_TIPO = caja.TRNCAJ_TIPO;
            objFP.CAJ_CODIGO = caja.CAJ_CODIGO;
            objFP.BANCLI_CODIGO = '';
            objFP.BAN_NOMBRE = '';
            objFP.NUMERO = caja.TRNCAJ_NUMERO;
            objFP.FECHA = this.datePipe.transform(caja.TRNCAJ_FECHA, 'dd/MM/yyyy');
            objFP.CONCEPTO = caja.TRNCAJ_DESCRIPCION;
            if (caja.TRNCAJ_IMPORTE < 0) {
              objFP.IMPORTE = Number(caja.TRNCAJ_IMPORTE * -1).toFixed(2);
            } else {
              objFP.IMPORTE = Number(caja.TRNCAJ_IMPORTE).toFixed(2);
            }
            objFP.CON_CODIGO = '';
            this.cxctrnpagoretService.erConcodigo1(caja.CAJ_CODIGO).subscribe((bannombre) => {
              objFP.CON_CODIGO = bannombre[0].CON_CODIGO;
            });
            // encontrar registro CAJ_CODIGO
            this.cxctrnpagoretService.cxcpagoretformapago.push(objFP);
          });
          this.aggrid.refreshaggrid(this.cxctrnpagoretService.cxcpagoretformapago, this.defaultColDefFormaPago.objeto);
          this.cxctrnpagoretService.calcularTotalFormaPagoDev();
        } else {
          this.cxctrnpagoretService.cxcpagoretformapago = [];
          this.aggrid.refreshaggrid(this.cxctrnpagoretService.cxcpagoretformapago, this.defaultColDefFormaPago.objeto);
        }
        
      });
    });
  }
  
  
  calcularTotales() {
    this.cxctrnpagoretService.calcularTotalDocumentos(); // .subscribe((res) => {})
    this.cxctrnpagoretService.calcularTotalRet();
    this.cxctrnpagoretService.calcularTotalFormaPagoDev();
    this.cxctrnpagoretService.calcularTotalACobrar();
    
  }
  
  
  seleccionRetenciones(params) {
    if (params === 'nuevo') {
      
      this.btnNuevoGridret = false;
      this.btnGuardarGridret = false;
      this.btnBorrarGridret = false;
      this.btnCancelarGridret = false;
      
      this.nuevaRetencion();
      
    }
    if (params === 'eliminar') {
      
      this.btnNuevoGridret = false;
      this.btnGuardarGridret = false;
      this.btnBorrarGridret = false;
      this.btnCancelarGridret = true;
      
      const indexRet = this.cxctrnpagoretService.cxcpagoretretenciones.indexOf(this.retencionSeleccionada);
      this.cxctrnpagoretService.cxcpagoretretenciones.splice(indexRet, 1);
      this.aggrid.refreshaggrid(this.cxctrnpagoretService.cxcpagoretretenciones, this.defaultColDefRet.objeto);
      this.cxctrnpagoretService.calcularTotalRet();
    }
    if (params === 'cancelar') {
      
      this.btnNuevoGridret = false;
      this.btnGuardarGridret = false;
      this.btnBorrarGridret = false;
      this.btnCancelarGridret = true;
      
      this.cargarRet();
      
    }
    if (params.object !== undefined) {
      this.retencionSeleccionada = params.object;
    }
  }
  
  // RETENCIONES
  
  nuevaRetencion() {
    if (this.numeroFactura !== '') {
      const linea = this.cxctrnpagoretService.cxcpagoretretenciones.length;
      const nuevodetalle = {
        CLI_CODIGO: this.cxctrnpagoretService.cxcpagoret.CLI_CODIGO,
        COM_CODIGO: '01',
        RETENCION_CODIGO: '',
        TRNRETENCION_AUTORIZACION: '',
        TRNRETENCION_BASE: Number(0).toFixed(2),
        TRNRETENCION_DESCRIPCION: '',
        TRNRETENCION_PORCENTAJE: Number(0).toFixed(2),
        TRNRETENCION_SERIE: '',
        TRNRETENCION_TOTALRETENIDO: Number(0).toFixed(2),
        CEN_CODIGO: '',
        ENCFAC_NUMERO: this.numeroFactura,
        TRNCOBRO_NRODOC: '',
        TRNRETENCION_FLAG: 1,
        TRNRETENCION_NRO: '',
        TRNRETENCION_ORIGEN: 'CXC',
        LINEA: String(linea + 1),
        TRNRETENCION_TIPORET:''//CDPJ
      };
      
      this.columnDefsRet.map(edit => {
        edit.editable = true;
      });
      
      this.aggrid.refreshColumnDefs();
      
      this.cxctrnpagoretService.cxcpagoretretenciones.push(nuevodetalle);
      this.aggrid.refreshaggrid(this.cxctrnpagoretService.cxcpagoretretenciones, this.defaultColDefRet.objeto);
      
      this.cxctrnpagoretService.calcularTotalFormaPagoDev();
      
      
      /*
     if (this.permisoActPrec.PERINSERCION === 1) {
       this.btnNuevoGridret = true;
       this.btnGuardarGridret = false;
       this.btnBorrarGridret = false;
       this.btnCancelarGridret = false;
       this.btnGuardar = false;
       
       this.formulaSeleccionada = {
         FORPRE_CODIGO: 0,
         FORPRE_NOMBRE: '',
         FORPRE_NOMSQL: '',
       };
       
       this.actpreService.formulas.push(this.formulaSeleccionada);
       this.aggrid.refreshaggrid(this.actpreService.formulas, this.defaultColDefFormulas.objeto);
       
     } else if (this.permisoActPrec.PERINSERCION === 0) {
       this.message.add({
         key: 'invactprecKey',
         severity: 'error',
         summary: 'Inserción de Fórmula',
         detail: 'El usuario no tiene permisos de inserción, acción denegada'
       });
     }*/
     
     
    } else {
      
      this.btnNuevoGridret = false;
      
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Inserción de retención',
        detail: 'Primero escoja un documento'
      });
    }
  }
  
  seleccionFormasPago(params) {
    if (params === 'nuevo') {
      
      this.btnNuevoGridfp = false;
      this.btnGuardarGridfp = false;
      this.btnBorrarGridfp = false;
      this.btnCancelarGridfp = false;
      
      this.nuevaFormaPago();
      
      
    }
    if (params === 'eliminar') {
      
      this.btnNuevoGridfp = false;
      this.btnGuardarGridfp = false;
      this.btnBorrarGridfp = false;
      this.btnCancelarGridfp = true;
      
      const indexFP = this.cxctrnpagoretService.cxcpagoretformapago.indexOf(this.fpSeleccionada);
      this.cxctrnpagoretService.cxcpagoretformapago.splice(indexFP, 1);
      this.aggrid.refreshaggrid(this.cxctrnpagoretService.cxcpagoretformapago, this.defaultColDefFormaPago.objeto);
      this.cxctrnpagoretService.calcularTotalFormaPagoDev();
    }
    if (params === 'cancelar') {
      
      this.btnNuevoGridfp = false;
      this.btnGuardarGridfp = false;
      this.btnBorrarGridfp = false;
      this.btnCancelarGridfp = true;
      
      this.cargarFPDev();
      
    } else if (params.object !== undefined) {
      
      this.fpSeleccionada = params.object;
    }
  }
  
  nuevaFormaPago() {
    
    const fecha = new Date();
    
    const linea = this.cxctrnpagoretService.cxcpagoretformapago.length;
    const nuevodetalle = {
      
      TRNPAGO_TIPO: '',
      CAJ_CODIGO: '',
      BANCLI_CODIGO: '',
      BAN_NOMBRE: '',
      NUMERO: '',
      FECHA: this.datePipe.transform(fecha, 'dd/MM/yyyy'),
      CONCEPTO: this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_CONCEPTO,
      IMPORTE: Number(this.calcularImpFP(this.cxctrnpagoretService.cxcpagoret.TOTALDOC)).toFixed(2),
      CON_CODIGO: '',
      DOC_TIPO: '',
      LINEA: String(linea + 1)
    };
    
    
    this.cxctrnpagoretService.cxcpagoretformapago.push(nuevodetalle);
    this.aggrid.refreshaggrid(this.cxctrnpagoretService.cxcpagoretformapago, this.defaultColDefFormaPago.objeto);
    this.cxctrnpagoretService.calcularTotalFormaPagoDev();
    
  }
  
  calcularImpFP(totalDoc): number {
    let totalFP = 0;
    let newimporteFP = 0;
    if (this.cxctrnpagoretService.cxcpagoretformapago.length !== 0) {
      this.cxctrnpagoretService.cxcpagoretformapago.map(imp => {
        totalFP += Number(imp.IMPORTE);
      });
      if (totalFP < Number(totalDoc)) {
        newimporteFP = Number(totalDoc) - totalFP;
      }
    } else {
      newimporteFP = Number(totalDoc);
    }
    return newimporteFP;
  }
  
  
  manejarSeleccion(opcion) {
    if (this.opcion === 'CLIENTE') {
      
      if (this.nuevoreg === true) {
        this.cxctrnpagoretService.cxcpagoret.CLI_CODIGO = opcion.CLI_CODIGO;
        this.busCLINOMBRE = opcion.CLI_NOMBRE;
        this.displayDialogBusquedaFast = false;
        this.cargarDocPorCobrar();
        
      } else if (this.nuevoreg === false) {
        this.cxctrnpagoretService.cxcpagoret.CLI_CODIGO = opcion.CLI_CODIGO;
        this.busCLINOMBRE = opcion.CLI_NOMBRE;
        this.displayDialogBusquedaFast = false;
      }
    }
    if (this.opcion === 'VENDEDOR') {
      
      this.cxctrnpagoretService.cxcpagoret.VEN_CODIGO = opcion.Código;
      this.busVENNOMBRE = opcion.Nombre;
      this.displayDialogBusquedaFast = false;
    }
    if (this.opcion === 'DOCUMENTO') {
      
      this.encontrarNumDoc(opcion.TRNCOBRO_NROCOMPROBANTE);
      this.displayDialogBusquedaFast = false;
      
    }
  }
  
  /*abrirCliente() {
    this.opcion = 'CLIENTE';
    this.types = [
      {label: 'CÓDIGO', value: 'CLI_CODIGO'},
      {label: 'NOMBRE', value: 'CLI_NOMBRE'},
      {label: 'RUCICE', value: 'CLI_RUCIDE'},
      {label: 'ZONA', value: 'VEN_FNC_OBTENER_ZONACLI(NVL(CLI_ZONA,\'NN\'))'},
      {label: 'VENDEDOR', value: 'VEN_FNC_NOMBRE_VENDEDOR(VEN_CODIGO)'},
      {label: 'GRUPO', value: 'VEN_FNC_OBTENER_GRU_NOMBRE_CLI(CLI_CODIGO)'},
      {label: 'CONTACTO', value: 'CLI_CONTACTO'},
      {label: 'PLACA', value: 'CLI_PLACA'},
      {label: 'NOMBREC', value: 'CLI_NOMBREC'}
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_MAECLIENTE';
    this.where = '';
    this.cxctrnpagoretService.buscarCliente().subscribe((res) => {
      this.arregloBus = res;
      this.displayDialogBusquedaFast = true;
    });
  }*/
  
  abrirCliente(parametro) {
    
    if (parametro === null) {
      parametro = '';
    }
    
    this.opcion = 'CLIENTE';
    this.types = [
      {label: 'CÓDIGO', value: 'CLI_CODIGO'},
      {label: 'NOMBRE', value: 'CLI_NOMBRE'},
      {label: 'RUCICE', value: 'CLI_RUCIDE'},
      {label: 'ZONA', value: 'VEN_FNC_OBTENER_ZONACLI(NVL(CLI_ZONA,\'NN\'))'},
      {label: 'VENDEDOR', value: 'VEN_FNC_NOMBRE_VENDEDOR(VEN_CODIGO)'},
      {label: 'GRUPO', value: 'VEN_FNC_OBTENER_GRU_NOMBRE_CLI(CLI_CODIGO)'},
      {label: 'CONTACTO', value: 'CLI_CONTACTO'},
      {label: 'PLACA', value: 'CLI_PLACA'},
      {label: 'NOMBREC', value: 'CLI_NOMBREC'}
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_MAECLIENTE';
    this.where = 'rownum<=100';
    
    this.cxctrnpagoretService.erClinombre1(parametro).subscribe((res: any) => {
      if (res !== null) {
        res.map((docs) => {
          this.busCLINOMBRE = docs.CLI_NOMBRE;
          if (this.nuevoreg === true) {
            this.cargarDocPorCobrar();
          }
        });
      } else {
        this.busquedaSer.busquedaVenMaeCliente2().subscribe((res: any[]) => {
          this.arregloBus = res;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
    
    
  }
  
  
  abrirVendedor(parametro) {
    
    if (parametro === null) {
      parametro = '';
    }
    
    this.opcion = 'VENDEDOR';
    this.types = [
      {label: 'CÓDIGO', value: 'Código'},
      {label: 'NOMBRE', value: 'Nombre'}
    ];
    this.busquedacampos = ['', ''];
    this.tabla = 'VEN_MAEVENDEDOR';
    this.where = '';
    
    
    this.cxctrnpagoretService.erVennombre1(parametro).subscribe((res: any) => {
      if (res !== null) {
        res.map((docs) => {
          this.busVENNOMBRE = docs.VEN_NOMBRE;
        });
      } else {
        this.cxctrnpagoretService.buscarVendedor().subscribe((res) => {
          this.arregloBus = res;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
    
    
  }
  
  /*
  buscarDoc() {
    this.opcion = 'DOCUMENTO';
    this.types = [
      {label: 'Número', value: 'Número'},
      {label: 'TIPO_DOC', value: 'TIPO_DOC'},
      {label: 'FECHA_TRANS', value: 'FECHA_TRANS'},
      {label: 'COD_CLIENTE', value: 'COD_CLIENTE'},
      {label: 'CLIENTE', value: 'CLIENTE'},
      {label: 'CONCEPTO', value: 'CONCEPTO'},
      {label: 'IMPORTE', value: 'IMPORTE'},
      {label: 'FECHA_VENC', value: 'FECHA_VENC'},
      {label: 'REF_EXTERNA', value: 'REF_EXTERNA'},
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'CXC_TRNCOBRO';
    
    this.cxctrnpagoretService.consultarDocumentosACobrar('B', '',
      'T.COM_CODIGO = \'01\' AND TRNCOBRO_REFERENCIAEXTERNA = \'PAGO RET.\'').subscribe(datos => {
      console.log(datos);
      this.arregloBus = datos;
      this.displayDialogBusquedaFast = true;
    });
    
  }*/
  
  buscarDoc() {
    this.opcion = 'DOCUMENTO';
    this.types = [
      {label: 'Número', value: 'TRNCOBRO_NROCOMPROBANTE'},
      {label: 'TIPO_DOC', value: 'TRNCOBRO_TIPODOC'},
      {label: 'FECHA_TRANS', value: 'TRNCOBRO_FECHATRN'},
      {label: 'COD_CLIENTE', value: 'CLI_CODIGO'},
      {label: 'CLIENTE', value: 'CLI_NOMBRE'},
      {label: 'CONCEPTO', value: 'TRNCOBRO_CONCEPTO'},
      {label: 'IMPORTE', value: 'TRNCOBRO_IMPORTE'},
      {label: 'FECHA_VENC', value: 'TRNCOBRO_FECHAVENCE'},
      {label: 'REF_EXTERNA', value: 'TRNCOBRO_REFERENCIAEXTERNA'},
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'CXC_TRNCOBRO';
    this.where = 'rownum<=100  AND TRNCOBRO_REFERENCIAEXTERNA = \'PAGO RET.\' ' +
      'ORDER BY TRNCOBRO_fechaTrn DESC ';
    
    this.busquedaSer.busquedaCXCTrnCobro2().subscribe((datos: any[]) => {
      datos.map(itembusq => {
        itembusq.TRNCOBRO_FECHATRN = this.datePipe.transform(itembusq.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
        itembusq.TRNCOBRO_FECHAVENCE = this.datePipe.transform(itembusq.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
      });
      this.arregloBus = datos;
      this.displayDialogBusquedaFast = true;
    });
    
  }
  
  
  encontrarNumDoc(numComprobante) {
    this.cxctrnpagoretService.erTrncobroNroDoc1(numComprobante).subscribe(trncobronrodoc => {
      if (trncobronrodoc !== null) {
        this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC = trncobronrodoc[0].TRNCOBRO_NRODOC;
        this.cargarDatos(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC);
        this.btnPrimero = false;
        this.btnAnterior = false;
        this.btnSiguiente = false;
        this.btnUltimo = false;
        this.btnRegresar = true;
        this.btnGuardar = true;
        this.btnBorrar = false;
      }
    });
    
  }
  
  cambioRet(cambio) {
    this.botonesmodificar();
    this.btnCancelarGridret = false;
    
  }
  
  cambioDoc(cambio) {
    this.cxctrnpagoretService.calcularTotalDocumentos();
  }
  
  seleccionDoc(valor) {
    
    if (this.cxctrnpagoretService.cxcpagoretdocumentos.length !== 0) {
      if (valor.object !== undefined) {
        this.numeroFactura = valor.object.TRNDOCUMENTO_NRODOC;
        this.docselected = true;
      }
      
    }
  }
  
  cambioFP(cambio) {
    this.btnCancelarGridfp = false;
    this.botonesmodificar();
  }
  
  
  cargarDocPorCobrar() {
    this.cxctrnpagoretService.consultarDocumentosACobrar('ON', 'COM_CODIGO LIKE \'01\'', 'UPPER(CLI_CODIGO) LIKE \'' +
      this.cxctrnpagoretService.cxcpagoret.CLI_CODIGO + '\'').subscribe((res) => {
      if (res !== null) {
        this.cxctrnpagoretService.cxcpagoretdocumentos = [];
        res.map((docs) => {
          if (docs.SALDO === 0) {
            let objDoc: Cxcpagoretdocumentos;
            objDoc = {};
            objDoc.TRNDOCUMENTO_NRODOC = docs.DOCUMENTO;
            objDoc.TRNCOBRO_FECHATRN = this.datePipe.transform(docs.FECHA, 'dd/MM/yyyy');
            objDoc.TRNCOBRO_CONCEPTO = docs.CONCEPTO;
            objDoc.SALDOFAC = docs.SALDO.toFixed(2);
            objDoc.TRNDOCUMENTO_IMPORTE = Number(0).toFixed(2);
            objDoc.VEN_CODIGO = docs.VENDEDOR;
            objDoc.TRNCOBRO_TIPODOC = docs.TRNCOBRO_TIPODOC;
            objDoc.SELECCIONADO = false;
            this.cxctrnpagoretService.cxcpagoretdocumentos.push(objDoc);
          }
          
        });
        this.aggrid.refreshaggrid(this.cxctrnpagoretService.cxcpagoretdocumentos, this.defaultColDefDoc.objeto);
        // calcular
        // this.cxctrnpagoretService.calcularTotalDocumentos();
      }
  
      this.cxctrnpagoretService.erMaeCliente1(this.cxctrnpagoretService.cxcpagoret.CLI_CODIGO).subscribe((data: any) => {
        for (const resCli of data) {
          if (resCli.CLI_CONREGMIC === 'S'
            || resCli.CLI_AGENTERET === 'S') {
            if (resCli.CLI_CONREGMIC === 'S'
              && resCli.CLI_AGENTERET === 'S') {
              this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas y agente de retención';
            } else {
              if (resCli.CLI_CONREGMIC === 'S') {
                this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas';
              }
              if (resCli.CLI_AGENTERET === 'S') {
                this.lblAplicaRegMicroempresas = 'Cliente aplica agente de retención';
              }
            }
          } else {
            this.lblAplicaRegMicroempresas = '';
          }
        }
      });
    });
  }
  
  // Validaciones antes de Guardar
  async guardarRegistro() {

    if (this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC === '') {
        if (this.permisoCXCpagoret.PERINSERCION === 0) {
          this.message.add({
            key: 'cxctrnpagoretKey',
            severity: 'error',
            summary: 'Inserción',
            detail: 'El usuario no tiene permisos de inserción, acción denegada'
          });
          this.spin=false;//CDPJ
          return;
        }
      } else {
        if (this.permisoCXCpagoret.PERACTUALIZACION === 0) {
          this.message.add({
            key: 'cxctrnpagoretKey',
            severity: 'error',
            summary: 'Actualización',
            detail: 'El usuario no tiene permisos de actualización, acción denegada'
          });
          this.spin=false;//CDPJ
          return;
        }
      }

     // verificar que el documento esté dentro del periodo contable
    if (await this.cxctrnpagoretService.verificarPeriodoContableN(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_FECHATRN) === false) {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error',
        detail: 'La Fecha del Documento Debe estar dentro del Periodo Contable.'
      });
      this.spin=false;//CDPJ
      return;
    }

    // verificar que se haya seleccionado un cliente
		if (String(this.cxctrnpagoretService.cxcpagoret.CLI_CODIGO).length === 0) {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'info',
        summary: 'Información',
        detail: 'No ha seleccionado el cliente.'
      });
      this.spin=false;//CDPJ
			return;
		} else { // verificar la existencia del cliente
			if (await this.existeCliente2() === false) {
        this.message.add({
          key: 'cxctrnpagoretKey',
          severity: 'info',
          summary: 'Información',
          detail: 'No existe el cliente.'
        });
        this.spin=false;//CDPJ
				return;
			}
    }
    
    // verificar la existencia del vendedor
		if (String(this.cxctrnpagoretService.cxcpagoret.VEN_CODIGO).length > 0) {
			if (await this.existeVendedor2() === false) {
        this.message.add({
          key: 'cxctrnpagoretKey',
          severity: 'info',
          summary: 'Información',
          detail: 'No existe el vendedor.'
        });
        document.getElementById('vendedor').focus();
        this.spin=false;//CDPJ
				return;
			}
    }


    if (String(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NROCOMPROBANTE).length > 0) {
      if (await this.verificarComprobanteUnico2() === false) {
        this.message.add({
          key: 'cxctrnpagoretKey',
          severity: 'info',
          summary: 'Información',
          detail: 'El número de comprobante ya existe.'
        });
        this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NROCOMPROBANTE = '';
        document.getElementById('nro').focus();
        this.spin=false;//CDPJ
				return;
			}
    }

    // validar que el total por cobrar sea igual al total de la forma de
		// pago

		if (Number(this.cxctrnpagoretService.cxcpagoret.TOTALFORMAPAGO) !== Number(this.cxctrnpagoretService.cxcpagoret.TOTALRET)) {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'info',
        summary: 'Información ',
        detail: 'El total de la forma de pago es distinto al total de los documentos'
      });
      this.spin=false;//CDPJ
      return;
    }

    // VALIDACIONES DE LAS RETENCIONES
		if (this.cxctrnpagoretService.cxcpagoretretenciones != null) {
			if (this.cxctrnpagoretService.cxcpagoretretenciones.length > 1) {
				if (this.faltaDatosRet2()) {
          this.spin=false;//CDPJ
          return;
        }	
			}
    }
//CDPJ VALIDA QUE SE HAYA SELECCIONADO UNN DOCUMENTO
let seleccionados = 0;
if (this.cxctrnpagoretService.cxcpagoretdocumentos.length !== 0) {
  this.cxctrnpagoretService.cxcpagoretdocumentos.map((resumen) => {
    if (resumen.SELECCIONADO === true) {
      seleccionados++;
    }
  });
  if (seleccionados === 0) {
    this.message.add({
      key: 'cxctrnpagoretKey',
      severity: 'info',
      summary: 'Información',
      detail: 'Ningún documento ha sido ' +
        'seleccionado.'
    });
    this.spin=false//CDPJ
    return;
  }
} 
    // VALIDACIONES DE LA FORMA DE PAGO PARA LAS DEVOLUCIONES DE ANTICIPOS

		if (await this.faltaDatosFPDev2()) {
      this.spin=false;//CDPJ
      return;
    }

    if (await this.guardarDocumento2()) {

			// actualizar el estado de la factura si éste es afectado por el
			// documento************************************************************************

		/* 	if (this.cxctrnpagoretService.cxcpagoretdocumentos.length > 0) {
				if (await this.actualizarEstadoFactura() === false) {
          // reversar documento
          this.message.add({
            key: 'cxctrnpagoretKey',
            severity: 'info',
            summary: 'Información',
            detail: 'El Documento es eliminado porque surgio un problema en la actualizacion del estado de las facturas incluidas'
          });
					await this.eliminarDocumento2();
				}
			} */



			// generar el asiento del documento
				const  bolAsientoCon = await this.generarAsientoContable2(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC);



		}
    /* if (this.cxctrnpagoretService.cxcpagoretdocumentos.length !== 0) {
      let haveDoc = false;
      this.cxctrnpagoretService.cxcpagoretdocumentos.map(doc => {
        if (doc.SELECCIONADO) {
          haveDoc = true;
        }
      });
      if (haveDoc) {
        this.verificarPeriodoContableGuardar(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_FECHATRN);
      } else {
        this.message.add({
          key: 'cxctrnpagoretKey',
          severity: 'error',
          summary: 'Error ',
          detail: 'Por favor seleccione al menos un documento para el registro.'
        });
      }
    } else {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: '"No existen documentos para el registro.'
      });
    }  */
    
  }

  async generarAsientoContable2(strTrnCobroNroDoc) {
		let bolGenerar = false;

      // verificar que el documento esté dentro del periodo contable
      if (await this.cxctrnpagoretService.verificarPeriodoContableN(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_FECHATRN) === false) {
        this.spin=false;//CDPJ
        return false;
      }
			
			bolGenerar = await this.generarAsientoRet2(strTrnCobroNroDoc);

		return bolGenerar;
	}

  async generarAsientoRet2(strTrnCobroNroDoc) {
    let bolGenerar = false;
		try {
			await this.cxctrnpagoretService.generarAsientoRetProm(strTrnCobroNroDoc, this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NROCOMPROBANTE);
			// Recuperar el número del asiento
			const rs = await this.cxctrnpagoretService.erAsinroProm(strTrnCobroNroDoc);

      let strNumAsi = rs === null ? '' : rs[0].ASI_NRO;
			if (String(strNumAsi).length > 0) {
				this.cxctrnpagoretService.cxcpagoret.ASI_NRO = strNumAsi;
        this.message.add({
          key: 'cxctrnpagoretKey',
          severity: 'success',
          summary: 'Información ',
          detail: 'El Asiento Contable se generó correctamente'
        });
        this.spin=false;//CDPJ
				bolGenerar = true;
			}
		} catch (err) {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: 'No se generó el asiento contable ' + err.error
      });
      this.spin=false;//CDPJ
		}
		return bolGenerar;
  }

  async actualizarEstadoFactura() {
    let bolAct = true;
    for (const item of this.cxctrnpagoretService.cxcpagoretdocumentos){
     
      if (item.SELECCIONADO) {
        bolAct = await this.actualizaEstadoFactura2(item.TRNDOCUMENTO_NRODOC, this.cxctrnpagoretService.cxcpagoret.CLI_CODIGO);

      }
      
      if ( !bolAct )
        break;
    }
    return bolAct;
  }

  async actualizaEstadoFactura2 (strNumFact, strCodCliente) {
    let bolAct = false;
    const strParam: any[] = [];
		
		strParam[0]= '01';
		strParam[1] = strCodCliente;
		strParam[2] = strNumFact;
		
		try {
			await this.cxctrnpagoretService.actualizarEstadoFact(strParam);
			bolAct = true;
		} catch (err) {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: 'No se puede actualizar el estado de la factura ' + err.error
      });
		}
		return bolAct;
  }
  async guardarDocumento2() {
    // verificar que el total retenido sea igual al importe de cada factura
		let strTrnCobroNroDoc = '';
		let strTrnCobroNroComprobante = '';

		let bolGuardar;
		
		if (String(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC).trim() === '')	{
		
			if (!this.verificarImporteTotalRet()) {
        this.spin=false;//CDPJ
        return false;
      }
				
			
	
			if ( await this.insertarCancelacionAbono2()) // insertar la retención
			{
        if (bolGuardar = await this.insertarDocPorCobrar2(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC, this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NROCOMPROBANTE)) // grabar
        	{
					bolGuardar = await this.insertarRetenciones2(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC,
							this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NROCOMPROBANTE); // graba
					
					if (bolGuardar==true) {  // generar notas de debito por la generacion de las retenciones
            await this.generarND2();
          }
						
				}
			}
			
			bolGuardar = await this.insertarFormaPago2(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC);
		}	else //actualizacion
		{
			
			// verificar que el total retenido sea igual al importe de cada
			// factura
			if (!this.verificarImporteTotalRet()){
        this.spin=false;//CDPJ
        return false;
      }
				

			if (bolGuardar = await this.actualizarTrnCobro2()) {
				if (bolGuardar = await this.eliminarTrnDocumento2()) {
					if (bolGuardar = await this.insertarDocPorCobrar2(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC, this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NROCOMPROBANTE)) {
						if (bolGuardar = await this.eliminarTrnRetencion2()) // elimina
						// las
						// retenciones
						{
							bolGuardar = await this.insertarRetenciones2(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC, this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NROCOMPROBANTE);
					
							if (bolGuardar==true)
								await this.generarND2(); //generar notas de debito por la generacion de las retenciones
							
							
						}
					}
				}
			}
		
			//forma de pago
			
			if (bolGuardar = await this.eliminarFPDev2(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC, false)) {
				bolGuardar = await this.insertarFormaPago2(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC);
    }
		}
			
			
		return bolGuardar;
  }

  async eliminarFPDev2(strNumDoc, rollback) {
    let bolEliminar = false;
			
		bolEliminar = await this.eliminarCaja2( strNumDoc, rollback );
		bolEliminar = await this.eliminarBancos2( strNumDoc, rollback );
		
		return bolEliminar;
 }

 async eliminarCaja2(strNumDoc, rollback) {
  let bolEliminar = false;
  
  try {

    await this.cxctrnpagoretService.eliminarCajaProm(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC);
    
    bolEliminar = true;
    
    if (bolEliminar && !rollback ) {
      this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC, 'E',
          '', '01', '', '', '', '').subscribe(() => {
        });
    }
      
      
  } catch (err) {
    this.message.add({
      key: 'cxctrnpagoretKey',
      severity: 'error',
      summary: 'Error ',
      detail: 'No se puede eliminar el documento. ' + err.error
    });
    this.spin=false;//CDPJ
  }
  return bolEliminar;

 }

 async eliminarBancos2(strNumDoc, rollback) {

  let bolEliminar = false;
		
		try {
      await this.cxctrnpagoretService.eliminarBancosProm(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC);
			bolEliminar = true;
			
			if ( bolEliminar && !rollback ) {
        this.auditoriagral.registrarAuditoria('BAN_TRNBAN', this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC, 'E',
        '', '01', '', '', '', '').subscribe(() => {
        });
      
      }
				
		} 	catch (err) {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: 'No se puede eliminar el documento'
      });
      this.spin=false;//CDPJ
    }
    
		return bolEliminar;
 }


  async eliminarTrnRetencion2() {
    let bolEliminar = false;
				
		try {
			await this.cxctrnpagoretService.eliminarTrnRetencionProm('TRNCOBRO_NRODOC = \'' + this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01' +
      '\' AND TRNRETENCION_ORIGEN = \'CXC\'')
			bolEliminar = true;
		} 
		catch (err) {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: 'No se puede eliminar el documento ' + err.error
      });
			this.spin=false;//CDPJ
		}
				
		return bolEliminar;
  }

  async eliminarTrnDocumento2() {

    let bolEliminar = false;
				
		try {
			await this.cxctrnpagoretService.eliminarTrnDocumentoProm('TRNCOBRO_NRODOC = \'' + this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'');	
			bolEliminar = true;
		} catch (err) {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: 'No se puede eliminar el documento ' + err.error
      });
      this.spin=false;//CDPJ
		}
				
		return bolEliminar;
  }

  async actualizarTrnCobro2() {
    
    let bolInsertar = false;
	
				
		try {
			const rs = await this.cxctrnpagoretService.actualizarTrnCobroProm(this.cxctrnpagoretService.cxcpagoret);
			if (rs.length > 0){
          this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC = rs[0].TRNCOBRO_NRODOC;
          this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NROCOMPROBANTE = rs[0].TRNCOBRO_NROCOMPROBANTE;
      }
      
      this.btnAsiento = false;
      this.btnVerAsiento = false;
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.btnPrimero = false;
      this.btnAnterior = false;
      this.btnSiguiente = false;
      this.btnUltimo = false;
			
			if (String(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC).length > 0 )
				bolInsertar = true;
			
			if ( bolInsertar ) {
        this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC
        + '/' + this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NROCOMPROBANTE, 'A',
        '', '01', '', '', '', '').subscribe(() => {
        });

			}
		} catch (err) {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: 'La información no se actualizó correctamente.'
      });
      this.spin=false;//CDPJ
    }
		
		return bolInsertar;

  }

  async insertarFormaPago2(strTrnCobroNroDoc) {
    let bolInsertar = false;

		for (const item of this.cxctrnpagoretService.cxcpagoretformapago) {	
			if ( item.TRNPAGO_TIPO === 'EF')
				bolInsertar = await this.insertarCaja2( item, strTrnCobroNroDoc);
			else
				bolInsertar = await this.insertarBanco2(item, strTrnCobroNroDoc);
		}
		
		return bolInsertar;
  }

  async insertarBanco2(item: Cxcpagoretformapago, strTrnCobroNroDoc) {
    let bolInsertar = false;
    try {
      await this.cxctrnpagoretService.insertarBancoProm(this.cxctrnpagoretService.cxcpagoret, item, this.busCLINOMBRE);
      bolInsertar = true;

      if (item.TRNPAGO_TIPO === 'CH') {
        this.actualizaUltimoCheque(item);
      }
				
      this.auditoriagral.registrarAuditoria('BAN_TRNBAN', item.NUMERO +
      '/' + this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC, 'I',
      '', '01', '', '', '', '').subscribe(() => {
    });
    } catch (err) {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: 'No se puede insertar la forma de pago en el banco '
      });
      this.spin=false;//CDPJ
    }

    return bolInsertar;

  }

  async insertarCaja2(item: Cxcpagoretformapago, strTrnCobroNroDoc) {

    let bolInsertar = false;
		item.CONCEPTO = '*PAGORET';
		

		try {
      await this.cxctrnpagoretService.insertarCajaProm(this.cxctrnpagoretService.cxcpagoret, item);
      bolInsertar = true;
      this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', item.NUMERO +
          '/' + this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC, 'I',
          '', '01', '', '', '', '').subscribe(() => {
        });
		
		} catch (err) {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: 'No se puede insertar la forma de pago en la caja '
      });
      this.spin=false;//CDPJ
		}
		return bolInsertar;
  }

  async generarND2() {
    try {
      await this.cxctrnpagoretService.generarNDProm(this.cxctrnpagoretService.cxcpagoret)
    } catch (err) {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: 'No se puede verificar la existencia de la misma retención.'
      });
      this.spin=false;//CDPJ
    }
  }

  async insertarRetenciones2(strTrnCobroNroDoc, strTrnCobroNroComprobante ) {
    let bolInsertar = true;
		
		for (let intIndiceLista = 0; intIndiceLista < this.cxctrnpagoretService.cxcpagoretretenciones.length; intIndiceLista++) {
      this.cxctrnpagoretService.cxcpagoretretenciones[intIndiceLista].TRNCOBRO_NROCOMPROBANTE = strTrnCobroNroComprobante;
      this.cxctrnpagoretService.cxcpagoretretenciones[intIndiceLista].TRNCOBRO_NRODOC = strTrnCobroNroDoc;
  
      if (this.cxctrnpagoretService.cxcpagoretretenciones[intIndiceLista].ENCFAC_NUMERO === undefined) {
        this.cxctrnpagoretService.cxcpagoretretenciones[intIndiceLista].ENCFAC_NUMERO = this.cxctrnpagoretService.cxcpagoretretenciones[intIndiceLista].TRNRETENCION_REFERENCIA;
      }
			
			bolInsertar = await this.insertarTrnRetencion2(this.cxctrnpagoretService.cxcpagoretretenciones[intIndiceLista]);		
		}
		
		return bolInsertar;
  }

  async insertarTrnRetencion2(item: Cxcpagoretretenciones) {
    let bolInsertar = false;
    const strParam: any[] = [];
		
		if (await this.existeRetencionFact2(item) ){ //verificar si existe la retención para la misma factura
      return bolInsertar;
    }
		
		
		try {
      await this.cxctrnpagoretService.insertarTrnRetencionProm(item);
     //CDPJ
     console.log('ret1',this.chkretelectronica)
     if(this.chkretelectronica === true){
       await this.cxctrnpagoretService.actualizarTipoRetencionProm(item, 'E');      
       console.log('ret2',this.chkretelectronica)
     }else if(this.chkretelectronica === false){
         await this.cxctrnpagoretService.actualizarTipoRetencionProm(item, 'F');
         console.log('ret3',this.chkretelectronica)
       }
       //CDPJ
			bolInsertar = true;
			
			if ( bolInsertar ) {
        this.auditoriagral.registrarAuditoria('SACI_TRNRETENCION', item.TRNCOBRO_NRODOC
        + '/' + item.TRNRETENCION_NRO, 'I',
        '', '01', '', '', '', '').subscribe(() => {
      });
				
			}
		} 
		catch (err) {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error',
        detail: 'La información no se grabó correctamente. ' + err.error
      });
      this.spin=false;//CDPJ
			bolInsertar = false;
		}
		return bolInsertar;

  }

  async existeRetencionFact2(item: Cxcpagoretretenciones) {
    let bolExiste = false;

		
		try {
			const rs = await this.cxctrnpagoretService.existeRetencionFactProm(item);
			console.log(rs);
			if (rs.length > 0){

        this.message.add({
          key: 'cxctrnpagoretKey',
          severity: 'info',
          summary: 'Información',
          detail: 'Ya existe la retención ' + item.TRNRETENCION_NRO + ' para la factura ' + item.ENCFAC_NUMERO
        });
				
				bolExiste = true;
			}
		} 
		catch (err) {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede verificar la existencia de la misma retención ' + err.error
      });
		}
		
		return bolExiste;
  }

  async faltaDatosFPDev2() {
    let bolFalta = false;
				
		for (const item of this.cxctrnpagoretService.cxcpagoretformapago){
			let strCodigo = '';
			
			if (String(item.TRNPAGO_TIPO).length === 0 ) // si no existe tipo de pago
			{
        this.message.add({
          key: 'cxctrnpagoretKey',
          severity: 'info',
          summary: 'Información',
          detail: 'Ingrese el tipo de pago.'
        });
				bolFalta = true;
			}	else{
				if (String(item.TRNPAGO_TIPO).length > 0 ) //existencia de la forma de pago
				{
					try {
						const rs = await this.cxctrnpagoretService.erFormapagotipoProm(item.TRNPAGO_TIPO);
                
            strCodigo = rs === null ? '' : rs[0].FORMAPAGO_TIPO;
					} catch (err) {
            this.message.add({
              key: 'cxctrnpagoretKey',
              severity: 'error',
              summary: 'Error',
              detail: 'No se puede verificar la existencia de la forma de pago'
            });
					}
					
					if (String(strCodigo).length === 0 )	{
            this.message.add({
              key: 'cxctrnpagoretKey',
              severity: 'info',
              summary: 'Información',
              detail: 'La forma de pago no existe.'
            });
						return bolFalta = true;
					}
				}
				if (String(item.NUMERO).length == 0 || String(item.FECHA).length === 0 ) {
          this.message.add({
            key: 'cxctrnpagoretKey',
            severity: 'error',
            summary: 'Error',
            detail: 'La información está incompleta.'
          });
					return bolFalta = true;
				}				
				
        //	PAGOS EN CHEQUE 
				if (String(item.TRNPAGO_TIPO) === 'CH'){
					if (String(item.BANCLI_CODIGO).length == 0 || String(item.BAN_NOMBRE).length == 0 ){
            this.message.add({
              key: 'cxctrnpagoretKey',
              severity: 'error',
              summary: 'Error',
              detail: 'La información está incompleta.'
            });
						return bolFalta = true;
					}
				}

				if (Number(item.IMPORTE) <= 0 ){
          this.message.add({
            key: 'cxctrnpagoretKey',
            severity: 'error',
            summary: 'Error',
            detail: 'El pago no puede ser menor o igual a cero.'
          });
					bolFalta = true;
				}
				
				if (String(item.CAJ_CODIGO).length > 0 ) {
					if (await this.verificarExistenciaCaja(item.CAJ_CODIGO) === false){
            this.message.add({
              key: 'cxctrnpagoretKey',
              severity: 'error',
              summary: 'Error',
              detail: 'No existe la caja.'
            });
						bolFalta = true;
					}
				}
					
			}
		}
		
		return bolFalta;
  }

  async verificarExistenciaCaja(strCajCodigo) {
		let bolExiste = true;	
		try {
			const rs = await this.cxctrnpagoretService.verificarExistenciaCaja(strCajCodigo);
			console.log(rs);
			if (rs.length > 0){
				if ( rs[0].CAJ_CODIGO === null ) {
					bolExiste = false;
					return bolExiste;
				}
			} else {
        bolExiste = false;
      }
				
		} 
		catch (err) {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede verificar la existencia de la caja'
      });
			bolExiste = false;
		}
		return bolExiste;
	}

  faltaDatosRet2() {
    let bolFalta = false;
		
		for (const item of this.cxctrnpagoretService.cxcpagoretretenciones) {
			let strCodigo = '';
			
			if (String(item.RETENCION_CODIGO).length === 0 ){
        this.message.add({
          key: 'cxctrnpagoretKey',
          severity: 'info',
          summary: 'Información ',
          detail: 'Ingrese el código de la Retención.'
        });
				bolFalta = true;
			} else {
				if (String(item.RETENCION_CODIGO).length > 0) { //existencia de la retención
					try {
						//  const rs = this.cxctrnpagoretService.erRetencionCodigoProm(item.RETENCION_CODIGO);
            this.cxctrnpagoretService.erRetencionCodigoProm(item.RETENCION_CODIGO).subscribe(it => {
              console.log(it);
              console.log(it[0].RETENCION_CODIGO);
              strCodigo = it === null ? '' : it[0].RETENCION_CODIGO;
              console.log(strCodigo);
					if (String(strCodigo).length === 0 ){
            this.message.add({
              key: 'cxctrnpagoretKey',
              severity: 'info',
              summary: 'Información',
              detail: 'La retención no existe.'
            });
						return bolFalta = true;
					}
            });
            //   strCodigo = rs === null ? '' : rs[0].RETENCION_CODIGO;
					} catch (err) {
            this.message.add({
              key: 'cxctrnpagoretKey',
              severity: 'error',
              summary: 'Error',
              detail: 'No se puede verificar la existencia de la retención'
            });
					}
					
					// if (String(strCodigo).length === 0 ){
          //   this.message.add({
          //     key: 'cxctrnpagoretKey',
          //     severity: 'info',
          //     summary: 'Información',
          //     detail: 'La retención no existe.'
          //   });
					// 	return bolFalta = true;
					// }
				}	if (String(item.TRNRETENCION_DESCRIPCION).length === 0 || String(item.TRNRETENCION_NRO).length === 0 ) {
          this.message.add({
            key: 'cxctrnpagoretKey',
            severity: 'info',
            summary: 'Información',
            detail: 'La descripción y el número de retención son obligatorios.'
          });
					return bolFalta = true;
				}
				
				if (Number(item.TRNRETENCION_BASE) < 0 )	{
          this.message.add({
            key: 'cxctrnpagoretKey',
            severity: 'info',
            summary: 'Información',
            detail: 'La base de la retención no puede ser menor a cero.'
          });
					return bolFalta = true;
				}
				
				if (Number(item.TRNRETENCION_PORCENTAJE) < 0 )	{
          this.message.add({
            key: 'cxctrnpagoretKey',
            severity: 'error',
            summary: 'Error',
            detail: 'El porcentaje de la retención no puede ser menor a cero.'
          });
					return bolFalta = true;
				}
				
				if (Number(item.TRNRETENCION_TOTALRETENIDO) < 0){
          this.message.add({
            key: 'cxctrnpagoretKey',
            severity: 'error',
            summary: 'Error',
            detail: 'El total de la retención no puede ser menor a cero.'
          });
					return bolFalta = true;
				}
			}
				
		}
			
		return bolFalta;
  }

  async verificarComprobanteUnico2() {
    let bolVerificar = true;
    const strParam: any[] = [];
		if (String(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC).trim() === '') {
			strParam[0] = 'N';
			strParam[1] = '';
		} else {
			strParam[0] = 'M';
			strParam[1] = this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC;
	
		}

		strParam[2] = String(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NROCOMPROBANTE).trim();
		strParam[3] = '01';

		try {
			const rs = await this.cxctrnpagoretService.verificarComprobanteUnicoProm(strParam);
      if (rs.length > 0) {
        if (rs[0]["'0'"] !== undefined) {
          if (!(rs[0]["'0'"] === '0')) {
            bolVerificar = false;
          }
        }

        if (rs[0][':B1'] !== undefined) {
          if (!(rs[0][':B1'] === '0')) {
            bolVerificar = false;
          }
        }
      }

		} catch (err) {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: 'No se puede verificar si el comprobante es único'
      });	
		}
		return bolVerificar;

  }
  
  
  verificarPeriodoContableGuardar(fecha) {
    
    this.cxctrnpagoretService.verificarPeriodoContable(fecha).subscribe(res => {
      if (res[0]['COUNT(*)'] === 0) {
        this.message.add({
          key: 'cxctrnpagoretKey',
          severity: 'error',
          summary: 'Error ',
          detail: '"La Fecha del Documento Debe estar dentro del Periodo Contable.'
        });
      } else if (res[0]['COUNT(*)'] === 1) {
        // resto de metodos
        
        if (this.cxctrnpagoretService.cxcpagoret.CLI_CODIGO.trim() !== '') {
          
          this.existeCliente(this.cxctrnpagoretService.cxcpagoret.CLI_CODIGO);
          
        } else {
          this.message.add({
            key: 'cxctrnpagoretKey',
            severity: 'error',
            summary: 'Información ',
            detail: 'No ha seleccionado el cliente'
          });
        }
        
      }
    }, error1 => {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: '"No se puede verificar periodo contable'
      });
    });
    
  }

  async existeCliente2() {
		let bolExiste = true;
		try {
      const rs = await this.cxctrnpagoretService.erClinombre2Prom(this.cxctrnpagoretService.cxcpagoret.CLI_CODIGO);
      
      const strNombreCliente = rs === null ? '' : rs[0].CLI_NOMBRE;

			if (String(strNombreCliente).length === 0) {
        bolExiste = false;
      }
				
		} catch (err) {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: 'No se puede consultar los clientes.'
      });
		}
		return bolExiste;
	}
  
  
  existeCliente(codcliente) {
    
    this.cxctrnpagoretService.erClinombre2(codcliente).subscribe(res => {
      if (res !== null) {
        if (this.cxctrnpagoretService.cxcpagoret.VEN_CODIGO !== null) {
          if (this.cxctrnpagoretService.cxcpagoret.VEN_CODIGO.trim() !== '') {
            this.existeVendedor(this.cxctrnpagoretService.cxcpagoret.VEN_CODIGO);
          }
        }
        this.verificarComprobanteUnico(this.cxctrnpagoretService.cxcpagoret);
      } else {
        this.message.add({
          key: 'cxctrnpagoretKey',
          severity: 'error',
          summary: 'Información ',
          detail: 'No existe el cliente.'
        });
      }
      
    }, error1 => {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: 'No se puede consultar los clientes.'
      });
    });
    
  }

  async existeVendedor2() {
		let bolExiste = true;
		try {
      const rs = await this.cxctrnpagoretService.erVennombre2Prom(this.cxctrnpagoretService.cxcpagoret.VEN_CODIGO);
			const strNombreVend = rs === null ? '' : rs[0].VEN_NOMBRE;

			if (String(strNombreVend).length === 0) {
        bolExiste = false;
      }
				
		} catch (err) {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: 'No se puede consultar los vendedores.'
      });
		}
		return bolExiste;
  }
  
  existeVendedor(codvendedor) {
    
    this.cxctrnpagoretService.erVennombre2(codvendedor).subscribe(res => {
      if (res !== null) {
        this.verificarComprobanteUnico(this.cxctrnpagoretService.cxcpagoret);
        
      } else {
        this.message.add({
          key: 'cxctrnpagoretKey',
          severity: 'error',
          summary: 'Información ',
          detail: 'No existe el vendedor.'
        });
      }
      
    }, error1 => {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: 'No se puede consultar los vendedores.'
      });
    });
    
  }
  
  verificarComprobanteUnico(encabezado) {
    if (encabezado.TRNCOBRO_NRODOC === '') {
      this.cxctrnpagoretService.verificarComprobanteUnico('N', encabezado).subscribe(res => {
        if (res !== null) {
          if (res[0]['\'0\''] === '0') {
            this.validarTotales(this.cxctrnpagoretService.cxcpagoret);
          } else {
            this.message.add({
              key: 'cxctrnpagoretKey',
              severity: 'error',
              summary: 'Información ',
              detail: 'El número de comprobante ya existe.'
            });
          }
        }
      }, error1 => {
        this.message.add({
          key: 'cxctrnpagoretKey',
          severity: 'error',
          summary: 'Error ',
          detail: 'No se puede verificar si el comprobante es único'
        });
      });
    } else {
      this.cxctrnpagoretService.verificarComprobanteUnico('M', encabezado).subscribe(res => {
        if (res !== null) {
          if (res[0]['\'0\''] === '0') {
            this.validarTotales(this.cxctrnpagoretService.cxcpagoret);
          } else {
            this.message.add({
              key: 'cxctrnpagoretKey',
              severity: 'error',
              summary: 'Información ',
              detail: 'El número de comprobante ya existe.'
            });
          }
        }
      }, error1 => {
        this.message.add({
          key: 'cxctrnpagoretKey',
          severity: 'error',
          summary: 'Error ',
          detail: 'No se puede verificar si el comprobante es único'
        });
      });
    }
    
    
  }
  
  validarTotales(total) {
    if (Number(total.TOTALFORMAPAGO) === Number(total.TOTALRET)) {
      this.faltaDatosRet(this.cxctrnpagoretService.cxcpagoretretenciones);
      this.faltaDatosFPDev(this.cxctrnpagoretService.cxcpagoretformapago);
      
      setTimeout(() => {
        if (this.bolfaltaDatosRet === true && this.bolfaltaDatosFP === true) {
          this.guardarDocumento();
          
          setTimeout(() => {
            this.nuevoreg = false;
            this.generarAsientoContable(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC);
            
          }, 1000);
        } else {
        }
      }, 500);
      
    } else {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Información ',
        detail: 'El total de la forma de pago es distinto al total de los documentos'
      });
    }
  }
  
  // this.cxctrnpagoretService.cxcpagoretretenciones
  faltaDatosRet(listret) {
    this.bolfaltaDatosRet = true;
    if (listret.length !== 0) {
      listret.map(ret => {
        if (ret.RETENCION_CODIGO.trim() !== '') {
          this.existeRet(ret);
        } else {
          this.message.add({
            key: 'cxctrnpagoretKey',
            severity: 'error',
            summary: 'Información ',
            detail: 'Ingrese el código de la Retención.'
          });
          this.bolfaltaDatosRet = false;
        }
      });
    }
  }
  
  existeRet(retencion) {
    this.cxctrnpagoretService.erRetencionCodigo(retencion.RETENCION_CODIGO).subscribe(res => {
        if (res !== null) {
          if (retencion.TRNRETENCION_DESCRIPCION.trim() !== '' && retencion.TRNRETENCION_NRO.trim() !== '') {
            if (Number(retencion.TRNRETENCION_BASE) > 0 && Number(retencion.TRNRETENCION_PORCENTAJE) > 0
              && Number(retencion.TRNRETENCION_TOTALRETENIDO) > 0) {
              
              // resto metodos
              
            } else {
              this.message.add({
                key: 'cxctrnpagoretKey',
                severity: 'error',
                summary: 'Información ',
                detail: 'La base, el porcentaje  o el total de la retención no puede ser menor a cero.'
              });
              this.bolfaltaDatosRet = false;
            }
          } else {
            this.message.add({
              key: 'cxctrnpagoretKey',
              severity: 'error',
              summary: 'Información ',
              detail: 'La descripción y el número de retención son obligatorios.'
            });
            this.bolfaltaDatosRet = false;
          }
          
        } else {
          this.message.add({
            key: 'cxctrnpagoretKey',
            severity: 'error',
            summary: 'Información ',
            detail: 'La retención no existe.'
          });
          this.bolfaltaDatosRet = false;
        }
      }, error1 => {
        this.message.add({
          key: 'cxctrnpagoretKey',
          severity: 'error',
          summary: 'Error ',
          detail: 'No se puede verificar la existencia de la retención'
        });
        this.bolfaltaDatosRet = false;
      }
    );
  }
  
  
  faltaDatosFPDev(listfpdev) {
    this.bolfaltaDatosFP = true;
    if (listfpdev.length !== 0) {
      listfpdev.map(fp => {
        if (fp.TRNPAGO_TIPO.trim() !== '') {
          this.existeFP(fp);
        } else {
          this.message.add({
            key: 'cxctrnpagoretKey',
            severity: 'error',
            summary: 'Información ',
            detail: 'Ingrese el tipo de pago.'
          });
          this.bolfaltaDatosFP = false;
        }
      });
    }
  }
  
  existeFP(formapago) {
    this.cxctrnpagoretService.erFormapagotipo(formapago.TRNPAGO_TIPO).subscribe(res => {
        if (res !== null) {
          if (formapago.FECHA.trim() !== '' && formapago.NUMERO.trim() !== '') {
            if (formapago.TRNPAGO_TIPO === 'CH') {
              if (formapago.BANCLI_CODIGO === '' || formapago.BAN_NOMBRE === '') {
                this.message.add({
                  key: 'cxctrnpagoretKey',
                  severity: 'error',
                  summary: 'Error ',
                  detail: 'La información está incompleta.'
                });
                this.bolfaltaDatosFP = false;
              } else {
                if (Number(formapago.IMPORTE) > 0) {
                  // resto metodos
                } else {
                  this.message.add({
                    key: 'cxctrnpagoretKey',
                    severity: 'error',
                    summary: 'Información ',
                    detail: 'El pago no puede ser menor o igual a cero.'
                  });
                  this.bolfaltaDatosFP = false;
                }
              }
              
            }
            
          } else {
            this.message.add({
              key: 'cxctrnpagoretKey',
              severity: 'error',
              summary: 'Información ',
              detail: 'La información está incompleta.'
            });
            this.bolfaltaDatosFP = false;
          }
          
        } else {
          this.message.add({
            key: 'cxctrnpagoretKey',
            severity: 'error',
            summary: 'Información ',
            detail: 'La forma de pago no existe.'
          });
          this.bolfaltaDatosFP = false;
        }
      }, error1 => {
        this.message.add({
          key: 'cxctrnpagoretKey',
          severity: 'error',
          summary: 'Error ',
          detail: 'No se puede verificar la existencia de la forma de pago'
        });
        this.bolfaltaDatosFP = false;
      }
    );
  }
  
  
  // *******
  
  
  guardarDocumento(): boolean {
    
    if (this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC === '') {
      
      if (!this.verificarImporteTotalRet()) {
        return false;
      }
      this.insertarCancelacionAbono();
      
      
    } else {
      // actualizar
      if (!this.verificarImporteTotalRet()) {
        return false;
      }
      this.actualizarTrnCobro();
      
      
    }
  }
  
  // actualizar doc
  
  actualizarTrnCobro() {
    
    this.cxctrnpagoretService.actualizarTrnCobro(this.cxctrnpagoretService.cxcpagoret).subscribe(res => {
      
      this.eliminarTrnDocumento(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC);
      
      this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC
        + '/' + this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NROCOMPROBANTE, 'A',
        '', '01', '', '', '', '').subscribe(() => {
      });
      
      
      this.btnAsiento = false;
      this.btnVerAsiento = false;
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.btnPrimero = false;
      this.btnAnterior = false;
      this.btnSiguiente = false;
      this.btnUltimo = false;
      
      
    }, error1 => {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: 'La información no se actualizó correctamente.'
      });
    });
    
  }
  
  eliminarTrnDocumento(trncobronrodoc) {
    this.cxctrnpagoretService.eliminarTrnDocumento('TRNCOBRO_NRODOC = \'' + trncobronrodoc + '\' AND COM_CODIGO = \'01\'').subscribe(res => {
      
      this.insertarDocPorCobrar();
      
      
    }, error1 => {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: 'No se puede eliminar el documento'
      });
    });
  }
  
  eliminarTrnRetencion(trncobronrodoc) {
    this.cxctrnpagoretService.eliminarTrnRetencion('TRNCOBRO_NRODOC = \'' + trncobronrodoc + '\' AND COM_CODIGO = \'01' +
      '\' AND TRNRETENCION_ORIGEN = \'CXC\'').subscribe(res => {
      
      
      this.insertarRetenciones();
      
      
    }, error1 => {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: 'No se puede eliminar el documento'
      });
      
    });
  }
  
  
  // Guardar Documento
  
  verificarImporteTotalRet(): boolean {
    let bolVerificar = true;
    let trncobroimporte = 0;
    this.cxctrnpagoretService.cxcpagoretdocumentos.map(numfac => {
      if (numfac.TRNDOCUMENTO_NRODOC === this.numeroFactura) {
        trncobroimporte = numfac.TRNDOCUMENTO_IMPORTE;
      }
    });
    if (Number(trncobroimporte) !== Number(this.cxctrnpagoretService.cxcpagoret.TOTALRET)) {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error Guardar',
        detail: 'El importe de la factura ' + this.numeroFactura
          + ' no es igual al total de las retenciones'
      });
      bolVerificar = false;
      return bolVerificar;
    }
    return bolVerificar;
  }
  
  insertarCancelacionAbono() {
    
    this.cxctrnpagoretService.cxcpagoret.APLORG_CODIGO = 'CXC';
    this.cxctrnpagoretService.cxcpagoret.COM_CODIGO = '01';
    this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_FLAG = '1';
    this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_TIPODOC = 'RT';
    this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_IMPORTE = this.cxctrnpagoretService.cxcpagoret.TOTALRET;
    this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_REFERENCIAEXTERNA = 'PAGO RET.';
    this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC = null;
    this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_FECHAVENCE = '';
    this.cxctrnpagoretService.cxcpagoret.BAN_CODIGO = null;
    this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_EFECTIVO = 0;
    this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_CAMBIO = 0;
    this.cxctrnpagoretService.cxcpagoret.CON_CODIGO = null;
    this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_SALDOINI = 0;
    this.cxctrnpagoretService.cxcpagoret.BOD_CODIGO = null;
    
    //this.insertarTrnCobro();
    
  }

  async insertarCancelacionAbono2() {
    let bolInsertar = false;
    const item: Cxcpagoret = {};
		item.APLORG_CODIGO = 'CXC';
		item.ASI_NRO = this.cxctrnpagoretService.cxcpagoret.ASI_NRO;
		item.CLI_CODIGO = this.cxctrnpagoretService.cxcpagoret.CLI_CODIGO;
		item.COM_CODIGO = '01';
		item.TRNCOBRO_CONCEPTO = this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_CONCEPTO;
		item.TRNCOBRO_FECHATRN = this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_FECHATRN;
		item.TRNCOBRO_FLAG = 1;
		item.TRNCOBRO_NROCOMPROBANTE = this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NROCOMPROBANTE;
    item.TRNCOBRO_TIPODOC ='RT';
    item.TRNCOBRO_IMPORTE = Number(this.cxctrnpagoretService.cxcpagoret.TOTALRET);
		item.USU_IDENTIFICACION = '';
		item.VEN_CODIGO = this.cxctrnpagoretService.cxcpagoret.VEN_CODIGO;
    item.TRNCOBRO_REFERENCIAEXTERNA = 'PAGO RET.';
    item.BOD_CODIGO = null;
    item.TRNCOBRO_NRODOC = null;
    item.TRNCOBRO_FECHAVENCE = '';
    item.BAN_CODIGO = null;
    item.TRNCOBRO_EFECTIVO = 0;
    item.TRNCOBRO_CAMBIO = 0;
    item.CON_CODIGO = null;
    item.TRNCOBRO_SALDOINI = 0;
    
		//bolInsertar = await this.insertarTrnCobro2(item);
    try {
      bolInsertar = await this.insertarTrnCobro2(item)
    } catch (error) {
      this.spin=false;//CDPJ
      return false;
    }
		return bolInsertar;
  }
  
  async insertarTrnCobro2(item: Cxcpagoret) {
    let bolInsertar = false;
	
				
		try {
      const rs = await this.cxctrnpagoretService.insertarTrnCobroProm(item);
      
          this.btnAsiento = false;
          this.btnVerAsiento = false;
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.btnRegresar = true;
          this.btnPrimero = false;
          this.btnAnterior = false;
          this.btnSiguiente = false;
          this.btnUltimo = false;
			
			if (rs.length > 0){
        this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC = rs[0].TRNCOBRO_NRODOC;
          this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NROCOMPROBANTE = rs[0].TRNCOBRO_NROCOMPROBANTE;
			}
			
			if (String(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC).length > 0 )
				bolInsertar = true;
			if (bolInsertar) {

        this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC
              + '/' + this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NROCOMPROBANTE, 'I',
              '', '01', '', '', '', '').subscribe(() => {
            });
			}
		} 
		catch (err) {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error Guardar',
        detail: 'La información no se grabó correctamente. ' + err.error
      });
			bolInsertar = false;
		}
		
		return bolInsertar;
  }
  
  insertarTrnCobro() {
    this.cxctrnpagoretService.insertarTrnCobro(this.cxctrnpagoretService.cxcpagoret).subscribe(res => {
      if (res !== null) {
        res.map(encabezado => {
          this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC = encabezado.TRNCOBRO_NRODOC;
          this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NROCOMPROBANTE = encabezado.TRNCOBRO_NROCOMPROBANTE;
          
          this.btnAsiento = false;
          this.btnVerAsiento = false;
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.btnRegresar = true;
          this.btnPrimero = false;
          this.btnAnterior = false;
          this.btnSiguiente = false;
          this.btnUltimo = false;
          
          if (this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC !== '') {
            
            this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC
              + '/' + this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NROCOMPROBANTE, 'I',
              '', '01', '', '', '', '').subscribe(() => {
            });
            
          }
          
          // resto de metodos
          //  this.generarAsientoContable(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC);
          
          this.insertarDocPorCobrar();
          
        });
      }
    });
    
  }
  
  
  insertarDocPorCobrar() {
    
    if (this.cxctrnpagoretService.cxcpagoretdocumentos.length !== 0) {
      this.cxctrnpagoretService.cxcpagoretdocumentos.map((selected) => {
        selected.TRNDOCUMENTO_REF = 'F';
        if (selected.APLORG_CODIGO === 'SISTEMA' && selected.TRNCOBRO_TIPODOC === 'FC') {
          selected.TRNDOCUMENTO_REF = 'C';
        }
        if (selected.SELECCIONADO === true) {
          this.cxctrnpagoretService.insertarTrnDocumento(this.cxctrnpagoretService.cxcpagoret, selected).subscribe(res => {
            
            this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC
              + '/' + selected.TRNDOCUMENTO_NRODOC, 'I',
              '', '01', '', '', '', '').subscribe(() => {
            });
            
            if (selected.APLORG_CODIGO === 'SISTEMA' && selected.TRNCOBRO_TIPODOC === 'FC') {
              this.actualizarCuotasPagadas(selected);
            }
            
            
            // resto de metodos
            
            if (this.nuevoreg === false) {
              this.eliminarTrnRetencion(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC);
              
            } else if (this.nuevoreg === true) {
              
              this.insertarRetenciones();
              
            }
            
          }, error1 => {
            this.message.add({
              key: 'cxctrnpagoretKey',
              severity: 'error',
              summary: 'Error ',
              detail: 'La información no se grabó correctamente.'
            });
          });
        }
      });
    }
  }

  async insertarDocPorCobrar2(strTrnCobroNroDoc, strTrnCobroNroComprobante) {
    let bolInsertar = false;
		
		for (const itemDoc of this.cxctrnpagoretService.cxcpagoretdocumentos)	{
			
		  if (itemDoc.SELECCIONADO === true) {
					
          const item: any = {};
          
          item.CLI_CODIGO = String(this.cxctrnpagoretService.cxcpagoret.CLI_CODIGO).trim();
          item.TRNDOCUMENTO_IMPORTE = Number(itemDoc.TRNDOCUMENTO_IMPORTE);
          item.TRNCOBRO_NROCOMPROBANTE = strTrnCobroNroComprobante;
          item.COM_CODIGO = '01'
          item.TRNDOCUMENTO_NRODOC = itemDoc.TRNDOCUMENTO_NRODOC;
					item.TRNDOCUMENTO_REF = 'F';
					if (itemDoc.APLORG_CODIGO === 'SISTEMA' && itemDoc.TRNCOBRO_TIPODOC ==='FC') {
            item.TRNDOCUMENTO_REF = 'c';
          }
					
					bolInsertar = await this.insertarTrnDocumento2(strTrnCobroNroDoc, 
							strTrnCobroNroComprobante, item);
					
	        // actualizar las cuotas en caso de que se hayan cancelado
					if ( bolInsertar ) {
						if (itemDoc.APLORG_CODIGO === 'SISTEMA' && itemDoc.TRNCOBRO_TIPODOC ==='FC' )	{
							bolInsertar = await this.actualizarCuotasPagadas2(item);
						}
					}
					
					if ( !bolInsertar )	{
            this.spin=false;//CDPJ
						break;
					}
				}	
		
		}		
		return bolInsertar;
  }

  async actualizarCuotasPagadas2(item) {
    let bolAct = true;
		try {
      await this.cxctrnpagoretService.actualizarCuotasPagadasProm(item);
		} 
		catch (err) {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: 'No se puede actualizar los pagos de las cuotas ' + err.error
      });
      this.spin=false;//CDPJ
			bolAct = false;
		}
		return bolAct;
  }
  
  actualizarCuotasPagadas(doc) {
    
    this.cxctrnpagoretService.actualizarCuotasPagadas(doc).subscribe(res => {
      },
      error1 => {
        this.message.add({
          key: 'cxctrnpagoretKey',
          severity: 'error',
          summary: 'Error ',
          detail: 'No se puede actualizar los pagos de las cuotas'
        });
      });
  }
  
  insertarRetenciones() {
    this.countFP = 0;
    console.log('insertaretenciones');
    this.cxctrnpagoretService.cxcpagoretretenciones.map(ret => {
      console.log('map', ret);
      ret.TRNCOBRO_NROCOMPROBANTE = this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NROCOMPROBANTE;
      ret.TRNCOBRO_NRODOC = this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC;
      if (ret.ENCFAC_NUMERO === undefined) {
        ret.ENCFAC_NUMERO = ret.TRNRETENCION_REFERENCIA;
      }
      
      this.existeRetencionFact(ret);  // verificar si existe la retención para la misma factura
      
    });
    
  }

  async insertarTrnDocumento2 (strTrnCobroNroDoc, strTrnNroComprobante, item ) {
    let bolInsertar = false;
		const strParam: any[] = [];
	
      strParam[0] = strTrnCobroNroDoc; 
      strParam[1] = item.TRNDOCUMENTO_NRODOC;  //número de la factura
      strParam[2] = item.TRNDOCUMENTO_IMPORTE;
      strParam[3] = item.COM_CODIGO;
      strParam[4] = item.CLI_CODIGO;
      strParam[5] = item.TRNDOCUMENTO_REF;
      strParam[6] = strTrnNroComprobante;
		
		try {
			await this.cxctrnpagoretService.insertarTrnDocumentoProm(strParam);
			bolInsertar = true;
			
			if ( bolInsertar ) {
        this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC
              + '/' + item.TRNDOCUMENTO_NRODOC, 'I',
              '', '01', '', '', '', '').subscribe(() => {
            });
			}
		} catch (err) {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: 'La información no se grabó correctamente. ' + err.error
      });
      this.spin=false;//CDPJ
			bolInsertar = false;
    }
    
		return bolInsertar;
  }
  
  insertarTrnRetencion(retencion) {
    
    this.cxctrnpagoretService.insertarTrnRetencion(retencion).subscribe(res => {
      
      this.auditoriagral.registrarAuditoria('SACI_TRNRETENCION', retencion.TRNCOBRO_NRODOC
        + '/' + retencion.TRNRETENCION_NRO, 'I',
        '', '01', '', '', '', '').subscribe(() => {
      });
      
      // resto metodos
      
      this.generarND();
      
      if (this.nuevoreg === false && this.countFP === 0) {
        this.eliminarFPDev();
        this.countFP++;
        
        
      } else if (this.nuevoreg === true && this.countFP === 0) {
        this.insertarFormaPago();
        this.countFP++;
        
      }
      
    }, error1 => {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: 'La información no se grabó correctamente.'
      });
    });
  }
  
  
  eliminarFPDev() {
    
    this.cxctrnpagoretService.eliminarCaja(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC).subscribe(res => {
      this.cxctrnpagoretService.eliminarBancos(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC).subscribe(res2 => {
        
        this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC, 'E',
          '', '01', '', '', '', '').subscribe(() => {
        });
        
        this.auditoriagral.registrarAuditoria('BAN_TRNBAN', this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC, 'E',
          '', '01', '', '', '', '').subscribe(() => {
        });
        
        
        this.insertarFormaPago();
        
        
      }, error1 => {
        this.message.add({
          key: 'cxctrnpagoretKey',
          severity: 'error',
          summary: 'Error ',
          detail: 'No se puede eliminar el documento'
        });
      });
    });
    
    
  }
  
  existeRetencionFact(retencion) {
    
    this.cxctrnpagoretService.existeRetencionFact(retencion).subscribe(res => {
      console.log('ret cod ', retencion.RETENCION_CODIGO);
      if (res !== null) {
        if (res.length !== 0) {
          this.message.add({
            key: 'cxctrnpagoretKey',
            severity: 'error',
            summary: 'Información ',
            detail: 'Ya existe la retención ' + retencion.TRNRETENCION_NRO + ' para la factura ' + retencion.ENCFAC_NUMERO
          });
        } else if (res.length === 0) {
          
          this.insertarTrnRetencion(retencion);
          
        }
        
      }
    }, error1 => {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: 'No se puede verificar la existencia de la misma retención.'
      });
    });
    
  }
  
  generarND() {
    this.cxctrnpagoretService.generarND(this.cxctrnpagoretService.cxcpagoret).subscribe(res => {
    }, error1 => {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: 'No se puede verificar la existencia de la misma retención.'
      });
    });
  }
  
  insertarFormaPago() {
    if (this.cxctrnpagoretService.cxcpagoretformapago !== null) {
      this.cxctrnpagoretService.cxcpagoretformapago.map(fp => {
        if (fp.TRNPAGO_TIPO === 'EF') {
          fp.CONCEPTO = '*PAGORET';
          this.insertarCaja(fp);
        } else if (fp.TRNPAGO_TIPO === 'CH') {
          this.insertarBanco(fp);
          
        }
      });
    }
  }
  
  insertarCaja(formapago) {
    
    
    this.cxctrnpagoretService.insertarCaja(this.cxctrnpagoretService.cxcpagoret, formapago)
      .subscribe(res => {
        
        this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', formapago.NUMERO +
          '/' + this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC, 'I',
          '', '01', '', '', '', '').subscribe(() => {
        });
        
      }, error1 => {
        this.message.add({
          key: 'cxctrnpagoretKey',
          severity: 'error',
          summary: 'Error ',
          detail: 'No se puede insertar la forma de pago en la caja '
        });
      });
    
    
  }
  
  insertarBanco(formapago) {
    this.cxctrnpagoretService.insertarBanco(this.cxctrnpagoretService.cxcpagoret, formapago, this.busCLINOMBRE)
      .subscribe(res => {
        this.auditoriagral.registrarAuditoria('BAN_TRNBAN', formapago.NUMERO +
          '/' + this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC, 'I',
          '', '01', '', '', '', '').subscribe(() => {
        });
        
        this.actualizaUltimoCheque(formapago);
        
      }, error1 => {
        this.message.add({
          key: 'cxctrnpagoretKey',
          severity: 'error',
          summary: 'Error ',
          detail: 'No se puede insertar la forma de pago en el banco '
        });
      });
    
    
  }
  
  actualizaUltimoCheque(formapago) {
    
    this.cxctrnpagoretService.actualizaUltimoCheque(formapago).subscribe(res => {
    
    
    }, error1 => {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: '"No se puede actualizar el último cheque del banco'
      });
    });
    
    
  }
  
  // generarAsientoContable
  
  generarAsientoContable(trnCobroNroDoc) {
    this.verificarPeriodoContable(this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_FECHATRN, trnCobroNroDoc);
    
  }
  
  verificarPeriodoContable(fecha, trnCobroNroDoc) {
    this.cxctrnpagoretService.verificarPeriodoContable(fecha).subscribe(res => {
      if (res[0]['COUNT(*)'] === 0) {
        this.message.add({
          key: 'cxctrnpagoretKey',
          severity: 'error',
          summary: 'Error ',
          detail: '"La Fecha del Documento Debe estar dentro del Periodo Contable.'
        });
      } else if (res[0]['COUNT(*)'] === 1) {
        
        this.generarAsientoRet(trnCobroNroDoc, this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_NROCOMPROBANTE);
      }
    }, error1 => {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: '"No se puede actualizar el último cheque del banco'
      });
    });
    
  }
  
  generarAsientoRet(trnCobroNroDoc, comprobante) {
    this.cxctrnpagoretService.generarAsientoRet(trnCobroNroDoc, comprobante).subscribe(res => {
      
      this.cxctrnpagoretService.erAsinro(trnCobroNroDoc).subscribe(res1 => {
        if (res1 !== null) {
          this.cxctrnpagoretService.cxcpagoret.ASI_NRO = res1[0].ASI_NRO;
          this.message.add({
            key: 'cxctrnpagoretKey',
            severity: 'success',
            summary: 'Información ',
            detail: '"El Asiento Contable se generó correctamente'
          });
        }
      });
      
    }, error1 => {
      
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Error ',
        detail: '"No se generó el asiento contable'
      });
      
    });
    
  }
  
  
  // others
  
  enableCeldaImporte(valor) {
    console.log(valor.data.SELECCIONADO);
    
    if (valor.data.SELECCIONADO === true) {
    
    
    } else if (valor.data.SELECCIONADO === false) {
    
    }
    
    
  }
  
  botonesmodificar() {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = true;
    this.btnRegresar = false;
    // bloquear botones en la edicion
    this.btnPrimero = true;
    this.btnAnterior = true;
    this.btnSiguiente = true;
    this.btnUltimo = true;
    
  }
  
  typecontrol(event) {
    if (event.key !== 13) {
      event.key.preventDefault();
    }
    
    //  (input)="typecontrol($event)"
  }
  
  verAsiento() {
    this.asiento = this.cxctrnpagoretService.cxcpagoret.ASI_NRO;
    if (this.asiento === '') {
      this.message.add({
        key: 'cxctrnpagoretKey',
        severity: 'error',
        summary: 'Información',
        detail: 'No ha generado el Asiento Contable'
      });
    } else {
      this.auxiliar++;
      // this.displayDialogAsiento = true;
      this.displayAsiContable = true;
    }
  }
  
  cambiarformatoFecha(fecha) {
    this.cxctrnpagoretService.cxcpagoret.TRNCOBRO_FECHATRN = this.datePipe.transform(fecha, 'dd/MM/yyyy');
    this.botonesmodificar();
  }
  
  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }
  
  cerrarReportes() {
    this.displayDialogReportes = false;
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos  = false;
    }
  }

}
