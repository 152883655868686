import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { Observable } from 'rxjs';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';


@Injectable({
  providedIn: 'root'
})
export class UtilitariosService {
  public url: string;
  public way: string;
  public menuParams: any[];
  public timeLeft = 5 * 60;
  public control = 1;

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }

  refrescarURL() {
    if (this.url === null) {
      this.url = sessionStorage.getItem('url');
    }
    if (this.way === null) {
      this.way = sessionStorage.getItem('way');
    }
  }

  getUnidadesEqui(uniCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/advantage/utilitarios/83bbebc9daf4c00', {
      elementos: {
        UNI_CODIGO: uniCodigo
      }
    }, this.confIniciales.httpOptions());
  }

  getFactorcantidad(UNIDADVENTA, UNIDADDETALLE) {
    return this.http.post<any[]>(this.way + '/advantage/utilitarios/10775ea6771b6300000000', {
      elementos: {
        UNIDADVENTA, UNIDADDETALLE
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  validalote(articulo, cantidad, linea, strNumDoc) {
    return this.http.post<any[]>(this.way + '/advantage/utilitarios/7d5593555c75dc', {
      elementos: {
        articulo, cantidad, linea, strNumDoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }


  getListaPrecioArt(artCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/advantage/utilitarios/15973b9db992c3000', {
      elementos: {
        ART_CODIGO: artCodigo
      }
    }, this.confIniciales.httpOptions());
  }

  getListaPrecioArtProm(artCodigo) {
    return this.http.post<any[]>(this.way + '/advantage/utilitarios/15973b9db992c3000', {
      elementos: {
        ART_CODIGO: artCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  menuParamsPed() {
    this.menuParams = [{
      label: 'Estado Especial del pedido', accion: {
        message: 'Esta seguro que desea eliminar la clase de numeración',
        header: 'Eliminar Clase de Numeración',
        icon: 'pi pi-exclamation-triangle',
        key: 'pedidoConf',
        acceptLabel: 'Si',
        rejectLabel: 'No'
      }
    }, { label: 'Copiar Pedido', accion: {} }];
    return this.menuParams;
  }

  obtenerUnidadEqui(strArtCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/advantage/utilitarios/617df5e4d76bd0', {
      elementos: {
        ART_CODIGO: strArtCodigo
      }
    }, this.confIniciales.httpOptions());
  }

  verificarLista(artCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/advantage/utilitarios/3eedc9f5973aa', {
      elementos: {
        ART_CODIGO: artCodigo
      }
    }, this.confIniciales.httpOptions()
    );
  }
  erArtPreDescuento(strArtCodigo, strLista) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/76d5bee76e6bb80', {
      elementos: {
        ART_CODIGO: strArtCodigo,
        DETFAC_LISTA: strLista
      },
    }, this.confIniciales.httpOptions()).toPromise();

  }

  configRegMic(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/advantage/utilitarios/33177ca1b75a4c', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  /*
  downloadPDF(url): any {
    const options = { responseType: ResponseContent.Blob};
    return this.http.get(url, options).map(
      (res) => {
      return new Blob([res.blob()], {type: 'application/pdf'});
    });
  }
  */

  updateLogoCompañia(imagen): void { // metodo para guardar una pantalla
    this.http.post(this.way + '/advantage/utilitarios/f65aaae20cc5b00', {
      elementos: {
        COM_LOGO: imagen
      }
    }, this.confIniciales.httpOptions()).subscribe();
  }

  updateLogotipo(logotipo, proveedor): Promise<any> {
    return this.http.post<any[]>((this.way === null ? sessionStorage.getItem('way') : this.way) + '/descargas/533beae218ecb40', {
      elementos: {
        LOGO: logotipo,
        PRO_CODIGO: proveedor
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  updateRutaLogo(ruta): void { // metodo para guardar una pantalla
    this.http.post(this.way + '/advantage/utilitarios/3d96ab7eed710', {
      elementos: {
        COM_RUTALOGO: ruta
      }
    }, this.confIniciales.httpOptions()).subscribe();
  }

  getLogotipo(): Observable<any[]> {
    return this.http.post<any[]>((this.way === null ? sessionStorage.getItem('way') : this.way) + '/loginsql/ae218ecb38', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  updateDatosIps(ippublica, host, enusu, usuario): void { // metodo para actualizar ips
    this.http.post(this.way + '/loginsql/299df2ab2', {
      elementos: {
        USU_IPPUBLICA: ippublica,
        USU_IPLOCAL: host,
        USU_ENUSO: enusu,
        USUIDENTIFICACION: usuario,
      }
    }, this.confIniciales.httpOptions()).subscribe();
  }

  updateDatosEnUso(enusu): void { // metodo para actualizar ips
    this.http.post(this.way + '/advantage/utilitarios/3d96abaebfb98', {
      elementos: {
        USU_ENUSO: enusu,
        USUIDENTIFICACION: this.usuario.identificacion
      }
    }, this.confIniciales.httpOptions()).subscribe();
  }

  hostname(): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/sesiones/8e39dbaace', {}).toPromise();
  }

  datosmarca(): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/sesiones/1aaee39656d8a', {}).toPromise();
  }

  verificarmarca(): Promise<any> {
    return this.http.post<any>(this.way + '/sesiones/1f76e4f9314c9600000000', {}).toPromise();
  }

  async esmarcasaci() {
    const esmarcasaci: any = await this.verificarmarca();
    return esmarcasaci.value;
  }

  public saveStorageIp(host, ipfe) {
    sessionStorage.setItem('internetProtocol', JSON.stringify({
      hostname: host,
      ipfrontend: ipfe
    }));
  }

  public getHostname(): string {
    const store = JSON.parse(sessionStorage.getItem('internetProtocol'));
    if (store == null) {
      return '';
    } else {
      return store.hostname;
    }
  }

  public saveStorageMarca(tipomarca, numero) {
    sessionStorage.setItem('marca', JSON.stringify({
      tipo: tipomarca,
      codigo: numero
    }));
  }

  public getTipoMarca(): string {
    const marca = JSON.parse(sessionStorage.getItem('internetProtocol'));
    if (marca == null) {
      return '';
    } else {
      return marca.tipo;
    }
  }

  public getIpFrontend(): string {
    const IP = JSON.parse(sessionStorage.getItem('internetProtocol'));
    if (IP == null) {
      return '';
    } else {
      return IP.ipfrontend;
    }
  }

  public saveActivacion(valor) {
    sessionStorage.setItem('activacion', JSON.stringify({
      activado: valor
    }));
  }

  public getActivacion(): boolean {
    const active = JSON.parse(sessionStorage.getItem('activacion'));
    if (active == null) {
      return false;
    } else {
      return active.activado;
    }
  }

  enviarCorreo(esquema, usuario): Observable<any> {
    return this.http.post<any[]>(this.way + '/correos/1b733d976d5bcb000', {
      elementos: {
        nombreesquema: esquema,
        user: usuario
      }
    });
  }

  enviarCorreoToken(esquema, usuario): Observable<any> {
    return this.http.post<any[]>(this.way + '/correos/3aff92b7dc51d8', {
      elementos: {
        nombreesquema: esquema,
        user: usuario
      }
    });
  }
///GSRF
enviarCorreoTokenaws(esquema, usuario): Observable<any> {
  return this.http.post<any[]>(this.way + '/correos/3aff92b7dc51d8aws', {
    elementos: {
      nombreesquema: esquema,
      user: usuario
    }
  });
}
enviarCorreoaws(esquema, usuario): Observable<any> {
  return this.http.post<any[]>(this.way + '/correos/1b733d976d5bcb000aws', {
    elementos: {
      nombreesquema: esquema,
      user: usuario
    }
  });
}
enviarCorreoTokenActAdmaws(esquema, usuario) {
  return this.http.post<any[]>(this.way + '/correos/e3aedee28eba98000000aws', {
    elementos: {
      nombreesquema: esquema,
      user: usuario
    }
  }).toPromise();
}

enviarCorreoActivacionaws(ruc, empresa, cadena) {
  return this.http.post<any[]>(this.way + '/correos/75ff256d4cecc0000000aws', {
    elementos: {
      ruc, empresa, cadena
    }
  }).toPromise();
}
enviarCorreoActivacionawsfe(ruc, empresa, cadena) {
  return this.http.post<any[]>(this.way + '/correos/75ff256d4cecc0000000aws02', {
    elementos: {
      ruc, empresa, cadena
    }
  }).toPromise();
}
///GSRF
//CDPJ
enviarCorreoTokenRvProdAdmaws(esquema, usuario,compr,fechnig,fechaemi,fechaini,fechaterm) {
  return this.http.post<any[]>(this.way + '/correos/3aff92b7dc51d8000000000000000', {
    elementos: {
    nombreesquema: esquema,
    user: usuario,
    comprobante:compr,
    fechahoraing:fechnig,
    fechahoraemi:fechaemi,
    fechahoraini:fechaini,
    fechahoraterm:fechaterm,
    }
  }).toPromise();
}
//CDPJ
  validarToken(clavetoken, usuario): Promise<any> {
    return this.http.post<any[]>(this.way + '/correos/faab26ab7dc5200', {
      elementos: {
        tokenfrontend: clavetoken,
        usuario
      }
    }).toPromise();
  }

  enviarCorreoTokenActAdm(esquema, usuario) {
    return this.http.post<any[]>(this.way + '/correos/e3aedee28eba98000000', {
      elementos: {
        nombreesquema: esquema,
        user: usuario
      }
    }).toPromise();
  }

  enviarCorreoActivacion(ruc, empresa, cadena) {
    return this.http.post<any[]>(this.way + '/correos/75ff256d4cecc0000000', {
      elementos: {
        ruc, empresa, cadena
      }
    }).toPromise();
  }

  validarTokenActAdm(clavetoken, usuario): Promise<any> {
    return this.http.post<any[]>(this.way + '/correos/7d55ee28eba99c00000', {
      elementos: {
        tokenfrontend: clavetoken,
        usuario
      }
    }).toPromise();
  }

  enviarCorreoAccesoIp(ipfrontend, esquema, usuario): Observable<any> {
    return this.http.post<any[]>(this.way + '/correos/3aff92b6a6766000000', {
      elementos: {
        ipfrontend, esquema, usuario
      }
    });
  }

  procesoFirmaElectronica(documento, strFecha): Promise<any> {
    console.log(documento, strFecha);
    return this.http.post(this.way + '/ventas/firmardocumentos/cef0c7730f96f00', {
      elementos: {
        documento,
        strFecha,
        proceso: 'firma'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  procesoFirmaElectronicaTipo(documento, strFecha, tipodoc): Promise<any> {
    console.log(documento, strFecha, tipodoc);
    return this.http.post(this.way + '/ventas/firmardocumentos/cef0c77qwaszx30f96f00', {
      elementos: {
        documento,
        strFecha,
        tipodoc,
        proceso: 'firma'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  continuarFirmaElectronica(documento): Promise<any> { // metodo para actualizar ips    
    return this.http.post(this.way + '/ventas/firmardocumentos/cef0c7730f96f00', {
      elementos: {
        documento,
        proceso: 'continuarfirma'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  continuarFirmaElectronicaTipo(documento, tipo): Promise<any> { // metodo para actualizar ips    
    return this.http.post(this.way + '/ventas/firmardocumentos/cef0c7730f96f00', {
      elementos: {
        documento, tipo,
        proceso: 'continuarfirma'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  reenviarCorreo(documento): Promise<any> { // metodo para actualizar ips    
    return this.http.post(this.way + '/ventas/firmardocumentos/cef0c7730f96f00', {
      elementos: {
        documento,
        proceso: 'correo'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  reenviarCorreoTipo(documento, tipo): Promise<any> { // metodo para actualizar ips    
    return this.http.post(this.way + '/ventas/firmardocumentos/cef0c7730f96f00', {
      elementos: {
        documento, tipo,
        proceso: 'correo'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  reversarProcesoFE(documento, tipo) {
    return this.http.post<any[]>(this.way + '/ventas/firmardocumentos/1b77ddbe2b79de00000000', {
      elementos: {
        p_trndoc_numdoc: documento,
        p_trndoc_tipo: tipo,
        p_com_codigo: '01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  reversarProcesoFEClave(claveacceso) {
    return this.http.post<any[]>(this.way + '/ventas/firmardocumentos/36efbb7c56f3bc00000000', {
      elementos: {
        claveacceso
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async verificarPeriodoContable(intDia, intMes, intAnio) {
    let bolVerificar = false;
    let mesCerrado = 0;
    try {
      const val = await this.getPeriodoContable(intDia, intMes, intAnio);
      mesCerrado = val[0].CONTADOR;
      if (mesCerrado === 1) {
        bolVerificar = true;
      }
    } catch (e) {
      bolVerificar = false;
    }
    return bolVerificar;
  }

  async getPeriodoContable(intDia, intMes, intAnio) {
    let strDia = '';
    let strMes = '';
    let strAnio = '';

    strDia = this.completarFecha(intDia);
    strMes = this.completarFecha(intMes + 1);
    strAnio = this.completarFecha(intAnio);

    return this.http.post<any[]>(this.way + '/ventas/facturas/c2faecbb6cc5f80', {
      elementos: {
        dia: strDia,
        mes: strMes,
        anio: strAnio
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  completarFecha(strFecha) {
    strFecha = strFecha.toString();
    if (strFecha.length === 1) {
      return '0' + strFecha;
    } else {
      return strFecha;
    }
  }

  getrucsri(clicodigo): Promise<any> {
    return this.http.post<any>(this.way + '/advantage/utilitarios/107777e67372', {
      elementos: {
        clicodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async verificaRucWs(rucIde) {
    let r = null;

    try {
      r = await this.getrucsri(rucIde);
    } catch  {
    }

    if (r != null) {
      return true;
    } else {
      return false;
    }
  }


  async ValidaRucAutorizacion(ruc) {
    let nroCedula;
    let estadoRuc;
    let digitosRuc;

    if (ruc.length !== 13) {
      return estadoRuc = 0;
    }

    digitosRuc = ruc.substring(10, 13);
    if (digitosRuc === '001') {
      estadoRuc = 3;
    } else {
      return estadoRuc = 0;
    }

    if (ruc.length <= 0) {
      return estadoRuc = 0;
    }

    nroCedula = ruc.substring(0, 10); // cedula

    if (nroCedula.length === 0 || ruc.length !== 13) {
      return estadoRuc = 0;
    }

    if (ruc.length === 13) {
      if (await this.verificaRucWs(ruc) === true) {
        return estadoRuc = 4;
      }
    }

    return estadoRuc;
  }

  ValidaCedulaAutorizacion(identificacion, tipo): number {
    const numero = identificacion;
    let bollong = false;
    let bolTipo = false;
    if (numero.trim().length === 10 || numero.trim().length === 13) {
      bollong = true;
    }

    if (!bollong) {
      return 0;
    }

    switch (tipo) {
      case 1:
        if (numero.trim().length === 10) {
          bolTipo = true;
        }
        break;

      case 2:
        if (numero.trim().length === 13) {
          bolTipo = true;
        }
        break;
      default:
        break;
    }

    if (!bolTipo) {
      return 0;
    }

    let suma = 0;
    let residuo = 0;
    let pri = false;
    let pub = false;
    let nat = false;
    const numeroProvincias = 24;
    let modulo = 11;
    let digitoVerificador;

    let d1 = 0;
    let d2 = 0;
    let d3 = 0;
    let d4 = 0;
    let d5 = 0;
    let d6 = 0;
    let d7 = 0;
    let d8 = 0;
    let d9 = 0;
    let d10 = 0;
    let p1 = 0;
    let p2 = 0;
    let p3 = 0;
    let p4 = 0;
    let p5 = 0;
    let p6 = 0;
    let p7 = 0;
    let p8 = 0;
    let p9 = 0;
    let state = false;

    for (let i = 0; i < numeroProvincias + 1; i++) {
      if (Number(numero.substring(0, 2)) === i) {
        state = true;
      }

    }
    if (!state) {
      return 3;
    }

    d1 = Number(numero.substring(0, 1));
    d2 = Number(numero.substring(1, 2));
    d3 = Number(numero.substring(2, 3));
    d4 = Number(numero.substring(3, 4));
    d5 = Number(numero.substring(4, 5));
    d6 = Number(numero.substring(5, 6));
    d7 = Number(numero.substring(6, 7));
    d8 = Number(numero.substring(7, 8));
    d9 = Number(numero.substring(8, 9));
    d10 = Number(numero.substring(9, 10));

    if (d3 === 7 || d3 === 8) {
      return 3;
    }

    if (d3 < 6) {
      nat = true;
      p1 = d1 * 2;
      if (p1 >= 10) {
        p1 -= 9;
      }
      p2 = d2 * 1;
      if (p2 >= 10) {
        p2 -= 9;
      }
      p3 = d3 * 2;
      if (p3 >= 10) {
        p3 -= 9;
      }
      p4 = d4 * 1;
      if (p4 >= 10) {
        p4 -= 9;
      }
      p5 = d5 * 2;
      if (p5 >= 10) {
        p5 -= 9;
      }
      p6 = d6 * 1;
      if (p6 >= 10) {
        p6 -= 9;
      }
      p7 = d7 * 2;
      if (p7 >= 10) {
        p7 -= 9;
      }
      p8 = d8 * 1;
      if (p8 >= 10) {
        p8 -= 9;
      }
      p9 = d9 * 2;
      if (p9 >= 10) {
        p9 -= 9;
      }
      modulo = 10;
    }

    if (d3 === 6) {
      pub = true;
      p1 = d1 * 3;
      p2 = d2 * 2;
      p3 = d3 * 7;
      p4 = d4 * 6;
      p5 = d5 * 5;
      p6 = d6 * 4;
      p7 = d7 * 3;
      p8 = d8 * 2;
      p9 = 0;
    }

    if (d3 === 9) {
      pri = true;
      p1 = d1 * 4;
      p2 = d2 * 3;
      p3 = d3 * 2;
      p4 = d4 * 7;
      p5 = d5 * 6;
      p6 = d6 * 5;
      p7 = d7 * 4;
      p8 = d8 * 3;
      p9 = d9 * 2;
    }

    suma = p1 + p2 + p3 + p4 + p5 + p6 + p7 + p8 + p9;
    residuo = suma % modulo;

    digitoVerificador = residuo === 0 ? 0 : modulo - residuo;

    if (pub === true) {
      if (digitoVerificador !== d9) {
        return 3;
      }
      if (numero.substring(9, 14) !== '0001') {
        return 3;
      }
    } else if (pri === true) {
      if (digitoVerificador !== d10) {
        return 3;
      }
      if (!numero.substring(10, 13).equalsIgnoreCase('001')) {
        return 3;
      }
    } else if (nat === true) {
      if (digitoVerificador !== d10) {
        return 3;
      }
      if (numero.length > 10
        && !numero.substring(10, 13).equalsIgnoreCase('001')) {
        return 3;
      }
    }
    return 1;
  }

  obtenerClave(clave): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/pagocontado/jwn7qmo1cc3b6ih', {
      elementos: {},
      datos: {
        Clave: clave
      }
    }, this.confIniciales.httpOptions());
  }

}
