<p-toast [style]="{marginTop: '30px'}" position="top-center" key="segMaeReferenciatoast"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="segmaeReferenciaDialog" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 99%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
						 [botonRegresar]="btnRegresar" [barraBotonesCRUD]="false"  
						 [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true"
													 
						
						 (signal)="manejarSenales($event)"></app-invmaebarra>
	</div>

</div>
<div style="overflow: hidden" class="p-col-12">
	<p-tabView>
		<p-tabPanel header="Referencias">
            <div class="p-grid">
                <div class="p-col-2">
                </div>
                <div class="p-col-1">
                    <span>Tipo: </span>
                </div>
                <div class="p-col-6" style="text-align: left">
                    <p-dropdown [style]="{'width':'100%'}" optionLabel="name" inputId="cmbReferencia"
                                [(ngModel)]="strCmbReferencia" [options]="cmbReferencia" (onChange)="cambioCmbReferencia($event)" [disabled]="disabledSelTodosMod"></p-dropdown>
                </div>
            </div>
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="segMaeReferenciat"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefMaeReferencia"
							[defaultColDef]="defaultColDefSegMaeReferencia"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)=selectMaeSegMaeRefereciat($event)
							(cambios)="cambioModulot($event)"

			></app-aggridsaci>
		</p-tabPanel>
	</p-tabView>
</div>