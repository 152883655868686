import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from 'src/app/usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { DetRolProceso, DetProvProceso, EncRolIndi } from '../nominterfaces/nomencrol';

@Injectable({
  providedIn: 'root'
})
export class NomencrolService {

  private way: string;
  public numcellnomencrol: number;//GSRF

  public AUXPANTVACA = {
    dias_vacaciones: 0.00, //As Double
    valor_vacaciones: 0.00, //As Double
    fecha_inicio: '',  //As String
    fecha_fin: '', //As String
    observacion: '', //As String
    detrolindi_diasvacagoza: 0, //As Integer
    detrolindi_valovacagoza: 0.00, //As Double
    control:false//CDPJ
  }

  public dato = {};

  /* public dato = {
    INGRESO: {
        Ingresos: {
              COMI:   true,
              D13F:   true,
              D134:   true,
              D14CF:  true,
              VACF:   true,
              DEV:    true,
              BON:    true,
              ETI1:   true,
              ETI2:   true,
              SUE:    true,
              GAL:    true,
              OTI:    true,
              UTL:    true,
              FRE:    true,
              D13:    true,
              D14:    true,
              D14C:   true,
              MVL:    true,
              ALIM:   true,
              OIFNG:  true,
              PAG:    true,
              OIFG:   true,
              IIESS:  true,
              2019: true,
          },

        Dias: {
              VACP: true,
              VACG: true
        },

        Horas: {
              H25: true,
              H50: true,
              H100: true
        }
    },
    DESCUENTO: {
        Egresos: {
              PR2: true,
              PCV: true,
              E100: true,
              UNIF: true,
              'IEP-E': true,
              TRI: true,
              OD2: true,


        }
    }
  } */

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) { 
      this.way = sessionStorage.getItem('way');
    }

  
  
  getObjetoValidar(tipo: string, campo: string, codigo: string){
    try {//para evitar errores al tratar de obtener objetos indefinidos
      if(this.dato[tipo][campo][`${codigo}`]){
        return true;
      }
    }catch{
      return false;
    }
    return false;
  }


  separarTipo(rubros = []){
    rubros.map( x => {

      if( this.dato[x.MAERUBRO_TIPO] === undefined ){
        this.dato[x.MAERUBRO_TIPO] = {}
      }

      if( this.dato[x.MAERUBRO_TIPO][x.MAERUBRO_UNIDAD] === undefined ){
        this.dato[x.MAERUBRO_TIPO][x.MAERUBRO_UNIDAD] = {};
      }

      if( this.dato[x.MAERUBRO_TIPO][x.MAERUBRO_UNIDAD][x.MAERUBRO_CODRUBR] === undefined ){
        this.dato[x.MAERUBRO_TIPO][x.MAERUBRO_UNIDAD][x.MAERUBRO_CODRUBR]  = true;
      }      

    } )

  }

  getPeriodoProceso(){
    let strSentencia = `SELECT PERIODO_CODIGO, PERIODO_FECINI, PERIODO_FECFIN FROM nom_maeperiodo WHERE periodo_estado = 'P'`;
  
    return this.http.post<any[]>(this.way + '/nom/rolproceso/107772ede77864000', {
        elementos: {
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  getRubros(){
    let strSentencia = 'select MAERUBRO_TIPO, MAERUBRO_CODRUBR, MAERUBRO_UNIDAD from nom_maerubro';
  
    return this.http.post<any[]>(this.way + '/nom/rolproceso/185f5d776f7e5f000', {
        elementos: {}
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  /* getRubros(){
  
    return this.http.post<any[]>(this.way + '/nom/encrol/0wr1biqz9xjpz31', {
        elementos: {
        },
      }, this.confIniciales.httpOptions()).toPromise();
  } */


  /* rolNavegar(strOpcion, strEncrolindi_estado, strCondicion ){
      return  this.http.post<any[]>(this.url + '/ejecutarproceso', {
        esquema: this.usuarioEsquema,
        elementoEjecutar: 'NOM_pck_ENCROLINDI_c.nom_sp_consultaRolNavegar',
        elementos: {
          p_opcion: strOpcion,
          p_encrolindi_estado: strEncrolindi_estado,
          p_condicion: strCondicion,
        },
      }).toPromise();
  }  */

  rolNavegar(strOpcion, strEncrolindi_estado, strCondicion ){
      return  this.http.post<any[]>(this.way + '/nom/encrol/dasdyk3vq8hzm9e', {
        elementos: {
          p_opcion: strOpcion,
          p_encrolindi_estado: strEncrolindi_estado,
          p_condicion: strCondicion,
        },
      }, this.confIniciales.httpOptions()).toPromise();
  } 


  FunListaTipRol(){
  
    return this.http.post<any[]>(this.way + '/nom/encrol/1onuhoy133hb8n8', {
        elementos: {
        },
      }, this.confIniciales.httpOptions()).toPromise();
  }


  llenaCampos(strCABROLADMN_CODIGO){
    
  
    return this.http.post<any[]>(this.way + '/nom/encrolcerrados/tnkaahz8v4xnlix', {
        elementos: {},
        datos: {
          CABROLADMN_CODIGO: strCABROLADMN_CODIGO
        }
      }, this.confIniciales.httpOptions()).toPromise();

  }

  encRegistroPeriodo(strCampo, strCondicion) {
    
    return this.http.post(this.way + '/nom/encrolcerrados/jlbrzvres5lto9o', {
      elementos: {
        Campo: strCampo,
        Condicion: strCondicion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  encRegistroEmpleado(strCampo, strCondicion) {
    
    return this.http.post(this.way + '/nom/encrolcerrados/mv4hihwfsf191mo', {
      elementos: {
        Campo: strCampo,
        Condicion: strCondicion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encRegistroTipoRol(strCampo, strCondicion) {
    
    return this.http.post(this.way + '/nom/encrolcerrados/735o489o6wsg1i6', {
      elementos: {
        Campo: strCampo,
        Condicion: strCondicion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }



  getDetalleRol(lblENCROLINDI_CODIGO) {
    return this.http.post<any[]>(this.way + '/nom/encrol/31dvwnfmpt9v647', {
        elementos: {
          ENCROLINDI_CODIGO: lblENCROLINDI_CODIGO
        },
      }, this.confIniciales.httpOptions()).toPromise();
  }


  getDetalleProvision(lblENCROLINDI_CODIGO){
  
    return this.http.post<any[]>(this.way + '/nom/encrol/sdxcrmo5aeq3aid', {
        elementos: {
          ENCROLINDI_CODIGO: lblENCROLINDI_CODIGO
        },
      }, this.confIniciales.httpOptions()).toPromise();
  }


  


actudetrolprov(detProvProceso: DetProvProceso ){
  return  this.http.post<any[]>(this.way + '/nom/encrol/yv5em1oxygfjxyp', {
    elementos: {
      p_encrolindi_codigo: detProvProceso.ENCROLINDI_CODIGO,
      p_detrolprov_numsec: detProvProceso.DETROLPROV_NUMSEC,
      p_com_codigo: detProvProceso.COM_CODIGO,
      p_detrolprov_tiporubr: detProvProceso.DETROLPROV_TIPORUBR,
      p_detrolprov_codigo: detProvProceso.DETROLPROV_CODIGO,
      p_detrolprov_descripcion: detProvProceso.DETROLPROV_DESCRIPCION,
      p_detrolprov_tipodet: detProvProceso.DETROLPROV_TIPODET,
      p_detrolprov_horas: detProvProceso.DETROLPROV_HORAS,
      p_detrolprov_dias: detProvProceso.DETROLPROV_DIAS,
      p_detrolprov_base: detProvProceso.DETROLPROV_BASE,
      p_detrolprov_valor: detProvProceso.DETROLPROV_VALOR,
      p_detrolprov_fechfinrubr: detProvProceso.DETROLPROV_FECHFINRUBR,
      p_maerubro_codigo: detProvProceso.MAERUBRO_CODIGO,
      p_maerubro_orden: detProvProceso.MAERUBRO_ORDEN,
    },
  }, this.confIniciales.httpOptions()).toPromise();
} 



  funCalculaValorHorasExtras(txtEMP_SUELDOMES, strCODIGO_RUBRO, dblNUMERO_HORAS){
    
    return this.http.post<any[]>(this.way + '/nom/encrol/yhs5mw912jnz8t9', {
        elementos: {
          EMP_SUELDOMES: txtEMP_SUELDOMES, 
          CODIGO_RUBRO: strCODIGO_RUBRO,
          NUMERO_HORAS: dblNUMERO_HORAS
        }
      }, this.confIniciales.httpOptions()).toPromise();
  }



  funDblObtieValoIessPerso(detrolindi_ingresos_graviess: number, emp_porcaporindi: number){
  
    return this.http.post<any[]>(this.way + '/nom/encrol/nhdqgczx4hn8mtn', {
        elementos: {
          Graviess: detrolindi_ingresos_graviess,
          Porcaporindi: emp_porcaporindi
        },
      }, this.confIniciales.httpOptions()).toPromise();
  }


  funDblObtieValoDeciTerc(detrolindi_ingresos_graviess: number, total_periodos: number ){

    return this.http.post<any[]>(this.way + '/nom/encrol/av3xxgsrk4ag301', {
        elementos: {
          Graviess: detrolindi_ingresos_graviess,
          Periodos: total_periodos
        }
      }, this.confIniciales.httpOptions()).toPromise();
  }
  //GSRF
  funDblObtieValovaca(detrolindi_ingresos_graviess: number, total_periodos: number , vaca: number){
    console.log('<<< vaca >>>',vaca)
    return this.http.post<any[]>(this.way + '/nom/encrol/6aeb85f64efaac000000', {
        elementos: {
          Graviess: detrolindi_ingresos_graviess,
          Periodos: total_periodos,
          Vaca: vaca 
        }
      }, this.confIniciales.httpOptions()).toPromise();
  }
  //GSRF

  funObtieneDiasTrabajados(lblENCROLINDI_CODIGO){
    
    return this.http.post<any[]>(this.way + '/nom/encrol/zaynbl6irtyjgjw', {
        elementos: {
          ENCROLINDI_CODIGO: lblENCROLINDI_CODIGO
        }
      }, this.confIniciales.httpOptions()).toPromise();
  }

  

  obtieneDiasVacacion(txtEMP_FECINGRESO, lblPERIODO_CODIGO, total_periodos, dbl_dias_trabajados ){
    return this.http.post<any[]>(this.way + '/nom/encrol/cdse8zrutesar1p', {
        elementos: {
          EMP_FECINGRESO: txtEMP_FECINGRESO,
          PERIODO_CODIGO : lblPERIODO_CODIGO,
          Periodos : total_periodos,
          Trabajados: dbl_dias_trabajados
        }
      }, this.confIniciales.httpOptions()).toPromise();
  }

  

  obtivalovaca(detrolindi_ingresos_graviess, total_periodos, dbl_dias_vac ){
    
    return this.http.post<any[]>(this.way + '/nom/encrol/88pkq95x0a55ep0', {
        elementos: {
          Graviess: detrolindi_ingresos_graviess,
          Periodos: total_periodos,
          Dias_vac: dbl_dias_vac
        }
      }, this.confIniciales.httpOptions()).toPromise();
  }



  funDblObtieFondRese(detrolindi_ingresos_graviess, emp_porcfondreser, ){
      
      return this.http.post<any[]>(this.way + '/nom/encrol/3rp6hm85za4ezfo', {
          elementos: {
            Graviess: detrolindi_ingresos_graviess,
            Porcfondreser: emp_porcfondreser
          }
        }, this.confIniciales.httpOptions()).toPromise();
  }

  obtiDiasVacaDispo(lblPERIODO_FECINI: string, lblEMP_CODIGO: string){
    return  this.http.post<any[]>(this.way + '/nom/rolproceso/c2fad97d4c53680', {
      elementos: {
        p_periodo_fecini:  lblPERIODO_FECINI, 
        p_emp_codigo: lblEMP_CODIGO, 
        p_com_codigo: '01', 
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

 
  obtiPerioVacaDispo(lblEMP_CODIGO: string){
    return  this.http.post<any[]>(this.way + '/nom/rolproceso/107772edfd4c6d000', {
      elementos: {
        p_emp_codigo: lblEMP_CODIGO, 
        p_com_codigo: '01', 
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtieneVacaTomadasXPeriodo(lblENCROLINDI_CODIGO, lblEMP_CODIGO, lblRUBRO_CODRUBR, txtPERIODO_FECFIN,
    dtpEMP_FECHINI, lblNUM_DIASVACA, txtTRANVACAEMPL_OBSERVACIONES, valor_vacaciones  ){
    return  this.http.post<any[]>(this.way + '/nom/rolproceso/41ddfa99dc5b3800000', { 
      elementos: {
        p_encrolindi_codigo: lblENCROLINDI_CODIGO,
        p_emp_codigo: lblEMP_CODIGO,
        p_maerubro_codrubr: lblRUBRO_CODRUBR,
        p_fecfin_periodo: txtPERIODO_FECFIN,
        p_fecini_vacacion: dtpEMP_FECHINI,
        p_dias_vacaciones: lblNUM_DIASVACA,
        p_valor_vacaciones: valor_vacaciones,
        p_transvacapendempl_obse: txtTRANVACAEMPL_OBSERVACIONES,
        p_com_codigo: '01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  listaVacacionesTomadas(lblENCROLINDI_CODIGO, lblRUBRO_CODRUBR){
      let strSentencia = `select periodo_codigo, fecini_vacacion, fecfin_vacacion,dias_vacatoma,nom_fnc_fechaPeriodo(periodo_codigo) as periodo,vacatoma_importe from
      nom_transvacatomaxperi  WHERE ENCROLINDI_CODIGO= ${lblENCROLINDI_CODIGO} and MAERUBRO_CODRUBR = '${lblRUBRO_CODRUBR}' order by fecini_vacacion`;
    
      return this.http.post<any[]>(this.way + '/nom/rolproceso/15973bf533b8b3000', {
        elementos: {
          ENCROLINDI_CODIGO: lblENCROLINDI_CODIGO,
          RUBRO_CODRUBR: lblRUBRO_CODRUBR
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
    
  }


  guardaObservVacaGozaPagadas(lblENCROLINDI_CODIGO, txtTRANVACAEMPL_OBSERVACIONES){
    return  this.http.post<any[]>(this.way + '/nom/rolproceso/21e56f0be7d4c', {
      elementos: {
        p_encrolindi_codigo: lblENCROLINDI_CODIGO,
        p_transvacapendempl_obse: txtTRANVACAEMPL_OBSERVACIONES,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizaEstaVacaPeriActual(lblPERIODO_CODIGO,  lblEMP_CODIGO){
    return  this.http.post<any[]>(this.way + '/nom/rolproceso/19276fea62b2ee0000', {
      elementos: {
        p_periodo_codigo: lblPERIODO_CODIGO, //number,
        p_emp_codigo:  lblEMP_CODIGO//number
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }


  calculaSueldoDiasTrab(dblEMP_REMSALUNI: number, dblNUMERO_DIAS: number) {
    let strSentencia = `select nom_pck_encrolindi.nom_fnc_obtisueldoprordiasmes( ${dblNUMERO_DIAS}, ${dblEMP_REMSALUNI} ) from dual`;
    
      return this.http.post<any[]>(this.way + '/nom/rolproceso/c5579e755b2ef000', {
          elementos: {
            NUMERO_DIAS: dblNUMERO_DIAS,
            EMP_REMSALUNI: dblEMP_REMSALUNI
          }
        }, this.confIniciales.httpOptions()
      ).toPromise();
  }


  funDblObtieValoIessPatro( detrolindi_ingresos_graviess: number, emp_porcaporpatr: number  ){
    let strSentencia = `select nom_pck_generaliquidacion.nom_fnc_obtiiesspatr(${detrolindi_ingresos_graviess}, ${emp_porcaporpatr}) from dual`;
    
      return this.http.post<any[]>(this.way + '/nom/rolproceso/c2feaac9dce6580', {
          elementos: {
            INGRESOS: detrolindi_ingresos_graviess,
            PORCENTAJE: emp_porcaporpatr
          }
        }, this.confIniciales.httpOptions()
      ).toPromise();
  }


  funCol3Prov(strMAERUBRO_CODRUBR, strMAERUBRO_TIPO){
    let strSentencia = `select maerubro_codigo,maerubro_nombre,maerubro_gravable,maerubro_aporrent,maerubro_proyrent from nom_maerubro 
        where maerubro_codrubr = '${strMAERUBRO_CODRUBR}' and  maerubro_tipo = '${strMAERUBRO_TIPO}'  and com_codigo = '01' `;

      return this.http.post<any[]>(this.way + '/nom/rolproceso/1febb315cef1f', {
          elementos: {
            MAERUBRO_CODRUBR: strMAERUBRO_CODRUBR,
            MAERUBRO_TIPO: strMAERUBRO_TIPO
          }
        }, this.confIniciales.httpOptions()
      ).toPromise();
  }

  funVerificaCodRubroIngrEgreFijo(strMAERUBRO_CODIGO: number, intEMP_CODIGO: number){
    let strSentencia = `select NOM_pck_MAERUBRO_c.nom_fnc_rubrPertIngrEgreFijo(${intEMP_CODIGO}, ${strMAERUBRO_CODIGO}) from dual`;
    
    return this.http.post<any[]>(this.way + '/nom/rolproceso/3eedc9ecc364e80000', {
        elementos: {
          EMP_CODIGO: intEMP_CODIGO,
          MAERUBRO_CODIGO: strMAERUBRO_CODIGO
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  actuDetRolIndi(detRolProceso: DetRolProceso ){
    return  this.http.post<any[]>(this.way + '/nom/rolproceso/533ad77778acb00', {
      elementos: {
        p_encrolindi_codigo: detRolProceso.ENCROLINDI_CODIGO,
        p_detrolindi_numsec: detRolProceso.DETROLINDI_NUMSEC,
        p_com_codigo: '01',
        p_detrolindi_tiporubr: detRolProceso.DETROLINDI_TIPORUBR,
        p_detrolindi_codigo: detRolProceso.DETROLINDI_CODIGO,//
        p_detrolindi_descripcion: detRolProceso.DETROLINDI_DESCRIPCION,//
        p_detrolindi_tipodet: detRolProceso.DETROLINDI_TIPODET,//
        p_detrolindi_horas: Number(detRolProceso.DETROLINDI_HORAS),//
        p_detrolindi_dias: Number(detRolProceso.DETROLINDI_DIAS),
        p_detrolindi_ingresos: Number(detRolProceso.DETROLINDI_INGRESOS),//
        p_detrolindi_egresos: Number(detRolProceso.DETROLINDI_EGRESOS),//
        p_detrolindi_fechfinrubr: (detRolProceso.DETROLINDI_FECHFINRUBR === null) ? '' :  detRolProceso.DETROLINDI_FECHFINRUBR,
        p_maerubro_codigo: detRolProceso.MAERUBRO_CODIGO,
        p_maerubro_orden: detRolProceso.MAERUBRO_ORDEN,
        p_detrolindi_fechinirubr: (detRolProceso.DETROLINDI_FECHINIRUBR === null) ? '' :  detRolProceso.DETROLINDI_FECHINIRUBR,
        p_detrolindi_diasvacagoza: detRolProceso.DETROLINDI_DIASVACAGOZA,
        p_detrolindi_valovacagoza: detRolProceso.DETROLINDI_VALOVACAGOZA,
        p_tranvacaempl_observaciones: detRolProceso.TRANVACAEMPL_OBSERVACIONES
      },
    }, this.confIniciales.httpOptions()).toPromise();
  } 

  actuDetRolProv(detProvProceso: DetProvProceso ){
    return  this.http.post<any[]>(this.way + '/nom/rolproceso/a675aeeef15cf000', {
      elementos: {
        p_encrolindi_codigo: detProvProceso.ENCROLINDI_CODIGO,
        p_detrolprov_numsec: detProvProceso.DETROLPROV_NUMSEC,
        p_com_codigo: '01' ,     
        p_detrolprov_tiporubr: detProvProceso.DETROLPROV_TIPORUBR,
        p_detrolprov_codigo: detProvProceso.DETROLPROV_CODIGO, 
        p_detrolprov_descripcion: detProvProceso.DETROLPROV_DESCRIPCION,
        p_detrolprov_tipodet: detProvProceso.DETROLPROV_TIPODET,
        p_detrolprov_horas: Number(detProvProceso.DETROLPROV_HORAS),
        p_detrolprov_dias: Number(detProvProceso.DETROLPROV_DIAS),
        p_detrolprov_base: Number(detProvProceso.DETROLPROV_BASE),
        p_detrolprov_valor: Number(detProvProceso.DETROLPROV_VALOR),
        p_detrolprov_fechfinrubr: (detProvProceso.DETROLPROV_FECHFINRUBR === null) ? '' :  detProvProceso.DETROLPROV_FECHFINRUBR,
        p_maerubro_codigo:  detProvProceso.MAERUBRO_CODIGO,
        p_maerubro_orden:  detProvProceso.MAERUBRO_ORDEN,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  } 


  actuInfoAdicRubrRol(strEncrolindi_codigo: string){
    return  this.http.post<any[]>(this.way + '/nom/rolproceso/533b2bbf7e5ef00', {
      elementos: {
        p_encrolindi_codigo: strEncrolindi_codigo 
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actuInfoAdicRubrProv(strEncrolindi_codigo: string){
    return  this.http.post<any[]>(this.way + '/nom/rolproceso/a676577efcbcf000', {
      elementos: {
        p_encrolindi_codigo: strEncrolindi_codigo 
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actuInfoEncRolIndi(  encRolIndi: EncRolIndi){
    return  this.http.post<any[]>(this.way + '/nom/rolproceso/14cecaef75d9bc0000', {
      elementos: {
        p_encrolindi_codigo: encRolIndi.codigo, // nom_encrolindi.encrolindi_codigo%type,
        p_com_codigo : '01',
        p_detrolindi_ing_graviess :  encRolIndi.detrolindi_ingresos_graviess,  //FLOAT,
        p_emp_porcaporindi: encRolIndi.emp_porcaporindi,
        p_total_periodos: encRolIndi.total_periodos,  //integer,
        p_emp_porcfondreser: encRolIndi.emp_porcfondreser,
        p_emp_porcaporpatr: encRolIndi.emp_porcaporpatr,
        p_cfgctbl_salariobasico: encRolIndi.cfgctbl_salariobasico,  // float,
        p_detrolprov_basedeciterc: encRolIndi.detrolprov_basedeciterc,  // float,
        p_detrolprov_basedecicuar: encRolIndi.detrolprov_basedecicuar,  // float,
        p_detrolprov_basedecivaca: encRolIndi.detrolprov_basevaca,  // float,
        p_detrolprov_basefondrese: encRolIndi.detrolprov_basefondrese,  // float,
        p_detrolprov_baseiesspatr: encRolIndi.detrolprov_baseiesspatr,  // float
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizaDecimalesBaseDatos(codigo){
    return  this.http.post<any[]>(this.way + '/nom/rolproceso/533be5564d73180', {
      elementos: {
        p_encrolindi_codigo: codigo, // nom_encrolindi.encrolindi_codigo%type,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  funActuInfoVacaRol(codigo){
    return  this.http.post<any[]>(this.way + '/nom/rolproceso/a677df53378ac800', {
      elementos: {
        p_encrolindi_codigo: codigo,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  /* actuInfoVacaGozadaPagada(codigo){
    return  this.http.post<any[]>(this.url + '/ejecutarprocesonoout', {
      esquema: this.usuarioEsquema,
      elementoEjecutar: '',
      elementos: {
        p_encrolindi_codigo: codigo,
        p_com_codigo: '01'
      },
    }).toPromise();

  } */

  elimVacaPorNumRolIndi(codigo){
    return  this.http.post<any[]>(this.way + '/nom/rolproceso/3ab2fa999c6efe00000', {
      elementos: {
        p_encrolindi_codigo: codigo,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtiSumDiasProrXPeri(strEmpCodigo){
    return  this.http.post<any[]>(this.way + '/nom/rolproceso/185f79eb3659cc000', {
      elementos: {
        p_emp_codigo: Number(strEmpCodigo),
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtiInfoVacGozPagXEmpl(codigo){
    return  this.http.post<any[]>(this.way + '/nom/rolproceso/30becaeffa990c0000', {
      elementos: {
        p_encrolindi_codigo: codigo,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  inserVacacionEmple(strMaerubro_codrubr, periodo, str_feciniperi, str_fecfinperi, dias: number, valor: number,
    int_periodo_codigo, EMP_CODIGO, encrolindi_codigo, fec_inivaca, fec_finvaca, observacion, numsec: number   ){
      console.log(  'ingresa inserVacacionEmple', strMaerubro_codrubr, periodo, str_feciniperi, str_fecfinperi, dias, valor,
      int_periodo_codigo, EMP_CODIGO, encrolindi_codigo, fec_inivaca, fec_finvaca, observacion  )
    return  this.http.post<any[]>(this.way + '/nom/rolproceso/95f8efa98eb6680', {
      elementos: {
        p_com_codigo: '01' , 
        p_tranvacaempl_tipo: strMaerubro_codrubr ,
        p_tranvacaempl_periodo: periodo,
        p_tranvacaempl_feciniperi: str_feciniperi,
        p_tranvacaempl_fecfinperi: str_fecfinperi,
        p_tranvacaempl_diasperi: 0,
        p_tranvacaempl_diasprorr: (dias * - 1),
        p_tranvacaempl_baseacum: 0,
        p_tranvacaempl_valor: (valor * -1),
        p_periodo_codigo: int_periodo_codigo,
        p_emp_codigo: EMP_CODIGO,
        p_encrolindi_codigo: encrolindi_codigo,
        p_tranvacaempl_estado: "ABIERTO",
        p_tranvacaempl_fechsalivaca: fec_inivaca,
        p_tranvacaempl_fechregrvaca: fec_finvaca,
        p_tranvacaempl_observaciones: observacion,
        p_encantemp_nrodocumento: '',
        p_detrolindi_numsec: numsec
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  funActuSaldoVacaGozadaPagada(strEncrolindi_codigo, strEmp_codigo ){
    return  this.http.post<any[]>(this.way + '/nom/rolproceso/533bc555bf53140', {
      elementos: {
        p_encrolindi_codigo: strEncrolindi_codigo,
        p_emp_codigo: strEmp_codigo,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  funGeneraAuditoriaRolPagos(strEncrolindi_codigo  ){
    return  this.http.post<any[]>(this.way + '/nom/rolproceso/20ebabcd96f15c0000', {
      elementos: {
        p_encrolindi_codigo: strEncrolindi_codigo , 
        p_usuidentificacion: this.usuario.identificacion, // varchar2,
        p_com_codigo: '01', // varchar2,
        p_aud_operacion: "GENE-LIQU-PROC-SISTEMA", // varchar2
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }


  elimDetRolIndi(strEncrolindi_codigo, strDetrolindi_numsec ){
    return  this.http.post<any[]>(this.way + '/nom/rolproceso/7564d77778acb00', {
      elementos: {
        p_encrolindi_codigo: strEncrolindi_codigo,
        p_detrolindi_numsec: strDetrolindi_numsec
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  elimDetRolprov(strEncrolindi_codigo, strDetrolindi_numsec){
    return  this.http.post<any[]>(this.way + '/nom/rolproceso/7564d77778cef00', {
      elementos: {
        p_encrolindi_codigo: strEncrolindi_codigo,
        p_detrolindi_numsec: strDetrolindi_numsec
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }


  elimiRegiRol(periodo_codigo, emp_codigo){
    return  this.http.post<any[]>(this.way + '/nom/rolproceso/7565696dd096f00', {
      elementos: {
        p_periodo_codigo: periodo_codigo ,
        p_emp_codigo: emp_codigo,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }


  

}



