//
// SACI WEB
// Rutina: Ingreso de Datos Anexos - Facturas de Proveedores
// Propietario: Victor Perez
// Modificado por: VP
// Fecha de creación: 28-02-2020
// Fecha de Modificación: 23-06-2020
//

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { ComEncfacpro } from '../cominterfaces/comencfacpro';
import { ComTrnformapago } from '../cominterfaces/comtrnformapago';
import { DatosanexosService } from '../comservicios/datosanexos.service';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
// Ag-grid
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';

@Component({
  selector: 'app-datosanexos',
  templateUrl: './datosanexos.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})

export class DatosanexosComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  @Input() strnumfac: string;
  @Output() salir = new EventEmitter<boolean>();
  @Input() strprocodigo: string;
  @Input() strvisto: boolean;
  @Input() strOrigen: string;
  datosFP: ComEncfacpro;
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  barraBotones2: boolean;

  booleanRegimen: boolean;
  booleanParaiso: boolean;
  booleanRegimenP: boolean;
  boolcmbtiporegimen: boolean;//CDPJ
  nuevoitem: ComTrnformapago//CDPJ
  consulta: string;

  dscSeleccionado: string;
  valorDscValor: number;
  valorDscPorcentaje: number;
  btnRegresar: boolean;

  displayDialogBusqueda: boolean;
  displayDialogBusquedaFast: boolean;

  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];
  tabla: string;
  where: string;
  opcionbusqueda: string;

  index: number;

  groupCompRet: boolean;
  bolPagoRegFiscal: boolean;

  formapagoSelected: ComTrnformapago;

  cmbtiporegimen: any[];
  booleandenomi:boolean; //CDPJ
  selecttiporegimen: any;//CDPJ
  cmbtiporegimenaux: any[];
  // cmbconvdobletrib: any[];
  cmbconvdobletribaux: any[];
  selectconvdobletrib: any;
  // cmbpagoexterior: any[];
  cmbpagoexterioraux: any[];
  selectpagoexterior: any;
  // cmbpagoregfiscal: any[];
  cmbpagoregfiscalaux: any[];
  selectpagoregfiscal: any;

  largo: string;
  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  defaultColDef;

  columnDefs = [
    { // 0
      headerName: 'Código', field: 'TRNFORMAPAGO_CODIGO', cellEditor: 'cellPrueba', width: 80,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Descripción', field: 'TRNFORMAPAGO_DESCRIPCION',
      // cellEditor: 'cellPrueba',
      width: 300,
      editable: false
      /*cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 25
          }
        }*/
    }
  ];

  constructor(private busqService: InvbusquedaService, public datosanexService: DatosanexosService,
    private messageService: MessageService, private confirmationService: ConfirmationService,
    private datePipe: DatePipe) {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultColDef = {
      editable: true,
      width: 100,
      objeto: 'datosanexos'
    };
  }

  ngOnInit() {
    this.largo = '500';
    this.index = 0;

    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = true;
    this.botonRegresar = true;
    this.barraBotones2 = true;

    this.groupCompRet = false;
    this.bolPagoRegFiscal = false;

    this.datosFP = {
      ENCFACPRO_NUMERO: '',
      COM_CODIGO: '',
      ENCFACPRO_FECHAEMISION: '',
      ENCFACPRO_FECHAVENCIMIENTO: '',
      ENCFACPRO_IVA: '',
      ENCFACPRO_ESTADO: '',
      PRO_CODIGO: '',
      PRO_NOMBRE: '',
      ENCFACPRO_CONTACTO: '',
      ENCFACPRO_REFERENCIA: '',
      ENCFACPRO_TOTAL: '',
      ENCFACPRO_TOTALNETO: '',
      ENCFACPRO_VALORDES: '',
      ENCFACPRO_PORCEDES: '',
      ENCFACPRO_VALORIVA: '',
      ENCFACPRO_PORCEIVA: '',
      ENCFACPRO_VALORICE: '',
      ENCFACPRO_BASEIVA: '',
      ENCFACPRO_BASEICE: '',
      ENCFACPRO_BASECERO: '',
      ENCFACPRO_COMENTARIO: '',
      ENCFACPRO_OTROS: '',
      ASI_NRO: '',
      ENCFACPRO_FECHAREC: '',
      ENCFACPRO_DISTCARGOS: '',
      ENCFACPRO_NUMDIASPLAZO: '',
      ENCFACPRO_IDCRETRI: '',
      ENCFACPRO_IDCRETRIDET: '',
      ENCFACPRO_SERIE: '',
      ENCFACPRO_AUTORIZACION: '',
      ENCFACPRO_TIPCOM: '',
      ENCFACPRO_TIPCOMDET: '',
      ENCFACPRO_MONTIVAPRESER: '',
      ENCFACPRO_PORIVAPRESER: '',
      ENCFACPRO_MONTRETPRESER: '',
      ENCFACPRO_MONTIVATRABIE: '',
      ENCFACPRO_PORIVATRABIE: '',
      ENCFACPRO_MONTRETTRABIE: '',
      MAEMOTIVO_CODIGO: '',
      ENCFACPRO_FECHACADFAC: '',
      ENCFACPRO_FLAG: '',
      ENCFACPRO_NUMINGRESO: '',
      ENCFACPRO_NUMLIQUIDACION: '',
      ENCFACPRO_FORMAPAGO: '',
      ENCFACPRO_TIPODES: '',
      USU_IDENTIFICACION: '',
      ENCFACPRO_GASTO: '',
      ENCORDCOM_NUMERO: '',
      ENCFACPRO_PAGOLOCALEXT: '',
      ENCFACPRO_PAISPAGO: '',
      ENCFACPRO_CONVDOBLETRIB: '',
      ENCFACPRO_PAGOEXTERIOR: '',
      ENCFACPRO_RETSERIEEST: '',
      ENCFACPRO_RETSERIEPTOEMI: '',
      ENCFACPRO_RETSECUENCIAL: '',
      ENCFACPRO_RETAUTORIZACION: '',
      ENCFACPRO_RETFECHA: '',
      ENCFACPRO_ESTADO_FE: '',
      ENCFACPRO_AUTORIZACION_FE: '',
      ENCFACPRO_CLAVEACCESO_FE: '',
      ENCFACPRO_NOOBJETOIVA: '',
      ENCFACPRO_NUMRETENCION: '',
      ENCFACPRO_LOCALIZACIONXML: '',
      ENCFACPRO_LOCALIZACIONPDF: '',
      ENCFACPRO_FECHAAUTRET_FE: '',
      ENCFACPRO_LOCALIZACIONXMLRET: '',
      ENCFACPRO_PATHXMLRET: '',
      COM_TIPOAMBFACTELEC: '',
      ENCFACPRO_PATHXMLNOAUTO_FE: '',
      ENCFACPRO_PATHPDF_FE: '',
      ENCFACPRO_BLOQUEFACXML: '',
      ENCFACPRO_BASENOOBJIVA: '',
      ENCFACPRO_PAGOREGFISCAL: '',
      ENCFACPRO_TIPOREGFISCAL: '',
      ENCFACPRO_CODPAISREGGEN: '',
      ENCFACPRO_CODPAISPARFIS: '',
      ENCFACPRO_DENOMINACION: '',
      ENCFACPRO_RUCTRANSPORTISTA: '',
      ENCFACPRO_RAZONTRANSPORTISTA: '',
      ENCFACPRO_REGIMENTRANSPOR: '',
      ENCFACPRO_ESTADO_FELIQ: '',
      ENCFACPRO_AUTORIZACION_FELIQ: '',
      ENCFACPRO_CLAVEACCESO_FELIQ: '',
      TRNFORMAPAGO_CODIGO: '',
      ENCFACPRO_FECHAAUT_FELIQ: '',
      PRO_TIPOID: ''
    };

    this.llenarCombos();
  }

  /*ngOnChanges(changes: SimpleChanges): void {
    for (const e in changes) {
      if (e === 'strnumfac' && this.strnumfac !== null) {
        this.cargarDatos();
      }
    }
  }*/
  //CDPJ
  //CDPJ
  async consultarfpPRO() {
    const fp = await this.datosanexService.ejecutarConsultaFpPro(this.strprocodigo);
    if (fp !== null && fp !== undefined){
      this.botonGuardar = false;
      for (const frp of fp){
        this.nuevofp();
      const item: ComTrnformapago=this.nuevoitem;
      item.ENCFACPRO_NUMERO= this.strnumfac;
      item.COM_CODIGO= '01';
      item.TRNFORMAPAGO_CODIGO= frp.TRNFORMAPAGO_CODIGO ===  null  || frp.TRNFORMAPAGO_CODIGO === undefined?'':frp.TRNFORMAPAGO_CODIGO;
      item.TRNFORMAPAGO_DESCRIPCION= frp.TRNFORMAPAGO_DESCRIPCION === null || frp.TRNFORMAPAGO_DESCRIPCION === undefined?'':frp.TRNFORMAPAGO_DESCRIPCION;
      }     
    }
    this.aggrid.refreshaggrid(this.datosanexService.formapago, this.defaultColDef.objeto);
  }
  //CDPJ
  async cargarDatospro(){
    this.botonGuardar=false;
    try {
      const data = await this.datosanexService.getAnexospro(this.strprocodigo);
        for (const rs of data) {
          //this.datosFP = rs;
          this.datosFP.ENCFACPRO_PAGOLOCALEXT    =   rs.ENCFACPRO_PAGOLOCALEXT
          this.datosFP.ENCFACPRO_TIPOREGFISCAL   =   rs.ENCFACPRO_TIPOREGFISCAL
          this.datosFP.ENCFACPRO_CODPAISREGGEN   =   rs.ENCFACPRO_CODPAISREGGEN
          this.datosFP.ENCFACPRO_CODPAISPARFIS   =   rs.ENCFACPRO_CODPAISPARFIS
          this.datosFP.ENCFACPRO_DENOMINACION    =   rs.ENCFACPRO_DENOMINACION
          this.datosFP.ENCFACPRO_PAISPAGO        =   rs.ENCFACPRO_PAISPAGO
          this.datosFP.ENCFACPRO_CONVDOBLETRIB   =   rs.ENCFACPRO_CONVDOBLETRIB
          this.datosFP.ENCFACPRO_PAGOEXTERIOR    =   rs.ENCFACPRO_PAGOEXTERIOR
          this.datosFP.ENCFACPRO_PAGOREGFISCAL   =   rs.ENCFACPRO_PAGOREGFISCAL
  
          if (this.datosFP.ENCFACPRO_TIPOREGFISCAL === null) {
            this.datosFP.ENCFACPRO_TIPOREGFISCAL = '';
          }
  
          if (this.datosFP.ENCFACPRO_CONVDOBLETRIB === null) {
            this.datosFP.ENCFACPRO_CONVDOBLETRIB = '';
          }
  
          if (this.datosFP.ENCFACPRO_PAGOEXTERIOR === null) {
            this.datosFP.ENCFACPRO_PAGOEXTERIOR = '';
          }
  
          if (this.datosFP.ENCFACPRO_PAGOREGFISCAL === null) {
            this.datosFP.ENCFACPRO_PAGOREGFISCAL = '';
          }
  
          for (const j of this.cmbtiporegimenaux) {
            if (this.datosFP.ENCFACPRO_TIPOREGFISCAL === j.codigo) {
              //this.cmbtiporegimen = [{codigo: j.codigo, name: j.name}];
              //CDPJ
              this.selecttiporegimen = { codigo: j.codigo, name: j.name };//CDPJ
              //console.log('si1',this.datosFP.ENCFACPRO_PAGOLOCALEXT)
              if (this.datosFP.ENCFACPRO_PAGOLOCALEXT === '02') {
                // console.log('si',this.datosFP.ENCFACPRO_PAGOLOCALEXT)
                this.booleanRegimen = false;
              } else {
                //console.log('bol')
                this.booleanRegimen = true;
              }
              //console.log('regselect',this.selecttiporegimen);      
              //CDPJ
            }
          }
  
          for (const j of this.cmbconvdobletribaux) {
            if (this.datosFP.ENCFACPRO_CONVDOBLETRIB === j.codigo) {
              // this.cmbconvdobletrib = [{codigo: j.codigo, name: j.name}];
              this.selectconvdobletrib = { codigo: j.codigo, name: j.name };
            }
          }
  
          for (const j of this.cmbpagoexterioraux) {
            if (this.datosFP.ENCFACPRO_PAGOEXTERIOR === j.codigo) {
              // this.cmbpagoexterior = [{codigo: j.codigo, name: j.name}];
              this.selectpagoexterior = { codigo: j.codigo, name: j.name };
            }
          }
  
          for (const j of this.cmbpagoregfiscalaux) {
            if (this.datosFP.ENCFACPRO_PAGOREGFISCAL === j.codigo) {
              // this.cmbpagoregfiscal = [{codigo: j.codigo, name: j.name}];
              this.selectpagoregfiscal = { codigo: j.codigo, name: j.name };
            }
          }
  
          this.habilitar('CARGAR');
  
          let er: any;
          er = await this.datosanexService.encontrarCodSriDescPromise('CODSRI_CODIGO=\'' +
            this.datosFP.ENCFACPRO_PAGOLOCALEXT + '\'' + ' AND CODSRI_TIPO=\'LE\'');
          if (er !== null) {
            if (er[0] !== undefined) {
              this.datosFP.ENCFACPRO_PAGOLOCALEXTDET = er[0].CODSRI_DESCRIPCION;
            }
          }
  
  
          er = await this.datosanexService.encontrarCodSriDescPromise('CODSRI_CODIGO=\'' +
            this.datosFP.ENCFACPRO_CODPAISREGGEN + '\'' + ' AND CODSRI_TIPO=\'RG\'');
          if (er !== null) {
            if (er[0] !== undefined) {
              this.datosFP.ENCFACPRO_CODPAISREGGENDET = er[0].CODSRI_DESCRIPCION;
            }
          }
  
          er = await this.datosanexService.encontrarCodSriDescPromise('CODSRI_CODIGO=\'' +
            this.datosFP.ENCFACPRO_CODPAISPARFIS + '\'' + ' AND CODSRI_TIPO=\'PG\'');
          if (er !== null) {
            if (er[0] !== undefined) {
              this.datosFP.ENCFACPRO_CODPAISPARFISDET = er[0].CODSRI_DESCRIPCION;
            }
          }
  
          er = await this.datosanexService.encontrarCodSriDescPromise('CODSRI_CODIGO=\'' +
            this.datosFP.ENCFACPRO_PAISPAGO + '\'' + ' AND CODSRI_TIPO=\'PA\'');
          if (er !== null) {
            if (er[0] !== undefined) {
              this.datosFP.ENCFACPRO_PAISPAGODET = er[0].CODSRI_DESCRIPCION;
            }
          }
  
          //this.consultarfp();
        }
        console.log(this.datosFP)
    } catch (error) {
      
    }
    
  }
  //CDPJ
  async cargarDatos() {
    if (this.strOrigen === 'COM'){
      try {
        const data = await this.datosanexService.getEncfacpro(this.strnumfac);
        for (const rs of data) {
          this.datosFP = rs;
  
  
          if (this.datosFP.ENCFACPRO_TIPOREGFISCAL === null) {
            this.datosFP.ENCFACPRO_TIPOREGFISCAL = '';
          }
  
          if (this.datosFP.ENCFACPRO_CONVDOBLETRIB === null) {
            this.datosFP.ENCFACPRO_CONVDOBLETRIB = '';
          }
  
          if (this.datosFP.ENCFACPRO_PAGOEXTERIOR === null) {
            this.datosFP.ENCFACPRO_PAGOEXTERIOR = '';
          }
  
          if (this.datosFP.ENCFACPRO_PAGOREGFISCAL === null) {
            this.datosFP.ENCFACPRO_PAGOREGFISCAL = '';
          }
  
          for (const j of this.cmbtiporegimenaux) {
            if (this.datosFP.ENCFACPRO_TIPOREGFISCAL === j.codigo) {
              //this.cmbtiporegimen = [{codigo: j.codigo, name: j.name}];
              //CDPJ
              this.selecttiporegimen = { codigo: j.codigo, name: j.name };//CDPJ
              //console.log('si1',this.datosFP.ENCFACPRO_PAGOLOCALEXT)
              if (this.datosFP.ENCFACPRO_PAGOLOCALEXT === '02') {
                // console.log('si',this.datosFP.ENCFACPRO_PAGOLOCALEXT)
                this.booleanRegimen = false;
              } else {
                //console.log('bol')
                this.booleanRegimen = true;
              }
              //console.log('regselect',this.selecttiporegimen);      
              //CDPJ
            }
          }
  
          for (const j of this.cmbconvdobletribaux) {
            if (this.datosFP.ENCFACPRO_CONVDOBLETRIB === j.codigo) {
              // this.cmbconvdobletrib = [{codigo: j.codigo, name: j.name}];
              this.selectconvdobletrib = { codigo: j.codigo, name: j.name };
            }
          }
  
          for (const j of this.cmbpagoexterioraux) {
            if (this.datosFP.ENCFACPRO_PAGOEXTERIOR === j.codigo) {
              // this.cmbpagoexterior = [{codigo: j.codigo, name: j.name}];
              this.selectpagoexterior = { codigo: j.codigo, name: j.name };
            }
          }
  
          for (const j of this.cmbpagoregfiscalaux) {
            if (this.datosFP.ENCFACPRO_PAGOREGFISCAL === j.codigo) {
              // this.cmbpagoregfiscal = [{codigo: j.codigo, name: j.name}];
              this.selectpagoregfiscal = { codigo: j.codigo, name: j.name };
            }
          }
  
          this.habilitar('CARGAR');
  
          let er: any;
          er = await this.datosanexService.encontrarCodSriDescPromise('CODSRI_CODIGO=\'' +
            this.datosFP.ENCFACPRO_PAGOLOCALEXT + '\'' + ' AND CODSRI_TIPO=\'LE\'');
          if (er !== null) {
            if (er[0] !== undefined) {
              this.datosFP.ENCFACPRO_PAGOLOCALEXTDET = er[0].CODSRI_DESCRIPCION;
            }
          }
  
  
          er = await this.datosanexService.encontrarCodSriDescPromise('CODSRI_CODIGO=\'' +
            this.datosFP.ENCFACPRO_CODPAISREGGEN + '\'' + ' AND CODSRI_TIPO=\'RG\'');
          if (er !== null) {
            if (er[0] !== undefined) {
              this.datosFP.ENCFACPRO_CODPAISREGGENDET = er[0].CODSRI_DESCRIPCION;
            }
          }
  
          er = await this.datosanexService.encontrarCodSriDescPromise('CODSRI_CODIGO=\'' +
            this.datosFP.ENCFACPRO_CODPAISPARFIS + '\'' + ' AND CODSRI_TIPO=\'PG\'');
          if (er !== null) {
            if (er[0] !== undefined) {
              this.datosFP.ENCFACPRO_CODPAISPARFISDET = er[0].CODSRI_DESCRIPCION;
            }
          }
  
          er = await this.datosanexService.encontrarCodSriDescPromise('CODSRI_CODIGO=\'' +
            this.datosFP.ENCFACPRO_PAISPAGO + '\'' + ' AND CODSRI_TIPO=\'PA\'');
          if (er !== null) {
            if (er[0] !== undefined) {
              this.datosFP.ENCFACPRO_PAISPAGODET = er[0].CODSRI_DESCRIPCION;
            }
          }
  
          this.consultarfp();
        }
      } catch (error) {
  
      }
    }
    if (this.strOrigen === 'CXP'){
      try {
        const data = await this.datosanexService.getEncCxp2(this.strnumfac);
        for (const rs of data) {
          this.datosFP = rs;
  
  
          if (this.datosFP.ENCFACPRO_TIPOREGFISCAL === null) {
            this.datosFP.ENCFACPRO_TIPOREGFISCAL = '';
          }
  
          if (this.datosFP.ENCFACPRO_CONVDOBLETRIB === null) {
            this.datosFP.ENCFACPRO_CONVDOBLETRIB = '';
          }
  
          if (this.datosFP.ENCFACPRO_PAGOEXTERIOR === null) {
            this.datosFP.ENCFACPRO_PAGOEXTERIOR = '';
          }
  
          if (this.datosFP.ENCFACPRO_PAGOREGFISCAL === null) {
            this.datosFP.ENCFACPRO_PAGOREGFISCAL = '';
          }
  
          for (const j of this.cmbtiporegimenaux) {
            if (this.datosFP.ENCFACPRO_TIPOREGFISCAL === j.codigo) {
              //this.cmbtiporegimen = [{codigo: j.codigo, name: j.name}];
              //CDPJ
              this.selecttiporegimen = { codigo: j.codigo, name: j.name };//CDPJ
              //console.log('si1',this.datosFP.ENCFACPRO_PAGOLOCALEXT)
              if (this.datosFP.ENCFACPRO_PAGOLOCALEXT === '02') {
                // console.log('si',this.datosFP.ENCFACPRO_PAGOLOCALEXT)
                this.booleanRegimen = false;
              } else {
                //console.log('bol')
                this.booleanRegimen = true;
              }
              //console.log('regselect',this.selecttiporegimen);      
              //CDPJ
            }
          }
  
          for (const j of this.cmbconvdobletribaux) {
            if (this.datosFP.ENCFACPRO_CONVDOBLETRIB === j.codigo) {
              // this.cmbconvdobletrib = [{codigo: j.codigo, name: j.name}];
              this.selectconvdobletrib = { codigo: j.codigo, name: j.name };
            }
          }
  
          for (const j of this.cmbpagoexterioraux) {
            if (this.datosFP.ENCFACPRO_PAGOEXTERIOR === j.codigo) {
              // this.cmbpagoexterior = [{codigo: j.codigo, name: j.name}];
              this.selectpagoexterior = { codigo: j.codigo, name: j.name };
            }
          }
  
          for (const j of this.cmbpagoregfiscalaux) {
            if (this.datosFP.ENCFACPRO_PAGOREGFISCAL === j.codigo) {
              // this.cmbpagoregfiscal = [{codigo: j.codigo, name: j.name}];
              this.selectpagoregfiscal = { codigo: j.codigo, name: j.name };
            }
          }
  
          this.habilitar('CARGAR');
  
          let er: any;
          er = await this.datosanexService.encontrarCodSriDescPromise('CODSRI_CODIGO=\'' +
            this.datosFP.ENCFACPRO_PAGOLOCALEXT + '\'' + ' AND CODSRI_TIPO=\'LE\'');
          if (er !== null) {
            if (er[0] !== undefined) {
              this.datosFP.ENCFACPRO_PAGOLOCALEXTDET = er[0].CODSRI_DESCRIPCION;
            }
          }
  
  
          er = await this.datosanexService.encontrarCodSriDescPromise('CODSRI_CODIGO=\'' +
            this.datosFP.ENCFACPRO_CODPAISREGGEN + '\'' + ' AND CODSRI_TIPO=\'RG\'');
          if (er !== null) {
            if (er[0] !== undefined) {
              this.datosFP.ENCFACPRO_CODPAISREGGENDET = er[0].CODSRI_DESCRIPCION;
            }
          }
  
          er = await this.datosanexService.encontrarCodSriDescPromise('CODSRI_CODIGO=\'' +
            this.datosFP.ENCFACPRO_CODPAISPARFIS + '\'' + ' AND CODSRI_TIPO=\'PG\'');
          if (er !== null) {
            if (er[0] !== undefined) {
              this.datosFP.ENCFACPRO_CODPAISPARFISDET = er[0].CODSRI_DESCRIPCION;
            }
          }
  
          er = await this.datosanexService.encontrarCodSriDescPromise('CODSRI_CODIGO=\'' +
            this.datosFP.ENCFACPRO_PAISPAGO + '\'' + ' AND CODSRI_TIPO=\'PA\'');
          if (er !== null) {
            if (er[0] !== undefined) {
              this.datosFP.ENCFACPRO_PAISPAGODET = er[0].CODSRI_DESCRIPCION;
            }
          }
  
          this.consultarfpCXP();
        }
      } catch (error) {
  
      }
    }
  }

  async manejarSenales(valor) {
    if (valor === 'Guardar') {
      console.log('origen',this.strOrigen)
      if(this.strOrigen === 'COM'){
        this.guardarRegistro();
      }
      if(this.strOrigen === 'CXP'){
        this.guardarRegistroCXP()
      }
    }

    if (valor === 'Cancelar') {
      this.cargarDatos();
    }

    if (valor === 'Salir') {
      if(this.strOrigen === 'COM'){
        await this.consultarfpS();//CDPJ
      }
      if(this.strOrigen === 'CXP'){
        await this.consultarfpScxp();//CDPJ
      }
      
    }
    if (valor === 'Visto') {
     
      if (this.index === 0) {
        await this.cargarDatospro()//CDPJ
      }
      if (this.index === 1) {
        this.consultarfpPRO()
      }
    }
  }

  async guardarRegistro() {

    // if (this.index === 0) {
    //   this.actualizarDatosFP();
    // }
    // if (this.index === 1) {
    //   this.guardarfp();
    // }
    if(await this.actualizarDatosFP() === true){
      await this.guardarfp();
    }
    
    
  }
  //CDPJ
  guardarRegistroCXP(){
    if (this.index === 0) {
      this.actualizarDatosCXP();
    }
    if (this.index === 1) {
      this.guardarfpcxp();
    }
  }
  actualizarDatosCXP() {
    let strSentencia = '';
    let codsritipo = '';
    let texto = '';
    let aux = 0;

    if (this.datosFP.ENCFACPRO_PAGOLOCALEXT === '' || this.datosFP.ENCFACPRO_PAGOLOCALEXT === null) {
      this.messageService.add({
        key: 'datane',
        severity: 'warn',
        summary: 'Información',
        detail: 'Por favor ingrese código del pago Local o al Exterior.'
      });
      return;
    }

    if (this.datosFP.ENCFACPRO_RETSERIEEST !== null) {
      if (this.datosFP.ENCFACPRO_RETSERIEEST.length !== 3 && this.datosFP.ENCFACPRO_RETSERIEEST.length !== 0) {
        this.messageService.add({
          key: 'datane',
          severity: 'warn',
          summary: 'Información',
          detail: 'Serie del establecimiento debe tener 3 caracteres.'
        });
        return;
      }
    }

    if (this.datosFP.ENCFACPRO_RETSERIEPTOEMI !== null) {
      if (this.datosFP.ENCFACPRO_RETSERIEPTOEMI.length !== 3 && this.datosFP.ENCFACPRO_RETSERIEPTOEMI.length !== 0) {
        this.messageService.add({
          key: 'datane',
          severity: 'warn',
          summary: 'Información',
          detail: 'Serie del punto de emisión debe tener 3 caracteres.'
        });
        return;
      }
    }

    if ((this.datosFP.ENCFACPRO_RETSECUENCIAL === null ? '' : this.datosFP.ENCFACPRO_RETSECUENCIAL).length >= 1
      && (this.datosFP.ENCFACPRO_RETSECUENCIAL === null ? '' : this.datosFP.ENCFACPRO_RETSECUENCIAL).length <= 9) {
    } else {
      if (this.datosFP.ENCFACPRO_RETSERIEEST !== null) {
        if (this.datosFP.ENCFACPRO_RETSERIEEST.length !== 0) {
          this.messageService.add({
            key: 'datane',
            severity: 'warn',
            summary: 'Información',
            detail: 'Número de autorización debe tener minimo 1 caracteres y máximo 9 caracteres.'
          });
          return;
        }
      }
    }

    if ((this.datosFP.ENCFACPRO_RETAUTORIZACION === null ? '' : this.datosFP.ENCFACPRO_RETAUTORIZACION).length >= 3
      && (this.datosFP.ENCFACPRO_RETAUTORIZACION === null ? '' : this.datosFP.ENCFACPRO_RETAUTORIZACION).length <= 37) {
    } else {
      if (this.datosFP.ENCFACPRO_RETSERIEEST !== null) {
        if (this.datosFP.ENCFACPRO_RETSERIEEST.length !== 0) {
          this.messageService.add({
            key: 'datane',
            severity: 'warn',
            summary: 'Información',
            detail: 'Número de autorización debe tener minimo 3 caracteres y máximo 37 caracteres.'
          });
          return;
        }
      }
    }

    this.datosFP.ENCFACPRO_CONVDOBLETRIB = this.selectconvdobletrib.codigo;
    this.datosFP.ENCFACPRO_PAGOEXTERIOR = this.selectpagoexterior.codigo;
    this.datosFP.ENCFACPRO_PAGOREGFISCAL = this.selectpagoregfiscal.codigo;
    this.datosFP.ENCFACPRO_TIPOREGFISCAL = this.selecttiporegimen.codigo;//CDPJ
    //CDPJ
    if(this.datosFP.ENCFACPRO_PAGOLOCALEXT === '02'){
      if(this.datosFP.ENCFACPRO_TIPOREGFISCAL === '01'){
        if(this.datosFP.ENCFACPRO_CODPAISREGGEN === '' 
        || this.datosFP.ENCFACPRO_PAISPAGO === ''
        || this.datosFP.ENCFACPRO_CONVDOBLETRIB === ''
        || this.datosFP.ENCFACPRO_PAGOEXTERIOR === '' ){
          this.messageService.add({
            key: 'datane',
            severity: 'warn',
            summary: 'Información',
            detail: 'Por favor ingrese todos los datos de los campos habilitados'
          });
          return;
        }
      }else if(this.datosFP.ENCFACPRO_TIPOREGFISCAL === '02'){
        if(this.datosFP.ENCFACPRO_CODPAISPARFIS === '' 
        || this.datosFP.ENCFACPRO_PAISPAGO === ''
        || this.datosFP.ENCFACPRO_CONVDOBLETRIB === ''
        || this.datosFP.ENCFACPRO_PAGOEXTERIOR === '' ){
          this.messageService.add({
            key: 'datane',
            severity: 'warn',
            summary: 'Información',
            detail: 'Por favor ingrese todos los datos de los campos habilitados'
          });
          return;
        }
      }else if(this.datosFP.ENCFACPRO_TIPOREGFISCAL === '03'){
        if(this.datosFP.ENCFACPRO_DENOMINACION === '' 
        || this.datosFP.ENCFACPRO_PAISPAGO === ''
        || this.datosFP.ENCFACPRO_CONVDOBLETRIB === ''
        || this.datosFP.ENCFACPRO_PAGOEXTERIOR === '' ){
          this.messageService.add({
            key: 'datane',
            severity: 'warn',
            summary: 'Información',
            detail: 'Por favor ingrese todos los datos de los campos habilitados'
          });
          return;
        }
      }else if(this.datosFP.ENCFACPRO_TIPOREGFISCAL === '') {
        this.messageService.add({
          key: 'datane',
          severity: 'warn',
          summary: 'Información',
          detail: 'Por favor escoja un tipo de régimen fiscal'
        });
        return;
      }
    }
    //CDPJ
    codsritipo = 'LE';
    texto = (this.datosFP.ENCFACPRO_PAGOLOCALEXT === null ? '' : this.datosFP.ENCFACPRO_PAGOLOCALEXT);

    strSentencia = 'SELECT CODSRI_CODIGO, CODSRI_DESCRIPCION, CODSRI_TIPO FROM ANEX_MAECODSRI';
    strSentencia = strSentencia + ' WHERE CODSRI_TIPO IN (\'' + codsritipo + '\') AND CODSRI_CODIGO=\'' + texto + '\'';

    this.datosanexService.ejecutarConsulta1(codsritipo, texto).subscribe(data => {
      aux = 0;
      for (const rs of data) {
        aux++;
      }

      if (aux === 0 && texto.length > 0) {
        this.messageService.add({
          key: 'datane',
          severity: 'warn',
          summary: 'Información',
          detail: 'Error, Pago local o al exterior es incorrecto.'
        });
      } else {
        codsritipo = 'RG';
        texto = (this.datosFP.ENCFACPRO_CODPAISREGGEN === null ? '' : this.datosFP.ENCFACPRO_CODPAISREGGEN);

        strSentencia = 'SELECT CODSRI_CODIGO, CODSRI_DESCRIPCION, CODSRI_TIPO FROM ANEX_MAECODSRI';
        strSentencia = strSentencia + ' WHERE CODSRI_TIPO IN (\'' + codsritipo + '\') AND CODSRI_CODIGO=\'' + texto + '\'';

        this.datosanexService.ejecutarConsulta1(codsritipo, texto).subscribe(data1 => {
          aux = 0;
          for (const rs of data1) {
            aux++;
          }

          if (aux === 0 && texto.length > 0) {
            this.messageService.add({
              key: 'datane',
              severity: 'warn',
              summary: 'Información',
              detail: 'Error, País al que se realiza el pago en régimen general es incorrecto.'
            });
          } else {
            codsritipo = 'PG';
            texto = (this.datosFP.ENCFACPRO_CODPAISPARFIS === null ? '' : this.datosFP.ENCFACPRO_CODPAISPARFIS);

            strSentencia = 'SELECT CODSRI_CODIGO, CODSRI_DESCRIPCION, CODSRI_TIPO FROM ANEX_MAECODSRI';
            strSentencia = strSentencia + ' WHERE CODSRI_TIPO IN (\'' + codsritipo + '\') AND CODSRI_CODIGO=\'' + texto + '\'';

            this.datosanexService.ejecutarConsulta1(codsritipo, texto).subscribe(data2 => {
              aux = 0;
              for (const rs of data2) {
                aux++;
              }

              if (aux === 0 && texto.length > 0) {
                this.messageService.add({
                  key: 'datane',
                  severity: 'warn',
                  summary: 'Información',
                  detail: 'Error, País al que se realiza el pago paraíso físcal es incorrecto.'
                });
              } else {
                codsritipo = 'PA\',\'PG';
                texto = (this.datosFP.ENCFACPRO_PAISPAGO === null ? '' : this.datosFP.ENCFACPRO_PAISPAGO);

                strSentencia = 'SELECT CODSRI_CODIGO, CODSRI_DESCRIPCION, CODSRI_TIPO FROM ANEX_MAECODSRI';
                strSentencia = strSentencia + ' WHERE CODSRI_TIPO IN (\'' + codsritipo + '\') AND CODSRI_CODIGO=\'' + texto + '\'';

                this.datosanexService.ejecutarConsulta1(codsritipo, texto).subscribe(data3 => {
                  aux = 0;
                  for (const rs of data3) {
                    aux++;
                  }

                  if (aux === 0 && texto.length > 0) {
                    this.messageService.add({
                      key: 'datane',
                      severity: 'warn',
                      summary: 'Información',
                      detail: 'Error, País al que se efectúa el pago es incorrecto.'
                    });
                  } else {
                    //const strOrigen = 'FP';
                    //if (strOrigen === 'FP') { // FACTURA DE PROVEEDORES
                      strSentencia = 'UPDATE COM_ENCFACPRO SET ENCFACPRO_PAGOLOCALEXT='
                        + '\'' + (this.datosFP.ENCFACPRO_PAGOLOCALEXT === null ? '' : this.datosFP.ENCFACPRO_PAGOLOCALEXT) + '\'';
                      strSentencia = strSentencia + ' ,ENCFACPRO_PAISPAGO=' + '\''
                        + (this.datosFP.ENCFACPRO_PAISPAGO === null ? '' : this.datosFP.ENCFACPRO_PAISPAGO) + '\'';
                      strSentencia = strSentencia + ' ,ENCFACPRO_CONVDOBLETRIB=' + '\''
                        + (this.datosFP.ENCFACPRO_CONVDOBLETRIB === null ? '' : this.datosFP.ENCFACPRO_CONVDOBLETRIB) + '\'';
                      strSentencia = strSentencia + ' ,ENCFACPRO_PAGOEXTERIOR=' + '\''
                        + (this.datosFP.ENCFACPRO_PAGOEXTERIOR === null ? '' : this.datosFP.ENCFACPRO_PAGOEXTERIOR) + '\'';
                      strSentencia = strSentencia + ' ,ENCFACPRO_RETSERIEEST=' + '\''
                        + (this.datosFP.ENCFACPRO_RETSERIEEST === null ? '' : this.datosFP.ENCFACPRO_RETSERIEEST) + '\'';
                      strSentencia = strSentencia + ' ,ENCFACPRO_RETSERIEPTOEMI=' + '\''
                        + (this.datosFP.ENCFACPRO_RETSERIEPTOEMI === null ? '' : this.datosFP.ENCFACPRO_RETSERIEPTOEMI) + '\'';
                      strSentencia = strSentencia + ' ,ENCFACPRO_RETSECUENCIAL=' + '\''
                        + (this.datosFP.ENCFACPRO_RETSECUENCIAL === null ? '' : this.datosFP.ENCFACPRO_RETSECUENCIAL) + '\'';
                      strSentencia = strSentencia + ' ,ENCFACPRO_RETAUTORIZACION=' + '\''
                        + (this.datosFP.ENCFACPRO_RETAUTORIZACION === null ? '' : this.datosFP.ENCFACPRO_RETAUTORIZACION) + '\'';
                      strSentencia = strSentencia + ' ,ENCFACPRO_PAGOREGFISCAL=' + '\''
                        + (this.datosFP.ENCFACPRO_PAGOREGFISCAL === null ? '' : this.datosFP.ENCFACPRO_PAGOREGFISCAL) + '\'';
                      if (this.datosFP.ENCFACPRO_RETFECHA !== null) {
                        strSentencia = strSentencia + ' ,ENCFACPRO_RETFECHA=TO_DATE(' + '\''
                          + this.datePipe.transform(this.datosFP.ENCFACPRO_RETFECHA, 'dd/MM/yyyy') + '\',\'dd/mm/yyyy\')';
                      } else {
                        strSentencia = strSentencia + ' ,ENCFACPRO_RETFECHA=\'\'';
                      }
                      strSentencia = strSentencia + ' ,ENCFACPRO_TIPOREGFISCAL=' + '\''
                        + (this.datosFP.ENCFACPRO_TIPOREGFISCAL === null ? '' : this.datosFP.ENCFACPRO_TIPOREGFISCAL) + '\'';
                      strSentencia = strSentencia + ' ,ENCFACPRO_CODPAISREGGEN=' + '\''
                        + (this.datosFP.ENCFACPRO_CODPAISREGGEN === null ? '' : this.datosFP.ENCFACPRO_CODPAISREGGEN) + '\'';
                      strSentencia = strSentencia + ' ,ENCFACPRO_CODPAISPARFIS=' + '\''
                        + (this.datosFP.ENCFACPRO_CODPAISPARFIS === null ? '' : this.datosFP.ENCFACPRO_CODPAISPARFIS) + '\'';
                      strSentencia = strSentencia + ' ,ENCFACPRO_DENOMINACION=' + '\''
                        + (this.datosFP.ENCFACPRO_DENOMINACION === null ? '' : this.datosFP.ENCFACPRO_DENOMINACION) + '\'';
                      strSentencia = strSentencia + ' WHERE ENCFACPRO_NUMERO= ' + '\''
                        + this.datosFP.ENCFACPRO_NUMERO + '\'';

                      this.datosanexService.ejecutarConsultacxp2(this.datosFP).subscribe(jjj => {
                        this.botonGuardar = true;
                        this.messageService.add({
                          key: 'datane',
                          severity: 'success',
                          summary: 'Información',
                          detail: 'Datos guardados correctamente.'
                        });
                      });
                    //}
                  }
                });
              }
            });
          }
        });
      }
    });
  }
  //CDPJ

  async actualizarDatosFP() {
    let strSentencia = '';
    let codsritipo = '';
    let texto = '';
    let aux = 0;
    let control=true;
    if (this.datosFP.ENCFACPRO_PAGOLOCALEXT === '' || this.datosFP.ENCFACPRO_PAGOLOCALEXT === null) {
      this.messageService.add({
        key: 'datane',
        severity: 'warn',
        summary: 'Información',
        detail: 'Por favor ingrese código del pago Local o al Exterior.'
      });
      return control=false;
    }

    if (this.datosFP.ENCFACPRO_RETSERIEEST !== null) {
      if (this.datosFP.ENCFACPRO_RETSERIEEST.length !== 3 && this.datosFP.ENCFACPRO_RETSERIEEST.length !== 0) {
        this.messageService.add({
          key: 'datane',
          severity: 'warn',
          summary: 'Información',
          detail: 'Serie del establecimiento debe tener 3 caracteres.'
        });
        return control=false;
      }
    }

    if (this.datosFP.ENCFACPRO_RETSERIEPTOEMI !== null) {
      if (this.datosFP.ENCFACPRO_RETSERIEPTOEMI.length !== 3 && this.datosFP.ENCFACPRO_RETSERIEPTOEMI.length !== 0) {
        this.messageService.add({
          key: 'datane',
          severity: 'warn',
          summary: 'Información',
          detail: 'Serie del punto de emisión debe tener 3 caracteres.'
        });
        return control=false;
      }
    }

    if ((this.datosFP.ENCFACPRO_RETSECUENCIAL === null ? '' : this.datosFP.ENCFACPRO_RETSECUENCIAL).length >= 1
      && (this.datosFP.ENCFACPRO_RETSECUENCIAL === null ? '' : this.datosFP.ENCFACPRO_RETSECUENCIAL).length <= 9) {
    } else {
      if (this.datosFP.ENCFACPRO_RETSERIEEST !== null) {
        if (this.datosFP.ENCFACPRO_RETSERIEEST.length !== 0) {
          this.messageService.add({
            key: 'datane',
            severity: 'warn',
            summary: 'Información',
            detail: 'Número de autorización debe tener minimo 1 caracteres y máximo 9 caracteres.'
          });
          return control=false;
        }
      }
    }

    if ((this.datosFP.ENCFACPRO_RETAUTORIZACION === null ? '' : this.datosFP.ENCFACPRO_RETAUTORIZACION).length >= 3
      && (this.datosFP.ENCFACPRO_RETAUTORIZACION === null ? '' : this.datosFP.ENCFACPRO_RETAUTORIZACION).length <= 37) {
    } else {
      if (this.datosFP.ENCFACPRO_RETSERIEEST !== null) {
        if (this.datosFP.ENCFACPRO_RETSERIEEST.length !== 0) {
          this.messageService.add({
            key: 'datane',
            severity: 'warn',
            summary: 'Información',
            detail: 'Número de autorización debe tener minimo 3 caracteres y máximo 37 caracteres.'
          });
          return control=false;
        }
      }
    }

    this.datosFP.ENCFACPRO_CONVDOBLETRIB = this.selectconvdobletrib.codigo;
    this.datosFP.ENCFACPRO_PAGOEXTERIOR = this.selectpagoexterior.codigo;
    this.datosFP.ENCFACPRO_PAGOREGFISCAL = this.selectpagoregfiscal.codigo;
    this.datosFP.ENCFACPRO_TIPOREGFISCAL = this.selecttiporegimen.codigo;//CDPJ
    //CDPJ
    if(this.datosFP.ENCFACPRO_PAGOLOCALEXT === '02'){
      if(this.datosFP.ENCFACPRO_TIPOREGFISCAL === '01'){
        if(this.datosFP.ENCFACPRO_CODPAISREGGEN === '' 
        || this.datosFP.ENCFACPRO_PAISPAGO === ''
        || this.datosFP.ENCFACPRO_CONVDOBLETRIB === ''
        || this.datosFP.ENCFACPRO_PAGOEXTERIOR === '' ){
          this.messageService.add({
            key: 'datane',
            severity: 'warn',
            summary: 'Información',
            detail: 'Por favor ingrese todos los datos de los campos habilitados'
          });
          return control=false;
        }
      }else if(this.datosFP.ENCFACPRO_TIPOREGFISCAL === '02'){
        if(this.datosFP.ENCFACPRO_CODPAISPARFIS === '' 
        || this.datosFP.ENCFACPRO_PAISPAGO === ''
        || this.datosFP.ENCFACPRO_CONVDOBLETRIB === ''
        || this.datosFP.ENCFACPRO_PAGOEXTERIOR === '' ){
          this.messageService.add({
            key: 'datane',
            severity: 'warn',
            summary: 'Información',
            detail: 'Por favor ingrese todos los datos de los campos habilitados'
          });
          return control=false;
        }
      }else if(this.datosFP.ENCFACPRO_TIPOREGFISCAL === '03'){
        if(this.datosFP.ENCFACPRO_DENOMINACION === '' 
        || this.datosFP.ENCFACPRO_PAISPAGO === ''
        || this.datosFP.ENCFACPRO_CONVDOBLETRIB === ''
        || this.datosFP.ENCFACPRO_PAGOEXTERIOR === '' ){
          this.messageService.add({
            key: 'datane',
            severity: 'warn',
            summary: 'Información',
            detail: 'Por favor ingrese todos los datos de los campos habilitados'
          });
          return control=false;
        }
      }else if(this.datosFP.ENCFACPRO_TIPOREGFISCAL === '') {
        this.messageService.add({
          key: 'datane',
          severity: 'warn',
          summary: 'Información',
          detail: 'Por favor escoja un tipo de régimen fiscal'
        });
        return control=false;
      }
    }
    //CDPJ
    codsritipo = 'LE';
    texto = (this.datosFP.ENCFACPRO_PAGOLOCALEXT === null ? '' : this.datosFP.ENCFACPRO_PAGOLOCALEXT);

    strSentencia = 'SELECT CODSRI_CODIGO, CODSRI_DESCRIPCION, CODSRI_TIPO FROM ANEX_MAECODSRI';
    strSentencia = strSentencia + ' WHERE CODSRI_TIPO IN (\'' + codsritipo + '\') AND CODSRI_CODIGO=\'' + texto + '\'';

    this.datosanexService.ejecutarConsulta1(codsritipo, texto).subscribe(data => {
      aux = 0;
      for (const rs of data) {
        aux++;
      }

      if (aux === 0 && texto.length > 0) {
        this.messageService.add({
          key: 'datane',
          severity: 'warn',
          summary: 'Información',
          detail: 'Error, Pago local o al exterior es incorrecto.'
        });
        return control=false;
      } else {
        codsritipo = 'RG';
        texto = (this.datosFP.ENCFACPRO_CODPAISREGGEN === null ? '' : this.datosFP.ENCFACPRO_CODPAISREGGEN);

        strSentencia = 'SELECT CODSRI_CODIGO, CODSRI_DESCRIPCION, CODSRI_TIPO FROM ANEX_MAECODSRI';
        strSentencia = strSentencia + ' WHERE CODSRI_TIPO IN (\'' + codsritipo + '\') AND CODSRI_CODIGO=\'' + texto + '\'';

        this.datosanexService.ejecutarConsulta1(codsritipo, texto).subscribe(data1 => {
          aux = 0;
          for (const rs of data1) {
            aux++;
          }

          if (aux === 0 && texto.length > 0) {
            this.messageService.add({
              key: 'datane',
              severity: 'warn',
              summary: 'Información',
              detail: 'Error, País al que se realiza el pago en régimen general es incorrecto.'
            });
            return control=false;
          } else {
            codsritipo = 'PG';
            texto = (this.datosFP.ENCFACPRO_CODPAISPARFIS === null ? '' : this.datosFP.ENCFACPRO_CODPAISPARFIS);

            strSentencia = 'SELECT CODSRI_CODIGO, CODSRI_DESCRIPCION, CODSRI_TIPO FROM ANEX_MAECODSRI';
            strSentencia = strSentencia + ' WHERE CODSRI_TIPO IN (\'' + codsritipo + '\') AND CODSRI_CODIGO=\'' + texto + '\'';

            this.datosanexService.ejecutarConsulta1(codsritipo, texto).subscribe(data2 => {
              aux = 0;
              for (const rs of data2) {
                aux++;
              }

              if (aux === 0 && texto.length > 0) {
                this.messageService.add({
                  key: 'datane',
                  severity: 'warn',
                  summary: 'Información',
                  detail: 'Error, País al que se realiza el pago paraíso físcal es incorrecto.'
                });
                return control=false;
              } else {
                codsritipo = 'PA\',\'PG';
                texto = (this.datosFP.ENCFACPRO_PAISPAGO === null ? '' : this.datosFP.ENCFACPRO_PAISPAGO);

                strSentencia = 'SELECT CODSRI_CODIGO, CODSRI_DESCRIPCION, CODSRI_TIPO FROM ANEX_MAECODSRI';
                strSentencia = strSentencia + ' WHERE CODSRI_TIPO IN (\'' + codsritipo + '\') AND CODSRI_CODIGO=\'' + texto + '\'';

                this.datosanexService.ejecutarConsulta1(codsritipo, texto).subscribe(data3 => {
                  aux = 0;
                  for (const rs of data3) {
                    aux++;
                  }

                  if (aux === 0 && texto.length > 0) {
                    this.messageService.add({
                      key: 'datane',
                      severity: 'warn',
                      summary: 'Información',
                      detail: 'Error, País al que se efectúa el pago es incorrecto.'
                    });
                    return control=false;
                  } else {
                    const strOrigen = 'FP';
                    if (strOrigen === 'FP') { // FACTURA DE PROVEEDORES
                      strSentencia = 'UPDATE COM_ENCFACPRO SET ENCFACPRO_PAGOLOCALEXT='
                        + '\'' + (this.datosFP.ENCFACPRO_PAGOLOCALEXT === null ? '' : this.datosFP.ENCFACPRO_PAGOLOCALEXT) + '\'';
                      strSentencia = strSentencia + ' ,ENCFACPRO_PAISPAGO=' + '\''
                        + (this.datosFP.ENCFACPRO_PAISPAGO === null ? '' : this.datosFP.ENCFACPRO_PAISPAGO) + '\'';
                      strSentencia = strSentencia + ' ,ENCFACPRO_CONVDOBLETRIB=' + '\''
                        + (this.datosFP.ENCFACPRO_CONVDOBLETRIB === null ? '' : this.datosFP.ENCFACPRO_CONVDOBLETRIB) + '\'';
                      strSentencia = strSentencia + ' ,ENCFACPRO_PAGOEXTERIOR=' + '\''
                        + (this.datosFP.ENCFACPRO_PAGOEXTERIOR === null ? '' : this.datosFP.ENCFACPRO_PAGOEXTERIOR) + '\'';
                      strSentencia = strSentencia + ' ,ENCFACPRO_RETSERIEEST=' + '\''
                        + (this.datosFP.ENCFACPRO_RETSERIEEST === null ? '' : this.datosFP.ENCFACPRO_RETSERIEEST) + '\'';
                      strSentencia = strSentencia + ' ,ENCFACPRO_RETSERIEPTOEMI=' + '\''
                        + (this.datosFP.ENCFACPRO_RETSERIEPTOEMI === null ? '' : this.datosFP.ENCFACPRO_RETSERIEPTOEMI) + '\'';
                      strSentencia = strSentencia + ' ,ENCFACPRO_RETSECUENCIAL=' + '\''
                        + (this.datosFP.ENCFACPRO_RETSECUENCIAL === null ? '' : this.datosFP.ENCFACPRO_RETSECUENCIAL) + '\'';
                      strSentencia = strSentencia + ' ,ENCFACPRO_RETAUTORIZACION=' + '\''
                        + (this.datosFP.ENCFACPRO_RETAUTORIZACION === null ? '' : this.datosFP.ENCFACPRO_RETAUTORIZACION) + '\'';
                      strSentencia = strSentencia + ' ,ENCFACPRO_PAGOREGFISCAL=' + '\''
                        + (this.datosFP.ENCFACPRO_PAGOREGFISCAL === null ? '' : this.datosFP.ENCFACPRO_PAGOREGFISCAL) + '\'';
                      if (this.datosFP.ENCFACPRO_RETFECHA !== null) {
                        strSentencia = strSentencia + ' ,ENCFACPRO_RETFECHA=TO_DATE(' + '\''
                          + this.datePipe.transform(this.datosFP.ENCFACPRO_RETFECHA, 'dd/MM/yyyy') + '\',\'dd/mm/yyyy\')';
                      } else {
                        strSentencia = strSentencia + ' ,ENCFACPRO_RETFECHA=\'\'';
                      }
                      strSentencia = strSentencia + ' ,ENCFACPRO_TIPOREGFISCAL=' + '\''
                        + (this.datosFP.ENCFACPRO_TIPOREGFISCAL === null ? '' : this.datosFP.ENCFACPRO_TIPOREGFISCAL) + '\'';
                      strSentencia = strSentencia + ' ,ENCFACPRO_CODPAISREGGEN=' + '\''
                        + (this.datosFP.ENCFACPRO_CODPAISREGGEN === null ? '' : this.datosFP.ENCFACPRO_CODPAISREGGEN) + '\'';
                      strSentencia = strSentencia + ' ,ENCFACPRO_CODPAISPARFIS=' + '\''
                        + (this.datosFP.ENCFACPRO_CODPAISPARFIS === null ? '' : this.datosFP.ENCFACPRO_CODPAISPARFIS) + '\'';
                      strSentencia = strSentencia + ' ,ENCFACPRO_DENOMINACION=' + '\''
                        + (this.datosFP.ENCFACPRO_DENOMINACION === null ? '' : this.datosFP.ENCFACPRO_DENOMINACION) + '\'';
                      strSentencia = strSentencia + ' WHERE ENCFACPRO_NUMERO= ' + '\''
                        + this.datosFP.ENCFACPRO_NUMERO + '\'';

                      this.datosanexService.ejecutarConsulta2(this.datosFP).subscribe(jjj => {
                        this.botonGuardar = true;
                        this.messageService.add({
                          key: 'datane',
                          severity: 'success',
                          summary: 'Información',
                          detail: 'Datos guardados correctamente.'
                        });
                        
                      });
                    }
                  }
                });
              }
            });
          }
        });
      }
    });
    return control;
  }


  habilitar(accion) {
    this.cambio();
    //   if (this.datosFP.ENCFACPRO_TIPOREGFISCAL === '01') {
    //     this.booleanRegimen = false;
    //     this.booleanParaiso = true;
    //     this.booleanRegimenP = true;
    //   } else if (this.datosFP.ENCFACPRO_TIPOREGFISCAL === '02') {
    //     this.booleanRegimen = true;
    //     this.booleanParaiso = false;
    //     this.booleanRegimenP = true;
    //   } else if (this.datosFP.ENCFACPRO_TIPOREGFISCAL === '03') {
    //     this.booleanRegimen = true;
    //     this.booleanParaiso = true;
    //     this.booleanRegimenP = false;
    //   } else if (this.datosFP.ENCFACPRO_TIPOREGFISCAL === '') {
    //     this.booleanRegimen = true;
    //     this.booleanParaiso = true;
    //     this.booleanRegimenP = true;
    //   }
    //   if (accion === 'CMB') {
    //     this.datosFP.ENCFACPRO_CODPAISREGGEN = '';
    //     this.datosFP.ENCFACPRO_CODPAISREGGENDET = '';
    //     this.datosFP.ENCFACPRO_CODPAISPARFIS = '';
    //     this.datosFP.ENCFACPRO_CODPAISPARFISDET = '';
    //     this.datosFP.ENCFACPRO_DENOMINACION = '';
    //     this.datosFP.ENCFACPRO_PAISPAGO = '';
    //     this.datosFP.ENCFACPRO_PAISPAGODET = '';
    //   }
    // }
    //CDPJ
    if (this.selecttiporegimen.codigo === '01') {
      this.booleanRegimen = false;
      console.log('1');
      this.booleanParaiso = true;
      this.booleanRegimenP = false;//CDPJ
      this.booleandenomi=true;//CDPJ
      if (this.datosFP.ENCFACPRO_PAGOLOCALEXT === '02') {
        console.log('4');
        this.booleanRegimenP =false;//CDPJ
        this.boolcmbtiporegimen = false;
      }
    } else if (this.selecttiporegimen.codigo === '02') {
      console.log('3');
      this.booleanRegimen = true;
      this.booleanParaiso = false;
      this.booleanRegimenP = false;//CDPJ
      this.booleandenomi=true;//CDPJ
      if (this.datosFP.ENCFACPRO_PAGOLOCALEXT === '02') {
        console.log('4');
        this.booleanRegimenP = false;//CDPJ
        this.boolcmbtiporegimen = false;
        this.booleandenomi=true;//CDPJ
      }
    } else if (this.selecttiporegimen.codigo === '03') {
      console.log('5')
      this.booleanRegimen = true;
      this.booleanParaiso = true;
      this.booleanRegimenP = false;
      this.booleandenomi=false;//CDPJ
      if (this.datosFP.ENCFACPRO_PAGOLOCALEXT === '02') {
        console.log('6')
        this.booleanRegimenP = false;
        this.boolcmbtiporegimen = false;
        this.booleandenomi=false;//CDPJ
      }
    } else if (this.selecttiporegimen.codigo === '') {
      console.log('7')
      this.booleanRegimen = true;
      this.booleanParaiso = true;
      this.booleanRegimenP = true;
      this.booleandenomi=true;//CDPJ
      this.datosFP.ENCFACPRO_PAISPAGO='';//CDPJ
      this.datosFP.ENCFACPRO_CODPAISREGGENDET=''//CDPJ
      this.datosFP.ENCFACPRO_CODPAISREGGEN = '';
      this.datosFP.ENCFACPRO_CODPAISREGGENDET = '';
      this.datosFP.ENCFACPRO_PAISPAGODET='';
      if (this.datosFP.ENCFACPRO_PAGOLOCALEXT === '01') {
        console.log('9')
        this.boolcmbtiporegimen = true;
      }

    }
    if (accion === 'CMB') {
      console.log('8')
      this.datosFP.ENCFACPRO_CODPAISREGGEN = '';
      this.datosFP.ENCFACPRO_CODPAISREGGENDET = '';
      this.datosFP.ENCFACPRO_CODPAISPARFIS = '';
      this.datosFP.ENCFACPRO_CODPAISPARFISDET = '';
      this.datosFP.ENCFACPRO_DENOMINACION = '';
      this.datosFP.ENCFACPRO_PAISPAGO = '';
      this.datosFP.ENCFACPRO_PAISPAGODET = '';
      this.boolcmbtiporegimen = false;//CDPJ
      this.selectconvdobletrib= { codigo: '', name: '' };//DEPENDERÁ DE LO QUE INDIQUE EL SRI
      this.selectpagoexterior= { codigo: '', name: '' };//DEPENDERÁ DE LO QUE INDIQUE EL SRI
    }
    //CDPJ
  }
  cambio() {
    this.botonGuardar = false;
  }

  setFocus(event) {
    this.botonGuardar = false;
    let element: any;
    switch (event.target.id) {
      case 'pagolocal': {
        this.abrirPagoLocal(event.target.value);
        break;
      }
      case 'paisesreg': {
        this.abrirPaisesRegimen(event.target.value);
        break;
      }
      case 'paisParaiso': {
        this.abrirPaisesParaiso(event.target.value);
        break;
      }
      case 'paisResid': {
        this.abrirPaisesExportacion(event.target.value);
        break;
      }
    }

    if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }
  }

  abrirPagoLocal(parametro) {
    this.cambio();
    this.opcionbusqueda = 'PAGOLOCALEXT';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código CON', value: 'CON_CODIGO' }
    ];
    this.busquedacampos = [this.datosFP.ENCFACPRO_PAGOLOCALEXT, '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.consulta = '*';
    this.where = 'CODSRI_TIPO=\'LE\'';


    this.datosanexService.encontrarCodSriCodigo(this.where + ' AND ' +
      this.types[0].value +
      ' LIKE \'' + parametro + '\'').subscribe(eR => {
        if (eR !== null) {
          if (eR[0] !== undefined) {
            this.datosFP.ENCFACPRO_PAGOLOCALEXTDET = eR[0].CODSRI_DESCRIPCION;
            document.getElementById('paisResid').focus();
          } else {
            this.busqService.busquedaAnexMaeCodSri8().subscribe((datos: any[]) => {
              this.arregloCons = datos;
              this.displayDialogBusquedaFast = true;
            });
          }
        } else {
          this.busqService.busquedaAnexMaeCodSri8().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      });
  }

  abrirPaisesRegimen(parametro) {
    this.opcionbusqueda = 'CODPAISREGGEN';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código CON', value: 'CON_CODIGO' }
    ];
    this.busquedacampos = [this.datosFP.ENCFACPRO_CODPAISREGGEN, '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.consulta = '*';
    this.where = 'CODSRI_TIPO=\'RG\'';

    this.datosanexService.encontrarCodSriCodigo(this.where + ' AND ' + this.types[0].value +
      ' LIKE \'' + parametro + '\'').subscribe(eR => {
        if (eR !== null) {
          if (eR[0] !== undefined) {
            this.datosFP.ENCFACPRO_CODPAISREGGENDET = eR[0].CODSRI_DESCRIPCION;
          } else {
            this.busqService.busquedaAnexMaeCodSri9().subscribe((datos: any[]) => {
              this.arregloCons = datos;
              this.displayDialogBusquedaFast = true;
            });
          }
        } else {
          this.busqService.busquedaAnexMaeCodSri9().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      });
  }

  abrirPaisesParaiso(parametro) {
    this.opcionbusqueda = 'CODPAISPARFIS';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código CON', value: 'CON_CODIGO' }
    ];
    this.busquedacampos = [this.datosFP.ENCFACPRO_CODPAISPARFIS, '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.consulta = '*';
    this.where = 'CODSRI_TIPO=\'PG\'';

    this.datosanexService.encontrarCodSriCodigo(this.where + ' AND ' +
      this.types[0].value +
      ' LIKE \'' + parametro + '\'').subscribe(eR => {
        if (eR !== null) {
          if (eR[0] !== undefined) {
            this.datosFP.ENCFACPRO_CODPAISPARFISDET = eR[0].CODSRI_DESCRIPCION;
          } else {
            this.busqService.busquedaAnexMaeCodSri10().subscribe((datos: any[]) => {
              this.arregloCons = datos;
              this.displayDialogBusquedaFast = true;
            });
          }
        } else {
          this.busqService.busquedaAnexMaeCodSri10().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      });
  }

  abrirPaisesExportacion(parametro) {
    this.opcionbusqueda = 'PAISPAGO';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código CON', value: 'CON_CODIGO' }
    ];
    this.busquedacampos = [this.datosFP.ENCFACPRO_PAISPAGO, '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.consulta = '*';
    this.where = 'CODSRI_TIPO=\'PA\'';

    this.datosanexService.encontrarCodSriCodigo(this.where + ' AND ' +
      this.types[0].value +
      ' LIKE \'' + parametro + '\'').subscribe(eR => {
        if (eR !== null) {
          if (eR[0] !== undefined) {
            this.datosFP.ENCFACPRO_PAISPAGODET = eR[0].CODSRI_DESCRIPCION;
          } else {
            this.busqService.busquedaAnexMaeCodSri11().subscribe((datos: any[]) => {
              this.arregloCons = datos;
              this.displayDialogBusquedaFast = true;
            });
          }
        } else {
          this.busqService.busquedaAnexMaeCodSri11().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      });
  }

  manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'PAGOLOCALEXT') {
      this.datosFP.ENCFACPRO_PAGOLOCALEXT = opcion.CODSRI_CODIGO;
      this.datosFP.ENCFACPRO_PAGOLOCALEXTDET = opcion.CODSRI_DESCRIPCION;
      //CDPJ
      if (this.datosFP.ENCFACPRO_PAGOLOCALEXT === '01') {
        this.boolcmbtiporegimen = true;
        this.selecttiporegimen = { codigo: '', name: '' };
        this.selectconvdobletrib= { codigo: '', name: '' };//DEPENDERÁ DE LO QUE INDIQUE EL SRI
        this.selectpagoexterior= { codigo: '', name: '' };//DEPENDERÁ DE LO QUE INDIQUE EL SRI
        this.habilitar('CARGAR');
      } else {
        this.boolcmbtiporegimen = false;
      }
      //CDPJ
    }

    if (this.opcionbusqueda === 'CODPAISREGGEN') {
      this.datosFP.ENCFACPRO_CODPAISREGGEN = opcion.CODSRI_CODIGO;
      this.datosFP.ENCFACPRO_CODPAISREGGENDET = opcion.CODSRI_DESCRIPCION;
    }

    if (this.opcionbusqueda === 'CODPAISPARFIS') {
      this.datosFP.ENCFACPRO_CODPAISPARFIS = opcion.CODSRI_CODIGO;
      this.datosFP.ENCFACPRO_CODPAISPARFISDET = opcion.CODSRI_DESCRIPCION;
    }

    if (this.opcionbusqueda === 'PAISPAGO') {
      this.datosFP.ENCFACPRO_PAISPAGO = opcion.CODSRI_CODIGO;
      this.datosFP.ENCFACPRO_PAISPAGODET = opcion.CODSRI_DESCRIPCION;
    }

    this.cambio();
    this.displayDialogBusqueda = false;
    this.displayDialogBusquedaFast = false;
  }

  gridsnull() {

  }

  seleccion(params) {

    if (params === 'nuevo') {
      this.nuevofp();
      return;
    }

    if (params === 'cancelar') {
      this.cancelarfp();
      return;
    }

    if (params === 'eliminar') {
      this.eliminarfp();
      return;
    }

    if (params.object !== undefined) {
      this.formapagoSelected = params.object;
    }

  }

  nuevofp() {
    const fp: ComTrnformapago = {
      ENCFACPRO_NUMERO: this.strnumfac,
      COM_CODIGO: '01',
      TRNFORMAPAGO_CODIGO: '',
      TRNFORMAPAGO_DESCRIPCION: '',
      CLAVE: ''
    };
    const indi = this.datosanexService.formapago.indexOf(this.formapagoSelected);

    if (indi === -1) {
      fp.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.datosanexService.formapago.push(fp);
      this.formapagoSelected = fp;
      this.aggrid.refreshaggrid(this.datosanexService.formapago, this.defaultColDef.objeto);
      this.aggrid.gridApi.setFocusedCell(this.datosanexService.formapago.length - 1, 'TRNFORMAPAGO_CODIGO');
    } else {
      fp.CLAVE = (indi + 1) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.datosanexService.formapago.splice(indi + 1, 0, fp);
      this.formapagoSelected = fp;
      this.aggrid.refreshaggridindex(this.datosanexService.formapago, this.defaultColDef.objeto, indi);
      this.aggrid.gridApi.setFocusedCell(indi + 1, 'TRNFORMAPAGO_CODIGO');
    }
    this.nuevoitem=fp;//CDPJ
    this.cambio();
  }

  cancelarfp() {
    if (this.formapagoSelected.TRNFORMAPAGO_CODIGO === '') {
      this.datosanexService.formapago.splice(this.datosanexService.formapago.findIndex(
        pago => pago.CLAVE === this.formapagoSelected.CLAVE),
        1);
      if (this.datosanexService.formapago.length > 0) {
        this.formapagoSelected = this.datosanexService.formapago[this.datosanexService.formapago.length - 1];
      }
      this.aggrid.refreshaggrid(this.datosanexService.formapago, this.defaultColDef.objeto);
    }
  }

  async consultarfp() {
    const strSentencia = 'SELECT * FROM COM_TRNFORMAPAGO WHERE ENCFACPRO_NUMERO=' + '\'' + this.strnumfac +
      '\'' + ' ORDER BY TRNFORMAPAGO_CODIGO';

    try {
      const fp = await this.datosanexService.ejecutarConsultaPromise(this.strnumfac);
      this.datosanexService.formapago = fp;
      this.formapagoSelected = {};
      for (const clave in this.datosanexService.formapago) {
        this.datosanexService.formapago[clave].CLAVE = clave;
      }
    } catch (error) {
    }
    this.botonGuardar = true;
  }
  async consultarfpCXP(){
    const strSentencia = 'SELECT * FROM COM_TRNFORMAPAGO WHERE ENCFACPRO_NUMERO=' + '\'' + this.strnumfac +
      '\'' + ' ORDER BY TRNFORMAPAGO_CODIGO';

    try {
      const fp = await this.datosanexService.ejecutarConsultaPromisecxp(this.strnumfac);
      this.datosanexService.formapago = fp;
      this.formapagoSelected = {};
      for (const clave in this.datosanexService.formapago) {
        this.datosanexService.formapago[clave].CLAVE = clave;
      }
    } catch (error) {
    }
    this.botonGuardar = true;
  }
//CDPJ
async consultarfpS() {
  const strSentencia = 'SELECT * FROM COM_TRNFORMAPAGO WHERE ENCFACPRO_NUMERO=' + '\'' + this.strnumfac +
    '\'' + ' ORDER BY TRNFORMAPAGO_CODIGO';
  let error=0;
  try {
    const fp = await this.datosanexService.ejecutarConsultaPromise(this.strnumfac);
    console.log('fp',fp)
    if(fp !== null && fp !== undefined && fp.length !==0){
      this.datosanexService.formapago = fp;
      this.formapagoSelected = {};
      for (const clave in this.datosanexService.formapago) {
        console.log('clave',this.datosanexService.formapago[clave].CLAVE)
        this.datosanexService.formapago[clave].CLAVE = clave;
        if(this.datosanexService.formapago[clave].CLAVE === null||
          this.datosanexService.formapago[clave].CLAVE === undefined ||
          this.datosanexService.formapago[clave].CLAVE === ''){
            error=error+1;
          }
      }
      console.log('error',error)
      if(error !==0){
          this.messageService.add({
            key: 'datane',
            severity: 'warn',
            summary: 'Información',
            detail: 'La factura no tiene forma de pago.'
          });
      }else{

        //CDPJ
  if(this.datosFP.ENCFACPRO_PAGOLOCALEXT === '02'){
    if(this.datosFP.ENCFACPRO_TIPOREGFISCAL === '01'){
      if(this.datosFP.ENCFACPRO_CODPAISREGGEN === '' 
      || this.datosFP.ENCFACPRO_PAISPAGO === ''
      || this.datosFP.ENCFACPRO_CONVDOBLETRIB === ''
      || this.datosFP.ENCFACPRO_PAGOEXTERIOR === '' ){
        this.messageService.add({
          key: 'datane',
          severity: 'warn',
          summary: 'Información',
          detail: 'Por favor ingrese todos los datos de los campos habilitados'
        });
        return;
      }else{
        this.salir.emit(true);
      }
    }else if(this.datosFP.ENCFACPRO_TIPOREGFISCAL === '02'){
      if(this.datosFP.ENCFACPRO_CODPAISPARFIS === '' 
      || this.datosFP.ENCFACPRO_PAISPAGO === ''
      || this.datosFP.ENCFACPRO_CONVDOBLETRIB === ''
      || this.datosFP.ENCFACPRO_PAGOEXTERIOR === '' ){
        this.messageService.add({
          key: 'datane',
          severity: 'warn',
          summary: 'Información',
          detail: 'Por favor ingrese todos los datos de los campos habilitados'
        });
        return;
      }else{
        this.salir.emit(true);
      }
    }else if(this.datosFP.ENCFACPRO_TIPOREGFISCAL === '03'){
      if(this.datosFP.ENCFACPRO_DENOMINACION === '' 
      || this.datosFP.ENCFACPRO_PAISPAGO === ''
      || this.datosFP.ENCFACPRO_CONVDOBLETRIB === ''
      || this.datosFP.ENCFACPRO_PAGOEXTERIOR === '' ){
        this.messageService.add({
          key: 'datane',
          severity: 'warn',
          summary: 'Información',
          detail: 'Por favor ingrese todos los datos de los campos habilitados'
        });
        return;
      }else{
        this.salir.emit(true);
      }
    }else if(this.datosFP.ENCFACPRO_TIPOREGFISCAL === '') {
      this.messageService.add({
        key: 'datane',
        severity: 'warn',
        summary: 'Información',
        detail: 'Por favor escoja un tipo de régimen fiscal'
      });
      return;
    }
  }else{
    this.salir.emit(true);
  }
  //CDPJ
      }
    }else{
      this.messageService.add({
        key: 'datane',
        severity: 'warn',
        summary: 'Información',
        detail: 'La factura no tiene forma de pago.'
      });
    }
  } catch (error) {
    this.messageService.add({
      key: 'datane',
      severity: 'warn',
      summary: 'Información',
      detail: 'La factura no tiene forma de pago.'
    });
  }
  //this.botonGuardar = true;
}
async consultarfpScxp() {
  const strSentencia = 'SELECT * FROM COM_TRNFORMAPAGO WHERE ENCFACPRO_NUMERO=' + '\'' + this.strnumfac +
    '\'' + ' ORDER BY TRNFORMAPAGO_CODIGO';
  let error=0;
  try {
    const fp = await this.datosanexService.ejecutarConsultaPromisecxp(this.strnumfac);
    console.log('fp',fp)
    if(fp !== null && fp !== undefined && fp.length !==0){
      this.datosanexService.formapago = fp;
      this.formapagoSelected = {};
      for (const clave in this.datosanexService.formapago) {
        console.log('clave',this.datosanexService.formapago[clave].CLAVE)
        this.datosanexService.formapago[clave].CLAVE = clave;
        if(this.datosanexService.formapago[clave].CLAVE === null||
          this.datosanexService.formapago[clave].CLAVE === undefined ||
          this.datosanexService.formapago[clave].CLAVE === ''){
            error=error+1;
          }
      }
      console.log('error',error)
      if(error !==0){
          this.messageService.add({
            key: 'datane',
            severity: 'warn',
            summary: 'Información',
            detail: 'La factura no tiene forma de pago.'
          });
      }else{

        //CDPJ
  if(this.datosFP.ENCFACPRO_PAGOLOCALEXT === '02'){
    if(this.datosFP.ENCFACPRO_TIPOREGFISCAL === '01'){
      if(this.datosFP.ENCFACPRO_CODPAISREGGEN === '' 
      || this.datosFP.ENCFACPRO_PAISPAGO === ''
      || this.datosFP.ENCFACPRO_CONVDOBLETRIB === ''
      || this.datosFP.ENCFACPRO_PAGOEXTERIOR === '' ){
        this.messageService.add({
          key: 'datane',
          severity: 'warn',
          summary: 'Información',
          detail: 'Por favor ingrese todos los datos de los campos habilitados'
        });
        return;
      }else{
        this.salir.emit(true);
      }
    }else if(this.datosFP.ENCFACPRO_TIPOREGFISCAL === '02'){
      if(this.datosFP.ENCFACPRO_CODPAISPARFIS === '' 
      || this.datosFP.ENCFACPRO_PAISPAGO === ''
      || this.datosFP.ENCFACPRO_CONVDOBLETRIB === ''
      || this.datosFP.ENCFACPRO_PAGOEXTERIOR === '' ){
        this.messageService.add({
          key: 'datane',
          severity: 'warn',
          summary: 'Información',
          detail: 'Por favor ingrese todos los datos de los campos habilitados'
        });
        return;
      }else{
        this.salir.emit(true);
      }
    }else if(this.datosFP.ENCFACPRO_TIPOREGFISCAL === '03'){
      if(this.datosFP.ENCFACPRO_DENOMINACION === '' 
      || this.datosFP.ENCFACPRO_PAISPAGO === ''
      || this.datosFP.ENCFACPRO_CONVDOBLETRIB === ''
      || this.datosFP.ENCFACPRO_PAGOEXTERIOR === '' ){
        this.messageService.add({
          key: 'datane',
          severity: 'warn',
          summary: 'Información',
          detail: 'Por favor ingrese todos los datos de los campos habilitados'
        });
        return;
      }else{
        this.salir.emit(true);
      }
    }else if(this.datosFP.ENCFACPRO_TIPOREGFISCAL === '') {
      this.messageService.add({
        key: 'datane',
        severity: 'warn',
        summary: 'Información',
        detail: 'Por favor escoja un tipo de régimen fiscal'
      });
      return;
    }
  }else{
    this.salir.emit(true);
  }
  //CDPJ
      }
    }else{
      this.messageService.add({
        key: 'datane',
        severity: 'warn',
        summary: 'Información',
        detail: 'La factura no tiene forma de pago.'
      });
    }
  } catch (error) {
    this.messageService.add({
      key: 'datane',
      severity: 'warn',
      summary: 'Información',
      detail: 'La factura no tiene forma de pago.'
    });
  }
  //this.botonGuardar = true;
}
//CDPJ
  eliminarfp() {
    this.datosanexService.formapago.splice(this.datosanexService.formapago.findIndex(
      fp => fp.CLAVE === this.formapagoSelected.CLAVE),
      1);
    this.aggrid.refreshaggrid(this.datosanexService.formapago, this.defaultColDef.objeto);
    this.cambio();
  }

  async guardarfp() {
    let strSentencia = '';
    strSentencia = 'DELETE FROM COM_TRNFORMAPAGO WHERE ENCFACPRO_NUMERO=\'' + this.datosFP.ENCFACPRO_NUMERO + '\'';
    strSentencia = strSentencia + ' AND COM_CODIGO=\'01\'';
    try {
      await this.datosanexService.ejecutarConsulta3(this.datosFP.ENCFACPRO_NUMERO).toPromise()//.subscribe(data => {
      for (const item of this.datosanexService.formapago) {
        if (item.TRNFORMAPAGO_CODIGO !== '' && item.TRNFORMAPAGO_CODIGO !== null) {
          this.datosanexService.insertarTrnFormaPago(item).toPromise()//.subscribe(data2 => {
          //});
        }
      }
      this.botonGuardar = true;
      this.messageService.add({
        key: 'datane',
        severity: 'success',
        summary: 'Información',
        detail: 'Datos guardados correctamente.'
      });
    //});
    } catch (error) {
      return false
    }
    
  }
  //CDPJ
  guardarfpcxp() {
    let strSentencia = '';
    strSentencia = 'DELETE FROM COM_TRNFORMAPAGO WHERE ENCFACPRO_NUMERO=\'' + this.datosFP.ENCFACPRO_NUMERO + '\'';
    strSentencia = strSentencia + ' AND COM_CODIGO=\'01\'';

    this.datosanexService.ejecutarConsulta3cxp(this.datosFP.ENCFACPRO_NUMERO).subscribe(data => {
      for (const item of this.datosanexService.formapago) {
        if (item.TRNFORMAPAGO_CODIGO !== '' && item.TRNFORMAPAGO_CODIGO !== null) {
          this.datosanexService.insertarTrnFormaPagocxp(item).subscribe(data2 => {
          });
        }
      }
      this.botonGuardar = true;
      this.messageService.add({
        key: 'datane',
        severity: 'success',
        summary: 'Información',
        detail: 'Datos guardados correctamente.'
      });
    });
  }
  //CDPJ

  llenarCombos() {
    this.cmbtiporegimenaux = [{ codigo: '01', name: '01-Regimen General' },
    { codigo: '02', name: '02-Paraiso Fiscal' },
    { codigo: '03', name: '03-Regimen Físcal preferente juridicción de menos imposición' },
    { codigo: '', name: '' }];

    this.cmbconvdobletribaux = [{ codigo: 'SI', name: 'SI' },
    { codigo: 'NO', name: 'NO' },
    { codigo: '', name: '' }];

    this.cmbpagoexterioraux = [{ codigo: 'SI', name: 'SI' },
    { codigo: 'NO', name: 'NO' },
    { codigo: '', name: '' }];

    this.cmbpagoregfiscalaux = [{ codigo: 'SI', name: 'SI' },
    { codigo: 'NO', name: 'NO' },
    { codigo: '', name: '' }];
  }


  llenarcmbtiporegimen() {
    //this.cmbtiporegimen = this.cmbtiporegimenaux;
    // this.datosFP.ENCFACPRO_TIPOREGFISCAL = this.cmbtiporegimenaux[0].codigo;
    this.cambio();
  }

  llenarcmbconvdobletrib() {
    // this.cmbconvdobletrib = this.cmbconvdobletribaux;
    // this.datosFP.ENCFACPRO_CONVDOBLETRIB = this.cmbconvdobletribaux[0].codigo;
    this.cambio();
  }

  llenarcmbpagoexterior() {
    // this.cmbpagoexterior = this.cmbpagoexterioraux;
    // this.datosFP.ENCFACPRO_PAGOEXTERIOR = this.cmbpagoexterioraux[0].codigo;
    this.cambio();
  }

  llenarcmbpagoregfiscal() {
    // this.cmbpagoregfiscal = this.cmbpagoregfiscalaux;
    // this.datosFP.ENCFACPRO_PAGOREGFISCAL = this.cmbpagoregfiscalaux[0].codigo;
    this.cambio();
  }

  busqtiporegimen(select) {
    this.datosFP.ENCFACPRO_TIPOREGFISCAL = select.value.codigo;
    this.habilitar('CMB');
  }

  busqconvdobletrib(select) {
    this.datosFP.ENCFACPRO_CONVDOBLETRIB = select.value.codigo;
    this.cambio();
  }

  busqpagoexterior(select) {
    this.datosFP.ENCFACPRO_PAGOEXTERIOR = select.value.codigo;
    this.cambio();
  }

  busqpagoregfiscal(select) {
    this.datosFP.ENCFACPRO_PAGOREGFISCAL = select.value.codigo;
    this.cambio();
  }

}
