import {Injectable} from '@angular/core';
import {Venmaegrupos} from '../veninterfaces/venmaegrupos';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Observable} from 'rxjs';
import {Venmaepromocion} from '../veninterfaces/venmaepromocion';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class VenmaepromocionService {
  get venmaepromo(): Venmaepromocion[] {
    return this._venmaepromo;
  }

  set venmaepromo(value: Venmaepromocion[]) {
    this._venmaepromo = value;
  }

  get unidades1(): string[] {
    return this._unidades1;
  }

  set unidades1(value: string[]) {
    this._unidades1 = value;
  }


  get unidades2(): string[] {
    return this._unidades2;
  }

  set unidades2(value: string[]) {
    this._unidades2 = value;
  }
  
  
  get unidades3(): string[] {
    return this._unidades3;
  }
  
  set unidades3(value: string[]) {
    this._unidades3 = value;
  }
  
  public url: string;
  private _venmaepromo: Venmaepromocion[];
  private _unidades1: string[];
  private _unidades2: string[];
  private _unidades3: string[];
  public way: string;
  constructor(private http: HttpClient,
              private confIniciales: ConfInicialesService,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }

  getPromo(): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/ventas/promociones/83bb9de2d8', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  getUnidades(artcodigo): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/promociones/41ddf5e4d535dc', {      
      elementos: {
        ART_CODIGO: artcodigo
      },
    }, this.confIniciales.httpOptions());
  }
  insertarPromocion(promocion: Venmaepromocion): Observable<any[]> {
   // pro
    return this.http.post<any[]>(this.way + '/ventas/promociones/257e3b79de2d860000', {      
      elementos: {
        p_maeprm_codigo: promocion.MAEPRM_CODIGO,
        p_maeprm_nombre: promocion.MAEPRM_NOMBRE,
        p_maeprm_nomsql: promocion.MAEPRM_NOMSQL,
        p_maeprm_artsrv: promocion.MAEPRM_ARTSRV,
        p_maeprm_precio: promocion.MAEPRM_PRECIO,
        p_maeprm_tipo: promocion.MAEPRM_TIPO,
        p_com_codigo: promocion.COM_CODIGO,
        p_maeprm_unidad: promocion.MAEPRM_UNIDAD,
        p_maeprm_fecini: promocion.MAEPRM_FECINI,
        p_maeprm_fecfin: promocion.MAEPRM_FECFIN,
        p_maeprm_artorg: promocion.MAEPRM_ARTORG,
        p_maeprm_artcant: promocion.MAEPRM_ARTCANT,
        p_maeprm_uniorg: promocion.MAEPRM_UNIORG,
        p_maeprm_tribiva: promocion.MAEPRM_TRIBIVA,
        p_maeprm_cantreg: promocion.MAEPRM_CANTREG,
        p_grup_codigo: promocion.GRUP_CODIGO,
        p_maeprm_valmin: promocion.MAEPRM_VALMIN,
        p_maeprm_valmax: promocion.MAEPRM_VALMAX,
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarPromo(promocion: Venmaepromocion): Observable<any[]> {
 //noout
  return this.http.post<any[]>(this.way + '/ventas/promociones/1d595a59de2d860000', {      
    elementos: {
      p_maeprm_codigo: promocion.MAEPRM_CODIGO,
      p_com_codigo: '01'
    },
  }, this.confIniciales.httpOptions());
  }
}
