import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { ArticuloSerie } from '../veninterfaces/articuloserie';
import { InvtrnkardexotrService } from '../venservicios/invtrnkardexotr.service';
import { InvTrnkardexotr } from '../veninterfaces/invtrnkardexotr';
import { MessageService } from 'primeng/api';
import { AggridBanComponent } from '../../ban/aggrid-ban/aggrid-ban.component';
import { DatePipe } from '@angular/common';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';//CDPJ

@Component({
  selector: 'app-invtrnkardexotr',
  templateUrl: './invtrnkardexotr.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class InvtrnkardexotrComponent implements OnInit, OnChanges {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  @Input() strNumFact: any;
  @Input() strNumFactDev: any;
  @Input() strFechaSer: any;
  @Input() strTipoDoc: any;
  @Input() strTipoTrn: any;
  @Input() devBoolean: any;
  @Input() strnBodega: any;//CDPJ
  @Input() strnBoddestino:any//CDPJ
  @Output() cerrar = new EventEmitter<boolean>();
  largo: string;
  largo2: string;
  selectAll: boolean;

  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  barraBotones2: boolean;

  strBodDestino: string;
  strBodOrigen: string;

  tabladetalle: ArticuloSerie[];
  detalleseleccionado: ArticuloSerie;

  tablaseriales: InvTrnkardexotr[];
  serialseleccionado: InvTrnkardexotr;

  listaseriales: InvTrnkardexotr[];

  bolvalidarDatos: boolean;

  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  defaultColDefDetalle;
  defaultColDefSeriales;
  columnDefsDetalle = [
    { // 0
      headerName: 'Bod.', field: 'BODCODIGO', width: 80, editable: false
    }, { // 1
      headerName: 'Artículo', field: 'ARTCODIGO', width: 130, editable: false
    }, { // 2
      headerName: 'Cant.', field: 'ARTCANTIDAD', width: 70, editable: false
    }, { // 3
      headerName: 'Nombre', field: 'ARTNOMBRE', width: 170, editable: false
    }];

  columnDefsSeriales = [
    {
      headerName: '',
      field: 'SELECCIONADO',
      cellRendererFramework: AggridBanComponent,
      editable: false,
      width: 50,
    },
    { // 0
      headerName: 'Serie', field: 'TRNKARDEXOTR_NUMSERIE', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 20,
          tienecalculos: true
        }
      }
    }, { // 1
      headerName: 'SIM', field: 'TRNKARDEXOTR_DIM', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 20
        }
      }
    }, { // 2
      headerName: 'Teléfono', field: 'TRNKARDEXOTR_TELEFONO', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 20
        }
      }
    }];

  constructor(private trnkardexotrservice: InvtrnkardexotrService, private messageService: MessageService, private datePipe: DatePipe,
    private auditoriagral: AuditoriagralService) {
    this.agTable();
    this.devBoolean = false;
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultColDefDetalle = {
      editable: true,
      width: 80,
      objeto: 'tabladetalle'
    };

    this.defaultColDefSeriales = {
      editable: true,
      width: 80,
      objeto: 'tablaseriales'
    };
  }

  ngOnInit() {
    this.largo = '650';
    this.largo2 = '600';
    this.selectAll = false;
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = true;
    this.botonRegresar = true;
    this.barraBotones2 = true;
    if (this.devBoolean === false) {
      this.columnDefsSeriales = [
        { // 0
          headerName: 'Serie', field: 'TRNKARDEXOTR_NUMSERIE', cellEditor: 'cellPrueba', width: 150,
          cellEditorParams:
          {
            values: {
              mayusculas: true,
              numeros: true,
              alphabetic: true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 0,
              obligatorio: true,
              fecha: false,
              clave: false,
              tamanocelda: 20,
              tienecalculos: true
            }
          }
        }, { // 1
          headerName: 'SIM', field: 'TRNKARDEXOTR_DIM', cellEditor: 'cellPrueba', width: 110,
          cellEditorParams:
          {
            values: {
              mayusculas: true,
              numeros: true,
              alphabetic: true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 0,
              obligatorio: false,
              fecha: false,
              clave: false,
              tamanocelda: 20
            }
          }
        }, { // 2
          headerName: 'Teléfono', field: 'TRNKARDEXOTR_TELEFONO', cellEditor: 'cellPrueba', width: 110,
          cellEditorParams:
          {
            values: {
              mayusculas: true,
              numeros: true,
              alphabetic: true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 0,
              obligatorio: false,
              fecha: false,
              clave: false,
              tamanocelda: 20
            }
          }
        }];
      this.agTable();
    }
    this.tabladetalle = [];
    this.detalleseleccionado = {};
    this.tablaseriales = [];
    this.serialseleccionado = {};
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const e in changes) {
      if (e === 'strNumFact' && this.strNumFact !== null) {
        this.mostrarDetalle();
      }
    }
  }

  mostrarDetalle() {
    this.tabladetalle = [];
    this.detalleseleccionado = {};
    this.tablaseriales = [];
    this.serialseleccionado = {};
    this.trnkardexotrservice.consultarArtículos(this.strTipoDoc, this.strNumFact).subscribe(res => {
      const listadetalle = [];
      for (const rs of res) {
        const data: ArticuloSerie = {};
        let i = 0;
        for (const rsin in rs) {
          if (i === 0) {
            data.BODCODIGO = rs[rsin];
            //CDPJ
            if (this.strTipoDoc === 'TRF') {
              data.BODCODIGO = this.strnBodega;
            }
            //CDPJ
          }
          if (i === 1) {
            data.ARTCODIGO = rs[rsin];
          }
          if (i === 2) {
            data.ARTNOMBRE = rs[rsin];
          }
          if (i === 3) {
            data.ARTCANTIDAD = rs[rsin];
          }
          if (i === 4) {
            data.NUMLINEA = rs[rsin];
          }
          i++;
        }
        listadetalle.push(data);
      }
      this.tabladetalle = listadetalle;
      // this.aggrid.refreshaggrid(this.tabladetalle, this.defaultColDefDetalle.objeto);

    });
  }

  manejarSenales(valor) {
    if (valor === 'Guardar') {
      this.guardarRegistro();
    }

    if (valor === 'Borrar') {
      // let val=0;
      // if (this.strTipoDoc === 'TRF') {
      //   val=this.serialseleccionado.TRNKARDEXOTR_NUMERO.toString().indexOf('_SA');
      //   if(val === -1) {
      //     this.serialseleccionado.TRNKARDEXOTR_NUMERO=this.serialseleccionado.TRNKARDEXOTR_NUMERO+'_SA';
      //     console.log(this.tablaseriales);
      //   }
      // }
      this.trnkardexotrservice.eliminarKardexOtr(this.serialseleccionado).subscribe(data => {
        this.messageService.add({
          key: 'seriales',
          severity: 'success',
          summary: 'Información',
          detail: 'Serial Eliminado'
        });
        this.mostrarDetalle();
        this.habilitarBotones(true, true, false, true);

      });
      //CDPJ
      this.auditoriagral.registrarAuditoria('INV_TRNKARDEXOTR_' + 
      this.strTipoDoc, this.serialseleccionado.TRNKARDEXOTR_NUMERO.toString(), 'E',
        '', '01', '', '', '', '').subscribe();
      //CDPJ
      if (this.strTipoDoc === 'TRF') {
        this.serialseleccionado.TRNKARDEXOTR_NUMERO = this.serialseleccionado.TRNKARDEXOTR_NUMERO.toString().replace('_SA', '');
        this.serialseleccionado.TRNKARDEXOTR_TIPO = 'EN'
        this.trnkardexotrservice.eliminarKardexOtr(this.serialseleccionado).subscribe(data => {
          this.messageService.add({
            key: 'seriales',
            severity: 'success',
            summary: 'Información',
            detail: 'Serial Eliminado'
          });

          this.mostrarDetalle();
          this.habilitarBotones(true, true, false, true);
        });
        //CDPJ
        this.auditoriagral.registrarAuditoria('INV_TRNKARDEXOTR_' + this.strTipoDoc,
          this.serialseleccionado.TRNKARDEXOTR_NUMERO.toString(), 'E',
          '', '01', '', '', '', '').subscribe();
        //CDPJ
      }
    }

    if (valor === 'Cancelar') {
      this.mostrarDetalle();
      this.habilitarBotones(true, true, false, true);
    }

    if (valor === 'Salir') {
      this.cerrar.emit(true);
    }


  }

  cambio(cambio) {
    if (cambio === true) {
      this.habilitarBotones(false, true, false, true);
    }
  }

  selecciondetalle(params) {
    if (params.object === undefined) {
      return;
    }

    this.detalleseleccionado = params.object;
    this.mostrarSerialesGrilla(this.strTipoDoc, this.detalleseleccionado);
  }

  seleccionseriales(params) {
    if (params.object === undefined) {
      return;
    }

    this.serialseleccionado = params.object;
    this.habilitarBotones(this.botonGuardar, false, false, false);
  }

  subirArchivo() {

  }

  mostrarSerialesGrilla(strTipoDoc, filaseleccionada) {
    let intNumSeriales = 0;
    let intNumLinea = 0;
    const strNumDoc = this.strNumFact;
    const strTipoTrn = this.strTipoTrn;

    const artcodigo = filaseleccionada.ARTCODIGO;
    const bodcodigo = filaseleccionada.BODCODIGO;
    const cantidad = filaseleccionada.ARTCANTIDAD;
    intNumLinea = filaseleccionada.NUMLINEA;
    if (strTipoDoc === 'FACPRO' || strTipoDoc === 'NOTREC') {
      this.trnkardexotrservice.consultarDetalle(artcodigo,
        strNumDoc, 'COM', intNumLinea).subscribe(res => {
          intNumSeriales = res.length;
          this.listaseriales = [];
          for (const item of res) {
            this.listaseriales.push(item);
          }
          this.tablaseriales = this.listaseriales;
          // Si no hay seriales ingresados se crea las líneas en blanco para el
          // ingreso dependiendo de la cantidad
          if (intNumSeriales === 0) {
            this.crearNuevosSeriales(cantidad, strNumDoc, strTipoTrn,
              bodcodigo, artcodigo, strTipoDoc, intNumLinea);
          } else if (intNumSeriales < cantidad) {
            this.crearNuevosSeriales(cantidad - intNumSeriales,
              strNumDoc, strTipoTrn, bodcodigo, artcodigo, strTipoDoc,
              intNumLinea);
          }
        });
    } else {
      if (strTipoDoc === 'DVFCOM' || strTipoDoc === 'DVFVEN') {
        this.trnkardexotrservice.consultarDetalleDev(artcodigo, this.strNumFactDev, strTipoDoc).subscribe((res) => {
          intNumSeriales = res.length;
          this.listaseriales = [];
          for (const item of res) {
            item.TRNKARDEXOTR_ORIGEN = strTipoDoc;
            item.TRNKARDEXOTR_NUMERO = this.strNumFact;
            item.TRNKARDEXOTR_NUMSEC = 0;
            item.TRNKARDEXOTR_TIPO = this.strTipoTrn;
            this.listaseriales.push(item);
          }
          this.tablaseriales = this.listaseriales;
          // Si no hay seriales ingresados se crea las líneas en blanco para el
          // ingreso dependiendo de la cantidad
          if (intNumSeriales === 0) {
            this.crearNuevosSeriales(cantidad, strNumDoc, strTipoTrn,
              bodcodigo, artcodigo, strTipoDoc, intNumLinea);
          } else if (intNumSeriales < cantidad) {
            this.crearNuevosSeriales(cantidad - intNumSeriales,
              strNumDoc, strTipoTrn, bodcodigo, artcodigo, strTipoDoc,
              intNumLinea);
          }
        });
      } else {
        this.trnkardexotrservice.consultarDetalle(artcodigo,
          this.strNumFact, strTipoDoc, intNumLinea).subscribe(res => {
            intNumSeriales = res.length;
            this.listaseriales = [];
            for (const item of res) {
              this.listaseriales.push(item);
            }
            this.tablaseriales = this.listaseriales;
            // Si no hay seriales ingresados se crea las líneas en blanco para el
            // ingreso dependiendo de la cantidad
            if (intNumSeriales === 0) {
              this.crearNuevosSeriales(cantidad, strNumDoc, strTipoTrn,
                bodcodigo, artcodigo, strTipoDoc, intNumLinea);
            } else if (intNumSeriales < cantidad) {
              this.crearNuevosSeriales(cantidad - intNumSeriales,
                strNumDoc, strTipoTrn, bodcodigo, artcodigo, strTipoDoc,
                intNumLinea);
            }
          });
      }
    }


  }

  crearNuevosSeriales(intNumSeriales, strNumDoc, strTipoTrn,
    strBodCodigo, strArtCodigo, strTipoDoc, NumLineaDoc) {
    this.tablaseriales = [];
    this.serialseleccionado = {};
    for (let fila = 1; fila <= intNumSeriales; fila++) {
      const item: InvTrnkardexotr = {};

      item.TRNKARDEXOTR_NUMERO = strNumDoc;
      item.TRNKARDEXOTR_NUMSEC = 0;
      item.COM_CODIGO = '01';
      item.TRNKARDEXOTR_TIPO = strTipoTrn;
      item.BOD_CODIGO = strBodCodigo;
      item.ART_CODIGO = strArtCodigo;
      item.TRNKARDEXOTR_NUMSERIE = '';
      item.TRNKARDEXOTR_DIM = '';
      item.TRNKARDEXOTR_TELEFONO = '';
      item.TRNKARDEXOTR_ORIGEN = strTipoDoc;
      item.TRNKARDEXOTR_LINEA = NumLineaDoc;
      item.TRNKARDEXOTR_FECHATRN = '';

      this.listaseriales.push(item);
    }
    this.tablaseriales = this.listaseriales;
    // this.aggrid.refreshaggrid(this.tablaseriales, this.defaultColDefSeriales.objeto);

  }

  guardarRegistro() {
    if (this.strTipoDoc === 'FACPRO' || this.strTipoDoc === 'NOTREC'
      || this.strTipoDoc === 'INVENT' || this.strTipoDoc === 'DVFVEN') {
      this.validaEntradaSerial(this.strTipoDoc, this.strNumFact);


    } else if (this.strTipoDoc === 'INVSAL' || this.strTipoDoc === 'VEN'
      || this.strTipoDoc === 'GRE' || this.strTipoDoc === 'DVFCOM'
      || this.strTipoDoc === 'MANTEGR' || this.strTipoDoc === 'TRF' || this.strTipoDoc === 'VENPTOVTA') {
      this.validaSalidaSerial(this.strTipoDoc, this.strNumFact);
    } else if (this.strTipoDoc === 'MANTING') {
      /* if (this.strNumFact.length > 0) {
         this.verificarExistenciaArtSalioFac(this.strNumFact);
         if (this.bolverificarExistenciaArtSalioFac === false) {
           bolinsertarSeriales = false;
         }
         this.validaEntradaSerial(this.strTipoDoc, this.strNumFact);
         if (this.bolvalidaEntradaSerial === false) {
           bolinsertarSeriales = false;
         }
         this.validaExistenciaSerialDimEntrada(this.strTipoDoc, this.strNumFact);
         if (this.bolvalidaExistenciaSerialDimEntrada === false) {
           bolinsertarSeriales = false;
         }
       }*/
    }
  }

  metodoinsertar(item) {
    let intFechaDoc: string;
    if (this.strFechaSer.toString().includes('-')) {
      intFechaDoc = this.datePipe.transform(this.strFechaSer, 'dd/MM/yyyy');
    } else {
      intFechaDoc = this.strFechaSer;
    }
    if (this.strTipoDoc === 'FACPRO' || this.strTipoDoc === 'NOTREC') {
      this.insertarSeriales('COM', this.detalleseleccionado.ARTCODIGO,
        intFechaDoc, this.strNumFact, item);
    } //CDPJ
    else if (this.strTipoDoc === 'TRF') {
      item.TRNKARDEXOTR_NUMERO = item.TRNKARDEXOTR_NUMERO + '_SA';
      //ingresamos la salida
      this.insertarSeriales(this.strTipoDoc, this.detalleseleccionado.ARTCODIGO,
        intFechaDoc, this.strNumFact, item);
      //ingresamos la entrada
      item.TRNKARDEXOTR_NUMERO = item.TRNKARDEXOTR_NUMERO.toString().replace('_SA', '');
      item.BOD_CODIGO = this.strnBoddestino;
      item.TRNKARDEXOTR_TIPO = 'EN'
      this.insertarSeriales(this.strTipoDoc, this.detalleseleccionado.ARTCODIGO,
        intFechaDoc, this.strNumFact, item);
    }//CDPJ
    else {
      this.insertarSeriales(this.strTipoDoc, this.detalleseleccionado.ARTCODIGO,
        intFechaDoc, this.strNumFact, item);
    }
    this.mostrarSerialesGrilla(this.strTipoDoc, this.detalleseleccionado);//CDPJ
    this.habilitarBotones(true, true, false, true);
  }

  validaEntradaSerial(strTipoDoc, strNumFact) {

    let intExistencia = 0;

    for (let fila = 1; fila <= this.tablaseriales.length; fila++) {
      let item: InvTrnkardexotr;
      item = this.tablaseriales[fila - 1];


      this.trnkardexotrservice.esExisteSerial(item.TRNKARDEXOTR_NUMSERIE, item.ART_CODIGO).subscribe(res => {
        for (const rs of res) {
          intExistencia = rs.SERIAL;
          if (intExistencia === 1) {
            this.messageService.add({
              key: 'seriales',
              severity: 'warn',
              summary: 'Información',
              detail: 'El Número de Serie ya Existe.'
            });
          } else {
            this.validaExistenciaSerialDimEntrada(this.strTipoDoc, this.strNumFact, item);
          }
        }
      });
    }
  }

  validaExistenciaSerialDimEntrada(strTipoDoc, strNumDoc, item): boolean {
    let intExistencia = 0;

    this.trnkardexotrservice.esvalExisteSerial(strTipoDoc, strNumDoc, item).subscribe(res => {
      for (const rs of res) {
        intExistencia = rs.SERIAL;

        if (intExistencia === 1) {
          this.messageService.add({
            key: 'seriales',
            severity: 'warn',
            summary: 'Información',
            detail: 'El Número de Serie ya Existe.'
          });
        } else {
          this.validaExistenciaSerialDimEntradaCu(strTipoDoc, strNumDoc, item);
        }
      }
    });
    return false;
  }

  validaSalidaSerial(strTipoDoc, strNumFact) {
    let intExistencia = 0;
    for (let fila = 1; fila <= this.tablaseriales.length; fila++) {
      let item: InvTrnkardexotr;
      item = this.tablaseriales[fila - 1];

      this.trnkardexotrservice.esvalSalidaSerial(item.TRNKARDEXOTR_NUMSERIE, item.ART_CODIGO, item.BOD_CODIGO).subscribe((res) => {
        intExistencia = res[0].SERIAL;
        if (intExistencia === 0) {
          this.messageService.add({
            key: 'seriales',
            severity: 'warn',
            summary: 'Información',
            detail: 'No existe serial.'
          });
        } else {
          this.validaExistenciaSerialDimSalida(this.strTipoDoc, this.strNumFact, item);
        }
      });
    }
  }

  validaExistenciaSerialDimSalida(strTipoDoc, strNumDoc, item) {
    let intExistencia = 0;


    this.trnkardexotrservice.esvalidaExistenciaSerialDimSalida(strTipoDoc, strNumDoc, item).subscribe(res => {
      for (const rs of res) {
        intExistencia = rs.SERIAL;

        if (intExistencia === 0) {
          this.messageService.add({
            key: 'seriales',
            severity: 'warn',
            summary: 'Información',
            detail: 'Número de Serie No está disponible.'
          });
        } else {


          this.trnkardexotrservice.esValiexiserialdimsal(item.ART_CODIGO, item.TRNKARDEXOTR_NUMSERIE).subscribe(data => {
            if (data.length === 0) {
              this.messageService.add({
                key: 'seriales',
                severity: 'warn',
                summary: 'Información',
                detail: 'Número de Serie no corresponde al artículo.'
              });
            } else {
              this.ValidaExistenciaSerialDimSalidaCu(strTipoDoc, strNumDoc, item);
            }
          });
        }
      }
    });
  }

  verificarExistenciaArtSalioFac(strNumFact): boolean {
    return false;

  }

  ValidaExistenciaSerialDimSalidaCu(strTipoDoc, strNumDoc, item) {
    let strSentencia = '';
    let intExistencia = 0;
    let strSql = '';
    const strBodOrigen = '';

    this.trnkardexotrservice.esValExisciaSerDimSalCu(strTipoDoc, strNumDoc, item).subscribe(res => {
      for (const rs of res) {
        intExistencia = rs.SERIAL;

        if (intExistencia === 0) {
          this.messageService.add({
            key: 'seriales',
            severity: 'warn',
            summary: 'Información',
            detail: 'Número de Serie No está disponible.'
          });
        } else {

          this.trnkardexotrservice.esVaExisciaSerDiSalCu2(item.ART_CODIGO, item.TRNKARDEXOTR_NUMSERIE).subscribe(data => {
            if (data.length === 0) {
              this.messageService.add({
                key: 'seriales',
                severity: 'warn',
                summary: 'Información',
                detail: 'Número de Serie no corresponde al artículo.'
              });
            } else {
              // verifica existencia del dim
              if (item.TRNKARDEXOTR_DIM === null) {
                item.TRNKARDEXOTR_DIM = '';
              }
              if (item.TRNKARDEXOTR_DIM.length > 0) {

                this.trnkardexotrservice.esVaExsciaSerDiSalCu3(strTipoDoc, strNumDoc, item).subscribe(res2 => {
                  for (const rs2 of res2) {
                    intExistencia = rs2.SERIAL;

                    if (intExistencia === 0) {
                      this.messageService.add({
                        key: 'seriales',
                        severity: 'warn',
                        summary: 'Información',
                        detail: 'Número de Dim No está disponible.'
                      });
                    } else {

                      this.trnkardexotrservice.esValExsciaSerDiSalCu4(item.ART_CODIGO, item.TRNKARDEXOTR_NUMSERIE).subscribe(data2 => {
                        if (data2.length === 0) {
                          this.messageService.add({
                            key: 'seriales',
                            severity: 'warn',
                            summary: 'Información',
                            detail: 'Número de Dim no corresponde al artículo.'
                          });
                        } else {
                          console.log('ingreso');

                          this.metodoinsertar(item);
                        }
                      });
                    }
                  }
                });
              } else {
                this.metodoinsertar(item);
              }
            }
          });
        }
      }
    });
  }

  validaExistenciaSerialDimEntradaCu(strTipoDoc, strNumDoc, item) {
    let strSentencia = '';
    let intExistencia = 0;
    let strSql = '';
    const strBodOrigen = '';

    this.trnkardexotrservice.validExistSerDimEntradCu5(strTipoDoc, strNumDoc, item).subscribe(res => {
      for (const rs of res) {
        intExistencia = rs.SERIAL;

        if (intExistencia === 1) {
          this.messageService.add({
            key: 'seriales',
            severity: 'warn',
            summary: 'Información',
            detail: 'El Número de Serie ya Existe'
          });
        } else {

          if (item.TRNKARDEXOTR_DIM === null) {
            item.TRNKARDEXOTR_DIM = '';
          }
          if (item.TRNKARDEXOTR_DIM.length > 0) {

            this.trnkardexotrservice.valiExistSerDimEntradaCu(strTipoDoc, strNumDoc, item).subscribe(res2 => {
              for (const rs2 of res2) {
                intExistencia = rs2.SERIAL;

                if (intExistencia === 0) {
                  this.messageService.add({
                    key: 'seriales',
                    severity: 'warn',
                    summary: 'Información',
                    detail: 'Número de Dim ya Existe.'
                  });
                } else {
                  this.metodoinsertar(item);
                }
              }
            });
          } else {
            this.metodoinsertar(item);
          }
        }
      }
    });
  }

  validarDatos(strArtCodigo) {
    this.bolvalidarDatos = true;
    let strActivarSerial = 'N';
    let strActivarDim = 'N';
    let strActivarNumeroTel = 'N';

    // Obligar el ingreso del serial, dim o teléfono si en la ficha del
    // artículo está activado
    for (let i = 0; i < 1; i++) {


      this.trnkardexotrservice.validarDatosArticulo(strArtCodigo).subscribe(res => {
        for (const rs of res) {
          if (rs.ART_ACTIVARSERIAL != null) {
            strActivarSerial = rs.ART_ACTIVARSERIAL;
          }
          if (rs.ART_ACTIVARDIM != null) {
            strActivarDim = rs.ART_ACTIVARDIM;
          }
          if (rs.ART_ACTIVARNUMEROTEL != null) {
            strActivarNumeroTel = rs.ART_ACTIVARNUMEROTEL;
          }
        }

        for (let fila = 1; fila <= this.tablaseriales.length; fila++) {
          let item: InvTrnkardexotr;
          item = this.tablaseriales[fila - 1];

          if (item.TRNKARDEXOTR_NUMSERIE === null) {
            item.TRNKARDEXOTR_NUMSERIE = '';
          }
          if (item.TRNKARDEXOTR_DIM === null) {
            item.TRNKARDEXOTR_DIM = '';
          }
          if (item.TRNKARDEXOTR_TELEFONO === null) {
            item.TRNKARDEXOTR_TELEFONO = '';
          }

          if (item.TRNKARDEXOTR_NUMSERIE.length === 0) {
            if (strActivarSerial === 'S') {
              this.messageService.add({
                key: 'seriales',
                severity: 'warn',
                summary: 'Información',
                detail: 'Ingrese el número de Serie.'
              });
              this.bolvalidarDatos = false;
              break;
            }
          }

          if (item.TRNKARDEXOTR_DIM.length === 0) {
            if (strActivarDim === 'S') {
              this.messageService.add({
                key: 'seriales',
                severity: 'warn',
                summary: 'Información',
                detail: 'Ingrese el número de Sim.'
              });
              this.bolvalidarDatos = false;
              break;
            }
          }

          if (item.TRNKARDEXOTR_TELEFONO != null
            && item.TRNKARDEXOTR_TELEFONO.length === 0) {
            if (strActivarNumeroTel === 'S') {
              this.messageService.add({
                key: 'seriales',
                severity: 'warn',
                summary: 'Información',
                detail: 'Ingrese el número de Teléfono.'
              });
              this.bolvalidarDatos = false;
              break;
            }
          }
        }
      });
    }
  }


  insertarSeriales(strTipoDoc, strArtCodigo,
    intFechaDoc, strNumDoc, item) {
    this.validarDatos(strArtCodigo);
    if (this.bolvalidarDatos) {
      if (item.TRNKARDEXOTR_NUMSERIE.length > 0
        || item.TRNKARDEXOTR_DIM.length > 0) {
        this.trnkardexotrservice.insertarKardexOtr(item, strTipoDoc, intFechaDoc).subscribe(res => {
          this.messageService.add({
            key: 'seriales',
            severity: 'success',
            summary: 'Información',
            detail: 'Seriales Guardados Correctamente'
          });
        });
        //CDPJ
        this.auditoriagral.registrarAuditoria('INV_TRNKARDEXOTR_' + this.strTipoDoc,
          item.TRNKARDEXOTR_NUMERO, 'I',
          '', '01', '', '', '', '').subscribe();
        //CDPJ
      }
    }
  }

  seleccionSerial(valor) {

  }

  seleccionarSeriales() {
    if (this.selectAll === true) {
      this.tablaseriales.map((serial) => {
        serial.SELECCIONADO = true;
      });
      this.aggrid.refreshaggrid(this.tablaseriales, this.defaultColDefSeriales.objeto);
    } else if (this.selectAll === false) {
      this.tablaseriales.map((serial) => {
        serial.SELECCIONADO = false;
      });
      this.aggrid.refreshaggrid(this.tablaseriales, this.defaultColDefSeriales.objeto);
    }
  }

  habilitarBotones(guardar, eliminar,
    cancelar, imprimir) {
    this.botonGuardar = guardar;
    this.botonBorrar = eliminar;
    this.botonRegresar = cancelar;
  }
}

