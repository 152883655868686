
<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="trnfichatec"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="trnfichaTecConf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 100%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
						 [botonBorrar]="btnBorrar" [barraBotonesDesplaz]="true" [barraBotones3]="true"
						 [barraBotonesAsiCon]="true" [botonverAsiento]="true"
						 (signal)="manejarSenales($event)">
		</app-invmaebarra>
	</div>
</div>

<p-tabView (onChange)="onTab1Change($event)" [activeIndex]="selectTab">
	<p-tabPanel header="Ficha Técnica - 1" [disabled]="boolIndex0">
		<div class="divgrups" id="divPrincipal3">
            <div class="p-col-12">
                 <div class="p-grid">
                    <div class="p-col-2" style="text-align: left">
                        <input type="text" pInputText
                                style="background-color: #EFFFF7; text-align: center;"
                                disabled [(ngModel)]="lblCodArticulo">
                    </div>
                    <div class="p-col-4" style="text-align: left">
                        <input type="text" pInputText
                                style="background-color: #EFFFF7; text-align: center;"
                                disabled [(ngModel)]="lblNomArticulo">
                    </div>
                </div>
            </div>
            <div class="p-col-12">
				<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
					<div class="p-col-2" style="text-align: left">
						<span>Código:</span>
					</div>
					<div class="p-col-1">
						<input type="text" id="codigo" pInputText style="background-color: white"
							   (keydown.enter)="cambiarFoco($event)" (keydown)="activarBoton('M')"
							   [(ngModel)]="txtCodigo">
                    </div>
                    <div class="p-col-1" style="text-align: right">
						<span>F.Vigencia:</span>
					</div>
					<div class="p-col-1">
						<p-calendar [(ngModel)]="dateTimeFechaVigencia"
											dateFormat="dd/mm/yy" [inputStyle]="{'width': '100px'}"
                                             inputId="fVigencia">
                        </p-calendar>
                    </div>
                    <div class="p-col-1" style="text-align: right">
						<span>Versión:</span>
					</div>
					<div class="p-col-1">
						<input type="text" id="version" pInputText style="background-color: white"
							   (keydown.enter)="cambiarFoco($event)" (keydown)="activarBoton('M')"
							   [(ngModel)]="txtVersion">
                    </div>
					<div class="p-col-1" style="text-align: right">
						<span>Título:</span>
					</div>
					<div class="p-col-3">
						<input type="text" id="titulo" pInputText style="background-color: white"
							   (keydown.enter)="cambiarFoco($event)" (keydown)="activarBoton('M')"
							   [(ngModel)]="txtTitulo">
                    </div>
				</div>
            </div>
            <div class="p-col-12">
				<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
					<div class="p-col-2" style="text-align: left">
						<span>Cliente:</span>
					</div>
					<div class="p-col-2">
						<input type="text" id="clicodigo" pInputText style="background-color: white"
							   (keydown.enter)="cambiarFoco($event)"
							   [(ngModel)]="txtCliCodigo">
                    </div>
                    <div class="p-col-1">
						<button type="button" style="background-image: url(../../../assets/images/iconos/buscar.png);"
								class="littlebuttons" (click)="buscarCliente(txtCliCodigo)"></button>
					</div>
					<div class="p-col-6">
						<input type="text" pInputText style="background-color: white"
							   [(ngModel)]="lblCLInombre" disabled>
					</div>

				</div>
            </div>
            <div class="p-col-12">
				<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
					<div class="p-col-2" style="text-align: left">
						<span>Declaración:</span>
					</div>
					<div class="p-col-9">
						<input type="text" id="declaracion" pInputText style="background-color: white"
							   (keydown.enter)="cambiarFoco($event)" (keydown)="activarBoton('M')"
							   [(ngModel)]="txtDeclaracion">
                    </div>
				</div>
            </div>
            <div class="p-col-12">
				<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
					<div class="p-col-2" style="text-align: left">
						<span>Calidad/Regulación:</span>
					</div>
					<div class="p-col-9">
						<input type="text" id="calidad" pInputText style="background-color: white"
							   (keydown.enter)="cambiarFoco($event)" (keydown)="activarBoton('M')"
							   [(ngModel)]="txtCalidadRegulacion">
                    </div>
				</div>
            </div>
            <div class="p-col-12">
				<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
					<div class="p-col-2" style="text-align: left">
						<span>Campo Aplicación:</span>
					</div>
					<div class="p-col-9">
						<input type="text" id="aplicacion" pInputText style="background-color: white"
							   (keydown.enter)="cambiarFoco($event)" (keydown)="activarBoton('M')"
							   [(ngModel)]="txtAplicacion">
                    </div>
				</div>
            </div>
            <div class="p-col-12">
				<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
					<div class="p-col-2" style="text-align: left">
						<span>Composición:</span>
					</div>
					<div class="p-col-9">
                        <textarea id="composicion" pInputTextarea (keydown)="activarBoton('M')"  [rows]="4" [cols]="140" [(ngModel)]="txtComposicion" (keydown.enter)="cambiarFoco($event)"></textarea>
                    </div>
				</div>
            </div>
            <div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-2" style="text-align: left">
						<span>Vida Anaquel:</span>
					</div>
					<div class="p-col-9">
                        <textarea id="anaquel" pInputTextarea (keydown)="activarBoton('M')" [rows]="3" [cols]="140" [(ngModel)]="txtVidaAnaquel" (keydown.enter)="cambiarFoco($event)"></textarea>
                    </div>
				</div>
            </div>
            <div class="p-col-12">
				<div class="p-grid" style="margin-top: 10px; margin-bottom: 10px">
					<div class="p-col-2" style="text-align: left">
						<span style="text-align: center; font-weight: bold">CARACTERISTICA SENSORIAL</span>
					</div>
				</div>
            </div>
            <div class="p-col-12">
                <div class="p-grid" style="margin-top: -10px; margin-bottom: 20px">
					<div class="p-col-1" style="text-align: left">
						<span>Estado físico:</span>
					</div>
					<div class="p-col-3">
						<input type="text" id="estadofisico" pInputText style="background-color: white"
							   (keydown.enter)="cambiarFoco($event)" (keydown)="activarBoton('M')"
							   [(ngModel)]="txtEstadoFisico">
                    </div>
                    <div class="p-col-1" style="text-align: left">
						<span>Color:</span>
					</div>
					<div class="p-col-2">
						<input type="text" id="color" pInputText style="background-color: white"
							   (keydown.enter)="cambiarFoco($event)" (keydown)="activarBoton('M')"
							   [(ngModel)]="txtColor">
                    </div>
                    <div class="p-col-2" style="text-align: left">
						<span>Perfíl aromático:</span>
					</div>
					<div class="p-col-2">
						<input type="text" id="aromatico" pInputText style="background-color: white"
							   (keydown.enter)="cambiarFoco($event)" (keydown)="activarBoton('M')"
							   [(ngModel)]="txtPerfilAromatico">
                    </div>
					
				</div>
            </div>
            <div class="p-col-12">
				<div class="p-grid" style="margin-top: 10px; margin-bottom: -10px">
					<div class="p-col-2" style="text-align: left">
						<span>Forma de Presentación:</span>
					</div>
					<div class="p-col-9">
                        <textarea id="presentacion" pInputTextarea (keydown)="activarBoton('M')" [rows]="3" [cols]="140" [(ngModel)]="txtPresentacion" (keydown.enter)="cambiarFoco($event)"></textarea>
                    </div>
				</div>
            </div>
            <div class="p-col-12">
				<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
					<div class="p-col-2" style="text-align: left">
						<span>Manejo y porcedimientos de almacen:</span>
					</div>
					<div class="p-col-9">
                        <textarea id="manejo" pInputTextarea (keydown)="activarBoton('M')" [rows]="3" [cols]="140" [(ngModel)]="txtManejoProcedimiento" (keydown.enter)="cambiarFoco($event)"></textarea>
                    </div>
				</div>
            </div>
            <div class="p-col-12">
				<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
					<div class="p-col-2" style="text-align: left">
						<span>Certificado analítico:</span>
					</div>
					<div class="p-col-9">
                        <textarea id="certificado" pInputTextarea (keydown)="activarBoton('M')" [rows]="3" [cols]="140" [(ngModel)]="txtCertificadoAnalitico" (keydown.enter)="cambiarFoco($event)"></textarea>
                    </div>
				</div>
            </div>
            <div class="p-col-12">
				<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
					<div class="p-col-2" style="text-align: left">
						<span>Revisado por:</span>
					</div>
					<div class="p-col-9">
                        <input type="text" id="revisado" pInputText style="background-color: white"
							   (keydown.enter)="cambiarFoco($event)" (keydown)="activarBoton('M')"
							   [(ngModel)]="txtRevisado">
                    </div>
				</div>
            </div>
            
        </div>
	</p-tabPanel>
	<p-tabPanel header="Ficha Técnica - 2" [disabled]="boolIndex1">
		<div class="divgrups" id="divPrincipal4">
			<div class="p-grid">
				<div class="p-col-3">
					<span style="font-weight: bold">CARACTERISTICA FISICO-QUIMICAS</span>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-7">
							<app-aggridsaci [width]=""
											[height]="largo"
											[enableSorting]="true"
											[enableFilter]="true"
											[rowData]="detalleFisicoQuim"
											[frameworkComponents]="frameworkComponents"
											[animateRows]="true"
											[rowSelection]="rowSelection"
											[columnDefs]="columnDefsFisicoQuim"
											[defaultColDef]="defaultFisicoQuimico"
											[rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="true"
											[mostrarGuardar]="false"
											[botonNuevo]="false"
											[botonRegresar]="true"
											[botonBorrar]="false"
											(selected)="seleccionDetFisQuim($event)"
											(cambios)="cambio($event)"
							>
							</app-aggridsaci>
						</div>
					</div>
				</div>

				<div class="p-col-3">
					<span style="font-weight: bold">CARACTERISTICAS MICROBIOLOGICAS</span>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-7">
							<app-aggridsaci [width]=""
											[height]="largo"
											[enableSorting]="true"
											[enableFilter]="true"
											[rowData]="detalleMicrobiologica"
											[frameworkComponents]="frameworkComponents"
											[animateRows]="true"
											[rowSelection]="rowSelection"
											[columnDefs]="columnDefsMicrobio"
											[defaultColDef]="defaultMicrobiologica"
											[rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="true"
											[mostrarGuardar]="false"
											[botonNuevo]="false"
											[botonRegresar]="true"
											[botonBorrar]="false"
											(selected)="seleccionDetMicrobiol($event)"
											(cambios)="cambio($event)"
							>
							</app-aggridsaci>
						</div>
					</div>
				</div>

			</div>
		</div>
	</p-tabPanel>

</p-tabView>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
		  appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
					 [busquedaParams]="busquedacampos" [consulta]="consulta"
					 (opcionSeleccionada)="manejarSeleccion($event)">
	</app-busquedadlg>
</p-dialog>

