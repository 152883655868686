<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="contcal"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmcontcal" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<app-invmaebarra [botonGuardar]="!botonGuardar" [botonBorrar]="!botonBorrar" [botonRegresar]="botonRegresar"
	[barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true"
	[botonVisto]="false" [barraBotones3Alt]="false" (signal)="manejarSenales($event)" [noNuevo]="true"
	[botonImprimir]="true">
</app-invmaebarra>
<div class="p-grid" style="width: 99%;">
	<!--LINEA 1-->
	<div class="p-col-2" style="text-align: left">
		<span>ARTICULO:</span>
	</div>
	<div class="p-col-2">
		<label for="lblCodArticulo"></label>
		<input class="frm" id="lblCodArticulo" type="text" pInputText
			style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
			[disabled]="true" [(ngModel)]="encordproService.trncontrolcalidad[indicador].ART_CODIGO" autocomplete="off">
	</div>
	<div class="p-col-5">
		<label for="lblNomArticulo"></label>
		<input class="frm" id="lblNomArticulo" type="text" pInputText
			style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
			[disabled]="true" [(ngModel)]="encordproService.trncontrolcalidad[indicador].ART_NOMBRE" autocomplete="off">
	</div>
	<div class="p-col-2">
		<label for="lblEncOrdPro"></label>
		<input class="frm" id="lblEncOrdPro" type="text" pInputText
			style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
			[disabled]="true" [(ngModel)]="encordproService.trncontrolcalidad[indicador].ENCORDPRO_NUMERO"
			autocomplete="off">
	</div>
	<div class="p-col-1">
		<label for="lblEstado"></label>
		<input class="frm" id="lblEstado" type="text" pInputText
			style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
			[disabled]="true" [(ngModel)]="encordproService.trncontrolcalidad[indicador].TRNCON_ESTADO"
			autocomplete="off">
	</div>
</div>
<p-tabView [(activeIndex)]="index" [style]="{'width':'98%'}">
	<p-tabPanel header="Certificado de Calidad">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-2" style="text-align: left">
						<span>Código:</span>
					</div>
					<div class="p-col-2">
						<input type="text" id="txtCodigo" pInputText style="background-color: white"
							(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
							[(ngModel)]="encordproService.trncontrolcalidad[indicador].TRNCON_CODIGO">
					</div>
					<div class="p-col-2" style="text-align: right">
						<span>F.Vigencia:</span>
					</div>
					<div class="p-col-2">
						<p-calendar [(ngModel)]="encordproService.trncontrolcalidad[indicador].TRNCON_FECHAVIGENCIA"
						[readonlyInput]="false" (keydown)="botonesmodificar()"
							dateFormat="dd/mm/yy" [inputStyle]="{'width': '100px'}" inputId="fVigencia">
						</p-calendar>
					</div>
					<div class="p-col-2" style="text-align: right">
						<span>Versión:</span>
					</div>
					<div class="p-col-2">
						<input type="text" id="version" pInputText style="background-color: white"
							(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
							[(ngModel)]="encordproService.trncontrolcalidad[indicador].TRNCON_VERSION">
					</div>
				</div>
			</div>
			<div class="p-col-12" style="margin-top: -10px">
				<div class="p-grid">
					<div class="p-col-2" style="text-align: left">
						<span>Fecha Caducidad:</span>
					</div>
					<div class="p-col-2">
						<p-calendar [(ngModel)]="encordproService.trncontrolcalidad[indicador].TRNCON_FECHACADUCIDAD"
							[readonlyInput]="false" (keydown)="botonesmodificar()"
							dateFormat="dd/mm/yy" [inputStyle]="{'width': '100px'}" inputId="DTFechaCaducidad">
						</p-calendar>
					</div>
					<div class="p-col-2" style="text-align: right">
						<span>Fecha último análisis:</span>
					</div>
					<div class="p-col-2">
						<p-calendar [(ngModel)]="encordproService.trncontrolcalidad[indicador].TRNCON_FECHAULTIMOANA"
							[readonlyInput]="false" (keydown)="botonesmodificar()"
							dateFormat="dd/mm/yy" [inputStyle]="{'width': '100px'}" inputId="DTFechaUltimoAnalisis">
						</p-calendar>
					</div>
				</div>
			</div>
			<div class="p-col-12" style="margin-top: -10px">
				<div class="p-grid">
					<div class="p-col-2" style="text-align: left">
						<span>Estado físico:</span>
					</div>
					<div class="p-col-10">
						<label for="txtCCEstadoFisico"></label>
						<input class="frm" id="txtCCEstadoFisico" type="text" pInputText
							[(ngModel)]="encordproService.trncontrolcalidad[indicador].TRNCON_ESTADOFISICO"
							(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
					</div>
				</div>
			</div>
			<div class="p-col-12" style="margin-top: -10px">
				<div class="p-grid">
					<div class="p-col-2" style="text-align: left">
						<span>Color:</span>
					</div>
					<div class="p-col-10">
						<label for="txtCCColor"></label>
						<input class="frm" id="txtCCColor" type="text" pInputText
							[(ngModel)]="encordproService.trncontrolcalidad[indicador].TRNCON_COLOR"
							(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
					</div>
				</div>
			</div>
			<div class="p-col-12" style="margin-top: -10px">
				<div class="p-grid">
					<div class="p-col-2" style="text-align: left">
						<span>Olor:</span>
					</div>
					<div class="p-col-10">
						<label for="txtCCOlor"></label>
						<input class="frm" id="txtCCOlor" type="text" pInputText
							[(ngModel)]="encordproService.trncontrolcalidad[indicador].TRNCON_OLOR"
							(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
					</div>
				</div>
			</div>
			<div class="p-col-12" style="margin-top: -10px">
				<div class="p-grid">
					<div class="p-col-2" style="text-align: left">
						<span>Sabor:</span>
					</div>
					<div class="p-col-10">
						<label for="txtCCsabor"></label>
						<input class="frm" id="txtCCsabor" type="text" pInputText
							[(ngModel)]="encordproService.trncontrolcalidad[indicador].TRNCON_SABOR"
							(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
					</div>
				</div>
			</div>
			<div class="p-col-12" style="margin-top: -10px">
				<div class="p-grid">
					<div class="p-col-2" style="text-align: left">
						<span>Lugar:</span>
					</div>
					<div class="p-col-10">
						<label for="txtccLugar"></label>
						<input class="frm" id="txtccLugar" type="text" pInputText
							[(ngModel)]="encordproService.trncontrolcalidad[indicador].TRNCON_LUGAR"
							(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
					</div>
				</div>
			</div>
			<div class="p-col-12" style="margin-top: -10px">
				<div class="p-grid">
					<div class="p-col-2" style="text-align: left">
						<span>Recomendaciones:</span>
					</div>
					<div class="p-col-10">
						<label for="txtRecomendaciones"></label>
						<input class="frm" id="txtRecomendaciones" type="text" pInputText
							[(ngModel)]="encordproService.trncontrolcalidad[indicador].TRNCON_RECOMENDACIONES"
							(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
					</div>
				</div>
			</div>
			<div class="p-col-12" style="margin-top: -10px">
				<div class="p-grid">
					<div class="p-col-2" style="text-align: left">
						<span>Nota:</span>
					</div>
					<div class="p-col-10">
						<label for="txtNota"></label>
						<input class="frm" id="txtNota" type="text" pInputText
							[(ngModel)]="encordproService.trncontrolcalidad[indicador].TRNCON_NOTA"
							(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
					</div>
				</div>
			</div>
			<div class="p-col-12" style="margin-top: -10px">
				<div class="p-grid">
					<div class="p-col-2" style="text-align: left">
						<span>Observaciones:</span>
					</div>
					<div class="p-col-10">
						<label for="txtObservaciones"></label>
						<input class="frm" id="txtObservaciones" type="text" pInputText
							[(ngModel)]="encordproService.trncontrolcalidad[indicador].TRNCON_OBSERVACIONES"
							(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
					</div>
				</div>
			</div>
			<div class="p-col-12" style="margin-top: -10px">
				<div class="p-grid">
					<div class="p-col-2" style="text-align: left">
						<span>Revisado:</span>
					</div>
					<div class="p-col-10">
						<label for="txtRevisado"></label>
						<input class="frm" id="txtRevisado" type="text" pInputText
							[(ngModel)]="encordproService.trncontrolcalidad[indicador].TRNCON_REVISADO"
							(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Análisis Físico Químico">
		<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
			[rowData]="detalleFisicoQuim" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
			[rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true" (selected)="seleccionDetFisQuim($event)"
			[mostrarGuardar]="false" (cambios)="cambio()"></app-aggridsaci>
	</p-tabPanel>
</p-tabView>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
		[busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Clave Autorización" [(visible)]="displayDialogAutorizacion" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" (onHide)="onHideAutorizacion()" [style]="{width: '40vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="autorizacion"></p-toast>
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							(keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog header="Reportes" [(visible)]="displayDialogReportes" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '40vw', height: '400px'}">
    <app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
        [strNumDoc]="EncOrdPro" [strCampo]="'ENCORDPRO_NUMERO'"
        (cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>
