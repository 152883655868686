import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {SaciMaeNumeracion} from '../seginterfaces/segmaeusuario';

@Injectable({
  providedIn: 'root'
})
export class SacimaenumeracionCService {
  private _detNumeracionC: SaciMaeNumeracion[] = []; // por esto esta el get
  
  get detNumeracionC(): SaciMaeNumeracion[] {
    return this._detNumeracionC;
  }
  set detNumeracionC( value: SaciMaeNumeracion[]) {
    this._detNumeracionC = value;
  }
  
 
  private way: string;
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }
  
  
  consultarNumeracionC( ) {
    return this.http.post<any[]>(this.way + '/seg/numeracionc/20eedfd676d4ca0000', {
      elementos: {
        p_NUM_orden: 'NUM_clase',
        p_NUM_tipo: 'C',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  insertarNumeracionC(item: SaciMaeNumeracion  ) {
    return this.http.post<any[]>(this.way + '/seg/numeracionc/12bf1dbedfd677000', {
      elementos: {
        p_NUM_clase: item.NUM_CLASE, //
        p_COM_codigo: item.COM_CODIGO, //
        p_NUM_docref: item.NUM_DOCREF, //
        p_NUM_nemonico: item.NUM_NEMONICO, //
        p_NUM_serie: item.NUM_SERIE, //
        p_NUM_secinicial: item.NUM_SECINICIAL, //
        p_NUM_secfinal: item.NUM_SECFINAL, //
        p_NUM_secactual: item.NUM_SECACTUAL, //
        p_NUM_tipo: 'C', //
        p_NUM_autorizacion: item.NUM_AUTORIZACION, //
        p_NUM_fechacad: item.NUM_FECHACAD, //
        p_NUM_fechaini: item.NUM_FECHAINI, //
        p_NUM_autoimpresor: item.NUM_AUTOIMPRESOR, //
        p_NUM_autorizacionant: item.NUM_AUTORIZACIONANT, //
        p_Num_fe:item.NUM_FE
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  actualizarNumeracionC( item: SaciMaeNumeracion  ) {
    return this.http.post<any[]>(this.way + '/seg/numeracionc/299df5fd676d4c', {
      elementos: {
        p_NUM_clase: item.NUM_CLASE,
        p_COM_codigo: item.COM_CODIGO, //
        p_NUM_docref: item.NUM_DOCREF, //
        p_NUM_nemonico: item.NUM_NEMONICO, //
        p_NUM_serie: item.NUM_SERIE, //
        p_NUM_secinicial: item.NUM_SECINICIAL, //
        p_NUM_secfinal: item.NUM_SECFINAL, //
        p_NUM_secactual: item.NUM_SECACTUAL, //
        p_NUM_autorizacion: item.NUM_AUTORIZACION, //
        p_NUM_fechacad: item.NUM_FECHACAD, //
        p_NUM_fechaini: item.NUM_FECHAINI, //
        p_NUM_autoimpresor: item.NUM_AUTOIMPRESOR, //
        p_Num_fe:item.NUM_FE
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  elimminarNumeracionC(item: SaciMaeNumeracion  ) {
    const  strSentencia = 'DELETE SACI_MAENUMERACION ' +
      'WHERE NUM_clase  = \'' + item.NUM_CLASE + '\' AND ' +
      'COM_codigo  = \'' + item.COM_CODIGO + '\' AND ' +
      'NUM_docref  = \'' + item.NUM_DOCREF + '\' AND ' +
      'NUM_nemonico  = \'' + item.NUM_NEMONICO + '\' AND ' +
      'NUM_serie  = \'' + item.NUM_SERIE + '\'';
    
    return this.http.post<any[]>(this.way + '/seg/numeracionc/7565695fd676d40', {
        elementos: {
          NUM_CLASE: item.NUM_CLASE,
          COM_CODIGO: item.COM_CODIGO,
          NUM_DOCREF: item.NUM_DOCREF,
          NUM_NEMONICO: item.NUM_NEMONICO,
          NUM_SERIE: item.NUM_SERIE
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
}

