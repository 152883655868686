import { Injectable } from '@angular/core';
import {Venmaeservicios} from '../veninterfaces/venmaeservicios';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Observable} from 'rxjs';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
@Injectable({
  providedIn: 'root'
})
export class VenmaeserviciosService {
    get auxsalto(): boolean {
        return this._auxsalto;
    }
    set auxsalto(value: boolean) {
        this._auxsalto = value;
    }
    get venmaeservicios(): Venmaeservicios[] {
    return this._venmaeservicios;
  }

  set venmaeservicios(value: Venmaeservicios[]) {
    this._venmaeservicios = value;
  }
  private _venmaeservicios: Venmaeservicios[];
  private _auxsalto: boolean;
  public url: string;
  public way: string;
  constructor(private http: HttpClient,
              private confIniciales: ConfInicialesService,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }
  getServicios(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/servicios/83bb9de2d8', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  
  }
  insertarServicio(servicio: Venmaeservicios): Observable<any> {
  
    return this.http.post<any[]>(this.way + '/ventas/servicios/12bf1dbeab7c7700000000', {      
      elementos: {
        p_SER_codigo_1: servicio.SER_CODIGO,
        p_COM_codigo_2: '01',
        p_SER_descripcion_2: servicio.SER_DESCRIPCION,
        p_CON_codigo_3: servicio.CON_CODIGO,
        p_SER_precio_4: Number(servicio.SER_PRECIO),
        p_SER_tributaiva_5: servicio.SER_TRIBUTAIVA,
        p_CEN_codigo_6: servicio.CEN_CODIGO,
        p_SER_formsrivtas: servicio.SER_FORMSRIVTAS,
        p_ser_basenoobjiva: servicio.SER_BASENOOBJIVA,
        p_ser_porceiva: servicio.SER_PORCEIVA,
        p_tariiva_codigo: servicio.TARIIVA_CODIGO,
      },
    }, this.confIniciales.httpOptions());
  }
  eliminarServicio(servicio: Venmaeservicios): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/servicios/eacad2bab7c7700000000', {      
      elementos: {
        p_SER_codigo_1: servicio.SER_CODIGO,
        p_COM_codigo_2: '01',
      },
    }, this.confIniciales.httpOptions());
    
  }
  actualizarServicio(servicio: Venmaeservicios, codigo): Observable<any[]> {
 
    return this.http.post<any[]>(this.way + '/ventas/servicios/299df2ab2', {      
      elementos: {
        p_SER_codigo_1: codigo,
        p_COM_codigo_2: '01',
        p_SER_codigo_2: servicio.SER_CODIGO,
        p_SER_descripcion_3: servicio.SER_DESCRIPCION,
        p_CON_codigo_4: servicio.CON_CODIGO,
        p_SER_precio_5: servicio.SER_PRECIO,
        p_SER_tributaiva_6: servicio.SER_TRIBUTAIVA,
        p_CEN_codigo_7: servicio.CEN_CODIGO,
        p_SER_formsrivtas: servicio.SER_FORMSRIVTAS,
        p_ser_basenoobjiva: servicio.SER_BASENOOBJIVA,
        p_ser_porceiva: servicio.SER_PORCEIVA,
        p_tariiva_codigo: servicio.TARIIVA_CODIGO,
      },
    }, this.confIniciales.httpOptions());
  }
  frSerCodigo(strsercodigo) {
    return this.http.post(this.way + '/ventas/servicios/3f7c76d986ca18', {
      elementos: {
        SER_CODIGO: strsercodigo,       
      },
    }, this.confIniciales.httpOptions());
  }

  verifica_detdvf(strConCodigo) {
    return this.http.post<any[]>(this.way + '/ventas/servicios/7ddb93daeeb7f0', {      
      elementos: {
        conCodigo: strConCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  
  }

  verifica_detgre(strConCodigo) {
    return this.http.post<any[]>(this.way + '/ventas/servicios/7ddb93daeec370', {      
      elementos: {
        conCodigo: strConCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  
  }

  verifica_detped(strConCodigo) {
    return this.http.post<any[]>(this.way + '/ventas/servicios/7ddb93daeee5cc', {      
      elementos: {
        conCodigo: strConCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  
  }

  verifica_detfac(strConCodigo) {
    return this.http.post<any[]>(this.way + '/ventas/servicios/7ddb93daeebd4c', {      
      elementos: {
        conCodigo: strConCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  
  }

  verifica_usoservi(strConCodigo) {
    return this.http.post<any[]>(this.way + '/ventas/servicios/fbb727fb98e3b80', {      
      elementos: {
        conCodigo: strConCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  
  }
  //CDPJ
  encontrarArtCntaySer(strArtCodigo, strtipo) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/75d98bf76adab8000000', {
      elementos: {
        ArtCodigo: strArtCodigo,
        strTIPO: strtipo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerClaveIva(): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/servicios/c2faebbb6caac00', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  actualizarporceiva(tariivacod,tariivaporce) {
    return this.http.post<any[]>(this.way + '/ventas/servicios/299df2ab28', {
      elementos: {
    p_tariiva_codigo: tariivacod,
    p_tariiva_porcentaje: tariivaporce
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
}
