//
// SACI WEB
// Rutina: Maestro de Ventas , sección proyectos
// Propietario: Francisco Cevallos
// Modificado por: FC
// Fecha de creación: 07-11-2019
// Fecha de Modificación: 29-11-2019
//
import {Component, DoCheck, OnInit, ViewChild} from '@angular/core';
import {Venmaepry} from '../veninterfaces/venmaepry';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {VenmaepryService} from '../venservicios/venmaepry.service';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Component({
  selector: 'app-venmaepry',
  templateUrl: './venmaepry.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class VenmaepryComponent implements OnInit, DoCheck {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  desCodigo: boolean;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  actualizar: boolean;
  codigoAux: any;
  nuevoCampo: boolean;
  largo: string;
  prySeleccionado: Venmaepry;
  permisoPry: SegMaePermiso;
  public frameworkComponents;
  rowStyle;
  defaultColDefPry;
  rowSelection = 'multiple';

  columnDefsPry = [
    {
      headerName: 'Proyecto Código', field: 'PRY_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 4
          }
        }
    },
    {
      headerName: 'Nombre', field: 'PRY_NOMBRE', cellEditor: 'cellPrueba', width: 250, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 250
          }
        }
    },
    {
      headerName: 'Cod-Cliente', field: 'CLI_CODIGO', cellEditor: 'cellPrueba', width: 150, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: false,
            saltoslinea: 2,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 20,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Nombre-Cliente', field: 'CLI_NOMBRE', cellEditor: 'cellPrueba', width: 200, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 200
          }
        }
    },
    {
      headerName: 'Bodega 1', field: 'BOD_CODIGO1', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 2,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 10
          }
        }
    },
    {
      headerName: 'Nombre-Bodega 1', field: 'NOM_BOD1', cellEditor: 'cellPrueba', width: 200, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false
          }
        }
    },
    {
      headerName: 'Bodega 2', field: 'BOD_CODIGO2', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 2,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 10
          }
        }
    },
    {
      headerName: 'Nombre-Bodega 2', field: 'NOM_BOD2', cellEditor: 'cellPrueba', width: 200, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false
          }
        }
    },
    {
      headerName: 'Bodega 3', field: 'BOD_CODIGO3', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 2,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 10
          }
        }
    },
    {
      headerName: 'Nombre-Bodega 3', field: 'NOM_BOD3', cellEditor: 'cellPrueba', width: 200, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false
          }
        }
    },
    {
      headerName: 'Bodega 4', field: 'BOD_CODIGO4', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 2,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 10
          }
        }
    },
    {
      headerName: 'Nombre-Bodega 4', field: 'NOM_BOD4', cellEditor: 'cellPrueba', width: 200, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false
          }
        }
    },
    {
      headerName: 'Bodega 5', field: 'BOD_CODIGO5', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 4,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 10
          }
        }
    },
    {
      headerName: 'Nombre-Bodega 5', field: 'NOM_BOD5', cellEditor: 'cellPrueba', width: 200, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false
          }
        }
    },
    {
      headerName: 'Bodega 6', field: 'BOD_CODIGO6', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 10
          }
        }
    },
    {
      headerName: 'Nombre-Bodega 6', field: 'NOM_BOD6', cellEditor: 'cellPrueba', width: 200, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
          }
        }
    },
    {
      headerName: 'Activo ', field: 'PRY_ACTIVO', cellEditor: 'cellPrueba', width: 200, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: false,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            regexp: /^[S]$|^[N]$/,
            tamanocelda: 1
          }
        }
    },
  ];

  constructor(public pryService: VenmaepryService, private message: MessageService, private errorService: ErroresBaseDatosService,
              private confirmationService: ConfirmationService, private permisosService: PermisosService,
              private auditoriagral: AuditoriagralService, private init: NuevoComponentService) {
    this.agTable();
    this.permisoPry = {};
    this.pryService.venmaepry = [];
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefPry = {
      width: 100,
      objeto: 'venmaepry'
    };
    this.permisoPry = {};
  }
  ngOnInit() {
    this.desCodigo = true;
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.actualizar = false;
    this.nuevoCampo = false;
    this.codigoAux = 0;
    this.pryService.getPry().subscribe((res) => {
      if (res!== null){
        this.pryService.venmaepry = res;
      }
    });
    this.largo = '258';
    this.permisosService.getListaPermisos('VEN', '1', '361').subscribe((res) => {
      this.permisoPry = res[0];
    });
  }
  ngDoCheck(): void {
    this.nombreBodega();
  }

  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      if (this.permisoPry.PERINSERCION === 1) {
        this.nuevopry();
      } else if (this.permisoPry.PERINSERCION === 0) {
        this.message.add({
          key: 'venmaepry',
          severity: 'error',
          summary: 'Inserción de Proyecto',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    }
    if (valor === 'Guardar') {
      if (this.permisoPry.PERINSERCION === 1) {
        this.btnGuardar = true;
        this.btnNuevo = false;
        this.btnBorrar = false;
        this.btnRegresar = true;
        if (this.actualizar === true) {
          this.pryService.actualizarPry(this.prySeleccionado, this.codigoAux).subscribe(() => {
            this.message.add({
              key: 'venmaepry',
              severity: 'success',
              summary: 'Actualización exitosa',
              detail: 'Se actualizó el proyecto correctamente'
            });
            this.auditoriagral.registrarAuditoria('VEN_MAEPRY', String(this.prySeleccionado.PRY_CODIGO), 'A',
              '', '01', '', '', '', '').subscribe(() => {});
            this.desCodigo = false;
          }, error1 => {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.message.add({
              key: 'venmaepry',
              severity: 'error',
              summary: 'Actualización de Proyecto',
              detail: mensaje
            });
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
            this.btnRegresar = true;
            this.pryService.getPry().subscribe((res) => {
              this.pryService.venmaepry = res;
              this.aggrid.refreshaggrid(this.pryService.venmaepry, this.defaultColDefPry.objeto);
            });
          });
        } else if (this.actualizar === false) {
          this.pryService.insertarPry(this.prySeleccionado).subscribe(() => {
            this.message.add({
              key: 'venmaepry',
              severity: 'success',
              summary: 'Inserción exitosa',
              detail: 'Se insertó el proyecto correctamente'
            });
            this.desCodigo = false;
            this.nuevoCampo = false;
            this.auditoriagral.registrarAuditoria('VEN_MAEPRY', String(this.prySeleccionado.PRY_CODIGO), 'I',
              '', '01', '', '', '', '').subscribe(() => {});
          }, error1 => {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.message.add({
              key: 'venmaepry',
              severity: 'error',
              summary: 'Inserción de el proyecto',
              detail: mensaje });
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
            this.btnRegresar = true;
            this.pryService.getPry().subscribe((res) => {
              this.pryService.venmaepry = res;
              this.aggrid.refreshaggrid(this.pryService.venmaepry, this.defaultColDefPry.objeto);
            });
          });
        }
      } else if (this.permisoPry.PERINSERCION === 0) {
        this.message.add({
          key: 'venmaepry',
          severity: 'error',
          summary: 'Actualización de Proyecto',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }
    }
    if (valor === 'Borrar') {
      if (this.permisoPry.PERINSERCION === 1) {
          if (this.prySeleccionado === undefined) {
              this.message.add({
                  key: 'venmaepry',
                  severity: 'error',
                  summary: 'Eliminacion de Proyecto',
                  detail: 'Seleccion Incorrecta, no se ha seleccionado un proyecto'
              });
          } else   {
              this.confirmarPry();
          }
      } else if (this.permisoPry.PERINSERCION === 0) {
        this.message.add({
          key: 'venmaepry',
          severity: 'error',
          summary: 'Eliminscion de Proyecto',
          detail: 'El usuario no tiene permisos de eliminar, acción denegada'
        });
      }
    }
    if (valor === 'Cancelar') {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.pryService.getPry().subscribe((res) => {
        this.pryService.venmaepry = res;
        this.aggrid.refreshaggrid(this.pryService.venmaepry, this.defaultColDefPry.objeto);
      });
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
  selectedPry(valor) {
    if (valor.object !== null) {
      this.prySeleccionado = valor.object;
      if (this.nuevoCampo === true) {
        this.actualizar = false;
      } else if (this.nuevoCampo === false) {
        this.actualizar = true;
        if (this.prySeleccionado !== undefined) {
          this.codigoAux = this.prySeleccionado.PRY_CODIGO;
        }
      }
    }
  }
  confirmarPry() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el proyecto',
      header: 'Eliminar Proyecto',
      icon: 'pi pi-exclamation-triangle',
      key: 'VenMaePry',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarpry();
      },
      reject: () => {
      }
    });
  }
  eliminarpry() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.pryService.eliminarPry(this.prySeleccionado).subscribe(() => {
      this.message.add({
        key: 'venmaepry',
        severity: 'success',
        summary: 'Eliminación exitosa',
        detail: 'Se eliminó el proyecto correctamente'
      });
      this.auditoriagral.registrarAuditoria('VEN_MAEPRY', String(this.prySeleccionado.PRY_CODIGO), 'E',
        '', '01', '', '', '', '').subscribe(() => {});
      this.pryService.venmaepry = this.pryService.venmaepry.filter((val, j) => j !==
        this.pryService.venmaepry.indexOf(this.prySeleccionado));
      this.aggrid.refreshaggrid(this.pryService.venmaepry, this.defaultColDefPry.objeto);
    }, error1 => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.message.add({
        key: 'venmaepry',
        severity: 'error',
        summary: 'Inserción de el grupo',
        detail: mensaje
      });
    });
  }
  cambio(cambio) {
    this.btnGuardar = false;
    this.btnRegresar = false;
  }
  nuevopry() {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = false;
    this.btnRegresar = false;
    this.nuevoCampo = true;
    this.desCodigo = false;
    const numero = this.revisarPryUndefined();
    this.prySeleccionado = {
      PRY_CODIGO: this.autoincrementableCod(numero),
      COM_CODIGO: '01',
      PRY_NOMBRE: '',
      CLI_CODIGO: '',
      BOD_CODIGO1: 'B2S.' + this.autoincrementableCod(numero),
      BOD_CODIGO2: 'B2P.' + this.autoincrementableCod(numero),
      BOD_CODIGO3: 'B2R.' + this.autoincrementableCod(numero),
      BOD_CODIGO4: 'B2Y.' + this.autoincrementableCod(numero),
      BOD_CODIGO5: 'B3O.' + this.autoincrementableCod(numero),
      BOD_CODIGO6: '',
      PRY_ACTIVO: '',
    };


    this.pryService.venmaepry.push(this.prySeleccionado);
    this.aggrid.refreshaggrid(this.pryService.venmaepry, this.defaultColDefPry.objeto);
  }
  autoincrementableCod(num: number ): string {
    let aux = String(num + 1);
    while (aux.length < 4) {
      aux = '0' + aux;
    }
    return aux;
  }
  revisarPryUndefined(): number {
    let aux : number = 0;
    if (this.pryService.venmaepry.length === 0 ){
      return aux;
    } else {
      let verificador : any = this.pryService.venmaepry[this.pryService.venmaepry.length - 1].PRY_CODIGO
      aux = parseInt(verificador);
      return aux;
    }
  }
  nombreBodega() {
    let indiceFila: number;
    indiceFila = 0;
    if(this.prySeleccionado!== undefined){
      this.pryService.venmaepry.map((toma) => {
        if (toma.PRY_CODIGO === this.prySeleccionado.PRY_CODIGO) {
          indiceFila = this.pryService.venmaepry.indexOf(toma);
        }
      });
      if(this.pryService.venmaepry[indiceFila].NOM_BOD1 === undefined || this.pryService.venmaepry[indiceFila].NOM_BOD2 === undefined ||
          this.pryService.venmaepry[indiceFila].NOM_BOD3 === undefined || this.pryService.venmaepry[indiceFila].NOM_BOD4 === undefined ||
          this.pryService.venmaepry[indiceFila].NOM_BOD5 === undefined || this.pryService.venmaepry[indiceFila].NOM_BOD6 === undefined ) {
        this.pryService.venmaepry[indiceFila].NOM_BOD1 = this.pryService.venmaepry[indiceFila].PRY_NOMBRE + ' / ' + this.pryService.venmaepry[indiceFila].CLI_NOMBRE;
        this.pryService.venmaepry[indiceFila].NOM_BOD2 = this.pryService.venmaepry[indiceFila].PRY_NOMBRE + ' / ' + this.pryService.venmaepry[indiceFila].CLI_NOMBRE;
        this.pryService.venmaepry[indiceFila].NOM_BOD3 = this.pryService.venmaepry[indiceFila].PRY_NOMBRE + ' / ' + this.pryService.venmaepry[indiceFila].CLI_NOMBRE;
        this.pryService.venmaepry[indiceFila].NOM_BOD4 = this.pryService.venmaepry[indiceFila].PRY_NOMBRE + ' / ' + this.pryService.venmaepry[indiceFila].CLI_NOMBRE;
        this.pryService.venmaepry[indiceFila].NOM_BOD5 = this.pryService.venmaepry[indiceFila].PRY_NOMBRE + ' / ' + this.pryService.venmaepry[indiceFila].CLI_NOMBRE;
      }
    }
  }
}
