import { Injectable } from '@angular/core';
import { bantrninv } from "../baninterfaces/bantrninv";
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {MessageService} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';

import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
@Injectable({
  providedIn: 'root'
})
export class BantrninversionesService {
  public detbantrninversion: bantrninv[][]=[];
  private way: string;
  constructor(
    private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private messageService: MessageService,
              private errorService: ErroresBaseDatosService,
              private confIniciales: ConfInicialesService
  ) { 
    this.way = sessionStorage.getItem('way');
  }
  encontrarConNombre(strParametro) {
    return this.http.post(this.way + '/inventario/articulo/3b6cc5ef8b2f6e', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }
}
