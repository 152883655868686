import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {BanTrnban} from '../baninterfaces/bantrnban';
import {BanEncconsil} from '../baninterfaces/banencconsil';
import {BanDetconsil} from '../baninterfaces/bandetconcil';
import {BanPartConsil} from '../baninterfaces/banpartconsil';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';


@Injectable({
  providedIn: 'root'
})
export class BanconcilbancariaService {
  
  public banenconsil: BanEncconsil = {};
  public detconcil: BanDetconsil[];
  public patidasconsil: BanPartConsil[];
  private _partidasCon: BanTrnban[];
  
  private way: string;
  
  get partidasCon(): BanTrnban[] {
    return this._partidasCon;
  }
  
  set partidasCon(value: BanTrnban[]) {
    this._partidasCon = value;
  }
  
  constructor(
    private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService
  ) {
    this.way = sessionStorage.getItem('way');
  }
  
  encontrarEncConciliacion(encconcilnumero, bancodigo) {
    return this.http.post<any[]>(this.way + '/ban/conciliacionban/c2faebb198bb280', {
        elementos: {
          encconcil_numero: encconcilnumero,
          ban_codigo: bancodigo
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  
  encontrarDetalleConciliacion(encconcilnumero, bancodigo) {
    return this.http.post<any[]>(this.way + '/ban/conciliacionban/c2fad77598bb280', {
        elementos: {
          encconcil_numero: encconcilnumero,
          ban_codigo: bancodigo
        },
      }, this.confIniciales.httpOptions()
    );
  }
  
  encontrarBanMaeBan(strCondicion): Observable<any> {
    return this.http.post<any[]>(this.way + '/ban/conciliacionban/f95dab55eca73000', {
        elementos: {
          condicion: strCondicion
        },
      }, this.confIniciales.httpOptions()
    );
  }
  
  
  encontrarConciliacion(strBanCodigo): Observable<any> {
    return this.http.post<any[]>(this.way + '/ban/conciliacionban/7caed662ec95640', {
        elementos: {
          ban_codigo: strBanCodigo
        },
      }, this.confIniciales.httpOptions()
    );
  }
  
  seleccionarRegistro(tipo, cadena, condicion): Observable<any[]> {
    return this.http.post<any>(this.way + '/ban/conciliacionban/75d98bf76adedc0', {
      elementos: {
        p_tipo: tipo,
        p_Cadena: cadena,
        p_condicion: condicion
      }
    }, this.confIniciales.httpOptions());
  }
  
  
  navegarRegistro(strTipo, strCondicion) {
    return this.http.post<any[]>(this.way + '/ban/conciliacionban/babee82b7b74280', {
        elementos: {
          tipo: strTipo,
          condicion: strCondicion
        },
      }, this.confIniciales.httpOptions()
    );
  }
  
  
  obtenerSaldoMes(strBanCod, fechaPeriodo, refContable) {
    return this.http.post<any>(this.way + '/ban/conciliacionban/617de2aadc59dc', {
      elementos: {
        p_BAN_codigop: strBanCod,
        p_COM_codigo: '01',
        p_fec_periodo: fechaPeriodo,
        p_RefContable: refContable,
      }
    }, this.confIniciales.httpOptions());
  }
  
  obtenerEstadoCntaAnt(Anio, strMes , strBanCodigo) {
    return this.http.post<any[]>(this.way + '/ban/conciliacionban/c2faee759e75f80', {
        elementos: {
          mes: strMes,
          anio: Anio,
          ban_codigo: strBanCodigo
        },
      }, this.confIniciales.httpOptions()
    );
  }
  
  actualizarFechaVencimiento() {
    return this.http.post<any[]>(this.way + '/ban/conciliacionban/14cebddf75d8e', {
        elementos: {},
      },  this.confIniciales.httpOptions()
    );
  }
  
  consultarDetConNoConcil(filtro) {
    return this.http.post<any[]>(this.way + '/ban/conciliacionban/c2fad776f866300', {
      elementos: {
        p_opcion: 'T',
        p_filtro: filtro === null ? '' : filtro,
        p_condicion: '',
      }
    },  this.confIniciales.httpOptions());
  }
  
  consultarPartConciliatoria(filtro, condicion) {
    return this.http.post<any[]>(this.way + '/ban/conciliacionban/617dcab7d662ec', {
      elementos: {
        p_opcion: 'A',
        p_filtro: filtro === null ? '' : filtro,
        p_condicion: condicion === null ? ' com_codigo=\'01\'' : '  com_codigo=\'01\' ' + condicion,
      }
    },  this.confIniciales.httpOptions());
  }
  
  cargarDatosCMBTipo(): Observable<any> {
    return this.http.post<any[]>(this.way + '/ban/conciliacionban/6abb8e32cbecb40', {
        elementos: {},
      },  this.confIniciales.httpOptions()
    );
    
  }
  
  eliminarParConciliatoria(encConcilNumero, banCodigo, trnBanNumero, tipo) {
    return this.http.post<any[]>(this.way + '/ban/conciliacionban/75656cab7d66300', {
      elementos: {
        p_ENCCONCIL_NUMERO: encConcilNumero,
        p_COM_CODIGO: '01',
        p_BAN_CODIGO: banCodigo,
        p_TRNBAN_TIPO: tipo,
        p_TRNBAN_NUMERO: trnBanNumero,
      }
    }, this.confIniciales.httpOptions());
  }
  
  
  consultarBAN_SP_ConsultarTrnBncrCncl(filtro) {
    return this.http.post<any[]>(this.way + '/ban/conciliacionban/20eef7775dd9b', {
      elementos: {
        p_opcion: 'T',
        p_filtro: filtro === null ? '' : filtro,
        p_condicion: '',
      }
    }, this.confIniciales.httpOptions());
  }
  
  
  async insertarEncConciliacionp(isNew: boolean, item: BanEncconsil): Promise<any> {
    return this.http.post<any[]>(this.way + '/ban/conciliacionban/95f8edf5d763300', {
      elementos: {
        p_ENCCONCIL_NUMERO: (isNew) ? '' : item.ENCCONCIL_NUMERO,
        p_COM_CODIGO: '01',
        p_BAN_CODIGO: item.BAN_CODIGO,
        p_ENCCONCIL_MES: item.ENCCONCIL_MES,
        p_ENCCONCIL_ANIO: item.ENCCONCIL_ANIO,
        p_ENCCONCIL_FECHACONCILIA: item.ENCCONCIL_FECHACONCILIA,
        p_ENCCONCIL_USUARIOCONCILIA: this.usuario.identificacion,
        p_ENCCONCIL_SALDOEC: Number(item.ENCCONCIL_SALDOEC),
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  async verificaPeriodoConciliacionp(banCodigo): Promise<any> {
    return this.http.post<any[]>(this.way + '/ban/conciliacionban/1f76e5976d98bb000', {
        elementos: {
          ban_codigo: banCodigo
        },
      },  this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  async insertarDetConciliacion(item: BanDetconsil): Promise<any> {
    return this.http.post<any[]>(this.way + '/ban/conciliacionban/95f8edf5aeeb300', {
      elementos: {
        p_ENCCONCIL_NUMERO: item.ENCCONCIL_NUMERO,
        p_COM_CODIGO: '01',
        p_BAN_CODIGO: item.BAN_CODIGO,
        p_TRNBAN_FECHA: item.TRNBAN_FECHA,
        p_TRNBAN_TIPO: item.TRNBAN_TIPO,
        p_TRNBAN_NUMERO: item.TRNBAN_NUMERO,
        p_TRNBAN_IMPORTE: item.TRNBAN_IMPORTE,
        p_TRNBAN_CONCEPTO: item.TRNBAN_CONCEPTO,
        p_TRNBAN_BENEFICIARIO: item.TRNBAN_BENEFICIARIO,
        p_ASI_COMPROBANTE: item.ASI_COMPROBANTE,
        p_ENCCONCIL_FECHACONCILIA: item.FECHA_CONCIL,
        p_ENCCONCIL_USUARIOCONCILIA: this.usuario.identificacion,
      }
    },  this.confIniciales.httpOptions()).toPromise();
  }
  
  async actualizaTrnBan(item: BanDetconsil) {
    return this.http.post<any[]>(this.way + '/ban/conciliacionban/533be557bbbad40', {
      elementos: {
        p_BAN_CODIGO: item.BAN_CODIGO,
        p_TRNBAN_TIPO: item.TRNBAN_TIPO,
        p_TRNBAN_NUMERO: item.TRNBAN_NUMERO,
        p_COM_CODIGO: '01'
      }
    },  this.confIniciales.httpOptions()).toPromise();
  }
  
  async eliminarPartidasConciliatorias(concilNumero): Promise<any> {
    return this.http.post<any[]>(this.way + '/ban/conciliacionban/7565956d98bb240', {
        elementos: {
          encconcil_numero: concilNumero
        }
      },  this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  async eliminarDetale(concilNumero, banCodigo): Promise<any> {
    return this.http.post<any[]>(this.way + '/ban/conciliacionban/7565695d5b6bbc0', {
        elementos: {
          encconcil_numero: concilNumero,
          ban_codigo: banCodigo
        }
      },  this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  async eliminarEncConciliacion(concilNumero, banCodigo): Promise<any> {
    return this.http.post<any[]>(this.way + '/ban/conciliacionban/7564ebb198bb240', {
      elementos: {
        p_ENCCONCIL_NUMERO: concilNumero,
        p_COM_CODIGO: '01',
        p_BAN_CODIGO: banCodigo,
      }
    },  this.confIniciales.httpOptions()).toPromise();
  }
  
  /*registrarAuditoria(strObjeto: string, strDato: string, strOperacion: string,
                     usuIdentificacion: string, comCodigo: string, audQuien: string,
                     audTipoElimina: string, audIdEquipo: string, audFechaHora: string): Promise<any>  {
    return this.http.post<any>(this.url + '/ejecutarprocesonoout', {
      esquema: this.usuarioEsquema,
      elementoEjecutar: 'SACI_PCK_MAEAUDITORIA.SACI_SP_insertarAuditoria',
      elementos: {
        p_AUD_objeto_1: strObjeto + '_W',
        p_AUD_dato_2: strDato,
        p_AUD_operacion_3: strOperacion,
        p_USU_identificacion_4: this.usuario.identificacion,
        p_COM_codigo_5: comCodigo,
        p_AUD_quien_6: this.usuario.identificacion,
        p_AUD_tipoelimina_7: audTipoElimina,
        P_AUD_Serial: audIdEquipo,
        p_AUD_fecha_hora: audFechaHora,
      }
    }).toPromise();
  }*/
  
  insertarParConciliatoria(concilNumero, item: BanPartConsil): Promise<any> {
    return this.http.post<any[]>(this.way + '/ban/conciliacionban/95f8edf72adb300', {
      elementos: {
        p_ENCCONCIL_NUMERO: concilNumero,
        p_COM_CODIGO: '01',
        p_BAN_CODIGO: item.BAN_CODIGO,
        p_TRNBAN_TIPO: item.TRNBAN_TIPO,
        p_TRNBAN_NUMERO: item.TRNBAN_NUMERO,
        p_TRNBAN_FECHA: item.TRNBAN_FECHA,
        p_TRNBAN_IMPORTE: Number(item.TRNBAN_IMPORTE),
        p_TRNBAN_CONCEPTO: item.TRNBAN_CONCEPTO,
        p_TRNBAN_BENEFICIARIO: item.TRNBAN_BENEFICIARIO,
        p_trnban_concil: item.CHECKCONT === true ? 1 : 0
      }
    },  this.confIniciales.httpOptions()).toPromise();
  }
  
  ActualizarParConciliatoria(concilNumero, item: BanPartConsil): Promise<any> {
    return this.http.post<any[]>(this.way + '/ban/conciliacionban/533b956d98bb240', {
      elementos: {
        p_ENCCONCIL_NUMERO: item.ENCCONCIL_NUMERO,
        p_COM_CODIGO: '01',
        p_BAN_CODIGO: item.BAN_CODIGO,
        p_TRNBAN_TIPO: item.TRNBAN_TIPO,
        p_TRNBAN_NUMERO: item.TRNBAN_NUMERO,
        p_TRNBAN_FECHA: item.TRNBAN_FECHA,
        p_TRNBAN_IMPORTE: Number(item.TRNBAN_IMPORTE),
        p_TRNBAN_CONCEPTO: item.TRNBAN_CONCEPTO,
        p_TRNBAN_BENEFICIARIO: item.TRNBAN_BENEFICIARIO,
        p_trnban_concil: item.CHECKCONT === true ? 1 : 0
      }
    },  this.confIniciales.httpOptions()).toPromise();
  }
}
