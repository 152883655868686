//
// SACI WEB
// Rutina: Inventario , actualización de precios
// Propietario: Francisco Cevallos
// Modificado por: FC
// Fecha de creación: 24-01-2020
// Fecha de Modificación: 28-02-2020
//

import {Component, OnInit, ViewChild} from '@angular/core';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {AggridBanComponent} from '../../ban/aggrid-ban/aggrid-ban.component';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {InvbusquedaService} from '../invservicios/invbusqueda.service';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {InvactpreciosService} from '../invservicios/invactprecios.service';
import {Invcalpreform} from '../invinterfaces/invcalpreform';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {DatePipe} from '@angular/common';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Component({
  selector: 'app-invactprecios',
  templateUrl: './invactprecios.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class InvactpreciosComponent implements OnInit {
  
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  
  btnGuardar: boolean;
  btnCancelar: boolean;
  btnNuevoGrid: boolean;
  btnGuardarGrid: boolean;
  btnBorrarGrid: boolean;
  btnCancelarGrid: boolean;
  displayAcciones: boolean;
  activeIndex: number;
  selectAll: boolean;
  largo = '200';
  largo2: string;
  public frameworkComponents;
  rowStyle;
  rowSelection = 'multiple';
  defaultColPrecios;
  defaultColDefFormulas;
  arregloEst: any[];
  arregloDinPrecios: any[];
  formulaSeleccionada: Invcalpreform;
  grupArtSeleccionada: string;
  listPrecSeleccionada: string;
  formulaXaplicar: string;
  listPrecXAct: any[];
  grupcodFormula: string;
  opcionesGrupArt: SelectItem[];
  opcionesListaPrecios: SelectItem[];
  opcionesListaPreciosCheck: SelectItem[];
  listaGrupArt: any[];
  listaListaPrecios: any[];
  columnDefsActPrecios2: any[];
  numDecimales: number;
  permisoActPrec: SegMaePermiso;
  
  
  columnDefsFormulas = [
    {
      headerName: 'Nombre', field: 'FORPRE_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 50,
          }
        }
    },
    {
      headerName: 'Condición', field: 'FORPRE_NOMSQL', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 50
          }
        }
    }
  ];
  
  constructor(public actpreService: InvactpreciosService, private message: MessageService, private errorService: ErroresBaseDatosService,
              private confirmationService: ConfirmationService, private busqService: InvbusquedaService,
              private permisosService: PermisosService, private auditoriagral: AuditoriagralService, private datePipe: DatePipe,
              private init: NuevoComponentService) {
    this.agTable();
    this.actpreService.formulas = [];
    this.opcionesGrupArt = [];
    this.opcionesListaPrecios = [];
    this.opcionesListaPreciosCheck = [];
    this.actpreService.precios = [];
    this.arregloEst = [];
    this.arregloDinPrecios = [];
    this.listPrecXAct = [];
    this.permisoActPrec = {};
    
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColPrecios = {
      editable: true,
      objeto: 'calpreciosinv'
    };
    this.defaultColDefFormulas = {
      editable: true,
      objeto: 'formulascalpreciosinv'
    };
    this.permisoActPrec = {};
    
  }
  
  ngOnInit() {
    
    this.btnGuardar = true;
    this.btnCancelar = true;
    this.grupArtSeleccionada = '';
    this.listPrecSeleccionada = '';
    this.activeIndex = 0;
    this.selectAll = false;
    this.largo = '200';
    this.largo2 = '500';
    this.obtenerGrupArt();
    this.formulaSeleccionada = {};
    this.formulaSeleccionada.FORPRE_NOMSQL = '';
    this.numDecimales = 2;
    this.permisosService.getListaPermisos('INV', '3', '81').subscribe((res) => {
      this.permisoActPrec = res[0];
    });
    
    this.actpreService.obtenerFormulas().subscribe((res) => {
      if (res !== null) {
        this.actpreService.formulas = res;
      }
    });
    this.inicializarColumnDefPrecios();
  }
  
  
  manejarSenales(valor) {
    
    if (valor === 'Guardar') {
      this.guardar();
      
    }
    
    if (valor === 'Cancelar') {
      if (this.activeIndex === 0) {
        this.obtenerListaPrecios(this.grupcodFormula);
      }
      
    }
    
    if (valor === 'Visto') {
      this.confirmarActPre();
    }
    
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
  seleccionarTodos() {
    if (this.selectAll === true) {
      if (this.arregloDinPrecios.length !== 0) {
        for (const res of this.arregloDinPrecios) {
          res.CHECKRECALPMD = true;
        }
      }
    }
    if (this.selectAll === false) {
      if (this.arregloDinPrecios.length !== 0) {
        for (const res of this.arregloDinPrecios) {
          res.CHECKRECALPMD = false;
        }
      }
    }
    this.aggrid.refreshaggrid(this.arregloDinPrecios, this.defaultColPrecios.objeto);
  }
  
  guardar() {
    if (this.activeIndex === 0) {
      this.actualizarPreciosTemp();
      
    }
    
    if (this.activeIndex === 1) {
      
      if (this.permisoActPrec.PERINSERCION === 1) {
        this.btnGuardar = true;
        this.actpreService.ermaeforprecioprecod(this.formulaSeleccionada.FORPRE_CODIGO).subscribe(existe => {
          if (existe !== null) {
            this.actpreService.insertarFormula(this.formulaSeleccionada).subscribe(() => {
              this.message.add({
                key: 'invactprecKey',
                severity: 'success',
                summary: 'Actualización exitosa',
                detail: 'Se actualizó la fórmula correctamente'
              });
              this.auditoriagral.registrarAuditoria('INV_MAEFORPRECIO', String(this.formulaSeleccionada.FORPRE_CODIGO), 'A',
                '', '01', '', '', '', '').subscribe(() => {
              });
              
              this.btnGuardar = true;
              this.btnNuevoGrid = false;
            }, error1 => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.message.add({
                key: 'invactprecKey',
                severity: 'error',
                summary: 'Actualización de la fórmula ',
                detail: mensaje
              });
              this.btnNuevoGrid = false;
              this.btnGuardar = true;
              this.btnBorrarGrid = false;
              this.btnCancelarGrid = true;
              this.actpreService.obtenerFormulas().subscribe((res) => {
                this.actpreService.formulas = res;
                this.aggrid.refreshaggrid(this.actpreService.formulas, this.defaultColDefFormulas.objeto);
              });
            });
          } else {
            this.actpreService.insertarFormula(this.formulaSeleccionada).subscribe(() => {
              this.message.add({
                key: 'invactprecKey',
                severity: 'success',
                summary: 'Inserción exitosa',
                detail: 'Se insertó la fórmula correctamente'
              });
              this.actpreService.obtenerFormulas().subscribe((res) => {
                this.actpreService.formulas = res;
                this.aggrid.refreshaggrid(this.actpreService.formulas, this.defaultColDefFormulas.objeto);
              });
              this.auditoriagral.registrarAuditoria('INV_MAEFORPRECIO', String(this.formulaSeleccionada.FORPRE_CODIGO), 'I',
                '', '01', '', '', '', '').subscribe(() => {
              });
              this.btnNuevoGrid = false;
              this.btnGuardar = true;
            }, error1 => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.message.add({
                key: 'invactprecKey',
                severity: 'error',
                summary: 'Inserción de la fórmula ',
                detail: mensaje
              });
              this.btnNuevoGrid = false;
              this.btnGuardar = true;
              this.btnBorrarGrid = false;
              this.btnCancelarGrid = true;
              this.actpreService.obtenerFormulas().subscribe((res) => {
                this.actpreService.formulas = res;
                this.aggrid.refreshaggrid(this.actpreService.formulas, this.defaultColDefFormulas.objeto);
              });
            });
          }
        });
      } else if (this.permisoActPrec.PERINSERCION === 0) {
        this.message.add({
          key: 'invactprecKey',
          severity: 'error',
          summary: 'Actualización de Fórmula',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }
      
    }
  }
  
  seleccionFormulas(params) {
    if (params === 'nuevo') {
      
      if (this.permisoActPrec.PERINSERCION === 1) {
        this.btnNuevoGrid = true;
        this.btnGuardarGrid = false;
        this.btnBorrarGrid = false;
        this.btnCancelarGrid = false;
        this.btnGuardar = false;
        
        this.formulaSeleccionada = {
          FORPRE_CODIGO: 0,
          FORPRE_NOMBRE: '',
          FORPRE_NOMSQL: '',
        };
        
        this.actpreService.formulas.push(this.formulaSeleccionada);
        this.aggrid.refreshaggrid(this.actpreService.formulas, this.defaultColDefFormulas.objeto);
        
      } else if (this.permisoActPrec.PERINSERCION === 0) {
        this.message.add({
          key: 'invactprecKey',
          severity: 'error',
          summary: 'Inserción de Fórmula',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
      
    } else if (params === 'eliminar') {
      
      if (this.permisoActPrec.PERINSERCION === 1) {
        if (this.formulaSeleccionada === undefined) {
          this.message.add({
            key: 'invactprecKey',
            severity: 'error',
            summary: 'Eliminacion de Formula ',
            detail: 'Seleccion Incorrecta, no se ha seleccionado una formula  '
          });
        } else {
          this.confirmarFormula();
        }
      } else if (this.permisoActPrec.PERINSERCION === 0) {
        this.message.add({
          key: 'invactprecKey',
          severity: 'error',
          summary: 'Eliminación de Fórmula',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
      }
      
    } else if (params === 'cancelar') {
      this.btnNuevoGrid = false;
      this.btnGuardar = true;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = true;
      this.actpreService.obtenerFormulas().subscribe((res) => {
        this.actpreService.formulas = res;
        this.aggrid.refreshaggrid(this.actpreService.formulas, this.defaultColDefFormulas.objeto);
      });
    } else if (params.object !== undefined) {
      this.formulaSeleccionada = params.object;
      this.formulaXaplicar = this.formulaSeleccionada.FORPRE_NOMSQL;
    }
  }
  
  confirmarFormula() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar la fórmula',
      header: 'Eliminar Fórmula ',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarActPrec',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarFormula();
      },
      reject: () => {
      }
    });
  }
  
  eliminarFormula() {
    this.btnNuevoGrid = false;
    this.btnGuardar = true;
    this.btnBorrarGrid = false;
    this.btnCancelarGrid = true;
    this.actpreService.eliminarFormula(this.formulaSeleccionada).subscribe(() => {
      this.message.add({
        key: 'invactprecKey',
        severity: 'success',
        summary: 'Eliminación exitosa',
        detail: 'Se eliminó la formula correctamente'
      });
      this.actpreService.formulas = this.actpreService.formulas.filter((val, j) => j !==
        this.actpreService.formulas.indexOf(this.formulaSeleccionada));
      this.aggrid.refreshaggrid(this.actpreService.formulas, this.defaultColDefFormulas.objeto);
      this.auditoriagral.registrarAuditoria('INV_MAEFORPRECIO', String(this.formulaSeleccionada.FORPRE_CODIGO), 'E',
        '', '01', '', '', '', '').subscribe(() => {
      });
      
    }, error1 => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.message.add({
        key: 'invactprecKey',
        severity: 'error',
        summary: 'Eliminación de fórmula fallida ',
        detail: mensaje
      });
    });
  }
  
  obtenerGrupArt() {
    this.actpreService.obtenerGruposArticulos().subscribe((res) => {
      this.listaGrupArt = res;
      this.opcionesGrupArt.push({label: '', value: ''});
      this.listaGrupArt.map((grupart) => {
        let nuevoItem: any;
        nuevoItem = {label: grupart.GRUP_NOMBRE, value: grupart.GRUP_CODIGO};
        this.opcionesGrupArt.push(nuevoItem);
      });
    });
  }
  
  
  obtenerListaPrecios(grupcodigo: string) {
    console.log('grup codigo ', grupcodigo);
    this.btnGuardar = true;
    this.grupcodFormula = grupcodigo;
    this.listPrecXAct = [];
    const fecha = new Date();
    let horas;
    let hora = fecha.getHours().toString();
    if (hora.length === 1) {
      hora = '0' + hora;
    }
    let minu = fecha.getMinutes().toString();
    if (minu.length === 1) {
      minu = '0' + minu;
    }
    let fechaReal: string;
    horas = String(hora) + ':' + String(minu);
    fechaReal = this.datePipe.transform(fecha, 'dd/MM/yyyy').toString();
    if (grupcodigo !== '') {
      this.actpreService.consultarListasGrupo(grupcodigo).subscribe((res) => {
        if (res !== null) {
          this.opcionesListaPrecios = [];
          this.opcionesListaPreciosCheck = [];
          this.opcionesListaPrecios.push({label: '', value: ''});
          this.listaListaPrecios = res;
          this.listaListaPrecios.map((listprecios) => {
            let nuevoItem: any;
            let nuevoItem2: any;
            nuevoItem = {label: listprecios.ARTPRE_CODIGO, value: listprecios.ARTPRE_CODIGO};
            nuevoItem2 = {label: listprecios.ARTPRE_CODIGO, value: false};
            this.opcionesListaPrecios.push(nuevoItem);
            this.opcionesListaPreciosCheck.push(nuevoItem2);
            
          });
        } else {
          this.opcionesListaPrecios = [];
          this.opcionesListaPreciosCheck = [];
          let nuevoItem: any;
          nuevoItem = {label: '', value: ''};
          this.opcionesListaPrecios.push(nuevoItem);
        }
      });
      
      this.actpreService.cargarTablaPrecios(fechaReal, horas,
        grupcodigo).subscribe((res1) => {
        
        this.arregloEst = res1;
        this.arregloDinPrecios = [];
        
        this.actpreService.cargarTablaPreciosFiltrada(fechaReal, horas,
          grupcodigo).subscribe((res2) => {
          this.arregloDinPrecios = [];
          this.buildArregloDinPrecios(res2);
          if (res2.length !== 0) {
            this.cargarColumnasTablaPrecios(true);
            this.aggrid.refreshColumnDefs();
            this.aggrid.refreshaggrid(this.actpreService.precios, this.defaultColPrecios.objeto);
          } else if (res2.length === 0) {
            this.cargarColumnasTablaPrecios(false);
            this.aggrid.refreshColumnDefs();
            this.aggrid.refreshaggrid(this.actpreService.precios, this.defaultColPrecios.objeto);
          }
        });
      });
      
      
    } else {
      /* this.opcionesListaPrecios = [];
       this.opcionesListaPreciosCheck = [];
       let nuevoItem: any;
       nuevoItem = {label: '', value: ''};
       this.opcionesListaPrecios.push(nuevoItem);*/
      this.inicializarColumnDefPrecios();
      this.arregloDinPrecios = [];
      this.actpreService.precios = this.arregloDinPrecios;
      this.aggrid.refreshaggrid(this.actpreService.precios, this.defaultColPrecios.objeto);
      
    }
  }
  
  inicializarColumnDefPrecios() {
    this.columnDefsActPrecios2 = [
      {
        headerName: '', field: 'CHECKRECALPMD', cellRendererFramework: AggridBanComponent, editable: false, width: 50
      },
      {
        headerName: 'Código', field: 'ART_CODIGO', editable: false, width: 100,
        
      },
      {
        headerName: 'Artículo', field: 'ART_NOMBRE', editable: false, width: 200
      },
      {
        headerName: 'Unidad', field: 'UNI_CODIGO', editable: false, width: 100
      },
      {
        headerName: 'Costo', field: 'PRECIO', editable: false, width: 100
      }];
  }
  
  
  buildArregloDinPrecios(res2: any) {
    
    for (const rs of res2) {
      const arregloIni = {
        CHECKRECALPMD: false,
        ART_CODIGO: '',
        ART_NOMBRE: '',
        UNI_CODIGO: '',
        PRECIO: '',
      };
      for (const listpre of this.opcionesListaPreciosCheck) {
        arregloIni[listpre.label] = Number(0).toFixed(this.numDecimales);
      }
      arregloIni.CHECKRECALPMD = false;
      arregloIni.ART_CODIGO = rs.ART_CODIGO;
      arregloIni.UNI_CODIGO = rs.UNI_CODIGO;
      arregloIni.ART_NOMBRE = rs.ART_NOMBRE;
      arregloIni.PRECIO = Number(rs.PRECIO.replace(',', '.')).toFixed(this.numDecimales - 1);
      
      for (const prec of this.arregloEst) {
        if (prec.ART_CODIGO === rs.ART_CODIGO && prec.UNI_CODIGO === rs.UNI_CODIGO) {
          arregloIni[prec.ARTPRE_CODIGO] = Number(prec.ARTPRE_PRECIO).toFixed(this.numDecimales);
        }
      }
      this.arregloDinPrecios.push(arregloIni);
      this.aggrid.refreshaggrid(this.arregloDinPrecios, this.defaultColPrecios.objeto);
    }
    
    
  }
  
  
  cargarColumnasTablaPrecios(existe: boolean) {
    this.inicializarColumnDefPrecios();
    if (existe === true) {
      
      this.opcionesListaPreciosCheck.map((precio) => {
        this.columnDefsActPrecios2.push(
          {
            headerName: precio.label,
            field: precio.label,
            cellEditor: 'cellPrueba',
            editable: true,
            width: 100,
            cellStyle: {textAlign: 'right'},
            cellEditorParams:
              {
                values: {
                  mayusculas: false,
                  numeros: true,
                  alphabetic: false,
                  saltoslinea: 1,
                  newrow: false,
                  numerodecimales: 2,
                  obligatorio: true,
                  fecha: false,
                  tamanocelda: 20
                }
              }
          });
      });
    }
    this.aggrid.refreshColumnDefs();
    
  }
  
  actualizarPrecios() {
    if (this.arregloDinPrecios.length !== 0) {
      this.arregloDinPrecios.map((res) => {
        if (res.CHECKRECALPMD === true) {
          for (const indice in this.listPrecXAct) {
            if (res[this.listPrecXAct[indice]] !== undefined) {
              this.actpreService.actualizarPrecioArt(res.ART_CODIGO, res.UNI_CODIGO, this.listPrecXAct[indice],
                Number(res[this.listPrecXAct[indice]])).subscribe(() => {
              });
            }
          }
        }
      });
      this.message.add({
        key: 'invactprecKey',
        severity: 'success',
        summary: 'Actualización Precios',
        detail: 'Actualización de precios exitosa'
      });
      this.btnGuardar = true;
      this.btnCancelar = true;
    }
  }
  
  async actualizarPreciosTemp() {
    if (this.arregloDinPrecios.length !== 0) {
      for (const res of this.arregloDinPrecios) {
        for (const indice in this.opcionesListaPreciosCheck) {
          try {
            if (res[this.opcionesListaPreciosCheck[indice].label] !== undefined) {
              await this.actpreService.actualizarTempPrecioArtP(res.ART_CODIGO, res.UNI_CODIGO, this.opcionesListaPreciosCheck[indice].label,
                Number(res[this.opcionesListaPreciosCheck[indice].label]));
            }
          } catch (error1) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.message.add({
              key: 'invactprecKey',
              severity: 'error',
              summary: 'Actualización de temporal de precios  ',
              detail: mensaje
            });
            return;
          }
        }
      }
      this.message.add({
        key: 'invactprecKey',
        severity: 'success',
        summary: 'Actualización de precios',
        detail: 'Guardado correctamente '
      });
      this.btnGuardar = true;
    }
  }
  
  async ejecutarFormulaPromise() {
    let contver = 0;
    if (this.arregloDinPrecios.length !== 0) {
      if (this.formulaSeleccionada.FORPRE_NOMSQL !== '' && this.listPrecSeleccionada !== '') {
        try {
          for (const res  of this.arregloDinPrecios) {
            const res1 = await this.actpreService.ejecutarFormula(res.ART_CODIGO, this.formulaSeleccionada.FORPRE_NOMSQL, this.listPrecSeleccionada,
              res.UNI_CODIGO);
            for (const value of res1) {
              this.arregloDinPrecios.map((res2) => {
                if (res.ART_CODIGO === res2.ART_CODIGO && res.UNI_CODIGO === res2.UNI_CODIGO) {
                  res2[this.listPrecSeleccionada] = Number(value.PREPRECIO).toFixed(this.numDecimales);
                }
              });
            }
          }
        } catch (err) {
          contver += 1;
        }
        
        if (contver !== 0) {
          this.message.add({
            key: 'invactprecKey',
            severity: 'error',
            summary: 'Ejecutar fórmula Precios',
            detail: 'No se ha podido ejecutar correctamente la fórmula'
          });
        } else if (contver === 0) {
          this.message.add({
            key: 'invactprecKey',
            severity: 'success',
            summary: 'Ejecutar fórmula Precios',
            detail: 'Se ha ejecutado correctamente la fórmula'
          });
        }
        
        this.aggrid.refreshaggrid(this.arregloDinPrecios, this.defaultColPrecios.objeto);
        this.btnGuardar = false;
        this.btnCancelar = false;
      } else {
        this.message.add({
          key: 'invactprecKey',
          severity: 'error',
          summary: 'Ejecutar fórmula Precios',
          detail: 'No se ha seleccionado ninguna de las listas de precios y fórmula'
        });
      }
    } else {
      this.message.add({
        key: 'invactprecKey',
        severity: 'error',
        summary: 'Ejecutar fórmula Precios',
        detail: 'No se ha seleccionado ningun grupo de artículo '
      });
    }
  }
  
  confirmarActPre() {
    if (this.contadorChecks() !== 0 && this.listPrecXAct.length !== 0) {
      this.confirmationService.confirm({
        message: 'Esta seguro que desea actualizar los precios',
        header: 'Actualizar Precios',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarActPrec',
        accept: () => {
          this.actualizarPrecios();
        },
        reject: () => {
        }
      });
    } else {
      this.message.add({
        key: 'invactprecKey',
        severity: 'error',
        summary: 'Actualizar Precios ',
        detail: 'No se ha seleccionado ninguna de las listas de precios y artículos a actualizar'
      });
    }
  }
  
  contadorChecks(): number {
    let contadorCheck = 0;
    if (this.arregloDinPrecios.length !== 0) {
      this.arregloDinPrecios.map((res) => {
        if (res.CHECKRECALPMD === true) {
          contadorCheck += 1;
        }
      });
    }
    return contadorCheck;
  }
  
  
  agregarListaAct(label, value) {
    if (this.listPrecXAct.length !== 0) {
      if (value === true) {
        this.listPrecXAct.push(label);
      } else if (value === false) {
        for (const indice in this.listPrecXAct) {
          if (this.listPrecXAct[indice] === label) {
            this.listPrecXAct = this.listPrecXAct.filter((val, j) => j !== Number(indice));
          }
        }
      }
    } else {
      if (value === true) {
        this.listPrecXAct.push(label);
      }
    }
  }
  
  cambio(cambio) {
    this.btnGuardar = false;
    this.btnCancelarGrid = false;
  }
  
  cambio2(cambio) {
    this.btnGuardar = false;
    this.btnCancelar = false;
  }
  
  cambiarIndices() {
    if (this.activeIndex === 0) {
      console.log('precios 3.0');
      this.aggrid.refreshaggrid(this.arregloDinPrecios, this.defaultColPrecios);
    }
    if (this.activeIndex === 1) {
      this.btnCancelarGrid = false;
      this.btnNuevoGrid = false;
      this.btnBorrarGrid = false;
      this.actpreService.obtenerFormulas().subscribe((res) => {
        this.actpreService.formulas = res;
        this.aggrid.refreshaggrid(this.actpreService.formulas, this.defaultColDefFormulas);
      });
    }
  }
  
}
