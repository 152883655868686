import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from 'src/app/usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { MessageService } from 'primeng';

@Injectable({
  providedIn: 'root'
})
export class NomtransotregresosService {

  private way: string;
  public url: string;

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) { 
      this.way = sessionStorage.getItem('way');
      this.url = sessionStorage.getItem('url');
    }

  

  funGlbObtieneCnfgSistema(cfg_codigo: string){
    return this.http.post<any[]>(this.way + '/nom/transotregresos/6p7fbr61mmijkub', {
        elementos: {
          Cfg_codigo: cfg_codigo
        },
        
      },this.confIniciales.httpOptions()).toPromise();
  }

  

  funListaConceptoOtroEgresos(){

    return this.http.post<any[]>(this.way + '/nom/transotregresos/061isf6qeb7zwa2', {
        elementos: {
        },
      },this.confIniciales.httpOptions()).toPromise();

  }

 

  funObtieneCtaContableConcepto(cmbMAECONCPOTREGRE_CODIGO: number){
  
    return this.http.post<any[]>(this.way + '/nom/transotregresos/vrmc2xuyo2un3o1', {
        elementos: {
          CmbMAECONCPOTREGRE_CODIGO: cmbMAECONCPOTREGRE_CODIGO
        },
      },this.confIniciales.httpOptions()).toPromise();
  }



  


  funDesplietaTipDiario(){
  
    return this.http.post<any[]>(this.way + '/nom/transotregresos/54nq8j9bvtit9gi', {
        elementos: {
        },
      },this.confIniciales.httpOptions()).toPromise();
  }




  

  getNroAsi(cmb_tdText: string){
  
    return this.http.post<any[]>(this.way + '/nom/transotregresos/pys2xf1shhu2bl4', {
        elementos: {
          Cmb_tdText: cmb_tdText
        },
      },this.confIniciales.httpOptions()).toPromise();
  }



  

  busquedaTabla(strCampo, strCondicion) {
    return this.http.post(this.way + '/nom/transotregresos/z7e10n6k7q40vat', {
      elementos: {
        Campo: strCampo,
        Condicion: strCondicion
      },
    },this.confIniciales.httpOptions()).toPromise();
  }

  

  busquedaEmpleado(strCampo,  strCondicion) {
    return this.http.post(this.way + '/nom/transotregresos/p191ofwji7dvitv', {
      elementos: {
        Campo: strCampo,
        Condicion: strCondicion
      },
    },this.confIniciales.httpOptions()).toPromise();
  }

  

  

  busquedaCentroCostos(strCampo,  strCondicion) {
    return this.http.post(this.way + '/nom/transotregresos/coq8oinph13axij', {
      elementos: {
        Campo: strCampo,
        Condicion: strCondicion
      },
    },this.confIniciales.httpOptions()).toPromise();
  }

  

  busquedaOtrosEgresos(strCampo,  strCondicion) {
    return this.http.post(this.way + '/nom/transotregresos/93yal0xw2om3n7r', {
      elementos: {
        Campo: strCampo,
        Condicion: strCondicion
      },
    },this.confIniciales.httpOptions()).toPromise();
  }


 

  busCuentaCons(strCampo,  strCondicion) {
    
    return this.http.post(this.way + '/nom/transotregresos/l1mk7g5n07v99ul', {
      elementos: {
        Campo: strCampo,
        Condicion: strCondicion
      },
    },this.confIniciales.httpOptions()).toPromise();
  }





  busquedaFormaPago(){
    return this.http.post<any[]>(this.way + '/nom/transotregresos/s9td4a0yoa9a76h', {
        elementos: {
        },
        
      },this.confIniciales.httpOptions()).toPromise();
  }

 

  busquedaBancos(){
    return this.http.post<any[]>(this.way + '/nom/transotregresos/fmx4yo1q952yu89', {
      elementos: {
      },
      
    },this.confIniciales.httpOptions()).toPromise();
  }

 

  getNumComprobante(cmb_tdText: string){ // sentencia
    return this.http.post<any[]>(this.way + '/nom/transotregresos/vzu619wva35i9mr', {
      elementos: {
        Cmb_tdText: cmb_tdText
      },
      
    },this.confIniciales.httpOptions()).toPromise();
  }

  

  funNomGlbObtienePeriodo(){
    return  this.http.post<any[]>(this.way + '/nom/transotregresos/mfaa8xk16x44rpw', {
      elementos: {
        p_com_codigo: '01'
      },
    },this.confIniciales.httpOptions()).toPromise();
  }

 

  consultaReg(){
    return this.http.post<any[]>(this.way + '/nom/transotregresos/xhjm2f5a7kngddy', {
      elementos: {
      },
      
    },this.confIniciales.httpOptions()).toPromise();
  }

  llenarString(m: string, n: number){
    let cadena = '';
    for( let i = 0; i < n; i++  ){
      cadena+=m
    }
    return cadena;
  }

  getAnterior(txtNumeroDoc: string){
  
    return this.http.post<any[]>(this.way + '/nom/transotregresos/q5g53fiolhhqdg6', {
        elementos: {
          TxtNumeroDoc: txtNumeroDoc
        },
      
      },this.confIniciales.httpOptions()).toPromise();
  }

 

  getPrimero(){
    return this.http.post<any[]>(this.way + '/nom/transotregresos/2ni0vv872xmwopj', {
      elementos: {
      },
    
    },this.confIniciales.httpOptions()).toPromise();
  }

  

  getSiguiente(txtNumeroDoc: string){
    return this.http.post<any[]>(this.way + '/nom/transotregresos/bbpreg5y0b7n7ng', {
      elementos: {
        TxtNumeroDoc: txtNumeroDoc
      },
    
    },this.confIniciales.httpOptions()).toPromise();
  }

  getUltimo(){
    return this.http.post<any[]>(this.way +'/nom/transotregresos/h4oip6n8wg4lbdn', {
      elementos: {
      },
    
    },this.confIniciales.httpOptions()).toPromise();
  }

 
  consultaLlenarDatos(nroDocumento: string){
   
    return this.http.post<any[]>(this.way + '/nom/transotregresos/x6c3j077bl4ehcc', {
        elementos: {
          NroDocumento: nroDocumento
        },
      },this.confIniciales.httpOptions()).toPromise();
  }

  

  getRucIde( strCampo, strCondicion){
    
    return this.http.post(this.way + '/nom/transotregresos/06wv8dcgivjhjf0', {
      elementos: {
        Campo: strCampo, 
        Condicion: strCondicion,
      },
    },this.confIniciales.httpOptions()).toPromise();

  }


  transaccionesCaja(codigo: string){
  
    return this.http.post<any[]>(this.way + '/nom/transotregresos/bsso21hfdk004az', {
        elementos: {
          Codigo: codigo
        },
      
    },this.confIniciales.httpOptions()).toPromise();
  }

  
  transaccionesBancos(codigo: string){
  
    return this.http.post<any[]>(this.way + '/nom/transotregresos/7rbyqklnm9junlu', {
        elementos: {
          Codigo: codigo
        },
      
      },this.confIniciales.httpOptions()).toPromise();
  }

 

  getBANCLI_NOMBRE(bancli_codigo: string){
  
    return this.http.post<any[]>(this.way + '/nom/transotregresos/xe11trfnjn6h05x', {
        elementos: {
          Bancli_codigo: bancli_codigo
        },
      },this.confIniciales.httpOptions()).toPromise();
  }

  


  getTAR_DESCRIPCION(bancli_codigo: string){
  
    return this.http.post<any[]>(this.way + '/nom/transotregresos/0k0bciwq7w1zs32', {
        elementos: {
          Bancli_codigo: bancli_codigo
        },
      },this.confIniciales.httpOptions()).toPromise();
  }


  getBanco(strCondicion){
    return this.http.post(this.way + '/nom/transotregresos/gctanzeokgtzv7l', {
      elementos: {
        Condicion: strCondicion
      },
    },this.confIniciales.httpOptions()).toPromise();
  }

  

  getTarjeta(strCondicion){
    return this.http.post(this.way + '/nom/transotregresos/85nsr4ozfoncbgw', {
      elementos: {
        Condicion: strCondicion
      },
    },this.confIniciales.httpOptions()).toPromise();
  }

  

  funGlbVerificarAsientoLinea(str_ASI_nro: string){
  
    return this.http.post<any[]>(this.way + '/nom/transotregresos/lnn2i1lkzrtmhxq', {
        elementos: {
          ASI_nro: str_ASI_nro
        },
      },this.confIniciales.httpOptions()).toPromise();
  }




  generarSecuencial(usuario: string, numDoc: string ){
    return this.http.post<any[]>(this.way + '/nom/transotregresos/8y8f1hi7tyb9qjx', {
        elementos: {
          Usuario: usuario,
          NumDoc: numDoc
        },
      },this.confIniciales.httpOptions()).toPromise();
  }

  guardarNuevoSecuencial(usuario: string, numDoc: string){
    return this.http.post<any[]>(this.way + '/nom/transotregresos/6rlsdfrme2boiq1', {
      elementos: {
        Usuario: usuario,
        NumDoc: numDoc
      },
    },this.confIniciales.httpOptions()).toPromise();
  }

  

  encontrarEmpleado(emp_codigo: string){
   
    return this.http.post<any[]>(this.way + '/nom/transotregresos/2zv7tauh5yjiber', {
     
        elementos: {
          Emp_codigo: emp_codigo
        },
      },this.confIniciales.httpOptions()).toPromise();
  }

  stringToDate(fecha: string){
    if ( fecha !== '' ){

      let fechaArray = fecha.split('/');

      return new Date( +fechaArray[2], +fechaArray[1] - 1, +fechaArray[0]) ;

    }
    
  }

  nuevoRegistro(txtNumeroDoc, lblEMP_CODIGO, txtConcepto, txtImporte: number, dTFechaEmision, txtNumComprobante, txtReferenciaAdicional,
    txtCON_CODIGO, conceptoCodigo, txtENCANTEMP_NUMECUOT, lblPERIODO_CODIGO, lblPERIODO_FECHAINI, strMAERUBRO_CODRUBR,strPERIODO_FECFIN,
     tipDia, txtEMP_CENTCOST ){
    return  this.http.post<any[]>(this.way + '/nom/transotregresos/osquwzzwc9np1ns', {
      elementos: {
        p_ENCANTEMP_NroDocumento: txtNumeroDoc, 
        p_com_codigo: '01',
        p_emp_codigo: lblEMP_CODIGO,
        p_ENCANTEMP_concepto: txtConcepto,
        p_ENCANTEMP_importe: txtImporte,
        p_ENCANTEMP_FechaEmision: dTFechaEmision,
        p_ENCANTEMP_nrocomprobante: txtNumComprobante,
        p_ENCANTEMP_referencia: txtReferenciaAdicional,
        p_con_codigo: txtCON_CODIGO,
        p_maeconcpotregre_codigo: conceptoCodigo,
        p_encantemp_numecuot: txtENCANTEMP_NUMECUOT,
        p_periodo_codigo: lblPERIODO_CODIGO,
        p_periodo_fecini: lblPERIODO_FECHAINI,
        p_maerubro_codrubr: strMAERUBRO_CODRUBR,
        p_periodo_fecfin: strPERIODO_FECFIN,
        p_tipdia_codigo: tipDia,
        p_cen_codigo: txtEMP_CENTCOST,
      },
    },this.confIniciales.httpOptions()).toPromise();
  }

  


  actualizaRegistro(txtNumeroDoc, txtConcepto, txtImporte: number, dTFechaEmision, txtNumComprobante, txtReferenciaAdicional,
    conceptoCodigo, txtENCANTEMP_NUMECUOT, lblPERIODO_CODIGO, lblPERIODO_FECHAINI, strMAERUBRO_CODRUBR,strPERIODO_FECFIN,
      txtEMP_CENTCOST ){
    return  this.http.post<any[]>(this.way + '/nom/transotregresos/1xgxoqf6fyua2rb', {
      elementos: {
        p_ENCANTEMP_NroDocumento: txtNumeroDoc,
        p_com_codigo: '01',
        p_ENCANTEMP_concepto: txtConcepto,
        p_ENCANTEMP_importe: txtImporte,
        p_ENCANTEMP_FechaEmision: dTFechaEmision,
        p_ENCANTEMP_nrocomprobante: txtNumComprobante,
        p_ENCANTEMP_referencia: txtReferenciaAdicional,
        p_maeconcpotregre_codigo: conceptoCodigo,
        p_encantemp_numecuot: txtENCANTEMP_NUMECUOT,
        p_periodo_codigo: lblPERIODO_CODIGO,
        p_periodo_fecini:  lblPERIODO_FECHAINI,
        p_maerubro_codrubr: strMAERUBRO_CODRUBR,
        p_periodo_fecfin: strPERIODO_FECFIN,
        p_cen_codigo: txtEMP_CENTCOST
      },
    },this.confIniciales.httpOptions()).toPromise();
  }



  funGlbBorraTrnDocumentoYTrnCajaBan(trncobro_nrodoc){
    
    return this.http.post<any[]>(this.way + '/nom/transotregresos/bdrxcyd0ixbj0fa', {
      
        elementos: {
          Trncobro_nrodoc: trncobro_nrodoc
        },
      },this.confIniciales.httpOptions()).toPromise();
  }


  insertarCajaMovimiento(CAJ_CODIGO, trnFecha, tipo, TRNCAJ_NUMERO, txtNumeroDoc, dbl_importe ){
    return  this.http.post<any[]>(this.way + '/nom/transotregresos/c3x4yh85u0yal3h', {
      elementos: {
        p_CAJ_codigo_1: CAJ_CODIGO,
        p_TRNCAJ_fecha_2: trnFecha,
        p_TRNCAJ_tipo_3: tipo,
        p_TRNCAJ_numero_4: TRNCAJ_NUMERO,
        p_TRNCAJ_formapago_5: tipo,
        p_TRNCAJ_docnro_6: txtNumeroDoc,
        p_TRNCAJ_importe_7: (dbl_importe * -1),
        p_TRNCAJ_descripcion_8: 'ANTICIPO A EMPLEADOS',
        p_TRNCAJ_anulado: '0',
        p_TRNCAJ_fechavenci_9: '',
        p_TRNCAJ_origen_10: 'NOM',
        p_COM_codigo_11: '01',
        p_BANCLI_codigo_12: '',
        p_USU_IDENTIFICACION: this.usuario.identificacion,
        p_CLI_codigo: '',
        p_cen_codigo: '',
        p_ven_codigo: '',
      },
    },this.confIniciales.httpOptions()).toPromise();
  }

  insertarBancoMovimiento(parametrosSp3,banCodigo,trnFecha, TRNBAN_NUMERO, txtNumeroDoc, dbl_importe, lblPROnombre, CON_CODIGO, TRNBAN_PAGOELE, txtPROcodigo){
    return  this.http.post<any[]>(this.way + '/nom/transotregresos/z812glpxesam91p', {
      esquema: this.usuarioEsquema,
      elementoEjecutar: 'NOM_PCK_ENCANTICIPO.BAN_SP_insertarBancoMovimiento',
      elementos: {
        p_BAN_codigo_1: banCodigo,
        p_TRNBAN_fecha_2: trnFecha ,
        p_TRNBAN_tipo_3: parametrosSp3,
        p_TRNBAN_numero_4: TRNBAN_NUMERO,
        p_TRNBAN_docnro_5: txtNumeroDoc,
        p_TRNBAN_importe_6: (dbl_importe * -1),
        p_TRNBAN_concepto_7: 'ANTICIPO A EMPLEADOS' ,
        p_TRNBAN_beneficiario_9: lblPROnombre,
        p_TRNBAN_anulado_10: '0',
        p_CON_codigo_11: CON_CODIGO,
        p_COM_codigo_12: '01',
        p_TRNBAN_origen_13: 'NOM',
        p_TRNBAN_NUMEROREFUSER: '',
        p_trnban_fechavence: trnFecha,
        p_trnban_pagoelec: (TRNBAN_PAGOELE === false )? '0' : '1',
        p_emp_codigo: txtPROcodigo,
      },
    },this.confIniciales.httpOptions()).toPromise();
  }


  actualizarNumero(clase, docRef, nemonico, serie){
    return  this.http.post<any[]>(this.way + '/nom/transotregresos/m1cn81qbaus7jgz', {
      elementos: {
        p_NUM_clase: clase,
        p_COM_codigo: '01',
        p_NUM_docref: docRef,
        p_NUM_nemonico: nemonico,
        p_NUM_serie: serie,
      },
    },this.confIniciales.httpOptions()).toPromise();
  }



  actualizarSaldoAnticipos(TxtNumeroDoc ){
    return  this.http.post<any[]>(this.way + '/nom/transotregresos/37qch49k8ftwcea', {
      elementos: {
        p_ENCANTEMP_NroDocumento: TxtNumeroDoc,
        p_com_codigo: '01',
      },
    },this.confIniciales.httpOptions()).toPromise();
  }


  getcajCodigoFormaPago(){
  
    return this.http.post<any[]>(this.way + '/nom/transotregresos/h708iz8t73c5uzf', {
       
        elementos: {
        },
      },this.confIniciales.httpOptions()).toPromise();
  }



  getcajCodigoVendedor(strVenCodigo){
  
    return this.http.post<any[]>(this.way + '/nom/transotregresos/fdmdqw2gnip9ehj', {
        elementos: {
          VenCodigo: strVenCodigo
        },
      },this.confIniciales.httpOptions()).toPromise();
  }



  funGlbObtieneCuentaCaja(caj_codigo: string){
  
    return this.http.post<any[]>(this.way + '/nom/transotregresos/u8974dsle3zx4hr', {
        elementos: {
          Caj_codigo: caj_codigo
        },
      },this.confIniciales.httpOptions()).toPromise();
  }


  getInfoPeriodo(lblPERIODO_CODIGO){
  
    return this.http.post<any[]>(this.way + '/nom/transotregresos/cfdyctg1zvckbl0', {
        elementos: {
          LblPERIODO_CODIGO: lblPERIODO_CODIGO
        },
      },this.confIniciales.httpOptions()).toPromise();
  }


  getValorSueldo(lblEMP_CODIGO){
  
    return this.http.post<any[]>(this.way + '/nom/transotregresos/inody7aqxot3hh6', {
        elementos: {
          LblEMP_CODIGO: lblEMP_CODIGO
        },
      },this.confIniciales.httpOptions()).toPromise();
  }

  

  getObtisueldoprordiasmes(PERIODO_DIACALC, dblEMP_REMSALUNI){
  
    return this.http.post<any[]>(this.way + '/nom/transotregresos/wg8u6galkm6rszb', {
        elementos: {
          PERIODO_DIACALC: PERIODO_DIACALC,
          DblEMP_REMSALUNI: dblEMP_REMSALUNI
        },
      },this.confIniciales.httpOptions()).toPromise();
  }



  actVacEmplDesOtrEgrCont( datos){
    return  this.http.post<any[]>(this.way + '/nom/transotregresos/nlpbrl1lkyuka7d', {
      elementos: {
        p_com_codigo: datos.p_com_codigo ,
        p_tranvacaempl_tipo: datos.p_tranvacaempl_tipo ,
        p_tranvacaempl_periodo: datos.p_tranvacaempl_periodo,
        p_tranvacaempl_feciniperi: datos.p_tranvacaempl_feciniperi,
        p_tranvacaempl_fecfinperi: datos.p_tranvacaempl_fecfinperi,
        p_tranvacaempl_diasperi:  datos.p_tranvacaempl_diasperi,
        p_tranvacaempl_diasprorr: datos.p_tranvacaempl_diasprorr,
        p_tranvacaempl_baseacum: datos.p_tranvacaempl_baseacum,
        p_tranvacaempl_valor: datos.p_tranvacaempl_valor,
        p_periodo_codigo: datos.p_periodo_codigo,
        p_emp_codigo: datos.p_emp_codigo,
        p_encrolindi_codigo: datos.p_encrolindi_codigo,
        p_tranvacaempl_estado: datos.p_tranvacaempl_estado,
        p_tranvacaempl_fechsalivaca: datos.p_tranvacaempl_fechsalivaca,
        p_tranvacaempl_fechregrvaca: datos.p_tranvacaempl_fechregrvaca,
        p_tranvacaempl_observaciones: datos.p_tranvacaempl_observaciones,
        p_encantemp_nrodocumento: datos.p_encantemp_nrodocumento,
      },
    },this.confIniciales.httpOptions()).toPromise();
  }




  funGlbVerificaAsientoCuadrado(str_asiNro: string){
  
    return this.http.post<any[]>(this.way + '/nom/transotregresos/i28lrpr1zc4ur31', {
        elementos: {
          AsiNro: str_asiNro
        },
      },this.confIniciales.httpOptions()).toPromise();
  }


  funValidaAntNoLigCierrCaj(txtNumeroDoc: string){
  
    return this.http.post<any[]>(this.way + '/nom/transotregresos/fa2rtiy0u4s9igw', {
        elementos: {
          TxtNumeroDoc: txtNumeroDoc
        },
      },this.confIniciales.httpOptions()).toPromise();
  }

  eliminarEncAnticipo(txtNumeroDoc){
    return  this.http.post<any[]>(this.way + '/nom/transotregresos/klszq6jsv7uxc5z', {
      elementos: {
        p_ENCANTEMP_NroDocumento: txtNumeroDoc,
        p_com_codigo: '01',
      },
    },this.confIniciales.httpOptions()).toPromise();
  }

  generarAsiento(txtNumeroDoc, lblEMP_CODIGO, cmb_td ){
    return  this.http.post<any[]>(this.way + '/nom/transotregresos/vxr9d00hsbx2zdb', {
      elementos: {
        p_ENCANTEMP_NroDocumento: txtNumeroDoc,
        p_emp_codigo: lblEMP_CODIGO,
        p_com_codigo: '01',
        p_origen: 'NOM',
        p_tipdia_codigo: cmb_td,
        P_usuario: this.usuario.identificacion,
      },
    },this.confIniciales.httpOptions()).toPromise();
  }

  busquedaCodigoCaja() {
    return  this.http.post<any[]>(this.way + '/nom/transotregresos/0hsbx2zdbvxr9d0', {
      elementos: {
      },
    },this.confIniciales.httpOptions()).toPromise();
  }

  funcObtieneNumeroCheque(str_BANcodigo){
    return  this.http.post<any[]>(this.way + '/nom/transotregresos/0hsbx2r1zc4ur31', {
      elementos: {
        p_BAN_codigop:str_BANcodigo,
        p_com_codigo: '01'
      },
    },this.confIniciales.httpOptions()).toPromise();
  }

  generarCuotaEgreFijo(strDocumento){
    return  this.http.post<any[]>(this.way + '/nom/transotregresos/1075d9eee1d0dc000', {
      elementos: {
        p_encantemp_nrodocumento: strDocumento,
        p_com_codigo: '01'
      },
    },this.confIniciales.httpOptions()).toPromise();
  }

  EliminarAsientoContable(strAsiNro){
    return this.http.post<any[]>(this.way + '/nom/transotregresos/eacacae4998bf800', {
        elementos: {
          p_asi_nro: strAsiNro,
          p_com_codigo: '01'
        },
      },this.confIniciales.httpOptions()).toPromise();
  }
  ActualizarAsinroEnc(strNumero){
    return this.http.post<any[]>(this.way + '/nom/transotregresos/533aebb15c95f80', {
        elementos: {
          DOCNRO: strNumero
        },
      },this.confIniciales.httpOptions()).toPromise();
  }
  ActualizarBanTrnBan(strNumero){
    return this.http.post<any[]>(this.way + '/nom/transotregresos/533ab55fbbbad40', {
        elementos: {
          DOCNRO: strNumero
        },
      },this.confIniciales.httpOptions()).toPromise();
  }
  ActualizarBanTrnCaj(strNumero){
    return this.http.post<any[]>(this.way + '/nom/transotregresos/533ab55fbbbb140', {
        elementos: {
          DOCNRO: strNumero
        },
      },this.confIniciales.httpOptions()).toPromise();
  }


}


