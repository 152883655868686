import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from 'src/app/usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class NomproccontabService {
 
  private way: string;

  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, 
              private confIniciales: ConfInicialesService,) {
                
                this.way = sessionStorage.getItem('way');
               }


  

  getRoles( srtOpcion, strFechaIni, strFechaFin ){
    return this.http.post<any[]>(this.way + '/nom/contabilizar/eg3r2t3r0a1qwpg', {
      elementos: { 
        p_fecini: strFechaIni, 
        p_fecfin: strFechaFin,
        p_opcion: srtOpcion  
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  

  contabilizar(periodoCodigo, tipRolCodigo) {
    return this.http.post<any>(this.way + '/nom/contabilizar/5icmn7hk37fwi3f', {
      elementos: {
        p_periodo_codigo: periodoCodigo,
        p_tiprol_codigo: tipRolCodigo,
        p_com_codigo: '01',
        p_usuidentificacion: this.usuario.identificacion,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }



  buscarAsiento(periodoCodigo, tipRolCodigo){
    
    return this.http.post<any[]>(this.way + '/nom/contabilizar/wdf4365beuctl4z', {
        elementos: {
          PeriodoCodigo: periodoCodigo,
          TipRolCodigo: tipRolCodigo,
        }
      }, this.confIniciales.httpOptions()).toPromise();
  }

  getTipoRol(tipoRol){
    
    return this.http.post<any[]>(this.way + '/nom/contabilizar/64najo90r6eizng', {
      elementos: {
        TipRolCodigo: tipoRol,
      }
      }, this.confIniciales.httpOptions()).toPromise();
  }




  
}
