import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {Observable} from 'rxjs';
import {BanDetBandejaPagos, BanEncBandejaPagos} from '../baninterfaces/banBandejaPagos';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Injectable({
  providedIn: 'root'
})
export class BanbandejapagosService {
  
  public encBandejaPagos: BanEncBandejaPagos;
  public detBandejaPagos: BanDetBandejaPagos[] = [];
  
  private way: string;
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService,
              private init: NuevoComponentService) {
    this.way = sessionStorage.getItem('way');
  }
  
  navegarRegistro(tipo, codigo) {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/babeedba12e7780', {
      elementos: {
        p_tipo: tipo,
        p_com_codigo: '01',
        p_encbandeja_codigo: codigo,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  encontrarNombreEstado(strEstado) {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/c2fb7c597b73b80', {
      elementos: {
        ref_codigo: strEstado
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerDatosEnc(strEncBanCodigo) {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/617d75dd762970', {
        elementos: {
          encbandeja_codigo : strEncBanCodigo
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }  
  
  autoCompletar(strCadena, intTamanioCadena) {
    let intAux = 0;
    let indice = 0;
    let strAutoCompletar = '';
    
    intAux = strCadena.length;
    for (indice = 1; indice <= intTamanioCadena - intAux; indice++) {
      strAutoCompletar = '0' + strAutoCompletar;
    }
    strAutoCompletar = strAutoCompletar + strCadena;
    return strAutoCompletar;
  }
  
  verificarDocConcil(encBandejaCodigo) {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/fbb6dc3198bb280', {
        elementos: {
          encbandeja_codigo: encBandejaCodigo
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  mostrarDocumentos(strTrnCobroNroDoc) {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/b639ddab6dc3400', {
        elementos: {
          encbandeja_codigo: strTrnCobroNroDoc
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  listarDatos(strEncBandejaCodigo) {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/565ceab6d5771c', {
        elementos: {
          encbandeja_codigo: strEncBandejaCodigo
        },
        
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  encontrarBanco(strCondicion): Observable<any> {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/3aecc5fab55d98', {
        elementos: {
          condicion: strCondicion
        },
        
      }, this.confIniciales.httpOptions()
    );
  }
  
  listarDatos2(strOrden) {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/acb9d56daaee380', {
        elementos: {
          orden: strOrden
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  obtenerConfiguracion() {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/617d75ddb662f0', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  guardarEncabezadoBanPagos(encbancodigo, enccomprobante, encfechaemi, bancodigo, fecdesde, fecfin, incret, inctod, concepto, total,
                            numenvio, patharchivo, estado, beneficiario) {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/8795b6baec5ab00', {
      elementos: {
        p_encbandeja_codigo: encbancodigo,
        p_com_codigo: '01',
        p_encbandeja_comprobante: enccomprobante,
        p_encbandeja_fechaemision: encfechaemi,
        p_ban_codigo: bancodigo,
        p_encbandeja_fecdesde: fecdesde,
        p_encbandeja_fecfin: fecfin,
        p_encbandeja_incret: incret,
        p_encbandeja_inctod: inctod,
        p_encbandeja_concepto: concepto,
        p_encbandeja_total: total,
        p_encbandeja_numenvio: numenvio,
        p_usuidentificacion: this.usuario.identificacion,
        p_encbandeja_patharchivo: patharchivo,
        p_encbandeja_estado: estado,
        p_encbandeja_beneficiario: beneficiario,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  guardarDetBanPagos(encbancodigo, item: BanDetBandejaPagos) {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/8795b6b5dd5ab00', {
      elementos: {
        p_encbandeja_codigo: encbancodigo,
        p_com_codigo: '01',
        p_detbandeja_comprobante: item.DETBANDEJA_COMPROBANTE,
        p_detbandeja_tipodoc: item.DETBANDEJA_TIPODOC,
        p_pro_codigo: item.PRO_CODIGO,
        p_detbandeja_importe: Number(item.DETBANDEJA_IMPORTE),
        p_detbandeja_descuento: Number(item.DETBANDEJA_DESCUENTO),
        p_detbandeja_tipodscto: item.DETBANDEJA_TIPODSCTO,
        p_detbandeja_ref: 'F',
        p_contro_grabar: item.CONTROL_GRABAR
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarItemDetalle(encCodigo, comprobante, procodigo) {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/3ab2b4baeb35dc', {
      elementos: {
        p_encbandeja_codigo: encCodigo,
        p_com_codigo: '01',
        p_detbandeja_comprobante: comprobante,
        p_pro_codigo: procodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  generarComprobanteEgreso(encCodigo) {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/41d7662d974370', {
      elementos: {
        p_encbandeja_codigo: encCodigo,
        p_com_codigo: '01',
        p_usuidentificacion: this.usuario.identificacion
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarEncBandeja(encCodigo) {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/3ab2b3aec5aaec', {
      elementos: {
        p_encbandeja_codigo: encCodigo,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  actualizarEstadoFacturaProv(proCodigo, nroDoc) {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/299d773aa6e1f8', {
      elementos: {
        p_pro_codigo: proCodigo,
        p_com_codigo: '01',
        p_trncobro_nrodoc: nroDoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerNumeroExpendiente() {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/c2fb7f59dc66600', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  veriExistExpediente(expnumero, exptipo, expdocumento, exporigen, expestado, expreferencia ) {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/7ddb93ddc665cc', {
      elementos: {
        p_exp_numero: String(expnumero),
        p_com_codigo: '01',
        p_exp_tipo: exptipo,
        p_exp_documento: expdocumento,
        p_exp_origen: exporigen,
        p_exp_estado: expestado,
        p_exp_referencia: expreferencia
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  insertarExpendiente(expnumero, exptipo, expdocumento, exporigen, expestado, expreferencia ) {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/4afc76faee3330', {
      elementos: {
        p_exp_numero: String(expnumero),
        p_com_codigo: '01',
        p_exp_tipo: exptipo,
        p_exp_documento: expdocumento,
        p_exp_origen: exporigen,
        p_exp_estado: expestado,
        p_exp_referencia: expreferencia
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  actualizarExpediente(encCodigo, strBandejaComprobante, strProCodigo, strExpNumero) {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/299df29dc665cc', {
      elementos: {
        p_encbandeja_codigo: encCodigo,
        p_com_codigo: '01',
        p_detbandeja_comprobante: strBandejaComprobante,
        p_pro_codigo: strProCodigo,
        p_exp_numero: strExpNumero
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerClave() {
    return this.http.post<any>(this.way + '/ban/bandejapagos/617d6555f72bdc', {
      elementos: {},
    }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  updateEstadoBandejaT(strEncBandejaCodigo) {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/299d773ab55dbc', {
        elementos: {
          encbandeja_codigo: strEncBandejaCodigo
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  updateEstadoBandejaP(strEncBandejaCodigo) {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/299d773ab55db8', {
        elementos: {
          encbandeja_codigo: strEncBandejaCodigo
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  listarDatosArchivoOcp(codigoBandeja) {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/565ceab6c8e198', {
      elementos: {
        p_encbandeja_codigo: codigoBandeja === '' ? '0' : codigoBandeja,
        p_com_codigo: '01',
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  ejeSpAchivoCash(encCodigo) {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/3a6e56d9162b90', {
      elementos: {
        p_ENCCASHMNG_CODIGO: encCodigo,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerDatosAchivoCashFormato3() {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/6abb856d9162b80', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  obtenerDatosRubro(strDocumento) {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/c2fad5771bf2f80', {
        elementos: {
          nrodoc: strDocumento
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  
  calcularImporteTotal(): number {
    let intIndiceLista = 0;
    let dblTotalImporte = 0;
    // Total de los documentos seleccionados
    for (intIndiceLista = 0; intIndiceLista < this.detBandejaPagos.length; intIndiceLista++) {
      const item = this.detBandejaPagos[intIndiceLista];
      if(item.CHECK === false){
        item.DETBANDEJA_IMPORTE=Number(0).toFixed(2);
      }
      dblTotalImporte = dblTotalImporte + Number(item.DETBANDEJA_IMPORTE) - Number(item.DETBANDEJA_DESCUENTO);
    }
    for (intIndiceLista = 0; intIndiceLista < this.detBandejaPagos.length; intIndiceLista++) {
      const item = this.detBandejaPagos[intIndiceLista];
      item.DETBANDEJA_IMPORTE=Number(item.DETBANDEJA_IMPORTE).toFixed(2);

    }
    const value = dblTotalImporte;
    this.encBandejaPagos.ENCBANDEJA_TOTAL = Number(value).toFixed(2);
    this.encBandejaPagos.ENCBANDEJA_TOTAL = this.init.moneyValidation(this.encBandejaPagos.ENCBANDEJA_TOTAL);
    
    return dblTotalImporte;
    
  }
   //CDPJ
   obtenerAsiNro(strDocumento) {
    return this.http.post<any[]>(this.way + '/ban/bandejapagos/17ee315b5725770000', {
        elementos: {
          nrodoc: strDocumento,
          com_codigo:'01'
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  //CDPJ
}
