import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {FacturaVenta, VenCredito, VenDebito, VenGuiaRemision, VenLiquidacion, VenRetencion} from '../veninterfaces/firmardocumento';


@Injectable({
  providedIn: 'root'
})
export class FirmardocumentoService {
  
  public detalleVenFacturas: FacturaVenta[][] = [];
  public detalleVenRetenciones: VenRetencion[][] = [];
  public detalleVenGuiaRemisiones: VenGuiaRemision[][] = [];
  public detalleVenDebitos: VenDebito[][] = [];
  public detalleVenCreditos: VenCredito[][] = [];
  public detalleVenLiquidacion: VenLiquidacion[][] = [];
  public btnFirmarFact: boolean[] = [];
  public btnFirmarRet: boolean[] = [];
  public btnFirmarGuia: boolean[] = [];
  public btnFirmarCred: boolean[] = [];
  public btnFirmarDeb: boolean[] = [];
  public btnFirmarLiq: boolean[] = [];
  
  
  
  private url: string;
  public way: string;
  constructor(
    private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    private confIniciales: ConfInicialesService,
    public usuario: Usuario,  private init: NuevoComponentService
  ) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }
  
  
  eslistarDatos2Fac(strfechaInicio,strfechaFin ){
    return this.http.post<any[]>(this.way + '/ventas/firmardocumentos/xececb3whdyc06l', {      
      elementos: {
        fechaInicio:strfechaInicio,
        fechaFin: strfechaFin
      },
    }, this.confIniciales.httpOptions()).toPromise();
    
  }
  eslistarDatos1Fac(strfechaInicio,strfechaFin ){
    return this.http.post<any[]>(this.way + '/ventas/firmardocumentos/1y2skro3j9aqf0o', {      
      elementos: {
        fechaInicio:strfechaInicio,
        fechaFin: strfechaFin
      },
    }, this.confIniciales.httpOptions()).toPromise();
    
  }
  eslistarDatos1Credito(strfechaInicio,strfechaFin ){
    return this.http.post<any[]>(this.way + '/ventas/firmardocumentos/8zpn8hkj4ju743b', {      
      elementos: {
        fechaInicio:strfechaInicio,
        fechaFin: strfechaFin
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
 
  eslistarDatos1Debito(strfechaInicio,strfechaFin){
    return this.http.post<any[]>(this.way + '/ventas/firmardocumentos/o0oflsdh8nqav9x', {      
      elementos: {
        fechaInicio:strfechaInicio,
        fechaFin: strfechaFin
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eslistarDatos1guiarem(strfechaInicio,strfechaFin){
    return this.http.post<any[]>(this.way + '/ventas/firmardocumentos/2t273g3q8pn65nd', {      
      elementos: {
        fechaInicio:strfechaInicio,
        fechaFin: strfechaFin
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eslistarDatos1Retencion(strfechaInicio,strfechaFin){
    return this.http.post<any[]>(this.way + '/ventas/firmardocumentos/fxfh21q9lkmley1', {      
      elementos: {
        fechaInicio:strfechaInicio,
        fechaFin: strfechaFin
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eslistarDatos1Liquidacion(strfechaInicio,strfechaFin){
    return this.http.post<any[]>(this.way + '/ventas/firmardocumentos/0vefa4b3fpfvn7m', {      
      elementos: {
        fechaInicio:strfechaInicio,
        fechaFin: strfechaFin
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  leerConfiguracionDatosElectronicos() {
    return this.http.post<any[]>(this.way + '/ventas/firmardocumentos/bzdp51lusbkzs4h', {      
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
    
  }
  
  obtenerClave() {
    
    return this.http.post<any>(this.way + '/ventas/firmardocumentos/r2sz21ruzrjzuz6', {
        elementos: {},
       
      }, this.confIniciales.httpOptions()
    ).toPromise();
  
  }
  erfechaDual(){
    return this.http.post<any[]>(this.way + '/ventas/firmardocumentos/6h3z5gq2370z7yr', {      
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
    
  }
  erfechaDualinsert(){
    return this.http.post<any[]>(this.way + '/ventas/firmardocumentos/j4gb5djf27ytt53', {      
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
    
  }

  
  firmarDocumentos(numDoc, doctipo, estado, resultado, ubicacionarch, secuencial, tipoambiente) {    
    return this.http.post<any[]>(this.way + '/ventas/firmardocumentos/sg6b6n9iy7kku6g', {      
      elementos: {
        p_trndoc_numdoc: numDoc,
        p_trndoc_tipo: doctipo,
        p_trndoc_estado: estado,
        p_trndoc_resultado: resultado,
        p_trndoc_ubicaarch: ubicacionarch,
        p_trndoc_secuencial: secuencial,
        p_usuidentificacion: this.usuario.identificacion,
        p_com_codigo: '01',
        p_trndoc_tipoamb_fe: tipoambiente
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  activarBtnFact() {
    let numSel = 0;
    for (const item of this.detalleVenFacturas[this.init.tabs[this.init.tabindex].indice]) {
      if (item.DOC_SELECCIONADOF === true) {
        numSel++;
      }
    }
    if (numSel === 0) {
      this.btnFirmarFact[this.init.tabs[this.init.tabindex].indice] = true;
    } else {
      this.btnFirmarFact[this.init.tabs[this.init.tabindex].indice] = false;
    }
    
  }
  activarBtnRet() {
    let numSel = 0;
    
    for (const item of this.detalleVenRetenciones[this.init.tabs[this.init.tabindex].indice]) {
      if (item.DOC_SELECCIONADOR === true) {
        numSel++;
      }
    }
    
    if (numSel === 0) {
      this.btnFirmarRet[this.init.tabs[this.init.tabindex].indice] = true;
    } else {
      this.btnFirmarRet[this.init.tabs[this.init.tabindex].indice] = false;
    }
    
  }
  activarBtnGuia() {
    let numSel = 0;
    
    for (const item of this.detalleVenGuiaRemisiones[this.init.tabs[this.init.tabindex].indice]) {
      if (item.DOC_SELECCIONADOG === true) {
        numSel++;
      }
    }
    
    if (numSel === 0) {
      this.btnFirmarGuia[this.init.tabs[this.init.tabindex].indice] = true;
    } else {
      this.btnFirmarGuia[this.init.tabs[this.init.tabindex].indice] = false;
    }
    
  }
  activarBtnCred() {
    let numSel = 0;
    
    for (const item of this.detalleVenCreditos[this.init.tabs[this.init.tabindex].indice]) {
      if (item.DOC_SELECCIONADOC === true) {
        numSel++;
      }
    }
    
    if (numSel === 0) {
      this.btnFirmarCred[this.init.tabs[this.init.tabindex].indice] = true;
    } else {
      this.btnFirmarCred[this.init.tabs[this.init.tabindex].indice] = false;
    }
    
  }
  activarBtnDeb() {
    let numSel = 0;
    
    for (const item of this.detalleVenDebitos[this.init.tabs[this.init.tabindex].indice]) {
      if (item.DOC_SELECCIONADOD === true) {
        numSel++;
      }
    }
    
    if (numSel === 0) {
      this.btnFirmarDeb[this.init.tabs[this.init.tabindex].indice] = true;
    } else {
      this.btnFirmarDeb[this.init.tabs[this.init.tabindex].indice] = false;
    }
    
  }
  activarBtnLiq() {
    let numSel = 0;
    
    for (const item of this.detalleVenLiquidacion[this.init.tabs[this.init.tabindex].indice]) {
      if (item.DOC_SELECCIONADOL === true) {
        numSel++;
      }
    }
    
    if (numSel === 0) {
      this.btnFirmarLiq[this.init.tabs[this.init.tabindex].indice] = true;
    } else {
      this.btnFirmarLiq[this.init.tabs[this.init.tabindex].indice] = false;
    }
  }
}
