<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="cxcmaeretencion"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="cxcmaeretencionConfirmacion" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>

<div class="p-grid" style="width: 99%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
						 [botonRegresar]="btnRegresar" [barraBotonesCRUD]="false"
						 [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"
						 [barraBotones5]="true"
						 (signal)="manejarSenales($event)"></app-invmaebarra>
	</div>

</div>
<div style="overflow: hidden" class="p-col-12">
	<p-tabView>
		<p-tabPanel header="Retención">
			<div class="p-col-12">
				<app-aggridsaci [width]="{'width':'100%', 'height':'calc(100vh - 508px)'}"
								[height]="largo"
								[enableSorting]="false"
								[enableFilter]="true"
								[rowData]="cuentaContableServicio.cxcmaeretencion"
								[frameworkComponents]="frameworkComponents"
								[animateRows]="true"
								[rowSelection]="rowSelection"
								[columnDefs]="columnDefsretencion"
								[defaultColDef]="defaultColDefretenciones"
								[rowStyle]="rowStyle"
								[singleClickEdit]="false"
								[botones]="false"
								(selected)="selectedRetencion($event)"
								(cambios)="cambio($event)"
				></app-aggridsaci>
			</div>

			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-2" style="margin-left: -15px">
						<span style="font-weight: bold;color: #002752;font-size: 1em">Tipo:</span>
					</div>
					<div class="p-col-5" style="margin-left: -15px">
						<span style="font-weight: bold;color: #002752;font-size: 1em">Tipo de Anex: </span>
					</div>
					<div class="p-col-4" style="margin-left: -15px">
						<span style="font-weight: bold;color: #002752;font-size: 1em">Tipo de Retención:</span>
					</div>
				</div>
			</div>
			<div class="p-col-12" style="margin-top: -15px">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-2">
						<span style="font-weight: bold;color: #002752;font-size: 1em">IVB  --> Iva de bienes </span>
					</div>
					<div class="p-col-5">
						<span style="font-weight: bold;color: #002752;font-size: 1em">NDRC --> No desplegar retención en los anexos de compra.</span>
					</div>
					<div class="p-col-4">
						<span style="font-weight: bold;color: #002752;font-size: 1em">RF --> Retención en la fuente</span>
					</div>
				</div>
			</div>
			<div class="p-col-12" style="margin-top: -15px">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-2">
						<span style="font-weight: bold;color: #002752;font-size: 1em">IVS  --> Iva de Servicios </span>
					</div>
					<div class="p-col-5">
						<span style="font-weight: bold;color: #002752;font-size: 1em">NDNC --> No desplegar números de retención en los anexos de compra.</span>
					</div>
					<div class="p-col-4">
						<span style="font-weight: bold;color: #002752;font-size: 1em">Multiple S --> Ingreso de la retención con mismo numero de documento.</span>
					</div>
				</div>
			</div>
		</p-tabPanel>
	</p-tabView>
</div>

