import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';

import {SegComConexion, SegInfadicional, SegMaeSucursal} from '../seginterfaces/segmaecompania';
import {MessageService} from 'primeng';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SegmaecompaniaService {
  public way: string;


  public detConexionArray: SegComConexion[][] = [];
  public detSucursalArray: SegMaeSucursal[][] = [];
  public detInfoFactArray: SegInfadicional[][] = [];
  public detInfoGuiaRemArray: SegInfadicional[][] = [];
  public detInfoLiqComArray: SegInfadicional[][] = [];
  public detInfoDevfacArray: SegInfadicional[][] = [];//GSRF
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, private confIniciales: ConfInicialesService, private messageService: MessageService,
              private errorService: ErroresBaseDatosService, private init: NuevoComponentService,
              private  auditoriaGralService: AuditoriagralService) {
  //  this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }
  
  consultarReferencia(refTipo, refOrden, refSelec) {
    return this.http.post<any[]>(this.way + '/seg/compania/662fcf576e7bb80', {
      elementos: {
        p_REF_tipo: refTipo,
        p_REF_orden: refOrden,
        p_REF_selecc: refSelec
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  listar(opcion) {
    const strSentencia = 'SELECT * FROM SEG_MAECOMPANIA';
    
    return this.http.post<any[]>(this.way + '/seg/compania/acb9d662d955e80', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  actualizarCompania(codigoCpia, ruc, razonSoc, comDireccion, comTelefono1, comTelefono2, comFax, comRepresentante, comCorreo, comProvincia,
                     comLocalizacionRpt, comAutSriRet, comSerieRet, comNombreP, comCoordinador, comCICoordinador, comContador, comRUCContador,
                     comLocalizacionFor, comNombreCorto, ComCantidad, strLocalizacionLogo1, strLocalizacionLogo2, strLocalizacionArchivo, strAmbFacElec,
                     FacElecAct, ProdAct, comContribuyenteespecial, comObligadocontabilidad, comUbicacionarchivos, comCorreocopiafac, comCorreocopianc,
                     comCorreocopiagre, comCorreocopiand, comCorreocopiaret, comubicacionlog, comSubsisio, comUbicacionXmlAut, comTransporte,
                     comCorreocopialiq, comConregmic, comAgenteret, txtresolucion, strConRimpe,strConRimpe_NP) {
    
    
    return this.http.post<any[]>(this.way + '/seg/compania/533be662d955e40', {
      elementos: {
        p_COMcodigo_1: codigoCpia,
        p_COMruci_2: ruc,
        p_COMnombre_3: razonSoc,
        p_COMdireccion_4: comDireccion,
        p_COMtelefono1_5: comTelefono1,
        p_COMtelefono2_6: comTelefono2,
        p_COMfax_7: comFax,
        p_COMrepresentante_8: comRepresentante,
        p_COMcorreo_9: comCorreo,
        p_COMprovincia_10: comProvincia,
        p_COMlocalizacion_rpt: comLocalizacionRpt,
        p_COMautsriret: comAutSriRet,
        p_COMserieret13: comSerieRet,
        p_COMNombreP: comNombreP,
        p_COMcoordinador: comCoordinador,
        p_COMcicoordinador: comCICoordinador,
        p_COMcontador: comContador,
        p_COMruccontador: comRUCContador,
        p_COMlocalizacion_for: comLocalizacionFor,
        p_COMnombrecorto: comNombreCorto,
        P_COMCANTIDAD: ComCantidad,
        p_comlocalizacionlog1: strLocalizacionLogo1,
        p_comlocalizacionlog2: strLocalizacionLogo2,
        p_comlocalizacionarchivo: strLocalizacionArchivo,
        p_com_Feactivada: FacElecAct,
        p_com_Prodactivada: ProdAct,
        p_com_tipoambfactelec: strAmbFacElec,
        p_com_contribuyenteespecial: comContribuyenteespecial,
        p_com_obligadocontabilidad: comObligadocontabilidad,
        p_com_ubicacionarchivos: comUbicacionarchivos,
        p_com_correocopiafac: comCorreocopiafac,
        p_com_correocopianc: comCorreocopianc,
        p_com_correocopiagre: comCorreocopiagre,
        p_com_correocopiand: comCorreocopiand,
        p_com_correocopiaret: comCorreocopiaret,
        p_com_ubicacionlog: comubicacionlog,
        p_com_subsidio: comSubsisio,
        p_com_ubicacionxmlaut: comUbicacionXmlAut,
        p_com_transporte: comTransporte,
        p_com_correocopialiq: comCorreocopialiq,
        p_com_comconregmic: '',
        p_com_agenteret: comAgenteret,
        p_com_numageret: txtresolucion,
        p_com_comconregrimpe: strConRimpe,
        p_strConRimpe_NP:strConRimpe_NP
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarDocumentosPruebaFE() {
    let strSentencia = '';
    
    strSentencia = 'delete from ele_trndocumento ';
    strSentencia = strSentencia + ' where trndoc_tipoamb_fe=\'1\'';
    strSentencia = strSentencia + ' and com_codigo=\'01\'';
    
    return this.http.post<any[]>(this.way + '/seg/compania/756566e199dae00', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  actualizarAccesoConexion(comConexion, pinAcceso, correoPin, telefonoPin) {
    this.http.post(this.way + '/seg/compania/299d5318ee3318', {
      elementos: {
        COM_CONEXION: String(comConexion),
        COM_PING: String(pinAcceso),
        COM_CORREOPING: String(correoPin),
        COM_TELEFONOPING: String(telefonoPin)
      }
    }, this.confIniciales.httpOptions()
    ).subscribe();
  }
  
  
  listarIPPublica() {
    const strSentencia = 'select * from seg_maeippublica order by ip_codigo';
    
    return this.http.post<any[]>(this.way + '/seg/compania/acb9d9673e5d680', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  guardarConexion(itemSel) {
    return this.http.post<any[]>(this.way + '/seg/compania/21e56daa662ee', {
      elementos: {
        p_com_codigo: itemSel.COM_CODIGO,
        p_ip_codigo: itemSel.IP_CODIGO,
        p_ip_numero: itemSel.IP_NUMERO,
        p_ip_descripcion: itemSel.IP_DESCRIPCION,
      }
    }, this.confIniciales.httpOptions()).toPromise();
    
  }
  
  async guardarConexionEnter(itemSel: SegComConexion) {
    try {
      const rs = await this.guardarConexion(itemSel);
      
      for (let fila = 0; fila < this.detConexionArray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
        const item = this.detConexionArray[this.init.tabs[this.init.tabindex].indice][fila];
        if (Number(itemSel.IP_CODIGO)  === item.IP_CODIGO) {
          item.IP_CODIGO = rs[0][':B1'];
        }
      }
      this.auditoriaGralService.registrarAuditoria('SEG_MAEIPPUBLICA', itemSel.IP_NUMERO, 'I',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', err.error);
    }
  }
  
  eliminarDetConexion(comCodigo, ipCodigo) {
    return this.http.post<any[]>(this.way + '/seg/compania/756566bbacc5dc0', {
      elementos: {
        p_com_codigo: comCodigo,
        p_ip_codigo: ipCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
    
  }
  
  listarSucursal() {
    const strSentencia = 'select * from SEG_MAESUCURSAL';
    
    return this.http.post<any[]>(this.way + '/seg/compania/acb9de799edf180', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  eliminarDetSucursal(sucCodigo, comCodigo) {
    return this.http.post<any[]>(this.way + '/seg/compania/756566bbbcf33c0', {
      elementos: {
        p_suc_codigo: sucCodigo,
        p_com_codigo: comCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  guardarSucursal(itemSel, proceso) {
    
    return this.http.post<any[]>(this.way + '/seg/compania/8795be799edf180', {
      elementos: {
        p_suc_codigo: itemSel.SUC_CODIGO,
        p_com_codigo: itemSel.COM_CODIGO,
        p_suc_descripcion: itemSel.SUC_DESCRIPCION,
        p_suc_ructransportista: itemSel.SUC_RUCTRANSPORTISTA,
        p_suc_razonsocialtranspor: itemSel.SUC_RAZONSOCIALTRANSPOR,
        p_suc_regimen: itemSel.SUC_REGIMEN,
        p_control: Number(itemSel.INT_NUMSEC)
      }
    }, this.confIniciales.httpOptions()).toPromise();
    
  }

  actualizarSucursal(itemSel, proceso) {
    
    return this.http.post<any[]>(this.way + '/seg/compania/14cefb9e67b7c', {
      elementos: {
        p_suc_codigo: itemSel.SUC_CODIGO,
        p_com_codigo: itemSel.COM_CODIGO,
        p_suc_descripcion: itemSel.SUC_DESCRIPCION,
        p_suc_ructransportista: itemSel.SUC_RUCTRANSPORTISTA,
        p_suc_razonsocialtranspor: itemSel.SUC_RAZONSOCIALTRANSPOR,
        p_suc_regimen: itemSel.SUC_REGIMEN,
        p_control: Number(itemSel.INT_NUMSEC)
      }
    }, this.confIniciales.httpOptions()).toPromise();
    
  }
  
  async guardarSucursalEnter(itemSel: SegMaeSucursal) {
    try {
      if (itemSel.INT_NUMSEC === 0) {
        await this.guardarSucursal(itemSel, 'seg_pck_maesucursales.seg_sp_guaSucursal');
        for (let fila = 0; fila < this.detSucursalArray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
          
          if (Number(itemSel.SUC_CODIGO)  === Number(this.detSucursalArray[this.init.tabs[this.init.tabindex].indice][fila].SUC_CODIGO)) {
            this.detSucursalArray[this.init.tabs[this.init.tabindex].indice][fila].INT_NUMSEC = 1;
          }
        }
        
        this.auditoriaGralService.registrarAuditoria('SEG_MAESUCURSAL', itemSel.SUC_CODIGO, 'I',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
        
        this.mensajeAlerta('success', 'Información', 'Registro guardado exitosamente...!!!');
        
      } else {
        await this.actualizarSucursal(itemSel, 'seg_pck_maesucursales.seg_sp_actSucursal');
        this.auditoriaGralService.registrarAuditoria('SEG_MAESUCURSAL', itemSel.SUC_CODIGO, 'A',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
        
        this.mensajeAlerta('success', 'Información', 'Registro actualizado exitosamente...!!!');
        
      }
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', err.error);
    }
  }
  
  listarInfoAdiFact() {
    const strSentencia = 'select * from seg_infadifac';
    return this.http.post<any[]>(this.way + '/seg/compania/15973b2bbd4d94000', {
        elementos: {},
      }, this.confIniciales.httpOptions()
      
    ).toPromise();
  }
  
  eliminarDetInfoAdiFact(comCodigo, numSec) {
    return this.http.post<any[]>(this.way + '/seg/compania/eacad2bbd4d7a800', {
      elementos: {
        p_com_codigo: comCodigo,
        p_infad_numsec: numSec
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  guardarInfoFactura(itemSel) {
    return this.http.post<any[]>(this.way + '/seg/compania/8795b6caef7a980', {
      elementos: {
        p_com_codig: itemSel.COM_CODIGO,
        p_infad_numsec: itemSel.INFAD_NUMSEC,
        p_infad_codigo : itemSel.INFAD_CODIGO,
        p_infad_descripcion: itemSel.INFAD_DESCRIPCION
      }
    }, this.confIniciales.httpOptions()).toPromise();
    
  }
  
  async guardarInfoFactEnter(itemSel: SegInfadicional) {
    try {
      const rs = await this.guardarInfoFactura(itemSel);
      
      if (itemSel.INFAD_NUMSEC === 0) {
        this.auditoriaGralService.registrarAuditoria('SEG_INFADIFAC', itemSel.INFAD_CODIGO, 'I',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
        
        for (let fila = 0; fila < this.detInfoFactArray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
          const item = this.detInfoFactArray[this.init.tabs[this.init.tabindex].indice][fila];
          if (Number(itemSel.INFAD_NUMSEC)  === item.INFAD_NUMSEC) {
            item.INFAD_NUMSEC = rs[0][':B1'];
          }
        }
      } else {
        this.auditoriaGralService.registrarAuditoria('SEG_INFADIFAC', itemSel.INFAD_CODIGO, 'A',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
      }
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', err.error);
    }
  }
  
  listarInfoAdiGuiaRem() {
    const strSentencia = 'select * from seg_infadigre';
    
    return this.http.post<any[]>(this.way + '/seg/compania/acb92bbd4d86e00', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  eliminarDetInfoAdiGuiaRem(comCodigo, numSec) {
    return this.http.post<any[]>(this.way + '/seg/compania/7565695dea86dc0', {
      elementos: {
        p_com_codigo: comCodigo,
        p_infad_numsec: numSec
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  guardarInfoGuiaRem(itemSel) {
    return this.http.post<any[]>(this.way + '/seg/compania/8795b95dea86e00', {
      elementos: {
        p_com_codig: itemSel.COM_CODIGO,
        p_infad_numsec: itemSel.INFAD_NUMSEC,
        p_infad_codigo : itemSel.INFAD_CODIGO,
        p_infad_descripcion: itemSel.INFAD_DESCRIPCION
      }
    }, this.confIniciales.httpOptions()).toPromise();
    
  }
  
  async guardarInfoGuiaRemEnter(itemSel: SegInfadicional) {
    try {
      const rs = await this.guardarInfoGuiaRem(itemSel);
      
      if (itemSel.INFAD_NUMSEC === 0) {
        this.auditoriaGralService.registrarAuditoria('SEG_INFADIGRE', itemSel.INFAD_CODIGO, 'I',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
        
        for (let fila = 0; fila < this.detInfoGuiaRemArray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
          const item = this.detInfoGuiaRemArray[this.init.tabs[this.init.tabindex].indice][fila];
          if (Number(itemSel.INFAD_NUMSEC)  === item.INFAD_NUMSEC) {
            item.INFAD_NUMSEC = rs[0][':B1'];
          }
        }
      } else {
        this.auditoriaGralService.registrarAuditoria('SEG_INFADIGRE', itemSel.INFAD_CODIGO, 'A',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
      }
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', err.error);
    }
  }
  
  listarInfoAdiLiqCom() {
    const strSentencia = 'select * from seg_infadiliq';
    
    return this.http.post<any[]>(this.way + '/seg/compania/15972577e14d93000', {
        elementos: {}
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  eliminarDetInfoAdiLiqCom(comCodigo, numSec) {
    return this.http.post<any[]>(this.way + '/seg/compania/eacad2bbd4d95800', {
      elementos: {
        p_com_codigo: comCodigo,
        p_infad_numsec: numSec
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  guardarInfoLiqCom(itemSel) {
    return this.http.post<any[]>(this.way + '/seg/compania/21e56e577e14da0000', {
      elementos: {
        p_com_codig: itemSel.COM_CODIGO,
        p_infad_numsec: itemSel.INFAD_NUMSEC,
        p_infad_codigo : itemSel.INFAD_CODIGO,
        p_infad_descripcion: itemSel.INFAD_DESCRIPCION
      }
    }, this.confIniciales.httpOptions()).toPromise();
    
  }
  
  async guardarInfoLiqComEnter(itemSel: SegInfadicional) {
    try {
      const rs = await this.guardarInfoLiqCom(itemSel);
      
      if (itemSel.INFAD_NUMSEC === 0) {
        this.auditoriaGralService.registrarAuditoria('SEG_INFADILIQ', itemSel.INFAD_CODIGO, 'I',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
        
        for (let fila = 0; fila < this.detInfoLiqComArray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
          const item = this.detInfoLiqComArray[this.init.tabs[this.init.tabindex].indice][fila];
          if (Number(itemSel.INFAD_NUMSEC)  === item.INFAD_NUMSEC) {
            item.INFAD_NUMSEC = rs[0][':B1'];
          }
        }
      } else {
        this.auditoriaGralService.registrarAuditoria('SEG_INFADILIQ', itemSel.INFAD_CODIGO, 'A',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
      }
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', err.error);
    }
  }
  
  obtenerMegasUitilizadas() {
    return this.http.post<any[]>(this.way + '/compras/docalmacenados/185f6ce82b9eed000', {
        elementos: {}
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  mensajeAlerta(tipo, titulo, msg) {
    this.messageService.add({
      key: 'compania',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }  
  
  obtenerClave(clave): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/pagocontado/jwn7qmo1cc3b6ih', {
      elementos: {},
      datos: {
        Clave: clave
      }
    }, this.confIniciales.httpOptions());
  }
   //GSRF
  guardarInfodevfac(itemSel) {
    return this.http.post<any[]>(this.way + '/seg/compania/8795b6ab72bbf000000000000', {
      elementos: {
        p_com_codig: itemSel.COM_CODIGO,
        p_infad_numsec: itemSel.INFAD_NUMSEC,
        p_infad_codigo : itemSel.INFAD_CODIGO,
        p_infad_descripcion: itemSel.INFAD_DESCRIPCION
      }
    }, this.confIniciales.httpOptions()).toPromise();
    
  }

  async guardarInfodevfacenter(itemSel: SegInfadicional) {
    try {
      const rs = await this.guardarInfodevfac(itemSel);
      
      if (itemSel.INFAD_NUMSEC === 0) {
        this.auditoriaGralService.registrarAuditoria('SEG_INFADIDEVFAC', itemSel.INFAD_CODIGO, 'I',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
        
        for (let fila = 0; fila < this.detInfoDevfacArray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
          const item = this.detInfoDevfacArray[this.init.tabs[this.init.tabindex].indice][fila];
          if (Number(itemSel.INFAD_NUMSEC)  === item.INFAD_NUMSEC) {
            item.INFAD_NUMSEC = rs[0][':B1'];
          }
        }
        this.mensajeAlerta('success', 'Información', 'Registro ingresado exitosamente...!!!');
      } else {
        this.auditoriaGralService.registrarAuditoria('SEG_INFADIDEVFAC', itemSel.INFAD_CODIGO, 'A',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
        this.mensajeAlerta('success', 'Información', 'Registro actualizado exitosamente...!!!');
      }
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', err.error);
    }
  }

  listarInfoAdidevfac() {
    const strSentencia = 'select * from seg_infadidevfac';
    
    return this.http.post<any[]>(this.way + '/seg/compania/565ceab72bbf0c0000000000', {
        elementos: {}
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  eliminarDetInfoAdidevfac(comCodigo, numSec) {
    return this.http.post<any[]>(this.way + '/seg/compania/7565695d5b6bbc0000000000000000', {
      elementos: {
        p_com_codigo: comCodigo,
        p_infad_numsec: numSec
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //GSRF
  //CDPJ
  uploadFirma(archivo, nombre) {
    return this.http.post<any[]>(this.way + '/seg/compania/7b2d575cf96ec8', {
      elementos: {
        file: archivo,
        name: nombre,

      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizapwd(con) {
    return this.http.post<any[]>(this.way + '/seg/compania/533be55658', {
      elementos: {
        cont:con
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  //CDPJ
}
