import {Injectable} from '@angular/core';

@Injectable()
export class UsuarioDescargas {
  CLI_RUCIDE: string;
  CLI_NOMBRE: string;
  CLI_CORREO: string;
  CLI_PASSWD: string;
  esquema: string;
  
  login: boolean = false;
  
  public saveStorage() {
    sessionStorage.setItem('userDescargas', JSON.stringify({
      esquema: this.esquema,
      CLI_RUCIDE: this.CLI_RUCIDE,
      CLI_NOMBRE: this.CLI_NOMBRE,
      CLI_CORREO: this.CLI_CORREO,      
      login: this.login
    }));
  }
  
  public loadStorage() {
    const store = JSON.parse(sessionStorage.getItem('userDescargas'));
    if (store == null) {
      this.login = false;
    } else {
      this.esquema = store.esquema;
      this.CLI_RUCIDE = store.CLI_RUCIDE;
      this.CLI_NOMBRE = store.CLI_NOMBRE;
      this.CLI_CORREO = store.CLI_CORREO;
        this.login = store.login;
    }
  }

}
