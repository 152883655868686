import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Venmaepry} from '../veninterfaces/venmaepry';
import {Observable} from 'rxjs';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class VenmaepryService {
  get venmaepry(): Venmaepry[] {
    return this._venmaepry;
  }

  set venmaepry(value: Venmaepry[]) {
    this._venmaepry = value;
  }
  public url: string;
  public way: string;
  private _venmaepry: Venmaepry[];

  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }
  getPry(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/proyectos/20eee778', {
        elementos: {}
    }, this.confIniciales.httpOptions());
  }



  insertarPry(pry: Venmaepry): Observable<any[]> {
    return this.http.post<any>(this.way + '/ventas/proyectos/12bf1dbee778', {
      elementos: {
        p_pry_codigo: pry.PRY_CODIGO,
        p_com_codigo: pry.COM_CODIGO ,
        p_pry_nombre: pry.PRY_NOMBRE ,
        p_cli_codigo: pry.CLI_CODIGO ,
        p_bod_codigo1: pry.BOD_CODIGO1 ,
        p_bod_nombre1: pry.NOM_BOD1 ,
        p_bod_codigo2: pry.BOD_CODIGO2 ,
        p_bod_nombre2: pry.NOM_BOD2 ,
        p_bod_codigo3: pry.BOD_CODIGO3 ,
        p_bod_nombre3: pry.NOM_BOD3 ,
        p_bod_codigo4: pry.BOD_CODIGO4 ,
        p_bod_nombre4: pry.NOM_BOD4 ,
        p_bod_codigo5: pry.BOD_CODIGO5 ,
        p_bod_nombre5: pry.NOM_BOD5 ,
        p_bod_codigo6: pry.BOD_CODIGO6 ,
        p_bod_nombre6: '',
        p_pry_activo: pry.PRY_ACTIVO,
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarPry(pry: Venmaepry, codigo): Observable<any[]> {
    return this.http.post<any>(this.way + '/ventas/proyectos/299df2ab2', {
      elementos: {
        p_pry_codigo: pry.PRY_CODIGO,
        p_com_codigo: pry.COM_CODIGO ,
        p_pry_nombre: pry.PRY_NOMBRE ,
        p_cli_codigo: pry.CLI_CODIGO ,
        p_bod_codigo1: pry.BOD_CODIGO1 ,
        p_bod_nombre1: pry.NOM_BOD1 ,
        p_bod_codigo2: pry.BOD_CODIGO2 ,
        p_bod_nombre2: pry.NOM_BOD2 ,
        p_bod_codigo3: pry.BOD_CODIGO3 ,
        p_bod_nombre3: pry.NOM_BOD3 ,
        p_bod_codigo4: pry.BOD_CODIGO4 ,
        p_bod_nombre4: pry.NOM_BOD4 ,
        p_bod_codigo5: pry.BOD_CODIGO5 ,
        p_bod_nombre5: pry.NOM_BOD5 ,
        p_bod_codigo6: pry.BOD_CODIGO6 ,
        p_bod_nombre6: pry.NOM_BOD6 ,
        p_pry_activo: pry.PRY_ACTIVO,
      }
    }, this.confIniciales.httpOptions());
  }



  eliminarPry(pry: Venmaepry): Observable<any[]> {
    return this.http.post<any>(this.way + '/ventas/proyectos/299df2ab2', {
      elementos: {
        p_pry_codigo: pry.PRY_CODIGO,
        p_com_codigo: pry.COM_CODIGO,
      }
    }, this.confIniciales.httpOptions());
  }


}
