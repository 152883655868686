import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import { MessageService } from 'primeng';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class SacimaeperiodoService {
  public way: string;

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private message: MessageService,
    private confIniciales: ConfInicialesService
  ) {
    this.way = sessionStorage.getItem('way');
  }

  loadData(): Observable<any[]> {
    return this.http.post<any>(this.way + '/cont/saciperiodo/cc5f9eaf72edc800', {
      elementos: {
        p_opcion: '',
        p_filtro: 'COM_CODIGO = \'01\'',
        p_condicion: '',
      }
    }, this.confIniciales.httpOptions());
  }

  guardaPeriodo(fechainicial, fechafinal): Observable<any> {
    return this.http.post<any>(this.way + '/cont/saciperiodo/14cef955965db90000', {
      elementos: {
        p_PER_fechainicial_1:  fechainicial,
        p_PER_fechafinal_2: fechafinal,
        p_COM_codigo_3: '01'
      }
    }, this.confIniciales.httpOptions());
  }

}
