<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="busqDlgMae"></p-toast>
<div style="height: 50px">
	<button style="background-image: url(../../../assets/images/iconos/filter.png);"
			(click)="busquedaIndi(busquedaParams, tipoBusqueda)"></button>
</div>
<div>
	<div class="p-grid">
		<div class="p-col-8 text-left">
			<table>
				<tr *ngFor="let data of arrayOptions; let indi = index" style="height: 30px">
					<td>{{data.label}}</td>
					<td style="width: 35px"></td>
					<td><input *ngIf="busquedaParams !== undefined" type="text" pInputText size="50"
							   [(ngModel)]="busquedaParams[indi]"
							   (keydown.enter)="busquedaIndi(busquedaParams, tipoBusqueda)"
							   style="width: auto"></td>
				</tr>
			</table>
		</div>
		<div class="p-col-4 text-left">
			<div class="p-grid">
				<div class="p-col-12">
					<p-radioButton name="grupo1" label="Coincidencias" value="contains"
								   [(ngModel)]="tipoBusqueda"></p-radioButton>
				</div>
				<div class="p-col-12">
					<p-radioButton name="grupo1" label="Palabras Completas" value="equals"
								   [(ngModel)]="tipoBusqueda"></p-radioButton>
				</div>
				<div class="p-col-12">
					<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}"
									   animationDuration=".5s"></p-progressSpinner>
				</div>
			</div>
		</div>
	</div>
</div>

