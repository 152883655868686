
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { Usuario } from 'src/app/usuario';


@Injectable({
  providedIn: 'root'
})
export class GenerarpedidosService {

  public way: string;

  constructor(private http: HttpClient, private confIniciales: ConfInicialesService,
    public usuario: Usuario) {
    this.way = sessionStorage.getItem('way');
  }

  metodo(cliCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/transformarpedidos/jdhfishf', {
      elementos: {
        CLI_CODIGO: cliCodigo
      },
    }, this.confIniciales.httpOptions());
  }


  erpBusquedaGrupo(tabla, where) {
    return this.http.post(this.way + '/ventas/transformarpedidos/76f2bf7350dfb40', {
      elementos: {
        tabla, where
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  consultarcantidad(grupo, tipo): any {
    return this.http.post(this.way + '/ventas/transformarpedidos/cc5f9eaf55b6280000000', {
      elementos: {
        grupo, tipo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarpedidos(TIPO, FECHAEMISION, CONCEPTO, GRUPO, CLASE, NEMONICO, SERIE, SECACTUAL, CHKPRECIOSERVICIO): any {
    return this.http.post(this.way + '/ventas/transformarpedidos/20ebbb6ade5cda0000', {
      elementos: {
        p_tipo: TIPO,
        p_fechaemision: FECHAEMISION,
        p_concepto: CONCEPTO,
        p_grupo: GRUPO,
        p_clase: CLASE,
        p_nemonico: NEMONICO,
        p_serie: SERIE,
        p_secactual: SECACTUAL,
        p_usuidentificacion: this.usuario.identificacion,
        p_com_codigo: '01',
        p_checkprecioservicio: CHKPRECIOSERVICIO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerClave(clave): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/pagocontado/jwn7qmo1cc3b6ih', {
      elementos: {},
      datos: {
        Clave: clave
      }
    }, this.confIniciales.httpOptions());
  }

}

