//
// SACI WEB
// Rutina: Utilitario documentos electronicos
// Propietario: Danny Alvarez
// Modificado por: DA
// Fecha de creación: 02-12-2019
// Fecha de Modificación: 20-12-2019
//

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {EncabezadoCierreCaja} from '../../ban/baninterfaces/encabezadoCierreCaja';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {AggridBanComponent} from '../../ban/aggrid-ban/aggrid-ban.component';
import {BancierrecajaService} from '../../ban/banservicios/bancierrecaja.service';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {DatePipe} from '@angular/common';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {VenmaeclienteService} from '../venservicios/venmaecliente.service';
import {VenMaeCliente} from '../veninterfaces/venmaecliente';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {EletrndocumentoService} from '../venservicios/eletrndocumento.service';
import {Commaeprovedor} from '../../com/cominterfaces/commaeproveedor';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';//CDPJ

@Component({
  selector: 'app-venutidocumentoselectronicos',
  templateUrl: './eletrndocumento.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class EletrndocumentoComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  index: number;
  dateDesde: any;
  dateHasta: any;
  types: SelectItem[];
  botonListo: boolean;
  botonesD: boolean;
  opcionbusqueda: string;
  busquedacampos: string[];
  tabla: string;
  optionsT: SelectItem[];
  optionsE: SelectItem[];
  desSelect: boolean;
  tipoDocumento: string;
  getEstadoDocumento: string;
  spin: boolean;
  opcSelectOri: any;
  cmbtip: any[];
  cmbtipo: any[];
  documentos: any[];
  codcliente: string;
  nombreCliente: string;
  ubiDescarga: string;
  arregloCons: any[];
  displayDialogBusquedaFast: boolean;
  valor: boolean;
  botonesBoolean: boolean;
  descarga: string;
  resultado: string;
  activeIndex: number;
  opcionDocumento: string;
  opcionDropDown: string;
  checkCierre: boolean;
  largo: string;
  public frameworkComponents;
  lblclipro: string;
  rowStyle;
  defaultColDefDocumentos;
  rowSelection = 'multiple';
  
  columnDefsDocumentos = [
    {
      headerName: '', field: 'VISTO', editable: false, width: 50,
      cellRendererFramework: AggridBanComponent
    },
    {
      headerName: 'Fecha', field: 'ENCFAC_FECHAEMISION1', editable: false, width: 120
    },
    {
      headerName: 'Tipo', field: 'TRNDOC_TIPO1', editable: false, width: 230
    },
    {
      headerName: 'Documento', field: 'TRNDOC_NUMDOC', editable: false, width: 140
    },
    {
      headerName: 'Cliente', field: 'CLI_CODIGO', editable: false, width: 130
    },
    {
      headerName: 'Nombre', field: 'CLI_NOMBRE', editable: false, width: 250
    },
    {
      headerName: 'Estado', field: 'TRNDOC_ESTADO', editable: false, width: 75
    },
    {
      headerName: 'XML - PDF', field: 'DESCARGA', editable: false, width: 150
    },
    {
      headerName: 'Fecha Aut FE', field: 'TRNDOC_FECHAAUT_FE', editable: false, width: 150
    },
    {
      headerName: 'Referencia', field: 'TRNDOC_NUMDOC', editable: false, width: 180
    }];
  
  
  constructor(public eletrndocumentoService: EletrndocumentoService, 
              private busqService: InvbusquedaService,
              private datePipe: DatePipe, private errorService: ErroresBaseDatosService,
              private message: MessageService, private confirmationService: ConfirmationService,
              private init: NuevoComponentService, private utilitariosService: UtilitariosService,//CDPJ
              ) {
    this.agTable();
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefDocumentos = {
      editable: true,
      objeto: 'maecierre'
    };
  }
  
  ngOnInit() {
    this.dateDesde = new Date();
    this.dateHasta = new Date();
    this.activeIndex = 0;
    this.opcionDocumento = '';
    this.tipoDocumento = '';
    this.getEstadoDocumento = '';
    this.botonListo = true;
    this.valor = true;
    this.desSelect = false;
    this.spin = false;
    this.opcionDropDown = '';
    this.codcliente = '';
    this.nombreCliente = '';
    this.botonesBoolean = true;
    this.ubiDescarga = '';
    this.resultado = '';
    this.checkCierre = true;
    this.descarga = 'Descargar';
    this.largo = '500';
    this.optionsT = [{label: '', value: 'VAC'}, {label: '01. Factura', value: 'FAC'}, {label: '03. Liquidación de Compra', value: 'LIQ'},
      {label: '04. Nota de Crédito', value: 'CRE'}, {
        label: '05. Nota de Débito', value: 'DEB'
      }, {label: '06. Guía de Remisión', value: 'GRE'}, {label: '07. Comprobante de Retención', value: 'RET'}];
    this.optionsE = [{label: '', value: 'VAC'}, {label: 'VA. Vacío', value: 'VA'},
      {label: 'PP. Preparado', value: 'PP'}, {label: 'FR. Firmado', value: 'FR'}, {
        label: 'RB. Recibido', value: 'RB'
      }, {label: 'CP. Clave en Procesamiento', value: 'CP'}, {label: 'AT. Autorizado', value: 'AT'},
      {label: 'NA. No Autorizado', value: 'NA'}, {label: 'SN. Sin Notificación', value: 'SN'},
      {label: 'NT. Notificado', value: 'NT'}];
    this.lblclipro = 'Cliente:';
    
  }
  
  
  manejarSeleccion(opcion) {
    
    if (this.opcionbusqueda === 'busquedacliente') {
      this.codcliente = opcion.CLI_CODIGO;
      this.nombreCliente = opcion.CLI_NOMBRE;
      this.displayDialogBusquedaFast = false;
      // this.encontrarCliente(opcion.CLI_CODIGO);
    } else if (this.opcionbusqueda === 'busquedaproveedor') {
      // this.proveedorSeleccionado.PRO_TIPOIDE = opcion.PRO_TIPOIDE;
      // this.seleccionarCombo();
      this.codcliente = opcion.PRO_CODIGO;
      this.nombreCliente = opcion.PRO_NOMBRE;
      this.displayDialogBusquedaFast = false;
    }
  }
  
  reenviar() {
    // let auxReenviar = '';
    this.message.add({
      key: 'docelec',
      severity: 'warn',
      summary: 'Reenviar Correo',
      detail: 'Solo se reenviarán los documentos en estado NT o AT'
    });
    console.log(this.documentos);
    this.documentos.map((doc) => {
      if (doc.VISTO === true) {
        if (doc.TRNDOC_ESTADO === 'AT' || doc.TRNDOC_ESTADO === 'NT') {
          
          this.eletrndocumentoService.reenviarDoc(doc).subscribe(() => {
          });
        }
      }
    });
  }
  
  autorizar() {
    this.message.add({
      key: 'docelec',
      severity: 'warn',
      summary: 'Autorizar Documento',
      detail: 'Solo se puede verificar documentos en PROCESAMIENTO - Estado CP'
    });
    this.documentos.map((doc) => {
      if (doc.VISTO === true) {
        if (doc.TRNDOC_ESTADO === 'CP' || (doc.TRNDOC_ESTADO === 'NA' && doc.TRNDOC_RESULTADO === 'PROCES')) {
          this.eletrndocumentoService.autorizarDoc(doc).subscribe(() => {
          });
        }
      }
    });
  }
  
  refirmar() {
    this.message.add({
      key: 'docelec',
      severity: 'warn',
      summary: 'Firmar Documento Rechazado',
      detail: 'Solo se puede volver a firmar documentos RECHAZADOS'
    });
    this.documentos.map((doc) => {
      if (doc.VISTO === true) {
        if (doc.TRNDOC_ESTADO === 'NA' && doc.TRNDOC_RESULTADO === 'RECHAZADO') {
          this.eletrndocumentoService.refirmarDoc(doc).subscribe(() => {
          });
        }
      }
    });
  }
  //CDPJ
  async continuarFEL() {
    //this.closeSidebar();
    const msgclave = 'docelec';
    this.message.add({
      key: msgclave,
      severity: 'warn',
      summary: 'Información',
      detail: 'Solo se puede continuar con el procesado de firma cuando los documentos están en estado AT, RB,'
      +' PP y FR'
    });
    try {
      this.documentos.map(async (doc) => {
        //console.log('<<<',doc.TRNDOC_RESULTADO)
        if (doc.VISTO === true) {
          if (doc.TRNDOC_ESTADO !== 'VA' && doc.TRNDOC_ESTADO !== 'CP' 
          && doc.TRNDOC_ESTADO !=='SN' && doc.TRNDOC_ESTADO !=='NA' && doc.TRNDOC_ESTADO !== 'NT' 
          ) {
            this.spin = true;
            const data = await this.utilitariosService.continuarFirmaElectronicaTipo(doc.TRNDOC_NUMDOC,this.tipoDocumento);
            this.spin = false;
            if (data !== 'NOAUTORIZADO') {
              this.message.add({
                key: msgclave,
                severity: 'warn',
                summary: 'Información',
                detail: data
              });
            }
          }
        }
      });
    } catch (error) {
      this.message.add({
        key: msgclave,
        severity: 'success',
        summary: 'Información',
        detail: error
      });
    }
    
    //const strNumDoc = this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO;

    //   if (strNumDoc === '' || strNumDoc === null || strNumDoc === undefined) {
    //     this.messageService.add({
    //       key: msgclave,
    //       severity: 'error',
    //       summary: 'Información',
    //       detail: 'No ha seleccionado un documento'
    //     });
    //     return;
    //   }

    //   if (this.datosfe.COM_FEACTIVADA === '0') {
    //     this.messageService.add({
    //       key: msgclave,
    //       severity: 'error',
    //       summary: 'Información',
    //       detail: 'Facturación electrónica no está activada.'
    //     });
    //     return;
    //   }

    //   if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO_FE === ''
    //     || this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO_FE === undefined
    //     || this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO_FE === null) {
    //     this.messageService.add({
    //       key: msgclave,
    //       severity: 'error',
    //       summary: 'Información',
    //       detail: 'El documento no ha iniciado el proceso de firma electrónica.'
    //     });
    //     return;
    //   }

    //   if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO_FE === 'VA') {
    //     this.messageService.add({
    //       key: msgclave,
    //       severity: 'error',
    //       summary: 'Información',
    //       detail: 'El documento se encuentra en su estado inicial, reverse el proceso de firma electrónica.'
    //     });
    //     return;
    //   }

    //   if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO_FE === 'NT') {
    //     this.messageService.add({
    //       key: msgclave,
    //       severity: 'error',
    //       summary: 'Información',
    //       detail: 'El documento se encuentra notificado.'
    //     });
    //     return;
    //   }


    //   if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO_FE === 'NA') {
    //     this.messageService.add({
    //       key: msgclave,
    //       severity: 'error',
    //       summary: 'Información',
    //       detail: 'Visualize el log. Corrija el documento y reverse el proceso de firma electrónica.'
    //     });
    //     return;
    //   }

    //   this.spin = true;
    //   const data = await this.utilitariosService.continuarFirmaElectronica(strNumDoc);
    //   this.spin = false;
    //   if (data !== 'NOAUTORIZADO') {
    //     this.messageService.add({
    //       key: msgclave,
    //       severity: 'success',
    //       summary: 'Información',
    //       detail: data
    //     });
    //   }
  }
  async reversarFEL() {
    const msgclave = 'docelec';
    this.message.add({
      key: msgclave,
      severity: 'warn',
      summary: 'Información',
      detail: 'Solo puede reversar el proceso de facturación electrónica si el documento está en VA'
    });
    //this.closeSidebar();
    // if (this.datosfe.COM_FEACTIVADA === '0') {
    //   return;
    // }
    // const strNumDoc = this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO
    // if (strNumDoc === '' || strNumDoc === null || strNumDoc === undefined) {
    //   this.messageService.add({
    //     key: 'encfac',
    //     severity: 'error',
    //     summary: 'Información',
    //     detail: 'No ha seleccionado un documento'
    //   });
    //   return;
    // }

    //const data = await this.encfacService.actualizarDatosElecProm(this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);
    // for (const rs of data) {
    //   this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE = rs.ENCFAC_ESTADO_FE;
    //   this.encfacService.encfacarray[this.indicador].ENCFAC_AUTORIZACION_FE = rs.ENCFAC_AUTORIZACION_FE;
    //   this.encfacService.encfacarray[this.indicador].ENCFAC_CLAVEACCESO_FE = rs.ENCFAC_CLAVEACCESO_FE;
    // }

    // if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO === "ANULADA") {
    //   return;
    // }

    // if (this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE !== "AT"
    //   && this.encfacService.encfacarray[this.indicador].ENCFAC_ESTADO_FE !== "NT") {

      // this.confirmationService.confirm({
      //   message: 'Está seguro de reversar el proceso?',
      //   header: 'Pregunta',
      //   icon: 'pi pi-exclamation-triangle',
      //   key: 'confirmarencfac',
      //   acceptLabel: 'Si',
      //   rejectLabel: 'No',
      //   accept: () => {
      //     this.confirmationService.close();
          try {
            this.documentos.map(async (doc) => {
              //console.log('<<<',doc.TRNDOC_RESULTADO)
              if (doc.VISTO === true) {
                if (doc.TRNDOC_ESTADO === 'VA' || doc.TRNDOC_ESTADO === 'CP') {
                  this.spin = true;
                  //const data = await this.utilitariosService.continuarFirmaElectronica(doc.TRNDOC_NUMDOC);
                  this.utilitariosService.reversarProcesoFE(doc.TRNDOC_NUMDOC, this.tipoDocumento);
                //this.utilitariosService.reversarProcesoFEClave(this.encfacService.encfacarray[this.indicador].ENCFAC_CLAVEACCESO_FE);
                  this.spin = false;
                  // if (data !== 'NOAUTORIZADO') {
                  //   this.message.add({
                  //     key: msgclave,
                  //     severity: 'warn',
                  //     summary: 'Información',
                  //     detail: data
                  //   });
                  // }
                }
              }
            });
            
            // this.auditoriagral.registrarAuditoria('ELE_TRNDOCUMENTO', strNumDoc + 'VEN_REVERSAR_FE', 'E',
            //   '', '01', '', '', '', '').subscribe();
            this.message.add({
              key: msgclave,
              severity: 'success',
              summary: 'Información',
              detail: 'Reversar proceso de inicio de firma electrónica terminó satisfactoriamente'
            });
            //this.refrescarDatosFE('interno');
          } catch (e) {
            this.message.add({
              key: msgclave,
              severity: 'error',
              summary: 'Error',
              detail: 'Reversar proceso de inicio de firma electrónica no terminó satisfactoriamente'
            });
          }
      //   }, reject: () => {
      //     this.confirmationService.close();
      //   }
      // });
    // } else {
    //   this.message.add({
    //     key: msgclave,
    //     severity: 'error',
    //     summary: 'Información',
    //     detail: 'Solo puede reversar el proceso de facturación electrónica si el documento no esta NT(Autorizado) ó AT(Notificado)'
    //   });
    // }
  }
  //CDPJ
  busquedacliente(parametro) {
    this.opcionbusqueda = 'busquedacliente';
    this.types = [
      {label: 'Código', value: 'CLI_CODIGO'},
      {label: 'Nombre', value: 'CLI_NOMBRE'},
      {label: 'RucIde', value: 'CLI_RUCIDE'},
      {label: 'Zona', value: 'CLI_ZONA'},
      {label: 'Grupo', value: 'GRU_CODIGO'},
      {label: 'Contacto', value: 'CLI_CONTACTO'},
      {label: 'Placa', value: 'CLI_PLACA'},
      {label: 'NombreC', value: 'CLI_NOMBREC'},
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_MAECLIENTE';
    this.busqService.busquedaVenMaeCliente3(parametro).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }
  
  buscarProveedor(parametro) {
    this.opcionbusqueda = 'busquedaproveedor';
    this.types = [
      {label: 'Código', value: 'PRO_CODIGO'},
      {label: 'Nombre', value: 'PRO_NOMBRE'},
      {label: 'Nombre Corto', value: 'PRO_NOMBREC'},
      {label: 'RUC', value: 'PRO_RUCIDE'},
      {label: 'Grupo', value: 'GRU_CODIGO'},
      {label: 'RUC', value: 'PRO_RUCIDE'},
      {label: 'Cuenta', value: 'CON_CODIGO1'}
      ,
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', ''];
    this.tabla = 'COM_MAEPROVEEDOR';
    
    this.busqService.busquedaComMaeProveedor3(parametro).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }
  
  cambioTipo(event) {
    
    console.log(this.opcionDocumento);
    if (event.value === 'VAC') {
      this.message.add({
        key: 'docelec',
        severity: 'warn',
        summary: 'Error',
        detail: 'Debe seleccionar un tipo de documento'
      });
      this.botonListo = true;
    }
  
    if (this.dateDesde === null) {
      this.message.add({
        key: 'docelec',
        severity: 'warn',
        summary: 'Error',
        detail: 'Ingrese una fecha de inicio'
      });
      this.botonListo = true;
    }
  
    if (this.dateHasta === null) {
      this.message.add({
        key: 'docelec',
        severity: 'warn',
        summary: 'Error',
        detail: 'Ingrese una fecha de fin'
      });
      this.botonListo = true;
    }
    
    
    this.tipoDocumento = event.originalEvent.srcElement.innerText.substr(0, 2);
    if (event.originalEvent.srcElement.innerText === '03. Liquidación de Compra' ||
    event.originalEvent.srcElement.innerText === '07. Comprobante de Retención') {
      this.lblclipro = 'Proveedor:';
      this.columnDefsDocumentos[4].headerName = 'Proveedor';
      this.columnDefsDocumentos[4].field = 'PRO_CODIGO';
      this.columnDefsDocumentos[5].field = 'PRO_NOMBRE';
    } else {
      this.lblclipro = 'Cliente:';
      this.columnDefsDocumentos[4].headerName = 'Cliente';
      this.columnDefsDocumentos[4].field = 'CLI_CODIGO';
      this.columnDefsDocumentos[5].field = 'CLI_NOMBRE';
    }
    
    if (event.originalEvent.srcElement.innerText === '01. Factura') {
      this.lblclipro = 'Cliente:';
      this.columnDefsDocumentos[1].field = 'ENCFAC_FECHAEMISION1';
    } else if (event.originalEvent.srcElement.innerText === '03. Liquidación de Compra') {
      this.lblclipro = 'Proveedor:';
      this.columnDefsDocumentos[1].field = 'ENCFAC_FECHAEMISION1';
    } else if (event.originalEvent.srcElement.innerText === '04. Nota de Crédito') {
      this.lblclipro = 'Cliente:';
      this.columnDefsDocumentos[1].field = 'ENCDVF_FECHAEMISION1';
    } else if (event.originalEvent.srcElement.innerText === '05. Nota de Débito') {
      this.lblclipro = 'Cliente:';
      this.columnDefsDocumentos[1].field = 'TRNCOBRO_FECHATRN1';
    } else if (event.originalEvent.srcElement.innerText === '06. Guía de Remisión') {
      this.lblclipro = 'Cliente:';
      this.columnDefsDocumentos[1].field = 'ENCGRE_FECHAEMISION';
    } else if (event.originalEvent.srcElement.innerText === '07. Comprobante de Retención') {
      this.lblclipro = 'Proveedor:';
      this.columnDefsDocumentos[1].field = 'TRNRETENCION_FECHAEMISION1';
    }
    
    this.documentos = [];
    this.resultado = '';
    this.aggrid.refreshColumnDefs();
  }
  
  seleccionBusqueda() {
    if (this.lblclipro === 'Cliente:') {
      this.busquedacliente(this.codcliente);
    } else {
      this.buscarProveedor(this.codcliente);
    }
  }
  
  llenarcomboide() {
    this.cmbtip = this.cmbtipo;
  }
  
  habilitarBoton() {
    this.botonListo = false;
  }
  
  habilitarBotones() {
    this.botonesD = true;
    this.documentos.map(seleccion => {
      if (seleccion.VISTO === true) {
        this.botonesD = false;
      }
    });
  }
  
  fechaB() {
    if (this.dateDesde !== null && this.dateHasta !== null && (this.tipoDocumento !== '')) {
      this.botonListo = false;
    }
  }
  
  seleccionarTodo() {
    this.botonesBoolean = !this.botonesBoolean;
    if (this.desSelect === true) {
      this.documentos.map((doc) => {
        doc.VISTO = true;
      });
      // this.aggrid.refreshaggrid(this.documentos, this.defaultColDefDocumentos.object);
      this.aggrid.refreshColumnDefs();
    } else if (this.desSelect === false) {
      this.documentos.map((doc) => {
        doc.VISTO = false;
      });
      // this.aggrid.refreshaggrid(this.documentos, this.defaultColDefDocumentos.object);
      this.aggrid.refreshColumnDefs();
    }
  }
  
  cambioEstado(event) {
    this.getEstadoDocumento = event.originalEvent.srcElement.innerText.substr(0, 2);
    this.documentos = [];
    this.resultado = '';
  }
  
  seleccionFila(valor) {
    if (valor.object !== undefined) {
      this.documentos.map(variable => {
        if (variable.TRNDOC_NUMDOC === valor.object.TRNDOC_NUMDOC) {
          console.log('visto 2:', variable.VISTO);
          if (variable.VISTO === undefined) {
            variable.VISTO = false;
            this.habilitarBotones();
          } else {
            variable.VISTO = true;
          }
        }
      });
      this.aggrid.refreshColumnDefs();
      // this.aggrid.refreshaggrid(this.documentos, 'maecierre');
      this.message.add({
        key: 'docelec',
        severity: 'warn',
        summary: 'No seleccionó una ruta destino',
        detail: 'Seleccione una ruta destino'
      });
    }
    this.resultado = valor.object.TRNDOC_RESULTADO;
  }
  
  
  consultarDocumentos() {
    
    let opcionDocumento = '';
    let compania = '';
    let fechaDesde = '';
    let fechaHasta = '';
    let cliente = '';
    let estado = '';
    
    
    opcionDocumento = this.tipoDocumento;
    compania = 'a.com_codigo = \'' + '01' + '\'';
    
    if (this.tipoDocumento === '01') {
      // fechaDesde = this.dateDesde.toLocaleString().substr(0, 10);
      fechaDesde = '  and encfac_fechaemision>=to_date(\'' +
        this.datePipe.transform(this.dateDesde, 'dd/MM/yyyy')
        + ' 00:00\',\'dd/mm/yyyy HH24:MI\') ';
      fechaHasta = '  and encfac_fechaemision<=to_date(\''
        + this.datePipe.transform(this.dateHasta, 'dd/MM/yyyy')
        + ' 23:59\',\'dd/mm/yyyy HH24:MI\') ';
    } else if (this.tipoDocumento === '03') {
      fechaDesde = '  and ENCFACPRO_FECHAEMISION>=to_date(\''
        + this.datePipe.transform(this.dateDesde, 'dd/MM/yyyy')
        + ' 00:00\',\'dd/mm/yyyy HH24:MI\') ';
      fechaHasta = '  and ENCFACPRO_FECHAEMISION<=to_date(\''
        + this.datePipe.transform(this.dateHasta, 'dd/MM/yyyy')
        + ' 23:59\',\'dd/mm/yyyy HH24:MI\') ';
    } else if (this.tipoDocumento === '04') {
      fechaDesde = '  and ENCDVF_FECHAEMISION>=to_date(\''
        + this.datePipe.transform(this.dateDesde, 'dd/MM/yyyy')
        + ' 00:00\',\'dd/mm/yyyy HH24:MI\') ';
      fechaHasta = '  and ENCDVF_FECHAEMISION<=to_date(\''
        + this.datePipe.transform(this.dateHasta, 'dd/MM/yyyy')
        + ' 23:59\',\'dd/mm/yyyy HH24:MI\') ';
    } else if (this.tipoDocumento === '05') {
      fechaDesde = '  and TRNCOBRO_FECHATRN>=to_date(\''
        + this.datePipe.transform(this.dateDesde, 'dd/MM/yyyy')
        + ' 00:00\',\'dd/mm/yyyy HH24:MI\') ';
      fechaHasta = '  and TRNCOBRO_FECHATRN<=to_date(\''
        + this.datePipe.transform(this.dateHasta, 'dd/MM/yyyy')
        + ' 23:59\',\'dd/mm/yyyy HH24:MI\') ';
    } else if (this.tipoDocumento === '06') {
      fechaDesde = '  and encgre_fechaemision>=to_date(\''
        + this.datePipe.transform(this.dateDesde, 'dd/MM/yyyy')
        + ' 00:00\',\'dd/mm/yyyy HH24:MI\') ';
      fechaHasta = '  and encgre_fechaemision<=to_date(\''
        + this.datePipe.transform(this.dateHasta, 'dd/MM/yyyy')
        + ' 23:59\',\'dd/mm/yyyy HH24:MI\') ';
    } else if (this.tipoDocumento === '07') {
      fechaDesde = '  and trnretencion_FECHAEMISION>=to_date(\''
        + this.datePipe.transform(this.dateDesde, 'dd/MM/yyyy')
        + ' 00:00\',\'dd/mm/yyyy HH24:MI\') ';
      fechaHasta = '  and trnretencion_FECHAEMISION<=to_date(\''
        + this.datePipe.transform(this.dateHasta, 'dd/MM/yyyy')
        + ' 23:59\',\'dd/mm/yyyy HH24:MI\') ';
    }
    
    
    cliente = this.codcliente;
    if (cliente === '') {
      this.nombreCliente = '';
    }
    estado = this.getEstadoDocumento;
    
    if (estado === 'em') {
      this.getEstadoDocumento = '';
    }
    
    this.eletrndocumentoService.getEleDoc(opcionDocumento, compania, fechaDesde, fechaHasta, cliente, estado).subscribe
    ((res) => {
      // console.log('res:', res);
      if (res !== null) {
        this.documentos = res;
        this.documentos.map((doc) => {
          doc.DESCARGA = 'Descargar';
        });
      }
    });
  }
  
  salir() {
    this.init.tabs.splice(this.init.tabindex, 1);
  }
}
