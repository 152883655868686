import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { TecEquipodet } from '../tecinterfaces/tecequipodet';

@Injectable({
  providedIn: 'root'
})
export class TecmaeequipoService {

  public way: string;
  public tecmaeequipodet: TecEquipodet[][] = [];

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }

  getequipos() {
    return this.http.post(this.way + '/serviciotecnico/equipos/20eebb5e9671c', {
      elementos: {
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarequipo(codigo) {
    return this.http.post(this.way + '/serviciotecnico/equipos/3aecc5fbb56dda00000', {
      elementos: {
        codigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarequipo(item) {
    return this.http.post(this.way + '/serviciotecnico/equipos/257e3b7d56ddb00000', {
      elementos: {
        p_equi_codigo: item.EQUI_CODIGO,
        p_equi_nombre: item.EQUI_NOMBRE
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarequipo(item) {
    return this.http.post(this.way + '/serviciotecnico/equipos/299df2ab256dda00000', {
      elementos: {
        p_equi_codigo: item.EQUI_CODIGO,
        p_equi_nombre: item.EQUI_NOMBRE
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarequipo(item) {
    return this.http.post(this.way + '/serviciotecnico/equipos/1d595a5756ddaf0000', {
      elementos: {
        p_equi_codigo: item.EQUI_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getequiposdet(equicodigo) {
    return this.http.post(this.way + '/serviciotecnico/equipos/10775daf4b38e3000', {
      elementos: {
        equicodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarequipodet(equicodigo, clave) {
    return this.http.post(this.way + '/serviciotecnico/equipos/1d7662fddab6ed000000000', {
      elementos: {
        equicodigo, clave
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarequipodet(item) {
    return this.http.post(this.way + '/serviciotecnico/equipos/12bf1dbeab6ed800000000', {
      elementos: {
        p_equi_codigo: item.EQUI_CODIGO,
        p_eti_codigo: item.ETI_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarequipodet(item) {
    return this.http.post(this.way + '/serviciotecnico/equipos/14cef95592b6ed000000000', {
      elementos: {
        p_equi_codigo: item.EQUI_CODIGO,
        p_eti_codigo: item.ETI_CODIGO,
        p_clave: item.CLAVE
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarequipodet(item) {
    return this.http.post(this.way + '/serviciotecnico/equipos/eacad2bab6ed780000000', {
      elementos: {
        p_equi_codigo: item.EQUI_CODIGO,
        p_eti_codigo: item.ETI_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarEquipoCliente(item) {
    return this.http.post(this.way + '/serviciotecnico/equipos/cac9d7662fdda800000000', {
      elementos: {
        equicodigo: item.EQUI_CODIGO,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

}
