<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="precal"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarprecal" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"
						 [barraBotones5]="true" [botonVisto]="false" [barraBotonesCRUD]="true" [barraBotones3Alt]="false"
						 (signal)="manejarSenales($event)"
						 style="text-align: left;">
		</app-invmaebarra>
	</div>
</div>
<div class="p-grid general" style="width: 99%; margin-top: -20px">
	<div class="p-col-12"></div>
	<div class="p-col-12">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-3">
							<div class="p-grid">
								<div class="p-col-1">
								</div>
								<div class="p-col-5">
									<span>Desde:</span>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-5">
									<p-calendar id="desdefec" [inputStyle]="{width: '100%', height: '20px'}"
												dateFormat="dd/mm/yy" [(ngModel)]="fechadesde"></p-calendar>
								</div>
							</div>
						</div>
						<div class="p-col-8"></div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -20px">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-3">
							<div class="p-grid">
								<div class="p-col-1">
								</div>
								<div class="p-col-5">
									<span>Hasta:</span>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-5">
									<p-calendar id="hastafec" [inputStyle]="{width: '100%', height: '20px'}"
												dateFormat="dd/mm/yy" [(ngModel)]="fechahasta"></p-calendar>
								</div>
							</div>
						</div>
						<div class="p-col-8">
							<div class="p-grid">
								<div class="p-col-4"></div>
								<div class="p-col-4">
									<button class="aceptar" pButton (click)="ingresarResultados()"
											style="background-image: url(../../../assets/images/iconos/btnAceptar.png); line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; margin-left: 25px">
										Aceptar
									</button>
								</div>
								<div class="p-col-4"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -20px; margin-bottom: -20px">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-3">
							<div class="p-grid">
								<div class="p-col-1">
								</div>
								<div class="p-col-5">
									<span>Tipo:</span>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-5">
									<p-dropdown id="tipo" [style]="{'height':'100%', 'width': '300px'}"
												[panelStyle]="{'width':'300px'}" [options]="options"
												[(ngModel)]="opcionSeleccionada"></p-dropdown>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="p-col-12" style="margin-top: -20px">
		<div class="divgrups">
			<div class="p-col-12" style="margin-top: -15px; margin-bottom: -20px">
				<div class="p-grid">
					<div class="p-col-9"></div>
					<div class="p-col-2">
						<p-checkbox binary="true" label="Seleccionar Todos" [(ngModel)]="selectAll"
									(click)="seleccionarTodos()"></p-checkbox>
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
			<!--<div class="p-col-12">
			  <div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-7">
				  <span>Criterio de Búsqueda</span>
				</div>
				<div class="p-col-1"></div>
				<div class="p-col-3">
				  <span>Campo</span>
				</div>
				<div class="p-col-1"></div>
				<div class="p-col-6">
				  <input type="text" pInputText [disabled]="disableBus" [(ngModel)]="busquedaCriterio">
				</div>
				<div class="p-col-2">
				  <button class="littlebuttons" style="background-image: url(../../../assets/images/iconos/buscar.png);" (click)="buscar()"></button>
				</div>
				<div class="p-col-3">
				  <p-dropdown *ngIf="opcionSeleccionada === 'COM' || opcionSeleccionada === 'DVC' || opcionSeleccionada === 'NR' || opcionSeleccionada === 'GRE' || opcionSeleccionada === 'TRF' || opcionSeleccionada === 'ENPROD' || opcionSeleccionada === 'SAPROD' || opcionSeleccionada === 'ENSAINV'" [style]="{'height':'90%', 'width': '150px'}" [panelStyle]="{'width':'300px'}" [options]="options2" [(ngModel)]="opcionBus"></p-dropdown>
				  <p-dropdown *ngIf="opcionSeleccionada === 'FAC' || opcionSeleccionada === 'DVF'" [style]="{'height':'90%', 'width': '150px'}" [panelStyle]="{'width':'300px'}" [options]="options3" [(ngModel)]="opcionBus2"></p-dropdown>
				</div>
			  </div>
			</div>-->
		</div>
		<p-scrollPanel [style]="{'width':'100%', 'margin-top': '-20px'}">
			<app-aggridsaci *ngIf="opcionSeleccionada === 'COM'"
							[width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="calcService.resultadosCOM"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsCalcKardexCOM"
							[defaultColDef]="defaultColCalcKardexCOM"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
			></app-aggridsaci>
			<app-aggridsaci *ngIf="opcionSeleccionada === 'DVC'"
							[width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="calcService.resultadosDVC"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsCalcKardexDVC"
							[defaultColDef]="defaultColCalcKardexDVC"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
			></app-aggridsaci>
			<app-aggridsaci *ngIf="opcionSeleccionada === 'NR'"
							[width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="calcService.resultadosNR"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsCalcKardexNR"
							[defaultColDef]="defaultColCalcKardexNR"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
			></app-aggridsaci>
			<app-aggridsaci *ngIf="opcionSeleccionada === 'FAC'"
							[width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="calcService.resultadosFAC"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsCalcKardexFAC"
							[defaultColDef]="defaultColCalcKardexFAC"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
			></app-aggridsaci>
			<app-aggridsaci *ngIf="opcionSeleccionada === 'DVF'"
							[width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="calcService.resultadosDVF"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsCalcKardexDVF"
							[defaultColDef]="defaultColCalcKardexDVF"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
			></app-aggridsaci>
			<app-aggridsaci *ngIf="opcionSeleccionada === 'GRE'"
							[width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="calcService.resultadosGRE"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsCalcKardexGRE"
							[defaultColDef]="defaultColCalcKardexGRE"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
			></app-aggridsaci>
			<app-aggridsaci *ngIf="opcionSeleccionada === 'TRF'"
							[width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="calcService.resultadosTRF"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsCalcKardexTRF"
							[defaultColDef]="defaultColCalcKardexTRF"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
			></app-aggridsaci>
			<app-aggridsaci
					*ngIf="opcionSeleccionada === 'ENSAINV' || opcionSeleccionada === 'ENPROD' || opcionSeleccionada === 'SAPROD'"
					[width]=""
					[height]="largo"
					[enableSorting]="true"
					[enableFilter]="true"
					[rowData]="calcService.resultados"
					[frameworkComponents]="frameworkComponents"
					[animateRows]="true"
					[rowSelection]="rowSelection"
					[columnDefs]="columnDefsCalcKardexENSAINV"
					[defaultColDef]="defaultColCalcKardexENSAINV"
					[rowStyle]="rowStyle"
					[singleClickEdit]="false"
					[botones]="false"
			></app-aggridsaci>

			<app-aggridsaci
					*ngIf="opcionSeleccionada === 'ORDPRO'"
					[width]=""
					[height]="largo"
					[enableSorting]="true"
					[enableFilter]="true"
					[rowData]="calcService.resultadosPROD"
					[frameworkComponents]="frameworkComponents"
					[animateRows]="true"
					[rowSelection]="rowSelection"
					[columnDefs]="columnDefsCalcKardexPROD"
					[defaultColDef]="defaultColCalcKardexPROD"
					[rowStyle]="rowStyle"
					[singleClickEdit]="false"
					[botones]="false"
			></app-aggridsaci>
		</p-scrollPanel>
		<div class="p-grid">
			<div class="p-col-12">
				<p-progressBar [value]="porcentaje"></p-progressBar>
			</div>
		</div>
	</div>
</div>
