import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MessageService, ConfirmationService, SelectItem } from 'primeng';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { Usuario } from 'src/app/usuario';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { ProdrecalcularcostosindService } from '../prodservicios/prodrecalcularcostosind.service';

@Component({
  selector: 'app-prodrecalcularcostosind',
  templateUrl: './prodrecalcularcostosind.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class ProdrecalcularcostosindComponent implements OnInit {

  cmbMes: any[] = [];
  strCmbMes: any;
  cmbTipoProduccion: any[] = [];
  strCmbTipoProduccion: any;
  lblCenNombre = '';
  txtCenCodigo= '';
  txtAnio;
  busquedacampos: string[];
  tabla: string;
  where: string;

   // dialogs
  displayDialogBusqueda: boolean;
  displayDialogBusquedaFast: boolean;
  

  types: SelectItem[];
  arregloCons: any[];
  opcionbusqueda: string;


  constructor(private datePipe: DatePipe,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private auditoriagral: AuditoriagralService,
    public usuario: Usuario,
    private init: NuevoComponentService,
    private confirmationService: ConfirmationService,
    private busqService: InvbusquedaService,
    private recalcularService: ProdrecalcularcostosindService) { }

  ngOnInit(): void {

    this.cmbMes = [];
    this.cmbMes = [{mes: '01 Enero'},
      {mes: '02 Febrero'},
      {mes: '03 Marzo'},
      {mes: '04 Abril'},
      {mes: '05 Mayo'},
      {mes: '06 Junio'},
      {mes: '07 Julio'},
      {mes: '08 Agosto'},
      {mes: '09 Septiembre'},
      {mes: '10 Octubre'},
      {mes: '11 Noviembre'},
      {mes: '12 Diciembre'}];

      this.strCmbMes = this.cmbMes[0];

      const fechaAct = new Date();
      this.txtAnio = fechaAct.getFullYear();

      this.cmbTipoProduccion = [{tipo: ''}, {tipo: 'Proceso'} ,{tipo: 'Formulación'}]
      this.strCmbTipoProduccion = this.cmbTipoProduccion[0];
  }

  async manejarSenales(valor) {
    if (valor === 'Visto') {
      const p = await this.recalcularService.encontrarCenCostoProm(this.txtCenCodigo);
      if (p === null) {
        this.mensajeAlerta('info', 'Información', 'Ingrese un centro de costos correcto');
      } else if (p[0].CEN_CODIGO === null) {
        this.mensajeAlerta('info', 'Información', 'Ingrese un centro de costos correcto');
      } else if (p[0].CEN_CODIGO !== null){
        const parametros: any[] = [];
        parametros[0] = String(this.strCmbMes.mes).substr(0,2);
        parametros[1] = this.usuario.identificacion;
        parametros[2] = String(this.txtAnio);
        parametros[3] = String(this.txtCenCodigo);
        
        if (this.strCmbTipoProduccion.tipo === 'Proceso') {
          try {
            await this.recalcularService.distribuirCostosIf(parametros);

            this.mensajeAlerta('success', 'Proceso', 'Proceso termino satisfactoriamente');
          } catch(err) {
            this.mensajeAlerta('error', 'Error', err.error);

          }
        } else if (this.strCmbTipoProduccion.tipo === 'Formulación') {
          try {
            await this.recalcularService.recalcularCostosInd(parametros);
            this.mensajeAlerta('success', 'Proceso', 'Proceso termino satisfactoriamente');
          } catch(err) {
            this.mensajeAlerta('error', 'Error', err.error);
          }
        } else {
          this.mensajeAlerta('info', 'Información', 'Seleccione el tipo de producción');
        }

      }
    }

    if (valor === 'Reversar') {
      const p = await this.recalcularService.encontrarCenCostoProm(this.txtCenCodigo);
      if (p === null) {
        this.mensajeAlerta('info', 'Información', 'Ingrese un centro de costos correcto');
      } else if (p[0].CEN_CODIGO === null) {
        this.mensajeAlerta('info', 'Información', 'Ingrese un centro de costos correcto');
      } else if (p[0].CEN_CODIGO !== null) {
        const parametros: any[] = [];
        parametros[0] = String(this.strCmbMes.mes).substr(0,2);
        parametros[1] = this.usuario.identificacion;
        parametros[2] = String(this.txtAnio);
        parametros[3] = String(this.txtCenCodigo);

        try {
          await this.recalcularService.reversarDisCostos(parametros);
          this.mensajeAlerta('success', 'Revesar', 'Se ha reversado correctamente');
        } catch(err) {
          this.mensajeAlerta('error', 'Error', err.error);
        }
      }
    }

    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }

  buscarCentro(parametro) {
    this.opcionbusqueda = 'busquedacodigocentro';
    this.types = [
      { label: 'Codigo', value: 'CEN_CODIGO' },
      { label: 'Nombre', value: 'CEN_NOMBRE' }
    ];

    this.busquedacampos = ['', ''];
    this.tabla = 'CON_MAECEN';
    this.where = '';

    this.recalcularService.encontrarCenCosto(parametro).subscribe(eR => {
        if (eR !== null) {
          if (eR[0] !== undefined) {
            this.txtCenCodigo = parametro;
            this.lblCenNombre = eR[0].CEN_NOMBRE;
          } else {
            this.busqService.busquedaConMaeCen4().subscribe((datos: any[]) => {
              this.arregloCons = datos;
              // this.displayDialogBusqueda = true;
              this.displayDialogBusquedaFast = true;
            });
          }
        } else {
          this.busqService.busquedaConMaeCen4().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      });
  }

  manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'busquedacodigocentro') {
      this.txtCenCodigo = opcion.CEN_CODIGO;
      this.lblCenNombre =  opcion.CEN_NOMBRE;
    }
    this.displayDialogBusquedaFast = false;
  }

  mensajeAlerta(tipo, titulo, msg) {
    this.messageService.add({
      key: 'prodrecalcostind',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

}
