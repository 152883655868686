import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SelectItem, MessageService } from 'primeng';
import { VenencptovtaService } from '../venservicios/venencptovta.service';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { DatePipe } from '@angular/common';//CDPJ
import { VenmaeclienteService } from '../venservicios/venmaecliente.service';//CDPJ

@Component({
  selector: 'app-dlgnuevocliente',
  templateUrl: './dlgnuevocliente.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class DlgnuevoclienteComponent implements OnInit {

  @Input() strCodigoVendedor: any;
  @Input() txtRuc: any;
  @Output() salir = new EventEmitter<any>();

  //txtRuc = '';
  chkbtnPlaca: boolean;
  txtNombre = '';
  txtDireccion = '';
  txtTelefono = '';
  txtEmail1 = '';
  lblvalida = '';
  CON_CODIGO1 = '';
  cli_fechaing: any;
  // dropdowns
  cmbTipoIde: any[];
  selectTipoIde: any;
  valoride: any;//CDPJ
  aut:boolean;//CDPJ
  boolguardar:boolean;
  boolbuscar:boolean;
  // busqueda
  opcionbusqueda: string;
  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];
  tabla: string;
  where: string;

  // dialogs
  displayDialogBusquedaFast: boolean;
  spin = false;
  exito: boolean;//CDPJ
  existe: boolean;//CDPJ
  botonRegresar = false;//CDPJ
  // autorizacion
  claveAuth: string;
  autorizacionCodigo: string;
  bolAutorizacion: boolean;
  displayDialogAutorizacion: boolean;

  constructor(public encptovtaService: VenencptovtaService, private busqService: InvbusquedaService,
    private utilitariosService: UtilitariosService, private messageService: MessageService,
    private auditoriagral: AuditoriagralService, private datePipe: DatePipe, public cliService: VenmaeclienteService) { }

  ngOnInit(): void {
    this.cmbTipoIde = [{ codigo: 'R', name: 'R' },
    { codigo: 'C', name: 'C' },
    { codigo: 'P', name: 'P' },
    { codigo: 'PP', name: 'PP' }];
    this.selectTipoIde = this.cmbTipoIde[0];
    console.log('ruc', this.txtRuc)
    if (this.txtRuc !== '' && this.txtRuc !== null && this.txtRuc !== undefined) {
      console.log('ruc', this.txtRuc)
      this.buscarRegistro();

    } else {
      this.limpiardatos();
    }

    this.exito = false;
    this.aut = false;
  }

  limpiardatos() {
    console.log('aqui');
    this.txtRuc = '';
    this.chkbtnPlaca = false;
    this.txtNombre = '';
    this.txtDireccion = '';
    this.txtTelefono = '';
    this.txtEmail1 = '';
    this.lblvalida = '';
    this.CON_CODIGO1 = '';
    //this.cli_fechaing = new Date();
  }

  async manejarSenales(valor) {
    if (valor === 'Guardar') {
      this.boolguardar=true;
      this.boolbuscar=false;
      await this.validaEnviaRespuesta(this.selectTipoIde.name, this.txtRuc);
      if (Number(this.valoride) === 1 || Number(this.valoride) === 3 || Number(this.valoride) === 4
      || Number(this.valoride) === 6) {
        if (this.aut === true){
          this.guardar();
        }
          
      
      } else {
        this.messageService.add({
          key: 'cliente',
          severity: 'warn',
          summary: 'Información',
          detail: 'EL Ruc/Céd que ingresó está incorrecto, verifiquelo, por favor'
        });
      }
    }

    if (valor === 'Buscar') {
      //this.buscarRegistro();
      this.busquedaclienteDlg(this.txtRuc);
    }

    if (valor === 'Salir') {
      //const valor = await this.validaConsultaRespuesta(this.txtRuc)
      if (this.txtDireccion === '' && this.txtEmail1 === '' && this.txtNombre === '' && this.txtTelefono === ''
        && this.txtRuc === '') {
        this.salir.emit({ code: this.txtRuc, name: this.txtNombre });
      } else {
        await this.validaEnviaRespuesta(this.selectTipoIde.name, this.txtRuc);
        if (Number(this.valoride) === 1 || Number(this.valoride) === 3 || Number(this.valoride) === 4
        || Number(this.valoride) === 6) {
          //this.buscarRegistro();
          this.busquedacliente(this.txtRuc);
          if (this.exito === false) {
          } else {
            this.salir.emit({ code: this.txtRuc, name: this.txtNombre });
          }
        } else {
          this.messageService.add({
            key: 'cliente',
            severity: 'warn',
            summary: 'Información',
            detail: 'EL Ruc/Céd que ingresó está incorrecto, verifiquelo, por favor'
          });
        }
      }


    }
    if (valor === 'Cancelar') {
      this.limpiardatos();
    }
  }

  async setFocus(event) {
    let element: any;
    switch (event.target.id) {
      case 'txtRuc': {
      this.boolguardar=false;
      this.boolbuscar=true;
        //console.log(Number (this.validaConsultaRespuesta(this.txtRuc)));
        await this.validaEnviaRespuesta(this.selectTipoIde.name, this.txtRuc);
        if (Number(this.valoride) === 1 || Number(this.valoride) === 3 || Number(this.valoride) === 4
        || Number(this.valoride) === 6) {
          if(this.aut === true){
            this.busquedacliente(this.txtRuc);
            if (this.exito === false) {
              element = document.getElementById('txtNombre');
            } else {
              element = document.getElementById('txtNombre');
            }

          }
        
        } else {
          this.messageService.add({
            key: 'cliente',
            severity: 'warn',
            summary: 'Información',
            detail: 'EL Ruc/Céd que ingresó está incorrecto, verifiquelo, por favor'
          });
        }
        break;
      }
      case 'txtNombre': {
        element = document.getElementById('txtDireccion');
        break;
      }
      case 'txtDireccion': {
        element = document.getElementById('txtTelefono');
        break;
      }
      case 'txtTelefono': {
        element = document.getElementById('txtEmail1');
        break;
      }
      case 'txtEmail1': {
        element = document.getElementById('txtRuc');
        break;
      }

      default: {
        break;
      }
    }

    if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }
  }

  change(event) {

  }

  async busquedacliente(parametro) {
    if (parametro === null) {
      parametro = '';
    }
    console.log('1');
    this.opcionbusqueda = 'cliente';
    this.types = [
      { label: 'Código', value: 'CLI_CODIGO' },
      { label: 'Nombre', value: 'CLI_NOMBRE' },
      { label: 'RucIde', value: 'CLI_RUCIDE' },
      { label: 'Zona', value: 'CLI_ZONA' },
      { label: 'Grupo', value: 'GRU_CODIGO' },
      { label: 'Contacto', value: 'CLI_CONTACTO' },
      { label: 'Placa', value: 'CLI_PLACA' },
      { label: 'NombreC', value: 'CLI_NOMBREC' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_MAECLIENTE';
    this.where = '';

    if (parametro !== '' && parametro !== undefined && parametro !== null) {
      console.log('2');
      const eR = await this.encptovtaService.erpBusquedaCliente(this.tabla, this.types[0].value, parametro);
      if (eR !== null) {
        console.log('3');
        if (eR[0] !== undefined) {
          console.log('4');
          this.manejarSeleccion(eR[0]);
          this.exito = true

          return;
        } else {
          console.log('5');
          this.spin = true;
          this.busqService.busquedaVenMaeCliente3(parametro).subscribe((datos: any[]) => {
            console.log('6');
            this.arregloCons = datos;
            this.spin = false;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.exito = false;
        this.messageService.add({
          key: 'cliente',
          severity: 'warn',
          summary: 'Información',
          detail: 'EL cliente no existe, registrelo por favor'
        });
        this.txtNombre = '';
        this.txtDireccion = '';
        this.txtTelefono = '';
        this.txtEmail1 = '';
        this.lblvalida = '';
        this.CON_CODIGO1 = '';
      }
    }
  }
  //CDPJ
  async busquedaclienteDlg(parametro) {
    if (parametro === null) {
      parametro = '';
    }
    console.log('1');
    this.opcionbusqueda = 'cliente';
    this.types = [
      { label: 'Código', value: 'CLI_CODIGO' },
      { label: 'Nombre', value: 'CLI_NOMBRE' },
      { label: 'RucIde', value: 'CLI_RUCIDE' },
      { label: 'Zona', value: 'CLI_ZONA' },
      { label: 'Grupo', value: 'GRU_CODIGO' },
      { label: 'Contacto', value: 'CLI_CONTACTO' },
      { label: 'Placa', value: 'CLI_PLACA' },
      { label: 'NombreC', value: 'CLI_NOMBREC' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_MAECLIENTE';
    this.where = '';

    if (parametro !== '' && parametro !== undefined && parametro !== null) {
      console.log('2');
      const eR = await this.encptovtaService.erpBusquedaCliente(this.tabla, this.types[0].value, parametro);
      if (eR !== null) {
        console.log('3');
        if (eR[0] !== undefined) {
          console.log('4');
          this.manejarSeleccion(eR[0]);
          this.exito = true

          return;
        }
      }
    }
    //console.log('5');
    this.spin = true;
    this.busqService.busquedaVenMaeCliente3(parametro).subscribe((datos: any[]) => {
      //console.log('6');
      this.arregloCons = datos;
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }

  //CDPJ
  async busquedaclienteplaca(parametro) {
    if (parametro === null) {
      parametro = '';
    }

    this.opcionbusqueda = 'cliente';
    this.types = [
      { label: 'Código', value: 'CLI_CODIGO' },
      { label: 'Nombre', value: 'CLI_NOMBRE' },
      { label: 'RucIde', value: 'CLI_RUCIDE' },
      { label: 'Placa', value: 'CLI_PLACA' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_MAECLIENTE';
    this.where = '';

    if (parametro !== '' && parametro !== undefined && parametro !== null) {

      const eR = await this.encptovtaService.erpBusquedaCliente(this.tabla, this.types[0].value, parametro);
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
          return;
        }
      }
    }

    this.spin = true;
    this.busqService.busquedaVenMaeCliente3(parametro).subscribe((datos: any[]) => {

      this.arregloCons = datos;
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });

  }

  manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'cliente') {
      if (opcion.CLI_CODIGO != null) {
        this.txtNombre = opcion.CLI_NOMBRE;
        this.txtRuc = opcion.CLI_CODIGO;
        this.txtDireccion = opcion.CLI_DIRECCION1 === null || opcion.CLI_DIRECCION1 === undefined?'':opcion.CLI_DIRECCION1;
        this.txtEmail1 = opcion.CLI_CORREO === null || opcion.CLI_CORREO === undefined?'':opcion.CLI_CORREO;
        this.txtTelefono = opcion.CLI_TELEFONO1 === null || opcion.CLI_TELEFONO1 === undefined?'':opcion.CLI_TELEFONO1;
        console.log(opcion.CLI_TIPOIDE);
        // Tipo de id

        if (opcion.CLI_TIPOIDE === '1') {
          this.selectTipoIde = { codigo: 'R', name: 'R' };

        }
        if (opcion.CLI_TIPOIDE === '2') {
          this.selectTipoIde = { codigo: 'C', name: 'C' };
        }
        if (opcion.CLI_TIPOIDE === '3') {
          this.selectTipoIde = { codigo: 'P', name: 'P' };
        }
        if (opcion.CLI_TIPOIDE === '4') {
          this.selectTipoIde = { codigo: 'PP', name: 'PP' };
        }
        this.lblvalida = opcion.CLI_VALIDACION;
        //this.salir.emit({ code: this.txtRuc, name: this.txtNombre });
        this.exito = true;
      }
    }
    this.displayDialogBusquedaFast = false;
  }

  buscarRegistro() {
    const BUSSIMCLI = this.encptovtaService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'BUSSIMCLI').CFG_VALOR3;

    if (BUSSIMCLI === 1) {
      this.busquedaclienteplaca(this.txtRuc);
    } else {
      this.busquedacliente(this.txtRuc);
    }

  }

  async validar(rucide) {
    await this.validaEnviaRespuesta(this.selectTipoIde.name, rucide);
  }

  async validaEnviaRespuesta(rucIdeTipo, rucIde) {
    if (rucIde !== null && rucIde !== undefined) {
      if (rucIdeTipo === 'R') {
        const vria = await this.validaConsultaRespuesta(rucIde);
        console.log('vria',vria);
        console.log('aut',this.aut);
        this.valoride = vria;
        if (vria === 1) {
          await this.validaColocarLabels(1);
          this.aut=true;
        } else if (vria === 3 ) {
          this.messageService.add({
            key: 'autorizacion',
            severity: 'warn',
            summary: 'Información',
            detail: 'RUC no se encuentra en nuestra base de datos, verifique' +
              ' en el portal del SRI.'
          });
          this.claveAuth = '';
          this.autorizacionCodigo = 'AUTRUCID';
          this.displayDialogAutorizacion = true;
          this.aut=false;
        } else if (vria === 4) {
          this.aut=true;
          await this.obtenerDatosCliente(rucIde);
        } else {
          
            await this.validaColocarLabels(2);
            this.aut=true;
          
          
          //this.txtRuc = '';
        }

      }
      if (rucIdeTipo === 'C') {
        const vria = await this.validaConsultaRespuesta(rucIde);
        this.valoride = vria;
        if (vria == 1) {
          await this.validaColocarLabels(1);
          this.aut=true;
        } else if (vria == 3 && this.aut === false) {
          this.messageService.add({
            key: 'autorizacion',
            severity: 'warn',
            summary: 'Información',
            detail: 'El Ruc/Céd ingresado está incorrecto'+
            'Ingrese la clave de autorización por favor'
          });
          this.claveAuth = '';
          this.autorizacionCodigo = 'AUTRUCID';
          this.displayDialogAutorizacion = true;
          this.aut=false;
        } else {
          this.aut=true;
          await this.validaColocarLabels(2);
          //this.txtRuc = '';
        }
      }
      if (rucIdeTipo === 'P') {
        this.aut=true;
        await this.validaColocarLabels(4);
        this.valoride = 4;
      }
      if (rucIdeTipo === 'PP') {
        await this.validaColocarLabels(4);
        this.valoride = 4;
        this.aut=true;
      }
    } else {
      this.messageService.add({
        key: 'cliente',
        severity: 'error',
        summary: 'Información',
        detail: 'El campo Ruc/Céd está vació'
      });
    }

  }

  async validaColocarLabels(tipo) {
    if (tipo === 1) {
      this.lblvalida = "Bien";
    }
    if (tipo === 2) {
      this.lblvalida = "Mal";
    }

    if (tipo === 3) {
      this.lblvalida = "Autoriza";
    }

    // PARA PASAPORTES
    if (tipo === 4) {
      this.lblvalida = "Bien";
    }

    if (tipo === 6) {
      this.lblvalida = "SRI";
    }

    /*
    let codcliente = '';
    if (this.bolcliente === false) {
      codcliente = this.maecliente.CLI_RUCIDE;
    } else {
      codcliente = this.maecliente.CLI_CODIGO;
    }

    const formsricom = await this.cliService.erExisteCliente(this.maecliente.CLI_RUCIDE, codcliente).toPromise();
    if (formsricom[0] !== undefined) {
      if (Number(formsricom[0].COUNT) > 0) {
        this.messageService.add({
          key: 'cliente',
          severity: 'warn',
          summary: 'Información',
          detail: 'Cliente ya existe'
        });
        this.lblvalida = "Mal";
      } else {
        if (this.bolcliente === false) {
          this.maecliente.CLI_CODIGO = this.maecliente.CLI_RUCIDE;
        }
      }
    }
*/

  }

  async validaConsultaRespuesta(rucIde) {

    let valRucIDe = 0;

    if (this.selectTipoIde.name === 'R') {
      const resp = await this.utilitariosService.ValidaRucAutorizacion(rucIde);

      if (resp == 1) {
        valRucIDe = 1;
      } else {
        if (resp == 4) {
          valRucIDe = 4;
        } else if (resp == 3) {
          valRucIDe = 3;
        } else {
          valRucIDe = 0;
        }
      }
    }
    if (this.selectTipoIde.name === 'C') {
      if (rucIde !== '9999999999') {
        const resp = await this.utilitariosService.ValidaCedulaAutorizacion(rucIde, 1);
        if (resp == 1) {
          valRucIDe = 1;
        } else {
          if (resp == 3) {
            valRucIDe = 3;
          } else {
            valRucIDe = 0;
          }
        }
      } else {
        valRucIDe = 0;
      }
    }

    if (this.selectTipoIde.name === 'P') {
      valRucIDe = 1;
    }
    if (this.selectTipoIde.name === 'PP') {
      valRucIDe = 1;
    }

    return valRucIDe;

  }


  comprobarClave() {
    this.utilitariosService.obtenerClave(this.autorizacionCodigo).subscribe((clave) => {
      let claveReal;
      claveReal = clave[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        this.accionesAutorizacion();
      } else if (clave !== this.claveAuth) {
        this.messageService.add({
          key: 'autorizacion',
          severity: 'error',
          summary: 'Clave Autorización',
          detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
        });
        this.aut=false;
      }
    });
  }

  accionesAutorizacion() {
    if (this.autorizacionCodigo === 'AUTRUCID') {
      this.validaColocarLabels(3);
      //this.aut=true;
    }
     if(this.boolguardar === true){
       this.guardar();
    }else if(this.boolbuscar === true){
      this.busquedacliente(this.txtRuc);
    }
   
    this.displayDialogAutorizacion = false;
  }

  async obtenerDatosCliente(rucIde) {
    let r = null;

    try {
      r = await this.utilitariosService.getrucsri(rucIde);
    } catch  {
    }
    // console.log(r);
    if (r != null) {
      this.txtNombre = r.RAZON_SOCIAL;
      this.txtDireccion = r.DESCRIPCION_CANTON === "null" ? "" : r.DESCRIPCION_CANTON + " - "
        + r.DESCRIPCION_PARROQUIA === "null" ? "" : r.DESCRIPCION_PARROQUIA;
      this.auditoriagral.registrarAuditoria('VEN_MAECLIENTE', 'SRI/' + rucIde, 'I',
        '', '01', '', '', '', '').subscribe();
      this.lblvalida = "SRI";
    }
  }

  onHideAutorizacion() {
    if (this.bolAutorizacion === false) {
      if (this.autorizacionCodigo === 'AUTRUCID') {
        this.messageService.add({
          key: 'cliente',
          severity: 'error',
          summary: 'Información',
          detail: 'Debe ingresar correctamente'
            + ' la clave para ingresar el Ruc/Ide'
        });
        this.txtRuc = '';
        document.getElementById('txtRuc').focus();
      }
    }
  }

  async guardar() {

    if (this.lblvalida === "Mal"
      || this.lblvalida === "") {
      this.exito = false;
      this.messageService.add({
        key: 'cliente',
        severity: 'error',
        summary: 'Información',
        detail: 'Número de identificación incorrecta. Por favor verifique'
      });
      return;
    } else if (this.txtRuc === '') {
      this.exito = false;
      this.messageService.add({
        key: 'cliente',
        severity: 'error',
        summary: 'Información',
        detail: 'Debe ingresar el Ruc/Céd del cliente'
      });
      return;
    } else if (this.txtNombre === '') {
      this.exito = false;
      this.messageService.add({
        key: 'cliente',
        severity: 'error',
        summary: 'Información',
        detail: 'Debe ingresar el nombre del cliente'
      });
      return;
    } else if (this.txtDireccion === '') {
      this.exito = false;
      this.messageService.add({
        key: 'cliente',
        severity: 'error',
        summary: 'Información',
        detail: 'Debe ingresar la dirección del cliente'
      });
      return;
    } else if (this.txtTelefono === '') {
      this.exito = false;
      this.messageService.add({
        key: 'cliente',
        severity: 'error',
        summary: 'Información',
        detail: 'Debe ingresar el teléfono del cliente'
      });
      return;
    }else if (this.txtEmail1 === '') {
      this.txtEmail1 = '';
    } else {

    }
    const formsricom = await this.cliService.erExisteCliente(this.txtRuc, this.txtRuc).toPromise();
    if (formsricom[0] !== undefined) {
      if (Number(formsricom[0].COUNT) > 0) {
        // this.messageService.add({
        //   key: 'cliente',
        //   severity: 'warn',
        //   summary: 'Información',
        //   detail: 'Cliente ya existe'
        // });
        // this.lblvalida = "Mal";
        //this.cliService.erExisteCliente(this.txtRuc, this.txtRuc)
        this.existe = true;
        this.actualizar();
      } else {
        this.existe = false;
        this.guardarCliente();
      }
    }

  }

  async guardarCliente() {
    let strNombreCorto = "";
    let strCuentaContable = "";
    let strGruCodigo = "";
    let strTipoIde = "";
    let strctan = "";
    this.cli_fechaing = new Date();
    // Tipo de id
    if (this.selectTipoIde.name === "R") {
      strTipoIde = "1";
    }
    if (this.selectTipoIde.name === "C") {
      strTipoIde = "2";
    }
    if (this.selectTipoIde.name === "P") {
      strTipoIde = "3";
    }
    if (this.selectTipoIde.name === "PP") {
      strTipoIde = "4";
    }
    if (this.txtNombre.length > 5)
      strNombreCorto = this.txtNombre.substring(0, 5).trim();
    else
      strNombreCorto = this.txtNombre.substring(0, this.txtNombre.length);

    const data: any = await this.encptovtaService.encontrarreferencia('CTACLI');
    console.log('sata', data);
    if (data !== null && data !== undefined && data !== '') {
      //console.log('ref',data.REF_CODIGO)
      for (const rs of data) {
        if (rs.REF_CODIGO !== null && rs.REF_CODIGO !== undefined && rs.REF_CODIGO !== '') {
          this.exito = true;
          strCuentaContable = rs.REF_CODIGO;
        } else {
          this.exito = false;
          this.messageService.add({
            key: 'cliente',
            severity: 'error',
            summary: 'Información',
            detail: 'Ingrese una cuenta contable por defecto para clientes en REFERENCIAS'
          });
          return;
        }
      }
    } else {
      this.exito = false;
      this.messageService.add({
        key: 'cliente',
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese una cuenta contable por defecto para clientes en REFERENCIAS'
      });
      return;
    }


    const datagr: any = await this.encptovtaService.encontrarreferencia('GRPCLI');

    if (datagr !== null && datagr !== undefined && datagr !== '') {
      for (const rs of datagr) {
        if (rs.REF_CODIGO !== null && rs.REF_CODIGO !== undefined && rs.REF_CODIGO !== '') {
          this.exito = true;
          strGruCodigo = rs.REF_CODIGO;
        } else {
          this.exito = false;
          this.messageService.add({
            key: 'cliente',
            severity: 'error',
            summary: 'Información',
            detail: 'Ingrese un código de grupo por defecto para clientes en REFERENCIAS'
          });
          return;
        }
      }
    } else {
      this.exito = false;
      this.messageService.add({
        key: 'cliente',
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese un código de grupo por defecto para clientes en REFERENCIAS'
      });
      return;
    }
    //CDPJ
    const dataan: any = await this.encptovtaService.encontrarreferencia('CANCLI');

    if (dataan !== null && dataan !== undefined && dataan !== '') {
      for (const rs of dataan) {
        if (rs.REF_CODIGO !== null && rs.REF_CODIGO !== undefined && rs.REF_CODIGO !== '') {
          this.exito = true;
          strctan = rs.REF_CODIGO;
        } else {
          this.exito = false;
          this.messageService.add({
            key: 'cliente',
            severity: 'error',
            summary: 'Información',
            detail: 'Ingrese cuenta contable de anticipo por defecto para clientes en REFERENCIAS'
          });
          return;
        }
      }
    } else {
      this.exito = false;
      this.messageService.add({
        key: 'cliente',
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese cuenta contable de anticipo por defecto para clientes en REFERENCIAS'
      });
      return;
    }
    //CDPJ
    this.cli_fechaing = this.datePipe.transform(this.cli_fechaing, 'dd/MM/yyyy')
    try {
      const cli = await this.encptovtaService.insertarcliente(this.txtRuc, strGruCodigo, this.strCodigoVendedor, this.txtNombre, strNombreCorto, strTipoIde,
        this.txtDireccion, this.txtTelefono, this.txtEmail1, strCuentaContable, this.cli_fechaing, strctan);
      console.log('cli', cli);
      this.messageService.add({
        key: 'cliente',
        severity: 'success',
        summary: 'Información',
        detail: 'La información del cliente se ingresó correctamente'
      });
      this.auditoriagral.registrarAuditoria('VEN_MAECLIENTE', this.txtRuc, 'I',
        '', '01', '', '', '', '').subscribe();
      //this.salir.emit({ code: this.txtRuc, name: this.txtNombre });

      await this.encptovtaService.actualizarValidacion(this.lblvalida, this.txtRuc);
      this.salir.emit({ code: this.txtRuc, name: this.txtNombre });
    } catch (e) {
      this.messageService.add({
        key: 'cliente',
        severity: 'error',
        summary: 'Información',
        detail: 'No se pudo ingresar la infomación del cliente'
      });
    }
  }
  async actualizar() {
    let strNombreCorto = "";
    let strCuentaContable = "";
    let strGruCodigo = "";
    let strTipoIde = "";
    let strctan = "";
    this.cli_fechaing = new Date();
    // Tipo de id
    if (this.selectTipoIde.name === "R") {
      strTipoIde = "1";
    }
    if (this.selectTipoIde.name === "C") {
      strTipoIde = "2";
    }
    if (this.selectTipoIde.name === "P") {
      strTipoIde = "3";
    }
    if (this.selectTipoIde.name === "PP") {
      strTipoIde = "4";
    }
    if (this.txtNombre.length > 5)
      strNombreCorto = this.txtNombre.substring(0, 5).trim();
    else
      strNombreCorto = this.txtNombre.substring(0, this.txtNombre.length);

    const data: any = await this.encptovtaService.encontrarreferencia('CTACLI');
    console.log('sata', data);
    if (data !== null && data !== undefined && data !== '') {
      //console.log('ref',data.REF_CODIGO)
      for (const rs of data) {
        if (rs.REF_CODIGO !== null && rs.REF_CODIGO !== undefined && rs.REF_CODIGO !== '') {
          this.exito = true;
          strCuentaContable = rs.REF_CODIGO;
        } else {
          this.exito = false;
          this.messageService.add({
            key: 'cliente',
            severity: 'error',
            summary: 'Información',
            detail: 'Ingrese una cuenta contable por defecto para clientes en REFERENCIAS'
          });
          return;
        }
      }
    } else {
      this.exito = false;
      this.messageService.add({
        key: 'cliente',
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese una cuenta contable por defecto para clientes en REFERENCIAS'
      });
      return;
    }


    const datagr: any = await this.encptovtaService.encontrarreferencia('GRPCLI');

    if (datagr !== null && datagr !== undefined && datagr !== '') {
      for (const rs of datagr) {
        if (rs.REF_CODIGO !== null && rs.REF_CODIGO !== undefined && rs.REF_CODIGO !== '') {
          this.exito = true;
          strGruCodigo = rs.REF_CODIGO;
        } else {
          this.exito = false;
          this.messageService.add({
            key: 'cliente',
            severity: 'error',
            summary: 'Información',
            detail: 'Ingrese un código de grupo por defecto para clientes en REFERENCIAS'
          });
          return;
        }
      }
    } else {
      this.exito = false;
      this.messageService.add({
        key: 'cliente',
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese un código de grupo por defecto para clientes en REFERENCIAS'
      });
      return;
    }
    //CDPJ
    const dataan: any = await this.encptovtaService.encontrarreferencia('CANCLI');

    if (dataan !== null && dataan !== undefined && dataan !== '') {
      for (const rs of dataan) {
        if (rs.REF_CODIGO !== null && rs.REF_CODIGO !== undefined && rs.REF_CODIGO !== '') {
          this.exito = true;
          strctan = rs.REF_CODIGO;
        } else {
          this.exito = false;
          this.messageService.add({
            key: 'cliente',
            severity: 'error',
            summary: 'Información',
            detail: 'Ingrese cuenta contable de anticipo por defecto para clientes en REFERENCIAS'
          });
          return;
        }
      }
    } else {
      this.exito = false;
      this.messageService.add({
        key: 'cliente',
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese cuenta contable de anticipo por defecto para clientes en REFERENCIAS'
      });
      return;
    }
    //CDPJ
    this.cli_fechaing = this.datePipe.transform(this.cli_fechaing, 'dd/MM/yyyy')
    try {
      const cli = await this.encptovtaService.actualizarcliente(this.txtRuc, strGruCodigo, this.strCodigoVendedor, this.txtNombre, strNombreCorto, strTipoIde,
        this.txtDireccion, this.txtTelefono, this.txtEmail1, strCuentaContable, strctan);
      console.log('cli', cli);
      this.messageService.add({
        key: 'cliente',
        severity: 'success',
        summary: 'Información',
        detail: 'La información del cliente se actualizó correctamente'
      });
      this.auditoriagral.registrarAuditoria('VEN_MAECLIENTE', this.txtRuc, 'A',
        '', '01', '', '', '', '').subscribe();
      this.busquedacliente(this.txtRuc);
      //this.salir.emit({ code: this.txtRuc, name: this.txtNombre, });
      await this.encptovtaService.actualizarValidacion(this.lblvalida, this.txtRuc);
      this.salir.emit({ code: this.txtRuc, name: this.txtNombre });
    } catch (e) {
      this.messageService.add({
        key: 'cliente',
        severity: 'error',
        summary: 'Información',
        detail: 'No se pudo actualizar la infomación del cliente'
      });
    };


  }



  async confirmarcliente() {
    const formsricom = await this.cliService.erExisteCliente(this.txtRuc, this.txtRuc).toPromise();
    if (formsricom[0] !== undefined) {
      if (Number(formsricom[0].COUNT) < 0) {
        this.messageService.add({
          key: 'cliente',
          severity: 'warn',
          summary: 'Información',
          detail: 'Cliente no existe'
        });
        this.lblvalida = "Mal";
      } else {
        this.busquedacliente(this.txtRuc);
      }
    }
  }

}
