<div class="p-grid">
  <div class="p-col-12">
    <p-table [(selection)]="selectedItems"
             [value]="arregloConsulta" [scrollable]="true" scrollHeight="300px" selectionMode="multiple">
      <ng-template pTemplate="caption">
        <div class="p-grid">
          <div class="p-col-4 text-left">
            <button class="littlebuttons" style="background-image: url(../../../assets/images/iconos/visto.png)" (click)="enviarDatos(elementosSeleccionados)"></button>
          </div>
          <div class="p-col-4 text-left">
          </div>
          <div class="p-col-4">
           <button  class="littlebuttons" style="background-image: url(../../../assets/images/iconos/salir.png); margin-right: -300px" (click)="salir()"></button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let head of arrayOptions" id="{{head.label}} ">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header">
        <tr class="ui-table-scrollable-wrapper">
          <th style="width: 3em">
            <p-tableHeaderCheckbox (click)="escogerTodos()"></p-tableHeaderCheckbox>
          </th>
          <th *ngFor="let head of arrayOptions">{{head.label}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body"
                   let-rowData>
        <tr id="tr">
          <td style="width: 3em">
            <p-tableCheckbox [value]="rowData" (click)="llenarArreglo(rowData)" id="cambio"  ></p-tableCheckbox>
          </td>
          <td *ngFor="let data of arrayOptions">{{rowData[data.value]}}</td>
        </tr>
      </ng-template>
    </p-table>

  </div>
</div>
