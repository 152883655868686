<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="segMaeRepPtoast"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="segRepPDialog" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
						 [botonBorrar]="btnBorrar"
						 (signal)="manejarSenales($event)" [barraBotonesDesplaz] = "false"
						 [botonBuscar]="btnBuscar"  [botonSiguiente]="botonSiguiente"  [botonAnterior]="botonAnterior"
						 [botonPrimero]="botonPrimero" [botonUltimo]="botonUltimo"
						 [barraBotones3]="true" [botonBinocular]="true"
						 [barraBotones5]="false" >
		</app-invmaebarra>
	</div>
</div>
<div class="divgrups">
	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-2">
					<span class="obligatorio">Código:</span>
				</div>
				<div class="p-col-2">
					<input  type="text" pInputText [(ngModel)]="selectedSegMaeRepPantalla.CNFGIMPR_CODIGO"
							disabled autocomplete="off">
				</div>
			</div>

		</div>
		<div class="p-col-12" style="margin-top: -15px">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-2">
					<span class="obligatorio">Forma:</span>
				</div>
				<div class="p-col-5">
					<input (ngModelChange)="cambioInput($event)" [disabled]="!editable" class="frm" type="text" pInputText [(ngModel)]="selectedSegMaeRepPantalla.CNFGIMPR_FORMA"
					>
				</div>
			</div>
		</div>
		<div class="p-col-12" style="margin-top: -15px">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-2">
					<span class="obligatorio">Reporte:</span>
				</div>
				<div class="p-col-5">
					<input [disabled]="!editable" class="frm" type="text" pInputText [(ngModel)]="selectedSegMaeRepPantalla.CNFGIMPR_REPORTE"
					>
				</div>
				<div class="p-col-1">
					<p-fileUpload id="com_firma" name="myfile[]" accept=".jasper"
								  mode="basic" customUpload="true" chooseLabel="Seleccionar" auto="true"
								  (uploadHandler)="onBasicUpload($event)" [disabled]="!editable">
					</p-fileUpload>
				</div>
			</div>
		</div>
		<div class="p-col-12" style="margin-top: -15px">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-2">
					<span class="obligatorio">Descripción:</span>
				</div>
				<div class="p-col-5">
					<input [disabled]="!editable" class="frm" type="text" pInputText [(ngModel)]="selectedSegMaeRepPantalla.CNFGIMPR_NOMBRE"
					>
				</div>
			</div>
		</div>
		<div class="p-col-12" style="margin-top: -15px">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-2">
					<span class="obligatorio">Procedimiento: </span>
				</div>
				<div class="p-col-5">
					<input [disabled]="!editable" class="frm" style="text-transform: uppercase" type="text" pInputText [(ngModel)]="selectedSegMaeRepPantalla.CNFGIMPR_SP"
					>
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-2">
					<span class="obligatorio">Tabla:</span>
				</div>
				<div class="p-col-2">
					<input [disabled]="!editable"  type="text" pInputText [(ngModel)]="selectedSegMaeRepPantalla.CNFGIMPR_TABLA"
						   style="text-transform: uppercase"	 autocomplete="off">
				</div>
				<div class="p-col-1">
					<span class="obligatorio">Localización:</span>
				</div>
				<div class="p-col-4">
					<div class="p-grid">
						<div class="p-col-8">
							<label for="PRO_RUCIDE"></label>
							<input [disabled]="!editable" style="text-transform: uppercase" id="PRO_RUCIDE" type="text" pInputText [(ngModel)]="selectedSegMaeRepPantalla.CNFGIMPR_LOCALIZACION"
								   autocomplete="off">
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</div>
<div class="p-col-12">
	<p-tabView>
		<p-tabPanel header="Condición del reporte">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="prmtCnfgImprs"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefPrmtCnfgImpr"
							[defaultColDef]="defaultColDefPrmtCnfgImpr"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="true"
							(selected)=selectPrmtCnfgImpr($event)
							(cambios)="cambioPrmtCnfgImpr($event)"
							[mostrarGuardar]="true"
			></app-aggridsaci>
		</p-tabPanel>
	</p-tabView>
</div>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
					 [consulta]="consulta" [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>
