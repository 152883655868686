<p-dialog header="Clave Autorización" [(visible)]="displayDialogAutorizacion"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  (onHide)="onHideAutorizacion()"
		  [style]="{width: '40vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="autorizacion"></p-toast>
	<div class="p-grid" style="width: 98%">
		<div class="p-col-12">
			<app-invmaebarra [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"
							 [barraBotones5]="true" [botonVisto]="false" [barraBotonesCRUD]="true"
							 (signal)="manejarSenales($event)"
							 style="text-align: left;">
			</app-invmaebarra>
		</div>
	</div>
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							   (keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>

<p-confirmDialog [transitionOptions]="'0ms'" key="segNumDialog" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<p-confirmDialog [transitionOptions]="'0ms'" key="segNumCamDialog" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"
						 [barraBotones5]="true" [botonVisto]="true" [barraBotonesCRUD]="true"
					    (signal)="manejarSenales($event)"
						style="text-align: left;">
		</app-invmaebarra>
</div>
</div>
<p-tabView *ngIf="displayConteCon==true">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="contadoresCon"></p-toast>
	<p-tabPanel header="Inicializar Contadores Contables">
		<div class="divgrups"  style="height: 150px; margin-bottom: 0px;" >              
			<div class="p-grid">
		  
				<div class="p-col-12" style="margin-top: 9px"  >
					<div class="p-grid" >
						<div class="p-col-4">	
						</div>
						<div class="p-col-1" style="margin-top: -7px">
							<span  style="font-size: 13px; font-weight: bold">Referencia:</span>
						</div>
						<div class="p-col-3" style="margin-top: -7px">
							<input id="nombreAsientos" type="text" style="font-size: 20px; height: 25px;" pInputText [(ngModel)]="txtContad"  maxlength="45" >
						</div>
						<div class="p-col-4">	
						</div>
				</div>
					
				
				</div>				
				<div class="p-col-12" >
					<div class="p-grid" >
						<div class="p-col-4">	
						</div>
						<div class="p-col-1" style="margin-top: -7px">
							<span  style="font-size: 13px; font-weight: bold">Numeración:</span>
						</div>
					</div>
					<div class="p-grid" >
						<div class="p-col-4">	
						</div>
						<div class="p-col-4">
							<button  pButton class="aceptar" style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100% " (click)="opcionInicializar()">Inicializar</button>	
						</div>
						<div class="p-col-4">	
						</div>
						
					</div>
					<div class="p-grid" style="margin-top: 2px">
						<div class="p-col-4">	
						</div>
						<div class="p-col-4">
							<button  pButton class="aceptar" style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100% " (click)=" opcionCambios()">Cambiar</button>	
						</div>
						<div class="p-col-4">	
						</div>
						
					</div>
				</div>
			</div>
		</div>           
	</p-tabPanel>
</p-tabView>