import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'app-banbusqueda',
  templateUrl: './banbusqueda.component.html',
  styleUrls: ['./banbusqueda.component.css']
})
export class BanbusquedaComponent implements OnInit, OnChanges, OnDestroy {

  constructor() {
    this.arrayOptions = [];
  }

  @Input() arregloConsulta: any[];
  @Input() arrayOptions: SelectItem[];
  @Output() opcionSeleccionada = new EventEmitter<any[]>();
  @Output() salirsignal = new EventEmitter<boolean>();
  tipoBusqueda: string;
  tipoSeleccionado: string;
  selectedItems: any[];
  elementosSeleccionados: any[];
  contador: number;

  static prueba() {
    document.getElementById('tr').tabIndex = -1;
    document.getElementById('tr').focus();
  }

  ngOnInit() {
    this.tipoBusqueda = 'contains';
    this.tipoSeleccionado = '';
    this.elementosSeleccionados = [];
    this.contador = 0;
  }
  ngOnDestroy(): void {
    this.contador = 0;
    this.elementosSeleccionados = [];
    this.arregloConsulta = [];
   
    console.log('salio del componente');
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.arrayOptions, this.arregloConsulta);
    for (const valor in changes) {
      if (valor === 'arrayOptions') {
        if (this.arrayOptions !== undefined) {
          this.tipoSeleccionado = this.arrayOptions[0].value;
        }
      } else if (valor === 'arregloConsulta') {

      }
    }
  }
  salir() {
    this.salirsignal.emit(true);
  }
  enviarDatos(datos) {
    this.opcionSeleccionada.emit(datos);
  }
  llenarArreglo(datosrecibidos) {
    let contador = 0;
    let dato = {};
    if (this.elementosSeleccionados.length !== 0) {
      this.elementosSeleccionados.map((res) => {
        if (res === datosrecibidos) {
          contador ++;
          dato = res;
        }
      });
      console.log(contador);
      if (contador === 0) {
        console.log('no rep', datosrecibidos);
        this.elementosSeleccionados = [...this.elementosSeleccionados, datosrecibidos];
      } else if (contador === 1) {
        console.log('rep', dato);
        this.elementosSeleccionados = this.elementosSeleccionados.filter((val, j) => j !==
          this.elementosSeleccionados.indexOf(dato));
      }
    } else {
      this.elementosSeleccionados = [...this.elementosSeleccionados, datosrecibidos];
    }
  }
  escogerTodos() {
    this.contador++;
    if (this.contador % 2 === 0) {
      this.elementosSeleccionados = [];
    } else if (this.contador % 2 !== 0) {
      this.elementosSeleccionados = this.arregloConsulta;
    }
  }


}
