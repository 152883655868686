//
// SACI WEB
// Rutina: Maestro de Cargos
// Propietario: Nicole Pirca
// Modificado por: NP
// Fecha de creación: 2019
// Fecha de Modificación: 5-11-2019
//
import {Component, OnInit, ViewChild} from '@angular/core';
import {Commaecargos} from '../cominterfaces/commaecargos';
import {CommaecargosService} from '../comservicios/commaecargos.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

// Ag-grid
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';

@Component({
  selector: 'app-commaecargos',
  templateUrl: './commaecargos.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class CommaecargosComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  actualizar: boolean;
  permisosCargos: SegMaePermiso;
  codigoAux: any;
  arregloCons: any[];
  nuevoCampo: boolean;
  largo: string;
  cargoSeleccionado: Commaecargos;
  public frameworkComponents;
  rowStyle;
  desCodigo: boolean;
  defaultColDefCargos;
  rowSelection = 'multiple';
  columnDefsCargos = [
    {
      headerName: 'Código', field: 'CARGO_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 5
          }
        }
    },
    {
      headerName: 'Nombre', field: 'CARGO_NOMBRE', cellEditor: 'cellPrueba', width: 250,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: false,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 35
          }
        }
    },
    {
      headerName: 'Cod.Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 35,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Centro de Costo', field: 'CEN_CODIGO', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 35
          }
        }
    }
  ];
  
  
  constructor(public cargoService: CommaecargosService, private message: MessageService, private errorService: ErroresBaseDatosService,
              private confirmationService: ConfirmationService, private permisosService: PermisosService,
              private busqService: InvbusquedaService, private auditoriagral: AuditoriagralService,
              private init: NuevoComponentService
  ) {
    
    this.agTable();
    this.permisosCargos = {};
    this.arregloCons = [];
    this.buscarCon();
    this.cargoService.commaecargos = [];
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefCargos = {
      editable: true,
      width: 100,
      objeto: 'maecargos'
    };
  }
  
  
  ngOnInit() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.actualizar = false;
    this.nuevoCampo = false;
    this.codigoAux = 0;
    this.permisosService.getListaPermisos('COM', '1', '663').subscribe((res) => {
      this.permisosCargos = res[0];
    });
    this.cargoService.getCargos().subscribe((res) => {
      if (res !== null) {
        this.cargoService.commaecargos = res;
        this.cargoService.commaecargos.map((cargo) => {
          if (cargo.CON_CODIGO !== null) {
            this.arregloCons.map((con) => {
              if (con.CON_CODIGO === cargo.CON_CODIGO) {
                cargo.CON_CENTRO = con.CON_CENTRO;
              }
            });
          } else {
            cargo.CON_CENTRO = 'No';
          }
        });
      }
    });
    this.largo = '258';
  }
  
  async manejarSenales(valor) {
    if (valor === 'Nuevo') {
      if (this.permisosCargos.PERINSERCION === 1) {
        this.btnNuevo = true;
        this.btnGuardar = false;
        this.btnBorrar = false;
        this.btnRegresar = false;
        this.nuevoCampo = true;
        this.aggrid.isEditable(true);
        this.cargoSeleccionado = {
          CARGO_CODIGO: '',
          CARGO_NOMBRE: '',
          CON_CODIGO: '',
          COM_CODIGO: '01',
          CEN_CODIGO: '',
        };
        this.cargoService.commaecargos.push(this.cargoSeleccionado);
        this.aggrid.refreshaggrid(this.cargoService.commaecargos, this.defaultColDefCargos.objeto);
      } else if (this.permisosCargos.PERINSERCION === 0) {
        this.message.add({
          key: 'maecargos',
          severity: 'error',
          summary: 'Inserción de Cargo',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    }
    if (valor === 'Guardar') {
      if (this.permisosCargos.PERACTUALIZACION === 1) {
        this.btnGuardar = true;
        this.btnNuevo = false;
        this.btnBorrar = false;
        this.btnRegresar = true;
        this.cargoService.erFindCodCargo(this.cargoSeleccionado.CARGO_CODIGO ).subscribe( async existe => {
          if (existe !== null) {
            let coincidencias: number;
            coincidencias = 0;
            // this.arregloCons.map((con) => {
            //   if (this.cargoSeleccionado.CON_CODIGO === con.CON_CODIGO) {
            //     coincidencias++;
            //   }
            // });
            const rsdata: any = await this.cargoService.encontrarArtCntaySer(this.cargoSeleccionado.CON_CODIGO, 'C');
            //let strCodigo = 0;
            if (rsdata !== null) {
              for (const rs of rsdata) {
                coincidencias = rs.NUMERO;
              }
            }
            if (coincidencias !== 0) {
              this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
              this.defaultColDefCargos.objeto].stopEditing();
              this.cargoService.actualizarCargo(this.cargoSeleccionado, this.codigoAux).subscribe(() => {
                this.message.add({
                  key: 'maecargos',
                  severity: 'success',
                  summary: 'Actualización exitosa',
                  detail: 'Se actualizó el cargo correctamente'
                });
                this.auditoriagral.registrarAuditoria('CON_MAECARGO', this.cargoSeleccionado.CARGO_CODIGO, 'A', ''
                  , '01', '', '', '', '').subscribe(() => {
                });
                this.aggrid.isEditable(false);
              }, error1 => {
                let mensaje: string;
                mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                this.message.add({
                  key: 'maecargos',
                  severity: 'error',
                  summary: 'Actualización de Cargo',
                  detail: mensaje
                });
                this.btnNuevo = false;
                this.btnGuardar = true;
                this.btnBorrar = false;
                this.btnRegresar = true;
                this.cargoService.getCargos().subscribe((res) => {
                  this.cargoService.commaecargos = res;
                  this.aggrid.refreshaggrid(this.cargoService.commaecargos, this.defaultColDefCargos.objeto);
                });
              });
            } else if (coincidencias === 0) {
              this.message.add({
                key: 'maecargos',
                severity: 'error',
                summary: 'Error en Cuenta Contable',
                detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
              });
              this.cargoSeleccionado.CON_CODIGO = '';
              this.aggrid.refreshaggrid(this.cargoService.commaecargos, this.defaultColDefCargos.objeto);
            }
          } else if (this.actualizar === false) {
            let coincidencias: number;
            coincidencias = 0;
            // this.arregloCons.map((con) => {
            //   if (this.cargoSeleccionado.CON_CODIGO === con.CON_CODIGO) {
            //     coincidencias++;
            //   }
            // });
            const rsdata: any = await this.cargoService.encontrarArtCntaySer(this.cargoSeleccionado.CON_CODIGO, 'C');
            //let strCodigo = 0;
            if (rsdata !== null) {
              for (const rs of rsdata) {
                coincidencias = rs.NUMERO;
              }
            }
            if (coincidencias !== 0) {
              this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
              this.defaultColDefCargos.objeto].stopEditing();
              this.cargoService.insertarCargo(this.cargoSeleccionado).subscribe(() => {
                this.message.add({
                  key: 'maecargos',
                  severity: 'success',
                  summary: 'Inserción exitosa',
                  detail: 'Se insertó Cargo'
                });
                this.aggrid.isEditable(false);
                this.nuevoCampo = false;
              }, error1 => {
                let mensaje: string;
                mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                this.message.add({
                  key: 'maecargos',
                  severity: 'error',
                  summary: 'Inserción del cargo',
                  detail: mensaje
                });
                this.auditoriagral.registrarAuditoria('CON_MAECARGO', this.cargoSeleccionado.CARGO_CODIGO, 'I', ''
                  , '01', '', '', '', '').subscribe(() => {
                });
                
                this.btnNuevo = false;
                this.btnGuardar = true;
                this.btnBorrar = false;
                this.btnRegresar = true;
                this.cargoService.getCargos().subscribe((res) => {
                  this.cargoService.commaecargos = res;
                  this.aggrid.refreshaggrid(this.cargoService.commaecargos, this.defaultColDefCargos.objeto);
                });
              });
            } else if (coincidencias === 0) {
              this.message.add({
                key: 'maecargos',
                severity: 'error',
                summary: 'Error en Cuenta contable',
                detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
              });
              this.cargoSeleccionado.CON_CODIGO = '';
              this.aggrid.refreshaggrid(this.cargoService.commaecargos, this.defaultColDefCargos.objeto);
            }
          }//
        });
      } else if (this.permisosCargos.PERACTUALIZACION === 0) {
        this.message.add({
          key: 'maecargos',
          severity: 'error',
          summary: 'Actualizacion de Cargo',
          detail: 'El usuario no tiene permisos de Actualizacion, acción denegada'
        });
      }
    }
    if (valor === 'Borrar') {
      if (this.permisosCargos.PERELIMACION === 1) {
        this.btnNuevo = false;
        this.aggrid.isEditable(false);
        if (this.cargoSeleccionado === undefined) {
          this.message.add({
            key: 'maecargos',
            severity: 'error',
            summary: 'Actualizacion de Cargo',
            detail: ' Seleccion Incorrecta, no se ha selecionado un Cargo'
          });
        } else {
          this.confirmarCargo();
        }
      } else if (this.permisosCargos.PERELIMACION === 0) {
        this.message.add({
          key: 'maecargos',
          severity: 'error',
          summary: 'Elinacion de Cargo',
          detail: 'El usuario no tiene permisos de Eliminacion, acción denegada'
        });
        this.cargoSeleccionado.CON_CODIGO = '';
        this.aggrid.refreshaggrid(this.cargoService.commaecargos, this.defaultColDefCargos.objeto);
      }
    }
    if (valor === 'Cancelar') {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.aggrid.isEditable(false);
      this.cargoService.getCargos().subscribe((res) => {
        this.cargoService.commaecargos = res;
        this.aggrid.refreshaggrid(this.cargoService.commaecargos, this.defaultColDefCargos.objeto);
      });
    }
    
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
    
  }
  
  selectedCargo(valor) {
    if (valor.object !== null) {
      this.cargoSeleccionado = valor.object;
      if (this.nuevoCampo === true) {
        this.actualizar = false;
      } else if (this.nuevoCampo === false) {
        this.actualizar = true;
        if (this.cargoSeleccionado !== undefined) {
          this.codigoAux = this.cargoSeleccionado.CARGO_CODIGO;
        }
      }
    }
  }
  
  confirmarCargo() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el Cargo',
      header: 'Eliminar Cargo',
      icon: 'pi pi-exclamation-triangle',
      key: 'MaeCargos',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarCargo();
      },
      reject: () => {
      }
    });
  }
  
  eliminarCargo() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.cargoService.eliminarCargo(this.cargoSeleccionado).subscribe(() => {
      this.message.add({
        key: 'maecargos',
        severity: 'success',
        summary: 'Eliminación exitosa',
        detail: 'Se eliminó el cargo correctamente'
      });
      this.auditoriagral.registrarAuditoria('CON_MAECARGO', this.cargoSeleccionado.CARGO_CODIGO, 'E', ''
        , '01', '', '', '', '').subscribe(() => {
      });
      
      this.cargoService.commaecargos = this.cargoService.commaecargos.filter((val, j) => j !==
        this.cargoService.commaecargos.indexOf(this.cargoSeleccionado));
      this.aggrid.refreshaggrid(this.cargoService.commaecargos, this.defaultColDefCargos.objeto);
    });
  }
  
  cambio(cambio) {
    this.btnGuardar = false;
    this.btnRegresar = false;
  }
  
  buscarCon() {
    this.busqService.busquedaConMaeCon().subscribe((res: any[]) => {
      this.arregloCons = res;
    });
  }
  
}
