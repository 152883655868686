import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NuevoComponentService {
  componentePrincipal = 0;
  private _tabs: any;
  private _tabindex: any = 1;
  public indice: any = 0;
  private _auxsalto: boolean;
  public disabledtab = false;
  
  constructor(private http: HttpClient) {
  }
  
  public grids: any[] = [];
  
  get tabs(): any {
    return this._tabs;
  }
  
  set tabs(value: any) {
    this._tabs = value;
  }
  
  get tabindex(): any {
    return this._tabindex;
  }
  
  set tabindex(value: any) {
    this._tabindex = value;
  }
  
  componenteIniciado() {
    this.componentePrincipal++;
  }
  
  componenteEliminado() {
    this.componentePrincipal--;
  }
  
  obtenerComponenteNum() {
    return this.componentePrincipal;
  }
  
  get auxsalto(): boolean {
    return this._auxsalto;
  }
  
  set auxsalto(value: boolean) {
    this._auxsalto = value;
  }
  
  quitarComas(value): string {
    const sincomas = String(value).split('').filter((ele, key) => ele !== ',').join('');
    return sincomas;
  }
  
  moneyValidation(value): string {
    let sign = '';
    let aux = String(value).substr(0, String(value).indexOf('.'));
    let before = '';
    if (aux.startsWith('-')) {
      sign = '-';
      aux = aux.substr(1);
    }
    for (let i = aux.length - 1, j = 1; i >= 0; i--, j++) {
      before = aux.charAt(i) + before;
      if (j % 3 === 0 && j < aux.length) {
        before = ',' + before;
      }
    }
    aux = sign + before + String(value).substr(String(value).indexOf('.'));
    return aux;
  }
  
}
