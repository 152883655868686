import { Component, OnInit, ViewChild } from '@angular/core';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { AggridBanComponent } from '../../ban/aggrid-ban/aggrid-ban.component';
import { FirmardocumentoService } from '../venservicios/firmardocumento.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { Usuario } from '../../usuario';
import { ConfirmationService, MessageService } from 'primeng';
import { DatePipe } from '@angular/common';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { SegMaeCompania } from '../../seg/seginterfaces/segmaecompania';
import { FacturaVenta, VenCredito, VenDebito, VenGuiaRemision, VenLiquidacion, VenRetencion } from '../veninterfaces/firmardocumento';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';


@Component({
  selector: 'app-firmardocumento',
  templateUrl: './firmardocumento.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class FirmardocumentoComponent implements OnInit {


  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  dateDesde = new Date();
  dateHasta = new Date();
  chkseleccionarNotas;
  chkseleccionarTodoFact;
  chkseleccionarTodoRet;
  chkseleccionarTodoGuiaRem;
  chkseleccionarTodoDebito;
  chkseleccionarTodoCredito;
  chkseleccionarTodoLiquidacion;
  fechaInicio = '';
  fechaFin = '';

  datosfe: SegMaeCompania;

  largo: string;
  public frameworkComponents;
  rowStyle;
  defaultVenFacturas;
  defaultVenRetenciones;
  defaultVenGuiaRemision;
  defaultVenCreditos;
  defaultVenDebitos;
  defaultVenLiquidaciones;
  rowSelection = 'multiple';

  indicador: any;

  contador = 0;
  claveDialog: boolean;
  claveAuth: string;
  tipoFirma = '';
  spin = false;

  columnDefsVenFacturas = [
    {
      headerName: 'Sel.', field: 'DOC_SELECCIONADOF', cellRendererFramework: AggridBanComponent,
      editable: false, width: 50
    },
    {
      headerName: 'Factura', field: 'ENCFAC_NUMERO', editable: false, width: 150,
    },
    {
      headerName: 'Fecha Emisión', field: 'ENCFAC_FECHAEMISION1', editable: false, width: 120,
    },
    {
      headerName: 'Estado', field: 'ENCFAC_ESTADO', editable: false, width: 80,
    },
    {
      headerName: 'Cédula/RUC', field: 'CLI_CODIGO', editable: false, width: 160,
    },
    {
      headerName: 'Nombre Cliente', field: 'CLI_NOMBRE', editable: false, width: 250,
    },
    {
      headerName: 'Núm.Electrónico', field: 'SERIE_SECACTUAL', editable: false, width: 150,
    },
    {
      headerName: 'Tipo', field: 'ENCFAC_TIPOFACTURA', editable: false, width: 80,
    }
  ];

  columnDefsVenRetenciones = [
    {
      headerName: 'Sel.', field: 'DOC_SELECCIONADOR', cellRendererFramework: AggridBanComponent,
      editable: false, width: 50
    },
    {
      headerName: 'Factura', field: 'TRNCOBRO_NROCOMPROBANTE', editable: false, width: 150,
    },
    {
      headerName: 'Fecha Emisión', field: 'TRNRETENCION_FECHAEMISION1', editable: false, width: 120,
    },
    {
      headerName: 'Estado', field: 'TRNRETENCION_ESTADO', editable: false, width: 80,
    },
    {
      headerName: 'Cédula/RUC', field: 'PRO_CODIGO', editable: false, width: 160,
    },
    {
      headerName: 'Nombre Proveedor', field: 'PRO_NOMBRE', editable: false, width: 300,
    },
    {
      headerName: 'Núm.Retención', field: 'TRNRETENCION_NRO', editable: false, width: 150,
    },
    {
      headerName: 'Comprobante', field: 'TRNRETENCION_SERIE', editable: false, width: 120,
    }
  ];

  columnDefsVenGuiaRemision = [
    {
      headerName: 'Sel.', field: 'DOC_SELECCIONADOG', cellRendererFramework: AggridBanComponent,
      editable: false, width: 50
    },
    {
      headerName: 'Núm Guia', field: 'ENCGRE_NUMERO', editable: false, width: 150,
    },
    {
      headerName: 'Fecha Emisión', field: 'ENCGRE_FECHAEMISION1', editable: false, width: 120,
    },
    {
      headerName: 'Estado', field: 'ENCGRE_ESTADO', editable: false, width: 80,
    },
    {
      headerName: 'Cédula/RUC', field: 'CLI_CODIGO', editable: false, width: 160,
    },
    {
      headerName: 'Nombre Cliente', field: 'ENCGRE_NMBRDSTNTR', editable: false, width: 300,
    },
    {
      headerName: 'Núm.Factura', field: 'ENCFAC_NUMERO', editable: false, width: 150,
    },
    {
      headerName: 'Cod.Guia', field: 'ENCGRE_CODIGO', editable: false, width: 100,
    }
  ];

  columnDefsVenCredito = [
    {
      headerName: 'Sel.', field: 'DOC_SELECCIONADOC', cellRendererFramework: AggridBanComponent,
      editable: false, width: 50
    },
    {
      headerName: 'Núm NC', field: 'ENCDVF_NUMERO', editable: false, width: 150,
    },
    {
      headerName: 'Fecha Emisión', field: 'ENCDVF_FECHAEMISION1', editable: false, width: 120,
    },
    {
      headerName: 'Estado', field: 'ENCDVF_ESTADO', editable: false, width: 80,
    },
    {
      headerName: 'Cédula/RUC', field: 'CLI_CODIGO', editable: false, width: 160,
    },
    {
      headerName: 'Nombre Cliente', field: 'CLI_NOMBRE', editable: false, width: 300,
    },
    {
      headerName: 'Núm.Referencia', field: 'ENCDVF_REFERENCIA', editable: false, width: 150,
    },
    {
      headerName: 'Núm.Electrónico', field: 'ENCDVF_ELECTRONICO', editable: false, width: 120,
    }
  ];

  columnDefsVenDebito = [
    {
      headerName: 'Sel.', field: 'DOC_SELECCIONADOD', cellRendererFramework: AggridBanComponent,
      editable: false, width: 50
    },
    {
      headerName: 'Núm ND', field: 'TRNCOBRO_NRODOC', editable: false, width: 150,
    },
    {
      headerName: 'Fecha Emisión', field: 'TRNCOBRO_FECHATRN1', editable: false, width: 120,
    },
    {
      headerName: 'Estado', field: 'TRNCOBRO_ESTADO', editable: false, width: 80,
    },
    {
      headerName: 'Cédula/RUC', field: 'CLI_CODIGO', editable: false, width: 160,
    },
    {
      headerName: 'Nombre Cliente', field: 'CLI_NOMBRE', editable: false, width: 300,
    },
    {
      headerName: 'Núm.Comprobante', field: 'TRNCOBRO_NROCOMPROBANTE', editable: false, width: 150,
    },
    {
      headerName: 'Núm.Ref', field: 'TRNCOBRO_REFERENCIAEXTERNA', editable: false, width: 120,
    }
  ];

  columnDefsVenLiquidacion = [
    {
      headerName: 'Sel.', field: 'DOC_SELECCIONADOL', cellRendererFramework: AggridBanComponent,
      editable: false, width: 50
    },
    {
      headerName: 'Factura', field: 'ENCFACPRO_NUMERO', editable: false, width: 150,
    },
    {
      headerName: 'Fecha Emisión', field: 'ENCFACPRO_FECHAEMISION1', editable: false, width: 120,
    },
    {
      headerName: 'Estado', field: 'ENCFACPRO_ESTADO', editable: false, width: 80,
    },
    {
      headerName: 'Cédula/RUC', field: 'PRO_CODIGO', editable: false, width: 160,
    },
    {
      headerName: 'Nombre Proveedor', field: 'PRO_NOMBRE', editable: false, width: 300,
    },
    {
      headerName: 'Núm.Liquidación', field: 'ENCFACPRO_REFERENCIA', editable: false, width: 150,
    },
    {
      headerName: 'Comprobante', field: 'ENCFACPRO_SERIE', editable: false, width: 120,
    }
  ];

  constructor(public firmadocService: FirmardocumentoService,
    private init: NuevoComponentService,
    private permisosService: PermisosService, private auditoriagral: AuditoriagralService,
    private errorService: ErroresBaseDatosService, public usuario: Usuario,
    private message: MessageService, private datePipe: DatePipe,
    private confirmationService: ConfirmationService, private utilitariosService: UtilitariosService) {
    this.agTable();
    this.firmadocService.detalleVenFacturas[this.indicador] = [];
    this.firmadocService.detalleVenCreditos[this.indicador] = [];
    this.firmadocService.detalleVenDebitos[this.indicador] = [];
    this.firmadocService.detalleVenGuiaRemisiones[this.indicador] = [];
    this.firmadocService.detalleVenLiquidacion[this.indicador] = [];
    this.firmadocService.detalleVenRetenciones[this.indicador] = [];
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };


    this.defaultVenFacturas = {
      editable: true,
      width: 100,
      objeto: 'firmadocfacturas',
      resizable: true
    };

    this.defaultVenRetenciones = {
      editable: true,
      width: 100,
      objeto: 'firmadocretenciones',
      resizable: true
    };
    this.defaultVenGuiaRemision = {
      editable: true,
      width: 100,
      objeto: 'firmadocguiaremision',
      resizable: true
    };

    this.defaultVenCreditos = {
      editable: true,
      width: 100,
      objeto: 'firmadoccreditos',
      resizable: true
    };

    this.defaultVenDebitos = {
      editable: true,
      width: 100,
      objeto: 'firmadocdebitos',
      resizable: true
    };
    this.defaultVenLiquidaciones = {
      editable: true,
      width: 100,
      objeto: 'firmadocliquidaciones',
      resizable: true
    };

  }

  async ngOnInit() {
    this.largo = '450';
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.firmadocService.detalleVenFacturas[this.indicador] = [];
    this.firmadocService.detalleVenCreditos[this.indicador] = [];
    this.firmadocService.detalleVenDebitos[this.indicador] = [];
    this.firmadocService.detalleVenGuiaRemisiones[this.indicador] = [];
    this.firmadocService.detalleVenLiquidacion[this.indicador] = [];
    this.firmadocService.detalleVenRetenciones[this.indicador] = [];

    this.firmadocService.btnFirmarFact[this.indicador] = true;
    this.firmadocService.btnFirmarRet[this.indicador] = true;
    this.firmadocService.btnFirmarGuia[this.indicador] = true;
    this.firmadocService.btnFirmarCred[this.indicador] = true;
    this.firmadocService.btnFirmarDeb[this.indicador] = true;
    this.firmadocService.btnFirmarLiq[this.indicador] = true;

    if (this.chkseleccionarNotas === true) {
      this.listarDatos2Fac();
    } else {
      this.listarDatos1Fac();
    }

    this.listarDatos1Credito();
    this.listarDatos1Debito();
    this.listarDatos1GuiaRem();
    this.listarDatos1Retencion();
    this.listarDatos1Liquidacion();
    const rs = await this.firmadocService.leerConfiguracionDatosElectronicos();
    for (const com of rs) {
      this.datosfe = com;
    }
  }

  manejarSenales(valor) {
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }

  aceptar() {
    let auxFecha = true;

    if (this.dateHasta.getFullYear() < this.dateDesde.getFullYear()) {
      auxFecha = false;
    }

    if ((this.dateHasta.getFullYear() === this.dateDesde.getFullYear()) && (this.dateHasta.getMonth() < this.dateDesde.getMonth())) {
      auxFecha = false;
    }

    if ((this.dateHasta.getFullYear() === this.dateDesde.getFullYear()) && (this.dateHasta.getMonth() === this.dateDesde.getMonth())
      && (this.dateHasta.getDate() < this.dateDesde.getDate())) {
      auxFecha = false;
    }

    if (auxFecha === true) {
      this.fechaInicio = this.dateDesde.getDate() + '/' + (this.dateDesde.getMonth() + 1) + '/' + this.dateDesde.getFullYear();
      this.fechaFin = this.dateHasta.getDate() + '/' + (this.dateHasta.getMonth() + 1) + '/' + this.dateHasta.getFullYear();

      if (this.chkseleccionarNotas === true) {
        this.listarDatos2Fac();
      } else {
        this.listarDatos1Fac();
      }

      this.listarDatos1Credito();
      this.listarDatos1Debito();
      this.listarDatos1GuiaRem();
      this.listarDatos1Retencion();
      this.listarDatos1Liquidacion();
    } else {
      this.mensajeAlerta('error', 'Error', '! La fecha final es menor que la fecha de inicio!');
    }
  }

  async listarDatos2Fac() {
    this.firmadocService.detalleVenFacturas[this.indicador] = [];
    this.spin = true;
    try {
      const rs = await this.firmadocService.eslistarDatos2Fac(this.fechaInicio, this.fechaFin);
      if (rs !== null) {
        this.firmadocService.detalleVenFacturas[this.indicador] = rs;
        this.firmadocService.detalleVenFacturas[this.indicador].map(item => {
          item.DOC_SELECCIONADOF = false;
        });
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso listar documentos no terminó satisfactoriamente.... ! ' + mensaje);
    }
    this.spin = false;
  }

  async listarDatos1Fac() {
    this.firmadocService.detalleVenFacturas[this.indicador] = [];
    this.spin = true;
    try {
      const rs = await this.firmadocService.eslistarDatos1Fac(this.fechaInicio, this.fechaFin);
      if (rs !== null) {
        this.firmadocService.detalleVenFacturas[this.indicador] = rs;
        this.firmadocService.detalleVenFacturas[this.indicador].map(item => {
          item.DOC_SELECCIONADOF = false;
        });
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso listar documentos no terminó satisfactoriamente.... ! ' + mensaje);
    }
    this.spin = false;
  }

  async listarDatos1Credito() {
    this.firmadocService.detalleVenCreditos[this.indicador] = [];

    try {
      const rs = await this.firmadocService.eslistarDatos1Credito(this.fechaInicio, this.fechaFin);
      if (rs !== null) {
        this.firmadocService.detalleVenCreditos[this.indicador] = rs;
        this.firmadocService.detalleVenCreditos[this.indicador].map(item => {
          item.DOC_SELECCIONADOC = false;
        });
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso listar documentos no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }

  async listarDatos1Debito() {
    this.firmadocService.detalleVenDebitos[this.indicador] = [];

    try {
      const rs = await this.firmadocService.eslistarDatos1Debito(this.fechaInicio, this.fechaFin);
      if (rs !== null) {
        this.firmadocService.detalleVenDebitos[this.indicador] = rs;
        this.firmadocService.detalleVenDebitos[this.indicador].map(item => {
          item.DOC_SELECCIONADOD = false;
        });
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso listar documentos no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }

  async listarDatos1GuiaRem() {
    this.firmadocService.detalleVenGuiaRemisiones[this.indicador] = [];

    try {
      const rs = await this.firmadocService.eslistarDatos1guiarem(this.fechaInicio, this.fechaFin);
      if (rs !== null) {
        this.firmadocService.detalleVenGuiaRemisiones[this.indicador] = rs;
        this.firmadocService.detalleVenGuiaRemisiones[this.indicador].map(item => {
          item.DOC_SELECCIONADOG = false;
        });
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso listar documentos no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }

  async listarDatos1Retencion() {
    this.firmadocService.detalleVenRetenciones[this.indicador] = [];

    try {
      const rs = await this.firmadocService.eslistarDatos1Retencion(this.fechaInicio, this.fechaFin);
      if (rs !== null) {
        this.firmadocService.detalleVenRetenciones[this.indicador] = rs;
        this.firmadocService.detalleVenRetenciones[this.indicador].map(item => {
          item.DOC_SELECCIONADOR = false;
        });
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso listar documentos no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }

  async listarDatos1Liquidacion() {
    this.firmadocService.detalleVenLiquidacion[this.indicador] = [];

    try {
      const rs = await this.firmadocService.eslistarDatos1Liquidacion(this.fechaInicio, this.fechaFin);
      if (rs !== null) {
        this.firmadocService.detalleVenLiquidacion[this.indicador] = rs;
        this.firmadocService.detalleVenLiquidacion[this.indicador].map(item => {
          item.DOC_SELECCIONADOL = false;
        });
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso listar documentos no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }

  selectTodosFact() {
    if (this.chkseleccionarTodoFact === true) {
      this.seleccionarTodosItemsFact(1);
      this.firmadocService.btnFirmarFact[this.indicador] = false;
    } else {
      this.seleccionarTodosItemsFact(0);
      this.firmadocService.btnFirmarFact[this.indicador] = true;
    }
  }

  seleccionarTodosItemsFact(intSel: number) {
    let bolSel = false;
    if (intSel === 1) {
      bolSel = true;
    } else {
      bolSel = false;
    }

    for (const item of this.firmadocService.detalleVenFacturas[this.indicador]) {
      item.DOC_SELECCIONADOF = bolSel;
    }
    this.contador = this.firmadocService.detalleVenFacturas[this.indicador].length;
    this.aggrid.refreshaggrid(this.firmadocService.detalleVenFacturas[this.indicador], this.defaultVenFacturas.objeto);
  }

  selectTodosRet() {
    if (this.chkseleccionarTodoRet === true) {
      this.seleccionarTodosItemsRet(1);
      this.firmadocService.btnFirmarRet[this.indicador] = false;
    } else {
      this.seleccionarTodosItemsRet(0);
      this.firmadocService.btnFirmarRet[this.indicador] = true;
    }
  }

  seleccionarTodosItemsRet(intSel: number) {
    let bolSel = false;

    if (intSel === 1) {
      bolSel = true;
    } else {
      bolSel = false;
    }

    for (const item of this.firmadocService.detalleVenRetenciones[this.indicador]) {
      item.DOC_SELECCIONADOR = bolSel;
    }
    this.contador = this.firmadocService.detalleVenRetenciones[this.indicador].length;
    this.aggrid.refreshaggrid(this.firmadocService.detalleVenRetenciones[this.indicador], this.defaultVenRetenciones.objeto);
  }

  selectTodosGuia() {
    if (this.chkseleccionarTodoGuiaRem === true) {
      this.seleccionarTodosItemsGuia(1);
      this.firmadocService.btnFirmarGuia[this.indicador] = false;
    } else {
      this.seleccionarTodosItemsGuia(0);
      this.firmadocService.btnFirmarGuia[this.indicador] = true;
    }
  }

  seleccionarTodosItemsGuia(intSel: number) {
    let bolSel = false;

    if (intSel === 1) {
      bolSel = true;
    } else {
      bolSel = false;
    }

    for (const item of this.firmadocService.detalleVenGuiaRemisiones[this.indicador]) {
      item.DOC_SELECCIONADOG = bolSel;
    }
    this.contador = this.firmadocService.detalleVenGuiaRemisiones[this.indicador].length;
    this.aggrid.refreshaggrid(this.firmadocService.detalleVenGuiaRemisiones[this.indicador], this.defaultVenGuiaRemision.objeto);
  }

  selectTodosCred() {
    if (this.chkseleccionarTodoCredito === true) {
      this.seleccionarTodosItemsCred(1);
      this.firmadocService.btnFirmarCred[this.indicador] = false;
    } else {
      this.seleccionarTodosItemsCred(0);
      this.firmadocService.btnFirmarCred[this.indicador] = true;
    }
  }

  seleccionarTodosItemsCred(intSel: number) {
    let bolSel = false;

    if (intSel === 1) {
      bolSel = true;
    } else {
      bolSel = false;
    }

    for (const item of this.firmadocService.detalleVenCreditos[this.indicador]) {
      item.DOC_SELECCIONADOC = bolSel;
    }
    this.contador = this.firmadocService.detalleVenCreditos[this.indicador].length;
    this.aggrid.refreshaggrid(this.firmadocService.detalleVenCreditos[this.indicador], this.defaultVenCreditos.objeto);
  }

  selectTodosDeb() {
    if (this.chkseleccionarTodoDebito === true) {
      this.seleccionarTodosItemsDeb(1);
      this.firmadocService.btnFirmarDeb[this.indicador] = false;
    } else {
      this.seleccionarTodosItemsDeb(0);
      this.firmadocService.btnFirmarDeb[this.indicador] = true;
    }
  }

  seleccionarTodosItemsDeb(intSel: number) {
    let bolSel = false;

    if (intSel === 1) {
      bolSel = true;
    } else {
      bolSel = false;
    }

    for (const item of this.firmadocService.detalleVenDebitos[this.indicador]) {
      item.DOC_SELECCIONADOD = bolSel;
    }
    this.contador = this.firmadocService.detalleVenDebitos[this.indicador].length;
    this.aggrid.refreshaggrid(this.firmadocService.detalleVenDebitos[this.indicador], this.defaultVenDebitos.objeto);
  }

  selectTodosLiq() {
    if (this.chkseleccionarTodoLiquidacion === true) {
      this.seleccionarTodosItemsLiq(1);
      this.firmadocService.btnFirmarLiq[this.indicador] = false;
    } else {
      this.seleccionarTodosItemsLiq(0);
      this.firmadocService.btnFirmarLiq[this.indicador] = true;
    }
  }

  seleccionarTodosItemsLiq(intSel: number) {
    let bolSel = false;

    if (intSel === 1) {
      bolSel = true;
    } else {
      bolSel = false;
    }

    for (const item of this.firmadocService.detalleVenLiquidacion[this.indicador]) {
      item.DOC_SELECCIONADOL = bolSel;
    }
    this.contador = this.firmadocService.detalleVenLiquidacion[this.indicador].length;
    this.aggrid.refreshaggrid(this.firmadocService.detalleVenLiquidacion[this.indicador], this.defaultVenLiquidaciones.objeto);
  }

  firmar(tipo: string) {
    this.tipoFirma = tipo;
    this.claveDialog = true;
  }

  async comprobarClave() {
    const clave = await this.firmadocService.obtenerClave();
    let claveReal;
    if (Object.keys(clave).length > 0) {
      claveReal = clave[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        this.claveDialog = false;
        this.confirmationService.confirm({
          message: 'Está seguro de firmar el documento ?',
          header: 'Confirmación',
          icon: 'pi pi-exclamation-triangle',
          key: 'firmardocConf',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: async () => {
            if (this.tipoFirma === 'facturas') {
              await this.insertarEleTrnDocumentoFac();
              if (this.chkseleccionarNotas === true) {
                this.listarDatos2Fac();
              } else {
                this.listarDatos1Fac();
              }
              this.firmadocService.btnFirmarFact[this.indicador] = true;
              this.chkseleccionarTodoFact = false;
            }
            if (this.tipoFirma === 'retenciones') {
              await this.insertarEleTrnDocumentoRet();
              this.listarDatos1Retencion();
              this.firmadocService.btnFirmarRet[this.indicador] = true;
              this.chkseleccionarTodoRet = false;
            }
            if (this.tipoFirma === 'guiasRemision') {
              await this.insertarEleTrnDocumentoGuia();
              this.listarDatos1GuiaRem();
              this.firmadocService.btnFirmarGuia[this.indicador] = true;
              this.chkseleccionarTodoGuiaRem = false;
            }
            if (this.tipoFirma === 'creditos') {
              await this.insertarEleTrnDocumentoCred();
              this.listarDatos1Credito();
              this.firmadocService.btnFirmarCred[this.indicador] = true;
              this.chkseleccionarTodoCredito = false;
            }
            if (this.tipoFirma === 'debitos') {
              await this.insertarEleTrnDocumentoDeb();
              this.listarDatos1Debito();
              this.firmadocService.btnFirmarDeb[this.indicador] = true;
              this.chkseleccionarTodoDebito = false;
            }
            if (this.tipoFirma === 'liquidaciones') {
              await this.insertarEleTrnDocumentoLiq();
              this.listarDatos1Liquidacion();
              this.firmadocService.btnFirmarLiq[this.indicador] = true;
              this.chkseleccionarTodoLiquidacion = false;
            }
            this.tipoFirma = '';
          },
          reject: () => {
          }
        });
        this.claveAuth = '';
      } else if (clave !== this.claveAuth) {
        this.claveDialog = false;
        this.mensajeAlerta('error', 'Error', 'La clave está incorrecta');
      }
    } else {
      this.claveDialog = false;
      this.mensajeAlerta('error', 'Error', 'La clave está incorrecta');
    }

  }

  async insertarEleTrnDocumentoFac() {
    let strTipoFac = '';
    let strFechaServidor = '';
    try {
      const rs = await this.firmadocService.erfechaDual();
      strFechaServidor = rs[0].FECHA;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }

    if (this.datosfe.COM_TIPOAMBFACTELEC === '1' || this.datosfe.COM_TIPOAMBFACTELEC === '2') {
      // Si tiene fecha de caducidad de la firma electrónica ingresada      
      if (this.datosfe.COM_FECHACADFIRMAELE !== null || this.datosfe.COM_FECHACADFIRMAELE !== '') {
        const fServer = strFechaServidor.split('/');
        const fechaServer = new Date(Number(fServer[2]), Number(fServer[1]) - 1, Number(fServer[0]));
        const fCadFact = String(this.datosfe.COM_FECHACADFIRMAELE).split('/');
        const fechaCadFact = new Date(Number(fCadFact[2]), Number(fCadFact[1]) - 1, Number(fCadFact[0]));

        if (fechaServer > fechaCadFact) {
          this.mensajeAlerta('info', 'Información', 'No puede firmar ningún documento electrónico porque esta ' +
            'caducada la fecha de validez de la firma electrónica');
          return;
        }
      } else {
        return;
      }
      for (const item of this.firmadocService.detalleVenFacturas[this.indicador]) {
        console.log('2', item);
        if (item.DOC_SELECCIONADOF === true) {
          console.log(3);
          if (item.ENCFAC_TIPOFACTURA === '18' || item.ENCFAC_TIPOFACTURA === '') { // factura normal
            strTipoFac = 'FCN';
          }
          if (item.ENCFAC_TIPOFACTURA === '01') { // factura de exportación
            strTipoFac = 'FCE';
          }
          if (item.ENCFAC_TIPOFACTURA === '41') { // factura De reembolso
            strTipoFac = 'FCR';
          }
          if (item.ENCFAC_TIPOFACTURA === '370') { // factura de operadora de transporte
            strTipoFac = 'FOT';
          }
          if (item.ENCFAC_TIPOFACTURA === '371') {// Comprobante Socio A Operadora De Transporte
            strTipoFac = 'COT';
          }

          try {
            await this.firmadocService.firmarDocumentos(item.ENCFAC_NUMERO, strTipoFac, 'VA', '', '',
              '', this.datosfe.COM_TIPOAMBFACTELEC);
            const data = await this.utilitariosService.procesoFirmaElectronica(item.ENCFAC_NUMERO, item.ENCFAC_FECHAEMISION1);
            if (data !== 'NOAUTORIZADO') {
              this.message.add({
                key: 'firmardoc',
                severity: 'warn',
                summary: 'Información',
                detail: data
              });
            }
          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('error', 'Error', '! Proceso guardar datos  no terminó satisfactoriamente.... ! ' + mensaje);
          }
        }
      }
    } else {
      this.message.add({
        key: 'firmardoc',
        severity: 'warn',
        summary: 'Información',
        detail: 'No se firmaron los documentos. Verifique la configuración'
      });
      return;
    }

  }

  async insertarEleTrnDocumentoRet() {
    let strTipoFac = '';
    let strFechaServidor = '';
    try {
      const rs = await this.firmadocService.erfechaDualinsert();
      strFechaServidor = rs[0].FECHA;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }

    if (this.datosfe.COM_TIPOAMBFACTELEC === '1' || this.datosfe.COM_TIPOAMBFACTELEC === '2') {
      // Si tiene fecha de caducidad de la firma electrónica ingresada
      if (this.datosfe.COM_FECHACADFIRMAELE !== null || this.datosfe.COM_FECHACADFIRMAELE !== '') {

        const fServer = strFechaServidor.split('/');
        const fechaServer = new Date(Number(fServer[2]), Number(fServer[1]) - 1, Number(fServer[0]));
        const fCadFact = String(this.datosfe.COM_FECHACADFIRMAELE).split('/');
        const fechaCadFact = new Date(Number(fCadFact[2]), Number(fCadFact[1]) - 1, Number(fCadFact[0]));

        if (fechaServer > fechaCadFact) {
          this.mensajeAlerta('info', 'Información', ' No puede firmar ningún documento electrónico porque esta ' +
            'caducada la fecha de validez de la firma electrónica');
          return;
        }
      } else {
        return;
      }

      for (const item of this.firmadocService.detalleVenRetenciones[this.indicador]) {
        if (item.DOC_SELECCIONADOR === true) {
          strTipoFac = 'RET';

          try {
            await this.firmadocService.firmarDocumentos(item.TRNCOBRO_NROCOMPROBANTE, strTipoFac, 'VA', '', '',
              '', this.datosfe.COM_TIPOAMBFACTELEC);
            const data = await this.utilitariosService.procesoFirmaElectronica(item.TRNCOBRO_NROCOMPROBANTE, item.TRNRETENCION_FECHAEMISION1);
            if (data !== 'NOAUTORIZADO') {
              this.message.add({
                key: 'firmardoc',
                severity: 'warn',
                summary: 'Información',
                detail: data
              });
            }
          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('error', 'Error', '! Proceso guardar datos  no terminó satisfactoriamente.... ! ' + mensaje);
          }

        }
      }
    } else {
      return;
    }


  }

  async insertarEleTrnDocumentoGuia() {
    let strTipoFac = '';
    let strFechaServidor = '';
    try {
      const rs = await this.firmadocService.erfechaDualinsert();
      strFechaServidor = rs[0].FECHA;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }

    if (this.datosfe.COM_TIPOAMBFACTELEC === '1' || this.datosfe.COM_TIPOAMBFACTELEC === '2') {
      // Si tiene fecha de caducidad de la firma electrónica ingresada
      if (this.datosfe.COM_FECHACADFIRMAELE !== null || this.datosfe.COM_FECHACADFIRMAELE !== '') {

        const fServer = strFechaServidor.split('/');
        const fechaServer = new Date(Number(fServer[2]), Number(fServer[1]) - 1, Number(fServer[0]));
        const fCadFact = String(this.datosfe.COM_FECHACADFIRMAELE).split('/');
        const fechaCadFact = new Date(Number(fCadFact[2]), Number(fCadFact[1]) - 1, Number(fCadFact[0]));

        if (fechaServer > fechaCadFact) {
          this.mensajeAlerta('info', 'Información', ' No puede firmar ningún documento electrónico porque esta ' +
            'caducada la fecha de validez de la firma electrónica');
          return;
        }
      } else {
        return;
      }

      for (const item of this.firmadocService.detalleVenGuiaRemisiones[this.indicador]) {
        if (item.DOC_SELECCIONADOG === true) {
          strTipoFac = 'GRE';

          try {
            await this.firmadocService.firmarDocumentos(item.ENCGRE_NUMERO, strTipoFac, 'VA', '', '',
              '', this.datosfe.COM_TIPOAMBFACTELEC);
            const data = await this.utilitariosService.procesoFirmaElectronica(item.ENCGRE_NUMERO, item.ENCGRE_FECHAEMISION1);
            if (data !== 'NOAUTORIZADO') {
              this.message.add({
                key: 'firmardoc',
                severity: 'warn',
                summary: 'Información',
                detail: data
              });
            }
          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('error', 'Error', '! Proceso guardar datos  no terminó satisfactoriamente.... ! ' + mensaje);
          }

        }
      }
    } else {
      return;
    }
  }

  async insertarEleTrnDocumentoCred() {
    let strTipoFac = '';
    let strFechaServidor = '';
    try {
      const rs = await this.firmadocService.erfechaDualinsert();
      strFechaServidor = rs[0].FECHA;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }

    if (this.datosfe.COM_TIPOAMBFACTELEC === '1' || this.datosfe.COM_TIPOAMBFACTELEC === '2') {
      // Si tiene fecha de caducidad de la firma electrónica ingresada
      if (this.datosfe.COM_FECHACADFIRMAELE !== null || this.datosfe.COM_FECHACADFIRMAELE !== '') {

        const fServer = strFechaServidor.split('/');
        const fechaServer = new Date(Number(fServer[2]), Number(fServer[1]) - 1, Number(fServer[0]));
        const fCadFact = String(this.datosfe.COM_FECHACADFIRMAELE).split('/');
        const fechaCadFact = new Date(Number(fCadFact[2]), Number(fCadFact[1]) - 1, Number(fCadFact[0]));

        if (fechaServer > fechaCadFact) {
          this.mensajeAlerta('info', 'Información', ' No puede firmar ningún documento electrónico porque esta ' +
            'caducada la fecha de validez de la firma electrónica');
          return;
        }
      } else {
        return;
      }

      for (const item of this.firmadocService.detalleVenCreditos[this.indicador]) {

        if (item.DOC_SELECCIONADOC === true) {
          strTipoFac = 'NC';

          try {
            await this.firmadocService.firmarDocumentos(item.ENCDVF_NUMERO, strTipoFac, 'VA', '', '',
              '', this.datosfe.COM_TIPOAMBFACTELEC);
            const data = await this.utilitariosService.procesoFirmaElectronica(item.ENCDVF_NUMERO, item.ENCDVF_FECHAEMISION1);
            if (data !== 'NOAUTORIZADO') {
              this.message.add({
                key: 'firmardoc',
                severity: 'warn',
                summary: 'Información',
                detail: data
              });
            }
          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('error', 'Error', '! Proceso guardar datos  no terminó satisfactoriamente.... ! ' + mensaje);
          }

        }
      }
    } else {
      return;
    }
  }

  async insertarEleTrnDocumentoDeb() {
    let strTipoFac = '';
    let strFechaServidor = '';
    try {
      const rs = await this.firmadocService.erfechaDualinsert();
      strFechaServidor = rs[0].FECHA;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }

    if (this.datosfe.COM_TIPOAMBFACTELEC === '1' || this.datosfe.COM_TIPOAMBFACTELEC === '2') {
      // Si tiene fecha de caducidad de la firma electrónica ingresada
      if (this.datosfe.COM_FECHACADFIRMAELE !== null || this.datosfe.COM_FECHACADFIRMAELE !== '') {

        const fServer = strFechaServidor.split('/');
        const fechaServer = new Date(Number(fServer[2]), Number(fServer[1]) - 1, Number(fServer[0]));
        const fCadFact = String(this.datosfe.COM_FECHACADFIRMAELE).split('/');
        const fechaCadFact = new Date(Number(fCadFact[2]), Number(fCadFact[1]) - 1, Number(fCadFact[0]));

        if (fechaServer > fechaCadFact) {
          this.mensajeAlerta('info', 'Información', ' No puede firmar ningún documento electrónico porque esta ' +
            'caducada la fecha de validez de la firma electrónica');
          return;
        }
      } else {
        return;
      }


      for (const item of this.firmadocService.detalleVenDebitos[this.indicador]) {
        if (item.DOC_SELECCIONADOD === true) {
          strTipoFac = 'ND';

          try {
            await this.firmadocService.firmarDocumentos(item.TRNCOBRO_NRODOC, strTipoFac, 'VA', '', '',
              '', this.datosfe.COM_TIPOAMBFACTELEC);
            const data = await this.utilitariosService.procesoFirmaElectronica(item.TRNCOBRO_NRODOC, item.TRNCOBRO_FECHATRN1);
            if (data !== 'NOAUTORIZADO') {
              this.message.add({
                key: 'firmardoc',
                severity: 'warn',
                summary: 'Información',
                detail: data
              });
            }
          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('error', 'Error', '! Proceso guardar datos  no terminó satisfactoriamente.... ! ' + mensaje);
          }

        }
      }
    } else {
      return;
    }
  }

  async insertarEleTrnDocumentoLiq() {
    let strTipoFac = '';
    let strFechaServidor = '';
    try {
      const rs = await this.firmadocService.erfechaDualinsert();
      strFechaServidor = rs[0].FECHA;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }

    if (this.datosfe.COM_TIPOAMBFACTELEC === '1' || this.datosfe.COM_TIPOAMBFACTELEC === '2') {
      // Si tiene fecha de caducidad de la firma electrónica ingresada
      if (this.datosfe.COM_FECHACADFIRMAELE !== null || this.datosfe.COM_FECHACADFIRMAELE !== '') {

        const fServer = strFechaServidor.split('/');
        const fechaServer = new Date(Number(fServer[2]), Number(fServer[1]) - 1, Number(fServer[0]));
        const fCadFact = String(this.datosfe.COM_FECHACADFIRMAELE).split('/');
        const fechaCadFact = new Date(Number(fCadFact[2]), Number(fCadFact[1]) - 1, Number(fCadFact[0]));

        if (fechaServer > fechaCadFact) {
          this.mensajeAlerta('info', 'Información', ' No puede firmar ningún documento electrónico porque esta ' +
            'caducada la fecha de validez de la firma electrónica');
          return;
        }
      } else {
        return;
      }


      for (const item of this.firmadocService.detalleVenLiquidacion[this.indicador]) {

        if (item.DOC_SELECCIONADOL === true) {
          strTipoFac = 'RET';

          try {
            await this.firmadocService.firmarDocumentos(item.ENCFACPRO_NUMERO, strTipoFac, 'VA', '', '',
              '', this.datosfe.COM_TIPOAMBFACTELEC);
            const data = await this.utilitariosService.procesoFirmaElectronica(item.ENCFACPRO_NUMERO, item.ENCFACPRO_FECHAEMISION1);
            if (data !== 'NOAUTORIZADO') {
              this.message.add({
                key: 'firmardoc',
                severity: 'warn',
                summary: 'Información',
                detail: data
              });
            }
          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('error', 'Error', '! Proceso guardar datos  no terminó satisfactoriamente.... ! ' + mensaje);
          }

        }
      }
    } else {
      return;
    }
  }

  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'firmardoc',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

}
