import { Component, OnInit, ViewChild } from '@angular/core';
import { AggridBanComponent } from '../../ban/aggrid-ban/aggrid-ban.component';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { ConcontabilizadocService } from '../conservicios/concontabilizadoc.service';
import { DatePipe } from '@angular/common';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import {Usuario} from '../../usuario';
import {ConComNotRec} from '../coninterfaces/concontabilizadoccom';
import {ConInvEntradaSalida, ConInvTransferencias} from '../coninterfaces/concontabilizadocinv';
import {ConVenFacturas, ConVenNotasCredito} from '../coninterfaces/concontabilizadocven';
import { ConNomRolPago } from '../coninterfaces/concontabilizadocnom';
import { ConProdOrdProd } from '../coninterfaces/concontabilizadocprod';
@Component({
  selector: 'app-concontabilizadoc',
  templateUrl: './concontabilizadoc.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class ConcontabilizadocComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  public frameworkComponents;
  rowStyle;
  largo: string;
  panel: string;
  nivel1 = 0;
  nivel2 = 0;
  hmpConfiguracion: any[];
  RANGFECHA: number;
  // Bancos
  // Movimientos de Bancos
  banMovBanFechaInicial: any;
  banMovBanFechaFinal: any;
  banMovBanTipo = 'T';
  defaultColConBanMovBan;
  banMovBanSeleccionarTodo: boolean;
  strTipoMovBan: any;
  cmbTipoMovBan: any[];
  // Movimientos de Caja
  banMovCajaFechaInicial: any;
  banMovCajaFechaFinal: any;
  banMovCajaTipo = 'T';
  defaultColConBanMovCaj;
  banMovCajaSeleccionarTodo: boolean;
  strTipoMovCaj: any;
  cmbTipoMovCaj: any[];
  
  // Compras
  // Facturas Proveedores comfacpro
  comFacProfechaInicial: any;
  comFacProfechaFinal: any;
  comFacProTipo = 'T';
  defaultColConFactPro;
  comFacProSeleccionarTodo: boolean;
  // Devoluciones de Compra comdevcom
  comDevComProfechaInicial: any;
  comDevComfechaFinal: any;
  comDevComTipo = 'T';
  defaultColComDevCom;
  comDevComSeleccionarTodo: boolean;
  // Notas de Recepcion
  comNotRecFechaInicial: any;
  comNotRecFechaFinal: any;
  comNotRecTipo = 'T';
  defaultColConNotRec;
  comNotRecSeleccionarTodo: boolean;
  
  // Inventario
  // Entradas y Salidas
  invEntradaSalidaFechaInicial: any;
  invEntradaSalidaFechaFinal: any;
  invEntradaSalidaTipo = 'T';
  defaultColConEntradaSalida;
  invEntradaSalidaSeleccionarTodo: boolean;
  strTipoEntradaSalida: any;
  cmbTipoEntradaSalida: any[];
  // Transferencias
  invTransferenciaFechaInicial: any;
  invTransferenciaFechaFinal: any;
  invTransferenciaTipo = 'T';
  defaultColConTranferencia;
  invTransferenciaSeleccionarTodo: boolean;
  // Tomas Fisicas
  invTomaFisicaFechaInicial: any;
  invTomaFisicaFechaFinal: any;
  invTomaFisicaTipo = 'T';
  defaultColConTomaFisica;
  invTomaFisicaSeleccionarTodo: boolean;
  // Ajustes
  strAnioAjustes: any;
  cmbAnioAjustes: any[];
  strMesAjustes: any;
  cmbMesAjustes: any[];
  strComentarioAjuste: '';
  ajustePositivoAjuste: any;
  ajusteNegativoAjuste: any;
  
  // Ventas
  // Facturas
  venFactFechaInicial: any;
  venFactFechaFinal: any;
  venFactTipo = 'T';
  defaultColConFactura;
  venFactSeleccionarTodo: boolean;
  strBodegaFactura: any;
  cmbBodegaFactura: any[];
  // Notas de Credito
  venNotaCreditoFechaInicial: any;
  venNotaCreditoFechaFinal: any;
  venNotaCreditoTipo = 'T';
  defaultColConNotaCredito;
  venNotaCreditoSeleccionarTodo: boolean;
  strBodegaNotaCredito: any;
  cmbBodegaNotaCredito: any[];
  // Guias de Remision
  venGuiaRemiFechaInicial: any;
  venGuiaRemiFechaFinal: any;
  venGuiaRemiTipo = 'T';
  defaultColConGuiaRemi;
  venGuiaRemiSeleccionarTodo: boolean;
  strBodegaGuiaRemi: any;
  cmbBodegaGuiaRemi: any[];
  
  // CXC
  // Comprobantes de Ingreso
  cxcComIngresoFechaInicial: any;
  cxcComIngresoFechaFinal: any;
  cxcComIngresoTipo = 'T';
  defaultColConComIngreso;
  cxcComIngresoSeleccionarTodo: boolean;
  strTipoDocComIngreso: any;
  cmbTipoDocComIngreso: any[];
  // Devoluciones de Anticipos
  cxcDevAntFechaInicial: any;
  cxcDevAntFechaFinal: any;
  cxcDevAntTipo = 'T';
  defaultColConDevAnt;
  cxcDevAntSeleccionarTodo: boolean;
  // Notas de Credito
  cxcNotaCredFechaInicial: any;
  cxcNotaCredFechaFinal: any;
  cxcNotaCredTipo = 'T';
  defaultColCXCNotaCred;
  cxcNotaCredSeleccionarTodo: boolean;
  // Notas de Debito
  cxcNotaDebitoFechaInicial: any;
  cxcNotaDebitoFechaFinal: any;
  cxcNotaDebitoTipo = 'T';
  defaultColCXCNotaDebito;
  cxcNotaDebitoSeleccionarTodo: boolean;
  // Retenciones
  cxcRetencionFechaInicial: any;
  cxcRetencionFechaFinal: any;
  cxcRetencionTipo = 'T';
  defaultColCXCRetencion;
  cxcRetencionSeleccionarTodo: boolean;
  
  // Cuentas por Pagar
  // Comprobantes de Egreso
  cxpEgresoFechaInicial: any;
  cxpEgresoFechaFinal: any;
  cxpEgresoTipo = 'T';
  defaultColCXPEgreso;
  cxpEgresoSeleccionarTodo: boolean;

  // Nómina
  // Roles de pago
  nomRolFechaInicial: any;
  nomRolFechaFinal: any;
  nomRolTipo = 'T';
  defaultColNomRol;
  nomRolSeleccionarTodo: boolean;

  // Producción
  // Ordenes de producción
  prodOrdProdFechaInicial: any;
  prodOrdProdFechaFinal: any;
  prodOrdProdTipo = 'T';
  defaultColProdOrdProd;
  prodOrdProdSeleccionarTodo: boolean;
  
  porcentaje = 0;
  
  rowSelection = 'multiple';
  
  columnDefsBanMovBan = [
    {
      headerName: 'Sel', field: 'CHECKCONT', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    {
      headerName: 'Banco', field: 'BAN_CODIGO', editable: false, width: 100
    },
    {
      headerName: 'Número', field: 'TRNBAN_NUMERO', editable: false, width: 100
    },
    {
      headerName: 'F. Emisión', field: 'TRNBAN_FECHA', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'F. Vence', field: 'TRNBAN_FECHAVENCE', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Concepto', field: 'TRNBAN_CONCEPTO', editable: false, width: 160
    },
    {
      headerName: 'No. Asiento', field: 'ASI_NRO', editable: false, width: 110
    },
    {
      headerName: 'Importe', field: 'TRNBAN_IMPORTE', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Beneficiario', field: 'TRNBAN_BENEFICIARIO', editable: false, width: 120
    },
  ];
  
  columnDefsBanMovCaj = [
    {
      headerName: 'Sel', field: 'CHECKCONT', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    {
      headerName: 'Caja', field: 'CAJ_CODIGO', editable: false, width: 100
    },
    {
      headerName: 'Número', field: 'TRNCAJ_NUMERO', editable: false, width: 100
    },
    {
      headerName: 'Comprobante', field: 'TRNCAJ_DOCNRO', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'F. Emisión', field: 'TRNCAJ_FECHA', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Concepto', field: 'TRNCAJ_DESCRIPCION', editable: false, width: 160
    },
    {
      headerName: 'No. Asiento', field: 'ASI_NRO', editable: false, width: 120
    },
    {
      headerName: 'Importe', field: 'TRNCAJ_IMPORTE', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    }
  ];
  
  columnDefsComFactPro = [
    {
      headerName: 'Sel', field: 'CHECKCONT', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    {
      headerName: 'Comprobante', field: 'ENCFACPRO_NUMERO', editable: false, width: 150
    },
    {
      headerName: 'Proveedor', field: 'PRO_NOMBRE', editable: false, width: 200
    },
    {
      headerName: 'F. Emisión', field: 'ENCFACPRO_FECHAEMISION', editable: false, width: 120, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'F. Vence', field: 'ENCFACPRO_FECHAVENCIMIENTO', editable: false, width: 120, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'No. Asiento', field: 'ASI_NRO', editable: false, width: 150
    },
    {
      headerName: 'Total', field: 'ENCFACPRO_TOTAL', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Comentario', field: 'ENCFACPRO_COMENTARIO', editable: false, width: 250
    },
  ];
  columnDefsComDevCom = [
    {
      headerName: 'Sel', field: 'CHECKCONT', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    {
      headerName: 'Comprobante', field: 'ENCDEV_NUMERO', editable: false, width: 150
    },
    {
      headerName: 'Proveedor', field: 'PRO_NOMBRE', editable: false, width: 200
    },
    {
      headerName: 'F. Emisión', field: 'ENCDEV_FECHAEMISION', editable: false, width: 120, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'F. Vence', field: 'ENCDEV_FECHACADUCIDAD', editable: false, width: 120, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'No. Asiento', field: 'ASI_NRO', editable: false, width: 150
    },
    {
      headerName: 'Total', field: 'ENCDEV_TOTAL', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Comentario', field: 'ENCDEV_COMENTARIO', editable: false, width: 250
    },
  ];
  
  columnDefsComNotRec = [
    {
      headerName: 'Sel', field: 'CHECKCONT', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    {
      headerName: 'Comprobante', field: 'NUMERO', editable: false, width: 150
    },
    {
      headerName: 'Proveedor', field: 'NOMBRE_PROVEEDOR', editable: false, width: 200
    },
    {
      headerName: 'F. Emisión', field: 'ENCNOTREC_FECHAEMISION', editable: false, width: 120, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'F. Vence', field: 'ENCNOTREC_FECHAENTREGA', editable: false, width: 120, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'No. Asiento', field: 'ASI_NRO', editable: false, width: 150
    },
    {
      headerName: 'Total', field: 'TOTAL', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Comentario', field: 'ENCNOTREC_OBSERVACION', editable: false, width: 250
    },
  ];
  
  columnDefsInvEnSal = [
    {
      headerName: 'Sel', field: 'CHECKCONT', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    {
      headerName: 'Número Doc.', field: 'NUMERO', editable: false, width: 110
    },
    {
      headerName: 'Fecha', field: 'FECHA', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Hora', field: 'HORA', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Tipo Doc', field: 'TIPO', editable: false, width: 50
    },
    {
      headerName: 'No. Comprobante', field: 'COMPROBANTE', editable: false, width: 110
    },
    {
      headerName: 'No. Asiento', field: 'ASI_NRO', editable: false, width: 110
    },
    {
      headerName: 'Comentario', field: 'COMENTARIO', editable: false, width: 150
    },
  ];
  
  columnDefsInvTransferencia = [
    {
      headerName: 'Sel', field: 'CHECKCONT', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    {
      headerName: 'Número Doc.', field: 'NUMERO', editable: false, width: 110
    },
    {
      headerName: 'No. Comprobante', field: 'COMPROBANTE', editable: false, width: 110
    },
    {
      headerName: 'F. Emisión', field: 'FECHA_EMISION', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'F. Transf', field: 'FECHA_TRANSFER', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Bod. Orig', field: 'BODEGA_ORIGEN', editable: false, width: 110
    },
    {
      headerName: 'Bod. Destino', field: 'BODEGA_DEST', editable: false, width: 110
    },
    {
      headerName: 'No. Asiento', field: 'ASI_NRO', editable: false, width: 110
    },
    {
      headerName: 'Comentario', field: 'COMENTARIO', editable: false, width: 150
    },
  ];
  
  columnDefsInvTomasFisicas = [
    {
      headerName: 'Sel', field: 'CHECKCONT', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    {
      headerName: 'Número Doc.', field: 'NUMERO', editable: false, width: 150
    },
    {
      headerName: 'Fecha', field: 'FECHA', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Hora', field: 'HORA', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'No. Asiento', field: 'ASI_NRO', editable: false, width: 110
    },
    {
      headerName: 'Comentario', field: 'COMENTARIO', editable: false, width: 150
    },
  ];
  
  columnDefsVenFacturas = [
    {
      headerName: 'Sel', field: 'CHECKCONT', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    {
      headerName: 'No. Factura', field: 'NUMERO', editable: false, width: 150
    },
    {
      headerName: 'F. Emisión', field: 'FECHA_EMISION', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'F. Vence', field: 'FECHA_VENCE', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'No. Asiento', field: 'ASI_NRO', editable: false, width: 110
    },
    {
      headerName: 'Comentario', field: 'ENCFAC_COMENTARIO', editable: false, width: 250
    },
    {
      headerName: 'Total', field: 'TOTAL', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
  ];
  
  columnDefsVenNotasCredito = [
    {
      headerName: 'Sel', field: 'CHECKCONT', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    {
      headerName: 'No. Factura', field: 'NUMERO', editable: false, width: 150
    },
    {
      headerName: 'F. Emisión', field: 'FECHA_EMISION', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'F. Vence', field: 'FECHA_VENCE', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'No. Asiento', field: 'ASI_NRO', editable: false, width: 110
    },
    {
      headerName: 'Comentario', field: 'ENCDVF_COMENTARIO', editable: false, width: 250
    },
    {
      headerName: 'Total', field: 'TOTAL', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
  ];
  
  columnDefsVenGuiaRemision = [
    {
      headerName: 'Sel', field: 'CHECKCONT', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    {
      headerName: 'No. Guia', field: 'ENCGRE_NUMERO', editable: false, width: 150
    },
    {
      headerName: 'F. Emisión', field: 'ENCGRE_FECHAEMISION', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'No. Asiento', field: 'ASI_NRO', editable: false, width: 110
    },
    {
      headerName: 'Comentario', field: 'ENCGRE_COMENTARIO', editable: false, width: 250
    },
    {
      headerName: 'Total', field: 'ENCGRE_TOTAL', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
  ];
  
  columnDefsCXCCompIngreso = [
    {
      headerName: 'Sel', field: 'CHECKCONT', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    {
      headerName: 'No. Cobro', field: 'TRNCOBRO_NRODOC', editable: false, width: 150
    },
    {
      headerName: 'Comprobante', field: 'TRNCOBRO_NROCOMPROBANTE', editable: false, width: 150
    },
    {
      headerName: 'F. Emisión', field: 'TRNCOBRO_FECHATRN', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Cliente', field: 'CLI_NOMBRE', editable: false, width: 200
    },
    
    {
      headerName: 'No. Asiento', field: 'ASI_NRO', editable: false, width: 110
    },
    {
      headerName: 'Total', field: 'TRNCOBRO_IMPORTE', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', editable: false, width: 250
    },
  
  ];
  
  columnDefsCXCDevAnticipo = [
    {
      headerName: 'Sel', field: 'CHECKCONT', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    {
      headerName: 'Comprobante', field: 'TRNCOBRO_NROCOMPROBANTE', editable: false, width: 150
    },
    {
      headerName: 'F. Emisión', field: 'TRNCOBRO_FECHATRN', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Cliente', field: 'CLI_NOMBRE', editable: false, width: 200
    },
    
    {
      headerName: 'No. Asiento', field: 'ASI_NRO', editable: false, width: 110
    },
    {
      headerName: 'Total', field: 'TRNCOBRO_IMPORTE', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
  
  ];
  
  columnDefsCXCNotasCredito = [
    {
      headerName: 'Sel', field: 'CHECKCONT', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    {
      headerName: 'No. Cobro', field: 'TRNCOBRO_NRODOC', editable: false, width: 150
    },
    {
      headerName: 'Comprobante', field: 'TRNCOBRO_NROCOMPROBANTE', editable: false, width: 150
    },
    {
      headerName: 'F. Emisión', field: 'TRNCOBRO_FECHATRN', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Cliente', field: 'CLI_NOMBRE', editable: false, width: 200
    },
    
    {
      headerName: 'No. Asiento', field: 'ASI_NRO', editable: false, width: 110
    },
    {
      headerName: 'Total', field: 'TRNCOBRO_IMPORTE', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
  
  ];
  
  columnDefsCXCNotasDebito = [
    {
      headerName: 'Sel', field: 'CHECKCONT', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    {
      headerName: 'No. Cobro', field: 'TRNCOBRO_NRODOC', editable: false, width: 150
    },
    {
      headerName: 'Comprobante', field: 'TRNCOBRO_NROCOMPROBANTE', editable: false, width: 150
    },
    {
      headerName: 'F. Emisión', field: 'TRNCOBRO_FECHATRN', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Cliente', field: 'CLI_NOMBRE', editable: false, width: 200
    },
    
    {
      headerName: 'No. Asiento', field: 'ASI_NRO', editable: false, width: 110
    },
    {
      headerName: 'Total', field: 'TRNCOBRO_IMPORTE', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
  
  ];
  
  columnDefsCXCRetencion = [
    {
      headerName: 'Sel', field: 'CHECKCONT', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    {
      headerName: 'Comprobante', field: 'TRNCOBRO_NROCOMPROBANTE', editable: false, width: 150
    },
    {
      headerName: 'F. Emisión', field: 'TRNCOBRO_FECHATRN', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Cliente', field: 'CLI_NOMBRE', editable: false, width: 200
    },
    
    {
      headerName: 'No. Asiento', field: 'ASI_NRO', editable: false, width: 110
    },
    {
      headerName: 'Total', field: 'TRNCOBRO_IMPORTE', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
  
  ];
  
  columnDefsCXPEgreso = [
    {
      headerName: 'Sel', field: 'CHECKCONT', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    {
      headerName: 'No. Ingreso', field: 'TRNCOBRO_NRODOC', editable: false, width: 150
    },
    {
      headerName: 'Comprobante', field: 'TRNCOBRO_NROCOMPROBANTE', editable: false, width: 150
    },
    {
      headerName: 'F. Emisión', field: 'TRNCOBRO_FECHATRN', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Cliente', field: 'PRO_NOMBRE', editable: false, width: 200
    },
    
    {
      headerName: 'No. Asiento', field: 'ASI_NRO', editable: false, width: 110
    },
    {
      headerName: 'Total', field: 'TRNCOBRO_IMPORTE', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
  
  ];

  columnDefsNomRol = [
    {
      headerName: 'Sel', field: 'CHECKCONT', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    {
      headerName: 'Periodo', field: 'PERIODO_CODIGO', editable: false, width: 100
    },
    {
      headerName: 'Fecha', field: 'PERIODO_FECFIN', editable: false, width: 100
    },
    {
      headerName: 'Tipo Rol', field: 'TIPROL_NOMBRE', editable: false, width: 220
    },
    {
      headerName: 'No. Asiento', field: 'ASI_NRO', editable: false, width: 150
    }
  
  ];

  columnDefsProdOrdProd = [
    {
      headerName: 'Sel', field: 'CHECKCONT', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    {
      headerName: 'Comprobante', field: 'ENCORDPRO_REFERENCIA', editable: false, width: 150
    },
    {
      headerName: 'Emisión', field: 'ENCORDPRO_FECHAEMISION', editable: false, width: 180
    },
    {
      headerName: 'Inicio', field: 'ENCORDPRO_FECHAINICIO', editable: false, width: 100
    },
    {
      headerName: 'Fin de Proceso', field: 'ENCORDPRO_FECHAFIN', editable: false, width: 100
    },
    {
      headerName: 'Ingreso', field: 'ENCORDPRO_FECHAENTRADA', editable: false, width: 100
    },
    {
      headerName: 'Número', field: 'ENCORDPRO_NUMERO', editable: false, width: 150
    },
    {
      headerName: 'Tipo', field: 'ENCORDPRO_TIPOPROD', editable: false, width: 80
    },
    {
      headerName: 'Estado', field: 'ENCORDPRO_ESTADO', editable: false, width: 80
    }
  
  ];
  
  
  
  constructor(
    public contabilizadocService: ConcontabilizadocService,
    private datePipe: DatePipe,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private auditoriagral: AuditoriagralService,
    public usuario: Usuario,
    private init: NuevoComponentService,
    private confirmationService: ConfirmationService
  
  ) {
    this.agTable();
    this.contabilizadocService.facturasProveedores = [];
    this.contabilizadocService.devolucionesProveedores = [];
    this.contabilizadocService.notasDeRecepcion = [];
    this.contabilizadocService.movimientosBancos = [];
    this.contabilizadocService.movimientosCaja = [];
    this.contabilizadocService.entradaSalida = [];
    this.contabilizadocService.transferencias = [];
    this.contabilizadocService.tomasFisicas = [];
    this.contabilizadocService.ventasFacturas = [];
    this.contabilizadocService.venNotasCredito = [];
    this.contabilizadocService.venGuiasRemision = [];
    this.contabilizadocService.cxcComprobIngreso = [];
    this.contabilizadocService.cxcDevAnticipo = [];
    this.contabilizadocService.cxcNotasCredito = [];
    this.contabilizadocService.cxcNotasDebito = [];
    this.contabilizadocService.cxcRetenciones = [];
    this.contabilizadocService.cxpEgresos = [];
    this.contabilizadocService.nomRolPagos = [];
    this.contabilizadocService.prodOrdProd = [];
  }
  
  ngOnInit(): void {
    this.panel = 'banMovBan';
    this.largo = '500';
    this.contabilizadocService.refrescarURL();
    
    this.banMovBanFechaInicial = new Date();
    this.banMovBanFechaFinal = new Date();
    this.banMovCajaFechaInicial = new Date();
    this.banMovCajaFechaFinal = new Date();
    
    this.comFacProfechaInicial = new Date();
    this.comFacProfechaFinal = new Date();
    this.comDevComProfechaInicial = new Date();
    this.comDevComfechaFinal = new Date();
    this.comNotRecFechaInicial = new Date();
    this.comNotRecFechaFinal = new Date();
    
    this.invEntradaSalidaFechaInicial = new Date();
    this.invEntradaSalidaFechaFinal = new Date();
    this.invTransferenciaFechaInicial = new Date();
    this.invTransferenciaFechaFinal = new Date();
    this.invTomaFisicaFechaInicial = new Date();
    this.invTomaFisicaFechaFinal = new Date();
    
    this.venFactFechaInicial = new Date();
    this.venFactFechaFinal = new Date();
    this.venNotaCreditoFechaInicial = new Date();
    this.venNotaCreditoFechaFinal = new Date();
    this.venGuiaRemiFechaInicial = new Date();
    this.venGuiaRemiFechaFinal = new Date();
    
    this.cxcComIngresoFechaInicial = new Date();
    this.cxcComIngresoFechaFinal = new Date();
    this.cxcDevAntFechaInicial = new Date();
    this.cxcDevAntFechaFinal = new Date();
    this.cxcNotaCredFechaInicial = new Date();
    this.cxcNotaCredFechaFinal = new Date();
    this.cxcNotaDebitoFechaInicial = new Date();
    this.cxcNotaDebitoFechaFinal = new Date();
    this.cxcRetencionFechaInicial = new Date();
    this.cxcRetencionFechaFinal = new Date();
    
    this.cxpEgresoFechaInicial = new Date();
    this.cxpEgresoFechaFinal = new Date();

    this.nomRolFechaInicial = new Date();
    this.nomRolFechaFinal = new Date();

    this.prodOrdProdFechaInicial = new Date();
    this.prodOrdProdFechaFinal = new Date();
    
    this.comFacProSeleccionarTodo = false;
    this.banMovBanSeleccionarTodo = false;
    this.nomRolSeleccionarTodo = false;
    this.prodOrdProdSeleccionarTodo = false;
    
    this.cmbTipoEntradaSalida = [];
    this.cmbTipoEntradaSalida = [{name: 'Entradas y Salidas', codigo: 0}, {name: 'Entradas', codigo: 1}, {name: 'Salidas', codigo: 2},
      {name: 'Entradas Producción', codigo: 3}, {name: 'Salidas Producción', codigo: 4}];
    this.strTipoEntradaSalida = this.cmbTipoEntradaSalida[0];
    
    this.cmbAnioAjustes = [];
    let intIndice = 0;
    for (intIndice = 2010; intIndice < 2040; intIndice ++) {
      this.cmbAnioAjustes.push({name: intIndice});
    }
    this.strAnioAjustes = this.cmbAnioAjustes[0];
    
    this.cmbMesAjustes = [];
    this.cmbMesAjustes = [{name: '01'}, {name: '02'}, {name: '03'}, {name: '04'}, {name: '05'}, {name: '06'}, {name: '07'}, {name: '08'}, {name: '09'},
      {name: '10'}, {name: '11'}, {name: '12'}];
    this.strMesAjustes = this.cmbMesAjustes[0];
    
    this.contabilizadocService.getDataCmbMovBancos().subscribe((tipoDoc) => {
      this.cmbTipoMovBan = [];
      this.cmbTipoMovBan.push({name: 'TODOS', codigo: 'todos'});
      for (const dato of tipoDoc) {
        this.cmbTipoMovBan.push({name: dato.REF_NOMBRE + ' ' + '(' + dato.REF_CODIGO + ')', codigo: dato.REF_CODIGO});
      }
      this.strTipoMovBan = this.cmbTipoMovBan[0];
    });
    
    this.contabilizadocService.getDataCmbMovCaja().subscribe((tipoDoc) => {
      this.cmbTipoMovCaj = [];
      this.cmbTipoMovCaj.push({name: 'TODOS', codigo: 'todos'});
      for (const dato of tipoDoc) {
        this.cmbTipoMovCaj.push({name: dato.REF_NOMBRE + ' ' + '(' + dato.REF_CODIGO + ')', codigo: dato.REF_CODIGO});
      }
      this.strTipoMovCaj = this.cmbTipoMovCaj[0];
    });
    
    this.contabilizadocService.consultarBodegaVentas().subscribe((bodega) => {
      this.cmbBodegaFactura = [];
      this.cmbBodegaNotaCredito = [];
      this.cmbBodegaGuiaRemi = [];
      
      for (const dato of bodega) {
        this.cmbBodegaFactura.push({name: dato.BOD_NOMBRE + ' ' + '(' + dato.BOD_CODIGO + ')', codigo: dato.BOD_CODIGO});
        this.cmbBodegaNotaCredito.push({name: dato.BOD_NOMBRE + ' ' + '(' + dato.BOD_CODIGO + ')', codigo: dato.BOD_CODIGO});
        this.cmbBodegaGuiaRemi.push({name: dato.BOD_NOMBRE + ' ' + '(' + dato.BOD_CODIGO + ')', codigo: dato.BOD_CODIGO});
      }
      
      this.cmbBodegaFactura.push({name: '', codigo: 'vacio'});
      this.cmbBodegaNotaCredito.push({name: '', codigo: 'vacio'});
      this.cmbBodegaGuiaRemi.push({name: '', codigo: 'vacio'});
      
      this.strBodegaFactura = this.cmbBodegaFactura[0];
      this.strBodegaNotaCredito = this.cmbBodegaNotaCredito[0];
      this.strBodegaGuiaRemi = this.cmbBodegaGuiaRemi[0];
    });
    
    this.cmbTipoDocComIngreso = [];
    this.cmbTipoDocComIngreso = [{name: 'TODOS'}, {name: 'ABONO'}, {name: 'CANCELACION'}, {name: 'ANTICIPO'}];
    this.strTipoDocComIngreso = this.cmbTipoDocComIngreso[0];
    this.auditoriagral.obtenerConfiguracion1().subscribe((conf) => {
      this.hmpConfiguracion = conf;
      this.RANGFECHA = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'RANGFECHA').CFG_VALOR1;
    })
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.data.ASI_NRO !== null) {
        return { background: '#ffff99' };
      } else {
        if (params.node.rowIndex % 2 !== 0) {
          return { background: '#D6EAF8' };
        }
      }
    };
    this.defaultColConFactPro = {
      editable: true,
      objeto: 'contfacpro'
    };
    this.defaultColComDevCom = {
      editable: true,
      objeto: 'contdevcom'
    };
    this.defaultColConBanMovBan = {
      editable: true,
      objeto: 'contbanmovban'
    };
    this.defaultColConBanMovCaj = {
      editable: true,
      objeto: 'contbanmovcaj'
    };
    this.defaultColConNotRec = {
      editable: true,
      objeto: 'contcomnotrec'
    };
    
    this.defaultColConEntradaSalida = {
      editable: true,
      objeto: 'continventsal'
    };
    
    this.defaultColConTranferencia = {
      editable: true,
      objeto: 'continvtranfer'
    };
    
    this.defaultColConTomaFisica = {
      editable: true,
      objeto: 'continvtomfis'
    };
    
    this.defaultColConFactura = {
      editable: true,
      objeto: 'contvenfactura'
    };
    
    this.defaultColConNotaCredito = {
      editable: true,
      objeto: 'contvennotacredito'
    };
    
    this.defaultColConGuiaRemi = {
      editable: true,
      objeto: 'contvenguiaremi'
    };
    
    this.defaultColConComIngreso = {
      editable: true,
      objeto: 'contcxccompingreso'
    };
    
    this.defaultColConDevAnt = {
      editable: true,
      objeto: 'contcxcdevanticipo'
    };
    
    this.defaultColCXCNotaCred = {
      editable: true,
      objeto: 'contcxcnotacredito'
    };
    
    this.defaultColCXCNotaDebito = {
      editable: true,
      objeto: 'contcxcnotadebito'
    };
    this.defaultColCXCRetencion = {
      editable: true,
      objeto: 'contcxcretencion'
    };
    
    this.defaultColCXPEgreso = {
      editable: true,
      objeto: 'contcxpegreso'
    };

    this.defaultColNomRol = {
      editable: true,
      objeto: 'contnomrol'
    };

    this.defaultColProdOrdProd = {
      editable: true,
      objeto: 'contprodordprod'
    };
  }
  
  manejarSenales(valor) {
    if (valor === 'Visto') {
      if (this.panel === '') {
        this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
      } else {
        this.contabilizar(this.panel);
      }
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
  async contabilizar(panel) {
    switch (panel) {
      case 'comfacpro': {
        if (this.contabilizadocService.facturasProveedores === null) {
          this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
        } else {
          if (this.contabilizadocService.facturasProveedores.length !== 0) {
            let numSel = this.contarSeleccionados(this.contabilizadocService.facturasProveedores);
            if (numSel === 0) {
              this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
            } else {
              let contador = 0;
              const totalSel = numSel;
              this.porcentaje = 0;
              let i = 0;
              for (const item of this.contabilizadocService.facturasProveedores) {
                if (item.CHECKCONT === true) {
                  this.porcentaje = this.porcentaje + Number(100 / totalSel);
                  try {
                    const contab = await this.contabilizadocService.contComFacPro(item.ENCFACPRO_NUMERO);
                    contador = contador + 1;
                    item.STATUS = 'ok';
                    item.CHECKCONT = false;
                    const asi =  await this.contabilizadocService.getAsiComFactPro(item.ENCFACPRO_NUMERO);
                    item.ASI_NRO = asi[0].ASI_NRO;
                    const indi = this.contabilizadocService.facturasProveedores.indexOf(item);
                    this.aggrid.refreshaggridindex(this.contabilizadocService.facturasProveedores, this.defaultColConFactPro.objeto, indi - 1);
                    this.aggrid.gridApi.setFocusedCell(indi - 1, 'CHECKCONT');
                    this.auditoriagral.registrarAuditoria('CONTABILIZARDOC', 'COM/FACPRO/' + String(item.ENCFACPRO_NUMERO), 'I',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
                  } catch (err) {
                    item.STATUS = 'fail';
                    numSel = numSel - 1;
                    this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible contabilizar el documento ' + item.ENCFACPRO_NUMERO);
                  }
                }
                i++;
                if (i === this.contabilizadocService.facturasProveedores.length && contador > 0) {
                  this.confirmationService.confirm({
                    message: 'Se han contabilizado ' + contador + ' documentos correctamente.',
                    header: 'Información',
                    icon: 'pi pi-check',
                    key: 'contdocConf',
                    acceptLabel: 'Aceptar',
                    rejectVisible: false,
                    accept: () => {
                      this.porcentaje = 0;
                      this.comFacProSeleccionarTodo = false;
                    }, reject: () => {
                    }
                  });
                }
              }
            }
          } else {
            this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
          }
        }
        break;
      }
      
      case 'comdevcom': {
        if (this.contabilizadocService.devolucionesProveedores === null) {
          this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
        } else {
          if (this.contabilizadocService.devolucionesProveedores.length !== 0) {
            let numSel = this.contarSeleccionados(this.contabilizadocService.devolucionesProveedores);
            if (numSel === 0) {
              this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
            } else {
              let contador = 0;
              const totalSel = numSel;
              this.porcentaje = 0;
              let i = 0;
              for (const item of this.contabilizadocService.devolucionesProveedores) {
                if (item.CHECKCONT === true) {
                  this.porcentaje = this.porcentaje + Number(100 / totalSel);
                  try {
                    let asi;
                    const reg = await this.contabilizadocService.erEncnotrecNumero(item.ENCDEV_NUMERO);
                    if (reg === null) {
                      await this.contabilizadocService.generarAsientoContableDevCom(item.ENCDEV_NUMERO);
                      asi = await this.contabilizadocService.erAsinroEncDev(item.ENCDEV_NUMERO);
                    } else {
                      await this.contabilizadocService.generarAsientoContableConsig(item.ENCDEV_NUMERO);
                      asi = await this.contabilizadocService.erAsinroEncNotRec(item.ENCDEV_NUMERO);
                    }
                    
                    item.ASI_NRO = asi[0].ASI_NRO;
                    contador = contador + 1;
                    item.STATUS = 'ok';
                    item.CHECKCONT = false;
                    const indi = this.contabilizadocService.devolucionesProveedores.indexOf(item);
                    this.aggrid.refreshaggridindex(this.contabilizadocService.devolucionesProveedores, this.defaultColComDevCom.objeto, indi - 1);
                    this.aggrid.gridApi.setFocusedCell(indi - 1, 'CHECKCONT');
                    this.auditoriagral.registrarAuditoria('CONTABILIZARDOC', 'COM/DEVCOM/' + String(item.ENCDEV_NUMERO), 'I',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
                  } catch (err) {
                    console.log(err.error);
                    item.STATUS = 'fail';
                    numSel = numSel - 1;
                    this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible contabilizar el documento ' + item.ENCDEV_NUMERO);
                  }
                }
                i++;
                if (i === this.contabilizadocService.devolucionesProveedores.length && contador > 0) {
                  this.confirmationService.confirm({
                    message: 'Se han contabilizado ' + contador + ' documentos correctamente.',
                    header: 'Información',
                    icon: 'pi pi-check',
                    key: 'contdocConf',
                    acceptLabel: 'Aceptar',
                    rejectVisible: false,
                    accept: () => {
                      this.porcentaje = 0;
                      this.comDevComSeleccionarTodo = false;
                    }, reject: () => {
                    }
                  });
                }
              }
            }
          } else {
            this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
          }
        }
        break;
      }
      
      case 'comnotrec' : {
        if (this.contabilizadocService.notasDeRecepcion === null) {
          this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
        } else {
          if (this.contabilizadocService.notasDeRecepcion.length !== 0) {
            let numSel = this.contarSeleccionados(this.contabilizadocService.notasDeRecepcion);
            if (numSel === 0) {
              this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
            } else {
              let contador = 0;
              const totalSel = numSel;
              this.porcentaje = 0;
              let i = 0;
              for (const item of this.contabilizadocService.notasDeRecepcion) {
                if (item.CHECKCONT === true) {
                  this.porcentaje = this.porcentaje + Number(100 / totalSel);
                  try {
                    const data = await this.contabilizadocService.generarAsientoContableNotRec(item.NUMERO);
                    const asi = await this.contabilizadocService.erAsinroEncNotRec(item.NUMERO);
                    item.ASI_NRO = asi[0].ASI_NRO;
                    contador = contador + 1;
                    item.STATUS = 'ok';
                    item.CHECKCONT = false;
                    const indi = this.contabilizadocService.notasDeRecepcion.indexOf(item);
                    this.aggrid.refreshaggridindex(this.contabilizadocService.notasDeRecepcion, this.defaultColConNotRec.objeto, indi - 1);
                    this.aggrid.gridApi.setFocusedCell(indi - 1, 'CHECKCONT');
                    this.auditoriagral.registrarAuditoria('CONTABILIZARDOC', 'COM/NOTREC/' + String(item.NUMERO), 'I',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
                  } catch (err) {
                    item.STATUS = 'fail';
                    numSel = numSel - 1;
                    this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible contabilizar el documento ' + item.NUMERO);
                  }
                }
                i++;
                if (i === this.contabilizadocService.notasDeRecepcion.length && contador > 0) {
                  this.confirmationService.confirm({
                    message: 'Se han contabilizado ' + contador + ' documentos correctamente.',
                    header: 'Información',
                    icon: 'pi pi-check',
                    key: 'contdocConf',
                    acceptLabel: 'Aceptar',
                    rejectVisible: false,
                    accept: () => {
                      this.comNotRecSeleccionarTodo = false;
                      this.porcentaje = 0;
                    }, reject: () => {
                    }
                  });
                }
              }
            }
          } else {
            this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
          }
        }
        break;
      }
      
      case 'banMovBan': {
        if (this.contabilizadocService.movimientosBancos === null) {
          this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
        } else {
          if (this.contabilizadocService.movimientosBancos.length !== 0) {
            let numSel = this.contarSeleccionados(this.contabilizadocService.movimientosBancos);
            if (numSel === 0) {
              this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
            } else {
              let contador = 0;
              const totalSel = numSel;
              this.porcentaje = 0;
              let i = 0;
              for (const item of this.contabilizadocService.movimientosBancos) {
                if (item.CHECKCONT === true) {
                  this.porcentaje = this.porcentaje + Number(100 / totalSel);
                  try {
                    const data = await  this.contabilizadocService.generarAsientoMovBan(item.BAN_CODIGO, item.TRNBAN_TIPO,
                      item.TRNBAN_NUMERO, item.TRNBAN_FECHA, item.TRNBAN_ORIGEN, '01', this.usuario.identificacion);
                    contador = contador + 1;
                    item.STATUS = 'ok';
                    item.ASI_NRO = data[0][':B1'];
                    item.CHECKCONT = false;
                    const indi = this.contabilizadocService.movimientosBancos.indexOf(item);
                    this.aggrid.refreshaggridindex(this.contabilizadocService.movimientosBancos, this.defaultColConBanMovBan.objeto, indi);
                    this.aggrid.gridApi.setFocusedCell(indi, 'CHECKCONT');
                    this.auditoriagral.registrarAuditoria('CONTABILIZARDOC', 'BAN/MOVBAN/' + String(item.TRNBAN_NUMERO), 'I',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
                  } catch (err) {
                    item.STATUS = 'fail';
                    numSel = numSel - 1;
                    this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible contabilizar el documento ' + item.TRNBAN_NUMERO);
                  }
                }
                i++;
                if (i === this.contabilizadocService.movimientosBancos.length && contador > 0) {
                  this.confirmationService.confirm({
                    message: 'Se han contabilizado ' + contador + ' documentos correctamente.',
                    header: 'Información',
                    icon: 'pi pi-check',
                    key: 'contdocConf',
                    acceptLabel: 'Aceptar',
                    rejectVisible: false,
                    accept: () => {
                      this.banMovBanSeleccionarTodo = false;
                      this.porcentaje = 0;
                    }, reject: () => {
                    }
                  });
                }
              }
            }
          } else {
            this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
          }
          
        }
        
        break;
      }
      case 'banMovCaja' : {
        if (this.contabilizadocService.movimientosCaja === null) {
          this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
        } else {
          if (this.contabilizadocService.movimientosCaja.length !== 0) {
            let numSel = this.contarSeleccionados(this.contabilizadocService.movimientosCaja);
            if (numSel === 0) {
              this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
            } else {
              let contador = 0;
              const totalSel = numSel;
              this.porcentaje = 0;
              let i = 0;
              for (const item of this.contabilizadocService.movimientosCaja) {
                if (item.CHECKCONT === true) {
                  this.porcentaje = this.porcentaje + Number(100 / totalSel);
                  try {
                    const data = await this.contabilizadocService.generarAsientoMovCaja(item.TRNCAJ_TIPO, item.TRNCAJ_NUMERO, item.CAJ_CODIGO,
                      item.TRNCAJ_DOCNRO, '01', this.usuario.identificacion);
                    contador = contador + 1;
                    item.STATUS = 'ok';
                    item.ASI_NRO = data[0][':B1'];
                    item.CHECKCONT = false;
                    const indi = this.contabilizadocService.movimientosCaja.indexOf(item);
                    this.aggrid.refreshaggridindex(this.contabilizadocService.movimientosCaja, this.defaultColConBanMovCaj.objeto, indi - 1);
                    this.aggrid.gridApi.setFocusedCell(indi - 1, 'CHECKCONT');
                    this.auditoriagral.registrarAuditoria('CONTABILIZARDOC', 'BAN/MOVCAJA/' + String(item.TRNCAJ_NUMERO), 'I',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
                  } catch (err) {
                    item.STATUS = 'fail';
                    numSel = numSel - 1;
                    this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible contabilizar el documento ' + item.TRNCAJ_NUMERO);
                  }
                }
                i++;
                if (i === this.contabilizadocService.movimientosCaja.length && contador > 0) {
                  this.confirmationService.confirm({
                    message: 'Se han contabilizado ' + contador + ' documentos correctamente.',
                    header: 'Información',
                    icon: 'pi pi-check',
                    key: 'contdocConf',
                    acceptLabel: 'Aceptar',
                    rejectVisible: false,
                    accept: () => {
                      this.porcentaje = 0;
                      this.banMovCajaSeleccionarTodo = false;
                    }, reject: () => {
                    }
                  });
                }
              }
            }
          } else {
            this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
          }
        }
        break;
      }
      
      case 'invEntradaSalida' : {
        if (this.contabilizadocService.entradaSalida === null) {
          this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
        } else {
          if (this.contabilizadocService.entradaSalida.length !== 0) {
            let numSel = this.contarSeleccionados(this.contabilizadocService.entradaSalida);
            if (numSel === 0) {
              this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
            } else {
              let contador = 0;
              const totalSel = numSel;
              this.porcentaje = 0;
              let i = 0;
              for (const item of this.contabilizadocService.entradaSalida) {
                if (item.CHECKCONT === true) {
                  this.porcentaje = this.porcentaje + Number(100 / totalSel);
                  let tipDiaCodigo = '';
                  let proceso = '';
                  if (this.contabilizadocService.strTrnTipoESProd === 'PROD') {
                    if (item.TIPO === 'EN') {
                      tipDiaCodigo = 'OPT';
                    } else {
                      tipDiaCodigo = 'OPP';
                    }
                  } else {
                    if (item.TIPO === 'EN') {
                      tipDiaCodigo = 'AE';
                    } else {
                      tipDiaCodigo = 'AS';
                    }
                  }
                  
                  if (this.contabilizadocService.strTrnTipoESProd === 'PROD') {
                    proceso = 'PROD_SP_GENERAR_ASIENTO_ES';
                  } else {
                    proceso = 'INV_SP_GENERAR_ASIENTO_ES';
                  }
                  
                  try {
                    const data = await  this.contabilizadocService.generarAsientoEntradaSalida(item.NUMERO, item.TIPO, tipDiaCodigo, proceso);
                    const asiNro = await this.contabilizadocService.consultarAsiEntradaSalida(item.NUMERO);
                    item.ASI_NRO = asiNro[0].ASI_NRO;
                    contador = contador + 1;
                    item.STATUS = 'ok';
                    item.CHECKCONT = false;
                    const indi = this.contabilizadocService.entradaSalida.indexOf(item);
                    this.aggrid.refreshaggridindex(this.contabilizadocService.entradaSalida, this.defaultColConEntradaSalida.objeto, indi - 1);
                    this.aggrid.gridApi.setFocusedCell(indi - 1, 'CHECKCONT');
                    this.auditoriagral.registrarAuditoria('CONTABILIZARDOC', 'INV/ES/' + String(item.NUMERO), 'I',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
                  } catch (err) {
                    item.STATUS = 'fail';
                    numSel = numSel - 1;
                    this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible contabilizar el documento ' + item.NUMERO);
                  }
                }
                i++;
                if (i === this.contabilizadocService.entradaSalida.length && contador > 0) {
                  this.confirmationService.confirm({
                    message: 'Se han contabilizado ' + contador + ' documentos correctamente.',
                    header: 'Información',
                    icon: 'pi pi-check',
                    key: 'contdocConf',
                    acceptLabel: 'Aceptar',
                    rejectVisible: false,
                    accept: () => {
                      this.invEntradaSalidaSeleccionarTodo = false;
                      this.porcentaje = 0;
                    }, reject: () => {
                    }
                  });
                }
              }
            }
          } else {
            this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
          }
        }
        break;
      }
      
      case 'invTransferencia': {
        if (this.contabilizadocService.transferencias === null) {
          this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
        } else {
          if (this.contabilizadocService.transferencias.length !== 0) {
            let numSel = this.contarSeleccionados(this.contabilizadocService.transferencias);
            if (numSel === 0) {
              this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
            } else {
              let contador = 0;
              const totalSel = numSel;
              this.porcentaje = 0;
              let i = 0;
              for (const item of this.contabilizadocService.transferencias) {
                if (item.CHECKCONT === true) {
                  this.porcentaje = this.porcentaje + Number(100 / totalSel);
                  try {
                    const data = await  this.contabilizadocService.generarAsientoTransferencia(item.NUMERO);
                    const asiNro = await this.contabilizadocService.consultarAsiTransferencia(item.NUMERO);
                    item.ASI_NRO = asiNro[0].ASI_NRO;
                    contador = contador + 1;
                    item.STATUS = 'ok';
                    item.CHECKCONT = false;
                    const indi = this.contabilizadocService.transferencias.indexOf(item);
                    this.aggrid.refreshaggridindex(this.contabilizadocService.transferencias, this.defaultColConTranferencia.objeto, indi - 1);
                    this.aggrid.gridApi.setFocusedCell(indi - 1, 'CHECKCONT');
                    this.auditoriagral.registrarAuditoria('CONTABILIZARDOC', 'INV/TRANSFERENCIA/' + String(item.NUMERO), 'I',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
                  } catch (err) {
                    item.STATUS = 'fail';
                    numSel = numSel - 1;
                    this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible contabilizar el documento ' + item.NUMERO);
                  }
                }
                i++;
                if (i === this.contabilizadocService.transferencias.length && contador > 0) {
                  this.confirmationService.confirm({
                    message: 'Se han contabilizado ' + contador + ' documentos correctamente.',
                    header: 'Información',
                    icon: 'pi pi-check',
                    key: 'contdocConf',
                    acceptLabel: 'Aceptar',
                    rejectVisible: false,
                    accept: () => {
                      this.invTransferenciaSeleccionarTodo = false;
                      this.porcentaje = 0;
                    }, reject: () => {
                    }
                  });
                }
              }
            }
          } else {
            this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
          }
        }
        break;
      }
      
      case 'invTomaFisica': {
        if (this.contabilizadocService.tomasFisicas === null) {
          this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
        } else {
          if (this.contabilizadocService.tomasFisicas.length !== 0) {
            const numSel = this.contarSeleccionados(this.contabilizadocService.tomasFisicas);
            if (numSel === 0) {
              this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
            } else {
              let contador = 0;
              const totalSel = numSel;
              this.porcentaje = 0;
              let i = 0;
              for (const item of this.contabilizadocService.tomasFisicas) {
                if (item.CHECKCONT === true) {
                  this.porcentaje = this.porcentaje + Number(100 / totalSel);
                  let asiNro;
                  try {
                    const data = await  this.contabilizadocService.generarAsientoTomaFisica(item.NUMERO);
                    asiNro = await this.contabilizadocService.consultarAsiTomaFisica(item.NUMERO);
                    
                    if (asiNro[0].ASI_NRO !== null) {
                      contador = contador + 1;
                      item.STATUS = 'ok';
                      item.CHECKCONT = false;
                      item.ASI_NRO = asiNro[0].ASI_NRO;
                      const indi = this.contabilizadocService.tomasFisicas.indexOf(item);
                      this.aggrid.refreshaggridindex(this.contabilizadocService.tomasFisicas, this.defaultColConTomaFisica.objeto, indi - 1);
                      this.aggrid.gridApi.setFocusedCell(indi - 1, 'CHECKCONT');
                      this.auditoriagral.registrarAuditoria('CONTABILIZARDOC', 'INV/TOMAFISICA/' + String(item.NUMERO), 'I',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
                    } else {
                      this.mensajeAlerta('error', 'Error', 'Ha sido imposible contabilizar el documento ' + item.NUMERO +
                        '\n El asiento no esta cuadrado');
                    }
                  } catch (err) {
                    this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible contabilizar el documento ' + item.NUMERO);
                  }
                  
                }
                i++;
                if (i === this.contabilizadocService.tomasFisicas.length && contador > 0) {
                  this.confirmationService.confirm({
                    message: 'Se han contabilizado ' + contador + ' documentos correctamente.',
                    header: 'Información',
                    icon: 'pi pi-check',
                    key: 'contdocConf',
                    acceptLabel: 'Aceptar',
                    rejectVisible: false,
                    accept: () => {
                      this.invTomaFisicaSeleccionarTodo = false;
                      this.porcentaje = 0;
                    }, reject: () => {
                    }
                  });
                }
              }
            }
          } else {
            this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar. ');
          }
        }
        break;
      }
      
      case 'venFactura': {
        if (this.contabilizadocService.ventasFacturas === null) {
          this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
        } else {
          if (this.contabilizadocService.ventasFacturas.length !== 0) {
            let numSel = this.contarSeleccionados(this.contabilizadocService.ventasFacturas);
            if (numSel === 0) {
              this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
            } else {
              let contador = 0;
              const totalSel = numSel;
              this.porcentaje = 0;
              let i = 0;
              for (const item of this.contabilizadocService.ventasFacturas) {
                if (item.CHECKCONT === true) {
                  this.porcentaje = this.porcentaje + Number(100 / totalSel);
                  const fp = item.FORMA_PAGO;
                  if (fp === 'T') {
                    try {
                      const data = await this.contabilizadocService.generarAsientoVenFactSinCXC(item.NUMERO);
                      const asiNro = await this.contabilizadocService.erAsinroVenencfac(item.NUMERO);
                      contador = contador + 1;
                      item.STATUS = 'ok';
                      item.CHECKCONT = false;
                      item.ASI_NRO = asiNro[0].ASI_NRO;
                      const indi = this.contabilizadocService.ventasFacturas.indexOf(item);
                      this.aggrid.refreshaggridindex(this.contabilizadocService.ventasFacturas, this.defaultColConFactura.objeto, indi - 1);
                      this.aggrid.gridApi.setFocusedCell(indi - 1, 'CHECKCONT');
                      this.auditoriagral.registrarAuditoria('CONTABILIZARDOC', 'VEN/FACTURA/' + String(item.NUMERO), 'I',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
                    } catch (err) {
                      item.STATUS = 'fail';
                      numSel = numSel - 1;
                      this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible contabilizar el documento ' + item.NUMERO);
                    }
                    
                  } else  {
                    try {
                      const data = await this.contabilizadocService.generarAsientoVenFact(item.NUMERO);
                      const asiNro = await this.contabilizadocService.erAsinroVenencfac(item.NUMERO);
                      contador = contador + 1;
                      item.STATUS = 'ok';
                      item.CHECKCONT = false;
                      item.ASI_NRO = asiNro[0].ASI_NRO;
                      const indi = this.contabilizadocService.ventasFacturas.indexOf(item);
                      this.aggrid.refreshaggridindex(this.contabilizadocService.ventasFacturas, this.defaultColConFactura.objeto, indi - 1);
                      this.aggrid.gridApi.setFocusedCell(indi - 1, 'CHECKCONT');
                    } catch (err) {
                      item.STATUS = 'fail';
                      numSel = numSel - 1;
                      this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible contabilizar el documento ' + item.NUMERO);
                    }
                  }
                }
                i++;
                if (i === this.contabilizadocService.ventasFacturas.length && contador > 0) {
                  this.confirmationService.confirm({
                    message: 'Se han contabilizado ' + contador + ' documentos correctamente.',
                    header: 'Información',
                    icon: 'pi pi-check',
                    key: 'contdocConf',
                    acceptLabel: 'Aceptar',
                    rejectVisible: false,
                    accept: () => {
                      this.venFactSeleccionarTodo = false;
                      this.porcentaje = 0;
                    }, reject: () => {
                    }
                  });
                }
              }
            }
          } else {
            this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar. ');
          }
        }
        break;
      }
      
      case 'venNotaCredito': {
        if (this.contabilizadocService.venNotasCredito === null) {
          this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
        } else {
          if (this.contabilizadocService.venNotasCredito.length !== 0) {
            let numSel = this.contarSeleccionados(this.contabilizadocService.venNotasCredito);
            if (numSel === 0) {
              this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
            } else {
              let contador = 0;
              const totalSel = numSel;
              this.porcentaje = 0;
              let i = 0;
              for (const item of this.contabilizadocService.venNotasCredito) {
                if (item.CHECKCONT === true) {
                  this.porcentaje = this.porcentaje + Number(100 / totalSel);
                  try {
                    const data = await this.contabilizadocService.generarAsientoVenNotaCred(item.NUMERO);
                    const asiNro = await this.contabilizadocService.erAsinroVendvf(item.NUMERO);
                    contador = contador + 1;
                    item.STATUS = 'ok';
                    item.CHECKCONT = false;
                    item.ASI_NRO = asiNro[0].ASI_NRO;
                    const indi = this.contabilizadocService.venNotasCredito.indexOf(item);
                    this.aggrid.refreshaggridindex(this.contabilizadocService.venNotasCredito, this.defaultColConNotaCredito.objeto, indi - 1);
                    this.aggrid.gridApi.setFocusedCell(indi - 1, 'CHECKCONT');
                    this.auditoriagral.registrarAuditoria('CONTABILIZARDOC', 'VEN/NOTACREDITO/' + String(item.NUMERO), 'I',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
                  } catch (err) {
                    item.STATUS = 'fail';
                    numSel = numSel - 1;
                    this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible contabilizar el documento ' + item.NUMERO);
                  }
                }
                i++;
                if (i === this.contabilizadocService.venNotasCredito.length && contador > 0) {
                  this.confirmationService.confirm({
                    message: 'Se han contabilizado ' + contador + ' documentos correctamente.',
                    header: 'Información',
                    icon: 'pi pi-check',
                    key: 'contdocConf',
                    acceptLabel: 'Aceptar',
                    rejectVisible: false,
                    accept: () => {
                      this.venNotaCreditoSeleccionarTodo = false;
                      this.porcentaje = 0;
                    }, reject: () => {
                    }
                  });
                }
              }
            }
          } else {
            this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar. ');
          }
        }
        break;
      }
      
      case 'venGuiaRemision': {
        if (this.contabilizadocService.venGuiasRemision === null) {
          this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
        } else {
          if (this.contabilizadocService.venGuiasRemision.length !== 0) {
            let numSel = this.contarSeleccionados(this.contabilizadocService.venGuiasRemision);
            if (numSel === 0) {
              this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
            } else {
              let contador = 0;
              const totalSel = numSel;
              this.porcentaje = 0;
              let i = 0;
              for (const item of this.contabilizadocService.venGuiasRemision) {
                if (item.CHECKCONT === true) {
                  this.porcentaje = this.porcentaje + Number(100 / totalSel);
                  try {
                    const data = await this.contabilizadocService.generarAsientoGuiaRemi(item.ENCGRE_CODIGO);
                    const asiNro = await this.contabilizadocService.erAsinroVenencgre(item.ENCGRE_CODIGO);
                    contador = contador + 1;
                    item.STATUS = 'ok';
                    item.CHECKCONT = false;
                    item.ASI_NRO = asiNro[0].ASI_NRO;
                    const indi = this.contabilizadocService.venGuiasRemision.indexOf(item);
                    this.aggrid.refreshaggridindex(this.contabilizadocService.venGuiasRemision, this.defaultColConGuiaRemi.objeto, indi - 1);
                    this.aggrid.gridApi.setFocusedCell(indi - 1, 'CHECKCONT');
                    this.auditoriagral.registrarAuditoria('CONTABILIZARDOC', 'VEN/GUIAREM/' + String(item.ENCGRE_CODIGO), 'I',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
                  } catch (err) {
                    item.STATUS = 'fail';
                    numSel = numSel - 1;
                    this.mensajeAlerta('error', 'ERROR', 'Ha sido imposible contabilizar el documento ' + item.ENCGRE_NUMERO);
                  }
                }
                i++;
                if (i === this.contabilizadocService.venGuiasRemision.length && contador > 0) {
                  this.confirmationService.confirm({
                    message: 'Se han contabilizado ' + contador + ' documentos correctamente.',
                    header: 'Información',
                    icon: 'pi pi-check',
                    key: 'contdocConf',
                    acceptLabel: 'Aceptar',
                    rejectVisible: false,
                    accept: () => {
                      this.venGuiaRemiSeleccionarTodo = false;
                      this.porcentaje = 0;
                    }, reject: () => {
                    }
                  });
                }
              }
            }
          } else {
            this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar. ');
          }
        }
        break;
      }
      case 'cxcComIngreso': {
        if (this.contabilizadocService.cxcComprobIngreso === null) {
          this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
        } else {
          if (this.contabilizadocService.cxcComprobIngreso.length !== 0) {
            let numSel = this.contarSeleccionados(this.contabilizadocService.cxcComprobIngreso);
            if (numSel === 0) {
              this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
            } else {
              let contador = 0;
              const totalSel = numSel;
              this.porcentaje = 0;
              let i = 0;
              for (const item of this.contabilizadocService.cxcComprobIngreso) {
                if (item.CHECKCONT === true) {
                  this.porcentaje = this.porcentaje + Number(100 / totalSel);
                  const tipo = item.TRNCOBRO_TIPODOC;
                  const conciliado = await this.contabilizadocService.verificarDocumentosConciliados(item.TRNCOBRO_NRODOC, 'CXC');
                  if (Object.keys(conciliado).length > 0) {
                    if (conciliado[0].TRNBAN_CONCILIA === 1) {
                      numSel = numSel - 1;
                      this.mensajeAlerta('info', 'Información', 'No ha sido posible contabilizar el documento ' + item.TRNCOBRO_NRODOC +
                        ' ya que contiene transacciones bancarias conciliadas');
                    } else {
                      if (tipo === 'AB' || tipo === 'CA') {
                        try {
                          const genAsi = await this.contabilizadocService.generarAsientoComIng(item.TRNCOBRO_NRODOC, item.TRNCOBRO_NROCOMPROBANTE);
                          const asiNro = await this.contabilizadocService.erAsinrotrncobro(item.TRNCOBRO_NRODOC,
                            item.TRNCOBRO_NROCOMPROBANTE);
                          
                          if (asiNro[0].ASI_NRO !== null) {
                            contador = contador + 1;
                            item.STATUS = 'ok';
                            item.CHECKCONT = false;
                            item.ASI_NRO = asiNro[0].ASI_NRO;
                            const indi = this.contabilizadocService.cxcComprobIngreso.indexOf(item);
                            this.aggrid.refreshaggridindex(this.contabilizadocService.cxcComprobIngreso, this.defaultColConComIngreso.objeto, indi - 1);
                            this.aggrid.gridApi.setFocusedCell(indi - 1, 'CHECKCONT');
                            this.auditoriagral.registrarAuditoria('CONTABILIZARDOC', 'CXC/COMINGRESO/' + String(item.TRNCOBRO_NRODOC), 'I',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
                          } else {
                            numSel = numSel - 1;
                            this.mensajeAlerta('error', 'Error', 'Ha sido imposible contabilizar el documento ' + item.TRNCOBRO_NRODOC );
                          }
                        } catch (err) {
                          item.STATUS = 'fail';
                          numSel = numSel - 1;
                          this.msgDescuadre(item.TRNCOBRO_NRODOC);
                        }
                      } else if (tipo === 'ANC') {
                        try {
                          const genAsi = await this.contabilizadocService.generarAsientoCXCAnticip(item.TRNCOBRO_NRODOC, item.CLI_CODIGO);
                          const asiNro = await this.contabilizadocService.erAsinrotrncobro(item.TRNCOBRO_NRODOC, item.TRNCOBRO_NROCOMPROBANTE);
                          if (asiNro[0].ASI_NRO !== null) {
                            contador = contador + 1;
                            item.STATUS = 'ok';
                            item.CHECKCONT = false;
                            item.ASI_NRO = asiNro[0].ASI_NRO;
                            const indi = this.contabilizadocService.cxcComprobIngreso.indexOf(item);
                            this.aggrid.refreshaggridindex(this.contabilizadocService.cxcComprobIngreso, this.defaultColConComIngreso.objeto, indi - 1);
                            this.aggrid.gridApi.setFocusedCell(indi - 1, 'CHECKCONT');
                            this.auditoriagral.registrarAuditoria('CONTABILIZARDOC', 'CXC/COMINGRESO/' + String(item.TRNCOBRO_NRODOC), 'I',
                           this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
                          } else {
                            numSel = numSel - 1;
                            this.mensajeAlerta('error', 'Error', 'Ha sido imposible contabilizar el documento ' + item.TRNCOBRO_NRODOC);
                          }
                        } catch (err) {
                          item.STATUS = 'fail';
                          numSel = numSel - 1;
                          this.msgDescuadre(item.TRNCOBRO_NRODOC);
                        }
                      }
                    }
                  } else {
                    if (tipo === 'AB' || tipo === 'CA') {
                      try {
                        const genAsi = await this.contabilizadocService.generarAsientoComIng(item.TRNCOBRO_NRODOC, item.TRNCOBRO_NROCOMPROBANTE);
                        const asiNro = await this.contabilizadocService.erAsinrotrncobro(item.TRNCOBRO_NRODOC, item.TRNCOBRO_NROCOMPROBANTE);
                        
                        if (asiNro[0].ASI_NRO !== null) {
                          contador = contador + 1;
                          item.STATUS = 'ok';
                          item.CHECKCONT = false;
                          item.ASI_NRO = asiNro[0].ASI_NRO;
                          const indi = this.contabilizadocService.cxcComprobIngreso.indexOf(item);
                          this.aggrid.refreshaggridindex(this.contabilizadocService.cxcComprobIngreso, this.defaultColConComIngreso.objeto, indi - 1);
                          this.aggrid.gridApi.setFocusedCell(indi - 1, 'CHECKCONT');
                          this.auditoriagral.registrarAuditoria('CONTABILIZARDOC', 'CXC/COMINGRESO/' + String(item.TRNCOBRO_NRODOC), 'I',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
                        } else {
                          numSel = numSel - 1;
                          this.mensajeAlerta('error', 'Error', 'Ha sido imposible contabilizar el documento ' + item.TRNCOBRO_NRODOC );
                        }
                        
                      } catch (err) {
                        item.STATUS = 'fail';
                        numSel = numSel - 1;
                        this.msgDescuadre(item.TRNCOBRO_NRODOC);
                      }
                    } else if (tipo === 'ANC') {
                      try {
                        const genAsi = await this.contabilizadocService.generarAsientoCXCAnticip(item.TRNCOBRO_NRODOC, item.CLI_CODIGO);
                        const asiNro = await this.contabilizadocService.erAsinrotrncobro(item.TRNCOBRO_NRODOC, 
                          item.TRNCOBRO_NROCOMPROBANTE);
                        if (asiNro[0].ASI_NRO !== null) {
                          contador = contador + 1;
                          item.STATUS = 'ok';
                          item.CHECKCONT = false;
                          item.ASI_NRO = asiNro[0].ASI_NRO;
                          const indi = this.contabilizadocService.cxcComprobIngreso.indexOf(item);
                          this.aggrid.refreshaggridindex(this.contabilizadocService.cxcComprobIngreso, this.defaultColConComIngreso.objeto, indi - 1);
                          this.aggrid.gridApi.setFocusedCell(indi - 1, 'CHECKCONT');
                          this.auditoriagral.registrarAuditoria('CONTABILIZARDOC', 'CXC/COMINGRESO/' + String(item.TRNCOBRO_NRODOC), 'I',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
                        } else {
                          numSel = numSel - 1;
                          this.mensajeAlerta('error', 'Error', 'Ha sido imposible contabilizar el documento ' + item.TRNCOBRO_NRODOC);
                        }
                      } catch (err) {
                        item.STATUS = 'fail';
                        numSel = numSel - 1;
                        this.msgDescuadre(item.TRNCOBRO_NRODOC);
                      }
                    }
                  }
                }
                i++;
                if (i === this.contabilizadocService.cxcComprobIngreso.length && contador > 0) {
                  this.confirmationService.confirm({
                    message: 'Se han contabilizado ' + contador + ' documentos correctamente.',
                    header: 'Información',
                    icon: 'pi pi-check',
                    key: 'contdocConf',
                    acceptLabel: 'Aceptar',
                    rejectVisible: false,
                    accept: () => {
                      this.cxcComIngresoSeleccionarTodo = false;
                      this.porcentaje = 0;
                    }, reject: () => {
                    }
                  });
                }
              }
            }
          } else {
            this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar. ');
          }
        }
        break;
      }
      
      case 'cxcDevAnticipo': {
        if (this.contabilizadocService.cxcDevAnticipo === null) {
          this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
        } else {
          if (this.contabilizadocService.cxcDevAnticipo.length !== 0) {
            let numSel = this.contarSeleccionados(this.contabilizadocService.cxcDevAnticipo);
            if (numSel === 0) {
              this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
            } else {
              let contador = 0;
              const totalSel = numSel;
              this.porcentaje = 0;
              let i = 0;
              for (const item of this.contabilizadocService.cxcDevAnticipo) {
                if (item.CHECKCONT === true) {
                  this.porcentaje = this.porcentaje + Number(100 / totalSel);
                  try {
                    const genAsi = await this.contabilizadocService.generarAsientoDevAnticipo(item.TRNCOBRO_NRODOC, item.CLI_CODIGO);
                    const asiNro = await this.contabilizadocService.erAsinrotrncobro(item.TRNCOBRO_NRODOC, item.TRNCOBRO_NROCOMPROBANTE);
                    if (asiNro[0].ASI_NRO !== null) {
                      contador = contador + 1;
                      item.STATUS = 'ok';
                      item.CHECKCONT = false;
                      item.ASI_NRO = asiNro[0].ASI_NRO;
                      const indi = this.contabilizadocService.cxcDevAnticipo.indexOf(item);
                      this.aggrid.refreshaggridindex(this.contabilizadocService.cxcDevAnticipo, this.defaultColConDevAnt.objeto, indi - 1);
                      this.aggrid.gridApi.setFocusedCell(indi - 1, 'CHECKCONT');
                      this.auditoriagral.registrarAuditoria('CONTABILIZARDOC', 'CXC/DEVANTICIPO/' + String(item.TRNCOBRO_NRODOC), 'I',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
                    }
                  } catch (err) {
                    item.STATUS = 'fail';
                    numSel = numSel - 1;
                    this.msgDescuadre(item.TRNCOBRO_NRODOC);
                  }
                }
                i++;
                if (i === this.contabilizadocService.cxcDevAnticipo.length && contador > 0) {
                  this.confirmationService.confirm({
                    message: 'Se han contabilizado ' + contador + ' documentos correctamente.',
                    header: 'Información',
                    icon: 'pi pi-check',
                    key: 'contdocConf',
                    acceptLabel: 'Aceptar',
                    rejectVisible: false,
                    accept: () => {
                      this.cxcDevAntSeleccionarTodo = false;
                      this.porcentaje = 0;
                    }, reject: () => {
                    }
                  });
                }
              }
            }
          } else {
            this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar. ');
          }
        }
        break;
      }
      
      case 'cxcNotaCredito': {
        if (this.contabilizadocService.cxcNotasCredito === null) {
          this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
        } else {
          if (this.contabilizadocService.cxcNotasCredito.length !== 0) {
            let numSel = this.contarSeleccionados(this.contabilizadocService.cxcNotasCredito);
            if (numSel === 0) {
              this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
            } else {
              let contador = 0;
              const totalSel = numSel;
              this.porcentaje = 0;
              let i = 0;
              for (const item of this.contabilizadocService.cxcNotasCredito) {
                if (item.CHECKCONT === true) {
                  this.porcentaje = this.porcentaje + Number(100 / totalSel);
                  try {
                    const dato = await this.contabilizadocService.generarAsientoCXCNotaCredito(item.TRNCOBRO_NRODOC,
                      item.TRNCOBRO_NROCOMPROBANTE, item.CLI_CODIGO);
                    contador = contador + 1;
                    item.STATUS = 'ok';
                    item.CHECKCONT = false;
                    item.ASI_NRO = dato[0][':B1'];
                    const indi = this.contabilizadocService.cxcNotasCredito.indexOf(item);
                    this.aggrid.refreshaggridindex(this.contabilizadocService.cxcNotasCredito, this.defaultColCXCNotaCred.objeto, indi - 1);
                    this.aggrid.gridApi.setFocusedCell(indi - 1, 'CHECKCONT');
                    this.auditoriagral.registrarAuditoria('CONTABILIZARDOC', 'CXC/NOTACREDITO/' + String(item.TRNCOBRO_NRODOC), 'I',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
                  } catch (err) {
                    item.STATUS = 'fail';
                    numSel = numSel - 1;
                    this.msgDescuadre(item.TRNCOBRO_NRODOC);
                  }
                }
                i++;
                if (i === this.contabilizadocService.cxcNotasCredito.length && contador > 0) {
                  this.confirmationService.confirm({
                    message: 'Se han contabilizado ' + contador + ' documentos correctamente.',
                    header: 'Información',
                    icon: 'pi pi-check',
                    key: 'contdocConf',
                    acceptLabel: 'Aceptar',
                    rejectVisible: false,
                    accept: () => {
                      this.cxcNotaCredSeleccionarTodo = false;
                      this.porcentaje = 0;
                    }, reject: () => {
                    }
                  });
                }
              }
            }
          } else {
            this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar. ');
          }
        }
        break;
      }
      
      case 'cxcNotaDebito': {
        if (this.contabilizadocService.cxcNotasDebito === null) {
          this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
        } else {
          if (this.contabilizadocService.cxcNotasDebito.length !== 0) {
            let numSel = this.contarSeleccionados(this.contabilizadocService.cxcNotasDebito);
            if (numSel === 0) {
              this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
            } else {
              let contador = 0;
              const totalSel = numSel;
              this.porcentaje = 0;
              let i = 0;
              for (const item of this.contabilizadocService.cxcNotasDebito) {
                if (item.CHECKCONT === true) {
                  this.porcentaje = this.porcentaje + Number(100 / totalSel);
                  try {
                    const dato = await this.contabilizadocService.generarAsientoCXCNotaDebito(item.TRNCOBRO_NRODOC,
                      item.TRNCOBRO_NROCOMPROBANTE, item.CLI_CODIGO);
                    contador = contador + 1;
                    item.STATUS = 'ok';
                    item.CHECKCONT = false;
                    item.ASI_NRO = dato[0][':B1'];
                    const indi = this.contabilizadocService.cxcNotasDebito.indexOf(item);
                    this.aggrid.refreshaggridindex(this.contabilizadocService.cxcNotasDebito, this.defaultColCXCNotaDebito.objeto, indi - 1);
                    this.aggrid.gridApi.setFocusedCell(indi - 1, 'CHECKCONT');
                    this.auditoriagral.registrarAuditoria('CONTABILIZARDOC', 'CXC/NOTADEBITO/' + String(item.TRNCOBRO_NRODOC), 'I',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
                  } catch (err) {
                    item.STATUS = 'fail';
                    numSel = numSel - 1;
                    this.msgDescuadre(item.TRNCOBRO_NRODOC);
                  }
                }
                i++;
                if (i === this.contabilizadocService.cxcNotasDebito.length && contador > 0) {
                  this.confirmationService.confirm({
                    message: 'Se han contabilizado ' + contador + ' documentos correctamente.',
                    header: 'Información',
                    icon: 'pi pi-check',
                    key: 'contdocConf',
                    acceptLabel: 'Aceptar',
                    rejectVisible: false,
                    accept: () => {
                      this.cxcNotaDebitoSeleccionarTodo = false;
                      this.porcentaje = 0;
                    }, reject: () => {
                    }
                  });
                }
              }
            }
          } else {
            this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar. ');
          }
        }
        break;
      }
      
      case 'cxcRetencion': {
        if (this.contabilizadocService.cxcRetenciones === null) {
          this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
        } else {
          if (this.contabilizadocService.cxcRetenciones.length !== 0) {
            let numSel = this.contarSeleccionados(this.contabilizadocService.cxcRetenciones);
            if (numSel === 0) {
              this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
            } else {
              let contador = 0;
              const totalSel = numSel;
              this.porcentaje = 0;
              let i = 0;
              for (const item of this.contabilizadocService.cxcRetenciones) {
                if (item.CHECKCONT === true) {
                  this.porcentaje = this.porcentaje + Number(100 / totalSel);
                  try {
                    const dato = await this.contabilizadocService.generarAsientoCXCRetencion(item.TRNCOBRO_NRODOC, item.TRNCOBRO_NROCOMPROBANTE);
                    const asiNro = await this.contabilizadocService.erAsinrotrncobro(item.TRNCOBRO_NRODOC, item.TRNCOBRO_NROCOMPROBANTE);
                    contador = contador + 1;
                    item.STATUS = 'ok';
                    item.CHECKCONT = false;
                    item.ASI_NRO = asiNro[0].ASI_NRO;
                    const indi = this.contabilizadocService.cxcRetenciones.indexOf(item);
                    this.aggrid.refreshaggridindex(this.contabilizadocService.cxcRetenciones, this.defaultColCXCRetencion.objeto, indi - 1);
                    this.aggrid.gridApi.setFocusedCell(indi - 1, 'CHECKCONT');
                    this.auditoriagral.registrarAuditoria('CONTABILIZARDOC', 'CXC/RETENCION/' + String(item.TRNCOBRO_NRODOC), 'I',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
                  } catch (err) {
                    item.STATUS = 'fail';
                    numSel = numSel - 1;
                    this.msgDescuadre(item.TRNCOBRO_NRODOC);
                  }
                }
                i++;
                if (i === this.contabilizadocService.cxcRetenciones.length && contador > 0) {
                  this.confirmationService.confirm({
                    message: 'Se han contabilizado ' + contador + ' documentos correctamente.',
                    header: 'Información',
                    icon: 'pi pi-check',
                    key: 'contdocConf',
                    acceptLabel: 'Aceptar',
                    rejectVisible: false,
                    accept: () => {
                      this.cxcRetencionSeleccionarTodo = false;
                      this.porcentaje = 0;
                    }, reject: () => {
                    }
                  });
                }
              }
            }
          } else {
            this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar. ');
          }
        }
        break;
      }
      
      case 'cxpEgreso': {
        if (this.contabilizadocService.cxpEgresos === null) {
          this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
        } else {
          if (this.contabilizadocService.cxpEgresos.length !== 0) {
            let numSel = this.contarSeleccionados(this.contabilizadocService.cxpEgresos);
            if (numSel === 0) {
              this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
            } else {
              let contador = 0;
              const totalSel = numSel;
              this.porcentaje = 0;
              let i = 0;
              for (const item of this.contabilizadocService.cxpEgresos) {
                if (item.CHECKCONT === true) {
                  let strAsiComprobante = '';
                  this.porcentaje = this.porcentaje + Number(100 / totalSel);
                  try {
                    const asiNro = await this.contabilizadocService.erAsicomprobante(item.ASI_NRO);
                    if (asiNro !== null) {
                      strAsiComprobante = asiNro[0].ASI_COMPROBANTE;
                      try {
                        const dato = await  this.contabilizadocService.generarAsientoCXPEgreso(item.TRNCOBRO_NRODOC, strAsiComprobante,
                          item.TIPDIA_CODIGO);
                        contador = contador + 1;
                        item.STATUS = 'ok';
                        item.CHECKCONT = false;
                        item.ASI_NRO = dato[0][':B1'];
                        const indi = this.contabilizadocService.cxpEgresos.indexOf(item);
                        this.aggrid.refreshaggridindex(this.contabilizadocService.cxpEgresos, this.defaultColCXPEgreso.objeto, indi - 1);
                        this.aggrid.gridApi.setFocusedCell(indi - 1, 'CHECKCONT');
                        this.auditoriagral.registrarAuditoria('CONTABILIZARDOC', 'CXP/EGRESO/' + String(item.TRNCOBRO_NRODOC), 'I',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
                      } catch (e) {
                        item.STATUS = 'fail';
                        numSel = numSel - 1;
                        this.msgDescuadre(item.TRNCOBRO_NRODOC);
                      }
                    } else {
                      this.msgDescuadre(item.TRNCOBRO_NRODOC);
                    }
                  } catch (err) {
                    item.STATUS = 'fail';
                    numSel = numSel - 1;
                    this.msgDescuadre(item.TRNCOBRO_NRODOC);
                  }
                }
                i++;
                if (i === this.contabilizadocService.cxpEgresos.length && contador > 0) {
                  this.confirmationService.confirm({
                    message: 'Se han contabilizado ' + contador + ' documentos correctamente.',
                    header: 'Información',
                    icon: 'pi pi-check',
                    key: 'contdocConf',
                    acceptLabel: 'Aceptar',
                    rejectVisible: false,
                    accept: () => {
                      this.cxpEgresoSeleccionarTodo = false;
                      this.porcentaje = 0;
                    }, reject: () => {
                    }
                  });
                }
              }
            }
          } else {
            this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar. ');
          }
        }
        break;
      }

      case 'nomRolPago': {
        if (this.contabilizadocService.nomRolPagos === null) {
          this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
        } else {
          if (this.contabilizadocService.nomRolPagos.length !== 0) {
            let numSel = this.contarSeleccionados(this.contabilizadocService.nomRolPagos);
            if (numSel === 0) {
              this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
            } else {
              let contador = 0;
              const totalSel = numSel;
              this.porcentaje = 0;
              let i = 0;
              for (const item of this.contabilizadocService.nomRolPagos) {
                if (item.CHECKCONT === true) {

                  
                  try {
                    await  this.contabilizadocService.generarasientonomrolpago(item.PERIODO_CODIGO, item.TIPROL_CODIGO);
                    this.porcentaje = this.porcentaje + Number(100 / totalSel);
                    this.auditoriagral.registrarAuditoria('NOM_ENCROLINDI', 'CONTABILIZACION PERIODO: ' + String(item.PERIODO_CODIGO), 'A',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
                    contador = contador + 1;
                    item.STATUS = 'ok';
                    item.CHECKCONT = false;
                    const asi =  await this.contabilizadocService.getAsiNomRolPago(item.PERIODO_CODIGO, item.TIPROL_CODIGO);
                    item.ASI_NRO = asi[0].ASI_NRO;

                    const indi = this.contabilizadocService.nomRolPagos.indexOf(item);
                    this.aggrid.refreshaggridindex(this.contabilizadocService.nomRolPagos, this.defaultColNomRol.objeto, indi - 1);
                     this.aggrid.gridApi.setFocusedCell(indi - 1, 'CHECKCONT');
                     this.auditoriagral.registrarAuditoria('CONTABILIZARDOC', 'NOM/ROLPAGO/' + String(item.PERIODO_CODIGO), 'I',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
                    
                  } catch (err) {
                    item.STATUS = 'fail';
                    numSel = numSel - 1;
                    
                  }
                }

                i++;
                if (i === this.contabilizadocService.nomRolPagos.length && contador > 0) {
                  this.confirmationService.confirm({
                    message: 'Se han contabilizado ' + contador + ' documentos correctamente.',
                    header: 'Información',
                    icon: 'pi pi-check',
                    key: 'contdocConf',
                    acceptLabel: 'Aceptar',
                    rejectVisible: false,
                    accept: () => {
                      this.nomRolSeleccionarTodo = false;
                      this.porcentaje = 0;
                    }, reject: () => {
                    }
                  });
                }
                
              }
            }
          } else {
            this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar. ');
          }
        }
        break;
      }
      case 'prodOrdProd': {
        if (this.contabilizadocService.prodOrdProd === null) {
          this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
        } else {
          if (this.contabilizadocService.prodOrdProd.length !== 0) {
            let numSel = this.contarSeleccionados(this.contabilizadocService.prodOrdProd);
            if (numSel === 0) {
              this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar.');
            } else {
              let Codigo = '';
              let Referencia = '';
              let TipoDocumento = '';
              let TipoProd = '';
              let Estado = '';

              let contador = 0;
              const totalSel = numSel;
              this.porcentaje = 0;
              let i = 0;
              for (const item of this.contabilizadocService.prodOrdProd) {
               
                TipoProd = item.ENCORDPRO_TIPOPROD;
                Estado = item.ENCORDPRO_ESTADO;
                Referencia = item.ENCORDPRO_REFERENCIA;
                Codigo = item.ENCORDPRO_NUMERO;

                if (item.CHECKCONT === true) {

                  try {
                    await this.GenerarAsientoContableOrdenProduccion(Codigo, TipoProd, Estado);

                    this.porcentaje = this.porcentaje + Number(100 / totalSel);
                    
                    contador = contador + 1;
                    item.STATUS = 'ok';
                    item.CHECKCONT = false;

                    this.auditoriagral.registrarAuditoria('CONTABILIZARDOC', 'PROD/ORDPROD/' + String(Codigo), 'I',
                    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});

                  } catch (err) {
                    item.STATUS = 'fail';
                    numSel = numSel - 1;
                    
                  }
                }

                i++;
                if (i === this.contabilizadocService.prodOrdProd.length && contador > 0) {
                  this.confirmationService.confirm({
                    message: 'Se han contabilizado ' + contador + ' documentos correctamente.',
                    header: 'Información',
                    icon: 'pi pi-check',
                    key: 'contdocConf',
                    acceptLabel: 'Aceptar',
                    rejectVisible: false,
                    accept: () => {
                      this.prodOrdProdSeleccionarTodo = false;
                      this.porcentaje = 0;
                      this.ordenesProduccion();
                    }, reject: () => {
                    }
                  });
                }
                
              }
            }
          } else {
            this.msgErrorSeleccion('No se ha seleccionado ningún elemento para contabilizar. ');
          }
        }
        break;
      }
    }
  }

  async GenerarAsientoContableOrdenProduccion (NumeroOrdPro, TipoProduccion, Estado) {
    let parametrosSp:any[] = [];
    if (TipoProduccion === 'F') {
      parametrosSp[0] = NumeroOrdPro;
      parametrosSp[1] = '01';
      parametrosSp[2] = 'OPP';
      parametrosSp[3] = 'PROD';
      parametrosSp[4] = this.usuario.identificacion;

      try {
        await this.contabilizadocService.generarAsiOrdProd(parametrosSp);
      } catch(err) {
        const rs = await this.contabilizadocService.getMensajeinfError(this.usuario.identificacion);
        for (const msj of rs) {
          this.mensajeAlerta('error', 'Error', msj.MENSAJEINF_DESCRIPCION + '. EN LA ORDEN DE PRODUCCION ' + NumeroOrdPro);
        }
      }


      parametrosSp = [];
      parametrosSp[0] = NumeroOrdPro;
      parametrosSp[1] = '01';
      parametrosSp[2] = 'OPT';
      parametrosSp[3] = 'PROD';
      parametrosSp[4] = this.usuario.identificacion;

      try {
        await this.contabilizadocService.generarAsiOrdProdTer(parametrosSp);
      } catch(err) {
        const rs = await this.contabilizadocService.getMensajeinfError(this.usuario.identificacion);
        for (const msj of rs) {
          this.mensajeAlerta('error', 'Error', msj.MENSAJEINF_DESCRIPCION + '. EN LA ORDEN DE PRODUCCION ' + NumeroOrdPro);
        }
      }
    } else {
      if (Estado == 'I') {
        parametrosSp = [];
        parametrosSp[0] = NumeroOrdPro;
        parametrosSp[1] = '01';
        parametrosSp[2] = 'OPA';
        parametrosSp[3] = 'PROD';
        try {
          await this.contabilizadocService.generarAsiOrdProdAju(parametrosSp);
        } catch(err) {
          this.mensajeAlerta('error', 'Error', err.error);
          
        }
      }
    }
  }
  
  contarSeleccionados(lista: any[]) {
    let cont = 0;
    lista.map((res) => {
      if (res.CHECKCONT === true) {
        cont++;
      }
    });
    console.log(cont);
    return cont;
  }
  
  // acciones paneles
  onTab1Change(event) {
    this.nivel1 = event.index;
  }
  onTab2Change(event) {
    switch (this.nivel1) {
      case 0: {
        switch (event.index) {
          case 0: {
            this.panel = 'banMovBan';
            break;
          }
          case 1: {
            this.panel = 'banMovCaja';
            break;
          }
          default: {
            this.panel = '';
            break;
          }
        }
        break;
      }
      case 1: {
        switch (event.index) {
          case 0: {
            this.panel = 'comfacpro';
            break;
          }
          case 1: {
            this.panel = 'comdevcom';
            break;
          }
          case 2: {
            this.panel = 'comnotrec';
            break;
          }
          default: {
            this.panel = '';
            break;
          }
        }
        break;
      }
      case 2: {
        switch (event.index) {
          case 0: {
            this.panel = 'invEntradaSalida';
            break;
          }
          // case 1: {
          //   this.panel = 'invTransferencia';
          //   break;
          // }
          case 1: {
            this.panel = 'invTomaFisica';
            break;
          }
          case 2: {
            this.panel = 'invAjuste';
            break;
          }
          default: {
            this.panel = '';
            break;
          }
        }
        break;
      }
      
      case 3: {
        switch (event.index) {
          case 0: {
            this.panel = 'venFactura';
            break;
          }
          case 1: {
            this.panel = 'venNotaCredito';
            break;
          }
          case 2: {
            this.panel = 'venGuiaRemision';
            break;
          }
          default: {
            this.panel = '';
            break;
          }
        }
        break;
      }
      
      case 4: {
        switch (event.index) {
          case 0: {
            this.panel = 'cxcCompIngreso';
            break;
          }
          case 1: {
            this.panel = 'cxcDevAnticipo';
            break;
          }
          case 2: {
            this.panel = 'cxcNotaCredito';
            break;
          }
          case 3: {
            this.panel = 'cxcNotaDebito';
            break;
          }
          case 4: {
            this.panel = 'cxcRetencion';
            break;
          }
          default: {
            this.panel = '';
            break;
          }
        }
        break;
      }
      
      case 5: {
        switch (event.index) {
          case 0: {
            this.panel = 'cxpEgreso';
            break;
          }
          default: {
            this.panel = '';
            break;
          }
        }
        break;
      }

      case 6: {
        switch (event.index) {
          case 0: {
            this.panel = 'nomRolPago';
            break;
          }
          default: {
            this.panel = '';
            break;
          }
        }
        break;
      }

      case 7: {
        switch (event.index) {
          case 0: {
            this.panel = 'prodOrdProd';
            break;
          }
          default: {
            this.panel = '';
            break;
          }
        }
        break;
      }
      
      default: {
        this.panel = '';
      }
      
    }
  }
  
  // SeleccionarTodo
  seleccionarTodo(panel) {
    switch (panel) {
      case 'comfacpro': {
        if (this.contabilizadocService.facturasProveedores === null) {
          this.msgErrorSeleccion('No hay registros para seleccionar.');
        } else {
          if (this.comFacProSeleccionarTodo === true) {
            if (this.contabilizadocService.facturasProveedores !== null) {
              this.contabilizadocService.facturasProveedores.map((res) => {
                res.CHECKCONT = true;
              });
            }
          }
          if (this.comFacProSeleccionarTodo === false) {
            if (this.contabilizadocService.facturasProveedores !== null) {
              this.contabilizadocService.facturasProveedores.map((res) => {
                res.CHECKCONT = false;
              });
            }
          }
          this.aggrid.refreshaggrid(this.contabilizadocService.facturasProveedores, this.defaultColConFactPro.objeto);
        }
        break;
      }
      
      case 'comdevcom': {
        if (this.contabilizadocService.devolucionesProveedores === null) {
          this.msgErrorSeleccion('No hay registros para seleccionar.');
        } else {
          if (this.comDevComSeleccionarTodo === true) {
            if (this.contabilizadocService.devolucionesProveedores !== null) {
              this.contabilizadocService.devolucionesProveedores.map((res) => {
                res.CHECKCONT = true;
              });
            }
          }
          if (this.comDevComSeleccionarTodo === false) {
            if (this.contabilizadocService.devolucionesProveedores !== null) {
              this.contabilizadocService.devolucionesProveedores.map((res) => {
                res.CHECKCONT = false;
              });
            }
          }
          this.aggrid.refreshaggrid(this.contabilizadocService.devolucionesProveedores, this.defaultColComDevCom.objeto);
        }
        break;
      }
      
      case 'comnotrec': {
        if (this.contabilizadocService.notasDeRecepcion === null) {
          this.msgErrorSeleccion('No hay registros para seleccionar.');
        } else {
          if (this.comNotRecSeleccionarTodo === true) {
            if (this.contabilizadocService.notasDeRecepcion !== null) {
              this.contabilizadocService.notasDeRecepcion.map((res) => {
                res.CHECKCONT = true;
              });
            }
          }
          if (this.comNotRecSeleccionarTodo === false) {
            if (this.contabilizadocService.notasDeRecepcion !== null) {
              this.contabilizadocService.notasDeRecepcion.map((res) => {
                res.CHECKCONT = false;
              });
            }
          }
          this.aggrid.refreshaggrid(this.contabilizadocService.notasDeRecepcion, this.defaultColConNotRec.objeto);
        }
        break;
      }
      
      case 'banMovBan': {
        if (this.contabilizadocService.movimientosBancos === null) {
          this.msgErrorSeleccion('No hay registros para seleccionar.');
        } else {
          if (this.banMovBanSeleccionarTodo === true) {
            if (this.contabilizadocService.movimientosBancos !== null) {
              this.contabilizadocService.movimientosBancos.map((res) => {
                res.CHECKCONT = true;
              });
            }
          }
          if (this.banMovBanSeleccionarTodo === false) {
            if (this.contabilizadocService.movimientosBancos !== null) {
              this.contabilizadocService.movimientosBancos.map((res) => {
                res.CHECKCONT = false;
              });
            }
          }
          this.aggrid.refreshaggrid(this.contabilizadocService.movimientosBancos, this.defaultColConBanMovBan.objeto);
        }
        break;
      }
      case 'banMovCaja' : {
        if (this.contabilizadocService.movimientosCaja === null) {
          this.msgErrorSeleccion('No hay registros para seleccionar.');
        } else {
          if (this.banMovCajaSeleccionarTodo === true) {
            if (this.contabilizadocService.movimientosCaja !== null) {
              this.contabilizadocService.movimientosCaja.map((res2) => {
                res2.CHECKCONT = true;
              });
            }
          }
          if (this.banMovCajaSeleccionarTodo === false) {
            if (this.contabilizadocService.movimientosCaja !== null) {
              this.contabilizadocService.movimientosCaja.map((res2) => {
                res2.CHECKCONT = false;
              });
            }
          }
          this.aggrid.refreshaggrid(this.contabilizadocService.movimientosCaja, this.defaultColConBanMovCaj.objeto);
        }
        break;
      }
      
      case 'invEntradaSalida' : {
        if (this.contabilizadocService.entradaSalida === null) {
          this.msgErrorSeleccion('No hay registros para seleccionar.');
        } else {
          if (this.invEntradaSalidaSeleccionarTodo === true) {
            if (this.contabilizadocService.entradaSalida !== null) {
              this.contabilizadocService.entradaSalida.map((res2) => {
                res2.CHECKCONT = true;
              });
            }
          }
          if (this.invEntradaSalidaSeleccionarTodo === false) {
            if (this.contabilizadocService.entradaSalida !== null) {
              this.contabilizadocService.entradaSalida.map((res2) => {
                res2.CHECKCONT = false;
              });
            }
          }
          this.aggrid.refreshaggrid(this.contabilizadocService.entradaSalida, this.defaultColConEntradaSalida.objeto);
        }
        break;
      }
      
      case 'invTransferencia' : {
        if (this.contabilizadocService.transferencias === null) {
          this.msgErrorSeleccion('No hay registros para seleccionar.');
        } else {
          if (this.invTransferenciaSeleccionarTodo === true) {
            if (this.contabilizadocService.transferencias !== null) {
              this.contabilizadocService.transferencias.map((res2) => {
                res2.CHECKCONT = true;
              });
            }
          }
          if (this.invTransferenciaSeleccionarTodo === false) {
            if (this.contabilizadocService.transferencias !== null) {
              this.contabilizadocService.transferencias.map((res2) => {
                res2.CHECKCONT = false;
              });
            }
          }
          this.aggrid.refreshaggrid(this.contabilizadocService.transferencias, this.defaultColConTranferencia.objeto);
        }
        break;
      }
      
      case 'invTomaFisica' : {
        if (this.contabilizadocService.tomasFisicas === null) {
          this.msgErrorSeleccion('No hay registros para seleccionar.');
        } else {
          if (this.invTomaFisicaSeleccionarTodo === true) {
            if (this.contabilizadocService.tomasFisicas !== null) {
              this.contabilizadocService.tomasFisicas.map((res2) => {
                res2.CHECKCONT = true;
              });
            }
          }
          if (this.invTomaFisicaSeleccionarTodo === false) {
            if (this.contabilizadocService.tomasFisicas !== null) {
              this.contabilizadocService.tomasFisicas.map((res2) => {
                res2.CHECKCONT = false;
              });
            }
          }
          this.aggrid.refreshaggrid(this.contabilizadocService.tomasFisicas, this.defaultColConTomaFisica.objeto);
        }
        break;
      }
      
      case 'venFactura' : {
        if (this.contabilizadocService.ventasFacturas === null) {
          this.msgErrorSeleccion('No hay registros para seleccionar.');
        } else {
          if (this.venFactSeleccionarTodo === true) {
            if (this.contabilizadocService.ventasFacturas !== null) {
              this.contabilizadocService.ventasFacturas.map((res2) => {
                res2.CHECKCONT = true;
              });
            }
          }
          if (this.venFactSeleccionarTodo === false) {
            if (this.contabilizadocService.ventasFacturas !== null) {
              this.contabilizadocService.ventasFacturas.map((res2) => {
                res2.CHECKCONT = false;
              });
            }
          }
          this.aggrid.refreshaggrid(this.contabilizadocService.ventasFacturas, this.defaultColConFactura.objeto);
        }
        break;
      }
      
      case 'venNotaCredito' : {
        if (this.contabilizadocService.venNotasCredito === null) {
          this.msgErrorSeleccion('No hay registros para seleccionar.');
        } else {
          if (this.venNotaCreditoSeleccionarTodo === true) {
            if (this.contabilizadocService.venNotasCredito !== null) {
              this.contabilizadocService.venNotasCredito.map((res2) => {
                res2.CHECKCONT = true;
              });
            }
          }
          if (this.venNotaCreditoSeleccionarTodo === false) {
            if (this.contabilizadocService.venNotasCredito !== null) {
              this.contabilizadocService.venNotasCredito.map((res2) => {
                res2.CHECKCONT = false;
              });
            }
          }
          this.aggrid.refreshaggrid(this.contabilizadocService.venNotasCredito, this.defaultColConNotaCredito.objeto);
        }
        break;
      }
      
      case 'venGuiaRemision' : {
        if (this.contabilizadocService.venGuiasRemision === null) {
          this.msgErrorSeleccion('No hay registros para seleccionar.');
        } else {
          if (this.venGuiaRemiSeleccionarTodo === true) {
            if (this.contabilizadocService.venGuiasRemision !== null) {
              this.contabilizadocService.venGuiasRemision.map((res2) => {
                res2.CHECKCONT = true;
              });
            }
          }
          if (this.venGuiaRemiSeleccionarTodo === false) {
            if (this.contabilizadocService.venGuiasRemision !== null) {
              this.contabilizadocService.venGuiasRemision.map((res2) => {
                res2.CHECKCONT = false;
              });
            }
          }
          this.aggrid.refreshaggrid(this.contabilizadocService.venGuiasRemision, this.defaultColConGuiaRemi.objeto);
        }
        break;
      }
      
      case 'cxcComIngreso' : {
        if (this.contabilizadocService.cxcComprobIngreso === null) {
          this.msgErrorSeleccion('No hay registros para seleccionar.');
        } else {
          if (this.cxcComIngresoSeleccionarTodo === true) {
            if (this.contabilizadocService.cxcComprobIngreso !== null) {
              this.contabilizadocService.cxcComprobIngreso.map((res2) => {
                res2.CHECKCONT = true;
              });
            }
          }
          if (this.cxcComIngresoSeleccionarTodo === false) {
            if (this.contabilizadocService.cxcComprobIngreso !== null) {
              this.contabilizadocService.cxcComprobIngreso.map((res2) => {
                res2.CHECKCONT = false;
              });
            }
          }
          this.aggrid.refreshaggrid(this.contabilizadocService.cxcComprobIngreso, this.defaultColConComIngreso.objeto);
        }
        break;
      }
      
      case 'cxcDevAnticipo' : {
        if (this.contabilizadocService.cxcDevAnticipo === null) {
          this.msgErrorSeleccion('No hay registros para seleccionar.');
        } else {
          if (this.cxcDevAntSeleccionarTodo === true) {
            if (this.contabilizadocService.cxcDevAnticipo !== null) {
              this.contabilizadocService.cxcDevAnticipo.map((res2) => {
                res2.CHECKCONT = true;
              });
            }
          }
          if (this.cxcDevAntSeleccionarTodo === false) {
            if (this.contabilizadocService.cxcDevAnticipo !== null) {
              this.contabilizadocService.cxcDevAnticipo.map((res2) => {
                res2.CHECKCONT = false;
              });
            }
          }
          this.aggrid.refreshaggrid(this.contabilizadocService.cxcDevAnticipo, this.defaultColConDevAnt.objeto);
        }
        break;
      }
      
      case 'cxcNotaCredito' : {
        if (this.contabilizadocService.cxcNotasCredito === null) {
          this.msgErrorSeleccion('No hay registros para seleccionar.');
        } else {
          if (this.cxcNotaCredSeleccionarTodo === true) {
            if (this.contabilizadocService.cxcNotasCredito !== null) {
              this.contabilizadocService.cxcNotasCredito.map((res2) => {
                res2.CHECKCONT = true;
              });
            }
          }
          if (this.cxcNotaCredSeleccionarTodo === false) {
            if (this.contabilizadocService.cxcNotasCredito !== null) {
              this.contabilizadocService.cxcNotasCredito.map((res2) => {
                res2.CHECKCONT = false;
              });
            }
          }
          this.aggrid.refreshaggrid(this.contabilizadocService.cxcNotasCredito, this.defaultColCXCNotaCred.objeto);
        }
        break;
      }
      
      case 'cxcNotaDebito' : {
        if (this.contabilizadocService.cxcNotasDebito === null) {
          this.msgErrorSeleccion('No hay registros para seleccionar.');
        } else {
          if (this.cxcNotaDebitoSeleccionarTodo === true) {
            if (this.contabilizadocService.cxcNotasDebito !== null) {
              this.contabilizadocService.cxcNotasDebito.map((res2) => {
                res2.CHECKCONT = true;
              });
            }
          }
          if (this.cxcNotaDebitoSeleccionarTodo === false) {
            if (this.contabilizadocService.cxcNotasDebito !== null) {
              this.contabilizadocService.cxcNotasDebito.map((res2) => {
                res2.CHECKCONT = false;
              });
            }
          }
          this.aggrid.refreshaggrid(this.contabilizadocService.cxcNotasDebito, this.defaultColCXCNotaDebito.objeto);
        }
        break;
      }
      
      case 'cxpEgreso' : {
        if (this.contabilizadocService.cxpEgresos === null) {
          this.msgErrorSeleccion('No hay registros para seleccionar.');
        } else {
          if (this.cxpEgresoSeleccionarTodo === true) {
            if (this.contabilizadocService.cxpEgresos !== null) {
              this.contabilizadocService.cxpEgresos.map((res2) => {
                res2.CHECKCONT = true;
              });
            }
          }
          if (this.cxpEgresoSeleccionarTodo === false) {
            if (this.contabilizadocService.cxpEgresos !== null) {
              this.contabilizadocService.cxpEgresos.map((res2) => {
                res2.CHECKCONT = false;
              });
            }
          }
          this.aggrid.refreshaggrid(this.contabilizadocService.cxpEgresos, this.defaultColCXPEgreso.objeto);
        }
        break;
      }
      
      case 'cxcRetencion' : {
        if (this.contabilizadocService.cxcRetenciones === null) {
          this.msgErrorSeleccion('No hay registros para seleccionar.');
        } else {
          if (this.cxcRetencionSeleccionarTodo === true) {
            if (this.contabilizadocService.cxcRetenciones !== null) {
              this.contabilizadocService.cxcRetenciones.map((res2) => {
                res2.CHECKCONT = true;
              });
            }
          }
          if (this.cxcRetencionSeleccionarTodo === false) {
            if (this.contabilizadocService.cxcRetenciones !== null) {
              this.contabilizadocService.cxcRetenciones.map((res2) => {
                res2.CHECKCONT = false;
              });
            }
          }
          this.aggrid.refreshaggrid(this.contabilizadocService.cxcRetenciones, this.defaultColCXCRetencion.objeto);
        }
        break;
      }

      case 'nomRolPago' : {
        if (this.contabilizadocService.nomRolPagos === null) {
          this.msgErrorSeleccion('No hay registros para seleccionar.');
        } else {
          if (this.nomRolSeleccionarTodo === true) {
            if (this.contabilizadocService.nomRolPagos !== null) {
              this.contabilizadocService.nomRolPagos.map((res2) => {
                res2.CHECKCONT = true;
              });
            }
          }
          if (this.nomRolSeleccionarTodo === false) {
            if (this.contabilizadocService.nomRolPagos !== null) {
              this.contabilizadocService.nomRolPagos.map((res2) => {
                res2.CHECKCONT = false;
              });
            }
          }
          this.aggrid.refreshaggrid(this.contabilizadocService.nomRolPagos, this.defaultColNomRol.objeto);
        }
        break;
      }

      case 'prodOrdProd': {
        if (this.contabilizadocService.prodOrdProd === null) {
          this.msgErrorSeleccion('No hay registros para seleccionar.');
        } else {
          if (this.prodOrdProdSeleccionarTodo === true) {
            if (this.contabilizadocService.prodOrdProd !== null) {
              this.contabilizadocService.prodOrdProd.map((res2) => {
                res2.CHECKCONT = true;
              });
            }
          }
          if (this.prodOrdProdSeleccionarTodo === false) {
            if (this.contabilizadocService.prodOrdProd !== null) {
              this.contabilizadocService.prodOrdProd.map((res2) => {
                res2.CHECKCONT = false;
              });
            }
          }
          this.aggrid.refreshaggrid(this.contabilizadocService.prodOrdProd, this.defaultColProdOrdProd.objeto);
        }
        break;
      }
      
    }
  }
  
  // Consultar
  consultarDocumentos(panel) {
    console.log('989879898---', this.RANGFECHA)
   
    switch (panel) {
      
      case 'comfacpro': {
        const dia = this.comFacProfechaInicial.getDate();
        const mes = this.comFacProfechaInicial.getMonth();
        const anio = this.comFacProfechaInicial.getFullYear();
        this.panel = 'comfacpro';
        console.log('<<<<>>>>', dia, mes, anio)
        this.contabilizadocService.verificarPeriodoContable(dia, mes, anio).subscribe((per) => {
          if (per[0].CONTADOR === 1) {
            //GSRF
            const comdesde = this.datePipe.transform(this.comFacProfechaInicial,  'MM/yyyy');
            const comhasta= this.datePipe.transform(this.comFacProfechaFinal, 'MM/yyyy');
            console.log( '11111111',comdesde, comhasta);
            if(this.RANGFECHA === 1){
              if(comdesde !== comhasta){
                this.mensajeAlerta('info', 'ERROR', 'El rango de fechas no puede ser superior a un mes.');
                return;
              }
            }
            //GSRF
            this.actualizarModeloComFactPro(this.comFacProfechaInicial, this.comFacProfechaFinal, this.comFacProTipo);
          } else {
            this.mensajeAlerta('info', 'ERROR', 'La Fecha debe estar dentro del periodo contable.');
          }
        });
        break;
      }
      
      case 'comdevcom': {
        const dia = this.comDevComProfechaInicial.getDate();
        const mes = this.comDevComProfechaInicial.getMonth();
        const anio = this.comDevComProfechaInicial.getFullYear();
        this.panel = 'comdevcom';
        this.contabilizadocService.verificarPeriodoContable(dia, mes, anio).subscribe((per) => {
          if (per[0].CONTADOR === 1) {
             //GSRF
             const comdesde = this.datePipe.transform(this.comDevComProfechaInicial,  'MM/yyyy');
             const comhasta= this.datePipe.transform(this.comDevComfechaFinal, 'MM/yyyy');
             console.log( '11111111',comdesde, comhasta);
             if(this.RANGFECHA === 1){
               if(comdesde !== comhasta){
                 this.mensajeAlerta('info', 'ERROR', 'El rango de fechas no puede ser superior a un mes.');
                 return;
               }
             }
             //GSRF
            this.actualizarModeloComDevCom(this.comDevComProfechaInicial, this.comDevComfechaFinal, this.comDevComTipo);
          } else {
            this.mensajeAlerta('info', 'ERROR', 'La Fecha debe estar dentro del periodo contable.');
          }
        });
        break;
      }
      
      case 'comnotrec': {
        const dia = this.comNotRecFechaInicial.getDate();
        const mes = this.comNotRecFechaInicial.getMonth();
        const anio = this.comNotRecFechaInicial.getFullYear();
        this.panel = 'comnotrec';
        this.contabilizadocService.verificarPeriodoContable(dia, mes, anio).subscribe((per) => {
          if (per[0].CONTADOR === 1) {
            //GSRF
            const comdesde = this.datePipe.transform(this.comNotRecFechaInicial,  'MM/yyyy');
            const comhasta= this.datePipe.transform(this.comNotRecFechaFinal, 'MM/yyyy');
            console.log( '11111111',comdesde, comhasta);
            if(this.RANGFECHA === 1){
              if(comdesde !== comhasta){
                this.mensajeAlerta('info', 'ERROR', 'El rango de fechas no puede ser superior a un mes.');
                return;
              }
            }
            //GSRF
            this.actualizarModeloComNotRec(this.comNotRecFechaInicial, this.comNotRecFechaFinal, this.comNotRecTipo);
          } else {
            this.mensajeAlerta('info', 'ERROR', 'La Fecha debe estar dentro del periodo contable.');
          }
        });
        break;
      }
      
      case 'banMovBan': {
        const dia = this.banMovBanFechaInicial.getDate();
        const mes = this.banMovBanFechaInicial.getMonth();
        const anio = this.banMovBanFechaInicial.getFullYear();
        this.panel = 'banMovBan';
        this.contabilizadocService.verificarPeriodoContable(dia, mes, anio).subscribe((per) => {
          if (per[0].CONTADOR === 1) {
            //GSRF
            const comdesde = this.datePipe.transform(this.banMovBanFechaInicial,  'MM/yyyy');
            const comhasta= this.datePipe.transform(this.banMovBanFechaFinal, 'MM/yyyy');
            console.log( '11111111',comdesde, comhasta);
            if(this.RANGFECHA === 1){
              if(comdesde !== comhasta){
                this.mensajeAlerta('info', 'ERROR', 'El rango de fechas no puede ser superior a un mes.');
                return;
              }
            }
            //GSRF
            this.actualizarModeloMovBan(this.banMovBanFechaInicial, this.banMovBanFechaFinal, this.strTipoMovBan, this.banMovBanTipo);
          } else {
            this.mensajeAlerta('info', 'ERROR', 'La Fecha debe estar dentro del periodo contable.');
          }
        });
        break;
      }
      
      case 'banMovCaja': {
        const dia = this.banMovCajaFechaInicial.getDate();
        const mes = this.banMovCajaFechaInicial.getMonth();
        const anio = this.banMovCajaFechaInicial.getFullYear();
        this.panel = 'banMovCaja';
        this.contabilizadocService.verificarPeriodoContable(dia, mes, anio).subscribe((per) => {
          if (per[0].CONTADOR === 1) {
             //GSRF
             const comdesde = this.datePipe.transform(this.banMovCajaFechaInicial,  'MM/yyyy');
             const comhasta= this.datePipe.transform(this.banMovCajaFechaFinal, 'MM/yyyy');
             console.log( '11111111',comdesde, comhasta);
             if(this.RANGFECHA === 1){
               if(comdesde !== comhasta){
                 this.mensajeAlerta('info', 'ERROR', 'El rango de fechas no puede ser superior a un mes.');
                 return;
               }
             }
             //GSRF
            this.actualizarModeloMovCaj(this.banMovCajaFechaInicial, this.banMovCajaFechaFinal, this.strTipoMovCaj, this.banMovCajaTipo);
          } else {
            this.mensajeAlerta('info', 'ERROR', 'La Fecha debe estar dentro del periodo contable.');
          }
        });
        break;
      }
      
      case 'invEntradaSalida': {
        const dia = this.invEntradaSalidaFechaInicial.getDate();
        const mes = this.invEntradaSalidaFechaInicial.getMonth();
        const anio = this.invEntradaSalidaFechaInicial.getFullYear();
        this.panel = 'invEntradaSalida';
        this.contabilizadocService.verificarPeriodoContable(dia, mes, anio).subscribe((per) => {
          if (per[0].CONTADOR === 1) {
             //GSRF
             const comdesde = this.datePipe.transform(this.invEntradaSalidaFechaInicial,  'MM/yyyy');
             const comhasta= this.datePipe.transform(this.invEntradaSalidaFechaFinal, 'MM/yyyy');
             console.log( '11111111',comdesde, comhasta);
             if(this.RANGFECHA === 1){
               if(comdesde !== comhasta){
                 this.mensajeAlerta('info', 'ERROR', 'El rango de fechas no puede ser superior a un mes.');
                 return;
               }
             }
             //GSRF
            this.actualizarModeloInvEnSal(this.invEntradaSalidaFechaInicial, this.invEntradaSalidaFechaFinal,
              this.strTipoEntradaSalida, this.invEntradaSalidaTipo);
          } else {
            this.mensajeAlerta('info', 'ERROR', 'La Fecha debe estar dentro del periodo contable.');
          }
        });
        break;
      }
      
      case 'invTransferencia': {
        const dia = this.invTransferenciaFechaInicial.getDate();
        const mes = this.invTransferenciaFechaInicial.getMonth();
        const anio = this.invTransferenciaFechaInicial.getFullYear();
        this.panel = 'invTransferencia';
        this.contabilizadocService.verificarPeriodoContable(dia, mes, anio).subscribe((per) => {
          if (per[0].CONTADOR === 1) {
            //GSRF
            const comdesde = this.datePipe.transform(this.invTransferenciaFechaInicial,  'MM/yyyy');
            const comhasta= this.datePipe.transform(this.invTransferenciaFechaFinal, 'MM/yyyy');
            console.log( '11111111',comdesde, comhasta);
            if(this.RANGFECHA === 1){
              if(comdesde !== comhasta){
                this.mensajeAlerta('info', 'ERROR', 'El rango de fechas no puede ser superior a un mes.');
                return;
              }
            }
            //GSRF
            this.actualizarModeloInvTransfer(this.invTransferenciaFechaInicial, this.invTransferenciaFechaFinal, this.invTransferenciaTipo);
          } else {
            this.mensajeAlerta('info', 'ERROR', 'La Fecha debe estar dentro del periodo contable.');
          }
        });
        break;
      }
      
      case 'invTomaFisica': {
        const dia = this.invTomaFisicaFechaInicial.getDate();
        const mes = this.invTomaFisicaFechaInicial.getMonth();
        const anio = this.invTomaFisicaFechaInicial.getFullYear();
        this.panel = 'invTomaFisica';
        this.contabilizadocService.verificarPeriodoContable(dia, mes, anio).subscribe((per) => {
          if (per[0].CONTADOR === 1) {
            //GSRF
            const comdesde = this.datePipe.transform(this.invTomaFisicaFechaInicial,  'MM/yyyy');
            const comhasta= this.datePipe.transform(this.invTomaFisicaFechaFinal, 'MM/yyyy');
            console.log( '11111111',comdesde, comhasta);
            if(this.RANGFECHA === 1){
              if(comdesde !== comhasta){
                this.mensajeAlerta('info', 'ERROR', 'El rango de fechas no puede ser superior a un mes.');
                return;
              }
            }
            //GSRF
            this.actualizarModeloInvTomFis(this.invTomaFisicaFechaInicial, this.invTomaFisicaFechaFinal, this.invTomaFisicaTipo);
          } else {
            this.mensajeAlerta('info', 'ERROR', 'La Fecha debe estar dentro del periodo contable.');
          }
        });
        break;
      }
      
      case 'venFactura': {
        const dia = this.venFactFechaInicial.getDate();
        const mes = this.venFactFechaInicial.getMonth();
        const anio = this.venFactFechaInicial.getFullYear();
        this.panel = 'venFactura';
        this.contabilizadocService.verificarPeriodoContable(dia, mes, anio).subscribe((per) => {
          if (per[0].CONTADOR === 1) {
            //GSRF
            const comdesde = this.datePipe.transform(this.venFactFechaInicial,  'MM/yyyy');
            const comhasta= this.datePipe.transform(this.venFactFechaFinal, 'MM/yyyy');
            console.log( '11111111',comdesde, comhasta);
            if(this.RANGFECHA === 1){
              if(comdesde !== comhasta){
                this.mensajeAlerta('info', 'ERROR', 'El rango de fechas no puede ser superior a un mes.');
                return;
              }
            }
            //GSRF
            this.actualizarModeloVenFactura(this.venFactFechaInicial, this.venFactFechaFinal, this.strBodegaFactura, this.venFactTipo);
          } else {
            this.mensajeAlerta('info', 'ERROR', 'La Fecha debe estar dentro del periodo contable.');
          }
        });
        break;
      }
      
      case 'venNotaCredito': {
        const dia = this.venNotaCreditoFechaInicial.getDate();
        const mes = this.venNotaCreditoFechaInicial.getMonth();
        const anio = this.venNotaCreditoFechaInicial.getFullYear();
        this.panel = 'venNotaCredito';
        this.contabilizadocService.verificarPeriodoContable(dia, mes, anio).subscribe((per) => {
          if (per[0].CONTADOR === 1) {
            //GSRF
            const comdesde = this.datePipe.transform(this.venNotaCreditoFechaInicial,  'MM/yyyy');
            const comhasta= this.datePipe.transform(this.venNotaCreditoFechaFinal, 'MM/yyyy');
            console.log( '11111111',comdesde, comhasta);
            if(this.RANGFECHA === 1){
              if(comdesde !== comhasta){
                this.mensajeAlerta('info', 'ERROR', 'El rango de fechas no puede ser superior a un mes.');
                return;
              }
            }
            //GSRF
            this.actualizarModeloVenNotaCred(this.venNotaCreditoFechaInicial, this.venNotaCreditoFechaFinal,
              this.strBodegaNotaCredito, this.venNotaCreditoTipo);
          } else {
            this.mensajeAlerta('info', 'ERROR', 'La Fecha debe estar dentro del periodo contable.');
          }
        });
        break;
      }
      
      case 'venGuiaRemision': {
        const dia = this.venGuiaRemiFechaInicial.getDate();
        const mes = this.venGuiaRemiFechaInicial.getMonth();
        const anio = this.venGuiaRemiFechaInicial.getFullYear();
        this.panel = 'venGuiaRemision';
        this.contabilizadocService.verificarPeriodoContable(dia, mes, anio).subscribe((per) => {
          if (per[0].CONTADOR === 1) {
            //GSRF
            const comdesde = this.datePipe.transform(this.venGuiaRemiFechaInicial,  'MM/yyyy');
            const comhasta= this.datePipe.transform(this.venGuiaRemiFechaFinal, 'MM/yyyy');
            console.log( '11111111',comdesde, comhasta);
            if(this.RANGFECHA === 1){
              if(comdesde !== comhasta){
                this.mensajeAlerta('info', 'ERROR', 'El rango de fechas no puede ser superior a un mes.');
                return;
              }
            }
            //GSRF
            this.actualizarModeloVenGuiaRem(this.venGuiaRemiFechaInicial, this.venGuiaRemiFechaFinal,
              this.strBodegaGuiaRemi, this.venGuiaRemiTipo);
          } else {
            this.mensajeAlerta('info', 'ERROR', 'La Fecha debe estar dentro del periodo contable.');
          }
        });
        break;
      }
      
      case 'cxcComIngreso': {
        const dia = this.cxcComIngresoFechaInicial.getDate();
        const mes = this.cxcComIngresoFechaInicial.getMonth();
        const anio = this.cxcComIngresoFechaInicial.getFullYear();
        this.panel = 'cxcComIngreso';
        this.contabilizadocService.verificarPeriodoContable(dia, mes, anio).subscribe((per) => {
          if (per[0].CONTADOR === 1) {
            //GSRF
            const comdesde = this.datePipe.transform(this.cxcComIngresoFechaInicial,  'MM/yyyy');
            const comhasta= this.datePipe.transform(this.cxcComIngresoFechaFinal, 'MM/yyyy');
            console.log( '11111111',comdesde, comhasta);
            if(this.RANGFECHA === 1){
              if(comdesde !== comhasta){
                this.mensajeAlerta('info', 'ERROR', 'El rango de fechas no puede ser superior a un mes.');
                return;
              }
            }
            //GSRF
            this.actualizarModeloComIngr(this.cxcComIngresoFechaInicial, this.cxcComIngresoFechaFinal,
              this.strTipoDocComIngreso, this.cxcComIngresoTipo);
          } else {
            this.mensajeAlerta('info', 'ERROR', 'La Fecha debe estar dentro del periodo contable.');
          }
        });
        break;
      }
      
      case 'cxcDevAnticipo': {
        const dia = this.cxcDevAntFechaInicial.getDate();
        const mes = this.cxcDevAntFechaInicial.getMonth();
        const anio = this.cxcDevAntFechaInicial.getFullYear();
        this.panel = 'cxcDevAnticipo';
        this.contabilizadocService.verificarPeriodoContable(dia, mes, anio).subscribe((per) => {
          if (per[0].CONTADOR === 1) {
            //GSRF
            const comdesde = this.datePipe.transform(this.cxcDevAntFechaInicial,  'MM/yyyy');
            const comhasta= this.datePipe.transform(this.cxcDevAntFechaFinal, 'MM/yyyy');
            console.log( '11111111',comdesde, comhasta);
            if(this.RANGFECHA === 1){
              if(comdesde !== comhasta){
                this.mensajeAlerta('info', 'ERROR', 'El rango de fechas no puede ser superior a un mes.');
                return;
              }
            }
            //GSRF
            this.actualizarModeloDevAnt(this.cxcDevAntFechaInicial, this.cxcDevAntFechaFinal, this.cxcDevAntTipo);
          } else {
            this.mensajeAlerta('info', 'ERROR', 'La Fecha debe estar dentro del periodo contable.');
          }
        });
        break;
      }
      
      case 'cxcNotaCredito': {
        const dia = this.cxcNotaCredFechaInicial.getDate();
        const mes = this.cxcNotaCredFechaInicial.getMonth();
        const anio = this.cxcNotaCredFechaInicial.getFullYear();
        this.panel = 'cxcNotaCredito';
        this.contabilizadocService.verificarPeriodoContable(dia, mes, anio).subscribe((per) => {
          if (per[0].CONTADOR === 1) {
            //GSRF
            const comdesde = this.datePipe.transform(this.cxcNotaCredFechaInicial,  'MM/yyyy');
            const comhasta= this.datePipe.transform(this.cxcNotaCredFechaFinal, 'MM/yyyy');
            console.log( '11111111',comdesde, comhasta);
            if(this.RANGFECHA === 1){
              if(comdesde !== comhasta){
                this.mensajeAlerta('info', 'ERROR', 'El rango de fechas no puede ser superior a un mes.');
                return;
              }
            }
            //GSRF
            this.actualizarModeloCXCNotaCred(this.cxcNotaCredFechaInicial, this.cxcNotaCredFechaFinal, this.cxcNotaCredTipo);
          } else {
            this.mensajeAlerta('info', 'ERROR', 'La Fecha debe estar dentro del periodo contable.');
          }
        });
        break;
      }
      case 'cxcNotaDebito': {
        const dia = this.cxcNotaDebitoFechaInicial.getDate();
        const mes = this.cxcNotaDebitoFechaInicial.getMonth();
        const anio = this.cxcNotaDebitoFechaInicial.getFullYear();
        this.panel = 'cxcNotaDebito';
        this.contabilizadocService.verificarPeriodoContable(dia, mes, anio).subscribe((per) => {
          if (per[0].CONTADOR === 1) {
             //GSRF
             const comdesde = this.datePipe.transform(this.cxcNotaDebitoFechaInicial,  'MM/yyyy');
             const comhasta= this.datePipe.transform(this.cxcNotaDebitoFechaFinal, 'MM/yyyy');
             console.log( '11111111',comdesde, comhasta);
             if(this.RANGFECHA === 1){
               if(comdesde !== comhasta){
                 this.mensajeAlerta('info', 'ERROR', 'El rango de fechas no puede ser superior a un mes.');
                 return;
               }
             }
             //GSRF
            this.actualizarModeloCXCNotaDebito(this.cxcNotaDebitoFechaInicial, this.cxcNotaDebitoFechaFinal, this.cxcNotaDebitoTipo);
          } else {
            this.mensajeAlerta('info', 'ERROR', 'La Fecha debe estar dentro del periodo contable.');
          }
        });
        break;
      }
      
      case 'cxcRetencion': {
        const dia = this.cxcRetencionFechaInicial.getDate();
        const mes = this.cxcRetencionFechaInicial.getMonth();
        const anio = this.cxcRetencionFechaInicial.getFullYear();
        this.panel = 'cxcRetencion';
        this.contabilizadocService.verificarPeriodoContable(dia, mes, anio).subscribe((per) => {
          if (per[0].CONTADOR === 1) {
            //GSRF
            const comdesde = this.datePipe.transform(this.cxcRetencionFechaInicial,  'MM/yyyy');
            const comhasta= this.datePipe.transform(this.cxcRetencionFechaFinal, 'MM/yyyy');
            console.log( '11111111',comdesde, comhasta);
            if(this.RANGFECHA === 1){
              if(comdesde !== comhasta){
                this.mensajeAlerta('info', 'ERROR', 'El rango de fechas no puede ser superior a un mes.');
                return;
              }
            }
            //GSRF
            this.actualizarModeloCXCRetencion(this.cxcRetencionFechaInicial, this.cxcRetencionFechaFinal, this.cxcRetencionTipo);
          } else {
            this.mensajeAlerta('info', 'ERROR', 'La Fecha debe estar dentro del periodo contable.');
          }
        });
        break;
      }
      
      case 'cxpEgreso': {
        const dia = this.cxpEgresoFechaInicial.getDate();
        const mes = this.cxpEgresoFechaInicial.getMonth();
        const anio = this.cxpEgresoFechaInicial.getFullYear();
        this.panel = 'cxpEgreso';
        this.contabilizadocService.verificarPeriodoContable(dia, mes, anio).subscribe((per) => {
          if (per[0].CONTADOR === 1) {
            //GSRF
            const comdesde = this.datePipe.transform(this.cxpEgresoFechaInicial,  'MM/yyyy');
            const comhasta= this.datePipe.transform(this.cxpEgresoFechaFinal, 'MM/yyyy');
            console.log( '11111111',comdesde, comhasta);
            if(this.RANGFECHA === 1){
              if(comdesde !== comhasta){
                this.mensajeAlerta('info', 'ERROR', 'El rango de fechas no puede ser superior a un mes.');
                return;
              }
            }
            //GSRF
            this.actualizarModeloCXPEgreso(this.cxpEgresoFechaInicial, this.cxpEgresoFechaFinal, this.cxpEgresoTipo);
          } else {
            this.mensajeAlerta('info', 'ERROR', 'La Fecha debe estar dentro del periodo contable.');
          }
        });
        break;
      }

      case 'nomRolPago': {
        const dia = this.nomRolFechaInicial.getDate();
        const mes = this.nomRolFechaInicial.getMonth();
        const anio = this.nomRolFechaInicial.getFullYear();
        this.panel = 'nomRolPago';
        this.contabilizadocService.verificarPeriodoContable(dia, mes, anio).subscribe((per) => {
          if (per[0].CONTADOR === 1) {
            //GSRF
            const comdesde = this.datePipe.transform(this.nomRolFechaInicial,  'MM/yyyy');
            const comhasta= this.datePipe.transform(this.nomRolFechaFinal, 'MM/yyyy');
            console.log( '11111111',comdesde, comhasta);
            if(this.RANGFECHA === 1){
              if(comdesde !== comhasta){
                this.mensajeAlerta('info', 'ERROR', 'El rango de fechas no puede ser superior a un mes.');
                return;
              }
            }
            //GSRF
            this.cargarFacturas(this.nomRolFechaInicial, this.nomRolFechaFinal, this.nomRolTipo);
          } else {
            this.mensajeAlerta('info', 'ERROR', 'La Fecha debe estar dentro del periodo contable.');
          }
        });
        break;
      }

      case 'prodOrdProd': {
        
        
        const dia = this.prodOrdProdFechaInicial.getDate();
        const mes = this.prodOrdProdFechaInicial.getMonth();
        const anio = this.prodOrdProdFechaInicial.getFullYear();
        this.panel = 'prodOrdProd';
        this.contabilizadocService.verificarPeriodoContable(dia, mes, anio).subscribe((per) => {
          if (per[0].CONTADOR === 1) {
            //GSRF
            const comdesde = this.datePipe.transform(this.prodOrdProdFechaInicial,  'MM/yyyy');
            const comhasta= this.datePipe.transform(this.prodOrdProdFechaFinal, 'MM/yyyy');
            console.log( '11111111',comdesde, comhasta);
            if(this.RANGFECHA === 1){
              if(comdesde !== comhasta){
                this.mensajeAlerta('info', 'ERROR', 'El rango de fechas no puede ser superior a un mes.');
                return;
              }
            }
            //GSRF
            this.ordenesProduccion();
          } else {
            this.mensajeAlerta('info', 'ERROR', 'La Fecha debe estar dentro del periodo contable.');
          }
        });
        break;
      }
      
      
    }
  }

  async ordenesProduccion() {
    let proc = '';
    let rs: any;
    let rs1;
    let contador = 0;

    const fechaIni = this.datePipe.transform(this.prodOrdProdFechaInicial, 'dd/MM/yyyy');
    const fechaFin = this.datePipe.transform(this.prodOrdProdFechaFinal, 'dd/MM/yyyy');

    proc = 'select a.* from prod_encordpro a where ';
    proc = proc + ' encordpro_estado<>\'A\' and com_codigo=\'01\'';
    proc = proc + ' and to_date(to_char(encordpro_fechaemision,\'dd/mm/yyyy\'),\'dd/mm/yyyy\')>=to_date(';
    proc = proc + '\'' + fechaIni + '\',\'dd/mm/yyyy\')';
    proc = proc + ' and to_date(to_char(encordpro_fechaemision,\'dd/mm/yyyy\'),\'dd/mm/yyyy\')<=to_date(';
    proc = proc + '\'' + fechaFin + '\',\'dd/mm/yyyy\') ';
    proc = proc + ' order by encordpro_fechaemision  asc'

    try {
      rs = await this.contabilizadocService.getOrdenesProduccion(fechaIni, fechaFin);
      console.log(rs);
      this.contabilizadocService.prodOrdProd = null;
      const detalle = [];
      if (rs.length > 0) {
        for (const orden of rs) {
          let reg: ConProdOrdProd = {};
          contador = 0;
          console.log(this.prodOrdProdTipo);
          if (this.prodOrdProdTipo === 'N') {
            // Código contable Asiento Producto en proceso
            const mes = new Date(orden.ENCORDPRO_FECHAINICIO);
            console.log(mes.getMonth());
            try {
              rs1 = await this.contabilizadocService.getAsiNroProdProc(orden.ENCORDPRO_NUMERO, mes.getMonth() +1);
              console.log(rs1);
              if (rs1.length > 0) {
                contador = contador + 1;
                rs1 = null;
              }
            } catch (err1) {
              this.mensajeAlerta('error', 'Error1', err1.error);
            }
            
            
            // Código contable Asiento Producto Termado
            if (orden.ENCORDPRO_FECHAENTRADA !== null) {
              const mes = new Date(orden.ENCORDPRO_FECHAENTRADA);

              try {
                rs1 = await this.contabilizadocService.getAsiNroProdTer(orden.ENCORDPRO_NUMERO, mes.getMonth() +1);
                console.log(rs1);
                if (rs1.length > 0) {
                  contador = contador + 1;
                  rs1 = null;
                }
              } catch (err2) {
                this.mensajeAlerta('error', 'Error2', err2.error);
              }
            }
  
            // Código el asiento de materia prima al costo
            if (orden.ENCORDPRO_FECHAENTRADA !== null) {
              const mes = new Date(orden.ENCORDPRO_FECHAENTRADA);
              console.log(mes);
              try {
                rs1 = await this.contabilizadocService.getAsiNroMatPrimaCosto(orden.ENCORDPRO_NUMERO,  mes.getMonth() + 1);
                console.log(rs1);
                if (rs1.length > 0) {
                  contador = contador + 1;
                  rs1 = null;
                }
              } catch (err3) {
                this.mensajeAlerta('error', 'Error3', err3.error);
              }
            }                
                     
          } 
          
          if (contador != 2) {
            
            if (orden.ENCORDPRO_FECHAEMISION !== null) {
              orden.ENCORDPRO_FECHAEMISION = this.datePipe.transform(orden.ENCORDPRO_FECHAEMISION, 'dd/MM/yyyy HH:mm:ss a');
            }
            if (orden.ENCORDPRO_FECHAINICIO !== null) {
              orden.ENCORDPRO_FECHAINICIO = this.datePipe.transform(orden.ENCORDPRO_FECHAINICIO, 'dd/MM/yyyy');
            }
            if (orden.ENCORDPRO_FECHAFIN !== null) {
              orden.ENCORDPRO_FECHAFIN = this.datePipe.transform(orden.ENCORDPRO_FECHAFIN, 'dd/MM/yyyy');
            }
            if (orden.ENCORDPRO_FECHAENTRADA_ !== null) {
              orden.ENCORDPRO_FECHAENTRADA= this.datePipe.transform(orden.ENCORDPRO_FECHAENTRADA, 'dd/MM/yyyy HH:mm:ss a');
            }
            reg = orden;
            reg.STATUS = null;
            reg.ASI_NRO = null;
            reg.CHECKCONT = false;

            detalle.push(reg);
          }
  
        }
        this.contabilizadocService.prodOrdProd = detalle;
      }
      

      

    } catch (err) {
      this.mensajeAlerta('error', 'Error', err.error);
    }

  }
  
  actualizarModeloMovBan(fechaIni, fechaFin, tipos, tipoVista) {
    const diaIni = fechaIni.getDate();
    const mesIni = fechaIni.getMonth();
    const anioIni = fechaIni.getFullYear();
    
    const diaFin = fechaFin.getDate();
    const mesFin = fechaFin.getMonth();
    const anioFin = fechaFin.getFullYear();
    
    this.contabilizadocService.verificarPeriodoContable(diaIni, mesIni, anioIni).subscribe((periodoini) => {
      if (periodoini[0].CONTADOR === 1) {
        this.contabilizadocService.verificarPeriodoContable(diaFin, mesFin, anioFin).subscribe((periodofin) => {
          if (periodofin[0].CONTADOR === 1) {
            
            const fechaInicio = fechaIni.toLocaleString('en-GB').split(',');
            const fechaFinal = fechaFin.toLocaleString('en-GB').split(',');
            
            let condicion = 'COM_CODIGO = \'01\' AND to_date(to_char(trnban_FECHA,\'dd/mm/yyyy\'),\'dd/mm/yyyy\')' +
              ' BETWEEN TO_DATE(\'' + fechaInicio[0] + '\',\'dd/mm/yyyy\') AND TO_DATE(\'' + fechaFinal[0] +
              '\',\'dd/mm/yyyy\') AND TRNBAN_ANULADO <> 1 AND TRNBAN_ORIGEN = \'BAN\'';
            
            if (tipoVista === 'N') {
              condicion = condicion + ' AND ASI_NRO IS NULL';
            }
            const tipo = this.strTipoMovBan;
            
            if (tipo.name !== 'TODOS') {
              if (tipo.codigo !== '') {
                condicion = condicion + ' AND TRNBAN_TIPO = \'' + tipo.codigo + '\'';
              }
            }
            condicion = condicion + ' ORDER BY TRNBAN_FECHA';
            
            this.contabilizadocService.obtenerMovimientosBancos(condicion).subscribe((datos) => {
              this.contabilizadocService.movimientosBancos = datos;
              if (this.contabilizadocService.movimientosBancos.length > 0) {
                this.contabilizadocService.movimientosBancos.map((item) => {
                  item.TRNBAN_FECHAVENCE = item.TRNBAN_FECHAVENCE === null ? '' : item.TRNBAN_FECHAVENCE;
                  item.TRNBAN_FECHA = item.TRNBAN_FECHA === null ? '' : item.TRNBAN_FECHA;
                  
                  if (item.TRNBAN_FECHA.toString().includes('-') && item.TRNBAN_FECHA !== '') {
                    item.TRNBAN_FECHA = this.datePipe.transform(item.TRNBAN_FECHA, 'dd/MM/yyyy');
                  }
                  
                  if (item.TRNBAN_FECHAVENCE.toString().includes('-') && item.TRNBAN_FECHAVENCE !== '') {
                    item.TRNBAN_FECHAVENCE = this.datePipe.transform(item.TRNBAN_FECHAVENCE, 'dd/MM/yyyy');
                  }
                  
                  item.TRNBAN_IMPORTE = item.TRNBAN_IMPORTE.toFixed(2);
                  item.CHECKCONT = false;
                  item.STATUS = null;
                });
              } else {
                this.mensajeAlerta('info', 'Información', 'No se han encontrado registros que coincidan con los parámetros ' +
                  'seleccionados');
              }
            });
          } else {
            this.mensajeAlerta('info', 'Información', 'La Fecha final está fuera del periodo contable.');
            return;
          }
        });
      } else {
        this.mensajeAlerta('info', 'Información', 'La Fecha inicial está fuera del periodo contable.');
        return;
      }
    });
    
  }
  
  actualizarModeloMovCaj(fechaIni, fechaFin, tipos, tipoVista) {
    const diaIni = fechaIni.getDate();
    const mesIni = fechaIni.getMonth();
    const anioIni = fechaIni.getFullYear();
    
    const diaFin = fechaFin.getDate();
    const mesFin = fechaFin.getMonth();
    const anioFin = fechaFin.getFullYear();
    
    this.contabilizadocService.verificarPeriodoContable(diaIni, mesIni, anioIni).subscribe((periodoini) => {
      if (periodoini[0].CONTADOR === 1) {
        this.contabilizadocService.verificarPeriodoContable(diaFin, mesFin, anioFin).subscribe((periodofin) => {
          if (periodofin[0].CONTADOR === 1) {
            
            const fechaInicio = fechaIni.toLocaleString('en-GB').split(',');
            const fechaFinal = fechaFin.toLocaleString('en-GB').split(',');
            
            let condicion = 'COM_CODIGO = \'01\' AND TRNCAJ_FECHA' +
              ' BETWEEN TO_DATE(\'' + fechaInicio[0] + '\',\'dd/mm/yyyy\') AND TO_DATE(\'' + fechaFinal[0] +
              '\',\'dd/mm/yyyy\') AND TRNCAJ_ANULADO <> 1';
            
            if (tipoVista === 'N') {
              condicion = condicion + ' AND ASI_NRO IS NULL';
            }
            const tipo = this.strTipoMovBan;
            
            if (tipo.name !== 'TODOS') {
              if (tipo.codigo !== '') {
                condicion = condicion + ' AND TRNCAJ_TIPO = \'' + tipo.codigo + '\'';
              }
            }
            condicion = condicion + ' ORDER BY TRNCAJ_FECHA';
            
            this.contabilizadocService.obtenerMovimientosCaja(condicion).subscribe((datos) => {
              this.contabilizadocService.movimientosCaja = datos;
              if (this.contabilizadocService.movimientosCaja.length > 0) {
                this.contabilizadocService.movimientosCaja.map((item) => {
                  item.TRNCAJ_FECHA = item.TRNCAJ_FECHA === null ? '' : item.TRNCAJ_FECHA;
                  if (item.TRNCAJ_FECHA.toString().includes('-') && item.TRNCAJ_FECHA !== '') {
                    item.TRNCAJ_FECHA = this.datePipe.transform(item.TRNCAJ_FECHA, 'dd/MM/yyyy');
                  }
                  item.TRNCAJ_IMPORTE = item.TRNCAJ_IMPORTE.toFixed(2);
                  item.CHECKCONT = false;
                  item.STATUS = null;
                });
              } else {
                this.mensajeAlerta('info', 'Información', 'No se han encontrado registros que coincidan con los parámetros ' +
                  'seleccionados');
              }
            });
          } else {
            this.mensajeAlerta('info', 'Información', 'La Fecha final está fuera del periodo contable.');
            return;
          }
        });
      } else {
        this.mensajeAlerta('info', 'Información', 'La Fecha inicial está fuera del periodo contable.');
        return;
      }
    });
    
  }
  
  actualizarModeloComFactPro(fechaIni, fechaFin, tipoVista) {
    const diaIni = fechaIni.getDate();
    const mesIni = fechaIni.getMonth();
    const anioIni = fechaIni.getFullYear();
    
    const diaFin = fechaFin.getDate();
    const mesFin = fechaFin.getMonth();
    const anioFin = fechaFin.getFullYear();
    
    this.contabilizadocService.verificarPeriodoContable(diaIni, mesIni, anioIni).subscribe((periodoini) => {
      if (periodoini[0].CONTADOR === 1) {
        this.contabilizadocService.verificarPeriodoContable(diaFin, mesFin, anioFin).subscribe((periodofin) => {
          if (periodofin[0].CONTADOR === 1) {
            
            const fechaInicio = fechaIni.toLocaleString('en-GB').split(',');
            const fechaFinal = fechaFin.toLocaleString('en-GB').split(',');
            
            let condicion = 'O.COM_CODIGO = \'01\' AND o.ENCFACPRO_FECHAEMISION' +
              ' BETWEEN TO_DATE(\'' + fechaInicio[0] + '\',\'dd/mm/yyyy\') AND TO_DATE(\'' + fechaFinal[0] +
              '\',\'dd/mm/yyyy\') AND ENCFACPRO_ESTADO <> \'A\'';
            
            if (tipoVista === 'N') {
              condicion = condicion + ' AND O.ASI_NRO IS NULL';
            }
            
            this.contabilizadocService.obtenerFacturasProveedor(condicion).subscribe((datos) => {
              this.contabilizadocService.facturasProveedores = datos;
              if (this.contabilizadocService.facturasProveedores !== null) {
                this.contabilizadocService.facturasProveedores.map((resultado) => {
                  resultado.ENCFACPRO_FECHAEMISION = resultado.ENCFACPRO_FECHAEMISION === null ? ''  : resultado.ENCFACPRO_FECHAEMISION;
                  resultado.ENCFACPRO_FECHAVENCIMIENTO = resultado.ENCFACPRO_FECHAVENCIMIENTO === null ? ''  : resultado.ENCFACPRO_FECHAVENCIMIENTO;
                  if (resultado.ENCFACPRO_FECHAEMISION.toString().includes('-') && resultado.ENCFACPRO_FECHAEMISION !== '') {
                    resultado.ENCFACPRO_FECHAEMISION = this.datePipe.transform(resultado.ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
                  }
                  
                  if (resultado.ENCFACPRO_FECHAVENCIMIENTO.toString().includes('-') && resultado.ENCFACPRO_FECHAVENCIMIENTO !== '') {
                    resultado.ENCFACPRO_FECHAVENCIMIENTO = this.datePipe.transform(resultado.ENCFACPRO_FECHAVENCIMIENTO, 'dd/MM/yyyy');
                  }
                  
                  resultado.ENCFACPRO_TOTAL = resultado.ENCFACPRO_TOTAL === null ? Number(0).toFixed(2) : resultado.ENCFACPRO_TOTAL.toFixed(2);
                  resultado.CHECKCONT = false;
                  resultado.STATUS = null;
                });
              } else if (this.contabilizadocService.facturasProveedores === null) {
                this.mensajeAlerta('info', 'Información', 'No se han encontrado registros que coincidan con los parámetros ' +
                  'seleccionados');
              }
            });
          } else {
            this.mensajeAlerta('info', 'Información', 'La Fecha final está fuera del periodo contable.');
            return;
          }
        });
      } else {
        this.mensajeAlerta('info', 'Información', 'La Fecha inicial está fuera del periodo contable.');
        return;
      }
    });
    
  }
  
  actualizarModeloComDevCom(fechaIni, fechaFin, tipoVista) {
    const diaIni = fechaIni.getDate();
    const mesIni = fechaIni.getMonth();
    const anioIni = fechaIni.getFullYear();
    
    const diaFin = fechaFin.getDate();
    const mesFin = fechaFin.getMonth();
    const anioFin = fechaFin.getFullYear();
    
    this.contabilizadocService.verificarPeriodoContable(diaIni, mesIni, anioIni).subscribe((periodoini) => {
      if (periodoini[0].CONTADOR === 1) {
        this.contabilizadocService.verificarPeriodoContable(diaFin, mesFin, anioFin).subscribe((periodofin) => {
          if (periodofin[0].CONTADOR === 1) {
            
            const fechaInicio = fechaIni.toLocaleString('en-GB').split(',');
            const fechaFinal = fechaFin.toLocaleString('en-GB').split(',');
            
            let condicion = 'o.COM_CODIGO = \'01\' AND to_date(to_char(o.ENCDEV_FECHAEMISION,\'dd/mm/yyyy\'),\'dd/mm/yyyy\')' +
              ' BETWEEN TO_DATE(\'' + fechaInicio[0] + '\',\'dd/mm/yyyy\') AND TO_DATE(\'' + fechaFinal[0] +
              '\',\'dd/mm/yyyy\') AND ENCDEV_ESTADO <> \'A\'';
            
            if (tipoVista === 'N') {
              condicion = condicion + ' AND O.ASI_NRO IS NULL';
            }
            
            this.contabilizadocService.obtenerDevolucionesProveedor(condicion).subscribe((datos) => {
              this.contabilizadocService.devolucionesProveedores = datos;
              if (this.contabilizadocService.devolucionesProveedores !== null) {
                this.contabilizadocService.devolucionesProveedores.map((resultado) => {
                  resultado.ENCDEV_FECHAEMISION = resultado.ENCDEV_FECHAEMISION === null ? ''  : resultado.ENCDEV_FECHAEMISION;
                  resultado.ENCDEV_FECHACADUCIDAD = resultado.ENCDEV_FECHACADUCIDAD === null ? ''  : resultado.ENCDEV_FECHACADUCIDAD;
                  if (resultado.ENCDEV_FECHAEMISION.toString().includes('-') && resultado.ENCDEV_FECHAEMISION !== '') {
                    resultado.ENCDEV_FECHAEMISION = this.datePipe.transform(resultado.ENCDEV_FECHAEMISION, 'dd/MM/yyyy');
                  }
                  
                  if (resultado.ENCDEV_FECHACADUCIDAD .toString().includes('-') && resultado.ENCDEV_FECHACADUCIDAD  !== '') {
                    resultado.ENCDEV_FECHACADUCIDAD = this.datePipe.transform(resultado.ENCDEV_FECHACADUCIDAD , 'dd/MM/yyyy');
                  }
                  
                  resultado.ENCDEV_TOTAL = resultado.ENCDEV_TOTAL.toFixed(2);
                  resultado.CHECKCONT = false;
                  resultado.STATUS = null;
                });
              } else if (this.contabilizadocService.devolucionesProveedores === null) {
                this.mensajeAlerta('info', 'Información', 'No se han encontrado registros que coincidan con los parámetros ' +
                  'seleccionados');
              }
            });
          } else {
            this.mensajeAlerta('info', 'Información', 'La Fecha final está fuera del periodo contable.');
            return;
          }
        });
      } else {
        this.mensajeAlerta('info', 'Información', 'La Fecha inicial está fuera del periodo contable.');
        return;
      }
    });
    
  }
  
  actualizarModeloComNotRec(fechaIni, fechaFin, tipoVista) {
    const diaIni = fechaIni.getDate();
    const mesIni = fechaIni.getMonth();
    const anioIni = fechaIni.getFullYear();
    
    const diaFin = fechaFin.getDate();
    const mesFin = fechaFin.getMonth();
    const anioFin = fechaFin.getFullYear();
    
    this.contabilizadocService.verificarPeriodoContable(diaIni, mesIni, anioIni).subscribe((periodoini) => {
      if (periodoini[0].CONTADOR === 1) {
        this.contabilizadocService.verificarPeriodoContable(diaFin, mesFin, anioFin).subscribe((periodofin) => {
          if (periodofin[0].CONTADOR === 1) {
            
            const fechaInicio = fechaIni.toLocaleString('en-GB').split(',');
            const fechaFinal = fechaFin.toLocaleString('en-GB').split(',');
            
            let condicion = 'o.COM_CODIGO = \'01\' AND o.ENCNOTREC_FECHAEMISION' +
              '  BETWEEN TO_DATE(\'' + fechaInicio[0] + '\',\'dd/mm/yyyy\') AND TO_DATE(\'' + fechaFinal[0] +
              '\',\'dd/mm/yyyy\') AND o.ENCNOTREC_ESTADO <> \'A\'';
            
            if (tipoVista === 'N') {
              condicion = condicion + ' AND O.ASI_NRO IS NULL';
            }
            
            this.contabilizadocService.obtenerNotasRecepcion(condicion).subscribe((datos) => {
              this.contabilizadocService.notasDeRecepcion = [];
              if (datos !== null) {
                for (const dato of datos) {
                  const item: ConComNotRec = {};
                  item.ASI_NRO = dato.ASI_NRO;
                  item.CHECKCONT = false;
                  item.NUMERO = dato.Numero;
                  item.ENCNOTREC_OBSERVACION = dato.ENCNOTREC_OBSERVACION;
                  item.NOMBRE_PROVEEDOR = dato['Nombre proveedor'];
                  item.TOTAL = Number(dato.Total).toFixed(2);
                  item.ENCNOTREC_FECHAEMISION = dato['Fec_Emi.'] === null ? ''  : dato['Fec_Emi.'];
                  item.ENCNOTREC_FECHAENTREGA = dato.ENCNOTREC_FECHAENTREGA === null ? ''  : dato.ENCNOTREC_FECHAENTREGA;
                  
                  if (item.ENCNOTREC_FECHAEMISION.toString().includes('-') && item.ENCNOTREC_FECHAEMISION !== '') {
                    item.ENCNOTREC_FECHAEMISION = this.datePipe.transform(item.ENCNOTREC_FECHAEMISION, 'dd/MM/yyyy');
                  }
                  if (item.ENCNOTREC_FECHAENTREGA.toString().includes('-') && item.ENCNOTREC_FECHAENTREGA  !== '') {
                    item.ENCNOTREC_FECHAENTREGA = this.datePipe.transform(item.ENCNOTREC_FECHAENTREGA , 'dd/MM/yyyy');
                  }
                  this.contabilizadocService.notasDeRecepcion.push(item);
                }
                this.aggrid.refreshaggrid(this.contabilizadocService.notasDeRecepcion, this.defaultColConNotRec.objeto);
              } else if (datos === null) {
                this.mensajeAlerta('info', 'Información', 'No se han encontrado registros que coincidan con los parámetros ' +
                  'seleccionados');
              }
            });
          } else {
            this.mensajeAlerta('info', 'Información', 'La Fecha final está fuera del periodo contable.');
            return;
          }
        });
      } else {
        this.mensajeAlerta('info', 'Información', 'La Fecha inicial está fuera del periodo contable.');
        return;
      }
    });
  }
  
  actualizarModeloInvEnSal(fechaIni, fechaFin, tipos, tipoVista) {
    const diaIni = fechaIni.getDate();
    const mesIni = fechaIni.getMonth();
    const anioIni = fechaIni.getFullYear();
    
    const diaFin = fechaFin.getDate();
    const mesFin = fechaFin.getMonth();
    const anioFin = fechaFin.getFullYear();
    
    this.contabilizadocService.verificarPeriodoContable(diaIni, mesIni, anioIni).subscribe((periodoini) => {
      if (periodoini[0].CONTADOR === 1) {
        this.contabilizadocService.verificarPeriodoContable(diaFin, mesFin, anioFin).subscribe((periodofin) => {
          if (periodofin[0].CONTADOR === 1) {
            
            const fechaInicio = fechaIni.toLocaleString('en-GB').split(',');
            const fechaFinal = fechaFin.toLocaleString('en-GB').split(',');
            
            let condicion = 'COM_CODIGO = \'01\' AND KAR_FECHA BETWEEN' +
              ' TO_DATE(\'' + fechaInicio[0] + '\',\'dd/mm/yyyy\') AND TO_DATE(\'' + fechaFinal[0] +
              '\',\'dd/mm/yyyy\')';
            
            if (tipoVista === 'N') {
              condicion = condicion + ' AND ASI_NRO IS NULL';
            }
            const tipo = tipos;
            
            if (tipo.codigo === 1) {
              condicion = condicion + ' AND KAR_TIPO = \'EN\'';
            } else if (tipo.codigo === 2) {
              condicion = condicion + ' AND KAR_TIPO = \'SA\'';
            } else if (tipo.codigo === 3) {
              condicion = condicion + ' AND KAR_TIPO = \'EN\'';
            } else if (tipo.codigo === 4) {
              condicion = condicion + ' AND KAR_TIPO = \'SA\'';
            }
            
            condicion = condicion + ' ORDER BY KAR_TIPO ASC, KAR_NRO ASC';
            
            this.contabilizadocService.obtenerInvEntradaSalida(condicion).subscribe((datos) => {
              this.contabilizadocService.entradaSalida = [];
              if (datos !== null) {
                for (const dato of datos) {
                  const item: ConInvEntradaSalida = {};
                  item.CHECKCONT = false;
                  item.STATUS = null;
                  item.ASI_NRO = dato.ASIENTO;
                  item.HORA = dato.HORA;
                  item.NUMERO = dato.NÚMERO;
                  item.TIPO = dato.TIPO;
                  item.ORDPRO = dato.ORDPRO;
                  item.COMPROBANTE = dato.COMPROBANTE;
                  item.COMENTARIO = dato.COMENTARIO;
                  item.FECHA = dato.FECHA === null ? '' : dato.FECHA;
                  if (item.FECHA.toString().includes('-') && item.FECHA !== '') {
                    item.FECHA = this.datePipe.transform(item.FECHA, 'dd/MM/yyyy');
                  }
                  
                  this.contabilizadocService.entradaSalida.push(item);
                }
                this.aggrid.refreshaggrid(this.contabilizadocService.entradaSalida, this.defaultColConEntradaSalida.objeto);
                
              } else if (datos === null) {
                this.mensajeAlerta('info', 'Información', 'No se han encontrado registros que coincidan con los parámetros ' +
                  'seleccionados');
              }
            });
          } else {
            this.mensajeAlerta('info', 'Información', 'La Fecha final está fuera del periodo contable.');
            return;
          }
        });
      } else {
        this.mensajeAlerta('info', 'Información', 'La Fecha inicial está fuera del periodo contable.');
        return;
      }
    });
    
  }
  
  actualizarModeloInvTransfer(fechaIni, fechaFin, tipoVista) {
    const diaIni = fechaIni.getDate();
    const mesIni = fechaIni.getMonth();
    const anioIni = fechaIni.getFullYear();
    
    const diaFin = fechaFin.getDate();
    const mesFin = fechaFin.getMonth();
    const anioFin = fechaFin.getFullYear();
    
    this.contabilizadocService.verificarPeriodoContable(diaIni, mesIni, anioIni).subscribe((periodoini) => {
      if (periodoini[0].CONTADOR === 1) {
        this.contabilizadocService.verificarPeriodoContable(diaFin, mesFin, anioFin).subscribe((periodofin) => {
          if (periodofin[0].CONTADOR === 1) {
            
            const fechaInicio = fechaIni.toLocaleString('en-GB').split(',');
            const fechaFinal = fechaFin.toLocaleString('en-GB').split(',');
            
            let condicion = 'E.COM_CODIGO = \'01\' AND E.TRANSFER_FECHAEMISION ' +
              'BETWEEN TO_DATE(\'' + fechaInicio[0] + '\',\'dd/mm/yyyy\') AND TO_DATE(\'' + fechaFinal[0] + '\',\'dd/mm/yyyy\')' +
              ' AND E.TRANSFER_ESTADO = \'T\'';
            
            if (tipoVista === 'N') {
              condicion = condicion + ' AND E.ASI_NRO IS NULL';
            }
            
            condicion = condicion + ' ORDER BY E.TRANSFER_NRO ASC';
            
            this.contabilizadocService.obtenerDatosTransferencia(condicion).subscribe((datos) => {
              this.contabilizadocService.transferencias = [];
              if (datos !== null) {
                for (const dato of datos) {
                  const item: ConInvTransferencias = {};
                  item.CHECKCONT = false;
                  item.STATUS = null;
                  item.ASI_NRO = dato.ASIENTO;
                  item.NUMERO = dato.NÚMERO;
                  item.COMPROBANTE = dato.COMPROBANTE;
                  item.COMENTARIO = dato.COMENTARIO;
                  item.FECHA_EMISION = dato.FECHA_EMISIÓN === null ? '' : dato.FECHA_EMISIÓN;
                  item.FECHA_TRANSFER = dato.FECHA_TRANSFER === null ? '' : dato.FECHA_TRANSFER;
                  item.FECHA_AUTORIZA = dato.FECHA_AUTORIZA === null ? '' : dato.FECHA_AUTORIZA;
                  
                  if (item.FECHA_EMISION.toString().includes('-') && item.FECHA_EMISION !== '') {
                    item.FECHA_EMISION = this.datePipe.transform(item.FECHA_EMISION, 'dd/MM/yyyy');
                  }
                  if (item.FECHA_TRANSFER.toString().includes('-') && item.FECHA_TRANSFER !== '') {
                    item.FECHA_TRANSFER = this.datePipe.transform(item.FECHA_TRANSFER, 'dd/MM/yyyy');
                  }
                  if (item.FECHA_AUTORIZA.toString().includes('-') && item.FECHA_AUTORIZA !== '') {
                    item.FECHA_AUTORIZA = this.datePipe.transform(item.FECHA_AUTORIZA, 'dd/MM/yyyy');
                  }
                  
                  this.contabilizadocService.transferencias.push(item);
                }
                this.aggrid.refreshaggrid(this.contabilizadocService.transferencias, this.defaultColConTranferencia.objeto);
                
              } else if (datos === null) {
                this.mensajeAlerta('info', 'Información', 'No se han encontrado registros que coincidan con los parámetros ' +
                  'seleccionados');
              }
            });
            
          } else {
            this.mensajeAlerta('info', 'Información', 'La Fecha final está fuera del periodo contable.');
            return;
          }
        });
      } else {
        this.mensajeAlerta('info', 'Información', 'La Fecha inicial está fuera del periodo contable.');
        return;
      }
    });
  }
  
  actualizarModeloInvTomFis(fechaIni, fechaFin, tipoVista) {
    const diaIni = fechaIni.getDate();
    const mesIni = fechaIni.getMonth();
    const anioIni = fechaIni.getFullYear();
    
    const diaFin = fechaFin.getDate();
    const mesFin = fechaFin.getMonth();
    const anioFin = fechaFin.getFullYear();
    
    this.contabilizadocService.verificarPeriodoContable(diaIni, mesIni, anioIni).subscribe((periodoini) => {
      if (periodoini[0].CONTADOR === 1) {
        this.contabilizadocService.verificarPeriodoContable(diaFin, mesFin, anioFin).subscribe((periodofin) => {
          if (periodofin[0].CONTADOR === 1) {
            
            const fechaInicio = fechaIni.toLocaleString('en-GB').split(',');
            const fechaFinal = fechaFin.toLocaleString('en-GB').split(',');
            
            let condicion = 'COM_CODIGO = \'01\' AND TRNTOMAFISICA_FECHA BETWEEN ' +
              'TO_DATE(\'' + fechaInicio[0] + '\',\'dd/mm/yyyy\') AND TO_DATE(\'' + fechaFinal[0] + '\',\'dd/mm/yyyy\')';
            
            if (tipoVista === 'N') {
              condicion = condicion + ' AND ASI_NRO IS NULL';
            }
            
            this.contabilizadocService.obtenerDatosTomasFisicas(condicion).subscribe((datos) => {
              this.contabilizadocService.tomasFisicas = datos;
              if (this.contabilizadocService.tomasFisicas !== null) {
                this.contabilizadocService.tomasFisicas.map((item) => {
                  item.FECHA = item.FECHA === null ? ''  : item.FECHA;
                  if (item.FECHA.toString().includes('-') && item.FECHA !== '') {
                    item.FECHA = this.datePipe.transform(item.FECHA, 'dd/MM/yyyy');
                  }
                  item.ASI_NRO = item.ASIENTO;
                  item.CHECKCONT = false;
                  item.STATUS = null;
                });
              } else if (this.contabilizadocService.tomasFisicas === null) {
                this.mensajeAlerta('info', 'Información', 'No se han encontrado registros que coincidan con los parámetros ' +
                  'seleccionados');
              }
            });
            
          } else {
            this.mensajeAlerta('info', 'Información', 'La Fecha final está fuera del periodo contable.');
            return;
          }
        });
      } else {
        this.mensajeAlerta('info', 'Información', 'La Fecha inicial está fuera del periodo contable.');
        return;
      }
    });
  }
  
  actualizarModeloVenFactura(fechaIni, fechaFin, tipos, tipoVista) {
    const diaIni = fechaIni.getDate();
    const mesIni = fechaIni.getMonth();
    const anioIni = fechaIni.getFullYear();
    
    const diaFin = fechaFin.getDate();
    const mesFin = fechaFin.getMonth();
    const anioFin = fechaFin.getFullYear();
    
    this.contabilizadocService.verificarPeriodoContable(diaIni, mesIni, anioIni).subscribe((periodoini) => {
      if (periodoini[0].CONTADOR === 1) {
        this.contabilizadocService.verificarPeriodoContable(diaFin, mesFin, anioFin).subscribe((periodofin) => {
          if (periodofin[0].CONTADOR === 1) {
            
            const fechaInicio = fechaIni.toLocaleString('en-GB').split(',');
            const fechaFinal = fechaFin.toLocaleString('en-GB').split(',');
            
            let condicion = 'COM_CODIGO = \'01\' AND to_date(to_char(ENCFAC_FECHAEMISION,\'dd/mm/yyyy\'),\'dd/mm/yyyy\')' +
              ' BETWEEN TO_DATE(\'' + fechaInicio[0] + '\',\'dd/mm/yyyy\') AND TO_DATE(\'' + fechaFinal[0] +
              '\',\'dd/mm/yyyy\') AND ENCFAC_ESTADO <> \'A\' AND  NOT ENCFAC_FORMAPAGO IS NULL';
            
            if (tipoVista === 'N') {
              condicion = condicion + ' AND ASI_NRO IS NULL';
            }
            const bodega = this.strBodegaFactura;
            
            if (bodega.name !== '') {
              if (bodega.codigo !== 'vacio') {
                condicion = condicion + ' AND BOD_CODIGO = \'' + bodega.codigo + '\'';
              }
            }
            
            this.contabilizadocService.obtenerDatosFacturas(condicion).subscribe((datos) => {
              this.contabilizadocService.ventasFacturas = [];
              if (datos !== null) {
                for (const dato of datos) {
                  const item: ConVenFacturas = {};
                  item.STATUS = null;
                  item.CHECKCONT = false;
                  item.FORMA_PAGO = dato['Forma Pago'];
                  item.ASI_NRO = dato.ASI_NRO;
                  item.NUMERO = dato.NUMERO;
                  item.ENCFAC_COMENTARIO = dato.ENCFAC_COMENTARIO;
                  item.FECHA_EMISION = dato.Fec_Emi === null ? '' : dato.Fec_Emi;
                  if (item.FECHA_EMISION.toString().includes('-') && item.FECHA_EMISION !== '') {
                    item.FECHA_EMISION = this.datePipe.transform(item.FECHA_EMISION, 'dd/MM/yyyy');
                  }
                  item.FECHA_VENCE = dato['Fec. Ven.'] === null ? '' : dato['Fec. Ven.'];
                  if (item.FECHA_VENCE.toString().includes('-') && item.FECHA_VENCE !== '') {
                    item.FECHA_VENCE = this.datePipe.transform(item.FECHA_VENCE, 'dd/MM/yyyy');
                  }
                  item.TOTAL = Number(dato.Total).toFixed(2);
                  this.contabilizadocService.ventasFacturas.push(item);
                }
                
                this.aggrid.refreshaggrid(this.contabilizadocService.ventasFacturas, this.defaultColConFactura.objeto);
              } else if (datos === null) {
                this.mensajeAlerta('info', 'Información', 'No se han encontrado registros que coincidan con los parámetros ' +
                  'seleccionados');
              }
            });
          } else {
            this.mensajeAlerta('info', 'Información', 'La Fecha final está fuera del periodo contable.');
            return;
          }
        });
      } else {
        this.mensajeAlerta('info', 'Información', 'La Fecha inicial está fuera del periodo contable.');
        return;
      }
    });
    
  }
  
  actualizarModeloVenNotaCred(fechaIni, fechaFin, tipos, tipoVista) {
    const diaIni = fechaIni.getDate();
    const mesIni = fechaIni.getMonth();
    const anioIni = fechaIni.getFullYear();
    
    const diaFin = fechaFin.getDate();
    const mesFin = fechaFin.getMonth();
    const anioFin = fechaFin.getFullYear();
    
    this.contabilizadocService.verificarPeriodoContable(diaIni, mesIni, anioIni).subscribe((periodoini) => {
      if (periodoini[0].CONTADOR === 1) {
        this.contabilizadocService.verificarPeriodoContable(diaFin, mesFin, anioFin).subscribe((periodofin) => {
          if (periodofin[0].CONTADOR === 1) {
            
            const fechaInicio = fechaIni.toLocaleString('en-GB').split(',');
            const fechaFinal = fechaFin.toLocaleString('en-GB').split(',');
            
            let condicion = 'COM_CODIGO = \'01\' AND to_date(to_char(ENCDvf_FECHAEMISION,\'dd/mm/yyyy\'),\'dd/mm/yyyy\')' +
              ' BETWEEN TO_DATE(\'' + fechaInicio[0] + '\',\'dd/mm/yyyy\') AND TO_DATE(\'' + fechaFinal[0] +
              '\',\'dd/mm/yyyy\') AND ENCDVF_ESTADO <> \'A\'';
            
            
            if (tipoVista === 'N') {
              condicion = condicion + ' AND ASI_NRO IS NULL';
            }
            const bodega = tipos;
            
            if (bodega.name !== '') {
              if (bodega.codigo !== 'vacio') {
                condicion = condicion + ' AND BOD_CODIGO = \'' + bodega.codigo + '\'';
              }
            }
            
            this.contabilizadocService.obtenerDatosNotasCredito(condicion).subscribe((datos) => {
              this.contabilizadocService.venNotasCredito = [];
              if (datos !== null) {
                for (const dato of datos) {
                  const item: ConVenNotasCredito = {};
                  item.STATUS = null;
                  item.CHECKCONT = false;
                  item.ASI_NRO = dato.ASI_NRO;
                  item.NUMERO = dato.NUMERO;
                  item.ENCDVF_COMENTARIO = dato.ENCDVF_COMENTARIO;
                  item.FECHA_EMISION = dato['Fec. Emi.'] === null ? '' : dato['Fec. Emi.'];
                  if (item.FECHA_EMISION.toString().includes('-') && item.FECHA_EMISION !== '') {
                    item.FECHA_EMISION = this.datePipe.transform(item.FECHA_EMISION, 'dd/MM/yyyy');
                  }
                  item.FECHA_VENCE = dato['Fec. Ven.'] === null ? '' : dato['Fec. Ven.'];
                  if (item.FECHA_VENCE.toString().includes('-') && item.FECHA_VENCE !== '') {
                    item.FECHA_VENCE = this.datePipe.transform(item.FECHA_VENCE, 'dd/MM/yyyy');
                  }
                  item.TOTAL = Number(dato.Total).toFixed(2);
                  this.contabilizadocService.venNotasCredito.push(item);
                }
                
                this.aggrid.refreshaggrid(this.contabilizadocService.venNotasCredito, this.defaultColConNotaCredito.objeto);
              } else if (datos === null) {
                this.mensajeAlerta('info', 'Información', 'No se han encontrado registros que coincidan con los parámetros ' +
                  'seleccionados');
              }
            });
          } else {
            this.mensajeAlerta('info', 'Información', 'La Fecha final está fuera del periodo contable.');
            return;
          }
        });
      } else {
        this.mensajeAlerta('info', 'Información', 'La Fecha inicial está fuera del periodo contable.');
        return;
      }
    });
  }
  
  actualizarModeloVenGuiaRem(fechaIni, fechaFin, tipos, tipoVista) {
    const diaIni = fechaIni.getDate();
    const mesIni = fechaIni.getMonth();
    const anioIni = fechaIni.getFullYear();
    
    const diaFin = fechaFin.getDate();
    const mesFin = fechaFin.getMonth();
    const anioFin = fechaFin.getFullYear();
    
    this.contabilizadocService.verificarPeriodoContable(diaIni, mesIni, anioIni).subscribe((periodoini) => {
      if (periodoini[0].CONTADOR === 1) {
        this.contabilizadocService.verificarPeriodoContable(diaFin, mesFin, anioFin).subscribe((periodofin) => {
          if (periodofin[0].CONTADOR === 1) {
            
            const fechaInicio = fechaIni.toLocaleString('en-GB').split(',');
            const fechaFinal = fechaFin.toLocaleString('en-GB').split(',');
            
            let condicion = ' COM_CODIGO = \'01\' AND to_date(to_char(ENCGRE_FECHAEMISION,\'dd/mm/yyyy\'),\'dd/mm/yyyy\') BETWEEN TO_DATE(\''
              + fechaInicio[0] + '\',\'dd/mm/yyyy\') ' +
              'AND TO_DATE(\'' + fechaFinal[0] + '\',\'dd/mm/yyyy\') AND ENCGRE_ESTADO <> \'A\' AND ' +
              'ENCGRE_ORIGEN = \'GRE\' AND ENCFAC_NUMERO IS NULL';
            
            if (tipoVista === 'N') {
              condicion = condicion + ' AND ASI_NRO IS NULL';
            }
            const bodega = tipos;
            
            if (bodega.name !== '') {
              if (bodega.codigo !== 'vacio') {
                condicion = condicion + ' AND BOD_CODIGO = \'' + bodega.codigo + '\'';
              }
            }
            
            condicion = condicion + ' ORDER BY ENCGRE_FECHAEMISION ';
            
            this.contabilizadocService.obtenerDatosGuiasRemision(condicion).subscribe((datos) => {
              this.contabilizadocService.venGuiasRemision = datos;
              if (this.contabilizadocService.venGuiasRemision !== null) {
                this.contabilizadocService.venGuiasRemision.map((item) => {
                  item.STATUS = null;
                  item.CHECKCONT = false;
                  item.ENCGRE_FECHAEMISION = item.ENCGRE_FECHAEMISION === null ? '' : item.ENCGRE_FECHAEMISION;
                  if (item.ENCGRE_FECHAEMISION.toString().includes('-') && item.ENCGRE_FECHAEMISION !== '') {
                    item.ENCGRE_FECHAEMISION = this.datePipe.transform(item.ENCGRE_FECHAEMISION, 'dd/MM/yyyy');
                  }
                  item.ENCGRE_TOTAL = Number(item.ENCGRE_TOTAL).toFixed(2);
                });
                
              } else if (this.contabilizadocService.venGuiasRemision === null) {
                this.mensajeAlerta('info', 'Información', 'No se han encontrado registros que coincidan con los parámetros ' +
                  'seleccionados');
              }
            });
          } else {
            this.mensajeAlerta('info', 'Información', 'La Fecha final está fuera del periodo contable.');
            return;
          }
        });
      } else {
        this.mensajeAlerta('info', 'Información', 'La Fecha inicial está fuera del periodo contable.');
        return;
      }
    });
    
  }
  
  actualizarModeloComIngr(fechaIni, fechaFin, tipos, tipoVista) {
    const diaIni = fechaIni.getDate();
    const mesIni = fechaIni.getMonth();
    const anioIni = fechaIni.getFullYear();
    
    const diaFin = fechaFin.getDate();
    const mesFin = fechaFin.getMonth();
    const anioFin = fechaFin.getFullYear();
    
    this.contabilizadocService.verificarPeriodoContable(diaIni, mesIni, anioIni).subscribe((periodoini) => {
      if (periodoini[0].CONTADOR === 1) {
        this.contabilizadocService.verificarPeriodoContable(diaFin, mesFin, anioFin).subscribe((periodofin) => {
          if (periodofin[0].CONTADOR === 1) {
            
            const fechaInicio = fechaIni.toLocaleString('en-GB').split(',');
            const fechaFinal = fechaFin.toLocaleString('en-GB').split(',');
            
            let condicion = 'C.COM_CODIGO = \'01\' AND C.TRNCOBRO_FECHATRN'
              + ' BETWEEN TO_DATE(\'' + fechaInicio[0] + '\',\'dd/mm/yyyy\') AND TO_DATE(\'' + fechaFinal[0]
              + '\',\'dd/mm/yyyy\') AND C.TRNCOBRO_ANULADO <> 1 AND (APLORG_CODIGO = \'CXC\' ' +
              'or APLORG_CODIGO = \'FAC\' or APLORG_CODIGO=\'VEN\' ) and nvl(trncobro_saldoini,0)=0 ';
            
            
            if (tipoVista === 'N') {
              condicion = condicion + ' AND ASI_NRO IS NULL';
            }
            const tipe = tipos;
            
            if (tipe.name === 'TODOS') {
              condicion = condicion + ' AND (C.TRNCOBRO_TIPODOC = \'AB\' OR C.TRNCOBRO_TIPODOC = \'ANC\' OR C.TRNCOBRO_TIPODOC = \'CA\')';
            }
            
            if (tipe.name === 'ABONO') {
              condicion = condicion + '  AND C.TRNCOBRO_TIPODOC = \'AB\' ';
            }
            
            if (tipe.name === 'CANCELACION') {
              condicion = condicion + '  AND C.TRNCOBRO_TIPODOC = \'CA\' ';
            }
            
            if (tipe.name === 'ANTICIPO') {
              condicion = condicion + '  AND C.TRNCOBRO_TIPODOC = \'ANC\' ';
            }
            
            
            this.contabilizadocService.obtenerDatosComprobantesIng(condicion).subscribe((datos) => {
              this.contabilizadocService.cxcComprobIngreso = datos;
              if (this.contabilizadocService.cxcComprobIngreso !== null) {
                this.contabilizadocService.cxcComprobIngreso.map((item) => {
                  item.STATUS = null;
                  item.CHECKCONT = false;
                  item.TRNCOBRO_FECHATRN = item.TRNCOBRO_FECHATRN === null ? '' : item.TRNCOBRO_FECHATRN;
                  if (item.TRNCOBRO_FECHATRN.toString().includes('-') && item.TRNCOBRO_FECHATRN !== '') {
                    item.TRNCOBRO_FECHATRN = this.datePipe.transform(item.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
                  }
                  item.TRNCOBRO_IMPORTE = Number(item.TRNCOBRO_IMPORTE).toFixed(2);
                });
                
              } else if (this.contabilizadocService.cxcComprobIngreso === null) {
                this.mensajeAlerta('info', 'Información', 'No se han encontrado registros que coincidan con los parámetros ' +
                  'seleccionados');
              }
            });
          } else {
            this.mensajeAlerta('info', 'Información', 'La Fecha final está fuera del periodo contable.');
            return;
          }
        });
      } else {
        this.mensajeAlerta('info', 'Información', 'La Fecha inicial está fuera del periodo contable.');
        return;
      }
    });
    
  }
  
  actualizarModeloDevAnt(fechaIni, fechaFin, tipoVista) {
    const diaIni = fechaIni.getDate();
    const mesIni = fechaIni.getMonth();
    const anioIni = fechaIni.getFullYear();
    
    const diaFin = fechaFin.getDate();
    const mesFin = fechaFin.getMonth();
    const anioFin = fechaFin.getFullYear();
    
    this.contabilizadocService.verificarPeriodoContable(diaIni, mesIni, anioIni).subscribe((periodoini) => {
      if (periodoini[0].CONTADOR === 1) {
        this.contabilizadocService.verificarPeriodoContable(diaFin, mesFin, anioFin).subscribe((periodofin) => {
          if (periodofin[0].CONTADOR === 1) {
            
            const fechaInicio = fechaIni.toLocaleString('en-GB').split(',');
            const fechaFinal = fechaFin.toLocaleString('en-GB').split(',');
            
            let condicion = 'C.COM_CODIGO = \'01\'  AND C.TRNCOBRO_FECHATRN' +
              ' BETWEEN TO_DATE(\'' + fechaInicio[0] + '\',\'dd/mm/yyyy\') AND TO_DATE(\'' + fechaFinal[0] +
              '\',\'dd/mm/yyyy\') AND C.TRNCOBRO_ANULADO <> 1 AND APLORG_CODIGO = \'CXC\'' +
              ' AND C.TRNCOBRO_TIPODOC = \'DAN\'';
            
            if (tipoVista === 'N') {
              condicion = condicion + ' AND ASI_NRO IS NULL';
            }
            
            this.contabilizadocService.obtenerDatosDevAnticipo(condicion).subscribe((datos) => {
              this.contabilizadocService.cxcDevAnticipo = datos;
              if (this.contabilizadocService.cxcDevAnticipo !== null) {
                this.contabilizadocService.cxcDevAnticipo.map((item) => {
                  item.TRNCOBRO_FECHATRN = item.TRNCOBRO_FECHATRN === null ? ''  : item.TRNCOBRO_FECHATRN;
                  if (item.TRNCOBRO_FECHATRN.toString().includes('-') && item.TRNCOBRO_FECHATRN !== '') {
                    item.TRNCOBRO_FECHATRN = this.datePipe.transform(item.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
                  }
                  item.TRNCOBRO_IMPORTE = item.TRNCOBRO_IMPORTE.toFixed(2);
                  item.CHECKCONT = false;
                  item.STATUS = null;
                });
              } else if (this.contabilizadocService.cxcDevAnticipo === null) {
                this.mensajeAlerta('info', 'Información', 'No se han encontrado registros que coincidan con los parámetros ' +
                  'seleccionados');
              }
            });
          } else {
            this.mensajeAlerta('info', 'Información', 'La Fecha final está fuera del periodo contable.');
            return;
          }
        });
      } else {
        this.mensajeAlerta('info', 'Información', 'La Fecha inicial está fuera del periodo contable.');
        return;
      }
    });
    
  }
  
  actualizarModeloCXCNotaCred(fechaIni, fechaFin, tipoVista) {
    const diaIni = fechaIni.getDate();
    const mesIni = fechaIni.getMonth();
    const anioIni = fechaIni.getFullYear();
    
    const diaFin = fechaFin.getDate();
    const mesFin = fechaFin.getMonth();
    const anioFin = fechaFin.getFullYear();
    
    this.contabilizadocService.verificarPeriodoContable(diaIni, mesIni, anioIni).subscribe((periodoini) => {
      if (periodoini[0].CONTADOR === 1) {
        this.contabilizadocService.verificarPeriodoContable(diaFin, mesFin, anioFin).subscribe((periodofin) => {
          if (periodofin[0].CONTADOR === 1) {
            
            const fechaInicio = fechaIni.toLocaleString('en-GB').split(',');
            const fechaFinal = fechaFin.toLocaleString('en-GB').split(',');
            
            let condicion = 'C.COM_CODIGO = \'01\' AND C.TRNCOBRO_FECHATRN' +
              ' BETWEEN TO_DATE(\'' + fechaInicio[0] + '\',\'dd/mm/yyyy\') AND TO_DATE(\'' + fechaFinal[0] +
              '\',\'dd/mm/yyyy\') AND C.TRNCOBRO_ANULADO <> 1 AND APLORG_CODIGO = \'CXC\'' +
              ' AND C.TRNCOBRO_TIPODOC = \'NC\'';
            
            if (tipoVista === 'N') {
              condicion = condicion + ' AND ASI_NRO IS NULL';
            }
            
            this.contabilizadocService.obtenerDatosCXCNotas(condicion).subscribe((datos) => {
              this.contabilizadocService.cxcNotasCredito = datos;
              if (this.contabilizadocService.cxcNotasCredito !== null) {
                this.contabilizadocService.cxcNotasCredito.map((item) => {
                  item.TRNCOBRO_FECHATRN = item.TRNCOBRO_FECHATRN === null ? ''  : item.TRNCOBRO_FECHATRN;
                  if (item.TRNCOBRO_FECHATRN.toString().includes('-') && item.TRNCOBRO_FECHATRN !== '') {
                    item.TRNCOBRO_FECHATRN = this.datePipe.transform(item.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
                  }
                  item.TRNCOBRO_IMPORTE = item.TRNCOBRO_IMPORTE.toFixed(2);
                  item.CHECKCONT = false;
                  item.STATUS = null;
                });
              } else if (this.contabilizadocService.cxcNotasCredito === null) {
                this.mensajeAlerta('info', 'Información', 'No se han encontrado registros que coincidan con los parámetros ' +
                  'seleccionados');
              }
            });
          } else {
            this.mensajeAlerta('info', 'Información', 'La Fecha final está fuera del periodo contable.');
            return;
          }
        });
      } else {
        this.mensajeAlerta('info', 'Información', 'La Fecha inicial está fuera del periodo contable.');
        return;
      }
    });
    
  }
  
  actualizarModeloCXCNotaDebito(fechaIni, fechaFin, tipoVista) {
    const diaIni = fechaIni.getDate();
    const mesIni = fechaIni.getMonth();
    const anioIni = fechaIni.getFullYear();
    
    const diaFin = fechaFin.getDate();
    const mesFin = fechaFin.getMonth();
    const anioFin = fechaFin.getFullYear();
    
    this.contabilizadocService.verificarPeriodoContable(diaIni, mesIni, anioIni).subscribe((periodoini) => {
      if (periodoini[0].CONTADOR === 1) {
        this.contabilizadocService.verificarPeriodoContable(diaFin, mesFin, anioFin).subscribe((periodofin) => {
          if (periodofin[0].CONTADOR === 1) {
            
            const fechaInicio = fechaIni.toLocaleString('en-GB').split(',');
            const fechaFinal = fechaFin.toLocaleString('en-GB').split(',');
            
            let condicion = 'C.COM_CODIGO = \'01\' AND C.TRNCOBRO_FECHATRN' +
              ' BETWEEN TO_DATE(\'' + fechaInicio[0] + '\',\'dd/mm/yyyy\') AND TO_DATE(\'' + fechaFinal[0] +
              '\',\'dd/mm/yyyy\') AND C.TRNCOBRO_ANULADO <> 1 AND APLORG_CODIGO = \'CXC\'' +
              ' AND C.TRNCOBRO_TIPODOC = \'ND\'';
            
            if (tipoVista === 'N') {
              condicion = condicion + ' AND ASI_NRO IS NULL';
            }
            
            this.contabilizadocService.obtenerDatosCXCNotas(condicion).subscribe((datos) => {
              this.contabilizadocService.cxcNotasDebito = datos;
              if (this.contabilizadocService.cxcNotasDebito !== null) {
                this.contabilizadocService.cxcNotasDebito.map((item) => {
                  item.TRNCOBRO_FECHATRN = item.TRNCOBRO_FECHATRN === null ? ''  : item.TRNCOBRO_FECHATRN;
                  if (item.TRNCOBRO_FECHATRN.toString().includes('-') && item.TRNCOBRO_FECHATRN !== '') {
                    item.TRNCOBRO_FECHATRN = this.datePipe.transform(item.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
                  }
                  item.TRNCOBRO_IMPORTE = item.TRNCOBRO_IMPORTE.toFixed(2);
                  item.CHECKCONT = false;
                  item.STATUS = null;
                });
              } else if (this.contabilizadocService.cxcNotasDebito === null) {
                this.mensajeAlerta('info', 'Información', 'No se han encontrado registros que coincidan con los parámetros ' +
                  'seleccionados');
              }
            });
          } else {
            this.mensajeAlerta('info', 'Información', 'La Fecha final está fuera del periodo contable.');
            return;
          }
        });
      } else {
        this.mensajeAlerta('info', 'Información', 'La Fecha inicial está fuera del periodo contable.');
        return;
      }
    });
    
  }
  
  actualizarModeloCXCRetencion(fechaIni, fechaFin, tipoVista) {
    const diaIni = fechaIni.getDate();
    const mesIni = fechaIni.getMonth();
    const anioIni = fechaIni.getFullYear();
    
    const diaFin = fechaFin.getDate();
    const mesFin = fechaFin.getMonth();
    const anioFin = fechaFin.getFullYear();
    
    this.contabilizadocService.verificarPeriodoContable(diaIni, mesIni, anioIni).subscribe((periodoini) => {
      if (periodoini[0].CONTADOR === 1) {
        this.contabilizadocService.verificarPeriodoContable(diaFin, mesFin, anioFin).subscribe((periodofin) => {
          if (periodofin[0].CONTADOR === 1) {
            
            const fechaInicio = fechaIni.toLocaleString('en-GB').split(',');
            const fechaFinal = fechaFin.toLocaleString('en-GB').split(',');
            
            let condicion = 'C.COM_CODIGO = \'01\' AND C.TRNCOBRO_FECHATRN' +
              ' BETWEEN TO_DATE(\'' + fechaInicio[0] + '\',\'dd/mm/yyyy\') AND TO_DATE(\'' + fechaFinal[0] +
              '\',\'dd/mm/yyyy\') AND C.TRNCOBRO_ANULADO <> 1 AND APLORG_CODIGO = \'CXC\'' +
              ' AND C.TRNCOBRO_TIPODOC = \'RT\'';
            
            if (tipoVista === 'N') {
              condicion = condicion + ' AND ASI_NRO IS NULL';
            }
            
            this.contabilizadocService.obtenerDatosCXCNotas(condicion).subscribe((datos) => {
              this.contabilizadocService.cxcRetenciones = datos;
              if (this.contabilizadocService.cxcRetenciones !== null) {
                this.contabilizadocService.cxcRetenciones.map((item) => {
                  item.TRNCOBRO_FECHATRN = item.TRNCOBRO_FECHATRN === null ? ''  : item.TRNCOBRO_FECHATRN;
                  if (item.TRNCOBRO_FECHATRN.toString().includes('-') && item.TRNCOBRO_FECHATRN !== '') {
                    item.TRNCOBRO_FECHATRN = this.datePipe.transform(item.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
                  }
                  item.TRNCOBRO_IMPORTE = item.TRNCOBRO_IMPORTE.toFixed(2);
                  item.CHECKCONT = false;
                  item.STATUS = null;
                });
              } else if (this.contabilizadocService.cxcRetenciones === null) {
                this.mensajeAlerta('info', 'Información', 'No se han encontrado registros que coincidan con los parámetros ' +
                  'seleccionados');
              }
            });
          } else {
            this.mensajeAlerta('info', 'Información', 'La Fecha final está fuera del periodo contable.');
            return;
          }
        });
      } else {
        this.mensajeAlerta('info', 'Información', 'La Fecha inicial está fuera del periodo contable.');
        return;
      }
    });
    
  }
  
  actualizarModeloCXPEgreso(fechaIni, fechaFin, tipoVista) {
    const diaIni = fechaIni.getDate();
    const mesIni = fechaIni.getMonth();
    const anioIni = fechaIni.getFullYear();
    
    const diaFin = fechaFin.getDate();
    const mesFin = fechaFin.getMonth();
    const anioFin = fechaFin.getFullYear();
    
    this.contabilizadocService.verificarPeriodoContable(diaIni, mesIni, anioIni).subscribe((periodoini) => {
      if (periodoini[0].CONTADOR === 1) {
        this.contabilizadocService.verificarPeriodoContable(diaFin, mesFin, anioFin).subscribe((periodofin) => {
          if (periodofin[0].CONTADOR === 1) {
            
            const fechaInicio = fechaIni.toLocaleString('en-GB').split(',');
            const fechaFinal = fechaFin.toLocaleString('en-GB').split(',');
            
            let condicion = 'a.COM_CODIGO = \'01\' AND a.TRNCOBRO_FECHATRN' +
              ' BETWEEN TO_DATE(\'' + fechaInicio[0] + '\',\'dd/mm/yyyy\') AND TO_DATE(\'' + fechaFinal[0] +
              '\',\'dd/mm/yyyy\') AND (TRNCOBRO_ESTADO <> \'A\' OR TRNCOBRO_ESTADO IS NULL)' +
              ' AND TRNCOBRO_TIPODOC IN(\'AB\', \'CA\')';
            
            if (tipoVista === 'N') {
              condicion = condicion + ' AND ASI_NRO IS NULL';
            }
            this.contabilizadocService.obtenerDatosCXPEgreso(condicion).subscribe((datos) => {
              this.contabilizadocService.cxpEgresos = datos;
              if (this.contabilizadocService.cxpEgresos !== null) {
                this.contabilizadocService.cxpEgresos.map((item) => {
                  item.TRNCOBRO_FECHATRN = item.TRNCOBRO_FECHATRN === null ? ''  : item.TRNCOBRO_FECHATRN;
                  if (item.TRNCOBRO_FECHATRN.toString().includes('-') && item.TRNCOBRO_FECHATRN !== '') {
                    item.TRNCOBRO_FECHATRN = this.datePipe.transform(item.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
                  }
                  item.TRNCOBRO_IMPORTE = item.TRNCOBRO_IMPORTE.toFixed(2);
                  item.CHECKCONT = false;
                  item.STATUS = null;
                });
              } else if (this.contabilizadocService.cxpEgresos === null) {
                this.mensajeAlerta('info', 'Información', 'No se han encontrado registros que coincidan con los parámetros ' +
                  'seleccionados');
              }
            });
          } else {
            this.mensajeAlerta('info', 'Información', 'La Fecha final está fuera del periodo contable.');
            return;
          }
        });
      } else {
        this.mensajeAlerta('info', 'Información', 'La Fecha inicial está fuera del periodo contable.');
        return;
      }
    });
    
  }
  
  confirmarGenerarAsi() {
        const dia = Number(1);
        const mes = Number(this.strMesAjustes.name)-1;
        const anio = Number(this.strAnioAjustes.name);
        console.log('<<<<>>>', dia, mes, anio)
        this.contabilizadocService.verificarPeriodoContable(dia, mes, anio).subscribe((per) => {
          console.log('<<<<>>>',per)
          if (per[0].CONTADOR === 1) {
              this.confirmationService.confirm({
                message: '¿ Está seguro de generar el asiento contable de ajuste ?',
                header: 'Generar Asiento Contable',
                icon: 'pi pi-exclamation-triangle',
                key: 'contdocConf',
                acceptLabel: 'Si',
                rejectLabel: 'No',
                accept: () => {
                  this.ejecutarAsientoAjuste();
                },
                reject: () => {
                }
              });
           } else {
            this.mensajeAlerta('info', 'ERROR', 'La Fecha debe estar dentro del periodo contable.');
          }
        });
  }
  
  ejecutarAsientoAjuste() {
    this.contabilizadocService.eliminarAsientoAj(this.strMesAjustes.name, this.strAnioAjustes.name,
      this.strComentarioAjuste, 'AAIP').subscribe(() => {
      this.ajustePositivoAjuste = '';
      this.ajusteNegativoAjuste = '';
      this.contabilizadocService.generarAsiAjusPositivo(this.strMesAjustes.name, this.strAnioAjustes.name,
        this.strComentarioAjuste, 'AAIP').subscribe(() => {
        this.contabilizadocService.generarAsiAjusNegativo(this.strMesAjustes.name, this.strAnioAjustes.name,
          this.strComentarioAjuste, 'AAIN').subscribe(() => {
          this.mensajeAlerta('success', 'Asiento de Ajuste', 'Asiento generado correctamente');
          this.traerAsientosGenerados();
          
        }, error1 => {
          this.mensajeAlerta('error', 'Error', '! Proceso generar asiento de ajuste negativo no terminó satisfactoriamente.... !');
        });
      }, error1 => {
        this.mensajeAlerta('error', 'Error', '! Proceso generar asiento de ajuste en positivo no terminó satisfactoriamente.... !');
      });
    }, error1 => {
      this.mensajeAlerta('error', 'Error', '! Proceso eliminar asiento de ajuste no terminó satisfactoriamente.... !');
    });
  }
  
  traerAsientosGenerados() {
    let strSentencia = ' TRNART_TIPO = \'AC\' and to_char(trnart_fechaes,\'yyyy\') = \'' + this.strAnioAjustes.name + '\'';
    strSentencia = strSentencia + ' and to_char(trnart_fechaes, \'mm\') = \'' + this.strMesAjustes.name + '\' AND TRNART_COSTOT < 0';
    strSentencia = strSentencia + ' and trnart_origen=\'AJEN\'';
    this.contabilizadocService.erAsinroTrnkardex(this.strAnioAjustes.name, this.strMesAjustes.name).subscribe((dato) => {
      if (dato !== null) {
        this.ajusteNegativoAjuste = dato[0].ASI_NRO;
      }
    }, error1 => {
      this.mensajeAlerta('error', 'Error', '! Proceso leer asiento de ajuste de inventario negativo no terminó satisfactoriamente.... !');
    });
    
    strSentencia = ' TRNART_TIPO = \'AC\' and to_char(trnart_fechaes,\'yyyy\') = \'' + this.strAnioAjustes.name + '\'';
    strSentencia = strSentencia + ' and to_char(trnart_fechaes, \'mm\') = \'' + this.strMesAjustes.name + '\' AND TRNART_COSTOT > 0';
    strSentencia = strSentencia + ' and trnart_origen=\'AJEN\'';
    this.contabilizadocService.erAsinroTrnkardex2(this.strAnioAjustes.name, this.strMesAjustes.name).subscribe((dato) => {
      if (dato !== null) {
        this.ajustePositivoAjuste = dato[0].ASI_NRO;
      }
    }, error1 => {
      this.mensajeAlerta('error', 'Error', '! Proceso leer asiento de ajuste de inventario positivo no terminó satisfactoriamente.... !');
    });
  }
  
  confirmarEliminarReg() {
    const dia = Number(1);
        const mes = Number(this.strMesAjustes.name)-1;
        const anio = Number(this.strAnioAjustes.name);
        console.log('<<<<>>>', dia, mes, anio)
        this.contabilizadocService.verificarPeriodoContable(dia, mes, anio).subscribe((per) => {
          console.log('<<<<>>>',per)
          if (per[0].CONTADOR === 1) {
            this.confirmationService.confirm({
              message: '¿ Está seguro de eliminar el registro ?',
              header: 'Eliminar',
              icon: 'pi pi-exclamation-triangle',
              key: 'contdocConf',
              acceptLabel: 'Si',
              rejectLabel: 'No',
              accept: () => {
                this.eliminarAsientoContable();
              },
              reject: () => {
              }
            });
           } else {
            this.mensajeAlerta('info', 'ERROR', 'La Fecha debe estar dentro del periodo contable.');
          }
        });
  
  }
  
  eliminarAsientoContable() {
    this.contabilizadocService.eliminarAsientoAj(this.strMesAjustes.name, this.strAnioAjustes.name,
      this.strComentarioAjuste, 'AAIP').subscribe(() => {
      this.mensajeAlerta('success', 'Asiento de ajuste', 'Asiento de ajuste eliminado correctamente');
      
    }, error1 => {
      this.mensajeAlerta('error', 'Error', '! Proceso eliminar asiento de ajuste no terminó satisfactoriamente.... !');
    });
  }
  
  // mensajes
  msgErrorSeleccion(mensaje: string) {
    this.messageService.add({
      key: 'contfacpro',
      severity: 'error',
      summary: 'Error',
      detail: mensaje
    });
  }
  
  msgDescuadre(documento: string) {
    this.messageService.add({
      key: 'contfacpro',
      severity: 'error',
      summary: 'Error',
      detail: 'Ha sido imposible contabilizar el documento ' + documento
    });
  }
  
  mensajeExito(contabilizados: number) {
    this.messageService.add({
      key: 'contfacpro',
      severity: 'success',
      summary: 'Información',
      detail: 'Se han contabilizado ' + contabilizados + ' documentos correctamente.'
    });
  }

  async cargarFacturas(fechaIni, fechaFin, tipoVista) {
    const diaIni = fechaIni.getDate();
    const mesIni = fechaIni.getMonth();
    const anioIni = fechaIni.getFullYear();
    
    const diaFin = fechaFin.getDate();
    const mesFin = fechaFin.getMonth();
    const anioFin = fechaFin.getFullYear();

    let int_opt;
    
    const periodoini = await this.contabilizadocService.verificarPeriodoContableProm(diaIni, mesIni, anioIni);
    if (periodoini[0].CONTADOR === 1) {
      const periodofin = await this.contabilizadocService.verificarPeriodoContableProm(diaFin, mesFin, anioFin);
      if (periodofin[0].CONTADOR === 1) {

        const fechaInicio = fechaIni.toLocaleString('en-GB').split(',');
        const fechaFinal = fechaFin.toLocaleString('en-GB').split(',');
        
        if (tipoVista === 'T') {
          int_opt = 1;
        } else if (tipoVista === 'N') {
          int_opt = 2;
        }

        const datos = await this.contabilizadocService.obtenerFacturasNomRol(fechaInicio[0], fechaFinal[0], int_opt);
        
        if (datos !== null) {
          const detalle = [];
          for (const item of datos) {
            let nombre;
            const reg: ConNomRolPago = {};
            reg.ASI_NRO = item.ASI_NRO;
            reg.CHECKCONT = false;
            reg.PERIODO_CODIGO = item.PERIODO_CODIGO,
            reg.PERIODO_FECFIN = item.PERIODO_FECFIN === null ? ''  : item.PERIODO_FECFIN;
            if (reg.PERIODO_FECFIN.toString().includes('-') && reg.PERIODO_FECFIN !== '') {
              reg.PERIODO_FECFIN = this.datePipe.transform(reg.PERIODO_FECFIN, 'dd/MM/yyyy');
            }
            reg.STATUS = null;
            reg.TIPROL_CODIGO = item.TIPROL_CODIGO,
            nombre = await this.contabilizadocService.erTipRolNombre(item.TIPROL_CODIGO);
            reg.TIPROL_NOMBRE = nombre === null ? '' : nombre[0].TIPROL_NOMBRE;

            detalle.push(reg);
          }
          this.contabilizadocService.nomRolPagos = detalle;
          
        } else {
          this.contabilizadocService.nomRolPagos = null;
          this.mensajeAlerta('info', 'Información', 'No se han encontrado registros que coincidan con los parámetros ' +
                  'seleccionados');
        }

      } else {
        this.mensajeAlerta('info', 'Información', 'La Fecha final está fuera del periodo contable.');
        return;
      }

    } else {
      this.mensajeAlerta('info', 'Información', 'La Fecha inicial está fuera del periodo contable.');
      return;
    }
    
  }
  
  mensajeAlerta(tipo, titulo, msg) {
    this.messageService.add({
      key: 'contfacpro',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
  
}
