import {Component, OnInit, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {Nommaetiporol} from '../nominterfaces/nommaetiporol';
import {ConfirmationService, MessageService} from 'primeng';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {NommaetiporolService} from '../nomservicios/nommaetiporol.service';

@Component({
  selector: 'app-nommaetiporol',
  templateUrl: './nommaetiporol.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NommaetiporolComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnGuardar: boolean;
  btnNuevo: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  defaultColDefMaeTipoRol;
  public frameworkComponents;
  rowStyle;
  largo = '258';
  rowSelection = 'multiple';
  nommaeTipoRol: Nommaetiporol[] = [];
  selectedMaeTipoRol: Nommaetiporol;
  columnDefMaeTipoRol = [
    {
      headerName: 'Código', field: 'TIPROL_CODIGO', cellEditor: 'cellPrueba', width: 250,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 2,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Nombre', field: 'TIPROL_NOMBRE', cellEditor: 'cellPrueba', width: 400,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 600,
            tienecalculos: false,
          }
        }
    },
  ];
  constructor(
    private messageService: MessageService,
    private init: NuevoComponentService,
    private errorService: ErroresBaseDatosService,
    private confirmationService: ConfirmationService,
    private maeTipoRolService: NommaetiporolService,
  ) {
    this.agTable();
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefMaeTipoRol = {
      editable: true,
      width: 130,
      objeto: 'maeProvincia'
    };
  }
  
  ngOnInit(): void {
    this.btnNuevo =  false;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.btnRegresar = false;
    this.getTipRoles();
  }
  
  getTipRoles() {
    this.maeTipoRolService.cargarTipoRoles().then( res => {
      this.nommaeTipoRol = res;
      // this.aggrid.gridApi.sizeColumnsToFit();
    } )
      .catch(e => {
        console.log(e);
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      });
  }
  
  
  selectMaeTipoRol(elemento) {
    if (elemento.object !== null) {
      this.selectedMaeTipoRol = elemento.object;
      if (this.selectedMaeTipoRol.old_TIPROL_CODIGO === undefined) {
        this.selectedMaeTipoRol.old_TIPROL_CODIGO = this.selectedMaeTipoRol.TIPROL_CODIGO;
      }
    }
  }
  
  cambio(event) {
    this.btnGuardar = false;
    this.btnRegresar = false;
    if (this.selectedMaeTipoRol.nuevo === undefined) {
      this.selectedMaeTipoRol.editado = true;
    }
  }
  
  manejarSenales(valor) {
    switch (valor) {
      case 'Nuevo':
        this.opcionNuevo();
        break;
      case 'Guardar':
        this.opcionGuardar();
        break;
      case 'Borrar':
        this.opcionBorrar();
        break;
      case 'Cancelar':
        this.cargar();
        break;
      case 'Salir':
        this.init.tabs.splice(  this.init.tabindex, 1);
        break;
      
    }
    
  }
  
  opcionNuevo() {
    this.btnNuevo = true;
    this.btnBorrar = false;
    this.btnRegresar = false;
    this.aggrid.isEditable(true);
    const new_nommaeTipoRol: Nommaetiporol = {
      
      TIPROL_CODIGO: '',
      TIPROL_NOMBRE: '',
      nuevo: true,
    };
    this.selectedMaeTipoRol = new_nommaeTipoRol;
    this.nommaeTipoRol.push(new_nommaeTipoRol);
    this.aggrid.refreshaggrid(this.nommaeTipoRol, this.defaultColDefMaeTipoRol.objeto);
  }
  
  opcionGuardar() {
    this.aggrid.gridApi.stopEditing();
    if (  this.selectedMaeTipoRol.TIPROL_NOMBRE !== '' && this.selectedMaeTipoRol.TIPROL_CODIGO !== '' ) {
      if( this.selectedMaeTipoRol.nuevo)  {
        this.enviarNuevo();
      } else if (this.selectedMaeTipoRol.editado) {
        this.enviarEditado();
      }
    } else {
      console.log('campos sin llenar');
      this.mensajeAlerta('info', 'Información', 'Se han encontrado campos vacíos');
      
    }
  }
  async enviarNuevo() {
    await this.maeTipoRolService.insertar(this.selectedMaeTipoRol).then(() => {
      this.mensajeAlerta('success', 'Información', '¡Tipo de rol creado exitosamente!');
      this.cargar();
      
      
    }).catch(e => {
      console.log(e);
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    });
  }
  
  async enviarEditado() {
    await this.maeTipoRolService.actualizar(this.selectedMaeTipoRol).then( () => {
      this.mensajeAlerta('success', 'Información', '¡Tipo de rol actualizado exitosamente!');
      this.cargar();
      
      
      
    }).catch( e => {
      console.log(e);
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }  );
  }
  
  opcionBorrar() {
    if ( this.selectedMaeTipoRol !== undefined ) {
      this.confirmationService.confirm({
        message: '¿Está seguro que desea eliminar el tipo de rol?',
        header: 'Eliminar Grupo',
        icon: 'pi pi-exclamation-triangle',
        key: 'NomMaeTipRolDialog',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {
          this.borrarRegistro();
        },
        reject: () => {
        }
      });
    }
  }
  
  async borrarRegistro() {
    await this.maeTipoRolService.borrar(this.selectedMaeTipoRol).then( () => {
      this.mensajeAlerta('success', 'Información', '¡El tipo de rol se eliminó exitosamente!');
      this.selectedMaeTipoRol = undefined;
      this.cargar();
    }  )
      .catch( e => {
        console.log(e);
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      } );
  }
  
  cargar() {
    this.btnNuevo =  false;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.btnRegresar = false;
    // this.anio = new Date().getFullYear();
    this.getTipRoles();
  }
  
  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'nommaetiproltoast',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
    
  }
  
}
