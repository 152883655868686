import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';


@Injectable({
  providedIn: 'root'
})
export class CxceditdatosclienteService {

  public url: string;
  public way: string;

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService,
  ) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }

  getCliente(clicodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxceditdatoscli/cc5f9eaf55b6a80000000', {
      elementos: {
        CLI_CODIGO: clicodigo
      }
    }, this.confIniciales.httpOptions());
  }
  erNombreProvincia(CLI_PROVINCIA) {
    return this.http.post(this.way + '/ventas/cliente/eddf165edd9de00000000', {
      elementos: {
        CLI_PROVINCIA
      },
    }, this.confIniciales.httpOptions());
  }

  erNombreCiudad(CIU_CODIGO) {
    return this.http.post(this.way + '/ventas/cliente/1dbbe2cbdb992f0000', {
      elementos: {
        CIU_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }

  erNombreCanton(CAN_CODIGO) {
    return this.http.post(this.way + '/ventas/cliente/1dbbe2cbdb98ac0000', {
      elementos: {
        CAN_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }

  erNombreParroquia(PARR_CODIGO) {
    return this.http.post(this.way + '/ventas/cliente/eddf165edd95700000000', {
      elementos: {
        PARR_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }
  erNombreZona(CLI_ZONA) {
    return this.http.post(this.way + '/ventas/cliente/76ef8b2f6e', {
      elementos: {
        CLI_ZONA
      },
    }, this.confIniciales.httpOptions());
  }
  erNombreCargo(CLI_CARGO) {
    return this.http.post(this.way + '/ventas/cliente/eddf165edcc57000', {
      elementos: {
        CLI_CARGO
      },
    }, this.confIniciales.httpOptions());
  }
  actualizarCliente(cliente) {
    return this.http.post(this.way + '/cxc/cxceditdatoscli/299df2ab2', {
      elementos: {
        p_CLI_codigo_1: cliente.CLI_CODIGO,
        p_CLI_direccion1_2: cliente.CLI_DIRECCION1,
        p_CLI_direccion2_3: cliente.CLI_DIRECCION2,
        p_CLI_codpostal_4: cliente.CLI_CODPOSTAL,
        p_CLI_telefono1_5: cliente.CLI_TELEFONO1,
        p_CLI_telefono2_6: cliente.CLI_TELEFONO2,
        p_CLI_fax_7:       cliente.CLI_FAX,
        p_CLI_correo_8:    cliente.CLI_CORREO,
        p_cli_correo2_9: cliente.CLI_CORREO2,
        p_CLI_provincia_10: cliente.CLI_PROVINCIA,
        p_CIU_codigo_11: cliente.CIU_CODIGO,
        p_can_codigo_12: cliente.CAN_CODIGO,
        p_parr_codigo_13: cliente.PARR_CODIGO,
        p_CLI_zona_14: cliente.CLI_ZONA,
        p_CLI_GMAPS_15: cliente.CLI_GMAPS,
        p_CLI_observacion_16: cliente.CLI_OBSERVACION,
        p_com_codigo : '01'
      },
    }, this.confIniciales.httpOptions());
  }
}
