import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  constructor(private http: HttpClient, public route: Router, public service: MessageService) {
  }

  // @ts-ignore
  public getUrl(callback) {    
    this.http.get('../../assets/config.json').subscribe(respuesta => {      
      let aux: any = respuesta;
      document.title = aux.titulo;
      if (aux.produccion === false) {
        sessionStorage.setItem('url', aux.url);
        sessionStorage.setItem('way', aux.way);
        sessionStorage.setItem('ubicacionReportes', aux.pathReportes);
        callback(aux.url);
      } else {
        sessionStorage.setItem('url', '/api');
        sessionStorage.setItem('way', aux.way);
        sessionStorage.setItem('ubicacionReportes', aux.pathReportes);
        callback('/api');
      }
    }, error => {
      this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'Refrescando la página' });
      // this.route.navigate(['login']);
    });
  }


  // @ts-ignore
  public async getConfigJson() {
    try {
      const respuesta = await this.http.get('../../assets/config.json').toPromise();
      let aux: any = respuesta;
      document.title = aux.titulo;
      sessionStorage.setItem('url', aux.url);
      sessionStorage.setItem('way', aux.way);
      sessionStorage.setItem('ubicacionReportes', aux.pathReportes);
      sessionStorage.setItem('timer', aux.timer);
    } catch (error) {
      this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'Refrescando la página' });
      // this.route.navigate(['login']);
    }
  }

  // @ts-ignore
  public async getUrllast(callback) {    
    try {
      const respuesta = await this.http.get('../../assets/config.json');      
      let aux: any = respuesta;
      document.title = aux.titulo;
      if (aux.produccion === false) {
        sessionStorage.setItem('url', aux.url);
        sessionStorage.setItem('way', aux.way);
        sessionStorage.setItem('ubicacionReportes', aux.pathReportes);
        callback(aux.url);
      } else {
        sessionStorage.setItem('url', '/api');
        sessionStorage.setItem('way', aux.way);
        sessionStorage.setItem('ubicacionReportes', aux.pathReportes);
        callback('/api');
      }
    } catch (error) {      
      this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'Refrescando la página' });
      this.route.navigate(['login']);
    }
  }

}
