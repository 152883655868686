import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
@Injectable({
  providedIn: 'root'
})
export class ConasidescuadreService {
  public way: string;
  
  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario, private confIniciales: ConfInicialesService) { 
	  this.way = sessionStorage.getItem('way');
	}
	
    consultarListar1(strfechaInicio, strfechaFin){
      return this.http.post<any[]>(this.way + '/cont/asidescuadre/83bacc5f9eaf580', {
          elementos: {
			fechaInicio: strfechaInicio,
			fechaFin: strfechaFin
		  }
        }, this.confIniciales.httpOptions()
      ).toPromise();
    }
    consultarListar2(strfechaInicio, strfechaFin){
    /* var strConsulta = "";

		strConsulta = "SELECT * FROM ("
				+ "SELECT ENCFACPRO_NUMERO, ENCFACPRO_FECHAEMISION AS FECHA, 'AFC' AS AFC, 'COM' AS ORIGEN FROM COM_ENCFACPRO WHERE ASI_NRO IS NULL AND COM_CODIGO='01' AND ENCFACPRO_FECHAEMISION BETWEEN "
				+ "TO_DATE('"
				+ fechaInicio
				+ "','DD/MM/YYYY') AND TO_DATE('"
				+ fechaFin
				+ "','DD/MM/YYYY') "
				+ "UNION SELECT ENCDEV_NUMERO, ENCDEV_FECHAEMISION AS FECHA, 'ADC', 'COM' AS ORIGEN FROM COM_ENCDEV WHERE ASI_NRO IS NULL AND COM_CODIGO='01' AND ENCDEV_FECHAEMISION BETWEEN "
				+ "TO_DATE('"
				+ fechaInicio
				+ "','DD/MM/YYYY') AND TO_DATE('"
				+ fechaFin
				+ "','DD/MM/YYYY') "
				+ "UNION SELECT ENCNOTREC_NUMERO, ENCNOTREC_FECHAEMISION AS FECHA, 'ANR', 'COM' AS ORIGEN FROM COM_ENCNOTREC WHERE ASI_NRO IS NULL AND COM_CODIGO='01' AND ENCNOTREC_FECHAEMISION BETWEEN "
				+ "TO_DATE('"
				+ fechaInicio
				+ "','DD/MM/YYYY') AND TO_DATE('"
				+ fechaFin
				+ "','DD/MM/YYYY') "
				+ "UNION SELECT ENCFAC_NUMERO, ENCFAC_FECHAEMISION AS FECHA, 'CI', 'FAC' AS ORIGEN FROM VEN_ENCFAC WHERE ASI_NRO IS NULL AND COM_CODIGO='01' AND ENCFAC_FECHAEMISION BETWEEN "
				+ "TO_DATE('"
				+ fechaInicio
				+ "','DD/MM/YYYY') AND TO_DATE('"
				+ fechaFin
				+ "','DD/MM/YYYY') "
				+ "UNION SELECT ENCDVF_NUMERO, ENCDVF_FECHAEMISION AS FECHA, 'NC', 'FAC' AS ORIGEN FROM VEN_ENCDVF WHERE ASI_NRO IS NULL  AND COM_CODIGO='01' AND ENCDVF_FECHAEMISION BETWEEN "
				+ "TO_DATE('"
				+ fechaInicio
				+ "','DD/MM/YYYY') AND TO_DATE('"
				+ fechaFin
				+ "','DD/MM/YYYY') "
				+ "UNION SELECT ENCGRE_NUMERO, ENCGRE_FECHAEMISION AS FECHA, 'AGR', 'VEN' AS ORIGEN FROM VEN_ENCGRE WHERE ASI_NRO IS NULL AND COM_CODIGO='01' AND ENCGRE_FECHAEMISION BETWEEN "
				+ "TO_DATE('"
				+ fechaInicio
				+ "','DD/MM/YYYY') AND TO_DATE('"
				+ fechaFin
				+ "','DD/MM/YYYY') "
				+ "UNION SELECT ENCCOB_COMPROBANTE, ENCCOB_FECHAEMISION AS FECHA, 'CI', 'CXC' AS ORIGEN FROM CXC_ENCCOBRO_MULTIPLE WHERE ASI_NRO IS NULL AND COM_CODIGO='01' AND ENCCOB_FECHAEMISION BETWEEN "
				+ "TO_DATE('"
				+ fechaInicio
				+ "','DD/MM/YYYY') AND TO_DATE('"
				+ fechaFin
				+ "','DD/MM/YYYY') "
				+ "UNION SELECT TRNCOBRO_NRODOC, TRNCOBRO_FECHATRN AS FECHA, 'CI', 'CXC' AS ORIGEN FROM CXC_TRNCOBRO WHERE ASI_NRO IS NULL AND COM_CODIGO='01' AND TRNCOBRO_FECHATRN BETWEEN "
				+ "TO_DATE('"
				+ fechaInicio
				+ "','DD/MM/YYYY') AND TO_DATE('"
				+ fechaFin
				+ "','DD/MM/YYYY') AND TRNCOBRO_TIPODOC <> 'FC' AND APLORG_CODIGO NOT IN ('SISTEMA','FAC')"
				+ "UNION SELECT TRNCOBRO_NRODOC, TRNCOBRO_FECHATRN AS FECHA, 'CE', 'CXP' AS ORIGEN FROM CXP_TRNCOBRO WHERE ASI_NRO IS NULL AND COM_CODIGO='01' AND TRNCOBRO_FECHATRN BETWEEN "
				+ "TO_DATE('"
				+ fechaInicio
				+ "','DD/MM/YYYY') AND TO_DATE('"
				+ fechaFin
				+ "','DD/MM/YYYY') AND TRNCOBRO_TIPODOC <> 'FC' AND APLORG_CODIGO NOT IN ('SISTEMA','COM') "
				+ "UNION SELECT KAR_NRO, KAR_FECHA AS FECHA, 'AE', 'ES_INV' AS ORIGEN FROM INV_ENCKARDEX WHERE ASI_NRO IS NULL AND COM_CODIGO='01' AND KAR_FECHA BETWEEN "
				+ "TO_DATE('"
				+fechaInicio
				+ "','DD/MM/YYYY') AND TO_DATE('"
				+ fechaFin
				+ "','DD/MM/YYYY') "
				+ "UNION SELECT  TRNTOMAFISICA_NRO, TRNTOMAFISICA_FECHA AS FECHA, 'ATF', 'TF_INV' AS ORIGEN FROM INV_TRNTOMAFISICA WHERE ASI_NRO IS NULL AND COM_CODIGO='01' AND TRNTOMAFISICA_FECHA BETWEEN "
				+ "TO_DATE('"
				+ fechaInicio
				+ "','DD/MM/YYYY') AND TO_DATE('"
				+ fechaFin
				+ "','DD/MM/YYYY') "
				+ "UNION SELECT TRANSFER_NRO, TRANSFER_FECHAEMISION AS FECHA, 'ATRF', 'INV' AS ORIGEN FROM INV_ENCTRANSFER WHERE ASI_NRO IS NULL AND COM_CODIGO='01' AND TRANSFER_FECHAEMISION BETWEEN "
				+ "TO_DATE('"
				+ fechaInicio
				+ "','DD/MM/YYYY') AND TO_DATE('"
				+ fechaFin
				+ "','DD/MM/YYYY') "
				+ "UNION SELECT TRNBAN_NUMERO, TRNBAN_FECHA AS FECHA, 'ABAN', 'BAN' AS ORIGEN FROM BAN_TRNBAN WHERE ASI_NRO IS NULL AND COM_CODIGO='01' AND TRNBAN_FECHA BETWEEN "
				+ "TO_DATE('"
				+ fechaInicio
				+ "','DD/MM/YYYY') AND TO_DATE('"
				+ fechaFin
				+ "','DD/MM/YYYY') "
				+ "UNION SELECT TRNCAJ_NUMERO, TRNCAJ_FECHA AS FECHA, 'CAJV', 'CAJ' AS ORIGEN FROM BAN_TRNCAJ WHERE ASI_NRO IS NULL AND COM_CODIGO='01' AND TRNCAJ_FECHA BETWEEN "
				+ "TO_DATE('"
				+ fechaInicio
				+ "','DD/MM/YYYY') AND TO_DATE('"
				+ fechaFin
        + "','DD/MM/YYYY') ) " + "ORDER BY ORIGEN, FECHA"; */
        
        return this.http.post<any[]>(this.way + '/cont/asidescuadre/83bacc5f9eaf581', {
          elementos: {
			fechaInicio: strfechaInicio,
			fechaFin: strfechaFin
		   },
        }, this.confIniciales.httpOptions()
      ).toPromise();
    }
//CDPJ
llenarListar2(strfechaInicio, strfechaFin,){
	return this.http.post<any[]>(this.way + '/cont/asidescuadre/15abaeadd65ceb000', {
		elementos: {
		  fechaInicio: strfechaInicio,
		  fechaFin: strfechaFin,
		  com_codigo:'01'
		}
	  }, this.confIniciales.httpOptions()
	).toPromise();
  }
//CDPJ
    parseDate(fecha:Date){
      var mes=fecha.getMonth()+1
      //var date= new Date(intAnio,strMes-1,1);
      //var date1= new Date(date.getFullYear(),date.getMonth()+1,0);
      var fec=fecha.getDate()+"/"+mes+"/"+fecha.getFullYear();
      return fec;
    }
    
}
