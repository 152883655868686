//
// SACI WEB
// Rutina: Maestro de anexos
// Propietario: Francisco Cevallos
// Modificado por: FC
// Fecha de creación: 02-03-2020
// Fecha de Modificación: 06-03-2020
//

import { Component, OnInit, ViewChild } from '@angular/core';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { AnexmaecodsriService } from '../anexservicios/anexmaecodsri.service';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { Anexmaecodsri } from '../anexinterfaces/anexmaecodsri';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
//CDPJ
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-anexmaecodsri',
  templateUrl: './anexmaecodsri.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class AnexmaecodsriComponent implements OnInit {

  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;


  btnNuevo: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  btnGuardar: boolean;
  opcionesTipo: SelectItem[];
  tipoSeleccionado: string;
  codsriSeleccionado: Anexmaecodsri;
  permisoCodSRI: SegMaePermiso;
  tipo: string;
  public frameworkComponents;
  largo: string;
  rowSelection = 'multiple';
  rowStyle;
  defaultColDefCodSRI;
  nuevoCampo: boolean;
  actualizar: boolean;


  columnDefsCodSRI = [
    {
      headerName: 'Código ', field: 'Código', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10
        }
      }
    },
    {
      headerName: 'Descripción', field: 'Descripción', cellEditor: 'cellPrueba', width: 200, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 100
        }
      }
    },
    {
      headerName: 'Año', field: 'Año', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 4

        }
      }
    },
    {
      headerName: 'Ref.', field: 'Ref', cellEditor: 'cellPrueba', width: 150, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Código Con', field: 'CodigoCon', cellEditor: 'cellPrueba', width: 200, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 30,
          opcionbuscar: true
        }
      }
    },
    //CDPJ
    {
      headerName: 'Fecha Ingreso', field: 'FechaIngreso', cellEditor: 'cellPrueba', width: 200, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: true,
          tamanocelda: 30,
          opcionbuscar: true,

        }
      }
    },
    {
      headerName: 'Fecha Fin', field: 'FechaFin', cellEditor: 'cellPrueba', width: 200, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: true,
          tamanocelda: 30,
          opcionbuscar: true,

        }
      }
    }
    //CDPJ

  ];


  constructor(public anexmaecodsriService: AnexmaecodsriService, private busqService: InvbusquedaService, private message: MessageService,
    private confirmationService: ConfirmationService, private errorService: ErroresBaseDatosService, private auditoriagral: AuditoriagralService,
    private permisosService: PermisosService, private init: NuevoComponentService,
    //CDPJ
    private datePipe: DatePipe) {

    this.agTable();
    this.opcionesTipo = [];

  }


  ngOnInit() {
    this.largo = '350';
    this.obtenerTipo();
    this.tipo = '';
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.permisosService.getListaPermisos('ANEX', '1', '1178').subscribe((res) => {
      this.permisoCodSRI = res[0];
    });
    this.nuevoCampo = false;
    this.actualizar = false;


  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.defaultColDefCodSRI = {
      width: 100,
      objeto: 'anexmaecodsri'
    };
    this.permisoCodSRI = {};

  }

  manejarSenales(valor) {

    if (valor === 'Nuevo') {
      if (this.permisoCodSRI.PERINSERCION === 1) {
        if (this.tipo !== '') {
          this.btnNuevo = true;
          this.btnGuardar = false;
          this.btnBorrar = false;
          this.btnRegresar = false;
          this.nuevoCampo = true;

          this.codsriSeleccionado = {
            Tipo: this.tipo,
            Código: '',
            Descripción: '',
            Año: '',
            CodigoCon: '',
            Ref: '',
            FechaIngreso: '',
            FechaFin: '31/12/2030',
          };

          this.anexmaecodsriService.codSRI.push(this.codsriSeleccionado);
          this.aggrid.refreshaggrid(this.anexmaecodsriService.codSRI, this.defaultColDefCodSRI.objeto);
        }

      } else if (this.permisoCodSRI.PERINSERCION === 0) {
        this.message.add({
          key: 'anexmaecodsriKey',
          severity: 'error',
          summary: 'Inserción ',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }

    }
    if (valor === 'Cancelar') {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.anexmaecodsriService.obtenerCodSRI(this.tipo).subscribe((res) => {
        this.anexmaecodsriService.codSRI = res;
        this.anexmaecodsriService.codSRI.map((ref) => {
          //CDPJ
          ref.FechaIngreso = this.datePipe.transform(ref['FechaInicio'], 'dd/MM/yyyy');
          ref.FechaFin = this.datePipe.transform(ref['FechaFin'], 'dd/MM/yyyy');
          //CDPJ
          ref.Ref = ref['Ref.'];
          delete ref['Ref.'];
        });
        this.aggrid.refreshaggrid(this.anexmaecodsriService.codSRI, this.defaultColDefCodSRI.objeto);
      });
    }
    if (valor === 'Guardar') {
      if (this.permisoCodSRI.PERINSERCION === 1) {
        this.guardarCodSRI();
      } else if (this.permisoCodSRI.PERINSERCION === 0) {
        this.message.add({
          key: 'anexmaecodsriKey',
          severity: 'error',
          summary: 'Actualización ',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }

    }
    if (valor === 'Borrar') {
      if (this.permisoCodSRI.PERINSERCION === 1) {
        if (this.codsriSeleccionado === undefined) {
          this.message.add({
            key: 'anexmaecodsriKey',
            severity: 'error',
            summary: 'Eliminacion ',
            detail: 'Seleccion Incorrecta, no se ha seleccionado una formula  '
          });
        } else {
          this.confirmarCodSRI();
        }
      } else if (this.permisoCodSRI.PERINSERCION === 0) {
        this.message.add({
          key: 'anexmaecodsriKey',
          severity: 'error',
          summary: 'Eliminación ',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
      }
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }

  confirmarCodSRI() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el código SRI seleccionado',
      header: 'Eliminar Código SRI',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmaranexmaecodsri',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarcodsri();
      },
      reject: () => {
      }
    });
  }

  eliminarcodsri() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.anexmaecodsriService.eliminarCodSRI(this.codsriSeleccionado).subscribe(() => {
      this.message.add({
        key: 'anexmaecodsriKey',
        severity: 'success',
        summary: 'Eliminación exitosa',
        detail: 'Se eliminó el código SRI  correctamente'
      });
      this.anexmaecodsriService.codSRI = this.anexmaecodsriService.codSRI.filter((val, j) => j !==
        this.anexmaecodsriService.codSRI.indexOf(this.codsriSeleccionado));
      this.aggrid.refreshaggrid(this.anexmaecodsriService.codSRI, this.defaultColDefCodSRI.objeto);
      this.auditoriagral.registrarAuditoria('ANEX_MAECODSRI', this.tipo, 'E',
        '', '01', '', '', '', '').subscribe(() => {
        });

    }, error1 => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.message.add({
        key: 'anexmaecodsriKey',
        severity: 'error',
        summary: 'Eliminación de el código SRI',
        detail: mensaje
      });
    });
  }
  //CDPJ
  validarFecha(fecha) {
    var Reg = /^\d{1,2}\/\d{1,2}\/\d{2,4}$/;
    if ((fecha.match(Reg)) && (fecha != '')) {
      console.log('if', fecha);
      return true;
    } else {
      console.log('else', fecha);
      return false;
    }
  }
  //CDPJ
  guardarCodSRI() {
    this.btnGuardar = true;
    this.btnNuevo = false;
    this.btnBorrar = false;
    this.btnRegresar = true;
    if (this.actualizar === true) {
      let coincidencias: number;
      coincidencias = 0;
      this.anexmaecodsriService.codSRI.map((con) => {
        if (this.codsriSeleccionado.Código === con.Código) {
          coincidencias++;
        }
      });
      if (coincidencias !== 0) {
        //CDPJ
        if (this.validarFecha(this.codsriSeleccionado.FechaIngreso) === false || this.validarFecha(this.codsriSeleccionado.FechaFin) === false) {
          //console.log('A', this.codsriSeleccionado.FechaFin[5]);
          //console.log('A', this.codsriSeleccionado.FechaIngreso);
          this.message.add({
            key: 'anexmaecodsriKey',
            severity: 'error',
            summary: 'Actualización de Grupo',
            detail: 'El formato de la Fecha de ingreso o Fecha fin no es el correcto'
          });
          return;
        } else {
          this.anexmaecodsriService.actualizarCodSRI(this.codsriSeleccionado).subscribe(() => {
            this.message.add({
              key: 'anexmaecodsriKey',
              severity: 'success',
              summary: 'Actualización exitosa',
              detail: 'Se actualizó correctamente'
            });
            this.aggrid.isEditable(false);
            this.auditoriagral.registrarAuditoria('ANEX_MAECODSRI', this.tipo, 'A',
              '', '01', '', '', '', '').subscribe(() => {
              });
          }, error1 => {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.message.add({
              key: 'anexmaecodsriKey',
              severity: 'error',
              summary: 'Actualización de Grupo',
              detail: mensaje
            });
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
            this.btnRegresar = true;
            this.anexmaecodsriService.obtenerCodSRI(this.tipo).subscribe((res) => {
              this.anexmaecodsriService.codSRI = res;
              this.anexmaecodsriService.codSRI.map((ref) => {
                //CDPJ
                ref.FechaIngreso = this.datePipe.transform(ref['FechaInicio'], 'dd/MM/yyyy');
                ref.FechaFin = this.datePipe.transform(ref['FechaFin'], 'dd/MM/yyyy');
                //CDPJ
                ref.Ref = ref['Ref.'];
                delete ref['Ref.'];
              });
              this.aggrid.refreshaggrid(this.anexmaecodsriService.codSRI, this.defaultColDefCodSRI.objeto);
            });
          });
        }


      }
    } else if (this.actualizar === false) {
      let coincidencias: number;
      coincidencias = 0;
      this.anexmaecodsriService.codSRI.map((con) => {
        if (this.codsriSeleccionado.Código === con.Código) {
          coincidencias++;
        }
      });
      if (coincidencias !== 0) {

        //CDPJ
        if (this.validarFecha(this.codsriSeleccionado.FechaIngreso) === false || this.validarFecha(this.codsriSeleccionado.FechaFin) === false) {
          //console.log('A', this.codsriSeleccionado.FechaFin[5]);
          //console.log('A', this.codsriSeleccionado.FechaIngreso);
          this.message.add({
            key: 'anexmaecodsriKey',
            severity: 'error',
            summary: 'Actualización de Grupo',
            detail: 'El formato de la Fecha de ingreso o Fecha fin no es el correcto'
          });
          return;
        } else {
          this.anexmaecodsriService.insertarCodSRI(this.codsriSeleccionado).subscribe(() => {
            this.message.add({
              key: 'anexmaecodsriKey',
              severity: 'success',
              summary: 'Inserción exitosa',
              detail: 'Se insertó correctamente'
            });
            this.nuevoCampo = false;
            this.aggrid.isEditable(false);
            this.auditoriagral.registrarAuditoria('ANEX_MAECODSRI', this.tipo, 'I',
              '', '01', '', '', '', '').subscribe(() => {
              });
          }, error1 => {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.message.add({
              key: 'anexmaecodsriKey',
              severity: 'error',
              summary: 'Inserción de Código SRI ',
              detail: mensaje
            });
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
            this.btnRegresar = true;
            this.anexmaecodsriService.obtenerCodSRI(this.tipo).subscribe((res) => {
              this.anexmaecodsriService.codSRI = res;
              this.anexmaecodsriService.codSRI.map((ref) => {
                //CDPJ
                ref.FechaIngreso = this.datePipe.transform(ref['FechaInicio'], 'dd/MM/yyyy');
                ref.FechaFin = this.datePipe.transform(ref['FechaFin'], 'dd/MM/yyyy');
                //CDPJ
                ref.Ref = ref['Ref.'];
                delete ref['Ref.'];
              });
              this.aggrid.refreshaggrid(this.anexmaecodsriService.codSRI, this.defaultColDefCodSRI.objeto);
            });
          });
        }
      }
    }
  }

  obtenerTipo() {
    this.anexmaecodsriService.obtenerTipos().subscribe((res) => {
      this.opcionesTipo.push({ label: '', value: '' });
      res.map((tipo) => {
        let nuevoItem: any;
        nuevoItem = { label: tipo.REF_NOMBRE, value: tipo.REF_CODIGO };
        this.opcionesTipo.push(nuevoItem);
      });
    });
  }

  obtenerCodSRI(tipo: string) {
    this.btnGuardar = true;
    this.tipo = tipo;
    if (tipo !== '') {
      this.anexmaecodsriService.obtenerCodSRI(tipo).subscribe((res) => {
        this.anexmaecodsriService.codSRI = res;
        this.anexmaecodsriService.codSRI.map((ref) => {
          //CDPJ
          ref.FechaIngreso = this.datePipe.transform(ref['FechaInicio'], 'dd/MM/yyyy');
          ref.FechaFin = this.datePipe.transform(ref['FechaFin'], 'dd/MM/yyyy');
          //CDPJ
          ref.Ref = ref['Ref.'];
          delete ref['Ref.'];
        });

        this.aggrid.refreshaggrid(this.anexmaecodsriService.codSRI, this.defaultColDefCodSRI.objeto);
      });
    } else if (tipo === '') {
      this.anexmaecodsriService.codSRI = [];
      this.aggrid.refreshaggrid(this.anexmaecodsriService.codSRI, this.defaultColDefCodSRI.objeto);

    }

  }

  selectedCodSRI(valor) {
    if (valor.object !== null) {
      this.codsriSeleccionado = valor.object;
      if (this.nuevoCampo === true) {
        this.actualizar = false;
      } else if (this.nuevoCampo === false) {
        this.actualizar = true;

      }
    }
  }

  cambio(cambio) {
    this.btnGuardar = false;
    this.btnRegresar = false;
  }



}
