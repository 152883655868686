import { Component, OnInit } from '@angular/core';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Component({
  selector: 'app-invcontdocumentos',
  templateUrl: './invcontdocumentos.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class InvcontdocumentosComponent implements OnInit {

  constructor(private init: NuevoComponentService) { }

  ngOnInit() {
  }
  
  manejarSenales(valor) {
    if (valor === 'Visto') {
    
    }
    if (valor === 'Salir') {
      this.salir();
    }
  }
  
  salir() {
    this.init.tabs.splice(this.init.tabindex, 1);
  }
  
  
}
