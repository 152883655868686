import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { InvTrnlote } from '../veninterfaces/invtrnlote';

import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
@Injectable({
  providedIn: 'root'
})

export class InvtrnloteService {
  public detlotearray: InvTrnlote[][] = [];
  public strOrigen: any[] = [];
  public decimalesCANTIDADlote:number//CDPJ

  public url: string;
  public auxsalto: boolean;  
  public way: string;
  public hmpConfiguracion = [];
  public auxcod:string;//CDPJ  

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }

 

  getConfiguracion() {
    return this.http.post<any[]>(this.way + '/reporte/auditoria/83bacc5df287b8000000', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }


  listarDatos(strNumeroDoc, intLineaDoc, strOrigen): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/ventas/invtrnlote/uubujhafg81yjbe', {

      elementos: {},
      datos: {
        NumeroDoc: strNumeroDoc,
        IntLineaDoc: intLineaDoc,
        Origen: strOrigen
      }
    }, this.confIniciales.httpOptions());
  }


  insertarTrnLote(trnlote): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/invtrnlote/vmk0egitrerm2tm', {
      elementos: {
        p_lote_numero: trnlote.LOTE_NUMERO,
        p_trnlote_fechavencimiento: trnlote.TRNLOTE_FECHAVENCIMIENTO,
        p_trnlote_documento: trnlote.TRNLOTE_DOCUMENTO,
        p_trnlote_lineadoc: trnlote.TRNLOTE_LINEADOC,
        p_trnlote_cantidad: trnlote.TRNLOTE_CANTIDAD,
        p_trnlote_unidades: trnlote.TRNLOTE_UNIDADES,
        p_trnlote_fracciones: trnlote.TRNLOTE_FRACCIONES,
        p_com_codigo: trnlote.COM_CODIGO,
        p_trnlote_origen: trnlote.TRNLOTE_ORIGEN,
        p_art_codigo: trnlote.ART_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }

  actualizarTrnLote(trnlote): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/invtrnlote/6mhqf74z5ktrmbg', {
      elementos: {
        p_lote_numero: trnlote.LOTE_NUMERO,
        p_trnlote_fechavencimiento: trnlote.TRNLOTE_FECHAVENCIMIENTO,
        p_trnlote_documento: trnlote.TRNLOTE_DOCUMENTO,
        p_trnlote_lineadoc: trnlote.TRNLOTE_LINEADOC,
        p_trnlote_cantidad: trnlote.TRNLOTE_CANTIDAD,
        p_trnlote_unidades: trnlote.TRNLOTE_UNIDADES,
        p_trnlote_fracciones: trnlote.TRNLOTE_FRACCIONES,
        p_trnlote_origen: trnlote.TRNLOTE_ORIGEN,
        p_com_codigo: trnlote.COM_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarTrnLote(trnlote): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/invtrnlote/xisg7bbwm2sbffe', {
      elementos: {
        p_lote_numero: trnlote.LOTE_NUMERO,
        p_trnlote_documento: trnlote.TRNLOTE_DOCUMENTO,
        p_trnlote_lineadoc: trnlote.TRNLOTE_LINEADOC,
        p_com_codigo: trnlote.COM_CODIGO,
        p_trnlote_origen: trnlote.TRNLOTE_ORIGEN
      },
    }, this.confIniciales.httpOptions());
  }

  existencialote(LOTE_NUMERO, auxcodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/invtrnlote/399c973aebb24a00000', {
      elementos: {
        LOTE_NUMERO,
        auxcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
}




