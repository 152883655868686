// SACI WEB
// Rutina: Maestro de Centro de Costo
// Propietario: Nicole Pirca
// Modificado por: SS
// Fecha de creación: 2019
// Fecha de Modificación: 30-11-2019
//

import {Component, OnInit, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {Banmaecaja} from '../../ban/baninterfaces/banmaecaja';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {ConmaecenService} from '../conservicios/conmaecen.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {ConmaecentroCostos} from '../coninterfaces/conmaecentro-costos';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {DatePipe} from '@angular/common';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Component({
  selector: 'app-conmaecon',
  templateUrl: './conmaecen.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class ConmaecenComponent implements OnInit {
  
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  actualizar: boolean;
  codigoAux: any;
  nuevoCampo: boolean;
  largo: string;
  centrocostoseleccionado: ConmaecentroCostos;
  tipoBusqueda: string;
  index: number;
  permisoCentroCosto: SegMaePermiso;
  arregloCons: any[];
  public frameworkComponents;
  rowStyle;
  defaultColDefMayorGeneral;
  defaultColDefCuentaContable;
  rowSelection = 'multiple';
  columnDefsConCuenta = [
    {
      headerName: 'Código', field: 'CEN_CODIGO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 35
          }
        }
    },
    {
      headerName: 'Nombre', field: 'CEN_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 45
          }
        }
    },
  ];
  columnDefMayorGeneral = [
    {
      headerName: 'Fecha', field: 'ASI_FECHA', width: 100,
      
    },
    {
      headerName: 'Asiento', field: 'ASI_nro', width: 100,
      
    },
    {
      headerName: 'Concepto', field: 'DETASI_Concepto', width: 100,
      
      
    },
    {
      headerName: 'Debe', field: 'Debe', width: 100
      
      
    },
    {
      headerName: 'Haber', field: 'Haber', width: 100,
    },
    {
      headerName: 'Saldo', field: 'Saldo', width: 100,
    },
  
  
  ];
  
  constructor(public ConmaeconServices: ConmaecenService, private permisosService: PermisosService,
              private message: MessageService, private auditoriagral: AuditoriagralService,
              private errorService: ErroresBaseDatosService, private confirmationService: ConfirmationService, private datePipe: DatePipe,
              private init: NuevoComponentService) {
    this.agTable();
    this.ConmaeconServices.conmaeMayorGeneral = [];
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefCuentaContable = {
      editable: true,
      width: 130,
      objeto: 'maecentroc'
    };
    this.defaultColDefMayorGeneral = {
      editable: true,
      width: 130,
      objeto: 'maemayorgeneral'
    };
  }
  
  ngOnInit() {
    
    this.index = 0;
    this.permisosService.getListaPermisos('CON', '1', '8').subscribe((res) => {
      this.permisoCentroCosto = res[0];
    });
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.actualizar = false;
    this.nuevoCampo = false;
    this.codigoAux = 0;
    this.ConmaeconServices.getCentroCostos().subscribe((res) => {
      if (res !== null) {
        this.ConmaeconServices.conmaecentroCostos = res;
      }
    });
    this.largo = '258';
    
  }
  
  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      if (this.permisoCentroCosto.PERINSERCION === 1) {
        this.btnNuevo = true;
        this.btnGuardar = false;
        this.btnBorrar = false;
        this.btnRegresar = false;
        this.nuevoCampo = true;
        const nuevoCentro = {
          CEN_CODIGO: '',
          CEN_NOMBRE: '',
          COM_CODIGO: '01',
        };
        this.aggrid.isEditable(true);


        const indi =  this.ConmaeconServices.conmaecentroCostos.indexOf(this.centrocostoseleccionado);
        if (indi === -1) {
          this.ConmaeconServices.conmaecentroCostos.push(nuevoCentro);
          this.centrocostoseleccionado = this.ConmaeconServices.conmaecentroCostos
            [this.ConmaeconServices.conmaecentroCostos.length - 1];
          this.aggrid.refreshaggrid(this.ConmaeconServices.conmaecentroCostos, this.defaultColDefCuentaContable.objeto);
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefCuentaContable.objeto].setFocusedCell(
            this.ConmaeconServices.conmaecentroCostos.length - 1, 'CEN_CODIGO');
        } else {
          this.centrocostoseleccionado = nuevoCentro;
          this.ConmaeconServices.conmaecentroCostos.splice(indi + 1, 0, nuevoCentro);
          this.aggrid.refreshaggridindex(this.ConmaeconServices.conmaecentroCostos, this.defaultColDefCuentaContable.objeto, indi);
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefCuentaContable.objeto].setFocusedCell(
            indi + 1, 'CEN_CODIGO');
        }




       /*  this.ConmaeconServices.conmaecentroCostos.push(this.centrocostoseleccionado);
        this.aggrid.refreshaggrid(this.ConmaeconServices.conmaecentroCostos, this.defaultColDefCuentaContable.objeto); */
      } else if (this.permisoCentroCosto.PERINSERCION === 0) {
        this.message.add({
          key: 'maecentroc',
          severity: 'error',
          summary: 'Inserción de Centro de Costos',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    }
    if (valor === 'Guardar') {
      if (this.permisoCentroCosto.PERACTUALIZACION === 1) {
        this.btnGuardar = false;
        this.btnNuevo = true;
        this.btnBorrar = false;
        this.btnRegresar = true;
        console.log(this.centrocostoseleccionado.CEN_CODIGO);
        this.ConmaeconServices.existeCentroCosto(this.centrocostoseleccionado.CEN_CODIGO).subscribe((existe) => {
          console.log(existe);
          if (existe.length > 0) {
            this.ConmaeconServices.actualizarCentroC(this.centrocostoseleccionado, this.codigoAux).subscribe(() => {
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
              this.message.add({
                key: 'maecentroc',
                severity: 'success',
                summary: 'Actualización exitosa',
                detail: 'Se actualizó Centro de Costos Correctamente'
              });
              this.auditoriagral.registrarAuditoria('CON_MAECEN', this.centrocostoseleccionado.CEN_CODIGO, 'A', '',
                '01', '', '', '', '').subscribe(() => {
              });
              this.aggrid.isEditable(false);
            }, error1 => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.message.add({
                key: 'maecentroc',
                severity: 'error',
                summary: 'Actualización de Centro de Costo',
                detail: mensaje
              });
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
              this.ConmaeconServices.getCentroCostos().subscribe((res) => {
                this.ConmaeconServices.conmaecentroCostos = res;
                this.aggrid.refreshaggrid(this.ConmaeconServices.conmaecentroCostos, this.defaultColDefCuentaContable.objeto);
              });
            });
          } else {
            this.ConmaeconServices.insertarCentroC(this.centrocostoseleccionado).subscribe(() => {
              this.message.add({
                key: 'maecentroc',
                severity: 'success',
                summary: 'Inserción exitosa',
                detail: 'Centro de Costos insertado exitosamente'
              });

              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
              
              this.auditoriagral.registrarAuditoria('CON_MAECEN',
                String(this.centrocostoseleccionado.CEN_CODIGO), 'I', '',
                '01', '', '', '', '').subscribe(() => {
              });
              this.aggrid.isEditable(false);
              this.nuevoCampo = false;
            }, error1 => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.message.add({
                key: 'maecentroc',
                severity: 'error',
                summary: 'Inserción de Centro de Costo',
                detail: mensaje
              });
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
              this.ConmaeconServices.getCentroCostos().subscribe((res) => {
                this.ConmaeconServices.conmaecentroCostos = res;
                this.aggrid.refreshaggrid(this.ConmaeconServices.conmaecentroCostos, this.defaultColDefCuentaContable.objeto);
              });
            });
          }
        });
      } else if (this.permisoCentroCosto.PERACTUALIZACION === 0) {
        this.message.add({
          key: 'maecentroc',
          severity: 'error',
          summary: 'Actualización de Centro de Costos',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }
    }
    if (valor === 'Borrar') {
      if (this.permisoCentroCosto.PERELIMACION === 1) {
        this.btnNuevo = false;
        this.aggrid.isEditable(false);
        if (this.centrocostoseleccionado === undefined) {
          this.message.add({
            key: 'maecentroc',
            severity: 'error',
            summary: 'Eliminacion de Centro de Costos',
            detail: 'Seleccion Incorrecta, no se ha seleccionado un Centro de Costos'
          });
        } else {
          this.confirmarCentroCostos();
        }
      } else if (this.permisoCentroCosto.PERELIMACION === 0) {
        this.message.add({
          key: 'maecentroc',
          severity: 'error',
          summary: 'Eliminación de Centro de Costos',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
        this.aggrid.refreshaggrid(this.ConmaeconServices.conmaecentroCostos, this.defaultColDefCuentaContable.objeto);
      }
    }
    if (valor === 'Cancelar') {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.aggrid.isEditable(false);
      this.ConmaeconServices.getCentroCostos().subscribe((res) => {
        this.ConmaeconServices.conmaecentroCostos = res;
        this.aggrid.refreshaggrid(this.ConmaeconServices.conmaecentroCostos, this.defaultColDefCuentaContable.objeto);
      });
    }
    
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
  selectedCuentaCosto(valor) {
    console.log(valor.object);
    if (this.index === 0) {
      if (valor.object !== null && valor.object !== undefined) {
        this.centrocostoseleccionado = valor.object;
        this.codigoAux = valor.object.CEN_CODIGO;
        this.ConmaeconServices.conmaeMayorGeneral = [];
        //let arregloMayorGeneral = [];
        // this.ConmaeconServices.obtenerMayorGeneral(this.centrocostoseleccionado).subscribe((res) => {
        //   let totalsaldo = 0;
        //   let totaldebe = 0;
        //   let totalhaber = 0;
        //   if (res !== null) {
        //     res.map((centro) => {
        //       const centrocosto = {
        //         ASI_FECHA: '',
        //         ASI_nro: '',
        //         DETASI_Concepto: '',
        //         Debe: '',
        //         Haber: '',
        //         Saldo: ''
        //       };
        //       centrocosto.ASI_FECHA = centro.Fecha.toLocaleString().substr(0, 10);
        //       centrocosto.ASI_FECHA = this.datePipe.transform(centrocosto.ASI_FECHA, 'dd/MM/yyyy');
        //       centrocosto.ASI_nro = centro.Asiento;
        //       centrocosto.DETASI_Concepto = centro.Concepto;
        //       centrocosto.Debe = centro.Debe.toFixed(2);
        //       centrocosto.Haber = (centro.Haber * -1).toFixed(2);
        //       totalsaldo = totalsaldo + Number(centrocosto.Debe) - Number(centrocosto.Haber);
        //       centrocosto.Saldo = totalsaldo.toFixed(2);
        //       totaldebe = totaldebe + Number(centro.Debe);
        //       totalhaber = totalhaber + Number(centro.Haber);
        //       arregloMayorGeneral.push(centrocosto);
              
        //     });
        //   }
        //   arregloMayorGeneral.push({
        //     ASI_FECHA: '',
        //     ASI_nro: '',
        //     DETASI_Concepto: '',
        //     Debe: totaldebe.toFixed(2),
        //     Haber: (totalhaber * -1).toFixed(2),
        //     Saldo: totalsaldo.toFixed(2)
        //   });
        //   this.ConmaeconServices.conmaeMayorGeneral = arregloMayorGeneral;
        // });
      }
    }
  }
  
  cambio(cambio) {
    this.btnGuardar = false;
    this.btnRegresar = false;
  }
  
  confirmarCentroCostos() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el Centro de Costos',
      header: 'Eliminar Centro de Costos',
      icon: 'pi pi-exclamation-triangle',
      key: 'Maecentroc',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarCentroCostos();
      },
      reject: () => {
      }
    });
  }
  
  eliminarCentroCostos() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.ConmaeconServices.eliminarCentroCosto(this.centrocostoseleccionado).subscribe((res) => {
      let ifDelete = 0;
      if (res.length > 0) {
        if (res[0][0] !== undefined) {
          ifDelete = res[0][0];
        }
        if (res[0][1] !== undefined) {
          ifDelete = res[0][1];
        }

        if (ifDelete != 1) { // /in dica que se no pudo borrar
          this.message.add({
            key: 'maecentroc',
            severity: 'error',
            summary: 'El centro posee subcentros',
            detail: 'Ha sido imposible eliminar el cento de costos debido a que este posee uno o mas subcentros'
          });
        }	else {
          this.message.add({
            key: 'maecentroc',
            severity: 'success',
            summary: 'Eliminación exitosa',
            detail: 'Se eliminó Centro de Costos correctamente'
          });
          this.auditoriagral.registrarAuditoria('CON_MAECEN', String(this.centrocostoseleccionado.CEN_CODIGO), 'E', '',
            '01', '', '', '', '').subscribe(() => {
          });
          this.ConmaeconServices.conmaecentroCostos = this.ConmaeconServices.conmaecentroCostos.filter((val, j) => j !==
            this.ConmaeconServices.conmaecentroCostos.indexOf(this.centrocostoseleccionado));
          this.aggrid.refreshaggrid(this.ConmaeconServices.conmaecentroCostos, this.defaultColDefCuentaContable.objeto);
        }

      }
      
    });
  }
  
  
}
