import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UsuarioEsquema} from '../usuarioEsquema';
import {Usuario} from '../usuario';
import { ConfInicialesService } from './conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class PdvfacturaService {

  public way: string;
  
  constructor(
    private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService
  ) {
    this.way = sessionStorage.getItem('way');
  }

  erCliCodigoCliNombre() {
    return this.http.post(this.way + '/pdv/factura/1db6564cc3650c0000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  insertarFacturaPtoVta(venencfac): Observable<any> {
    
    return this.http.post(this.way + '/pdv/factura/12bf1dbebd4cf0000', {
      elementos: {
        p_ENCFAC_numero: venencfac.ENCFAC_NUMERO,
        p_ENCFAC_numero_1: venencfac.ENCFAC_NUMERO,
        p_COM_codigo_2: '01',
        p_ENCFAC_fechaemision_3: venencfac.ENCFAC_FECHAEMISION,
        p_ENCFAC_fechaentrega_4: venencfac.ENCFAC_FECHAENTREGA,
        p_ENCFAC_estado_5: venencfac.ENCFAC_ESTADO,
        p_CLI_codigo_6: venencfac.CLI_CODIGO,
        p_CLI_nombre_7: venencfac.CLI_NOMBRE,
        p_BOD_codigo_8: venencfac.BOD_CODIGO,
        p_VEN_codigo_9: venencfac.VEN_CODIGO,
        p_ENCFFA_codigo: null,
        p_ENCFAC_formapago_10: venencfac.ENCFAC_FORMAPAGO,
        p_ENCFAC_referencia: null,
        p_ENCFAC_tipo_11: null,
        p_ENCFAC_lista_12: venencfac.ENCFAC_LISTA,
        p_ENCFAC_fechavencimiento_13: venencfac.ENCFAC_FECHAVENCIMIENTO,
        p_ENCFAC_total_14: venencfac.ENCFAC_TOTAL,
        p_ENCFAC_totalneto: venencfac.ENCFAC_TOTALNETO,
        p_ENCFAC_porceiva_15: venencfac.ENCFAC_PORCEIVA,
        p_ENCFAC_valoriva_16: venencfac.ENCFAC_VALORIVA,
        p_ENCFAC_porcecar_17: venencfac.ENCFAC_PORCECAR,
        p_ENCFAC_valorcar_18: venencfac.ENCFAC_VALORCAR,
        p_ENCFAC_porcertc_19: 0,
        p_ENCFAC_valorrtc_20: 0,
        p_ENCFAC_porcedes_21: venencfac.ENCFAC_PORCEDES,
        p_ENCFAC_valordes_22: venencfac.ENCFAC_VALORDES,
        p_ENCFAC_valordev_23: venencfac.ENCFAC_VALORDEV,
        p_ENCFAC_porceint_26: venencfac.ENCFAC_PORCEINT,
        p_ENCFAC_valorint_27: venencfac.ENCFAC_VALORINT,
        p_ENCFAC_porcerec_28: venencfac.ENCFAC_PORCEREC,
        p_ENCFAC_valorrec_29: venencfac.ENCFAC_VALORREC,
        p_ENCFAC_VALORICE: venencfac.ENCFAC_VALORICE,
        p_ENCFAC_PORCEICE: venencfac.ENCFAC_PORCEICE,
        p_ENCFAC_impreso_30: venencfac.ENCFAC_IMPRESO,
        p_ENCFAC_comentario_31: venencfac.ENCFAC_COMENTARIO,
        p_ENCFAC_tipofac_32: venencfac.ENCFAC_TIPOFAC,
        p_ENCFAC_generafac_33: venencfac.ENCFAC_GENERAFAC,
        p_ENCFAC_numerodob_34: null,
        p_ENCFAC_direccion_35: venencfac.ENCFAC_DIRECCION,
        p_ENCFAC_telefono_36: venencfac.ENCFAC_TELEFONO,
        p_ENCFAC_rucide_37: venencfac.ENCFAC_RUCIDE,
        p_ENCFAC_chofer_38: venencfac.ENCFAC_CHOFER,
        p_ENCFAC_carro_39: venencfac.ENCFAC_CARRO,
        p_ENCFAC_placa_40: venencfac.ENCFAC_PLACA,
        p_ENCFAC_iva_41: venencfac.ENCFAC_IVA,
        p_ENCFAC_baseiva: venencfac.ENCFAC_BASEIVA,
        p_ENCFAC_baseice: venencfac.ENCFAC_BASEICE,
        p_ENCFAC_basecero: venencfac.ENCFAC_BASECERO,
        p_ENCFAC_otros: venencfac.ENCFAC_OTROS,
        p_ENCFAC_grupo_42: venencfac.ENCFAC_GRUPO,
        p_encfac_diascrdt: venencfac.ENCFAC_DIASCRDT,
        p_encfac_clidsct: venencfac.ENCFAC_CLIDSCT,
        p_usu_identificacion: this.usuario.identificacion,
        p_ENCFAC_TIPODSCTO: venencfac.ENCFAC_TIPODSCTO,
        p_ENCFAC_ORIGEN: venencfac.ENCFAC_ORIGEN,
        p_ENCFAC_secactual: venencfac.ENCFAC_SECACTUAL,
        p_ENCFAC_REFCLI: venencfac.ENCFAC_REFCLI,
        p_ENCFAC_NOTADEVENTA: venencfac.ENCFAC_NOTADEVENTA,
        p_ENCFAC_AUTORIZACION: venencfac.ENCFAC_AUTORIZACION,
        p_ENCFAC_PLACA_CLI: venencfac.ENCFAC_PLACA_CLI
      },
    }, this.confIniciales.httpOptions());
  }
  
  insertarDetalle(vendetfac): Observable<any> {
    
    return this.http.post(this.way + '/pdv/factura/95f8edf5aeebd80', {
      elementos: {
        p_DETFAC_linea: vendetfac.DETFAC_LINEA,
        p_ENCFAC_numero_1: vendetfac.ENCFAC_NUMERO,
        p_COM_codigo_2: '01',
        p_DETFAC_linea_3: vendetfac.DETFAC_LINEA_3,
        p_DETFAC_tipodet_4: vendetfac.DETFAC_TIPODET,
        p_BOD_codigo_5: vendetfac.BOD_CODIGO,
        p_DETFAC_codigo_6: vendetfac.DETFAC_CODIGO,
        p_DETFAC_descripcion_7: vendetfac.DETFAC_DESCRIPCION,
        p_DETFAC_tribiva_8: vendetfac.DETFAC_TRIBIVA,
        p_DETFAC_tribice_9: vendetfac.DETFAC_TRIBICE,
        p_DETFAC_simbolo_10: vendetfac.UNI_SIMBOLO,
        p_DETFAC_cantidad_11: vendetfac.DETFAC_CANTIDAD,
        p_DETFAC_precio_12: vendetfac.DETFAC_PRECIO,
        p_DETFAC_descuento_13: vendetfac.DETFAC_DESCUENTO,
        p_DETFAC_total_14: vendetfac.DETFAC_TOTAL,
        p_DETFAC_iva_15: vendetfac.DETFAC_IVA,
        p_DETFAC_ice_16: vendetfac.DETFAC_ICE,
        p_DETFAC_lista_17: vendetfac.DETFAC_LISTA,
        p_DETFAC_baseiva_18: vendetfac.DETFAC_BASEIVA,
        p_DETFAC_baseice_19: vendetfac.DETFAC_BASEICE,
        p_DETFAC_basecero_20: vendetfac.DETFAC_BASECERO,
        p_DETFAC_porceice_21: vendetfac.DETFAC_PORCEICE,
        p_ENCCOT_numero: vendetfac.ENCCOT_NUMERO,
        p_DETCOT_linea: vendetfac.DETCOT_LINEA,
        p_ENCPED_numero: vendetfac.ENCPED_NUMERO,
        p_DETPED_linea: vendetfac.DETPED_LINEA,
        p_ENCGRE_numero: vendetfac.ENCGRE_NUMERO,
        p_DETGRE_linea: vendetfac.DETGRE_LINEA,
        p_UNI_CODIGO: vendetfac.UNI_SIMBOLO,
        p_DETFAC_tipo_22: vendetfac.DETFAC_TIPO,
        p_DETFAC_codigoref_23: vendetfac.DETFAC_CODIGOREF,
        p_ENCFAC_numero: vendetfac.ENCFAC_NUMERO,
        p_detfac_promocion: vendetfac.DETFAC_PROMOCION,
        p_detfac_orden: vendetfac.DETFAC_ORDEN,
        p_detfac_linorgprom: vendetfac.DETFAC_LINORGPROM,
        p_maeprm_codigo: vendetfac.MAEPRM_CODIGO,
        p_DETFAC_FECHAREALIZACION: vendetfac.DETFAC_FECHAREALIZACION,
        p_detfac_precioc: vendetfac.DETFAC_PRECIOC,
        p_cen_codigo: vendetfac.CEN_CODIGO,
        p_detfac_bulto: vendetfac.DETFAC_BULTO,
        p_detfac_cantidadb: vendetfac.DETFAC_CANTIDADB,
        p_detfac_tiposri: vendetfac.DETFAC_TIPOSRI,
        p_detfac_signo: vendetfac.DETFAC_SIGNO,
        p_detfac_artacambio: vendetfac.DETFAC_ARTACAMBIO,
        p_ordtra_numero: vendetfac.ORDTRA_NUMERO,
        p_detordtra_linea: vendetfac.DETORDTRA_LINEA,
        p_encreq_numero: vendetfac.ENCREQ_NUMERO,
        p_detreq_linea: vendetfac.DETREQ_LINEA,
        p_detfac_basenoobjiva: vendetfac.DETFAC_BASENOOBJIVA,
        p_detfac_tribasenoobjiva: vendetfac.DETFAC_TRIBASENOOBJIVA,
        p_encmat_numero: vendetfac.ENCMAT_NUMERO,
        p_detmat_codigo: vendetfac.DETMAT_CODIGO,
        p_detfac_ordenlote: vendetfac.DETFAC_ORDENLOTE,
        p_DETFAC_cantidadund: '',
        p_DETFAC_IRBPNR:  '',//GSRF
        p_DETFAC_VALORIRBPNR: '',//GSRF
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  getFacturas(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/41dd7a99df6d5c', {
      elementos: {
        ENCFAC_COMENTARIO: 'VICTOR'
      }
    }, this.confIniciales.httpOptions());
  }
  
  getDetalleFactura(encfacnumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/10775aeebd4cf0000', {
      elementos: {
        ENCFAC_NUMERO: encfacnumero
      }
    }, this.confIniciales.httpOptions());
  }
  
  borrarTodoDetalle(encfacnum): Observable<any> {
    return this.http.post<any>(this.way + '/pdv/factura/3ab2b770dc35de00000', {
      elementos: {
        ENCFAC_NUMERO: encfacnum
      }
    }, this.confIniciales.httpOptions());
  }
  
  eliminarFac(encfac): Observable<any> {
    return this.http.post<any>(this.way + '/pdv/factura/eacad2bbd4cef800', {
      elementos: {
        ENCFAC_NUMERO: encfac
      }
    }, this.confIniciales.httpOptions());
  }
  
  actualizarkardex(factura): Observable<any> {
    return this.http.post(this.way + '/pdv/factura/a677d456dae', {
      elementos: {
        p_ENCFAC_numero_1: factura,
        p_COM_codigo_3: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  getTrnDocumento(trndocumentonrodoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/20eef7776e19ec0000', {
      elementos: {
        TRNDOCUMENTO_NRODOC: trndocumentonrodoc
      }
    }, this.confIniciales.httpOptions());
  }
  
  getTrnCobro(trncobronrodoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/41ddeeeecc2f78', {
      elementos: {
        TRNCOBRO_NRODOC: trncobronrodoc
      }
    }, this.confIniciales.httpOptions());
  }
  
  getTrnRetencion(trncobronrodoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/10777bbbeddd76000', {
      elementos: {
        TRNCOBRO_NRODOC: trncobronrodoc
      }
    }, this.confIniciales.httpOptions());
  }
  
  getTrnPago(trncobronrodoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/20eef777caa18', {
      elementos: {
        TRNCOBRO_NRODOC: trncobronrodoc
      }
    }, this.confIniciales.httpOptions());
  }
  
  
  getTrnResumen(trncobroreferencia): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/41ddeeddcf59d8', {
      elementos: {
        TRNCOBRO_REFERENCIA: trncobroreferencia
      }
    }, this.confIniciales.httpOptions());
  }
  
  getInfoCredito(clicodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/20eecaefc336e60000', {
      elementos: {
        COM_CODIGO: '01',
        CLI_CODIGO: clicodigo
      }
    }, this.confIniciales.httpOptions());
  }
  
  getMaeCliente(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/10776ca732b276000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  getClienteCodigo(cliCod): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/20eeb2b275fae', {
      elementos: {
        COM_CODIGO: '01',
        CLI_CODIGO: cliCod,
      }
    }, this.confIniciales.httpOptions());
  }
  
  
  getMaeVendedor(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/83bb653beebb600', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  getVendedorCodigo(venCod): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/83bbf75dae66180', {
      elementos: {
        VEN_CODIGO: venCod,
      }
    }, this.confIniciales.httpOptions());
  }
  
  getUsuario(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/20eefb9e56e58', {
      elementos: {
        USUIDENTIFICACION: this.usuario.identificacion
      }
    }, this.confIniciales.httpOptions());
  }
  
  getClaveVendedor(clave): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/107759557ddf76000', {
      elementos: {
        VEN_CLAVE: clave
      }
    }, this.confIniciales.httpOptions());
  }
  
  getAutorizacion(encfacnumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/107755eee372', {
      elementos: {
        ENCFAC_NUMERO: encfacnumero
      }
    }, this.confIniciales.httpOptions());
  }
  
  
  recogerProducto(articulo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/6dcccef0df33b8', {
      elementos: {
        ART_CODIGO: articulo,
        ARTPRE_CODIGO: 'A'
      },
    }, this.confIniciales.httpOptions());
  }
  
  recogerArticulosFilter(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/db98adf5f2af600', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  getBancosDP(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/41dd5aaecc71b8', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  getBancosCH(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/41dd5aaecc7190', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  getBancosTA(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/41dd5aaecc73a8', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  getFormaPago(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/41dd7e376caa18', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  getCodsri(tipo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/10775986f372', {
      elementos: {
        CODSRI_TIPO: tipo
      }
    }, this.confIniciales.httpOptions());
  }
  
  getRetenciones(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/20eeeddd75d92c0000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  
  retornarAsiento(factura): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/1b7771bbab9276000', {
      elementos: {
        ENCFAC_NUMERO: factura
      }
    }, this.confIniciales.httpOptions());
  }
  
  insertarTrnCobro(cxctrncobro): Observable<any> {
    return this.http.post(this.way + '/pdv/factura/12bf1dbef77766000', {
      elementos: {
        p_TRNCOBRO_nroDoc: cxctrncobro.TRNCOBRO_NRODOC,
        p_TRNCOBRO_nroComprobante: cxctrncobro.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_tipoDoc: cxctrncobro.TRNCOBRO_TIPODOC,
        p_TRNCOBRO_fechaTrn: cxctrncobro.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_concepto: cxctrncobro.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_importe: cxctrncobro.TRNCOBRO_IMPORTE,
        p_TRNCOBRO_fechavence: cxctrncobro.TRNCOBRO_FECHAVENCE,
        p_APLORG_codigo: cxctrncobro.APLORG_CODIGO,
        p_TRNCOBRO_referenciaExterna: cxctrncobro.TRNCOBRO_REFERENCIAEXTERNA,
        p_CLI_codigo: cxctrncobro.CLI_CODIGO,
        p_VEN_codigo: cxctrncobro.VEN_CODIGO,
        p_COM_codigo: cxctrncobro.COM_CODIGO,
        p_ASI_NRO: cxctrncobro.ASI_NRO,
        p_USU_IDENTIFICACION: cxctrncobro.USU_IDENTIFICACION,
        p_TRNCOBRO_FLAG: cxctrncobro.TRNCOBRO_FLAG,
        p_BAN_CODIGO: cxctrncobro.BAN_CODIGO,
        p_TRNCOBRO_EFECTIVO: cxctrncobro.TRNCOBRO_EFECTIVO,
        p_TRNCOBRO_CAMBIO: cxctrncobro.TRNCOBRO_CAMBIO,
        p_CON_CODIGO: cxctrncobro.CON_CODIGO,
        p_TRNCOBRO_SALDOINI: cxctrncobro.TRNCOBRO_SALDOINI,
        p_BOD_CODIGO: cxctrncobro.BOD_CODIGO,
      },
    }, this.confIniciales.httpOptions());
  }
  
  insertarTrnDocumento(cxctrncobro): Observable<any> {
    return this.http.post(this.way + '/pdv/factura/12bf1dbef7776e000', {
      elementos: {
        p_TRNCOBRO_nroDoc_1: cxctrncobro.TRNCOBRO_NRODOC,
        p_TRNDOCUMENTO_nroDoc_2: cxctrncobro.TRNCOBRO_NRODOC_2,
        p_TRNDOCUMENTO_importe_3: cxctrncobro.TRNCOBRO_IMPORTE,
        p_COM_codigo_4: cxctrncobro.COM_CODIGO,
        p_CLI_codigo_5: cxctrncobro.CLI_CODIGO,
        p_TRNDOCUMENTO_REF: cxctrncobro.CUOTAFAC,
        p_TRNCOBRO_NROCOMPROBANTE: cxctrncobro.TRNCOBRO_NROCOMPROBANTE
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  insertarTrnRetencion(sacitrnretencion): Observable<any> {
    return this.http.post(this.way + '/pdv/factura/257e3b7deef6ee0000', {
      elementos: {
        p_retencion_codigo_1: sacitrnretencion.RETENCION_CODIGO,
        p_trnretencion_descripcion_4: sacitrnretencion.TRNRETENCION_DESCRIPCION,
        p_trnretencion_nro_1: sacitrnretencion.TRNRETENCION_NRO,
        p_trnretencion_base_5: sacitrnretencion.TRNRETENCION_BASE,
        p_trnretencion_porcentaje_6: sacitrnretencion.TRNRETENCION_PORCENTAJE,
        p_trnretencion_totalretenido_7: sacitrnretencion.TRNRETENCION_TOTALRETENIDO,
        p_trncobro_nrodoc_8: sacitrnretencion.TRNCOBRO_NRODOC,
        p_com_codigo_3: sacitrnretencion.COM_CODIGO,
        p_trnretencion_origen: sacitrnretencion.TRNRETENCION_ORIGEN,
        p_con_codigo: sacitrnretencion.CON_CODIGO,
        p_cen_codigo: sacitrnretencion.CEN_CODIGO,
        p_cli_codigo: sacitrnretencion.CLI_CODIGO,
        p_ENCFAC_NUMERO: sacitrnretencion.TRNRETENCION_REFERENCIA,
        p_TRNCOBRO_NROCOMPROBANTE: sacitrnretencion.TRNCOBRO_NROCOMPROBANTE,
      },
    }, this.confIniciales.httpOptions());
  }
  
  insertarFormaPago(cxctrnpago): Observable<any> {
    return this.http.post(this.way + '/pdv/factura/12bf1dbebf1bb6000', {
      elementos: {
        p_TRNPAGO_tipo_2: cxctrnpago.TRNPAGO_TIPO,
        p_CAJ_codigo_3: cxctrnpago.CAJ_CODIGO,
        p_TRNPAGO_numero_4: cxctrnpago.TRNPAGO_NUMERO,
        p_TRNPAGO_fecha_5: cxctrnpago.TRNPAGO_FECHA,
        p_BANCLI_CODIGO: cxctrnpago.BANCLI_CODIGO,
        p_TRNPAGO_NROCTA: cxctrnpago.TRNPAGO_NROCTA,
        p_TRNPAGO_concepto_6: cxctrnpago.TRNPAGO_CONCEPTO,
        p_TRNPAGO_importe_7: cxctrnpago.TRNPAGO_IMPORTE,
        p_TRNPAGO_numero_anterior: cxctrnpago.TRNPAGO_NUMEROANT,
        p_CON_codigo_9: cxctrnpago.CON_CODIGO,
        p_TRNCOBRO_nroDoc_1: cxctrnpago.TRNCOBRO_NRODOC,
        p_COM_codigo_8: cxctrnpago.COM_CODIGO,
        p_TRNCOBRO_nroComprobante: cxctrnpago.TRNCOBRO_NROCOMPROBANTE,
        p_CLI_CODIGO: cxctrnpago.CLI_CODIGO,
        p_TRNPAGO_CODSRI: cxctrnpago.TRNPAGO_CODSRI,
        p_TRNCHEPOS_NUMDOC: cxctrnpago.TRNCHEPOS_NUMDOC,
        p_TRNPAGO_TIPDIS: cxctrnpago.TRNPAGO_TIPDIS
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  generarAsientoContable(encfacnumero, trncobronrodoc): Observable<any> {
    return this.http.post(this.way + '/pdv/factura/1075dcae4998bf000', {
      elementos: {
        p_ENCFAC_numero: encfacnumero,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'CI',
        p_trncobro_nrodoc: trncobronrodoc
      },
    }, this.confIniciales.httpOptions());
  }
  
  generarAsientoContableCredito(encfacnumero): Observable<any> {
    return this.http.post(this.way + '/pdv/factura/1072b92662fd5b000', {
      elementos: {
        p_ENCFAC_numero: encfacnumero,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'AF',
        p_trncobro_nrodoc: encfacnumero
      },
    }, this.confIniciales.httpOptions());
  }
  
  actualizarFormaPago(strNumFact, strTipoFP): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/533be7e376caa00', {
      elementos: {
        ENCFAC_FORMAPAGO: strTipoFP,
        ENCFAC_NUMERO: strNumFact,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  
  actualizarEstado(strNumFact, estado): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/14cef9dcea9b8', {
      elementos: {
        ENCFAC_ESTADO: estado,
        ENCFAC_NUMERO: strNumFact,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  
  limpiarAsiento(strNumFact, estado): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/acad992b9275f80', {
      elementos: {
        ASI_NRO: estado,
        ENCFAC_NUMERO: strNumFact,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  
  eliminarTrnCobro(trncobronrodoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/75656eeeecc2f80', {
      elementos: {
        TRNCOBRO_NRODOC: trncobronrodoc
      }
    }, this.confIniciales.httpOptions());
  }
  
  getPrecioArticulo(prod, opcion): Observable<any[]> {
    if (opcion === 0) {
      return this.http.post<any[]>(this.way + '/pdv/factura/41ddcedcc56fa0', {
        elementos: {
          ART_CODIGO: prod.ART_CODIGO,
          COM_CODIGO: '01',
          ARTPRE_CODIGO: 'A',
          UNI_CODIGO: prod.ART_UNIDADVENTA
        }
      }, this.confIniciales.httpOptions());
    }
    if (opcion === 1) {
      return this.http.post<any[]>(this.way + '/pdv/factura/41ddcedcc52324', {
        elementos: {
          ART_CODIGO: prod.ART_CODIGO,
          COM_CODIGO: '01',
          ARTPRE_CODIGO: 'A',
          UNI_CODIGO: prod.ART_UNIDADVENTA
        }
      }, this.confIniciales.httpOptions());
    }
  }
  
  
  getCedula(ced): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/getcedula', {
      cedula: ced
    }, this.confIniciales.httpOptions());
  }
  
  anularAsiento(asinumero): Observable<any> {
    return this.http.post(this.way + '/pdv/factura/2afeaab9275fb8', {
      elementos: {
        p_ASI_nro: asinumero,
        p_COM_codigo: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  anularFactura(strNumFact, razon): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/2afeabd4cefb6a', {
      elementos: {
        ENCFAC_ESTADO: 'A',
        ENCFAC_OBSERVACION: razon,
        ENCFAC_NUMERO: strNumFact,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  
  
  obtenerPeriodoContable(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/c2fb976d98bf580', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  
  insertarCliente(ruc, gru, ven, nombre, tipo, direccion, telefono, correo, fecha, cuenta, cuenta2): Observable<any[]> {
    console.log(fecha);
    fecha = '25/10/2019';
    console.log(fecha);
    let clavetipo = '';
    switch (tipo) {
      case 'C':
        clavetipo = '2';
        break;
      case 'R':
        clavetipo = '1';
        break;
      case 'P':
        clavetipo = '3';
        break;
      case 'PP':
        clavetipo = '4';
        break;
      default:
        clavetipo = '6';
        break;
    }
    return this.http.post<any[]>(this.way + '/pdv/factura/12bf1dbeb2b276000', {
      elementos: {
        CLI_CODIGO: ruc,
        GRU_CODIGO: gru,
        VEN_CODIGO: ven,
        CLI_NOMBRE: nombre,
        CLI_NOMBREC: nombre.substr(0, 10),
        CLI_TIPOIDE: clavetipo,
        CLI_RUCIDE: ruc,
        CLI_DIRECCION1: direccion,
        CLI_DIRECCION2: '',
        CLI_CODPOSTAL: '',
        CLI_TELEFONO1: telefono,
        CLI_TELEFONO2: '',
        CLI_FAX: '',
        CLI_CORREO: correo,
        CLI_CONTACTO: '',
        CLI_FECING: fecha,
        CLI_LIMCREDIT: '0',
        CLI_DIACREDIT: '0',
        CLI_DESCUENTO: '0',
        CLI_IVA: '1',
        CLI_CONTRIBUYENTE: 'N',
        CLI_LISTA: 'A',
        CON_CODIGO1: cuenta,
        CON_CODIGO2: cuenta2,
        CLI_ZONA: '010',
        CLI_TIPO: '',
        CLI_PROVINCIA: '',
        CIU_CODIGO: '',
        CLI_ESTADO: 'A',
        CLI_PORCEDESCUENTO: '0',
        CLI_FECHANACIMIENTO: fecha,
        CLI_OBSERVACION: '',
        COM_CODIGO: '01',
        gru_tipo: 'CLI'
      }
    }, this.confIniciales.httpOptions());
  }
  
  actualizarCliente(ruc, nombre, direccion, telefono, correo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/299df32b275fae', {
      elementos: {
        CLI_NOMBRE: nombre,
        CLI_NOMBREC: nombre.substr(0, 10),
        CLI_DIRECCION1: direccion,
        CLI_TELEFONO1: telefono,
        CLI_CORREO: correo,
        CLI_CODIGO: ruc,
      }
    }, this.confIniciales.httpOptions());
  }
  
  datosultimocli(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/d5771cf57acac800', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  
  ejecutarVenSpFactura(numerofactura): Observable<any> {
    return this.http.post(this.way + '/pdv/factura/74ddf75f997a980', {
      elementos: {
        p_ENCFAC_NUMERO: numerofactura,
        p_COM_codigo: '01',
        p_parametro_ide: '123'
      },
    }, this.confIniciales.httpOptions());
  }
  
  obtenerDatosFactura(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/factura/30beb55d7a99e00000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
}

