import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {ContrndistribpccService} from '../conservicios/contrndistribpcc.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {Contrndistribpcc} from '../coninterfaces/contrndistribpcc';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {Usuario} from '../../usuario';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';

@Component({
  selector: 'app-contrndistribucionpcc',
  templateUrl: './contrndistribucionpcc.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class ContrndistribucionpccComponent implements OnInit {
  
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  @Input() strAsiNro: string;
  @Input() intDetasiNroFila: number;
  @Input() strCodigo: string;
  @Input() strDescripcion: string;
  @Input() dblValor: string;
  @Input() strTipoDet: string;
  @Output() signalSalir = new EventEmitter<boolean>();
  
  
  
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  
  largo: string;
  public frameworkComponents;
  rowStyle;
  defaultDistribCC;
  rowSelection = 'multiple';
  
  detalleDistribSel: Contrndistribpcc;
  bolNOPRGDCC: boolean;
  
  TrnprocenCodigo: '';
  
  opcion: string;
  displayDialogBus: boolean;
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  where: string;
  
  
  columnDefsDistribCC = [
    {
      headerName: 'Cuenta', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 150, cellStyle: {textAlign: 'left'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 35,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'C.Costos', field: 'CEN_CODIGO', cellEditor: 'cellPrueba', width: 150, cellStyle: {textAlign: 'left'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 35,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Nombre_CC', field: 'CEN_NOMBRE', cellEditor: 'cellPrueba', width: 170, cellStyle: {textAlign: 'left'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 45
          }
        }
    },
    {
      headerName: '%', field: 'TRNDISP_PORCE', cellEditor: 'cellPrueba', width: 150, cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        }
    },
    {
      headerName: 'Valor', field: 'TRNDISP_VALOR', cellEditor: 'cellPrueba', width: 150, cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        }
    },
    {
      headerName: 'Tipo Gasto', field: 'TIPGAS_CODIGO', cellEditor: 'cellPrueba', editable: false, width: 150
    }
  
  ];
  
  constructor(private confIniciales: ConfInicialesService, public condistribccService: ContrndistribpccService,
              private  auditoriaGralService: AuditoriagralService, private message: MessageService,
              private auditoriagral: AuditoriagralService, public usuario: Usuario, private errorService: ErroresBaseDatosService,
              private confirmationService: ConfirmationService, public busquedaSer: InvbusquedaService) {
    this.agTable();
    this.condistribccService.detDistribcc = [];
    this.detalleDistribSel = {};
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    
    this.defaultDistribCC = {
      editable: true,
      width: 100,
      objeto: 'contrnDistribcc',
      resizable: false
    };
    
  }

  ngOnInit() {
    this.condistribccService.dblValor = this.dblValor;
    this.auditoriaGralService.obtenerConfiguracion1().subscribe((res) => {
      this.condistribccService.hmpConfiguracion = res;
    });
    
    this.condistribccService.refrescarURL();
  
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
  
    this.largo = '580';
  
    this.displayDialogBus = false;
    this.arregloBus = [];
    this.opcion = '';
    
    this.mostrarDatos(this.strAsiNro, this.intDetasiNroFila);
  }
  
  manejarSenales(valor) {
  
    if (valor === 'Nuevo') {
      this.nuevoRegistro();
    }
    if (valor === 'Borrar') {
      this.eliminarRegistro();
    }
    if (valor === 'Guardar') {
      this.guardarRegistro();
    }
    if (valor === 'Cancelar') {
      this.cancelarRegistro();
    }
    
    if (valor === 'Salir') {
      this.signalSalir.emit(true);
    }
  }
  
  nuevoRegistro() {
    this.nuevoElemento();
  }
  
  eliminarRegistro() {
    if (Object.keys(this.detalleDistribSel).length > 0) {
      this.confirmarEliminar();
    } else {
      this.mensajeAlerta('error', 'Error', '! Seleccione una fila.... !');
    }
  }
  guardarRegistro() {
  
    if (this.sumarTotalImporte() === false) {
      this.mensajeAlerta('info', 'Información', 'El total de distribución no es igual al importe');
      return;
    }
    
    this.eliminarLineaEnBlanco();
    this.guardarDistribucion();
   
  }
  
  cancelarRegistro() {
  
  }
  
  mostrarDatos(strAsiNro, intDetasiNroFila) {
    // let item: Contrndistribpcc;
    this.condistribccService.listarDatos(strAsiNro, intDetasiNroFila).subscribe((datos) => {
      if (datos !== null) {
        this.condistribccService.detDistribcc = datos;
        this.condistribccService.detDistribcc.map((item) => {
          item.CEN_CODIGO = item.CEN_CODIGO === null ? '' : item.CEN_CODIGO;
          item.CEN_NOMBRE = item.CEN_NOMBRE === null ? '' : item.CEN_NOMBRE;
          item.COM_CODIGO = item.COM_CODIGO === null ? '' : item.COM_CODIGO;
          item.DETASI_NROFILA = item.DETASI_NROFILA === null ? 0 : item.DETASI_NROFILA;
          item.ASI_NRO = item.ASI_NRO === null ? '' : item.ASI_NRO;
          item.TRNDISP_VALOR = item.TRNDISP_VALOR === null ? Number(0).toFixed(this.confIniciales.getNumDecSist()) :
            Number(item.TRNDISP_VALOR).toFixed(this.confIniciales.getNumDecSist());
          item.TRNDISP_PORCE = item.TRNDISP_PORCE === null ? Number(0).toFixed(this.confIniciales.getNumDecSist()) :
            Number(item.TRNDISP_PORCE).toFixed(this.confIniciales.getNumDecSist());
          item.CON_CODIGO = item.CON_CODIGO === null ? '' : item.CON_CODIGO;
          item.TRNDISP_LINEA = item.TRNDISP_LINEA === null ? 0 : item.TRNDISP_LINEA;
          item.PROG_CODIGO = item.PROG_CODIGO === null ? '' : item.PROG_CODIGO;
          item.PROG_NOMBRE = item.PROG_NOMBRE === null ? '' : item.PROG_NOMBRE;
          item.TIPGAS_CODIGO = item.TIPGAS_CODIGO === null ? '' : item.TIPGAS_CODIGO;
          item.CONTROL = '1';
  
          let strDato = '';
  
          this.condistribccService.erContipgas(item.CON_CODIGO).subscribe((reg) => {
            
            strDato = reg[0].CON_TIPGAS;
            if (strDato === 'Si') {
              item.BOLTIPOGASTO = true;
            }
          });
        });
        this.calcularTotal();
      } else {
        this.condistribccService.detDistribcc = [];
      }
    });
    
  }
  
  cambio(event) {
    
    if (Object.keys(this.detalleDistribSel).length > 0 && this.detalleDistribSel.TRNDISP_LINEA !== 0) {
      this.confirmarEliminar();
    }
    
   /* this.btnGuardar = false;
    this.btnRegresar = false;
    this.btnNuevo = true; */
    
  }
  
  seleccionDetDistribCC(valor) {
    if (valor.object !== undefined) {
      this.detalleDistribSel = valor.object;
    }
  }
  
  calcularTotal() {
    
    let intIndiceLista = 0;
    let item: Contrndistribpcc;
    let dblTotal = 0;
   
    
    for (intIndiceLista = 0; intIndiceLista < this.condistribccService.detDistribcc.length; intIndiceLista++) {
      item = this.condistribccService.detDistribcc[intIndiceLista];
     
      dblTotal = dblTotal + Number(item.TRNDISP_VALOR);
    }
    
    this.condistribccService.totalDist = Number(dblTotal).toFixed(this.confIniciales.getNumDecSist());
 
    
  }
  
  listarDatosAutomatica() {
   
    if (this.btnGuardar === true) {
      this.condistribccService.listarDatosAutomatica(this.strAsiNro, this.intDetasiNroFila, this.TrnprocenCodigo).subscribe((datos) => {
       
        for (const a of datos) {
          const item: Contrndistribpcc = {};
          item.PROG_CODIGO = a.PROG_CODIGO === null ? '' : a.PROG_CODIGO;
          item.PROG_NOMBRE = a.PROG_NOMBRE === null ? '' : a.PROG_NOMBRE;
          item.CEN_NOMBRE = a.CEN_NOMBRE === null ? '' : a.CEN_NOMBRE;
          item.CEN_CODIGO = a.CEN_CODIGO === null ? '' : a.CEN_CODIGO;
          item.CON_CODIGO = '01';
          item.CONTROL = '';
          item.DETASI_NROFILA = this.intDetasiNroFila;
          item.ASI_NRO = this.strAsiNro;
          item.TRNDISP_VALOR = a.VALOR === null ? Number(0).toFixed(this.confIniciales.getNumDecSist()) :
            Number(a.VALOR).toFixed(this.confIniciales.getNumDecSist());
          item.TRNDISP_PORCE = a.PROG_PORCENTAJE === null ? Number(0).toFixed(this.confIniciales.getNumDecSist()) :
            Number(a.PROG_PORCENTAJE).toFixed(this.confIniciales.getNumDecSist());
          item.CON_CODIGO = this.strCodigo;
          item.TRNDISP_LINEA = 0;
          item.TIPGAS_CODIGO = '';
          
          this.condistribccService.detDistribcc.push(item);
        }
        this.calcularTotal();
        this.aggrid.refreshColumnDefs();
        this.aggrid.refreshaggrid(this.condistribccService.detDistribcc, this.defaultDistribCC.objeto);
        
      });
      this.activaBotones('DA', true, false, true, true, true);
    } else {
      this.mensajeAlerta('info', 'Información', 'Error, Guarde las modificaciones');
    }
  }
  
  nuevoElemento() {
    const linea = this.condistribccService.detDistribcc.length;
    
    if (this.condistribccService.getConfiguraciones('NOPRGDCC') === 1) {
      this.bolNOPRGDCC = true;
    } else {
      this.bolNOPRGDCC = false;
    }
  
    let strDato = '';
    let strTipoGasto = '';
  
    const nuevodetDistibCC = {
      PROG_CODIGO: '',
      PROG_NOMBRE: '',
      CEN_CODIGO: '',
      CEN_NOMBRE: '',
      COM_CODIGO: '01',
      DETASI_NROFILA: this.intDetasiNroFila,
      ASI_NRO: this.strAsiNro,
      CON_CODIGO: this.strCodigo,
      CONTROL: '',
      TRNDISP_LINEA: 0,
      TRNDISP_PORCE: Number(0).toFixed(this.confIniciales.getNumDecSist()),
      TRNDISP_VALOR: Number(0).toFixed(this.confIniciales.getNumDecSist()),
      TIPGAS_CODIGO: '',
      BOLTIPOGASTO: false,
      LINEA: linea + 1
    };
  
    this.condistribccService.erContipgas(this.strCodigo).subscribe((reg) => {
        if (reg !== null) {
          strDato = reg[0].CON_TIPGAS;
        } else {
          strDato = '';
        }
        this.condistribccService.erTipgascodigo().subscribe((regTipGas) => {
          strTipoGasto = regTipGas[0].TIPGAS_CODIGO;
          if (strDato === 'Si') {
            nuevodetDistibCC.TIPGAS_CODIGO = strTipoGasto;
            nuevodetDistibCC.BOLTIPOGASTO = true;
          } else {
            nuevodetDistibCC.TIPGAS_CODIGO = '';
            nuevodetDistibCC.BOLTIPOGASTO = false;
          }
          if (this.bolNOPRGDCC === true) {
            nuevodetDistibCC.PROG_CODIGO = 'RPE001';
            nuevodetDistibCC.PROG_NOMBRE = 'XYZ';
          }
          const indi = this.condistribccService.detDistribcc.indexOf(this.detalleDistribSel);
          this.condistribccService.detDistribcc.push(nuevodetDistibCC);
          this.aggrid.refreshaggridindex(this.condistribccService.detDistribcc, this.defaultDistribCC.objeto, indi);
          this.aggrid.gridApi.setFocusedCell(indi + 1, 'CEN_CODIGO');//CDPJ
      });
    });
    this.activaBotones('N', false, false, false, true, true);
    
  }
  
  activaBotones(strTipo, bolNuevo, bolGuardar, bolCancelar, bolEliminar, bolBuscar) {
    this.btnNuevo = bolNuevo;
    this.btnGuardar = bolGuardar;
    this.btnBorrar = bolEliminar;
    this.btnRegresar = bolCancelar;
    if (strTipo === 'DA') {
      this.btnGuardar = false;
      this.btnRegresar = false;
      this.btnBorrar = false;
      this.btnNuevo = false;
    }
  }
  
  sumarTotalImporte(): boolean {
    
    let intIndiceLista = 0;
    let item: Contrndistribpcc;
    let dblTotalImporte = 0;
    
    for (intIndiceLista = 0; intIndiceLista < this.condistribccService.detDistribcc.length; intIndiceLista++) {
      item = this.condistribccService.detDistribcc[intIndiceLista];
      dblTotalImporte = dblTotalImporte + Number(item.TRNDISP_VALOR);
      dblTotalImporte=Number(dblTotalImporte.toFixed(this.confIniciales.getNumDecSist()))
    }
    console.log('dblTotalImporte',dblTotalImporte);
    console.log('this.dblValor',Number(Number(this.dblValor).toFixed(this.confIniciales.getNumDecSist())))
    if (dblTotalImporte !== Number(Number(this.dblValor).toFixed(this.confIniciales.getNumDecSist())) && dblTotalImporte !== 0 ) {
      return false;
    }
    
    return true;
    
  }
  
  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'conDistriCC',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
  
  eliminarLineaEnBlanco() {
    
    let intIndiceLista = 0;
  
    for (intIndiceLista = 0; intIndiceLista < this.condistribccService.detDistribcc.length; intIndiceLista++) {
      const item = this.condistribccService.detDistribcc[intIndiceLista];
      
      if (item.PROG_CODIGO === '' || item.PROG_NOMBRE === '') {
        this.condistribccService.detDistribcc.splice(this.condistribccService.detDistribcc.findIndex(
          distrib => distrib.TRNDISP_LINEA === item.TRNDISP_LINEA && distrib.LINEA === item.LINEA),
          1);
      }
    
    }
    this.aggrid.refreshColumnDefs();
    this.aggrid.refreshaggrid(this.condistribccService.detDistribcc, this.defaultDistribCC.objeto);
    
  }
  
  async guardarDistribucion() {
    let intIndiceLista = 0;
    let item: Contrndistribpcc;
  
    for (intIndiceLista = 0; intIndiceLista < this.condistribccService.detDistribcc.length; intIndiceLista++) {
      item = this.condistribccService.detDistribcc[intIndiceLista];
      if (item.CONTROL === '') {
        if (this.verificarDatos() === true  && item.CEN_CODIGO !== ''  ) {
          try {
            await  this.condistribccService.guardarDistribucion(item).toPromise()
              item.CONTROL = '1';
              this.auditoriagral.registrarAuditoria('CON_TRNDISTRIBUCIONPCC', item.ASI_NRO + '/' + item.DETASI_NROFILA + '/' + item.CEN_CODIGO, 'I',
                this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
              });
              
               this.activaBotones('G', false, true, true, false, false);
            // this.condistribccService.guardarDistribucion(item).subscribe(() => {
            //   item.CONTROL = '1';
            //   this.auditoriagral.registrarAuditoria('CON_TRNDISTRIBUCIONPCC', item.ASI_NRO + '/' + item.DETASI_NROFILA + '/' + item.CEN_CODIGO, 'I',
            //     this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            //   });
              
            //   this.activaBotones('G', false, true, true, false, false);
            // }, error1 => {
            //   let mensaje: string;
            //   mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
            //   this.mensajeAlerta('error', 'Error', error1.error);
            // });
          } catch (error1) {
            let mensaje: string;
              mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
              this.mensajeAlerta('error', 'Error', error1.error);
          }
          

        }
      }
    }
  }
  
  verificarDatos(): boolean {
  
    let intIndiceLista = 0;
    let item: Contrndistribpcc;
    
    for (intIndiceLista = 0; intIndiceLista < this.condistribccService.detDistribcc.length; intIndiceLista++) {
      item = this.condistribccService.detDistribcc[intIndiceLista];
      
      if (item.PROG_CODIGO === '') {
        this.mensajeAlerta('error', 'Error', 'Código del programa es incorrecto');
        return false;
      }
      
    }
    return true;
  }
  
  confirmarEliminar() {
    this.confirmationService.confirm({
      message: '¿ Está seguro de eliminar el registro ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'conDistriCCconf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.condistribccService.eliminarLineaSel(this.detalleDistribSel).subscribe(() => {
          this.auditoriagral.registrarAuditoria('CON_TRNDISTRIBUCIONPCC', this.detalleDistribSel.ASI_NRO + '/' +
            this.detalleDistribSel.DETASI_NROFILA + '/' + this.detalleDistribSel.PROG_CODIGO + '/' + this.detalleDistribSel.CEN_CODIGO, 'E',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });
  
          this.condistribccService.detDistribcc.splice(this.condistribccService.detDistribcc.findIndex(
            item => item.TRNDISP_LINEA === this.detalleDistribSel.TRNDISP_LINEA && item.LINEA === this.detalleDistribSel.LINEA),
            1);
  
          this.aggrid.refreshaggrid(this.condistribccService.detDistribcc, this.defaultDistribCC.objeto);
  
          this.activaBotones('E', false, true, true, false, false);
          this.condistribccService.calcularTotal();
        }, error1 => {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
          this.mensajeAlerta('error', 'Error', error1.error);
        });
      },
      reject: () => {
      }
    });
  }
  
  buscarDatos() {
    this.opcion = 'BuscarTrnProCen';
    this.types = [
      {label: 'TRNPROCEN_CODIGO', value: 'TRNPROCEN_CODIGO'},
      {label: 'TRNPROCEN_DESCRIPCION', value: 'TRNPROCEN_DESCRIPCION'},
    ];
    this.busquedacampos = ['', ''];
    this.consulta = 'distinct TRNPROCEN_CODIGO,TRNPROCEN_DESCRIPCION';
    this.tabla = 'VEN_TRNPROCEN';
    this.where = '';
    this.busquedaSer.busquedaVenTrnProcen().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.displayDialogBusquedaFast = true;
    });
  
  }
  
  manejarSeleccion(valor) {
    if (this.opcion === 'BuscarTrnProCen') {
      this.TrnprocenCodigo = valor.TRNPROCEN_CODIGO;
      // this.seleccionBanco(valor);
    }
    
    
    
    this.displayDialogBus = false;
    this.displayDialogBusquedaFast = false;
  }
  
  
  
  
}
