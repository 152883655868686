<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="ordenCom"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="ordenComConf" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 99%;">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
			[botonBorrar]="btnBorrar" (signal)="manejarSenales($event)" [botonAnterior]="botonAnterior"
			[botonPrimero]="botonPrimero" [botonUltimo]="botonUltimo" [botonSiguiente]="botonSiguiente"
			[botonAnular]="botonAnular"
			[barraBotones3]="true" [barraOrden]="false" [botonBuscar]="false" [botonAlmacenardoc] = "true"></app-invmaebarra>
	</div>
</div>
<div class="p-grid" style="margin-top: -10px; margin-bottom: -5px; width: 99%;">
	<div class="p-col-2">
		<input type="text" pInputText style="background-color: deepskyblue" [disabled]="true"
			[(ngModel)]="encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_NUMERO">
	</div>
	<div class="p-col-2"></div>
	<div class="p-col-2">
		<input type="text" pInputText style="background-color: deepskyblue" [disabled]="true"
			[(ngModel)]="encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_ESTADO">
	</div>
	<div class="p-col-6"></div>
</div>
<!-- CDPJ -->
<div class="layout-config">
	<div class="layout-config-content-wrapper">
		<a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones($event)">
			<i class="pi pi-bars" style="cursor: pointer"></i>
		</a>
		<p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
			[dismissible]="false" [showCloseIcon]="false">
			<br>
			<br>
			<br>
			<p-tabView>
				<p-tabPanel header="Acciones">
					<div class="p-grid">
						<!-- GSRF -->
						<div class="p-col-12">
							<i class="pi pi-folder archivoOpc"></i><span (click)="cambioestado()"
								class="archivoOpc">Cambio de estado de la Orden de compra</span>
						</div>
						<div class="p-col-12">
							<i class="pi pi-folder archivoOpc"></i><span (click)="modporceiva()"
								class="archivoOpc">Modificar el porcentaje del IVA.</span>
						</div>
						<div class="p-col-12">
							<i class="pi pi-caret-right archivoOpc"></i><span (click)="actbasesiva()"
								class="archivoOpc">Actualizar bases IVA</span>
						</div>
						<div class="p-col-12">
							<i class="pi pi-folder archivoOpc"></i><span (click)="verbasesiva()"
								class="archivoOpc">Visualizar bases IVA</span>
						</div>
						<!-- GSRF -->
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-sidebar>
	</div>
</div>
<!-- CDPJ -->
<p-tabView [(activeIndex)]="index" (onChange)="changeTab()">
	<p-tabPanel header="Compra">
		<div class="divgrups" id="divPrincipal">
			<div class="p-grid p-align-stretch">
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Comprobante:</span>
						</div>
						<div class="p-col-2">
							<input type="text" id="comprobante" pInputText
								[(ngModel)]="encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_REFERENCIA"
								[disabled]="true" maxlength="15">
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-1">
							<span>Referencia:</span>
						</div>
						<div class="p-col-1">
							<input type="text" id="referencia" pInputText style="width: 100%"
								(keydown.enter)="cambiarFoco($event)"
								[(ngModel)]="encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_REFERENCIA_ADI"
								maxlength="15" (input)="cambio($event)">
						</div>
						<div class="p-col-2">
							<span>Fecha Emisión:</span>
						</div>
						<div class="p-col-1">
							<p-calendar dateFormat="dd/mm/yy" [inputStyle]="{'width': '100%'}"
								[(ngModel)]="encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_FECHAEMISION"
								(input)="cambio($event)"></p-calendar>
						</div>
						<div class="p-col-1">
							<span>% I.V.A.</span>
							
						</div>
						<div class="p-col-1"> 
									<p-dropdown [options]="cmbtipoIva" [style]="{'minWidth': '100%', 'width': '100%'}"
                                        optionLabel="name" 
                                        (onChange)="cambiavalorIva($event)"
										[(ngModel)]="selectcombtipoIva"
										[disabled]="boolIva"
							            ></p-dropdown> 
						 </div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Proveedor:</span>
						</div>
						<div class="p-col-2">
							<input type="text" id="proveedor" pInputText
								[(ngModel)]="encordService.encOrdSeleccionadaarray[indicador].PRO_CODIGO"
								(keydown.enter)="cambiarFoco($event)" (input)="cambio($event)" maxlength="20">
						</div>
						<div class="p-col-4">
							<div class="p-grid">
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png); float:left"
										(click)="abrirProveedores()"></button>
								</div>
								<div class="p-col-11">
									<span style="color: blue; float: left">{{nombreProveedor}}</span>
								</div>
							</div>
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-10">
									<span>Fecha Entrega:</span>
								</div>
								<div class="p-col-2">
									<p-checkbox binary="true" [(ngModel)]="fechaBoolean"
										(ngModelChange)="cambio($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-1">
							<p-calendar [disabled]="!fechaBoolean" dateFormat="dd/mm/yy"
								[inputStyle]="{'width': '100%'}"
								[(ngModel)]="encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_FECHAENTREGA"
								(input)="cambio($event)"></p-calendar>
						</div>
						<div class="p-col-1">
							<span>Tipo</span>
						</div>
						<div class="p-col-1">
							<p-dropdown [options]="opcionTipoCompra"
								[(ngModel)]="encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_TIPOOC"
								[style]="{'minWidth': '100%', 'width': '100%'}" (ngModelChange)="cambio($event)">
							</p-dropdown>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -19px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Contacto:</span>
						</div>
						<div class="p-col-5">
							<input id="contacto" type="text" pInputText
								[(ngModel)]="encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_CONTACTO"
								(input)="cambio($event)" maxlength="120">
						</div>
						<div class="p-col-1"></div>
						<div class="p-col-2">
							<span>Num N.R:</span>
						</div>
						<div class="p-col-1">
							<div class="p-grid">
								<div class="p-col-9">
									<input id="numRef" type="text" [style]="{'width': '100%'}"
										[(ngModel)]="encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_NUMNOTREC"
										(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)" maxlength="20">
								</div>
								<div class="p-col-3">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="abrirNotRec()"></button>
								</div>
							</div>

						</div>
						<div class="p-col-1">
							<span>Estado:</span>
		
						</div>
						<div class="p-col-1">
							<p-dropdown [options]="opcionEstadoCompra"
								[(ngModel)]="encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_ESTADOOC"
								[style]="{'minWidth': '100%', 'width': '100%'}" (ngModelChange)="cambio($event)">
							</p-dropdown>
						</div>
						
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -23px; margin-bottom: -20px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Comentario:</span>
						</div>
						<div class="p-col-5">
							<input id="comentario" type="text" pInputText (input)="cambio($event)"
								[(ngModel)]="encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_OBSERVACION"
								(keydown.enter)="cambiarFoco($event)" maxlength="200">
						</div>
						<div class="p-col-4">
							<div class="p-grid">
								<div class="p-col-1">
									<span>F.P.</span>
								</div>
								<div class="p-col-11">
									<input type="text" id="FP" style="width: 100%;" [(ngModel)]="fpValor"
										[disabled]="true">
								</div>
							</div>
						</div>
						<div class="p-col-1">
							<span>Bodega</span>
	
						</div>
						<div class="p-col-1">
							<div class="p-grid">
								<div class="p-col-9">
									<input type="text" id="bodega" pInputText style="width: 100%"
										[(ngModel)]="bodCodigo" (keydown.enter)="cambiarFoco($event)"
										(input)="cambio($event)">
								</div>
								<div class="p-col-3">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="abrirBodega()"></button>
								</div>
							</div>
						</div>
						<!-- <div class="p-col-1">
							<div class="p-grid">
								<div class="p-col-9">
									<input type="text" id="bodega" pInputText style="width: 100%"
										[(ngModel)]="bodCodigo" (keydown.enter)="cambiarFoco($event)"
										(input)="cambio($event)">
								</div>
								<div class="p-col-3">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="abrirBodega()"></button>
								</div>
							</div>
						</div> -->
					</div>
				</div>
			</div>
		</div>
		<div>
			<app-aggridsaci *ngIf="detalleOrdenSeleccionada === undefined" [width]="" [height]="largo"
				[enableSorting]="false" [enableFilter]="true" [rowData]="encordService.detordenesarray[indicador]"
				[frameworkComponents]="frameworkComponents" [animateRows]="true" [rowSelection]="rowSelection"
				[columnDefs]="columnDefsOrdenDetalle" [defaultColDef]="defaultOrdenDetalle" [rowStyle]="rowStyle"
				[singleClickEdit]="false" [botones]="true" [mostrarGuardar]="false" [botonesAdicionales]="true"
				[comboOpciones]="comboOpciones" [tipodetalle]="tipodetalle" (selected)="seleccionDetalleOrd($event)"
				(cambios)="cambio($event)" [botonNuevo]="btnNuevoGrid" [botonGuardar]="btnGuardarGrid"
				[botonBorrar]="btnBorrarGrid" [botonRegresar]="btnCancelarGrid" [botonImagen]="btnImagenGrid"
				[botonVerificarExis]="btnVerificarExis"></app-aggridsaci>
			<app-aggridsaci
				*ngIf="detalleOrdenSeleccionada !== undefined && detalleOrdenSeleccionada.DETORDCOM_TIPODET !== ''"
				[width]="" [height]="largo" [enableSorting]="false" [enableFilter]="true"
				[rowData]="encordService.detordenesarray[indicador]" [frameworkComponents]="frameworkComponents"
				[animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefsOrdenDetalle"
				[defaultColDef]="defaultOrdenDetalle" [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true"
				[mostrarGuardar]="false" [botonesAdicionales]="true" [comboOpciones]="comboOpciones"
				[tipodetalle]="tipodetalle" (selected)="seleccionDetalleOrd($event)" (cambios)="cambio($event)"
				(rdbtipodet)="cambiarTipo($event)" [botonNuevo]="btnNuevoGrid" [botonGuardar]="btnGuardarGrid"
				[botonBorrar]="btnBorrarGrid" [botonRegresar]="btnCancelarGrid" [botonImagen]="btnImagenGrid"
				[botonVerificarExis]="btnVerificarExis" [imagenProducto]="imagenProducto"></app-aggridsaci>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Documentación">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Archivo:</span>
						</div>
						<div class="p-col-8">
							<input type="text" style="width: 100%"
								[(ngModel)]="encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_ARCHIVO"
								[disabled]="true" (input)="cambio($event)" maxlength="2000">
						</div>
						<div class="p-col-2">
							<input type="file" [(ngModel)]="archivoSubido" (ngModelChange)="subirArchivo()">
						</div>
						<div class="p-col-1"></div>
					</div>
				</div>
				<div class="p-col-12">
					<textarea rows="12" cols="100" (input)="cambio($event)" pInputTextarea
						style="width: 100%; height: 350px"
						[(ngModel)]="encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_DOCUMENTACION"
						maxlength="3000"></textarea>
				</div>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Estadísticas" [disabled]="disabledEstadisticas">
		<div class="p-grid">
			<!--LINEA 1-->
			<div class="p-col-6">
				<div class="p-grid">
					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-4">
								<button pButton (click)="mostrarEstadisticas()"
									style="background-image: url(../../../assets/images/iconos/cogwheel.png); width: 40px; height: 40px; background-color: transparent;"
									title="Generar estadísticas">
								</button>
							</div>
							<div class="p-col-4">
								<span style="font-size: 16px; font-weight: bold; text-align: center">COMPRAS POR
									MESES</span>
							</div>
							<div class="p-col-4">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 20px;">
						<p-chart [options]="options" type="bar" [data]="dataComprasProveedor" height="415px"></p-chart>
					</div>
				</div>
			</div>
			<div class="p-col-6">
				<div class="p-grid">
					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-3">
							</div>
							<div class="p-col-6">
								<span style="font-size: 16px; font-weight: bold;">
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									PRODUCTOS MÁS COMPRADOS</span>
							</div>
							<div class="p-col-3">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 10px;">
						<div class="p-grid">
							<div class="p-col-3">
							</div>
							<div class="p-col-2">
								<span>Ordenar por:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="cantidad" (onClick)="orderByTopProd('cantidad')"
									inputId="orden1"></p-radioButton>
								<label> Cantidad </label>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="precio" (onClick)="orderByTopProd('total')"
									inputId="orden2"></p-radioButton>
								<label> Total </label>
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px;">
						<app-aggridsaci [width]="" [height]="'390'" [enableSorting]="true" [enableFilter]="true"
							[rowData]="detTopProductos" [frameworkComponents]="frameworkComponents" [animateRows]="true"
							[rowSelection]="rowSelection" [columnDefs]="columnDefsTopProductos"
							[defaultColDef]="defaultColTopProductos" [rowStyle]="rowStyleTopProd" [botones]="false"
							[singleClickEdit]="false">
						</app-aggridsaci>
					</div>
				</div>
			</div>
			<div class="p-col-12" style="margin-top: 20px;">
				<div class="p-grid">
					<div class="p-col-5">
					</div>
					<div class="p-col-2">
						<span style="font-size: 16px; font-weight: bold;">
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							FORMULARIO</span>
					</div>
					<div class="p-col-5">
					</div>

					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-1">
								<span>Artículo:</span>
							</div>

							<div class="p-col-2" style="text-align: left">
								<div class="p-grid">
									<div class="p-col-8">
										<input id="ART_CODIGO" type="text" pInputText autocomplete="off"
											[(ngModel)]="txtArtCodigo">
									</div>
									<div class="p-col-4">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="buscarArticulo()"></button>
									</div>
								</div>
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-1">
								<span>Unidad:</span>
							</div>
							<div class="p-col-1">
								<input id="ART_UNIDAD" type="text" pInputText disabled [(ngModel)]="txtArtUnidad"
									autocomplete="off">
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-7">
										<span>Existencia Máxima:</span>
									</div>
									<div class="p-col-5">
										<input id="ART_EXISTMAX" type="text" pInputText disabled autocomplete="off"
											[(ngModel)]="txtExistenciaMax">
									</div>
								</div>

							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -5px;">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-1">
								<span>Descripción:</span>
							</div>

							<div class="p-col-2" style="text-align: left">
								<input id="ART_DESCRIPCION" type="text" pInputText disabled autocomplete="off"
									[(ngModel)]="txtArtDescripcion">
							</div>

							<div class="p-col-2">
								<!--								<span>Rotación:</span>-->
							</div>
							<div class="p-col-1">
								<!--								<input id="ART_ROTACION" type="text" pInputText disabled [(ngModel)]="txtArtRotacion"-->
								<!--									   autocomplete="off">-->
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-7">
										<span>Existencia Mínima:</span>
									</div>
									<div class="p-col-5">
										<input id="ART_EXISTMIN" type="text" pInputText disabled
											[(ngModel)]="txtExistenciaMin" autocomplete="off">
									</div>
								</div>

							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 20px;">
						<p-table [value]="detalleExistencia">
							<ng-template pTemplate="header">
								<tr>
									<th>Existencia</th>
									<th>Ordenes de Compra</th>
									<th>Pedidos Clientes</th>
									<th>Saldo</th>
									<th>Existencia Mínima</th>
									<th>Comprar</th>
									<th>Últ. Costo Compra</th>
									<th>Total Compra</th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-existencia>
								<tr>
									<td style="text-align: right">{{existencia.EXISTENCIA}}</td>
									<td style="text-align: right">{{existencia.ORDENES_COMPRA}}</td>
									<td style="text-align: right">{{existencia.PEDIDOS_CLIENTES}}</td>
									<td style="text-align: right">{{existencia.SALDO}}</td>
									<td style="text-align: right">{{existencia.EXIST_MIN}}</td>
									<td style="text-align: right">{{existencia.COMPRAR}}</td>
									<td style="text-align: right">{{existencia.ULTCOSTO_COM}}</td>
									<td style="text-align: right">{{existencia.TOTAL_COMPRA}}</td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</div>
				<p-tabView>
					<p-tabPanel header="Movimientos">
						<div class="p-col-12" style="margin-top: 10px;">
							<div class="p-grid">
								<div class="p-col-3">
								</div>
								<div class="p-col-2">
									<span>Ordenar por:</span>
								</div>
								<div class="p-col-2" style="text-align: left">
									<p-radioButton name="orden" value="fecha" (onClick)="llenarMovByProvArt('fecha')"
										inputId="orden1"></p-radioButton>
									<label> Fecha </label>
								</div>
								<div class="p-col-2" style="text-align: left">
									<p-radioButton name="orden" value="costo" (onClick)="llenarMovByProvArt('costo')"
										inputId="orden2"></p-radioButton>
									<label> Costo </label>
								</div>
							</div>
						</div>
						<app-aggridsaci [width]="" [height]="'450'" [enableSorting]="true" [enableFilter]="true"
							[rowData]="detMovimientosProv" [frameworkComponents]="frameworkComponents"
							[animateRows]="true" [rowSelection]="rowSelection"
							[columnDefs]="columnDefsMovimientosByProv" [defaultColDef]="defaultColMovByProv"
							[rowStyle]="rowStyle" [botones]="false" [singleClickEdit]="false">
						</app-aggridsaci>


					</p-tabPanel>
					<p-tabPanel header="Existencia por Bodega">
						<app-aggridsaci [width]="" [height]="'450'" [enableSorting]="true" [enableFilter]="true"
							[rowData]="detExistenciaBodegas" [frameworkComponents]="frameworkComponents"
							[animateRows]="true" [rowSelection]="rowSelection"
							[columnDefs]="columnDefsExistenciaBodegas" [defaultColDef]="defaultColExistBod"
							[rowStyle]="rowStyle" [botones]="false" [singleClickEdit]="false">
						</app-aggridsaci>
					</p-tabPanel>
					<p-tabPanel header="Ventas vs Compras">
						<div class="p-grid">
							<!--LINEA 1-->
							<div class="p-col-6">
								<div class="p-grid">
									<div class="p-col-12">
										<p-chart type="bar" [data]="dataVentasVsCompras" height="415px"></p-chart>
									</div>
								</div>
							</div>
							<div class="p-col-6">
								<div class="p-grid">
									<div class="p-col-4">
									</div>
									<div class="p-col-4"><span style="font-size: 16px; font-weight: bold;">
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											Índice de Provisión</span>
									</div>
									<div class="p-col-4">
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Inventario inicial:</span>
											</div>
											<div class="p-col-2">
												<input id="INVENTARIO_INICIAL" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtInventarioIni" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>(+) Compras del periodo:</span>
											</div>
											<div class="p-col-2">
												<input id="COMPRAS_PERIODO" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtComprasPeriodo" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Total disponible:</span>
											</div>
											<div class="p-col-2">
												<input id="TOTAL_DISPONIBLE" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtTotalDisponible"
													style="text-align: right; background-color: yellow">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Ventas del último periodo:</span>
											</div>
											<div class="p-col-2">
												<input id="VENTA_ULTIMOPER" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtVenUltimoPer" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Ventas promedio 10 periodos anteriores:</span>
											</div>
											<div class="p-col-2">
												<input id="VENTASPROM_PERANT" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtVenPromPerAnt" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Índice de provisión último periodo:</span>
											</div>
											<div class="p-col-2">
												<input id="INDPROV_ULTPER" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtIndiceProvUltPer"
													style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Índice de provisión 10 últimos periodos:</span>
											</div>
											<div class="p-col-2">
												<input id="INDPROV" type="text" pInputText autocomplete="off" readonly
													[(ngModel)]="txtIndiceProvPeriodos" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Compras planificadas:</span>
											</div>
											<div class="p-col-2">
												<input id="COMPRAS_PLANIFICADAS" type="number" pInputText
													autocomplete="off" [(ngModel)]="txtComprasPlanificadas"
													style="text-align: right">
											</div>
											<div class="p-col-2">
												<button pButton (click)="calcularIndice()"
													style="background-image: url(../../../assets/images/iconos/cogwheel.png);background-color: transparent;"
													title="Calcular Índice">
												</button>
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Total disponible:</span>
											</div>
											<div class="p-col-2">
												<input id="totalDisponible" type="text" pInputText readonly
													autocomplete="off" [(ngModel)]="txtTotalDisponibleCom"
													style="text-align: right; background-color: yellow">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Índice de provisión último periodo:</span>
											</div>
											<div class="p-col-2">
												<input id="INDPROV_ULT" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtIndiceProvUltPer2"
													style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Índice de provisión 10 últimos periodos:</span>
											</div>
											<div class="p-col-2">
												<input id="INDPRO" type="text" pInputText autocomplete="off" readonly
													[(ngModel)]="txtIndiceProvPeriodos2" style="text-align: right">
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</p-tabPanel>
				</p-tabView>
			</div>
		</div>
	</p-tabPanel>
</p-tabView>
<div class="divgrupstabla">
	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-4">
					<div class="p-grid">
						<div class="p-col-4">
							<span class="obligatorio">Neto</span>
						</div>
						<div class="p-col-4">
							<span class="obligatorio">Dscto (Valor)</span>
						</div>
						<div class="p-col-4">
							<span class="obligatorio">Dscto (%)</span>
						</div>

					</div>
				</div>
				<div class="p-col-4">
					<div class="p-grid">
						<div class="p-col-4">
							<span class="obligatorio">Base I.V.A.</span>
						</div>
						<div class="p-col-4">
							<span class="obligatorio">Valor I.V.A.</span>
						</div>
						<div class="p-col-4">
							<span class="obligatorio">Base I.C.E.</span>
						</div>
					</div>
				</div>
				<div class="p-col-4">
					<div class="p-grid">
						<div class="p-col-4">
							<span class="obligatorio">Valor I.C.E.</span>
						</div>
						<div class="p-col-4">
							<span class="obligatorio">Base Cero</span>
						</div>
						<div class="p-col-4">
							<span class="obligatorio">Total</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="p-col-12" style="margin-top: -20px">
			<div class="p-grid">
				<div class="p-col-4">
					<div class="p-grid">
						<div class="p-col-4">
							<input type="text" class="totales" pInputText [disabled]="true"
								[(ngModel)]="encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_TOTALNETO">
						</div>
						<div class="p-col-4">
							<input type="text" class="totales" pInputText [disabled]="true"
								[(ngModel)]="encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_VALORDES">
						</div>
						<div class="p-col-4">
							<input type="text" class="totales" pInputText [disabled]="true"
								[(ngModel)]="encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_PORCEDES">
						</div>
					</div>
				</div>
				<div class="p-col-4">
					<div class="p-grid">
						<div class="p-col-4">
							<input type="text" class="totales" pInputText [disabled]="true"
								[(ngModel)]="encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_BASEIVA">
						</div>
						<div class="p-col-4">
							<input type="text" class="totales" pInputText [disabled]="true"
								[(ngModel)]="encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_VALORIVA">
						</div>
						<div class="p-col-4">
							<input type="text" class="totales" pInputText [disabled]="true"
								[(ngModel)]="encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_BASEICE">
						</div>
					</div>
				</div>
				<div class="p-col-4">
					<div class="p-grid">
						<div class="p-col-4">
							<input type="text" class="totales" pInputText [disabled]="true"
								[(ngModel)]="encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_VALORICE">
						</div>
						<div class="p-col-4">
							<input type="text" class="totales" pInputText [disabled]="true"
								[(ngModel)]="encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_BASECERO">
						</div>
						<div class="p-col-4">
							<input type="text" class="totales" pInputText [disabled]="true"
								[(ngModel)]="encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_TOTAL">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
		[busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)" [consulta]="consulta">
	</app-busquedadlg>
</p-dialog>
<p-dialog header="Descuento Global" [(visible)]="displayDialogDescuento" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '35vw', height: '35vh'}">
	<div class="p-grid">
		<div class="p-col-12">
			<button class="littlebuttons" pButton (click)="aplicarDescuento()"
				style="background-image: url(../../../assets/images/iconos/visto.png);">
			</button>
		</div>
		<div class="p-col-12">
			<p-tabView>
				<p-tabPanel header="Tipo Descuento">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-3">
							<p-radioButton name="opcionDescuento" label="Por Valor" value="dscValor"
								[(ngModel)]="dscSeleccionado" (ngModelChange)="cambioDescuento()"></p-radioButton>
						</div>
						<div class="p-col-3"></div>
						<div class="p-col-3">
							<p-radioButton name="opcionDescuento" label="En Porcentaje" value="dscPorcentaje"
								[(ngModel)]="dscSeleccionado" (ngModelChange)="cambioDescuento()"></p-radioButton>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-1"></div>
						<div class="p-col-4">
							<input type="text" pInputText pKeyFilter="num" (input)="comprobarValor()"
								(keydown.enter)="aplicarDescuento()" [(ngModel)]="valorDscValor"
								[disabled]="booleanValor" appTwoDigitDecimaNumber>
							<p-message *ngIf="valorInvalidoV === true" severity="error"
								text="Valor de descuento no válido"></p-message>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-4">
							<input type="text" pInputText pKeyFilter="num" (input)="comprobarValor()"
								(keydown.enter)="aplicarDescuento()" [(ngModel)]="valorDscPorcentaje"
								[disabled]="booleanPorcentaje" appTwoDigitDecimaNumber>
							<p-message *ngIf="valorInvalidoP === true" severity="error" text="Porcentaje no válido ">
							</p-message>
						</div>
						<div class="p-col-1"></div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</div>
	</div>
</p-dialog>
<p-dialog header="Clave Autorización" [(visible)]="claveDialog" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="ordenComclave"></p-toast>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-4">
					<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-4">
					<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
						(keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
				</div>
				<div class="p-col-1"></div>
			</div>
		</div>
	</div>
</p-dialog>
<p-dialog header="Reportes" [(visible)]="displayDialogReportes" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: '400px'}">
	<app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
		[strNumDoc]="encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_NUMERO" [strCampo]="'ENCORDCOM_NUMERO'"
		(cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>
<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'COMENCORDCOM'" [txtNumComprobante] = "encordService.encOrdSeleccionadaarray[indicador].ENCORDCOM_NUMERO"
	(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>
<!-- GSRF -->
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	<p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
	</p-dialog>
</div>

<!-- GSRF -->
<!-- CDPJ -->
<!-- GSRF -->
<p-dialog header="Ordenes de compra" [(visible)]="displaycambioestado" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '65vw', height: '65vh'} " [focusOnShow]="true" appendTo="body" >
	<div class="p-col-12">
		<h3 style="text-align: center;">Notas de Recepción</h3>
	</div>
	<!-- <br> -->
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-2">
						<h4 >Codigo</h4>
					</div>
					<div class="p-col-3">
						<h4>Número</h4>
					</div>
					<div class="p-col-3">
						<h4 >Fecha</h4>
					</div>
					<div class="p-col-2">
						<h4 >Código Proveedor</h4>
					</div>
					<div class="p-col-2">
						<h4 >Total</h4>
					</div>
				</div>
			</div>
		</div>
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-2">
						<span>{{this.codigogre}}</span>
					</div>
					<div class="p-col-3">
						<span>{{this.numerogre}}</span>
					</div>
					<div class="p-col-3">
						<span>{{this.fechagre }}</span>
					</div>
					<div class="p-col-2">
						<span>{{this.cligre}}</span>
					</div>
					<div class="p-col-2">
						<span>{{this.totgre}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="p-col-12">
		<h3 style="text-align: center;">Facturas de Proveedores</h3>
	</div>
	<!-- <br> -->
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-2">
						<h4 >Número</h4>
					</div>
					<div class="p-col-3">
						<h4 >Fecha</h4>
					</div>
					<div class="p-col-3">
						<h4 >Código Proveedor</h4>
					</div>
					<div class="p-col-2">
						<h4 >Nombre Proveedor</h4>
					</div>
					<div class="p-col-2">
						<h4 >Total</h4>
					</div>
				</div>
			</div>
		</div>
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-2">
						<span>{{this.codigofac}} </span>
					</div>
					<div class="p-col-3">
						<span>{{this.fechafac}} </span>
					</div>
					<div class="p-col-3">
						<span>{{this.clifac }} </span>
					</div>
					<div class="p-col-2">
						<span>{{this.clinomfac}} </span>
					</div>
					<div class="p-col-2">
						<span>{{this.totfac}} </span>
					</div>
				</div>
			</div>
		</div>
		
	</div>
	<div class="p-col-12">
		<p style="text-align: center;">
			<button class=button_largos type="button"
				style="background-image: url(../../../assets/images/iconspdv/boton-largo-cambio-estado.png); width:5em; height: 3em "
				(click)="cambiarestadopen()"></button>
		</p>
	</div>
	
</p-dialog>
<!-- GSRF -->
<p-dialog header="Bases IVA" [(visible)]="displaybasesiva" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '55vw', height: '55vh'} " [focusOnShow]="true" appendTo="body" >

	<app-aggridsaci [width]="-800" [height]="-800" [enableSorting]="true" [enableFilter]="true"
			[rowData]="basesiva" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsBasesIva"
			[defaultColDef]="defaultColBasesIva" [rowStyle]="rowStyle" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>



</p-dialog>
<!-- CDPJ -->