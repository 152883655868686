import { Injectable } from '@angular/core';
import { UsuarioEsquema } from 'src/app/usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class NomenviorolesService {
  public parametroIde:string
  private way: string;

  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, 
              private confIniciales: ConfInicialesService,private datePipe: DatePipe,) {
                
                this.way = sessionStorage.getItem('way');
               }


  getHtml(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/nom/nomenviaroles/41dd8f6d5662d800000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }


  getRoles(  strFechaIni, strFechaFin ){
    return this.http.post<any[]>(this.way + '/nom/nomenviaroles/198bf3d5eab7bc0000', {
      elementos: { 
        p_fecini: strFechaIni, 
        p_fecfin: strFechaFin,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  

 
  generarRol(periodoCodigo, tipRolCodigo,empcodigo) {
    this.parametroIde = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
    return this.http.post<any>(this.way + '/nom/nomenviaroles/e9b99eeab79de0000000000000', {
      elementos: {
        P_PERIODO_CODIGO :periodoCodigo,
        p_TIPROL_CODIGO :tipRolCodigo,
        p_dep_codigo_ini :'',
        p_dep_codigo_fin :'',
        p_emp_codigo_ini :empcodigo,
        p_emp_codigo_fin :empcodigo,
        p_COM_CODIGO :'01',
        p_USUIDENTIFICACION:this.usuario.identificacion,
        p_PARAMETRO_IDE: this.parametroIde 
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  generarcorreo(encviocorreo, rol) {
    return this.http.post<any>(this.way + '/nom/nomenviaroles/83aeeda998dee00', {
      elementos: {
        p_encenviocor_codigo:encviocorreo.ENCENVIOCOR_CODIGO,
        p_enviocorvar_codigo:encviocorreo.ENVIOCORVAR_CODIGO ,
        p_periodocod:rol.PERIODO_CODIGO,
        p_empcedula:rol.CEDULA,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getcamposcorreos(enviocorcodigo, enviocorvarorigen) {
    return this.http.post<any>(this.way + '/nom/nomenviaroles/10775f8dd998df000', {
      elementos: {
        p_enviocorcodigo :enviocorcodigo,
        p_enviocorvarorigen :enviocorvarorigen,
 
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  consultreporte(strValor){
    return this.http.post<any[]>(this.way + '/nom/nomenviaroles/83bbbaab6c8cc00', {
        elementos: {
            rlcodigo: strValor
        }
      }, this.confIniciales.httpOptions()
      
    ).toPromise();
  }
  generarReporte(){
    const ubirep = sessionStorage.getItem('ubicacionReportes');
    let params = {};
    //let parametroIde = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
    const headers = new HttpHeaders().set('Session-Id', sessionStorage.getItem('session'));
    headers.set('withCredentials', 'true');
    params = {
      COMCODIGO: '01',
      SUBREPORT_DIR: ubirep,
      NOMUSUARIO: this.usuario.identificacion,
      parametroIDE: this.parametroIde,
      querryString: '',
    }
    return this.http.post(this.way + '/nom/nomenviaroles/1075ddb56f6ece00000000', {
      //nombreReporte: nombrereporte,
      parametros: params,
      excel: false
    }, { headers, responseType: 'blob' });
  }

  enviarCorreo(asunto,remitente,cuerpo01,cuerpo02,piecorreo,correo,nomarchivo,nomreporte,chklogo) {
    //console.log(archivo);
    const ubirep = sessionStorage.getItem('ubicacionReportes');
    let params = {};
    params = {
      COMCODIGO: '01',
      SUBREPORT_DIR: ubirep,
      NOMUSUARIO: this.usuario.identificacion,
      parametroIDE: this.parametroIde,
      querryString: '',
    }
    return this.http.post<any>(this.way + '/nom/nomenviaroles/75952aabbd5324000000', {
      elementos: {
        p_asunto:asunto,
        p_remitente:remitente,
        p_cuerpo01:cuerpo01,
        p_cuerpo02:cuerpo02,
        p_piecorreo:piecorreo,
        p_correo :correo,
        p_nomarchivo :nomarchivo,
        p_reporte:nomreporte,
        p_chklog:chklogo
      },
      parametros: params,
      excel: false
    }, this.confIniciales.httpOptions()).toPromise();
  }
  // erpBusquedaPeriodo(strValor, strValue, strParams) {
  //   return this.http.post(this.way + '/nom/nomenviaroles/', {
  //     elementos: {
  //       Valor: strValor,
  //       Value: strValue,
  //       Params: strParams
  //     },
  //   }, this.confIniciales.httpOptions()).toPromise();
  // }



  // buscarAsiento(periodoCodigo, tipRolCodigo){
    
  //   return this.http.post<any[]>(this.way + '/nom/contabilizar/wdf4365beuctl4z', {
  //       elementos: {
  //         PeriodoCodigo: periodoCodigo,
  //         TipRolCodigo: tipRolCodigo,
  //       }
  //     }, this.confIniciales.httpOptions()).toPromise();
  // }

  // getTipoRol(tipoRol){
    
  //   return this.http.post<any[]>(this.way + '/nom/contabilizar/64najo90r6eizng', {
  //     elementos: {
  //       TipRolCodigo: tipoRol,
  //     }
  //     }, this.confIniciales.httpOptions()).toPromise();
  // }

}
