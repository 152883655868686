<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="distriCC"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="distriCCconf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra (signal)="manejarSenales($event)" [botonNuevo]="botonNuevo" [botonGuardar]="botonGuardar"
						 [botonBorrar]="botonBorrar" [botonRegresar]="botonRegresar"
						 [barraBotonesDesplaz]="oculto" [barraBotones3]="oculto"
						 [barraBotonesAsiCon]="oculto" [barraBotones5]="oculto"
						 [check]="false"></app-invmaebarra>
	</div>
</div>
<div class="divgrups">
	<div class="p-grid">
		<div class="p-col-2">
			<label for="ARTICULO"></label>
			<input class="frm" id="ARTICULO" type="text" pInputText [(ngModel)]="strCodigo" disabled
				   autocomplete="off">
		</div>
		<div class="p-col-3">
			<label for="NOMBRE"></label>
			<input class="frm" id="NOMBRE" type="text" pInputText [(ngModel)]="strDescripcion" disabled
				   autocomplete="off">
		</div>
		<div class="p-col-1">
			<span>Importe:</span>
		</div>
		<div class="p-col-2">
			<label for="IMPORTE"></label>
			<input class="frm" id="IMPORTE" type="text" pInputText
				   [(ngModel)]="strImporte" disabled
				   autocomplete="off">
		</div>
		<div class="p-col-4">
			<button class="sincss" pButton (click)="listarDatosAutomatica()">Distribución Automatica
			</button>
		</div>
		<div class="p-col-12">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="false"
							[enableFilter]="true"
							[rowData]="distriCCService.distribucionCC"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsDistri"
							[defaultColDef]="defaultColDefDistri"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)="seleccionDistriCC($event)"
							(cambios)="cambio($event)"
							align="left"
							style="justify-items: end"
			></app-aggridsaci>
		</div>
		<div class="p-col-8">
		</div>
		<div class="p-col-1">
			<span>Total:</span>
		</div>
		<div class="p-col-2">
			<label for="TOTAL"></label>
			<input class="frm" id="TOTAL" type="text" pInputText
				   [(ngModel)]="distriCCService.total" disabled
				   autocomplete="off">
		</div>
	</div>
</div>

