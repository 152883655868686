import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import { Venmaeprovincias, Venmaecanton, Venmaeparroquia } from '../veninterfaces/venmaeprovincias';
@Injectable({
  providedIn: 'root'
})
export class VenmaeprovinciasService {
  private url: string;
  public way: string;
  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario, private confIniciales: ConfInicialesService) { 
      this.url = sessionStorage.getItem('url');
      this.way = sessionStorage.getItem('way');
      this.usuario.identificacion;
    }
  consultaProvincia(){
    
  return this.http.post<any[]>(this.way + '/ventas/provincias/198bf3d5eab79e000000000', {      
    elementos: {},
  }, this.confIniciales.httpOptions()).toPromise();
  }
  insertarProvicia(valor:Venmaeprovincias){
  
  return this.http.post<any[]>(this.way + '/ventas/provincias/12bf1dbeab79de00000000', {      
    elementos: {
      PROV_CODIGO: valor.PROV_CODIGO,
      PROV_NOMBRE: valor.PROV_NOMBRE
    },
  }, this.confIniciales.httpOptions()).toPromise();
  }
 
  actualizarProvincia(valor:Venmaeprovincias){
  return this.http.post<any[]>(this.way + '/ventas/provincias/299df2b3bc7e58', {      
    elementos: {
      PROV_NOMBRE: valor.PROV_NOMBRE,
      PROV_CODIGO: valor.PROV_CODIGO
    },
  }, this.confIniciales.httpOptions()).toPromise();
  }
  eliminarProvincia(valor:Venmaeprovincias){
  return this.http.post<any[]>(this.way + '/ventas/provincias/3ab2b4b3bc7e58', {      
    elementos: {
      PROV_CODIGO: valor.PROV_CODIGO
    },
  }, this.confIniciales.httpOptions()).toPromise();
  }

  consultaCanton(valor:string){
    return this.http.post<any[]>(this.way + '/ventas/provincias/198bf3d562afdc0000', {      
      elementos: {
        p_com_codigo: '01',   
        p_can_pv_codigo:valor,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  insertarCanton(valor:Venmaecanton, pv:string){
   //no out
    return this.http.post<any[]>(this.way + '/ventas/provincias/4afc76d8abf718', {      
      elementos: {
        p_com_codigo:'01' ,
        p_can_codigo: valor.CAN_CODIGO,
        p_can_descripcion:valor.CAN_DESCRIPCION,
        p_pv_Codigo:pv 
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarCanton(valor:Venmaecanton, pv:string){
    //noout
    /*
      p_com_codigo  ven_maecanton.com_codigo%type,
      p_can_codigo    ven_maecanton.can_codigo%type,
      p_can_descripcion ven_maecanton.can_descripcion%type,
      p_pv_Codigo ven_maecanton.pv_codigo%type
    */
   console.log(valor,pv);
    return this.http.post<any[]>(this.way + '/ventas/provincias/299df298abf718', {      
      elementos: {
        p_com_codigo:'01',
        p_can_codigo:valor.CAN_CODIGO,
        p_can_descripcion:valor.CAN_DESCRIPCION,
        p_pv_Codigo:pv
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eliminarCanton(valor:Venmaecanton,pv:string){
       //noout
       return this.http.post<any[]>(this.way + '/ventas/provincias/3ab2b498abf718', {      
        elementos: {
          p_can_codigo:valor.CAN_CODIGO,
          p_pv_Codigo:pv
        },
      }, this.confIniciales.httpOptions()).toPromise();
  }
  consultaParroquia(valor:string){  
    //pros
    return this.http.post<any[]>(this.way + '/ventas/provincias/3317e7ab956f7800000', {      
      elementos: {
        p_com_codigo: '01',  
        p_CAN_Codigo:valor, 
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  insertarParroquia(valor:Venmaeparroquia, can:string){
      //noout
      return this.http.post<any[]>(this.way + '/ventas/provincias/257e3b7956f78e0000', {      
        elementos: {
          p_com_codigo:'01',
          p_PARR_codigo:valor.PARR_CODIGO,
          p_PARR_descripcion:valor.PARR_DESCRIPCION,
          p_CAN_Codigo:can
        },
      }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarParroquia(valor:Venmaeparroquia, can:string){
      //noout
      return this.http.post<any[]>(this.way + '/ventas/provincias/533be5565956f8000000', {      
        elementos: {
          p_com_codigo:'01',
          p_PARR_codigo:valor.PARR_CODIGO,
          p_PARR_descripcion:valor.PARR_DESCRIPCION,
          p_CAN_Codigo:can
        },
      }, this.confIniciales.httpOptions()).toPromise();
  }
  eliminarParroquia(valor:Venmaeparroquia,can:string){
  
    //noout
    return this.http.post<any[]>(this.way + '/ventas/provincias/1d595a5956f78d0000', {      
      elementos: {
        p_PARR_codigo:valor.PARR_CODIGO,
        p_CAN_Codigo:can
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
}
