<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="actmaepoliza"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="actmaepolizaConf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="actmaepolizaService.btnNuevo[indicador]" 
		[botonGuardar]="actmaepolizaService.btnGuardar[indicador]"
		 [botonBorrar]="actmaepolizaService.btnBorrar[indicador]"
						 [barraBotonesDesplaz]="true"  [barraBotonesCRUD]="false"
						 [barraBotones3]="true" [barraBotonesAsiCon]="true"
						 [botonBinocular]="true" 
						 [botonBuscar]="actmaepolizaService.btnBuscar[indicador]"
                         [barraBotones5]="true"
						 (signal)="manejarSenales($event)"
						></app-invmaebarra>


    </div>
    
    <p-tabView>
        <p-tabPanel header="Polizas">
            <div style="overflow: hidden" class="p-col-9">
                <app-aggridsaci [width]=""
                                [height]="largo"
                                [enableSorting]="false"
                                [enableFilter]="true"
                                [rowData]="actmaepolizaService.detmaePolizaArray[indicador]"
                                [frameworkComponents]="frameworkComponents"
                                [animateRows]="true"
                                [rowSelection]="rowSelection"
                                [columnDefs]="columnDefActMaePoliza"
                                [defaultColDef]="defaultActMaePoliza"
                                [rowStyle]="rowStyle"
                                [singleClickEdit]="false"
                                [botones]="false"
                                (selected)="selectActMaePoliza($event)"
                                (cambios)="cambio($event)"
    
                ></app-aggridsaci>
            </div>
        </p-tabPanel>
    </p-tabView>