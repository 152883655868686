<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="calc"></p-toast>
<div style="width: 100%; background: rgb(232,242,255)">
	<table style="width: 100%;">
		<tr>
			<td colspan="6">
				<div class="input-group">
					<div class="search-container" *ngIf="!productobol">
						<div class="p-grid">
							<div class="p-col-10">
								<input pInputText [disabled]="facPagada" id="search-bar" type="text"
									   (keydown.enter)="onKeyPress()" [value]="string || ''"
									   [(ngModel)]="stringTeclado"/>
							</div>
							<div class="p-col-2">
								<button id="search" pButton *ngIf="string || stringTeclado" type="button" (click)="vaciarArreglo()"
										style="background-image: url(../../../../assets/images/iconspdv/borrar-todo-lo-escrito.png); outline: none;">
								</button>
							</div>
						</div>
					</div>
					<div *ngIf="productobol">
						<div style="margin-left: 15px;">
							<p-autoComplete [size]="25" [(ngModel)]="nomArt"
											[suggestions]="filteredArticuloSingle"
											(completeMethod)="filterArticuloSingle($event)"
											placeholder="Ingrese el nombre del artículo" [minLength]="1"
											(onSelect)="onSelectArticulo(nomArt)" (click)="teclado('producto')"
											[showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" [dropdown]="true"
											(onDropdownClick)="filterArticuloSingle($event)" maxlength="20" ></p-autoComplete>
						</div>
					</div>
				</div>
			</td>
			<td>
				<button [disabled]="facPagada" class="button_calc" type="button" (click)="buscarArticulo()"
						style="background-image: url(../../../../assets/images/iconspdv/enter.png);">
				</button>
			</td>
		</tr>
		<tr>
			<td style="width: 29%; height: 85px" rowspan="2">
				<button class="button_largos" [ngStyle]="{'height':'100%', 'width':'100%'}" type="button" (click)="guardar()"
						style="background-image: url(../../../../assets/images/iconspdv/Guardar-simbolo.png);"
						title="Guardar los productos del Actual">
				</button>
			</td>
			<td style="width: 5%"></td>
			<td style="width: 18%">
				<button [disabled]="facPagada" class="button_calc" type="button" (click)="enviarNumero(1)"
						style="background-image: url(../../../../assets/images/iconspdv/1.png);">
				</button>
			</td>
			<td style="width: 5%"></td>
			<td style="width: 18%">
				<button [disabled]="facPagada" class="button_calc" type="button" (click)="enviarNumero(2)"
						style="background-image: url(../../../../assets/images/iconspdv/2.png);">
				</button>
			</td>
			<td style="width: 5%"></td>
			<td style="width: 18%">
				<button [disabled]="facPagada" class="button_calc" type="button" (click)="enviarNumero(3)"
						style="background-image: url(../../../../assets/images/iconspdv/3.png);">
				</button>
			</td>
		</tr>
		<tr>
			<td></td>
			<td>
				<button [disabled]="facPagada" class="button_calc" type="button" (click)="enviarNumero(4)"
						style="background-image: url(../../../../assets/images/iconspdv/4.png);">
				</button>
			</td>
			<td></td>
			<td>
				<button [disabled]="facPagada" class="button_calc" type="button" (click)="enviarNumero(5)"
						style="background-image: url(../../../../assets/images/iconspdv/5.png);">
				</button>
			</td>
			<td></td>
			<td>
				<button [disabled]="facPagada" class="button_calc" type="button" (click)="enviarNumero(6)"
						style="background-image: url(../../../../assets/images/iconspdv/6.png);">
				</button>
			</td>
		</tr>
		<tr>
			<td>
				<button *ngIf="!facPagada" [disabled]="facPagada" class="button_largos" (click)="aplicarDescuentoGlobal()"
						type="button"
						style="background-image: url(../../../../assets/images/iconspdv/descuento-simbolo.png);"
						title="Aplicar descuento al pedido">
				</button>
				<button *ngIf="facPagada" [disabled]="facPagada" class="button_largos" (click)="aplicarDescuentoGlobal()"
						type="button"
						style="background-image: url(../../../../assets/images/iconspdv/descuento-simbolo-gris.png);"
						title="Aplicar descuento al pedido">
				</button>
			</td>
			<td></td>
			<td>
				<button [disabled]="facPagada" class="button_calc" type="button" (click)="enviarNumero(7)"
						style="background-image: url(../../../../assets/images/iconspdv/7.png);">
				</button>
			</td>
			<td></td>
			<td>
				<button [disabled]="facPagada" class="button_calc" type="button" (click)="enviarNumero(8)"
						style="background-image: url(../../../../assets/images/iconspdv/8.png);">
				</button>
			</td>
			<td></td>
			<td>
				<button [disabled]="facPagada" class="button_calc" type="button" (click)="enviarNumero(9)"
						style="background-image: url(../../../../assets/images/iconspdv/9.png);">
				</button>
			</td>
		</tr>
		<tr>
			<td>
				<button *ngIf="!facPagada" [disabled]="facPagada" class="button_largos" type="button"
						(click)="confirmarEliminar()"
						style="background-image: url(../../../../assets/images/iconspdv/eliminar-factura-simbolo.png);"
						title="Eliminar Pedido Actual">
				</button>
				<button *ngIf="facPagada" [disabled]="facPagada" class="button_largos" type="button"
						(click)="confirmarEliminar()"
						style="background-image: url(../../../../assets/images/iconspdv/eliminar-gris.png);"
						title="Eliminar Pedido Actual">
				</button>
			</td>
			<td></td>
			<td>
				<button [disabled]="facPagada" type="button" class="button_calc" (click)="borrarArreglo()"
						style="background-image: url(../../../../assets/images/iconspdv/borar-individual-2.png); outline: none;"></button>
			</td>
			<td></td>
			<td>
				<button [disabled]="facPagada" class="button_calc" type="button" (click)="enviarNumero(0)"
						style="background-image: url(../../../../assets/images/iconspdv/0.png);">
				</button>
			</td>
			<td></td>
			<td>
				<button [disabled]="facPagada" class="button_calc" type="button" (click)="enviarNumero(404)"
						style="background-image: url(../../../../assets/images/iconspdv/punto.png);">
				</button>
			</td>
		</tr>
	</table>
</div>

<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarCalc" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<p-dialog header="Descuento Global" [(visible)]="dscGlobal" [responsive]="true" [modal]="false"
		  [style]="{width: '500px'}" [transitionOptions]="'0ms'">
	<div class="p-grid">
		<div class="p-col-12">
			<span style="font: 16px 'Roboto'; font-weight: bold">Elija el método de Descuento para el pedido</span>
		</div>
		<div class="p-col-1"></div>
		<div class="p-col-4">
			<p-radioButton name="opciones" value="dscGValor" label="Valor" [(ngModel)]="dscSeleccionado"
						   inputId="dscValor"></p-radioButton>
		</div>
		<div class="p-col-2"></div>
		<div class="p-col-4">
			<p-radioButton name="opciones" value="dscGPorcentaje" label="Porcentaje" [(ngModel)]="dscSeleccionado"
						   inputId="dscPorcentaje"></p-radioButton>
		</div>
		<div class="p-col-1"></div>
		<div class="p-col-1"></div>
		<div class="p-col-4">
			<input pInputText id="dscValor" [disabled]="dscGValor" type="text" [(ngModel)]="valorDscGV" pKeyFilter="num"
				   (input)="comprobarValor()" (click)="teclado('dscValor')" appTwoDigitDecimaNumber>
			<p-message *ngIf="valorInvalidoV === true" severity="error" text="Valor de descuento no válido"></p-message>
		</div>
		<div class="p-col-2"></div>
		<div class="p-col-4">
			<input pInputText id="dscPorcentaje" [disabled]="dscGPorcentaje" type="text" [(ngModel)]="valorDscGP"
				   pKeyFilter="num" (input)="comprobarValor()" (click)="teclado('dscPorcentaje')" appTwoDigitDecimaNumber>
			<p-message *ngIf="valorInvalidoP === true" severity="error" text="Porcentaje no válido "></p-message>
		</div>
		<div class="p-col-1"></div>
	</div>
	<p-footer>
		<div class="text-right">
			<button [disabled]="botonDsc" pButton icon="pi pi-check" label="Aplicar" (click)="enviarDscGlobal()"></button>
		</div>
	</p-footer>
</p-dialog>
