export class Prodcostfech  {
    CINF_DOCUMENTO?;
    CIN_CODIGO_1?;
    COM_CODIGO?;
    CINF_COSTO?;
    CINF_FECHA?;
    CINF_MES?;
    CEN_CODIGO?;
    CINF_ANIO?;
    CIN_NOMBRE?;
    NUEVO?:boolean;
    NUMCOS?:number;
    CINF_DISTRIBUCION?;
}