<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="bandejaPagos"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="bandejaPagosconf" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>

<div class="p-grid" style="width: 98%;">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
						 [botonBorrar]="btnBorrar"
						 (signal)="manejarSenales($event)" [botonAnterior]="botonAnterior" [botonPrimero]="botonPrimero"
						 [botonUltimo]="botonUltimo"
						 [botonSiguiente]="botonSiguiente" [barraBandejaPago]="true" [barraBotones3]="true"
						 [barraConEncasil]="false" [botonAnular]="btnAnular" [botonBuscar]="btnBuscar">
		</app-invmaebarra>
	</div>
</div>
<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px; width: 99%;">
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-5"></div>
			<div class="p-col-2">
				<input type="text" pInputText
					   style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
					   disabled
					   [(ngModel)]="bandejaPagoService.encBandejaPagos.ENCBANDEJA_NOMESTADO">
			</div>
			<div class="p-col-4"></div>
			<div class="p-col-1">
				<input type="text" pInputText
					   style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
					   disabled
					   [(ngModel)]="bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO">
			</div>
		</div>
	</div>
</div>
<p-tabView (onChange)="onTab1Change($event)" [activeIndex]="selectTab">
	<p-tabPanel header="Datos">
		<div class="divgrups" id="divPrincipal">
			<div class="p-grid">
				<!--LINE 1-->
				<div class="p-col-1">
					<span>Fecha Emisión:</span>
				</div>
				<div class="p-col-2" style="text-align: left">
					<p-calendar id="fechaEmision" [inputStyle]="{width: '70%', height: '20px'}"
								[disabled]="boolBtnAceptar"
								dateFormat="dd/mm/yy" [(ngModel)]="bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION">
					</p-calendar>
				</div>
				<div class="p-col-1">
					<span>Comprobante:</span>
				</div>
				<div class="p-col-1">
					<input id="comprobante" type="text" pInputText autocomplete="off"
						   [(ngModel)]="bandejaPagoService.encBandejaPagos.ENCBANDEJA_COMPROBANTE" [readOnly]="editNumComprob"
						   (keydown.enter)="cambiarFoco($event)">
				</div>
				<div class="p-col-1">
				</div>
				<div class="p-col-1">
					<span>Número de Envío:</span>
				</div>
				<div class="p-col-1" style="text-align: left">
					<p-dropdown [style]="{'width': '60%', 'min-width': '60%'}"
								optionLabel="label" inputId="inputNumEnvio" [(ngModel)]="strNumEnvio"
								[options]="cmbNumEnvio" (keydown.enter)="cambiarFoco($event)"></p-dropdown>
				</div>
				<div class="p-col-1" style="text-align: right">
					<span>Benef:</span>
				</div>
				<div class="p-col-2">
					<input id="beneficiario" type="text" pInputText autocomplete="off"
						   [(ngModel)]="bandejaPagoService.encBandejaPagos.ENCBANDEJA_BENEFICIARIO">
				</div>
				<!--LINE 2-->
				<div class="p-col-12" style="margin-top: -5px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Banco:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<input id="BAN_CODIGO" type="text" pInputText autocomplete="off"
									[disabled]="boolBtnAceptar"
								   [(ngModel)]="bandejaPagoService.encBandejaPagos.BAN_CODIGO" (keydown.enter)="cambiarFoco($event)"
								   (input)="cambio($event)">
						</div>
						<div class="p-col-1">
							<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									[disabled]="boolBtnAceptar"
									class="littlebuttons" (click)="buscarBancos(bandejaPagoService.encBandejaPagos.BAN_CODIGO)"></button>
						</div>
						<div class="p-col-2" style="text-align: left">
							<input id="BAN_NOMBRE" type="text" pInputText disabled autocomplete="off"
								   [(ngModel)]="bandejaPagoService.encBandejaPagos.BAN_BANCO">
						</div>
						<div class="p-col-1">
						</div>
						<div class="p-col-2" style="text-align: left">
							<p-checkbox label="Incluir Detalle" binary="true" [(ngModel)]="chkIncluirDetalle">
							</p-checkbox>
						</div>
						<div class="p-col-4" style="text-align: left">
						</div>
					</div>
				</div>
				<!--LINE 3-->
				<div class="p-col-12" style="margin-top: -10px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Concepto:</span>
						</div>
						<div class="p-col-6" style="text-align: left">
							<input id="concepto" type="text" pInputText
								   [(ngModel)]="bandejaPagoService.encBandejaPagos.ENCBANDEJA_CONCEPTO"
								   [disabled]="boolBtnAceptar"
								   (keydown.enter)="cambiarFoco($event)" (input)="cambio($event)" autocomplete="off">
						</div>
						<div class="p-col-1">
						</div>
						<div class="p-col-1" style="text-align: right">
							<span>Importe:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<input id="importe" type="text" pInputText [(ngModel)]="bandejaPagoService.encBandejaPagos.ENCBANDEJA_TOTAL"
								   disabled>
						</div>
						<div class="p-col-2">
						</div>
					</div>
				</div>
				<!--LINE 3-->
				<div class="p-col-12" style="margin-top: -10px; margin-bottom: -15px;">
					<div class="p-grid">
						<div class="p-col-1">
							<!--									<p-button label="Aceptar" (click)="aceptar()"-->
							<!--											  [disabled]="boolBtnAceptar"></p-button>-->

							<button (click)="aceptar()" pButton class="aceptar" [disabled]="boolBtnAceptar"
									style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">
								Aceptar
							</button>

						</div>
						<div class="p-col-1">
							<span>Ordenado por:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<p-radioButton name="orden" value="proveedor" inputId="orden1"
										   [(ngModel)]="orden"></p-radioButton>
							<label> Proveedor </label>
						</div>
						<div class="p-col-1" style="text-align: left">
							<p-radioButton name="orden" value="fecha" inputId="orden2"
										   [(ngModel)]="orden"></p-radioButton>
							<label> Fecha </label>
						</div>
						<div class="p-col-7">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="divg" id="divPrincipal2">
			<app-aggridsaci [width]="2400"
							[height]="350"
							[enableSorting]="false"
							[enableFilter]="true"
							[rowData]="bandejaPagoService.detBandejaPagos"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsBanPagos"
							[defaultColDef]="defaultColBanPago"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="true"
							[botonesAdicionales]="true"
							[botonExpedienteExistente]="false"
							[botonExpedienteNuevo]="false"
							[mostrarGuardar]="false"
							[botonNuevo]="true"
							[botonRegresar]="true"
							[botonBorrar]="true"
							(cambios)="cambio($event)"
							(selected)="seleccionDetBandeja($event)"

			>
			</app-aggridsaci>
		</div>
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-12">
					<p-progressBar [value]="porcentaje"></p-progressBar>
				</div>
				
				<!-- <div class="p-col-1" style="text-align: left">
					<span>Ubicación:</span>
				</div>
				<div class="p-col-6" style="text-align: left">
					<input id="ENCCASHMNG_PATHARCHIVO" type="text" pInputText autocomplete="off">
				</div>
				<div class="p-col-4" style="text-align: left">
				</div> -->
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Comprobantes de Egreso">
		<div class="divg" style="height: 450px">
			<app-aggridsaci [width]=""
							[height]="largoComp"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="comprobantesEgreso"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsBanComEgreso"
							[defaultColDef]="defaultColComEgreso"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)="seleccionDetComprobante($event)"
			>
			</app-aggridsaci>
		</div>
	</p-tabPanel>
</p-tabView>
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBus"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [closable]="false"
		  [style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>
<p-dialog header="Clave de Autorización" [(visible)]="claveDialog"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '40vw', height: 'auto'} ">
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							   (keydown.enter)="comprobarClave()" placeholder="Ingrese la clave" autocomplete="off">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>
<!-- CDPJ -->
<p-dialog header="Reportes" [(visible)]="displayDialogReportes"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '40vw', height: '400px'}">
	<app-sacimaecfngimpr *ngIf="displayDialogReportes" [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
						 [strNumDoc]="asi_nro"
						 [strCampo]="'ASI_NRO'"
						(cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	<p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
	</p-dialog>
</div>
<!-- CDPJ -->