//
// SACI WEB
// Rutina: Maestro de compras  de grupo
// Propietario: Francisco Cevallos
// Modificado por: FC
// Fecha de creación: 05-11-2019
// Fecha de Modificación: 29-11-2019
//
import {Component, OnInit, ViewChild} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {Commaegrupo} from '../cominterfaces/commaegrupo';
import {CommaegrupoService} from '../comservicios/commaegrupo.service';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

// Ag-grid
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';

@Component({
  selector: 'app-commaegrupo',
  templateUrl: './commaegrupo.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})

export class CommaegrupoComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  desCodigo: boolean;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  btnBuscar: boolean;
  actualizar: boolean;
  codigoAux: any;
  nuevoCampo: boolean;
  permisoGrupo: SegMaePermiso;
  arregloCons: any[];
  largo: string;
  grupoSeleccionado: Commaegrupo;
  public frameworkComponents;
  rowStyle;
  defaultColDefGrupos;
  rowSelection = 'multiple';
  
  columnDefsGrupos = [
    {
      headerName: 'Código', field: 'GRU_CODIGO', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 5
          }
        }
    },
    {
      headerName: 'Nombre', field: 'GRU_NOMBRE', cellEditor: 'cellPrueba', width: 250,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 250
          }
        }
    },
    {
      headerName: 'Cta.Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 38
          }
        }
    },
  ];
  
  constructor(public commaeGrupo: CommaegrupoService, private message: MessageService, private errorService: ErroresBaseDatosService,
              private confirmationService: ConfirmationService, private busqService: InvbusquedaService,
              private permisosService: PermisosService, private auditoriagral: AuditoriagralService,
              private init: NuevoComponentService) {
    this.agTable();
    this.permisoGrupo = {};
    this.commaeGrupo.commaegrupo = [];
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefGrupos = {
      editable: true,
      width: 100,
      objeto: 'commaegrupo'
    };
    this.permisoGrupo = {};
  }
  
  ngOnInit() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnBuscar = false;
    this.buscarCon();
    this.btnRegresar = true;
    this.actualizar = false;
    this.nuevoCampo = false;
    this.codigoAux = 0;
    this.permisosService.getListaPermisos('COM', '1', '3150').subscribe((res) => {
      this.permisoGrupo = res[0];
    });
    this.commaeGrupo.getGrupos().subscribe((res) => {
      if (res !== null) {
        this.commaeGrupo.commaegrupo = res;
      }
    });
    this.largo = '258';
  }
  
  async manejarSenales(valor) {
    if (valor === 'Nuevo') {
      if (this.permisoGrupo.PERINSERCION === 1) {
        this.btnNuevo = true;
        this.btnGuardar = false;
        this.btnBorrar = false;
        this.btnRegresar = false;
        this.nuevoCampo = true;
        this.aggrid.isEditable(true);
        // this.desCodigo = false;
        this.grupoSeleccionado = {
          CEN_CODIGO: '',
          CON_CODIGO: '',
          CON_CODIGODES: '',
          GRU_CODIGO: '',
          GRU_NOMBRE: '',
          GRU_PORDES: 0,
          GRU_TIPO: 'PRO',
        };
        this.commaeGrupo.commaegrupo.push(this.grupoSeleccionado);
        this.aggrid.refreshaggrid(this.commaeGrupo.commaegrupo, this.defaultColDefGrupos.objeto);
      } else if (this.permisoGrupo.PERINSERCION === 0) {
        this.message.add({
          key: 'maecomgrupo',
          severity: 'error',
          summary: 'Inserción de Grupo',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    }
    if (valor === 'Guardar') {
      if (this.permisoGrupo.PERINSERCION === 1) {
        this.btnGuardar = true;
        this.btnNuevo = false;
        this.btnBorrar = false;
        this.btnRegresar = true;
        if (this.actualizar === true) {
          let coincidencias: number;
          coincidencias = 0;
          // this.arregloCons.map((con) => {
          //   if (this.grupoSeleccionado.CON_CODIGO === con.CON_CODIGO || this.grupoSeleccionado.CON_CODIGO === '') {
          //     coincidencias++;
          //   }
          // });
         // if (coincidencias !== 0) {
            this.commaeGrupo.actualizarGrupo(this.grupoSeleccionado, this.codigoAux).subscribe(() => {
              this.message.add({
                key: 'maecomgroup',
                severity: 'success',
                summary: 'Actualización exitosa',
                detail: 'Se actualizó el grupo correctamente'
              });
              this.aggrid.isEditable(false);
              this.auditoriagral.registrarAuditoria('VEN_MAEGRUPO', String(this.grupoSeleccionado.GRU_CODIGO), 'A',
                '', '01', '', '', '', '').subscribe(() => {
              });
            }, error1 => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.message.add({
                key: 'maecomgroup',
                severity: 'error',
                summary: 'Actualización de Grupo',
                detail: mensaje
              });
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
              this.commaeGrupo.getGrupos().subscribe((res) => {
                this.commaeGrupo.commaegrupo = res;
                this.aggrid.refreshaggrid(this.commaeGrupo.commaegrupo, this.defaultColDefGrupos.objeto);
              });
            });
        //  } else if (coincidencias === 0) {
            /* this.message.add({
              key: 'maecomgroup',
              severity: 'error',
              summary: 'Inserción de el grupo',
              detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
            });
            this.grupoSeleccionado.CON_CODIGO = '';
            this.aggrid.refreshaggrid(this.commaeGrupo.commaegrupo, this.defaultColDefGrupos.objeto); */
        //  }
        } else if (this.actualizar === false) {
          let coincidencias: number;
          coincidencias = 0;
          /* this.arregloCons.map((con) => {
            if (this.grupoSeleccionado.CON_CODIGO === con.CON_CODIGO) {
              coincidencias++;
            }
          }); */
         // if (coincidencias !== 0) {
            this.commaeGrupo.insertarGrupo(this.grupoSeleccionado).subscribe(() => {
              this.message.add({
                key: 'maecomgroup',
                severity: 'success',
                summary: 'Inserción exitosa',
                detail: 'Se insertó el grupo correctamente'
              });
              this.nuevoCampo = false;
              this.desCodigo = false;
              this.aggrid.isEditable(false);
              this.auditoriagral.registrarAuditoria('VEN_MAEGRUPO', String(this.grupoSeleccionado.GRU_CODIGO), 'I',
                '', '01', '', '', '', '').subscribe(() => {
              });
              
            }, error1 => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.message.add({
                key: 'maecomgroup',
                severity: 'error',
                summary: 'Inserción de el grupo',
                detail: mensaje
              });
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
              this.commaeGrupo.getGrupos().subscribe((res) => {
                this.commaeGrupo.commaegrupo = res;
                this.aggrid.refreshaggrid(this.commaeGrupo.commaegrupo, this.defaultColDefGrupos.objeto);
              });
            });
         // } else if (coincidencias === 0) {
            /*this.message.add({
              key: 'maecomgroup',
              severity: 'error',
              summary: 'Inserción de el grupo',
              detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
            });
            this.grupoSeleccionado.CON_CODIGO = '';
            this.aggrid.refreshaggrid(this.commaeGrupo.commaegrupo, this.defaultColDefGrupos.objeto); */
         // }
        }
      } else if (this.permisoGrupo.PERINSERCION === 0) {
        this.message.add({
          key: 'maecomgrupo',
          severity: 'error',
          summary: 'Actualización de Grupo',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }
      
    }
    if (valor === 'Borrar') {
      if (this.permisoGrupo.PERELIMACION === 1) {
        this.aggrid.isEditable(false);
        if (this.grupoSeleccionado === undefined) {
          this.message.add({
            key: 'maecomgrupo',
            severity: 'error',
            summary: 'Eliminacion de Grupo',
            detail: 'Seleccion Incorrecta, no se ha seleccionado un grupo '
          });
        } else {
          this.confirmarGrupo();
        }
      } else if (this.permisoGrupo.PERELIMACION === 0) {
        this.message.add({
          key: 'maecomgrupo',
          severity: 'error',
          summary: 'Eliminación de Grupo',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
      }
    }
    
    if (valor === 'Cancelar') {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.commaeGrupo.getGrupos().subscribe((res) => {
        this.commaeGrupo.commaegrupo = res;
        this.aggrid.refreshaggrid(this.commaeGrupo.commaegrupo, this.defaultColDefGrupos.objeto);
      });
    }
    
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
  selectedGrupo(valor) {
    if (valor.object !== null) {
      this.grupoSeleccionado = valor.object;
      if (this.nuevoCampo === true) {
        this.actualizar = false;
      } else if (this.nuevoCampo === false) {
        this.actualizar = true;
        if (this.grupoSeleccionado !== undefined) {
          this.codigoAux = this.grupoSeleccionado.GRU_CODIGO;
        }
      }
    }
  }
  
  confirmarGrupo() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el grupo',
      header: 'Eliminar Grupo',
      icon: 'pi pi-exclamation-triangle',
      key: 'ComMaeGroup',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminargrupo();
      },
      reject: () => {
      }
    });
  }
  
  eliminargrupo() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.commaeGrupo.eliminarGrupo(this.grupoSeleccionado).subscribe(() => {
      this.message.add({
        key: 'maecomgroup',
        severity: 'success',
        summary: 'Eliminación exitosa',
        detail: 'Se eliminó el grupo correctamente'
      });
      this.commaeGrupo.commaegrupo = this.commaeGrupo.commaegrupo.filter((val, j) => j !==
        this.commaeGrupo.commaegrupo.indexOf(this.grupoSeleccionado));
      this.aggrid.refreshaggrid(this.commaeGrupo.commaegrupo, this.defaultColDefGrupos.objeto);
      this.auditoriagral.registrarAuditoria('VEN_MAEGRUPO', String(this.grupoSeleccionado.GRU_CODIGO), 'E',
        '', '01', '', '', '', '').subscribe(() => {
      });
      
    }, error1 => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.message.add({
        key: 'maecomgroup',
        severity: 'error',
        summary: 'Inserción de el grupo',
        detail: mensaje
      });
    });
  }
  
  cambio(cambio) {
    this.btnGuardar = false;
    this.btnRegresar = false;
  }
  
  buscarCon() {
    this.busqService.busquedaConMaeCon().subscribe((res: any[]) => {
      this.arregloCons = res;
    });
  }
}
