import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appFourDigitDecimalNumber]'
})
export class FourDigitDecimalNumberDirective {
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,4}$/g);
  private specialKeys: Array<string> = ['-', 'Delete', 'Enter', 'ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown', 'Shift',
    'Home', 'CapsLock', 'Alt', 'Tab', 'Insert', 'End', 'Backspace', 'PageUp', 'PageDown', 'Control', 'META', 'NUMLOCK', 'F1',
    'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12', ' ', 'Escape', 'AudioVolumeMute', 'AudioVolumeUp',
    'AudioVolumeDown', 'MEDIASTOP', 'MEDIAPLAYPAUSE', 'MEDIATRACKNEXT', 'MEDIATRACKPREVIOUS'];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // console.log(this.el.nativeElement.value);

    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);

    if (next && !String(next).match(this.regex)) {
      this.el.nativeElement.value = String(event.key).charAt(1);
      // event.preventDefault();
    }
  }
}
