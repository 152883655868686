import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {Contrndistribpcc} from '../coninterfaces/contrndistribpcc';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContrndistribpccService {
  
  get detDistribcc(): Contrndistribpcc[] {
    return this._detDistribcc;
  }
  
  set detDistribcc(value: Contrndistribpcc[]) {
    this._detDistribcc = value;
  }

  
  private way: string;
  public hmpConfiguracion: any[];
  public dblValor: string;
  public totalDist = Number(0).toFixed(2);
  
  private _detDistribcc: Contrndistribpcc[];
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }
  
  refrescarURL() {
    if (this.way === null) {
      this.way = sessionStorage.getItem('way');
    }
  }
  
  listarDatos(strAsiNro, intDetasiNroFila): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cont/distribucionpcc/565ceab6d5771c', {
        elementos: {
          p_asi_nro: strAsiNro,
          p_detasi_nrofila: intDetasiNroFila,
          p_com_codigo: '01',
        },
      }, this.confIniciales.httpOptions()
    );
  }

  erContipgas(strCodigo) {  
    return this.http.post(this.way + '/cont/distribucionpcc/3b6cc5fb2cc15c', {
      elementos: {
        CON_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erTipgascodigo() {  
    return this.http.post(this.way + '/cont/distribucionpcc/1dbecb305719870000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  
  getConfiguraciones(codigo): number {
    const valorConfig = this.hmpConfiguracion.find(element => element.CFG_CODIGO === codigo).CFG_VALOR1;
    return valorConfig;
  }
  calcularTotal() {
    
    let intIndiceLista = 0;
    let item: Contrndistribpcc;
    let dblTotal = 0;
    
    
    for (intIndiceLista = 0; intIndiceLista < this.detDistribcc.length; intIndiceLista++) {
      item = this.detDistribcc[intIndiceLista];
      
      dblTotal = dblTotal + Number(item.TRNDISP_VALOR);
    }
    
    this.totalDist = Number(dblTotal).toFixed(this.confIniciales.getNumDecSist());
    
  }
  
  guardarDistribucion(item: Contrndistribpcc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cont/distribucionpcc/e2bee6cb9ddc980', {
        elementos: {
          p_asi_nro: item.ASI_NRO,
          p_detasi_nrofila: Number(item.DETASI_NROFILA),
          p_com_codigo: '01',
          p_cen_codigo: item.CEN_CODIGO,
          p_trndisp_valor: Number(item.TRNDISP_VALOR),
          p_trndisp_porce: Number(item.TRNDISP_PORCE),
          p_con_codigo: item.CON_CODIGO,
          p_trndisp_linea: Number(item.TRNDISP_LINEA),
          p_prog_codigo: item.PROG_CODIGO,
          p_tipgas_codigo: item.TIPGAS_CODIGO
        },
      
      }, this.confIniciales.httpOptions()
    );
  }
  
  eliminarLineaSel(item: Contrndistribpcc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cont/distribucionpcc/75656acaee571c0', {
        elementos: {
          p_asi_nro: item.ASI_NRO,
          p_detasi_nrofila: Number(item.DETASI_NROFILA),
          p_com_codigo: '01',
          p_trndisp_linea: Number(item.TRNDISP_LINEA)
        },
        
      }, this.confIniciales.httpOptions()
    );
  }
  
  listarDatosAutomatica(StrAsiNro, intDetasiNroFila, strTrnProCenCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cont/distribucionpcc/acb9d6abb857b80', {
        elementos: {
          p_trnprocen_codigo: strTrnProCenCodigo,
          p_com_codigo: '01',
          p_asi_nro: StrAsiNro,
          p_detasi_nrofila: intDetasiNroFila,
        },
      }, this.confIniciales.httpOptions()
    );
  }
}
