<div class="layout-wrapper" [ngClass]="{'layout-horizontal': menuMode === 'horizontal',
    'layout-overlay': menuMode === 'overlay',
    'layout-static': menuMode === 'static',
    'layout-slim': menuMode === 'slim',
    'layout-menu-light': lightMenu === true,
    'layout-menu-dark': lightMenu === false,
    'layout-overlay-active': overlayMenuActive,
    'layout-mobile-active': staticMenuMobileActive,
    'layout-static-inactive': staticMenuDesktopInactive,
    'layout-rtl': isRTL}" [class]="topbarColor" (click)="onLayoutClick()">
	<app-topbar></app-topbar>
	<app-menu [reset]="resetMenu"></app-menu>
	<app-right-menu></app-right-menu>
	<div class="layout-main">
		<div class="layout-content">
			<!--   <router-outlet></router-outlet> -->
			<mat-tab-group (selectedIndexChange)="onTabChange($event)" [(selectedIndex)]="init.tabindex">
				<mat-tab [disabled]="init.disabledtab" *ngFor="let tab of init.tabs">
					<ng-template *ngIf="botonesnuevosalir" mat-tab-label>
						{{ tab.label }}
						<span></span>
						<span (click)="openNewTab('frmTOUCHFACTURA', 'Facturación', '', '')"
							  style="padding:5px; background-image: url(../assets/images/iconos/add.png);"></span>
						<span (click)="cerrarTab(init.tabindex)"
							  style="padding:5px; background-image: url(../assets/images/iconos/cancel.png);"></span>
					</ng-template>
					<ng-template *ngIf="botonesnuevosalir === false" mat-tab-label>
						{{ tab.label }}
					</ng-template>
					<app-listacomponentes (menuOpciones)="cambiarOpciones($event)" [componete]="tab.component"
										  [label]="tab.label" [modprefijo]="tab.modprefijo"
										  (mouseenter)="onMenuButtonClick2($event)"></app-listacomponentes>
				</mat-tab>
			</mat-tab-group>
		</div>
	</div>
	<!--<div class="layout-footer">
		<app-footer></app-footer>
	</div>-->
	<div class="layout-content-mask"></div>
</div>
<!--<div (window:beforeunload)="doSomething()"></div>-->
<!--
<script language="JavaScript" type="text/javascript">
	window.onbeforeunload = preguntarAntesDeSalir;
	function preguntarAntesDeSalir() {
		return 'Preguntar antes de salir?'
	}
</script>
-->
