import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {InvReservas, InvTrnReservas} from '../invinterfaces/invtrnreservas';
import {AggridBanComponent} from '../../ban/aggrid-ban/aggrid-ban.component';
import {InvtrnreservasService} from '../invservicios/invtrnreservas.service';
import {DatePipe} from '@angular/common';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {InvenctransferService} from '../invservicios/invenctransfer.service';

@Component({
  selector: 'app-invreservas',
  templateUrl: './invreservas.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class InvreservasComponent implements OnInit {
  
  
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  largo: string;
  allSelected: boolean;
  rowSelection = 'multiple';
  public frameworkComponents;
  arregloaEnviar: InvReservas[] = [];
  @Input() bodCodigo: string;
  @Input() bodDestino: string;
  @Input() fechaCaduca;
  @Input() indicador;
  @Output() detallesSeleccionados = new EventEmitter<InvReservas[]>();
  @Output() signalSalir = new EventEmitter<boolean>();
  rowStyle;
  defaultColDef;
  
  detallesel: InvReservas = {};
  
  
  columnDefsDetfac = [
    {
      headerName: '', field: 'TRNRES_SELECCIONADO', cellRendererFramework: AggridBanComponent, editable: false, width: 50, checkCierre: false,
    },
    { // 0
      headerName: 'Número', field: 'TRNRES_NUMERO', width: 100, editable: false,
    },
    { // 1
      headerName: 'F.Caduca', field: 'TRNRES_CADUCA', editable: false, width: 110
    },
    { // 1
      headerName: 'Usuario', field: 'TRNRES_USUARIO', editable: false, width: 100
    },
    // { // 1
    //   headerName: 'Bod.Origen', field: 'BOD_CODIGO', editable: false, width: 110
    // },
    // { // 2
    //   headerName: 'Bod.Destino', field: 'BOD_CODIGODES', width: 110, editable: false
    // },
    { // 2
      headerName: 'Artículo', field: 'ART_CODIGO', width: 110, editable: false
    },
    { // 2
      headerName: 'Descripción', field: 'ART_NOMBRE', width: 180, editable: false
    },
    { // 2
      headerName: 'Unidad', field: 'ART_UNIDAD', width: 80, editable: false
    },
    { // 2
      headerName: 'Cantidad', field: 'TRNRES_CANTIDAD', width: 90, editable: false
    },
    { // 2
      headerName: 'Despachado', field: 'TRNRES_ENTREGADO', width: 90, editable: false
    },
    { // 2
      headerName: 'Transf.Pendiente', field: 'TRANSFER_CANTIDAD_P', width: 90, editable: false
    },
    { // 8
      headerName: 'Observación', field: 'TRNRES_OBSERVACION', width: 200, editable: false
      // , editable: true,
    }
  ];
  constructor(public reservasService: InvtrnreservasService, private datePipe: DatePipe,
              public init: NuevoComponentService, public enctransferService: InvenctransferService) {
    this.agTable();
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDef = {
      editable: true,
      width: 100,
      objeto: 'invdetreservas'
    };
  }
  async ngOnInit() {
    
    this.allSelected = false;
    this.largo = '500';
    this.arregloaEnviar = [];
    this.fechaCaduca = this.datePipe.transform(this.fechaCaduca, 'dd/MM/yyyy HH:mm');
    this.reservasService.detalleReservaTransfer = [];
    const detalle: InvReservas[] = [];
    const rs = await this.reservasService.reservasTranfer(this.bodCodigo, this.bodDestino, this.fechaCaduca);
    
    if (rs.length > 0) {
      for (const item of rs) {
        const det: InvReservas = {};
        
        det.TRNRES_NUMERO = item.TRNRES_NUMERO;
        det.ART_CODIGO = item.ART_CODIGO;
        det.ART_NOMBRE = item.ART_NOMBRE;
        det.BOD_CODIGO = item.BOD_CODIGO;
        det.ART_UNIDAD = item.ART_UNIDAD;
        det.BOD_CODIGODES = item.BOD_CODIGODES;
        det.COM_CODIGO = item.COM_CODIGO;
        det.TRNRES_SELECCIONADO = false;
        det.TRNRES_CADUCA = this.datePipe.transform(item.TRNRES_CADUCA, 'dd/MM/yyyy HH:mm');
        det.TRNRES_FECHA = this.datePipe.transform(item.TRNRES_FECHA, 'dd/MM/yyyy HH:mm');
        det.TRNRES_CANTIDAD = Number(item.TRNRES_CANTIDAD).toFixed(2);
        det.TRNRES_ENTREGADO = item.TRNRES_ENTREGADO === null ? Number(0).toFixed(2) :
          Number(item.TRNRES_ENTREGADO ).toFixed(2);
        det.TRNRES_USUARIO = item.TRNRES_USUARIO;
        det.TRNRES_OBSERVACION = item.TRNRES_OBSERVACION;
        det.TRANSFER_CANTIDAD_P = Number(item.TRANSFER).toFixed(2);
        detalle.push(det);
      }
      
      if (this.enctransferService.invdettransferarray[this.indicador].length > 0) {
        for (const transfer of this.enctransferService.invdettransferarray[this.indicador]) {
          for (const res of detalle) {
            if (transfer.TRNRES_NUMERO === res.TRNRES_NUMERO) {
              console.log('iguales');
              detalle.splice(detalle.findIndex(
                item => item.TRNRES_NUMERO === res.TRNRES_NUMERO),
                1);
            }
          }
        }
      }
      this.reservasService.detalleReservaTransfer = detalle;
    }
  }
  
  // async ngOnChanges(changes: SimpleChanges) {
  //
  //   if (this.bodCodigo !== undefined && this.bodDestino !== undefined) {
  //
  //   }
  // }
  
  seleccionar() {
    
    if (this.allSelected === true) {
      this.reservasService.detalleReservaTransfer.map((item) => {
        item.TRNRES_SELECCIONADO = true;
      });
      this.aggrid.refreshaggrid(this.reservasService.detalleReservaTransfer, this.defaultColDef.objeto);
    } else if (this.allSelected === false) {
      this.reservasService.detalleReservaTransfer.map((item) => {
        item.TRNRES_SELECCIONADO = false;
      });
      this.aggrid.refreshaggrid(this.reservasService.detalleReservaTransfer, this.defaultColDef.objeto);
    }
  }
  enviarDetalles() {
    let detReserva;
    for (const item of this.reservasService.detalleReservaTransfer) {
      if (this.enctransferService.invdettransferarray[this.indicador].length !== 0) {
        detReserva = this.enctransferService.invdettransferarray[this.indicador].find(element => element.TRNRES_NUMERO === item.TRNRES_NUMERO);
        console.log(detReserva);
      }
      if (item.TRNRES_SELECCIONADO === true && detReserva === undefined) {
        this.arregloaEnviar.push(item);
      }
    }
    
    this.detallesSeleccionados.emit(this.arregloaEnviar);
  }
  enviarSalir() {
    this.signalSalir.emit(true);
  }
  
  
  
}
