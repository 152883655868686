<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="recalcstpromprod"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarrecalcstpromprod" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 98%">
    <div class="p-col-12">
        <app-invmaebarra [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"
            [barraBotones5]="true" [barraBotonesCRUD]="true" [barraBotones3Alt]="false"
            (signal)="manejarSenales($event)" style="text-align: left;">
        </app-invmaebarra>
    </div>
</div>
<div class="divgrups">
    <div class="p-grid" style="margin-top: 10px; margin-bottom: 10px">
        <div class="p-col-3">
        </div>
        <div class="p-col-1">
            <span>Inicial:</span>
        </div>
        <div class="p-col-1">
            <p-calendar id="FECHAINICIAL" [inputStyle]="{width: '100%', height: '20px'}" [(ngModel)]="fechainicial"
                dateFormat="dd/mm/yy">
            </p-calendar>
        </div>
        <div class="p-col-1">
            <p-calendar id="HORAINICIAL" [inputStyle]="{width: '80%', height: '20px'}" [timeOnly]="true"
                [(ngModel)]="horainicial" dateFormat="dd/mm/yy"></p-calendar>
        </div>
        <div class="p-col-6"></div>
        <div class="p-col-3">
        </div>
        <div class="p-col-1">
            <span>Final:</span>
        </div>
        <div class="p-col-1">
            <p-calendar id="FECHAFINAL" [inputStyle]="{width: '100%', height: '20px'}" [(ngModel)]="fechafinal"
                dateFormat="dd/mm/yy">
            </p-calendar>
        </div>
        <div class="p-col-1">
            <p-calendar id="HORAFINAL" [inputStyle]="{width: '80%', height: '20px'}" [timeOnly]="true"
                [(ngModel)]="horafinal" dateFormat="dd/mm/yy"></p-calendar>
        </div>
        <div class="p-col-6"></div>
        <div class="p-col-3">
        </div>
        <div class="p-col-3">
            <button class="sincss" pButton (click)="procesar()">Procesar
            </button>
        </div>
        <div class="p-col-6"></div>
        <div class="p-col-12"></div>
        <div class="p-col-12"></div>
        <div class="p-col-12"></div>
        <div class="p-col-12"></div>
        <div class="p-col-12"></div>
        <div class="p-col-12"></div>
        <div class="p-col-12"></div>
        <div class="p-col-12"></div>
        <div class="p-col-12"></div>
        <div class="p-col-12"></div>
        <div class="p-col-12"></div>
        <div class="p-col-12"></div>
        <div class="p-col-12"></div>
        <div class="p-col-12"></div>
        <div class="p-col-12"></div>
    </div>
</div>


<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
</div>