<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="cxcmaetarjetacredito"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="Cxcmaetarjetacredito" title="Confirmar Acción"
                 appendTo="body"></p-confirmDialog>

<div class="p-grid" style="width: 99%">
  <div class="p-col-12">
    <app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
                     [botonRegresar]="btnRegresar" [barraBotonesCRUD]="false"
                     [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true"
                     (signal)="manejarSenales($event)"></app-invmaebarra>

  </div>
</div>
<div style="overflow: hidden" class="p-col-12">
  <p-tabView>
    <p-tabPanel header="Tarjeta de Crédito">
      <app-aggridsaci [width]=""
                      [height]="largo"
                      [enableSorting]="false"
                      [enableFilter]="true"
                      [rowData]="tarjetaService.cxcmaeTarjetaCredito"
                      [frameworkComponents]="frameworkComponents"
                      [animateRows]="true"
                      [rowSelection]="rowSelection"
                      [columnDefs]="columnDefsTarjetaCredito"
                      [defaultColDef]="defaultColDeTarjetaCredito"
                      [rowStyle]="rowStyle"
                      [singleClickEdit]="false"
                      [botones]="false"
					  (guardarEnter)="controlGuardar($event)"
					  (selected)="selectedTarjetaCredito($event)"
                      (cambios)="cambio($event)"

      ></app-aggridsaci>
    </p-tabPanel>
  </p-tabView>
</div>

