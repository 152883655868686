//
// SACI WEB
// Rutina: Pto Vta Maestro Pantalla
// Propietario: Victor Perez
// Modificado por: VP
// Fecha de creación: 2019
// Fecha de Modificación: 2019
//
import {Component, Input, OnInit} from '@angular/core';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {MaepantallaService} from '../../servicios/maepantalla.service';
import {MessageService} from 'primeng/api';
import {MaesubcategoriaService} from '../../servicios/maesubcategoria.service';
import {MaePantalla} from '../../interfaces/maepantalla';


@Component({
  selector: 'app-maepantalla',
  templateUrl: './maepantalla.component.html',
  styleUrls: ['./maepantalla.component.css'],
  preserveWhitespaces: false
})

export class MaepantallaComponent implements OnInit {
  @Input() selectedPantalla: MaePantalla[];
  
  displayDialog: boolean;
  maepantalla: MaePantalla = {};
  selectedMaePantalla: MaePantalla;
  newMaePantalla: boolean;
  clonedselectedPantalla: { [s: string]: MaePantalla; } = {};
  prodCATCODIGO: string;
  prodSUBCODIGO: string;
  results: MaePantalla[];
  subcategorias: any[];
  
  // combo
  
  items: SelectItem[];
  item: string;
  
  filteredArticulosSingle: any[];
  filteredNameArticulosSingle: any[];
  filteredNameArticulosSingleAux: any[];
  
  imgBlob: any;
  
  autodialog = true;
  auxcodigoarticulo = '';
  auxnombrearticulo = '';
  
  constructor(private selectedPantallaervice: MaepantallaService, private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private subCatService: MaesubcategoriaService) {
  }
  
  ngOnInit() {
    this.prodCATCODIGO = this.subCatService.recogerCATCODIGO().toString();
    this.prodSUBCODIGO = this.subCatService.recogerSUBCODIGO().toString();
  }
  
  showDialogToAdd() {
    this.newMaePantalla = true;
    this.maepantalla = {};
    this.prodCATCODIGO = this.subCatService.recogerCATCODIGO().toString();
    this.prodSUBCODIGO = this.subCatService.recogerSUBCODIGO().toString();
    this.auxcodigoarticulo = '';
    this.auxnombrearticulo = '';
    this.displayDialog = true;
    this.maepantalla.CHOSE = false;
    this.autodialog = true;
    localStorage.clear();
  }
  
  save() {
    
    if (this.maepantalla.PANT_ORDEN === undefined || this.maepantalla.PANT_ORDEN === '' || this.maepantalla.PANT_ORDEN === null ||
      this.auxcodigoarticulo === undefined || this.auxcodigoarticulo === '' || this.auxcodigoarticulo === null ||
      this.auxnombrearticulo === undefined || this.auxnombrearticulo === '' || this.auxnombrearticulo === null) {
      this.messageService.add({
        key: 'message',
        severity: 'warn',
        summary: 'Error!',
        detail: 'Ingrese todos los campos'
      });
      return;
    }
    
    const selectedPantalla = [...this.selectedPantalla];
    if (this.newMaePantalla) {
      
      this.imgBlob = localStorage.getItem('imgblob');
      this.maepantalla.ART_CODIGO = this.auxcodigoarticulo;
      this.maepantalla.ART_NOMBRE = this.auxnombrearticulo;
      this.maepantalla.CAT_CODIGO = this.prodCATCODIGO;
      this.maepantalla.SUB_CODIGO = this.prodSUBCODIGO;
      this.maepantalla.ART_IMAGEN = this.imgBlob;
      if (this.maepantalla.PANT_PESOSEL === true) {
        this.maepantalla.PANT_PESO = '1';
      } else {
        this.maepantalla.PANT_PESO = '0';
      }
      selectedPantalla.push(this.maepantalla);
      this.selectedPantallaervice.insertMaePantalla(this.maepantalla);
      this.messageService.add({
        key: 'message',
        severity: 'success',
        summary: 'Ingreso Exitoso!',
        detail: 'Registro de pantalla ingresado'
      });
    } else {
      selectedPantalla[this.selectedPantalla.indexOf(this.selectedMaePantalla)] = this.maepantalla;
      this.messageService.add({
        key: 'message',
        severity: 'error',
        summary: 'Error!',
        detail: 'Ocurrio un error al ingresar los datos'
      });
    }
    this.selectedPantalla = selectedPantalla;
    this.maepantalla = null;
    this.displayDialog = false;
  }
  
  delete() {
    const index = this.selectedPantalla.indexOf(this.selectedMaePantalla);
    this.selectedPantalla = this.selectedPantalla.filter((val, i) => i !== index);
    this.maepantalla = null;
    this.displayDialog = false;
  }
  
  onRowEditInit(pan: MaePantalla) {
    pan.CHOSE = true;
    this.clonedselectedPantalla[pan.ART_CODIGO] = {...pan};
  }
  
  onRowEditSave(pan: MaePantalla) {
    if (pan.PANT_PESOSEL === true) {
      pan.PANT_PESO = '1';
    } else {
      pan.PANT_PESO = '0';
    }
    this.imgBlob = localStorage.getItem('imgblob');
    if (this.imgBlob === null || this.imgBlob === undefined) {
      for (const element of this.selectedPantalla) {
        if (element.ART_CODIGO === pan.ART_CODIGO) {
          pan.ART_IMAGEN = element.ART_IMAGEN;
        }
      }
    } else {
      pan.ART_IMAGEN = this.imgBlob;
    }
    pan.CHOSE = false;
    
    if (pan.PANT_ORDEN !== '') {
      delete this.clonedselectedPantalla[pan.ART_CODIGO];
      this.selectedPantallaervice.updateMaePantalla(pan);
      /* this.selectedPantallaervice.getMaePantalla().subscribe((respuesta: any[]) => {
         this.selectedPantalla = respuesta;
       });*/
      this.messageService.add({
        key: 'message',
        severity: 'success',
        summary: 'Actualización Exitosa!',
        detail: 'Registro de Pantalla Actualizado'
      });
    } else {
      this.messageService.add({
        key: 'message',
        severity: 'error',
        summary: 'Error!',
        detail: 'Ingrese los campos vacios'
      });
    }
    localStorage.clear();
    this.imgBlob = null;
  }
  
  onRowEditCancel(pan: MaePantalla) {
    // this.pant2[index] = this.clonedselectedPantalla[pan.ART_CODIGO];
    delete this.clonedselectedPantalla[pan.ART_CODIGO];
    pan.CHOSE = false;
    this.selectedPantallaervice.getMaePantalla(this.prodCATCODIGO, this.prodSUBCODIGO).subscribe((respuesta: any[]) => {
      this.selectedPantalla = respuesta;
    });
  }
  
  onRowEditDelete(pan: MaePantalla) {
    // this.pant2[index] = this.clonedselectedPantalla[pan.ART_CODIGO];
    delete this.clonedselectedPantalla[pan.ART_CODIGO];
    this.selectedPantalla.splice(this.selectedPantalla.indexOf(pan.ART_CODIGO) - 1, 1);
    this.selectedPantallaervice.deleteMaePantalla(pan);
    this.messageService.add({
      key: 'message',
      severity: 'success',
      summary: 'Eliminación Exitosa!',
      detail: 'Registro de pantalla eliminado'
    });
  }

// consulta de articulos
  filterArticuloSingle(event) {
    const query = event.query;
    this.selectedPantallaervice.getMaeArticuloPrecioA().subscribe((respuesta: any[]) => {
      this.filteredArticulosSingle = this.filterArticulo(query, respuesta);
    });
  }
  
  filterArticulo(query, art: any[]): any[] {
    const filtered: any[] = [];
    for (let i = 0; i < art.length; i++) {
      const articulo = art[i];
      if (articulo.ART_CODIGO.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(articulo);
      }
    }
    return filtered;
  }
  
  // consulta de nombres articulos
  filterNameArticuloSingle(event) {
    const query = event.query;
    this.selectedPantallaervice.getMaeArticuloPrecioA().subscribe((respuesta: any[]) => {
      this.filteredNameArticulosSingle = this.filterNameArticulo(query, respuesta);
    });
  }
  
  filterNameArticulo(query, names: any[]): any[] {
    const filtered: any[] = [];
    for (let i = 0; i < names.length; i++) {
      const articulo = names[i];
      if (articulo.ART_NOMBREC.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(articulo);
      }
    }
    return filtered;
  }
  
  public onSelectArticulo(cat): void {
    this.auxcodigoarticulo = cat.ART_CODIGO;
    this.auxnombrearticulo = cat.ART_NOMBREC;
    this.autodialog = false;
  }
  
  
  public onSelectName(cat): void {
    this.auxcodigoarticulo = cat.ART_CODIGO;
    this.auxnombrearticulo = cat.ART_NOMBREC;
    this.autodialog = false;
  }
  
  onBasicUpload(event) {
    const fileReader = new FileReader();
    for (const file of event.files) {
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        localStorage.setItem('imgblob', fileReader.result + '');
      };
    }
  }
  
  
  clean() {
    this.autodialog = true;
  }
  
}



