import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from 'src/app/usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class NomencrolcerradosService {


  private way: string;
  public url: string;

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) { 
      this.way = sessionStorage.getItem('way');
      this.url = sessionStorage.getItem('url');
    }



  rolNavegarCerr(strOpcion, strEncrolindi_estado, strCondicion ){
    return  this.http.post<any[]>(this.way + '/nom/encrolcerrados/v1v0p5qgl2unmwc', {
      elementos: {
        p_opcion: strOpcion,
        p_encrolindi_estado: strEncrolindi_estado,
        p_condicion: strCondicion,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }


  llenaCampos(strCABROLADMN_CODIGO){
    
  
    return this.http.post<any[]>(this.way + '/nom/encrolcerrados/tnkaahz8v4xnlix', {
        elementos: {},
        datos: {
          CABROLADMN_CODIGO: strCABROLADMN_CODIGO
        }
      }, this.confIniciales.httpOptions()).toPromise();

  }


  

  encRegistroPeriodo(strCampo, strCondicion) {
    
    return this.http.post(this.way + '/nom/encrolcerrados/jlbrzvres5lto9o', {
      elementos: {
        Campo: strCampo,
        Condicion: strCondicion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  encRegistroEmpleado(strCampo, strCondicion) {
    
    return this.http.post(this.way + '/nom/encrolcerrados/mv4hihwfsf191mo', {
      elementos: {
        Campo: strCampo,
        Condicion: strCondicion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encRegistroTipoRol(strCampo, strCondicion) {
    
    return this.http.post(this.way + '/nom/encrolcerrados/735o489o6wsg1i6', {
      elementos: {
        Campo: strCampo,
        Condicion: strCondicion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

 

 

  getDetalleRol(lblENCROLINDI_CODIGO) {
   
    return this.http.post<any[]>(this.way + '/nom/encrolcerrados/wa2oa0ms03ce29m', {
        elementos: {
          ENCROLINDI_CODIGO: lblENCROLINDI_CODIGO
        },
      }, this.confIniciales.httpOptions()).toPromise();
  }


  getDetalleProvision(lblENCROLINDI_CODIGO){
  
    return this.http.post<any[]>(this.way + '/nom/encrolcerrados/v7fc88qtbrtt5o', {
        elementos: {
          ENCROLINDI_CODIGO: lblENCROLINDI_CODIGO
        },
      }, this.confIniciales.httpOptions()).toPromise();
  }


  FunListaTipRol(){
  
    return this.http.post<any[]>(this.way + '/nom/encrolcerrados/k8kd0swzxb0r7s1', {
        elementos: {
        },
      }, this.confIniciales.httpOptions()).toPromise();
  }
//CDPJ
actualizarEstado(strCABROLADMN_CODIGO){
  return this.http.post<any[]>(this.way + '/nom/encrolcerrados/299df2ab2', {
      elementos: {},
      datos: {
        CABROLADMN_CODIGO: strCABROLADMN_CODIGO
      }
    }, this.confIniciales.httpOptions()).toPromise();

}

funGeneraAuditoriaRolPagosCerrados(strEncrolindi_codigo  ){
  return  this.http.post<any[]>(this.way + '/nom/rolproceso/20ebabcd96f15c0000', {
    elementos: {
      p_encrolindi_codigo: strEncrolindi_codigo , 
      p_usuidentificacion: this.usuario.identificacion, // varchar2,
      p_com_codigo: '01', // varchar2,
      p_aud_operacion: "ACT-ESTADO-ROL-CERRADO", // varchar2
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
//CDPJ
  
}
