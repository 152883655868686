import * as JsBarcode from 'jsbarcode';
import {TouchTmpfactura} from '../interfaces/touchtmpfactura';

export class ServicioFacturacionPDF {

  constructor() {
  }

  obtenerCodigoBarras(codigo) {
    const canvas = document.createElement('canvas');
    JsBarcode(canvas, codigo, {format: 'CODE128'});
    // console.log(canvas.toDataURL("image/png"));
    return canvas.toDataURL('image/png');
  }

  getDetalleFactura(listafactura: TouchTmpfactura[]) {
    const tamanioletraDetalle = 7;
    const body: any = [[
      {text: 'Cant', bold: true, alignment: 'center', fontSize: 8},
      {text: 'Descripción', bold: true, alignment: 'center', fontSize: 8},
      {text: 'Pre.Uni', bold: true, alignment: 'center', fontSize: 8},
      {text: 'Pre.Tot', bold: true, alignment: 'center', fontSize: 8}
    ]];
    listafactura.forEach((detfac) => {
      const detalle = [
        {text: detfac.DETFAC_CANTIDAD, fontSize: tamanioletraDetalle},
        {text: detfac.DETFAC_DESCRIPCION, alignment: 'left', fontSize: tamanioletraDetalle},
        {text: detfac.DETFAC_PRECIO.toFixed(2), fontSize: tamanioletraDetalle},
        {text: detfac.DETFAC_TOTAL.toFixed(2), fontSize: tamanioletraDetalle}
      ];

      body.push(detalle);
    });
    console.log(body);
    return body;
  }

  getPagos(listafactura: TouchTmpfactura[]) {
    const tamanioletraDetalle = 7;
    const body: any = [[
      {text: 'Tipo', bold: true, alignment: 'center', fontSize: 8},
      {text: 'Descripción', bold: true, alignment: 'center', fontSize: 8},
      {text: 'Importe', bold: true, alignment: 'center', fontSize: 8}
    ]];
    listafactura.forEach((detfac) => {
      const detalle = [
        {text: detfac.TRNPAGO_TIPO, aligment: 'left', fontSize: tamanioletraDetalle},
        {text: detfac.BANCLI_NOMBRE, alignment: 'left', fontSize: tamanioletraDetalle},
        {text: detfac.TRNPAGO_IMPORTE.toFixed(2), fontSize: tamanioletraDetalle},
      ];
      body.push(detalle);
    });
    console.log(body);
    return body;
  }

  datosempresa(visual, listafactura) {
    if (visual === true) {
      return {
        text: listafactura[0].COMNOMBREP,
        style: 'header'
      };
    } else {
      return {};
    }
  }

  razonsocial(visual, listafactura) {
    if (visual === true) {
      return {
        text: listafactura[0].COMNOMBRE,
        style: 'razonsocial'
      };
    } else {
      return {};
    }
  }

  correo(visual, listafactura) {
    if (visual === true) {
      return ['CORREO:', listafactura[0].COMCORREO];
    } else {
      return {};
    }
  }


  vendedor(visual, listafactura) {
    if (visual === true) {
      return ['VENDEDOR', listafactura[0].VEN_NOMBRE];
    } else {
      return {};
    }
  }


  public construirFactura(listafacturas: TouchTmpfactura[]): any {
    // variables para los formatos de la hoja como margenes , tamaño de letras, etc...
    const ancho = 236;
    const largo = 1000;
    const margenizquierdo = 10;
    const margensuperior = 20;
    const margenderecho = 10;
    const margeninferior = 60;
    const listafactura: Array<TouchTmpfactura> = listafacturas.filter((ven) => {
      return ven.TIPO === 'FAC';
    });
    const pagos: Array<TouchTmpfactura> = listafacturas.filter((ven) => {
      return ven.TIPO === 'PAGO';
    });
    // construccion de la factura
    const dd = {
      pageSize: {
        width: ancho,
        height: largo
      },
      pageMargins: [margenizquierdo, margensuperior, margenderecho, margeninferior],
      content: [
        this.datosempresa(true, listafactura),
        this.razonsocial(true, listafactura),
        // {canvas: [{type: 'line', x1: 0, y1: 5, x2: 236 - 20, y2: 5, lineWidth: 0.5, dash: {length: 2}}]},
        // datos del cliente
        {
          style: 'tableExample',
          table: {
            body: [
              ['RUC:', listafactura[0].COMRUCI],
              ['DIRECCIÓN:', listafactura[0].COMDIRECCION],
              ['TELÉFONO:', listafactura[0].COMTELEFONO1],
              this.correo(true, listafactura),
              this.vendedor(true, listafactura)
            ]
          },
          layout: 'noBorders'
        },
        {canvas: [{type: 'line', x1: 0, y1: 5, x2: 236 - 20, y2: 5, lineWidth: 0.5, dash: {length: 2}}]},
        {
          text: 'FACTURA Nº: ' + listafactura[0].ENCFAC_NUMERO,
          style: 'numerofactura'
        },
        {canvas: [{type: 'line', x1: 0, y1: 0, x2: 236 - 20, y2: 0, lineWidth: 0.5, dash: {length: 2}}]},
        {
          style: 'tableExample',
          table: {
            body: [
              ['NOMBRE:', listafactura[0].CLI_NOMBRE],
              ['CI/RUC:', listafactura[0].CLI_RUCIDE],
              ['DIRECCIÓN:', listafactura[0].CLI_DIRECCION1],
              ['TELÉFONO:', listafactura[0].CLI_TELEFONO1],
              ['FECHA:', listafactura[0].ENCFAC_FECHAEMISION.toLocaleString().substr(0, 10)]
            ]
          },
          layout: 'noBorders'
        },
        {canvas: [{type: 'line', x1: 0, y1: 0, x2: 236 - 20, y2: 0, lineWidth: 0.5, dash: {length: 2}}]},
        {
          style: 'tableDetalle',
          table: {
            widths: ['auto', '*', 'auto', 'auto'],
            headerRows: 1,
            body: this.getDetalleFactura(listafactura)
          },
          layout: 'headerLineOnly'
        },
        {canvas: [{type: 'line', x1: 0, y1: 0, x2: 236 - 20, y2: 0, lineWidth: 0.5, dash: {length: 2}}]},
        {
          columns: [
            {width: '*', text: ''},
            {
              width: 'auto',
              style: 'tableTotales',
              table: {
                widths: ['auto', 'auto'],
                headerRows: 4,
                body: [
                  ['SUBTOTAL:', listafactura[0].ENCFAC_TOTALNETO.toFixed(2)],
                  ['DESCUENTO:', listafactura[0].ENCFAC_VALORDES.toFixed(2)],
                  ['TARIFA 0%:', listafactura[0].ENCFAC_BASECERO.toFixed(2)],
                  ['IVA 12%:', listafactura[0].ENCFAC_VALORIVA.toFixed(2)],
                  ['TOTAL:', listafactura[0].ENCFAC_TOTAL.toFixed(2)],
                ]
              },
              layout: 'headerLineOnly'
            }
          ]
        },
        // fromas de pago
        {canvas: [{type: 'line', x1: 0, y1: 3, x2: 236 - 20, y2: 3, lineWidth: 0.5, dash: {length: 2}}]},
        {
          text: 'Forma de pago: ',
          fontSize: 9,
          alignment: 'center'
        },
        {
          style: 'tableDetalle',
          table: {
            widths: ['auto', '*', 'auto'],
            headerRows: 0,
            body: this.getPagos(pagos)
          },
          layout: 'headerLineOnly'
        },
        {canvas: [{type: 'line', x1: 0, y1: 3, x2: 236 - 20, y2: 3, lineWidth: 0.5, dash: {length: 2}}]},
        {
          text: 'CLAVE DE ACCESO',
          margin: [0, 5, 0, 0],
          style: 'header'
        },
        // poner imagen
        {
          border: [true, false, true, true],
          image: this.obtenerCodigoBarras(listafactura[0].ENCFAC_AUTORIZACION_FE),
          alignment: 'center',
          width: 200
        },
        {
          text: 'Descargue su Factura Electrónica en: www.sacianex.com',
          fontSize: 8,
          alignment: 'center'
        },
        {canvas: [{type: 'line', x1: 0, y1: 5, x2: 236 - 20, y2: 5, lineWidth: 0.5, dash: {length: 2}}]},
        {canvas: [{type: 'line', x1: 236 / 4, y1: 30, x2: (236 - 20) - 236 / 4, y2: 30, lineWidth: 0.5, dash: {length: 2}}]},
        {
          text: 'recibi conforme',
          alignment: 'center'
        }
      ],
      styles: {
        header: {
          fontSize: 9,
          bold: true,
          alignment: 'center',
        },
        razonsocial: {
          fontSize: 8,
          bold: true,
          alignment: 'center',
        },
        numerofactura: {
          fontSize: 9,
          bold: true,
          alignment: 'center',
        },
        content: {
          fontSize: 8,
          bold: false,
          alignment: 'justify',
        },
        tableExample: {
          margin: [0, 0, 0, 0],
          fontSize: 8,
        },
        tableDetalle: {
          margin: [0, 5, 0, 0],
          fontSize: 7,
          alignment: 'right',
        },
        tableTotales: {
          margin: [0, 5, 0, 0],
          fontSize: 8,
          alignment: 'right',
        },
        bigger: {
          fontSize: 15,
          italics: true
        }
      },
      defaultStyle: {
        fontSize: 8,
      }
    };
    return dd;
  }
}
