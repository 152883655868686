import { Component, OnInit, ViewChild } from '@angular/core';
import { Usuario } from '../../usuario';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { SegMaeCompania } from '../../ven/veninterfaces/segmaecompania';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { UtilitariosService } from '../../advantage/advantageservices/utilitarios.service';
import { VenmaeclienteService } from '../../ven/venservicios/venmaecliente.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { Cxctmptrnresumen } from '../../ven/veninterfaces/cxctmptrnresumen';
import { Cxctrnresumen } from '../../ven/veninterfaces/cxctrnresumen';
// Ag-grid
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { AggridBanComponent } from '../../ban/aggrid-ban/aggrid-ban.component';
import { CxctrncobranzasService } from '../cxcservicios/cxctrncobranzas.service';
import { Cxctrnanticipos } from '../cxcinterfaces/cxctrnanticipos';
import { Cxctrncobranzas } from '../cxcinterfaces/cxctrncobranzas';
import { CxctrnCobresumen } from '../cxcinterfaces/cxctrncobrresumen';


@Component({
  selector: 'app-cxctrncobranzas',
  templateUrl: './cxctrncobranzas.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class CxctrncobranzasComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  hmpConfiguracion: any[];
  indicador: any;
  largo: string;
  largoestcta: string;
  public frameworkComponents;
  rowSelection = 'multiple';
  rowSelectionResumen;
  rowStyle;
  rowStyleEC;
  rowStyleSaldo;
  defaultCxcTrnCob;
  detalleSelected: Cxctrncobranzas;
  detallecobSelected:CxctrnCobresumen;
  cxctmptrnresumen: Cxctmptrnresumen[];
  cxctrnresumen: Cxctrnresumen[];
  //cxctrncobranzasService.cxctrcobnresumen:any[];
  defaultColDefCxctmptrnresumen;
  defaultColDefCxctrnresumen;
  defaultColDefCxcCobtrnresumen;
  spin = false;
  trncobro: string;
  clicodigo: string;
  secuncial: string;
  displayDialogCxcTrnHis = false
  //labels
  lblcliente: string;
  lbltelefono: string;
  lblsaldo;
  lblsaldoCli;
  lbldireccion: string;
  lblcorreo: string;
  lblvendedor: string;
  lblobservacion: string;
  lblcontacto:string;
  lblclicodigo:string;
  //paneles
  index: number;
  //comboorderby
  opcionesOrderBy: any[];
  selecttipoorderby: any;//CDPJ
  chkaplicaorderby:boolean;

  disabledorderby:boolean;

  //tabs
  bolestcta: boolean;
  bolsaldo: boolean;
  bolresumen:boolean;
  diasabledtabs:boolean;
  diasabledtabsR:boolean;
  //botones
  botonBorrar:boolean=true;
  //checks
  valSelect = 'valSelect'
  //reportes
  displayDialogfrmcorreos:boolean;//CDPJ
  auxreporte: number = 0;
  strNombreForma: string;
  boolformscorreo:boolean
  booltotcxc:boolean;
  displayDialogeditdatoscli:boolean
    //ver facturas
    displayDialofencfac:boolean;
  columnDefsCxcTrnCob = [
    {
      headerName: 'S/N',
      field: 'SELECCIONADO',
      cellRendererFramework: AggridBanComponent,
      editable: (params) => {
        return false;
      },
      width: 50,
      onCellClicked: (params) => {
        this.seleccionchek(params.data); 
      }
    },
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', 
      editable: (params) => {
        return false;
      },cellEditor: 'cellPrueba', width: 89, cellStyle: { textAlign: 'left' },
      onCellDoubleClicked: (params) => {
        //this.abrirBanTrnBanEdit(params.data);
      }
    },
    {
      headerName: 'VENCE', field: 'TRNCOBRO_FECHAVENCE', editable: (params) => {
        return false;
      }, cellEditor: 'cellPrueba', width: 89, cellStyle: { textAlign: 'left' },
      onCellDoubleClicked: (params) => {
        // this.abrirBanTrnBanEdit(params.data);
      }
    },
    {
      headerName: 'DÍAS', field: 'NUM_DIAS', editable: (params) => {
        return false;
      }, cellEditor: 'cellPrueba', width: 60, cellStyle: { textAlign: 'left' },
      onCellDoubleClicked: (params) => {
        //this.abrirBanTrnBanEdit(params.data);
      }
    },
    {
      headerName: 'CLIENTE', field: 'CLI_CODIGO', editable: (params) => {
        return false;
      }, cellEditor: 'cellPrueba', width: 95,
      cellStyle: { textAlign: 'left' },
      onCellDoubleClicked: (params) => {
        //this.abrirBanTrnBanEdit(params.data);
      }
    },
    {
      headerName: 'NOMBRE', field: 'CLI_NOMBRE', editable: (params) => {
        return false;
      }, cellEditor: 'cellPrueba', width: 200, cellStyle: { textAlign: 'left' },
      onCellDoubleClicked: (params) => {
        // this.abrirBanTrnBanEdit(params.data);
      }
    },
    {
      headerName: 'VENDEDOR', field: 'VEN_CODIGO', editable: (params) => {
        return false;
      }, width: 85, cellEditor: 'cellPrueba', cellStyle: { textAlign: 'left' },
      onCellDoubleClicked: (params) => {
        //this.abrirBanTrnBanEdit(params.data);
      }
    },
    {
      headerName: 'FACTURA', field: 'TRNCOBRO_NRODOC', editable: (params) => {
        return false;
      }, cellEditor: 'cellPrueba', width: 150, cellStyle: { textAlign: 'left' },
      onCellDoubleClicked: (params) => {
        this.abrirVenencfacEdit(params.data);
      }
    },
    {
      headerName: 'TOTAL', field: 'TRNCOBRO_IMPORTE', editable: (params) => {
        return false;
      }, cellEditor: 'cellPrueba', width: 75, cellStyle: { textAlign: 'right' },
      onCellDoubleClicked: (params) => {
        ///this.abrirBanTrnBanEdit(params.data);
      }
    },
    {
      headerName: 'IVA', field: 'RETIVA', width: 65, editable: (params) => {
        return false;
      }, cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      onCellDoubleClicked: (params) => {
        ///this.abrirBanTrnBanEdit(params.data);
      }
    },
    {
      headerName: 'RENTA', field: 'RETRENTA', editable: (params) => {
        return false;
      }, cellEditor: 'cellPrueba', width: 65, cellStyle: { textAlign: 'right' },
      onCellDoubleClicked: (params) => {
        // this.abrirBanTrnBanEdit(params.data);
      }
    },
    {
      headerName: 'ABONO', field: 'TRNCOBRO_ABONO', editable: (params) => {
        return false;
      }, cellEditor: 'cellPrueba', width: 65, cellStyle: { textAlign: 'right' },
      onCellDoubleClicked: (params) => {
        //this.abrirBanTrnBanEdit(params.data);
      }
    },
    {
      headerName: 'SALDO', field: 'TRNCOBRO_SALDO', editable: (params) => {
        return false;
      }, cellEditor: 'cellPrueba', width: 65, cellStyle: { textAlign: 'right' },
      onCellDoubleClicked: (params) => {
        //this.abrirBanTrnBanEdit(params.data);
      }
    },{
      headerName: 'ORIGEN', field: 'TRNCOBRO_TIPODOC', editable: (params) => {
        return false;
      }, cellEditor: 'cellPrueba', width: 65,
      onCellDoubleClicked: (params) => {
        //this.abrirBanTrnBanEdit(params.data);
      }
    },
    {
      headerName: 'LLAMADA', field: 'FECHA_ULTLLAMDA', editable: (params) => {
        return false;
      }, cellEditor: 'cellPrueba', width: 80, cellStyle: { textAlign: 'right' },
      onCellDoubleClicked: (params) => {
        this.abrirCxcTrnedithis(params.data);
      }
    },
    {
      headerName: 'COMENTARIO', field: 'COMENTARIO', cellEditor: 'cellPrueba', width: 200,
      onCellDoubleClicked: (params) => {
        this.abrirCxcTrnedithis(params.data);
      }
    }
  ];
  columnDefsCxctmptrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', editable: (params) => {
        return false;
      }, width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC', editable: (params) => {
        return false;
      },
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', editable: (params) => {
        return false;
      }, width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', editable: (params) => {
        return false;
      }, width: 200
    },
    {
      headerName: 'Debe', field: 'DEBE',  editable: (params) => {
        return false;
      },cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Haber', field: 'HABER', editable: (params) => {
        return false;
      }, cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Saldo', field: 'SALDO', editable: (params) => {
        return false;
      }, cellStyle: { textAlign: 'right' }
    },
  ];

  columnDefsCxctrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', editable: (params) => {
        return false;
      }, width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC', editable: (params) => {
        return false;
      },
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', editable: (params) => {
        return false;
      }, width: 200
    },
    {
      headerName: 'Fecha Vence', field: 'TRNCOBRO_FECHAVENCE', editable: (params) => {
        return false;
      }, width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', editable: (params) => {
        return false;
      }, width: 200
    },
    {
      headerName: 'Importe', field: 'TRNCOBRO_IMPORTE', editable: (params) => {
        return false;
      }, cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Pagado', field: 'PAGADO', editable: (params) => {
        return false;
      }, cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Saldo', field: 'SALDO', editable: (params) => {
        return false;
      }, cellStyle: { textAlign: 'right' }
    },
  ];
  columnDefsCxcCobtrnresumen = [
    {
      headerName: 'S/N',
      field: 'SELECCIONADO',
      cellRendererFramework: AggridBanComponent,
      editable: () => {
        return false;
      },
      width: 50,
    },
    {
      headerName: 'Cod. Cliente', field: 'CLI_CODIGO', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 200
    },
    {
      headerName: 'Cliente', field: 'CLI_NOMBRE', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 300
    },
    {
      headerName: 'Saldo Total', field: 'DEUDATOTAL', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 150,cellStyle: { textAlign: 'right' }
    },
    
  ];
  constructor(private confirmationService: ConfirmationService,
    private busquedaSer: InvbusquedaService, private datePipe: DatePipe,
    private messageService: MessageService, private utilitariosService: UtilitariosService, private cliService: VenmaeclienteService,
    private auditoriagral: AuditoriagralService, private errorService: ErroresBaseDatosService,
    private confIniciales: ConfInicialesService, private permisosService: PermisosService, public usuario: Usuario,
    private init: NuevoComponentService, public cxctrncobranzasService: CxctrncobranzasService) {
    this.agTable();


  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.rowStyleEC = (params) => {
      if (params.data.TRNCOBRO_TIPO !== 'A') {
        if (params.data.TRNCOBRO_FECHATRN === '') {
          return { background: 'rgb( 255, 255, 170)' };
        } else {
          return { background: 'rgb( 196, 225, 255)' };
        }
      }
    };

    this.rowStyleSaldo = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if (params.data.TRNCOBRO_FECHATRN === '') {
        return { background: 'rgb( 255, 255, 170)' };
      }
    }
    this.defaultCxcTrnCob = {
      editable: false,
      objeto: 'cxctrncob',
      resizable: true
    };
    this.defaultColDefCxctmptrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctmptrnresumen',
      resizable: true
    };

    this.defaultColDefCxctrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctrnresumen',
      resizable: true
    };
    this.defaultColDefCxcCobtrnresumen= {
      editable: false,
      width: 100,
      objeto: 'cxctrcobnresumen',
    };
  }

  async ngOnInit() {

    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.largo = '250';
    this.largoestcta = '420';
    this.cxctrncobranzasService.trncobranza = [];
    this.chkaplicaorderby=false;
    this.disabledorderby=true;
    this.displayDialogfrmcorreos=false;
    this.displayDialogeditdatoscli=false;
    this.bolestcta = false;
    this.bolsaldo = false;
    this.bolresumen=false;
    this.diasabledtabs=true;
    this.diasabledtabsR=true;
    this.detalleSelected={}
    this.detallecobSelected={}
    this.botonBorrar=true;
    this.boolformscorreo=true
    //this.booltotcxc=true;
    await this.configsaldocartera()
    this.datosini();
    this.llenarcombos();
    this.index=0;
    setTimeout(() => {
      document.getElementById('contorno').style.pointerEvents = 'all';
    }, 2000);
  }


  ngOnDestroy(): void {
    this.confIniciales.instanciasCobranza--;
  }
  async configsaldocartera(){
    const data = await this.cxctrncobranzasService.ervrtotcxc(this.usuario.identificacion);
    if (data !== null && data !== undefined) {
      for (const cfgValor of data) {

        //if (cfgValor.USU_VERTOTCXC === null || cfgValor.USU_VERTOTCXC === undefined) {
          //this.booltotcxc = true;
        //} else {
          this.booltotcxc = cfgValor.USU_VERTOTCXC === 1?true:false;
        //}
      }
    }
  console.log( this.booltotcxc)
  }
  async manejarSenales(valor) {
    
    if (valor === 'Cancelar') {
      if(this.index === 0){
        document.getElementById('contorno').style.pointerEvents = 'all';
      await this.consultarCobranza();
      this.botonBorrar=false;
     this.diasabledtabsR=false;
      }
      if (this.index === 3) {
        let arrayresumen:CxctrnCobresumen[]=[]
          this.cxctrncobranzasService.cxctrcobnresumen = [];
          this.bolresumen = true;
          arrayresumen=await this.cliService.getInfoResumen(
            'cxcCobranzas',this.usuario.identificacion);
          this.cxctrncobranzasService.cxctrcobnresumen = arrayresumen
          this.cxctrncobranzasService.cxctrcobnresumen.map(val=>{
            val.SELECCIONADO=false;
          })
      }
    }
    if (valor === 'Nuevo'){
      this.displayDialogeditdatoscli=true;
    }
    if (valor === 'Borrar') {
      this.eliminar();
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
        //CDPJ
        if (valor === 'FormularioCorreos'){
          this.strNombreForma = this.init.tabs[this.init.tabindex].component;
          this.auxreporte++;
          this.displayDialogfrmcorreos=true
        }
        //CDPJ
  }
  seleccionRol(){
    let selectbool=false
    for (const rol of this.cxctrncobranzasService.trncobranza){
      if(rol.SELECCIONADO === true){
        return selectbool=true;
      }
    }
    return selectbool;
  }
  seleccionCli(){
    let selectbool=false
    for (const rol of this.cxctrncobranzasService.cxctrcobnresumen){
      if(rol.SELECCIONADO === true){
        return selectbool=true;
      }
    }
    return selectbool;

  }
  eliminar() {

     let intIndiceFila=0
    //     intIndiceFila = this.cxctrncobranzasService.trncobranza.findIndex(distri =>
    //       distri.TRNCOBRO_NRODOC === this.detalleSelected.TRNCOBRO_NRODOC);
    // let item: Cxctrncobranzas;
    // if (intIndiceFila !== -1) {
    //   item = this.cxctrncobranzasService.trncobranza[intIndiceFila];
    // } else {
    //   this.messageService.add({
    //     key: 'edittrn',
    //     severity: 'error',
    //     summary: 'Error',
    //     detail: '! Seleccione una fila.... !'
    //   });
    //   return;
    // }

    if(this.seleccionRol() === true){
      this.confirmationService.confirm({
        message: 'Está seguro de eliminar los registros seleccionados?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'cxccobro',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: async () => {
          try {
            this.spin=true;
          for (const item of this.cxctrncobranzasService.trncobranza){
              if(item.SELECCIONADO === true){               
                intIndiceFila = this.cxctrncobranzasService.trncobranza.findIndex(distri =>
                distri.TRNCOBRO_NRODOC === item.TRNCOBRO_NRODOC);
                await this.cxctrncobranzasService.eliminarDetCob(item,this.usuario.identificacion).toPromise()          
                // this.cxctrncobranzasService.trncobranza.splice(intIndiceFila, 1);                      
            }
            // this.aggrid.refreshaggrid(this.cxctrncobranzasService.trncobranza, this.defaultCxcTrnCob.objeto);     
          }
          
          // if(this.cxctrncobranzasService.trncobranza.length>0){
          //   this.detalleSelected=this.cxctrncobranzasService.trncobranza[intIndiceFila-1];
          //   this.diasabledtabs=false;
          // }
          this.spin=false;
          this.messageService.add({
            key: 'cxcCobranzas',
           severity: 'success',
            summary: 'Información',
            detail: 'Se eliminó con éxito'
          });
          this.datosini();
          await this.consultarCobranzaTmp();
          this.aggrid.refreshaggrid(this.cxctrncobranzasService.trncobranza, this.defaultCxcTrnCob.objeto);
          //await this.sumatoria();
          this.aggrid.refreshaggrid(this.cxctrncobranzasService.trncobranza, this.defaultCxcTrnCob.objeto);
          } catch (error) {
            this.spin=false;
          }
          
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    }else{
        this.messageService.add({
          key: 'cxcCobranzas',
          severity: 'info',
        summary: 'Error',
        detail: 'Seleccione un registro'
      });
      return;
    }
    
  }
  seleccionItem(valor) {
    if (valor.object !== undefined) {
      if (this.cxctrncobranzasService.trncobranza.length === 0) {
        return;
      }
      this.detalleSelected = valor.object;
      this.cargarFcaCliente(this.detalleSelected);
      this.diasabledtabs=false;
    }
  }
  seleccionchek(params:Cxctrncobranzas){
    console.log(params.SELECCIONADO)
    
  }
  seleccionchekResumen(params){
    console.log(params)
    // for (const rol of this.cxctrncobranzasService.cxctrcobnresumen){
    //   if(params.SELECCIONADO === true && rol.CLI_CODIGO === params.CLI_CODIGO){
    //   }else{
    //     params.SELECCIONADO=false;
    //   }
    // }
    this.aggrid.refreshColumnDefs();
  }
  seleccionItemCob(params) {
    let num=0;
    //if (valor.object !== undefined) {
      if (this.cxctrncobranzasService.trncobranza.length === 0) {
        return;
      }

      console.log('params',params.object)
      console.log(this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +this.defaultColDefCxcCobtrnresumen.objeto].getFocusedCell())
      console.log('params2',params.object.CLI_CODIGO)
      this.cxctrncobranzasService.cxctrcobnresumen.map(variable => {
        if(this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefCxcCobtrnresumen.objeto].getFocusedCell().column.colId === 'SELECCIONADO'){
          if (variable.SELECCIONADO === true && 
           //( num === this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefCxcCobtrnresumen.objeto].getFocusedCell().rowIndex 
           //&& 
           variable.CLI_CODIGO === params.object.CLI_CODIGO
           //)
           ) {
            variable.SELECCIONADO = true;
            this.detallecobSelected = variable;
            console.log('var',variable.CLI_CODIGO)
            this.boolformscorreo=false;
          } else { 
            variable.SELECCIONADO = false;
            //this.boolformscorreo=true;
          }
          num=num+1;
        }
       
    });
    if(this.seleccionCli() === true){
      this.boolformscorreo=false;
    }else{
      this.boolformscorreo=true;
    }
    console.log(this.cxctrncobranzasService.cxctrcobnresumen[this.cxctrncobranzasService.cxctrcobnresumen.indexOf(this.detallecobSelected)])
    //this.aggrid.refreshaggrid(this.cxctrncobranzasService.cxctrcobnresumen[this.cxctrncobranzasService.cxctrcobnresumen.indexOf(this.detallecobSelected)], this.defaultColDefCxcCobtrnresumen.objeto);
    //this.aggrid.refreshColumnDefsIndice(this.cxctrncobranzasService.cxctrcobnresumen.indexOf(this.detallecobSelected),this.defaultColDefCxcCobtrnresumen.objeto,this.columnDefsCxcCobtrnresumen)
    this.aggrid.refreshaggridindex(this.cxctrncobranzasService.cxctrcobnresumen,this.defaultColDefCxcCobtrnresumen.objeto,this.cxctrncobranzasService.cxctrcobnresumen.indexOf(this.detallecobSelected))
    
    this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +this.defaultColDefCxcCobtrnresumen.objeto].setFocusedCell( this.cxctrncobranzasService.cxctrcobnresumen.indexOf(this.detallecobSelected), 'CLI_CODIGO')
    //this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +this.defaultColDefCxcCobtrnresumen.objeto].refreshColumnDefs();
      
      this.cargarFcaClienteTot(this.detallecobSelected);
      this.diasabledtabs=false;
    //}
  }
  async cargarFcaCliente(dato) {
    try {
      let ven_nombre='';
      const infocli = await this.cxctrncobranzasService.informacioncli(dato.CLI_CODIGO)
      const infoven = await this.cxctrncobranzasService.informacionven(dato.VEN_CODIGO)
      ven_nombre=infoven === null || infoven === undefined|| infoven[0] === undefined || infoven[0] === null?'':infoven[0].VEN_NOMBRE;
      this.lblcliente = dato.CLI_NOMBRE
      this.lbltelefono = infocli[0].CLI_TELEFONO1 === null || infocli[0].CLI_TELEFONO1 === undefined ?'':String(infocli[0].CLI_TELEFONO1) 
      this.lbltelefono = this.lbltelefono + (infocli[0].CLI_TELEFONO2 === null || infocli[0].CLI_TELEFONO2 === undefined ? '' : '-' + String(infocli[0].CLI_TELEFONO2))
      //this.lblsaldo = dato.TRNCOBRO_SALDO
     this.sumatoriasaldocliente(dato);
      this.lblcontacto=infocli[0].CLI_CONTACTO === null || infocli[0].CLI_CONTACTO === undefined?'':infocli[0].CLI_CONTACTO
      this.lbldireccion = infocli[0].CLI_DIRECCION1 === null || infocli[0].CLI_DIRECCION1 === undefined?'':infocli[0].CLI_DIRECCION1;
      this.lblcorreo = infocli[0].CLI_CORREO === null || infocli[0].CLI_CORREO === undefined?'':infocli[0].CLI_CORREO;
      this.lblvendedor = ven_nombre === null || ven_nombre === undefined?'':ven_nombre;
      this.lblobservacion = dato.TRNHIS_COMENTARIO
      this.lblclicodigo=dato.CLI_CODIGO === null || dato.CLI_CODIGO === undefined?'':dato.CLI_CODIGO;
    } catch (error) {
      console.log(error)
    }

  }

  sumatoriasaldocliente(dato){
    this.lblsaldoCli=0;
      for (const cob of this.cxctrncobranzasService.trncobranza) {
        if(cob.CLI_CODIGO === dato.CLI_CODIGO){
          this.lblsaldoCli=Number(this.lblsaldoCli)+Number(cob.TRNCOBRO_SALDO); 
        }         
      }
      console.log(this.lblsaldoCli)
      this.lblsaldoCli=Number(this.lblsaldoCli).toFixed(this.confIniciales.getNumDecSist());
      console.log(this.lblsaldoCli)
      this.lblsaldoCli= this.init.moneyValidation(this.lblsaldoCli);
      console.log(this.lblsaldoCli)
  }
  async cargarFcaClienteTot(dato) {
    try {
      let ven_nombre='';
      const infocli = await this.cxctrncobranzasService.informacioncli(dato.CLI_CODIGO)
      const infoven = await this.cxctrncobranzasService.informacionven(dato.VEN_CODIGO)
      ven_nombre=infoven === null || infoven === undefined|| infoven[0] === undefined || infoven[0] === null?'':infoven[0].VEN_NOMBRE;
      this.lblcliente = dato.CLI_NOMBRE
      this.lbltelefono = infocli[0].CLI_TELEFONO1 === null || infocli[0].CLI_TELEFONO1 === undefined ?'':String(infocli[0].CLI_TELEFONO1) 
      this.lbltelefono = this.lbltelefono + (infocli[0].CLI_TELEFONO2 === null || infocli[0].CLI_TELEFONO2 === undefined ? '' : '-' + String(infocli[0].CLI_TELEFONO2))
      //this.lblsaldo = dato.TRNCOBRO_SALDO
      this.lblsaldoCli=dato.DEUDATOTAL
      this.lblsaldoCli=this.init.moneyValidation(this.lblsaldoCli);
      this.lblcontacto=infocli[0].CLI_CONTACTO === null || infocli[0].CLI_CONTACTO === undefined?'':infocli[0].CLI_CONTACTO;
      this.lbldireccion = infocli[0].CLI_DIRECCION1 === null || infocli[0].CLI_DIRECCION1 === undefined?'':infocli[0].CLI_DIRECCION1;
      this.lblcorreo = infocli[0].CLI_CORREO === null || infocli[0].CLI_CORREO === undefined?'':infocli[0].CLI_CORREO;
      this.lblvendedor = ven_nombre === null || ven_nombre === undefined?'':ven_nombre;
      this.lblobservacion = dato.TRNHIS_COMENTARIO
      this.lblclicodigo=dato.CLI_CODIGO === null || dato.CLI_CODIGO === undefined?'':dato.CLI_CODIGO;
    } catch (error) {
      console.log(error)
    }

  }
  async changeTabs() { 
    if(this.index === 0){
      this.bolestcta = false;
    this.bolsaldo = false;
    this.bolresumen=false;
    this.botonBorrar=false;
    this.boolformscorreo=true;
    }
    if (this.index === 1) {
      if (this.bolestcta === false) {
        this.cxctmptrnresumen = [];
        this.bolestcta = true;
        this.botonBorrar=true;
        this.cxctmptrnresumen = await this.cliService.getInfoEstadoCuenta(this.detalleSelected.CLI_CODIGO,
          'cxcCobranzas'); 
          this.boolformscorreo=true;     
      }
    }
    if (this.index === 2) {
      if (this.bolsaldo === false) {
        this.cxctrnresumen = [];
        this.bolsaldo = true;
        this.botonBorrar=true;
        this.cxctrnresumen = await this.cliService.getInfoSaldos(this.detalleSelected.CLI_CODIGO,
          'cxcCobranzas');  
          this.boolformscorreo=true;
      }
    }
    if (this.index === 3) {
      document.getElementById('contorno2').style.pointerEvents = 'none';
      if (this.bolresumen === false) {
        let arrayresumen:CxctrnCobresumen[]=[]
        this.cxctrncobranzasService.cxctrcobnresumen = [];
        this.bolresumen = true;
        arrayresumen=await this.cliService.getInfoResumen(
          'cxcCobranzas',this.usuario.identificacion);
        this.cxctrncobranzasService.cxctrcobnresumen = arrayresumen
        this.cxctrncobranzasService.cxctrcobnresumen.map(val=>{
          val.SELECCIONADO=false;
        })
      }
      this.botonBorrar=true;
    }
  }
  datosini() {
    this.lblcliente = '';
    this.lbltelefono = '';
    this.lblsaldo = Number(Number(0).toFixed(this.confIniciales.getNumDecSist()));
    this.lblsaldoCli= Number(Number(0).toFixed(this.confIniciales.getNumDecSist()));
    this.lbldireccion = '';
    this.lblcorreo = '';
    this.lblvendedor = '';
    this.lblobservacion = '';
    this.lblcontacto='';
    this.lblclicodigo='';
    this.bolestcta = false;
    this.bolsaldo = false;
    this.bolresumen=false;
  }
  async consultarCobranza() {
    
    if(this.chkaplicaorderby === true){
      this.aplicarorderby()
    }
    if(this.chkaplicaorderby === false){
      this.spin = true;
    this.cxctrncobranzasService.trncobranza = [];
    this.datosini();
    await this.cxctrncobranzasService.llenartrnccobranza(this.usuario.identificacion);
    const data = await this.cxctrncobranzasService.consulttrnccobranza(this.usuario.identificacion);
    this.cxctrncobranzasService.trncobranza = data
    //this.cxctrncobranzasService.trncobranza.map((cob) => {
    for (const cob of this.cxctrncobranzasService.trncobranza) {
      cob.TRNCOBRO_FECHATRN = this.datePipe.transform(cob.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
      cob.TRNCOBRO_FECHAVENCE = this.datePipe.transform(cob.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
      cob.TRNCOBRO_IMPORTE = cob.TRNCOBRO_IMPORTE.toFixed(this.confIniciales.getNumDecSist());
      cob.RETIVA = cob.RETIVA === null || cob.RETIVA === undefined || cob.RETIVA === '' ? Number(0).toFixed(this.confIniciales.getNumDecSist()) : cob.RETIVA;
      cob.RETRENTA = cob.RETRENTA === null || cob.RETRENTA === undefined || cob.RETRENTA === '' ? Number(0).toFixed(this.confIniciales.getNumDecSist()) : cob.RETIVA;
      cob.TRNCOBRO_ABONO = cob.TRNCOBRO_ABONO === null || cob.TRNCOBRO_ABONO === undefined ? Number(0).toFixed(this.confIniciales.getNumDecSist()) : cob.TRNCOBRO_ABONO;
      cob.TRNCOBRO_SALDO = cob.TRNCOBRO_SALDO === null || cob.TRNCOBRO_SALDO === undefined ? Number(0).toFixed(this.confIniciales.getNumDecSist()) : cob.TRNCOBRO_SALDO
      cob.RETIVA = Number(cob.RETIVA).toFixed(this.confIniciales.getNumDecSist());
      cob.RETRENTA = Number(cob.RETIVA).toFixed(this.confIniciales.getNumDecSist());
      cob.TRNCOBRO_ABONO = Number(cob.TRNCOBRO_ABONO).toFixed(this.confIniciales.getNumDecSist());
      cob.TRNCOBRO_SALDO = Number(cob.TRNCOBRO_SALDO).toFixed(this.confIniciales.getNumDecSist());
      cob.FECHA_ULTLLAMDA = this.datePipe.transform(cob.TRNHIS_FECHA, 'dd/MM/yyyy');
      cob.COMENTARIO = cob.TRNHIS_COMENTARIO === null || cob.TRNHIS_COMENTARIO === undefined ? '' : cob.TRNHIS_COMENTARIO;
      this.lblsaldo=Number(this.lblsaldo)+Number(cob.TRNCOBRO_SALDO);  
      cob.SELECCIONADO=false;    
    }
    this.lblsaldo=Number(this.lblsaldo).toFixed(this.confIniciales.getNumDecSist());
    this.lblsaldo=this.init.moneyValidation(this.lblsaldo);
    if(this.booltotcxc === false){
      this.lblsaldo = Number(Number(0).toFixed(this.confIniciales.getNumDecSist()));
    }
    this.aggrid.refreshColumnDefs();
    this.spin = false;
    }
  }
  abrirCxcTrnedithis(data) {
    this.trncobro = data.TRNCOBRO_NRODOC;
    this.clicodigo = data.CLI_CODIGO;
    this.secuncial = data.SECUENCIAL;
    this.displayDialogCxcTrnHis = true
  }
  async CerrarCxcTrnedithis(evento) {
    this.displayDialogCxcTrnHis = false;
    await this.consultarCobranzaTmp();
   
    
  }
  async recibirSenalesCxcEdithis(valor) {
    if (valor === true) {
      this.displayDialogCxcTrnHis = false;
      await this.consultarCobranzaTmp();
      
    }
  }
  async consultarCobranzaTmp(){
    
    if(this.chkaplicaorderby === true){
      this.aplicarorderbyTmp()
    }
    if(this.chkaplicaorderby === false){
      this.spin = true;
    this.cxctrncobranzasService.trncobranza = [];
    this.datosini();
    //await this.cxctrncobranzasService.llenartrnccobranza(this.usuario.identificacion);
    const data = await this.cxctrncobranzasService.consulttrnccobranza(this.usuario.identificacion);
    this.cxctrncobranzasService.trncobranza = data
    //this.cxctrncobranzasService.trncobranza.map((cob) => {
    for (const cob of this.cxctrncobranzasService.trncobranza) {
      cob.TRNCOBRO_FECHATRN = this.datePipe.transform(cob.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
      cob.TRNCOBRO_FECHAVENCE = this.datePipe.transform(cob.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
      cob.TRNCOBRO_IMPORTE = cob.TRNCOBRO_IMPORTE.toFixed(this.confIniciales.getNumDecSist());
      cob.RETIVA = cob.RETIVA === null || cob.RETIVA === undefined || cob.RETIVA === '' ? Number(0).toFixed(this.confIniciales.getNumDecSist()) : cob.RETIVA;
      cob.RETRENTA = cob.RETRENTA === null || cob.RETRENTA === undefined || cob.RETRENTA === '' ? Number(0).toFixed(this.confIniciales.getNumDecSist()) : cob.RETIVA;
      cob.TRNCOBRO_ABONO = cob.TRNCOBRO_ABONO === null || cob.TRNCOBRO_ABONO === undefined ? Number(0).toFixed(this.confIniciales.getNumDecSist()) : cob.TRNCOBRO_ABONO;
      cob.TRNCOBRO_SALDO = cob.TRNCOBRO_SALDO === null || cob.TRNCOBRO_SALDO === undefined ? Number(0).toFixed(this.confIniciales.getNumDecSist()) : cob.TRNCOBRO_SALDO
      cob.RETIVA = Number(cob.RETIVA).toFixed(this.confIniciales.getNumDecSist());
      cob.RETRENTA = Number(cob.RETIVA).toFixed(this.confIniciales.getNumDecSist());
      cob.TRNCOBRO_ABONO = Number(cob.TRNCOBRO_ABONO).toFixed(this.confIniciales.getNumDecSist());
      cob.TRNCOBRO_SALDO = Number(cob.TRNCOBRO_SALDO).toFixed(this.confIniciales.getNumDecSist());
      cob.FECHA_ULTLLAMDA = this.datePipe.transform(cob.TRNHIS_FECHA, 'dd/MM/yyyy');
      cob.COMENTARIO = cob.TRNHIS_COMENTARIO === null || cob.TRNHIS_COMENTARIO === undefined ? '' : cob.TRNHIS_COMENTARIO;
      this.lblsaldo=Number(this.lblsaldo)+Number(cob.TRNCOBRO_SALDO);      
      cob.SELECCIONADO=false;
    }
    console.log('a',this.lblsaldo)
    this.lblsaldo=Number(this.lblsaldo).toFixed(this.confIniciales.getNumDecSist());
    console.log('b',this.lblsaldo)
    this.lblsaldo=this.init.moneyValidation(this.lblsaldo);
    console.log('c',this.lblsaldo)
    if(this.booltotcxc === false){
      this.lblsaldo = Number(Number(0).toFixed(this.confIniciales.getNumDecSist()));
    }
    this.aggrid.refreshColumnDefs();
    this.spin = false;
    console.log('this.detalleSelected',this.detalleSelected)
    const indi =  this.cxctrncobranzasService.trncobranza.findIndex(distri =>
      distri.TRNCOBRO_NRODOC === this.detalleSelected.TRNCOBRO_NRODOC);
    console.log(indi)
    if (indi === -1) {
    } else {
      console.log('1')
      this.aggrid.gridApi.setFocusedCell(indi, 'FECHA_ULTLLAMDA');
      this.aggrid.refreshColumnDefs();
    }
    }
  }
  async sumatoria(){
    this.datosini();
    for (const cob of this.cxctrncobranzasService.trncobranza) {

      this.lblsaldo=Number(this.lblsaldo)+Number(cob.TRNCOBRO_SALDO);      
    }
    this.lblsaldo=Number(Number(this.lblsaldo).toFixed(this.confIniciales.getNumDecSist()));
    this.lblsaldo=this.init.moneyValidation(this.lblsaldo);
    if(this.booltotcxc === false){
      this.lblsaldo = Number(Number(0).toFixed(this.confIniciales.getNumDecSist()));
    }
    console.log(this.lblsaldo);
  }
  llenarcombos() {
    this.opcionesOrderBy = [{ codigo: '', name: '' },
    { codigo: '01', name: 'Nombre del cliente + Fecha de emisión' },
    { codigo: '02', name: 'Fecha de emisión + Nombre del cliente' },
    { codigo: '03', name: 'Días + Nombre del cliente' }
    ];
    this.selecttipoorderby={ codigo: '', name: '' };
  }
  busqorderby(select){
    if(select.value.codigo === ''){
      this.chkaplicaorderby=false
      this.disabledorderby=true
    }else{
      this.disabledorderby=false
    }
    this.chkaplicaorderby=false
  }

  async aplicarorderby() {
    this.botonBorrar=false;
    let orderby = ''
    if (this.selecttipoorderby.codigo === '01') {
      orderby = 'order by CLI_NOMBRE asc,TRNCOBRO_FECHATRN desc'
    } else if (this.selecttipoorderby.codigo === '02') {
      orderby = 'order by TRNCOBRO_FECHATRN desc, CLI_NOMBRE asc'
    } else if (this.selecttipoorderby.codigo === '03') {
      orderby = ' order by NUM_DIAS desc, CLI_NOMBRE asc'
    } else {
      this.messageService.add({
        key: 'cxcCobranzas',
        severity: 'info',
        summary: 'Información',
        detail: 'Seleccione una de las opciones para ordenar.'
      });
      this.chkaplicaorderby=false;
      return;
    }
    try {
    this.spin = true;
    this.cxctrncobranzasService.trncobranza = [];
    this.datosini();
    await this.cxctrncobranzasService.llenartrnccobranza(this.usuario.identificacion);
      const data = await this.cxctrncobranzasService.consulttrnccobranzaorder(this.usuario.identificacion, orderby);
      this.cxctrncobranzasService.trncobranza = data
      //this.cxctrncobranzasService.trncobranza.map((cob) => {
      for (const cob of this.cxctrncobranzasService.trncobranza) {
        cob.TRNCOBRO_FECHATRN = this.datePipe.transform(cob.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
        cob.TRNCOBRO_FECHAVENCE = this.datePipe.transform(cob.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
        cob.TRNCOBRO_IMPORTE = cob.TRNCOBRO_IMPORTE.toFixed(this.confIniciales.getNumDecSist());
        cob.RETIVA = cob.RETIVA === null || cob.RETIVA === undefined || cob.RETIVA === '' ? Number(0).toFixed(this.confIniciales.getNumDecSist()) : cob.RETIVA;
        cob.RETRENTA = cob.RETRENTA === null || cob.RETRENTA === undefined || cob.RETRENTA === '' ? Number(0).toFixed(this.confIniciales.getNumDecSist()) : cob.RETIVA;
        cob.TRNCOBRO_ABONO = cob.TRNCOBRO_ABONO === null || cob.TRNCOBRO_ABONO === undefined ? Number(0).toFixed(this.confIniciales.getNumDecSist()) : cob.TRNCOBRO_ABONO;
        cob.TRNCOBRO_SALDO = cob.TRNCOBRO_SALDO === null || cob.TRNCOBRO_SALDO === undefined ? Number(0).toFixed(this.confIniciales.getNumDecSist()) : cob.TRNCOBRO_SALDO

        cob.RETIVA = Number(cob.RETIVA).toFixed(this.confIniciales.getNumDecSist());
        cob.RETRENTA = Number(cob.RETIVA).toFixed(this.confIniciales.getNumDecSist());
        cob.TRNCOBRO_ABONO = Number(cob.TRNCOBRO_ABONO).toFixed(this.confIniciales.getNumDecSist());
        cob.TRNCOBRO_SALDO = Number(cob.TRNCOBRO_SALDO).toFixed(this.confIniciales.getNumDecSist());
        //const hist=await this.cxctrncobranzasService.consulttrnchistorial(cob.TRNCOBRO_NRODOC,cob.CLI_CODIGO,cob.SECUENCIAL);
        //if(hist.length>0){
        cob.FECHA_ULTLLAMDA = this.datePipe.transform(cob.TRNHIS_FECHA, 'dd/MM/yyyy');
        cob.COMENTARIO = cob.TRNHIS_COMENTARIO === null || cob.TRNHIS_COMENTARIO === undefined ? '' : cob.TRNHIS_COMENTARIO;
        this.lblsaldo=Number(this.lblsaldo)+Number(cob.TRNCOBRO_SALDO);   
        cob.SELECCIONADO=false;
        //}
        //console.log(hist)
        //console.log('1')
      }//);
      this.lblsaldo=Number(this.lblsaldo).toFixed(this.confIniciales.getNumDecSist());
      this.lblsaldo=this.init.moneyValidation(this.lblsaldo);
      if(this.booltotcxc === false){
        this.lblsaldo = Number(Number(0).toFixed(this.confIniciales.getNumDecSist()));
      }
      this.aggrid.refreshColumnDefs();
      this.spin = false;
    } catch (error) {

    }
  }
  async aplicarorderbyTmp() {
    this.botonBorrar=false;
    let orderby = ''
    if (this.selecttipoorderby.codigo === '01') {
      orderby = 'order by CLI_NOMBRE asc,TRNCOBRO_FECHATRN desc'
    } else if (this.selecttipoorderby.codigo === '02') {
      orderby = 'order by TRNCOBRO_FECHATRN desc, CLI_NOMBRE asc'
    } else if (this.selecttipoorderby.codigo === '03') {
      orderby = ' order by NUM_DIAS desc, CLI_NOMBRE asc'
    } else {
      this.messageService.add({
        key: 'cxcCobranzas',
        severity: 'info',
        summary: 'Información',
        detail: 'Seleccione una de las opciones para ordenar.'
      });
      this.chkaplicaorderby=false;
      return;
    }
    try {
    this.spin = true;
    this.cxctrncobranzasService.trncobranza = [];
    this.datosini();
    //await this.cxctrncobranzasService.llenartrnccobranza(this.usuario.identificacion);
      const data = await this.cxctrncobranzasService.consulttrnccobranzaorder(this.usuario.identificacion, orderby);
      this.cxctrncobranzasService.trncobranza = data
      //this.cxctrncobranzasService.trncobranza.map((cob) => {
      for (const cob of this.cxctrncobranzasService.trncobranza) {
        cob.TRNCOBRO_FECHATRN = this.datePipe.transform(cob.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
        cob.TRNCOBRO_FECHAVENCE = this.datePipe.transform(cob.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
        cob.TRNCOBRO_IMPORTE = cob.TRNCOBRO_IMPORTE.toFixed(this.confIniciales.getNumDecSist());
        cob.RETIVA = cob.RETIVA === null || cob.RETIVA === undefined || cob.RETIVA === '' ? Number(0).toFixed(this.confIniciales.getNumDecSist()) : cob.RETIVA;
        cob.RETRENTA = cob.RETRENTA === null || cob.RETRENTA === undefined || cob.RETRENTA === '' ? Number(0).toFixed(this.confIniciales.getNumDecSist()) : cob.RETIVA;
        cob.TRNCOBRO_ABONO = cob.TRNCOBRO_ABONO === null || cob.TRNCOBRO_ABONO === undefined ? Number(0).toFixed(this.confIniciales.getNumDecSist()) : cob.TRNCOBRO_ABONO;
        cob.TRNCOBRO_SALDO = cob.TRNCOBRO_SALDO === null || cob.TRNCOBRO_SALDO === undefined ? Number(0).toFixed(this.confIniciales.getNumDecSist()) : cob.TRNCOBRO_SALDO

        cob.RETIVA = Number(cob.RETIVA).toFixed(this.confIniciales.getNumDecSist());
        cob.RETRENTA = Number(cob.RETIVA).toFixed(this.confIniciales.getNumDecSist());
        cob.TRNCOBRO_ABONO = Number(cob.TRNCOBRO_ABONO).toFixed(this.confIniciales.getNumDecSist());
        cob.TRNCOBRO_SALDO = Number(cob.TRNCOBRO_SALDO).toFixed(this.confIniciales.getNumDecSist());
        //const hist=await this.cxctrncobranzasService.consulttrnchistorial(cob.TRNCOBRO_NRODOC,cob.CLI_CODIGO,cob.SECUENCIAL);
        //if(hist.length>0){
        cob.FECHA_ULTLLAMDA = this.datePipe.transform(cob.TRNHIS_FECHA, 'dd/MM/yyyy');
        cob.COMENTARIO = cob.TRNHIS_COMENTARIO === null || cob.TRNHIS_COMENTARIO === undefined ? '' : cob.TRNHIS_COMENTARIO;
        this.lblsaldo=Number(this.lblsaldo)+Number(cob.TRNCOBRO_SALDO);  
        cob.SELECCIONADO=false; 
        //}
        //console.log(hist)
        //console.log('1')
      }//);
      this.lblsaldo=Number(this.lblsaldo).toFixed(this.confIniciales.getNumDecSist());
      this.lblsaldo=this.init.moneyValidation(this.lblsaldo);
      if(this.booltotcxc === false){
        this.lblsaldo = Number(Number(0).toFixed(this.confIniciales.getNumDecSist()));
      }
      this.aggrid.refreshColumnDefs();
      this.spin = false;
    } catch (error) {

    }
  }
  seleccionarall(event){
    if( event.checked ){
      this.cxctrncobranzasService.trncobranza.map( rol => {
        rol.SELECCIONADO = true;
      } )
    } else {
      this.cxctrncobranzasService.trncobranza.map( rol => {
        rol.SELECCIONADO = false;
      } )
    }
    this.aggrid.refreshaggrid(this.cxctrncobranzasService.trncobranza, this.defaultCxcTrnCob.objeto);
  }
  cerrarfrmcorreos(){
    this.displayDialogfrmcorreos=false;
  }
  abrirVenencfacEdit(params){
    if(params.TRNCOBRO_TIPODOC === 'FC'){
      this.displayDialofencfac=true
    }
  }
  cerrareditdatoscli(){
    this.displayDialogeditdatoscli=false;
  }
  cerrarencfac(){
    this.displayDialofencfac=false
  }
}
