import { Component, OnInit, ViewChild } from '@angular/core';
import { ActmaepolizaService } from '../actservicios/actmaepoliza.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { PermisosService } from 'src/app/advantage/advantageservices/permisos.service';
import { MessageService, ConfirmationService } from 'primeng';
import { Usuario } from 'src/app/usuario';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { DatePipe } from '@angular/common';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { ActMaePoliza } from '../actinterfaces/actmaepoliza';
import { SegMaePermiso } from 'src/app/advantage/advantageinterfaces/segmaepermiso';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';

@Component({
  selector: 'app-actmaepoliza',
  templateUrl: './actmaepoliza.component.html',
  styleUrls: ['./actmaepoliza.component.css']
})
export class ActmaepolizaComponent implements OnInit {

  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  detallePolizaSelect: ActMaePoliza = {};
  indicador: any;

  // Permisos
  permisos: SegMaePermiso;

  public frameworkComponents;
  rowStyle;
  defaultActMaePoliza;
  rowSelection = 'multiple';
  largo: string;
  ancho: string;

  columnDefActMaePoliza = [
    {
      headerName: 'Código.', field: 'POL_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 25,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: 'Número', field: 'POL_NUMERO', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 100,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: 'Empresa', field: 'POL_EMPRESA', cellEditor: 'cellPrueba', width: 350, editable:true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 100,
            tienecalculos: true,
          }
        }
    }, 
    {
      headerName: 'FechaIni', field: 'POL_FECHAINI', cellEditor: 'cellPrueba', editable: true, width: 100, cellStyle: {textAlign: 'left'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: true,
            tamanocelda: 80,
          }
        }
    },
    {
      headerName: 'FechaFin', field: 'POL_FECHAFIN', cellEditor: 'cellPrueba', editable: true, width: 100, cellStyle: {textAlign: 'left'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: true,
            tamanocelda: 80,
          }
        }
    },
  ]

  constructor(public actmaepolizaService:ActmaepolizaService, private auditoriaGralService: AuditoriagralService,
    private permisosService: PermisosService, private message: MessageService, public usuario: Usuario,
    private errorService: ErroresBaseDatosService, private busquedaSer: InvbusquedaService,
    private datePipe: DatePipe, private confirmationService: ConfirmationService, 
    private confIniciales: ConfInicialesService, private init: NuevoComponentService) { 
      this.actmaepolizaService.detmaePolizaArray[this.indicador] = [];
      this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.defaultActMaePoliza = {
      editable: true,
      width: 100,
      objeto: 'actmaepoliza',
      resizable: true
    };
  }

  async ngOnInit() {
    this.largo = '360';
    this.ancho = '60';
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.actmaepolizaService.detmaePolizaArray[this.indicador] = [];

    this.actmaepolizaService.btnGuardar[this.indicador] = true;
    this.actmaepolizaService.btnNuevo[this.indicador] = false;
    this.actmaepolizaService.btnBorrar[this.indicador] = false;
    this.actmaepolizaService.btnBuscar[this.indicador]= false;

    await this.listarDatos();
  }

  async manejarSenales(valor) {
    
    if (valor === 'Nuevo') {
      this.nuevoRegistro();
    
    }
    if (valor === 'Borrar') {
      this.eliminarRegistro();
    }
    if (valor === 'Guardar') {
     this.guardarRegistro();
      
    }
    if (valor === 'Cancelar') {
     this.listarDatos();
     this.activaBotones('G',true,false,false,true,true,true,true,true,true,true);
    }
    if (valor === 'Buscar') {
     
    }

    if (valor === 'Salir') {
     
      this.init.tabs.splice(this.init.tabindex, 1);
          
    }

  }

  eliminarRegistro() {
    if (Object.keys(this.detallePolizaSelect).length > 0) {
      this.confirmEliminar();
    } else {
      this.mensajeAlerta('error', 'Error', '! Seleccione una fila.... !');
    }
  }

  confirmEliminar() {
    this.confirmationService.confirm({
      message: '¿ Está seguro de eliminar el registro ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'actmaepolizaConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarDetalleSel();
      },
      reject: () => {
      }
    });
  }

  async eliminarDetalleSel() {
    const params:any[] = [];
    params[0] = this.detallePolizaSelect.POL_CODIGO;
    try {
      if (this.detallePolizaSelect.COD_ANTERIOR !== '' ) {
        await this.actmaepolizaService.eliminarRegistro(params);
        this.auditoriaGralService.registrarAuditoria('ACT_MAEPOLIZA', this.detallePolizaSelect.POL_CODIGO, 'E',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
             });
		  }
			this.mensajeAlerta('success', 'Información', 'Registro eliminado exitosamente....!!!')
      this.actmaepolizaService.detmaePolizaArray[this.indicador] = this.actmaepolizaService.detmaePolizaArray[this.indicador].filter((val, j) => j !==
      this.actmaepolizaService.detmaePolizaArray[this.indicador].indexOf(this.detallePolizaSelect));
      this.aggrid.refreshaggrid(this.actmaepolizaService.detmaePolizaArray[this.indicador], this.defaultActMaePoliza.objeto);

			this.activaBotones('G',true,false,false,true,true,true,true,true,true,true);

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
      return false;
    }

    return true;
  }

  async guardarRegistro() {
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultActMaePoliza.objeto].stopEditing();
    const Parametros: any[] = [];
    Parametros[0]=this.detallePolizaSelect.POL_CODIGO;
		Parametros[1]=this.detallePolizaSelect.POL_NUMERO;
		Parametros[2]=this.detallePolizaSelect.POL_EMPRESA;
		Parametros[3]=this.detallePolizaSelect.POL_FECHAINI;			
		Parametros[4]=this.detallePolizaSelect.POL_FECHAFIN;			
    Parametros[5]='01';
    
    try {

      if (this.detallePolizaSelect.COD_ANTERIOR === '')	{
					if (this.faltanCamposDet()===false) {
            return false;
          }
        
          await this.actmaepolizaService.insertarRegistro(Parametros);
					this.auditoriaGralService.registrarAuditoria('ACT_MAEPOLIZA', this.detallePolizaSelect.POL_CODIGO, 'I',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
             });
          const indi = this.actmaepolizaService.detmaePolizaArray[this.indicador].indexOf(this.detallePolizaSelect);
          this.actmaepolizaService.detmaePolizaArray[this.indicador][indi].IS_NEW_ROW = false;
          this.actmaepolizaService.detmaePolizaArray[this.indicador][indi].COD_ANTERIOR = this.detallePolizaSelect.POL_CODIGO;

          this.activaBotones('G',true,false,false,true,true,true,true,true,true,true);
          this.mensajeAlerta('success', 'Información', 'Registro guardado exitosamente');
      } else {
					if (this.detallePolizaSelect.COD_ANTERIOR !== '')	{
						if (this.faltanCamposDet()===false) {
              return false;
            }

            await this.actmaepolizaService.actualizarRegistro(Parametros);
					  this.auditoriaGralService.registrarAuditoria('ACT_MAEPOLIZA', this.detallePolizaSelect.POL_CODIGO, 'A',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
             });
            
             this.activaBotones('G',true,false,false,true,true,true,true,true,true,true);
             this.mensajeAlerta('success', 'Información', 'Registro actualizado exitosamente');
					}
				}

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
      return false;
    }

    return true;

  }

  faltanCamposDet() {
		let intIndiceLista=0;
		let item: ActMaePoliza = {};

		
		for (intIndiceLista=0;intIndiceLista<this.actmaepolizaService.detmaePolizaArray[this.indicador].length;intIndiceLista++)	{
			item = this.actmaepolizaService.detmaePolizaArray[this.indicador][intIndiceLista];
				if (item.POL_CODIGO === '')	{
          this.mensajeAlerta('info', 'Información', 'Error, Código incorrecto');
		    	return false;
				}
				
				if (item.POL_NUMERO === '')	{
          this.mensajeAlerta('info', 'Información', 'Error, Número incorrecto');
		    	return false;
				}
				
				if (item.POL_EMPRESA === '')	{
          this.mensajeAlerta('info', 'Información', 'Error, Empresa incorrecta');
		    	return false;
				}
			
				if (item.POL_FECHAINI === '')	{
          this.mensajeAlerta('info', 'Información', 'Error, Fecha Inicial incorrecta');
		    	return false;
				}

				if (item.POL_FECHAFIN === '')	{
          this.mensajeAlerta('info', 'Información', 'Error, Fecha Final incorrecta');
          return false;
				}
			
		}//fin de for
		
		
		return true;
		
	} //Fin faltan CamposDet

  nuevoRegistro() {
    const linea = this.actmaepolizaService.detmaePolizaArray[this.indicador].length;
    const nuevodetalle: ActMaePoliza = {
      LINEA: linea + 1,
      IS_NEW_ROW: true,
      POL_CODIGO: '',
      COD_ANTERIOR: '',
      POL_EMPRESA: '',
      POL_FECHAFIN: '',
      POL_FECHAINI: '',
      POL_NUMERO: ''
    
    };
         
    const indi = this.actmaepolizaService.detmaePolizaArray[this.indicador].indexOf(this.detallePolizaSelect);
    if (indi === -1) {
      this.actmaepolizaService.detmaePolizaArray[this.indicador].push(nuevodetalle);
      this.detallePolizaSelect = this.actmaepolizaService.detmaePolizaArray[this.indicador][this.actmaepolizaService.detmaePolizaArray[this.indicador].length - 1];
      this.aggrid.refreshaggrid(this.actmaepolizaService.detmaePolizaArray[this.indicador], this.defaultActMaePoliza.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultActMaePoliza.objeto].setFocusedCell(
        this.actmaepolizaService.detmaePolizaArray[this.indicador].length - 1, 'POL_CODIGO');
    } else {
      this.detallePolizaSelect= nuevodetalle;
      this.actmaepolizaService.detmaePolizaArray[this.indicador].splice(indi + 1, 0, nuevodetalle);
      this.aggrid.refreshaggridindex(this.actmaepolizaService.detmaePolizaArray[this.indicador], this.defaultActMaePoliza.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultActMaePoliza.objeto].setFocusedCell(
      indi + 1, 'POL_CODIGO');
    }
  }

  async listarDatos() {
    this.actmaepolizaService.detmaePolizaArray[this.indicador] = [];
    try {
      const rs = await this.actmaepolizaService.listarDatos();
      let numLinea = 0;
      const detalle = [];
      for (const i of rs) {
        numLinea++;
        const item: ActMaePoliza = {};
        item.POL_CODIGO = i.POL_CODIGO === null ? '' : i.POL_CODIGO;
        item.POL_EMPRESA = i.POL_EMPRESA === null ? '' : i.POL_EMPRESA;
        item.POL_NUMERO = i.POL_NUMERO === null ? '' : i.POL_NUMERO;
        if (i.POL_FECHAINI !== null) {
          item.POL_FECHAINI = this.datePipe.transform(i.POL_FECHAINI, 'dd/MM/yyyy');
        } else {
          item.POL_FECHAINI = '';
        }

        if (i.POL_FECHAFIN !== null) {
          item.POL_FECHAFIN = this.datePipe.transform(i.POL_FECHAFIN, 'dd/MM/yyyy');
        } else {
          item.POL_FECHAFIN = '';
        }

        item.COD_ANTERIOR = item.POL_CODIGO;
        item.LINEA = numLinea;
        item.IS_NEW_ROW = false;
        detalle.push(item);
      }
      this.actmaepolizaService.detmaePolizaArray[this.indicador] = detalle;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
    }
  }

  activaBotones(strTipo,bolNuevo,bolGuardar,bolCancelar,bolEliminar,bolInicio,bolAnterior,bolSiguiente,bolUltimo,bolBuscar,bolImprimir) {
    this.actmaepolizaService.btnGuardar[this.indicador] = !bolGuardar;
    this.actmaepolizaService.btnNuevo[this.indicador] = !bolNuevo; 
    this.actmaepolizaService.btnBorrar[this.indicador] = !bolEliminar;
  }

  cambio(event) {
    this.activaBotones('M',false,true,true,false,false,false,false,false,false,false); 
  }

  selectActMaePoliza(valor) {
    if (valor.object !== undefined) {
      this.detallePolizaSelect= valor.object;
    }
  }

  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'actmaepoliza',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

}
