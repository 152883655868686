import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {Observable} from 'rxjs';
import { Nomprovincia } from '../nominterfaces/nommaeprovincia';

@Injectable({
  providedIn: 'root'
})
export class NommaeprovinciaService {
  
  
  public way: string;

  private _nommaeprov_provincias: Nomprovincia[] = [];
  private _btnGuardar:boolean;
  private _btnNuevo:boolean;
  private _btnBorrar:boolean;
  private _btnRegresar:boolean;
  
  
  get nommaeprov_provincia (): Nomprovincia[] {
    return this._nommaeprov_provincias;
  }
  set nommaeprov_provincia(value: Nomprovincia[]) {
    this._nommaeprov_provincias = value;
    this._nommaeprov_provincias.map( (item) =>{
      item.NUMERO = this._nommaeprov_provincias.indexOf( item ) + 1;
    }  );
  }
  private _nommaeprov_ciudades: Nomprovincia[] = [];
  get nommaeprov_ciudades (): Nomprovincia[] {
    return this._nommaeprov_ciudades;
  }
  set nommaeprov_ciudades(value: Nomprovincia[]) {
    this._nommaeprov_ciudades = value;
    this._nommaeprov_ciudades.map( (item) =>{
      item.NUMERO = this._nommaeprov_ciudades.indexOf( item ) + 1;
    }  );
  }
  
  
  get btnNuevo() {
    return this._btnNuevo;
  }
  get btnGuardar() {
    return this._btnGuardar;
  }
  get btnBorrar() {
    return this._btnBorrar;
  }
  get btnRegresar() {
    return this._btnRegresar;
  }
  
  set btnNuevo(value: boolean) {
    this._btnNuevo = value;
  }
  set btnGuardar(value: boolean) {
    this._btnGuardar  = value;
  }
  set btnBorrar(value: boolean) {
    this._btnBorrar = value;
  }
  set btnRegresar(value: boolean) {
    this._btnRegresar = value;
  }
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService
  ) {
    this.way = sessionStorage.getItem('way');
  }
  
  cargarProvincias(){
    let strSentencia = 'select * from nom_maeprovincia where pro_padre is null order by pro_nombre';
    
    return this.http.post<any[]>(this.way + '/nom/provincias/62b70cef1f95d80', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  cargarCiudades(pro_codigo){
    let strSentencia = 'select * from nom_maeprovincia where pro_padre = '   + pro_codigo + '  order by pro_nombre';
    return this.http.post<any[]>(this.way + '/nom/provincias/62b7064bcd535c0', {
        elementos: {
          PRO_PADRE: pro_codigo
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  insertar( nommaeProvincia: Nomprovincia ) {
    
    let strSentencia = 'insert into nom_maeprovincia ( ' +
      'pro_codigo,'    +
      'pro_nombre,'    +
      'pro_padre,'     +
      'com_codigo'     +
      ' )'             +
      'VALUES(' +
      '\''    + nommaeProvincia.PRO_CODIGO       + '\',' +
      '\''  + nommaeProvincia.PRO_NOMBRE         + '\',' +
      '\''  + nommaeProvincia.PRO_PADRE          + '\',' +
      '\''  + nommaeProvincia.COM_CODIGO         + '\')';
    
    
    return this.http.post<any[]>(this.way + '/nom/provincias/95f8ede778fcb00', {
        elementos: {
          PRO_CODIGO: nommaeProvincia.PRO_CODIGO,
          PRO_NOMBRE: nommaeProvincia.PRO_NOMBRE,
          PRO_PADRE: nommaeProvincia.PRO_PADRE,
          COM_CODIGO: nommaeProvincia.COM_CODIGO
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  actualizar( nommaeprovincia: Nomprovincia) {
    let strSentencia = 'update nom_maeprovincia  ' +
      'set pro_codigo = \''   + nommaeprovincia.PRO_CODIGO + '\',' +
      ' pro_nombre = \''      + nommaeprovincia.PRO_NOMBRE + '\' ' +
      'where pro_codigo = \'' + nommaeprovincia.old_PRO_CODIGO + '\'';
    
    return this.http.post<any[]>(this.way + '/nom/provincias/a677d9de3f2bb000', {
        elementos: {
          PRO_CODIGO: nommaeprovincia.PRO_CODIGO,
          PRO_NOMBRE: nommaeprovincia.PRO_NOMBRE,
          old_PRO_CODIGO: nommaeprovincia.old_PRO_CODIGO
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  borrar(  nommaeprovincia: Nomprovincia ) {
    let strSentencia = 'delete from nom_maeprovincia  ' +
      'where pro_codigo = \'' + nommaeprovincia.old_PRO_CODIGO + '\'';
    
    return this.http.post<any[]>(this.way + '/nom/provincias/eacad9de3f2bb000', {
        elementos: {
          old_PRO_CODIGO: nommaeprovincia.old_PRO_CODIGO
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
    
  }
  
  
  initBotones() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = false;
  }
}
