//
// SACI WEB
// Rutina: Pto Vta Factura
// Propietario: Wilson Ramos
// Modificado por: WR
// Fecha de creación: 2019
// Fecha de Modificación: 2019
//
import {
  AfterViewChecked,
  AfterViewInit, ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {CalculosServiceService} from '../../servicios/calculos-service.service';
import {Maearticulo} from '../../interfaces/maearticulo';
import {MessageService} from 'primeng/api';


@Component({
  selector: 'app-pdvfactura',
  templateUrl: './pdvfactura.component.html',
  styleUrls: ['./pdvfactura.component.css'],
  preserveWhitespaces: false
})
export class PdvfacturaComponent implements OnInit, DoCheck, OnChanges, OnDestroy, AfterViewInit, AfterViewChecked {
  @Output() productobo = new EventEmitter<boolean>();
  @Output() controlCobrar = new EventEmitter<boolean>();

  @Input() selectedProducto: Maearticulo;
  @Input() auxiliar: any;
  @Input() numero: number;
  @Input() instanciaPrin: number;
  @Input() facPagada: boolean;
  prodFactura: [Maearticulo[]] = [[]];
  subtotal: [any] = [0];
  iva: [any] = [0];
  total: [any] = [0];
  baseiva: [any] = [0];
  basecero: [any] = [0];
  descuento: [any] = [0];
  facSeleccionada: Maearticulo[] = [];
  prodSeleccionado: Maearticulo = {};
  indexProdSeleccionado: number;
  string = '';
  stringTeclado = '';

  constructor(private calc: CalculosServiceService, private messageService: MessageService, private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.facPagada = false;
    this.prodFactura[this.instanciaPrin - 1] = [];
    this.subtotal[this.instanciaPrin - 1] = [0];
    this.iva[this.instanciaPrin - 1] = [0];
    this.total[this.instanciaPrin - 1] = [0];
    this.prodSeleccionado = undefined;
  }

  ngAfterViewInit(): void {
    this.cd.detach();
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calc.recibirAux(this.auxiliar);
  }

  ngDoCheck(): void {
    this.subtotal = this.calc.entregarSubtotal();
    this.baseiva = this.calc.entregarBaseIVA();
    this.iva = this.calc.entregarIVA();
    this.basecero = this.calc.entregarBaseCero();
    this.descuento = this.calc.entregarDescuento();
    this.total = this.calc.entregarTotal();
    this.string = this.calc.returnCalcValue();
    if (this.auxiliar === '1') {
      this.prodFactura = this.calc.recibirDet();
    } else {
      this.prodFactura = this.calc.entregarFactura();
    }
    this.stringTeclado = this.calc.retornarValorTeclado();
    if (this.facPagada === true) {
      this.prodSeleccionado = undefined;
    }
  }

  ngOnDestroy(): void {
    this.prodFactura[this.instanciaPrin - 1] = [];
    this.subtotal[this.instanciaPrin - 1] = [0];
    this.iva[this.instanciaPrin - 1] = [0];
    this.total[this.instanciaPrin - 1] = [0];
    this.cd.detach();
  }


  selectedFac(fac: Maearticulo, index: number) {
    this.prodSeleccionado = fac;
    this.indexProdSeleccionado = index;
    this.calc.enviarArt(fac, index);
  }

  eliminarProd() {
    if (this.prodSeleccionado === undefined) {
      this.messageService.add({
        key: 'fac',
        severity: 'error',
        summary: 'Error al borrar artículo',
        detail: 'No se escogio un producto de la factura'
      });
      this.vaciarArreglo();
      return;
    } else  {
      this.calc.borrarProd(this.prodSeleccionado, this.indexProdSeleccionado, this.instanciaPrin);
      this.controlCobrar.emit(false);
      this.prodSeleccionado = undefined;
    }
  }

  enviarDescuento() {
    if (this.prodSeleccionado === undefined) {
      this.messageService.add({
        key: 'fac',
        severity: 'error',
        summary: 'Error al Aplicar descuento',
        detail: 'No se escogio un producto de la factura'
      });
      this.vaciarArreglo();
    } else {
      if ((this.string || this.stringTeclado) === undefined || (this.string || this.stringTeclado) === '' || isNaN(Number(this.string))
        || isNaN(Number(this.stringTeclado)) || ((Number(this.string) < 0 || Number(this.stringTeclado)) < 0) || ((Number(this.string) > 100
          || Number(this.stringTeclado)) > 100)) {
        this.messageService.add({
          key: 'fac',
          severity: 'error',
          summary: 'Error al Aplicar descuento',
          detail: 'No se escribio un valor de descuento correcto'
        });
        this.vaciarArreglo();
      } else {
        this.messageService.add({
          key: 'fac',
          severity: 'success',
          summary: 'Descuento Aplicado',
          detail: 'Se aplico el descuento correctamente'
        });
        this.calc.modificarDescuento(this.prodSeleccionado, (Number(this.string) || Number(this.stringTeclado)), this.instanciaPrin);
        this.vaciarArreglo();
        this.controlCobrar.emit(false);
      }
    }
  }

  enviarPrecio() {
    if (this.prodSeleccionado === undefined) {
      this.messageService.add({
        key: 'fac',
        severity: 'error',
        summary: 'Error al Aplicar descuento',
        detail: 'No se escogio un producto de la factura'
      });
      this.vaciarArreglo();
    } else {
      if ((this.string || this.stringTeclado) === undefined || (this.string || this.stringTeclado) === '' ||
        isNaN(Number(this.string) || Number(this.stringTeclado))
        || ((Number(this.string) || Number(this.stringTeclado)) < 0) ||
        ((Number(this.string) || Number(this.stringTeclado)) > Number(this.prodSeleccionado.ARTPRE_PRECIO))) {
        this.messageService.add({
          key: 'fac',
          severity: 'error',
          summary: 'Error al Aplicar descuento',
          detail: 'No se escribio un valor de descuento correcto'
        });
        this.vaciarArreglo();
      } else {
        this.messageService.add({
          key: 'fac',
          severity: 'success',
          summary: 'Descuento Aplicado',
          detail: 'Se aplico el descuento correctamente'
        });
        this.calc.modificarPrecio(this.prodSeleccionado, (Number(this.string) || Number(this.stringTeclado)), this.instanciaPrin);
        this.vaciarArreglo();
        this.controlCobrar.emit(false);
      }
    }
  }

  enviarCantidad() {
    if (this.prodSeleccionado === undefined) {
      this.messageService.add({
        key: 'fac',
        severity: 'error',
        summary: 'Error al Cambiar cantidad',
        detail: 'No se escogio un producto de la factura'
      });
      this.vaciarArreglo();
    } else {
      if ((this.string || this.stringTeclado) === undefined || (this.string || this.stringTeclado) === '' ||
        isNaN(Number(this.string) || Number(this.stringTeclado))
        || ((Number(this.string) || Number(this.stringTeclado)) <= 0)) {
        this.messageService.add({
          key: 'fac',
          severity: 'error',
          summary: 'Error al Cambiar cantidad',
          detail: 'No se escribio un valor de cantidad correcto'
        });
        this.vaciarArreglo();
      } else {
        this.messageService.add({
          key: 'fac',
          severity: 'success',
          summary: 'Cantidad Modificada',
          detail: 'Se cambioRet la cantidad al producto seleccionado'
        });
        this.calc.modificarCantidad(this.prodSeleccionado, (Number(this.string) || Number(this.stringTeclado)), this.instanciaPrin);
        this.vaciarArreglo();
        this.controlCobrar.emit(false);
      }
    }
  }

  enviarPrecioValor() {
    if (this.prodSeleccionado === undefined) {
      this.messageService.add({
        key: 'fac',
        severity: 'error',
        summary: 'Error al Cambiar precio',
        detail: 'No se escogio un producto de la factura'
      });
      this.vaciarArreglo();
    } else {
      if ((this.string || this.stringTeclado) === undefined || (this.string || this.stringTeclado) === '' ||
        isNaN(Number(this.string) || Number(this.stringTeclado))
        || ((Number(this.string) || Number(this.stringTeclado)) <= 0)) {
        this.messageService.add({
          key: 'fac',
          severity: 'error',
          summary: 'Error al Cambiar precio',
          detail: 'No se escribio un valor de precio correcto'
        });
        this.vaciarArreglo();
      } else {
        this.messageService.add({
          key: 'fac',
          severity: 'success',
          summary: 'Precio Cambiado',
          detail: 'Se cambioRet el precio del producto seleccionado'
        });
        this.calc.modificarPrecioValor(this.prodSeleccionado, (Number(this.string) || Number(this.stringTeclado)), this.instanciaPrin);
        this.vaciarArreglo();
        this.controlCobrar.emit(false);
      }
    }
  }

  vaciarArreglo() {
    this.stringTeclado = '';
    this.calc.vaciarCant();
  }

  buscarProducto() {
    this.productobo.emit(true);
  }


  moneyValidation(campo) {
    if (campo.indexOf(',') !== -1) {
      return campo;
    }

    let arreglo = [];
    let asase: string;
    const auxil = campo + '';
    const punto = auxil.split('.');
    while (punto[0].replace(/-/g, '').length > 3) {
      arreglo.push(punto[0].substr(punto[0].length - 3, punto[0].length));
      punto[0] = punto[0].substr(0, punto[0].length - 3);
    }

    asase = punto[0];
    arreglo = arreglo.reverse();
    for (const i of arreglo) {
      asase = asase + ',' + i;
    }

    if (punto[1] !== undefined) {
      if (punto[1].length > 0) {
        asase = asase + '.' + punto[1];
      }
    }

    return asase;

  }
}
