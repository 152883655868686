import { Component, OnInit, ViewChild } from '@angular/core';
import { TecencordtraService } from '../tecservicios/tecencordtra.service';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { MessageService, ConfirmationService, SelectItem } from 'primeng';
import { PermisosService } from 'src/app/advantage/advantageservices/permisos.service';
import { DatePipe } from '@angular/common';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { SegMaePermiso } from 'src/app/advantage/advantageinterfaces/segmaepermiso';
import { VenTrnrpttecnicoot } from '../tecinterfaces/ventrnrpttecnicoot';
import { AggridBanComponent } from 'src/app/ban/aggrid-ban/aggrid-ban.component';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { VenDetordtra } from '../tecinterfaces/vendetordtra';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { VenTrnregllamadasot } from '../tecinterfaces/ventrnregllamadasot';
import { VenTrncorreosot } from '../tecinterfaces/ventrncorreosot';

@Component({
  selector: 'app-tecencordtra',
  templateUrl: './tecencordtra.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class TecencordtraComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  indicador: any;
  index: number;
  indexprev: number;

  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;

  detalleSeleccionado: VenDetordtra;
  regllamSeleccionado: VenTrnregllamadasot;
  contnotifSeleccionado: VenTrncorreosot;
  tableDatosTecnicos: VenTrnrpttecnicoot[];
  tableHistorial: any[];


  tipodetalle: string;
  comboOpciones: any[];
  permisoEncordtra: SegMaePermiso;
  messagekey = 'encordtra';
  confirmkey = 'confirmencordtra';

  displayDialogBusquedaFast: boolean = false;
  spin: boolean = false;

  // dropdowns
  opcionesEstOrdTra: SelectItem[];
  opcionesEstEquipo: SelectItem[];

  // busqueda
  opcionbusqueda: string;
  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];
  consulta: string;
  tabla: string;
  where: string;

  largo: any;
  largogen: any;

  arrayIva: string[];
  displayDialogDocumentos = false;

  public frameworkComponents;
  rowStyle;
  defaultColDef;
  defaultColDefDatosTecnicos;
  defaultColDefDetalle;
  defaultColDefRegistroLLamadas;
  defaultColDefEnvioCorreos;
  defaultColDefHistorial;
  rowSelection = 'multiple';

  columnDefs = [
    {
      headerName: 'Código', field: 'ETI_CODIGO', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
        }
      }
    },
    {
      headerName: 'Nombre', field: 'ETI_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 30
        }
      }
    },
    {
      headerName: 'Descripcion', field: 'DETMAT_DESCRIPCION', cellEditor: 'cellPrueba', width: 500,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 30
        }
      }
    }
  ]

  columnDefsDatosTecnicos = [
    {
      headerName: 'S.', field: 'SELECCION',
      cellRendererFramework: AggridBanComponent,
      editable: false,
      width: 50,
      onCellClicked: (params) => {
      }
    },
    {
      headerName: 'Código', field: 'REF_CODIGO', cellEditor: 'cellPrueba'
    },
    {
      headerName: 'Descripción', field: 'DESCRIPCION', cellEditor: 'cellPrueba', width: 300
    },
  ]

  columnDefsDetalle = [
    {
      headerName: 'Bodega', field: 'BOD_CODIGO', cellEditor: 'cellPrueba',
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Código', field: 'DETORDTRA_CODIGO', cellEditor: 'cellPrueba',
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Descripción', field: 'DETORDTRA_DESCRIPCION', cellEditor: 'cellPrueba', width: 300,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 3,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 30,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Iva', field: 'DETORDTRA_TRIBIVA', cellEditor: 'cellPrueba',
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Unidad', field: 'DETORDTRA_UNIDAD', cellEditor: 'cellPrueba',
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    },
    {
      headerName: 'Cantidad', field: 'DETORDTRA_CANTIDAD', cellEditor: 'cellPrueba',
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Precio', field: 'DETORDTRA_PRECIO', cellEditor: 'cellPrueba',
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Total', field: 'DETORDTRA_TOTAL', cellEditor: 'cellPrueba',
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    },
  ]

  columnDefsRegistroLLamadas = [
    {
      headerName: 'Emisión', field: 'TRNREGLLAMOT_FECHA', cellEditor: 'cellPrueba',
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: true,
          clave: false,
          tamanocelda: 10,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Hora', field: 'TRNREGLLAMOT_HORA', cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    },
    {
      headerName: 'Vendedor', field: 'VEN_CODIGO', cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 2,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Nombre', field: 'VEN_NOMBRE', cellEditor: 'cellPrueba', width: 300,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Prioridad', field: 'TRNREGLLAMOT_TIPO', cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    },
    {
      headerName: 'Informe al Cliente', field: 'TRNREGLLAMOT_MOTIVO', cellEditor: 'cellPrueba', width: 400,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 100
        }
      }
    },
  ]

  columnDefsEnvioCorreos = [
    {
      headerName: 'Emisión', field: 'TRNCORREOSOT_FECHA', cellEditor: 'cellPrueba',
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: true,
          clave: false,
          tamanocelda: 10,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Hora', field: 'TRNCORREOSOT_HORA', cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    },
    {
      headerName: 'Vendedor', field: 'VEN_CODIGO', cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 2,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Nombre', field: 'VEN_NOMBRE', cellEditor: 'cellPrueba', width: 300,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Motivo', field: 'TRNCORREOSOT_MOTIVO', cellEditor: 'cellPrueba', width: 400,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 100
        }
      }
    },
  ]

  columnDefsHistorial = [
    {
      headerName: 'Ordenes Trabajo', field: 'ORDTRA_NUMERO', cellEditor: 'cellPrueba', width: 150
    },
    {
      headerName: 'Fecha', field: 'ORDTRA_FECHA', cellEditor: 'cellPrueba'
    },
    {
      headerName: 'Estado', field: 'ORDTRA_ESTADO', cellEditor: 'cellPrueba', width: 80
    },
    {
      headerName: 'Cliente', field: 'CLI_CODIGO', cellEditor: 'cellPrueba', width: 120
    },
    {
      headerName: 'Nombre-Cliente', field: 'CLI_NOMBRE', cellEditor: 'cellPrueba', width: 300
    },
    {
      headerName: 'Total', field: 'ORDTRA_TOTAL', cellEditor: 'cellPrueba'
    },
    {
      headerName: 'Equipo', field: 'ORDTRA_PRODUCTOCLI', cellEditor: 'cellPrueba'
    },
    {
      headerName: 'Nombre-Equipo', field: 'NOMBREEQUIPO', cellEditor: 'cellPrueba'
    },
    {
      headerName: 'Marca', field: 'ORDTRA_MARCA', cellEditor: 'cellPrueba'
    },
    {
      headerName: 'Nombre-Marca', field: 'NOMBREMARCA', cellEditor: 'cellPrueba'
    },
    {
      headerName: 'Ubicación', field: 'ORDTRA_UBICACION', cellEditor: 'cellPrueba'
    },
    {
      headerName: 'Nombre-Ubicación', field: 'UBICACIONEQUIPO', cellEditor: 'cellPrueba'
    },
    {
      headerName: 'Modelo', field: 'ORDTRA_MODELO', cellEditor: 'cellPrueba'
    },
    {
      headerName: 'Serie', field: 'ORDTRA_SERIE', cellEditor: 'cellPrueba'
    },
  ]

  constructor(public encordtraService: TecencordtraService, public init: NuevoComponentService,
    private messageService: MessageService, private permisosService: PermisosService,
    private confirmationService: ConfirmationService, private datePipe: DatePipe,
    private confIniciales: ConfInicialesService, private busqService: InvbusquedaService,
    private auditoriagral: AuditoriagralService) {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultColDef = {
      editable: true,
      width: 100,
      objeto: 'tecdetmant'
    };

    this.defaultColDefDatosTecnicos = {
      editable: true,
      width: 100,
      objeto: 'datostecnicos'
    };

    this.defaultColDefDetalle = {
      editable: true,
      width: 100,
      objeto: 'detalleordentrabajo'
    };

    this.defaultColDefRegistroLLamadas = {
      editable: true,
      width: 100,
      objeto: 'registrollamadas'
    };

    this.defaultColDefEnvioCorreos = {
      editable: true,
      width: 100,
      objeto: 'enviocorreos'
    };

    this.defaultColDefHistorial = {
      editable: true,
      width: 100,
      objeto: 'historial'
    };
  }

  async ngOnInit() {
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.largo = '680';
    this.largogen = '590';
    this.index = 0;
    this.indexprev = 0;

    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;

    this.encordtraService.encordtra[this.indicador] = {};
    this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO = '';
    this.encordtraService.tableEtiquetas[this.indicador] = [];

    this.comboOpciones = [{ label: 'A', value: 'A' },
    { label: 'S', value: 'S' }];

    this.llenarCombos();

    await this.getPermisos();

    this.confIniciales.getDecimales('frmVEN_ENCFAC').subscribe((dec) => {
      if (dec !== null) {
        dec.map((decimales) => {
          if (decimales.NUMDEC_TIPDOC === 'detfac') {
            if (decimales.NUMDEC_CAMPO === 'TOTAL') {
              this.encordtraService.decimalesTotal = Number(decimales.NUMDEC_NUMDEC);
            }
          }
        });
      }
    });

    this.getConfiguracion();

    this.encordtraService.iva = await this.confIniciales.obtenerImpuesto('I', 'A');
    this.arrayIva = ["Exento", "Neto + Iva", "Incluido Iva"];
    this.encordtraService.cmbIva = this.arrayIva[0];
  }

  async getPermisos() {
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisoEncordtra = data[0];
        } else {
          this.permisoEncordtra = null;
        }
      } else {
        this.permisoEncordtra = null;
      }
    } catch (e) {
      this.permisoEncordtra = null;
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Error',
        detail: 'No se pudo obtener los permisos del formulario'
      });
    }
  }

  getConfiguracion() {
    this.auditoriagral.obtenerConfiguracion1().subscribe((conf) => {
      this.encordtraService.hmpConfiguracion = conf;
    });
  }

  async llenarCombos() {
    this.opcionesEstOrdTra = [{ label: 'Revisión', value: 'Revisión' },
    { label: 'Diagnóstico', value: 'Diagnóstico' },
    { label: 'Reparación', value: 'Reparación' },
    { label: 'Espera de Repuestos', value: 'Espera de Repuestos' },
    { label: 'No responde', value: 'No responde' },
    { label: 'Reparada', value: 'Reparada' }];

    this.opcionesEstEquipo = [];
    const data: any = await this.encordtraService.getDropEstEquipo();
    for (const rs of data) {
      this.opcionesEstEquipo.push({ label: rs.ORDTRA_ESTEQUIPO, value: rs.ORDTRA_ESTEQUIPO });
    }
  }


  async manejarSenales(valor) {
    if (valor === 'Nuevo') {
      this.nuevo();
    }
    if (valor === 'Guardar') {
      this.guardar(this.index);
    }
    if (valor === 'Borrar') {
      this.eliminar();
    }
    if (valor === 'Cancelar') {
      this.cancelar();
    }
    if (valor === 'Buscar') {
      this.buscarordtra();
    }
    if (valor === 'Primero') {
      this.seleccionarRegistro('P');
    }
    if (valor === 'Anterior') {
      this.seleccionarRegistro('A');
    }
    if (valor === 'Siguiente') {
      this.seleccionarRegistro('S');
    }
    if (valor === 'Ultimo') {
      this.seleccionarRegistro('U');
    }
    if (valor === 'Almacenardoc') {
      if (this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO !== '') {
        const usu = await this.confIniciales.obtenerPermisoUsuario(this.encordtraService.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.messageService.add({
            key: 'encordtra',
            severity: 'info',
            summary: 'Información',
            detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
          });
        }

      }
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }

  async seleccionarRegistro(strTipo) {
    let strNroOrd = '';
    if (this.permisoEncordtra !== null) {
      if (this.permisoEncordtra.PERTODO === 0) {
        if (this.permisoEncordtra.PERSELECCION === 0) {
          this.messageService.add({
            key: this.messagekey,
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario '
              + this.encordtraService.usuario.identificacion
              + ', no tiene permiso de Selección. Consulte con el Administrador del Sistema.'
          });
          return;
        }
      }
    }


    const data: any = await this.encordtraService.seleccionarRegistro(strTipo, this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO);
    for (const rs of data) {      
      strNroOrd = rs.ORDTRA_NUMERO==null?"":rs.ORDTRA_NUMERO;	
    }
    if (strNroOrd != '') {
      this.encontrarOrdenTrabajo(strNroOrd);
    }


  }

  nuevo() {
    if (this.permisoEncordtra !== null) {
      if (this.permisoEncordtra.PERTODO === 0) {
        if (this.permisoEncordtra.PERINSERCION === 0) {
          this.messageService.add({
            key: this.messagekey,
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario '
              + this.encordtraService.usuario.identificacion
              + ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.'
          });
          return;
        }
      }
    }
    this.limpiarDatosDocumento();
    /*tabDatos.setEnabled(true);
    tabDatos.setSelection(0);
    toolBarNavegarDetalle.setEnabled(true);

    grpCliente.setEnabled(true);
    grpEquipo.setEnabled(true);
    grpProducto.setEnabled(true);
    group.setEnabled(true);
    cmbIva.setEnabled(true);
    grpDetalle.setEnabled(true);
    lblUsuario.setText(Conexion.usuarioConectado);
    txtRucCi.setText("");
    txtRucCi.setFocus();*/
    document.getElementById('CLI_CODIGO').focus();
    /*
    habilitarBotones(false, true, false, true, false, false, false, false, true, false, false);
    activarBotonesDetalle(true, false, true, true, true);
    bolNuevo = true;
    btnArticulo.setSelection(true);*/
    this.encordtraService.tableDetalle[this.indicador] = [];
    this.encordtraService.tableRegistroLLamadas[this.indicador] = [];
    this.encordtraService.tableEnvioCorreos[this.indicador] = [];
    this.tableDatosTecnicos = [];
    this.modeloDatTeclistarDatos('');
    this.tableHistorial = [];
    /*
        txtDdSer.setText("");
        txtMemSer.setText("");
    
        cmbEstadoOrdTra.deselectAll();
        cmnEstadoEquipo.deselectAll();*/
  }

  async guardar(index) {

    if (this.permisoEncordtra !== null) {
      if (this.permisoEncordtra.PERTODO === 0) {
        if (this.permisoEncordtra.PERINSERCION === 0) {
          this.messageService.add({
            key: this.messagekey,
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario '
              + this.encordtraService.usuario.identificacion
              + ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.'
          });
          return;
        }
      }
    }

    if (index === 0 || index === 1 || index === 2) { // orden de trabajo      

      if (this.encordtraService.encordtra[this.indicador].CLI_CODIGO === '' || this.encordtraService.encordtra[this.indicador].ORDTRA_PRODUCTOCLI === '') {
        this.messageService.add({
          key: this.messagekey,
          severity: 'error',
          summary: 'Información',
          detail: 'Debe ingresar la información del cliente, problema, producto a reparar y servicio'
        });

      } else {

        let strFechaEmision = '', strFechaVence = '', strFecIngre = '', strFecDiag = '', strFechaDiag = '',
          strFechaPro = '', strEstado = '', strFechaEntregado = '', strFechaMaximaGarantia = '', strFechaAsignacionTecnica = '';
        let strHoraAsignado = '', strHoraEntrega = '';

        strFechaEmision = this.datePipe.transform(this.encordtraService.encordtra[this.indicador].ORDTRA_FECHA, 'dd/MM/yyyy');

        strFechaVence = this.datePipe.transform(this.encordtraService.encordtra[this.indicador].ORDTRA_FECHAVENCE, 'dd/MM/yyyy');

        let strFechaDatosTecnicos = this.datePipe.transform(this.encordtraService.encordtra[this.indicador].ORDTRA_FECHADATOSTEC, 'dd/MM/yyyy');

        // Utilidades.ConvierteFechaAString(dateTimeFecDiag.getDay(), dateTimeFecDiag.getMonth(), dateTimeFecDiag.getYear()) + " " + dateTimeHoraDiag.getHours() + ":" + dateTimeHoraDiag.getMinutes();
        strFecIngre = this.datePipe.transform(this.encordtraService.encordtra[this.indicador].ORDTRA_FECHAINGRESO, 'dd/MM/yyyy') + " " + this.datePipe.transform(this.encordtraService.encordtra[this.indicador].ORDTRA_HORAINGRESO, 'HH:mm');
        strFecDiag = this.datePipe.transform(this.encordtraService.encordtra[this.indicador].ORDTRA_FECHADIAG, 'dd/MM/yyyy') + " " + this.datePipe.transform(this.encordtraService.encordtra[this.indicador].ORDTRA_HORADIAG, 'HH:mm');
        strFechaDiag = this.datePipe.transform(this.encordtraService.encordtra[this.indicador].ORDTRA_FECHADIAG, 'dd/MM/yyyy') + " " + this.datePipe.transform(this.encordtraService.encordtra[this.indicador].ORDTRA_HORADIAG, 'HH:mm');
        strFechaPro = this.datePipe.transform(this.encordtraService.encordtra[this.indicador].ORDTRA_FECHAPROC, 'dd/MM/yyyy') + " " + this.datePipe.transform(this.encordtraService.encordtra[this.indicador].ORDTRA_HORAPROC, 'HH:mm');

        strFechaEntregado = this.datePipe.transform(this.encordtraService.encordtra[this.indicador].ORDTRA_FECHAENTREGADO, 'dd/MM/yyyy');
        strFechaMaximaGarantia = this.datePipe.transform(this.encordtraService.encordtra[this.indicador].ORDTRA_FECHAMAXIMAGAR, 'dd/MM/yyyy') + " " + this.datePipe.transform(this.encordtraService.encordtra[this.indicador].ORDTRA_FECHAMAXIMAGAR, 'HH:mm');
        strFechaAsignacionTecnica = this.datePipe.transform(this.encordtraService.encordtra[this.indicador].ORDTRA_FECHAASIGNACIONTEC, 'dd/MM/yyyy');
        if (this.encordtraService.encordtra[this.indicador].ORDTRA_HORAENTREGADO.length !== 5) {
          strHoraEntrega = this.datePipe.transform(this.encordtraService.encordtra[this.indicador].ORDTRA_HORAENTREGADO, 'HH:mm');
        } else {
          strHoraEntrega = this.encordtraService.encordtra[this.indicador].ORDTRA_HORAENTREGADO;
        }
        if (this.encordtraService.encordtra[this.indicador].ORDTRA_HORAASIGNACIONTEC.length !== 5) {
          strHoraAsignado = this.datePipe.transform(this.encordtraService.encordtra[this.indicador].ORDTRA_HORAASIGNACIONTEC, 'HH:mm');
        } else {
          strHoraAsignado = this.encordtraService.encordtra[this.indicador].ORDTRA_HORAASIGNACIONTEC;
        }

        if (this.encordtraService.encordtra[this.indicador].ORDTRA_ESTADO === 'Pendiente') {
          strEstado = "P";
        } else {
          strEstado = "G";
        }

        const Parametros: any = {};

        Parametros.ORDTRA_NUMERO = this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO;
        Parametros.COM_CODIGO = '01';
        Parametros.ORDTRA_FECHA = strFechaEmision; // fecha de emision de la orden de trabajo   // fecha de la transaccion
        Parametros.ORDTRA_FECHAVENCE = strFechaVence; // fecha de vencimiento de la orden de trabajo
        Parametros.ORDTRA_ESTADO = strEstado;
        Parametros.USU_IDENTIFICACION = this.encordtraService.usuario.identificacion;
        Parametros.ORDTRA_ESTEQUIPO = this.encordtraService.encordtra[this.indicador].ORDTRA_ESTEQUIPO;
        Parametros.ORDTRA_FECHAINGRESO = strFecIngre; // fecha de ingreso del producto
        Parametros.ORDTRA_FECHADIAGNOSTICO = strFecDiag; // fecha a la que debe estar listo el diagnostico
        Parametros.ORDTRA_PROBLEMA = this.encordtraService.encordtra[this.indicador].ORDTRA_PROBLEMA;
        Parametros.ORDTRA_FECHADIAG = strFechaDiag; // fecha de diagnostico   
        Parametros.ORDTRA_DIAGNOSTICO = this.encordtraService.encordtra[this.indicador].ORDTRA_DIAGNOSTICO;
        Parametros.ORDTRA_FECHAPROC = strFechaPro; // fecha del procedimiento 
        Parametros.ORDTRA_PROCEDIMIENTO = this.encordtraService.encordtra[this.indicador].ORDTRA_PROCEDIMIENTO;
        Parametros.ORDTRA_PRODUCTOCLI = this.encordtraService.encordtra[this.indicador].ORDTRA_PRODUCTOCLI;
        Parametros.ORDTRA_SERIE = this.encordtraService.encordtra[this.indicador].ORDTRA_SERIE;
        Parametros.ORDTRA_DDDESC = this.encordtraService.encordtra[this.indicador].ORDTRA_DDDESC;
        Parametros.ORDTRA_DDSERIE = this.encordtraService.encordtra[this.indicador].ORDTRA_DDSERIE;
        Parametros.ORDTRA_MEMDESC = this.encordtraService.encordtra[this.indicador].ORDTRA_MEMDESC;
        Parametros.ORDTRA_MEMSERIE = this.encordtraService.encordtra[this.indicador].ORDTRA_MEMSERIE;
        Parametros.ORDTRA_UNIOPT = this.encordtraService.encordtra[this.indicador].ORDTRA_UNIOPT;
        Parametros.ORDTRA_ADAPTADOR = this.encordtraService.encordtra[this.indicador].ORDTRA_ADAPTADOR;
        Parametros.ORDTRA_BATERIA = this.encordtraService.encordtra[this.indicador].ORDTRA_BATERIA;
        Parametros.ORDTRA_OBSPRO = this.encordtraService.encordtra[this.indicador].ORDTRA_OBSPRO;
        Parametros.ORDTRA_IVA = Number(this.encordtraService.encordtra[this.indicador].ORDTRA_IVA);
        Parametros.ORDTRA_NETO = Number(this.encordtraService.encordtra[this.indicador].ORDTRA_NETO);
        Parametros.ORDTRA_TOTAL = Number(this.encordtraService.encordtra[this.indicador].ORDTRA_TOTAL);
        Parametros.CLI_CODIGO = this.encordtraService.encordtra[this.indicador].CLI_CODIGO;
        Parametros.ORDTRA_BASEIVA = Number(this.encordtraService.encordtra[this.indicador].ORDTRA_BASEIVA);
        Parametros.ORDTRA_BASECERO = Number(this.encordtraService.encordtra[this.indicador].ORDTRA_BASECERO);
        Parametros.ORDTRA_IDIVA = this.encordtraService.encordtra[this.indicador].ORDTRA_IDIVA;
        Parametros.ORDTRA_UBICACION = this.encordtraService.encordtra[this.indicador].ORDTRA_UBICACION;
        Parametros.ORDTRA_MEMADI = this.encordtraService.encordtra[this.indicador].ORDTRA_MEMADI;
        Parametros.ORDTRA_MEMADISERIE = this.encordtraService.encordtra[this.indicador].ORDTRA_MEMADISERIE;
        Parametros.CLI_CODIGO_FAC = this.encordtraService.encordtra[this.indicador].CLI_CODIGO_FAC;
        Parametros.ORDTRA_FECHAENTREGADO = strFechaEntregado; // Fecha entregado
        Parametros.ORDTRA_FECHAMAXIMAGAR = strFechaMaximaGarantia; // fecha maxima de garantia
        Parametros.ORDTRA_MARCA = this.encordtraService.encordtra[this.indicador].ORDTRA_MARCA;
        Parametros.ORDTRA_MODELO = this.encordtraService.encordtra[this.indicador].ORDTRA_MODELO;
        Parametros.VEN_CODIGO = this.encordtraService.encordtra[this.indicador].VEN_CODIGO;
        Parametros.ORDTRA_FECHAASIGNACIONTEC = strFechaAsignacionTecnica; // Fecha de asignacion tecnica
        Parametros.ORDTRA_UBICACIONIMAGEN = this.encordtraService.encordtra[this.indicador].ORDTRA_UBICACIONIMAGEN;
        Parametros.ORDTRA_FALLAREPORTADA = null; // this.encordtraService.encordtra[this.indicador].ORDTRA_FALLAREPORTADA;
        Parametros.ORDTRA_REPORTETECNICO = null; // this.encordtraService.encordtra[this.indicador].ORDTRA_REPORTETECNICO;
        Parametros.ORDTRA_HORAENTREGADO = strHoraEntrega;
        Parametros.ORDTRA_HORASIGNACION = strHoraAsignado;
        Parametros.ORDTRA_MEMORIA2DESC = this.encordtraService.encordtra[this.indicador].ORDTRA_MEMORIA2DESC;
        Parametros.ORDTRA_SERIE1MEMORIA2 = this.encordtraService.encordtra[this.indicador].ORDTRA_SERIE1MEMORIA2;
        Parametros.ORDTRA_SERIE2MEMORIA2 = this.encordtraService.encordtra[this.indicador].ORDTRA_SERIE2MEMORIA2;
        Parametros.ORDTRA_SERIE3MEMORIA2 = this.encordtraService.encordtra[this.indicador].ORDTRA_SERIE3MEMORIA2;
        Parametros.ORDTRA_ESTADOOT = this.encordtraService.encordtra[this.indicador].ORDTRA_ESTADOOT;
        Parametros.ORDTRA_FECHADATOSTEC = strFechaDatosTecnicos;

        const data: any = await this.encordtraService.insertarOrdenTrabajo(Parametros);

        for (const rs of data) {
          if (this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO === '') {
            this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO = rs.V_ORDTRA_NUMERO;
            // this.bolNuevo = false;
            this.auditoriagral.registrarAuditoria('VEN_ENCORDTRA',
              this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO, 'I',
              '', '01', '', '', '', '').subscribe();
          } else {
            this.auditoriagral.registrarAuditoria('VEN_ENCORDTRA',
              this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO, 'A',
              '', '01', '', '', '', '').subscribe();
          }
          this.botonesIniciar();
          /*txtRucCi.setEditable(false);
          btnBuscarCli.setEnabled(false);*/
          this.modeloDatTecguardarDetalle();
          this.modeloDetalleguardarDetalle();
          this.messageService.add({
            key: this.messagekey,
            severity: 'success',
            summary: 'Información',
            detail: 'Orden de producción guardada exitosamente'
          });
        }
      }
    } // fin guarda primera pestaña

    if (index === 3) { // registro de llamadas      
      this.modeloRegistroLLamadasguardarDetalle();
      this.botonesIniciar();
      this.messageService.add({
        key: this.messagekey,
        severity: 'success',
        summary: 'Información',
        detail: 'Registro de llamadas guardado exitosamente'
      });
    }


    if (index === 4) { // correos    
      this.modeloRegistroCorreosguardarDetalle();
      this.botonesIniciar();
      this.messageService.add({
        key: this.messagekey,
        severity: 'success',
        summary: 'Información',
        detail: 'Registro de correos guardado exitosamente'
      });
    }


  }

  async modeloDatTecguardarDetalle() {

    await this.encordtraService.EliminartodoRpttecnicoot(this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO);

    for (const item of this.tableDatosTecnicos) {
      if (item.SELECCION === true) {
        item.ORDTRA_NUMERO = this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO;
        this.encordtraService.InsertarRpttecnicoot(item)
        this.auditoriagral.registrarAuditoria('VEN_TRNRPTTECNICOOT',
          this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO + "/" + item.REF_TIPO + "/" + item.REF_CODIGO, 'I',
          '', '01', '', '', '', '').subscribe();
      } // fin inserta información    
    }

    this.botonesIniciar();

  }

  async modeloDetalleguardarDetalle() {

    for (const item of this.encordtraService.tableDetalle[this.indicador]) {

      if (item.DETORDTRA_CODIGO !== '') {
        const Parametros: any = {};
        Parametros.ORDTRA_NUMERO = this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO;
        Parametros.DETORDTRA_LINEA = item.DETORDTRA_LINEA; // linea del detalle
        Parametros.DETORDTRA_TIPODET = item.DETORDTRA_TIPODET; // tipo del detalle
        Parametros.BOD_CODIGO = item.BOD_CODIGO; // bodega
        Parametros.DETORDTRA_CODIGO = item.DETORDTRA_CODIGO;
        Parametros.DETORDTRA_DESCRIPCION = item.DETORDTRA_DESCRIPCION;
        Parametros.DETORDTRA_UNIDAD = item.DETORDTRA_UNIDAD;
        Parametros.DETORDTRA_CANTIDAD = Number(item.DETORDTRA_CANTIDAD);
        Parametros.DETORDTRA_PRECIO = Number(item.DETORDTRA_PRECIO);
        Parametros.DETORDTRA_TOTAL = Number(item.DETORDTRA_TOTAL);
        Parametros.DETORDTRA_TRIBIVA = item.DETORDTRA_TRIBIVA;
        Parametros.DETORDTRA_IVA = Number(item.DETORDTRA_IVA);

        const data: any = await this.encordtraService.InsertarDetordtra(Parametros);

        for (const rs of data) {
          if (item.DETORDTRA_LINEA == 0) {
            item.DETORDTRA_LINEA = rs.linea;
            this.auditoriagral.registrarAuditoria('VEN_DETORDTRA',
              this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO + "/" + item.DETORDTRA_CODIGO, 'I',
              '', '01', '', '', '', '').subscribe();
          } else {
            this.auditoriagral.registrarAuditoria('VEN_DETORDTRA',
              this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO + "/" + item.DETORDTRA_LINEA, 'M',
              '', '01', '', '', '', '').subscribe();
          }
        }
      }
    }
  }

  async modeloRegistroLLamadasguardarDetalle() {

    for (const item of this.encordtraService.tableRegistroLLamadas[this.indicador]) {

      if (item.VEN_CODIGO !== '') {
        const Parametros: any = {};
        Parametros.ORDTRA_NUMERO = this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO;
        Parametros.TRNREGLLAMOT_LINEA = item.TRNREGLLAMOT_LINEA;
        Parametros.TRNREGLLAMOT_FECHA = item.TRNREGLLAMOT_FECHA;
        Parametros.TRNREGLLAMOT_HORA = item.TRNREGLLAMOT_HORA;
        Parametros.VEN_CODIGO = item.VEN_CODIGO;
        Parametros.TRNREGLLAMOT_TIPO = item.TRNREGLLAMOT_TIPO;
        Parametros.TRNREGLLAMOT_MOTIVO = item.TRNREGLLAMOT_MOTIVO;

        const data: any = await this.encordtraService.InsertarRegllam(Parametros);

        for (const rs of data) {
          if (item.TRNREGLLAMOT_LINEA == 0) {
            item.TRNREGLLAMOT_LINEA = rs.linea;
            this.auditoriagral.registrarAuditoria('VEN_TRNREGLLAMADASOT',
              this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO + "/" + item.TRNREGLLAMOT_FECHA + "/" + item.VEN_CODIGO, 'I',
              '', '01', '', '', '', '').subscribe();
          } else {
            this.auditoriagral.registrarAuditoria('VEN_TRNREGLLAMADASOT',
              this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO + "/" + item.TRNREGLLAMOT_FECHA + "/" + item.VEN_CODIGO, 'A',
              '', '01', '', '', '', '').subscribe();
          }
        }
      }
    }

  }

  async modeloRegistroCorreosguardarDetalle() {
    for (const item of this.encordtraService.tableEnvioCorreos[this.indicador]) {

      if (item.VEN_CODIGO !== '') {
        const Parametros: any = {};
        Parametros.ORDTRA_NUMERO = this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO;
        Parametros.TRNCORREOSOT_LINEA = item.TRNCORREOSOT_LINEA;
        Parametros.TRNCORREOSOT_FECHA = item.TRNCORREOSOT_FECHA;
        Parametros.TRNCORREOSOT_HORA = item.TRNCORREOSOT_HORA;
        Parametros.VEN_CODIGO = item.VEN_CODIGO;
        Parametros.TRNCORREOSOT_MOTIVO = item.TRNCORREOSOT_MOTIVO;

        const data: any = await this.encordtraService.InsertarCorreo(Parametros);

        for (const rs of data) {
          if (item.TRNCORREOSOT_LINEA == 0) {
            item.TRNCORREOSOT_LINEA = rs.linea;
            this.auditoriagral.registrarAuditoria('VEN_TRNCORREOSOT',
              this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO + "/" + item.TRNCORREOSOT_FECHA + "/" + item.VEN_CODIGO, 'I',
              '', '01', '', '', '', '').subscribe();
          } else {
            this.auditoriagral.registrarAuditoria('VEN_TRNCORREOSOT',
              this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO + "/" + item.TRNCORREOSOT_FECHA + "/" + item.VEN_CODIGO, 'A',
              '', '01', '', '', '', '').subscribe();
          }
        }
      }
    }
  }

  eliminar() {
    if (this.index == 0) { // generales		
      if (this.permisoEncordtra !== null) {
        if (this.permisoEncordtra.PERTODO === 0) {
          if (this.permisoEncordtra.PERELIMACION === 0) {
            this.messageService.add({
              key: this.messagekey,
              severity: 'error',
              summary: 'Información',
              detail: 'El usuario '
                + this.encordtraService.usuario.identificacion
                + ', no tiene permiso de eliminación. Consulte con el Administrador del Sistema.'
            });
            return;
          }
        }
      }
    }

    this.confirmationService.confirm({
      message: 'Está seguro de eliminar el registro?',
      header: 'Orden de trabajo',
      icon: 'pi pi-exclamation-triangle',
      key: this.confirmkey,
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: async () => {
        if (this.encordtraService.encordtra[this.indicador].ORDTRA_ESTADO === "Cerrada") {
          this.messageService.add({
            key: this.messagekey,
            severity: 'error',
            summary: 'Información',
            detail: 'La orden de trabajo no puede ser eliminada ya que se encuentra facturada'
          });
          return;
        }

        if (this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO === "") {
          this.messageService.add({
            key: this.messagekey,
            severity: 'error',
            summary: 'Información',
            detail: 'Seleccione el registro a Eliminar'
          });
          return;
        }

        this.encordtraService.eliminarEncordtra(this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO);
        this.auditoriagral.registrarAuditoria('VEN_ENCORDTRA',
          this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO, 'E',
          '', '01', '', '', '', '').subscribe();

        this.limpiarDatosDocumento();

      },
      reject: async () => {
        return;
      }
    });
  }

  cancelar() {
    this.botonesIniciar();
    this.encontrarOrdenTrabajo(this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO);
  }

  buscarordtra() {
    this.opcionbusqueda = 'ordtra';
    this.types = [
      { label: 'Número', value: 'ORDTRA_NUMERO' },
      { label: 'Código', value: 'CLI_CODIGO' },
      { label: 'Equipo', value: 'ORDTRA_PRODUCTOCLI' }
    ];
    this.busquedacampos = ['', '', ''];
    this.consulta = '';
    this.tabla = 'VEN_ENCORDTRA';
    this.where = '';

    this.spin = true;
    this.busqService.busquedaVenEncOrdTra1().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }

  async changeTabs(event) {
    if (this.btnGuardar === false && (this.indexprev === 3 || this.indexprev === 4)) {
      this.confirmationService.confirm({
        message: 'Desea descartar los cambios',
        header: 'Orden de trabajo',
        icon: 'pi pi-exclamation-triangle',
        key: this.confirmkey,
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: async () => {
          this.indexprev = event.index;
        },
        reject: async () => {
          this.guardar(this.indexprev);
          this.indexprev = event.index;
        }
      });
    } else {
      this.indexprev = event.index;
    }

  }

  botonesModificar() {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = true;
    this.btnRegresar = false;
  }

  botonesIniciar() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = false;
  }

  async seleccionEtiqueta(valor) {
    if (valor === 'nuevo') {
      this.nuevoetiqueta();
    }
    if (valor === 'eliminar') {
      this.eliminaretiqueta();
    }
    if (valor === 'cancelar') {
      this.cancelaretiqueta();
    }
    if (valor.object !== null && valor.object !== undefined) {
      this.detalleSeleccionado = valor.object;
    }
  }

  nuevoetiqueta() {
    /* if (this.permisoEtiqueta.PERINSERCION === 1) {
       this.botonesModificar();
 
       const etiquetanuevo = {
         CLI_CODIGO: this.tecencmant.CLI_CODIGO,
         EQUI_CODIGO: this.tecencmant.CLI_CODIGO,
         MANT_SERIE: this.tecencmant.CLI_CODIGO,
         COM_CODIGO: '01',
         ETI_CODIGO: '',
         DETMAT_DESCRIPCION: '',
         ETI_NOMBRE: '',
         CLAVE: ''
       };
 
       const indi = this.tecencmantService.tecdetmant[this.indicador].indexOf(this.detmantSeleccionado);
 
       if (indi === -1) {
         etiquetanuevo.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
         this.tecencmantService.tecdetmant[this.indicador].push(etiquetanuevo);
         this.detmantSeleccionado = etiquetanuevo;
         this.aggrid.refreshaggrid(this.tecencmantService.tecdetmant[this.indicador], this.defaultColDef.objeto);
         if (this.tecencmantService.tecdetmant[this.indicador].length === 1) {
           this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
             this.defaultColDef.objeto].setFocusedCell(0, 'ETI_CODIGO');
         } else {
           this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
             this.defaultColDef.objeto].setFocusedCell((this.tecencmantService.tecdetmant[this.indicador].length) - 1, 'ETI_CODIGO');
         }
       } else {
         etiquetanuevo.CLAVE = (indi + 1) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
         this.tecencmantService.tecdetmant[this.indicador].splice(indi + 1, 0, etiquetanuevo);
         this.detmantSeleccionado = etiquetanuevo;
         this.aggrid.refreshaggridindex(this.tecencmantService.tecdetmant[this.indicador], this.defaultColDef.objeto, indi);
         this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
           this.defaultColDef.objeto].setFocusedCell(indi + 1, 'ETI_CODIGO');
       }
     } else if (this.permisoEtiqueta.PERINSERCION === 0) {
       this.messageService.add({
         key: this.messagekey,
         severity: 'error',
         summary: 'Inserción',
         detail: 'El usuario no tiene permisos de inserción, acción denegada'
       });
     }*/
  }

  eliminaretiqueta() {
    /* if (this.permisoEtiqueta.PERELIMACION === 1) {
       this.confirmationService.confirm({
         message: 'Esta seguro que desea eliminar la etiqueta',
         header: 'Eliminar etiqueta',
         icon: 'pi pi-exclamation-triangle',
         key: this.confirmkey,
         acceptLabel: 'Si',
         rejectLabel: 'No',
         accept: async () => {
           this.confirmationService.close();
           const itemdet: TecDetmant = {};
           itemdet.CLI_CODIGO = this.tecencmant.CLI_CODIGO;
           itemdet.EQUI_CODIGO = this.tecencmant.EQUI_CODIGO;
           itemdet.MANT_SERIE = this.tecencmant.MANT_SERIE;
           itemdet.ETI_CODIGO = this.detmantSeleccionado.ETI_CODIGO;
           itemdet.DETMAT_DESCRIPCION = this.detmantSeleccionado.DETMAT_DESCRIPCION;
           await this.tecencmantService.eliminardetmant(itemdet);
           this.tecencmantService.tecdetmant[this.indicador].splice(this.tecencmantService.tecdetmant[this.indicador].findIndex(
             eti => eti.CLAVE === this.detmantSeleccionado.CLAVE), 1);
           this.aggrid.refreshaggrid(this.tecencmantService.tecdetmant[this.indicador], this.defaultColDef.objeto);
           this.messageService.add({
             key: this.messagekey,
             severity: 'success',
             summary: 'Equipos Cliente',
             detail: 'Etiqueta eliminada correctamente'
           });
         },
         reject: () => {
           this.confirmationService.close();
         }
       });
     } else if (this.permisoEtiqueta.PERELIMACION === 0) {
       this.messageService.add({
         key: this.messagekey,
         severity: 'error',
         summary: 'Inserción',
         detail: 'El usuario no tiene permisos de eliminación, acción denegada'
       });
     }*/
  }

  async cancelaretiqueta() {
    /*if (this.permisoEtiqueta.PERSELECCION === 1) {
      this.botonesIniciar();
      const data: any = await this.tecencmantService.getequiposetiquetas(this.tecencmant.EQUI_CODIGO);
      for (const rs of data) {
        rs.CLAVE = rs.ETI_CODIGO;
      }
      this.tecencmantService.tecdetmant[this.indicador] = data;
    } else if (this.permisoEtiqueta.PERSELECCION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de selección, acción denegada'
      });
    }*/
  }

  async busquedacliente(parametro, opcion) {
    if (parametro === null) {
      parametro = '';
    }
    if (opcion === '1') {
      this.opcionbusqueda = 'cliente';
    }
    if (opcion === '2') {
      this.opcionbusqueda = 'clientefac';
    }
    this.types = [
      { label: 'Código', value: 'CLI_CODIGO' },
      { label: 'Nombre', value: 'CLI_NOMBRE' },
      { label: 'RucIde', value: 'CLI_RUCIDE' },
      { label: 'Dirección', value: 'CLI_DIRECCION' },
      { label: 'Teléfono1', value: 'CLI_TELEFONO1' },
      { label: 'Teléfono2', value: 'CLI_TELEFONO2' },
      { label: 'Correo', value: 'CLI_CORREO' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', ''];
    this.consulta = '';
    this.tabla = 'VEN_MAECLIENTE';
    this.where = '';

    if (parametro !== '' && parametro !== undefined && parametro !== null) {
      const eR = await this.encordtraService.erpBusquedaCliente(this.tabla, this.types[0].value, parametro);
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
          return;
        }
      }
    }

    this.spin = true;
    this.busqService.busquedaVenMaeCliente3(parametro).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });

  }

  async manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'cliente') {
      // this.limpiardatos();
      this.encordtraService.encordtra[this.indicador].CLI_CODIGO = opcion.CLI_CODIGO;
      this.encordtraService.encordtra[this.indicador].CLI_NOMBRE = opcion.CLI_NOMBRE;
      this.encordtraService.encordtra[this.indicador].CLI_DIRECCION1 = opcion.CLI_DIRECCION1;
      this.encordtraService.encordtra[this.indicador].CLI_TELEFONO1 = opcion.CLI_TELEFONO1;
      this.encordtraService.encordtra[this.indicador].CLI_TELEFONO2 = opcion.CLI_TELEFONO2;
      this.encordtraService.encordtra[this.indicador].CLI_CORREO = opcion.CLI_CORREO;

    }

    if (this.opcionbusqueda === 'clientefac') {
      this.encordtraService.encordtra[this.indicador].CLI_CODIGO_FAC = opcion.CLI_CODIGO;
      this.encordtraService.encordtra[this.indicador].CLI_CODIGO_FACNOMBRE = opcion.CLI_NOMBRE;
    }

    if (this.opcionbusqueda === 'ordtra') {
      this.encontrarOrdenTrabajo(opcion.ORDTRA_NUMERO);
    }

    if (this.opcionbusqueda === 'equipo') {
      this.encordtraService.encordtra[this.indicador].ORDTRA_PRODUCTOCLI = opcion.EQUI_CODIGO;
      this.encordtraService.encordtra[this.indicador].ORDTRA_PRODUCTOCLINOMBRE = opcion.EQUI_NOMBRE;
      this.encordtraService.encordtra[this.indicador].ORDTRA_SERIE = opcion.MANT_SERIE;
      this.encordtraService.encordtra[this.indicador].ORDTRA_MARCA = opcion.MANT_MARCA;
      this.encordtraService.encordtra[this.indicador].ORDTRA_MODELO = opcion.MANT_MODELO;
      this.encordtraService.encordtra[this.indicador].ORDTRA_ORIGEN = opcion.MANT_ORIGEN;
      this.encordtraService.encordtra[this.indicador].MANT_DIAS_MANTE = opcion.MANT_DIAS_MANTE;
      this.encordtraService.encordtra[this.indicador].MANT_ULT_MANTE = new Date(opcion.MANT_ULT_MANTE);
      this.encordtraService.encordtra[this.indicador].MANT_PRX_MANTE = new Date(opcion.MANT_PRX_MANTE);
      /*const data2: any = await this.encordtraService.encontrarequipo(opcion.EQUI_CODIGO);
      for (const rs2 of data2) {
        this.encordtraService.encordtra[this.indicador].ORDTRA_PRODUCTOCLINOMBRE = rs2.EQUI_NOMBRE;
      }*/
      const item: any = {};
      item.CLI_CODIGO = this.encordtraService.encordtra[this.indicador].CLI_CODIGO;
      item.EQUI_CODIGO = this.encordtraService.encordtra[this.indicador].ORDTRA_PRODUCTOCLI;
      item.MANT_SERIE = this.encordtraService.encordtra[this.indicador].ORDTRA_SERIE;
      const data: any = await this.encordtraService.getdetmant(item);
      this.encordtraService.tableEtiquetas[this.indicador] = data;
      document.getElementById('ORDTRA_UBICACION').focus();
    }

    if (this.opcionbusqueda === 'vendedor') {
      this.encordtraService.encordtra[this.indicador].VEN_CODIGO = opcion.VEN_CODIGO;
      this.encordtraService.encordtra[this.indicador].VEN_NOMBRE = opcion.VEN_NOMBRE;
      document.getElementById('ORDTRA_OBSPRO').focus();
    }

    this.displayDialogBusquedaFast = false;
  }

  setFocus(event) {
    let element: any;
    switch (event.target.id) {
      case 'CLI_CODIGO': {
        this.busquedacliente(event.target.value, '1');
        break;
      }
      case 'CLI_CODIGO_FAC': {
        this.busquedacliente(event.target.value, '2');
        break;
      }
      case 'ORDTRA_PRODUCTOCLI': {
        this.busquedaequipo(event.target.value);
        break;
      }
      case 'VEN_CODIGO': {
        this.busquedavendedor(event.target.value);
        break;
      }
      case 'MANT_DIAS_MANTE': {
        element = document.getElementById('EQUI_CODIGO');
        break;
      }
    }

    if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }
  }

  limpiarDatosDocumento() {
    //txtRucCi.setEditable(true);
    //btnBuscarCli.setEnabled(true);
    this.encordtraService.encordtra[this.indicador] = {
      CLI_CODIGO: '',
      ORDTRA_PROBLEMA: '',
      ORDTRA_DIAGNOSTICO: '',
      ORDTRA_PROCEDIMIENTO: '',
      ORDTRA_OBSPRO: '',
      ORDTRA_PRODUCTOCLI: '',
      ORDTRA_SERIE: '',
      ORDTRA_UNIOPT: '',
      ORDTRA_UBICACION: '',
      ORDTRA_NUMERO: '',
      CLI_NOMBRE: '',
      USU_IDENTIFICACION: '',
      CLI_TELEFONO1: '',
      CLI_DIRECCION1: '',
      ORDTRA_ESTADO: 'Pendiente',
      ORDTRA_TOTAL: 0,
      ORDTRA_NETO: 0,
      ORDTRA_BASECERO: 0,
      ORDTRA_BASEIVA: 0,
      ORDTRA_IVA: 0,
      ORDTRA_FECHA: new Date(),
      ORDTRA_FECHAPROC: new Date(),
      ORDTRA_FECHAINGRESO: new Date(),
      ORDTRA_FECHADIAG: new Date(),
      ORDTRA_FECHAVENCE: new Date(),
      ORDTRA_HORAPROC: new Date(),
      ORDTRA_HORAINGRESO: new Date(),
      ORDTRA_HORADIAG: new Date(),
      ORDTRA_HORADIAGN: new Date(),
      ORDTRA_FECHAENTREGADO: new Date(),
      ORDTRA_HORAENTREGADO: new Date(),
      ORDTRA_FECHAMAXIMAGAR: new Date(),
      ORDTRA_MARCA: '',
      ORDTRA_MODELO: '',
      VEN_CODIGO: '',
      ORDTRA_FECHAASIGNACIONTEC: new Date(),
      ORDTRA_HORAASIGNACIONTEC: new Date(),
      CLI_TELEFONO2: '',
      CLI_CORREO: '',
      CLI_CODIGO_FAC: '',
      NUMFACTURA: '',
      MONTO: '',
      CLI_CODIGO_FACNOMBRE: '',
      ORDTRA_FALLAREPORTADA: '',
      ORDTRA_REPORTETECNICO: '',
      ORDTRA_PRODUCTOCLINOMBRE: '',
      ORDTRA_ESTADOOT: '',
      ORDTRA_ESTEQUIPO: ''
    }

    this.encordtraService.tableRegistroLLamadas[this.indicador] = [];
    this.encordtraService.tableEnvioCorreos[this.indicador] = [];
    this.tableDatosTecnicos = [];
    this.modeloDatTeclistarDatos('');
    this.tableHistorial = [];
    this.encordtraService.tableEtiquetas[this.indicador] = [];
    this.tipodetalle = 'A';
    /*
    txtDdSer.setText("");
    txtMemSer.setText("");
  	
    cmbEstadoOrdTra.deselectAll();
    cmnEstadoEquipo.deselectAll();
    */

  }

  async modeloDatTeclistarDatos(strNumOrdTra) {
    let data: any;
    if (strNumOrdTra !== '') {
      data = await this.encordtraService.getDatosTecnicosOrdenTrabajo(strNumOrdTra);
    }
    else {
      data = await this.encordtraService.getDatosTecnicos();
    }

    const lstVenTrnDatoTecOT = [];
    for (const rs of data) {
      const item: VenTrnrpttecnicoot = {};
      item.REF_CODIGO = rs.CODIGOMAESTRO;
      item.DESCRIPCION = rs.NOMBREMAESTRO;
      item.REF_TIPO = rs.TIPOMAESTRO == null ? "" : rs.TIPOMAESTRO;

      if (rs.REF_TIPO !== null) {
        item.SELECCION = true;
      }
      else {
        item.SELECCION = false;
      }

      lstVenTrnDatoTecOT.push(item);
    }

    this.tableDatosTecnicos = lstVenTrnDatoTecOT;
  }

  async mostrarDetalle(strEncOrdTraNro) {
    const data: any = await this.encordtraService.getDetordtra(strEncOrdTraNro);
    for (const rs of data) {
      rs.CLAVE = rs.DETORDTRA_LINEA;
      rs.DETORDTRA_CANTIDAD = Number(rs.DETORDTRA_CANTIDAD).toFixed(this.encordtraService.decimalesTotal);
      rs.DETORDTRA_PRECIO = Number(rs.DETORDTRA_PRECIO).toFixed(this.encordtraService.decimalesTotal);
      rs.DETORDTRA_TOTAL = Number(rs.DETORDTRA_TOTAL).toFixed(this.encordtraService.decimalesTotal);
    }
    this.encordtraService.tableDetalle[this.indicador] = data;
  } // fin listar datos

  async mostrarRegistroLLamadas(strEncOrdTraNro) {
    const data: any = await this.encordtraService.getRegllamada(strEncOrdTraNro);
    for (const rs of data) {
      rs.CLAVE = rs.TRNREGLLAMOT_LINEA;
      rs.TRNREGLLAMOT_FECHA = this.datePipe.transform(new Date(rs.TRNREGLLAMOT_FECHA), 'dd/MM/yyyy');
    }
    this.encordtraService.tableRegistroLLamadas[this.indicador] = data;
  } // fin listar datos

  async mostrarRegistroCorreos(strEncOrdTraNro) {
    const data: any = await this.encordtraService.getCorreos(strEncOrdTraNro);
    for (const rs of data) {
      rs.CLAVE = rs.TRNCORREOSOT_LINEA;
      rs.TRNCORREOSOT_FECHA = this.datePipe.transform(new Date(rs.TRNCORREOSOT_FECHA), 'dd/MM/yyyy');
    }
    this.encordtraService.tableEnvioCorreos[this.indicador] = data;
  } // fin listar datos

  async mostrarHistorial(strCodCliente) {
    const data: any = await this.encordtraService.getHistorial(strCodCliente);
    this.tableHistorial = data;
  } // fin listar datos

  async busquedaequipo(parametro) {
    this.opcionbusqueda = 'equipo';
    this.types = [
      { label: 'Código', value: 'EQUI_CODIGO' },
      { label: 'Nombre', value: 'EQUI_NOMBRE' },
      { label: 'Serie', value: 'MANT_SERIE' },
      { label: 'Marca', value: 'MANT_MARCA' },
      { label: 'Modelo', value: 'MANT_MODELO' },
      { label: 'Origen', value: 'MANT_ORIGEN' },
      { label: 'Días', value: 'MANT_DIAS_MANTE' },
      { label: 'Ultimo mant.', value: 'MANT_ULT_MANTE' },
      { label: 'Proximo mant.', value: 'MANT_PRX_MANTE' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', ''];
    this.consulta = 'A.EQUI_CODIGO, (SELECT EQUI_NOMBRE FROM TEC_MAEEQUIPO WHERE EQUI_CODIGO=A.EQUI_CODIGO) AS EQUI_NOMBRE,' +
      'A.MANT_SERIE, A.MANT_MARCA, A.MANT_MODELO, A.MANT_ORIGEN, A.CLI_CODIGO, A.MANT_DIAS_MANTE, A.MANT_ULT_MANTE,' +
      'A.MANT_PRX_MANTE';
    this.tabla = 'TEC_ENCMANT A';
    this.where = 'CLI_CODIGO=\'' + this.encordtraService.encordtra[this.indicador].CLI_CODIGO + '\'';

    this.busqService.busquedaTecEncMant1(this.encordtraService.encordtra[this.indicador].CLI_CODIGO, parametro).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  async busquedavendedor(parametro) {
    if (parametro === null) {
      parametro = '';
    }
    this.opcionbusqueda = 'vendedor';
    this.types = [
      { label: 'Código', value: 'VEN_CODIGO' },
      { label: 'Nombre', value: 'VEN_NOMBRE' }
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = '';
    this.tabla = 'VEN_MAEVENDEDOR';
    this.where = '';

    const eR = await this.encordtraService.erBusquedaVendedor(this.types[1].value, this.tabla, this.types[0].value, parametro);
    if (eR !== null) {
      if (eR[0] !== undefined) {
        this.manejarSeleccion(eR[0]);
      } else {
        this.spin = true;
        this.busqService.busquedaVenMaeVendedor3(parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.spin = false;
          this.displayDialogBusquedaFast = true;
        });
      }
    } else {
      this.spin = true;
      this.busqService.busquedaVenMaeVendedor3(parametro).subscribe((datos: any[]) => {
        this.arregloCons = datos;
        this.spin = false;
        this.displayDialogBusquedaFast = true;
      });
    }

  }

  async seleccionDetalle(valor) {
    if (valor === 'nuevo') {
      this.nuevodetalle();
    }
    if (valor === 'eliminar') {
      this.eliminardetalle();
    }
    if (valor === 'cancelar') {
      this.cancelardetalle();
    }
    if (valor.object !== null && valor.object !== undefined) {
      this.detalleSeleccionado = valor.object;
      this.tipodetalle = this.detalleSeleccionado.DETORDTRA_TIPODET;
    }
  }

  nuevodetalle() {
    if (this.permisoEncordtra.PERINSERCION === 1) {
      this.botonesModificar();
      if (this.tipodetalle === undefined) {
        this.tipodetalle = 'A';
      }

      const detallenuevo = {
        ORDTRA_NUMERO: this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO,
        COM_CODIGO: '01',
        DETORDTRA_LINEA: 0,
        DETORDTRA_TIPODET: this.tipodetalle,
        BOD_CODIGO: '',
        DETORDTRA_CODIGO: '',
        DETORDTRA_DESCRIPCION: '',
        DETORDTRA_UNIDAD: '',
        DETORDTRA_CANTIDAD: '1.0',
        DETORDTRA_PRECIO: '',
        DETORDTRA_TOTAL: '0',
        DETORDTRA_TRIBIVA: 'S',
        DETORDTRA_IVA: '0',
        CLAVE: ''
      };

      const indi = this.encordtraService.tableDetalle[this.indicador].indexOf(this.detalleSeleccionado);

      if (indi === -1) {
        detallenuevo.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.encordtraService.tableDetalle[this.indicador].push(detallenuevo);
        this.detalleSeleccionado = detallenuevo;
        this.aggrid.refreshaggrid(this.encordtraService.tableDetalle[this.indicador], this.defaultColDefDetalle.objeto);
        if (this.encordtraService.tableDetalle[this.indicador].length === 1) {
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
            this.defaultColDefDetalle.objeto].setFocusedCell(0, 'BOD_CODIGO');
        } else {
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
            this.defaultColDefDetalle.objeto].setFocusedCell((this.encordtraService.tableDetalle[this.indicador].length) - 1, 'BOD_CODIGO');
        }
      } else {
        detallenuevo.CLAVE = (indi + 1) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.encordtraService.tableDetalle[this.indicador].splice(indi + 1, 0, detallenuevo);
        this.detalleSeleccionado = detallenuevo;
        this.aggrid.refreshaggridindex(this.encordtraService.tableDetalle[this.indicador], this.defaultColDefDetalle.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefDetalle.objeto].setFocusedCell(indi + 1, 'BOD_CODIGO');
      }
    } else if (this.permisoEncordtra.PERINSERCION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de inserción, acción denegada'
      });
    }
  }

  eliminardetalle() {
    if (this.permisoEncordtra.PERELIMACION === 1) {
      this.confirmationService.confirm({
        message: 'Esta seguro que desea eliminar el detalle',
        header: 'Eliminar detalle',
        icon: 'pi pi-exclamation-triangle',
        key: this.confirmkey,
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: async () => {
          this.confirmationService.close();
          if (this.detalleSeleccionado.DETORDTRA_LINEA !== 0) {
            const itemdet: any = {};
            itemdet.ORDTRA_NUMERO = this.detalleSeleccionado.ORDTRA_NUMERO;
            itemdet.DETORDTRA_LINEA = this.detalleSeleccionado.DETORDTRA_LINEA;
            await this.encordtraService.eliminarDetordtra(itemdet);
          }

          this.encordtraService.tableDetalle[this.indicador].splice(this.encordtraService.tableDetalle[this.indicador].findIndex(
            eti => eti.CLAVE === this.detalleSeleccionado.CLAVE), 1);
          this.aggrid.refreshaggrid(this.encordtraService.tableDetalle[this.indicador], this.defaultColDefDetalle.objeto);
          this.messageService.add({
            key: this.messagekey,
            severity: 'success',
            summary: 'Detalle Orden de Trabajo',
            detail: 'Detalle eliminado correctamente'
          });
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    } else if (this.permisoEncordtra.PERELIMACION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de eliminación, acción denegada'
      });
    }
  }

  async cancelardetalle() {
    if (this.permisoEncordtra.PERSELECCION === 1) {
      this.botonesIniciar();
      await this.mostrarDetalle(this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO);
    } else if (this.permisoEncordtra.PERSELECCION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de selección, acción denegada'
      });
    }
  }

  async seleccionRegLlam(valor) {
    if (valor === 'nuevo') {
      this.nuevoregllam();
    }
    if (valor === 'eliminar') {
      this.eliminarregllam();
    }
    if (valor === 'cancelar') {
      this.cancelarregllam();
    }
    if (valor.object !== null && valor.object !== undefined) {
      this.regllamSeleccionado = valor.object;
    }
  }

  nuevoregllam() {
    if (this.permisoEncordtra.PERINSERCION === 1) {
      this.botonesModificar();

      const regllamnuevo = {
        ORDTRA_NUMERO: this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO,
        COM_CODIGO: '01',
        VEN_CODIGO: '',
        TRNREGLLAMOT_FECHA: this.datePipe.transform(new Date(), 'dd/MM/yyyy'),
        TRNREGLLAMOT_HORA: this.datePipe.transform(new Date(), 'HH:mm'),
        TRNREGLLAMOT_MOTIVO: '',
        TRNREGLLAMOT_LINEA: 0,
        VEN_NOMBRE: '',
        TRNREGLLAMOT_TIPO: '',
        CLAVE: ''
      };

      const indi = this.encordtraService.tableRegistroLLamadas[this.indicador].indexOf(this.regllamSeleccionado);

      if (indi === -1) {
        regllamnuevo.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.encordtraService.tableRegistroLLamadas[this.indicador].push(regllamnuevo);
        this.detalleSeleccionado = regllamnuevo;
        this.aggrid.refreshaggrid(this.encordtraService.tableRegistroLLamadas[this.indicador], this.defaultColDefRegistroLLamadas.objeto);
        if (this.encordtraService.tableRegistroLLamadas[this.indicador].length === 1) {
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
            this.defaultColDefRegistroLLamadas.objeto].setFocusedCell(0, 'TRNREGLLAMOT_FECHA');
        } else {
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
            this.defaultColDefRegistroLLamadas.objeto].setFocusedCell((this.encordtraService.tableRegistroLLamadas[this.indicador].length) - 1, 'TRNREGLLAMOT_FECHA ');
        }
      } else {
        regllamnuevo.CLAVE = (indi + 1) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.encordtraService.tableRegistroLLamadas[this.indicador].splice(indi + 1, 0, regllamnuevo);
        this.detalleSeleccionado = regllamnuevo;
        this.aggrid.refreshaggridindex(this.encordtraService.tableRegistroLLamadas[this.indicador], this.defaultColDefRegistroLLamadas.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefRegistroLLamadas.objeto].setFocusedCell(indi + 1, 'TRNREGLLAMOT_FECHA');
      }
    } else if (this.permisoEncordtra.PERINSERCION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de inserción, acción denegada'
      });
    }
  }

  eliminarregllam() {
    if (this.permisoEncordtra.PERELIMACION === 1) {
      this.confirmationService.confirm({
        message: 'Esta seguro que desea eliminar el registro',
        header: 'Eliminar registro',
        icon: 'pi pi-exclamation-triangle',
        key: this.confirmkey,
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: async () => {
          this.confirmationService.close();
          if (this.regllamSeleccionado.TRNREGLLAMOT_LINEA !== 0) {
            const itemdet: any = {};
            itemdet.ORDTRA_NUMERO = this.regllamSeleccionado.ORDTRA_NUMERO;
            itemdet.TRNREGLLAMOT_LINEA = this.regllamSeleccionado.TRNREGLLAMOT_LINEA;
            await this.encordtraService.eliminarRegllam(itemdet);
          }

          this.encordtraService.tableRegistroLLamadas[this.indicador].splice(this.encordtraService.tableRegistroLLamadas[this.indicador].findIndex(
            eti => eti.CLAVE === this.regllamSeleccionado.CLAVE), 1);
          this.aggrid.refreshaggrid(this.encordtraService.tableRegistroLLamadas[this.indicador], this.defaultColDefRegistroLLamadas.objeto);
          this.messageService.add({
            key: this.messagekey,
            severity: 'success',
            summary: 'Registro de llamada',
            detail: 'Registro eliminado correctamente'
          });
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    } else if (this.permisoEncordtra.PERELIMACION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de eliminación, acción denegada'
      });
    }
  }

  async cancelarregllam() {
    if (this.permisoEncordtra.PERSELECCION === 1) {
      this.botonesIniciar();
      await this.mostrarRegistroLLamadas(this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO);
    } else if (this.permisoEncordtra.PERSELECCION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de selección, acción denegada'
      });
    }
  }

  async seleccionContNotif(valor) {
    if (valor === 'nuevo') {
      this.nuevocontnotif();
    }
    if (valor === 'eliminar') {
      this.eliminarcontnotif();
    }
    if (valor === 'cancelar') {
      this.cancelarcontnotif();
    }
    if (valor.object !== null && valor.object !== undefined) {
      this.contnotifSeleccionado = valor.object;
    }
  }

  nuevocontnotif() {
    if (this.permisoEncordtra.PERINSERCION === 1) {
      this.botonesModificar();

      const contnotifnuevo = {
        ORDTRA_NUMERO: this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO,
        COM_CODIGO: '01',
        TRNCORREOSOT_LINEA: 0,
        TRNCORREOSOT_FECHA: this.datePipe.transform(new Date(), 'dd/MM/yyyy'),
        TRNCORREOSOT_HORA: this.datePipe.transform(new Date(), 'HH:mm'),
        VEN_CODIGO: '',
        VEN_NOMBRE: '',
        TRNCORREOSOT_MOTIVO: '',
        CLAVE: ''
      };

      const indi = this.encordtraService.tableEnvioCorreos[this.indicador].indexOf(this.contnotifSeleccionado);

      if (indi === -1) {
        contnotifnuevo.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.encordtraService.tableEnvioCorreos[this.indicador].push(contnotifnuevo);
        this.detalleSeleccionado = contnotifnuevo;
        this.aggrid.refreshaggrid(this.encordtraService.tableEnvioCorreos[this.indicador], this.defaultColDefEnvioCorreos.objeto);
        if (this.encordtraService.tableEnvioCorreos[this.indicador].length === 1) {
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
            this.defaultColDefEnvioCorreos.objeto].setFocusedCell(0, 'TRNCORREOSOT_FECHA');
        } else {
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
            this.defaultColDefEnvioCorreos.objeto].setFocusedCell((this.encordtraService.tableEnvioCorreos[this.indicador].length) - 1, 'TRNCORREOSOT_FECHA ');
        }
      } else {
        contnotifnuevo.CLAVE = (indi + 1) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.encordtraService.tableEnvioCorreos[this.indicador].splice(indi + 1, 0, contnotifnuevo);
        this.detalleSeleccionado = contnotifnuevo;
        this.aggrid.refreshaggridindex(this.encordtraService.tableEnvioCorreos[this.indicador], this.defaultColDefEnvioCorreos.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefEnvioCorreos.objeto].setFocusedCell(indi + 1, 'TRNCORREOSOT_FECHA');
      }
    } else if (this.permisoEncordtra.PERINSERCION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de inserción, acción denegada'
      });
    }
  }

  eliminarcontnotif() {
    if (this.permisoEncordtra.PERELIMACION === 1) {
      this.confirmationService.confirm({
        message: 'Esta seguro que desea eliminar el registro',
        header: 'Eliminar registro',
        icon: 'pi pi-exclamation-triangle',
        key: this.confirmkey,
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: async () => {
          this.confirmationService.close();
          if (this.contnotifSeleccionado.TRNCORREOSOT_LINEA !== 0) {
            const itemdet: any = {};
            itemdet.ORDTRA_NUMERO = this.contnotifSeleccionado.ORDTRA_NUMERO;
            itemdet.TRNCORREOSOT_LINEA = this.contnotifSeleccionado.TRNCORREOSOT_LINEA;
            await this.encordtraService.eliminarCorreo(itemdet);
          }

          this.encordtraService.tableEnvioCorreos[this.indicador].splice(this.encordtraService.tableEnvioCorreos[this.indicador].findIndex(
            eti => eti.CLAVE === this.contnotifSeleccionado.CLAVE), 1);
          this.aggrid.refreshaggrid(this.encordtraService.tableEnvioCorreos[this.indicador], this.defaultColDefEnvioCorreos.objeto);
          this.messageService.add({
            key: this.messagekey,
            severity: 'success',
            summary: 'Control de Notificaciones',
            detail: 'Registro eliminado correctamente'
          });
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    } else if (this.permisoEncordtra.PERELIMACION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de eliminación, acción denegada'
      });
    }
  }

  async cancelarcontnotif() {
    if (this.permisoEncordtra.PERSELECCION === 1) {
      this.botonesIniciar();
      await this.mostrarRegistroCorreos(this.encordtraService.encordtra[this.indicador].ORDTRA_NUMERO);
    } else if (this.permisoEncordtra.PERSELECCION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de selección, acción denegada'
      });
    }
  }

  async encontrarOrdenTrabajo(ordtranumero) {
    const data: any = await this.encordtraService.getOrdenTrabajo(ordtranumero);
    for (const rs of data) {
      rs.ORDTRA_FECHA = new Date(rs.ORDTRA_FECHA);
      rs.ORDTRA_FECHAENTREGADO = new Date(rs.ORDTRA_FECHAENTREGADO);
      rs.ORDTRA_FECHAMAXIMAGAR = new Date(rs.ORDTRA_FECHAMAXIMAGAR);
      rs.ORDTRA_FECHAASIGNACIONTEC = new Date(rs.ORDTRA_FECHAASIGNACIONTEC);
      rs.ORDTRA_HORAASIGNACIONTEC = new Date(rs.ORDTRA_FECHAASIGNACIONTEC);

      rs.ORDTRA_FECHAINGRESO = new Date(rs.ORDTRA_FECHAINGRESO);
      rs.ORDTRA_HORAINGRESO = new Date(rs.ORDTRA_FECHAINGRESO);
      rs.ORDTRA_HORADIAG = new Date(rs.ORDTRA_FECHADIAG);
      rs.ORDTRA_HORAPROC = new Date(rs.ORDTRA_FECHAPROC);
      rs.ORDTRA_NETO = Number(rs.ORDTRA_NETO).toFixed(this.encordtraService.decimalesTotal);
      rs.ORDTRA_BASEIVA = Number(rs.ORDTRA_BASEIVA).toFixed(this.encordtraService.decimalesTotal);
      rs.ORDTRA_IVA = Number(rs.ORDTRA_IVA).toFixed(this.encordtraService.decimalesTotal);
      rs.ORDTRA_BASECERO = Number(rs.ORDTRA_BASECERO).toFixed(this.encordtraService.decimalesTotal);
      rs.ORDTRA_TOTAL = Number(rs.ORDTRA_TOTAL).toFixed(this.encordtraService.decimalesTotal);
      this.encordtraService.encordtra[this.indicador] = rs;
    }

    if (this.encordtraService.encordtra[this.indicador].ORDTRA_ESTADO === "P") {
      this.encordtraService.encordtra[this.indicador].ORDTRA_ESTADO = "Pendiente";
    } else {
      this.encordtraService.encordtra[this.indicador].ORDTRA_ESTADO = "Cerrada";
    }

    const eR = await this.encordtraService.erpBusquedaCliente('VEN_MAECLIENTE', 'CLI_CODIGO', this.encordtraService.encordtra[this.indicador].CLI_CODIGO);
    if (eR !== null) {
      if (eR[0] !== undefined) {
        this.encordtraService.encordtra[this.indicador].CLI_CODIGO = eR[0].CLI_CODIGO;
        this.encordtraService.encordtra[this.indicador].CLI_NOMBRE = eR[0].CLI_NOMBRE;
        this.encordtraService.encordtra[this.indicador].CLI_DIRECCION1 = eR[0].CLI_DIRECCION1;
        this.encordtraService.encordtra[this.indicador].CLI_TELEFONO1 = eR[0].CLI_TELEFONO1;
        this.encordtraService.encordtra[this.indicador].CLI_TELEFONO2 = eR[0].CLI_TELEFONO2;
        this.encordtraService.encordtra[this.indicador].CLI_CORREO = eR[0].CLI_CORREO;
      }
    }

    const item: any = {};
    item.CLI_CODIGO = this.encordtraService.encordtra[this.indicador].CLI_CODIGO;
    item.EQUI_CODIGO = this.encordtraService.encordtra[this.indicador].ORDTRA_PRODUCTOCLI;
    item.MANT_SERIE = this.encordtraService.encordtra[this.indicador].ORDTRA_SERIE;
    const encm: any = await this.encordtraService.getencmant(item);
    for (let rs of encm) {
      this.encordtraService.encordtra[this.indicador].MANT_DIAS_MANTE = rs.MANT_DIAS_MANTE;
      this.encordtraService.encordtra[this.indicador].MANT_ULT_MANTE = new Date(rs.MANT_ULT_MANTE);
      this.encordtraService.encordtra[this.indicador].MANT_PRX_MANTE = new Date(rs.MANT_PRX_MANTE);
    }
    const detm: any = await this.encordtraService.getdetmant(item);
    this.encordtraService.tableEtiquetas[this.indicador] = detm;

    this.modeloDatTeclistarDatos(ordtranumero);
    this.mostrarDetalle(ordtranumero);
    this.mostrarRegistroLLamadas(ordtranumero);
    this.mostrarRegistroCorreos(ordtranumero);
    this.mostrarHistorial(this.encordtraService.encordtra[this.indicador].CLI_CODIGO);
    // this.despliegaEstadodelEquipo(ordtranumero);
  }

  rdbtipodet(params) {
    this.tipodetalle = params;
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos = false;
    }
  }


}
