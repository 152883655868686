//
// SACI WEB
// Rutina: Maestro de Unidades
// Propietario: Wilson Ramos
// Modificado por: WR
// Fecha de creación: 2019
// Fecha de Modificación: 13-01-2020
//
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {InvmaeunidadesService} from '../invservicios/invmaeunidades.service';
import {Invmaeunidad} from '../invinterfaces/invmaeunidad';
import {Invmaeunidadequi} from '../invinterfaces/invmaeunidadequi';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {InvbusquedaService} from '../invservicios/invbusqueda.service';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Component({
  selector: 'app-invmaeunidades',
  templateUrl: './invmaeunidades.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css'],
  preserveWhitespaces: false
})
export class InvmaeunidadesComponent implements OnInit, OnDestroy {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonRegresar: boolean;
  botonBorrar: boolean;
  barraBotones2: boolean;
  unidades: Invmaeunidad[];
  unidadesAux: Invmaeunidad[];
  permisosUnidades: SegMaePermiso;
  unidadSeleccionada: Invmaeunidad;
  equivalenciasAux: Invmaeunidadequi[];
  equivalenciaSeleccionada: Invmaeunidadequi;
  types: SelectItem[];
  index: number;
  largo: string;
  public frameworkComponents;
  rowStyle;
  defaultColDefUnidad;
  defaultColDefEquivalencia;
  rowSelection = 'multiple';
  columnDefsUnidad = [
    {
      headerName: 'Código', field: 'UNI_CODIGO', cellEditor: 'cellPrueba',
      // editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10
          }
        }
    },
    {
      headerName: 'Nombre', field: 'UNI_NOMBRE', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 45
          }
        }
    },
    {
      headerName: 'Símbolo', field: 'UNI_SIMBOLO', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10
          }
        }
    },
    {
      headerName: 'Orden', field: 'UNI_ORDEN', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: true,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 10
          }
        }
    }];

  columnDefsEquivalencia = [
    {
      headerName: 'Factor', field: 'EQU_FACTOR', cellEditor: 'cellPrueba',
      // editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            regexp: /^[/]$|^[*]$/
          }
        }
    },
    {
      headerName: 'Cantidad', field: 'EQU_CANTIDAD', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 32
          }
        }
    },
    {
      headerName: 'U. Destino', field: 'UNI_CODIGODESTINO', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: true,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            opcionbuscar: true
          }
        }
    }
   ];

  constructor(public unidadService: InvmaeunidadesService, private busquedaSer: InvbusquedaService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService, private errorService: ErroresBaseDatosService,
              private permisosService: PermisosService, private auditoriagral: AuditoriagralService,
              private confIniciales: ConfInicialesService, private init: NuevoComponentService) {
    this.agTable();
    this.unidadService.maeunidades = [];
    this.unidadService.maeequivalencias = [];
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefUnidad = {
      editable: true,
      width: 200,
      objeto: 'maeunidad'
    };
    this.defaultColDefEquivalencia = {
      editable: true,
      width: 200,
      objeto: 'maeequivalencia'
    };
  }
  
  ngOnInit() {
    this.index = 0;
    this.unidades = [];
    this.unidadSeleccionada = {};
    this.permisosService.getListaPermisos('INV', '1', '75').subscribe((res) => {
      this.permisosUnidades = res[0];
    });
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonRegresar = true;
    this.botonBorrar = false;
    this.barraBotones2 = true;
    this.largo = '258';
    this.unidadService.getUnidades().subscribe((res) => {
      if (res.length !== 0) {
        this.unidadService.maeunidades = res;
        this.unidadSeleccionada = this.unidadService.maeunidades[this.unidadService.maeunidades.length - 1];
        this.unidadService.getEquivalencias(this.unidadSeleccionada.UNI_CODIGO).subscribe((respuesta) => {
          this.unidadService.maeequivalencias = respuesta;
        });
      }
    });
  }
  ngOnDestroy(): void {
    this.confIniciales.instanciaUnidades--;
  }
  
  seleccionUnidad(params) {
    if (this.index === 0) {
      if (params.object !== undefined) {
        this.unidadSeleccionada = params.object;
        this.unidadService.getEquivalencias(this.unidadSeleccionada.UNI_CODIGO).subscribe((res) => {
          this.unidadService.maeequivalencias = res;
          
        });
      } else {
        this.unidadService.getEquivalencias(this.unidadSeleccionada.UNI_CODIGO).subscribe((res) => {
          this.unidadService.maeequivalencias = res;
        });
      }
    }
  }
  seleccionEquivalencia(params) {
    if (this.index === 1) {
      this.equivalenciaSeleccionada = params.object;
    }
  }
  
  cargarTabla() {
      if (this.index === 0) {
        this.botonGuardar = true;
        this.botonRegresar = true;
        this.botonNuevo = false;
        this.aggrid.refreshaggrid(this.unidadService.maeunidades, this.defaultColDefUnidad.objeto);
      } else if (this.index === 1) {
        this.botonGuardar = true;
        this.botonRegresar = true;
        this.botonNuevo = false;
        this.aggrid.refreshaggrid(this.unidadService.maeequivalencias, this.defaultColDefEquivalencia.objeto);
      }
  }
  
  cambio(cambio) {
    if (cambio === true) {
      this.botonGuardar = false;
      this.botonRegresar = false;
      this.botonNuevo = true;
    }
  }
  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      if (this.permisosUnidades.PERINSERCION === 1) {
        this.nuevaUnidad();
      } else if (this.permisosUnidades.PERINSERCION === 0) {
        this.messageService.add({
          key: 'uni',
          severity: 'error',
          summary: 'Inserción de Unidad',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    } else if (valor === 'Guardar') {
      if (this.permisosUnidades.PERACTUALIZACION === 1) {
        if (this.index === 0) {
          this.unidadService.saveUnidades(this.unidadSeleccionada);
          this.botonNuevo = false;
          this.botonRegresar = true;
          this.botonGuardar = true;
        } else if (this.index === 1) {
          this.unidadService.saveEquivalencia(this.equivalenciaSeleccionada, this.unidadSeleccionada);
          this.botonNuevo = false;
          this.botonRegresar = true;
          this.botonGuardar = true;
        }
      } else if (this.permisosUnidades.PERACTUALIZACION === 0) {
        this.messageService.add({
          key: 'uni',
          severity: 'error',
          summary: 'Actualización de Unidad',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }
    } else if (valor === 'Borrar') {
      if (this.permisosUnidades.PERELIMACION === 1) {
        if (this.unidadSeleccionada === undefined) {
          this.messageService.add({
            key: 'uni',
            severity: 'error',
            summary: 'Eliminacion de Unidad',
            detail: 'Selección Incorrecta, no se ha seleccionado una unidad'
          });
        } else   {
          this.confirmarBorrar();
        }
      } else if (this.permisosUnidades.PERELIMACION === 0) {
        this.messageService.add({
          key: 'uni',
          severity: 'error',
          summary: 'Eliminación de Unidad',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
      }
    } else if (valor === 'Cancelar') {
      this.cancelarCambios();
    }
  
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  
  
  }
  nuevaUnidad() {
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonRegresar = false;
    if (this.index === 0) {
      const unidadNueva: Invmaeunidad = {
        UNI_CODIGO: '', UNI_NOMBRE: '', COM_CODIGO: '01', UNI_ORDEN: '', UNI_FLAG: '', UNI_SIMBOLO: ''
      };
      this.unidadService.maeunidades.push(unidadNueva);
      this.aggrid.refreshaggrid(this.unidadService.maeunidades, this.defaultColDefUnidad.objeto);
      this.unidadSeleccionada = this.unidadService.maeunidades[this.unidadService.maeunidades.length - 1];
    } else if (this.index === 1) {
      const equivalenciaNueva: Invmaeunidadequi = {
        EQU_FACTOR: '', EQU_CANTIDAD: '', UNI_CODIGODESTINO: '', UNI_CODIGO: this.unidadSeleccionada.UNI_CODIGO
      };
      this.unidadService.maeequivalencias.push(equivalenciaNueva);
      this.aggrid.refreshaggrid(this.unidadService.maeequivalencias, this.defaultColDefEquivalencia.objeto);
      this.equivalenciaSeleccionada = this.unidadService.maeequivalencias[this.unidadService.maeequivalencias.length - 1];
    }
  }
  confirmarBorrar() {
    if (this.index === 0) {
      this.confirmationService.confirm({
        message: 'Esta seguro que desea eliminar la unidad',
        header: 'Eliminar Unidad',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarUni',
        accept: () => {
          this.borrarUnidad();
        },
        reject: () => {
        }
      });
    } else if (this.index === 1) {
      this.confirmationService.confirm({
        message: 'Esta seguro que desea eliminar la equivalencia',
        header: 'Eliminar Equivalencia',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarUni',
        accept: () => {
          this.borrarEquivalencia();
        },
        reject: () => {
        }
      });
    }
  }
  cancelarCambios() {
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonRegresar = true;
    if (this.index === 0) {
      this.unidadService.getUnidades().subscribe((respuesta) => {
        this.unidadesAux = respuesta;
        this.unidadService.maeunidades = this.unidadesAux;
      });
    } else if (this.index === 1) {
      this.unidadService.getEquivalencias(this.unidadSeleccionada.UNI_CODIGO).subscribe((res => {
        this.equivalenciasAux = res;
        this.unidadService.maeequivalencias = this.equivalenciasAux;
      }));
    }
  }
  borrarUnidad() {
    if (this.unidadSeleccionada.UNI_CODIGO !== '') {
      if (this.unidadSeleccionada === undefined) {
        this.unidadService.getEquivalencias(this.unidadSeleccionada.UNI_CODIGO).subscribe((res) => {
          if (res.length === 0) {
            this.unidadService.borrarUnidad(this.unidadService.maeunidades[this.unidadService.maeunidades.length - 1].UNI_CODIGO).subscribe(() => {
              this.messageService.add({
                key: 'uni',
                severity: 'success',
                summary: 'Eliminación de Unidad',
                detail: 'La Unidad se eliminó correctamente'
              });
              this.auditoriagral.registrarAuditoria('INV_MAEUNIDAD', String(this.unidadSeleccionada.UNI_CODIGO), 'E', '',
                '01', '', '', '', '').subscribe(() => {
              });
              this.unidadService.maeunidades = this.unidadService.maeunidades.filter((val, j) => j !==
                this.unidadService.maeunidades.indexOf(this.unidadService.maeunidades[this.unidadService.maeunidades.length - 1]));
            }, error1 => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.messageService.add({
                key: 'uni',
                severity: 'error',
                summary: 'Eliminación de Unidad',
                detail: mensaje
              });
            });
            return;
          } else {
            this.messageService.add({
              key: 'uni',
              severity: 'error',
              summary: 'Eliminación de Unidad',
              detail: 'La Unidad tiene equivalencias, borre las equivalencias en primer lugar'
            });
            return;
          }
        });
      }
      this.botonNuevo = false;
      this.botonRegresar = true;
      this.botonGuardar = true;
      let contador = 0;
      let i = 0;
      do {
        if (this.unidadSeleccionada.UNI_CODIGO === this.unidadService.maeunidades[i].UNI_CODIGO) {
          contador++;
        }
        i++;
      } while (this.unidadService.maeunidades.length > i);
      if (contador === 1) {
        this.unidadService.getEquivalencias(this.unidadSeleccionada.UNI_CODIGO).subscribe((res) => {
          if (res.length === 0) {
            this.unidadService.borrarUnidad(this.unidadSeleccionada.UNI_CODIGO).subscribe(() => {
              this.messageService.add({
                key: 'uni',
                severity: 'success',
                summary: 'Eliminación de Unidad',
                detail: 'La Unidad se eliminó correctamente'
              });
              this.auditoriagral.registrarAuditoria('INV_MAEUNIDAD', String(this.unidadSeleccionada.UNI_CODIGO), 'E', '',
                '01', '', '', '', '').subscribe(() => {
              });
              this.unidadService.maeunidades = this.unidadService.maeunidades.filter((val, j) => j !==
                this.unidadService.maeunidades.indexOf(this.unidadSeleccionada));
            }, error1 => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.messageService.add({
                key: 'uni',
                severity: 'error',
                summary: 'Eliminación de Unidad',
                detail: mensaje
              });
            });
            return;
          } else {
            this.messageService.add({
              key: 'uni',
              severity: 'error',
              summary: 'Eliminación de Unidad',
              detail: 'La Unidad tiene equivalencias, borre las equivalencias en primer lugar'
            });
            return;
          }
        });
      } else if (contador !== 1) {
        this.unidadService.maeunidades = this.unidadService.maeunidades.filter((val, j) => j !==
          this.unidadService.maeunidades.indexOf(this.unidadSeleccionada));
      }
    } else {
      this.unidadService.maeunidades = this.unidadService.maeunidades.filter((val, j) => j !==
        this.unidadService.maeunidades.indexOf(this.unidadSeleccionada));
    }
  }
  borrarEquivalencia() {
    if (this.equivalenciaSeleccionada === undefined) {
      this.unidadService.borrarEquivalencia(this.unidadService.maeequivalencias[this.unidadService.maeequivalencias.length - 1])
        .subscribe(() => {
        this.messageService.add({
          key: 'uni',
          severity: 'success',
          summary: 'Eliminación de Equivalencia',
          detail: 'La Equivalencia se eliminó correctamente'
        });
        this.auditoriagral.registrarAuditoria('INV_MAEUNIDADEQUI', String(this.equivalenciaSeleccionada.UNI_CODIGO), 'E', '',
            '01', '', '', '', '').subscribe(() => {});
        this.unidadService.maeequivalencias = this.unidadService.maeequivalencias.filter((val, j) => j !==
          this.unidadService.maeequivalencias.indexOf(this.unidadService.maeequivalencias[this.unidadService.maeequivalencias.length - 1]));
      }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'uni',
          severity: 'error',
          summary: 'Eliminación de Equivalencia',
          detail: mensaje
        });
      });
      return;
    }
    this.botonNuevo = false;
    this.botonRegresar = true;
    this.botonGuardar = true;
    let contador = 0;
    let i = 0;
    do {
      if (this.equivalenciaSeleccionada.UNI_CODIGODESTINO === this.unidadService.maeequivalencias[i].UNI_CODIGODESTINO) {
        contador++;
      }
      i++;
    } while (this.unidadService.maeequivalencias.length > i);
    if (contador === 1) {
      this.unidadService.borrarEquivalencia(this.equivalenciaSeleccionada).subscribe(() => {
        this.messageService.add({
          key: 'uni',
          severity: 'success',
          summary: 'Eliminación de Equivalencia',
          detail: 'La Equivalencia se eliminó correctamente'
        });
        this.auditoriagral.registrarAuditoria('INV_MAEUNIDADEQUI', String(this.equivalenciaSeleccionada.UNI_CODIGO), 'E', '',
          '01', '', '', '', '').subscribe(() => {});
        this.unidadService.maeequivalencias = this.unidadService.maeequivalencias.filter((val, j) => j !==
          this.unidadService.maeequivalencias.indexOf(this.equivalenciaSeleccionada));
      }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'uni',
          severity: 'error',
          summary: 'Eliminación de Equivalencia',
          detail: mensaje
        });
      });
    } else if (contador !== 1) {
      this.unidadService.maeequivalencias = this.unidadService.maeequivalencias.filter((val, j) => j !==
        this.unidadService.maeequivalencias.indexOf(this.equivalenciaSeleccionada));
    }
  }
}
