<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="busdocpagados"></p-toast>

<div class="p-grid" style="width: 100%">
	<div class="p-col-12">
		<app-invmaebarra [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"
        [barraBotones5]="false" [barraBotonesCRUD]="true"
        [barraBotones3Alt]="false"  [botonBuscar]="false" [botonBinocular]="true" (signal)="manejarSenales($event)" >	
		</app-invmaebarra>
	</div>
</div>

<div class="divgrups">
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-1">
				<span>Proveedor:</span>
			</div>
			<div class="p-col-2">
				<input id="PRO_CODIGO" type="text" pInputText autocomplete="off"
					   style="float: left" [(ngModel)]="txtProCodigo">
			</div>
			<div class="p-col-1">
				<button type="button"
						style="background-image: url(../../../assets/images/iconos/buscar.png); "
						class="littlebuttons" (click)="desplegarDatosProveedor(txtProCodigo)"></button>
			</div>
			<div class="p-col-5">
				<input id="PRO_NOMBRE" type="text" pInputText autocomplete="off" disabled [(ngModel)]="txtProNombre">
			</div>

		</div>
	</div>
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-1">
				<span>Nro.Factura:</span>
			</div>
			<div class="p-col-2">
		        <input id="FAC_NUMERO" type="text" pInputText autocomplete="off" maxlength="10"
                           style="float: left" [(ngModel)]="txtNumFactura">
			</div>
			<div class="p-col-2">
			</div>
		</div>
    </div>
    <app-aggridsaci [width]=""
								[height]="largo"
								[enableSorting]="true"
								[enableFilter]="true"
								[rowData]="detalleDoc"
								[frameworkComponents]="frameworkComponents"
								[animateRows]="true"
								[rowSelection]="rowSelection"
								[columnDefs]="columnDefsDocPagados"
								[defaultColDef]="defaultDocPagados"
								[rowStyle]="rowStyle"
								[singleClickEdit]="false"
								[botones]="false"
								[mostrarGuardar]="false"
								[botonesAdicionales]="false"
				>
				</app-aggridsaci>
</div>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>