<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="servicios"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarServicios" title="Confirmar Acción"
                 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 99%">
  <div class="p-col-12">
    <app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
                     [botonRegresar]="btnRegresar" [barraBotonesCRUD]="false"
                     [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true"
                     (signal)="manejarSenales($event)"></app-invmaebarra>
  </div>
  <div style="overflow: hidden" class="p-col-12">
    <p-tabView>
      <p-tabPanel header="Servicios">
        <app-aggridsaci [width]=""
                        [height]="largo"
                        [enableSorting]="false"
                        [enableFilter]="true"
                        [rowData]="serviService.venmaeservicios"
                        [frameworkComponents]="frameworkComponents"
                        [animateRows]="true"
                        [rowSelection]="rowSelection"
                        [columnDefs]="columnDefsServicios"
                        [defaultColDef]="defaultColDefServicios"
                        [rowStyle]="rowStyle"
                        [singleClickEdit]="false"
                        [botones]="false"
                        (selected)="selectedServicio($event)"
                        (cambios)="cambio()"
        ></app-aggridsaci>
      </p-tabPanel>
    </p-tabView>
  </div>


</div>
<!-- CDPJ -->
<div class="layout-config">
	<div class="layout-config-content-wrapper">
		<a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones()">
			<i class="pi pi-bars" style="cursor: pointer"></i>
		</a>
		<p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
				   [dismissible]="true" [showCloseIcon]="false">
			<br>
			<br>
			<br>
			<p-tabView>
				<p-tabPanel header="Acciones">
					<div class="p-grid">
						<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 200px)'}">

							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="actualizariva()"
									class="archivoOpc">Actualizar IVA</span>
							</div>
						</p-scrollPanel>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-sidebar>
	</div>
</div>
<p-dialog header="Actualización de IVA" [(visible)]="displayDialogactiva" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '35vw', height: 'auto'}">
	<div class="p-grid">
		<div class="p-col-1">
			<button pButton (click)="aplicarActualizar()"
				style="background-image: url(../../../assets/images/iconos/visto.png);
				background-size: contain !important; width: 35px; height: 35px">
			</button>			
		</div>
		<div class="p-col-1">
			<button pButton (click)="cancelariva()"
				style="background-image: url(../../../assets/images/iconos/regresar.png); 
				background-size: contain !important; width: 35px; height: 35px">
		</button>
		</div>		
	</div>
	<div class="p-grid" >
		<div class="p-col-1">
			<span>IVA:</span>
		</div>
		<div class="p-col-4">
			<input id="tariivaporcentaje" type="text" pInputText  autocomplete="off" [(ngModel)]="tariivaporcentaje"
			(keydown.enter)="busquedaivacod(tariivaporcentaje)">
		</div>
		<div class="p-col-1">
			<button type="button"
			style="background-image: url(../../../assets/images/iconos/buscar.png);"
			(click)="busquedaivacod(tariivaporcentaje)"></button>	
		</div>	
		<div class="p-col-1">
			<span style="float: left"> {{tariivadescripcion}}</span>
		</div>	
	</div>
	<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFasttariva" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
		[busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>
<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="artiva"></p-toast>
</p-dialog>
<p-dialog header="Clave Autorización" [(visible)]="claveDialog" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="commaeserclave"></p-toast>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-4">
					<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-4">
					<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
						(keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
				</div>
				<div class="p-col-1"></div>
			</div>
		</div>
	</div>
</p-dialog>
<!-- CDPJ -->
