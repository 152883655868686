//
// SACI WEB
// Rutina: Maestro de cuentas bancarias
// Propietario: Francisco Cevallos
// Modificado por: FC
// Fecha de creación: 25-11-2019
// Fecha de Modificación: 08-01-2020
//
import {Component, OnInit} from '@angular/core';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {DatePipe} from '@angular/common';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {BanmaebanService} from '../banservicios/banmaeban.service';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {Banmaeban} from '../baninterfaces/banmaeban';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Component({
  selector: 'app-banmaeban',
  templateUrl: './banmaeban.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class BanmaebanComponent implements OnInit {
  
  cuentaBanSeleccionada: Banmaeban;
  cuentaBanSeleccionadaAux: Banmaeban;
  // manejadores de los combos box
  opcionesNomBan: SelectItem[];
  opcionesScrsBan: SelectItem[];
  opcionesTipoCnt: SelectItem[];
  opcionesProCM: SelectItem[];
  opcionesBusqueda: SelectItem[];
  listaNomBan: any[];
  listaScrsBan: any[];
  listaTipoCnt: any[];
  listaProCM: any[];
  desabilitarCampos: boolean;
  desabilitarCC: boolean;
  tabla: string;
  busquedacampos: string[];
  
  // declaracion de botons y elementos del html
  btnNuevo: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  btnGuardar: boolean;
  btnPrimero: boolean;
  btnAnterior: boolean;
  btnSiguiente: boolean;
  btnUltimo: boolean;
  inputNum: boolean;
  indice: number;
  opcion: string;
  permisoCntBnc: SegMaePermiso;
  types: SelectItem[];
  codigoAnt: string;
  arregloBus: any[];
  opcionDropDown: string;
  // tabla
  displayDialogBusquedaFast: boolean;
  
  checkcheque: boolean;
  displayDialogBus: boolean;
  // tabla
  public frameworkComponents;
  largo: string;
  rowSelection = 'multiple';
  rowStyle;
  defaultColDefEstCnt;
  
  columnDefsEstCnt = [
    {
      headerName: 'Fecha', field: 'Fecha', width: 100, editable: false,
      
    },
    {
      headerName: 'Número', field: 'Número', width: 100, editable: false,
      
    },
    {
      headerName: 'Tipo', field: 'Tipo', width: 100, editable: false,
      
    },
    {
      headerName: 'Concepto', field: 'Concepto', width: 400, editable: false,
      
    },
    {
      headerName: 'Debe ', field: 'Debe', width: 100, editable: false, cellStyle: {textAlign: 'right'},
      
    },
    {
      headerName: 'Haber', field: 'Haber', width: 100, editable: false, cellStyle: {textAlign: 'right'},
      
    },
    {
      headerName: 'Saldo ', field: 'Saldo', width: 100, editable: false, cellStyle: {textAlign: 'right'},
      
    },
  ];
  
  constructor(public banmaebanService: BanmaebanService, private busquedaSer: InvbusquedaService, private datePipe: DatePipe,
              private message: MessageService, private confirmationService: ConfirmationService,
              private errorService: ErroresBaseDatosService, private auditoriagral: AuditoriagralService,
              private permisosService: PermisosService, private init: NuevoComponentService) {
    this.opcionesNomBan = [];
    this.opcionesScrsBan = [];
    this.opcionesTipoCnt = [];
    this.opcionesProCM = [];
    this.opcionesBusqueda = [];
    this.listaNomBan = [];
    this.listaScrsBan = [];
    this.listaTipoCnt = [];
    this.listaProCM = [];
    this.agTable();
    this.permisoCntBnc = {};
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefEstCnt = {
      width: 100,
      objeto: 'banmaeban'
    };
  }
  
  ngOnInit() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.btnPrimero = false;
    this.btnAnterior = false;
    this.btnSiguiente = false;
    this.btnUltimo = false;
    this.indice = 0;
    this.inputNum = true;
    this.desabilitarCC = true;
    this.desabilitarCampos = true;
    this.checkcheque = false;
    this.displayDialogBus = false;
    this.largo = '400';
    this.permisosService.getListaPermisos('BAN', '1', '28').subscribe((permiso) => {
      this.permisoCntBnc = permiso[0];
    });
    this.codigoAnt = '';
    this.cuentaBanSeleccionada = {};
    this.cuentaBanSeleccionada.BAN_CODIGO = '';
    this.cuentaBanSeleccionada.CON_CODIGO = '';
    this.cuentaBanSeleccionadaAux = {};
    this.banmaebanService.getCntBancarias().subscribe((res) => {
      this.banmaebanService.banmaeban = res;
    });
    this.opcionDropDown = '';
    this.obtenerBan();
    this.opcionesTipoCnt = [{label: '', value: ''}, {label: 'Ahorros', value: 'Ahorros'},
      {label: 'Corriente', value: 'Corriente'}, {label: 'Inversiones', value: 'Inversiones'}];
    this.opcionesBusqueda = [{label: 'Fecha', value: 'Fecha'},
      {label: 'Número', value: 'Número'}, {label: 'Tipo', value: 'Tipo'},
      {label: 'Concepto', value: 'Concepto'}, {label: 'Debe', value: 'Debe'},
      {label: 'Haber', value: 'Haber'}, {label: 'Saldo', value: 'Saldo'}];
    this.obtenerProCM();
  }
  
  
  manejarSenales(valor) {
    if (valor === 'Buscar') {
      
      this.buscarCntsBan();
      document.getElementById('contorno').style.pointerEvents = 'all';
    }
    if (valor === 'Nuevo') {
      
      if (this.permisoCntBnc.PERINSERCION === 1) {
        this.desabilitarCampos = false;
        document.getElementById('contorno').style.pointerEvents = 'all';
        this.nuevoCntBan();
      } else if (this.permisoCntBnc.PERINSERCION === 0) {
        this.message.add({
          key: 'banmaebanKey',
          severity: 'error',
          summary: 'Inserción de Cuenta Bancaria',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
      
    }
    if (valor === 'Cancelar') {
      this.cancelarCntBan();
    }
    if (valor === 'Guardar') {
      if (this.permisoCntBnc.PERINSERCION === 1) {
        this.guardarCntBan();
      } else if (this.permisoCntBnc.PERINSERCION === 0) {
        this.message.add({
          key: 'banmaebanKey',
          severity: 'error',
          summary: 'Actualización de Cuenta Bancaria',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }
      
    }
    if (valor === 'Borrar') {
      if (this.permisoCntBnc.PERELIMACION === 1) {
        this.confirmarCntBan();
      } else if (this.permisoCntBnc.PERELIMACION === 0) {
        this.message.add({
          key: 'banmaebanKey',
          severity: 'error',
          summary: 'Eliminación de Cuenta Bancaria',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
      }
      
    }
    if (valor === 'Primero') {
      this.banmaebanService.banmaebanEstCnt = []
      this.desabilitarCampos = true;
      this.btnAnterior = true;
      this.btnPrimero = true;
      this.btnUltimo = false;
      this.btnSiguiente = false;
      this.btnRegresar = false;
      this.btnGuardar = true;
      this.indice = 0;
      this.cuentaBanSeleccionada = this.banmaebanService.banmaeban[this.indice];
      this.cuentaBanSeleccionadaAux = this.banmaebanService.banmaeban[this.indice];
      this.codigoAnt = this.cuentaBanSeleccionada.BAN_CODIGO;
      if (this.cuentaBanSeleccionada.BAN_DINEROELEC === '1') {
        this.cuentaBanSeleccionada.BAN_DINEROELEC = true;
      } else if (this.cuentaBanSeleccionada.BAN_DINEROELEC === null) {
        this.cuentaBanSeleccionada.BAN_DINEROELEC = false;
      }
      this.banmaebanService.obtenerEstCntBnc(this.cuentaBanSeleccionada).subscribe((res) => {
        if (res !== null) {
          this.banmaebanService.banmaebanEstCnt = res;
          this.banmaebanService.banmaebanEstCnt.map((estcnt) => {
            estcnt.Fecha = estcnt.Fecha.substring(0, 10);
            estcnt.Debe = Number(estcnt.Debe).toFixed(2);
            estcnt.Haber = Number(estcnt.Haber).toFixed(2);
            estcnt.Saldo = Number(estcnt.Saldo).toFixed(2);
          });
        }
      });
      document.getElementById('contorno').style.pointerEvents = 'all';
    }
    if (valor === 'Anterior') {
      this.banmaebanService.banmaebanEstCnt = []
      this.desabilitarCampos = true;
      if (this.btnUltimo === true && this.btnSiguiente === true) {
        this.btnUltimo = false;
        this.btnSiguiente = false;
      }
      this.btnRegresar = false;
      this.btnGuardar = true;
      this.indice--;
      if (this.indice <= 0) {
        this.btnPrimero = true;
        this.btnAnterior = true;
      }
      
      this.cuentaBanSeleccionada = this.banmaebanService.banmaeban[this.indice];
      this.cuentaBanSeleccionadaAux = this.banmaebanService.banmaeban[this.indice];
      this.obtenerSaldo(this.cuentaBanSeleccionada);
      this.obtenerSucBan(this.cuentaBanSeleccionada);
      this.codigoAnt = this.cuentaBanSeleccionada.BAN_CODIGO;
      if (this.cuentaBanSeleccionada.BAN_DINEROELEC === '1') {
        this.cuentaBanSeleccionada.BAN_DINEROELEC = true;
      } else if (this.cuentaBanSeleccionada.BAN_DINEROELEC === null) {
        this.cuentaBanSeleccionada.BAN_DINEROELEC = false;
      }
      this.banmaebanService.obtenerEstCntBnc(this.cuentaBanSeleccionada).subscribe((res) => {
        if (res !== null) {
          this.banmaebanService.banmaebanEstCnt = res;
          this.banmaebanService.banmaebanEstCnt.map((estcnt) => {
            estcnt.Fecha = estcnt.Fecha.substring(0, 10);
            estcnt.Debe = Number(estcnt.Debe).toFixed(2);
            estcnt.Haber = Number(estcnt.Haber).toFixed(2);
            estcnt.Saldo = Number(estcnt.Saldo).toFixed(2);
          });
        }
      });
      document.getElementById('contorno').style.pointerEvents = 'all';
    }
    if (valor === 'Siguiente') {
      this.banmaebanService.banmaebanEstCnt = []
      this.desabilitarCampos = true;
      if (this.btnPrimero === true && this.btnAnterior === true) {
        this.btnPrimero = false;
        this.btnAnterior = false;
      }
      this.btnGuardar = true;
      this.btnRegresar = false;
      this.indice++;
      if (this.indice >= this.banmaebanService.banmaeban.length - 1) {
        this.btnUltimo = true;
        this.btnSiguiente = true;
      }
      this.cuentaBanSeleccionada = this.banmaebanService.banmaeban[this.indice];
      this.cuentaBanSeleccionadaAux = this.banmaebanService.banmaeban[this.indice];
      this.obtenerSaldo(this.cuentaBanSeleccionada);
      this.obtenerSucBan(this.cuentaBanSeleccionada);
      this.codigoAnt = this.cuentaBanSeleccionada.BAN_CODIGO;
      if (this.cuentaBanSeleccionada.BAN_DINEROELEC === '1') {
        this.cuentaBanSeleccionada.BAN_DINEROELEC = true;
      } else if (this.cuentaBanSeleccionada.BAN_DINEROELEC === null) {
        this.cuentaBanSeleccionada.BAN_DINEROELEC = false;
      }
      this.banmaebanService.obtenerEstCntBnc(this.cuentaBanSeleccionada).subscribe((res) => {
        if (res !== null) {
          this.banmaebanService.banmaebanEstCnt = res;
          this.banmaebanService.banmaebanEstCnt.map((estcnt) => {
            estcnt.Fecha = estcnt.Fecha.substring(0, 10);
            estcnt.Debe = Number(estcnt.Debe).toFixed(2);
            estcnt.Haber = Number(estcnt.Haber).toFixed(2);
            estcnt.Saldo = Number(estcnt.Saldo).toFixed(2);
          });
        }
      });
      document.getElementById('contorno').style.pointerEvents = 'all';
    }
    if (valor === 'Ultimo') {
      this.banmaebanService.banmaebanEstCnt = []
      this.desabilitarCampos = true;
      this.btnUltimo = true;
      this.btnSiguiente = true;
      this.btnPrimero = false;
      this.btnAnterior = false;
      this.btnRegresar = false;
      this.btnGuardar = true;
      this.indice = this.banmaebanService.banmaeban.length - 1;
      this.cuentaBanSeleccionada = this.banmaebanService.banmaeban[this.indice];
      this.cuentaBanSeleccionadaAux = this.banmaebanService.banmaeban[this.indice];
      this.obtenerSaldo(this.cuentaBanSeleccionada);
      this.obtenerSucBan(this.cuentaBanSeleccionada);
      this.codigoAnt = this.cuentaBanSeleccionada.BAN_CODIGO;
      if (this.cuentaBanSeleccionada.BAN_DINEROELEC === '1') {
        this.cuentaBanSeleccionada.BAN_DINEROELEC = true;
      } else if (this.cuentaBanSeleccionada.BAN_DINEROELEC === null) {
        this.cuentaBanSeleccionada.BAN_DINEROELEC = false;
      }
      this.banmaebanService.obtenerEstCntBnc(this.cuentaBanSeleccionada).subscribe((res) => {
        if (res !== null) {
          this.banmaebanService.banmaebanEstCnt = res;
          this.banmaebanService.banmaebanEstCnt.map((estcnt) => {
            estcnt.Fecha = estcnt.Fecha.substring(0, 10);
            estcnt.Debe = Number(estcnt.Debe).toFixed(2);
            estcnt.Haber = Number(estcnt.Haber).toFixed(2);
            estcnt.Saldo = Number(estcnt.Saldo).toFixed(2);
          });
        }
      });
      document.getElementById('contorno').style.pointerEvents = 'all';
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
  setFocus(event) {
  
    switch (event.target.id) {
    
      case 'bancodigo': {
        document.getElementById('NOM_BAN').focus();
        break;
      }
      case 'NOM_BAN': {
        //document.getElementById('SUC_BAN').focus();
        this.buscarCntsBanSd();//CDPJ
        break;
      }
      case 'SUC_BAN': {
        document.getElementById('bannum1').focus();
        break;
      }
    
      case 'bannum1': {
        document.getElementById('bannum2').focus();
        break;
      }
    
      case 'bannum2': {
        document.getElementById('bandir').focus();
        break;
      }
    
      case 'bandir': {
        document.getElementById('bancorreo').focus();
        break;
      }
    
      case 'bancorreo': {
        document.getElementById('numcuenta').focus();
        break;
      }
    
      case 'numcuenta': {
        document.getElementById('tipocuenta').focus();
        break;
      }
    
      case 'tipocuenta': {
        document.getElementById('cuenta_refcont').focus();
        break;
      }
    
      case 'cuenta_refcont': {
        this.abrirCuentas();
        document.getElementById('jefecuenta').focus();
        break;
      }
    
      case 'jefecuenta': {
        document.getElementById('docxpagcuenta').focus();
        break;
      }
    
      case 'docxpagcuenta': {
        this.abrirCuentas2();
        document.getElementById('otrcuentas').focus();
        break;
      }
      case 'otrcuentas': {
        this.abrirCuentas3();
        document.getElementById('directoriocm').focus();
        break;
      }
      case 'procesocm': {
        document.getElementById('directoriocm').focus();
        break;
      }
    
      case 'directoriocm': {
        document.getElementById('bannumcel').focus();
        break;
      }
    
      default: {
        break;
      }
    }
    
  }
  
  
  obtenerSaldo(cuenta: Banmaeban) {
    let saldoTrnBanImporte = 0;
    let saldoIni = 0;
    this.banmaebanService.obtenerSaldoInicial(cuenta.CON_CODIGO).subscribe((res) => {
      if (res.length !== 0) {
        saldoIni = res[0].DETASI_IMPORTE;
        this.banmaebanService.obtenerSaldoTrBan(this.cuentaBanSeleccionada.BAN_CODIGO).subscribe((res1) => {
          if (res.length !== 0) {
            saldoTrnBanImporte = res1[0].SALDOSINANULADOS;
            this.cuentaBanSeleccionada.BAN_SALDO = saldoIni + saldoTrnBanImporte;
          }
        });
      }
    });
  }
  
  obtenerBan() {
    this.banmaebanService.consultarBancos().subscribe((res) => {
      this.listaNomBan = res;
      this.opcionesNomBan.push({label: '', value: ''});
      this.listaNomBan.map((nomban) => {
        let nuevoItem: any;
        nuevoItem = {label: nomban.BAN_BANCO, value: nomban.BAN_BANCO};
        this.opcionesNomBan.push(nuevoItem);
      });
    });
  }
  
  
  obtenerSucBan(cuenta: Banmaeban) {
    if (cuenta.BAN_BANCO !== '') {
      this.banmaebanService.consultarScrsBanco(cuenta.BAN_BANCO).subscribe((res) => {
        if (res !== null) {
          this.opcionesScrsBan = [];
          this.listaScrsBan = res;
          this.listaScrsBan.map((sucban) => {
            let nuevoItem: any;
            nuevoItem = {label: sucban.BAN_SUCURSAL, value: sucban.BAN_SUCURSAL};
            this.opcionesScrsBan.push(nuevoItem);
          });
        }
      });
    } else {
      let nuevoItem: any;
      nuevoItem = {label: '', value: ''};
      this.opcionesScrsBan.push(nuevoItem);
      this.banmaebanService.banmaebanEstCnt = [];//GSRF
    }
    
  }
  
  obtenerSucBanModificar(cuenta: Banmaeban) {
    this.banmaebanService.consultarScrsBanco(cuenta.BAN_BANCO).subscribe((res) => {
      //this.botonesmodificar();
      this.opcionesScrsBan = [];
      this.listaScrsBan = res;
      this.listaScrsBan.map((sucban) => {
        let nuevoItem: any;
        nuevoItem = {label: sucban.BAN_SUCURSAL, value: sucban.BAN_SUCURSAL};
        this.opcionesScrsBan.push(nuevoItem);
      });
    });
  }
  
  obtenerProCM() {
    this.banmaebanService.consultarProcesoCM().subscribe((res) => {
      this.opcionesProCM = [];
      this.listaProCM = res;
      this.opcionesProCM.push({label: '', value: ''});
      this.listaProCM.map((banproccm) => {
        let nuevoItem: any;
        nuevoItem = {label: banproccm.REF_NOMBRE, value: banproccm.REF_NOMBRE};
        this.opcionesProCM.push(nuevoItem);
      });
    });
  }
  
  nuevoCntBan() {
    this.btnNuevo = true;
    // this.btnGuardar = false;
    this.btnBorrar = false;
    this.btnRegresar = false;
    this.btnPrimero = true;
    this.btnSiguiente = true;
    this.btnAnterior = true;
    this.btnUltimo = true;
    this.cuentaBanSeleccionada = {
      BAN_CODIGO: '',
      BAN_BANCO: '',
      BAN_SUCURSAL: '',
      BAN_DIRECCION: '',
      BAN_TELEFONO1: '',
      BAN_TELEFONO2: '',
      BAN_CORREO: '',
      BAN_CTANRO: '',
      BAN_JEFECTA: '',
      CON_CODIGO: '',
      COM_CODIGO: '01',
      BAN_ULTIMOCHEQUE: '',
      CEN_CODIGO: '',
      BAN_TIPOCUENTA: '',
      CON_CODIGO_2: '',
      CON_CODIGO_3:'',
      BAN_PROCESO_CM: '',
      BAN_DIRECTORIO_CM: '',
      BAN_DINEROELEC: '',
      BAN_TELEFONOCEL: '',
      BAN_SALDO: '',
    };
    if (this.cuentaBanSeleccionada.BAN_BANCO === '') {
      this.obtenerSucBan(this.cuentaBanSeleccionada);
    }
    this.obtenerSucBan(this.cuentaBanSeleccionada);
    this.codigoAnt = this.cuentaBanSeleccionada.BAN_CODIGO;
  }
  
  cancelarCntBan() {
    console.log('*/*/*/',this.codigoAnt)
    console.log('*/*/*/',this.desabilitarCampos)
    this.btnNuevo = false;
    this.btnGuardar = true; // cb
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.btnPrimero = false;
    this.btnAnterior = false;
    this.btnSiguiente = false;
    this.btnUltimo = false;
    if (this.desabilitarCampos === true ) {//GSRF
      this.cuentaBanCancelada();
      this.checkcheque = false;
    } else {
      document.getElementById('contorno').style.pointerEvents = 'none';
      this.cuentaBanSeleccionada = {
        BAN_CODIGO: '',
        BAN_BANCO: '',
        BAN_SUCURSAL: '',
        BAN_DIRECCION: '',
        BAN_TELEFONO1: '',
        BAN_TELEFONO2: '',
        BAN_CORREO: '',
        BAN_CTANRO: '',
        BAN_JEFECTA: '',
        CON_CODIGO: '',
        COM_CODIGO: '01',
        BAN_ULTIMOCHEQUE: '',
        CEN_CODIGO: '',
        BAN_TIPOCUENTA: '',
        CON_CODIGO_2: '',
        CON_CODIGO_3:'',
        BAN_PROCESO_CM: '',
        BAN_DIRECTORIO_CM: '',
        BAN_DINEROELEC: '',
        BAN_TELEFONOCEL: '',
        BAN_SALDO: '',
      };
      this.obtenerSucBan(this.cuentaBanSeleccionada);
      this.checkcheque = false;
    }
  }
  
  
  cuentaBanCancelada() {
    this.banmaebanService.getCntBancarias().subscribe((res) => {
      res.map((cntban) => {
        if (cntban.BAN_CODIGO === this.cuentaBanSeleccionada.BAN_CODIGO) {
          this.cuentaBanSeleccionadaAux = cntban;
        }
      });
      this.cuentaBanSeleccionada = this.cuentaBanSeleccionadaAux;
      this.obtenerSucBan(this.cuentaBanSeleccionada);
      // this.obtenerDatos();
    });
  }
  
  confirmarCntBan() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar la Cuenta Bancaria',
      header: 'Eliminar Cuenta Bancaria',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarCntBan',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.eliminarCntBan();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }
  
  eliminarCntBan() {
    this.banmaebanService.verificarMovimientoBanco(this.cuentaBanSeleccionada.BAN_CODIGO).subscribe((res) => {
      if (res.length === 0) {
        if (this.cuentaBanSeleccionada.BAN_CODIGO === '') {
          this.message.add({
            key: 'banmaebanKey',
            severity: 'error',
            summary: 'Eliminación de Cuenta Bancaria',
            detail: 'No se ha seleccionado una cuenta  para eliminar'
          });
        } else if (this.cuentaBanSeleccionada.BAN_CODIGO !== '') {
          this.banmaebanService.eliminarCuentaBancaria(this.cuentaBanSeleccionada).subscribe(() => {
            this.message.add({
              key: 'banmaebanKey',
              severity: 'success',
              summary: 'Eliminación de Cuenta Bancaria',
              detail: 'Se ha eliminado la Cuenta Bancaria'
            });
            this.auditoriagral.registrarAuditoria('BAN_CODIGO', String(this.cuentaBanSeleccionada.BAN_CODIGO), 'E',
              '', '01', '', '', '', '').subscribe(() => {
            });
            document.getElementById('contorno').style.pointerEvents = 'none';
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
            this.btnRegresar = true;
            this.cuentaBanSeleccionada = {
              BAN_CODIGO: '',
              BAN_BANCO: '',
              BAN_SUCURSAL: '',
              BAN_DIRECCION: '',
              BAN_TELEFONO1: '',
              BAN_TELEFONO2: '',
              BAN_CORREO: '',
              BAN_CTANRO: '',
              BAN_JEFECTA: '',
              CON_CODIGO: '',
              COM_CODIGO: '01',
              BAN_ULTIMOCHEQUE: '',
              CEN_CODIGO: '',
              BAN_TIPOCUENTA: '',
              CON_CODIGO_2: '',
              CON_CODIGO_3:'',
              BAN_PROCESO_CM: '',
              BAN_DIRECTORIO_CM: '',
              BAN_DINEROELEC: '',
              BAN_TELEFONOCEL: '',
              BAN_SALDO: '',
            };
            this.banmaebanService.banmaebanEstCnt = [];
            this.banmaebanService.getCntBancarias().subscribe((res1) => {
              this.banmaebanService.banmaeban = res1;
            });
          }, error1 => {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
            this.message.add({
              key: 'banmaebanKey',
              severity: 'error',
              summary: 'Eliminación de Cuenta Bancaria ',
              detail: mensaje
            });
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
            this.btnRegresar = true;
          });
        }
      } else if (res.length !== 0) {
        this.message.add({
          key: 'banmaebanKey',
          severity: 'error',
          summary: 'Eliminación de Cuenta Bancaria',
          detail: 'La Cuenta Bancaria se encuentra en uso'
        });
      }
    });
    
  }
  
  guardarCntBan() {
    //GSRF
    if(this.cuentaBanSeleccionada.CON_CODIGO!== null){
    if (this.cuentaBanSeleccionada.CON_CODIGO.substr(-1, 1) === '.') {
      this.message.add({
        key: 'banmaebanKey',
        severity: 'error',
        summary: 'Cuenta Contable',
        detail: 'No se puede elegir esa cuenta contable'
      });
      this.cuentaBanSeleccionada.CON_CODIGO='';
      return;
    } 
  }
  if(this.cuentaBanSeleccionada.CON_CODIGO_2 !== null){
    if ( this.cuentaBanSeleccionada.CON_CODIGO_2.substr(-1, 1) === '.') {
      this.message.add({
        key: 'banmaebanKey',
        severity: 'error',
        summary: 'Cuenta Contable',
        detail: 'No se puede elegir esa cuenta contable'
      });
      this.cuentaBanSeleccionada.CON_CODIGO_2='';
      return;
    }
  }
    //GSRF
    //CDPJ
    if(this.cuentaBanSeleccionada.CON_CODIGO_3 !== null){
      if ( this.cuentaBanSeleccionada.CON_CODIGO_3.substr(-1, 1) === '.') {
        this.message.add({
          key: 'banmaebanKey',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
        this.cuentaBanSeleccionada.CON_CODIGO_3='';
        return;
      }
    }
    //CDPJ
    if (this.cuentaBanSeleccionada.BAN_CODIGO === null || this.cuentaBanSeleccionada.BAN_CTANRO === null ||
      this.cuentaBanSeleccionada.BAN_BANCO === null || this.cuentaBanSeleccionada.CON_CODIGO === null ||
      this.cuentaBanSeleccionada.BAN_TIPOCUENTA === null || this.cuentaBanSeleccionada.BAN_CODIGO === '' ||
      this.cuentaBanSeleccionada.BAN_CTANRO === '' || this.cuentaBanSeleccionada.BAN_BANCO === '' ||
      this.cuentaBanSeleccionada.CON_CODIGO === '' || this.cuentaBanSeleccionada.BAN_TIPOCUENTA === '') {
      this.message.add({
        key: 'banmaebanKey',
        severity: 'error',
        summary: 'Inserción o Actualización de Cuenta Bancaria',
        detail: 'Faltan campos obligatorios en la Cuenta Bancaria'
      });
    } else {
      this.cuentaBanSeleccionada.BAN_CODIGO = String(this.cuentaBanSeleccionada.BAN_CODIGO).toUpperCase();
      if (String(this.cuentaBanSeleccionada.BAN_CODIGO).trim().length <= 2 && this.desabilitarCampos === false) {
        this.message.add({
          key: 'banmaebanKey',
          severity: 'error',
          summary: 'Error',
          detail: 'El código de banco debe tener más de 2 dígitos'
        });
      } else {
        this.banmaebanService.encontrarBanCodigo(this.cuentaBanSeleccionada.BAN_CODIGO).subscribe(existe => {
          if (existe !== null) {
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
            this.btnRegresar = true;
            if (this.cuentaBanSeleccionada.BAN_DINEROELEC === true) {
              this.cuentaBanSeleccionada.BAN_DINEROELEC = '1';
            } else if (this.cuentaBanSeleccionada.BAN_DINEROELEC === false) {
              this.cuentaBanSeleccionada.BAN_DINEROELEC = '';
            }
            this.banmaebanService.actualizarCuentaBancaria(this.cuentaBanSeleccionada).subscribe((res) => {
              this.banmaebanService.actualizarNumCheque(this.cuentaBanSeleccionada).subscribe(() => {
              });
              this.checkcheque = false;
              this.message.add({
                key: 'banmaebanKey',
                severity: 'success',
                summary: 'Actualización de Cuenta Bnacaria',
                detail: 'Se ha actualizado la cuenta'
              });
              this.auditoriagral.registrarAuditoria('BAN_CODIGO', String(this.cuentaBanSeleccionada.BAN_CODIGO), 'A',
                '', '01', '', '', '', '').subscribe(() => {
              });
        
              this.banmaebanService.getCntBancarias().subscribe((res1) => {
                this.banmaebanService.banmaeban = res1;
              });
        
              if (this.indice <= 0) {
                this.btnSiguiente = false;
                this.btnUltimo = false;
              } else if (this.indice >= this.banmaebanService.banmaeban.length - 1) {
                this.btnPrimero = false;
                this.btnAnterior = false;
              } else {
                this.btnPrimero = false;
                this.btnAnterior = false;
                this.btnSiguiente = false;
                this.btnUltimo = false;
              }
        
            }, error1 => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
              this.message.add({
                key: 'banmaebanKey',
                severity: 'error',
                summary: 'Actualización de la cuenta ',
                detail: mensaje
              });
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
            });
          } else if (existe === null) {
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
            this.btnRegresar = true;
            if (this.cuentaBanSeleccionada.BAN_DINEROELEC === true) {
              this.cuentaBanSeleccionada.BAN_DINEROELEC = '1';
            } else if (this.cuentaBanSeleccionada.BAN_DINEROELEC === false) {
              this.cuentaBanSeleccionada.BAN_DINEROELEC = '';
            }
            this.banmaebanService.insertarCuentaBancaria(this.cuentaBanSeleccionada).subscribe(() => {
              this.banmaebanService.actualizarNumCheque(this.cuentaBanSeleccionada).subscribe(() => {
              });
              this.checkcheque = false;
              this.message.add({
                key: 'banmaebanKey',
                severity: 'success',
                summary: 'Inserción de Cuenta Bancaria',
                detail: 'Se ha insertado la Cuenta Bancaria'
              });
              this.codigoAnt = this.cuentaBanSeleccionada.BAN_CODIGO;
              this.auditoriagral.registrarAuditoria('BAN_CODIGO', String(this.cuentaBanSeleccionada.BAN_CODIGO), 'I',
                '', '01', '', '', '', '').subscribe(() => {
              });
        
              this.banmaebanService.getCntBancarias().subscribe((res1) => {
                this.banmaebanService.banmaeban = res1;
              });
        
            }, error1 => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
              this.message.add({
                key: 'banmaebanKey',
                severity: 'error',
                summary: 'Inserción de Cuenta Bancaria',
                detail: mensaje
              });
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
            });
          }
        });
      }
    }
  }
  
  
  abrirCuentas() {
    this.opcion = 'CUENTAS';
    this.types = [
      {label: 'CÓDIGO', value: 'CON_CODIGO'},
      {label: 'NOMBRE', value: 'CON_NOMBRE'}
    ];
    this.busquedacampos = ['', ''];
    this.tabla = 'CON_MAECON';
    this.busquedaSer.busquedaConMaeCon2().subscribe((res: any[]) => {
      this.arregloBus = res;
      this.displayDialogBusquedaFast = true;
    });
  }
  
  abrirCuentas2() {
    this.opcion = 'CUENTAS2';
    this.types = [
      {label: 'CÓDIGO', value: 'CON_CODIGO'},
      {label: 'NOMBRE', value: 'CON_NOMBRE'}
    ];
    this.busquedacampos = ['', ''];
    this.tabla = 'CON_MAECON';
    this.busquedaSer.busquedaConMaeCon2().subscribe((res: any[]) => {
      this.arregloBus = res;
      this.displayDialogBusquedaFast = true;
    });
  }
  abrirCuentas3(){
    this.opcion = 'CUENTAS3';
    this.types = [
      {label: 'CÓDIGO', value: 'CON_CODIGO'},
      {label: 'NOMBRE', value: 'CON_NOMBRE'}
    ];
    this.busquedacampos = ['', ''];
    this.tabla = 'CON_MAECON';
    this.busquedaSer.busquedaConMaeCon2().subscribe((res: any[]) => {
      this.arregloBus = res;
      this.displayDialogBusquedaFast = true;
    });
  }
  
  buscarCntsBan() {
    this.opcion = 'CNTSBAN';
    this.types = [
      {label: 'Código', value: 'BAN_CODIGO'},
      {label: 'Banco', value: 'BAN_BANCO'},
      {label: 'Cuenta NRO', value: 'BAN_CTANRO'},
      {label: 'Jefe Cuenta ', value: 'BAN_JEFECTA'},
      {label: 'Sucursal', value: 'BAN_SUCURSAL'},
      {label: 'Direccion', value: 'BAN_DIRECCION'},
      {label: 'Telefono1', value: 'BAN_TELEFONO1'},
      {label: 'Ref.Contable', value: 'CON_CODIGO'},
      {label: 'CEN_CODIGO', value: 'CEN_CODIGO'},
      {label: 'Tipo de Cuenta', value: 'BAN_TIPOCUENTA'},
      {label: 'Doc. por pagar', value: 'CON_CODIGO_2'},
      {label: 'Proceso', value: 'BAN_PROCESO_CM'},
      {label: 'Directorio CM', value: 'BAN_DIRECTORIO_CM'},
      {label: 'Dinero Electrónico', value: 'BAN_DINEROELEC'},
      {label: 'Tel Cel', value: 'BAN_TELEFONOCEL'},
      {label: 'Ult.Cheque', value: 'BAN_ULTIMOCHEQUE'}
      ,
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
    this.tabla = 'BAN_MAEBAN';
    
    this.busquedaSer.busquedaBanMaeBan4().subscribe((datos: any[]) => {
      this.arregloBus = datos;
      this.displayDialogBusquedaFast = true;
    });
  }
    //CDPJ
    buscarCntsBanSd() {
      this.opcion = 'CNTSBANSd';
      this.types = [
        {label: 'Código', value: 'BAN_CODIGO'},
        {label: 'Banco', value: 'BAN_BANCO'},
        {label: 'Cuenta NRO', value: 'BAN_CTANRO'},
        {label: 'Jefe Cuenta ', value: 'BAN_JEFECTA'},
        {label: 'Sucursal', value: 'BAN_SUCURSAL'},
        {label: 'Direccion', value: 'BAN_DIRECCION'},
        {label: 'Telefono1', value: 'BAN_TELEFONO1'},
        {label: 'Ref.Contable', value: 'CON_CODIGO'},
        {label: 'CEN_CODIGO', value: 'CEN_CODIGO'},
        {label: 'Tipo de Cuenta', value: 'BAN_TIPOCUENTA'},
        {label: 'Doc. por pagar', value: 'CON_CODIGO_2'},
        {label: 'Proceso', value: 'BAN_PROCESO_CM'},
        {label: 'Directorio CM', value: 'BAN_DIRECTORIO_CM'},
        {label: 'Dinero Electrónico', value: 'BAN_DINEROELEC'},
        {label: 'Tel Cel', value: 'BAN_TELEFONOCEL'},
        {label: 'Ult.Cheque', value: 'BAN_ULTIMOCHEQUE'}
        ,
      ];
      this.busquedacampos = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
      this.tabla = 'BAN_MAEBAN';
      
      this.busquedaSer.busquedaBanMaeBan4().subscribe((datos: any[]) => {
        this.arregloBus = datos;
        this.displayDialogBusquedaFast = true;
      });
    }
    //CDPJ
  manejarSeleccion(opcion) {
    if (this.opcion === 'CUENTAS') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.message.add({
          key: 'banmaebanKey',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.cuentaBanSeleccionada.CON_CODIGO = opcion.CON_CODIGO;
        this.displayDialogBusquedaFast = false;
      }
    }
    if (this.opcion === 'CUENTAS2') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.message.add({
          key: 'banmaebanKey',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.cuentaBanSeleccionada.CON_CODIGO_2 = opcion.CON_CODIGO;
        this.displayDialogBusquedaFast = false;
      }
    }
    //CDPJ
    if (this.opcion === 'CUENTAS3') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.message.add({
          key: 'banmaebanKey',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.cuentaBanSeleccionada.CON_CODIGO_3 = opcion.CON_CODIGO;
        this.displayDialogBusquedaFast = false;
      }
    }
    //CDPJ
    
    if (this.opcion === 'CNTSBAN') {
      this.banmaebanService.banmaebanEstCnt = [];//GSRF
      this.cuentaBanSeleccionada.BAN_CODIGO = opcion.BAN_CODIGO;
      this.cuentaBanSeleccionada.BAN_BANCO = opcion.BAN_BANCO;
      this.cuentaBanSeleccionada.BAN_SUCURSAL = opcion.BAN_SUCURSAL;
      this.cuentaBanSeleccionada.BAN_DIRECCION = opcion.BAN_DIRECCION;
      this.cuentaBanSeleccionada.BAN_TELEFONO1 = opcion.BAN_TELEFONO1;
      this.cuentaBanSeleccionada.BAN_TELEFONO2 = opcion.BAN_TELEFONO2;
      this.cuentaBanSeleccionada.BAN_CORREO = opcion.BAN_CORREO;
      this.cuentaBanSeleccionada.BAN_CTANRO = opcion.BAN_CTANRO;
      this.cuentaBanSeleccionada.BAN_JEFECTA = opcion.BAN_JEFECTA;
      this.cuentaBanSeleccionada.CON_CODIGO = opcion.CON_CODIGO;
      
      this.cuentaBanSeleccionada.BAN_ULTIMOCHEQUE = opcion.BAN_ULTIMOCHEQUE;
      this.cuentaBanSeleccionada.CEN_CODIGO = opcion.CEN_CODIGO;
      this.cuentaBanSeleccionada.BAN_TIPOCUENTA = opcion.BAN_TIPOCUENTA;
      this.cuentaBanSeleccionada.CON_CODIGO_2 = opcion.CON_CODIGO_2;
      this.cuentaBanSeleccionada.CON_CODIGO_3=opcion.CON_CODIGO_3;//CDPJ
      this.cuentaBanSeleccionada.BAN_PROCESO_CM = opcion.BAN_PROCESO_CM;
      this.cuentaBanSeleccionada.BAN_DIRECTORIO_CM = opcion.BAN_DIRECTORIO_CM;
      this.cuentaBanSeleccionada.BAN_DINEROELEC = opcion.BAN_DINEROELEC;
      this.cuentaBanSeleccionada.BAN_TELEFONOCEL = opcion.BAN_TELEFONOCEL;
      this.cuentaBanSeleccionada.BAN_SALDO = opcion.BAN_SALDO;
  
      this.banmaebanService.obtenerEstCntBnc(this.cuentaBanSeleccionada).subscribe((res) => {
        if (res !== null) {
          this.banmaebanService.banmaebanEstCnt = res;
          this.banmaebanService.banmaebanEstCnt.map((estcnt) => {
            estcnt.Fecha = estcnt.Fecha.substring(0, 10);
            estcnt.Debe = Number(estcnt.Debe).toFixed(2);
            estcnt.Haber = Number(estcnt.Haber).toFixed(2);
            estcnt.Saldo = Number(estcnt.Saldo).toFixed(2);
          });
        }
      });
      this.obtenerSucBanModificar(this.cuentaBanSeleccionada);
      this.displayDialogBusquedaFast = false;
       //GSRF
       console.log('AQUIIIIIIII')
       this.desabilitarCampos = true;
       this.btnAnterior = false;
       this.btnPrimero = false;
       this.btnUltimo = false;
       this.btnSiguiente = false;
       this.btnRegresar = false;
       this.btnGuardar = true;
       //GSRF
    }
    //CDPJ
    if (this.opcion === 'CNTSBANSd') {
       
      this.cuentaBanSeleccionada.BAN_BANCO = opcion.BAN_BANCO;
      this.cuentaBanSeleccionada.BAN_SUCURSAL = opcion.BAN_SUCURSAL;
      // this.banmaebanService.obtenerEstCntBnc(this.cuentaBanSeleccionada).subscribe((res) => {
      //   if (res !== null) {
      //     this.banmaebanService.banmaebanEstCnt = res;
      //     this.banmaebanService.banmaebanEstCnt.map((estcnt) => {
      //       estcnt.Fecha = estcnt.Fecha.substring(0, 10);
      //       estcnt.Debe = Number(estcnt.Debe).toFixed(2);
      //       estcnt.Haber = Number(estcnt.Haber).toFixed(2);
      //       estcnt.Saldo = Number(estcnt.Saldo).toFixed(2);
      //     });
      //   }
      // });
      this.obtenerSucBanModificar(this.cuentaBanSeleccionada);
      this.displayDialogBusquedaFast = false;
    }

   //CDPJ 
  }
  
  
  cambio() {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnRegresar = false;
  }
  
  botonesmodificar() {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = true;
    this.btnRegresar = false;
    // bloquear botones en la edicion
    this.btnPrimero = true;
    this.btnAnterior = true;
    this.btnSiguiente = true;
    this.btnUltimo = true;
    
  }
  
  cargarValuesAnteriores() {
    this.cuentaBanSeleccionada = this.banmaebanService.banmaeban[this.indice];
    this.cuentaBanSeleccionadaAux = this.banmaebanService.banmaeban[this.indice];
    this.obtenerSaldo(this.cuentaBanSeleccionada);
    this.obtenerSucBan(this.cuentaBanSeleccionada);
    this.codigoAnt = this.cuentaBanSeleccionada.BAN_CODIGO;
    if (this.cuentaBanSeleccionada.BAN_DINEROELEC === '1') {
      this.cuentaBanSeleccionada.BAN_DINEROELEC = true;
    } else if (this.cuentaBanSeleccionada.BAN_DINEROELEC === null) {
      this.cuentaBanSeleccionada.BAN_DINEROELEC = false;
    }
    this.banmaebanService.obtenerEstCntBnc(this.cuentaBanSeleccionada).subscribe((res) => {
      if (res !== null) {
        this.banmaebanService.banmaebanEstCnt = res;
        this.banmaebanService.banmaebanEstCnt.map((estcnt) => {
          estcnt.Fecha = estcnt.Fecha.substring(0, 10);
          estcnt.Debe = Number(estcnt.Debe).toFixed(2);
          estcnt.Haber = Number(estcnt.Haber).toFixed(2);
          estcnt.Saldo = Number(estcnt.Saldo).toFixed(2);
        });
      }
    });
  }
  
}
