<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="audi"></p-toast>
<div class="p-grid" style="width: 99%">
	<div class="p-col-12">
		<app-invmaebarra [barraBotonesCRUD]="true" [barraBotonesDesplaz]="true"
						 [barraBotones3]="true" [barraBotonesAsiCon]="true"
						 [barraBotones5]="true" [botonVisto]="false"
						 [botonImprimir]="true"
						 [barraBotones3Alt]="false"
						 (signal)="manejarSenales($event)"></app-invmaebarra>
	</div>
</div>
<div class="divgrups" style="width: 99%">
	<div class="p-grid">
		<div class="p-col-12" style="margin-top: 10px;">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-1">Desde</div>
				<div class="p-col-2">
					<p-calendar id="desde" [inputStyle]="{width: '100%', height: '20px'}"
								[(ngModel)]="desde"
								[readonlyInput]="false" dateFormat="dd/mm/yy"
								(onSelect)="botonesmodificar()"
								(keydown)="botonesmodificar()">
					</p-calendar>
				</div>
				<div class="p-col-1">Hasta</div>
				<div class="p-col-2">
					<p-calendar id="hasta" [inputStyle]="{width: '100%', height: '20px'}"
								[(ngModel)]="hasta"
								[readonlyInput]="false" dateFormat="dd/mm/yy"
								(onSelect)="botonesmodificar()"
								(keydown)="botonesmodificar()">
					</p-calendar>
				</div>
			</div>
		</div>
		<div class="p-col-12" style="margin-top: -15px;">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-1">Módulo</div>
				<div class="p-col-4">
					<p-dropdown [options]="cmbmodulo"
								[style]="{'minWidth': '100%', 'width':'100%'}"
								optionLabel="name" [panelStyle]="{'width':'110%'}"
								[(ngModel)]="selectmodulo"
								(onChange)="llenarObjetos()">
					</p-dropdown>
				</div>
				<div class="p-col-1">Operación</div>
				<div class="p-col-4">
					<p-dropdown [options]="cmboperacion"
								[style]="{'minWidth': '100%', 'width':'100%'}"
								optionLabel="name" [panelStyle]="{'width':'110%'}"
								[(ngModel)]="selectoperacion"
								(onChange)="botonesmodificar()">
					</p-dropdown>
				</div>
			</div>
		</div>
		<div class="p-col-12" style="margin-top: -15px;">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-1">Objeto</div>
				<div class="p-col-4">
					<p-dropdown [options]="cmbobjeto"
								[style]="{'minWidth': '100%', 'width':'100%'}"
								optionLabel="name" [panelStyle]="{'width':'110%'}"
								[(ngModel)]="selectobjeto"
								(onChange)="botonesmodificar()">
					</p-dropdown>
				</div>
				<div class="p-col-1">Identificación</div>
				<div class="p-col-4">
					<p-dropdown [options]="cmbide"
								[style]="{'minWidth': '100%', 'width':'100%'}"
								optionLabel="name" [panelStyle]="{'width':'110%'}"
								[(ngModel)]="selectide"
								(onChange)="botonesmodificar()">
					</p-dropdown>
				</div>
			</div>
		</div>
		<div class="p-col-12" style="margin-top: -15px;">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-1">Dato</div>
				<div class="p-col-4">
					<label for="dato"></label>
					<input class="frm" id="dato" type="text" pInputText maxlength="49"
						   [(ngModel)]="dato"
						   (keyup)="botonesmodificar()" autocomplete="off">
				</div>
			</div>
		</div>
		<!--<div class="p-col-12" style="margin-top: -15px;">
			<div class="p-grid">
				<div class="p-col-6"></div>
				<div class="p-col-3">
					<button class="sincss" pButton (click)="hacerConsulta('hoy')"
							style="background-image: url(../../../assets/images/iconos/btnAceptar.png);
						width: 100%; height: 75%;">
						HOY
					</button>
				</div>
				<div class="p-col-3">
					<button class="sincss" pButton (click)="hacerConsulta('ultimahora')"
							style="background-image: url(../../../assets/images/iconos/btnAceptar.png);
						width: 100%; height: 75%;">
						ULTIMA HORA
					</button>
				</div>
			</div>
		</div>
		<div class="p-col-12" style="margin-top: -10px; margin-bottom: -15px;">
			<div class="p-grid">
				<div class="p-col-12">
					<input class="frm" id="consulta" type="text" pInputText
						   [(ngModel)]="consulta"
						   autocomplete="off" style="width: 100%">
				</div>
			</div>
		</div>-->
	</div>
</div>
<div class="p-grid" style="width: 99%; margin-top: -25px;">
	<div class="p-col-12">
		<app-aggridsaci [width]=""
						[height]="'420'"
						[enableSorting]="true"
						[enableFilter]="true"
						[rowData]="sacimaeauditoria"
						[frameworkComponents]="frameworkComponents"
						[animateRows]="true"
						[rowSelection]="rowSelection"
						[columnDefs]="columnDefs"
						[defaultColDef]="defaultColDef"
						[rowStyle]="rowStyle"
						[botones]="false"
						[singleClickEdit]="false"></app-aggridsaci>
	</div>
</div>


<p-dialog header="Reportes" [(visible)]="displayDialogReportes"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '40vw', height: '400px'}">
	<app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
						 [strNumDoc]="''" [strCampo]="''" (cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>
