import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';


@Injectable({
  providedIn: 'root'
})
export class SegmaeorganizacionService {

  public way: string;
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }
  
  
  consultaOrganizacionSistema() {
    console.log(this.usuario.identificacion);
    return this.http.post<any[]>(this.way + '/seg/organizacion/83bb8dc392e7600', {
      elementos: {
        p_usuario: this.usuario.identificacion,
        p_COMcodigop: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  consultaModulos() {
    return this.http.post<any[]>(this.way + '/seg/organizacion/662fcf56d86fac0', {
      elementos: {
        p_opcion: 'T',
        p_MODprefijop: '',
        p_condicion: ''
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  consultaMenus() {
    
    return this.http.post<any[]>(this.way + '/seg/organizacion/3317e7ab675fdc', {
      elementos: {
        p_opcion: 'T',
        p_MENcodigop: '',
        p_condicion: ''
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  cargarOpcionMenuModulo(prefijo, menu) {
    
    return this.http.post<any[]>(this.way + '/seg/organizacion/c56e18cb2cebd800', {
      elementos: {
        p_prefijo:  prefijo,
        p_menu:     menu
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  guardarModulo( prefijo: string, nombre: string, externo: string, orden: string, paquete: string) {
    
    return this.http.post<any[]>(this.way + '/seg/organizacion/43cadb6d86fab8', {
      elementoEjecutar:  paquete,  // 'SEG_PCK_MAEMODULO.SEG_SP_insertarModulo'
      elementos: {
        p_MODprefijo_1: prefijo,
        p_MODnombre_2:  nombre,
        p_MODimagen_3:  '',
        p_MODexterno_4: externo,
        p_MODorden_5:   orden
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarModulo( prefijo) {
    
    return this.http.post<any[]>(this.way + '/seg/organizacion/3ab2b4ad86fab8', {
      elementos: {
        p_MODprefijo_1: prefijo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  guardarMenu(  nombre, orden) {
    console.log(nombre, orden);
    return this.http.post<any[]>(this.way + '/seg/organizacion/21e56daab3afe', {
      elementos: {
        p_MENnombre_1: nombre,
        p_MENfecha_2:  null,
        p_MENordenP_3: orden,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarMenu( codigo ) { 
    return this.http.post<any[]>(this.way + '/seg/organizacion/1d595a57b3afe', {
      elementos: {
        p_MENcodigo_1: codigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarOpcion( menuCodigo, prefijoMod, opcionCodigo ) {
    // console.log(codigo, prefijoMod, codigoMenu);
    return this.http.post<any[]>(this.way + '/seg/organizacion/3ab2b4b1964b18', {
      elementos: {
        p_OPCcodigo_1:    opcionCodigo,     // decimal,
        p_MODprefijo_2:   prefijoMod, // varchar2,
        p_MENcodigo_3:    menuCodigo  // decimal
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  actualizarMenu(codigo,  nombre, orden) {
    console.log(nombre, orden, codigo);
    return this.http.post<any[]>(this.way + '/seg/organizacion/299df2ab2b3afe', {
      elementos: {
        p_MENcodigo_1: codigo,
        p_MENnombre_1: nombre,
        p_MENordenP_3: orden,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  guardarOpcion(  prefijo, menCodigo, nombre, formulario, orden) {
    console.log(nombre, orden);
    return this.http.post<any[]>(this.way + '/seg/organizacion/43cadb71964b18', {
      elementos: {
        p_MODprefijo_1: prefijo, // varchar2,
        p_MENcodigo_2:  menCodigo, // decimal,
        p_OPCnombre_3:  nombre, // varchar2,
        p_OPCobjeto_4:  formulario, // varchar2,
        p_OPCorden_5:  orden // varchar2,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  actualizarOpcion( codigo, prefijo, menCodigo, nombre, formulario, orden) {
    console.log(  prefijo, menCodigo, nombre, formulario, orden);
    return this.http.post<any[]>(this.way + '/seg/organizacion/14cefb1964b17', {
      elementos: {
        p_OPCcodigo_1:  codigo, // decimal,
        p_MODprefijo_2: prefijo, // varchar2,
        p_MENcodigo_3:  menCodigo, //  decimal,
        p_OPCnombre_4:  nombre,  //  varchar2,
        p_OPCobjeto_5:  formulario, //  varchar2,
        p_OPCorden_6:   orden //  varchar2
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
}
