<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="encmant"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmencmant" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 99%">
    <div class="p-col-12" *ngIf="auxiliar === undefined">
        <app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
            [botonRegresar]="btnRegresar" [barraBotonesCRUD]="false" [barraBotonesDesplaz]="true" [barraBotones3]="true"
            [barraBotonesAsiCon]="true" [barraBotones5]="true" (signal)="manejarSenales($event)"></app-invmaebarra>
    </div>
    <div class="p-col-12">
        <div class="divgrups">
            <div class="p-grid" style="margin-bottom: -15px;">
                <div class="p-col-12">
                    <div class="p-grid">
                        <div class="p-col-1">
                            <span>Cliente:</span>
                        </div>
                        <div class="p-col-2">
                            <label for="CLI_CODIGO"></label>
                            <input class="frm" id="CLI_CODIGO" type="text" pInputText
                                [disabled]="auxiliar !== undefined" [(ngModel)]="tecencmant.CLI_CODIGO"
                                (keydown.enter)="setFocus($event)" autocomplete="off">
                        </div>
                        <div class="p-col-1">
                            <button *ngIf="auxiliar === undefined" type="button"
                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                (click)="busquedacliente(tecencmant.CLI_CODIGO)"></button>
                        </div>
                        <div class="p-col-4">
                            <label for="CLI_NOMBRE"></label>
                            <input class="frm" id="CLI_NOMBRE" type="text" pInputText disabled
                                style="color: blue;font-weight: bold;" [(ngModel)]="tecencmant.CLI_NOMBRE"
                                autocomplete="off">
                        </div>
                    </div>
                </div>
                <div class="p-col-12" style="margin-top: -15px;">
                    <div class="p-grid">
                        <div class="p-col-1">
                            <span>Direccion:</span>
                        </div>
                        <div class="p-col-11">
                            <label for="CLI_DIRECCION"></label>
                            <input class="frm" id="CLI_DIRECCION" type="text" pInputText disabled
                                style="color: blue;font-weight: bold;" [(ngModel)]="tecencmant.CLI_DIRECCION"
                                autocomplete="off">
                        </div>
                    </div>
                </div>
                <div class="p-col-12" style="margin-top: -15px;">
                    <div class="p-grid">
                        <div class="p-col-1">
                            <span>Teléfono 1:</span>
                        </div>
                        <div class="p-col-2">
                            <label for="CLI_TELEFONO1"></label>
                            <input class="frm" id="CLI_TELEFONO1" type="text" pInputText disabled
                                style="color: blue;font-weight: bold;" [(ngModel)]="tecencmant.CLI_TELEFONO1"
                                autocomplete="off">
                        </div>
                        <div class="p-col-1"></div>
                        <div class="p-col-1">
                            <span>Teléfono 2:</span>
                        </div>
                        <div class="p-col-2">
                            <label for="CLI_TELEFONO2"></label>
                            <input class="frm" id="CLI_TELEFONO2" type="text" pInputText disabled
                                style="color: blue; font-weight: bold;" [(ngModel)]="tecencmant.CLI_TELEFONO2"
                                autocomplete="off">
                        </div>
                        <div class="p-col-1"></div>
                        <div class="p-col-1">
                            <span>Correo:</span>
                        </div>
                        <div class="p-col-3">
                            <label for="CLI_CORREO"></label>
                            <input class="frm" id="CLI_CORREO" type="text" pInputText disabled
                                style="color: blue;font-weight: bold;" [(ngModel)]="tecencmant.CLI_CORREO"
                                autocomplete="off">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="overflow: hidden" class="p-col-12" style="margin-top: -20px;">
        <p-tabView [(activeIndex)]="index" (onChange)="changeTabs()">
            <p-tabPanel header="Equipos" [disabled]="btnNuevo">
                <app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
                    [rowData]="tecequipos" [frameworkComponents]="frameworkComponents" [animateRows]="true"
                    [rowSelection]="rowSelection" [columnDefs]="columnDefsEquipo" [defaultColDef]="defaultColDefEquipo"
                    [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="false"
                    (selected)="seleccionequipo($event)">
                </app-aggridsaci>
            </p-tabPanel>
            <p-tabPanel header="Descripción">
                <div class="divgrups">
                    <div class="p-grid">
                        <div class="p-col-12">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Equipo</span>
                                </div>
                                <div class="p-col-2">
                                    <label for="EQUI_CODIGO"></label>
                                    <input class="frm" id="EQUI_CODIGO" type="text" pInputText
                                        [(ngModel)]="tecencmant.EQUI_CODIGO" (keydown.enter)="setFocus($event)"
                                        (keydown)="botonesModificar()" autocomplete="off" maxlength="10">
                                </div>
                                <div class="p-col-1">
                                    <button type="button"
                                        style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                        (click)="busquedaequipo(tecencmant.EQUI_CODIGO)"></button>
                                </div>
                                <div class="p-col-2">
                                    <label for="EQUI_NOMBRE"></label>
                                    <input class="frm" id="EQUI_NOMBRE" type="text" pInputText disabled
                                        style="color: blue;" [(ngModel)]="tecencmant.EQUI_NOMBRE" autocomplete="off">
                                </div>
                                <div class="p-col-1">
                                    <span>Artículo</span>
                                </div>
                                <div class="p-col-2">
                                    <label for="ART_CODIGO"></label>
                                    <input class="frm" id="ART_CODIGO" type="text" pInputText
                                        [(ngModel)]="tecencmant.ART_CODIGO" (keydown.enter)="setFocus($event)"
                                        (keydown)="botonesModificar()" autocomplete="off" maxlength="20">
                                </div>
                                <div class="p-col-1">
                                    <button type="button"
                                        style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                        (click)="busquedaarticulo(tecencmant.ART_CODIGO)"></button>
                                </div>
                                <div class="p-col-2">
                                    <label for="ART_NOMBRE"></label>
                                    <input class="frm" id="ART_NOMBRE" type="text" pInputText disabled
                                        style="color: blue;" [(ngModel)]="tecencmant.ART_NOMBRE" autocomplete="off">
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12" style="margin-top: -15px;">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Serie</span>
                                </div>
                                <div class="p-col-2">
                                    <label for="MANT_SERIE"></label>
                                    <input class="frm" id="MANT_SERIE" type="text" pInputText
                                        [(ngModel)]="tecencmant.MANT_SERIE" (keydown.enter)="setFocus($event)"
                                        (keydown)="botonesModificar()" autocomplete="off" maxlength="20">
                                </div>
                                <div class="p-col-1">
                                    <span>Marca</span>
                                </div>
                                <div class="p-col-2">
                                    <p-dropdown [style]="{'minWidth': '100%', 'width': '100%'}"
                                        [panelStyle]="{'width':'100%'}" [options]="opcionesMarca"
                                        [(ngModel)]="tecencmant.MANT_MARCA" (keydown.enter)="setFocus($event)"
                                        (onChange)="botonesModificar()" (keydown)="botonesModificar()" editable="true">
                                    </p-dropdown>
                                </div>
                                <div class="p-col-1">
                                    <span>Modelo</span>
                                </div>
                                <div class="p-col-2">
                                    <p-dropdown [style]="{'minWidth': '100%', 'width': '100%'}"
                                        [panelStyle]="{'width':'100%'}" [options]="opcionesModelo"
                                        [(ngModel)]="tecencmant.MANT_MODELO" (keydown.enter)="setFocus($event)"
                                        (onChange)="botonesModificar()" (keydown)="botonesModificar()" editable="true">
                                    </p-dropdown>
                                </div>
                                <div class="p-col-1">
                                    <span>Origen</span>
                                </div>
                                <div class="p-col-2">
                                    <p-dropdown [style]="{'minWidth': '100%', 'width': '100%'}"
                                        [panelStyle]="{'width':'100%'}" [options]="opcionesOrigen"
                                        [(ngModel)]="tecencmant.MANT_ORIGEN" (keydown.enter)="setFocus($event)"
                                        (onChange)="botonesModificar()" (keydown)="botonesModificar()" editable="true">
                                    </p-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12" style="margin-top: -10px;">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Dias mantenimiento</span>
                                </div>
                                <div class="p-col-1">
                                    <label for="MANT_DIAS_MANTE"></label>
                                    <input class="frm" id="MANT_DIAS_MANTE" type="number" pInputText
                                        [(ngModel)]="tecencmant.MANT_DIAS_MANTE" (keydown.enter)="setFocus($event)"
                                        (keydown)="botonesModificar()" autocomplete="off">
                                </div>
                                <div class="p-col-1"></div>
                                <div class="p-col-1">
                                    <span>Último mantenimiento</span>
                                </div>
                                <div class="p-col-2">
                                    <p-calendar id="MANT_ULT_MANTE" [inputStyle]="{width: '100%', height: '20px'}"
                                        [(ngModel)]="tecencmant.MANT_ULT_MANTE" [readonlyInput]="true"
                                        dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
                                        (keydown)="botonesModificar()">
                                    </p-calendar>
                                </div>
                                <div class="p-col-1">
                                    <span>Próximo mantenimiento</span>
                                </div>
                                <div class="p-col-2">
                                    <p-calendar id="MANT_PRX_MANTE" [inputStyle]="{width: '100%', height: '20px'}"
                                        [(ngModel)]="tecencmant.MANT_PRX_MANTE" [readonlyInput]="true"
                                        dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
                                        (keydown)="botonesModificar()">
                                    </p-calendar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
                    [rowData]="tecencmantService.tecdetmant[indicador]" [frameworkComponents]="frameworkComponents"
                    [animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef" [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="false"
                    (cambios)="botonesModificar()" [botones]="true" [mostrarGuardar]="true"
                    (selected)="seleccionDet($event)">
                </app-aggridsaci>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>

<p-dialog header="Búsqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
        [busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
    <p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
    </p-progressSpinner>
</div>