<p-toast [style]="{marginTop: '30px'}" position="top-center" key="horaExtra"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmHoraExtra" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 98%">
    <div class="p-col-12">
        <app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
            [botonBorrar]="btnBorrar" [botonAnterior]="botonAnterior" [botonPrimero]="botonPrimero"
            [botonUltimo]="botonUltimo" [botonSiguiente]="botonSiguiente" [barraBotones3]="true"
            [barraConEncasil]="true" [botonBuscar]="btnBuscar" [botonBinocular]="true" [botonAlmacenardoc] = "true"
            (signal)="manejarSenales($event)">
        </app-invmaebarra>
    </div>
</div>
<div class="divgrups">
    <div class="p-grid">
        <div class="p-col-4"></div>
        <div class="p-col-4">
            <table style="width: 100%;">
                <tr style="background-color: rgba(66, 214, 240, 0.829);">
                    <td style="border: 1px solid; text-align: center;">{{identiUsuario}}</td>
                    <td style="border: 1px solid; text-align: center;">{{fecha | date: 'dd/MM/yyyy' }}</td>
                    <td style="border: 1px solid; text-align: center;">{{fecha | date: 'hh:mm' }}</td>
                </tr>
            </table>
        </div>
        <div class="p-col-2">
            <span style="margin-left: 20px;" *ngIf="lblCABHORAEXTR_CODIGO > 0">
                {{lblCABHORAEXTR_CODIGO}}
            </span>
        </div>
        <div class="p-col-2"></div>
    </div>
</div>
<div class="divgrups">
    <div class="p-grid">
        <div class="p-col-2">
            <span style="margin-left: 20px;">Periodo:</span>
        </div>
        <div class="p-col-2">

            <input class="frm" type="text" pInputText readonly="readonly" [(ngModel)]="txtPERIODO_FECINI">
        </div>
        <div class="p-col-2">
            <input class="frm" type="text" pInputText readonly="readonly" [(ngModel)]="txtPERIODO_FECFIN">

        </div>
        <div class="p-col-1">
            <span style="margin-left: 20px;">{{ lblPERIODO_CODIGO }}</span>
        </div>
        <div class="p-col-2"></div>
        <div class="p-col-2">
            <span style="margin-left: 20px;">{{ txtCABHORAEXTR_ESTADO }}</span>
        </div>
    </div>
</div>

<app-aggridsaci [width]="" [height]="largo" [enableSorting]="false" [enableFilter]="true"
    [rowData]="cabHoraExtrSrv.detHorasExtra" [frameworkComponents]="frameworkComponents" [animateRows]="true"
    [rowSelection]="rowSelection" [columnDefs]="columnDefDetHorasExtra" [defaultColDef]="defaultColDefHoraExtra"
    [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true" [botonGuardar]="true" [botonNuevo]="botonnuevo"
    [botonBorrar]="botoneliminar" [botonRegresar]="botoncancelar" [botonesAdicionales]="true"
    [botonBuscar]="botonbuscar" (cambios)="cambio($event)" (selected)="seleccion($event)">
</app-aggridsaci>

<div class="divgrups">
    <div class="p-grid">

        <div class="p-col-2">
            <span style="margin-left: 20px;">Total Valor Horas 25%:</span>
        </div>
        <div class="p-col-1">

            <input class="frm" type="text" pInputText [(ngModel)]="TXTtotal25" readonly="readonly">
        </div>
        <div class="p-col-1"></div>

        <div class="p-col-2">
            <span style="margin-left: 20px;">Total Valor Horas 50%:</span>
        </div>
        <div class="p-col-1">
            <input class="frm" type="text" pInputText [(ngModel)]="TXTtotal50" readonly="readonly">
        </div>
        <div class="p-col-1"></div>
        <div class="p-col-2">
            <span style="margin-left: 20px;">Total Valor Horas 100%:</span>
        </div>
        <div class="p-col-1">
            <input class="frm" type="text" pInputText [(ngModel)]="TXTtotal100" readonly="readonly">
        </div>
        <div class="p-col-1"></div>
    </div>
</div>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
        [where]="where" [busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)">
    </app-busquedadlg>
</p-dialog>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaTabla" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlgmae [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
        [where]="condicion" [busquedaParams]="busquedacampos" (opcionSeleccionada)="seleccionTabla($event)"
        [busquedaCompuesta]="false"></app-busquedadlgmae>
</p-dialog>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaNuevo" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
        [where]="condicion" [busquedaParams]="busquedacampos" (opcionSeleccionada)="seleccionTablaNuevo($event)"
        [busquedaCompuesta]="false"></app-busquedadlg>
</p-dialog>

<p-dialog header="Reportes" [(visible)]="displayDialogReportes" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '40vw', height: '400px'}">
    <app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma" [strNumDoc]="lblCABHORAEXTR_CODIGO"
        [strCampo]="'CABHORAEXTR_CODIGO'" (cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'NOMCABHORAEXTR'" [txtNumComprobante] = "lblCABHORAEXTR_CODIGO"
	(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>

<!-- <p-dialog [(visible)]="spin"  [style]="{width: '300px', height: '300px'}">
    
    <div style="display: flex;align-items: center;justify-content: center; z-index: 999;  overflow: show;">
        <p-progressSpinner *ngIf="spin"
            animationDuration=".5s"></p-progressSpinner>
        </div>
</p-dialog> -->

