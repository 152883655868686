import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from 'src/app/usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { NomDetHoraExtr } from '../nominterfaces/nomrhucabhoraextr';

@Injectable({
  providedIn: 'root'
})
export class NomrhucabhoraextrService {
   
  private way: string;

  public detHorasExtra: NomDetHoraExtr [] = [];

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) { 
      this.way = sessionStorage.getItem('way');
   
    }




  getPeriodoHorasExtras(cabpagtrabCodigo: number){
    return  this.http.post<any[]>(this.way + '/nom/cabhoraextr/3j8xqfgail49bgz', {
      elementos: {
        p_cabhoraextr_codigo: cabpagtrabCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }


  getCabHextraEmpleados(cabpagtrabCodigo: number){
    return  this.http.post<any[]>(this.way + '/nom/cabhoraextr/lvqkvsqgfm9g2uo', {
      elementos: {
        p_cabhoraextr_codigo: cabpagtrabCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }



  encontrarRegistro(strCampo, strTabla, strCondicion) {
     
     return this.http.post<any[]>(this.way + '/nom/cabhoraextr/c49u1oh2ajitytv', {
       elementos: {
         Campo: strCampo,
         Valor: strTabla,
         Condicion: strCondicion
       },
     }, this.confIniciales.httpOptions()).toPromise();
   }


  getSiguientePeriodoHorasExtras(strCABHORAEXTRCODIGO: number) {
  
    return this.http.post<any[]>(this.way + '/nom/cabhoraextr/qughcraw0ns907a', {
        elementos: {
          CABHORAEXTRCODIGO: strCABHORAEXTRCODIGO
        },
      }, this.confIniciales.httpOptions()).toPromise();
  }


  getUltimoPeriodoHorasExtras() {
    
  
    return this.http.post<any[]>(this.way + '/nom/cabhoraextr/vds49bjwkvrcy2w', {
        elementos: {},
      }, this.confIniciales.httpOptions()).toPromise();
  }

  

  getAnteriorPeriodoHorasExtras(strCABHORAEXTRCODIGO: number) {
    
  
    return this.http.post<any[]>(this.way + '/nom/cabhoraextr/8xfsjl1xcyepcxy', {
        elementos: {
          CABHORAEXTRCODIGO: strCABHORAEXTRCODIGO
        },
      }, this.confIniciales.httpOptions()).toPromise();
  }

 

  getPrimeroPeriodoHorasExtras() {
  
    return this.http.post<any[]>(this.way + '/nom/cabhoraextr/9unfvfdr5tcqyya', {
        elementos: {}
      }, this.confIniciales.httpOptions()).toPromise();
  }


  getPeriodoEnProceso(){
    return  this.http.post<any[]>(this.way + '/nom/cabhoraextr/iq4ojy48zuniwba', {
      elementos: {
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }


  actuCabHoraExtr(cabhoraextrCodigo, periodoCodigo, pFechaIni, pFechaFin){
    return  this.http.post<any[]>(this.way + '/nom/cabhoraextr/57z57iuboh7rj7l', {
      elementos: {
        p_cabhoraextr_codigo: cabhoraextrCodigo,   
        p_cabhoraextr_anio: '',      
        p_cabhoraextr_mes: '',       
        p_com_codigo: '01',            
        p_cabhoraextr_suelbasi: 0,  
        p_periodo_codigo: periodoCodigo,        
        p_periodo_fecini: pFechaIni,        
        p_periodo_fecfin: pFechaFin,    
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }


  actuDetHoraExtr(cabhoraextrCodigo: number, numsec: number, emp_codigo: number,numhoraextr25: number, numhoraextr50: number, num_numhoraextr100: number, 
    valhoraextr25: number, valhoraextr50: number, valhoraextr100: number,orden, rsu ){
  return  this.http.post<any[]>(this.way + '/nom/cabhoraextr/m4ybi3cpkjplln1', {
    elementos: {
      p_cabhoraextr_codigo: cabhoraextrCodigo,  
      p_dethoraextr_numsec: numsec,  
      p_emp_codigo  :emp_codigo,        
      p_com_codigo :'01',         
      p_num_numhoraextr25  : numhoraextr25, 
      p_num_numhoraextr50  : numhoraextr50, 
      p_num_numhoraextr100 : num_numhoraextr100, 
      p_num_valhoraextr25  : valhoraextr25,
      p_num_valhoraextr50  : valhoraextr50,
      p_num_valhoraextr100 : valhoraextr100,
      p_dethoraextr_orden  : orden, 
      p_dethoraextr_rsu    : rsu,
    },
  }, this.confIniciales.httpOptions()).toPromise();
}


  elimCabHoraExtr(cabhoraextrCodigo: number ){
    return  this.http.post<any[]>(this.way + '/nom/cabhoraextr/tc4afy4666hmifi', {
      elementos: {
        p_cabhoraextr_codigo: cabhoraextrCodigo,          
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  elimDetHoraExtr(cabhoraextrCodigo: number, dethoraextr_numsec){
    return  this.http.post<any[]>(this.way + '/nom/cabhoraextr/fqa6z7hrpdlcw8j', {
      elementos: {
        p_cabhoraextr_codigo: cabhoraextrCodigo,
        p_dethoraextr_numsec:   dethoraextr_numsec     
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }


  getInfoEmpleado(emp_int_empcodigo: number, txtCABHORAEXTR_ESTADO: string){

  
    return this.http.post<any[]>(this.way + '/nom/cabhoraextr/hrpdlcw8jfqa6z7', {

        elementos: {
          CABHORAEXTR_ESTADO: txtCABHORAEXTR_ESTADO,
          EMPCODIGO: emp_int_empcodigo
        },

      }, this.confIniciales.httpOptions()).toPromise();
  }



  obtienevalorhoraextra(dblRSU, str_CODRUBR, dblNUM_NUMHORAEXTR){
  
    return this.http.post<any[]>(this.way + '/nom/cabhoraextr/w8jfqa6z7hrpdlc', {
        elementos: {
          DblRSU: dblRSU,
          CODRUBR: str_CODRUBR,
          DblNUM_NUMHORAEXTR: dblNUM_NUMHORAEXTR
        },

      }, this.confIniciales.httpOptions()).toPromise();
  }
}


