import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {AggridBanComponent} from '../../ban/aggrid-ban/aggrid-ban.component';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {VendetfacService} from '../venservicios/vendetfac.service';
import {VenDetfacZ} from '../veninterfaces/vendetfacz';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';//CDPJ
@Component({
  selector: 'app-vendetfac',
  templateUrl: './vendetfac.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class VendetfacComponent implements OnInit, OnChanges {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  largo: string;
  allSelected: boolean;
  rowSelection = 'multiple';
  public frameworkComponents;
  @Input() encfacNumero: string;
  @Input() hmpConfiguracion: any[];
  @Input() nomComponente: string;
  @Output() detallesSeleccionados = new EventEmitter<VenDetfacZ[]>();
  @Output() signalSalir = new EventEmitter<boolean>();
  //CDPJ
  decimalesCantidadGre: number;
  decimalesPrecioGre: number;
  decimalesCantidadFac:number;
  decimalesPrecioFac:number;
  decimalestotalFac:number;
  decimalesdescFac:number;
  //CDPJ
  rowStyle;
  defaultColDef;
  columnDefsDetfac = [
    {
      headerName: '', field: 'SELECCIONADO', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    { // 0
      headerName: 'Bodega', field: 'BOD_CODIGO', width: 80, editable: false,
      // editable: true,
    },
    { // 1
      headerName: 'Código', field: 'DETFAC_CODIGO', width: 150, editable: false,
    },
    { // 2
      headerName: 'Descripción', field: 'DETFAC_DESCRIPCION', width: 200, editable: false
    },
    { // 3
      headerName: 'Iva', field: 'DETFAC_TRIBIVA', width: 60, editable: false
      // , editable: true,
    },
    { // 4
      headerName: 'Ice', field: 'DETFAC_TRIBICE', width: 60, editable: false
      // , editable: true,
    },
    {
      headerName: 'Unidad', field: 'DETFAC_UNIDAD', editable: false, width: 80,
    },
    { // 6
      headerName: 'Existencia', field: 'DETFAC_CANTIDAD', cellStyle: {textAlign: 'right'}, width: 90, editable: false
      // , editable: true,
    },
    { // 7
      headerName: 'Precio', field: 'DETFAC_PRECIO', cellStyle: {textAlign: 'right'}, width: 90, editable: false
      // , editable: true,
    },
    { // 8
      headerName: 'Dc (%)', field: 'DETFAC_DESCUENTO', cellStyle: {textAlign: 'right'}, width: 90, editable: false
      // , editable: true,
    },
    { // 9
      headerName: 'Total', field: 'DETFAC_TOTAL', cellStyle: {textAlign: 'right'}, width: 90, editable: false
      // , editable: true,
    },
    { // 11
      headerName: 'Lista', field: 'DETFAC_LISTA', width: 80, editable: false
      // , editable: true,
    },
    { // 12
      headerName: 'Factura', field: 'ENCFAC_NUMERO', editable: false, width: 200
    },
    { // 13
      headerName: 'Empresa', field: 'COM_CODIGO', editable: false, width: 80
    },
    { // 14
      headerName: 'Tipo', field: 'DETFAC_TIPODET', editable: false, width: 80
    },
    { // 15
      headerName: 'Valor IVA', field: 'DETFAC_IVA', editable: false, width: 100
    },
    { // 16
      headerName: 'Valor ICE', field: 'DETFAC_ICE', editable: false, width: 100
    },
    { // 17
      headerName: 'Ice (%)', field: 'DETFAC_PORCEICE', width: 80, editable: false
      // , editable: true,
    },
    { // 18
      headerName: 'Linea', field: 'DETFAC_LINEA', editable: false, width: 100
      // , editable: true,
    },
    { // 18
      headerName: 'Promoción', field: 'DETFAC_PROMOCION', editable: false, width: 100
      // , editable: true,
    },
    { // GSRF
      headerName: 'irbpnr', field: 'DETFAC_IRBPNR', editable: false, width: 100//GSRF
      // , editable: true,
    },
    { // GSRF
      headerName: 'Total irbpnr', field: 'DETFAC_VALORIRBPNR', editable: false, width: 100//GSRF
      // , editable: true,
    },
  ];
  
  constructor(public detfacService: VendetfacService,
    private confIniciales: ConfInicialesService//CDPJ
    ) {
    this.agTable();
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDef = {
      editable: true,
      width: 100,
      objeto: 'detfacgre',
      resizable: true
    };
  }
  
  ngOnInit() {
    this.allSelected = false;
    this.largo = '600';
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (this.encfacNumero !== undefined) {
      this.allSelected = false;
      this.buscarDetalleReferencia(this.encfacNumero);
      //CDPJ

      this.confIniciales.getDecimales('frmVEN_ENCFAC').subscribe((dec) => {
        if (dec !== null) {
          dec.map((decimales) => {
            if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
              this.decimalesCantidadFac = Number(decimales.NUMDEC_NUMDEC);
            } else if (decimales.NUMDEC_CAMPO === 'PRECIO') {
              this.decimalesPrecioFac = Number(decimales.NUMDEC_NUMDEC);
            }else if (decimales.NUMDEC_CAMPO === 'TOTAL') {
              this.decimalestotalFac = Number(decimales.NUMDEC_NUMDEC);
            } else if (decimales.NUMDEC_CAMPO === 'DESCUENTO') {
              this.decimalesdescFac = Number(decimales.NUMDEC_NUMDEC);
            }
          });
        }
      });

      //CDPJ
    }
  }
  
  buscarDetalleReferencia(strNumRef) {
    let strSql = '';
    //CDPJ
    this.confIniciales.getDecimales('frmVEN_ENCFAC').subscribe((dec) => {
      if (dec !== null) {
        dec.map((decimales) => {
          if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
            this.decimalesCantidadFac = Number(decimales.NUMDEC_NUMDEC);
          } else if (decimales.NUMDEC_CAMPO === 'PRECIO') {
            this.decimalesPrecioFac = Number(decimales.NUMDEC_NUMDEC);
          }else if (decimales.NUMDEC_CAMPO === 'TOTAL') {
            this.decimalestotalFac = Number(decimales.NUMDEC_NUMDEC);
          } else if (decimales.NUMDEC_CAMPO === 'DESCUENTO') {
            this.decimalesdescFac = Number(decimales.NUMDEC_NUMDEC);
          }
        });
      }
    });
    //CDPJ
    if (this.nomComponente === 'venGuiaRem') {
      //CDPJ
      this.confIniciales.getDecimales('frmVEN_ENCFAC').subscribe((dec) => {
        if (dec !== null) {
          dec.map((decimales) => {
            if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
              this.decimalesCantidadFac = Number(decimales.NUMDEC_NUMDEC);
            } else if (decimales.NUMDEC_CAMPO === 'PRECIO') {
              this.decimalesPrecioFac = Number(decimales.NUMDEC_NUMDEC);
            }
          });
        }
      });
      //CDPJ
      strSql = 'select BOD_CODIGO, DETFAC_CODIGO, DETFAC_DESCRIPCION, DETFAC_TRIBIVA, DETFAC_TRIBICE, UNI_SIMBOLO AS DETFAC_UNIDAD,';
      strSql = strSql + ' ((select nvl(sum(f.detfac_cantidad), 0)  from  ven_detfac f, ven_encfac e where f.encfac_numero = \'' + strNumRef + '\'';
      strSql = strSql + '  AND f.COM_CODIGO = \'01\' AND f.encfac_numero = e.encfac_Numero AND f.COM_CODIGO = e.COM_CODIGO';
      strSql = strSql + '  AND f.detfac_linea = ff.DETFAC_linea AND e.encfac_estado <> \'A\' ) -(select nvl(sum(g.detgre_cantidad), 0)';
      strSql = strSql + ' from ven_encgre w, ven_detGre g, ven_encfac e where w.encgre_codigo=g.encgre_codigo and w.com_codigo=g.com_codigo';
      strSql = strSql + ' and w.encgre_estado <> \'A\' and  e.encfac_numero = \'' + strNumRef + '\' AND e.COM_CODIGO = \'01\'';
      strSql = strSql + ' AND e.encfac_numero = g.encfac_Numero AND e.COM_CODIGO = g.COM_CODIGO AND detfac_linea = ff.DETFAC_linea';
      strSql = strSql + ' AND e.encfac_estado <> \'A\'))  AS DETFAC_CANTIDAD,';
      strSql = strSql + ' DETFAC_PRECIO, DETFAC_DESCUENTO, DETFAC_TOTAL,';
      strSql = strSql + ' DETFAC_LISTA, ENCFAC_NUMERO, COM_CODIGO, DETFAC_TIPODET,';
      strSql = strSql + ' DETFAC_IVA, DETFAC_ICE, DETFAC_PORCEICE, DETFAC_LINEA, ';
      strSql = strSql + ' DETFAC_PROMOCION , DETFAC_IRBPNR, DETFAC_VALORIRBPNR from  ven_detfac FF where encfac_numero = \'' + strNumRef + '\'';
      strSql = strSql + ' AND COM_CODIGO = \'01\'';
      strSql = strSql + ' AND ((select nvl(sum(f.detfac_cantidad), 0)  from  ven_detfac f, ven_encfac e where f.encfac_numero = \'' + strNumRef + '\'';
      strSql = strSql + ' AND f.COM_CODIGO = \'01\'';
      strSql = strSql + ' AND f.encfac_numero = e.encfac_Numero AND f.COM_CODIGO =';
      strSql = strSql + ' e.COM_CODIGO AND f.detfac_linea = ff.DETFAC_linea AND e.encfac_estado <> \'A\' ) - (select nvl(sum(g.detgre_cantidad),';
      strSql = strSql + ' 0) from ven_encgre w,ven_detGre g, ven_encfac e where w.encgre_codigo=g.encgre_codigo and w.com_codigo=g.com_codigo';
      strSql = strSql + ' and w.encgre_estado <> \'A\' and e.encfac_numero = \'' + strNumRef + '\'';
      strSql = strSql + ' AND e.COM_CODIGO = \'01\'';
      strSql = strSql + ' AND e.encfac_numero = g.encfac_Numero AND e.COM_CODIGO = g.COM_CODIGO AND detfac_linea = ff.DETFAC_linea';
      strSql = strSql + ' AND e.encfac_estado <> \'A\'))  > 0 order by detfac_linea';
      
      this.detfacService.getDetalleRef1(strNumRef).subscribe((res) => {
        this.detfacService.detfac = res;
        this.detfacService.detfac.map((detf) => {
          detf.SELECCIONADO = false;
          //detf.DETFAC_PRECIO = Number(detf.DETFAC_PRECIO).toFixed(4);
          //detf.DETFAC_CANTIDAD = Number(detf.DETFAC_CANTIDAD).toFixed(4);
          detf.DETFAC_PRECIO = Number(detf.DETFAC_PRECIO).toFixed(this.decimalesPrecioFac);//CDPJ
          detf.DETFAC_CANTIDAD = Number(detf.DETFAC_CANTIDAD).toFixed(this.decimalesCantidadFac);//CDPJ
          detf.DETFAC_IRBPNR = Number(detf.DETFAC_IRBPNR).toFixed(this.decimalesCantidadFac);//GSRF
            detf.DETFAC_VALORIRBPNR = Number(detf.DETFAC_VALORIRBPNR).toFixed(this.decimalesCantidadFac);//GSRF
            detf.DETFAC_TOTAL = Number(detf.DETFAC_TOTAL).toFixed(this.decimalestotalFac);//GSRF
          detf.DETFAC_DESCUENTO = Number(detf.DETFAC_DESCUENTO).toFixed(this.decimalesdescFac);//GSRF
        });
      });
    
    } else {
      
      const FPLOTSIM = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FPLOTSIM').CFG_VALOR1;
      
      if (FPLOTSIM === 0) {
        strSql = 'SELECT BOD_CODIGO,DETFAC_CODIGO,DETFAC_DESCRIPCION,DETFAC_UNIDAD,';
        strSql = strSql + ' (DETFAC_CANTIDAD - ( SELECT NVL(SUM(DETDVF_CANTIDAD),0) FROM VEN_ENCDVF E, VEN_DETDVF D';
        strSql = strSql + ' WHERE E.ENCDVF_NUMERO = D.ENCDVF_NUMERO AND E.COM_CODIGO = D.COM_CODIGO AND';
        strSql = strSql + ' ENCDVF_ESTADO <> \'A\' AND ENCFAC_NUMERO = DET.ENCFAC_NUMERO AND DETFAC_LINEA = DET.DETFAC_LINEA ) ) AS DETFAC_CANTIDAD  ,';
        strSql = strSql + ' DETFAC_TRIBIVA,DETFAC_TRIBICE,DETFAC_PRECIO,DETFAC_LINEA,';
        strSql = strSql + ' DETFAC_TOTAL,DETFAC_DESCUENTO,DETFAC_TIPODET,DETFAC_LISTA,ENCFAC_NUMERO,';
        strSql = strSql + ' CEN_CODIGO, DETFAC_PORCEICE, DETFAC_PROMOCION, DETFAC_IRBPNR, DETFAC_VALORIRBPNR,' +
        ' DETFAC_TIPOSRI, nvl(( select  maefar_vencimiento from inv_maefar i ' +
        ' where i.art_codigo=DET.detfac_codigo ),\'N\') as maefar_vencimiento, DETFAC_CANTIDADUND,';
        strSql = strSql + ' DETFAC_IVA, DETFAC_ICE, DETFAC_BASEIVA, DETFAC_BASEICE, DETFAC_BASECERO';
        strSql = strSql + ' FROM VEN_DETFAC  DET WHERE ENCFAC_NUMERO=\'' + strNumRef + '\' AND DETFAC_TIPODET <> \'*\'';
        strSql = strSql + ' AND COM_CODIGO = \'01\' ORDER BY DETFAC_LINEA';

        this.detfacService.getDetalleRef2(strNumRef).subscribe((res) => {
          this.detfacService.detfac = res;
          this.detfacService.detfac.map((detf) => {
            detf.SELECCIONADO = false;
            //detf.DETFAC_PRECIO = Number(detf.DETFAC_PRECIO).toFixed(4);
            //detf.DETFAC_CANTIDAD = Number(detf.DETFAC_CANTIDAD).toFixed(4);
            detf.DETFAC_PRECIO = Number(detf.DETFAC_PRECIO).toFixed(this.decimalesPrecioFac);//CDPJ
            detf.DETFAC_CANTIDAD = Number(detf.DETFAC_CANTIDAD).toFixed(this.decimalesCantidadFac);//CDPJ
            detf.DETFAC_IRBPNR = Number(detf.DETFAC_IRBPNR).toFixed(this.decimalesCantidadFac);//GSRF
            detf.DETFAC_VALORIRBPNR = Number(detf.DETFAC_VALORIRBPNR).toFixed(this.decimalesCantidadFac);//GSRF
            detf.DETFAC_TOTAL = Number(detf.DETFAC_TOTAL).toFixed(this.decimalestotalFac);//GSRF
            detf.DETFAC_DESCUENTO = Number(detf.DETFAC_DESCUENTO).toFixed(this.decimalesdescFac);//GSRF
          });
        });
      
      } else {
      
        strSql = 'SELECT BOD_CODIGO,DETFAC_CODIGO,DETFAC_DESCRIPCION,DETFAC_UNIDAD,';
        strSql = strSql + ' (DETFAC_CANTIDAD - ( SELECT NVL(SUM(DETDVF_CANTIDAD),0) FROM VEN_ENCDVF E, VEN_DETDVF D';
        strSql = strSql + ' WHERE E.ENCDVF_NUMERO = D.ENCDVF_NUMERO AND E.COM_CODIGO = D.COM_CODIGO AND';
        strSql = strSql + ' ENCDVF_ESTADO <> \'A\' AND ENCFAC_NUMERO = DET.ENCFAC_NUMERO AND DETFAC_LINEA = DET.DETFAC_LINEA )) AS DETFAC_CANTIDAD,';
        strSql = strSql + ' DETFAC_TRIBIVA,DETFAC_TRIBICE,DETFAC_PRECIO,DETFAC_LINEA,';
        strSql = strSql + ' DETFAC_TOTAL,DETFAC_DESCUENTO,DETFAC_TIPODET,DETFAC_LISTA,ENCFAC_NUMERO,';
        strSql = strSql + ' CEN_CODIGO, DETFAC_PORCEICE, DETFAC_PROMOCION , DETFAC_IRBPNR, DETFAC_VALORIRBPNR,' +
        ' DETFAC_TIPOSRI, nvl(( select  art_caduca from inv_maearticulo i ' +
        ' where i.art_codigo=DET.detfac_codigo ),\'N\') as art_caduca, DETFAC_CANTIDADUND,';
        strSql = strSql + ' DETFAC_IVA, DETFAC_ICE, DETFAC_BASEIVA, DETFAC_BASEICE, DETFAC_BASECERO';
        strSql = strSql + ' FROM VEN_DETFAC  DET WHERE ENCFAC_NUMERO=\'' + strNumRef + '\' AND DETFAC_TIPODET <> \'*\'';
        strSql = strSql + ' AND COM_CODIGO = \'01\' ORDER BY DETFAC_LINEA';
        
        this.detfacService.getDetalleRef3(strNumRef).subscribe((res) => {
          this.detfacService.detfac = res;
          this.detfacService.detfac.map((detf) => {
            detf.SELECCIONADO = false;
            //detf.DETFAC_PRECIO = Number(detf.DETFAC_PRECIO).toFixed(4);
            //detf.DETFAC_CANTIDAD = Number(detf.DETFAC_CANTIDAD).toFixed(4);
            detf.DETFAC_PRECIO = Number(detf.DETFAC_PRECIO).toFixed(this.decimalesPrecioFac);//CDPJ
            detf.DETFAC_CANTIDAD = Number(detf.DETFAC_CANTIDAD).toFixed(this.decimalesCantidadFac);//CDPJ
            detf.DETFAC_IRBPNR = Number(detf.DETFAC_IRBPNR).toFixed(this.decimalesCantidadFac);//GSRF
            detf.DETFAC_VALORIRBPNR = Number(detf.DETFAC_VALORIRBPNR).toFixed(this.decimalesCantidadFac);//GSRF
            detf.DETFAC_TOTAL = Number(detf.DETFAC_TOTAL).toFixed(this.decimalestotalFac);//GSRF
            detf.DETFAC_DESCUENTO = Number(detf.DETFAC_DESCUENTO).toFixed(this.decimalesdescFac);//GSRF
          });
        });
      
      }
  
    }
    
    /* this.detfacService.getDetalleRef(strSql).subscribe((res) => {
      this.detfacService.detfac = res;
      this.detfacService.detfac.map((detf) => {
        detf.SELECCIONADO = false;
        detf.DETFAC_PRECIO = Number(detf.DETFAC_PRECIO).toFixed(4);
        detf.DETFAC_CANTIDAD = Number(detf.DETFAC_CANTIDAD).toFixed(4);
      });
    }); */
    
  }
  
  seleccionar() {
    if (this.allSelected === true) {
      this.detfacService.detfac.map((detf) => {
        detf.SELECCIONADO = true;
      });
      this.aggrid.refreshaggrid(this.detfacService.detfac, this.defaultColDef.objeto);
    } else if (this.allSelected === false) {
      this.detfacService.detfac.map((detf) => {
        detf.SELECCIONADO = false;
      });
      this.aggrid.refreshaggrid(this.detfacService.detfac, this.defaultColDef.objeto);
    }
  }
  
  enviarDetalles() {
    let arregloaEnviar: VenDetfacZ[];
    arregloaEnviar = [];
    this.detfacService.detfac.map((detf) => {
      if (detf.SELECCIONADO === true) {
        arregloaEnviar.push(detf);
      }
    });
    this.detallesSeleccionados.emit(arregloaEnviar);
  }
  
  enviarSalir() {
    this.signalSalir.emit(true);
  }
  
}
