<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="segMaeUsuario"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="segmaeusuarioConf" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
			[botonBorrar]="btnBorrar" (signal)="manejarSenales($event)" [botonAnterior]="botonAnterior"
			[botonPrimero]="botonPrimero" [botonUltimo]="botonUltimo" [botonSiguiente]="botonSiguiente"
			[botonBuscar]="btnBuscar" [barraBotones3]="true" [botonBinocular]="true">
		</app-invmaebarra>
	</div>
</div>
<p-tabView (onChange)="onTab1Change($event)" [(activeIndex)]="indexTabUsuarios">
	<p-tabPanel header="Datos Generales" [disabled]="disabledTab0">
		<div class="divgrups" id="divPrincipal">
			<div class="p-grid">
				<div class="p-col-12">
					<div class="p-grid">
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
					</div>
				</div>
				<div class="p-col-1">
					<span>CI*:</span>
				</div>
				<div class="p-col-2">
					<input id="numCedUser" type="text" pInputText [(ngModel)]="txtCiRuc" (input)="cambio($event)"
						maxlength="10" (keydown.enter)="cambiarFoco($event)" autocomplete="off">
				</div>
				<div class="p-col-6">
				</div>
				<div class="p-col-2">
					<span [ngStyle]="{'color': estadoUsuario === 'CONECTADO' ? '#0c54b8' : 'lightslategray'}"
						style=" font-size: 18px; font-weight: bold;"> {{estadoUsuario}} </span>
				</div>

				<div class="p-col-12" style="margin-top: -7px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Nombres*:</span>
						</div>
						<div class="p-col-6">
							<input id="nombreUser" type="text" pInputText [(ngModel)]="txtNombres"
								(input)="cambio($event)" maxlength="45" (keydown.enter)="cambiarFoco($event)"
								autocomplete="off">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Apellidos*:</span>
						</div>
						<div class="p-col-6">
							<input id="apellidoUser" type="text" pInputText [(ngModel)]="txtApellidos"
								(input)="cambio($event)" maxlength="45" (keydown.enter)="cambiarFoco($event)"
								autocomplete="off">
						</div>
						<div class="p-col-1">
						</div>
						<div class="p-col-1">
							<span style="font-size: 16px; font-weight: bold; float: right">IP Pública:</span>
						</div>
						<div class="p-col-1">
							<span style=" font-size: 14px;">{{txtIPPublica}}</span>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Correo*:</span>
						</div>
						<div class="p-col-2">
							<input id="correoPinUser" type="email" pInputText [(ngModel)]="txtCorreoPin" maxlength="100"
								(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)" autocomplete="off">
						</div>
						<div class="p-col-1">
						</div>
						<div class="p-col-1">
							<span>Teléfono Móvil*:</span>
						</div>
						<div class="p-col-2">
							<input id="celularPinUser" type="text" pInputText [(ngModel)]="txtCelularPin"
								(input)="cambio($event)" maxlength="10" (keydown.enter)="cambiarFoco($event)"
								autocomplete="off">
						</div>
						<div class="p-col-1">
						</div>
						<div class="p-col-1">
							<span style="font-size: 16px; font-weight: bold; float: right">Host:</span>
						</div>
						<div class="p-col-3">
							<span style=" font-size: 14px;">{{txtIPLocal}}</span>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Perfil*:</span>
						</div>
						<div class="p-col-2">
							<p-dropdown [style]="{'width': '100%', 'min-width': '100%'}" optionLabel="perfilnombre"
								[(ngModel)]="strPerfilUsuario" inputId="cmbPerfilUsuario" [options]="cmbPerfil"
								(onChange)="cambio($event)" (keydown.enter)="cambiarFoco($event)">
							</p-dropdown>
						</div>
						<div class="p-col-1">
						</div>
						<div class="p-col-1">
							<span>Clave* (max. 10):</span>
						</div>
						<div class="p-col-2">
							<input id="clave" type="password" pInputText [(ngModel)]="txtClave" (input)="cambio($event)"
								maxlength="10" (keydown.enter)="cambiarFoco($event)" autocomplete="off">
						</div>
						<div class="p-col-1">
						</div>

					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>IdUsuario*:</span>
						</div>
						<div class="p-col-2">
							<input id="usuario" type="text" pInputText [(ngModel)]="txtIdUsuario"
								[disabled]="enabledIdUsuario" maxlength="10" (input)="cambio($event)"
								(keydown.enter)="cambiarFoco($event)" autocomplete="off"
								style="text-transform: lowercase;"> <!-- CDPJ -->
						</div>
						<div class="p-col-1">
						</div>
						<div class="p-col-1">
							<span>Confirmar Clave*:</span>
						</div>
						<div class="p-col-2">
							<input id="confirmarClave" type="password" pInputText [(ngModel)]="txtConfirmarClave"
								(input)="cambio($event)" maxlength="10" (keydown.enter)="cambiarFoco($event)"
								autocomplete="off">
						</div>
					</div>
				</div>

				<div class="p-col-12">
					<div class="p-grid">
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Fecha Expedición:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<p-calendar id="fechaExpedicion" dateFormat="dd/mm/yy"
								[style]="{'width': '100%', 'min-width': '100%'}" [(ngModel)]="dateTimeFechaExpedicion"
								(ngModelChange)="cambio($event)"></p-calendar>
						</div>
						<div class="p-col-2">
						</div>
						<div class="p-col-1">
							<span>Fecha Caducidad:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<p-calendar id="fechaCaducidad" dateFormat="dd/mm/yy"
								[style]="{'width': '100%', 'min-width': '100%'}" [(ngModel)]="dateTimeFechaCaducidad"
								(ngModelChange)="cambio($event)"></p-calendar>
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Bodega Ventas:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<input id="BOD_VENTAS" type="text" pInputText autocomplete="off" [(ngModel)]="txtCodBodAsig"
								(input)="cambioBodega($event)" maxlength="10" (keydown.enter)="cambiarFoco($event)">
						</div>
						<div class="p-col-1">
							<button type="button"
								style="background-image: url(../../../assets/images/iconos/buscar.png);"
								class="littlebuttons" (click)="buscarBodega(txtCodBodAsig)"></button>
						</div>
						<div class="p-col-4" style="text-align: left">
							<input id="BOD_VENTASNOMBRE" type="text" pInputText disabled autocomplete="off"
								[(ngModel)]="lblNomBodVentas">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Bodega Ing.Dev.:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<input id="BOD_INGDEV" type="text" pInputText autocomplete="off"
								[(ngModel)]="txtCodBodIngDev" (input)="cambioBodegaDev($event)" maxlength="10"
								(keydown.enter)="cambiarFoco($event)">
						</div>
						<div class="p-col-1">
							<button type="button"
								style="background-image: url(../../../assets/images/iconos/buscar.png);"
								class="littlebuttons" (click)="buscarBodegaDev(txtCodBodIngDev)"></button>
						</div>
						<div class="p-col-4" style="text-align: left">
							<input id="BOD_INGDEVNOMBRE" type="text" pInputText disabled autocomplete="off"
								[(ngModel)]="lblNomBodIngDev">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Vendedor:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<input id="CODIGO_VENDEDOR" type="text" pInputText autocomplete="off"
								[(ngModel)]="txtCodVendedor" (input)="cambioVendedor($event)" maxlength="5"
								(keydown.enter)="cambiarFoco($event)">
						</div>
						<div class="p-col-1">
							<button type="button"
								style="background-image: url(../../../assets/images/iconos/buscar.png);"
								class="littlebuttons" (click)="buscarVendedor(txtCodVendedor)"></button>
						</div>
						<div class="p-col-4" style="text-align: left">
							<input id="NOMBRE_VENDEDOR" type="text" pInputText disabled autocomplete="off"
								[(ngModel)]="lblNombreVen">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px" *ngIf="marcasaci">
					<div class="p-grid">
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-2">
									<p-checkbox class="txtAlign" id="chkautentifi" name="chkautentifi" value="" label=""
										binary="{{chkautentifi}}" [(ngModel)]="chkautentifi" (click)="cambio($event)">
									</p-checkbox>
								</div>
								<div class="p-col-10">
									<span>Autenticación en dos pasos</span>
								</div>
							</div>
						</div>
						<!-- CDPJ -->
						<div class="p-col-1"></div>
						<div class="p-col-4">
							<div class="p-grid">
								<div class="p-col-1">
									<p-checkbox class="txtAlign" id="chkverpreconsexis" name="chkverpreconsexis" value="" label=""
										binary="{{chkverpreconsexis}}" [(ngModel)]="chkverpreconsexis" (click)="cambio($event)">
									</p-checkbox>
								</div>
								<div class="p-col-10">
									<span>Visualizar lista de precios en consultar existencia</span>
								</div>
							</div>
						</div>
						
						<div class="p-col-4">
							<div class="p-grid">
								<div class="p-col-1">
									<p-checkbox class="txtAlign" id="chkvertotcxc" name="chkvertotcxc" value="" label=""
										binary="{{chkvertotcxc}}" [(ngModel)]="chkvertotcxc" (click)="cambio($event)">
									</p-checkbox>
								</div>
								<div class="p-col-10">
									<span>Visualizar saldo total de la cartera en el módulo de cobranzas</span>
								</div>
							</div>
						</div>
						<!-- CDPJ -->						
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-2">
									<p-checkbox binary="true" [(ngModel)]="chkbtnALMACENARDOC" (onChange)="cambio($event)">
									</p-checkbox>
								</div>
								<div class="p-col-10">
									<span>Almacenar documentos</span>
								</div>
							</div>
						</div>
						<div class="p-col-1">
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-2">
									<p-checkbox binary="true" [(ngModel)]="chkbtnVISUALIZARDOC" (onChange)="cambio($event)">
									</p-checkbox>
								</div>
								<div class="p-col-10">
									<span>Visualizar documentos</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- GSRF -->
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-2">
									<p-checkbox binary="true" [(ngModel)]="chkbtntodclientes" (onChange)="cambio($event)">
									</p-checkbox>
								</div>
								<div class="p-col-10">
									<span>Movil: Visualizar todos los clientes</span>
								</div>
							</div>
						</div>
						<div class="p-col-1">
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-2">
									<p-checkbox binary="true" [(ngModel)]="chkbtntodpedidos" (onChange)="cambio($event)">
									</p-checkbox>
								</div>
								<div class="p-col-10">
									<span>Movil: Visualizar todos los pedidos</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- GSRF -->
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Permisos Personalizados" [disabled]="disabledTab1">
		<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 270px)'}">
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-1">
					</div>
					<div class="p-col-5">
						<span>Usuario: &nbsp;&nbsp;&nbsp;&nbsp; {{lblNombreUsuarioPer}} </span>
					</div>
					<!--					<div class="p-col-5">-->
					<!--						<input id="NOMBRE_USERPER" type="text" pInputText disabled autocomplete="off" [(ngModel)]="lblNombreUsuarioPer">-->
					<!--					</div>-->
					<div class="p-col-12">
						<div class="p-grid">
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-10">
								<p-tree [value]="treeData" selectionMode="checkbox"
									(onNodeSelect)="permisoSelect($event)" (onNodeUnselect)="permisoUnselect($event)"
									[(selection)]="selectedFiles2" styleClass="arbol"></p-tree>
							</div>

						</div>

					</div>
				</div>
			</div>
		</p-scrollPanel>
	</p-tabPanel>
	<p-tabPanel header="Permisos Predefinidos" [disabled]="disabledTab2">
		<div class="divgrups" style="height: 400px">
			<div class="p-grid">
				<div class="p-col-1">
				</div>
				<div class="p-col-5">
					<span>Usuario: &nbsp;&nbsp;&nbsp;&nbsp; {{lblNombreUsuario}}</span>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
					</div>
				</div>

				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-10">
							<span>Datos para copiar los permisos </span>
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-2">
						</div>
						<div class="p-col-1">
							<span>Usuario: </span>
						</div>
						<div class="p-col-3" style="text-align: left">
							<p-dropdown [style]="{'width':'100%'}" optionLabel="name" inputId="cmbUsuario"
								[(ngModel)]="strCmbUsuario" [options]="cmbUsuario" (onChange)="cambioCmbUser($event)">
							</p-dropdown>
						</div>
					</div>
				</div>

				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-2">
						</div>
						<div class="p-col-1">
							<span>Módulo: </span>
						</div>
						<div class="p-col-3" style="text-align: left">
							<p-dropdown [style]="{'width':'100%'}" optionLabel="name" inputId="cmbModulo"
								[(ngModel)]="strCmbModulo" [options]="cmbModulo" (onChange)="cambioCmbModulo($event)"
								[disabled]="disabledSelTodosMod"></p-dropdown>
						</div>
						<div class="p-col-2">
							<p-checkbox label="Seleccionar Todos" binary="true" [(ngModel)]="chkSeleccionarTodosModulos"
								(onChange)="selTodosModulos($event)">
							</p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-2">
						</div>
						<div class="p-col-1">
							<span>Menús: </span>
						</div>
						<div class="p-col-3" style="text-align: left">
							<p-dropdown [style]="{'width':'100%'}" optionLabel="name" inputId="cmbMenu"
								[(ngModel)]="strCmbMenu" [options]="cmbMenu" [disabled]="disabledSelTodosMenu">
							</p-dropdown>
						</div>
						<div class="p-col-2">
							<p-checkbox label="Seleccionar Todos" binary="true" [(ngModel)]="chkSeleccionarTodosMenus"
								(onChange)="selTodosMenus($event)">
							</p-checkbox>
						</div>
					</div>
				</div>

			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Numeraciones PV" [disabled]="disabledTab3">
		<div class="divgrups" style="height: 400px">
			<div class="p-grid">
				<div class="p-col-1">
				</div>
				<div class="p-col-5">
					<span>Usuario: &nbsp;&nbsp;&nbsp;&nbsp; {{lblNombreUsuarioNum}}</span>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-12">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
								[rowData]="segMaeUsuarioService.detnumeracionarray[indicador]"
								[frameworkComponents]="frameworkComponents" [animateRows]="true"
								[rowSelection]="rowSelection" [columnDefs]="columnDefsSegNumPV"
								[defaultColDef]="defaultSegNumPV" [rowStyle]="rowStyle" [singleClickEdit]="false"
								[botones]="true" [mostrarGuardar]="false" [botonesAdicionales]="true"
								[botonNuevo]="btnNuevoGrid" [botonBorrar]="btnBorrarGrid"
								[botonRegresar]="btnCancelarGrid" (selected)="seleccionDetNumeracion($event)"
								(cambios)="cambioGrid($event)"></app-aggridsaci>
						</div>
					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Permisos Especiales" [disabled]="disabledTab4">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-1">
				</div>
				<div class="p-col-5">
					<span>Usuario: &nbsp;&nbsp;&nbsp;&nbsp; {{lblNombreUsuarioPerEsp}}</span>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
					</div>
				</div>

				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-3">
						</div>
						<div class="p-col-1">
							<span>Módulo: </span>
						</div>
						<div class="p-col-2" style="text-align: left">
							<p-dropdown [style]="{'width':'100%'}" optionLabel="name" inputId="cmbModuloPerEsp"
								[(ngModel)]="strCmbModuloPerEsp" [options]="cmbModuloPerEsp"
								(onChange)="cambioCmbModuloPerEsp($event)"></p-dropdown>
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-3">
						</div>
						<div class="p-col-1">
							<span>Opciones: </span>
						</div>
						<div class="p-col-2" style="text-align: left">
							<p-dropdown [style]="{'width':'100%'}" optionLabel="name" inputId="cmbOpcPerEsp"
								[(ngModel)]="strCmbOpcPerEsp" [options]="cmbOpcPerEsp"
								(onChange)="cambioCmbOpcionPerEsp($event)"></p-dropdown>
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-3">
						</div>
						<div class="p-col-1">
							<span>ID Super Usuario: </span>
						</div>
						<div class="p-col-2" style="text-align: left">
							<p-dropdown [style]="{'width':'100%'}" optionLabel="name" inputId="cmbSuperUser"
								[(ngModel)]="strCmbSuperUser" [options]="cmbSuperUser"
								(onChange)="cambioCmbSuperUser($event)"></p-dropdown>
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-2">
						</div>
						<div class="p-col-8">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
								[rowData]="segMaeUsuarioService.detguiobjpermiarray[indicador]"
								[frameworkComponents]="frameworkComponents" [animateRows]="true"
								[rowSelection]="rowSelection" [columnDefs]="columnDefsSegPerEsp"
								[defaultColDef]="defaultSegPerEsp" [rowStyle]="rowStyle" [singleClickEdit]="false"
								[botones]="false" (selected)="seleccionDetPerEsp($event)"
								(cambios)="cambioGridPerEsp($event)"></app-aggridsaci>
						</div>
					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
</p-tabView>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBus" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [closable]="false" [style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
		(opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
		[where]="where" [busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)">
	</app-busquedadlg>
</p-dialog>
<div style=" 	position: fixed;
				z-index: 999;
				height: 2em;
				width: 2em;
				overflow: visible;
				margin: auto;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;

				">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
</div>

<p-dialog header="Código de seguridad" [(visible)]="displayToken" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [closable]="true" [showHeader]="true">
	<p-toast [style]="{marginTop: '35vh'}" position="top-center" key="segMaeUsuToken"></p-toast>
	<div style="background-color: rgb(235,245,251); padding: 1.5em;">
		<div class="p-col-12" style="text-align: center">
			<input style="font-size: 25pt; width: 150px; height: 60px;" type="password" [(ngModel)]="tokenusuario"
				(keydown)="enterToken($event)" pInputText autocomplete="off">
		</div>
		<div class="p-col-12" style="text-align: center">
			<div class="p-grid">
				<div class="p-col-6" style="text-align: center">
					<button class="aceptar" (click)="aceptarToken()" pButton type="button" icon="pi pi-user"
						label="Aceptar"></button>
				</div>
				<div class="p-col-6" style="text-align: center">
					<button class="aceptar" (click)="cancelarToken()" pButton type="button" icon="pi pi-undo"
						label="Cancelar"></button>
				</div>
			</div>
		</div>
	</div>
</p-dialog>