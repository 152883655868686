//
// SACI WEB
// Rutina: Maestro de Tipo de Diario
// Propietario: Nicole Pirca
// Modificado por: SS
// Fecha de creación: 2019
// Fecha de Modificación: 8-12-2019
//


import {Component, OnDestroy, OnInit,ViewChild} from '@angular/core';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {ConmaetipoDiarioService} from '../conservicios/conmaetipo-diario.service';
import {Conmaetipodiario} from '../coninterfaces/conmaetipo-diario';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import { AggridBanComponent } from 'src/app/ban/aggrid-ban/aggrid-ban.component';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';

@Component({
  selector: 'app-conmaetipo-diario',
  templateUrl: './conmaetipo-diario.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class ConmaetipoDiarioComponent implements OnInit, OnDestroy {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  defaultColDefconmaetipodiario;
  public frameworkComponents;
  rowStyle;
  largo = '258';
  rowSelection = 'multiple';
  conmaetipodiariov: Conmaetipodiario[] = [];
  selectedconmaetipodiariov: Conmaetipodiario;


  tipodiarioSeleccionado: Conmaetipodiario;
  tipodiarioSeleccionadoAux: Conmaetipodiario;
  btnNuevo: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  btnGuardar: boolean;
  btnPrimero: boolean;
  btnAnterior: boolean;
  btnSiguiente: boolean;
  btnUltimo: boolean;
  inputNum: boolean;
  indice: number;
  opcion: string;
  codigoAux: any;
  permisoTipoDiario: SegMaePermiso;
  // arregloCons: any[];
 // types: SelectItem[];
  codigoAnt: string;
  displayDialogBus2: boolean;
  // arregloBus: any[];
  opcionDropDown: string;
  auxZeros: string;
  
  // BUSQUEDA
  displayDialogBus: boolean;
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  where: string;
  columnDefConMaeTip = [
    {
      headerName: '', field: 'NUMERO', editable: false, width: 50,
    },
    {
      headerName: 'Código.', field: 'TIPDIA_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 4,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Nombre', field: 'TIPDIA_NOMBRE', cellEditor: 'cellPrueba', width: 350, editable:true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 35,
            tienecalculos: false,
          }
        }
    } ,
    {
      headerName: 'No. Actual', field: 'TIPDIA_NROACTUAL', cellEditor: 'cellPrueba', width: 200, editable:true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 14,
            tienecalculos: false,
          }
        }
    }, 
    
    {
      headerName: 'Mandatorio', field: 'EDITABLE',cellRendererFramework: AggridBanComponent, editable:true,checkCierre: true, width: 200, onCellClicked: (params) => {
        console.log(params);
        this.seleccionItem(params.data);  
      }
    },

  ] 
  constructor(private tipodiarioService: ConmaetipoDiarioService, private message: MessageService,
              private errorService: ErroresBaseDatosService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService, private permisosService: PermisosService,
              private auditoriagral: AuditoriagralService, private confInciales: ConfInicialesService,
              private busqService: InvbusquedaService,  private init: NuevoComponentService,
              private busquedaSer: InvbusquedaService) {
                this.agTable()
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefconmaetipodiario= {
      editable: true,
      width: 130,
      objeto: 'maeConsultaTipoRef'
    };
  }
  ngOnInit() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.btnPrimero = false;
    this.btnAnterior = false;
    this.btnSiguiente = false;
    this.btnUltimo = false;
    this.indice = 0;
    this.inputNum = true;
    
    this.permisosService.getListaPermisos('CON', '1', '7').subscribe((permiso) => {
      this.permisoTipoDiario = permiso[0];
    });
    this.codigoAnt = '';
    this.tipodiarioSeleccionado = {};
    this.arregloBus = [];
    this.displayDialogBus2 = false;
    this.tipodiarioSeleccionadoAux = {};
    this.tipodiarioService.getTipoDiario().toPromise().then((res) => {
      this.tipodiarioService.contmaeipodiario = res;
      this.conmaetipodiariov=res
      this.conmaetipodiariov.map(x=>{
        x.NUMERO = this.conmaetipodiariov.indexOf( x ) + 1;
        x.EDITABLE= (x.TIPDIA_MANDATORIO==1)?true:false;
        x.NUEVO=false;
       
      })
    });
    this.opcionDropDown = '';
    this.auxZeros = '';
  
    this.displayDialogBus = false;
    this.arregloBus = [];
    this.opcion = '';
    
  }
  selectConMaeTipo(elemento){
     if (elemento.object !== null) {
      this.selectedconmaetipodiariov= elemento.object;
    }
  }
  cambioModulo(evento){
    this.btnGuardar = false;
    this.btnRegresar = false;
    this.btnNuevo = true;
  }
  manejarSenales(valor) {
    
    if (this.tipodiarioService.contmaeipodiario.length !== 0) {
      if (valor === 'Primero') {
        this.btnAnterior = true;
        this.btnPrimero = true;
        this.btnUltimo = false;
        this.btnSiguiente = false;
        this.btnRegresar = false;
        this.indice = 0;
        this.tipodiarioSeleccionado = this.tipodiarioService.contmaeipodiario[this.indice];
        this.tipodiarioSeleccionadoAux = this.tipodiarioService.contmaeipodiario[this.indice];
        this.codigoAnt = this.tipodiarioSeleccionado.TIPDIA_CODIGO;
      }
      if (valor === 'Anterior') {
        if (this.btnUltimo === true && this.btnSiguiente === true) {
          this.btnUltimo = false;
          this.btnSiguiente = false;
        }
        this.btnRegresar = false;
        this.indice--;
        if (this.indice < 0) {
        } else {
          this.tipodiarioSeleccionado = this.tipodiarioService.contmaeipodiario[this.indice];
          this.tipodiarioSeleccionadoAux = this.tipodiarioService.contmaeipodiario[this.indice];
          this.codigoAnt = this.tipodiarioSeleccionado.TIPDIA_CODIGO;
        }
        
      }
      if (valor === 'Siguiente') {
        if (this.btnPrimero === true && this.btnAnterior === true) {
          this.btnPrimero = false;
          this.btnAnterior = false;
        }
        this.btnRegresar = false;
        this.indice++;
        if (this.indice > this.tipodiarioService.contmaeipodiario.length) {
        } else {
          this.tipodiarioSeleccionado = this.tipodiarioService.contmaeipodiario[this.indice];
          this.tipodiarioSeleccionadoAux = this.tipodiarioService.contmaeipodiario[this.indice];
          this.codigoAnt = this.tipodiarioSeleccionado.TIPDIA_CODIGO;
        }
        
      }
      if (valor === 'Ultimo') {
        this.btnUltimo = true;
        this.btnSiguiente = true;
        this.btnPrimero = false;
        this.btnAnterior = false;
        this.btnRegresar = false;
        this.indice = this.tipodiarioService.contmaeipodiario.length - 1;
        this.tipodiarioSeleccionado = this.tipodiarioService.contmaeipodiario[this.indice];
        this.tipodiarioSeleccionadoAux = this.tipodiarioService.contmaeipodiario[this.indice];
        this.codigoAnt = this.tipodiarioSeleccionado.TIPDIA_CODIGO;
      }
      if (valor === 'Buscar') {

        this.buscarTipoDiario();

      }
      if (valor === 'Nuevo') {
        if (this.permisoTipoDiario.PERINSERCION === 1) {
          this.btnNuevo = true;
          this.btnGuardar = false;
          this.btnBorrar = false;
          this.btnRegresar = false;
          this.btnPrimero = true;
          this.btnSiguiente = true;
          this.btnAnterior = true;
          this.btnUltimo = true;
          const codigo = this.tipodiarioService.contmaeipodiario[this.tipodiarioService.contmaeipodiario.length - 1].TIPDIA_CODIGO;
          this.tipodiarioSeleccionado = {
            TIPDIA_CODIGO: '',
            TIPDIA_NOMBRE: '',
            TIPDIA_NROACTUAL: '',
            TIPDIA_MANDATORIO: ''
            
          };
          this.codigoAnt = this.tipodiarioSeleccionado.TIPDIA_NOMBRE;
        } else if (this.permisoTipoDiario.PERINSERCION === 0) {
          this.message.add({
            key: 'tipodiario',
            severity: 'error',
            summary: 'Inserción de Tipo de Diario',
            detail: 'El usuario no tiene permisos de inserción, acción denegada'
          });
        }
      }
      if (valor === 'Cancelar') {
        this.btnNuevo = false;
        this.btnGuardar = true;
        this.btnBorrar = false;
        this.btnRegresar = true;
        this.btnPrimero = false;
        this.btnAnterior = false;
        this.btnSiguiente = false;
        this.btnUltimo = false;
        if (this.codigoAnt !== '') {
          this.tipodiarioSeleccionado = {
            TIPDIA_CODIGO: this.codigoAnt, COM_CODIGO: '01',
            TIPDIA_NOMBRE: this.tipodiarioSeleccionadoAux.TIPDIA_NOMBRE,
            TIPDIA_MANDATORIO: this.tipodiarioSeleccionadoAux.TIPDIA_MANDATORIO,
            TIPDIA_NROACTUAL: this.tipodiarioSeleccionadoAux.TIPDIA_NROACTUAL
          };
        } else if (this.codigoAnt === '') {
          const codigo = this.tipodiarioService.contmaeipodiario[this.tipodiarioService.contmaeipodiario.length - 1].TIPDIA_CODIGO;
          this.tipodiarioSeleccionado = {
            TIPDIA_CODIGO: '', COM_CODIGO: '01', TIPDIA_NOMBRE: '', TIPDIA_MANDATORIO: '',
            TIPDIA_NROACTUAL: ''
          };
        }
      }
      if (valor === 'Guardar') {
        if (this.permisoTipoDiario.PERACTUALIZACION === 1) {
          this.tipodiarioService.erFindCodTipDia(this.tipodiarioSeleccionado.TIPDIA_CODIGO).subscribe(existe => {
            if (existe !== null && existe !== undefined) {
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
              this.tipodiarioService.actualizarTipoDiario(this.tipodiarioSeleccionado, this.codigoAnt).subscribe((res) => {
                this.message.add({
                  key: 'tipodiario',
                  severity: 'success',
                  summary: 'Actualización de Tipo de Diario',
                  detail: 'Se ha actualizado Tipo de Diario'
                });
                this.auditoriagral.registrarAuditoria('CON_MAETIPDIA', String(this.tipodiarioSeleccionado.TIPDIA_CODIGO), 'A',
                  '', '01', '', '', '', '').subscribe(() => {
                });
              }, error1 => {
                let mensaje: string;
                mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                this.message.add({
                  key: 'tipodiario',
                  severity: 'error',
                  summary: 'Actualización de Tipo de Diario',
                  detail: mensaje
                });
                this.btnNuevo = false;
                this.btnGuardar = true;
                this.btnBorrar = false;
                this.btnRegresar = true;
              });
            } else if (existe === null) {
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
              this.tipodiarioService.guardarTipoDiario(this.tipodiarioSeleccionado).subscribe(() => {
                this.message.add({
                  key: 'tipodiario',
                  severity: 'success',
                  summary: 'Inserción de Tipo de Diario',
                  detail: 'Se ha insertado de Tipo de Diario'
                });
                this.tipodiarioService.getTipoDiario().toPromise().then((res) => {
                  this.tipodiarioService.contmaeipodiario = res;
                });
                this.opcionDropDown = '';
                
                this.auditoriagral.registrarAuditoria('CON_MAETIPDIA', String(this.tipodiarioSeleccionado.TIPDIA_CODIGO), 'I',
                  '', '01', '', '', '', '').toPromise().then(() => {
                });
              }, error1 => {
                
                let mensaje: string;
                mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                this.message.add({
                  key: 'tipodiario',
                  severity: 'error',
                  summary: 'Inserción de Tipo de Diario',
                  detail: mensaje
                });
                this.btnNuevo = false;
                this.btnGuardar = true;
                this.btnBorrar = false;
                this.btnRegresar = true;
              });
            }
          });
        } else if (this.permisoTipoDiario.PERACTUALIZACION === 0) {
          this.message.add({
            key: 'tipodiario',
            severity: 'error',
            summary: 'Actualización de Tipos de Diario ',
            detail: 'El usuario no tiene permisos de actualización, acción denegada'
          });
        }
      }
      if (valor === 'Borrar') {
        if (this.permisoTipoDiario.PERELIMACION === 1) {
          this.confirmartipoDiario();
        } else if (this.permisoTipoDiario.PERELIMACION === 0) {
          this.message.add({
            key: 'tipodiario',
            severity: 'error',
            summary: 'Eliminación de Tipo de Diario',
            detail: 'El usuario no tiene permisos de eliminación, acción denegada'
          });
        }
      }
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  //v2 table
  manejarSenalest(valor){
    switch (valor) {
      case 'Nuevo':
        this.opcionNuevo();
        break;
      case 'Guardar':
        this.opcionGuardar();
        break;
      case 'Borrar':
        this.opcionEliminar();
        break;
      case 'Cancelar':
        this.cargar();
        break;
      case 'Buscar':
        this.buscarRegistro();
        break;
      case 'Salir':
       this.init.tabs.splice(this.init.tabindex, 1);
        break;
    
    }
  }
  opcionNuevo(){
    this.btnNuevo = true;
    this.btnBorrar = false;
    this.btnRegresar = false;
    this.aggrid.isEditable(true);
    this.nuevoModulo();
  }
  nuevoModulo() {
    const newModulo:  Conmaetipodiario= {  
      COM_CODIGO:'',
      TIPDIA_CODIGO:'',
      TIPDIA_NOMBRE:'',
      TIPDIA_NROACTUAL:'',
      NUMERO:this.conmaetipodiariov.length +1,
      TIPDIA_MANDATORIO:null,
      EDITABLE:false,
      NUEVO:true 
  };
  
  this.conmaetipodiariov.push(newModulo); 
  this.aggrid.refreshaggrid(this.conmaetipodiariov, this.defaultColDefconmaetipodiario.objeto ); 
  const indice= this.conmaetipodiariov.indexOf(newModulo);
  this.aggrid.gridApi.setFocusedCell(indice , 'TIPDIA_CODIGO');
  
  }
  seleccionItem(valor){
    console.log(valor);
  }
  opcionGuardar(){
    let paquete = '';
    const modulo: Conmaetipodiario = {
      TIPDIA_CODIGO:this.selectedconmaetipodiariov.TIPDIA_CODIGO,
      TIPDIA_NOMBRE:this.selectedconmaetipodiariov.TIPDIA_NOMBRE,
      TIPDIA_NROACTUAL:this.selectedconmaetipodiariov.TIPDIA_NROACTUAL,
      //EDITABLE:this.selectedconmaetipodiariov.EDITABLE
     };
     let valido = true;
    
    for ( const key in modulo ) { 
      if ( modulo[key] === null || modulo[key] === '') {
        valido = false;
      }
    }
    if ( valido ) {
        if(this.selectedconmaetipodiariov.NUEVO==true){
          this.aggrid.gridApi.stopEditing();
          this.tipodiarioService.guardarTipoDiario(this.selectedconmaetipodiariov).toPromise().then(res=>{
            this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
            this.cargar();
            this.auditoriagral.registrarAuditoria('CON_MAETIPDIA', String(this.selectedconmaetipodiariov.TIPDIA_CODIGO), 'I',
            '', '01', '', '', '', '').toPromise().then(() => {
          });
          }).catch(e=>{
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
            this.mensajeAlerta('error', 'Error', mensaje);
          }); 
          
        
              
          
        }else if(this.selectedconmaetipodiariov.NUEVO==false){
          this.aggrid.gridApi.stopEditing();
          this.tipodiarioService.actualizarTipoDiario(this.selectedconmaetipodiariov, 
            this.selectedconmaetipodiariov.TIPDIA_CODIGO).toPromise().then(res=>{
              this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
              this.cargar();
              this.auditoriagral.registrarAuditoria('CON_MAETIPDIA', String(this.selectedconmaetipodiariov.TIPDIA_CODIGO), 'A',
              '', '01', '', '', '', '').toPromise().then(() => {
            });
            }).catch(e=>{
              let mensaje: string;
              mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
              this.mensajeAlerta('error', 'Error', mensaje);
            }); 
        }
    }else{
      this.mensajeAlerta('info', 'Información', 'Se han encontrado campos vacíos');
    }
  }
  opcionEliminar(){
    if ( this.selectedconmaetipodiariov !== undefined ) {
      this.confirmationService.confirm({
        message: '¿Está seguro que desea eliminar este registro?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'segOrgDialog',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {
          //this.eliminarTipoDiario()
          this.tipodiarioService.eliminarTipoDiario( this.selectedconmaetipodiariov).toPromise().then(res=>{
            this.mensajeAlerta('success', 'Información', '¡Modulo eliminado!');
            this.cargar();
          }).catch(e=>{
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
            this.mensajeAlerta('error', 'Error', mensaje);
          }); 
            
        
        },
        reject: () => {
        }
      })
    }
  }
  cargar(){
    this.btnNuevo =  false;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.btnRegresar = false;
    this.tipodiarioService.getTipoDiario().toPromise().then((res) => {
      this.tipodiarioService.contmaeipodiario = res;
      this.conmaetipodiariov=res
      this.conmaetipodiariov.map(x=>{
        x.NUMERO = this.conmaetipodiariov.indexOf( x ) + 1;
        x.EDITABLE= (x.TIPDIA_MANDATORIO==1)?true:false;
        x.NUEVO=false;
       
      })
    });
    
  }
  buscarRegistro(){

  }
  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'contipodiario',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
    
  }
 
  confirmartipoDiario() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar Tipo de Diario',
      header: 'Eliminar Tipo de Diario',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmartipodiario',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        
        this.eliminarTipoDiario();
      },
      reject: () => {
      }
    });
  }
  
  eliminarTipoDiario() {
    if (this.tipodiarioSeleccionado.TIPDIA_CODIGO === '') {
      this.message.add({
        key: 'tipodiario',
        severity: 'error',
        summary: 'Eliminación de Tipo de Diario',
        detail: 'No se ha seleccionado un Tipo de Diario a Eliminar'
      });
    } else if (this.tipodiarioSeleccionado.TIPDIA_CODIGO !== '') {
      this.tipodiarioService.eliminarTipoDiario(this.tipodiarioSeleccionado).subscribe(() => {
        this.message.add({
          key: 'tipodiario',
          severity: 'success',
          summary: 'Eliminación de Tipo de Diario',
          detail: 'Se ha eliminado el Tipo de Diario'
        });
        this.tipodiarioService.getTipoDiario().subscribe((res) => {
          this.tipodiarioService.contmaeipodiario = res;
        });
        this.opcionDropDown = '';
        
        this.auditoriagral.registrarAuditoria('CON_MAETIPDIA', String(this.tipodiarioSeleccionado.TIPDIA_CODIGO), 'E',
          '', '01', '', '', '', '').subscribe(() => {
        });
        this.btnNuevo = false;
        this.btnGuardar = true;
        this.btnBorrar = false;
        this.btnRegresar = true;
        this.tipodiarioSeleccionado = {
          TIPDIA_CODIGO: '', COM_CODIGO: '01', TIPDIA_NOMBRE: '', TIPDIA_MANDATORIO: '',
          TIPDIA_NROACTUAL: ''
        };
      }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.message.add({
          key: 'confirmartipodiario',
          severity: 'error',
          summary: 'Eliminación de Tipo de Diario',
          detail: mensaje
        });
        this.btnNuevo = false;
        this.btnGuardar = true;
        this.btnBorrar = false;
        this.btnRegresar = true;
      });
    }
  }
  
  completarzeros(numactual) {
    if (numactual !== undefined) {
      if (numactual.length >= 10) {
        this.auxZeros = numactual;
        return;
      }
      let aux = numactual;
      for (let i = 0; i < 10 - numactual.length; i++) {
        aux = '0' + aux;
      }
      
      if (Number(aux) !== 0) {
        this.auxZeros = aux;
      } else {
        this.auxZeros = '0000000001';
      }
      
    }
  }
  
  
  buscarTipoDiario() {
    
    this.opcion = 'Buscar';
    this.types = [
      {label: 'CÓDIGO', value: 'TIPDIA_CODIGO'},
      {label: 'NOMBRE', value: 'TIPDIA_NOMBRE'},
      {label: 'NRO ACTUAL', value: 'TIPDIA_NROACTUAL'},
    ];
    this.busquedacampos = ['', '', ''];
    this.tabla = 'CON_MAETIPDIA';
    this.where = '';
    this.consulta = 'TIPDIA_CODIGO, TIPDIA_NOMBRE, TIPDIA_NROACTUAL';
    // this.tipodiarioService.getTipoDiario().subscribe((res) => {
    //   this.arregloBus = res;
    //   this.displayDialogBus2 = true;
    // });
    this.busquedaSer.busquedaConMaeTipdia1().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.displayDialogBusquedaFast = true;
    });
  }
  
  ngOnDestroy(): void {
  }
  
  manejarSeleccion(valor) {
    if (this.opcion === 'Buscar') {
      this.tipodiarioService.contmaeipodiario.map((trans) => {
        if (trans.TIPDIA_CODIGO === valor.TIPDIA_CODIGO) {
          this.indice = this.tipodiarioService.contmaeipodiario.indexOf(trans);
        }
      });
      this.tipodiarioSeleccionado = this.tipodiarioService.contmaeipodiario[this.indice];
      this.codigoAnt = this.tipodiarioSeleccionado.TIPDIA_CODIGO;
      this.displayDialogBus2 = false;
    }
    this.displayDialogBus = false;
    this.displayDialogBusquedaFast = false;
  }
  
  cambio() {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnRegresar = false;
  }
  
  setFocus(event) {
    let element: any;
    switch (event.target.id) {
      case 'TIPDIA_CODIGO': {
        element = document.getElementById('TIPDIA_NOMBRE');
        break;
      }
      case 'TIPDIA_NOMBRE': {
        element = document.getElementById('TIPDIA_NROACTUAL');
        break;
      }
      case 'TIPDIA_NROACTUAL': {
        this.completarzeros(this.tipodiarioSeleccionado.TIPDIA_NROACTUAL);
        this.tipodiarioSeleccionado.TIPDIA_NROACTUAL = this.auxZeros;
        element = document.getElementById('TIPDIA_CODIGO');
        break;
      }
      default: {
        break;
      }
    }
    if (element === null) {
      return;
    } else {
      element.focus();
    }
  }
  
}
