export class NommaeEmpleado {
    BAN_CODIGOEMP:any;
    CLI_CODIGO:any;
    COM_CODIGO:any;
    CON_CODIALIM:any;
    CON_CODIANTI:any;
    CON_CODIBONIRESP:any;
    CON_CODIETI1ING:any;
    CON_CODIETI2ING:any;
    CON_CODIGOPRES:any;
    CON_CODIGOSXP:any;
    CON_CODIMOVI:any;
    CON_CODIPRESHIPO:any;
    CON_CODIPRESIESSQ:any;
    CON_CODIREMUSALAUNIF:any;
    CON_CODISEGUPRIV:any;
    CON_CODITELE:any;
    DEP_CODIGO:any;
    EMP_ACTIVO:any;
    EMP_ALIMENTACION:any;
    EMP_ALIMTRIBIESS:any;
    EMP_ANTICIPO:any;
    EMP_APELLIDO:any;
    EMP_APLICONVEVITDOBLTRIB:any;
    EMP_BONRESP:any;
    EMP_CALC75:any;
    EMP_CALCDECCUARTO:any;
    EMP_CALCDECTERCERO:any;
    EMP_CALCVACACIONES:any;
    EMP_CALLE:any;
    EMP_CARGIESS:any;
    EMP_CARGO:any;
    EMP_CARNCONA:any;
    EMP_CASA:any;
    EMP_CAUSSALI:any;
    EMP_CEDUMILI:any;
    EMP_CELULAR:any;
    EMP_CENTCOST:any;
    EMP_CODIBANCEMPL:any;
    EMP_CODIGO:any;
    EMP_CODIGO2:any;
    EMP_COMPSALA:any;
    EMP_CONCODIGO:any;
    EMP_CONDTRABRESPDISC:any;
    EMP_CORREO:any;
    EMP_CTAAHORROS:any;
    EMP_CTACALC75:any;
    EMP_CTACOMPSALA:any;
    EMP_DECICUARCALC:any;
    EMP_DECICUARPAGA:any;
    EMP_DECICUARRETI:any;
    EMP_DECICUARTREGI:any;
    EMP_DECITERCCALC:any;
    EMP_DECITERCPAGA:any;
    EMP_DECITERCRETI:any;
    EMP_DETADISC:any;
    EMP_DETASALI:any;
    EMP_DIRECCION:any;
    EMP_DISCSUST:any;
    EMP_EMPRASUMIMPURENT:any;
    EMP_ESTATRAB:any;
    EMP_ETI1ING:any;
    EMP_ETI1INGTRIBIESS:any;
    EMP_ETI2ING:any;
    EMP_ETI2INGTRIBIESS:any;
    EMP_FECHACONTRATO:any;
    EMP_FECHNACI:any;
    EMP_FECINGRESO:any;
    EMP_FECSALIDA:any;
    EMP_FNDRSVPAGAR:any;
    EMP_FNDRSVRRETENER:any;
    EMP_FNDSRSRVA:any;
    EMP_FORMPAGO:any;
    EMP_HORATIEMPARC:any;
    EMP_MOVILIZACION:any;
    EMP_NOMBRDISCSUST:any;
    EMP_NOMBRE:any;
    EMP_NUMCARGAS:any;
    EMP_PAISRESI:any;
    EMP_PLAZOCONTRATO:any;
    EMP_PORCAPORINDI:any;
    EMP_PORCAPORPATR:any;
    EMP_PORCDISC:any;
    EMP_PORCFONDRESER:any;
    EMP_PRESIESS:any;
    EMP_PRESTAMO:any;
    EMP_PRESTHIPOTECARIO:any;
    EMP_REMSALUNI:any;
    EMP_RESITRAB:any;
    EMP_ROLADMN:any;
    EMP_ROLVEND:any;
    EMP_RUCIDE:any;
    EMP_RUCIDEDISCSUST:any;
    EMP_SEGUROFP:any; 
    EMP_SEXO:any; 
    EMP_TELEFONO:any;
    EMP_TELEFONO1:any;
    EMP_TELEFONO2:any;
    EMP_TIPCTABANC:any;
    EMP_TIPOEMPL:any;
    EMP_TIPOIDE:any;
    EMP_TIPOSISTSALANETO:any;
    EMP_TIPROL_CODIGO_03:any;
    EMP_TIPROL_CODIGO_04:any;
    EMP_TRABJORNPARC:any;
    EMP_VACACALC:any;
    EMP_VACAPAGA:any;
    EMP_VACARETI:any;
    PROV_CODIGO:any;
    PROV_CODIGOC:any;
    TIPROL_CODIGO:any;
    VEN_CODIGO:any;
    EMP_ESTACIVIL:any;
    EMP_CORREOEMPRE:any;
    EMP_CORREOPERS:any;
    EMP_NACIONALIDAD:any;
    EMP_LICENCADUCA :any;
    EMP_LICENCIA:any;
    EMP_CARNET:any;
    EMP_LUGARNACI:any;
    EMP_JEFEINMD:any;
    EMP_JEFEEXT:any;
    NUEVO?:boolean;
    EMP_ENFCATASTROFICA?: any;
}