<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="maeequipo"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmmaeequipo" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 99%">
  <div class="p-col-12">
    <app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
      [botonRegresar]="btnRegresar" [barraBotonesCRUD]="false" [barraBotonesDesplaz]="true" [barraBotones3]="true"
      [barraBotonesAsiCon]="true" [barraBotones5]="true" (signal)="manejarSenales($event)"></app-invmaebarra>
  </div>
  <div style="overflow: hidden" class="p-col-12">
    <p-tabView [(activeIndex)]="index" (onChange)="changeTabs()">
      <p-tabPanel header="Equipo">
        <app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
          [rowData]="tecmaeequipo" [frameworkComponents]="frameworkComponents" [animateRows]="true"
          [rowSelection]="rowSelection" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowStyle]="rowStyle"
          [singleClickEdit]="false" [botones]="false" (selected)="seleccion($event)" (cambios)="botonesModificar()">
        </app-aggridsaci>
      </p-tabPanel>
      <p-tabPanel header="Detalle Equipo" [disabled]="btnNuevo && index===0">
        <div class="divgrups">
          <div class="p-grid">
            <div class="p-col-2">
            </div>
            <div class="p-col-2">
              <span>{{equipoSeleccionado.EQUI_CODIGO}}</span>
            </div>
            <div class="p-col-1">
            </div>
            <div class="p-col-3">
              <span>{{equipoSeleccionado.EQUI_NOMBRE}}</span>
            </div>
          </div>
        </div>
        <app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
          [rowData]="equipoService.tecmaeequipodet[indicador]" [frameworkComponents]="frameworkComponents"
          [animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefsDet"
          [defaultColDef]="defaultColDefDet" [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true"
          (selected)="seleccionDet($event)">
        </app-aggridsaci>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>