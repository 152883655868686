<div class="p-grid" style="width: 99%;">
	<div class="p-col-12">
        <app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="true"
            [botonBorrar]="btnBorrar"
            (signal)="manejarSenales($event)" [barraBotonesDesplaz] = "false"
            [botonBuscar]="btnBuscar"  [botonSiguiente]="botonSiguiente"  [botonAnterior]="botonAnterior"
            [botonPrimero]="botonPrimero" [botonUltimo]="botonUltimo"
            [barraBotones3]="true" [botonBinocular]="true"  [botonCabInpRent]="true"
            [barraBotones5]="false" 
            [botonGenexml]="btnGenexml"
            [botonRecImpRen]="btnRecImpRen"
            [botonCamLiq]="btnCamLiq"
            [botonRevEstLiq]="btnRevEstLiq"
            [botonAlmacenardoc] = "true"
            >
        </app-invmaebarra>
	</div>
</div>
<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="inpurent"></p-toast>
	
<p-confirmDialog [transitionOptions]="'0ms'" key="NomImpRenta" title="Confirmar Acción"
                 appendTo="body"></p-confirmDialog>
<p-confirmDialog [transitionOptions]="'0ms'" key="NomdetImpRenta" title="Confirmar Acción"
                 appendTo="body"></p-confirmDialog>
<div style="overflow: hidden" id="divPrincipal" class="p-col-12">
    <div class="divgrups">
        <div class="p-grid">
            <div class="p-col-1">
                <span style="text-align: center; color: rgb(169,50,38); font-weight: bold;font-size: 15px;">{{cabImpuRent.CABIMPURENT_CODIGO}}</span>
            </div>
            <div class="p-col-5">
                
            </div>
            <div class="p-col-2">
                <span style="text-align:left; color: rgb(0,0,255); font-weight: bold;font-size: 15px;">{{cabImpuRent.CABIMPURENT_ESTADO}}</span>
            
            </div>
            <div class="p-col-4">
                <table style="width: 100%;text-align: left;">
                    <tr style="background-color: rgba(66, 214, 240, 0.829);">
                        <td style="border: 1px solid; text-align: center;">{{identiUsuario}}</td>
                        <td style="border: 1px solid; text-align: center;">{{fecha | date: 'dd/MM/yyyy' }}</td>
                        <td style="border: 1px solid; text-align: center;">{{fecha | date: 'hh:mm:ss' }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <p-tabView (onChange)="onTab1Change($event)" [(activeIndex)]="indexTab">
        <p-tabPanel header="Impuesto a la Renta" >
           
            <div class="divgrups">
                <div class="p-grid">
                    <div class="p-col-12">
                        <div class="p-grid">
                            <div class="p-col-1">
                                
                            </div>
                            <div class="p-col-1">
                                <span>Año</span>
                            </div>
                            <div class="p-col-1">
                                <input type="text"  style="width: 100%;" id="cabanio" (keydown)="cambioImpuRent()" [(ngModel)]="cabImpuRent.CABIMPURENT_ANIO"  autocomplete="off" pInputText>
                            </div>
                            <div class="p-col-1">
                                <span>Descripción</span>
                            </div>
                            <div class="p-col-4">
                                <input type="text" style="width: 100%;" id="cabdescrip" (keydown)="cambioImpuRent()" [(ngModel)]="cabImpuRent.CABIMPURENT_DESCRIPCION" autocomplete="off" pInputText>
                            </div>
                            
                        </div>                        
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col-12">
                        <div class="p-grid">
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="cargoDatos==false">
                <app-aggridsaci *ngIf="cargoDatos==false" #impuRent [width]=""
                                [height]="largo"
                                [enableSorting]="false"
                                [enableFilter]="true"
                                [rowData]="impurentService.detImpuRent"
                                [frameworkComponents]="frameworkComponents"
                                [animateRows]="true"
                                [rowSelection]="rowSelection"
                                [columnDefs]="columnnomDetImpuRent"
                                [defaultColDef]="defaultColDefDetImpuRent"
                                [rowStyle]="rowStyle"
                                [singleClickEdit]="false"
                                [botones]="activarboto"
                                [mostrarGuardar]="false"
                                (selected)="selecImpuRent($event)"
                                (cambios)="cambioImpuRent()"
                ></app-aggridsaci> 
            </div>    
            <div  *ngIf="cargoDatos==true">
                <div style="display: flex;align-items: center;justify-content: center">
                    <p-progressSpinner *ngIf="cargoDatos==true" [style]="{width: '300px', height: '300px'}"
                        animationDuration=".5s"></p-progressSpinner>
                    </div>
            </div>
        </p-tabPanel>
    </p-tabView>
</div>
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
					 [consulta]="consulta" [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccionbus($event)"></app-busquedadlg>
</p-dialog>
<p-dialog header="Clave Autorización" [(visible)]="displayDialogClave"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
          [style]="{width: '70vw', height: '35vh'} ">
	<app-segclaveautoriza  [codigo]="cod" (salirclave)="salirc($event)" (autorizado)="autoriza($event)"></app-segclaveautoriza>
</p-dialog>

<p-dialog header="Exportar datos archivos planos" [(visible)]="displayDialogenexml"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '35vh'} ">
    <p-toast [style]="{marginTop: '41vh'}" position="top-center" key="autorizacion"></p-toast>
    <div style="height: 100%;">
        <div class="p-grid" style="width: 98%">
                <div class="p-col-12">
                    <app-invmaebarra [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"
                                    [barraBotones5]="true" [botonVisto]="false" [barraBotones3Alt]="false" [barraBotonesCRUD]="true"
                                    (signal)="manejarSenalesgexml($event)"
                                    style="text-align: left;">
                    </app-invmaebarra>
                </div>
            </div>
            <div class="divgrups">
                <div class="p-grid">
                    <div class="p-col-12">
                        <div class="p-grid">
                            <div class="p-col-1"></div>
                            <div class="p-col-1">
                                <label for="clave" style="font-weight: bold">Archivo</label>
                            </div>
                            
                            <div class="p-col-1"></div>
                            <div class="p-col-6">
                                <input type="text" pInputText id="clave" [(ngModel)]="namexml"
                                    (keydown.enter)="opcionvisxml()" >
                            </div>
                            <div class="p-col-1"></div>
                        </div>
                    </div>
                </div>
            </div>
    </div>       
</p-dialog>

<p-dialog header="Reportes" [(visible)]="displayDialogReportes" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '40vw', height: '400px'}">
    <app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
        [strNumDoc]="cabImpuRent.CABIMPURENT_CODIGO" [strCampo]="'CABIMPURENT_CODIGO'"
        (cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'NOMCABIMPURENT'" [txtNumComprobante] = "cabImpuRent.CABIMPURENT_CODIGO"
	(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>