import { Component, OnInit, ViewChild } from '@angular/core';
import { ActencsalidaService } from '../actservicios/actencsalida.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { PermisosService } from 'src/app/advantage/advantageservices/permisos.service';
import { MessageService, ConfirmationService, SelectItem } from 'primeng';
import { Usuario } from 'src/app/usuario';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { DatePipe } from '@angular/common';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { SegMaePermiso } from 'src/app/advantage/advantageinterfaces/segmaepermiso';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { ActDetSalida } from '../actinterfaces/actdetsalida';
import { ActDetVarSalida } from '../actinterfaces/actdetvarsalida';

@Component({
  selector: 'app-actencsalida',
  templateUrl: './actencsalida.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class ActencsalidaComponent implements OnInit {

  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  btnNuevo = false;
  btnGuardar = true;
  btnBorrar = false;
  btnRegresar = false;
  botonPrimero = false;
  botonAnterior = false;
  botonSiguiente = false;
  botonUltimo = false;
  btnBuscar = false;
  btnVisto = false;
  btnRevActFijo = false

  btnNuevoDetSal = true;
  btnBorrarDetSal = true;
  btnCancelarDetSal = true;

  btnNuevoDetVarSal = true;
  btnBorrarDetVarSal = true;
  btnCancelarDetVarSal = true;

  lblAsiNro = '';
  txtEsta = '';
  lblEstado = '';
  lblConf = '';
  txtNume = '';
  txtRefe = '';
  txtComp = '';
  dateFecha: any ='';
  cmbTipUsu:any[] = [];
  strTipoUsu: any;
  txtCodCli1 = '';
  lblNomCli1 = '';
  lblNomCli2 = '';
  txtCodCli2 = '';
  txtCiuCod = '';
  lblCiuNomb = '';
  txtCodOfi = '';
  lblNomOfi = '';
  txtCodArea = '';
  lblNomArea = '';
  txtCodDep = '';
  lblNomDep = '';
  cmbMoti:any[] = [];
  strMotivo: any;
  txtAuto = '';
  txtObse1 = '';
  txtObse2 = '';
  txtActPie = '';
  txtDepAcumKard = '';

  largo: string;
  public frameworkComponents;
  rowSelection = 'multiple';
  rowStyle;
  defaultActDetSalida;
  defaultActDetVarSal;

  // Busqueda
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  where: string;
  opcion: string;

  indicador: any;

  // Permisos
  permisos: SegMaePermiso;

  detalleDetSalidaSel: ActDetSalida = {};
  detalleDetVarSalSel: ActDetVarSalida = {}

  strTipoGuardar = '';

  displayAsiContable: boolean;
  claveDialog: boolean;
  claveAuth: string;
  accionClave: string;
  displayDialogDocumentos = false;
  spin: boolean;//CDPJ

  columnDefsActDetSalida = [
    {
      headerName: 'Código', field: 'ACT_CODIGO', cellEditor: 'cellPrueba', width: 120, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },onCellDoubleClicked: (params) => {
        this.doubleClick(params.data);
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 30,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Nombre', field: 'DETSALIDA_DESCRIPCION', cellEditor: 'cellPrueba', width: 250, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },onCellDoubleClicked: (params) => {
        this.doubleClick(params.data);
      },
      cellEditorParams:
      {
        values: {
          numeros: true,
          mayusculas: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: true

        }
      }
    },
    {
      headerName: 'Referencia', field: 'DETSALIDA_REFERENCIA', cellEditor: 'cellPrueba', width: 125, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },onCellDoubleClicked: (params) => {
        this.doubleClick(params.data);
      },
      cellEditorParams:
      {
        values: {
          numeros: true,
          mayusculas: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 15,
          tienecalculos: true

        }
      }
    },
    {
      headerName: 'Comentario', field: 'DETSALIDA_COMENTARIO', cellEditor: 'cellPrueba',  width: 200, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },onCellDoubleClicked: (params) => {
        this.doubleClick(params.data);
      },
      cellEditorParams:
      {
        values: {
          numeros: true,
          mayusculas: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: true

        }
      }
    },
    {
      headerName: 'Importe', field: 'DETSALIDA_IMPORTE', cellEditor: 'cellPrueba', width: 120, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },onCellDoubleClicked: (params) => {
        this.doubleClick(params.data);
      },
      cellEditorParams:
      {
        values: {
          numeros: true,
          mayusculas: false,
          alphabetic: false,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 32,
          int: true,
          tienecalculos: true,
        }
      }
    }
  ];

  columnDefsActVariosSalida = [
    {
      headerName: 'Código', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 120, cellStyle: { textAlign: 'left' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 30,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Nombre', field: 'DETVARIOSSAL_DESCRIPCION', cellEditor: 'cellPrueba', width: 250, cellStyle: { textAlign: 'left' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          numeros: true,
          mayusculas: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: true

        }
      }
    },
    {
      headerName: 'Importe', field: 'DETVARIOSSAL_IMPORTE', cellEditor: 'cellPrueba', width: 120, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          numeros: true,
          mayusculas: false,
          alphabetic: false,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 32,
          int: true,
          tienecalculos: true,
        }
      }
    }
    ,
    {
      headerName: 'Cntro.C', field: 'CEN_CODIGO', cellEditor: 'cellPrueba', width: 120, cellStyle: { textAlign: 'left' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 30,
          opcionbuscar: true
        }
      }
    }
  ];



  constructor(public actencsalidaService: ActencsalidaService,private auditoriaGralService: AuditoriagralService,
    private permisosService: PermisosService, private message: MessageService, public usuario: Usuario,
    private errorService: ErroresBaseDatosService, private busquedaSer: InvbusquedaService,
    private datePipe: DatePipe, private confirmationService: ConfirmationService, 
    private confIniciales: ConfInicialesService, private init: NuevoComponentService,
    private utilitariosService: UtilitariosService) { 
      this.actencsalidaService.detSalidaArray[this.indicador] = [];
      this.actencsalidaService.detVariosSalidaArray[this.indicador] = [];
      this.agTable();
    }

  agTable() {
      this.frameworkComponents = {
        cellPrueba: CellEditorPrueba
      };
      this.rowStyle = (params) => {
        if (params.node.rowIndex % 2 !== 0) {
          return { background: '#D6EAF8' };
        }
      };
      this.defaultActDetSalida = {
        editable: true,
        width: 100,
        objeto: 'actdetsalida',
        resizable: true
      };

      this.defaultActDetVarSal = {
        editable: true,
        width: 100,
        objeto: 'actdetvarsal',
        resizable: true
      };
  }

  async ngOnInit() {

    this.largo = '600';
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.dateFecha = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    this.actencsalidaService.detSalidaArray[this.indicador] = [];
    this.actencsalidaService.detVariosSalidaArray[this.indicador] = [];
    this.spin=false;//cdpj
    this.cmbTipUsu = [];
    this.cmbTipUsu.push({name: ''}, {name: 'Interno'}, {name: 'Externo'});
    this.strTipoUsu = this.cmbTipUsu[0];

    this.cmbMoti = [];
    

    
    await this.getPermisos();
    await this.mostrarMotivo();
  }
  

  async manejarSenales(valor) { 
    if (valor === 'Primero') {
      this.seleccionarRegistro('P');
    }
    if (valor === 'Siguiente') {
      this.seleccionarRegistro('S');
    }
    if (valor === 'Anterior') {
      this.seleccionarRegistro('A');
    }
    if (valor === 'Ultimo') {
      this.seleccionarRegistro('U');
    }
    if (valor === 'Nuevo') {
      this.nuevoRegistro();
    }
    if (valor === 'Borrar') {
      this.eliminarRegistro();
    }
    if (valor === 'Guardar') {
      this.guardarRegistro();
    }
    if (valor === 'Cancelar') {
      this.cancelarRegistro();
    }
    if (valor === 'Buscar') {
      this.buscarRegistro();
    }
    if (valor === 'Visto') {
      this.confirmaSalida();
    }
    if (valor === 'Reversar') {
      this.reversarSalida()
    }
    if (valor === 'Imprimir') {
      
    }
    if (valor === 'Almacenardoc') {
      if (this.txtNume !== '') {
        const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.mensajeAlerta('info', 'Información','No tiene los permisos necesarios. Consulte con el Administrador del Sistema');
        }
        
      }
    }

    if (valor === 'Asiento') {
      this.generarAsiContable();
    }
    if (valor === 'AsientoVer') {
      if (this.lblAsiNro !== ''){
        this.displayAsiContable = true;
      } else {
        this.mensajeAlerta('info', 'Información', 'No ha generado el Asiento Contable');
      }
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }

  }

  reversarSalida() {
    this.claveDialog = true;
    this.accionClave = 'reversar';
    this.claveAuth = '';
  }

  confirmaSalida() {
    if (this.obtieneTotalImpoActi()!= this.obtieneTotalImpoVari()) {
      this.mensajeAlerta('info', 'Información', 'Para confirmar una salida los DEBITOS deben ser igual a los CREDITOS');
			return;
    }

    this.claveDialog = true;
    this.accionClave = 'confirmar';
    this.claveAuth = '';
  }

  async comprobarClave() {
    try {
      const clave = await this.actencsalidaService.claveAutorizacion();
      let claveReal;
      if (Object.keys(clave).length > 0) {
        claveReal = clave[0].AUTORIZACION_CLAVE;
        if (claveReal === this.claveAuth) {
          this.claveDialog = false;
          this.claveAuth = '';
          if (this.accionClave === 'confirmar') {
            await this.confirmar();
          }
          if (this.accionClave === 'reversar') {
            await this.reversar();
          }

        } else if (claveReal !== this.claveAuth) {
          this.mensajeAlertaClave('error', 'Error', 'La clave está incorrecta');
        }
      } else {
        this.mensajeAlertaClave('error', 'Error', 'La clave está incorrecta');
      }
    } catch (err) {
      this.mensajeAlertaClave('error', 'Error', 'Problema al obtener la clave autorización ' + err.error);
    }
  }

  async reversar() {
    if (await this.actualizarEstadoDocumento('P', this.txtNume) === true) {
      this.auditoriaGralService.registrarAuditoria('ACT_ENCSALIDA', this.txtNume + '/' + 'REVERSA' , 'A',
       this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
    }

    //'--eliminar las transcciones de kardex relacionados con salidas

			const Parametros: any[] =[];
			const ParamTrf: any[] = [];
			ParamTrf[0] = Number(this.txtNume);	
			ParamTrf[1] = '01';			
			
			try {
        await this.actencsalidaService.eliminarTrnKardexRevSalida(ParamTrf);
			} catch (err) {
        this.mensajeAlerta('error', 'Error', '! Proceso guardar act_pck_encsalida.act_sp_actualizaTrnTransf no terminó satisfactoriamente.... ! ' + err.error);
				return;
      }
      
      Parametros[0] = Number(this.txtNume);
		
			try {
        await this.actencsalidaService.reversarActivoDadoBaja(Parametros);
			} catch (err) {
        this.mensajeAlerta('error', 'Error', '! Proceso guardar cabecera de la factura  no terminó satisfactoriamente.... ! ' + err.error);
				return;
      }

      try {
        await this.actencsalidaService.eliminarNovedadSalida(Parametros);
			} catch (err) {
        this.mensajeAlerta('error', 'Error', '! Proceso act_pck_encsalida.act_sp_daBajaActivo  no terminó satisfactoriamente.... ! ' + err.error);
				return;
      }
      
      await this.mostrarDatos(this.txtNume);
  }

  async confirmar() {
    if (await this.actualizarEstadoDocumento('CNFRM', this.txtNume) === true) {
      this.auditoriaGralService.registrarAuditoria('ACT_ENCSALIDA', this.txtNume + '/' + 'CONFIRMA' , 'A',
       this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
    }

    const Parametros: any[] = [];
    Parametros[0] = Number(this.txtNume);

    try {
      await this.actencsalidaService.confirmarSalida(Parametros);
    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso guardar cabecera de la factura  no terminó satisfactoriamente.... ! ' + err.error);
      return;
    }

    try {
      await this.actencsalidaService.daBajaActivos(Parametros); 
    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso act_pck_encsalida.act_sp_daBajaActivo  no terminó satisfactoriamente.... ! ' + err.error);
      return;
    }

    const ParamTrf: any[] = [];

    ParamTrf[0] = Number(this.txtNume);		
    ParamTrf[1] = '01';				
    
    try {
      await this.actencsalidaService.actualizarTrnTranf(ParamTrf);
    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso guardar act_pck_encsalida.act_sp_actualizaTrnTransf no terminó satisfactoriamente.... ! ' + err.error);
      return;
    }

    const ParamKar: any[] = [];

    ParamKar[0] = Number(this.txtNume);
    ParamKar[1] = '01';			
    ParamKar[2] = this.usuario.identificacion;			
    
    try {  
      await this.actencsalidaService.generarKardex(ParamKar);
    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso guardar act_pck_encsalida.act_sp_generaKardex no terminó satisfactoriamente.... ! ' + err.error);
      return;
    }

    await this.mostrarDatos(this.txtNume);
  }

  async actualizarEstadoDocumento(strEstado, strCodigo) {
    let resultado = false;
    try {
      await this.actencsalidaService.actualizarEstadoDoc(strEstado, strCodigo);
      resultado = true;
    } catch (err) {
      this.mensajeAlerta('err', 'Error', 'Error al actualizar el estado del documento ' + err.error);
      resultado = false;
    }
    return resultado;
  }

  obtieneTotalImpoVari() {
    let dblRetorno = 0;
		let intIndiceLista = 0;
		let item: ActDetVarSalida = {};
		for (intIndiceLista=0;intIndiceLista<this.actencsalidaService.detVariosSalidaArray[this.indicador].length;intIndiceLista++) {
			item = this.actencsalidaService.detVariosSalidaArray[this.indicador][intIndiceLista];
			if (Number(item.DETVARIOSSAL_IMPORTE) > 0){
				dblRetorno = dblRetorno + Number(item.DETVARIOSSAL_IMPORTE);
			}
		}		
		console.log('dblRetornoimpovari',Number(Number(dblRetorno).toFixed(2)))
		return Number(Number(dblRetorno).toFixed(2));
  }

  obtieneTotalImpoActi(){
    let dblRetorno = 0;
    let intIndiceLista = 0;
    let item: ActDetSalida = {};
    for (intIndiceLista=0;intIndiceLista<this.actencsalidaService.detSalidaArray[this.indicador].length;intIndiceLista++) {
      item = this.actencsalidaService.detSalidaArray[this.indicador][intIndiceLista];
      if (Number(item.DETSALIDA_IMPORTE) > 0){
        dblRetorno = dblRetorno + Number(item.DETSALIDA_IMPORTE);
      }
    }		
    console.log('dblRetornoimpoacti',Number(Number(dblRetorno).toFixed(2)))
    return Number(Number(dblRetorno).toFixed(2));
  }

  async generarAsiContable() {
    if (this.txtEsta !== 'CNFRM') {
      this.mensajeAlerta('info', 'Información', 'Para generar el asiento contable primero debe confirmar la salida');
			return;
		}
		const parametros: any[] = [];
		
		parametros[0] = Number(this.txtNume);
		parametros[1] = '01';
		parametros[2] = this.usuario.identificacion;
		
		try {

      const rs = await this.actencsalidaService.generarAsientoContable(parametros);
      for (const res of rs) {
        if(res.ASI_NRO !== null) {
          this.lblAsiNro = res.ASI_NRO;
        } else {
          this.lblAsiNro= '';
        }
      
      }

      this.mensajeAlerta('success', 'Información', 'Asiento contable generado exitosamente.. !!!!');

		} catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso generarAsiContable  no terminó satisfactoriamente.... ! ' + err.error);
		}
  }

  eliminarRegistro() {
    if (this.permisos !== null ) {
      if (this.permisos.PERTODO === 0 ) {
        if (this.permisos.PERELIMACION === 0 ) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ', no tiene permiso de eliminación. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }

    this.confirmationService.confirm({
      message: '¿ Está seguro de eliminar el registro ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'actencsalidaConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: async () => {
        if (this.txtNume === '') {
          this.mensajeAlerta('info', 'Información', 'Seleccione el registro a Eliminar');
          return;
        }

        const Parametros: any[] = [];
        
        Parametros[0] = this.txtNume;
        Parametros[1] = '01';

			
        try {

          await this.actencsalidaService.eliminarSalidaActivo(Parametros);
       
          this.auditoriaGralService.registrarAuditoria('ACT_ENCSALIDA', this.txtNume , 'E',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });
            
          this.limpiarDatos();
          this.actencsalidaService.detVariosSalidaArray[this.indicador] = [];
          this.actencsalidaService.detSalidaArray[this.indicador] = [];
          this.mensajeAlerta('success', 'Información', 'Registro eliminado exitosamente !!!! ');   
        } catch (err) {
          this.mensajeAlerta('error', 'Error', '! Proceso eliminar datos no terminó satisfactoriamente.... ! ' + err.error);
        }
      },
      reject: () => {
         this.init.tabs.splice(this.init.tabindex, 1);
      }
    });


  }

  async guardarRegistro() {
    this.spin=true;//CDPJ
		if (this.txtNume === '') {
			this.strTipoGuardar = 'N';
        /*Verifica los permisos*/
      if (this.permisos !== null ) {
        if (this.permisos.PERTODO === 0 ) {
          if (this.permisos.PERINSERCION === 0 ) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.');
              this.spin=false;//CDPJ
            return;
          }
        }
      }
		} else { /*Modifica registro*/
			this.strTipoGuardar = 'M';	
      /*Verifica los permisos*/
      if (this.permisos !== null ) {
        if (this.permisos.PERTODO === 0 ) {
          if (this.permisos.PERACTUALIZACION === 0 ) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
              this.spin=false;//CDPJ
            return;
          }
        }
      }
		}

		if (await this.FunVerificaActivoDadoDeBaja() === true){
      this.mensajeAlerta('info', 'Información', 'No puede modificar esta salida porque hay activos dados de baja');
      this.spin=false;//CDPJ
      return;
    }

    let strDia='', strMes='', strAnio='';

    if (this.dateFecha.toString().includes('-')) {
      strDia = this.completarFecha(String(this.dateFecha.getDate()));
      strMes = this.completarFecha(String(this.dateFecha.getMonth() + 1));
      strAnio = this.completarFecha(String(this.dateFecha.getFullYear()));
    } else {
      const fechaArray = String(this.dateFecha).split('/');
      strDia = fechaArray[0];
      strMes = fechaArray[1];
      strAnio = fechaArray[2];
    }
		const strFechaEmi = strDia + '-' + strMes + '-' + strAnio;	
		
		if (await this.faltaCampos() === true) {//Si los datos de la cabecera son correctos
			this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultActDetSalida.objeto].stopEditing();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultActDetVarSal.objeto].stopEditing();
				//Guardar la cabecera 
			const Parametros: any[] = [];
				
			Parametros[0] = Number(this.txtNume);		
			Parametros[1] = '01';
			Parametros[2] = String(this.cmbTipUsu.findIndex(index => index.name === this.strTipoUsu.name));	 
			Parametros[3] = this.txtCodCli1;
			Parametros[4] = this.txtCiuCod;
			Parametros[5] = this.txtCodOfi;
			Parametros[6] = this.txtCodDep;	
			Parametros[7] = this.txtCodArea;	
			Parametros[8] = this.txtAuto;
			Parametros[9] = this.txtRefe;	
			Parametros[10] = this.txtObse1;		
			Parametros[11] = this.txtObse2;	
			Parametros[12] = '';	
			Parametros[13] = '';		
			Parametros[14] = this.txtEsta;	
			Parametros[15] = this.txtCodCli2;			
			Parametros[16] = this.strMotivo.codigo;
			Parametros[17] = strFechaEmi;
			Parametros[18] = this.txtComp;
				
			try {
				const rs = await this.actencsalidaService.guardarEncSalida(Parametros);
				for (const res of rs) {
					if (this.strTipoGuardar === 'N') {
						if(res.V_ENCSALIDA_CODIGO !== null) {
              this.txtNume = res.V_ENCSALIDA_CODIGO;
            } else {
              this.txtNume = '';
            }	
						if(res.V_ENCSALIDA_REFERENCIA !== null) {
              this.txtRefe = res.V_ENCSALIDA_REFERENCIA;
            } else {
              this.txtRefe = '';
            }

            this.auditoriaGralService.registrarAuditoria('ACT_ENCSALIDA', this.txtNume , 'I',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });
						 
						} else {
              this.auditoriaGralService.registrarAuditoria('ACT_ENCSALIDA', this.txtNume , 'A',
                this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
              });
					  }
					    
						await this.guardarDetalle();
						await this.GuardaVarios();
						this.activaBotones('G',true, false, false, true, true, true, true, true, true, true);
            this.mensajeAlerta('success', 'Información', 'Registro guardado exitosamente !!!! ');
					}
					this.spin=false;//CDPJ
				} catch (err) {
          this.mensajeAlerta('error', 'Error', '! Proceso guardar cabecera de la factura  no terminó satisfactoriamente.... ! ' + err.error);
          this.spin=false;//CDPJ
				}
				
			
    }//Fin si los datos de la cabecera son correctos
    else{
      this.spin=false;//CDPJ
    }
  }

  async GuardaVarios() {
    const Parametros: any[] =[];
    let intIndiceLista = 0;
		let item: ActDetVarSalida = {};
		for (intIndiceLista=0;intIndiceLista<this.actencsalidaService.detVariosSalidaArray[this.indicador].length;intIndiceLista++) {
			item = this.actencsalidaService.detVariosSalidaArray[this.indicador][intIndiceLista];
			if (item.CON_CODIGO === '') {
        this.actencsalidaService.detVariosSalidaArray[this.indicador].splice(this.actencsalidaService.detVariosSalidaArray[this.indicador].findIndex(
          det => det.LINEA === item.LINEA && det.DETVARIOSSAL_NUMSEC === item.DETVARIOSSAL_NUMSEC),
          1);
      }
		}
		
		
		intIndiceLista=0;
		
		for (intIndiceLista=0;intIndiceLista<this.actencsalidaService.detVariosSalidaArray[this.indicador].length;intIndiceLista++)
		{
			item = this.actencsalidaService.detVariosSalidaArray[this.indicador][intIndiceLista];
			if (item.CON_CODIGO !== '') {
				
				Parametros[0] = this.txtNume; //Nro de la retencion
				Parametros[1] = Number(item.DETVARIOSSAL_NUMSEC);
				Parametros[2] = item.CON_CODIGO;
			  Parametros[3] = item.DETVARIOSSAL_DESCRIPCION;
				Parametros[4] = Number(item.DETVARIOSSAL_IMPORTE);
        Parametros[5] = Number(intIndiceLista+1);
        Parametros[6] = item.CEN_CODIGO;//CDPJ
        				
				try {
					const rs = await this.actencsalidaService.guardarDetVariosSalida(Parametros);
					for (const res of rs) {
						if (item.DETVARIOSSAL_NUMSEC == 0) {
              this.actencsalidaService.detVariosSalidaArray[this.indicador][intIndiceLista].DETVARIOSSAL_NUMSEC = res[':B1'];
              this.auditoriaGralService.registrarAuditoria('ACT_DETVARIOSSAL', this.txtNume + '/' + item.CON_CODIGO, 'I',
                this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
              });
						} else {
              this.auditoriaGralService.registrarAuditoria('ACT_DETVARIOSSAL', this.txtNume + '/' + item.CON_CODIGO, 'A',
                this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
              });
            }

					}					
				} catch (err) {
          this.mensajeAlerta('error', 'Error', '! Proceso guardar varios no terminó satisfactoriamente.... ! ' + err.error);
          this.spin=false;//CDPJ
				}
			} //Fin si no es un valor en blanco	
				
		}
  }

  async guardarDetalle() {
    let intIndiceLista=0;
		let item: ActDetSalida = {};
		const Parametros: any[] = [];
		
		for (intIndiceLista=0;intIndiceLista<this.actencsalidaService.detSalidaArray[this.indicador].length;intIndiceLista++)	{
			item = this.actencsalidaService.detSalidaArray[this.indicador][intIndiceLista];
			
			if (item.ACT_CODIGO !== '') {
				//Código de la factura del proveedor
				Parametros[0] = this.txtNume;
				Parametros[1] = Number(item.DETSALIDA_NUMSEC); //linea del detalle		
				Parametros[2] = item.ACT_CODIGO;//bodega			
				Parametros[3] = '01';
				Parametros[4] = item.DETSALIDA_REFERENCIA;			
				Parametros[5] = item.DETSALIDA_COMENTARIO;	
				Parametros[6] = item.DETSALIDA_DESCRIPCION;			
				Parametros[7] = Number(intIndiceLista + 1);			
				Parametros[8] = Number(item.DETSALIDA_IMPORTE);
				
				try {
          const rs = await this.actencsalidaService.guardarDetSalida(Parametros);
					for (const res of rs) {
						if (item.DETSALIDA_NUMSEC === 0) {
              this.actencsalidaService.detSalidaArray[this.indicador][intIndiceLista].DETSALIDA_NUMSEC = res[':B1'];
              this.auditoriaGralService.registrarAuditoria('ACT_DETSALIDA', this.txtNume + '/' + item.ACT_CODIGO, 'I',
                this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
              });
						} else {
              this.auditoriaGralService.registrarAuditoria('ACT_DETSALIDA', this.txtNume + '/' + item.ACT_CODIGO, 'A',
                this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
              });
            }
					}
					
				} catch (err) {
          this.mensajeAlerta('error', 'Error', '! Proceso guardar datos del detalle no terminó satisfactoriamente.... ! ' + err.error);
          this.spin=false;//CDPJ
				}

			}
		}//Fin de for
  }

  async FunVerificaActivoDadoDeBaja() {
    let dblRetorno = false;
    let intIndiceLista = 0;
    let item: ActDetSalida = {};
    const Parametros: any[] = [];
    let intExiste = 0;
    for (intIndiceLista=0;intIndiceLista<this.actencsalidaService.detSalidaArray[this.indicador].length;intIndiceLista++) {
      item = this.actencsalidaService.detSalidaArray[this.indicador][intIndiceLista];
      
      if (item.ACT_CODIGO !== '') {
        Parametros[0] = item.ACT_CODIGO;

        try {
          const rs = await this.actencsalidaService.verificarActivoDadoBaja(Parametros);
          for (const res of rs) {
            intExiste = res.EXISTE;
            if (intExiste==1){
              dblRetorno = true;
            }
          }
          
        } catch (err) {
          this.mensajeAlerta('error', 'Error', '! Proceso FunVerificaActivoDadoDeBaja no terminó satisfactoriamente.... ! ' + err.error);
        }

      }
	}//Fin de for
	
	

	return dblRetorno;
  }

  async cancelarRegistro() {
    await this.mostrarDatos(this.txtNume);
  }

  nuevoRegistro() {

    document.getElementById('divPrincipal').style.pointerEvents = 'all';
    this.limpiarDatos();
    this.actencsalidaService.detVariosSalidaArray[this.indicador] = [];
    this.actencsalidaService.detSalidaArray[this.indicador] = [];
		this.txtEsta = 'P';
		this.lblEstado = 'PENDIENTE';
		this.activaBotones('N', false, true, true, false, false, false, false, false, false, false);
    document.getElementById('referencia').focus();
    
  }

  async seleccionarRegistro(strTipo){

    if (this.permisos !== null ) {
      if (this.permisos.PERTODO === 0 ) {
        if (this.permisos.PERSELECCION === 0 ) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ', no tiene permiso de Selección. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }
		
		const parametros: any[] = [];
		let strEncFacpro = '';
		
		parametros[0] = strTipo;
		parametros[1] ='01'; 
		parametros[2] = this.txtNume; 
	
	
		try {
			const rs = await this.actencsalidaService.navegarRegistros(parametros);
			
			if (rs !== null) {
        strEncFacpro = rs[0].ENCSALIDA_CODIGO == null ? '': rs[0].ENCSALIDA_CODIGO;
      }
					
			
		} catch (err) {
      this.mensajeAlerta('error', 'Error', '! Error al seleccionar registro.... ! ' + err.error)
		}
		
		if (strEncFacpro !== '') {
			await this.mostrarDatos(strEncFacpro);
    }
    
	}

  buscarRegistro() {
    if (this.permisos !== null ) {
      if (this.permisos.PERTODO === 0 ) {
        if (this.permisos.PERSELECCION === 0 ) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ', no tiene permiso de Selección. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }

    this.opcion = 'BuscarRegistro';
    this.types = [
      { label: 'CÓDIGO', value: 'ENCSALIDA_CODIGO' },
      { label: 'REFERENCIA', value: 'ENCSALIDA_REFERENCIA' },
      { label: 'FECHA', value: 'ENCSALIDA_FECHA' },
      { label: 'OBSERVACION', value: 'ENCSALIDA_OBSERV_1' }
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', ''];
    this.consulta = '*'
    this.tabla = 'act_encsalida';
    this.where = 'ROWNUM < 50';

    this.busquedaSer.busquedaActEncSalida1().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.displayDialogBusquedaFast = true;
    });

  }

  busquedaUsuario1(parametro) {
    this.opcion = 'usuario1';
    this.types = [
      {label: 'CÓDIGO', value: 'EMP_CODIGO'},
      {label: 'NOMBRES', value: 'EMP_NOMBRE'},
      {label: 'APELLIDOS', value: 'EMP_APELLIDO'}
    ];
    this.busquedacampos = [parametro, '', ''];
    this.consulta = "EMP_CODIGO, emp_nombre || ' ' || emp_apellido as EMP_NOMBRE";
    this.where = ' ROWNUM < 50';
    this.tabla = 'nom_maeempleado';
    
    this.actencsalidaService.encontrarNomMaeEmpleado(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaNomMaeEmpleado3().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaNomMaeEmpleado3().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaUsuario2(parametro) {
    this.opcion = 'usuario2';
    this.types = [
      {label: 'CÓDIGO', value: 'CLI_CODIGO'},
      {label: 'NOMBRE', value: 'CLI_NOMBRE'},
      {label: 'RUCIDE', value: 'CLI_RUCIDE'},
      {label: 'NOMBREC', value: 'CLI_NOMBREC'}
    ];
    this.busquedacampos = [parametro, '', '', ''];
    this.consulta = '*';
    this.where = ' ROWNUM < 50';
    this.tabla = 'ven_maecliente';
    
    this.actencsalidaService.encontrarVenMaeCliente(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaVenMaeCliente7().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaVenMaeCliente7().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaCiudad(parametro) {
    this.opcion = 'ciudad';
    this.types = [
      {label: 'CÓDIGO', value: 'CIU_CODIGO'},
      {label: 'NOMBRE', value: 'CIU_NOMBRE'},
      {label: 'NOMBRE CORTO', value: 'CIU_NOMBREC'}
    ];
    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.where = 'ROWNUM < 50';
    this.tabla = 'ven_maeciudad';
    
    this.actencsalidaService.encontrarVenMaeCiudad(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaVenMaeCiudad2().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaVenMaeCiudad2().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaOficina(parametro) {
    this.opcion = 'oficina';
    this.types = [
      {label: 'CÓDIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'}
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = '*';
    this.where = 'ref_tipo = \'ACTOF\' and ROWNUM < 50';
    this.tabla = 'SACI_MAEREFERENCIA';
    
    this.actencsalidaService.encontrarReferencia(parametro, 'ACTOF').subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaSaciMaeReferencia10('ACTOF').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaSaciMaeReferencia10('ACTOF').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaArea(parametro) {
    this.opcion = 'area';
    this.types = [
      {label: 'CÓDIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'}
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = '*';
    this.where = 'ref_tipo = \'ACTAR\' and ROWNUM < 50';
    this.tabla = 'SACI_MAEREFERENCIA';
    
    this.actencsalidaService.encontrarReferencia(parametro, 'ACTAR').subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaSaciMaeReferencia10('ACTAR').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaSaciMaeReferencia10('ACTAR').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaDepartamento(parametro) {
    this.opcion = 'departamento';
    this.types = [
      {label: 'CÓDIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'}
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = '*';
    this.where = 'ref_tipo = \'ACTDP\' and ROWNUM < 50';
    this.tabla = 'SACI_MAEREFERENCIA';
    
    this.actencsalidaService.encontrarReferencia(parametro, 'ACTDP').subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaSaciMaeReferencia10('ACTDP').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaSaciMaeReferencia10('ACTDP').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  manejarSeleccion(valor) {

    if (this.opcion === 'BuscarRegistro') {
      this.mostrarDatos(valor.ENCSALIDA_CODIGO);
    }
    if (this.opcion === 'ciudad') {
      if (valor.CIU_CODIGO !== '') {
        this.txtCiuCod = valor.CIU_CODIGO;
			  this.lblCiuNomb = valor.CIU_NOMBRE;
      }
    }

    if (this.opcion === 'oficina') {
      if (valor.REF_CODIGO !== '') {
        this.txtCodOfi = valor.REF_CODIGO;
			  this.lblNomOfi = valor.REF_NOMBRE;
      }
    }

    if (this.opcion === 'area') {
      if (valor.REF_CODIGO !== '') {
        this.txtCodArea = valor.REF_CODIGO;
			  this.lblNomArea = valor.REF_NOMBRE;
      }
    }

    if (this.opcion === 'departamento') {
      if (valor.REF_CODIGO !== '') {
        this.txtCodDep = valor.REF_CODIGO;
			  this.lblNomDep = valor.REF_NOMBRE;
      }
    }

    if (this.opcion === 'ciudad') {
      if (valor.CIU_CODIGO !== '') {
        this.txtCiuCod = valor.CIU_CODIGO;
			  this.lblCiuNomb = valor.CIU_NOMBRE;
      }
    }

    if (this.opcion === 'usuario1') {
      if (valor.EMP_CODIGO !== '') {
        this.txtCodCli1 = valor.EMP_CODIGO;
			  this.lblNomCli1 = valor.EMP_NOMBRE;
      }
    }

    if (this.opcion === 'usuario2') {
      if (valor.CLI_CODIGO !== '') {
        this.txtCodCli2 = valor.CLI_CODIGO;
			  this.lblNomCli2 = valor.CLI_NOMBRE;
      }
    }

    this.displayDialogBusquedaFast = false;
  }

  async mostrarDatos(strEncKardCod) {
    this.spin=true;//CDPJ
		if (strEncKardCod == '') {
      strEncKardCod = '0';
    }
    
    this.limpiarDatos();

    this.actencsalidaService.detSalidaArray[this.indicador] = [];

    try {
			const rs = await this.actencsalidaService.obtenerDatos(strEncKardCod);

			for (const res of rs) {

				document.getElementById('divPrincipal').style.pointerEvents = 'all';
        
        this.txtNume = res.ENCSALIDA_CODIGO;
        this.dateFecha = this.datePipe.transform(res.ENCSALIDA_FECHA, 'dd/MM/yyyy');
        this.txtRefe = res.ENCSALIDA_REFERENCIA === null ? '' : res.ENCSALIDA_REFERENCIA;
        this.txtComp = res.ENCSALIDA_CMPBCOMPRA === null ? '' : res.ENCSALIDA_CMPBCOMPRA;
        this.txtObse1 = res.ENCSALIDA_OBSERV_1 === null ? '' : res.ENCSALIDA_OBSERV_1;
        this.txtObse2 = res.ENCSALIDA_OBSERV_2 === null ? '' : res.ENCSALIDA_OBSERV_2;
				this.lblAsiNro = res.ASI_NRO === null ? '' : res.ASI_NRO;

        if (Number(res.ENCSALIDA_TIPOUSUARIO) !== 0) {
          this.strTipoUsu = this.cmbTipUsu[Number(res.ENCSALIDA_TIPOUSUARIO)];
        }

        this.txtCodCli1 = res.CLI_CODIGO === null ? '' : res.CLI_CODIGO;
        this.txtCodCli2 = res.CLI_CODIGO2 === null ? '' : res.CLI_CODIGO2;
        this.txtCiuCod = res.CIU_CODIGO === null ? '' : res.CIU_CODIGO;
        this.txtCodOfi = res.ENCSALIDA_OFICINA === null ? '' : res.ENCSALIDA_OFICINA;
        this.txtCodArea = res.ENCSALIDA_AREA === null ? '' : res.ENCSALIDA_AREA;
        this.txtCodDep = res.ENCSALIDA_DEPARTAMENTO === null ? '' : res.ENCSALIDA_DEPARTAMENTO;
        this.txtEsta = res.ENCSALIDA_ESTADO === null ? '' : res.ENCSALIDA_ESTADO;
					
				if (this.txtEsta !== 'P') {
					this.lblEstado = 'CONFIRMADO';
					if (res.ENCSALIDA_FECCONFIRMA === null) {
						this.lblConf = '';
					} else {
            this.lblConf = 'Confirmado: ' + this.datePipe.transform(res.ENCSALIDA_FECCONFIRMA, 'dd/MM/yyyy');
					}
					
				} else {
          this.lblEstado = 'PENDIENTE';
				}
				
        let StrCodMotivo = '',strNomMotivo='';
        
				if (res.ENCSALIDA_MOTIVO !== null) {
          StrCodMotivo = res.ENCSALIDA_MOTIVO;
        } else {
          this.strMotivo = this.cmbMoti[0];
        }

        for (const item of this.cmbMoti) {
          if (item.codigo === StrCodMotivo) {
            this.strMotivo = {name: item.name, codigo: item.codigo};
          }
        }

        this.txtCodDep = res.ENCSALIDA_DEPARTAMENTO === null ? '' : res.ENCSALIDA_DEPARTAMENTO;
        this.txtAuto = res.ENCSALIDA_AUTORIZADO === null ? '' : res.ENCSALIDA_AUTORIZADO;

        const codCli1 = this.txtCodCli1 === '' ? 0: this.txtCodCli1;
        this.lblNomCli1 = await this.encontrarRegistro("emp_nombre || ' ' || emp_apellido", "nom_maeempleado", "emp_codigo = " + codCli1);
			  this.lblNomCli2 = await this.encontrarRegistro("cli_nombre", "ven_maecliente", "cli_codigo = '" + this.txtCodCli2 +"'");
        this.lblCiuNomb = await this.encontrarRegistro("ciu_nombre", "ven_maeciudad", "ciu_codigo = '" + this.txtCiuCod + "'");
        this.lblNomOfi = await this.encontrarRegistro("ref_nombre", "saci_maereferencia", "ref_codigo = '" + this.txtCodOfi + "' and ref_tipo = 'ACTOF'");
        this.lblNomArea = await this.encontrarRegistro("ref_nombre", "saci_maereferencia", "ref_codigo = '" + this.txtCodArea + "' and ref_tipo = 'ACTAR'");
        this.lblNomDep = await this.encontrarRegistro("ref_nombre", "saci_maereferencia", "ref_codigo = '" + this.txtCodDep + "' and ref_tipo = 'ACTDP'");

				
				await this.mostrarDetAct(this.txtNume);
				await this.mostrarDetVar(this.txtNume);
        
      }

		} catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso desplegar datos no terminó satisfactoriamente.... ! '  + err.error);	
      this.spin=false;//CDPJ
		}
		
		if (this.lblEstado === 'CONFIRMADO'){
      this.activaBotones("NoModificar", true, false, false, true, true, true, true, true, true, true);
      this.spin=false;//CDPJ
		}
		else{
			this.activaBotones('NAV', true, false, false, true, true, true, true, true, true, true);
			this.activarBotonesDetalle(true,true,false);
      this.activarBotonesVarios(true, true,false);		
      this.spin=false;//CDPJ	
		}
		
  }

  async mostrarDetVar(strEncFacProNumero) {
		let intSalCod = 0;
		let concentro='';
		if (strEncFacProNumero !== '') {
      intSalCod = Number(strEncFacProNumero);
    }	
    
    this.actencsalidaService.detVariosSalidaArray[this.indicador] = [];
		

		try {
			const rs = await this.actencsalidaService.obtenerDatosDetVarios(intSalCod);
      let num = 0;
      const detalle = [];
			for (const res of rs) {
        
        num++;
				const item: ActDetVarSalida = {};
        item.CON_CODIGO = res.CON_CODIGO === null ? '' : res.CON_CODIGO;
        item.DETVARIOSSAL_DESCRIPCION = res.DETVARIOSSAL_DESCRIPCION === null ? '' : res.DETVARIOSSAL_DESCRIPCION;
        item.DETVARIOSSAL_IMPORTE = res.DETVARIOSSAL_IMPORTE === 0 ? Number(0).toFixed(2) : Number(res.DETVARIOSSAL_IMPORTE).toFixed(2);
        item.DETVARIOSSAL_NUMSEC = res.DETVARIOSSAL_NUMSEC;
        item.LINEA = num;
        item.CEN_CODIGO=res.CEN_CODIGO === null || res.CEN_CODIGO === undefined?'':res.CEN_CODIGO;//CDPJ
        const data = await  this.actencsalidaService.ercentrocuenta(item.CON_CODIGO);
        if(data !== null && data !== undefined){
          for (let i of data) {
            item.CON_CENTRO =  i.CON_CENTRO;
          }
        }
				detalle.push(item);
      }
      this.actencsalidaService.detVariosSalidaArray[this.indicador] = detalle;
		
		} catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso listarDatos no terminó satisfactoriamente.... ! ' + err.error);	
      this.spin=false;//CDPJ		
		}
  }

  async mostrarDetAct(StrNumeroFactura) {
   
    this.actencsalidaService.detSalidaArray[this.indicador] = [];
		
		const parametros: any[] = [];
		parametros[0] = StrNumeroFactura;

		try {
      const rs = await this.actencsalidaService.obtenerDatosDetSalida(parametros);
      let num = 0;
			const detalle = [];
			for (const res of rs) {
        num++;
        const item: ActDetSalida = {};
        item.DETSALIDA_NUMSEC = res.DETSALIDA_NUMSEC === 0 ? 0 : res.DETSALIDA_NUMSEC;
        item.ACT_CODIGO = res.ACT_CODIGO === null ? '' : res.ACT_CODIGO;
        item.DETSALIDA_DESCRIPCION = res.DETSALIDA_DESCRIPCION === null ? '' :  res.DETSALIDA_DESCRIPCION;
        item.DETSALIDA_REFERENCIA = res.DETSALIDA_REFERENCIA === null ? '' : res.DETSALIDA_REFERENCIA;
        item.DETSALIDA_COMENTARIO = res.DETSALIDA_COMENTARIO === null ? '' : res.DETSALIDA_COMENTARIO;
        item.DETSALIDA_IMPORTE = res.DETSALIDA_IMPORTE === 0 ? Number(0).toFixed(2) : Number(res.DETSALIDA_IMPORTE).toFixed(2)
        item.LINEA = num;

				detalle.push(item);
			}
			this.actencsalidaService.detSalidaArray[this.indicador] = detalle;
			
		} catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso listarDatos no terminó satisfactoriamente.... ! ' + err.error);
      this.spin=false;//CDPJ
    }
    

  }

  activarBotonesDetalle(bolNuevoDetalle, bolEliminarDetalle, bolCancelarDetalle) {
    this.btnNuevoDetSal = !bolNuevoDetalle;
		this.btnBorrarDetSal = !bolEliminarDetalle;
		this.btnCancelarDetSal = !bolCancelarDetalle;
  }

  activarBotonesVarios(bolNuevoDetalle, bolEliminarDetalle, bolCancelarDetalle ) {
		this.btnNuevoDetVarSal = !bolNuevoDetalle;
		this.btnBorrarDetVarSal = !bolEliminarDetalle;
		this.btnCancelarDetVarSal = !bolCancelarDetalle;
		
	}

  activaBotones(strTipo, bolNuevo, bolGuardar, bolCancelar, bolEliminar, bolInicio, bolAnterior, bolSiguiente, bolUltimo, bolBuscar, bolImprimir) {
    
    this.btnGuardar = !bolGuardar; 
    this.btnNuevo = !bolNuevo; 
    this.btnBorrar = !bolEliminar;
    
    if (strTipo === 'NoModificar') {
      document.getElementById('divPrincipal').style.pointerEvents = 'none';

      this.aggrid.bloquearGrid();

      this.activarBotonesDetalle(false,false,false);
     	this.activarBotonesVarios(false, false,false);			
      this.btnGuardar = true; 
      this.btnBorrar = true;
    
        
      if (this.txtEsta === 'CNFRM') {
        this.btnVisto = true;
        this.btnRevActFijo = false;
      }
        
    } else {
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      this.aggrid.habilitarGrid();
      this.activarBotonesDetalle(true,true,false);
      this.activarBotonesVarios(true, true,false);		
      this.btnVisto = false;
      this.btnRevActFijo = true;
    }
      
   
          
}

  async encontrarRegistro(strCampo, strTabla, strCondicion) {
    let strResultado = '';
    try {
      const rs = await this.actencsalidaService.encontrarRegistroPromise(strCampo, strTabla, strCondicion);
      if (rs != null) {
        for (const item of rs) {
          for (const clave in item) {
            if (item[clave] !== null) {
              strResultado = item[clave];
            } else{
              strResultado = '';
            }
          }
        }
       
      }
    } catch (err) {
      this.mensajeAlerta('error', 'Error', err.error);
      this.spin=false;//CDPJ
    }
    return strResultado;
  }

  limpiarDatos() {
		this.txtNume = '';
		this.dateFecha = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
		this.txtRefe = '';
		this.txtComp = '';
		this.txtObse1 = '';
		this.txtObse2 = '';
		this.lblAsiNro = '';
		this.txtEsta = 'P';
		this.lblEstado = 'PENDIENTE';
		this.strTipoUsu = this.cmbTipUsu[0];
		this.txtCodCli1 = '';
		this.txtCodCli2 = '';
		this.txtCiuCod = '';
		this.txtCodOfi = '';
		this.txtCodArea = '';
		this.txtCodDep = '';
		this.strMotivo = this.cmbMoti[0];
		this.txtAuto = '';
    this.lblConf = '';
    this.lblNomCli1 = '';
		this.lblNomCli2 ='';
    this.lblCiuNomb = '';
    this.lblNomOfi = '';
    this.lblNomArea = '';
    this.lblNomDep = '';
    this.txtActPie = '';
    this.txtDepAcumKard = ''; 
  }

  async getPermisos() {
  
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisos = data[0];
        } else {
          this.permisos = null;
        }
      } else {
        this.permisos = null;
      }
    } catch (e) {
      this.permisos = null;
      this.mensajeAlerta('error', 'Error', 'No se pudo obtener los permisos del formulario');
    }
  }

  async mostrarMotivo() {
		
		try {
      const rs = await this.actencsalidaService.consultarMotivo();
      this.cmbMoti = [];
      this.cmbMoti.push({name: '', codigo: ''});
			for (const res of rs) {
        this.cmbMoti.push({name: res.REF_NOMBRE, codigo: res.REF_CODIGO});
      }
      
    this.strMotivo = this.cmbMoti[0];

		} catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso mostrarMotivo no terminó satisfactoriamente.... ! ' + err.error);
		}
  }
  

  cambiarFoco(evento) {
    if (evento.target.id === 'referencia') {
      document.getElementById('comprobante').focus();
    }
    if (evento.target.id === 'comprobante') {
      document.getElementById('tipousu').focus();
    }
    if (evento.target.id === 'tipousu') {
      document.getElementById('cliente1').focus();
    }
    if (evento.target.id === 'cliente1') {
      document.getElementById('cliente2').focus();
    }
    if (evento.target.id === 'cliente2') {
      document.getElementById('ciudad').focus();
    }
    if (evento.target.id === 'ciudad') {
      document.getElementById('oficina').focus();
    }
    if (evento.target.id === 'oficina') {
      document.getElementById('area').focus();
    }
    if (evento.target.id === 'area') {
      document.getElementById('departamento').focus();
    }
    if (evento.target.id === 'departamento') {
      document.getElementById('motivo').focus();
    }
    if (evento.target.id === 'motivo') {
      document.getElementById('autorizado').focus();
    }
    if (evento.target.id === 'autorizado') {
      document.getElementById('observacion1').focus();
    }
    if (evento.target.id === 'observacion1') {
      document.getElementById('observacion2').focus();
    }
  }

  cambio(event) {
    this.activaBotones('M', false, true, true, false, false, false, false, false, false, false);
  }

  cambioDet(event) {
    this.activaBotones('M', false, true,true, false, false, false, false, false, false, false);
		this.activarBotonesDetalle(false,false,true);
  }

  cambioVar(event) {
    this.activaBotones('M', false, true,true, false, false, false, false, false, false, false);
		this.activarBotonesVarios(false,false,true);
  }

  seleccionDetSalida(valor) {
    if (valor === 'nuevo') {
      this.insertarLineaDetActi();
    }
    if (valor === 'eliminar') {
      this.eliminarRegistroDetActi();
    }
    if (valor === 'cancelar') {
      this.cancelarRegistroDetActi();
    }

    if (valor.object !== undefined) {
      this.detalleDetSalidaSel = valor.object;
    }
  }

  cancelarRegistroDetActi() {
    let intIndiceLista = 0;
    for (intIndiceLista = 0; intIndiceLista < this.actencsalidaService.detSalidaArray[this.indicador].length; intIndiceLista++) {
      const item = this.actencsalidaService.detSalidaArray[this.indicador][intIndiceLista];
      if (item.ACT_CODIGO === '') {
        this.actencsalidaService.detSalidaArray[this.indicador].splice(this.actencsalidaService.detSalidaArray[this.indicador].findIndex(
          det => det.LINEA === item.LINEA && det.DETSALIDA_NUMSEC === item.DETSALIDA_NUMSEC),
          1);
      }
    }
    
    this.aggrid.refreshaggrid(this.actencsalidaService.detSalidaArray[this.indicador], this.defaultActDetSalida.objeto);
    this.activarBotonesDetalle(true,true,false);		
  }

  async eliminarRegistroDetActi() {
    let item: ActDetSalida = {};
    const parametros: any[] = [];
    
    if (Object.keys(this.detalleDetSalidaSel).length === 0) {
      this.mensajeAlerta('error', 'Error', '! Seleccione una fila.... !');
      return;
    }

    item = this.detalleDetSalidaSel;

    this.confirmationService.confirm({
      message: '¿ Está seguro de eliminar el registro ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'actencsalidaConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: async () => {
        parametros[0] =this.txtNume;
			  parametros[1] = Number(item.DETSALIDA_NUMSEC);
			
        try {

          await this.actencsalidaService.eliminarDetSalida(parametros);

          this.auditoriaGralService.registrarAuditoria('ACT_DETSALIDA', this.txtNume + '/' + item.ACT_CODIGO, 'E',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });
          this.actencsalidaService.detSalidaArray[this.indicador].splice(
            this.actencsalidaService.detSalidaArray[this.indicador].findIndex(
            det => det.LINEA === item.LINEA && det.DETSALIDA_NUMSEC === item.DETSALIDA_NUMSEC),
            1);
  
          this.aggrid.refreshaggrid(this.actencsalidaService.detSalidaArray[this.indicador], this.defaultActDetSalida.objeto);
  
          this.activaBotones('M', false, true, true, false, false, false, false, false, false, false);
          this.activarBotonesDetalle(true,true,false);
          
        } catch (err) {
          this.mensajeAlerta('error', 'Error', '! Proceso eliminar línea de detalle  no terminó satisfactoriamente.... ! ' + err.error);
        }
      },
      reject: () => {
         this.init.tabs.splice(this.init.tabindex, 1);
      }
    });


  }

  async insertarLineaDetActi() {
    if (await this.faltaCampos() === true ) {
      this.nuevoElementoDetSalida();
    }
			
  }

  nuevoElementoDetSalida() {
    const linea = this.actencsalidaService.detSalidaArray[this.indicador].length;
    const nuevodetalle: ActDetSalida= {
      LINEA: linea + 1,
      ACT_CODIGO: '',
      DETSALIDA_COMENTARIO: '',
      DEPRE_ACUM: Number(0).toFixed(2),
      DETSALIDA_IMPORTE: Number(0).toFixed(2),
      DETSALIDA_DESCRIPCION: '',
      DETSALIDA_NUMSEC: 0,
      DETSALIDA_REFERENCIA: ''          
    };
         
    const indi = this.actencsalidaService.detSalidaArray[this.indicador].indexOf(this.detalleDetSalidaSel);
    if (indi === -1) {
      this.actencsalidaService.detSalidaArray[this.indicador].push(nuevodetalle);
      this.detalleDetSalidaSel = this.actencsalidaService.detSalidaArray[this.indicador][this.actencsalidaService.detSalidaArray[this.indicador].length - 1];
      this.aggrid.refreshaggrid(this.actencsalidaService.detSalidaArray[this.indicador], this.defaultActDetSalida.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultActDetSalida.objeto].setFocusedCell(
        this.actencsalidaService.detSalidaArray[this.indicador].length - 1, 'ACT_CODIGO');
    } else {
      this.detalleDetSalidaSel = nuevodetalle;
      this.actencsalidaService.detSalidaArray[this.indicador].splice(indi + 1, 0, nuevodetalle);
      this.aggrid.refreshaggridindex(this.actencsalidaService.detSalidaArray[this.indicador], this.defaultActDetSalida.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultActDetSalida.objeto].setFocusedCell(
      indi + 1, 'ACT_CODIGO');
    }
    this.activaBotones('N', false, true, true, false, false, false, false, false, false, false);
    this.activarBotonesDetalle(false,false,true);
  }



  async faltaCampos() {

    let strDia='', strMes='', strAnio='';

    if (this.dateFecha.toString().includes('-')) {
      strDia = this.completarFecha(String(this.dateFecha.getDate()));
      strMes = this.completarFecha(String(this.dateFecha.getMonth()));
      strAnio = this.completarFecha(String(this.dateFecha.getFullYear()));
    } else {
      const fechaArray = String(this.dateFecha).split('/');
      strDia = fechaArray[0];
      strMes = String(Number(fechaArray[1])-1);
      strAnio = fechaArray[2];
    }

    //Verifica que documento este dentro del periodo contable
    if (await this.utilitariosService.verificarPeriodoContable(Number(strDia), Number(strMes), Number(strAnio)) === false) {
      this.mensajeAlerta('info', 'Información', 'Error, fecha debe de estar dentro del período contable');
      return false;
    }
		
		if (this.strTipoUsu.name === '') {
      this.mensajeAlerta('info', 'Información', 'Error, Ingrese un tipo de usuario');
      document.getElementById('tipousu').focus();
			return false;	
		}		
		
		//comentario1
		if (String(this.txtAuto).length === 0)	{
      this.mensajeAlerta('info', 'Información', 'Error, Ingrese la autorización');
      document.getElementById('autorizado').focus();
			return false;	
		}
		return true;
	}

  seleccionDetVarSalida(valor) {
    if (valor === 'nuevo') {
      this.insertarLineaDetVari();
    }
    if (valor === 'eliminar') {
      this.eliminarRegistroVar();
    }
    if (valor === 'cancelar') {
      this.cancelarAccionesVar();
    }

    if (valor.object !== undefined) {

      this.detalleDetVarSalSel = valor.object;

    }

  }

  cancelarAccionesVar() {
    let intIndiceLista = 0;
    for (intIndiceLista = 0; intIndiceLista < this.actencsalidaService.detVariosSalidaArray[this.indicador].length; intIndiceLista++) {
      const item = this.actencsalidaService.detVariosSalidaArray[this.indicador][intIndiceLista];
      if (item.CON_CODIGO === '') {
        this.actencsalidaService.detVariosSalidaArray[this.indicador].splice(this.actencsalidaService.detVariosSalidaArray[this.indicador].findIndex(
          det => det.LINEA === item.LINEA && det.DETVARIOSSAL_NUMSEC === item.DETVARIOSSAL_NUMSEC),
          1);
      }
    }
    this.aggrid.refreshaggrid(this.actencsalidaService.detVariosSalidaArray[this.indicador], this.defaultActDetVarSal.objeto);
    this.activarBotonesVarios(true,true,false);
  }

  async eliminarRegistroVar() {
    
		const Parametros: any[] = [];
		let item: ActDetVarSalida = {};

    if (Object.keys(this.detalleDetVarSalSel).length === 0) {
      this.mensajeAlerta('error', 'Error', '! Seleccione una fila.... ! ');
      return;
    }
    item = this.detalleDetVarSalSel;


    this.confirmationService.confirm({
      message: '¿ Está seguro de eliminar el registro ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'actencsalidaConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: async () => {

        Parametros[0] = this.txtNume;
        Parametros[1] = Number(item.DETVARIOSSAL_NUMSEC);
 	
        try {
          await this.actencsalidaService.eliminarDetVarSalida(Parametros);
          
          this.auditoriaGralService.registrarAuditoria('ACT_DETVARIOSSAL', this.txtNume + '/' + item.CON_CODIGO, 'E',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });
          this.actencsalidaService.detVariosSalidaArray[this.indicador].splice(
            this.actencsalidaService.detVariosSalidaArray[this.indicador].findIndex(
            det => det.LINEA === item.LINEA && det.DETVARIOSSAL_NUMSEC === item.DETVARIOSSAL_NUMSEC),
            1);
  
          this.aggrid.refreshaggrid(this.actencsalidaService.detVariosSalidaArray[this.indicador], this.defaultActDetVarSal.objeto);

          this.activaBotones('M', false, true, true, false, false, false, false, false, false, false);
          this.activarBotonesVarios(false, false, true);
          
        } catch (err) {
          this.mensajeAlerta('error', 'Error', '! Proceso eliminar línea de detalle  no terminó satisfactoriamente.... ! ' + err.error);				
        }
        
        
      }, reject: () => {
         this.init.tabs.splice(this.init.tabindex, 1);
      }
    });
  }

  async insertarLineaDetVari() {
    if (await this.faltaCampos() === true ) {
      this.nuevoElementoVarios();
    }
			
  }

  nuevoElementoVarios() {
    const linea = this.actencsalidaService.detVariosSalidaArray[this.indicador].length;
    const nuevodetalle: ActDetVarSalida = {
      LINEA: linea + 1,
      CON_CODIGO: '',
      DETVARIOSSAL_DESCRIPCION: '',
      DETVARIOSSAL_IMPORTE: Number(0).toFixed(2),
      DETVARIOSSAL_NUMSEC: 0,
      DETVARIOSSAL_ORDEN: 0,
      ENCSALIDA_CODIGO:0,
      CEN_CODIGO:''      
    };
         
    const indi = this.actencsalidaService.detVariosSalidaArray[this.indicador].indexOf(this.detalleDetVarSalSel);
    if (indi === -1) {
      this.actencsalidaService.detVariosSalidaArray[this.indicador].push(nuevodetalle);
      this.detalleDetVarSalSel = this.actencsalidaService.detVariosSalidaArray[this.indicador][this.actencsalidaService.detVariosSalidaArray[this.indicador].length - 1];
      this.aggrid.refreshaggrid(this.actencsalidaService.detVariosSalidaArray[this.indicador], this.defaultActDetVarSal.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultActDetVarSal.objeto].setFocusedCell(
        this.actencsalidaService.detVariosSalidaArray[this.indicador].length - 1, 'CON_CODIGO');
    } else {
      this.detalleDetVarSalSel = nuevodetalle;
      this.actencsalidaService.detVariosSalidaArray[this.indicador].splice(indi + 1, 0, nuevodetalle);
      this.aggrid.refreshaggridindex(this.actencsalidaService.detVariosSalidaArray[this.indicador], this.defaultActDetVarSal.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultActDetVarSal.objeto].setFocusedCell(
      indi + 1, 'CON_CODIGO');
    }

    this.activaBotones('M', false, true, true, false, false, false, false, false, false, false);
		this.activarBotonesVarios(false, false, true);
  }

  completarFecha(strFecha): string {
    if (strFecha.length === 1) {
      return '0' + strFecha;
    } else {
      return strFecha;
    }
    
  }

  async doubleClick(data) {
    let strActCod = data.ACT_CODIGO;
		let strActNom = data.DETSALIDA_DESCRIPCION;
    this.txtActPie = strActNom;
    
    let strDia='', strMes='', strAnio='';

    if (this.dateFecha.toString().includes('-')) {
       strDia = this.completarFecha(String(this.dateFecha.getDate()));
       strMes = this.completarFecha(String(this.dateFecha.getMonth() + 1));
       strAnio = this.completarFecha(String(this.dateFecha.getFullYear()));
    } else {
      const fechaArray = String(this.dateFecha).split('/');
      strDia = fechaArray[0];
      strMes = fechaArray[1];
      strAnio = fechaArray[2];
    }

    let strFecha = strAnio + '/' + strMes + '/' + strDia;
    
    
    const dblDepAcum = await this.FunObtieneDepreciacionAcumulada(strFecha,strActCod);
		this.txtDepAcumKard = Number(dblDepAcum).toFixed(2);

  }

  async FunObtieneDepreciacionAcumulada(strFecha, strActivo) { // lista las articulos para cuando se va a generar una nueva depreciacion
    
    let dblRetorno = 0;
    let parametros: any[] = [];
    parametros[0]=strActivo;
    parametros[1]=strFecha;
    try {
      const rs =await this.actencsalidaService.depreciacionAcumulada(parametros);
      for (const res of rs) {
        dblRetorno = res.DETKARDEX_IMPORTE === 0 ? 0 : Number(Number(res.DETKARDEX_IMPORTE).toFixed(2));
      }
      
    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso FunObtieneDepreciacionAcumulada no terminó satisfactoriamente.... ! ' + err.error);		
    }	
    return dblRetorno;
  }


  isEdit(data): boolean {
    if (this.txtEsta === 'P') {
      return true;
    } else {
      return false;
    }
  }

  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }

  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'actencsalida',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos  = false;
    }
  }

  mensajeAlertaClave(tipo, titulo, msg) {
    this.message.add({
      key: 'actencsalidaclave',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

}
