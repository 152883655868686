import { Injectable } from '@angular/core';
import {CxcformaPago} from '../cxcinterfaces/cxcforma-pago';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Observable} from 'rxjs';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class CxcformaPagoService {

  public way: string;
  private _formaPagoServicio: CxcformaPago[];
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService)
   {
                this.way = sessionStorage.getItem('way');
    }

  get formaPagoServicio(): CxcformaPago[] {
    return this._formaPagoServicio;
  }

  set formaPagoServicio(value: CxcformaPago[]) {
    this._formaPagoServicio = value;
  }
  getFormaPagoServicio(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/formapago/83bafc6ecacaa00', {
        elementos: {},
    }, this.confIniciales.httpOptions());
  }

  actualizarFormaPago(formaPago: CxcformaPago, codigoAux): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/formapago/2996ecacadf2ab2', {
      elementos: {
        p_FORMAPAGO_tipo_1: formaPago.FORMAPAGO_TIPO,
        p_FORMAPAGO_nombre_4: formaPago.FORMAPAGO_NOMBRE,
        p_FORMAPAGO_caja_3: formaPago.CAJ_CODIGO,
        p_FORMAPAGO_tipo_anterior: codigoAux,
        p_COM_codigo_5: '01',
        p_FORMAPAGO_CODIGOSRI: formaPago.FORMAPAGO_CODIGOSRI
      }
    }, this.confIniciales.httpOptions());
  }

  insertarFormaPago(formaPago: CxcformaPago): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/formapago/12bf1dbeab6', {
      elementos: {
        p_FORMAPAGO_tipo_1: formaPago.FORMAPAGO_TIPO,
        p_FORMAPAGO_NOMBRE_2: formaPago.FORMAPAGO_NOMBRE,
        p_FORMAPAGO_caja_3: formaPago.CAJ_CODIGO,
        p_COM_codigo_3: '01',
        p_FORMAPAGO_CODIGOSRI: formaPago.FORMAPAGO_CODIGOSRI
      }
    }, this.confIniciales.httpOptions());
  }
  eliminarFormaPago(formaPago: CxcformaPago): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/formapago/eacad2bab6fc70', {
      elementos: {
        p_FORMAPAGO_tipo_1: formaPago.FORMAPAGO_TIPO,
        p_COM_codigo_2: '01',
      }
    }, this.confIniciales.httpOptions());
  }
}
