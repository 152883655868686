import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { UtilitariosService } from './utilitarios.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class AuditoriagralService {
 
  public way: string;

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private utilitariosService: UtilitariosService,
    private confIniciales: ConfInicialesService) {
    
    this.way = sessionStorage.getItem('way');
  }

  registrarAuditoria(strObjeto: string, strDato: string, strOperacion: string,
    usuIdentificacion: string, comCodigo: string, audQuien: string,
    audTipoElimina: string, audIdEquipo: string, audFechaHora: string): Observable<any[]> {
    if (strDato === '' || strDato === null || strDato === undefined) {
      strDato = 'NOHAYDATO';
    }
    return this.http.post<any>(this.way + '/reporte/auditoria/af365dc6e4a', {
      elementos: {
        p_AUD_objeto_1: strObjeto + '_W',
        p_AUD_dato_2: strDato,
        p_AUD_operacion_3: strOperacion,
        p_USU_identificacion_4: this.usuario.identificacion,
        p_COM_codigo_5: comCodigo,
        p_AUD_quien_6: this.usuario.identificacion,
        p_AUD_tipoelimina_7: audTipoElimina,
        P_AUD_Serial: audIdEquipo,
        p_AUD_fecha_hora: audFechaHora,
        p_AUD_equipo: this.utilitariosService.getHostname(),
        p_AUD_ippublica: this.utilitariosService.getIpFrontend()
      }
    }, this.confIniciales.httpOptions());
  }

  registrarAuditoriaPromise(strObjeto: string, strDato: string, strOperacion: string,
    usuIdentificacion: string, comCodigo: string, audQuien: string,
    audTipoElimina: string, audIdEquipo: string, audFechaHora: string): Promise<any[]> {
    if (strDato === '' || strDato === null || strDato === undefined) {
      strDato = 'NOHAYDATO';
    }
    return this.http.post<any>(this.way + '/reporte/auditoria/af365dc6e4a', {
      elementos: {
        p_AUD_objeto_1: strObjeto + '_W',
        p_AUD_dato_2: strDato,
        p_AUD_operacion_3: strOperacion,
        p_USU_identificacion_4: this.usuario.identificacion,
        p_COM_codigo_5: comCodigo,
        p_AUD_quien_6: this.usuario.identificacion,
        p_AUD_tipoelimina_7: audTipoElimina,
        P_AUD_Serial: audIdEquipo,
        p_AUD_fecha_hora: audFechaHora,
        p_AUD_equipo: this.utilitariosService.getHostname(),
        p_AUD_ippublica: this.utilitariosService.getIpFrontend()
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerConfiguracion1() {
    return this.http.post<any[]>(this.way + '/seg/usuario/41dd662ef943dc0000000000', {
      elementos: {}
    }, this.confIniciales.httpOptions());
  }

  obtenerConfiguracionProm() {
    return this.http.post<any[]>(this.way + '/seg/usuario/41dd662ef943dc0000000000', {
      elementos: {}
    }, this.confIniciales.httpOptions()).toPromise();
  }

  transformarFecha(fecha): string {
    let anio = '';
    let mes = '';
    let dia = '';
    let resto = '';
    let tamano = 0;

    resto = fecha;
    tamano = resto.indexOf('-');
    anio = resto.substr(0, tamano);
    resto = resto.substr(tamano + 1);

    tamano = resto.indexOf('-');
    mes = resto.substr(0, tamano);
    resto = resto.substr(tamano + 1);

    dia = resto;
    return dia + '/' + mes + '/' + anio;
  }


  consultarAuditoria(strConsulta): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/seg/auditoria/3317e7aaaf365c', {
      elementos: {
        CONSULTA: strConsulta
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarAuditoria(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/seg/auditoria/3ab2b4aeaf365c', {
      elementos: {
      }
    }, this.confIniciales.httpOptions());
  }

  insertarAuditoria(strConsulta): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/seg/auditoria/4afc76faaf365c', {
      elementos: {
        CONSULTA: strConsulta
      }
    }, this.confIniciales.httpOptions());
  }

  obtenermodulos(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/seg/auditoria/c2faebdb0df5700', {
      elementos: {}
    }, this.confIniciales.httpOptions());
  }

  obtenerObjetos(strObjeto): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/seg/auditoria/617d75f0b9bbb8', {
      elementos: {
        AUD_OBJETO: strObjeto
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerIdentificacion(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/seg/auditoria/c2faebc9aebf680', {
      elementos: {}
    }, this.confIniciales.httpOptions());
  }

  obtenerOperacion(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/seg/auditoria/c2faebe32eda980', {
      elementos: {}
    }, this.confIniciales.httpOptions());
  }


}
