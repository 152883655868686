import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {SaciMaeNumeracion} from '../seginterfaces/segmaeusuario';

@Injectable({
  providedIn: 'root'
})
export class SegcambioclaveService {
  
  private way: string;
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }
  
  
  verficarClave(claveActual) {
    const  strSentencia = `select usuidentificacion from seg_maeusuario where usuclave = '${claveActual}' and usuidentificacion = '${this.usuario.identificacion}'`;
    
    return this.http.post<any[]>(this.way + '/seg/cambioclave/3eedc9ecaabee', {
        elementos: {
          USU_CLAVE: claveActual,
          USU_IDENTIFICACION: this.usuario.identificacion
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  cambioclave(claveNueva) {
    const  strSentencia = `Update SEG_MAEUSUARIO Set USUCLAVE = '${claveNueva}' Where USUIDENTIFICACION = '${this.usuario.identificacion}'`;
    return this.http.post<any[]>(this.way + '/seg/cambioclave/533be5564caac00', {
        elementos: {
          USUCLAVE: claveNueva,
          USUIDENTIFICACION: this.usuario.identificacion
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
}
