import {Component, OnInit, ViewChild} from '@angular/core';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {AggridBanComponent} from '../aggrid-ban/aggrid-ban.component';
import {BantrnbanService} from '../banservicios/bantrnban.service';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {DatePipe} from '@angular/common';
import {MessageService, SelectItem} from 'primeng';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {BanTrnbanN} from '../baninterfaces/bantrnbanN';

@Component({
  selector: 'app-bantrnban',
  templateUrl: './bantrnban.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class BantrnbanComponent implements OnInit {
  
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;
  btnBuscar: boolean;
  spin = false;
  
  largo: string;
  public frameworkComponents;
  rowSelection = 'multiple';
  rowStyle;
  defaultBanTrnBan;
  
  where = null;
  indice = 0;
  
  displayDialogBanTrnBan: boolean;
  
  banCodigoTrnBan: string;
  fechaEmisionTrnBan: string;
  origenTrnBan: string;
  tipoTrnBan: string;
  anuladoTrnBan: number;
  trnbanNumeroTrnBan: string;
  referenciaTrnBan: string;
  conceptoTrnBan: string;
  importeTrnBan: string;
  beneficiarioTrnBan: string;
  nroDocumentoTrnBan: string;
  conciliaTrnBan: number;
  fechaVencimientoTrnBan: string;
  asiNroTrnBan: string;
  numeroConciliacionTrnBan: string;
  regContableTrnBan: boolean;
  asientoTrnBan: boolean;
  isBanderaTrnBan: boolean;
  tipoAccionTrnBan: string;
  
  displayDialogBus: boolean;
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  condicion: string;
  opcion: string;
  
  boolDialog: boolean;
  displayAsiContable: boolean;
  
  NumAsiRegCont: string;
  
  detalleSelected: BanTrnbanN;
    
    columnDefsBanTrnBan = [
    {
      headerName: 'Banco', field: 'BAN_CODIGO', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'left'},
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnBanEdit(params.data);
      }
    },
    {
      headerName: 'Fecha Emi.', field: 'TRNBAN_FECHA', cellEditor: 'cellPrueba', width: 130, cellStyle: {textAlign: 'left'},
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnBanEdit(params.data);
      }
    },
    {
      headerName: 'ORIG', field: 'TRNBAN_ORIGEN', cellEditor: 'cellPrueba', width: 80, cellStyle: {textAlign: 'left'},
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnBanEdit(params.data);
      }
    },
    {
      headerName: 'Tipo', field: 'TRNBAN_TIPO', cellEditor: 'cellPrueba', width: 80, cellStyle: {textAlign: 'left'},
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnBanEdit(params.data);
      }
    },
    {
      headerName: 'AN', field: 'TRNBAN_ANULADO', cellRendererFramework: AggridBanComponent, width: 60,
      editable: false, checkCierre: true, cellStyle: {textAlign: 'left'},
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnBanEdit(params.data);
      }
    },
    {
      headerName: 'Número', field: 'TRNBAN_NUMERO', cellEditor: 'cellPrueba', width: 110, cellStyle: {textAlign: 'left'},
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnBanEdit(params.data);
      }
    },
    {
      headerName: 'Referencia', field: 'TRNBAN_NUMEROREFUSER', width: 110, cellEditor: 'cellPrueba', cellStyle: {textAlign: 'left'},
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnBanEdit(params.data);
      }
    },
    {
      headerName: 'Concepto', field: 'TRNBAN_CONCEPTO', cellEditor: 'cellPrueba', width: 200, cellStyle: {textAlign: 'left'},
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnBanEdit(params.data);
      }
    },
    {
      headerName: 'Debe', field: 'TRNBAN_DEBE', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right'},
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnBanEdit(params.data);
      }
    },
    {
      headerName: 'Haber', field: 'TRNBAN_HABER', width: 100, cellEditor: 'cellPrueba', cellStyle: {textAlign: 'right'},
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnBanEdit(params.data);
      }
    },
    {
      headerName: 'Fecha Venc.', field: 'TRNBAN_FECHAVENCE', cellEditor: 'cellPrueba', width: 125, cellStyle: {textAlign: 'left'},
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnBanEdit(params.data);
      }
    },
    {
      headerName: 'Nombre', field: 'TRNBAN_BENEFICIARIO', cellEditor: 'cellPrueba', width: 200, cellStyle: {textAlign: 'left'},
      onCellDoubleClicked: (params) => {
        this.abrirBanTrnBanEdit(params.data);
      }
    }
  ];

  constructor(public bantrnbanService: BantrnbanService, private confIniciales: ConfInicialesService,
              private datePipe: DatePipe, private busquedaSer: InvbusquedaService, private init: NuevoComponentService,
              private message: MessageService) {
    this.agTable();
    this.bantrnbanService.banTrnban = [];
    this.detalleSelected = {};
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    
    this.defaultBanTrnBan = {
      editable: false,
      objeto: 'bantrnban',
      resizable: true
    };
  }

  ngOnInit() {
    
    this.detalleSelected = {};
    this.displayDialogBanTrnBan = false;
    this.boolDialog = false;
    this.btnNuevo = false;
    this.btnBorrar = true;
    this.spin = false;
    this.largo = '250';
    this.opcion = '';
    
    this.displayDialogBus = false;
    this.arregloBus = [];
    
    this.consultarMovBan(null);
  
    this.displayAsiContable = false;
    
  }
      //CDPJ
      ngOnDestroy(): void {
        this.confIniciales.instanciasMoviBancos--;
      }
      //CDPJ
  async manejarSenales(valor) {
    if (valor === 'Nuevo') {
      this.tipoAccionTrnBan = 'new';
      this.asiNroTrnBan = '';
      this.banCodigoTrnBan = '';
      this.fechaEmisionTrnBan = String(new Date());
      this.origenTrnBan = 'BAN';
      this.tipoTrnBan = '';
      this.anuladoTrnBan = 0;
      this.trnbanNumeroTrnBan = '';
      this.referenciaTrnBan = '';
      this.conceptoTrnBan  = '';
      this.importeTrnBan =  Number(0).toFixed(this.confIniciales.getNumDecSist());
      
  
      this.beneficiarioTrnBan =  '';
      this.nroDocumentoTrnBan = '';
      this.conciliaTrnBan = 0;
      this.fechaVencimientoTrnBan = String(new Date());
      this.numeroConciliacionTrnBan = '';
      this.regContableTrnBan = false;
      this.asientoTrnBan = false;
      this.isBanderaTrnBan = true;
      this.displayDialogBanTrnBan = true;
    }

    if (valor === 'Cancelar') {
      this.consultarMovBan(null);
    }
    
    if (valor === 'Guardar') {
    
    }
  
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  
  
    if (valor === 'Buscar') {

      this.spin = true;
      await this.consultarMovBan(null);
      await this.buscarRegistro();
      this.spin = false;
    }
  
    if (valor === 'AsientoVer') {
      this.verAsientoContable();
    }
  }
  
  async consultarMovBan(where) {
    let filtro = '';
    const opcion = 'T';
    const condicion = '';
    if (where === null) {
      filtro = 'COM_codigo = 01';
    }
    
    if (where !== null) {
      filtro = where;
    }
    
    const movBan = await this.bantrnbanService.consultarMovBancosProm(opcion, filtro, condicion)
      this.bantrnbanService.banTrnban = movBan;
      
      this.bantrnbanService.banTrnban.map((ban) => {
        ban.TRNBAN_FECHA = this.datePipe.transform(ban.TRNBAN_FECHA , 'dd/MM/yyyy');
        ban.TRNBAN_FECHAVENCE = this.datePipe.transform(ban.TRNBAN_FECHAVENCE, 'dd/MM/yyyy');
        ban.TRNBAN_ANULADO = ban.TRNBAN_ANULADO === 0 ? false : true;
        if (Number(ban.TRNBAN_IMPORTE) > 0) {
          ban.TRNBAN_DEBE = ban.TRNBAN_IMPORTE;
          ban.TRNBAN_HABER = 0.0;
        } else {
          ban.TRNBAN_DEBE = 0.0;
          ban.TRNBAN_HABER = ban.TRNBAN_IMPORTE * -1;
        }
  
        ban.TRNBAN_DEBE  = ban.TRNBAN_DEBE.toFixed(this.confIniciales.getNumDecSist());
        ban.TRNBAN_HABER = ban.TRNBAN_HABER.toFixed(this.confIniciales.getNumDecSist());
      });
      this.aggrid.refreshColumnDefs();
    
  }
  
  recibirSenalesBanTrnban(valor) {
    if (valor === true) {
      this.consultarMovBan(null);
      this.displayDialogBanTrnBan = false;
      this.boolDialog = false;
    }
  }
  
  abrirBanTrnBanEdit(dato) {
    this.asiNroTrnBan = dato.ASI_NRO;
    this.banCodigoTrnBan = dato.BAN_CODIGO;
    this.fechaEmisionTrnBan = dato.TRNBAN_FECHA;
    this.origenTrnBan = dato.TRNBAN_ORIGEN;
    this.tipoTrnBan = dato.TRNBAN_TIPO;
    this.anuladoTrnBan = dato.TRNBAN_ANULADO === true ? 1 : 0;
    this.trnbanNumeroTrnBan = dato.TRNBAN_NUMERO;
    this.referenciaTrnBan = dato.TRNBAN_NUMEROREFUSER;
    this.conceptoTrnBan  = dato.TRNBAN_CONCEPTO;
    if (Number(dato.TRNBAN_DEBE) > 0) {
      this.importeTrnBan = Number(dato.TRNBAN_DEBE).toFixed(this.confIniciales.getNumDecSist());
    } else {
      this.importeTrnBan = Number(dato.TRNBAN_HABER * -1).toFixed(this.confIniciales.getNumDecSist());
    }
    
    this.beneficiarioTrnBan =  dato.TRNBAN_BENEFICIARIO;
    this.nroDocumentoTrnBan = dato.TRNBAN_DOCNRO ;
    this.conciliaTrnBan = dato.TRNBAN_CONCILIA;
    this.fechaVencimientoTrnBan = dato.TRNBAN_FECHAVENCE;
    this.numeroConciliacionTrnBan = dato.ENCCONCIL_NUMERO;
    this.regContableTrnBan = false;
    this.asientoTrnBan = false;
    this.tipoAccionTrnBan = 'view';
    this.isBanderaTrnBan = false;
    this.boolDialog = true;
    this.displayDialogBanTrnBan = true;
    
  }
  
  manejarSeleccion(valor) {
    if (this.opcion === 'BUSCAR') {
      this.displayDialogBusquedaFast = false;
      this.llenarTabla(valor);
    }
  }
  
  buscarRegistro() {
    this.opcion = 'BUSCAR';
    this.types = [
      {label: 'BANCO', value: 'BAN_CODIGO'},
      {label: 'TIPO', value: 'TRNBAN_TIPO'},
      {label: 'NÚMERO', value: 'TRNBAN_NUMERO'},
      {label: 'FECHA', value: 'TRNBAN_FECHA'},
      {label: 'BENEFICIARIO', value: 'TRNBAN_BENEFICIARIO'},
    ];
  
    this.busquedacampos = ['', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'BAN_TRNBAN';
    this.condicion = 'TRNBAN_TIPO NOT LIKE \'AN\' ORDER BY TRNBAN_fecha DESC,TRNBAN_numero DESC';
    this.arregloCons = [];
    this.displayDialogBusquedaFast = true;
  }
  
  llenarTabla(datos) {
    this.bantrnbanService.banTrnban = datos;
    this.bantrnbanService.banTrnban.map((ban) => {
      ban.TRNBAN_FECHA = this.datePipe.transform(ban.TRNBAN_FECHA , 'dd/MM/yyyy');
      ban.TRNBAN_FECHAVENCE = this.datePipe.transform(ban.TRNBAN_FECHAVENCE, 'dd/MM/yyyy');
      ban.TRNBAN_ANULADO = ban.TRNBAN_ANULADO === 0 ? false : true;
      if (Number(ban.TRNBAN_IMPORTE) > 0) {
        ban.TRNBAN_DEBE = ban.TRNBAN_IMPORTE;
        ban.TRNBAN_HABER = 0.0;
      } else {
        ban.TRNBAN_DEBE = 0.0;
        ban.TRNBAN_HABER = ban.TRNBAN_IMPORTE * -1;
      }
    
      ban.TRNBAN_DEBE  = ban.TRNBAN_DEBE.toFixed(this.confIniciales.getNumDecSist());
      ban.TRNBAN_HABER = ban.TRNBAN_HABER.toFixed(this.confIniciales.getNumDecSist());
    });
    this.aggrid.refreshColumnDefs();
  }
  
  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }
  
  
  seleccionItem(valor) {
    if (valor.object !== undefined) {
      this.detalleSelected = valor.object;
    }
  }
  
  verAsientoContable() {
    if (this.detalleSelected.ASI_NRO !== '' && this.detalleSelected.ASI_NRO !== null) {
      this.NumAsiRegCont = this.detalleSelected.ASI_NRO;
      this.displayAsiContable = true;
    } else {
      this.mensajeAlerta('info', 'Información', 'No ha generado el Asiento Contable');
    }
    
  }
  
  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'BanTrnBanN',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
  
  CerrarTrnBanEdit(evento) {
    this.consultarMovBan(null);
  }
  

}
