<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="actmaegrupo"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="actmaegrupoConf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="actmaegrupoService.btnNuevo[indicador]" 
		[botonGuardar]="actmaegrupoService.btnGuardar[indicador]"
		 [botonBorrar]="actmaegrupoService.btnBorrar[indicador]"
						 [barraBotonesDesplaz]="true"  [barraBotonesCRUD]="false"
						 [barraBotones3]="true" [barraBotonesAsiCon]="true"
						 [botonBinocular]="true" 
						 [botonBuscar]="actmaegrupoService.btnBuscar[indicador]"
						 [botonImprimir]="false"
						 (signal)="manejarSenales($event)"
						></app-invmaebarra>


	</div>


<p-tabView>
	<p-tabPanel header="Grupos">
		<div style="overflow: hidden" class="p-col-9">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="false"
							[enableFilter]="true"
							[rowData]="actmaegrupoService.detmaeGrupoArray[indicador]"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefActMaeGrupo"
							[defaultColDef]="defaultActMaeGrupo"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)="selectActMaeGrupo($event)"
							(cambios)="cambio($event)"

			></app-aggridsaci>
		</div>
	</p-tabPanel>
</p-tabView>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '35vh'} ">
	<app-busquedadlgmae [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
						[where]="condicion"
						[busquedaParams]="busquedacampos"
						(opcionSeleccionada)="manejarSeleccion($event)"
						[busquedaCompuesta]="false"></app-busquedadlgmae>
</p-dialog>

