<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="contfacpro"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="contdocConf" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"
						 [barraBotones5]="true" [botonVisto]="false" [barraBotonesCRUD]="true" [barraBotones3Alt]="false"
						 (signal)="manejarSenales($event)"
						 style="text-align: left;">
		</app-invmaebarra>
	</div>
</div>

<div style="overflow: hidden" class="p-col-12">
	<p-tabView (onChange)="onTab1Change($event)">
		<p-tabPanel header="BAN">
			<p-tabView (onChange)="onTab2Change($event)">
				<p-tabPanel header="Movimientos de Bancos">
					<div class="divgrups">
						<div class="p-grid">
							<!--LINE 1-->
							<div class="p-col-1">
								<span>Fecha Inicial:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-calendar id="banMovBanFechaInicial" [inputStyle]="{width: '100%', height: '20px'}"
											[(ngModel)]="banMovBanFechaInicial"
											dateFormat="dd/mm/yy">
								</p-calendar>
							</div>
							<div class="p-col-2" style="text-align: left;">
								<p-radioButton name="banMovBanTipo" value="T" label="Todo" [(ngModel)]="banMovBanTipo">
								</p-radioButton>
							</div>
							<div class="p-col-2">
								<span>Tipo de Documento:</span>
							</div>
							<div class="p-col-1">
								<p-dropdown [style]="{'width':'100%'}" [(ngModel)]="strTipoMovBan"
											optionLabel="name" [options]="cmbTipoMovBan" [panelStyle]="{'width':'100%'}"
											inputId="tipoBanMovBan">
								</p-dropdown>
							</div>
							<div class="p-col-3"></div>

							<!--LINE 2-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-1">
										<span>Fecha Final:</span>
									</div>
									<div class="p-col-2" style="text-align: left">
										<p-calendar id="banMovBanFechaFinal"
													[inputStyle]="{width: '100%', height: '20px'}"
													[(ngModel)]="banMovBanFechaFinal"
													dateFormat="dd/mm/yy">
										</p-calendar>
									</div>
									<div class="p-col-2" style="text-align: left;">
										<p-radioButton name="banMovBanTipo" value="N" [(ngModel)]="banMovBanTipo"
													   label="No Contabilizado">
										</p-radioButton>
									</div>
									<div class="p-col-2"></div>
									<div class="p-col-1">
										<button (click)="consultarDocumentos('banMovBan')" pButton class="aceptar"
												style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">
											Visualizar
										</button>
										<!--								<p-button label="Visualizar" (click)="consultarDocumentos('banMovBan')"> </p-button>-->
									</div>
									<div class="p-col-3"></div>
								</div>
							</div>

							<!--LINE 3-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-4" style="text-align: left;">
										<span style="font-weight: bold">Documentos a contabilizar</span>
									</div>
									<div class="p-col-8"></div>
								</div>
							</div>

							<!--LINE 4-->
							<div class="p-col-12" style=" margin-bottom: -15px;">
								<div class="p-grid">
									<div class="p-col-2" style="text-align: left;">
										<p-checkbox binary="true" label="Seleccionar Todos"
													[(ngModel)]="banMovBanSeleccionarTodo"
													(click)="seleccionarTodo('banMovBan')">
										</p-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
											[rowData]="contabilizadocService.movimientosBancos"
											[frameworkComponents]="frameworkComponents" [animateRows]="true"
											[rowSelection]="rowSelection" [columnDefs]="columnDefsBanMovBan"
											[defaultColDef]="defaultColConBanMovBan" [rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false">

							</app-aggridsaci>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-1">
							<div class="cuadrado" style="width: 80px; height: 25px;background: #ffff99;"></div>
						</div>
						<div class="p-col-4" style="text-align: left;">
							<span>Documentos contabilizados</span>
						</div>
						<div class="p-col-7"></div>
						<div class="p-col-12">
							<p-progressBar [value]="porcentaje"></p-progressBar>
						</div>
					</div>
				</p-tabPanel>
				<p-tabPanel header="Movimientos de Caja">
					<div class="divgrups">
						<div class="p-grid">
							<!--LINE 1-->
							<div class="p-col-1">
								<span>Fecha Inicial:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-calendar id="banMovCajaFechaInicial" [inputStyle]="{width: '100%', height: '20px'}"
											[(ngModel)]="banMovCajaFechaInicial"
											dateFormat="dd/mm/yy">
								</p-calendar>
							</div>
							<div class="p-col-2" style="text-align: left;">
								<p-radioButton name="banMovCajaTipo" value="T" label="Todo"
											   [(ngModel)]="banMovCajaTipo">
								</p-radioButton>
							</div>
							<div class="p-col-2">
								<span>Tipo de Documento:</span>
							</div>
							<div class="p-col-1">
								<p-dropdown [style]="{'width':'100%'}" [(ngModel)]="strTipoMovCaj"
											optionLabel="name" [options]="cmbTipoMovCaj" [panelStyle]="{'width':'100%'}"
											inputId="tipoBanMovCaj">
								</p-dropdown>
							</div>
							<div class="p-col-3"></div>
							<!--LINE 2-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-1">
										<span>Fecha Final:</span>
									</div>
									<div class="p-col-2" style="text-align: left">
										<p-calendar id="banMovCajaFechaFinal"
													[inputStyle]="{width: '100%', height: '20px'}"
													[(ngModel)]="banMovCajaFechaFinal"
													dateFormat="dd/mm/yy">
										</p-calendar>
									</div>
									<div class="p-col-2" style="text-align: left;">
										<p-radioButton name="banMovCajaTipo" value="N" [(ngModel)]="banMovCajaTipo"
													   label="No Contabilizado">
										</p-radioButton>
									</div>
									<div class="p-col-2"></div>
									<div class="p-col-1">
										<button (click)="consultarDocumentos('banMovCaja')" pButton class="aceptar"
												style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">
											Visualizar
										</button>
										<!--								<p-button label="Visualizar" (click)="consultarDocumentos('banMovCaja')"> </p-button>-->
									</div>
									<div class="p-col-3"></div>
								</div>
							</div>
							<!--LINE 3-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-4" style="text-align: left;">
										<span style="font-weight: bold">Documentos a contabilizar</span>
									</div>
									<div class="p-col-8"></div>
								</div>
							</div>
							<!--LINE 4-->
							<div class="p-col-12" style=" margin-bottom: -15px;">
								<div class="p-grid">
									<div class="p-col-2" style="text-align: left;">
										<p-checkbox binary="true" label="Seleccionar Todos"
													[(ngModel)]="banMovCajaSeleccionarTodo"
													(click)="seleccionarTodo('banMovCaja')">
										</p-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
											[rowData]="contabilizadocService.movimientosCaja"
											[frameworkComponents]="frameworkComponents" [animateRows]="true"
											[rowSelection]="rowSelection" [columnDefs]="columnDefsBanMovCaj"
											[defaultColDef]="defaultColConBanMovCaj" [rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false">

							</app-aggridsaci>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-1">
							<div class="cuadrado" style="width: 80px; height: 25px;background: #ffff99;"></div>
						</div>
						<div class="p-col-4" style="text-align: left;">
							<span>Documentos contabilizados</span>
						</div>
						<div class="p-col-7"></div>
						<div class="p-col-12">
							<p-progressBar [value]="porcentaje"></p-progressBar>
						</div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-tabPanel>
		<p-tabPanel header="COM">
			<p-tabView (onChange)="onTab2Change($event)">
				<p-tabPanel header="Facturas de Proveedores">
					<div class="divgrups">
						<div class="p-grid">
							<!--LINE 1-->
							<div class="p-col-1">
								<span>Fecha Inicial:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-calendar id="comFacProfechaInicial" [inputStyle]="{width: '100%', height: '20px'}"
											dateFormat="dd/mm/yy" [(ngModel)]="comFacProfechaInicial">
								</p-calendar>
							</div>
							<div class="p-col-2" style="text-align: left;">
								<p-radioButton name="comFacProTipo" value="T" [(ngModel)]="comFacProTipo" label="Todo">
								</p-radioButton>
							</div>
							<div class="p-col-6"></div>
							<!--LINE 2-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-1">
										<span>Fecha Final:</span>
									</div>
									<div class="p-col-2" style="text-align: left">
										<p-calendar id="comFacProfechaFinal"
													[inputStyle]="{width: '100%', height: '20px'}"
													dateFormat="dd/mm/yy" [(ngModel)]="comFacProfechaFinal">
										</p-calendar>
									</div>
									<div class="p-col-2" style="text-align: left;">
										<p-radioButton name="comFacProTipo" value="N" [(ngModel)]="comFacProTipo"
													   label="No Contabilzado">
										</p-radioButton>
									</div>
									<div class="p-col-2"></div>
									<div class="p-col-1">
										<button (click)="consultarDocumentos('comfacpro')" pButton class="aceptar"
												style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">
											Visualizar
										</button>
										<!--								<p-button label="Visualizar" (click)="consultarDocumentos('comfacpro')"> </p-button>-->
									</div>
									<div class="p-col-3"></div>
								</div>
							</div>
							<!--LINE 3-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-4" style="text-align: left;">
										<span style="font-weight: bold">Documentos a contabilizar</span>
									</div>
									<div class="p-col-8"></div>
								</div>
							</div>
							<!--LINE 4-->
							<div class="p-col-12" style=" margin-bottom: -15px;">
								<div class="p-grid">
									<div class="p-col-2" style="text-align: left;">
										<p-checkbox binary="true" label="Seleccionar Todos"
													[(ngModel)]="comFacProSeleccionarTodo"
													(click)="seleccionarTodo('comfacpro')">
										</p-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
											[rowData]="contabilizadocService.facturasProveedores"
											[frameworkComponents]="frameworkComponents" [animateRows]="true"
											[rowSelection]="rowSelection" [columnDefs]="columnDefsComFactPro"
											[defaultColDef]="defaultColConFactPro" [rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false"></app-aggridsaci>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-1">
							<div class="cuadrado" style="width: 80px; height: 25px;background: #ffff99;"></div>
						</div>
						<div class="p-col-4" style="text-align: left;">
							<span>Documentos contabilizados</span>
						</div>
						<div class="p-col-7"></div>
						<div class="p-col-12">
							<p-progressBar [value]="porcentaje"></p-progressBar>
						</div>
					</div>
				</p-tabPanel>
				<p-tabPanel header="Devoluciones de Compra">
					<div class="divgrups">
						<div class="p-grid">
							<!--LINE 1-->
							<div class="p-col-1">
								<span>Fecha Inicial:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-calendar id="comDevComProfechaInicial" [inputStyle]="{width: '100%', height: '20px'}"
											dateFormat="dd/mm/yy" [(ngModel)]="comDevComProfechaInicial">
								</p-calendar>
							</div>
							<div class="p-col-2" style="text-align: left;">
								<p-radioButton name="comDevComTipo" value="T" [(ngModel)]="comDevComTipo" label="Todo">
								</p-radioButton>
							</div>
							<div class="p-col-6"></div>
							<!--LINE 2-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-1">
										<span>Fecha Final:</span>
									</div>
									<div class="p-col-2" style="text-align: left">
										<p-calendar id="comDevComfechaFinal"
													[inputStyle]="{width: '100%', height: '20px'}"
													dateFormat="dd/mm/yy" [(ngModel)]="comDevComfechaFinal">
										</p-calendar>
									</div>
									<div class="p-col-2" style="text-align: left;">
										<p-radioButton name="comDevComTipo" value="N" [(ngModel)]="comDevComTipo"
													   label="No Contabilizado">
										</p-radioButton>
									</div>
									<div class="p-col-2"></div>
									<div class="p-col-1">
										<button (click)="consultarDocumentos('comdevcom')" pButton class="aceptar"
												style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">
											Visualizar
										</button>
										<!--								<p-button label="Visualizar" (click)="consultarDocumentos('comdevcom')"> </p-button>-->
									</div>
									<div class="p-col-3"></div>
								</div>
							</div>
							<!--LINE 3-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-4" style="text-align: left;">
										<span style="font-weight: bold">Documentos a contabilizar</span>
									</div>
									<div class="p-col-8"></div>
								</div>
							</div>
							<!--LINE 4-->
							<div class="p-col-12" style=" margin-bottom: -15px;">
								<div class="p-grid">
									<div class="p-col-2" style="text-align: left;">
										<p-checkbox binary="true" label="Seleccionar Todos"
													[(ngModel)]="comDevComSeleccionarTodo"
													(click)="seleccionarTodo('comdevcom')">
										</p-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
											[rowData]="contabilizadocService.devolucionesProveedores"
											[frameworkComponents]="frameworkComponents" [animateRows]="true"
											[rowSelection]="rowSelection" [columnDefs]="columnDefsComDevCom"
											[defaultColDef]="defaultColComDevCom" [rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false"></app-aggridsaci>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-1">
							<div class="cuadrado" style="width: 80px; height: 25px;background: #ffff99;"></div>
						</div>
						<div class="p-col-4" style="text-align: left;">
							<span>Documentos contabilizados</span>
						</div>
						<div class="p-col-7"></div>
						<div class="p-col-12">
							<p-progressBar [value]="porcentaje"></p-progressBar>
						</div>
					</div>
				</p-tabPanel>
				<p-tabPanel header="Notas de Recepción">
					<div class="divgrups">
						<div class="p-grid">
							<!--LINE 1-->
							<div class="p-col-1">
								<span>Fecha Inicial:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-calendar id="comNotRecFechaInicial" [inputStyle]="{width: '100%', height: '20px'}"
											dateFormat="dd/mm/yy" [(ngModel)]="comNotRecFechaInicial">
								</p-calendar>
							</div>
							<div class="p-col-2" style="text-align: left;">
								<p-radioButton name="comNotRecTipo" value="T" [(ngModel)]="comNotRecTipo" label="Todo">
								</p-radioButton>
							</div>
							<div class="p-col-6"></div>
							<!--LINE 2-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-1">
										<span>Fecha Final:</span>
									</div>
									<div class="p-col-2" style="text-align: left">
										<p-calendar id="comNotRecFechaFinal"
													[inputStyle]="{width: '100%', height: '20px'}"
													dateFormat="dd/mm/yy" [(ngModel)]="comNotRecFechaFinal">
										</p-calendar>
									</div>
									<div class="p-col-2" style="text-align: left;">
										<p-radioButton name="comNotRecTipo" value="N" [(ngModel)]="comNotRecTipo"
													   label="No Contabilizado">
										</p-radioButton>
									</div>
									<div class="p-col-2"></div>
									<div class="p-col-1">
										<button (click)="consultarDocumentos('comnotrec')" pButton class="aceptar"
												style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">
											Visualizar
										</button>
										<!--								<p-button label="Visualizar" (click)="consultarDocumentos('comnotrec')"> </p-button>-->
									</div>
									<div class="p-col-3"></div>
								</div>
							</div>
							<!--LINE 3-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-4" style="text-align: left;">
										<span style="font-weight: bold">Documentos a contabilizar</span>
									</div>
									<div class="p-col-8"></div>
								</div>
							</div>
							<!--LINE 4-->
							<div class="p-col-12" style=" margin-bottom: -15px;">
								<div class="p-grid">
									<div class="p-col-2" style="text-align: left;">
										<p-checkbox binary="true" label="Seleccionar Todos"
													[(ngModel)]="comNotRecSeleccionarTodo"
													(click)="seleccionarTodo('comnotrec')">
										</p-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
											[rowData]="contabilizadocService.notasDeRecepcion"
											[frameworkComponents]="frameworkComponents" [animateRows]="true"
											[rowSelection]="rowSelection" [columnDefs]="columnDefsComNotRec"
											[defaultColDef]="defaultColConNotRec" [rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false"></app-aggridsaci>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-1">
							<div class="cuadrado" style="width: 80px; height: 25px;background: #ffff99;"></div>
						</div>
						<div class="p-col-4" style="text-align: left;">
							<span>Documentos contabilizados</span>
						</div>
						<div class="p-col-7"></div>
						<div class="p-col-12">
							<p-progressBar [value]="porcentaje"></p-progressBar>
						</div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-tabPanel>
		<p-tabPanel header="INV">
			<p-tabView (onChange)="onTab2Change($event)">
				<p-tabPanel header="Entradas/Salidas">
					<div class="divgrups">
						<div class="p-grid">
							<!--LINE 1-->
							<div class="p-col-1">
								<span>Fecha Inicial:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-calendar id="invEntradaSalidaFechaInicial"
											[inputStyle]="{width: '100%', height: '20px'}"
											[(ngModel)]="invEntradaSalidaFechaInicial"
											dateFormat="dd/mm/yy">
								</p-calendar>
							</div>
							<div class="p-col-2" style="text-align: left;">
								<p-radioButton name="invEntradaSalidaTipo" value="T" label="Todo"
											   [(ngModel)]="invEntradaSalidaTipo">
								</p-radioButton>
							</div>
							<div class="p-col-2">
								<span>Tipo de Documento:</span>
							</div>
							<div class="p-col-1">
								<p-dropdown [style]="{'width':'100%'}" [(ngModel)]="strTipoEntradaSalida"
											optionLabel="name" [options]="cmbTipoEntradaSalida"
											[panelStyle]="{'width':'100%'}" inputId="tipoInvEnSal">
								</p-dropdown>
							</div>
							<div class="p-col-3"></div>
							<!--LINE 2-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-1">
										<span>Fecha Final:</span>
									</div>
									<div class="p-col-2" style="text-align: left">
										<p-calendar id="invEntradaSalidaFechaFinal"
													[inputStyle]="{width: '100%', height: '20px'}"
													[(ngModel)]="invEntradaSalidaFechaFinal"
													dateFormat="dd/mm/yy">
										</p-calendar>
									</div>
									<div class="p-col-2" style="text-align: left;">
										<p-radioButton name="invEntradaSalidaTipo" value="N"
													   [(ngModel)]="invEntradaSalidaTipo"
													   label="No Contabilizado">
										</p-radioButton>
									</div>
									<div class="p-col-2"></div>
									<div class="p-col-1">
										<button (click)="consultarDocumentos('invEntradaSalida')" pButton
												class="aceptar"
												style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">
											Visualizar
										</button>
										<!--								<p-button label="Visualizar" (click)="consultarDocumentos('invEntradaSalida')"> </p-button>-->
									</div>
									<div class="p-col-3"></div>
								</div>
							</div>
							<!--LINE 3-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-4" style="text-align: left;">
										<span style="font-weight: bold">Documentos a contabilizar</span>
									</div>
									<div class="p-col-8"></div>
								</div>
							</div>
							<!--LINE 4-->
							<div class="p-col-12" style=" margin-bottom: -15px;">
								<div class="p-grid">
									<div class="p-col-2" style="text-align: left;">
										<p-checkbox binary="true" label="Seleccionar Todos"
													[(ngModel)]="invEntradaSalidaSeleccionarTodo"
													(click)="seleccionarTodo('invEntradaSalida')">
										</p-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
											[rowData]="contabilizadocService.entradaSalida"
											[frameworkComponents]="frameworkComponents" [animateRows]="true"
											[rowSelection]="rowSelection" [columnDefs]="columnDefsInvEnSal"
											[defaultColDef]="defaultColConEntradaSalida" [rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false"></app-aggridsaci>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-1">
							<div class="cuadrado" style="width: 80px; height: 25px;background: #ffff99;"></div>
						</div>
						<div class="p-col-4" style="text-align: left;">
							<span>Documentos contabilizados</span>
						</div>
						<div class="p-col-7"></div>
						<div class="p-col-12">
							<p-progressBar [value]="porcentaje"></p-progressBar>
						</div>
					</div>
				</p-tabPanel>
				<!--				<p-tabPanel header="Transferencias">-->
				<!--					<div class="divgrups">-->
				<!--						<div class="p-grid">-->
				<!--							&lt;!&ndash;LINE 1&ndash;&gt;-->
				<!--							<div class="p-col-1">-->
				<!--								<span>Fecha Inicial:</span>-->
				<!--							</div>-->
				<!--							<div class="p-col-2" style="text-align: left">-->
				<!--								<p-calendar id="invTransferenciaFechaInicial"-->
				<!--											[inputStyle]="{width: '100%', height: '20px'}"-->
				<!--											dateFormat="dd/mm/yy" [(ngModel)]="invTransferenciaFechaInicial">-->
				<!--								</p-calendar>-->
				<!--							</div>-->
				<!--							<div class="p-col-2" style="text-align: left;">-->
				<!--								<p-radioButton name="invTransferenciaTipo" value="T" [(ngModel)]="invTransferenciaTipo"-->
				<!--											   label="Todo">-->
				<!--								</p-radioButton>-->
				<!--							</div>-->
				<!--							<div class="p-col-6"></div>-->
				<!--							&lt;!&ndash;LINE 2&ndash;&gt;-->
				<!--							<div class="p-col-12">-->
				<!--								<div class="p-grid">-->
				<!--									<div class="p-col-1">-->
				<!--										<span>Fecha Final:</span>-->
				<!--									</div>-->
				<!--									<div class="p-col-2" style="text-align: left">-->
				<!--										<p-calendar id="invTransferenciaFechaFinal"-->
				<!--													[inputStyle]="{width: '100%', height: '20px'}"-->
				<!--													dateFormat="dd/mm/yy" [(ngModel)]="invTransferenciaFechaFinal">-->
				<!--										</p-calendar>-->
				<!--									</div>-->
				<!--									<div class="p-col-2" style="text-align: left;">-->
				<!--										<p-radioButton name="invTransferenciaTipo" value="N"-->
				<!--													   [(ngModel)]="invTransferenciaTipo"-->
				<!--													   label="No Contabilizado">-->
				<!--										</p-radioButton>-->
				<!--									</div>-->
				<!--									<div class="p-col-2"></div>-->
				<!--									<div class="p-col-1">-->
				<!--										<button (click)="consultarDocumentos('invTransferencia')" pButton-->
				<!--												class="aceptar"-->
				<!--												style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">-->
				<!--											Visualizar-->
				<!--										</button>-->
				<!--										&lt;!&ndash;								<p-button label="Visualizar" (click)="consultarDocumentos('invTransferencia')"> </p-button>&ndash;&gt;-->
				<!--									</div>-->
				<!--									<div class="p-col-3"></div>-->
				<!--								</div>-->
				<!--							</div>-->
				<!--							&lt;!&ndash;LINE 3&ndash;&gt;-->
				<!--							<div class="p-col-12">-->
				<!--								<div class="p-grid">-->
				<!--									<div class="p-col-4" style="text-align: left;">-->
				<!--										<span style="font-weight: bold">Documentos a contabilizar</span>-->
				<!--									</div>-->
				<!--									<div class="p-col-8"></div>-->
				<!--								</div>-->
				<!--							</div>-->
				<!--							&lt;!&ndash;LINE 4&ndash;&gt;-->
				<!--							<div class="p-col-12" style=" margin-bottom: -15px;">-->
				<!--								<div class="p-grid">-->
				<!--									<div class="p-col-2" style="text-align: left;">-->
				<!--										<p-checkbox binary="true" label="Seleccionar Todos"-->
				<!--													[(ngModel)]="invTransferenciaSeleccionarTodo"-->
				<!--													(click)="seleccionarTodo('invTransferencia')">-->
				<!--										</p-checkbox>-->
				<!--									</div>-->
				<!--								</div>-->
				<!--							</div>-->
				<!--						</div>-->
				<!--					</div>-->
				<!--					<div class="p-grid">-->
				<!--						<div class="p-col-12">-->
				<!--							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"-->
				<!--											[rowData]="contabilizadocService.transferencias"-->
				<!--											[frameworkComponents]="frameworkComponents" [animateRows]="true"-->
				<!--											[rowSelection]="rowSelection" [columnDefs]="columnDefsInvTransferencia"-->
				<!--											[defaultColDef]="defaultColConTranferencia" [rowStyle]="rowStyle"-->
				<!--											[singleClickEdit]="false"-->
				<!--											[botones]="false"></app-aggridsaci>-->
				<!--						</div>-->
				<!--					</div>-->
				<!--					<div class="p-grid">-->
				<!--						<div class="p-col-1">-->
				<!--							<div class="cuadrado" style="width: 80px; height: 25px;background: #ffff99;"></div>-->
				<!--						</div>-->
				<!--						<div class="p-col-4" style="text-align: left;">-->
				<!--							<span>Documentos contabilizados</span>-->
				<!--						</div>-->
				<!--						<div class="p-col-7"></div>-->
				<!--						<div class="p-col-12">-->
				<!--							<p-progressBar [value]="porcentaje"></p-progressBar>-->
				<!--						</div>-->
				<!--					</div>-->
				<!--				</p-tabPanel>-->
				<p-tabPanel header="Toma Física">
					<div class="divgrups">
						<div class="p-grid">
							<!--LINE 1-->
							<div class="p-col-1">
								<span>Fecha Inicial:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-calendar id="invTomaFisicaFechaInicial"
											[inputStyle]="{width: '100%', height: '20px'}"
											dateFormat="dd/mm/yy" [(ngModel)]="invTomaFisicaFechaInicial">
								</p-calendar>
							</div>
							<div class="p-col-2" style="text-align: left;">
								<p-radioButton name="invTomaFisicaTipo" value="T" [(ngModel)]="invTomaFisicaTipo"
											   label="Todo">
								</p-radioButton>
							</div>
							<div class="p-col-6"></div>
							<!--LINE 2-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-1">
										<span>Fecha Final:</span>
									</div>
									<div class="p-col-2" style="text-align: left">
										<p-calendar id="invTomaFisicaFechaFinal"
													[inputStyle]="{width: '100%', height: '20px'}"
													dateFormat="dd/mm/yy" [(ngModel)]="invTomaFisicaFechaFinal">
										</p-calendar>
									</div>
									<div class="p-col-2" style="text-align: left;">
										<p-radioButton name="invTomaFisicaTipo" value="N"
													   [(ngModel)]="invTomaFisicaTipo"
													   label="No Contabilizado">
										</p-radioButton>
									</div>
									<div class="p-col-2"></div>
									<div class="p-col-1">
										<button (click)="consultarDocumentos('invTomaFisica')" pButton class="aceptar"
												style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">
											Visualizar
										</button>
										<!--								<p-button label="Visualizar" (click)="consultarDocumentos('invTomaFisica')"> </p-button>-->
									</div>
									<div class="p-col-3"></div>
								</div>
							</div>
							<!--LINE 3-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-4" style="text-align: left;">
										<span style="font-weight: bold">Documentos a contabilizar</span>
									</div>
									<div class="p-col-8"></div>
								</div>
							</div>
							<!--LINE 4-->
							<div class="p-col-12" style=" margin-bottom: -15px;">
								<div class="p-grid">
									<div class="p-col-2" style="text-align: left;">
										<p-checkbox binary="true" label="Seleccionar Todos"
													[(ngModel)]="invTomaFisicaSeleccionarTodo"
													(click)="seleccionarTodo('invTomaFisica')">
										</p-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
											[rowData]="contabilizadocService.tomasFisicas"
											[frameworkComponents]="frameworkComponents" [animateRows]="true"
											[rowSelection]="rowSelection" [columnDefs]="columnDefsInvTomasFisicas"
											[defaultColDef]="defaultColConTomaFisica" [rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false"></app-aggridsaci>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-1">
							<div class="cuadrado" style="width: 80px; height: 25px;background: #ffff99;"></div>
						</div>
						<div class="p-col-4" style="text-align: left;">
							<span>Documentos contabilizados</span>
						</div>
						<div class="p-col-7"></div>
						<div class="p-col-12">
							<p-progressBar [value]="porcentaje"></p-progressBar>
						</div>
					</div>
				</p-tabPanel>
				<p-tabPanel header="Ajustes">
					<div class="divgrups">
						<div class="p-grid">
							<!--LINE 1-->
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-6">
										<button style="background-image: url(../../../assets/images/iconos/011-checklist.png); height: 40px; width: 40px"
												title="Ejecutar" (click)="confirmarGenerarAsi()">
										</button>
									</div>
									<div class="p-col-6">
										<button style="background-image: url(../../../assets/images/iconos/1-borrar_Asi.png); height: 40px; width: 40px"
												title="Eliminar" (click)="confirmarEliminarReg()">
										</button>
									</div>
								</div>
							</div>
							<div class="p-col-10"></div>
							<!--LINE 2-->
							<div class="p-col-1" style="text-align: left">
								<span>Año:</span>
							</div>
							<div class="p-col-1" style="text-align: left">
								<p-dropdown [style]="{'minWidth': '80px','width': '80px'}" [(ngModel)]="strAnioAjustes"
											optionLabel="name" [options]="cmbAnioAjustes" [panelStyle]="{'width':'50%'}"
											inputId="anioInvAjuste">
								</p-dropdown>
							</div>
							<div class="p-col-1" style="text-align: right">
								<span>Mes:</span>
							</div>
							<div class="p-col-1" style="text-align: left">
								<p-dropdown [style]="{'minWidth': '80px','width': '80px'}" [(ngModel)]="strMesAjustes"
											optionLabel="name" [options]="cmbMesAjustes" [panelStyle]="{'width':'50%'}"
											inputId="mesInvAjuste">
								</p-dropdown>
							</div>
							<div class="p-col-8"></div>
							<!--LINEA3-->
							<div class="p-col-1" style="text-align: left">
								<span>Comentario:</span>
							</div>
							<div class="p-col-5" style="text-align: left">
								<input type="text" pInputText style="background-color: white"
									   [(ngModel)]="strComentarioAjuste">
							</div>
							<div class="p-col-6"></div>
							<!--LINEA4-->
							<div class="p-col-2" style="text-align: left">
								<span>Asiento ajuste positivo:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<input type="text" pInputText style="background-color: white"
									   [(ngModel)]="ajustePositivoAjuste" readonly>
							</div>
							<div class="p-col-8"></div>
							<!--LINEA5-->
							<div class="p-col-2" style="text-align: left">
								<span>Asiento ajuste negativo:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<input type="text" pInputText style="background-color: white"
									   [(ngModel)]="ajusteNegativoAjuste" readonly>
							</div>
							<div class="p-col-8"></div>
						</div>
						<div style="height: 200px"></div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-tabPanel>
		<p-tabPanel header="VEN">
			<p-tabView (onChange)="onTab2Change($event)">
				<p-tabPanel header="Facturas">
					<div class="divgrups">
						<div class="p-grid">
							<!--LINE 1-->
							<div class="p-col-1">
								<span>Fecha Inicial:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-calendar id="venFactFechaInicial" [inputStyle]="{width: '100%', height: '20px'}"
											[(ngModel)]="venFactFechaInicial"
											dateFormat="dd/mm/yy">
								</p-calendar>
							</div>
							<div class="p-col-2" style="text-align: left;">
								<p-radioButton name="venFactTipo" value="T" label="Todo" [(ngModel)]="venFactTipo">
								</p-radioButton>
							</div>
							<div class="p-col-2" style="text-align: right">
								<span>Bodega:</span>
							</div>
							<div class="p-col-2">
								<p-dropdown [style]="{'width':'100%'}" [(ngModel)]="strBodegaFactura"
											optionLabel="name" [options]="cmbBodegaFactura"
											[panelStyle]="{'width':'100%'}" inputId="bodegaFactura">
								</p-dropdown>
							</div>
							<div class="p-col-2"></div>
							<!--LINE 2-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-1">
										<span>Fecha Final:</span>
									</div>
									<div class="p-col-2" style="text-align: left">
										<p-calendar id="venFactFechaFinal"
													[inputStyle]="{width: '100%', height: '20px'}"
													[(ngModel)]="venFactFechaFinal"
													dateFormat="dd/mm/yy">
										</p-calendar>
									</div>
									<div class="p-col-2" style="text-align: left;">
										<p-radioButton name="venFactTipo" value="N" [(ngModel)]="venFactTipo"
													   label="No Contabilizado">
										</p-radioButton>
									</div>
									<div class="p-col-2"></div>
									<div class="p-col-1">
										<button (click)="consultarDocumentos('venFactura')" pButton class="aceptar"
												style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">
											Visualizar
										</button>
										<!--								<p-button label="Visualizar" (click)="consultarDocumentos('venFactura')"> </p-button>-->
									</div>
									<div class="p-col-3"></div>
								</div>
							</div>
							<!--LINE 3-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-4" style="text-align: left;">
										<span style="font-weight: bold">Documentos a contabilizar</span>
									</div>
									<div class="p-col-8"></div>
								</div>
							</div>
							<!--LINE 4-->
							<div class="p-col-12" style=" margin-bottom: -15px;">
								<div class="p-grid">
									<div class="p-col-2" style="text-align: left;">
										<p-checkbox binary="true" label="Seleccionar Todos"
													[(ngModel)]="venFactSeleccionarTodo"
													(click)="seleccionarTodo('venFactura')">
										</p-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
											[rowData]="contabilizadocService.ventasFacturas"
											[frameworkComponents]="frameworkComponents" [animateRows]="true"
											[rowSelection]="rowSelection" [columnDefs]="columnDefsVenFacturas"
											[defaultColDef]="defaultColConFactura" [rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false"></app-aggridsaci>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-1">
							<div class="cuadrado" style="width: 80px; height: 25px;background: #ffff99;"></div>
						</div>
						<div class="p-col-4" style="text-align: left;">
							<span>Documentos contabilizados</span>
						</div>
						<div class="p-col-7"></div>
						<div class="p-col-12">
							<p-progressBar [value]="porcentaje"></p-progressBar>
						</div>
					</div>
				</p-tabPanel>
				<p-tabPanel header="Notas de Crédito">
					<div class="divgrups">
						<div class="p-grid">
							<!--LINE 1-->
							<div class="p-col-1">
								<span>Fecha Inicial:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-calendar id="venNotaCreditoFechaInicial"
											[inputStyle]="{width: '100%', height: '20px'}"
											[(ngModel)]="venNotaCreditoFechaInicial"
											dateFormat="dd/mm/yy">
								</p-calendar>
							</div>
							<div class="p-col-2" style="text-align: left;">
								<p-radioButton name="venNotaCreditoTipo" value="T" label="Todo"
											   [(ngModel)]="venNotaCreditoTipo">
								</p-radioButton>
							</div>
							<div class="p-col-2" style="text-align: right">
								<span>Bodega:</span>
							</div>
							<div class="p-col-2">
								<p-dropdown [style]="{'width':'100%'}" [(ngModel)]="strBodegaNotaCredito"
											optionLabel="name" [options]="cmbBodegaNotaCredito"
											[panelStyle]="{'width':'100%'}" inputId="bodegaNotaCredito">
								</p-dropdown>
							</div>
							<div class="p-col-2"></div>
							<!--LINE 2-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-1">
										<span>Fecha Final:</span>
									</div>
									<div class="p-col-2" style="text-align: left">
										<p-calendar id="venNotaCreditoFechaFinal"
													[inputStyle]="{width: '100%', height: '20px'}"
													[(ngModel)]="venNotaCreditoFechaFinal"
													dateFormat="dd/mm/yy">
										</p-calendar>
									</div>
									<div class="p-col-2" style="text-align: left;">
										<p-radioButton name="venNotaCreditoTipo" value="N"
													   [(ngModel)]="venNotaCreditoTipo"
													   label="No Contabilizado">
										</p-radioButton>
									</div>
									<div class="p-col-2"></div>
									<div class="p-col-1">
										<button (click)="consultarDocumentos('venNotaCredito')" pButton class="aceptar"
												style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">
											Visualizar
										</button>
										<!--								<p-button label="Visualizar" (click)="consultarDocumentos('venNotaCredito')"> </p-button>-->
									</div>
									<div class="p-col-3"></div>
								</div>
							</div>
							<!--LINE 3-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-4" style="text-align: left;">
										<span style="font-weight: bold">Documentos a contabilizar</span>
									</div>
									<div class="p-col-8"></div>
								</div>
							</div>
							<!--LINE 4-->
							<div class="p-col-12" style=" margin-bottom: -15px;">
								<div class="p-grid">
									<div class="p-col-2" style="text-align: left;">
										<p-checkbox binary="true" label="Seleccionar Todos"
													[(ngModel)]="venNotaCreditoSeleccionarTodo"
													(click)="seleccionarTodo('venNotaCredito')">
										</p-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
											[rowData]="contabilizadocService.venNotasCredito"
											[frameworkComponents]="frameworkComponents" [animateRows]="true"
											[rowSelection]="rowSelection" [columnDefs]="columnDefsVenNotasCredito"
											[defaultColDef]="defaultColConNotaCredito" [rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false"></app-aggridsaci>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-1">
							<div class="cuadrado" style="width: 80px; height: 25px;background: #ffff99;"></div>
						</div>
						<div class="p-col-4" style="text-align: left;">
							<span>Documentos contabilizados</span>
						</div>
						<div class="p-col-7"></div>
						<div class="p-col-12">
							<p-progressBar [value]="porcentaje"></p-progressBar>
						</div>
					</div>
				</p-tabPanel>
				<p-tabPanel header="Guías de Remisión">
					<div class="divgrups">
						<div class="p-grid">
							<!--LINE 1-->
							<div class="p-col-1">
								<span>Fecha Inicial:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-calendar id="venGuiaRemiFechaInicial" [inputStyle]="{width: '100%', height: '20px'}"
											[(ngModel)]="venGuiaRemiFechaInicial"
											dateFormat="dd/mm/yy">
								</p-calendar>
							</div>
							<div class="p-col-2" style="text-align: left;">
								<p-radioButton name="venGuiaRemiTipo" value="T" label="Todo"
											   [(ngModel)]="venGuiaRemiTipo">
								</p-radioButton>
							</div>
							<div class="p-col-2" style="text-align: right">
								<span>Bodega:</span>
							</div>
							<div class="p-col-2">
								<p-dropdown [style]="{'width':'100%'}" [(ngModel)]="strBodegaGuiaRemi"
											optionLabel="name" [options]="cmbBodegaGuiaRemi"
											[panelStyle]="{'width':'100%'}" inputId="bodegaGuiaRemi">
								</p-dropdown>
							</div>
							<div class="p-col-2"></div>
							<!--LINE 2-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-1">
										<span>Fecha Final:</span>
									</div>
									<div class="p-col-2" style="text-align: left">
										<p-calendar id="venGuiaRemiFechaFinal"
													[inputStyle]="{width: '100%', height: '20px'}"
													[(ngModel)]="venGuiaRemiFechaFinal"
													dateFormat="dd/mm/yy">
										</p-calendar>
									</div>
									<div class="p-col-2" style="text-align: left;">
										<p-radioButton name="venGuiaRemiTipo" value="N" [(ngModel)]="venGuiaRemiTipo"
													   label="No Contabilizado">
										</p-radioButton>
									</div>
									<div class="p-col-2"></div>
									<div class="p-col-1">
										<button (click)="consultarDocumentos('venGuiaRemision')" pButton class="aceptar"
												style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">
											Visualizar
										</button>
										<!--								<p-button label="Visualizar" (click)="consultarDocumentos('venGuiaRemision')"> </p-button>-->
									</div>
									<div class="p-col-3"></div>
								</div>
							</div>
							<!--LINE 3-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-4" style="text-align: left;">
										<span style="font-weight: bold">Documentos a contabilizar</span>
									</div>
									<div class="p-col-8"></div>
								</div>
							</div>
							<!--LINE 4-->
							<div class="p-col-12" style=" margin-bottom: -15px;">
								<div class="p-grid">
									<div class="p-col-2" style="text-align: left;">
										<p-checkbox binary="true" label="Seleccionar Todos"
													[(ngModel)]="venGuiaRemiSeleccionarTodo"
													(click)="seleccionarTodo('venGuiaRemision')">
										</p-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
											[rowData]="contabilizadocService.venGuiasRemision"
											[frameworkComponents]="frameworkComponents" [animateRows]="true"
											[rowSelection]="rowSelection" [columnDefs]="columnDefsVenGuiaRemision"
											[defaultColDef]="defaultColConGuiaRemi" [rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false"></app-aggridsaci>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-1">
							<div class="cuadrado" style="width: 80px; height: 25px;background: #ffff99;"></div>
						</div>
						<div class="p-col-4" style="text-align: left;">
							<span>Documentos contabilizados</span>
						</div>
						<div class="p-col-7"></div>
						<div class="p-col-12">
							<p-progressBar [value]="porcentaje"></p-progressBar>
						</div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-tabPanel>
		<p-tabPanel header="CXC">
			<p-tabView (onChange)="onTab2Change($event)">
				<p-tabPanel header="Comprobantes de Ingreso">
					<div class="divgrups">
						<div class="p-grid">
							<!--LINE 1-->
							<div class="p-col-1">
								<span>Fecha Inicial:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-calendar id="cxcComIngresoFechaInicial"
											[inputStyle]="{width: '100%', height: '20px'}"
											[(ngModel)]="cxcComIngresoFechaInicial"
											dateFormat="dd/mm/yy">
								</p-calendar>
							</div>
							<div class="p-col-2" style="text-align: left;">
								<p-radioButton name="cxcComIngresoTipo" value="T" label="Todo"
											   [(ngModel)]="cxcComIngresoTipo">
								</p-radioButton>
							</div>
							<div class="p-col-2" style="text-align: right">
								<span>Bodega:</span>
							</div>
							<div class="p-col-2">
								<p-dropdown [style]="{'width':'100%'}" [(ngModel)]="strTipoDocComIngreso"
											optionLabel="name" [options]="cmbTipoDocComIngreso"
											[panelStyle]="{'width':'100%'}" inputId="cxcComIngresoTipo">
								</p-dropdown>
							</div>
							<div class="p-col-2"></div>
							<!--LINE 2-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-1">
										<span>Fecha Final:</span>
									</div>
									<div class="p-col-2" style="text-align: left">
										<p-calendar id="cxcComIngresoFechaFinal"
													[inputStyle]="{width: '100%', height: '20px'}"
													[(ngModel)]="cxcComIngresoFechaFinal"
													dateFormat="dd/mm/yy">
										</p-calendar>
									</div>
									<div class="p-col-2" style="text-align: left;">
										<p-radioButton name="cxcComIngresoTipo" value="N"
													   [(ngModel)]="cxcComIngresoTipo"
													   label="No Contabilizado">
										</p-radioButton>
									</div>
									<div class="p-col-2"></div>
									<div class="p-col-1">
										<button (click)="consultarDocumentos('cxcComIngreso')" pButton class="aceptar"
												style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">
											Visualizar
										</button>
										<!--								<p-button label="Visualizar" (click)="consultarDocumentos('cxcComIngreso')"> </p-button>-->
									</div>
									<div class="p-col-3"></div>
								</div>
							</div>
							<!--LINE 3-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-4" style="text-align: left;">
										<span style="font-weight: bold">Documentos a contabilizar</span>
									</div>
									<div class="p-col-8"></div>
								</div>
							</div>
							<!--LINE 4-->
							<div class="p-col-12" style=" margin-bottom: -15px;">
								<div class="p-grid">
									<div class="p-col-2" style="text-align: left;">
										<p-checkbox binary="true" label="Seleccionar Todos"
													[(ngModel)]="cxcComIngresoSeleccionarTodo"
													(click)="seleccionarTodo('cxcComIngreso')">
										</p-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
											[rowData]="contabilizadocService.cxcComprobIngreso"
											[frameworkComponents]="frameworkComponents" [animateRows]="true"
											[rowSelection]="rowSelection" [columnDefs]="columnDefsCXCCompIngreso"
											[defaultColDef]="defaultColConComIngreso" [rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false"></app-aggridsaci>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-1">
							<div class="cuadrado" style="width: 80px; height: 25px;background: #ffff99;"></div>
						</div>
						<div class="p-col-4" style="text-align: left;">
							<span>Documentos contabilizados</span>
						</div>
						<div class="p-col-7"></div>
						<div class="p-col-12">
							<p-progressBar [value]="porcentaje"></p-progressBar>
						</div>
					</div>
				</p-tabPanel>
				<p-tabPanel header="Devoluciones de Anticipos">
					<div class="divgrups">
						<div class="p-grid">
							<!--LINE 1-->
							<div class="p-col-1">
								<span>Fecha Inicial:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-calendar id="cxcDevAntFechaInicial" [inputStyle]="{width: '100%', height: '20px'}"
											dateFormat="dd/mm/yy" [(ngModel)]="cxcDevAntFechaInicial">
								</p-calendar>
							</div>
							<div class="p-col-2" style="text-align: left;">
								<p-radioButton name="cxcDevAntTipo" value="T" [(ngModel)]="cxcDevAntTipo" label="Todo">
								</p-radioButton>
							</div>
							<div class="p-col-6"></div>
							<!--LINE 2-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-1">
										<span>Fecha Final:</span>
									</div>
									<div class="p-col-2" style="text-align: left">
										<p-calendar id="cxcDevAntFechaFinal"
													[inputStyle]="{width: '100%', height: '20px'}"
													dateFormat="dd/mm/yy" [(ngModel)]="cxcDevAntFechaFinal">
										</p-calendar>
									</div>
									<div class="p-col-2" style="text-align: left;">
										<p-radioButton name="cxcDevAntTipo" value="N" [(ngModel)]="cxcDevAntTipo"
													   label="No Contabilizado">
										</p-radioButton>
									</div>
									<div class="p-col-2"></div>
									<div class="p-col-1">
										<button (click)="consultarDocumentos('cxcDevAnticipo')" pButton class="aceptar"
												style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">
											Visualizar
										</button>
										<!--								<p-button label="Visualizar" (click)="consultarDocumentos('cxcDevAnticipo')"> </p-button>-->
									</div>
									<div class="p-col-3"></div>
								</div>
							</div>
							<!--LINE 3-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-4" style="text-align: left;">
										<span style="font-weight: bold">Documentos a contabilizar</span>
									</div>
									<div class="p-col-8"></div>
								</div>
							</div>
							<!--LINE 4-->
							<div class="p-col-12" style=" margin-bottom: -15px;">
								<div class="p-grid">
									<div class="p-col-2" style="text-align: left;">
										<p-checkbox binary="true" label="Seleccionar Todos"
													[(ngModel)]="cxcDevAntSeleccionarTodo"
													(click)="seleccionarTodo('cxcDevAnticipo')">
										</p-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
											[rowData]="contabilizadocService.cxcDevAnticipo"
											[frameworkComponents]="frameworkComponents" [animateRows]="true"
											[rowSelection]="rowSelection" [columnDefs]="columnDefsCXCDevAnticipo"
											[defaultColDef]="defaultColConDevAnt" [rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false"></app-aggridsaci>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-1">
							<div class="cuadrado" style="width: 80px; height: 25px;background: #ffff99;"></div>
						</div>
						<div class="p-col-4" style="text-align: left;">
							<span>Documentos contabilizados</span>
						</div>
						<div class="p-col-7"></div>
						<div class="p-col-12">
							<p-progressBar [value]="porcentaje"></p-progressBar>
						</div>
					</div>
				</p-tabPanel>
				<p-tabPanel header="Notas de Crédito">
					<div class="divgrups">
						<div class="p-grid">
							<!--LINE 1-->
							<div class="p-col-1">
								<span>Fecha Inicial:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-calendar id="cxcNotaCredFechaInicial" [inputStyle]="{width: '100%', height: '20px'}"
											dateFormat="dd/mm/yy" [(ngModel)]="cxcNotaCredFechaInicial">
								</p-calendar>
							</div>
							<div class="p-col-2" style="text-align: left;">
								<p-radioButton name="cxcNotaCredTipo" value="T" [(ngModel)]="cxcNotaCredTipo"
											   label="Todo">
								</p-radioButton>
							</div>
							<div class="p-col-6"></div>
							<!--LINE 2-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-1">
										<span>Fecha Final:</span>
									</div>
									<div class="p-col-2" style="text-align: left">
										<p-calendar id="cxcNotaCredFechaFinal"
													[inputStyle]="{width: '100%', height: '20px'}"
													dateFormat="dd/mm/yy" [(ngModel)]="cxcNotaCredFechaFinal">
										</p-calendar>
									</div>
									<div class="p-col-2" style="text-align: left;">
										<p-radioButton name="cxcNotaCredTipo" value="N" [(ngModel)]="cxcNotaCredTipo"
													   label="No Contabilizado">
										</p-radioButton>
									</div>
									<div class="p-col-2"></div>
									<div class="p-col-1">
										<button (click)="consultarDocumentos('cxcNotaCredito')" pButton class="aceptar"
												style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">
											Visualizar
										</button>
										<!--								<p-button label="Visualizar" (click)="consultarDocumentos('cxcNotaCredito')"> </p-button>-->
									</div>
									<div class="p-col-3"></div>
								</div>
							</div>
							<!--LINE 3-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-4" style="text-align: left;">
										<span style="font-weight: bold">Documentos a contabilizar</span>
									</div>
									<div class="p-col-8"></div>
								</div>
							</div>
							<!--LINE 4-->
							<div class="p-col-12" style=" margin-bottom: -15px;">
								<div class="p-grid">
									<div class="p-col-2" style="text-align: left;">
										<p-checkbox binary="true" label="Seleccionar Todos"
													[(ngModel)]="cxcNotaCredSeleccionarTodo"
													(click)="seleccionarTodo('cxcNotaCredito')">
										</p-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
											[rowData]="contabilizadocService.cxcNotasCredito"
											[frameworkComponents]="frameworkComponents" [animateRows]="true"
											[rowSelection]="rowSelection" [columnDefs]="columnDefsCXCNotasCredito"
											[defaultColDef]="defaultColCXCNotaCred" [rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false"></app-aggridsaci>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-1">
							<div class="cuadrado" style="width: 80px; height: 25px;background: #ffff99;"></div>
						</div>
						<div class="p-col-4" style="text-align: left;">
							<span>Documentos contabilizados</span>
						</div>
						<div class="p-col-7"></div>
						<div class="p-col-12">
							<p-progressBar [value]="porcentaje"></p-progressBar>
						</div>
					</div>
				</p-tabPanel>
				<p-tabPanel header="Notas de Débito">
					<div class="divgrups">
						<div class="p-grid">
							<!--LINE 1-->
							<div class="p-col-1">
								<span>Fecha Inicial:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-calendar id="cxcNotaDebitoFechaInicial"
											[inputStyle]="{width: '100%', height: '20px'}"
											dateFormat="dd/mm/yy" [(ngModel)]="cxcNotaDebitoFechaInicial">
								</p-calendar>
							</div>
							<div class="p-col-2" style="text-align: left;">
								<p-radioButton name="cxcNotaDebitoTipo" value="T" [(ngModel)]="cxcNotaDebitoTipo"
											   label="Todo">
								</p-radioButton>
							</div>
							<div class="p-col-6"></div>
							<!--LINE 2-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-1">
										<span>Fecha Final:</span>
									</div>
									<div class="p-col-2" style="text-align: left">
										<p-calendar id="cxcNotaDebitoFechaFinal"
													[inputStyle]="{width: '100%', height: '20px'}"
													dateFormat="dd/mm/yy" [(ngModel)]="cxcNotaDebitoFechaFinal">
										</p-calendar>
									</div>
									<div class="p-col-2" style="text-align: left;">
										<p-radioButton name="cxcNotaDebitoTipo" value="N"
													   [(ngModel)]="cxcNotaDebitoTipo"
													   label="No Contabilizado">
										</p-radioButton>
									</div>
									<div class="p-col-2"></div>
									<div class="p-col-1">
										<button (click)="consultarDocumentos('cxcNotaDebito')" pButton class="aceptar"
												style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">
											Visualizar
										</button>
										<!--								<p-button label="Visualizar" (click)="consultarDocumentos('cxcNotaDebito')"> </p-button>-->
									</div>
									<div class="p-col-3"></div>
								</div>
							</div>
							<!--LINE 3-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-4" style="text-align: left;">
										<span style="font-weight: bold">Documentos a contabilizar</span>
									</div>
									<div class="p-col-8"></div>
								</div>
							</div>
							<!--LINE 4-->
							<div class="p-col-12" style=" margin-bottom: -15px;">
								<div class="p-grid">
									<div class="p-col-2" style="text-align: left;">
										<p-checkbox binary="true" label="Seleccionar Todos"
													[(ngModel)]="cxcNotaDebitoSeleccionarTodo"
													(click)="seleccionarTodo('cxcNotaDebito')">
										</p-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
											[rowData]="contabilizadocService.cxcNotasDebito"
											[frameworkComponents]="frameworkComponents" [animateRows]="true"
											[rowSelection]="rowSelection" [columnDefs]="columnDefsCXCNotasDebito"
											[defaultColDef]="defaultColCXCNotaDebito" [rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false"></app-aggridsaci>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-1">
							<div class="cuadrado" style="width: 80px; height: 25px;background: #ffff99;"></div>
						</div>
						<div class="p-col-4" style="text-align: left;">
							<span>Documentos contabilizados</span>
						</div>
						<div class="p-col-7"></div>
						<div class="p-col-12">
							<p-progressBar [value]="porcentaje"></p-progressBar>
						</div>
					</div>
				</p-tabPanel>
				<p-tabPanel header="Retenciones">
					<div class="divgrups">
						<div class="p-grid">
							<!--LINE 1-->
							<div class="p-col-1">
								<span>Fecha Inicial:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-calendar id="cxcRetencionFechaInicial" [inputStyle]="{width: '100%', height: '20px'}"
											dateFormat="dd/mm/yy" [(ngModel)]="cxcRetencionFechaInicial">
								</p-calendar>
							</div>
							<div class="p-col-2" style="text-align: left;">
								<p-radioButton name="cxcRetencionTipo" value="T" [(ngModel)]="cxcRetencionTipo"
											   label="Todo">
								</p-radioButton>
							</div>
							<div class="p-col-6"></div>
							<!--LINE 2-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-1">
										<span>Fecha Final:</span>
									</div>
									<div class="p-col-2" style="text-align: left">
										<p-calendar id="cxcRetencionFechaFinal"
													[inputStyle]="{width: '100%', height: '20px'}"
													dateFormat="dd/mm/yy" [(ngModel)]="cxcRetencionFechaFinal">
										</p-calendar>
									</div>
									<div class="p-col-2" style="text-align: left;">
										<p-radioButton name="cxcRetencionTipo" value="N" [(ngModel)]="cxcRetencionTipo"
													   label="No Contabilizado">
										</p-radioButton>
									</div>
									<div class="p-col-2"></div>
									<div class="p-col-1">
										<button (click)="consultarDocumentos('cxcRetencion')" pButton class="aceptar"
												style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">
											Visualizar
										</button>
										<!--							<p-button label="Visualizar" (click)="consultarDocumentos('cxcRetencion')"> </p-button>-->
									</div>
									<div class="p-col-3"></div>
								</div>
							</div>
							<!--LINE 3-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-4" style="text-align: left;">
										<span style="font-weight: bold">Documentos a contabilizar</span>
									</div>
									<div class="p-col-8"></div>
								</div>
							</div>
							<!--LINE 4-->
							<div class="p-col-12" style=" margin-bottom: -15px;">
								<div class="p-grid">
									<div class="p-col-2" style="text-align: left;">
										<p-checkbox binary="true" label="Seleccionar Todos"
													[(ngModel)]="cxcRetencionSeleccionarTodo"
													(click)="seleccionarTodo('cxcRetencion')">
										</p-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
											[rowData]="contabilizadocService.cxcRetenciones"
											[frameworkComponents]="frameworkComponents" [animateRows]="true"
											[rowSelection]="rowSelection" [columnDefs]="columnDefsCXCRetencion"
											[defaultColDef]="defaultColCXCRetencion" [rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false"></app-aggridsaci>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-1">
							<div class="cuadrado" style="width: 80px; height: 25px;background: #ffff99;"></div>
						</div>
						<div class="p-col-4" style="text-align: left;">
							<span>Documentos contabilizados</span>
						</div>
						<div class="p-col-7"></div>
						<div class="p-col-12">
							<p-progressBar [value]="porcentaje"></p-progressBar>
						</div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-tabPanel>
		<p-tabPanel header="CXP">
			<p-tabView (onChange)="onTab2Change($event)">
				<p-tabPanel header="Comprobantes de Egreso">
					<div class="divgrups">
						<div class="p-grid">
							<!--LINE 1-->
							<div class="p-col-1">
								<span>Fecha Inicial:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-calendar id="cxpEgresoFechaInicial" [inputStyle]="{width: '100%', height: '20px'}"
											dateFormat="dd/mm/yy" [(ngModel)]="cxpEgresoFechaInicial">
								</p-calendar>
							</div>
							<div class="p-col-2" style="text-align: left;">
								<p-radioButton name="cxpEgresoTipo" value="T" [(ngModel)]="cxpEgresoTipo" label="Todo">
								</p-radioButton>
							</div>
							<div class="p-col-6"></div>
							<!--LINE 2-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-1">
										<span>Fecha Final:</span>
									</div>
									<div class="p-col-2" style="text-align: left">
										<p-calendar id="cxpEgresoFechaFinal"
													[inputStyle]="{width: '100%', height: '20px'}"
													dateFormat="dd/mm/yy" [(ngModel)]="cxpEgresoFechaFinal">
										</p-calendar>
									</div>
									<div class="p-col-2" style="text-align: left;">
										<p-radioButton name="cxpEgresoTipo" value="N" [(ngModel)]="cxpEgresoTipo"
													   label="No Contabilizado">
										</p-radioButton>
									</div>
									<div class="p-col-2"></div>
									<div class="p-col-1">
										<button (click)="consultarDocumentos('cxpEgreso')" pButton class="aceptar"
												style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">
											Visualizar
										</button>
										<!--								<p-button label="Visualizar" (click)="consultarDocumentos('cxpEgreso')"> </p-button>-->
									</div>
									<div class="p-col-3"></div>
								</div>
							</div>
							<!--LINE 3-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-4" style="text-align: left;">
										<span style="font-weight: bold">Documentos a contabilizar</span>
									</div>
									<div class="p-col-8"></div>
								</div>
							</div>
							<!--LINE 4-->
							<div class="p-col-12" style=" margin-bottom: -15px;">
								<div class="p-grid">
									<div class="p-col-2" style="text-align: left;">
										<p-checkbox binary="true" label="Seleccionar Todos"
													[(ngModel)]="cxpEgresoSeleccionarTodo"
													(click)="seleccionarTodo('cxpEgreso')">
										</p-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
											[rowData]="contabilizadocService.cxpEgresos"
											[frameworkComponents]="frameworkComponents" [animateRows]="true"
											[rowSelection]="rowSelection" [columnDefs]="columnDefsCXPEgreso"
											[defaultColDef]="defaultColCXPEgreso" [rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false"></app-aggridsaci>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-1">
							<div class="cuadrado" style="width: 80px; height: 25px;background: #ffff99;"></div>
						</div>
						<div class="p-col-4" style="text-align: left;">
							<span>Documentos contabilizados</span>
						</div>
						<div class="p-col-7"></div>
						<div class="p-col-12">
							<p-progressBar [value]="porcentaje"></p-progressBar>
						</div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-tabPanel>
		<p-tabPanel header="NOM">
			<p-tabView (onChange)="onTab2Change($event)">
				<p-tabPanel header="Roles de Pago">
					<div class="divgrups">
						<div class="p-grid">
							<!--LINE 1-->
							<div class="p-col-1">
								<span>Fecha Inicial:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-calendar id="nomRolFechaInicial" [inputStyle]="{width: '100%', height: '20px'}"
											dateFormat="dd/mm/yy" [(ngModel)]="nomRolFechaInicial">
								</p-calendar>
							</div>
							<div class="p-col-2" style="text-align: left;">
								<p-radioButton name="nomRolTipo" value="T" [(ngModel)]="nomRolTipo" label="Todo">
								</p-radioButton>
							</div>
							<div class="p-col-6"></div>
							<!--LINE 2-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-1">
										<span>Fecha Final:</span>
									</div>
									<div class="p-col-2" style="text-align: left">
										<p-calendar id="nomRolFechaFinal"
													[inputStyle]="{width: '100%', height: '20px'}"
													dateFormat="dd/mm/yy" [(ngModel)]="nomRolFechaFinal">
										</p-calendar>
									</div>
									<div class="p-col-2" style="text-align: left;">
										<p-radioButton name="nomRolTipo" value="N" [(ngModel)]="nomRolTipo"
													   label="No Contabilizado">
										</p-radioButton>
									</div>
									<div class="p-col-2"></div>
									<div class="p-col-1">
										<button (click)="consultarDocumentos('nomRolPago')" pButton class="aceptar"
												style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">
											Visualizar
										</button>
										<!--								<p-button label="Visualizar" (click)="consultarDocumentos('cxpEgreso')"> </p-button>-->
									</div>
									<div class="p-col-3"></div>
								</div>
							</div>
							<!--LINE 3-->
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-4" style="text-align: left;">
										<span style="font-weight: bold">Documentos a contabilizar</span>
									</div>
									<div class="p-col-8"></div>
								</div>
							</div>
							<!--LINE 4-->
							<div class="p-col-12" style=" margin-bottom: -15px;">
								<div class="p-grid">
									<div class="p-col-2" style="text-align: left;">
										<p-checkbox binary="true" label="Seleccionar Todos"
													[(ngModel)]="nomRolSeleccionarTodo"
													(click)="seleccionarTodo('nomRolPago')">
										</p-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
											[rowData]="contabilizadocService.nomRolPagos"
											[frameworkComponents]="frameworkComponents" [animateRows]="true"
											[rowSelection]="rowSelection" [columnDefs]="columnDefsNomRol"
											[defaultColDef]="defaultColNomRol" [rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false"></app-aggridsaci>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-1">
							<div class="cuadrado" style="width: 80px; height: 25px;background:  #ffff99;"></div>
						</div>
						<div class="p-col-4" style="text-align: left;">
							<span>Documentos contabilizados</span>
						</div>
						<div class="p-col-7"></div>
						<div class="p-col-12">
							<p-progressBar [value]="porcentaje"></p-progressBar>
						</div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-tabPanel>
		<p-tabPanel header="PROD">
			<p-tabView (onChange)="onTab2Change($event)">
				<p-tabPanel header="Ordenes de Producción">
					<div class="divgrups">
						<div class="p-grid">
							
							<div class="p-col-1">
								<span>Fecha Inicial:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-calendar id="prodOrdProdFechaInicial" [inputStyle]="{width: '100%', height: '20px'}"
											dateFormat="dd/mm/yy" [(ngModel)]="prodOrdProdFechaInicial">
								</p-calendar>
							</div>
							<div class="p-col-2" style="text-align: left;">
								<p-radioButton name="prodOrdProdTipo" value="T" [(ngModel)]="prodOrdProdTipo" label="Todo">
								</p-radioButton>
							</div>
							<div class="p-col-6"></div>
							
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-1">
										<span>Fecha Final:</span>
									</div>
									<div class="p-col-2" style="text-align: left">
										<p-calendar id="prodOrdProdFechaFinal"
													[inputStyle]="{width: '100%', height: '20px'}"
													dateFormat="dd/mm/yy" [(ngModel)]="prodOrdProdFechaFinal">
										</p-calendar>
									</div>
									<div class="p-col-2" style="text-align: left;">
										<p-radioButton name="prodOrdProdTipo" value="N" [(ngModel)]="prodOrdProdTipo"
													label="No Contabilizado">
										</p-radioButton>
									</div>
									<div class="p-col-2"></div>
									<div class="p-col-1">
										<button (click)="consultarDocumentos('prodOrdProd')" pButton class="aceptar"
												style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">
											Visualizar
										</button>
									</div>
									<div class="p-col-3"></div>
								</div>
							</div>
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-4" style="text-align: left;">
										<span style="font-weight: bold">Documentos a contabilizar</span>
									</div>
									<div class="p-col-8"></div>
								</div>
							</div>
							<div class="p-col-12" style=" margin-bottom: -15px;">
								<div class="p-grid">
									<div class="p-col-2" style="text-align: left;">
										<p-checkbox binary="true" label="Seleccionar Todos"
													[(ngModel)]="prodOrdProdSeleccionarTodo"
													(click)="seleccionarTodo('prodOrdProd')">
										</p-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12">
							<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
											[rowData]="contabilizadocService.prodOrdProd"
											[frameworkComponents]="frameworkComponents" [animateRows]="true"
											[rowSelection]="rowSelection" [columnDefs]="columnDefsProdOrdProd"
											[defaultColDef]="defaultColProdOrdProd" [rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false"></app-aggridsaci>
						</div>
					</div>
					<div class="p-grid">
						<!-- <div class="p-col-1">
							<div class="cuadrado" style="width: 80px; height: 25px;background:  #ffff99;"></div>
						</div>
						<div class="p-col-4" style="text-align: left;">
							<span>Documentos contabilizados</span>
						</div>
						<div class="p-col-7"></div> -->
						<div class="p-col-12">
							<p-progressBar [value]="porcentaje"></p-progressBar>
						</div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-tabPanel>
	</p-tabView>
</div>