import {Injectable} from '@angular/core';
import {Maearticulo} from '../interfaces/maearticulo';
import {VenEncfac} from '../interfaces/venencfac';
import {BanMaeban} from '../interfaces/banmaeban';
import {SaciMaeretencion} from '../interfaces/sacimaeretencion';
import {AnexMaecodsri} from '../interfaces/anexmaecodsri';
import {CxcMaetarjeta} from '../interfaces/cxcmaetarjeta';
import {CxcMaebancli} from '../interfaces/cxcmaebancli';

@Injectable({
  providedIn: 'root'
})
export class CalculosServiceService {
  facturaComun: [Maearticulo[]] = [[]];
  factura: [Maearticulo[]] = [[]];
  valoraEnviar: any[] = [];
  subtotalFac: [any] = [0];
  selectedProd: Maearticulo;
  posSelectedProd: number;
  facSeleccionada: [Maearticulo []] = [[]];
  selectedFac: VenEncfac;
  posSelectedFac: number;
  auxiliar: string;
  IVA = 12;
  iva: [any] = [0];
  total: [any] = [0];
  selectedBan: BanMaeban;
  posSelectedBan: number;
  selectedBanCH: CxcMaebancli;
  posSelectedBanCH: number;
  selectedBanTA: CxcMaetarjeta;
  posSelectedBanTA: number;
  decCantidad = 0;
  decPrecio = 0;
  decTotal = 0;
  decDescuento = 0;
  selectedCodsri: AnexMaecodsri;
  posSelectedCodsri: number;
  darFocus: [number] = [0];
  selectedRet: SaciMaeretencion;
  posSelectedRet: number;
  decimalConfigVenENCFAC = [];
  baseiva: [any] = [0];
  basecero: [any] = [0];
  descuento: [any] = [0];
  descuentop: [any] = [0];
  stringTeclado = '';

  constructor() {
  }

  entregarDecPrecio() {
    return this.decPrecio;
  }

  setFocus(valor, pos) {
    this.darFocus[pos - 1] = valor;
  }

  getFocus() {
    return this.darFocus;
  }

  editFocus(valor, posicion) {
    this.darFocus[posicion - 1] = valor;
  }

  setDecimalesConfig(config) {
    this.decimalConfigVenENCFAC = config;
    for (const conf of this.decimalConfigVenENCFAC) {
      if (conf.NUMDEC_CAMPO === 'CANTIDAD') {
        this.decCantidad = conf.NUMDEC_NUMDEC;
      } else if (conf.NUMDEC_CAMPO === 'PRECIO') {
        this.decPrecio = conf.NUMDEC_NUMDEC;
      } else if (conf.NUMDEC_CAMPO === 'DESCUENTO') {
        this.decDescuento = conf.NUMDEC_NUMDEC;
      } else if (conf.NUMDEC_CAMPO === 'TOTAL') {
        this.decTotal = conf.NUMDEC_NUMDEC;
      }
    }
  }


  getDecimalesConfig(campo) {
    if (campo === 'CANTIDAD') {
      return this.decCantidad;
    } else if (campo === 'PRECIO') {
      return this.decPrecio;
    } else if (campo === 'DESCUENTO') {
      return this.decDescuento;
    } else if (campo === 'TOTAL') {
      return this.decTotal;
    }
  }


  calcSubtotal(fact: Maearticulo[], posicion: number) {
    this.subtotalFac[posicion - 1] = [0];
    this.iva[posicion - 1] = [0];
    this.total[posicion - 1] = [0];
    this.baseiva[posicion - 1] = [0];
    this.basecero[posicion - 1] = [0];
    this.descuento[posicion - 1] = [0];
    this.descuentop[posicion - 1] = [0];

    let i = 0;
    if (fact.length !== 0) {
      do {
        const totalproducto = (fact[i].ARTPRE_PRECIO * fact[i].CANTIDAD
          - ((fact[i].DESCUENTO / 100) * (fact[i].ARTPRE_PRECIO * fact[i].CANTIDAD)));
        this.subtotalFac[posicion - 1] = Number(this.subtotalFac[posicion - 1]) + totalproducto;
        if (fact[i].ART_TRIBUTAIVA === 'S') {
          this.baseiva[posicion - 1] = Number(this.baseiva[posicion - 1]) + totalproducto;
        } else {
          this.basecero[posicion - 1] = Number(this.basecero[posicion - 1]) + totalproducto;
        }
        i++;
      } while (i < fact.length);
      this.iva[posicion - 1] = Number(this.baseiva[posicion - 1]) * this.IVA / 100;
      this.total[posicion - 1] = Number(this.baseiva[posicion - 1]) + Number(this.iva[posicion - 1]) + Number(this.basecero[posicion - 1]);

      this.subtotalFac[posicion - 1] = Number(this.subtotalFac[posicion - 1]).toFixed(2);
      this.iva[posicion - 1] = Number(this.iva[posicion - 1]).toFixed(2);
      this.total[posicion - 1] = Number(this.total[posicion - 1]).toFixed(this.decTotal);
      this.baseiva[posicion - 1] = Number(this.baseiva[posicion - 1]).toFixed(2);
      this.basecero[posicion - 1] = Number(this.basecero[posicion - 1]).toFixed(2);
    } else {
      this.subtotalFac[posicion - 1] = 0;
      this.iva[posicion - 1] = 0;
      this.total[posicion - 1] = 0;
    }
  }
  recibirFacComun() {
    return this.facturaComun;
  }

  entregarSubtotal() {
    return this.subtotalFac;
  }

  entregarIVA() {
    return this.iva;
  }

  entregarBaseIVA() {
    return this.baseiva;
  }

  entregarBaseCero() {
    return this.basecero;
  }

  entregarTotal() {
    return this.total;
  }

  entregarDescuento() {
    return this.descuento;
  }

  entregarDescuentoP() {
    return this.descuentop;
  }


  entregarFactura() {
    return this.factura;
  }

  recibirAux(aux: string) {
    this.auxiliar = aux;
  }
  llenarFac(art: Maearticulo, aux: string, posicion: number) {
    if (aux === '0') {
      art.ARTPRE_PRECIO = Number(art.ARTPRE_PRECIO).toFixed(this.decPrecio);
      if (this.factura[posicion - 1].length === 0 && art.CANTIDAD === undefined) {
        this.sumarProductos(2, art, posicion);
      } else {
        if (this.factura[posicion - 1].includes(art)) {
          this.factura[posicion - 1].map((product) => {
            if (product.ART_CODIGO === art.ART_CODIGO && product.ART_NOMBREC === art.ART_NOMBREC) {
              if (product.CANTIDAD === art.CANTIDAD) {
                this.sumarProductos(1, art, posicion);
              } else {
                this.sumarProductos(4, art, posicion);
              }
            }
          });
        } else {
          if (art.CANTIDAD === undefined && this.factura[posicion - 1].length !== 0) {
            let cont = 0;
            let i = 0;
            do {
              if (this.factura[posicion - 1][i].ART_CODIGO === art.ART_CODIGO) {
                cont = 1;
              }
              i++;
            } while (i < this.factura[posicion - 1].length);
            if (cont === 1) {
              this.sumarProductos(5, art, posicion);
            } else {
              this.sumarProductos(2, art, posicion);
            }
          } else if (this.factura[posicion - 1].length !== 0) {
            let cont = 0;
            let i = 0;
            do {
              if (this.factura[posicion - 1][i].ART_CODIGO === art.ART_CODIGO) {
                cont = 1;
              }
              i++;
            } while (i < this.factura[posicion - 1].length);
            if (cont === 1) {
              this.sumarProductos(4, art, posicion);
            } else {
              this.sumarProductos(3, art, posicion);
            }
          } else if (this.factura[posicion - 1].length === 0 && art.CANTIDAD !== undefined) {
            this.sumarProductos(3, art, posicion);
          } else {
            this.sumarProductos(2, art, posicion);
          }
        }
      }
    } else if (aux === '1') {
      console.log('1', art.CANTIDAD);
      art.ARTPRE_PRECIO = Number(art.ARTPRE_PRECIO).toFixed(this.decPrecio);
      if (this.facSeleccionada[posicion - 1].length === 0 && art.CANTIDAD === undefined) {
        this.sumarProductos(7, art, posicion);
      } else {
        if (this.facSeleccionada[posicion - 1].includes(art)) {
          this.facSeleccionada[posicion - 1].map((product) => {
            if (product.ART_CODIGO === art.ART_CODIGO && product.ART_NOMBREC === art.ART_NOMBREC) {
              if (product.CANTIDAD === art.CANTIDAD) {
                console.log('opcionprod 6');
                this.sumarProductos(6, art, posicion);
              } else {
                console.log('opcionprod 9');
                this.sumarProductos(9, art, posicion);
              }
            }
          });
        } else {
          if (art.CANTIDAD === undefined && this.facSeleccionada[posicion - 1].length !== 0) {
            let cont = 0;
            let i = 0;
            do {
              if (this.facSeleccionada[posicion - 1][i].ART_CODIGO === art.ART_CODIGO) {
                cont = 1;
              }
              i++;
            } while (i < this.facSeleccionada[posicion - 1].length);
            if (cont === 1) {
              this.sumarProductos(10, art, posicion);
            } else {
              this.sumarProductos(7, art, posicion);
            }
          } else if (this.facSeleccionada[posicion - 1].length !== 0) {
            let cont = 0;
            let i = 0;
            do {
              if (this.facSeleccionada[posicion - 1][i].ART_CODIGO === art.ART_CODIGO) {
                cont = 1;
              }
              i++;
            } while (i < this.facSeleccionada[posicion - 1].length);
            if (cont === 1) {
              this.sumarProductos(9, art, posicion);
            } else {
              this.sumarProductos(8, art, posicion);
            }
          } else if (this.facSeleccionada[posicion - 1].length === 0 && art.CANTIDAD !== undefined) {
            this.sumarProductos(8, art, posicion);
          } else {
            this.sumarProductos(7, art, posicion);
          }
        }
      }
    }
  }

  sumarProductos(valor: number, prod: Maearticulo, posicion: number) {
    switch (valor) {
      case 1:
        this.factura[posicion - 1].map((producto) => {
          if (producto.ART_CODIGO === prod.ART_CODIGO && producto.ART_NOMBREC === prod.ART_NOMBREC) {
            prod.CANTIDAD++;
            prod.DETFAC_LINEA3 = 0;
            this.calcSubtotal(this.factura[posicion - 1], posicion);
          }
        });
        this.facturaComun = this.factura;
        break;
      case 2:
        prod.CANTIDAD = 1;
        prod.DESCUENTO = 0;
        prod.DETFAC_LINEA3 = 0;
        this.factura[posicion - 1].push(prod);
        this.calcSubtotal(this.factura[posicion - 1], posicion);
        this.facturaComun = this.factura;
        break;
      case 3:
        prod.DESCUENTO = 0;
        prod.DETFAC_LINEA3 = 0;
        this.factura[posicion - 1].push(prod);
        this.calcSubtotal(this.factura[posicion - 1], posicion);
        this.facturaComun = this.factura;
        break;
      case 4:
        this.factura[posicion - 1].map((producto) => {
          if (producto.ART_CODIGO === prod.ART_CODIGO && producto.ART_NOMBREC === prod.ART_NOMBREC) {
            prod.DETFAC_LINEA3 = 0;
            producto.CANTIDAD = prod.CANTIDAD;
            this.calcSubtotal(this.factura[posicion - 1], posicion);
          }
        });
        this.facturaComun = this.factura;
        break;
      case 5:
        this.factura[posicion - 1].map((producto) => {
          if (producto.ART_CODIGO === prod.ART_CODIGO && producto.ART_NOMBREC === prod.ART_NOMBREC) {
            producto.CANTIDAD++;
            prod.DETFAC_LINEA3 = 0;
            prod.CANTIDAD = producto.CANTIDAD;
            this.calcSubtotal(this.factura[posicion - 1], posicion);
          }
        });
        this.facturaComun = this.factura;
        break;
      case 6:
        this.facSeleccionada[posicion - 1].map((producto) => {
          if (producto.ART_CODIGO === prod.ART_CODIGO && producto.ART_NOMBREC === prod.ART_NOMBREC) {
            prod.CANTIDAD++;
            console.log('Sumar', prod.CANTIDAD);
            this.calcSubtotal(this.facSeleccionada[posicion - 1], posicion);
          }
        });
        this.facturaComun = this.facSeleccionada;
        break;
      case 7:
        prod.CANTIDAD = 1;
        prod.DESCUENTO = 0;
        this.facSeleccionada[posicion - 1].push(prod);
        this.calcSubtotal(this.facSeleccionada[posicion - 1], posicion);
        this.facturaComun = this.facSeleccionada;
        break;
      case 8:
        prod.DESCUENTO = 0;
        this.facSeleccionada[posicion - 1].push(prod);
        this.calcSubtotal(this.facSeleccionada[posicion - 1], posicion);
        this.facturaComun = this.facSeleccionada;
        break;
      case 9:
        this.facSeleccionada[posicion - 1].map((producto) => {
          if (producto.ART_CODIGO === prod.ART_CODIGO && producto.ART_NOMBREC === prod.ART_NOMBREC) {
            if (producto.CANTIDAD === prod.CANTIDAD) {
              prod.CANTIDAD++;
            } else {
              producto.CANTIDAD = prod.CANTIDAD;
            }
            this.calcSubtotal(this.facSeleccionada[posicion - 1], posicion);
          }
        });
        this.facturaComun = this.facSeleccionada;
        break;
      case 10:
        this.facSeleccionada[posicion - 1].map((producto) => {
          if (producto.ART_CODIGO === prod.ART_CODIGO && producto.ART_NOMBREC === prod.ART_NOMBREC) {
            producto.CANTIDAD++;
            prod.CANTIDAD = producto.CANTIDAD;
            this.calcSubtotal(this.facSeleccionada[posicion - 1], posicion);
          }
        });
        this.facturaComun = this.facSeleccionada;
        break;
    }
  }

  borrarProd(fac: Maearticulo, index: number, posicion: number) {
    console.log(this.auxiliar);
    if (this.auxiliar === '0') {
      this.factura[posicion - 1].map((producto) => {
        if (producto.ART_CODIGO === fac.ART_CODIGO && producto.ART_NOMBREC === fac.ART_NOMBREC) {
          this.factura[posicion - 1] = this.factura[posicion - 1].filter((val, i) => i !== index);
          this.calcSubtotal(this.factura[posicion - 1], posicion);
          fac.CANTIDAD = 1;
        }
      });
      this.facturaComun = this.factura;
    } else {
      this.facSeleccionada[posicion - 1].map((producto) => {
        if (producto.ART_CODIGO === fac.ART_CODIGO && producto.ART_NOMBREC === fac.ART_NOMBREC) {
          this.facSeleccionada[posicion - 1] = this.facSeleccionada[posicion - 1].filter((val, i) => i !== index);
          this.calcSubtotal(this.facSeleccionada[posicion - 1], posicion);
          fac.CANTIDAD = 1;
        }
      });
      this.facturaComun = this.facSeleccionada;
    }
  }

  enviarArt(fac: Maearticulo, index: number) {
    this.selectedProd = fac;
    this.posSelectedProd = index;
  }

  recibirArt() {
    return this.selectedProd;
  }

  recibirIndex() {
    return this.posSelectedProd;
  }

  calcValue(num: number) {
    if (num === 404) {
      this.valoraEnviar.push('.');
    } else {
      this.valoraEnviar.push(num);
    }
  }

  returnCalcValue() {
    return this.valoraEnviar.join('');
  }

  recibirValorTeclado(valor) {
    this.stringTeclado = valor;
  }

  retornarValorTeclado() {
    return this.stringTeclado;
  }

  modificarDescuento(fac: Maearticulo, nuevoDsc: number, posicion: number) {
    if (this.auxiliar === '0') {
      this.factura[posicion - 1].map((producto) => {
        if (producto.ART_CODIGO === fac.ART_CODIGO && producto.ART_NOMBRE === fac.ART_NOMBRE) {
          fac.DESCUENTO = nuevoDsc.toFixed(this.decDescuento);
          this.calcSubtotal(this.factura[posicion - 1], posicion);
        }
      });
      this.facturaComun = this.factura;
    } else if (this.auxiliar === '1') {
      this.facSeleccionada[posicion - 1].map((producto) => {
        if (producto.ART_CODIGO === fac.ART_CODIGO && producto.ART_NOMBRE === fac.ART_NOMBRE) {
          fac.DESCUENTO = nuevoDsc.toFixed(this.decDescuento);
          this.calcSubtotal(this.facSeleccionada[posicion - 1], posicion);
        }
      });
      this.facturaComun = this.facSeleccionada;
    }
  }

  modificarPrecio(fac: Maearticulo, nuevoPre: number, posicion: number) {
    if (this.auxiliar === '0') {
      this.factura[posicion - 1].map((producto) => {
        if (producto.ART_CODIGO === fac.ART_CODIGO && producto.ART_NOMBRE === fac.ART_NOMBRE) {
          fac.DESCUENTO = (nuevoPre * (100) / (fac.ARTPRE_PRECIO * fac.CANTIDAD));
          fac.DESCUENTO = Number(fac.DESCUENTO).toFixed(this.decDescuento);
          this.calcSubtotal(this.factura[posicion - 1], posicion);
        }
      });
      this.facturaComun = this.factura;
    } else if (this.auxiliar === '1') {
      this.facSeleccionada[posicion - 1].map((producto) => {
        if (producto.ART_CODIGO === fac.ART_CODIGO && producto.ART_NOMBRE === fac.ART_NOMBRE) {
          fac.DESCUENTO = (nuevoPre * (100) / (fac.ARTPRE_PRECIO * fac.CANTIDAD));
          fac.DESCUENTO = Number(fac.DESCUENTO).toFixed(this.decDescuento);
          this.calcSubtotal(this.facSeleccionada[posicion - 1], posicion);
        }
      });
      this.facturaComun = this.facSeleccionada;
    }
  }

  modificarCantidad(fac: Maearticulo, nuevaCant: number, posicion: number) {
    if (this.auxiliar === '0') {
      this.factura[posicion - 1].map((producto) => {
        if (producto.ART_CODIGO === fac.ART_CODIGO && producto.ART_NOMBRE === fac.ART_NOMBRE) {
          fac.CANTIDAD = nuevaCant;
          this.calcSubtotal(this.factura[posicion - 1], posicion);
        }
      });
      this.facturaComun = this.factura;
    } else if (this.auxiliar === '1') {
      this.facSeleccionada[posicion - 1].map((producto) => {
        if (producto.ART_CODIGO === fac.ART_CODIGO && producto.ART_NOMBRE === fac.ART_NOMBRE) {
          fac.CANTIDAD = nuevaCant;
          this.calcSubtotal(this.facSeleccionada[posicion - 1], posicion);
        }
      });
      this.facturaComun = this.facSeleccionada;
    }
  }

  modificarPrecioValor(fac: Maearticulo, nuevoPrecio: number, posicion: number) {
    if (this.auxiliar === '0') {
      this.factura[posicion - 1].map((producto) => {
        if (producto.ART_CODIGO === fac.ART_CODIGO && producto.ART_NOMBRE === fac.ART_NOMBRE) {
          fac.ARTPRE_PRECIO = nuevoPrecio.toFixed(this.decPrecio);
          this.calcSubtotal(this.factura[posicion - 1], posicion);
        }
      });
      this.facturaComun = this.factura;
    } else if (this.auxiliar === '1') {
      this.facSeleccionada[posicion - 1].map((producto) => {
        if (producto.ART_CODIGO === fac.ART_CODIGO && producto.ART_NOMBRE === fac.ART_NOMBRE) {
          fac.ARTPRE_PRECIO = nuevoPrecio.toFixed(this.decPrecio);
          this.calcSubtotal(this.facSeleccionada[posicion - 1], posicion);
        }
      });
      this.facturaComun = this.facSeleccionada;
    }
  }
  descGlobalV2(desc: number, descp: number, posicion: number, factura) {
    this.calcSubtotal(factura, posicion);
    this.descuento[posicion - 1] = desc;
    this.descuentop[posicion - 1] = descp;
    const subtotal = Number(this.baseiva[posicion - 1]) + Number(this.basecero[posicion - 1]);
    console.log(this.subtotalFac);
    const porcentaje = desc * 100 / subtotal;
    console.log(porcentaje);
    console.log(this.baseiva[posicion - 1]);

    this.baseiva[posicion - 1] = this.baseiva[posicion - 1] - (this.baseiva[posicion - 1] * porcentaje / 100);

    this.basecero[posicion - 1] = this.basecero[posicion - 1] - (this.basecero[posicion - 1] * porcentaje / 100);


    this.iva[posicion - 1] = Number(this.baseiva[posicion - 1]) * 0.12;
    this.total[posicion - 1] = Number(this.baseiva[posicion - 1]) + Number(this.iva[posicion - 1]) + Number(this.basecero[posicion - 1]);
    this.descuento[posicion - 1] = Number(this.descuento[posicion - 1]).toFixed(this.decTotal);
    this.descuentop[posicion - 1] = Number(this.descuentop[posicion - 1]).toFixed(this.decTotal);
    this.baseiva[posicion - 1] = Number(this.baseiva[posicion - 1]).toFixed(this.decTotal);
    this.iva[posicion - 1] = Number(this.iva[posicion - 1]).toFixed(this.decTotal);
    this.basecero[posicion - 1] = Number(this.basecero[posicion - 1]).toFixed(this.decTotal);
    this.total[posicion - 1] = Number(this.total[posicion - 1]).toFixed(this.decTotal);
  }


  eliminarFac(posicion: number) {
    this.factura[posicion - 1] = [];
    this.facSeleccionada[posicion - 1] = [];
    this.calcSubtotal(this.factura[posicion - 1] || this.facSeleccionada[posicion - 1], posicion);
  }

  vaciarCant() {
    this.valoraEnviar = [];
    this.stringTeclado = '';
  }

  reducirCant() {
    this.valoraEnviar.pop();
  }


// facturas

  enviarFac(fac: VenEncfac, index: number) {
    this.selectedFac = fac;
    this.posSelectedFac = index;
  }

  recibirFac() {
    return this.selectedFac;
  }

  enviarBan(ban: BanMaeban, index: number) {
    this.selectedBan = ban;
    this.posSelectedBan = index;
  }

  recibirBan() {
    return this.selectedBan;
  }

  enviarBanCH(ban: CxcMaebancli, index: number) {
    this.selectedBanCH = ban;
    this.posSelectedBanCH = index;
  }

  recibirBanCH() {
    return this.selectedBanCH;
  }

  enviarBanTA(ban: CxcMaetarjeta, index: number) {
    this.selectedBanTA = ban;
    this.posSelectedBanTA = index;
  }

  recibirBanTA() {
    return this.selectedBanTA;
  }

  enviarCodsri(codsri: AnexMaecodsri, index: number) {
    this.selectedCodsri = codsri;
    this.posSelectedCodsri = index;
  }

  recibirCodsri() {
    return this.selectedCodsri;
  }

  enviarRet(ret: SaciMaeretencion, index: number) {
    this.selectedRet = ret;
    this.posSelectedRet = index;
  }

  recibirRet() {
    return this.selectedRet;
  }


  limpiar(posicion: number) {
    this.facSeleccionada[posicion - 1] = [];
    this.factura[posicion - 1] = [];
    this.subtotalFac[posicion - 1] = 0;
    this.iva[posicion - 1] = 0;
    this.total[posicion - 1] = 0;
  }

  enviarDet(fac: Maearticulo, index: number, posicion: number) {
    this.selectedProd = fac;
    this.facSeleccionada[posicion - 1].push(this.selectedProd);
    this.calcSubtotal(this.facSeleccionada[posicion - 1], posicion);
    this.posSelectedProd = index;
    this.facturaComun = this.facSeleccionada;
  }

  recibirDet() {
    return this.facSeleccionada;
  }
}
