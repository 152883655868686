    <div class="p-grid">
        <div class="p-col-6">
            <img src="assets/layout/images/logo-roma.svg" alt="roma-layout" />
            <p>Premium PrimeFaces Application Template</p>
        </div>
        <div class="p-col-6 footer-icons">
            <a href="#">
                <i class="pi pi-home"></i>
            </a>
            <a href="#">
                <i class="pi pi-globe"></i>
            </a>
            <a href="#">
                <i class="pi pi-envelope"></i>
            </a>
        </div>
    </div>