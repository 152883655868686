import { Component, OnInit,ViewChild } from '@angular/core';
import { SegmaereferenciaService } from '../segservicios/segmaereferencia.service';
import { SegMaeConsultaTipoRef } from '../seginterfaces/segmaetipref';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import { AggridBanComponent } from 'src/app/ban/aggrid-ban/aggrid-ban.component';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { SegmaeReferencia } from '../seginterfaces/segmaereferencia';
@Component({
  selector: 'app-segmaereferencia',
  templateUrl: './segmaereferencia.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class SegmaereferenciaComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  //strCmbModulo;
  strCmbReferencia;
  cmbReferencia;
  disabledSelTodosMod = false;
  segMaeReferencia:SegMaeConsultaTipoRef[]=[];
  selectsegMaeReferenciaCmb:SegMaeConsultaTipoRef={};
  /*Tabla*/
  defaultColDefSegMaeReferencia;
  public frameworkComponents;
  rowStyle;
  largo = '258';
  rowSelection = 'multiple';
  segMaeReferenciat:SegmaeReferencia[]=[];
  selectedsegMaeReferenciat: SegmaeReferencia; 
  btnGuardar: boolean;
  btnNuevo = true;
  btnBorrar: boolean;
  btnRegresar: boolean;
  btnBuscar: boolean;
  btnRecargar:boolean;
  columnDefMaeReferencia = [
    /* {
      headerName: '', field: 'NUMERO', editable: false, width: 50,
    }, */
    {
      headerName: 'Código', field: 'Código', cellEditor: 'cellPrueba', width: 90, editable: (params) => {
        const boolEdit = this.isEdit(params.data) ;
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 20,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Nombre', field: 'Nombre', cellEditor: 'cellPrueba', width: 200, editable: (params) => {
        const boolEdit = this.isEdit(params.data) ;
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 100,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Nombre Corto', field: 'Nombre Corto', cellEditor: 'cellPrueba', width: 200, editable: (params) => {
        const boolEdit = this.isEdit(params.data) ;
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 25,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Valor', field: 'Valor', cellEditor: 'cellPrueba', width: 100,editable: (params) => {
        const boolEdit = this.isEdit(params.data) ;
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 45,
            tienecalculos: false,
          }
        }
    }

  ]
  constructor(private segmaereferenciaService:SegmaereferenciaService,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private confirmationService: ConfirmationService,
    
    private auditoriaGralService: AuditoriagralService,
    private init: NuevoComponentService
              ) { }

  ngOnInit(): void {

    this.llenarDrop();
    this.segMaeReferenciat=[];
    this.agTable();
    this.btnGuardar = true;
    this.btnRegresar = true;
    this.btnNuevo = true;
    this.btnBorrar=true;
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefSegMaeReferencia = {
      editable: true,
      width: 130,
      objeto: 'maeSegReferecia'
    };
  }
  cambioCmbReferencia(e) {
    this.selectsegMaeReferenciaCmb.TIP_EDITABLE=e.value.editable;
    this.selectsegMaeReferenciaCmb.TIP_CODIGO=e.value.codigo;
    this.buttonsCmb(e.value.editable);

   this.segmaereferenciaService.consultarReferenciaSelect(e.value.codigo).then(res=>{
   
    this.segMaeReferenciat=res;
    this.segMaeReferenciat.map( x => {
      x.NUMERO = this.segMaeReferenciat.indexOf( x ) + 1;
      
      x.NUEVO=false;
    } );
  }).catch(e=>console.log(e.error));
  //this.selectsegMaeReferenciaCmb=this.segMaeReferenciat[0]
  //console.log(this.segMaeReferenciat);
 
  }

  buttonsCmb(valor:string){
    if(valor=="1"){
      this.btnGuardar = true;
      this.btnRegresar = true;
      this.btnBorrar=false;
      this.btnNuevo = false;
    }else{
      this.btnGuardar = true;
      this.btnRegresar = true;
      this.btnNuevo = true;
      this.btnBorrar=true;
    }
  }
  isEdit(elemento){
   
    if(this.selectsegMaeReferenciaCmb.TIP_EDITABLE=="1"){
     
      if (this.aggrid.gridApi.getFocusedCell().column.colId === 'Código') { 
        return true;
      }
      if (this.aggrid.gridApi.getFocusedCell().column.colId === 'Nombre') { 
        return true;
      }
      if (this.aggrid.gridApi.getFocusedCell().column.colId === 'Nombre Corto') { 
        return true;
      }
      if (this.aggrid.gridApi.getFocusedCell().column.colId === 'Valor') { 
        return true;
      }
    }else{
      if (this.aggrid.gridApi.getFocusedCell().column.colId === 'Código') { 
        return false;
      }
      if (this.aggrid.gridApi.getFocusedCell().column.colId === 'Nombre') { 
        return false;
      }
      if (this.aggrid.gridApi.getFocusedCell().column.colId === 'Nombre Corto') { 
        return false;
      }
      if (this.aggrid.gridApi.getFocusedCell().column.colId === 'Valor') { 
        return false;
      }
    }
  }
  cambioModulot(event){
    this.btnGuardar = false;
    this.btnRegresar = false;
    this.btnNuevo = true;
    this.btnBorrar=true;
  }
  selectMaeSegMaeRefereciat(elemento){
    
    if (elemento.object !== null) {
      this.selectedsegMaeReferenciat = elemento.object;
      
     
    }
  }
  async llenarDrop(){
    
    await this.segmaereferenciaService.consultarReferencias().then(res=>{
      
      this.segMaeReferencia=res;
      this.cmbReferencia=[];
      this.cmbReferencia.push({name:'', codigo: '', editable: ''});
      
      this.segMaeReferencia.map(x=>{
        try{
          if(x.TIP_CODIGO!=null || x.TIP_CODIGO!=""){
            
            if(x.TIP_DESCRIPCION!=null || x.TIP_DESCRIPCION!=""){
              this.cmbReferencia.push({name: x.TIP_CODIGO + ' - ' + x.TIP_DESCRIPCION, codigo: x.TIP_CODIGO, editable:x.TIP_EDITABLE});
            }
          }
         
        }catch(err){
          this.segMaeReferencia=[];
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', '! Error al mostrar los Módulos.... ! ' + mensaje);
        }
      }) ;
      //this.strCmbReferencia=this.cmbReferencia[0];
      
    })
  }
  manejarSenales(valor){ 
    switch (valor) {
      case 'Nuevo':
       this.opcionNuevo();
        break;
      case 'Guardar':
        this.opcionGuardar();
        break;
      case 'Borrar':
        this.opcionEliminar();
        break;
      case 'Cancelar':
        this.cargar();
        break;
      case 'Buscar':
        //this.buscarRegistro();
        break;
      case 'Salir':
       this.init.tabs.splice(this.init.tabindex, 1);
        break;
    
    }
  }
  opcionNuevo(){
    let valor;
    if(this.segMaeReferenciat!=null || this.segMaeReferenciat!=undefined){
      valor=this.segMaeReferenciat.length+1
    }else{
      valor=1;
      this.segMaeReferenciat=[];
    }
    const newModulo:  SegmaeReferencia= {  
      Código:'',
      Nombre:'',
      'Nombre Corto':'',
      Tipo:'',
      NUMERO:valor,
      Valor:'',
      NUEVO:true
    };
    try{  
      this.segMaeReferenciat.push(newModulo); 
      this.aggrid.refreshaggrid(this.segMaeReferenciat, this.defaultColDefSegMaeReferencia.objeto ); 
      const indice= this.segMaeReferenciat.indexOf(newModulo);
      this.aggrid.gridApi.setFocusedCell(indice , 'Código');
      ////GSRF
      this.selectedsegMaeReferenciat.NUEVO=true;
      ////GSRF
    }catch(err){
      console.log(err);
    }
  }
  opcionGuardar(){
    this.aggrid.gridApi.stopEditing();
    const modulo: SegmaeReferencia = {
        
      Código:  this.selectedsegMaeReferenciat.Código,
      Nombre: this.selectedsegMaeReferenciat.Nombre,
      'Nombre Corto': this.selectedsegMaeReferenciat["Nombre Corto"],
      Valor: this.selectedsegMaeReferenciat.Valor,
     };
     let valido = true;
    
    for ( const key in modulo ) { 
      if ( (modulo[key] === null || modulo[key] === '') && key !== 'Valor') {
        valido = false;
      }
    }
    if ( valido ) {
      
      let auditoria=`${this.selectsegMaeReferenciaCmb.TIP_CODIGO}/${this.selectedsegMaeReferenciat.Código}`;
      if(this.selectedsegMaeReferenciat.NUEVO==true){
        this.aggrid.gridApi.stopEditing();
          this.segmaereferenciaService.insertarReferencia(this.selectsegMaeReferenciaCmb.TIP_CODIGO,modulo).then(res=>{
              this.cargar();
              ////GSRF
              this.mensajeAlerta('success', 'Ingreso', 'Ingreso exitoso');
              ////GSRF
              this.auditoriaGralService.registrarAuditoria('SACI_MAEREFERENCIA', auditoria, 'I',
              '', '01', '', '', '', '').toPromise().then(() => {
              });
          }).catch(e=>{e})

      }else if(this.selectedsegMaeReferenciat.NUEVO==false){
        this.aggrid.gridApi.stopEditing();
          this.segmaereferenciaService.actualizarReferencia(this.selectsegMaeReferenciaCmb.TIP_CODIGO,modulo).then(res=>{
            this.auditoriaGralService.registrarAuditoria('SACI_MAEREFERENCIA', auditoria, 'A',
            '', '01', '', '', '', '').toPromise().then(() => {
            });  
            this.cargar();
            ////GSRF
            this.mensajeAlerta('success', 'Actualización', 'Actualización exitosa');
            ////GSRF
          }).catch(e=>{e})
      }
    }else{
     
      this.mensajeAlerta('info', 'Información', 'Se han encontrado campos vacíos');
    }
  }
  cargar(){
    this.segmaereferenciaService.consultarReferenciaSelect(this.selectsegMaeReferenciaCmb.TIP_CODIGO).then(res=>{
   
      this.segMaeReferenciat=res;
      this.segMaeReferenciat.map( x => {
        x.NUMERO = this.segMaeReferenciat.indexOf( x ) + 1;
        
        x.NUEVO=false;
      } );
    }).catch(e=>console.log(e.error));
    this.buttonsCmb(this.selectsegMaeReferenciaCmb.TIP_EDITABLE);
    //console.log(this.segMaeReferenciat);
  }
  opcionEliminar(){
    
    
  if ( this.selectedsegMaeReferenciat !== undefined ) {
  let auditoria=`${this.selectsegMaeReferenciaCmb.TIP_CODIGO}/${this.selectedsegMaeReferenciat.Código}`;
    this.confirmationService.confirm({
      message: '¿Está seguro que desea eliminar este registro?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'segmaeReferenciaDialog',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        this.segmaereferenciaService.eliminarReferencia(this.selectsegMaeReferenciaCmb.TIP_CODIGO, this.selectedsegMaeReferenciat).then(res=>{
          this.mensajeAlerta('success', 'Información', '¡Modulo eliminado!');
          this.cargar();
          this.auditoriaGralService.registrarAuditoria('SACI_MAEREFERENCIA', auditoria, 'E',
          '', '01', '', '', '', '').toPromise().then(() => {
          });
        }).catch(e=>{
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        }); 
      },
      reject: () => {
      }
    })
  }
  
  }
  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'segMaeReferenciatoast',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
    
  }
}
