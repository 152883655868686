<div class="p-grid" style="width: 99%">
    <div class="p-col-12">
        <app-invmaebarra [botonNuevo]="true" [botonGuardar]="btnGuardar" [botonBorrar]="true"
                         [botonRegresar]="btnRegresar" [barraBotonesCRUD]="false"
                         [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true"
                         (signal)="manejarSenalesAdi($event)"></app-invmaebarra>
    </div>
</div>
<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="maeNomMatdatadit"></p-toast>
<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px; width: 99%;">
	<div class="p-col-12">
		<div class="p-grid">
			
			<div class="p-col-2">
				<input type="text" pInputText [(ngModel)]="inputcodigo" 
					   style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
					   disabled
					  >
			</div>
			<!-- <div class="p-col-4"></div> -->
			<div class="p-col-7">
				<input type="text" pInputText [(ngModel)]="inputanomape" 
					   style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
					   disabled
					   >
			</div>
		</div>
	</div>
</div>
<div style="overflow: hidden" class="p-col-12">
    <p-tabView>
        <p-tabPanel header="Datos Adicionales">
            <app-aggridsaci [width]=""
            [height]="largoAdi"
            [enableSorting]="true"
            [enableFilter]="true"
            [rowData]="nommaeDatAdi"
            [frameworkComponents]="frameworkComponents"
            [animateRows]="true"
            [rowSelection]="rowSelection"
            [columnDefs]="columnDefMaeDatAdi"
            [defaultColDef]="defaultColDefMaeDatAdi"
            [rowStyle]="rowStyle"
            [singleClickEdit]="false"
            [botones]="false"
            (selected)=selectMaeDatAdi($event)
            (cambios)="cambioDatAdi($event)"
            ></app-aggridsaci>
        </p-tabPanel>
    </p-tabView>
</div>

