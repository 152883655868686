<div class="p-grid">
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-1">
				<button style="background-image: url(../../../assets/images/iconos/visto.png);" (click)="enviarDetalles()"></button>
			</div>
			<div class="p-col-5"></div>
			<div class="p-col-4">
				<p-checkbox label="Seleccionar Todo" [(ngModel)]="allSelected" binary="true" (click)="seleccionar()"></p-checkbox>
			</div>
			<div class="p-col-1"></div>
			<div class="p-col-1">
				<button style="background-image: url(../../../assets/images/iconos/salir.png);" (click)="enviarSalir()"></button>
			</div>
		</div>
	</div>
</div>
<app-aggridsaci [width]=""
				[height]="largo"
				[enableSorting]="true"
				[enableFilter]="true"
				[rowData]="detalleEmpresas"
				[frameworkComponents]="frameworkComponents"
				[animateRows]="true"
				[rowSelection]="rowSelection"
				[columnDefs]="columnDefsEmpresas"
				[defaultColDef]="defaultColDef"
				[rowStyle]="rowStyle"
				[singleClickEdit]="false"
				[botones]="false"
				[mostrarGuardar]="false"
				[botonesAdicionales]="true"
></app-aggridsaci>
