import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { NomencrolService } from 'src/app/nom/nomservicios/nomencrol.service';
import { VacaDispo, VacaXperiodo } from 'src/app/nom/nominterfaces/nomencrol';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { DatePipe } from '@angular/common';
import { MessageService } from 'primeng';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';

@Component({
  selector: 'app-nomencrol3',
  templateUrl: './nomencrol3.component.html',
  styleUrls: ['../../../../advantage/styles/saciwebmae.css']
})
export class Nomencrol3Component implements OnInit {
  @ViewChild('gridDiasVaca') aggrid: AggridsaciComponent;
  @ViewChild('gridTabla2') gridTabla2: AggridsaciComponent;
  @Input() datosIn;
  @Output() salirvac= new EventEmitter<boolean>();
  @Input () datosvac;
  lblNUM_DIASVACA = '';
  lblPERIODO_FECINI = '';
  lblEMP_CODIGO = '';
  txtPERIODO_FECINI;
  txtPERIODO_FECFIN;
  lblPERIODO_CODIGO = '';
  lblRUBRO_CODRUBR = '';
  lblENCROLINDI_CODIGO = '';
  txtTRANVACAEMPL_OBSERVACIONES = '';
  txtTRANVACAEMPL_SALDODIAS= '';
  txtTRANVACAEMPL_VALOR = '';
  dtpEMP_FECHINI;
  dtpEMP_FECHFIN;


  vacaDisponibles: VacaDispo [] = [];
  public frameworkComponents;
  largo = 650;
  rowStyle;
  defaultColDefDiasVaca;
  rowSelection = 'multiple';

  
  vacaXperiodo: VacaXperiodo[] = [];
  defaultColDefVacaXpe;

  /* AUXPANTVACA = {
    dias_vacaciones: 0.00, //As Double
    valor_vacaciones: 0.00, //As Double
    fecha_inicio: '',  //As String
    fecha_fin: '', //As String
    observacion: '', //As String
    detrolindi_diasvacagoza: 0, //As Integer
    detrolindi_valovacagoza: 0.00 //As Double
  } */

  columnDefDiasVaca = [
    {
      headerName: 'Período', field: 'TRANVACAEMPL_PERIODO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: true,
          }
        }
    },

    {
      headerName: 'Días Disponibles', field: 'TRANVACAEMPL_SALDO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: true,
          }
        }
    },

    {
      headerName: 'Toma', field: 'toma', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: true,
          }
        }
    },

    {
      headerName: 'Saldo', field: 'saldo', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: true,
          }
        }
    },
  ]

  columnDefVacaXpe = [
    {
      headerName: 'Período', field: 'PERIODO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: true,
          }
        }
    },

    {
      headerName: 'Fecha Inicio vacaciones ', field: 'FECINI_VACACION', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: true,
          }
        }
    },

    {
      headerName: 'Fecha Fin vacaciones', field: 'FECFIN_VACACION', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: true,
          }
        }
    },

    {
      headerName: '#Días vacaciones', field: 'DIAS_VACATOMA', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: 'Importe', field: 'VACATOMA_IMPORTE', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: true,
          }
        }
    },
  ]

  constructor(
    private rolProcesoSrv: NomencrolService,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private pipe: DatePipe,) {
      this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefDiasVaca = {
      editable: true,
      width: 100,
      objeto: 'gridDiasVaca'
    };
    this.defaultColDefVacaXpe = {
      editable: true,
      width: 130,
      objeto: 'tabla2'
    };
  }

  async ngOnInit() {
   await this.setInData();
    this.funObtieneInfoVacaciones();
  }

  async setInData(){
    this.lblNUM_DIASVACA =      this.datosIn.lblNUM_DIASVACA
    this.lblPERIODO_FECINI =    this.datosIn.lblPERIODO_FECINI
    this.lblEMP_CODIGO =        this.datosIn.lblEMP_CODIGO
    this.txtPERIODO_FECINI =    this.datosIn.txtPERIODO_FECINI
    this.txtPERIODO_FECFIN =    this.datosIn.txtPERIODO_FECFIN
    this.lblPERIODO_CODIGO =    this.datosIn.lblPERIODO_CODIGO
    this.lblRUBRO_CODRUBR =     this.datosIn.lblRUBRO_CODRUBR
    this.lblENCROLINDI_CODIGO = this.datosIn.lblENCROLINDI_CODIGO
    if(this.datosvac.DETROLINDI_EDITABLEVACA === true){
      this.dtpEMP_FECHINI=this.datosvac.DETROLINDI_FECHINIRUBR;
      this.dtpEMP_FECHFIN=this.datosvac.DETROLINDI_FECHFINRUBR
      this.txtTRANVACAEMPL_OBSERVACIONES=this.datosvac.TRANVACAEMPL_OBSERVACIONES;
      await this.cmdCalcVacaciones()
      // this.vacaXperiodo.map( x => {
      //   x.FECFIN_VACACION = this.pipe.transform(x.FECFIN_VACACION,'dd/MM/yyyy');
      //   x.FECINI_VACACION = this.pipe.transform(x.FECINI_VACACION,'dd/MM/yyyy');
      //   x.VACATOMA_IMPORTE = x.VACATOMA_IMPORTE.toFixed(2);
      // }  ) 
      // this.aggrid.refreshaggrid(this.vacaXperiodo, this.defaultColDefVacaXpe.objeto);
      setTimeout(() => {
        document.getElementById('contorno').style.pointerEvents = 'none';      }, 1000);
     
    }
  }


  async funObtieneInfoVacaciones() {
    await this.rolProcesoSrv.obtiDiasVacaDispo(this.lblPERIODO_FECINI, this.lblEMP_CODIGO).then(  res => {
      
      if( res !== null ){
        this.txtTRANVACAEMPL_SALDODIAS = res[0].TRANVACAEMPL_DIASPRORR.toFixed(2);
        this.txtTRANVACAEMPL_VALOR = res[0].TRANVACAEMPL_VALOR.toFixed(2);
      } else {
        this.txtTRANVACAEMPL_SALDODIAS = '';
        this.txtTRANVACAEMPL_VALOR = '';
      }
      this.funObtieneInfoPeriodoVacaciones();
    } ).catch( e => {
      console.error(e)
    } )
  }

  async funObtieneInfoPeriodoVacaciones()  {
    await this.rolProcesoSrv.obtiPerioVacaDispo(this.lblEMP_CODIGO).then(  infoVaca => {
      if( infoVaca !==  null ){

        infoVaca.map(info => {
          if ( info.TRANVACAEMPL_SALDO !== null && info.TRANVACAEMPL_SALDO > 0) {
            
            if ( info.TRANVACAEMPL_PERIODO === null ) {
              info.TRANVACAEMPL_PERIODO = '';
            }
            info.TRANVACAEMPL_SALDO = info.TRANVACAEMPL_SALDO.toFixed(2);
  
            this.vacaDisponibles.push(info);
          } 
  
  
        })
      }

      this.funDescuentaVacacionesPeriodo();
    } ).catch( e => {
      console.error(e)
    } )
  }



  funDescuentaVacacionesPeriodo(){
    let dblSaldoDiasVaca = Number(this.lblNUM_DIASVACA);
    this.vacaDisponibles.map( info => {
      if( dblSaldoDiasVaca > 0   ) {

        if(dblSaldoDiasVaca <= info.TRANVACAEMPL_SALDO){

              
          info.toma = dblSaldoDiasVaca;
          info.saldo = (Number( info.TRANVACAEMPL_SALDO) - dblSaldoDiasVaca).toFixed(2); // dblsaldo = dblDiasDiponibles - dblSaldoDiasVaca
          dblSaldoDiasVaca = dblSaldoDiasVaca - Number(this.lblNUM_DIASVACA);
        } else {

          info.toma = info.TRANVACAEMPL_SALDO;
          info.saldo = 0;
          dblSaldoDiasVaca = dblSaldoDiasVaca - info.TRANVACAEMPL_SALDO;
        }
      }

      this.aggrid.refreshaggrid(this.vacaDisponibles, this.defaultColDefDiasVaca.objeto);
    } )

    
  }

  setDtpEMP_FECHINI(event){

    let fechaFin = new Date( event );
    fechaFin.setDate( fechaFin.getDate() + Number(this.lblNUM_DIASVACA) -1 );

    let month = '' + (fechaFin.getMonth() + 1);
    let day = '' + fechaFin.getDate();
    let year = fechaFin.getFullYear();
  
    if( month.length < 2 ) { month = '0' + month }
    if( day.length < 2 ) { day = '0' + day }
    this.dtpEMP_FECHFIN = `${day}/${month}/${year}`;

    let element = document.getElementById('observaciones');
    element.focus();
  }

  async cmdCalcVacaciones(){

    if( this.txtPERIODO_FECINI === '' ) {
      this.mensajeAlerta('error', 'Vacaciones', 'Ingrese fecha inicial');
      return;
    }

    if( this.txtPERIODO_FECFIN === ''  ){
      this.mensajeAlerta('error', 'Vacaciones', 'Ingrese fecha inicial');
      return;
    }

    if( this.txtTRANVACAEMPL_OBSERVACIONES  === '' ){
      this.mensajeAlerta('error', 'Vacaciones', 'Ingrese observaciones');
      return;
    }

    await this.calculaDiasVacaciones();

  }


  async calculaDiasVacaciones(){//convertir a DATE
    if( this.dtpEMP_FECHINI < this.stringToDate(this.txtPERIODO_FECINI) || this.dtpEMP_FECHINI > this.stringToDate(this.txtPERIODO_FECFIN)){
      this.mensajeAlerta('error', 'Vacaciones', 'Error fecha de inicio de vacaciones no esta dentro del período');
      return;
    } 

    /* if( Number(this.lblNUM_DIASVACA) > Number(this.txtTRANVACAEMPL_SALDODIAS) ){
      this.mensajeAlerta('error', 'Vacaciones', 'Los días de vacaciones superan a los días disponibles de vacaciones');
      return;
    } */

   await this.funObtieneVacaTomadasPorPeriodo();
  }

  async funObtieneVacaTomadasPorPeriodo(){
    let valor_vacaciones = this.funCalculaValorVacaProrDias();

    await this.rolProcesoSrv.obtieneVacaTomadasXPeriodo(
      this.lblENCROLINDI_CODIGO, this.lblEMP_CODIGO, this.lblRUBRO_CODRUBR, this.txtPERIODO_FECFIN,
      this.convertDate(this.dtpEMP_FECHINI), this.lblNUM_DIASVACA, this.txtTRANVACAEMPL_OBSERVACIONES, valor_vacaciones
    ).then ( res => {
      this.funListaVacacionesTomadas();
    } ).catch( e => {
      console.error(e);
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    } )

  }

  funCalculaValorVacaProrDias(){
    let dblDIAS_VACADISP = Number(this.txtTRANVACAEMPL_SALDODIAS)
    let dblVALOR_VACADISP = Number(this.txtTRANVACAEMPL_VALOR)
    let dblDIAS_VACA = Number(this.lblNUM_DIASVACA);
    let resul = dblDIAS_VACA * dblVALOR_VACADISP / dblDIAS_VACADISP
    return resul;

  }

  async funListaVacacionesTomadas(){
    await this.rolProcesoSrv.listaVacacionesTomadas(this.lblENCROLINDI_CODIGO, this.lblRUBRO_CODRUBR).then( infoVaca => {
      
      this.vacaXperiodo = [ ...infoVaca ];
      this.vacaXperiodo.map( x => {
        x.FECFIN_VACACION = this.pipe.transform(x.FECFIN_VACACION,'dd/MM/yyyy');
        x.FECINI_VACACION = this.pipe.transform(x.FECINI_VACACION,'dd/MM/yyyy');
        if(this.datosvac.DETROLINDI_EDITABLEVACA === true){
          x.VACATOMA_IMPORTE = this.datosvac.DETROLINDI_INGRESOS;
        }else{
          x.VACATOMA_IMPORTE = x.VACATOMA_IMPORTE.toFixed(2);
        }
        
      }  ) 
      this.aggrid.refreshaggrid(this.vacaXperiodo, this.defaultColDefVacaXpe.objeto);
    }).catch( e  => {
      console.error(e);
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    } )
  }

  convertDate(date: string) {
    if( date !== '' || date !== undefined || date !== null){

      const fecha = new Date( date );
      
  
      let month = '' + (fecha.getMonth() + 1);
      let day = '' + fecha.getDate();
      let year = fecha.getFullYear();
  
      if( month.length < 2 ) { month = '0' + month }
      if( day.length < 2 ) { day = '0' + day }
  
      return `${day}/${month}/${year}`;
    } 

    return '';
    
  }
  
  stringToDate(fecha: string){
    if ( fecha !== '' ){

      let fechaArray = fecha.split('/');

      return new Date( +fechaArray[2], +fechaArray[1] - 1, +fechaArray[0]) ;

    }
    
  }

  cmd_aceptar(){
    if( this.vacaXperiodo.length === 0 ){
      this.mensajeAlerta('info', 'Vacaciones', `No se tienen registros de días de vacaciones tomados por período`);
      return;
    }
    this. rolProcesoSrv. AUXPANTVACA.fecha_inicio = this.vacaXperiodo[0].FECINI_VACACION;
    this.rolProcesoSrv.AUXPANTVACA.fecha_fin = this.vacaXperiodo[0].FECFIN_VACACION;
    this.rolProcesoSrv.AUXPANTVACA.dias_vacaciones = this.vacaXperiodo[0].DIAS_VACATOMA;
    this.rolProcesoSrv.AUXPANTVACA.valor_vacaciones = Number(this.vacaXperiodo[0].VACATOMA_IMPORTE);
    this.rolProcesoSrv.AUXPANTVACA.observacion = this.txtTRANVACAEMPL_OBSERVACIONES;
    this.rolProcesoSrv.AUXPANTVACA.control=true
    if( this.lblRUBRO_CODRUBR === 'VACG' ){
      this.rolProcesoSrv.AUXPANTVACA.detrolindi_diasvacagoza = this.rolProcesoSrv.AUXPANTVACA.dias_vacaciones;
      this.rolProcesoSrv.AUXPANTVACA.detrolindi_valovacagoza = this.rolProcesoSrv.AUXPANTVACA.valor_vacaciones;
    } 
    if( this.lblRUBRO_CODRUBR === 'VACP' ){
      this.rolProcesoSrv.AUXPANTVACA.detrolindi_diasvacagoza = this.rolProcesoSrv.AUXPANTVACA.dias_vacaciones;
      this.rolProcesoSrv.AUXPANTVACA.detrolindi_valovacagoza = this.rolProcesoSrv.AUXPANTVACA.valor_vacaciones;
    } 

    this.guardaObservVacaGozaPagadas();

  }

  async guardaObservVacaGozaPagadas(){
    await this.rolProcesoSrv.guardaObservVacaGozaPagadas(this.lblENCROLINDI_CODIGO, this.txtTRANVACAEMPL_OBSERVACIONES).then( infoVaca => {
      this.actualizaEstaVacaPeriActual();
    }).catch( e  => {
      console.error(e);
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    } )
  }

  async actualizaEstaVacaPeriActual(){
    await this.rolProcesoSrv.actualizaEstaVacaPeriActual(this.lblPERIODO_CODIGO, this.lblEMP_CODIGO).then( infoVaca => {
     
      this.mensajeAlerta('success', 'Vacaciones', 'Se han guardado los datos');
      this.salirvac.emit(false);
    }).catch( e  => {
      console.error(e);
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    } )
  }

  cmd_cancelar(){
    this.rolProcesoSrv.AUXPANTVACA = {
      dias_vacaciones: 0.00,
      valor_vacaciones: 0.00,
      fecha_inicio: '',
      fecha_fin: '',
      observacion: '', 
      detrolindi_diasvacagoza: 0,
      detrolindi_valovacagoza: 0.00,
      control:false
    }

    this.salirvac.emit(false);

  }


  

  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'rolProceso3',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
  }



}
