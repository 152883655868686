import { Component, OnInit, ViewChild } from '@angular/core';
import { ComnotrecEC } from '../cominterfaces/comnotrec';
import { ComnotrecDetalle } from '../cominterfaces/comnotrecDetalle';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { CommaeEC } from '../cominterfaces/proveedorTablaEC';
import { ComnotrecImp } from '../cominterfaces/comnotrecImp';
import { ComnotrecVar } from '../cominterfaces/comnotrecVar';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { ComnotrecService } from '../comservicios/comnotrec.service';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { CommaeproveedorService } from '../comservicios/commaeproveedor.service';
import { ComdetnotrecService } from '../comservicios/comdetnotrec.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';

// Ag-grid
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { ComExistenciaBodegas, ComExistencias, ComMovimientoProv, ComTopProductos } from '../cominterfaces/comestadisticas';
import { VenestadisticaService } from '../../ven/venservicios/venestadistica.service';
import { Cxctmptrnresumen } from '../../ven/veninterfaces/cxctmptrnresumen';
import { Cxctrnresumen } from '../../ven/veninterfaces/cxctrnresumen';
import { ComestadisticasService } from '../comservicios/comestadisticas.service';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import { Usuario } from 'src/app/usuario';
import { finalize } from 'rxjs/operators';//GSRF
import { Comencnotrecbasesiva } from "../cominterfaces/comecnnotrecbasesiba";

@Component({
  selector: 'app-comnotrec',
  templateUrl: './comnotrec.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class ComnotrecComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  indicador: any;
  index: number;
  okay: string;
  tipodetalle: string;

  auxContEC: number;
  debeTotal: number;
  haberTotal: number;
  displayDialogDescuento: boolean;
  displayDialogLotes: boolean;
  displayAcciones: boolean;

  dscProveedorActual: number;
  proveedorNombre: string;
  btnNuevo: boolean;
  btnBorrar: boolean;
  codnombre: string;
  btnGuardar: boolean;
  btnPrimero: boolean;
  btnOrdenCompra: boolean;
  btnBuscar: boolean;
  ivaB: boolean;
  btnNuevoGrid: boolean;
  errores: number;
  btnBorrarGrid: boolean;
  btnCancelarGrid: boolean;
  btnAnterior: boolean;
  btnSiguiente: boolean;
  btnUltimo: boolean;
  consulta: string;
  btnRegresar: boolean;
  indice: number;
  codigoAnt: string;
  auxEstado: string;
  hmpConfiguracion: any[];
  aucref: boolean;//CDPJ
  opcionbusqueda: string;
  busquedacampos: string[];
  tabla: string;
  where: string;

  defaultProvS;
  defaultProvEC;
  dscSeleccionado: string;
  valorDscValor: number;
  valorDscPorcentaje: number;
  types: SelectItem[];
  arregloCons: any[];
  displayDialogBusquedaFast: boolean;
  notRecSeleccionado: ComnotrecEC;
  notRecSeleccionadoAux: ComnotrecEC;
  notRecRecepcion: ComnotrecDetalle;
  notRecImportacion: ComnotrecImp;
  notRecVarios: ComnotrecVar;
  permisos: SegMaePermiso;
  strFechaEntrega?;
  strFechaEmision?;
  listaSaldos: CommaeEC[];
  public frameworkComponents;
  auxNeto: number;
  auxDscto: number;
  auxporcDscto: number;
  auxBaIva: number;
  auxIva: number;
  auxBaice: number;
  auxIce: number;
  auxBaCero: number;
  auxTotal: number;
  auxBod: string;
  auxBodCod: string;
  displayDialogBusqueda: boolean;
  DSCARTCOM?;
  displayDialogSeriales: boolean;
  displayDialogAsiento: boolean;
  strNumFact: any;
  strFechaSer: any;
  displayDialogAutorizacion: boolean;

  totalimporte: number;
  totalpagado: number;
  totalsaldo: number;
  newFila: boolean;
  booleanValor: boolean;
  booleanPorcentaje: boolean;
  valorInvalidoV: boolean;
  valorInvalidoP: boolean;
  boolDistribuir: boolean;
  boolReverzar: boolean;
  chkvalor: boolean;
  chkvolumen: boolean;
  chkpeso: boolean;
  chkfactor: boolean;
  numberchk: number;
  banderaVarios: boolean;
  //CDPJ
  displayDialogTasa: boolean;
  claveDialog: boolean;
  //CDPJ
  //CDPJ
  btnTasaCambio: boolean;
  //CDPJ
  //CDPJ
  tasaSeleccionado: string;
  //CDPJ

  //CDPJ
  valorTasaCambio: number;
  ValorOriginal: number;
  //CDPJ
  //CDPJ
  booleanTasa: boolean;
  booleanOriginal: boolean;
  booleanAOriginal: boolean = true;
  booleanATasa: boolean = false;
  bolean: boolean = false;
  //CDPJ
  //CDPJ
  notRecTasaCambio: ComnotrecEC;
  chkvalorDis: boolean;
  chkvolumenDis: boolean;
  chkpesoDis: boolean;
  chkfactorDis: boolean;
  chkmercons: boolean;

  auxiliar: number;
  auxiliarlot = 0;

  claveAuth: string;
  autorizacionCodigo: string;
  strEncOrdCom: string;
  mercons: string[];
  banderaSeriales: boolean;
  rowStyle;
  rowSelection = 'multiple';
  largo: string;
  largoestcta: string;
  largosaldo: string;
  comboOpciones: any[];
  defaultRecepcion;
  defaultImportaciones;
  defaultVarios;

  // Asiento Contable
  displayAsiContable: boolean;

  displayDialogReportes: boolean;
  auxreporte: number = 0;
  strNombreForma: string;

  NumAsiRegCont: string;
  cxctmptrnresumen: Cxctmptrnresumen[];
  cxctrnresumen: Cxctrnresumen[];
  bolestcta: boolean;
  bolsaldo: boolean;
  //CDPJ
  valor: string;
  basesiva:Comencnotrecbasesiva[]=[]
  displaybasesiva:boolean=false;
  defaultColBasesIva;
  iva:number
  //CDPJ
  validarNumero() {
    this.valor = (<HTMLInputElement>document.getElementById("ENCNOTREC_TASA_CAMBIO")).value
    console.log(this.valor);
    (<HTMLInputElement>document.getElementById("ENCNOTREC_TASA_CAMBIO")).value = (this.valor.indexOf(".") >= 0) ? (this.valor.substr(0, this.valor.indexOf(".")) + this.valor.substr(this.valor.indexOf("."), 5)) : this.valor;
  }
  //CDPJ
  //CDPJ
  // ESTADISTICAS

  detTopProductos: ComTopProductos[] = [];
  dataComprasProveedor: any;
  dataVentasVsCompras: any;
  disabledEstadisticas = true;
  options = {
    legend: {
      display: false
    }
  };
  defaultColTopProductos;
  defaultColMovByProv;
  defaultColExistBod;
  rowStyleTopProd;
  detalleExistencia: ComExistencias[] = [];
  detMovimientosProv: ComMovimientoProv[] = [];
  detExistenciaBodegas: ComExistenciaBodegas[] = [];
  txtArtCodigo = '';
  txtArtDescripcion = '';
  txtArtUnidad = '';
  txtArtRotacion = '';
  txtExistenciaMin: any = '';
  txtExistenciaMax: any = '';
  txtInventarioIni: any = '';
  txtComprasPeriodo: any = '';
  txtTotalDisponible: any = '';
  txtVenUltimoPer: any = '';
  txtVenPromPerAnt: any = '';
  txtIndiceProvUltPer: any = '';
  txtIndiceProvPeriodos: any = '';
  txtComprasPlanificadas: any = '';
  txtTotalDisponibleCom: any = '';
  txtIndiceProvUltPer2: any = '';
  txtIndiceProvPeriodos2: any = '';

  displayDialogDocumentos = false;
  //GSRF
  detnumero: any = '';
  spin = false;

  columnDefsRecepcion = [
    { // 0
      headerName: 'Bodega', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 75, editable: false, pinned: 'left',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Código', field: 'DETNOTREC_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: true, pinned: 'left',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 20,
          opcionbuscar: true,
          tienecalculos: true
        }
      }
    },
    { // 2
      headerName: 'Descripción', field: 'DETNOTREC_DESCRIPCION', cellEditor: 'cellPrueba', width: 375, editable: true, pinned: 'left',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 25,
          opcionbuscar: true

        }
      }
    },
    { // 3
      headerName: 'IVA', field: 'DETNOTREC_TRIBIVA', cellEditor: 'cellPrueba', width: 75, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 1,
          tienecalculos: true
        }
      }
    },
    { // 4
      headerName: 'ICE', field: 'DETNOTREC_TRIBICE', cellEditor: 'cellPrueba', width: 75, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 1,
          tienecalculos: true
        }
      }
    },
    { // 5
      headerName: 'Unidad', field: 'DETNOTREC_UNIDAD', cellEditor: 'cellPrueba', width: 75, editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 25,
          tienecalculos: true
        }
      }
    },
    { // 6
      headerName: 'Cantidad',
      field: 'DETNOTREC_CANTIDAD',
      cellEditor: 'cellPrueba',
      width: 75,
      editable: true,
      cellStyle: { textAlign: 'right' },
      onCellClicked: (params) => {
        this.abrirLotes(params.data);
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 2,
          newrow: false,
          numerodecimales: this.comnotrecServicio.decimalesCantidad,
          obligatorio: true,
          fecha: false,
          tamanocelda: 25,
          tienecalculos: true

        }
      }
    },
    { // 7
      headerName: 'Fact', field: 'DETNOTREC_FACTOR', cellEditor: 'cellPrueba', width: 75, editable: true, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: this.confIniciales.getNumDecSist(),
          obligatorio: false,
          fecha: false,
          tamanocelda: 25,
          tienecalculos: true
        }
      }
    },
    { // 8
      headerName: 'Costo', field: 'DETNOTREC_COSTO', cellEditor: 'cellPrueba', width: 125, editable: true, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          newrow: this.newFila,
          alphabetic: true,
          saltoslinea: 1,

          numerodecimales: this.comnotrecServicio.decimalesCosto,
          obligatorio: true,
          fecha: false,
          tamanocelda: 25,
          tienecalculos: true
        }
      }
    },
    { // 9
      headerName: 'Dsc1(%)', field: 'DETNOTREC_DESCUENTO', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 90,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: this.confIniciales.getNumDecSist(),
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true

        }
      }
    },
    { // 10
      headerName: 'Dsc2(%)', field: 'DETNOTREC_DESCUENTO2', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 90,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: this.confIniciales.getNumDecSist(),
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 11
      headerName: 'Dsc3(%)', field: 'DETNOTREC_DESCUENTO3', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 90,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: !this.newFila,
          numerodecimales: this.confIniciales.getNumDecSist(),
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Total', field: 'DETNOTREC_TOTAL', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 75, editable: false,
      onCellClicked: (params) => {
        this.abrirLotes(params.data);
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: this.comnotrecServicio.decimalesTotal,
          obligatorio: false,
          fecha: false,
          tamanocelda: 25
        }
      }
    },
    {
      headerName: 'Factor(%)', field: 'DETNOTREC_PORFACTOR', cellEditor: 'cellPrueba', width: 75, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: this.confIniciales.getNumDecSist(),
          obligatorio: false,
          fecha: false,
          tamanocelda: 25
        }
      }
    },
    {
      headerName: 'Costo FOB', field: 'DETNOTREC_COSTOFOB', cellEditor: 'cellPrueba', width: 110, editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: this.confIniciales.getNumDecSist(),
          obligatorio: false,
          fecha: false,
          tamanocelda: 25
        }
      }
    },
    {
      headerName: 'Peso', field: 'DETNOTREC_ARTPESO', cellEditor: 'cellPrueba', width: 75, editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: this.confIniciales.getNumDecSist(),
          obligatorio: false,
          fecha: false,
          tamanocelda: 25
        }
      }
    },
    {
      headerName: 'CentroC.', field: 'CEN_CODIGO', cellEditor: 'cellPrueba', width: 75, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: this.confIniciales.getNumDecSist(),
          obligatorio: false,
          fecha: false,
          tamanocelda: 25
        }
      }
    },
    {
      headerName: 'Cant. Und.', field: 'DETNOTREC_CANTIDADUND', cellEditor: 'cellPrueba', width: 75, editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: this.confIniciales.getNumDecSist(),
          obligatorio: false,
          fecha: false,
          tamanocelda: 25
        }
      }
    }
    ,
    { // GSRF
      headerName: 'Artículo', field: 'DETNOTREC_AJUSTE', cellEditor: 'cellPrueba', width: 120,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tienecalculos: true,
          tamanocelda: 32,
          //regexp: /^[S]$|^[s]$/,
        }
      }
    },
    //CDPJ
    { 
      headerName: '%IVA', field: 'DETNOTREC_PORIVA', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          int: true,
          opcionbuscar: true
        }
      }
    }
  ];
  // Impoortaciones
  columnDefsImportaciones = [
    {
      headerName: 'Código', field: 'REF_CODIGO', cellEditor: 'cellPrueba', width: 100,
      editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: true
        }
      }
    },
    {
      headerName: 'Descripción', field: 'REF_NOMBRE', cellEditor: 'cellPrueba', width: 250, editable: true,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
        }
      }
    },
    {
      headerName: 'Base', field: 'NOTRECIMP_BASE', cellEditor: 'cellPrueba', width: 100, editable: true,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tienecalculos: true,
          tamanocelda: 32


        }
      }
    },
    {
      headerName: 'Porcentaje', field: 'NOTRECIMP_PORCENTAJE', cellEditor: 'cellPrueba', width: 100, editable: true,
      // , editable: true, CODSRI_DESCRIPCION AUTSRI_FECHAINI AUTSRI_FECHAFIN AUTSRI_SERIESTAB
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tienecalculos: true,
          tamanocelda: 5


        }
      }
    },
    {
      headerName: 'Importe', field: 'NOTRECIMP_IMPORTE', cellEditor: 'cellPrueba', width: 100, editable: true,
      // , editable: true, CODSRI_DESCRIPCION AUTSRI_FECHAINI AUTSRI_FECHAFIN AUTSRI_SERIESTAB
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
        }
      }
    },
    {
      headerName: 'Nom. Proveedor', field: 'PRO_NOMBRE', cellEditor: 'cellPrueba', width: 250,
      editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Num. Documento', field: 'NUM_DOCUMENTO', cellEditor: 'cellPrueba', width: 130, editable: true,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 25,
          opcionbuscar: true

        }
      }
    }
  ];
  // varios
  columnDefsVarios = [
    {
      headerName: 'Ref.Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 150,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: true,
          opcionbuscar: true,

        }
      }
    },
    {
      headerName: 'Descripción', field: 'CON_NOMBRE', cellEditor: 'cellPrueba', width: 250, editable: true,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
        }
      }
    },
    {
      headerName: 'Creditos',
      field: 'NOTRECVAR_IMPORTE',
      cellEditor: 'cellPrueba',
      width: 100,
      editable: true,
      cellStyle: { textAlign: 'right' },
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,

        }
      }
    },
    //CDPJ
    {
      headerName: 'Cnt. Costos', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
      editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 35
        }
      }
    }
    //CDPJ
  ];

  columnDefsCxctmptrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC', width: 100,
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Debe', field: 'DEBE', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Haber', field: 'HABER', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: { textAlign: 'right' },
    },
  ];

  columnDefsCxctrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC'
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Importe', field: 'TRNCOBRO_IMPORTE', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Pagado', field: 'PAGADO', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: { textAlign: 'right' },
    },
  ];


  columnDefsTopProductos = [
    {
      headerName: 'Producto', field: 'DETFACPRO_CODIGO', width: 115, editable: false
    },
    {
      headerName: 'Descripción', field: 'DETFACPRO_DESCRIPCION', width: 190, editable: false
    },
    {
      headerName: 'Cantidad', field: 'CANTIDAD_TOTAL', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Precio', field: 'DETFACPRO_COSTO', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Total', field: 'TOTAL', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Stock', field: 'STOCK', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];

  columnDefsMovimientosByProv = [
    {
      headerName: 'Proveedor', field: 'PRO_CODIGO', width: 115, editable: false
    },
    {
      headerName: 'Nombre', field: 'PRO_NOMBRE', width: 190, editable: false
    },
    {
      headerName: 'Fecha Compra', field: 'ENCFACPRO_FECHAEMISION', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Costo', field: 'DETFACPRO_COSTO', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];

  columnDefsExistenciaBodegas = [
    {
      headerName: 'Código', field: 'BOD_CODIGO', width: 115, editable: false
    },
    {
      headerName: 'Nombre', field: 'BOD_NOMBRE', width: 190, editable: false
    },
    {
      headerName: 'Existencia', field: 'EXISTENCIA', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Pedidos', field: 'PEDIDO', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Saldo', field: 'SALDO', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];
  //CDPJ
  columnDefsBasesIva = [
    {
      headerName: '%IVA', field: 'TARIIVA_DESCRIPCION', width: 115, editable: false
    },
    {
      headerName: 'Base', field: 'IVA_BASE', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Valor IVA', field: 'IVA_VALOR', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];
  //CDPJ
  asiento: any;


  constructor(public comnotrecServicio: ComnotrecService, public comdetnotrecServicio: ComdetnotrecService,
    private busqService: InvbusquedaService, private messageService: MessageService,
    private datePipe: DatePipe, private auditoriagral: AuditoriagralService, private confIniciales: ConfInicialesService,
    private permisosService: PermisosService, private confirmationService: ConfirmationService,
    private proveedorServicio: CommaeproveedorService, private init: NuevoComponentService,
    private venEstadisticasService: ComestadisticasService, private utilitariosService: UtilitariosService,
    public usuario: Usuario) {
    this.agTable();
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador] = [];
    this.comnotrecServicio.comnotrecImportacionarray[this.indicador] = [];
    this.comnotrecServicio.comnotrecVararray[this.indicador] = [];
  }

  async ngOnInit() {
    this.displayAsiContable = false;
    this.banderaVarios = false;
    this.btnPrimero = false;
    this.btnAnterior = false;
    this.btnSiguiente = false;
    this.btnUltimo = false;
    this.numberchk = 0;
    this.errores = 0;
    //CDPJ
    this.btnTasaCambio = true;
    //CDPJ
    this.btnOrdenCompra = true;
    this.btnNuevoGrid = true;
    this.btnBorrarGrid = true;
    this.displayDialogAutorizacion = false;
    this.asiento = '';
    this.btnCancelarGrid = true;

    this.auxiliar = 0;

    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = true;
    this.btnBuscar = false;
    this.boolDistribuir = false;
    this.boolReverzar = true;
    this.chkvalor = false;
    this.chkvolumen = false;
    this.chkpeso = false;
    this.chkfactor = false;
    this.chkvalorDis = false;
    this.chkvolumenDis = false;
    this.chkpesoDis = false;
    this.chkfactorDis = false;
    this.displayAcciones = false;

    this.banderaSeriales = true;
    this.displayDialogDescuento = false;
    this.displayDialogSeriales = false;
    this.displayDialogAsiento = false;
    //CDPJ
    this.displayDialogTasa = false;
    //CDPJ
    this.indice = 0;
    this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador] = [];
    this.comnotrecServicio.comnotrecImportacionarray[this.indicador] = [];
    this.comnotrecServicio.comnotrecVararray[this.indicador] = [];
    this.comnotrecServicio.getEncnotRec().subscribe((res) => {

      this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador] = res;
    });
    this.codigoAnt = '';
    this.index = 0;

    this.okay = '';
    this.largo = '-5800';
    this.largoestcta = '400';
    this.largosaldo = '400';
    this.dscProveedorActual = 0;
    this.notRecSeleccionado = {};
    this.notRecSeleccionadoAux = {};
    this.notRecRecepcion = {};
    this.notRecVarios = {};

    this.strFechaEmision = new Date();
    this.strFechaEntrega = new Date();
    this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador] = [];
    this.comboOpciones = [{ label: 'A', value: 'A' },
    { label: '*', value: '*' }];
    this.tipodetalle = 'A';
    this.permisosService.getListaPermisos('COM', '6', '633').subscribe((res) => {
      this.permisos = res[0];
    });
    this.auditoriagral.obtenerConfiguracion1().subscribe((conf) => {
      this.newFila = true;
      this.hmpConfiguracion = conf;
      this.comnotrecServicio.hmpConfiguracion = conf;
      this.DSCARTCOM = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DSCARTCOM').CFG_VALOR1;
      this.comnotrecServicio.bolConsideraIvaarray[this.indicador] = !this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'IVANR').CFG_VALOR1;

      if (this.DSCARTCOM !== 1) {
        this.columnDefsRecepcion.splice(11, 1);
        this.columnDefsRecepcion.splice(10, 1);
        this.columnDefsRecepcion.splice(9, 1);
        this.newFila = false;

      }
      //CDPJ
      const conaucref = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'AUCREFNT').CFG_VALOR1;
      if (conaucref === 1) {
        this.aucref = true;
      } else {
        this.aucref = false;
      }
      const cencosvarnt = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CENCOSNT').CFG_VALOR1;
      if (cencosvarnt === 0 || cencosvarnt === null || cencosvarnt === undefined) {
        this.columnDefsVarios.splice(4, 1);
      }

      //CDPJ
      this.aggrid.refreshColumnDefs();
    });
    this.confIniciales.getDecimales('frmCOM_ENCNOTREC').subscribe((dec) => {
      dec.map(aux => {
        if (aux.NUMDEC_CAMPO === 'CANTIDAD') {
          this.comnotrecServicio.decimalesCantidad = Number(aux.NUMDEC_NUMDEC);
        } else if (aux.NUMDEC_CAMPO === 'COSTO') {
          this.comnotrecServicio.decimalesCosto = Number(aux.NUMDEC_NUMDEC);

        } else if (aux.NUMDEC_CAMPO === 'COSTOTOTAL') {
          this.comnotrecServicio.decimalesCostoTotal = Number(aux.NUMDEC_NUMDEC);
        } else if (aux.NUMDEC_CAMPO === 'TOTAL') {
          this.comnotrecServicio.decimalesTotal = Number(aux.NUMDEC_NUMDEC);
        }
      });
      this.comnotrecServicio.decimalesGenerales = Number(this.confIniciales.getNumDecSist());
      this.aggrid.refreshColumnDefs();
    });
    this.generarMercons();
    this.bolestcta = false;
    this.bolsaldo = false;
    this.iva = await this.confIniciales.obtenerImpuesto('I', 'A');
    await this.confIniciales.obtenerSrifeTariIva()
    this.comnotrecServicio.porceiva=this.iva
    let data1=this.confIniciales.srifetariiva.find(element => Number(element.TARIIVA_PORCENTAJE) === Number(this.comnotrecServicio.porceiva))
    this.comnotrecServicio.tariivacod=data1.TARIIVA_CODIGO
  }
  //CDPJ
  ngOnDestroy(): void {
    this.confIniciales.instanciasNotRec--;
  }
  //CDPJ
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.rowStyleTopProd = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if (params.data.DETFACPRO_CODIGO === '' || params.data.DETFACPRO_CODIGO === null) {
        return { background: 'rgb( 164, 255, 167)' };
      }
    };

    this.defaultRecepcion = {
      editable: true,
      width: 130,
      objeto: 'comnotrecRecepcion'
    };

    this.defaultProvEC = {
      editable: false,
      width: 100,
      objeto: 'commaeproveedorEC'
    };
    this.defaultProvS = {
      editable: false,
      width: 100,
      objeto: 'commaeproveedorS'
    };
    this.defaultImportaciones = {
      editable: false,
      width: 100,
      objeto: 'comnotrecImportaciones'
    };
    this.defaultVarios = {
      editable: false,
      width: 100,
      objeto: 'comnotrecVarios'
    };

    this.defaultColTopProductos = {
      editable: true,
      width: 100,
      objeto: 'topProductos',
      resizable: true
    };

    this.defaultColMovByProv = {
      editable: true,
      width: 100,
      objeto: 'movproveedores',
      resizable: true
    };

    this.defaultColExistBod = {
      editable: true,
      width: 100,
      objeto: 'existenciabodegas',
      resizable: true
    };
    //CDPJ
    this.defaultColBasesIva = {
      editable: false,
      width: 100,
      objeto: 'basesiva',
    };
    //CDPJ
  }

  generarMercons() {
    this.mercons = [];
    this.comnotrecServicio.ejecutarConsulta1().subscribe(res => {
      for (const rs of res) {
        this.mercons.push(rs.ART_CODIGO);
      }
    });
  }

  validarMercons(): any {
    let retornar = 0;
    this.mercons.map(aux => {
      this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].map(aux2 => {
        if (aux === aux2.DETNOTREC_CODIGO) {
          retornar++;
        }
      });
    });
    return retornar;
  }

  async manejarSenales(valor) {
    if (valor !== 'Nuevo' && valor !== 'Cancelar'
      && valor !== 'Primero' && valor !== 'Anterior' && valor !== 'Siguiente' && valor !== 'Ultimo'
      && valor !== 'Buscar' && valor !== 'Imprimir' && valor !== 'AsientoVer' && valor !== 'Salir') {
      // Verifica el periodo contable

      const dia = this.strFechaEmision.getDate();
      const mes = this.strFechaEmision.getMonth();
      const anio = this.strFechaEmision.getFullYear();

      if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
        this.messageService.add({
          key: 'notrec',
          severity: 'info',
          summary: 'Información',
          detail: 'Error, fecha debe de estar dentro del período contable'
        });
        return;
      }
    }

    if (this.index === 5) {
      return;
    }

    if (this.errores === 0) {
      if (valor === 'Primero') {
        // if (this.banderaSeriales) {
        //   this.btnAnterior = true;
        //   this.btnPrimero = true;
        //   this.btnUltimo = false;
        //   this.btnSiguiente = false;
        //   this.indice = 0;
        //   this.seleccionarEncabezado();
        //   this.llenarLabes();
        // }
        //CDPJ
        if (this.verificarAsiento() === true) {
          if (this.banderaSeriales) {
            this.btnAnterior = true;
            this.btnPrimero = true;
            this.btnUltimo = false;
            this.btnSiguiente = false;
            this.indice = 0;

            this.seleccionarEncabezado();
            this.llenarLabes();

          }
        }
        //CDPJ

      }
      if (valor === 'Anterior') {
         //CDPJ
         if (this.verificarAsiento()=== true){
          if (this.banderaSeriales) {
            this.btnRegresar = false;
  
            if (!this.btnPrimero && !this.btnAnterior && !this.btnSiguiente && !this.btnUltimo && this.indice === 0) {
              this.indice = 0;
              this.btnPrimero = true;
              this.btnAnterior = true;
              this.btnSiguiente = false;
              this.btnUltimo = false;
            } else {
              this.indice--;
              this.btnPrimero = false;
              this.btnAnterior = false;
              this.btnSiguiente = false;
              this.btnUltimo = false;
              if (this.indice === 0) {
                this.btnPrimero = true;
                this.btnAnterior = true;
              }
            }
  
            this.seleccionarEncabezado();
            this.llenarLabes();
          }
  
        }
        //CDPJ
        // if (this.banderaSeriales) {
        //   this.btnRegresar = false;
        //   if (!this.btnPrimero && !this.btnAnterior && !this.btnSiguiente && !this.btnUltimo && this.indice === 0) {
        //     this.indice = 0;
        //     this.btnPrimero = true;
        //     this.btnAnterior = true;
        //     this.btnSiguiente = false;
        //     this.btnUltimo = false;
        //   } else {
        //     this.indice--;
        //     this.btnPrimero = false;
        //     this.btnAnterior = false;
        //     this.btnSiguiente = false;
        //     this.btnUltimo = false;
        //     if (this.indice === 0) {
        //       this.btnPrimero = true;
        //       this.btnAnterior = true;
        //     }
        //   }

        //   this.seleccionarEncabezado();
        //   this.llenarLabes();
        // }


      }
      if (valor === 'Siguiente') {
        //CDPJ
        if (this.verificarAsiento()=== true){
          if (this.banderaSeriales) {
            if (!this.btnPrimero && !this.btnAnterior && !this.btnSiguiente && !this.btnUltimo && this.indice === 0) {
              this.indice = 0;
              this.btnPrimero = true;
              this.btnAnterior = true;
              this.btnSiguiente = false;
              this.btnUltimo = false;
  
            } else {
              this.indice++;
              this.btnPrimero = false;
              this.btnAnterior = false;
              this.btnSiguiente = false;
              this.btnUltimo = false;
              if (this.indice === this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador].length - 1) {
                this.btnSiguiente = true;
                this.btnUltimo = true;
              }
            }
  
  
            this.btnRegresar = false;
  
            this.seleccionarEncabezado();
            this.llenarLabes();
          }
        }
        //CDPJ
        // if (this.banderaSeriales) {
        //   if (!this.btnPrimero && !this.btnAnterior && !this.btnSiguiente && !this.btnUltimo && this.indice === 0) {
        //     this.indice = 0;
        //     this.btnPrimero = true;
        //     this.btnAnterior = true;
        //     this.btnSiguiente = false;
        //     this.btnUltimo = false;

        //   } else {
        //     this.indice++;
        //     this.btnPrimero = false;
        //     this.btnAnterior = false;
        //     this.btnSiguiente = false;
        //     this.btnUltimo = false;
        //     if (this.indice === this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador].length - 1) {
        //       this.btnSiguiente = true;
        //       this.btnUltimo = true;
        //     }
        //   }


        //   this.btnRegresar = false;

        //   this.seleccionarEncabezado();
        //   this.llenarLabes();
        // }

      }
      if (valor === 'Ultimo') {
        //CDPJ
        if (this.verificarAsiento()=== true){
          if (this.banderaSeriales) {
            this.btnUltimo = true;
            this.btnSiguiente = true;
            this.btnPrimero = false;
            this.btnAnterior = false;
            this.btnRegresar = false;
            this.indice = this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador].length - 1;
            this.seleccionarEncabezado();
            this.llenarLabes();
          }
        }
        //CDPJ
        // if (this.banderaSeriales) {
        //   this.btnUltimo = true;
        //   this.btnSiguiente = true;
        //   this.btnPrimero = false;
        //   this.btnAnterior = false;
        //   this.btnRegresar = false;
        //   this.indice = this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador].length - 1;
        //   this.seleccionarEncabezado();
        //   this.llenarLabes();
        // }

      }
      if (valor === 'Buscar') {
        //this.manejarSenales('Cancelar');

        // if (this.banderaSeriales) {
        //   this.buscarNota();
        // }
        //CDPJ
        if (this.verificarAsiento()=== true){
          if (this.banderaSeriales) {
            this.buscarNota();
          }
        }
        //CDPJ
      }
      if (valor === 'Nuevo') {
        //CDPJ
        if (this.verificarAsiento()=== true){
          //CDPJ
          this.booleanATasa = false;
          this.booleanAOriginal = true;
          //CDPJ
          if (this.permisos.PERINSERCION === 1) {
            this.index = 0;
            this.btnGuardar = false;
            this.btnNuevo = true;
            this.btnBorrar = true;
            this.btnRegresar = false;
            this.btnPrimero = true;
            this.btnAnterior = true;
            this.btnSiguiente = true;
            this.btnUltimo = true;
            this.btnRegresar = true;
            this.btnOrdenCompra = false;
            this.btnNuevoGrid = false;
            //CDPJ
            this.btnTasaCambio = false;
            this.booleanATasa = false;
            this.booleanAOriginal = true;
            this.boolDistribuir = false;
            this.boolReverzar = true;
            this.chkfactor = false;
            this.chkpeso = false;
            this.chkvolumen = false;
            this.chkvalor = false;
            this.btnNuevoGrid = false;
            this.btnBorrarGrid = false;
            this.btnCancelarGrid = false;
            this.numberchk = 0;
            this.bolean = false;
            this.comnotrecServicio.porceiva=this.iva
            let data1=this.confIniciales.srifetariiva.find(element => Number(element.TARIIVA_PORCENTAJE) === Number(this.comnotrecServicio.porceiva))
            this.comnotrecServicio.tariivacod=data1.TARIIVA_CODIGO
            //CDPJ
            this.btnBorrarGrid = false;
            this.btnCancelarGrid = true;
            const iva = this.comnotrecServicio.porceiva;
            document.getElementById('divPrincipal').style.pointerEvents = 'all';
            // document.getElementById('divPrincipal').style.opacity = '1.0';
            this.aggrid.habilitarGrid();
            this.ivaB = false;
            this.auxBod = '';
            this.auxEstado = 'PENDIENTE';
            this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador] = [];
            this.comnotrecServicio.comnotrecImportacionarray[this.indicador] = [];
            this.comnotrecServicio.comnotrecVararray[this.indicador] = [];
            this.comnotrecServicio.dblValorDescGlbOrdarray[this.indicador] = 0;
            this.comnotrecServicio.dblPorcDescGlbOrdarray[this.indicador] = 0;
            this.notRecSeleccionado = {
              COM_CODIGO: '',
              ENCNOTREC_CONTACTO: '',
              ENCNOTREC_FECHAEMISION: this.strFechaEmision,
              ENCNOTREC_FECHAENTREGA: this.strFechaEntrega,
              ENCNOTREC_IVA: '',
              ENCNOTREC_ESTADO: 'P',
              ENCNOTREC_OBSERVACION: '',
              ENCNOTREC_TOTAL: Number(0).toFixed(this.comnotrecServicio.decimalesCostoTotal),
              ENCNOTREC_TOTALNETO: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
              ENCNOTREC_VALORIVA: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
              ENCNOTREC_PORCEIVA: Number(iva).toFixed(2),
              ENCNOTREC_VALORICE: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
              ENCNOTREC_BASEIVA: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
              ENCNOTREC_BASEICE: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
              ENCNOTREC_BASECERO: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
              ENCNOTREC_NUMERO: null,
              PRO_CODIGO: '',
              ENCNOTREC_REFERENCIA: null,
              ENCNOTREC_OTROS: '',
              ASI_NRO: '',
              ENCNOTREC_MERCONS: '',
              ENCNOTREC_REFERENCIA_ADI: '',
              MAEMOTIVO_CODIGO: '',
              ENCNOTREC_FLAG: '',
              ENCNOTREC_VALORDES: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
              ENCNOTREC_PORCEDES: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
              ENCNOTREC_TIPODES: '',
              //CDPJ
              ENCNOTREC_TASA_CAMBIO: Number(0).toFixed(4),
  
            };
            this.auxBodCod = '';
            this.auxBod = '';
            this.strFechaEmision = new Date();
            this.strFechaEntrega = new Date();
            this.proveedorNombre = '';
            this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador] = this.notRecSeleccionado;
  
            this.codigoAnt = this.notRecSeleccionado.ENCNOTREC_NUMERO;
            this.tipodetalle = 'A';
            this.bolestcta = false;
            this.bolsaldo = false;
            //GSTR
            this.detnumero = '';
          } else if (this.permisos.PERINSERCION === 0) {
            this.messageService.add({
              key: 'notrec',
              severity: 'error',
              summary: 'Inserción de Nota de recpcion',
              detail: 'El usuario no tiene permisos de inserción, acción denegada'
            });
  
          }
  
  
        }
      }
      //CDPJ
      //   //CDPJ
      //   this.booleanATasa = false;
      //   this.booleanAOriginal = true;
      //   //CDPJ
      //   if (this.permisos.PERINSERCION === 1) {
      //     this.index = 0;
      //     this.btnGuardar = false;
      //     this.btnNuevo = true;
      //     this.btnBorrar = true;
      //     this.btnRegresar = false;
      //     this.btnPrimero = true;
      //     this.btnAnterior = true;
      //     this.btnSiguiente = true;
      //     this.btnUltimo = true;
      //     this.btnRegresar = true;
      //     this.btnOrdenCompra = false;
      //     this.btnNuevoGrid = false;
      //     //CDPJ
      //     this.btnTasaCambio = false;
      //     this.booleanATasa = false;
      //     this.booleanAOriginal = true;
      //     this.boolDistribuir = false;
      //     this.boolReverzar = true;
      //     this.chkfactor = false;
      //     this.chkpeso = false;
      //     this.chkvolumen = false;
      //     this.chkvalor = false;
      //     this.btnNuevoGrid = false;
      //     this.btnBorrarGrid = false;
      //     this.btnCancelarGrid = false;
      //     this.numberchk = 0;
      //     this.bolean = false;
      //     //CDPJ
      //     this.btnBorrarGrid = false;
      //     this.btnCancelarGrid = true;
      //     const iva = 12;
      //     document.getElementById('divPrincipal').style.pointerEvents = 'all';
      //     // document.getElementById('divPrincipal').style.opacity = '1.0';
      //     this.aggrid.habilitarGrid();
      //     this.ivaB = false;
      //     this.auxBod = '';
      //     this.auxEstado = 'PENDIENTE';
      //     this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador] = [];
      //     this.comnotrecServicio.comnotrecImportacionarray[this.indicador] = [];
      //     this.comnotrecServicio.comnotrecVararray[this.indicador] = [];
      //     this.comnotrecServicio.dblValorDescGlbOrdarray[this.indicador] = 0;
      //     this.comnotrecServicio.dblPorcDescGlbOrdarray[this.indicador] = 0;
      //     this.notRecSeleccionado = {
      //       COM_CODIGO: '',
      //       ENCNOTREC_CONTACTO: '',
      //       ENCNOTREC_FECHAEMISION: this.strFechaEmision,
      //       ENCNOTREC_FECHAENTREGA: this.strFechaEntrega,
      //       ENCNOTREC_IVA: '',
      //       ENCNOTREC_ESTADO: 'P',
      //       ENCNOTREC_OBSERVACION: '',
      //       ENCNOTREC_TOTAL: Number(0).toFixed(this.comnotrecServicio.decimalesCostoTotal),
      //       ENCNOTREC_TOTALNETO: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      //       ENCNOTREC_VALORIVA: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      //       ENCNOTREC_PORCEIVA: Number(iva).toFixed(2),
      //       ENCNOTREC_VALORICE: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      //       ENCNOTREC_BASEIVA: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      //       ENCNOTREC_BASEICE: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      //       ENCNOTREC_BASECERO: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      //       ENCNOTREC_NUMERO: null,
      //       PRO_CODIGO: '',
      //       ENCNOTREC_REFERENCIA: null,
      //       ENCNOTREC_OTROS: '',
      //       ASI_NRO: '',
      //       ENCNOTREC_MERCONS: '',
      //       ENCNOTREC_REFERENCIA_ADI: '',
      //       MAEMOTIVO_CODIGO: '',
      //       ENCNOTREC_FLAG: '',
      //       ENCNOTREC_VALORDES: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      //       ENCNOTREC_PORCEDES: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      //       ENCNOTREC_TIPODES: '',
      //       //CDPJ
      //       ENCNOTREC_TASA_CAMBIO: Number(0).toFixed(4),

      //     };
      //     this.auxBodCod = '';
      //     this.auxBod = '';
      //     this.strFechaEmision = new Date();
      //     this.strFechaEntrega = new Date();
      //     this.proveedorNombre = '';
      //     this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador] = this.notRecSeleccionado;

      //     this.codigoAnt = this.notRecSeleccionado.ENCNOTREC_NUMERO;
      //     this.tipodetalle = 'A';
      //     this.bolestcta = false;
      //     this.bolsaldo = false;
      //     //GSTR
      //     this.detnumero = '';
      //   } else if (this.permisos.PERINSERCION === 0) {
      //     this.messageService.add({
      //       key: 'notrec',
      //       severity: 'error',
      //       summary: 'Inserción de Nota de recpcion',
      //       detail: 'El usuario no tiene permisos de inserción, acción denegada'
      //     });

      //   }


      // }
    } else {
      this.messageService.add({
        key: 'notrec',
        severity: 'warn',
        summary: 'Información',
        detail: 'Ingrese los seriales.'
      });
    }


    if (valor === 'Cancelar') {
      this.btnPrimero = false;
      this.btnAnterior = false;
      this.btnSiguiente = false;
      this.btnUltimo = false;

      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = true;
      this.btnBuscar = false;
      //CDPJ
      this.booleanATasa = false;
      this.booleanAOriginal = true;
      //CDPJ
      if (this.codigoAnt !== null && this.codigoAnt !== '') {
        this.comnotrecServicio.getEncnotRec().subscribe((res) => {

          this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador] = res;
          if (this.indice === 0) {
            this.btnAnterior = true;
            this.btnPrimero = true;
            this.btnUltimo = false;
            this.btnSiguiente = false;
            this.btnRegresar = false;
          }
          if (this.indice === this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador].length - 1) {
            this.btnAnterior = false;
            this.btnPrimero = false;
            this.btnUltimo = true;
            this.btnSiguiente = true;
            this.btnRegresar = false;
          }
          this.seleccionarEncabezado();
        });

      } else {
        this.nuevoNot();

      }


    }
    if (valor === 'Guardar') {
      if (this.index === 1) {
        if(this.notRecSeleccionado.ENCNOTREC_NUMERO === null || this.notRecSeleccionado.ENCNOTREC_NUMERO === undefined || this.notRecSeleccionado.ENCNOTREC_NUMERO === ''){
          if(this.permisos.PERTODO === 0){
            if(this.permisos.PERINSERCION === 0){
              this.messageService.add({
                key: 'notrec',
                severity: 'error',
                summary: 'Inserción Nota de Recepción',
                detail: 'El usuario no tiene permisos de inserción, acción denegada'
              });
              this.spin = false;
              return
            }
          }
  
        }else{
          if(this.permisos.PERTODO === 0){
            if(this.permisos.PERACTUALIZACION === 0){
              this.messageService.add({
                key: 'notrec',
                severity: 'error',
                summary: 'Actualización Nota de Recepción',
                detail: 'El usuario no tiene permisos de actualización, acción denegada'
              });
              this.spin = false;
              return
            }
          }
        }
        this.guardarImportacion();

      } else if (this.index === 2) {
          if(this.permisos.PERTODO === 0){
            if(this.permisos.PERACTUALIZACION === 0){
              this.messageService.add({
                key: 'notrec',
                severity: 'error',
                summary: 'Actualización Nota de Recepción',
                detail: 'El usuario no tiene permisos de actualización, acción denegada'
              });
              this.spin = false;
              return
            }
          }
        
        this.confirmarGenerarAsiento();
      } else {
        this.spin=true//CDPJ
        if(this.notRecSeleccionado.ENCNOTREC_NUMERO === null || this.notRecSeleccionado.ENCNOTREC_NUMERO === undefined || this.notRecSeleccionado.ENCNOTREC_NUMERO === ''){
          if(this.permisos.PERTODO === 0){
            if(this.permisos.PERINSERCION === 0){
              this.messageService.add({
                key: 'notrec',
                severity: 'error',
                summary: 'Inserción Nota de Recepción',
                detail: 'El usuario no tiene permisos de inserción, acción denegada'
              });
              this.spin = false;
              return
            }
          }
  
        }else{
          if(this.permisos.PERTODO === 0){
            if(this.permisos.PERACTUALIZACION === 0){
              this.messageService.add({
                key: 'notrec',
                severity: 'error',
                summary: 'Actualización Nota de Recepción',
                detail: 'El usuario no tiene permisos de actualización, acción denegada'
              });
              this.spin = false;
              return
            }
          }
        }
        
        this.guardarChecks();
        if (this.notRecSeleccionado.ENCNOTREC_MERCONS === 'S') {
          const ind = this.validarMercons();
          console.log(ind, '!==', this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].length);
          if (ind !== this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].length) {
            this.messageService.add({
              key: 'notrec',
              severity: 'error',
              summary: 'Actualización Nota de Recepción',
              detail: 'No se pudo ingresar el registro actual debido a que uno o más artículos no tienen asignado el codigo contable' +
                ' para consignación de mercaderia.'
            });
            this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultRecepcion.objeto].setFocusedCell(ind, 'DETNOTREC_CODIGO');
          this.spin=false//CDPJ
          } else {
            this.guardarNotaRecepcion();
          }
        } else {
          this.guardarNotaRecepcion();
        }
      }


    }
    if (valor === 'Borrar') {
      if (this.permisos.PERELIMACION === 1) {
        if (this.notRecSeleccionado === undefined) {
          this.messageService.add({
            key: 'notrec',
            severity: 'error',
            summary: 'Eliminacion de Nota de Recepción',
            detail: 'Seleccion Incorrecta, no se ha seleccionado una nota'
          });
        } else {
          this.confirmarNotRec();
        }

      } else if (this.permisos.PERELIMACION === 0) {
        this.messageService.add({
          key: 'notrec',
          severity: 'error',
          summary: 'Eliminación de Proveedor',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
      }

    }
    if (valor === 'Descuento') {
      if (this.notRecSeleccionado.ENCNOTREC_ESTADO === 'P') {
        this.displayDialogDescuento = true;
        this.booleanValor = false;
        this.booleanPorcentaje = true;
      } else {
        this.messageService.add({
          key: 'notrec',
          severity: 'error',
          summary: 'Descuento',
          detail: 'No se puede aplicar el descuento'
        });
      }
    }
    if (valor === 'Anular') {

      //this.confirmarAnulacionNotRec();
      this.compclaveanular();

    }
    if (valor === 'Asiento') {
      this.confirmarGenerarAsiento();

    }
    if (valor === 'AsientoVer') {
      this.verAsiento();
    }
    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }
    if (valor === 'Almacenardoc') {
      if (this.notRecSeleccionado.ENCNOTREC_NUMERO !== '') {
        const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.messageService.add({
            key: 'notrec',
            severity: 'info',
            summary: 'Información',
            detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
          });
        }

      }
    }
    if (valor === 'Salir') {
      if (this.verificarAsiento() === true) {
        this.init.tabs.splice(this.init.tabindex, 1);
      }
    }


  }

  async guardarNotaRecepcion() {
    this.spin = true;
    //if (this.permisos.PERACTUALIZACION === 1) {

      this.notRecSeleccionado.ENCNOTREC_FECHAEMISION = this.datePipe.transform(this.strFechaEmision, 'dd/MM/yyyy');
      this.notRecSeleccionado.ENCNOTREC_FECHAENTREGA = this.datePipe.transform(this.strFechaEntrega, 'dd/MM/yyyy');
      // guardar primero encabezado despues detalle
      //----------------------------------------------GSRF
      console.log(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador])
      console.log(this.detnumero);
      if (this.detnumero !== '') {
        console.log('if');
        if (this.detnumero !== undefined) {
          if (this.detnumero !== null) {
            const data = await this.comnotrecServicio.ejecutarConsultaPromise(this.detnumero);
            console.log(data[0]);
            console.log(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador]);
            let contador = 0;//CDPJ
             for (const aux of this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador]) {
              console.log('nota', aux.DETNOTREC_CODIGO);
              console.log('nota', aux.DETNOTREC_CANTIDAD);
              const dat = await this.comnotrecServicio.consultarDetalleOrdenCompra(data[0]);
              for (const aux1 of dat) {
                console.log(aux1);
                console.log('orden', aux1.DETORDCOM_CODIGO);
                console.log('orden', aux1.DETORDCOM_CANTIDAD);
                console.log('orden', this.detnumero);
                console.log('nota', aux.ENCORDCOM_NUMERO);
                console.log('notalinea', contador);
                console.log('ordenlinea', aux1.DETORDCOM_LINEA);
                let sumcantot =  aux1.DETORDCOM_CANTIDAD - aux1.DETORDCOM_DESPACHO;
                console.log('sumcantot', sumcantot);

                if (aux.DETNOTREC_CODIGO === aux1.DETORDCOM_CODIGO && aux.ENCORDCOM_NUMERO === this.detnumero && aux.DETORDCOM_LINEA === aux1.DETORDCOM_LINEA && aux.DETNOTREC_CANTIDAD > sumcantot) {
                  this.messageService.add({
                    key: 'notrec',
                    severity: 'error',
                    summary: 'Ingreso Notas de Recepción',
                    detail: 'Revise el detalle la cantidad es mayor a la registrada en ordenes de compra, articulo '+aux1.DETORDCOM_CODIGO
                  });
                  this.spin = false; 
                  return;
                } else {
                  console.log('menor')
                }

              }
              contador++;
            }
          }
        }
      } else {
        console.log('else');
        let contador = 0;//CDPJ

        for (const aux2 of this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador]) {
          contador = 0;//CDPJ
          console.log(aux2.ENCORDCOM_NUMERO);
          if (aux2.ENCORDCOM_NUMERO !== undefined) {
            if (aux2.ENCORDCOM_NUMERO !== null) {
              const data = await this.comnotrecServicio.ejecutarConsultaPromise(aux2.ENCORDCOM_NUMERO);

              console.log(data[0]);
              if (aux2.ENCORDCOM_NUMERO !== '') {
                console.log(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador]);
                for (const aux of this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador]) {
                  console.log(aux.DETNOTREC_CODIGO);
                  console.log(aux.DETNOTREC_CANTIDAD);
                  let canante
                  this.comnotrecServicio.getDetnotRec(this.notRecSeleccionado.ENCNOTREC_NUMERO).subscribe(async res => {
                    if (res !== null) {
                      for(let rest of res){
                      if (rest.DETNOTREC_CODIGO === aux.DETNOTREC_CODIGO) {
                        canante=rest.DETNOTREC_CANTIDAD
                     }
                    }
                    }
                  });
                  console.log('cantianterior',canante);
                  const dat = await this.comnotrecServicio.consultarDetalleOrdenCompra(data[0]);//CDPJ
                  for (const aux1 of dat) {
                    // console.log(aux1);
                    // console.log(aux1.DETORDCOM_CODIGO);
                    // console.log(aux1.DETORDCOM_CANTIDAD);
                    console.log(aux1);
                    console.log('orden', aux1.DETORDCOM_CODIGO);
                    console.log('orden', aux1.DETORDCOM_CANTIDAD);
                    console.log('orden', aux1.ENCORDCOM_NUMERO);
                    console.log('nota', aux.ENCORDCOM_NUMERO);
                    console.log('notalinea', contador);
                    console.log('ordenlinea', aux1.DETORDCOM_LINEA);
                    let sumcantot =  aux1.DETORDCOM_CANTIDAD - (aux1.DETORDCOM_DESPACHO-canante);
                    console.log('sumcantot', sumcantot);
                    if (aux.DETNOTREC_CODIGO === aux1.DETORDCOM_CODIGO && aux.ENCORDCOM_NUMERO === aux1.ENCORDCOM_NUMERO && aux.DETORDCOM_LINEA === aux1.DETORDCOM_LINEA && aux.DETNOTREC_CANTIDAD > sumcantot) {
                      this.messageService.add({
                        key: 'notrec',
                        severity: 'error',
                        summary: 'Ingreso Notas de Recepción',
                        detail: 'Revise el detalle la cantidad es mayor a la registrada en ordenes de compra, articulo '+aux1.DETORDCOM_CODIGO
                      });
                      this.spin = false;
                      return;
                    } else {
                      console.log('menor')
                    }
                  }
                  contador++;//CDPJ
                }
              }
            }
          }
        }
      }
      //CDPJ
      const rs = await this.comnotrecServicio.erBodCodigo1(this.auxBodCod).catch(e=>{
        this.spin=false//CDPJ
      });;
      const data = await this.comnotrecServicio.EncontrarProvedor(this.notRecSeleccionado.PRO_CODIGO).catch(e=>{
        this.spin=false//CDPJ
      });
      //CDPJ
      //GSRF
      if (this.notRecSeleccionado.ENCNOTREC_TOTAL === '' || this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].length === 0) {
        this.messageService.add({
          key: 'notrec',
          severity: 'error',
          summary: 'Ingreso Notas de Recepción',
          detail: 'No se puede ingresar una nota de recepción sin detalle, por favor revise los valores e intente nuevamente'
        });
        this.spin=false//CDPJ

      }else if (rs === null || rs === undefined || rs === ''){//CDPJ
        this.messageService.add({
          key: 'notrec',
          severity: 'error',
          summary: 'Ingreso Notas de Recepción',
          detail: 'Error, Código de la bodega '+this.auxBodCod+ ' es incorrecto'
        });
        this.spin = false;
        return;
        //CDPJ
      } else if (data === null || data === undefined || data === '') {
        this.messageService.add({
          key: 'notrec',
          severity: 'error',
          summary: 'Ingreso Notas de Recepción',
          detail: 'No existe el proveedor '+ this.notRecSeleccionado.PRO_CODIGO
        });
        this.spin = false;
      return;
      //CDPJ
    }else if (await this.verificacionDatosCompletos() === 0) {//CDPJ
        let numero;

        const rs = await this.comnotrecServicio.insertarEncabezadoProm(this.notRecSeleccionado).catch(e=>{
          this.spin=false//CDPJ
        });
        numero = rs[0].v_ENCNOTREC_numero;
        this.disabledEstadisticas = false;

        if (this.notRecSeleccionado.ENCNOTREC_NUMERO === null) {
          this.notRecSeleccionado.ENCNOTREC_NUMERO = numero;
          this.notRecSeleccionado.ENCNOTREC_REFERENCIA = String(numero).substring(2, 12);
          this.auditoriagral.registrarAuditoria('com_encnotrec', String(numero), 'I',
            '', '01', '', '', '', '').subscribe(() => {
            });
          await this.guardarDetalle();
          this.spin = false;
          this.comnotrecServicio.getEncnotRec().pipe(finalize(async () => {
            await this.comnotrecServicio.cambiarEstadoOrden(this.notRecSeleccionado);
            await this.comnotrecServicio.registrarKardexProm(this.notRecSeleccionado);
            await this.comnotrecServicio.verificarFacProProm(this.notRecSeleccionado);
          })).subscribe((res3) => {

            this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador] = res3;
            this.indice = this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador].length - 1;
            this.notRecSeleccionado = this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador][this.indice];
            this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador] = this.notRecSeleccionado;

            this.seleccionarEncabezado();
            this.llenarLabes();
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
            this.btnRegresar = true;
            this.btnBuscar = false;
            this.btnPrimero = false;
            this.btnAnterior = false;
            this.btnSiguiente = true;
            this.btnUltimo = true;
          });

        } else {
          this.messageService.add({
            key: 'notrec',
            severity: 'success',
            summary: 'Actualización Notas de Recepción',
            detail: 'Actualización  realizada con éxito'
          });

          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.btnRegresar = true;
          this.btnBuscar = false;
          if (this.indice === 0) {


            this.btnPrimero = true;
            this.btnAnterior = true;
            this.btnSiguiente = false;
            this.btnUltimo = false;


          } else if (this.indice === this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador].length - 1) {
            this.btnPrimero = false;
            this.btnAnterior = false;
            this.btnSiguiente = true;
            this.btnUltimo = true;
          } else {
            this.btnPrimero = false;
            this.btnAnterior = false;
            this.btnSiguiente = false;
            this.btnUltimo = false;
          }
          this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador][this.indice].ENCNOTREC_FECHAENTREGA = new Date(this.strFechaEntrega);
          this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador][this.indice].ENCNOTREC_FECHAEMISION = new Date(this.strFechaEmision);
          this.auditoriagral.registrarAuditoria('com_encnotrec', String(numero), 'A',
            '', '01', '', '', '', '').subscribe(() => {
            });
          await this.guardarDetalle();
          this.spin=false//CDPJ
          this.comnotrecServicio.getEncnotRec().pipe(finalize(async () => {
            await this.comnotrecServicio.cambiarEstadoOrden(this.notRecSeleccionado);
            await this.comnotrecServicio.registrarKardexProm(this.notRecSeleccionado);
            await this.comnotrecServicio.verificarFacProProm(this.notRecSeleccionado);
          })).subscribe((res3) => {

            this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador] = res3;
            this.notRecSeleccionado = this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador][this.indice];
            this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador] = this.notRecSeleccionado;

            this.seleccionarEncabezado();
            this.seleccionarDetalle();
            this.llenarLabes();
          });
        }
        this.spin = false;//CDPJ
        // await this.comnotrecServicio.cambiarEstadoOrden(this.notRecSeleccionado);
        // await this.comnotrecServicio.registrarKardexProm(this.notRecSeleccionado);
        // await this.comnotrecServicio.verificarFacProProm(this.notRecSeleccionado);


        /*this.comnotrecServicio.insertarEncabezado(this.notRecSeleccionado).subscribe(res => {
          
          res.map(aux => {
            numero = aux.v_ENCNOTREC_numero;
            
          });
          if (this.notRecSeleccionado.ENCNOTREC_NUMERO === null) {
            this.notRecSeleccionado.ENCNOTREC_NUMERO = numero;
            this.notRecSeleccionado.ENCNOTREC_REFERENCIA = String(numero).substring(2, 12);
            this.auditoriagral.registrarAuditoria('com_encnotrec', String(numero), 'I',
              '', '01', '', '', '', '').subscribe(() => {
            });
            this.guardarDetalle();
            this.comnotrecServicio.getEncnotRec().subscribe((res3) => {
              
              this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador] = res3;
              this.indice = this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador].length - 1;
              this.notRecSeleccionado = this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador][this.indice];
              this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador] = this.notRecSeleccionado;
              
              this.seleccionarEncabezado();
              this.llenarLabes();
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
              this.btnBuscar = false;
              this.btnPrimero = false;
              this.btnAnterior = false;
              this.btnSiguiente = true;
              this.btnUltimo = true;
            });
            
          } else {
            this.messageService.add({
              key: 'notrec',
              severity: 'success',
              summary: 'Actualización Notas de Recepción',
              detail: 'Actualización  realizada con éxito'
            });
            
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
            this.btnRegresar = true;
            this.btnBuscar = false;
            if (this.indice === 0) {
              
              
              this.btnPrimero = true;
              this.btnAnterior = true;
              this.btnSiguiente = false;
              this.btnUltimo = false;
              
              
            } else if (this.indice === this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador].length - 1) {
              this.btnPrimero = false;
              this.btnAnterior = false;
              this.btnSiguiente = true;
              this.btnUltimo = true;
            } else {
              this.btnPrimero = false;
              this.btnAnterior = false;
              this.btnSiguiente = false;
              this.btnUltimo = false;
            }
            this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador][this.indice].ENCNOTREC_FECHAENTREGA = new Date(this.strFechaEntrega);
            this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador][this.indice].ENCNOTREC_FECHAEMISION = new Date(this.strFechaEmision);
            this.auditoriagral.registrarAuditoria('com_encnotrec', String(numero), 'A',
              '', '01', '', '', '', '').subscribe(() => {
            });
            this.guardarDetalle();
            this.comnotrecServicio.getEncnotRec().subscribe((res3) => {
              
              this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador] = res3;
              this.notRecSeleccionado = this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador][this.indice];
              this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador] = this.notRecSeleccionado;
              
              this.seleccionarEncabezado();
              this.seleccionarDetalle();
              this.llenarLabes();
            });
          }
        });*/
      }
    // } else if (this.permisos.PERACTUALIZACION === 0) {
    //   this.messageService.add({
    //     key: 'notrec',
    //     severity: 'error',
    //     summary: 'Actualización Nota de Recepción',
    //     detail: 'El usuario no tiene permisos de actualización o guardado, acción desanegada'
    //   });
    // }
    this.spin = false;
  }

  async guardarImportacion() {
    let numero = 0;//GSRF
    await this.comnotrecServicio.eliminaimpall(this.notRecSeleccionado.ENCNOTREC_NUMERO); //GSRF
    this.comnotrecServicio.comnotrecImportacionarray[this.indicador].map(aux => {
      console.log(aux)
      console.log('nuevo eliminar')
      if (aux.REF_CODIGO !== '') {
        //GSRF
        numero = numero + 1;
        aux.NOTRECIMP_NUMSEC = numero;
        aux.PRO_NOMBRE = aux.PRO_NOMBRE === null || aux.PRO_NOMBRE === undefined ? '' : aux.PRO_NOMBRE;//GSRF
        aux.NUM_DOCUMENTO = aux.NUM_DOCUMENTO === null || aux.NUM_DOCUMENTO === undefined ? '' : aux.NUM_DOCUMENTO;//GSRF
        console.log(aux.NOTRECIMP_NUMSEC);
        this.comnotrecServicio.insertarImportacion(aux, aux.NOTRECIMP_TIPO).subscribe(res1 => {
          //GSRF
          if (this.comnotrecServicio.comnotrecImportacionarray[this.indicador].length - 1 ===
            this.comnotrecServicio.comnotrecImportacionarray[this.indicador].indexOf(aux)) {
            this.messageService.add({
              key: 'notrec',
              severity: 'success',
              summary: 'Actualización Nota de Recepción',
              detail: 'Registro guardado exitosamente.'
            });
            this.spin=false//CDPJ
            this.auditoriagral.registrarAuditoria('com_notrecimp', aux.ENCNOTREC_NUMERO, 'I',
              '', '01', '', '', '', '').subscribe(() => {
              });
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
            this.btnRegresar = true;
            this.btnBuscar = false;
            if (this.indice === 0) {


              this.btnPrimero = true;
              this.btnAnterior = true;
              this.btnSiguiente = false;
              this.btnUltimo = false;


            } else if (this.indice === this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].length - 1) {
              this.btnPrimero = false;
              this.btnAnterior = false;
              this.btnSiguiente = true;
              this.btnUltimo = true;
            } else {
              this.btnPrimero = false;
              this.btnAnterior = false;
              this.btnSiguiente = false;
              this.btnUltimo = false;
            }
          }
        });
      }
    });
  }

  // verificacionDatosCompletos(): boolean {
  //   let banderaIncompleta = false;
  //   this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].map(aux => {
  //     if (aux.DETNOTREC_TIPODET !== '*') {
  //       if (Number(aux.DETNOTREC_CANTIDAD) === 0) {
  //         this.messageService.add({//CDPJ
  //           key: 'notrec',
  //           severity: 'error',
  //           summary: 'Ingreso Notas de Recepción',
  //           detail: 'Ingrese una cantidad valida en el detalle.'
  //         });      //CDPJ                            
  //         banderaIncompleta = true;
  //       }else if(aux.DETNOTREC_DESCRIPCION.length > 80){//CDPJ
  //         this.messageService.add({
  //           key: 'notrec',
  //           severity: 'error',
  //           summary: 'Ingreso Notas de Recepción',
  //           detail: 'La descricpcion en el detalle no debe ser mayor a 80 caracteres.'
  //         });
  //         banderaIncompleta = true;
  //       //CDPJ
  //   }
  //     } else {
  //       banderaIncompleta = false;
  //     }

  //   });

  //   return banderaIncompleta;
  // }
  async verificacionDatosCompletos() {
    let banderaIncompleta = 0;
    await Promise.all(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].map(async aux => {
      const rsdata: any = await this.comnotrecServicio.encontrarArtCntaySer(aux.DETNOTREC_CODIGO, aux.DETNOTREC_TIPODET);
      let strCodigo = 0;
      if (rsdata !== null) {
        for (const rs of rsdata) {
          strCodigo = rs.NUMERO;
        }
      }
      if (aux.DETNOTREC_TIPODET !== '*') {
        const rsdata: any = await this.comnotrecServicio.encontrarArtCntaySer(aux.DETNOTREC_CODIGO, aux.DETNOTREC_TIPODET);
        const rsdata2: any = await this.comnotrecServicio.encontrarArtCntaySer(aux.DETNOTREC_AJUSTE, 'A');
        const rsdata1: any = await this.comnotrecServicio.encontrarArttribIva(aux.DETNOTREC_CODIGO, aux.DETNOTREC_TIPODET);
        const rs = await this.comnotrecServicio.erBodCodigo1(this.auxBodCod);
        const num= await this.comnotrecServicio.validarcencodigo(aux.CEN_CODIGO);
        let cont=0;
                if (num !== null && num !== undefined){
                  for( const rs of num){
                    if(rs.NUMERO !== null && rs.NUMERO!== undefined && rs.NUMERO !== ''){
                      cont=rs.NUMERO
                    }
                    
                  }
                }
        let strCodigo = 0;
        if (rsdata !== null) {
          for (const rs of rsdata) {
            strCodigo = rs.NUMERO;
          }
        }
        let strCodigoIVA = '';
        if (rsdata1 !== null) {
          for (const rs1 of rsdata1) {
            strCodigoIVA = rs1.ART_TRIBUTAIVA;
          }
        }
        let strCodigoAjuste=0;
        if (rsdata2 !== null) {
          for (const rs of rsdata2) {
            strCodigoAjuste = rs.NUMERO;
          }
        }
        if(aux.CEN_CODIGO !== null && aux.CEN_CODIGO !== '' && aux.CEN_CODIGO!== undefined){
          if (cont === 0){
            this.messageService.add({
              key: 'notrec',
              severity: 'error',
              summary: 'Ingreso Notas de Recepción',
              detail: 'El código '+aux.CEN_CODIGO+' del centro de costos ingresado no existe'
            });
            
            banderaIncompleta++;
            }
          }
        if(aux.BOD_CODIGO === null || aux.BOD_CODIGO === '' || aux.BOD_CODIGO=== undefined){
          this.messageService.add({
            key: 'notrec',
            severity: 'error',
            summary: 'Ingreso Notas de Recepción',
            detail: 'Error, Código de la bodega está vacío'
          });
          banderaIncompleta++;
        }else if(aux.BOD_CODIGO !== null && aux.BOD_CODIGO !== '' && aux.BOD_CODIGO!== undefined){
        if (rs === null || rs === undefined || rs === ''){
          this.messageService.add({
            key: 'notrec',
            severity: 'error',
            summary: 'Ingreso Notas de Recepción',
            detail: 'Error, Código de la bodega '+this.auxBodCod+ ' es incorrecto'
          });
          
          banderaIncompleta++;
          }
        } if (Number(aux.DETNOTREC_CANTIDAD) === 0) {
          this.messageService.add({//CDPJ
            key: 'notrec',
            severity: 'error',
            summary: 'Ingreso Notas de Recepción',
            detail: 'Ingrese una cantidad valida en el detalle.'
          });      //CDPJ                            
          banderaIncompleta++;
        } else if (aux.DETNOTREC_DESCRIPCION.length > 80) {//CDPJ
          this.messageService.add({
            key: 'notrec',
            severity: 'error',
            summary: 'Ingreso Notas de Recepción',
            detail: 'La descricpcion en el detalle no debe ser mayor a 80 caracteres.'
          });
          banderaIncompleta++;
          //CDPJ
        } else if (aux.DETNOTREC_TIPODET === 'A' && strCodigo === 0) {
          this.messageService.add({
            key: 'notrec',
            severity: 'error',
            summary: 'Ingreso Notas de Recepción',
            detail: 'El artículo ' + aux.DETNOTREC_CODIGO + ' no existe'
          });
          banderaIncompleta++;
        } else if (aux.DETNOTREC_TIPODET === 'S' && strCodigo === 0) {
          this.messageService.add({
            key: 'notrec',
            severity: 'error',
            summary: 'Ingreso Notas de Recepción',
            detail: 'El servicio ' + aux.DETNOTREC_CODIGO + ' no existe'
          });
          banderaIncompleta++;
        }else if (aux.DETNOTREC_TIPODET === 'A' && strCodigoAjuste === 0 && aux.DETNOTREC_AJUSTE !== ''&& aux.DETNOTREC_AJUSTE !== null && aux.DETNOTREC_AJUSTE !== undefined) {
          this.messageService.add({
            key: 'notrec',
            severity: 'error',
            summary: 'Ingreso Notas de Recepción',
            detail: 'No existe el código ' + aux.DETNOTREC_AJUSTE + ', colocado en el campo artículo '
          });
          banderaIncompleta++;
        } else if (aux.DETNOTREC_TIPODET === 'A' && (aux.DETNOTREC_TRIBIVA === '' || aux.DETNOTREC_TRIBIVA === null || aux.DETNOTREC_TRIBIVA === undefined)) {
          if (strCodigoIVA === null || strCodigoIVA === undefined || strCodigoIVA === '') {
            this.messageService.add({
              key: 'notrec',
              severity: 'error',
              summary: 'Ingreso Notas de Recepción',
              detail: 'Ingrese N o S en el IVA del artículo ' + aux.DETNOTREC_CODIGO
            });
            banderaIncompleta++;

          } else {
            aux.DETNOTREC_TRIBIVA = strCodigoIVA;
          }
          this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador], this.defaultRecepcion.objeto);
          this.comnotrecServicio.calcularTotales(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].indexOf(aux));//CDPJ
          this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador], this.defaultRecepcion.objeto);
        } if (aux.DETNOTREC_TIPODET === 'S' && (aux.DETNOTREC_TRIBIVA === '' || aux.DETNOTREC_TRIBIVA === null || aux.DETNOTREC_TRIBIVA === undefined)) {
          if (strCodigoIVA === null || strCodigoIVA === undefined || strCodigoIVA === '') {
            this.messageService.add({
              key: 'notrec',
              severity: 'error',
              summary: 'Ingreso Notas de Recepción',
              detail: 'Ingrese N o S en el IVA del servicio ' + aux.DETNOTREC_CODIGO
            });
            banderaIncompleta++;

          } else {
            aux.DETNOTREC_TRIBIVA = strCodigoIVA;
          }
          this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador], this.defaultRecepcion.objeto);
          this.comnotrecServicio.calcularTotales(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].indexOf(aux));//CDPJ
          this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador], this.defaultRecepcion.objeto);
        }
                 //CDPJ
                 if(aux.DETNOTREC_PORIVA === null || aux.DETNOTREC_PORIVA === undefined || aux.DETNOTREC_PORIVA === ''){       
                  this.messageService.add({
                    key: 'notrec',
                    severity: 'error',
                    summary: 'Error en IVA',
                    detail: 'El campo %IVA no puede estar vacio, a menos que sea un comentario'
                  });
                  banderaIncompleta++;              
                }
                if(aux.TARIIVA_CODIGO === null || aux.TARIIVA_CODIGO === undefined || aux.TARIIVA_CODIGO === ''){       
                  this.messageService.add({
                    key: 'notrec',
                    severity: 'error',
                    summary: 'Error en IVA',
                    detail: 'Falta seleccionar el tipo de porcentaje del código '+aux.DETNOTREC_CODIGO
                  });
                  banderaIncompleta++;              
                }else if(aux.DETNOTREC_TRIBIVA === 'S' && aux.TARIIVA_CODIGO !== null && aux.TARIIVA_CODIGO !== undefined && aux.TARIIVA_CODIGO !== ''){
                  let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === aux.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) !== 0 && Number(aux.DETNOTREC_PORIVA) !== 0 && Number(element.TARIIVA_PORCENTAJE) === Number(aux.DETNOTREC_PORIVA) )
                  if(data === undefined || data === null){
                    this.messageService.add({
                      key: 'notrec',
                      severity: 'error',
                      summary: 'Error en IVA',
                      detail: 'El %IVA con el porcentaje '+aux.DETNOTREC_PORIVA+' no es parte de los porcentajes que puede seleccionar cuando se tributa IVA.'
                    });
                    banderaIncompleta++;             
                  }
                }else if(aux.DETNOTREC_TRIBIVA === 'N'
                && aux.TARIIVA_CODIGO !== null && aux.TARIIVA_CODIGO !== undefined && aux.TARIIVA_CODIGO !== ''){
                  let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === aux.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) === 0 && Number(aux.DETNOTREC_PORIVA) === 0 && Number(element.TARIIVA_PORCENTAJE) === Number(aux.DETNOTREC_PORIVA) )
                  console.log(data)
                  console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === aux.TARIIVA_CODIGO))
                  if(data === undefined || data === null){
                    this.messageService.add({
                      key: 'notrec',
                      severity: 'error',
                      summary: 'Error en IVA',
                      detail: 'El %IVA con el porcentaje '+aux.DETNOTREC_PORIVA+' no es parte de los porcentajes que puede seleccionar cuando no se tributan IVA.'
                    });
                    banderaIncompleta++;
                  }
                }
        
              //CDPJ
      } else {
        //banderaIncompleta = true;
      }

    }));

    return banderaIncompleta;
  }

  async guardarDetalle() {
    //console.log('bol',this.verificacionDatosCompletos())
    //if (!this.verificacionDatosCompletos()) {
    for (const aux of this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador]) {
      if (aux.DETNOTREC_CODIGO !== '' || aux.DETNOTREC_TIPODET === '*') {
      //CDPJ
      if(aux.DETNOTREC_TIPODET === '*'){
        aux.DETNOTREC_PORIVA=0;
        aux.TARIIVA_CODIGO='-1';
      }
      //CDPJ
        await this.comnotrecServicio.insertarDetallePromise(aux, this.notRecSeleccionado.ENCNOTREC_NUMERO).catch(e=>{
          this.spin=false//CDPJ
        });
        console.log(aux);
        if (aux.ENCORDCOM_NUMERO !== '' && aux.ENCORDCOM_NUMERO !== null) {
          await this.comnotrecServicio.cambiarEstadoProm(aux).catch(e=>{
            this.spin=false//CDPJ
          });
          this.auditoriagral.registrarAuditoria('COM_ENCORDCOM', aux.ENCORDCOM_NUMERO, 'A',
            '', '01', '', '', '', '').subscribe(() => {
            });
        }

        if (aux.DETNOTREC_LINEA === 0) {
          aux.DETNOTREC_LINEA = aux.LINEA;
          this.auditoriagral.registrarAuditoria('com_detnotrec', this.notRecSeleccionado.ENCNOTREC_NUMERO + '/' + aux.DETNOTREC_CODIGO, 'I',
            '', '01', '', '', '', '').subscribe(() => {
            });
            this.auditoriagral.registrarAuditoria('com_detnotrec', this.notRecSeleccionado.ENCNOTREC_NUMERO + '/' + aux.DETNOTREC_CODIGO+ '/' 
        +'PorIva:'+ aux.DETNOTREC_PORIVA + '/' + 'CodTarIva:'+ aux.TARIIVA_CODIGO, 'I', 
        '', '01', '', '', '', '').subscribe(() => {
        });
          if (this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].length - 1 ===
            this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].indexOf(aux)) {
            this.messageService.add({
              key: 'notrec',
              severity: 'success',
              summary: 'Ingreso Notas de Recepción',
              detail: 'Ingreso realizado con éxito'
            });
            this.spin=false//CDPJ
            this.manejarSenales('Cancelar');//CDPJ
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
            this.btnRegresar = true;
            this.btnBuscar = false;
            if (this.indice === 0) {
              this.btnPrimero = true;
              this.btnAnterior = true;
              this.btnSiguiente = false;
              this.btnUltimo = false;
            } else if (this.indice === this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].length - 1) {
              this.btnPrimero = false;
              this.btnAnterior = false;
              this.btnSiguiente = true;
              this.btnUltimo = true;
            } else {
              this.btnPrimero = false;
              this.btnAnterior = false;
              this.btnSiguiente = false;
              this.btnUltimo = false;
            }
          }
        } else {
          this.auditoriagral.registrarAuditoria('com_detnotrec', this.notRecSeleccionado.ENCNOTREC_NUMERO + '/' + aux.DETNOTREC_CODIGO, 'A',
            '', '01', '', '', '', '').subscribe(() => {
            });
            this.auditoriagral.registrarAuditoria('com_detnotrec', this.notRecSeleccionado.ENCNOTREC_NUMERO + '/' + aux.DETNOTREC_CODIGO+ '/' 
            +'PorIva:'+ aux.DETNOTREC_PORIVA + '/' + 'CodTarIva:'+ aux.TARIIVA_CODIGO, 'A', 
            '', '01', '', '', '', '').subscribe(() => {
            });
        }
      }
    }

    // this.comnotrecServicio.registrarKardex(this.notRecSeleccionado).subscribe(() => {
    //   this.comnotrecServicio.verificarFacPro(this.notRecSeleccionado).subscribe(() => {
    //     /*
    //     this.comnotrecServicio.verificarFacPro(this.notRecSeleccionado).subscribe(() => {
    //       this.comnotrecServicio.registrarKardex(this.notRecSeleccionado).subscribe(() => {
    //       });
    //     });*/
    //   });
    // });
    //} else {
    // this.comnotrecServicio.eliminarNotaRecepcion(this.notRecSeleccionado).subscribe(() => {

    // });
    // this.messageService.add({
    //   key: 'notrec',
    //   severity: 'error',
    //   summary: 'Ingreso Notas de Recepción',
    //   detail: 'Los datos del detalle de la nota de recepción no estan completos, por favor revise los valores e intente nuevamente.'
    // });CDPJ COMENTÉ
    // }

  }

  nuevoNot() {
    const iva = 12;
    this.indice = 0;
    this.btnOrdenCompra = true;
    //CDPJ
    this.btnTasaCambio = true;
    this.booleanATasa = false;
    this.booleanAOriginal = true;
    //CDPJ
    this.btnNuevoGrid = true;
    this.btnBorrarGrid = true;
    this.btnCancelarGrid = true;


    document.getElementById('divPrincipal').style.pointerEvents = 'none';
    // document.getElementById('divPrincipal').style.opacity = '0.7';
    this.ivaB = true;
    this.auxBod = '';
    this.auxEstado = '';
    this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador] = [];
    this.notRecSeleccionado = {
      COM_CODIGO: '',
      ENCNOTREC_CONTACTO: '',
      ENCNOTREC_FECHAEMISION: this.strFechaEmision,
      ENCNOTREC_FECHAENTREGA: this.strFechaEntrega,
      ENCNOTREC_IVA: '',
      ENCNOTREC_ESTADO: 'P',
      ENCNOTREC_OBSERVACION: '',
      ENCNOTREC_TOTAL: Number(0).toFixed(this.comnotrecServicio.decimalesCostoTotal),
      ENCNOTREC_TOTALNETO: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      ENCNOTREC_VALORIVA: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      ENCNOTREC_PORCEIVA: Number(iva).toFixed(2),
      ENCNOTREC_VALORICE: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      ENCNOTREC_BASEIVA: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      ENCNOTREC_BASEICE: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      ENCNOTREC_BASECERO: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      ENCNOTREC_NUMERO: null,
      PRO_CODIGO: '',
      ENCNOTREC_REFERENCIA: null,
      ENCNOTREC_OTROS: '',
      ASI_NRO: '',
      ENCNOTREC_MERCONS: '',
      ENCNOTREC_REFERENCIA_ADI: '',
      MAEMOTIVO_CODIGO: '',
      ENCNOTREC_FLAG: '',
      ENCNOTREC_VALORDES: '',
      ENCNOTREC_PORCEDES: '',
      ENCNOTREC_TIPODES: '',
      //CDPJ
      ENCNOTREC_TASA_CAMBIO: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),

    };
    this.proveedorNombre = '';
    this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador] = this.notRecSeleccionado;
    this.comnotrecServicio.comnotrecVararray[this.indicador] = [];
    this.comnotrecServicio.comnotrecImportacionarray[this.indicador] = [];//GSRF
    this.codigoAnt = this.notRecSeleccionado.ENCNOTREC_NUMERO;
  }

  confirmarNotRec() {
    this.confirmationService.confirm({
      message: 'Esta seguro de eliminar la nota de recepción',
      header: 'Eliminar nota de recepción',
      icon: 'pi pi-exclamation-triangle',
      key: 'notrecConfirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarNotRec();
      },
      reject: () => {
      }
    });
  }
  //CDPJ
  compclaveanular() {
    if (this.displayAcciones === true) {
      this.closeSidebar();
    }
    const valclave = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'SCANNR').CFG_VALOR1;
    console.log(valclave);
    if (valclave === 1) {
      this.claveDialog = true;
    } else {
      this.confirmarAnulacionNotRec();
    }

  }
  //CDPJ
  confirmarAnulacionNotRec() {
    if (this.displayAcciones === true) {
      this.closeSidebar();
    }

    if (this.notRecSeleccionado.ENCNOTREC_ESTADO === 'P') {
      this.confirmationService.confirm({
        message: 'Realmente desea anular la nota de recepción actual',
        header: 'Anular nota de recepción',
        icon: 'pi pi-exclamation-triangle',
        key: 'notrecConfirmar',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.notRecSeleccionado.ENCNOTREC_ESTADO = 'A';
          this.auxEstado = 'ANULADA';
          document.getElementById('divPrincipal').style.pointerEvents = 'none';
          // document.getElementById('divPrincipal').style.opacity = '0.7';
          //CDPJ
          this.auditoriagral.registrarAuditoria('COM_ENCNOTREC', this.notRecSeleccionado.ENCNOTREC_NUMERO, 'AN',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });
          //CDPJ          

          this.btnOrdenCompra = true;
          this.btnNuevoGrid = true;
          this.btnBorrarGrid = true;
          this.btnCancelarGrid = true;
          this.btnBorrar = true;

          // this.guardarNotaRecepcion();

          this.comnotrecServicio.cambiarEstado(this.notRecSeleccionado).subscribe(data => {
            this.comnotrecServicio.registrarKardex(this.notRecSeleccionado).subscribe();
             //GSRF
             if(this.notRecSeleccionado.ASI_NRO !== null && this.notRecSeleccionado.ASI_NRO !== undefined 
              && this.notRecSeleccionado.ASI_NRO !== ''){
                console.log('**999',this.notRecSeleccionado.ASI_NRO)
                this.comnotrecServicio.anularAsiento(this.notRecSeleccionado.ASI_NRO).subscribe();
                this.auditoriagral.registrarAuditoria('COM_ENCNOTREC', this.notRecSeleccionado.ASI_NRO, 'AN',
                this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
                });
            }
            //GSRF
          });

        },
        reject: () => {
        }
      });
    } else {
      this.messageService.add({
        key: 'notrec',
        severity: 'error',
        summary: '',
        detail: 'No se puede anular la nota de recepción'
      });
    }

  }

  confirmarGenerarAsiento() {
    if (this.displayAcciones === true) {
      this.closeSidebar();
    }
    //CDPJ
    console.log('check', this.chkmercons);
    if (this.chkmercons === true) {
      if (this.notRecSeleccionado.ENCNOTREC_ESTADO === 'P') {
        this.confirmationService.confirm({
          message: 'Realmente desea generar el asiento contable',
          header: 'Generar asiento contable',
          icon: 'pi pi-exclamation-triangle',
          key: 'notrecConfirmar',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.comnotrecServicio.generarAsientoContable(this.notRecSeleccionado.ENCNOTREC_NUMERO,
              this.notRecSeleccionado.ENCNOTREC_NUMERO.substr(this.notRecSeleccionado.ENCNOTREC_NUMERO.length - 7)).subscribe(asi => {
                this.comnotrecServicio.encontrarAsiNro(this.notRecSeleccionado.ENCNOTREC_NUMERO).subscribe(cen => {
                  if (cen[0] !== undefined) {
                    this.manejarSenales('Cancelar');
                    this.notRecSeleccionado.ASI_NRO = cen[0].ASI_NRO;
                    this.messageService.add({
                      key: 'notrec',
                      severity: 'success',
                      summary: 'Información',
                      detail: 'El Asiento Contable se generó correctamente'
                    });
                    this.spin = false;//CDPJ
                  }
                });
              });
            this.auditoriagral.registrarAuditoria('com_trnnotrecvar', this.notRecSeleccionado.ENCNOTREC_NUMERO, 'I',
              '', '01', '', '', '', '').subscribe(() => {
              });
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
            this.btnRegresar = true;
            this.btnBuscar = false;
            if (this.indice === 0) {


              this.btnPrimero = true;
              this.btnAnterior = true;
              this.btnSiguiente = false;
              this.btnUltimo = false;


            } else if (this.indice === this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].length - 1) {
              this.btnPrimero = false;
              this.btnAnterior = false;
              this.btnSiguiente = true;
              this.btnUltimo = true;
            } else {
              this.btnPrimero = false;
              this.btnAnterior = false;
              this.btnSiguiente = false;
              this.btnUltimo = false;
            }




            this.guardarNotaRecepcion();
          },
          reject: () => {
          }
        });
      } else {
        this.messageService.add({
          key: 'notrec',
          severity: 'error',
          summary: '',
          detail: 'No se puede generar el asiento'
        });
        this.spin = false;//CDPJ
      }

    } else {
      const NCDGASINR = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NCDGASINR').CFG_VALOR1;
      let sumart=0;
      if(NCDGASINR === 0){
        sumart= this.comnotrecServicio.sumaArticulos();
      }else{
        sumart= this.comnotrecServicio.sumaArticulosSNDG();
      }
      if (Number(this.notRecSeleccionado.TOTAL_VARIOS) === sumart) {
        if (this.notRecSeleccionado.ENCNOTREC_ESTADO === 'P') {
          this.confirmationService.confirm({
            message: 'Realmente desea generar el asiento contable',
            header: 'Generar asiento contable',
            icon: 'pi pi-exclamation-triangle',
            key: 'notrecConfirmar',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            accept: () => {
              this.comnotrecServicio.eliminarTrnotVar().subscribe(() => {

                this.comnotrecServicio.comnotrecVararray[this.indicador].map(aux => {
                  aux.NOTRECVAR_LINEA = this.comnotrecServicio.comnotrecVararray[this.indicador].indexOf(aux) + 1;
                  aux.NOTRECVAR_IMPORTE = Number(aux.NOTRECVAR_IMPORTE);

                  this.comnotrecServicio.insertarTrnotVar(aux).subscribe(() => {
                    if (this.comnotrecServicio.comnotrecVararray[this.indicador].length - 1 ===
                      this.comnotrecServicio.comnotrecVararray[this.indicador].indexOf(aux)) {
                      this.comnotrecServicio.generarAsientoContable(this.notRecSeleccionado.ENCNOTREC_NUMERO,
                        this.notRecSeleccionado.ENCNOTREC_NUMERO.substr(this.notRecSeleccionado.ENCNOTREC_NUMERO.length - 7)).subscribe(asi => {
                          this.comnotrecServicio.encontrarAsiNro(this.notRecSeleccionado.ENCNOTREC_NUMERO).subscribe(cen => {
                            if (cen[0] !== undefined) {
                              this.manejarSenales('Cancelar');
                              this.notRecSeleccionado.ASI_NRO = cen[0].ASI_NRO;
                              this.messageService.add({
                                key: 'notrec',
                                severity: 'success',
                                summary: 'Información',
                                detail: 'El Asiento Contable se generó correctamente'
                              });
                              this.spin = false;//CDPJ
                            }
                          });
                        });
                    }
                  });
                });
                this.auditoriagral.registrarAuditoria('com_trnnotrecvar', this.notRecSeleccionado.ENCNOTREC_NUMERO, 'I',
                  '', '01', '', '', '', '').subscribe(() => {
                  });
                this.btnNuevo = false;
                this.btnGuardar = true;
                this.btnBorrar = false;
                this.btnRegresar = true;
                this.btnBuscar = false;
                if (this.indice === 0) {


                  this.btnPrimero = true;
                  this.btnAnterior = true;
                  this.btnSiguiente = false;
                  this.btnUltimo = false;


                } else if (this.indice === this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].length - 1) {
                  this.btnPrimero = false;
                  this.btnAnterior = false;
                  this.btnSiguiente = true;
                  this.btnUltimo = true;
                } else {
                  this.btnPrimero = false;
                  this.btnAnterior = false;
                  this.btnSiguiente = false;
                  this.btnUltimo = false;
                }

              });


              this.guardarNotaRecepcion();
            },
            reject: () => {
            }
          });
        } else {
          this.messageService.add({
            key: 'notrec',
            severity: 'error',
            summary: '',
            detail: 'No se puede generar el asiento'
          });
          this.spin = false;//CDPJ
        }
      } else {
        this.messageService.add({
          key: 'notrec',
          severity: 'error',
          summary: 'Actualización Nota de Recepción',
          detail: 'El valor ingresado en la tabla varios no coincide con el valor neto de la nota de recepción.'
        });
        this.spin = false;//CDPJ
      }
    }
    //CDPJ

  }

  verAsiento() {
    this.asiento = this.notRecSeleccionado.ASI_NRO;
    if (this.asiento === null || this.asiento === undefined || this.asiento === '') {
      this.messageService.add({
        key: 'comnotrec',
        severity: 'error',
        summary: 'Información',
        detail: 'No ha generado el Asiento Contable'
      });
    } else {
      this.auxiliar++;
      this.NumAsiRegCont = this.notRecSeleccionado.ASI_NRO;
      this.displayAsiContable = true;
      // this.displayDialogAsiento = true;
    }
  }

  agregarSeriales() {
    if (this.notRecSeleccionado.ENCNOTREC_ESTADO === 'P') {
      this.strNumFact = this.notRecSeleccionado.ENCNOTREC_NUMERO;
      this.strFechaSer = this.datePipe.transform(this.notRecSeleccionado.ENCNOTREC_FECHAEMISION, 'dd/MM/yyyy');
      this.displayDialogSeriales = true;
    } else {
      this.messageService.add({
        key: 'notrec',
        severity: 'error',
        summary: '',
        detail: 'No se puede ingresar seriarles en la nota de recepción'
      });
    }
    if (this.displayAcciones === true) {
      this.closeSidebar();
    }
  }

  async eliminarNotRec() {
    try {

      await this.comnotrecServicio.eliminarNotaRecepcionProm(this.notRecSeleccionado);
      await this.comnotrecServicio.registrarKardexProm(this.notRecSeleccionado);
      await this.comnotrecServicio.verificarFacProProm(this.notRecSeleccionado);

      for (const item of this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador]) {
        if (item.ENCORDCOM_NUMERO !== '' && item.ENCORDCOM_NUMERO !== null) {
          try {
            await this.comnotrecServicio.cambiarEstadoProm(item);
          } catch (err) {

          }
        }
        await this.auditoriagral.registrarAuditoria('COM_DETNOTREC', this.notRecSeleccionado.ENCNOTREC_NUMERO + '/' + item.DETNOTREC_CODIGO, 'E', '', '01', '', '', '',
          '').toPromise()
      }
      this.messageService.add({
        key: 'notrec',
        severity: 'success',
        summary: 'Eliminación exitosa',
        detail: 'Se eliminó la nota de recepción correctamente'
      });

      this.errores = 0;
      this.auditoriagral.registrarAuditoria('com_encnotrec', this.notRecSeleccionado.ENCNOTREC_NUMERO, 'E',
        '', '01', '', '', '', '').subscribe(() => {
        });

      this.nuevoNot();
      this.indice = 0;
      this.comnotrecServicio.getEncnotRec().subscribe((res) => {

        this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador] = res;
      });
      this.codigoAnt = '';

      this.btnPrimero = false;
      this.btnAnterior = false;
      this.btnSiguiente = false;
      this.btnUltimo = false;
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = true;
      this.btnBuscar = false;


    } catch (err) {

    }
    /*this.comnotrecServicio.eliminarNotaRecepcion(this.notRecSeleccionado).subscribe(() => {
      this.messageService.add({
        key: 'notrec',
        severity: 'success',
        summary: 'Eliminación exitosa',
        detail: 'Se eliminó la nota de recepción correctamente'
      });
      this.comnotrecServicio.registrarKardex(this.notRecSeleccionado).subscribe(() => {
        this.comnotrecServicio.verificarFacPro(this.notRecSeleccionado).subscribe(() => {
          this.comnotrecServicio.verificarFacPro(this.notRecSeleccionado).subscribe(() => {
            this.comnotrecServicio.registrarKardex(this.notRecSeleccionado).subscribe(() => {
            });
          });
        });
      });
      this.errores = 0;
      this.auditoriagral.registrarAuditoria('com_encnotrec', this.notRecSeleccionado.ENCNOTREC_NUMERO, 'E',
        '', '01', '', '', '', '').subscribe(() => {
      });
      
      this.nuevoNot();
      this.indice = 0;
      this.comnotrecServicio.getEncnotRec().subscribe((res) => {
        
        this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador] = res;
      });
      this.codigoAnt = '';
      
      this.btnPrimero = false;
      this.btnAnterior = false;
      this.btnSiguiente = false;
      this.btnUltimo = false;
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = true;
      this.btnBuscar = false;
      
      
    }); */

  }

  cambio(cambio) {

    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = false;
    this.btnRegresar = false;
    this.btnPrimero = true;
    this.btnAnterior = true;
    this.btnSiguiente = true;
    this.btnUltimo = true;
    this.btnBuscar = true;

    if (cambio === 'mercons') {
      this.generarMercons();
    }

    this.disabledEstadisticas = true;
  }

  llenarLabes() {

    this.comnotrecServicio.getNombreProveedor(this.notRecSeleccionado.PRO_CODIGO).subscribe((res) => {
      this.proveedorNombre = res[0].PRO_NOMBRE;

    });
    if (this.notRecSeleccionado.ENCNOTREC_ESTADO === 'F') {
      this.auxEstado = 'FACTURADA';
    } else if (this.notRecSeleccionado.ENCNOTREC_ESTADO === 'P') {
      this.auxEstado = 'PENDIENTE';
    } else if (this.notRecSeleccionado.ENCNOTREC_ESTADO === 'A') {
      this.auxEstado = 'ANULADA';
    }


  }

  seleccionarEncabezado() {
    this.detnumero = '';
    this.ivaB = true;
    this.auxNeto = 0;
    this.auxBaIva = 0;
    this.auxBaice = 0;
    this.auxporcDscto = 0;
    this.auxBaCero = 0;
    this.auxDscto = 0;
    this.auxIva = 0;
    this.auxIce = 0;
    this.auxTotal = 0;
    this.notRecSeleccionado = this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador][this.indice];
    this.notRecSeleccionadoAux = this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador][this.indice];
    this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador] = this.notRecSeleccionado;
    this.codigoAnt = this.notRecSeleccionado.ENCNOTREC_NUMERO;
    this.notRecSeleccionado.ENCNOTREC_PORCEIVA = Number(this.notRecSeleccionado.ENCNOTREC_PORCEIVA).toFixed(this.comnotrecServicio.decimalesGenerales);
    this.notRecSeleccionado.ENCNOTREC_PORCEDES = Number(this.notRecSeleccionado.ENCNOTREC_PORCEDES).toFixed(this.comnotrecServicio.decimalesGenerales);
    this.notRecSeleccionado.ENCNOTREC_VALORDES = Number(this.notRecSeleccionado.ENCNOTREC_VALORDES).toFixed(this.comnotrecServicio.decimalesGenerales);
    this.notRecSeleccionado.ENCNOTREC_TOTALNETO = Number(this.notRecSeleccionado.ENCNOTREC_TOTALNETO).toFixed(this.comnotrecServicio.decimalesGenerales);
    this.notRecSeleccionado.ENCNOTREC_BASEIVA = Number(this.notRecSeleccionado.ENCNOTREC_BASEIVA).toFixed(this.comnotrecServicio.decimalesGenerales);
    this.notRecSeleccionado.ENCNOTREC_VALORIVA = Number(this.notRecSeleccionado.ENCNOTREC_VALORIVA).toFixed(this.comnotrecServicio.decimalesGenerales);
    this.notRecSeleccionado.ENCNOTREC_BASEICE = Number(this.notRecSeleccionado.ENCNOTREC_BASEICE).toFixed(this.comnotrecServicio.decimalesGenerales);
    this.notRecSeleccionado.ENCNOTREC_VALORICE = Number(this.notRecSeleccionado.ENCNOTREC_VALORICE).toFixed(this.comnotrecServicio.decimalesGenerales);
    this.notRecSeleccionado.ENCNOTREC_BASECERO = Number(this.notRecSeleccionado.ENCNOTREC_BASECERO).toFixed(this.comnotrecServicio.decimalesGenerales);
    this.comnotrecServicio.dblValorDescGlbOrdarray[this.indicador] = this.notRecSeleccionado.ENCNOTREC_VALORDES;
    this.comnotrecServicio.dblPorcDescGlbOrdarray[this.indicador] = this.notRecSeleccionado.ENCNOTREC_PORCEDES;
    this.strFechaEntrega = new Date(this.notRecSeleccionado.ENCNOTREC_FECHAENTREGA);
    this.strFechaEmision = new Date(this.notRecSeleccionado.ENCNOTREC_FECHAEMISION);
    //CDPJ
    let data1=this.confIniciales.srifetariiva.find(element => Number(element.TARIIVA_PORCENTAJE) === Number(this.comnotrecServicio.porceiva))
    this.comnotrecServicio.tariivacod=data1.TARIIVA_CODIGO
    //CDPJ

    this.comnotrecServicio.consultarsumarVarios().subscribe(res => {
      this.notRecSeleccionado.TOTAL_VARIOS = res[0].TOTAL_VARIOS * -1;
      this.notRecSeleccionado.TOTAL_VARIOS = Number(this.notRecSeleccionado.TOTAL_VARIOS).toFixed(this.comnotrecServicio.decimalesGenerales);
      //CDPJ
      this.notRecSeleccionado.ENCNOTREC_TASA_CAMBIO = Number(this.notRecSeleccionado.ENCNOTREC_TASA_CAMBIO).toFixed(4);
    });
    // estado de la nota
    this.seleccionarChecks();
    if (this.notRecSeleccionado.PRO_CODIGO !== '') {
      this.disabledEstadisticas = false;
    } else {
      this.disabledEstadisticas = true;
    }
    this.comnotrecServicio.consultarTrnnotVar(this.notRecSeleccionado).subscribe(res => {
      if (res !== null) {

        this.comnotrecServicio.comnotrecVararray[this.indicador] = res;
        this.comnotrecServicio.comnotrecVararray[this.indicador].map(aux => {
          aux.NOTRECVAR_IMPORTE *= -1;

          aux.NOTRECVAR_IMPORTE = (Number(aux.NOTRECVAR_IMPORTE).toFixed(this.comnotrecServicio.decimalesGenerales));
          aux.NOTRECVAR_LINEA = this.comnotrecServicio.comnotrecVararray[this.indicador].indexOf(aux) + 1;
        });
        this.comnotrecServicio.calcularVarios();
      } else {
        this.comnotrecServicio.comnotrecVararray[this.indicador] = [];

      }
    });

    if (this.notRecSeleccionado.PRO_CODIGO !== undefined) {
      this.seleccionarDetalle();
    }

    this.bolestcta = false;
    this.bolsaldo = false;
  }

  async seleccionarDetalle() {
    if (this.notRecSeleccionado.ENCNOTREC_ESTADO === 'F') {
      this.auxEstado = 'FACTURADA';
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      // document.getElementById('divPrincipal').style.opacity = '0.7';
      this.aggrid.bloquearGrid();//CDPJ
      this.btnOrdenCompra = true;
      this.btnNuevoGrid = true;
      this.btnBorrarGrid = true;
      this.btnCancelarGrid = true;
      this.btnBorrar = true;
      // this.columnDefsRecepcion.map(aux => { CDPJ COMENTÉ
      //   aux.editable = false;
      // });
      // this.aggrid.refreshColumnDefs();


    } else if (this.notRecSeleccionado.ENCNOTREC_ESTADO === 'P') {
      this.auxEstado = 'PENDIENTE';
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      // document.getElementById('divPrincipal').style.opacity = '1.0';
      this.aggrid.habilitarGrid();//CDPJ
      this.btnOrdenCompra = false;
      this.btnNuevoGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = true;
      this.btnBorrar = false;
      // this.columnDefsRecepcion.map(aux => { CDPJ COMENTÉ
      //   aux.editable = true;
      // });
      // this.aggrid.refreshColumnDefs();
      //CDPJ
      this.comnotrecServicio.getImportaciones(this.notRecSeleccionado.ENCNOTREC_NUMERO).subscribe(res => {
        if (res.length > 0) {
          console.log('1');
          this.comnotrecServicio.comnotrecImportacionarray[this.indicador] = res;
          this.comnotrecServicio.comnotrecImportacionarray[this.indicador].map(aux => {
            aux.NOTRECIMP_BASE = Number(aux.NOTRECIMP_BASE).toFixed(this.comnotrecServicio.decimalesGenerales);
            aux.NOTRECIMP_IMPORTE = Number(aux.NOTRECIMP_IMPORTE).toFixed(this.comnotrecServicio.decimalesGenerales);
            aux.NOTRECIMP_PORCENTAJE = Number(aux.NOTRECIMP_PORCENTAJE).toFixed(this.comnotrecServicio.decimalesGenerales);
            this.comnotrecServicio.getDescripcionImportaciones(aux.REF_CODIGO).subscribe(res1 => {
              console.log('1.1');
              aux.REF_NOMBRE = res1[0].REF_NOMBRE;
              this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecImportacionarray[this.indicador], this.defaultImportaciones.objeto);
              this.btnNuevoGrid = true;
              this.btnBorrarGrid = true;
              this.btnCancelarGrid = true;
              if (aux.NOTRECIMP_TIPO === '1') {
                console.log('2');
                this.chkvalor = true;
                this.chkvalorDis = true;
                this.chkvolumen = false;
                this.chkpeso = false;
                this.chkfactor = false;

                this.boolDistribuir = true;
                this.boolReverzar = false;
                document.getElementById('divPrincipal').style.pointerEvents = 'none';
                this.aggrid.bloquearGrid()

              } else if (aux.NOTRECIMP_TIPO === '2') {

                this.chkvolumen = true;
                this.chkvolumenDis = true;
                this.chkvalor = false;
                this.chkpeso = false;
                this.chkfactor = false;
                this.boolDistribuir = true;
                this.boolReverzar = false;
                document.getElementById('divPrincipal').style.pointerEvents = 'none';
                this.aggrid.bloquearGrid()

              } else if (aux.NOTRECIMP_TIPO === '3') {
                this.chkpeso = true;
                this.chkpesoDis = true;
                this.chkvolumen = false;
                this.chkvalor = false;
                this.chkfactor = false;
                this.boolDistribuir = true;
                this.boolReverzar = false;
                document.getElementById('divPrincipal').style.pointerEvents = 'none';
                this.aggrid.bloquearGrid()

              } else if (aux.NOTRECIMP_TIPO === '4') {
                this.chkfactor = true;
                this.chkfactorDis = true;
                this.chkpeso = false;
                this.chkvolumen = false;
                this.chkvalor = false;
                this.boolDistribuir = true;
                this.boolReverzar = false;
                document.getElementById('divPrincipal').style.pointerEvents = 'none';
                this.aggrid.bloquearGrid()

              } else {
                console.log('3');
                this.boolDistribuir = false;
                this.boolReverzar = true;
                this.chkfactor = false;
                this.chkpeso = false;
                this.chkvolumen = false;
                this.chkvalor = false;
                this.btnNuevoGrid = false;
                this.btnBorrarGrid = false;
                this.btnCancelarGrid = false;
                document.getElementById('divImportacioneschk').style.pointerEvents = 'all';
                // document.getElementById('divImportacioneschk').style.opacity = '1';
                this.aggrid.habilitarGrid()
              }
              this.controlchecksImportacion();
              this.comnotrecServicio.calcularImportacion(this.comnotrecServicio.comnotrecImportacionarray[this.indicador].indexOf(aux));
            });

          });
        } else {
          this.comnotrecServicio.comnotrecImportacionarray[this.indicador] = [];
          this.boolDistribuir = false;//CDPJ
          this.boolReverzar = true;
          this.chkfactor = false;
          this.chkpeso = false;
          this.chkvolumen = false;
          this.chkvalor = false;
          document.getElementById('divImportacioneschk').style.pointerEvents = 'none';
          // document.getElementById('divImportacioneschk').style.opacity = '0.6';
          this.aggrid.habilitarGrid()
        }
      });
      this.comnotrecServicio.comnotrecImportacionarray[this.indicador] = [];
      //CDPJ

    } else if (this.notRecSeleccionado.ENCNOTREC_ESTADO === 'A') {
      this.auxEstado = 'ANULADA';
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      // document.getElementById('divPrincipal').style.opacity = '0.7';
      this.aggrid.bloquearGrid();//CDPJ
      this.btnOrdenCompra = true;
      this.btnNuevoGrid = true;
      this.btnBorrarGrid = true;
      this.btnCancelarGrid = true;
      this.btnBorrar = true;
      // this.columnDefsRecepcion.map(aux => { CDPJ COMENTÉ
      //   aux.editable = false;
      // });
      // this.aggrid.refreshColumnDefs();

    }
    this.comnotrecServicio.getDetnotRec(this.notRecSeleccionado.ENCNOTREC_NUMERO).subscribe(async res => {

      if (res !== null) {
        this.errores = 0;

        this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador] = res;
        ///GSRF
        this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].map(re => {
          re.DETNOTREC_COSTOFOB = re.DETNOTREC_COSTOFOB.toFixed(this.comnotrecServicio.decimalesGenerales);
        })
        const NOTRECSIFA = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOTRECSIFA').CFG_VALOR1;
        console.log(NOTRECSIFA);
        for (const count3 of this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador]) {
          count3.DETNOTREC_AJUSTE = count3.DETNOTREC_AJUSTE === null || count3.DETNOTREC_AJUSTE === undefined ? '' : count3.DETNOTREC_AJUSTE;//GSRF
          console.log(count3);
         //CDPJ
         count3.LINEA = count3.DETNOTREC_LINEA;
         count3.CEN_CODIGO= count3.CEN_CODIGO === null || count3.CEN_CODIGO === undefined?'':count3.CEN_CODIGO;
         //CDPJ
          let contarkar = await this.comnotrecServicio.consultarfaccomatada(count3);
          console.log(contarkar[0].CONTAR);
          if (NOTRECSIFA === 1) {
            if (contarkar[0].CONTAR !== 0) {
              console.log('aqui');
              document.getElementById('divPrincipal').style.pointerEvents = 'none';
              // document.getElementById('divPrincipal').style.opacity = '0.7';
              this.aggrid.bloquearGrid();//CDPJ
              this.btnOrdenCompra = true;
              this.btnNuevoGrid = true;
              this.btnBorrarGrid = true;
              this.btnCancelarGrid = true;
              this.btnBorrar = true;
              this.messageService.add({
                key: 'notrec',
                severity: 'success',
                summary: 'Nota de recepción',
                detail: 'Esta nota de recepcion cuenta con items ya facturados'
              });
            }
          }
        }
        ///GSRF
        this.auxBodCod = this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador][0].BOD_CODIGO;
        this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].map(aux => {
          if (aux.DETNOTREC_TIPODET === '*') {
            aux.DETNOTREC_CANTIDAD = '';
            aux.DETNOTREC_FACTOR = '';
            aux.DETNOTREC_COSTO = '';
            aux.DETNOTREC_DESCUENTO = '';
            aux.DETNOTREC_DESCUENTO2 = '';
            aux.DETNOTREC_DESCUENTO3 = '';
            aux.DETNOTREC_TOTAL = '';
            aux.DETNOTREC_PORFACTOR = '';
            aux.DETNOTREC_COSTOFOB = '';
            aux.DETNOTREC_ARTPESO = '';
          } else if (aux.DETNOTREC_TIPODET === 'A' || aux.DETNOTREC_TIPODET === 'S') {
            this.comnotrecServicio.calcularTotales(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].indexOf(aux));
            this.validarSeriales(aux);
            this.comnotrecServicio.consultarArticuloSel(aux.DETNOTREC_CODIGO).subscribe(res1 => {
              res1.map(dat => {

                aux.ART_CADUCA = dat.CADUCA;
              });

            });

          }


        });
        this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador], this.defaultRecepcion.objeto);

      }


    });
    this.seleccionarChecks();
  }


  senialestablaImportacion(valor) {
    if (valor === 'nuevo') {
      //CDPJ
      this.btnNuevoGrid = true;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      //CDPJ
      this.cambio(valor);
      const numero = this.comnotrecServicio.comnotrecImportacionarray[this.indicador].length;
      const nuevo = {
        ENCNOTREC_NUMERO: this.notRecSeleccionado.ENCNOTREC_NUMERO,
        COM_CODIGO: '01',
        NOTRECIMP_NUMSEC: Number(numero + 1),
        REF_TIPO: 'IMP',
        REF_CODIGO: '',
        NOTRECIMP_BASE: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
        NOTRECIMP_PORCENTAJE: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
        NOTRECIMP_IMPORTE: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
        NOTRECIMP_TIPO: null,
        REF_NOMBRE: '',
        LINEA: Number(numero + 1)

      };
      this.notRecImportacion = nuevo;
      this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecImportacionarray[this.indicador], this.defaultImportaciones.objeto);
      this.comnotrecServicio.comnotrecImportacionarray[this.indicador].push(this.notRecImportacion);
      // this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecImportacionarray[this.indicador], this.defaultImportaciones.objeto);
      // this.comnotrecServicio.calcularImportacion(this.comnotrecServicio.comnotrecImportacionarray[this.indicador].indexOf(nuevo));
      //CDPJ
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultImportaciones.objeto].setFocusedCell(
        this.comnotrecServicio.comnotrecImportacionarray[this.indicador].indexOf(this.notRecImportacion), 'PRO_NOMBRE');
      this.comnotrecServicio.calcularImportacion(this.comnotrecServicio.comnotrecImportacionarray[this.indicador].indexOf(nuevo));
      this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecImportacionarray[this.indicador], this.defaultImportaciones.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultImportaciones.objeto].setFocusedCell(
        this.comnotrecServicio.comnotrecImportacionarray[this.indicador].indexOf(this.notRecImportacion), 'REF_CODIGO');
      //CDPJ
    }
    if (valor === 'eliminar') {

      this.comnotrecServicio.encontrarEncNotNumeroImp(this.notRecImportacion.ENCNOTREC_NUMERO, this.notRecImportacion.NOTRECIMP_NUMSEC).subscribe(existe => {
        if (existe) {
          this.confirmationService.confirm({
            message: 'Esta seguro de eliminar el registro seleccionado',
            header: 'Eliminar registro',
            icon: 'pi pi-exclamation-triangle',
            key: 'notrecConfirmar',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            accept: () => {
              this.comnotrecServicio.eliminarImportacion(this.notRecImportacion).subscribe(res => {
                this.llenartablaImportaciones();
                this.messageService.add({
                  key: 'notrec',
                  severity: 'success',
                  summary: 'Eliminación del registro',
                  detail: 'Registro eliminado con éxito'
                });
                this.auditoriagral.registrarAuditoria('com_notrecimp', this.notRecImportacion.ENCNOTREC_NUMERO, 'E',
                  '', '01', '', '', '', '').pipe(finalize(() => this.guardarImportacion())).subscribe(() => {
                  });
              });


            },
            reject: () => {
            }
          });


        } else if (!existe) {
          this.confirmationService.confirm({
            message: 'Esta seguro de eliminar el registro seleccionado',
            header: 'Eliminar registro',
            icon: 'pi pi-exclamation-triangle',
            key: 'notrecConfirmar',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            accept: () => {
              this.comnotrecServicio.comnotrecImportacionarray[this.indicador].splice(
                this.comnotrecServicio.comnotrecImportacionarray[this.indicador].indexOf(this.notRecImportacion), 1);
              this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecImportacionarray[this.indicador], this.defaultImportaciones.objeto);
              this.messageService.add({
                key: 'notrec',
                severity: 'success',
                summary: 'Eliminación del registro',
                detail: 'Registro eliminado con éxito'
              });
            },
            reject: () => {
            }
          });

        }
      });


      this.btnNuevoGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;


    }
    if (valor === 'cancelar') {

      //CDPJ
      this.booleanATasa = true;
      this.booleanOriginal = false;
      //CDPJ
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnBuscar = false;
      if (this.indice === 0) {
        this.btnAnterior = true;
        this.btnPrimero = true;
        this.btnUltimo = false;
        this.btnSiguiente = false;
        this.btnRegresar = false;
      } else if (this.indice === this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador].length - 1) {
        this.btnAnterior = false;
        this.btnPrimero = false;
        this.btnUltimo = true;
        this.btnSiguiente = true;
        this.btnRegresar = false;
      } else {
        this.btnPrimero = false;
        this.btnAnterior = false;
        this.btnSiguiente = false;
        this.btnUltimo = false;
      }
      this.llenartablaImportaciones();


    }
    if (valor.object !== undefined) {
      this.notRecImportacion = valor.object;

    }
  }


  senialestablaVarios(valor) {
    if (valor === 'nuevo') {
      this.cambio(valor);
      const numero = this.comnotrecServicio.comnotrecVararray[this.indicador].length;
      const nuevo = {
        ENCNOTREC_NUMERO: this.notRecSeleccionado.ENCNOTREC_NUMERO,
        COM_CODIGO: '01',
        NOTRECVAR_LINEA: Number(numero + 1),
        NOTRECVAR_IMPORTE: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
        CON_CODIGO: '',
        CEN_CODIGO: '',
        CON_NOMBRE: '',
        LINEA: Number(numero + 1)
      };
      this.notRecVarios = nuevo;
      this.comnotrecServicio.comnotrecVararray[this.indicador].push(this.notRecVarios);
      this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecVararray[this.indicador], this.defaultVarios.objeto);
    }

    if (valor === 'eliminar') {
      this.confirmationService.confirm({
        message: 'Esta seguro de eliminar el registro seleccionado',
        header: 'Eliminar registro',
        icon: 'pi pi-exclamation-triangle',
        key: 'notrecConfirmar',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.comnotrecServicio.comnotrecVararray[this.indicador].splice(this.comnotrecServicio.comnotrecVararray[this.indicador].indexOf(this.notRecVarios), 1);
          this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecVararray[this.indicador], this.defaultVarios.objeto);
          this.messageService.add({
            key: 'notrec',
            severity: 'success',
            summary: 'Eliminación del registro',
            detail: 'Registro eliminado con éxito'
          });
          this.banderaVarios = false;
          this.cambio(false);
          this.comnotrecServicio.calcularVarios();
        },
        reject: () => {
        }
      });


    }
    if (valor === 'cancelar') {

      //CDPJ
      this.booleanATasa = false;
      this.booleanOriginal = true;
      //CDPJ
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnBuscar = false;
      if (this.indice === 0) {
        this.btnAnterior = true;
        this.btnPrimero = true;
        this.btnUltimo = false;
        this.btnSiguiente = false;
        this.btnRegresar = false;
      } else if (this.indice === this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador].length - 1) {
        this.btnAnterior = false;
        this.btnPrimero = false;
        this.btnUltimo = true;
        this.btnSiguiente = true;
        this.btnRegresar = false;
      } else {
        this.btnPrimero = false;
        this.btnAnterior = false;
        this.btnSiguiente = false;
        this.btnUltimo = false;
      }
      this.llenartablaVarios();


    }
    if (valor.object !== undefined) {
      this.notRecVarios = valor.object;

    }
  }

  async distribuir() {
    // validacion si esta guardado o no con la lo importacion con la longitud

    if (await this.verificacionDatosCompletos() === 0) {//CDPJ
      this.comnotrecServicio.getImportaciones(this.notRecSeleccionado.ENCNOTREC_NUMERO).subscribe(res => {
        if (this.comnotrecServicio.comnotrecImportacionarray[this.indicador].length === res.length) {
          switch (this.numberchk) {
            case 1:
              this.comnotrecServicio.realizarDistribucionxValor();
              this.comnotrecServicio.comnotrecImportacionarray[this.indicador].map(aux => {
                this.comnotrecServicio.eliminarImportacion(aux).subscribe(res1 => {
                  this.comnotrecServicio.insertarImportacion(aux, 1).subscribe(res2 => {

                  });
                  this.boolDistribuir = true;
                  this.boolReverzar = false;
                  this.chkfactorDis = true;
                  this.chkvalorDis = true;
                  this.chkvolumenDis = true;
                  this.chkpesoDis = true;
                  this.bolean = true;//CDPJ
                  document.getElementById('divImportacioneschk').style.pointerEvents = 'none';
                  // document.getElementById('divImportacioneschk').style.opacity = '0.7';
                });
              });


              break;
            case 2:
              this.comnotrecServicio.realizarDistribucionxVolumen();
              this.comnotrecServicio.comnotrecImportacionarray[this.indicador].map(aux => {
                this.comnotrecServicio.eliminarImportacion(aux).subscribe(res1 => {
                  this.comnotrecServicio.insertarImportacion(aux, 2).subscribe(() => {

                  });
                  this.boolDistribuir = true;
                  this.boolReverzar = false;
                  this.chkfactorDis = true;
                  this.chkvalorDis = true;
                  this.chkvolumenDis = true;
                  this.chkpesoDis = true;
                  this.bolean = true;//CDPJ
                  document.getElementById('divImportacioneschk').style.pointerEvents = 'none';
                  // document.getElementById('divImportacioneschk').style.opacity = '0.7';
                });
              });
              break;
            case 3:
              this.comnotrecServicio.realizarDistribucionxPeso();
              this.comnotrecServicio.comnotrecImportacionarray[this.indicador].map(aux => {
                this.comnotrecServicio.eliminarImportacion(aux).subscribe(res1 => {
                  this.comnotrecServicio.insertarImportacion(aux, 3).subscribe(res2 => {

                  });
                  this.boolDistribuir = true;
                  this.boolReverzar = false;
                  this.chkfactorDis = true;
                  this.chkvalorDis = true;
                  this.chkvolumenDis = true;
                  this.chkpesoDis = true;
                  this.bolean = true;//CDPJ
                  document.getElementById('divImportacioneschk').style.pointerEvents = 'none';
                  // document.getElementById('divImportacioneschk').style.opacity = '0.7';
                });
              });
              break;
            case 4:
              this.comnotrecServicio.realizarDistribucionxFactor();
              this.comnotrecServicio.comnotrecImportacionarray[this.indicador].map(aux => {
                this.comnotrecServicio.eliminarImportacion(aux).subscribe(res1 => {
                  this.comnotrecServicio.insertarImportacion(aux, 4).subscribe(res2 => {

                  });
                  this.boolDistribuir = true;
                  this.boolReverzar = false;
                  this.chkfactorDis = true;
                  this.chkvalorDis = true;
                  this.chkvolumenDis = true;
                  this.chkpesoDis = true;
                  this.bolean = true;//CDPJ
                  document.getElementById('divImportacioneschk').style.pointerEvents = 'none';
                  // document.getElementById('divImportacioneschk').style.opacity = '0.7';
                });
              });
              break;
            case 0:
              this.messageService.add({
                key: 'notrec',
                severity: 'error',
                summary: 'Importación',
                detail: 'Primero debe elegir un tipo de distribución para poder realizarla.'
              });
              break;
            default:
              break;

          }
          this.messageService.add({
            key: 'notrec',
            severity: 'success',
            summary: 'Ingreso Notas de Recepción',
            detail: 'Registro guardado con éxito'
          });
        } else {
          this.bolean = false;//CDPJ
          this.messageService.add({
            key: 'notrec',
            severity: 'error',
            summary: 'Importación',
            detail: 'Primero guarde los datos de la importación'
          });
          return;
        }

      });
      this.guardarNotaRecepcion();//CDPJ
    } else {
      this.bolean = false;//CDPJ
      this.messageService.add({
        key: 'notrec',
        severity: 'error',
        summary: 'Ingreso Notas de Recepción',
        detail: 'Los datos del detalle de la nota de recepción no estan completos, por favor revise los valores e intente nuevamente.'
      });
    }

  }

  reverzar() {
    this.comnotrecServicio.reverzarDistribucion();
    this.comnotrecServicio.comnotrecImportacionarray[this.indicador].map(aux => {
      this.comnotrecServicio.eliminarImportacion(aux).subscribe(res1 => {
        this.comnotrecServicio.insertarImportacion(aux, null).subscribe(res2 => {

        });
        this.boolDistribuir = false;
        this.boolReverzar = true;
        this.chkfactorDis = false;
        this.chkvalorDis = false;
        this.chkvolumenDis = false;
        this.chkpesoDis = false;
        this.chkfactor = false;
        this.chkvalor = false;
        this.chkvolumen = false;
        this.chkpeso = false;
        //CDPJ
        this.bolean = false;
        this.btnNuevoGrid = false;
        this.btnBorrarGrid = false;
        this.btnCancelarGrid = false;
        //CDPJ
        this.controlchecksImportacion();
        document.getElementById('divImportacioneschk').style.pointerEvents = 'all';
        // document.getElementById('divImportacioneschk').style.opacity = '1';
        this.messageService.add({
          key: 'notrec',
          severity: 'success',
          summary: 'Ingreso Notas de Recepción',
          detail: 'Registro guardado con éxito'
        });
      });
    });
    this.guardarNotaRecepcion();//CDPJ

  }

  async senialestablaRecepcion(valor) {
    //
    if (valor === 'nuevo') {
          //CDPJ
          if(isNaN(Number(this.notRecSeleccionado.ENCNOTREC_PORCEIVA))){
            this.messageService.add({
              key: 'notrec',
              severity: 'error',
              summary: 'Ingreso Detalle',
              detail: 'El campo % I.V.A. Está vacío'
            });
            return;
          }
          if(Number(this.notRecSeleccionado.ENCNOTREC_PORCEIVA) === 0){
            this.comnotrecServicio.tariivacod='';
            this.comnotrecServicio.porceiva=Number(this.notRecSeleccionado.ENCNOTREC_PORCEIVA);
          }else{
            const data =this.confIniciales.srifetariiva.find(element => element.TARIIVA_PORCENTAJE === Number(this.notRecSeleccionado.ENCNOTREC_PORCEIVA))
            this.comnotrecServicio.tariivacod=data.TARIIVA_CODIGO
            this.comnotrecServicio.porceiva=Number(this.notRecSeleccionado.ENCNOTREC_PORCEIVA);
          }
          //CDPJ
      if (this.auxBodCod === '') {
        this.messageService.add({
          key: 'notrec',
          severity: 'error',
          summary: 'Ingreso Detalle',
          detail: 'Por favor ingrese una bodega para ingresar el detalle'
        });
        document.getElementById('auxBodCod').focus();

      } else {
        this.nuevoDetalleOrden();
      }
    } else if (valor === 'eliminar') {
      if (this.notRecSeleccionado.ENCNOTREC_NUMERO === null) {
        this.confirmationService.confirm({
          message: 'Esta seguro de eliminar el registro seleccionado',
          header: 'Eliminar registro',
          icon: 'pi pi-exclamation-triangle',
          key: 'notrecConfirmar',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            //CDPJ
            if (this.notRecRecepcion.DETNOTREC_LINEA !== 0) {
              //this.notRecRecepcion.DETNOTREC_LINEA=this.notRecRecepcion.LINEA;
              this.comdetnotrecServicio.eliminarDetnotRec(this.notRecSeleccionado.ENCNOTREC_NUMERO, this.notRecRecepcion).subscribe(res => {

              });
              this.auditoriagral.registrarAuditoria('COM_DETNOTREC', this.notRecSeleccionado.ENCNOTREC_NUMERO + '/' + this.notRecRecepcion.DETNOTREC_CODIGO, 'E', '', '01', '', '', '',
                '').subscribe(() => {
                });

              this.seleccionarDetalle();
            } else {
              this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].splice(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].indexOf(this.notRecRecepcion), 1);
              this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador], this.defaultRecepcion.objeto);
            }
            //CDPJ
            this.messageService.add({
              key: 'notrec',
              severity: 'success',
              summary: 'Eliminación del registro',
              detail: 'Registro eliminado con éxito'
            });


          },
          reject: () => {
          }
        });

      } else {
        this.confirmationService.confirm({
          message: 'Esta seguro de eliminar el registro seleccionado',
          header: 'Eliminar registro',
          icon: 'pi pi-exclamation-triangle',
          key: 'notrecConfirmar',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            //CDPJ
            if (this.notRecRecepcion.DETNOTREC_LINEA !== 0) {
              //this.notRecRecepcion.DETNOTREC_LINEA=this.notRecRecepcion.LINEA;
              this.comdetnotrecServicio.eliminarDetnotRec(this.notRecSeleccionado.ENCNOTREC_NUMERO, this.notRecRecepcion).subscribe(res => {

              });
              this.auditoriagral.registrarAuditoria('COM_DETNOTREC', this.notRecSeleccionado.ENCNOTREC_NUMERO + '/' + this.notRecRecepcion.DETNOTREC_CODIGO, 'E', '', '01', '', '', '',
                '').subscribe(() => {
                });
              this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].splice(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].indexOf(this.notRecRecepcion), 1);//GSRF
              this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador], this.defaultRecepcion.objeto);//GSRF
              //this.seleccionarDetalle();
            } else {
              this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].splice(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].indexOf(this.notRecRecepcion), 1);
              this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador], this.defaultRecepcion.objeto);
            }
            //CDPJ
            this.messageService.add({
              key: 'notrec',
              severity: 'success',
              summary: 'Eliminación del registro',
              detail: 'Registro eliminado con éxito'
            });
          },
          reject: () => {
          }
        });


      }
      this.btnNuevoGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = true;
      this.btnOrdenCompra = false;
    } else if (valor === 'cancelar') {
      //CDPJ
      this.booleanATasa = true;
      this.booleanOriginal = false;

      //CDPJ
      this.btnNuevoGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = true;
      this.btnOrdenCompra = false;
      if (this.notRecSeleccionado.ENCNOTREC_NUMERO === null) {
        this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].map(aux => {
          if (aux.DETNOTREC_DESCRIPCION === '') {
            this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].splice(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].indexOf(aux), 1);
            this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador], this.defaultRecepcion.objeto);
          }
        });
      } else {
        this.seleccionarDetalle();
      }
    } else if (valor === 'ordenCompra') {
      if (this.proveedorNombre === '') {
        this.messageService.add({
          key: 'notrec',
          severity: 'error',
          summary: 'Ingreso Orden Compra',
          detail: 'Primero debe ingresar un proveedor para poder insertar una orden de compra en el detalle'
        });

        document.getElementById('PRO_CODIGO').focus();


      } else if (this.auxBodCod === '') {
        this.messageService.add({
          key: 'notrec',
          severity: 'error',
          summary: 'Ingreso Orden Compra',
          detail: 'Primero debe ingresar una bodega para poder insertar una orden de compra en el detalle'
        });
        document.getElementById('auxBod').focus();

      } else {
        // codigo tabla buscar orden compra orden compra
        //this.agregarOrdenCompra();
        //CDPJ
        const rs = await this.comnotrecServicio.erBodCodigo1(this.auxBodCod);
        if (rs === null || rs === undefined || rs === ''){
          this.messageService.add({
            key: 'notrec',
            severity: 'error',
            summary: 'Ingreso Notas de Recepción',
            detail: 'Error, Código de la bodega '+this.auxBodCod+ ' es incorrecto'
          });
          return;
        }else{
          // codigo tabla buscar orden compra orden compra
          this.agregarOrdenCompra();
        }
        //CDPJ
      }
    }
    if (valor.object !== undefined) {
      this.notRecRecepcion = valor.object;
      this.tipodetalle = this.notRecRecepcion.DETNOTREC_TIPODET;

    }


  }
  abrirbusquedaiva() {
    this.opcionbusqueda = 'busquedatariiva';
    this.types = [
      { label: 'Código', value: 'TARIIVA_CODIGO' },
      { label: 'Descripcion', value: 'TARIIVA_DESCRIPCION' },
      { label: 'Porcentaje', value: 'TARIIVA_PORCENTAJE' }
    ];

    this.busquedacampos = ['', '', ''];
    this.tabla = 'SRIFE_TARIIVA';
    this.where = 'TARIIVA_ACTIVO=\'S\'';
    this.busqService.busquedaSrifeTariIva(this.where).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      // this.displayDialogBusqueda = true;
      this.displayDialogBusquedaFast = true;
    });
  }
  // getEncnotRec
  manejarSeleccion(opcion) {

    if (this.opcionbusqueda === 'busquedanota') {
      this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador].map((nota) => {
        if (nota.ENCNOTREC_NUMERO === opcion.ENCNOTREC_NUMERO) {
          this.indice = this.comnotrecServicio.comnotrecRecepcionECarray[this.indicador].indexOf(nota);
        }
      });
      this.seleccionarEncabezado();
    } if (this.opcionbusqueda === 'busquedatariiva'){
      this.comnotrecServicio.porceiva=opcion.TARIIVA_PORCENTAJE;
      this.comnotrecServicio.tariivacod=opcion.TARIIVA_CODIGO;
      this.notRecSeleccionado.ENCNOTREC_PORCEIVA=Number(this.comnotrecServicio.porceiva);
      this.displayDialogBusquedaFast = false;
    }
    if (this.opcionbusqueda === 'busquedaproveedor') {
      this.notRecSeleccionado.PRO_CODIGO = opcion.PRO_CODIGO;
      this.proveedorNombre = opcion.PRO_NOMBRE;
      this.notRecSeleccionado.ENCNOTREC_CONTACTO = opcion.PRO_CONTACTO;
      if (opcion.PRO_DESCUENTO !== null) {
        this.dscProveedorActual = opcion.PRO_DESCUENTO;
      } else if (opcion.PRO_DESCUENTO === null) {
        this.dscProveedorActual = 0;
      }
      document.getElementById('ENCNOTREC_CONTACTO').focus();
    }
    if (this.opcionbusqueda === 'busquedabodega') {
      if (this.notRecSeleccionado.ENCNOTREC_NUMERO === null) {
        this.auxBod = opcion.BOD_NOMBRE;
        this.auxBodCod = opcion.BOD_CODIGO;
        this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador] = [];
        this.nuevoDetalleOrden();
      }


    }
    if (this.opcionbusqueda === 'ordenCompra') {
      const strEncOrdCom = opcion.ENCORDCOM_NUMERO;
      this.detnumero = strEncOrdCom;
      const intSALCM = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'SALCM').CFG_VALOR1;
      if (intSALCM === 1) { // manejo especial utilizando requisiciones
        this.verificaSiTieneLiberacionReq(strEncOrdCom);
      } else {
        this.LlenarcamposOrdenCompra(strEncOrdCom);
      }


    }

    if (this.opcionbusqueda === 'articulo') {
      this.txtArtCodigo = opcion.ART_CODIGO;
      this.txtArtDescripcion = opcion.ART_NOMBRE;
      this.txtArtUnidad = opcion.ART_UNIDADCOSTEO;
      this.txtExistenciaMin = opcion.ART_CANTMIN === null ? 0 : opcion.ART_CANTMIN;
      this.txtExistenciaMax = opcion.ART_CANTMAX === null ? 0 : opcion.ART_CANTMAX;
      this.llenarTablaExistencia();
      this.llenarMovByProvArt('fecha');
      this.llenarExistenciaBodegas();
      this.ventasVsCompras();
      this.llenarIndiceProvisiones();
    }

    this.displayDialogBusqueda = false;
    this.displayDialogBusquedaFast = false;
  }

  async LlenarcamposOrdenCompra(strEncOrdComNumero) {
    let strComprobante = '';

    let strSentencia = 'select * from com_encordcom where encordcom_numero='
      + '\'' + strEncOrdComNumero + '\'';
    strSentencia = strSentencia + ' and  com_codigo=' + '\'01\'';

    const data = await this.comnotrecServicio.ejecutarConsultaPromise(strEncOrdComNumero);
    this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].map(aux => {
      //  verifica si la linea esta vacia y la elimina
      if (aux.DETNOTREC_CODIGO === '') {
        this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].splice(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].indexOf(aux), 1);
        this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador], this.defaultRecepcion.objeto);
      }
    });

    const numero = this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].length;
    this.valorDscValor = data[0].ENCORDCOM_VALORDES;
    this.valorDscPorcentaje = data[0].ENCORDCOM_PORCEDES;

    strComprobante = data[0].ENCORDCOM_REFERENCIA;

    const nuevo = {
      BOD_CODIGO: this.auxBodCod,
      DETNOTREC_CODIGO: '',
      ENCNOTREC_NUMERO: this.notRecSeleccionado.ENCNOTREC_NUMERO,
      DETNOTREC_DESCRIPCION: '====<< ORDEN DE COMPRA ' + strComprobante + '>>====',
      DETNOTREC_TRIBIVA: '',
      DETNOTREC_TRIBICE: '',
      DETNOTREC_UNIDAD: '',
      DETNOTREC_CANTIDAD: Number(0).toFixed(this.comnotrecServicio.decimalesCantidad),
      DETNOTREC_CANFACDEV: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      DETNOTREC_COSTO: Number(0).toFixed(this.comnotrecServicio.decimalesCosto),
      DETNOTREC_TOTAL: Number(0).toFixed(this.comnotrecServicio.decimalesTotal),
      DETNOTREC_PORFACTOR: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      DETNOTREC_COSTOFOB: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      DETNOTREC_ARTPESO: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      CEN_CODIGO: null,
      DETNOTREC_CANTIDADUND: '0.0',
      COM_CODIGO: '01',
      DETNOTREC_LINEA: 0,
      DETNOTREC_TIPODET: '*',
      DETNOTREC_IVA: '',
      DETNOTREC_ICE: '',
      DETNOTREC_DESPACHO: 0,
      DETNOTREC_DESCUENTO: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      DETNOTREC_PORCEICE: '',
      DETNOTREC_BASEIVA: '',
      DETNOTREC_BASEICE: '',
      DETNOTREC_BASECERO: '',
      ENCORDCOM_NUMERO: '',
      COM_CODIGO1: '',
      DETORDCOM_LINEA: '',
      DETNOTREC_DESCUENTO2: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      DETNOTREC_DESCUENTO3: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      DETNOTREC_FACTOR: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      DETNOTREC_VALORDES: 0,
      DETNOTREC_PORCEDES: 0,
      DETNOTREC_LOTE: '',
      DETNOTREC_AJUSTE: '',
      LINEA: Number(numero + 1),
      DETNOTREC_PORIVA:0,
      TARIIVA_CODIGO:'-1'

    };
    this.notRecRecepcion = nuevo;
    this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].push(this.notRecRecepcion);

    const dat = await this.comnotrecServicio.consultarDetalleOrdenCompra(data[0]);
    dat.map(aux => {
       //CDPJ
       if (aux.DETORDCOM_TIPODET !== 'S'){
      console.log(aux);
      //GSRF
      let sumdat = Number(aux.DETORDCOM_CANTIDAD)-Number(aux.DETORDCOM_DESPACHO);
      console.log('suma---',sumdat)
      if(Number(sumdat) > Number(0) || aux.DETORDCOM_TIPODET === '*'){
         //GSRF
      const dato = {
        BOD_CODIGO: aux.BOD_CODIGO,
        DETNOTREC_CODIGO: aux.DETORDCOM_CODIGO,
        ENCNOTREC_NUMERO: this.notRecSeleccionado.ENCNOTREC_NUMERO,
        DETNOTREC_DESCRIPCION: aux.DETORDCOM_DESCRIPCION,
        DETNOTREC_TRIBIVA: aux.DETORDCOM_TRIBIVA,
        DETNOTREC_TRIBICE: aux.DETORDCOM_TRIBICE,
        DETNOTREC_UNIDAD: aux.DETORDCOM_UNIDAD,
        DETNOTREC_CANTIDAD: Number(sumdat).toFixed(this.comnotrecServicio.decimalesCantidad),
        DETNOTREC_CANFACDEV: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
        DETNOTREC_COSTO: Number(aux.DETORDCOM_COSTO).toFixed(this.comnotrecServicio.decimalesCosto),
        DETNOTREC_TOTAL: Number(aux.DETORDCOM_TOTAL).toFixed(this.comnotrecServicio.decimalesTotal),
        DETNOTREC_PORFACTOR: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
        DETNOTREC_COSTOFOB: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
        DETNOTREC_ARTPESO: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
        CEN_CODIGO: null,
        DETNOTREC_CANTIDADUND: Number(aux.DETORDCOM_CANTIDADUND).toFixed(this.comnotrecServicio.decimalesGenerales),
        COM_CODIGO: '01',
        DETNOTREC_LINEA: 0,
        DETNOTREC_TIPODET: aux.DETORDCOM_TIPODET,
        DETNOTREC_IVA: aux.DETORDCOM_IVA,
        DETNOTREC_ICE: aux.DETORDCOM_ICE,
        DETNOTREC_DESPACHO: aux.DETORDCOM_DESPACHO,
        DETNOTREC_DESCUENTO: Number(aux.DETORDCOM_DESCUENTO).toFixed(this.comnotrecServicio.decimalesGenerales),
        DETNOTREC_PORCEICE: aux.DETORDCOM_PORCEICE,
        DETNOTREC_BASEIVA: aux.DETORDCOM_BASEIVA,
        DETNOTREC_BASEICE: aux.DETORDCOM_BASEICE,
        DETNOTREC_BASECERO: aux.DETORDCOM_BASECERO,
        ENCORDCOM_NUMERO: data[0].ENCORDCOM_NUMERO,
        COM_CODIGO1: '',
        DETORDCOM_LINEA: aux.DETORDCOM_LINEA,
        DETNOTREC_DESCUENTO2: Number(aux.DETORDCOM_DESCUENTO2).toFixed(this.comnotrecServicio.decimalesGenerales),
        DETNOTREC_DESCUENTO3: Number(aux.DETORDCOM_DESCUENTO3).toFixed(this.comnotrecServicio.decimalesGenerales),
        DETNOTREC_FACTOR: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
        DETNOTREC_VALORDES: 0,
        DETNOTREC_PORCEDES: 0,
        DETNOTREC_LOTE: '',
        DETNOTREC_AJUSTE: '',
        LINEA: Number(numero + 1),
        DETNOTREC_PORIVA:isNaN(Number(aux.DETORDCOM_PORIVA))?0: Number(Number(aux.DETORDCOM_PORIVA).toFixed(this.comnotrecServicio.decimalesGenerales)),
        TARIIVA_CODIGO:aux.TARIIVA_CODIGO === null || aux.TARIIVA_CODIGO === undefined?'':aux.TARIIVA_CODIGO

      };
      this.notRecRecepcion = dato;
      this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].push(this.notRecRecepcion);
     }
    }
    });


    this.cambio(null);
    const fin = {
      BOD_CODIGO: this.auxBodCod,
      DETNOTREC_CODIGO: '',
      ENCNOTREC_NUMERO: this.notRecSeleccionado.ENCNOTREC_NUMERO,
      DETNOTREC_DESCRIPCION: '===========================================',
      DETNOTREC_TRIBIVA: '',
      DETNOTREC_TRIBICE: '',
      DETNOTREC_UNIDAD: '',
      DETNOTREC_CANTIDAD: Number(0).toFixed(this.comnotrecServicio.decimalesCantidad),
      DETNOTREC_CANFACDEV: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      DETNOTREC_COSTO: Number(0).toFixed(this.comnotrecServicio.decimalesCosto),
      DETNOTREC_TOTAL: Number(0).toFixed(this.comnotrecServicio.decimalesTotal),
      DETNOTREC_PORFACTOR: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      DETNOTREC_COSTOFOB: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      DETNOTREC_ARTPESO: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      CEN_CODIGO: null,
      DETNOTREC_CANTIDADUND: '0.0',
      COM_CODIGO: '01',
      DETNOTREC_LINEA: 0,
      DETNOTREC_TIPODET: '*',
      DETNOTREC_IVA: '',
      DETNOTREC_ICE: '',
      DETNOTREC_DESPACHO: 0,
      DETNOTREC_DESCUENTO: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      DETNOTREC_PORCEICE: '',
      DETNOTREC_BASEIVA: '',
      DETNOTREC_BASEICE: '',
      DETNOTREC_BASECERO: '',
      ENCORDCOM_NUMERO: '',
      COM_CODIGO1: '',
      DETORDCOM_LINEA: '',
      DETNOTREC_DESCUENTO2: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      DETNOTREC_DESCUENTO3: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      DETNOTREC_FACTOR: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      DETNOTREC_VALORDES: 0,
      DETNOTREC_PORCEDES: 0,
      DETNOTREC_LOTE: '',
      DETNOTREC_AJUSTE: '',
      LINEA: Number(numero + 1),
      DETNOTREC_PORIVA:0,
      TARIIVA_CODIGO:'-1'
    };
    this.notRecRecepcion = fin;
    this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].push(this.notRecRecepcion);
    this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador], this.defaultRecepcion.objeto);
    this.comnotrecServicio.calcularTotales(null);
    if (data[0].ENCORDCOM_TIPODES === 'V') {
      this.descuentoAplicado('valor');
    }
    if (data[0].ENCORDCOM_TIPODES === 'P') {
      this.descuentoAplicado('porcentaje');
    }
    this.btnNuevoGrid = false;
  }

  async nuevoDetalleOrden() {

    this.btnNuevoGrid = false;
    this.btnBorrarGrid = false;
    this.btnCancelarGrid = false;
    this.btnOrdenCompra = false;
    let numero;
    const rs = await this.comnotrecServicio.erBodCodigo1(this.auxBodCod);
    if (rs === null || rs === undefined || rs === ''){
      this.messageService.add({
        key: 'notrec',
        severity: 'error',
        summary: 'Ingreso Notas de Recepción',
        detail: 'Error, Código de la bodega '+this.auxBodCod+ ' es incorrecto'
      });
      return;
    }else{
    numero = this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].length;
    this.displayDialogBusqueda = false;

    const nuevo = {
      BOD_CODIGO: this.auxBodCod,
      DETNOTREC_CODIGO: '',
      ENCNOTREC_NUMERO: '',
      DETNOTREC_DESCRIPCION: '',
      DETNOTREC_TRIBIVA: '',
      DETNOTREC_TRIBICE: '',
      DETNOTREC_UNIDAD: '',
      DETNOTREC_CANTIDAD: Number(0).toFixed(this.comnotrecServicio.decimalesCantidad),
      DETNOTREC_CANFACDEV: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      DETNOTREC_COSTO: Number(0).toFixed(this.comnotrecServicio.decimalesCosto),
      DETNOTREC_TOTAL: Number(0).toFixed(this.comnotrecServicio.decimalesTotal),
      DETNOTREC_PORFACTOR: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      DETNOTREC_COSTOFOB: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      DETNOTREC_ARTPESO: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      CEN_CODIGO: null,
      DETNOTREC_CANTIDADUND: '0.0',
      COM_CODIGO: '01',
      DETNOTREC_LINEA: 0,
      DETNOTREC_TIPODET: this.tipodetalle,
      DETNOTREC_IVA: '',
      DETNOTREC_ICE: '',
      DETNOTREC_DESPACHO: 0,
      DETNOTREC_DESCUENTO: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      DETNOTREC_PORCEICE: '',
      DETNOTREC_BASEIVA: '',
      DETNOTREC_BASEICE: '',
      DETNOTREC_BASECERO: '',
      ENCORDCOM_NUMERO: '',
      COM_CODIGO1: '',
      DETORDCOM_LINEA: '',
      DETNOTREC_DESCUENTO2: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      DETNOTREC_DESCUENTO3: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      DETNOTREC_FACTOR: Number(0).toFixed(this.comnotrecServicio.decimalesGenerales),
      DETNOTREC_VALORDES: 0,
      DETNOTREC_PORCEDES: 0,
      DETNOTREC_LOTE: '',
      DETNOTREC_AJUSTE: '',
      LINEA: Number(numero + 1),
      DETNOTREC_PORIVA:Number(this.comnotrecServicio.porceiva),
      TARIIVA_CODIGO:this.comnotrecServicio.tariivacod

    };

    const indi = this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].indexOf(this.notRecRecepcion);
    if (indi === -1) {
      this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].push(nuevo);
      this.notRecRecepcion = this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador]
      [this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].length - 1];
      this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador], this.defaultRecepcion.objeto);

      if (this.tipodetalle === '*') {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultRecepcion.objeto].setFocusedCell(
          this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].length - 1, 'DETNOTREC_DESCRIPCION');
      } else {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultRecepcion.objeto].setFocusedCell(
          this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].length - 1, 'DETNOTREC_CODIGO');
      }


    } else {
      //CDPJ
      this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].splice(indi + 1, 0, nuevo);
      this.notRecRecepcion = nuevo;
      this.aggrid.refreshaggridindex(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador], this.defaultRecepcion.objeto, indi);
      console.log('else', this.notRecRecepcion)
      //CDPJ
      if (this.tipodetalle === '*') {
        this.aggrid.refreshaggridindex(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador], this.defaultRecepcion.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultRecepcion.objeto].setFocusedCell(
          indi + 1, 'DETNOTREC_DESCRIPCION');
      } else {
        this.aggrid.refreshaggridindex(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador], this.defaultRecepcion.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultRecepcion.objeto].setFocusedCell(
          indi + 1, 'DETNOTREC_CODIGO');

      }

    }

    this.btnNuevoGrid = true;


    /* this.notRecRecepcion = nuevo;
    this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].push(this.notRecRecepcion);
    this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador],
    this.defaultRecepcion.objeto);
    if (this.tipodetalle === '*') {
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultRecepcion.objeto].setFocusedCell
      (this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].length - 1, 'DETNOTREC_DESCRIPCION');
    } else {
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultRecepcion.objeto].setFocusedCell
      (this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].length - 1, 'DETNOTREC_CODIGO');
    }
    this.btnNuevoGrid = true; */
  }
  }

  buscarProveedor() {
    this.opcionbusqueda = 'busquedaproveedor';
    this.types = [
      { label: 'Código', value: 'PRO_CODIGO' },
      { label: 'Nombre', value: 'PRO_NOMBRE' },
      { label: 'Nombre Corto', value: 'PRO_NOMBREC' },
      { label: 'RUC', value: 'PRO_RUCIDE' },
      { label: 'Grupo', value: 'GRU_CODIGO' },
      { label: 'RUC', value: 'PRO_RUCIDE' },
      { label: 'Cuenta', value: 'CON_CODIGO1' }
      ,
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'COM_MAEPROVEEDOR';
    this.consulta = '';
    this.where = '';

    this.proveedorServicio.erbuscarProveedor(this.notRecSeleccionado.PRO_CODIGO).subscribe(eR => {

      if (eR !== null) {
        this.proveedorNombre = eR[0].PRO_NOMBRE;

        document.getElementById('ENCNOTREC_CONTACTO').focus();
      } else {
        this.busqService.busquedaComMaeProveedor7().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });

  }

  buscarBodega() {
    if (this.proveedorNombre === '') {
      this.messageService.add({
        key: 'notrec',
        severity: 'error',
        summary: 'Ingreso Bódega',
        detail: 'Ingrese un proveedor'
      });

    } else {
      this.opcionbusqueda = 'busquedabodega';
      this.types = [
        { label: 'Código', value: 'BOD_CODIGO' },
        { label: 'Nombre', value: 'BOD_NOMBRE' },

      ];
      this.busquedacampos = ['', ''];
      this.tabla = 'inv_maebodega';
      this.proveedorServicio.erbodega(this.auxBodCod).subscribe(eR => {
        console.log(eR);
        if (eR !== null) {
          if (this.notRecSeleccionado.ENCNOTREC_NUMERO === null) {
            this.auxBod = eR[0].BOD_NOMBRE;
            this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador] = [];
            this.nuevoDetalleOrden();
          }
        } else {
          this.busqService.busquedaInvMaeBodega4().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      });


    }
  }

  buscarNota() {
    this.opcionbusqueda = 'busquedanota';
    this.types = [
      { label: 'Número', value: 'ENCNOTREC_NUMERO' },
      { label: 'Comprobante', value: 'ENCNOTREC_REFERENCIA' },//CDPJ ANTES DECIA REFERENCIA AHORA COMPROBANTE
      { label: 'Fec. Emisión', value: 'ENCNOTREC_FECHAEMISION' },
      { label: 'Estado', value: 'ENCNOTREC_ESTADO' },
      { label: 'Nombre Proveedor', value: 'PRO_NOMBRE' },
      { label: 'Cod Proveedor', value: 'PRO_CODIGO' },
      { label: 'Total', value: 'ENCNOTREC_TOTAL' },
      { label: 'Total Neto', value: 'ENCNOTREC_TOTALNETO' },
      { label: 'Comentario', value: 'ENCNOTREC_OBSERVACION' },//CDPJ
      { label: 'Referencia', value: 'ENCNOTREC_REFERENCIA_ADI' },//CDPJ
    ];
    this.consulta = 'com_encnotrec.*,  COM_FNC_NOMBRE_PROVEEDOR(PRO_CODIGO) AS' +
      ' PRO_NOMBRE';
    //this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.busquedacampos = ['', '', '', '', '', '', '', '', '', '', ''];//CDPJ
    this.tabla = 'com_encnotrec';
    this.where = ' ENCNOTREC_NUMERO IS NOT NULL order by ENCNOTREC_FECHAEMISION DESC ';
    this.busqService.busquedaComEncNotRec4().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.arregloCons.map((registro) => {
        if (registro.ENCNOTREC_FECHAEMISION !== null) {
          registro.ENCNOTREC_FECHAEMISION = this.datePipe.transform(registro.ENCNOTREC_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          registro.ENCNOTREC_FECHAEMISION = '';
        }
       
      });
      this.displayDialogBusquedaFast = true;
    });

  }

  setFocus(event) {
    let element: any;

    switch (event.target.id) {
      case 'ENCNOTREC_CONTACTO': {
        element = document.getElementById('observacion');
        break;
      }
      case 'observacion': {
        element = document.getElementById('ENCNOTREC_PORCEIVA');
        break;
      }

      //CDPJ
      case 'ENCNOTREC_TASA_CAMBIO': {
        element = document.getElementById('ENCNOTREC_TASA_CAMBIO');

        break;
      }
      //CDPJ
      case 'ENCNOTREC_PORCEIVA': {
        if (Number(this.notRecSeleccionado.ENCNOTREC_PORCEIVA) === 0 || Number(this.notRecSeleccionado.ENCNOTREC_PORCEIVA) === 12 ||
          Number(this.notRecSeleccionado.ENCNOTREC_PORCEIVA) === 14) {
          element = document.getElementById('ENCNOTREC_REFERENCIA_ADI');
          this.notRecSeleccionado.ENCNOTREC_PORCEIVA = Number(this.notRecSeleccionado.ENCNOTREC_PORCEIVA).toFixed(this.comnotrecServicio.decimalesGenerales);
        } else {
          element = document.getElementById('ENCNOTREC_PORCEIVA');
          this.messageService.add({
            key: 'notrec',
            severity: 'error',
            summary: 'Iva',
            detail: 'El porcentaje de iva es incorrecto'
          });
          this.notRecSeleccionado.ENCNOTREC_PORCEIVA = Number(0).toFixed(this.comnotrecServicio.decimalesGenerales);

        }
        break;
      }
      case 'ENCNOTREC_REFERENCIA_ADI': {
        element = document.getElementById('auxBodCod');
        break;
      }


      default: {
        break;
      }
    }

    if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }
  }

  buscarOrdenCompra() {
    const strFiltro = 'ENCORDCOM_ESTADO = \'T\'';

    this.opcionbusqueda = 'ordenCompra';
    this.types = [
      { label: 'NUMERO', value: 'ENCORDCOM_NUMERO' },
      { label: 'REFERENCIA', value: 'ENCORDCOM_REFERENCIA' },
      { label: 'FEC. EMISIÓN', value: 'ENCORDCOM_FECHAEMISION' },
      { label: 'ESTADO', value: 'ENCORDCOM_ESTADO' },
      { label: 'NOMBRE', value: 'PRO_NOMBRE' },
      { label: 'PROVEEDOR', value: 'PRO_CODIGO' },
      { label: 'CONTACTO', value: 'ENCORDCOM_CONTACTO' },
      { label: 'TOTAL', value: 'ENCORDCOM_TOTAL' },
      { label: 'NETO', value: 'ENCORDCOM_TOTALNETO' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.consulta = 'A.*, COM_FNC_NOMBRE_PROVEEDOR(PRO_CODIGO) AS PRO_NOMBRE';
    this.tabla = 'COM_ENCORDCOM A';
    this.where = 'PRO_CODIGO=' + '\'' + this.notRecSeleccionado.PRO_CODIGO + '\'' + ' and ' + strFiltro;

    this.busqService.busquedaComEncOrdCom4(this.notRecSeleccionado.PRO_CODIGO, strFiltro).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      if (this.arregloCons !== null) {
        this.arregloCons.map((registro) => {
          if (registro.ENCORDCOM_FECHAEMISION !== null) {
            registro.ENCORDCOM_FECHAEMISION = this.datePipe.transform(registro.ENCORDCOM_FECHAEMISION, 'dd/MM/yyyy');
          } else {
            registro.ENCORDCOM_FECHAEMISION = '';
          }
        });
      }
      this.displayDialogBusquedaFast = true;
    });
  }

  cambiarTipo(valor) {
    this.tipodetalle = valor;
    // this.notRecRecepcion.DETNOTREC_TIPODET = valor;
  }

  comprobarValor() {
    if (this.booleanValor === false) {
      this.valorDscPorcentaje = Number(this.valorDscValor * 100) / Number(this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador].ENCNOTREC_TOTALNETO);
      this.valorDscPorcentaje = Number(this.valorDscPorcentaje.toFixed(2));
      if (this.valorDscValor > Number(this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador].ENCNOTREC_TOTALNETO)) {
        this.valorInvalidoV = true;
      } else {
        this.valorInvalidoV = false;
      }
    } else if (this.booleanPorcentaje === false) {
      this.valorDscValor = Number(this.valorDscPorcentaje * this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador].ENCNOTREC_TOTALNETO) / 100;
      this.valorDscValor = Number(this.valorDscValor.toFixed(2));
      if (this.valorDscPorcentaje > 100) {
        this.valorInvalidoP = true;
      } else {
        this.valorInvalidoP = false;
      }
    }
  }

  cambioDescuento() {
    if (this.dscSeleccionado === 'dscValor') {
      this.booleanValor = false;
      this.booleanPorcentaje = true;
    } else if (this.dscSeleccionado === 'dscPorcentaje') {
      this.booleanPorcentaje = false;
      this.booleanValor = true;
    }
  }

  aplicarDescuento() {

    let tipo = '';
    if (this.booleanValor === false) {


      if (this.dscProveedorActual < (this.valorDscValor * 100 / Number(this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador].ENCNOTREC_TOTAL))) {
        tipo = 'valor';
        this.confirmarDescuento(tipo);
      } else {
        tipo = 'valor';
        this.descuentoAplicado(tipo);
      }
    }
    if (this.booleanPorcentaje === false) {


      if (this.dscProveedorActual < this.valorDscPorcentaje) {

        tipo = 'porcentaje';
        this.confirmarDescuento(tipo);
      } else {
        tipo = 'porcentaje';
        this.descuentoAplicado(tipo);
      }
    }
  }

  confirmarDescuento(tipo) {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea aplicar un descuento mayor',
      header: 'El proveedor tiene un descuento menor',
      icon: 'pi pi-exclamation-triangle',
      key: 'notrecConfirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.descuentoAplicado(tipo);
      },
      reject: () => {
      }
    });
  }
  //CDPJ
  async displayTasaC() {
    if (this.notRecSeleccionado.ENCNOTREC_TASA_CAMBIO === '') {
      this.messageService.add({
        key: 'notrec',
        severity: 'error',
        summary: 'Ingreso Tasa Cambio',
        detail: 'Primero debe ingresar una tasa de cambio'
      });
    } else if (this.notRecSeleccionado.ENCNOTREC_TASA_CAMBIO <= 0) {
      this.messageService.add({
        key: 'notrec',
        severity: 'error',
        summary: 'Ingreso Tasa Cambio',
        detail: 'Primero debe ingresar una tasa de cambio mayor a cero'
      });

    } else if (this.notRecSeleccionado.ENCNOTREC_TOTAL === '' || this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].length === 0) {
      this.messageService.add({
        key: 'notrec',
        severity: 'error',
        summary: 'Ingreso Notas de Recepción',
        detail: 'No se puede ingresar una tasa de cambio sin detalle, por favor revise los valores e intente nuevamente'
      });

    }
    else if (await this.verificacionDatosCompletos() === 0) {//CDPJ
      if (this.notRecSeleccionado.ENCNOTREC_ESTADO === 'P') {

        this.displayDialogTasa = true;
      } else {
        this.messageService.add({
          key: 'notrec',
          severity: 'error',
          summary: '',
          detail: 'No se puede ingresar la tasa de cambio'
        });
      }
      if (this.displayAcciones === true) {
        this.closeSidebar();
      }
    } else {
      this.comnotrecServicio.eliminarNotaRecepcion(this.notRecSeleccionado).subscribe(() => {

      });
      this.messageService.add({
        key: 'notrec',
        severity: 'error',
        summary: 'Ingreso Notas de Recepción',
        detail: 'Los datos del detalle de la nota de recepción no estan completos, por favor revise los valores e intente nuevamente.'
      });
    }

  }
  //CDPJ

  //CDPJ
  cambioTasa() {

    if (this.tasaSeleccionado === 'valorTasaCambio') {

      this.booleanTasa = false;
      this.booleanOriginal = true;

    } else if (this.tasaSeleccionado === 'valorOriginal') {

      this.booleanOriginal = false;
      this.booleanTasa = true;

    }
  }
  //CDPJ

  //CDPJ
  aplicarTasaCambio() {
    if (this.displayAcciones === true) {
      this.closeSidebar();
    }

    this.valorTasaCambio = Number((<HTMLInputElement>document.getElementById("ENCNOTREC_TASA_CAMBIO")).value);
    let tipoTasa = '';

    if (this.booleanTasa === false) {

      tipoTasa = 'T';
      this.tasaAplicada(tipoTasa);
    }
    if (this.booleanOriginal === false) {
      tipoTasa = 'O';
      this.tasaAplicada(tipoTasa);
    }
  }
  async comprobarClave() {
    try {
      const rs = await this.comnotrecServicio.obtenerClaveProm();
      let claveReal;
      claveReal = rs[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        this.confirmarAnulacionNotRec();
        this.claveAuth = '';
        this.claveDialog = false;
      } else {
        this.messageService.add({
          key: 'notrec',
          severity: 'error',
          summary: '',
          detail: 'La clave de autorización para anular la'
            + ' Nota de Recepción' +
            ' Es incorrecta'
        });
        this.claveDialog = false;
        this.claveAuth = '';
      }

    } catch (err) {

    }
  }
  //CDPJ

  //CDPJ
  tasaAplicada(tipoTasa) {
    this.displayDialogTasa = false;

    this.btnGuardar = false;
    this.btnNuevo = true;
    if (tipoTasa === 'T') {
      this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].map(aux => {
        if (aux.DETNOTREC_TIPODET === '*') {
          aux.DETNOTREC_CANTIDAD = '';
          aux.DETNOTREC_FACTOR = '';
          aux.DETNOTREC_COSTO = '';
          aux.DETNOTREC_DESCUENTO = '';
          aux.DETNOTREC_DESCUENTO2 = '';
          aux.DETNOTREC_DESCUENTO3 = '';
          aux.DETNOTREC_TOTAL = '';
          aux.DETNOTREC_PORFACTOR = '';
          aux.DETNOTREC_COSTOFOB = '';
          aux.DETNOTREC_ARTPESO = '';
        } else if (aux.DETNOTREC_TIPODET === 'A' || aux.DETNOTREC_TIPODET === 'S') {
          aux.DETNOTREC_COSTO = aux.DETNOTREC_COSTO * this.valorTasaCambio;
          this.comnotrecServicio.calcularTotales(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].indexOf(aux));
          this.validarSeriales(aux);
          this.comnotrecServicio.consultarArticuloSel(aux.DETNOTREC_CODIGO).subscribe(res1 => {
            res1.map(dat => {

              aux.ART_CADUCA = dat.CADUCA;
            });

          });

        }
        this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador], this.defaultRecepcion.objeto);



      });
      this.booleanAOriginal = false;
      this.booleanATasa = true;
    } else if (tipoTasa === 'O') {
      this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].map(aux => {
        if (aux.DETNOTREC_TIPODET === '*') {
          aux.DETNOTREC_CANTIDAD = '';
          aux.DETNOTREC_FACTOR = '';
          aux.DETNOTREC_COSTO = '';
          aux.DETNOTREC_DESCUENTO = '';
          aux.DETNOTREC_DESCUENTO2 = '';
          aux.DETNOTREC_DESCUENTO3 = '';
          aux.DETNOTREC_TOTAL = '';
          aux.DETNOTREC_PORFACTOR = '';
          aux.DETNOTREC_COSTOFOB = '';
          aux.DETNOTREC_ARTPESO = '';
        } else if (aux.DETNOTREC_TIPODET === 'A' || aux.DETNOTREC_TIPODET === 'S') {
          aux.DETNOTREC_COSTO = aux.DETNOTREC_COSTO / this.valorTasaCambio;
          this.comnotrecServicio.calcularTotales(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador].indexOf(aux));
          this.validarSeriales(aux);
          this.comnotrecServicio.consultarArticuloSel(aux.DETNOTREC_CODIGO).subscribe(res1 => {
            res1.map(dat => {

              aux.ART_CADUCA = dat.CADUCA;
            });

          });

        }
        this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.indicador], this.defaultRecepcion.objeto);



      });
      this.booleanATasa = false
      this.booleanAOriginal = true;

    }
    //

    document.querySelectorAll('[name=opcionTasa').forEach((x) => (x as HTMLInputElement).checked = false);


  }
  //CDPJ
  llenartablaImportaciones() {
    if (this.notRecSeleccionado.ENCNOTREC_ESTADO === 'F') {
      this.btnNuevoGrid = true;
      this.btnBorrarGrid = true;
      this.btnCancelarGrid = true;
      this.auxEstado = 'FACTURADA';
      document.getElementById('divImportaciones').style.pointerEvents = 'none';
      // document.getElementById('divImportaciones').style.opacity = '0.7';

      // this.columnDefsImportaciones.map(aux => {CDPJ COMENTÉ
      //   aux.editable = false;
      // });
      // this.aggrid.refreshColumnDefs();
      this.bolean = true;//CDPJ

    } else if (this.notRecSeleccionado.ENCNOTREC_ESTADO === 'P') {
      this.btnNuevoGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;

      this.auxEstado = 'PENDIENTE';
      document.getElementById('divImportaciones').style.pointerEvents = 'all';
      // document.getElementById('divImportaciones').style.opacity = '1';
      this.bolean = false;//CDPJ
      // this.columnDefsImportaciones.map(aux => { CDPJ COMENTÉ
      //   aux.editable = true;
      // });
      // this.aggrid.refreshColumnDefs();

    } else if (this.notRecSeleccionado.ENCNOTREC_ESTADO === 'A') {
      this.btnNuevoGrid = true;
      this.btnBorrarGrid = true;
      this.btnCancelarGrid = true;
      this.auxEstado = 'ANULADA';
      document.getElementById('divImportaciones').style.pointerEvents = 'none';
      // document.getElementById('divImportaciones').style.opacity = '0.7';
      this.bolean = true;//CDPJ
      // this.columnDefsImportaciones.map(aux => { CDPJ COMENTÉ
      //   aux.editable = false;
      // });
      // this.aggrid.refreshColumnDefs();

    }

    if (this.notRecSeleccionado.ENCNOTREC_NUMERO !== undefined) {
      if (this.notRecSeleccionado.ENCNOTREC_NUMERO !== null) {
        this.comnotrecServicio.getImportaciones(this.notRecSeleccionado.ENCNOTREC_NUMERO).subscribe(res => {
          if (res.length > 0) {
            this.comnotrecServicio.comnotrecImportacionarray[this.indicador] = res;
            this.comnotrecServicio.comnotrecImportacionarray[this.indicador].map(aux => {
              aux.NOTRECIMP_BASE = Number(aux.NOTRECIMP_BASE).toFixed(this.comnotrecServicio.decimalesGenerales);
              aux.NOTRECIMP_IMPORTE = Number(aux.NOTRECIMP_IMPORTE).toFixed(this.comnotrecServicio.decimalesGenerales);
              aux.NOTRECIMP_PORCENTAJE = Number(aux.NOTRECIMP_PORCENTAJE).toFixed(this.comnotrecServicio.decimalesGenerales);
              this.comnotrecServicio.getDescripcionImportaciones(aux.REF_CODIGO).subscribe(res1 => {

                aux.REF_NOMBRE = res1[0].REF_NOMBRE;
                this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecImportacionarray[this.indicador], this.defaultImportaciones.objeto);

                this.btnNuevoGrid = true;
                this.btnBorrarGrid = true;
                this.btnCancelarGrid = true;
                if (aux.NOTRECIMP_TIPO === '1') {
                  this.chkvalor = true;
                  this.chkvalorDis = true;
                  this.chkvolumen = false;
                  this.chkpeso = false;
                  this.chkfactor = false;

                  this.boolDistribuir = true;
                  this.boolReverzar = false;
                  this.bolean = true;//CDPJ

                } else if (aux.NOTRECIMP_TIPO === '2') {

                  this.chkvolumen = true;
                  this.chkvolumenDis = true;
                  this.chkvalor = false;
                  this.chkpeso = false;
                  this.chkfactor = false;
                  this.boolDistribuir = true;
                  this.boolReverzar = false;
                  this.bolean = true;//CDPJ
                } else if (aux.NOTRECIMP_TIPO === '3') {
                  this.chkpeso = true;
                  this.chkpesoDis = true;
                  this.chkvolumen = false;
                  this.chkvalor = false;
                  this.chkfactor = false;
                  this.boolDistribuir = true;
                  this.boolReverzar = false;
                  this.bolean = true;//CDPJ

                } else if (aux.NOTRECIMP_TIPO === '4') {
                  this.chkfactor = true;
                  this.chkfactorDis = true;
                  this.chkpeso = false;
                  this.chkvolumen = false;
                  this.chkvalor = false;
                  this.boolDistribuir = true;
                  this.boolReverzar = false;
                  this.bolean = true;//CDPJ

                } else {
                  this.boolDistribuir = false;
                  this.boolReverzar = true;
                  this.chkfactor = false;
                  this.chkpeso = false;
                  this.chkvolumen = false;
                  this.chkvalor = false;
                  this.btnNuevoGrid = false;
                  this.btnBorrarGrid = false;
                  this.btnCancelarGrid = false;
                  document.getElementById('divImportacioneschk').style.pointerEvents = 'all';
                  // document.getElementById('divImportacioneschk').style.opacity = '1';
                  this.bolean = false;//CDPJ
                }

                this.controlchecksImportacion();

                this.comnotrecServicio.calcularImportacion(this.comnotrecServicio.comnotrecImportacionarray[this.indicador].indexOf(aux));
              });

            });


          } else {
            this.comnotrecServicio.comnotrecImportacionarray[this.indicador] = [];
            this.boolDistribuir = false;
            this.boolReverzar = true;
            this.chkfactor = false;
            this.chkpeso = false;
            this.chkvolumen = false;
            this.chkvalor = false;
            document.getElementById('divImportacioneschk').style.pointerEvents = 'none';
            // document.getElementById('divImportacioneschk').style.opacity = '0.6';
          }


        });
      }


    }
  }

  llenartablaVarios() {
    if (this.notRecSeleccionado.ENCNOTREC_ESTADO === 'F') {
      this.btnNuevoGrid = true;
      this.btnBorrarGrid = true;
      this.btnCancelarGrid = true;


      this.columnDefsVarios.map(aux => {
        aux.editable = false;
      });
      this.aggrid.refreshColumnDefs();


    } else if (this.notRecSeleccionado.ENCNOTREC_ESTADO === 'P') {
      this.btnNuevoGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      this.auxEstado = 'PENDIENTE';

      this.columnDefsVarios.map(aux => {
        aux.editable = true;
      });
      this.aggrid.refreshColumnDefs();

    } else if (this.notRecSeleccionado.ENCNOTREC_ESTADO === 'A') {
      this.btnNuevoGrid = true;
      this.btnBorrarGrid = true;
      this.btnCancelarGrid = true;


      this.columnDefsVarios.map(aux => {
        aux.editable = false;
      });
      this.aggrid.refreshColumnDefs();

    }
    if (this.notRecSeleccionado.ENCNOTREC_NUMERO !== undefined) {


      if (this.notRecSeleccionado.ENCNOTREC_NUMERO !== null) {

        this.comnotrecServicio.consultarTrnnotVar(this.notRecSeleccionado).subscribe(res => {
          if (res !== null) {

            this.comnotrecServicio.comnotrecVararray[this.indicador] = res;
            this.comnotrecServicio.comnotrecVararray[this.indicador].map(aux => {
              aux.NOTRECVAR_IMPORTE *= -1;

              aux.NOTRECVAR_IMPORTE = (Number(aux.NOTRECVAR_IMPORTE).toFixed(this.comnotrecServicio.decimalesGenerales));
              aux.NOTRECVAR_LINEA = this.comnotrecServicio.comnotrecVararray[this.indicador].indexOf(aux) + 1;
              this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecVararray[this.indicador], this.defaultVarios.objeto);
            });
            this.comnotrecServicio.calcularVarios();
          } else {
            this.comnotrecServicio.comnotrecVararray[this.indicador] = [];
            this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecVararray[this.indicador], this.defaultVarios.objeto);

          }
        });

      }
    }
  }



  descuentoAplicado(tipo) {
    this.displayDialogDescuento = false;
    this.btnGuardar = false;
    this.btnNuevo = true;
    if (tipo === 'valor') {
      this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador].ENCNOTREC_TIPODES = 'V';
      this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador].ENCNOTREC_VALORDES = this.valorDscValor;
      this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador].ENCNOTREC_PORCEDES = (this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador].ENCNOTREC_VALORDES
        * 100) / this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador].ENCNOTREC_TOTALNETO;
      this.valorDscPorcentaje = this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador].ENCNOTREC_PORCEDES;
      this.comnotrecServicio.dblPorcDescGlbOrdarray[this.indicador] = this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador].ENCNOTREC_PORCEDES;
      this.comnotrecServicio.dblValorDescGlbOrdarray[this.indicador] = this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador].ENCNOTREC_VALORDES;
      this.comnotrecServicio.descuentoGlobal();
    } else if (tipo === 'porcentaje') {
      this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador].ENCNOTREC_TIPODES = 'P';
      this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador].ENCNOTREC_PORCEDES = this.valorDscPorcentaje;
      this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador].ENCNOTREC_VALORDES = Number(
        this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador].ENCNOTREC_TOTALNETO * this.valorDscPorcentaje / 100);
      this.valorDscValor = this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador].ENCNOTREC_VALORDES;
      this.comnotrecServicio.dblPorcDescGlbOrdarray[this.indicador] = this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador].ENCNOTREC_PORCEDES;
      this.comnotrecServicio.dblValorDescGlbOrdarray[this.indicador] = this.comnotrecServicio.comnotrecRecepcionSelectarray[this.indicador].ENCNOTREC_VALORDES;
      this.comnotrecServicio.descuentoGlobal();
    }
  }


  async changeTabs() {
    //CDPJ
    if (this.index === 0) {
      if (this.notRecSeleccionado.ENCNOTREC_NUMERO !== '' && this.notRecSeleccionado.ENCNOTREC_NUMERO !== undefined
        && this.notRecSeleccionado.ENCNOTREC_NUMERO !== null) {
        //console.log('change');
        this.seleccionarDetalle();
      }
    }
    //CDPJ
    if (this.index === 1) {
      this.llenartablaImportaciones();
    }
    if (this.index === 2) {
      this.llenartablaVarios();
    }
    if (this.index === 3) {
      if (this.bolestcta === false) {
        this.cxctmptrnresumen = [];
        this.bolestcta = true;
        this.cxctmptrnresumen = await this.proveedorServicio.llenarEstadoCuenta(this.notRecSeleccionado.PRO_CODIGO);
      }
    }
    if (this.index === 4) {
      if (this.bolsaldo === false) {
        this.cxctrnresumen = [];
        this.bolsaldo = true;
        this.cxctrnresumen = await this.proveedorServicio.llenarTablaSaldos(this.notRecSeleccionado.PRO_CODIGO);
      }
    }

    if (this.index === 5) {
      this.dataComprasProveedor = {
        labels: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
        datasets: [{
          label: 'Ventas',
          backgroundColor: '#42A5F5',
          borderColor: '#1E88E5',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
        ]
      };

      this.dataVentasVsCompras = {
        labels: ['', ''],
        datasets: [
          {
            label: 'Ventas',
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [0]
          },
          {
            label: 'Compras',
            backgroundColor: '#9CCC65',
            borderColor: '#7CB342',
            data: [0]
          }
        ]
      };

      this.detTopProductos = [];
      this.detalleExistencia = [];
      this.detMovimientosProv = [];
      this.detExistenciaBodegas = [];
      this.txtArtCodigo = '';
      this.txtArtDescripcion = '';
      this.txtExistenciaMax = '';
      this.txtExistenciaMin = '';
      this.txtArtUnidad = '';
      this.txtArtRotacion = '';
      this.txtInventarioIni = '';
      this.txtComprasPeriodo = '';
      this.txtTotalDisponible = '';
      this.txtVenUltimoPer = '';
      this.txtVenPromPerAnt = '';
      this.txtIndiceProvUltPer = '';
      this.txtIndiceProvPeriodos = '';
      this.txtComprasPlanificadas = '';
      this.txtTotalDisponibleCom = '';
      this.txtIndiceProvUltPer2 = '';
      this.txtIndiceProvPeriodos2 = '';

      const existencias: ComExistencias = {};
      existencias.COMPRAR = '';
      existencias.EXIST_MIN = '';
      existencias.EXISTENCIA = '';
      existencias.ORDENES_COMPRA = '';
      existencias.PEDIDOS_CLIENTES = '';
      existencias.SALDO = '';
      existencias.TOTAL_COMPRA = '';
      existencias.ULTCOSTO_COM = '';
      this.detalleExistencia.push(existencias);

    }

  }

  cambiarBoton(valor) {
    if (this.index === 0) {
      this.btnNuevoGrid = false;
    }
    if (this.index === 1) {
      document.getElementById('divImportacioneschk').style.pointerEvents = 'all';
      // document.getElementById('divImportacioneschk').style.opacity = '1';
      this.btnNuevoGrid = false;//CDPJ
    }
    if (this.index === 2) {
      const NCDGASINR = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NCDGASINR').CFG_VALOR1;
      if(NCDGASINR === 0){
        this.comnotrecServicio.comnotrecVararray[this.indicador][Number(this.notRecVarios.NOTRECVAR_LINEA) - 1].NOTRECVAR_IMPORTE =
        this.comnotrecServicio.sumaArticulos() - Number(this.comnotrecServicio.calcularVarios());
      }else{
        this.comnotrecServicio.comnotrecVararray[this.indicador][Number(this.notRecVarios.NOTRECVAR_LINEA) - 1].NOTRECVAR_IMPORTE =
        this.comnotrecServicio.sumaArticulosSNDG() - Number(this.comnotrecServicio.calcularVarios());
      }
      this.comnotrecServicio.comnotrecVararray[this.indicador][Number(this.notRecVarios.NOTRECVAR_LINEA) - 1].NOTRECVAR_IMPORTE =
        Number(this.comnotrecServicio.comnotrecVararray[this.indicador][Number(this.notRecVarios.NOTRECVAR_LINEA) - 1].NOTRECVAR_IMPORTE)
          .toFixed(this.comnotrecServicio.decimalesGenerales);
      this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecVararray[this.indicador], this.defaultVarios.objeto);
      this.comnotrecServicio.calcularVarios();
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultRecepcion.objeto].setFocusedCell(Number(this.notRecVarios.NOTRECVAR_LINEA) - 1, 'NOTRECVAR_IMPORTE');
    }
  }

  seleccionarChecks() {
    if (this.notRecSeleccionado.ENCNOTREC_MERCONS === 'S') {
      this.chkmercons = true;
    } else {
      this.chkmercons = false;
    }

  }

  guardarChecks() {
    if (this.chkmercons) {
      this.notRecSeleccionado.ENCNOTREC_MERCONS = 'S';
    } else {
      this.notRecSeleccionado.ENCNOTREC_MERCONS = 'N';
    }
  }

  controlchecksImportacion() {
    if (this.chkvalor) {
      this.chkvolumenDis = true;
      this.chkpesoDis = true;
      this.chkfactorDis = true;
      this.numberchk = 1;

    } else if (this.chkvolumen) {
      this.chkvalorDis = true;
      this.chkpesoDis = true;
      this.chkfactorDis = true;
      this.numberchk = 2;

    } else if (this.chkpeso) {
      this.chkvalorDis = true;
      this.chkvolumenDis = true;
      this.chkfactorDis = true;
      this.numberchk = 3;


    } else if (this.chkfactor) {
      this.chkpesoDis = true;
      this.chkvolumenDis = true;
      this.chkvalorDis = true;
      this.numberchk = 4;


    } else {
      this.chkpesoDis = false;
      this.chkvolumenDis = false;
      this.chkvalorDis = false;
      this.chkfactorDis = false;
      this.numberchk = 0;


    }

  }

  abrirLotes(params) {
    this.notRecRecepcion = params;
    this.auxiliarlot++;
    this.displayDialogLotes = this.notRecSeleccionado.ENCNOTREC_NUMERO !== null && this.notRecRecepcion.ART_CADUCA === 'S';
  }

  acciones(valor) {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 0; i < elemento.length; i++) {
        elemento[i].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 0; i < elemento.length; i++) {
        elemento[i].className = 'layout-config';
      }
    }


  }

  closeSidebar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 0; i < elemento.length; i++) {
      elemento[i].className = 'layout-config';
    }
  }

  agregarOrdenCompra() {
    this.buscarOrdenCompra();
  }

  verificaSiTieneLiberacionReq(strOrdCompra) {
    let intNumReg = 0;

    let strSentencia = ' select count(*) as COUN from pre_detpresupuesto a, com_detordcom b';
    strSentencia = strSentencia + ' where not encreq_numero_lib is null ';
    strSentencia = strSentencia + ' and a.encreq_numero_lib=b.encreq_numero';
    strSentencia = strSentencia + ' and a.com_codigo=b.com_codigo and encordcom_numero=' + '\'' + strOrdCompra + '\'';

    this.comnotrecServicio.ejecutarConsulta2(strOrdCompra).subscribe(eC => {
      for (const rs of eC) {
        intNumReg = rs.COUN;

      }

      if (intNumReg > 0) {
        this.TieneLiberacionReq(strOrdCompra);
      } else {
        // Si tiene liberación por las requisiciones

        strSentencia = ' select count(*) from com_encrequisicion a,com_detordcom b ';
        strSentencia = strSentencia + ' where  not a.encreq_numero_ref is null and encreq_estado<>\'A\' ';
        strSentencia = strSentencia + ' and a.encreq_numero_ref=b.encreq_numero';
        strSentencia = strSentencia + ' and a.com_codigo=b.com_codigo and b.encordcom_numero=' + '\'' + strOrdCompra + '\'';

        this.comnotrecServicio.ejecutarConsulta3(strOrdCompra).subscribe(eC2 => {
          for (const rs of eC2) {
            intNumReg = rs.COUN;
          }
          if (intNumReg > 0) {
            this.TieneLiberacionReq(strOrdCompra);
          }
        });
      }
    });


  }

  TieneLiberacionReq(strEncOrdCom) {
    this.messageService.add({
      key: 'notRec',
      severity: 'warn',
      summary: 'Información',
      detail: 'La orden de compra esta enlazada con una requisición que tiene una liberación de presupuesto, por favor comuniquese con la area de contabilidad.'
    });
    this.autorizacionCodigo = 'AUTUTIOC';
    this.claveAuth = '';
    this.strEncOrdCom = strEncOrdCom;
    this.displayDialogAutorizacion = true;
  }

  // retorna  true si puede navegar
  // pendiente la validación
  validarSeriales(aux) {

    let articulo;
    let cantidad;
    let artserialflag = '';
    let notarecepcion = '';
    let intLinea = 0;
    let strSentencia = '';
    if (this.notRecSeleccionado.ENCNOTREC_ESTADO === 'A') {
      this.errores = 0;
    } else if (this.notRecSeleccionado.ENCNOTREC_NUMERO === '') {
      this.errores = 0;
    } else {
      articulo = aux.DETNOTREC_CODIGO;
      cantidad = aux.DETNOTREC_CANTIDAD;
      intLinea = aux.DETNOTREC_LINEA;


      this.comnotrecServicio.encontrarArtSerialFlag(articulo).subscribe(data => {
        if (data !== null) {
          if (data[0] !== undefined) {
            artserialflag = data[0].ART_SERIALFLAG;
          }
        }

        this.comnotrecServicio.encontrarEncNotNumeroNot(this.notRecSeleccionado.ENCNOTREC_NUMERO, articulo).subscribe(data2 => {
          if (data2 !== null) {
            if (data2[0] !== undefined) {
              notarecepcion = data2[0].ENCNOTREC_NUMERO;
            }
          }
          if (notarecepcion === null) {
            notarecepcion = '';
          }
          if (artserialflag === 'S' && notarecepcion !== '') {
            strSentencia = 'select count(*) AS COUN from INV_TRNKARDEXOTR ';
            strSentencia = strSentencia + ' where art_codigo = \''
              + articulo + '\' and TRNKARDEXOTR_NUMERO = \''
              + this.notRecSeleccionado.ENCNOTREC_NUMERO + '\'';
            strSentencia = strSentencia + ' and trnkardexotr_linea = ' + intLinea;
            this.comnotrecServicio.ejecutarConsulta4(articulo, this.notRecSeleccionado.ENCNOTREC_NUMERO, intLinea).subscribe(data3 => {

              for (const rs of data3) {

                if (rs.COUN !== Number(cantidad)) {
                  this.errores++;
                }

              }
            });
          }
        });
      });


      /*
      setTimeout(() => {
        if (this.errores > 0) {
        
        
        }
      }, 500);
      */
    }


  }


  verificarAsiento() {
    if (this.auxEstado === 'FACTURADA' || this.auxEstado === 'ANULADA') {
      return true;
    }

    const VAROBLNR = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'VAROBLNR').CFG_VALOR1;
    if (VAROBLNR === 1) {
      const NCDGASINR = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NCDGASINR').CFG_VALOR1;    
      let getNeto = '';
      if (this.notRecSeleccionado.ENCNOTREC_TOTALNETO === '' ||
        this.notRecSeleccionado.ENCNOTREC_TOTALNETO === null ||
        this.notRecSeleccionado.ENCNOTREC_TOTALNETO === undefined
        || this.notRecSeleccionado.ENCNOTREC_NUMERO === ''
        || this.notRecSeleccionado.ENCNOTREC_NUMERO === null 
        ||  this.notRecSeleccionado.ENCNOTREC_NUMERO === undefined) {
        getNeto = '0';
      } else {
        //getNeto = this.comnotrecServicio.sumaArticulos().toString();
        if(NCDGASINR === 0){
          getNeto= this.comnotrecServicio.sumaArticulos().toString();
        }else{
          getNeto= this.comnotrecServicio.sumaArticulosSNDG().toString();
        }
      }
      if (this.getSumatoriaVarios() !== Number(getNeto)) {
        this.messageService.add({
          key: 'notrec',
          severity: 'warn',
          summary: 'Información',
          detail: 'Es necesario verificar el asiento. \nEl Valor Total de la '
            + 'tabla Varios no coincide con el Valor Neto de la Nota de Recepción'
        });
        return false;
      }
    }

    // if(!ValidarSeriales(lblCodigo.getText()))
    // return false;

    return true;
  }


  getSumatoriaVarios(): number {
    let sum = 0;
    this.comnotrecServicio.comnotrecVararray[this.indicador].map(aux => {
      sum = sum + Number(aux.NOTRECVAR_IMPORTE);
    });

    return sum;
  }

  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }

  mostrarEstadisticas() {
    this.comprasRealizadas();
    this.topProductos();
  }

  async comprasRealizadas() {
    const fechaInicial = new Date();
    fechaInicial.setMonth(fechaInicial.getMonth() - 12);
    fechaInicial.setDate(1);

    const fechaAct = new Date();
    const rangoFechas: any[] = [];

    for (let i = 1; fechaInicial <= fechaAct; i++) {
      rangoFechas.push(this.datePipe.transform(fechaInicial, 'dd/MM/yyyy'));
      fechaInicial.setMonth(fechaInicial.getMonth() + 1);
    }


    try {

      const rs = await this.venEstadisticasService.comprasAcumuladas(this.notRecSeleccionado.PRO_CODIGO);


      if (rs !== null) {

        const index1 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[0]);
        const valor1 = index1 >= 0 ? rs[index1].VENTA_TOTAL : 0;
        const index2 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[1]);
        const valor2 = index2 >= 0 ? rs[index2].VENTA_TOTAL : 0;
        const index3 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[2]);
        const valor3 = index3 >= 0 ? rs[index3].VENTA_TOTAL : 0;
        const index4 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[3]);
        const valor4 = index4 >= 0 ? rs[index4].VENTA_TOTAL : 0;
        const index5 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[4]);
        const valor5 = index5 >= 0 ? rs[index5].VENTA_TOTAL : 0;
        const index6 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[5]);
        const valor6 = index6 >= 0 ? rs[index6].VENTA_TOTAL : 0;
        const index7 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[6]);
        const valor7 = index7 >= 0 ? rs[index7].VENTA_TOTAL : 0;
        const index8 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[7]);
        const valor8 = index8 >= 0 ? rs[index8].VENTA_TOTAL : 0;
        const index9 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[8]);
        const valor9 = index9 >= 0 ? rs[index9].VENTA_TOTAL : 0;
        const index10 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[9]);
        const valor10 = index10 >= 0 ? rs[index10].VENTA_TOTAL : 0;
        const index11 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[10]);
        const valor11 = index11 >= 0 ? rs[index11].VENTA_TOTAL : 0;
        const index12 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[11]);
        const valor12 = index12 >= 0 ? rs[index12].VENTA_TOTAL : 0;
        const index13 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[12]);
        const valor13 = index13 >= 0 ? rs[index13].VENTA_TOTAL : 0;

        this.dataComprasProveedor = {
          labels: [
            String(rangoFechas[0]).substring(8, 10) + '/' + String(rangoFechas[0]).substring(3, 5),
            String(rangoFechas[1]).substring(8, 10) + '/' + String(rangoFechas[1]).substring(3, 5),
            String(rangoFechas[2]).substring(8, 10) + '/' + String(rangoFechas[2]).substring(3, 5),
            String(rangoFechas[3]).substring(8, 10) + '/' + String(rangoFechas[3]).substring(3, 5),
            String(rangoFechas[4]).substring(8, 10) + '/' + String(rangoFechas[4]).substring(3, 5),
            String(rangoFechas[5]).substring(8, 10) + '/' + String(rangoFechas[5]).substring(3, 5),
            String(rangoFechas[6]).substring(8, 10) + '/' + String(rangoFechas[6]).substring(3, 5),
            String(rangoFechas[7]).substring(8, 10) + '/' + String(rangoFechas[7]).substring(3, 5),
            String(rangoFechas[8]).substring(8, 10) + '/' + String(rangoFechas[8]).substring(3, 5),
            String(rangoFechas[9]).substring(8, 10) + '/' + String(rangoFechas[9]).substring(3, 5),
            String(rangoFechas[10]).substring(8, 10) + '/' + String(rangoFechas[10]).substring(3, 5),
            String(rangoFechas[11]).substring(8, 10) + '/' + String(rangoFechas[11]).substring(3, 5),
            String(rangoFechas[12]).substring(8, 10) + '/' + String(rangoFechas[12]).substring(3, 5),
          ],
          datasets: [{
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [valor1, valor2, valor3, valor4, valor5, valor6, valor7,
              valor8, valor9, valor10, valor11, valor12, valor13]
          }
          ]
        };

      } else {
        this.dataComprasProveedor = {
          labels: [
            String(rangoFechas[0]).substring(8, 10) + '/' + String(rangoFechas[0]).substring(3, 5),
            String(rangoFechas[1]).substring(8, 10) + '/' + String(rangoFechas[1]).substring(3, 5),
            String(rangoFechas[2]).substring(8, 10) + '/' + String(rangoFechas[2]).substring(3, 5),
            String(rangoFechas[3]).substring(8, 10) + '/' + String(rangoFechas[3]).substring(3, 5),
            String(rangoFechas[4]).substring(8, 10) + '/' + String(rangoFechas[4]).substring(3, 5),
            String(rangoFechas[5]).substring(8, 10) + '/' + String(rangoFechas[5]).substring(3, 5),
            String(rangoFechas[6]).substring(8, 10) + '/' + String(rangoFechas[6]).substring(3, 5),
            String(rangoFechas[7]).substring(8, 10) + '/' + String(rangoFechas[7]).substring(3, 5),
            String(rangoFechas[8]).substring(8, 10) + '/' + String(rangoFechas[8]).substring(3, 5),
            String(rangoFechas[9]).substring(8, 10) + '/' + String(rangoFechas[9]).substring(3, 5),
            String(rangoFechas[10]).substring(8, 10) + '/' + String(rangoFechas[10]).substring(3, 5),
            String(rangoFechas[11]).substring(8, 10) + '/' + String(rangoFechas[11]).substring(3, 5),
            String(rangoFechas[12]).substring(8, 10) + '/' + String(rangoFechas[12]).substring(3, 5),
          ],
          datasets: [{
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
          ]
        };
      }
    } catch (err) {

    }
  }

  async topProductos() {
    this.detTopProductos = [];
    let totalCantidad = 0;
    let totalPrecio = 0;
    let total = 0;
    const fechaActual = new Date();
    const strFecha = this.datePipe.transform(fechaActual, 'dd/MM/yyyy');
    const time = new Date();
    const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
    const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
    const strHora = hora + ':' + minutos;

    const detalle: ComTopProductos[] = [];


    try {
      const rs = await this.venEstadisticasService.topProductos(this.notRecSeleccionado.PRO_CODIGO, 'cantidad');
      if (rs !== null) {
        for (const item of rs) {
          const det: ComTopProductos = {};
          det.DETFACPRO_CODIGO = item.DETFACPRO_CODIGO;
          det.DETFACPRO_DESCRIPCION = item.DETFACPRO_DESCRIPCION;
          det.CANTIDAD_TOTAL = Number(item.CANTIDAD_TOTAL).toFixed(2);
          totalCantidad = totalCantidad + Number(item.CANTIDAD_TOTAL);
          det.DETFACPRO_COSTO = Number(item.DETFACPRO_COSTO).toFixed(2);
          totalPrecio = totalPrecio + Number(item.DETFACPRO_COSTO);
          det.TOTAL = Number(item.TOTAL).toFixed(2);
          total = total + Number(item.TOTAL);
          det.STOCK = Number(item.STOCK).toFixed(2);
          detalle.push(det);
        }
        const totales: ComTopProductos = {};
        totales.DETFACPRO_CODIGO = '';
        totales.DETFACPRO_DESCRIPCION = 'TOTAL';
        totales.CANTIDAD_TOTAL = Number(totalCantidad).toFixed(2);
        totales.DETFACPRO_COSTO = Number(totalPrecio).toFixed(2);
        totales.TOTAL = Number(total).toFixed(2);
        totales.STOCK = '';
        detalle.push(totales);

        this.detTopProductos = detalle;
      }

    } catch (err) {

    }
  }


  async orderByTopProd(tipo) {
    this.detTopProductos = [];
    let totalCantidad = 0;
    let totalPrecio = 0;
    let total = 0;

    const fechaActual = new Date();
    const strFecha = this.datePipe.transform(fechaActual, 'dd/MM/yyyy');
    const time = new Date();
    const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
    const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
    const strHora = hora + ':' + minutos;

    const detalle: ComTopProductos[] = [];
    let rs;

    try {
      if (tipo === 'total') {
        rs = await this.venEstadisticasService.topProductos(this.notRecSeleccionado.PRO_CODIGO, 'total');
      }

      if (tipo === 'cantidad') {
        rs = await this.venEstadisticasService.topProductos(this.notRecSeleccionado.PRO_CODIGO, 'cantidad');
      }
      if (rs !== null) {
        for (const item of rs) {
          const det: ComTopProductos = {};
          det.DETFACPRO_CODIGO = item.DETFACPRO_CODIGO;
          det.DETFACPRO_DESCRIPCION = item.DETFACPRO_DESCRIPCION;
          det.CANTIDAD_TOTAL = Number(item.CANTIDAD_TOTAL).toFixed(2);
          totalCantidad = totalCantidad + Number(item.CANTIDAD_TOTAL);
          det.DETFACPRO_COSTO = Number(item.DETFACPRO_COSTO).toFixed(2);
          totalPrecio = totalPrecio + Number(item.DETFACPRO_COSTO);
          det.TOTAL = Number(item.TOTAL).toFixed(2);
          total = total + Number(item.TOTAL);
          det.STOCK = Number(item.STOCK).toFixed(2);
          detalle.push(det);
        }
        const totales: ComTopProductos = {};
        totales.DETFACPRO_CODIGO = '';
        totales.DETFACPRO_DESCRIPCION = 'TOTAL';
        totales.CANTIDAD_TOTAL = Number(totalCantidad).toFixed(2);
        totales.DETFACPRO_COSTO = Number(totalPrecio).toFixed(2);
        totales.TOTAL = Number(total).toFixed(2);
        totales.STOCK = '';
        detalle.push(totales);

        this.detTopProductos = detalle;
      }

    } catch (err) {

    }
  }

  buscarArticulo() {
    this.detalleExistencia = [];
    this.detMovimientosProv = [];
    this.detExistenciaBodegas = [];

    this.txtArtCodigo = '';
    this.txtArtDescripcion = '';
    this.txtExistenciaMax = '';
    this.txtExistenciaMin = '';
    this.txtArtUnidad = '';
    this.txtArtRotacion = '';
    this.txtInventarioIni = '';
    this.txtComprasPeriodo = '';
    this.txtTotalDisponible = '';
    this.txtVenUltimoPer = '';
    this.txtVenPromPerAnt = '';
    this.txtIndiceProvUltPer = '';
    this.txtIndiceProvPeriodos = '';
    this.txtComprasPlanificadas = '';
    this.txtTotalDisponibleCom = '';
    this.txtIndiceProvUltPer2 = '';
    this.txtIndiceProvPeriodos2 = '';

    const existencias: ComExistencias = {};
    existencias.COMPRAR = '';
    existencias.EXIST_MIN = '';
    existencias.EXISTENCIA = '';
    existencias.ORDENES_COMPRA = '';
    existencias.PEDIDOS_CLIENTES = '';
    existencias.SALDO = '';
    existencias.TOTAL_COMPRA = '';
    existencias.ULTCOSTO_COM = '';
    this.detalleExistencia.push(existencias);

    const grafico = {
      labels: ['', ''],
      datasets: [
        {
          label: 'Ventas',
          backgroundColor: '#42A5F5',
          borderColor: '#1E88E5',
          data: [0]
        },
        {
          label: 'Compras',
          backgroundColor: '#9CCC65',
          borderColor: '#7CB342',
          data: [0]
        }
      ]
    };

    this.dataVentasVsCompras = grafico;


    this.opcionbusqueda = 'articulo';
    this.types = [
      { label: 'Código', value: 'ART_CODIGO' },
      { label: 'Nombre', value: 'ART_NOMBRE' },
      { label: 'Grupo', value: 'GRUP_CODIGO' },
      { label: 'NombreCorto', value: 'ART_NOMBREC' },
      { label: 'Laboratorio', value: 'ART_LABORATORIO' },
      { label: 'Ubicación', value: 'ART_UBICACION' },
      { label: 'CodigoBarras', value: 'ART_CODIGOALT1' },
      { label: 'Alterno3', value: 'ART_CODIGOALT3' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'INV_MAEARTICULO';
    this.where = 'ART_ACTIVO=\'S\'';

    this.busqService.busquedaInvMaeArticulo1().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  async llenarMovByProvArt(tipo) {
    this.detMovimientosProv = [];
    const detalle: ComMovimientoProv[] = [];
    let rs;

    try {
      if (tipo === 'fecha') {
        rs = await this.venEstadisticasService.movimientoArtProv(this.txtArtCodigo, 'fecha');
      }

      if (tipo === 'costo') {
        rs = await this.venEstadisticasService.movimientoArtProv(this.txtArtCodigo, 'costo');
      }

      if (rs !== null) {
        for (const item of rs) {
          const det: ComMovimientoProv = {};
          det.PRO_CODIGO = item.PRO_CODIGO;
          det.PRO_NOMBRE = item.PRO_NOMBRE;
          det.ENCFACPRO_FECHAEMISION = this.datePipe.transform(item.ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
          det.DETFACPRO_COSTO = Number(item.DETFACPRO_COSTO).toFixed(2);


          detalle.push(det);
        }
        this.detMovimientosProv = detalle;
      }

    } catch (err) {

    }
  }

  async llenarTablaExistencia() {

    let rs1;
    let rs2;
    let rs3;
    let rs4;

    try {
      rs1 = this.venEstadisticasService.ultimoCostoCompra(this.txtArtCodigo);
      rs2 = this.venEstadisticasService.existenciaArticulo(this.txtArtCodigo);
      rs3 = this.venEstadisticasService.cantidadOrdCompra(this.txtArtCodigo);
      rs4 = this.venEstadisticasService.cantidadPedidos(this.txtArtCodigo);
    } catch (err) {

    }

    rs1 = await rs1;
    rs2 = await rs2;
    rs3 = await rs3;
    rs4 = await rs4;

    this.detalleExistencia = [];
    const existencias: ComExistencias = {};
    existencias.ULTCOSTO_COM = Number(rs1[0].ULTIMO_COSTO).toFixed(2);
    existencias.EXISTENCIA = Number(rs2[0].EXISTENCIA);
    existencias.ORDENES_COMPRA = Number(rs3[0].TOTAL_ORDCOM);
    existencias.EXIST_MIN = this.txtExistenciaMin;
    existencias.PEDIDOS_CLIENTES = Number(rs4[0].TOTAL_PEDIDOS);
    existencias.SALDO = Number(rs2[0].EXISTENCIA) + Number(rs3[0].TOTAL_ORDCOM) - Number(rs4[0].TOTAL_PEDIDOS);
    existencias.COMPRAR = existencias.SALDO < Number(this.txtExistenciaMin) ? Math.abs(existencias.SALDO) + Number(this.txtExistenciaMin) : 0;
    existencias.TOTAL_COMPRA = this.init.moneyValidation(Number(existencias.COMPRAR * Number(existencias.ULTCOSTO_COM)).toFixed(2));


    this.detalleExistencia.push(existencias);
  }

  async llenarExistenciaBodegas() {
    this.detExistenciaBodegas = [];
    const detalle: ComExistenciaBodegas[] = [];

    const fechaActual = new Date();
    const strFecha = this.datePipe.transform(fechaActual, 'dd/MM/yyyy');
    const time = new Date();
    const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
    const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
    const strHora = hora + ':' + minutos;

    try {
      const rs = await this.venEstadisticasService.llenarExistenciaBod(this.txtArtCodigo);
      if (rs !== null) {
        for (const item of rs) {
          const det: ComExistenciaBodegas = {};
          det.BOD_CODIGO = item.BOD_CODIGO;
          det.BOD_NOMBRE = item.BOD_NOMBRE;
          det.EXISTENCIA = Number(item.EXISTENCIA).toFixed(2);
          det.PEDIDO = Number(item.PEDIDOS).toFixed(2);
          det.SALDO = Number(item.SALDO).toFixed(2);


          detalle.push(det);
        }
        this.detExistenciaBodegas = detalle;
      }

    } catch (err) {

    }
  }

  async ventasVsCompras() {
    let rs1;
    let rs2;

    try {

      rs1 = this.venEstadisticasService.ventasVsCompras(this.txtArtCodigo, 'V');
      rs2 = this.venEstadisticasService.ventasVsCompras(this.txtArtCodigo, 'C');

    } catch (err) {

    }
    rs1 = await rs1;
    rs2 = await rs2;

    const grafico = {
      labels: ['', ''],
      datasets: [
        {
          label: 'Ventas',
          backgroundColor: '#42A5F5',
          borderColor: '#1E88E5',
          data: [rs1[0].TOTALVENTAS]
        },
        {
          label: 'Compras',
          backgroundColor: '#9CCC65',
          borderColor: '#7CB342',
          data: [rs2[0].TOTALCOMPRAS]
        }
      ]
    };

    this.dataVentasVsCompras = grafico;

  }

  async llenarIndiceProvisiones() {
    let rs1;
    let rs2;
    let rs3;
    let rs4;

    try {
      rs1 = this.venEstadisticasService.obtenerExistenciaArtIndProv(this.txtArtCodigo);
      rs2 = this.venEstadisticasService.comprasPeriodo(this.txtArtCodigo);
      rs3 = this.venEstadisticasService.comprasUltPeriodo(this.txtArtCodigo);
      rs4 = this.venEstadisticasService.ventasPeriodo(this.txtArtCodigo);
    } catch (err) {

    }

    rs1 = await rs1;
    rs2 = await rs2;
    rs3 = await rs3;
    rs4 = await rs4;

    this.txtInventarioIni = Number(rs1[0].EXISTENCIA_ACTUAL).toFixed(2);
    this.txtComprasPeriodo = Number(rs2[0].COMPRAS_PERIODO).toFixed(2);
    this.txtTotalDisponible = Number(rs1[0].EXISTENCIA_ACTUAL + rs2[0].COMPRAS_PERIODO).toFixed(2);

    this.txtVenUltimoPer = Number(rs3[0].VENTAS_ULTIMOPER).toFixed(2);
    this.txtVenPromPerAnt = Number(rs4[0].VENTAS_PERIODOS / 10).toFixed(2);


    this.txtIndiceProvUltPer = Number(this.txtVenUltimoPer) !== 0 ? Number(this.txtTotalDisponible) / Number(this.txtVenUltimoPer) :
      0;
    this.txtIndiceProvUltPer = Number(this.txtIndiceProvUltPer).toFixed(2);
    this.txtIndiceProvPeriodos = Number(this.txtVenPromPerAnt) !== 0 ? Number(this.txtTotalDisponible) / Number(this.txtVenPromPerAnt) :
      0;
    this.txtIndiceProvPeriodos = Number(this.txtIndiceProvPeriodos).toFixed(2);

  }

  calcularIndice() {
    this.txtTotalDisponibleCom = Number(this.txtTotalDisponible) + Number(this.txtComprasPlanificadas);
    this.txtTotalDisponibleCom = Number(this.txtTotalDisponibleCom).toFixed(2);

    this.txtIndiceProvUltPer2 = Number(this.txtVenUltimoPer) !== 0 ? Number(this.txtTotalDisponibleCom) / Number(this.txtVenUltimoPer) : 0;
    this.txtIndiceProvUltPer2 = Number(this.txtIndiceProvUltPer2).toFixed(2);
    this.txtIndiceProvPeriodos2 = Number(this.txtVenPromPerAnt) !== 0 ? Number(this.txtTotalDisponibleCom) / Number(this.txtVenPromPerAnt) :
      0;
    this.txtIndiceProvPeriodos2 = Number(this.txtIndiceProvPeriodos2).toFixed(2);
  }

  cerrarReportes() {
    this.displayDialogReportes = false;
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos = false;
    }
  }
   //CDPJ
   async verbasesiva(){
    this.closeSidebar()
    this.basesiva=[]
    const data = await this.comnotrecServicio.obtenerbasesiva(this.notRecSeleccionado.ENCNOTREC_NUMERO);
    if(data !== null && data !== undefined){
     this.basesiva=data
     for (const data2 of  this.basesiva){
       data2.TARIIVA_PORCENTAJE=isNaN(Number(data2.TARIIVA_PORCENTAJE))?Number(0).toFixed(2):Number(data2.TARIIVA_PORCENTAJE).toFixed(2)
       data2.IVA_BASE=isNaN(Number(data2.IVA_BASE))?Number(0).toFixed(2):Number(data2.IVA_BASE).toFixed(2)
       data2.IVA_VALOR=isNaN(Number(data2.IVA_VALOR))?Number(0).toFixed(2):Number(data2.IVA_VALOR).toFixed(2)
     }
    }
    this.displaybasesiva=true;

  }
  async actbasesiva(){
    this.spin=true;//CDPJ
    this.closeSidebar();
    try {
      await this.comnotrecServicio.actualizabasesiva(this.notRecSeleccionado.ENCNOTREC_NUMERO);
      this.messageService.add({
        key: 'notrec',
        severity: 'success',
        summary: 'Información',
        detail: 'Bases IVA actualizadas correctamente'
      });
      this.auditoriagral.registrarAuditoria('COM_ENCNOTREC',this.notRecSeleccionado.ENCNOTREC_NUMERO+'/'+'ACCIONES_ACT_VALORES_BASES_IVA', 'A', 
      '', '01', '', '', '', '').subscribe(() => {
      });
      this.spin=false;
    } catch (error) {
      this.messageService.add({
        key: 'notrec',
        severity: 'error',
        summary: 'Información',
        detail: 'Erro al actualiza las bases IVA'+ error
      });
      this.spin=false;
    }
    
  }
  //CDPJ
}

