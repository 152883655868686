import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ActualizacionPreciosFp} from '../cominterfaces/actualizacionpreciosfp';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class ActualizacionpreciosfpService {

  public way: string;
  
  public hmpConfiguracion: any[];
  public strTipoIva: string;
  public listdetalleprecios: ActualizacionPreciosFp[];
  
  private _auxsalto: boolean;
  
  public grids: any[] = [];
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService
  ) {
    this.way = sessionStorage.getItem('way');
  }
  
  insertarReembolso(item): Observable<any> {
    return this.http.post<any>(this.way + '/compras/actualizarprecio/95f9b73acbc5780', {
      elementos: {
        p_encfacpro_numero: item.ENCFACPRO_NUMERO,
        p_com_codigo: item.COM_CODIGO,
        p_trnree_tipoidepro: item.TRNREE_TIPOIDEPRO,
        p_trnree_idepro: item.TRNREE_IDEPRO,
        p_trnree_tipcomp: item.TRNREE_TIPCOMP,
        p_trnree_serieest: item.TRNREE_SERIEEST,
        p_trnree_serieptoemi: item.TRNREE_SERIEPTOEMI,
        p_trnree_seccompvta: item.TRNREE_SECCOMPVTA,
        p_trnree_fechaemision: item.TRNREE_FECHAEMISION,
        p_trnree_autorizacion: item.TRNREE_AUTORIZACION,
        p_trnree_basecero: item.TRNREE_BASECERO,
        p_trnree_baseiva: item.TRNREE_BASEIVA,
        p_trnree_basenoiva: item.TRNREE_BASENOIVA,
        p_trnree_porcentajeiva: item.TRNREE_PORCENTAJEIVA,
        p_trnree_montoice: item.TRNREE_MONTOICE,
        p_trnree_montoiva: item.TRNREE_MONTOIVA,
        p_trnree_baseimpexeReemb: item.TRNREE_BASEIMPEXEREEMB,
        p_trnree_paispago: item.TRNREE_PAISPAGO,
        p_trnree_protipo: item.TRNREE_PROTIPO,
        p_trnree_empleado: item.TRNREE_EMPLEADO,
        p_trnree_tipgas: item.TRNREE_TIPGAS
      }
    }, this.confIniciales.httpOptions());
  }
  
  calcularPreciosVentas(strNumFact, strCodArticulo, intDetFacProLinea): Observable<any[]> {
    return this.http.post<any>(this.way + '/compras/actualizarprecio/62ab9db992c7fc0', {
      elementos: {
        p_ENCFACPRO_NUMERO: strNumFact,
        p_ART_codigo: strCodArticulo,
        p_detfacpro_linea: intDetFacProLinea,
        p_COM_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  
  
  getReembolsos(encfacnumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/actualizarprecio/83bbb73acbc5780', {
        elementos: {
          ENCFACPRO_NUMERO: encfacnumero
        }
    }, this.confIniciales.httpOptions());
  }

  encontrarArtCodigoAlt2(strArtCodigo) {
    return this.http.post(this.way + '/compras/actualizarprecio/76d5beb30d557c0', {
      elementos: {
        art_codigo: strArtCodigo
      },
    }, this.confIniciales.httpOptions());
  }
  
  ejecutarConsulta1(strNumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/actualizarprecio/3a6e662fcf57a0', {
        elementos: {
          encfacpro_numero: strNumero
        }
    }, this.confIniciales.httpOptions());
  }

  ejecutarConsulta2(strCodigoAlt, strArtCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/actualizarprecio/3a6e662fcf57a2', {
        elementos: {
          DETFACPRO_CODIGOALT: strCodigoAlt,
          DETFACPRO_CODIGO: strArtCodigo
        },
    }, this.confIniciales.httpOptions());
  }

  ejecutarConsulta3(strPorcentaje, strArtCodigo, strUniCodigo, strArtpreCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/actualizarprecio/3a6e662fcf57a4', {
        elementos: {
          ARTPRE_PORCENTAJE: strPorcentaje,
          ART_CODIGO: strArtCodigo,
          UNI_CODIGO: strUniCodigo,
          ARTPRE_CODIGO: strArtpreCodigo,
        },
    }, this.confIniciales.httpOptions());
  }

  ejecutarConsulta4(intBolActcpap, intprecio, intCostoprom, strArtCodigo, strUniCodigo, strArtPreCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/actualizarprecio/3a6e662fcf57a5', {
        elementos: {
          BOLACTCPAP: intBolActcpap,
          PRECIO: intprecio,
          COSTOPROMEDIO: intCostoprom,
          ART_CODIGO: strArtCodigo,
          UNI_CODIGO: strUniCodigo,
          ARTPRE_CODIGO: strArtPreCodigo
        },
    }, this.confIniciales.httpOptions());
  }
  
  
}



