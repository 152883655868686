<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="docimpsri">
	<p-confirmDialog [transitionOptions]="'0ms'" key="docimpsri" title="Confirmar Acción"
					 appendTo="body"></p-confirmDialog>
</p-toast>
<div class="p-grid" style="width: 98%" id="div">
	<div class="p-col-12">
		<p-tabView [(activeIndex)]="activeIndex">
			<p-tabPanel header="IMPORTAR">
				<div class="divgrups"> 
                    <!-- Primera Separación -->
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-11">
								</div>
								<div class="p-col-1" style="text-align: right;">
									<button type="button"
											style="background-image: url(../../../assets/images/iconos/salir.png);"
											(click)="salir()"></button>
								</div>
							</div>
								
						</div>
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-6">
								<div class="p-grid">
										<div class="p-col-2">
											<span>Documento:</span>
										</div>
										<div class="p-col-9">
											<p-fileUpload (uploadHandler)="SeleccionarDirectorio($event)" accept=".txt" chooseLabel="..."
												auto="false" customUpload="true" mode="advanced" name="myfile[]" [files]="filesImportXML"
												(onRemove)="cleardoc()">
											</p-fileUpload>
										</div>
										<div class="p-col-1" style="text-align: right;">
											<button type="button" (click)="procesar()"
													style="background-image: url(../../../assets/images/iconos/visto.png); float: left;">
											</button>
										</div>
									</div>
									<div class="p-grid">
										<div class="p-col-12">
										</div>
									</div>
									<div class="p-grid">
										<div class="p-col-2">
											<p-colorPicker [disabled]="true" [(ngModel)]="choosedColorR" >
											</p-colorPicker>
										</div>
										<div class="p-col-8">
											<span>Proveedor no encontrado</span>
										</div>
									</div>
								</div>
								<div class="p-col-6">
									<div class="p-grid">
										<div class="p-col-12">
											<div class="p-grid">
												<div class="p-col-1" ></div>
												<div class="p-col-2" >
													<span>Bodega:</span>
												</div>
												<div class="p-col-2">
													<label for="BOD_CODIGOXML"></label>
													<input class="frm" id="BOD_CODIGOXML" type="text" pInputText [(ngModel)]="bodcodigoxml" [disabled]="selectlinea"
														(keydown.enter)="setFocus($event)"  autocomplete="off">
												</div>
												<div class="p-col-1">
													<button type="button" style="background-image: url(../../../assets/images/iconos/buscar.png);" [disabled]="selectlinea"
														(click)="busquedaBodega(bodcodigoxml, 'bodegaxml')"></button>
												</div>
												<div class="p-col-4">
													<label for="BOD_NOMBREXML"></label>
													<input class="frm" id="BOD_NOMBREXML" type="text" pInputText [(ngModel)]="bodnombrexml"
														(keydown.enter)="setFocus($event)"  autocomplete="off"
														disabled>
												</div>
											</div>
										</div>
										<div class="p-col-12">
											<div class="p-grid">
												<div class="p-col-1" ></div>
												<div class="p-col-2" >
													<span>Id. Crédito Tributario:</span>
												</div>
												<div class="p-col-2">
													<label for="IDCREDITOXML"></label>
													<input class="frm" id="IDCREDITOXML" type="text" pInputText [(ngModel)]="idcreditoxml" [disabled]="selectlinea"
														(keydown.enter)="setFocus($event)" autocomplete="off">
												</div>
												<div class="p-col-1">
													<button type="button" style="background-image: url(../../../assets/images/iconos/buscar.png);" [disabled]="selectlinea"
														(click)="busquedaidcretri(idcreditoxml, 'cretrixml')"></button>
												</div>
												<div class="p-col-4">
													<label for="IDCREDITONOMBREXML"></label>
													<input class="frm" id="IDCREDITONOMBREXML" type="text" pInputText
														[(ngModel)]="idcreditonombrexml" (keydown.enter)="setFocus($event)"
														 autocomplete="off" disabled>
												</div>
											</div>
										</div>
										<div class="p-col-12">
											<div class="p-grid">
												<div class="p-col-1" ></div>
												<div class="p-col-2" >
													<!-- <span>Generar la Retención:</span> -->
												</div>
												<div class="p-col-2">
													<!-- <p-checkbox id="DESSELECT"
														binary="true"
														(onChange)="genretencion()" [(ngModel)]="desSelect"
														(ngModelChange)="habilitarBotones()" [disabled]="selectlinea"
													></p-checkbox> -->
												</div>
											</div>
										</div>
										<div class="p-col-12">
											<div class="p-grid">
												<!-- (click)="autorizar()"
															[disabled]="botonesBoolean" -->
												<div class="p-col-4" >
													<button class="aceptar" pButton [disabled]="actbotones1" (click)="descargarsri()"
															style="background-image: url(../../../assets/images/iconos/btnAceptar.png); line-height: 2.5em; margin-top: 2px; margin-left: 3px;  width: 75%; height: 70%">
														Descargar
													</button>
												</div>
												<div class="p-col-4">
													<button class="aceptar" pButton [disabled]="actbotones2" (click)="revisaralt()"
															style="background-image: url(../../../assets/images/iconos/btnAceptar.png); line-height: 2.5em; margin-top: 2px; margin-left: 3px;  width: 75%; height: 70%">
														Revisar Alternos
													</button>
												</div>
												<div class="p-col-4" >
													<button class="aceptar" pButton [disabled]="actbotones3" (click)="importarsri()"
															style="background-image: url(../../../assets/images/iconos/btnAceptar.png); line-height: 2.5em; margin-top: 2px; margin-left: 3px;  width: 75%; height: 70%">
														Importar
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- Segunda Fila -->
						
						<!-- <div class="p-col-2">
							
						</div> -->
					</div>
				</div>
				<app-aggridsaci
						[width]=""
						[height]="largo"
						[enableSorting]="true"
						[enableFilter]="true"
						[rowData]="documentos"
						[frameworkComponents]="frameworkComponents"
						[animateRows]="true"
						[rowSelection]="rowSelection"
						[columnDefs]="columnDefsDocimpsri"
						[defaultColDef]="defaultColDefDocimpsri"
						[rowStyle]="rowStyle"
						[singleClickEdit]="false"
						[botones]="false"
						(selected)="seleccionFil()"
				></app-aggridsaci>
			</p-tabPanel>
		</p-tabView>
	</div>
</div>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '70vw', height: '70vh'}" [focusOnShow]="true">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

	<div  style="position: fixed;
				z-index: 999;
				height: 2em;
				width: 2em;
				overflow: visible;
				margin: auto;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;">
		<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s" >
		</p-progressSpinner>
		<p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
				[style]="{ opacity: 0.00}" position="top">
	</p-dialog>	
	</div>

<p-dialog header="Detalle" [(visible)]="displaycodalt" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{width: '90vw'} " [focusOnShow]="true" appendTo="body"    >
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="codaltpan">
		<p-confirmDialog [transitionOptions]="'0ms'" key="codaltpan" 
						 appendTo="body"></p-confirmDialog>
	</p-toast>
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-11">
					</div>
					<div class="p-col-1" style="text-align: right;">
						<button type="button"
								style="background-image: url(../../../assets/images/iconos/salir.png);"
								(click)="sal()"></button>
					</div>
				</div>
			</div>
			<div class="p-col-12">
				<app-aggridsaci [width]=""
								[height]="'500'"
								[enableSorting]="true"
								[enableFilter]="true"
								[rowData]="AneximpdocsriService.arreglocodalt[indicador]"
								[frameworkComponents]="frameworkComponents"
								[animateRows]="true"
								[rowSelection]="rowSelection"
								[columnDefs]="columnDefscodalter"
								[defaultColDef]="defaultDefscodalter"
								[rowStyle]="rowStyle1"
								[singleClickEdit]="false"
								[botones]="false"
								(selected)="seleccionFilcodalt($event)"
								>
				</app-aggridsaci>
			</div>
		</div>
	</div>
</p-dialog>

