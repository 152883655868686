import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import { Nommaedatosadi, NommaedatosadiEmp } from '../nominterfaces/nonmaedatosadi';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
@Injectable({
  providedIn: 'root'
})
export class NommaedatosadiService {

  public way: string;

  public nommaedatosadi:Nommaedatosadi[]=[];

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private confIniciales: ConfInicialesService,
    public usuario: Usuario) {

      this.way = sessionStorage.getItem('way');
    
      this.usuario.identificacion;
    }

  guardarDatosadi( nommaedatosadi:Nommaedatosadi){
    console.log( nommaedatosadi);
    if(nommaedatosadi.DATADI_TAMANIO==''){
      nommaedatosadi.DATADI_TAMANIO="0";
    }
    if(nommaedatosadi.DATADI_NOMBRECAMPO==''){
      nommaedatosadi.DATADI_NOMBRECAMPO="";
    }
    nommaedatosadi.DATADI_ORDEN=this.padnum(parseInt(nommaedatosadi.DATADI_ORDEN));
    console.log(nommaedatosadi);
    return this.http.post<any[]>(this.way + '/nom/maedatosadi/10f2b6d5771c53000', {
        elementos: {
          p_datadi_orden: this.padnum(parseInt(nommaedatosadi.DATADI_ORDEN)),
          p_datadi_nombrecampo: nommaedatosadi.DATADI_NOMBRECAMPO,
          p_datadi_etiqueta: nommaedatosadi.DATADI_ETIQUETA,
          p_datadi_tipodato: nommaedatosadi.DATADI_TIPODATO,
          p_datadi_tamanio:nommaedatosadi.DATADI_TAMANIO,
          p_datadi_estado:nommaedatosadi.DATADI_ESTADO
        },
    }, this.confIniciales.httpOptions()).toPromise(); 
  }
  eliminarDatosadi( nommaedatosadi:Nommaedatosadi){
    return this.http.post<any[]>(this.way + '/nom/maedatosadi/756566abb8e29c0', {
        elementos: {
          p_datadi_nombrecampo: nommaedatosadi.DATADI_NOMBRECAMPO,
        },
    }, this.confIniciales.httpOptions()).toPromise(); 
  }
  async selectDatosadi(){
    var strSentencia="select * from NOM_MAEDATOSADI order by DATADI_ORDEN ";
    
    return await this.http.post<any[]>(this.way + '/nom/maedatosadi/e3aad5771c53680', {
      elementos: {},
   
    }, this.confIniciales.httpOptions()
  ).toPromise();
  }
  async CamposNuevos(){
    await this.selectDatosadi().then(async res=>{
      const datos:Nommaedatosadi[]=res;
       await  this.AdicionarCamposNuevos(datos).then(async res=>{
          this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
          return;
        }).catch(err=>{
          this.mensajeAlerta('error', 'Error', err);
          return;
        })
    })
  }
  async AdicionarCamposNuevos(nommaedatosadi:Nommaedatosadi[]){
    var doornot=true;
    for(let item of nommaedatosadi){
      if(item.DATADI_ESTADO=="PEN"){
        var strSentencia= "ALTER TABLE NOM_MAEEMPLEADO";
       switch(item.DATADI_TIPODATO){
         case 'Etiqueta':
          doornot=false;
           break;
         case 'Varchar2':
          strSentencia = strSentencia + " ADD ( " + item.DATADI_NOMBRECAMPO + "  VARCHAR2( " + String(item.DATADI_TAMANIO) + "))"
           break;
         case 'Number':
          strSentencia = strSentencia + " ADD ( " + item.DATADI_NOMBRECAMPO  + "  NUMBER( " + String(item.DATADI_TAMANIO)  + "))"
           break;
         case 'Float':
          strSentencia = strSentencia + " ADD ( " +item.DATADI_NOMBRECAMPO + " FLOAT( " + String(item.DATADI_TAMANIO) + "))"
          break;
         case 'Date':
          strSentencia = strSentencia + " ADD ( " + item.DATADI_NOMBRECAMPO + " DATE)"
          break;
          case 'Clob':
          strSentencia = strSentencia + " ADD ( " + item.DATADI_NOMBRECAMPO + " CLOB)"
          break;
         default:
           break;
       }
        if(doornot==true){
          await  this.alterTABLE(strSentencia).then((res)=>{
              
              strSentencia = "update nom_maedatosadi set datadi_estado='OK'";
              strSentencia = strSentencia + " where datadi_nombrecampo='" + item.DATADI_NOMBRECAMPO + "'";
              this.actualizarEstadoDatoAdic(item.DATADI_NOMBRECAMPO).then(res=>{
                return;
                
              }).catch(e=>{
                let mensaje: string;
                mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
                this.mensajeAlerta('error', 'Error', mensaje);
              })
          }).catch(e=>{
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
            this.mensajeAlerta('error', 'Error', mensaje);
          })
        }else{
           
          strSentencia = "update nom_maedatosadi set datadi_estado='OK'";
          strSentencia = strSentencia + " where DATADI_ETIQUETA='" + item.DATADI_ETIQUETA + "' AND DATADI_TIPODATO='Etiqueta'";
          this.actualizarEstadoEtiqueta(item.DATADI_ETIQUETA).then(res=>{
            return;
            
          }).catch(e=>{
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
            this.mensajeAlerta('error', 'Error', mensaje);
          })
        }
      }
    }   
  }
  async alterTABLE(strsql:string){
    return await this.http.post<any[]>(this.way + '/nom/maedatosadi/3a6e671d7ebaec', {
      elementos: {
        sentencia: strsql
      }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  async actualizarEstadoDatoAdic(campo:string){
    return await this.http.post<any[]>(this.way + '/nom/maedatosadi/a6779d6b55d53800', {
      elementos: {
        DATADI_NOMBRECAMPO: campo
      }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  async actualizarEstadoEtiqueta(etiqueta:string){
    return await this.http.post<any[]>(this.way + '/nom/maedatosadi/14cef3adc3bb2d0000', {
      elementos: {
        DATADI_ETIQUETA: etiqueta
      }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  async actualizarEmplados(nommaedatosadi:NommaedatosadiEmp){
    var strSentencia= "update nom_maeempleado set";
    switch(nommaedatosadi.DATADI_TIPODATO){
      case 'Etiqueta':
       
        break;
      case 'Varchar2':
       
        break;
      case 'Number':
       
        break;
      case 'Float':
       
       break;
      case 'Date':
       break;
      default:
        break;
    }
    return await this.http.post<any[]>(this.way + '/nom/maedatosadi/a677ceb66ae53800', {
      elementos: {
        sentencia: strSentencia
      }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'tnonmaedatosadi',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
  }
  padnum(num:number){
    var ret:string;
    if(num<10){
      ret=num.toString().padStart(2,'0');
      return ret;
    }else{
      return num;
    }
  }
}
