<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="cliente"></p-toast>
<app-invmaebarra [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true"
    [botonGuardarPermanente]="true" [barraBotonesCRUD]="true" [barraBotones3Alt]="false" [botonCancelarPermanente]="true" 
    (signal)="manejarSenales($event)" [botonBuscarPermanente]="true" style="text-align: left;">
</app-invmaebarra>
<div class="divgrups">
    <div class="p-grid">
        <div class="p-col-2">
            <span>Ide:</span>
        </div>
        <div class="p-col-5">
            <p-dropdown [options]="cmbTipoIde" [style]="{'minWidth': '100%', 'width':'100%'}" optionLabel="name"
                [panelStyle]="{'width':'110%'}" [(ngModel)]="selectTipoIde" (onChange)="change($event)"></p-dropdown>
        </div>
        <div class="p-col-2">
            <button type="button" style="background-image: url(../../../assets/images/iconos/visto.png);"
                (click)="validar(txtRuc)"></button>
        </div>
        <div class="p-col-3">
            <span>{{lblvalida}}</span>
        </div>

        <div class="p-col-2">
            <span>Ruc/Céd:</span>
        </div>
        <div class="p-col-7">
            <label for="txtRuc"></label>
            <input class="frm" id="txtRuc" type="text" pInputText [(ngModel)]="txtRuc"
                (keydown.enter)="setFocus($event)" autocomplete="off">
        </div>
        <div class="p-col-3">
            <p-checkbox name="chkbtnPlaca" value="" label="Placa" binary="{{chkbtnPlaca}}" [(ngModel)]="chkbtnPlaca">
            </p-checkbox>
        </div>
        <div class="p-col-2">
            <span>Nombre:</span>
        </div>
        <div class="p-col-10">
            <label for="txtNombre"></label>
            <input class="frm" id="txtNombre" type="text" pInputText [(ngModel)]="txtNombre"
                (keydown.enter)="setFocus($event)" autocomplete="off">
        </div>
        <div class="p-col-2">
            <span>Dirección:</span>
        </div>
        <div class="p-col-10">
            <label for="txtDireccion"></label>
            <input class="frm" id="txtDireccion" type="text" pInputText [(ngModel)]="txtDireccion"
                (keydown.enter)="setFocus($event)" autocomplete="off">
        </div>
        <div class="p-col-2">
            <span>Teléfono:</span>
        </div>
        <div class="p-col-10">
            <label for="txtTelefono"></label>
            <input class="frm" id="txtTelefono" type="text" pInputText [(ngModel)]="txtTelefono"
                (keydown.enter)="setFocus($event)" autocomplete="off">
        </div>
        <div class="p-col-2">
            <span>Email:</span>
        </div>
        <div class="p-col-10">
            <label for="txtEmail1"></label>
            <input class="frm" id="txtEmail1" type="text" pInputText [(ngModel)]="txtEmail1"
                (keydown.enter)="setFocus($event)" autocomplete="off">
        </div>
        <!-- CDPJ -->
        <!-- <div class="p-col-2">
            <span>Referencia Contable:</span>
        </div>
        <div class="p-col-10">
            <label for="CON_CODIGO1"></label>
            <input class="frm" id="CON_CODIGO1" type="text" pInputText
                [(ngModel)]="CON_CODIGO1" (keydown.enter)="setFocus($event)"
                 autocomplete="off">
        </div>
        CDPJ -->
    </div>
</div>


<p-dialog header="Búsqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
        [busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>


<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
    <p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
    </p-progressSpinner>
</div>

<p-dialog header="Clave Autorización" [(visible)]="displayDialogAutorizacion" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" (onHide)="onHideAutorizacion()" [style]="{width: '40vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="autorizacion"></p-toast>
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							(keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>