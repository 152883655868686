//
// SACI WEB
// Rutina: Ingreso de Salidas
// Propietario: Wilson Ramos
// Modificado por: WR
// Fecha de creación: 2019
// Fecha de Modificación: 22-01-2020
//
import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';
import { Invsalida } from '../invinterfaces/invsalida';
import { Invencsalida } from '../invinterfaces/invencsalida';
import { Invsalidavar } from '../invinterfaces/invsalidavar';
import { InvMaearticulo } from '../invinterfaces/invmaearticulo';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { InvsalidaService } from '../invservicios/invsalida.service';
import { InvbusquedaService } from '../invservicios/invbusqueda.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { DatePipe } from '@angular/common';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import { Usuario } from 'src/app/usuario';

@Component({
  selector: 'app-invsalida',
  templateUrl: './invsalida.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})

export class InvsalidaComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  hmpConfiguracion: any[];
  indicador: any;
  modulo: any;

  encSalidas: Invencsalida[];
  salidaSeleccionada: Invsalida;
  encsalidaSeleccionada: Invencsalida = {};
  salidavarSeleccionada: Invsalidavar;
  encsalidaSeleccionadaAux: Invencsalida;
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  botonPrimero: boolean;
  botonSiguiente: boolean;
  botonAnterior: boolean;
  botonUltimo: boolean;
  auxiliar: number;
  barraBotones2: boolean;
  barraBotones4: boolean;
  panelSalidas: boolean;
  panelVarios: boolean;
  inputsboolean: boolean;
  botonNuevoP: boolean;
  botonBorrarP: boolean;
  botonRegresarP: boolean;
  botonNuevoPV: boolean;
  botonBorrarPV: boolean;
  botonRegresarPV: boolean;
  decimalesPrecioSal: number;
  decimalesCantidadSal: number;
  decimalesTotalSal: number;
  displayDialogBus: boolean;
  displayDialogBusFast: boolean;
  displayDialogAsiento: boolean;
  displayAsiContable: boolean;
  checkSaldoI: boolean;
  tieneAsiento: boolean;
  totalSalida: number;
  asiento: any;
  saldoTotalVarios: number;
  contadorLineaVar: number;
  displayAcciones: boolean;
  displayDialogSeriales: boolean;
  displayDialogLotes: boolean;
  spin=false;//CDPJ

  // date1: any;
  // salidaService.hora: any;
  indice: number;
  artNombre: InvMaearticulo;
  opcion: string;
  types: SelectItem[];
  arregloBusq: any[];
  busquedaCampos: string[];
  tabla: string;
  where: string;
  activeIndex: number;
  largo: string;
  consulta: string;
  public frameworkComponents;
  rowStyle;
  defaultColDefSalida;
  defaultColDefSalidaVar;
  rowSelection = 'multiple';
  posibleGuardar: boolean;
  contadorAsiento: number;
  auxiliarlot = 0;


  auxreporte: number = 0;
  strNombreForma: string;
  displayDialogReportes: boolean;

  displayDialogDocumentos = false;

  columnDefsSalida = [
    { // 0
      headerName: 'Bodega', field: 'BOD_CODIGO', cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Artículo', field: 'ART_CODIGO', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: false,
          obligatorio: true,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 25
        }
      }
    },
    { // 2
      headerName: 'Nombre', field: 'ART_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: false,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80
        }
      }
    },
    { // 3
      headerName: 'Documento', field: 'DETKAR_NRODOC', cellEditor: 'cellPrueba', editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: false,
          obligatorio: true,
          fecha: false
        }
      }
    },
    { // 4
      headerName: 'Cantidad', field: 'DETKAR_CANTIDAD', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      // , editable: true,
      onCellClicked: (params) => {
        this.abrirLotes(params.data);
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 6,
          obligatorio: true,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
        }
      }
    },
    { // 5
      headerName: 'Costo', field: 'DETKAR_COSTOUNIT', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      editable: () => {
        return false;
      }
      /*cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 6,
            obligatorio: true,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        }*/
    },
    { // 6
      headerName: 'Costo Total', field: 'DETKAR_COSTOTOTAL', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      editable: () => {
        return false;
      }
      /*cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: true,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 32
          }
        }*/
    },{ // 7
      headerName: 'C.Costos', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 35
        }
      }
    }
  ];

  columnDefsSalidaVar = [{
    headerName: 'Ref Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba',
    cellEditorParams:
    {
      values: {
        mayusculas: false,
        numeros: true,
        alphabetic: true,
        saltoslinea: 1,
        newrow: false,
        numerodecimales: false,
        // obligatorio: true,
        fecha: false,
        opcionbuscar: true,
        tamanocelda: 35
      }
    }
  },
  {
    headerName: 'Descripción', field: 'CON_NOMBRE', cellEditor: 'cellPrueba',
    /*cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 100
        }
      }*/
  },
  {
    headerName: 'Créditos', field: 'DETKARDEXVAR_IMPORTE', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
    cellEditorParams:
    {
      values: {
        mayusculas: false,
        numeros: true,
        alphabetic: false,
        saltoslinea: 1,
        newrow: false,
        numerodecimales: 2,
        obligatorio: true,
        fecha: false,
        tamanocelda: 32,
      }
    }
  },
  {
    headerName: 'Cnt. Costos', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
    cellEditorParams:
    {
      values: {
        mayusculas: false,
        numeros: true,
        alphabetic: false,
        saltoslinea: 1,
        newrow: false,
        numerodecimales: 0,
        // obligatorio: true,
        fecha: false,
        opcionbuscar: true,
        tamanocelda: 35
      }
    }
  }
  ];

  constructor(public salidaService: InvsalidaService,
    private datePipe: DatePipe,
    private busquedaService: InvbusquedaService,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private confirmationService: ConfirmationService,
    private confIniciales: ConfInicialesService,
    private init: NuevoComponentService,
    private auditoriagral: AuditoriagralService,
    private utilitariosService: UtilitariosService,
    public usuario: Usuario
  ) {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.defaultColDefSalida = {
      editable: true,
      width: 150,
      objeto: 'maesalida'
    };
    this.defaultColDefSalidaVar = {
      editable: true,
      width: 150,
      objeto: 'maesalidavar'
    };
  }

  ngOnInit() {
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.modulo = this.init.tabs[this.init.tabindex - 1].modprefijo;
    this.confIniciales.getDecimales('frmINV_ENCKARDEX').subscribe((dec) => {
      if (dec !== null) {
        dec.map((decimales) => {
          if (decimales.NUMDEC_CAMPO === 'COSTO') {
            this.decimalesPrecioSal = Number(decimales.NUMDEC_NUMDEC);
            this.salidaService.decimalesPrecioSal = this.decimalesPrecioSal;
          } else if (decimales.NUMDEC_CAMPO === 'COSTOTOTAL') {
            this.decimalesTotalSal = Number(decimales.NUMDEC_NUMDEC);
            this.salidaService.decimalesTotalSal = this.decimalesTotalSal;
          } else if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
            this.decimalesCantidadSal = Number(decimales.NUMDEC_NUMDEC);
            this.salidaService.decimalesCantidadSal = this.decimalesCantidadSal;
          }
        });

        this.columnDefsSalida[4].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.salidaService.decimalesCantidadSal,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };

        this.columnDefsSalida[5].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.salidaService.decimalesPrecioSal,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };
        this.aggrid.refreshColumnDefs();
      }
    });
    this.tabla = '';
    this.auxiliar = 0;
    this.busquedaCampos = [];
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = true;
    this.botonRegresar = true;
    this.botonPrimero = false;
    this.botonSiguiente = false;
    this.botonAnterior = false;
    this.botonUltimo = false;
    this.barraBotones2 = false;
    this.barraBotones4 = false;
    this.panelSalidas = false;
    this.panelVarios = false;
    //CDPJ
    this.botonNuevoP = true;
    this.botonBorrarP = true;
    this.botonRegresarP = true;
    this.botonNuevoPV = true;
    this.botonBorrarPV = true;
    this.botonRegresarPV = true;
    this.spin=false//CDPJ
    //CDPJ

    this.botonNuevoP = true;
    this.botonBorrarP = true;
    this.botonRegresarP = true;
    this.botonNuevoPV = true;
    this.botonBorrarPV = true;
    this.botonRegresarPV = true;
    this.displayDialogBus = false;
    this.displayDialogBusFast = false;
    this.displayDialogAsiento = false;
    this.displayAsiContable = false;
    this.displayAcciones = false;
    this.displayDialogSeriales = false;
    this.displayDialogLotes = false;
    this.totalSalida = 0;
    this.contadorLineaVar = 0;
    this.saldoTotalVarios = 0;
    this.salidaService.detkardexsarray[this.indicador] = [];
    this.activeIndex = 0;
    this.salidaService.detkardexsvararray[this.indicador] = [];
    // this.salidaService.calcularTotal();
    this.indice = 0;
    this.artNombre = {};
    this.checkSaldoI = false;
    this.opcion = '';
    this.salidaService.date1 = new Date();
    this.salidaService.hora = new Date();
    this.salidaSeleccionada = {};
    this.encsalidaSeleccionada = {};
    this.inputsboolean = true;
    this.largo = '-5800';
    this.asiento = '';
    this.tieneAsiento = false;
    this.consulta = '';
    this.obtenerEncSalidas();
    this.contadorAsiento = 0;

    this.getConfiguracion();

    this.displayDialogReportes = false;
  }

  limpiardatos() {
    this.tabla = '';
    this.auxiliar = 0;
    this.busquedaCampos = [];
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = true;
    this.botonRegresar = true;
    this.botonPrimero = false;
    this.botonSiguiente = false;
    this.botonAnterior = false;
    this.botonUltimo = false;
    this.barraBotones2 = false;
    this.barraBotones4 = false;
    this.panelSalidas = false;
    this.panelVarios = false;
    this.botonNuevoP = true;
    this.botonBorrarP = true;
    this.botonRegresarP = true;
    this.botonNuevoPV = true;
    this.botonBorrarPV = true;
    this.botonRegresarPV = true;
    this.displayDialogBus = false;
    this.displayDialogBusFast = false;
    this.displayDialogAsiento = false;
    this.displayAsiContable = false;
    this.displayAcciones = false;
    this.displayDialogSeriales = false;
    this.displayDialogLotes = false;
    this.totalSalida = 0;
    this.contadorLineaVar = 0;
    this.saldoTotalVarios = 0;
    this.salidaService.detkardexsarray[this.indicador] = [];
    this.activeIndex = 0;
    this.salidaService.detkardexsvararray[this.indicador] = [];
    this.indice = 0;
    this.artNombre = {};
    this.checkSaldoI = false;
    this.opcion = '';
    this.salidaService.date1 = new Date();
    this.salidaService.hora = new Date();
    this.salidaSeleccionada = {};
    this.encsalidaSeleccionada = {};
    this.inputsboolean = true;
    this.largo = '500';
    this.asiento = '';
    this.tieneAsiento = false;
    this.consulta = '';
    this.obtenerEncSalidas();
    this.contadorAsiento = 0;
  }

  getConfiguracion() {
    this.auditoriagral.obtenerConfiguracion1().subscribe((conf) => {
      this.hmpConfiguracion = conf;
      //CDPJ
    const ccpies=this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CCPIES').CFG_VALOR1;
    //CDPJ
    if(ccpies === 0 || ccpies === null || ccpies === undefined){
      this.columnDefsSalida.splice(7, 1);
    }
    this.aggrid.refreshColumnDefs();
      
    });
  }

  async obtenerEncSalidas() {
    this.salidaService.getEncSalidas().subscribe((res) => {
      this.encSalidas = res;
    });

  }

  async manejarSenales(valor) {
    if (valor !== 'Nuevo' && valor !== 'Cancelar'
      && valor !== 'Primero' && valor !== 'Anterior' && valor !== 'Siguiente' && valor !== 'Ultimo'
      && valor !== 'Buscar' && valor !== 'Imprimir' && valor !== 'AsientoVer' && valor !== 'Salir') {
      // Verifica el periodo contable
      const dia = this.salidaService.date1.getDate();
      const mes = this.salidaService.date1.getMonth();
      const anio = this.salidaService.date1.getFullYear();


      if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
        this.messageService.add({
          key: 'sal',
          severity: 'info',
          summary: 'Información',
          detail: 'Error, fecha debe de estar dentro del período contable'
        });
        return;
      }
    }

    if (valor === 'Guardar') {
      this.realizarGuardar();
    }

    if (valor === 'Borrar') {
      this.confirmarBorrarT('documento');
    }

    if (valor === 'Buscar') {
      if (await this.ValidarSeriales(this.encsalidaSeleccionada.KAR_NRO)) {
        if (!this.existeAsiento()) {
          return;
        }
        //CDPJ
        if(this.modulo !== 'PROD'){
          if(this.encsalidaSeleccionada.KAR_NRO === undefined || this.encsalidaSeleccionada.KAR_NRO === null||
            this.encsalidaSeleccionada.KAR_NRO === ''){
              this.salidaService.totalSalidaarray[this.indicador]=Number(0).toFixed(0)
            }
          let totalvar: number;
          totalvar = Number(this.calcularTotalVarios());
          if (Number(this.salidaService.totalSalidaarray[this.indicador]) !== totalvar) {
            this.messageService.add({
              key: 'sal',
              severity: 'error',
              summary: 'Generar asiento',
              detail: 'El total de varios es diferente al total del documento.' 
               
            });
          } else {
            this.opcion = 'BUSQUEDA';
            this.types = [
              { label: 'Número', value: 'KAR_NRO' },
              { label: 'Fecha', value: 'KAR_FECHA' },
              { label: 'Comentario', value: 'KAR_COMENTARIO' },
              { label: 'Comprobante', value: 'KAR_NROCOMPROBANTE' },
              { label: 'No. Asiento', value: 'ASI_NRO' },
              { label: 'ORDEN PROD', value: 'ENCORDPRO_NUMERO' },
              { label: 'Origen', value: 'KAR_ORIGEN' },
            ];
            //CDPJ
            this.busquedaCampos = ['', '', '', '', '', '', '']
            this.tabla = 'INV_ENCKARDEX';
            if(this.modulo === 'PROD'){
              this.where = 'KAR_TIPO = \'SA\' AND KAR_ORIGEN = \'PROD\' AND ROWNUM < 50 ORDER BY KAR_NRO DESC';
            }else{
              this.where = 'KAR_TIPO = \'SA\' AND (KAR_ORIGEN = \'ES_INV\' OR KAR_ORIGEN = \'INV\') AND ROWNUM < 50 ORDER BY KAR_NRO DESC';
            }
            this.busquedaService. busquedaInvEncKardex2NEW(this.modulo).subscribe((res: any[]) => {
              this.arregloBusq = res;
              this.arregloBusq.map((bus) => {
                bus.KAR_FECHA = this.datePipe.transform(bus.KAR_FECHA, 'dd/MM/yyyy');
              });
              this.displayDialogBusFast = true;
            });
    
          }
      }else{
        this.opcion = 'BUSQUEDA';
        this.types = [
          { label: 'Número', value: 'KAR_NRO' },
          { label: 'Fecha', value: 'KAR_FECHA' },
          { label: 'Comentario', value: 'KAR_COMENTARIO' },
          { label: 'Comprobante', value: 'KAR_NROCOMPROBANTE' },
          { label: 'No. Asiento', value: 'ASI_NRO' },
          { label: 'ORDEN PROD', value: 'ENCORDPRO_NUMERO' },
          { label: 'Origen', value: 'KAR_ORIGEN' },
        ];
        //CDPJ
        this.busquedaCampos = ['', '', '', '', '', '', '']
        this.tabla = 'INV_ENCKARDEX';
        if(this.modulo === 'PROD'){
          this.where = 'KAR_TIPO = \'SA\' AND KAR_ORIGEN = \'PROD\'';
        }else{
          this.where = 'KAR_TIPO = \'SA\' AND KAR_ORIGEN = (\'ES_INV\' OR KAR_ORIGEN = \'INV\')';
        }
        this.busquedaService. busquedaInvEncKardex2NEW(this.modulo).subscribe((res: any[]) => {
          this.arregloBusq = res;
          this.arregloBusq.map((bus) => {
            bus.KAR_FECHA = this.datePipe.transform(bus.KAR_FECHA, 'dd/MM/yyyy');
          });
          this.displayDialogBusFast = true;
        });

      }
        //CDPJ
        // this.opcion = 'BUSQUEDA';
        // this.types = [
        //   { label: 'Número', value: 'KAR_NRO' },
        //   { label: 'Fecha', value: 'KAR_FECHA' },
        //   { label: 'Comentario', value: 'KAR_COMENTARIO' },
        //   { label: 'Comprobante', value: 'KAR_NROCOMPROBANTE' },
        //   { label: 'No. Asiento', value: 'ASI_NRO' },
        //   { label: 'ORDEN PROD', value: 'ENCORDPRO_NUMERO' },
        //   { label: 'Origen', value: 'KAR_ORIGEN' },
        // ];
        // //CDPJ
        // this.busquedaCampos = ['', '', '', '', '', '', '']
        // this.tabla = 'INV_ENCKARDEX';
        // if(this.modulo === 'PROD'){
        //   this.where = 'KAR_TIPO = \'SA\' AND KAR_ORIGEN = \'PROD\'';
        // }else{
        //   this.where = 'KAR_TIPO = \'SA\' AND (KAR_ORIGEN = \'ES_INV\' OR KAR_ORIGEN = \'INV\')';
        // }
        // this.busquedaService. busquedaInvEncKardex2NEW(this.modulo).subscribe((res: any[]) => {
        //   this.arregloBusq = res;
        //   this.arregloBusq.map((bus) => {
        //     bus.KAR_FECHA = this.datePipe.transform(bus.KAR_FECHA, 'dd/MM/yyyy');
        //   });
        //   this.displayDialogBusFast = true;
        // });
      }
    }

    if (valor === 'Cancelar' || valor === 'Nuevo' || valor === 'Primero' || valor === 'Ultimo'
      || valor === 'Anterior' || valor === 'Siguiente') {
      if (valor === 'Nuevo' || valor === 'Primero' || valor === 'Ultimo'
        || valor === 'Anterior' || valor === 'Siguiente') {
        if (!this.existeAsiento()) {
          return;
        }
      }
      if (await this.ValidarSeriales(this.encsalidaSeleccionada.KAR_NRO) || valor === 'Cancelar') {
        if (this.contadorAsiento !== 0) {
          this.salidaService.getAsiento(this.encsalidaSeleccionada).subscribe((res) => {
            if (res[0].ASI_NRO === null) {
              this.confirmarBorrarT('asiento');
            } else {
              //this.mostrarSalida(valor);
              //CDPJ
              if(this.modulo !== 'PROD'){
                if(this.encsalidaSeleccionada.KAR_NRO === undefined || this.encsalidaSeleccionada.KAR_NRO === null||
                  this.encsalidaSeleccionada.KAR_NRO === ''){
                    this.salidaService.totalSalidaarray[this.indicador]=Number(0).toFixed(0)
                  }
                let totalvar: number;
                totalvar = Number(this.calcularTotalVarios());
                if (Number(this.salidaService.totalSalidaarray[this.indicador]) !== totalvar) {
                  this.messageService.add({
                    key: 'sal',
                    severity: 'error',
                    summary: 'Generar asiento',
                    detail: 'El total de varios es diferente al total del documento.' 
                     
                  });
                } else {
                  this.mostrarSalida(valor);
                }
                }else{
                  this.mostrarSalida(valor);
                }
                //CDPJ
            }
          });
        } else {
          if(this.modulo !== 'PROD'){
            let totalvar: number;
            if(this.encsalidaSeleccionada.KAR_NRO === undefined || this.encsalidaSeleccionada.KAR_NRO === null||
              this.encsalidaSeleccionada.KAR_NRO === ''){
                this.salidaService.totalSalidaarray[this.indicador]=Number(0).toFixed(0)
              }
            totalvar = Number(this.calcularTotalVarios());
            if (Number(this.salidaService.totalSalidaarray[this.indicador]) !== totalvar) {
              this.messageService.add({
                key: 'sal',
                severity: 'error',
                summary: 'Generar asiento',
                detail: 'El total de varios es diferente al total del documento.'
                 
              });
            } else {
              this.mostrarSalida(valor);
            }
          }else{
            this.mostrarSalida(valor);
          }
          
          // this.mostrarSalida(valor);
        }
      }
    }

    if (valor === 'Asiento') {
      if(this.modulo !== 'PROD'){
        let totalvar: number;
        totalvar = Number(this.calcularTotalVarios());
        if (Number(this.salidaService.totalSalidaarray[this.indicador]) !== totalvar) {
          this.messageService.add({
            key: 'sal',
            severity: 'error',
            summary: 'Generar asiento',
            detail: 'El total de varios es diferente al total del documento.' +
              'No se puede generar el Asiento contable'
          });
        } else {
          await this.generarAsiento();
        }
      }else{
        await this.generarAsientoProd();
      }
    }

    if (valor === 'AsientoVer') {
      //if(this.modulo !== 'PROD'){
      this.salidaService.getAsiento(this.encsalidaSeleccionada).subscribe((res) => {
        this.asiento = res[0].ASI_NRO;
        if (this.asiento === null) {
          this.asiento = '';
        }
        if (this.asiento === '') {
          this.messageService.add({
            key: 'sal',
            severity: 'error',
            summary: 'Visualizacion de Asiento Contable',
            detail: 'Esta salida no tiene un asiento contable'
          });
        } else {
          this.auxiliar++;
          // this.displayDialogAsiento = true;
          this.displayAsiContable = true;
        }
      });
     //}
    }

    if (valor === 'Salir') {
      if (await this.ValidarSeriales(this.encsalidaSeleccionada.KAR_NRO)) {
        if (!this.existeAsiento()) {
        } else {
          //CDPJ
          if(this.modulo !== 'PROD'){
            if(this.encsalidaSeleccionada.KAR_NRO === undefined || this.encsalidaSeleccionada.KAR_NRO === null||
              this.encsalidaSeleccionada.KAR_NRO === ''){
                this.salidaService.totalSalidaarray[this.indicador]=Number(0).toFixed(0)
              }
            let totalvar: number;
            totalvar = Number(this.calcularTotalVarios());
            if (Number(this.salidaService.totalSalidaarray[this.indicador]) !== totalvar) {
              this.messageService.add({
                key: 'sal',
                severity: 'error',
                summary: 'Generar asiento',
                detail: 'El total de varios es diferente al total del documento.'
                  
              });
            } else {
              this.init.tabs.splice(this.init.tabindex, 1);
            }
            }else{
              this.init.tabs.splice(this.init.tabindex, 1);
            }
          //this.init.tabs.splice(this.init.tabindex, 1);
          //CDPJ
        }
      }
    }

    if (valor === 'Almacenardoc') {
      if (this.encsalidaSeleccionada.KAR_NRO !== '') {
        const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.messageService.add({
            key: 'sal',
            severity: 'info',
            summary: 'Información',
            detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
          });
        }

      }
    }

    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }


  }

  realizarNuevo() {
    if (this.activeIndex === 0) {
      this.limpiardatos();
      this.nuevoEncSalida();
    } else {
      this.limpiardatos();
      this.activeIndex = 1;
      this.nuevoEncSalida();
      this.desactivarBotonesNuevo();
      this.panelSalidas = true;
      this.botonNuevoPV = false;
      this.botonBorrarPV = false;
      this.botonRegresarPV = false;
      
    }
  }

  nuevoEncSalida() {
    this.desactivarBotonesNuevo();
    //this.panelVarios = true;
    this.inputsboolean = false;
    this.botonNuevoP = false;
    this.botonBorrarP = false;
    let encSalida: Invencsalida;
    encSalida = {
      KAR_NRO: '',
      KAR_TIPO: 'SA',
      KAR_NROCOMPROBANTE: '',
      COM_CODIGO: '01',
      TIPDIA_CODIGO: 'SA',
      USUIDENTIFICACION: 'admin',
      KAR_NO_KARDEX: '0',
      KAR_SALDOINI: '',
      KAR_ESTADO: '',
      ENCORDPRO_NUMERO: '',
      KAR_COMENTARIO: '',
      KAR_FECHA: new Date()
    };
    this.encSalidas.push(encSalida);
    this.encsalidaSeleccionada = this.encSalidas[this.encSalidas.length - 1];
    this.salidaService.detkardexsarray[this.indicador] = [];
    this.salidaService.detkardexsvararray[this.indicador] = [];
    this.salidaService.calcularTotal();
    document.getElementById('nroComp').focus();
  }

  desactivarBotonesNuevo() {
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = false;
    this.botonPrimero = true;
    this.botonAnterior = true;
    this.botonSiguiente = true;
    this.botonUltimo = true;
    this.barraBotones2 = true;
    this.barraBotones4 = false;
  }

  async realizarGuardar() {
    //CDPJ
    let errores=0;
    this.spin=true//CDPJ
    const ccpies=this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CCPIES').CFG_VALOR1;
    this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefSalida.objeto].setFocusedCell(this.salidaService.detkardexsarray[this.indicador].length - 1, 'DETKAR_COSTOUNIT');
    this.aggrid.refreshaggrid(this.salidaService.detkardexsarray[this.indicador], this.defaultColDefSalida.objeto);
    //CDPJ
    if (this.encsalidaSeleccionada.KAR_NROCOMPROBANTE !== '') {
      await Promise.all(this.salidaService.detkardexsarray[this.indicador].map( async(entrada) => {
        const rsdata: any = await this.salidaService.encontrarArtCntaySer(entrada.ART_CODIGO, 'A');
        const rs = await this.salidaService.erBodCodigo1(entrada.BOD_CODIGO);
        let strCodigo = 0;
                if (rsdata !== null) {
                  for (const rs of rsdata) {
                    strCodigo = rs.NUMERO;
                  }
                }
        if (ccpies === 1){
          if(entrada.CEN_CODIGO ===''){
            this.messageService.add({
              key: 'sal',
              severity: 'error',
              summary: 'Guardar Entrada',
              detail: 'Ingrese el centro de costos'
            });
            this.spin=false//CDPJ
            errores=errores+1;
          }
          
        }
        if(entrada.ART_CODIGO === ''){
          this.messageService.add({
            key: 'sal',
            severity: 'error',
            summary: 'Guardar Entrada',
            detail: 'Ingrese el código del artículo'
          });
          this.spin=false//CDPJ
          errores=errores+1;
        }
//CDPJ
if(strCodigo === 0){//CDPJ
  this.messageService.add({
    key: 'sal',
    severity: 'error',
    summary: 'Información',
    detail: 'El artículo '+ entrada.ART_CODIGO +' no existe'
  });
  this.spin=false//CDPJ
  errores++;
}
if (entrada.BOD_CODIGO === null || entrada.BOD_CODIGO === '' || entrada.BOD_CODIGO === undefined) {
  this.messageService.add({
    key: 'sal',
    severity: 'warn',
    summary: 'Información',
    detail: 'Error, Código de la bodega está vacío'
  });
  this.spin=false//CDPJ
  errores++;
}
if (entrada.BOD_CODIGO !== null && entrada.BOD_CODIGO !== '' && entrada.BOD_CODIGO !== undefined) {
  if (rs === null || rs === undefined || rs === '') {
    this.messageService.add({
      key: 'sal',
      severity: 'warn',
      summary: 'Información',
      detail: 'Error, Código de la bodega ' + entrada.BOD_CODIGO + 'es incorrecto.'
    });
    this.spin=false//CDPJ
    errores++;
  }
}
      }));
      console.log(errores)
      if(errores === 0){
      if (this.salidaSeleccionada.BOD_CODIGO === undefined) {
        this.salidaService.detkardexsarray[this.indicador] = this.salidaService.detkardexsarray[this.indicador].filter((val, j) => j !==
          this.salidaService.detkardexsarray[this.indicador].indexOf(this.salidaSeleccionada));
        this.botonNuevoP = false;
        if (this.salidaService.detkardexsarray[this.indicador].length !== 0) {
          this.verificarCantidadDisponible();
        } else {
        }
      } else {
        this.verificarCantidadDisponible();
      }
    }
    } else {
      this.messageService.add({
        key: 'sal',
        severity: 'error',
        summary: 'Error',
        detail: 'El número de comprobante es obligatorio',
      });
      this.spin=false//CDPJ
    }
  }

  calcularTotalVarios() {
    let total: number;
    total = 0;
    for (const e of this.salidaService.detkardexsvararray[this.indicador]) {
      total = Number(total) + Number(e.DETKARDEXVAR_IMPORTE);
    }
    return (total.toFixed(this.decimalesTotalSal));
  }

  mostrarSalida(valor) {
    if (valor === 'Primero') {
      this.contadorAsiento = 1;
      if (this.botonUltimo === true) {
        this.botonUltimo = !this.botonUltimo;
      }
      if (this.botonSiguiente === true) {
        this.botonSiguiente = !this.botonSiguiente;
      }
      this.botonPrimero = true;
      this.botonAnterior = true;
      this.inputsboolean = false;
      this.botonNuevoP = false;
      this.botonNuevoPV = false;
      this.botonRegresarP = true;
      this.botonRegresarPV = true; 
      if (this.activeIndex === 1) {
        this.activeIndex = 0;
      } 
      this.encsalidaSeleccionada = this.encSalidas[0];
      if (this.encsalidaSeleccionada.KAR_SALDOINI !== 'S') {
        this.checkSaldoI = false;
      }
      this.indice = 0;
      this.mostrarDatos(this.encsalidaSeleccionada);
    }

    if (valor === 'Ultimo') {
      this.contadorAsiento = 1;
      if (this.botonPrimero === true) {
        this.botonPrimero = !this.botonPrimero;
      }
      if (this.botonAnterior === true) {
        this.botonAnterior = !this.botonAnterior;
      }
      this.botonSiguiente = true;
      this.botonUltimo = true;
      this.inputsboolean = false; // para habilitar algunos inputs
      if (this.activeIndex === 1) {
        this.activeIndex = 0; // regresa de nuevo a la pestaña de salidas del tabPanel
      }
      this.indice = this.encSalidas.length - 1;
      if (this.encsalidaSeleccionada.KAR_SALDOINI !== 'S') {
        this.checkSaldoI = false;
      }
      this.salidaService.getEncSalidas().subscribe((res) => {
        this.encSalidas = res;
      });
      this.encsalidaSeleccionada = this.encSalidas[this.indice];
      this.mostrarDatos(this.encsalidaSeleccionada);
    }

    if (valor === 'Anterior') {
      this.contadorAsiento = 1;
      this.inputsboolean = false;
      if (this.activeIndex === 1) {
        this.activeIndex = 0;
      }
      this.indice -= 1;
      this.encsalidaSeleccionada = this.encSalidas[this.indice];
      if (this.encsalidaSeleccionada.KAR_SALDOINI !== 'S') {
        this.checkSaldoI = false;
      }
      if (this.botonUltimo === true && this.botonSiguiente === true) {
        this.botonUltimo = false;
        this.botonSiguiente = false;
      }
      if (this.indice <= 0) {
        this.botonPrimero = true;
        this.botonAnterior = true;
        if (this.indice === 0) {
          this.mostrarDatos(this.encsalidaSeleccionada);
        } else {
          return;
        }
      } else {
        this.mostrarDatos(this.encsalidaSeleccionada);
      }
    }

    if (valor === 'Siguiente') {
      this.contadorAsiento = 1;
      this.inputsboolean = false;
      if (this.activeIndex === 1) {
        this.activeIndex = 0;
      }
      this.indice++;
      this.encsalidaSeleccionada = this.encSalidas[this.indice];
      if (this.encsalidaSeleccionada.KAR_SALDOINI !== 'S') {
        this.checkSaldoI = false;
      }
      if (this.botonPrimero === true && this.botonAnterior === true) {
        this.botonPrimero = false;
        this.botonAnterior = false;
      }
      if (this.indice >= this.encSalidas.length - 1) {
        this.botonUltimo = true;
        this.botonSiguiente = true;
        if (this.indice === this.encSalidas.length - 1) {
          this.mostrarDatos(this.encsalidaSeleccionada);
        } else {
          return;
        }
      } else {
        this.mostrarDatos(this.encsalidaSeleccionada);
      }
    } else if (valor === 'Nuevo') {
      this.contadorAsiento = 1;
      this.realizarNuevo();
    } else if (valor === 'Cancelar') {
      this.botonNuevo = false;
      // console.log(this.encsalidaSeleccionada);
      if (this.encsalidaSeleccionada.KAR_NRO === '' || this.encsalidaSeleccionada.KAR_NRO === undefined) {
        if (this.activeIndex === 1) {
          this.limpiardatos();
          this.activeIndex = 1;
        } else {
          this.limpiardatos();
        }
      } else {
        this.salidaService.getEncSalidas().subscribe((res) => {
          this.encSalidas = res;
          for (const a in this.encSalidas) {
            if (this.encSalidas[a].KAR_NRO === this.encsalidaSeleccionada.KAR_NRO) {
              this.encsalidaSeleccionadaAux = this.encSalidas[a];
            }
          }
          this.salidaService.date1 = new Date(this.encsalidaSeleccionadaAux.KAR_FECHA);
          this.salidaService.hora = new Date(this.encsalidaSeleccionadaAux.KAR_FECHA);
          this.salidaService.hora.setHours(this.encsalidaSeleccionadaAux.KAR_HORA.substr(0, 2));
          this.salidaService.hora.setMinutes(this.encsalidaSeleccionadaAux.KAR_HORA.substr(-1, 2));
          this.encsalidaSeleccionada = this.encsalidaSeleccionadaAux;
          this.enviarCancelarSalida();
        });
      }
    }
  }

  manejarSeleccion(opcion) {
    //GSRF
    if (this.opcion === 'orden') {
      this.encsalidaSeleccionada.ENCORDPRO_NUMERO =opcion.ENCORDPRO_NUMERO
      this.displayDialogBusFast = false;
    }

    //GSRF
    if (this.opcion === 'BUSQUEDA') {
      if (this.activeIndex === 1) {
        this.activeIndex = 0;
      }
      this.inputsboolean = false;
      this.encsalidaSeleccionada = opcion;
      if (this.encsalidaSeleccionada.KAR_SALDOINI !== 'S') {
        this.checkSaldoI = false;
      }
      this.encSalidas.map((salid) => {
        if (salid.KAR_NRO === this.encsalidaSeleccionada.KAR_NRO) {
          this.encsalidaSeleccionada = salid;
        }
      });
      this.indice = this.encSalidas.indexOf(this.encsalidaSeleccionada);
      this.mostrarDatos(this.encsalidaSeleccionada);
      this.displayDialogBusFast = false;//GSRF
    } else if (this.opcion === 'CSA') {
      this.salidaService.detkardexsvararray[this.indicador][this.salidaService.detkardexsvararray[this.indicador].indexOf(this.salidavarSeleccionada)].CEN_CODIGO = opcion.CEN_CODIGO;
    } else if (this.opcion === 'CON') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'sal',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.salidaService.detkardexsvararray[this.indicador][this.salidaService.detkardexsvararray[this.indicador].indexOf(this.salidavarSeleccionada)].CON_CODIGO =
          opcion.CON_CODIGO;
        this.salidaService.detkardexsvararray[this.indicador][this.salidaService.detkardexsvararray[this.indicador].indexOf(this.salidavarSeleccionada)].CON_NOMBRE =
          opcion.CON_NOMBRE;
        this.botonNuevoPV = false;
        this.botonBorrarPV = false;
        this.botonRegresarPV = false;
      }
    }
    this.displayDialogBus = false;
  }

  mostrarDatos(dato) {
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
    this.barraBotones2 = false;
    this.barraBotones4 = false;
    //CDPJ
    this.botonNuevoP = false;
    this.botonBorrarP = false;
    this.botonRegresarP = false;
    this.botonNuevoPV = false;
    this.botonNuevoPV = false;
    this.botonRegresarPV = true;
    let fecha = '';
    let hora = '';
    if (dato.KAR_FECHA === undefined) {
      return;
    }
    this.salidaService.date1 = new Date(dato.KAR_FECHA);
    this.salidaService.hora = new Date(dato.KAR_FECHA);
    if (dato.KAR_HORA.toString().length === 4) {
      const separador = ':';
      const arregloHora = dato.KAR_HORA.toString().split(separador);
      console.log(arregloHora);
      for (let i = 0; i < arregloHora.length; i++) {
        if (arregloHora[i].length !== 2) {
          arregloHora[i] = '0' + arregloHora[i];
        }
      }
      const horaDefinitiva = arregloHora.join(':');
      console.log(horaDefinitiva);
      dato.KAR_HORA = horaDefinitiva;
    } else if (dato.KAR_HORA.toString().length === 3) {
      dato.KAR_HORA = '0' + dato.KAR_HORA.toString();
      dato.KAR_HORA = dato.KAR_HORA.toString().substr(0, 2) + '0'
        + dato.KAR_HORA.toString().substr(3, 1);
    }
    this.salidaService.hora.setHours(dato.KAR_HORA.substr(0, 2));
    this.salidaService.hora.setMinutes(dato.KAR_HORA.substr(3, 2));

    this.salidaService.consultarDetKardex(dato.KAR_NRO).subscribe((res) => {
      this.salidaService.detkardexsarray[this.indicador] = res;
      this.salidaService.detkardexsarray[this.indicador].map((salida) => {
        salida.DETKAR_COSTOUNIT = salida.DETKAR_COSTOUNIT.toFixed(this.decimalesPrecioSal);
        salida.DETKAR_CANTIDAD = salida.DETKAR_CANTIDAD.toFixed(this.decimalesCantidadSal);
        salida.CEN_CODIGO= salida.CEN_CODIGO === null || salida.CEN_CODIGO === undefined ? '' : salida.CEN_CODIGO;//CDPJ
      });
      // colocar en cada articulo su cantidad guardada y existente
      fecha = this.datePipe.transform(this.salidaService.date1, 'dd/MM/yyyy');
      hora  = this.datePipe.transform(this.salidaService.hora, 'HH:mm');
      for (const i of this.salidaService.detkardexsarray[this.indicador]) {
        this.salidaService.getcantidadGrabadaDoc(dato.KAR_NRO, i.ART_CODIGO).subscribe((res2) => {
          i.CANTIDAD_GUARDADA = res2[0].CANTGRABADA;
          this.salidaService.getExistenciaArt('FAC', i.ART_CODIGO, i.BOD_CODIGO,fecha,hora).subscribe((res3) => {
            i.CANTIDAD_DISPONIBLE = res3[0].ARTEXISTENCIA;
          });
        });
      }
      this.salidaService.calcularTotal();
      this.salidaService.getSalidasVar(dato.KAR_NRO).subscribe((resp) => {
        this.salidaService.detkardexsvararray[this.indicador] = resp;
        this.contadorLineaVar = resp.length;
        this.salidaService.detkardexsvararray[this.indicador].map((salvar) => {
          salvar.DETKARDEXVAR_IMPORTE = salvar.DETKARDEXVAR_IMPORTE.toFixed(2);
        });
      });
    });
  }

  enviarNuevoSalida() {
//CDPJ
this.botonNuevoP = true;
this.botonRegresarP = false;
    let hora;
    let minutos;
    hora = this.salidaService.hora.getHours();
    minutos = this.salidaService.hora.getMinutes();
    if (hora.toString().length === 1) {
      hora = '0' + hora;
    }
    if (minutos.toString().length === 1) {
      minutos = '0' + minutos;
    }
    const hour = hora + ':' + minutos;
    const date = this.datePipe.transform(this.salidaService.date1, 'dd/MM/yyyy');
    let i=this.salidaService.detkardexsarray[this.indicador].length+1;//CDPJ

    const numeroInicial = 1;
    const nuevaSalida = {
      CLAVE: i+this.datePipe.transform(new Date(), 'dd/MM/yyyyHHmmss'),//CDPJ
      // BOD_CODIGO: '',
      ART_CODIGO: '',
      ART_NOMBRE: '',
      KAR_NRO: this.encsalidaSeleccionada.KAR_NRO,
      DETKAR_CANTIDAD: numeroInicial.toFixed(this.decimalesCantidadSal),
      DETKAR_COSTOUNIT: numeroInicial.toFixed(this.decimalesPrecioSal),
      DETKAR_COSTOTOTAL: numeroInicial.toFixed(this.decimalesTotalSal),
      CANTIDAD_DISPONIBLE: 0,
      DETKAR_NRODOC: '',
      CON_CODIGO: '',
      DETKAR_LINEA: 0,
      ENCGRE_CODIGO: '',
      DETGRE_LINEA: '0',
      DETKAR_LOTE: '',
      CLI_CODIGO: '',
      DETKAR_PUNTOS: '0',
      MAEPUN_CODIGO: '',
      DETKAR_CODIGOAUX: '',
      DETKAR_CAJAS: '',
      DETKAR_FRACCIONES: '',
      CANTIDAD_GUARDADA: 0, FECHA: date + '' + hour, HORA: hour,
      CEN_CODIGO:''//CDPJ

    };
    const indi = this.salidaService.detkardexsarray[this.indicador].indexOf(this.salidaSeleccionada);
    if (indi === -1) {
      this.salidaService.detkardexsarray[this.indicador].push(nuevaSalida);
      this.aggrid.refreshaggrid(this.salidaService.detkardexsarray[this.indicador], this.defaultColDefSalida.objeto);
      this.salidaService.calcularTotal();
    } else {
      this.salidaService.detkardexsarray[this.indicador].splice(indi + 1, 0, nuevaSalida);
      this.aggrid.refreshaggridindex(this.salidaService.detkardexsarray[this.indicador], this.defaultColDefSalida.objeto, indi);
      this.salidaService.calcularTotal();
    }
  }

  enviarBorrarSalida() {
    if (this.salidaSeleccionada === undefined) {
      this.salidaService.borrarSalida(this.salidaService.detkardexsarray[this.indicador][this.salidaService.detkardexsarray[this.indicador].length - 1]).subscribe(() => {
        this.messageService.add({
          key: 'sal',
          severity: 'success',
          summary: 'Eliminación Salida',
          detail: 'La salida seleccionada se eliminó correctamente'
        });
        this.encSalidas = this.encSalidas.filter((val, j) => j !== this.encSalidas.indexOf(this.encsalidaSeleccionada));
        //CDPJ
        this.salidaService.calcularTotal();
      }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'sal',
          severity: 'error',
          summary: 'Eliminación de Salida',
          detail: mensaje
        });
      });
      return;
    }
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
    this.botonNuevoP = false;
    this.botonBorrarP = false;
    let contador = 0;
    let i = 0;
    do {
      if (this.salidaSeleccionada === this.salidaService.detkardexsarray[this.indicador][i]) {
        contador++;
      }
      i++;
    } while (this.salidaService.detkardexsarray[this.indicador].length > i);
    if (contador === 1) {
      this.salidaService.borrarSalida(this.salidaSeleccionada).subscribe(() => {
        this.messageService.add({
          key: 'sal',
          severity: 'success',
          summary: 'Eliminación de Salida',
          detail: 'La salida se eliminó correctamente'
        });
        this.auditoriagral.registrarAuditoria('INV_DETKARDEX', this.salidaSeleccionada.ART_CODIGO, 'E',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });
        this.salidaService.detkardexsarray[this.indicador] = this.salidaService.detkardexsarray[this.indicador].filter((val, j) => j !==
          this.salidaService.detkardexsarray[this.indicador].indexOf(this.salidaSeleccionada));
        //CDPJ
        this.salidaService.calcularTotal();
        this.botonNuevoP=false;
      }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'sal',
          severity: 'error',
          summary: 'Eliminación de Salida',
          detail: mensaje
        });
      });
    } else if (contador !== 1) {
      this.salidaService.detkardexsarray[this.indicador] = this.salidaService.detkardexsarray[this.indicador].filter((val, j) => j !==
        this.salidaService.detkardexsarray[this.indicador].indexOf(this.salidaSeleccionada));
    }
  }

  cambio() {
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = false;
    this.botonUltimo = true;
    this.botonAnterior = true;
    this.botonSiguiente = true;
    this.botonPrimero = true;
    //CDPJ
    this.botonNuevoP = false;
    if (this.activeIndex === 0) {
      this.panelVarios = true;
    } else {
      this.panelSalidas = true;
    }
  }

  async guardar() {
    if (this.activeIndex === 0) {
      await this.guardarSalida();
      
     
    } else if (this.activeIndex === 1) {
      await this.guardarSalida();
      
      
    } else {
      return;
    }
  }

  obtenerCantGuaradadoDisponible(salida, nroDocumento) {
    this.salidaService.getcantidadGrabadaDoc(nroDocumento, salida.ART_CODIGO).subscribe((res3) => {
      salida.CANTIDAD_GUARDADA = Number(res3[0].CANTGRABADA);
      this.salidaService.getExistenciaArt('FAC', salida.ART_CODIGO, salida.BOD_CODIGO,this.encsalidaSeleccionada.KAR_FECHA,this.encsalidaSeleccionada.KAR_HORA).subscribe((res4) => {
        salida.CANTIDAD_DISPONIBLE = res4[0].ARTEXISTENCIA;
      });
    });
  }

  deshabilitarBotonesGuardar() {
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
    this.botonPrimero = false;
    this.botonUltimo = false;
    this.botonSiguiente = false;
    this.botonAnterior = false;
    this.barraBotones2 = false;
    this.botonNuevoP = false;
    this.botonBorrarP = false;
    this.botonRegresarP = true;
    this.botonNuevoPV = false;
    this.botonNuevoPV = false;
    this.botonRegresarPV = true;
  }

  verificarBorrarLinea() {
    if (this.salidaSeleccionada.KAR_NRO === undefined) {
      this.messageService.add({
        key: 'sal',
        severity: 'info',
        summary: 'Información',
        detail: 'Seleccione la fila que desea eliminar',
      });
    } else {
      this.confirmarBorrarT('registro');
    }
  }

  verificarBorrarLineaVar() {
    if (this.salidavarSeleccionada.KAR_NRO === undefined) {
      this.messageService.add({
        key: 'sal',
        severity: 'info',
        summary: 'Información',
        detail: 'Seleccione la fila que desea eliminar',
      });
    } else {
      this.confirmarBorrarT('registroV');
    }
  }

  confirmarBorrarT(valor: string) {
    let mensaje: string;
    let metodo: number;
    let llave: string;
    switch (valor) {
      case 'registro':
        mensaje = 'Está seguro de eliminar el Registro?';
        metodo = 0;
        llave = 'confirmarSal';
        break;
      case 'registroV':
        mensaje = 'Está seguro de eliminar el Registro?';
        metodo = 1;
        llave = 'confirmarSal';
        break;
      case 'documento':
        mensaje = 'Está seguro de eliminar el Documento?';
        metodo = 2;
        llave = 'confirmarSal';
        break;
      case 'asiento':
        mensaje = 'El documento no tiene Asiento Contable. Desea eliminar el documento?';
        metodo = 3;
        llave = 'confSinAsiento';
        break;
      case 'cambio':
        mensaje = 'Existen cambios, desea guardarlos?';
        metodo = 4;
        llave = 'confirmarSal';
    }
    this.confirmationService.confirm({
      message: mensaje,
      header: 'Información',
      icon: 'pi pi-question',
      key: llave,
      accept: () => {
        if (metodo === 0) {
          this.enviarBorrarSalida();
          return;
        } else if (metodo === 1) {
          this.enviarBorrarSalidaVar();
          return;
        } else if (metodo === 2) {
          this.borrarEncSalida();
          return;
        } else if (metodo === 3) {
          this.confirmarBorrarT('documento');
          return;
        } else if (metodo === 4) {
          this.manejarSenales('Guardar');
          return;
        }
      },
      reject: () => {
        if (metodo === 4) {
          this.verificarAsiento();
        }
      }
    });
  }

  verificarAsiento() {
    this.salidaService.getAsiento(this.encsalidaSeleccionada).subscribe((res) => {
      this.asiento = res[0].ASI_NRO;
      if (this.asiento === null) {
        this.asiento = '';
      }
      if (this.asiento === '') {
        this.confirmarBorrarT('asiento');
      }
    });
  }

  borrarEncSalida() {
    this.salidaService.borrarEncSalida(this.encsalidaSeleccionada).subscribe(() => {
      this.auditoriagral.registrarAuditoria('INV_ENCKARDEX', this.encsalidaSeleccionada.KAR_NRO, 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
      let numero;
      if (this.encsalidaSeleccionada.ASI_NRO !== undefined || this.encsalidaSeleccionada.ASI_NRO !== '') {
        numero = this.encsalidaSeleccionada.ASI_NRO;
        if (numero !== undefined || numero !== '') {
          this.salidaService.eliminarAsientoCont(numero).subscribe(() => {
          });
        }
      }
      this.messageService.add({
        key: 'sal',
        severity: 'success',
        summary: 'Eliminación de Salida',
        detail: 'Se eliminó la salida correctamente'
      });
      this.salidaSeleccionada = {};
      this.salidaService.date1 = new Date();
      this.salidaService.hora = new Date();
      this.salidaService.calcularTotal();
      this.limpiardatos();
    }, error1 => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'sal',
        severity: 'error',
        summary: 'Eliminicación de Salida',
        detail: mensaje
      });
    });
  }

  enviarCancelarSalida() {
    this.salidaService.consultarDetKardex(this.encsalidaSeleccionada.KAR_NRO).subscribe((res) => {
      this.botonRegresarP = true;
      this.botonNuevoP = false;
      this.botonBorrarP = false;
      this.salidaService.detkardexsarray[this.indicador] = res;
      if (this.salidaService.detkardexsarray[this.indicador].length === 0) {
        this.salidaSeleccionada = {};
      } else {
        this.salidaSeleccionada = this.salidaService.detkardexsarray[this.indicador][this.salidaService.detkardexsarray[this.indicador].length - 1];
      }
      let hora;
      let minutos;
      hora = this.salidaService.hora.getHours();
      minutos = this.salidaService.hora.getMinutes();
      if (hora.toString().length === 1) {
        hora = '0' + hora;
      }
      if (minutos.toString().length === 1) {
        minutos = '0' + minutos;
      }
      const hour = hora + ':' + minutos;
      const date = this.datePipe.transform(this.salidaService.date1, 'dd/MM/yyyy');
      let i = 0;
      this.salidaService.detkardexsarray[this.indicador].map((salida) => {
        this.salidaService.getArticulo(salida.ART_CODIGO).subscribe((res1) => {
          salida.ART_NOMBRE = res1[0].ART_NOMBRE;
        });
        i++;
        salida.FECHA = date + '' + hour;
        salida.HORA = hour;
        salida.CLAVE = i + this.datePipe.transform(new Date(), 'dd/MM/yyyyHHmmss');
        salida.CEN_CODIGO= salida.CEN_CODIGO === null || salida.CEN_CODIGO === undefined ? '' : salida.CEN_CODIGO;//CDPJ
      });

      this.salidaService.calcularTotal();
    });
  }

  enviarCancelarSalidaVarios() {
    this.salidaService.getSalidasVar(this.encsalidaSeleccionada.KAR_NRO).subscribe((resp) => {
      this.botonRegresarPV = false;
      this.botonNuevoPV = false;
      this.botonBorrarPV = false;
      this.salidaService.detkardexsvararray[this.indicador] = resp;
      if (this.salidaService.detkardexsvararray[this.indicador].length === 0) {
        this.salidavarSeleccionada = {};
      } else {
        this.salidavarSeleccionada = this.salidaService.detkardexsvararray[this.indicador][this.salidaService.detkardexsvararray[this.indicador].length - 1];
      }
    });
  }

  enviarNuevoVario() {
    this.saldoTotalVarios = this.totalSalida;
    this.contadorLineaVar = this.salidaService.detkardexsvararray[this.indicador].length + 1;
    // this.contadorLineaVar += 1;
    // this.lineakardexVarios += 1;
    if (this.contadorLineaVar !== 1) {
      let totalActual: number;
      totalActual = 0;
      for (const e of this.salidaService.detkardexsvararray[this.indicador]) {
        totalActual = totalActual + Number(e.DETKARDEXVAR_IMPORTE);
      }
      this.saldoTotalVarios = this.totalSalida - totalActual;
    }
    let i = this.salidaService.detkardexsvararray[this.indicador].length + 1;//CDPJ
    const nuevaSalidaVar = {
      CLAVE: i+ '' + this.datePipe.transform(new Date(), 'dd/MM/yyyyHHmmss'),//CDPJ
      DETKARDEXVAR_IMPORTE: this.calcularSaldoImporteVarios().toFixed(2),
      DETKARDEXVAR_LINEA: this.contadorLineaVar,
      KAR_NRO: this.encsalidaSeleccionada.KAR_NRO,
      CON_NOMBRE: '',
      CEN_CODIGO: '',
      CON_CODIGO: '',
      COM_CODIGO: ''
    };
    const indi = this.salidaService.detkardexsvararray[this.indicador].indexOf(this.salidavarSeleccionada);
    if (indi === -1) {
      this.salidaService.detkardexsvararray[this.indicador].push(nuevaSalidaVar);
      this.salidavarSeleccionada = this.salidaService.detkardexsvararray[this.indicador][this.salidaService.detkardexsvararray[this.indicador].length - 1];
      this.aggrid.refreshaggrid(this.salidaService.detkardexsvararray[this.indicador], this.defaultColDefSalidaVar.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefSalidaVar.objeto].setFocusedCell(this.salidaService.detkardexsvararray[this.indicador].length - 1, 'CON_CODIGO');
    } else {
      this.salidaService.detkardexsvararray[this.indicador].splice(indi + 1, 0, nuevaSalidaVar);
      this.salidavarSeleccionada=nuevaSalidaVar;
      this.aggrid.refreshaggridindex(this.salidaService.detkardexsvararray[this.indicador], this.defaultColDefSalidaVar.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefSalidaVar.objeto].setFocusedCell(indi + 1, 'CON_CODIGO');
    }
  }

  async generarAsiento() {
    try {
      await this.salidaService.generarAsientoProm(this.encsalidaSeleccionada);
      const resp = await this.salidaService.getAsientoProm(this.encsalidaSeleccionada);
      this.encsalidaSeleccionada.ASI_NRO = resp[0].ASI_NRO;
      this.messageService.add({
        key: 'sal',
        summary: 'Insertar Asiento Contable',
        severity: 'success',
        detail: 'El Asiento contable se genero correctamente'
      });
      this.spin=false//CDPJ
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(err.error.substr(0, 9));
      this.messageService.add({
        key: 'sal',
        severity: 'error',
        summary: 'Inserción de Asiento Contable',
        detail: mensaje
      });
      this.spin=false//CDPJ
    }

    // ANTIGUO
    /*this.salidaService.generarAsiento(this.encsalidaSeleccionada).subscribe(() => {
        this.salidaService.getAsiento(this.encsalidaSeleccionada).subscribe((resp) => {
          this.encsalidaSeleccionada.ASI_NRO = resp[0].ASI_NRO;
        });
        // console.log(res);
        this.messageService.add({
          key: 'sal',
          summary: 'Insertar Asiento Contable',
          severity: 'success',
          detail: 'El Asiento contable se genero correctamente'
        });
      },
      error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'sal',
          severity: 'error',
          summary: 'Inserción de Asiento Contable',
          detail: mensaje
        });
      }); */
  }
//CDPJ
async generarAsientoProd(){
  try {
    await this.salidaService.generarAsientoProdProm(this.encsalidaSeleccionada,this.usuario.identificacion);
    const resp = await this.salidaService.getAsientoProm(this.encsalidaSeleccionada);

    this.encsalidaSeleccionada.ASI_NRO = resp[0].ASI_NRO;
    this.messageService.add({
      key: 'sal',
      summary: 'Insertar Asiento Contable',
      severity: 'success',
      detail: 'El Asiento contable se genero correctamente'
    });
    this.spin=false//CDPJ

  } catch (err) {
    let mensaje: string;
    mensaje = this.errorService.generarMensajeError(err.error.substr(0, 9));
    this.messageService.add({
      key: 'sal',
      severity: 'error',
      summary: 'Inserción de Asiento Contable',
      detail: mensaje
    });
    this.spin=false//CDPJ
  }
}
//CDPJ
  enviarBorrarSalidaVar() {
    if (this.salidavarSeleccionada !== undefined) {
        if (this.salidavarSeleccionada.CLAVE === null || this.salidavarSeleccionada.CLAVE === undefined || this.salidavarSeleccionada.CLAVE === ''){
          this.salidaService.borrarSalidaVarios(this.salidavarSeleccionada).subscribe(() => {
            this.messageService.add({
              key: 'sal',
              severity: 'success',
              summary: 'Eliminación Salida',
              detail: 'La salida seleccionada se eliminó correctamente'
            });
            this.salidaService.detkardexsvararray[this.indicador] = this.salidaService.detkardexsvararray[this.indicador].filter((val, j) => j !==
              this.salidaService.detkardexsvararray[this.indicador].indexOf(this.salidaService.detkardexsvararray[this.indicador][this.salidaService.detkardexsvararray[this.indicador].length - 1]));
          this.calcularTotalVarios();
          this.auditoriagral.registrarAuditoria('INV_DETKARDEXVAR',this.salidavarSeleccionada.KAR_NRO+'/'+this.salidavarSeleccionada.CON_CODIGO, 'E',
                  '', '01', '', '', '', '').subscribe(() => {
                  });
            }, error1 => {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.messageService.add({
              key: 'sal',
              severity: 'error',
              summary: 'Eliminación de Salida',
              detail: mensaje,
            });
          });
        }else{
          this.salidaService.detkardexsvararray[this.indicador] = this.salidaService.detkardexsvararray[this.indicador].filter((val, j) => j !==
              this.salidaService.detkardexsvararray[this.indicador].indexOf(this.salidaService.detkardexsvararray[this.indicador][this.salidaService.detkardexsvararray[this.indicador].length - 1]));
        }
      
      return;
    }
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
    this.botonNuevoPV = false;
    this.botonBorrarPV = false;
    this.botonRegresarPV = true;
    let contador = 0;
    let i = 0;
    do {
      if (this.salidavarSeleccionada === this.salidaService.detkardexsvararray[this.indicador][i]) {
        contador++;
      }
      i++;
    } while (this.salidaService.detkardexsvararray[this.indicador].length > i);
    if (contador === 1) {
      this.salidaService.borrarSalidaVarios(this.salidavarSeleccionada).subscribe(() => {
        this.messageService.add({
          key: 'sal',
          severity: 'success',
          summary: 'Eliminación de Salida',
          detail: 'La salida se eliminó correctamente'
        });
        this.salidaService.detkardexsvararray[this.indicador] = this.salidaService.detkardexsvararray[this.indicador].filter((val, j) => j !==
          this.salidaService.detkardexsvararray[this.indicador].indexOf(this.salidavarSeleccionada));
          this.calcularTotalVarios();
        }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'sal',
          severity: 'error',
          summary: 'Eliminación de Salida',
          detail: mensaje
        });
      });
    } else if (contador !== 1) {
      this.salidaService.detkardexsvararray[this.indicador] = this.salidaService.detkardexsvararray[this.indicador].filter((val, j) => j !==
        this.salidaService.detkardexsvararray[this.indicador].indexOf(this.salidavarSeleccionada));
    }
  }

  // Verificar que las salidas sean menores a la existencia
  verificarCantidadDisponible() {
    for (const i of this.salidaService.detkardexsarray[this.indicador]) {
      if (i.DETKAR_LINEA === 0) {
        let cantidadTabla: number;
        let cantidadExistente: number;
        let cantidadGuardada: number;
        cantidadTabla = 0;
        cantidadGuardada = 0;
        cantidadExistente = 0;
        cantidadTabla = this.cantidadArticulosTabla(i.ART_CODIGO, i.BOD_CODIGO);//GSRF
        cantidadGuardada = i.CANTIDAD_GUARDADA;
        cantidadExistente = i.CANTIDAD_DISPONIBLE + cantidadGuardada;
        if (cantidadExistente < cantidadTabla) {
          this.messageService.add({
            key: 'sal',
            severity: 'error',
            summary: 'ingreso cantidad',
            detail: 'La existencia del Artículo ' + i.ART_CODIGO + ' es ' + cantidadExistente
              + '. La cantidad de la Salida no puede ser mayor a la Existencia...'
          });
          this.posibleGuardar = false;
          this.spin=false//CDPJ
          break;
        } else {
          this.posibleGuardar = true;
        }
      } else {
        let cantidadTabla: number;
        let cantidadExistente: number;
        let cantidadGuardada: number;
        cantidadTabla = 0;
        cantidadGuardada = 0;
        cantidadExistente = 0;
        cantidadTabla = this.cantidadArticulosTabla(i.ART_CODIGO, i.BOD_CODIGO);//GSRF
        cantidadGuardada = i.CANTIDAD_GUARDADA;
        cantidadExistente = i.CANTIDAD_DISPONIBLE + cantidadGuardada;
        if (cantidadExistente < cantidadTabla) {
          this.messageService.add({
            key: 'sal',
            severity: 'error',
            summary: 'ingreso cantidad',
            detail: 'La existencia del Artículo ' + i.ART_CODIGO + ' es ' + cantidadExistente
              + '. La cantidad de la Salida no puede ser mayor a la Existencia...'
          });
          this.posibleGuardar = false;
          this.spin=false//CDPJ
          break;
        } else {
          this.posibleGuardar = true;
        }
      }
    }
    if (this.posibleGuardar === true) {
      this.guardar();
    }
  }

  cantidadArticulosTabla(codArticulo, bodcodigo) {
    let cantidadT: number;
    cantidadT = 0;
    for (const i in this.salidaService.detkardexsarray[this.indicador]) {
      if (this.salidaService.detkardexsarray[this.indicador][i].ART_CODIGO === codArticulo &&
        this.salidaService.detkardexsarray[this.indicador][i].BOD_CODIGO === bodcodigo) {
        cantidadT = cantidadT + Number(this.salidaService.detkardexsarray[this.indicador][i].DETKAR_CANTIDAD);
      }
    }
    return cantidadT;
  }

  seleccionsalida(params) {
    if (params === 'cancelar') {
      //this.enviarCancelarSalida();
      return;
    }
    if (params === 'eliminar') {
      this.verificarBorrarLinea();
      return;
    }
    if (params === 'nuevo') {
      this.enviarNuevoSalida();
      return;
    }
    this.salidaSeleccionada = params.object;
    this.salidaService.auxsalto = false;
  }

  seleccionSalidaVar(params) {
    if (params === 'cancelar') {
      this.enviarCancelarSalidaVarios();
      return;
    }
    if (params === 'eliminar') {
      this.verificarBorrarLineaVar();
      return;
    }
    if (params === 'nuevo') {
      this.enviarNuevoVario();
      this.botonGuardar = false;
      return;
    }
    this.salidavarSeleccionada = params.object;
    this.salidaService.auxsalto = false;
  }

  async guardarvarios() {
    //GSRF
    for (const detentrada of this.salidaService.detkardexsvararray[this.indicador]) {
      console.log('/-/-/*-*-',detentrada.CON_CODIGO)
      if (detentrada.CON_CODIGO === '') {
          this.messageService.add({
            key: 'sal',
            severity: 'error',
            summary: 'Cuenta Contable',
            detail: 'Ingrese una cuenta contable'
          });
          this.spin=false//CDPJ
          return
      }
    }
    //GSRF
    if (this.salidavarSeleccionada !== {}) {
      let totalV: number;
      totalV = Number(this.calcularTotalVarios());
      if (Number(this.salidaService.totalSalidaarray[this.indicador]) !== totalV && this.modulo !== 'PROD') {
        this.messageService.add({
          key: 'sal',
          severity: 'error',
          summary: 'Inserción varios',
          life: 8000,
          detail: 'El total de Varios debe ser igual ' +
            'al Total de las Entradas/Salidas.' + 'La diferencia es: ' + (Number(this.salidaService.totalSalidaarray[this.indicador]) - Number(totalV))
        }
        );
        this.spin=false//CDPJ
      } else {
        this.contadorLineaVar = 0;
        // console.log('esto envio a borrar:');
        // console.log(this.salidasvar )

        for (const salidaV of this.salidaService.detkardexsvararray[this.indicador]) {
          try {
            await this.salidaService.borrarSalidaVariosProm(salidaV);
          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.messageService.add({
              key: 'sal',
              severity: 'error',
              summary: 'EliminarVarios',
              detail: mensaje
            }
            );
            this.spin=false//CDPJ
          }
        }

        for (const salidaVar of this.salidaService.detkardexsvararray[this.indicador]) {
          this.contadorLineaVar += 1;
          salidaVar.KAR_NRO = this.encsalidaSeleccionada.KAR_NRO;
          salidaVar.DETKARDEXVAR_LINEA = this.contadorLineaVar;
          console.log(salidaVar);

          try {

            await this.salidaService.insertarSalidaVarProm(salidaVar);
            if(salidaVar.CLAVE === null || salidaVar.CLAVE === undefined || salidaVar.CLAVE === ''){
              this.auditoriagral.registrarAuditoria('INV_DETKARDEXVAR', String(salidaVar.KAR_NRO)+'/'+salidaVar.CON_CODIGO, 'A',
              '', '01', '', '', '', '').subscribe(() => {
              });
            }else{
              this.auditoriagral.registrarAuditoria('INV_DETKARDEXVAR', String(salidaVar.KAR_NRO)+'/'+salidaVar.CON_CODIGO, 'I',
              '', '01', '', '', '', '').subscribe(() => {
              });
            }

          } catch (err) {

            this.messageService.add({
              key: 'sal',
              severity: 'error',
              summary: 'Inserción varios',
              life: 8000,
              detail: 'No se inserto el registro en varios, error ' + err.error
            });
            this.spin=false//CDPJ
          }
        }

        await this.manejarSenales('Asiento');

        // Antiguo
        /*for (const salidaV of this.salidaService.detkardexsvararray[this.indicador]) {
          this.salidaService.borrarSalidaVarios(salidaV).subscribe(() => {
            // console.log(res);
          });
        }
        for (const salidaVar of this.salidaService.detkardexsvararray[this.indicador]) {
          this.contadorLineaVar += 1;
          salidaVar.KAR_NRO = this.encsalidaSeleccionada.KAR_NRO;
          salidaVar.DETKARDEXVAR_LINEA = this.contadorLineaVar;
          console.log(salidaVar);
          this.salidaService.insertarSalidaVar(salidaVar).subscribe(() => {
            // console.log(res);
            this.manejarSenales('Asiento');
          }, error1 => {
            this.messageService.add({
                key: 'sal',
                severity: 'error',
                summary: 'Inserción varios',
                life: 8000,
                detail: 'No se inserto el registro en varios, error ' + error1.error
              }
            );
          });
        }*/
        this.botonNuevo = false;
        this.botonGuardar = true;
        this.botonBorrar = false;
        this.botonRegresar = true;
        this.botonPrimero = false;
        this.botonAnterior = false;
        this.botonSiguiente = false;
        this.botonUltimo = false;
        this.barraBotones2 = false;
        this.barraBotones4 = false;
        this.botonNuevoPV = false;
        this.botonBorrarPV = false;
        this.botonRegresarPV = false;
        this.panelSalidas = false;
      }
      this.salidaService.getEncSalidas().subscribe((res) => {
        this.encSalidas = res;
        this.indice = this.encSalidas.length - 1
      });
      this.manejarSenales('Cancelar')
    }
  }

  async guardarSalida() {
    //GSRF
    for (const detentrada of this.salidaService.detkardexsvararray[this.indicador]) {
      console.log('/-/-/*-*-',detentrada.CON_CODIGO)
      if (detentrada.CON_CODIGO === '') {
          this.messageService.add({
            key: 'sal',
            severity: 'error',
            summary: 'Cuenta Contable',
            detail: 'Ingrese una cuenta contable'
          });
          this.spin=false//CDPJ
          return
      }
    }
    //GSRF
    //CDPJ
    this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefSalida.objeto].setFocusedCell(this.salidaService.detkardexsarray[this.indicador].length - 1, 'DETKAR_COSTOUNIT');
    this.aggrid.refreshaggrid(this.salidaService.detkardexsarray[this.indicador], this.defaultColDefSalida.objeto);
    this.salidaService.calcularTotal();
    let totalV: number;
    totalV = Number(this.calcularTotalVarios());
    if (Number(this.salidaService.totalSalidaarray[this.indicador]) !== totalV && this.modulo !== 'PROD') {
      this.messageService.add({
        key: 'sal',
        severity: 'error',
        summary: 'Inserción varios',
        life: 8000,
        detail: 'El total de Varios debe ser igual ' +
          'al Total de las Entradas/Salidas.' + 'La diferencia es: ' + (Number(this.salidaService.totalSalidaarray[this.indicador]) - Number(totalV))

      }
      );
      this.spin=false//CDPJ
      this.panelVarios = false;
      
      this.aggrid.refreshaggrid(this.salidaService.detkardexsarray[this.indicador], this.defaultColDefSalida.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefSalida.objeto].setFocusedCell(this.salidaService.detkardexsarray[this.indicador].length - 1, 'DETKAR_COSTOUNIT');
      this.salidaService.calcularTotal();
      return;

    } else {
//CDPJ
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefSalida.objeto].setFocusedCell(this.salidaService.detkardexsarray[this.indicador].length - 1, 'DETKAR_COSTOUNIT');
      this.salidaService.calcularTotal();

      //GSRF
      if(this.modulo === 'PROD'){
        this.encsalidaSeleccionada.KAR_ORIGEN = 'PROD';
      }else{
      this.encsalidaSeleccionada.KAR_ORIGEN = 'ES_INV';
      }
      //GSRF
      let hora;
      let minutos;
      hora = this.salidaService.hora.getHours();
      minutos = this.salidaService.hora.getMinutes();
      if (hora.toString().length === 1) { // poniendo el formato 00
        hora = '0' + hora;
      } else if (minutos.toString().length === 1) {
        minutos = '0' + minutos;
      }
      this.encsalidaSeleccionada.KAR_HORA = hora + ':' + minutos;
      this.encsalidaSeleccionada.KAR_FECHA = this.datePipe.transform(this.salidaService.date1, 'dd/MM/yyyy');

      if (this.encsalidaSeleccionada.KAR_NRO !== '') {
        this.salidaService.actualizarEncSalida(this.encsalidaSeleccionada).subscribe(async () => {
          this.auditoriagral.registrarAuditoria('INV_ENCKARDEX', String(this.encsalidaSeleccionada.KAR_NRO), 'A',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });
          this.messageService.add({
            key: 'sal',
            severity: 'success',
            summary: 'Actualización de Salida',
            detail: 'Se actualizó la salida correctamente'
          });

          //CDPJ
          this.spin=false//CDPJ
          this.salidaService.calcularTotal();
          if (this.salidaService.detkardexsarray[this.indicador].length !== 0) {
            for (const salida of this.salidaService.detkardexsarray[this.indicador]) {
              salida.KAR_NRO = this.encsalidaSeleccionada.KAR_NRO;
              salida.DETKAR_COSTOTOTAL = Number(salida.DETKAR_COSTOUNIT) * Number(salida.DETKAR_CANTIDAD);
              //this.salidaService.insertarSalida(salida).subscribe((resp2) => {
                const res1 = await this.salidaService.insertarSalida(salida).toPromise()//.subscribe((res1) => {
                  if (this.salidaService.detkardexsarray[this.indicador][this.salidaService.detkardexsarray[this.indicador].indexOf(salida)].DETKAR_LINEA === 0) {
                    console.log('Esta es la respuesta de la linea cuando es nueva');
                    // console.log(resp2[0]);
                    // const detKarLinea = JSON.stringify(resp2[0]);
                    // this.obtenerDetKarLinea(detKarLinea, salida);
                    this.auditoriagral.registrarAuditoria('INV_DETKARDEX', salida.KAR_NRO + '/' + String(salida.ART_CODIGO), 'I',
                      this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
                      });
                  } else {
                    this.auditoriagral.registrarAuditoria('INV_DETKARDEX', salida.KAR_NRO + '/' + String(salida.ART_CODIGO), 'A',
                      this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
                      });
                  }
                  for (const rs of res1){
                    salida.DETKAR_LINEA=rs[':B1']
                  }
                
                this.salidaService.actualizarKardex_en_sa(this.encsalidaSeleccionada).subscribe(() => {
                  this.obtenerCantGuaradadoDisponible(salida, this.encsalidaSeleccionada.KAR_NRO);
                });
              //});
            }
            this.deshabilitarBotonesGuardar();
            this.panelVarios = false;
          } else {
            return;
          }
          await this.guardarvarios();
        }, error1 => {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
          this.messageService.add({
            key: 'sal',
            severity: 'error',
            summary: 'Actualización de Salida',
            detail: mensaje
          });
          this.spin=false//CDPJ
        });
        // Este es el código para insertar un nuevo encabezado de salida
      } else {
        //CDPJ
        this.salidaService.calcularTotal();
        let hora1;
        let minutos1;
        hora1 = this.salidaService.hora.getHours();
        minutos1 = this.salidaService.hora.getMinutes();
        console.log('minutos1',minutos1)
        console.log('minutos1.toString().length',minutos1.toString().length)
        if (hora1.toString().length === 1) { // poniendo el formato 00
          hora1 = '0' + hora1;
        } else if (minutos1.toString().length === 1) {
          minutos1 = '0' + minutos1.toString();
        }
        this.encsalidaSeleccionada.KAR_HORA = hora1 + ':' + minutos1;
        this.encsalidaSeleccionada.KAR_FECHA = this.datePipe.transform(this.salidaService.date1, 'dd/MM/yyyy');

        // console.log('Esto se guarda');
        // console.log(this.encsalidaSeleccionada);
        this.salidaService.insertarEncSalida(this.encsalidaSeleccionada).subscribe(async (res) => {

          this.encsalidaSeleccionada.KAR_NRO = JSON.stringify(res[0]).substr(8, 12);
          this.auditoriagral.registrarAuditoria('INV_ENCKARDEX', String(this.encsalidaSeleccionada.KAR_NRO), 'I',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });
          this.messageService.add({
            key: 'sal',
            severity: 'success',
            summary: 'Inserción de Salida',
            detail: 'Se ingresó la salida correctamente'
          });
          this.spin=false//CDPJ
          this.panelVarios = false;
          if (this.salidaService.detkardexsarray[this.indicador].length !== 0) {
            for (const salida of this.salidaService.detkardexsarray[this.indicador]) {

              salida.KAR_NRO = this.encsalidaSeleccionada.KAR_NRO;
              salida.DETKAR_COSTOTOTAL = (Number(salida.DETKAR_COSTOUNIT) *
                Number(salida.DETKAR_CANTIDAD)) + '';
              const res1 = await this.salidaService.insertarSalida(salida).toPromise()//.subscribe((res1) => {
                for (const rs of res1){
                  salida.DETKAR_LINEA=rs[':B1']
                }
                //const detKarLinea = JSON.stringify(res1[0]);
                this.auditoriagral.registrarAuditoria('INV_DETKARDEX', salida.KAR_NRO + '/' + String(salida.ART_CODIGO), 'I',
                  this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
                  });
                //this.obtenerDetKarLinea(detKarLinea, salida);
                this.salidaService.actualizarKardex_en_sa(this.encsalidaSeleccionada).subscribe(() => {
                  this.obtenerCantGuaradadoDisponible(salida, this.encsalidaSeleccionada.KAR_NRO);
                });
              //});

            }
          } else {
            return;
          }
          this.deshabilitarBotonesGuardar();
          await this.guardarvarios();
          this.contadorAsiento = 1;
        }, error1 => {
          let mensaje: string;
          console.log(error1);
          mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
          this.messageService.add({
            key: 'sal',
            severity: 'error',
            summary: 'Inserción de Salida',
            detail: mensaje
          });
          this.spin=false//CDPJ
        });
      }
      // cdpj
      this.botonRegresarP = false;
    
    }
  }

  obtenerDetKarLinea(detKarLinea, salida) {
    switch (detKarLinea.length) {
      case 9:
        this.salidaService.detkardexsarray[this.indicador][this.salidaService.detkardexsarray[this.indicador].indexOf(salida)].DETKAR_LINEA =
          Number(detKarLinea.substr(7, 1));
        break;
      case 10:
        this.salidaService.detkardexsarray[this.indicador][this.salidaService.detkardexsarray[this.indicador].indexOf(salida)].DETKAR_LINEA =
          Number(detKarLinea.substr(7, 2));
        break;
      case 11:
        this.salidaService.detkardexsarray[this.indicador][this.salidaService.detkardexsarray[this.indicador].indexOf(salida)].DETKAR_LINEA =
          Number(detKarLinea.substr(7, 3));
        break;
      default:
        break;
    }
  }

  acciones() {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className = 'layout-config';
      }
    }

  }

  abrirSeriales() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
    this.displayDialogSeriales = true;
  }

  cambiarFoco(evento) {
    if (evento.target.id === 'nroComp') {
      document.getElementById('comentario').focus();
    } else if (evento.target.id === 'comentario') {
      this.enviarNuevoSalida();
    }
  }

  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }

  existeAsiento() {
    let bolExiste = false;

    /*if (cmpEntradaSalida.chbtnNoAfectarKardex.getSelection()==true)
      return true;
    */
    if (this.encsalidaSeleccionada.KAR_ORIGEN === 'PROD') {
      return true;
    }

    if (this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DOCSINASI').CFG_VALOR1 === 0) {
      let bolSaldoIni = false;

      if (this.checkSaldoI != null) {
        if (this.checkSaldoI) {
          bolSaldoIni = true;
        }
      }

      if (this.encsalidaSeleccionada.ASI_NRO === null || this.encsalidaSeleccionada.ASI_NRO === undefined) {
        this.encsalidaSeleccionada.ASI_NRO = '';
      }

      if (this.encsalidaSeleccionada.KAR_NRO === null || this.encsalidaSeleccionada.KAR_NRO === undefined) {
        this.encsalidaSeleccionada.KAR_NRO = '';
      }

      if (this.encsalidaSeleccionada.ASI_NRO.length === 0 &&
        this.encsalidaSeleccionada.KAR_NRO.length > 0 && !bolSaldoIni) {
        this.confirmationService.confirm({
          message: 'El documento no tiene Asiento Contable.' +
            ' Desea eliminar el documento?',
          header: 'Pregunta',
          icon: 'pi pi-exclamation-triangle',
          key: 'confirmarSal',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.confirmationService.close();
            this.borrarEncSalida();
          },
          reject: () => {
            this.confirmationService.close();
          }
        });
      } else {
        bolExiste = true;
      }
    } else {
      bolExiste = true;
    }

    return bolExiste;
  }

  calcularSaldoImporteVarios(): number {
    let dblSaldoVarios = 0;
    let dblTotalIngVar = 0;
    let dblTotalDoc = 0;


    dblTotalDoc = Number(this.salidaService.totalSalidaarray[this.indicador]);

    for (const item of this.salidaService.detkardexsvararray[this.indicador]) {
      dblTotalIngVar = dblTotalIngVar + Number(item.DETKARDEXVAR_IMPORTE);
    }

    dblSaldoVarios = dblTotalDoc - dblTotalIngVar;

    return dblSaldoVarios;
  }

  abrirLotes(params) {
    this.salidaSeleccionada = params;
    this.auxiliarlot++;
    this.displayDialogLotes = this.encsalidaSeleccionada.KAR_NRO.length > 0 && this.salidaSeleccionada.ART_CADUCA === 'S';
  }

  cerrarLotes() {
    this.displayDialogLotes = false;
  }

  async ValidarSeriales(strNumDoc) {
    let errores = 0;
    let articulo = '';
    let cantidad = 0;
    let artserialflag = '';
    let intLinea = 0;
    let strSentencia = '';

    for (let i = 1; i <= this.salidaService.detkardexsarray[this.indicador].length; i++) {
      artserialflag = this.salidaService.detkardexsarray[this.indicador][i - 1].ART_SERIALFLAG;
      cantidad = this.salidaService.detkardexsarray[this.indicador][i - 1].DETKAR_CANTIDAD;
      intLinea = this.salidaService.detkardexsarray[this.indicador][i - 1].DETKAR_LINEA;
      articulo = this.salidaService.detkardexsarray[this.indicador][i - 1].ART_CODIGO;

      if (artserialflag === 'S') {
        const data3 = await this.salidaService.ecvalidarseriales(articulo, strNumDoc, intLinea);
        for (const rs of data3) {
          if (rs.COUN !== Number(cantidad)) {
            errores++;
          }
        }
      }
    }

    if (errores > 0) {
      this.messageService.add({
        key: 'sal',
        severity: 'warn',
        summary: 'Información',
        detail: 'Ingrese los seriales.'
      });
      return false;
    } else {
      return true;
    }
  }

  cerrarSeriales() {
    this.displayDialogSeriales = false;
  }

  cerrarReportes() {
    this.displayDialogReportes = false;
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos = false;
    }
  }
  //GSRF
busquedaorden() {
  this.opcion = 'orden';
  this.types = [
    { label: 'Orden', value: 'ENCORDPRO_NUMERO' },
    { label: 'Referencia', value: 'ENCORDPRO_REFERENCIA' },
    { label: 'Fecha Emi.', value: 'ENCORDPRO_FECHAEMISION' },
    { label: 'Fecha Inicio', value: 'ENCORDPRO_FECHAINICIO' },
    { label: 'Estado', value: 'ENCORDPRO_ESTADO' }
  ];
  this.busquedaCampos = ['', '', '', '', '', '', '', '', '', ''];
  //this.consulta = '*';
  this.tabla = 'PROD_ENCORDPRO';
  this.where = 'ROWNUM < 50';
  this.busquedaService.busquedaNEW().subscribe((datos: any[]) => {
    this.arregloBusq = datos;
    this.arregloBusq.map((registro) => {
      if (registro.ENCORDPRO_FECHAEMISION !== null) {
        registro.ENCORDPRO_FECHAEMISION = this.datePipe.transform(registro.ENCORDPRO_FECHAEMISION, 'dd/MM/yyyy');
      } else {
        registro.ENCORDPRO_FECHAEMISION = '';
      }
      if (registro.ENCORDPRO_FECHAINICIO !== null) {
        registro.ENCORDPRO_FECHAINICIO = this.datePipe.transform(registro.ENCORDPRO_FECHAINICIO, 'dd/MM/yyyy');
      } else {
        registro.ENCORDPRO_FECHAINICIO = '';
      }
    });
    this.displayDialogBusFast = true;
  });
}

//GSRF
}



