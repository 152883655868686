<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="cuotas"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="cuotasconf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra (signal)="manejarSenales($event)" [botonNuevo]="botonNuevo" [botonGuardar]="botonGuardar"
						 [botonBorrar]="botonBorrar" [botonRegresar]="botonRegresar"
						 [barraBotonesDesplaz]="false" [barraBotones3]="oculto"
						 [barraBotonesAsiCon]="oculto" [barraBotones5]="false" [botonBuscar]="false"
						 [botonPrimero]="botonPrimero"
						 [botonAnterior]="botonAnterior"
						 [botonSiguiente]="botonSiguiente"
						 [botonUltimo]="botonUltimo"
						 [check]="true" [botonAlmacenardoc] = "true"></app-invmaebarra>
	</div>
</div>
<div id="divPrincipal" class="divgrups">
	<!--<div class="contorno" id="contorno">-->
		<div class="p-grid">
			<div class="p-col-12">
				<span>Documento</span>
			</div>
			<div class="p-col-12" style="margin-top: -15px">
				<div class="divgrups" aria-valuetext="Documento">
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Número:</span>
								</div>
								<div class="p-col-2">
									<label for="ENCFACNUMERO"></label>
									<input class="frm" id="ENCFACNUMERO" type="text" pInputText
										   [(ngModel)]="strNumFact" [disabled]="disnumero"
										   (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										   autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="buscarDocPend()" [disabled]="disnumero"
									></button>
								</div>
								<div class="p-col-2">
									<label for="COMPROBANTE"></label>
									<input class="frm" id="COMPROBANTE" type="text" pInputText disabled
										   [(ngModel)]="encfac.ENCFAC_NUMERO"
										   autocomplete="off">
								</div>
								<div class="p-col-1">
								</div>
								<div class="p-col-1">
									<span>Fecha:  </span>
								</div>
								<div class="p-col-1">
									<p-calendar id="ENCFAC_FECHAEMISION" [inputStyle]="{width: '100%', height: '20px'}"
												[(ngModel)]="dateTimeFecha"												
												disabled="true" dateFormat="dd/mm/yy"
												(keydown.enter)="setFocus($event)"
												(keydown)="botonesmodificar()">
									</p-calendar>
								</div>
								<div class="p-col-1">
									<span>Total:  </span>
								</div>
								<div class="p-col-2">
									<label for="TOTAL"></label>
									<input class="frm" id="TOTAL" type="text" pInputText disabled
										   style="text-align: right" [(ngModel)]="lblTotalDoc"
										   autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Cliente:</span>
								</div>
								<div class="p-col-5">
									<label for="CLI_NOMBRE"></label>
									<input class="frm" id="CLI_NOMBRE" type="text" pInputText disabled
										   [(ngModel)]="lblCliente"
										   autocomplete="off">
								</div>
								<div class="p-col-1">
								</div>
								<div class="p-col-1">
									<span>Vendedor:</span>
								</div>
								<div class="p-col-1">
									<label for="VENDEDOR"></label>
									<input class="frm" id="VENDEDOR" type="text" pInputText
										   [(ngModel)]="lblVendedor" disabled="true"
										   (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										   autocomplete="off">
								</div>
								<div class="p-col-1">
									<span>SubTotal:  </span>
								</div>
								<div class="p-col-2">
									<label for="SUBTOTAL"></label>
									<input class="frm" id="SUBTOTAL" type="text" pInputText disabled
										   style="color: blue; text-align: right" [(ngModel)]="lblSaldoDoc"
										   autocomplete="off">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="p-col-12" style="margin-top: -15px">
				<span>Cuotas</span>
			</div>
			<div class="p-col-12" style="margin-top: -15px">
				<div class="divgrups">
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-6">
									<div class="p-grid">
										<div class="p-col-2">
											<span>Interés Anual:  </span>
										</div>
										<div class="p-col-2">
											<label for="ENCCUOTA_INTERESANUAL"></label>
											<input class="frm" id="ENCCUOTA_INTERESANUAL" type="text" pInputText
												   style="text-align: right" (keydown.enter)="setFocus($event)"
												   (keydown)="botonesmodificar()"
												   [(ngModel)]="enccuota.ENCCUOTA_INTERESANUAL"
												   autocomplete="off" appTwoDigitDecimaNumber>
										</div>
										<div class="p-col-2">
											<span>%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Número</span>
										</div>
										<div class="p-col-2">
											<label for="ENCCUOTA_NROCUOTAS"></label>
											<input class="frm" id="ENCCUOTA_NROCUOTAS" type="text" pKeyFilter="pint"
												   pInputText
												   style="text-align: right" (keydown.enter)="setFocus($event)"
												   (keydown)="botonesmodificar()"
												   [(ngModel)]="enccuota.ENCCUOTA_NROCUOTAS"
												   autocomplete="off">
										</div>
										<div class="p-col-2">
											<span>Vence cada  </span>
										</div>
										<div class="p-col-1">
											<label for="ENCCUOTA_VENCECDIA"></label>
											<input class="frm" id="ENCCUOTA_VENCECDIA" type="text" pKeyFilter="pint"
												   pInputText
												   style="text-align: right" (keydown.enter)="setFocus($event)"
												   (keydown)="botonesmodificar()"
												   [(ngModel)]="enccuota.ENCCUOTA_VENCECDIA"
												   autocomplete="off">
										</div>
										<div class="p-col-1">
											<span>dias  </span>
										</div>
									</div>
								</div>
								<div class="p-col-1">
									<span>Tipo Interés:  </span>
								</div>
								<div class="p-col-5">
									<div class="p-grid">
										<div class="p-col-4">
											<p-dropdown [options]="cmbtipointeres" [style]="{'width':'100%'}"
														optionLabel="name" [panelStyle]="{'width':'110%'}"
														(onClick)="llenarcmbtipointeres()"
														(onChange)="busqtipointeres($event)"></p-dropdown>
										</div>
										<div class="p-col-2">
											<span>Interés:  </span>
										</div>
										<div class="p-col-2">
											<label for="ENCCUOTA_INTERESEDIT"></label>
											<input class="frm" id="ENCCUOTA_INTERESEDIT" type="text" pInputText
												   style="text-align: right" (keydown.enter)="setFocus($event)"
												   (keydown)="botonesmodificar()"
												   [(ngModel)]="enccuota.ENCCUOTA_INTERESEDIT"
												   autocomplete="off" appTwoDigitDecimaNumber>
										</div>
										<div class="p-col-1">
											<div *ngIf="enccuota.ENCCUOTA_INTERESTIPO === 'PORCE'">
												<span>%  </span>
											</div>
										</div>
										<div class="p-col-2">
											<label for="ENCCUOTA_INTERESDIS"></label>
											<input class="frm" id="ENCCUOTA_INTERESDIS" type="text" pInputText disabled
												   style="color: blue; text-align: right"
												   [(ngModel)]="enccuota.ENCCUOTA_INTERESDIS"
												   autocomplete="off">
										</div>
										<div class="p-col-1">
											<div *ngIf="enccuota.ENCCUOTA_INTERESTIPO === 'VAL'">
												<span>%  </span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px">
							<div class="p-grid">
								<div class="p-col-2">
									<span>Fecha de inicio  </span>
								</div>
								<div class="p-col-1">
									<p-calendar id="ENCCUOTA_FECHAINICIO" [inputStyle]="{width: '100%', height: '20px'}"
												[(ngModel)]="enccuota.ENCCUOTA_FECHAINICIO"												
												[readonlyInput]="true" dateFormat="dd/mm/yy"
												(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()">
									</p-calendar>
								</div>
								<div class="p-col-4">
								</div>
								<div class="p-col-2">
									<span>Total Financiado  </span>
								</div>
								<div class="p-col-1">
									<label for=ENCCUOTA_FINANCIADO></label>
									<input class="frm" id="ENCCUOTA_FINANCIADO" type="text" pInputText disabled
										   style="color: blue; text-align: right"
										   [(ngModel)]="enccuota.ENCCUOTA_FINANCIADO"
										   autocomplete="off">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="p-col-12" style="margin-top: -15px">
				<span>Detalle Cuotas</span>
			</div>
			<div class="p-col-12" style="margin-top: -15px;">
				<div class="divgrups">
					<div class="p-grid" style="text-align: left;">
						<div class="p-col-12">
							<app-aggridsaci [width]=""
											[height]="largo"
											[enableSorting]="true"
											[enableFilter]="true"
											[rowData]="detcuotas"
											[frameworkComponents]="frameworkComponents"
											[animateRows]="true"
											[rowSelection]="rowSelection"
											[columnDefs]="columnDefsDetcuotas"
											[defaultColDef]="defaultColDef"
											[rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="true"
											(selected)="selecciondetcuotas($event)"
											[mostrarGuardar]="false"
											(cambios)="cambio($event)"
											(click)="selecciondetcuotas($event)"></app-aggridsaci>
						</div>
					</div>
				</div>
			</div>

			<div class="p-col-12" style="margin-top: -15px">
				<div class="p-grid">
					<div class="p-col-2">
						<span>Cnta.Contable Intereses:</span>
					</div>
					<div class="p-col-1">
						<input id="CONCODIGO" type="text" pInputText
							   [(ngModel)]="enccuota.CON_CODIGO"
							   (keydown.enter)="setFocus($event)" (input)="limpiarCuentaCont()"
							   autocomplete="off">
					</div>
					<div class="p-col-1">
						<button type="button"
								style="background-image: url(../../../assets/images/iconos/buscar.png);"
								(click)="buscarCuentaCont(enccuota.CON_CODIGO)"
						></button>
					</div>
					<div class="p-col-3">
						<input id="CONNOMBRE" type="text" pInputText disabled
							   [(ngModel)]="enccuota.CON_NOMBRE"
							   autocomplete="off">
					</div>
				</div>
			</div>
			<div class="divgrupstabla">
				<div class="p-grid">
					<div class="p-col-1" style="margin-top: 10px; height: 25%; background-color: rgb(250,250,170)">
					</div>
					<div class="p-col-2">
						<span class="obligatorio">Cuotas Abonadas/Canceladas</span>
					</div>
					<div class="p-col-4">
					</div>
					<div class="p-col-3">
						<div class="p-grid">
							<div class="p-col-4">
								<span class="obligatorio">Total Cuotas</span>
							</div>
							<div class="p-col-8">
								<label for=TOTALPIE></label>
								<input class="totales" id="TOTALPIE" type="text" pInputText disabled
									   style="font-weight: bold; text-align: right"
									   [(ngModel)]="enccuota.ENCCUOTA_TOTAL"
									   autocomplete="off">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	<!--</div>-->
</div>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusqueda"
		  [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla"
					 [busquedaParams]="busquedacampos" [consulta]="consulta"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Reportes" [(visible)]="displayDialogReportes"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '40vw', height: '400px'}">
	<app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
						 [strNumDoc]="strNumFact"
						 [strCampo]="'ENCFAC_NUMERO'" (cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>


<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'CXCENCCUOTA'" [txtNumComprobante] = "strNumFact"
	(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>