import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {Observable} from 'rxjs';
import {BanDetVarCaja} from '../baninterfaces/bantrncajatrn';

@Injectable({
  providedIn: 'root'
})
export class BantrncajaService {
  public detModeloVarios: BanDetVarCaja[][] = [];
  public detModeloVarCaja: BanDetVarCaja[];
  
  private way: string;
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, private confIniciales: ConfInicialesService) {
  
    this.way = sessionStorage.getItem('way');
  }
  
  consultarMovCaj(where, opcion, condicion) {
    return this.http.post<any[]>(this.way + '/ban/movimientocaja/cc5f9eadb1f62800', {
      elementos: {
        p_opcion: opcion,
        p_filtro: where === null ? ' BAN_TRNCAJ.COM_codigo =\'01\' ' : where +  ' and  BAN_TRNCAJ.COM_codigo =\'01\'',
        p_condicion: condicion
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  verificarExistenciaCaja(strCodCaj) {
    return this.http.post<any[]>(this.way + '/ban/movimientocaja/1f76e4f7719263000', {
      elementos: {
        p_CAJ_codigop: strCodCaj
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  encontrarCenNombre(strCenCodigo) {
    return this.http.post(this.way + '/ban/movimientocaja/3b6c75ef8b2f6e', {
      elementos: {
        cen_codigo: strCenCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  encontrarBanCliNombre(strCliCodigo) {
    return this.http.post(this.way + '/ban/movimientocaja/76d6abb2b2be2c0', {
      elementos: {
        cli_codigo: strCliCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  encontrarTarDescripcion(strCliCodigo) {
    return this.http.post(this.way + '/ban/movimientocaja/76faadb5dc66e40', {
      elementos: {
        tar_codigo: strCliCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  encontrarTrnBanNumero(strCajCodigo, srtTrnCajTipo, strTrnCajNumero, strTrnCajDocnro) {
    return this.http.post(this.way + '/ban/movimientocaja/3b7dddd6abdfd6', {
      elementos: {
        caj_codigo: strCajCodigo,
        trncaj_tipo: srtTrnCajTipo,
        trncaj_numero: strTrnCajNumero,
        trncaj_docnro: strTrnCajDocnro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  encontrarBanCodigo(strCajCodigo, srtTrnCajTipo, strTrnCajNumero, strTrnCajDocnro) {
    return this.http.post(this.way + '/ban/movimientocaja/76fbbbad57661c0', {
      elementos: {
        caj_codigo: strCajCodigo,
        trncaj_tipo: srtTrnCajTipo,
        trncaj_numero: strTrnCajNumero,
        trncaj_docnro: strTrnCajDocnro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  encontrarBanBanco(strBanCodigo) {
    return this.http.post(this.way + '/ban/movimientocaja/edf7775aaeb56000', {
      elementos: {
        ban_codigo: strBanCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  encontrarTrnCajSaldo(strCajCodigo, srtTrnCajTipo, strTrnCajNumero, strTrnCajDocnro) {
    return this.http.post(this.way + '/ban/movimientocaja/3b7dddd8a9f154', {
      elementos: {
        caj_codigo: strCajCodigo,
        trncaj_tipo: srtTrnCajTipo,
        trncaj_numero: strTrnCajNumero,
        trncaj_docnro: strTrnCajDocnro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  
  consultarVarios(strCmbCodigo, strTrnCajNumero, strCajCodigo) {
    return this.http.post<any>(this.way + '/ban/movimientocaja/662fcf57eadcb00', {
      elementos: {
        cmb_codigo: strCmbCodigo,
        trncaj_numero: strTrnCajNumero,
        caj_codigo: strCajCodigo
      }
    }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  encontrarCaja(strParametro): Observable<any> {
    return this.http.post(this.way + '/ban/movimientocaja/662fcf57bbbb140', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }
  
  encontrarBanco(strParametro): Observable<any> {
    return this.http.post(this.way + '/ban/movimientocaja/662fcf556abb2c0', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }
  
  encontrarTarjeta(strParametro): Observable<any> {
    return this.http.post(this.way + '/ban/movimientocaja/198bf3d5eab73', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }
  
  encontrarCentroC(strParametro): Observable<any> {
    return this.http.post(this.way + '/ban/movimientocaja/198bf3d563aecc0000', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }
    
  actualizarRegistro(cajCodigo, trncajFecha, trncajTipo, trncajNumero, trncajFormaPago, trncajDocNro, trncajImporte, trncajDescripcion,
                     trncajAnulado, trncajFechaVen, trncajOrigen, bancliCodigo, cenCodigo) {
    return this.http.post<any[]>(this.way + '/ban/movimientocaja/299ddba12e7778', {
      elementos: {
        p_CAJ_codigo_3: cajCodigo,
        p_TRNCAJ_fecha_4: trncajFecha,
        p_TRNCAJ_tipo_5: trncajTipo,
        p_TRNCAJ_numero_6: trncajNumero,
        p_TRNCAJ_formapago_7: trncajFormaPago,
        p_TRNCAJ_docnro_8: trncajDocNro,
        p_TRNCAJ_importe_9: trncajImporte,
        p_TRNCAJ_descripcion_10: trncajDescripcion,
        p_TRNCAJ_anulado: trncajAnulado,
        p_TRNCAJ_fechavenci_11: trncajFechaVen,
        p_TRNCAJ_origen_12: trncajOrigen,
        p_COM_codigo_13: '01',
        p_BANCLI_codigo_14: bancliCodigo === undefined ? '' : bancliCodigo,
        p_USU_IDENTIFICACION: this.usuario.identificacion,
        p_cen_codigo: cenCodigo === undefined ? '' : cenCodigo,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminaTrnVarios(trncajTipo, trncajNumero, cajCodigo, trncajDocNro) {
    
    return this.http.post<any[]>(this.way + '/ban/movimientocaja/3ab2b4beadcb1c', {
      elementos: {
        p_trncaj_tipo: trncajTipo,
        p_trncaj_numero: trncajNumero,
        p_caj_codigo: cajCodigo,
        p_trncaj_docnro: trncajDocNro,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  generarAsiento(trncajTipo, trncajNumero, cajCodigo, trncajDocNro) {
    return this.http.post<any[]>(this.way + '/ban/movimientocaja/83aeeda95c93300', {
      elementos: {
        p_trncaj_tipo: trncajTipo,
        p_trncaj_numero: trncajNumero,
        p_caj_codigo: cajCodigo,
        p_trncaj_docnro: trncajDocNro,
        p_com_codigo: '01',
        p_usu_identificacion: this.usuario.identificacion
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  guardarVarios(trncajTipo, trncajNumero, cajCodigo, trncajDocNro, conCodigo, cenCodigo, trncajImporte) {
    return this.http.post<any[]>(this.way + '/ban/movimientocaja/10f2b6d56feadd000', {
      elementos: {
        p_trncaj_tipo: trncajTipo,
        p_trncaj_numero: trncajNumero,
        p_caj_codigo: cajCodigo,
        p_trncaj_docnro: trncajDocNro,
        p_com_codigo: '01',
        p_con_codigo: conCodigo,
        p_cen_codigo: cenCodigo,
        p_trncajvar_importe: trncajImporte
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  existenciaNum(strTrnCajNumero) {
    return this.http.post<any>(this.way + '/ban/movimientocaja/7ddb93efeb3b78', {
      elementos: {
        trncaj_numero: strTrnCajNumero
      }
    }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  insertar(strCajCodigo, strFechaTrnCaj, strTipo, strTrnCajNumero, strTrnCajFormaPago, strTrnCajDocNro, strTrnCajImporte, strTrnCajDescripcion,
           intTrnCajAnulado, strFechaTrnCajVenci, strTrnCajTipo, strCodReferencia, strCliCodigo, strCenCodigo) {
    return this.http.post<any[]>(this.way + '/ban/movimientocaja/95f8edf58a9db00', {
      elementos: {
        p_CAJ_codigo_1: strCajCodigo,
        p_TRNCAJ_fecha_2: strFechaTrnCaj,
        p_TRNCAJ_tipo_3: strTipo,
        p_TRNCAJ_numero_4: strTrnCajNumero,
        p_TRNCAJ_formapago_5: strTrnCajFormaPago,
        p_TRNCAJ_docnro_6: strTrnCajDocNro,
        p_TRNCAJ_importe_7: strTrnCajImporte,
        p_TRNCAJ_descripcion_8: strTrnCajDescripcion,
        p_TRNCAJ_anulado: intTrnCajAnulado,
        p_TRNCAJ_fechavenci_9: strFechaTrnCajVenci,
        p_TRNCAJ_origen_10: strTrnCajTipo,
        p_COM_codigo_11: '01',
        p_BANCLI_codigo_12: strCodReferencia,
        p_USU_IDENTIFICACION: this.usuario.identificacion,
        p_CLI_codigo: strCliCodigo,
        p_cen_codigo: strCenCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
    
  }
  
  consulta_max_docnum() {
    return this.http.post<any>(this.way + '/ban/movimientocaja/cc5f9ead95c63800', {
      elementos: {},
    }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  anularAsientoContable(strAsiNro) {
    return this.http.post<any[]>(this.way + '/ban/movimientocaja/2afe5724cc5faa', {
      elementos: {
        p_ASI_nro: strAsiNro,
        p_COM_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  actualizarNumAsientoDocxxx(strAnulado, strTipo, strTrnCajNumero, strNumDoc) {
    return this.http.post<any>(this.way + '/ban/movimientocaja/a676feb2b9276000', {
      elementos: {
        anulado: strAnulado,
        trncaj_tipo: strTipo,
        trncaj_numero: strTrnCajNumero,
        trncaj_docnro: strNumDoc
      }
    }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  eliminaRegistro(strTipo, strTrnCajNumero) {
    return this.http.post<any[]>(this.way + '/ban/movimientocaja/3ab2b4aeadba1200000', {
      elementos: {
        p_TRNCAJ_tipo_1: strTipo,
        p_TRNCAJ_numero_2: strTrnCajNumero
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerConfiguracion() {
    return this.http.post<any[]>(this.way + '/ban/movimientocaja/617d75ddb662f0', {
        elementos: {},
       
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  obtenerClave() {
    return this.http.post<any>(this.way + '/ban/movimientocaja/c2faebbb6caac00', {
      elementos: {},
    }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  verificarCentroCostos(strCodCuenta) {
    return this.http.post<any[]>(this.way + '/ban/movimientocaja/7ddb93d8ebb31c', {
        elementos: {
          con_codigo: strCodCuenta
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
    //CDPJ
    verificarExistenciaCnta(strCodCaj) {
      return this.http.post<any[]>(this.way + '/ban/movimientocaja/3eedc9f262b6e', {
        elementos: {
          con_codigo: strCodCaj
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }
    //CDPJ
}
