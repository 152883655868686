<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="pagcondvf"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="pagcondvfConf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<app-invmaebarra [botonGuardar]="botonGuardar" [botonBorrar]="botonBorrar"
				 [botonRegresar]="botonRegresar"
				 (signal)="manejarSenales($event)" [barraBotonesDesplaz]="barraBotones2" [barraBotones3]="barraBotones2"
				 [barraBotonesAsiCon]="barraBotones2" [barraBotones5]="true" [noNuevoDVF]="true"></app-invmaebarra>
<div class="divgrups">
	<div class="p-grid" style="margin-bottom: -23px">
		<div class="p-col-1">
			<span>Num.Dev.:</span>
		</div>
		<div class="p-col-3">
			<label for="NUMFACT"></label>
			<input class="frm" id="NUMFACT" type="text" pInputText disabled
				   [(ngModel)]="encdvf.ENCDVF_NUMERO" autocomplete="off">
		</div>
		<div class="p-col-1">
			<span>Cliente:</span>
		</div>
		<div class="p-col-2">
			<label for="CLIENTE"></label>
			<input class="frm" id="CLIENTE" type="text" pInputText disabled
				   [(ngModel)]="encdvf.CLI_CODIGO" autocomplete="off">
		</div>
		<div class="p-col-3">
		</div>
		<div class="p-col-2">
			<label for="ASIENTO"></label>
			<input class="frm" id="ASIENTO" type="text" pInputText disabled
				   [(ngModel)]="encdvf.ASI_NRO" autocomplete="off">
		</div>
		<div class="p-col-12">
			<div class="divgrups">
				<div class="p-grid" style="margin-bottom: -13px">
					<div class="p-col-3">
						<span class="contado">Saldo a Pagar:</span>
					</div>
					<div class="p-col-3">
						<input class="contado" id="TOTAL" type="text" pInputText disabled
							   [(ngModel)]="totalApagar" style="color: blue;">
					</div>
					<div class="p-col-6">
					</div>

				</div>
			</div>
		</div>
	</div>
</div>
<div class="contorno" id="contorno">
	<div class="p-grid">
		<div class="p-col-12">
			<span style="font-weight: bold">Facturas Pendientes</span>
		</div>
		<div class="p-col-12" style="margin-top: -20px">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="pagconservice.resumen"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsFacPendientes"
							[defaultColDef]="defaultColDefFacP"
							(keydown.enter)="calcularTotal()"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false">
			</app-aggridsaci>
		</div>
		<div class="p-col-12" style="margin-top: -20px">
			<span style="font-weight: bold">Otras Formas de Pago</span>
		</div>
		<div class="p-col-12" style="margin-top: -20px">
			<app-aggridsaci [width]="1200"
							[height]="largo2"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="pagconservice.pagos"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsPago"
							[defaultColDef]="defaultColDef"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="true"
							(selected)="seleccionpago($event)"
							(keydown.enter)="calcularTotal()"
							[mostrarGuardar]="false"
							[botonNuevo]="botonNuevoGrid"
							[botonBorrar]="botonBorrarGrid"
							[botonRegresar]="botonRegresarGrid"
							(cambios)="cambiosbotones($event)">
			</app-aggridsaci>
		</div>
	</div>
</div>
<div class="p-grid">
	<div class="p-col-3">
		<div class="p-grid">
			<div class="p-col-3">
				<p-colorPicker [disabled]="true" [(ngModel)]="choosedColorG"></p-colorPicker>
			</div>
			<div class="p-col-9">
				<span>Documentos Conciliados</span>
			</div>
		</div>
	</div>
	<div class="p-col-1"></div>
	<div class="p-col-3">
		<div class="p-grid">
			<div class="p-col-3">
				<p-colorPicker [disabled]="true" [(ngModel)]="choosedColorY"></p-colorPicker>
			</div>
			<div class="p-col-9">
				<span>Anticipo Utilizado</span>
			</div>
		</div>
	</div>
	<div class="p-col-6"></div>
</div>
<div class="p-grid" style="margin-top: -20px">
	<div class="p-col-12">
		<span style="font-weight: bold;">Totales</span>
	</div>
	<div class="p-col-12" style="margin-top: -10px">
		<div class="p-grid">
			<div class="p-col-1"></div>
			<div class="p-col-2">
				<div class="p-grid">
					<div class="p-col-12">
						<span>Facturas Pend.</span>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<input type="text" [disabled]="true" [(ngModel)]="totalFacturasPend">
					</div>
				</div>
			</div>
			<div class="p-col-1"></div>
			<div class="p-col-2">
				<div class="p-grid">
					<div class="p-col-12">
						<span>Otras Formas Pago</span>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<input type="text" [disabled]="true" [(ngModel)]="totalFormaPago">
					</div>
				</div>
			</div>
			<div class="p-col-1"></div>
			<div class="p-col-2">
				<div class="p-grid">
					<div class="p-col-12">
						<span style="color: blue">A Pagar</span>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<input type="text" [disabled]="true" [(ngModel)]="pagarTotal">
					</div>
				</div>
			</div>
			<div class="p-col-3"></div>
		</div>
	</div>
</div>

<p-dialog header="Clave Autorización" [(visible)]="claveDialog"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '40vw', height: 'auto'} ">
	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-4">
					<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-4">
					<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
						   (keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
				</div>
				<div class="p-col-1"></div>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width:'90vw', height:'120vh'}"
		  [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="asiento" [abiertoDesde]="'pagocontadodvf'"
					(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>
