import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import {Segencenviocorreo} from '../seginterfaces/segencenviocorreo';
import {Segencenviocorreovar} from '../seginterfaces/segencviocorvar';
import {Observable, of} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SegencenviocorreoService {

  private way: string;
  public url: string;
  constructor(private http: HttpClient, public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario, private confIniciales: ConfInicialesService) {
      this.way = sessionStorage.getItem('way');
     }
     consultarReporte(strValor,strValue,strParams){
  return this.http.post<any[]>(this.way + '/seg/enviocorreos/20eef14c9594ee000000000', {
      elementos: {
          Valor: strValor,
          Value: strValue,
          Params: strParams,
          
      }
    }, this.confIniciales.httpOptions()
    
  ).toPromise();
}
consultarFormulario(strValor,strValue,strParams){
  return this.http.post<any[]>(this.way + '/seg/enviocorreos/107778a649f8de00000000', {
      elementos: {
          Valor: strValor,
          Value: strValue,
          Params: strParams
      }
    }, this.confIniciales.httpOptions()
    
  ).toPromise();
}
consultarVariables(strValor){
  return this.http.post<any[]>(this.way + '/seg/enviocorreos/41dde2992663800000000000', {
      elementos: {
          enviocorvarcodigo: strValor
      }
    }, this.confIniciales.httpOptions()
    
  ).toPromise();
}
consulExisreporte(strValor,tiporep){
  return this.http.post<any[]>(this.way + '/seg/enviocorreos/41dddbb38df5d0', {
      elementos: {
          rlcodigo: strValor,
          tiporeporte:tiporep,
      }
    }, this.confIniciales.httpOptions()
    
  ).toPromise();
}
consulExisformulario(strValor){
  return this.http.post<any[]>(this.way + '/seg/enviocorreos/20eebf1bb7aaae0000', {
      elementos: {
        enviocorvarcodigo: strValor
      }
    }, this.confIniciales.httpOptions()
    
  ).toPromise();
}
insertarFormulario(enviorol){
  console.log(enviorol)
  return this.http.post<any[]>(this.way + '/seg/enviocorreos/95f8edf5f8ddc0000000', {
    
      elementos: {
        p_encenviocor_codigo:enviorol.ENCENVIOCOR_CODIGO ,
        p_encenviocor_nombre:enviorol.ENCENVIOCOR_NOMBRE,
        p_encenviocor_repcodigo:enviorol.ENCENVIOCOR_REPCODIGO,
        p_encenviocor_descripcion:enviorol.ENCENVIOCOR_DESCRIPCION,
        p_encenviocor_asunto:enviorol.ENCENVIOCOR_ASUNTO,
        p_encenviocor_remitente:enviorol.ENCENVIOCOR_REMITENTE,
        p_encenviocor_cuerpo1:enviorol.ENCENVIOCOR_CUERPO1,
        p_encenviocor_cuerpo2:enviorol.ENCENVIOCOR_CUERPO2,
        p_encenviocor_piecorreo:enviorol.ENCENVIOCOR_PIECORREO,
        p_encenviocor_firma:enviorol.ENCENVIOCOR_FIRMA,
        p_encenviocor_logo:enviorol.ENCENVIOCOR_LOGO,
        p_enviocorvar_codigo:enviorol.ENVIOCORVAR_CODIGO,
        p_encenviocor_tiporeporte:enviorol.ENCENVIOCOR_TIPOREPORTE,
        p_encenviocor_nomarchivo:enviorol.ENCENVIOCOR_NOMARCHIVO,
       

      }
    }, this.confIniciales.httpOptions()
    
  ).toPromise();
}
consultformulariocorreo(strValor) {
  return this.http.post<any[]>(this.way + '/seg/enviocorreos/41dd7e376f555c0000000000', {
      elementos: {
        enviocorcodigo: strValor
      }
    }, this.confIniciales.httpOptions()
    
  ).toPromise();
}
consultnomreporte(strValor,tiporep){
  return this.http.post<any[]>(this.way + '/seg/enviocorreos/10776f8b6dd9c7000', {
      elementos: {
          rlcodigo: strValor,
          tiporeporte:tiporep
      }
    }, this.confIniciales.httpOptions()
    
  ).toPromise();
}
consultnomformulario(strValor){
  return this.http.post<any[]>(this.way + '/seg/enviocorreos/83bb7c59f8ddc0000000', {
      elementos: {
        enviocorvarcodigo: strValor
      }
    }, this.confIniciales.httpOptions()
    
  ).toPromise();
}
eliminarformulariocorreo(strValor) {
  return this.http.post<any[]>(this.way + '/seg/enviocorreos/1d595a5756df8e000000000', {
      elementos: {
        p_encenviocor_codigo: strValor
      }
    }, this.confIniciales.httpOptions()
    
  ).toPromise();
}
consultvistaprevia(strValor1,strValor2) {
  return this.http.post<any[]>(this.way + '/seg/enviocorreos/20eefe5ceab3b80000', {
      elementos: {
        p_encenviocor_codigo :strValor1,
        p_enviocorvar_codigo :strValor2,
      }
    }, this.confIniciales.httpOptions()
    
  ).toPromise();
}
}
