 <div class="p-grid" style="width: 99%;">
	<div class="p-col-12">
        <app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
            [botonBorrar]="btnBorrar"
            (signal)="manejarSenales($event)" [barraBotonesDesplaz] = "false"
            [botonBuscar]="btnBuscar"  [botonSiguiente]="botonSiguiente"  [botonAnterior]="botonAnterior"
            [botonPrimero]="botonPrimero" [botonUltimo]="botonUltimo"
            [barraBotones3]="true" [botonBinocular]="true"
            [barraBotones5]="false" >
        </app-invmaebarra>
	</div>
</div>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
					 [consulta]="consulta" [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccionbus($event)"></app-busquedadlg>
</p-dialog>
<div class="layout-config">
	<div class="layout-config-content-wrapper">
		<a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones()">
			<i class="pi pi-bars" style="cursor: pointer"></i>
		</a>
		<p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
				   [dismissible]="true" [showCloseIcon]="false">
			<br>
			<br>
			<br>
			<p-tabView>
				<p-tabPanel header="Acciones">
					<div class="p-grid">
                        <div class="p-col-12">
							<i class="pi pi-caret-right archivoOpc"></i><span (click)="activarValores()"
																			  class="archivoOpc">Activar valores</span>
						</div>
						<div class="p-col-12">
							<i class="pi pi-caret-right archivoOpc"></i><span (click)="agregarDatosAdi()"
												  class="archivoOpc">Ingreso de información de campos adicionaeles</span>
                        </div>
                        <div class="p-col-12">
							<i class="pi pi-caret-right archivoOpc"></i><span (click)="agregarDatosAdiglob()"
												  class="archivoOpc">Ingreso de documentos</span>
						</div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-sidebar>
	</div>
</div>
<!--Dialos Adicional-->
<ng-container *ngIf="displaydialogadi">
<p-dialog header="Ingreso de datos Adicionales" [(visible)]="displaydatosAdi"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  (onHide)="onHideDatAdi()"
		  [style]="{width: '70vw', height: 'auto'} ">
    <p-toast [style]="{marginTop: '41vh'}" position="top-center" key="rubDialog"></p-toast>
    <app-nommatdatadi (salir)="salir($event)" [datadiempl]="selectedNomEmpl"></app-nommatdatadi>
   
</p-dialog>
</ng-container>
<ng-container *ngIf="displaydialogadiglob">
    <p-dialog header="Ingreso de datos Adicionales" [(visible)]="displaydialogadiglob"
              [transitionOptions]="'0ms'"
              [modal]="true"
              appendTo="body"
              (onHide)="onHideAutorizacion()"
              [style]="{width: '70vw', height: 'auto'} ">
        <p-toast [style]="{marginTop: '41vh'}" position="top-center" key="rubDialog"></p-toast>
        <app-nommaeddataglob (salirclo)="salirClo($event)" [datadiempl]="selectedNomEmpl"></app-nommaeddataglob>
       
    </p-dialog>
    </ng-container>
 <div style="overflow: hidden" id="divPrincipal" class="p-col-12">
    <div   style="margin-top: -10px; margin-bottom: -10px; width: 99%;">
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Código:</span>
                </div> 
                
                <div class="p-col-2">
                    <input type="text" pInputText id="nombres"  (keydown)="botonesmodificar()" [(ngModel)]="selectedNomEmpl.EMP_CODIGO2" 
                           style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
                           disabled
                          >
                </div>
                <div class="p-col-1">
                    <span>Nombres:</span>
                </div> 
                <div class="p-col-3">
                    <input type="text" pInputText id="apellidos"  (keydown)="botonesmodificar()" [(ngModel)]="selectedNomEmpl.EMP_NOMBRE" 
                           style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
                           
                           >
                </div>
                <div class="p-col-1">
                    <span>Apellidos:</span>
                </div> 
                <div class="p-col-3">
                    <input type="text" pInputText  [(ngModel)]="selectedNomEmpl.EMP_APELLIDO"
                           style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
                           
                           >
                </div>
                <div class="p-col-1">
                    <input type="text" pInputText id="id"  (keydown)="botonesmodificar()" [(ngModel)]="selectedNomEmpl.EMP_CODIGO" 
                           style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
                           disabled
                          >
                </div>
            </div>
        </div>
    </div>
    <p-toast [style]="{marginTop: '41vh'}" position="top-center" key="maeEmpleadob"></p-toast>
    <p-confirmDialog [transitionOptions]="'0ms'" key="NomMaeEmplEliminar" title="Confirmar Acción"
                 appendTo="body"></p-confirmDialog>
    <p-confirmDialog [transitionOptions]="'0ms'" key="NomEmpleIngFijoEli" title="Confirmar Acción"
                 appendTo="body"></p-confirmDialog>
    <p-confirmDialog [transitionOptions]="'0ms'" key="NomEmpleEgreFijoEli" title="Confirmar Acción"
                 appendTo="body"></p-confirmDialog>
    <p-confirmDialog [transitionOptions]="'0ms'" key="NomEmpleCargaEli" title="Confirmar Acción"
                 appendTo="body"></p-confirmDialog>
    <ng-container *ngIf="displaydialogPerEmpl">
        <p-dialog header="Periodo" [(visible)]="displaydialogPerEmpl"
                              [transitionOptions]="'0ms'"
                              [modal]="true"
                              appendTo="body"
                              (onHide)="onHideIngre()"
                              [style]="{width: '70vw', height: 'auto'} ">
            <p-toast [style]="{marginTop: '41vh'}" position="top-center" key="rubDialog"></p-toast>
            <app-nomempleadoperiodo  [cuotas]="selectednomIngrFijo.MAEINGRFIJO_NROCUOT" [fechareg]="selectednomIngrFijo.MAEINGRFIJO_FECHREGI"(salirclo)="salirPerEmpl($event)" (resp)="llenarPerEmpl($event)"></app-nomempleadoperiodo>                       
        </p-dialog>
    </ng-container>
    <ng-container *ngIf="displaydialogEgrePerEmpl">
        <p-dialog header="Periodo" [(visible)]="displaydialogEgrePerEmpl"
                              [transitionOptions]="'0ms'"
                              [modal]="true"
                              appendTo="body"
                              (onHide)="onHideEgre()"
                              [style]="{width: '70vw', height: 'auto'} ">
            <p-toast [style]="{marginTop: '41vh'}" position="top-center" key="rubDialog"></p-toast>
            <app-nomempleadoperiodo  [cuotas]="selectednomEgreFijo.MAEEGREFIJO_NROCUOT" [fechareg]="selectednomEgreFijo.MAEEGREFIJO_FECHREGI"(salirclo)="salirEgrePerEmpl($event)" (resp)="llenarEgrePerEmpl($event)"></app-nomempleadoperiodo>                       
        </p-dialog>
    </ng-container>                
    <ng-container *ngIf="displaydialogadiglob">
    <p-dialog header="Ingreso de datos Adicionales" [(visible)]="displaydialogadiglob"
              [transitionOptions]="'0ms'"
              [modal]="true"
              appendTo="body"
              (onHide)="onHideAutorizacion()"
              [style]="{width: '70vw', height: 'auto'} ">
        <p-toast [style]="{marginTop: '41vh'}" position="top-center" key="rubDialog"></p-toast>
        <app-nommaeddataglob (salirclo)="salirClo($event)" [datadiempl]="selectedNomEmpl"></app-nommaeddataglob>
       
    </p-dialog>
    </ng-container>
    <ng-container *ngIf="displaydialogemplvac">
        <p-dialog header="Vacaciones" [(visible)]="displaydialogemplvac"
                  [transitionOptions]="'0ms'"
                  [modal]="true"
                  appendTo="body"
                  (onHide)="onhideVaca()"
                  [style]="{width: '90vw', height: 'auto'} ">
            <app-nomepleadovac (salirvac)="salirvac($event)" [selNomEmpl]="selectedNomEmpl"></app-nomepleadovac>
           
        </p-dialog>
        </ng-container>

    <p-tabView (onChange)="onTab1Change($event)" #tabs [(activeIndex)]="indexTab">
            <p-tabPanel header="Generales" [disabled]="disabledTab0">
                <p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 270px)'}">
                <div  *ngIf="visi==true">
                <div class="divgrups" >
                    <div class="p-grid">
                        <div class="p-col-12">
                            <div class="p-grid">
                                
                                <div class="p-col-2">
                                    <span>Tipo ID:</span>
                                </div>
                                                    
                                <div class="p-col-2">
                                    <p-dropdown [options]="cmbtipoid" [style]="{'width': '100%'}"
                                                [(ngModel)]="valortipoid" optionLabel="refnombre"
                                                [panelStyle]="{'width':'110%'}"
                                                (onChange)="cambioOption($event)" (keydown.enter)="cambiarFoco2($event)"
                                                ></p-dropdown>
                                </div>
                                <div class="p-col-2">	
                                    <span>Cédula/Pasaporte:</span>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" id="inp_EMP_RUCIDE"  (keydown.enter)="setFocus($event)"  (keydown)="botonesmodificar()" [(ngModel)]="selectedNomEmpl.EMP_RUCIDE"  style="width: 100%" pInputText autocomplete="off" >
                                </div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-2">	
                                    <span>Estado Civil:</span>
                                </div>
                                <div class="p-col-2">
                                    <input class="frm" id="inp_estaCivi" type="text" pInputText [(ngModel)]="selectedNomEmpl.EMP_ESTACIVIL" 
                                    (keydown.enter)="busquedaEstCivil(selectedNomEmpl.EMP_ESTACIVIL)" (keydown)="botonesmodificar()"          
                                    autocomplete="off" (keydown.enter)="setFocus($event)">
                                </div>
                                <div class="p-col-1">
                                    <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busquedaEstCivil(selectedNomEmpl.EMP_ESTACIVIL)"></button>
                                </div>
                            
                                <div class="p-col-3" style="text-align: left">
                                    <input id="span_esta_civ" type="text" (keydown)="botonesmodificar()" [(ngModel)]="span_esta_civil" pInputText disabled autocomplete="off" >
                                </div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-2">	
                                    <span>Correo Empresarial:</span>
                                </div>
                                <div class="p-col-6">
                                    <input type="text" id="inp_CORREOEMPRE" (keydown.enter)="setFocus($event)"  (keydown)="botonesmodificar()"  [(ngModel)]="selectedNomEmpl.EMP_CORREOEMPRE" style="width: 100%" pInputText autocomplete="off">
                                </div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-2">	
                                    <span>Correo personal:</span>
                                </div>
                                <div class="p-col-6">
                                    <input type="text" id="inp_CORREOPERS" (keydown.enter)="setFocus($event)" style="width: 100%" (keydown)="botonesmodificar()" [(ngModel)]="selectedNomEmpl.EMP_CORREOPERS" autocomplete="off"  pInputText>
                                </div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-2">
                                    <span>Nacionalidad:</span>
                                </div>    
                                <div class="p-col-2">
                                    <p-dropdown [options]="cmbNacio" [style]="{'width': '100%'}"
                                                [(ngModel)]="valorNacio" optionLabel="nacnombre"
                                                [panelStyle]="{'width':'110%'}"
                                                id="inp_Nacio" 
                                                (onChange)="cambioOptionNac($event)" (keydown.enter)="cambiarFocoNac($event)"
                                                ></p-dropdown>
                                </div>
                                <div class="p-col-2">
                                    <span>Lugar nacimiento:</span>
                                </div>  
                                <div class="p-col-3">
                                    <input type="text" id="inp_lugarnacimiento" (keydown.enter)="setFocus($event)" style="width: 100%" (keydown)="botonesmodificar()" [(ngModel)]="selectedNomEmpl.EMP_LUGARNACI" autocomplete="off"  pInputText>
                                </div>
                            </div>

                            <div class="p-grid">
                                <div class="p-col-2">
                                    <span>Licencia de Conducir:</span>
                                </div>    
                                <div class="p-col-2">
                                    <p-dropdown [options]="cmbLicen" [style]="{'width': '100%'}"
                                                [(ngModel)]="pLicen" optionLabel="tipo"
                                                [panelStyle]="{'width':'110%'}"
                                                (onChange)="cambioOptionLicen($event)" (keydown.enter)="cambiarFocoNac($event)"
                                                ></p-dropdown>
                                </div>
                                <div class="p-col-2">	
                                    <span>Fecha de caducidad:</span>
                                </div>
                                <div class="p-col-3">
                                    <p-calendar id="pFecCadu" [inputStyle]="{width: '100%', height: '20px'}"
                                                [(ngModel)]="selectedNomEmpl.EMP_LICENCADUCA" 
                                                dateFormat="dd/mm/yy"
                                                (ngModelChange)="cambio($event)">
                                    </p-calendar>
                                </div>
                                <div class="p-col-1">
                                    <span>Carnet:</span>
                                </div>                          
                                <div class="p-col-2">
                                    <input type="text" id="inpEMPCARNET" (keydown.enter)="setFocus($event)"  (keydown)="botonesmodificar()" [(ngModel)]="selectedNomEmpl.EMP_CARNET" style="width: 100%" pInputText autocomplete="off">
                                </div>
                            </div>    
                            <div class="p-grid">
                                <div class="p-col-2">	
                                    <span>Celular:</span>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" id="inpEMPCELULAR" (keydown.enter)="setFocus($event)"  (keydown)="botonesmodificar()" [(ngModel)]="selectedNomEmpl.EMP_CELULAR" style="width: 100%" pInputText autocomplete="off">
                                </div>
                                <div class="p-col-2">	
                                    <span>Cédula Militar:</span>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" id="inpEMPCEDUMILI" (keydown.enter)="setFocus($event)"  (keydown)="botonesmodificar()" autocomplete="off" [(ngModel)]="selectedNomEmpl.EMP_CEDUMILI" style="width: 100%" pInputText>
                                </div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-2">	
                                    <span>Fecha de nacimiento:</span>
                                </div>
                                <div class="p-col-3">
                                    <p-calendar id="pFecNaci" [inputStyle]="{width: '100%', height: '20px'}"
                                                [(ngModel)]="selectedNomEmpl.EMP_FECHNACI"
                                                dateFormat="dd/mm/yy"
                                                (ngModelChange)="cambio($event)">
                                    </p-calendar>
                                </div>
                                    <div class="p-col-1">
                                            <span >Sexo:</span>
                                    </div>
                                    <div class="p-col-2">
                                        <label ></label>
                                        <input class="frm" id="inpsexo" type="text" pInputText
                                        [(ngModel)]="selectedNomEmpl.EMP_SEXO" (keydown)="botonesmodificar()"
                                        (keydown.enter)="busquedaSexo(selectedNomEmpl.EMP_SEXO)" (keydown)="botonesmodificar()"
                                        (keydown.enter)="setFocus($event)"  autocomplete="off">
                                    </div>
                                    <div class="p-col-1">
                                        <button type="button"
                                                    style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                    (click)="busquedaSexo(selectedNomEmpl.EMP_SEXO)"></button>
                                    </div>
                                
                                    <div class="p-col-3" style="text-align: left">
                                        <input id="span_sexid" type="text" [(ngModel)]="span_sex" pInputText disabled autocomplete="off"  >
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>  
            <div class="divgrups">
                    <div class="p-grid">
                        <div class="p-col-12">
                            <div class="p-grid">
                                <div class="p-col-1">	
                                    <span>Fecha de ingreso:</span>
                                </div>
                                <div class="p-col-2">
                                    <p-calendar id="inpFechaIngre" [inputStyle]="{width: '100%', height: '20px'}"
                                                [(ngModel)]="selectedNomEmpl.EMP_FECINGRESO" 
                                                dateFormat="dd/mm/yy" 
                                                (ngModelChange)="cambio($event)">
                                    </p-calendar>
                                </div>
                                <div class="p-col-1">
                                <p-checkbox class="txtAlign" id="chkactivos"
                                              binary="true"
                                                [(ngModel)]="chkactivo" (click)="activochk()"
                                                (keydown.enter)="setFocus($event)"
                                                [disabled]="validacheck"></p-checkbox>
                                    &nbsp;<span class="txtAlign">&nbsp;Activo &nbsp;&nbsp;</span>
                                </div>    
                                <div class="p-col-2">	
                                    <span>Fecha de contrato:</span>
                                </div>
                                <div class="p-col-3">
                                    <p-calendar id="inpFechaCont" [inputStyle]="{width: '100%', height: '20px'}"
                                                [(ngModel)]="selectedNomEmpl.EMP_FECHACONTRATO" 
                                                dateFormat="dd/mm/yy"
                                                (ngModelChange)="cambio($event)">
                                    </p-calendar>
                                </div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-1">	
                                    <span>Fecha salida:</span>
                                </div>
                                <div class="p-col-2">
                                    <p-checkbox class="txtAlign" id="chksalidas"
                                                    binary="true"
                                                        [(ngModel)]="chksalida" (click)="activochk()"
                                                        (keydown.enter)="setFocus($event)"></p-checkbox>
   
                                    <p-calendar id="inpFechaSali" [inputStyle]="{width: '90%', height: '20px'}"
                                                        [(ngModel)]="selectedNomEmpl.EMP_FECSALIDA" 
                                                        dateFormat="dd/mm/yy" 
                                                        (ngModelChange)="cambio($event)" [disabled]="calsalifech">
                                    </p-calendar>
                                </div>
                                <div class="p-col-1">	
                                    <span>Plazo Contrato:</span>
                                </div>
                                <div class="p-col-2">
                                    <p-dropdown [options]="valPlazoContrato" [style]="{'width': '100%'}"
                                                [(ngModel)]="valPlaContra" optionLabel="refnombrea"
                                                [panelStyle]="{'width':'110%'}"
                                                id="inpPlazoContrato"
                                                (onChange)="cambioPlazoContra($event)" (keydown.enter)="cambiarFocoNac($event)"
                                                ></p-dropdown>
                                </div> 
                            </div>
                            <div class="p-grid">
                                <div class="p-col-1">	
                                    <span>Causa Salida:</span>
                                </div>
                                <div class="p-col-2">
                                    <p-dropdown [options]="valCausaSalida" [style]="{'width': '100%'}"
                                                [(ngModel)]="valCausaSal" optionLabel="refnombreb"
                                                [panelStyle]="{'width':'110%'}"
                                                (onChange)="cambioCausaSal($event)" (keydown.enter)="cambiarFocoNac($event)"
                                                ></p-dropdown>
                                </div>
                                <div class="p-col-1">	
                                    <span>Detalle Salida:</span>
                                </div>
                                <div class="p-col-3">
                                    <input type="text" id="inpDETASALI" autocomplete="off" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" [(ngModel)]="selectedNomEmpl.EMP_DETASALI" style="width: 100%" pInputText>
                                </div>
                                <div class="p-col-1">	
                                    <span>Departamento:</span>
                                </div>
                                <div class="p-col-3">
                                    <p-dropdown [options]="cmbDepart" [style]="{'width': '100%'}"
                                                [(ngModel)]="valorDepart" optionLabel="depnombre"
                                                [panelStyle]="{'width':'110%'}"
                                                (onChange)="cambioDepart($event)" (keydown.enter)="cambiarFocoNac($event)"
                                                ></p-dropdown>
                                </div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span >Jefe inmediato:</span>
                                </div>
                                <div class="p-col-2">
                                    <label ></label>
                                    <input class="frm" id="inpjefeInmediato" type="text" pInputText
                                            [(ngModel)]="selectedNomEmpl.EMP_JEFEINMD"
                                            
                                            (keydown.enter)="busqueJefInme(selectedNomEmpl.EMP_JEFEINMD)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                </div>
                                <div class="p-col-1">
                                    <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busqueJefInme(selectedNomEmpl.EMP_JEFEINMD)"></button>
                                </div>
                                <div class="p-col-3">
                                    <input type="text"   [(ngModel)]="span_JefInme" style="width: 100%" pInputText disabled autocomplete="off">
                                </div>
                            </div>
                                <div class="p-grid">
                                <div class="p-col-1">
                                    <span >Jefe externo:</span>
                                </div>
                                <div class="p-col-2">
                                    <label ></label>
                                    <input class="frm" id="inpjefeExterno" type="text" pInputText
                                            [(ngModel)]="selectedNomEmpl.EMP_JEFEEXT"
                                            (keydown.enter)="busqueJefExt(selectedNomEmpl.EMP_JEFEEXT)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                </div>
                                <div class="p-col-1">
                                    <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busqueJefExt(selectedNomEmpl.EMP_JEFEEXT)"></button>
                                </div>
                                <div class="p-col-3">
                                    <input type="text"   [(ngModel)]="span_JefExt" style="width: 100%" pInputText disabled autocomplete="off">
                                </div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span >Centro de costos:</span>
                                </div>
                                <div class="p-col-2">
                                    <label ></label>
                                    <input class="frm" id="inpcentroCostos" type="text" pInputText
                                            [(ngModel)]="selectedNomEmpl.EMP_CENTCOST"
                                            (keydown.enter)="busCentroCostos(selectedNomEmpl.EMP_CENTCOST)" (keydown)="botonesmodificar1()"
                                            autocomplete="off">
                                </div>
                                <div class="p-col-1">
                                    <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busCentroCostos(selectedNomEmpl.EMP_CENTCOST)"></button>
                                </div>
                                
                                <div class="p-col-3">
                                    <input id="span_centroCos" type="text" [(ngModel)]="span_centroCos" pInputText disabled autocomplete="off"  >
                                </div>
                                <div class="p-col-2">
                                    <span >Cuenta Contable:</span>
                                </div>
                                <div class="p-col-2">
                                    <label ></label>
                                    <input class="frm" id="inpcuentaConta" type="text" pInputText
                                            [(ngModel)]="selectedNomEmpl.EMP_CONCODIGO"
                                            (keydown.enter)="busCuentaCons(selectedNomEmpl.EMP_CONCODIGO)" (keydown)="botonesmodificar1()"
                                            autocomplete="off">
                                </div>
                                <div class="p-col-1">
                                    <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busCuentaCons(selectedNomEmpl.EMP_CONCODIGO)"></button>
                                </div>
                        
                            </div>
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span >Cargo en la Empresa:</span>
                                </div>
                                <div class="p-col-2">
                                    <label for="inpCargEmpr"></label>
                                    <input class="frm" id="inpCargEmpr" type="text" pInputText
                                            [(ngModel)]="selectedNomEmpl.EMP_CARGO"
                                            (keydown.enter)="busCarEmpr(selectedNomEmpl.EMP_CARGO)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                </div>
                                <div class="p-col-1">
                                    <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busCarEmpr(selectedNomEmpl.EMP_CARGO)"></button>
                                </div>
                                <div class="p-col-2">
                                    <input id="span_CargEmpr" type="text" [(ngModel)]="span_CarEmpr" pInputText disabled autocomplete="off" >
                                </div>
                                <div class="p-col-1">
                                    <span >Cargo en el IESS:</span>
                                </div>
                                <div class="p-col-2">
                                    <label ></label>
                                    <input class="frm" id="inpCargIess" type="text" pInputText
                                            [(ngModel)]="selectedNomEmpl.EMP_CARGIESS"
                                            (keydown.enter)="busCargIees(selectedNomEmpl.EMP_CARGIESS)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                </div>
                                <div class="p-col-1">
                                    <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busCargIees(selectedNomEmpl.EMP_CARGIESS)"></button>
                                </div>
                                <div class="p-col-2">
                                    <input id="span_CargIess" type="text" [(ngModel)]="span_CarIess" pInputText disabled autocomplete="off">
                                </div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Establecimiento donde trabaja:</span>
                                </div>
                                <div class="p-col-2">
                                    <label for="inpEstTrab"></label>
                                    <input class="frm" id="inpEstTrab" type="text" pInputText
                                            [(ngModel)]="selectedNomEmpl.EMP_ESTATRAB"
                                            (keydown.enter)="busEstTrab(selectedNomEmpl.EMP_ESTATRAB)" (keydown)="botonesmodificar1()"
                                            autocomplete="off">
                                </div>
                                <div class="p-col-1">
                                    <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busEstTrab(selectedNomEmpl.EMP_ESTATRAB)"></button>
                                </div>
                                <div class="p-col-2">
                                    <input id="span_EstTraba" type="text" [(ngModel)]="span_EstTrab" pInputText disabled autocomplete="off">
                                </div>
                                <div class="p-col-1">
                                    <span>Tipo Empleado:</span>
                                </div>
                                <div class="p-col-2">
                                    <label ></label>
                                    <input class="frm" id="inpTipEmple" type="text" pInputText
                                            [(ngModel)]="selectedNomEmpl.EMP_TIPOEMPL"
                                            (keydown.enter)="busTipEmpl(selectedNomEmpl.EMP_TIPOEMPL)" (keydown)="botonesmodificar1()"
                                            autocomplete="off">
                                </div>
                                <div class="p-col-1">
                                    <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busTipEmpl(selectedNomEmpl.EMP_TIPOEMPL)"></button>
                                </div>
                                <div class="p-col-2">
                                    <input id="span_TipoEmpl" type="text" [(ngModel)]="span_TipEmpl" pInputText disabled autocomplete="off">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
                <div class="divgrups">
                    <div class="p-grid">
                        <div class="p-col-12">
                            <div class="p-grid">
                                <div class="p-col-1">	
                                    <span>Dirección:</span>
                                </div>
                                <div class="p-col-7">
                                    <input type="text" id="inpEMPDIRECCION" (keydown)="botonesmodificar()" (keydown.enter)="setFocus($event)" [(ngModel)]="selectedNomEmpl.EMP_DIRECCION" style="width: 100%" pInputText autocomplete="off">
                                </div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-1">	
                                    <span>Calle:</span>
                                </div>
                                <div class="p-col-7">
                                    <input type="text" id="inpEMPCALLE" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" [(ngModel)]="selectedNomEmpl.EMP_CALLE"  style="width: 100%" pInputText autocomplete="off">
                                </div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-1">	
                                    <span>Casa:</span>
                                </div>
                                <div class="p-col-7">
                                    <input type="text" id="inpEMPCASA" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" [(ngModel)]="selectedNomEmpl.EMP_CASA" style="width: 100%" pInputText autocomplete="off">
                                </div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Provincia:</span>
                                </div>                          
                                <div class="p-col-3">
                                    <p-dropdown [options]="cmbProv" [style]="{'width': '100%'}"
                                                [(ngModel)]="valorProv" optionLabel="provnombre"
                                                [panelStyle]="{'width':'110%'}"
                                                (onChange)="cambioProv()" (keydown.enter)="cambiarFocoNac($event)"
                                                ></p-dropdown>
                                </div>
                                <div class="p-col-1">
                                    <span>Ciudad:</span>
                                </div>                          
                                <div class="p-col-3">
                                    <ng-container *ngIf="cuidadbool==false">
                                        <p-dropdown [options]="cmbCiud" [style]="{'width': '100%'}"
                                                [panelStyle]="{'width':'110%'}"
                                                [(ngModel)]="valorCiud" optionLabel="refnombre"
                                                ></p-dropdown>
                                    </ng-container>
                                    <ng-container *ngIf="cuidadbool==true">
                                        <p-dropdown [options]="cmbCiud" [style]="{'width': '100%'}"
                                                [panelStyle]="{'width':'110%'}"
                                                [(ngModel)]="valorCiud" optionLabel="refnombre"
                                                (onChange)="cambioCiud($event)" (keydown.enter)="cambiarFocoNac($event)"
                                                ></p-dropdown>
                                    </ng-container>
                                    
                                </div>
                            </div> 
                            <div class="p-grid">
                                <div class="p-col-1">	
                                    <span>Teléfono 1:</span>
                                </div>
                                <div class="p-col-3">
                                    <input  id="inpEMPTELEFONO" (keydown.enter)="setFocus($event)" [(ngModel)]="selectedNomEmpl.EMP_TELEFONO1" (keydown)="botonesmodificar()" type="text" style="width: 100%" pInputText autocomplete="off">
                                </div>
                                <div class="p-col-1">	
                                    <span>Teléfono 2:</span>
                                </div>
                                <div class="p-col-3">
                                    <input id="inp2EMPTELEFONO" (keydown.enter)="setFocus($event)" [(ngModel)]="selectedNomEmpl.EMP_TELEFONO2" (keydown)="botonesmodificar()" type="text" style="width: 100%" pInputText autocomplete="off">
                                </div>
                            </div> 
                        </div>                 
                    </div>
                </div>
                <div class="divgrups">
                    <div class="p-grid">
                        <div class="p-col-12">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Tipo Rol:</span>
                                </div>
                                <div class="p-col-2">
                                    <label ></label>
                                    <input class="frm" id="inpTipRol" type="text" pInputText
                                            [(ngModel)]="selectedNomEmpl.TIPROL_CODIGO"
                                            (keydown.enter)="busTipoRol(selectedNomEmpl.TIPROL_CODIGO)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                </div>
                                <div class="p-col-1">
                                    <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busTipoRol(selectedNomEmpl.TIPROL_CODIGO)"></button>
                                </div>
                                <div class="p-col-3">
                                    <input id="span_TipRol" type="text" [(ngModel)]="span_TipoRol" pInputText disabled autocomplete="off">
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                </div>
            </p-scrollPanel>
            </p-tabPanel>
        <!-- </form> -->
        <p-tabPanel header="Valores" [disabled]="disabledTab6" *ngIf="bolvaloresActi==true">
        <!-- <p-tabPanel header="Valores" [disabled]="disabledTab6"> -->
            <p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 270px)'}">
                <div class="divgrups">
                    <div class="p-grid">
                        <div class="p-col-12">
                            <div class="p-grid">
                                <div class="p-col-2">
                                    <span class="obligatorio">Ingresos</span>
                                </div>
                            </div>
                            <div class="p-grid" style="margin-bottom: 5px;">
                                <div class="p-col-2">
                                
                                </div>
                                <div class="p-col-2">
                                    <span class="obligatorio">Valor</span>
                                </div>
                                <div class="p-col-2">
                                    <span class="obligatorio">Cta Contable</span>
                                </div>
                                <div class="p-col-1">

                                </div>
                                <div class="p-col-1" style="margin-right: 50px;">
                                    <span class="obligatorio">Valor</span>
                                </div>
                                <div class="p-col-2">
                                    <span class="obligatorio">Cta Contable</span>
                                </div>
                            </div>
                            
                            <div class="p-grid" style="margin-bottom: 5px;">
                                
                                    <div class="p-col-2">
                                        <span>Remuneración Salarial Unificada:</span>
                                    </div>
                                    <div class="p-col-2">
                                        <input type="text" id="inpEMPREMSALUNI" (keydown.enter)="setFocus($event)"  (keydown)="botonesmodificar1()" [(ngModel)]="selectedNomEmpl.EMP_REMSALUNI" style="width: 100%;text-align: right;"  autocomplete="off" pInputText>
                                    </div>
                                    <div class="p-col-1">                                
                                        <input class="frm" id="inpCODIREMUSALAUNIF" type="text" pInputText
                                                    [(ngModel)]="selectedNomEmpl.CON_CODIREMUSALAUNIF"
                                                    
                                                    (keydown.enter)="busCODIREMUSALAUNIF(selectedNomEmpl.CON_CODIREMUSALAUNIF)" (keydown)="botonesmodificar1()"
                                                    autocomplete="off">
                                    </div>
                                    <div style="margin-left: 10px;margin-right: 10px;">
                                        <button type="button"
                                                        style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                        (click)="busCODIREMUSALAUNIF(selectedNomEmpl.CON_CODIREMUSALAUNIF)"></button>
                                    </div>
                                
                                    <div class="p-col-1">
                                        <span>Alimentación:</span>
                                    </div>
                                    <div class="p-col-2">
                                        <input type="text" id="inpALIMENTACION" (keydown)="botonesmodificar1()"  (keydown.enter)="setFocus($event)" [(ngModel)]="selectedNomEmpl.EMP_ALIMENTACION" style="width: 100%;text-align: right;"  autocomplete="off" pInputText>
                                    </div>
                                    <div class="p-col-1">                                
                                        <input class="frm" id="inpCODIALIM" type="text" pInputText
                                                [(ngModel)]="selectedNomEmpl.CON_CODIALIM" (keydown)="botonesmodificar1()"
                                                (keydown.enter)="busCON_CODIALIM(selectedNomEmpl.CON_CODIALIM)" 
                                                autocomplete="off">
                                    </div>
                                    <div style="margin-left: 10px;margin-right: 10px;">
                                        <button type="button"
                                                    style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                    (click)="busCON_CODIALIM(selectedNomEmpl.CON_CODIALIM)"></button>
                                    </div>
                            </div>
                            
                            <div class="p-grid" style="margin-bottom: 5px;">
                                <div class="p-col-2">
                                    <span>Bonificación por Responsabilidad:</span>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" id="inpBONRESP" (keydown)="botonesmodificar1()" (keydown.enter)="setFocus($event)" [(ngModel)]="selectedNomEmpl.EMP_BONRESP" style="width: 100%;text-align: right;"  autocomplete="off" pInputText>
                                </div>
                                <div class="p-col-1">                                
                                    <input class="frm" id="inpCODIBONIRESP" type="text" pInputText
                                            [(ngModel)]="selectedNomEmpl.CON_CODIBONIRESP"
                                            (keydown.enter)="busCON_CODIBONIRESP(selectedNomEmpl.CON_CODIBONIRESP)" (keydown)="botonesmodificar1()"
                                            autocomplete="off">
                                </div>
                                <div style="margin-left: 10px;margin-right: 10px;">
                                    <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busCON_CODIBONIRESP(selectedNomEmpl.CON_CODIBONIRESP)"></button>
                                </div>
                                <div class="p-col-1">
                                    <span>{{etiqueta1}}:</span>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" id="inpETI1ING" (keydown)="botonesmodificar1()" (keydown.enter)="setFocus($event)" [(ngModel)]="selectedNomEmpl.EMP_ETI1ING" style="width: 100%;text-align: right;"  autocomplete="off" pInputText>
                                </div>
                                <div class="p-col-1">                                
                                    <input class="frm" id="inpCODIETI1ING" type="text" pInputText
                                            [(ngModel)]="selectedNomEmpl.CON_CODIETI1ING"
                                            (keydown)="botonesmodificar1()"
                                            (keydown.enter)="busCON_CODIETI1ING(selectedNomEmpl.CON_CODIETI1ING)" (keydown)="botonesmodificar1()"
                                            autocomplete="off">
                                </div>
                                <div style="margin-left: 10px;margin-right: 10px;">
                                    <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busCON_CODIETI1ING(selectedNomEmpl.CON_CODIETI1ING)"></button>
                                </div>
                            </div>
                            <div class="p-grid" style="margin-bottom: 5px;">
                                <div class="p-col-2">
                                    <span>Otros Ingresos Gravables:</span>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" id="inpCOMPSALA" (keydown)="botonesmodificar1()" (keydown.enter)="setFocus($event)" [(ngModel)]="selectedNomEmpl.EMP_COMPSALA" style="width: 100%;text-align: right;"  autocomplete="off" pInputText>
                                </div>
                                <div class="p-col-1">                                
                                    <input class="frm" id="inpCTACOMPSALA" type="text" pInputText
                                            [(ngModel)]="selectedNomEmpl.EMP_CTACOMPSALA"
                                            (keydown.enter)="busCTACOMPSALA(selectedNomEmpl.EMP_CTACOMPSALA)" (keydown)="botonesmodificar1()"
                                            autocomplete="off">
                                </div>
                                <div style="margin-left: 10px;margin-right: 10px;">
                                    <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busCTACOMPSALA(selectedNomEmpl.EMP_CTACOMPSALA)"></button>
                                </div>
                                <div class="p-col-1">
                                    <span>{{etiqueta2}}:</span>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" id="inpETI2ING" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar1()" [(ngModel)]="selectedNomEmpl.EMP_ETI2ING" style="width: 100%;text-align: right;"  autocomplete="off" pInputText>
                                </div>
                                <div class="p-col-1">                                
                                    <input class="frm" id="inpCODIETI2ING" type="text" pInputText
                                            [(ngModel)]="selectedNomEmpl.CON_CODIETI2ING"
                                            (keydown.enter)="busCON_CODIETI2ING(selectedNomEmpl.CON_CODIETI2ING)" (keydown)="botonesmodificar1()"
                                            autocomplete="off">
                                </div>
                                <div style="margin-left: 10px;margin-right: 10px;">
                                    <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busCON_CODIETI2ING(selectedNomEmpl.CON_CODIETI2ING)"></button>
                                </div>
                            </div> 
                            <div class="p-grid" style="margin-bottom: 5px;">
                                <div class="p-col-3">
                                    <span>Ley de Galápagos 80% sueldo:</span>
                                </div>
                                <div class="p-col-1">
                                    <p-checkbox class="txtAlign" id="chkEMP_CALC75" [(ngModel)]="chkEMP_CALC75"
                                                label="" binary="true"
                                                 (click)="ecalcchk()" 
                                                (keydown.enter)="setFocus($event)"></p-checkbox>
                                </div>
                                <div class="p-col-1">                                
                                    <input class="frm" id="inpCTACALC75" type="text" pInputText
                                            [(ngModel)]="selectedNomEmpl.EMP_CTACALC75"
                                            (keydown.enter)="busCTACALC75(selectedNomEmpl.EMP_CTACALC75)" (keydown)="botonesmodificar1()"
                                            autocomplete="off">
                                </div>
                                <div style="margin-left: 10px;margin-right: 10px;">
                                    <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busCTACALC75(selectedNomEmpl.EMP_CTACALC75)"></button>
                                </div>
                                
                            </div> 
                        </div>
                    </div>
                    
                </div>
                <div class="divgrups">
                    <div class="p-grid">
                        <div class="p-col-12">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span class="obligatorio">Descuentos</span>
                                </div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-2">
                                
                                </div>
                                <div class="p-col-2">
                                    <span class="obligatorio">Valor</span>
                                </div>
                                <div class="p-col-2">
                                    <span class="obligatorio">Cta Contable</span>
                                </div>
                                <div class="p-col-1">

                                </div>
                                <div class="p-col-1" style="margin-right: 50px;">
                                    <span class="obligatorio">Valor</span>
                                </div>
                                <div class="p-col-2">
                                    <span class="obligatorio">Cta Contable</span>
                                </div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-2">
                                    <span>Anticipo quincenal:</span>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" id="inpANTICIPO" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar1()" [(ngModel)]="selectedNomEmpl.EMP_ANTICIPO" style="width: 100%;text-align: right;"  autocomplete="off" pInputText>
                                </div>
                                <div class="p-col-1">                                
                                    <input class="frm" id="inpCODIANTI" type="text" pInputText
                                            [(ngModel)]="selectedNomEmpl.CON_CODIANTI"
                                            (keydown.enter)="busCON_CODIANTI(selectedNomEmpl.CON_CODIANTI)" (keydown)="botonesmodificar1()"
                                            autocomplete="off">
                                </div>
                                <div style="margin-left: 10px;margin-right: 10px;">
                                    <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busCON_CODIANTI(selectedNomEmpl.CON_CODIANTI)"></button>
                                </div>
                                <div class="p-col-1">
                                    <span>Seguro P:</span>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" id="inpSEGUROFP" (keydown.enter)="setFocus($event)" [(ngModel)]="selectedNomEmpl.EMP_SEGUROFP" style="width: 100%;text-align: right;"  autocomplete="off" pInputText>
                                </div>
                                <div class="p-col-1">                                
                                    <input class="frm" id="inpCODISEGUPRIV" type="text" pInputText
                                            [(ngModel)]="selectedNomEmpl.CON_CODISEGUPRIV"
                                            (keydown.enter)="busCON_CODISEGUPRIV(selectedNomEmpl.CON_CODISEGUPRIV)" (keydown)="botonesmodificar1()"
                                            autocomplete="off">
                                </div>
                                <div style="margin-left: 15px;">
                                    <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busCON_CODISEGUPRIV(selectedNomEmpl.CON_CODISEGUPRIV)"></button>
                                </div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-2">
                                    <span>Préstamo quirograf.:</span>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" id="inpPRESIESS" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar1()" [(ngModel)]="selectedNomEmpl.EMP_PRESIESS" style="width: 100%;text-align: right;"  autocomplete="off" pInputText>
                                </div>
                                <div class="p-col-1">                                
                                    <input class="frm" id="inpCODIPRESIESSQ" type="text" pInputText
                                            [(ngModel)]="selectedNomEmpl.CON_CODIPRESIESSQ"
                                            (keydown.enter)="busCON_CODIPRESIESSQ(selectedNomEmpl.CON_CODIPRESIESSQ)" (keydown)="botonesmodificar1()"
                                            autocomplete="off">
                                </div>
                                <div style="margin-left: 10px;margin-right: 10px;">
                                    <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busCON_CODIPRESIESSQ(selectedNomEmpl.CON_CODIPRESIESSQ)"></button>
                                </div>
                                <div class="p-col-1">
                                    <span>Movilización:</span>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" id="inpMOVILIZACION" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar1()" [(ngModel)]="selectedNomEmpl.EMP_MOVILIZACION" style="width: 100%;text-align: right;"  autocomplete="off" pInputText>
                                </div>
                                <div class="p-col-1">                                
                                    <input class="frm" id="inpCODIMOVI"  type="text" pInputText
                                            [(ngModel)]="selectedNomEmpl.CON_CODIMOVI"
                                            (keydown.enter)="busCON_CODIMOVI(selectedNomEmpl.CON_CODIMOVI)" (keydown)="botonesmodificar1()"
                                            autocomplete="off">
                                </div>
                                <div style="margin-left: 15px;">
                                    <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busCON_CODIMOVI(selectedNomEmpl.CON_CODIMOVI)"></button>
                                </div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-2">
                                    <span>Préstamo hipotec.:</span>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" id="inpPRESTHIPOTECARIO" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar1()" [(ngModel)]="selectedNomEmpl.EMP_PRESTHIPOTECARIO" style="width: 100%;text-align: right;"  autocomplete="off" pInputText>
                                </div>
                                <div class="p-col-1">                                
                                    <input class="frm" id="inpCODIPRESHIPO" type="text" pInputText
                                            [(ngModel)]="selectedNomEmpl.CON_CODIPRESHIPO"
                                            (keydown.enter)="busCON_CODIPRESHIPO(selectedNomEmpl.CON_CODIPRESHIPO)" (keydown)="botonesmodificar1()"
                                            autocomplete="off">
                                </div>
                                <div style="margin-left: 10px;margin-right: 10px;">
                                    <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busCON_CODIPRESHIPO(selectedNomEmpl.CON_CODIPRESHIPO)"></button>
                                </div>
                                <div class="p-col-1">
                                    <span>Teléfono:</span>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" id="inpTELEFONO" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar1()" [(ngModel)]="selectedNomEmpl.EMP_TELEFONO" style="width: 100%;text-align: right;"  autocomplete="off" pInputText>
                                </div>
                                <div class="p-col-1">                                
                                    <input class="frm" id="inpCODITELE" type="text" pInputText
                                            [(ngModel)]="selectedNomEmpl.CON_CODITELE"
                                            (keydown.enter)="busCON_CODITELE(selectedNomEmpl.CON_CODITELE)" (keydown)="botonesmodificar1()"
                                            autocomplete="off">
                                </div>
                                <div style="margin-left: 15px;">
                                    <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busCON_CODITELE(selectedNomEmpl.CON_CODITELE)"></button>
                                </div>
                            </div> 
                            <div class="p-grid">
                                <div class="p-col-2">
                                    <span>Préstamo empresa:</span>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" id="inpPRESTAMO" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar1()" [(ngModel)]="selectedNomEmpl.EMP_PRESTAMO" style="width: 100%;text-align: right;"  autocomplete="off" pInputText>
                                </div>
                                <div class="p-col-1">                                
                                    <input class="frm" id="inpCODIGOPRES" type="text" pInputText
                                            [(ngModel)]="selectedNomEmpl.CON_CODIGOPRES"
                                            (keydown.enter)="busCON_CODIGOPRES(selectedNomEmpl.CON_CODIGOPRES)" (keydown)="botonesmodificar1()"
                                            autocomplete="off">
                                </div>
                                <div style="margin-left: 10px;margin-right: 10px;">
                                    <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busCON_CODIGOPRES(selectedNomEmpl.CON_CODIGOPRES)"></button>
                                </div>
                                
                            </div> 
                        </div>
                    </div>
                    
                </div>
                <div class="divgrups">
                    <div class="p-grid">
                        <div class="p-col-12">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span class="obligatorio">Provisiones</span>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div class="p-grid">
                            <div class="p-col-12">
                                <div class="p-grid">
                                    <div  class="p-col-1"></div>   
                                    <div class="p-col-4 maeempleadobox">                                   
                                                    <div style="width: 100%;">
                                                        <span class="obligatorio">Fondos de Reserva</span>
                                                    </div>                                
                                                    <div style="width: 100%; display: flex; flex-flow: row;" >
                                                        <div  style="width: 10%;"></div>
                                                            <div style="width: 10%;">
                                                                <p-checkbox class="txtAlign" 
                                                                            label="" binary="true"
                                                                            [(ngModel)]="chkEMP_FNDSRSRVA" (click)="fndsrsrvachk()"
                                                                            (keydown.enter)="setFocus($event)"></p-checkbox>
                                                                
                                                            </div> 
                                                            <div style="width: 30%;">
                                                                <label >Calcular</label>
                                                            </div>                                   
                                                            <div  style="display: flex; flex-flow: row;width: 50%;">
                                                                <div class="p-field-radiobutton" >
                                                                    <p-radioButton  value="val1" (click)="fondosreserva()" [(ngModel)]="pagarretener"></p-radioButton>
                                                                        <label for="">Pagar</label>
                                                                </div>   
                                                                <div class="p-field-radiobutton" style="margin-left: 10px;">
                                                                    <p-radioButton (click)="fondosreserva()" [(ngModel)]="pagarretener" value="val2"></p-radioButton>
                                                                        <label for="">Retener</label>
                                                                </div>   
                                                                
                                                            </div>
                                                    </div>
                                            
                                    </div>
                                    <div  class="p-col-1"></div>
                                    <div class="p-col-5 maeempleadobox">
                                        <div style="width: 100%;">
                                            <span class="obligatorio">Vacaciones</span>
                                        </div>                                
                                        <div style="width: 100%; display: flex; flex-flow: row;" >
                                            <div  style="width: 10%;"></div>
                                                <div style="width: 10%;">
                                                    <p-checkbox class="txtAlign" id="chkEMP_VACACALC"
                                                                label="" binary="true"
                                                                [(ngModel)]="chkEMP_VACACALC" (click)="vaccalcchk()"
                                                                (keydown.enter)="setFocus($event)"></p-checkbox>
                                                    
                                                </div> 
                                                <div style="width: 15%;">
                                                    <label >Calcular</label>
                                                </div>                                   
                                                <div  style="display: flex; flex-flow: row;width: 35%;">
                                                    <div class="p-field-radiobutton" >
                                                        <p-radioButton value="val1" (click)="vacaciones()" [(ngModel)]="cvacaciones"></p-radioButton>
                                                            <label for="">Pagar</label>
                                                    </div>   
                                                    <div class="p-field-radiobutton" style="margin-left: 10px;">
                                                        <p-radioButton  value="val2" (click)="vacaciones()" [(ngModel)]="cvacaciones"></p-radioButton>
                                                            <label for="">Retener</label>
                                                    </div>   
                                        
                                                </div>
                                                <div style="display: flex; flex-flow: row;width: 30%;">
                                                </div>
                                        </div>
                                    </div>
                                    <div  class="p-col-1"></div>
                                </div>
                            </div>
                            <div class="p-col-12">
                                <div class="p-grid">
                                    <div  class="p-col-1"></div>  
                                    <div class="p-col-4 maeempleadobox">                                   
                                                    <div style="width: 100%;">
                                                        <span class="obligatorio">Décimo Tercero</span>
                                                    </div>                                
                                                    <div style="width: 100%; display: flex; flex-flow: row;" >
                                                        <div  style="width: 10%;"></div>
                                                            <div style="width: 10%;">
                                                                <p-checkbox class="txtAlign" id="chkEMP_DECITERCCALC"
                                                                            label="" binary="true"
                                                                            [(ngModel)]="chkEMP_DECITERCCALC" (click)="decimotercerchk()"
                                                                            (keydown.enter)="setFocus($event)"></p-checkbox>
                                                                
                                                            </div> 
                                                            <div style="width: 30%;">
                                                                <label >Calcular</label>
                                                            </div>                                   
                                                            <div  style="display: flex; flex-flow: row;width: 50%;">
                                                                <div class="p-field-radiobutton" >
                                                                    <p-radioButton  value="val1"  (click)="decimotercero()" [(ngModel)]="rdecimotercero"></p-radioButton>
                                                                        <label for="">Pagar</label>
                                                                </div>   
                                                                <div class="p-field-radiobutton" style="margin-left: 10px;">
                                                                    <p-radioButton  value="val2"  (click)="decimotercero()" [(ngModel)]="rdecimotercero"></p-radioButton>
                                                                        <label for="">Retener</label>
                                                                </div>   
                                                    
                                                            </div>
                                                    </div>
                                            
                                    </div>
                                    <div  class="p-col-1"></div>
                                    <div class="p-col-5 maeempleadobox">
                                        <div style="width: 100%;">
                                            <span class="obligatorio">Décimo Cuarto</span>
                                        </div>                                
                                        <div style="width: 100%; display: flex; flex-flow: row;" >
                                                <div  style="width: 10%;"></div>
                                                <div style="width: 10%;">
                                                    <p-checkbox class="txtAlign" id="chkEMP_DECICUARCALC"
                                                                label="" binary="true"
                                                                [(ngModel)]="chkEMP_DECICUARCALC" (click)="decimocuartochk()"
                                                                (keydown.enter)="setFocus($event)"></p-checkbox>
                                                    
                                                </div> 
                                                <div style="width: 15%;">
                                                    <label >Calcular</label>
                                                </div>                                   
                                                <div  style="display: flex; flex-flow: row;width: 35%;">
                                                    <div class="p-field-radiobutton" >
                                                        <p-radioButton value="val1" (click)="decimocuarto()" [(ngModel)]="rdecimocuarto"></p-radioButton>
                                                            <label for="">Pagar</label>
                                                    </div>   
                                                    <div class="p-field-radiobutton" style="margin-left: 10px;">
                                                        <p-radioButton value="val2" (click)="decimocuarto()" [(ngModel)]="rdecimocuarto"></p-radioButton>
                                                            <label for="">Retener</label>
                                                    </div>   
                                                    
                                                </div>
                                                <div style="display: flex; flex-flow: row;width: 30%;">
                                                    <div >                                
                                                        <input class="frm" id="CON_CODITELE" type="text" pInputText
                                                                [(ngModel)]="selectedNomEmpl.EMP_DECICUARTREGI"
                                                                (keydown.enter)="busEMP_DECICUARTREGI(selectedNomEmpl.EMP_DECICUARTREGI)" (keydown)="botonesmodificar1()"
                                                                autocomplete="off">
                                                    </div>
                                                    <div style="margin-left: 10px;margin-right: 10px;">
                                                        <button type="button"
                                                                    style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                                    (click)="busEMP_DECICUARTREGI(selectedNomEmpl.EMP_DECICUARTREGI)"></button>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                    <div  class="p-col-1"></div>
                                </div>
                            </div>
                        </div>        
                </div>
                <div class="divgrups">
                <div class="p-grid">
                    <div class="p-col-12">
                        <div class="p-grid">
                            <div class="p-col-1">
                                <span class="obligatorio">Porcentajes</span>
                            </div>
                            
                        </div>
                        
                    </div>
                    <div class="p-col-12">
                        <div class="p-grid">
                            <div class="p-col-2">
                                
                            </div>
                            <div class="p-col-1">
                                <span>%Aporte Individual</span>
                            </div>
                            <div class="p-col-1">
                                <input type="text" id="inpPORCAPORINDI" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar1()" [(ngModel)]="selectedNomEmpl.EMP_PORCAPORINDI" style="width: 100%;text-align: right;"  autocomplete="off" pInputText>
                            </div>
                            <div class="p-col-1">
                                <span>%Aporte Patronal</span>
                            </div>
                            <div class="p-col-1">
                                <input type="text" id="inpPORCAPORPATR" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar1()" [(ngModel)]="selectedNomEmpl.EMP_PORCAPORPATR" style="width: 100%;text-align: right;"  autocomplete="off" pInputText>
                            </div>
                            <div class="p-col-1">
                                <span>%Fondos de Reserva</span>
                            </div>
                            <div class="p-col-1">
                                <input type="text" id="inpPORCFONDRESER" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar1()" [(ngModel)]="selectedNomEmpl.EMP_PORCFONDRESER" style="width: 100%;text-align: right;"  autocomplete="off" pInputText>
                            </div>
                        </div>                        
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col-12">
                        <div class="p-grid">
                        </div>
                    </div>
                </div>
            </div>
             </p-scrollPanel>
        </p-tabPanel>
        <!--Aqui Jona Otros Valores-->
        <p-tabPanel header="Otros Datos" [disabled]="disabledTab1">
            <p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 270px)'}">
                <div *ngIf="otrosDatos==true">
                    <div class="divgrups">
                        <div class="p-grid">
                            <div class="p-col-12">
                            
                                    <div class="p-grid">

                                        <div class="p-col-2">	
                                            <span>%Discapacidad</span>
                                        </div>
                                        <div class="p-col-2">
                                            <input type="text" id="inpPORCDISC" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar2()" [(ngModel)]="selectedNomEmpl.EMP_PORCDISC" style="width: 100%" pInputText>
                                        </div>
                                        <div class="p-col-2">	
                                            <span>Carnet Conadis</span>
                                        </div>
                                        <div class="p-col-2">
                                            <input type="text" id="inpCARNCONA" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar2()"  [(ngModel)]="selectedNomEmpl.EMP_CARNCONA" style="width: 100%" pInputText>
                                        </div>
                                    </div>
                                    <div class="p-grid">
                                        <div class="p-col-2">	
                                            <span>Detalle Discapacidad</span>
                                        </div>
                                        <div class="p-col-2">
                                            <input type="text" id="inpDETADISC" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar2()" [(ngModel)]="selectedNomEmpl.EMP_DETADISC" style="width: 100%" pInputText>
                                        </div>
                                    </div>
                                    <div class="p-grid">
                                        <div class="p-col-2">	
                                            <span>Condición del Trabajador con Respecto a Discapacidades:</span>
                                        </div>
                                        <div class="p-col-2">
                                            <p-dropdown [options]="cmbConTrabRescDisc" [style]="{'width': '100%'}"
                                                        [(ngModel)]="valorConTrabRescDisc"
                                                        optionLabel="CODSRI_DESCRIPCION"
                                                        [panelStyle]="{'width':'110%'}"
                                                        (onChange)="cambioConTrabRescDisc($event)" (keydown.enter)="cambiarFocoNac($event)"
                                                        ></p-dropdown>
                                        </div>
                                    
                                    </div>
                                    <div class="p-grid">
                                        <div class="p-col-2">	
                                            <span>Cédula/Pasaporte:</span>
                                        </div>
                                        <div class="p-col-2">
                                            <input type="text" id="inpRUCIDEDISCSUST" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar2()" [(ngModel)]="selectedNomEmpl.EMP_RUCIDEDISCSUST" style="width: 100%" pInputText>
                                        </div>
                                        <div class="p-col-2">	
                                            <span>Nombre</span>
                                        </div>
                                        <div class="p-col-2">
                                            <input type="text"  id="inpNOMBRDISCSUST" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar2()"  [(ngModel)]="selectedNomEmpl.EMP_NOMBRDISCSUST" style="width: 100%" pInputText>
                                        </div>
                                    </div> 
                            </div>
                        </div>
                    </div> 
                    <div class="divgrups" style="height: 100px;">
                        <div class="p-grid">
                            <div class="p-col-12">
                            
                                    <div class="p-grid">

                                        <div class="p-col-2">	
                                            <span>Enfermedad Catastrofica</span>
                                        </div>
                                        <div class="p-col-1">
                                            <p-dropdown [options]="cmbEnfermedadCatastrof" [style]="{'width': '100%'}"
                                                        [(ngModel)]="valorEnfermedadCatastrof"
                                                        optionLabel="EMP_ENFCATASTROFICA"
                                                        [panelStyle]="{'width':'100%'}"
                                                        (onChange)="cambioEnfermCatastrof($event)"
                                                        ></p-dropdown>
                                        </div>
                                        
                                    </div>
                                    
                                    
                                   
                            </div>
                        </div>
                    </div>
                    <div class="divgrups">
                        <div class="p-grid">
                            <div class="p-col-12">
                                <div class="p-grid">
                                    <div class="p-col-3">	
                                        <span>Empresa asume impuesto a la Renta (Si o No):</span>
                                    </div>
                                    <p-checkbox class="txtAlign" id="chkImpRent"
                                    label="" binary="true"
                                    [(ngModel)]="chkImpRent" (click)="impuestochk()"
                                    (keydown.enter)="setFocus($event)"></p-checkbox>
                                </div>
                            </div> 
                            <div class="p-col-12">
                                <div class="p-grid">
                                    <div class="p-col-3">	
                                        <span>Tipo de Sistema Salario Neto:</span>
                                    </div>
                                
                                    <div class="p-col-2">
                                        <p-dropdown [options]="cmbTIPOSISTSALANETO" [style]="{'width': '100%'}"
                                                    [(ngModel)]="valorTIPOSISTSALANETO"
                                                    optionLabel="CODSRI_DESCRIPCION"
                                                    [panelStyle]="{'width':'110%'}"
                                                    (onChange)="cambioTIPOSISTSALANETO($event)" (keydown.enter)="cambiarFocoNac($event)"
                                                    ></p-dropdown>
                                    </div>
                                </div>
                            </div> 
                            <div class="p-col-12">
                                <div class="p-grid">
                                    <div class="p-col-3">	
                                        <span>Residencia del trabajador:</span>
                                    </div>
                                
                                    <div class="p-col-2">
                                        <p-dropdown [options]="cmbRESITRAB" [style]="{'width': '100%'}"
                                                    [(ngModel)]="valorRESITRAB"
                                                    optionLabel="CODSRI_DESCRIPCION"
                                                    [panelStyle]="{'width':'110%'}"
                                                    (onChange)="cambioRESITRAB($event)" (keydown.enter)="cambiarFocoNac($event)"
                                                    ></p-dropdown>
                                    </div>
                                </div>
                            </div> 
                            <div class="p-col-12">
                                <div class="p-grid">
                                    <div class="p-col-3">	
                                        <span>País de Residencia:</span>
                                    </div>
                                
                                    <div class="p-col-2">
                                        <p-dropdown [options]="cmbPAISRESI" [style]="{'width': '100%'}"
                                                    [(ngModel)]="valorPAISRESI"
                                                    optionLabel="CODSRI_DESCRIPCION"
                                                    [panelStyle]="{'width':'110%'}"
                                                    (onChange)="cambioPAISRESI($event)" (keydown.enter)="cambiarFocoNac($event)"
                                                    [disabled]="boolcmbt"
                                                    ></p-dropdown>
                                    </div>
                                </div>
                            </div> 
                            <div class="p-col-12">
                                <div class="p-grid">
                                    <div class="p-col-3">	
                                        <span>Aplica convenio para evitar doble tributación:</span>
                                    </div>
                                
                                    <div class="p-col-2">
                                        <p-dropdown [options]="cmbAPLICONVEVITDOBLTRIB" [style]="{'width': '100%'}"
                                                    [(ngModel)]="valorAPLICONVEVITDOBLTRIB"
                                                    optionLabel="CODSRI_DESCRIPCION"
                                                    [panelStyle]="{'width':'110%'}"
                                                    (onChange)="cambioAPLICONVEVITDOBLTRIB($event)" (keydown.enter)="cambiarFocoNac($event)"
                                                    [disabled]="boolcmbt"
                                                    ></p-dropdown>
                                    </div>
                                </div>
                            </div> 
                            <div class="p-col-12">
                                <div class="p-grid">
                                    <div class="p-col-3">	
                                        <span>Trabajardor Jornada Parcial (Si o No):</span>
                                    </div>
                                    <p-checkbox class="txtAlign" id="chkJorPar"
                                        label="" binary="true"
                                        [(ngModel)]="chkJorPar" (click)="joparchk()"
                                        (keydown.enter)="setFocus($event)"></p-checkbox>
                                </div>
                                
                            </div> 
                            <div class="p-col-12">
                                <div class="p-grid">
                                    <div class="p-col-3">	
                                        <span>Horas de tiempo parcial:</span>
                                    </div>
                                    <div class="p-col-2">
                                        <input type="text" id="inpHORATIEMPARC" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar2()"   [(ngModel)]="selectedNomEmpl.EMP_HORATIEMPARC" style="width: 100%" pInputText>
                                    </div>
                                </div>
                                
                            </div> 
                        </div>
                    </div>
                    <div class="divgrups">
                        <div class="p-grid">                    
                            <div class="p-col-12">
                                <div class="p-grid">
                                    <div class="p-col-2">	
                                        <span>Vendedor:</span>
                                    </div>      
                                    <div class="p-col-2">
                                        <p-dropdown [options]="cmbVEN_CODIGO" [style]="{'width': '100%'}"
                                                    [(ngModel)]="valorVEN_CODIGO"
                                                    optionLabel="VEN_NOMBRE"
                                                    [panelStyle]="{'width':'110%'}"
                                                    (onChange)="cambioVEN_CODIGO($event)" (keydown.enter)="cambiarFocoNac($event)"
                                                    ></p-dropdown>
                                    </div>
                                </div>
                                <div class="p-grid">
                                    <div class="p-col-2">
                                        <span>Cliente:</span>
                                    </div>
                                    <div class="p-col-2">
                                        <label ></label>
                                        <input class="frm" id="inpCLICODIGO" type="text" pInputText
                                                [(ngModel)]="selectedNomEmpl.CLI_CODIGO"
                                                (keydown.enter)="busCliCodigo(selectedNomEmpl.CLI_CODIGO)" (keydown)="botonesmodificar1()"
                                                autocomplete="off">
                                    </div>
                                    <div class="p-col-1">
                                        <button type="button"
                                                    style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                    (click)="busCliCodigo(selectedNomEmpl.CLI_CODIGO)"></button>
                                    </div>
                                    <div class="p-col-3">
                                        <input id="span_CliCodig"  type="text" [(ngModel)]="span_CliCodigo" pInputText disabled autocomplete="off">
                                    </div>
                                </div>
                            </div>                     
                        </div>
                    </div>
                </div> 
            </p-scrollPanel> 
        </p-tabPanel>
        
        <p-tabPanel header="Forma de Pago" [disabled]="disabledTab2">
            <div *ngIf="otrosDatos==true">
             <div class="divgrups">
                <div class="p-grid">
                    <div class="p-col-12">
                        <div class="p-grid">
                            <div class="p-col-2">
                                <span>Cta Contable Sueldo por Pagar:</span>
                            </div>
                            <div class="p-col-2">
                                <label></label>
                                <input class="frm" id="inpCODIGOSXP" type="text" pInputText
                                        [(ngModel)]="selectedNomEmpl.CON_CODIGOSXP"
                                        (keydown.enter)="busCODIGOSXP(selectedNomEmpl.CON_CODIGOSXP)" (keydown)="botonesmodificar3()"
                                        autocomplete="off">
                            </div>
                            <div class="p-col-1">
                                <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                            (click)="busCODIGOSXP(selectedNomEmpl.CON_CODIGOSXP)"></button>
                            </div>
                            <div class="p-col-3">
                                <input id="span_CODIGOSXP" type="text" (keydown.enter)="setFocus($event)" [(ngModel)]="span_CODIGOSXP" pInputText disabled autocomplete="off">
                                
                            </div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-2">
                                <span >Banco Compañía:</span>
                            </div>
                            <div class="p-col-2">
                                <label></label>
                                <input class="frm" id="inpBANCODIGOEMP" type="text" pInputText
                                        [(ngModel)]="selectedNomEmpl.BAN_CODIGOEMP"
                                        (keydown.enter)="busBAN_CODIGOEMP(selectedNomEmpl.BAN_CODIGOEMP)" (keydown)="botonesmodificar3()"
                                        autocomplete="off">
                            </div>
                            <div class="p-col-1">
                                <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                            (click)="busBAN_CODIGOEMP(selectedNomEmpl.BAN_CODIGOEMP)"></button>
                            </div>
                            <div class="p-col-3">
                                <input id="spanCODIGOEMP" type="text"  [(ngModel)]="span_CODIGOEMP" pInputText disabled autocomplete="off">
                            </div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-2">
                                <span>Banco del Empleado</span>
                            </div>
                            <div class="p-col-2">
                                <label></label>
                                <input class="frm" id="inpCODIBANCEMPL" type="text" pInputText
                                        [(ngModel)]="selectedNomEmpl.EMP_CODIBANCEMPL"
                                        (keydown.enter)="busCODIBANCEMPL(selectedNomEmpl.EMP_CODIBANCEMPL)" (keydown)="botonesmodificar3()"
                                        autocomplete="off">
                            </div>
                            <div class="p-col-1">
                                <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                            (click)="busCODIBANCEMPL(selectedNomEmpl.EMP_CODIBANCEMPL)"></button>
                            </div>
                            <div class="p-col-3">
                                <input id="spanCODIBANCEMPL"  type="text" [(ngModel)]="span_CODIBANCEMPL" pInputText disabled autocomplete="off">
                            </div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-2">
                                <span >Forma Pago:</span>
                            </div>
                            <div class="p-col-2">
                                <label></label>
                                <input class="frm" id="inpFORMPAGO" type="text" pInputText
                                        [(ngModel)]="selectedNomEmpl.EMP_FORMPAGO"
                                        (keydown.enter)="busFORMPAGO(selectedNomEmpl.EMP_FORMPAGO)" (keydown)="botonesmodificar3()"
                                        autocomplete="off">
                            </div>
                            <div class="p-col-1">
                                <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                            (click)="busFORMPAGO(selectedNomEmpl.EMP_FORMPAGO)"></button>
                            </div>
                            <div class="p-col-3">
                                <input id="spanFORMPAGO" type="text" [(ngModel)]="span_FORMPAGO" pInputText disabled autocomplete="off">
                            </div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-2">
                                <span class="obligatorio">Tipo de Cuenta:</span>
                            </div>
                            <div class="p-col-2">
                                <p-dropdown [options]="cmbTipCuent" [style]="{'width': '100%'}"
                                            [(ngModel)]="varTipCuent"
                                            optionLabel="REF_NOMBRE"
                                            [panelStyle]="{'width':'110%'}"
                                            (onChange)="cambioTipCuent($event)" 
                                            ></p-dropdown>
                            </div>
                            <div class="p-col-1">	
                                <span>Cuenta:</span>
                            </div>
                            <div class="p-col-2">
                                <input type="text" id="inpCTAAHORROS" (keydown)="botonesmodificar3()" [(ngModel)]="selectedNomEmpl.EMP_CTAAHORROS" style="width: 100%" pInputText>
                            </div>
                        </div>
                        <div class="p-grid">
                            <h1></h1>
                        </div>
                        <div class="p-grid">
                            <h1></h1>
                        </div>
                    </div>
                </div>
                
                </div>  
            </div>
        </p-tabPanel>
        <!--Ingresos/Egresos Fijos-->
        <p-tabPanel header="Ingresos/Egresos Fijos" [disabled]="disabledTab3">
            <div  *ngIf="ingregreFijos==false" style="display: flex;align-items: center;justify-content: center;">
                <p-progressSpinner *ngIf="ingregreFijos==false" [style]="{width: '200px', height: '200px'}"
                       animationDuration=".5s"></p-progressSpinner>
            </div>
            <ng-container *ngIf="ingregreFijos">
                <div class="p-grid">
                    <div class="p-col-12">
                        <div style="width: 100%;">
                            <span class="obligatorio" style="font-size: 14px;">Ingresos</span>
                        </div>  
                    </div>
                    <div class="p-col-12">
                        <app-aggridsaci #ingrFijo [width]=""
                                        [height]="largo1"
                                        [enableSorting]="true"
                                        [enableFilter]="true"
                                        [rowData]="nomempleadosService.nommaenomIngrFijo"
                                        [frameworkComponents]="frameworkComponents"
                                        [animateRows]="true"
                                        [rowSelection]="rowSelection"
                                        [columnDefs]="columnDefMaeIngrFijo"
                                        [defaultColDef]="defaultColDefMaeIngrFijo"
                                        [rowStyle]="rowStyle"
                                        [singleClickEdit]="false"
                                        [botones]="true"
                                        [mostrarGuardar]="false"
                                        (selected)="ingresosFijo($event)"
                                        (cambios)="cambioIngrFijo($event)"
                        ></app-aggridsaci> 
                    </div> 
                    <div style="width: 100%;">
                        <span class="obligatorio" style="font-size: 14px;">Egresos</span>
                    </div> 
                    <div class="p-col-12">
                        <app-aggridsaci #egreFijo [width]=""
                                        [height]="largo1"
                                        [enableSorting]="true"
                                        [enableFilter]="true"
                                        [rowData]="nomempleadosService.nommaeEgreFijo"
                                        [frameworkComponents]="frameworkComponents"
                                        [animateRows]="true"
                                        [rowSelection]="rowSelection"
                                        [columnDefs]="columnDefNomEgreFijo"
                                        [defaultColDef]="defaultColDefNomEgreFijo"
                                        [rowStyle]="rowStyle"
                                        [singleClickEdit]="false"
                                        [botones]="true"
                                        [mostrarGuardar]="false"
                                        (selected)="egresosFijo($event)"
                                        (cambios)="cambioEgrFijo($event)"
                        ></app-aggridsaci>  
                    </div>
                </div>
            </ng-container>
       </p-tabPanel>
        <!--Aqui Jona Vacaciones-->
        <p-tabPanel header="Vacaciones" [disabled]="disabledTab4">
            <div  *ngIf="vacacionestabla==false" style="display: flex;align-items: center;justify-content: center;">
                <p-progressSpinner *ngIf="vacacionestabla==false" [style]="{width: '200px', height: '200px'}"
                       animationDuration=".5s"></p-progressSpinner>
            </div>
            <ng-container *ngIf="vacacionestabla">
                <app-aggridsaci  #vaca [width]=""
                                [height]="largo2"
                                [enableSorting]="true"
                                [enableFilter]="true"
                                [rowData]="nomempleadosService.nomVacaciones"
                                [frameworkComponents]="frameworkComponents"
                                [animateRows]="true"
                                [rowSelection]="rowSelection"
                                [columnDefs]="columnDefVacaciones"
                                [defaultColDef]="defaultColDefVacaciones"
                                [rowStyle]="rowStyle"
                                [singleClickEdit]="false"
                                [botones]="false"
                                [mostrarGuardar]="false"
                                [botonesAdicionales]="false"
                                (selected)="selecVacaciones($event)"
                                (cambios)="cambioVacaciones($event)"
                ></app-aggridsaci> 
             </ng-container>
        </p-tabPanel>
        <!--Aqui Jona Cargas Familiares-->
        <p-tabPanel header="Cargas Familiares" [disabled]="disabledTab5">
            <div class="divgrups" >
                <div class="p-grid">
                    <div class="p-col-12">
                        <div class="p-grid">
                        
                            <div class="p-col-2">	
                                <span>Num. Cargas:</span>
                            </div>
                            <div class="p-col-2">
                                <input type="text" id="inp_EMP_RUCIDE"    (keydown)="botoneModCf2()" [(ngModel)]="selectedNomEmpl.EMP_NUMCARGAS"  style="width: 100%" pInputText autocomplete="off" >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div  *ngIf="carFamtabla==false" style="display: flex;align-items: center;justify-content: center;">
                <p-progressSpinner *ngIf="carFamtabla==false" [style]="{width: '200px', height: '200px'}"
                       animationDuration=".5s"></p-progressSpinner>
            </div>
            <ng-container *ngIf="carFamtabla">
                <app-aggridsaci  #CarFam [width]=""
                                [height]="largo3"
                                [enableSorting]="true"
                                [enableFilter]="true"
                                [rowData]="nomempleadosService.nomCargaFamil"
                                [frameworkComponents]="frameworkComponents"
                                [animateRows]="true"
                                [rowSelection]="rowSelection"
                                [columnDefs]="columnnomCargaFami"
                                [defaultColDef]="defaultColDefnomCargaFami"
                                [rowStyle]="rowStyle"
                                [singleClickEdit]="false"
                                [botones]="true"
                                [botonesAdicionales]="false"
                                [botonNuevo]="btnNuevoCarFam"
                                [botonGuardar]="btnGuardarCarFam"
                                [botonBorrar]="btnBorrarCarFam"
                                [botonRegresar]="btnCancelarCarFam"
                                (selected)="selecCargaFami($event)"
                                (cambios)="cambioCargaFami($event)"
                ></app-aggridsaci>
            </ng-container> 
        </p-tabPanel>
    </p-tabView>  
</div>   
<!--Busqueda Estado Civil-->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastEstCiv"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloEstCiv" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccionEstCiv($event)"></app-busquedadlg>
</p-dialog>
<!--Busqueda Sexo-->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastSex"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloSex" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccionSex($event)"></app-busquedadlg>
</p-dialog>
<!--Busqueda Centro Costos-->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastCenCos"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCenCos" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccionCentroCos($event)"></app-busquedadlg>
</p-dialog>
<!--Busqueda Cuenta Contable -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastCuenCont"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCuenCont" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccionCuenCont($event)"></app-busquedadlg>
</p-dialog>

<!--Busqueda Cargo Empresa -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastCargEmpr"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCargEmpr" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccionCarEmpr($event)"></app-busquedadlg>
</p-dialog>
<!--Busqueda Cargo Iess -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastCargIess"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCargIess" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccCargIess($event)"></app-busquedadlg>
</p-dialog>

<!--Establecimiento donde trabaja -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastEstTrab"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloEstTrab" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccionEstTrab($event)"></app-busquedadlg>
</p-dialog>
<!-- Tipo Empleado -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastTipEmpl"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloTipEmpl" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)=" manejarSeleccTipEmpl($event)"></app-busquedadlg>
</p-dialog>
<!-- Tipo Rol -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastTipRol"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloTipoRol
    " [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccTipRol($event)"></app-busquedadlg>
</p-dialog>
<!-- Jefe Inmd -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastJefInme"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="cmbJefInme" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccionJefInm($event)"></app-busquedadlg>
</p-dialog>
<!-- Jefe Ext -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastJefExt"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="cmbJefExt" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccionJefExt($event)"></app-busquedadlg>
</p-dialog>
<!-- Cliente -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastCliCodigo"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCliCodigo" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccCliCodigo($event)"></app-busquedadlg>
</p-dialog>
<!-- Cta Contable Sueldo por Pagar -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastCODIGOSXP"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCODIGOSXP" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarCODIGOSXP($event)"></app-busquedadlg>
</p-dialog>
<!-- Banco Compañia -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastBAN_CODIGOEMP"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloBAN_CODIGOEMP" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarBAN_CODIGOEMP($event)"></app-busquedadlg>
</p-dialog>
<!-- Banco Empleado -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastCODIBANCEMPL"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCODIBANCEMPL" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarCODIBANCEMPL($event)"></app-busquedadlg>
</p-dialog>

<!-- FORMA DE PAGO -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastFORMPAGO"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloFORMPAGO" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarEMP_FORMPAGO($event)"></app-busquedadlg>
</p-dialog>
<!-- Activar Valores -->
<p-dialog header="Clave Autorización" [(visible)]="displayDialogActivarValores"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  (onHide)="onHideActivarVal()"
		  [style]="{width: '40vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="autorizacion"></p-toast>
	<div class="p-grid" style="width: 98%">
		<div class="p-col-12">
			<app-invmaebarra [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"
							 [barraBotones5]="true" [botonVisto]="false" [barraBotonesCRUD]="true"
							 (signal)="manejarSenalesActivar($event)"
							 style="text-align: left;">
			</app-invmaebarra>
		</div>
	</div>
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							   (keydown.enter)="opcionVisto()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>
<!-- Remuneración Salarial Unificada -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastCODIREMUSALAUNIF"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCODIREMUSALAUNIF" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarCODIREMUSALAUNIF($event)"></app-busquedadlg>
</p-dialog>
<!-- Alimentación -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastCON_CODIALIM"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCON_CODIALIM" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarCON_CODIALIM($event)"></app-busquedadlg>
</p-dialog>
<!-- Bonificación por Responsabilidad -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastCON_CODIBONIRESP"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCON_CODIBONIRESP" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarCON_CODIBONIRESP($event)"></app-busquedadlg>
</p-dialog>
<!-- Bonificación por Responsabilidad Etiqueta-->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastCON_CODIETI1ING"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCON_CODIETI1ING" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarCON_CODIETI1ING($event)"></app-busquedadlg>
</p-dialog>
<!-- Otros Ingresos Gravables -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastCTACOMPSALA"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCTACOMPSALA" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarCTACOMPSALA($event)"></app-busquedadlg>
</p-dialog>

<!-- Otros Ingresos Gravables Etiqueta-->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastCON_CODIETI2ING"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCON_CODIETI2ING" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarCON_CODIETI2ING($event)"></app-busquedadlg>
</p-dialog>
<!-- Ley de Galápagos 75% sueldo-->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastCTACALC75"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCTACALC75" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarCTACALC75($event)"></app-busquedadlg>
</p-dialog>
<!-- Anticipo Quincenal-->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastCON_CODIANTI"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCON_CODIANTI" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarCON_CODIANTI($event)"></app-busquedadlg>
</p-dialog>
<!--  Seguro P -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastCON_CODISEGUPRIV"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCON_CODISEGUPRIV" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarCON_CODISEGUPRIV($event)"></app-busquedadlg>
</p-dialog>
<!--  Préstamo quirograf  -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastCON_CODIPRESIESSQ"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCON_CODIPRESIESSQ" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarCON_CODIPRESIESSQ($event)"></app-busquedadlg>
</p-dialog>
<!--  Movilidad  -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastCON_CODIMOVI"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCON_CODIMOVI" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarCON_CODIMOVIQ($event)"></app-busquedadlg>
</p-dialog>
<!--  Préstamo hipotec.  -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastCON_CODIPRESHIPO"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCON_CODIPRESHIPO" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarCON_CODIPRESHIPO($event)"></app-busquedadlg>
</p-dialog>
<!--  Teléfono  -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastCON_CODITELE"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCON_CODITELE" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarCON_CODITELE($event)"></app-busquedadlg>
</p-dialog>

<!--  Préstamo empresa  -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastCON_CODIGOPRES"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCON_CODIGOPRES" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarCON_CODIGOPRES($event)"></app-busquedadlg>
</p-dialog>
<!--  Préstamo empresa  -->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastEMP_DECICUARTREGI"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloEMP_DECICUARTREGI" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarEMP_DECICUARTREGI($event)"></app-busquedadlg>
</p-dialog>
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
</div>