<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="maecuenta"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarcuenta" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 99%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
						 [botonRegresar]="btnRegresar" [botonPrimero]="btnPrimero" [botonAnterior]="btnAnterior"
						 [botonSiguiente]="btnSiguiente" [botonUltimo]="btnUltimo" [barraBotonesCRUD]="false"
						 [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"
						 [barraBotones5]="false"
						 [botonBuscar]="btnBuscar"
						 [botonBinocular]="true"
						 (signal)="manejarSenales($event)"></app-invmaebarra>


	</div>

	<div class="p-col-12">
		<p-tabView [(activeIndex)]="index" (activeIndexChange)="cambioTab()">
			<p-tabPanel header="Cuenta">
				<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 275px)'}">
					<app-aggridsaci [width]=""
									[height]="largo"
									[enableSorting]="false"
									[enableFilter]="true"
									[rowData]="cuentaService.commaecuenta"
									[frameworkComponents]="frameworkComponents"
									[animateRows]="true"
									[rowSelection]="rowSelection"
									[columnDefs]="columnDefsCuenta"
									[defaultColDef]="defaultColDefCuentas"
									[rowStyle]="rowStyle"
									[singleClickEdit]="false"
									[botones]="false"
									(selected)="selectedCuenta($event)"
									(cambios)="cambio($event)"
					></app-aggridsaci>
				</p-scrollPanel>
			</p-tabPanel>
			<p-tabPanel header="Presupuesto">
				<div class="p-grid">
					<div class="p-col-2">
                    <span>
					{{cuentaContableAux}}
				</span>
					</div>
					<div class="p-col-1">
					</div>
					<div class="p-col-9">
						<span>
					{{nombreCuentaAux}}
				     </span>
					</div>
				</div>
				<app-aggridsaci [width]=""
								[height]="largo"
								[enableSorting]="false"
								[enableFilter]="true"
								[rowData]="cuentaService.conmaePresupuesto"
								[frameworkComponents]="frameworkComponents"
								[animateRows]="true"
								[rowSelection]="rowSelection"
								[columnDefs]="columnDefPresupuesto"
								[defaultColDef]="defaultColDefPresupuesto"
								[rowStyle]="rowStyle"
								[singleClickEdit]="false"
								(selected)="selectedPresupuesto($event)"
								[botones]="false"
								(cambios)="cambio($event)"

				></app-aggridsaci>
			</p-tabPanel>

			<!-- <p-tabPanel header="Mayor General">
				<app-aggridsaci [width]=""
								[height]="largo"
								[enableSorting]="true"
								[enableFilter]="true"
								[rowData]="cuentaService.conmaeMayorGeneral"
								[frameworkComponents]="frameworkComponents"
								[animateRows]="true"
								[rowSelection]="rowSelection"
								[columnDefs]="columnDefMayorGeneral"
								[defaultColDef]="defaultColDefMayorGeneral"
								[rowStyle]="rowStyle"
								[singleClickEdit]="false"
								[botones]="false"

				></app-aggridsaci>
			</p-tabPanel> -->
		</p-tabView>
	</div>
</div>
<p-dialog header="Búsqueda de datos" [(visible)]="displayDialogBus2" [responsive]="true"
		  [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '70vw', height: '70vh'}">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloBus"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlgmae [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
						[where]="condicion"
						[busquedaParams]="busquedacampos"
						(opcionSeleccionada)="manejarSeleccion($event)"
						[busquedaCompuesta]="false"></app-busquedadlgmae>
</p-dialog>




