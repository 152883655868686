import {Injectable} from '@angular/core';
import {Cxcmaeretencion} from '../cxcinterfaces/cxcmaeretencion';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Observable} from 'rxjs';
import {MessageService} from 'primeng/api';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class CxcmaeretencionService {
  get cxcmaeretencion(): Cxcmaeretencion[] {
    return this._cxcmaeretencion;
  }
  
  set cxcmaeretencion(value: Cxcmaeretencion[]) {
    this._cxcmaeretencion = value;
  }
  
 
  public way: string;
  private _cxcmaeretencion: Cxcmaeretencion[];
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, private message: MessageService,
              private confIniciales: ConfInicialesService) {
  
    this.way = sessionStorage.getItem('way');
  }
  
  getretencionClient(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/retenciones/20eeeddd75d92c00', {
        elementos: {}
    }, this.confIniciales.httpOptions());
  }
  
  insertarRetencion(retencion: Cxcmaeretencion): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/retenciones/12bf1dbeab7b770', {
      elementos: {
        p_RETENCION_codigo_1: retencion.RETENCION_CODIGO,
        p_RETENCION_nombre_2: retencion.RETENCION_NOMBRE,
        p_RETENCION_porcentaje_3: retencion.RETENCION_PORCENTAJE,
        p_con_codigo: retencion.CON_CODIGO,
        p_retencion_tipo: retencion.RETENCION_TIPO,
        p_retencion_tipoanex: retencion.RETENCION_TIPOANEX,
        p_retencion_codsri: retencion.RETENCION_CODSRI,
        p_retencion_tipret: retencion.RETENCION_TIPRET,
        p_com_codigo: retencion.COM_CODIGO,
        P_retencion_multiple: retencion.RETENCION_MULTIPLE,
        p_con_codigo_vta: retencion.CON_CODIGO_VTA,
      }
    }, this.confIniciales.httpOptions());
  }
  
  getTnrRetencion(retencion: Cxcmaeretencion): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/retenciones/41ddedf7b775d8', {
        elementos: {
          RETENCION_CODIGO: retencion.RETENCION_CODIGO
        }
    }, this.confIniciales.httpOptions());
  }
  
  actualizarRetencion(retencion: Cxcmaeretencion, codigo): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/retenciones/299eeeddd7df2ab2', {
      elementos: {
        p_RETENCION_codigo_1: retencion.RETENCION_CODIGO,
        p_RETENCION_codigo_2: retencion.RETENCION_CODIGO,
        p_RETENCION_nombre_3: retencion.RETENCION_NOMBRE,
        p_RETENCION_porcentaje_4: retencion.RETENCION_PORCENTAJE,
        p_con_codigo: retencion.CON_CODIGO,
        p_retencion_tipo: retencion.RETENCION_TIPO,
        p_retencion_tipoanex: retencion.RETENCION_TIPOANEX,
        p_retencion_codsri: retencion.RETENCION_CODSRI,
        p_retencion_tipret: retencion.RETENCION_TIPRET,
        p_com_codigo: '01',
        P_retencion_multiple: retencion.RETENCION_MULTIPLE,
        p_con_codigo_vta: retencion.CON_CODIGO_VTA,
      }
    }, this.confIniciales.httpOptions());
  }
  
  eliminarRetencion(retencion: Cxcmaeretencion): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/retenciones/eacad2bab7b77800', {
      elementos: {
        p_RETENCION_codigo_1: retencion.RETENCION_CODIGO,
        p_com_codigo: retencion.COM_CODIGO,
      }
    }, this.confIniciales.httpOptions());
  }

  erFindCodRet(codigo) {    
    return this.http.post(this.way + '/cxc/retenciones/f1dbedd75d9' , {
      elementos: {
        RETENCION_CODIGO: codigo
      },
    }, this.confIniciales.httpOptions());
  }



  calculoPor(index) {
    if (this.cxcmaeretencion[index].RETENCION_PORCENTAJE > 100) {
      this.cxcmaeretencion[index].RETENCION_PORCENTAJE = 100;
    }
    
  }
  
  verificarCod(index) {
    let bandera = false;
    this.cxcmaeretencion.map(aux => {
      if(aux !== this.cxcmaeretencion[index]){
        if (aux.RETENCION_CODIGO === this.cxcmaeretencion[index].RETENCION_CODIGO) {
    
          bandera = true;
        }
      }
      
    });
    if (bandera) {
      this.message.add({
        key: 'cxcmaeretencion',
        severity: 'warn',
        summary: 'Clave repetida',
        detail: 'Favor ingrese una clave válida'
      });
      this.cxcmaeretencion[index].RETENCION_CODIGO = '';
    }
  }
    //CDPJ
    encontrarArtCntaySer(strArtCodigo, strtipo) {
      return this.http.post<any[]>(this.way + '/ventas/facturas/75d98bf76adab8000000', {
        elementos: {
          ArtCodigo: strArtCodigo,
          strTIPO: strtipo
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }
    //CDPJ
}
