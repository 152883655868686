import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UtilitariosService} from '../advantage/advantageservices/utilitarios.service';

@Component({
  selector: 'app-reportepdfviewer',
  templateUrl: './reportepdfviewer.component.html',
  styleUrls: ['./reportepdfviewer.component.css']
})
export class ReportepdfviewerComponent implements OnInit {
  
  NOMBREREPORTE: string;
  urlreporte: string;
  usuarioEsquema: any;
  esquema: string;
  src: any;
  src1 = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';
  
  constructor(private utilitariosService: UtilitariosService, private rutaActiva: ActivatedRoute) {
  
  }
  
  ngOnInit() {
    this.urlreporte = sessionStorage.getItem('urlreporte');
    this.usuarioEsquema = JSON.parse(sessionStorage.getItem('usuarioEsquema'));
    console.log('inicio');
    this.esquema = this.usuarioEsquema.esquema;
    this.NOMBREREPORTE = '';
    this.NOMBREREPORTE = this.rutaActiva.snapshot.params.nombre;
    this.NOMBREREPORTE = this.NOMBREREPORTE.substr(0, this.NOMBREREPORTE.length - 7);
    console.log(this.NOMBREREPORTE, this.urlreporte);
    console.log(this.urlreporte + this.NOMBREREPORTE + '.pdf?j_username=' + this.esquema + '&j_password=jasperadmin');
    this.src = this.urlreporte + this.NOMBREREPORTE + '.pdf?j_username=' + this.esquema + '&j_password=jasperadmin';
    
  }
  
  async abrir() {
    console.log(this.src);
    // window.open(this.urlreporte + this.NOMBREREPORTE + '.pdf?j_username=' + this.esquema + '&j_password=jasperadmin', '_blank');
    const blob = await fetch(this.src).then(r => r.blob());
    const file = new Blob([blob], {type: 'application/pdf'});
    const fileURL = URL.createObjectURL(file);
    
    window.open(fileURL);
    
    /*this.utilitariosService.downloadPDF(this.src).subscribe(res => {
      const fileURL = URL.createObjectURL(res);
      
      window.open(fileURL);
    });*/
  }
  
  
  descargar() {
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = 'assets/file';
    link.download = this.src;
    document.body.appendChild(link);
    link.click();
    link.remove();
    
  }
  
}
