<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="impdocsri">
	<p-confirmDialog [transitionOptions]="'0ms'" key="impdocsri" title="Confirmar Acción"
					 appendTo="body"></p-confirmDialog>
</p-toast>
<div class="layout-config">
    <div class="layout-config-content-wrapper">
        <a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones()">
            <i class="pi pi-bars" style="cursor: pointer"></i>
        </a>
        <p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
            [dismissible]="true" [showCloseIcon]="false">
            <br>
            <br>
            <br>
            <p-tabView>
                <p-tabPanel header="Acciones">
                    <div class="p-grid">
                        <p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 200px)'}">
                            <div class="p-col-12">
                                <i class="pi pi-caret-right archivoOpc"></i><span (click)="homolaarti()"
                                    class="archivoOpc">Homologar Detalle</span>
                            </div>
                        </p-scrollPanel>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </p-sidebar>
    </div>
</div>
<div class="p-grid" style="width: 99%">
    <div class="p-col-12">
        <app-invmaebarra [barraBotonesCRUD]="true" [barraBotonesDesplaz]="true" [barraBotones3]="true"
		[botonVisto]="botonVisto" [botonFolder]='false' [botonArchivo]='botonArchivo'  [barraBotonesAsiCon]="true" [barraBotones5]="true" 
		[barraBotones3Alt]="false" [barraBotonesIMP]="false" (signal)="manejarSenales($event)"></app-invmaebarra>
    </div>
</div>
<div class="p-grid" style="width: 98%" id="div">
	<div class="p-col-12">
		<p-tabView [(activeIndex)]="activeIndex">
			<p-tabPanel header="IMPORTAR">
				<div class="divgrups"> 
                    <!-- Primera Separación -->
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid">
                                <div class="p-col-6">
									<div class="p-grid">
                                        <div class="p-col-12">
										</div>
										<div class="p-col-12">
											<div class="p-grid">
												<div class="p-col-3" >
													<span>Bodega:</span>
												</div>
												<div class="p-col-3">
													<label for="BOD_CODIGOXML"></label>
													<input class="frm" id="BOD_CODIGOXML" type="text" pInputText [(ngModel)]="bodcodigoxml" 
														(keydown.enter)="setFocus($event)"  autocomplete="off">
												</div>
												<div class="p-col-1">
													<button type="button" style="background-image: url(../../../assets/images/iconos/buscar.png);" 
														(click)="busquedaBodega(bodcodigoxml, 'bodegaxml')"></button>
												</div>
												<div class="p-col-5">
													<label for="BOD_NOMBREXML"></label>
													<input class="frm" id="BOD_NOMBREXML" type="text" pInputText [(ngModel)]="bodnombrexml"
														(keydown.enter)="setFocus($event)"  autocomplete="off"
														disabled>
												</div>
											</div>
										</div>
										<div class="p-col-12">
											<div class="p-grid">
												<div class="p-col-3" >
													<span>Id. Crédito Tributario:</span>
												</div>
												<div class="p-col-3">
													<label for="IDCREDITOXML"></label>
													<input class="frm" id="IDCREDITOXML" type="text" pInputText [(ngModel)]="idcreditoxml" 
														(keydown.enter)="setFocus($event)" autocomplete="off">
												</div>
												<div class="p-col-1">
													<button type="button" style="background-image: url(../../../assets/images/iconos/buscar.png);" 
														(click)="busquedaidcretri(idcreditoxml, 'cretrixml')"></button>
												</div>
												<div class="p-col-5">
													<label for="IDCREDITONOMBREXML"></label>
													<input class="frm" id="IDCREDITONOMBREXML" type="text" pInputText
														[(ngModel)]="idcreditonombrexml" (keydown.enter)="setFocus($event)"
														 autocomplete="off" disabled>
												</div>
											</div>
                                        </div>
									</div>
								</div>
								<div class="p-col-6">
                                        <div class="p-grid">
                                            <div class="p-col-12" >

                                            </div>
                                            <div class="p-col-12">
                                                <div class="p-grid">
                                                    <div class="p-col-3" >
                                                        <span>Bloque de archivos:</span>
                                                    </div>
                                                    <div class="p-col-7">
                                                        <label for="IDBLOQARCH"></label>
                                                        <input class="frm" id="IDBLOQARCH" type="text" pInputText [(ngModel)]="idbloqarch" 
                                                            (keydown.enter)="setFocus($event)" autocomplete="off" maxlength="10">
													</div>
													<div class="p-col-1" >
														<button type="button" style="background-image: url(../../../assets/images/iconos/visto.png); text-align: center;" 
														(click)="bloqarch()"></button>
                                                    </div>
                                                    <div class="p-col-1">
                                                        <button *ngIf="bloqdisable === true" type="button" style="background-image: url(../../../assets/images/iconos/borrar.png); text-align: center;" 
															(click)="deletebloq()"></button>
														<button *ngIf="bloqdisable === false" type="button" style="background-image: url(../../../assets/images/iconos/borrar-d.png); text-align: center;" 
                                                            ></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
												<div class="p-grid">
													<div class="p-col-3">
														<span>Proveedor:</span>
													</div>
													<div class="p-col-3">
														<label for="PRO_CODIGO"></label>
														<input class="frm" id="PRO_CODIGO" type="text" pInputText
															[(ngModel)]="PRO_CODIGO"
															(keydown.enter)="setFocus($event)" 
															autocomplete="off">
													</div>		
													<div class="p-col-1">
														<button type="button"
															style="background-image: url(../../../assets/images/iconos/buscar.png);"
															(click)="busquedaproveedor(PRO_CODIGO)"></button>
													</div>		
													<div class="p-col-4">
														<label for="PRO_NOMBRE"></label>
														<input  class="frm" id="PRO_NOMBRE" type="text" pInputText disabled
															[(ngModel)]="PRO_NOMBRE" 
															autocomplete="off">
													</div>	
													<div class="p-col-1">
														<button type="button"
															style="background-image: url(../../../assets/images/iconos/guardar.png);"
															(click)="guardapro(PRO_CODIGO)"></button>
													</div>	
												</div>	
                                            </div>
                                            <!-- <div class="p-col-12">
                                                <div class="p-grid">
                                                    <div class="p-col-3" >
                                                    </div>
                                                    <div class="p-col-6">
                                                        <button class="aceptar" pButton  (click)="impinformacion()"
                                                                style="background-image: url(../../../assets/images/iconos/btnAceptar.png); line-height: 2.5em; margin-top: 2px; margin-left: 3px;  width: 75%; height: 62%; font-size: 9pt;">
                                                            Importar Información
                                                        </button>
                                                    </div>
                                                    <div class="p-col-3" >
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
								</div>
								
							</div>
						</div>
						<!-- Segunda Fila -->
						
						<!-- <div class="p-col-2">
							
						</div> -->
					</div>
				</div>
				<p-tabView [(activeIndex)]="indTabladatos" (activeIndexChange)="actualizarTablas()">
					<p-tabPanel header="Datos">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-2" >
									<span>Archivos en el directorio:</span>
								</div>
								<div class="p-col-1">
									<label for="NUMARCHIVOS"></label>
									<input class="frm" id="NUMARCHIVOS" type="text" pInputText [(ngModel)]="numarchivos" 
										disabled autocomplete="off">
								</div>
								<div class="p-col-2" >
								</div>
								<div class="p-col-2" >
									<span>Archivos leidos:</span>
								</div>
								<div class="p-col-1">
									<label for="NUMLEIDOS"></label>
									<input class="frm" id="NUMLEIDOS" type="text" pInputText [(ngModel)]="numleidos" 
										disabled autocomplete="off">
								</div>
							</div>
						</div>
						<app-aggridsaci
						[width]=""
						[height]="largo"
						[enableSorting]="true"
						[enableFilter]="true"
						[rowData]="documentos"
						[frameworkComponents]="frameworkComponents"
						[animateRows]="true"
						[rowSelection]="rowSelection"
						[columnDefs]="columnDefsDoc"
						[defaultColDef]="defaultColDefDoc"
						[rowStyle]="rowStyle"
						[singleClickEdit]="false"
						[botones]="false"
						(selected)="seleccionFil()"
						></app-aggridsaci>
					</p-tabPanel>
					<p-tabPanel header="Facturas compras">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-2" style="text-align: left;">
									<p-checkbox binary="true" label="Seleccionar Todos"
									[(ngModel)]="faccomTodo"
									(click)="seleccionarTodo('faccom')">
									</p-checkbox>
								</div>
								<div class="p-col-2" >
									
								</div>
								<div class="p-col-3">
									<button pButton style="width: 150px; background-color: #5778bf; text-align: center; padding-top: 2%;" 
									(click)="envianodedufac()">
										No Deducible
									</button>
								</div>
							</div>
						</div>
						<app-aggridsaci
						[width]=""
						[height]="largo"
						[enableSorting]="true"
						[enableFilter]="true"
						[rowData]="documentosfaccomp"
						[frameworkComponents]="frameworkComponents"
						[animateRows]="true"
						[rowSelection]="rowSelection"
						[columnDefs]="columnDefsDocfaccom"
						[defaultColDef]="defaultColDefDocfaccom"
						[rowStyle]="rowStyle1"
						[singleClickEdit]="false"
						[botones]="false"
						(selected)="seleccionFil()"
						></app-aggridsaci>
					</p-tabPanel>
					<p-tabPanel header="Retenciones">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-2" style="text-align: left;">
									<p-checkbox binary="true" label="Seleccionar Todos"
									[(ngModel)]="retcomTodo"
									(click)="seleccionarTodo('retcom')">
										
									</p-checkbox>
								</div>
								<div class="p-col-2" >
									
								</div>
								<div class="p-col-3">
									<button pButton style="width: 150px; background-color: #5778bf; text-align: center; padding-top: 2%;" 
									(click)="envianodeduret()">
										No Deducible
									</button>
								</div>
							</div>
						</div>
						<app-aggridsaci
						[width]=""
						[height]="largo"
						[enableSorting]="true"
						[enableFilter]="true"
						[rowData]="documentosretcom"
						[frameworkComponents]="frameworkComponents"
						[animateRows]="true"
						[rowSelection]="rowSelection"
						[columnDefs]="columnDefsDocretcom"
						[defaultColDef]="defaultColDefDocretcom"
						[rowStyle]="rowStyle1"
						[singleClickEdit]="false"
						[botones]="false"
						(selected)="seleccionFil()"
						></app-aggridsaci>
					</p-tabPanel>
					<p-tabPanel header="No Deducible">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-2" style="text-align: left;">
									<p-checkbox binary="true" label="Seleccionar Todos"
									[(ngModel)]="nodedutodo"
									(click)="seleccionarTodo('nodedut')">	
									</p-checkbox>
								</div>
								<div class="p-col-2" >
									
								</div>
								<div class="p-col-3">
									<button pButton style="width: 150px; background-color: #5778bf; text-align: center; padding-top: 2%;" 
									(click)="deletenodedu()">
										Deducible
									</button>
								</div>

							</div>
						</div>
						<app-aggridsaci
						[width]=""
						[height]="largo"
						[enableSorting]="true"
						[enableFilter]="true"
						[rowData]="documentosnodeducible"
						[frameworkComponents]="frameworkComponents"
						[animateRows]="true"
						[rowSelection]="rowSelection"
						[columnDefs]="columnDefsnodeducible"
						[defaultColDef]="defaultDefsnodeducible"
						[rowStyle]="rowStyle1"
						[singleClickEdit]="false"
						[botones]="false"
						(selected)="seleccionFil()"
						></app-aggridsaci>
					</p-tabPanel>
				</p-tabView>	
				
			</p-tabPanel>
		</p-tabView>
		<div class="divaceptar"  style="background-image: url(../../../assets/images/iconos/btnAceptar.png);  line-height: 2.5em; margin-top: 2px; margin-left: 3px;  width: 150px; height:35px; color: white; text-align: center; visibility: hidden;"	>
			<input #fileInput name="Listar Archivos" type="file" webkitdirectory directory  style="opacity: 0.0; position: absolute; 
			margin-top: 2px; margin-left: 0px;  width: 150px; height:35px; cursor: pointer; font-size: 25pt; visibility: hidden;"
				(change)="onBasicUpload(fileInput.files)"  />
		</div>
	</div>
</div>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '70vw', height: '70vh'}" [focusOnShow]="true">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>
	

<p-dialog header="Homologación" [(visible)]="displaycodalt" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{width: '90vw'} " [focusOnShow]="true" appendTo="body"    >
	<p-toast [style]="{marginTop: '41vh'}" position="center" key="codhomo">
		<p-confirmDialog [transitionOptions]="'0ms'" key="codhomo" 
						 appendTo="body"></p-confirmDialog>
	</p-toast>
	<div class="p-grid" style="width: 99%">
		<div class="p-col-12">
			<app-invmaebarra [barraBotonesCRUD]="false" [botonGuardar]="btnGuardarhomo" [barraBotonesDesplaz]="true" [barraBotones3]="true"
			 [barraBotonesAsiCon]="true" [barraBotones5]="true" [barraBotones3Alt]="false"  (signal)="manejarSenahomoart($event)"></app-invmaebarra>
		</div>
	</div>
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<app-aggridsaci [width]=""
								[height]="'500'"
								[enableSorting]="true"
								[enableFilter]="true"
								[rowData]="ComimpdoceleService.arreglohomo[indicador]"
								[frameworkComponents]="frameworkComponents"
								[animateRows]="true"
								[rowSelection]="rowSelection"
								[columnDefs]="columnDefscodalter"
								[defaultColDef]="defaultDefscodalter"
								[rowStyle]="rowStyle1"
								[singleClickEdit]="false"
								[botones]="false"
								>
				</app-aggridsaci>
			</div>
		</div>
	</div>
	<div  style="position: fixed;
				z-index: 999;
				height: 2em;
				width: 2em;
				overflow: visible;
				margin: auto;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;">
		<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'  }" position="top" animationDuration=".5s" >
		</p-progressSpinner>
		<p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
				[style]="{ opacity: 0.00}" position="top">
	</p-dialog>	
	</div> 
</p-dialog>

<div  style="position: fixed;
				z-index: 999;
				height: 2em;
				width: 2em;
				overflow: visible;
				margin: auto;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;">
		<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'  }" position="top" animationDuration=".5s" >
		</p-progressSpinner>
		<p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
				[style]="{ opacity: 0.00}" position="top">
	</p-dialog>	
	</div> 

