import {Component, OnInit, ViewChild} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng';

import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {NommaetablitaretService} from '../nomservicios/nommaetablitaret.service';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {Nommaetablitaret} from '../nominterfaces/nommaetablitaret';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {Nommaetiporol} from '../nominterfaces/nommaetiporol';

@Component({
  selector: 'app-nommaetablitaret',
  templateUrl: './nommaetablitaret.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NommaetablitaretComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  anios = [];
  
  anioSeleccionado;
  newanio:string;
  btnGuardar: boolean;
  btnNuevo: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  
  defaultColDefMaeTablitaRet;
  public frameworkComponents;
  
  rowStyle;
  largo = '258';
  rowSelection = 'multiple';
  //nommaeTablitaRet: Nommaetablitaret[] = [];
  selected_TablitaRet: Nommaetablitaret;
  columnDefMaeTablitaRet = [
    {
      headerName: 'N', field: 'NUMERO', editable: false, cellEditor: 'cellPrueba', width: 50,
    },
    {
      headerName: 'Fr. Básica', field: 'MAETABLITARET_FRACBASICA', cellEditor: 'cellPrueba', width: 150,  cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: 'MaxFr.Bas. ', field: 'MAETABLITARET_MAXFRACBSC', cellEditor: 'cellPrueba', width: 150, cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 600,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: 'Importe', field: 'MAETABLITARET_IMPFRCBSC', cellEditor: 'cellPrueba', width: 150, cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 600,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: 'Excedente', field: 'MAETABLITARET_EXCEDENTE', cellEditor: 'cellPrueba', width: 150, cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 600,
            tienecalculos: true,
          }
        }
    },
  ];
  private codigoActual: any;
  
  constructor(
    private messageService: MessageService,
    private init: NuevoComponentService,
    private errorService: ErroresBaseDatosService,
    private confirmationService: ConfirmationService,
    public maeTablitaRetService: NommaetablitaretService,
  ) {
    this.maeTablitaRetService.nommaeTablitaRet = [];
    this.agTable();
  }
  
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefMaeTablitaRet = {
      editable: true,
      width: 130,
      objeto: 'maeTablitaRet'
    };
  }
  
  ngOnInit(): void {
    this.btnNuevo =  false;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.btnRegresar = false;
    this.getListaAnio();
  }
  
  getListaAnio() {
    
    this.maeTablitaRetService.ListaAnios().then( res => {
   
      this.anios = [];
      for ( const anio of res ) {
        this.anios.push({anio: anio.MAETABLITARET_ANIO});
      }
 
    } )
      .catch(e => console.log(e));
  }
  
  
  
  
  buscarAnio(event) {
  
    if ( event.value !== null) {

      if(event.value.anio){
        this.anioSeleccionado = event.value.anio;
        this.getTablita(event.value.anio);
       
      }else{
        this.anioSeleccionado = event.value;
        this.getTablita(event.value);
       
      }
    
    }
  }
  
  
  async  getTablita(anio: string) {
    await this.maeTablitaRetService.cargarTablita(anio).then( async  res => {
     
      this.maeTablitaRetService.nommaeTablitaRet = res;
      
      this.maeTablitaRetService.nommaeTablitaRet.map( (item) => {
      
        item.NUMERO = this.maeTablitaRetService.nommaeTablitaRet.indexOf( item ) + 1;
        item.MAETABLITARET_FRACBASICA= Number(item.MAETABLITARET_FRACBASICA).toFixed(2);
        item.MAETABLITARET_EXCEDENTE=Number(item.MAETABLITARET_EXCEDENTE).toFixed(2);
        item.MAETABLITARET_IMPFRCBSC=Number(item.MAETABLITARET_IMPFRCBSC).toFixed(2);
        item.MAETABLITARET_MAXFRACBSC=Number(item.MAETABLITARET_MAXFRACBSC).toFixed(2);
      }  );
      
      return await this.maeTablitaRetService.getCodigoActual().then( /*array*/ cod => {
       
        for(const key in cod[0]) { // recorrer campos de un objeto
          this.codigoActual = cod[0][key];
        }
        if(this.codigoActual==null || this.codigoActual==undefined){
          this.codigoActual=1;
        }
        return;
      });
    })
      .catch(e => { console.log(e); } );
  }
  
  manejarSenales(valor) {
    switch (valor) {
      case 'Nuevo':
        this.opcionNuevo();
        break;
      case 'Guardar':
        this.opcionGuardar();
        break;
      case 'Borrar':
        this.opcionBorrar();
        break;
      case 'Cancelar':
        this.cargar();
        break;
      case 'Salir':
        this.init.tabs.splice(  this.init.tabindex, 1);
        break;
      
    }
    
  }
 
  opcionNuevo() {
   
    if ( this.anioSeleccionado || this.newanio ) {
      this.btnNuevo = true;
      this.btnBorrar = false;
      this.btnRegresar = false;
      //this.aggrid.isEditable(true);
      const new_nommaeTabRet: Nommaetablitaret = {
        
        MAETABLITARET_CODIGO: this.codigoActual,
        COM_CODIGO: '01',
        MAETABLITARET_FRACBASICA: '',
        MAETABLITARET_MAXFRACBSC: '',
        MAETABLITARET_IMPFRCBSC: '',
        MAETABLITARET_EXCEDENTE: '',
        NUMERO:this.maeTablitaRetService.nommaeTablitaRet.length+1,
        MAETABLITARET_ANIO: this.anioSeleccionado,
        nuevo: true
      };
      
      this.maeTablitaRetService.nommaeTablitaRet.push(new_nommaeTabRet);
      this.selected_TablitaRet=new_nommaeTabRet;//GSRF
      this.aggrid.refreshaggrid(this.maeTablitaRetService.nommaeTablitaRet, this.defaultColDefMaeTablitaRet.objeto);
    }
  }
  
  selectMaeTablitaRet(elemento) {
    if (elemento.object !== null) {
      this.selected_TablitaRet = elemento.object;
      
    }
  }
  
  cambio(event) {
    this.btnGuardar = false;
    this.btnRegresar = false;
    if (this.selected_TablitaRet.nuevo === undefined) {
      this.selected_TablitaRet.editado = true;
    }
  }
  
  opcionGuardar() {
    //this.aggrid.gridApi.stopEditing(); GSRF
    
    this.selected_TablitaRet.MAETABLITARET_FRACBASICA = Number( this.selected_TablitaRet.MAETABLITARET_FRACBASICA );
    this.selected_TablitaRet.MAETABLITARET_MAXFRACBSC = Number( this.selected_TablitaRet.MAETABLITARET_MAXFRACBSC );
    this.selected_TablitaRet.MAETABLITARET_IMPFRCBSC  = Number( this.selected_TablitaRet.MAETABLITARET_IMPFRCBSC );
    this.selected_TablitaRet.MAETABLITARET_EXCEDENTE  = Number( this.selected_TablitaRet.MAETABLITARET_EXCEDENTE );
    
    
    if( this.selected_TablitaRet.nuevo)  {
      this.selected_TablitaRet.MAETABLITARET_ANIO= this.anioSeleccionado,
        this.enviarNuevo();
        this.getListaAnio()
    } else if (this.selected_TablitaRet.editado) {
      this.enviarEditado();
      this.getListaAnio();
    }
    
    
  }
  
  async enviarNuevo() {
    await this.maeTablitaRetService.insertar(this.selected_TablitaRet).then(() => {
      this.mensajeAlerta('success', 'Información', '¡Registro creado exitosamente!');
      this.cargar();
      
      
    }).catch(e => {
      
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    });
  }
  
  async enviarEditado() {
    await this.maeTablitaRetService.actualizar(this.selected_TablitaRet).then( () => {
   
      this.mensajeAlerta('success', 'Información', '¡Registro actualizado exitosamente!');
      this.cargar();
      
      
      
    }).catch( e => {
      
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }  );
  }
  
  
  opcionBorrar(){
    this.confirmationService.confirm({
      message: '¿Está seguro que desea eliminar el registro?',
      header: 'Eliminar Periodo',
      icon: 'pi pi-exclamation-triangle',
      key: 'NomMaeTabRetDialog',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarTablita();
      },
      reject: () => {
      }
    });
  }
  
  eliminarTablita(){
    if( this.selected_TablitaRet.nuevo === undefined ){
      this.maeTablitaRetService.borrar( this.selected_TablitaRet ).then( () => {
        this.mensajeAlerta('success', 'Información', ' ¡Registro eliminado exitosamente!');
        this.cargar();
      } ).catch( e => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      } );
    }
  }
  
  
  cargar() {
    this.btnNuevo =  false;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.btnRegresar = false;
    // this.anio = new Date().getFullYear();
    this.getTablita(this.anioSeleccionado);
  }
  
  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'nommaetabrettoast',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
    
  }
  
}
