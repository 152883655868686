//
// SACI WEB
// Rutina: Maestro de Clientes
// Propietario: Sandy Simbaña
// Modificado por: SS
// Fecha de creación: 2019
// Fecha de Modificación: 29-11-2019
//

import {Component, OnInit, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {Cxccomision} from '../cxcinterfaces/cxccomision';
import {CxcomisionService} from '../cxcservicios/cxcomision.service';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {CxcMaeTarjetaCredito} from '../../interfaces/cxcmaetarjetacredito';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Component({
  selector: 'app-cxccomision',
  templateUrl: './cxccomision.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class CxccomisionComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  actualizar: boolean;
  nuevoCampo: boolean;
  permisoComision: SegMaePermiso;
  codigoAux: any;
  arregloCons: any[];
  largo: string;
  comisionseleccionada: Cxccomision;
  public frameworkComponents;
  rowStyle;
  desCodigo: boolean;
  defaultColDefCxccomision;
  rowSelection = 'multiple';
  columnDefsCxccomision = [
    {
      headerName: 'Comisión Código', field: 'COMISION_CODIGO', cellEditor: 'cellPrueba', width: 200, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10
          }
        }
    },
    {
      headerName: 'Nombre', field: 'COMISION_NOMBRE', cellEditor: 'cellPrueba', width: 250,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 45
          }
        }
    },
    {
      headerName: 'Cta Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 35,
            tienecalculos: true
          }
        }
    },
  ];

  constructor(public comisionService: CxcomisionService, private message: MessageService,
              private errorService: ErroresBaseDatosService, private confirmationService: ConfirmationService,
              private busqService: InvbusquedaService, private permisosService: PermisosService,
              private auditoriagral: AuditoriagralService, private init: NuevoComponentService) {
    this.agTable();
    this.comisionService.comisionesServicio =[];
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefCxccomision = {
      editable: true,
      width: 100,
      objeto: 'comision'
    };
  }

  ngOnInit() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.actualizar = false;
    this.nuevoCampo = false;
    this.buscarCon();
    this.codigoAux = 0;
    this.cargarComisiones();
    this.permisosService.getListaPermisos('CXC', '1', '3300').subscribe((res) => {
      this.permisoComision = res[0];
    });
    this.largo = '430';
  }
  
  cargarComisiones() {
  
    this.comisionService.getComisionServicio().subscribe((res) => {
      if (res !== null) {
        this.comisionService.comisionesServicio = res;
        
        let linea = 0;
        this.comisionService.comisionesServicio = [];
        res.map(fp => {
          linea += 1;
          let objCom: Cxccomision;
          objCom = {};
          objCom.APLORG_CODIGO = fp.APLORG_CODIGO;
          objCom.COMISION_CODIGO = fp.COMISION_CODIGO;
          objCom.COMISION_NOMBRE = fp.COMISION_NOMBRE;
          objCom.COMISION_PORCENTAJE = fp.COMISION_PORCENTAJE;
          objCom.CON_CODIGO = fp.CON_CODIGO;
          objCom.COM_CODIGO = fp.COM_CODIGO;
          objCom.LINEA = String(linea);
          this.comisionService.comisionesServicio.push(objCom);
          
        });
        this.aggrid.refreshaggrid(this.comisionService.comisionesServicio, this.defaultColDefCxccomision.objeto);
      }
    });
    
    
  }
  
  
  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      if (this.permisoComision.PERINSERCION === 1) {
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.btnRegresar = false;
      this.nuevoCampo = true;
      this.aggrid.isEditable(true);
  
        const linea = this.comisionService.comisionesServicio.length;
        const numero = linea === 0 ? 0 : Number(this.comisionService.comisionesServicio[this.comisionService.comisionesServicio.length - 1].COMISION_CODIGO);
      this.comisionseleccionada = {
        APLORG_CODIGO: 'CXC',
        COMISION_CODIGO: '0' + String(numero + 1),
        COMISION_NOMBRE: '',
        COMISION_PORCENTAJE: '',
        CON_CODIGO: '',
        COM_CODIGO: '01',
        LINEA: String(linea + 1)
      };
      this.comisionService.comisionesServicio.push(this.comisionseleccionada);
      this.aggrid.refreshaggrid(this.comisionService.comisionesServicio, this.defaultColDefCxccomision.objeto);
      this.aggrid.gridApi.setFocusedCell(Number(this.comisionseleccionada.LINEA)-1, 'COMISION_NOMBRE');
    }else if (this.permisoComision.PERINSERCION === 0) {
        this.message.add({
          key: 'comision',
          severity: 'error',
          summary: 'Inserción de Comisiones',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    }
    
    if (valor === 'Guardar') {
      if (this.permisoComision.PERACTUALIZACION === 1) {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      
      /*
      this.comisionService.encontrarRegistro('COMISION_CODIGO', 'SACI_MAECOMISION',
        'COMISION_CODIGO =\'' + this.comisionseleccionada.COMISION_CODIGO + '\'').subscribe(existe => {
        if (existe !== null) {
          let coincidencias: number;
          coincidencias = 0;
          this.arregloCons.map((con) => {
            if (this.comisionseleccionada.CON_CODIGO === con.CON_CODIGO) {
              coincidencias++;
            }
          });
          if (coincidencias !== 0) {
            this.comisionService.actualizarComision(this.comisionseleccionada, this.codigoAux).subscribe(() => {
              this.message.add({
                key: 'comision',
                severity: 'success',
                summary: 'Actualización exitosa',
                detail: 'Se actualizó Comisión correctamente'
              });
              this.auditoriagral.registrarAuditoria('SACI_MAECOMISION', String(this.comisionseleccionada.COMISION_CODIGO), 'A',
                '', '01', '', '', '', '').subscribe(() => {});
  
              this.aggrid.isEditable(false);
            }, error1 => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.message.add({
                key: 'comision',
                severity: 'error',
                summary: 'Actualización de Comision',
                detail: mensaje
              });
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
              this.cargarComisiones();
            });
          } else if (coincidencias === 0) {
            this.message.add({
              key: 'comision',
              severity: 'error',
              summary: 'Error en Cuenta Contable',
              detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
            });
            this.comisionseleccionada.CON_CODIGO = '';
            this.aggrid.refreshaggrid(this.comisionService.comisionesServicio, this.defaultColDefCxccomision.objeto);
          }
        } else if (this.actualizar === false) {
          let coincidencias: number;
          coincidencias = 0;
          this.arregloCons.map((con) => {
            if (this.comisionseleccionada.CON_CODIGO === con.CON_CODIGO) {
              coincidencias++;
            }
          });
          if (coincidencias !== 0) {
            this.comisionService.insertarComision(this.comisionseleccionada).subscribe(() => {
              this.message.add({
                key: 'comision',
                severity: 'success',
                summary: 'Inserción exitosa',
                detail: 'Se insertó comision correctamente'
              });
              this.auditoriagral.registrarAuditoria('SACI_MAECOMISION', String(this.comisionseleccionada.COMISION_CODIGO), 'I',
                '', '01', '', '', '', '').subscribe(() => {});
              this.aggrid.isEditable(false);
              this.nuevoCampo = false;
            }, error1 => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.message.add({
                key: 'comision',
                severity: 'error',
                summary: 'Inserción de Comision',
                detail: mensaje
              });
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
              this.cargarComisiones();
            });
          } else if (coincidencias === 0) {
            this.message.add({
              key: 'comision',
              severity: 'error',
              summary: 'Error en Cuenta contable',
              detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
            });
            this.comisionseleccionada.CON_CODIGO = '';
            this.aggrid.refreshaggrid(this.comisionService.comisionesServicio, this.defaultColDefCxccomision.objeto);
          }
        }
      });*/
      
      this.guardarComision();
      
      
    }else if (this.permisoComision.PERACTUALIZACION === 0) {
        this.message.add({
          key: 'comision',
          severity: 'error',
          summary: 'Actualización de Comisiones',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }
    }
    if (valor === 'Borrar') {
      if (this.permisoComision.PERELIMACION === 1) {
      this.btnNuevo = false;
      this.aggrid.isEditable(false);
        if (this.comisionseleccionada === undefined) {
          this.message.add({
            key: 'comision',
            severity: 'error',
            summary: 'Eliminacion de Comisión',
            detail: 'Seleccion Incorrecta, no se ha seleccionado una comisión'
          });
        } else   {
          this.confirmarComision();
        }
      }else if (this.permisoComision.PERELIMACION === 0) {
        this.message.add({
          key: 'comision',
          severity: 'error',
          summary: 'Eliminación de Comision',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
      }
    }
    if (valor === 'Cancelar') {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.aggrid.isEditable(false);
      this.cargarComisiones();
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
 async  guardarComision() {
    if (this.comisionseleccionada.COMISION_CODIGO !== '' && this.comisionseleccionada.COMISION_NOMBRE !== ''
      && this.comisionseleccionada.CON_CODIGO !== ''){
      if (this.nuevoCampo) {
        let coincidencias: number;
        coincidencias = 0;
        // this.arregloCons.map((con) => {
        //   if (this.comisionseleccionada.CON_CODIGO === con.CON_CODIGO) {
        //     coincidencias++;
        //   }
        // });
        //CDPJ
        const rsdata: any = await this.comisionService.encontrarArtCntaySer(this.comisionseleccionada.CON_CODIGO, 'C');
        if (rsdata !== null) {
          for (const rs of rsdata) {
            coincidencias = rs.NUMERO;
          }
        }
        //CDPJ
        if (coincidencias !== 0) {
          this.comisionService.insertarComision(this.comisionseleccionada).subscribe(() => {
            this.message.add({
              key: 'comision',
              severity: 'success',
              summary: 'Inserción exitosa',
              detail: 'Se insertó comision correctamente'
            });
            this.auditoriagral.registrarAuditoria('SACI_MAECOMISION', String(this.comisionseleccionada.COMISION_CODIGO), 'I',
              '', '01', '', '', '', '').subscribe(() => {});
            this.aggrid.isEditable(false);
            this.nuevoCampo = false;
          }, error1 => {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.message.add({
              key: 'comision',
              severity: 'error',
              summary: 'Inserción de Comision',
              detail: mensaje
            });
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
            this.btnRegresar = true;
            this.nuevoCampo = false;
        
            this.cargarComisiones();
          });
        } else if (coincidencias === 0) {
          this.message.add({
            key: 'comision',
            severity: 'error',
            summary: 'Error en Cuenta contable',
            detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
          });
          this.comisionseleccionada.CON_CODIGO = '';
          this.aggrid.refreshaggrid(this.comisionService.comisionesServicio, this.defaultColDefCxccomision.objeto);
        }
      }
      else if (! this.nuevoCampo ) {
        this.comisionService.erComisioncodigo(this.comisionseleccionada.COMISION_CODIGO).subscribe(async existe => {
          if (existe !== null) {
            let coincidencias: number;
            coincidencias = 0;
            // this.arregloCons.map((con) => {
            //   if (this.comisionseleccionada.CON_CODIGO === con.CON_CODIGO) {
            //     coincidencias++;
            //   }
            // });
            //CDPJ
            const rsdata: any = await this.comisionService.encontrarArtCntaySer(this.comisionseleccionada.CON_CODIGO, 'C');
            if (rsdata !== null) {
              for (const rs of rsdata) {
                coincidencias = rs.NUMERO;
              }
            }
        //CDPJ
            if (coincidencias !== 0) {
              this.comisionService.actualizarComision(this.comisionseleccionada, this.codigoAux).subscribe(() => {
                this.message.add({
                  key: 'comision',
                  severity: 'success',
                  summary: 'Actualización exitosa',
                  detail: 'Se actualizó Comisión correctamente'
                });
                this.auditoriagral.registrarAuditoria('SACI_MAECOMISION', String(this.comisionseleccionada.COMISION_CODIGO), 'A',
                  '', '01', '', '', '', '').subscribe(() => {});
            
                this.aggrid.isEditable(false);
                this.nuevoCampo = false;
            
              }, error1 => {
                let mensaje: string;
                mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                this.message.add({
                  key: 'comision',
                  severity: 'error',
                  summary: 'Actualización de Comision',
                  detail: mensaje
                });
                this.btnNuevo = false;
                this.btnGuardar = true;
                this.btnBorrar = false;
                this.btnRegresar = true;
                this.cargarComisiones();
                this.nuevoCampo = false;
            
              });
            } else if (coincidencias === 0) {
              this.message.add({
                key: 'comision',
                severity: 'error',
                summary: 'Error en Cuenta Contable',
                detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
              });
              this.comisionseleccionada.CON_CODIGO = '';
              this.aggrid.refreshaggrid(this.comisionService.comisionesServicio, this.defaultColDefCxccomision.objeto);
            }
          }
      
        });
      }
    } else {
      this.message.add({
        key: 'comision',
        severity: 'error',
        summary: 'Campos Obligatorios',
        detail: 'Los campos codigo , nombre y cuenta contable son obligatorios'
      });
      this.aggrid.gridApi.setFocusedCell(Number(this.comisionseleccionada.LINEA)-1, 'COMISION_NOMBRE');
    }
    
  }

  seleccionComision(valor) {
    if (valor.object !== null) {
      this.comisionseleccionada = valor.object;
      if (this.nuevoCampo === true) {
        this.actualizar = false;
      } else if (this.nuevoCampo === false) {
        if (this.comisionseleccionada !== undefined) {
          this.actualizar = true;
          this.codigoAux = this.comisionseleccionada.COMISION_CODIGO;
        }
      }
    }
  }

  confirmarComision() {
    this.confirmationService.confirm({
      message: '¿Esta seguro que desea eliminar esta comisión?',
      header: 'Eliminar Comisión',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarComision',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarComision();
      },
      reject: () => {
      }
    });
  }

  eliminarComision() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.comisionService.eliminarComision(this.comisionseleccionada).subscribe(() => {
      this.message.add({
        key: 'comision',
        severity: 'success',
        summary: 'Eliminación exitosa',
        detail: 'Se eliminó la comisión correctamente'
      });
      this.auditoriagral.registrarAuditoria('SACI_MAECOMISION', String(this.comisionseleccionada.COMISION_CODIGO), 'E',
        '', '01', '', '', '', '').subscribe(() => {});
      this.comisionService.comisionesServicio = this.comisionService.comisionesServicio.filter((val, j) => j !==
        this.comisionService.comisionesServicio.indexOf(this.comisionseleccionada));
      this.aggrid.refreshaggrid(this.comisionService.comisionesServicio, this.defaultColDefCxccomision.objeto);
    });
  }

  buscarCon() {
    this.busqService.busquedaConMaeCon().subscribe((res: any[]) => {
      this.arregloCons = res;
    });
  }

  cambio(cambio) {
    this.btnGuardar = false;
    this.btnRegresar = false;
  }
  
  controlGuardar(valor) {
    this.manejarSenales('Guardar');
  }
}
