import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import {ConBanMovBan, ConBanMovCaja} from '../coninterfaces/concontabilizadocban';
import {ConComNotRec, ConProConComFact, ConProConDevPro} from '../coninterfaces/concontabilizadoccom';
import {ConInvEntradaSalida, ConInvTomasFisicas, ConInvTransferencias} from '../coninterfaces/concontabilizadocinv';
import {ConVenFacturas, ConVenGuiaRemision, ConVenNotasCredito} from '../coninterfaces/concontabilizadocven';
import {
  ConCXCCompIngreso,
  ConCXCDevAnticipo,
  ConCXCNotasCredito,
  ConCXCNotasDebito,
  ConCXCRetencion
} from '../coninterfaces/concontabilizadoccxc';
import {ConCXPEgreso} from '../coninterfaces/concontabilizadoccxp';
import {ConNomRolPago} from '../coninterfaces/concontabilizadocnom';
import {ConProdOrdProd} from '../coninterfaces/concontabilizadocprod';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class ConcontabilizadocService {
  
  public facturasProveedores: ConProConComFact[];
  public devolucionesProveedores: ConProConDevPro[];
  public notasDeRecepcion: ConComNotRec[];
  
  public movimientosBancos: ConBanMovBan[];
  public movimientosCaja: ConBanMovCaja[];
  
  public entradaSalida: ConInvEntradaSalida[];
  public transferencias: ConInvTransferencias[];
  public tomasFisicas: ConInvTomasFisicas[];
  
  public ventasFacturas: ConVenFacturas[];
  public venNotasCredito: ConVenNotasCredito[];
  public venGuiasRemision: ConVenGuiaRemision[];
  
  public cxcComprobIngreso: ConCXCCompIngreso[];
  public cxcDevAnticipo: ConCXCDevAnticipo[];
  public cxcNotasCredito: ConCXCNotasCredito[];
  public cxcNotasDebito: ConCXCNotasDebito[];
  public cxcRetenciones: ConCXCRetencion[];
  
  public cxpEgresos: ConCXPEgreso[];
  public nomRolPagos: ConNomRolPago[];

  public prodOrdProd: ConProdOrdProd[];
  
  public way: string;
  public strTrnTipoESProd = '';


  constructor(private http: HttpClient, public usuarioEsquema: UsuarioEsquema, public usuario: Usuario,
    private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }
  
  refrescarURL() {
    if (this.way === null) {
      this.way = sessionStorage.getItem('way');
    }
  }


  erAsinroTrnkardex(strAnio, strMes) {
    return this.http.post(this.way + '/cont/contabilizardoc/1db57257de3bbc0000', {
      elementos: {
        AnioAjustes: strAnio,
        MesAjustes: strMes
      },
    }, this.confIniciales.httpOptions());
  }

  erAsinroTrnkardex2(strAnio, strMes) {
    return this.http.post(this.way + '/cont/contabilizardoc/edab92bef1dde000', {
      elementos: {
        AnioAjustes: strAnio,
        MesAjustes: strMes
      },
    }, this.confIniciales.httpOptions());
  }
  
  verificarPeriodoContable(intDia, intMes, intAnio): Observable<any> {
    let strDia = '';
    let strMes = '';
    let strAnio = '';
    
    strDia = this.completarFecha(intDia);
    strMes = this.completarFecha(intMes + 1);
    strAnio = this.completarFecha(intAnio);
    /* const strSql = 'select count(*) as contador from saci_maeperiodo ' +
      'where  to_date(' + '\''  + strDia + '/' + strMes + '/' + strAnio + '\'' + ',' + '\'dd/mm/yyyy\' ) >= per_fechainicial' +
      ' and to_date (' + '\''  + strDia + '/' + strMes + '/' + strAnio + '\'' + ',' + '\'dd/mm/yyyy\' ) >= per_fechainicial'; */
    
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/cbb72c370cc5f80', {
        elementos: {
          dia: strDia,
          mes: strMes,
          anio: strAnio
        }
      } , this.confIniciales.httpOptions()
    );
  }
  
  completarFecha(strFecha) {
    strFecha = strFecha.toString();
    if (strFecha.length === 1) {
      return '0' + strFecha;
    } else {
      return strFecha;
    }
  }
  
  // COMPRAS
  // Facturas Proveedores
  obtenerFacturasProveedor(where) {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83baf533b9de400', {
      elementos: {
        p_Tipo: 'C',
        p_Cadena: where,
      }
    }, this.confIniciales.httpOptions());
  }
  
  // Devoluciones Proveedores
  obtenerDevolucionesProveedor(condicion) {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83bad77f15cef00', {
      elementos: {
        p_Tipo: 'A',
        p_Cadena: condicion,
      }
    }, this.confIniciales.httpOptions());
  }
  

  // Notas de Recepcion
  obtenerNotasRecepcion(condicion) {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83bb7c755b73200', {
      elementos: {
        p_Tipo: 'T',
        p_Cadena: condicion,
      }
    }, this.confIniciales.httpOptions());
  }
  
  // BANCOS
  // Movimientos de Bancos
  
  getDataCmbMovBancos(): Observable<any>  {
    /* let strSentencia = '';
    strSentencia = 'SELECT REF_NOMBRE, REF_CODIGO FROM SACI_MAEREFERENCIA ';
    strSentencia = strSentencia + ' WHERE REF_TIPO = \'TRB\' AND COM_CODIGO = \'01\'';
    strSentencia = strSentencia + ' ORDER BY REF_NOMBRE'; */

    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83bacb2ed8fad80', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    );
  }
  
  obtenerMovimientosBancos(strwhere): Observable<any>  {
    /* const sentenciaSql = 'SELECT BAN_CODIGO, TRNBAN_NUMERO, TRNBAN_FECHA, TRNBAN_FECHAVENCE, ' +
      'TRNBAN_IMPORTE, ASI_NRO, TRNBAN_CONCEPTO, TRNBAN_BENEFICIARIO, ' +
      'TRNBAN_TIPO, TRNBAN_ORIGEN FROM BAN_TRNBAN WHERE ' + strwhere ; */
  
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/41ddb63f25aaec', {
        elementos: {
          where: strwhere
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  // Movimientos de Caja
  
  getDataCmbMovCaja(): Observable<any>  {
    /*let strSentencia = '';
    strSentencia = 'SELECT REF_NOMBRE, REF_CODIGO FROM SACI_MAEREFERENCIA ';
    strSentencia = strSentencia + ' WHERE REF_TIPO = \'TRC\' AND COM_CODIGO = \'01\'';
    strSentencia = strSentencia + ' ORDER BY REF_NOMBRE'; */
    
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/10775965db1f63000', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    );
  }
  
  obtenerMovimientosCaja(strwhere): Observable<any>  {
    /* const sentenciaSql = 'SELECT TRNCAJ_TIPO, TRNCAJ_NUMERO, CAJ_CODIGO, TRNCAJ_FECHA, TRNCAJ_DOCNRO,' +
      ' TRNCAJ_IMPORTE, TRNCAJ_DESCRIPCION, ASI_NRO FROM BAN_TRNCAJ WHERE ' +
      'TRNCAJ_ORIGEN = \'CAJ\' AND ' + where ; */

    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/41ddb63f262a68', {
        elementos: {
          where: strwhere
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  // INVENTARIO
  // Entradas y Salidad
  obtenerInvEntradaSalida(where) {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83bb2bfdd7ef180', {
      elementos: {
        p_opcion: 'T',
        p_filtro: where,
        p_condicion: ''
      }
    }, this.confIniciales.httpOptions());
  }

  // Transferencia
  obtenerDatosTransferencia(condicion) {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/10777bb55f8f77000', {
      elementos: {
        p_opcion: 'C',
        p_filtro: '',
        p_condicion: condicion,
      }
    }, this.confIniciales.httpOptions());
  }
  
  // Tomas Fisicas
  obtenerDatosTomasFisicas(condicion) {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/10777b8b29f2e5000', {
      elementos: {
        p_opcion: 'T',
        p_filtro: condicion,
        p_condicion: '',
      }
    }, this.confIniciales.httpOptions());
  }
  
  // Ajustes
  eliminarAsientoAj(mes, anio, concepto, tipDiaCodigo) {
    
    if (concepto === undefined) {
      concepto = '';
    }
    
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/756565724ebf540', {
      elementos: {
        p_mes: mes ,
        p_anio: String(anio),
        p_concepto: concepto,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: tipDiaCodigo,
      }
    }, this.confIniciales.httpOptions());
  }
  
  generarAsiAjusPositivo(mes, anio, concepto, tipDiaCodigo) {
    if (concepto === undefined) {
      concepto = '';
    }
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83aeae4953f6700', {
      elementos: {
        p_mes: mes,
        p_anio: String(anio),
        p_concepto: concepto,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: tipDiaCodigo,
      }
    }, this.confIniciales.httpOptions());
  }
  
  generarAsiAjusNegativo(mes, anio, concepto, tipDiaCodigo) {
    if (concepto === undefined) {
      concepto = '';
    }
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83aeae4953f5e00', {
      elementos: {
        p_mes: mes,
        p_anio: String(anio),
        p_concepto: concepto,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: tipDiaCodigo,
      }
    }, this.confIniciales.httpOptions());
  }
  
  // Ventas
  // Facturas
  
  consultarBodegaVentas() {
    /* const sentenciaSql = 'SELECT BOD_NOMBRE, BOD_CODIGO FROM '
      + ' INV_MAEBODEGA WHERE (BOD_TIPO <> \'E\' OR BOD_TIPO IS NULL) AND'
      + ' COM_CODIGO = \'01\' ORDER BY BOD_CODIGO';
     */
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/41dd5e1bf75fa8', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    );
  }
  
  obtenerDatosFacturas(condicion) {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83bad5771c7a980', {
      elementos: {
        p_Tipo: 'F',
        p_Cadena: condicion,
      }
    }, this.confIniciales.httpOptions());
  }
  
  
  // Notas de Credito
  
  obtenerDatosNotasCredito(condicion) {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/20eeb55dc72f8e0000', {
      elementos: {
        p_Tipo: 'F',
        p_Cadena: condicion,
        p_condicion: '',
      }
    }, this.confIniciales.httpOptions());
  }
  
  
  // Guias Remision
  obtenerDatosGuiasRemision(condicion) {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/10775aaee21e97000', {
      elementos: {
        p_tipo: 'F',
        p_cadena: condicion,
        p_condicion: '',
      }
    }, this.confIniciales.httpOptions());
  }
  
  // CXC
  
  // Comprobantes de Ingreso
  obtenerDatosComprobantesIng(condicion) {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/10775aaeb316cd000', {
      elementos: {
        p_opcion: 'T',
        p_filtro: '',
        p_condicion: ' aplorg_codigo= \'CXC\' AND ' + condicion,
      }
    }, this.confIniciales.httpOptions());
  }
  
  // Devolucion de Anticipos
  obtenerDatosDevAnticipo(condicion) {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/10775aaeb5df56000', {
      elementos: {
        p_opcion: 'T',
        p_filtro: '',
        p_condicion: condicion,
      }
    }, this.confIniciales.httpOptions());
  }
  
  
  // Notas de Credito
  obtenerDatosCXCNotas(condicion) {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83bad5758cbe380', {
      elementos: {
        p_opcion: 'T',
        p_filtro: '',
        p_condicion: condicion,
      }
    }, this.confIniciales.httpOptions());
  }
  
  // CXP
  
  obtenerDatosCXPEgreso(condicion) {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83bad5759974380', {
      elementos: {
        p_opcion: 'F',
        p_filtro: '',
        p_condicion: condicion,
      }
    }, this.confIniciales.httpOptions());
  }
  
  
  // Promesas
  
  async generarAsientoMovCaja(strTipo, strTrnCajNumero, strCajCodigo, strTrnCajDocNro, strCpnia, strUsuarioConectado): Promise <any> {
  
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/1075d5c95b1f63000', {
      elementos: {
        p_trncaj_tipo: strTipo,
        p_trncaj_numero: strTrnCajNumero,
        p_caj_codigo: strCajCodigo,
        p_trncaj_docnro: strTrnCajDocNro,
        p_com_codigo: strCpnia,
        p_usu_identificacion: strUsuarioConectado
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  async contComFacPro(comprobante: string): Promise <any> {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83aeae49ea66780', {
      elementos: {
        p_encfacpro_numero: comprobante,
        p_com_codigo: '01',
        p_tipdia_codigo: 'AFC',
        p_origen: 'COM',
        p_usuario: this.usuario.identificacion
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  async getAsiComFactPro(strnumDoc: string): Promise <any> {
    /* let strSentencia = '';
    strSentencia = 'SELECT ASI_NRO FROM COM_ENCFACPRO WHERE ENCFACPRO_NUMERO = \'' + strnumDoc + '\'' +
      ' AND COM_CODIGO = \'01\''; */
    
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83baae49ea66780', {
        elementos: {
          numDoc: strnumDoc
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  
  async generarAsientoContableDevCom(encDevNumero: string): Promise <any> {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83aeae49aefb300', {
      elementos: {
        p_encdev_numero: encDevNumero,
        p_com_codigo: '01',
        p_tipdia_codigo: 'ADC',
        p_origen: 'COM',
        p_usuario: this.usuario.identificacion,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  async generarAsientoContableConsig(encDevNumero: string): Promise <any> {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83aeae4998bf280', {
      elementos: {
        p_encdev_numero: encDevNumero,
        p_com_codigo: '01',
        p_tipdia_codigo: 'ADC',
        p_origen: 'COM',
        p_usuario: this.usuario.identificacion,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  async generarAsientoContableNotRec(encNotRecNumero: string): Promise<any> {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83aeae4af8eee00', {
      elementos: {
        p_encnotrec_numero: encNotRecNumero,
        p_com_codigo: '01',
        p_tipdia_codigo: 'ANR',
        p_origen: 'COM',
        p_usuario: this.usuario.identificacion,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  

  async erAsinroEncDev(strNumero): Promise<any> {
    return this.http.post(this.way + '/cont/contabilizardoc/1db57257de1d760000', {
      elementos: {
        ENCDEV_NUMERO: strNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async erAsinroEncNotRec(strNumero): Promise<any> {
    return this.http.post(this.way + '/cont/contabilizardoc/1db57257de2f8f0000', {
      elementos: {
        ENCDEV_NUMERO: strNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async erAsinroVenencfac(strNumero): Promise<any> {
    return this.http.post(this.way + '/cont/contabilizardoc/1db57257de3eec0000', {
      elementos: {
        ENCFAC_NUMERO: strNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async erAsinroVendvf(strNumero): Promise<any> {
    return this.http.post(this.way + '/cont/contabilizardoc/76d5c95f78fb800', {
      elementos: {
        ENCDVF_NUMERO: strNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async erAsinroVenencgre(strNumero): Promise<any> {
    return this.http.post(this.way + '/cont/contabilizardoc/76d5c95f7886dc0', {
      elementos: {
        ENCGRE_CODIGO: strNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async erAsinrotrncobro(strNumeroDoc, strNumComprobante): Promise<any> {
    return this.http.post(this.way + '/cont/contabilizardoc/57257de3bbbb300', {
      elementos: {
        TRNCOBRO_NRODOC: strNumeroDoc,
        TRNCOBRO_NROCOMPROBANTE: strNumComprobante
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async erAsicomprobante(strAsinro): Promise<any> {
    return this.http.post(this.way + '/cont/contabilizardoc/15c93316cef0b50000', {
      elementos: {
        ASI_NRO: strAsinro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  async generarAsientoMovBan(Bancodigo, Bantipo, bannumero, Banfecha, Banorigen, comcodigo, usuario ): Promise<any>  {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83aeae4ad8fad80', {
      elementos: {
        p_BAN_CODIGO: Bancodigo,
        p_TRNBAN_TIPO: Bantipo,
        p_TRNBAN_NUMERO: bannumero,
        p_TRNBAN_FECHA: Banfecha,
        p_ORIGEN: Banorigen,
        p_COM_CODIGO: comcodigo,
        p_USU_IDENTIFICACION: usuario,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  async generarAsientoEntradaSalida(KAR_NRO, KAR_TIPO, tipDiaCodigo, proceso): Promise<any> {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83aeae49d7ef180', {
      elementoEjecutar: proceso,
      elementos: {
        p_KAR_NRO: KAR_NRO,
        p_kar_tipo: KAR_TIPO,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: tipDiaCodigo,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  async consultarAsiEntradaSalida(strnumDoc): Promise<any> {
   /*  const sentenciaSql = 'SELECT ASI_NRO FROM INV_ENCKARDEX WHERE KAR_NRO = \'' + strnumDoc
      + '\' AND COM_CODIGO = \'01\''; */
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/107755c95f7876000', {
        elementos: {
          numDoc: strnumDoc
        },
      }, this.confIniciales.httpOptions()).toPromise();
  }
  
  async generarAsientoTransferencia(numero: string): Promise<any> {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83aeae4bbb55f80', {
      elementos: {
        p_transfer_nro: numero,
        p_com_codigo: '01',
        p_tipdia_codigo: 'ATRF',
        p_origen: 'INV',
        p_usuario: this.usuario.identificacion,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  async consultarAsiTransferencia(strnumDoc): Promise<any> {
   /*  const sentenciaSql = 'SELECT ASI_NRO FROM INV_ENCTRANSFER WHERE TRANSFER_NRO = \'' + strnumDoc +
      '\' AND COM_CODIGO = \'01\''; */
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/20eeab9b95f78e0000', {
        elementos: {
          numDoc: strnumDoc
        }
      }, this.confIniciales.httpOptions()).toPromise();
  }
  
  async generarAsientoTomaFisica(numero: string): Promise<any>  {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/41d757257de3b800000', {
      elementos: {
        p_trntomafisica_nro: numero,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'ATF',
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  async consultarAsiTomaFisica(strnumDoc): Promise<any> {
   /*  const sentenciaSql = 'SELECT ASI_NRO FROM INV_TRNTOMAFISICA WHERE TRNTOMAFISICA_NRO = \'' + strnumDoc +
      '\' AND COM_CODIGO = \'01\''; */
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/41dd57257de3b800000', {
        elementos: {
          numDoc: strnumDoc
        }
      }, this.confIniciales.httpOptions()).toPromise();
  }
  
  async generarAsientoVenFactSinCXC(encFacNumero): Promise<any> {
    const tam = encFacNumero.length;
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/41d757257de3f000000', {
      elementos: {
        p_ENCFAC_numero: encFacNumero,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'CI',
        p_trncobro_nrodoc: encFacNumero.trim().substring(tam - 7),
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  async generarAsientoVenFact(encFacNumero): Promise<any> {
    
    const tam = encFacNumero.length;
    
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/41d757257de3f000012', {
      elementos: {
        p_ENCFAC_numero: encFacNumero,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'AF',
        p_asi_comprobante: encFacNumero.trim().substring(tam - 7),
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  async generarAsientoVenNotaCred(numero): Promise<any> {
    const tam = numero.length;
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/20ebab92be3aa60000', {
      elementos: {
        p_ENCDVF_numero: numero,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'NC',
        p_asi_comprobante: numero.trim().substring(tam - 7),
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  async generarAsientoGuiaRemi(encgreCodigo: string): Promise<any> {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83aeae4a1e96e00', {
      elementos: {
        p_encgre_codigo: encgreCodigo,
        p_com_codigo: '01',
        p_tipdia_codigo: 'AGR',
        p_origen: 'VEN',
        p_usuario: this.usuario.identificacion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  
  async verificarDocumentosConciliados(strDocumento, strOrigen): Promise<any> {
   /*  let strSentencia = 'select * from ban_Trnban where trncobro_nrodoc=\'' + strDocumento + '\'';
    strSentencia = strSentencia + ' and com_codigo=\'01\'';
    strSentencia = strSentencia + ' and trnban_origen=\'' +  strOrigen + '\'';
     */
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/fbb727b70c66300', {
        elementos: {
          Documento: strDocumento,
          Origen: strOrigen
        }
      }, this.confIniciales.httpOptions()
      ).toPromise();
  }
  
  async generarAsientoComIng(nroDoc, comprobante): Promise<any> {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83aeae4998b4b00', {
      elementos: {
        p_trncobro_nrodoc: nroDoc,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'CI',
        p_asi_comprobante: comprobante
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  async generarAsientoCXCAnticip(nroDoc, cliCodigo): Promise<any> {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/1075d5c93157ed000', {
      elementos: {
        p_trncobro_nrodoc: nroDoc,
        p_cli_codigo: cliCodigo ,
        p_com_codigo: '01',
        p_origen: 'CXC',
        p_tipdia_codigo: 'CI',
        P_usuario: this.usuario.identificacion,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  async generarAsientoDevAnticipo(nroDoc, cliCodigo): Promise<any> {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/1075d5c935df56000', {
      elementos: {
        p_trncobro_nrodoc: nroDoc,
        p_cli_codigo: cliCodigo,
        p_com_codigo: '01',
        p_origen: 'CXC',
        p_tipdia_codigo: 'CE',
        P_usuario: this.usuario.identificacion,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  async generarAsientoCXCNotaCredito(nroDoc, nroComprobante, cliCodigo): Promise<any> {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/1075d5c932f8eb000', {
      elementos: {
        p_trncobro_nrodoc: nroDoc,
        p_trncobro_nrocomprobante: nroComprobante,
        p_cli_codigo: cliCodigo,
        p_com_codigo: '01',
        p_tipdia_codigo: 'ANCC',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  async generarAsientoCXCNotaDebito(nroDoc, nroComprobante, cliCodigo): Promise<any> {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/1075d5c932f8eb012', {
      elementos: {
        p_trncobro_nrodoc: nroDoc,
        p_trncobro_nrocomprobante: nroComprobante,
        p_cli_codigo: cliCodigo,
        p_com_codigo: '01',
        p_tipdia_codigo: 'ANDC',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  async generarAsientoCXCRetencion(nroDoc, nroComprobante): Promise<any> {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83aeae499b77600', {
      elementos: {
        p_trncobro_nrodoc: nroDoc,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'ARTC',
        p_asi_comprobante: nroComprobante
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  async generarAsientoCXPEgreso(nroDoc, nroComprobante, tipDiaCodigo): Promise<any> {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83aeae499974380', {
      elementos: {
        p_trncobro_nrodoc: nroDoc,
        p_com_codigo: '01',
        p_tipdia_codigo: tipDiaCodigo,
        p_usuario: this.usuario.identificacion,
        p_asi_comprobante: nroComprobante,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async erEncnotrecNumero(strNumero) {
    return this.http.post(this.way + '/cont/contabilizardoc/76dd765f1b75fc0', {
      elementos: {
        ENCDEV_NUMERO: strNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerFacturasNomRol(strFechaIni, strFechaFin, strOpcion) {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/c2fadc32f8b6f00', {
      elementos: {
        p_fecini: strFechaIni,
        p_fecfin: strFechaFin,
        p_opcion: strOpcion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

    verificarPeriodoContableProm(intDia, intMes, intAnio) {
    let strDia = '';
    let strMes = '';
    let strAnio = '';
    
    strDia = this.completarFecha(intDia);
    strMes = this.completarFecha(intMes + 1);
    strAnio = this.completarFecha(intAnio);
    /* const strSql = 'select count(*) as contador from saci_maeperiodo ' +
      'where  to_date(' + '\''  + strDia + '/' + strMes + '/' + strAnio + '\'' + ',' + '\'dd/mm/yyyy\' ) >= per_fechainicial' +
      ' and to_date (' + '\''  + strDia + '/' + strMes + '/' + strAnio + '\'' + ',' + '\'dd/mm/yyyy\' ) >= per_fechainicial'; */
    
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/cbb72c370cc5f80', {
        elementos: {
          dia: strDia,
          mes: strMes,
          anio: strAnio
        }
      } , this.confIniciales.httpOptions()
    ).toPromise();
  }

  async erTipRolNombre(strTipRolCodigo): Promise<any> {
    return this.http.post(this.way + '/cont/contabilizardoc/3b7d96778adf16', {
      elementos: {
        TIPROL_CODIGO: strTipRolCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarasientonomrolpago(periodoCodigo, Tiporol) {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/1075d5c95f16de000', {
      elementos: {
        p_periodo_codigo: Number(periodoCodigo),
        p_tiprol_codigo: Tiporol,
        p_com_codigo: '01',
        p_usuidentificacion: this.usuario.identificacion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async getAsiNomRolPago(periodoCodigo, Tiporol): Promise <any> {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83baae4b78ae580', {
        elementos: {
          PERIODO_CODIGO: periodoCodigo,
          TIPROL_CODIGO: Tiporol
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  getOrdenesProduccion(strFechaInicio, strFechaFin) {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/20eee36dcef0d', {
      elementos: {
        fechaIni: strFechaInicio,
        fechaFin: strFechaFin
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getAsiNroProdProc(strNumero, mes) {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/41dd57259de778', {
      elementos: {
        ENCORDPRO_NUMERO: strNumero,
        intMes: mes
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getAsiNroProdTer(strNumero, mes) {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/41dd57259df5dc', {
      elementos: {
        ENCORDPRO_NUMERO: strNumero,
        intMes: mes
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getAsiNroMatPrimaCosto(strNumero, mes) {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83baae4acaee780', {
      elementos: {
        ENCORDPRO_NUMERO: strNumero,
        intMes: mes
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarAsiOrdProd(strParametros) {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/1075d5c9636dcf000', {
      elementos: {
        p_encordpro_numero: strParametros[0],
        p_com_codigo: strParametros[1],
        p_tipdia_codigo: strParametros[2],
        p_origen: strParametros[3],
        p_usuario: strParametros[4]
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getMensajeinfError(strUsuario) {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/83bb6e4e577bb80', {
      elementos: {
        usuario: strUsuario
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarAsiOrdProdTer(strParametros) {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/41d757259ebb78', {
      elementos: {
        p_encordpro_numero: strParametros[0],
        p_com_codigo: strParametros[1],
        p_tipdia_codigo: strParametros[2],
        p_origen: strParametros[3],
        p_usuario: strParametros[4]
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarAsiOrdProdAju(strParametros) {
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/41d75725954fdc', {
      elementos: {
        p_encordpro_numero: strParametros[0],
        p_com_codigo: strParametros[1],
        p_tipdia_codigo: strParametros[2],
        p_usuario: strParametros[3]
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //GSRF
  
  verificarPeriodoContablenew(intDia, intMes, intAnio){
    let strDia = '';
    let strMes = '';
    let strAnio = '';
    
    strDia = this.completarFecha(intDia);
    strMes = this.completarFecha(intMes + 1);
    strAnio = this.completarFecha(intAnio);
    /* const strSql = 'select count(*) as contador from saci_maeperiodo ' +
      'where  to_date(' + '\''  + strDia + '/' + strMes + '/' + strAnio + '\'' + ',' + '\'dd/mm/yyyy\' ) >= per_fechainicial' +
      ' and to_date (' + '\''  + strDia + '/' + strMes + '/' + strAnio + '\'' + ',' + '\'dd/mm/yyyy\' ) >= per_fechainicial'; */
    
    return this.http.post<any[]>(this.way + '/cont/contabilizardoc/cbb72c370cc5f80', {
        elementos: {
          dia: strDia,
          mes: strMes,
          anio: strAnio
        }
      } , this.confIniciales.httpOptions()).toPromise();
  }
  //GSRF
  
}
