import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ComExistenciaBodegas} from '../../com/cominterfaces/comestadisticas';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {ExistenciabodegaService} from '../venservicios/existenciabodega.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {InvTrnReservas} from '../../inv/invinterfaces/invtrnreservas';
import {Usuario} from '../../usuario';
import {DatePipe} from '@angular/common';
import {InvtrnreservasService} from '../../inv/invservicios/invtrnreservas.service';

@Component({
  selector: 'app-existenciabodega',
  templateUrl: './existenciabodega.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class ExistenciabodegaComponent implements OnInit {
  
  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  
  // busqueda
  opcionbusqueda: string;
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];
  tabla: string;
  where: string;
  
  // Existencia por bodega
  displayDialogImagenArt = false;
  displayDialogReserva = false;
  @Input() txtArtCodigo = '';
  @Input() txtArtDescripcion = '';
  @Input() txtListaPrecio = '';
  @Input() iva;
  @Input() txtCliente = '';
  @Input() txtBodegaDes = '';
  @Output() signalSalir = new EventEmitter<boolean>();
  fecha;
  hora;
  
  txtArtPathFoto = '';
  defaultColExistBod;
  detExistenciaBodegas: ComExistenciaBodegas[] = [];
  disabledVerImagen = true;
  
  detalleSeleccionado: ComExistenciaBodegas = {};
  txtCantidadReserva = '';
  txtObservacion = '';
  
  claveDialog = false;
  claveAuth: string;
  
  columnDefsExistenciaBodegas = [
    {
      headerName: 'Código', field: 'BOD_CODIGO', width: 115, editable: false
    },
    {
      headerName: 'Nombre', field: 'BOD_NOMBRE',  width: 190, editable: false
    },
    {
      headerName: 'Existencia', field: 'EXISTENCIA', width: 120, cellStyle: {textAlign: 'right'}, editable: false
    },
    {
      headerName: 'Pedidos', field: 'PEDIDO', width: 90, cellStyle: {textAlign: 'right'}, editable: false
    },
    {
      headerName: 'Reservas', field: 'RESERVAS', width: 90, cellStyle: {textAlign: 'right'}, editable: false
    },
    {
      headerName: 'Disponible', field: 'SALDO', width: 90, cellStyle: {textAlign: 'right'}, editable: false
    },
    // {
    //   headerName: 'Trib.IVA', field: 'ART_TRIBUTAIVA', width: 90, cellStyle: {textAlign: 'right'}, editable: false
    // },
    {
      headerName: 'Precio ', field: 'PRECIO', width: 90, cellStyle: {textAlign: 'right'}, editable: false
    },
    // {
    //   headerName: 'IVA', field: 'IVA', width: 90, cellStyle: {textAlign: 'right'}, editable: false
    // },
    {
      headerName: 'Precio + IVA', field: 'TOTAL', width: 120, cellStyle: {textAlign: 'right'}, editable: false
    }
  ];
  
  constructor( private busqService: InvbusquedaService,
               private existenciaBodegaService: ExistenciabodegaService,
               private message: MessageService, private confirmationService: ConfirmationService,
               private auditoriaGralService: AuditoriagralService, public usuario: Usuario,
               private datePipe: DatePipe, private reservaService: InvtrnreservasService) {
    this.agTable();
  }
  
  async ngOnInit() {
    this.disabledVerImagen = true;
    this.detExistenciaBodegas = [];
    this.fecha = new Date();
    this.hora = new Date();
    
    if (this.txtArtCodigo !== '') {
     // const strSql = 'select art_imagen from TOUCH_MAEPANTALLA where art_codigo = \'' + this.txtArtCodigo + '\'';
      try {
        
        const rs = await this.existenciaBodegaService.existenciaPorBodega(this.txtArtCodigo, this.txtListaPrecio);
        
        const imagen = await this.existenciaBodegaService.obtenerImgArticulo(this.txtArtCodigo);
        
        this.disabledVerImagen = imagen.length === 0 ? true : false;
        
        this.txtArtPathFoto = imagen.length === 0 ? '' : imagen[0].ART_IMAGEN;
        
        
        const detalle: ComExistenciaBodegas[] = [];
        for (const item of rs) {
          if (Number(item.EXISTENCIA) > 0) {
            const det: ComExistenciaBodegas = {};
            det.BOD_CODIGO = item.BOD_CODIGO;
            det.BOD_NOMBRE = item.BOD_NOMBRE;
            det.EXISTENCIA = Number(item.EXISTENCIA).toFixed(2);
            det.PEDIDO = Number(item.PEDIDO).toFixed(2);
            det.RESERVAS = Number(item.RESERVA).toFixed(2);
            const saldo = Number(item.EXISTENCIA) - Number(item.PEDIDO) - Number(item.RESERVA);
            det.SALDO = Number(saldo).toFixed(2);
            det.ART_TRIBUTAIVA = item.ART_TRIBUTAIVA;
            det.PRECIO = Number(item.PRECIO).toFixed(2);
            const iva = Number(this.iva) / 100;
            det.IVA = item.ART_TRIBUTAIVA === 'S' ? Number(Number(iva) * Number(item.PRECIO)).toFixed(2) : Number(0).toFixed(2);
            det.TOTAL = Number(item.PRECIO + Number(det.IVA)).toFixed(2);
            detalle.push(det);
          }
          
        }
        this.detExistenciaBodegas = detalle;
        // this.displayDialogExistBod = true;
      } catch (err) {
        console.log(err);
      }
    }
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    
    
    
    this.defaultColExistBod = {
      editable: true,
      width: 100,
      objeto: 'existenciabodegas',
      resizable: true
    };
  }
  
  visualizarImagen() {
    this.displayDialogImagenArt = true;
  }
  
  buscarArticulo() {
    
    this.txtArtCodigo = '';
    this.txtArtDescripcion = '';
    this.detExistenciaBodegas = [];
    
    this.opcionbusqueda = 'articulo';
    this.types = [
      {label: 'Código', value: 'ART_CODIGO'},
      {label: 'Nombre', value: 'ART_NOMBRE'},
      {label: 'Grupo', value: 'GRUP_CODIGO'},
      {label: 'NombreCorto', value: 'ART_NOMBREC'},
      {label: 'Laboratorio', value: 'ART_LABORATORIO'},
      {label: 'Ubicación', value: 'ART_UBICACION'},
      {label: 'CodigoBarras', value: 'ART_CODIGOALT1'},
      {label: 'Alterno3', value: 'ART_CODIGOALT3'},
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', ''];
    this.tabla = 'INV_MAEARTICULO';
    this.where = 'ART_ACTIVO=\'S\'';
    
    this.busqService.busquedaInvMaeArticulo1().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }
  
  manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'articulo') {
      this.txtArtCodigo = opcion.ART_CODIGO;
      this.txtArtDescripcion = opcion.ART_NOMBRE;
      this.llenarExistenciaBod();
    }
    this.displayDialogBusquedaFast = false;
  }
  
  async llenarExistenciaBod() {
    this.disabledVerImagen = true;
    this.detExistenciaBodegas = [];
   // const strSql = 'select art_imagen from TOUCH_MAEPANTALLA where art_codigo = \'' + this.txtArtCodigo + '\'';
    try {
      const rs = await this.existenciaBodegaService.existenciaPorBodega(this.txtArtCodigo, this.txtListaPrecio);
      const imagen = await this.existenciaBodegaService.obtenerImgArticulo(this.txtArtCodigo);
      this.disabledVerImagen = imagen.length === 0 ? true : false;
      this.txtArtPathFoto = imagen.length === 0 ? '' : imagen[0].ART_IMAGEN;
      const detalle: ComExistenciaBodegas[] = [];
      for (const item of rs) {
        if (Number(item.EXISTENCIA) > 0) {
          const det: ComExistenciaBodegas = {};
          det.BOD_CODIGO = item.BOD_CODIGO;
          det.BOD_NOMBRE = item.BOD_NOMBRE;
          det.EXISTENCIA = Number(item.EXISTENCIA).toFixed(2);
          det.PEDIDO = Number(item.PEDIDO).toFixed(2);
          det.RESERVAS = Number(item.RESERVA).toFixed(2);
          const saldo = Number(item.EXISTENCIA) - Number(item.PEDIDO) - Number(item.RESERVA);
          det.SALDO = Number(saldo).toFixed(2);
          det.ART_TRIBUTAIVA = item.ART_TRIBUTAIVA;
          det.PRECIO = Number(item.PRECIO).toFixed(2);
          const iva = Number(this.iva) / 100;
          det.IVA = item.ART_TRIBUTAIVA === 'S' ? Number(Number(iva) * Number(item.PRECIO)).toFixed(2) : Number(0).toFixed(2);
          det.TOTAL = Number(item.PRECIO + Number(det.IVA)).toFixed(2);
          detalle.push(det);
        }
        
      }
      this.detExistenciaBodegas = detalle;
    } catch (err) {
      this.mensajeAlerta('error', 'Error', 'Proceso de listar información no terminó satisfactoriamente..!!!');
    }
  }
  
  salir() {
    this.signalSalir.emit(true);
  }
  
  reservar() {
    if (Object.keys(this.detalleSeleccionado).length > 0) {
      this.txtCantidadReserva = '';
      this.txtObservacion = this.txtCliente;
      this.displayDialogReserva = true;
    } else {
      this.mensajeAlerta('warn', 'Información', 'Seleccione la bodega en la que va a realizar la reserva...!!!');
    }
  }
  
  selecciondetalle(params) {
    if (params.object === undefined) {
      return;
    }
    
    this.detalleSeleccionado = params.object;
    
  }
  
  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'venexistenciabod',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
  
  mensajeAlertaReserva(tipo, titulo, msg) {
    this.message.add({
      key: 'venreserva',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
  
  async guardarReserva() {
    if (this.txtCantidadReserva === undefined || String(this.txtCantidadReserva).trim().length === 0) {
      this.mensajeAlertaReserva('info', 'Información', 'Ingrese la cantidad de la reserva');
      return;
    }
    
    if (Number(this.txtCantidadReserva) === 0) {
      this.mensajeAlertaReserva('info', 'Información', 'La cantidad de la reserva no puede ser cero');
      return;
    }
    
    if (Number(this.txtCantidadReserva) > Number(this.detalleSeleccionado.SALDO)) {
      this.mensajeAlertaReserva('info', 'Información', 'La cantidad a reservar es mayor a la cantidad disponible del artículo');
    } else {
      this.claveAuth = '';
      this.claveDialog = true;
    }
  }
  
  cancelarReserva() {
    this.displayDialogReserva = false;
  }
  async comprobarClave() {
    // const strSql = 'select USUCLAVE from seg_maeusuario where usuidentificacion = \'' + this.usuario.identificacion + '\'';
    const clave = await this.existenciaBodegaService.usuarioClave(this.usuario.identificacion);
    let claveReal = '';
    if (clave.length > 0) {
      claveReal = clave[0].USUCLAVE;
      if (claveReal === this.claveAuth) {
        this.claveDialog = false;
        try {
          const diaCaduca = await this.reservaService.erconfiguracionClave();
          const numDias = diaCaduca === null ? 1 : Number(diaCaduca[0].CFG_VALOR2);
          const fechaActual = new Date();
          const fecha1 = new Date();
          fecha1.setDate(fecha1.getDate() + numDias);
          const item: InvTrnReservas = {};
          item.TRNRES_USUARIO = this.usuario.identificacion;
          item.TRNRES_FECHA = this.datePipe.transform(fechaActual, 'dd/MM/yyyy HH:mm');
          item.TRNRES_CADUCA = this.datePipe.transform(fecha1, 'dd/MM/yyyy HH:mm');
          item.TRNRES_OBSERVACION = this.txtObservacion;
          item.COM_CODIGO = '01';
          item.TRNRES_CANTIDAD = Number(this.txtCantidadReserva);
          item.BOD_CODIGO = this.detalleSeleccionado.BOD_CODIGO;
          item.ART_CODIGO = this.txtArtCodigo;
          item.TRNRES_NUMERO = '';
          item.TRNRES_ENTREGADO = '';
          item.BOD_CODIGODES = this.txtBodegaDes;
         // const srtSql = 'select art_unidadcosteo from inv_maearticulo where art_codigo = \'' + this.txtArtCodigo + '\'';
          const unidad = await this.existenciaBodegaService.artUnidadCosteo(this.txtArtCodigo);
          item.ART_UNIDAD = unidad.length === 0 ? '' : unidad[0].ART_UNIDADCOSTEO;
          
          await this.reservaService.guardarReserva(item);
          this.auditoriaGralService.registrarAuditoria('INV_TRNRESERVA',   + 'VEN-FACT/' +
            item.ART_CODIGO, 'I',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });
          this.displayDialogReserva = false;
          this.mensajeAlerta('success', 'Información', 'Reserva guardada exitosamente...!!!');
          this.llenarExistenciaBod();
          
        } catch (e) {
          this.mensajeAlertaReserva('error', 'Error', 'No se ha podido guardar la reserva correctamente..!!!');
        }
      } else {
        this.claveDialog = false;
        this.mensajeAlertaReserva('error', 'Error', 'La clave es incorrecta');
      }
    } else {
      this.mensajeAlertaReserva('error', 'Error', 'No se encontró la clave del usuario ' + this.usuario.identificacion );
    }
  }
  
}
