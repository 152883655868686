<p-toast [style]="{marginTop: '30px'}" position="top-center" key="tnonmaedatosadi"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="dnonmaedatosadi" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 99%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
						 [botonRegresar]="btnRegresar" [barraBotonesCRUD]="false"  
						 [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true"
													 
						
						 (signal)="manejarSenales($event)"></app-invmaebarra>
	</div>

</div>
<div style="overflow: hidden" class="p-col-12">
	<p-tabView>
		<p-tabPanel header="Campos Adicionales">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="false"
							[enableFilter]="true"
							[rowData]="nommaedatosadiService.nommaedatosadi"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefNomDatosadi"
							[defaultColDef]="defaultColDefNomDatosadi"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)=selectNomDatosadi($event)
							(cambios)="cambioModulo($event)"

			></app-aggridsaci>
        </p-tabPanel>
        <div class="divgrups">
            <div class="p-grid" style="margin-top: 10px; margin-bottom: 10px">
                <div class="p-col-3">
                </div>
                <div class="p-col-6">
                    <button  pButton class="aceptar" style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100% " (click)="opcionAdicionales()">Adicionar campos nuevos al maestro de Empleados</button>	
                </div>
                <div class="p-col-3">
                </div>
            </div>
        </div>
    </p-tabView>

</div>
<p-confirmDialog [transitionOptions]="'0ms'" key="dnonmaedatosopt" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>