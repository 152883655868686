<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="cfngimpr"></p-toast>
<p-confirmDialog [style]="{width: '41vw'}" [transitionOptions]="'0ms'" key="confcfngimpr"
				 title="Reporte" appendTo="body"></p-confirmDialog>
<a id="areporte" target="_blank" [routerLink]="['/reportes' , reporteSeleccionado.CNFGIMPR_REPORTE ]"
   routerLinkActive="active">
</a>
<app-invmaebarra [barraBotonesCRUD]="true" [barraBotonesDesplaz]="true"
				 (signal)="manejarSenales($event)"
				 [barraBotones3]="true"
				 [barraBotonesAsiCon]="true"
				 [barraBotones5]="true"
				 [reporte]="true"></app-invmaebarra>
<!--[imprimir]="false"-->
<div class="p-grid" (keydown.f4)="visualizarValorconsulta()">
	<div class="p-col-12">
		<div style="height: 180px">
			<p-table [value]="reportes" selectionMode="single" scrollable="true" scrollHeight="200px">
				<ng-template pTemplate="colgroup" let-columns>
					<colgroup>
						<col style="width:100px">
						NOMBRE
					</colgroup>
				</ng-template>
				<ng-template pTemplate="header" let-columns>
					<tr>
						<th>NOMBRE</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-reporte let-rowData>
					<tr [pSelectableRow]="rowData || reporteSeleccionado" (click)="mandarinforeporte(rowData)">
						<td *ngIf="reporte !== undefined">{{reporte.CNFGIMPR_NOMBRE}}</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>
	<div class="p-col-12">
		<span (click)="visualizarValorconsulta()">F4 ->Visualizar Formula Reporte</span>
	</div>
</div>
