import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnexgenerararchivosService {
  
  
  private way: string;
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService) {
  
    this.way = sessionStorage.getItem('way');
  }
  
  verificarFacIncorrectas(fechaInicial, fechaFinal) {
    return this.http.post<any[]>(this.way + '/anex/genarch/3eedc9ea64aec', {
      elementos: {
        fechaIni: fechaInicial,
        fechaFin: fechaFinal
      }
    },
      this.confIniciales.httpOptions()).toPromise();
  }
  
  facNoAutorizadas(fechaInicial, fechaFinal) {
    return this.http.post<any[]>(this.way + '/anex/genarch/3d4cbe15eee372', {
      elementos: {
        fechaIni: fechaInicial,
        fechaFin: fechaFinal
      }
    },
      this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerCodSri(strCodigo) {
    return this.http.post<any[]>(this.way + '/anex/genarch/10775986f372', {
        elementos: {
          codsri_ref: strCodigo
        }
      },
      this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerCodSriRef(strCodigo) {
    return this.http.post<any[]>(this.way + '/anex/genarch/10775986edcf', {
        elementos: {
          periodo: strCodigo
        }
      },
      this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerConfiguracion() {
    return this.http.post<any[]>(this.way + '/anex/genarch/1077598bbe50', {
        elementos: {},
      },
      this.confIniciales.httpOptions()).toPromise();
  }
  
  truncarValoresIvaCom() {
    return this.http.post<any[]>(this.way + '/anex/genarch/777ebb25f53318', {
        elementos: {},
      },
      this.confIniciales.httpOptions()).toPromise();
  }
  
  truncarValoresRetCom() {
    return this.http.post<any[]>(this.way + '/anex/genarch/777ebb36eeb318', {
        elementos: {},
      },
      this.confIniciales.httpOptions()).toPromise();
  }
  
  truncarValoresIvaVen() {
    return this.http.post<any[]>(this.way + '/anex/genarch/777ebb25f57dd8', {
        elementos: {},
      },
      this.confIniciales.httpOptions()).toPromise();
  }
  
  buscarEmpresas(strCondicion) {
    return this.http.post<any[]>(this.way + '/anex/genarch/2fdc62b6eb676e', {
        elementos: {
          condicion: strCondicion
        },
      }, this.confIniciales.httpOptions()).toPromise();
  }
  
  insertarEsquema(params) {
    return this.http.post<any[]>(this.way + '/anex/genarch/14d6bb9af3aca', {
      elementos: {
        p_esquema_codigo: params[0],
        p_com_codigo: params[1],
        p_esquema_nombre: params[2],
        p_esquema_empresa: params[3],
        p_esquema_origen: params[4]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarEsquema(params) {
    return this.http.post<any[]>(this.way + '/anex/genarch/3ab2b3b9af3aca', {
      elementos: {
        p_com_codigo: params[0],
        p_esquema_codigo: params[1],
        p_esquema_origen: params[2]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  leerConfiguracionDatosElectronicos() {
    return this.http.post<any[]>(this.way + '/anex/genarch/6dca6abb8755cc', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  
  generarCompras(params) {
    return this.http.post<any[]>(this.way + '/anex/genarch/41d776d98b6768', {
      elementos: {
        p_anio: params[0],
        p_mes: params[1],
        p_com_codigo: params[2],
        p_microempresas: params[3],
        p_esquema: params[4],
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  consultarEsquemas(strCondicion) {
    return this.http.post<any[]>(this.way + '/anex/genarch/41dd7735e7595c', {
      elementos: {
        condicion: strCondicion
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarIvaCom() {
    return this.http.post<any[]>(this.way + '/anex/genarch/1d595a5f53316', {
      elementos: {},
      
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarIvaVen() {
    return this.http.post<any[]>(this.way + '/anex/genarch/1d595a5f57dd7', {
      elementos: {},
      
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarIvaAnu() {
    return this.http.post<any[]>(this.way + '/anex/genarch/1d595a5f52afe', {
      elementos: {},
      
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarDatosTablasTmpFormaPago() {
    return this.http.post<any[]>(this.way + '/anex/genarch/3ab2b355db65f8', {
      elementos: {}, 
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarVentas(params) {
    return this.http.post<any[]>(this.way + '/anex/genarch/41d776feebf55c', {
      elementos: {
        p_anio: params[0],
        p_mes: params[1],
        p_com_codigo: params[2],
        p_microempresas: params[3],
        p_esquema: params[4],
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarDocumentosAnulados(params) {
    return this.http.post<any[]>(this.way + '/anex/genarch/20ebbb6dc315800000', {
      elementos: {
        p_anio: params[0],
        p_mes: params[1],
        p_com_codigo: params[2],
        p_microempresas: params[3],
        p_esquema: params[4],
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
}
