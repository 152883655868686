import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SegclaveautorizaService } from 'src/app/nom/nomservicios/segclaveautoriza.service';
import { MessageService } from 'primeng';

@Component({
  selector: 'app-segclaveautoriza',
  templateUrl: './segclaveautoriza.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class SegclaveautorizaComponent implements OnInit {
  @Input() codigo:string;
  @Output() salirclave= new EventEmitter<string>();
  @Output() autorizado= new EventEmitter<boolean>();
  claveAuth:string="";
  constructor(private claveservicio:SegclaveautorizaService,
              private messageService: MessageService) { }

  ngOnInit(): void {
  }
  manejarSenalesActivar(valor){
    switch (valor) {
      case 'Visto':
        this.opcionVisto();
        break;
      case 'Salir':
        this.salirclave.emit('salir');
        break;
    }
  }
  async opcionVisto(){
    var resul=await this.claveservicio.validarActivarValor(this.claveAuth,this.codigo);
    if(resul==true){
      this.autorizado.emit(true);
      this.claveAuth="";
      this.salirclave.emit('salir');
    }else{
      this.autorizado.emit(false);
      this.mensajeAlertaAuto('error', 'Error', 'Contraseña incorrecta');
    }
  }
  onHideActivarVal(){
    this.salirclave.emit('salir');
    
  }
  mensajeAlertaAuto(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'autorizacion',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
    
  }
}
