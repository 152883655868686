
<p-toast [style]="{marginTop: '30px'}" position="top-center" key="otrosEgresos"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmotrosEgresos" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
						 [botonBorrar]="btnBorrar"
						 [botonAnterior]="botonAnterior" [botonPrimero]="botonPrimero"
                         [botonUltimo]="botonUltimo"
                         [botonAsiento]="false"
                         [botonverAsiento]="false"
						 [botonSiguiente]="botonSiguiente" [barraBotones3]="true" [barraConEncasil]="true"
                          [botonBuscar]="btnBuscar" [botonBinocular]="true" [botonAsiento]="false"
                          [botonAlmacenardoc] = "true"
                          (signal)="manejarSenales($event)">
		</app-invmaebarra>
	</div>
</div>
<div>
    <table style="margin-left: 50vw;">
        <tr style="background-color: rgba(66, 214, 240, 0.829);">
            <td style="border: 1px solid; text-align: center;">{{identiUsuario}}</td>
            <td style="border: 1px solid; text-align: center;">{{fecha | date: 'dd/MM/yyyy' }}</td>
            <td style="border: 1px solid; text-align: center;">{{fecha | date: 'hh:mm' }}</td>
        </tr>
    </table>
</div>
<div style="overflow: hidden" class="p-col-12">
    <p-tabView>
        <p-tabPanel header="Datos anticipo">
            <div class="divgrups">
                <div class="p-grid">
                    <div class="p-col-8"></div>
                    <div class="p-col-4">
                        <table style="width: 100%;">
                            <tr style="background-color: rgba(126, 169, 177, 0.829);">
                                <td style="border: 1px solid; text-align: center;">{{lblasi_nro}}</td>
                            </tr>
                        </table>
                    </div>
                    
                </div>
                <div class="p-grid">

                    <div class="p-col-2" style="padding-left: 30px;">
                        <span >Empleado:</span>
                    </div>

                    <div class="p-col-2" >
                        <input class="frm"  type="text" pInputText  style="width: 80%;" [(ngModel)]="txtPROcodigo.text" [disabled]= "!txtPROcodigo.Enabled">
                        <button type="button" (click)="busquedaEmpleado()"
                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                            class="littlebuttons"  ></button>
                    </div>

                    <div class="p-col-3">
                        <span style="color: mediumblue;">{{lblPROnombre}}</span>
                    </div>

                    <div class="p-col-1">
                        <span >{{lblEMP_CODIGO}}</span>
                    </div>

                    <div class="p-col-2" style="padding-left: 30px;">
                        <span>Fecha emisión:</span>
                    </div>
                    <div class="p-col-2">
                        <p-calendar id="inpFechaSali" [inputStyle]="{width: '100%', height: '20px'}"
                            [(ngModel)]="dTFechaEmision"
                            dateFormat="dd/mm/yy" 
                            (onChange)="selecFechaEmi($event)"
                        >
                        </p-calendar>
                    </div>

                </div>
                <div class="p-grid">

                    <div class="p-col-2" style="padding-left: 30px;">
                        <span >No. de Documento:</span>
                    </div>
                    <div class="p-col-2">
                        <input class="frm"  [(ngModel)]="txtNumeroDoc.text" type="text" pInputText readonly="readonly">
                    </div>

                    <div class="p-col-1" style="padding-left: 50px;">
                        <span >Td:</span>
                    </div>
                    <div class="p-col-2">
                        <p-dropdown 
                            [options]="cmb_td" 
                            [style]="{'minWidth': '100%', 'width':'100%'}"
                            optionLabel="TIPDIA_CODIGO" 
                            optionValue="TIPDIA_CODIGO"
                            [panelStyle]="{'width':'110%'}"
                            [(ngModel)]="tipSeleccionado"
                            (onChange)="selecTip($event)">
                                    
                        </p-dropdown>
                    </div>
                    <div class="p-col-1"></div>
                    <div class="p-col-2" style="padding-left: 30px;">
                        <span >Nro de Comprobante:</span>
                    </div>
                    <div class="p-col-2">
                        <input class="frm"  type="text" pInputText [(ngModel)]="txtNumComprobante">
                    </div>


                </div>
                <div class="p-grid">

                    <div class="p-col-2" style="padding-left: 30px;">
                        <span>Concepto:</span>
                    </div>
                    <div class="p-col-3">
                        <p-dropdown 
                                    
                            [options]="cmbMAECONCPOTREGRE" 
                            [style]="{'minWidth': '100%', 'width':'100%'}"
                            optionLabel="MAECONCPOTREGRE_NOMBRE" 
                            optionValue="MAECONCPOTREGRE_CODIGO"
                            [panelStyle]="{'width':'110%'}"
                            [(ngModel)]="conceptoSeleccionado"
                            (onChange)="selecConcepto($event)">
                                    
                        </p-dropdown>
                    </div>

                    <div class="p-col-2" style="padding-left: 50px;">
                        <span >Cta Contable:</span>
                    </div>
                    <div class="p-col-2" >
                        <input class="frm"  type="text" pInputText  style="width: 80%;" [(ngModel)]="txtCON_CODIGO">
                        <button type="button"
                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                            class="littlebuttons" (click)="busCuentaCons(nomEmpl.EMP_CONCODIGO)" ></button>
                    </div>

                    <div class="p-col-1" style="padding-left: 30px;">
                        <span >CC:</span>
                    </div>
                    <div class="p-col-2" >
                        <input class="frm"  type="text" pInputText  style="width: 80%;"  [(ngModel)]="txtEMP_CENTCOST">
                        <button type="button"
                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                            class="littlebuttons" (click)="busquedaCentroCostos()" ></button>
                    </div>

                </div>
                <div class="p-grid">

                    <div class="p-col-2" style="padding-left: 30px;">
                        <span >Descripción:</span>
                    </div>
                    <div class="p-col-5">
                        <input class="frm"  [(ngModel)]="txtConcepto" type="text" pInputText>
                    </div>

                </div>
                <div class="p-grid">

                    <div class="p-col-2" style="padding-left: 30px;">
                        <span >Importe:</span>
                    </div>
                    <div class="p-col-2">
                        <input class="frm"  type="number" pInputText [(ngModel)]="txtImporte.text" [disabled]="!txtImporte.Enabled" (change)="setSaldo()">
                    </div>

                    

                    <div class="p-col-1" style="padding-left: 30px;">
                        <span >Saldo:</span>
                    </div>
                    <div class="p-col-2">
                        <span >{{lblSaldo}}</span>
                    </div>

                    <div *ngIf="fraCUOTAS" class="p-col-1" style="padding-left: 30px;">
                        <span >Cuotas:</span>
                    </div>
                    <div *ngIf="fraCUOTAS" class="p-col-1">
                        <input class="frm"  type="number" pInputText  [(ngModel)]="txtENCANTEMP_NUMECUOT">
                    </div>


                    <div *ngIf="fra_dias" class="p-col-1" style="padding-left: 30px;">
                        <span >Dias:</span>
                    </div>
                    <div *ngIf="fra_dias" class="p-col-1">
                        <input class="frm"  type="number" pInputText  [(ngModel)]="txtENCANTEMP_NUMDIASVACA">
                    </div>

                    <!-- <div class="p-col-2"  *ngIf="(!fra_dias  && !fraCUOTAS && fraPERIODO)"></div> -->

                    <div  *ngIf="fraPERIODO" class="p-col-1" >
                        <span style="width: 80%;" >Periodo:</span>
                    </div>
                    <div *ngIf="fraPERIODO"  class="p-col-2">
                        <span style="background-color: rgba(126, 169, 177, 0.829);width: 80%;">{{lblPERIODO_FECHAINI}}</span>
                        <button type="button"
                            class="littlebuttons" style="background-color: rgb(140, 199, 233);" (click)="buscarPeriodos()">
                            ...
                        </button>
                    </div>

                </div>
                
            </div>
            <div class="divgrups">
                <p-tabView>
                    <p-tabPanel header="FORMA PAGO">
                        <app-aggridsaci  [width]="" [height]="largo"
												[enableSorting]="false" [enableFilter]="true"
												[rowData]="dataFormaPago"
												[frameworkComponents]="frameworkComponents" [animateRows]="true"
												[rowSelection]="rowSelection" [columnDefs]="columDefFormaPago"
												[defaultColDef]="defaultColumnDefFormaPago"
												[rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true"
                                                [mostrarGuardar]="false" (cambios)="cambio($event)"
                                                [botonNuevo]="botonnuevo"
                                                [botonBorrar]="botoneliminar"
                                                [botonRegresar]="botoncancelar"
                                                [botonGuardar]="true"
												(selected)="seleccionFormaPago($event)">
                        </app-aggridsaci>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </p-tabPanel>
    </p-tabView>
</div>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaEmpleado"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccionEmpleado($event)"></app-busquedadlg>
</p-dialog>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastCuenCont"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCuenCont" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccionCuenCont($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaCentroCostos"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccionCentroCost($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaEgresos"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccionEgresos($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Periodo" *ngIf="displaydialogEgrePerEmpl" [(visible)]="displaydialogEgrePerEmpl"
                              [transitionOptions]="'0ms'"
                              [modal]="true"
                              appendTo="body"
                              (onHide)="onHideEgre()"
                              [style]="{width: '70vw', height: 'auto'} ">
            <p-toast [style]="{marginTop: '41vh'}" position="top-center" key="rubDialog"></p-toast>
            <app-nomempleadoperiodo  [cuotas]="frmNomEmpleado2.lblCUOTAS" [fechareg]="frmNomEmpleado2.lblFECHA"(salirclo)="salirEgrePerEmpl($event)" (resp)="llenarEgrePerEmpl($event)"></app-nomempleadoperiodo>                       
</p-dialog>

<p-dialog header="Vacaciones" [(visible)]="monstrarEncRol3" *ngIf="monstrarEncRol3">
    <app-nomencrol3  [datosIn]="datosRol3" (salirvac)="salirf($event)"></app-nomencrol3>
</p-dialog>


<p-dialog header="Forma de pago" [(visible)]="displaydialogFormaPago"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccionFormaPago($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Forma de pago" [(visible)]="displaydialogBancos"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccionBancos($event)"></app-busquedadlg>
</p-dialog>
        
<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width:'90vw', height:'120vh'}"
		  [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="asiento" [abiertoDesde]="'otrosEgresos'"
					(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog> 
<!-- ----------------------- -->
<p-dialog header="Codigo Caja" [(visible)]="displaydialogCodigoCaja"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccionCodigoCaja($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Reportes" [(visible)]="displayDialogReportes" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '40vw', height: '400px'}">
    <app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
        [strNumDoc]="lblasi_nro" [strCampo]="'ASI_NRO'"
        (cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'NOMOTROSEGRESOS'" [txtNumComprobante] = "txtNumeroDoc.text"
	(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>


