import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class SegmaeperfilService {

  public way: string;

  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
    this.usuario.identificacion;
  }
  
  consultaPerfil(where) {
    const parametro1 = 'T';
    
    let parametro2 = 'COM_CODIGO = \'01\'';
    if (where != null) {
      parametro2 += ' AND ' + where;
    }
    const parametro3 = '';
    
    return this.http.post<any[]>(this.way + '/seg/perfil/662fcf572edbe40', {
      elementos: {
        p_opcion: parametro1,
        p_filtro: parametro2,
        p_condicion: parametro3
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  consultarPermisos(perfil) {
    const parametro1 = 'T';
    
    let parametro2 = 'COMCODIGO = \'01\'';
    if (perfil != null) {
      parametro2 += ' AND PERFIL_CODIGO = \'' + perfil.Código + '\'';
    }
    const parametro3 = '';
    
    return this.http.post<any[]>(this.way + '/seg/perfil/3317e65dbb4b98', {
      elementos: {
        p_opcion: parametro1,
        p_filtro: parametro2,
        p_condicion: parametro3
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  cargarModuloOpciones() {
    return this.http.post<any[]>(this.way + '/seg/perfil/315b85b0dc6592', {
      elementos: {
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  cargarMenuConOpciones(prefijo) {
    
    return this.http.post<any[]>(this.way + '/seg/perfil/315b859d7c6592', {
      elementos: {
        p_prefijon: prefijo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerOpciones() {
    const strSentencia = 'select * from seg_maeopcion  order by modprefijo,mencodigo,opccodigo';
    
    return this.http.post<any[]>(this.way + '/seg/perfil/c2faec6592c5e00', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  guardarPerfil( codigo: string, nombre: string) {    
    return this.http.post<any[]>(this.way + '/seg/perfil/43cadb72edbe54', {
      elementos: {
        p_PER_codigo_1: codigo,
        p_PER_nombre_2: nombre,
        p_COM_codigo_3: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise(); 
  }
  
  actualizarPerfil( codigo: string, nombre: string, oldCodigo: string) {
    
    return this.http.post<any[]>(this.way + '/seg/perfil/14cefb2edbe55', {
      elementos: {
        p_PER_codigoa: oldCodigo,
        p_PER_codigo_1: codigo,
        p_PER_nombre_2: nombre,
        p_COM_codigo_3: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
    
    
  }
  
  eliminarPerfil( codigo: string) {
    
    return this.http.post<any[]>(this.way + '/seg/perfil/3ab2b4b2edbe54', {
      elementos: {
        p_PER_codigo_1: codigo,
        p_COM_codigo_2: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
    
    
  }
  
  guardarPermiso( perfilCodigo, permiso  ) {
    return this.http.post<any[]>(this.way + '/seg/perfil/8795b6e5dbb4b80', {
      elementos: {
        p_PERFIL_CODIGO:    perfilCodigo,
        p_COMCODIGO:        '01',
        p_PERSELECCION:     permiso.PERSELECCION,
        p_PERINSERCION:     permiso.PERINSERCION,
        p_PERACTUALIZACION: permiso.PERACTUALIZACION,
        p_PERELIMINACION:   permiso.PERELIMINACION,
        p_PERTODO:          permiso.PERTODO,
        p_OPCCODIGO:        permiso.OPCCODIGO,
        p_MENCODIGO:        permiso.MENCODIGO,
        p_MODPREFIJO:       permiso.MODPREFIJO,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  actualizarPermiso( perfilCodigo, permiso  ) {
    const strSentencia = `update SEG_MAEPERMISOPERFIL set
      PERSELECCION = ${permiso.PERSELECCION},
      PERINSERCION = ${permiso.PERINSERCION},
      PERACTUALIZACION = ${permiso.PERACTUALIZACION},
      PERELIMINACION = ${permiso.PERELIMINACION},
      PERTODO = ${permiso.PERTODO}
       where MODPREFIJO = '${permiso.MODPREFIJO}'
    and OPCCODIGO = ${permiso.OPCCODIGO} and MENCODIGO = ${permiso.MENCODIGO}
    AND PERFIL_CODIGO = '${perfilCodigo}'`;
    
    console.log(strSentencia);
    
    return this.http.post<any[]>(this.way + '/seg/perfil/299df65dbb4b98', {
        elementos: {
          PERSELECCION: permiso.PERSELECCION,
          PERINSERCION: permiso.PERINSERCION,
          PERACTUALIZACION: permiso.PERACTUALIZACION,
          PERELIMINACION: permiso.PERELIMINACION,
          PERTODO: permiso.PERTODO,
          MODPREFIJO: permiso.MODPREFIJO,
          OPCCODIGO: permiso.OPCCODIGO,
          MENCODIGO: permiso.MENCODIGO,
          PERFILCODIGO: perfilCodigo
        },
       
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  
}
