import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { NomproccontabService } from '../nomservicios/nomproccontab.service';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import { AggridBanComponent } from 'src/app/ban/aggrid-ban/aggrid-ban.component';
import { UtilitariosService } from '../../advantage/advantageservices/utilitarios.service';
import { NomProcContab } from '../nominterfaces/nomproccontab';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { MessageService } from 'primeng';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { Usuario } from 'src/app/usuario';
import {NomenviorolesService} from '../nomservicios/nomenvioroles.service';
import {NomEnvioRoles} from '../nominterfaces/nomevioroles';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { saveAs } from 'file-saver/FileSaver';
import {Segencenviocorreo} from '../../seg/seginterfaces/segencenviocorreo';
@Component({
  selector: 'app-nomenvioroles',
  templateUrl: './nomenvioroles.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NomenviorolesComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  fechaIni;
  fechaFin;

  defaultColDefRolPagos;
  public frameworkComponents;

  rowStyle;
  largo = '258';
  rowSelection = 'multiple';
  rolesPago: NomEnvioRoles [] = [];


  radioButton= 'val1';
  valSelect = 'valSelect'
  PERIODO_COD='';
  comlogo
  spin;
  htmlenc;
  htmlcue;
  nombrep;
  //CORREOS
  encenviocorreo: Segencenviocorreo = {};
//reporte
reporte='';
  columnDefRolPagos = [
    {
      headerName: 'S', field: 'EMVIAR_CORREO',  cellRendererFramework: AggridBanComponent,  
      editable: (params) => {
        return false;
      }, cellEditor: 'cellPrueba', width: 50, onCellClicked: (params) => {
        this.seleccionItem(params.data);  
      }
    },
    {
      headerName: 'Período', field: 'PERIODO_CODIGO', editable: false, cellEditor: 'cellPrueba', width: 100, 
      
    },
    {
      headerName: 'Fecha Inicio', field: 'PERIODO_FECINI',editable: false, cellEditor: 'cellPrueba', width: 180,
      
    },
    {
      headerName: 'Fecha Final', field: 'PERIODO_FECFIN', editable: false,cellEditor: 'cellPrueba', width: 180, 
      
    },{
      headerName: 'CI', field: 'CEDULA', editable: false, cellEditor: 'cellPrueba', width: 140, 
      
    },
    {
      headerName: 'Empleado', field: 'EMP_NOMBRE', editable: false, cellEditor: 'cellPrueba', width: 250, 
      
    },
    {
      headerName: 'Sueldo', field: 'EMP_SUELDO', editable: false, cellEditor: 'cellPrueba', width: 150, cellStyle: { textAlign: 'right' },
      
    },
    {
      headerName: 'Tipo de rol', field: 'TIPO_ROL', editable: false, cellEditor: 'cellPrueba', width: 180,
      
    },
  ];
  
  constructor(private init: NuevoComponentService,
    private contabSrv: NomproccontabService,
    private messageService: MessageService,
    public usuario: Usuario,
    private errorService: ErroresBaseDatosService, private auditoriagral: AuditoriagralService,
    private nomenviorolesService:NomenviorolesService,
    private confIniciales: ConfInicialesService,
    private utilitariosService:UtilitariosService)
  {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefRolPagos = {
      editable: true,
      width: 130,
      objeto: 'rolPagos'
    };


  }


  seleccionItem(param: NomEnvioRoles){
    
    console.log(param.EMVIAR_CORREO)
  }

  async ngOnInit() {
    this.fechaIni = new Date();
    this.fechaFin = new Date();
    this.encenviocorreo={}
    await this.getdatoscorreo();
    this.getlogotipo()
    this.gtmhtml();
    
    this.spin=false;
  }
  getlogotipo() {
    this.utilitariosService.getLogotipo().subscribe(data => {
      for (const rs of data) {
        this.comlogo=rs.COM_LOGO;
      }      
    });
  }
  gtmhtml(){
    this.nomenviorolesService.getHtml().subscribe(data => {
      for (const rs of data) {
        this.htmlenc=rs.COM_HTMLENCA;
        this.htmlcue=rs.COM_HTMLCUERP;
        this.nombrep=rs.COMNOMBREP;
      }      
    });
  }
  async getdatoscorreo(){
    try {
      const data=await this.nomenviorolesService.getcamposcorreos('001','frmNOM_ENVIOROLES');
    const data2 = await this.nomenviorolesService.consultreporte(data[0].ENCENVIOCOR_REPCODIGO);
    if(data !== null && data !== undefined){
      this.encenviocorreo=data
    }
    if(data2[0] !== null && data2[0] !== undefined){
      this.reporte=data2[0].RL_ARCHIVO
    }else{
      this.reporte='';
    }
    this.encenviocorreo[0].ENCENVIOCOR_CUERPO1= this.encenviocorreo[0].ENCENVIOCOR_CUERPO1 === null ||  this.encenviocorreo[0].ENCENVIOCOR_CUERPO1 === undefined?'': this.encenviocorreo[0].ENCENVIOCOR_CUERPO1;
    this.encenviocorreo[0].ENCENVIOCOR_CUERPO2= this.encenviocorreo[0].ENCENVIOCOR_CUERPO2 === null ||  this.encenviocorreo[0].ENCENVIOCOR_CUERPO2 === undefined?'': this.encenviocorreo[0].ENCENVIOCOR_CUERPO2;
    this.encenviocorreo[0].ENCENVIOCOR_PIECORREO= this.encenviocorreo[0].ENCENVIOCOR_PIECORREO === null ||  this.encenviocorreo[0].ENCENVIOCOR_PIECORREO === undefined?'': this.encenviocorreo[0].ENCENVIOCOR_PIECORREO;   
    this.encenviocorreo[0].ENCENVIOCOR_LOGO=this.encenviocorreo[0].ENCENVIOCOR_LOGO === 1?true:false;
    console.log(this.encenviocorreo)
    } catch (error) {
      console.log(error)
    }
    
  }
  // consultarRoles(){
  //   if( this.radioButton === 'val1' ){
  //     this.getRoles('1');
  //   } else if ( this.radioButton === 'val2' ){
  //     this.getRoles('2');
  //   }
  // }

  async getRoles(){
    await this.nomenviorolesService.getRoles( this.convertDate(this.fechaIni), this.convertDate( this.fechaFin ) ).then( r => {
  
      //this.nomProcContas = [...r]
      this.rolesPago = r;
      if( this.rolesPago !== null ) { 
        for (const roles  of this.rolesPago){
          roles.PERIODO_FECINI = this.convertDate(roles.PERIODO_FECINI);
          roles.PERIODO_FECFIN = this.convertDate(roles.PERIODO_FECFIN);
          roles.EMP_SUELDO=roles.EMP_SUELDO === null || roles.EMP_SUELDO === undefined || roles.EMP_SUELDO === ''?Number(Number(0).toFixed(2)):roles.EMP_SUELDO;
          roles.EMP_SUELDO=Number(roles.EMP_SUELDO).toFixed(this.confIniciales.getNumDecSist());
          roles.EMVIAR_CORREO=false
        }
        this.aggrid.refreshaggrid(this.rolesPago, this.defaultColDefRolPagos.objeto);
      }
    }).catch( e => {
      console.error(e);
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    } );
  }


  // async setTipoRol(){
  //   for(let rolConta of this.rolesPago ) {
  //     await this.contabSrv.getTipoRol(rolConta.TIPROL_CODIGO).then( res => {

  //       if( res !==  null ){
  //         if( res.length > 0 ){
  //           rolConta.nomRol = res[0].TIPROL_NOMBRE;
  //           rolConta.fecha = this.convertDate(rolConta.PERIODO_FECFIN);
  //         }
  //       }
        
        
  //       this.aggrid.refreshaggrid(this.rolesPago, this.defaultColDefRolPagos.objeto);
  //     } ).catch( e => {
  //       console.error(e);
  //       let mensaje: string;
  //       mensaje = this.errorService.generarMensajeError(e.error);
  //       this.mensajeAlerta('error', 'Error', mensaje);
  //     } );
  //   }
    
  // }

  convertDate(date: string) {
    const fecha = new Date( date );

    let month = '' + (fecha.getMonth() + 1);
    let day = '' + fecha.getDate();
    let year = fecha.getFullYear();

    if( month.length < 2 ) { month = '0' + month }
    if( day.length < 2 ) { day = '0' + day }

    return `${day}/${month}/${year}`;
  }

  selectTodos(event){
    if( event.checked ){
      this.rolesPago.map( rol => {
        rol.EMVIAR_CORREO = true;
      } )
    } else {
      this.rolesPago.map( rol => {
        rol.EMVIAR_CORREO = false;
      } )
    }
    this.aggrid.refreshaggrid(this.rolesPago, this.defaultColDefRolPagos.objeto);
  }

  manejarSenales(valor){
    switch (valor) {
      case 'Salir':
       this.init.tabs.splice(this.init.tabindex, 1);
      break;
      case 'EnviarCorreo':
        this.spin=true;
        this.enviarRoles();
      break;
      default:
      break;
    }
  }

//  async  seleccionarConta(){
//    let huboError = false;
//     for( let  rol of  this.rolesPago)  {
//       if (rol.contabilizar){
//         if( await this.contabilizar(rol) ) {

//           if( await !this.setAsientoNulo(rol)){
//             huboError = true;
//           }
//         }else {
//           huboError= true;
//         }
//       }
      
//     }   
//     if( !huboError ){
//       this.mensajeAlerta('success', 'Éxito', `Proceso exitoso`);
//     }
//   }
async enviarRoles(){
  if( this.rolesPago !== null ) { 
    let anio
    if(this.seleccionRol() === true){
      let cuerpo01='';
      let cuerpo02='';
      let piecorreo='';
      
      const base64Data=this.comlogo.split(',')[1];
      const byteArray=Uint8Array.from(atob(base64Data),c=>c.charCodeAt(0));
      const blob =new Blob([byteArray],{type:'image/png'})
      const fileURL=URL.createObjectURL(blob);
      for (const rol of this.rolesPago){
       if(rol.EMVIAR_CORREO === true){
         try {
          await this.nomenviorolesService.generarRol(rol.PERIODO_CODIGO,rol.TIPROL_CODIGO,rol.EMP_CODIGO).catch(error=>{
            this.mensajeAlerta('error', 'Error', 'Hubo un error al Ejecutar el proceso');
            this.spin=false;
            return;
          });
          const data=await this.nomenviorolesService.generarcorreo(this.encenviocorreo[0],rol).catch(error=>{
            this.mensajeAlerta('error', 'Error', 'Hubo un error al cargar los datos del correo');
            this.spin=false;
            return;
          }); 
          data[0].CUERPO01=data[0].CUERPO01 === null || data[0].CUERPO01 === undefined?'':data[0].CUERPO01;
          data[0].CUERPO02=data[0].CUERPO02 === null || data[0].CUERPO02 === undefined?'':data[0].CUERPO02;
          data[0].PIECORREO = data[0].PIECORREO === null || data[0].PIECORREO === undefined?'':data[0].PIECORREO;
          cuerpo01 = data[0].CUERPO01.replaceAll('\n','<br>');
          cuerpo02 = data[0].CUERPO02.replaceAll('\n','<br>');
          piecorreo = data[0].PIECORREO.replaceAll('\n','<br>');
          const resp=await this.nomenviorolesService.enviarCorreo(data[0].ASUNTO,data[0].REMITENTE,cuerpo01,cuerpo02,piecorreo,rol.EMPMAIL,data[0].NOMARCHIVO,this.reporte,this.encenviocorreo[0].ENCENVIOCOR_LOGO).catch(error=>{
            this.mensajeAlerta('error', 'Error', 'Hubo un error al enviar el correo a '+rol.EMP_NOMBRE);
            this.spin=false;        
          });
          if(resp === true){
            this.mensajeAlerta('success', 'Información', 'Éxito al enviar el correo a '+rol.EMP_NOMBRE);
          }else{
            this.mensajeAlerta('error', 'Error', 'Hubo un error al enviar el correo a '+rol.EMP_NOMBRE);
            this.spin=false;
            return;
          }
         } catch (error) {
           console.log(error)
          this.mensajeAlerta('error', 'Error', 'Hubo un error al Generar el reporte de '+ rol.EMP_NOMBRE);
         
           this.spin=false;
           return;
         }
        
       }
      }
      this.spin=false;
    }else{
      this.mensajeAlerta('error', 'Error', 'Seleccione un rol para enviar.');
      this.spin=false;
    }
  }else{
    this.mensajeAlerta('error', 'Error', 'El detalle para el envío de roles está vacío.');
    this.spin=false;
  }

}
seleccionRol(){
  let selectbool=false
  for (const rol of this.rolesPago){
    if(rol.EMVIAR_CORREO === true){
      return selectbool=true;
    }
  }
  return selectbool;
}




  // async contabilizar(rol: NomProcContab){
  //   return await this.contabSrv.contabilizar(rol.PERIODO_CODIGO, rol.TIPROL_CODIGO).then( async () => {
  //      await this.auditoriagral.registrarAuditoria('NOM_ENCROLINDI', `CONTABILIZACION PERIODO: ${rol.PERIODO_CODIGO}`, 'A',
  //     this.usuario.identificacion, '01', '', '', '', '');
  //     return true;
  //   } ).catch( e => {
  //     console.error(e.error);
  //     let mensaje: string;
  //     mensaje = e.error;
  //     try{
  //       mensaje = mensaje.split("ORA-06512")[0];
  //     }catch{
  //       mensaje = 'Hubo un problema contabilizando los roles'
  //     }
  //     this.mensajeAlerta('error', 'Error', mensaje);
  //     return false;
  //   } )
  // }

  // async setAsientoNulo(rol: NomProcContab){
  //   return await this.contabSrv.buscarAsiento(rol.PERIODO_CODIGO, rol.TIPROL_CODIGO).then( res => {
     
  //     let rolAsi = this.rolesPago.find( rolp => (rol.PERIODO_CODIGO === rolp.PERIODO_CODIGO && rol.TIPROL_CODIGO === rolp.TIPROL_CODIGO )  );
  //     if( rolAsi ){
  //       rolAsi.ASI_NRO = res[0].ASI_NRO;
  //     }
  //     this.aggrid.refreshaggrid(this.rolesPago, this.defaultColDefRolPagos.objeto);
  //     return true;
  //   } ). catch( e => {
  //     console.error(e);
      
  //     this.mensajeAlerta('error', 'Error', 'Hubo un problema buscando el asiento');
  //     return false;
  //   } )
  // }

  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'envioRol',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
  }

}
