//
// SACI WEB
// Rutina: Devolución de Facturas
// Propietario: Wilson Ramos
// Modificado por: WR
// Fecha de creación: 26-02-2020
// Fecha de Modificación: 04-03-2020
//

import {Component, OnInit, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {Cxctmptrnresumen} from '../../ven/veninterfaces/cxctmptrnresumen';
import {Cxctrnresumen} from '../../ven/veninterfaces/cxctrnresumen';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {DatePipe} from '@angular/common';
import {UtilitariosService} from '../../advantage/advantageservices/utilitarios.service';
import {VenmaeclienteService} from '../../ven/venservicios/venmaecliente.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {Usuario} from '../../usuario';
import {NumeracionService} from '../../advantage/advantageservices/numeracion.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {ComencdevfacService} from '../comservicios/comencdevfac.service';
import {ComencfacproService} from '../comservicios/comencfacpro.service';
import {ComnotrecService} from '../comservicios/comnotrec.service';
import {Comdetdevfac} from '../cominterfaces/comdetdevfac';
import {Comencdevfac} from '../cominterfaces/comencdevfac';
import {CommaeproveedorService} from '../comservicios/commaeproveedor.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {ComExistenciaBodegas, ComExistencias, ComMovimientoProv, ComTopProductos} from '../cominterfaces/comestadisticas';
import {ComestadisticasService} from '../comservicios/comestadisticas.service';
import { Comencliquicombasesiva } from "../cominterfaces/comencliquicombasesiva";
import { NumeracionComponent } from 'src/app/advantage/numeracion/numeracion.component';

@Component({
  selector: 'app-comencdevfac',
  templateUrl: './comencdevfac.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class ComencdevfacComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  displayDialogDetFac: boolean;
  displayDialogPedidos: boolean;
  displayDialogAsiento: boolean;
  displayDialogSeriales: boolean;
  displayAcciones: boolean;
  displayDialogDescuento: boolean;
  displayDialogPago: boolean;
  displayAsiContable: boolean;
  displayDialogReportes: boolean;
  displayDialogLotes: boolean;
  auxreporte: number = 0;
  auxiliarlot: number = 0;
  strNombreForma: string;
  auxiliar: number;
  detalleDVFSeleccionado: Comdetdevfac;
  detalleDVFSeleccionadoAux: Comdetdevfac;
  claveExportNuevo: string;
  tipodetalleactivo: string;
  listaSaldos: any;
  auxContEC: number;
  debeTotal: number;
  totalfaconr: number;//GSRF
  haberTotal: number;
  totalimporte: number;
  totalpagado: number;
  totalsaldo: number;
  botonFacNR: string;
  grupo1: string;
  where: string;
  displayLog: boolean;
  valorInvalidoV: boolean;
  valorInvalidoP: boolean;
  numeracionDialog: boolean;
  editarCeldas: boolean;
  barraBotones2: boolean;
  btnNuevo: boolean;
  booleanValor: boolean;
  booleanDatosPantalla: boolean;
  booleanPorcentaje: boolean;
  booleanRegimen: boolean;
  booleanParaiso: boolean;
  booleanRegimenP: boolean;
  booleanRefrendo: boolean;
  booleanImpuestoRenta: boolean;
  totalApagar: number;
  consulta: string;
  nombrePaisSeleccionado: string;
  codigoPaisRegimen: string;
  codigoPaisParaiso: string;
  dscSeleccionado: string;
  valorDscValor: number;
  valorDscPorcentaje: number;
  btnGuardar: boolean;
  btnRegresar: boolean;
  btnBorrar: boolean;
  botonAnular: boolean;
  botonXML: boolean;
  botonAsiento: boolean;
  botonPago: boolean;
  botonBuscar: boolean;
  botonVerAsiento: boolean;
  asiento: string;
  numdecsist: number;
  dscClienteActual: number;
  botonAnterior: boolean;
  botonPrimero: boolean;
  botonUltimo: boolean;
  numeroActual: number;
  claveAuth: string;
  saldoCliente: string;
  nombreProveedor: string;
  permisosDVF: SegMaePermiso;
  logResultado: string;
  numeracionActual: any;
  decimalesCantidadDVF: number;
  decimalesPrecioDVF: number;
  decimalesTotalDVF: number;
  botonSiguiente: boolean;
  btnNuevoGrid: boolean;
  btnGuardarGrid: boolean;
  btnBorrarGrid: boolean;
  btnCancelarGrid: boolean;
  btnVerificarExis: boolean;
  arregloCons: any[];
  displayDialogBusquedaFast: boolean;
  displayDialogBus: boolean;
  claveDialog: boolean;
  accionClave: string;
  cobroCodigo: string;
  opcion: string;
  numDocRef: string;
  imagenProducto: string;
  busquedacampos: any[];
  arregloBus: any[];
  types: SelectItem[];
  largo: string;
  largoRef: string;
  indice: number;
  i: number;
  tabla: string;
  estadoRef: string;
  largoestcta: string;
  cotizacionBoolean: boolean;
  fechaEmision: any;
  fechaInicio: any;
  fechaFin: any;
  horaSistema: any;
  horaInicio: any;
  horaFin: any;
  porcIvaBoolean: boolean;
  opcionIva: SelectItem[];
  opcionIvaDesc: SelectItem[];
  opcionesBodega: SelectItem[];
  opcionesRegimen: SelectItem[];
  opcionesExportacion: SelectItem[];
  opcionesDistrito: SelectItem[];
  opcionesRegRefrendo: SelectItem[];
  opcionesIngresoExterior: SelectItem[];
  opcionesTipoIngresoExterior: SelectItem[];
  choosedColorG: string;
  choosedColorSB: string;
  ventasboolean: boolean;
  comprasboolean: boolean;
  consigboolean: boolean;
  trasladoboolean: boolean;
  devboolean: boolean;
  impboolean: boolean;
  expboolean: boolean;
  otrosboolean: boolean;
  transboolean: boolean;
  bolestcta: boolean;
  bolsaldo: boolean;
  activeIndex: number;
  nombrebodega: string;
  opcionesprecio: SelectItem[];
  listaprecios: any[];
  cxctmptrnresumen: Cxctmptrnresumen[];
  cxctrnresumen: Cxctrnresumen[];
  public frameworkComponents;
  encabezadosDevF: Comencdevfac[];
  rowStyle;
  busquedaCompuesta: boolean;
  defaultColDefDevolucion;
  rowSelection = 'multiple';
  tipodetalle = '';
  comboOpciones: any[];
  indicador: any;
  defaultColDefCxctmptrnresumen;
  defaultColDefCxctrnresumen;
  spin=false;

  // ESTADISTICAS
  detTopProductos: ComTopProductos[] = [];
  dataComprasProveedor: any;
  dataVentasVsCompras: any;
  disabledEstadisticas = true;
  options = {
    legend: {
      display: false
    }
  };
  defaultColTopProductos;
  defaultColMovByProv;
  defaultColExistBod;
  rowStyleTopProd;
  detalleExistencia: ComExistencias[] = [];
  detMovimientosProv: ComMovimientoProv[] = [];
  detExistenciaBodegas: ComExistenciaBodegas[] = [];
  txtArtCodigo = '';
  txtArtDescripcion = '';
  txtArtUnidad = '';
  txtArtRotacion = '';
  txtExistenciaMin: any = '';
  txtExistenciaMax: any = '';
  txtInventarioIni: any = '';
  txtComprasPeriodo: any = '';
  txtTotalDisponible: any = '';
  txtVenUltimoPer: any = '';
  txtVenPromPerAnt: any = '';
  txtIndiceProvUltPer: any = '';
  txtIndiceProvPeriodos: any = '';
  txtComprasPlanificadas: any = '';
  txtTotalDisponibleCom: any = '';
  txtIndiceProvUltPer2: any = '';
  txtIndiceProvPeriodos2: any = '';
  displayDialogDocumentos = false;
  basesiva:Comencliquicombasesiva[]=[];
  displaybasesiva:boolean=false;
  defaultColBasesIva;
  iva:number
  columnDefsCxctmptrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC', width: 100,
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Debe', field: 'DEBE', cellStyle: {textAlign: 'right'},
    },
    {
      headerName: 'Haber', field: 'HABER', cellStyle: {textAlign: 'right'},
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: {textAlign: 'right'},
    },
  ];
  
  columnDefsCxctrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC'
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Importe', field: 'TRNCOBRO_IMPORTE', cellStyle: {textAlign: 'right'},
    },
    {
      headerName: 'Pagado', field: 'PAGADO', cellStyle: {textAlign: 'right'},
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: {textAlign: 'right'},
    },
  ];
  
  columnDefsDVFDetalle = [
    { // 0
      headerName: 'Bodega', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 50,
            opcionbuscar: true
          }
        }
    },
    { // 1
      headerName: 'Código', field: 'DETDEV_CODIGO', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 20,
            opcionbuscar: true
          }
        }
    },
    { // 2
      headerName: 'Descripción', field: 'DETDEV_DESCRIPCION', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 100
          }
        }
    },
    { // 3
      headerName: 'IVA', field: 'DETDEV_PORIVA', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          int: true,
          opcionbuscar: true
          }
        }
    },
    { // 4
      headerName: 'ICE', field: 'DETDEV_TRIBICE', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            regexp: /^[S]$|^[N]$/,
            tamanocelda: 1,
            tienecalculos: true
          }
        }
    },
    { // 5
      headerName: 'Unidad', field: 'UNI_CODIGO', cellEditor: 'agSelectCellEditor', editable: true, width: 100,
      cellEditorParams:
        {
          values: []
        }
    },
    { // 6
      headerName: 'Cantidad', field: 'DETDEV_CANTIDAD', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right'},
      onCellClicked: (params) => {
        this.abrirLotes(params.data);
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        }
    },
    { // 7
      headerName: 'Costo', field: 'DETDEV_COSTO', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right'},
      editable: (p) => {
        return p.data.COSTOBLOQ !== true;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        }
    },
    {
      headerName: 'Desc. ($)', field: 'DETDEV_VALDES', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: true,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        }
    },
    {
      headerName: 'Desc. (%)', field: 'DETDEV_PORDES', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: true,
            numerodecimales: 3,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        }
    },
    {
      headerName: 'Dsct2(%)', field: 'DETDEV_PORDES2', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: true,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 3,
            tienecalculos: true,
            int: true
          }
        }
    },
    {
      headerName: 'Dsct3(%)', field: 'DETDEV_PORDES3', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: true,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 3,
            tienecalculos: true,
            int: true
          }
        }
    },
    {
      headerName: 'Total', field: 'DETDEV_TOTAL', cellEditor: 'cellPrueba', width: 150, cellStyle: {textAlign: 'right'}, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            int: true
          }
        }
    },
    {
      headerName: 'C.Costos', field: 'CEN_CODIGO', editable: true, width: 80, 
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 20,
          int: true
        }
      }
    },
    {
      headerName: 'Cant.Und', field: 'DETDEV_CANTIDADUND', editable: false, width: 80
    },
    {
      headerName: 'Tipo Gast.', field: 'TIPGAS_CODIGO', editable: true, width: 80,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 20,
            int: true
          }
        }
    }];
  
  columnDefsTopProductos = [
    {
      headerName: 'Producto', field: 'DETFACPRO_CODIGO', width: 115, editable: false
    },
    {
      headerName: 'Descripción', field: 'DETFACPRO_DESCRIPCION', width: 190, editable: false
    },
    {
      headerName: 'Cantidad', field: 'CANTIDAD_TOTAL', width: 90, cellStyle: {textAlign: 'right'}, editable: false
    },
    {
      headerName: 'Precio', field: 'DETFACPRO_COSTO', width: 90, cellStyle: {textAlign: 'right'}, editable: false
    },
    {
      headerName: 'Total', field: 'TOTAL', width: 90, cellStyle: {textAlign: 'right'}, editable: false
    },
    {
      headerName: 'Stock', field: 'STOCK', width: 90, cellStyle: {textAlign: 'right'}, editable: false
    }
  ];
  
  columnDefsMovimientosByProv = [
    {
      headerName: 'Proveedor', field: 'PRO_CODIGO', width: 115, editable: false
    },
    {
      headerName: 'Nombre', field: 'PRO_NOMBRE', width: 190, editable: false
    },
    {
      headerName: 'Fecha Compra', field: 'ENCFACPRO_FECHAEMISION', width: 90, cellStyle: {textAlign: 'right'}, editable: false
    },
    {
      headerName: 'Costo', field: 'DETFACPRO_COSTO', width: 90, cellStyle: {textAlign: 'right'}, editable: false
    }
  ];
  
  columnDefsExistenciaBodegas = [
    {
      headerName: 'Código', field: 'BOD_CODIGO', width: 115, editable: false
    },
    {
      headerName: 'Nombre', field: 'BOD_NOMBRE', width: 190, editable: false
    },
    {
      headerName: 'Existencia', field: 'EXISTENCIA', width: 90, cellStyle: {textAlign: 'right'}, editable: false
    },
    {
      headerName: 'Pedidos', field: 'PEDIDO', width: 90, cellStyle: {textAlign: 'right'}, editable: false
    },
    {
      headerName: 'Saldo', field: 'SALDO', width: 90, cellStyle: {textAlign: 'right'}, editable: false
    }
  ];
  //CDPJ
  columnDefsBasesIva = [
    {
      headerName: '%IVA', field: 'TARIIVA_DESCRIPCION', width: 115, editable: false
    },
    {
      headerName: 'Base', field: 'IVA_BASE', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Valor', field: 'IVA_VALOR', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];
  //CDPJ
  constructor(public comDevFacService: ComencdevfacService, private confirmationService: ConfirmationService,
              private busquedaSer: InvbusquedaService, private datePipe: DatePipe,
              private message: MessageService, private utilitariosService: UtilitariosService,
              private cliService: VenmaeclienteService, public usuario: Usuario,
              private auditoriagral: AuditoriagralService, private errorService: ErroresBaseDatosService,
              private confIniciales: ConfInicialesService, private permisosService: PermisosService,
              private numeracionService: NumeracionService, private comencfacService: ComencfacproService,
              private comencnotrecService: ComnotrecService, private proveedorServicio: CommaeproveedorService,
              private init: NuevoComponentService, private venEstadisticasService: ComestadisticasService) {
    this.botonFacNR = '';
    this.agTable();
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.comDevFacService.detdevFacarray[this.indicador] = [];
    this.comDevFacService.encDevSeleccionadaarray[this.indicador] = {};
    this.encabezadosDevF = [];
    this.detalleDVFSeleccionado = {};
    this.detalleDVFSeleccionadoAux = {};
    this.opcionesprecio = [];
    this.listaprecios = [];
    this.opcionesBodega = [];
    this.opcionesRegimen = [];
    this.opcionesExportacion = [];
    this.opcionesDistrito = [];
    this.opcionesRegRefrendo = [];
    this.opcionesIngresoExterior = [];
    this.opcionesTipoIngresoExterior = [];
    this.usuario.loadStorage();
    this.cxctrnresumen = [];
    this.cxctmptrnresumen = [];
    this.listaSaldos = [];
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.rowStyle = (params) => {
      if (params.data.DETDEV_TIPODET === 'Y') {
        return {background: 'rgb(255, 204, 255)'};
      }
      if (params.data.TRNCOBRO_FECHATRN === '') {
        return {background: '#AAFF00'};
      }
    };
    this.rowStyleTopProd = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
      if (params.data.DETFACPRO_CODIGO === '' || params.data.DETFACPRO_CODIGO === null) {
        return {background: 'rgb( 164, 255, 167)'};
      }
    };
    this.defaultColDefDevolucion = {
      editable: true,
      width: 100,
      objeto: 'comdetdev'
    };
    this.defaultColDefCxctmptrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctmptrnresumen'
    };
    
    this.defaultColDefCxctrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctrnresumen'
    };
    
    this.defaultColTopProductos = {
      editable: true,
      width: 100,
      objeto: 'topProductos',
      resizable: true
    };
    
    this.defaultColMovByProv = {
      editable: true,
      width: 100,
      objeto: 'movproveedores',
      resizable: true
    };
    
    this.defaultColExistBod = {
      editable: true,
      width: 100,
      objeto: 'existenciabodegas',
      resizable: true
    };
    this.defaultColBasesIva = {
      editable: false,
      width: 100,
      objeto: 'basesiva',
    };
  }
  
 async ngOnInit() {
    this.displayAcciones = false;
    this.displayDialogDescuento = false;
    this.booleanValor = true;
    this.auxiliar = 0;
    this.booleanPorcentaje = false;
    this.bolestcta = false;
    this.bolsaldo = false;
    this.spin=false;//CDPJ
    this.confIniciales.getDecimales('frmCOM_ENCDEVOLUCION').subscribe((dec) => {
      if (dec !== null) {
        dec.map((decimales) => {
          this.decimalesCantidadDVF = 2;
          this.comDevFacService.decimalesCantidadDevFC = this.decimalesCantidadDVF;
          if (decimales.NUMDEC_CAMPO === 'COSTO') {
            this.decimalesPrecioDVF = Number(decimales.NUMDEC_NUMDEC);
            this.comDevFacService.decimalesPrecioDevFC = this.decimalesPrecioDVF;
          } else if (decimales.NUMDEC_CAMPO === 'COSTOTOTAL') {
            this.decimalesTotalDVF = Number(decimales.NUMDEC_NUMDEC);
            this.comDevFacService.decimalesTotalDevFC = this.decimalesTotalDVF;
          }
        });
        this.columnDefsDVFDetalle[7].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales:  this.comDevFacService.decimalesPrecioDevFC,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        };
        //GSRF
        this.columnDefsDVFDetalle[8].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales:  this.comDevFacService.decimalesPrecioDevFC,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        };
        this.columnDefsDVFDetalle[9].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales:  3,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        };
        //GSRF
        this.columnDefsDVFDetalle[12].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales:  this.comDevFacService.decimalesTotalDevFC,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            int: true
          }
        };
      }
      this.aggrid.refreshColumnDefs();
    });
    this.auditoriagral.obtenerConfiguracion1().subscribe((conf) => {
      this.comDevFacService.hmpConfiguracion = conf;
    });
    this.permisosService.getListaPermisos('COM', '6', '772').subscribe((permiso) => {
      this.permisosDVF = permiso[0];
    });
    this.numDocRef = '';
    this.asiento = '';
    this.consulta = '';
    this.accionClave = '';
    this.booleanDatosPantalla = true;
    this.largoestcta = '420';
    this.nombrePaisSeleccionado = '';
    this.tipodetalleactivo = 'all';
    this.dscSeleccionado = 'dscPorcentaje';
    this.claveExportNuevo = '';
    this.codigoPaisRegimen = '';
    this.codigoPaisParaiso = '';
    this.where = '';
    this.auxContEC = 0;
    this.debeTotal = 0;
    this.haberTotal = 0;
    this.totalimporte = 0;
    this.totalpagado = 0;
    this.totalsaldo = 0;
    this.cobroCodigo = '';
    this.valorDscValor = 0;
    this.busquedaCompuesta = false;
    this.valorDscPorcentaje = 0;
    this.porcIvaBoolean = true;
    this.booleanRegimen = true;
    this.booleanParaiso = true;
    this.booleanRegimenP = true;
    this.booleanRefrendo = true;
    this.numeracionActual = {};
    this.dscClienteActual = 0;
    this.totalApagar = 0;
    this.displayDialogDetFac = false;
    this.displayDialogPedidos = false;
    this.displayDialogAsiento = false;
    this.displayDialogSeriales = false;
    this.displayAsiContable = false;
    this.editarCeldas = false;
    this.displayDialogPago = false;
    this.claveDialog = false;
    this.displayLog = false;
    this.valorInvalidoP = false;
    this.valorInvalidoV = false;
    this.numeracionDialog = false;
    this.logResultado = '';
    this.saldoCliente = '';
    this.estadoRef = '';
    this.nombreProveedor = '';
    this.valorDscValor = 0;
    this.valorDscPorcentaje = 0;
    this.choosedColorG = '#00FF00';
    this.choosedColorSB = '#FF80F0';
    this.claveAuth = '';
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.botonPrimero = false;
    this.botonAnterior = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
    this.botonAnular = false;
    this.botonXML = false;
    this.botonAsiento = false;
    this.botonVerAsiento = false;
    this.botonBuscar = false;
    this.botonPago = false;
    this.cotizacionBoolean = false;
    this.booleanImpuestoRenta = true;
    this.activeIndex = 0;
    this.ventasboolean = false;
    this.imagenProducto = '';
    this.comprasboolean = false;
    this.consigboolean = false;
    this.trasladoboolean = false;
    this.nombrebodega = '';
    this.devboolean = false;
    this.impboolean = false;
    this.expboolean = false;
    this.otrosboolean = false;
    this.transboolean = false;
    this.displayDialogBus = false;
    this.numeroActual = 0;
    this.numdecsist = this.confIniciales.getNumDecSist();
    this.displayDialogBusquedaFast = false;
    this.comboOpciones = [{label: 'A', value: 'A'},
      {label: 'S', value: 'S'}, {label: 'C', value: 'C'}, {label: '*', value: '*'}];
    this.tipodetalle = 'A';
    this.opcionIva = [{label: '12', value: 12}, {label: '14', value: 14}];
    this.opcionIvaDesc = [{label: 'Neto + IVA', value: '1'}, {label: 'Exento', value: '0'}];
    this.comDevFacService.dblPorcIvaDevF = 12;
    this.fechaEmision = new Date().toLocaleString().substr(0, 10);
    this.fechaInicio = new Date().toLocaleString().substr(0, 10);
    this.fechaFin = new Date().toLocaleString().substr(0, 10);
    this.horaInicio = new Date();
    this.horaFin = new Date();
    this.horaSistema = new Date();
    this.comDevFacService.getListaPrecios().subscribe((res) => {
      this.listaprecios = res;
      this.listaprecios.map((precio) => {
        let nuevoItem: any;
        nuevoItem = {label: precio.ARTPRE_CODIGO, value: precio.ARTPRE_CODIGO};
        this.opcionesprecio.push(nuevoItem);
      });
    });
    this.largo = '-5800';
    this.largoRef = '400';
    this.indice = 0;
    this.i = 0;
    this.grupo1 = 'fac';
    this.comDevFacService.getEncDevF().subscribe((res) => {
      this.encabezadosDevF = res;
    });
    this.iva = await this.confIniciales.obtenerImpuesto('I', 'A');
    this.comDevFacService.porceiva=await this.confIniciales.obtenerImpuesto('I', 'A');
    await this.confIniciales.obtenerSrifeTariIva()
    this.comDevFacService.tariivacod=this.confIniciales.srifetariiva.find(element => element.TARIIVA_PORCENTAJE === Number(this.comDevFacService.porceiva)).TARIIVA_CODIGO
  }
  //CDPJ
  ngOnDestroy(): void {
    this.confIniciales.instanciasDevFacComp--;
  }
//CDPJ

  async manejarSenales(valor) {
    if (valor !== 'Nuevo' && valor !== 'Cancelar'
      && valor !== 'Primero' && valor !== 'Anterior' && valor !== 'Siguiente' && valor !== 'Ultimo'
      && valor !== 'Buscar' && valor !== 'Imprimir' && valor !== 'AsientoVer' && valor !== 'Salir') {
      // Verifica el periodo contable

      let dia = new Date().getDate();
      let mes = new Date().getMonth();
      let anio = new Date().getFullYear();
      try {
        // Verifica el periodo contable
        dia = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION === undefined ? dia : this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION.getDate();
        mes = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION === undefined ? mes : this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION.getMonth();
        anio = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION === undefined ? anio : this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION.getFullYear();
      } catch {
        const tmpfecha = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION.substr(0, 10);
        console.log(tmpfecha);
        const splfec = tmpfecha.split('/');
        const fecha = new Date(splfec[2], splfec[1] - 1, splfec[0]);
        dia = fecha.getDate();
        mes = fecha.getMonth();
        anio = fecha.getFullYear();
      }

      if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'Error, fecha debe de estar dentro del período contable'
        });
        return;
      }
    }  

    if (this.activeIndex === 3) {
      return;
    }
    //CDPJ
    let strCodigo=0
    let srtArtCntaSer=0;
    const detalle = await this.comDevFacService.verificarDetalleDevFacpro(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO);
    const detalle2 = await this.comDevFacService.verificarDetalleDevFacproArtCntaSer(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO);
    if (detalle !== null && detalle !== undefined) {
      for (const rs of detalle) {
        strCodigo = rs.NUMERO;
      }
    }
    if (detalle2 !== null && detalle2 !== undefined) {
      for (const rs of detalle2) {
        srtArtCntaSer = rs.NUMERO;
      }
    }
    //CDPJ
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 0; i < elemento.length; i++) {
      elemento[i].className = 'layout-config';
    }
    if (valor === 'Primero') {
      //CDPJ
      if (this.comDevFacService.detdevFacarray[this.indicador].length === 0 
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== ''
        ) {
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'Ingrese información en el detalle de la Devolución'
        });
        return;
      }
      if(strCodigo === 0  
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== ''){
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'La Devolución no tiene guardado el detalle'
        });
        return;
      }
      if(srtArtCntaSer === 0  
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== ''){
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'La Devolución debe tener registrado al menos un artículo o una cuenta o un servicio.'
        });
        return;
      }
      //CDPJ
      this.indice = 0;
      if (this.botonUltimo === true) {
        this.botonUltimo = !this.botonUltimo;
      }
      if (this.botonSiguiente === true) {
        this.botonSiguiente = !this.botonSiguiente;
      }
      this.botonPrimero = true;
      this.botonAnterior = true;
      this.comDevFacService.encDevSeleccionadaarray[this.indicador] = this.encabezadosDevF[this.indice];
      this.obtenerDatos();
    } else if (valor === 'Siguiente') {
      //CDPJ
      if (this.comDevFacService.detdevFacarray[this.indicador].length === 0 
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== ''
        ) {
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'Ingrese información en el detalle de la Devolución'
        });
        return;
      }
      if(strCodigo === 0  
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== ''){
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'La Devolución no tiene guardado el detalle'
        });
        return;
      }
      if(srtArtCntaSer === 0  
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== ''){
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'La Devolución debe tener registrado al menos un artículo o una cuenta o un servicio.'
        });
        return;
      }
      //CDPJ
      this.indice++;
      if (this.botonPrimero === true && this.botonAnterior === true) {
        this.botonPrimero = false;
        this.botonAnterior = false;
      }
      console.log('this.indice',this.indice)
      console.log('this.encabezadosDevF.length',this.encabezadosDevF.length)
      if (this.indice >= this.encabezadosDevF.length - 1) {
        this.botonUltimo = true;
        this.botonSiguiente = true;
        console.log('1')
        if (this.indice === this.encabezadosDevF.length - 1) {
          this.comDevFacService.encDevSeleccionadaarray[this.indicador] = this.encabezadosDevF[this.indice];
          this.obtenerDatos();
          console.log('2')
        } else if (this.indice !== this.encabezadosDevF.length - 1) {
          console.log('3')
          return;
        }
      } else if (this.indice < this.encabezadosDevF.length - 1) {
        this.comDevFacService.encDevSeleccionadaarray[this.indicador] = this.encabezadosDevF[this.indice];
        console.log('4')
        this.obtenerDatos();
      }
    } else if (valor === 'Anterior') {
      //CDPJ
      if (this.comDevFacService.detdevFacarray[this.indicador].length === 0 
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== ''
        ) {
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'Ingrese información en el detalle de la Devolución'
        });
        return;
      }
      if(strCodigo === 0  
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== ''){
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'La Devolución no tiene guardado el detalle'
        });
        return;
      }
      if(srtArtCntaSer === 0  
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== ''){
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'La Devolución debe tener registrado al menos un artículo o una cuenta o un servicio.'
        });
        return;
      }
      //CDPJ
      if (this.indice === 0) {
        return;
      }
      this.indice--;
      this.comDevFacService.encDevSeleccionadaarray[this.indicador] = this.encabezadosDevF[this.indice];
      if (this.botonUltimo === true && this.botonSiguiente === true) {
        this.botonUltimo = false;
        this.botonSiguiente = false;
      }
      if (this.indice <= 0) {
        this.botonPrimero = true;
        this.botonAnterior = true;
        if (this.indice === 0) {
          this.obtenerDatos();
        } else if (this.indice !== 0) {
          return;
        }
      } else if (this.indice > 0) {
        this.obtenerDatos();
      }
    } else if (valor === 'Ultimo') {
      //CDPJ
      if (this.comDevFacService.detdevFacarray[this.indicador].length === 0 
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== ''
        ) {
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'Ingrese información en el detalle de la Devolución'
        });
        return;
      }
      if(strCodigo === 0  
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== ''){
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'La Devolución no tiene guardado el detalle'
        });
        return;
      }
      if(srtArtCntaSer === 0  
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== ''){
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'La Devolución debe tener registrado al menos un artículo o una cuenta o un servicio.'
        });
        return;
      }
      //CDPJ
      this.indice = this.encabezadosDevF.length - 1;
      this.comDevFacService.encDevSeleccionadaarray[this.indicador] = this.encabezadosDevF[this.indice];
      if (this.botonPrimero === true) {
        this.botonPrimero = !this.botonPrimero;
      }
      if (this.botonAnterior === true) {
        this.botonAnterior = !this.botonAnterior;
      }
      this.botonSiguiente = true;
      this.botonUltimo = true;
      this.obtenerDatos();
    } else if (valor === 'Nuevo') {
      //CDPJ
      if (this.comDevFacService.detdevFacarray[this.indicador].length === 0 
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== ''
        ) {
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'Ingrese información en el detalle de la Devolución'
        });
        return;
      }
      if(strCodigo === 0  
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== ''){
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'La Devolución no tiene guardado el detalle'
        });
        return;
      }
      if(srtArtCntaSer === 0  
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== ''){
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'La Devolución debe tener registrado al menos un artículo o una cuenta o un servicio.'
        });
        return;
      }
      //CDPJ
      if (this.permisosDVF.PERINSERCION === 1) {
        this.nuevaDVF('N');
      } else if (this.permisosDVF.PERINSERCION === 0) {
        this.message.add({
          key: 'devolucionCom',
          severity: 'error',
          summary: 'Inserción de Devolución',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    } else if (valor === 'Borrar') {
      if (this.permisosDVF.PERELIMACION === 1) {
        this.confirmarDev();
      } else if (this.permisosDVF.PERELIMACION === 0) {
        this.message.add({
          key: 'devolucionCom',
          severity: 'error',
          summary: 'Eliminación de Devolución',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
      }
    } else if (valor === 'Guardar') {
      if (this.permisosDVF.PERACTUALIZACION === 1) {
        this.spin=true;//CDPJ
        //GSRF
        const existefac = await this.comDevFacService.existfacdev(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_REFERENCIA,this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO);
        if (existefac.length !== 0 && this.indice === -1 ) {
          this.message.add({
            key: 'devolucionCom',
            severity: 'error',
            summary: 'Inserción de Devolución',
            detail: 'El número de comprobante ya existe para este proveedor'
          });
          document.getElementById('comprobante2').focus();
          this.spin=false;
          return;
        }
        //GSRF
        const rs = await this.comDevFacService.erBodCodigo1(this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO);
        if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_SERIE.length < 6) {
          this.message.add({
            key: 'devolucionCom',
            severity: 'error',
            summary: 'Inserción de Devolución',
            detail: 'El número de serie ingresado es invalido'
          });
          document.getElementById('comprobante').focus();
          this.spin=false;//CDPJ
          return;
        }
        if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_AUTORIZACION.length === 0) {
          this.message.add({
            key: 'devolucionCom',
            severity: 'error',
            summary: 'Inserción de Devolución',
            detail: 'Ingrese un número de autorización'
          });
          document.getElementById('autorizacion').focus();
          this.spin=false;//CDPJ
          return;
        }
        if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO.length === 0) {
          this.message.add({
            key: 'devolucionCom',
            severity: 'error',
            summary: 'Inserción de Devolución',
            detail: 'Ingrese una bodega válida'
          });
          document.getElementById('bodega').focus();
          this.spin=false;//CDPJ
          return;
        }
        //CDPJ
        if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO.length !== 0) {
          if (rs === null || rs === undefined || rs === ''){
            this.message.add({
              key: 'devolucionCom',
              severity: 'error',
              summary: 'Ingreso Notas de Recepción',
              detail: 'Error, Código de la bodega '+this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO+ ' es incorrecto'
            });
            document.getElementById('bodega').focus();
            this.spin=false;//CDPJ
          return;
          }
        }
        //CDPJ
        if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO.length === 0) {
          this.message.add({
            key: 'devolucionCom',
            severity: 'error',
            summary: 'Inserción de Devolución',
            detail: 'Ingrese una factura o nota de recepción'
          });
          document.getElementById('facturaNota').focus();
          this.spin=false;//CDPJ
          return;
        }
        //GSRF
        console.log('/*/*/',this.totalfaconr.toFixed(2))
        console.log('/*/*/',this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_TOTAL)
        if ( Number(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_TOTAL) > Number(this.totalfaconr.toFixed(2)) ) {
          this.message.add({
            key: 'devolucionCom',
            severity: 'error',
            summary: 'Inserción de Devolución',
            detail: 'EL total es mayor a la factura o nota de recepción atada al documento'
          });
          this.spin=false;//CDPJ
          document.getElementById('facturaNota').focus();
          return;
        }
        //GSRF
        if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO === '' ||
          this.comDevFacService.detdevFacarray[this.indicador].length === 0) {
          this.message.add({
            key: 'devolucionCom',
            severity: 'error',
            summary: 'Inserción de Devolución',
            detail: 'No existe un cliente o el detalle está vacio'
          });
          this.spin=false;//CDPJ
          return;
        } else if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION === '' ||
          this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION === null ||
          this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHACADUCIDAD
          === '' || this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHACADUCIDAD === null) {
          this.message.add({
            key: 'devolucionCom',
            severity: 'error',
            summary: 'Inserción de Devolución',
            detail: 'Ingrese fechas validas para guardar la devolución'
          });
          this.spin=false;//CDPJ
          return;
        } else {
          if (this.comDevFacService.detdevFacarray[this.indicador].length !== 0) {
            let errores = 0;
            await Promise.all(this.comDevFacService.detdevFacarray[this.indicador].map(async (dev) => {
              if (dev.DETDEV_TIPODET === '*' || dev.DETDEV_TIPODET === 'Y') {
                errores += 0;
              } else if (dev.DETDEV_TIPODET !== '*' && dev.DETDEV_TIPODET !== 'Y') {
                let cont=0;
                const rsdata: any = await this.comDevFacService.encontrarArtCntaySer(dev.DETDEV_CODIGO, dev.DETDEV_TIPODET);
                const num= await this.comDevFacService.erCencodigo(dev.CEN_CODIGO);
                const rs1 = await this.comDevFacService.erBodCodigo1(dev.BOD_CODIGO);
                let strCodigo = 0;
              if (rsdata !== null) {
                for (const rs of rsdata) {
                  strCodigo = rs.NUMERO;
                }
              }
              if (num !== null && num !== undefined){
                for( const rs of num){
                  if(rs.NUMERO !== null && rs.NUMERO!== undefined && rs.NUMERO !== ''){
                    cont=rs.NUMERO
                  }
                  
                }
              }
                if (dev.DETDEV_CODIGO === '') {
                  this.comDevFacService.detdevFacarray[this.indicador] = this.comDevFacService.detdevFacarray[this.indicador].filter((val, j) => j !==
                    this.comDevFacService.detdevFacarray[this.indicador].indexOf(dev));
                  this.aggrid.refreshaggrid(this.comDevFacService.detdevFacarray[this.indicador], this.defaultColDefDevolucion.objeto);
                } else if (dev.BOD_CODIGO === '') {
                  this.message.add({
                    key: 'devolucionCom',
                    severity: 'error',
                    summary: 'Inserción de Devolución',
                    detail: 'Ingrese una bodega al detalle de la devolución'
                  });
                  this.spin=false;//CDPJ
                  errores++;
                }else if(dev.BOD_CODIGO !== null && dev.BOD_CODIGO !== '' && dev.BOD_CODIGO!== undefined){
                  if (rs1 === null || rs1 === undefined || rs1 === ''){
                    this.message.add({
                      key: 'devolucionCom',
                      severity: 'error',
                      summary: 'Ingreso Notas de Recepción',
                      detail: 'Error, Código de la bodega '+dev.BOD_CODIGO+ ' es incorrecto'
                    });
                    this.spin=false;//CDPJ
                    errores++;
                    }
                  }  if (Number(dev.DETDEV_CANTIDAD) <= 0) {
                  this.message.add({
                    key: 'devolucionCom',
                    severity: 'error',
                    summary: 'Inserción de Devolución',
                    detail: 'Ingrese una cantidad valida al detalle de la devolución'
                  });
                  this.spin=false;//CDPJ
                  errores++;
                } else if (Number(dev.DETDEV_COSTO) <= 0) {
                  this.message.add({
                    key: 'devolucionCom',
                    severity: 'error',
                    summary: 'Inserción de Devolución',
                    detail: 'Ingrese un precio valido al detalle de la devolución'
                  });
                  this.spin=false;//CDPJ
                  errores++;
                }else if(dev.DETDEV_TIPODET === 'A' && strCodigo === 0){//CDPJ
                  this.message.add({
                    key: 'devolucionCom',
                    severity: 'error',
                    summary: 'Inserción de Devolución',
                    detail: 'El artículo '+ dev.DETDEV_CODIGO +' no existe'
                  });
                  this.spin=false;//CDPJ
                  errores++;
                }else if(dev.DETDEV_TIPODET === 'S' && strCodigo === 0){
                  this.message.add({
                    key: 'devolucionCom',
                    severity: 'error',
                    summary: 'Inserción de Devolución',
                    detail: 'El servicio '+ dev.DETDEV_CODIGO +' no existe'
                  });
                  this.spin=false;//CDPJ
                  errores++;
                }else if(dev.DETDEV_TIPODET === 'C' && strCodigo === 0){
                  this.message.add({
                    key: 'devolucionCom',
                    severity: 'error',
                    summary: 'Inserción de Devolución',
                    detail: 'La cuenta contable '+ dev.DETDEV_CODIGO +' no existe'
                  });
                  this.spin=false;//CDPJ
                  errores++;
                }
                console.log(dev.CEN_CODIGO)
               if(dev.CEN_CODIGO !== null && dev.CEN_CODIGO !== '' && dev.CEN_CODIGO !== undefined){
                  console.log(cont)
                  if (cont === 0){
                    this.message.add({
                      key: 'devolucionCom',
                      severity: 'error',
                      summary: 'Inserción de Devolución',
                      detail: 'Error, Código del centro de costos: '+dev.CEN_CODIGO+ ' es incorrecto'
                    });
                    this.spin=false;//CDPJ
                    errores++;
                    }
                  }
                  //CDPJ
                if(dev.TARIIVA_CODIGO === null || dev.TARIIVA_CODIGO === undefined || dev.TARIIVA_CODIGO === ''){       
                  this.message.add({
                    key: 'devolucionCom',
                    severity: 'warn',
                    summary: 'Error en IVA',
                    detail: 'Falta seleccionar el tipo de porcentaje del código '+dev.DETDEV_CODIGO
                  });
                  this.spin=false;//CDPJ
                   
                  errores++;             
                }
                if(dev.DETDEV_PORIVA === null || dev.DETDEV_PORIVA === undefined || dev.DETDEV_PORIVA === ''){
                  this.message.add({
                    key: 'devolucionCom',
                    severity: 'warn',
                    summary: 'Error en IVA',
                    detail: 'El campo IVA no puede guardese como vacío.'
                  });
                  this.spin=false;//CDPJ
                  
                  errores++;
                }if(dev.DETDEV_PORIVA !== null || dev.DETDEV_PORIVA !== undefined || dev.DETDEV_PORIVA !== ''){
                  let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_PORCENTAJE === Number(dev.DETDEV_PORIVA) )
                  if(data === undefined|| data === null){
                    this.message.add({
                      key: 'devolucionCom',
                      severity: 'warn',
                      summary: 'Error en IVA',
                      detail: 'El porcentaje '+dev.DETDEV_PORIVA +' no forma parte de los porcentajes permitidos'
                    });
                    this.spin=false;//CDPJ
                    
                    errores++;
                  }
                }
                //CDPJ
              }
            }));
            if (errores === 0) {
              this.guardarDevolucion();
            }
          }
        }
      } else if (this.permisosDVF.PERACTUALIZACION === 0) {
        this.message.add({
          key: 'devolucionCom',
          severity: 'error',
          summary: 'Actualización de Devolucioón',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
        this.spin=false;//CDPJ
      }
    } else if (valor === 'Cancelar') {
      //CDPJ
      if (this.comDevFacService.detdevFacarray[this.indicador].length === 0 
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== ''
        ) {
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'Ingrese información en el detalle de la Devolución'
        });
        return;
      }
      if(strCodigo === 0  
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== ''){
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'La Devolución no tiene guardado el detalle'
        });
        return;
      }
      if(srtArtCntaSer === 0  
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== ''){
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'La Devolución debe tener registrado al menos un artículo o una cuenta o un servicio.'
        });
        return;
      }
      //CDPJ
      this.cancelarEdicionDevolucion();
    } else if (valor === 'Anular') {
      if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_ESTADO !== 'ANULADA') {
        this.confirmarAnularDev();
      } else if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_ESTADO === 'ANULADA') {
        this.message.add({
          key: 'devolucionCom',
          severity: 'error',
          summary: 'Anular Devolución',
          detail: 'La Devolución ya esta Anulada'
        });
      }
    } else if (valor === 'Buscar') {
      //CDPJ
      if (this.comDevFacService.detdevFacarray[this.indicador].length === 0 
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== ''
        ) {
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'Ingrese información en el detalle de la Devolución'
        });
        return;
      }
      if(strCodigo === 0  
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== ''){
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'La Devolución no tiene guardado el detalle'
        });
        return;
      }
      if(srtArtCntaSer === 0  
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== ''){
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'La Devolución debe tener registrado al menos un artículo o una cuenta o un servicio.'
        });
        return;
      }
      //CDPJ
      this.buscarDevoluciones();
    } else if (valor === 'Asiento') {
      if (this.btnGuardar === true) {
        this.confirmarGenerarAsiento();
      } else {
        this.message.add({
          key: 'devolucionCom',
          severity: 'error',
          summary: 'Asiento Devolución',
          detail: 'No se puede generar un asiento debido a que la factura no esta guardada'
        });
      }
    } else if (valor === 'AsientoVer') {
      this.comDevFacService.getAsiento(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe((res) => {
        this.asiento = res[0].ASI_NRO;
        if (this.asiento === null) {
          this.message.add({
            key: 'devolucionCom',
            severity: 'error',
            summary: 'Visualizacion de Asiento Contable',
            detail: 'Esta devolución no tiene un asiento contable'
          });
        } else {
          this.auxiliar++;
          // this.displayDialogAsiento = true;
          this.displayAsiContable = true;
        }
      });
    } else if (valor === 'Descuento') {
      if (this.comDevFacService.encDevSeleccionadaarray[this.indicador] !== undefined) {
        this.displayDialogDescuento = true;
      }
    }

    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }

    if (valor === 'Almacenardoc') {
      if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== '') {
        const usu = await this.comDevFacService.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.message.add({
            key: 'devolucionCom',
            severity: 'info',
            summary: 'Información',
            detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
          });
        }
        
      }
    }
    
    if (valor === 'Salir') {
      //CDPJ
      if (this.comDevFacService.detdevFacarray[this.indicador].length === 0 
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO  !== ''
        ) {
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'Ingrese información en el detalle de la Devolución'
        });
        return;
      }
      if(strCodigo === 0  
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== ''){
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'La Devolución no tiene guardado el detalle'
        });
        return;
      }
      if(srtArtCntaSer === 0  
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== null
        &&  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== undefined
        && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== ''){
        this.message.add({
          key: 'devolucionCom',
          severity: 'info',
          summary: 'Información',
          detail: 'La Devolución debe tener registrado al menos un artículo o una cuenta o un servicio.'
        });
        return;
      }
      //CDPJ
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
  nuevaDVF(parametro) {
    if (parametro === 'N') { // N para nuevo
      this.btnNuevo = true;
      document.getElementById('divPrincipal9').style.pointerEvents = 'all';//SE CAMBIO EL divPrincipal POR divPrincipal9 PORQUE SE BLOQUEABA AL ABRIR EL ASIENTO CDPJ 
      // document.getElementById('divPrincipal').style.opacity = '1.0';
      this.columnDefsDVFDetalle.map((columna) => {
        columna.editable = true;
      });
      this.columnDefsDVFDetalle[7].editable = (p) => {
        return p.data.COSTOBLOQ !== true;
      };
      this.aggrid.refreshColumnDefs2(this.columnDefsDVFDetalle);
      this.editarCeldas = false;
      this.tipodetalleactivo = 'all';
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.btnRegresar = false;
      this.botonUltimo = true;
      this.botonAnterior = true;
      this.botonSiguiente = true;
      this.botonPrimero = true;
      this.botonPago = true;
      this.botonAnular = true;
      this.botonBuscar = true;
      this.botonAsiento = true;
      this.botonVerAsiento = true;
      this.porcIvaBoolean = true;
      this.booleanDatosPantalla = false;
      const nuevaDevolucion = {
        ENCDEV_NUMERO: '', COM_CODIGO: '01', ENCDEV_REFERENCIA: '', ENCDEV_FECHAEMISION: new Date(), ENCDEV_ESTADO:
          'PENDIENTE', PRO_CODIGO: '', ENCDEV_CONTACTO: '', ENCDEV_TOTAL: 0, ENCDEV_COMENTARIO: '', ASI_NRO: '', ENCDEV_TOTALNETO: 0,
        ENCDEV_VALORDES: 0, ENCDEV_PORCEDES: 0, ENCDEV_VALORIVA: 0, ENCDEV_VALORICE: 0, ENCDEV_BASEIVA: 0, ENCDEV_BASEICE: 0,
        ENCDEV_BASECERO: 0, ENCDEV_PORCEIVA: Number(this.comDevFacService.porceiva), ENCDEV_IVA: 0, ENCDEV_SERIE: '', ENCDEV_AUTORIZACION: '', ENCDEV_MONTIVAPRESER: '',
        ENCDEV_PORIVAPRESER: '', ENCDEV_MONTRETPRESER: '', ENCDEV_MONTIVATRABIE: '', ENCDEV_PORIVATRABIE: '', ENCDEV_MONTRETTRABIE: '',
        MAEMOTIVO_CODIGO: '', ENCDEV_FECHACADUCIDAD: new Date(), DETDEVPRO_OTROS: '', ENCDEV_DETDEVNUMERO: '', BOD_CODIGO: '', MERCONS: '',
      };
      this.nombreProveedor = '';
      this.estadoRef = '';
      this.comDevFacService.encDevSeleccionadaarray[this.indicador] = nuevaDevolucion;
      this.comDevFacService.dblPorcDescGlbDevFCarray[this.indicador] = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_PORCEDES;
      this.comDevFacService.dblValorDescGlbDevFCarray[this.indicador] = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_VALORDES;
      this.comDevFacService.detdevFacarray[this.indicador] = [];
      this.botonFacNR = 'fac';
      // this.claveExportNuevo = 'INS';
      document.getElementById('comprobante').focus();
      this.indice = -1;
      this.bolestcta = false;
      this.bolsaldo = false;
    } else if (parametro === 'C') { // C para cancelar
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.botonUltimo = false;
      this.botonAnterior = false;
      this.botonPrimero = false;
      this.botonSiguiente = false;
      this.botonBuscar = false;
      this.botonPago = false;
      this.botonAnular = false;
      this.botonAsiento = false;
      this.botonVerAsiento = false;
      this.porcIvaBoolean = true;
      const nuevaDevolucion = {
        ENCDEV_NUMERO: '', COM_CODIGO: '01', ENCDEV_REFERENCIA: '', ENCDEV_FECHAEMISION: new Date(), ENCDEV_ESTADO:
          'PENDIENTE', PRO_CODIGO: '', ENCDEV_CONTACTO: '', ENCDEV_TOTAL: 0, ENCDEV_COMENTARIO: '', ASI_NRO: '', ENCDEV_TOTALNETO: 0,
        ENCDEV_VALORDES: 0, ENCDEV_PORCEDES: 0, ENCDEV_VALORIVA: 0, ENCDEV_VALORICE: 0, ENCDEV_BASEIVA: 0, ENCDEV_BASEICE: 0,
        ENCDEV_BASECERO: 0, ENCDEV_PORCEIVA:  Number(this.comDevFacService.porceiva), ENCDEV_IVA: 0, ENCDEV_SERIE: '', ENCDEV_AUTORIZACION: '', ENCDEV_MONTIVAPRESER: '',
        ENCDEV_PORIVAPRESER: '', ENCDEV_MONTRETPRESER: '', ENCDEV_MONTIVATRABIE: '', ENCDEV_PORIVATRABIE: '', ENCDEV_MONTRETTRABIE: '',
        MAEMOTIVO_CODIGO: '', ENCDEV_FECHACADUCIDAD: new Date(), DETDEVPRO_OTROS: '', ENCDEV_DETDEVNUMERO: '', BOD_CODIGO: '', MERCONS: ''
      };
      this.nombreProveedor = '';
      this.estadoRef = '';
      this.horaSistema = new Date();
      this.comDevFacService.encDevSeleccionadaarray[this.indicador] = nuevaDevolucion;
      this.comDevFacService.dblPorcDescGlbDevFCarray[this.indicador] = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_PORCEDES;
      this.comDevFacService.dblValorDescGlbDevFCarray[this.indicador] = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_VALORDES;
      this.comDevFacService.detdevFacarray[this.indicador] = [];
    }
  }

  async guardarDevolucion() {
    if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION.toString().includes('-')) {
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION =
        this.datePipe.transform(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION,
          'dd/MM/yyyy');
    } else if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION.toString().includes('/')) {
    }
    if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHACADUCIDAD.toString().includes('-')) {
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHACADUCIDAD =
        this.datePipe.transform(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHACADUCIDAD,
          'dd/MM/yyyy');
    } else if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHACADUCIDAD.toString().includes('/')) {
    }
    if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_ESTADO === 'PENDIENTE') {
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_ESTADO = 'P';
    }
    //this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_PORCEIVA = 12;
    const res = await this.comDevFacService.guardarENCDEVProm(this.comDevFacService.encDevSeleccionadaarray[this.indicador]).catch(e=>{
      this.spin=false//CDPJ
    });
      if (res !== null) {
        this.disabledEstadisticas = false;
        if (this.indice === -1) {
          this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO = res[0].v_encdev_numero;
          
          for (const dev of  this.comDevFacService.detdevFacarray[this.indicador]) {
            dev.ENCDEV_NUMERO = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO;
            //CDPJ
            if(dev.DETDEV_TIPODET ==='*'){
              dev.DETDEV_PORIVA=0;
              dev.TARIIVA_CODIGO='-1';
            }
            //CDPJ
            await this.comDevFacService.insertarDetalleProm(dev).catch(e=>{
              this.spin=false//CDPJ
            });
              if (dev.DETDEV_LINEA === 0) {
                dev.DETDEV_LINEA = dev.LINEA;
                this.auditoriagral.registrarAuditoria('COM_DETDEV', String(dev.ENCDEV_NUMERO), 'I',
                  '', '01', '', '', '', '').subscribe(() => {
                });
                this.auditoriagral.registrarAuditoria('COM_DETDEV', String(dev.ENCDEV_NUMERO)+ '/' + dev.DETDEV_CODIGO+ '/' 
                +'PorIva:'+ dev.DETDEV_PORIVA + '/' + 'CodTarIva:'+ dev.TARIIVA_CODIGO, 'I', 
                '', '01', '', '', '', '').subscribe(() => {
                });
              } else if (dev.DETDEV_LINEA !== 0) {
                this.auditoriagral.registrarAuditoria('COM_DETDEV', String(dev.ENCDEV_NUMERO), 'A',
                  '', '01', '', '', '', '').subscribe(() => {
                });
                this.auditoriagral.registrarAuditoria('COM_DETDEV', String(dev.ENCDEV_NUMERO)+ '/' + dev.DETDEV_CODIGO+ '/' 
                +'PorIva:'+ dev.DETDEV_PORIVA + '/' + 'CodTarIva:'+ dev.TARIIVA_CODIGO, 'A', 
                '', '01', '', '', '', '').subscribe(() => {
                });
              }
          }

          this.message.add({
            key: 'devolucionCom',
            severity: 'success',
            summary: 'Inserción de Devolución',
            detail: 'La devolución se insertó exitosamente'
          });
          this.spin=false//CDPJ
          this.botonPrimero = false;
          this.botonAnterior = false;
          this.botonSiguiente = false;
          this.botonUltimo = false;
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.btnRegresar = true;
          this.botonBuscar = false;
          this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_ESTADO = 'PENDIENTE';
          this.botonAnular = false;
          this.botonAsiento = false;
          this.botonVerAsiento = false;
          this.porcIvaBoolean = true;
          this.booleanDatosPantalla = true;
          this.indice = this.encabezadosDevF.length + 1;
          await this.comDevFacService.actualizarIvaProm(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO, this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_PORCEIVA);
          await this.comDevFacService.actualizarKardexProm(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO);
          await this.comDevFacService.recalcularCostoPromedioDocProm('select detdev_codigo from com_detdev where encdev_numero= \'' +
            this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO + '\'', this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION);
          if (this.botonFacNR === 'fac') {
            const item: any = {};
            item.TRNCOBRO_NRODOC = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO;
            item.COM_CODIGO = '01';
            item.TRNCOBRO_FECHATRN = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION;
            item.TRNCOBRO_TIPODOC = 'NC';
            item.TRNCOBRO_NROCOMPROBANTE = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_REFERENCIA;
            item.TRNCOBRO_CONCEPTO = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_COMENTARIO;
            item.TRNCOBRO_IMPORTE = Number(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_TOTAL);
            item.TRNCOBRO_FECHAVENCE = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHACADUCIDAD;
            item.PRO_CODIGO = this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO;
            item.APLORG_CODIGO = 'COM';
            item.TRNCOBRO_USUARIO_ACT = this.comDevFacService.usuario.identificacion;
            item.TRNCOBRO_FECHARECEPCION = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION;
            item.TRNCOBRO_REFERENCIAEXTERNA = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO;
            item.CON_CODIGO = this.comDevFacService.encDevSeleccionadaarray[this.indicador].CON_CODIGO;
            await this.comDevFacService.insertarTrncobroProm(item);
            await this.comDevFacService.generarAsientoProm(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO);
            this.auditoriagral.registrarAuditoria('COM_ENCDEV', String(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO), 'I',
              '', '01', '', '', '', '').subscribe(() => {});
            const asi = await this.comDevFacService.getAsientoProm(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO);
            this.comDevFacService.encDevSeleccionadaarray[this.indicador].ASI_NRO = asi[0].ASI_NRO;
            this.message.add({
              key: 'devolucionCom',
              severity: 'success',
              summary: 'Asiento Contable',
              detail: 'Se ha generado el asiento'
            });
                    
            this.spin=false//CDPJ 
          } else if (this.botonFacNR === 'nr') {
            if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].MERCONS === 'S') {
              await this.comDevFacService.generarAsientoConsigProm(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO);
              this.auditoriagral.registrarAuditoria('COM_ENCDEV',String(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO), 'I',
                        '', '01', '', '', '', '').subscribe(() => { });
              const asi2 = await this.comDevFacService.getAsientoProm(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO);
              this.comDevFacService.encDevSeleccionadaarray[this.indicador].ASI_NRO = asi2[0].ASI_NRO;
              this.message.add({
                key: 'devolucionCom',
                severity: 'success',
                summary: 'Asiento Contable',
                detail: 'Se ha generado el asiento'
                });
                this.spin=false//CDPJ
            } else {
              await this.comDevFacService.generarAsientoRecepProm(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO);
              this.auditoriagral.registrarAuditoria('COM_ENCDEV',
                String(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO), 'I',
                '', '01', '', '', '', '').subscribe(() => { });
              const asi3 = await this.comDevFacService.getAsientoProm(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO);
              this.comDevFacService.encDevSeleccionadaarray[this.indicador].ASI_NRO = asi3[0].ASI_NRO;
              this.message.add({
                key: 'devolucionCom',
                severity: 'success',
                summary: 'Asiento Contable',
                detail: 'Se ha generado el asiento'
              });   
              this.spin=false//CDPJ 
            }
          }
          const res1 = await this.comDevFacService.getEncDevFProm();
          this.encabezadosDevF = res1;
          this.cancelarEdicionDevolucion();
        } else {
          for (const dev of this.comDevFacService.detdevFacarray[this.indicador]) {
            dev.ENCDEV_NUMERO = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO;
            await this.comDevFacService.insertarDetalleProm(dev).catch(e=>{
              this.spin=false//CDPJ
            });
            if (dev.DETDEV_LINEA === 0) {
              dev.DETDEV_LINEA = dev.LINEA;
              this.auditoriagral.registrarAuditoria('COM_DETDEV', String(dev.ENCDEV_NUMERO), 'I',
                '', '01', '', '', '', '').subscribe(() => {
              });
            } else if (dev.DETDEV_LINEA !== 0) {
              this.auditoriagral.registrarAuditoria('COM_DETDEV', String(dev.ENCDEV_NUMERO), 'A',
                '', '01', '', '', '', '').subscribe(() => {
              });
            }
          }

          this.message.add({
            key: 'devolucionCom',
            severity: 'success',
            summary: 'Actualización de Devolución',
            detail: 'La devolución se actualizó exitosamente'
          });
          this.spin=false//CDPJ
          this.botonPrimero = false;
          this.botonAnterior = false;
          this.botonSiguiente = false;
          this.botonUltimo = false;
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.btnRegresar = true;
          this.botonBuscar = false;
          this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_ESTADO = 'PENDIENTE';
          this.botonAnular = false;
          this.botonAsiento = false;
          this.botonVerAsiento = false;
          this.porcIvaBoolean = true;
          this.booleanDatosPantalla = true;
          
          await this.comDevFacService.actualizarIvaProm(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO, this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_PORCEIVA);
          await this.comDevFacService.actualizarKardexProm(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO);
          await this.comDevFacService.recalcularCostoPromedioDocProm('select detdev_codigo from com_detdev where encdev_numero= \'' +
            this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO + '\'', this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION);
          if (this.botonFacNR === 'fac') {
            const item: any = {};
            item.TRNCOBRO_NRODOC = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO;
            item.COM_CODIGO = '01';
            item.TRNCOBRO_FECHATRN = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION;
            item.TRNCOBRO_TIPODOC = 'NC';
            item.TRNCOBRO_NROCOMPROBANTE = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_REFERENCIA;
            item.TRNCOBRO_CONCEPTO = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_COMENTARIO;
            item.TRNCOBRO_IMPORTE = Number(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_TOTAL);
            item.TRNCOBRO_FECHAVENCE = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHACADUCIDAD;
            item.PRO_CODIGO = this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO;
            item.APLORG_CODIGO = 'COM';
            item.TRNCOBRO_USUARIO_ACT = this.comDevFacService.usuario.identificacion;
            item.TRNCOBRO_FECHARECEPCION = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION;
            item.TRNCOBRO_REFERENCIAEXTERNA = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO;
            item.CON_CODIGO = this.comDevFacService.encDevSeleccionadaarray[this.indicador].CON_CODIGO;
            await this.comDevFacService.insertarTrncobroProm(item);
            await this.comDevFacService.generarAsientoProm(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO);
                    this.auditoriagral.registrarAuditoria('COM_ENCDEV', String(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO), 'A',
                      '', '01', '', '', '', '').subscribe(() => { });
            const asi = await this.comDevFacService.getAsientoProm(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO);
              this.comDevFacService.encDevSeleccionadaarray[this.indicador].ASI_NRO = asi[0].ASI_NRO;
              this.message.add({
                key: 'devolucionCom',
                severity: 'success',
                summary: 'Asiento Contable',
                detail: 'Se ha generado el asiento'
              });
              this.spin=false//CDPJ
           } else if (this.botonFacNR === 'nr') {
             if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].MERCONS === 'S') {
              await this.comDevFacService.generarAsientoConsigProm(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO);
              this.auditoriagral.registrarAuditoria('COM_ENCDEV', String(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO), 'A',
                '', '01', '', '', '', '').subscribe(() => {});
              const asi2 = await this.comDevFacService.getAsientoProm(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO);
              this.comDevFacService.encDevSeleccionadaarray[this.indicador].ASI_NRO = asi2[0].ASI_NRO;
              this.message.add({
                key: 'devolucionCom',
                severity: 'success',
                summary: 'Asiento Contable',
                detail: 'Se ha generado el asiento'
              });
              this.spin=false//CDPJ
          } else {
            await this.comDevFacService.generarAsientoRecepProm(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO);
                      this.auditoriagral.registrarAuditoria('COM_ENCDEV', String(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO), 'A',
                        '', '01', '', '', '', '').subscribe(() => { });
            const asi3 = await this.comDevFacService.getAsientoProm(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO);
            this.comDevFacService.encDevSeleccionadaarray[this.indicador].ASI_NRO = asi3[0].ASI_NRO;
            this.message.add({
              key: 'devolucionCom',
              severity: 'success',
              summary: 'Asiento Contable',
              detail: 'Se ha generado el asiento'
            });
            this.spin=false//CDPJ      
        
          }
        }
        const res1 = await this.comDevFacService.getEncDevFProm();
        this.encabezadosDevF = res1;
        this.cancelarEdicionDevolucion();
      }
    }
   
  }
  
  guardarDevolucionOld() {
    if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION.toString().includes('-')) {
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION =
        this.datePipe.transform(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION,
          'dd/MM/yyyy');
    } else if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION.toString().includes('/')) {
    }
    if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHACADUCIDAD.toString().includes('-')) {
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHACADUCIDAD =
        this.datePipe.transform(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHACADUCIDAD,
          'dd/MM/yyyy');
    } else if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHACADUCIDAD.toString().includes('/')) {
    }
    if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_ESTADO === 'PENDIENTE') {
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_ESTADO = 'P';
    }
    this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_PORCEIVA = 12;
    this.comDevFacService.guardarENCDEV(this.comDevFacService.encDevSeleccionadaarray[this.indicador]).subscribe((res) => {
      if (res !== null) {
        this.disabledEstadisticas = false;
        if (this.indice === -1) {
          this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO = res[0].v_encdev_numero;
          this.comDevFacService.detdevFacarray[this.indicador].map((dev) => {
            dev.ENCDEV_NUMERO = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO;
            this.comDevFacService.insertarDetalle(dev).subscribe(() => {
              if (dev.DETDEV_LINEA === 0) {
                dev.DETDEV_LINEA = dev.LINEA;
                this.auditoriagral.registrarAuditoria('COM_DETDEV', String(dev.ENCDEV_NUMERO), 'I',
                  '', '01', '', '', '', '').subscribe(() => {
                });
              } else if (dev.DETDEV_LINEA !== 0) {
                this.auditoriagral.registrarAuditoria('COM_DETDEV', String(dev.ENCDEV_NUMERO), 'A',
                  '', '01', '', '', '', '').subscribe(() => {
                });
              }
            });
          });
          this.obtenerDatos();
          this.message.add({
            key: 'devolucionCom',
            severity: 'success',
            summary: 'Inserción de Devolución',
            detail: 'La devolución se insertó exitosamente'
          });
          this.botonPrimero = false;
          this.botonAnterior = false;
          this.botonSiguiente = false;
          this.botonUltimo = false;
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.btnRegresar = true;
          this.botonBuscar = false;
          this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_ESTADO = 'PENDIENTE';
          this.botonAnular = false;
          this.botonAsiento = false;
          this.botonVerAsiento = false;
          this.porcIvaBoolean = true;
          this.booleanDatosPantalla = true;
          this.indice = this.encabezadosDevF.length + 1;
          this.comDevFacService.actualizarIva(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO, this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_PORCEIVA).subscribe(() => {
            this.comDevFacService.actualizarKardex(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe(() => {
              this.comDevFacService.recalcularCostoPromedioDoc('select detdev_codigo from com_detdev where encdev_numero= \'' +
                this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO + '\'', this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION).subscribe(() => {
                if (this.botonFacNR === 'fac') {
                  const item: any = {};
                  item.TRNCOBRO_NRODOC = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO;
                  item.COM_CODIGO = '01';
                  item.TRNCOBRO_FECHATRN = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION;
                  item.TRNCOBRO_TIPODOC = 'NC';
                  item.TRNCOBRO_NROCOMPROBANTE = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_REFERENCIA;
                  item.TRNCOBRO_CONCEPTO = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_COMENTARIO;
                  item.TRNCOBRO_IMPORTE = Number(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_TOTAL);
                  item.TRNCOBRO_FECHAVENCE = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHACADUCIDAD;
                  item.PRO_CODIGO = this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO;
                  item.APLORG_CODIGO = 'COM';
                  item.TRNCOBRO_USUARIO_ACT = this.comDevFacService.usuario.identificacion;
                  item.TRNCOBRO_FECHARECEPCION = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION;
                  item.TRNCOBRO_REFERENCIAEXTERNA = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO;
                  item.CON_CODIGO = this.comDevFacService.encDevSeleccionadaarray[this.indicador].CON_CODIGO;
                  this.comDevFacService.insertarTrncobro(item).subscribe();
                  this.comDevFacService.generarAsiento(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe(() => {
                    this.auditoriagral.registrarAuditoria('COM_ENCDEV', String(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO), 'I',
                      '', '01', '', '', '', '').subscribe(() => {
                      this.comDevFacService.getAsiento(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe((asi) => {
                        this.comDevFacService.encDevSeleccionadaarray[this.indicador].ASI_NRO = asi[0].ASI_NRO;
                        this.message.add({
                          key: 'devolucionCom',
                          severity: 'success',
                          summary: 'Asiento Contable',
                          detail: 'Se ha generado el asiento'
                        });
                      });
                    });
                  });
                } else if (this.botonFacNR === 'nr') {
                  if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].MERCONS === 'S') {
                    this.comDevFacService.generarAsientoConsig(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe(() => {
                      this.auditoriagral.registrarAuditoria('COM_ENCDEV',
                        String(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO), 'I',
                        '', '01', '', '', '', '').subscribe(() => {
                        this.comDevFacService.getAsiento(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe((asi2) => {
                          this.comDevFacService.encDevSeleccionadaarray[this.indicador].ASI_NRO = asi2[0].ASI_NRO;
                          this.message.add({
                            key: 'devolucionCom',
                            severity: 'success',
                            summary: 'Asiento Contable',
                            detail: 'Se ha generado el asiento'
                          });
                        });
                      });
                    });
                  } else {
                    this.comDevFacService.generarAsientoRecep(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe(() => {
                      this.auditoriagral.registrarAuditoria('COM_ENCDEV',
                        String(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO), 'I',
                        '', '01', '', '', '', '').subscribe(() => {
                        this.comDevFacService.getAsiento(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe((asi3) => {
                          this.comDevFacService.encDevSeleccionadaarray[this.indicador].ASI_NRO = asi3[0].ASI_NRO;
                          this.message.add({
                            key: 'devolucionCom',
                            severity: 'success',
                            summary: 'Asiento Contable',
                            detail: 'Se ha generado el asiento'
                          });
                        });
                      });
                    });
                  }
                }
              });
            });
          });
          this.comDevFacService.getEncDevF().subscribe((res1) => {
            this.encabezadosDevF = res1;
          });
        } else {
          this.comDevFacService.detdevFacarray[this.indicador].map((dev) => {
            dev.ENCDEV_NUMERO = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO;
            this.comDevFacService.insertarDetalle(dev).subscribe(() => {
              if (dev.DETDEV_LINEA === 0) {
                dev.DETDEV_LINEA = dev.LINEA;
                this.auditoriagral.registrarAuditoria('COM_DETDEV', String(dev.ENCDEV_NUMERO), 'I',
                  '', '01', '', '', '', '').subscribe(() => {
                });
              } else if (dev.DETDEV_LINEA !== 0) {
                this.auditoriagral.registrarAuditoria('COM_DETDEV', String(dev.ENCDEV_NUMERO), 'A',
                  '', '01', '', '', '', '').subscribe(() => {
                });
              }
            });
          });
          this.message.add({
            key: 'devolucionCom',
            severity: 'success',
            summary: 'Actualización de Devolución',
            detail: 'La devolución se actualizó exitosamente'
          });
          this.botonPrimero = false;
          this.botonAnterior = false;
          this.botonSiguiente = false;
          this.botonUltimo = false;
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.btnRegresar = true;
          this.botonBuscar = false;
          this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_ESTADO = 'PENDIENTE';
          this.botonAnular = false;
          this.botonAsiento = false;
          this.botonVerAsiento = false;
          this.porcIvaBoolean = true;
          this.booleanDatosPantalla = true;
          this.comDevFacService.actualizarIva(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO, this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_PORCEIVA).subscribe(() => {
            this.comDevFacService.actualizarKardex(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe(() => {
              this.comDevFacService.recalcularCostoPromedioDoc('select detdev_codigo from com_detdev where encdev_numero= \'' +
                this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO + '\'', this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION).subscribe(() => {
                if (this.botonFacNR === 'fac') {
                  const item: any = {};
                  item.TRNCOBRO_NRODOC = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO;
                  item.COM_CODIGO = '01';
                  item.TRNCOBRO_FECHATRN = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION;
                  item.TRNCOBRO_TIPODOC = 'NC';
                  item.TRNCOBRO_NROCOMPROBANTE = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_REFERENCIA;
                  item.TRNCOBRO_CONCEPTO = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_COMENTARIO;
                  item.TRNCOBRO_IMPORTE = Number(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_TOTAL);
                  item.TRNCOBRO_FECHAVENCE = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHACADUCIDAD;
                  item.PRO_CODIGO = this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO;
                  item.APLORG_CODIGO = 'COM';
                  item.TRNCOBRO_USUARIO_ACT = this.comDevFacService.usuario.identificacion;
                  item.TRNCOBRO_FECHARECEPCION = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION;
                  item.TRNCOBRO_REFERENCIAEXTERNA = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO;
                  item.CON_CODIGO = this.comDevFacService.encDevSeleccionadaarray[this.indicador].CON_CODIGO;
                  this.comDevFacService.insertarTrncobro(item).subscribe();
                  this.comDevFacService.generarAsiento(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe(() => {
                    this.auditoriagral.registrarAuditoria('COM_ENCDEV', String(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO), 'A',
                      '', '01', '', '', '', '').subscribe(() => {
                      this.comDevFacService.getAsiento(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe((asi) => {
                        this.comDevFacService.encDevSeleccionadaarray[this.indicador].ASI_NRO = asi[0].ASI_NRO;
                        this.message.add({
                          key: 'devolucionCom',
                          severity: 'success',
                          summary: 'Asiento Contable',
                          detail: 'Se ha generado el asiento'
                        });
                      });
                    });
                  });
                } else if (this.botonFacNR === 'nr') {
                  if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].MERCONS === 'S') {
                    this.comDevFacService.generarAsientoConsig(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe(() => {
                      this.auditoriagral.registrarAuditoria('COM_ENCDEV', String(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO), 'A',
                        '', '01', '', '', '', '').subscribe(() => {
                        this.comDevFacService.getAsiento(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe((asi2) => {
                          this.comDevFacService.encDevSeleccionadaarray[this.indicador].ASI_NRO = asi2[0].ASI_NRO;
                          this.message.add({
                            key: 'devolucionCom',
                            severity: 'success',
                            summary: 'Asiento Contable',
                            detail: 'Se ha generado el asiento'
                          });
                        });
                      });
                    });
                  } else {
                    this.comDevFacService.generarAsientoRecep(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe(() => {
                      this.auditoriagral.registrarAuditoria('COM_ENCDEV', String(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO), 'A',
                        '', '01', '', '', '', '').subscribe(() => {
                        this.comDevFacService.getAsiento(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe((asi3) => {
                          this.comDevFacService.encDevSeleccionadaarray[this.indicador].ASI_NRO = asi3[0].ASI_NRO;
                          this.message.add({
                            key: 'devolucionCom',
                            severity: 'success',
                            summary: 'Asiento Contable',
                            detail: 'Se ha generado el asiento'
                          });
                        });
                      });
                    });
                  }
                }
              });
            });
          });
          this.comDevFacService.getEncDevF().subscribe((res1) => {
            this.encabezadosDevF = res1;
          });
        }
      }
    });
  }
  
  confirmarDev() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar la devolución',
      header: 'Eliminar Devolución',
      icon: 'pi pi-exclamation-triangle',
      key: 'devolucionConfCom',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarDevolucion();
      },
      reject: () => {
      }
    });
  }

  async eliminarCxpTrnCobro(docReferencia) {
   
		const params: any[] = [];
		//Número de documento
		params[0] = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO;
		//Codigo de la comundidad
		params[1] = '01';
		//Código del proveedor
		params[2] = this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO;
		//Tipo de documento
		params[3] = 'NC';
	
		
			try{
        await this.comDevFacService.eliminarTrnCobroProm(params);
			return true;
		}catch(err){
				return false;
		}
    
  }
  
  async eliminarDevolucion() {
    if (this.comDevFacService.encDevSeleccionadaarray[this.indicador] !== undefined) {
      if (this.botonFacNR === 'fac') {
        if (await this.eliminarCxpTrnCobro(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO) === false) {
          this.message.add({
            key: 'devolucionCom',
            severity: 'error',
            summary: 'Error',
            detail: 'No se puede eliminar la devolución, ya que existen transacciones realizadas con la Nota de crédito generada'
          });
          return;
        }
      }
      const numeroDev = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO;
      await this.comDevFacService.eliminarEncProm(this.comDevFacService.encDevSeleccionadaarray[this.indicador]);
      await this.comDevFacService.actualizarKardexProm(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO);
      await this.comDevFacService.eliminarSerialesProm(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO);
      await this.comDevFacService.recalcularCostosArtEliminadosProm(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO, this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION);
      this.auditoriagral.registrarAuditoria('COM_ENCDEV', String(numeroDev),
        'E', '', '01', '', '', '', '').subscribe(() => {
      });
      
      for (const detalle of this.comDevFacService.detdevFacarray[this.indicador]) {
        await this.comDevFacService.actualizarEstadoProm(detalle);
        this.auditoriagral.registrarAuditoria('COM_DETDEV', String(numeroDev)
          , 'E', '', '01', '', '', '', '').subscribe(() => {
        });
      }
    
      this.message.add({
        key: 'devolucionCom',
        severity: 'success',
        summary: 'Eliminación de Devolución',
        detail: 'Devolución  eliminada correctamente'
      });
        
        this.comDevFacService.encDevSeleccionadaarray[this.indicador] = {
          ENCDEV_NUMERO: '',
          COM_CODIGO: '01',
          ENCDEV_REFERENCIA: '',
          ENCDEV_FECHAEMISION: new Date(),
          ENCDEV_ESTADO:
            '',
          PRO_CODIGO: '',
          ENCDEV_CONTACTO: '',
          ENCDEV_TOTAL: 0,
          ENCDEV_COMENTARIO: '',
          ASI_NRO: '',
          ENCDEV_TOTALNETO: 0,
          ENCDEV_VALORDES: 0,
          ENCDEV_PORCEDES: 0,
          ENCDEV_VALORIVA: 0,
          ENCDEV_VALORICE: 0,
          ENCDEV_BASEIVA: 0,
          ENCDEV_BASEICE: 0,
          ENCDEV_BASECERO: 0,
          ENCDEV_PORCEIVA: 0,
          ENCDEV_IVA: 0,
          ENCDEV_SERIE: '',
          ENCDEV_AUTORIZACION: '',
          ENCDEV_MONTIVAPRESER: '',
          ENCDEV_PORIVAPRESER: '',
          ENCDEV_MONTRETPRESER: '',
          ENCDEV_MONTIVATRABIE: '',
          ENCDEV_PORIVATRABIE: '',
          ENCDEV_MONTRETTRABIE: '',
          MAEMOTIVO_CODIGO: '',
          ENCDEV_FECHACADUCIDAD: new Date(),
          DETDEVPRO_OTROS: '',
          ENCDEV_DETDEVNUMERO: '',
          BOD_CODIGO: '',
          MERCONS: '',
        };
        this.comDevFacService.detdevFacarray[this.indicador] = [];
        this.nombreProveedor = '';
        this.btnNuevo = false;
        this.btnGuardar = true;
        this.btnBorrar = false;
        this.btnRegresar = true;

        const res1 = await this.comDevFacService.getEncDevFProm();
        this.encabezadosDevF = res1;
     
    } else {
      this.message.add({
        key: 'devolucionCom',
        severity: 'error',
        summary: 'Eliminación de Devolución',
        detail: 'Seleccione una devolución para ser eliminada'
      });
    }
  }
  
  cancelarEdicionDevolucion() {
    if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== '' && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== undefined) {
      this.comDevFacService.getEncDevolucionNum(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe(async (res) => {
        if (res.length === 0) {
          this.nuevaDVF('C');
        } else {
          const res1 = await this.comDevFacService.getEncDevFProm();
          this.encabezadosDevF = res1;
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.botonAnterior = false;
          this.botonPrimero = false;
          this.botonUltimo = false;
          this.botonSiguiente = false;
          this.botonBuscar = false;
          this.botonPago = false;
          this.botonAnular = false;
          this.botonAsiento = false;
          this.botonVerAsiento = false;
          this.comDevFacService.encDevSeleccionadaarray[this.indicador] = res[0];
          this.activeIndex = 0;
          this.obtenerDatos();
        }
      });
    } else {
      this.nuevaDVF('C');
    }
  }
  
  confirmarAnularDev() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea anular la devolución',
      header: 'Anular Devolución',
      icon: 'pi pi-exclamation-triangle',
      key: 'devolucionConfCom',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.anularDev();
      },
      reject: () => {
      }
    });
  }
  
  anularDev() {
    if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION.toString().includes('-')) {
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION = this.datePipe.transform(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION,
        'dd/MM/yyyy');
    } else if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION.toString().includes('/')) {
    }
    this.comDevFacService.anularDev(this.comDevFacService.encDevSeleccionadaarray[this.indicador], 'A').subscribe(async () => {
      this.auditoriagral.registrarAuditoria('COM_ENCDEV', String(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO), 'A', '', '01', '', '', '', '').subscribe(() => {
      });
      if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ASI_NRO !== '' && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ASI_NRO !== null) {
        this.comDevFacService.anularAsiento(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ASI_NRO).subscribe(() => {
        });
      }
      //CDPJ
      if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO.toString().includes('FP')) {
        await this.eliminarCxpTrnCobro(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO)
      }
      //CDPJ
      this.comDevFacService.eliminarSeriales(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe(() => {
      });
      this.comDevFacService.recalcularCostoPromedioDoc('select detdev_codigo from com_detdev where encdev_numero= \'' +
        this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO + '\'', this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION).subscribe(() => {
      });
      this.message.add({
        key: 'devolucionCom',
        severity: 'success',
        summary: 'Anular Devolución',
        detail: 'Se anulo la devolución'
      });
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_ESTADO = 'ANULADA';
      document.getElementById('divPrincipal9').style.pointerEvents = 'none';
      // document.getElementById('divPrincipal').style.opacity = '0.7';
      this.columnDefsDVFDetalle.map((columna) => {
        columna.editable = false;
      });
      this.columnDefsDVFDetalle[7].editable = (p) => {
        return p.data.COSTOBLOQ !== true;
      };
      this.aggrid.refreshColumnDefs2(this.columnDefsDVFDetalle);
      this.editarCeldas = true;
      this.tipodetalleactivo = 'none';
      this.btnNuevoGrid = true;
      this.btnGuardarGrid = true;
      this.btnCancelarGrid = true;
      this.btnBorrarGrid = true;
      this.botonAnular = true;
      this.botonAsiento = true;
    });
  }
  
  obtenerDatos() {
    this.bolestcta = false;
    this.bolsaldo = false;
    if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_ESTADO === 'P' || this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_ESTADO === 'PENDIENTE') {
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_ESTADO = 'PENDIENTE';
      document.getElementById('divPrincipal9').style.pointerEvents = 'all';
      // document.getElementById('divPrincipal').style.opacity = '1.0';
      this.columnDefsDVFDetalle.map((columna) => {
        columna.editable = true;
      });
      this.columnDefsDVFDetalle[7].editable = (p) => {
        return p.data.COSTOBLOQ !== true;
      };
      this.aggrid.refreshColumnDefs2(this.columnDefsDVFDetalle);
      this.editarCeldas = false;
      this.tipodetalleactivo = 'all';
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnCancelarGrid = false;
      this.btnBorrarGrid = false;
      this.botonAnular = false;
      this.botonAsiento = false;
    } else if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_ESTADO === 'G' || this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_ESTADO === 'PAGADA') {
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_ESTADO = 'PAGADA';
      document.getElementById('divPrincipal9').style.pointerEvents = 'none';
      // document.getElementById('divPrincipal').style.opacity = '0.7';
      this.columnDefsDVFDetalle.map((columna) => {
        columna.editable = false;
      });
      this.aggrid.refreshColumnDefs2(this.columnDefsDVFDetalle);
      this.editarCeldas = true;
      this.tipodetalleactivo = 'none';
      this.btnNuevoGrid = true;
      this.btnGuardarGrid = true;
      this.btnCancelarGrid = true;
      this.btnBorrarGrid = true;
      this.botonAnular = false;
      this.botonAsiento = true;
    } else if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_ESTADO === 'A' || this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_ESTADO === 'ANULADA') {
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_ESTADO = 'ANULADA';
      document.getElementById('divPrincipal9').style.pointerEvents = 'none';
      // document.getElementById('divPrincipal').style.opacity = '0.7';
      this.columnDefsDVFDetalle.map((columna) => {
        columna.editable = false;
      });
      this.aggrid.refreshColumnDefs2(this.columnDefsDVFDetalle);
      this.editarCeldas = true;
      this.tipodetalleactivo = 'none';
      this.btnNuevoGrid = true;
      this.btnGuardarGrid = true;
      this.btnCancelarGrid = true;
      this.btnBorrarGrid = true;
      this.botonAnular = true;
      this.botonAsiento = true;
    }
    this.valorDscValor = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_VALORDES;
    this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_VALORDES = Number(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_VALORDES).toFixed(this.numdecsist);
    this.comDevFacService.dblValorDescGlbDevFCarray[this.indicador] = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_VALORDES;
    this.valorDscPorcentaje = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_PORCEDES;
    this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_PORCEDES = Number(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_PORCEDES).toFixed(this.numdecsist);
    this.comDevFacService.dblPorcDescGlbDevFCarray[this.indicador] = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_PORCEDES;
    this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_VALORIVA = Number(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_VALORIVA).toFixed(this.numdecsist);
    this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_BASEIVA = Number(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_BASEIVA).toFixed(this.numdecsist);
    this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_VALORICE = Number(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_VALORICE).toFixed(this.numdecsist);
    this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_BASEICE = Number(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_BASEICE).toFixed(this.numdecsist);
    this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_BASECERO = Number(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_BASECERO).toFixed(this.numdecsist);
    this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_TOTALNETO = Number(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_TOTALNETO).toFixed(this.numdecsist);
    this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_TOTAL = Number(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_TOTAL).toFixed(this.numdecsist);
    if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO !== null) {
      this.comDevFacService.obtenerNombreProv(this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO).subscribe((res1) => {
        this.nombreProveedor = res1[0].PRO_NOMBRE;
        this.comDevFacService.encDevSeleccionadaarray[this.indicador].CON_CODIGO = res1[0].CON_CODIGO1;
      });
    } else {
      this.nombreProveedor = '';
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].CON_CODIGO = '';
    }
    this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION = new Date(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHAEMISION);
    this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHACADUCIDAD = new Date(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_FECHACADUCIDAD);
    if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO !== '') {
      this.comDevFacService.getDetDevF(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe((res) => {
        if (res !== null) {
          this.comDevFacService.detdevFacarray[this.indicador] = res;
          this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO = this.comDevFacService.detdevFacarray[this.indicador][0].DETDEV_NUMERO;
          if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO.toString().includes('FP')) {
            this.botonFacNR = 'fac';
          } else if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO.toString().includes('NR')) {
            this.botonFacNR = 'nr';
          }
          this.busFacturaNota()
          this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO = this.comDevFacService.detdevFacarray[this.indicador][0].BOD_CODIGO;
          this.comDevFacService.detdevFacarray[this.indicador].map((pedido) => {
            const opcPedido = [];
            opcPedido.push(pedido.UNI_CODIGO);
            this.columnDefsDVFDetalle[5].cellEditorParams = {
              values: opcPedido
            };
            this.aggrid.refreshColumnDefs2(this.columnDefsDVFDetalle);
            pedido.DETDEV_COSTO = pedido.DETDEV_COSTO.toFixed(this.decimalesPrecioDVF);
            //GSRF
            pedido.DETDEV_VALDES = pedido.DETDEV_VALDES.toFixed(this.decimalesPrecioDVF);
            pedido.DETDEV_PORDES = pedido.DETDEV_PORDES.toFixed(3);
            //GSRF
            pedido.DETDEV_CANTIDAD = pedido.DETDEV_CANTIDAD.toFixed(this.decimalesCantidadDVF);
            pedido.CANTIDAD = pedido.DETDEV_CANTIDAD;
            pedido.DETDEV_TOTAL = Number(pedido.DETDEV_TOTAL).toFixed(this.decimalesTotalDVF);
            pedido.LINEA = pedido.DETDEV_LINEA;
          });
          this.detalleDVFSeleccionado = this.comDevFacService.detdevFacarray[this.indicador][0];
          // this.llenarEstadoCuenta();
          // this.llenarTablaSaldos();
        }
      });
    }
    if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO !== '') {
      this.disabledEstadisticas = false;
    } else {
      this.disabledEstadisticas = true;
    }
  }
  
  buscarDevoluciones() {
    this.opcion = 'BUSCAR';
    this.busquedaCompuesta = false;
    this.types = [
      {label: 'NÚMERO', value: 'ENCDEV_NUMERO'},
      {label: 'REFERENCIA', value: 'ENCDEV_REFERENCIA'},
      {label: 'FEC. EMISIÓN', value: 'ENCDEV_FECHAEMISION'},
      {label: 'ESTADO', value: 'ENCDEV_ESTADO'},
      {label: 'NOMBRE PROVEEDOR', value: 'PRO_NOMBRE'},
      {label: 'PROVEEDOR', value: 'PRO_CODIGO'},
      {label: 'CONTACTO', value: 'ENCDEV_CONTACTO'},
      {label: 'TOTAL', value: 'ENCDEV_TOTAL'},
      {label: 'TOTAL NETO', value: 'ENCDEV_TOTALNETO'},
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'COM_ENCDEV';
    this.consulta = 'ENCDEV_NUMERO, ENCDEV_FECHAEMISION, ENCDEV_REFERENCIA, ENCDEV_ESTADO, PRO_CODIGO, ENCDEV_CONTACTO, ENCDEV_TOTAL, ENCDEV_TOTALNETO, ' +
      'COM_FNC_NOMBRE_PROVEEDOR(PRO_CODIGO) AS PRO_NOMBRE';
    this.where = 'ENCDEV_NUMERO IS NOT NULL ORDER BY ENCDEV_FECHAEMISION DESC';
    this.busquedaSer.busquedaComEncDev().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.arregloCons.map((registro) => {
        if (registro.ENCDEV_FECHAEMISION !== null) {
          registro.ENCDEV_FECHAEMISION = this.datePipe.transform(registro.ENCDEV_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          registro.ENCDEV_FECHAEMISION = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });
  }
  
  abrirProveedor() {
    this.opcion = 'BUSQUEDAPROV';
    this.busquedaCompuesta = false;
    this.types = [
      {label: 'Código', value: 'PRO_CODIGO'},
      {label: 'Nombre', value: 'PRO_NOMBRE'},
      {label: 'Nombre Corto', value: 'PRO_NOMBREC'},
      {label: 'Grupo', value: 'GRU_CODIGO'},
      {label: 'RUC', value: 'PRO_RUCIDE'},
      {label: 'Cuenta', value: 'CON_CODIGO1'}
    ];
    this.busquedacampos = [this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO, '', '', '', '', ''];
    this.tabla = 'COM_MAEPROVEEDOR';
    this.consulta = 'PRO_CODIGO, PRO_NOMBRE, PRO_NOMBREC, GRU_CODIGO, PRO_RUCIDE, CON_CODIGO1, PRO_CONTACTO, PRO_IVA, PRO_DESCUENTO';
    this.where = '';
    this.busquedaSer.busquedaComMaeProveedor2(this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }
  
  abrirBodega() {
    this.opcion = 'BUSQUEDABOD';
    this.busquedaCompuesta = false;
    this.types = [
      {label: 'Código', value: 'BOD_CODIGO'},
      {label: 'Nombre', value: 'BOD_NOMBRE'}
    ];
    this.busquedacampos = [this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO, ''];
    this.tabla = 'inv_maebodega';
    this.consulta = '*';
    this.where = '';
    this.busquedaSer.busquedaInvMaeBodega1(this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }
  
  abrirFacturaNota() {
    this.busquedaCompuesta = true;
    this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO = '';
    if (this.botonFacNR === 'fac') {
      this.opcion = 'BUSQUEDAFAC';
      this.types = [
        {label: 'NÚMERO', value: 'ENCFACPRO_NUMERO'},
        {label: 'REFERENCIA', value: 'ENCFACPRO_REFERENCIA'},
        {label: 'F_EMISIÓN', value: 'ENCFACPRO_FECHAEMISION'},
        {label: 'ESTADO', value: 'ENCFACPRO_ESTADO'},
        {label: 'NOMBRE PROV', value: 'PRO_NOMBRE'},
        {label: 'NOMBRE CORTO', value: 'PRO_NOMBREC'},
        {label: 'PROVEEDOR', value: 'PRO_CODIGO'},
        {label: 'CONTACTO', value: 'ENCFACPRO_CONTACTO'},
        {label: 'TOTAL', value: 'ENCFACPRO_TOTAL'},
        {label: 'NETO', value: 'ENCFACPRO_TOTALNETO'},
        {label: 'BASE IVA', value: 'ENCFACPRO_BASEIVA'},
      ];
      this.busquedacampos = [this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO, '', '', '', '', '', '', '', '', '', ''];
      this.tabla = 'COM_ENCFACPRO f, COM_MAEPROVEEDOR p';
      this.consulta = 'f.*, p.PRO_NOMBRE, p.PRO_NOMBREC';
      this.where = 'f.ENCFACPRO_ESTADO != \'A\' AND f.PRO_CODIGO = \'' +
        this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO + '\' AND f.' + this.types[0].value + ' LIKE \'%' +
        this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO + '%\' AND p.PRO_CODIGO = f.PRO_CODIGO';
      this.comDevFacService.buscarEncfacPro(this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO, this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO).subscribe((datos: any[]) => {
        this.arregloCons = datos;
        this.arregloCons.map((consulta) => {
          consulta.ENCFACPRO_FECHAEMISION = this.datePipe.transform(consulta.ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
        });
        this.displayDialogBusquedaFast = true;
      });
    } else if (this.botonFacNR === 'nr') {
      this.opcion = 'BUSQUEDANR';
      this.types = [
        {label: 'NUMERO', value: 'ENCNOTREC_NUMERO'},
        {label: 'REFERENCIA', value: 'ENCNOTREC_REFERENCIA'},
        {label: 'F_EMISION', value: 'ENCNOTREC_FECHAEMISION'},
        {label: 'ESTADO', value: 'ENCNOTREC_ESTADO'},
        {label: 'NOMBRE PROV', value: 'PRO_NOMBRE'},
        {label: 'NOMBRE CORTO', value: 'PRO_NOMBREC'},
        {label: 'PROVEEDOR', value: 'PRO_CODIGO'},
        {label: 'CONTACTO', value: 'ENCNOTREC_CONTACTO'},
        {label: 'TOTAL', value: 'ENCNOTREC_TOTAL'},
        {label: 'NETO', value: 'ENCNOTREC_TOTALNETO'}
      ];
      this.busquedacampos = [this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO, '', '', '', '', '', '', '', '', ''];
      this.tabla = 'COM_ENCNOTREC r, COM_MAEPROVEEDOR p';
      this.consulta = 'r.*, p.PRO_NOMBRE, p.PRO_NOMBREC';
      this.where = 'r.ENCNOTREC_ESTADO != \'A\' AND r.PRO_CODIGO = \'' +
        this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO + '\' AND r.' + this.types[0].value + ' LIKE \'%' +
        this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO + '%\' AND p.PRO_CODIGO = r.PRO_CODIGO';
      this.comDevFacService.buscarEncNotRec(this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO, this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO).subscribe((datos: any[]) => {
        this.arregloCons = datos;
        this.arregloCons.map((consulta) => {
          consulta.ENCNOTREC_FECHAEMISION = this.datePipe.transform(consulta.ENCNOTREC_FECHAEMISION, 'dd/MM/yyyy');
        });
        this.displayDialogBusquedaFast = true;
      });
    }
  }
  //GSRF
busFacturaNota() {
  console.log('*/-*/',this.botonFacNR)
  if (this.botonFacNR === 'fac') {
    this.comDevFacService.buscarEncfacPro(this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO, this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO).subscribe((datos: any[]) => {
      datos.map((consulta) => {
        consulta.ENCFACPRO_FECHAEMISION = this.datePipe.transform(consulta.ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
        console.log('*/-*/',consulta);
        console.log('*-/-*/',consulta.ENCFACPRO_TOTAL)
        console.log('*-/-*/',consulta.ENCFACPRO_IVA)
        this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_IVA = consulta.ENCFACPRO_IVA;
        this.totalfaconr = consulta.ENCFACPRO_TOTAL;
      });
    });
  } else if (this.botonFacNR === 'nr') {
    this.comDevFacService.buscarEncNotRec(this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO, this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO).subscribe((datos: any[]) => {
     datos.map((consulta) => {
        consulta.ENCNOTREC_FECHAEMISION = this.datePipe.transform(consulta.ENCNOTREC_FECHAEMISION, 'dd/MM/yyyy');
        console.log('*/-*/',consulta);
        console.log('*-/-*/',consulta.ENCNOTREC_TOTAL)
        console.log('*-/-*/',consulta.ENCNOTREC_IVA)
        this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_IVA = consulta.ENCNOTREC_IVA;
        this.totalfaconr = consulta.ENCNOTREC_TOTAL;
      });
    });
  }
}
abrirbusquedaiva() {
  this.opcion = 'busquedatariiva';
  this.types = [
    { label: 'Código', value: 'TARIIVA_CODIGO' },
    { label: 'Descripcion', value: 'TARIIVA_DESCRIPCION' },
    { label: 'Porcentaje', value: 'TARIIVA_PORCENTAJE' }
  ];

  this.busquedacampos = ['', '', ''];
  this.tabla = 'SRIFE_TARIIVA';
  this.where = 'TARIIVA_ACTIVO=\'S\'';
  this.busquedaSer.busquedaSrifeTariIva(this.where).subscribe((datos: any[]) => {
    this.arregloCons = datos;
    // this.displayDialogBusqueda = true;
    this.displayDialogBusquedaFast = true;
  });
}
//GSRF
  manejarSeleccion(opcion) {
    if (this.opcion === 'BUSCAR') {
      this.encabezadosDevF.map((enca) => {
        if (enca.ENCDEV_NUMERO === opcion.ENCDEV_NUMERO) {
          this.indice = this.encabezadosDevF.indexOf(enca);
        }
      });
      if (this.indice === this.encabezadosDevF.length - 1) {
        this.botonUltimo = true;
        this.botonSiguiente = true;
        this.comDevFacService.encDevSeleccionadaarray[this.indicador] = this.encabezadosDevF[this.indice];
        this.obtenerDatos();
      } else if (this.indice === 0) {
        this.botonPrimero = true;
        this.botonAnterior = true;
        this.comDevFacService.encDevSeleccionadaarray[this.indicador] = this.encabezadosDevF[this.indice];
        this.obtenerDatos();
      } else {
        this.botonPrimero = false;
        this.botonAnterior = false;
        this.botonSiguiente = false;
        this.botonUltimo = false;
        this.comDevFacService.encDevSeleccionadaarray[this.indicador] = this.encabezadosDevF[this.indice];
        this.obtenerDatos();
      }
      this.displayDialogBusquedaFast = false;
    } else if (this.opcion === 'busquedatariiva'){
      this.comDevFacService.porceiva=opcion.TARIIVA_PORCENTAJE;
      this.comDevFacService.tariivacod=opcion.TARIIVA_CODIGO;
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_PORCEIVA=Number(this.comDevFacService.porceiva);
      this.displayDialogBusquedaFast = false;
    }else if (this.opcion === 'BUSQUEDAPROV') {
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO = opcion.PRO_CODIGO;
      this.nombreProveedor = opcion.PRO_NOMBRE;
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_IVA = opcion.PRO_IVA;
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_CONTACTO = opcion.PRO_CONTACTO;
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].CON_CODIGO = opcion.CON_CODIGO1;
      this.displayDialogBusquedaFast = false;
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.botonPrimero = true;
      this.botonSiguiente = true;
      this.botonAnterior = true;
      this.botonUltimo = true;
      document.getElementById('comentario').focus();
    } else if (this.opcion === 'BUSQUEDABOD') {
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO = opcion.BOD_CODIGO;
      this.displayDialogBusquedaFast = false;
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.botonPrimero = true;
      this.botonSiguiente = true;
      this.botonAnterior = true;
      this.botonUltimo = true;
      document.getElementById('autorizacion').focus();
    } else if (this.opcion === 'BUSQUEDAFAC') {
      console.log('*-/-*/',opcion.ENCFACPRO_TOTAL)//GSRF
      console.log('*-/-*/',opcion.ENCFACPRO_IVA)//GSRF
      this.totalfaconr = opcion.ENCFACPRO_TOTAL;//GSRF
      let intSW=0;
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO = opcion.ENCFACPRO_NUMERO;
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_IVA = opcion.ENCFACPRO_IVA;
      this.comencfacService.getDetfacpro(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO).subscribe((res) => {
        //CDPJ
        for (const rs of res){
          if (intSW === 0) {
            if (rs.BOD_CODIGO === null
              || rs.BOD_CODIGO === undefined
              || rs.BOD_CODIGO === '') {
              intSW = 0;
            } else {
              console.log('entro',intSW)
              this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO = rs.BOD_CODIGO;
              intSW++;
              // this.encfacproService.erBodNombre2(this.encfacproService.encfacproarray[this.indicador].BOD_CODIGO).subscribe(er2 => {
              //   if (er2 !== null) {
              //     if (er2[0] !== undefined) {
              //       this.encfacproService.encfacproarray[this.indicador].BOD_NOMBRE = er2[0].BOD_NOMBRE;
              //     }
              //   }
              // });
            }
          }
        }
        //CDPJ
        //this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO = res[0].BOD_CODIGO;
        this.displayDialogBusquedaFast = false;
        this.btnNuevo = true;
        this.btnGuardar = false;
        this.btnBorrar = false;
        this.botonPrimero = true;
        this.botonSiguiente = true;
        this.botonAnterior = true;
        this.botonUltimo = true;
        document.getElementById('bodega').focus();
      });
    } else if (this.opcion === 'BUSQUEDANR') {
      console.log('*-/-*/',opcion.ENCNOTREC_TOTAL)//GSRF
      console.log('*-/-*/',opcion.ENCNOTREC_IVA)//GSRF
      this.totalfaconr = opcion.ENCNOTREC_TOTAL;//GSRF
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO = opcion.ENCNOTREC_NUMERO;
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_IVA = opcion.ENCNOTREC_IVA;
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].MERCONS = opcion.ENCNOTREC_MERCONS;
      this.comencnotrecService.getDetnotRec(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO).subscribe((res) => {
        this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO = res[0].BOD_CODIGO;
        this.displayDialogBusquedaFast = false;
        this.btnNuevo = true;
        this.btnGuardar = false;
        this.btnBorrar = false;
        this.botonPrimero = true;
        this.botonSiguiente = true;
        this.botonAnterior = true;
        this.botonUltimo = true;
        document.getElementById('bodega').focus();
      });
    }
    
    if (this.opcion === 'articulo') {
      this.txtArtCodigo = opcion.ART_CODIGO;
      this.txtArtDescripcion = opcion.ART_NOMBRE;
      this.txtArtUnidad = opcion.ART_UNIDADCOSTEO;
      this.txtExistenciaMin = opcion.ART_CANTMIN === null ? 0 : opcion.ART_CANTMIN;
      this.txtExistenciaMax = opcion.ART_CANTMAX === null ? 0 : opcion.ART_CANTMAX;
      this.displayDialogBusquedaFast = false;
      this.llenarTablaExistencia();
      this.llenarMovByProvArt('fecha');
      this.llenarExistenciaBodegas();
      this.ventasVsCompras();
      this.llenarIndiceProvisiones();
    }
  }
  
  seleccionDetalleDvf(valor) {
    if (valor === 'nuevo') {
      if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO !== '' && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO !== '') {
        this.nuevodetalleDevolucion();
      } else if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO === '') {
        this.message.add({
          key: 'devolucionCom',
          severity: 'error',
          summary: 'Inserción de detalle de la devolución',
          detail: 'Escoja una factura o nota de recepción'
        });
      } else if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO === '') {
        this.message.add({
          key: 'devolucionCom',
          severity: 'error',
          summary: 'Inserción de detalle de la devolución',
          detail: 'Seleccione una bodega'
        });
      }
    } else if (valor === 'eliminar') {
      this.confirmarEliminarDetDev();
    } else if (valor === 'cancelar') {
      this.cancelarDetDVF();
    }
    if (valor.object !== undefined) {
      this.detalleDVFSeleccionado = valor.object;
      if (this.aggrid.gridApi.getFocusedCell().column.colId === 'UNI_CODIGO') {
        if (this.detalleDVFSeleccionado.MULTIUNIDAD === 'S') {
          const opciones = [];
          opciones.push(this.detalleDVFSeleccionado.UNI_CODIGO);
          this.utilitariosService.getUnidadesEqui(this.detalleDVFSeleccionado.UNI_CODIGO).subscribe((res) => {
            if (res !== null) {
              res.map((unidades) => {
                opciones.push(unidades.UNI_CODIGODESTINO);
              });
            }
          });
          this.comDevFacService.detdevFacarray[this.indicador][this.comDevFacService.detdevFacarray[this.indicador].indexOf(this.detalleDVFSeleccionado)].UNI_CODIGO =
            this.detalleDVFSeleccionado.UNI_CODIGO;
          this.columnDefsDVFDetalle[5].cellEditor = 'agSelectCellEditor';
          this.columnDefsDVFDetalle[5].editable = true;
          this.columnDefsDVFDetalle[5].cellEditorParams = {
            values: opciones
          };
          this.aggrid.refreshColumnDefs2(this.columnDefsDVFDetalle);
          this.aggrid.gridApi.setFocusedCell(this.comDevFacService.detdevFacarray[this.indicador].indexOf(this.detalleDVFSeleccionado), 'UNI_CODIGO');
        } else if (this.detalleDVFSeleccionado.MULTIUNIDAD === 'N') {
          const opciones = [];
          opciones.push(this.detalleDVFSeleccionado.UNI_CODIGO);
          this.comDevFacService.detdevFacarray[this.indicador][this.comDevFacService.detdevFacarray[this.indicador].indexOf(this.detalleDVFSeleccionado)].UNI_CODIGO =
            this.detalleDVFSeleccionado.UNI_CODIGO;
          this.columnDefsDVFDetalle[5].cellEditor = 'cellPrueba';
          this.columnDefsDVFDetalle[5].editable = false;
          this.aggrid.refreshColumnDefs2(this.columnDefsDVFDetalle);
          this.aggrid.gridApi.setFocusedCell(this.comDevFacService.detdevFacarray[this.indicador].indexOf(this.detalleDVFSeleccionado), 'UNI_CODIGO');
        }
      }
    }
  }
  
  async nuevodetalleDevolucion() {
    if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO !== '') {
      this.btnNuevoGrid = true;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      const rs = await this.comDevFacService.erBodCodigo1(this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO);
      if (rs === null || rs === undefined || rs === ''){
        this.message.add({
          key: 'devolucionCom',
          severity: 'error',
          summary: 'Ingreso Notas de Recepción',
          detail: 'Error, Código de la bodega '+this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO+ ' es incorrecto'
        });
        return;
      }else{
      const linea = this.comDevFacService.detdevFacarray[this.indicador].length;
      const nuevodetalle = {
        ENCDEV_NUMERO: '', COM_CODIGO: '01', DETDEV_LINEA: 0, DETDEV_TIPODET: this.tipodetalle, BOD_CODIGO:
        this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO, DETDEV_CODIGO: '', DETDEV_DESCRIPCION: '', UNI_CODIGO: '',
        DETDEV_CANTIDAD: 0, DETDEV_COSTO: 0, DETDEV_VALDES: 0, DETDEV_PORDES: 0, DETDEV_TOTAL: 0, DETDEV_PROMOCION: '', DETDEV_PORIVA: Number(this.comDevFacService.dblPorcIvaDevF),
        DETDEV_IVA: 0, DETDEV_TRIBICE: 'N', DETDEV_ICE: 0, DETDEV_PORCEICE: 0, DETDEV_BASEIVA: 0, DETDEV_BASEICE: 0, DETDEV_BASECERO: 0,
        DETDEV_NUMERO: this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO, CEN_CODIGO: '', DETDEV_PORDES2: 0,
        DETDEV_PORDES3: 0, DOC_TIPO: '', DETDEVDOC_LINEA: 0, DETFACPRO_TIPOSRI: '', DETDEV_CAJAS: '', DETDEV_FRACCIONES: '',
        DETDEV_CANTIDADUND: 0, TIPGAS_CODIGO: '', CANTIDAD: 0, LINEA: Number(linea + 1), MULTIUNIDAD: 'N',TARIIVA_CODIGO:''
      };
      if (this.detalleDVFSeleccionado !== undefined && this.detalleDVFSeleccionado !== null) {
        const indi = this.comDevFacService.detdevFacarray[this.indicador].indexOf(this.detalleDVFSeleccionado);
        if (indi === -1) {
          this.comDevFacService.detdevFacarray[this.indicador].push(nuevodetalle);
          this.detalleDVFSeleccionado = this.comDevFacService.detdevFacarray[this.indicador][this.comDevFacService.detdevFacarray[this.indicador].length - 1];
          //CDPJ
        if(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_IVA === '0'){
          this.detalleDVFSeleccionado.TARIIVA_CODIGO='7';
          this.detalleDVFSeleccionado.DETDEV_PORIVA=0;
        }else if(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_IVA === '1'){
          const data =this.confIniciales.srifetariiva.find(element => element.TARIIVA_PORCENTAJE === Number(this.comDevFacService.porceiva))
          if(data !== null && data !== undefined){
            this.detalleDVFSeleccionado.TARIIVA_CODIGO=data.TARIIVA_CODIGO;
            this.detalleDVFSeleccionado.DETDEV_PORIVA=data.TARIIVA_PORCENTAJE;
          }
          }
          if(this.detalleDVFSeleccionado.DETDEV_TIPODET === '*'){
            this.detalleDVFSeleccionado.TARIIVA_CODIGO='-1';
            this.detalleDVFSeleccionado.DETDEV_PORIVA=0;
          }
          //CDPJ
          this.aggrid.refreshaggrid(this.comDevFacService.detdevFacarray[this.indicador], this.defaultColDefDevolucion.objeto);
          this.aggrid.gridApi.setFocusedCell(this.comDevFacService.detdevFacarray[this.indicador].length - 1, 'DETDEV_CODIGO');
        } else {
          this.comDevFacService.detdevFacarray[this.indicador].splice(indi + 1, 0, nuevodetalle);
          //CDPJ
          this.detalleDVFSeleccionado=nuevodetalle
        if(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_IVA === '0'){
          this.detalleDVFSeleccionado.TARIIVA_CODIGO='7';
          this.detalleDVFSeleccionado.DETDEV_PORIVA=0;
        }else if(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_IVA === '1'){
          const data =this.confIniciales.srifetariiva.find(element => element.TARIIVA_PORCENTAJE === Number(this.comDevFacService.porceiva))
          if(data !== null && data !== undefined){
            this.detalleDVFSeleccionado.TARIIVA_CODIGO=data.TARIIVA_CODIGO;
            this.detalleDVFSeleccionado.DETDEV_PORIVA=data.TARIIVA_PORCENTAJE;
          }
          }
          if(this.detalleDVFSeleccionado.DETDEV_TIPODET === '*'){
            this.detalleDVFSeleccionado.TARIIVA_CODIGO='-1';
            this.detalleDVFSeleccionado.DETDEV_PORIVA=0;
          }
          //CDPJ
          this.aggrid.refreshaggridindex(this.comDevFacService.detdevFacarray[this.indicador], this.defaultColDefDevolucion.objeto, indi);
          this.aggrid.gridApi.setFocusedCell(indi + 1, 'DETDEV_CODIGO');
        }
      }
      this.comDevFacService.strTipoIvaarray[this.indicador] = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_IVA;
    }
    } else {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      this.message.add({
        key: 'devolucionCom',
        severity: 'error',
        summary: 'Inserción de detalle de la devolución',
        detail: 'Primero ingrese un proveedor'
      });
      document.getElementById('proveedor').focus();
    }
  }
  
  confirmarEliminarDetDev() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el registro seleccionado',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'devolucionConfCom',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarDetDev();
      },
      reject: () => {
      }
    });
  }
  
  eliminarDetDev() {
    if (this.detalleDVFSeleccionado !== undefined) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      if (this.detalleDVFSeleccionado.DETDEV_LINEA === 0) {
        this.comDevFacService.detdevFacarray[this.indicador] = this.comDevFacService.detdevFacarray[this.indicador].filter((val, j) => j !==
          this.comDevFacService.detdevFacarray[this.indicador].indexOf(this.detalleDVFSeleccionado));
        this.aggrid.refreshaggrid(this.comDevFacService.detdevFacarray[this.indicador], this.defaultColDefDevolucion.objeto);
        this.comDevFacService.calcularTotal();
      } else {
        this.comDevFacService.eliminarDetDVF(this.detalleDVFSeleccionado).subscribe(() => {
          this.comDevFacService.detdevFacarray[this.indicador] = this.comDevFacService.detdevFacarray[this.indicador].filter((val, j) => j !==
            this.comDevFacService.detdevFacarray[this.indicador].indexOf(this.detalleDVFSeleccionado));
          this.aggrid.refreshaggrid(this.comDevFacService.detdevFacarray[this.indicador], this.defaultColDefDevolucion.objeto);
          this.auditoriagral.registrarAuditoria('COM_DETDEV', String(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO), 'E',
            '', '01', '', '', '', '').subscribe(() => {
          });
          this.comDevFacService.calcularTotal();
        });
      }
    }
  }
  
  cancelarDetDVF() {
    if (this.detalleDVFSeleccionado.DETDEV_LINEA === 0) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = true;
      this.comDevFacService.detdevFacarray[this.indicador] = this.comDevFacService.detdevFacarray[this.indicador].filter((val, j) => j !==
        this.comDevFacService.detdevFacarray[this.indicador].indexOf(this.detalleDVFSeleccionado));
      this.detalleDVFSeleccionado = {};
    } else if (this.detalleDVFSeleccionado.DETDEV_LINEA !== 0) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = true;
      this.comDevFacService.getDetDevF(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe((res) => {
        this.comDevFacService.detdevFacarray[this.indicador] = res;
        this.comDevFacService.detdevFacarray[this.indicador].map((detalle) => {
          if (detalle.DETDEV_LINEA === this.detalleDVFSeleccionado.DETDEV_LINEA) {
            this.detalleDVFSeleccionadoAux
              = detalle;
          }
        });
        this.detalleDVFSeleccionado = this.detalleDVFSeleccionadoAux;
        const opcPedido = [];
        opcPedido.push(this.detalleDVFSeleccionado.UNI_CODIGO);
        this.columnDefsDVFDetalle[5].cellEditorParams = {
          values: opcPedido
        };
        this.aggrid.refreshColumnDefs2(this.columnDefsDVFDetalle);
        this.comDevFacService.calcularTotal();
      });
    }
  }
  
  cambio(cambio) {
    if (this.comDevFacService.encDevSeleccionadaarray[this.indicador] !== undefined) {
      if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_ESTADO === 'PENDIENTE') {
        this.btnGuardar = false;
        this.btnRegresar = false;
        this.btnNuevo = true;
        this.btnNuevoGrid = false;
      }
    }
    this.disabledEstadisticas = true;
  }
  
  cambiarFoco(evento) {
    if (evento.target.id === 'comprobante2') {
      if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_REFERENCIA.length < 9 && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_REFERENCIA.length !== 0) {
        do {
          this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_REFERENCIA = '0' + this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_REFERENCIA;
        } while (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_REFERENCIA.length < 9);
        document.getElementById('proveedor').focus();
      } else if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_REFERENCIA.length < 9 && this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_REFERENCIA.length === 0) {
        this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_REFERENCIA = '1';
        do {
          this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_REFERENCIA = '0' + this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_REFERENCIA;
        } while (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_REFERENCIA.length < 9);
        document.getElementById('proveedor').focus();
      }
    } else if (evento.target.id === 'comprobante') {
      document.getElementById('comprobante2').focus();
    } else if (evento.target.id === 'proveedor') {
      this.busquedaSer.encontrarRegistro39(this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO).subscribe((resProv) => {
        if (resProv !== null) {
          this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO = resProv[0].PRO_CODIGO;
          this.nombreProveedor = resProv[0].PRO_NOMBRE;
          this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_IVA = resProv[0].PRO_IVA;
          this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_CONTACTO = resProv[0].PRO_CONTACTO;
          this.comDevFacService.encDevSeleccionadaarray[this.indicador].CON_CODIGO = resProv[0].CON_CODIGO1;//CDPJ
          this.displayDialogBusquedaFast = false;
          this.btnNuevo = true;
          this.btnGuardar = false;
          this.btnBorrar = false;
          this.botonPrimero = true;
          this.botonSiguiente = true;
          this.botonAnterior = true;
          this.botonUltimo = true;
          document.getElementById('comentario').focus();
        } else {
          this.abrirProveedor();
        }
      });
    } else if (evento.target.id === 'bodega') {
      this.busquedaSer.encontrarRegistro40(this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO).subscribe((resBod) => {
          if (resBod !== null) {
            this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO = resBod[0].BOD_CODIGO;
            this.displayDialogBusquedaFast = false;
            this.btnNuevo = true;
            this.btnGuardar = false;
            this.btnBorrar = false;
            this.botonPrimero = true;
            this.botonSiguiente = true;
            this.botonAnterior = true;
            this.botonUltimo = true;
            document.getElementById('autorizacion').focus();
          } else {
            this.abrirBodega();
          }
        }
      );
    } else if (evento.target.id === 'comentario') {
      document.getElementById('facturaNota').focus();
    } else if (evento.target.id === 'facturaNota') {
      if (this.botonFacNR === 'fac') {
        this.tabla = 'COM_ENCFACPRO f, COM_MAEPROVEEDOR p';
        this.consulta = 'f.*, p.PRO_NOMBRE, p.PRO_NOMBREC';
        this.where = 'f.ENCFACPRO_ESTADO != \'A\' AND f.PRO_CODIGO = \'' +
          this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO + '\' AND f.ENCFACPRO_NUMERO = \'' +
          this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO + '\' AND p.PRO_CODIGO = f.PRO_CODIGO';
        this.comDevFacService.buscarEncfacPro(this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO, this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO).subscribe((datos: any[]) => {
          if (datos !== null) {
            let intSW=0; 
            this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO = datos[0].ENCFACPRO_NUMERO;
            this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_IVA = datos[0].ENCFACPRO_IVA;
            this.comencfacService.getDetfacpro(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO).subscribe((res) => {
              //CDPJ
              for (const rs of res){
                if (intSW === 0) {
                  if (rs.BOD_CODIGO === null
                    || rs.BOD_CODIGO === undefined
                    || rs.BOD_CODIGO === '') {
                    intSW = 0;
                  } else {
                    console.log('entro',intSW)
                    this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO = rs.BOD_CODIGO;
                    intSW++;
                    // this.encfacproService.erBodNombre2(this.encfacproService.encfacproarray[this.indicador].BOD_CODIGO).subscribe(er2 => {
                    //   if (er2 !== null) {
                    //     if (er2[0] !== undefined) {
                    //       this.encfacproService.encfacproarray[this.indicador].BOD_NOMBRE = er2[0].BOD_NOMBRE;
                    //     }
                    //   }
                    // });
                  }
                }
              }
              //CDPJ
              //this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO = res[0].BOD_CODIGO;
              this.displayDialogBusquedaFast = false;
              this.btnNuevo = true;
              this.btnGuardar = false;
              this.btnBorrar = false;
              this.botonPrimero = true;
              this.botonSiguiente = true;
              this.botonAnterior = true;
              this.botonUltimo = true;
              document.getElementById('bodega').focus();
            });
          } else {
            this.abrirFacturaNota();
          }
        });
      } else if (this.botonFacNR === 'nr') {
        this.tabla = 'COM_ENCNOTREC r, COM_MAEPROVEEDOR p';
        this.consulta = 'r.*, p.PRO_NOMBRE, p.PRO_NOMBREC';
        this.where = 'r.ENCNOTREC_ESTADO != \'A\' AND r.PRO_CODIGO = \'' +
          this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO + '\' AND r.ENCNOTREC_NUMERO = \'' +
          this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO + '\' AND p.PRO_CODIGO = r.PRO_CODIGO';
        this.comDevFacService.buscarEncNotRec(this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO, this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO).subscribe((datos: any[]) => {
          if (datos !== null) {
            this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO = datos[0].ENCNOTREC_NUMERO;
            this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_IVA = datos[0].ENCNOTREC_IVA;
            this.comDevFacService.encDevSeleccionadaarray[this.indicador].MERCONS = datos[0].ENCNOTREC_MERCONS;
            this.comencnotrecService.getDetnotRec(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO).subscribe((res) => {
              this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO = res[0].BOD_CODIGO;
              this.displayDialogBusquedaFast = false;
              this.btnNuevo = true;
              this.btnGuardar = false;
              this.btnBorrar = false;
              this.botonPrimero = true;
              this.botonSiguiente = true;
              this.botonAnterior = true;
              this.botonUltimo = true;
              document.getElementById('bodega').focus();
            });
          } else {
            this.abrirFacturaNota();
          }
        });
      }
    } else if (evento.target.id === 'autorizacion') {
      this.nuevodetalleDevolucion();
    }
  }
  
  cambiarTipo(valor) {
    this.tipodetalle = valor;
    /* this.comDevFacService.detdevFacarray[this.indicador].map((pedido) => {
      if (pedido.DETDEV_LINEA === this.detalleDVFSeleccionado.DETDEV_LINEA) {
        pedido.DETDEV_TIPODET = valor;
        this.detalleDVFSeleccionado.DETDEV_TIPODET = valor;
      }
    }); */
  }
  
  confirmarGenerarAsiento() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea generar el asiento',
      header: 'Asiento Devolución',
      icon: 'pi pi-exclamation-triangle',
      key: 'devolucionConfCom',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        if (this.botonFacNR === 'fac') {
          console.log(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO);
          this.comDevFacService.generarAsiento(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe(() => {
            this.auditoriagral.registrarAuditoria('COM_ENCDEV', String(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO), 'A',
              '', '01', '', '', '', '').subscribe(() => {
              this.comDevFacService.getAsiento(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe((asi) => {
                this.comDevFacService.encDevSeleccionadaarray[this.indicador].ASI_NRO = asi[0].ASI_NRO;
                this.message.add({
                  key: 'devolucionCom',
                  severity: 'success',
                  summary: 'Asiento Contable',
                  detail: 'Se ha generado el asiento'
                });
              });
            });
          }, error1 => {
            this.message.add({
              key: 'devolucionCom',
              severity: 'error',
              summary: 'Asiento Contable',
              detail: 'No se ha generado el asiento, error ' + error1.error
            });
          });
        } else if (this.botonFacNR === 'nr') {
          if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].MERCONS === 'S') {
            this.comDevFacService.generarAsientoConsig(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe(() => {
              this.auditoriagral.registrarAuditoria('COM_ENCDEV', String(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO), 'A',
                '', '01', '', '', '', '').subscribe(() => {
                this.comDevFacService.getAsiento(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe((asi2) => {
                  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ASI_NRO = asi2[0].ASI_NRO;
                  this.message.add({
                    key: 'devolucionCom',
                    severity: 'success',
                    summary: 'Asiento Contable',
                    detail: 'Se ha generado el asiento'
                  });
                });
              });
            }, error1 => {
              this.message.add({
                key: 'devolucionCom',
                severity: 'error',
                summary: 'Asiento Contable',
                detail: 'No se ha generado el asiento, error ' + error1.error
              });
            });
          } else {
            this.comDevFacService.generarAsientoRecep(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe(() => {
              this.auditoriagral.registrarAuditoria('COM_ENCDEV', String(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO), 'A',
                '', '01', '', '', '', '').subscribe(() => {
                this.comDevFacService.getAsiento(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe((asi3) => {
                  this.comDevFacService.encDevSeleccionadaarray[this.indicador].ASI_NRO = asi3[0].ASI_NRO;
                  this.message.add({
                    key: 'devolucionCom',
                    severity: 'success',
                    summary: 'Asiento Contable',
                    detail: 'Se ha generado el asiento'
                  });
                });
              });
            });
          }
        }
      },
      reject: () => {
      }
    });
  }
  
  aplicarDescuento() {
    let tipo = '';
    if (this.booleanValor === false) {
      if (this.dscClienteActual < (this.valorDscValor * 100 / Number(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_TOTAL))) {
        tipo = 'valor';
        this.confirmarDescuento(tipo);
      } else {
        tipo = 'valor';
        this.descuentoAplicado(tipo);
      }
    }
    if (this.booleanPorcentaje === false) {
      if (this.dscClienteActual < this.valorDscPorcentaje) {
        tipo = 'porcentaje';
        this.confirmarDescuento(tipo);
      } else {
        tipo = 'porcentaje';
        this.descuentoAplicado(tipo);
      }
    }
  }
  
  cambioDescuento() {
    if (this.dscSeleccionado === 'dscValor') {
      this.booleanValor = false;
      this.booleanPorcentaje = true;
    } else if (this.dscSeleccionado === 'dscPorcentaje') {
      this.booleanPorcentaje = false;
      this.booleanValor = true;
    }
  }
  
  comprobarValor() {
    if (this.booleanValor === false) {
      this.valorDscPorcentaje = Number(this.valorDscValor * 100) / Number(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_TOTALNETO);
      this.valorDscPorcentaje = Number(this.valorDscPorcentaje.toFixed(2));
      this.valorInvalidoV = this.valorDscValor > Number(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_TOTALNETO);
    } else if (this.booleanPorcentaje === false) {
      this.valorDscValor = Number(this.valorDscPorcentaje * this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_TOTALNETO) / 100;
      this.valorDscValor = Number(this.valorDscValor.toFixed(2));
      this.valorInvalidoP = this.valorDscPorcentaje > 100;
    }
  }
  
  confirmarDescuento(tipo) {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea aplicar un descuento mayor',
      header: 'El cliente tiene un descuento menor',
      icon: 'pi pi-exclamation-triangle',
      key: 'devolucionConfCom',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.descuentoAplicado(tipo);
      },
      reject: () => {
      }
    });
  }
  
  descuentoAplicado(tipo) {
    this.displayDialogDescuento = false;
    this.btnGuardar = false;
    this.btnNuevo = true;
    if (tipo === 'valor') {
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_VALORDES = this.valorDscValor;
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_PORCEDES = (this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_VALORDES
        * 100) / this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_TOTALNETO;
      this.valorDscPorcentaje = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_PORCEDES;
      this.comDevFacService.dblPorcDescGlbDevFCarray[this.indicador] = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_PORCEDES;
      this.comDevFacService.dblValorDescGlbDevFCarray[this.indicador] = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_VALORDES;
      this.comDevFacService.calcularTotal();
    } else if (tipo === 'porcentaje') {
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_PORCEDES = this.valorDscPorcentaje;
      this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_VALORDES = Number(
        this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_TOTALNETO * this.valorDscPorcentaje / 100);
      this.valorDscValor = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_VALORDES;
      this.comDevFacService.dblPorcDescGlbDevFCarray[this.indicador] = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_PORCEDES;
      this.comDevFacService.dblValorDescGlbDevFCarray[this.indicador] = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_VALORDES;
      this.comDevFacService.calcularTotal();
    }
  }
  
  async abrirDetalleFactura() {
    //CDPJ
    const rs = await this.comDevFacService.erBodCodigo1(this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO);
    //CDPJ
    if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO.length !== 0) {
      if (rs === null || rs === undefined || rs === ''){
        this.message.add({
          key: 'devolucionCom',
          severity: 'error',
          summary: 'Ingreso Notas de Recepción',
          detail: 'Error, Código de la bodega '+this.comDevFacService.encDevSeleccionadaarray[this.indicador].BOD_CODIGO+ ' es incorrecto'
        });
        document.getElementById('bodega').focus();
      return;
      }else{
        this.displayDialogDetFac = true;
      }
    }
   
  }
  
  recibirSenales(valor) {
    if (valor === true) {
      this.displayDialogDetFac = false;
    }
  }
  
  cerrarReportes() {
    this.displayDialogReportes = false;
  }
  
  async recibirDetalles(valores) {
    console.log('aqui/*/**/',valores)
    let devdetalle: Comdetdevfac;
    let devdetalles: Comdetdevfac[];
    let tipodes:string='';
    let valordes:number;
    let porcedes:number;
    devdetalles = [];
    devdetalle = {};
    if (this.botonFacNR === 'fac') {
      if (valores !== null) {
        const data = await this.comDevFacService.getEncfacproProm(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO);
        for (const rs of data) {
          this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_PORCEIVA=rs.ENCFACPRO_PORCEIVA;
          tipodes=rs.ENCFACPRO_TIPODES === null || rs.ENCFACPRO_TIPODES === undefined?'':rs.ENCFACPRO_TIPODES;
          valordes=rs.ENCFACPRO_VALORDES === null || rs.ENCFACPRO_VALORDES === undefined?0:rs.ENCFACPRO_VALORDES;
          porcedes=rs.ENCFACPRO_PORCEDES=== null || rs.ENCFACPRO_PORCEDES === undefined?0:rs.ENCFACPRO_PORCEDES;

        }
        valores.map((valor) => {
          devdetalle.BOD_CODIGO = valor.BOD_CODIGO;
          devdetalle.DETDEV_CODIGO = valor.DETFACPRO_CODIGO;
          devdetalle.DETDEV_DESCRIPCION = valor.DETFACPRO_DESCRIPCION;
          devdetalle.DETDEV_PORIVA = valor.DETFACPRO_PORIVA
          devdetalle.DETDEV_TRIBICE = valor.DETFACPRO_TRIBICE;
          devdetalle.UNI_CODIGO = valor.DETFACPRO_UNIDAD;
          devdetalle.DETDEV_CANTIDAD = valor.DETFACPRO_CANTIDAD;
          devdetalle.CANTIDAD = valor.DETFACPRO_CANTIDAD;
          devdetalle.DETDEV_COSTO = valor.DETFACPRO_COSTO;
          devdetalle.DETDEV_TOTAL = valor.DETFACPRO_TOTAL;
          devdetalle.DETDEV_PORDES = valor.DETFACPRO_PORDES;
          devdetalle.DETDEV_PORDES2 = valor.DETFACPRO_PORDES2;
          devdetalle.DETDEV_PORDES3 = valor.DETFACPRO_PORDES3;
          devdetalle.DETDEV_TIPODET = valor.DETFACPRO_TIPODET;
          devdetalle.DETDEV_LINEA = 0;
          devdetalle.LINEA = valor.DETFACPRO_LINEA;
          devdetalle.DETDEVDOC_LINEA = valor.DETORDCOM_LINEA;
          devdetalle.CEN_CODIGO = valor.CEN_CODIGO === undefined || valor.CEN_CODIGO === null ? '': valor.CEN_CODIGO;//CDPJ
          devdetalle.DETDEV_CANTIDADUND = 0;
          devdetalle.TIPGAS_CODIGO = '';
          devdetalle.ENCDEV_NUMERO = '';
          devdetalle.DETDEV_IVA = valor.DETFACPRO_IVA;
          devdetalle.DETDEV_ICE = valor.DETFACPRO_ICE;
          devdetalle.DETDEV_PORCEICE = valor.DETFACPRO_PORCEICE;
          devdetalle.COM_CODIGO = '01';
          devdetalle.DETDEV_NUMERO = valor.ENCFACPRO_NUMERO;
          devdetalle.DETDEV_BASEIVA = valor.DETFACPRO_BASEIVA;
          devdetalle.DETDEV_BASEICE = valor.DETFACPRO_BASEICE;
          devdetalle.DETDEV_BASECERO = valor.DETFACPRO_BASECERO;
          devdetalle.DETDEV_PROMOCION = '';
          devdetalle.DOC_TIPO = '';
          devdetalle.DETFACPRO_TIPOSRI = valor.DETFACPRO_TIPOSRI;
          devdetalle.DETDEV_CAJAS = '';
          devdetalle.DETDEV_FRACCIONES = '';
          devdetalle.DETDEV_VALDES = valor.DETFACPRO_VALDES;
          devdetalle.COSTOBLOQ = true;
          devdetalle.TARIIVA_CODIGO=valor.TARIIVA_CODIGO;//CDPJ
          devdetalles.push(devdetalle);
          devdetalle = {};
        });
        this.comDevFacService.detdevFacarray[this.indicador] = devdetalles;
        this.aggrid.refreshaggrid(this.comDevFacService.detdevFacarray[this.indicador], this.defaultColDefDevolucion.objeto);
        this.comDevFacService.strTipoIvaarray[this.indicador] = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_IVA;
        this.comDevFacService.calcularTotal();
        //CDPJ
        console.log('tipodes',tipodes)
        if(tipodes !== ''){
          if(tipodes === 'P'){
            this.valorDscPorcentaje=porcedes;
            this.descuentoAplicado('porcentaje')  
          }else if(tipodes === 'V'){
            this.valorDscValor=valordes;
            this.descuentoAplicado('valor')       
          }
        }
        
        //CDPJ
        this.displayDialogDetFac = false;
      }
    } else if (this.botonFacNR === 'nr') {
      if (valores !== null) {
        const data = await this.comDevFacService.getEncnotrecProm(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_DETDEVNUMERO);
        for (const rs of data) {
          this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_PORCEIVA=rs.ENCNOTREC_PORCEIVA;
          tipodes=rs.ENCNOTREC_TIPODES === null || rs.ENCNOTREC_TIPODES === undefined?'':rs.ENCNOTREC_TIPODES;
          valordes=rs.ENCNOTREC_VALORDES === null || rs.ENCNOTREC_VALORDES === undefined?0:rs.ENCNOTREC_VALORDES;
          porcedes=rs.ENCNOTREC_PORCEDES=== null || rs.ENCNOTREC_PORCEDES === undefined?0:rs.ENCNOTREC_PORCEDES;

        }
        valores.map((valor) => {
          devdetalle.BOD_CODIGO = valor.BOD_CODIGO;
          devdetalle.DETDEV_CODIGO = valor.DETNOTREC_CODIGO === null || valor.DETNOTREC_CODIGO === undefined?'':valor.DETNOTREC_CODIGO;
          devdetalle.DETDEV_DESCRIPCION = valor.DETNOTREC_DESCRIPCION;
          if (valor.DETNOTREC_TRIBIVA === 'S') {
            devdetalle.DETDEV_PORIVA = valor.DETNOTREC_PORIVA;
          } else if (valor.DETNOTREC_TRIBIVA === 'N') {
            devdetalle.DETDEV_PORIVA = 0;
          }else if(valor.DETNOTREC_TIPODET === '*'){
            devdetalle.DETDEV_PORIVA = 0;
          }
          devdetalle.DETDEV_TRIBICE = valor.DETNOTREC_TRIBICE;
          devdetalle.UNI_CODIGO = valor.DETNOTREC_UNIDAD;
          devdetalle.DETDEV_CANTIDAD = valor.DETNOTREC_CANTIDAD;
          devdetalle.CANTIDAD = valor.DETNOTREC_CANTIDAD;
          devdetalle.DETDEV_COSTO = valor.DETNOTREC_COSTO;
          devdetalle.DETDEV_TOTAL = valor.DETNOTREC_TOTAL;
          devdetalle.DETDEV_PORDES = valor.DETNOTREC_DESCUENTO;
          devdetalle.DETDEV_PORDES2 = valor.DETNOTREC_DESCUENTO2;
          devdetalle.DETDEV_PORDES3 = valor.DETNOTREC_DESCUENTO3;
          devdetalle.DETDEV_TIPODET = valor.DETNOTREC_TIPODET;
          devdetalle.DETDEV_LINEA = 0;
          devdetalle.LINEA = valor.DETNOTREC_LINEA;
          devdetalle.DETDEVDOC_LINEA = valor.DETORDCOM_LINEA;
          devdetalle.CEN_CODIGO = valor.CEN_CODIGO === undefined || valor.CEN_CODIGO === null ? '': valor.CEN_CODIGO;//CDPJ
          devdetalle.DETDEV_CANTIDADUND = 0;
          devdetalle.TIPGAS_CODIGO = '';
          devdetalle.ENCDEV_NUMERO = '';
          devdetalle.DETDEV_IVA = valor.DETNOTREC_IVA;
          devdetalle.DETDEV_ICE = valor.DETNOTREC_ICE;
          devdetalle.DETDEV_PORCEICE = valor.DETNOTREC_PORCEICE;
          devdetalle.COM_CODIGO = '01';
          devdetalle.DETDEV_NUMERO = valor.ENCNOTREC_NUMERO;
          devdetalle.DETDEV_BASEIVA = valor.DETNOTREC_BASEIVA;
          devdetalle.DETDEV_BASEICE = valor.DETNOTREC_BASEICE;
          devdetalle.DETDEV_BASECERO = valor.DETNOTREC_BASECERO;
          devdetalle.DETDEV_PROMOCION = '';
          devdetalle.DOC_TIPO = '';
          devdetalle.DETFACPRO_TIPOSRI = '';
          devdetalle.DETDEV_CAJAS = '';
          devdetalle.DETDEV_FRACCIONES = '';
          devdetalle.DETDEV_VALDES = valor.DETNOTREC_VALORDES;
          devdetalles.push(devdetalle);
          devdetalle.TARIIVA_CODIGO=valor.TARIIVA_CODIGO;//CDPJ
          devdetalle = {};
        });
        this.comDevFacService.detdevFacarray[this.indicador] = devdetalles;
        this.aggrid.refreshaggrid(this.comDevFacService.detdevFacarray[this.indicador], this.defaultColDefDevolucion.objeto);
       this.comDevFacService.strTipoIvaarray[this.indicador] = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_IVA;
        this.comDevFacService.calcularTotal();
         //CDPJ
         console.log('tipodes',tipodes)
         if(tipodes !== ''){
           if(tipodes === 'P'){
             this.valorDscPorcentaje=porcedes;
             this.descuentoAplicado('porcentaje')  
           }else if(tipodes === 'V'){
             this.valorDscValor=valordes;
             this.descuentoAplicado('valor')       
           }
         }
         
         //CDPJ
        this.displayDialogDetFac = false;
      }
    }
  }
  
  acciones(valor) {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 0; i < elemento.length; i++) {
        elemento[i].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 0; i < elemento.length; i++) {
        elemento[i].className = 'layout-config';
      }
    }
  }
  
  abrirSeriales() {
    this.cerrarSideBar();
    this.displayDialogSeriales = true;
  }
  
  cerrarSideBar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 0; i < elemento.length; i++) {
      elemento[i].className = 'layout-config';
    }
  }
 
  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }
  
  mostrarEstadisticas() {
    this.comprasRealizadas();
    this.topProductos();
  }
  
  async comprasRealizadas() {
    const fechaInicial = new Date();
    fechaInicial.setMonth(fechaInicial.getMonth() - 12);
    fechaInicial.setDate(1);
    
    const fechaAct = new Date();
    const rangoFechas: any[] = [];
    
    for (let i = 1; fechaInicial <= fechaAct; i++) {
      rangoFechas.push(this.datePipe.transform(fechaInicial, 'dd/MM/yyyy'));
      fechaInicial.setMonth(fechaInicial.getMonth() + 1);
    }
    
    try {
  
      const rs = await this.venEstadisticasService.comprasAcumuladas(this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO);
      
      
      if (rs !== null) {
        
        const index1 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[0]);
        const valor1 = index1 >= 0 ? rs[index1].VENTA_TOTAL : 0;
        const index2 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[1]);
        const valor2 = index2 >= 0 ? rs[index2].VENTA_TOTAL : 0;
        const index3 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[2]);
        const valor3 = index3 >= 0 ? rs[index3].VENTA_TOTAL : 0;
        const index4 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[3]);
        const valor4 = index4 >= 0 ? rs[index4].VENTA_TOTAL : 0;
        const index5 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[4]);
        const valor5 = index5 >= 0 ? rs[index5].VENTA_TOTAL : 0;
        const index6 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[5]);
        const valor6 = index6 >= 0 ? rs[index6].VENTA_TOTAL : 0;
        const index7 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[6]);
        const valor7 = index7 >= 0 ? rs[index7].VENTA_TOTAL : 0;
        const index8 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[7]);
        const valor8 = index8 >= 0 ? rs[index8].VENTA_TOTAL : 0;
        const index9 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[8]);
        const valor9 = index9 >= 0 ? rs[index9].VENTA_TOTAL : 0;
        const index10 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[9]);
        const valor10 = index10 >= 0 ? rs[index10].VENTA_TOTAL : 0;
        const index11 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[10]);
        const valor11 = index11 >= 0 ? rs[index11].VENTA_TOTAL : 0;
        const index12 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[11]);
        const valor12 = index12 >= 0 ? rs[index12].VENTA_TOTAL : 0;
        const index13 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[12]);
        const valor13 = index13 >= 0 ? rs[index13].VENTA_TOTAL : 0;
        
        this.dataComprasProveedor = {
          labels: [
            String(rangoFechas[0]).substring(8, 10) + '/' + String(rangoFechas[0]).substring(3, 5),
            String(rangoFechas[1]).substring(8, 10) + '/' + String(rangoFechas[1]).substring(3, 5),
            String(rangoFechas[2]).substring(8, 10) + '/' + String(rangoFechas[2]).substring(3, 5),
            String(rangoFechas[3]).substring(8, 10) + '/' + String(rangoFechas[3]).substring(3, 5),
            String(rangoFechas[4]).substring(8, 10) + '/' + String(rangoFechas[4]).substring(3, 5),
            String(rangoFechas[5]).substring(8, 10) + '/' + String(rangoFechas[5]).substring(3, 5),
            String(rangoFechas[6]).substring(8, 10) + '/' + String(rangoFechas[6]).substring(3, 5),
            String(rangoFechas[7]).substring(8, 10) + '/' + String(rangoFechas[7]).substring(3, 5),
            String(rangoFechas[8]).substring(8, 10) + '/' + String(rangoFechas[8]).substring(3, 5),
            String(rangoFechas[9]).substring(8, 10) + '/' + String(rangoFechas[9]).substring(3, 5),
            String(rangoFechas[10]).substring(8, 10) + '/' + String(rangoFechas[10]).substring(3, 5),
            String(rangoFechas[11]).substring(8, 10) + '/' + String(rangoFechas[11]).substring(3, 5),
            String(rangoFechas[12]).substring(8, 10) + '/' + String(rangoFechas[12]).substring(3, 5),
          ],
          datasets: [{
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [valor1, valor2, valor3, valor4, valor5, valor6, valor7,
              valor8, valor9, valor10, valor11, valor12, valor13]
          }
          ]
        };
        
      } else {
        this.dataComprasProveedor = {
          labels: [
            String(rangoFechas[0]).substring(8, 10) + '/' + String(rangoFechas[0]).substring(3, 5),
            String(rangoFechas[1]).substring(8, 10) + '/' + String(rangoFechas[1]).substring(3, 5),
            String(rangoFechas[2]).substring(8, 10) + '/' + String(rangoFechas[2]).substring(3, 5),
            String(rangoFechas[3]).substring(8, 10) + '/' + String(rangoFechas[3]).substring(3, 5),
            String(rangoFechas[4]).substring(8, 10) + '/' + String(rangoFechas[4]).substring(3, 5),
            String(rangoFechas[5]).substring(8, 10) + '/' + String(rangoFechas[5]).substring(3, 5),
            String(rangoFechas[6]).substring(8, 10) + '/' + String(rangoFechas[6]).substring(3, 5),
            String(rangoFechas[7]).substring(8, 10) + '/' + String(rangoFechas[7]).substring(3, 5),
            String(rangoFechas[8]).substring(8, 10) + '/' + String(rangoFechas[8]).substring(3, 5),
            String(rangoFechas[9]).substring(8, 10) + '/' + String(rangoFechas[9]).substring(3, 5),
            String(rangoFechas[10]).substring(8, 10) + '/' + String(rangoFechas[10]).substring(3, 5),
            String(rangoFechas[11]).substring(8, 10) + '/' + String(rangoFechas[11]).substring(3, 5),
            String(rangoFechas[12]).substring(8, 10) + '/' + String(rangoFechas[12]).substring(3, 5),
          ],
          datasets: [{
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
          ]
        };
      }
    } catch (err) {
    
    }
  }
  
  async topProductos() {
    this.detTopProductos = [];
    let totalCantidad = 0;
    let totalPrecio = 0;
    let total = 0;
    const fechaActual = new Date();
    const strFecha = this.datePipe.transform(fechaActual, 'dd/MM/yyyy');
    const time = new Date();
    const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
    const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
    const strHora = hora + ':' + minutos;
    
    const detalle: ComTopProductos[] = [];
    
    
    try {
      const rs = await this.venEstadisticasService.topProductos(this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO, 'cantidad');
      if (rs !== null) {
        for (const item of rs) {
          const det: ComTopProductos = {};
          det.DETFACPRO_CODIGO = item.DETFACPRO_CODIGO;
          det.DETFACPRO_DESCRIPCION = item.DETFACPRO_DESCRIPCION;
          det.CANTIDAD_TOTAL = Number(item.CANTIDAD_TOTAL).toFixed(2);
          totalCantidad = totalCantidad + Number(item.CANTIDAD_TOTAL);
          det.DETFACPRO_COSTO = Number(item.DETFACPRO_COSTO).toFixed(2);
          totalPrecio = totalPrecio + Number(item.DETFACPRO_COSTO);
          det.TOTAL = Number(item.TOTAL).toFixed(2);
          total = total + Number(item.TOTAL);
          det.STOCK = Number(item.STOCK).toFixed(2);
          detalle.push(det);
        }
        const totales: ComTopProductos = {};
        totales.DETFACPRO_CODIGO = '';
        totales.DETFACPRO_DESCRIPCION = 'TOTAL';
        totales.CANTIDAD_TOTAL = Number(totalCantidad).toFixed(2);
        totales.DETFACPRO_COSTO = Number(totalPrecio).toFixed(2);
        totales.TOTAL = Number(total).toFixed(2);
        totales.STOCK = '';
        detalle.push(totales);
        
        this.detTopProductos = detalle;
      }
      
    } catch (err) {
    
    }
  }
  
  async changeTab() {
    if (this.activeIndex === 1) {
      this.bolestcta = false
      this.bolsaldo = false
    }
    if (this.activeIndex === 1) {
      if (this.bolestcta === false) {
        this.cxctmptrnresumen = [];
        this.bolestcta = true;
        this.cxctmptrnresumen = await this.proveedorServicio.llenarEstadoCuenta(this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO);
      }
      this.bolsaldo = false
    }
    if (this.activeIndex === 2) {
      if (this.bolsaldo === false) {
        this.cxctrnresumen = [];
        this.bolsaldo = true;
        this.cxctrnresumen = await this.proveedorServicio.llenarTablaSaldos(this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO);
      }
      this.bolestcta = false
    }
    
    if (this.activeIndex === 3) {
      this.bolestcta = false
      this.bolsaldo = false
      this.dataComprasProveedor = {
        labels: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
        datasets: [{
          label: 'Ventas',
          backgroundColor: '#42A5F5',
          borderColor: '#1E88E5',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
        ]
      };
      
      this.dataVentasVsCompras = {
        labels: ['', ''],
        datasets: [
          {
            label: 'Ventas',
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [0]
          },
          {
            label: 'Compras',
            backgroundColor: '#9CCC65',
            borderColor: '#7CB342',
            data: [0]
          }
        ]
      };
      
      this.detTopProductos = [];
      this.detalleExistencia = [];
      this.detMovimientosProv = [];
      this.detExistenciaBodegas = [];
      this.txtArtCodigo = '';
      this.txtArtDescripcion = '';
      this.txtExistenciaMax = '';
      this.txtExistenciaMin = '';
      this.txtArtUnidad = '';
      this.txtArtRotacion = '';
      this.txtInventarioIni = '';
      this.txtComprasPeriodo = '';
      this.txtTotalDisponible = '';
      this.txtVenUltimoPer = '';
      this.txtVenPromPerAnt = '';
      this.txtIndiceProvUltPer = '';
      this.txtIndiceProvPeriodos = '';
      this.txtComprasPlanificadas = '';
      this.txtTotalDisponibleCom = '';
      this.txtIndiceProvUltPer2 = '';
      this.txtIndiceProvPeriodos2 = '';
      
      const existencias: ComExistencias = {};
      existencias.COMPRAR = '';
      existencias.EXIST_MIN = '';
      existencias.EXISTENCIA = '';
      existencias.ORDENES_COMPRA = '';
      existencias.PEDIDOS_CLIENTES = '';
      existencias.SALDO = '';
      existencias.TOTAL_COMPRA = '';
      existencias.ULTCOSTO_COM = '';
      this.detalleExistencia.push(existencias);
      
    }
  }
  
  async orderByTopProd(tipo) {
    this.detTopProductos = [];
    let totalCantidad = 0;
    let totalPrecio = 0;
    let total = 0;
    const fechaActual = new Date();
    const strFecha = this.datePipe.transform(fechaActual, 'dd/MM/yyyy');
    const time = new Date();
    const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
    const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
    const strHora = hora + ':' + minutos;
    
    const detalle: ComTopProductos[] = [];
    let rs;
    
    try {
      if (tipo === 'total') {
        rs = await this.venEstadisticasService.topProductos(this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO, 'total');
      }
  
      if (tipo === 'cantidad') {
        rs = await this.venEstadisticasService.topProductos(this.comDevFacService.encDevSeleccionadaarray[this.indicador].PRO_CODIGO, 'cantidad');
      }
      if (rs !== null) {
        for (const item of rs) {
          const det: ComTopProductos = {};
          det.DETFACPRO_CODIGO = item.DETFACPRO_CODIGO;
          det.DETFACPRO_DESCRIPCION = item.DETFACPRO_DESCRIPCION;
          det.CANTIDAD_TOTAL = Number(item.CANTIDAD_TOTAL).toFixed(2);
          totalCantidad = totalCantidad + Number(item.CANTIDAD_TOTAL);
          det.DETFACPRO_COSTO = Number(item.DETFACPRO_COSTO).toFixed(2);
          totalPrecio = totalPrecio + Number(item.DETFACPRO_COSTO);
          det.TOTAL = Number(item.TOTAL).toFixed(2);
          total = total + Number(item.TOTAL);
          det.STOCK = Number(item.STOCK).toFixed(2);
          detalle.push(det);
        }
        const totales: ComTopProductos = {};
        totales.DETFACPRO_CODIGO = '';
        totales.DETFACPRO_DESCRIPCION = 'TOTAL';
        totales.CANTIDAD_TOTAL = Number(totalCantidad).toFixed(2);
        totales.DETFACPRO_COSTO = Number(totalPrecio).toFixed(2);
        totales.TOTAL = Number(total).toFixed(2);
        totales.STOCK = '';
        detalle.push(totales);
        
        this.detTopProductos = detalle;
      }
      
    } catch (err) {
    
    }
  }
  
  buscarArticulo() {
    this.detalleExistencia = [];
    this.detMovimientosProv = [];
    this.detExistenciaBodegas = [];
    
    this.txtArtCodigo = '';
    this.txtArtDescripcion = '';
    this.txtExistenciaMax = '';
    this.txtExistenciaMin = '';
    this.txtArtUnidad = '';
    this.txtArtRotacion = '';
    this.txtInventarioIni = '';
    this.txtComprasPeriodo = '';
    this.txtTotalDisponible = '';
    this.txtVenUltimoPer = '';
    this.txtVenPromPerAnt = '';
    this.txtIndiceProvUltPer = '';
    this.txtIndiceProvPeriodos = '';
    this.txtComprasPlanificadas = '';
    this.txtTotalDisponibleCom = '';
    this.txtIndiceProvUltPer2 = '';
    this.txtIndiceProvPeriodos2 = '';
    
    const existencias: ComExistencias = {};
    existencias.COMPRAR = '';
    existencias.EXIST_MIN = '';
    existencias.EXISTENCIA = '';
    existencias.ORDENES_COMPRA = '';
    existencias.PEDIDOS_CLIENTES = '';
    existencias.SALDO = '';
    existencias.TOTAL_COMPRA = '';
    existencias.ULTCOSTO_COM = '';
    this.detalleExistencia.push(existencias);
    
    const grafico = {
      labels: ['', ''],
      datasets: [
        {
          label: 'Ventas',
          backgroundColor: '#42A5F5',
          borderColor: '#1E88E5',
          data: [0]
        },
        {
          label: 'Compras',
          backgroundColor: '#9CCC65',
          borderColor: '#7CB342',
          data: [0]
        }
      ]
    };
    
    this.dataVentasVsCompras = grafico;
    
    
    this.opcion = 'articulo';
    this.types = [
      {label: 'Código', value: 'ART_CODIGO'},
      {label: 'Nombre', value: 'ART_NOMBRE'},
      {label: 'Grupo', value: 'GRUP_CODIGO'},
      {label: 'NombreCorto', value: 'ART_NOMBREC'},
      {label: 'Laboratorio', value: 'ART_LABORATORIO'},
      {label: 'Ubicación', value: 'ART_UBICACION'},
      {label: 'CodigoBarras', value: 'ART_CODIGOALT1'},
      {label: 'Alterno3', value: 'ART_CODIGOALT3'},
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'INV_MAEARTICULO';
    this.where = 'ART_ACTIVO=\'S\'';
    
    this.busquedaSer.busquedaInvMaeArticulo1().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }
  
  async llenarMovByProvArt(tipo) {
    this.detMovimientosProv = [];
    
    const detalle: ComMovimientoProv[] = [];
    let rs;
    
    try {
      if (tipo === 'fecha') {
        rs = await this.venEstadisticasService.movimientoArtProv(this.txtArtCodigo, 'fecha');
      }
  
      if (tipo === 'costo') {
        rs = await this.venEstadisticasService.movimientoArtProv(this.txtArtCodigo, 'costo');
      }
      if (rs !== null) {
        for (const item of rs) {
          const det: ComMovimientoProv = {};
          det.PRO_CODIGO = item.PRO_CODIGO;
          det.PRO_NOMBRE = item.PRO_NOMBRE;
          det.ENCFACPRO_FECHAEMISION = this.datePipe.transform(item.ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
          det.DETFACPRO_COSTO = Number(item.DETFACPRO_COSTO).toFixed(2);
          
          
          detalle.push(det);
        }
        this.detMovimientosProv = detalle;
      }
      
    } catch (err) {
    
    }
  }
  
  async llenarTablaExistencia() {
    let rs1;
    let rs2;
    let rs3;
    let rs4;
    
    try {
      rs1 = this.venEstadisticasService.ultimoCostoCompra(this.txtArtCodigo);
      rs2 = this.venEstadisticasService.existenciaArticulo(this.txtArtCodigo);
      rs3 = this.venEstadisticasService.cantidadOrdCompra(this.txtArtCodigo);
      rs4 = this.venEstadisticasService.cantidadPedidos(this.txtArtCodigo);
    } catch (err) {
    
    }
    
    rs1 = await rs1;
    rs2 = await rs2;
    rs3 = await rs3;
    rs4 = await rs4;
    
    this.detalleExistencia = [];
    const existencias: ComExistencias = {};
    existencias.ULTCOSTO_COM = Number(rs1[0].ULTIMO_COSTO).toFixed(2);
    existencias.EXISTENCIA = Number(rs2[0].EXISTENCIA);
    existencias.ORDENES_COMPRA = Number(rs3[0].TOTAL_ORDCOM);
    existencias.EXIST_MIN = this.txtExistenciaMin;
    existencias.PEDIDOS_CLIENTES = Number(rs4[0].TOTAL_PEDIDOS);
    existencias.SALDO = Number(rs2[0].EXISTENCIA) + Number(rs3[0].TOTAL_ORDCOM) - Number(rs4[0].TOTAL_PEDIDOS);
    existencias.COMPRAR = existencias.SALDO < Number(this.txtExistenciaMin) ? Math.abs(existencias.SALDO) + Number(this.txtExistenciaMin) : 0;
    existencias.TOTAL_COMPRA = this.init.moneyValidation(Number(existencias.COMPRAR * Number(existencias.ULTCOSTO_COM)).toFixed(2));
    
    
    this.detalleExistencia.push(existencias);
  }
  
  async llenarExistenciaBodegas() {
    this.detExistenciaBodegas = [];
    const detalle: ComExistenciaBodegas[] = [];
    
    const fechaActual = new Date();
    const strFecha = this.datePipe.transform(fechaActual, 'dd/MM/yyyy');
    const time = new Date();
    const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
    const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
    const strHora = hora + ':' + minutos;
    
    try {
      const rs = await this.venEstadisticasService.llenarExistenciaBod(this.txtArtCodigo);
      if (rs !== null) {
        for (const item of rs) {
          const det: ComExistenciaBodegas = {};
          det.BOD_CODIGO = item.BOD_CODIGO;
          det.BOD_NOMBRE = item.BOD_NOMBRE;
          det.EXISTENCIA = Number(item.EXISTENCIA).toFixed(2);
          det.PEDIDO = Number(item.PEDIDOS).toFixed(2);
          det.SALDO = Number(item.SALDO).toFixed(2);
          
          
          detalle.push(det);
        }
        this.detExistenciaBodegas = detalle;
      }
      
    } catch (err) {
    
    }
  }
  
  async ventasVsCompras() {
    
    let rs1;
    let rs2;
    
    try {
  
      rs1 = this.venEstadisticasService.ventasVsCompras(this.txtArtCodigo, 'V');
      rs2 = this.venEstadisticasService.ventasVsCompras(this.txtArtCodigo, 'C');
      
    } catch (err) {
    
    }
    rs1 = await rs1;
    rs2 = await rs2;
    
    const grafico = {
      labels: ['', ''],
      datasets: [
        {
          label: 'Ventas',
          backgroundColor: '#42A5F5',
          borderColor: '#1E88E5',
          data: [rs1[0].TOTALVENTAS]
        },
        {
          label: 'Compras',
          backgroundColor: '#9CCC65',
          borderColor: '#7CB342',
          data: [rs2[0].TOTALCOMPRAS]
        }
      ]
    };
    
    this.dataVentasVsCompras = grafico;
    
  }
  
  async llenarIndiceProvisiones() {
    
    let rs1;
    let rs2;
    let rs3;
    let rs4;
    
    try {
      rs1 = this.venEstadisticasService.obtenerExistenciaArtIndProv(this.txtArtCodigo);
      rs2 = this.venEstadisticasService.comprasPeriodo(this.txtArtCodigo);
      rs3 = this.venEstadisticasService.comprasUltPeriodo(this.txtArtCodigo);
      rs4 = this.venEstadisticasService.ventasPeriodo(this.txtArtCodigo);
    } catch (err) {
    
    }
    
    rs1 = await rs1;
    rs2 = await rs2;
    rs3 = await rs3;
    rs4 = await rs4;
    
    this.txtInventarioIni = Number(rs1[0].EXISTENCIA_ACTUAL).toFixed(2);
    this.txtComprasPeriodo = Number(rs2[0].COMPRAS_PERIODO).toFixed(2);
    this.txtTotalDisponible = Number(rs1[0].EXISTENCIA_ACTUAL + rs2[0].COMPRAS_PERIODO).toFixed(2);
    
    this.txtVenUltimoPer = Number(rs3[0].VENTAS_ULTIMOPER).toFixed(2);
    this.txtVenPromPerAnt = Number(rs4[0].VENTAS_PERIODOS / 10).toFixed(2);
    
    
    this.txtIndiceProvUltPer = Number(this.txtVenUltimoPer) !== 0 ? Number(this.txtTotalDisponible) / Number(this.txtVenUltimoPer) :
      0;
    this.txtIndiceProvUltPer = Number(this.txtIndiceProvUltPer).toFixed(2);
    this.txtIndiceProvPeriodos = Number(this.txtVenPromPerAnt) !== 0 ? Number(this.txtTotalDisponible) / Number(this.txtVenPromPerAnt) :
      0;
    this.txtIndiceProvPeriodos = Number(this.txtIndiceProvPeriodos).toFixed(2);
    
  }
  
  calcularIndice() {
    this.txtTotalDisponibleCom = Number(this.txtTotalDisponible) + Number(this.txtComprasPlanificadas);
    this.txtTotalDisponibleCom = Number(this.txtTotalDisponibleCom).toFixed(2);
    
    this.txtIndiceProvUltPer2 = Number(this.txtVenUltimoPer) !== 0 ? Number(this.txtTotalDisponibleCom) / Number(this.txtVenUltimoPer) : 0;
    this.txtIndiceProvUltPer2 = Number(this.txtIndiceProvUltPer2).toFixed(2);
    this.txtIndiceProvPeriodos2 = Number(this.txtVenPromPerAnt) !== 0 ? Number(this.txtTotalDisponibleCom) / Number(this.txtVenPromPerAnt) :
      0;
    this.txtIndiceProvPeriodos2 = Number(this.txtIndiceProvPeriodos2).toFixed(2);
  }
  
  cerrarSeriales() {
    this.displayDialogSeriales = false;
  }

  abrirLotes(params) {
    this.detalleDVFSeleccionado = params;
    this.auxiliarlot++;
    this.displayDialogLotes = this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO.length > 0
      && this.detalleDVFSeleccionado.ART_CADUCA === 'S';
  }

  cerrarLotes() {
    this.displayDialogLotes = false;
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos  = false;
    }
  }

  generarAsiento() {
    if (this.botonFacNR === 'fac') {
      console.log(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO);
      this.comDevFacService.generarAsiento(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe(() => {
        this.auditoriagral.registrarAuditoria('COM_ENCDEV', String(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO), 'A',
          '', '01', '', '', '', '').subscribe(() => {
          this.comDevFacService.getAsiento(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe((asi) => {
            this.comDevFacService.encDevSeleccionadaarray[this.indicador].ASI_NRO = asi[0].ASI_NRO;
            this.message.add({
              key: 'devolucionCom',
              severity: 'success',
              summary: 'Asiento Contable',
              detail: 'Se ha generado el asiento'
            });
          });
        });
      }, error1 => {
        this.message.add({
          key: 'devolucionCom',
          severity: 'error',
          summary: 'Asiento Contable',
          detail: 'No se ha generado el asiento, error ' + error1.error
        });
      });
    } else if (this.botonFacNR === 'nr') {
      if (this.comDevFacService.encDevSeleccionadaarray[this.indicador].MERCONS === 'S') {
        this.comDevFacService.generarAsientoConsig(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe(() => {
          this.auditoriagral.registrarAuditoria('COM_ENCDEV', String(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO), 'A',
            '', '01', '', '', '', '').subscribe(() => {
            this.comDevFacService.getAsiento(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe((asi2) => {
              this.comDevFacService.encDevSeleccionadaarray[this.indicador].ASI_NRO = asi2[0].ASI_NRO;
              this.message.add({
                key: 'devolucionCom',
                severity: 'success',
                summary: 'Asiento Contable',
                detail: 'Se ha generado el asiento'
              });
            });
          });
        }, error1 => {
          this.message.add({
            key: 'devolucionCom',
            severity: 'error',
            summary: 'Asiento Contable',
            detail: 'No se ha generado el asiento, error ' + error1.error
          });
        });
      } else {
        this.comDevFacService.generarAsientoRecep(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe(() => {
          this.auditoriagral.registrarAuditoria('COM_ENCDEV', String(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO), 'A',
            '', '01', '', '', '', '').subscribe(() => {
            this.comDevFacService.getAsiento(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO).subscribe((asi3) => {
              this.comDevFacService.encDevSeleccionadaarray[this.indicador].ASI_NRO = asi3[0].ASI_NRO;
              this.message.add({
                key: 'devolucionCom',
                severity: 'success',
                summary: 'Asiento Contable',
                detail: 'Se ha generado el asiento'
              });
            });
          });
        });
      }
    }
  }
  //cdpj
  async verbasesiva(){
    this.cerrarSideBar()
    this.basesiva=[]
    const data = await this.comDevFacService.obtenerbasesiva(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO);
    if(data !== null && data !== undefined){
     this.basesiva=data
     for (const data2 of  this.basesiva){
       data2.TARIIVA_PORCENTAJE=isNaN(Number(data2.TARIIVA_PORCENTAJE))?Number(0).toFixed(2):Number(data2.TARIIVA_PORCENTAJE).toFixed(2)
       data2.IVA_BASE=isNaN(Number(data2.IVA_BASE))?Number(0).toFixed(2):Number(data2.IVA_BASE).toFixed(2)
       data2.IVA_VALOR=isNaN(Number(data2.IVA_VALOR))?Number(0).toFixed(2):Number(data2.IVA_VALOR).toFixed(2)
     }
    }
    this.displaybasesiva=true;

  }
  async actbasesiva(){
    this.spin=true;
    this.cerrarSideBar();
    try {
      await this.comDevFacService.actualizabasesiva(this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO);
      this.message.add({
        key: 'devolucionCom',
        severity: 'success',
        summary: 'Información',
        detail: 'Bases IVA actualizadas correctamente'
      });
      this.auditoriagral.registrarAuditoria('COM_ENCDEV',this.comDevFacService.encDevSeleccionadaarray[this.indicador].ENCDEV_NUMERO+'/'+'ACCIONES_ACT_VALORES_BASES_IVA', 'A', 
      '', '01', '', '', '', '').subscribe(() => {
      });
      this.spin=false;
    } catch (error) {
      this.message.add({
        key: 'devolucionCom',
        severity: 'error',
        summary: 'Información',
        detail: 'Error al actualiza las bases IVA'+ error
      });
      this.spin=false;
    }
    
  }
  //cdpj
}
