import { Component, OnInit } from '@angular/core';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { saveAs } from 'file-saver/FileSaver';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import { MessageService } from 'primeng';

@Component({
  selector: 'app-segconformacion',
  templateUrl: './segconformacion.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class SegconformacionComponent implements OnInit {

  constructor(private confIniciales: ConfInicialesService, private init: NuevoComponentService,
    private utilitariosService: UtilitariosService, private messageService: MessageService) {
  }

  ngOnInit(): void {


  }

  async conformacion() {
    let ruc;
    const data = await this.confIniciales.getRuc();
    for (const rs of data) {
      ruc = rs.COMRUCI;
    }
    const file = await this.confIniciales.conformacion(ruc);
    const blob = new Blob([file], { type: 'text/plain' });
    saveAs(blob, ruc + '.txt');
  }


  async enviaractivacion() {
    let ruc;
    let empresa;
    const data = await this.confIniciales.getRuc();
    for (const rs of data) {
      ruc = rs.COMRUCI;
      empresa = rs.COMNOMBRE;
    }
    const conforma = await this.confIniciales.conformacion(ruc);
      //GSRF
    console.log('----->',ruc)
    // if(ruc === '1708190911001' || ruc === '170819091100S' 
    // ||ruc === '099215449700F' ||ruc === '1721288221001' 
    // || ruc==='1712117975001' || ruc==='1793143865001'
    // || ruc==='1708190911001' || ruc==='1706076104001'
    // || ruc ==='1704598513001' || ruc ==='2290352080001'
    // || ruc ==='1793168833001' || ruc ==='0925395733001'
    // || ruc ==='1791856910001' || ruc ==='1791323548001'
    // || ruc ==='1792326222001' || ruc ==='1793031331001'
    // || ruc ==='0993373823001' || ruc ==='1792155495001'
    // || ruc ==='1710095108001' || ruc ==='1792088674001'
    // || ruc ==='0992149213001' || ruc ==='1793082025001'
    // || ruc=== '1793095518001' || ruc=== '1801982636001'
    // || ruc=== '1792014778001' || ruc=== '0993252921001'
    // || ruc=== '1792581532001' || ruc=== '1002157871001'
    // || ruc=== '1792363640001' || ruc=== '1792506816001'
    // || ruc=== '1792028272001' || ruc=== '1791264169001'
    // || ruc=== '1706728944001' || ruc=== '1714402490001'
    // || ruc=== '0992937238001' || ruc=== '1791050487001'
    // || ruc=== '1792788862002' || ruc=== '1793203069001'
    // || ruc=== '1792902630001' || ruc=== '0890039278001'
    // || ruc=== '0992869844001' || ruc=== '0993378980001'
    // || ruc=== '0992306122001' || ruc=== '0990337640001'
    // || ruc=== '0990871434001' || ruc=== '0992622296001'
    // || ruc=== '0992629991001' || ruc=== '0992391944001'
    // || ruc=== '0992760648001' || ruc=== '0992154497001'
    // || ruc=== '0991448284001' ){
    const essaci = await this.utilitariosService.esmarcasaci();
    if(essaci === true){
      this.utilitariosService.enviarCorreoActivacionaws(ruc, empresa, conforma);
      this.messageService.add({
        key: 'conforma',
        severity: 'success',
        summary: 'Correo enviado',
        detail: 'El correo de activación ha sido enviado a \'sacierpactivaciones@gmail.com\''
      });
    } else{
      this.utilitariosService.enviarCorreoActivacion(ruc, empresa, conforma);
      this.messageService.add({
        key: 'conforma',
        severity: 'success',
        summary: 'Correo enviado',
        detail: 'El correo de activación ha sido enviado a \'sacierpactivaciones@gmail.com\''
      });
    }
    //GSRF
  }

  manejarSenales(valor) {
    switch (valor) {
      case 'Salir':
        this.init.tabs.splice(this.init.tabindex, 1);
        break;
    }
  }

}
