import {Routes, RouterModule} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
// import {SampleDemoComponent} from './demo/view/sampledemo.component';
// import {DataDemoComponent} from './demo/view/datademo.component';
// import {PanelsDemoComponent} from './demo/view/panelsdemo.component';
// import {OverlaysDemoComponent} from './demo/view/overlaysdemo.component';
// import {MenusDemoComponent} from './demo/view/menusdemo.component';
// import {MessagesDemoComponent} from './demo/view/messagesdemo.component';
// import {MiscDemoComponent} from './demo/view/miscdemo.component';
// import {EmptyDemoComponent} from './demo/view/emptydemo.component';
// import {ChartsDemoComponent} from './demo/view/chartsdemo.component';
// import {FileDemoComponent} from './demo/view/filedemo.component';
// import {DocumentationComponent} from './demo/view/documentation.component';
import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {InvmaearticuloComponent} from './inv/invmaearticulo/invmaearticulo.component';
import {ReportePdfComponent} from './reporte-pdf/reporte-pdf.component';
import {DescargasHomeComponent} from './descargas/descargas-home/descargas-home.component';
import {RecuperarPasswdComponent} from './descargas/recuperar-passwd/recuperar-passwd.component';
import {ReportepdfviewerComponent} from './reportepdfviewer/reportepdfviewer.component';
import {AppLoginComponent} from './pages/app.login.component';
import {AppLoginMarcaComponent} from './pages/app.loginmarca.component';


export const routes: Routes = [
  {
    path: '', component: AppMainComponent,
    children: [
      // {path: 'sample', component: SampleDemoComponent},
      {path: 'forms', component: InvmaearticuloComponent},
      // {path: 'data', component: DataDemoComponent},
      // {path: 'panels', component: PanelsDemoComponent},
      // {path: 'overlays', component: OverlaysDemoComponent},
      // {path: 'menus', component: MenusDemoComponent},
      // {path: 'messages', component: MessagesDemoComponent},
      // {path: 'misc', component: MiscDemoComponent},
      // {path: 'empty', component: EmptyDemoComponent},
      // {path: 'charts', component: ChartsDemoComponent},
      // {path: 'file', component: FileDemoComponent},
      // {path: 'documentation', component: DocumentationComponent}
    ]
  },
  {path: 'error', component: AppErrorComponent},
  {path: 'accessdenied', component: AppAccessdeniedComponent},
  {path: '404', component: AppNotfoundComponent},
  {path: 'login', component: AppLoginComponent},
  // {path: 'login', component: AppLoginMarcaComponent},
  {path: 'reportes', component: ReportePdfComponent},
  {path: 'reportes/:nombre', component: ReportePdfComponent},
  {path: 'reportesnew', component: ReportepdfviewerComponent},
  {path: 'reportesnew/:nombre', component: ReportepdfviewerComponent},
  {path: 'descargas', component: DescargasHomeComponent},
  {path: 'recuperarpasswd', component: RecuperarPasswdComponent},
  {path: '**', redirectTo: '/404'},

];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'});
