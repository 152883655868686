//
// SACI WEB
// Rutina: Maestro de Clientes
// Propietario: Victor Perez
// Modificado por: VP
// Fecha de creación: 19-08-2019
// Fecha de Modificación: 29-11-2019
//

import { Component, OnInit, ViewChild } from '@angular/core';
import { VenMaeCliente } from '../veninterfaces/venmaecliente';
import { VenmaeclienteService } from '../venservicios/venmaecliente.service';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { MessageService, SelectItem } from 'primeng/api';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { Cxctmptrnresumen } from '../veninterfaces/cxctmptrnresumen';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { Cxctrnresumen } from '../veninterfaces/cxctrnresumen';
import { DatePipe, UpperCasePipe } from '@angular/common';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { VenDetFacturas, VenTopProductos } from '../veninterfaces/venestadisticas';
import { VenestadisticaService } from '../venservicios/venestadistica.service';
import { VenTrnsertran } from '../veninterfaces/ventrnsertran';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { Usuario } from 'src/app/usuario';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';//GSRF
import { PermisosService } from '../../advantage/advantageservices/permisos.service';//GSRF

@Component({
  selector: 'app-venmaecliente',
  templateUrl: './venmaecliente.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})

export class VenmaeclienteComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  barraBotones2: boolean;
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;
  index: number;

  maecliente: VenMaeCliente = {};
  permisoProveedor: SegMaePermiso;//GSRF

  lblvalida: string = '';
  cmbide: any[];
  selectide: any;
  cmbcontribuyente: any[];
  selectcontribuyente: any;
  cmbparterelacionada: any[];
  selectparterelacionada: any;
  cmbdenominacion: any[];
  etiquetasaudi:any[];
  selectdenominacion: any;
  cmbtipo: any[];
  selecttipo: any;
  cmblista: any[];
  selectlista: any;
  cmbiva: any[];
  selectiva: any;

  hmpConfiguracion: any[];//CDPj
  Nodupruc: number;//CDPJ

  denominaciondisabled: boolean;

  chkunificado: boolean;
  chkexcluye: boolean;
  chklider: boolean;
  chkjefezonal: boolean;
  chkfacturacionelec: boolean;
  chktarjetadesc: boolean;
  chkconregmic: boolean;
  chkconregrimpe: boolean;
  chkagenteret: boolean;

  opcionbusqueda: string;
  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];
  tabla: string;
  where:string;//CDPJ

  displayDialogBusqueda: boolean;
  displayDialogBusquedaFast: boolean;
  cxctmptrnresumen: Cxctmptrnresumen[];
  cxctrnresumen: Cxctrnresumen[];
  rowSelection = 'multiple';

  // autorizacion
  claveAuth: string;
  autorizacionCodigo: string;
  bolAutorizacion: boolean;
  displayDialogAutorizacion: boolean;

  // Estadistica

  dataVentasAcum: any;
  detTopProductos: VenTopProductos[] = [];
  detFacturas: VenDetFacturas[] = [];
  defaultColTopProductos;
  defaultColDetFacturas;
  rowStyleTopProd;
  rowStyleDetFacts;
  disabledEstadisticas = true;
  totalDocXCobrar: any = '';
  totalDocXCobrarPorc: any = '';
  totalDocVencidos: any = '';
  totalDocVencidosPorc: any = '';
  totalChPosfechados: any = '';
  totalChPosfechadosPorc: any = '';
  totalDocumentos: any = '';
  totalPedPendientes: any = '';
  totalPedSatisfechos: any = '';
  fechaPrimeraFact: any = '';
  totalChProtestados: any = '';
  bolestcta: boolean;
  bolsaldo: boolean;

  auxiliarequipo = 0;
  auxiliarsenalequipo = 0;
  enviarsenalequipo = '';

  servicioPedSelected: VenTrnsertran;
  indicador: any;

  options = {
    legend: {
      display: false
    }
  };

  displayDialogDocumentos = false;
  anticorreo1
  anticorreo2
  columnDefsCxctmptrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC'
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Debe', field: 'DEBE', cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Haber', field: 'HABER', cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: { textAlign: 'right' }
    },
    
  ];

  columnDefsCxctrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC'
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Fecha Vence', field: 'TRNCOBRO_FECHAVENCE', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Importe', field: 'TRNCOBRO_IMPORTE', cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Pagado', field: 'PAGADO', cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Comentario', field: 'COMENTARIO', cellStyle: { textAlign: 'right' }
    },
  ];

  columnDefsTopProductos = [
    {
      headerName: 'Producto', field: 'DETFAC_CODIGO', width: 115, editable: false
    },
    {
      headerName: 'Descripción', field: 'DETFAC_DESCRIPCION', width: 190, editable: false
    },
    {
      headerName: 'Cantidad', field: 'CANTIDAD_TOTAL', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Precio', field: 'DETFAC_PRECIO', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Total', field: 'TOTAL', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Stock', field: 'STOCK', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];

  columnDefsDetFacturas = [
    {
      headerName: 'Fecha', field: 'ENCFAC_FECHAEMISION', width: 90, editable: false
    },
    {
      headerName: 'Factura', field: 'ENCFAC_NUMERO', width: 140, editable: false
    },
    {
      headerName: 'Producto', field: 'DETFAC_CODIGO', width: 90, editable: false
    },
    {
      headerName: 'Descripción', field: 'DETFAC_DESCRIPCION', width: 100, editable: false
    },
    {
      headerName: 'Cantidad', field: 'DETFAC_CANTIDAD', width: 80, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Precio', field: 'DETFAC_PRECIO', width: 80, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Total', field: 'DETFAC_TOTAL', width: 80, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];

  columnDefServicioPed = [
    { // 0
      headerName: 'Tipo', field: 'TRNSERTRAN_TIPO', cellEditor: 'agSelectCellEditor', width: 150, editable: true,
      cellEditorParams:
      {
        values: []
      }
    },
    { // 1
      headerName: 'Servicio', field: 'SER_CODIGO', cellEditor: 'cellPrueba', width: 120,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: true,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 300
        }
      }
    },
    { // 2
      headerName: 'Descripción', field: 'SER_DESCRIPCION', cellEditor: 'cellPrueba', width: 200,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: true,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 300
        }
      }
    },
    { // 3
      headerName: 'Precio', field: 'TRNSERTRAN_PRECIO', cellEditor: 'cellPrueba', width: 100,
      cellStyle: { textAlign: 'right' },
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: true,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 300,
          tienecalculos: true
        }
      }
    },
    { // 4
      headerName: 'Descuento', field: 'TRNSERTRAN_DESCUENTO', cellEditor: 'cellPrueba', width: 100,
      cellStyle: { textAlign: 'right' },
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: true,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 300,
          tienecalculos: true
        }
      }
    }
  ];

  public frameworkComponents;
  rowStyle;
  rowStyleEC;
  rowStyleSaldo;
  defaultColDefCxctmptrnresumen;
  defaultColDefCxctrnresumen;
  defaultColDefServicioPed;
  largoestcta: string;
  largo: string;

  bolcliente: boolean;
  valorRucI: boolean;
  valorcedulaI: boolean;

  constructor(public cliService: VenmaeclienteService, private busqService: InvbusquedaService,
    private messageService: MessageService, private errorService: ErroresBaseDatosService,
    private datePipe: DatePipe, private confIniciales: ConfInicialesService, private permisosService: PermisosService,
    private init: NuevoComponentService, private venEstadisticasService: VenestadisticaService,
    private auditoriagral: AuditoriagralService, public usuario: Usuario,
    private utilitariosService: UtilitariosService) {
    this.agTable();
    this.detTopProductos = [];
    this.detFacturas = [];
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.rowStyleEC = (params) => {
      if (params.data.TRNCOBRO_TIPO !== 'A') {
        if (params.data.TRNCOBRO_FECHATRN === '') {
          return { background: 'rgb( 255, 255, 170)' };
        } else {
          return { background: 'rgb( 196, 225, 255)' };
        }
      }
    };

    this.rowStyleSaldo = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if (params.data.TRNCOBRO_FECHATRN === '') {
        return { background: 'rgb( 255, 255, 170)' };
      }
    };

    this.rowStyleTopProd = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if (params.data.DETFAC_CODIGO === '' || params.data.DETFAC_CODIGO === null) {
        return { background: 'rgb( 164, 255, 167)' };
      }
    };

    this.rowStyleDetFacts = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultColDefCxctmptrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctmptrnresumen',
      resizable: true
    };

    this.defaultColDefCxctrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctrnresumen',
      resizable: true
    };

    this.defaultColTopProductos = {
      editable: true,
      width: 100,
      objeto: 'topProductos',
      resizable: true
    };

    this.defaultColDetFacturas = {
      editable: true,
      width: 100,
      objeto: 'detalleFacturas',
      resizable: true
    };

    this.defaultColDefServicioPed = {
      editable: true,
      width: 100,
      objeto: 'servicioped',
      resizable: true
    };

  }

  ngOnInit() {
    //GSRF
    this.permisosService.getListaPermisos('VEN', '1', '15').subscribe((res) => {
      this.permisoProveedor = res[0];
      console.log('permiso-->',this.permisoProveedor)
    });
    //GSRF
    //CDPJ
    this.getConfiguracion()
    //CDPJ
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;

    this.index = 0;
    this.barraBotones2 = true;
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
    this.botonPrimero = true;
    this.botonAnterior = true;
    this.botonSiguiente = true;
    this.botonUltimo = true;
    this.llenarCombos();
    this.nuevoCli();
    this.largoestcta = '420';
    this.largo = '420';
    this.bolcliente = true;
    this.denominaciondisabled = true;

    this.prepararPestaniaSerped();
  }
      //CDPJ
      ngOnDestroy(): void {
        this.confIniciales.instanciasClientes--;
      }
    //CDPJ
   //CDPJ
   getConfiguracion() {
    this.auditoriagral.obtenerConfiguracion1().subscribe((conf) => {
      this.hmpConfiguracion = conf;
      this.cliService.hmpConfiguracion = conf;
      this.Nodupruc = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NODUPRUC').CFG_VALOR1;

    });
  }
  //CDPJ
  async manejarSenales(valor) {

    if (this.index === 7) {
      return;
    }

    if (valor === 'Nuevo') {
      if (this.permisoProveedor.PERINSERCION === 1) {
        this.nuevo();
      this.disabledEstadisticas = true;
      }
      if (this.permisoProveedor.PERINSERCION === 0) {
        this.messageService.add({
          key: 'cliente',
          severity: 'error',
          summary: 'Inserción de Cliente',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    }
    if (valor === 'Guardar') {
      if (this.permisoProveedor.PERACTUALIZACION === 1) {
        this.guardar();
      }
      if (this.permisoProveedor.PERACTUALIZACION === 0) {
        this.messageService.add({
          key: 'cliente',
          severity: 'error',
          summary: 'Actualización de Cliente',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }
      
    }
    if (valor === 'Borrar') {
      if (this.permisoProveedor.PERELIMACION === 1) {
      this.eliminar();
      }
      if (this.permisoProveedor.PERELIMACION === 0) {
        this.messageService.add({
          key: 'cliente',
          severity: 'error',
          summary: 'Eliminación de Cliente',
          detail: 'El usuario no tiene permisos de Eliminación, acción denegada'
        });
      }
    }
    if (valor === 'Cancelar') {
      this.cancelar();
    }
    if (valor === 'Primero') {
      this.seleccionarRegistro('P');
    }
    if (valor === 'Anterior') {
      this.seleccionarRegistro('A');
    }
    if (valor === 'Siguiente') {
      this.seleccionarRegistro('S');
    }
    if (valor === 'Ultimo') {
      this.seleccionarRegistro('U');
    }
    if (valor === 'Buscar') {
      this.busquedacliente();
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
    if (valor === 'Almacenardoc') {
      if (this.maecliente.CLI_CODIGO !== '') {
        const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.messageService.add({
            key: 'cliente',
            severity: 'info',
            summary: 'Información',
            detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
          });
        }

      }
    }
    document.getElementById('contorno').style.pointerEvents = 'all';
  }

  async changeTabs() {

    if (this.index === 2) {
      if (this.bolestcta === false) {
        this.cxctmptrnresumen = [];
        this.bolestcta = true;
        this.cxctmptrnresumen = await this.cliService.getInfoEstadoCuenta(this.maecliente.CLI_CODIGO,
          'cliente');
      }
    }
    if (this.index === 3) {
      if (this.bolsaldo === false) {
        this.cxctrnresumen = [];
        this.bolsaldo = true;
        this.cxctrnresumen = await this.cliService.getInfoSaldos(this.maecliente.CLI_CODIGO,
          'cliente');
      }
    }
    if (this.index === 7) { // Estadisticas
      this.dataVentasAcum = {
        labels: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
        datasets: [{
          label: 'Ventas',
          backgroundColor: '#42A5F5',
          borderColor: '#1E88E5',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }]
      };
      this.totalDocumentos = '';
      this.totalChPosfechadosPorc = '';
      this.totalDocVencidosPorc = '';
      this.totalDocXCobrar = '';
      this.totalDocXCobrarPorc = '';
      this.totalChPosfechados = '';
      this.totalDocVencidos = '';
      this.totalPedPendientes = '';
      this.totalPedSatisfechos = '';
      this.fechaPrimeraFact = '';
      this.detTopProductos = [];
      this.detFacturas = [];
      this.totalChProtestados = '';
      return;
    }

    if (this.index >= 2) {
      this.botonNuevo = true;
      this.botonGuardar = true;
      this.botonBorrar = true;
      this.botonRegresar = true;
      this.botonPrimero = false;
      this.botonAnterior = false;
      this.botonSiguiente = false;
      this.botonUltimo = false;
    } else {
      if (this.botonGuardar === true) {
        this.botonNuevo = false;
        this.botonGuardar = true;
        this.botonBorrar = false;
        this.botonRegresar = false;
      }
      this.botonPrimero = true;
      this.botonAnterior = true;
      this.botonSiguiente = true;
      this.botonUltimo = true;
    }

    if (this.index === 6) { // Estadisticas
      this.auxiliarequipo++;
      this.botonNuevo = false;
      this.botonGuardar = true;
      this.botonBorrar = false;
      this.botonRegresar = false;
    }
  }

  async prepararPestaniaSerped() {
    const data = await this.cliService.getreferenciatisecl();
    const opciones = [];
    for (const rs of data) {
      if (rs.REF_NOMBRE !== 'TODO') {
        opciones.push(rs.REF_NOMBRE);
      }
    }
    this.columnDefServicioPed[0].cellEditorParams = {
      values: opciones
    };
    this.confIniciales.getDecimales('frmINV_MAEARTICULO').subscribe((dec) => {
      if (dec !== null) {
        dec.map((decimales) => {
          if (decimales.NUMDEC_CAMPO === 'PRECIO') {
            this.cliService.decimalesPrecio = Number(decimales.NUMDEC_NUMDEC);
          }
        });
      }

      this.columnDefServicioPed[3].cellEditorParams = {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: true,
          numerodecimales: this.cliService.decimalesPrecio,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 300
        }
      };

      this.columnDefServicioPed[4].cellEditorParams = {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: true,
          numerodecimales: this.cliService.decimalesPrecio,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 300
        }
      };

      this.aggrid.refreshColumnDefsIndice(this.indicador, this.defaultColDefServicioPed.objeto,
        this.columnDefServicioPed);
    });

  }

  botonesmodificar() {
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = false;
  }

  botmodclirucide() {
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = false;
    this.lblvalida = '';
  }

  cambiarbotones(event) {
    if (event === true) {
      this.botonNuevo = true;
      this.botonGuardar = false;
      this.botonBorrar = true;
      this.botonRegresar = false;
    } else {
      this.botonNuevo = false;
      this.botonGuardar = true;
      this.botonBorrar = false;
      this.botonRegresar = false;
    }
  }

  botonesmodificardet() {
  }

  seleccionvenserped(params) {
    if (params === 'nuevo') {
      this.nuevo();
      return;
    }
    if (params === 'cancelar') {
      this.cancelar();
      return;
    }
    if (params === 'eliminar') {
      this.eliminar();
      return;
    }
    if (params === 'guardar') {
      this.guardar();
      return;
    }

    if (params.object !== undefined) {
      this.servicioPedSelected = params.object;
    }
  }

  setFocus(event) {
    let element: any;
    switch (event.target.id) {
      case 'CLI_RUCIDE': {
        element = document.getElementById('CLI_CODIGO');
        break;
      }
      case 'CLI_CODIGO': {
        element = document.getElementById('CLI_NOMBRE');
        break;
      }
      case 'CLI_NOMBRE': {
        element = document.getElementById('CLI_NOMBREC');
        this.maecliente.CLI_NOMBREC = this.maecliente.CLI_NOMBRE.substr(0, 12);
        break;
      }
      case 'CLI_NOMBREC': {
        element = document.getElementById('CLI_CONTACTO');
        break;
      }
      case 'CLI_CONTACTO': {
        element = document.getElementById('GRU_CODIGO');
        break;
      }
      case 'CLI_FECING': {
        element = document.getElementById('GRU_CODIGO');
        break;
      }
      case 'GRU_CODIGO': {
        this.busquedagrupo(event.target.value);
        break;
      }
      case 'CLI_FECHANACIMIENTO': {
        element = document.getElementById('CLI_ESTCIV');
        break;
      }
      case 'CLI_ESTCIV': {
        this.busquedaestciv(event.target.value);
        break;
      }
      case 'CLI_ORGING': {
        this.busquedaorging(event.target.value);
        break;
      }
      case 'CLI_SEXO': {
        this.busquedasexo(event.target.value);
        break;
      }
      case 'chkunificado': {
        element = document.getElementById('chkexcluye');
        break;
      }
      case 'chkexcluye': {
        element = document.getElementById('VEN_CODIGO');
        break;
      }
      case 'VEN_CODIGO': {
        this.busquedavendedor(event.target.value);
        break;
      }
      case 'CLI_DIRECCION1': {
        element = document.getElementById('CLI_DIRECCION2');
        break;
      }
      case 'CLI_DIRECCION2': {
        element = document.getElementById('CLI_TELEFONO1');
        break;
      }
      case 'CLI_TELEFONO1': {
        element = document.getElementById('CLI_TELEFONO2');
        break;
      }
      case 'CLI_TELEFONO2': {
        element = document.getElementById('CLI_FAX');
        break;
      }
      case 'CLI_FAX': {
        element = document.getElementById('CLI_CODPOSTAL');
        break;
      }
      case 'CLI_CODPOSTAL': {
        element = document.getElementById('CLI_CARGO');
        break;
      }
      case 'CLI_CARGO': {
        this.busquedacargo(event.target.value);
        break;
      }
      case 'CLI_CORREO': {
        element = document.getElementById('CLI_CORREO2');
        break;
      }
      case 'CLI_CORREO2': {
        element = document.getElementById('CLI_PROVINCIA');
        break;
      }
      case 'CLI_PROVINCIA': {
        this.busquedaprovincia(event.target.value);
        break;
      }
      case 'CIU_CODIGO': {
        this.busquedaciudad(event.target.value);
        break;
      }
      case 'CAN_CODIGO': {
        this.busquedacanton(event.target.value);
        break;
      }
      case 'PARR_CODIGO': {
        this.busquedaparroquia(event.target.value);
        break;
      }
      case 'CLI_ZONA': {
        this.busquedazona(event.target.value);
        break;
      }
      case 'CLI_GMAPS': {
        this.busquedagmaps(event.target.value);
        element = document.getElementById('CLI_OBSERVACION');
        break;
      }
      case 'CLI_OBSERVACION': {
        element = document.getElementById('CLI_RUCIDE');
        break;
      }
      case 'CLI_SUJETO': {
        this.busquedatipo(event.target.value);
        break;
      }
      case 'CLI_DESCUENTO': {
        element = document.getElementById('CLI_DESCUENTOLIM');
        break;
      }
      case 'CLI_DESCUENTOLIM': {
        element = document.getElementById('CLI_LIMCREDIT');
        break;
      }
      case 'CLI_LIMCREDIT': {
        element = document.getElementById('CLI_DIACREDIT');
        break;
      }
      case 'CLI_DIACREDIT': {
        element = document.getElementById('CLI_DIACHPOS');
        break;
      }
      case 'CLI_DIACHPOS': {
        element = document.getElementById('BANCLI_CODIGO');
        break;
      }
      case 'BANCLI_CODIGO': {
        this.busquedabanco(event.target.value);
        break;
      }
      case 'CLI_NROCUENTA': {
        element = document.getElementById('CLI_FORMAPAGO_CODSRI');
        break;
      }
      case 'CLI_FORMAPAGO_CODSRI': {
        this.busquedaformapago(event.target.value);
        break;
      }
      case 'CLI_ESTADO': {
        this.busquedaestado(event.target.value);
        break;
      }
      case 'CON_CODIGO1': {
        this.busquedaconcodigo('1', event.target.value);
        break;
      }
      case 'CON_CODIGO2': {
        this.busquedaconcodigo('2', event.target.value);
        break;
      }
      case 'CON_CODIGO3': {
        this.busquedaconcodigo('3', event.target.value);
        break;
      }
      case 'CEN_CODIGO': {
        this.busquedacencodigo(event.target.value);
        break;
      }
      case 'CLI_LOCAL': {
        element = document.getElementById('CLI_PLACA');
        break;
      }
      case 'CLI_PLACA': {
        element = document.getElementById('CLI_PRECIOAC20');
        break;
      }
      case 'CLI_PRECIOAC20': {
        element = document.getElementById('CLI_PRECIORC250');
        break;
      }
      case 'CLI_PRECIORC250': {
        element = document.getElementById('CLI_NUMEROCUOTAS');
        break;
      }
      case 'CLI_NUMEROCUOTAS': {
        element = document.getElementById('CLI_INCREMENTO');
        break;
      }
      case 'CLI_INCREMENTO': {
        element = document.getElementById('CLI_SUJETO');
        break;
      }


      default: {
        break;
      }
    }

    if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }
  }

  async seleccionarRegistro(strTipo) {
    try {
      const datos = await this.cliService.seleccionarRegistroProm(strTipo, this.maecliente);
      await this.limpiartablas();
      this.encontrarCliente(datos[0].CLI_CODIGO);
    } catch (err) {
      const error = this.errorService.generarMensajeErrorCompleto(err.error);
      this.messageService.add({
        key: 'cliente',
        severity: 'error',
        summary: 'Error',
        detail: error
      });
    }

    /*this.cliService.seleccionarRegistro(strTipo, this.maecliente).subscribe((datos: any[]) => {
      this.limpiartablas();
      this.encontrarCliente(datos[0].CLI_CODIGO);
    }, error1 => {
      const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'cliente',
        severity: 'error',
        summary: 'Error',
        detail: error
      });
    });*/
  }

  async limpiartablas() {
    await this.nuevoCli();
    this.bolcliente = false;
    this.denominaciondisabled = false;
  }

  async encontrarClienteNew(cliente) {
    try {
      const res = await this.cliService.getClienteProm(cliente);
      for (const i of res) {
        this.bolcliente = true;
        i.CLI_FECING = new Date(i.CLI_FECING);
        i.CLI_FECHANACIMIENTO = new Date(i.CLI_FECHANACIMIENTO);
        i.CLI_FECHAULTPAG = new Date(i.CLI_FECHAULTPAG);
        this.maecliente = i;
        this.chkunificado = this.maecliente.CLI_UNIFICA === 'S';
        this.chkexcluye = this.maecliente.CLI_EXCLUYE === 'S';
        this.chklider = this.maecliente.CLI_LIDER === 'S';
        this.chkjefezonal = this.maecliente.CLI_JEFE === 'S';
        this.chkfacturacionelec = this.maecliente.CLI_FACTURAELECT === 'S';
        this.chktarjetadesc = this.maecliente.CLI_TARDSC === 'S';
        this.chkconregmic = this.maecliente.CLI_CONREGMIC === 'S';
        this.chkagenteret = this.maecliente.CLI_AGENTERET === 'S';
        this.chkconregrimpe = this.maecliente.CLI_CONREGRIMPE === 'S';
        for (const j of this.cmbide) {
          if (this.maecliente.CLI_TIPOIDE === j.codigo) {
            this.maecliente.CLI_TIPOIDENOMBRE = j.name;
            this.selectide = { codigo: j.codigo, name: j.name };
          }
        }

        if (this.maecliente.CLI_TIPOIDENOMBRE === 'Pasaporte') {
          this.denominaciondisabled = false;
        } else {
          this.denominaciondisabled = true;
        }

        for (const j of this.cmbcontribuyente) {
          if (this.maecliente.CLI_CONTRIBUYENTE === j.codigo) {
            this.maecliente.CLI_CONTRIBUYENTENOMBRE = j.name;
            this.selectcontribuyente = { codigo: j.codigo, name: j.name };
          }
        }
        for (const j of this.cmbparterelacionada) {
          if (this.maecliente.CLI_PARTEREL === j.codigo) {
            this.maecliente.CLI_PARTERELNOMBRE = j.name;
            this.selectparterelacionada = { codigo: j.codigo, name: j.name };
          }
        }
        for (const j of this.cmbtipo) {
          if (this.maecliente.CLI_TIPO === j.codigo) {
            this.maecliente.CLI_TIPONOMBRE = j.name;
            this.selecttipo = { codigo: j.codigo, name: j.name };
          }
        }
        for (const j of this.cmbiva) {
          if (this.maecliente.CLI_IVA === j.codigo) {
            this.maecliente.CLI_IVANOMBRE = j.name;
            this.selectiva = { codigo: j.codigo, name: j.name };
          }
        }
        for (const j of this.cmblista) {
          if (this.maecliente.CLI_LISTA === j.codigo) {
            this.selectlista = { codigo: j.codigo, name: j.name };
          }
        }
        if (this.maecliente.GRU_CODIGO !== null) {
          this.cliService.erNombreGrupo(this.maecliente.GRU_CODIGO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.GRU_NOMBRE = formsricom[0].GRU_NOMBRE;
              }
            }
          });
        }
        if (this.maecliente.CLI_ESTCIV !== null) {
          this.cliService.erNombreEstadoCivil(this.maecliente.CLI_ESTCIV).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CLI_ESTCIVNOMBRE = formsricom[0].REF_NOMBRE;
              }
            }
          });
        }
        if (this.maecliente.CLI_ORGING !== null) {
          this.cliService.erNombreOrigen(this.maecliente.CLI_ORGING).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CLI_ORGINGNOMBRE = formsricom[0].REF_NOMBRE;
              }
            }
          });
        }
        if (this.maecliente.CLI_SEXO !== null) {
          this.cliService.erNombreSexo(this.maecliente.CLI_SEXO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CLI_SEXONOMBRE = formsricom[0].REF_NOMBRE;
              }
            }
          });
        }
        if (this.maecliente.VEN_CODIGO !== null) {
          this.cliService.erNombreVendedor(this.maecliente.VEN_CODIGO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.VEN_NOMBRE = formsricom[0].VEN_NOMBRE;
              }
            }
          });
        }
        if (this.maecliente.CLI_CARGO !== null) {
          this.cliService.erNombreCargo(this.maecliente.CLI_CARGO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CLI_CARGONOMBRE = formsricom[0].REF_NOMBRE;
              }
            }
          });
        }
        if (this.maecliente.CLI_PROVINCIA !== null) {
          this.cliService.erNombreProvincia(this.maecliente.CLI_PROVINCIA).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CLI_PROVINCIANOMBRE = formsricom[0].PROV_NOMBRE;
              }
            }
          });
        }
        if (this.maecliente.CIU_CODIGO !== null) {
          this.cliService.erNombreCiudad(this.maecliente.CIU_CODIGO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CIU_NOMBRE = formsricom[0].CIU_NOMBRE;
              }
            }
          });
        }
        if (this.maecliente.CAN_CODIGO !== null) {
          this.cliService.erNombreCanton(this.maecliente.CAN_CODIGO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CAN_NOMBRE = formsricom[0].CAN_DESCRIPCION;
              }
            }
          });
        }
        if (this.maecliente.PARR_CODIGO !== null) {
          this.cliService.erNombreParroquia(this.maecliente.PARR_CODIGO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.PARR_NOMBRE = formsricom[0].PARR_DESCRIPCION;
              }
            }
          });
        }

        if (this.maecliente.CLI_ZONA !== null) {
          this.cliService.erNombreZona(this.maecliente.CLI_ZONA).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CLI_ZONANOMBRE = formsricom[0].REF_NOMBRE;
              }
            }
          });
        }

        if (this.maecliente.CLI_SUJETO !== null) {
          this.cliService.erNombreSujeto(this.maecliente.CLI_SUJETO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CLI_SUJETONOMBRE = formsricom[0].REF_NOMBRE;
              }
            }
          });
        }

        if (this.maecliente.BANCLI_CODIGO !== null) {
          this.cliService.erNombreBanco(this.maecliente.BANCLI_CODIGO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.BANCLI_NOMBRE = formsricom[0].BANCLI_NOMBRE;
              }
            }
          });
        }

        if (this.maecliente.CLI_FORMAPAGO_CODSRI !== null) {
          this.cliService.erNombreFormaPago(
            this.maecliente.CLI_FORMAPAGO_CODSRI).subscribe((formsricom) => {
              if (formsricom !== null) {
                if (formsricom[0] !== undefined) {
                  this.maecliente.CLI_FORMAPAGO_CODSRINOMBRE = formsricom[0].CODSRI_DESCRIPCION;
                }
              }
            });
        }

        if (this.maecliente.CLI_ESTADO !== null) {
          this.cliService.erNombreEstado(this.maecliente.CLI_ESTADO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CLI_ESTADONOMBRE = formsricom[0].REF_NOMBRE;
              }
            }
          });
        }

        if (this.maecliente.CON_CODIGO1 !== null) {
          this.cliService.erNombreConcodigo(this.maecliente.CON_CODIGO1).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CON_CODIGO1NOMBRE = formsricom[0].CON_NOMBRE;
              }
            }
          });
        }

        if (this.maecliente.CON_CODIGO2 !== null) {
          this.cliService.erNombreConcodigo(this.maecliente.CON_CODIGO2).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CON_CODIGO2NOMBRE = formsricom[0].CON_NOMBRE;
              }
            }
          });
        }

        if (this.maecliente.CON_CODIGO3 !== null) {
          this.cliService.erNombreConcodigo(this.maecliente.CON_CODIGO3).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CON_CODIGO3NOMBRE = formsricom[0].CON_NOMBRE;
              }
            }
          });
        }

        if (this.maecliente.CEN_CODIGO !== null) {
          this.cliService.erNombreCencos(this.maecliente.CEN_CODIGO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CEN_CODIGONOMBRE = formsricom[0].CEN_NOMBRE;
              }
            }
          });
        }

        this.cliService.erTipoIdentifi(this.maecliente.CLI_CODIGO).subscribe((tipoCli) => {
          if (String(tipoCli[0].CLI_TIPOIDE) === '6') {
            this.disabledEstadisticas = true;
          } else {
            this.disabledEstadisticas = false;
          }
        });

      }

      if (this.index < 2) {
        this.botonNuevo = false;
        this.botonGuardar = true;
        this.botonBorrar = false;
        this.botonRegresar = true;
      }

      this.bolestcta = false;
      this.bolsaldo = false;

    } catch (err) {
      const error = this.errorService.generarMensajeErrorCompleto(err.error);
      this.messageService.add({
        key: 'cliente',
        severity: 'error',
        summary: 'Error',
        detail: error
      });
    }

  }

  encontrarCliente(cliente) {
    this.cliService.getCliente(cliente).subscribe((res) => {
      for (const i of res) {
        this.bolcliente = true;
        i.CLI_FECING = new Date(i.CLI_FECING);
        i.CLI_FECHANACIMIENTO = new Date(i.CLI_FECHANACIMIENTO);
        i.CLI_FECHAULTPAG = new Date(i.CLI_FECHAULTPAG);

        this.maecliente = i;
        this.chkunificado = this.maecliente.CLI_UNIFICA === 'S';
        this.chkexcluye = this.maecliente.CLI_EXCLUYE === 'S';
        this.chklider = this.maecliente.CLI_LIDER === 'S';
        this.chkjefezonal = this.maecliente.CLI_JEFE === 'S';
        this.chkfacturacionelec = this.maecliente.CLI_FACTURAELECT === 'S';
        this.chktarjetadesc = this.maecliente.CLI_TARDSC === 'S';
        this.chkconregmic = this.maecliente.CLI_CONREGMIC === 'S';
        this.chkagenteret = this.maecliente.CLI_AGENTERET === 'S';
        this.chkconregrimpe = this.maecliente.CLI_CONREGRIMPE === 'S';
        this.lblvalida = i.CLI_VALIDACION;
        //CDPJ
        this.anticorreo1=this.maecliente.CLI_CORREO === null || this.maecliente.CLI_CORREO === undefined?'':this.maecliente.CLI_CORREO;
        this.anticorreo2=this.maecliente.CLI_CORREO2 === null || this.maecliente.CLI_CORREO2 === undefined?'':this.maecliente.CLI_CORREO2;
        //CDPJ
        for (const j of this.cmbide) {
          if (this.maecliente.CLI_TIPOIDE === j.codigo) {
            this.maecliente.CLI_TIPOIDENOMBRE = j.name;
            this.selectide = { codigo: j.codigo, name: j.name };
          }
        }

        if (this.maecliente.CLI_TIPOIDENOMBRE === 'Pasaporte') {
          this.denominaciondisabled = false;
        } else {
          this.denominaciondisabled = true;
        }

        for (const j of this.cmbcontribuyente) {
          if (this.maecliente.CLI_CONTRIBUYENTE === j.codigo) {
            this.maecliente.CLI_CONTRIBUYENTENOMBRE = j.name;
            this.selectcontribuyente = { codigo: j.codigo, name: j.name };
          }
        }
        for (const j of this.cmbparterelacionada) {
          if (this.maecliente.CLI_PARTEREL === j.codigo) {
            this.maecliente.CLI_PARTERELNOMBRE = j.name;
            this.selectparterelacionada = { codigo: j.codigo, name: j.name };
          }
        }
        for (const j of this.cmbtipo) {
          if (this.maecliente.CLI_TIPO === j.codigo) {
            this.maecliente.CLI_TIPONOMBRE = j.name;
            this.selecttipo = { codigo: j.codigo, name: j.name };
          }
        }
        for (const j of this.cmbiva) {
          if (this.maecliente.CLI_IVA === j.codigo) {
            this.maecliente.CLI_IVANOMBRE = j.name;
            this.selectiva = { codigo: j.codigo, name: j.name };
          }
        }
        for (const j of this.cmblista) {
          if (this.maecliente.CLI_LISTA === j.codigo) {
            this.selectlista = { codigo: j.codigo, name: j.name };
          }
        }
        if (this.maecliente.GRU_CODIGO !== null) {
          this.cliService.erNombreGrupo(this.maecliente.GRU_CODIGO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.GRU_NOMBRE = formsricom[0].GRU_NOMBRE;
              }
            }
          });
        }
        if (this.maecliente.CLI_ESTCIV !== null) {
          this.cliService.erNombreEstadoCivil(this.maecliente.CLI_ESTCIV).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CLI_ESTCIVNOMBRE = formsricom[0].REF_NOMBRE;
              }
            }
          });
        }
        if (this.maecliente.CLI_ORGING !== null) {
          this.cliService.erNombreOrigen(this.maecliente.CLI_ORGING).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CLI_ORGINGNOMBRE = formsricom[0].REF_NOMBRE;
              }
            }
          });
        }
        if (this.maecliente.CLI_SEXO !== null) {
          this.cliService.erNombreSexo(this.maecliente.CLI_SEXO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CLI_SEXONOMBRE = formsricom[0].REF_NOMBRE;
              }
            }
          });
        }
        if (this.maecliente.VEN_CODIGO !== null) {
          this.cliService.erNombreVendedor(this.maecliente.VEN_CODIGO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.VEN_NOMBRE = formsricom[0].VEN_NOMBRE;
              }
            }
          });
        }
        if (this.maecliente.CLI_CARGO !== null) {
          this.cliService.erNombreCargo(this.maecliente.CLI_CARGO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CLI_CARGONOMBRE = formsricom[0].REF_NOMBRE;
              }
            }
          });
        }
        if (this.maecliente.CLI_PROVINCIA !== null) {
          this.cliService.erNombreProvincia(this.maecliente.CLI_PROVINCIA).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CLI_PROVINCIANOMBRE = formsricom[0].PROV_NOMBRE;
              }
            }
          });
        }
        if (this.maecliente.CIU_CODIGO !== null) {
          this.cliService.erNombreCiudad(this.maecliente.CIU_CODIGO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CIU_NOMBRE = formsricom[0].CIU_NOMBRE;
              }
            }
          });
        }
        if (this.maecliente.CAN_CODIGO !== null) {
          this.cliService.erNombreCanton(this.maecliente.CAN_CODIGO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CAN_NOMBRE = formsricom[0].CAN_DESCRIPCION;
              }
            }
          });
        }
        if (this.maecliente.PARR_CODIGO !== null) {
          this.cliService.erNombreParroquia(this.maecliente.PARR_CODIGO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.PARR_NOMBRE = formsricom[0].PARR_DESCRIPCION;
              }
            }
          });
        }

        if (this.maecliente.CLI_ZONA !== null) {
          this.cliService.erNombreZona(this.maecliente.CLI_ZONA).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CLI_ZONANOMBRE = formsricom[0].REF_NOMBRE;
              }
            }
          });
        }

        if (this.maecliente.CLI_SUJETO !== null) {
          this.cliService.erNombreSujeto(this.maecliente.CLI_SUJETO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CLI_SUJETONOMBRE = formsricom[0].REF_NOMBRE;
              }
            }
          });
        }

        if (this.maecliente.BANCLI_CODIGO !== null) {
          this.cliService.erNombreBanco(this.maecliente.BANCLI_CODIGO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.BANCLI_NOMBRE = formsricom[0].BANCLI_NOMBRE;
              }
            }
          });
        }

        if (this.maecliente.CLI_FORMAPAGO_CODSRI !== null) {
          this.cliService.erNombreFormaPago(
            this.maecliente.CLI_FORMAPAGO_CODSRI).subscribe((formsricom) => {
              if (formsricom !== null) {
                if (formsricom[0] !== undefined) {
                  this.maecliente.CLI_FORMAPAGO_CODSRINOMBRE = formsricom[0].CODSRI_DESCRIPCION;
                }
              }
            });
        }

        if (this.maecliente.CLI_ESTADO !== null) {
          this.cliService.erNombreEstado(this.maecliente.CLI_ESTADO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CLI_ESTADONOMBRE = formsricom[0].REF_NOMBRE;
              }
            }
          });
        }

        if (this.maecliente.CON_CODIGO1 !== null) {
          this.cliService.erNombreConcodigo(this.maecliente.CON_CODIGO1).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CON_CODIGO1NOMBRE = formsricom[0].CON_NOMBRE;
              }
            }
          });
        }

        if (this.maecliente.CON_CODIGO2 !== null) {
          this.cliService.erNombreConcodigo(this.maecliente.CON_CODIGO2).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CON_CODIGO2NOMBRE = formsricom[0].CON_NOMBRE;
              }
            }
          });
        }

        if (this.maecliente.CON_CODIGO3 !== null) {
          this.cliService.erNombreConcodigo(this.maecliente.CON_CODIGO3).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CON_CODIGO3NOMBRE = formsricom[0].CON_NOMBRE;
              }
            }
          });
        }

        if (this.maecliente.CEN_CODIGO !== null) {
          this.cliService.erNombreCencos(this.maecliente.CEN_CODIGO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maecliente.CEN_CODIGONOMBRE = formsricom[0].CEN_NOMBRE;
              }
            }
          });
        }

        this.cliService.erTipoIdentifi(this.maecliente.CLI_CODIGO).subscribe((tipoCli) => {
          if (String(tipoCli[0].CLI_TIPOIDE) === '6') {
            this.disabledEstadisticas = true;
          } else {
            this.disabledEstadisticas = false;
          }
        });

        this.cliService.getventrnsertran(this.maecliente.CLI_CODIGO).subscribe(data => {
          let resultado = [];
          if (data !== null) {
            resultado = data;
          }
          resultado.map((rs) => {
            rs.CONTROL_GUARDAR = false;
            rs.TRNSERTRAN_PRECIO = rs.TRNSERTRAN_PRECIO.toFixed(this.cliService.decimalesPrecio);
            rs.TRNSERTRAN_DESCUENTO = rs.TRNSERTRAN_DESCUENTO.toFixed(this.cliService.decimalesPrecio);
          });
          this.cliService.venservicioped[this.indicador] = resultado;
        });

        if (this.index === 6) {
          this.auxiliarequipo++;
        }

      }

      if (this.index < 2) {
        this.botonNuevo = false;
        this.botonGuardar = true;
        this.botonBorrar = false;
        this.botonRegresar = true;
      }

      this.bolestcta = false;
      this.bolsaldo = false;
    });
  }

  async nuevoCli() {
    this.maecliente = {
      CLI_CODIGO: '', GRU_CODIGO: '', GRU_NOMBRE: '', VEN_CODIGO: '', VEN_NOMBRE: '',
      CLI_NOMBREC: '', CLI_NOMBRE: '', CLI_TIPOIDE: '1', CLI_TIPOIDENOMBRE: '', CLI_RUCIDE: '',
      CLI_DIRECCION1: '', CLI_DIRECCION2: '', CLI_CODPOSTAL: '', CLI_TELEFONO1: '', CLI_TELEFONO2: '',
      CLI_FAX: '', CLI_CORREO: '', CLI_CONTACTO: '', CLI_FECING: '', CLI_LIMCREDIT: 0,
      CLI_DIACREDIT: 0, CLI_DESCUENTO: 0, CLI_IVA: '', CLI_IVANOMBRE: '', CLI_CONTRIBUYENTE: '',
      CLI_CONTRIBUYENTENOMBRE: '', CLI_LISTA: '', CON_CODIGO1: '', CON_CODIGO1NOMBRE: '', CON_CODIGO2: '',
      CON_CODIGO2NOMBRE: '', CLI_ZONA: '', CLI_ZONANOMBRE: '', CLI_OBSERVACION: '', CLI_SALDO: '',
      NOM_CODIGO: '', DEP_CODIGO: '', CLI_TIPO: '', CLI_TIPONOMBRE: '', CLI_PROVINCIA: '',
      CLI_PROVINCIANOMBRE: '', CIU_CODIGO: '', CIU_NOMBRE: '', TCR_CODIGO: '', CLI_TRFIVA: '',
      CLI_TRFRETENCION: '', CBR_CODIGO: '', CLI_ESTADO: 'A', CLI_ESTADONOMBRE: '', ENCFFA_CODIGO: '',
      CLI_LINNEG: '', CLI_PORCEDESCUENTO: 0, CLI_VALORRECARGO: 0, CLI_PORCERECARGO: 0, CLI_FECHAULTPAG: '',
      CLI_CODSEGURIDAD: '', CLI_FLAG: '', CEN_CODIGO: '', CEN_CODIGONOMBRE: '', CLI_LOCAL: '',
      CLI_FECHANACIMIENTO: '', CLI_SEXO: '', CLI_SEXONOMBRE: '', CLI_CARGO: '', CLI_CARGONOMBRE: '',
      CLI_DIACHPOS: 0, CLI_UNIFICA: '', CLI_EXCLUYE: '', CLI_DESCUENTOLIM: 0, CON_CODIGO3: '',
      CON_CODIGO3NOMBRE: '', COM_CODIGO: '01', GRU_TIPO: '', BANCLI_CODIGO: '', BANCLI_NOMBRE: '',
      CLI_NROCUENTA: '', CLI_LIDER: '', CLI_JEFE: '', CLI_TARDSC: '', CLI_PRECIOAC20: '', CLI_PRECIORC250: '',
      CLI_INACTIVO: '', USUARIO: '', CLI_NUMEROCUOTAS: '', CLI_INCREMENTO: '', CLI_FACTURAELECT: '',
      CLI_CORREO2: '', CLI_CLAVSACIANEX: '', CLI_PARTEREL: '', CLI_PARTERELNOMBRE: '', CLI_FORMAPAGO_CODSRI: '',
      CLI_FORMAPAGO_CODSRINOMBRE: '', CLI_TIPODEN: '', CLI_INSTRUCCION: '', CLI_TIPOSANGRE: '', CLI_TALLA: '',
      CLI_CATEGORIA: '', CLI_NOMALUMNO: '', CLI_APEALUMNO: '', CLI_LICENCIA: '', CLI_SUJETO: '',
      CLI_SUJETONOMBRE: '', CAN_CODIGO: '', CAN_NOMBRE: '', PARR_CODIGO: '', PARR_NOMBRE: '', CLI_ESTCIV: '',
      CLI_ESTCIVNOMBRE: '', CLI_ORGING: '', CLI_ORGINGNOMBRE: '', CLI_GMAPS: '', CLI_PLACA: '',
      CLI_COMENTARIOS: ''
    };

    this.maecliente.CLI_FECING = new Date();
    this.chkunificado = false;
    this.chkexcluye = false;
    this.chklider = false;
    this.chkjefezonal = false;
    this.chkfacturacionelec = false;
    this.chktarjetadesc = false;
    this.chkconregmic = false;
    this.chkagenteret = false;
    this.chkconregrimpe = false
    this.bolestcta = false;
    this.bolsaldo = false;
    this.lblvalida = '';

    const res = await this.cliService.getCuentasProm();
    if (res[0] !== undefined) {
      this.maecliente.CON_CODIGO1 = res[0].CON_CODIGO1;
      this.maecliente.CON_CODIGO2 = res[0].CON_CODIGO2;
      this.maecliente.CON_CODIGO3 = res[0].CON_CODIGO3;
    }

    this.selectide = { codigo: this.cmbide[0].codigo, name: this.cmbide[0].name };
    this.selectiva = { codigo: this.cmbiva[1].codigo, name: this.cmbiva[1].name };
    this.maecliente.CLI_IVA = this.selectiva.codigo;
    this.selectdenominacion = { codigo: this.cmbdenominacion[0].codigo, name: this.cmbdenominacion[0].name };
    this.maecliente.CLI_TIPODEN = this.selectdenominacion.codigo;
    this.selectcontribuyente = { codigo: this.cmbcontribuyente[0].codigo, name: this.cmbcontribuyente[0].name };
    this.maecliente.CLI_CONTRIBUYENTE = this.selectcontribuyente.codigo;
    this.selectparterelacionada = { codigo: this.cmbparterelacionada[0].codigo, name: this.cmbparterelacionada[0].name };
    this.maecliente.CLI_PARTEREL = this.selectparterelacionada.codigo;
    this.selecttipo = { codigo: this.cmbtipo[0].codigo, name: this.cmbtipo[0].name };
    this.maecliente.CLI_TIPO = this.selecttipo.codigo;
    if (this.cmblista.length > 0) {
      this.selectlista = { codigo: this.cmblista[0].codigo, name: this.cmblista[0].name };
      this.maecliente.CLI_LISTA = this.selectlista.codigo;
    } else {
      this.maecliente.CLI_LISTA = '';
    }

    this.cxctmptrnresumen = [];
    this.cxctrnresumen = [];
    this.cliService.venservicioped[this.indicador] = [];
  }

  nuevo() {
    if (this.index === 0 || this.index === 1) {
      this.botonNuevo = true;
      this.botonGuardar = false;
      this.botonBorrar = true;
      this.botonRegresar = false;
      this.limpiartablas();
      this.llenarCombos();
    }

    if (this.index === 5) {

      if (this.maecliente.CLI_CODIGO === ''
        || this.maecliente.CLI_CODIGO === null
        || this.maecliente.CLI_CODIGO === undefined) {
        this.messageService.add({
          key: 'cliente',
          severity: 'error',
          summary: 'Error',
          detail: 'Escoja un cliente'
        });
        return;
      }

      if (this.servicioPedSelected !== undefined) {
        if (this.servicioPedSelected.SER_CODIGO === '') {
          return;
        }
      }

      const serped: VenTrnsertran = {
        CLI_CODIGO: this.maecliente.CLI_CODIGO,
        SER_CODIGO: '',
        SER_DESCRIPCION: '',
        COM_CODIGO: '01',
        TRNSERTRAN_PRECIO: '',
        TRNSERTRAN_DESCUENTO: '',
        TRNSERTRAN_TIPO: '',
        TRNSERTRAN_PESTANIA: '',
        CONTROL_GUARDAR: true
      };

      const indi = this.cliService.venservicioped[this.indicador].indexOf(this.servicioPedSelected);
      if (indi === -1) {
        serped.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.cliService.venservicioped[this.indicador].push(serped);
        this.servicioPedSelected = serped;
        this.aggrid.refreshaggrid(this.cliService.venservicioped[this.indicador], this.defaultColDefServicioPed.objeto);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_'
          + this.defaultColDefServicioPed.objeto].setFocusedCell(
            (this.cliService.venservicioped[this.indicador].length) - 1, 'TRNSERTRAN_TIPO');
      } else {
        serped.CLAVE = indi + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.cliService.venservicioped[this.indicador].splice(indi + 1, 0, serped);
        this.servicioPedSelected = serped;
        this.aggrid.refreshaggridindex(this.cliService.venservicioped[this.indicador], this.defaultColDefServicioPed.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_'
          + this.defaultColDefServicioPed.objeto].setFocusedCell(
            indi + 1, 'TRNSERTRAN_TIPO');
      }
    }

    if (this.index === 6) {
      this.enviarsenalequipo = 'Nuevo';
      this.auxiliarsenalequipo++;
    }
  }

  busquedacliente() {
    this.opcionbusqueda = 'busquedacliente';
    this.types = [
      { label: 'Código', value: 'CLI_CODIGO' },
      { label: 'Nombre', value: 'CLI_NOMBRE' },
      { label: 'RucIde', value: 'CLI_RUCIDE' },
      { label: 'Zona', value: 'CLI_ZONA' },
      { label: 'Grupo', value: 'GRU_CODIGO' },
      { label: 'Contacto', value: 'CLI_CONTACTO' },
      { label: 'Placa', value: 'CLI_PLACA' },
      { label: 'NombreC', value: 'CLI_NOMBREC' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_MAECLIENTE';
    this.where=''
    this.busqService.busquedaVenMaeCliente7().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  busquedagrupo(parametro) {
    this.opcionbusqueda = 'busquedagrupo';
    this.types = [
      { label: 'Código', value: 'GRU_CODIGO' },
      { label: 'Nombre', value: 'GRU_NOMBRE' }
    ];

    this.cliService.erNombreGrupo(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maecliente.GRU_CODIGO = parametro;
          this.maecliente.GRU_NOMBRE = eR[0].GRU_NOMBRE;
          document.getElementById('CLI_ESTCIV').focus();
        } else {
          this.busqService.busquedaVenMaeGrupo3().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusqueda = true;
          });
        }
      } else {
        this.busqService.busquedaVenMaeGrupo3().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusqueda = true;
        });
      }
    });

  }

  busquedaestciv(parametro) {
    this.opcionbusqueda = 'busquedaestciv';
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' }
    ];

    this.cliService.erNombreEstadoCivil(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maecliente.CLI_ESTCIV = parametro;
          this.maecliente.CLI_ESTCIVNOMBRE = eR[0].REF_NOMBRE;
          document.getElementById('CLI_ORGING').focus();
        } else {
          this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'ESTCIV\'').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusqueda = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'ESTCIV\'').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusqueda = true;
        });
      }
    });

  }

  busquedaorging(parametro) {
    this.opcionbusqueda = 'busquedaorging';
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' }
    ];

    this.cliService.erNombreOrigen(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maecliente.CLI_ORGING = parametro;
          this.maecliente.CLI_ORGINGNOMBRE = eR[0].REF_NOMBRE;
          document.getElementById('CLI_SEXO').focus();
        } else {
          this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'ORGING\'').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusqueda = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'ORGING\'').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusqueda = true;
        });
      }
    });

  }

  busquedasexo(parametro) {
    this.opcionbusqueda = 'busquedasexo';
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' }
    ];

    this.cliService.erNombreSexo(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maecliente.CLI_SEXO = parametro;
          this.maecliente.CLI_SEXONOMBRE = eR[0].REF_NOMBRE;
          document.getElementById('VEN_CODIGO').focus();
        } else {
          this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'SEX\'').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusqueda = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'SEX\'').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusqueda = true;
        });
      }
    });

  }

  busquedavendedor(parametro) {
    this.opcionbusqueda = 'busquedavendedor';
    this.types = [
      { label: 'Código', value: 'VEN_CODIGO' },
      { label: 'Nombre', value: 'VEN_NOMBRE' }
    ];

    this.cliService.erNombreVendedor(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maecliente.VEN_CODIGO = parametro;
          this.maecliente.VEN_NOMBRE = eR[0].VEN_NOMBRE;
          document.getElementById('CLI_DIRECCION1').focus();
        } else {
          this.busqService.busquedaVenMaeVendedor().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusqueda = true;
          });
        }
      } else {
        this.busqService.busquedaVenMaeVendedor().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusqueda = true;
        });
      }
    });
  }

  busquedacargo(parametro) {
    this.opcionbusqueda = 'busquedacargo';
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' }
    ];

    this.cliService.erNombreCargo(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maecliente.CLI_CARGO = parametro;
          this.maecliente.CLI_CARGONOMBRE = eR[0].REF_NOMBRE;
          document.getElementById('CLI_CORREO').focus();
        } else {
          this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'CRG\'').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusqueda = true;
          });

        }
      } else {
        this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'CRG\'').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusqueda = true;
        });

      }
    });
  }

  busquedaprovinciaref(parametro) {
    this.opcionbusqueda = 'busquedaprovinciaref';
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' }
    ];
    this.cliService.erNombreProvinciaref(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maecliente.CLI_PROVINCIA = parametro;
          this.maecliente.CLI_PROVINCIANOMBRE = eR[0].REF_NOMBRE;
          document.getElementById('CIU_CODIGO').focus();
        } else {
          this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'PV\'').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusqueda = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'PV\'').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusqueda = true;
        });
      }
    });
  }

  busquedaprovincia(parametro) {
    this.opcionbusqueda = 'busquedaprovincia';
    this.types = [
      { label: 'Código', value: 'PROV_CODIGO' },
      { label: 'Nombre', value: 'PROV_NOMBRE' }
    ];
   
    this.busquedacampos = [parametro, '', ''];
    this.tabla = 'VEN_MAEPROVINCIA';
    this.where=''
    this.cliService.erNombreProvincia(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maecliente.CLI_PROVINCIA = parametro;
          this.maecliente.CLI_PROVINCIANOMBRE = eR[0].PROV_NOMBRE;
          document.getElementById('CIU_CODIGO').focus();
        } else {
          this.busqService.busquedaVenMaeProvincia1().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaVenMaeProvincia1().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaciudad(parametro) {
    this.opcionbusqueda = 'busquedaciudad';
    this.types = [
      { label: 'Código', value: 'CIU_CODIGO' },
      { label: 'Nombre', value: 'CIU_NOMBRE' }
    ];
    parametro=parametro === null || parametro === undefined?'':parametro
    this.busquedacampos = [parametro, '', ''];
    this.tabla = 'VEN_MAECIUDAD';
    this.where=''
    this.cliService.erNombreCiudad(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maecliente.CIU_CODIGO = parametro;
          this.maecliente.CIU_NOMBRE = eR[0].CIU_NOMBRE;
          document.getElementById('CAN_CODIGO').focus();
        } else {
          this.busqService.busquedaVenMaeCiudad1().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaVenMaeCiudad1().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedacanton(parametro) {
    this.opcionbusqueda = 'busquedacanton';
    this.types = [
      { label: 'Código', value: 'CAN_CODIGO' },
      { label: 'Nombre', value: 'CAN_DESCRIPCION' }
    ];
    parametro=parametro === null || parametro === undefined?'':parametro
    this.busquedacampos = [parametro, '', ''];
    this.tabla = 'VEN_MAECANTON';
    this.where='PV_CODIGO=\''+this.maecliente.CLI_PROVINCIA+'\'';

    this.cliService.erNombreCanton(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maecliente.CAN_CODIGO = parametro;
          this.maecliente.CAN_NOMBRE = eR[0].CAN_DESCRIPCION;
          document.getElementById('PARR_CODIGO').focus();
        } else {
          this.busqService.busquedaVenMaeCanton1(this.maecliente.CLI_PROVINCIA).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaVenMaeCanton1(this.maecliente.CLI_PROVINCIA).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaparroquia(parametro) {
    this.opcionbusqueda = 'busquedaparroquia';
    this.types = [
      { label: 'Código', value: 'PARR_CODIGO' },
      { label: 'Nombre', value: 'PARR_DESCRIPCION' }
    ];
    parametro=parametro === null || parametro === undefined?'':parametro
    this.busquedacampos = [parametro, '', ''];
    this.tabla = 'VEN_MAEPARROQUIA';
    this.where='CAN_CODIGO=\''+this.maecliente.CAN_CODIGO+'\''
    this.cliService.erNombreParroquia(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maecliente.PARR_CODIGO = parametro;
          this.maecliente.PARR_NOMBRE = eR[0].PARR_DESCRIPCION;
          document.getElementById('CLI_ZONA').focus();
        } else {
          this.busqService.busquedaVenMaeParroquia1(this.maecliente.CAN_CODIGO).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaVenMaeParroquia1(this.maecliente.CAN_CODIGO).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });

  }

  busquedazona(parametro) {
    this.opcionbusqueda = 'busquedazona';
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' }
    ];

    this.cliService.erNombreZona(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maecliente.CLI_ZONA = parametro;
          this.maecliente.CLI_ZONANOMBRE = eR[0].REF_NOMBRE;
          document.getElementById('CLI_GMAPS').focus();
        } else {
          this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'Z\'').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusqueda = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'Z\'').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusqueda = true;
        });
      }
    });
  }

  busquedatipo(parametro) {
    this.opcionbusqueda = 'busquedatipo';
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' }
    ];

    this.cliService.erNombreSujeto(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maecliente.CLI_SUJETO = parametro;
          this.maecliente.CLI_SUJETONOMBRE = eR[0].REF_NOMBRE;
          document.getElementById('CLI_DESCUENTO').focus();
        } else {
          this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'CLASUJ\'').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusqueda = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'CLASUJ\'').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusqueda = true;
        });
      }
    });

  }

  busquedagmaps(parametro) {
    window.open(parametro, '_blank');
  }

  busquedabanco(parametro) {
    this.opcionbusqueda = 'busquedabanco';
    this.types = [
      { label: 'Código', value: 'BANCLI_CODIGO' },
      { label: 'Nombre', value: 'BANCLI_NOMBRE' },
      { label: 'Estado', value: 'BANCLI_ESTADO' },
      { label: 'Nombre', value: 'COM_CODIGO' },
    ];
    this.cliService.erNombreBanco(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maecliente.BANCLI_CODIGO = parametro;
          this.maecliente.BANCLI_NOMBRE = eR[0].BANCLI_NOMBRE;
          document.getElementById('CLI_NROCUENTA').focus();
        } else {
          this.busqService.busquedaCXCMaeBanCli3().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusqueda = true;
          });
        }
      } else {
        this.busqService.busquedaCXCMaeBanCli3().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusqueda = true;
        });
      }
    });
  }

  busquedaformapago(parametro) {
    this.opcionbusqueda = 'busquedaformapago';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Nombre', value: 'CODSRI_DESCRIPCION' }
    ];
    this.cliService.erNombreFormaPago(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maecliente.CLI_FORMAPAGO_CODSRI = parametro;
          this.maecliente.CLI_FORMAPAGO_CODSRINOMBRE = eR[0].CODSRI_DESCRIPCION;
          document.getElementById('CLI_ESTADO').focus();
        } else {
          this.busqService.busquedaAnexMaeCodSri17().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusqueda = true;
          });
        }
      } else {
        this.busqService.busquedaAnexMaeCodSri17().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusqueda = true;
        });
      }
    });
  }

  busquedaestado(parametro) {
    this.opcionbusqueda = 'busquedaestado';
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' }
    ];
    this.cliService.erNombreEstado(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maecliente.CLI_ESTADO = parametro;
          this.maecliente.CLI_ESTADONOMBRE = eR[0].REF_NOMBRE;
          document.getElementById('CON_CODIGO1').focus();
        } else {
          this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'ET\'').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusqueda = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'ET\'').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusqueda = true;
        });
      }
    });
  }


  busquedaconcodigo(concodigo, parametro) {
    this.opcionbusqueda = 'busquedaconcodigo' + concodigo;
    this.types = [
      { label: 'Código', value: 'CON_CODIGO' },
      { label: 'Nombre', value: 'CON_NOMBRE' },
      { label: 'Cnt.Costos', value: 'CON_CENTRO' },
      { label: 'Cod.Sri', value: 'CON_CODSRI' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'CON_MAECON';
    this.where=''
    this.cliService.erNombreConcodigo(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          if (concodigo === '1') {
            //GSRF
            if (parametro.substr(-1, 1) === '.') {
              this.messageService.add({
                key: 'cliente',
                severity: 'error',
                summary: 'Cuenta Contable',
                detail: 'No se puede elegir esa cuenta contable'
              });
              this.maecliente.CON_CODIGO1 = '';
              this.maecliente.CON_CODIGO1NOMBRE = '';
              document.getElementById('CON_CODIGO2').focus();
              return;
            }
            //GSRF
            this.maecliente.CON_CODIGO1 = parametro;
            this.maecliente.CON_CODIGO1NOMBRE = eR[0].CON_NOMBRE;
            document.getElementById('CON_CODIGO2').focus();
          }
          if (concodigo === '2') {
            //GSRF
            if (parametro.substr(-1, 1) === '.') {
              this.messageService.add({
                key: 'cliente',
                severity: 'error',
                summary: 'Cuenta Contable',
                detail: 'No se puede elegir esa cuenta contable'
              });
              this.maecliente.CON_CODIGO2 = '';
              this.maecliente.CON_CODIGO2NOMBRE = '';
              document.getElementById('CON_CODIGO2').focus();
              return;
            }
            //GSRF
            this.maecliente.CON_CODIGO2 = parametro;
            this.maecliente.CON_CODIGO2NOMBRE = eR[0].CON_NOMBRE;
            document.getElementById('CON_CODIGO3').focus();
          }
          if (concodigo === '3') {
            //GSRF
            if (parametro.substr(-1, 1) === '.') {
              this.messageService.add({
                key: 'cliente',
                severity: 'error',
                summary: 'Cuenta Contable',
                detail: 'No se puede elegir esa cuenta contable'
              });
              this.maecliente.CON_CODIGO3 = '';
              this.maecliente.CON_CODIGO3NOMBRE = '';
              document.getElementById('CON_CODIGO2').focus();
              return;
            }
            //GSRF
            this.maecliente.CON_CODIGO3 = parametro;
            this.maecliente.CON_CODIGO3NOMBRE = eR[0].CON_NOMBRE;
            document.getElementById('CEN_CODIGO').focus();
          }
        } else {
          this.busqService.busquedaConMaeCon2().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaConMaeCon2().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedacencodigo(parametro) {
    this.opcionbusqueda = 'busquedacencodigo';
    this.types = [
      { label: 'Código', value: 'CEN_CODIGO' },
      { label: 'Nombre', value: 'CEN_NOMBRE' }
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'CON_MAECEN';
    this.where=''
    this.cliService.erNombreCencos(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maecliente.CEN_CODIGO = parametro;
          this.maecliente.CEN_CODIGONOMBRE = eR[0].CEN_NOMBRE;
          document.getElementById('CLI_LOCAL').focus();

        } else {
          this.busqService.busquedaConMaeCen3().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaConMaeCen3().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });

  }


  manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'busquedacliente') {
      this.encontrarCliente(opcion.CLI_CODIGO);
    } else {
      this.botonesmodificar();
    }

    if (this.opcionbusqueda === 'busquedagrupo') {
      this.maecliente.GRU_CODIGO = opcion.GRU_CODIGO;
      this.maecliente.GRU_NOMBRE = opcion.GRU_NOMBRE;
      document.getElementById('CLI_ESTCIV').focus();
    }
    if (this.opcionbusqueda === 'busquedaestciv') {
      this.maecliente.CLI_ESTCIV = opcion.REF_CODIGO;
      this.maecliente.CLI_ESTCIVNOMBRE = opcion.REF_NOMBRE;
      document.getElementById('CLI_ORGING').focus();
    }

    if (this.opcionbusqueda === 'busquedaorging') {
      this.maecliente.CLI_ORGING = opcion.REF_CODIGO;
      this.maecliente.CLI_ORGINGNOMBRE = opcion.REF_NOMBRE;
      document.getElementById('CLI_SEXO').focus();
    }

    if (this.opcionbusqueda === 'busquedasexo') {
      this.maecliente.CLI_SEXO = opcion.REF_CODIGO;
      this.maecliente.CLI_SEXONOMBRE = opcion.REF_NOMBRE;
      document.getElementById('VEN_CODIGO').focus();
    }
    if (this.opcionbusqueda === 'busquedavendedor') {
      this.maecliente.VEN_CODIGO = opcion.VEN_CODIGO;
      this.maecliente.VEN_NOMBRE = opcion.VEN_NOMBRE;
      document.getElementById('CLI_DIRECCION1').focus();
    }
    if (this.opcionbusqueda === 'busquedacargo') {
      this.maecliente.CLI_CARGO = opcion.REF_CODIGO;
      this.maecliente.CLI_CARGONOMBRE = opcion.REF_NOMBRE;
      document.getElementById('CLI_CORREO').focus();
    }
    if (this.opcionbusqueda === 'busquedaprovinciaref') {
      this.maecliente.CLI_PROVINCIA = opcion.REF_CODIGO;
      this.maecliente.CLI_PROVINCIANOMBRE = opcion.REF_NOMBRE;
      document.getElementById('CIU_CODIGO').focus();
    }
    if (this.opcionbusqueda === 'busquedaprovincia') {
      this.maecliente.CLI_PROVINCIA = opcion.PROV_CODIGO;
      this.maecliente.CLI_PROVINCIANOMBRE = opcion.PROV_NOMBRE;
      document.getElementById('CIU_CODIGO').focus();
    }
    if (this.opcionbusqueda === 'busquedaciudad') {
      this.maecliente.CIU_CODIGO = opcion.CIU_CODIGO;
      this.maecliente.CIU_NOMBRE = opcion.CIU_NOMBRE;
      document.getElementById('CAN_CODIGO').focus();
    }
    if (this.opcionbusqueda === 'busquedacanton') {
      this.maecliente.CAN_CODIGO = opcion.CAN_CODIGO;
      this.maecliente.CAN_NOMBRE = opcion.CAN_DESCRIPCION;
      document.getElementById('PARR_CODIGO').focus();
    }
    if (this.opcionbusqueda === 'busquedaparroquia') {
      this.maecliente.PARR_CODIGO = opcion.PARR_CODIGO;
      this.maecliente.PARR_NOMBRE = opcion.PARR_DESCRIPCION;
      document.getElementById('CLI_ZONA').focus();
    }
    if (this.opcionbusqueda === 'busquedazona') {
      this.maecliente.CLI_ZONA = opcion.REF_CODIGO;
      this.maecliente.CLI_ZONANOMBRE = opcion.REF_NOMBRE;
      document.getElementById('CLI_GMAPS').focus();
    }
    if (this.opcionbusqueda === 'busquedatipo') {
      this.maecliente.CLI_SUJETO = opcion.REF_CODIGO;
      this.maecliente.CLI_SUJETONOMBRE = opcion.REF_NOMBRE;
      document.getElementById('CLI_DESCUENTO').focus();
    }
    if (this.opcionbusqueda === 'busquedabanco') {
      this.maecliente.BANCLI_CODIGO = opcion.BANCLI_CODIGO;
      this.maecliente.BANCLI_NOMBRE = opcion.BANCLI_NOMBRE;
      document.getElementById('CLI_NROCUENTA').focus();
    }
    if (this.opcionbusqueda === 'busquedaformapago') {
      this.maecliente.CLI_FORMAPAGO_CODSRI = opcion.CODSRI_CODIGO;
      this.maecliente.CLI_FORMAPAGO_CODSRINOMBRE = opcion.CODSRI_DESCRIPCION;
      document.getElementById('CLI_ESTADO').focus();
    }
    if (this.opcionbusqueda === 'busquedaestado') {
      this.maecliente.CLI_ESTADO = opcion.REF_CODIGO;
      this.maecliente.CLI_ESTADONOMBRE = opcion.REF_NOMBRE;
      document.getElementById('CON_CODIGO1').focus();
    }
    if (this.opcionbusqueda === 'busquedaconcodigo1') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cliente',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.maecliente.CON_CODIGO1 = opcion.CON_CODIGO;
        this.maecliente.CON_CODIGO1NOMBRE = opcion.CON_NOMBRE;
        document.getElementById('CON_CODIGO2').focus();
      }
    }
    if (this.opcionbusqueda === 'busquedaconcodigo2') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cliente',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.maecliente.CON_CODIGO2 = opcion.CON_CODIGO;
        this.maecliente.CON_CODIGO2NOMBRE = opcion.CON_NOMBRE;
        document.getElementById('CON_CODIGO3').focus();
      }
    }
    if (this.opcionbusqueda === 'busquedaconcodigo3') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cliente',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.maecliente.CON_CODIGO3 = opcion.CON_CODIGO;
        this.maecliente.CON_CODIGO3NOMBRE = opcion.CON_NOMBRE;
        document.getElementById('CEN_CODIGO').focus();
      }
    }
    if (this.opcionbusqueda === 'busquedacencodigo') {
      this.maecliente.CEN_CODIGO = opcion.CEN_CODIGO;
      this.maecliente.CEN_CODIGONOMBRE = opcion.CEN_NOMBRE;
      document.getElementById('CLI_LOCAL').focus();
    }
    this.displayDialogBusqueda = false;
    this.displayDialogBusquedaFast = false;
  }

  async guardar() {

    //GSRF
    if(this.maecliente.CON_CODIGO1 !== null){
      if (this.maecliente.CON_CODIGO1.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cliente',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable en Referencia Contable'
        });
        this.maecliente.CON_CODIGO1 = '';
        this.maecliente.CON_CODIGO1NOMBRE = '';
        this.index=1;
        document.getElementById('CON_CODIGO1').focus();
        return;
      }
    }
     //GSRF
     if(this.maecliente.CON_CODIGO2 !== null){
     if (this.maecliente.CON_CODIGO2.substr(-1, 1) === '.') {
      this.messageService.add({
        key: 'cliente',
        severity: 'error',
        summary: 'Cuenta Contable',
        detail: 'No se puede elegir esa cuenta contable'
      });
      this.maecliente.CON_CODIGO2 = '';
      this.maecliente.CON_CODIGO2NOMBRE = '';
      document.getElementById('CON_CODIGO2').focus();
      return;
    }
  }
    //GSRF
  
    //GSRF
    if(this.maecliente.CON_CODIGO3 !== null){
    if (this.maecliente.CON_CODIGO3.substr(-1, 1) === '.') {
      this.messageService.add({
        key: 'cliente',
        severity: 'error',
        summary: 'Cuenta Contable',
        detail: 'No se puede elegir esa cuenta contable'
      });
      this.maecliente.CON_CODIGO3 = '';
      this.maecliente.CON_CODIGO3NOMBRE = '';
      document.getElementById('CON_CODIGO3').focus();
      return;
    }
  }
    //GSRF
    //GSRF
    if (this.index === 0 || this.index === 1) {
      if (this.lblvalida === '' || this.lblvalida === 'Mal') {
        this.messageService.add({
          key: 'cliente',
          severity: 'warn',
          summary: 'Información',
          detail: 'Identificación incorrecta'
        });
        return;
      }
      if (this.maecliente.CLI_CODIGO === '' ||
        this.maecliente.CLI_NOMBRE === '' ||
        this.maecliente.CLI_NOMBREC === '' ||
        this.maecliente.CLI_RUCIDE === '' ||
        this.maecliente.GRU_CODIGO === '' ||
        this.maecliente.VEN_CODIGO === '' ||
        this.maecliente.CLI_DIRECCION1 === '' ||
        this.maecliente.CLI_TELEFONO1 === '' ||
        this.maecliente.CLI_CORREO === '' ||
        this.maecliente.CON_CODIGO1 === '' ||
        this.maecliente.CON_CODIGO2 === '') {
        this.messageService.add({
          key: 'cliente',
          severity: 'warn',
          summary: 'Información',
          detail: 'Los campos Código, Nombre, Nombre corto, RucIde, Grupo, Vendedor, Dirección, Teléfono, Correo y Referencia Contable ' +
            'son obligatorios revise que los datos sean validos'
        });
        return;
      }
      if (this.chkunificado === true) {
        this.maecliente.CLI_UNIFICA = 'S';
      } else {
        this.maecliente.CLI_UNIFICA = 'N';
      }
      if (this.chktarjetadesc === true) {
        this.maecliente.CLI_TARDSC = 'S';
      } else {
        this.maecliente.CLI_TARDSC = 'N';
      }
      if (this.chkexcluye === true) {
        this.maecliente.CLI_EXCLUYE = 'S';
      } else {
        this.maecliente.CLI_EXCLUYE = 'N';
      }
      if (this.chkfacturacionelec === true) {
        this.maecliente.CLI_FACTURAELECT = 'S';
      } else {
        this.maecliente.CLI_FACTURAELECT = 'N';
      }
      if (this.chkjefezonal === true) {
        this.maecliente.CLI_JEFE = 'S';
      } else {
        this.maecliente.CLI_JEFE = 'N';
      }
      if (this.chklider === true) {
        this.maecliente.CLI_LIDER = 'S';
      } else {
        this.maecliente.CLI_LIDER = 'N';
      }
      if (this.chkconregmic === true) {
        this.maecliente.CLI_CONREGMIC = 'S';
      } else {
        this.maecliente.CLI_CONREGMIC = 'N';
      }
      if (this.chkagenteret === true) {
        this.maecliente.CLI_AGENTERET = 'S';
      } else {
        this.maecliente.CLI_AGENTERET = 'N';
      }

      if (this.chkconregrimpe === true) {
        this.maecliente.CLI_CONREGRIMPE = 'S';
      } else {
        this.maecliente.CLI_CONREGRIMPE = 'N';
      }

      this.maecliente.CLI_IVA = this.selectiva.codigo;
      this.maecliente.CLI_TIPODEN = this.selectdenominacion.codigo;
      this.maecliente.CLI_CODIGO=this.maecliente.CLI_CODIGO.toString().toUpperCase();
      console.log(this.maecliente.CLI_CODIGO);
      if (this.maecliente.CLI_CODIGO !== null) {
        let count=0;
        if (this.Nodupruc !== 1) {
          const formsricom = await this.cliService.erExisteClienteCod(this.maecliente.CLI_CODIGO).toPromise();
          if (formsricom[0] !== undefined) {
            if (Number(formsricom[0].COUNT) > 0) {
              count=Number(formsricom[0].COUNT)
              
            }
          }
        }
          if(count>0 && this.bolcliente === false){
            this.messageService.add({
              key: 'cliente',
              severity: 'warn',
              summary: 'Información',
              detail: 'Cliente ya existe'
            });
            this.lblvalida = "Mal";
            return;
          }
        this.cliService.erExisteClienteCod(this.maecliente.CLI_CODIGO).subscribe((formsricom) => {
          if (formsricom[0] !== undefined) {
            if (Number(formsricom[0].COUNT) > 0) {
              this.cliService.actualizarCliente(this.maecliente).subscribe((datos: any[]) => {
                this.cliService.actualizarDatosAdicionales(this.maecliente).subscribe();
                this.messageService.add({
                  key: 'cliente',
                  severity: 'success',
                  summary: 'Información',
                  detail: 'El cliente se actualizó correctamente'
                });
                this.botonNuevo = false;
                this.botonGuardar = true;
                this.botonBorrar = false;
                this.botonRegresar = true;
                this.cliService.erTipoIdentifi(this.maecliente.CLI_CODIGO).subscribe((tipoCli) => {
                  if (String(tipoCli[0].CLI_TIPOIDE) === '6') {
                    this.disabledEstadisticas = true;
                  } else {
                    this.disabledEstadisticas = false;
                  }
                });
                this.cliService.actualizarValidacion(this.lblvalida, this.maecliente.CLI_CODIGO).subscribe();
                this.auditoriagral.registrarAuditoria('VEN_MAECLIENTE', this.maecliente.CLI_CODIGO, 'A',
                  '', '01', '', '', '', '').subscribe();
                  //CDPJ AUDITORIA CORREO 
                this.auditoriagral.registrarAuditoria('VEN_MAECLIENTE', this.maecliente.CLI_CODIGO+'/'+
                this.anticorreo1+'-'+this.anticorreo2+'/'+this.maecliente.CLI_CORREO+'-'+this.maecliente.CLI_CORREO2
                , 'A',
                '', '01', '', '', '', '').subscribe();
                for(const data of this.etiquetasaudi){
                  if(data.name !== ''){
                    if(data.codigo === 'CLI_VALIDACION'){
                      this.auditoriagral.registrarAuditoria('VEN_MAECLIENTE', this.maecliente.CLI_CODIGO+'/'+
                      data.name+'/'+ (this.lblvalida === null || this.lblvalida === undefined?'':this.lblvalida)
                      , 'A',
                      '', '01', '', '', '', '').subscribe();
                    }else{
                      if(data.codigo === 'CLI_FECHANACIMIENTO' || data.codigo === 'CLI_FECING'){
                        this.auditoriagral.registrarAuditoria('VEN_MAECLIENTE', this.maecliente.CLI_CODIGO+'/'+
                        data.name+'/'+ (this.maecliente[data.codigo] === null || this.maecliente[data.codigo] === undefined?'': this.datePipe.transform(this.maecliente[data.codigo], 'dd/MM/yyyy'))
                        , 'A',
                        '', '01', '', '', '', '').subscribe();
                      }else{
                        this.auditoriagral.registrarAuditoria('VEN_MAECLIENTE', this.maecliente.CLI_CODIGO+'/'+
                        data.name+'/'+ (this.maecliente[data.codigo] === null || this.maecliente[data.codigo] === undefined?'':this.maecliente[data.codigo])
                        , 'A',
                        '', '01', '', '', '', '').subscribe();
                      }
                     
                    }
                  }
                  
                }
                //CDPJ
              }, error1 => {
                let mensaje: string;
                mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                this.messageService.add({
                  key: 'cliente',
                  severity: 'error',
                  summary: 'Actualizar cliente',
                  detail: mensaje
                });
              });
            } else {
              this.cliService.guardarCliente(this.maecliente).subscribe((datos: any[]) => {
                this.cliService.actualizarDatosAdicionales(this.maecliente).subscribe();
                this.messageService.add({
                  key: 'cliente',
                  severity: 'success',
                  summary: 'Información',
                  detail: 'El cliente se insertó correctamente'
                });
                this.botonNuevo = false;
                this.botonGuardar = true;
                this.botonBorrar = false;
                this.botonRegresar = true;
                this.cliService.erTipoIdentifi(this.maecliente.CLI_CODIGO).subscribe((tipoCli) => {
                  if (String(tipoCli[0].CLI_TIPOIDE) === '6') {
                    this.disabledEstadisticas = true;
                  } else {
                    this.disabledEstadisticas = false;
                  }
                });
                this.cliService.actualizarValidacion(this.lblvalida, this.maecliente.CLI_CODIGO).subscribe();
                this.auditoriagral.registrarAuditoria('VEN_MAECLIENTE', this.maecliente.CLI_CODIGO, 'I',
                  '', '01', '', '', '', '').subscribe();
                  for(const data of this.etiquetasaudi){
                    if(data.name !== ''){
                      if(data.codigo === 'CLI_VALIDACION'){
                        this.auditoriagral.registrarAuditoria('VEN_MAECLIENTE', this.maecliente.CLI_CODIGO+'/'+
                        data.name+'/'+ (this.lblvalida === null || this.lblvalida === undefined?'':this.lblvalida)
                        , 'I',
                        '', '01', '', '', '', '').subscribe();
                      }else{
                        if(data.codigo === 'CLI_FECHANACIMIENTO' || data.codigo === 'CLI_FECING'){
                          this.auditoriagral.registrarAuditoria('VEN_MAECLIENTE', this.maecliente.CLI_CODIGO+'/'+
                          data.name+'/'+ (this.maecliente[data.codigo] === null || this.maecliente[data.codigo] === undefined?'': this.datePipe.transform(this.maecliente[data.codigo], 'dd/MM/yyyy'))
                          , 'I',
                          '', '01', '', '', '', '').subscribe();
                        }else{
                          this.auditoriagral.registrarAuditoria('VEN_MAECLIENTE', this.maecliente.CLI_CODIGO+'/'+
                          data.name+'/'+ (this.maecliente[data.codigo] === null || this.maecliente[data.codigo] === undefined?'':this.maecliente[data.codigo])
                          , 'I',
                          '', '01', '', '', '', '').subscribe();
                        }
                      }                    
                    }
                  }
                  
              }, error1 => {
                let mensaje: string;
                mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                this.messageService.add({
                  key: 'cliente',
                  severity: 'error',
                  summary: 'Insertar cliente',
                  detail: mensaje
                });
              });
            }
          }
        });
      }
    }

    if (this.index === 5) {
      for (const venser of this.cliService.venservicioped[this.indicador]) {
        if (venser.CONTROL_GUARDAR === true) {
          await this.cliService.guardarventrnsertran(venser);
          this.auditoriagral.registrarAuditoria('VEN_TRNSERTRAN',
            this.maecliente.CLI_CODIGO + '/' + this.servicioPedSelected.SER_CODIGO, 'I',
            '', '01', '', '', '', '').subscribe();
        } else {
          await this.cliService.actualizarventrnsertran(venser);
          this.auditoriagral.registrarAuditoria('VEN_TRNSERTRAN',
            this.maecliente.CLI_CODIGO + '/' + this.servicioPedSelected.SER_CODIGO, 'A',
            '', '01', '', '', '', '').subscribe();
        }
      }
      this.messageService.add({
        key: 'cliente',
        severity: 'success',
        summary: 'Guardar',
        detail: 'Datos de servicios guardados correctamente'
      });
    }

    if (this.index === 6) {
      this.enviarsenalequipo = 'Guardar';
      this.auxiliarsenalequipo++;
    }
  }

  async eliminar() {

    if (this.index === 0 || this.index === 1) {
      if (this.maecliente.CLI_CODIGO === null || this.maecliente.CLI_CODIGO === undefined ||
        this.maecliente.CLI_CODIGO === '') {
        return;
      }
      this.cliService.eliminarCliente(this.maecliente).subscribe((datos: any[]) => {
        this.messageService.add({
          key: 'cliente',
          severity: 'success',
          summary: 'Información',
          detail: 'El cliente se eliminó correctamente'
        });
        this.botonNuevo = false;
        this.botonGuardar = true;
        this.botonBorrar = false;
        this.botonRegresar = true;
        this.limpiartablas();
      }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'cliente',
          severity: 'error',
          summary: 'Eliminar cliente',
          detail: mensaje
        });
      });
    }

    if (this.index === 5) {

      if (this.servicioPedSelected.CONTROL_GUARDAR === true) {
        this.cliService.venservicioped[this.indicador].splice(this.cliService.venservicioped[this.indicador].findIndex(
          dato => dato.CLAVE === this.servicioPedSelected.CLAVE), 1);
        this.messageService.add({
          key: 'cliente',
          severity: 'success',
          summary: 'Información',
          detail: 'El detalle se eliminó correctamente'
        });
      } else {
        await this.cliService.eliminarventrnsertran(this.servicioPedSelected);
        this.cliService.venservicioped[this.indicador].splice(this.cliService.venservicioped[this.indicador].findIndex(
          dato => dato.CLAVE === this.servicioPedSelected.CLAVE), 1);
        this.auditoriagral.registrarAuditoria('VEN_TRNSERTRAN',
          this.maecliente.CLI_CODIGO + '/' + this.servicioPedSelected.SER_CODIGO, 'E',
          '', '01', '', '', '', '').subscribe();
        this.messageService.add({
          key: 'cliente',
          severity: 'success',
          summary: 'Información',
          detail: 'El detalle se eliminó correctamente'
        });
      }
      this.aggrid.refreshaggrid(this.cliService.venservicioped[this.indicador], this.defaultColDefServicioPed.objeto);

    }

    if (this.index === 6) {
      this.enviarsenalequipo = 'Borrar';
      this.auxiliarsenalequipo++;
    }
  }

  async cancelar() {
    if (this.index === 6) {
      this.enviarsenalequipo = 'Cancelar';
      this.auxiliarsenalequipo++;
      return;
    }

    if (this.index < 2) {
      this.botonNuevo = false;
      this.botonGuardar = true;
      this.botonRegresar = true;
    }
    const clicodigo = this.maecliente.CLI_CODIGO;
    await this.limpiartablas();
    this.encontrarCliente(clicodigo);

  }

  cambio() {
  }

  // COMBOS

  busqide() {
    this.maecliente.CLI_TIPOIDE = this.selectide.codigo;
    this.maecliente.CLI_TIPOIDENOMBRE = this.selectide.name;
    this.maecliente.CLI_RUCIDE = '';
    this.botonesmodificar();
  }

  busqcontribuyente(cont) {
    this.maecliente.CLI_CONTRIBUYENTE = cont.value.codigo;
    this.maecliente.CLI_CONTRIBUYENTENOMBRE = cont.value.name;
    this.botonesmodificar();
  }

  busqparterelacionada(parte) {
    this.maecliente.CLI_PARTEREL = parte.value.codigo;
    this.maecliente.CLI_PARTERELNOMBRE = parte.value.name;
    this.botonesmodificar();
  }

  busqtipo(parte) {
    this.maecliente.CLI_TIPO = parte.value.codigo;
    this.maecliente.CLI_TIPONOMBRE = parte.value.name;
    this.botonesmodificar();
  }

  busqlista(lista) {
    this.maecliente.CLI_LISTA = lista.value.name;
    this.botonesmodificar();
  }

  busqiva(iva) {
    this.maecliente.CLI_IVA = iva.value.codigo;
    this.maecliente.CLI_IVANOMBRE = iva.value.name;
    this.botonesmodificar();
  }

  llenarCombos() {

    this.cmbide = [{ codigo: '1', name: 'R.U.C.' },
    { codigo: '2', name: 'Cédula' },
    { codigo: '3', name: 'Pasaporte' },
    { codigo: '4', name: 'Placa' },
    { codigo: '5', name: 'Extranjero' },
    { codigo: '6', name: 'Consumidor Final' }];


    this.cmbcontribuyente = [{ codigo: 'N', name: 'Normal' },
    { codigo: 'E', name: 'Especial' }];

    this.cmbparterelacionada = [{ codigo: '', name: '' },
    { codigo: 'SI', name: 'SI' },
    { codigo: 'NO', name: 'NO' }];

    /* const sentenciatipo = 'SELECT * FROM SACI_MAEREFERENCIA WHERE REF_TIPO=\'TIPCLI\' ORDER BY (REF_CODIGO)'; // consulta
    this.cliService.ejecutarConsulta(sentenciatipo).subscribe(data => {
      this.cmbtipo = [];
      for (const rs of data) {
        this.cmbtipo.push({codigo: rs.REF_CODIGO, name: rs.REF_NOMBRE});
      }
    }); */

    this.cmbtipo = [{ codigo: '1', name: 'NORMAL' },
    { codigo: '2', name: 'CLIENTE BLOQUEADO' },
    { codigo: '3', name: 'PAGO SOLO EFECTIVO' }];

    this.cliService.consultarListaPrecios().subscribe(data => {
      this.cmblista = [];
      for (const rs of data) {
        this.cmblista.push({ codigo: rs.ARTPRE_CODIGO, name: rs.ARTPRE_CODIGO });
      }
    });


    this.cmbiva = [{ codigo: '0', name: 'Exento' },
    { codigo: '1', name: 'Neto + Iva' },
    { codigo: '2', name: 'Incluido Iva' }];

    this.cmbdenominacion = [{ codigo: '0', name: '' },
    { codigo: 'Natural', name: 'Natural' },
    { codigo: 'Sociedad', name: 'Sociedad' }];
    this.etiquetasaudi=[
      {codigo:'CLI_TIPOIDENOMBRE',name:'TIPO ID'},
      {codigo:'CLI_RUCIDE',name:'RUC / IDE'},{codigo:'CLI_CODIGO',name:'Código'},{codigo:'CLI_NOMBRE',name:'Nombre:'},
      {codigo:'CLI_NOMBREC',name:'Nombre Corto'},{codigo:'CLI_CONTACTO',name:'Contacto'},{codigo:'CLI_FECING',name:'Fecha Emisión'},
      {codigo:'GRU_CODIGO',name:'Grupo'},{codigo:'CLI_FECHANACIMIENTO',name:'Fecha Nacimiento'},
      {codigo:'CLI_ESTCIV',name:'Estado Civil'},{codigo:'CLI_ORGING',name:'Org. Ingresos'},
      {codigo:'CLI_SEXO',name:'Sexo'},{codigo:'CLI_UNIFICA',name:'Unificado'},{codigo:'CLI_EXCLUYE',name:'Excluye'},
      {codigo:'VEN_CODIGO',name:'Vendedor'},{codigo:'CLI_DIRECCION1',name:'Direccion1'},{codigo:'CLI_DIRECCION2',name:'Direccion2'},
      {codigo:'CLI_TELEFONO1',name:'Teléfono1'},{codigo:'CLI_TELEFONO2',name:'Teléfono2'},{codigo:'CLI_FAX',name:'Fax'},
      {codigo:'CLI_CODPOSTAL',name:'Cód. Postal'},{codigo:'CLI_CARGO',name:'Cargo'},{codigo:'CLI_CORREO',name:'Email 1'},
      {codigo:'CLI_CORREO2',name:'Email 2'},{codigo:'CLI_PROVINCIA',name:'Provincia'},{codigo:'CIU_CODIGO',name:'Ciudad'},
      {codigo:'CAN_CODIGO',name:'Cantón'},{codigo:'PARR_CODIGO',name:'Parroquia'},{codigo:'CLI_ZONA',name:'Zona'},
      {codigo:'CLI_OBSERVACION',name:'Observación'},{codigo:'CLI_CONTRIBUYENTENOMBRE',name:'Contribuyente'},{codigo:'CLI_PARTERELNOMBRE',name:'Parte Relacionada'},
      {codigo:'CLI_SUJETO',name:'Tipo Cliente'},{codigo:'CLI_TIPONOMBRE',name:'Tipo'},{codigo:'CLI_LIDER',name:'Líder'},
      {codigo:'CLI_JEFE',name:'Jefe Zonal'},{codigo:'CLI_DESCUENTO',name:'Descuento'},{codigo:'CLI_DESCUENTOLIM',name:'Descuento Límite'},
      {codigo:'CLI_FACTURAELECT',name:'Facturación Electrónica'},{codigo:'CLI_LIMCREDIT',name:'Límite de Crédito'},{codigo:'CLI_DIACREDIT',name:'Días Crédito'},
      {codigo:'CLI_DIACHPOS',name:'Días Cheque Pos'},{codigo:'BANCLI_CODIGO',name:'Banco Cheques'},{codigo:'CLI_NROCUENTA',name:'Cta. Banco Cheques'},
      {codigo:'CLI_FORMAPAGO_CODSRI',name:'Forma Pago Cod. Sri'},{codigo:'CLI_TARDSC',name:'Cliente con tarjeta descuento'},{codigo:'CLI_ESTADO',name:'Estado'},
      {codigo:'CLI_LISTA',name:'Lista'},{codigo:'CLI_IVANOMBRE',name:'I.V.A'},{codigo:'CON_CODIGO1',name:'Referencia Contable'},
      {codigo:'CON_CODIGO2',name:'Ref.a Cont. Ant'},{codigo:'CON_CODIGO3',name:'Ref. Cont. Descuento'},{codigo:'CEN_CODIGO',name:'Centro de Costos'},
      {codigo:'CLI_LOCAL',name:'Local'},{codigo:'CLI_PLACA',name:'Placa'},{codigo:'CLI_PRECIOAC20',name:'AC-20'},
      {codigo:'CLI_PRECIORC250',name:'RC-250'},{codigo:'CLI_NUMEROCUOTAS',name:'N° Cuotas'},{codigo:'CLI_INCREMENTO',name:'Incremento'},
      {codigo:'CLI_CONREGRIMPE',name:'Régimen RIMPE'},{codigo:'CLI_AGENTERET',name:'Agente de retención'},{codigo:'CLI_VALIDACION',name:'Validación'},{codigo:'',name:''},
    ]
  }

  async validaColocarLabels(tipo) {
    if (tipo === 1) {
      this.lblvalida = "Bien";
    }
    if (tipo === 2) {
      this.lblvalida = "Mal";
    }

    if (tipo === 3) {
      this.lblvalida = "Autoriza";
    }

    // PARA PASAPORTES
    if (tipo === 4) {
      this.lblvalida = "Bien";
    }

    if (tipo === 6) {
      this.lblvalida = "SRI";
    }

    let codcliente = '';
    console.log('this.bolcliente',this.bolcliente)
    if (this.bolcliente === false) {
      codcliente = this.maecliente.CLI_RUCIDE;
    } else {
      codcliente = this.maecliente.CLI_CODIGO;
    }
    if (this.bolcliente === false) {
      this.maecliente.CLI_CODIGO = this.maecliente.CLI_RUCIDE;
    }

    // const formsricom = await this.cliService.erExisteCliente(this.maecliente.CLI_RUCIDE, codcliente).toPromise();
    // if (formsricom[0] !== undefined) {
    //   if (Number(formsricom[0].COUNT) > 0) {
    //     this.messageService.add({
    //       key: 'cliente',
    //       severity: 'warn',
    //       summary: 'Información',
    //       detail: 'Cliente ya existe'
    //     });
    //     this.lblvalida = "Mal";
    //   } else {
    //     if (this.bolcliente === false) {
    //       this.maecliente.CLI_CODIGO = this.maecliente.CLI_RUCIDE;
    //     }
    //   }
    // }
     //  CDPJ
     if (this.Nodupruc === 1) {
      const formsricom = await this.cliService.erExisteCliente(this.maecliente.CLI_RUCIDE, codcliente).toPromise();
      if (formsricom[0] !== undefined) {
        if (Number(formsricom[0].COUNT) > 0) {
          this.messageService.add({
            key: 'cliente',
            severity: 'warn',
            summary: 'Información',
            detail: 'Cliente ya existe'
          });
          this.lblvalida = "Mal";
          this.maecliente.CLI_CODIGO=''
        } else {
          if (this.bolcliente === false) {
            this.maecliente.CLI_CODIGO = this.maecliente.CLI_RUCIDE;
          }
        }
      }
     }//else{
    //   const formsricom = await this.cliService.erExisteClienteCod(codcliente).toPromise();
    //   if (formsricom[0] !== undefined) {
    //     if (Number(formsricom[0].COUNT) > 0) {
    //       this.messageService.add({
    //         key: 'cliente',
    //         severity: 'warn',
    //         summary: 'Información',
    //         detail: 'Cliente ya existe'
    //       });
    //       this.lblvalida = "Mal";
    //     } else {
    //       if (this.bolcliente === false) {
    //         this.maecliente.CLI_CODIGO = this.maecliente.CLI_RUCIDE;
    //       }
    //     }
    //   }
    // }
    //CDPJ

  }

  verificaId(identificacion, tipo): boolean {
    const numero = identificacion;
    let bollong = false;
    let bolTipo = false;
    if (numero.trim().length === 10 || numero.trim().length === 13) {
      bollong = true;
    }

    if (!bollong) {
      // CompositeUtilities.openError(,"Error","La longitud del
      // identificador deve ser de 10 o de 13 para poder verificarlo.);
      console.log('La longitud del identificador  deve ser de 10 o de 13 para poder verificarlo.');
      // errorValidacionId = "La longitud del identificador  deve ser de 10 o de 13 para poder verificarlo.";
      return false;
    }

    switch (tipo) {
      case 1:
        if (numero.trim().length === 10) {
          bolTipo = true;
        }
        break;

      case 2:
        if (numero.trim().length === 13) {
          bolTipo = true;
        }
        break;
      default:
        break;
    }

    if (!bolTipo) {
      // CompositeUtilities.openError(,"Error","La longitud del
      // identificador deve ser de 10 o de 13 para poder verificarlo.);
      console.log('El numero ingresado no corresponde a  tipo de identificación  elegido.');
      // errorValidacionId = "El numero ingresado no corresponde a  tipo de identificación  elegido.";
      return false;
    }

    let suma = 0;
    let residuo = 0;
    let pri = false;
    let pub = false;
    let nat = false;
    const numeroProvincias = 24;
    let modulo = 11;
    let digitoVerificador;

    let d1 = 0;
    let d2 = 0;
    let d3 = 0;
    let d4 = 0;
    let d5 = 0;
    let d6 = 0;
    let d7 = 0;
    let d8 = 0;
    let d9 = 0;
    let d10 = 0;
    let p1 = 0;
    let p2 = 0;
    let p3 = 0;
    let p4 = 0;
    let p5 = 0;
    let p6 = 0;
    let p7 = 0;
    let p8 = 0;
    let p9 = 0;
    let state = false;

    /* Verifico que el campo no contenga letras */
    for (let i = 0; i < numeroProvincias + 1; i++) {
      // CompositeUtilities.openError(,"Error","El código de la provincia
      // (dos primeros dígitos) es inválido");

      if (Number(numero.substring(0, 2)) === i) {
        state = true;
      }

    }
    if (!state) {
      console.log('El código de la provincia (dos primeros dígitos) es inválido');
      // errorValidacionId = "El código de la provincia (dos primeros dígitos) es inválido";
      return false;
    }

    /* Aqui almacenamos los digitos de la cedula en variables. */
    d1 = Number(numero.substring(0, 1));
    d2 = Number(numero.substring(1, 2));
    d3 = Number(numero.substring(2, 3));
    d4 = Number(numero.substring(3, 4));
    d5 = Number(numero.substring(4, 5));
    d6 = Number(numero.substring(5, 6));
    d7 = Number(numero.substring(6, 7));
    d8 = Number(numero.substring(7, 8));
    d9 = Number(numero.substring(8, 9));
    d10 = Number(numero.substring(9, 10));

    /* El tercer digito es: */
    /* 9 para sociedades privadas y extranjeros */
    /* 6 para sociedades publicas */
    /* menor que 6 (0,1,2,3,4,5) para personas naturales */

    if (d3 === 7 || d3 === 8) {
      // CompositeUtilities.openError(this,"ERROR","El tercer dígito
      // ingresado es inválido");

      console.log('El tercer dígito ingresado es inválido');

      // errorValidacionId = 'El tercer dígito ingresado es inválido';
      return false;
    }

    /* Solo para personas naturales (modulo 10) */
    if (d3 < 6) {
      nat = true;
      p1 = d1 * 2;
      if (p1 >= 10) {
        p1 -= 9;
      }
      p2 = d2 * 1;
      if (p2 >= 10) {
        p2 -= 9;
      }
      p3 = d3 * 2;
      if (p3 >= 10) {
        p3 -= 9;
      }
      p4 = d4 * 1;
      if (p4 >= 10) {
        p4 -= 9;
      }
      p5 = d5 * 2;
      if (p5 >= 10) {
        p5 -= 9;
      }
      p6 = d6 * 1;
      if (p6 >= 10) {
        p6 -= 9;
      }
      p7 = d7 * 2;
      if (p7 >= 10) {
        p7 -= 9;
      }
      p8 = d8 * 1;
      if (p8 >= 10) {
        p8 -= 9;
      }
      p9 = d9 * 2;
      if (p9 >= 10) {
        p9 -= 9;
      }
      modulo = 10;
    }

    /* Solo para sociedades publicas (modulo 11) */
    /*
     * Aqui el digito verficador esta en la posicion 9, en las otras 2 en la
     * pos. 10
     */
    if (d3 === 6) {
      pub = true;
      p1 = d1 * 3;
      p2 = d2 * 2;
      p3 = d3 * 7;
      p4 = d4 * 6;
      p5 = d5 * 5;
      p6 = d6 * 4;
      p7 = d7 * 3;
      p8 = d8 * 2;
      p9 = 0;
    }

    /* Solo para entidades privadas (modulo 11) */
    if (d3 === 9) {
      pri = true;
      p1 = d1 * 4;
      p2 = d2 * 3;
      p3 = d3 * 2;
      p4 = d4 * 7;
      p5 = d5 * 6;
      p6 = d6 * 5;
      p7 = d7 * 4;
      p8 = d8 * 3;
      p9 = d9 * 2;
    }

    suma = p1 + p2 + p3 + p4 + p5 + p6 + p7 + p8 + p9;
    residuo = suma % modulo;

    /* Si residuo=0, dig.ver.=0, caso contrario 10 - residuo */
    digitoVerificador = residuo === 0 ? 0 : modulo - residuo;

    /* ahora comparamos el elemento de la posicion 10 con el dig. ver. */
    if (pub === true) {
      if (digitoVerificador !== d9) {
        // CompositeUtilities.openError(this,"ERROR","El ruc de la
        // empresa del sector público es incorrecto.");
        console.log('El ruc de la empresa del sector público es incorrecto.');
        // errorValidacionId = 'El ruc de la empresa del sector público es incorrecto.';
        return false;
      }
      /* El ruc de las empresas del sector publico terminan con 0001 */
      if (numero.substring(9, 14) !== '0001') {
        // CompositeUtilities.openError(this,"ERROR","El ruc de la
        // empresa del sector público debe terminar con 0001");
        console.log('El ruc de la empresa del sector público es incorrecto.');
        // errorValidacionId = 'El ruc de la empresa del sector público es incorrecto.';
        return false;
      }
    } else if (pri === true) {
      if (digitoVerificador !== d10) {
        // CompositeUtilities.openError(this,"ERROR","El ruc de la
        // empresa del sector privado es incorrecto.");
        console.log('El ruc de la empresa del sector privado es incorrecto.');
        // errorValidacionId = 'El ruc de la empresa del sector privado es incorrecto.';
        return false;
      }
      if (!numero.substring(10, 13).equalsIgnoreCase('001')) {

        console.log(' tamaño ' + numero.trim());
        // CompositeUtilities.openError(this,"ERROR","El ruc de la
        // empresa del sector privado debe terminar con 001");
        console.log('El ruc de la empresa del sector privado debe terminar con 001');
        // errorValidacionId = 'El ruc de la empresa del sector privado debe terminar con 001';
        return false;
      }
    } else if (nat === true) {
      if (digitoVerificador !== d10) {
        // CompositeUtilities.openError(this,"ERROR","El número de
        // cédula de la persona natural es incorrecto.");
        console.log('El número de cédula de la persona natural es incorrecto.');
        // errorValidacionId = 'El número de cédula de la persona natural es incorrecto.';
        return false;
      }
      if (numero.length > 10
        && !numero.substring(10, 13).equalsIgnoreCase('001')) {
        // CompositeUtilities.openError(this,"ERROR","El ruc de la
        // persona natural debe terminar con 001");

        console.log('El ruc de la persona natural debe terminar con 001');
        // errorValidacionId = 'El ruc de la persona natural debe terminar con 001';
        return false;
      }
    }
    return true;
  }

  ValidaRuc(ruc): number {
    let nroCedula;
    let tercerDigito;
    let decimoDigito;
    let decimoDigitoaux;
    let sum = 0;
    let contador = 0;
    let contadorPosicion = 0;
    let cociente;
    let decena;
    let verificador;
    let estadoRuc;
    let digitosRuc;

    if (ruc.length !== 13) {
      return estadoRuc = 0;
    }

    digitosRuc = ruc.substring(10, 13);
    if (digitosRuc === '001') {
      estadoRuc = 1;
    } else {
      return estadoRuc = 0;
    }

    if (ruc.length <= 0) {
      return estadoRuc = 0;
    }

    nroCedula = ruc.substring(0, 10); // cedula
    tercerDigito = ruc.substring(2, 3);
    decimoDigitoaux = ruc.substring(9, 10);

    if (nroCedula.length === 0 || ruc.length !== 13) {
      return estadoRuc = 0;
    }
    // El tercer dígito es siempre menor a 6: 0, 1, 2, 3, 4, 5 para Personas Naturales
    if (Number(tercerDigito) >= 0
      && Number(tercerDigito) <= 6
      && Number(decimoDigitoaux) !== 0) {
      const Idprovincia = ruc.substring(0, 2); // obtengo el tercer dígito

      if ((Number(Idprovincia) >= 1 && Number(Idprovincia) <= 24)
        || Number(Idprovincia) === 30) {
        decimoDigito = ruc.substring(9, 10);

        do {
          contadorPosicion = (2 * contador) + 1;
          const digCedula = nroCedula.charAt(contadorPosicion - 1);
          const digiCed = digCedula + '';
          let multi = Number(digiCed) * 2;
          if (multi >= 10) {
            multi = 1 + multi % 10;
          }
          sum = sum + multi;
          contador++;
        } while (contadorPosicion < 9);

        contador = 1;
        contadorPosicion = 1;
        do {
          contadorPosicion = 2 * contador;
          const digCedula = nroCedula.charAt(contadorPosicion - 1);
          const digiCed = digCedula + '';
          sum = sum + Number(digiCed);
          contador++;
        } while (contadorPosicion < 8);

        cociente = Math.trunc(sum / 10);
        decena = (cociente + 1) * 10;
        verificador = decena - sum;

        if (verificador === 10) {
          verificador = 0;
        }

        if (verificador === Number(decimoDigito)) {
          estadoRuc = 1;
        } else {
          estadoRuc = 0;
        }
      } else { // fin del if rango provincias
        return estadoRuc = 0;
      }
    } else { // fin if personas naturales
      // El tercer dígito 6 para Sociedades Públicas
      if (Number(tercerDigito) === 6
        && Number(tercerDigito) === 0) {
        const digUno = Number(nroCedula.substring(0, 1));
        const digDos = Number(nroCedula.substring(1, 2));
        const digTres = Number(nroCedula.substring(2, 3));
        const digCuatro = Number(nroCedula.substring(3, 4));
        const digCinco = Number(nroCedula.substring(4, 5));
        const digSeis = Number(nroCedula.substring(5, 6));
        const digSiete = Number(nroCedula.substring(6, 7));
        const digOcho = Number(nroCedula.substring(7, 8));
        const digNueve = Number(nroCedula.substring(8, 9));

        sum = digUno * 3 + digDos * 2 + digTres * 7 + digCuatro * 6
          + digCinco * 5 + digSeis * 4 + digSiete * 3 + digOcho * 2;

        do {
          sum = sum - 11;
        } while (sum > 11);
        let digVerif = 11 - sum;

        if (digVerif === 10) {
          digVerif = 0;
          return estadoRuc = 0;
        } else {
          if (digVerif !== digNueve) {
            return estadoRuc = 0;
          } else {
            return estadoRuc = 1;
          }
        }
      }// fin de if Sociedades Públicas

      // El tercer dígito 9 para Sociedades Privadas y Extranjeros sin cédula
      if (Number(tercerDigito) === 9) {
        const digUno = Number(nroCedula.substring(0, 1));
        const digDos = Number(nroCedula.substring(1, 2));
        const digTres = Number(nroCedula.substring(2, 3));
        const digCuatro = Number(nroCedula.substring(3, 4));
        const digCinco = Number(nroCedula.substring(4, 5));
        const digSeis = Number(nroCedula.substring(5, 6));
        const digSiete = Number(nroCedula.substring(6, 7));
        const digOcho = Number(nroCedula.substring(7, 8));
        const digNueve = Number(nroCedula.substring(8, 9));
        const digDiez = Number(nroCedula.substring(9, 10));

        sum = digUno * 4 + digDos * 3 + digTres * 2 + digCuatro * 7
          + digCinco * 6 + digSeis * 5 + digSiete * 4 + digOcho * 3
          + digNueve * 2;

        do {
          sum = sum - 11;
        } while (sum > 11);
        let digVerif = 11 - sum;

        if (digVerif === 10) {
          digVerif = 0;
          return estadoRuc = 0;
        } else {
          if (digVerif !== digDiez) {
            return estadoRuc = 0;
          } else {
            return estadoRuc = 1;
          }
        }
      }// fin de if Sociedades Privadas y Extranjeros sin cédula
    }
    return estadoRuc;
  }

  ValidaCedula(cedula): number {
    let sum1 = 0;
    let sum2 = 0;
    let digito;
    let mayorNueve;
    let estadoCedula = 0;

    if (cedula.length === 10 || cedula.length === 11) {

      let i;
      let mod;
      for (i = 1; i <= 9; i++) {
        const digitoCedcrc = cedula.charAt(i - 1);
        const digitoCed = digitoCedcrc + '';
        digito = Number(digitoCed);
        mod = i % 2;
        if (i % 2 === 0) {
          sum1 = sum1 + digito;
        } else {
          mayorNueve = digito * 2 > 9 ? (digito * 2) - 9 : digito * 2;
          sum2 = sum2 + mayorNueve; // + if(digito * 2 >9, (digito *
          // 2)-9, digito * 2);
        }
      }// fin for de 1 a 9
      digito = Number(((sum1 + sum2) + '').substring(1, 2));

      if (((10 - digito) + '') === (cedula.substring(9, 10))) {
        return estadoCedula = 1;
      }

      return estadoCedula = 0;
    } // fin if length() de cedula

    return estadoCedula;
  }

  mostrarEstadisticas() {
    this.calcularDocumentos();
    this.ventasAcumuladas();
    this.topProductos();
    this.detalleVentas();
    this.pedidosPendientes();
    this.pedidosOk();
    this.primeraFactura();
    this.chequeProtestado();
  }

  async primeraFactura() {

    try {
      const rs6 = await this.venEstadisticasService.primeraFactura(this.maecliente.CLI_CODIGO);
      this.fechaPrimeraFact = this.datePipe.transform(rs6[0].ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
    } catch (err) {

    }

  }

  async pedidosPendientes() {
    try {
      const rs4 = await this.venEstadisticasService.cantidadPedidos(this.maecliente.CLI_CODIGO, 'P');
      this.totalPedPendientes = rs4[0].CANTIDAD;
    } catch (err) {

    }
  }

  async pedidosOk() {

    try {

      const rs5 = await this.venEstadisticasService.cantidadPedidos(this.maecliente.CLI_CODIGO, 'OK');
      this.totalPedSatisfechos = rs5[0].CANTIDAD;

    } catch (err) {

    }
  }

  async chequeProtestado() {

    try {

      const rs7 = await this.venEstadisticasService.cantidadChProtestados(this.maecliente.CLI_CODIGO);
      this.totalChProtestados = rs7[0].TOTAL;

    } catch (err) {

    }
  }

  async detalleVentas() {
    const detalle: VenDetFacturas[] = [];

    try {
      const rs8 = await this.venEstadisticasService.detalleVentas(this.maecliente.CLI_CODIGO, 'fecha');

      this.detFacturas = [];
      if (rs8 !== null) {
        for (const item of rs8) {
          const det: VenDetFacturas = {};
          det.ENCFAC_NUMERO = item.ENCFAC_NUMERO;
          det.ENCFAC_FECHAEMISION = this.datePipe.transform(item.ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
          det.DETFAC_CODIGO = item.DETFAC_CODIGO;
          det.DETFAC_DESCRIPCION = item.DETFAC_DESCRIPCION;
          det.DETFAC_CANTIDAD = Number(item.DETFAC_CANTIDAD).toFixed(2);
          det.DETFAC_PRECIO = Number(item.DETFAC_PRECIO).toFixed(2);
          det.DETFAC_TOTAL = Number(item.DETFAC_TOTAL).toFixed(2);
          detalle.push(det);
        }

        this.detFacturas = detalle;
      }
    } catch (err) {

    }

  }

  async calcularDocumentos() {

    try {
      const rs1 = await this.venEstadisticasService.encontrarDocumentos('PORCOBRAR', this.maecliente.CLI_CODIGO);
      this.totalDocXCobrar = this.init.moneyValidation(Number(rs1[0].SALDO).toFixed(2));
      const rs2 = await this.venEstadisticasService.encontrarDocumentos('VENCIDOS', this.maecliente.CLI_CODIGO);
      this.totalDocVencidos = this.init.moneyValidation(Number(rs2[0].SALDO).toFixed(2));
      const rs3 = await this.venEstadisticasService.encontrarDocumentos('POSFECHADOS', this.maecliente.CLI_CODIGO);
      this.totalChPosfechados = this.init.moneyValidation(Number(rs3[0].SALDO).toFixed(2));

      this.totalDocumentos = Number(rs1[0].SALDO) + Number(rs2[0].SALDO) + Number(rs3[0].SALDO);
      this.totalDocXCobrarPorc = this.totalDocumentos > 0 ?
        this.init.moneyValidation(Number(rs1[0].SALDO * 100 / this.totalDocumentos).toFixed(2)) : 0;
      this.totalDocVencidosPorc = this.totalDocumentos > 0 ?
        this.init.moneyValidation(Number(rs2[0].SALDO * 100 / this.totalDocumentos).toFixed(2)) : 0;
      this.totalChPosfechadosPorc = this.totalDocumentos > 0 ?
        this.init.moneyValidation(Number(rs3[0].SALDO * 100 / this.totalDocumentos).toFixed(2)) : 0;

      this.totalDocumentos = this.init.moneyValidation(Number(this.totalDocumentos).toFixed(2));


    } catch (err) {

    }
  }

  async orderByDetFacturas(tipo) {
    this.detFacturas = [];
    const detalle: VenDetFacturas[] = [];
    let rs8;

    try {

      if (tipo === 'fecha') {
        rs8 = await this.venEstadisticasService.detalleVentas(this.maecliente.CLI_CODIGO, 'fecha');
      }

      if (tipo === 'producto') {
        rs8 = await this.venEstadisticasService.detalleVentas(this.maecliente.CLI_CODIGO, 'producto');
      }

      if (rs8 !== null) {
        for (const item of rs8) {
          const det: VenDetFacturas = {};
          det.ENCFAC_NUMERO = item.ENCFAC_NUMERO;
          det.ENCFAC_FECHAEMISION = this.datePipe.transform(item.ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
          det.DETFAC_CODIGO = item.DETFAC_CODIGO;
          det.DETFAC_DESCRIPCION = item.DETFAC_DESCRIPCION;
          det.DETFAC_CANTIDAD = Number(item.DETFAC_CANTIDAD).toFixed(2);
          det.DETFAC_PRECIO = Number(item.DETFAC_PRECIO).toFixed(2);
          det.DETFAC_TOTAL = Number(item.DETFAC_TOTAL).toFixed(2);
          detalle.push(det);
        }

        this.detFacturas = detalle;
      }

    } catch (err) {

    }
  }

  async ventasAcumuladas() {

    const fechaInicial = new Date();
    fechaInicial.setMonth(fechaInicial.getMonth() - 12);
    fechaInicial.setDate(1);


    const fechaAct = new Date();
    const rangoFechas: any[] = [];

    for (let i = 1; fechaInicial <= fechaAct; i++) {
      rangoFechas.push(this.datePipe.transform(fechaInicial, 'dd/MM/yyyy'));
      fechaInicial.setMonth(fechaInicial.getMonth() + 1);
    }


    try {

      const rs = await this.venEstadisticasService.ventasAcumuladas(this.maecliente.CLI_CODIGO);

      if (rs !== null) {

        const index1 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[0]);
        const valor1 = index1 >= 0 ? rs[index1].VENTA_TOTAL : 0;
        const index2 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[1]);
        const valor2 = index2 >= 0 ? rs[index2].VENTA_TOTAL : 0;
        const index3 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[2]);
        const valor3 = index3 >= 0 ? rs[index3].VENTA_TOTAL : 0;
        const index4 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[3]);
        const valor4 = index4 >= 0 ? rs[index4].VENTA_TOTAL : 0;
        const index5 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[4]);
        const valor5 = index5 >= 0 ? rs[index5].VENTA_TOTAL : 0;
        const index6 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[5]);
        const valor6 = index6 >= 0 ? rs[index6].VENTA_TOTAL : 0;
        const index7 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[6]);
        const valor7 = index7 >= 0 ? rs[index7].VENTA_TOTAL : 0;
        const index8 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[7]);
        const valor8 = index8 >= 0 ? rs[index8].VENTA_TOTAL : 0;
        const index9 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[8]);
        const valor9 = index9 >= 0 ? rs[index9].VENTA_TOTAL : 0;
        const index10 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[9]);
        const valor10 = index10 >= 0 ? rs[index10].VENTA_TOTAL : 0;
        const index11 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[10]);
        const valor11 = index11 >= 0 ? rs[index11].VENTA_TOTAL : 0;
        const index12 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[11]);
        const valor12 = index12 >= 0 ? rs[index12].VENTA_TOTAL : 0;
        const index13 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[12]);
        const valor13 = index13 >= 0 ? rs[index13].VENTA_TOTAL : 0;

        this.dataVentasAcum = {
          labels: [
            String(rangoFechas[0]).substring(8, 10) + '/' + String(rangoFechas[0]).substring(3, 5),
            String(rangoFechas[1]).substring(8, 10) + '/' + String(rangoFechas[1]).substring(3, 5),
            String(rangoFechas[2]).substring(8, 10) + '/' + String(rangoFechas[2]).substring(3, 5),
            String(rangoFechas[3]).substring(8, 10) + '/' + String(rangoFechas[3]).substring(3, 5),
            String(rangoFechas[4]).substring(8, 10) + '/' + String(rangoFechas[4]).substring(3, 5),
            String(rangoFechas[5]).substring(8, 10) + '/' + String(rangoFechas[5]).substring(3, 5),
            String(rangoFechas[6]).substring(8, 10) + '/' + String(rangoFechas[6]).substring(3, 5),
            String(rangoFechas[7]).substring(8, 10) + '/' + String(rangoFechas[7]).substring(3, 5),
            String(rangoFechas[8]).substring(8, 10) + '/' + String(rangoFechas[8]).substring(3, 5),
            String(rangoFechas[9]).substring(8, 10) + '/' + String(rangoFechas[9]).substring(3, 5),
            String(rangoFechas[10]).substring(8, 10) + '/' + String(rangoFechas[10]).substring(3, 5),
            String(rangoFechas[11]).substring(8, 10) + '/' + String(rangoFechas[11]).substring(3, 5),
            String(rangoFechas[12]).substring(8, 10) + '/' + String(rangoFechas[12]).substring(3, 5),
          ],
          datasets: [{
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [valor1, valor2, valor3, valor4, valor5, valor6, valor7,
              valor8, valor9, valor10, valor11, valor12, valor13]
          }
          ]
        };

      } else {
        this.dataVentasAcum = {
          labels: [
            String(rangoFechas[0]).substring(8, 10) + '/' + String(rangoFechas[0]).substring(3, 5),
            String(rangoFechas[1]).substring(8, 10) + '/' + String(rangoFechas[1]).substring(3, 5),
            String(rangoFechas[2]).substring(8, 10) + '/' + String(rangoFechas[2]).substring(3, 5),
            String(rangoFechas[3]).substring(8, 10) + '/' + String(rangoFechas[3]).substring(3, 5),
            String(rangoFechas[4]).substring(8, 10) + '/' + String(rangoFechas[4]).substring(3, 5),
            String(rangoFechas[5]).substring(8, 10) + '/' + String(rangoFechas[5]).substring(3, 5),
            String(rangoFechas[6]).substring(8, 10) + '/' + String(rangoFechas[6]).substring(3, 5),
            String(rangoFechas[7]).substring(8, 10) + '/' + String(rangoFechas[7]).substring(3, 5),
            String(rangoFechas[8]).substring(8, 10) + '/' + String(rangoFechas[8]).substring(3, 5),
            String(rangoFechas[9]).substring(8, 10) + '/' + String(rangoFechas[9]).substring(3, 5),
            String(rangoFechas[10]).substring(8, 10) + '/' + String(rangoFechas[10]).substring(3, 5),
            String(rangoFechas[11]).substring(8, 10) + '/' + String(rangoFechas[11]).substring(3, 5),
            String(rangoFechas[12]).substring(8, 10) + '/' + String(rangoFechas[12]).substring(3, 5),
          ],
          datasets: [{
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
          ]
        };
      }
    } catch (err) {

    }
  }

  async topProductos() {
    this.detTopProductos = [];
    let totalCantidad = 0;
    let totalPrecio = 0;
    let total = 0;
    const detalle: VenTopProductos[] = [];

    try {
      const rs = await this.venEstadisticasService.topProductos(this.maecliente.CLI_CODIGO, 'cantidad');
      if (rs !== null) {
        for (const item of rs) {
          const det: VenTopProductos = {};
          det.DETFAC_CODIGO = item.DETFAC_CODIGO;
          det.DETFAC_DESCRIPCION = item.DETFAC_DESCRIPCION;
          det.CANTIDAD_TOTAL = Number(item.CANTIDAD_TOTAL).toFixed(2);
          totalCantidad = totalCantidad + Number(item.CANTIDAD_TOTAL);
          det.DETFAC_PRECIO = Number(item.DETFAC_PRECIO).toFixed(2);
          totalPrecio = totalPrecio + Number(item.DETFAC_PRECIO);
          det.TOTAL = Number(item.TOTAL).toFixed(2);
          total = total + Number(item.TOTAL);
          det.STOCK = Number(item.STOCK).toFixed(2);
          detalle.push(det);
        }
        const totales: VenTopProductos = {};
        totales.DETFAC_CODIGO = '';
        totales.DETFAC_DESCRIPCION = 'TOTAL';
        totales.CANTIDAD_TOTAL = Number(totalCantidad).toFixed(2);
        totales.DETFAC_PRECIO = Number(totalPrecio).toFixed(2);
        totales.TOTAL = Number(total).toFixed(2);
        totales.STOCK = '';
        detalle.push(totales);

        this.detTopProductos = detalle;
      }

    } catch (err) {

    }
  }

  async orderByTopProd(tipo) {
    this.detTopProductos = [];
    let totalCantidad = 0;
    let totalPrecio = 0;
    let total = 0;
    let rs;
    const detalle: VenTopProductos[] = [];

    try {
      if (tipo === 'total') {
        rs = await this.venEstadisticasService.topProductos(this.maecliente.CLI_CODIGO, 'total');
      }

      if (tipo === 'cantidad') {
        rs = await this.venEstadisticasService.topProductos(this.maecliente.CLI_CODIGO, 'cantidad');
      }
      if (rs !== null) {
        for (const item of rs) {
          const det: VenTopProductos = {};
          det.DETFAC_CODIGO = item.DETFAC_CODIGO;
          det.DETFAC_DESCRIPCION = item.DETFAC_DESCRIPCION;
          det.CANTIDAD_TOTAL = Number(item.CANTIDAD_TOTAL).toFixed(2);
          totalCantidad = totalCantidad + Number(item.CANTIDAD_TOTAL);
          det.DETFAC_PRECIO = Number(item.DETFAC_PRECIO).toFixed(2);
          totalPrecio = totalPrecio + Number(item.DETFAC_PRECIO);
          det.TOTAL = Number(item.TOTAL).toFixed(2);
          total = total + Number(item.TOTAL);
          det.STOCK = Number(item.STOCK).toFixed(2);
          detalle.push(det);
        }
        const totales: VenTopProductos = {};
        totales.DETFAC_CODIGO = '';
        totales.DETFAC_DESCRIPCION = 'TOTAL';
        totales.CANTIDAD_TOTAL = Number(totalCantidad).toFixed(2);
        totales.DETFAC_PRECIO = Number(totalPrecio).toFixed(2);
        totales.TOTAL = Number(total).toFixed(2);
        totales.STOCK = '';
        detalle.push(totales);

        this.detTopProductos = detalle;
      }

    } catch (err) {

    }
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos = false;
    }
  }

  async validar(rucide) {
    this.validaEnviaRespuesta(this.selectide.name, rucide);
  }

  async validaEnviaRespuesta(rucIdeTipo, rucIde) {

    if (rucIdeTipo === 'R.U.C.') {
      const vria = await this.validaConsultaRespuesta(rucIde);

      if (vria === 1) {
        await this.validaColocarLabels(1);
      } else if (vria === 3) {
        this.messageService.add({
          key: 'autorizacion',
          severity: 'success',
          summary: 'Información',
          detail: 'RUC no se encuentra en nuestra base de datos, verifique' +
            ' en el portal del SRI.'
        });
        this.claveAuth = '';
        this.autorizacionCodigo = 'AUTRUCID';
        this.displayDialogAutorizacion = true;
      } else if (vria === 4) {
        await this.obtenerDatosCliente(rucIde);
      } else {
        await this.validaColocarLabels(2);
        this.maecliente.CLI_RUCIDE = '';
      }

    }
    if (rucIdeTipo === 'Cédula') {
      const vria = await this.validaConsultaRespuesta(rucIde);

      if (vria == 1) {
        await this.validaColocarLabels(1);
      } else if (vria == 3) {
        this.messageService.add({
          key: 'autorizacion',
          severity: 'success',
          summary: 'Información',
          detail: 'RUC no se encuentra en nuestra base de datos, verifique' +
            ' en el portal del SRI.'
        });
        this.claveAuth = '';
        this.autorizacionCodigo = 'AUTRUCID';
        this.displayDialogAutorizacion = true;
      } else {
        await this.validaColocarLabels(2);
        this.maecliente.CLI_RUCIDE = '';
      }
    }
    if (rucIdeTipo === 'Pasaporte') {
      await this.validaColocarLabels(4);
    }
    if (rucIdeTipo === 'Placa') {
      await this.validaColocarLabels(4);
    }
    if (rucIdeTipo === 'Extranjero') {
      await this.validaColocarLabels(4);
    }
    if (rucIdeTipo === 'Consumidor Final') {
      await this.validaColocarLabels(4);
    }
  }


  async validaConsultaRespuesta(rucIde) {

    let valRucIDe = 0;

    if (this.selectide.name === 'R.U.C.') {
      const resp = await this.utilitariosService.ValidaRucAutorizacion(rucIde);

      if (resp == 1) {
        valRucIDe = 1;
      } else {
        if (resp == 4) {
          valRucIDe = 4;
        } else if (resp == 3) {
          valRucIDe = 3;
        } else {
          valRucIDe = 0;
        }
      }
    }
    if (this.selectide.name === 'Cédula') {
      if (rucIde !== '9999999999') {
        const resp = await this.utilitariosService.ValidaCedulaAutorizacion(rucIde, 1);
        if (resp == 1) {
          valRucIDe = 1;
        } else {
          if (resp == 3) {
            valRucIDe = 3;
          } else {
            valRucIDe = 0;
          }
        }
      } else {
        valRucIDe = 0;
      }
    }

    if (this.selectide.name === 'Pasaporte') {
      valRucIDe = 1;
    }
    if (this.selectide.name === 'Placa') {
      valRucIDe = 1;
    }
    if (this.selectide.name === 'Extranjero') {
      valRucIDe = 1;
    }
    if (this.selectide.name === 'Consumir Final') {
      valRucIDe = 1;
    }

    return valRucIDe;

  }


  async obtenerDatosCliente(rucIde) {
    let r = null;

    try {
      r = await this.utilitariosService.getrucsri(rucIde);
    } catch  {
    }
    // console.log(r);
    if (r != null) {
      this.maecliente.CLI_NOMBRE = r.RAZON_SOCIAL;
      this.maecliente.CLI_NOMBREC = r.RAZON_SOCIAL.substr(0, 12);
      this.maecliente.CLI_CONTACTO = r.NOMBRE_COMERCIAL === "null" ? "" : r.NOMBRE_COMERCIAL;
      this.maecliente.CLI_OBSERVACION = r.TIPO_CONTRIBUYENTE + " - "
        + r.ESTADO_CONTRIBUYENTE + " - "
        + r.CLASE_CONTRIBUYENTE + " - " + r.OBLIGADO;
      this.auditoriagral.registrarAuditoria('VEN_MAECLIENTE', 'SRI/' + rucIde, 'I',
        '', '01', '', '', '', '').subscribe();
      this.buscarCodigosProv(r);
      this.validaColocarLabels(6);
    }
  }

  comprobarClave() {
    this.utilitariosService.obtenerClave(this.autorizacionCodigo).subscribe((clave) => {
      let claveReal;
      claveReal = clave[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        this.accionesAutorizacion();
      } else if (clave !== this.claveAuth) {
        this.messageService.add({
          key: 'autorizacion',
          severity: 'error',
          summary: 'Clave Autorización',
          detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
        });
      }
    });
  }

  accionesAutorizacion() {
    if (this.autorizacionCodigo === 'AUTRUCID') {
      this.validaColocarLabels(3);
    }

    this.displayDialogAutorizacion = false;
  }

  onHideAutorizacion() {
    if (this.bolAutorizacion === false) {
      if (this.autorizacionCodigo === 'AUTRUCID') {
        this.messageService.add({
          key: 'cliente',
          severity: 'error',
          summary: 'Información',
          detail: 'Debe ingresar correctamente'
            + ' la clave para ingresar el Ruc/Ide'
        });
        this.maecliente.CLI_RUCIDE = '';
        document.getElementById('CLI_RUCIDE').focus();
      }
    }
  }

  buscarCodigosProv(r) {

    this.maecliente.CLI_PROVINCIANOMBRE = r.DESCRIPCION_PROVINCIA;

    this.cliService.erCodProvincia(this.maecliente.CLI_PROVINCIANOMBRE).subscribe((formsricom) => {
      if (formsricom !== null) {
        if (formsricom[0] !== undefined) {
          this.maecliente.CLI_PROVINCIA = formsricom[0].PROV_CODIGO;
        }
      }
    });

    this.maecliente.CAN_NOMBRE = r.DESCRIPCION_CANTON;

    this.cliService.erCodCanton(this.maecliente.CAN_NOMBRE).subscribe((formsricom) => {
      if (formsricom !== null) {
        if (formsricom[0] !== undefined) {
          this.maecliente.CAN_CODIGO = formsricom[0].CAN_CODIGO;
        }
      }
    });

    this.maecliente.PARR_NOMBRE = r.DESCRIPCION_PARROQUIA;

    this.cliService.erCodParroquia(this.maecliente.PARR_NOMBRE).subscribe((formsricom) => {
      if (formsricom !== null) {
        if (formsricom[0] !== undefined) {
          this.maecliente.PARR_CODIGO = formsricom[0].PARR_CODIGO;
        }
      }
    });

    this.maecliente.CIU_CODIGO = r.CODIGO_CIIU;
  }

}
