<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="genped"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmargenped" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 98%">
    <div class="p-col-12">
        <app-invmaebarra [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"
            [barraBotones5]="true" [botonVisto]="false" [barraBotonesCRUD]="true" [barraBotones3Alt]="false"
            (signal)="manejarSenales($event)" style="text-align: left;">
        </app-invmaebarra>
    </div>
</div>
<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 270px)'}">
    <div class="divgrups">
        <div class="p-grid">
            <!--LINEA 1-->
            <div class="p-col-3"></div>
            <div class="p-col-2">
                <span>Tipo:</span>
            </div>
            <div class="p-col-2">
                <p-dropdown [options]="cmbtipo" [style]="{'minWidth': '100%', 'width':'100%'}" optionLabel="name"
                    [panelStyle]="{'width':'110%'}" [(ngModel)]="selecttipo" (onChange)="consultarCantidad()"></p-dropdown>
            </div>
            <!--LINEA 2-->
            <div class="p-col-12">
                <div class="p-grid">
                    <div class="p-col-3"></div>
                    <div class="p-col-2">
                        <span>Fecha Emisión:</span>
                    </div>
                    <div class="p-col-1">
                        <p-calendar id="FECHAEMISION" [inputStyle]="{width: '100%', height: '20px'}" [(ngModel)]="fecha"
                            [readonlyInput]="true" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
                            (keydown)="botonesmodificar()">
                        </p-calendar>
                    </div>
                </div>
            </div>
            <!--LINEA 3-->
            <div class="p-col-12">
                <div class="p-grid">
                    <div class="p-col-3"></div>
                    <div class="p-col-2">
                        <span>Concepto:</span>
                    </div>
                    <div class="p-col-5">
                        <label for="CONCEPTO"></label>
                        <input class="frm" id="CONCEPTO" type="text" pInputText [(ngModel)]="concepto"
                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
                    </div>
                </div>
            </div>
            <!--LINEA 4-->
            <div class="p-col-12">
                <div class="p-grid">
                    <div class="p-col-3"></div>
                    <div class="p-col-2">
                        <span>Grupo:</span>
                    </div>
                    <div class="p-col-2">
                        <label for="GRUPO"></label>
                        <input class="frm" id="GRUPO" type="text" pInputText [(ngModel)]="grupo"
                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
                    </div>
                    <div class="p-col-1">
                        <button type="button" style="background-image: url(../../../assets/images/iconos/buscar.png);"
                            (click)="busquedagrupo(grupo)"></button>
                    </div>
                    <div class="p-col-2">
                        <span>{{nombregrupo}}</span>
                    </div>
                </div>
            </div>
            <!--LINEA 5-->
            <div class="p-col-12">
                <div class="p-grid">
                    <div class="p-col-3"></div>
                    <div class="p-col-3">
                        <div class="p-grid">
                            <div class="p-col-2">
                                <p-checkbox name="precio" value="" label="" binary="{{chkprecio}}"
                                    [(ngModel)]="chkprecio">
                                </p-checkbox>
                            </div>
                            <div class="p-col-10">
                                <span>Tomar el precio del maestro de servicios</span>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-2"></div>
                    <div class="p-col-3">
                        <span>Cantidad de pedidos a generar: {{cantidad}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-scrollPanel>

<p-dialog header="Búsqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
		[busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Clave Autorización" [(visible)]="displayDialogAutorizacion" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" (onHide)="onHideAutorizacion()" [style]="{width: '40vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="autorizacion"></p-toast>
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							(keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>