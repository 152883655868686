<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="devolucion"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="devolucionConf" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="layout-config">
	<div class="layout-config-content-wrapper">
		<a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones()">
			<i class="pi pi-bars" style="cursor: pointer"></i>
		</a>
		<p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
			[dismissible]="false" [showCloseIcon]="false">
			<br>
			<br>
			<br>
			<p-tabView>
				<p-tabPanel header="Acciones">
					<div class="p-grid">
						<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 200px)'}">
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="abrirSeriales()"
									class="archivoOpc">Ingresar Seriales</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="habilitarPorcIva()"
									class="archivoOpc">Modificar Porcentaje de Iva</span>
							</div>
							<hr />
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="reversarProceso()"
									class="archivoOpc">Reversar inicio de proceso de firma electrónica</span>
							</div>
							<div *ngIf="boolfacturaElec === true" class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="continuarFE()"
									class="archivoOpc">Continuar proceso de firma electrónica</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="refrescarDatosFE()"
									class="archivoOpc">Refrescar datos de documento electrónico</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="abrirLog()"
									class="archivoOpc">Visualizar log del documento</span>
							</div>
							<!-- CDPJ -->
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="visualizarEst()"
									class="archivoOpc">Estados firma electrónica</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="activarFacAnulada()"
									class="archivoOpc">Activar Devolución Anulada</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="actbasesiva()"
									class="archivoOpc">Actualizar bases IVA</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-folder archivoOpc"></i><span (click)="verbasesiva()"
									class="archivoOpc">Visualizar bases IVA</span>
							</div>
							<!-- CDPJ -->
						</p-scrollPanel>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-sidebar>
	</div>
</div>

<div class="p-grid" style="width: 99%;">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
			[botonBorrar]="btnBorrar" [botonXML]="botonXML" [botonverAsiento]="botonVerAsiento" [botonPago]="botonPago"
			(signal)="manejarSenales($event)" [botonAnterior]="botonAnterior" [botonPrimero]="botonPrimero"
			[botonUltimo]="botonUltimo" [botonBuscar]="botonBuscar" [botonAsiento]="botonAsiento"
			[botonSiguiente]="botonSiguiente" [barraGre]="false" [barraDevolucion]="false" [barraBotones3]="true"
			[botonAnular]="botonAnular" [botonReenviarCorreo]="false" [botonAlmacenardoc] = "true"></app-invmaebarra>
	</div>
	<div class="p-col-12">
		<div class="divgrups2" style="margin-top: -10px; margin-bottom: -10px;">
			<div class="p-col-12">
				<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px;">
					<div class="p-col-2">
						<input type="text" pInputText style="background-color: deepskyblue"
							[(ngModel)]="venDevFacService.encdevfacarray[indicador].ENCDVF_GRUPO" [disabled]="true">
					</div>
					<div class="p-col-1"></div>
					<div class="p-col-2">
						<input type="text" pInputText style="background-color: deepskyblue"
							[(ngModel)]="venDevFacService.encdevfacarray[indicador].ENCDVF_ESTADO" [disabled]="true">
					</div>
					<div class="p-col-1">
						<input type="text" pInputText
							style="background-color: darkblue; font-weight: bold; color: white;"
							[(ngModel)]="venDevFacService.encdevfacarray[indicador].ENCDVF_ESTADO_FE" [disabled]="true">
					</div>
					<div class="p-col-2">
						<input type="text" pInputText
							style="background-color: darkblue; font-weight: bold; color: white;"
							[(ngModel)]="venDevFacService.encdevfacarray[indicador].ENCDVF_AUTORIZACION_FE"
							[disabled]="true">
					</div>
					<div class="p-col-2">
						<input type="text" pInputText
							style="background-color: darkblue; font-weight: bold; color: white;"
							[(ngModel)]="venDevFacService.encdevfacarray[indicador].ENCDVF_CLAVEACCESO_FE"
							[disabled]="true">
					</div>
					<div class="p-col-2">
						<input type="text" pInputText style="background-color: deepskyblue;"
							[(ngModel)]="venDevFacService.encdevfacarray[indicador].ASI_NRO" [disabled]="true">
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="p-col-12" (keydown.f9)="abrirDetalleFactura()">
		<p-tabView [(activeIndex)]="activeIndex" (onChange)="changeTabs()">
			<p-tabPanel header="Devolución">
				<!--<div class="contorno" id="contorno">-->
				<div class="divgrups" id="divPrincipal">
					<div class="p-grid p-align-stretch">
						<!--LINEA 1-->
						<div class="p-col-12">
							<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
								<div class="p-col-1">
									<span>Número:</span>
								</div>
								<div class="p-col-2">
									<input type="text" id="comprobante" (input)="cambio()" [disabled]="!edittxtnumero" oninput="this.value= this.value.replace(/[^a-zA-Z0-9]/g, '');"
										(input)="solonumeros($event)"
										(keydown.enter)="cambiarFoco($event)" pInputText style="width: 100%"
										[(ngModel)]="venDevFacService.encdevfacarray[indicador].ENCDVF_NUMERO"
										[maxLength]="50" autocomplete="off">
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Autorización:</span>
								</div>
								<div class="p-col-1">
									<input type="text" id="autorizacion" [disabled]="true" pInputText
										style="width: 100%"
										[(ngModel)]="venDevFacService.encdevfacarray[indicador].ENCDVF_AUTORIZACION"
										[maxLength]="20" autocomplete="off">
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Saldo Cliente:</span>
								</div>
								<div class="p-col-1">
									<input type="text" id="saldocliente" (input)="cambio()" [disabled]="true"
										(keydown.enter)="cambiarFoco($event)" pInputText style="width: 100%"
										[(ngModel)]="venDevFacService.encdevfacarray[indicador].SALDO" [maxLength]="32"
										autocomplete="off">
								</div>
								<div class="p-col-3">
								</div>
							</div>
						</div>
						<!--LINEA 2-->
						<div class="p-col-12" style="margin-top: -5px;">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Fecha Emisión:</span>
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-7">
											<p-calendar dateFormat="dd/mm/yy" [inputStyle]="{'width': '100%'}"
												[(ngModel)]="venDevFacService.encdevfacarray[indicador].ENCDVF_FECHAEMISION">
											</p-calendar>
										</div>
										<div class="p-col-5">
											<p-calendar [inputStyle]="{'width': '100%'}" [timeOnly]="true"
												[(ngModel)]="horaSistema"></p-calendar>
										</div>
									</div>
								</div>
								<div class="p-col-4"></div>
								<div class="p-col-1">
									<span>Listas Precio:</span>
								</div>
								<div class="p-col-1">
									<p-dropdown [options]="opcionesprecio"
										[style]="{'width': '100%', 'min-width': '100%'}"
										[(ngModel)]="venDevFacService.encdevfacarray[indicador].ENCDVF_LISTA">
									</p-dropdown>
								</div>
								<div class="p-col-3">
								</div>
							</div>
						</div>
						<!--LINEA 3-->
						<div class="p-col-12" style="margin-top: -20px;">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Cliente:</span>
								</div>
								<div class="p-col-2">
									<input type="text" id="cliente" (input)="cambio()"
										(keydown.enter)="cambiarFoco($event)" pInputText
										[(ngModel)]="venDevFacService.encdevfacarray[indicador].CLI_CODIGO"
										[maxLength]="20" autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="abrirClientes()"></button>
								</div>
								<div class="p-col-2">
									<span
										style="color: blue">{{venDevFacService.encdevfacarray[indicador].CLI_NOMBRE}}</span>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>I.V.A:</span>
								</div>
								<div class="p-col-2">
									<p-dropdown
										disabled="{{venDevFacService.encdevfacarray[indicador].ENCDVF_REFERENCIA !== ''}}"
										[style]="{'width': '100%', 'min-width': '100%'}" [options]="opcionIvaDesc"
										[(ngModel)]="venDevFacService.encdevfacarray[indicador].ENCDVF_IVA">
									</p-dropdown>
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-3">
											<span>%</span>
										</div>
										<div class="p-col-9">
											<!-- <p-dropdown [style]="{'width': '100%', 'min-width': '100%'}"
												[options]="opcionIva" [disabled]="porcIvaBoolean"
												[(ngModel)]="venDevFacService.dblPorcIvaDevF[indicador]"
												(ngModelChange)="venDevFacService.calcularTotal()"></p-dropdown> -->
												<p-dropdown [options]="cmbporceiva" [style]="{'minWidth': '100%', 'width': '100%'}"
													optionLabel="name" 
													(onChange)="cambiavalorIva($event)"
													[(ngModel)]="selectcombtipoIva"
													[disabled]="porcIvaBoolean"
													></p-dropdown> 
										</div>
									</div>
								</div>
							</div>
						</div>
						<!--LINEA 4-->
						<div class="p-col-12" style="margin-top: -22px;">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Referencia:</span>
								</div>
								<div class="p-col-2">
									<input type="text" id="referencia" (input)="cambio()"
										(keydown.enter)="cambiarFoco($event)" pInputText
										[(ngModel)]="venDevFacService.encdevfacarray[indicador].ENCDVF_REFERENCIA"
										[maxLength]="50" autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="abrirFactura()"></button>
								</div>
								<div class="p-col-2">
									<span style="color: red">{{estadoRef}}</span>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Bodega:</span>
								</div>
								<div class="p-col-4">
									<p-dropdown id="bodega" [style]="{'width': '100%', 'min-width': '100%'}"
										[options]="opcionesBodega"
										[(ngModel)]="venDevFacService.encdevfacarray[indicador].BOD_CODIGO">
									</p-dropdown>
								</div>
							</div>
						</div>
						<!--LINEA 5-->
						<div class="p-col-12" style="margin-top: -17px;">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Vendedor:</span>
								</div>
								<div class="p-col-2">
									<input type="text" id="vendedor" (input)="cambio()"
										(keydown.enter)="cambiarFoco($event)" pInputText
										[(ngModel)]="venDevFacService.encdevfacarray[indicador].VEN_CODIGO"
										[maxLength]="5" autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="abrirVendedor()"></button>
								</div>
								<div class="p-col-2">
									<span>{{nombreVendedor}}</span>
								</div>
								<div class="p-col-6"></div>
							</div>
						</div>
						<!--LINEA 6-->
						<div class="p-col-12" style="margin-top: -15px; margin-bottom: -20px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Comentario:</span>
								</div>
								<div class="p-col-5">
									<input type="text" style="width: 100%" (input)="cambio()" id="comentario"
										(keydown.enter)="cambiarFoco($event)"
										[(ngModel)]="venDevFacService.encdevfacarray[indicador].ENCDVF_COMENTARIO"
										[maxLength]="250" autocomplete="off">
								</div>
								<div class="p-col-6"></div>
							</div>
						</div>
					</div>
				</div>
				<!--</div>-->
				<app-aggridsaci [width]="1450" [height]="largo" [enableSorting]="false" [enableFilter]="true"
					[rowData]="venDevFacService.detdevfacarray[indicador]" [frameworkComponents]="frameworkComponents"
					[animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefsDVFDetalle"
					[defaultColDef]="defaultColDefDevolucion" [rowStyle]="rowStyle" [singleClickEdit]="false"
					[botones]="true" [mostrarGuardar]="false" [botonesAdicionales]="true"
					[comboOpciones]="comboOpciones" [tipodetalle]="tipodetalle" (selected)="seleccionDetalleDvf($event)"
					(cambios)="cambio()" (rdbtipodet)="cambiarTipo($event)" [botonNuevo]="btnNuevoGrid"
					[botonGuardar]="btnGuardarGrid" [botonBorrar]="btnBorrarGrid" [botonRegresar]="btnCancelarGrid"
					[botonVerificarExis]="btnVerificarExis" [suppressCellSelection]="editarCeldas"
					[tipodetalleactivo]="tipodetalleactivo" [imagenProducto]="imagenProducto"
					[btnVerExistenciaBodPed]="false" (keydown.f9)="abrirDetalleFactura()"></app-aggridsaci>
				<div class="divgrupstabla">
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-4">
									<div class="p-grid">
										<div class="p-col-4">
											<span class="obligatorio">Neto</span>
										</div>
										<div class="p-col-4">
											<span class="obligatorio">Dscto (Valor)</span>
										</div>
										<div class="p-col-4">
											<span class="obligatorio">Dscto (%)</span>
										</div>
									</div>
								</div>
								<div class="p-col-4">
									<div class="p-grid">
										<div class="p-col-4">
											<span class="obligatorio">Base I.V.A.</span>
										</div>
										<div class="p-col-4">
											<span class="obligatorio">Valor I.V.A.</span>
										</div>
										<div class="p-col-4">
											<span class="obligatorio">Base I.C.E.</span>
										</div>
									</div>
								</div>
								<div class="p-col-4">
									<div class="p-grid">
										<div class="p-col-4">
											<span class="obligatorio">Valor I.C.E.</span>
										</div>
										<div class="p-col-4">
											<span class="obligatorio">Base Cero</span>
										</div>
										<div class="p-col-4">
											<span class="obligatorio">Total</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -20px">
							<div class="p-grid">
								<div class="p-col-4">
									<div class="p-grid">
										<div class="p-col-4">
											<input type="text" class="totales" pInputText [disabled]="true"
												[(ngModel)]="venDevFacService.encdevfacarray[indicador].ENCDVF_TOTALNETO"
												autocomplete="off">
										</div>
										<div class="p-col-4">
											<input type="text" class="totales" pInputText [disabled]="true"
												[(ngModel)]="venDevFacService.encdevfacarray[indicador].ENCDVF_VALORDES"
												autocomplete="off">
										</div>
										<div class="p-col-4">
											<input type="text" class="totales" pInputText [disabled]="true"
												[(ngModel)]="venDevFacService.encdevfacarray[indicador].ENCDVF_PORCEDES"
												autocomplete="off">
										</div>
									</div>
								</div>
								<div class="p-col-4">
									<div class="p-grid">
										<div class="p-col-4">
											<input type="text" class="totales" pInputText [disabled]="true"
												[(ngModel)]="venDevFacService.encdevfacarray[indicador].ENCDVF_BASEIVA"
												autocomplete="off">
										</div>
										<div class="p-col-4">
											<input type="text" class="totales" pInputText [disabled]="true"
												[(ngModel)]="venDevFacService.encdevfacarray[indicador].ENCDVF_VALORIVA"
												autocomplete="off">
										</div>
										<div class="p-col-4">
											<input type="text" class="totales" pInputText [disabled]="true"
												[(ngModel)]="venDevFacService.encdevfacarray[indicador].ENCDVF_BASEICE"
												autocomplete="off">
										</div>
									</div>
								</div>
								<div class="p-col-4">
									<div class="p-grid">
										<div class="p-col-4">
											<input type="text" class="totales" pInputText [disabled]="true"
												[(ngModel)]="venDevFacService.encdevfacarray[indicador].ENCDVF_VALORICE"
												autocomplete="off">
										</div>
										<div class="p-col-4">
											<input type="text" class="totales" pInputText [disabled]="true"
												[(ngModel)]="venDevFacService.encdevfacarray[indicador].ENCDVF_BASECERO"
												autocomplete="off">
										</div>
										<div class="p-col-4">
											<input type="text" class="totales" pInputText [disabled]="true"
												[(ngModel)]="venDevFacService.encdevfacarray[indicador].ENCDVF_TOTAL"
												autocomplete="off">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -10px; margin-bottom: -20px;">
							<div class="p-grid">
								<div class="p-col-4">
									<span style="color: deeppink;">F9 => Traer Items Factura</span>
								</div>
								<div class="p-col-8">
									<div class="p-grid">
										<div class="p-col-1">
										</div>
										<div class="p-col-1">
											<span class="obligatorio">IRBPNR:</span>
										</div>
										<div class="p-col-2">
											<input type="text" class="totales" pInputText [disabled]="true"
												[(ngModel)]="venDevFacService.encdevfacarray[indicador].ENCDVF_VALORIRBPNR"
												autocomplete="off">
										</div>
										<div class="p-col-2">
										</div>
										<div class="p-col-3">
											<div class="p-grid">
												<div class="p-col-4"
													style="margin-top: 10px; height: 25%; background-color: #00FF00">
												</div>
												<div class="p-col-8">
													<span>Promociones</span>
												</div>
											</div>
										</div>
										<div class="p-col-3">
											<div class="p-grid">
												<div class="p-col-4"
													style="margin-top: 10px; height: 25%; background-color: #FF80F0">
												</div>
												<div class="p-col-8">
													<span>Componente</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="p-col-1"></div>
							</div>
						</div>
					</div>
				</div>
			</p-tabPanel>
			<p-tabPanel header="Estado de Cuenta" [disabled]="btnNuevo && activeIndex<1">
				<div class="divgrups">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Cliente:</span>
						</div>
						<div class="p-col-6">
							<span
								style="color: blue">{{venDevFacService.encdevfacarray[indicador].CLI_CODIGO + ' - ' + venDevFacService.encdevfacarray[indicador].CLI_NOMBRE}}</span>
						</div>
						<div class="p-col-4">
						</div>
					</div>
				</div>
				<app-aggridsaci [width]="" [height]="largoestcta" [enableSorting]="true" [enableFilter]="true"
					[rowData]="cxctmptrnresumen" [frameworkComponents]="frameworkComponents" [animateRows]="true"
					[rowSelection]="rowSelection" [columnDefs]="columnDefsCxctmptrnresumen"
					[defaultColDef]="defaultColDefCxctmptrnresumen" [rowStyle]="rowStyleEC" [botones]="false"
					[singleClickEdit]="false"></app-aggridsaci>
			</p-tabPanel>
			<p-tabPanel header="Saldos" [disabled]="btnNuevo && activeIndex<1">
				<div class="divgrups2">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Cliente:</span>
						</div>
						<div class="p-col-6">
							<span
								style="color: blue">{{venDevFacService.encdevfacarray[indicador].CLI_CODIGO + ' - ' + venDevFacService.encdevfacarray[indicador].CLI_NOMBRE}}</span>
						</div>
						<div class="p-col-4">
						</div>
					</div>
				</div>
				<app-aggridsaci [width]="" [height]="largoestcta" [enableSorting]="true" [enableFilter]="true"
					[rowData]="cxctrnresumen" [frameworkComponents]="frameworkComponents" [animateRows]="true"
					[rowSelection]="rowSelection" [columnDefs]="columnDefsCxctrnresumen"
					[defaultColDef]="defaultColDefCxctrnresumen" [rowStyle]="rowStyleSaldo" [botones]="false"
					[singleClickEdit]="false"></app-aggridsaci>
			</p-tabPanel>
			<p-tabPanel header="Exportación Nuevo" [disabled]="btnNuevo && activeIndex<1">
				<p-tabView>
					<p-tabPanel header="Cliente">
						<div class="divgrups3">
							<div class="p-grid">
								<div class="p-col-12">
									<span style="font-weight: bold">Especifique la información correspondiente al
										cliente</span>
								</div>
								<div class="p-col-3">
								</div>
								<div class="p-col-9">
									<div class="p-grid">
										<div class="p-col-12">
											<div class="p-grid">
												<div class="p-col-3">
													<span>Tipo Id. Cliente</span>
												</div>
												<div class="p-col-5"><input type="text" style="width: 100%"
														[disabled]="true"
														[(ngModel)]="exportacionNuevoSeleccionado.CLI_TIPOIDE"
														autocomplete="off">
												</div>
												<div class="p-col-4"></div>
											</div>
										</div>
										<div class="p-col-12">
											<div class="p-grid" style="margin-top: -20px;">
												<div class="p-col-3">
													<span>N° Identificación Cliente</span>
												</div>
												<div class="p-col-5"><input type="text" style="width: 100%"
														[disabled]="true"
														[(ngModel)]="exportacionNuevoSeleccionado.CLI_RUCIDE"
														autocomplete="off">
												</div>
												<div class="p-col-4"></div>
											</div>
										</div>
										<div class="p-col-12">
											<div class="p-grid" style="margin-top: -20px;">
												<div class="p-col-3">
													<span>¿Es parte relacionada?</span>
												</div>
												<div class="p-col-5"><input type="text" style="width: 100%"
														[disabled]="true"
														[(ngModel)]="exportacionNuevoSeleccionado.CLI_PARTEREL"
														autocomplete="off">
												</div>
												<div class="p-col-4"></div>
											</div>
										</div>
										<div class="p-col-12">
											<div class="p-grid" style="margin-top: -20px;">
												<div class="p-col-3">
													<span>Tipo de Cliente</span>
												</div>
												<div class="p-col-5"><input type="text" style="width: 100%"
														[disabled]="true"
														[(ngModel)]="exportacionNuevoSeleccionado.TIPOCLI"
														autocomplete="off">
												</div>
												<div class="p-col-4"></div>
											</div>
										</div>
										<div class="p-col-12">
											<div class="p-grid" style="margin-top: -20px;">
												<div class="p-col-3">
													<span>Razón Social</span>
												</div>
												<div class="p-col-5"><input type="text" style="width: 100%"
														[disabled]="true"
														[(ngModel)]="exportacionNuevoSeleccionado.CLI_NOMBRE"
														autocomplete="off">
												</div>
												<div class="p-col-4"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="p-grid">
								<div class="p-col-12">
									<span style="font-weight: bold">Informacion de la Exportación</span>
								</div>
								<div class="p-col-3">
								</div>
								<div class="p-col-9">
									<div class="p-grid">
										<div class="p-col-12">
											<div class="p-grid">
												<div class="p-col-7">
													<span>Tipo de regimen fiscal del exterior:</span>
												</div>
												<div class="p-col-4">
													<p-dropdown [style]="{width: '100%', 'min-width': '100%'}"
														[options]="opcionesRegimen"
														[(ngModel)]="exportacionNuevoSeleccionado.REGIMEN"
														(ngModelChange)="habilitarExpNuevoCliente()"></p-dropdown>
												</div>
												<div class="p-col-1"></div>
											</div>
										</div>
										<div class="p-col-12">
											<div class="p-grid" style="margin-top: -20px;">
												<div class="p-col-7">
													<span>País al que se realiza el pago en régimen general:</span>
												</div>
												<div class="p-col-1">
													<input type="text" id="paisesreg" (input)="cambiarFoco($event)"
														style="width: 100%" [maxLength]="10"
														(keydown.enter)="cambiarFoco($event)"
														[(ngModel)]="codigoPaisRegimen" [disabled]="booleanRegimen"
														autocomplete="off">
												</div>
												<div class="p-col-1">
													<button type="button"
														style="background-image: url(../../../assets/images/iconos/buscar.png);"
														(click)="abrirPaisesRegimen()"
														[disabled]="booleanRegimen"></button>
												</div>
												<div class="p-col-1">
													<span
														*ngIf="booleanRegimen === false">{{nombrePaisSeleccionado}}</span>
												</div>
												<div class="p-col-2"></div>
											</div>
										</div>
										<div class="p-col-12">
											<div class="p-grid" style="margin-top: -20px;">
												<div class="p-col-7">
													<span>País al que se realiza el pago paraiso fiscal:</span>
												</div>
												<div class="p-col-1">
													<input type="text" style="width: 100%" id="paisParaiso"
														(input)="cambiarFoco($event)" [maxLength]="10"
														(keydown.enter)="cambiarFoco($event)"
														[(ngModel)]="codigoPaisParaiso" [disabled]="booleanParaiso"
														autocomplete="off">
												</div>
												<div class="p-col-1">
													<button type="button"
														style="background-image: url(../../../assets/images/iconos/buscar.png);"
														(click)="abrirPaisesParaiso()"
														[disabled]="booleanParaiso"></button>
												</div>
												<div class="p-col-1">
													<span
														*ngIf="booleanParaiso === false">{{nombrePaisSeleccionado}}</span>
												</div>
												<div class="p-col-2"></div>
											</div>
										</div>
										<div class="p-col-12">
											<div class="p-grid" style="margin-top: -20px;">
												<div class="p-col-7" style="text-align: left">
													<span>Denominación del régimen fiscal preferente o jurisdicción de
														menor imposición:</span>
												</div>
												<div class="p-col-4"><input type="text" id="denoExp" style="width: 100%"
														(keydown.enter)="cambiarFoco($event)"
														[(ngModel)]="exportacionNuevoSeleccionado.DENOEXPCLI"
														[maxLength]="100" [disabled]="booleanRegimenP"
														autocomplete="off">
												</div>
												<div class="p-col-1"></div>
											</div>
										</div>
										<div class="p-col-12">
											<div class="p-grid" style="margin-top: -20px;">
												<div class="p-col-7" style="text-align: left">
													<span>País de residencia o establecimiento permanente a quien se
														efectua la exportación o ingreso del exterior</span>
												</div>
												<div class="p-col-1">
													<input type="text" style="width: 100%" id="paisResid"
														(input)="cambiarFoco($event)"
														(keydown.enter)="cambiarFoco($event)"
														[(ngModel)]="exportacionNuevoSeleccionado.PAISEFECEXP"
														[disabled]="booleanRegimenP" [maxLength]="10"
														autocomplete="off">
												</div>
												<div class="p-col-1">
													<button type="button"
														style="background-image: url(../../../assets/images/iconos/buscar.png);"
														(click)="abrirPaisesExportacion()"
														[disabled]="booleanRegimenP"></button>
												</div>
												<div class="p-col-1">
													<span
														*ngIf="booleanRegimenP === false">{{nombrePaisSeleccionado}}</span>
												</div>
												<div class="p-col-1"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</p-tabPanel>
					<p-tabPanel header="Refrendo">
						<div class="divgrups3">
							<div class="p-grid">
								<div class="p-col-12">
									<div class="p-grid">
										<div class="p-col-1"></div>
										<div class="p-col-4">
											<span>Tipo de Exportación</span>
										</div>
										<div class="p-col-5">
											<p-dropdown [style]="{width: '100%', 'min-width': '100%'}"
												[options]="opcionesExportacion"
												[(ngModel)]="exportacionNuevoSeleccionado.EXPORTACIONDE"
												(ngModelChange)="habilitarRefrendos()"></p-dropdown>
										</div>
										<div class="p-col-2"></div>
									</div>
								</div>
								<div class="p-col-12" style="margin-top: -15px;">
									<div class="p-grid">
										<div class="p-col-1"></div>
										<div class="p-col-4">
											<span>Tipo de Ingreso del exterior</span>
										</div>
										<div class="p-col-5">
											<p-dropdown [style]="{width: '100%', 'min-width': '100%'}"
												[disabled]="booleanRefrendo" [options]="opcionesTipoIngresoExterior"
												[(ngModel)]="exportacionNuevoSeleccionado.TIPOINGRESO"></p-dropdown>
										</div>
										<div class="p-col-2"></div>
									</div>
								</div>
								<div class="p-col-12" style="margin-top: -15px;">
									<div class="p-grid">
										<div class="p-col-1"></div>
										<div class="p-col-4">
											<span>El ingreso del exterior fue gravado con impuesto a la renta o uno
												similar, en el país en el que se obtuvo?</span>
										</div>
										<div class="p-col-3">
											<p-dropdown [style]="{width: '100%', 'min-width': '100%'}"
												[disabled]="booleanRefrendo" [options]="opcionesIngresoExterior"
												[(ngModel)]="exportacionNuevoSeleccionado.INGGRAVADOEXT"
												(ngModelChange)="habilitarImpuestoRenta()"></p-dropdown>
										</div>
										<div class="p-col-4"></div>
									</div>
								</div>
								<div class="p-col-12" style="margin-top: -5px;">
									<div class="p-grid">
										<div class="p-col-1"></div>
										<div class="p-col-4">
											<span>Valor del impuesto a la renta o impuesto similar pagado en el exterior
												por el ingreso obtenido</span>
										</div>
										<div class="p-col-4">
											<input type="text" id="valorImpuestoR" (input)="cambiarFoco($event)"
												(keydown.enter)="cambiarFoco($event)" [disabled]="booleanImpuestoRenta"
												[(ngModel)]="exportacionNuevoSeleccionado.VALORIMPEXT" [maxLength]="32"
												pKeyFilter="pint" autocomplete="off">
										</div>
										<div class="p-col-3"></div>
									</div>
								</div>
								<div class="p-col-12" style="margin-top: -1px;">
									<div class="p-grid">
										<div class="p-col-1"></div>
										<div class="p-col-4">
											<span>Valor FOB Valor del ingreso del Exterior</span>
										</div>
										<div class="p-col-4">
											<input type="text" id="valorfobC" autocomplete="off"
												(input)="cambiarFoco($event)" (keydown.enter)="cambiarFoco($event)"
												[(ngModel)]="exportacionNuevoSeleccionado.VALORFOB" [maxLength]="32"
												appTwoDigitDecimaNumber>
										</div>
										<div class="p-col-3"></div>
									</div>
								</div>
								<div class="p-col-12" style="margin-top: -10px;">
									<div class="p-grid">
										<div class="p-col-1"></div>
										<div class="p-col-4">
											<span>Fecha de la Transacción</span>
										</div>
										<div class="p-col-2">
											<p-calendar dateFormat="dd/mm/yy" [inputStyle]="{width: '100%'}"
												[(ngModel)]="exportacionNuevoSeleccionado.FECHAEMBARQUE"></p-calendar>
										</div>
										<div class="p-col-5"></div>
									</div>
								</div>
							</div>
							<div class="p-grid" *ngIf="exportacionNuevoSeleccionado.EXPORTACIONDE === '01'">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span style="font-weight: bold">Refrendo</span>
								</div>
								<div class="p-col-10"></div>
								<div class="p-col-12">
									<div class="p-grid">
										<div class="p-col-1"></div>
										<div class="p-col-2">
											<span>N Documento de transporte</span>
										</div>
										<div class="p-col-3">
											<input type="text" id="doctran" style="width: 100%"
												(input)="cambiarFoco($event)"
												[(ngModel)]="exportacionNuevoSeleccionado.DOCTRANSP" [maxLength]="10"
												autocomplete="off">
										</div>
										<div class="p-col-6"></div>
									</div>
								</div>
								<div class="p-col-12">
									<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
										<div class="p-col-1"></div>
										<div class="p-col-2">
											<span>Distrito</span>
										</div>
										<div class="p-col-4">
											<p-dropdown [style]="{width: '100%', 'min-width': '100%'}"
												[options]="opcionesDistrito"
												[(ngModel)]="exportacionNuevoSeleccionado.DISTADUANERO"></p-dropdown>
										</div>
										<div class="p-col-5"></div>
									</div>
								</div>
								<div class="p-col-12">
									<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
										<div class="p-col-1"></div>
										<div class="p-col-2">
											<span>Año</span>
										</div>
										<div class="p-col-3">
											<input type="text" style="width: 100%" (input)="cambiarFoco($event)"
												[(ngModel)]="exportacionNuevoSeleccionado.ANIO" [maxLength]="10"
												autocomplete="off">
										</div>
										<div class="p-col-6"></div>
									</div>
								</div>
								<div class="p-col-12">
									<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
										<div class="p-col-1"></div>
										<div class="p-col-2">
											<span>Régimen</span>
										</div>
										<div class="p-col-4">
											<p-dropdown [style]="{width: '100%', 'min-width': '100%'}"
												[options]="opcionesRegRefrendo"
												[(ngModel)]="exportacionNuevoSeleccionado.TIPOREGI"></p-dropdown>
										</div>
										<div class="p-col-5"></div>
									</div>
								</div>
								<div class="p-col-12">
									<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
										<div class="p-col-1"></div>
										<div class="p-col-2">
											<span>Correlativo</span>
										</div>
										<div class="p-col-3">
											<input type="text" style="width: 100%" (input)="cambiarFoco($event)"
												[(ngModel)]="exportacionNuevoSeleccionado.CORRELATIVO" [maxLength]="20"
												autocomplete="off">
										</div>
										<div class="p-col-6"></div>
									</div>
								</div>
							</div>
						</div>
					</p-tabPanel>
					<p-tabPanel header="Comprobante">
						<div class="divgrups3">
							<div class="p-grid">
								<div class="p-col-12">
									<div class="p-grid">
										<div class="p-col-1"></div>
										<div class="p-col-2">
											<span>Tipo de Comprobante</span>
										</div>
										<div class="p-col-2">
											<span>04-NOTA DE CRÉDITO</span>
										</div>
										<div class="p-col-7"></div>
									</div>
								</div>
								<div class="p-col-12" style="margin-top: -15px;">
									<div class="p-grid">
										<div class="p-col-1"></div>
										<div class="p-col-2">
											<span>Valor FOB del Comprobante Local</span>
										</div>
										<div class="p-col-2">
											<input type="text" style="width: 100%" (input)="cambiarFoco($event)"
												[(ngModel)]="exportacionNuevoSeleccionado.VALORFOBCOMPROBANTE"
												[maxLength]="32" pKeyFilter="pint" autocomplete="off">
										</div>
										<div class="p-col-7"></div>
									</div>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<span style="font-weight: bold">Factura de Exportaciones</span>
								</div>
								<div class="p-col-9"></div>
								<div class="p-col-12" style="margin-top: -15px;">
									<div class="p-grid">
										<div class="p-col-1"></div>
										<div class="p-col-2">
										</div>
										<div class="p-col-1">
											<div class="p-grid">
												<div class="p-col-12">
													<span>Establecimiento</span>
												</div>
												<div class="p-col-12">
													<input type="text" style="width: 100%" [disabled]="true"
														[(ngModel)]="exportacionNuevoSeleccionado.ESTABLECIMIENTO"
														autocomplete="off">
												</div>
											</div>
										</div>
										<div class="p-col-1">
											<div class="p-grid">
												<div class="p-col-12">
													<span>Pto de Emisión</span>
												</div>
												<div class="p-col-12">
													<input type="text" style="width: 100%" [disabled]="true"
														[(ngModel)]="exportacionNuevoSeleccionado.PUNTOEMISION"
														autocomplete="off">
												</div>
											</div>
										</div>
										<div class="p-col-1">
											<div class="p-grid">
												<div class="p-col-12">
													<span>Secuencial</span>
												</div>
												<div class="p-col-12">
													<input type="text" style="width: 100%" [disabled]="true"
														[(ngModel)]="exportacionNuevoSeleccionado.ENCDVF_SECACTUAL"
														autocomplete="off">
												</div>
											</div>
										</div>
										<div class="p-col-6"></div>
									</div>
								</div>
								<div class="p-col-12" style="margin-top: -15px;">
									<div class="p-grid">
										<div class="p-col-1"></div>
										<div class="p-col-2">
											<span>N° de Autorización</span>
										</div>
										<div class="p-col-3">
											<input type="text" style="width: 100%" [disabled]="true"
												[(ngModel)]="exportacionNuevoSeleccionado.ENCDVF_AUTORIZACION"
												autocomplete="off">
										</div>
										<div class="p-col-6"></div>
									</div>
								</div>
								<div class="p-col-12" style="margin-top: -15px;">
									<div class="p-grid">
										<div class="p-col-1"></div>
										<div class="p-col-2">
											<span>Fecha de Emisión</span>
										</div>
										<div class="p-col-1">
											<p-calendar dateFormat="dd/mm/yy" [inputStyle]="{width: '100%'}"
												[disabled]="true"
												[(ngModel)]="exportacionNuevoSeleccionado.ENCDVF_FECHAEMISION">
											</p-calendar>
										</div>
										<div class="p-col-8"></div>
									</div>
								</div>
							</div>
						</div>
					</p-tabPanel>
				</p-tabView>
			</p-tabPanel>
			<!-- GSRF -->
			<p-tabPanel header="Información Adicional" [disabled]="btnNuevo && activeIndex<1">
				<div class="divgrups">
					<div class="p-grid">
						<!--LINEA 1-->
						<div class="p-col-8">
						</div>
						<div class="p-col-1">
							<button type="button" style="background-image: url(../../../assets/images/iconos/visto.png);"
								(click)="guardarDatosBloque()"></button>
						</div>
					</div>
				</div>
		
				<app-aggridsaci [width]="" [height]="largo" [enableSorting]="false" [enableFilter]="true"
					[rowData]="veninfoadicdevfac" [frameworkComponents]="frameworkComponents" [animateRows]="true"
					[rowSelection]="rowSelection" [columnDefs]="columnDefsInfoAdic" [defaultColDef]="defaultColDefInfoAdic"
					[rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true" (selected)="seleccioninfoadic($event)"
					[mostrarGuardar]="false"></app-aggridsaci>
			</p-tabPanel>
			<!-- GSRF -->
		</p-tabView>
	</div>
</div>

<p-dialog header="Ingreso de Seriales" [(visible)]="displayDialogSeriales" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-invtrnkardexotr *ngIf="venDevFacService.encdevfacarray[indicador].ENCDVF_NUMERO !== '' &&
						  venDevFacService.encdevfacarray[indicador].ENCDVF_NUMERO !== undefined"
		[strNumFact]="venDevFacService.encdevfacarray[indicador].ENCDVF_NUMERO"
		[strFechaSer]="venDevFacService.encdevfacarray[indicador].ENCDVF_FECHAEMISION" [strTipoDoc]="'DVFVEN'"
		[strTipoTrn]="'EN'" [devBoolean]="true"
		[strNumFactDev]="venDevFacService.encdevfacarray[indicador].ENCDVF_REFERENCIA" (cerrar)="cerrarSeriales()">
	</app-invtrnkardexotr>
</p-dialog>
<p-dialog header="Numeración" [(visible)]="numeracionDialog" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '40vw', height: '40vh'} ">
	<app-numeracion *ngIf="numDocRef !== ''" [numDocRef]="numDocRef" [auxiliar]="auxiliarnum"
		(numeracionSeleccion)="numeracionSeleccionada($event)"></app-numeracion>
</p-dialog>
<p-dialog header="Búsqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla"
		[busquedaParams]="busquedacampos" [where]="where" (opcionSeleccionada)="manejarSeleccion($event)"
		[consulta]="consulta"></app-busquedadlg>
</p-dialog>
<p-dialog header="Seleccion de datos" [(visible)]="displayDialogDetFac" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width:'70vw', height:'60vh'}">
	<app-vendetfac
		*ngIf="venDevFacService.encdevfacarray[indicador].ENCDVF_REFERENCIA !== '' || venDevFacService.encdevfacarray[indicador].ENCDVF_REFERENCIA !== undefined"
		[encfacNumero]="venDevFacService.encdevfacarray[indicador].ENCDVF_REFERENCIA"
		[hmpConfiguracion]="hmpConfiguracion" (detallesSeleccionados)="recibirDetalles($event)"
		(signalSalir)="recibirSenales($event)"></app-vendetfac>
</p-dialog>

<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width:'90vw', height:'120vh'}" [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="asiento" [abiertoDesde]="'venencdevfac'"
		(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>

<p-dialog header="Clave Autorización" [(visible)]="claveDialog" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: 'auto'} ">
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							(keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog header="Descuento Global" [(visible)]="displayDialogDescuento" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '35vw', height: '35vh'}">
	<div class="p-grid">
		<div class="p-col-12">
			<button class="littlebuttons" pButton (click)="aplicarDescuento()"
				style="background-image: url(../../../assets/images/iconos/visto.png);">
			</button>
		</div>
		<div class="p-col-12">
			<p-tabView>
				<p-tabPanel header="Tipo Descuento">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-3">
							<p-radioButton name="opcionDescuento" label="Por Valor" value="dscValor"
								[(ngModel)]="dscSeleccionado" (ngModelChange)="cambioDescuento()"></p-radioButton>
						</div>
						<div class="p-col-3"></div>
						<div class="p-col-3">
							<p-radioButton name="opcionDescuento" label="En Porcentaje" value="dscPorcentaje"
								[(ngModel)]="dscSeleccionado" (ngModelChange)="cambioDescuento()"></p-radioButton>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-1"></div>
						<div class="p-col-4">
							<input type="text" pInputText pKeyFilter="num" (input)="comprobarValor()"
								(keydown.enter)="aplicarDescuento()" [(ngModel)]="valorDscValor"
								[disabled]="booleanValor" appTwoDigitDecimaNumber autocomplete="off">
							<p-message *ngIf="valorInvalidoV === true" severity="error"
								text="Valor de descuento no válido"></p-message>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-4">
							<input type="text" pInputText pKeyFilter="num" (input)="comprobarValor()"
								(keydown.enter)="aplicarDescuento()" [(ngModel)]="valorDscPorcentaje"
								[disabled]="booleanPorcentaje" appTwoDigitDecimaNumber autocomplete="off">
							<p-message *ngIf="valorInvalidoP === true" severity="error" text="Porcentaje no válido ">
							</p-message>
						</div>
						<div class="p-col-1"></div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</div>
	</div>
</p-dialog>
<p-dialog header="Pago de la Devolución" [(visible)]="displayDialogPago" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: 'auto'}" (onHide)="onHideEvent()">
	<app-pagocontadodvf [auxiliar]="auxiliarpago" [confPago]="confPago"
		[encdvf]="venDevFacService.encdevfacarray[indicador]" [totalApagar]="totalApagar"
		[displayPago]="displayDialogPago" (guardarCorrecto)="recibirGuardarPago($event)"
		(eliminarCorrecto)="recibirEliminarPago($event)" (salir)="salirDialog()"></app-pagocontadodvf>
</p-dialog>
<p-dialog header="Log de la firma Electrónica" [(visible)]="displayLog" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '60vw', height: '60vh'} ">
	<div class="p-grid">
		<div class="p-col-12">
			<textarea rows="12" cols="100" pInputTextarea style="width: 100%; height: 400px" [(ngModel)]="logResultado"
				[disabled]="true" maxlength="3000" autocomplete="off"></textarea>
		</div>
	</div>
</p-dialog>
<p-dialog header="Existencia por bodega" [(visible)]="displayDialogExistBod" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '500px'}">
	<div class="p-grid">
		<div class="p-col-12" style="margin-top: 20px;">
			<div class="p-grid">
				<div class="p-col-12" style="margin-top: 20px;">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Artículo:</span>
						</div>

						<div class="p-col-2" style="text-align: left">
							<div class="p-grid">
								<div class="p-col-8">
									<input id="ART_CODIGO" type="text" pInputText autocomplete="off"
										[(ngModel)]="txtArtCodigo">
								</div>
								<div class="p-col-4">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="buscarArticulo()"></button>
								</div>
							</div>
						</div>
						<div class="p-col-3" style="text-align: left">
							<input id="ART_DESCRIPCION" type="text" pInputText disabled autocomplete="off"
								[(ngModel)]="txtArtDescripcion">
						</div>
						<div class="p-col-1">
						</div>
						<div class="p-col-1">
							<span style="font-weight: bold">Lista {{txtArtPrecioLista}}</span>
						</div>
						<div class="p-col-1">
						</div>
						<div class="p-col-1">
							<button pButton (click)="visualizarImagen()" [disabled]="disabledVerImagen"
								style="background-image: url(../../../assets/images/iconos/013-life-saver.png);background-color: transparent;"
								title="Visualizar imagen">
							</button>
						</div>
					</div>
				</div>
			</div>
			<!--			 <img src="../../../assets/images/bg-03.jpg" width="100px">-->

			<app-aggridsaci [width]="" [height]="'525'" [enableSorting]="true" [enableFilter]="true"
				[rowData]="detExistenciaBodegas" [frameworkComponents]="frameworkComponents" [animateRows]="true"
				[rowSelection]="rowSelection" [columnDefs]="columnDefsExistenciaBodegas"
				[defaultColDef]="defaultColExistBod" [rowStyle]="rowStyleSaldo" [botones]="false"
				[singleClickEdit]="false">
			</app-aggridsaci>
		</div>
	</div>

</p-dialog>

<p-dialog header="Imagen" [(visible)]="displayDialogImagenArt" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '350px', height: '310px'}">
	<div style="text-align: center">
		<img [src]="txtArtPathFoto" style='width:200px; height:200px; '>
	</div>

</p-dialog>

<p-dialog header="Reportes" [(visible)]="displayDialogReportes" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: '400px'}">
	<app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
		[strNumDoc]="venDevFacService.encdevfacarray[indicador].ENCDVF_NUMERO" [strCampo]="'ENCDVF_NUMERO'"
		(cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>

<p-dialog header="Lotes" [(visible)]="displayDialogLotes" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<app-invtrnlote [auxiliar]="auxiliarlot" [detalle]="detalleDVFSeleccionado" [encabezado]="venDevFacService.encdevfacarray[indicador]" [cadena]="'DEVFAC'"
		(salir)="cerrarLotes()"></app-invtrnlote>
</p-dialog>

<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
</div>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'VENENCDEVFAC'" [txtNumComprobante] = "venDevFacService.encdevfacarray[indicador].ENCDVF_NUMERO"
	(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>
<!-- CDPJ -->
<p-dialog header="Clave Supervisor" [(visible)]="displayDialogSupervisor" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: 'auto'} ">
	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-4">
					<label for="usuariosup" style="font-weight: bold">Usuario:</label>
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-4">
					<input pInputText id="usuariosup" [(ngModel)]="usuarioSup" placeholder="Ingrese el usuario">
				</div>
				<div class="p-col-1"></div>
				<div class="p-col-1"></div>
				<div class="p-col-4">
					<label for="clavesup" style="font-weight: bold">Clave:</label>
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-4">
					<input type="password" pInputText id="clavesup" [(ngModel)]="claveSup"
						(keydown.enter)="validarClave()" placeholder="Ingrese la clave">
				</div>
				<div class="p-col-1"></div>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog header="Estados firma electrónica" [(visible)]="displayDialogEst" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '60vw', height: '60vh'} ">
	<div class="p-grid">
		<div class="p-col-12">
			<label for="ESTFIRMA"></label>
			<textarea id="ESTFIRMA" rows="12" cols="100" pInputTextarea style="width: 100%; height: 230px;font-size: 16px;"
				[(ngModel)]="estfirma" [disabled]="true" maxlength="3000"></textarea>
		</div>
	</div>
</p-dialog>
<p-dialog header="Bases IVA" [(visible)]="displaybasesiva" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '55vw', height: '55vh'} " [focusOnShow]="true" appendTo="body" >

	<app-aggridsaci [width]="-800" [height]="-800" [enableSorting]="true" [enableFilter]="true"
			[rowData]="basesiva" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsBasesIva"
			[defaultColDef]="defaultColBasesIva" [rowStyle]="rowStyle" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>



</p-dialog>
<!-- CDPJ -->