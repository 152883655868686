import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Venmaevendedor} from '../veninterfaces/venmaevendedor';
import {Observable} from 'rxjs';
import {Venmaerancom} from '../veninterfaces/venmaerancom';
import {Venmaepreciovend} from '../veninterfaces/venmaepreciovend';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class VenmaevendedorService {
  get venmaepreciovend(): Venmaepreciovend[] {
    return this._venmaepreciovend;
  }

  set venmaepreciovend(value: Venmaepreciovend[]) {
    this._venmaepreciovend = value;
  }
  get venmaevendedorCom(): Venmaerancom[] {
    return this._venmaevendedorCom;
  }

  set venmaevendedorCom(value: Venmaerancom[]) {
    this._venmaevendedorCom = value;
  }
  get venmaevendedor(): Venmaevendedor[] {
    return this._venmaevendedor;
  }

  set venmaevendedor(value: Venmaevendedor[]) {
    this._venmaevendedor = value;
  }
  public url: string;
  private _venmaevendedor: Venmaevendedor[];
  private _venmaevendedorCom: Venmaerancom[];
  private _venmaepreciovend: Venmaepreciovend[];
  public way: string;
  constructor(private http: HttpClient,
    private confIniciales: ConfInicialesService,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }
  getVendedor(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/vendedor/83bbf75daebb700', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  insertarVendedor(vendedor: Venmaevendedor): Observable<any[]> {
    //noout
    return this.http.post<any[]>(this.way + '/ventas/vendedor/12bf1dbfbaed73000', {      
      elementos: {
        p_VEN_codigo_1: vendedor.VEN_CODIGO,
        p_VEN_nombre_2: vendedor.VEN_NOMBRE,
        p_VEN_comision1_3: Number(vendedor.VEN_COMISION1),
        p_VEN_comision2_4: Number(vendedor.VEN_COMISION2),
        p_com_codigo_5: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  eliminarVendedor(vendedor: Venmaevendedor): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/vendedor/eacad2fbaed73800', {      
      elementos: {
        p_VEN_codigo_1: vendedor.VEN_CODIGO,
        p_com_codigo_2: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  actualizarVendedor(vendedor: Venmaevendedor, codigoAux): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/vendedor/14cef95f75dae70000', {      
      elementos: {
        p_VEN_codigo_1: codigoAux,
        p_VEN_codigo_2: vendedor.VEN_CODIGO,
        p_VEN_nombre_3: vendedor.VEN_NOMBRE,
        p_VEN_comision1_4: Number(vendedor.VEN_COMISION1),
        p_VEN_comision2_5: Number(vendedor.VEN_COMISION2),
        p_com_codigo_6: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  getVendedorCOM(vendedor: Venmaevendedor): Observable<any[]> {
    
    return this.http.post<any[]>(this.way + '/ventas/vendedor/20eeb316fbaed80000', {      
      elementos: {
        VEN_CODIGO: vendedor.VEN_CODIGO 
      },
    }, this.confIniciales.httpOptions());
  }
  getVendedorprecio(vendedor: Venmaevendedor): Observable<any[]> {
    
    return this.http.post<any[]>(this.way + '/ventas/vendedor/107773b73258fc00000000', {      
      elementos: {
        VEN_CODIGO: vendedor.VEN_CODIGO 
      },
    }, this.confIniciales.httpOptions());
  }
  getListaPrecios(): Observable<any[]> {
   
    return this.http.post<any[]>(this.way + '/ventas/vendedor/20eed65ceab3b80000', {      
      elementos: { },
    }, this.confIniciales.httpOptions());
  }
  guardarClave(vendedor: Venmaevendedor): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/vendedor/8795b6ab6caac00', {      
      elementos: {
        p_VEN_codigo_1: vendedor.VEN_CODIGO,
        p_VEN_clave: vendedor.VEN_CLAVE,
        P_CAJ_codigo: vendedor.CAJ_CODIGO,
        p_BOD_codigo: vendedor.BOD_CODIGO,
        p_com_codigo: '01'
       },
    }, this.confIniciales.httpOptions());
  }
  guardarCamposAdicionales(vendedor: Venmaevendedor, fechaIngreso, fechaNacimiento): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/ventas/vendedor/21e56daadb15660000', {      
      elementos: {
        p_VEN_codigo_1: vendedor.VEN_CODIGO,
        p_com_codigo: '01',
        p_ven_direccion1: vendedor.VEN_DIRECCION1,
        p_ven_direccion2: vendedor.VEN_DIRECCION2,
        p_ven_telefono1: vendedor.VEN_TELEFONO1,
        p_ven_telefono2: vendedor.VEN_TELEFONO2,
        p_ven_fax: vendedor.VEN_FAX,
        p_ven_correo: vendedor.VEN_CORREO,
        p_ven_zona: vendedor.VEN_ZONA,
        p_ven_provincia: vendedor.VEN_PROVINCIA,
        p_ciu_codigo: vendedor.CIU_CODIGO,
        p_ven_jefezona: vendedor.VEN_JEFEZONA,
        p_ven_ide: vendedor.VEN_IDE,
        p_con_codigo: vendedor.CON_CODIGO,
        p_ven_fechaIngreso: fechaIngreso.toString(),
        p_ven_fechaNacimiento: fechaNacimiento.toString(),
        p_ven_transporte: vendedor.VEN_TRANSPORTE,
        p_ven_observaciones: vendedor.VEN_OBSERVACIONES
       },
    }, this.confIniciales.httpOptions());
  }
  insertarRangosComisiones(comision: Venmaerancom): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/vendedor/257e3b7b55e18e0000', {      
      elementos: {
        p_ven_codigo: comision.VEN_CODIGO,
        p_com_codigo: '01',
        p_rancom_codigo: comision.RANCOM_CODIGO,
        p_rancom_desde: Number(comision.RANCOM_DESDE.toString()),
        p_rancom_hasta: Number(comision.RANCOM_HASTA.toString()),
        p_rancom_porcentaje: Number(comision.RANCOM_PORCENTAJE.toString())
       },
    }, this.confIniciales.httpOptions());
  }
  actualizarRangosComisiones(comision: Venmaerancom): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/vendedor/a6776abc31cce000', {      
      elementos: {
        p_ven_codigo: comision.VEN_CODIGO,
        p_com_codigo: '01',
        p_rancom_codigo: comision.RANCOM_CODIGO,
        p_rancom_desde: Number(comision.RANCOM_DESDE.toString()),
        p_rancom_hasta: Number(comision.RANCOM_HASTA.toString()),
        p_rancom_porcentaje: Number(comision.RANCOM_PORCENTAJE.toString())
       },
    }, this.confIniciales.httpOptions());
  }
  eliminarRangosComisiones(comision: Venmaerancom): Observable<any[]> {
   
    return this.http.post<any[]>(this.way + '/ventas/vendedor/eacad2bab7b5600000000', {      
      elementos: {
        p_ven_codigo: comision.VEN_CODIGO,
        p_com_codigo: '01',
        p_rancom_codigo: comision.RANCOM_CODIGO
       },
    }, this.confIniciales.httpOptions());
  }

  frVenCodigo(strven_codigo) {
    return this.http.post(this.way + '/ventas/vendedor/3f7f75d986ca18', {
      elementos: {
        ven_codigo: strven_codigo
      },
    }, this.confIniciales.httpOptions());
  }
  frVenclave(strven_clave) {
    return this.http.post(this.way + '/ventas/vendedor/1fbfbaecaabee', {
      elementos: {
        VEN_CLAVE: strven_clave
      },
    }, this.confIniciales.httpOptions());
  }
  frRamComCodigo(strramconcodigo,strvencondigo) {
    return this.http.post(this.way + '/ventas/vendedor/1fbdaaccc5d9870000', {
      elementos: {
        RANCOM_CODIGO: strramconcodigo,
        VEN_CODIGO:strvencondigo
      },
    }, this.confIniciales.httpOptions());
  }
  frCodAux(strcodaux,strvencondigo) {
    return this.http.post(this.way + '/ventas/vendedor/7ed986abdc', {
      elementos: {
        codigoAux: strcodaux,
        VEN_CODIGO:strvencondigo
      },
    }, this.confIniciales.httpOptions());
  }
 
  insertarPreciosVendedor(precio: Venmaepreciovend): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/vendedor/33b73258e7dd76000000000', {      
      elementos: {
        p_ven_codigo: precio.VEN_CODIGO,
        p_com_codigo: '01',
        p_artpre_codigo: precio.ARTPRE_CODIGO
       },
    }, this.confIniciales.httpOptions());
  }
  actualizarPreciosVendedor(precio: Venmaepreciovend, codigoAux): Observable<any[]> {
   
    return this.http.post<any[]>(this.way + '/ventas/vendedor/14cee76e64b1d0000000000', {      
      elementos: {
        p_ven_codigo: precio.VEN_CODIGO,
        p_com_codigo: '01',
        p_artpre_codigo: precio.ARTPRE_CODIGO,
        p_artpre_codigo2: codigoAux
       },
    }, this.confIniciales.httpOptions());
  }
  eliminarPreciosVendedor(precio: Venmaepreciovend): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/vendedor/756569676e64b0000', {      
      elementos: {
        p_ven_codigo: precio.VEN_CODIGO,
        p_com_codigo: '01',
        p_artpre_codigo: precio.ARTPRE_CODIGO
       },
    }, this.confIniciales.httpOptions());
  }
}
