//
// SACI WEB
// Rutina: Pto Vta Maestro Subcategoria
// Propietario: Wilson Ramos
// Modificado por: WR
// Fecha de creación: 2019
// Fecha de Modificación: 2019
//
import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {MaesubcategoriaService} from '../../servicios/maesubcategoria.service';
import {Maesubcategoria} from '../../interfaces/maesubcategoria';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Maecategoria} from '../../interfaces/maecategoria';
import {MaePantalla} from '../../interfaces/maepantalla';
import {MaecategoriaService} from '../../servicios/maecategoria.service';


@Component({
  selector: 'app-maesubcategoria',
  templateUrl: './maesubcategoria.component.html',
  styleUrls: ['./maesubcategoria.component.css'],
  preserveWhitespaces: false
})
export class MaesubcategoriaComponent implements OnInit {
  @Input() selectedSubCat: Maesubcategoria[];
  @Output() productos = new EventEmitter<MaePantalla[]>();
  arregloSubCategoriasSeleccionadas: [MaePantalla[]] = [[]];
  productosSeleccionados: MaePantalla[];
  subcategoriasAux: Maesubcategoria[];
  pantallas: MaePantalla[];
  displayDialog: boolean;
  subcategoria: Maesubcategoria = {};
  selectedsubCategoria: Maesubcategoria;
  newSubCategoria: boolean;
  brands: any[];
  cols: any[];
  public selectedSubcategoria: string;
  clonedsubCat: { [s: string]: Maesubcategoria; } = {};
  hidePantalla: boolean;
  
  editing: boolean;
  
  constructor(private subcategoriaService: MaesubcategoriaService, private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private categoriaService: MaecategoriaService) {
  }
  
  ngOnInit() {
    
    this.cols = [
      {field: 'CAT_CODIGO', header: 'Codigo Categoria'},
      {field: 'SUB_CODIGO', header: 'Codigo Subcategoria'},
      {field: 'SUB_NOMBRE', header: 'Nombre'},
      {field: 'SUB_CORTO', header: 'Corto'},
      {field: '', header: 'Opciones'}
    ];
    
    this.brands = [
      {label: 'SUB4', value: 'SUB4'},
      {label: 'SUB5', value: 'SUB5'},
      {label: 'SUB6', value: 'SUB6'}
    ];
    this.subcategoriasAux = this.selectedSubCat;
    this.editing = false;
  }
  
  showDialogToAdd() {
    this.newSubCategoria = true;
    this.subcategoria = {};
    this.selectedSubcategoria = this.categoriaService.recogerCATCODIGO().toString();
    this.displayDialog = true;
  }
  
  save() {
    const subcategorias = [...this.selectedSubCat];
    this.subcategoria.CAT_CODIGO = this.selectedSubcategoria;
    if (this.newSubCategoria) {
      subcategorias.push(this.subcategoria);
    } else {
      subcategorias[this.selectedSubCat.indexOf(this.selectedsubCategoria)] = this.subcategoria;
    }
    
    this.selectedSubCat = subcategorias;
    this.subcategoriaService.agregarSubcategoria(this.subcategoria);
    this.subcategoria = null;
    this.displayDialog = false;
    this.messageService.add({key: 'message', severity: 'success', summary: 'Inserción Exitosa!', detail: 'Subcategoria Ingresada'});
    
  }
  
  
  onRowEditInit(subCat: Maesubcategoria) {
    this.clonedsubCat[subCat.SUB_CODIGO] = {...subCat};
    this.editing = true;
  }
  
  onRowEditSave(subCat: Maesubcategoria) {
    if (subCat.SUB_NOMBRE !== '') {
      this.subcategoriaService.actualizarSubCat(subCat);
      
      delete this.clonedsubCat[subCat.SUB_CODIGO];
      this.messageService.add({
        key: 'message',
        severity: 'success',
        summary: 'Actualización Exitosa!',
        detail: 'SubCategoria Actualizada'
      });
      this.editing = false;
    } else {
      this.messageService.add({
        key: 'message',
        severity: 'warn',
        summary: 'Actualización no Completada',
        detail: 'Los valores deben ser distintos de vacio'
      });
      
    }
  }
  
  
  onRowDelete(subCat: Maesubcategoria, index: number) {
    this.subcategoriaService.eliminarSubCategoria(subCat);
    this.selectedSubCat = this.selectedSubCat.filter((val, i) => i !== index);
    this.subcategoria = null;
    
  }
  
  onRowEditCancel() {
    this.categoriaService.recogerCategoria(this.selectedSubCat[0]).subscribe((respuesta: any[]) => {
      this.subcategoriasAux = respuesta;
      this.selectedSubCat = this.subcategoriasAux;
    });
  }
  
  irPantalla(subCat: Maesubcategoria) {
    if (this.editing === false) {
      this.subcategoriaService.recogerPantalla(subCat).subscribe((respuesta: any[]) => {
        this.productosSeleccionados = respuesta;
        this.pantallas = respuesta;
        this.arregloSubCategoriasSeleccionadas.push(respuesta);
        for (const element of this.productosSeleccionados) {
          element.PANT_PESOSEL = element.PANT_PESO === '1';
        }
        this.productos.emit(this.productosSeleccionados);
        this.hidePantalla = true;
      });
    }
  }
  
  accept(subCat: Maecategoria, index: number) {
    this.onRowDelete(subCat, index);
  }
  
  confirmation(subCat: Maecategoria, index: number) {
    this.confirmationService.confirm({
      key: 'confirmSubcat',
      message: 'Quieres eliminar esta subcategoria?',
      header: 'Confirmacion de Eliminacion',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.onRowDelete(subCat, index);
      },
      reject: () => {
      },
      blockScroll: true
    });
    
  }
  
  
}
