//
// SACI WEB
// Rutina: Maestro de Bodegas
// Propietario: Wilson Ramos
// Modificado por: WR
// Fecha de creación: 2019
// Fecha de Modificación: 29-11-2019
//
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Maebodega} from '../invinterfaces/maebodega';
import {InvmaebodegaService} from '../invservicios/invmaebodega.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {InvbusquedaService} from '../invservicios/invbusqueda.service';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Component({
  selector: 'app-invmaebodega',
  templateUrl: './invmaebodega.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css'],
  preserveWhitespaces: false
})
export class InvmaebodegaComponent implements OnInit, OnDestroy {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  bodegasAux: Maebodega[];
  bodegaSeleccionada: Maebodega;
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonRegresar: boolean;
  tipoBusqueda: string;
  permisoBodega: SegMaePermiso;
  opcion: string;
  barraBotones2: boolean;
  largo = '200';
  public frameworkComponents;
  rowStyle;
  barravisible: boolean;
  arregloCons: any[];
  defaultColDefBodega;
  rowSelection = 'multiple';
  columnDefsBodega = [
    {
      headerName: 'Código', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', editable: false, pinned: 'left',
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
          }
        }
    },
    {
      headerName: 'Nombre', field: 'BOD_NOMBRE', cellEditor: 'cellPrueba', width: 200, pinned: 'left',
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 50
          }
        }
    },
    {
      headerName: 'Dirección', field: 'BOD_UBICACION', cellEditor: 'cellPrueba',
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 30
          }
        }
    },
    {
      headerName: 'Teléfono 1', field: 'BOD_TELEFONO1', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 15
          }
        }
    },
    {
      headerName: 'Teléfono 2', field: 'BOD_TELEFONO2', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 15
          }
        }
    },
    {
      headerName: 'Contacto', field: 'BOD_CONTACTO', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 30
          }
        }
    },
    {
      headerName: 'RUC', field: 'BOD_RUC', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 15
          }
        }
    },
    {
      headerName: 'Autorización', field: 'BOD_AUTORIZACION', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 37
          }
        }
    },
    {
      headerName: 'Fec. Validez', field: 'BOD_FECHAVALIDEZ', cellEditor: 'cellPrueba', width: 200,
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: true
          }
        }
    },
    {
      headerName: 'Tipo', cellEditor: 'agSelectCellEditor', field: 'BOD_TIPO', editable: true, width: 300,
      cellEditorParams: {
        values: ['', 'E']
      }
    },
    {
      headerName: 'Cta. Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 35
          }
        },  cellRendererParams: { checkbox: true}
    },
    {
      headerName: 'Ctro. Cos', field: 'CEN_CODIGO', cellEditor: 'cellPrueba', editable: true,
      // , editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: true,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 35
          }
        }
    }];

  constructor(public bodegaSer: InvmaebodegaService, private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private errorService: ErroresBaseDatosService, private datePipe: DatePipe,
              private permisosService: PermisosService, private auditoriagral: AuditoriagralService,
              private busqService: InvbusquedaService, private confIniciales: ConfInicialesService,
              private init: NuevoComponentService) {
    this.agTable();
    this.permisoBodega = {};
    this.bodegaSer.bodegas = [];
    this.arregloCons = [];
    this.buscarCon();
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefBodega = {
      editable: true,
      width: 100,
      objeto: 'maebodega'
    };
  }
  ngOnInit() {
    this.tipoBusqueda = 'contains';
    this.permisosService.getListaPermisos('INV', '1', '21').subscribe((res) => {
      this.permisoBodega = res[0];
    });
    this.bodegaSer.getBodega().subscribe((res) => {
      if (res !== null) {
      res.map ((bod) => {
        if (bod.BOD_FECHAVALIDEZ !== null && bod.BOD_FECHAVALIDEZ !== '') {
          bod.BOD_FECHAVALIDEZ = bod.BOD_FECHAVALIDEZ.toLocaleString().substr(0, 10);
          bod.BOD_FECHAVALIDEZ = this.datePipe.transform(bod.BOD_FECHAVALIDEZ.toLocaleString(),
            'dd/MM/yyyy');
        }
      });
      this.bodegaSer.bodegas = res;
      this.bodegaSer.bodegas.map((bodega) => {
          if (bodega.CON_CODIGO !== null) {
            this.arregloCons.map((con) => {
              if (con.CON_CODIGO === bodega.CON_CODIGO) {
                bodega.CON_CENTRO = con.CON_CENTRO;
              }
            });
          } else {
            bodega.CON_CENTRO = 'No';
          }
        });
      }
    });
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonRegresar = true;
    this.barraBotones2 = true;
    this.largo = '258';
    this.barravisible = false;
  }
  ngOnDestroy(): void {
    this.confIniciales.instanciaBodega--;
  }
  
  seleccionBodega(params) {
    this.bodegaSeleccionada = params.object;
  }
  cambio(cambio) {
    if (cambio === true) {
      this.botonGuardar = false;
      this.botonRegresar = false;
      this.botonNuevo = true;
    }
  }
  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      if (this.permisoBodega.PERINSERCION === 1) {
        this.nuevaBodega();
      } else if (this.permisoBodega.PERINSERCION === 0) {
        this.messageService.add({
          key: 'bod',
          severity: 'error',
          summary: 'Inserción de Bodega',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    } else if (valor === 'Guardar') {
      if (this.permisoBodega.PERACTUALIZACION === 1) {
        this.botonNuevo = false;
        this.botonRegresar = true;
        this.botonGuardar = true;
        
        if (this.bodegaSeleccionada.BOD_FECHAVALIDEZ !== null) {
          this.bodegaSeleccionada.BOD_FECHAVALIDEZ = this.datePipe.transform(this.bodegaSeleccionada.BOD_FECHAVALIDEZ.toLocaleString()
              .substr(0, 10), 'dd/MM/yyyy');
        }
        this.bodegaSer.saveBodega(this.bodegaSeleccionada);
        this.aggrid.isEditable(false);
      } else if (this.permisoBodega.PERACTUALIZACION === 0) {
        this.messageService.add({
          key: 'bod',
          severity: 'error',
          summary: 'Actualización de Bodega',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }
    } else if (valor === 'Borrar') {
      if (this.permisoBodega.PERELIMACION === 1) {
        if (this.bodegaSeleccionada === undefined) {
          this.messageService.add({
            key: 'bod',
            severity: 'error',
            summary: 'Eliminacion de Bodega',
            detail: 'Seleccion Incorrecta, no se ha seleccionado una bodega'
          });
        } else   {
          this.confirmarBorrar();
          this.aggrid.isEditable(false);
        }
      } else if (this.permisoBodega.PERELIMACION === 0) {
        this.messageService.add({
          key: 'bod',
          severity: 'error',
          summary: 'Eliminación de Bodega',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
      }
    } else if (valor === 'Cancelar') {
      this.cancelarCambios();
      this.aggrid.isEditable(false);
    }
    
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
  confirmarBorrar() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar la bodega',
      header: 'Eliminar Bodega',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarBod',
      accept: () => {
        this.borrarBodega();
      },
      reject: () => {
      }
    });
  }
  nuevaBodega() {
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonRegresar = false;
    let numero: number;
    if (this.bodegaSer.bodegas.length === 0) {
      numero = 0;
    } else {
      numero = Number(this.bodegaSer.bodegas[this.bodegaSer.bodegas.length - 1].BOD_CODIGO);
    }
    const bodegaNueva: Maebodega = {
      BOD_CODIGO: '0' + String(numero + 1), BOD_NOMBRE: '', BOD_UBICACION: '', BOD_TELEFONO1: ''
      , BOD_TELEFONO2: '', BOD_CONTACTO: '', BOD_AUTORIZACION: '', BOD_TIPO: '', BOD_RUC: '', BOD_FECHAVALIDEZ: '', CON_CODIGO: '',
      CEN_CODIGO: ''
    };
    this.bodegaSer.bodegas.push(bodegaNueva);
    this.aggrid.refreshaggrid(this.bodegaSer.bodegas, this.defaultColDefBodega.objeto);
    this.bodegaSeleccionada = this.bodegaSer.bodegas[this.bodegaSer.bodegas.length - 1];
    this.aggrid.isEditable(true);
  }
  cancelarCambios() {
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonRegresar = true;
    this.bodegaSer.getBodega().subscribe((respuesta) => {
      respuesta.map ((bod) => {
        if (bod.BOD_FECHAVALIDEZ !== null) {
          bod.BOD_FECHAVALIDEZ = bod.BOD_FECHAVALIDEZ.toLocaleString().substr(0, 10);
        }
      });
      this.bodegasAux = respuesta;
      this.bodegaSer.bodegas = this.bodegasAux;
    });
  }
  borrarBodega() {
    if (this.bodegaSeleccionada === undefined) {
      this.bodegaSer.borrarBodega(this.bodegaSer.bodegas[this.bodegaSer.bodegas.length - 1].BOD_CODIGO).subscribe(() => {
        this.messageService.add({
          key: 'bod',
          severity: 'success',
          summary: 'Eliminación de Bodega',
          detail: 'La bodega se eliminó correctamente'
        });
        this.auditoriagral.registrarAuditoria('INV_MAEBODEGA', String(this.bodegaSeleccionada.BOD_CODIGO), 'E', '',
          '01', '', '', '', '').subscribe(() => {});
        this.bodegaSer.bodegas = this.bodegaSer.bodegas.filter((val, j) => j !==
          this.bodegaSer.bodegas.indexOf(this.bodegaSer.bodegas[this.bodegaSer.bodegas.length - 1]));
      }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'bod',
          severity: 'error',
          summary: 'Eliminación de Bodega',
          detail: mensaje
        });
      });
      return;
    }
    this.botonNuevo = false;
    this.botonRegresar = true;
    this.botonGuardar = true;
    let contador = 0;
    let i = 0;
    do {
      if (this.bodegaSeleccionada.BOD_CODIGO === this.bodegaSer.bodegas[i].BOD_CODIGO) {
        contador++;
      }
      i++;
    } while (this.bodegaSer.bodegas.length > i);
    if (contador === 1) {
      this.bodegaSer.borrarBodega(this.bodegaSeleccionada.BOD_CODIGO).subscribe(() => {
        this.messageService.add({
          key: 'bod',
          severity: 'success',
          summary: 'Eliminación de Bodega',
          detail: 'La bodega se eliminó correctamente'
        });
        this.auditoriagral.registrarAuditoria('INV_MAEBODEGA', String(this.bodegaSeleccionada.BOD_CODIGO), 'E', '',
          '01', '', '', '', '').subscribe(() => {});
        this.bodegaSer.bodegas = this.bodegaSer.bodegas.filter((val, j) => j !==
          this.bodegaSer.bodegas.indexOf(this.bodegaSeleccionada));
      }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'bod',
          severity: 'error',
          summary: 'Eliminación de Bodega',
          detail: mensaje
        });
      });
    } else if (contador !== 1) {
      this.bodegaSer.bodegas = this.bodegaSer.bodegas.filter((val, j) => j !== this.bodegaSer.bodegas.indexOf(this.bodegaSeleccionada));
    }
  }
  buscarCon() {
    this.busqService.busquedaConMaeCon().subscribe((res: any[]) => {
      this.arregloCons = res;
    });
  }
}
