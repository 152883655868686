import { Component, OnInit, ViewChild } from '@angular/core';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { PermisosService } from 'src/app/advantage/advantageservices/permisos.service';
import { MessageService, ConfirmationService, SelectItem } from 'primeng';
import { Usuario } from 'src/app/usuario';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { DatePipe } from '@angular/common';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { ActMaeGrupo } from '../actinterfaces/actmaegrupo';
import { ActmaegrupoService } from '../actservicios/actmaegrupo.service';
import { SegMaePermiso } from 'src/app/advantage/advantageinterfaces/segmaepermiso';

@Component({
  selector: 'app-actmaegrupo',
  templateUrl: './actmaegrupo.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class ActmaegrupoComponent implements OnInit {

  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  btnNuevo = false;
  btnGuardar = true;
  btnBorrar = false;
  btnBuscar = false;

  // Permisos
  permisos: SegMaePermiso;

  public frameworkComponents;
  rowStyle;
  defaultActMaeGrupo;
  rowSelection = 'multiple';
  largo: string;
  ancho: string;

  detalleGrupSelect: ActMaeGrupo = {};
  indicador: any;

  where: string;
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  condicion: string;
  opcion: string;


  columnDefActMaeGrupo = [
    {
      headerName: 'Código.', field: 'ACTGR_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 5,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: 'Nombre', field: 'ACTGR_NOMBRE', cellEditor: 'cellPrueba', width: 350, editable:true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 50,
            tienecalculos: true,
          }
        }
    } ,
    {
      headerName: 'Cuenta Cont', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 150, editable:true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },

  ]

  constructor(public actmaegrupoService:ActmaegrupoService, private auditoriaGralService: AuditoriagralService,
    private permisosService: PermisosService, private message: MessageService, public usuario: Usuario,
    private errorService: ErroresBaseDatosService, private busquedaSer: InvbusquedaService,
    private datePipe: DatePipe, private confirmationService: ConfirmationService, 
    private confIniciales: ConfInicialesService, private init: NuevoComponentService) {
      this.actmaegrupoService.detmaeGrupoArray[this.indicador] = [];
      this.agTable();
    }

    agTable() {
      this.frameworkComponents = {
        cellPrueba: CellEditorPrueba
      };
      this.rowStyle = (params) => {
        if (params.node.rowIndex % 2 !== 0) {
          return { background: '#D6EAF8' };
        }
      };
      this.defaultActMaeGrupo = {
        editable: true,
        width: 100,
        objeto: 'actmaegrupo',
        resizable: true
      };
    }

  async ngOnInit() {
    this.largo = '360';
    this.ancho = '60';
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.actmaegrupoService.detmaeGrupoArray[this.indicador] = [];
    this.where = null;
    this.actmaegrupoService.btnGuardar[this.indicador] = true;
    this.actmaegrupoService.btnNuevo[this.indicador] = false;
    this.actmaegrupoService.btnBorrar[this.indicador] = false;
    this.actmaegrupoService.btnBuscar[this.indicador]= false;
    this.actmaegrupoService.activateButtons('NORMAL_STATE');
    await this.getPermisos();
    await this.consultaGRUPOS(null);
  }

  async manejarSenales(valor) {
    
    if (valor === 'Nuevo') {
      if (this.permisos !== null) {
        if (this.permisos.PERINSERCION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ',  no tiene permiso de inserción. Consulte con el Administrador del Sistema.');
          return;
        }
      }
      

      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultActMaeGrupo.objeto].stopEditing();
      this.nuevaLineaDetalle();
    
    }
    if (valor === 'Borrar') {
      if (this.permisos !== null) {
        if (this.permisos.PERELIMACION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ',  no tiene permiso de eliminación. Consulte con el Administrador del Sistema.');
          return false;
        }
      }

      if (Object.keys(this.detalleGrupSelect).length > 0) {
        this.confirmEliminar();
      } else {
        this.mensajeAlerta('error', 'Error', '! Seleccione una fila.... !');
      }
    }
    if (valor === 'Guardar') {
      await this.insertaactualizaGrupo();
			this.actmaegrupoService.activateButtons('NORMAL_STATE');
      
    }
    if (valor === 'Cancelar') {
     this.refreshTables(0);
    }
    if (valor === 'Buscar') {
     this.buscarGrupo();
    }

    if (valor === 'Salir') {
     
      this.init.tabs.splice(this.init.tabindex, 1);
          
    }

  }


  async getPermisos() {
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisos = data[0];
        } else {
          this.permisos = null;
        }
      } else {
        this.permisos = null;
      }
    } catch (e) {
      this.permisos = null;
      this.mensajeAlerta('error', 'Error', 'No se pudo obtener los permisos del formulario');
    }
  }

  nuevaLineaDetalle() {
    const linea = this.actmaegrupoService.detmaeGrupoArray[this.indicador].length;
    const nuevodetalle: ActMaeGrupo = {
      LINEA: linea + 1,
      ACTGR_CODIGO: '',
      ACTGR_CODIGOP: '',
      ACTGR_NOMBRE: '',
      CEN_CODIGO: '',
      COD_ANTERIOR: '',
      IS_NEW_ROW: true,
      CON_CODIGO: ''          
    };
         
    const indi = this.actmaegrupoService.detmaeGrupoArray[this.indicador].indexOf(this.detalleGrupSelect);
    if (indi === -1) {
      this.actmaegrupoService.detmaeGrupoArray[this.indicador].push(nuevodetalle);
      this.detalleGrupSelect = this.actmaegrupoService.detmaeGrupoArray[this.indicador][this.actmaegrupoService.detmaeGrupoArray[this.indicador].length - 1];
      this.aggrid.refreshaggrid(this.actmaegrupoService.detmaeGrupoArray[this.indicador], this.defaultActMaeGrupo.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultActMaeGrupo.objeto].setFocusedCell(
      this.actmaegrupoService.detmaeGrupoArray[this.indicador].length - 1, 'ACTGR_CODIGO');
    } else {
      this.detalleGrupSelect = nuevodetalle;
      this.actmaegrupoService.detmaeGrupoArray[this.indicador].splice(indi + 1, 0, nuevodetalle);
      this.aggrid.refreshaggridindex(this.actmaegrupoService.detmaeGrupoArray[this.indicador], this.defaultActMaeGrupo.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultActMaeGrupo.objeto].setFocusedCell(
      indi + 1, 'ACTGR_CODIGO');
    }
  }

  async refreshTables(type: number) {
    try {
      const indi = this.actmaegrupoService.detmaeGrupoArray[this.indicador].indexOf(this.detalleGrupSelect);
      await this.consultaGRUPOS(null);
      if (indi === -1) {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultActMaeGrupo.objeto].setFocusedCell(
          this.actmaegrupoService.detmaeGrupoArray[this.indicador].length - 1, 'ACTGR_CODIGO');
      } else {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultActMaeGrupo.objeto].setFocusedCell(
          indi + 1, 'ACTGR_CODIGO');
      }

      this.actmaegrupoService.activateButtons('NORMAL_STATE');
    } catch (err) {

    }
  }

  async insertaactualizaGrupo() {
	
		this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultActMaeGrupo.objeto].stopEditing();
		if (this.detalleGrupSelect.IS_NEW_ROW) {
      if(this.verificaPreingreso() ===true) {
       if (await this.insertaGrupo()) {
         this.mensajeAlerta('success', 'Información', 'Registro guardado exitosamente....!!!');
         const indi = this.actmaegrupoService.detmaeGrupoArray[this.indicador].indexOf(this.detalleGrupSelect);
         this.actmaegrupoService.detmaeGrupoArray[this.indicador][indi].IS_NEW_ROW = false;
         this.detalleGrupSelect.IS_NEW_ROW = false;
        } else {
          let intIndiceLista = 0;
          for (intIndiceLista = 0; intIndiceLista < this.actmaegrupoService.detmaeGrupoArray[this.indicador].length; intIndiceLista++) {
            const item = this.actmaegrupoService.detmaeGrupoArray[this.indicador][intIndiceLista];
      
            if (item.ACTGR_CODIGO === this.detalleGrupSelect.ACTGR_CODIGO) {
              this.actmaegrupoService.detmaeGrupoArray[this.indicador].splice(this.actmaegrupoService.detmaeGrupoArray[this.indicador].findIndex(
                det => det.LINEA === item.LINEA && det.ACTGR_CODIGO === item.ACTGR_CODIGO),
                1);
            }

          }
          this.aggrid.refreshaggrid(this.actmaegrupoService.detmaeGrupoArray[this.indicador], this.defaultActMaeGrupo.objeto);
          this.actmaegrupoService.activateButtons('NORMAL_STATE');
        }
      } else { 
        const indi = this.actmaegrupoService.detmaeGrupoArray[this.indicador].indexOf(this.detalleGrupSelect);
         this.actmaegrupoService.detmaeGrupoArray[this.indicador][indi].IS_NEW_ROW = true;
         this.detalleGrupSelect.IS_NEW_ROW = true;
      }

    } else {
      if (this.permisos !== null) {
        if (this.permisos.PERACTUALIZACION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ',  no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
          return false;
        }
      }

      if(await this.verificaPreingreso()) {
          if(await this.actualizaGrupo()) {
            this.mensajeAlerta('success', 'Información', 'Registro actualizado exitosamente....!!!');
            await this.consultaGRUPOS(null);
            this.actmaegrupoService.activateButtons('NORMAL_STATE');
        }else {

        }
      }
    }
  }

  async actualizaGrupo() {

		if (this.permisos !== null) {
      if (this.permisos.PERACTUALIZACION === 0) {
        this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
          ',  no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
        return false;
      }
    }

		const params: any[] = [];
		params[0] = this.detalleGrupSelect.ACTGR_CODIGO;
		params[1] = this.detalleGrupSelect.ACTGR_CODIGO;
		params[2] = this.detalleGrupSelect.ACTGR_NOMBRE;
		params[3] = this.detalleGrupSelect.CON_CODIGO;
		params[4] = '';
		params[5] = '';

		try {
			await this.actmaegrupoService.actualizarGRUPO(params);
      this.auditoriaGralService.registrarAuditoria('ACT_MAEGRUPO', this.detalleGrupSelect.ACTGR_CODIGO, 'A',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });

		} catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
			return false;
		}
		return true;
	}

  async insertaGrupo() {
    //console.log('********');
    if (this.permisos !== null) {
      if (this.permisos.PERINSERCION === 0) {
        this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
          ',  no tiene permiso de inserción. Consulte con el Administrador del Sistema.');
        return false;
      }
    }

    const params: any[] = [];
		params[0] = this.detalleGrupSelect.ACTGR_CODIGO;
		params[1] = this.detalleGrupSelect.ACTGR_NOMBRE;
		params[2] = this.detalleGrupSelect.CON_CODIGO;
		params[3] = '';
    params[4] = '';
    
    try {
      await this.actmaegrupoService.insertarGRUPO(params);
      this.auditoriaGralService.registrarAuditoria('ACT_MAEGRUPO', this.detalleGrupSelect.ACTGR_CODIGO, 'I',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
      return false;
    }

    return true;
  }
  
  verificaPreingreso() {
    if (String(this.detalleGrupSelect.ACTGR_CODIGO).length === 0) {
      this.mensajeAlerta('error', 'Error', 'El código es obligatorio..!!!');
      this.actmaegrupoService.activateButtons('EDIT');
      return false;
    }

    if (String(this.detalleGrupSelect.ACTGR_NOMBRE).length === 0) {
      this.mensajeAlerta('error', 'Error', 'La descripción es obligatoria..!!!');
      this.actmaegrupoService.activateButtons('EDIT');
      return false;
    }

    return true;
  }

  buscarGrupo () {
    this.opcion = 'BUSCAR';
    this.types = [
      {label: 'CÓDIGO', value: 'ACTGR_CODIGO'},
      {label: 'NOMBRE', value: 'ACTGR_NOMBRE'},
      {label: 'CTA.CONTABLE', value: 'CON_CODIGO'}
    ];
  
    this.busquedacampos = ['', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'act_maegrupo';
    this.condicion = 'actgr_codigop IS NULL ORDER by actgr_codigo ASC';
    this.arregloCons = [];
    this.displayDialogBusquedaFast = true;
  }

  manejarSeleccion(valor) {
    if (this.opcion === 'BUSCAR') {
      this.displayDialogBusquedaFast = false;
      this.llenarTabla(valor);
    }
  }

  llenarTabla(datos) {
    this.actmaegrupoService.detmaeGrupoArray[this.indicador] = datos;
    let numLin = 0;
    this.actmaegrupoService.detmaeGrupoArray[this.indicador].map( item => {
      numLin ++;
      item.ACTGR_CODIGOP = item.ACTGR_CODIGOP === null ? '' : item.ACTGR_CODIGOP;
      item.CEN_CODIGO = item.CEN_CODIGO === null ? '' : item.CEN_CODIGO;
      item.COD_ANTERIOR = item.ACTGR_CODIGO;
      item.CON_CODIGO = item.CON_CODIGO === null ? '' : item.CON_CODIGO;
      item.IS_NEW_ROW = false;
      item.LINEA = numLin;
    });
    this.aggrid.refreshColumnDefs();
  }

  cambio(event) {
    
    this.actmaegrupoService.activateButtons('EDIT');
  }

  selectActMaeGrupo(valor) {
    if (valor.object !== undefined) {
      this.detalleGrupSelect= valor.object;
    }
  }

  async consultaGRUPOS(where) {

    this.actmaegrupoService.detmaeGrupoArray[this.indicador] = [];
    const params: any[] = [];
    params[0] = '';
    params[1] = 'actgr_codigo';
    params[2] = '';

    try {
      const rs = await this.actmaegrupoService.consultaGRUPOS(params);

      if (rs !== null) {
        let numLin = 0;
        const detalle = [];
        for (const i of rs) {
          numLin ++;
          const item: ActMaeGrupo = {};
          item.ACTGR_CODIGO = i['Codigo'];
          item.ACTGR_CODIGOP = '';
          item.ACTGR_NOMBRE = i['Nombre'];
          item.CEN_CODIGO = '';
          item.COD_ANTERIOR = i['Codigo'];
          item.CON_CODIGO = i['Cta Contable'];
          item.IS_NEW_ROW = false;
          item.LINEA = numLin;
          detalle.push(item);
        }

        this.actmaegrupoService.detmaeGrupoArray[this.indicador] = detalle;
      }
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
    }


  }

  confirmEliminar() {
    this.confirmationService.confirm({
      message: '¿ Está seguro de eliminar el registro ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'actmaegrupoConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarDetalleSel();
      },
      reject: () => {
      }
    });
  }

  async eliminarDetalleSel() {
    const params:any[] = [];
    params[0] = this.detalleGrupSelect.ACTGR_CODIGO;
    try {
      await this.actmaegrupoService.eliminarGRUPO(params);
      this.auditoriaGralService.registrarAuditoria('ACT_MAEGRUPO', this.detalleGrupSelect.ACTGR_CODIGO, 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
      this.mensajeAlerta('success', 'Información', 'Registro eliminado exitosamente....!!!')
      this.actmaegrupoService.detmaeGrupoArray[this.indicador] = this.actmaegrupoService.detmaeGrupoArray[this.indicador].filter((val, j) => j !==
      this.actmaegrupoService.detmaeGrupoArray[this.indicador].indexOf(this.detalleGrupSelect));
      this.aggrid.refreshaggrid(this.actmaegrupoService.detmaeGrupoArray[this.indicador], this.defaultActMaeGrupo.objeto);
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
    }
  }

  

  
  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'actmaegrupo',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

}
