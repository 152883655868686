<p-toast [style]="{marginTop: '30px', width: '500px'}" position="top-center" key="contaRol"></p-toast>
<div class="p-grid" style="width: 99%">
    <div class="p-col-12">
        <app-invmaebarra [barraBotonesCRUD]="true" [barraBotonesDesplaz]="true" [barraBotones3]="true"
        [botonVisto]="false" [barraBotonesAsiCon]="true" [barraBotones5]="true" [barraBotones3Alt]="false" (signal)="manejarSenales($event)"></app-invmaebarra>
    </div>
</div> 

<div style="overflow: hidden" class="p-col-12">
    <p-tabView>
        <p-tabPanel header="Roles de Pago">
            <div class="divgrups">
                <div class="p-grid" style="margin-top: 10px;">
                    <div class="p-col-1">
						<span>Fecha Inicial:</span>
                    </div>
                    <div class="p-col-4">
                        <p-calendar [(ngModel)]="fechaIni" [inputStyle]="{width: '100%', height: '20px'}" dateFormat="dd/mm/yy" ></p-calendar>
                    </div>
                    <div class="p-col-4">
                        <div class="p-field-radiobutton" style="margin-left: 10px;">
                            <p-radioButton [(ngModel)]="radioButton"  value="val1"></p-radioButton>
                                <label for="">Todas</label>
                        </div>
                    </div>
                </div>
                <div class="p-grid" style="margin-top: 10px;">
                    <div class="p-col-1">
						<span>Fecha Final:</span>
                    </div>
                    <div class="p-col-4">
                        <p-calendar [(ngModel)]="fechaFin" [inputStyle]="{width: '100%', height: '20px'}" dateFormat="dd/mm/yy" ></p-calendar>
                    </div>
                    <div class="p-col-4">
                        <div class="p-field-radiobutton" >
                            <p-radioButton [(ngModel)]="radioButton" value="val2" ></p-radioButton>
                                <label for="">No contabilizadas</label>
                        </div> 
                    </div>
                    <div class="p-col-3">
                        <button   
                            pButton class="aceptar" 
                            style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 49%;margin-left: 5px;margin-bottom: 5px;" 
                            (click)="consultarRoles()">Aceptar</button>
                    </div>
                </div>
                <div class="p-grid" style="margin-top: 10px;">
                    <div class="p-col-9"></div>
                    <div class="p-col-3">
                        <p-checkbox value="valSelect" (onChange)="selectTodos($event)"></p-checkbox>
                        <label for="">Seleccionar Todos</label>
                    </div>
                </div>
            </div>
            <app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="rolesPago"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefRolPagos"
							[defaultColDef]="defaultColDefRolPagos"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"

			></app-aggridsaci>
        </p-tabPanel>
    </p-tabView>
</div>
<!-- CDPJ -->
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
    <p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
    </p-progressSpinner>
    <!-- <p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
	</p-dialog> -->
</div>
<!-- CDPJ -->
