
import {Renderer2,Component, OnDestroy, OnInit, ViewChild, ElementRef} from '@angular/core';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {AggridBanComponent} from '../../ban/aggrid-ban/aggrid-ban.component';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {DatePipe} from '@angular/common';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {EletrndocumentoService} from 'src/app/ven/venservicios/eletrndocumento.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import { comdatosimp ,comdatos,ComEncfacpro,comdathomologa} from '../cominterfaces/comencfacpro';
import { ComDetfacpro } from 'src/app/com/cominterfaces/comdetfacpro';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import { ComencfacproService } from 'src/app/com/comservicios/comencfacpro.service';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {ComimpdoceleService}from'../comservicios/comimpdocele.service';
import { Cxctrnretencion } from 'src/app/cxc/cxcinterfaces/cxctrnretencion';
import { CxctrnretencionesService} from 'src/app/cxc/cxcservicios/cxctrnretenciones.service';
@Component({ 
  selector: 'app-comimpdocele',
  templateUrl: './comimpdocele.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class ComimpdoceleComponent implements OnInit {
  public frameworkComponents;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  indicador: any;
  arreglotxt:  []; 
  guararreglotxt: [];
  selecteditem: '';
  arreglocodalt:  [];
  selecteditemcodalt: '';
  documentosupload: any[];
  documentos: any[];
  documentosfaccomp: any[];
  documentosretcom: any[];
  documentosnodeducible: any[];
  codalt: any[];
  encfacimp: ComEncfacpro; 
  detfachomo: any;
  defaultColDefDoc;
  defaultColDefDocfaccom;
  defaultColDefDocretcom;
  defaultDefscodalter;
  btnGuardarhomo = true;
  rowStyle;
  rowStyle1;
  choosedColorR;
  largo: string;
  types: SelectItem[];
  arregloCons: any[];
  tabla: string;
  consulta: string;
  where: string;
  opcionbusqueda;
  busquedacampos: string[];
  activeIndex;
  indTabladatos;
  displayAcciones: boolean;
  bloqdisable: boolean;
  botonArchivo: boolean;
  botonVisto: boolean;
  filesImportXML = [];
  desSelect: boolean;
  indiceTablas: number;
  spin = false;
  nodedutodo: boolean;
  rowSelection;
  resultado;
  displayDialogBusquedaFast;
  displaycodalt;
  botonesBoolean: boolean;
  newnumfac:'';
  filesini: any[];
  rutacarp;
  PRO_CODIGO;
  PRO_NOMBRE;
  // LECTURA TXT
  fileReader: FileReader;
  fileTXT: any;
  localizacionxml2: string;
  bodcodigoxml
  botonesmodificar;
  bodnombrexml;
  idcreditoxml;
  idcreditonombrexml;
  idbloqarch;
  idnumimp;
  defaultDefsnodeducible;
  selectlinea: boolean;
  actbotones1: boolean;
  actbotones2: boolean;
  actbotones3: boolean;
  RUC;
  numarchivos: number;
  numleidos: number;
  faccomTodo: boolean;
  retcomTodo: boolean;
  //GSRF
  columnDefsDoc = [
    // {
    //   headerName: '', field: 'VISTO', editable: false, width: 50,
    //   cellRendererFramework: AggridBanComponent
    // },
    {
      headerName: 'Archivo', field: 'doc_name', editable: false, width: 350
    },
    {
      headerName: 'Fecha', field: 'doc_date', editable: false, width: 110
    },
    {
      headerName: 'Tipo', field: 'doc_tipo', editable: false, width: 70
    },
    {
      headerName: 'Origen', field: 'doc_orig', editable: false, width: 70
    },
    {
      headerName: 'Documento', field: 'doc_doc', editable: false, width: 110
    },
    {
      headerName: 'RUC', field: 'doc_RUC', editable: false, width: 120
    },
    {
      headerName: 'Nombre Comercial', field: 'doc_nomcom', editable: false, width: 250
    },
    {
      headerName: 'Autorización', field: 'doc_auth', editable: false, width: 300
    },
  ];
  columnDefsDocfaccom = [
    {
      headerName: 'Sel.', field: 'VISTO', editable: false, width: 60,
      cellRendererFramework: AggridBanComponent
    },
    {
      headerName: 'Archivo', field: 'doc_name', editable: false, width: 350
    },
    {
      headerName: 'Fecha', field: 'doc_date', editable: false, width: 110
    },
    {
      headerName: 'Tipo', field: 'doc_tipo', editable: false, width: 70
    },
    {
      headerName: 'Origen', field: 'doc_orig', editable: false, width: 70
    },
    {
      headerName: 'Documento', field: 'doc_doc', editable: false, width: 110
    },
    {
      headerName: 'RUC', field: 'doc_RUC', editable: false, width: 120
    },
    {
      headerName: 'Nombre Comercial', field: 'doc_nomcom', editable: false, width: 250
    },
    {
      headerName: 'Autorización', field: 'doc_auth', editable: false, width: 300
    }
  ];

  columnDefsDocretcom = [
    {
      headerName: 'Sel.', field: 'VISTO', editable: false, width: 60,
      cellRendererFramework: AggridBanComponent
    },
    {
      headerName: 'Archivo', field: 'doc_name', editable: false, width: 350
    },
    {
      headerName: 'Fecha', field: 'doc_date', editable: false, width: 110
    },
    {
      headerName: 'Tipo', field: 'doc_tipo', editable: false, width: 70
    },
    {
      headerName: 'Origen', field: 'doc_orig', editable: false, width: 70
    },
    {
      headerName: 'Documento', field: 'doc_doc', editable: false, width: 110
    },
    {
      headerName: 'RUC', field: 'doc_RUC', editable: false, width: 120
    },
    {
      headerName: 'Nombre Comercial', field: 'doc_nomcom', editable: false, width: 250
    },
    {
      headerName: 'Autorización', field: 'doc_auth', editable: false, width: 300
    },
  ];
  columnDefscodalter = [
      {
        headerName: 'Proveedor', field: 'PRO_CODIGO', width: 120,
        editable: (params) => {
          return false;
        }
      },{
        headerName: 'Nombre Pro', field: 'PRO_NOMBRE', width: 200,
        editable: (params) => {
          return false;
        }
      },
      {
        headerName: 'Codigo', field: 'DETFAC_CODIGO', cellEditor: 'cellPrueba', width: 100,
        editable: (params) => {
          return false;
        },
        cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 25,
            opcionbuscar: true
          }
        }
      },
      {
        headerName: 'Producto', field: 'DETFAC_DESCRIPCION', width: 200,
        editable: (params) => {
          return false;
        },
      },
      {
        headerName: 'Tipo', field: 'DETFAC_TIPO', width: 70, cellEditor: 'agSelectCellEditor',
        // , editable: true,
        cellEditorParams:  
        {
          values: ['','A','S','C']
        }, onCellValueChanged: (params) => {
          console.log('----',params)
          params.data.DETFACPRO_UNIDAD='';
          params.data.DETFAC_CODIGOTIPO='';
          params.data.DETFAC_NOMBRETIPO='';
          params.data.DETFAC_HOMOLOGADO=0;
         this.btnGuardarhomo=false;
         //this.aggrid.refreshaggrid(this.ComimpdoceleService.arreglohomo[this.indicador], this.defaultDefscodalter.objeto);
         this.aggrid.refreshaggridindex(this.ComimpdoceleService.arreglohomo[this.indicador],this.defaultDefscodalter.objeto,params.node.childIndex);
         this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultDefscodalter.objeto].setFocusedCell(params.node.childIndex, 'DETFAC_CODIGOTIPO');
        }
      },
      {
        headerName: 'Homologación', field: 'DETFAC_CODIGOTIPO', cellEditor: 'cellPrueba', width: 110,
        // , editable: true,
        cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 25,
            opcionbuscar: true
          }
        }, onCellValueChanged: (params) => {
          this.btnGuardarhomo=false;
         }
      },
      {
        headerName: 'Descripción', field: 'DETFAC_NOMBRETIPO', 
        editable: (params) => {
          return false;
        }, width: 210, onCellValueChanged: (params) => {
          this.btnGuardarhomo=false;
         }
      },
      {
        headerName: 'Unidad', field: 'DETFACPRO_UNIDAD', width: 70,
        editable: (params) => {
          return false;
        }
      },
      {
        headerName: 'Est.', field: 'DETFAC_HOMOLOGADO', width: 50,
        cellRendererFramework: AggridBanComponent
      },
    ];
    
  columnDefsnodeducible = [ 
    {
      headerName: 'Sel.', field: 'VISTO', editable: false, width: 60,
      cellRendererFramework: AggridBanComponent
    },
    {
      headerName: 'Clave de Acceso', field: 'TRNCLAVEDOC_CLACESSO', editable: false, width: 450
    },
  ];
  constructor(private init: NuevoComponentService,public encfacproService: ComencfacproService,
    private busqService: InvbusquedaService, private datePipe: DatePipe ,
    public ComimpdoceleService: ComimpdoceleService, private renderer: Renderer2,
     private messageService: MessageService, private confirmationService: ConfirmationService,
     public cxctrnretencionesService:CxctrnretencionesService) { 
      this.agTable();
      this.ComimpdoceleService.arreglohomo[this.indicador]=[]
    }
    agTable() {
      this.frameworkComponents = {
        cellPrueba: CellEditorPrueba
      };
    
      this.rowStyle1 = (params) => {
        if (params.node.rowIndex % 2 !== 0) {
          return {background: '#D6EAF8'};
        }
      };
      this.rowStyle = (params) => {
        if (params.node.data.doc_auth  === 'ARCHIVO NO LEIDO' ) {
          return {background: '#FF6666'};
        } else if (params.node.data.doc_auth  === 'ARCHIVO YA PROCESADO') {
          return {background: 'rgb(128, 255, 128)'};
        }else if (params.node.data.doc_auth  === 'NO DEDUCIBLE') {
          return {background: '#A571FC'};
        }else if (params.node.rowIndex % 2 !== 0) {
          return {background: '#D6EAF8'};
        }
      };
      this.defaultColDefDoc = {
        editable: true,
        objeto: 'docimp'
      };
      this.defaultColDefDocfaccom = {
        editable: true,
        objeto: 'docimpfaccom'
      };
      this.defaultColDefDocretcom = {
        editable: true,
        objeto: 'docimpretcom'
      };
      this.defaultDefscodalter = {
        editable: true,
          width: 100,
          objeto: 'detfachomo',
          resizable: true
      };
      this.defaultDefsnodeducible={
        editable: true,
          width: 100,
          objeto: 'nodeducible',
          resizable: true
      };
    }

    async ngOnInit() {
      this.bloqdisable = true;
      this.botonArchivo = true;
      this.botonVisto = true;
      this.ComimpdoceleService.arreglohomo[this.indicador]=[]
      this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
      this.idbloqarch = '';
      this.largo = '500';
      this.fileTXT = '';
      this.selectlinea = true;
      this.actbotones1= true;
      this.actbotones2= true;
      this.actbotones3= true;
      this.arreglotxt = [];
      this.guararreglotxt = [];
      this.bodcodigoxml='';
      this.bodnombrexml='';
      this.idcreditoxml='';
      this.idcreditonombrexml='';
      this.choosedColorR = '#FF0000';
      this.spin = false;
      let cod = await this.ComimpdoceleService.buscaprov();
      
     
      if(cod[0].PRO_CODIGO !== null || cod[0].PRO_CODIGO !== undefined || cod[0].PRO_CODIGO !== ''  ){
        this.PRO_CODIGO= cod[0].PRO_CODIGO;
        this.PRO_NOMBRE= await this.busquedaproveedor(cod[0].PRO_CODIGO);
      }else{
        this.PRO_CODIGO= '';
        this.PRO_NOMBRE='';
        this.messageService.add({
          key: 'impdocsri',
          severity: 'error',
          summary: 'Importar',
          detail: 'Coloque un proveedor'
        });
      }
      //await this.ComimpdoceleService.deleteencfacpro();
      //await this.ComimpdoceleService.deletedetfacpro();
      await this.ComimpdoceleService.deleteret();
      const nodedu = await this.ComimpdoceleService.buscnodedu(); 
      this.documentosnodeducible=nodedu;
    }
 async manejarSenales(valor){
    switch (valor) {
      case 'Salir':
       this.init.tabs.splice(this.init.tabindex, 1);
      break;
      case 'Visto':
        this.detfachomo = await this.ComimpdoceleService.buscahomologa();
        for(let aux of this.detfachomo){
          if(aux.DETFAC_HOMOLOGADO === 0 && this.indTabladatos === 1){
            this.messageService.add({
              key: 'impdocsri',
              severity: 'error',
              summary: 'Importar',
              detail: 'Homologar todo el detalle'
            });
            return;
          }
        }
        if(this.idbloqarch === ''){
          this.messageService.add({
            key: 'impdocsri',
            severity: 'error',
            summary: 'Importar',
            detail: 'Genere número de bloque'
          });
          return;
         }
         this.bloqdisable = true;
        switch (this.indTabladatos) {
          case 1: {
          //   this.spin=true;
          //   try{
          //   await this.ComimpdoceleService.updateablodnum(this.indTabladatos,this.idbloqarch);
          //   await this.ComimpdoceleService.updatehomovsfacimp();
          //   await this.ComimpdoceleService.impencandet();
          //   this.messageService.add({
          //     key: 'impdocsri',
          //     severity: 'success',
          //     summary: 'Importar Factura',
          //     detail: 'Se importó exitosamente'
          //   });
          //   this.idbloqarch = '';
          //   await this.ComimpdoceleService.deleteencfacpro();
          //   await this.ComimpdoceleService.deletedetfacpro();
          //   this.spin=false;
          // } catch (error) {
          //   this.messageService.add({
          //     key: 'impdocsri',
          //     severity: 'error',
          //     summary: 'Importar Factura',
          //     detail: 'Error al Importar'
          //   });
          //   this.spin=false;
          // }
            break;
          }
          case 2: {
            try{
              this.spin=true;
              await this.ComimpdoceleService.impretencion(this.idbloqarch);
              this.messageService.add({
                key: 'impdocsri',
                severity: 'success',
                summary: 'Importar Retención',
                detail: 'Se importó exitosamente'
              });
              this.idbloqarch = '';
              await this.ComimpdoceleService.deleteret();
              this.spin=false;
          } catch (error) {
            this.messageService.add({
              key: 'impdocsri',
              severity: 'error',
              summary: 'Importar Retención',
              detail: 'Error al Importar'
            });
            this.spin=false;
          }

            break;
          }
        }
        const nodedu = await this.ComimpdoceleService.buscnodedu(); 
        this.documentosnodeducible=nodedu;
        await this.onBasicUpload(this.filesini);
        this.aggrid.refreshaggrid(this.documentosnodeducible, this.defaultDefsnodeducible.objeto);
        this.aggrid.refreshaggrid(this.documentosfaccomp, this.defaultColDefDocfaccom.objeto);
        this.aggrid.refreshaggrid(this.documentosnodeducible, this.defaultDefsnodeducible.objeto);
        this.faccomTodo = false;
        this.retcomTodo = false;
        this.nodedutodo = false;
        this.botonVisto = true;
      break;
      case 'Listaarch':
       await this.ejecutararch();
        
      break;
      case 'impdoc':
        await this.impinformacion();
        
      break;
      default:
      break;
    }
  }
  sal() {
    this.displaycodalt= false;
  }
  ejecutararch(){
    console.log('entra',this.fileInput.nativeElement)
    
    const inputElement = this.fileInput.nativeElement;
    this.renderer.setProperty(inputElement,'value', null);
    this.rutacarp = '';
    this.fileInput.nativeElement.click();
    
  }
  acciones() {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className = 'layout-config';
      }
    }
  }
  closeSidebar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }
  seleccionarTodo(panel) {
    this.spin = true;
    switch (panel) {
      case 'faccom': {
        for(let aux of this.documentosfaccomp){
          if (this.faccomTodo === false) {
            aux.VISTO = false;
          }
          if (this.faccomTodo === true) {
            aux.VISTO = true;
          }
        }
        this.aggrid.refreshaggrid(this.documentosfaccomp, this.defaultColDefDocfaccom.objeto);
        break;
      }
      case 'retcom': {
        for(let aux of this.documentosretcom){
          if (this.retcomTodo === false) {
            aux.VISTO = false;
          }
          if (this.retcomTodo === true) {
            aux.VISTO = true;
          }
        }
        this.aggrid.refreshaggrid(this.documentosretcom, this.defaultColDefDocretcom.objeto);
        break;
      }
      case 'nodedut': {
        for(let aux of this.documentosnodeducible){
          if (this.nodedutodo === false) {
            aux.VISTO = false;
          }
          if (this.nodedutodo === true) {
            aux.VISTO = true;
          }
        }
        this.aggrid.refreshaggrid(this.documentosnodeducible, this.defaultDefsnodeducible.objeto);
        break;
      }
    }
    this.spin = false;
  }
  
  cleardoc(){  
  } 
  procesar() {

  }
  actualizarTablas(){
    this.idbloqarch = '';
    this.bloqdisable = true;
  }
  revisaralt(){

  }
  async manejarSenahomoart(valor){
    switch (valor) {
      case 'Salir':
        
        if(this.btnGuardarhomo === true){
          this.displaycodalt= false; 
        }else{
          this.confirmationService.confirm({
            message: '¿Está seguro que desea salir, sin guardar?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            key: 'codhomo',
            acceptLabel: 'Sí',
            rejectLabel: 'No',
            accept: () => {
              this.displaycodalt= false; 
            },
            reject: () => {
            }
          })
        }
      break;
      case 'Cancelar':
        this.detfachomo = await this.ComimpdoceleService.buscahomologa();
        this.ComimpdoceleService.arreglohomo[this.indicador]=this.detfachomo;
        this.aggrid.refreshaggrid(this.ComimpdoceleService.arreglohomo[this.indicador], this.defaultDefscodalter.objeto);
      break; 
      case 'Guardar':
        try {
          this.spin=true;
          for(let aux of this.ComimpdoceleService.arreglohomo[this.indicador]){
            await this.ComimpdoceleService.updatehomologacion(aux); 
          } 
          this.btnGuardarhomo = true;
          this.messageService.add({
            key: 'codhomo',
            severity: 'success',
            summary: 'Inserción de Homologación',
            detail: 'Se actualizó exitosamente'
          });
         await this.manejarSenahomoart('Cancelar');
          this.spin=false;
        } catch (error) {
          this.messageService.add({
            key: 'codhomo',
            severity: 'error',
            summary: 'Inserción de homologación',
            detail: 'Error al actualizar homologación  '
          });
          this.spin=false;
        }
      break; 
      
      default:
      break;
    }
  }
  setFocus(event){
    let element: any;
    switch (event.target.id) {
    case 'BOD_CODIGOXML': {
      this.busquedaBodega(event.target.value, 'bodegaxml');
      document.getElementById('IDCREDITOXML').focus();
      if(this.bodcodigoxml!=='' && this.idcreditoxml!==''){
        this.actbotones2= false;
      }
      break;
    }case 'IDCREDITOXML': {
      this.busquedaidcretri(event.target.value, 'cretrixml');
      document.getElementById('DESSELECT').focus();
      if(this.bodcodigoxml!=='' && this.idcreditoxml!==''){
        this.actbotones2= false;
      }
      break;
    }
    case 'PRO_CODIGO': {
      this.busquedaproveedor(event.target.value);
      break;
    }
    }
  }
  busquedaBodega(parametro, opcionbusqueda) {
    console.log('entra---')
    if (parametro === undefined) {
      parametro = '';
    }

    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'BOD_CODIGO' },
      { label: 'Nombre', value: 'BOD_NOMBRE' }
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = '';
    this.tabla = 'inv_maebodega';
    this.where = '';

    this.encfacproService.erBodNombre1(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          if (opcionbusqueda === 'bodegaxml') {
            this.bodnombrexml = eR[0].BOD_NOMBRE;
          }
          //this.displayDialogBusquedaFast = true;
        } else {
          this.busqService.busquedaInvMaeBodega2(parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaInvMaeBodega2(parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }
  busquedaproveedor(parametro) {
    if (parametro === null) {
      parametro = '';
    }
    this.opcionbusqueda = 'proveedor';
    this.types = [
      { label: 'Código', value: 'PRO_CODIGO' },
      { label: 'Nombre', value: 'PRO_NOMBRE' },
      { label: 'Nombre Corto', value: 'PRO_NOMBREC' },
      { label: 'Grupo', value: 'GRU_CODIGO' },
      { label: 'Ruc', value: 'PRO_RUCIDE' },
      { label: 'Cuenta', value: 'CON_CODIGO1' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', ''];
    this.consulta = '';
    this.tabla = 'COM_MAEPROVEEDOR';
    this.where = '';

    this.encfacproService.erMaeProveedor(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaComMaeProveedor3(parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaComMaeProveedor3(parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }
  busquedaidcretri(parametro, opcionbusqueda) {
    const strFechaEmision = this.datePipe.transform('01/01/2022', 'dd/MM/yyyy');
    let strSentencia = '';
    strSentencia = strSentencia + ' to_date( \'' + strFechaEmision + '\' ,\'dd/mm/yyyy\')>= codsri_fechaini';
    strSentencia = strSentencia + ' and to_date( \'' + strFechaEmision + '\' ,\'dd/mm/yyyy\')<= codsri_fechafin';

    if (parametro === null) {
      parametro = '';
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref.', value: 'CODSRI_REF' },
      { label: 'Código Con.', value: 'CON_CODIGO' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', ''];
    this.consulta = '';
    this.tabla = 'ANEX_MAECODSRI';
    this.where = 'CODSRI_TIPO=\'CT\' AND ' + strSentencia;

    this.encfacproService.erCodSriDescripcion1(strFechaEmision, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.idcreditonombrexml = eR[0].CODSRI_DESCRIPCION;
        } else {
          this.busqService.busquedaAnexMaeCodSri2(strSentencia, parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaAnexMaeCodSri2(strSentencia, parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }
  descargarsri(){

  }
  async bloqarch(){
    this.bloqdisable = false;
    switch (this.indTabladatos) {
      case 1: {
        const num = await this.ComimpdoceleService.generablodnum(this.indTabladatos);
        this.idbloqarch = num[0].BLOQUE;
        break;
      }
      case 2: {
        const num = await this.ComimpdoceleService.generablodnum(this.indTabladatos);
        this.idbloqarch = num[0].BLOQUE;
        break;
      }
    }
  }
  async impinformacion(){
    if(this.bodcodigoxml === ''){
      this.messageService.add({
        key: 'impdocsri',
        severity: 'error',
        summary: 'Importar',
        detail: 'Ingrese bodega'
      });
      return;
    }
    if(this.idcreditoxml === ''){
      this.messageService.add({
        key: 'impdocsri',
        severity: 'error',
        summary: 'Importar',
        detail: 'Ingrese Crédito Tributario'
      });
      return;
    }
    if(this.PRO_CODIGO === null || this.PRO_CODIGO === undefined || this.PRO_CODIGO === ''){
      this.messageService.add({
        key: 'impdocsri',
        severity: 'error',
        summary: 'Importar',
        detail: 'Ingrese Proveedor'
      });
      return;
    }
   
    switch (this.indTabladatos) {
      case 1: {
        this.spin=true;
        console.log('<<<<<_>>>>> ',this.documentosfaccomp)
        console.log('<<<<<_>>>>> ',this.documentosupload)
        let strDatos:  any[]= [];
        //await this.ComimpdoceleService.deleteencfacpro();
        //await this.ComimpdoceleService.deletedetfacpro();
        for(const res of this.documentosfaccomp){
          if(res.VISTO === true){
            strDatos = [...strDatos,{doc_name: res.doc_name} ];
          }   
        }
        for(const res of strDatos){
            for(const res1 of this.documentosupload){
              if(res.doc_name === res1.name){
                const obj1 = await this.transformaobjeto(res1.arch);
                const version =  obj1.comprobante.match(/<\?xml version="(.+?)" encoding="(.+?)"\?>/);
                const obj2 = await this.parseXml(obj1.comprobante);
                console.log('++++++2',obj2);
                let procodigo ='';
                let pronombre ='';
                const exispro = await this.ComimpdoceleService.existenpro(obj2.infoTributaria.ruc);
                console.log('++++++existe',exispro);
                let fecha= this.datePipe.transform(new Date(), 'dd/MM/yyyy')
                if(exispro.length === 0){
                  const datapro = await this.ComimpdoceleService.existenprocod(this.PRO_CODIGO);
                  console.log('entra',datapro);
                  const nombre=obj2.infoTributaria.razonSocial;
                  const nombrec = nombre.length > 13 ? nombre.substring(0, 13) : nombre;
                  const ruc=obj2.infoTributaria.ruc;
                  const numruc=ruc.length === 13 ? "1" : ruc.length === 10 ? "2" : "";
                  try {
                    await this.ComimpdoceleService.guardarProveedor(ruc,numruc,obj2.infoTributaria.dirMatriz,nombre,nombrec,datapro[0].GRU_CODIGO,datapro[0].CON_CODIGO1,datapro[0].CON_CODIGO2, fecha)//.subscribe(() => {});
                    procodigo = ruc;
                    pronombre = nombre;

                  } catch (error) {
                    this.messageService.add({
                      key: 'impdocsri',
                      severity: 'error',
                      summary: 'Inserción de Proveedor',
                      detail: 'Error al insertar RUC '+ruc
                    });
                    this.spin=false;
                    return; 
                  }
                  
                }else{
                  procodigo = exispro[0].PRO_CODIGO;
                  pronombre = exispro[0].PRO_NOMBRE;
                }
                try {
                let comprobante = await this.readComprobante({ descarga: obj2 })
                console.log('87978797----', comprobante)
                //varables encabezado
                const item: ComEncfacpro = {};
                item.ENCFACPRO_NUMERO = '';
                item.COM_CODIGO = '01';
                item.ENCFACPRO_FECHAEMISION = comprobante.purchaseReceiptDate;
                item.ENCFACPRO_FECHAVENCIMIENTO = comprobante.purchaseReceiptDate;
                item.ENCFACPRO_FECHAREC = comprobante.purchaseReceiptDate;
                item.ENCFACPRO_IVA = '2';
                item.ENCFACPRO_ESTADO = 'P';
                item.PRO_CODIGO = procodigo;
                item.ENCFACPRO_CONTACTO = comprobante.providerSocialReason;
                item.ENCFACPRO_REFERENCIA = comprobante.sequential
                item.ENCFACPRO_TOTAL = Number(comprobante.total);
                item.ENCFACPRO_TOTALNETO = Number(comprobante.subTotalWithoutTaxes);
                item.ENCFACPRO_VALORDES = Number(comprobante.totalDiscount);
                item.ENCFACPRO_PORCEDES = 0;
                item.ENCFACPRO_VALORIVA = Number(comprobante.ivaValue);
                item.ENCFACPRO_PORCEIVA = Number(comprobante.porcent);
                item.ENCFACPRO_VALORICE = 0;
                item.ENCFACPRO_BASEIVA = Number(comprobante.subTotalIva);
                item.ENCFACPRO_BASEICE = 0;
                item.ENCFACPRO_BASECERO = Number(comprobante.subTotalZeroIva);
                item.ENCFACPRO_COMENTARIO = comprobante.comment;
                item.ENCFACPRO_OTROS = 0;
                item.ENCFACPRO_DISTCARGOS = 'N';
                item.ENCFACPRO_NUMDIASPLAZO = '';
                item.ENCFACPRO_IDCRETRI = this.idcreditoxml;
                item.ENCFACPRO_SERIE = comprobante.serie;
                item.ENCFACPRO_AUTORIZACION = comprobante.authorization;
                item.ENCFACPRO_TIPCOM = '01';
                item.MAEMOTIVO_CODIGO = '';
                item.ENCFACPRO_FECHACADFAC = comprobante.purchaseReceiptDate;
                item.ENCFACPRO_NUMINGRESO = '';
                item.ENCFACPRO_NUMLIQUIDACION = '';
                item.ENCFACPRO_TIPODES = 'V';
                item.ENCFACPRO_GASTO = '';
                item.ENCORDCOM_NUMERO = '';
                item.ENCFACPRO_NOOBJETOIVA = Number(comprobante.subTotalNoObjectIva);
                item.ENCFACPRO_BASENOOBJIVA = 0;
                item.TRNFORMAPAGO_CODIGO = '';
                item.ENCFACPRO_FLAG=10;
                this.encfacimp=item;
                console.log('/-/-/insertar enc',this.encfacimp);
                this.encfacimp.ENCFACPRO_IDCRETRI=this.idcreditoxml;
                //guarda encabezado
                const encs = await this.ComimpdoceleService.insertarEncabezadoFactPromise(this.encfacimp);
                //variables detalle
                const itemdet: ComDetfacpro  = {};
                const itemdethomo: comdathomologa  = {};
                if(Array.isArray(comprobante.details)){
                  for(let det of comprobante.details){
                    itemdet.ENCFACPRO_NUMERO = encs[0].V_ENCFACPRO_NUMERO;
                    itemdet.DETFACPRO_LINEA = 0;
                    itemdet.DETFACPRO_TIPODET = det.CODALT_TIPO;
                    itemdet.BOD_CODIGO = this.bodcodigoxml;
                    itemdet.DETFACPRO_CODIGO = det.CODALT_ITEM;
                    itemdet.DETFACPRO_DESCRIPCION = det.CODALT_DESCRIPCION;
                    itemdet.DETFACPRO_UNIDAD = det.CODALT_UNIDAD;
                    itemdet.DETFACPRO_CANTIDAD = Number(det.CODALT_CANTIDAD);
                    itemdet.DETFACPRO_ENTREGADO = 0;
                    itemdet.DETFACPRO_COSTO = Number(det.CODALT_COSTO);
                    itemdet.DETFACPRO_VALDES = Number(det.CODALT_DESC);
                    itemdet.DETFACPRO_PORDES = 0;
                    itemdet.DETFACPRO_TOTAL = Number(det.CODALT_TOTAL);
                    itemdet.DETFACPRO_PORIVA = Number(det.CODALT_IVARATE);
                    itemdet.DETFACPRO_IVA = Number(det.CODALT_IVAVAL);
                    itemdet.DETFACPRO_TRIBICE = "N";
                    itemdet.DETFACPRO_ICE = 0;
                    itemdet.DETFACPRO_PORCEICE = 0;
                    itemdet.DETFACPRO_BASEIVA = Number(det.CODALT_BASEIMPO);
                    itemdet.DETFACPRO_BASEICE = 0;
                    itemdet.DETFACPRO_BASECERO = 0;
                    itemdet.ENCORDCOM_NUMERO = null;
                    itemdet.DETORDCOM_LINEA = null;
                    itemdet.ENCNOTREC_NUMERO = null;
                    itemdet.DETNOTREC_LINEA = null;
                    itemdet.DETFACPRO_PROMOCION = null;
                    itemdet.DETFACPRO_TOTALINCP = null;
                    itemdet.DETFACPRO_PORDES2 = null;
                    itemdet.DETFACPRO_PORDES3 = null;
                    itemdet.CEN_CODIGO = det.CODALT_CENCOS;
                    itemdet.DETFACPRO_FACTOR = null;
                    itemdet.DETFACPRO_PRECIOFOB = null;
                    itemdet.DETFACPRO_ESQUEMADOC = null;
                    itemdet.DETFACPRO_TIPOSRI = null;
                    itemdet.DETFACPRO_PRECIOA = null;
                    itemdet.DETFACPRO_PRECIOB = null;
                    itemdet.DETFACPRO_PRECIOC = null;
                    itemdet.TRNSOLFAC_CODIGO = null;
                    itemdet.TRNSOLFAC_LINEA = null;
                    itemdet.ENCREQ_NUMERO = null;
                    itemdet.DETREQ_LINEA = null;
                    itemdet.ENCGRE_CODIGO = null;
                    itemdet.DETGRE_LINEA = null;
                    itemdet.DETFACPRO_BASENOOBJIVA = null;
                    itemdet.DETFACPRO_TRIBASENOOBJIVA = null;
                    itemdet.DETFACPRO_CANTIDADUND = null;
                    itemdet.TIPGAS_CODIGO= null;
                    itemdet.TARIIVA_CODIGO=det.CODALT_IVACOD.toString();

                    itemdethomo.DETFAC_CODIGO = det.CODALT_ITEM;
                    itemdethomo.DETFAC_DESCRIPCION = det.CODALT_DESCRIPCION;
                    itemdethomo.DETFAC_CODIGOTIPO ='';
                    itemdethomo.DETFAC_HOMOLOGADO = 0;
                    itemdethomo.COM_CODIGO = '01';
                    itemdethomo.DETFAC_TIPO ='';
                    itemdethomo.DETFAC_NOMBRETIPO ='';
                    itemdethomo.PRO_CODIGO = procodigo;
                    itemdethomo.PRO_NOMBRE = pronombre;

                    await this.ComimpdoceleService.insertarDetalleFacturaPromise(itemdet);
                    await this.ComimpdoceleService.insertarhomologacion(itemdethomo);
                  }
                }else{
                   const det = comprobante.details;
                    itemdet.ENCFACPRO_NUMERO = encs[0].V_ENCFACPRO_NUMERO;
                    itemdet.DETFACPRO_LINEA = 0;
                    itemdet.DETFACPRO_TIPODET = det.CODALT_TIPO;
                    itemdet.BOD_CODIGO = this.bodcodigoxml;
                    itemdet.DETFACPRO_CODIGO = det.CODALT_ITEM;
                    itemdet.DETFACPRO_DESCRIPCION = det.CODALT_DESCRIPCION;
                    itemdet.DETFACPRO_UNIDAD = det.CODALT_UNIDAD;
                    itemdet.DETFACPRO_CANTIDAD = Number(det.CODALT_CANTIDAD);
                    itemdet.DETFACPRO_ENTREGADO = 0;
                    itemdet.DETFACPRO_COSTO = Number(det.CODALT_COSTO);
                    itemdet.DETFACPRO_VALDES = Number(det.CODALT_DESC);
                    itemdet.DETFACPRO_PORDES = 0;
                    itemdet.DETFACPRO_TOTAL = Number(det.CODALT_TOTAL);
                    itemdet.DETFACPRO_PORIVA = Number(det.CODALT_IVARATE);
                    itemdet.DETFACPRO_IVA = Number(det.CODALT_IVAVAL);
                    itemdet.DETFACPRO_TRIBICE = "N";
                    itemdet.DETFACPRO_ICE = 0;
                    itemdet.DETFACPRO_PORCEICE = 0;
                    itemdet.DETFACPRO_BASEIVA = Number(det.CODALT_BASEIMPO);
                    itemdet.DETFACPRO_BASEICE = 0;
                    itemdet.DETFACPRO_BASECERO = 0;
                    itemdet.ENCORDCOM_NUMERO = null;
                    itemdet.DETORDCOM_LINEA = null;
                    itemdet.ENCNOTREC_NUMERO = null;
                    itemdet.DETNOTREC_LINEA = null;
                    itemdet.DETFACPRO_PROMOCION = null;
                    itemdet.DETFACPRO_TOTALINCP = null;
                    itemdet.DETFACPRO_PORDES2 = null;
                    itemdet.DETFACPRO_PORDES3 = null;
                    itemdet.CEN_CODIGO = det.CODALT_CENCOS;
                    itemdet.DETFACPRO_FACTOR = null;
                    itemdet.DETFACPRO_PRECIOFOB = null;
                    itemdet.DETFACPRO_ESQUEMADOC = null;
                    itemdet.DETFACPRO_TIPOSRI = null;
                    itemdet.DETFACPRO_PRECIOA = null;
                    itemdet.DETFACPRO_PRECIOB = null;
                    itemdet.DETFACPRO_PRECIOC = null;
                    itemdet.TRNSOLFAC_CODIGO = null;
                    itemdet.TRNSOLFAC_LINEA = null;
                    itemdet.ENCREQ_NUMERO = null;
                    itemdet.DETREQ_LINEA = null;
                    itemdet.ENCGRE_CODIGO = null;
                    itemdet.DETGRE_LINEA = null;
                    itemdet.DETFACPRO_BASENOOBJIVA = null;
                    itemdet.DETFACPRO_TRIBASENOOBJIVA = null;
                    itemdet.DETFACPRO_CANTIDADUND = null;
                    itemdet.TIPGAS_CODIGO= null;
                    itemdet.TARIIVA_CODIGO=det.CODALT_IVACOD.toString();
                    
                    itemdethomo.DETFAC_CODIGO = det.CODALT_ITEM;
                    itemdethomo.DETFAC_DESCRIPCION = det.CODALT_DESCRIPCION;
                    itemdethomo.DETFAC_CODIGOTIPO ='';
                    itemdethomo.DETFAC_HOMOLOGADO = 0;
                    itemdethomo.COM_CODIGO = '01';
                    itemdethomo.DETFAC_TIPO ='';
                    itemdethomo.DETFAC_NOMBRETIPO ='';
                    itemdethomo.PRO_CODIGO = procodigo;
                    itemdethomo.PRO_NOMBRE = pronombre;
                    await this.ComimpdoceleService.insertarDetalleFacturaPromise(itemdet);
                    await this.ComimpdoceleService.insertarhomologacion(itemdethomo);
                }
                this.messageService.add({
                  key: 'impdocsri',
                  severity: 'success',
                  summary: 'Inserción de Factura temporal',
                  detail: 'Ingreso a temporales exitoso, porfavor homolege articulos '
                });
                } catch (error) {
                  this.messageService.add({
                    key: 'impdocsri',
                    severity: 'error',
                    summary: 'Inserción de Factura temporal',
                    detail: 'Error al insertar RUC '+obj2.infoTributaria.ruc
                  });
                  this.spin=false;
                  return;
                }
                //this.spin=false;
              }
            }
          }  
          this.spin=false;
          //this.botonVisto = false;
          const nodedu = await this.ComimpdoceleService.buscnodedu(); 
          this.documentosnodeducible=nodedu;
          await this.onBasicUpload(this.filesini);
          this.aggrid.refreshaggrid(this.documentosnodeducible, this.defaultDefsnodeducible.objeto);
          this.aggrid.refreshaggrid(this.documentosfaccomp, this.defaultColDefDocfaccom.objeto);
          this.aggrid.refreshaggrid(this.documentosnodeducible, this.defaultDefsnodeducible.objeto);
          this.faccomTodo = false;
          this.retcomTodo = false;
          this.nodedutodo = false;
        break;
      }
      case 2: {
        this.spin=true;
        console.log('<<<<<_>>>>> ',this.indTabladatos)
        console.log('<<<<<_>>>>> ',this.documentosretcom)
        let strDatos:  any[]= [];
        await this.ComimpdoceleService.deleteret();
        for(const res of this.documentosretcom){
          if(res.VISTO === true){
            strDatos = [...strDatos,{doc_name: res.doc_name} ];
          }   
        }
        for(const res of strDatos){
            for(const res1 of this.documentosupload){
              if(res.doc_name === res1.name){
                const obj1 = await this.transformaobjeto(res1.arch);
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(obj1.comprobante, 'text/xml');
                const version = xmlDoc.documentElement.getAttribute("version")
                console.log('++++++2',version);
                const obj2 = await this.parseXml(obj1.comprobante);
                console.log('++++++2',obj2);
                //let procodigo ='';
                
                const item: Cxctrnretencion = {};
                  if( version === '1.0.0' ){
                    console.log('entra --',version);
                    if(Array.isArray(obj2.impuestos.impuesto)){
                      for(let det of obj2.impuestos.impuesto){
                        let data;
                        let data2;
                        let data3;
                        try {
                          const dat =await this.ComimpdoceleService.selectfac(det.numDocSustento.substring(0, 6),det.numDocSustento.substring(6, 15)); 
                          console.log('++++++66',dat);
                          if(dat.length === 0){
                            this.messageService.add({
                              key: 'impdocsri',
                              severity: 'error',
                              summary: 'Retención',
                              detail: 'La Factura '+det.numDocSustento+' no esta creada, de la retención '+obj2.infoTributaria.secuencial
                            });
                            this.spin=false;
                            return;
                          }
                         data =await this.cxctrnretencionesService.encnomretencion(det.codigoRetencion);
                         data2=await this.cxctrnretencionesService.encconcodigoretencion(det.codigoRetencion);
                         data3=await this.cxctrnretencionesService.enccodigoretencion(det.codigoRetencion);
                        if(data3 !== null && data3!== undefined){
                            if(data3[0] !== null && data3[0] !== undefined && data3[0] !== ''){
                                if(data3[0].RETENCION_CODIGO !== null && data3[0].RETENCION_CODIGO !== undefined && data3[0].RETENCION_CODIGO !== ''){
                                  item.RETENCION_CODIGO= data3[0].RETENCION_CODIGO
                                    item.TRNRETENCION_DESCRIPCION= data[0].RETENCION_NOMBRE
                                  }
                                  if(data2.length !== 0 ){
                                    item.CON_CODIGO= data2[0].CON_CODIGO_VTA
                                  }
                              }else{
                              this.messageService.add({
                                  key: 'impdocsri',
                                  severity: 'error',
                                  summary: 'Información',
                                  detail: 'No existe el CodSRi.'+det.codigoRetencion+' Dentro del maestro de retenciones'
                                });
                              }
                        }else{
                            this.messageService.add({
                               key: 'impdocsri',
                               severity: 'error',
                               summary: 'Información',
                               detail: 'No existe el CodSRi.'+det.codigoRetencion+' Dentro del maestro de retenciones'
                             });
                        }
                        
                        item.TRNRETENCION_BASE = det.baseImponible;
                        item.TRNRETENCION_PORCENTAJE = det.porcentajeRetener;
                        item.TRNRETENCION_TOTALRETENIDO = det.valorRetenido;
                        item.ENCFAC_NUMERO = det.numDocSustento;
                        item.CLI_CODIGO=obj2.infoTributaria.ruc;
                        item.TRNRETENCION_NRO = obj2.infoTributaria.estab + obj2.infoTributaria.ptoEmi + obj2.infoTributaria.secuencial,
                        item.TRNRETENCION_ORIGEN = 'CXC';
                        item.TRNRETENCION_FLAG = '10';
                        item.TRNRETENCION_NROAUTORIZACION =obj2.infoTributaria.claveAcceso;
                        item.TRNRETENCION_FECHAEMISION = obj2.infoCompRetencion.fechaEmision;
                        console.log('3,',item)
                        
                          await this.ComimpdoceleService.insertarRetencionP(item);
                        } catch (error) {
                          this.messageService.add({
                            key: 'impdocsri',
                            severity: 'error',
                            summary: 'Inserción de Retención',
                            detail: 'Error al insertar Retención '+data3[0].RETENCION_CODIGO+' del RUC: '+ obj2.infoTributaria.ruc
                          });
                          this.spin=false;
                          return;
                        }
                        
                      }
                    }else{
                      let data;
                      let data2;
                      let data3;
                      try {
                        const dat =await this.ComimpdoceleService.selectfac(obj2.impuestos.impuesto.numDocSustento.substring(0, 6),obj2.impuestos.impuesto.numDocSustento.substring(6, 15)); 
                        console.log('++++++66',dat);
                        if(dat.length === 0){
                          this.messageService.add({
                            key: 'impdocsri',
                            severity: 'error',
                            summary: 'Retención',
                            detail: 'La Factura '+obj2.impuestos.impuesto.numDocSustento+' no esta creada, de la retención '+obj2.infoTributaria.secuencial
                          });
                          this.spin=false;
                          return;
                        }
                       data =await this.cxctrnretencionesService.encnomretencion(obj2.impuestos.impuesto.codigoRetencion);
                       data2=await this.cxctrnretencionesService.encconcodigoretencion(obj2.impuestos.impuesto.codigoRetencion);
                       data3=await this.cxctrnretencionesService.enccodigoretencion(obj2.impuestos.impuesto.codigoRetencion);
                        if(data3 !== null && data3!== undefined){
                            if(data3[0] !== null && data3[0] !== undefined && data3[0] !== ''){
                                if(data3[0].RETENCION_CODIGO !== null && data3[0].RETENCION_CODIGO !== undefined && data3[0].RETENCION_CODIGO !== ''){
                                  item.RETENCION_CODIGO= data3[0].RETENCION_CODIGO
                                    item.TRNRETENCION_DESCRIPCION= data[0].RETENCION_NOMBRE
                                  }
                                  if(data2.length !== 0 ){
                                    item.CON_CODIGO= data2[0].CON_CODIGO_VTA
                                  }
                              }else{
                              this.messageService.add({
                                  key: 'impdocsri',
                                  severity: 'error',
                                  summary: 'Información',
                                  detail: 'No existe el CodSRi.'+obj2.impuestos.impuesto.codigoRetencion+' Dentro del maestro de retenciones'
                                });
                              }
                        }else{
                            this.messageService.add({
                               key: 'impdocsri',
                               severity: 'error',
                               summary: 'Información',
                               detail: 'No existe el CodSRi.'+obj2.impuestos.impuesto.codigoRetencion+' Dentro del maestro de retenciones'
                             });
                        }
                        
                        item.TRNRETENCION_BASE = obj2.impuestos.impuesto.baseImponible;
                        item.TRNRETENCION_PORCENTAJE = obj2.impuestos.impuesto.porcentajeRetener;
                        item.TRNRETENCION_TOTALRETENIDO = obj2.impuestos.impuesto.valorRetenido;
                        item.ENCFAC_NUMERO = obj2.impuestos.impuesto.numDocSustento;
                        item.CLI_CODIGO=obj2.infoTributaria.ruc;
                        item.TRNRETENCION_NRO = obj2.infoTributaria.estab + obj2.infoTributaria.ptoEmi + obj2.infoTributaria.secuencial,
                        item.TRNRETENCION_ORIGEN = 'CXC';
                        item.TRNRETENCION_FLAG = '10';
                        item.TRNRETENCION_NROAUTORIZACION =obj2.infoTributaria.claveAcceso;
                        item.TRNRETENCION_FECHAEMISION = obj2.infoCompRetencion.fechaEmision;
                        console.log('3,',item)
                        
                          await this.ComimpdoceleService.insertarRetencionP(item);
                        } catch (error) {
                          this.messageService.add({
                            key: 'impdocsri',
                            severity: 'error',
                            summary: 'Inserción de Retención',
                            detail: 'Error al insertar Retención '+data3[0].RETENCION_CODIGO+' del RUC: '+ obj2.infoTributaria.ruc
                          });
                          this.spin=false;
                          return;
                        }
                        
                    }
                  }else if( version === '2.0.0' ){
                    console.log('entra --',version);
                    if(Array.isArray(obj2.docsSustento.docSustento.retenciones.retencion)){
                      for(let det of obj2.docsSustento.docSustento.retenciones.retencion){
                        let data;
                        let data2;
                        let data3;
                        try {
                          const dat =await this.ComimpdoceleService.selectfac(obj2.docsSustento.docSustento.numDocSustento.substring(0, 6),obj2.docsSustento.docSustento.numDocSustento.substring(6, 15)); 
                          console.log('++++++66',dat);
                          if(dat.length === 0){
                            this.messageService.add({
                              key: 'impdocsri',
                              severity: 'error',
                              summary: 'Retención',
                              detail: 'La Factura '+obj2.docsSustento.docSustento.numDocSustento+' no esta creada, de la retención '+obj2.infoTributaria.secuencial
                            });
                            this.spin=false;
                            return;
                          }
                         data =await this.cxctrnretencionesService.encnomretencion(det.codigoRetencion);
                         data2=await this.cxctrnretencionesService.encconcodigoretencion(det.codigoRetencion);
                         data3=await this.cxctrnretencionesService.enccodigoretencion(det.codigoRetencion);
                        if(data3 !== null && data3!== undefined){
                            if(data3[0] !== null && data3[0] !== undefined && data3[0] !== ''){
                                if(data3[0].RETENCION_CODIGO !== null && data3[0].RETENCION_CODIGO !== undefined && data3[0].RETENCION_CODIGO !== ''){
                                  item.RETENCION_CODIGO= data3[0].RETENCION_CODIGO
                                    item.TRNRETENCION_DESCRIPCION= data[0].RETENCION_NOMBRE
                                  }
                                  if(data2.length !== 0 ){
                                    item.CON_CODIGO= data2[0].CON_CODIGO_VTA
                                  }
                              }else{
                              this.messageService.add({
                                  key: 'impdocsri',
                                  severity: 'error',
                                  summary: 'Información',
                                  detail: 'No existe el CodSRi.'+det.codigoRetencion+' Dentro del maestro de retenciones'
                                });
                              }
                        }else{
                            this.messageService.add({
                               key: 'impdocsri',
                               severity: 'error',
                               summary: 'Información',
                               detail: 'No existe el CodSRi.'+det.codigoRetencion+' Dentro del maestro de retenciones'
                             });
                        }
                        
                        item.TRNRETENCION_BASE = det.baseImponible;
                        item.TRNRETENCION_PORCENTAJE = det.porcentajeRetener;
                        item.TRNRETENCION_TOTALRETENIDO = det.valorRetenido;
                        item.ENCFAC_NUMERO = obj2.docsSustento.docSustento.numDocSustento;
                        item.CLI_CODIGO= obj2.infoTributaria.ruc;
                        item.TRNRETENCION_NRO = obj2.infoTributaria.estab + obj2.infoTributaria.ptoEmi + obj2.infoTributaria.secuencial,
                        item.TRNRETENCION_ORIGEN = 'CXC';
                        item.TRNRETENCION_FLAG = '10';
                        item.TRNRETENCION_NROAUTORIZACION =obj2.infoTributaria.claveAcceso;
                        item.TRNRETENCION_FECHAEMISION = obj2.infoCompRetencion.fechaEmision;
                        console.log('3,',item)
                        
                          await this.ComimpdoceleService.insertarRetencionP(item);
                        } catch (error) {
                          this.messageService.add({
                            key: 'impdocsri',
                            severity: 'error',
                            summary: 'Inserción de Retención',
                            detail: 'Error al insertar Retención '+data3[0].RETENCION_CODIGO+' del RUC: '+ obj2.infoTributaria.ruc
                          });
                          this.spin=false;
                          return;
                        }
                        
                      }
                    }else{
                      let data;
                      let data2;
                      let data3;
                      try {
                        const dat =await this.ComimpdoceleService.selectfac(obj2.docsSustento.docSustento.numDocSustento.substring(0, 6),obj2.docsSustento.docSustento.numDocSustento.substring(6, 15)); 
                        console.log('++++++66',dat);
                        if(dat.length === 0){
                          this.messageService.add({
                            key: 'impdocsri',
                            severity: 'error',
                            summary: 'Retención',
                            detail: 'La Factura '+obj2.docsSustento.docSustento.numDocSustento+' no esta creada, de la retención '+obj2.infoTributaria.secuencial
                          });
                          this.spin=false;
                          return;
                        }
                       data =await this.cxctrnretencionesService.encnomretencion(obj2.docsSustento.docSustento.retenciones.retencion.codigoRetencion);
                       data2=await this.cxctrnretencionesService.encconcodigoretencion(obj2.docsSustento.docSustento.retenciones.retencion.codigoRetencion);
                       data3=await this.cxctrnretencionesService.enccodigoretencion(obj2.docsSustento.docSustento.retenciones.retencion.codigoRetencion);
                        if(data3 !== null && data3!== undefined){
                            if(data3[0] !== null && data3[0] !== undefined && data3[0] !== ''){
                                if(data3[0].RETENCION_CODIGO !== null && data3[0].RETENCION_CODIGO !== undefined && data3[0].RETENCION_CODIGO !== ''){
                                  item.RETENCION_CODIGO= data3[0].RETENCION_CODIGO
                                    item.TRNRETENCION_DESCRIPCION= data[0].RETENCION_NOMBRE
                                  }
                                  if(data2.length !== 0 ){
                                    item.CON_CODIGO= data2[0].CON_CODIGO_VTA
                                  }
                              }else{
                              this.messageService.add({
                                  key: 'impdocsri',
                                  severity: 'error',
                                  summary: 'Información',
                                  detail: 'No existe el CodSRi.'+obj2.docsSustento.docSustento.retenciones.retencion.codigoRetencion+' Dentro del maestro de retenciones'
                                });
                              }
                        }else{
                            this.messageService.add({
                               key: 'impdocsri',
                               severity: 'error',
                               summary: 'Información',
                               detail: 'No existe el CodSRi.'+obj2.docsSustento.docSustento.retenciones.retencion.codigoRetencion+' Dentro del maestro de retenciones'
                             });
                        }
                        
                        item.TRNRETENCION_BASE = obj2.docsSustento.docSustento.retenciones.retencion.baseImponible;
                        item.TRNRETENCION_PORCENTAJE = obj2.docsSustento.docSustento.retenciones.retencion.porcentajeRetener;
                        item.TRNRETENCION_TOTALRETENIDO = obj2.docsSustento.docSustento.retenciones.retencion.valorRetenido;
                        item.ENCFAC_NUMERO = obj2.docsSustento.docSustento.numDocSustento;
                        item.CLI_CODIGO=obj2.infoTributaria.ruc;
                        item.TRNRETENCION_NRO = obj2.infoTributaria.estab + obj2.infoTributaria.ptoEmi + obj2.infoTributaria.secuencial,
                        item.TRNRETENCION_ORIGEN = 'CXC';
                        item.TRNRETENCION_FLAG = '10';
                        item.TRNRETENCION_NROAUTORIZACION =obj2.infoTributaria.claveAcceso;
                        item.TRNRETENCION_FECHAEMISION = obj2.infoCompRetencion.fechaEmision;
                        console.log('3,',item)
                        
                          await this.ComimpdoceleService.insertarRetencionP(item);
                        } catch (error) {
                          this.messageService.add({
                            key: 'impdocsri',
                            severity: 'error',
                            summary: 'Inserción de Retención',
                            detail: 'Error al insertar Retención '+data3[0].RETENCION_CODIGO+' del RUC: '+ obj2.infoTributaria.ruc
                          });
                          this.spin=false;
                          return;
                        }
                        
                    }
                  }
              }
            }
          } 
          this.messageService.add({
            key: 'impdocsri',
            severity: 'success',
            summary: 'Inserción de Retención temporal',
            detail: 'Ingreso a temporales exitoso.'
          });
          this.spin=false;
          this.botonVisto = false;
        break;
      }
    }
  }
  seleccionFil() {
    console.log('<<<<<_>>>>> ',this.documentos)
    console.log('<<<<<_>>>>> ',this.documentosfaccomp)
    console.log('<<<<<_>>>>> ',this.documentosretcom)
  }
  async manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'bodegaxml') {
      this.bodcodigoxml = opcion.BOD_CODIGO;
      this.bodnombrexml = opcion.BOD_NOMBRE;
      document.getElementById('IDCREDITOXML').focus();
      this.displayDialogBusquedaFast = false;
    }
    if (this.opcionbusqueda === 'cretrixml') {
      this.idcreditoxml = opcion.CODSRI_CODIGO;
      this.idcreditonombrexml = opcion.CODSRI_DESCRIPCION;
      this.displayDialogBusquedaFast = false;
      document.getElementById('PRO_CODIGO').focus();
      
    }
    if (this.opcionbusqueda === 'proveedor') {
      
      this.PRO_CODIGO = opcion.PRO_CODIGO;
      this.PRO_NOMBRE = opcion.PRO_NOMBRE;
      this.displayDialogBusquedaFast = false;
     
    }
  } 
  async onBasicUpload(event){ 
    this.spin = true;
    this.filesini = event;
    const rucemp =  await this.ComimpdoceleService.conruccompania(); 
    console.log('----',event); 
    console.log('----666666',rucemp[0].COMRUCI); 
    this.RUC = rucemp[0].COMRUCI;
    //this.RUC = '1708190911001';
    let exisDatos:  comdatosimp[]= [];
    let strDatos:  any[]= [];
    let exisDatosfaccom:  comdatos[]= [];
    let exisDatosretcom:  comdatos[]= [];
    let numnoleido=0;
    let numvacio=0;
    let numxml=0;
    for(const res of event){
      if(res.type === 'text/xml'){
        const textxml = await this.leerarch(res);
        //console.log('+++++1',textxml);
        strDatos = [...strDatos,{name: res.name, arch: textxml} ];
        numxml=numxml+1;
      } 
    }
    console.log('----2',numxml); 
    if(numxml === 0){
      this.messageService.add({
        key: 'impdocsri',
        severity: 'error',
        summary: 'Lectura de archivos',
        detail: 'No existe ningún archivo xml'
      });
      this.spin = false;
      return
    }
    this.documentosupload=strDatos;
    for(const res of strDatos){
      console.log('+++++9',res);
      const obj1 = await this.transformaobjeto(res.arch);
      console.log('+++++91',obj1);
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(obj1.comprobante, 'text/xml');
      const version = xmlDoc.documentElement.getAttribute("version")
      console.log('++++++1',version);
      //const version =  obj1.comprobante.match(/<\?xml version="(.+?)" encoding="(.+?)"\?> <\?comprobanteRetencion id="(.+?)" version="(.+?)"\?>/);
      const obj2 = await this.parseXml(obj1.comprobante);
      console.log('++++++2',obj2);
      let date='';
       let orig='';
       let ruc_doc='';
       let nom='';
       let clave='';
       let Tipo='';
       let secuencial='';
       let docok='';
       if(version !== null && version !== undefined && version!== ''){
      if (obj2.infoTributaria.codDoc === '01' && obj2.infoTributaria.ruc === this.RUC) {
        clave = obj2.infoTributaria.claveAcceso;
        date = obj2.infoFactura.fechaEmision;
        orig = 'V';
        ruc_doc= obj2.infoFactura.identificacionComprador;
        nom=obj2.infoFactura.razonSocialComprador;
        Tipo= obj2.infoTributaria.codDoc;
        secuencial= obj2.infoTributaria.secuencial;
      } else if(obj2.infoTributaria.codDoc === '03' && obj2.infoTributaria.ruc === this.RUC ){
        clave= obj2.infoTributaria.claveAcceso;
        date = obj2.infoLiquidacionCompra.fechaEmision;
        orig = 'V';
        ruc_doc= obj2.infoLiquidacionCompra.identificacionProveedor;
        nom=obj2.infoLiquidacionCompra.razonSocialProveedor;
        Tipo= obj2.infoTributaria.codDoc;
        secuencial= obj2.infoTributaria.secuencial;
      } else if(obj2.infoTributaria.codDoc === '04' && obj2.infoTributaria.ruc === this.RUC){
        clave=obj2.infoTributaria.claveAcceso;
        date = obj2.infoNotaCredito.fechaEmision
        orig = 'V';
        ruc_doc= obj2.infoNotaCredito.identificacionComprador;
        nom=obj2.infoNotaCredito.razonSocialComprador;
        Tipo= obj2.infoTributaria.codDoc;
        secuencial= obj2.infoTributaria.secuencial;
      }else if(obj2.infoTributaria.codDoc === '05' && obj2.infoTributaria.ruc === this.RUC ){
        clave=obj2.infoTributaria.claveAcceso;
        date = obj2.infoNotaDebito.fechaEmision
        orig = 'V';
        ruc_doc= obj2.infoNotaDebito.identificacionComprador;
        nom=obj2.infoNotaDebito.razonSocialComprador;
        Tipo= obj2.infoTributaria.codDoc;
        secuencial= obj2.infoTributaria.secuencial;
      }else if(obj2.infoTributaria.codDoc === '06' && obj2.infoTributaria.ruc === this.RUC){
        clave=obj2.infoTributaria.claveAcceso;
        date = obj2.infoGuiaRemision.fechaIniTransporte
        orig = 'V';
        ruc_doc= obj2.infoTributaria.ruc ;
        nom=obj2.infoTributaria.razonSocial;
        Tipo= obj2.infoTributaria.codDoc;
        secuencial= obj2.infoTributaria.secuencial;
      }else if(obj2.infoTributaria.codDoc === '07' && obj2.infoTributaria.ruc === this.RUC){
        clave=obj2.infoTributaria.claveAcceso;
        date = obj2.infoCompRetencion.fechaEmision
        orig = 'C';
        ruc_doc= obj2.infoCompRetencion.identificacionSujetoRetenido;
        nom='';
        Tipo= obj2.infoTributaria.codDoc;
        secuencial= obj2.infoTributaria.secuencial;
      } 
        //////// recibidos /////////
      else if(obj2.infoTributaria.codDoc === '01' && obj2.infoFactura.identificacionComprador === this.RUC ){
        clave=obj2.infoTributaria.claveAcceso;
        date = obj2.infoFactura.fechaEmision;
        orig = 'C';
        ruc_doc= obj2.infoTributaria.ruc;
        nom=obj2.infoTributaria.razonSocial;
        Tipo= obj2.infoTributaria.codDoc;
        secuencial= obj2.infoTributaria.secuencial;
        console.log('**clave***',clave )
        const exitfacpro = await this.ComimpdoceleService.buscfacpro(clave);  
        console.log('*****',exitfacpro )
        if(exitfacpro.length !== 0){
          docok ='ARCHIVO YA PROCESADO'
        }else{
          const nodedu = await this.ComimpdoceleService.buscnodeduOK(clave);  
          console.log('*****',nodedu )
          if(nodedu.length === 0){
            exisDatosfaccom = [...exisDatosfaccom, 
              { VISTO: false,
                doc_name: res.name,
                doc_auth:clave,
                doc_date:date,
                doc_tipo:Tipo,
                doc_orig:orig,
                doc_doc:secuencial,
                doc_RUC:ruc_doc ,
                doc_nomcom:nom,
              }];
          }else{
            if(nodedu[0].TRNCLAVEDOC_ESTADO ==='OK'){
              docok ='ARCHIVO YA PROCESADO'
            }else{
              docok ='NO DEDUCIBLE'
            }
          }
        }
        
      }else if(obj2.infoTributaria.codDoc === '03' && obj2.infoLiquidacionCompra.identificacionProveedor === this.RUC ){
        clave = obj2.infoTributaria.claveAcceso;
        date = obj2.infoLiquidacionCompra.fechaEmision;
        orig = 'C';
        ruc_doc = obj2.infoTributaria.ruc;
        nom = obj2.infoTributaria.razonSocial;
        Tipo= obj2.infoTributaria.codDoc;
        secuencial= obj2.infoTributaria.secuencial;
      } else if(obj2.infoTributaria.codDoc === '04' && obj2.infoNotaCredito.identificacionComprador === this.RUC){
        clave = obj2.infoTributaria.claveAcceso;
        date = obj2.infoNotaCredito.fechaEmision
        orig = 'C';
        ruc_doc= obj2.infoTributaria.ruc;
        nom=obj2.infoTributaria.razonSocial;
        Tipo= obj2.infoTributaria.codDoc;
        secuencial= obj2.infoTributaria.secuencial;
      }else if(obj2.infoTributaria.codDoc === '05' && obj2.infoNotaDebito.identificacionComprador === this.RUC ){
        clave=obj2.infoTributaria.claveAcceso;
        date = obj2.infoNotaDebito.fechaEmision
        orig = 'C';
        ruc_doc= obj2.infoTributaria.ruc;
        nom=obj2.infoTributaria.razonSocial;
        Tipo= obj2.infoTributaria.codDoc;
        secuencial= obj2.infoTributaria.secuencial;
      }else if(obj2.infoTributaria.codDoc === '07' && obj2.infoCompRetencion.identificacionSujetoRetenido === this.RUC){
        
        
          clave=obj2.infoTributaria.claveAcceso;
          date = obj2.infoCompRetencion.fechaEmision
          orig = 'V';
          ruc_doc=  obj2.infoTributaria.ruc;
          nom = obj2.infoTributaria.razonSocial;
          Tipo = obj2.infoTributaria.codDoc;
          secuencial = obj2.infoTributaria.secuencial;
          const nodedu = await this.ComimpdoceleService.buscnodeduOK(clave); 
          console.log('*****',nodedu )
          if(nodedu.length === 0){
          exisDatosretcom = [...exisDatosretcom, 
            { VISTO: false,
              doc_name: res.name,
              doc_auth:clave,
              doc_date:date,
              doc_tipo:Tipo,
              doc_orig:orig,
              doc_doc:secuencial,
              doc_RUC:ruc_doc ,
              doc_nomcom:nom,
            }];
          }else{
            if(nodedu[0].TRNCLAVEDOC_ESTADO ==='OK'){
              docok ='ARCHIVO YA PROCESADO'
            }else{
              docok ='NO DEDUCIBLE'
            }
           }
      }
      if(clave===''){
        clave='ARCHIVO NO LEIDO'
        numnoleido=numnoleido+1
      }
      if( docok === 'ARCHIVO YA PROCESADO'){
        clave=docok;
      }
      if( docok === 'NO DEDUCIBLE'){
        clave=docok;
      }
      exisDatos = [...exisDatos, 
        {doc_name: res.name,
          doc_auth:clave,
          doc_date:date,
          doc_tipo:Tipo,
          doc_orig:orig,
          doc_doc:secuencial,
          doc_RUC:ruc_doc ,
          doc_nomcom:nom,
        }];
      }else{
        numvacio=numvacio+1
      }
    }
    this.numarchivos=Number(strDatos.length);
    this.numleidos= Number(this.numarchivos)-Number(numnoleido)-Number(numvacio);
    this.documentosretcom=exisDatosretcom; 
    this.documentosfaccomp=exisDatosfaccom;
    this.documentos=exisDatos;
    this.spin = false;
    this.botonArchivo = false;

  }
  leerarch(file){
    return new Promise((resolve,reject)=>{
      const reader = new FileReader()
      
      reader.onload = function (e){
        resolve(e.target.result) ;
      }
      reader.onerror = function(err){
        reject(err)
      }
      reader.readAsText(file,'UTF-8')
    })
    
  }

  transformaobjeto(stingtxt){
    const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(stingtxt, 'text/xml');

        // Verifica si el documento XML se analizó correctamente
        if (xmlDoc.documentElement.nodeName === 'parsererror') {
          throw new Error('Error al analizar el XML');
        }

        // Luego, puedes recorrer el documento XML y construir un objeto JavaScript
        const xmlObject: any = {};
        const rootElement = xmlDoc.documentElement;

        for (let i = 0; i < rootElement.children.length; i++) {
          const element = rootElement.children[i];
          xmlObject[element.nodeName] = element.textContent;
        }
        return xmlObject;
  }

   parseXml(xmlString: string): any {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, 'text/xml');
  
    if (xmlDoc.documentElement.nodeName === 'parsererror') {
      throw new Error('Error al analizar el XML');
    }
  
     function parseElement(element) {
    const elementObject = {};
  
    // Manejar los atributos del elemento
    if (element.attributes.length > 0) {
      elementObject['attributes'] = {};
      for (let i = 0; i < element.attributes.length; i++) {
        const attribute = element.attributes[i];
        elementObject['attributes'][attribute.name] = attribute.value;
      }
    }
  
    // Manejar el contenido de texto del elemento
    if (element.childNodes.length === 1 && element.childNodes[0].nodeType === Node.TEXT_NODE) {
      // Si el elemento tiene solo un nodo de texto como contenido, regresar ese valor.
      return element.childNodes[0].textContent;
    }
  
    // Manejar los hijos del elemento
    for (let i = 0; i < element.children.length; i++) {
      const child = element.children[i];
      const childValue = parseElement(child);
  
      if (elementObject[child.nodeName]) {
        if (!Array.isArray(elementObject[child.nodeName])) {
          elementObject[child.nodeName] = [elementObject[child.nodeName]];
        }
        elementObject[child.nodeName].push(childValue);
      } else {
        elementObject[child.nodeName] = childValue;
      }
    }
  
    return elementObject;
  }
  
  
    const rootElement = xmlDoc.documentElement;
    const result: any = {};
  
    for (let i = 0; i < rootElement.children.length; i++) {
      const child = rootElement.children[i];
      if (result[child.nodeName]) {
        if (!Array.isArray(result[child.nodeName])) {
          result[child.nodeName] = [result[child.nodeName]];
        }
        result[child.nodeName].push(parseElement(child));
      } else {
        result[child.nodeName] = parseElement(child);
      }
    }
  
    return result;
  }
 async guardapro(parameter){
     this.spin = true
     try {
      await this.ComimpdoceleService.cambiarpro(parameter);
      this.spin = false
      this.messageService.add({
        key: 'impdocsri',
        severity: 'success',
        summary: 'Inserción de Proveedor',
        detail: 'Se ha insertado el proveedor'
      });
     } catch (error) {
      this.messageService.add({
        key: 'impdocsri',
        severity: 'error',
        summary: 'Inserción de Proveedor',
        detail: error
      });
      this.spin = false
     }
      
  }
  importarsri(){

  }
  ///////// lectura xml /////////
  readComprobante({ descarga }) {
    try {
      const {
         infoTributaria, infoFactura, detalles 
      } = descarga
      const total = this.getTotals({ totalConImpuestos: infoFactura.totalConImpuestos })
      const details = this.getDetails({ detalles })
      const header = {
        providerIdentification: infoTributaria.ruc,
        providerSocialReason: infoTributaria.razonSocial,
        providerIdentificationType: `ruc`,
        address: infoTributaria.dirMatriz,
        authorization: infoTributaria.claveAcceso,
        serie: infoTributaria.estab + infoTributaria.ptoEmi,
        sequential: infoTributaria.secuencial,
        //purchaseReceiptDate: this.datePipe.transform(infoFactura.fechaEmision, 'dd/MM/yyyy'),
        purchaseReceiptDate: infoFactura.fechaEmision,
        emissionDate: this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss'),
        subTotalWithoutTaxes: parseFloat(infoFactura.totalSinImpuestos),
        totalDiscount: parseFloat(infoFactura.totalDescuento),
        total: parseFloat(infoFactura.importeTotal),
        enumeration:
          infoTributaria.estab + infoTributaria.ptoEmi + infoTributaria.secuencial,
        ...total,
        voucherType: `01`,
        comment: `Importado`,
        details,
      }
      console.log('------CABECERA------', header)
      return header
    } catch (error) {
      console.error(error)
      return null
    }
  }
  
   getTotals({ totalConImpuestos }) {
    const totals = {
      subTotalIva: 0,
      subTotalZeroIva: 0,
      subTotalNoObjectIva: 0,
      subTotalNoExemptIva: 0,
      iceValue: 0,
      ivaValue: 0,
      porcent: 0,
    }
    const totalImpuesto = totalConImpuestos.totalImpuesto
    if(Array.isArray(totalImpuesto)){
      totalImpuesto.forEach((impuesto) => {
        const { codigoPorcentaje, baseImponible, valor, codigo } = impuesto
        if (codigo === `3`) { //ICE
          totals.iceValue += parseFloat(valor)
        }
        switch (codigoPorcentaje) {
          case `2`: ///iva 12
            totals.subTotalIva += parseFloat(baseImponible)
            totals.ivaValue += parseFloat(valor)
            totals.porcent = 12;
            break
          case `0`:/// 0 de iv
            totals.subTotalZeroIva += parseFloat(baseImponible)
            totals.porcent = 0;
            break 
          case `6`:///// NO OBJETO DE IVA
            totals.subTotalNoObjectIva += parseFloat(baseImponible)
            totals.porcent = 0;
            break
          case `7`:////
            totals.subTotalNoExemptIva += parseFloat(baseImponible)
            totals.porcent = 0;
            break
          case `8`:////
            totals.subTotalNoExemptIva += parseFloat(baseImponible)
            totals.porcent = 8;
            break
          case `3`:////
            totals.subTotalNoExemptIva += parseFloat(baseImponible)
            totals.porcent = 14;
            break
          default:
            break
        }
      })
    }else{
        const { codigoPorcentaje, baseImponible, valor, codigo } = totalImpuesto
        if (codigo === `3`) { //ICE
          totals.iceValue += parseFloat(valor)
        }
        switch (codigoPorcentaje) {
          case `2`: ///iva 12
            totals.subTotalIva += parseFloat(baseImponible)
            totals.ivaValue += parseFloat(valor)
            totals.porcent = 12;
            break
          case `0`:/// 0 de iv
            totals.subTotalZeroIva += parseFloat(baseImponible)
            totals.porcent = 0;
            break 
          case `6`:///// NO OBJETO DE IVA
            totals.subTotalNoObjectIva += parseFloat(baseImponible)
            totals.porcent = 0;
            break
          case `7`:////
            totals.subTotalNoExemptIva += parseFloat(baseImponible)
            totals.porcent = 0;
            break
          case `8`:////
            totals.subTotalNoExemptIva += parseFloat(baseImponible)
            totals.porcent = 8;
            break
          case `3`:////
            totals.subTotalNoExemptIva += parseFloat(baseImponible)
            totals.porcent = 14;
            break
          default:
            break
        }
    }
    console.log('------TOTALES------', totals)
    return totals
  }
   getDetails({ detalles }) {
    try {
      //console.log(JSON.stringify(detalles, null, 2))
      //generate a code random number
      const details = detalles.detalle
      let detailsParsed;
      if(Array.isArray(details)){
         detailsParsed = details.map((detail) => {
          const {
            codigoPrincipal,
            //codigoAuxiliar,
            descripcion,
            cantidad,
            precioUnitario,
            descuento,
            precioTotalSinImpuesto,
            impuestos,
          } = detail
          const { baseImponible, valor, codigoPorcentaje } = impuestos.impuesto
          return {
            CODALT_ITEM: codigoPrincipal ? codigoPrincipal : `NOCODE`,
            CODALT_TIPO:'*',
            CODALT_CODIGO:'',
            CODALT_DESCRIPCION: descripcion,
            CODALT_UNIDAD:'',
            CODALT_CANTIDAD: parseFloat(cantidad),
            CODALT_COSTO: parseFloat(precioUnitario),
            CODALT_DESC: parseFloat(descuento),
            CODALT_TOTAL: parseFloat(precioTotalSinImpuesto),
            CODALT_IVAVAL: parseFloat(valor),
            CODALT_BASEIMPO: parseFloat(baseImponible),
            CODALT_IVACOD: parseFloat(codigoPorcentaje),
            CODALT_IVARATE: codigoPorcentaje === `2` ? 12 : 0,
            CODALT_CENCOS:'',
          }
          
        })
    
      }else{
          const {
            codigoPrincipal,
            //codigoAuxiliar,
            descripcion,
            cantidad,
            precioUnitario,
            descuento,
            precioTotalSinImpuesto,
            impuestos,
          } = details
          const { baseImponible, valor, codigoPorcentaje } = impuestos.impuesto
          detailsParsed = {
            CODALT_ITEM: codigoPrincipal ? codigoPrincipal : `NOCODE`,
            CODALT_TIPO:'*',
            CODALT_CODIGO:'',
            CODALT_DESCRIPCION: descripcion,
            CODALT_UNIDAD:'',
            CODALT_CANTIDAD: parseFloat(cantidad),
            CODALT_COSTO: parseFloat(precioUnitario),
            CODALT_DESC: parseFloat(descuento),
            CODALT_TOTAL: parseFloat(precioTotalSinImpuesto),
            CODALT_IVAVAL: parseFloat(valor),
            CODALT_BASEIMPO: parseFloat(baseImponible),
            CODALT_IVACOD: parseFloat(codigoPorcentaje),
            CODALT_IVARATE: codigoPorcentaje === `2` ? 12 : 0,
            CODALT_CENCOS:'',
          }
      }
     
      console.log('------DETALLES------', detailsParsed)
      return detailsParsed
    } catch (error) {
      console.error(error)
      return null
    }
  }
 ///////////////////////////////////////////////////////////////////////
 async homolaarti(){
  this.detfachomo = await this.ComimpdoceleService.buscahomologa();
  this.ComimpdoceleService.arreglohomo[this.indicador]=this.detfachomo;
  this.displaycodalt= true;
  console.log('<<<<<>>>>>', this.detfachomo )
   //this.ComimpdoceleService.deletedetfacpro();
}
async deletebloq(){

if(this.idbloqarch === ''){
  this.messageService.add({
    key: 'impdocsri',
    severity: 'error',
    summary: 'Eliminar bloque',
    detail: 'Ingrese número de bloque a eliminar'
  });
  return;
}
switch (this.indTabladatos) {
  case 1: {
    try{
      this.spin=true;
      await this.ComimpdoceleService.deletebloqfac(this.idbloqarch);
      this.messageService.add({
        key: 'impdocsri',
        severity: 'success',
        summary: 'facturas de compras',
        detail: 'Se eliminó exitosamente'
      });
      this.idbloqarch = ''
      this.spin=false;
    } catch (error) {
      this.messageService.add({
        key: 'impdocsri',
        severity: 'error',
        summary: 'Eliminar Bloque de facturas',
        detail: 'Error al Eliminar'
      });
      this.spin=false;
    }
    break;
    }
  case 2: {
            
    break;
  }
  }
} 
async envianodedufac(){
  this.spin=true;
  let strDatos:  any[]= [];
      for(const res of this.documentosfaccomp){
        if(res.VISTO === true){
          strDatos = [...strDatos,{doc_name: res.doc_name} ];
        }   
      }
  for(const res of strDatos){
    for(const res1 of this.documentosupload){
      if(res.doc_name === res1.name){
        try{
        const obj1 = await this.transformaobjeto(res1.arch);
        const obj2 = await this.parseXml(obj1.comprobante);
        console.log('++++++2',obj2);
        await this.ComimpdoceleService.insertarnodedu(obj2.infoTributaria.claveAcceso,'ND');
        this.messageService.add({
          key: 'impdocsri',
          severity: 'success',
          summary: 'No deducible',
          detail: 'Se ingreso exitosamente'
        });
        this.spin=false;
      } catch (error) {
        this.messageService.add({
          key: 'impdocsri',
          severity: 'error',
          summary: 'No deducible',
          detail: 'Error al ingresar no deducible'
        });
        this.spin=false;
      }
          }
        }
      }
      const nodedu = await this.ComimpdoceleService.buscnodedu(); 
      this.documentosnodeducible=nodedu;
      await this.onBasicUpload(this.filesini);
      this.aggrid.refreshaggrid(this.documentosnodeducible, this.defaultDefsnodeducible.objeto);
      this.aggrid.refreshaggrid(this.documentosfaccomp, this.defaultColDefDocfaccom.objeto);
      this.aggrid.refreshaggrid(this.documentosretcom, this.defaultColDefDocretcom.objeto);
      this.faccomTodo = false;
      this.spin=false;
}
async envianodeduret(){
  this.spin=true;
  let strDatos:  any[]= [];
  for(const res of this.documentosretcom){
    if(res.VISTO === true){
      strDatos = [...strDatos,{doc_name: res.doc_name} ];
    }   
  }
  for(const res of strDatos){
      for(const res1 of this.documentosupload){
        if(res.doc_name === res1.name){
          try{
          const obj1 = await this.transformaobjeto(res1.arch);
          const obj2 = await this.parseXml(obj1.comprobante);
          console.log('++++++2',obj2);
          await this.ComimpdoceleService.insertarnodedu(obj2.infoTributaria.claveAcceso,'ND');
          this.messageService.add({
            key: 'impdocsri',
            severity: 'success',
            summary: 'No deducible',
            detail: 'Se ingreso exitosamente'
          });
          this.spin=false;
        } catch (error) {
          this.messageService.add({
            key: 'impdocsri',
            severity: 'error',
            summary: 'No deducible',
            detail: 'Error al ingresar no deducible'
          });
          this.spin=false;
        }
        }
      }
    }
    const nodedu = await this.ComimpdoceleService.buscnodedu(); 
    this.documentosnodeducible=nodedu;
    await this.onBasicUpload(this.filesini);
    this.aggrid.refreshaggrid(this.documentosnodeducible, this.defaultDefsnodeducible.objeto);
    this.aggrid.refreshaggrid(this.documentosfaccomp, this.defaultColDefDocfaccom.objeto);
    this.aggrid.refreshaggrid(this.documentosretcom, this.defaultColDefDocretcom.objeto);
    this.retcomTodo = false;
    this.spin=false;
}
async deletenodedu(){
  this.spin=true;
  for(const res of this.documentosnodeducible){
    if(res.VISTO === true){
      try{
        await this.ComimpdoceleService.deletenodedu(res.TRNCLAVEDOC_CLACESSO);
        this.messageService.add({
          key: 'impdocsri',
          severity: 'success',
          summary: 'No deducible',
          detail: 'Se eliminó exitosamente'
        });
        this.spin=false;
      } catch (error) {
        this.messageService.add({
          key: 'impdocsri',
          severity: 'error',
          summary: 'No deducible',
          detail: 'Error al eliminar no deducible'
        });
        this.spin=false;
      }
    }   
  }
  const nodedu = await this.ComimpdoceleService.buscnodedu(); 
  this.documentosnodeducible=nodedu;
  await this.onBasicUpload(this.filesini);
  this.aggrid.refreshaggrid(this.documentosfaccomp, this.defaultColDefDocfaccom.objeto);
  this.aggrid.refreshaggrid(this.documentosretcom, this.defaultColDefDocretcom.objeto);
  this.aggrid.refreshaggrid(this.documentosnodeducible, this.defaultDefsnodeducible.objeto);
    this.nodedutodo = false;
    this.spin=false;
}
}
