import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MaePantalla} from '../interfaces/maepantalla';
import {UsuarioEsquema} from '../usuarioEsquema';
import {Usuario} from '../usuario';
import { ConfInicialesService } from './conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class MaepantallaService {
  datos: MaePantalla;
  public way: string;

  constructor(
    private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService
  ) {
    this.way = sessionStorage.getItem('way');
  }
  getMaePantalla(catcodigo, subcatcodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/pantalla/20eed94ecaafd60000', {
      elementos: {
        CAT_CODIGO: catcodigo,
        SUB_CODIGO: subcatcodigo
      }
    }, this.confIniciales.httpOptions());
  }

  getMaeArticuloPrecioA(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/pantalla/20eeab72ee76e60000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  insertMaePantalla(maepantalla): void { // metodo para guardar una pantalla
    console.log(maepantalla);
    this.http.post(this.way + '/pdv/pantalla/257e3b7db29d960000', {
      elementos: {
        // CAT_CODIGO: maepantalla.CAT_CODIGO.CAT_CODIGO,
        CAT_CODIGO: maepantalla.CAT_CODIGO,
        COM_CODIGO: '01',
        // SUB_CODIGO: maepantalla.SUB_CODIGO.SUB_CODIGO,
        SUB_CODIGO: maepantalla.SUB_CODIGO,
        PANT_ORDEN: maepantalla.PANT_ORDEN,
        ART_CODIGO: maepantalla.ART_CODIGO,
        PANT_PESO: maepantalla.PANT_PESO,
        ART_IMAGEN: maepantalla.ART_IMAGEN
      }
    }, this.confIniciales.httpOptions()).subscribe(respuesta => {
      console.log(respuesta);
      console.log('Técnico insertado');
    }, error => {
      console.log(error);
      console.log('Error');
    });
  }

  updateMaePantalla(maepantalla): void { // actualizar pantalla
    console.log(maepantalla);
    this.http.post(this.way + '/pdv/pantalla/7b2d575d653b2c00000', {
      elementos: {
        PANT_ORDEN: maepantalla.PANT_ORDEN,
        PANT_PESO: maepantalla.PANT_PESO,
        ART_CODIGO: maepantalla.ART_CODIGO,
        CAT_CODIGO: maepantalla.CAT_CODIGO,
        SUB_CODIGO: maepantalla.SUB_CODIGO,
        ART_IMAGEN: maepantalla.ART_IMAGEN
      }
    }, this.confIniciales.httpOptions()).subscribe(respuesta => {
      console.log(respuesta);
      console.log('Pantalla actualizada');
    }, error => {
      console.log(error);
      console.log('Error');
    });
  }


  deleteMaePantalla(maepantalla): void { // actualizar pantalla
    console.log(maepantalla);
    this.http.post(this.way + '/pdv/pantalla/3ab2b4aeadd94e00000', {
      elementos: {
        ART_CODIGO: maepantalla.ART_CODIGO
      }
    }, this.confIniciales.httpOptions()).subscribe(respuesta => {
      console.log(respuesta);
      console.log('Técnico actualizado');
    }, error => {
      console.log(error);
      console.log('Error');
    });
  }

}

