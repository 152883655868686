<p-toast [style]="{marginTop: '41vh'}" position="top-center"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmlogin" title="Pregunta" appendTo="body"></p-confirmDialog>
<div  >
	<div class="p-grid" >
		<div class="p-col-8"  style=" background-color: #ffffff; ">
			<div class="login-body" style="z-index: 1; ">
				<div style=" position: absolute; width: 100%; top: 50%; left: 50%; transform: translate(-50%,-50%); ">
					<p-galleria [(value)]="imagenes" [numVisible]="3" [showIndicators]="true" [showThumbnails]="false"
						[changeItemOnIndicatorHover]="true" [showIndicatorsOnItem]="true" [autoPlay]="true" [circular]="true"
						[transitionInterval]="10000"  >
						<ng-template pTemplate="item" let-item>
							<img src="../../assets/layout/images/{{item.image}}" [alt]="item.name" style="  width: 100%; height: 100%;  ">
						</ng-template>
					</p-galleria>
				</div>
				<div class="letra1" style="z-index: 4; position: absolute;">
					<h1 style="margin-top: 20vh; margin-left: 1vw; font-size: 4vh; color: #000000; text-align: justify;  margin-right: 15vw;">{{part1}}
						{{part2}}<br></h1>
				</div>
				<div class="letra2" style="z-index: 4; position: absolute;">
					<h1 style="margin-top: 20vh; margin-left: 1vw; font-size: 4vh; color: #ffffff; text-align: justify; margin-right: 15vw;">{{part3}}
						{{part4}}<br></h1>
				</div>
				<div class="letra3" style="z-index: 4; position: absolute;">
					<h1 style="margin-top: 20vh; margin-left: 1vw; font-size: 4vh; color: #ffffff; text-align: justify; margin-right: 15vw;">{{part5}}
						{{part6}}<br></h1>
				</div>
				<div class="letra4" style="z-index: 4; position: absolute;">
					<h1 style="margin-top: 23vh; margin-left: 1vw; font-size: 4vh; color: #000000; text-align: justify; margin-right: 15vw;">{{part7}}
						{{part8}}</h1>
				</div>
				<div class="letra5" style="z-index: 4; position: absolute;">
					<h1 style="margin-top: 20vh; margin-left: 1vw; font-size: 4vh; color: #ffffff; text-align: justify; margin-right: 15vw;">{{part9}}
						{{part10}}<br></h1>
				</div>
				<div class="letra6" style="z-index: 4; position: absolute;">
					<h1 style="margin-top: 20vh; margin-left: 1vw; font-size: 4vh; color: #000000; text-align: justify; margin-right: 15vw;">{{part11}}
						{{part12}}<br></h1>
				</div>
			</div>
		</div>
		<div class="p-col-4" style=" position: relative; background-color: #ffffff; z-index: 1;"> 
			<div>
				<div style="width: 98%;  height: 50px; text-align: right; margin-top: 8%; z-index: 4;  position: absolute;">
					<div class="layout-config">
						<div class="layout-config-content-wrapper" >
							<a class="layout-config-button" id="layout-config-button" style="cursor: pointer; " (click)="acciones()" >
								<i class="pi pi-bars" style="cursor: pointer; width: 50px; font-size: 40px; color:#1d2355 ;" ></i>
							</a>
							<p-sidebar *ngIf="sacim === true" class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
								[dismissible]="true" [showCloseIcon]="false" [style]="{'width':'250px', 'height':'230px','margin-top': '95px' ,'margin-right': '10px' ,'border-radius': '10px' ,'border': '1px solid #1d2355' }">
								<br>
								<div  class="p-col-12" style="font-size: 16px; font-weight: bold;  text-align: left !important; ">
									<p-tabMenu [model]="items" style="list-style-type: none; font-size: 16px;">
										<br><br>
									</p-tabMenu>
								</div>
							</p-sidebar>
							<p-sidebar *ngIf="sacim === false" class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
								[dismissible]="true" [showCloseIcon]="false" [style]="{'width':'250px', 'height':'150px','margin-top': '95px' ,'margin-right': '10px' ,'border-radius': '10px' ,'border': '1px solid #1d2355' }">
								<br>
								<div  class="p-col-12" style="font-size: 16px; font-weight: bold;  text-align: left !important; ">
									<p-tabMenu [model]="items" style="list-style-type: none; font-size: 16px;">
										<br><br>
									</p-tabMenu>
								</div>
							</p-sidebar>
						</div>
					</div>
				</div>
				<div style="position: absolute; width: 100%; top: 50%; left: 50%; transform: translate(-50%,-50%);">
					<div style="position: flex; width: 100%;  height: 100%; text-align: center; ">
						<img src="../../assets/layout/images/Logo_Marca.png" style="
						width: 20vw; height: 10vh;">
					</div>
					<div *ngIf="sacim === true" style="text-align: center; font-size: 16px; font-weight: bold; margin-top: 5%;">
						<h3>Saci Erp en la nube te permite llevar tu negocio a todos lados.</h3>
					</div>
					<div style=" width: 100%;  height: 100%;  align-items: center; margin-top: 5%; border-radius: 15px;border: 1px solid #1d2355; padding: 4px;">
						<div class=p-grid>
							<div id="item1" class="p-col-12" *ngIf="item1 === true">
									<div style="background-color: rgb(235,245,251); padding: 1.5em;">
										<!-- <div class="p-col-12" style="text-align: center">
											<img src="../../assets/layout/images/Logo_Marca.png" style="width: 150px; height: 50px">
										</div> -->
										<div class="p-col-12" style="text-align: center; font-size: 16px; font-weight: bold;">
											<div class="login-input" style="position: relative;">
												<i class="pi pi-briefcase" style="position: absolute; left: 17%; top: 50%; transform: translateY(-50%);"></i>
												<input id="empresa" [(ngModel)]="nombreempresa" type="text" pInputText autocomplete="off" placeholder="Empresa" style=" width: 70%; height: 40px; font-size: 15px; padding-left: 7%;">
											</div>
										</div>
										<div class="p-col-12 username-container" style="text-align: center; font-size: 16px; font-weight: bold;">
											<div class="login-input"style="position: relative;">
												<i class="pi pi-user" style="position: absolute;  left: 17%; top: 50%; transform: translateY(-50%);"></i>
												<input id="input" [(ngModel)]="usuario" type="text" pInputText autocomplete="off" placeholder="Usuario" style="width: 70%; height: 40px; font-size: 15px; padding-left: 7%;">
											</div>
										</div>
										<div class="p-col-12 password-container" style="text-align: center; font-size: 16px; font-weight: bold;">
											<div class="login-input"style="position: relative;">
												<i class="pi pi-lock" style="position: absolute;  left: 17%; top: 50%; transform: translateY(-50%);"></i>
												<input [(ngModel)]="pass" (keydown)="loginwithEnter($event)" pPassword type="password"
													autocomplete="new-password"  placeholder="Contraseña" style="width: 70%; height: 40px; font-size: 15px; padding-left: 7%;"/>
											</div>
										</div>
										<br>
										<div class="p-col-12" style="text-align: center; font-size: 16px; font-weight: bold;">
											<a style="cursor: pointer;" role="button" (click)="olvidocontrasena()">¿Olvidó su contraseña?</a>
										</div>
										<div *ngIf="captchaexiste==true" class="p-col-12" style="text-align: center; margin-top: 20px">
											<div class="p-grid">
												<div class="p-col-2" style="text-align: center; font-size: 16px;  "></div>
												<div class="p-col-8" style="text-align: center; font-size: 16px;  ">
													<p-captcha siteKey="{{secretKeyCaptcha}}" (onResponse)="showResponse($event)" style="text-align: center; width: 100%; height: auto; max-width: 100%;"></p-captcha></div>
												<div class="p-col-2" style="text-align: center; font-size: 16px;  "></div>
											</div>
										</div>
										<br>
										<div class="p-col-12" style="text-align: center">
											<div class="p-grid">
												<div class="p-col-12" style="text-align: center; font-size: 16px;  ">
													<button (click)="login()" pButton type="button" icon="pi pi-user" label="Ingresar" style="font-size: 16px;"></button>
												</div>
												<!--<div class="p-col-4" style="text-align: center">
													<button (click)="login()" pButton type="button" icon="pi pi-user"
															label="Olvidó su Contraseña"></button>
												</div>-->
												<!-- <div class="p-col-6" style="text-align: center">
													<button (click)="back()" pButton type="button" icon="pi pi-undo" class="ui-button-secondary"
														label="Regresar"></button>
												</div> -->
											</div>
										</div>
									</div>
							</div>
							<div id="item2" class="p-col-12" *ngIf="item2 === true">
								<div style="background-color: rgb(235,245,251); padding: 1.5em">
									<!-- <div class="p-col-12" style="text-align: center">
										<img src="../../assets/layout/images/Logo_Marca.png" style="width: 150px; height: 50px">
									</div> -->
									<div class="p-col-12 username-container" style="text-align: center; font-size: 16px; font-weight: bold;">
										<div class="login-input"style="position: relative;">
											<i class="pi pi-id-card" style="position: absolute; left: 17%; top: 50%; transform: translateY(-50%);"></i>
											<input id="imputDescargas" [(ngModel)]="idDescargas" type="text" pInputText autocomplete="off" placeholder="ID" style="width: 70%; height: 40px; font-size: 15px; padding-left: 7%;">
										</div>
									</div>
									<div class="p-col-12 password-container" style="text-align: center; font-size: 16px; font-weight: bold;">
										<div class="login-input"style="position: relative;">
											<i class="pi pi-lock" style="position: absolute; left: 17%; top: 50%; transform: translateY(-50%);"></i>
											<input [(ngModel)]="passDescargas" (keydown)="loginwithEnterDescargas($event)" pPassword
												type="password" placeholder="Contraseña" style="width: 70%;height: 40px; font-size: 15px;  padding-left: 7%;" />
										</div>
									</div>
									<br>
									<div class="p-col-12" style="text-align: center; font-size: 16px; font-weight: bold;">
										<a routerLink="/recuperarpasswd">¿Olvido su contraseña?</a>
									</div>
									<br>
									<div *ngIf="captchaexiste==true" class="p-col-12" style="text-align: center; font-size: 16px; ">
										<div class="p-grid">
											<div class="p-col-2" style="text-align: center; font-size: 16px;  "></div>
											<div class="p-col-8" style="text-align: center; font-size: 16px;  ">
												<p-captcha siteKey="{{secretKeyCaptcha}}" (onResponse)="showResponse($event)" style="text-align: center;  width: 100%; height: auto; max-width: 100%;"></p-captcha></div>
											<div class="p-col-2" style="text-align: center; font-size: 16px;  "></div>
										</div>
									</div>
									<div class="p-col-12" style="text-align: center; font-size: 16px;">
										<button (click)="loginDescargas()" pButton type="button" icon="pi pi-user" label="Ingresar" style="font-size: 16px;"></button>
									</div>
									<!-- <div class="p-col-12" style="text-align: center">
										<button (click)="back()" pButton type="button" icon="pi pi-undo" class="ui-button-secondary"
											label="Regresar"></button>
									</div> -->
								</div>
							</div>
						</div>
					</div>
					<div *ngIf="sacim === true" style="text-align: center; font-size: 16px; font-weight: bold; margin-top: 5%;">
						<h4>Al ingresar a SACIERP, estas aceptando nuestros 
							<br>
							<a (click)="displayTtyc=true">Términos y Condiciones.</a>
						</h4>
					</div>
				</div>
				<!-- <div >
					<div class=p-grid>
							<div class="p-col-12" style=" width: 100%;  height: 100%; text-align: center;  ">
								<img src="../../assets/layout/images/oracle.png" style="margin-top: -5px; width: 80%; height: auto">
							</div>
					</div>
				</div> -->
			</div>
		</div>
	</div>
</div>
<!-- <img src="../../assets/layout/images/Logo_Marca.png" style="position: absolute; z-index: 2;
	 width: 20vw; height: 10vh;
	 margin-top: 5vh; margin-left: 5vw">
<div style="position: absolute;
	z-index: 5; margin-top: 91vh; margin-left: 75vw; width: 24vw; height: 5vh">
	<div class=p-grid style="height: 5vh">
		<div class="p-col-2" style="cursor: pointer" title="Facebook" (click)="links('facebook')">
		</div>
		<div class="p-col-2" style="cursor: pointer" title="Instagram" (click)="links('instagram')">
		</div>
		<div class="p-col-2" style="cursor: pointer" title="Twitter" (click)="links('twitter')">
		</div>
		<div class="p-col-6">
			<div class=p-grid style="height: 5vh">
				<div class="p-col-5" style="cursor: pointer" title="Youtube" (click)="links('youtube')">
				</div>
				<div class="p-col-5" style="cursor: pointer" title="Whatsapp" (click)="links('whatsapp')">
				</div>
			</div>
		</div>
	</div>
</div>
<div style="position: absolute; z-index: 2; width: 98%; margin-top: 8vh">
	<div class=p-grid>
		<div class="p-col-4">
		</div>
		<div class="p-col-6">

		</div>
	</div>
</div>

<div style="position: absolute; z-index: 2; width: 98%; margin-top: 8vh">
	<div class=p-grid>
		<div class="p-col-4">
		</div>
		<div class="p-col-6">
			<div class=p-grid>
				<div class="p-col-4">
					<button class="image" (click)="openLogin(2)" pButton
							type="button" style="background-image: url(../../assets/images/1_Ingresar.png);"></button>
				</div>
				<div class="p-col-4">
					<button class="image" (click)="openLogin(1)" pButton
							type="button" style="background-image: url(../../assets/images/2_Descargar.png);"></button>
				</div>
				<div class="p-col-4">
					<button class="image" (click)="openLogin(3)" pButton
							type="button"
							style="background-image: url(../../assets/images/3_Aulavirtual.png);"></button>
					<button (click)="openLogin(4)" pButton
							type="button"></button>
				</div>
			</div>
			<p-tabMenu [model]="items"></p-tabMenu>
		</div>
		<div class="p-col-2">
			<div class=p-grid>
				<div class="p-col-11">
					<img src="../../assets/layout/images/oracle.png" style="margin-top: -5px; width: 100%; height: 6vh">
				</div>
			</div>
		</div>
	</div>
</div> -->

<!-- <div class="login-body" style="z-index: 1;">
	<p-galleria [(value)]="imagenes" [numVisible]="3" [showIndicators]="true" [showThumbnails]="false"
		[changeItemOnIndicatorHover]="true" [showIndicatorsOnItem]="true" [autoPlay]="true" [circular]="true"
		[transitionInterval]="10000" style="margin-top: 20vh; position: absolute">
		<ng-template pTemplate="item" let-item>
			<img src="../../assets/layout/images/{{item.image}}" [alt]="item.name" style="width: 100vw; height: 80vh">
		</ng-template>
	</p-galleria>
	<div class="letra1" style="z-index: 4; position: absolute;">
		<h1 style="margin-top: 20vh; margin-left: 10vw; font-size: 5vh; color: #ffffff">{{part1}}<br>
			{{part2}}<br>
			{{part3}}<br>
			{{part4}}<br>
			{{part5}}</h1>
	</div>
	<div class="letra2" style="z-index: 4; position: absolute;">
		<h1 style="margin-top: 25vh; margin-left: 10vw; font-size: 2vh; color: #ffffff">{{part6}}</h1>
	</div>
	<div class="letra3" style="z-index: 4; position: absolute;">
		<h1 style="margin-top: 20vh; margin-left: 10vw; font-size: 5vh; color: #ffffff">{{part7}}<br>
			{{part8}}<br>
			{{part9}}</h1>
	</div>
	<div class="letra4" style="z-index: 4; position: absolute;">
		<h1 style="margin-top: 23vh; margin-left: 10vw; font-size: 2vh; color: #ffffff">{{part10}}<br>
			{{part11}}</h1>
	</div>
	<div class="letra5" style="z-index: 4; position: absolute;">
		<h1 style="margin-top: 20vh; margin-left: 10vw; font-size: 5vh; color: #000000">{{part12}}<br>
			{{part13}}<br>
			{{part14}}<br>
			{{part15}}<br>
			{{part16}}</h1>
	</div>
	<div class="letra6" style="z-index: 4; position: absolute;">
		<h1 style="margin-top: 25vh; margin-left: 10vw; font-size: 2vh; color: #000000">{{part17}}</h1>
	</div>
</div> -->

<!-- <p-dialog header="Descargas" [(visible)]="displayDescargas" [transitionOptions]="'0ms'" [modal]="false" appendTo="body"
	[closable]="false" [showHeader]="false">
	<div style="background-color: rgb(235,245,251); padding: 1.5em">
		<div class="p-col-12" style="text-align: center">
			<img src="../../assets/layout/images/Logo_Marca.png" style="width: 150px; height: 50px">
		</div>
		<div class="p-col-12 username-container">
			<label>ID</label>
			<div class="login-input">
				<input id="imputDescargas" [(ngModel)]="idDescargas" type="text" pInputText autocomplete="off">
			</div>
		</div>
		<div class="p-col-12 password-container">
			<label>Contraseña</label>
			<div class="login-input">
				<input [(ngModel)]="passDescargas" (keydown)="loginwithEnterDescargas($event)" pPassword
					type="password" />
			</div>
		</div>
		<div class="p-col-12">
			<a routerLink="/recuperarpasswd">Olvido su contraseña</a>
		</div>
		<div *ngIf="captchaexiste==true" class="p-col-12">
			<p-captcha #captcha siteKey="{{secretKeyCaptcha}}" (onResponse)="showResponse($event)"></p-captcha>
		</div>
		<div class="p-col-12" style="text-align: center">
			<button (click)="loginDescargas()" pButton type="button" icon="pi pi-user" label="Ingresar"></button>
		</div>
		<div class="p-col-12" style="text-align: center">
			<button (click)="back()" pButton type="button" icon="pi pi-undo" class="ui-button-secondary"
				label="Regresar"></button>
		</div>
	</div>
</p-dialog> -->


<!-- <p-dialog header="Empresa" [(visible)]="displayEmpresa" [transitionOptions]="'0ms'" [modal]="false" 
	[closable]="false" [showHeader]="false">
	<div style="background-color: rgb(235,245,251); padding: 1.5em;">
		<div class="p-col-12" style="text-align: center">
			<img src="../../assets/layout/images/Logo_Marca.png" style="width: 150px; height: 50px">
		</div>
		<div class="p-col-12" style="text-align: center">
			<label>Empresa</label>
			<div class="login-input">
				<input id="empresa" [(ngModel)]="nombreempresa" type="text" pInputText autocomplete="off">
			</div>
		</div>
		<div class="p-col-12 username-container" style="text-align: center">
			<label>Usuario</label>
			<div class="login-input">
				<input id="input" [(ngModel)]="usuario" type="text" pInputText autocomplete="off">
			</div>
		</div>
		<div class="p-col-12 password-container" style="text-align: center">
			<label>Contraseña</label>
			<div class="login-input">
				<input [(ngModel)]="pass" (keydown)="loginwithEnter($event)" pPassword type="password"
					autocomplete="new-password" />
			</div>
		</div>
		<div class="p-col-12" style="text-align: center">
			<a style="cursor: pointer;" role="button" (click)="olvidocontrasena()">Olvidó su contraseña</a>
		</div>
		<div *ngIf="captchaexiste==true" class="p-col-12" style="text-align: center; margin-top: 20px">
			<p-captcha siteKey="{{secretKeyCaptcha}}" (onResponse)="showResponse($event)"></p-captcha>
		</div>
		<div class="p-col-12" style="text-align: center">
			<div class="p-grid">
				<div class="p-col-6" style="text-align: center">
					<button (click)="login()" pButton type="button" icon="pi pi-user" label="Ingresar"></button>
				</div>
				<div class="p-col-4" style="text-align: center">
					<button (click)="login()" pButton type="button" icon="pi pi-user"
							label="Olvidó su Contraseña"></button>
				</div>
				<div class="p-col-6" style="text-align: center">
					<button (click)="back()" pButton type="button" icon="pi pi-undo" class="ui-button-secondary"
						label="Regresar"></button>
				</div>
			</div>
		</div>
	</div>
</p-dialog> -->

<p-dialog header="Endpoint" [(visible)]="displayEndpoint" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[closable]="true" [showHeader]="true">

	<div class="p-col-12 section-test-values">
		<div>
			<input type="text" [(ngModel)]="ruc" pInputText placeholder="RUC" autocomplete="off">
		</div>
		<div>
			<input type="text" [(ngModel)]="servicio" pInputText placeholder="service" autocomplete="off">
		</div>
		<div>
			<input type="text" [(ngModel)]="user" pInputText placeholder="user" autocomplete="off">
		</div>
	</div>
	<div class="p-col-12 section-test-results">
		<div class="p-col-3">
			<button (click)="testEmpresasEndPoint()" pButton type="button" label="Test"></button>
		</div>
		<div class="p-col-9">
			{{resultado}}
		</div>
	</div>

</p-dialog>


<p-dialog header="Código de seguridad" [(visible)]="displayToken" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [closable]="true" [showHeader]="true">
	<p-toast [style]="{marginTop: '35vh'}" position="top-center" key="toast"></p-toast>
	<div style="background-color: rgb(235,245,251); padding: 1.5em;">
		<div class="p-col-12" style="text-align: center;  margin-top: -15px">
			<span style=" font-size: 14px; font-weight: bold; color: red">{{displayTimer}}</span>
		</div>
		<div class="p-col-12" style="text-align: center">
			<input style="font-size: 25pt; width: 150px" type="password" [(ngModel)]="tokenusuario"
				(keydown)="enterToken($event)" pInputText autocomplete="off">
		</div>
		<div class="p-col-12" style="text-align: center; margin-bottom: -20px;">
			<div class="p-grid">
				<div class="p-col-6" style="text-align: center; margin-top: 10px" >
					<button (click)="aceptarToken()" pButton type="button" icon="pi pi-check" label="Aceptar"></button>
				</div>
				<div class="p-col-6" style="text-align: center; margin-top: 10px">
					<button (click)="cancelarToken()" pButton type="button" icon="pi pi-undo"
						class="ui-button-secondary" label="Cancelar"></button>
				</div>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog header="Términos y  Condiciones" [(visible)]="displayTtyc" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [closable]="true" [showHeader]="true" [style]="{width: '80vw', height: 'auto'}">
	<div style="text-align: justify;">
		<p class="western"  style="text-align: center; line-height: 108%; margin-right: 0.01in; margin-bottom: 0in; font-size: 14pt">
			<b>Acuerdo de servicio de la
			Plataforma SaciERP Cloud</b></p>
			<br>
			<p class="western"  style="text-align: left; line-height: 108%; text-indent: 0in; margin-left: 0in; margin-right: 0in; margin-bottom: 0in">
			 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			<b>TERMINOS GENERALES. - </b>PRODUCTORA DE SOFTWARE SACIERP SAS, es
			una persona jurídica, cuyo objeto social le permite realizar el
			desarrollo de todo tipo de software y de manera especial el software
			contable; 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			PRODUCTORA DE SOFTWARE SACIERP SAS, ha concebido y desarrollado por
			medio de su departamento técnico la plataforma 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			SaciERP misma que despliega en el enlace <b>www.sacierp.cloud</b>, en
			los servidores de propiedad de PRODUCTORA DE SOFTWARE SACIERP SAS; 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			PRODUCTORA DE SOFTWARE SACIERP SAS pone a disposición de EL USUARIO
			la plataforma de servicio SaciERP, para su uso en modalidad SaaS
			“Software como servicio”, mismo que le permite almacenar y
			administrar su información contable en línea a través del
			internet.; 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			EL USUARIO declara que toda la información contable proporcionada e
			ingresada en la plataforma SaciERP proviene de negocios de origen
			lícitos, por lo que declara no utilizar la plataforma para
			propósitos opuestos a la Ley, las buenas costumbres y la moral, por
			lo que PRODUCTORA DE SOFTWARE SACIERP SAS no se hace responsable de
			ello; 
			</p>
			<p class="western" style="line-height: 103%; margin-bottom: 0in">PRODUCTORA
			DE SOFTWARE SACIERP SAS podrá divulgar la información almacenada en
			sus bases de datos a cualquier autoridad, únicamente con el
			propósito de cumplir con órdenes o requerimientos de autoridades
			competentes. No obstante, se aclara que PRODUCTORA DE SOFTWARE
			SACIERP SAS no utilizará la información confidencial y/o datos
			personales almacenados en la base de datos con fines públicos sin la
			autorización expresa dEL USUARIO. Esta autorización se considera
			otorgada de manera tácita por parte de EL USUARIO a PRODUCTORA DE
			SOFTWARE SACIERP SAS en el instante en que solicita asistencia
			técnica, ya sea presencial o en línea, a través del sistema de
			tickets de su mesa de servicio. Esta autorización se limita a la
			realización de un trabajo puntual y específico;</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			PRODUCTORA DE SOFTWARE SACIERP SAS ha mantenido y mantiene todos los
			estándares de seguridad normalmente aceptados por equipos de TI, y
			en cuidado de esta seguirá ampliando e implementado nuevas y mejores
			medidas de seguridad, EL USUARIO acepta la contingencia de que
			cualquier correspondencia electrónica cursada en el ámbito del
			servicio contratado en la plataforma SaciERP pueda ser capturada y/o
			interceptada de cualquier forma por parte de terceros. 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			<br><b>SLA ACUERDO DE NIVEL DE SERVICO. -</b>  <b>HORARIO DE SERVICIO DE
			SOPORTE TECNICO. – </b>El servicio de soporte técnico se lo brinda
			mediante el acceso al sistema de tickets en el siguiente enlace
			<u><a href="http://www.saci-soporte.com/" style="color: #0000ff;">www.saci-soporte.com</a></u><a href="http://www.saci-soporte.com/">
			</a> el horario de atención son todos los días hábiles en horario
			de 08:30 a 17:30, los tickets se resuelven con atención en línea o
			presencial de acuerdo a solicitud de EL USUARIO. Los tickets se
			cierran por servicio facturado o ejecución de garantía de la
			Plataforma SaciERP, en cualquier caso, siempre generan un informe
			técnico del problema y la solución brindada. 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			<b>Tiempo de respuesta en niveles de servicio. – </b>Los niveles de
			servicio son asignados por el operador Administrador del Centro de
			atención al Cliente del sistema de tickets. 
			</p>
			<p class="western"  style=" text-align: center; line-height: 108%; text-indent: 0in; margin-left: -0in;  margin-bottom: 0in">
			<img src="../../assets/layout/images/tyc_img1.jpg" name="Picture 193"  width="511" height="204" />
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			<b>Tarifas de soporte técnico. – </b>Las horas de soporte técnico
			de tickets cerrados por facturación tienen un costo de 50.00 usd la
			hora más impuestos de ley; en forma presencial el tiempo mínimo de
			facturación es de 2 horas, mientras que en modalidad en línea es de
			30 minutos. Los tickets cerrados por garantía de la Plataforma no
			tienen costo alguno. 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			<b>Política de precios. – </b>Los precios de atención por soporte
			técnico son directamente proporcionales con el salario mínimo vital
			vigente a la fecha y estos se renovarán de acuerdo con las
			variaciones dispuestas por el estado ecuatoriano, así el costo de la
			hora de soporte técnico corresponde al 11.11% de un salario mínimo
			vital. 
			</p>
			<p class="western"  style=" text-align: left; line-height: 108%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			<br><b>METRICA DE LOS SERVICIOS SLA.   </b>
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			<b>Disponibilidad. - </b>En base a la disponibilidad ofertada 98%, la
			suma ponderada anual o mensual del tiempo en línea del sistema
			comparada versus el 100%. 
			</p>
			<p  style=" font-family: Calibri, serif; text-align: left; line-height: 100%; text-indent: 0in; margin-left: 0in; margin-right: 0in; margin-bottom: 0in">
			<b style="font-family: Arial, serif;">Restricciones de Disponibilidad. -</b>
			Estas restricciones incluyen mantenimiento
			rutinario de la Plataforma y tiempos fuera de línea no
			calendarizados producidos por factores no considerados dentro del
			presente Acuerdo (factores eléctricos, desastres naturales,
			manipulación de terceros).
			Suspensiones por fuerza mayor debido a mantenimientos programados e
			informados con antelación; Indisponibilidad debido a eventos fuera
			del control de SaciERP debido a indisponibilidad de los proveedores
			de infraestructura o  datacenter de alojamiento y despliegue; Fallas
			en servidores, hardware y software; debido a acciones externas por
			parte de hackers, virus, cookies, etc; Indisponibilidad por fallas en
			los equipos de los usuarios de la plataforma, etc.</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			<b>Promedio de espera. - </b>Tiempo promedio de espera de los
			usuarios para recibir atención telefónica o mail durante períodos
			de tiempo diurno y nocturno  
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			Línea base diurna: &gt; = 25 minutos 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			Línea base nocturna: &gt; = N/D 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			<b>Suspensión del servicio. –</b> El Acuerdo de nivel de servicio
			está disponible siempre que EL USUARIO no mantenga facturas
			pendientes de pago. 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			<br><b>ACTIVACION Y MANTENIMIENTO DE USUARIOS NOMBRADOS. -</b> EL USUARIO
			se compromete a notificar a PRODUCTORA DE SOFTWARE SACIERP SAS la
			designación del usuario-administrador de la plataforma SaciERP y
			PRODUCTORA DE SOFTWARE SACIERP SAS se compromete a la creación de
			los usuarios bajo los perfiles de acceso proporcionados por parte del
			usuario-administrador, una vez activados su mantenimiento es
			responsabilidad directa del usuario-administrador; bajo su
			responsabilidad está el control absoluto de las claves asignadas,
			haciéndose responsable de cualquier uso no adecuado de las mismas,
			la verificación de accesos no autorizados y su inmediata
			comunicación a PRODUCTORA DE SOFTWARE SACIERP SAS, esta a su vez se
			reserva el derecho de aceptar o negar una autorización de usuarios.
			Así mismo se reserva el derecho para retirar la autorización
			concedida, bloquear temporal o de manera definitiva el acceso de
			usuarios, cuanto se realice un mal uso por parte de éste en la
			plataforma SaciERP. 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			<br><b>LICENCIA DE USO y GARANTIA TECNICA. -</b> En virtud de este
			Contrato PRODUCTORA DE SOFTWARE SACIERP SAS se compromete a extender
			una Garantía Técnica sobre la plataforma SaciERP bajo las
			siguientes limitaciones y condiciones: 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			No se garantiza que la plataforma SaciERP cumpla con las expectativas
			dEL USUARIO por fuera del desempeño usual técnico del programa.
			PRODUCTORA DE SOFTWARE SACIERP SAS reconoce una Garantía Técnica de
			365 días (12 meses) a partir de la firma del presente contrato,
			sobre defectos de fabricación (errores técnicos de programación
			exclusivamente) y la reparación sin costo alguno para EL USUARIO.
			Esta garantía no incluye daños causados por la manipulación
			indebida del programa ajena al personal técnico autorizado de
			PRODUCTORA DE SOFTWARE SACIERP SAS, o generados por problemas en
			hardware, software, virus o factores externos que influyan el
			correcto funcionamiento de la plataforma SaciERP; 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			La Plataforma SaciERP entra en producción tal como fue desarrollado
			y desplegado a esta fecha y versión, no se aceptarán reclamos
			posteriores sobre supuestas especificaciones no informadas por
			escrito y con anterioridad a la adquisición por parte de EL USUARIO;
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			EL USUARIO conoce que PRODUCTORA DE SOFTWARE SACIERP SAS puede
			realizar modificaciones o actualizaciones a la plataforma SaciERP sin
			necesidad de notificación alguna a ninguna persona; 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			PRODUCTORA DE SOFTWARE SACIERP SAS garantiza la conexión de usuarios
			nombrados e ingreso de información ilimitada en la plataforma en un
			acceso del 98% con una cobertura 7/24; 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			PRODUCTORA DE SOFTWARE SACIERP SAS no será responsable por cualquier
			virus o afectaciones a los equipos de EL USUARIO o de terceros como
			consecuencia del acceso a la Plataforma SaciERP; 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			EL USUARIO renuncia expresamente a reclamar cualquier clase de
			responsabilidad, así como daños y perjuicios, al PROVEEDOR por
			posibles fallos, lentitud o errores en el acceso y uso de la
			plataforma SaciERP. 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			PRODUCTORA DE SOFTWARE SACIERP SAS entrega a EL USUARIO la plataforma
			estándar, Todo cambio requerido por escrito que se solicite, correrá
			a cargo de EL USUARIO bajo las tarifas que PRODUCTORA DE SOFTWARE
			SACIERP SAS establezca en caso de ser aceptado, factible y con tiempo
			estimado; 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			Esta Licencia no altera ni incluye la propiedad intelectual de
			PRODUCTORA DE SOFTWARE SACIERP SAS, por lo que no incluye código
			fuente ni binario de la plataforma; 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			Esta Licencia de uso no incluye marcas, patentes ni logotipos de
			PRODUCTORA DE SOFTWARE SACIERP SAS, ni compromete de ninguna manera
			la propiedad intelectual de SaciERP. 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			El acceso y uso de la Plataforma SaciERP es de responsabilidad
			exclusiva dEL USUARIO, por lo que PRODUCTORA DE SOFTWARE SACIERP SAS
			no se responsabiliza de manera directa ni subsidiaria de cualquier
			daño que EL USUARIO pudiera causar a terceros. 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			<br><b>LIMITACIONES DE LICENCIA. -</b>  EL USUARIO no está facultado a: 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			Comercializar o transferir los términos de este contrato de manera
			total o parcial a terceros; Realizar actividades de ingeniería
			inversa sobre los programas (binarios), desplegados en servidores o
			que pudiera llegar a tener acceso; 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			Alterar, copiar, transferir, vender, entregar a terceros, divulgar,
			publicar el código fuente bdd, alojado en la base de datos, mismo
			que es parte integral de la propiedad intelectual de SaciERP; Uso sin
			autorización escrita de logotipos, marcas o patentes de PRODUCTORA
			DE SOFTWARE SACIERP SAS. 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			<br><b>NO SE INCLUYE. - </b> No se incluyen en este contrato ni se altera
			la propiedad intelectual, marcas, patentes o programas fuentes de
			SaciERP o cualquier otro de propiedad de PRODUCTORA DE SOFTWARE
			SACIERP SAS. <br>
			<br><b>PERDIDA DE DERECHOS SOBRE ASISTENCIA TÉCNICA Y
			GARANTIA. - </b>Toda la asistencia técnica que EL USUARIO requiera,
			deberá ser solicitada, a través de PRODUCTORA DE SOFTWARE SACIERP
			SAS o su red de CAST, (Centro autorizado de servicio técnico),
			ubicados dentro del territorio nacional, mismos que estarán siempre
			actualizados en la página Web de PRODUCTORA DE SOFTWARE SACIERP SAS.
			Nunca y bajo ningún concepto, el personal de PRODUCTORA DE SOFTWARE
			SACIERP SAS o de los CAST, podrá ser contratado ni directa, ni
			indirectamente por parte de EL USUARIO;  
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			EL USUARIO, no podrá contratar de ninguna manera, ni forma los
			servicios del personal de PRODUCTORA DE SOFTWARE SACIERP SAS, incluso
			de ex empleados de PRODUCTORA DE SOFTWARE SACIERP SAS hasta 24 meses
			posteriores a cualquier relación contractual que mantenga EL USUARIO
			con PRODUCTORA DE SOFTWARE SACIERP SAS; 
			</p>
			<p class="western" style="line-height: 103%; margin-left: -0in; margin-right: 0in; margin-bottom: 0in">
			Si esta situación se presentara, esto es que EL USUARIO, por sí o
			por interpuesta persona contratare los servicios del personal técnico
			de PRODUCTORA DE SOFTWARE SACIERP SAS o de los CAST de forma
			ocasional o permanente, este perderá automáticamente la garantía
			sobre el producto concesionado, perderá el derecho a contar con la
			asistencia proporcionada por PRODUCTORA DE SOFTWARE SACIERP SAS o los
			CAST, así también este contrato se dará por terminado de manera
			tácita. 
			</p>
	</div>
</p-dialog>