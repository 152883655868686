import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Observable} from 'rxjs';
import {Maebodega} from '../invinterfaces/maebodega';
import {MessageService} from 'primeng/api';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class InvmaebodegaService {
  public url: string;
  public way: string;
  private _bodegas: Maebodega[];
  private bodegasAux: Maebodega[];

  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private messageService: MessageService, 
              private auditoriagral: AuditoriagralService,
              private confIniciales: ConfInicialesService) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }

  

  getBodega(): Observable<any[]> {
    return this.http.post<any[]>(this.way +'/inventario/bodega/10775786ba0a', {
        elementos: {},
    }, this.confIniciales.httpOptions());
  }


  saveBodega(bodega) {
    let contador = 0;
    let i = 0;
    this.getBodega().subscribe((respuesta) => {
      if (respuesta.length !== 0) {
      this.bodegasAux = respuesta;
      do {
        if (bodega.BOD_CODIGO === this.bodegasAux[i].BOD_CODIGO) {
          contador++;
        }
        i++;
      } while (this.bodegasAux.length > i);
      if (contador === 1) {
        let j = 0;
        let cont1 = 0;
        do {
          if (bodega.BOD_CODIGO === this.bodegas[j].BOD_CODIGO) {
            cont1++;
          }
          j++;
        } while (this.bodegas.length > j);
        if (cont1 === 2) {
          this.getBodega().subscribe((respuesta1) => {
            this.bodegasAux = respuesta1;
            this.bodegas = this.bodegasAux;
            this.messageService.add({
              key: 'bod',
              severity: 'error',
              summary: 'Inserción de Bodega',
              detail: 'El código de la bodega ya existe'
            });
          });
        } else if (cont1 === 1) {
          this.actualizarBodega(bodega).subscribe(() => {
            this.messageService.add({
              key: 'bod',
              severity: 'success',
              summary: 'Actualización de Bodega',
              detail: 'La bodega se actualizó correctamente'
            });
            this.auditoriagral.registrarAuditoria('INV_MAEBODEGA', String(bodega.BOD_CODIGO), 'A', '',
              '01', '', '', '', '').subscribe(() => {
            });
          });
        }
      } else {
        if (bodega.BOD_CODIGO !== '' && bodega.BOD_NOMBRE !== '') {
          this.guardarBodega(bodega).subscribe(() => {
            this.messageService.add({
              key: 'bod',
              severity: 'success',
              summary: 'Inserción de Bodega',
              detail: 'La bodega se insertó correctamente'
            });
            this.auditoriagral.registrarAuditoria('INV_MAEBODEGA', String(bodega.BOD_CODIGO), 'I', '',
              '01', '', '', '', '').subscribe(() => {
            });
          });
        } else {
          this.messageService.add({
            key: 'bod',
            severity: 'error',
            summary: 'Inserción de Bodega',
            detail: 'La bodega no se insertó, ingrese los campos obligatorios (*)'
          });
        }
      }
    } else {
        if (bodega.BOD_CODIGO !== '' && bodega.BOD_NOMBRE !== '') {
          this.guardarBodega(bodega).subscribe(() => {
            this.messageService.add({
              key: 'bod',
              severity: 'success',
              summary: 'Inserción de Bodega',
              detail: 'La bodega se insertó correctamente'
            });
            this.auditoriagral.registrarAuditoria('INV_MAEBODEGA', String(bodega.BOD_CODIGO), 'I', '',
              '01', '', '', '', '').subscribe(() => {
            });
          });
        } else {
          this.messageService.add({
            key: 'bod',
            severity: 'error',
            summary: 'Inserción de Bodega',
            detail: 'La bodega no se insertó, ingrese los campos obligatorios (*)'
          });
        }
      }
    });
  }


  guardarBodega(bodega: Maebodega): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/bodega/257e3b7d56d7860000', {
      elementos: {
        p_BOD_codigo_1: bodega.BOD_CODIGO,
        p_COM_codigo_2: '01',
        p_BOD_nombre_3: bodega.BOD_NOMBRE,
        p_BOD_ubicacion_4: bodega.BOD_UBICACION,
        p_BOD_telefono1: bodega.BOD_TELEFONO1,
        p_BOD_telefono2: bodega.BOD_TELEFONO2,
        p_BOD_Contacto: bodega.BOD_CONTACTO,
        p_BOD_ruc: bodega.BOD_RUC,
        p_BOD_autorizacion: bodega.BOD_AUTORIZACION,
        p_BOD_fechavalidez: bodega.BOD_FECHAVALIDEZ,
        p_BOD_tipo: bodega.BOD_TIPO,
        p_CEN_codigo: bodega.CEN_CODIGO,
        p_CON_codigo: bodega.CON_CODIGO,
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarBodega(bodega): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/bodega/299df2ab2', {
      elementos: {
        p_BOD_codigo_1: bodega.BOD_CODIGO,
        p_COM_codigo_2: '01',
        p_BOD_codigo_3: bodega.BOD_CODIGO,
        p_BOD_nombre_4: bodega.BOD_NOMBRE,
        p_BOD_ubicacion_5: bodega.BOD_UBICACION,
        p_BOD_telefono1: bodega.BOD_TELEFONO1,
        p_BOD_telefono2: bodega.BOD_TELEFONO2,
        p_BOD_Contacto: bodega.BOD_CONTACTO,
        p_BOD_ruc: bodega.BOD_RUC,
        p_BOD_autorizacion: bodega.BOD_AUTORIZACION,
        p_BOD_fechavalidez: bodega.BOD_FECHAVALIDEZ,
        p_BOD_tipo: bodega.BOD_TIPO,
        p_CEN_codigo: bodega.CEN_CODIGO,
        p_CON_codigo: bodega.CON_CODIGO,
      }
    }, this.confIniciales.httpOptions());
  }

  borrarBodega(bodega): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/bodega/5e37b56d786ba00', {
      elementos: {
        p_BOD_codigo_1: bodega,
        p_COM_codigo_2: '01'
      },
    }, this.confIniciales.httpOptions());
  }


  get bodegas(): Maebodega[] {
    return this._bodegas;
  }

  set bodegas(value: Maebodega[]) {
    this._bodegas = value;
  }
}
