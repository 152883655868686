import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Observable} from 'rxjs';
import {Invmaeunidad} from '../invinterfaces/invmaeunidad';
import {MessageService} from 'primeng/api';
import {Invmaeunidadequi} from '../invinterfaces/invmaeunidadequi';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class InvmaeunidadesService {
  get maeequivalencias(): Invmaeunidadequi[] {
    return this._maeequivalencias;
  }

  set maeequivalencias(value: Invmaeunidadequi[]) {
    this._maeequivalencias = value;
  }
  get maeunidades(): Invmaeunidad[] {
    return this._maeunidades;
  }

  set maeunidades(value: Invmaeunidad[]) {
    this._maeunidades = value;
  }
  public url: string;
  public way: string;
  private _maeunidades: Invmaeunidad[];
  private _maeequivalencias: Invmaeunidadequi[];
  private unidadesAux: Invmaeunidad[];
  private equivalenciasAux: Invmaeunidadequi[];

  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, private messageService: MessageService, private auditoriagral: AuditoriagralService,
              private confIniciales: ConfInicialesService) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }

  getUnidades(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/unidades/41ddf5e4d535dc', {
        elementos: {},
    }, this.confIniciales.httpOptions());
  }
  saveUnidades(unidad) {
    let contador = 0;
    let i = 0;
    this.getUnidades().subscribe((res) => {
      if (res.length !== 0) {
      this.unidadesAux = res;
      do {
        if (unidad.UNI_CODIGO === this.unidadesAux[i].UNI_CODIGO) {
          contador++;
        }
        i++;
      } while (this.unidadesAux.length > i);
      if (contador === 1) {
        let j = 0;
        let cont1 = 0;
        do {
          if (unidad.UNI_CODIGO === this.maeunidades[j].UNI_CODIGO) {
            cont1++;
          }
          j++;
        } while (this.maeunidades.length > j);
        if (cont1 === 2) {
          this.getUnidades().subscribe((respuesta) => {
            this.unidadesAux = respuesta;
            this.maeunidades = this.unidadesAux;
            this.messageService.add({
              key: 'uni',
              severity: 'error',
              summary: 'Inserción de Unidad',
              detail: 'La unidad no se insertó, el código ya existe'
            });
          });
        } else if (cont1 === 1) {
          this.actualizarUnidades(unidad).subscribe(() => {
            this.messageService.add({
              key: 'uni',
              severity: 'success',
              summary: 'Actualización de Unidad',
              detail: 'La unidad se actualizó correctamente'
            });
            this.auditoriagral.registrarAuditoria('INV_MAEUNIDAD', String(unidad.UNI_CODIGO), 'A', '',
              '01', '', '', '', '').subscribe(() => {
            });
          });
        }
      } else {
        if (unidad.UNI_CODIGO !== '' && unidad.UNI_NOMBRE !== '' && unidad.UNI_SIMBOLO !== '') {
          unidad.UNI_CODIGO = unidad.UNI_CODIGO.toUpperCase();
          unidad.UNI_NOMBRE = unidad.UNI_NOMBRE.toUpperCase();
          unidad.UNI_SIMBOLO = unidad.UNI_SIMBOLO.toUpperCase();
          this.guardarUnidades(unidad).subscribe(() => {
            this.messageService.add({
              key: 'uni',
              severity: 'success',
              summary: 'Inserción de Unidad',
              detail: 'La unidad se insertó correctamente'
            });
            this.auditoriagral.registrarAuditoria('INV_MAEUNIDAD', String(unidad.UNI_CODIGO), 'I', '',
              '01', '', '', '', '').subscribe(() => {
            });
          });
        } else {
          /*this.botonGuardar = false;
          this.botonRegresar = false;
          this.botonNuevo = true;*/
          this.messageService.add({
            key: 'uni',
            severity: 'error',
            summary: 'Inserción de Unidad',
            detail: 'Ingrese los campos obligatorios (*)'
          });
        }
      }
    } else {
        if (unidad.UNI_CODIGO !== '' && unidad.UNI_NOMBRE !== '' && unidad.UNI_SIMBOLO !== '') {
          unidad.UNI_CODIGO = unidad.UNI_CODIGO.toUpperCase();
          unidad.UNI_NOMBRE = unidad.UNI_NOMBRE.toUpperCase();
          unidad.UNI_SIMBOLO = unidad.UNI_SIMBOLO.toUpperCase();
          this.guardarUnidades(unidad).subscribe(() => {
            this.messageService.add({
              key: 'uni',
              severity: 'success',
              summary: 'Inserción de Unidad',
              detail: 'La unidad se insertó correctamente'
            });
            this.auditoriagral.registrarAuditoria('INV_MAEUNIDAD', String(unidad.UNI_CODIGO), 'I', '',
              '01', '', '', '', '').subscribe(() => {
            });
          });
        } else {
          /*this.botonGuardar = false;
          this.botonRegresar = false;
          this.botonNuevo = true;*/
          this.messageService.add({
            key: 'uni',
            severity: 'error',
            summary: 'Inserción de Unidad',
            detail: 'Ingrese los campos obligatorios (*)'
          });
        }
      }
    });
  }

  guardarUnidades(unidad: Invmaeunidad): Observable<any> {
    return this.http.post<any>( this.way + '/inventario/unidades/43cadb55bf5e4c00000', {
      elementos: {
        p_UNI_codigo_1: unidad.UNI_CODIGO,
        p_UNI_nombre_2: unidad.UNI_NOMBRE,
        p_UNI_simbolo_3: unidad.UNI_SIMBOLO,
        p_UNI_orden: unidad.UNI_ORDEN,
        p_COM_codigo_4: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  saveEquivalencia(equivalencia, unidad) {
    let contador = 0;
    let i = 0;
    this.getEquivalencias(equivalencia.UNI_CODIGO).subscribe((res) => {
      this.equivalenciasAux = res;
      if (this.equivalenciasAux.length === 0) {
        equivalencia.UNI_CODIGO = unidad.UNI_CODIGO;
        this.guardarEquivalencia(equivalencia).subscribe(() => {
          this.messageService.add({
            key: 'uni',
            severity: 'success',
            summary: 'Inserción de Equivalencia',
            detail: 'La equivalencia se insertó correctamente'
          });
          this.auditoriagral.registrarAuditoria('INV_MAEUNIDADEQUI', String(equivalencia.UNI_CODIGO), 'I', '',
            '01', '', '', '', '').subscribe(() => {});
        });
      } else {
        do {
          if (equivalencia.UNI_CODIGODESTINO === this.equivalenciasAux[i].UNI_CODIGODESTINO &&
            equivalencia.EQU_FACTOR === this.equivalenciasAux[i].EQU_FACTOR) {
            contador++;
          }
          i++;
        } while (this.equivalenciasAux.length > i);
        if (contador === 1) {
          let j = 0;
          let cont1 = 0;
          do {
            if (equivalencia.UNI_CODIGODESTINO === this.maeequivalencias[j].UNI_CODIGODESTINO &&
              equivalencia.EQU_FACTOR === this.maeequivalencias[j].EQU_FACTOR) {
              cont1++;
            }
            j++;
          } while (this.maeequivalencias.length > j);
          if (cont1 === 2) {
            this.getEquivalencias(unidad).subscribe((res1 => {
              this.equivalenciasAux = res1;
              this.maeequivalencias = this.equivalenciasAux;
              this.messageService.add({
                key: 'uni',
                severity: 'error',
                summary: 'Inserción de Equivalencia',
                detail: 'La equivalencia ya existe, por lo tanto no se ingreso'
              });
            }));
          } else if (cont1 === 1) {
            this.actualizarEquivalencias(equivalencia).subscribe(() => {
              this.messageService.add({
                key: 'uni',
                severity: 'success',
                summary: 'Actualización de Equivalencia',
                detail: 'La equivalencia se actualizó correctamente'
              });
              this.auditoriagral.registrarAuditoria('INV_MAEUNIDADEQUI', String(equivalencia.UNI_CODIGO), 'A', '',
                '01', '', '', '', '').subscribe(() => {});
            });
          }
        } else {
          if (equivalencia.EQU_FACTOR !== '' && equivalencia.UNI_CODIGODESTINO !== '' && equivalencia.EQU_CANTIDAD !== '') {
            this.guardarEquivalencia(equivalencia).subscribe(() => {
              this.messageService.add({
                key: 'uni',
                severity: 'success',
                summary: 'Inserción de Equivalencia',
                detail: 'La equivalencia se insertó correctamente'
              });
              this.auditoriagral.registrarAuditoria('INV_MAEUNIDADEQUI', String(equivalencia.UNI_CODIGO), 'I', '',
                '01', '', '', '', '').subscribe(() => {});
            });
          } else {
            /*this.botonGuardar = false;
            this.botonRegresar = false;
            this.botonNuevo = true;*/
            this.messageService.add({
              key: 'uni',
              severity: 'error',
              summary: 'Inserción de Equivalencia',
              detail: 'La equivalencia no se insertó, ingrese todos los campos'
            });
          }
        }
      }
    });
  }

  guardarEquivalencia(equi): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/unidades/8795b6ab6ed7a80', {
      elementos: {
        p_UNI_codigo_1: equi.UNI_CODIGO,
        p_COM_codigo_2: '01',
        p_EQU_factor_3: equi.EQU_FACTOR,
        p_EQU_cantidad_4: Number(equi.EQU_CANTIDAD),
        p_UNI_codigodestino_5: equi.UNI_CODIGODESTINO,
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarUnidades(unidad): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/unidades/299df2ab2', {
      elementos: {
        p_UNI_codigo_1: unidad.UNI_CODIGO,
        p_UNI_codigo_2: unidad.UNI_CODIGO,
        p_UNI_nombre_3: unidad.UNI_NOMBRE,
        p_UNI_simbolo_4: unidad.UNI_SIMBOLO,
        p_UNI_orden: unidad.UNI_ORDEN,
        p_COM_codigo_5: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarEquivalencias(equi): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/unidades/299df2ab2a', {
      elementos: {
        p_COM_codigo_2: '01',
        p_UNI_codigodestino_3: equi.UNI_CODIGODESTINO,
        p_UNI_codigo_1: equi.UNI_CODIGO,
        p_COM_codigo_5: '01',
        p_EQU_factor_6: equi.EQU_FACTOR,
        p_EQU_cantidad_7: equi.EQU_CANTIDAD,
        p_UNI_codigodestino_8: equi.UNI_CODIGODESTINO
      }
    }, this.confIniciales.httpOptions());
  }

  borrarUnidad(unidad): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/unidades/5e37b56fd793540', {
      elementos: {
        p_UNI_codigo_1: unidad,
        p_COM_codigo_2: '01'
      },
    }, this.confIniciales.httpOptions());
  }

  borrarEquivalencia(equi): Observable<any> {
    return this.http.post<any>( this.way + '/inventario/unidades/2f1bdab6ed7a60', {
      elementos: {
        p_UNI_codigo_1: equi.UNI_CODIGO,
        p_COM_codigo_2: '01',
        p_UNI_codigodestino_3: equi.UNI_CODIGODESTINO
      },
    }, this.confIniciales.httpOptions());
  }

  getEquivalencias(uniCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/unidades/83baed7a5f', {
      elementos: {
        UNI_CODIGO: uniCodigo,
      }
    }, this.confIniciales.httpOptions());
  }
}
