import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Usuario} from '../../usuario';
import {CxcTrnpago} from '../../interfaces/cxctrnpago';
import {Venencdevfac} from '../veninterfaces/venencdevfac';
import {CxcTrncobro} from '../../interfaces/cxctrncobro';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DatePipe} from '@angular/common';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {VenencfacService} from '../venservicios/venencfac.service';
import {PagocontadodvfService} from '../venservicios/pagocontadodvf.service';

// Ag-grid
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {AggridBanComponent} from '../../ban/aggrid-ban/aggrid-ban.component';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';

@Component({
  selector: 'app-pagocontadodvf',
  templateUrl: './pagocontadodvf.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class PagocontadodvfComponent implements OnInit, OnChanges {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  @Input() auxiliar: number;
  @Input() confPago: string;
  @Input() encdvf: Venencdevfac;
  @Input() totalApagar: number;
  @Input() displayPago: boolean;
  @Output() guardarCorrecto = new EventEmitter<boolean>();
  @Output() eliminarCorrecto = new EventEmitter<boolean>();
  @Output() salir = new EventEmitter<boolean>();
  index: number;
  largo: string;
  largo2: string;
  
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  barraBotones2: boolean;
  displayDialogAsiento: boolean;
  displayAsiContable: boolean;
  choosedColorG: string;
  choosedColorY: string;
  pagarTotal: number;
  totalFacturasPend: number;
  totalFormaPago: number;
  totalImporte: number;
  botonNuevoGrid: boolean;
  botonBorrarGrid: boolean;
  botonRegresarGrid: boolean;
  tipoClave: string;
  saldo: string;
  efectivo: string;
  cambio: string;
  
  pagoSeleccionado: CxcTrnpago;
  
  hmpConfiguracion: any[];
  
  strTipoDoc = '';
  
  claveDialog: boolean;
  claveAuth: string;
  asiento: string;
  
  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  defaultColDef;
  defaultColDefFacP;
  columnDefsPago = [
    { // 0
      headerName: 'Tipo', field: 'TRNPAGO_TIPO', cellEditor: 'cellPrueba', width: 80,
      // editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 10,
            opcionbuscar: true
            
          }
        }
    }, { // 1
      headerName: 'Caja', field: 'CAJ_CODIGO', cellEditor: 'cellPrueba', width: 100,
      // editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            clave: false,
            tamanocelda: 10
          }
        }
    }, { // 2
      headerName: 'Banco', field: 'BANCLI_CODIGO', cellEditor: 'cellPrueba', width: 100,
      // editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            clave: false,
            tamanocelda: 10
          }
        }
    }, { // 3
      headerName: 'Nombre Banco', field: 'BANCLI_NOMBRE', cellEditor: 'cellPrueba', width: 150,
      // editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 4,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: true,
            clave: false,
            tamanocelda: 10
          }
        }
    }, { // 4
      headerName: 'Número', field: 'TRNPAGO_NUMERO', cellEditor: 'cellPrueba', width: 150, onCellClicked: () => {
        this.calcularTotal();
      },
      // editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            clave: false,
            tamanocelda: 10
          }
        }
    }, { // 5
      headerName: 'Fecha', field: 'TRNPAGO_FECHA', cellEditor: 'cellPrueba', width: 110,
      // editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            clave: false,
            tamanocelda: 10
          }
        }
    }, { // 6
      headerName: 'Concepto', field: 'TRNPAGO_CONCEPTO', cellEditor: 'cellPrueba', width: 130,
      // editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            clave: false,
            tamanocelda: 50
          }
        }
    }, { // 7
      headerName: 'Importe', field: 'TRNPAGO_IMPORTE', cellEditor: 'cellPrueba', width: 90, cellStyle: {textAlign: 'right'},
      // editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: true,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 10
          }
        }
    }];
  
  columnDefsFacPendientes = [
    {
      headerName: '',
      field: 'SELECCIONADO',
      cellRendererFramework: AggridBanComponent,
      editable: false,
      width: 50,
      onCellClicked: (params) => {
        this.seleccionFacturas(params);
      }
    },
    { // 0
      headerName: 'Número', field: 'TRNCOBRO_NRODOC', editable: false, width: 150,
    }, { // 1
      headerName: 'F. Emisión', field: 'TRNCOBRO_FECHATRN', editable: false, width: 100,
    }, { // 2
      headerName: 'F. Vence', field: 'TRNCOBRO_FECHAVENCE', editable: false, width: 100,
    }, { // 3
      headerName: 'Saldo', field: 'SALDO', editable: false, width: 100, cellStyle: {textAlign: 'right'},
    }, { // 4
      headerName: 'Importe', field: 'TRNCOBRO_IMPORTE',
      editable: (params) => {
        return params.data.SELECCIONADO;
      }, width: 100, cellEditor: 'cellPrueba',
      cellStyle: {textAlign: 'right'},
      cellEditorParams: {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          int: true
        }
      }
    }
  ];
  
  constructor( public pagconservice: PagocontadodvfService,private datePipe: DatePipe, private message: MessageService,
              private auditoriagral: AuditoriagralService, public encfacService: VenencfacService,
              private confirmationService: ConfirmationService, public usuario: Usuario) {
    this.agTable();
    this.pagconservice.pagos = [];
    this.pagconservice.resumen = [];
    this.usuario.loadStorage();
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    
    this.defaultColDef = {
      editable: true,
      width: 80,
      objeto: 'pagcondvf',
      resizable: true
    };
    
    this.defaultColDefFacP = {
      editable: true,
      width: 80,
      objeto: 'facpendientes',
      resizable: true
    };
  }
  
  ngOnInit() {
    this.index = 1;
    this.pagconservice.resumen = [];
    this.pagconservice.pagos = [];
    this.displayDialogAsiento = false;
    this.displayAsiContable = false;
    this.asiento = '';
    this.largo = '650';
    this.largo2 = '100';
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = true;
    this.botonRegresar = true;
    this.barraBotones2 = true;
    this.claveAuth = '';
    this.claveDialog = false;
    this.choosedColorG = '#00ffa0';
    this.choosedColorY = '#ffff00';
    this.pagarTotal = 0;
    this.totalFacturasPend = 0;
    this.tipoClave = '';
    this.totalImporte = 0;
    this.totalFormaPago = 0;
    this.totalFacturasPend = 0;
  }
  
  limpiarDatos(): void {
    this.pagarTotal = 0;
    this.totalFacturasPend = 0;
    this.tipoClave = '';
    this.totalImporte = 0;
    this.totalFormaPago = 0;
    this.totalFacturasPend = 0;
    this.pagconservice.resumen = [];
    this.pagconservice.pagos = [];
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    for (const e in changes) {
      if (e === 'auxiliar' && this.encdvf !== null) {
        this.cancelarPago();
      }
    }
  }
  
  consultarPagoDev() {
    this.botonBorrar = false;
    this.pagconservice.consultarFacturasUtilizadas(this.encdvf.ENCDVF_NUMERO).subscribe((res1) => {
      document.getElementById('contorno').style.pointerEvents = 'none';
      if (res1.length !== 0) {
        res1.map((doc) => {
          this.pagconservice.consultarFacturasInformacion(doc.TRNDOCUMENTO_NRODOC).subscribe((fac) => {
            doc.TRNCOBRO_NRODOC = doc.TRNDOCUMENTO_NRODOC;
            doc.TRNCOBRO_IMPORTE = doc.TRNDOCUMENTO_IMPORTE;
            doc.TRNCOBRO_FECHATRN = this.datePipe.transform(fac[0].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
            doc.TRNCOBRO_FECHAVENCE = this.datePipe.transform(fac[0].TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
            doc.SALDO = Number(fac[0].SALDO).toFixed(4);
            doc.TRNCOBRO_IMPORTE = Math.abs(Number(doc.TRNCOBRO_IMPORTE)).toFixed(2);
            doc.SELECCIONADO = true;
            this.pagconservice.resumen.push(doc);
            this.aggrid.refreshaggrid(this.pagconservice.resumen, this.defaultColDefFacP.objeto);
          });
        });
      }
      this.pagconservice.consultarPagosEfectivo(this.encdvf.ENCDVF_NUMERO).subscribe((res2) => {
        if (res2.length !== 0) {
          res2.map((caja) => {
            caja.TRNPAGO_TIPO = caja.TRNCAJ_TIPO;
            caja.TRNPAGO_NUMERO = caja.TRNCAJ_NUMERO;
            caja.TRNPAGO_FECHA = this.datePipe.transform(caja.TRNCAJ_FECHA, 'dd/MM/yyyy');
            caja.TRNPAGO_CONCEPTO = caja.TRNCAJ_DESCRIPCION;
            caja.TRNPAGO_IMPORTE = Math.abs(Number(caja.TRNCAJ_IMPORTE));
          });
          if (this.pagconservice.pagos.length !== 0) {
            res2.map((caja1) => {
              this.pagconservice.pagos.splice(this.pagconservice.pagos.length, 0, caja1);
            });
            this.calcularTotal();
          } else if (this.pagconservice.pagos.length === 0) {
            this.pagconservice.pagos = res2;
            this.calcularTotal();
            return;
          }
        }
      });
      this.pagconservice.consultarPagosCheque(this.encdvf.ENCDVF_NUMERO).subscribe((res3) => {
        if (res3.length !== 0) {
          res3.map((banco) => {
            banco.TRNPAGO_TIPO = banco.TRNBAN_TIPO;
            banco.TRNPAGO_NUMERO = banco.TRNBAN_NUMERO;
            banco.TRNPAGO_FECHA = this.datePipe.transform(banco.TRNBAN_FECHA, 'dd/MM/yyyy');
            banco.TRNPAGO_CONCEPTO = banco.TRNBAN_CONCEPTO;
            banco.TRNPAGO_IMPORTE = Math.abs(Number(banco.TRNBAN_IMPORTE));
            banco.BANCLI_CODIGO = banco.BAN_CODIGO;
          });
          if (this.pagconservice.pagos.length !== 0) {
            res3.map((banco1) => {
              this.pagconservice.pagos.splice(this.pagconservice.pagos.length, 0, banco1);
            });
            this.calcularTotal();
          } else {
            this.pagconservice.pagos = res3;
            this.calcularTotal();
            return;
          }
        }
      });
      this.pagconservice.consultarPagosAnticipo(this.encdvf.ENCDVF_NUMERO).subscribe((res4) => {
        if (res4.length !== 0) {
          res4.map((anc) => {
            anc.TRNPAGO_TIPO = 'ANCL';
            anc.TRNPAGO_NUMERO = anc.TRNCOBRO_NROCOMPROBANTE;
            anc.TRNPAGO_FECHA = this.datePipe.transform(anc.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
            anc.TRNPAGO_CONCEPTO = anc.TRNCOBRO_CONCEPTO;
            anc.TRNPAGO_IMPORTE = Math.abs(Number(anc.TRNCOBRO_IMPORTE));
          });
          if (this.pagconservice.pagos.length !== 0) {
            res4.map((anc1) => {
              this.pagconservice.pagos.splice(this.pagconservice.pagos.length, 0, anc1);
            });
            this.calcularTotal();
          } else {
            this.pagconservice.pagos = res4;
            this.calcularTotal();
            return;
          }
        }
      });
      this.calcularTotal();
    });
  }
  
  manejarSenales(valor) {
    if (valor === 'Guardar') {
      this.comprobarEstado();
    }
    
    if (valor === 'Borrar') {
      if (this.encdvf.ENCDVF_ESTADO_FE !== '' && this.encdvf.ENCDVF_ESTADO_FE !== null) {
        this.confirmationService.confirm({
          message: 'Esta seguro de eliminar la forma de pago del documento que esta en proceso de autorización',
          header: 'Pregunta',
          icon: 'pi pi-exclamation-triangle',
          key: 'pagcondvfConf',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.claveDialog = true;
            this.tipoClave = 'eliminar';
          },
          reject: () => {
          }
        });
      } else {
        this.claveDialog = true;
        this.tipoClave = 'eliminarnoFE';
      }
    }
    
    if (valor === 'Cancelar') {
      this.cancelarPago();
    }
    
    if (valor === 'AsientoVer') {
      this.asiento = this.encdvf.ASI_NRO;
      if (this.asiento === null || this.asiento === '') {
        this.message.add({
          key: 'devolucion',
          severity: 'error',
          summary: 'Visualizacion de Asiento Contable',
          detail: 'Esta devolución no tiene un asiento contable'
        });
      } else {
        // this.displayDialogAsiento = true;
        this.displayAsiContable = true;
      }
    }
    
    if (valor === 'Salir') {
      this.salir.emit(true);
    }
    
  }
  
  cambiosbotones(event) {
    if (event === true) {
      this.botonBorrarGrid = false;
    }
  }
  
  seleccionpago(params) {
    if (params === 'nuevo') {
      this.nuevo();
      return;
    }
    if (params === 'cancelar') {
      this.cancelar();
      return;
    }
    if (params === 'eliminar') {
      this.eliminar();
      return;
    }
    if (params.object === undefined) {
      return;
    }
    this.pagoSeleccionado = params.object;
    this.calcularTotal();
    if (this.pagoSeleccionado.CLAVE === undefined) {
      this.pagoSeleccionado.CLAVE = this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
    }
    this.pagconservice.auxsalto = false;
  }
  
  seleccionFacturas(valor) {
    this.botonGuardar = false;
    if (valor.data.SELECCIONADO === true) {
      valor.data.TRNCOBRO_IMPORTE = valor.data.SALDO;
    } else {
      valor.data.TRNCOBRO_IMPORTE = 0;
    }
    this.aggrid.refreshaggrid(this.pagconservice.resumen, this.defaultColDefFacP.objeto);
    const indi = this.pagconservice.resumen.indexOf(valor.data);
    if (indi === -1) {
      this.aggrid.gridApi.setFocusedCell(0, 'SELECCIONADO');
    } else {
      this.aggrid.gridApi.setFocusedCell(indi, 'SELECCIONADO');
    }
    this.calcularTotal();
  }
  
  
  calcularTotal() {
    if (this.pagconservice.resumen.length !== 0) {
      this.totalFormaPago = 0;
      this.pagarTotal = 0;
      this.totalFacturasPend = 0;
      let dblTotal = 0;
      this.pagconservice.pagos.map((pago) => {
        if (pago.TRNPAGO_TIPO === 'EF') {
          this.totalFormaPago += Number(pago.TRNPAGO_IMPORTE);
          dblTotal = Number(this.totalFacturasPend) + Number(this.totalFormaPago);
          this.pagarTotal = Number(dblTotal.toFixed(2));
        } else if (pago.TRNPAGO_TIPO === 'CH' && pago.BANCLI_CODIGO !== '') {
          this.totalFormaPago += Number(pago.TRNPAGO_IMPORTE);
          dblTotal = Number(this.totalFacturasPend) + Number(this.totalFormaPago);
          this.pagarTotal = Number(dblTotal.toFixed(2));
        } else if (pago.TRNPAGO_TIPO === 'ANCL') {
          this.totalFormaPago += Number(pago.TRNPAGO_IMPORTE);
          dblTotal = Number(this.totalFacturasPend) + Number(this.totalFormaPago);
          this.pagarTotal = Number(dblTotal.toFixed(2));
        }
      });
      this.pagconservice.resumen.map((resu) => {
        if (resu.SELECCIONADO === true) {
          this.totalFacturasPend += Number(resu.TRNCOBRO_IMPORTE);
          dblTotal = Number(this.totalFacturasPend) + Number(this.totalFormaPago);
          this.pagarTotal = Number(dblTotal.toFixed(2));
        }
      });
    } else {
      this.totalFormaPago = 0;
      this.pagarTotal = 0;
      this.totalFacturasPend = 0;
      let dblTotal = 0;
      this.pagconservice.pagos.map((pago) => {
        if (pago.TRNPAGO_TIPO === 'EF') {
          this.totalFormaPago += Number(pago.TRNPAGO_IMPORTE);
          dblTotal = Number(this.totalFacturasPend) + Number(this.totalFormaPago);
          this.pagarTotal = Number(dblTotal.toFixed(2));
        } else if (pago.TRNPAGO_TIPO === 'CH' && pago.BANCLI_CODIGO !== '') {
          this.totalFormaPago += Number(pago.TRNPAGO_IMPORTE);
          dblTotal = Number(this.totalFacturasPend) + Number(this.totalFormaPago);
          this.pagarTotal = Number(dblTotal.toFixed(2));
        } else if (pago.TRNPAGO_TIPO === 'ANCL') {
          this.totalFormaPago += Number(pago.TRNPAGO_IMPORTE);
          dblTotal = Number(this.totalFacturasPend) + Number(this.totalFormaPago);
          this.pagarTotal = Number(dblTotal.toFixed(2));
        }
      });
      this.pagconservice.resumen.map((resu) => {
        if (resu.SELECCIONADO === true) {
          this.totalFacturasPend += Number(resu.TRNCOBRO_IMPORTE);
          dblTotal = Number(this.totalFacturasPend) + Number(this.totalFormaPago);
          this.pagarTotal = Number(dblTotal.toFixed(2));
        }
      });
    }
    this.totalFacturasPend = Number(this.totalFacturasPend.toFixed(2));
    this.totalFormaPago = Number(this.totalFormaPago.toFixed(2));
    this.pagarTotal = Number(this.pagarTotal.toFixed(2));
  }
  
  comprobarEstado() {
    if (this.encdvf.ENCDVF_ESTADO_FE !== '' && this.encdvf.ENCDVF_ESTADO_FE !== null) {
      this.confirmationService.confirm({
        message: 'Esta seguro de modificar el documento que esta en proceso de autorización',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'pagcondvfConf',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.claveDialog = true;
          this.tipoClave = 'guardar';
        },
        reject: () => {
        }
      });
    } else {
      this.guardarRegistro();
    }
  }
  
  comprobarClave() {
    if (this.tipoClave === 'eliminar') {
      this.pagconservice.obtenerClave('MODFPDE').subscribe((clave) => {
        let claveReal;
        claveReal = clave[0].AUTORIZACION_CLAVE;
        if (claveReal === this.claveAuth) {
          this.claveDialog = false;
          this.auditoriagral.registrarAuditoria('VEN_ENCDVF', String(this.encdvf.ENCDVF_NUMERO) + 'VEN_ELI-FP-DE', 'E',
            '', '01', '', '', '', '').subscribe(() => {
          });
          this.claveAuth = '';
          this.reversarPago();
        } else if (clave !== this.claveAuth) {
          this.message.add({
            key: 'pagcondvf',
            severity: 'error',
            summary: 'Clave Autorización',
            detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
          });
        }
      });
    } else if (this.tipoClave === 'eliminarnoFE') {
      this.pagconservice.obtenerClave('REVPAGDV').subscribe((clave) => {
        let claveReal;
        claveReal = clave[0].AUTORIZACION_CLAVE;
        if (claveReal === this.claveAuth) {
          this.claveDialog = false;
          this.claveAuth = '';
          this.confirmationService.confirm({
            message: 'Esta seguro de reversar el pago',
            header: 'Pregunta',
            icon: 'pi pi-exclamation-triangle',
            key: 'pagcondvfConf',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            accept: () => {
              this.reversarPago();
            },
            reject: () => {
            }
          });
        } else if (clave !== this.claveAuth) {
          this.message.add({
            key: 'pagcondvf',
            severity: 'error',
            summary: 'Clave Autorización',
            detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
          });
        }
      });
    } else if (this.tipoClave === 'guardar') {
      this.pagconservice.obtenerClave('MODFPDE').subscribe((clave) => {
        let claveReal;
        claveReal = clave[0].AUTORIZACION_CLAVE;
        if (claveReal === this.claveAuth) {
          this.claveDialog = false;
          this.auditoriagral.registrarAuditoria('VEN_ENCDVF', String(this.encdvf.ENCDVF_NUMERO) + 'VEN_MODDEV-E', 'A',
            '', '01', '', '', '', '').subscribe(() => {
          });
          this.claveAuth = '';
          this.guardarRegistro();
        } else if (clave !== this.claveAuth) {
          this.message.add({
            key: 'pagcondvf',
            severity: 'error',
            summary: 'Clave Autorización',
            detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
          });
        }
      });
    }
  }
  
  insertarCancFactPend() {
    let fecha = '';
    if (this.encdvf.ENCDVF_FECHAEMISION.toString().includes('/')) {
      fecha = this.encdvf.ENCDVF_FECHAEMISION.toString().substr(0, 10);
    } else {
      fecha = this.datePipe.transform(this.encdvf.ENCDVF_FECHAEMISION, 'dd/MM/yyyy');
    }
    
    if (this.pagconservice.resumen.length !== 0) {
      const item: any = {};
      let facpendSeleccionadas = 0;
      
      item.APLORG_CODIGO = 'VEN';
      item.ASI_NRO = '';
      item.CLI_CODIGO = this.encdvf.CLI_CODIGO;
      item.VEN_CODIGO = '';
      item.CON_CODIGO = '';
      item.BAN_CODIGO = '';
      item.COM_CODIGO = '01';
      item.TRNCOBRO_EFECTIVO = 0;
      item.TRNCOBRO_CAMBIO = 0;
      item.TRNCOBRO_CONCEPTO = 'PAGO DEVOLUCION ' + this.encdvf.ENCDVF_NUMERO + ' DE LA FACT. ' + this.encdvf.ENCDVF_REFERENCIA;
      item.TRNCOBRO_FECHATRN = fecha;
      item.TRNCOBRO_FLAG = '1';
      item.TRNCOBRO_NROCOMPROBANTE = '';
      item.TRNCOBRO_NRODOC = '';
      item.TRNCOBRO_TIPODOC = 'CA';
      item.TRNCOBRO_IMPORTE = Number(this.totalFacturasPend);
      item.USU_IDENTIFICACION = this.usuario.identificacion;
      item.TRNCOBRO_REFERENCIAEXTERNA = this.encdvf.ENCDVF_NUMERO;
      item.TRNCOBRO_FECHAVENCE = '';
      item.BOD_CODIGO = '';
      item.TRNCOBRO_SALDOINI = 0;
      
      this.pagconservice.insertarTrnCobro(item).subscribe((res) => {
        this.pagconservice.resumen.map((itemFac) => {
          if (itemFac.SELECCIONADO === true) {
            facpendSeleccionadas++;
            item.TRNCOBRO_NRODOC = itemFac.TRNCOBRO_NRODOC; // número de la factura
            item.TRNCOBRO_IMPORTE = itemFac.TRNCOBRO_IMPORTE; // importe de la factura
            if (res.length !== 0) {
              const strNroDoc = res[0].TRNCOBRO_NRODOC;
              const strNroComprobante = res[0].TRNCOBRO_NROCOMPROBANTE;
              this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', strNroDoc + '/' + strNroComprobante, 'I',
                '', '01', '', '', '', '').subscribe(() => {
              });
              const CUOTAFAC = 'F';
              this.pagconservice.insertarTrnDocumento(strNroDoc, strNroComprobante, item, CUOTAFAC).subscribe((res2) => {
                this.pagconservice.actualizaEstadoFactura(itemFac.TRNCOBRO_NRODOC, this.encdvf.CLI_CODIGO).subscribe(() => {
                });
              });
            }
          }
        });
        
        this.insertarOtrFP();
      });
    } else {
      if (this.pagconservice.pagos.length !== 0) {
        this.pagconservice.pagos.map((pago) => {
          if (pago.TRNPAGO_CONCEPTO === '' || pago.TRNPAGO_CONCEPTO === null || pago.TRNPAGO_CONCEPTO === undefined) {
            pago.TRNPAGO_CONCEPTO = '*';
          }
          if (pago.TRNPAGO_TIPO === 'EF') {
            this.pagconservice.insertarCaja(pago, this.encdvf.ENCDVF_NUMERO).subscribe(() => {
              this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', pago.TRNCOBRO_NRODOC + '/' + this.encdvf.ENCDVF_NUMERO, 'I',
                '', '01', '', '', '', '').subscribe(() => {
              });
            });
          } else if (pago.TRNPAGO_TIPO === 'CH') {
            this.pagconservice.insertarBanco(pago, this.encdvf.ENCDVF_NUMERO, this.encdvf.CLI_NOMBRE).subscribe(() => {
              this.pagconservice.actualizaUltimoCheque(pago.BANCLI_CODIGO, pago.TRNPAGO_NUMERO).subscribe(() => {
              });
              this.auditoriagral.registrarAuditoria('BAN_TRNBAN', pago.TRNCOBRO_NRODOC + '/' + this.encdvf.ENCDVF_NUMERO, 'I',
                '', '01', '', '', '', '').subscribe(() => {
              });
            });
          } else if (pago.TRNPAGO_TIPO === 'ANCL') {
            const ancl: CxcTrncobro = {};
            ancl.APLORG_CODIGO = 'VEN';
            ancl.ASI_NRO = '';
            ancl.CLI_CODIGO = this.encdvf.CLI_CODIGO;
            ancl.VEN_CODIGO = '';
            ancl.CON_CODIGO = '';
            ancl.BAN_CODIGO = '';
            ancl.COM_CODIGO = '01';
            ancl.TRNCOBRO_EFECTIVO = 0;
            ancl.TRNCOBRO_CAMBIO = 0;
            ancl.TRNCOBRO_CONCEPTO = pago.TRNPAGO_CONCEPTO;
            ancl.TRNCOBRO_FECHATRN = fecha;
            ancl.TRNCOBRO_FLAG = '1';
            ancl.TRNCOBRO_NROCOMPROBANTE = pago.TRNPAGO_NUMERO;
            ancl.TRNCOBRO_NRODOC = pago.TRNCOBRO_NRODOC;
            ancl.TRNCOBRO_TIPODOC = 'ANC';
            ancl.TRNCOBRO_IMPORTE = pago.TRNPAGO_IMPORTE;
            ancl.USU_IDENTIFICACION = this.usuario.identificacion;
            ancl.TRNCOBRO_REFERENCIAEXTERNA = this.encdvf.ENCDVF_NUMERO;
            ancl.TRNCOBRO_FECHAVENCE = '';
            ancl.BOD_CODIGO = '';
            ancl.TRNCOBRO_SALDOINI = 0;
            
            this.pagconservice.insertarTrnCobro(ancl).subscribe((res6) => {
              if (res6.length !== 0) {
                const strNroDoc1 = res6[0].TRNCOBRO_NRODOC;
                const strNroComprobante1 = res6[0].TRNCOBRO_NROCOMPROBANTE;
                this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', strNroDoc1 + '/' + strNroComprobante1, 'I',
                  '', '01', '', '', '', '').subscribe(() => {
                });
              }
            });
          }
        });
        this.guardarCorrecto.emit(true);
      }
    }
  }
  
  insertarOtrFP() {
    let fecha = '';
    if (this.encdvf.ENCDVF_FECHAEMISION.toString().includes('/')) {
      fecha = this.encdvf.ENCDVF_FECHAEMISION.toString().substr(0, 10);
    } else {
      fecha = this.datePipe.transform(this.encdvf.ENCDVF_FECHAEMISION, 'dd/MM/yyyy');
    }
    if (this.pagconservice.pagos.length !== 0) {
      this.pagconservice.pagos.map((pago) => {
        if (pago.TRNPAGO_CONCEPTO === '' || pago.TRNPAGO_CONCEPTO === null || pago.TRNPAGO_CONCEPTO === undefined) {
          pago.TRNPAGO_CONCEPTO = '*';
        }
        if (pago.TRNPAGO_TIPO === 'EF') {
          this.pagconservice.insertarCaja(pago, this.encdvf.ENCDVF_NUMERO).subscribe(() => {
            this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', pago.TRNCOBRO_NRODOC + '/' + this.encdvf.ENCDVF_NUMERO, 'I',
              '', '01', '', '', '', '').subscribe(() => {
              
            });
          });
        } else if (pago.TRNPAGO_TIPO === 'CH') {
          this.pagconservice.insertarBanco(pago, this.encdvf.ENCDVF_NUMERO, this.encdvf.CLI_NOMBRE).subscribe(() => {
            this.pagconservice.actualizaUltimoCheque(pago.BANCLI_CODIGO, pago.TRNPAGO_NUMERO).subscribe(() => {
            });
            this.auditoriagral.registrarAuditoria('BAN_TRNBAN', pago.TRNCOBRO_NRODOC + '/' + this.encdvf.ENCDVF_NUMERO, 'I',
              '', '01', '', '', '', '').subscribe(() => {
            });
          });
        } else if (pago.TRNPAGO_TIPO === 'ANCL') {
          const ancl: CxcTrncobro = {};
          ancl.APLORG_CODIGO = 'VEN';
          ancl.ASI_NRO = '';
          ancl.CLI_CODIGO = this.encdvf.CLI_CODIGO;
          ancl.VEN_CODIGO = '';
          ancl.CON_CODIGO = '';
          ancl.BAN_CODIGO = '';
          ancl.COM_CODIGO = '01';
          ancl.TRNCOBRO_EFECTIVO = 0;
          ancl.TRNCOBRO_CAMBIO = 0;
          ancl.TRNCOBRO_CONCEPTO = pago.TRNPAGO_CONCEPTO;
          ancl.TRNCOBRO_FECHATRN = fecha;
          ancl.TRNCOBRO_FLAG = '1';
          ancl.TRNCOBRO_NROCOMPROBANTE = pago.TRNPAGO_NUMERO;
          ancl.TRNCOBRO_NRODOC = pago.TRNCOBRO_NRODOC;
          ancl.TRNCOBRO_TIPODOC = 'ANC';
          ancl.TRNCOBRO_IMPORTE = pago.TRNPAGO_IMPORTE;
          ancl.USU_IDENTIFICACION = this.usuario.identificacion;
          ancl.TRNCOBRO_REFERENCIAEXTERNA = this.encdvf.ENCDVF_NUMERO;
          ancl.TRNCOBRO_FECHAVENCE = '';
          ancl.BOD_CODIGO = '';
          ancl.TRNCOBRO_SALDOINI = 0;
          
          this.pagconservice.insertarTrnCobro(ancl).subscribe((res4) => {
            if (res4.length !== 0) {
              const strNroDoc1 = res4[0].TRNCOBRO_NRODOC;
              const strNroComprobante1 = res4[0].TRNCOBRO_NROCOMPROBANTE;
              this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', strNroDoc1 + '/' + strNroComprobante1, 'I',
                '', '01', '', '', '', '').subscribe(() => {
              });
            }
          });
        }
        this.guardarCorrecto.emit(true);
      });
    } else {
      this.guardarCorrecto.emit(true);
    }
  }
  
  async guardarRegistro() {
    let errores = 0;
    if (Number(this.pagarTotal) === 0) {
      this.message.add({
        key: 'pagcondvf',
        severity: 'error',
        summary: 'Pago',
        detail: 'El valor del total a pagar es igual a cero'
      });
      errores++;
      return;
    }
    
    if (Number(this.totalApagar) !== Number(this.pagarTotal)) {
      this.message.add({
        key: 'pagcondvf',
        severity: 'error',
        summary: 'Pago',
        detail: 'El total a pagar es distinto al saldo de la devolución'
      });
      errores++;
      return;
    }
    
    for (const pago of this.pagconservice.pagos ){
      //this.pagconservice.pagos.map((pago) => {
         //CDPJ
         let numero=0
         const exispago= await this.pagconservice.erpExisFormaPago(pago.BANCLI_CODIGO,pago.TRNPAGO_TIPO,pago.TRNPAGO_NUMERO);
         if(exispago !== null){
           numero=Number(exispago[0].NUMERO)
         }
         //CDPJ
        if (Number(pago.TRNPAGO_IMPORTE) <= 0) {
          this.message.add({
            key: 'pagcondvf',
            severity: 'error',
            summary: 'Pago',
            detail: 'El valor de importe debe ser mayor 0'
          });
          errores++;
          return;
        }
        //CDPJ
        if (pago.TRNPAGO_TIPO === 'CH' && numero > 0) {
          this.message.add({
            key: 'pagcondvf',
            severity: 'error',
            summary: 'Pago',
            detail: 'El número de comprobante ya existe.'
          });
          errores++;
          return;
        }
        //CDPJ
      };//);
    
      for (const itemFac of this.pagconservice.resumen){
        //this.pagconservice.resumen.map((itemFac) => {
          if (itemFac.SELECCIONADO === true) {
            if (Number(itemFac.TRNCOBRO_IMPORTE) > Number(itemFac.SALDO)) {
              this.message.add({
                key: 'pagcondvf',
                severity: 'error',
                summary: 'Pago',
                detail: 'El valor de importe no debe superar al saldo del documento'
              });
              errores++;
            }
          }
        };//);
    
    if (errores === 0) {
      this.insertarCancFactPend();
    }
  }
  
  reversarPago() {
    if (this.pagconservice.pagos.length !== 0) {
      let saldoAnt = 0;
      this.pagconservice.pagos.map((fpago) => {
        if (fpago.TRNPAGO_TIPO === 'ANCL') {
          this.pagconservice.verificarSaldoAnticipo(fpago.TRNPAGO_NUMERO).subscribe((res) => {
            console.log(res)
            console.log(res[0])
            if(res[0] !== undefined && res[0] !== null){
              saldoAnt = Number(res[0].SALDO) * (-1);
            if (saldoAnt !== 0 && saldoAnt !== Number(fpago.TRNPAGO_IMPORTE)) {
              this.message.add({
                key: 'pagcondvf',
                severity: 'error',
                summary: 'Reversar Pago',
                detail: 'No se puede reversar el pago, el anticipo ' + fpago.TRNPAGO_NUMERO + ' ya fue utilizado'
              });
              return;
            } else {
              this.pagconservice.eliminarCobro(fpago.TRNPAGO_NUMERO).subscribe(() => {
                this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', fpago.TRNPAGO_NUMERO+ '/' + this.encdvf.ENCDVF_NUMERO, 'E',
                  '', '01', '', '', '', '').subscribe(() => {
                  this.eliminarCorrecto.emit(true);
                });
              });
            }
            }else {
              this.pagconservice.eliminarCobro(fpago.TRNPAGO_NUMERO).subscribe(() => {
                this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', fpago.TRNPAGO_NUMERO+ '/' + this.encdvf.ENCDVF_NUMERO, 'E',
                  '', '01', '', '', '', '').subscribe(() => {
                  this.eliminarCorrecto.emit(true);
                });
              });
            }
            
          });
        } else if (fpago.TRNPAGO_TIPO === 'CH') {
          this.pagconservice.erreversarpago(fpago.TRNPAGO_NUMERO, fpago.BANCLI_CODIGO , fpago.TRNPAGO_TIPO).subscribe((res2) => {
            if (res2[0].TRNBAN_CONCILIA === '1') {
              this.message.add({
                key: 'pagcondvf',
                severity: 'error',
                summary: 'Reversar Pago',
                detail: 'No se puede reversar el pago, el cheque ' + fpago.TRNPAGO_NUMERO + ' esta conciliado'
              });
              return;
            } else {
              this.pagconservice.eliminarBanco(this.encdvf.ENCDVF_NUMERO, fpago).subscribe(() => {
                this.auditoriagral.registrarAuditoria('BAN_TRNBAN', fpago.TRNPAGO_NUMERO + '/' + this.encdvf.ENCDVF_NUMERO, 'E',
                  '', '01', '', '', '', '').subscribe(() => {
                  this.eliminarCorrecto.emit(true);
                });
              });
            }
          });
        } else if (fpago.TRNPAGO_TIPO === 'EF') {
          this.pagconservice.eliminarCaja(this.encdvf.ENCDVF_NUMERO, fpago).subscribe(() => {
            this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', fpago.TRNPAGO_NUMERO + '/' + this.encdvf.ENCDVF_NUMERO, 'E',
              '', '01', '', '', '', '').subscribe(() => {
              this.eliminarCorrecto.emit(true);
            });
          });
        }
      });
    }
    if (this.pagconservice.resumen.length !== 0) {
      this.pagconservice.resumen.map((resum) => {
        if (resum.SELECCIONADO === true) {
          this.pagconservice.eliminarFacturasPendientes(resum.TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
            this.pagconservice.eliminarCobro(resum.TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
              this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', resum.TRNCOBRO_NROCOMPROBANTE+ '/' + this.encdvf.ENCDVF_NUMERO, 'E',
                '', '01', '', '', '', '').subscribe(() => {
                this.pagconservice.actualizaEstadoFactura(resum.TRNCOBRO_NRODOC, this.encdvf.CLI_CODIGO).subscribe(() => {
                  this.eliminarCorrecto.emit(true);
                });
              });
            });
          });
        }
      });
    }
  }
  
  nuevo() {
    this.botonNuevoGrid = true;
    this.botonGuardar = false;
    const linea = this.pagconservice.pagos.length;
    let importeNuevo = this.totalApagar;
    if (this.pagarTotal !== 0) {
      importeNuevo = Number(this.totalApagar.toFixed(2)) - Number(this.pagarTotal.toFixed(2));
      if (importeNuevo < 0) {
        importeNuevo = 0.00;
      }
    }
    const pagoNuevo: CxcTrnpago = {
      TRNCOBRO_NRODOC: '',
      TRNPAGO_TIPO: '',
      CAJ_CODIGO: '',
      TRNPAGO_NUMERO: '',
      TRNPAGO_FECHA: this.datePipe.transform(new Date(), 'dd/MM/yyyy'),
      TRNPAGO_CONCEPTO: '',
      TRNPAGO_IMPORTE: importeNuevo.toFixed(2),
      COM_CODIGO: '01',
      CON_CODIGO: '',
      TRNCOBRO_NROCOMPROBANTE: '',
      CXP_TRNCOBRO_CODIGO: '',
      CLI_CODIGO: '',
      BANCLI_CODIGO: '',
      TRNPAGO_NROCTA: '',
      TRNPAGO_FLAG: '1',
      TRNPAGO_REFER: '',
      CABROLADMN_CODIGO: '',
      EMP_CODIGO: '',
      CABROLVEND_CODIGO: '',
      EMP_CODIGOVEND: '',
      TRNPAGO_CODSRI: '01',
      TRNCHEPOS_NUMDOC: '',
      TRNPAGO_TIPDIS: '',
      TRNPAGO_NUMEROANT: '',
      BANCLI_NOMBRE: '',
      LINEA: Number(linea + 1)
    };
    this.pagconservice.pagos.push(pagoNuevo);
    this.aggrid.refreshaggrid(this.pagconservice.pagos, this.defaultColDef.objeto);
  }
  
  eliminar() {
    this.pagconservice.pagos.splice(this.pagconservice.pagos.findIndex(
      pago => pago.LINEA === this.pagoSeleccionado.LINEA),
      1);
    this.aggrid.refreshaggrid(this.pagconservice.pagos, this.defaultColDef.objeto);
    this.buttonmod();
  }
  
  cancelar() {
    for (let i = this.pagconservice.pagos.length; i > 0; i--) {
      if (this.pagconservice.pagos[i - 1].TRNPAGO_TIPO === '' && this.pagconservice.pagos[i - 1].TRNPAGO_IMPORTE <= 0) {
        this.pagconservice.pagos.splice(i - 1,
          1);
      }
    }
    this.aggrid.refreshaggrid(this.pagconservice.pagos, this.defaultColDef.objeto);
    this.buttonmod();
  }
  
  buttonmod() {
    this.botonNuevoGrid = false;
    this.botonBorrarGrid = true;
    this.botonRegresarGrid = false;
  }
  
  gridsnull() {
    this.buttonmod();
  }
  
  cancelarPago() {
    this.limpiarDatos();
    if (this.encdvf.ENCDVF_ESTADO === 'PENDIENTE') {
      this.botonNuevoGrid = false;
      this.botonBorrarGrid = true;
      this.pagconservice.consultarFacturasPendientes(this.encdvf.ENCDVF_REFERENCIA, this.encdvf.CLI_CODIGO).subscribe((res) => {
        this.pagconservice.resumen = res;
        this.pagconservice.resumen.map((resumen) => {
          resumen.TRNCOBRO_FECHATRN = this.datePipe.transform(resumen.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
          resumen.TRNCOBRO_FECHAVENCE = this.datePipe.transform(resumen.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
          resumen.SALDO = Math.abs(Number(resumen.SALDO)).toFixed(2);
          resumen.TRNCOBRO_IMPORTE = 0;
          resumen.SELECCIONADO = false;
        });
        document.getElementById('contorno').style.pointerEvents = 'all';
      });
    }
    
    if (this.encdvf.ENCDVF_ESTADO === 'PAGADA') {
      this.consultarPagoDev();
      this.botonGuardar = true;
    }
  }
  
  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }
}
