import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Venmaetransportistas} from '../veninterfaces/venmaetransportistas';
import {Observable} from 'rxjs';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
    providedIn: 'root'
})
export class VenmaetransportistasService {
    get venmaetransportistas(): Venmaetransportistas[] {
        return this._venmaetransportistas;
    }
    set venmaetransportistas(value: Venmaetransportistas[]) {
        this._venmaetransportistas = value;
    }
    public url: string;
    public way: string;
    private _venmaetransportistas: Venmaetransportistas[];
    constructor(private http: HttpClient,
                public usuarioEsquema: UsuarioEsquema,
                public usuario: Usuario,
                private confIniciales: ConfInicialesService) {
        this.url = sessionStorage.getItem('url');
        this.way = sessionStorage.getItem('way');
    }
    guardarTransportistas(transportistas: Venmaetransportistas): Observable<any[]> {
        return this.http.post<any>(this.way + '/ventas/transportistas/95f8edf7bb55f80', {
            elementos: {
                p_transport_codigo: Number(transportistas.TRANSPORT_CODIGO),
                p_com_codigo: '01',
                p_transport_ruc: transportistas.TRANSPORT_RUC,
                p_transport_nombre: transportistas.TRANSPORT_NOMBRE,
                p_transport_telefono1: transportistas.TRANSPORT_TELEFONO1,
                p_transport_telefono2: transportistas.TRANSPORT_TELEFONO2,
                p_transport_direccion: transportistas.TRANSPORT_DIRECCION,
                p_transport_contacto: transportistas.TRANSPORT_CONTACTO,
                p_transport_auto: transportistas.TRANSPORT_AUTO,
                p_transport_placa: transportistas.TRANSPORT_PLACA
            }
        }, this.confIniciales.httpOptions());
    }
    actualizarTransportistas(transportistas: Venmaetransportistas): Observable<any[]> {
        return this.http.post<any>(this.way + '/ventas/transportistas/299df2ab2', {
            elementos: {
                p_transport_codigo: Number(transportistas.TRANSPORT_CODIGO),
                p_com_codigo: '01',
                p_transport_ruc: transportistas.TRANSPORT_RUC,
                p_transport_nombre: transportistas.TRANSPORT_NOMBRE,
                p_transport_telefono1: transportistas.TRANSPORT_TELEFONO1,
                p_transport_telefono2: transportistas.TRANSPORT_TELEFONO2,
                p_transport_direccion: transportistas.TRANSPORT_DIRECCION,
                p_transport_contacto: transportistas.TRANSPORT_CONTACTO,
                p_transport_auto: transportistas.TRANSPORT_AUTO,
                p_transport_placa: transportistas.TRANSPORT_PLACA
            }
        }, this.confIniciales.httpOptions());
    }


    erFindCodTransp(codigo) {    
        return this.http.post(this.way + '/ventas/transportistas/1db7caed661b280000', {
          elementos: {
            TRANSP_CODIGO: codigo
          },
        }, this.confIniciales.httpOptions());
      }



    getTransportistas(): Observable<any[]> {
        return this.http.post<any[]>(this.way + '/ventas/transportistas/10777bb55f99', {
                elementos: {},
                esquema: {
                    usuario: this.usuarioEsquema.esquema,
                    password: this.usuarioEsquema.password
                }
        }, this.confIniciales.httpOptions());
    }
    eliminarTransportista(transportistas: Venmaetransportistas): Observable<any[]> {
      return this.http.post<any>(this.way + '/ventas/transportistas/1d595a5756fbb50000', {
        elementos: {
          p_transport_codigo: Number(transportistas.TRANSPORT_CODIGO)
        }
      }, this.confIniciales.httpOptions());
    }


    actualizarTonelaje(transportistas: Venmaetransportistas): Observable<any[]> {
      return this.http.post<any[]>(this.way + '/ventas/transportistas/299df2ab2d5b6bb80', {
          elementos: {
            TRANSPORT_TONELAJE: Number(transportistas.TRANSPORT_TONELAJE),
            TRANSPORT_CODIGO: Number(transportistas.TRANSPORT_CODIGO)
          },
      }, this.confIniciales.httpOptions());
    }
}
