
		<div class="card card-w-title">
			<h1>Recuperar contraseña</h1>
			<div class="p-grid form-group">
				<div class="p-col-12">
					<label>Ingrese su ID</label>
					<input type="text" pInputText placeholder="ID">
				</div>
				<div class="p-col-12">
					<label>Ingrese su EMAIL</label>
					<input type="text" pInputText placeholder="Email">
				</div>
				<div class="p-col-12 p-sm-6 p-md-6">
					<button  pButton type="button" icon="pi pi-user" label="Enviar"></button>
				</div>
			</div>
		</div>
