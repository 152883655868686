<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="concilBancaria"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="concilBancariaconf" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>

<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="botonNuevo" [botonGuardar]="botonGuardar" [botonBorrar]="botonBorrar"
						 [botonRegresar]="botonRegresar" [botonAnterior]="botonAnterior" [botonPrimero]="botonPrimero"
						 [botonUltimo]="botonUltimo"
						 [botonSiguiente]="botonSiguiente" [barraBotonesCRUD]="false"
						 [barraBotonesDesplaz]="false" [barraBotones3]="true" [barraBotonesAsiCon]="true"
						 [botonBuscar]="false"
						 (signal)="manejarSenales($event)">
		</app-invmaebarra>
	</div>
</div>

<p-tabView (onChange)="onTab1Change($event)" [activeIndex]="selectTab">
	<p-tabPanel header="Conciliación">
		<div class="divgrups" id="divPrincipal">
			<div class="p-grid">
				<!--Primera Fila-->
				<div class="p-col-1">
					<span>Número:</span>
				</div>
				<div class="p-col-1">
					<label for="ENCCONNUMERO"></label>
					<input id="ENCCONNUMERO" type="text"
						   style="text-align: right;" pInputText disabled
						   [(ngModel)]="conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO"
						   autocomplete="off">
				</div>
				<div class="p-col-1">
				</div>
				<div class="p-col-1">
					<div class="p-grid">
						<div class="p-col-8">
							<span>Banco:</span>
						</div>
						<div class="p-col-2">
							<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									class="littlebuttons"
									(click)="busquedabanco(conciliacionBancaService.banenconsil.BAN_CODIGO)"
									[disabled]="inputDes"></button>
						</div>
						<div class="p-col-2">
						</div>
					</div>
				</div>
				<div class="p-col-1">
					<label for="BAN_CODIGO"></label>
					<input id="BAN_CODIGO" type="text" pInputText [disabled]="inputDes"
						   [(ngModel)]="conciliacionBancaService.banenconsil.BAN_CODIGO"
						   (keydown.enter)="setFocus($event)" autocomplete="off" (input)="getCodigoBan($event)">
				</div>
				<div class="p-col-2">
					<label for="NOMBANCO"></label>
					<input id="NOMBANCO" type="text"
						   pInputText disabled
						   [(ngModel)]="conciliacionBancaService.banenconsil.BAN_NOMBRE"
						   autocomplete="off">
				</div>
				<div class="p-col-1">
					<div class="p-grid">
						<div class="p-col-4">
						</div>
						<div class="p-col-8">
							<span>Cuenta:</span>
						</div>
					</div>

				</div>
				<div class="p-col-1">
					<label for="NROCUENTA"></label>
					<input id="NROCUENTA" type="text" style="text-align: right"
						   pInputText disabled
						   [(ngModel)]="conciliacionBancaService.banenconsil.BAN_CTANRO"
						   autocomplete="off">
				</div>
				<div class="p-col-1">
					<label for="CONCODIGO"></label>
					<input id="CONCODIGO" type="text" style="text-align: right"
						   pInputText disabled
						   [(ngModel)]="conciliacionBancaService.banenconsil.CON_CODIGO"
						   autocomplete="off">
				</div>
				<div class="p-col-1"></div>
				<!--Segunda Fila-->
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Periodo:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<p-dropdown [options]="cmbMes"
										[style]="{'width': '100%', 'min-width': '100%'}"
										[(ngModel)]="strCmbMes"
										optionLabel="label" [readonly]="boolCmbMes" inputId="inputMes"
										(keydown.enter)="setFocus($event)"></p-dropdown>
						</div>
						<div class="p-col-1" style="text-align: left">
							<p-dropdown [options]="cmbAnio"
										[style]="{'width': '100%', 'min-width': '100%'}"
										[(ngModel)]="strCmbAnio" optionLabel="label" [readonly]="boolCmbAnio"
										inputId="inputAnio" (keydown.enter)="setFocus($event)"></p-dropdown>
						</div>
						<div class="p-col-1"></div>
						<div class="p-col-1">
							<span>Saldo E.cuenta:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<label for="ENCCONCIL_SALDOEC"></label>
							<input id="ENCCONCIL_SALDOEC" pInputText type="text"
								   [(ngModel)]="conciliacionBancaService.banenconsil.ENCCONCIL_SALDOEC"
								   appTwoDigitDecimaNumber style="text-align: right;" (input)="cambio($event)">
						</div>
						<div class="p-col-1" *ngIf="btnaceptar == true">
							<!--							<p-button label="Aceptar" (click)="aceptar()" [disabled]="inputDes"></p-button>-->
							<button (click)="aceptar()" pButton class="aceptar"
									style="line-height: 1.5em; font-family: Arial, serif; height: 25px; width: 70px">
								Aceptar
							</button>
						</div>
						<div class="p-col-5"></div>
					</div>
				</div>
				<!--Tercera fila-->
				<div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px;">
					<div class="p-grid">
						<div class="p-col-4"></div>
						<div class="p-col-1">
							<span>Saldo en libros:</span>
						</div>
						<div class="p-col-1">
							<label for="totalLibros"></label>
							<input pInputText type="text" id="totalLibros"
								   [(ngModel)]="conciliacionBancaService.banenconsil.SALDO_LIBROS"
								   style="text-align: right;"
								   disabled>
						</div>
						<div class="p-col-6"></div>

					</div>
				</div>
			</div>
		</div>
		<!-- Segunda separación -->
		<div class="divgrups">
			<div class="p-col-12" style="margin-top: -10px; margin-bottom: -15px;">
				<div class="p-grid">
					<div class="p-col-2">
						<p-checkbox label="Mostrar no conciliado" binary="true"
									[(ngModel)]="chknoconcilia"
									(onChange)="mostrarTodos($event)">
						</p-checkbox>
					</div>
					<div class="p-col-1">
					</div>
					<div class="p-col-1" style="text-align: right">
						<span>Total Sel.:</span>
					</div>
					<div class="p-col-1" style="text-align: left">
						<input pInputText type="text" id="totalseleccionado"
							   [(ngModel)]="conciliacionBancaService.banenconsil.TOTAL_SELECT"
							   style="color: mediumblue;" disabled>
					</div>
					<div class="p-col-1" style="text-align: right">
						<span>Sal.EstCta.Ant: </span>
					</div>
					<div class="p-col-1" style="text-align: left">
						<input pInputText type="text" id="saldoestctaant"
							   [(ngModel)]="conciliacionBancaService.banenconsil.SALDO_CTAANT"
							   style="color: mediumblue;" disabled>
					</div>
					<div class="p-col-1" style="text-align: right">
						<span>Saldo Total:</span>
					</div>
					<div class="p-col-1" style="text-align: left">
						<input pInputText type="text" id="saldototal"
							   [(ngModel)]="conciliacionBancaService.banenconsil.SALDO_TOTAL"
							   style="color: mediumblue;" disabled>
					</div>
					<div class="p-col-1">
					</div>
					<div class="p-col-2">
						<p-checkbox label="Seleccionar Todos" binary="true"
									[(ngModel)]="concilBanDetConcilSelecTodo"
									(onChange)="selectAll($event)">
						</p-checkbox>
					</div>
				</div>
			</div>
		</div>

		<!-- Tercera separación -->
		<!--			<div class="p-grid">-->
		<!--				<div class="p-col-12">-->
		<app-aggridsaci *ngIf="selectTab === 0" [width]=""
						[height]="largo"
						
						[enableFilter]="true"
						[rowData]="conciliacionBancaService.detconcil"
						[frameworkComponents]="frameworkComponents"
						[animateRows]="true"
						[rowSelection]="rowSelection"
						[columnDefs]="columnDefsConcilBancaria"
						[defaultColDef]="defaultColConcil"
						[rowStyle]="rowStyle"
						[singleClickEdit]="false"
						[botones]="false"
						[mostrarGuardar]="false"
						(selected)="seleccionDetConsil($event)"
		></app-aggridsaci>
		<!--				</div>-->
		<!--			</div>-->
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-12">
					<p-progressBar [value]="porcentaje"></p-progressBar>
				</div>
				<!-- <div class="p-col-1" style="text-align: left">
					<span>Ubicación:</span>
				</div>
				<div class="p-col-6" style="text-align: left">
					<input id="ENCCASHMNG_PATHARCHIVO" type="text" pInputText autocomplete="off">
				</div>
				<div class="p-col-4" style="text-align: left">
				</div> -->
			</div>
		</div>
	</p-tabPanel>
	<!--Segunda pestaña-->
	<p-tabPanel header="Partidas Conciliatorias">
		<div class="divgrups" id="divPrincipal2">
			<div class="p-grid">
				<div class="p-col-12">
					<input type="text" pInputText
						   style="background-color: rgb(232, 242, 255); color: mediumblue; text-align: left; font-weight: bold; width: 600px"
						   readonly [(ngModel)]="lblBanDatos">
				</div>
				<div style="height: 150px">
				</div>
			</div>
		</div>
		<app-aggridsaci *ngIf="selectTab === 1" [width]=""
						[height]="largoPart"
						[enableSorting]="true"
						[enableFilter]="true"
						[rowData]="conciliacionBancaService.patidasconsil"
						[frameworkComponents]="frameworkComponents"
						[animateRows]="true"
						[rowSelection]="rowSelection"
						[columnDefs]="columnDefsPartConsil"
						[defaultColDef]="defaultColPartConsil"
						[rowStyle]="rowStyle"
						[singleClickEdit]="false"
						[botones]="true"
						[mostrarGuardar]="false"
						[botonesAdicionales]="true"
						(selected)="seleccionDetPartConsil($event)"
						(cambios)="cambioGrid($event)"
						[botonNuevo]="btnNuevoGrid"
						[botonGuardar]="btnGuardarGrid"
						[botonBorrar]="btnBorrarGrid"
						[botonRegresar]="btnCancelarGrid"
		></app-aggridsaci>

		<!-- Tercera separación -->
		<div class="p-grid">
			<div class="p-col-12">

			</div>
		</div>

	</p-tabPanel>
</p-tabView>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusqueda" [transitionOptions]="'0ms'" [modal]="true"
		  appendTo="body" [style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
		  appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
					 [busquedaParams]="busquedacampos" [consulta]="consulta"
					 (opcionSeleccionada)="manejarSeleccion($event)">
	</app-busquedadlg>
</p-dialog>

<p-dialog header="Reportes" [(visible)]="displayDialogReportes"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '40vw', height: '400px'}">
	<app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
						 [strNumDoc]="conciliacionBancaService.banenconsil.ENCCONCIL_NUMERO"
						 [strCampo]="'ENCCONCIL_NUMERO'"
						 [strNumDoc2]="conciliacionBancaService.banenconsil.BAN_CODIGO"
						 [strCampo2]="'ban_codigo'" (cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
</div>