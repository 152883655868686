import {Component, OnInit, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {AggridBanComponent} from '../aggrid-ban/aggrid-ban.component';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {Usuario} from '../../usuario';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {BancashmngService} from '../banservicios/bancashmng.service';
import {BanCashMngDet, BanCashMngEnc} from '../baninterfaces/bancashmng';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {DatePipe} from '@angular/common';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';

@Component({
  selector: 'app-bancashmanagement',
  templateUrl: './bancashmanagement.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class BancashmanagementComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;
  btnBuscar: boolean;
  btnAnular: boolean;
  
  public frameworkComponents;
  rowStyle;
  defaultColBanCashMng;
  rowSelection = 'multiple';
  largo: string;
  
  permisosCashMng: SegMaePermiso;
  encCashmng: BanCashMngEnc;
  detCashmng: BanCashMngDet[];
  
  cmbNumEnvio: any[];
  strNumEnvio: any;
  chkCashResumido: boolean;
  visibleCashResumido: boolean;
  detalleCashSelect: BanCashMngDet;
  
  // Busqueda
  
  displayDialogBus: boolean;
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  where: string;
  opcion: string;
  
  // Dialogo Cash
  
  visibleDialogEdit: boolean;
  
  boolSelectAll: boolean;
  chkIncluirDetalle: boolean;
  
  columnDefsCashMng = [
    {
      headerName: 'S', field: 'CHECK', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    {
      headerName: 'Fecha', field: 'DETCASHMNG_FECHA', editable: false, width: 100
    },
    {
      headerName: 'Código Proveedor', field: 'PRO_CODIGO', editable: false, width: 175, onCellDoubleClicked: (params) => {
        this.editCashMng(params.data);
      }
    },
    {
      headerName: 'Nombre', field: 'PRO_NOMBRE', editable: false, width: 250, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'Comp. NroDoc', field: 'DETCASHMNG_COMPROBANTE', editable: false, width: 150, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'Importe', field: 'DETCASHMNG_IMPORTE', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Tipo', field: 'DETCASHMNG_TIPO', editable: false, width: 100
    },
    {
      headerName: 'Banco', field: 'BAN_CODIGO', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Tip.Ban', field: 'TRN_BANTIPO', editable: false, width: 100, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Número', field: 'TRN_BANNUMERO', editable: false, width: 150
    },
    {
      headerName: 'BanCodigo_CM', field: 'COM_BANCODIGOCM', editable: false, width: 100, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'TipoCta_CM', field: 'COM_TIPOCTACM', editable: false, width: 100, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'NumCta_CM', field: 'COM_NUMCTACM', editable: false, width: 100, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'FormaPago_CM', field: 'COM_FORPAGCM', editable: false, width: 100, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'Benf_CM', field: 'COM_BENFCM', editable: false, width: 200, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'DirBenf_CM', field: 'COM_DIFBENFCM', editable: false, width: 200, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'TelfBenf_CM', field: 'COM_TELFBENFCM', editable: false, width: 110, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'TipoIdentif_CM', field: 'PRO_TIPOIDENTIFCM', editable: false, width: 100, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'Id_CM', field: 'PRO_IDCM', editable: false, width: 110, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'Ciudad_CM', field: 'PRO_CIUDADCM', editable: false, width: 150, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'LocPag_CM', field: 'PRO_LOCPAGCM', editable: false, width: 200, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'Email_CM', field: 'PRO_EMAILCM', editable: false, width: 250, cellStyle: { textAlign: 'left' },
    }
   
  ];
  
  
  constructor(private permisosService: PermisosService, public usuario: Usuario, private message: MessageService,
              public banCashmngService: BancashmngService, private busquedaSer: InvbusquedaService,
              private datePipe: DatePipe, private auditoriagral: AuditoriagralService,
              private init: NuevoComponentService,  private confirmationService: ConfirmationService,
              private utilitariosService: UtilitariosService,
              private confIniciales: ConfInicialesService) {
    this.agTable();
    this.encCashmng = {};
    this.detCashmng = [];
    this.detalleCashSelect = {};
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    
    this.defaultColBanCashMng = {
      editable: true,
      width: 100,
      objeto: 'bancashmng',
      resizable: true
    };
  }

  ngOnInit() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = true;
    this.btnRegresar = true;
    // this.btnNuevoGrid = true;
    // this.btnGuardarGrid = true;
    // this.btnCancelarGrid = true;
    // this.btnBorrarGrid = true;
    this.botonPrimero = false;
    this.botonAnterior = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
    this.btnBuscar = false;
    this.btnAnular = true;
  
    this.largo = '580';
  
    this.permisosService.getListaPermisos('BAN', '3', '601').subscribe((permiso) => {
      this.permisosCashMng = permiso[0];
    });
    this.encCashmng = {
      BAN_BANCO: '',
      BAN_CODIGO: '',
      ENCCASHMNG_CODIGO: '',
      ENCCASHMNG_COMPROBANTE: '',
      ENCCASHMNG_CONCEPTO: '',
      ENCCASHMNG_FECDESDE: new Date(),
      ENCCASHMNG_FECFIN: new Date(),
      ENCCASHMNG_FECHAEMISION: new Date(),
      ENCCASHMNG_NUMENVIO: '',
      ENCCASHMNG_PATHARCHIVO: ''
   };
    
    this.configuracionInicial();
  
    this.displayDialogBus = false;
    this.arregloBus = [];
    this.opcion = '';
    
    this.visibleDialogEdit = false;
  }
        //CDPJ
        ngOnDestroy(): void {
          this.confIniciales.instanciasCashManage--;
        }
        //CDPJ
  configuracionInicial() {
    let i = 0;
    let strNumero = '';
    this.cmbNumEnvio = [];
    for (i = 1; i < 1000 ; i++) {
      
      strNumero = this.banCashmngService.autoCompletar(String(i), 3);
      this.cmbNumEnvio.push({label: strNumero});
    }
    
  }
  
  async manejarSenales(valor) {
    if (valor === 'Primero') {
      this.seleccionarRegistro('P');
    }
    if (valor === 'Siguiente') {
      this.seleccionarRegistro('S');
    }
    if (valor === 'Anterior') {
      this.seleccionarRegistro('A');
    }
    if (valor === 'Ultimo') {
      this.seleccionarRegistro('U');
    }
    if (valor === 'Buscar') {
      this.buscarRegistro();
    }
    if (valor === 'Nuevo') {
      this.nuevoRegistro();
    }
    if (valor === 'Guardar') {
      this.guardarDatos();
    }
    if (valor === 'Borrar') {
      this.eliminarRegistro();
    }
    if (valor === 'Cancelar') {
      if (this.encCashmng.ENCCASHMNG_CODIGO !== '') {
        this.mostrarDatos(this.encCashmng.ENCCASHMNG_CODIGO);
      } else {
        this.nuevoRegistro();
        this.activaBotones('INI', false, true, true, false);
      }
    }
    if (valor === 'generarTxt') {
      if (String(this.encCashmng) !== '') {
        // pichincha
        if (await this.verificarBancoCash(this.encCashmng.BAN_CODIGO, 'CODBANPIC') > 0) {
          if (this.chkCashResumido === false) {
            this.generarAchivoCashFormato3();
          } else {
            this.generarAchivoCashFormatoPichinchaResum();
          }
          
          return;
        }
        // internacional
        if (await this.verificarBancoCash(this.encCashmng.BAN_CODIGO, 'CODBANINT') > 0) {
          this.cashBancoInternacional();
          return;
        }
  
        // produbanco
        if (await this.verificarBancoCash(this.encCashmng.BAN_CODIGO, 'CODBANPRO') > 0) {
          this.cashBancoProdubanco();
          return;
        }
        // Guayaquil
        if (await this.verificarBancoCash(this.encCashmng.BAN_CODIGO, 'CODBANGUA') > 0) {
          this.cashBancoGuayaquil();
          return;
        }
      }
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
  async seleccionarRegistro(strTipo) {
    if (this.permisosCashMng != null) {
      if (this.permisosCashMng.PERTODO === 0) {
        if (this.permisosCashMng.PERSELECCION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de Selección. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }
  
    let strEncbandejaCodigo = '';
  
    try {
      const rs = await this.banCashmngService.obtenerEncCashMng(strTipo, this.encCashmng.ENCCASHMNG_CODIGO);
      if (rs !== null) {
        strEncbandejaCodigo = rs[0].ENCCASHMNG_CODIGO === null ? '' : rs[0].ENCCASHMNG_CODIGO;
      }

    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Error al seleccionar registro.... !');
    }
    
    if (strEncbandejaCodigo !== '') {
      this.mostrarDatos(strEncbandejaCodigo);
    }
  }
  
  async mostrarDatos(strEncCash) {
   
    let strControlActivaOpciones = '';
  
    this.nuevoRegistro();
    this.detCashmng = [];
  
    try {
      const rs = await this.banCashmngService.mostrarDatos(strEncCash);
      if (rs !== null) {
        this.encCashmng.ENCCASHMNG_CODIGO = rs[0].ENCCASHMNG_CODIGO === null ? '' : rs[0].ENCCASHMNG_CODIGO;
        this.encCashmng.ENCCASHMNG_FECHAEMISION = new Date(rs[0].ENCCASHMNG_FECHAEMISION);
        this.encCashmng.ENCCASHMNG_COMPROBANTE = rs[0].ENCCASHMNG_COMPROBANTE === null ? '' : rs[0].ENCCASHMNG_COMPROBANTE;
  
        this.encCashmng.ENCCASHMNG_COMPROBANTE = rs[0].ENCCASHMNG_COMPROBANTE === null ? '' : rs[0].ENCCASHMNG_COMPROBANTE;
        this.encCashmng.BAN_CODIGO = rs[0].BAN_CODIGO === null ? '' : rs[0].BAN_CODIGO;
        this.encCashmng.BAN_BANCO = rs[0].BAN_BANCO === null ? '' : rs[0].BAN_BANCO;
        this.encCashmng.ENCCASHMNG_FECDESDE = rs[0].ENCCASHMNG_FECDESDE === null ? '' : new Date(rs[0].ENCCASHMNG_FECDESDE);
        this.encCashmng.ENCCASHMNG_FECFIN = rs[0].ENCCASHMNG_FECFIN === null ? '' : new Date(rs[0].ENCCASHMNG_FECFIN);
        this.encCashmng.ENCCASHMNG_NUMENVIO = rs[0].ENCCASHMNG_NUMENVIO;
        
        for (const a of this.cmbNumEnvio) {
          if (Number(a.label) === rs[0].ENCCASHMNG_NUMENVIO) {
            this.strNumEnvio = {label: a.label};
          }
        }
  
        this.encCashmng.ENCCASHMNG_CONCEPTO = rs[0].ENCCASHMNG_CONCEPTO === null ? '' : rs[0].ENCCASHMNG_CONCEPTO;
        this.encCashmng.ENCCASHMNG_PATHARCHIVO = rs[0].ENCCASHMNG_PATHARCHIVO === null ? '' : rs[0].ENCCASHMNG_PATHARCHIVO;
        
        // Mostrar detalle
        this.listarDatos(this.encCashmng.ENCCASHMNG_CODIGO);
        const dia = this.encCashmng.ENCCASHMNG_FECHAEMISION.getDate();
        const mes = this.encCashmng.ENCCASHMNG_FECHAEMISION.getMonth();
        const anio = this.encCashmng.ENCCASHMNG_FECHAEMISION.getFullYear();
        
  
        if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
          strControlActivaOpciones = 'NoModificar';
        }
  
        if (strControlActivaOpciones === 'NoModificar'  ) {
          this.activaBotones('NoModificar', false, true, true, false);
        } else {
          this.activaBotones('NAV', false, true, true, false);
        }
        
       // modeloComEgr.seleccionarTodosItems(1);
      }
    
    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso desplegar datos no terminó satisfactoriamente.... !');
    }
  
    // pichincha
  
    if (await this.verificarBancoCash(this.encCashmng.BAN_CODIGO, 'CODBANPIC') > 0) {
      this.visibleCashResumido = true;
      this.chkCashResumido = false;
    } else {
      this.visibleCashResumido = false;
      this.chkCashResumido = false;
    }
  }
  
  async listarDatos(strEncBandejaCodigo) {
    let dblPagado = 0;
    try {
      const rs = await this.banCashmngService.listarDatos(strEncBandejaCodigo);
      if (rs.length > 0) {
        for (const dato of rs) {
          const item: BanCashMngDet = {};
          item.DETCASHMNG_FECHA = dato.DETCASHMNG_FECHA === null ? '' : dato.DETCASHMNG_FECHA;
          if (item.DETCASHMNG_FECHA.toString().includes('-') && item.DETCASHMNG_FECHA !== '') {
            item.DETCASHMNG_FECHA  = this.datePipe.transform(item.DETCASHMNG_FECHA , 'dd/MM/yyyy');
          }
          item.DETCASHMNG_COMPROBANTE = dato.DETCASHMNG_COMPROBANTE === null ? '' : dato.DETCASHMNG_COMPROBANTE;
          item.PRO_CODIGO = dato.PRO_CODIGO === null ? '' : dato.PRO_CODIGO;
          item.PRO_NOMBRE = dato.PRO_NOMBRE === null ? '' : dato.PRO_NOMBRE;
          item.DETCASHMNG_TIPO = dato.DETCASHMNG_TIPO === null ? '' : dato.DETCASHMNG_TIPO;
          dblPagado = dato.DETCASHMNG_IMPORTE === 0 ? 0 : dato.DETCASHMNG_IMPORTE;
          item.DETCASHMNG_IMPORTE = Number(dblPagado).toFixed(2);
          item.CHECK = true;
          item.BAN_CODIGO = dato.BAN_CODIGO === null ? '' : dato.BAN_CODIGO;
          item.TRN_BANTIPO = dato.TRN_BANTIPO === null ? '' : dato.TRN_BANTIPO;
          item.TRN_BANNUMERO = dato.TRN_BANNUMERO === null ? '' : dato.TRN_BANNUMERO;
          item.COM_BANCODIGOCM = dato.COM_BANCODIGOCM === null ? '' : dato.COM_BANCODIGOCM;
          item.COM_TIPOCTACM = dato.COM_TIPOCTACM === null ? '' : dato.COM_TIPOCTACM;
          item.COM_NUMCTACM = dato.COM_NUMCTACM === null ? '' : dato.COM_NUMCTACM;
          item.COM_FORPAGCM = dato.COM_FORPAGCM === null ? '' : dato.COM_FORPAGCM;
          item.COM_BENFCM = dato.COM_BENFCM === null ? '' : dato.COM_BENFCM;
          item.COM_DIFBENFCM = dato.COM_DIFBENFCM === null ? '' : dato.COM_DIFBENFCM;
          item.COM_TELFBENFCM = dato.COM_TELFBENFCM === null ? '' : dato.COM_TELFBENFCM;
          item.PRO_TIPOIDENTIFCM = dato.PRO_TIPOIDENTIFCM === null ? '' : dato.PRO_TIPOIDENTIFCM;
          item.PRO_IDCM = dato.PRO_IDCM === null ? '' : dato.PRO_IDCM;
          item.PRO_CIUDADCM = dato.PRO_CIUDADCM === null ? '' : dato.PRO_CIUDADCM;
          item.PRO_LOCPAGCM = dato.PRO_LOCPAGCM === null ? '' : dato.PRO_LOCPAGCM;
          item.PRO_EMAILCM = dato.PRO_EMAILCM === null ? '' : dato.PRO_EMAILCM;
          item.DETCASHMNG_LINEA = dato.DETCASHMNG_LINEA === null ? 0 : dato.DETCASHMNG_LINEA;
          
          this.detCashmng.push(item);
        }
        this.aggrid.refreshColumnDefs();
        this.aggrid.refreshaggrid(this.detCashmng, this.defaultColBanCashMng.objeto);
      }
    } catch (error) {
      this.mensajeAlerta('error', 'ERROR', error.error);
    }
  
  }
  
  nuevoRegistro() {
    
    this.encCashmng.ENCCASHMNG_CODIGO = '';
    this.encCashmng.ENCCASHMNG_COMPROBANTE = '';
    this.encCashmng.ENCCASHMNG_FECHAEMISION = new Date();
    this.encCashmng.BAN_BANCO = '';
    this.encCashmng.BAN_CODIGO = '';
    this.encCashmng.ENCCASHMNG_FECDESDE = new Date();
    this.encCashmng.ENCCASHMNG_FECFIN = new Date();
    this.strNumEnvio = this.cmbNumEnvio[0];
    this.encCashmng.ENCCASHMNG_CONCEPTO = '';
    this.encCashmng.ENCCASHMNG_PATHARCHIVO = '';
    this.visibleCashResumido = false;
    this.chkCashResumido = false;
    this.detCashmng = [];
    
    this.activaBotones('N', true, false, false, true);
    
  }
  
  activaBotones(strTipo, bolNuevo, bolGuardar, bolCancelar, bolEliminar) {
    this.btnNuevo = bolNuevo;
    this.btnGuardar = bolGuardar;
    this.btnBorrar = bolEliminar;
    this.btnRegresar = bolCancelar;

    if (strTipo === 'NAV') {
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
    }
    if (strTipo === 'N') {
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
    }
    if (strTipo === 'NoModificar') {
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
    }
  }
  
  async verificarBancoCash(strCodBanco, strTipoBanco) {
    let intExiste = 0;
    // pichincha
    try {
      const rs = await this.banCashmngService.verificarBancoCash(strCodBanco, strTipoBanco);
      if (rs !== null) {
        intExiste = rs[0]['COUNT(*)'];
      }
    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Error al seleccionar registro.... !');
    }
    return intExiste;
  }
  
  buscarRegistro() {
    if (this.permisosCashMng != null) {
      if (this.permisosCashMng.PERTODO === 0) {
        if (this.permisosCashMng.PERSELECCION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de Selección. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }
    
    this.opcion = 'BuscarCashMng';
    this.types = [
      {label: 'NRO DOC', value: 'ENCCASHMNG_CODIGO'},
      {label: 'NRO COMPROBANTE', value: 'ENCCASHMNG_COMPROBANTE'},
      {label: 'FECHA EMISIÓN', value: 'ENCCASHMNG_FECHAEMISION'},
      {label: 'CÓDIGO BANCO', value: 'BAN_CODIGO'},
      {label: 'BANCO', value: 'BAN_BANCO'},
      {label: 'CONCEPTO', value: 'ENCCASHMNG_CONCEPTO'},
    ];
    this.busquedacampos = ['', '', '', '', '', ''];
    this.tabla = 'BAN_ENCCASHMNG';
    this.where = '';
    this.consulta = 'ENCCASHMNG_CODIGO, ENCCASHMNG_COMPROBANTE, ENCCASHMNG_FECHAEMISION, BAN_CODIGO, ' +
      'BAN_FNC_NOMBRE_BANCO(BAN_CODIGO, \'01\') AS BAN_BANCO, ENCCASHMNG_CONCEPTO';
    this.busquedaSer.busquedaBanEncCashmng().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.arregloCons.map((dato) => {
        if (dato.ENCCASHMNG_FECHAEMISION !== null) {
          dato.ENCCASHMNG_FECHAEMISION = this.datePipe.transform(dato.ENCCASHMNG_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          dato.ENCCASHMNG_FECHAEMISION = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });
  }
  
  async manejarSeleccion(valor) {
    if (this.opcion === 'BuscarCashMng') {
      if (valor.ENCCASHMNG_CODIGO !== '') {
        this.mostrarDatos(valor.ENCCASHMNG_CODIGO);
      }
    }
  
    if (this.opcion === 'BuscarBanco') {
      if (valor.BAN_CODIGO !== '') {
        this.encCashmng.BAN_CODIGO = valor.BAN_CODIGO;
        this.encCashmng.BAN_BANCO = valor.BAN_BANCO;
        this.activaBotones('M', true, false, false, true);
        try {
          const rs = await this.banCashmngService.encontrardirectoriocm('ban_codigo= \'' + this.encCashmng.BAN_CODIGO + '\'');
          if (rs[0].BAN_DIRECTORIO_CM !== null) {
            this.encCashmng.ENCCASHMNG_PATHARCHIVO = rs[0].BAN_DIRECTORIO_CM;
          } else {
            this.encCashmng.ENCCASHMNG_PATHARCHIVO = '';
          }
        } catch (err) {
         this.mensajeAlerta('error', 'Error', err.error);
         
        }
        // pichincha
  
        if (await this.verificarBancoCash(this.encCashmng.BAN_CODIGO, 'CODBANPIC') > 0) {
          this.visibleCashResumido = true;
          this.chkCashResumido = false;
          
        } else {
          this.visibleCashResumido = false;
          this.chkCashResumido = false;
        }
      }
    }
    this.displayDialogBus = false;
    this.displayDialogBusquedaFast = false;
  }
  
  buscarBancos(parametro) {
    this.opcion = 'BuscarBanco';
    this.types = [
      {label: 'CÓDIGO', value: 'BAN_CODIGO'},
      {label: 'BANCO', value: 'BAN_BANCO'},
      {label: 'CUENTA NRO', value: 'BAN_CTANRO'},
      {label: 'JEFE CUENTA', value: 'BAN_JEFECTA'},
      {label: 'SUCURSAL', value: 'BAN_SUCURSAL'},
      {label: 'DIRECCIÓN', value: 'BAN_DIRECCION'},
      {label: 'TELEFONO1', value: 'BAN_TELEFONO1'},
      {label: 'REF. CONTABLE', value: 'CON_CODIGO'},
      {label: 'CEN_CODIGO', value: 'CEN_CODIGO'},
      {label: 'TIPO DE CUENTA', value: 'BAN_TIPOCUENTA'},
      {label: 'DOC. POR PAGAR', value: 'CON_CODIGO_2'},
      {label: 'PROCESO', value: 'BAN_PROCESO_CM'},
      {label: 'DIRECTORIOCM', value: 'BAN_DIRECTORIO_CM'},
      {label: 'DINEROELEC', value: 'BAN_DINEROELEC'},
      {label: 'TELCEL', value: 'BAN_TELEFONOCEL'},
      {label: 'ULT. CHEQUE', value: 'BAN_ULTIMOCHEQUE'},
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
    this.consulta = 'BAN_codigo,BAN_banco, NVL(BAN_ctanro, \'*\') AS BAN_ctanro, BAN_jefecta,BAN_sucursal,' +
      ' BAN_direccion, BAN_telefono1, NVL(CON_codigo, \'\') as CON_codigo, CEN_CODIGO,BAN_tipocuenta,' +
      ' con_codigo_2, ban_proceso_cm, ban_directorio_cm, ban_dineroelec, ban_telefonocel,BAN_ULTIMOCHEQUE';
    this.tabla = 'BAN_MAEBAN';
    this.where = '';
  
    this.banCashmngService.encontrarBanco(this.types[0].value + ' LIKE \'' + parametro + '\'').subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaBanMaeBan2(parametro).subscribe((res: any[]) => {
            this.arregloCons = res;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaBanMaeBan2(parametro).subscribe((res: any[]) => {
          this.arregloCons = res;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }
  
  mostrarComprobantes() {
    const strCodigoBan = this.encCashmng.BAN_CODIGO;
    const strFechaDesde = this.encCashmng.ENCCASHMNG_FECDESDE.toLocaleString('en-GB').split(',');
    const strFechaHasta = this.encCashmng.ENCCASHMNG_FECFIN.toLocaleString('en-GB').split(',');
    
  
    this.listarDatosAceptar(strCodigoBan, strFechaDesde[0], strFechaHasta[0]);
  }
  
  async listarDatosAceptar(strBanCodigo, strFechaInicial, strFechaFinal) {
    let dblPagado = 0;
    let rs;
    // if (await this.verificarBancoCash(this.encCashmng.BAN_CODIGO, 'CODBANGUA') > 0) {
    //   strProceso = 'BAN_PCK_ENCBANDEJA_C.BAN_SP_DATOSPARACASH_G';
    // } else {
    //   strProceso = 'BAN_PCK_ENCBANDEJA_C.BAN_SP_DATOSPARACASH';
    // }
    //
    try {
      if (await this.verificarBancoCash(this.encCashmng.BAN_CODIGO, 'CODBANGUA') > 0) {
        rs = await this.banCashmngService.listarDatosAceptarG(strFechaInicial, strFechaFinal, strBanCodigo);
      } else {
        rs = await this.banCashmngService.listarDatosAceptar(strFechaInicial, strFechaFinal, strBanCodigo);
      }
      
      this.detCashmng = [];
      if (rs !== null) {
        for (const dato of rs) {
          const item: BanCashMngDet = {};
          item.DETCASHMNG_FECHA = dato.ENCBANDEJA_FECHAEMISION === null ? '' : dato.ENCBANDEJA_FECHAEMISION;
          item.DETCASHMNG_COMPROBANTE = dato.TRNCOBRO_NRODOC === null ? '' : dato.TRNCOBRO_NRODOC;
          item.PRO_CODIGO = dato.PRO_CODIGO === null ? '' : dato.PRO_CODIGO;
          item.PRO_NOMBRE = dato.PRO_NOMBRE === null ? '' : dato.PRO_NOMBRE;
          dblPagado = dato.IMPORTE === 0 ? 0 : dato.IMPORTE;
          dblPagado = Number(dblPagado.toFixed(2));
          item.DETCASHMNG_IMPORTE = dblPagado;
          item.DETCASHMNG_TIPO = dato.TIPO === null ? '' : dato.TIPO;
          item.BAN_CODIGO = dato.BAN_CODIGO === null ? '' : dato.BAN_CODIGO;
          item.TRN_BANTIPO = dato.TRN_BANTIPO === null ? '' : dato.TRN_BANTIPO;
          item.TRN_BANNUMERO = dato.TRN_BANNUMERO === null ? '' : dato.TRN_BANNUMERO;
          item.COM_BANCODIGOCM = dato.COM_BANCODIGOCM === null ? '' : dato.COM_BANCODIGOCM;
          item.COM_TIPOCTACM = dato.COM_TIPOCTACM === null ? '' : dato.COM_TIPOCTACM;
          item.COM_NUMCTACM = dato.COM_NUMCTACM === null ? '' : dato.COM_NUMCTACM;
          item.COM_FORPAGCM = dato.COM_FORPAGCM === null ? '' : dato.COM_FORPAGCM;
          item.COM_BENFCM = dato.COM_BENFCM === null ? '' : dato.COM_BENFCM;
          item.COM_DIFBENFCM = dato.COM_DIFBENFCM === null ? '' : dato.COM_DIFBENFCM;
          item.COM_TELFBENFCM = dato.COM_TELFBENFCM === null ? '' : dato.COM_TELFBENFCM;
          item.PRO_TIPOIDENTIFCM = dato.PRO_TIPOIDENTIFCM === null ? '' : dato.PRO_TIPOIDENTIFCM;
          item.PRO_IDCM = dato.PRO_IDCM === null ? '' : dato.PRO_IDCM;
          item.PRO_CIUDADCM = dato.PRO_CIUDADCM === null ? '' : dato.PRO_CIUDADCM;
          item.PRO_LOCPAGCM = dato.PRO_LOCPAGCM === null ? '' : dato.PRO_LOCPAGCM;
          item.PRO_EMAILCM = dato.PRO_EMAILCM === null ? '' : dato.PRO_EMAILCM;
          item.DETCASHMNG_LINEA = 0;
          item.CHECK = false;
          this.detCashmng.push(item);
        }
  
        this.aggrid.refreshColumnDefs();
        this.aggrid.refreshaggrid(this.detCashmng, this.defaultColBanCashMng.objeto);
        
      }
    } catch (err) {
      this.mensajeAlerta('error', 'ERROR', err.error);
    }
  }
  
  async guardarDatos() {
    
    let strNumeroEnv = '';
    let intNumReg = 0;
    
    const strFechaEmision = this.encCashmng.ENCCASHMNG_FECHAEMISION.toLocaleString('en-GB').split(',');
    
    const strFechaDesde = this.encCashmng.ENCCASHMNG_FECDESDE.toLocaleString('en-GB').split(',');
    
    const strFechaHasta = this.encCashmng.ENCCASHMNG_FECFIN.toLocaleString('en-GB').split(',');
    
    if ( await this.verificarBancoCash(this.encCashmng.BAN_CODIGO, 'CODBANGUA') > 0) {
      intNumReg = Number(this.strNumEnvio.label);
      strNumeroEnv = this.autoCompletar(String(intNumReg), 2);
    } else {
      strNumeroEnv = this.strNumEnvio.label;
    }
    
    
    
    try {
      const rs = await this.banCashmngService.guardarEncCashmng(this.encCashmng.ENCCASHMNG_CODIGO, this.encCashmng.ENCCASHMNG_COMPROBANTE,
        strFechaEmision[0], this.encCashmng.BAN_CODIGO, strFechaDesde[0], strFechaHasta[0], '', '', this.encCashmng.ENCCASHMNG_CONCEPTO,
        0, strNumeroEnv, this.encCashmng.ENCCASHMNG_PATHARCHIVO);
      if (rs !== null) {
        if (this.encCashmng.ENCCASHMNG_CODIGO === '') {
          this.encCashmng.ENCCASHMNG_CODIGO = rs[0][':B2'];
          this.encCashmng.ENCCASHMNG_COMPROBANTE = rs[0][':B1'];
          this.auditoriagral.registrarAuditoria('BAN_ENCCASHMNG', this.encCashmng.ENCCASHMNG_COMPROBANTE , 'I',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });
        } else {
          this.auditoriagral.registrarAuditoria('BAN_ENCCASHMNG', this.encCashmng.ENCCASHMNG_COMPROBANTE , 'A',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });
        }
        this.guardarDetalle();
        this.activaBotones('G', false, true, true, false);
      }
    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso guardar cabecera no terminó satisfactoriamente.... !');
  }
  
  }
  
  autoCompletar(strCadena, intTamanioCadena) {
    let intAux = 0;
    let indice = 0;
    let strAutoCompletar = '';
    
    intAux = strCadena.length;
    for (indice = 1; indice <= intTamanioCadena - intAux; indice++) {
      strAutoCompletar = '0' + strAutoCompletar;
    }
    strAutoCompletar = strAutoCompletar + strCadena;
    return strAutoCompletar;
  }
  
  async guardarDetalle() {
    let intIndiceLista = 0;
    let item: BanCashMngDet;
  
    try {
      await this.banCashmngService.eliminarLineaDetalle(this.encCashmng.ENCCASHMNG_CODIGO);
    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso guardar datos de los documentos no terminó satisfactoriamente.... !');
      return false;
    }
  
    for (intIndiceLista = 0; intIndiceLista < this.detCashmng.length; intIndiceLista++) {
      item = this.detCashmng[intIndiceLista];

    
      if (item.CHECK === true) {  // si esta seleccionado
        
        try {
          const rs = await this.banCashmngService.insertarDetalleCash(this.encCashmng.ENCCASHMNG_CODIGO, item);
   
  
          this.auditoriagral.registrarAuditoria('BAN_DETCASHMNG', this.encCashmng.ENCCASHMNG_CODIGO + '/' + item.DETCASHMNG_COMPROBANTE , 'I',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });
        
        } catch (err) {
     
          this.mensajeAlerta('error', 'Error', '! Proceso guardar datos de los documentos no terminó satisfactoriamente.... !');
          return false;
        }
      
      }
    
    } // Fin de for
    
    return true;
  }
  
  eliminarRegistro() {
  
    if (this.permisosCashMng != null) {
      if (this.permisosCashMng.PERTODO === 0) {
        if (this.permisosCashMng.PERELIMACION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de eliminación. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }
  
    this.confirmationService.confirm({
      message: 'Está seguro de eliminar el registro?',
      header: 'Eliminar',
      icon: 'pi pi-exclamation-triangle',
      key: 'banCashMngConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarCashMng();
      },
      reject: () => {
      
      }
    });
    
  }
  
  async eliminarCashMng() {
    if (this.encCashmng.ENCCASHMNG_CODIGO === '') {
      this.mensajeAlerta('info', 'Información', 'Seleccione el registro a Eliminar');
      return;
    }
    
    try {
      await this.banCashmngService.eliminarEncCashmng(this.encCashmng.ENCCASHMNG_CODIGO);
      this.auditoriagral.registrarAuditoria('BAN_ENCCASHMNG', this.encCashmng.ENCCASHMNG_CODIGO + '/' + this.encCashmng.ENCCASHMNG_COMPROBANTE , 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
    
      // actualiza el saldo de los documentos (FACTURAS DE PROVEEDORES)
    
      this.nuevoRegistro();
    
      this.activaBotones('G', false, true, true, false);
    
    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso Eliminar Documento no terminó satisfactoriamente.... !');
    }
  }
  
  seleccionDetCashmng(valor) {
    
    if (valor.object !== undefined) {
      this.detalleCashSelect = valor.object;
    }
    
  }
  
  editarDetalleCash() {
    if (Object.keys(this.detalleCashSelect).length > 0) {
    
    }
  }
  
  editCashMng(data) {
    if (this.encCashmng.ENCCASHMNG_CODIGO !== '') {
      this.visibleDialogEdit = true;
    }
  }
  
  guardaDetalleCashmng(valor) {
    if (valor === true) {
      this.mostrarDatos(this.encCashmng.ENCCASHMNG_CODIGO);
      this.visibleDialogEdit = false;
    }
  }
  
  salirDialogEdit(valor) {
    if (valor === true) {
      this.visibleDialogEdit = false;
    }
  }
  
  guardarCashProv(valor) {
    if (valor === true) {
      this.visibleDialogEdit = false;
    }
  }
  
  selectAll(e) {
    if (e.checked) {
      this.detCashmng.map(item => {
        item.CHECK = true;
      });
      this.aggrid.refreshaggrid(this.detCashmng, this.defaultColBanCashMng.objeto);
    }
    if (!e.checked) {
      this.detCashmng.map(item => {
        item.CHECK = false;
      });
      this.aggrid.refreshaggrid(this.detCashmng, this.defaultColBanCashMng.objeto);
    }
    
  }
  
  cambio(event) {
    if (this.encCashmng.ENCCASHMNG_CODIGO !== '') {
      this.btnGuardar = false;
      this.btnRegresar = false;
      this.btnNuevo = true;
    }
  }
  
  async generarAchivoCashFormato3() {
    let strCadena = '';
    try {
      await this.banCashmngService.ejeSpAchivoCash(this.encCashmng.ENCCASHMNG_CODIGO);
    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso generar archivo no terminó satisfactoriamente.... !');
    }
    let strNombreArchivo = '';
    let strDia = '';
    let strMes = '';
    let strAnio = '';
  
  
    strDia = this.completarFecha(String(this.encCashmng.ENCCASHMNG_FECHAEMISION.getDate()));
    strMes = this.completarFecha(String(this.encCashmng.ENCCASHMNG_FECHAEMISION.getMonth() + 1));
    strAnio = this.completarFecha(String(this.encCashmng.ENCCASHMNG_FECHAEMISION.getFullYear()));
    
    
    strNombreArchivo = 'PAGOS_MULTICASH_' + strAnio +  strMes +  strDia + '_' +  this.strNumEnvio.label + '.txt';
  
    let dblImporte = 0;
    let dblAux = 0;
    let intParteEntera = 0;
    let strConcepto = '2';
    
    try {
      const rs = await this.banCashmngService.obtenerDatosAchivoCashFormato3();
     
      if ( rs !== null) {
        for (const item of rs) {
          
          // 1  codigo orientación
          strCadena = strCadena + 'PA' + '\t';
          
          // 2 Cuenta bancaria del banco
          if (item.DETCASHMNG_NUMEROCTABANCO !== null) {
            strCadena = strCadena  + item.DETCASHMNG_NUMEROCTABANCO + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 3 Secuencial del pago
          strCadena = strCadena  + item.DETCASHMNG_SECUENCIA + '\t';
  
          // 4 Comprobante de pago
          if (item.DETCASHMNG_COMPROBANTE !== null) {
            strCadena = strCadena  + item.DETCASHMNG_COMPROBANTE + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 5  identificacion del proveedor
          if (item.DETCASHMNG_PROCODIGO !== null) {
            strCadena = strCadena  + item.DETCASHMNG_PROCODIGO + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 6  Moneda
          strCadena = strCadena  + 'USD' + '\t';
          
          // 7 valor
          if (item.DETCASHMNG_IMPORTE !== null) {
            dblImporte = item.DETCASHMNG_IMPORTE;
          } else {
            dblImporte = 0;
          }
  
          intParteEntera = Math.trunc(dblImporte);
  
          strCadena = strCadena  + this.autoCompletar(String(intParteEntera), 11);
          // --parte decimal
          dblAux = dblImporte - Math.trunc(dblImporte);
          dblAux =  dblAux * 100;
          dblAux = Number(Number(dblAux).toFixed(2));
          const y = Math.trunc(Math.round(dblAux));
  
          strCadena = strCadena  + this.autoCompletar(String(y), 2) + '\t';
  
          // 8 forma de pago
          if (item.DETCASHMNG_FORPAGCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_FORPAGCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 9 codigo del banco
          if (item.DETCASHMNG_CODIGOBANCOCASH !== null) {
            strCadena = strCadena  + item.DETCASHMNG_CODIGOBANCOCASH + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 10 tipo de cuenta
          if (item.DETCASHMNG_TIPOCTACM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_TIPOCTACM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 11 Número de cuenta
          if (item.DETCASHMNG_NUMCTACM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_NUMCTACM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 12 Tipo de Id del cliente beneficiario
  
          if (item.DETCASHMNG_TIPOIDENTIFCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_TIPOIDENTIFCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 13codigo del proveedor
          if (item.DETCASHMNG_PROCODIGO !== null) {
            strCadena = strCadena  + this.autoCompletar(item.DETCASHMNG_PROCODIGO, 13) +  '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 14 Nombre del Cliente Beneficiario
          if (item.DETCASHMNG_BENFCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_BENFCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          // 15 Direccion del beneficiario
          if (item.DETCASHMNG_DIFBENFCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_DIFBENFCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 16 ciudad
          if (item.DETCASHMNG_CIUDADCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_CIUDADCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 17 telefono
          if (item.DETCASHMNG_TELFBENFCM !== null) {
            strCadena = strCadena  + this.autoCompletar(item.DETCASHMNG_TELFBENFCM.replace('-', ''), 9) + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 18 sector
          strCadena = strCadena  + '\t';
  
          // 19 referencia
          if (item.DETCASHMNG_CONCEPTO !== null) {
            let strObserva = item.DETCASHMNG_CONCEPTO;
            strObserva = strObserva.replace('\r\n', '');
            strCadena = strCadena  + strObserva +  '\t';
            strConcepto = item.DETCASHMNG_CONCEPTO;
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 20 email
          if (item.DETCASHMNG_EMAILCM !== null) {
            strCadena = strCadena  + '|' + item.DETCASHMNG_EMAILCM + '|' + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          strCadena = strCadena + '\r\n';
        }
    
        this.download(strNombreArchivo, strCadena);
      }
    } catch (err) {
  
      this.mensajeAlerta('error', 'Error', '! Proceso generar archivo no terminó satisfactoriamente.... !');
    }
  }
  
  async generarAchivoCashFormatoPichinchaResum() {
    let strCadena = '';
    try {
      await this.banCashmngService.ejeSpAchivoCash(this.encCashmng.ENCCASHMNG_CODIGO);
    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso generar archivo no terminó satisfactoriamente.... !');
    }
    let strNombreArchivo = '';
    let strDia = '';
    let strMes = '';
    let strAnio = '';
  
  
    strDia = this.completarFecha(String(this.encCashmng.ENCCASHMNG_FECHAEMISION.getDate()));
    strMes = this.completarFecha(String(this.encCashmng.ENCCASHMNG_FECHAEMISION.getMonth() + 1));
    strAnio = this.completarFecha(String(this.encCashmng.ENCCASHMNG_FECHAEMISION.getFullYear()));
  
  
    strNombreArchivo = 'PAGOS_MULTICASH_' + strAnio +  strMes +  strDia + '_' +  this.strNumEnvio.label + '.txt';
  
    let dblImporte = 0;
    let dblAux = 0;
    let intParteEntera = 0;
    let strConcepto = '2';
  
    try {
      const rs = await this.banCashmngService.obtenerDatosAchivoCashFormato3();
      if ( rs !== null) {
        for (const item of rs) {
        
          // 1  codigo orientación
          strCadena = strCadena + 'PA' + '\t';
          
          // 2 Secuencial del pago
          strCadena = strCadena  + item.DETCASHMNG_SECUENCIA + '\t';
        
          // 3  Moneda
          strCadena = strCadena  + 'USD' + '\t';
        
          // 4 valor
          if (item.DETCASHMNG_IMPORTE !== null) {
            dblImporte = item.DETCASHMNG_IMPORTE;
          } else {
            dblImporte = 0;
          }
        
          intParteEntera = Math.trunc(dblImporte);
        
          strCadena = strCadena  + this.autoCompletar(String(intParteEntera), 11);
          // --parte decimal
          dblAux = dblImporte - Math.trunc(dblImporte);
          dblAux =  dblAux * 100;
          dblAux = Number(Number(dblAux).toFixed(2));
          const y = Math.trunc(Math.round(dblAux));
        
          strCadena = strCadena  + this.autoCompletar(String(y), 2) + '\t';
        
          // 5 forma de pago
          if (item.DETCASHMNG_FORPAGCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_FORPAGCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 6 tipo de cuenta
          if (item.DETCASHMNG_TIPOCTACM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_TIPOCTACM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
  
          // 7 Cuenta bancaria del banco
          if (item.DETCASHMNG_NUMEROCTABANCO !== null) {
            strCadena = strCadena  + item.DETCASHMNG_NUMEROCTABANCO + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
  
          // 8 Concepto
          if (item.DETCASHMNG_CONCEPTO !== null) {
            let strObserva = item.DETCASHMNG_CONCEPTO;
            strObserva = strObserva.replace('\r\n', '');
            strCadena = strCadena  + strObserva +  '\t';
            strConcepto = item.DETCASHMNG_CONCEPTO;
          } else {
            strCadena = strCadena  + '' + '\t';
          }
  
          // 9 Tipo de Id del cliente beneficiario
          if (item.DETCASHMNG_TIPOIDENTIFCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_TIPOIDENTIFCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
  
          // 10 codigo del proveedor
          if (item.DETCASHMNG_PROCODIGO !== null) {
            strCadena = strCadena  + item.DETCASHMNG_PROCODIGO +  '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
  
          // 11 Nombre del Cliente Beneficiario
          if (item.DETCASHMNG_BENFCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_BENFCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
  
          // 12 codigo del banco
          if (item.DETCASHMNG_CODIGOBANCOCASH !== null) {
            strCadena = strCadena  + item.DETCASHMNG_CODIGOBANCOCASH + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
        
          strCadena = strCadena + '\r\n';
        }

       
        this.download(strNombreArchivo, strCadena);
      }
    } catch (err) {

      this.mensajeAlerta('error', 'Error', '! Proceso generar archivo no terminó satisfactoriamente.... !');
    }
  }
  
  async cashBancoInternacional() {
    let strCadena = '';
    let p = '';
    try {
      await this.banCashmngService.ejeSpAchivoCash(this.encCashmng.ENCCASHMNG_CODIGO);
    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso generar archivo no terminó satisfactoriamente.... !');
    }
    let strNombreArchivo = '';
    let strDia = '';
    let strMes = '';
    let strAnio = '';
  
  
    strDia = this.completarFecha(String(this.encCashmng.ENCCASHMNG_FECHAEMISION.getDate()));
    strMes = this.completarFecha(String(this.encCashmng.ENCCASHMNG_FECHAEMISION.getMonth() + 1));
    strAnio = this.completarFecha(String(this.encCashmng.ENCCASHMNG_FECHAEMISION.getFullYear()));
  
  
    strNombreArchivo = 'PAGOS_MULTICASH_' + strAnio +  strMes +  strDia + '_' +  this.strNumEnvio.label + '.txt';
  
    let dblImporte = 0;
    let dblAux = 0;
    let intParteEntera = 0;
    let strConcepto = '2';
    const strRefAdic = '';
  
    try {
      const rs = await this.banCashmngService.obtenerDatosAchivoCashFormato3();
   
      if ( rs !== null) {
        for (const item of rs) {
        
          // 1  codigo orientación
          strCadena = strCadena + 'PA' + '\t';
  
          // 2 contrapartida
          if (item.DETCASHMNG_IDPROV !== null) {
            strCadena = strCadena  + item.DETCASHMNG_IDPROV + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
        
          // 3  Moneda
          strCadena = strCadena  + 'USD' + '\t';
        
          // 4 valor
          if (item.DETCASHMNG_IMPORTE !== null) {
            dblImporte = item.DETCASHMNG_IMPORTE;
          } else {
            dblImporte = 0;
          }
          
          // -- parte entera
          intParteEntera = Math.trunc(dblImporte);
          strCadena = strCadena  + Math.trunc(intParteEntera);
          // --parte decimal
          dblAux = dblImporte - Math.trunc(dblImporte);
          dblAux =  dblAux * 100;
  
          if (Math.trunc(dblAux) === 0) {
            p = '00';
            strCadena = strCadena + p	+ '\t';
          } else {
            const y = Math.trunc(Math.round(dblAux));
            strCadena = strCadena  + this.autoCompletar(String(y), 2) + '\t';
          }
  
          // 5 forma de pago
          strCadena = strCadena + 'CTA' + '\t';
          
          // 6 tipo de cuenta
          if (item.DETCASHMNG_TIPOCTACM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_TIPOCTACM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
  
          // 7 Número de cuenta
          if (item.DETCASHMNG_NUMCTACM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_NUMCTACM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
  
          // 8 Concepto
          if (item.DETCASHMNG_CONCEPTO !== null) {
            strCadena = strCadena  + item.DETCASHMNG_CONCEPTO +  '\t';
            strConcepto = item.DETCASHMNG_CONCEPTO;
          } else {
            strCadena = strCadena  + '' + '\t';
          }
  
          // 9 Tipo de Id del cliente beneficiario
          if (item.DETCASHMNG_TIPOIDENTIFCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_TIPOIDENTIFCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
  
          // 10 Id del proveedor
          if (item.DETCASHMNG_IDPROV !== null) {
            strCadena = strCadena  + this.autoCompletar(item.DETCASHMNG_IDPROV, 13) + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
  
          // 11 Nombre del Cliente Beneficiario
          if (item.DETCASHMNG_BENFCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_BENFCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
  
          // 12 codigo del banco
          if (item.DETCASHMNG_CODIGOBANCOCASH !== null) {
            strCadena = strCadena  + item.DETCASHMNG_CODIGOBANCOCASH + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
  
          strCadena = strCadena + strRefAdic + '\t';
  
          if (this.chkIncluirDetalle === true) {
            this.generaDetalleRubrosLineaFormato(item.DETCASHMNG_DOCUMENTO, Number(item.DETCASHMNG_SECUENCIA),
              strConcepto);
          }
          
          strCadena = strCadena + '\r\n';
        }
   
      
        this.download(strNombreArchivo, strCadena);
      }
    } catch (err) {
  
      this.mensajeAlerta('error', 'Error', '! Proceso generar archivo no terminó satisfactoriamente.... !');
    }
  }
  
  async generaDetalleRubrosLineaFormato(strDocumento, intSecuencial, strConcepto) {
    let strCadenaPagos = '';
    let dblImporte = 0;
    let intParteEntera = 0;
    let dblAux = 0;
    
    try {
      const rs = await this.banCashmngService.obtenerDatosRubro(this.encCashmng.ENCCASHMNG_CODIGO);
      if (rs !== null) {
        for (const item of rs) {
          // 1 Tipo de registro
          strCadenaPagos = 'DE' + '\t';
          // 2 Secuencial del  pago
          strCadenaPagos = strCadenaPagos + intSecuencial + '\t';
          // 3 Tipo de rubro
          strCadenaPagos = strCadenaPagos + 'INGRESO' + '\t';
          // 4 Concepto
          strCadenaPagos = strCadenaPagos + strConcepto + '\t';
          // 5 Valor Base
          if (item.TRNDOCUMENTO_IMPORTE !== null) {
            dblImporte = item.TRNDOCUMENTO_IMPORTE;
          } else {
            dblImporte = 0;
          }
          // --parte entera
          intParteEntera = Math.trunc(dblImporte);
          strCadenaPagos  = strCadenaPagos  + this.autoCompletar(String(intParteEntera), 11);
          // --parte decimal
          dblAux = dblImporte - Math.trunc(dblImporte);
          intParteEntera = Math.trunc(dblAux * 100);
          strCadenaPagos = strCadenaPagos  + this.autoCompletar(String(intParteEntera), 2) + '\t';
  
          // 6 pocentaje
          strCadenaPagos = strCadenaPagos + '00000' + '\t';
  
          // 7 Valor neto
          if (item.TRNDOCUMENTO_IMPORTE !== null) {
            dblImporte = item.TRNDOCUMENTO_IMPORTE;
          } else {
            dblImporte = 0;
          }
          
          // --parte entera
          intParteEntera = Math.trunc(dblImporte);
          strCadenaPagos = strCadenaPagos  + this.autoCompletar(String(intParteEntera), 11);
          // --parte decimal
          dblAux = dblImporte - Math.trunc(dblImporte);
          intParteEntera =  Math.trunc(dblAux * 100);
          strCadenaPagos = strCadenaPagos  + this.autoCompletar(String(intParteEntera), 2) + '\t';
          
          strCadenaPagos = strCadenaPagos + '\r\n';
        }
      }
    } catch (err) {
     
      this.mensajeAlerta('error', 'Error', '! Proceso leer el detalle de los pagos no terminó satisfactoriamente....!');
    }
  
  }
  
  async cashBancoProdubanco() {
    let strCadena = '';
    try {
      await this.banCashmngService.ejeSpAchivoCash(this.encCashmng.ENCCASHMNG_CODIGO);
    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso generar archivo no terminó satisfactoriamente.... !');
    }
    let strNombreArchivo = '';
    let strDia = '';
    let strMes = '';
    let strAnio = '';
  
  
    strDia = this.completarFecha(String(this.encCashmng.ENCCASHMNG_FECHAEMISION.getDate()));
    strMes = this.completarFecha(String(this.encCashmng.ENCCASHMNG_FECHAEMISION.getMonth() + 1));
    strAnio = this.completarFecha(String(this.encCashmng.ENCCASHMNG_FECHAEMISION.getFullYear()));
  
  
    strNombreArchivo = 'PAGOS_MULTICASH_' + strAnio +  strMes +  strDia + '_' +  this.strNumEnvio.label + '.txt';
  
    let dblImporte = 0;
    let dblAux = 0;
    let intParteEntera = 0;
    let strConcepto = '2';
    let strRefAdic = '';
    let intCntrl = 0;
  
    try {
      const rs = await this.banCashmngService.obtenerDatosAchivoCashFormato3();
     
      if ( rs !== null) {
        for (const item of rs) {
      
        
          // 1  codigo orientación
          strCadena = strCadena + 'PA' + '\t';
        
          // 2 Cuenta bancaria del banco
          if (item.DETCASHMNG_NUMEROCTABANCO !== null) {
            strCadena = strCadena  + this.autoCompletar(item.DETCASHMNG_NUMEROCTABANCO, 11) + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
        
          // 3 Secuencial del pago
          strCadena = strCadena  + item.DETCASHMNG_SECUENCIA + '\t';
        
          // 4 Comprobante de pago
          if (item.DETCASHMNG_COMPROBANTE !== null) {
            strCadena = strCadena  + item.DETCASHMNG_COMPROBANTE + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
  
          // 5   contrapartida
          if (item.DETCASHMNG_FORPAGCM !== null) {
            if (item.DETCASHMNG_FORPAGCM === 'CTA') {
              strCadena = strCadena  + item.DETCASHMNG_NUMCTACM + '\t';
            } else {
              strCadena = strCadena  + item.DETCASHMNG_IDPROV + '\t';
            }
            
          } else {
            strCadena = strCadena  + item.DETCASHMNG_IDPROV + '\t';
          }
  
          // 6  Moneda
          strCadena = strCadena  + 'USD' + '\t';
  
          // 7 valor
          if (item.DETCASHMNG_IMPORTE !== null) {
            dblImporte = item.DETCASHMNG_IMPORTE;
          } else {
            dblImporte = 0;
          }
  
          intParteEntera = Math.trunc(dblImporte);
          strCadena = strCadena  + this.autoCompletar(String(intParteEntera), 11);
          
          // --parte decimal
          dblAux = dblImporte - Math.trunc(dblImporte);
          dblAux =  dblAux * 100;
          dblAux = Number(Number(dblAux).toFixed(2));
          const y = Math.trunc(Math.round(dblAux));
  
          strCadena = strCadena  + this.autoCompletar(String(y), 2) + '\t';
  
          // 8 forma de pago
          if (item.DETCASHMNG_FORPAGCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_FORPAGCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
  
          // 9 codigo del banco
          if (item.DETCASHMNG_CODIGOBANCOCASH !== null) {
            if (item.DETCASHMNG_FORPAGCM === 'EFE' || item.DETCASHMNG_FORPAGCM === 'CHQ') {
              strCadena = strCadena  + '0036' + '\t';
            } else {
              strCadena = strCadena  + item.DETCASHMNG_CODIGOBANCOCASH + '\t';
            }
          } else {
            strCadena = strCadena  + '' + '\t';
          }
  
          // 10 tipo de cuenta
          if (item.DETCASHMNG_FORPAGCM !== null ) {
            if (item.DETCASHMNG_FORPAGCM === 'CTA') {
              strCadena = strCadena  + item.DETCASHMNG_TIPOCTACM + '\t';
            }
            if (item.DETCASHMNG_FORPAGCM === 'CHQ' || item.DETCASHMNG_FORPAGCM === 'EFE') {
              strCadena = strCadena  + '' + '\t';
            }
            if (item.DETCASHMNG_FORPAGCM === null) {
              strCadena = strCadena  + '' + '\t';
            }
          } else {
            strCadena = strCadena  + '' + '\t';
          }
  
          // 11 Número de cuenta
          if (item.DETCASHMNG_FORPAGCM !== null) {
            if (item.DETCASHMNG_FORPAGCM === 'CTA') {
              if (item.DETCASHMNG_CODIGOBANCOCASH === '036') {
                this.autoCompletar(item.DETCASHMNG_NUMCTACM, 11);
              } else {
                strCadena = strCadena  + item.DETCASHMNG_NUMCTACM + '\t';
              }
              
            }
          } else {
            this.mensajeAlerta('info', 'Información', 'Forma de pago no existe del proveedor ' + item.DETCASHMNG_IDPROV);
          }
  
          if (item.DETCASHMNG_FORPAGCM === 'CHQ' || item.DETCASHMNG_FORPAGCM === 'EFE') {
            strCadena = strCadena  + '' + '\t';
          }
  
          // 12 Tipo de Id del cliente beneficiario
          if (item.DETCASHMNG_TIPOIDENTIFCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_TIPOIDENTIFCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
  
          // 13 Número ID Cliente Beneficiario
          if (item.DETCASHMNG_IDPROV !== null) {
            strCadena = strCadena  + item.DETCASHMNG_IDPROV + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
  
          // 14 Nombre del Cliente Beneficiario
          if (item.DETCASHMNG_BENFCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_BENFCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 15 Direccion del beneficiario
          if (item.DETCASHMNG_DIFBENFCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_DIFBENFCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 16 ciudad
          if (item.DETCASHMNG_CIUDADCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_CIUDADCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
  
          // 17 telefono
          if (item.DETCASHMNG_TELFBENFCM !== null) {
            strCadena = strCadena  + this.autoCompletar(item.DETCASHMNG_TELFBENFCM.replace('-', ''), 9) + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
  
          // 18 localidad o sector
          if (item.DETCASHMNG_FORPAGCM === 'CTA') {
            strCadena = strCadena  + '' + '\t';
          } else {
            if (item.DETCASHMNG_LOCPAGCM !== null) {
              strCadena = strCadena  + item.DETCASHMNG_LOCPAGCM + '\t';
            } else {
              strCadena = strCadena  + '' + '\t';
            }
          }
  
          // 19 referencia
          if (item.DETCASHMNG_CONCEPTO !== null) {
            strCadena = strCadena  + item.DETCASHMNG_CONCEPTO +  '\t';
            strConcepto = item.DETCASHMNG_CONCEPTO;
          } else {
            strCadena = strCadena  + '' + '\t';
          }
  
          // 20 email
          if (item.DETCASHMNG_COMPROBANTE !== null) {
            strRefAdic = item.DETCASHMNG_COMPROBANTE;
          }
          intCntrl = 0;
  
          if (item.DETCASHMNG_EMAILCM !== null) {
            strRefAdic = strRefAdic + '|' + item.DETCASHMNG_EMAILCM;
            intCntrl = 1;
          }
  
          strCadena = strCadena  +  strRefAdic +  '\t';
        
          strCadena = strCadena + '\r\n';
        }
  
        this.download(strNombreArchivo, strCadena);
      }
    } catch (err) {

      this.mensajeAlerta('error', 'Error', '! Proceso generar archivo no terminó satisfactoriamente.... !');
    }
  }
  
  async cashBancoGuayaquil() {
    let strCadena = '';
    let intNumReg = 0;
    let strNumeroEnv = '';
  
    intNumReg = Number(this.strNumEnvio.label);
    strNumeroEnv = this.autoCompletar(String(intNumReg), 2);
  
    try {
      await this.banCashmngService.ejeSpAchivoCash(this.encCashmng.ENCCASHMNG_CODIGO);
    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso generar archivo no terminó satisfactoriamente.... !');
    }
    let strNombreArchivo = '';
    let strDia = '';
    let strMes = '';
    let strAnio = '';
  
  
    strDia = this.completarFecha(String(this.encCashmng.ENCCASHMNG_FECHAEMISION.getDate()));
    strMes = this.completarFecha(String(this.encCashmng.ENCCASHMNG_FECHAEMISION.getMonth() + 1));
    strAnio = this.completarFecha(String(this.encCashmng.ENCCASHMNG_FECHAEMISION.getFullYear()));
  
  
    strNombreArchivo = 'PAGOS_MULTICASH_' + strAnio +  strMes +  strDia + '_' +  strNumeroEnv + '.txt';
  
    let dblImporte = 0;
    let dblAux = 0;
    let intParteEntera = 0;
    let strConcepto = '2';
    let strRefAdic = '';
    let intCntrl = 0;
  
    try {
      const rs = await this.banCashmngService.obtenerDatosAchivoCashFormato3();
      if ( rs !== null) {
        for (const item of rs) {
          // 1  codigo orientación
          strCadena = strCadena + 'PA' + '\t';
        
          // 2 Cuenta bancaria del banco
          if (item.DETCASHMNG_NUMEROCTABANCO !== null) {
            strCadena = strCadena  + this.autoCompletar(item.DETCASHMNG_NUMEROCTABANCO, 11) + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
        
          // 3 Secuencial del pago
          strCadena = strCadena  + item.DETCASHMNG_SECUENCIA + '\t';
        
          // 4 Comprobante de pago
          if (item.DETCASHMNG_COMPROBANTE !== null) {
            strCadena = strCadena  + item.DETCASHMNG_COMPROBANTE + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
        
          // 5   contrapartida
          if (item.DETCASHMNG_FORPAGCM !== null) {
            if (item.DETCASHMNG_FORPAGCM === 'CTA') {
              strCadena = strCadena  + item.DETCASHMNG_NUMCTACM + '\t';
            } else {
              strCadena = strCadena  + item.DETCASHMNG_IDPROV + '\t';
            }
          } else {
            strCadena = strCadena  + item.DETCASHMNG_IDPROV + '\t';
          }
        
          // 6  Moneda
          strCadena = strCadena  + 'USD' + '\t';
        
          // 7 valor
          if (item.DETCASHMNG_IMPORTE !== null) {
            dblImporte = item.DETCASHMNG_IMPORTE;
          } else {
            dblImporte = 0;
          }
        
          intParteEntera = Math.trunc(dblImporte);
          strCadena = strCadena  + this.autoCompletar(String(intParteEntera), 11);
        
          // --parte decimal
          dblAux = dblImporte - Math.trunc(dblImporte);
          dblAux =  dblAux * 100;
          dblAux = Number(Number(dblAux).toFixed(2));
          const y = Math.trunc(Math.round(dblAux));
        
          strCadena = strCadena  + this.autoCompletar(String(y), 2) + '\t';
        
          // 8 forma de pago
          if (item.DETCASHMNG_FORPAGCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_FORPAGCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
        
          // 9 codigo del banco
          if (item.DETCASHMNG_CODIGOBANCOCASH !== null) {
            strCadena = strCadena  + item.DETCASHMNG_CODIGOBANCOCASH + '\t';
          } else {
            this.mensajeAlerta('info', 'Información', 'Código del banco no existe ' + item.DETCASHMNG_IDPROV);
          }
        
          // 10 tipo de cuenta
          if (item.DETCASHMNG_FORPAGCM !== null ) {
            if (item.DETCASHMNG_FORPAGCM === 'CTA') {
              strCadena = strCadena  + item.DETCASHMNG_TIPOCTACM + '\t';
            }
            if (item.DETCASHMNG_FORPAGCM === 'CHQ' || item.DETCASHMNG_FORPAGCM === 'EFE') {
              strCadena = strCadena  + '' + '\t';
            }
            if (item.DETCASHMNG_FORPAGCM === null) {
              strCadena = strCadena  + '' + '\t';
            }
          } else {
            strCadena = strCadena  + '' + '\t';
          }
        
          // 11 Número de cuenta
          if (item.DETCASHMNG_FORPAGCM === 'CTA') {
              if (item.DETCASHMNG_CODIGOBANCOCASH === '036') {
                this.autoCompletar(item.DETCASHMNG_NUMCTACM, 11);
              } else {
                strCadena = strCadena  + item.DETCASHMNG_NUMCTACM + '\t';
              }
            
          }
        
          if (item.DETCASHMNG_FORPAGCM === 'CHQ' || item.DETCASHMNG_FORPAGCM === 'EFE') {
            strCadena = strCadena  + '' + '\t';
          }
        
          // 12 Tipo de Id del cliente beneficiario
          if (item.DETCASHMNG_TIPOIDENTIFCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_TIPOIDENTIFCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
        
          // 13 Número ID Cliente Beneficiario
          if (item.DETCASHMNG_PROCODIGO !== null) {
            strCadena = strCadena  + this.autoCompletar(item.DETCASHMNG_IDPROV, 13) + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
        
          // 14 Nombre del Cliente Beneficiario
          if (item.DETCASHMNG_BENFCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_BENFCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
        
          // 15 Direccion del beneficiario
          if (item.DETCASHMNG_DIFBENFCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_DIFBENFCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
        
          // 16 ciudad
          if (item.DETCASHMNG_CIUDADCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_CIUDADCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
        
          // 17 telefono
          if (item.DETCASHMNG_TELFBENFCM !== null) {
            strCadena = strCadena  + this.autoCompletar(item.DETCASHMNG_TELFBENFCM.replace('-', ''), 9) + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
        
          // 18 localidad o sector
          if (item.DETCASHMNG_FORPAGCM === 'CTA') {
            strCadena = strCadena  + '' + '\t';
          } else {
            if (item.DETCASHMNG_LOCPAGCM !== null) {
              strCadena = strCadena  + item.DETCASHMNG_LOCPAGCM + '\t';
            } else {
              strCadena = strCadena  + '' + '\t';
            }
          }
        
          // 19 referencia
          if (item.DETCASHMNG_CONCEPTO !== null) {
            strCadena = strCadena  + item.DETCASHMNG_CONCEPTO +  '\t';
            strConcepto = item.DETCASHMNG_CONCEPTO;
          } else {
            strCadena = strCadena  + '' + '\t';
          }
        
          // 20 email
          if (item.DETCASHMNG_COMPROBANTE !== null) {
            strRefAdic = item.DETCASHMNG_COMPROBANTE;
          }
          intCntrl = 0;
        
          if (item.DETCASHMNG_EMAILCM !== null) {
            strRefAdic = strRefAdic + '|' + item.DETCASHMNG_EMAILCM;
            intCntrl = 1;
          }
        
          strCadena = strCadena  +  strRefAdic +  '\t';
  
          if (this.chkIncluirDetalle === true) {
            this.generaDetalleDocumento(item.DETCASHMNG_DOCUMENTO, Number(item.DETCASHMNG_SECUENCIA), strConcepto);
          }
          
        
          strCadena = strCadena + '\r\n';
          
        }
    
        this.download(strNombreArchivo, strCadena);
      }
    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso generar archivo no terminó satisfactoriamente.... !');
    }
    
  }
  
  async generaDetalleDocumento(strDocumento, intSecuencial, strConcepto) {
    let strCadena = '';
    let strCadenaPagos = '';
    try {
      await this.banCashmngService.ejeSpCashDatosDet(strDocumento, this.encCashmng.ENCCASHMNG_CODIGO, intSecuencial);
    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso generar  archivo de detalle del documento no terminó satisfactoriamente.... !');
    }
  
    strCadena = 'select * from BAN_TMP_CASH_DETALLE';
    let dblImporte = 0;
    let intParteEntera = 0;
    let dblAux = 0;
    
    try {
      const rs = await this.banCashmngService.generarDetalleDoc();
      if (rs !== null) {
        for (const item of rs) {
          dblImporte = 0;
          intParteEntera = 0;
          dblAux = 0;
          // 1 Tipo de registro
          strCadenaPagos = 'DE' + '\t';
          // 2 Secuencial del  pago
          strCadenaPagos = strCadenaPagos + intSecuencial + '\t';
          // 3 Tipo de rubro
          strCadenaPagos = strCadenaPagos + 'INGRESO' + '\t';
          // 4 Concepto
          strCadenaPagos = strCadenaPagos + item.DET_CONCEPTO + '\t';
          // 5 Valor Base
          if (item.DET_BASE !== null) {
            dblImporte = item.DET_BASE;
          } else {
            dblImporte = 0;
          }
          // --parte entera
          intParteEntera = Math.trunc(dblImporte);
          strCadenaPagos = strCadenaPagos  + this.autoCompletar(String(intParteEntera), 11);
          // --parte decimal
          dblAux = dblImporte - Math.trunc(dblImporte);
          dblAux = dblAux * 100;
          dblAux = Number(Number(dblAux).toFixed(2));
          let y = Math.trunc(Math.round(dblAux));
  
          strCadenaPagos = strCadenaPagos  + this.autoCompletar(String(y), 2) + '\t';
          // 6 pocentaje
          strCadenaPagos = strCadenaPagos + '00000' + '\t';
          // 7 Valor neto
          if (item.DET_NETO !== null) {
            dblImporte = item.DET_NETO;
          } else {
            dblImporte = 0;
          }
          // --parte entera
          intParteEntera = Math.trunc(dblImporte);
          strCadenaPagos = strCadenaPagos  + this.autoCompletar(String(intParteEntera), 11);
          // --parte decimal
          dblAux = 0;
          dblAux = dblImporte - Math.trunc(dblImporte);
          dblAux = dblAux * 100;
          dblAux =  Number(Number(dblAux).toFixed(2));
          y = Math.trunc(Math.round(dblAux));
  
          strCadenaPagos = strCadenaPagos  + this.autoCompletar(String(y), 2) + '\t';
          strCadena = strCadena + '\r\n';
        }
      }
    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso leer  detalle del documento no terminó satisfactoriamente.... !');
    }
    
    
  }
  
  download(filename, text) {
    const blob = new Blob([text], {type: 'text/plain'});
    const element = document.createElement('a');
    element.setAttribute('download', filename);
    element.setAttribute('href', window.URL.createObjectURL(blob));
    element.click();
    // const element = document.createElement('a');
    // element.setAttribute('href', 'data:text/plain;charset-utf-8' + encodeURIComponent(text));
    // element.setAttribute('download', filename);
    // element.style.display = 'none';
    // document.body.appendChild(element);
    // element.click();
    // document.body.removeChild(element);
  }
  
  completarFecha(strFecha): string {
    if (strFecha.length === 1) {
      return '0' + strFecha;
    } else {
      return strFecha;
    }
    
  }
  
  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'banCashMng',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
}
