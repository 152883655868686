<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="contdoc"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarcontdoc" title="Confirmar Acción"
                 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"
						 [barraBotones5]="true" [botonVisto]="false" [barraBotonesCRUD]="true" [barraBotones3Alt]="false"
						 (signal)="manejarSenales($event)"
						 style="text-align: left;">
		</app-invmaebarra>
	</div>
</div>
<div class="p-grid" style="width: 99%" class="general">
  <div class="p-col-12"></div>
  <p-tabView>
    <p-tabPanel header="Entradas/Salidas">
      <div class="p-col-12">
        <div class="divgrups">
          <div class="p-grid">
            <div class="p-col-12"></div>
            <div class="p-col-3">
              <div class="p-grid">
                <div class="p-col-5">
                  <span>Fecha Inicial:</span>
                </div>
                <div class="p-col-1"></div>
                <div class="p-col-6">
                  <p-calendar [inputStyle]="{width: '100%', height: '20px'}" dateFormat="dd/mm/yy" ></p-calendar>
                </div>
              </div>
            </div>
            <div class="p-col-1"></div>
            <div class="p-col-3">
              <div class="p-grid">
                <div class="p-col-12">
                  <p-radioButton label="Todas" name="group1" ></p-radioButton>
                </div>
              </div>
            </div>
            <div class="p-col-5"></div>
            <div class="p-col-3">
              <div class="p-grid">
                <div class="p-col-5">
                  <span>Fecha Final:</span>
                </div>
                <div class="p-col-1"></div>
                <div class="p-col-6">
                  <p-calendar  [inputStyle]="{width: '100%', height: '20px'}" dateFormat="dd/mm/yy" ></p-calendar>
                </div>
              </div>
            </div>
            <div class="p-col-1"></div>
            <div class="p-col-3">
              <div class="p-grid">
                <div class="p-col-12">
                  <p-radioButton label="No contabilizadas" name="group1"></p-radioButton>
                </div>
              </div>
            </div>
            <div class="p-col-5"></div>
          </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Transferencias"></p-tabPanel>
    <p-tabPanel header="Toma Física"></p-tabPanel>
  </p-tabView>
</div>
