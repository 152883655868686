import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {MessageService} from 'primeng';

import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class VenestadisticaService {
  
  public url: string;
  public way: string;
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService,
              private messageService: MessageService,
              private init: NuevoComponentService) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }

  eridcliente(strCLI_CODIGO){
    return this.http.post<any[]>(this.way + '/ventas/venestadistica/w7y9cf4v88orlje', {      
      elementos: {
        CLI_CODIGO: strCLI_CODIGO,
        },
    }, this.confIniciales.httpOptions()).toPromise();
  } 
 
  
  encontrarDocumentos(strTipo, strCliCodigo) {
    
    return this.http.post<any[]>(this.way + '/ventas/venestadistica/r4sgc0wdwmuwics', {      
      elementos: {
        p_opcion_1: strTipo,
        p_cli_codigo_2: strCliCodigo,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  ventasAcumuladas(strCliCodigo) {
    
 
    return this.http.post<any[]>(this.way + '/ventas/venestadistica/ijzwz49jdwczhb8', {      
      elementos: {
        p_cli_codigo_1: strCliCodigo,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  topProductos(strCliCodigo, strOrden) {
  
    return this.http.post<any[]>(this.way + '/ventas/venestadistica/q0nj4ie2bpycoy1', {      
      elementos: {
        p_cli_codigo_1: strCliCodigo,
        p_orden_2: strOrden,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  detalleVentas(strCliCodigo, strOrden) {
  
    return this.http.post<any[]>(this.way + '/ventas/venestadistica/cfj8r4kdh1okkx5', {      
      elementos: {
        p_cli_codigo_1: strCliCodigo,
        p_orden_2: strOrden,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  cantidadPedidos(strCliCodigo, strEstado) {
    
    return this.http.post<any[]>(this.way + '/ventas/venestadistica/1fnm7zcyjrajz3q', {      
      elementos: {
        p_cli_codigo_1: strCliCodigo,
        p_estado_2: strEstado
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  cantidadChProtestados(strCliCodigo) {
   
    return this.http.post<any[]>(this.way + '/ventas/venestadistica/z58uwieuom0czoz', {      
      elementos: {
        p_cli_codigo_1: strCliCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  primeraFactura(strCliCodigo) {
   
    return this.http.post<any[]>(this.way + '/ventas/venestadistica/6bm45nhrkccyo9x', {      
      elementos: {
        p_cli_codigo_1: strCliCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
}
