import { Injectable } from '@angular/core';
import {CxpCuotaVar, CxpDetCuota, CxpEncCuota} from '../cxpinterfaces/cxpenccuota';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {DatePipe} from '@angular/common';
import {Observable} from 'rxjs';
import {Cxctrncobro} from '../../cxc/cxcinterfaces/cxctrncobro';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class CxpenccuotaService {
  
  public encCuotaArray: CxpEncCuota[] = [];
  public detCuotaArray: CxpDetCuota[][] = [];
  public detCuotaVarArray: CxpCuotaVar[][] = [];
  public way: string;
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private init: NuevoComponentService,
              private datePipe: DatePipe,
              private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }
  
  consultarEncabezadoCuotas(strOpcion, strCondicion) {
    return this.http.post<any>(this.way + '/cxp/enccuota/662fc75d8cf63c0', {
      elementos: {
        p_opcion: strOpcion,
        p_filtro: '',
        p_condicion: strCondicion,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getAsiNrocuota(strEncCuotacodigo) {
    return this.http.post<any[]>(this.way + '/cxp/enccuota/83baae4afbc3400', {
      elementos: {
        ENCCUOTA_CODIGO: strEncCuotacodigo
      }
    }, this.confIniciales.httpOptions()
   ).toPromise();
  }

  eliminarTrnCuotasVar(strNumDoc) {
    return this.http.post<any[]>(this.way + '/cxp/enccuota/eacaddddd9ec8000', {
      elementos: {
        NumDoc: strNumDoc
      }
    }, this.confIniciales.httpOptions()
   ).toPromise();
  }

  actualizarNumFactura(strNumFact, strEncCuota, strLinea ) {
    return this.http.post<any[]>(this.way + '/cxp/enccuota/533bebef0f533c0', {
      elementos: {
        NumFact: strNumFact,
        EncCuota: strEncCuota,
        Linea: strLinea
      }
    }, this.confIniciales.httpOptions()
   ).toPromise();
  }

  eliminarFactCuotas(strEncCuota) {
    return this.http.post<any[]>(this.way + '/cxp/enccuota/3ab2b3d4ceb3d8', {
      elementos: {
        ENCCUOTA_CODIGO: strEncCuota
      }
    }, this.confIniciales.httpOptions()
   ).toPromise();
  }

  completarDatosDoc(strEncfacproNumero) {
    return this.http.post<any[]>(this.way + '/cxp/enccuota/3316cb55dc370c', {
      elementos: {
        ENCFACPRO_NUMERO: strEncfacproNumero
      }
    }, this.confIniciales.httpOptions()
   ).toPromise();
  }
  
  obtenerSaldoDocumento(strNumDoc, strProCodigo) {
    return this.http.post<any[]>(this.way + '/cxp/enccuota/617de2aadc370c', {
      elementos: {
        TrnCobroNroDoc: strNumDoc,
        ProCodigo: strProCodigo
      }
    }, this.confIniciales.httpOptions()
   ).toPromise();
  }

  consultarVarios(strNumDoc) {
    return this.http.post<any[]>(this.way + '/cxp/enccuota/3317e7beadcb1c', {
      elementos: {
        NumDoc: strNumDoc
      }
    }, this.confIniciales.httpOptions()
   ).toPromise();
  }

  consultarDetalleCuotas(strCuotaCodigo) {
    return this.http.post<any[]>(this.way + '/cxp/enccuota/198bf3cd7759ec0000', {
      elementos: {
        EncCuotaCodigo: strCuotaCodigo
      }
    }, this.confIniciales.httpOptions()
   ).toPromise();
  }

  obtenerDatosAdicionales(strEncFacProNum) {
    return this.http.post<any[]>(this.way + '/cxp/enccuota/617d6abb85364c', {
      elementos: {
        ENCFACPRO_NUMERO: strEncFacProNum
      }
    }, this.confIniciales.httpOptions()
   ).toPromise();
  }

  erTrnCobroNroDoc(strNumComprobante) {
    return this.http.post(this.way + '/cxp/enccuota/1db6617bc5f6dc0000', {
      elementos: {
        TRNCOBRO_NROCOMPROBANTE: strNumComprobante
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  insertarEncabezadoDoc(encFacProNumero, proCodigom, encCuotaFeInicio, encCuotaTotal, encCuotaInteresVal, encCuotaInteresPorce,
                        encCuotaFinanciado, encCuotaNroCuotas, encCuotaVenceDia, encCuotaAnio, encCuotaAbono, encCuotaSubtotal,
                        encFacTotal, encCuotaTipoInt, encCuotaDoc, encCuotaCodigo, encCuotaIntAnual) {
    return this.http.post<any>(this.way + '/cxp/enccuota/4afc75d8a5b70c', {
      elementos: {
        p_ENCFACPRO_NUMERO: encFacProNumero,
        p_COM_CODIGO: '01',
        p_PRO_CODIGO: proCodigom,
        p_ENCCUOTA_FECHAINICIO: encCuotaFeInicio,
        p_ENCCUOTA_TOTAL: encCuotaTotal,
        p_ENCCUOTA_INTERESVAL: encCuotaInteresVal,
        p_ENCCUOTA_INTERESPORCE: encCuotaInteresPorce,
        p_ENCCUOTA_FINANCIADO: encCuotaFinanciado,
        p_ENCCUOTA_NROCUOTAS: encCuotaNroCuotas,
        p_ENCCUOTA_VENCECDIA: encCuotaVenceDia,
        p_ENCCUOTA_ANIO: encCuotaAnio,
        p_ENCCUOTA_ABONO: encCuotaAbono,
        p_ENCCUOTA_SUBTOTAL: encCuotaSubtotal,
        p_ENCFAC_TOTAL: encFacTotal,
        p_ENCCUOTA_INTERESTIPO: encCuotaTipoInt,
        p_ENCCUOTA_DOC: encCuotaDoc,
        p_ENCCUOTA_CODIGO: encCuotaCodigo,
        p_ENCCUOTA_INTERESANUAL: encCuotaIntAnual,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarDetalleDoc(encCuotaCodigo) {
    return this.http.post<any>(this.way + '/cxp/enccuota/3ab2b35dd5370c', {
      elementos: {
        p_COM_CODIGO: '01',
        p_ENCCUOTA_CODIGO: encCuotaCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  insertarDetalleDoc(encFacProNum, proCodigo, encCuotaAnio, detCuotaNroLinea, detCuotaFeVence, detCuotaConcepto, detCuotaReferencia,
                     detCuotaImporte, detCuotaPagado, detCuotaSaldo, detCuotaNroCuota, detCuotaLineaAct, encCuotaCodigo) {
    return this.http.post<any>(this.way + '/cxp/enccuota/95f8edf5aeeb700', {
      elementos: {
        p_ENCFACPRO_NUMERO: encFacProNum,
        p_PRO_CODIGO: proCodigo,
        p_COM_CODIGO: '01',
        p_ENCCUOTA_ANIO: encCuotaAnio,
        p_DETCUOTA_NROLINEA: detCuotaNroLinea,
        p_DETCUOTA_FECHAVENCE: detCuotaFeVence,
        p_DETCUOTA_CONCEPTO: detCuotaConcepto,
        p_DETCUOTA_REFERENCIA: detCuotaReferencia,
        p_DETCUOTA_IMPORTE: detCuotaImporte,
        p_DETCUOTA_PAGADO: detCuotaPagado,
        p_DETCUOTA_SALDO: detCuotaSaldo,
        p_ENCCUOTA_NROCUOTAS: detCuotaNroCuota,
        p_DETCUOTA_NROLINEA_ACT: detCuotaLineaAct,
        p_ENCCUOTA_CODIGO: encCuotaCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  guardarCuentaXPagar(item: Cxctrncobro) {
    return this.http.post<any>(this.way + '/cxp/enccuota/21e56daceab38e0000', {
      elementos: {
        p_trncobro_nrodoc: item.TRNCOBRO_NRODOC,
        p_com_codigo: '01',
        p_TRNCOBRO_FECHATRN: item.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_TIPODOC: item.TRNCOBRO_TIPODOC,
        p_TRNCOBRO_NROCOMPROBANTE: item.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_CONCEPTO: item.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_IMPORTE: Number(item.TRNCOBRO_IMPORTE),
        p_TRNCOBRO_FECHAVENCE: item.TRNCOBRO_FECHAVENCE,
        p_pro_CODIGO: item.CLI_CODIGO,
        p_APLORG_CODIGO: 'CXP',
        p_trncobro_usuario_act: item.USU_IDENTIFICACION,
        p_trncobro_fecharecepcion: item.TRNCOBRO_FECHATRN,
        p_trncobro_referenciaexterna: item.TRNCOBRO_REFERENCIAEXTERNA,
        p_con_codigo: item.CON_CODIGO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  guardarTrnDocumento(strTrnCobroNroDoc, importe) {
    return this.http.post<any>(this.way + '/cxp/enccuota/43cadb7bbbb70c', {
      elementos: {
        p_trncobro_nrodoc: strTrnCobroNroDoc,
        p_pro_codigo: this.encCuotaArray[this.init.tabs[this.init.tabindex].indice].PRO_CODIGO,
        p_com_codigo: '01',
        p_trndocumento_nrodoc: this.encCuotaArray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_NUMERO,
        p_trndocumento_tipodoc: 'FC',
        p_trndocumento_importe: importe,
        p_trndocumento_descuento: 0,
        p_trndocumento_tipodscto: '',
        p_trndocumento_ref: 'F',
        p_trndocumento_flag: 0
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  actualizarEstadoFacturaProv(strCodProv, strTrnCobroNroDoc) {
    return this.http.post<any>(this.way + '/cxp/enccuota/533bceb5ea66780', {
      elementos: {
        p_pro_codigo: strCodProv,
        p_com_codigo: '01',
        p_trncobro_nrodoc: strTrnCobroNroDoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  calcularTotalCuotas() {
    let dblTotalCuotas = 0;
    
    for (let fila = 0; fila < this.detCuotaArray[this.init.tabs[this.init.tabindex].indice].length; fila++ ) {
      dblTotalCuotas = dblTotalCuotas + Number(this.detCuotaArray[this.init.tabs[this.init.tabindex].indice][fila].DETCUOTA_IMPORTE);
    }
    
    const strTotalCuotas = Number(dblTotalCuotas).toFixed(2);
    this.encCuotaArray[this.init.tabs[this.init.tabindex].indice].TOTAL_CUOTAS = strTotalCuotas;
  }
  
  eliminarDocumento() {
    return this.http.post<any>(this.way + '/cxp/enccuota/3ab2b4aeadb70c', {
      elementos: {
        p_ENCCUOTA_CODIGO: this.encCuotaArray[this.init.tabs[this.init.tabindex].indice].ENCCUOTA_CODIGO,
        p_COM_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  insertarVarios(encCuotaCodigo, linea, conCodigo, conNombre, cenCodigo, importe) {
    return this.http.post<any>(this.way + '/cxp/enccuota/4afc76feadcb1c', {
      elementos: {
        p_enccuota_codigo: encCuotaCodigo,
        p_trncuotasvar_linea: linea,
        p_com_codigo: '01',
        p_con_codigo: conCodigo,
        p_con_nombre: conNombre,
        p_cen_codigo: cenCodigo,
        p_trncuotasvar_importe: importe
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  generarAsientoContable(encCuotacodigo, tipDia) {
    return this.http.post<any>(this.way + '/cxp/enccuota/41d75724cc5fa8', {
      elementos: {
        p_enccuota_codigo: encCuotacodigo,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: tipDia
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
}
