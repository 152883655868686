<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="message"></p-toast>
<p-table [autoLayout]="true" selectionMode="single" *ngIf="selectedSubCat" [columns]="cols" [value]="selectedSubCat"
         editMode="row" [paginator]="true" [rows]="9" dataKey="SUB_CODIGO">
  <ng-template pTemplate="header" let-columns>
    <tr style="width: 100px; height: 50px">
      <th *ngFor="let col of columns" [pSortableColumn]="col.field">
        {{col.header}}
        <p-sortIcon [field]="col.field"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-rowData2 let-editing="editing" let-ri="rowIndex">
    <tr (click)="irPantalla(rowData)" [pEditableRow]="rowData" [ngStyle]="{'height':'30px'}">
      <td>
        {{rowData.CAT_CODIGO}}
      </td>
      <td>
        {{rowData.SUB_CODIGO}}
      </td>
      <td style="width: 150px">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input pInputText type="text" [(ngModel)]="rowData.SUB_NOMBRE" [ngStyle]="{'width':'100%'}" maxlength="50">
          </ng-template>
          <ng-template pTemplate="output">
            {{rowData.SUB_NOMBRE}}
          </ng-template>
        </p-cellEditor>
      </td>
      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input pInputText type="text" [(ngModel)]="rowData.SUB_CORTO" [ngStyle]="{'width':'100%'}" maxlength="10">
          </ng-template>
          <ng-template pTemplate="output">
            {{rowData.SUB_CORTO}}
          </ng-template>
        </p-cellEditor>
      </td>
      <td style="text-align:center">
        <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="pi pi-pencil" class="ui-button-info"
                style="margin-right: .5em" (click)="onRowEditInit(rowData)" title="Editar Subcategoria"></button>
        <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="pi pi-check" class="ui-button-success"
                style="margin-right: .5em" (click)="onRowEditSave(rowData)"></button>
        <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="pi pi-replay" class="ui-button-warning"
                (click)="onRowEditCancel()" style="margin-right: .5em"></button>
        <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="pi pi-times" class="ui-button-danger"
                style="margin-right: .5em" (click)="confirmation(rowData, ri)" title="Eliminar Subcategoria"></button>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="summary" let-rowData>
    <div style="text-align:left">
      <button type="button" pButton icon="pi pi-star" (click)="showDialogToAdd()"
              label="Añadir nueva SubCategoria"></button>
    </div>
  </ng-template>
</p-table>

<p-dialog (keyup.enter)="save()" header="Detalle Subcategoria" [(visible)]="displayDialog" [responsive]="true"
          [modal]="false" [style]="{width: '500px'}">
  <div class="ui-g ui-fluid" *ngIf="subcategoria">
    <div class="ui-g-12">
      <div class="ui-g-4">
        <label for="CAT_CODIGO">Código Categoria</label>
      </div>
      <div class="ui-g-8">
        <input pInputText id="CAT_CODIGO" [placeholder]="selectedSubcategoria" [defaultValue]="selectedSubcategoria"
               [disabled]="true" [(ngModel)]="subcategoria.CAT_CODIGO" maxlength="30"/>
      </div>
    </div>
    <div class="ui-g-12">
      <div class="ui-g-4">
        <label for="SUB_CODIGO">Código SubCategoria</label>
      </div>
      <div class="ui-g-8">
        <input pInputText id="SUB_CODIGO" [(ngModel)]="subcategoria.SUB_CODIGO" maxlength="30"/>
      </div>
    </div>
    <div class="ui-g-12">
      <div class="ui-g-4">
        <label for="SUB_NOMBRE">Nombre SubCategoria</label>
      </div>
      <div class="ui-g-8">
        <input pInputText id="SUB_NOMBRE" [(ngModel)]="subcategoria.SUB_NOMBRE" maxlength="50"/>
      </div>
    </div>
    <div class="ui-g-12">
      <div class="ui-g-4">
        <label for="SUB_CORTO">Nombre Corto</label>
      </div>
      <div class="ui-g-8">
        <input pInputText id="SUB_CORTO" [(ngModel)]="subcategoria.SUB_CORTO" maxlength="10"/>
      </div>
    </div>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button type="button" pButton icon="pi pi-check" (click)="save()" label="Save"></button>
    </div>
  </p-footer>
</p-dialog>
<p-confirmDialog key="confirmSubcat" appendTo="@(body)" [autoZIndex]="true" [closable]="true"
                 [transitionOptions]="'0ms'" header="Confirmacion" [style]="{width:'50vw'}"></p-confirmDialog>


