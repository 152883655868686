//
// SACI WEB
// Rutina: Maestro de bancos de clientes
// Propietario: Andrés Ruiz
// Modificado por: AR
// Fecha de creación: 18-11-2019
// Fecha de Modificación: 29-11-2019
//

import {Component, OnInit, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {Cxcmaeretencion} from '../cxcinterfaces/cxcmaeretencion';
import {CxcmaeretencionService} from '../cxcservicios/cxcmaeretencion.service';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Component({
  selector: 'app-cxcmaeretencion',
  templateUrl: './cxcmaeretencion.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class CxcmaeretencionComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  actualizar: boolean;
  codigoAux: any;
  nuevoCampo: boolean;
  desCodigo: boolean;
  stayScrolltoend: boolean;
  arregloCons: any[];
  largo: string;
  retencionSeleccionada: Cxcmaeretencion;
  permisoRetencion: SegMaePermiso;
  public frameworkComponents;
  rowStyle;
  defaultColDefretenciones;
  rowSelection = 'multiple';
  columnDefsretencion = [
    {
      headerName: 'Código', field: 'RETENCION_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: true
          }
        }
    },
    {
      headerName: 'Nombre', field: 'RETENCION_NOMBRE', cellEditor: 'cellPrueba', width: 250,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 100
          }
        }
    },
    {
      headerName: '%', field: 'RETENCION_PORCENTAJE', cellEditor: 'cellPrueba', width: 200,
      cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mmayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 4,
            tienecalculos: true
  
          }
        }
    },
    {
      headerName: 'Cta Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 35,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Cta Contable V', field: 'CON_CODIGO_VTA', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 35,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Tipo', field: 'RETENCION_TIPO', cellEditor: 'agSelectCellEditor', width: 200,
      cellEditorParams:
        {
          values: ['X', 'IVB', 'IVS']
        }
    },
    {
      headerName: 'TipoAnex', field: 'RETENCION_TIPOANEX', cellEditor: 'agSelectCellEditor', width: 200,
      cellEditorParams:
        {
          values: ['NDRC', 'NDNC']
        }
    },
    {
      headerName: 'CodSri', field: 'RETENCION_CODSRI', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10
          }
        }
    },
    {
      headerName: 'TipRet', field: 'RETENCION_TIPRET', cellEditor: 'agSelectCellEditor', width: 200,
      cellEditorParams:
        {
          
            values: ['RF', '' ]
          
        }
    },
    {
      headerName: 'Mult', field: 'RETENCION_MULT', cellEditor: 'agSelectCellEditor', width: 200, editable: true,
      cellEditorParams:
        {
          values: ['S', '' ]
        }
    },
  ];
  
  constructor(public cuentaContableServicio: CxcmaeretencionService, private message: MessageService,
              private errorService: ErroresBaseDatosService, private busqService: InvbusquedaService,
              private confirmationService: ConfirmationService, private permisosService: PermisosService,
              private auditoriagral: AuditoriagralService, private init: NuevoComponentService) {
    this.agTable();
    this.permisoRetencion = {};
    this.cuentaContableServicio.cxcmaeretencion = [ ];
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefretenciones = {
      width: 100,
      objeto: 'cxcmaerentencion',
      editable: true
    };
  }
  
  ngOnInit() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.actualizar = false;
    this.nuevoCampo = false;
    this.desCodigo = true;
    this.buscarCon();
    this.codigoAux = 0;
    this.permisosService.getListaPermisos('CXC', '1', '101').subscribe((res) => {
      this.permisoRetencion = res[0];
    });
    this.cuentaContableServicio.getretencionClient().subscribe((res) => {
     if (res !== null){
       this.cuentaContableServicio.cxcmaeretencion = res;
     }
     
    });
    this.largo = '400';
  }
  actualizarR() {
    this.cuentaContableServicio.actualizarRetencion(this.retencionSeleccionada, this.codigoAux).subscribe(() => {
      this.message.add({
        key: 'cxcmaeretencion',
        severity: 'success',
        summary: 'Actualización exitosa',
        detail: 'Se actualizó la retención correctamente'
      });
      this.auditoriagral.registrarAuditoria('CXC_MAERETENCION', String(this.retencionSeleccionada.RETENCION_CODIGO), 'A',
        '', '01', '', '', '', '').subscribe(() => {});
      this.aggrid.isEditable(this.btnNuevo);
    }, error1 => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.message.add({
        key: 'cxcmaeretencion',
        severity: 'error',
        summary: 'Actualización de la retención',
        detail: mensaje
      });
      console.log(error1.error);
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.cuentaContableServicio.getretencionClient().subscribe((res) => {
        this.cuentaContableServicio.cxcmaeretencion = res;
        this.aggrid.refreshaggrid(this.cuentaContableServicio.cxcmaeretencion, this.defaultColDefretenciones.objeto);
      });
    });
  }
  insertarR() {
    this.cuentaContableServicio.insertarRetencion(this.retencionSeleccionada).subscribe(() => {
      this.message.add({
        key: 'cxcmaeretencion',
        severity: 'success',
        summary: 'Inserción exitosa',
        detail: 'Se insertó la retencion correctamente'
      });
      this.auditoriagral.registrarAuditoria('CXC_MAERETENCION', String(this.retencionSeleccionada.RETENCION_CODIGO), 'I',
        '', '01', '', '', '', '').subscribe(() => {});
      this.aggrid.isEditable(this.btnNuevo);
      this.nuevoCampo = false;
    }, error1 => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.message.add({
        key: 'cxcmaeretencion',
        severity: 'error',
        summary: 'Inserción de la retencion',
        detail: mensaje
      });
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.cuentaContableServicio.getretencionClient().subscribe((res) => {
        this.cuentaContableServicio.cxcmaeretencion = res;
        this.aggrid.refreshaggrid(this.cuentaContableServicio.cxcmaeretencion, this.defaultColDefretenciones.objeto);
      });
    });
  }
  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      if (this.permisoRetencion.PERINSERCION === 1) {
        this.btnNuevo = true;
        this.btnGuardar = false;
        this.btnBorrar = false;
        this.btnRegresar = false;
        this.nuevoCampo = true;
        this.stayScrolltoend = true;
        this.desCodigo = false;
        this.aggrid.isEditable(this.btnNuevo);
        this.retencionSeleccionada = {
          RETENCION_CODIGO: '',
          RETENCION_NOMBRE: '',
          RETENCION_PORCENTAJE: '',
          CON_CODIGO: '',
          CON_CODIGO_VTA: '',
          RETENCION_TIPO: 'X',
          RETENCION_TIPOANEX: '',
          RETENCION_CODSRI: '',
          RETENCION_TIPRET: '',
          COM_CODIGO: '01',
          RETENCION_MULTIPLE: '',
        };
        this.cuentaContableServicio.cxcmaeretencion.push(this.retencionSeleccionada);
        this.aggrid.refreshaggrid(this.cuentaContableServicio.cxcmaeretencion, this.defaultColDefretenciones.objeto);
      } else if (this.permisoRetencion.PERINSERCION === 0) {
        this.message.add({
          key: 'cxcmaerentencion',
          severity: 'error',
          summary: 'Inserción de Retención',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    }
    if (valor === 'Guardar') {
      
      if (this.permisoRetencion.PERACTUALIZACION === 1) {
        this.cuentaContableServicio.erFindCodRet(this.retencionSeleccionada.RETENCION_CODIGO).subscribe(async existe => {
          
          this.btnGuardar = true;
          this.btnNuevo = false;
          this.btnBorrar = false;
          this.btnRegresar = true;
          let coincidencias;
          let coincidencias2;
          coincidencias = 0;
          coincidencias2 = 0;
        // this.arregloCons.map((con) => {
          //   if (this.retencionSeleccionada.CON_CODIGO === con.CON_CODIGO) {
          //     coincidencias = true;
          //   }
          //   if (this.retencionSeleccionada.CON_CODIGO_VTA === con.CON_CODIGO) {
          //      coincidencias2 = true;
          //   }
          // });
          const rsdata: any = await this.cuentaContableServicio.encontrarArtCntaySer(this.retencionSeleccionada.CON_CODIGO, 'C');
          const rsdata2: any = await this.cuentaContableServicio.encontrarArtCntaySer(this.retencionSeleccionada.CON_CODIGO_VTA, 'C');
          //let strCodigo = 0;
          if (rsdata !== null) {
            for (const rs of rsdata) {
              coincidencias = rs.NUMERO;
            }
          }
          if (rsdata2 !== null) {
            for (const rs of rsdata2) {
              coincidencias2 = rs.NUMERO;
            }
          }
          if (existe !== null) {
           
            if (this.retencionSeleccionada.CON_CODIGO === null) {
              this.retencionSeleccionada.CON_CODIGO = '';
            }
            if (this.retencionSeleccionada.CON_CODIGO_VTA === null) {
              this.retencionSeleccionada.CON_CODIGO_VTA = '';
            }
            if ((this.retencionSeleccionada.CON_CODIGO.length === 0 && this.retencionSeleccionada.CON_CODIGO_VTA.length === 0)) {
              this.actualizarR();
            } else if (this.retencionSeleccionada.CON_CODIGO.length !== 0 && this.retencionSeleccionada.CON_CODIGO_VTA.length === 0) {
              if (coincidencias>0) {
                this.actualizarR();
              } else {
                this.message.add({
                  key: 'cxcmaeretencion',
                  severity: 'error',
                  summary: 'Error en Cuenta Contable ',
                  detail: 'La cuenta contable   ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
                });
                this.retencionSeleccionada.CON_CODIGO = '';
                this.aggrid.refreshaggrid(this.cuentaContableServicio.cxcmaeretencion, this.defaultColDefretenciones.objeto);
    
              }
  
            } else if (this.retencionSeleccionada.CON_CODIGO.length === 0 && this.retencionSeleccionada.CON_CODIGO_VTA.length !== 0) {
              if (coincidencias2>0) {
                this.actualizarR();
              } else {
                this.message.add({
                  key: 'cxcmaeretencion',
                  severity: 'error',
                  summary: 'Error en Cuenta Contable V ',
                  detail: 'La cuenta contable V ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
                });
                this.retencionSeleccionada.CON_CODIGO = '';
                this.aggrid.refreshaggrid(this.cuentaContableServicio.cxcmaeretencion, this.defaultColDefretenciones.objeto);
    
              }
  
            } else {
              if (coincidencias>0 && coincidencias2>0 ) {
               this.actualizarR();
              } else if (coincidencias === 0) {
                this.message.add({
                  key: 'cxcmaeretencion',
                  severity: 'error',
                  summary: 'Error en Cuenta Contable',
                  detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
                });
                this.retencionSeleccionada.CON_CODIGO = '';
                this.aggrid.refreshaggrid(this.cuentaContableServicio.cxcmaeretencion, this.defaultColDefretenciones.objeto);
              } else if (coincidencias2=0) {
                this.message.add({
                  key: 'cxcmaeretencion',
                  severity: 'error',
                  summary: 'Error en Cuenta Contable VTA',
                  detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
                });
                this.retencionSeleccionada.CON_CODIGO_VTA = '';
                this.aggrid.refreshaggrid(this.cuentaContableServicio.cxcmaeretencion, this.defaultColDefretenciones.objeto);
              }
  
            }
          
            
          } else {
            if (this.retencionSeleccionada.CON_CODIGO.length === 0 && this.retencionSeleccionada.CON_CODIGO_VTA.length === 0) {
              this.insertarR();
            } else if (this.retencionSeleccionada.CON_CODIGO.length === 0 && this.retencionSeleccionada.CON_CODIGO_VTA.length !== 0) {
              console.log('okay asdf');
              if (coincidencias2>0) {
                this.insertarR();
              } else {
                this.message.add({
                  key: 'cxcmaeretencion',
                  severity: 'error',
                  summary: 'Error en Cuenta Contable',
                  detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
                });
                this.retencionSeleccionada.CON_CODIGO = '';
                this.aggrid.refreshaggrid(this.cuentaContableServicio.cxcmaeretencion, this.defaultColDefretenciones.objeto);
              }
  
            } else if (this.retencionSeleccionada.CON_CODIGO.length !== 0 && this.retencionSeleccionada.CON_CODIGO_VTA.length === 0) {
              if (coincidencias>0) {
                this.insertarR();
              } else {
                this.message.add({
                  key: 'cxcmaeretencion',
                  severity: 'error',
                  summary: 'Error en Cuenta Contable ',
                  detail: 'La cuenta contable   ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
                });
                this.retencionSeleccionada.CON_CODIGO = '';
                this.aggrid.refreshaggrid(this.cuentaContableServicio.cxcmaeretencion, this.defaultColDefretenciones.objeto);
              }
  
            } else {
              
              
              if (coincidencias>0 && coincidencias2>0) {
                this.insertarR();
              } else if (coincidencias === 0) {
                this.message.add({
                  key: 'cxcmaeretencion',
                  severity: 'error',
                  summary: 'Error en Cuenta Contable ',
                  detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
                });
                this.retencionSeleccionada.CON_CODIGO_VTA = '';
                this.aggrid.refreshaggrid(this.cuentaContableServicio.cxcmaeretencion, this.defaultColDefretenciones.objeto);
              } else if (coincidencias2 === 0) {
                this.message.add({
                  key: 'cxcmaeretencion',
                  severity: 'error',
                  summary: 'Error en Cuenta Contable VTA',
                  detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
                });
                this.retencionSeleccionada.CON_CODIGO_VTA = '';
                this.aggrid.refreshaggrid(this.cuentaContableServicio.cxcmaeretencion, this.defaultColDefretenciones.objeto);
              }
            }
            
          } //
        });
        this.aggrid.isEditable(this.btnNuevo);
      } else if (this.permisoRetencion.PERACTUALIZACION === 0) {
        this.message.add({
          key: 'cxcmaeretencion',
          severity: 'error',
          summary: 'Actualización de Retencion',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }
    }
    if (valor === 'Borrar') {
      console.log('okay');
      if (this.permisoRetencion.PERELIMACION === 1) {
        if (this.retencionSeleccionada === undefined) {
          this.message.add({
            key: 'cxcmaerentencion',
            severity: 'error',
            summary: 'Eliminacion de Retención',
            detail: 'Seleccion Incorrecta, no se ha seleccionado una retención'
          });
        } else {
          this.btnNuevo = false;
          this.aggrid.isEditable(this.btnNuevo);
          this.confirmarRetencion();
        }
      } else if (this.permisoRetencion.PERELIMACION === 0) {
        this.message.add({
          key: 'cxcmaeretencion',
          severity: 'error',
          summary: 'Eliminación de Retención',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
      }
    }
    
    if (valor === 'Cancelar') {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.aggrid.isEditable(this.btnNuevo);
      this.cuentaContableServicio.getretencionClient().subscribe((res) => {
        this.cuentaContableServicio.cxcmaeretencion = res;
        this.aggrid.refreshaggrid(this.cuentaContableServicio.cxcmaeretencion, this.defaultColDefretenciones.objeto);
      });
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
  selectedRetencion(valor) {
    if (valor.object !== null) {
      this.retencionSeleccionada = valor.object;
      if (this.nuevoCampo === true) {
        this.actualizar = false;
      } else if (this.nuevoCampo === false) {
        this.actualizar = true;
        if (this.retencionSeleccionada !== undefined) {
          this.codigoAux = this.retencionSeleccionada.COM_CODIGO;
        }
      }
    }
  }
  
  confirmarRetencion() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el banco',
      header: 'Eliminar banco',
      icon: 'pi pi-exclamation-triangle',
      key: 'cxcmaeretencionConfirmacion',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarRetencion();
      },
      reject: () => {
      }
    });
  }
  
  buscarCon() {
    this.busqService.busquedaConMaeCon().subscribe((res: any[]) => {
      this.arregloCons = res;
    });
  }
  
  eliminarRetencion() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.cuentaContableServicio.eliminarRetencion(this.retencionSeleccionada).subscribe(() => {
      this.message.add({
        key: 'cxcmaeretencion',
        severity: 'success',
        summary: 'Eliminación exitosa',
        detail: 'Se eliminó la retención correctamente'
      });
      this.auditoriagral.registrarAuditoria('CXC_MAERETENCION', String(this.retencionSeleccionada.RETENCION_CODIGO), 'E',
        '', '01', '', '', '', '').subscribe(() => {});
  
      this.cuentaContableServicio.cxcmaeretencion = this.cuentaContableServicio.cxcmaeretencion.filter((val, j) => j !==
        this.cuentaContableServicio.cxcmaeretencion.indexOf(this.retencionSeleccionada));
      this.aggrid.refreshaggrid(this.cuentaContableServicio.cxcmaeretencion, this.defaultColDefretenciones.objeto);
    }, error1 => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.message.add({
        key: 'cxcmaeretencion',
        severity: 'error',
        summary: 'Eliminacion de el banco',
        detail: mensaje
      });
    });
  }
  
  cambio(cambio) {
    this.btnGuardar = false;
    this.btnRegresar = false;
  }
}


