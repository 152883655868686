import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Observable} from 'rxjs';
import {Invmaegrupo} from '../invinterfaces/invmaegrupo';
import {MessageService} from 'primeng/api';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class InvgrupoService {

  public url: string;
  public way: string;
  public invmaegrupos: Invmaegrupo[];
  private gruposAux: Invmaegrupo[];
  private subgruposAux: Invmaegrupo[];
  public invmaesubgrupos: Invmaegrupo[];
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, 
              private messageService: MessageService, 
              private auditoriagral: AuditoriagralService,
              private confIniciales: ConfInicialesService) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }
  
  getGrupo(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/grupos/83bb0dfb38', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  saveGrupo(grupo) {
    let contador = 0;
    let i = 0;
    this.getGrupo().subscribe((res) => {
      console.log(res);
      if (res.length !== 0) {
        this.gruposAux = res;
        do {
          if (grupo.GRUP_CODIGO === this.gruposAux[i].GRUP_CODIGO) {
            contador++;
          }
          i++;
        } while (this.gruposAux.length > i);
        if (contador === 1) {
          let j = 0;
          let cont1 = 0;
          do {
            if (grupo.GRUP_CODIGO === this.invmaegrupos[j].GRUP_CODIGO) {
              cont1++;
            }
            j++;
          } while (this.invmaegrupos.length > j);
          if (cont1 === 2) {
            this.getGrupo().subscribe((respuesta) => {
              this.gruposAux = respuesta;
              this.invmaegrupos = this.gruposAux;
              this.messageService.add({
                key: 'grup',
                severity: 'error',
                summary: 'Inserción de Grupo',
                detail: 'El grupo no se insertó, el código ya existe'
              });
            });
          } else if (cont1 === 1) {
            this.actualizarGrupo(grupo).subscribe(() => {
              this.messageService.add({
                key: 'grup',
                severity: 'success',
                summary: 'Actualización de Grupo',
                detail: 'El grupo se actualizó correctamente'
              });
              this.auditoriagral.registrarAuditoria('INV_MAEGRUPO', String(grupo.GRUP_CODIGO), 'A', '',
                '01', '', '', '', '').subscribe(() => {
              });
            });
          }
        } else {
          if (grupo.GRUP_CODIGO !== '' && grupo.GRUP_NOMBRE) {
            this.guardarGrupo(grupo).subscribe(() => {
              this.messageService.add({
                key: 'grup',
                severity: 'success',
                summary: 'Inserción de Grupo',
                detail: 'El grupo se insertó correctamente'
              });
              this.auditoriagral.registrarAuditoria('INV_MAEGRUPO', String(grupo.GRUP_CODIGO), 'I', '',
                '01', '', '', '', '').subscribe(() => {
              });
            });
          } else {
            /*this.botonGuardar = false;
            this.botonRegresar = false;
            this.botonNuevo = true;*/
            this.messageService.add({
              key: 'grup',
              severity: 'error',
              summary: 'Inserción de Grupo',
              detail: 'El grupo no se insertó, ingrese los campos obligatorios (*)'
            });
          }
        }
      } else {
        if (grupo.GRUP_CODIGO !== '' && grupo.GRUP_NOMBRE) {
          this.guardarGrupo(grupo).subscribe(() => {
            this.messageService.add({
              key: 'grup',
              severity: 'success',
              summary: 'Inserción de Grupo',
              detail: 'El grupo se insertó correctamente'
            });
            this.auditoriagral.registrarAuditoria('INV_MAEGRUPO', String(grupo.GRUP_CODIGO), 'I', '',
              '01', '', '', '', '').subscribe(() => {
            });
          });
        } else {
          /*this.botonGuardar = false;
          this.botonRegresar = false;
          this.botonNuevo = true;*/
          this.messageService.add({
            key: 'grup',
            severity: 'error',
            summary: 'Inserción de Grupo',
            detail: 'El grupo no se insertó, ingrese los campos obligatorios (*)'
          });
        }
      }
    });
  }
  
  saveSubGrupo(subgrupo, grupo) {
    console.log(subgrupo, grupo);
    let contador1 = 0;
    let a = 0;
    this.getSubgrupo(grupo.GRUP_CODIGO).subscribe((res) => {
      this.subgruposAux = res;
      if (this.subgruposAux.length === 0) {
        subgrupo.GRUP_CODIGOP = grupo.GRUP_CODIGO;
        this.guardarGrupo(subgrupo).subscribe(() => {
          this.messageService.add({
            key: 'grup',
            severity: 'success',
            summary: 'Inserción de SubGrupo',
            detail: 'El Subgrupo se insertó correctamente'
          });
        });
      } else {
        do {
          if (subgrupo.GRUP_CODIGO === this.subgruposAux[a].GRUP_CODIGO) {
            contador1++;
          }
          a++;
        } while (this.subgruposAux.length > a);
        if (contador1 === 1) {
          subgrupo.GRUP_CODIGOP = grupo.GRUP_CODIGO;
          let j = 0;
          let cont1 = 0;
          do {
            if (subgrupo.GRUP_CODIGO === this.invmaesubgrupos[j].GRUP_CODIGO) {
              cont1++;
            }
            j++;
          } while (this.invmaesubgrupos.length > j);
          if (cont1 === 2) {
            this.getSubgrupo(grupo.GRUP_CODIGO).subscribe((res1 => {
              this.subgruposAux = res1;
              this.invmaesubgrupos = this.subgruposAux;
              this.messageService.add({
                key: 'grup',
                severity: 'error',
                summary: 'Inserción de SubGrupo',
                detail: 'El Subgrupo no se insertó, el código ya existe'
              });
            }));
          } else if (cont1 === 1) {
            this.actualizarGrupo(subgrupo).subscribe(() => {
              this.messageService.add({
                key: 'grup',
                severity: 'success',
                summary: 'Actualización de SubGrupo',
                detail: 'El Subgrupo se actualizó correctamente'
              });
              this.auditoriagral.registrarAuditoria('INV_MAEGRUPO', String(grupo.GRUP_CODIGO), 'A', '',
                '01', '', '', '', '').subscribe(() => {
              });
            });
          }
        } else {
          subgrupo.GRUP_CODIGOP = grupo.GRUP_CODIGO;
          if (subgrupo.GRUP_CODIGO !== '' && subgrupo.GRUP_NOMBRE !== '') {
            this.guardarGrupo(subgrupo).subscribe(() => {
              this.messageService.add({
                key: 'grup',
                severity: 'success',
                summary: 'Inserción de SubGrupo',
                detail: 'El Subgrupo se insertó correctamente'
              });
              this.auditoriagral.registrarAuditoria('INV_MAEGRUPO', String(grupo.GRUP_CODIGO), 'I', '',
                '01', '', '', '', '').subscribe(() => {
              });
            });
          } else {
            /*this.botonGuardar = false;
            this.botonRegresar = false;
            this.botonNuevo = true;*/
            this.messageService.add({
              key: 'grup',
              severity: 'error',
              summary: 'Inserción de SubGrupo',
              detail: 'El Subgrupo no se insertó, ingrese los valores obligatorios (*)'
            });
          }
        }
      }
    });
  }
  
  guardarGrupo(grupo: Invmaegrupo): Observable<any> {
    console.log(grupo);
    return this.http.post<any>(this.way + '/inventario/grupos/8795b6ab70dfb00', {            
      elementos: {
        p_GRUP_codigo_1: grupo.GRUP_CODIGO,
        p_GRUP_nombre_2: grupo.GRUP_NOMBRE,
        p_CON_codigo_3: grupo.CON_CODIGO,
        p_CEN_codigo: grupo.CEN_CODIGO,
        p_GRUP_codigop_4: grupo.GRUP_CODIGOP,
        p_GRUP_VALOR1: grupo.GRUP_VALOR1,
        p_GRUP_VALOR2: grupo.GRUP_VALOR2,
        p_GRUP_VALOR3: grupo.GRUP_VALOR3,
        p_GRUP_VALOR4: grupo.GRUP_VALOR4,
        p_GRUP_FACTOR: grupo.GRUP_FACTOR,
        p_CON_CODIGOACT: grupo.CON_CODIGOACT,
        p_CON_CODIGOGAS: grupo.CON_CODIGOGAS,
        p_CON_CODIGOING: grupo.CON_CODIGOING,
      }
    }, this.confIniciales.httpOptions());
  }
  
  actualizarGrupo(grupo): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/grupos/299df2ab2', {            
      elementos: {
        p_GRUP_codigo_1: grupo.GRUP_CODIGO,
        p_GRUP_codigo_2: grupo.GRUP_CODIGO,
        p_GRUP_nombre_3: grupo.GRUP_NOMBRE,
        p_CON_codigo_4: grupo.CON_CODIGO,
        p_CEN_codigo: grupo.CEN_CODIGO,
        p_GRUP_codigop_5: grupo.GRUP_CODIGOP,
        p_GRUP_VALOR1: grupo.GRUP_VALOR1,
        p_GRUP_VALOR2: grupo.GRUP_VALOR2,
        p_GRUP_VALOR3: grupo.GRUP_VALOR3,
        p_GRUP_VALOR4: grupo.GRUP_VALOR4,
        p_GRUP_FACTOR: grupo.GRUP_FACTOR,
        p_CON_CODIGOACT: grupo.CON_CODIGOACT,
        p_CON_CODIGOGAS: grupo.CON_CODIGOGAS,
        p_CON_CODIGOING: grupo.CON_CODIGOING,
      }
    }, this.confIniciales.httpOptions());
  }
  
  borrarGrupo(grupoCOD, grupoCODP): Observable<any> {

    return this.http.post<any>(this.way + '/inventario/grupos/2f1bdab70dfb38', {            
      elementos: {
        p_GRUP_codigo_1: grupoCOD,
        p_GRUP_codigo_2: grupoCODP
      }
    }, this.confIniciales.httpOptions());
  }
  
  getSubgrupo(grupoCod): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/inventario/grupos/41dde7970dfb38', {      
      elementos: {
        GRUP_CODIGOP: grupoCod,
      },
    }, this.confIniciales.httpOptions());
  }
  
  insertarExtras(grupo): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/grupos/12bf1dbeab6e', {            
      elementos: {
        p_GRUP_CODIGO: grupo.GRUP_CODIGO,
        p_GRUP_GARANTIAS: grupo.GRUP_GARANTIAS,
        p_GRUP_OTROSCON: grupo.GRUP_OTROSCON
      }
    }, this.confIniciales.httpOptions());
  }
  
}
