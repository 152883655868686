<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="nomcf"></p-toast>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="" [botonGuardar]="btnGuardar" [botonBorrar]=""
						 [botonRegresar]="btnRegresar" [barraBotonesCRUD]=""
						 [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="false" [barraBotones5]="true"
						 (signal)="manejarSenales($event)"
						 >

		</app-invmaebarra>
	</div>
</div>
<p-tabView>
	<p-tabPanel header="Configuración Nómina" *ngIf="cargabool==true">
		<div>
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-5">
								<span> % Aporte Individual: </span>
							</div>
							<div class="p-col-2">
								<input class="frm"  type="text" pInputText (keydown)="cambio($event)" style="text-align: right"
									   [(ngModel)]="nomcfgrgen.NOMAPIND" appTwoDigitDecimaNumber>
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-5">
								<span> % Aporte Patronal: </span>
							</div>
							<div class="p-col-2">
								<input class="frm"  type="text" (keydown)="cambio($event)" style="text-align: right"
									   [(ngModel)]="nomcfgrgen.NOMAPPATR" appTwoDigitDecimaNumber>
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-5">
								<span> % Fondos de Reserva: </span>
							</div>
							<div class="p-col-2">
								<input class="frm"  type="text" pInputText (keydown)="cambio($event)" style="text-align: right"
									   [(ngModel)]="nomcfgrgen.NOMPRFNRSV" appTwoDigitDecimaNumber>
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-5">
								<span> Calcula Base Imponible y Retención: </span>
							</div>
							<div class="p-col-2">
								<p-checkbox (onChange)="cambio($event)"
										binary="true" [(ngModel)]="nomcfgrgen.NOMCARE"	>
								</p-checkbox>
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-5">
								<span> Salario Básico: </span>
							</div>
							<div class="p-col-2">
								<input class="frm"  type="text" pInputText (keydown)="cambio($event)" style="text-align: right"
									   [(ngModel)]="nomcfgrgen.NOMSUEBAS" appTwoDigitDecimaNumber>
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-5">
								<span> Factor Hora Extra 100%: </span>
							</div>
							<div class="p-col-2">
								<input class="frm"  type="text"  (keydown)="cambio($event)" style="text-align: right"
									   [(ngModel)]="nomcfgrgen.NOMFCHE100" appTwoDigitDecimaNumber>
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-5">
								<span> Factor Hora Extra 50%: </span>
							</div>
							<div class="p-col-2">
								<input class="frm"  type="text" pInputText (keydown)="cambio($event)" style="text-align: right"
									   [(ngModel)]="nomcfgrgen.NOMFCHE50" appTwoDigitDecimaNumber>
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-5">
								<span> Factor Hora Extra 25%: </span>
							</div>
							<div class="p-col-2">
								<input class="frm"  type="text" pInputText (keydown)="cambio($event)" style="text-align: right"
									   [(ngModel)]="nomcfgrgen.NOMFCHE25" appTwoDigitDecimaNumber>
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-5">
								<span> Horas trabajadas al mes: </span>
							</div>
							<div class="p-col-2">
								<input class="frm"  type="text" (keydown)="cambio($event)" style="text-align: right"
									   [(ngModel)]="nomcfgrgen.NOMHOTRME" appTwoDigitDecimaNumber>
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-5">
								<span> Número de días por mes: </span>
							</div>
							<div class="p-col-2">
								<input class="frm"  type="text" pInputText (keydown)="cambio($event)" style="text-align: right"
									   [(ngModel)]="nomcfgrgen.NOMDIASMES" appTwoDigitDecimaNumber>
							</div>
						</div>
					</div><!--p-col-12-->
				</div><!-- p-grid-->
			</div><!-- p-grid-->
		</div><!-- divgrups-->
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-5">
							<span> Calcular días reales para entradas y salidas: </span>
						</div>
						<div class="p-col-1">
							<p-checkbox (onChange)="cambio($event)"
									binary="true" [(ngModel)]="nomcfgrgen.NOMCDRES">
							</p-checkbox>
						</div>
					</div><!-- p-grid-->
					<div class="p-grid">
						<div class="p-col-5">
							<span> Calcular días reales para novedades: </span>
						</div>
						<div class="p-col-1">
							<p-checkbox (onChange)="cambio($event)"
									binary="true" [(ngModel)]="nomcfgrgen.NOMCDRPN">
							</p-checkbox>
						</div>
					</div><!-- p-grid-->
					<div class="p-grid">
						<div class="p-col-5">
							<span> Descontar Iess Personal por días de enfermedad 25%: </span>
						</div>
						<div class="p-col-1">
							<p-checkbox (onChange)="cambio($event)"
									binary="true" [(ngModel)]="nomcfgrgen.NOMIESS25">
							</p-checkbox>
						</div>
					</div><!-- p-grid-->
					<div class="p-grid">
						<div class="p-col-5">
							<span> Enfermedad sin paga (Iess asume el 100% de enfermedad): </span>
						</div>
						<div class="p-col-1">
							<p-checkbox (onChange)="cambio($event)"
									binary="true" [(ngModel)]="nomcfgrgen.NOMESP100">
							</p-checkbox>
						</div>
					</div><!-- p-grid-->
				</div><!--p-col-12-->
			</div><!-- p-grid-->
		</div><!-- divgrups-->
	</p-tabPanel>
</p-tabView>
