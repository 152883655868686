import { Component, OnInit, ViewChild } from '@angular/core';
import { Usuario } from 'src/app/usuario';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { AggridBanComponent } from 'src/app/ban/aggrid-ban/aggrid-ban.component';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { isNullOrUndefined } from 'util';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { MessageService, ConfirmationService, SelectItem } from 'primeng';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { DatePipe } from '@angular/common';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { PermisosService } from 'src/app/advantage/advantageservices/permisos.service';
import { NomliqudecitercService } from '../nomservicios/nomliqudeciterc.service';
import { nomliqucabecera, nomliquvarios, nomliqudetalle } from '../nominterfaces/nomliqudeciterc';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
@Component({
  selector: 'app-nomliqudeciterc',
  templateUrl: './nomliqudeciterc.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NomliqudecitercComponent implements OnInit {
  @ViewChild('detalle') aggrid: AggridsaciComponent;
  @ViewChild('varios') vaggrid: AggridsaciComponent;

  btnGuardar: boolean;
  btnNuevo: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  btnBuscar: boolean;
  botonAnterior: boolean;
  botonPrimero: boolean;
  botonUltimo: boolean;
  botonSiguiente: boolean;
  btnBorrAsieCo: boolean;
  btnGenArText: boolean;
  btnVisAsiC: boolean;
  btnGenAsiC: boolean;
  indexTab: number = 0;
  fecha = new Date();
  identiUsuario = '';
  selectcabe: nomliqucabecera = new nomliqucabecera();
  btnConLiq: boolean = false;
  btnReveEstLiq: boolean = false;
  displayAsiContable: boolean = false;
  displayDialogBusqArray: boolean = false;
  asiento: string = "";
  displayDialogClave: boolean = false;
  displayDialog2Clave: boolean = false;
  cod: string = "CNFLIQDE";
  selectednomliquvar: nomliquvarios;
  selectednomdetliq: nomliqudetalle;
  displaydialogTxt: boolean = false;
  //Totales
  dblHORA_TIEMPARC = 0;
  dblACUM_HORAPROV = 0;
  dblACUM_DIASPROVTOTA = 0;
  dblACUM_BASEPROVTOTA = 0;
  dblACUM_VALOPROVTOTA = 0;
  dblACUM_DIASPAGA = 0;
  dblACUM_BASEPROVPAGA = 0;
  dblACUM_VALOPROVPAGA = 0;
  dblAJUS_PROV = 0;
  dblANTI_PROV = 0;
  dblDESC_PROV = 0;
  dblDETLIQUBENESOCI_DESCRETEJUDI = 0;
  dblDETLIQUBENESOCI_NETOAPAGAR = 0;
  //as
  descRubro: string = ""
  arregloRubro = [];
  dispDiausFastRubro: boolean = false;
  types: SelectItem[];
  busquedacampos = [];
  tabla: string;
  consulta: string;
  arregloCons: any[] = [];
  arrlegoBus = [];
  where: string;
  opcion: string;
  condicion: string;
  //Tabla Detalle
  displayDialogBusquedaFast: boolean = false;
  displayDialogReportes: boolean;
  public frameworkComponents;
  rowStyle;
  largo = '500';
  defaultColDefDetliqdcab;
  rowSelection = 'single';

  // reporte
  auxreporte: number = 0;
  strNombreForma: string;

  displayDialogDocumentos = false;

  columnnomliqdcab = [
    {
      headerName: 'Codigo Empleado', editable: true, field: 'EMP_CODIGO', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: false,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Cédula', editable: false, field: 'EMP_CODIGO2', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: false,

        }
      }
    },
    {
      headerName: 'Nombres', editable: false, field: 'EMP_NOMBRE', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: false,

        }
      }
    },
    {
      headerName: 'Apellidos', editable: true, field: 'EMP_APELLIDO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: false,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Cargo Iess', editable: true, field: 'DETLIQUBENESOCI_CARGIESSDESC', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: false,

        }
      }
    },
    {
      headerName: 'Sexo', editable: true, field: 'DETLIQUBENESOCI_SEXO', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: true,
          tamanocelda: 100,
          tienecalculos: false,

        }
      }
    },
    {
      headerName: 'Discapacitado', editable: true, field: 'DETLIQUBENESOCI_PORCDISC', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: true,
          tamanocelda: 100,
          tienecalculos: false,

        }
      }
    },
    {
      headerName: 'Jornada Parcial', editable: (params) => {
        const boolEdit = this.isEditIngre(params.data);
        return boolEdit;
      }, field: 'DETLIQUBENESOCI_TRABJORNPARC', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: false,

        }
      }
    },
    {
      headerName: 'Horas Tiempo Parcial', cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEditIngre(params.data);
        return boolEdit;
      }, field: 'DETLIQUBENESOCI_HORATIEMPARC', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
        this.calcular(params);
      }
    },
    {
      headerName: 'Acumulado de Horas Provisión', cellStyle: { textAlign: 'right' }, editable: false, field: 'DETLIQUBENESOCI_ACUMHORAPROV', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }
    },
    {
      headerName: 'Acumulado de Días Provisión Total', cellStyle: { textAlign: 'right' }, editable: false, field: 'DETLIQUBENESOCI_ACUMDIAPRTOT', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }
    },
    {
      headerName: 'Acumulado Base de Provisión Total', cellStyle: { textAlign: 'right' }, editable: false, field: 'DETLIQUBENESOCI_ACUMBASPRTOT', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }
    },
    {
      headerName: 'Acumulado o Valor de Provisión Total', cellStyle: { textAlign: 'right' }, editable: false, field: 'DETLIQUBENESOCI_ACUMVALPRTOT', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }
    },
    {
      headerName: 'Acumulado de Días Pagados', cellStyle: { textAlign: 'right' }, editable: false, field: 'DETLIQUBENESOCI_ACUMDIAPAGA', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }
    },
    {
      headerName: 'Acumulado de Base de Provisión Pagados', cellStyle: { textAlign: 'right' }, editable: false, field: 'DETLIQUBENESOCI_ACUMBASPRPAG', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }
    },
    {
      headerName: 'Acumulado de Valor de Provisión Pagados', cellStyle: { textAlign: 'right' }, editable: false, field: 'DETLIQUBENESOCI_ACUMVALPRPAG', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }
    },
    {
      headerName: 'Ajuste Provisión', editable: false, cellStyle: { textAlign: 'right' }, field: 'DETLIQUBENESOCI_AJUSPROV', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }
    },
    {
      headerName: 'Anticipo de Provisión', editable: false, cellStyle: { textAlign: 'right' }, field: 'DETLIQUBENESOCI_ANTIPROV', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }
    },
    {
      headerName: 'Descuento Provisión', editable: (params) => {
        const boolEdit = this.isEditIngre(params.data);
        return boolEdit;
      }, cellStyle: { textAlign: 'right' }, field: 'DETLIQUBENESOCI_DESCPROV', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
        this.calcular(params);
      }
    },
    {
      headerName: 'Forma de Pago', editable: (params) => {
        const boolEdit = this.isEditIngre(params.data);
        return boolEdit;
      }, field: 'DETLIQUBENESOCI_FORMPAGODESC', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Banco de Pago', editable: (params) => {
        const boolEdit = this.isEditIngre(params.data);
        return boolEdit;
      }, field: 'DETLIQUBENESOCI_BANCPAGODESC', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Tipo de Cuenta', editable: (params) => {
        const boolEdit = this.isEditIngre(params.data);
        return boolEdit;
      }, field: 'DETLIQUBENESOCI_TIPOCUENDESC', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Número de Cuenta ', editable: (params) => {
        const boolEdit = this.isEditIngre(params.data);
        return boolEdit;
      }, field: 'DETLIQUBENESOCI_NUMECUEN', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,

        }
      }
    },

    {
      headerName: 'Descuento por Retencion Judicial', editable: (params) => {
        const boolEdit = this.isEditIngre(params.data);
        return boolEdit;
      }, field: 'DETLIQUBENESOCI_DESCRETEJUDI', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,

        }
      }, onCellValueChanged: (params) => {
        this.calcular(params);
      }
    },
    {
      headerName: 'Neto a Pagar', editable: false, cellStyle: { textAlign: 'right' }, field: 'dblTOTA_APAGAR', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,

        }
      }
    }
  ];
  //Tabla Detalle
  defaultColDefDetliqdvar;
  columnnomliqdvar = [
    {
      headerName: 'Cuenta', editable: true, field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: false,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Descripción', editable: false, field: 'DETLIQUBENSOCVAR_DESCRIPCION', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: false,

        }
      }
    },
    {
      headerName: 'CC', editable: false, field: 'CEN_CODIGO', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: false,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Debe', editable: true, field: 'DETLIQUBENSOCVAR_DEBE', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }
    },
    {
      headerName: 'Haber', editable: true, field: 'DETLIQUBENSOCVAR_HABER', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }
    }
  ]
  constructor(
    public liqservice: NomliqudecitercService,
    private init: NuevoComponentService,
    private permisosService: PermisosService,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private confirmationService: ConfirmationService,
    private auditoriagral: AuditoriagralService,
    private busquedaSer: InvbusquedaService,
    private datePipe: DatePipe,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) {
    this.btnGuardar = true;
    this.btnNuevo = false;
    this.btnBorrar = false;
    this.btnRegresar = false;
    this.botonAnterior = false;
    this.botonPrimero = false;
    this.botonUltimo = false;
    this.btnBuscar = false;
    this.botonSiguiente = false;
    this.btnBorrAsieCo = true;
    this.btnGenArText = true;
    this.btnVisAsiC = true;
    this.btnGenAsiC = true;
    this.agTable();
    this.datePipe.transform(this.fecha, 'dd/MM/yy');
    this.identiUsuario = this.usuario.identificacion;
  }

  ngOnInit(): void {
    try {
      this.liqservice.nomliqdet=[];//GSRF
      this.liqservice.nomliquvar=[];// GSRF
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
    } catch (e) {

    }
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.defaultColDefDetliqdcab = {
      editable: true,
      width: 130,
      objeto: 'nomliqdeceterc'
    };
    this.defaultColDefDetliqdvar = {
      editable: true,
      width: 130,
      objeto: 'nomliqdecetervar'
    };
  }
  onTab1Change(valor) {

  }
  busquedaRubro() {

  }
  botonesmodificar() {
    this.btnBuscar = true;

    this.btnNuevo = true;
    this.btnRegresar = false;
  }
  setFocus(elemento) {

  }
  async manejarSenales(valor) {
    switch (valor) {
      case 'Nuevo':
        this.opcionNuevo();
        break;
      case 'Guardar':
        this.opcionGuardar();
        break;
      case 'Borrar':
        this.opcionBorrar();
        break;
      case 'Cancelar':
        this.opcionRecargar();
        break;
      case 'Primero':
        await this.Primero();
        break;
      case 'Anterior':
        await this.Anterior();
        break;
      case 'Siguiente':
        await this.Siguiente();
        break;
      case 'Ultimo':
        await this.Ultimo();
        break;
      case 'ejecutarliq':
        await this.ejeculiq();
        break;
      case 'revliq':
        await this.reverliq();
        break;
      case 'Buscar':
        this.buscarCondicion();
        break;
      case 'borrasiescont':
        this.opcBorrasiescont();
        break;
      case 'genasiecont':
        this.opcGenasiecont();
        break;
      case 'visasiecont':
        this.opcVisasiecont();
        break;
      case 'genarchtext':
        this.opcGenarchtext();
        break;
      case 'Imprimir':
        this.strNombreForma = this.init.tabs[this.init.tabindex].component;
        this.auxreporte++;
        this.displayDialogReportes = true;
        break;
      case 'Salir':
        this.init.tabs.splice(this.init.tabindex, 1);
        break;
      case 'Almacenardoc':
        if (this.selectcabe.MAERUBRO_CODRUBR !== '') {
          const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
          if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
            this.displayDialogDocumentos = true;
          } else {
            this.messageService.add({
              key: 'novedad',
              severity: 'info',
              summary: 'Información',
              detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
            });
          }

        }
        break;

    }

  }
  async Primero() {
    const pri = await this.liqservice.primeroliqu().then(async res => {
      const lis = await this.liqservice.lisliqucabecera(res[0]["MIN(ENCLIQUBENESOCI_CODIGO)"]).then(async (re: any) => {
        this.selectcabe = await this.liqservice.convertcabecera(re[0]);

        if (this.selectcabe.ENCLIQUBENESOCI_ESTADO == "CONFIRMADO") {
          this.btnReveEstLiq = true;
          this.btnConLiq = false;
          this.btnGenAsiC = false;
          this.btnBorrAsieCo = false;
        } else {

          this.btnReveEstLiq = false;
          this.btnConLiq = true;
          this.btnGenAsiC = true;
          this.btnBorrAsieCo = true;
        }

        console.log(this.selectcabe);
        const det = await this.liqservice.lisliqudetalle(res[0]["MIN(ENCLIQUBENESOCI_CODIGO)"], this.selectcabe).then(async (as: any) => {
          this.liqservice.nomliqdet = await this.liqservice.busquedaTabla(this.selectcabe, as);
          console.log(this.liqservice.nomliqdet);
          this.llenarParte2();
          const vari = await this.liqservice.lisliquvarios(res[0]["MIN(ENCLIQUBENESOCI_CODIGO)"]).then((ve: any) => {
            this.liqservice.nomliquvar = ve;
            let i = 0;
            for (let vari of this.liqservice.nomliquvar) {
              vari.NUMLIQV = i;
              i++;
            }
            this.aggrid.refreshaggrid(this.liqservice.nomliqdet, this.defaultColDefDetliqdcab.objeto);
            //this.egraggrid.refreshaggrid( this.liqservice.nomliquvar, this.defaultColDefDetliqdvar.objeto )
            try {
              document.getElementById('divPrincipal').style.pointerEvents = 'all';
            } catch (e) {

            }
          })
        })
      })
    })
  }
  llenarParte2() {
    let res = this.liqservice.funObtieneTotales();
    this.dblHORA_TIEMPARC = this.liqservice.cambiarFloatFixed(res[0]);
    this.dblACUM_HORAPROV = this.liqservice.cambiarFloatFixed(res[1]);
    this.dblACUM_DIASPROVTOTA = this.liqservice.cambiarFloatFixed(res[2]);
    this.dblACUM_BASEPROVTOTA = this.liqservice.cambiarFloatFixed(res[3]);
    this.dblACUM_VALOPROVTOTA = this.liqservice.cambiarFloatFixed(res[4]);
    this.dblACUM_DIASPAGA = this.liqservice.cambiarFloatFixed(res[5]);
    this.dblACUM_BASEPROVPAGA = this.liqservice.cambiarFloatFixed(res[6]);
    this.dblACUM_VALOPROVPAGA = this.liqservice.cambiarFloatFixed(res[7]);
    this.dblAJUS_PROV = this.liqservice.cambiarFloatFixed(res[8]);
    this.dblANTI_PROV = this.liqservice.cambiarFloatFixed(res[9]);
    this.dblDESC_PROV = this.liqservice.cambiarFloatFixed(res[10]);
    this.dblDETLIQUBENESOCI_DESCRETEJUDI = this.liqservice.cambiarFloatFixed(res[11]);
    this.dblDETLIQUBENESOCI_NETOAPAGAR = this.liqservice.cambiarFloatFixed(res[12]);
    console.log(res[12]);
  }
  llenarCambios() {
    //Jona Cam
  }
  async Ultimo() {
    const ult = await this.liqservice.ultimoliqu().then(async res => {
      const lis = await this.liqservice.lisliqucabecera(res[0]["MAX(ENCLIQUBENESOCI_CODIGO)"]).then(async (re: any) => {
        this.selectcabe = await this.liqservice.convertcabecera(re[0]);
        if (this.selectcabe.ENCLIQUBENESOCI_ESTADO == "CONFIRMADO") {
          this.btnReveEstLiq = true;
          this.btnConLiq = false;
          this.btnGenAsiC = false;
          this.btnBorrAsieCo = false;
        } else {

          this.btnReveEstLiq = false;
          this.btnConLiq = true;
          this.btnGenAsiC = true;
          this.btnBorrAsieCo = true;
        }
        const det = await this.liqservice.lisliqudetalle(res[0]["MAX(ENCLIQUBENESOCI_CODIGO)"], this.selectcabe).then(async (as: any) => {
          this.liqservice.nomliqdet = await this.liqservice.busquedaTabla(this.selectcabe, as);
          this.llenarParte2();
          const vari = await this.liqservice.lisliquvarios(res[0]["MAX(ENCLIQUBENESOCI_CODIGO)"]).then((ve: any) => {
            this.liqservice.nomliquvar = ve;
            let i = 0;
            for (let vari of this.liqservice.nomliquvar) {
              vari.NUMLIQV = i;
              i++;
            }
            this.aggrid.refreshaggrid(this.liqservice.nomliqdet, this.defaultColDefDetliqdcab.objeto);
            //this.egraggrid.refreshaggrid( this.liqservice.nomliquvar, this.defaultColDefDetliqdvar.objeto )
            try {
              document.getElementById('divPrincipal').style.pointerEvents = 'all';
            } catch (e) {

            }
          })
        })
      })
    })
  }
  async Anterior() {
    console.log(this.selectcabe.ENCLIQUBENESOCI_CODIGO);
    if (isNullOrUndefined(this.selectcabe.ENCLIQUBENESOCI_CODIGO) || this.selectcabe.ENCLIQUBENESOCI_CODIGO == '') {
      this.liqservice.nomliquvar = [];
      return;
    }
    try {
      const ult = await this.liqservice.anteriorliqu(this.selectcabe.ENCLIQUBENESOCI_CODIGO).then(async res => {
        const lis = await this.liqservice.lisliqucabecera(res[0]["MAX(ENCLIQUBENESOCI_CODIGO)"]).then(async (re: any) => {
          this.selectcabe = await this.liqservice.convertcabecera(re[0]);
          if (this.selectcabe.ENCLIQUBENESOCI_ESTADO == "CONFIRMADO") {
            this.btnReveEstLiq = true;
            this.btnConLiq = false;
            this.btnGenAsiC = false;
            this.btnBorrAsieCo = false;
          } else {

            this.btnReveEstLiq = false;
            this.btnConLiq = true;
            this.btnGenAsiC = true;
            this.btnBorrAsieCo = true;
          }
          const det = await this.liqservice.lisliqudetalle(res[0]["MAX(ENCLIQUBENESOCI_CODIGO)"], this.selectcabe).then(async (as: any) => {
            this.liqservice.nomliqdet = await this.liqservice.busquedaTabla(this.selectcabe, as);
            this.llenarParte2();
            const vari = await this.liqservice.lisliquvarios(res[0]["MAX(ENCLIQUBENESOCI_CODIGO)"]).then((ve: any) => {
              this.liqservice.nomliquvar = ve;
              let i = 0;
              for (let vari of this.liqservice.nomliquvar) {
                vari.NUMLIQV = i;
                i++;
              }
              this.aggrid.refreshaggrid(this.liqservice.nomliqdet, this.defaultColDefDetliqdcab.objeto);
              //this.egraggrid.refreshaggrid( this.liqservice.nomliquvar, this.defaultColDefDetliqdvar.objeto )
              try {
                document.getElementById('divPrincipal').style.pointerEvents = 'all';
              } catch (e) {

              }
            })
          })
        })
      })
    } catch (e) {
      console.log(e.error);
    }
  }
  async Siguiente() {
    console.log(this.selectcabe.ENCLIQUBENESOCI_CODIGO);
    if (isNullOrUndefined(this.selectcabe.ENCLIQUBENESOCI_CODIGO) || this.selectcabe.ENCLIQUBENESOCI_CODIGO == '') {
      this.liqservice.nomliquvar = [];
      return;
    }
    try {
      const ult = await this.liqservice.siguienteliqu(this.selectcabe.ENCLIQUBENESOCI_CODIGO).then(async res => {
        console.log(res);
        const lis = await this.liqservice.lisliqucabecera(res[0]["MIN(ENCLIQUBENESOCI_CODIGO)"]).then(async (re: any) => {
          this.selectcabe = await this.liqservice.convertcabecera(re[0]);
          if (this.selectcabe.ENCLIQUBENESOCI_ESTADO == "CONFIRMADO") {
            this.btnReveEstLiq = true;
            this.btnConLiq = false;
            this.btnGenAsiC = false;
            this.btnBorrAsieCo = false;
          } else {

            this.btnReveEstLiq = false;
            this.btnConLiq = true;
            this.btnGenAsiC = true;
            this.btnBorrAsieCo = true;
          }
          const det = await this.liqservice.lisliqudetalle(res[0]["MIN(ENCLIQUBENESOCI_CODIGO)"], this.selectcabe).then(async (as: any) => {
            this.liqservice.nomliqdet = await this.liqservice.busquedaTabla(this.selectcabe, as);
            this.llenarParte2();
            const vari = await this.liqservice.lisliquvarios(res[0]["MIN(ENCLIQUBENESOCI_CODIGO)"]).then((ve: any) => {
              this.liqservice.nomliquvar = ve;
              let i = 0;
              for (let vari of this.liqservice.nomliquvar) {
                vari.NUMLIQV = i;
                i++;
              }
              this.aggrid.refreshaggrid(this.liqservice.nomliqdet, this.defaultColDefDetliqdcab.objeto);
              //this.egraggrid.refreshaggrid( this.liqservice.nomliquvar, this.defaultColDefDetliqdvar.objeto )
              try {
                document.getElementById('divPrincipal').style.pointerEvents = 'all';
              } catch (e) {

              }
            })
          })
        })
      })
    } catch (e) {
      console.log(e.error);
    }
  }
  async buscarCondicion() {
    if (this.btnNuevo == true) {
      const er = await this.liqservice.funCtrlAsienContableCuadrado(this.btnBuscar, this.selectcabe.ENCLIQUBENESOCI_CODIGO);
      console.log(er);
      if (er == false) {
        this.mensajeAlerta('error', 'Error', 'Error, el asiento no esta cuadrado');
        return;
      }
    }

    this.types = [
      { label: 'Cód', value: 'ENCLIQUBENESOCI_CODIGO' },
      { label: 'Rubro', value: 'MAERUBRO_CODRUBR' },
      { label: 'Fecha Inicio Provición', value: 'ENCLIQUBENESOCI_FECINIPROV' },
      { label: 'Fecha Fin Provición', value: 'ENCLIQUBENESOCI_FECFINPROV' },
      { label: 'Estado', value: 'ENCLIQUBENESOCI_ESTADO' }
    ];
    this.busquedacampos = ['', '', '', ''];
    this.consulta = ' ENCLIQUBENESOCI_CODIGO,MAERUBRO_CODRUBR,ENCLIQUBENESOCI_FECINIPROV,ENCLIQUBENESOCI_FECFINPROV,ENCLIQUBENESOCI_ESTADO';
    this.tabla = 'nom_encliqubenesoci';
    this.where = '';
    this.liqservice.buscarbeneficiosSoc().then((datos: any[]) => {

      this.arregloCons = datos;
      this.arregloCons.map(x => {
        x.ENCLIQUBENESOCI_FECINIPROV = this.liqservice.cambiarfechanull(x.ENCLIQUBENESOCI_FECINIPROV);
        x.ENCLIQUBENESOCI_FECFINPROV = this.liqservice.cambiarfechanull(x.ENCLIQUBENESOCI_FECFINPROV);
      })
      this.displayDialogBusquedaFast = true;
    });
  }

  async manejarSeleccionbus(condicion) {
    this.displayDialogBusquedaFast = false;

    const lis = await this.liqservice.lisliqucabecera(condicion.ENCLIQUBENESOCI_CODIGO).then(async (re: any) => {
      this.selectcabe = await this.liqservice.convertcabecera(re[0]);
      console.log(this.selectcabe.ASI_NRO);
      if (this.selectcabe.ENCLIQUBENESOCI_ESTADO == "CONFIRMADO") {
        this.btnReveEstLiq = true;
        this.btnConLiq = false;
        this.btnGenAsiC = false;
        this.btnBorrAsieCo = false;
      } else {

        this.btnReveEstLiq = false;
        this.btnConLiq = true;
        this.btnGenAsiC = true;
        this.btnBorrAsieCo = true;
      }
      const det = await this.liqservice.lisliqudetalle(condicion.ENCLIQUBENESOCI_CODIGO, this.selectcabe).then(async (as: any) => {
        this.liqservice.nomliqdet = await this.liqservice.busquedaTabla(this.selectcabe, as);
        this.llenarParte2();
        const vari = await this.liqservice.lisliquvarios(condicion.ENCLIQUBENESOCI_CODIGO).then((ve: any) => {
          this.liqservice.nomliquvar = ve;
          let i = 0;
          for (let vari of this.liqservice.nomliquvar) {
            vari.NUMLIQV = i;
            i++;
          }
          this.aggrid.refreshaggrid(this.liqservice.nomliqdet, this.defaultColDefDetliqdcab.objeto);
          //this.egraggrid.refreshaggrid( this.liqservice.nomliquvar, this.defaultColDefDetliqdvar.objeto )
          try {
            document.getElementById('divPrincipal').style.pointerEvents = 'all';
          } catch (e) {

          }
        })
      })
    })
  }
  selecnomliqdcab(valor) {
    console.log(valor);
    if (valor === 'nuevo') {
      //this.nuevoIngrFijo();
    }

    if (valor === 'eliminar') {
      //this.eliminarIngFijo();
    }

    if (valor === 'cancelar') {
      //await  this.cancelarIngFijo();
    }
    if (valor === 'btnGridBuscar') {
      this.opcionBuscarDetliq();
    }
    if (valor.object !== undefined) {
      this.selectednomdetliq = valor.object;
    }
  }
  cambionomliqdcab() {

  }
  selecnomliqdvar(valor) {
    if (valor === 'nuevo') {
      this.nuevoVarliq();
    }

    if (valor === 'eliminar') {
      this.eliminarVarliq();
    }

    if (valor === 'cancelar') {
      return;
    }
    if (valor === 'btnGridBuscar') {
      this.opcionBuscarDetliq();
    }
    if (valor.object !== undefined) {
      this.selectednomliquvar = valor.object;
    }
  }
  cambionomliqdvar(event) {

  }
  nuevoVarliq() {
    /*let er = this.funVerificaInformCabeceraNew(this.selectcabe);
    if (er == "") {


    } else {
      this.mensajeAlerta('error', 'Error', er);

      return;
    }*/
    if (this.funVerificaInformCabeceraNew(this.selectcabe) === false) {
      return;
    }
    this.vaggrid.isEditable(true);
    const newModulo: nomliquvarios = {
      CEN_CODIGO: '',
      COM_CODIGO: '',
      CON_CODIGO: '',
      CON_COMCODIGO: '',
      DETLIQUBENSOCVAR_DEBE: 0.00,
      DETLIQUBENSOCVAR_DESCRIPCION: '',
      DETLIQUBENSOCVAR_HABER: 0.00,
      DETLIQUBENSOCVAR_NUMSEC: '',
      DETLIQUBENSOCVAR_ORDEN: '',
      ENCLIQUBENESOCI_CODIGO: '',
    };
    this.btnGuardar = false;
    this.btnNuevo = true;
    this.btnRegresar = false;
    this.btnBuscar = true;
    try {
      this.liqservice.nomliquvar.push(newModulo);
      this.vaggrid.refreshaggrid(this.liqservice.nomliquvar, this.defaultColDefDetliqdvar.objeto);
      const indice = this.liqservice.nomliquvar.indexOf(newModulo);
      this.vaggrid.gridApi.setFocusedCell(indice, 'EMP_CODIGO');
    } catch (err) {
      console.error(err)
    }
  }
  eliminarVarliq() {
    if (this.selectednomliquvar !== undefined) {
      this.confirmationService.confirm({
        message: '¿Está seguro que desea eliminar?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'NomVarLiq',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: async () => {
          try {
            this.liqservice.eliminaLineaDetVarios(this.selectednomliquvar).then(async res => {
              const vari = await this.liqservice.lisliquvarios(this.selectcabe.ENCLIQUBENESOCI_CODIGO).then((ve: any) => {
                this.liqservice.nomliquvar = ve;
                let i = 0;
                for (let vari of this.liqservice.nomliquvar) {
                  vari.NUMLIQV = i;
                  i++;
                };
                this.vaggrid.refreshaggrid(this.liqservice.nomliquvar, this.defaultColDefDetliqdvar.objeto);
                this.mensajeAlerta('success', 'Información', '¡Se eliminó el registro correctamente!');
              })

            })
          } catch (e) {
            console.error(e.error);
          }


        },
        reject: () => {
        }
      })
    }

  }
  cambio(event) {

  }
  async opcionAceptar() {
    console.log(this.selectcabe.ENCLIQUBENESOCI_ESTADO);
    if (this.selectcabe.ENCLIQUBENESOCI_ESTADO == "CONFIRMADO") {
      return;
    }
    if (this.funVerificaInformCabeceraNew(this.selectcabe) === false) {
      return;
    }

    if (await this.funVeriProvGenePerioNew(this.selectcabe) === false) {
      return;
    }

    const elim = await this.liqservice.elimtrapenliqbensocrol(this.selectcabe);
    const inser = await this.liqservice.insertrapenliqbensocrol(this.selectcabe);
    const list: any = await this.liqservice.funlistadecimosWeb(this.selectcabe);
    console.log(list);
    this.liqservice.nomliqdet = await this.liqservice.busquedaTabla2(this.selectcabe, list);
    this.llenarParte2();
    this.aggrid.refreshaggrid(this.liqservice.nomliqdet, this.defaultColDefDetliqdcab.objeto);
    this.btnGuardar = false;
    this.btnNuevo = true;
    this.btnRegresar = false;
    this.btnBuscar = true;


    /*let er = this.liqservice.funVerificaInformCabecera(this.selectcabe);
    if (er == "") {


    } else {
      this.mensajeAlerta('error', 'Error', er);

      return;
    }
    try {
      let bo = await this.funVeriProvGenePerio(this.selectcabe);
      console.log(bo);
      if (bo == true) {
        const elim = await this.liqservice.elimtrapenliqbensocrol(this.selectcabe);
        const inser = await this.liqservice.insertrapenliqbensocrol(this.selectcabe);
        const list: any = await this.liqservice.funlistadecimosWeb(this.selectcabe);
        console.log(list);
        this.liqservice.nomliqdet = await this.liqservice.busquedaTabla2(this.selectcabe, list);
        this.llenarParte2();
        this.aggrid.refreshaggrid(this.liqservice.nomliqdet, this.defaultColDefDetliqdcab.objeto);
        this.btnGuardar = false;
        this.btnNuevo = true;
        this.btnRegresar = false;
        this.btnBuscar = true;
      } else {
        return;
      }

    } catch (e) {
      console.error(e.error);
    }*/
  }
  async funVeriProvGenePerio(cod: nomliqucabecera) {
    console.log(cod);
    if (isNullOrUndefined(cod.ENCLIQUBENESOCI_CODIGO)) {
      cod.ENCLIQUBENESOCI_CODIGO = 0;
    }
    return new Promise(async (resolve) => {
      const verprod = await this.liqservice.conVeriProvGenePerio(cod).then(res => {
        if (res[0]["COUNT(*)"] == 0) {

        } else {

          if (cod != undefined || cod != null) {

            this.confirmationService.confirm({
              message: '¿Este período ya ha sido generado, desea generar nuevamente?',
              header: 'Confirmación',
              icon: 'pi pi-exclamation-triangle',
              key: 'nomCaba',
              acceptLabel: 'Sí',
              rejectLabel: 'No',
              accept: async () => {
                try {
                  const ele = await this.liqservice.funEliminaDetalleBeneSoci(cod.ENCLIQUBENESOCI_CODIGO).then(res => {
                    console.log('si funca');
                    resolve(true);
                  })
                } catch (e) {
                  console.log(e.error);
                  resolve(false);
                }
              },
              reject: () => {
                resolve(false);
              }
            })
          }
        }
      })
    })
  }
  async funVeriProvGenePerioNew(cod: nomliqucabecera) {
    console.log(cod);
    if (isNullOrUndefined(cod.ENCLIQUBENESOCI_CODIGO)) {
      cod.ENCLIQUBENESOCI_CODIGO = 0;
    }

    try {
      const res: any = await this.liqservice.conVeriProvGenePerioNew(cod);
      if (res[0]["COUNT(*)"] == 0) {
        return true;
      } else {
        if (cod != undefined || cod != null) {

          this.confirmationService.confirm({
            message: '¿Este período ya ha sido generado, desea generar nuevamente?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            key: 'nomCaba',
            acceptLabel: 'Sí',
            rejectLabel: 'No',
            accept: async () => {
              if (await this.funEliminaDetalleBeneSoci(cod.ENCLIQUBENESOCI_CODIGO) === true) {
                return true;
              } else {
                return false;
              }


            },
            reject: () => {
              return false;
            }
          })
        }
      }

    } catch (err) {
      return false;
    }
  }

  async funEliminaDetalleBeneSoci(cod: nomliqucabecera) {
    try {
      await this.liqservice.funEliminaDetalleBeneSociNew(cod.ENCLIQUBENESOCI_CODIGO);
    } catch (err) {
      return false;
    }
    return true;
  }
  busRubro(parametro) {
    if (parametro == null || parametro == undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCODIREMUSALAUNIF';
    this.types = [
      { label: 'CODIGO', value: 'REF_CODIGO' },
      { label: 'NOMBRE', value: 'REF_NOMBRE' },
      { label: 'NOMBRE CORTO', value: 'REF_NOMBREC' },

    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = 'REF_CODIGO,REF_NOMBRE, REF_NOMBREC,REF_TIPO';
    this.tabla = 'SACI_MAEREFERENCIA';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\'' + " AND  REF_TIPO = 'NOMCDC'";
    this.liqservice.busRubro(parametro).then((res: any[]) => {
      if (res != null) {
        if (res[0] != undefined) {
          this.liqservice.bustablarubro(parametro).then((res: any[]) => {
            this.arregloRubro = res;
            this.dispDiausFastRubro = true;
          });
        } else {
          this.liqservice.bustablarubro(parametro).then((res: any[]) => {
            this.arregloRubro = res;
            this.dispDiausFastRubro = true;
          });
        }
      } else {
        this.liqservice.bustablarubro(parametro).then((res: any[]) => {
          this.arregloRubro = res;
          this.dispDiausFastRubro = true;
        });
      }
    });
  }
  async manejarRubro(event) {
    this.selectcabe.MAERUBRO_CODRUBR = event.REF_CODIGO;
    this.dispDiausFastRubro = false;
    this.selectcabe.descRubro = event.REF_NOMBRE;
    try {
      const infdcter = await this.liqservice.funllenaInformaciondecimotercero(this.selectcabe);
      console.log(infdcter);
      this.selectcabe.ENCLIQUBENESOCI_FECINIPROV = this.liqservice.cambiarfechanull(infdcter[0].MAERUBRO_FECINI);
      this.selectcabe.ENCLIQUBENESOCI_FECFINPROV = this.liqservice.cambiarfechanull(infdcter[0].MAERUBRO_FECFIN);
      this.botonesmodificar();
    } catch (e) {
      console.error(e)
    }
  }
  opcionNuevo() {
    let nuevo: nomliqucabecera = new nomliqucabecera()
    this.selectcabe = nuevo;
    this.liqservice.nomliqdet = [];
    this.liqservice.nomliquvar = [];
    this.liqservice.periodoActual().then(res => {
      this.selectcabe.PERIODO_FECINI = this.liqservice.cambiarfechanull(res[0].PERIODO_FECINI);
      this.selectcabe.PERIODO_FECFIN = this.liqservice.cambiarfechanull(res[0].PERIODO_FECFIN);
      this.selectcabe.PERIODO_CODIGO = this.liqservice.cambiarfechanull(res[0].PERIODO_CODIGO);
    });
    this.liqservice.sueldoActual().then(res => {
      this.selectcabe.ENCLIQUBENESOCI_SALABASIUNIF = this.liqservice.cambiarfechanull(res[0].CFG_VALOR1);

    })
    try {
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
    } catch (e) {

    }
  }
  opcionBorrar() {
    if (this.selectcabe !== undefined) {
      if (this.selectcabe.ENCLIQUBENESOCI_ESTADO == "CONFIRMADO") {

        this.mensajeAlerta('error', 'Error', 'No puede borrar la liquidación esta CONFIRMADA');
        return;
      }
      this.confirmationService.confirm({
        message: '¿Está seguro que desea eliminar?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'NomDetLiq',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {
          try {
            this.liqservice.borrarliq(this.selectcabe, this.selectcabe.ASI_NRO).then(res => {
              this.mensajeAlerta('success', 'Información', '¡Se eliminó el registro correctamente!');
              this.opcionNuevo();
            })
          } catch (e) {
            console.error(e.error);
          }


        },
        reject: () => {
        }
      })
    }
  }
  async opcionGuardar() {
    if (this.liqservice.nomliqdet.length == 0) {
      this.mensajeAlerta('error', 'Error', 'La liquidación debe tener al menos un item');
      return;
    }
    let res = this.funVerificaInformCabeceraNew(this.selectcabe);
    let i = 1;
    if (res) {
      if (this.indexTab === 0) {
        try {
          const cab: any = await this.liqservice.guardarcabliqNew(this.selectcabe, this.identiUsuario);
          this.selectcabe.ENCLIQUBENESOCI_CODIGO = cab[0]['V_ENCLIQUBENESOCI_CODIGO'];
        } catch (e) {
          this.mensajeAlerta('error', 'Error', e.error);
          return;

        }
        i = 1;
        for (let det of this.liqservice.nomliqdet) {
          try {
            const as = await this.liqservice.guardardetliq(det, i, this.selectcabe.ENCLIQUBENESOCI_CODIGO);
            i++;
          } catch (e) {
            this.mensajeAlerta('error', 'Error', 'Ha Ocurrido Un Error En La Transacción');
            return;
          }
        }
        this.opcionRecargar();
      }
      if (this.indexTab === 1) {
        i = 0;
        for (let vari of this.liqservice.nomliquvar) {
          try {
            await this.liqservice.guardarvarliq(vari, i++, this.selectcabe.ENCLIQUBENESOCI_CODIGO);
            await this.opcionRecargar();
            this.opcGenasiecont();
            this.indexTab = 0;
          } catch (e) {
            this.mensajeAlerta('error', 'Error', 'Ha Ocurrido Un Error En La Transacción');
            return;
          }
        }
      }
      this.mensajeAlerta('success', 'Información', '¡Se agregó correctamente!');
    } else {
      this.mensajeAlerta('error', 'Error', 'Ha Ocurrido Un Error En La Transacción');
    }
  }

  async opcionRecargar() {
    this.liqservice.nomliqdet = [];
    this.liqservice.nomliquvar = [];
    console.log(this.selectcabe.ENCLIQUBENESOCI_CODIGO);
    const lis = await this.liqservice.lisliqucabecera(this.selectcabe.ENCLIQUBENESOCI_CODIGO).then(async (re: any) => {
      this.selectcabe = await this.liqservice.convertcabecera(re[0]);
      console.log(this.selectcabe.ASI_NRO);
      if (this.selectcabe.ENCLIQUBENESOCI_ESTADO == "CONFIRMADO") {
        this.btnReveEstLiq = true;
        this.btnConLiq = false;
        this.btnGenAsiC = false;
        this.btnBorrAsieCo = false;
      } else {

        this.btnReveEstLiq = false;
        this.btnConLiq = true;
        this.btnGenAsiC = true;
        this.btnBorrAsieCo = true;
      }

      const det = await this.liqservice.lisliqudetalle(this.selectcabe.ENCLIQUBENESOCI_CODIGO, this.selectcabe).then(async (as: any) => {
        this.liqservice.nomliqdet = await this.liqservice.busquedaTabla(this.selectcabe, as);
        this.llenarParte2();
        const vari = await this.liqservice.lisliquvarios(this.selectcabe.ENCLIQUBENESOCI_CODIGO).then((ve: any) => {
          this.liqservice.nomliquvar = ve;
          let i = 0;
          for (let vari of this.liqservice.nomliquvar) {
            vari.NUMLIQV = i;
            i++;
          }
          this.aggrid.refreshaggrid(this.liqservice.nomliqdet, this.defaultColDefDetliqdcab.objeto);
        })
      })
    })
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = false;
    this.btnBuscar = false;
  }
  async opcBorrasiescont() {

    if (this.selectcabe.ENCLIQUBENESOCI_CODIGO == 0) {
      return;
    }
    try {
      const ele = await this.liqservice.elimasieconta(this.selectcabe, this.selectcabe.ASI_NRO);
      this.selectcabe.ASI_NRO = "";
    } catch (e) {
      this.mensajeAlerta('error', 'Error', 'Ha Ocurrido Un Error En La Transacción');
    }
  }
  async opcGenasiecont() {

    if (this.btnGuardar == false) {
      this.mensajeAlerta('error', 'Error', 'Para generar el asiento contable primero guarde la transacción');
      return;
    }
    try {
      const gene: any = await this.liqservice.generarasiento(this.selectcabe.ENCLIQUBENESOCI_CODIGO, this.identiUsuario);
      for (const rs of gene) {
        this.selectcabe.ASI_NRO = rs[":B1"];
      }
    } catch (e) {

    }


  }
  opcVisasiecont() {
    if (this.btnGuardar == false) {
      this.mensajeAlerta('error', 'Error', 'No ha generado el Asiento Contable');
      return;
    }
    this.asiento = this.selectcabe.ASI_NRO,
      console.log(this.asiento);
    this.displayAsiContable = true;

  }
  opcGenarchtext() {

    this.selectcabe.ENCLIQUBENESOCI_CODIGO;
    if (this.selectcabe.ENCLIQUBENESOCI_CODIGO == 0) {
      return;
    }
    if (this.selectcabe.ENCLIQUBENESOCI_ESTADO != "CONFIRMADO") {
      return;
    }
    this.displaydialogTxt = true;
    //ENCLIQUBENESOCI_CODIGO
  }
  opcionBuscarDetliq() {
    this.buscarRegistro();
  }
  recibirSenalesConEncasil(event) {

  }
  manejarSelecGridbus(elemento) {

    for (let a of elemento) {
      let valor = this.liqservice.nomliqdet.find(ele => ele.EMP_CODIGO == a.EMP_CODIGO);
      if (isNullOrUndefined(valor)) {
        continue;
      } else {
        const indice = this.liqservice.nomliqdet.indexOf(valor);
        this.aggrid.gridApi.setFocusedCell(indice, 'EMP_CODIGO');
        this.aggrid.refreshaggridindex(this.liqservice.nomliqdet, this.defaultColDefDetliqdcab.objeto, indice);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefDetliqdcab.objeto].setFocusedCell(
          indice, 'EMP_CODIGO');
        return;
      }
    }
    this.mensajeAlerta('error', 'Error', 'No existe ningún registro');
    return;
  }
  buscarRegistro() {
    this.opcion = 'BUSCAR';
    this.types = [
      { label: 'Código', value: 'EMP_CODIGO' },
      { label: 'Nombre', value: 'EMP_NOMBRE' },
      { label: 'Apellido', value: 'EMP_APELLIDO' },

    ];

    this.busquedacampos = ['', '', ''];
    this.consulta = 'EMP_CODIGO,EMP_NOMBRE,EMP_APELLIDO';
    this.tabla = 'NOM_MAEEMPLEADO';
    this.condicion = ' ROWNUM<50 order by EMP_APELLIDO';
    this.arregloCons = [];
    this.displayDialogBusqArray = true;
  }
  async ejeculiq() {
    const er = await this.liqservice.funCtrlAsienContableCuadrado(this.btnBuscar, this.selectcabe.ENCLIQUBENESOCI_CODIGO);
    if (er == false) {
      this.mensajeAlerta('error', 'Error', 'Error, el asiento no esta cuadrado');
      return;
    }

    if (isNullOrUndefined(this.selectcabe.ASI_NRO) || this.selectcabe.ASI_NRO == "") {
      this.mensajeAlerta('error', 'Error', 'Error, el asiento no esta generado');
      return;
    }
    if (this.selectcabe.ENCLIQUBENESOCI_CODIGO == 0) {
      return;
    }
    this.displayDialog2Clave = true;
  }
  reverliq() {
    if (this.selectcabe.ENCLIQUBENESOCI_CODIGO == 0) {
      return;
    }
    this.displayDialogClave = true;
  }
  salirc(event) {

  }
  //ejecutar
  async autorizados(valor) {
    if (valor == true) {
      try {
        this.displayDialog2Clave = false;
        const eje = await this.liqservice.ejecliqui(this.selectcabe);
        const conf = await this.liqservice.funConfirmaTransLiquBeneSoci(this.selectcabe);
        const ac = await this.liqservice.funActualizaEstadoProvision(this.selectcabe, 'CONFIRMADO');
        this.opcionRecargar();
      } catch (e) {
        console.error(e)
      }
    } else {

    }
  }
  //reversar
  async autoriza(valor) {
    if (valor == true) {
      try {
        console.log('entra');
        this.displayDialogClave = false;
        const rever = await this.liqservice.reverliq(this.selectcabe);
        const re = await this.liqservice.funReversaTransLiquBeneSoci(this.selectcabe);
        const ac = await this.liqservice.funActualizaEstadoProvision(this.selectcabe, '');
        this.opcionRecargar();
      } catch (e) {
        console.error(e)
      }
    } else {

    }
  }
  isEditIngre(valor) {
    console.log(valor);
    if (this.selectcabe.ENCLIQUBENESOCI_ESTADO == "CONFIRMADO") {
      return false;
    } else {
      return true;
    }
  }
  calcular(params) {
    console.log(params);
    this.liqservice.calculosParciales();
    this.llenarParte2();
    this.aggrid.refreshaggrid(this.liqservice.nomliqdet, this.defaultColDefDetliqdcab.objeto);
    this.aggrid.gridApi.setFocusedCell(this.selectednomdetliq.NUMCAB, 'DETIMPURENT_TOTAINGR');
  }
  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'novedad',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
  }

  cerrarReportes() {
    this.displayDialogReportes = false;
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos = false;
    }
  }

  funVerificaInformCabeceraNew(cab: nomliqucabecera): boolean {
    console.log(cab);
    let error = "";
    if (cab.MAERUBRO_CODRUBR == "") {
      this.mensajeAlerta('error', 'Error', 'Error, ingrese el rubro');
      return false;
    }
    if (cab.ENCLIQUBENESOCI_FECINIPROV == "") {
      this.mensajeAlerta('error', 'Error', 'Error, ingrese la fecha inicial de provisión del rubro en el maestro de rubros');
      return false;
    }
    if (cab.ENCLIQUBENESOCI_FECFINPROV == "") {
      this.mensajeAlerta('error', 'Error', 'Error, ingrese la fecha final de provisión del rubro en el maestro de rubros');
      return false;
    }
    if (cab.PERIODO_CODIGO == "") {
      this.mensajeAlerta('error', 'Error', 'Error, ingrese el periodo');
      return false;
    }
    if (cab.PERIODO_FECINI == "") {
      this.mensajeAlerta('error', 'Error', 'Error, ingrese la fecha inicial del periodo');
      return false;
    }
    if (cab.PERIODO_FECFIN == "") {
      this.mensajeAlerta('error', 'Error', 'Error, ingrese la fecha final del periodo');
      return false;
    }
    let f1: string[] = cab.PERIODO_FECINI.split('/');
    let f2: string[] = cab.ENCLIQUBENESOCI_FECFINPROV.split('/');
    let feini: Date = new Date(parseInt(f1[2]), parseInt(f1[1]) - 1, parseInt(f1[0]));
    let fefin: Date = new Date(parseInt(f2[2]), parseInt(f2[1]) - 1, parseInt(f2[0]));
    if (fefin >= feini) {
      this.mensajeAlerta('error', 'Error', 'Error, la fecha final de la provisión debe ser menor al período en proceso');
      return false;
    }
    return true;
  }
}
