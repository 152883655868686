import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {VenDetfacZ} from '../veninterfaces/vendetfacz';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class VendetfacService {
  get detfac(): VenDetfacZ[] {
    return this._detfac;
  }
  
  set detfac(value: VenDetfacZ[]) {
    this._detfac = value;
  }
  
  public way: string;
  private _detfac: VenDetfacZ[];
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }
  
  getDetfac(encfacnumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/10775aeebd4c', {
      elementos: {
        ENCFAC_NUMERO: encfacnumero
      }
    }, this.confIniciales.httpOptions());
  }

  getDetalleRef1(strEncfacNumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/20eeb5dddb9e1', {
      elementos: {
        ENCFAC_NUMERO: strEncfacNumero
      }
    }, this.confIniciales.httpOptions());
  }

  getDetalleRef2(strEncfacNumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/20eeb5dddb9e2', {
      elementos: {
        ENCFAC_NUMERO: strEncfacNumero
      }
    }, this.confIniciales.httpOptions());
  }

  getDetalleRef3(strEncfacNumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/20eeb5dddb9e3', {
      elementos: {
        ENCFAC_NUMERO: strEncfacNumero
      }
    }, this.confIniciales.httpOptions());
  }
  
  
}
