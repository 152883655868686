<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="venmaepry"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="VenMaePry" title="Confirmar Acción"
                 appendTo="body"></p-confirmDialog>

<div class="p-grid" style="width: 99%">
  <div class="p-col-12">
    <app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
                     [botonRegresar]="btnRegresar" [barraBotonesCRUD]="false"
                     [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true"
                     (signal)="manejarSenales($event)"></app-invmaebarra>
  </div>

</div>
<div style="overflow: hidden" class="p-col-12">
  <p-tabView>
    <p-tabPanel header="Proyectos">
      <app-aggridsaci [width]=""
                      [height]="largo"
                      [enableSorting]="true"
                      [enableFilter]="true"
                      [rowData]="pryService.venmaepry"
                      [frameworkComponents]="frameworkComponents"
                      [animateRows]="true"
                      [rowSelection]="rowSelection"
                      [columnDefs]="columnDefsPry"
                      [defaultColDef]="defaultColDefPry"
                      [rowStyle]="rowStyle"
                      [singleClickEdit]="false"
                      [botones]="false"
                      (selected)="selectedPry($event)"
                      (cambios)="cambio($event)"

      ></app-aggridsaci>
    </p-tabPanel>
  </p-tabView>
</div>
