<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="cobrar"></p-toast>
<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 280px)'}">
	<div style="margin-top: 50px">
		<div *ngIf="menupago" style="text-align: center">
			<div *ngIf="!imprimir" style="text-align: center">
				<button type="button" class="pagos" (click)="contado()"
						style="background-image: url(../../../assets/images/iconspdv/boton-en-blanco-.png);">Contado
				</button>
				<button type="button" class="pagos" (click)="credito()"
						style="background-image: url(../../../assets/images/iconspdv/boton-en-blanco-.png);">Crédito
				</button>
			</div>
			<div *ngIf="imprimir" style="text-align: center; margin-top: -50px">
				<embed [src]="blobpdf | safe" type="application/pdf"
					   width="95%" height="400px"/>
			</div>
		</div>
		<div *ngIf="!menupago">
			<div style="width: 100%">
				<table>
					<tr>
						<td style="width: 30%; justify-content: left">
							<span>Total A Pagar</span>
						</td>
						<td style="width: 10%">
						</td>
						<td style="width: 30%; justify-content: left">
							<span>{{moneyValidation(totalapagar.toFixed(2) + '')}}</span>
						</td>
						<td style="width: 30%"></td>
					</tr>
					<tr>
						<td style="justify-content: left">
							<span>Efectivo</span>
						</td>
						<td></td>
						<td style="justify-content: left">
							<input id="efectivocomma" [disabled]="existeasiento" style="text-align: left" size="12px"
								   pKeyFilter="num"
								   pInputText
								   type="text" [(ngModel)]="efectivocomma" (keydown.enter)="calculaCambio()"
								   (click)="enviarTeclado('numericoEfectivo')" appTwoDigitDecimaNumber>
						</td>
						<td></td>
					</tr>
					<tr>
						<td style="justify-content: left">
							<span>Cambio</span>
						</td>
						<td></td>
						<td style="justify-content: left">
							<span>{{moneyValidation(cambio.toFixed(2) + '')}}</span>
						</td>
						<td></td>
					</tr>
				</table>
			</div>
			<div style="padding-top: 20px">
				<p-accordion>
					<p-accordionTab>
						<p-header>
							<span>Formas de Pago</span>
						</p-header>
						<div class="scrollable">
							<table>
								<thead>
								<td style="font-weight: bold">
									<div style="text-align: center">Tipo</div>
								</td>
								<td style="font-weight: bold">Numero</td>
								<td style="font-weight: bold">Banco</td>
								<td style="font-weight: bold">Nombre</td>
								<td style="font-weight: bold">Nro.Cta</td>
								<td style="font-weight: bold">Importe</td>
								<td style="font-weight: bold">Cod.Sri</td>
								<td style="font-weight: bold">
									<div style="text-align: center; width: 20px">Disp</div>
								</td>
								<td>
									<!--<button style="width: 50%; background-color: #98FB98" (click)="addlineafp()">☼</button>-->
									<button type="button" class="buttongene"
											style="background-image: url(../../../assets/images/iconos/064-create.png);"
											(click)="addlineafp()"></button>
								</td>
								</thead>
								<tr *ngFor="let i of tiposdepago; index as indi">
									<td>
										<!--<button type="button" class="btn dropdown-toggle" pagos-toggle="dropdown"
												aria-haspopup="true" aria-expanded="false">{{i.TIPO}}</button>
										<div class="dropdown-menu">
											<label class="dropdown-item"
												   (click)="opcion('Cheque',indi)">Cheque</label>
											<label class="dropdown-item"
												   (click)="opcion('Depósito',indi)">Depósito</label>
											<label class="dropdown-item"
												   (click)="opcion('Tarjeta',indi)">Tarjeta</label>
										</div>-->
										<p-dropdown [options]="fpago[indi]" [style]="{'width':'100px'}"
													optionLabel="name"
													(onClick)="llenarcombotpagos(indi)"
													(onChange)="opcion($event, indi)"></p-dropdown>
									</td>
									<td><input size="5px" pInputText type="text" [(ngModel)]="i.NUMERO"
											   (click)="enviarTecladoFormaPago('numericoNum', indi)"></td>
									<td><input size="5px" pInputText type="text" [(ngModel)]="i.BANCO"
											   (click)="showDialogBancos(indi)"></td>
									<td><input size="5px" pInputText type="text" [(ngModel)]="i.NOMBREBANCO"></td>
									<td><input size="5px" pInputText type="text" [(ngModel)]="i.NROCUENTA"
											   (click)="enviarTecladoFormaPago('numericoCta', indi)"></td>
									<td><input size="5px" pInputText type="text" pKeyFilter="money"
											   [(ngModel)]="i.IMPORTECOMMA"
											   (keydown.enter)="calculaCambioFormaPago()"
											   appTwoDigitDecimaNumber
											   (click)="enviarTecladoFormaPago('numericoImporte', indi)"></td>
									<td><input size="5px" pInputText type="text" [(ngModel)]="i.CODSRI"
											   (click)="showDialogCodsri(indi)"></td>
									<td>
										<!--<button *ngIf="i.TIPO==='Tarjeta'" [disabled]="i.TIPO!=='Tarjeta'" type="button"
												class="btn dropdown-toggle"
												pagos-toggle="dropdown" aria-haspopup="true"
												aria-expanded="false">{{i.DISPOSITIVO}}</button>
										<div class="dropdown-menu">
											<label class="dropdown-item" (click)="opciondis('DF',indi)">DF</label>
											<label class="dropdown-item" (click)="opciondis('MD',indi)">MD</label>
										</div>-->
										<p-dropdown *ngIf="i.TIPO==='Tarjeta'" [disabled]="i.TIPO!=='Tarjeta'"  [options]="fdisp[indi]"
													optionLabel="name"
													(onClick)="llenarcombotdisp(indi)"
													(onChange)="opciondis($event, indi)"></p-dropdown>
									</td>
									<td>
										<!--<button style="width: 50%; background-color: #DC143C" (click)="dellineafp(indi)">..</button>-->
										<button type="button" class="buttongene"
												style="background-image: url(../../../assets/images/iconos/006-eraser.png);"
												(click)="dellineafp(indi)"></button>
									</td>
								</tr>
							</table>

						</div>
					</p-accordionTab>
					<p-accordionTab>
						<p-header>
							<span>Retenciones</span>
						</p-header>
						<div class="scrollable">
							<table>
								<thead>
								<td style="font-weight: bold">
									<div style="text-align: center">Codigo</div>
								</td>
								<td style="font-weight: bold">Descripción</td>
								<td style="font-weight: bold">Número</td>
								<td style="font-weight: bold">Base</td>
								<td style="font-weight: bold">%</td>
								<td style="font-weight: bold">T.Retenido</td>
								<td>
									<!--<button style="width: 50%; background-color: #98FB98" (click)="addlinearet()">☼</button>-->
									<button type="button" class="buttongene"
											style="background-image: url(../../../assets/images/iconos/064-create.png);"
											(click)="addlinearet()"></button>
								</td>
								</thead>
								<tr *ngFor="let i of tiposderetencion; index as indi">
									<td><input size="5px" pInputText type="text" [(ngModel)]="i.CODIGO"
											   (click)="showDialogRetenciones(indi)"></td>
									<td><input size="5px" pInputText type="text" [(ngModel)]="i.DESCRIPCION"></td>
									<td><input size="5px" pInputText type="text" [(ngModel)]="i.NUMERO"
											   (click)="enviarTecladoRetencion('numericoNumRet', indi)"></td>
									<td><input size="5px" pInputText type="text" [(ngModel)]="i.BASECOMMA"
											   (keydown.enter)="calculaCambioRetencion()"
											   (click)="enviarTecladoRetencion('numericoBase', indi)"></td>
									<td><input size="5px" pInputText type="text" [disabled]="true"
											   [(ngModel)]="i.PORCENTAJE"></td>
									<td><input size="5px" pInputText type="text" [disabled]="true"
											   [(ngModel)]="i.TOTALRETENIDOCOMMA"
											   (keydown.enter)="addlinearet()"
											   (keydown.backspace)="dellinearet(indi)"></td>
									<td>
										<!--<button (click)="dellinearet(indi)"></button>-->
										<button type="button" class="buttongene"
												style="background-image: url(../../../assets/images/iconos/006-eraser.png);"
												(click)="dellinearet(indi)"></button>
									</td>
								</tr>
							</table>
						</div>
					</p-accordionTab>
				</p-accordion>
			</div>
		</div>
	</div>
</p-scrollPanel>
<div>
	<table style="width: 100%">
		<tr>
			<td>
				<button *ngIf="!existeasiento" [disabled]="existeasiento" type="button" class="buttoncobrar" pButton
						(click)="cobrarFactura()"
						title="Cobrar"
						style="background-image: url(../../../assets/images/iconspdv/cobrar-simbolo.png);">
				</button>
				<button *ngIf="existeasiento" [disabled]="existeasiento" type="button" class="buttoncobrar" pButton
						(click)="cobrarFactura()"
						title="Cobrar"
						style="background-image: url(../../../assets/images/iconspdv/cobrar-simbolo-gris.png);">
				</button>
			</td>
			<td>
				<button type="button" class="buttoncobrar" pButton title="Imprimir" (click)="imprimirFactura()"
						style="background-image: url(../../../assets/images/iconspdv/imprimir-simbolo.png);">
				</button>
			</td>
			<td>
				<button type="button" class="buttoncobrar" pButton (click)="salir()" title="Salir"
						style="background-image: url(../../../assets/images/iconspdv/salir-simbolo.png);">
				</button>
			</td>
			<td>
				<button type="button" class="buttoncobrar" pButton (click)="reversarformapago()"
						title="Reversar forma de pago"
						style="background-image: url(../../../assets/images/iconspdv/eliminar-producto.png);">
				</button>
			</td>
		</tr>
	</table>
</div>


<p-dialog header="Bancos" [(visible)]="displayDialogBancos" [responsive]="true"
		  [modal]="false"
		  [style]="{width: '700px'}">
	<p-scrollPanel [style]="{'width':'100%', 'height':'300px'}">
		<div class="p-grid">
			<div *ngIf="tipo == 'DP'" class="p-col-12">
				<p-table selectionMode="single" editMode="row" [selection]="bancoSelec" [value]="bancos">
					<ng-template pTemplate="header">
						<tr class="ui-table-scrollable-wrapper">
							<th [ngStyle]="{'width':'30%'}">Codigo</th>
							<th [ngStyle]="{'width':'20%'}">Banco</th>
							<th [ngStyle]="{'width':'20%'}">Cuenta Nro.</th>
							<th [ngStyle]="{'width':'30%'}">Sucursal</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-banco let-rowIndex="rowIndex"
								 let-rowData>
						<tr [pSelectableRow]="rowData" (click)="selectedBan(rowData, rowIndex)"
							(dblclick)="seleccionarBanco()">
							<td style="height: 20px">
								{{banco.BAN_CODIGO}}
							</td>
							<td style="height: 20px">
								{{banco.BAN_BANCO}}
							</td>
							<td style="height: 20px">
								{{banco.BAN_CTANRO}}
							</td>
							<td style="height: 20px">
								{{banco.BAN_SUCURSAL}}
							</td>
						</tr>
					</ng-template>
				</p-table>
			</div>

			<div *ngIf="tipo == 'CH'" class="p-col-12">
				<p-table selectionMode="single" editMode="row" [selection]="bancoSelecCH" [value]="bancosCH">
					<ng-template pTemplate="header">
						<tr class="ui-table-scrollable-wrapper">
							<th [ngStyle]="{'width':'30%'}">Codigo</th>
							<th [ngStyle]="{'width':'20%'}">Banco</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-banco let-rowIndex="rowIndex"
								 let-rowData>
						<tr [pSelectableRow]="rowData" (click)="selectedBanCH(rowData, rowIndex)"
							(dblclick)="seleccionarBancoCH()">
							<td style="height: 20px">
								{{banco.BANCLI_CODIGO}}
							</td>
							<td style="height: 20px">
								{{banco.BANCLI_NOMBRE}}
							</td>
						</tr>
					</ng-template>
				</p-table>
			</div>


			<div *ngIf="tipo == 'TA'" class="p-col-12">
				<p-table selectionMode="single" editMode="row" [selection]="bancoSelecTA" [value]="bancosTA">
					<ng-template pTemplate="header">
						<tr class="ui-table-scrollable-wrapper">
							<th [ngStyle]="{'width':'30%'}">Codigo</th>
							<th [ngStyle]="{'width':'20%'}">Tarjeta</th>
							<th [ngStyle]="{'width':'20%'}">Porcentaje</th>
							<th [ngStyle]="{'width':'30%'}">Cuenta</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-banco let-rowIndex="rowIndex"
								 let-rowData>
						<tr [pSelectableRow]="rowData" (click)="selectedBanTA(rowData, rowIndex)"
							(dblclick)="seleccionarBancoTA()">
							<td style="height: 20px">
								{{banco.TAR_CODIGO}}
							</td>
							<td style="height: 20px">
								{{banco.TAR_DESCRIPCION}}
							</td>
							<td style="height: 20px">
								{{banco.TAR_PORCENTAJE}}
							</td>
							<td style="height: 20px">
								{{banco.CON_CODIGO}}
							</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</div>
	</p-scrollPanel>
	<p-footer>
		<div *ngIf="tipo == 'DP'" class="ui-dialog-buttonpane ui-helper-clearfix">
			<button type="button" pButton icon="fa fa-check" (click)="seleccionarBanco()" label="Seleccionar"></button>
		</div>
		<div *ngIf="tipo == 'CH'" class="ui-dialog-buttonpane ui-helper-clearfix">
			<button type="button" pButton icon="fa fa-check" (click)="seleccionarBancoCH()"
					label="Seleccionar"></button>
		</div>
		<div *ngIf="tipo == 'TA'" class="ui-dialog-buttonpane ui-helper-clearfix">
			<button type="button" pButton icon="fa fa-check" (click)="seleccionarBancoTA()"
					label="Seleccionar"></button>
		</div>
	</p-footer>
</p-dialog>


<p-dialog header="Codigos SRI" [(visible)]="displayDialogCodsri" [responsive]="true"
		  [modal]="false"
		  [style]="{width: '700px'}" [transitionOptions]="'0ms'">
	<p-scrollPanel [style]="{'width':'100%', 'height':'300px'}">
		<div class="p-grid">
			<div class="p-col-12">
				<p-table selectionMode="single" editMode="row" [selection]="codsriSelec" [value]="codssri">
					<ng-template pTemplate="header">
						<tr class="ui-table-scrollable-wrapper">
							<th [ngStyle]="{'width':'30%'}">Codigo</th>
							<th [ngStyle]="{'width':'50%'}">Descripcion</th>
							<th [ngStyle]="{'width':'20%'}">Año</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-codsri let-rowIndex="rowIndex"
								 let-rowData>
						<tr [pSelectableRow]="rowData" (click)="selectedCodsri(rowData, rowIndex)"
							(dblclick)="seleccionarCodsri()">
							<td style="height: 20px">
								{{codsri.CODSRI_CODIGO}}
							</td>
							<td style="height: 20px">
								{{codsri.CODSRI_DESCRIPCION}}
							</td>
							<td style="height: 20px">
								{{codsri.CODSRI_ANIO}}
							</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</div>
	</p-scrollPanel>
	<p-footer>
		<div class="ui-dialog-buttonpane ui-helper-clearfix">
			<button type="button" pButton icon="fa fa-check" (click)="seleccionarCodsri()" label="Seleccionar"></button>
		</div>
	</p-footer>
</p-dialog>


<p-dialog header="Retenciones" [(visible)]="displayDialogRetencion" [responsive]="true"
		  [modal]="false"
		  [style]="{width: '700px'}" [transitionOptions]="'0ms'">
	<p-scrollPanel [style]="{'width':'100%', 'height':'300px'}">
		<div class="p-grid">
			<div class="p-col-12">
				<p-table selectionMode="single" editMode="row" [selection]="retencionSelec" [value]="retenciones">
					<ng-template pTemplate="header">
						<tr class="ui-table-scrollable-wrapper">
							<th [ngStyle]="{'width':'10%'}">Codigo</th>
							<th [ngStyle]="{'width':'70%'}">Retencion</th>
							<th [ngStyle]="{'width':'10%'}">Porcentaje</th>
							<th [ngStyle]="{'width':'10%'}">Tipo</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-retencion let-rowIndex="rowIndex"
								 let-rowData>
						<tr [pSelectableRow]="rowData" (click)="selectedRet(rowData, rowIndex)"
							(dblclick)="seleccionarRetencion()">
							<td style="height: 20px">
								{{retencion.RETENCION_CODIGO}}
							</td>
							<td style="height: 20px">
								{{retencion.RETENCION_NOMBRE}}
							</td>
							<td style="height: 20px">
								{{retencion.RETENCION_PORCENTAJE}}
							</td>
							<td style="height: 20px">
								{{retencion.RETENCION_TIPRET}}
							</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</div>
	</p-scrollPanel>
	<p-footer>
		<div class="ui-dialog-buttonpane ui-helper-clearfix">
			<button type="button" pButton icon="fa fa-check" (click)="seleccionarRetencion()"
					label="Seleccionar"></button>
		</div>
	</p-footer>
</p-dialog>
<input id="focus" style="position: absolute; left: -1000px">
