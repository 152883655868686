import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {Observable} from 'rxjs';
import {Nommaeperiodo} from '../nominterfaces/nommaeperiodo';
import { MessageService } from 'primeng';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class NommaeperiodoService {
  public way: string;
  private url: string;
  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    private confIniciales: ConfInicialesService,
    public usuario: Usuario ) {
      this.url = sessionStorage.getItem('url');
      this.way = sessionStorage.getItem('way');
      this.usuario.identificacion;
}
async cargaDatosAnio(stranio){
  return  await this.http.post(this.way + '/nom/nomperiodo/o846ukgla5k18bh', {
    elementos: {
      anio:stranio
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
async getAnioAuto(stranio){
  return  await this.http.post(this.way + '/nom/nomperiodo/z7twbwp84v39mqm', {
    elementos: {
      p_periodo_anio:stranio 
    },
  }, this.confIniciales.httpOptions()).toPromise();
}

async GeneRolMens30Dias(anios){
  return  await this.http.post(this.way + '/nom/nomperiodo/eobp7k0yulpejc4', {
    elementos: {
      p_anio:anios,
      p_com_codigo:'01' 
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
async GeneRolQuinDias(anios){
  return  await this.http.post(this.way + '/nom/nomperiodo/ulc12dp8woeih6c', {
    elementos: {
      p_anio:anios,
      p_com_codigo:'01' 
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
//
async GeneRolMesExac(anios){
  return  await this.http.post(this.way + '/nom/nomperiodo/iet20bartwsm24f', {
    elementos: {
      p_anio:anios,
      p_com_codigo:'01' 
    },
  }, this.confIniciales.httpOptions()).toPromise();
}

async ActivaPeridioEnProceso(){
  return  await this.http.post(this.way + '/nom/nomperiodo/br39xj8veyur9ic', {
    elementos: {
      p_com_codigo:'01' 
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
async getCodigoActual(){
  return  await this.http.post(this.way + '/nom/nomperiodo/ah3x4ltynvc9rtu', {
    elementos: {},
  }, this.confIniciales.httpOptions()).toPromise();
}
async NomGlbObtienePeriodo(){
  return  await this.http.post(this.way + '/nom/nomperiodo/yudrre2sl6bgk9q', {
    elementos: {
      p_com_codigo:'01'
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
async actualizaPeriodo(nommaeperiodo: Nommaeperiodo){
  if(nommaeperiodo.PERIODO_CODIGO==""|| isNullOrUndefined(nommaeperiodo.PERIODO_CODIGO)){
    nommaeperiodo.PERIODO_CODIGO=0;
  }
  return  await this.http.post(this.way + '/nom/nomperiodo/24ji8w2o5ui4k8l', {
    elementos: {
      p_periodo_codigo       :parseInt(nommaeperiodo.PERIODO_CODIGO),    
      p_periodo_anio         :nommaeperiodo.PERIODO_ANIO,  
      p_periodo_numero       :nommaeperiodo.PERIODO_NUMERO,
      p_periodo_despricion   :nommaeperiodo.PERIODO_DESPRICION,
      p_periodo_fecini      :nommaeperiodo.PERIODO_FECINI,
      p_periodo_fecfin      :nommaeperiodo.PERIODO_FECFIN,
      p_periodo_diacalc     :nommaeperiodo.PERIODO_DIACALC,
      p_periodo_diaobr      :nommaeperiodo.PERIODO_DIAOBR,
      p_periodo_diaemp      :nommaeperiodo.PERIODO_DIAEMP,
      p_periodo_diasanio    :nommaeperiodo.PERIODO_DIASANIO,
      p_periodo_estado      :nommaeperiodo.PERIODO_ESTADO,
      p_com_codigo          :'01',
    },
  }, this.confIniciales.httpOptions()).toPromise();
}

async borrar(nommaeperiodo: Nommaeperiodo){
  return  await this.http.post(this.way + '/nom/nomperiodo/308nu8xe80ttxrk', {
    elementos: {
      p_periodo_codigo:parseInt(nommaeperiodo.PERIODO_CODIGO)
    },
  }, this.confIniciales.httpOptions()).toPromise();
}

//






}
