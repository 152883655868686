import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NomencrolcerradosService } from '../nomservicios/nomencrolcerrados.service';
import { Usuario } from 'src/app/usuario';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { SelectItem } from 'primeng/api/selectitem';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { MessageService } from 'primeng';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Component({
  selector: 'app-nomencrolcerrados',
  templateUrl: './nomencrolcerrados.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NomencrolcerradosComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;


  botonPrimero = false;
  botonAnterior = false;
  botonSiguiente = false;
  botonUltimo = false;
  btnBuscar = false;

  spin = false;//GSRF

  rol = '';
  estado = '';
  txtPERIODO_FECINI = '';
  txtPERIODO_FECFIN = '';
  txtENCROL_ANIO = '';
  lblEMP_CODIGO = '';
  txtEMP_SUELDO = '';
  txtEMP_NOMBRE = '';
  lblPERIODO_CODIGO = '';
  txtENCROLINDI_ESTADO = '';
  txtTIPOROL_CODIGO = '';
  txtEMP_FECINGRESO = '';
  txtEMP_FECSALIDA = '';
  fechaSalida = '';
  lblENCROLINDI_CODIGO = '';
  txtEMP_RUCIDE = '';
  cmbTIPROL = [];
  cmbTIPROL_CODIGO = '';
  cmbASI_NRO = [];
  selectedRol;
  selectedAsiento;

  total = {
    totalUno: '0',
    totalDos: '0',
    netoPagar: '0'
  }

  fecha = new Date();
  identiUsuario = '';
  tabIndex = 0;

  largo = '258';
  rowStyle;
  defaultColDefDetRol;
  rowSelection = 'multiple';
  detalleRoles = [];
  public frameworkComponents;
  private clave = '@#administrador#@+ADMIN';
  detalleProv = [];
  defaultColDefDetProv;

  types: SelectItem[];
  tabla: string;
  consulta: string;
  busquedacampos: any[];
  where: string;
  arregloCons: any[];
  arregloBus: any[];
  displayDialogBusquedaFast = false;
  displayDialogReportes: boolean;

  // reporte
  auxreporte: number = 0;
  strNombreForma: string;

  displayAsiContable = false;
  asiento: any;

  displayDialogDocumentos = false;

  columnDefDetRol = [
    {
      headerName: 'Tipo', field: 'DETROLINDI_TIPORUBR', editable: false, cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 400,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Código', field: 'DETROLINDI_CODIGO', editable: false, cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Descripción', field: 'DETROLINDI_DESCRIPCION', editable: false, cellEditor: 'cellPrueba', width: 300,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'T', field: 'DETROLINDI_TIPODET', editable: false, cellEditor: 'cellPrueba', width: 50,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Horas', field: 'DETROLINDI_HORAS', editable: false, cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Días', field: 'DETROLINDI_DIAS', editable: false, cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Ingresos', field: 'DETROLINDI_INGRESOS', editable: false, cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,



        }
      }
    },
    {
      headerName: 'Egresos', field: 'DETROLINDI_EGRESOS', editable: false, cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'FechFin', field: 'DETROLINDI_FECHFINRUBR', editable: false, cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'FechIni', field: 'DETROLINDI_FECHINIRUBR', editable: false, cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Observación', field: 'TRANVACAEMPL_OBSERVACIONES', editable: false, cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }
    },

  ];

  columnDefDetProv = [
    {
      headerName: 'Código', field: 'DETROLPROV_CODIGO', editable: false, cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Descripción', field: 'DETROLPROV_DESCRIPCION', editable: false, cellEditor: 'cellPrueba', width: 300,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'T', field: 'DETROLPROV_TIPODET', editable: false, cellEditor: 'cellPrueba', width: 50,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Días', field: 'DETROLPROV_DIAS', editable: false, cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Base', field: 'DETROLPROV_BASE', editable: false, cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Valor', field: 'DETROLPROV_VALOR', editable: false, cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'FechFin', field: 'DETROLPROV_FECHFINRUBR', editable: false, cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }
    },
  ]
  //CDPJ
  displayAcciones: boolean;
  claveDialog: boolean;
  claveAuth: string;
  message: any;
  btonEliminar: boolean;
//CDPJ
  constructor(
    private datePipe: DatePipe,
    private rolCerradoSrv: NomencrolcerradosService,
    public usuario: Usuario,
    private init: NuevoComponentService,
    private busquedaSer: InvbusquedaService,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private confIniciales: ConfInicialesService
  ) {
    this.identiUsuario = this.usuario.identificacion;
    this.datePipe.transform(this.fecha, 'dd/MM/yy');

    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.defaultColDefDetRol = {
      editable: true,
      width: 130,
      objeto: 'detalleRol'
    };
    this.defaultColDefDetProv = {
      editable: true,
      width: 130,
      objeto: 'detalleProvisiones'
    };
  }

  ngOnInit(): void {
    this.txtENCROL_ANIO = new Date().getFullYear().toString();
    this.getListaTipRol();
  }

  //CDPJ

  acciones(valor) {

    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 0; i < elemento.length; i++) {
        elemento[i].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 0; i < elemento.length; i++) {
        elemento[i].className = 'layout-config';
      }
    }

  }
  closeSidebar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }

  cambiarEstado() {
    this.closeSidebar();
    this.claveDialog = true;

  }
  comprobarClave() {
    let claveReal;
    claveReal = this.claveAuth + '+' + this.usuario.codperfil;
    if (claveReal === this.clave) {
      this.actualizarestado();
    } else if (this.clave !== this.claveAuth) {

      this.claveDialog = false;
      this.claveAuth = '';
      this.messageService.add({
        key: 'rolCerado',
        severity: 'error',
        summary: 'Clave Autorización',
        detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
      });
      return;
    }
  }

  actualizarestado() {
    this.claveDialog = false;
    this.claveAuth = '';

    this.rolCerradoSrv.actualizarEstado(this.lblENCROLINDI_CODIGO);

    this.llenarCampos(this.lblENCROLINDI_CODIGO);
    this.txtENCROLINDI_ESTADO = '';
    this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
    //this.funGeneraAuditoriaRolPagos();
    this.btonEliminar = false;
  }


  async funGeneraAuditoriaRolPagos() {
    await this.rolCerradoSrv.funGeneraAuditoriaRolPagosCerrados(this.lblENCROLINDI_CODIGO).then(() => {

      this.mensajeAlerta('success', 'Información', 'Datos guardados');

    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);

    });
  }

  //CDPJ








  async getListaTipRol() {
    let tipRol = {
      TIPROL_CODIGO: "",
      TIPROL_NOMBRE: ""
    }
    await this.rolCerradoSrv.FunListaTipRol().then(lista => {
      this.cmbTIPROL = [tipRol, ...lista];
    }).catch(e => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);

    });
  }

  convertDate(date: string) {
    if (date !== '' && date !== undefined && date !== null) {

      const fecha = new Date(date);

      let month = '' + (fecha.getMonth() + 1);
      let day = '' + fecha.getDate();
      let year = fecha.getFullYear();

      if (month.length < 2) { month = '0' + month }
      if (day.length < 2) { day = '0' + day }

      return `${day}/${month}/${year}`;

    }

    return '';


  }
  fechaYmD(fecha: string) {
    if (fecha === '') {
      return '';
    }
    let fechaArray = fecha.split('/');

    return `${fechaArray[2]}/${fechaArray[1]}/${fechaArray[0]}`;


  }

  async manejarSenales(valor) {
    switch (valor) {
      case 'Salir':
        //CDPJ
        this.btonEliminar = true;
        this.init.tabs.splice(this.init.tabindex, 1);
        break;
      case 'Siguiente':
        //CDPJ
        this.btonEliminar = true;
        this.getSiguiente();
        break;

      case 'Ultimo':
        //CDPJ
        this.btonEliminar = true;
        this.getUltimo();
        break;
      case 'Anterior':
        //CDPJ
        this.btonEliminar = true;
        this.getAnterior();
        break;
      case 'Primero':
        //CDPJ
        this.btonEliminar = true;
        this.getPrimero();
        break;

      case 'Buscar':
        //CDPJ
        this.btonEliminar = true;
        this.busqueda();
        break;

      case 'Asiento':
        //CDPJ
        this.btonEliminar = true;
        this.cmdasilote();
        break;
      case 'Cancelar':
        //CDPJ
        this.btonEliminar = true;
        this.reset();
        break;
      case 'Imprimir':
        //CDPJ
        this.btonEliminar = true;
        this.strNombreForma = this.init.tabs[this.init.tabindex].component;
        this.auxreporte++;
        this.displayDialogReportes = true;
        break;
      case 'Almacenardoc':
        //CDPJ
        this.btonEliminar = true;
          if (this.lblENCROLINDI_CODIGO !== '') {
            const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
            if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
              this.displayDialogDocumentos = true;
            } else {
              this.messageService.add({
                key: 'rolCerado',
                severity: 'info',
                summary: 'Información',
                detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
              });
            }
            
          }
        break;
    }
  }

  async getUltimo() {
    //GSRF
    let condicion = '';
    console.log('*****',this.cmbTIPROL_CODIGO)
    if (this.cmbTIPROL_CODIGO !== '') {
      condicion = `${condicion} AND TIPROL_CODIGO ='${this.cmbTIPROL_CODIGO}'`;
    }
    //GSRF
    await this.rolCerradoSrv.rolNavegarCerr('U', 'CERRADO', condicion).then(res => {

      if (res !== null) {
        this.llenarCampos(res[0].ENCROLINDI_CODIGO);
      }
    }).catch(e => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);

    });
  }

  async getPrimero() {
    
    //GSRF
    console.log('*****',this.cmbTIPROL_CODIGO)
    let condicion = '';
    if (this.cmbTIPROL_CODIGO !== '') {
      condicion = `${condicion} AND TIPROL_CODIGO ='${this.cmbTIPROL_CODIGO}'`;
    }
    //GSRF
    await this.rolCerradoSrv.rolNavegarCerr('P', 'CERRADO', condicion).then(res => {


      if (res !== null) {
        this.llenarCampos(res[0].ENCROLINDI_CODIGO);
      }
    }).catch(e => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);

    });
  }

  async getAnterior() {
    let condicion = `${this.fechaYmD(this.txtPERIODO_FECINI)} ${this.txtEMP_NOMBRE}`;
    if (this.cmbTIPROL_CODIGO !== '') {
      condicion = `${condicion}' AND TIPROL_CODIGO ='${this.cmbTIPROL_CODIGO}`;
    }

    await this.rolCerradoSrv.rolNavegarCerr('A', 'CERRADO', condicion).then(res => {


      if (res !== null) {
        this.llenarCampos(res[0].ENCROLINDI_CODIGO);
      }
    }).catch(e => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);

    });
  }


  async getSiguiente() {
    let condicion = `${this.fechaYmD(this.txtPERIODO_FECINI)} ${this.txtEMP_NOMBRE}`;
    if (this.cmbTIPROL_CODIGO !== '') {
      condicion = `${condicion}' AND TIPROL_CODIGO ='${this.cmbTIPROL_CODIGO}`;
    }

    await this.rolCerradoSrv.rolNavegarCerr('S', 'CERRADO', condicion).then(res => {


      if (res !== null) {
        this.llenarCampos(res[0].ENCROLINDI_CODIGO);
      }
    }).catch(e => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);

    });
  }


  async llenarCampos(ENCROLINDI_CODIGO) {
    this.spin=true;//GSRF
    await this.rolCerradoSrv.llenaCampos(ENCROLINDI_CODIGO).then(async cab => {


      this.lblENCROLINDI_CODIGO = cab[0].ENCROLINDI_CODIGO;
      this.txtENCROL_ANIO = cab[0].ENCROLINDI_ANIO;
      this.lblPERIODO_CODIGO = cab[0].PERIODO_CODIGO;
      this.lblEMP_CODIGO = cab[0].EMP_CODIGO;
      this.txtEMP_SUELDO = cab[0].EMP_SUELDO.toFixed(2);;
      this.txtENCROLINDI_ESTADO = cab[0].ENCROLINDI_ESTADO;
      this.txtEMP_NOMBRE = cab[0].EMP_APELLIDO + ' ' + cab[0].EMP_NOMBRE;
      if (cab[0].ASI_NRO !== null) {
        let ASI_NRO = cab[0].ASI_NRO;
        this.cmbASI_NRO.push(ASI_NRO);
      } else {
        let ASI_NRO = cab[0].ASI_NRO2;
        this.cmbASI_NRO.push(ASI_NRO);
      }

      await this.getDatosAdicionales();

    }).catch(e => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);

    });
    this.spin=false;//GSRF
  }

  async getDatosAdicionales() {

    let fechaIni = await this.rolCerradoSrv.encRegistroPeriodo('periodo_fecini', `periodo_codigo=${this.lblPERIODO_CODIGO}`);

    let fechaFin = await this.rolCerradoSrv.encRegistroPeriodo('periodo_fecfin', `periodo_codigo=${this.lblPERIODO_CODIGO}`);

    let empRucide = await this.rolCerradoSrv.encRegistroEmpleado('emp_rucide', `emp_codigo=${this.lblEMP_CODIGO}`);

    let FecIngreso = await this.rolCerradoSrv.encRegistroEmpleado('EMP_FECINGRESO', `emp_codigo=${this.lblEMP_CODIGO}`);

    let FecSalida = await this.rolCerradoSrv.encRegistroEmpleado('EMP_FECSALIDA', `emp_codigo=${this.lblEMP_CODIGO}`);

    let tiprolCodigo = await this.rolCerradoSrv.encRegistroEmpleado('tiprol_codigo', `emp_codigo=${this.lblEMP_CODIGO}`);




    Promise.all([
      fechaIni,
      fechaFin,
      empRucide,
      FecIngreso,
      FecSalida,
      tiprolCodigo
    ]).then(async valores => {
      this.txtPERIODO_FECINI = this.convertDate(valores[0][0].PERIODO_FECINI);
      this.txtPERIODO_FECFIN = this.convertDate(valores[1][0].PERIODO_FECFIN);
      this.txtEMP_RUCIDE = valores[2][0].EMP_RUCIDE;
      this.txtEMP_FECINGRESO = (valores[3][0].EMP_FECINGRESO === null) ? '' : this.convertDate(valores[3][0].EMP_FECINGRESO);
      this.txtEMP_FECSALIDA = (valores[4][0].EMP_FECSALIDA === null) ? '' : this.convertDate(valores[4][0].EMP_FECSALIDA);
      this.txtTIPOROL_CODIGO = valores[5][0].TIPROL_CODIGO;

      await this.rolCerradoSrv.encRegistroTipoRol('tiprol_nombre', `tiprol_codigo =${this.txtTIPOROL_CODIGO}`).then(nomRol => {

        this.txtTIPOROL_CODIGO = this.txtTIPOROL_CODIGO + '-' + nomRol[0].TIPROL_NOMBRE;
        this.getDetalleRol();
      })


    })
      .catch(e => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);

      });



  }

  async getDetalleRol() {


    await this.rolCerradoSrv.getDetalleRol(this.lblENCROLINDI_CODIGO).then(async detRol => {


      this.detalleRoles = [...detRol];

      this.detalleRoles.map(det => {

        det.DETROLINDI_INGRESOS = (det.DETROLINDI_INGRESOS !== null) ? (det.DETROLINDI_INGRESOS).toFixed(2) : '0.00';
        det.DETROLINDI_EGRESOS = (det.DETROLINDI_EGRESOS !== null) ? (det.DETROLINDI_EGRESOS).toFixed(2) : '0.00';
        det.DETROLINDI_HORAS = (det.DETROLINDI_HORAS !== null) ? (det.DETROLINDI_HORAS).toFixed(2) : '0.00';
        det.DETROLINDI_DIAS = (det.DETROLINDI_DIAS !== null) ? (det.DETROLINDI_DIAS).toFixed(2) : '0.00';
        det.DETROLINDI_FECHINIRUBR = this.convertDate(det.DETROLINDI_FECHINIRUBR);
        det.DETROLINDI_FECHFINRUBR = this.convertDate(det.DETROLINDI_FECHFINRUBR);

      })
      this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);

      return await this.rolCerradoSrv.getDetalleProvision(this.lblENCROLINDI_CODIGO).then(detProv => {

        this.detalleProv = [...detProv];

        this.detalleProv.map(detp => {
          detp.DETROLPROV_BASE = (detp.DETROLPROV_BASE !== null) ? (detp.DETROLPROV_BASE).toFixed(2) : '0.00';
          detp.DETROLPROV_VALOR = (detp.DETROLPROV_VALOR !== null) ? (detp.DETROLPROV_VALOR).toFixed(2) : '0.00';
          detp.DETROLPROV_DIAS = (detp.DETROLPROV_DIAS !== null) ? (detp.DETROLPROV_DIAS).toFixed(2) : '0.00';


        })

        this.sumTotales();
        this.aggrid.refreshaggrid(this.detalleProv, this.defaultColDefDetProv.objeto);

      })
    }).catch(e => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);

    });

  }

  onTabChange({ index }) {
    this.tabIndex = index;
    this.sumTotales();
  }

  sumTotales() {
    this.total = {
      totalUno: '0',
      totalDos: '0',
      netoPagar: '0'
    }
    let totalUno = 0;
    let totalDos = 0;
    let totalNeto = 0;
    switch (this.tabIndex) {
      case 0:

        this.detalleRoles.map(det => {

          totalUno = totalUno + Number(det.DETROLINDI_INGRESOS);
          totalDos = totalDos + Number(det.DETROLINDI_EGRESOS);


        });
        totalNeto = totalUno - totalDos;
        this.total.totalUno = totalUno.toFixed(2);
        this.total.totalDos = totalDos.toFixed(2);
        this.total.netoPagar = totalNeto.toFixed(2);
        break;

      case 1:

        this.detalleProv.map(det => {

          totalUno = totalUno + Number(det.DETROLPROV_BASE)
          totalDos = totalDos + Number(det.DETROLPROV_VALOR)

        });

        this.total.totalUno = totalUno.toFixed(2);
        this.total.totalDos = totalDos.toFixed(2);
        this.total.netoPagar = this.total.totalDos;
        break;

    }
  }


  selectDetRol(rol) {

  }
  selectDetProv(prov) {

  }

  selecTipoRol(dato) {
    this.cmbTIPROL_CODIGO = dato.value.TIPROL_CODIGO;
  }
  selecAsiento(event) {
  }

  busqueda() {
    this.types = [
      { label: 'Cod', value: 'ENCROLINDI_CODIGO' },
      { label: 'Código', value: 'NOM_FNC_OBTIENCOD2EMPL(EMP_CODIGO)' },
      { label: 'Empleado', value: 'NOM_FNC_OBTIENENMBREMPL(EMP_CODIGO)' },
      { label: 'Fec.Ing.', value: 'EMP_FECINGRESO' },
      { label: 'Est.Cli.', value: 'EMP_ACTIVO' },
      { label: 'Período', value: 'PERIODO_DESPRICION' },
      { label: 'FecIni', value: 'PERIODO_FECINI' },
      { label: 'FecFin', value: 'PERIODO_FECFIN' }
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', ''];
    this.consulta = `to_char(encrolindi_codigo)  AS ENCROLINDI_CODIGO, NOM_FNC_OBTIENCOD2EMPL(EMP_CODIGO), NOM_FNC_OBTIENENMBREMPL(EMP_CODIGO),` + 
    ` nom_fnc_obtienfecingempl(emp_codigo) AS EMP_FECINGRESO, nom_fnc_obtienempactivo (emp_codigo) AS EMP_ACTIVO, periodo_despricion, ` +
    ` a.periodo_fecini as "PERIODO_FECINI", a.periodo_fecfin as "PERIODO_FECFIN" `;
    this.tabla = 'nom_encrolindi a,nom_maeperiodo b ' 

    this.where = ' a.periodo_codigo = b.periodo_codigo and nvl(encrolindi_estado, \'*\') = \'CERRADO\'';

    console.log(this.consulta, this.tabla, this.where);

    this.busquedaSer.busquedaNomEncRolIndi2().subscribe((res: any[]) => {


      res.map(emp => {
        emp.PERIODO_FECINI = this.convertDate(emp.PERIODO_FECINI);
        emp.PERIODO_FECFIN = this.convertDate(emp.PERIODO_FECFIN);
        emp.EMP_FECINGRESO = this.convertDate(emp.EMP_FECINGRESO);
      })

      this.arregloCons = [...res];
      this.displayDialogBusquedaFast = true;
    });
  }

  cmdasilote() {

    if (this.cmbASI_NRO === [null] || this.cmbASI_NRO === null || this.cmbASI_NRO[0] === null) {
      this.mensajeAlerta('error', 'Error', 'No ha generado el Asiento Contable');
    } else {
      this.asiento = this.cmbASI_NRO[0];
      this.displayAsiContable = true;
    }

  }

  manejarSeleccion(emp) {
    console.log(emp)
    this.llenarCampos(emp['ENCROLINDI_CODIGO']);
    this.displayDialogBusquedaFast = false;
  }



  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }

  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'rolCerado',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
  }

  reset() {
    this.rol = '';
    this.estado = '';
    this.txtPERIODO_FECINI = '';
    this.txtPERIODO_FECFIN = '';
    this.txtENCROL_ANIO = '';
    this.lblEMP_CODIGO = '';
    this.txtEMP_SUELDO = '';
    this.txtEMP_NOMBRE = '';
    this.lblPERIODO_CODIGO = '';
    this.txtENCROLINDI_ESTADO = '';
    this.txtTIPOROL_CODIGO = '';
    this.txtEMP_FECINGRESO = '';
    this.txtEMP_FECSALIDA = '';
    this.fechaSalida = '';
    this.lblENCROLINDI_CODIGO = '';
    this.txtEMP_RUCIDE = '';
    this.cmbTIPROL = [];
    this.cmbTIPROL_CODIGO = '';
    this.cmbASI_NRO = [];
    this.selectedRol;
    this.selectedAsiento;

    this.detalleRoles = [];
    this.detalleProv = [];
    this.total = {
      totalUno: '0',
      totalDos: '0',
      netoPagar: '0'
    }
    this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
    this.aggrid.refreshaggrid(this.detalleProv, this.defaultColDefDetProv.objeto);

  }

  cerrarReportes() {
    this.displayDialogReportes = false;
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos  = false;
    }
  }

}
