import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { VenMaeCliente } from '../../ven/veninterfaces/venmaecliente'

import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { MessageService, SelectItem } from 'primeng/api';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { Usuario } from 'src/app/usuario';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import {CxceditdatosclienteService} from '../cxcservicios/cxceditdatoscliente.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
@Component({
  selector: 'app-cxceditdatoscliente',
  templateUrl: './cxceditdatoscliente.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class CxceditdatosclienteComponent implements OnInit {
  @Input() stCliCodigo:string
  @Output() salir = new EventEmitter<boolean>();

  botonNuevo = false;
  botonGuardar = true;
  botonBorrar = true;
  botonRegresar = true;
  barraBotones2 = true;
  index: number;
  cxceditdatoscli: VenMaeCliente = {};
  lblvalida: string = '';
  cmbide: any[];
  selectide: any;
  permisoProveedor: SegMaePermiso;//GSRF
//busquedas
opcionbusqueda: string;
types: SelectItem[];
arregloCons: any[];
busquedacampos: string[];
tabla: string;
where:string;//CDPJ
displayDialogBusqueda: boolean;
displayDialogBusquedaFast: boolean;



  constructor(
     private busqService: InvbusquedaService,
    private messageService: MessageService, 
     private confIniciales: ConfInicialesService, private permisosService: PermisosService,
     private errorService: ErroresBaseDatosService,
    private auditoriagral: AuditoriagralService, public usuario: Usuario,
    private utilitariosService: UtilitariosService,private cxceditdatosclienteService:CxceditdatosclienteService
  ) { }

  ngOnInit(){
    this.permisosService.getListaPermisos('VEN', '1', '15').subscribe((res) => {
      this.permisoProveedor = res[0];
      console.log('permiso-->',this.permisoProveedor)
    });
    this.index = 0;
    this.barraBotones2 = true;
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
    this.cmbide = [{ codigo: '1', name: 'R.U.C.' },
    { codigo: '2', name: 'Cédula' },
    { codigo: '3', name: 'Pasaporte' },
    { codigo: '4', name: 'Placa' },
    { codigo: '5', name: 'Extranjero' },
    { codigo: '6', name: 'Consumidor Final' }];
    if(this.stCliCodigo !== null && this.stCliCodigo !== undefined && this.stCliCodigo !== ''){
      this.consultarcli()
      
    }
  }
  async manejarSenales(valor) {
    if (valor === 'Guardar') {
      if (this.permisoProveedor.PERACTUALIZACION === 1) {
        this.guardar();
      }
      if (this.permisoProveedor.PERACTUALIZACION === 0) {
        this.messageService.add({
          key: 'cliente',
          severity: 'error',
          summary: 'Actualización de Cliente',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }
    }
    if(valor === 'Cancelar'){
      this.botonGuardar = true;
      this.consultarcli()

    }
    if (valor === 'Salir') {
      this.salir.emit(true);
    }

  }
  guardar(){
    if (
        this.cxceditdatoscli.CLI_DIRECCION1 === '' ||
        this.cxceditdatoscli.CLI_TELEFONO1 === '' ||
        this.cxceditdatoscli.CLI_CORREO === ''
        ) {
        this.messageService.add({
          key: 'cxceditcli',
          severity: 'warn',
          summary: 'Información',
          detail: 'Los campos  Dirección, Teléfono y Correo ' +
            'son obligatorios revise que los datos sean validos'
        });
        return;
      }
      this.cxceditdatosclienteService.actualizarCliente(this.cxceditdatoscli).subscribe((datos: any[]) => {
        
        this.messageService.add({
          key: 'cxceditcli',
          severity: 'success',
          summary: 'Información',
          detail: 'El cliente se actualizó correctamente'
        });
        this.auditoriagral.registrarAuditoria('CXC_EDITDATOSCLI', this.cxceditdatoscli.CLI_CODIGO, 'A',
                  '', '01', '', '', '', '').subscribe();
        this.botonGuardar = true;
        this.consultarcli()
      }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'cxceditcli',
          severity: 'error',
          summary: 'Actualizar cliente',
          detail: mensaje
        });
      })

  }
  setFocus(valor){

  }
  botonesmodificar() {
    this.botonGuardar = false;
  }
  consultarcli(){
    this.cxceditdatosclienteService.getCliente(this.stCliCodigo).subscribe((res) => {
      for (const i of res) {
        this.cxceditdatoscli = i;
        this.lblvalida = i.CLI_VALIDACION;
        for (const j of this.cmbide) {
          if (this.cxceditdatoscli.CLI_TIPOIDE === j.codigo) {
            this.cxceditdatoscli.CLI_TIPOIDENOMBRE = j.name;
            this.selectide = { codigo: j.codigo, name: j.name };
          }
        }
        if (this.cxceditdatoscli.CLI_CARGO !== null) {
          this.cxceditdatosclienteService.erNombreCargo(this.cxceditdatoscli.CLI_CARGO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.cxceditdatoscli.CLI_CARGONOMBRE = formsricom[0].REF_NOMBRE;
              }
            }
          });
        }
        if (this.cxceditdatoscli.CLI_PROVINCIA !== null) {
          this.cxceditdatosclienteService.erNombreProvincia(this.cxceditdatoscli.CLI_PROVINCIA).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.cxceditdatoscli.CLI_PROVINCIANOMBRE = formsricom[0].PROV_NOMBRE;
              }
            }
          });
        }
        if (this.cxceditdatoscli.CIU_CODIGO !== null) {
          this.cxceditdatosclienteService.erNombreCiudad(this.cxceditdatoscli.CIU_CODIGO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.cxceditdatoscli.CIU_NOMBRE = formsricom[0].CIU_NOMBRE;
              }
            }
          });
        }
        if (this.cxceditdatoscli.CAN_CODIGO !== null) {
          this.cxceditdatosclienteService.erNombreCanton(this.cxceditdatoscli.CAN_CODIGO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.cxceditdatoscli.CAN_NOMBRE = formsricom[0].CAN_DESCRIPCION;
              }
            }
          });
        }
        if (this.cxceditdatoscli.PARR_CODIGO !== null) {
          this.cxceditdatosclienteService.erNombreParroquia(this.cxceditdatoscli.PARR_CODIGO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.cxceditdatoscli.PARR_NOMBRE = formsricom[0].PARR_DESCRIPCION;
              }
            }
          });
        }

        if (this.cxceditdatoscli.CLI_ZONA !== null) {
          this.cxceditdatosclienteService.erNombreZona(this.cxceditdatoscli.CLI_ZONA).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.cxceditdatoscli.CLI_ZONANOMBRE = formsricom[0].REF_NOMBRE;
              }
            }
          });
        }
      }
      
    });
  
  }
  busquedaprovincia(parametro) {
    this.opcionbusqueda = 'busquedaprovincia';
    this.types = [
      { label: 'Código', value: 'PROV_CODIGO' },
      { label: 'Nombre', value: 'PROV_NOMBRE' }
    ];
   
    this.busquedacampos = [parametro, '', ''];
    this.tabla = 'VEN_MAEPROVINCIA';
    this.where=''
    this.cxceditdatosclienteService.erNombreProvincia(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.cxceditdatoscli.CLI_PROVINCIA = parametro;
          this.cxceditdatoscli.CLI_PROVINCIANOMBRE = eR[0].PROV_NOMBRE;
          document.getElementById('CIU_CODIGO').focus();
        } else {
          this.busqService.busquedaVenMaeProvincia1().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaVenMaeProvincia1().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaciudad(parametro) {
    this.opcionbusqueda = 'busquedaciudad';
    this.types = [
      { label: 'Código', value: 'CIU_CODIGO' },
      { label: 'Nombre', value: 'CIU_NOMBRE' }
    ];
    parametro=parametro === null || parametro === undefined?'':parametro
    this.busquedacampos = [parametro, '', ''];
    this.tabla = 'VEN_MAECIUDAD';
    this.where=''
    this.cxceditdatosclienteService.erNombreCiudad(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.cxceditdatoscli.CIU_CODIGO = parametro;
          this.cxceditdatoscli.CIU_NOMBRE = eR[0].CIU_NOMBRE;
          document.getElementById('CAN_CODIGO').focus();
        } else {
          this.busqService.busquedaVenMaeCiudad1().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaVenMaeCiudad1().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedacanton(parametro) {
    this.opcionbusqueda = 'busquedacanton';
    this.types = [
      { label: 'Código', value: 'CAN_CODIGO' },
      { label: 'Nombre', value: 'CAN_DESCRIPCION' }
    ];
    parametro=parametro === null || parametro === undefined?'':parametro
    this.busquedacampos = [parametro, '', ''];
    this.tabla = 'VEN_MAECANTON';
    this.where='PV_CODIGO=\''+this.cxceditdatoscli.CLI_PROVINCIA+'\'';

    this.cxceditdatosclienteService.erNombreCanton(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.cxceditdatoscli.CAN_CODIGO = parametro;
          this.cxceditdatoscli.CAN_NOMBRE = eR[0].CAN_DESCRIPCION;
          document.getElementById('PARR_CODIGO').focus();
        } else {
          this.busqService.busquedaVenMaeCanton1(this.cxceditdatoscli.CLI_PROVINCIA).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaVenMaeCanton1(this.cxceditdatoscli.CLI_PROVINCIA).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaparroquia(parametro) {
    this.opcionbusqueda = 'busquedaparroquia';
    this.types = [
      { label: 'Código', value: 'PARR_CODIGO' },
      { label: 'Nombre', value: 'PARR_DESCRIPCION' }
    ];
    parametro=parametro === null || parametro === undefined?'':parametro
    this.busquedacampos = [parametro, '', ''];
    this.tabla = 'VEN_MAEPARROQUIA';
    this.where='CAN_CODIGO=\''+this.cxceditdatoscli.CAN_CODIGO+'\''
    this.cxceditdatosclienteService.erNombreParroquia(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.cxceditdatoscli.PARR_CODIGO = parametro;
          this.cxceditdatoscli.PARR_NOMBRE = eR[0].PARR_DESCRIPCION;
          document.getElementById('CLI_ZONA').focus();
        } else {
          this.busqService.busquedaVenMaeParroquia1(this.cxceditdatoscli.CAN_CODIGO).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaVenMaeParroquia1(this.cxceditdatoscli.CAN_CODIGO).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });

  }

  busquedazona(parametro) {
    this.opcionbusqueda = 'busquedazona';
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' }
    ];

    this.cxceditdatosclienteService.erNombreZona(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.cxceditdatoscli.CLI_ZONA = parametro;
          this.cxceditdatoscli.CLI_ZONANOMBRE = eR[0].REF_NOMBRE;
          document.getElementById('CLI_GMAPS').focus();
        } else {
          this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'Z\'').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusqueda = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'Z\'').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusqueda = true;
        });
      }
    });
  }
  busquedacargo(parametro) {
    this.opcionbusqueda = 'busquedacargo';
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' }
    ];

    this.cxceditdatosclienteService.erNombreCargo(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.cxceditdatoscli.CLI_CARGO = parametro;
          this.cxceditdatoscli.CLI_CARGONOMBRE = eR[0].REF_NOMBRE;
          document.getElementById('CLI_CORREO').focus();
        } else {
          this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'CRG\'').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusqueda = true;
          });

        }
      } else {
        this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'CRG\'').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusqueda = true;
        });

      }
    });
  }
  busquedagmaps(parametro) {
    window.open(parametro, '_blank');
  }
  manejarSeleccion(opcion) {
   
    if (this.opcionbusqueda === 'busquedaprovincia') {
      this.cxceditdatoscli.CLI_PROVINCIA = opcion.PROV_CODIGO;
      this.cxceditdatoscli.CLI_PROVINCIANOMBRE = opcion.PROV_NOMBRE;
      document.getElementById('CIU_CODIGO').focus();
    }
    if (this.opcionbusqueda === 'busquedaciudad') {
      this.cxceditdatoscli.CIU_CODIGO = opcion.CIU_CODIGO;
      this.cxceditdatoscli.CIU_NOMBRE = opcion.CIU_NOMBRE;
      document.getElementById('CAN_CODIGO').focus();
    }
    if (this.opcionbusqueda === 'busquedacanton') {
      this.cxceditdatoscli.CAN_CODIGO = opcion.CAN_CODIGO;
      this.cxceditdatoscli.CAN_NOMBRE = opcion.CAN_DESCRIPCION;
      document.getElementById('PARR_CODIGO').focus();
    }
    if (this.opcionbusqueda === 'busquedaparroquia') {
      this.cxceditdatoscli.PARR_CODIGO = opcion.PARR_CODIGO;
      this.cxceditdatoscli.PARR_NOMBRE = opcion.PARR_DESCRIPCION;
      document.getElementById('CLI_ZONA').focus();
    }
    if (this.opcionbusqueda === 'busquedazona') {
      this.cxceditdatoscli.CLI_ZONA = opcion.REF_CODIGO;
      this.cxceditdatoscli.CLI_ZONANOMBRE = opcion.REF_NOMBRE;
      document.getElementById('CLI_GMAPS').focus();
    }
    if (this.opcionbusqueda === 'busquedacargo') {
      this.cxceditdatoscli.CLI_CARGO = opcion.REF_CODIGO;
      this.cxceditdatoscli.CLI_CARGONOMBRE = opcion.REF_NOMBRE;
      document.getElementById('CLI_CORREO').focus();
    }

    this.displayDialogBusqueda = false;
    this.displayDialogBusquedaFast = false;
  }
  async changeTabs() {}
}

