<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="cxctrnpagoretKey"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarcxctrnpagoret" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
						 [botonRegresar]="btnRegresar" [botonPrimero]="btnPrimero" [botonAnterior]="btnAnterior"
						 [botonSiguiente]="btnSiguiente" [botonUltimo]="btnUltimo" [barraBotonesDesplaz]="false"
						 [barraBotones3]="true" [barraBotonesAsiCon]="false"
						 [barraBotones5]="false" [botonBuscar]="false"
						 [botonAsiento]="btnAsiento" [botonverAsiento]="btnVerAsiento" [botonAlmacenardoc] = "true"
						 (signal)="manejarSenales($event)"
		>
		</app-invmaebarra>
	</div>
</div>

<p-tabView>
	<p-tabPanel header="Datos">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-12">
					<!--LINEA 1-->
					<div class="p-grid">
						<div class="p-col-5"></div>
						<div class="p-col-1">
							<label for="estado"></label>
							<input class="frm" id="estado" type="text" pInputText
								   [(ngModel)]="estado"
								   disabled>
						</div>
						<div class="p-col-2">
							<label for="trncobronrodoc"></label>
							<input class="frm" id="trncobronrodoc" type="text" pInputText
								   [(ngModel)]="cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC"
								   disabled>
						</div>
						<div class="p-col-2">
							<label for="asiento"></label>
							<input class="frm" id="asiento" type="text" pInputText
								   [(ngModel)]="cxctrnpagoretService.cxcpagoret.ASI_NRO"
								   disabled>
						</div>
						<div class="p-col-2"></div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top:-15px">
					<!--LINEA 2-->
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-1">
							<span>  Cliente: </span>
						</div>
						<div class="p-col-2">
							<label for="cliente"></label>
							<input class="frm" id="cliente" type="text" pInputText
								   [(ngModel)]="cxctrnpagoretService.cxcpagoret.CLI_CODIGO"
								   (keydown.enter)="setFocus($event)">
						</div>
						<div class="p-col-1">
							<button type="button"
									(click)="abrirCliente('')"
									style="background-image: url(../../../assets/images/iconos/buscar.png);">
							</button>
						</div>
						<div class="p-col-3">
							<label for="cliente2"></label>
							<input class="frm" id="cliente2" type="text" pInputText
								   [(ngModel)]="busCLINOMBRE"
								   disabled>
						</div>
						<div class="p-col-1"></div>
						<div class="p-col-2">
							<label for="impreso"></label>
							<input class="frm" id="impreso" type="text" pInputText
								   [(ngModel)]="cxctrnpagoretService.cxcpagoret.TRNCOBRO_IMPRESO"
								   [disabled]="enableinputs">
						</div>
						<div class="p-col-1"></div>

					</div>
				</div>
				<div class="p-col-12" style="margin-top:-17px">
					<!--LINEA 3-->
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-1">
							<span>  Vendedor: </span>
						</div>
						<div class="p-col-2">
							<label for="vendedor"></label>
							<input class="frm" id="vendedor" type="text" pInputText
								   [(ngModel)]="cxctrnpagoretService.cxcpagoret.VEN_CODIGO"
								   (keydown.enter)="setFocus($event)">
						</div>
						<div class="p-col-1">
							<button type="button"
									(click)="abrirVendedor('')"
									style="background-image: url(../../../assets/images/iconos/buscar.png);">
							</button>
						</div>
						<div class="p-col-3">
							<label for="vendedor2"></label>
							<input class="frm" id="vendedor2" type="text" pInputText
								   [(ngModel)]="busVENNOMBRE"
								   disabled>
						</div>
						<div class="p-col-4"></div>

					</div>
				</div>
				<div class="p-col-12" style="margin-top:-17px">
					<!--LINEA 4-->
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-1">
							<span>  Número: </span>
						</div>
						<div class="p-col-2">
							<label for="nro"></label>
							<input class="frm" id="nro" type="text" pInputText
								   [(ngModel)]="cxctrnpagoretService.cxcpagoret.TRNCOBRO_NROCOMPROBANTE"
								   (keydown.enter)="setFocus($event)"
								   [disabled]="enableinputs">
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-1">
							<span>  Fecha: </span>
						</div>
						<div class="p-col-2">
							<p-calendar id="fecha" [inputStyle]="{width: '100%', height: '20px'}" dateFormat="dd/mm/yy"
										[(ngModel)]="cxctrnpagoretService.cxcpagoret.TRNCOBRO_FECHATRN"
										(ngModelChange)="cambiarformatoFecha(cxctrnpagoretService.cxcpagoret.TRNCOBRO_FECHATRN)"
										(keydown.enter)="setFocus($event)"></p-calendar>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-1"></div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top:-15px; margin-bottom: -15px">
					<!--LINEA 5-->
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-1">
							<span>  Concepto: </span>
						</div>
						<div class="p-col-8">
							<label for="concepto"></label>
							<input class="frm" id="concepto" type="text" pInputText 
								   [(ngModel)]="cxctrnpagoretService.cxcpagoret.TRNCOBRO_CONCEPTO"
								   (keydown)="botonesmodificar()" autocomplete="off">
						</div>
						<div class="p-col-2"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-12">
					<p-tabView [(activeIndex)]="activeIndex" (activeIndexChange)="cargarTabla()">
						<p-tabPanel header="Documentos">
							<div class="p-col-12">
								<app-aggridsaci
										*ngIf="activeIndex === 0"
										[width]=""
										[height]="largo"
										[enableSorting]="true"
										[enableFilter]="true"
										[rowData]="cxctrnpagoretService.cxcpagoretdocumentos"
										[frameworkComponents]="frameworkComponents"
										[animateRows]="true"
										[rowSelection]="rowSelection"
										[columnDefs]="columnDefsDoc"
										[defaultColDef]="defaultColDefDoc"
										[rowStyle]="rowStyle"
										[singleClickEdit]="false"
										[botones]="false"
										(selected)="seleccionDoc($event)"
										(cambios)="cambioDoc($event)"
										align="left"
								></app-aggridsaci>
							</div>
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-1">
										<p-colorPicker [disabled]="true" [(ngModel)]="choosedColor"></p-colorPicker>
									</div>
									<div class="p-col-2">
										<span>Facturas Generadas por cuotas</span>
									</div>
									<div class="p-col-9">
									</div>
								</div>
							</div>
							<!--<div class="p-col-6">
								<div class="p-col-4">
									<p-colorPicker [disabled]="true" [(ngModel)]="choosedColor"></p-colorPicker>
								</div>
								<div class="p-col-2">
									<span >  Facturas generadas por Cuotas </span>
								</div>
							</div>-->

						</p-tabPanel>
						<p-tabPanel header="Retenciones">
							<div class="divgrups">
								<div class="p-grid">
									<div class="p-col-2">
										<span>  Factura: </span>
									</div>
									<div class="p-col-2">
										<label for="nrofactret"></label>
										<input class="frm" id="nrofactret" type="text" pInputText disabled
											   [(ngModel)]="numeroFactura">
									</div>
									<div class="p-col-7">
										<span style="color: rgb(0, 0, 255); font-weight: bold">{{lblAplicaRegMicroempresas}}</span>
									</div>
									<div class="p-col-12">
										<app-aggridsaci
												*ngIf="activeIndex === 1"
												[width]=""
												[height]="largo"
												[enableSorting]="false"
												[enableFilter]="true"
												[frameworkComponents]="frameworkComponents"
												[animateRows]="true"
												[rowSelection]="rowSelection"
												[rowData]="cxctrnpagoretService.cxcpagoretretenciones"
												[columnDefs]="columnDefsRet"
												[defaultColDef]="defaultColDefRet"
												[rowStyle]="rowStyle"
												[singleClickEdit]="false"
												[botones]="true"
												[mostrarGuardar]="false"
												[botonNuevo]="btnNuevoGridret"
												[botonGuardar]="btnGuardarGridret"
												[botonBorrar]="btnBorrarGridret"
												[botonRegresar]="btnCancelarGridret"
												(selected)="seleccionRetenciones($event)"
												(cambios)="cambioRet($event)"
												align="left"
										></app-aggridsaci>
									</div>
									<div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px">
										<div class="p-grid">
											<div class="p-col-2">
												<div class="p-grid">
													<div class="p-col-3">
														<p-checkbox id="chkunificado"
																	name="chkunificado" value="" label="" binary="{{chkretelectronica}}"
																	[(ngModel)]="chkretelectronica" (click)="botonesmodificar()"
																	(keydown.enter)="setFocus($event)"></p-checkbox>
													</div>
													<div class="p-col-9">
														<span>Retención Electrónica</span>
													</div>
												</div>
											</div>
											<div class="p-col-3"></div>
											<div class="p-col-2">
												<span>  Total Retenciones por Fact.: </span>
											</div>
											<div class="p-col-2">
												<label for="totalretfac"></label>
												<input class="frm" id="totalretfac" type="text" pInputText
													   [(ngModel)]="cxctrnpagoretService.cxcpagoret.TOTALRET">
											</div>
										</div>
									</div>
								</div>
							</div>
						</p-tabPanel>
						<p-tabPanel header="Formas de Pago">
							<div class="divgrups">
								<div class="p-grid">
									<div class="p-col-12">
										<app-aggridsaci
												*ngIf="activeIndex === 2"
												[width]=""
												[height]="largo"
												[enableSorting]="false"
												[enableFilter]="true"
												[frameworkComponents]="frameworkComponents"
												[animateRows]="true"
												[rowSelection]="rowSelection"
												[rowData]="cxctrnpagoretService.cxcpagoretformapago"
												[columnDefs]="columnDefsFormaPago"
												[defaultColDef]="defaultColDefFormaPago"
												[rowStyle]="rowStyle"
												[singleClickEdit]="false"
												[botones]="true"
												[mostrarGuardar]="false"
												[botonNuevo]="btnNuevoGridfp"
												[botonGuardar]="btnGuardarGridfp"
												[botonBorrar]="btnBorrarGridfp"
												[botonRegresar]="btnCancelarGridfp"
												(selected)="seleccionFormasPago($event)"
												(cambios)="cambioFP($event)"

												align="left"
										></app-aggridsaci>
									</div>
									<div class="p-col-12" style="margin-top: -15px">
										<div class="p-grid">
									<div class="p-col-2">
										<span>  Total Forma Pago: </span>
									</div>
									<div class="p-col-2">
										<label for="totalfp"></label>
										<input class="frm" id="totalfp" type="text" pInputText
											   [disabled]="enableinputs">
									</div>
									<div class="p-col-8"></div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px">
										<div class="p-grid">
											<div class="p-col-1">
												<p-colorPicker [disabled]="true"
															   [(ngModel)]="choosedColorFP"></p-colorPicker>
											</div>
											<div class="p-col-2">
												<span>Documentos Conciliados</span>
											</div>
											<div class="p-col-9">
											</div>
										</div>
									</div>
								</div>
							</div>
						</p-tabPanel>
					</p-tabView>
				</div>
			</div>
		</div>
		<div class="divgrupstabla">
			<div class="p-grid">
				<div class="p-col-2">
					<span class="obligatorio">  Total Documentos: </span>
				</div>
				<div class="p-col-1">
					<label for="totaldoc"></label>
					<input class="totales" id="totaldoc" type="text" pInputText
						   [(ngModel)]="cxctrnpagoretService.cxcpagoret.TOTALDOC">
				</div>
				<div class="p-col-2">
					<span class="obligatorio">  Total Retenciones: </span>
				</div>
				<div class="p-col-1">
					<label for="totalret"></label>
					<input class="totales" id="totalret" type="text" pInputText
						   [(ngModel)]="cxctrnpagoretService.cxcpagoret.TOTALRET">
				</div>
				<div class="p-col-2">
					<span class="obligatorio">  Total Forma Pago: </span>
				</div>
				<div class="p-col-1">
					<label for="totalformapago"></label>
					<input class="totales" id="totalformapago" type="text" pInputText
						   [(ngModel)]="cxctrnpagoretService.cxcpagoret.TOTALFORMAPAGO">
				</div>
				<div class="p-col-2">
					<span class="obligatorio">  Total A Pagar: </span>
				</div>
				<div class="p-col-1">
					<label for="totalcobrar"></label>
					<input class="totales" id="totalcobrar" type="text" pInputText
						   [(ngModel)]="cxctrnpagoretService.cxcpagoret.TOTALACOBRAR">
				</div>

			</div>
		</div>
	</p-tabPanel>
</p-tabView>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloBus" [tabla]="tabla" [where]="where"
					 [busquedaParams]="busquedacampos" [busquedaCompuesta]="true"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width:'90vw', height:'120vh'}"
		  [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="asiento" [abiertoDesde]="'venencfac'"
					(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>

<p-dialog header="Reportes" [(visible)]="displayDialogReportes"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '40vw', height: '400px'}">
	<app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
						 [strNumDoc]="cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC"
						 [strCampo]="'TRNCOBRO_NRODOC'" (cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'CXCTRNPAGORET'" [txtNumComprobante] = "cxctrnpagoretService.cxcpagoret.TRNCOBRO_NRODOC"
	(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>
<!-- CDPJ -->
<div style="position: fixed;
z-index: 999;
height: 2em;
width: 2em;
overflow: visible;
margin: auto;
top: 0;
left: 0;
bottom: 0;
right: 0;">
<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
</p-progressSpinner>
<p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
[style]="{ opacity: 0.00}" position="top">
</p-dialog>
</div>
<!-- CDPJ -->