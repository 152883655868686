import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { ActMaeGrupo } from '../actinterfaces/actmaegrupo';
import { MessageService } from 'primeng';

@Injectable({
  providedIn: 'root'
})
export class ActmaegrupoService {

  private way: string;

  public btnNuevo:boolean[] = [];
  public btnGuardar:boolean[] = [];
  public btnBorrar:boolean[] = [];
  public btnBuscar:boolean[] = [];

  
  public detmaeGrupoArray: ActMaeGrupo[][] = [];

  constructor(private http: HttpClient, 
    private confIniciales: ConfInicialesService, 
    private init: NuevoComponentService,
    private message: MessageService) { 
      this.way = sessionStorage.getItem('way');
      

  }

  activateButtons(tipoActivacion) {
    try{
      switch(tipoActivacion){
        case 'EDIT' :{
          this.activaBoton(true, true, false, false, false, true);
        }break;
        case 'INSERT':{
         this.activaBoton(true, true, false, false, false, true);
        }break;
        case 'NORMAL_STATE':{
          this.activaBoton(false, false, true, true, true, true);				
        }break;
        case 'DESACTIVE_ALL':{
          this.activaBoton(false, false, false, false, false, false);				
        }break;
        case 'PRY_1':{
          this.activaBoton(true, true, false,false, false, false);	
          
        }break;
        case 'RESTART':{
          this.activaBoton(false, false, true, true, true, true);
        }break;
      }
      }catch(err){}
  }

  activaBoton(guarda, cancela,nuevo, elimina, busca, refresca){
    this.btnGuardar[this.init.tabs[this.init.tabindex].indice] = !guarda;
    this.btnNuevo[this.init.tabs[this.init.tabindex].indice] = !nuevo;
    this.btnBorrar[this.init.tabs[this.init.tabindex].indice] = !elimina;
    this.btnBuscar[this.init.tabs[this.init.tabindex].indice]= !busca;
    console.log(this.btnNuevo);
  }

  consultaGRUPOS(parametros) {
    return this.http.post<any[]>(this.way + '/act/maegrupo/3317e5950dfb38', {
      elementos: {
        p_GRU_codigo: parametros[0],
        p_GRU_orden: parametros[1],
        p_GRU_selecc: parametros[2]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarGRUPO(parametros) {
    return this.http.post<any[]>(this.way + '/act/maegrupo/4afc75950dfb38', {
      elementos: {
        p_actgr_codigo_1: parametros[0],
        p_actgr_nombre_2: parametros[1],
        p_CON_codigo_3: parametros[2],
        p_CEN_codigo: parametros[3],
        p_actgr_codigop_4: parametros[4]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarGRUPO(parametros) {
    return this.http.post<any[]>(this.way + '/act/maegrupo/299df5950dfb38', {
      elementos: {
        p_actgr_codigo_1: parametros[0],
        p_actgr_codigo_2: parametros[1],
        p_actgr_nombre_3: parametros[2],
        p_CON_codigo_4: parametros[3],
        p_CEN_codigo: parametros[4],
        p_actgr_codigop_5: parametros[5],
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarGRUPO(parametros) {
    return this.http.post<any[]>(this.way + '/act/maegrupo/3ab2b5950dfb38', {
      elementos: {
        p_actgr_codigo: parametros[0]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'actmaegrupo',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
}
