//
// SACI WEB
// Rutina: Pto Vta Maestro Categoria
// Propietario: Wilson Ramos
// Modificado por: WR
// Fecha de creación: 2019
// Fecha de Modificación: 2019
//
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Maecategoria} from '../../interfaces/maecategoria';
import {MaecategoriaService} from '../../servicios/maecategoria.service';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {Maesubcategoria} from '../../interfaces/maesubcategoria';



@Component({
  selector: 'app-maecategoria',
  templateUrl: './maecategoria.component.html',
  styleUrls: ['./maecategoria.component.css'],
  preserveWhitespaces: false
})
export class MaecategoriaComponent implements OnInit {
  @Output() subCat = new EventEmitter<Maesubcategoria[]>();
  displayDialog: boolean;
  subcategorias: Maesubcategoria[];
  categoria: Maecategoria = {};
  subcats: Maesubcategoria[];
  selectedCategoria: Maecategoria;
  newCategoria: boolean;
  arregloCategorias: string[] = [];
  arregloCategoriasSeleccionadas: [Maesubcategoria[]] = [[]];
  categorias: Maecategoria[];
  categoriasAux: Maecategoria[];
  clonedCategorias: { [s: string]: Maecategoria; } = {};
  cols: any[];
  selectedCat: boolean;
  
  editing: boolean;
  
  constructor(private categoriaService: MaecategoriaService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService) {
  }


  ngOnInit() {
    this.categoriaService.getMaeCategorias().subscribe((respuesta: any[]) => {
      this.categorias = respuesta;
    });

    this.cols = [
      {field: 'CAT_CODIGO', header: 'COD Categoria'},
      {field: 'CAT_NOMBRE', header: 'Nombre'},
      {field: 'CAT_CORTO', header: 'Corto'},
      {field: '', header: 'Opciones'}
    ];
    this.editing = false;
  }

  showDialogToAdd() {
    this.newCategoria = true;
    this.categoria = {};
    this.displayDialog = true;
  }

  save() {
    const categorias = [...this.categorias];
    if (this.newCategoria) {
      categorias.push(this.categoria);
    } else {
      categorias[this.categorias.indexOf(this.selectedCategoria)] = this.categoria;
    }


    this.categorias = categorias;
    this.categoriaService.agregarCategoria(this.categoria);
    this.categoria = null;
    this.displayDialog = false;
    this.messageService.add({key: 'message', severity: 'success', summary: 'inserccion exitosa', detail: 'inserccion exitosa'});
  }

  delete() {
    const index = this.categorias.indexOf(this.selectedCategoria);
    this.categorias = this.categorias.filter((val, i) => i !== index);
    this.categoria = null;
    this.displayDialog = false;
  }

  onRowEditInit(categoria: Maecategoria) {
    this.clonedCategorias[categoria.CAT_CODIGO] = {...categoria};
    this.editing = true;
  }

  onRowEditSave(categoria: Maecategoria) {
    if (categoria.CAT_NOMBRE !== '') {
      this.categoriaService.actualizarCategoria(categoria);

      delete this.clonedCategorias[categoria.CAT_CODIGO];
      this.messageService.add({key: 'message', severity: 'success', summary: 'Actualización Exitosa!', detail: 'Categoria Actualizada'});
      this.editing = false;
    } else {
      this.messageService.add({
        key: 'message',
        severity: 'warn',
        summary: 'Actualización no Completada',
        detail: 'Los valores deben ser distintos de vacio'
      });

    }
  }

  onRowEditCancel() {
    this.categoriaService.getMaeCategorias().subscribe((respuesta: any[]) => {
      this.categoriasAux = respuesta;
      this.categorias = this.categoriasAux;
    });
  }

  onRowDelete(subCat: Maecategoria, index: number) {
    this.categoriaService.eliminarCategoria(subCat);
    this.categorias = this.categorias.filter((val, i) => i !== index);
    this.categoria = null;

  }

  showConfirm(categoria: Maecategoria, index: number) {
    const index1 = index;
    let cat1: Maecategoria;
    cat1 = categoria;
    this.confirmationService.confirm({
      message: 'Desea eliminar esta categoria?',
      header: 'Confirmar',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.onRowDelete(cat1, index1);
      },
      blockScroll: true
    });
  }

  onReject() {
    this.messageService.clear('c');
  }

  irPantalla(cat: Maecategoria) {
    if (this.editing === false) {
      this.selectedCat = true;
      this.arregloCategorias.push(cat.CAT_NOMBRE);
      this.categoriaService.recogerCategoria(cat).subscribe((respuesta: any[]) => {
        this.subcategorias = respuesta;
        this.subcats = respuesta;
        this.arregloCategoriasSeleccionadas.push(respuesta);
        this.subCat.emit(this.subcategorias);
      });
    }
  }

  accept(Cat: Maecategoria, index: number) {
    this.onRowDelete(Cat, index);
  }

}

