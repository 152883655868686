import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MessageService, SelectItem} from 'primeng';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
@Component({
  selector: 'app-busquedabdg',
  templateUrl: './busquedabdg.component.html',
  styleUrls: ['./busquedabdg.component.css']
})
export class BusquedabdgComponent implements OnInit, OnChanges {
  @Input() arregloConsulta: any[];
  @Input() arrayOptions: SelectItem[];
  @Input() tabla: string;
  @Input() busquedaParams: string[];
  @Input() consulta: string;
  @Input() consulta1: string;
  @Input() consulta2: string;
  @Input() where: string;
  @Input() busquedaCompuesta: boolean;
  @Output() opcionSeleccionada = new EventEmitter<any>();
  
  tipoBusqueda: string;
  tipoSeleccionado: string;
  spin: boolean;
  datos = [];
  constructor(private busqService: InvbusquedaService, private message: MessageService) { }
  
  ngOnInit() {
    this.tipoBusqueda = 'contains';
    this.tipoSeleccionado = '';
    this.consulta = '';
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    for (const valor in changes) {
      if (valor === 'arrayOptions') {
        if (this.arrayOptions !== undefined) {
          this.tipoSeleccionado = this.arrayOptions[0].value;
        }
      } else if (valor === 'arregloConsulta') {
      }
    }
  }
  
  async busquedaIndi(params, tipo) {
    
    
    
    let condicion = '';
    let aux = 0;
    
    if (tipo === 'contains') {
      aux = 0;
      // for (const {item, index} of this.arrayOptions.map((item, index) => ({item, index}))) {
      this.arrayOptions.forEach((item, index) => {
        if (params[index] !== '') {
          if (aux === 0) {
            condicion = 'UPPER(' + item.value + ') LIKE UPPER(\'%' + params[index] + '%\')';
            aux++;
          } else {
            condicion = condicion + ' AND UPPER(' + item.value + ') LIKE UPPER(\'%' + params[index] + '%\')';
            aux++;
          }
        }
        if (aux === 0) {
          condicion = 'ROWNUM<100';
        }
      });
    }
    
    if (tipo === 'equals') {
      aux = 0;
      this.arrayOptions.forEach((item, index) => {
        if (params[index] !== '') {
          if (aux === 0) {
            condicion = 'UPPER(' + item.value + ') = UPPER(\'' + params[index] + '\')';
            aux++;
          } else {
            condicion = condicion + ' AND UPPER(' + item.value + ') = UPPER(\'' + params[index] + '\')';
            aux++;
          }
        }
        if (aux === 0) {
          condicion = 'ROWNUM<100';
        }
      });
    }
    
    if (this.where !== undefined) {
      if (this.where.length > 0) {
        condicion = condicion + ' AND ' + this.where;
      }
    }
    
    this.spin = true;
    
    this.datos = [];
    
    for ( const arrCon of this.arregloConsulta ) {
      const consulta ='distinct '+  '\'' + arrCon + this.consulta + arrCon + this.consulta1 + arrCon + this.consulta2;
      if (this.consulta === '') {
    
        await this.busqService.buscarconsultexistencias('*', this.tabla, condicion).toPromise().then((datos: any[]) => {
         /* if (datos === null) {
            this.mensajeAlerta('error', 'ERROR', 'No se han encontrado coincidencias ' +
              ' bajo los criterios especifiados, modifiquelos, he intente nuevamente');
          } else {
            this.datosObtenidos(datos);
          }
          this.spin = false;*/
         if ( datos !==  null ) {
           this.datos.push(...datos);
         }
        }).catch( e  => {
          console.error(e);
        });
    
    
      } else if (this.consulta !== '' && this.busquedaCompuesta === false) {
        await this.busqService.buscarconsultexistencias( consulta, this.tabla, condicion).toPromise().then((datos: any[]) => {
          if (  datos !==  null ) {
            this.datos.push(...datos);
          }
          // this.spin = false;
        }).catch( e  => {
          console.error(e);
        });
      }
      
    }
    this.datosObtenidos(this.datos);
    this.spin = false;
    
    
  }
  
  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'busqDlgMae',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
  
  datosObtenidos(datos) {
    for (let i = 0; i < 30; i++) {
      this.busquedaParams[i] = '';
    }
    if ( datos.length === 0 ) {
      this.mensajeAlerta('error', 'ERROR', 'No se han encontrado coincidencias ' +
        ' bajo los criterios especifiados, modifiquelos, he intente nuevamente');
    }
    this.opcionSeleccionada.emit(datos);
  }
  
}
