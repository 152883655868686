<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="principal"></p-toast>
<div class="wrapper">
	<div class="p-grid">
		<div class="p-col-12">
			<p-accordion>
				<p-accordionTab (click)="iniciarBarra()" [selected]="seleccionadaBarra" [transitionOptions]="'0ms'">
					<p-header>
						<span style="padding-right: 20%">{{titulo}}</span>
						<span>{{datos}}</span>
						<span style="display: block; float: right">{{datosiniciales}}</span>
					</p-header>
					<div class="p-grid fondopedido">
						<div class="p-col-12">
							<table style="width: 100%">
								<tr>
									<td style="width: 25%" >
										<div style="text-align: left">
											<button class="buttonprincipal" type="button" pButton (click)="nuevo()" title="Nuevo Pedido"
													style="background-image: url(../../../../assets/images/iconspdv/nueva-factura-cuadrado-2.png); outline: none;"></button>
											<button class="buttonprincipal" type="button" pButton (click)="showDialogpedido()" title="Buscar"
													style="background-image: url(../../../../assets/images/iconspdv/boton-buscar-cuadrado.png); outline: none;"></button>
											<button class="buttonprincipal" type="button" pButton (click)="anular()" title="Anular Pedido"
													style="background-image: url(../../../../assets/images/iconspdv/anularfactura.png); outline: none;"></button>
										</div>
									</td>
									<td style="width: 35%">
										<span style="font-weight: bold">Estado:  </span>
										<span
												style="background: transparent; padding-left: 20px; padding-right: 20px; margin-left: 27px">{{facSeleccionada.ENCPED_ESTADO}}</span>
									<td style="width: 40%">
                    <span
							style="background: transparent; padding-left: 20px; padding-right: 20px">{{facSeleccionada.ENCPED_NUMERO}}</span>
										<div style="float: right">
											<span style="font-weight: bold">Digital:  </span>
											<p-checkbox
													[disabled]="facSeleccionada.ENCPED_NUMERO !== '' && facSeleccionada.ENCPED_NUMERO !== undefined && facSeleccionada.ENCPED_NUMERO !== null"
													name="group1" value="" label="" binary="{{chkpedido}}" [(ngModel)]="chkpedido"></p-checkbox>
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<br>
										<table>
											<tr>
												<span style="font-weight: bold">Fecha:  </span>
												<p-calendar [disabled]="fechaInput" [(ngModel)]="date1"
															[readonlyInput]="true" showIcon="true" dateFormat="dd/mm/yy" [locale]="es">
												</p-calendar>
												<br>
											</tr>
											<br>
											<tr>
												<span style="font-weight: bold; margin-top: -5px">Pedido: </span>
												<span
														style="background: transparent; padding-left: 20px; padding-right: 20px; margin-left: 10px">{{facSeleccionada.ENCPED_NUMERO}}</span>
											</tr>
										</table>
									</td>
									<td colspan="2">
										<table>
											<tr>
												<td>
													<span style="font-weight: bold">Cliente:</span>
												</td>
												<td>
													<input [disabled]="inputsIniciales" (input)="onInputChange($event)" size="25px" pInputText
														   type="text" [(ngModel)]="codCli"
														   (click)="teclado('cliente')" (keydown.enter)="onSelectCliente2(codCli)">

													<button [disabled]="inputsIniciales" class="buttonprincipal" type="button" pButton
															(click)="showDialogCliente()"
															style="background-image: url(../../../../assets/images/iconspdv/boton-buscar-cuadrado.png); outline: none;"></button>
												</td>
												<td>
													<span>{{nombrecliente || ''}}</span>
												</td>
											</tr>
											<tr>
												<td>
													<span style="font-weight: bold">Vendedor:</span>
												</td>
												<td>
													<input [disabled]="inputsIniciales" (input)="onInputChange($event)" size="25px" pInputText
														   type="text" [(ngModel)]="codVen"
														   (click)="teclado('vendedor')" (keydown.enter)="onSelectvendedor2(codVen)"
														   (focus)="teclado('vendedor')">

													<button [disabled]="inputsIniciales" class="buttonprincipal" type="button" pButton
															(click)="showDialogVendedor()"
															style="background-image: url(../../../../assets/images/iconspdv/boton-buscar-cuadrado.png); outline: none;"></button>
												</td>
												<td>
													<span>{{nombrevendedor || ''}}</span>
												</td>
											</tr>
										</table>
									</td>
								</tr>
							</table>
						</div>
					</div>
				</p-accordionTab>
			</p-accordion>
		</div>
	</div>
	<div id="divPrincipal" class="p-grid" (click)="barraSelected()">
		<div class="p-col-5">
			<div>
				<app-pedpedido (productobo)="productobo($event)" [instanciaPrin]="instanciaPrin" [selectedProducto]="producto"
							   [auxiliar]="auxiliar" [facPagada]="pagada"></app-pedpedido>
			</div>
			<div style="height:100%; margin-top: -8px; margin-bottom: 0px">
				<app-pedcalculadora (valor)="enviarNum($event)" [producto]="producto"
									(codCliente)="reiniciarFac($event)" (numfact)="numped($event)" (estado)="estado($event)"
									(pagada)="facPagada($event)" [inpcliente]="maecliente"
									[inpvendedor]="maevendedor" [auxiliar]="auxiliar" [pedido]="facSeleccionada.ENCPED_NUMERO"
									[inppedido]="facSeleccionada" [inpchkpedido]="chkpedido"
									[inpDate]="date1" (openTeclado)="teclado($event)" [nomArt]="productoBuscar"
									[facPagada]="pagada" [initPrin]="instanciaPrin" [fechainicial]="fechainicial"
									[fechafinal]="fechafinal" [valorDscGlobalP]="dscPorcentaje"
									[valorDscGlobalV]="dscValor" [desenfoque]="desenfocar"></app-pedcalculadora>
			</div>
		</div>
		<div class="p-col-7">
			<div style="height: calc(100vh - 200px)">
				<app-pedproductos (producto)="enviarProducto($event)" [pagada]="pagada"></app-pedproductos>
			</div>
			<div class="results">
				<span>A Pagar:</span>
				<span style="margin-left: 20px">${{total[instanciaPrin - 1]}}</span>
			</div>
		</div>
	</div>
</div>

<p-sidebar [(visible)]="displayDialogTeclado" [position]="posicionBarra"
		   [style]="{height: alturateclado, width: anchoteclado}" [modal]="false">
	<div class="simple-keyboard"></div>
</p-sidebar>

<p-confirmDialog key="principal" appendTo="body" [style]="{width:'50vw'}" [transitionOptions]="'0ms'">
</p-confirmDialog>


<p-dialog header="Buscar Pedido" [(visible)]="displayDialogBuscarpedidos" [responsive]="true"
		  [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '700px'}">
	<p-scrollPanel [style]="{'width':'100%', 'height':'480px'}">
		<div class="p-grid">
			<div class="p-col-12">
				<p-table #buscar selectionMode="single" editMode="row"
						 [globalFilterFields]="['ENCPED_NUMERO', 'ENCPED_FECHAEMISION', 'CLI_CODIGO', 'CLI_NOMBRE']"
						 [selection]="facSeleccionada" [value]="pedidos">
					<ng-template pTemplate="caption">
						<div class="text-left">
							<i class="fa fa-search" style="margin: 4px 4px 0 0"
							   (click)="buscar.filterGlobal(busquedaFac, 'contains')"></i>
							<input id="inputBusqueda" type="text" pInputText size="50" [(ngModel)]="busquedaFac"
								   (input)="buscar.filterGlobal(busquedaFac, 'contains')"
								   style="width: auto" (click)="teclado('buscarPed')">
						</div>
					</ng-template>
					<ng-template pTemplate="header">
						<tr class="ui-table-scrollable-wrapper">
							<th [ngStyle]="{'width':'30%'}">Pedido</th>
							<th [ngStyle]="{'width':'20%'}">Fecha</th>
							<th [ngStyle]="{'width':'20%'}">Cliente</th>
							<th [ngStyle]="{'width':'30%'}">Nombre</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-factura let-rowIndex="rowIndex"
								 let-rowData>
						<tr [pSelectableRow]="rowData" (click)="selectedPedido(rowData, rowIndex)">
							<td style="height: 20px">
								{{factura.ENCPED_NUMERO}}
							</td>
							<td style="height: 20px">
								{{factura.ENCPED_FECHAEMISION.toLocaleString('en-GB', {timeZone: 'UTC'}).substr(0, 10)}}
							</td>
							<td style="height: 20px">
								{{factura.CLI_CODIGO}}
							</td>
							<td style="height: 20px">
								{{factura.CLI_NOMBRE}}
							</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</div>
	</p-scrollPanel>
	<p-footer>
		<div class="ui-dialog-buttonpane ui-helper-clearfix">
			<button type="button" pButton icon="fa fa-check" (click)="seleccionarpedido()" label="Seleccionar"></button>
		</div>
	</p-footer>
</p-dialog>

<p-dialog header="Clave Vendedor" [(visible)]="displayDialogClave" [responsive]="true" [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '500px'}" (onHide)="cerrarClave()">
	<div class="ui-g ui-fluid">
		<div class="ui-g-12">
			<div class="ui-g-4">
				<label for="VEN_CLAVE">Clave del vendedor : </label>
			</div>
			<div class="ui-g-8">
				<input autofocus (click)="teclado('newfac')" type="password" id="VEN_CLAVE" [(ngModel)]="clavevendedor"
					   (keydown.enter)="seleccionarClaveVendedor()">
			</div>
		</div>
	</div>
	<p-footer>
		<div class="ui-dialog-buttonpane ui-helper-clearfix">
			<button type="button" pButton icon="fa fa-check" (click)="seleccionarClaveVendedor()"
					label="Seleccionar"></button>
		</div>
	</p-footer>
</p-dialog>


<p-dialog header="Seleccionar Vendedor" [(visible)]="displayDialogVendedor" [responsive]="true"
		  [transitionOptions]="'0ms'" [modal]="false" [style]="{width: '500px'}">
	<div class="ui-g ui-fluid">
		<div class="ui-g-12">
			<div class="ui-g-4">
				<label for="VEN_CODIGO">Codigo Vendedor : </label>
			</div>
			<div class="ui-g-8">
				<input *ngIf="!autodialog" pInputText type="text" [disabled]="true" [(ngModel)]="auxcodigovendedor">
				<p-autoComplete *ngIf="autodialog" [(ngModel)]="auxcodigovendedor" [suggestions]="filteredVendedorSingle"
								(completeMethod)="filtervendedorSingle($event)" id="VEN_CODIGO" field="VEN_CODIGO" [size]="30"
								placeholder="Codigo" (onSelect)="onSelectvendedor(auxcodigovendedor)"
								[minLength]="1" (click)="teclado('venCod')"></p-autoComplete>
			</div>
		</div>
		<div class="ui-g-12">
			<div class="ui-g-4">
				<label for="VEN_NOMBRE">Nombre : </label>
			</div>
			<div class="ui-g-8">
				<input *ngIf="!autodialog" pInputText type="text" disabled="true" [(ngModel)]="auxnombrevendedor">
				<p-autoComplete *ngIf="autodialog" [(ngModel)]="auxnombrevendedor" [suggestions]="filteredNameVendedorSingle"
								(completeMethod)="filterNamevendedorsSingle($event)" id="VEN_NOMBRE" field="VEN_NOMBRE"
								[size]="30"
								placeholder="Nombre" (onSelect)="onSelectNameVen(auxnombrevendedor)"
								[minLength]="1" (click)="teclado('venNom')"></p-autoComplete>
			</div>
		</div>
		<div class="ui-g-12">
		</div>
		<div class="ui-g-12">
		</div>
		<div class="ui-g-12">
		</div>
	</div>
	<p-footer>
		<div class="ui-dialog-buttonpane ui-helper-clearfix">
			<button type="button" pButton icon="pi pi-replay" (click)="clean()" label="Limpiar"></button>
			<button type="button" pButton icon="fa fa-check" (click)="seleccionarVendedor()" label="Seleccionar"></button>
		</div>
	</p-footer>
</p-dialog>


<p-dialog header="Seleccionar Cliente" [(visible)]="displayDialog" [responsive]="true" [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '500px'}">
	<div class="ui-g ui-fluid">
		<div class="ui-g-12">
			<div class="ui-g-4">
				<label>Cedula / Ruc : </label>
			</div>
			<div class="ui-g-8">
				<input *ngIf="!autodialog" pInputText type="text" disabled="true" [(ngModel)]="auxcodigocliente">
				<p-autoComplete *ngIf="autodialog" [(ngModel)]="auxcodigocliente" [suggestions]="filteredClientesSingle"
								(completeMethod)="filterClienteSingle($event)" [size]="30"
								placeholder="Ruc / Cedula" (onSelect)="onSelectCliente(auxcodigocliente)"
								[minLength]="1" (keydown.enter)="onSelectClienteDialog(auxcodigocliente)"
								[dropdown]="true" (onDropdownClick)="filterClienteSingle($event)"
								(click)="teclado('cliCod')"></p-autoComplete>

			</div>
		</div>
		<div class="ui-g-12">
			<div class="ui-g-4">
				<label>Nombre : </label>
			</div>
			<div class="ui-g-8">
				<input *ngIf="!autodialog" pInputText type="text" disabled="true" [(ngModel)]="auxnombrecliente">
				<p-autoComplete *ngIf="autodialog" [(ngModel)]="auxnombrecliente" [suggestions]="filteredNameClientesSingle"
								(completeMethod)="filterNameClientesSingle($event)"
								[size]="30" placeholder="Nombre" (onSelect)="onSelectNameCli(auxnombrecliente)"
								[minLength]="1" [dropdown]="true"
								(onDropdownClick)="filterNameClientesSingle($event)"
								(click)="teclado('cliNom')"></p-autoComplete>
			</div>
		</div>
		<div class="ui-g-12">
		</div>
		<div class="ui-g-12">
		</div>
		<div class="ui-g-12">
		</div>
	</div>
	<p-footer>
		<div class="ui-dialog-buttonpanel ui-helper-clearfix">
			<button type="button" pButton icon="pi pi-pencil" (click)="modificar()" label="Modificar"></button>
			<button type="button" pButton icon="pi pi-replay" (click)="clean()" label="Limpiar"></button>
			<button type="button" pButton icon="fa fa-check" (click)="seleccionarCliente()" label="Seleccionar"></button>
		</div>
	</p-footer>
</p-dialog>


<p-dialog header="Nuevo Cliente" [(visible)]="displayDialogNewCli" [responsive]="true" [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '500px'}">
	<div class="ui-g ui-fluid">
		<div class="ui-g-12">
			<div class="ui-g-4">
				<label for="IDE">Tipo Identificación: </label>
			</div>
			<div class="ui-g-4">
				<button id="IDE" type="button" class="btn dropdown-toggle" data-toggle="dropdown"
						aria-haspopup="true" aria-expanded="false">{{ide}}</button>
				<div class="dropdown-menu">
					<label class="dropdown-item" (click)="opcion('R')">R - (Ruc)</label>
					<label class="dropdown-item" (click)="opcion('C')">C - (Cédula)</label>
					<label class="dropdown-item" (click)="opcion('P')">P - (Pasaporte)</label>
					<label class="dropdown-item" (click)="opcion('PP')">PP - (Placa)</label>
				</div>
			</div>
			<div class="ui-g-2">
				<button class="cliente" *ngIf="!(valorcedulaI || valorRucI)" type="button"
						style="background-image: url(../../../../assets/images/iconspdv/iconos/guardar.png);"
						(click)="save()"></button>
				<button class="cliente" *ngIf="valorcedulaI || valorRucI" [disabled]="valorcedulaI || valorRucI" type="button"
						style="background-image: url(../../../../assets/images/iconspdv/iconos/guardar-d.png);"
				></button>
			</div>
			<div class="ui-g-2">
				<button class="cliente" type="button" style="background-image: url(../../../../assets/images/iconspdv/iconos/buscar.png);"
						(click)="buscarcedula()"></button>

			</div>
		</div>
		<div class="ui-g-12">
			<div class="ui-g-4">
				<label>Cédula / Ruc : </label>
			</div>
			<div class="ui-g-8">
				<input disabled="{{codigobloqueado}}" (input)="validar()" pInputText type="text" [(ngModel)]="auxcodigocliente"
					   (click)="teclado('cedula')" (ngModelChange)="validar()">
				<p-message *ngIf="valorcedulaI === true" severity="error" text="El número de cédula no es correcto"></p-message>
				<p-message *ngIf="valorRucI === true" severity="error" text="El número de RUC no es correcto"></p-message>
			</div>
		</div>
		<div class="ui-g-12">
			<div class="ui-g-4">
				<label>Nombre : </label>
			</div>
			<div class="ui-g-8">
				<input pInputText type="text" [(ngModel)]="auxnombrecliente" (click)="teclado('nombreCli')">
			</div>
		</div>
		<div class="ui-g-12">
			<div class="ui-g-4">
				<label for="CLI_DIRECCION">Dirección : </label>
			</div>
			<div class="ui-g-8">
				<input pInputText type="text" id="CLI_DIRECCION" [(ngModel)]="auxdireccioncliente" (click)="teclado('dirCli')">
			</div>
		</div>
		<div class="ui-g-12">
			<div class="ui-g-4">
				<label for="CLI_TELEFONO">Telefono : </label>
			</div>
			<div class="ui-g-8">
				<input pInputText type="text" id="CLI_TELEFONO" [(ngModel)]="auxtelefonocliente" (click)="teclado('telCli')"
					   pKeyFilter="pint">
			</div>
		</div>
		<div class="ui-g-12">
			<div class="ui-g-4">
				<label for="CLI_CORREO">Email : </label>
			</div>
			<div class="ui-g-8">
				<input pInputText type="text" id="CLI_CORREO" [(ngModel)]="auxcorreocliente" (click)="teclado('mailCli')"
					   pKeyFilter="email">
			</div>
		</div>
	</div>
	<p-footer>
		<div class="ui-dialog-buttonpanel ui-helper-clearfix">
			<button type="button" pButton icon="fa fa-check" (click)="seleccionarCliente()" label="Seleccionar"></button>
		</div>
	</p-footer>
</p-dialog>

<p-dialog header="Anular Factura" [(visible)]="displayDialogRazonAnular" [responsive]="true" [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '500px'}">
	<div class="ui-g ui-fluid">
		<span>Razón:</span>
		<input pInputText type="text" [(ngModel)]="razonanular" (click)="teclado('razonanular')">
	</div>
	<p-footer>
		<div class="ui-dialog-buttonpanel ui-helper-clearfix">
			<button type="button" pButton icon="fa fa-check" (click)="anularfac()" label="Anular"></button>
		</div>
	</p-footer>
</p-dialog>
