import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {VenDetped} from '../veninterfaces/vendetped';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class PedpedidoService {
  
  public way: string;
  constructor(
    private http: HttpClient,
    private confIniciales: ConfInicialesService,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario
  ) {
  
    this.way = sessionStorage.getItem('way');
  }
  
  static getCommonHeader() {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS');
    headers.append('Access-Control-Allow-Headers', 'Content-Type, Access-Control-Allow-Headers, ' +
      'Authorization, X-Requested-With, Access-Control-Allow-Origin, Access-Control-Allow-Methods');
    return headers;
  }
  
  getMaePantalla(): Observable<any[]> {
   
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/w5hvlar4f0kvpey', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  // PROCESOS
  
  insertarpedido(venencped): Observable<any> {
  
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/cjwl1l3ulj28gel', {      
      elementos: {
        p_ENCPED_numero: venencped.ENCPED_NUMERO,
        p_ENCPED_numero_1: venencped.ENCPED_NUMERO,
        p_COM_codigo_2: '01',
        p_CLI_codigo_3: venencped.CLI_CODIGO,
        p_VEN_codigo_4: venencped.VEN_CODIGO,
        p_ENCPED_fechaemision_5: venencped.ENCPED_FECHAEMISION,
        p_ENCPED_fechaentrega_6: venencped.ENCPED_FECHAENTREGA,
        p_ENCPED_iva_7: venencped.ENCPED_IVA,
        p_ENCPED_estado_8: venencped.ENCPED_ESTADO,
        p_ENCPED_orden_10: '',
        p_ENCPED_lista_12: venencped.ENCPED_LISTA,
        p_ENCPED_observacion_13: '',
        p_ENCPED_total_14: venencped.ENCPED_TOTAL,
        p_ENCPED_totalneto_15: venencped.ENCPED_TOTALNETO,
        p_ENCPED_valoriva_18: venencped.ENCPED_VALORIVA,
        p_ENCPED_porceiva_19: venencped.ENCPED_PORCEIVA,
        p_ENCPED_valorice_20: venencped.ENCPED_VALORICE,
        p_ENCPED_porceice_21: venencped.ENCPED_PORCEICE,
        p_ENCPED_baseiva_22: venencped.ENCPED_BASEIVA,
        p_ENCPED_baseice_23: venencped.ENCPED_BASEICE,
        p_ENCPED_basecero_24: venencped.ENCPED_BASECERO,
        p_ENCPED_valordes: venencped.ENCPED_VALORDES,
        p_ENCPED_porcedes: venencped.ENCPED_PORCEDES,
        p_ENCPED_grupo_25: venencped.ENCPED_GRUPO,
        p_ENCPED_referencia: '',
        p_ENCPED_fechavalidez: venencped.ENCPED_FECHAEMISION,
        p_BOD_codigo: venencped.BOD_CODIGO,
        p_ENCPED_tipo: '',
        p_ENCPED_tipodscto: venencped.ENCPED_TIPODSCTO,
        p_ENCPED_refcli: venencped.ENCPED_REFCLI
      },
    }, this.confIniciales.httpOptions());
  }
  
  insertarDetalle(vendetped): Observable<any> {
    
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/uwu9azdhd6m7g9j', {      
      elementos: {
        p_DETPED_linea: vendetped.DETPED_LINEA,
        p_ENCPED_numero_1: vendetped.ENCPED_NUMERO,
        p_COM_codigo_2: '01',
        p_DETPED_tipodet_4: vendetped.DETPED_TIPODET,
        p_DETPED_codigo_5: vendetped.DETPED_CODIGO,
        p_DETPED_descripcion_6: vendetped.DETPED_DESCRIPCION,
        p_DETPED_tribiva_7: vendetped.DETPED_TRIBIVA,
        p_DETPED_tribice_8: vendetped.DETPED_TRIBICE,
        p_DETPED_unidad_9: vendetped.DETPED_UNIDAD,
        p_DETPED_cantidad_10: Number(vendetped.DETPED_CANTIDAD),
        p_DETPED_despacho_11: Number(vendetped.DETPED_DESPACHO),
        p_DETPED_precio_12: Number(vendetped.DETPED_PRECIO),
        p_DETPED_descuento_13: Number(vendetped.DETPED_DESCUENTO),
        p_DETPED_total_14: Number(vendetped.DETPED_TOTAL),
        p_DETPED_iva_15: Number(vendetped.DETPED_IVA),
        p_DETPED_ice_16: Number(vendetped.DETPED_ICE),
        p_DETPED_lista_17: vendetped.DETPED_LISTA,
        p_DETPED_baseiva_18: Number(vendetped.DETPED_BASEIVA),
        p_DETPED_baseice_19: Number(vendetped.DETPED_BASEICE),
        p_DETPED_basecero_20: Number(vendetped.DETPED_BASECERO),
        p_DETPED_porceice_21: Number(vendetped.DETPED_PORCEICE),
        p_DETPED_orden: Number(vendetped.DETPED_ORDEN),
        p_DETPED_numblo: vendetped.DETPED_NUMBLO,
        p_BOD_CODIGO: vendetped.BOD_CODIGO,
        p_DETPED_CODALTERNO: vendetped.DETPED_CODALTERNO,
        p_DETPED_PROMOCION: vendetped.DETPED_PROMOCION,
        p_MAEPROVTA_CODIGO: vendetped.MAEPROVTA_CODIGO,
        p_detped_cantidadund: 0,
        p_detped_lote: '',
        p_detped_caracteristicas: ''
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  getpedidos(): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/gakuacixq9901wk', {      
      elementos: {
        ENCPED_REFCLI: 'PE 8787'
      },
    }, this.confIniciales.httpOptions());
  }
  
  
   getpedidosAtrib(objeto, valu): Observable<any[]> {
    const header = PedpedidoService.getCommonHeader();
    if (objeto === 'ENCFAC_NUMERO') {
      
      return this.http.post<any[]>(this.way + '/ventas/pedpedido/6flzo6fl9vp3bpx', {      
        elementos: {
          ENCFAC_COMENTARIO: 'VICTOR',
          ENCFAC_NUMERO: '%' + valu + '%'
        },
      }, this.confIniciales.httpOptions());
    }
    
    if (objeto === 'ENCFAC_FECHAEMISION') {
     
      return this.http.post<any[]>(this.way + '/ventas/pedpedido/m00w2fanstk15zt', {      
        elementos: {
          ENCFAC_COMENTARIO: 'VICTOR',
          FORMATO: 'DD/MM/YYYY',
          ENCFAC_FECHAEMISION: valu
        },
      }, this.confIniciales.httpOptions());
    }
    
    if (objeto === 'CLI_CODIGO') {
    
      return this.http.post<any[]>(this.way + '/ventas/pedpedido/0fa2pezc882qkf1', {      
        elementos: {
          ENCFAC_COMENTARIO: 'VICTOR',
          CLI_CODIGO: '%' + valu + '%'
        },
      }, this.confIniciales.httpOptions());
    }
    
    if (objeto === 'CLI_NOMBRE') {
      
      return this.http.post<any[]>(this.way + '/ventas/pedpedido/2yhyzfzyp60l354', {      
        elementos: {
          ENCFAC_COMENTARIO: 'VICTOR',
          CLI_NOMBRE: '%' + valu + '%'
        },
      }, this.confIniciales.httpOptions());
    }
  }
   
  getDetallepedido(encpednumero): Observable<any[]> {
    
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/mk7h771gj4kowa4', {      
      elementos: {
        ENCPED_NUMERO: encpednumero
      },
    }, this.confIniciales.httpOptions());
  }
  
  borrarTodoDetalle(encfacnum): Observable<any> {
   
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/8x9m4ed398inbcm', {      
      elementos: {
        ENCFAC_NUMERO: encfacnum
      },
    }, this.confIniciales.httpOptions());
  }
  
   eliminarDetPed(detPed: VenDetped): Observable<any[]> {
   
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/62984kwm1zgd544', {      
      elementos: {
        p_ENCPED_numero_1: detPed.ENCPED_NUMERO,
        p_COM_codigo_2: '01',
        p_DETPED_linea_3: detPed.DETPED_LINEA
      },
    }, this.confIniciales.httpOptions());
  }
   
  eliminarEncPed(encPed): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/98ib4i7otphspw5', {      
      elementos: {
        p_ENCPED_numero_1: encPed,
        p_COM_codigo_2: '01',
      },
    }, this.confIniciales.httpOptions());
  }
  
   actualizarkardex(pedido): Observable<any> {
 
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/ot1dyfgrla83o8e', {      
      elementos: {
        p_encfac_numero: pedido,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions());
  } 
  
   getTrnDocumento(trndocumentonrodoc): Observable<any[]> {
    
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/40ujyclgl387neo', {      
      elementos: {
        TRNDOCUMENTO_NRODOC: trndocumentonrodoc
      },
    }, this.confIniciales.httpOptions());
  } 
  
  
  getTrnCobro(trncobronrodoc): Observable<any[]> {
  
    
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/ioeo52yalscwllr', {      
      elementos: {
        TRNCOBRO_NRODOC: trncobronrodoc
      },
    }, this.confIniciales.httpOptions());
  } 
  
   getTrnRetencion(trncobronrodoc): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/mc0pjtglrl0j9g0', {      
      elementos: {
        TRNCOBRO_NRODOC: trncobronrodoc
      },
    }, this.confIniciales.httpOptions());
  }
   
   eliminarDetallepedido(pedido, linea): Observable<any> {
 
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/61st8dtsr2i5aow', {      
      elementos: {
        p_ENCFAC_numero_1: pedido,
        p_DETFAC_linea_2: linea,
        p_COM_codigo_3: '01'
      },
    }, this.confIniciales.httpOptions());
  } 
  
  
  getMaeCliente(): Observable<any[]> {
    
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/gmyy3blnmf4cjbg', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  getClienteCodigo(cliCod): Observable<any[]> {
    
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/wkrc0bjbbskerfr', {      
      elementos: {
        COM_CODIGO: '01',
        CLI_CODIGO: cliCod,
      },
    }, this.confIniciales.httpOptions());
  }
  
  
   getMaeVendedor(): Observable<any[]> {
 
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/apvxaukonntndin', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
   
  getVendedorCodigo(venCod): Observable<any[]> {
    
   
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/dslgh2pxdk0j79y', {      
      elementos: {
        VEN_CODIGO: venCod,
      },
    }, this.confIniciales.httpOptions());
  }
  
  getUsuario(): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/ventas/pedpedido/wh922pvgy77t0qn', {      
      elementos: {
        USUIDENTIFICACION: this.usuario.identificacion
      },
    }, this.confIniciales.httpOptions());
  }
  
  getClaveVendedor(clave): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/ventas/pedpedido/7uwevtvd3bq2d89', {      
      elementos: {
        VEN_CLAVE: clave
      },
    }, this.confIniciales.httpOptions());
  }
  
  getAutorizacion(encfacnumero): Observable<any[]> {
   
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/nv2xbt67k37rqa8', {      
      elementos: {
        ENCFAC_NUMERO: encfacnumero
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  recogerProducto(articulo): Observable<any[]> {
   
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/j1sqamiakl07xqk', {      
      elementos: {
        ART_CODIGO: articulo,
        ARTPRE_CODIGO: 'A'
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  recogerArticulos(): Observable<any[]> {
 
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/pnjw4oladl0u5w4', {      
      elementos: {
        ARTPRE_CODIGO: 'A'
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  recogerArticulosFilter(): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/0f1potrhgzxjf4u', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  getBancosDP(): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/jp1qyeo5tzycij9', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  getBancosCH(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/rk4xsesx6m7uuj0', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  getBancosTA(): Observable<any[]> {
 
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/hh07v6fruy0tezr', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  getFormaPago(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/lyq82ugdxid9ghk', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  getCodsri(tipo): Observable<any[]> {
 
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/zkqxj0mq3w2e30p', {      
      elementos: {
        CODSRI_TIPO: tipo
      },
    }, this.confIniciales.httpOptions());
  }
  
  getRetenciones(): Observable<any[]> {
 
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/9779bnyfsat6u84', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  
  retornarAsiento(pedido): Observable<any[]> {
   
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/vu9b0qifuainvu8', {      
      elementos: {
        ENCFAC_NUMERO: pedido
      },
    }, this.confIniciales.httpOptions());
  }
  
  insertarTrnCobro(cxctrncobro): Observable<any> {
    
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/n9jgxmoyu4k2lq7', {      
      elementos: {
        p_TRNCOBRO_nroDoc: cxctrncobro.TRNCOBRO_NRODOC,
        p_TRNCOBRO_nroComprobante: cxctrncobro.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_tipoDoc: cxctrncobro.TRNCOBRO_TIPODOC,
        p_TRNCOBRO_fechaTrn: cxctrncobro.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_concepto: cxctrncobro.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_importe: cxctrncobro.TRNCOBRO_IMPORTE,
        p_TRNCOBRO_fechavence: cxctrncobro.TRNCOBRO_FECHAVENCE,
        p_APLORG_codigo: cxctrncobro.APLORG_CODIGO,
        p_TRNCOBRO_referenciaExterna: cxctrncobro.TRNCOBRO_REFERENCIAEXTERNA,
        p_CLI_codigo: cxctrncobro.CLI_CODIGO,
        p_VEN_codigo: cxctrncobro.VEN_CODIGO,
        p_COM_codigo: cxctrncobro.COM_CODIGO,
        p_ASI_NRO: cxctrncobro.ASI_NRO,
        p_USU_IDENTIFICACION: cxctrncobro.USU_IDENTIFICACION,
        p_TRNCOBRO_FLAG: cxctrncobro.TRNCOBRO_FLAG,
        p_BAN_CODIGO: cxctrncobro.BAN_CODIGO,
        p_TRNCOBRO_EFECTIVO: cxctrncobro.TRNCOBRO_EFECTIVO,
        p_TRNCOBRO_CAMBIO: cxctrncobro.TRNCOBRO_CAMBIO,
        p_CON_CODIGO: cxctrncobro.CON_CODIGO,
        p_TRNCOBRO_SALDOINI: cxctrncobro.TRNCOBRO_SALDOINI,
        p_BOD_CODIGO: cxctrncobro.BOD_CODIGO,
      },
    }, this.confIniciales.httpOptions());
  }
  
  insertarTrnDocumento(cxctrncobro): Observable<any> {
     
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/jqpbolqs2hzuhl9', {      
      elementos: {
        p_TRNCOBRO_nroDoc_1: cxctrncobro.TRNCOBRO_NRODOC,
        p_TRNDOCUMENTO_nroDoc_2: cxctrncobro.TRNCOBRO_NRODOC_2,
        p_TRNDOCUMENTO_importe_3: cxctrncobro.TRNCOBRO_IMPORTE,
        p_COM_codigo_4: cxctrncobro.COM_CODIGO,
        p_CLI_codigo_5: cxctrncobro.CLI_CODIGO,
        p_TRNDOCUMENTO_REF: cxctrncobro.CUOTAFAC,
        p_TRNCOBRO_NROCOMPROBANTE: cxctrncobro.TRNCOBRO_NROCOMPROBANTE
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  insertarTrnRetencion(sacitrnretencion): Observable<any> {
    
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/rnccq67cfe7aq55', {      
      elementos: {
        p_retencion_codigo_1: sacitrnretencion.RETENCION_CODIGO,
        p_trnretencion_descripcion_4: sacitrnretencion.TRNRETENCION_DESCRIPCION,
        p_trnretencion_nro_1: sacitrnretencion.TRNRETENCION_NRO,
        p_trnretencion_base_5: sacitrnretencion.TRNRETENCION_BASE,
        p_trnretencion_porcentaje_6: sacitrnretencion.TRNRETENCION_PORCENTAJE,
        p_trnretencion_totalretenido_7: sacitrnretencion.TRNRETENCION_TOTALRETENIDO,
        p_trncobro_nrodoc_8: sacitrnretencion.TRNCOBRO_NRODOC,
        p_com_codigo_3: sacitrnretencion.COM_CODIGO,
        p_trnretencion_origen: sacitrnretencion.TRNRETENCION_ORIGEN,
        p_con_codigo: sacitrnretencion.CON_CODIGO,
        p_cen_codigo: sacitrnretencion.CEN_CODIGO,
        p_cli_codigo: sacitrnretencion.CLI_CODIGO,
        p_ENCFAC_NUMERO: sacitrnretencion.TRNRETENCION_REFERENCIA,
        p_TRNCOBRO_NROCOMPROBANTE: sacitrnretencion.TRNCOBRO_NROCOMPROBANTE,
      },
    }, this.confIniciales.httpOptions());
  }
  
  insertarFormaPago(cxctrnpago): Observable<any> {
    
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/3wv735c585zla5m', {      
      elementos: {
        p_TRNPAGO_tipo_2: cxctrnpago.TRNPAGO_TIPO,
        p_CAJ_codigo_3: cxctrnpago.CAJ_CODIGO,
        p_TRNPAGO_numero_4: cxctrnpago.TRNPAGO_NUMERO,
        p_TRNPAGO_fecha_5: cxctrnpago.TRNPAGO_FECHA,
        p_BANCLI_CODIGO: cxctrnpago.BANCLI_CODIGO,
        p_TRNPAGO_NROCTA: cxctrnpago.TRNPAGO_NROCTA,
        p_TRNPAGO_concepto_6: cxctrnpago.TRNPAGO_CONCEPTO,
        p_TRNPAGO_importe_7: cxctrnpago.TRNPAGO_IMPORTE,
        p_TRNPAGO_numero_anterior: cxctrnpago.TRNPAGO_NUMEROANT,
        p_CON_codigo_9: cxctrnpago.CON_CODIGO,
        p_TRNCOBRO_nroDoc_1: cxctrnpago.TRNCOBRO_NRODOC,
        p_COM_codigo_8: cxctrnpago.COM_CODIGO,
        p_TRNCOBRO_nroComprobante: cxctrnpago.TRNCOBRO_NROCOMPROBANTE,
        p_CLI_CODIGO: cxctrnpago.CLI_CODIGO,
        p_TRNPAGO_CODSRI: cxctrnpago.TRNPAGO_CODSRI,
        p_TRNCHEPOS_NUMDOC: cxctrnpago.TRNCHEPOS_NUMDOC,
        p_TRNPAGO_TIPDIS: cxctrnpago.TRNPAGO_TIPDIS
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  generarAsientoContable(encfacnumero, trncobronrodoc): Observable<any> {
  
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/53bm7jrvj1a5um7', {      
      elementos: {
        p_ENCFAC_numero: encfacnumero,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'CI',
        p_trncobro_nrodoc: trncobronrodoc
      },
    }, this.confIniciales.httpOptions());
  }
  
  generarAsientoContableCredito(encfacnumero): Observable<any> {
  
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/7rlz8pn7rjt6i7m', {      
      elementos: {
        p_ENCFAC_numero: encfacnumero,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'AF',
        p_trncobro_nrodoc: encfacnumero
      },
    }, this.confIniciales.httpOptions());
  }
  
  actualizarFormaPago(strNumFact, strTipoFP): Observable<any[]> {
    
  
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/654lorw2a3wf69e', {      
      elementos: {
        ENCFAC_FORMAPAGO: strTipoFP,
        ENCFAC_NUMERO: strNumFact,
        COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  actualizarEstado(strNumFact, estado): Observable<any[]> {
   
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/mfamviqq73omzsg', {      
      elementos: {
        ENCFAC_ESTADO: estado,
        ENCFAC_NUMERO: strNumFact,
        COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  limpiarAsiento(strNumFact, estado): Observable<any[]> {
 
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/4mx1dkx6icflcw1', {      
      elementos: {
        ASI_NRO: estado,
        ENCFAC_NUMERO: strNumFact,
        COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  actualizarTrnCobro(cxctrncobro): Observable<any> {
  
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/x7mf5r8qgcavemn', {      
      elementos: {
        p_TRNCOBRO_nroDoc: null,
        p_TRNCOBRO_nroComprobante: null,
        p_TRNCOBRO_tipoDoc: null,
        p_TRNCOBRO_fechaTrn: null,
        p_TRNCOBRO_concepto: null,
        p_TRNCOBRO_importe: null,
        p_TRNCOBRO_fechavence: null,
        p_APLORG_codigo: null,
        p_TRNCOBRO_referenciaExterna: null,
        p_CLI_codigo: null,
        p_VEN_codigo: null,
        p_COM_codigo: null,
        p_ASI_NRO: null,
        p_USU_IDENTIFICACION: null,
        p_TRNCOBRO_FLAG: null,
        p_BAN_CODIGO: null,
        p_TRNCOBRO_EFECTIVO: null,
        p_TRNCOBRO_CAMBIO: null,
        p_CON_CODIGO: null,
        p_TRNCOBRO_SALDOINI: null,
        p_BOD_CODIGO: null
      },
    }, this.confIniciales.httpOptions());
  }
  
  eliminarTrnCobro(trncobronrodoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/23sqy0dpdn8t6n3', {      
      elementos: {
        TRNCOBRO_NRODOC: trncobronrodoc
      },
    }, this.confIniciales.httpOptions());
  }
  
  eliminarDetasi(pedido): Observable<any[]> {
 
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/t9umev6s03edhg6', {      
      elementos: {
        DETASI_REFERENCIA: pedido
      },
    }, this.confIniciales.httpOptions());
  }
  
  eliminarEncasi(pedido): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/yssukfluss6pxyk', {      
      elementos: {
        ASI_COMPROBANTE: pedido
      },
    }, this.confIniciales.httpOptions());
  }
  

  
 
  
  getPrecioArticulo(prod, opcion): Observable<any[]> {
    const header = PedpedidoService.getCommonHeader();
    if (opcion === 0) {
    
      return this.http.post<any[]>(this.way + '/ventas/pedpedido/j9i6cgp5zpigvsa', {      
        elementos: {
          ART_CODIGO: prod.ART_CODIGO,
          COM_CODIGO: '01',
          ARTPRE_CODIGO: 'A',
          UNI_CODIGO: prod.ART_UNIDADVENTA
        },
      }, this.confIniciales.httpOptions());
    }
    if (opcion === 1) {
    
      return this.http.post<any[]>(this.way + '/ventas/pedpedido/k5htynfy05sfx02', {      
        elementos: {
          ART_CODIGO: prod.ART_CODIGO,
          COM_CODIGO: '01',
          ARTPRE_CODIGO: 'A',
          UNI_CODIGO: prod.ART_UNIDADVENTA
        },
      }, this.confIniciales.httpOptions());
    }
  }
  
  
  getCedula(ced): Observable<any[]> {
    const header = PedpedidoService.getCommonHeader();
    return this.http.post<any[]>(this.way + '/getcedula', {
      cedula: ced
    }, {
      headers: header
    });
  }
  
  anularAsiento(asinumero): Observable<any> {
  
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/0141kuzamhz2nps', {      
      elementos: {
        p_ASI_nro: asinumero,
        p_COM_codigo: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  anularpedido(strNumFact, razon): Observable<any[]> {
   
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/9figqk5ofg3qa9p', {      
      elementos: {
        ENCFAC_ESTADO: 'A',
        ENCFAC_OBSERVACION: razon,
        ENCFAC_NUMERO: strNumFact,
        COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  obtenerPeriodoContable(): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/o2757emj8wosk4f', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  
  insertarCliente(ruc, gru, ven, nombre, tipo, direccion, telefono, correo, fecha, cuenta, cuenta2): Observable<any[]> {
    console.log(fecha);
    let clavetipo = '';
    switch (tipo) {
      case 'C':
        clavetipo = '2';
        break;
      case 'R':
        clavetipo = '1';
        break;
      case 'P':
        clavetipo = '3';
        break;
      case 'PP':
        clavetipo = '4';
        break;
      default:
        clavetipo = '6';
        break;
    }
    
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/yikjrpzv0y6j2le', {      
      elementos: {
        CLI_CODIGO: ruc,
        GRU_CODIGO: gru,
        VEN_CODIGO: ven,
        CLI_NOMBRE: nombre,
        CLI_NOMBREC: nombre.substr(0, 10),
        CLI_TIPOIDE: clavetipo,
        CLI_RUCIDE: ruc,
        CLI_DIRECCION1: direccion,
        CLI_DIRECCION2: '',
        CLI_CODPOSTAL: '',
        CLI_TELEFONO1: telefono,
        CLI_TELEFONO2: '',
        CLI_FAX: '',
        CLI_CORREO: correo,
        CLI_CONTACTO: '',
        CLI_FECING: fecha,
        CLI_LIMCREDIT: '0',
        CLI_DIACREDIT: '0',
        CLI_DESCUENTO: '0',
        CLI_IVA: '1',
        CLI_CONTRIBUYENTE: 'N',
        CLI_LISTA: 'A',
        CON_CODIGO1: cuenta,
        CON_CODIGO2: cuenta2,
        CLI_ZONA: '010',
        CLI_TIPO: '',
        CLI_PROVINCIA: '',
        CIU_CODIGO: '',
        CLI_ESTADO: 'A',
        CLI_PORCEDESCUENTO: '0',
        CLI_FECHANACIMIENTO: fecha,
        CLI_OBSERVACION: '',
        COM_CODIGO: '01',
        gru_tipo: 'CLI'
      },
    }, this.confIniciales.httpOptions());
  }
  
  actualizarCliente(ruc, nombre, direccion, telefono, correo): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/n7w5bdubg5uugmr', {      
      elementos: {
        CLI_NOMBRE: nombre,
        CLI_NOMBREC: nombre.substr(0, 10),
        CLI_DIRECCION1: direccion,
        CLI_TELEFONO1: telefono,
        CLI_CORREO: correo,
        CLI_CODIGO: ruc,
      },
    }, this.confIniciales.httpOptions());
  }
  
  datosultimocli(): Observable<any[]> {
 
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/f95ekb838key10w', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  
  ejecutarVenSppedido(numeropedido): Observable<any> {
  
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/99d0vo8h73n9odv', {      
      elementos: {
        p_ENCFAC_NUMERO: numeropedido,
        p_COM_codigo: '01',
        p_parametro_ide: '123'
      },
    }, this.confIniciales.httpOptions());
  }
  
  obtenerDatospedido(): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/pedpedido/ktrft1vdj3xo27f', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
}

