import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { NommaeEmpleado } from '../nominterfaces/nomempleado';
import { NommaedatosadiEmp } from '../nominterfaces/nonmaedatosadi';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import { NommatdatadiService } from '../nomservicios/nommatdatadi.service';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { MessageService, ConfirmationService, FileUpload } from 'primeng';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { DatePipe } from '@angular/common';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { NommaedataglobService } from '../nomservicios/nommaedataglob.service';
import { DomSanitizer,SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-nommaeddataglob',
  templateUrl: './nommaeddataglob.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NommaeddataglobComponent implements OnInit {

  @Input() datadiempl:NommaeEmpleado;
  @Output() salirclo= new EventEmitter<string>();
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  @ViewChild(FileUpload) pupload: FileUpload;
   valor:any;
  imgBlob:any;
  doc:any[]=[];
  imgbool:boolean;
  pdfbool:boolean;
  inputcodigo:string="";
  inputanomape:string="";
 
  btnRegresar: boolean;
  displaydatosAdiglob: boolean;
  displaydatosImaDia:boolean;

  ima_pdf:any;
  files:File[];
  cargando:boolean;
  largoAdi='450';
  rowStyle;
  rowSelection = 'multiple';
  nommaeDatAdi:NommaedatosadiEmp[]=[];
  defaultColDefMaeDatAdi;
  public frameworkComponents;
  selectedNomDatAdi:NommaedatosadiEmp;
  columnDefMaeDatAdi=[
    {
      headerName: '', field: 'NUMERO', cellEditor: 'cellPrueba', editable: true, width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
           
          }
        }
    },
 
    {
      headerName: 'Etiqueta', editable: false, field: 'DATADI_ETIQUETA', cellEditor: 'cellPrueba',onCellClicked: (params) => {
        this.seleccionIma(params.data);  
      }, width: 150,  
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: false,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: false,
            
          }
        }
    },
    {
      headerName: 'Dato',  field: 'ESTADO', cellEditor: 'cellPrueba',onCellClicked: (params) => {
        this.seleccionItem(params.data);  
      }, width: 150,
      
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: false,
            int:true
          }
        }
        
    }
  ];
  constructor(private init: NuevoComponentService,
    private nommatdatadiService: NommatdatadiService,
    private datePipe: DatePipe,
    private domsanitazer:DomSanitizer,
    private errorService: ErroresBaseDatosService,
    private messageService: MessageService,
    private auditoriaGralService: AuditoriagralService,
    private confirmationService: ConfirmationService,
    private nommaedataglobService:NommaedataglobService) {
      this.displaydatosAdiglob=false;
      this.displaydatosImaDia=false;
      this.cargando=false;
      this.imgbool=false;
      this.pdfbool=false;
      this.btnRegresar = false;
      this.agTable();
     }

  ngOnInit(): void {
    this.inputcodigo=this.datadiempl.EMP_CODIGO
    this.inputanomape=this.datadiempl.EMP_APELLIDO+" "+this.datadiempl.EMP_NOMBRE;
    this.nommaedataglobService.selectDatosadimatglob().then(res=>{
      let campos:string="";
      this.nommaeDatAdi=res;
     
      for(let i=0; i<this.nommaeDatAdi.length;i++){
        
        if(i==this.nommaeDatAdi.length-1){
          campos=campos+this.nommaeDatAdi[i].DATADI_NOMBRECAMPO+" ";
        }else{
          campos=campos+this.nommaeDatAdi[i].DATADI_NOMBRECAMPO+", ";
        }
      }
      this.nommaedataglobService.ejecutarSenten(campos,this.datadiempl.EMP_CODIGO).then(res=>{
       
        this.nommaeDatAdi.map(x=>{
          x.NUMERO= this.nommaeDatAdi.indexOf( x ) + 1;
          x.NUEVO=false;
          x.VALOR=res[0][x.DATADI_NOMBRECAMPO];
          x.ESTADO=(res[0][x.DATADI_NOMBRECAMPO]!=null)?'lleno':'vacio';
        });
        
        this.cargando=true;
      
      })
      
    })
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefMaeDatAdi = {
      editable: true,
      width: 130,
      objeto: 'nomMaeRubros'
    };
  }
  manejarSenalesAdi(valor){
    switch (valor) {
      case 'Nuevo':
        
        break;
      case 'Guardar':
        this.opcionGuardar();
        break;
      case 'Borrar':
       
        break;
      case 'Cancelar':
        this.cargar();
        break;
      case 'Buscar':
       
        break;
      case 'Salir':
        this.salirclo.emit('salir');
        break;
    
    }
  }
  cargar(){
    this.nommaedataglobService.selectDatosadimatglob().then(res=>{
      let campos:string="";
      this.nommaeDatAdi=res;
     
      for(let i=0; i<this.nommaeDatAdi.length;i++){
        
        if(i==this.nommaeDatAdi.length-1){
          campos=campos+this.nommaeDatAdi[i].DATADI_NOMBRECAMPO+" ";
        }else{
          campos=campos+this.nommaeDatAdi[i].DATADI_NOMBRECAMPO+", ";
        }
      }
      this.nommaedataglobService.ejecutarSenten(campos,this.datadiempl.EMP_CODIGO).then(res=>{
        this.nommaeDatAdi.map(x=>{
          x.NUMERO= this.nommaeDatAdi.indexOf( x ) + 1;
          x.NUEVO=false;
          x.VALOR=res[0][x.DATADI_NOMBRECAMPO];
          x.ESTADO=(res[0][x.DATADI_NOMBRECAMPO]!=null)?'lleno':'vacio';
        });
        this.aggrid.refreshaggrid(this.nommaeDatAdi, this.defaultColDefMaeDatAdi.objeto );
        this.aggrid.refreshaggrid(this.nommaeDatAdi, this.defaultColDefMaeDatAdi.objeto );
        this.aggrid.gridApi.setFocusedCell(0 , 'NUMERO');
      
      }) 
    })
  }
  opcionGuardar(){

  }
  seleccionItem(data){
    this.displaydatosAdiglob=true;
    this.pupload.clear();
    this.pupload._files=this.selectedNomDatAdi.files;
  }
  
  cambioDatAdi($event){
    
    this.btnRegresar = false;
  
  }
  selectMaeDatAdi(elemento){
    if (elemento.object !== null) {
      this.selectedNomDatAdi=elemento.object;
    }
  }
  onHideDatAdiDoc(){
    this.displaydatosImaDia=false;
  }
  async onBasicUpload(event){
    this.valor=await this.readfile2(event);
    
  }
  cargara(){
    this.displaydatosAdiglob=false;
    this.selectedNomDatAdi.VALOR=this.valor;
    this.selectedNomDatAdi.files=this.files;
     this.nommaedataglobService.updateDonAdi(this.valor,this.selectedNomDatAdi.DATADI_NOMBRECAMPO,this.datadiempl.EMP_CODIGO).then(()=>{
      this.mensajeAlerta('success', 'Información', '¡Información Agregada!');
      this.cargar();
    }).catch(err=>{  let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    });
     
  
  }


  
  readfile(event){
    return new Promise((res,rej)=>{
      let inp=event;
      let reader =new FileReader();
      reader.onload= function(e){
        let blb= new Blob([reader.result],{type:inp.files[0].type})
        res(blb);
      };
      reader.readAsArrayBuffer(inp.files[0])
    })
  }
  readfile2(event){
    return new Promise((res,rej)=>{
      let inp=event;
      let reader =new FileReader();
      reader.onload= function(e){
        res(reader.result);
      };
      reader.readAsDataURL(inp.files[0]);
    
    });

  }
  seleccionIma(elemento){
    
    if (elemento != null) {
      this.selectedNomDatAdi=elemento;
    }else{
      this.mensajeAlerta('info', 'Información', '¡Este campo no contiene datos!');
      return;
    }
    if(this.selectedNomDatAdi.VALOR==null){
      this.mensajeAlerta('info', 'Información', '¡Este campo no contiene datos!');
      return;
    }
    this.imgbool=this.selectedNomDatAdi.VALOR.includes('data:image/');
    this.pdfbool=this.selectedNomDatAdi.VALOR.includes('data:application/pdf');
    this.ima_pdf="";
    if(this.imgbool==true){
      this.pdfbool==false;
      this.ima_pdf=this.selectedNomDatAdi.VALOR;
      this.displaydatosImaDia=true;
      
    }
    if(this.pdfbool==true){
      this.imgbool==false;
      this.ima_pdf=this.domsanitazer.bypassSecurityTrustResourceUrl(this.selectedNomDatAdi.VALOR);
      this.displaydatosImaDia=true;
    }
  }
  mensajeAlerta(tipo, titulo, msg) {
    this. messageService.add({
      key: 'docimgToast',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
  opcionEliminar(){
    if ( this.selectedNomDatAdi !== undefined ) {
      this.confirmationService.confirm({
        message: '¿Está seguro que desea eliminar?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'eliminardatDialog',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {
          this.nommaedataglobService.updateDonAdi(null,this.selectedNomDatAdi.DATADI_NOMBRECAMPO,this.datadiempl.EMP_CODIGO).then(()=>{
            
            this.cargar();
            this.displaydatosImaDia=false;
            this.mensajeAlerta('success', 'Información', '¡Información Eliminada!');
           ;
          }).catch(err=>{  let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('error', 'Error', mensaje);
          });
        },
        reject: () => {
        }
      })
    }
  }
  opcionActualizar(){
    this.displaydatosImaDia=false;
    this.displaydatosAdiglob=true;
  }
}
