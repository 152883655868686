import {Injectable} from '@angular/core';
import {CxcTrnpago} from '../../interfaces/cxctrnpago';
import {SaciTrnretencion} from '../../interfaces/sacitrnretencion';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Observable} from 'rxjs';
import {Cxctrnresumen} from '../veninterfaces/cxctrnresumen';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class PagocontadodvfService {
  get resumen(): Cxctrnresumen[] {
    return this._resumen;
  }
  
  set resumen(value: Cxctrnresumen[]) {
    this._resumen = value;
  }
  
  public url: string;
  private _pagos: CxcTrnpago[];
  private _reten: SaciTrnretencion[];
  private _resumen: Cxctrnresumen[];
  private _auxsalto: boolean;
  public CAJXVEN: number;
  public way: string;
  
  constructor(private http: HttpClient,
              private confIniciales: ConfInicialesService,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');

    this.usuario.loadStorage();
  }
  
  get pagos(): CxcTrnpago[] {
    return this._pagos;
  }
  
  set pagos(value: CxcTrnpago[]) {
    this._pagos = value;
  }
  
  get reten(): SaciTrnretencion[] {
    return this._reten;
  }
  
  set reten(value: SaciTrnretencion[]) {
    this._reten = value;
  }
  
  get auxsalto(): boolean {
    return this._auxsalto;
  }
  
  set auxsalto(value: boolean) {
    this._auxsalto = value;
  }
  
  /*
  get CAJXVEN(): number {
    return this._CAJXVEN;
  }
  
  set CAJXVEN(value: number) {
    this._CAJXVEN = value;
  }*/
  consultarFacturasPendientes(docRef, cliCod): Observable<any[]> {
   
    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/5g648ar89tz7si', {      
      elementos: {
        NUMDOC_REF: docRef,
        CLI_CODIGO: cliCod
      },
    }, this.confIniciales.httpOptions());
  }
  
  getAsiento(codigo: string): Observable<any> {
    
    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/dkxnb9ekhdr0mf7', {      
      elementos: {
        ENCDVF_NUMERO: codigo
      },
    }, this.confIniciales.httpOptions());
  }
  
  insertarCaja(caja, nroDoc): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/7h9y6064kflk14t', {      
      elementos: {
        p_CAJ_codigo_1: caja.CAJ_CODIGO,
        p_TRNCAJ_fecha_2: caja.TRNPAGO_FECHA,
        p_TRNCAJ_tipo_3: caja.TRNPAGO_TIPO,
        p_TRNCAJ_numero_4: caja.TRNPAGO_NUMERO,
        p_TRNCAJ_formapago_5: caja.TRNPAGO_TIPO,
        p_TRNCAJ_docnro_6: nroDoc,
        p_TRNCAJ_importe_7: Number(caja.TRNPAGO_IMPORTE) * -1,
        p_TRNCAJ_descripcion_8: caja.TRNPAGO_CONCEPTO,
        p_TRNCAJ_anulado: 0,
        p_TRNCAJ_fechavenci_9: '',
        p_TRNCAJ_origen_10: 'FAC',
        p_COM_codigo_11: '01',
        p_BANCLI_codigo_12: '',
        p_USU_IDENTIFICACION: this.usuario.identificacion,
        p_CLI_codigo: caja.CLI_CODIGO,
        p_cen_codigo: '',
      },
    }, this.confIniciales.httpOptions());
  }
  
  insertarBanco(banco, nroDoc, nomCli): Observable<any[]> {
 
    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/kgi3lv1gp7najdr', {      
      elementos: {
        p_BAN_codigo_1: banco.BANCLI_CODIGO,
        p_TRNBAN_fecha_2: banco.TRNPAGO_FECHA,
        p_TRNBAN_tipo_3: banco.TRNPAGO_TIPO,
        p_TRNBAN_numero_4: banco.TRNPAGO_NUMERO,
        p_TRNBAN_docnro_5: nroDoc,
        p_TRNBAN_importe_6: Number(banco.TRNPAGO_IMPORTE * -1),
        p_TRNBAN_concepto_7: banco.TRNPAGO_CONCEPTO,
        p_TRNBAN_beneficiario_9: nomCli,
        p_TRNBAN_anulado_10: '0',
        p_CON_codigo_11: banco.CON_CODIGO,
        p_COM_codigo_12: '01',
        p_TRNBAN_origen_13: 'VEN',
        p_TRNBAN_NUMEROREFUSER: '',
        p_TRNBAN_FECHAVENCE: banco.TRNPAGO_FECHA,
        p_ENCREQ_NUMERO: '',
        P_PRO_CODIGO: '',
        p_TRNBAN_PAGOELEC: ''
      },
    }, this.confIniciales.httpOptions());
  }
  
  actualizaUltimoCheque(banCodigo, numeroCheque): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/j9f21b9u8jr1p6k', {      
      elementos: {
        BAN_ULTIMOCHEQUE: numeroCheque,
        BAN_CODIGO: banCodigo
      },
    }, this.confIniciales.httpOptions());
  }
  
  consultarFacturasUtilizadas(numDev): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/flxzqc4tvcpbea8', {      
      elementos: {
        TRNCOBRO_REFERENCIAEXTERNA: numDev
      },
    }, this.confIniciales.httpOptions());
  }
  
  consultarFacturasInformacion(numFac): Observable<any[]> {
 
    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/sgu745abxuvqlzv', {      
      elementos: {
        TRNCOBRO_NRODOC: numFac
      },
    }, this.confIniciales.httpOptions());
  }
  
  consultarPagosEfectivo(numDev): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/pzi4olo66resq40', {      
      elementos: {
        TRNCAJ_DOCNRO: numDev
      },
    }, this.confIniciales.httpOptions());
  }
  
  consultarPagosCheque(numDev): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/z58ta5shqz2o65s', {      
      elementos: {
        TRNBAN_DOCNRO: numDev
      },
    }, this.confIniciales.httpOptions());
  }
  
  consultarPagosAnticipo(numDev): Observable<any[]> {
 
    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/6edr6sv2zvby4pb', {      
      elementos: {
        TRNCOBRO_REFERENCIAEXTERNA: numDev
      },
    }, this.confIniciales.httpOptions());
  }
  erreversarpago(strTRNPAGO_NUMERO,strBANCLI_CODIGO,strTRNPAGO_TIPO){
    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/cbca6o96l3sfc7v', {      
      elementos: {
        TRNPAGO_NUMERO:strTRNPAGO_NUMERO,
        BANCLI_CODIGO:strBANCLI_CODIGO,
        TRNPAGO_TIPO: strTRNPAGO_TIPO
      },
    }, this.confIniciales.httpOptions());
  }
 
  consultarTrnCobro(strOpcion, strCondicion, strFiltro): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/si0la2cv9e69yds', {      
      elementos: {
        p_opcion: strOpcion,
        p_filtro: strFiltro,
        p_condicion: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }
  
  insertarTrnCobro(trncobro): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/tfmunwv69vjvo28', {      
      elementos: {
        p_TRNCOBRO_nroDoc: trncobro.TRNCOBRO_NRODOC,
        p_TRNCOBRO_nroComprobante: trncobro.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_tipoDoc: trncobro.TRNCOBRO_TIPODOC,
        p_TRNCOBRO_fechaTrn: trncobro.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_concepto: trncobro.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_importe: trncobro.TRNCOBRO_IMPORTE,
        p_TRNCOBRO_fechavence: trncobro.TRNCOBRO_FECHAVENCE,
        p_APLORG_codigo: trncobro.APLORG_CODIGO,
        p_TRNCOBRO_referenciaExterna: trncobro.TRNCOBRO_REFERENCIAEXTERNA,
        p_CLI_codigo: trncobro.CLI_CODIGO,
        p_VEN_codigo: trncobro.VEN_CODIGO,
        p_COM_codigo: trncobro.COM_CODIGO,
        p_ASI_NRO: trncobro.ASI_NRO,
        p_USU_IDENTIFICACION: this.usuario.identificacion,
        p_TRNCOBRO_FLAG: Number(trncobro.TRNCOBRO_FLAG),
        p_BAN_CODIGO: trncobro.BAN_CODIGO,
        p_TRNCOBRO_EFECTIVO: Number(trncobro.TRNCOBRO_EFECTIVO),
        p_TRNCOBRO_CAMBIO: Number(trncobro.TRNCOBRO_CAMBIO),
        p_CON_CODIGO: trncobro.CON_CODIGO,
        p_TRNCOBRO_SALDOINI: Number(trncobro.TRNCOBRO_SALDOINI),
        p_BOD_CODIGO: trncobro.BOD_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }
  
  insertarTrnDocumento(strTrnCobroNroDoc, strTrnNroComprobante, trncobro, cuota): Observable<any[]> {
   
    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/kkgjjp2pnmigimt', {      
      elementos: {
        p_TRNCOBRO_nroDoc_1: strTrnCobroNroDoc,
        p_TRNDOCUMENTO_nroDoc_2: trncobro.TRNCOBRO_NRODOC,
        p_TRNDOCUMENTO_importe_3: Number(trncobro.TRNCOBRO_IMPORTE),
        p_COM_codigo_4: '01',
        p_CLI_codigo_5: trncobro.CLI_CODIGO,
        p_TRNDOCUMENTO_REF: cuota,
        p_TRNCOBRO_NROCOMPROBANTE: strTrnNroComprobante
      },
    }, this.confIniciales.httpOptions());
  }
  
  actualizaEstadoFactura(strNumFact, strCodCliente): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/5qc1oon0nsnkzng', {      
      elementos: {
        p_com_codigo: '01',
        p_cli_codigo: strCodCliente,
        p_encfac_numero: strNumFact
      },
    }, this.confIniciales.httpOptions());
  }
  
  verificarSaldoAnticipo(numFac): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/lc5meyu3m0do5cf', {      
      elementos: {
        TRNCOBRO_NRODOC: numFac
      },
    }, this.confIniciales.httpOptions());
  }
  
  eliminarFacturasPendientes(numFac): Observable<any[]> {
   
    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/a63nkuwf2si1bg3', {      
      elementos: {
        p_trncobro_nrodoc: numFac,
        p_com_codigo: '01',
      },
    }, this.confIniciales.httpOptions());
  }
  
  eliminarCobro(numCobro): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/z5pcjbmy0djer7o', {      
      elementos: {
        TRNCOBRO_NRODOC: numCobro
      },
    }, this.confIniciales.httpOptions());
  }
  
  eliminarCaja(numDev, caja): Observable<any[]> {
  
     
    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/gmrambe14w6ygta', {      
      elementos: {
        TRNCAJ_DOCNRO: numDev,
          TRNCAJ_NUMERO: caja.TRNPAGO_NUMERO,
          TRNCAJ_TIPO: caja.TRNPAGO_TIPO,
          CAJ_CODIGO: caja.CAJ_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }
  
  eliminarBanco(numDev, banco): Observable<any[]> {
    
    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/xp26hshfmybhoib', {      
      elementos: {
        TRNBAN_DOCNRO: numDev,
          BAN_CODIGO: banco.BANCLI_CODIGO,
          TRNBAN_TIPO: banco.TRNPAGO_TIPO,
          TRNBAN_NUMERO: banco.TRNPAGO_NUMERO
      },
    }, this.confIniciales.httpOptions());
  }
  
  actualizaFormaPagoFact(strNumFact, strTipoFP): Observable<any> {

       
    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/rnuc0e50n8m0b84', {      
      elementos: {
        TipoFP: strTipoFP,
        NumFact: strNumFact,
      },
    }, this.confIniciales.httpOptions());
  }
  
  anularAsientoContable(strAsiNro): Observable<any[]> {
      
    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/zvu870ko7t5c596', {      
      elementos: {
        p_ASI_nro: strAsiNro,
        p_COM_codigo: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  actualizarAsiNroFact(strNumFact): Observable<any> {
   
    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/u9hlaj7e4e861b5', {      
      elementos: {
        NumFact:strNumFact
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  obtenerClave(strclave): Observable<any> {
   
    return this.http.post<any[]>(this.way + '/ventas/pagodevolucion/emfzh98rvgf64mh', {      
      elementos: {
        clave:strclave
      },
    }, this.confIniciales.httpOptions());
  }
  //CDPJ
  erpExisFormaPago(banCliCodigo,tipopago,numeropago){
    return this.http.post(this.way + '/ventas/pagodevolucion/7d559354e', {
      elementos: {
        p_banclicodigo: banCliCodigo,
        p_bantrntipopago:tipopago,
        p_bantrnpagonum:numeropago
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
}
