import { Component, OnInit, ViewChild, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { TecencmantService } from '../tecservicios/tecencmant.service';
import { SegMaePermiso } from 'src/app/advantage/advantageinterfaces/segmaepermiso';
import { MessageService, ConfirmationService, SelectItem } from 'primeng';
import { PermisosService } from 'src/app/advantage/advantageservices/permisos.service';
import { DatePipe } from '@angular/common';
import { TecDetmant } from '../tecinterfaces/tecdetmant';
import { TecEncmant } from '../tecinterfaces/tecencmant';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';


@Component({
  selector: 'app-tecencmant',
  templateUrl: './tecencmant.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class TecencmantComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  indicador: any;
  index: number;

  @Input() auxiliar: boolean;
  @Input() auxiliarsenal: boolean;
  @Input() maestrocliente: any;
  @Input() recibosenal: any;
  @Output() cambiarbotones = new EventEmitter<boolean>();

  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;

  tecequipos: TecEncmant[];
  equipoclienteSeleccionado: TecEncmant;
  tecencmant: TecEncmant;
  detmantSeleccionado: TecDetmant;

  permisoEtiqueta: SegMaePermiso;
  messagekey = 'encmant';
  confirmkey = 'confirmencmant';

  displayDialogBusquedaFast: boolean = false;
  spin: boolean = false;

  // busqueda
  opcionbusqueda: string;
  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];
  tabla: string;
  where: string;

  // dropdowns
  opcionesMarca: SelectItem[];
  opcionesModelo: SelectItem[];
  opcionesOrigen: SelectItem[];

  largo: string;
  public frameworkComponents;
  rowStyle;
  defaultColDef;
  defaultColDefEquipo;
  rowSelection = 'multiple';

  columnDefs = [
    {
      headerName: 'Código', field: 'ETI_CODIGO', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
        }
      }
    },
    {
      headerName: 'Nombre', field: 'ETI_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 30
        }
      }
    },
    {
      headerName: 'Descripcion', field: 'DETMAT_DESCRIPCION', cellEditor: 'cellPrueba', width: 500,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 30
        }
      }
    }
  ]

  columnDefsEquipo = [
    {
      headerName: 'Código', field: 'EQUI_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: false
    },
    {
      headerName: 'Nombre', field: 'EQUI_NOMBRE', cellEditor: 'cellPrueba', width: 150, editable: false
    },
    {
      headerName: 'Serie', field: 'MANT_SERIE', cellEditor: 'cellPrueba', width: 150, editable: false
    },
    {
      headerName: 'Marca', field: 'MANT_MARCA', cellEditor: 'cellPrueba', width: 150, editable: false
    },
    {
      headerName: 'DíasMant.', field: 'MANT_DIAS_MANTE', cellEditor: 'cellPrueba', width: 100, editable: false
    },
    {
      headerName: 'UltMant', field: 'MANT_ULT_MANTE', cellEditor: 'cellPrueba', width: 100, editable: false
    },
    {
      headerName: 'ProxMant', field: 'MANT_PRX_MANTE', cellEditor: 'cellPrueba', width: 100, editable: false
    }
  ]

  constructor(public tecencmantService: TecencmantService, public init: NuevoComponentService,
    private messageService: MessageService, private permisosService: PermisosService,
    private confirmationService: ConfirmationService, private datePipe: DatePipe,
    private busqService: InvbusquedaService) {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultColDef = {
      editable: true,
      width: 100,
      objeto: 'tecdetmant'
    };

    this.defaultColDefEquipo = {
      editable: true,
      width: 100,
      objeto: 'tecencmantequipos'
    };
  }

  async ngOnInit() {
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.largo = '550';
    this.botonesIniciar();
    this.limpiardatos();
    await this.getPermisos();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const e in changes) {
      if (e === 'auxiliar' && this.maestrocliente !== undefined) {
        this.tecencmant.CLI_CODIGO = this.maestrocliente.CLI_CODIGO;
        this.tecencmant.CLI_NOMBRE = this.maestrocliente.CLI_NOMBRE;
        this.tecencmant.CLI_DIRECCION = this.maestrocliente.CLI_DIRECCION1;
        this.tecencmant.CLI_TELEFONO1 = this.maestrocliente.CLI_TELEFONO1;
        this.tecencmant.CLI_TELEFONO2 = this.maestrocliente.CLI_TELEFONO2;
        this.tecencmant.CLI_CORREO = this.maestrocliente.CLI_CORREO;
        this.cancelar();
      }
      if (e === 'auxiliarsenal' && this.maestrocliente !== undefined) {
        this.manejarSenales(this.recibosenal)
      }
    }
  }

  limpiardatos() {
    this.tecequipos = [];
    let clicodigo, clinombre, clidir, clitel1, clitel2, clicorreo;
    if (this.tecencmant !== undefined) {
      clicodigo = this.tecencmant.CLI_CODIGO;
      clinombre = this.tecencmant.CLI_NOMBRE;
      clidir = this.tecencmant.CLI_DIRECCION;
      clitel1 = this.tecencmant.CLI_TELEFONO1;
      clitel2 = this.tecencmant.CLI_TELEFONO2;
      clicorreo = this.tecencmant.CLI_CORREO;
    }
    this.tecencmant = {
      CLI_CODIGO: '',
      EQUI_CODIGO: '',
      MANT_SERIE: '',
      COM_CODIGO: '01',
      MANT_MARCA: '',
      MANT_MODELO: '',
      MANT_ORIGEN: '',
      MANT_DIAS_MANTE: '',
      MANT_ULT_MANTE: new Date(),
      MANT_PRX_MANTE: new Date(),
      ART_CODIGO: '',
      CLI_NOMBRE: '',
      EQUI_NOMBRE: '',
      ART_NOMBRE: '',
      CLI_DIRECCION: '',
      CLI_TELEFONO1: '',
      CLI_TELEFONO2: '',
      CLI_CORREO: ''
    };
    if (clicodigo !== undefined) {
      this.tecencmant.CLI_CODIGO = clicodigo;
      this.tecencmant.CLI_NOMBRE = clinombre;
      this.tecencmant.CLI_DIRECCION = clidir;
      this.tecencmant.CLI_TELEFONO1 = clitel1;
      this.tecencmant.CLI_TELEFONO2 = clitel2;
      this.tecencmant.CLI_CORREO = clicorreo;
    }
    this.tecencmantService.tecdetmant[this.indicador] = [];
    this.opcionesMarca = [];
    this.opcionesModelo = [];
    this.opcionesOrigen = [];
    this.btnNuevo = true;
  }

  async getPermisos() {
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisoEtiqueta = data[0];
        } else {
          this.permisoEtiqueta = null;
        }
      } else {
        this.permisoEtiqueta = null;
      }
    } catch (e) {
      this.permisoEtiqueta = null;
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Error',
        detail: 'No se pudo obtener los permisos del formulario'
      });
    }
  }

  botonesModificar() {
    if (this.auxiliar !== undefined) {
      this.cambiarbotones.emit(true);
    }
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = true;
    this.btnRegresar = false;
  }

  botonesIniciar() {
    if (this.auxiliar !== undefined) {
      this.cambiarbotones.emit(false);
    }
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = false;
  }

  async seleccionequipo(valor) {
    if (valor.object !== null) {
      this.equipoclienteSeleccionado = valor.object;
      const data: any = await this.tecencmantService.getencmant(this.equipoclienteSeleccionado);
      for (const rs of data) {
        this.tecencmant.CLI_CODIGO = rs.CLI_CODIGO;
        this.tecencmant.EQUI_CODIGO = rs.EQUI_CODIGO;
        this.tecencmant.MANT_SERIE = rs.MANT_SERIE;
        this.tecencmant.COM_CODIGO = rs.COM_CODIGO;
        this.tecencmant.MANT_MARCA = rs.MANT_MARCA;
        this.tecencmant.MANT_MODELO = rs.MANT_MODELO;
        this.tecencmant.MANT_ORIGEN = rs.MANT_ORIGEN;
        this.tecencmant.MANT_DIAS_MANTE = rs.MANT_DIAS_MANTE;
        this.tecencmant.MANT_ULT_MANTE = new Date(rs.MANT_ULT_MANTE);
        this.tecencmant.MANT_PRX_MANTE = new Date(rs.MANT_PRX_MANTE);
        this.tecencmant.ART_CODIGO = rs.ART_CODIGO;
        this.tecencmant.EQUI_NOMBRE = rs.EQUI_NOMBRE;
        this.tecencmant.ART_NOMBRE = rs.ART_NOMBRE;
      }
      const datadet: any = await this.tecencmantService.getdetmant(this.equipoclienteSeleccionado);
      await this.listardropdowns(this.tecencmant.EQUI_CODIGO);
      this.tecencmantService.tecdetmant[this.indicador] = datadet;
    }
  }

  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      this.nuevo();
    }
    if (valor === 'Guardar') {
      this.guardar();
    }
    if (valor === 'Borrar') {
      this.eliminar();
    }
    if (valor === 'Cancelar') {
      this.cancelar();
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }

  nuevo() {
    if (this.tecencmant.CLI_CODIGO === undefined) {
      this.tecencmant.CLI_CODIGO = '';
    }
    if (this.tecencmant.CLI_CODIGO.length > 0) {
      this.index = 1;
      this.limpiardatos();
      this.botonesModificar();
      document.getElementById('EQUI_CODIGO').focus();
    } else {
      this.messageService.add({
        key: this.messagekey,
        severity: 'warn',
        summary: 'Equipos Cliente',
        detail: 'Ingrese el cliente'
      });
    }
  }

  async guardar() {
    if (await this.faltacampos() === false) {
      return;
    }
    if (await this.faltacamposdet() === false) {
      return;
    }
    this.calcDateProxMant();
    const data: any = await this.tecencmantService.encontrarequipocliente(this.tecencmant.CLI_CODIGO, this.tecencmant.EQUI_CODIGO,
      this.tecencmant.MANT_SERIE, this.tecencmant.COM_CODIGO);
    if (data.length === 0) {
      this.insertar();
    } else {
      this.actualizar();
    }
    this.botonesIniciar();
    this.index = 0;
  }

  async faltacampos() {

    if (this.tecencmant.EQUI_CODIGO === '') {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Equipos Cliente',
        detail: 'Ingrese el codigo del equipo'
      });
      return false;
    }

    const data: any = await this.tecencmantService.encontrarequipo(this.tecencmant.EQUI_CODIGO);
    if (data.length === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Equipos Cliente',
        detail: 'No existe el equipo ingresado'
      });
      return false;
    }

    if (this.tecencmant.MANT_SERIE === '') {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Equipos Cliente',
        detail: 'Ingrese la serie del equipo'
      });
      return false;
    }

    if (this.tecencmant.MANT_MARCA === '') {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Equipos Cliente',
        detail: 'Ingrese la marca del equipo'
      });
      return false;
    }

    if (this.tecencmant.MANT_MODELO === '') {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Equipos Cliente',
        detail: 'Ingrese el modelo del equipo'
      });
      return false;
    }

    if (this.tecencmant.MANT_ORIGEN === '') {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Equipos Cliente',
        detail: 'Ingrese el origen del equipo'
      });
      return false;
    }

    if (this.tecencmant.MANT_DIAS_MANTE === '') {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Equipos Cliente',
        detail: 'Ingrese los días de mantenimiento del equipo'
      });
      return false;
    }

    if (this.tecencmant.MANT_ULT_MANTE === '') {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Equipos Cliente',
        detail: 'Ingrese la fecha del ultimo mantenimiento del equipo'
      });
      return false;
    }

  }


  async faltacamposdet() {
    let cont = 0;
    for (const rs of this.tecencmantService.tecdetmant[this.indicador]) {
      if (rs.ETI_CODIGO === '') {
        cont++;
      }
      const data: any = await this.tecencmantService.encontraretiqueta(rs.ETI_CODIGO);
      if (data.length === 0) {
        this.messageService.add({
          key: this.messagekey,
          severity: 'error',
          summary: 'Equipos Cliente',
          detail: 'No existe la etiqueta ' + rs.ETI_CODIGO + ' ingresado'
        });
        cont++;
      }
      if (rs.DETMAT_DESCRIPCION === '') {
        cont++;
      }
    }

    if (cont > 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Equipos Cliente',
        detail: 'Complete los campos en el detalle'
      });
      return false;
    } else {
      return true;
    }
  }

  async insertar() {
    if (this.permisoEtiqueta.PERINSERCION === 1) {
      const item: TecEncmant = {};
      item.CLI_CODIGO = this.tecencmant.CLI_CODIGO;
      item.EQUI_CODIGO = this.tecencmant.EQUI_CODIGO;
      item.MANT_SERIE = this.tecencmant.MANT_SERIE;
      item.MANT_MARCA = this.tecencmant.MANT_MARCA;
      item.MANT_MODELO = this.tecencmant.MANT_MODELO;
      item.MANT_ORIGEN = this.tecencmant.MANT_ORIGEN;
      item.MANT_DIAS_MANTE = this.tecencmant.MANT_DIAS_MANTE;
      item.MANT_ULT_MANTE = this.datePipe.transform(this.tecencmant.MANT_ULT_MANTE, 'dd/MM/yyyy');
      item.MANT_PRX_MANTE = this.datePipe.transform(this.tecencmant.MANT_PRX_MANTE, 'dd/MM/yyyy');
      item.ART_CODIGO = this.tecencmant.ART_CODIGO;

      await this.tecencmantService.insertarencmant(item);

      for (const rs of this.tecencmantService.tecdetmant[this.indicador]) {
        const itemdet: TecDetmant = {};
        itemdet.CLI_CODIGO = this.tecencmant.CLI_CODIGO;
        itemdet.EQUI_CODIGO = this.tecencmant.EQUI_CODIGO;
        itemdet.MANT_SERIE = this.tecencmant.MANT_SERIE;
        itemdet.ETI_CODIGO = rs.ETI_CODIGO;
        itemdet.DETMAT_DESCRIPCION = rs.DETMAT_DESCRIPCION;
        await this.tecencmantService.insertardetmant(itemdet);
      }
      this.messageService.add({
        key: this.messagekey,
        severity: 'success',
        summary: 'Equipos Cliente',
        detail: 'Equipo del cliente guardado correctamente'
      });
      this.cancelar();
    } else if (this.permisoEtiqueta.PERINSERCION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de inserción, acción denegada'
      });
    }
  }

  async verificareliminar() {
    const data = await this.tecencmantService.encontrarequipoordtra(this.tecencmant);
    if (data !== null) {
      if (data[0] !== undefined) {
        this.messageService.add({
          key: this.messagekey,
          severity: 'error',
          summary: 'Equipo',
          detail: 'No se pudo eliminar. Equipo se encuentra agregado en una orden de trabajo.'
        });
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  eliminar() {
    if (this.permisoEtiqueta.PERELIMACION === 1) {
      this.confirmationService.confirm({
        message: 'Esta seguro que desea eliminar el equipo del cliente',
        header: 'Eliminar etiqueta',
        icon: 'pi pi-exclamation-triangle',
        key: this.confirmkey,
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: async () => {
          this.confirmationService.close();
          if (await this.verificareliminar() === true) {
            this.botonesIniciar();
            await this.tecencmantService.eliminardetmantall(this.tecencmant);
            await this.tecencmantService.eliminarencmant(this.tecencmant);
            this.limpiardatos();
            this.mostrarequipos(this.tecencmant.CLI_CODIGO);
            this.index = 0;
            this.messageService.add({
              key: this.messagekey,
              severity: 'success',
              summary: 'Equipos Cliente',
              detail: 'Etiqueta eliminada correctamente'
            });
          }
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    } else if (this.permisoEtiqueta.PERELIMACION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de eliminación, acción denegada'
      });
    }
  }

  cancelar() {
    this.botonesIniciar();
    this.index = 0;
    this.mostrarequipos(this.tecencmant.CLI_CODIGO);
  }

  async actualizar() {
    if (this.permisoEtiqueta.PERACTUALIZACION === 1) {
      const item: TecEncmant = {};
      item.CLI_CODIGO = this.tecencmant.CLI_CODIGO;
      item.EQUI_CODIGO = this.tecencmant.EQUI_CODIGO;
      item.MANT_SERIE = this.tecencmant.MANT_SERIE;
      item.MANT_MARCA = this.tecencmant.MANT_MARCA;
      item.MANT_MODELO = this.tecencmant.MANT_MODELO;
      item.MANT_ORIGEN = this.tecencmant.MANT_ORIGEN;
      item.MANT_DIAS_MANTE = this.tecencmant.MANT_DIAS_MANTE;
      item.MANT_ULT_MANTE = this.datePipe.transform(this.tecencmant.MANT_ULT_MANTE, 'dd/MM/yyyy');
      item.MANT_PRX_MANTE = this.datePipe.transform(this.tecencmant.MANT_PRX_MANTE, 'dd/MM/yyyy');
      item.ART_CODIGO = this.tecencmant.ART_CODIGO;
      await this.tecencmantService.actualizarencmant(item);
      await this.tecencmantService.eliminardetmantall(this.tecencmant);
      for (const rs of this.tecencmantService.tecdetmant[this.indicador]) {
        const itemdet: TecDetmant = {};
        itemdet.CLI_CODIGO = this.tecencmant.CLI_CODIGO;
        itemdet.EQUI_CODIGO = this.tecencmant.EQUI_CODIGO;
        itemdet.MANT_SERIE = this.tecencmant.MANT_SERIE;
        itemdet.ETI_CODIGO = rs.ETI_CODIGO;
        itemdet.DETMAT_DESCRIPCION = rs.DETMAT_DESCRIPCION;
        await this.tecencmantService.insertardetmant(itemdet);
      }
      this.messageService.add({
        key: this.messagekey,
        severity: 'success',
        summary: 'Equipos Cliente',
        detail: 'Equipo del cliente actualizado correctamente'
      });
      this.cancelar();
    } else if (this.permisoEtiqueta.PERACTUALIZACION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de inserción, acción denegada'
      });
    }
  }


  async seleccionDet(valor) {
    if (valor === 'nuevo') {
      this.nuevodet();
    }
    if (valor === 'eliminar') {
      this.eliminardet();
    }
    if (valor === 'cancelar') {
      this.cancelardet();
    }
    if (valor.object !== null && valor.object !== undefined) {
      this.detmantSeleccionado = valor.object;
    }
  }

  nuevodet() {
    if (this.permisoEtiqueta.PERINSERCION === 1) {
      this.botonesModificar();

      const etiquetanuevo = {
        CLI_CODIGO: this.tecencmant.CLI_CODIGO,
        EQUI_CODIGO: this.tecencmant.CLI_CODIGO,
        MANT_SERIE: this.tecencmant.CLI_CODIGO,
        COM_CODIGO: '01',
        ETI_CODIGO: '',
        DETMAT_DESCRIPCION: '',
        ETI_NOMBRE: '',
        CLAVE: ''
      };

      const indi = this.tecencmantService.tecdetmant[this.indicador].indexOf(this.detmantSeleccionado);

      if (indi === -1) {
        etiquetanuevo.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.tecencmantService.tecdetmant[this.indicador].push(etiquetanuevo);
        this.detmantSeleccionado = etiquetanuevo;
        this.aggrid.refreshaggrid(this.tecencmantService.tecdetmant[this.indicador], this.defaultColDef.objeto);
        if (this.tecencmantService.tecdetmant[this.indicador].length === 1) {
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
            this.defaultColDef.objeto].setFocusedCell(0, 'ETI_CODIGO');
        } else {
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
            this.defaultColDef.objeto].setFocusedCell((this.tecencmantService.tecdetmant[this.indicador].length) - 1, 'ETI_CODIGO');
        }
      } else {
        etiquetanuevo.CLAVE = (indi + 1) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.tecencmantService.tecdetmant[this.indicador].splice(indi + 1, 0, etiquetanuevo);
        this.detmantSeleccionado = etiquetanuevo;
        this.aggrid.refreshaggridindex(this.tecencmantService.tecdetmant[this.indicador], this.defaultColDef.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDef.objeto].setFocusedCell(indi + 1, 'ETI_CODIGO');
      }
    } else if (this.permisoEtiqueta.PERINSERCION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de inserción, acción denegada'
      });
    }
  }

  eliminardet() {
    if (this.permisoEtiqueta.PERELIMACION === 1) {
      this.confirmationService.confirm({
        message: 'Esta seguro que desea eliminar la etiqueta',
        header: 'Eliminar etiqueta',
        icon: 'pi pi-exclamation-triangle',
        key: this.confirmkey,
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: async () => {
          this.confirmationService.close();
          const itemdet: TecDetmant = {};
          itemdet.CLI_CODIGO = this.tecencmant.CLI_CODIGO;
          itemdet.EQUI_CODIGO = this.tecencmant.EQUI_CODIGO;
          itemdet.MANT_SERIE = this.tecencmant.MANT_SERIE;
          itemdet.ETI_CODIGO = this.detmantSeleccionado.ETI_CODIGO;
          itemdet.DETMAT_DESCRIPCION = this.detmantSeleccionado.DETMAT_DESCRIPCION;
          await this.tecencmantService.eliminardetmant(itemdet);
          this.tecencmantService.tecdetmant[this.indicador].splice(this.tecencmantService.tecdetmant[this.indicador].findIndex(
            eti => eti.CLAVE === this.detmantSeleccionado.CLAVE), 1);
          this.aggrid.refreshaggrid(this.tecencmantService.tecdetmant[this.indicador], this.defaultColDef.objeto);
          this.messageService.add({
            key: this.messagekey,
            severity: 'success',
            summary: 'Equipos Cliente',
            detail: 'Etiqueta eliminada correctamente'
          });
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    } else if (this.permisoEtiqueta.PERELIMACION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de eliminación, acción denegada'
      });
    }
  }

  async cancelardet() {
    if (this.permisoEtiqueta.PERSELECCION === 1) {
      this.botonesIniciar();
      const data: any = await this.tecencmantService.getequiposetiquetas(this.tecencmant.EQUI_CODIGO);
      for (const rs of data) {
        rs.CLAVE = rs.ETI_CODIGO;
      }
      this.tecencmantService.tecdetmant[this.indicador] = data;
    } else if (this.permisoEtiqueta.PERSELECCION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de selección, acción denegada'
      });
    }
  }

  async busquedacliente(parametro) {
    if (parametro === null) {
      parametro = '';
    }
    this.opcionbusqueda = 'cliente';
    this.types = [
      { label: 'Código', value: 'CLI_CODIGO' },
      { label: 'Nombre', value: 'CLI_NOMBRE' },
      { label: 'RucIde', value: 'CLI_RUCIDE' },
      { label: 'Dirección', value: 'CLI_DIRECCION' },
      { label: 'Teléfono1', value: 'CLI_TELEFONO1' },
      { label: 'Teléfono2', value: 'CLI_TELEFONO2' },
      { label: 'Correo', value: 'CLI_CORREO' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', ''];
    this.tabla = 'VEN_MAECLIENTE';
    this.where = '';

    if (parametro !== '' && parametro !== undefined && parametro !== null) {
      const eR = await this.tecencmantService.erpBusquedaCliente(this.tabla, this.types[0].value, parametro);
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
          return;
        }
      }
    }

    this.spin = true;
    this.busqService.busquedaVenMaeCliente3(parametro).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });

  }

  async busquedaequipo(parametro) {
    this.opcionbusqueda = 'equipo';
    this.types = [
      { label: 'Código', value: 'EQUI_CODIGO' },
      { label: 'Nombre', value: 'EQUI_NOMBRE' },
    ];
    this.busquedacampos = [parametro, ''];
    this.tabla = 'TEC_MAEEQUIPO';
    this.where = '';

    if (parametro !== '' && parametro !== undefined && parametro !== null) {
      const eR = await this.tecencmantService.encontrarequipo(parametro);
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
          return;
        }
      }
    }

    this.busqService.busquedaTecMaeEquipo1(parametro).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  async busquedaarticulo(parametro) {
    this.opcionbusqueda = 'articulo';
    this.types = [
      { label: 'Orden', value: 'ART_CODIGO' },
      { label: 'Nombre', value: 'ART_NOMBRE' },
      { label: 'Grupo', value: 'GRUP_CODIGO' },
      { label: 'NombreCorto', value: 'ART_NOMBREC' },
      { label: 'CodigoAlterno', value: 'ART_CODIGOALT1' },
      { label: 'Ubicación', value: 'ART_UBICACION' },
      { label: 'Laboratorio', value: 'ART_LABORATORIO' },
      { label: 'Alterno2', value: 'ART_CODIGOALT2' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', '', ''];
    this.tabla = 'INV_MAEARTICULO';
    this.where = 'COM_CODIGO = \'01\' AND ART_ACTIVO=\'S\'';

    if (parametro !== '' && parametro !== undefined && parametro !== null) {
      const eR = await this.tecencmantService.encontrararticulo(parametro);
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
          return;
        }
      }
    }

    this.busqService.busquedaInvMaeArticulo5(parametro).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  async mostrarequipos(clicodigo) {
    const data: any = await this.tecencmantService.getequiposcliente(clicodigo);
    for (const rs of data) {
      rs.MANT_ULT_MANTE = this.datePipe.transform(rs.MANT_ULT_MANTE, 'dd/MM/yyyy');
      rs.MANT_PRX_MANTE = this.datePipe.transform(rs.MANT_PRX_MANTE, 'dd/MM/yyyy');
    }
    this.tecequipos = data;
  }

  async manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'cliente') {
      this.limpiardatos();
      this.btnNuevo = false;
      this.tecencmant.CLI_CODIGO = opcion.CLI_CODIGO;
      this.tecencmant.CLI_NOMBRE = opcion.CLI_NOMBRE;
      this.tecencmant.CLI_DIRECCION = opcion.CLI_DIRECCION1;
      this.tecencmant.CLI_TELEFONO1 = opcion.CLI_TELEFONO1;
      this.tecencmant.CLI_TELEFONO2 = opcion.CLI_TELEFONO2;
      this.tecencmant.CLI_CORREO = opcion.CLI_CORREO;
      this.mostrarequipos(opcion.CLI_CODIGO);
      this.index = 0;
    }
    if (this.opcionbusqueda === 'equipo') {
      this.tecencmant.EQUI_CODIGO = opcion.EQUI_CODIGO;
      this.tecencmant.EQUI_NOMBRE = opcion.EQUI_NOMBRE;
      const data: any = await this.tecencmantService.getequiposetiquetas(this.tecencmant.EQUI_CODIGO);
      for (const rs of data) {
        rs.CLAVE = rs.ETI_CODIGO;
        rs.DETMAT_DESCRIPCION = '';
      }
      this.tecencmantService.tecdetmant[this.indicador] = data;
      this.listardropdowns(opcion.EQUI_CODIGO);
      document.getElementById('ART_CODIGO').focus();
    }
    if (this.opcionbusqueda === 'articulo') {
      this.tecencmant.ART_CODIGO = opcion.ART_CODIGO;
      this.tecencmant.ART_NOMBRE = opcion.ART_NOMBRE;
      document.getElementById('MANT_SERIE').focus();
    }
    this.displayDialogBusquedaFast = false;
  }

  setFocus(event) {
    let element: any;
    switch (event.target.id) {
      case 'CLI_CODIGO': {
        this.busquedacliente(event.target.value);
        break;
      }
      case 'EQUI_CODIGO': {
        this.busquedaequipo(event.target.value);
        break;
      }
      case 'ART_CODIGO': {
        this.busquedaarticulo(event.target.value);
        break;
      }
      case 'MANT_SERIE': {
        element = document.getElementById('MANT_DIAS_MANTE');
        break;
      }
      case 'MANT_MARCA': {
        element = document.getElementById('MANT_MODELO');
        break;
      }
      case 'MANT_MODELO': {
        element = document.getElementById('MANT_ORIGEN');
        break;
      }
      case 'MANT_ORIGEN': {
        element = document.getElementById('MANT_DIAS_MANTE');
        break;
      }
      case 'MANT_DIAS_MANTE': {
        element = document.getElementById('EQUI_CODIGO');
        this.calcDateProxMant();
        break;
      }
      case 'MANT_ULT_MANTE': {
        element = document.getElementById('EQUI_CODIGO');
        this.calcDateProxMant();
        break;
      }
      default: {
        break;
      }
    }

    if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }
  }

  calcDateProxMant() {
    const dateTimeFecIni: Date = this.tecencmant.MANT_ULT_MANTE;
    this.tecencmant.MANT_PRX_MANTE = this.obtenerFechaProxMant(dateTimeFecIni.getDate(),
      dateTimeFecIni.getMonth(), dateTimeFecIni.getFullYear());
  }

  obtenerFechaProxMant(dia, mes, anio): Date {
    let fecha1: Date;
    fecha1 = new Date(anio, mes, dia);

    fecha1.setDate(dia + (this.tecencmant.MANT_DIAS_MANTE === '' ? 0 :
      Number(this.tecencmant.MANT_DIAS_MANTE)));

    return fecha1;
  }

  async changeTabs() {
    if (this.index > 0) {
      this.botonesIniciar();
      document.getElementById('EQUI_CODIGO').focus();
    } else {
      this.botonesIniciar();
    }
  }

  async listardropdowns(equicodigo) {
    await this.listarmarcas(equicodigo);
    await this.listarmodelos(equicodigo);
    await this.listarorigenes(equicodigo);
  }

  async listarmarcas(equicodigo) {
    this.opcionesMarca = [];
    const data: any = await this.tecencmantService.encontrarmarcaequipo(equicodigo);
    for (const rs of data) {
      this.opcionesMarca.push({ label: rs.MANT_MARCA, value: rs.MANT_MARCA });
    }
  }

  async listarmodelos(equicodigo) {
    this.opcionesModelo = [];
    const data: any = await this.tecencmantService.encontrarmodeloequipo(equicodigo);
    for (const rs of data) {
      this.opcionesModelo.push({ label: rs.MANT_MODELO, value: rs.MANT_MODELO });
    }
  }

  async listarorigenes(equicodigo) {
    this.opcionesOrigen = [];
    const data: any = await this.tecencmantService.encontrarorigenequipo(equicodigo);
    for (const rs of data) {
      this.opcionesOrigen.push({ label: rs.MANT_ORIGEN, value: rs.MANT_ORIGEN });
    }
  }

}
