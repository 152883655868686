import { Component, OnInit, ViewChild } from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {MessageService, ConfirmationService} from 'primeng';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import {Nommaeperiodo} from '../nominterfaces/nommaeperiodo';
import { NommaeperiodoService } from '../nomservicios/nommaeperiodo.service';
import { DatePipe } from '@angular/common';
import { IfStmt } from '@angular/compiler';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
@Component({
  selector: 'app-nommaeperiodo',
  templateUrl: './nommaeperiodo.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NommaeperiodoComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  valor:string='MEN30D';
  anioD:number;
  btnGuardar: boolean;
  btnNuevo: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  displayDialogPerido:boolean;
  defaultColDefMaePeriodo;
  public frameworkComponents;

  rowStyle;
  largo = '370';
  rowSelection = 'multiple';
  nommaeperiodo: Nommaeperiodo[]=[];
  selected_maePeriodo: Nommaeperiodo;

  anio: number;
  codigoActual: number;

  displayAcciones: boolean;

  columnDefMaePeriodo = [
    {
      headerName: 'Periodo código', field: 'PERIODO_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: false,
      /*cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: false,
          }
        }*/
    },
    {
      headerName: '#', field: 'PERIODO_NUMERO', cellEditor: 'cellPrueba', width: 50,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 200,
            tienecalculos: false
          }
        }
    },
    {
      headerName: 'Descripción', field: 'PERIODO_DESPRICION', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 200,
            tienecalculos: false,
          }
        }
    },
    
    {
      headerName: 'Fec.Ini.', field: 'PERIODO_FECINI', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: true,
            tamanocelda: 400,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Fec.Fin.', field: 'PERIODO_FECFIN', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas:false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: true,
            tamanocelda: 400,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'D.C.', field: 'PERIODO_DIACALC', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 200,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'D.O.', field: 'PERIODO_DIAOBR', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 200,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'D.E.', field: 'PERIODO_DIAEMP', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 200,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'D. Año', field: 'PERIODO_DIASANIO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 200,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Est', field: 'PERIODO_ESTADO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 200,
            tienecalculos: false,
          }
        }
    },
  ];

  constructor(
    public maeperiodoService: NommaeperiodoService,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private init: NuevoComponentService,
    private errorService: ErroresBaseDatosService,
    private confirmationService: ConfirmationService,
    private auditoriaGralService: AuditoriagralService,
  ) {
    this.agTable();
   }


   
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefMaePeriodo = {
      editable: true,
      width: 130,
      objeto: 'maePeriodo'
    };
  }

  ngOnInit(): void {
    this.btnNuevo =  false;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.btnRegresar = false;

    this.anio = new Date().getFullYear();
    this.anioD = new Date().getFullYear();
    this.getData();
    this.displayAcciones = false;
    this.displayDialogPerido=false;
  }

  getData() {
    this.maeperiodoService.cargaDatosAnio(this.anio).then( (res:any) => {
        this.nommaeperiodo = res;
        for( let nomperiodo of this.nommaeperiodo  ) {
          nomperiodo.PERIODO_FECINI = this.datePipe.transform( nomperiodo.PERIODO_FECINI, 'dd/MM/yyyy' );
          nomperiodo.PERIODO_FECFIN = this.datePipe.transform( nomperiodo.PERIODO_FECFIN, 'dd/MM/yyyy' );
        }
        return this.maeperiodoService.getCodigoActual().then( /*array*/ cod =>  {
          for( const key in cod[0] ){ // recorrer campos de un objeto
            this.codigoActual = cod[0][key]
          }
          return;
        });
    } ). catch ( e => console.log(e) )
  }

  


  selectMaePeriodo(elemento) {
    if (elemento.object !== null) {
      this.selected_maePeriodo = elemento.object;
    }
  }

  cambio(cambio) {
    this.btnNuevo=true;
    this.btnGuardar = false,
    this.btnRegresar = false; 
    if (this.selected_maePeriodo.nuevo === undefined) {
      this.selected_maePeriodo.editado = true;
    }
  }
  agregarPeriodo(){
    this.displayDialogPerido = true;
    
    this.closeSidebar();
  }
  onHideAutorizacion(){
    this.displayDialogPerido = false;
  }
  opcionCancelar(){
    this.displayDialogPerido = false;
  }
  async opcionAceptar(){
    if(this.anioD==null){
      this.mensajeAlertaDialog('error', 'Error', 'Ingrese el año');
    }else{
      if(this.validarGenerar()){
        switch(this.valor){
          case 'MEN30D':
            await this.maeperiodoService.GeneRolMens30Dias(this.anioD).then(()=>{
              this.mensajeAlerta('success', 'Información', '¡Se genero exitosamente!');
      
              this.cargar();
              this.displayDialogPerido = false;

            }).catch(err=>{
              this.mensajeAlertaDialog('error', 'Error', 'Ha Ocurrido Un Error En La Transacción'); 
            })
            break;
          case 'MENSREAL':
            await this.maeperiodoService.GeneRolMesExac(this.anioD).then(()=>{
              this.mensajeAlerta('success', 'Información', '¡Se genero exitosamente!');
              this.cargar();
              this.displayDialogPerido = false;
              
            }).catch(err=>{
              this.mensajeAlertaDialog('error', 'Error', 'Ha Ocurrido Un Error En La Transacción');
            })
            break;
          case 'QUINDIAS':
            await this.maeperiodoService.GeneRolQuinDias(this.anioD).then(()=>{
              this.mensajeAlerta('success', 'Información', '¡Se genero exitosamente!');
              this.cargar();
              this.displayDialogPerido = false;
            }).catch(err=>{
              this.mensajeAlertaDialog('error', 'Error', 'Ha Ocurrido Un Error En La Transacción');
            })
            break
        }
        await this.maeperiodoService.ActivaPeridioEnProceso().then(()=>{ 
          this.maeperiodoService.NomGlbObtienePeriodo().then((res)=>{
          })
        }).catch(err=>{
            this.mensajeAlertaDialog('error', 'Error', err);
        })
      }else{
        this.mensajeAlertaDialog('error', 'Error', 'No puede generar períodos para este año');
      }
    }
   
  }
   async validarGenerar(){
    await this.maeperiodoService.getAnioAuto(this.anioD).then(res=>{
      if(res[0]["COUNT(PERIODO_NUMERO)"]==0){
        return true;
      }else{
        return false;
      }
    })
  }
  acciones(){
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className = 'layout-config';
      }
    }
  }
  closeSidebar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }
  manejarSenales(valor) {
    switch (valor) {
      case 'Nuevo':
        this.opcionNuevo();
        break;
      case 'Guardar':
        this.opcionGuardar();
        break;
      case 'Borrar':
        this.opcionBorrar();
        break;
      case 'Cancelar':
        this.cargar();
        break;
      case 'Salir':
        this.init.tabs.splice(  this.init.tabindex, 1);
        break;
      
    }

    
  }

  opcionNuevo(){
    this.btnNuevo = true;
    this.btnBorrar = false;
    this.btnRegresar = false;
    const new_nommaeperiodo: Nommaeperiodo = {
      PERIODO_CODIGO:'',
      PERIODO_ANIO: this.anio,
      PERIODO_NUMERO: '',
      PERIODO_DESPRICION: '',
      PERIODO_FECINI: '',
      PERIODO_FECFIN: '',
      PERIODO_DIACALC: 0,
      PERIODO_DIAOBR: 0,
      PERIODO_DIAEMP: 0,
      PERIODO_DIASANIO: 0,
      PERIODO_ESTADO: 'A',
      COM_CODIGO: '01',
      nuevo: true
    };
    this.nommaeperiodo.push(new_nommaeperiodo);
    this.aggrid.refreshaggrid(this.nommaeperiodo, this.defaultColDefMaePeriodo.objeto);
  }

  opcionGuardar(){
    this.aggrid.gridApi.stopEditing();
    this.selected_maePeriodo.PERIODO_DIACALC  = Number(this.selected_maePeriodo.PERIODO_DIACALC);
    this.selected_maePeriodo.PERIODO_DIAOBR   = Number(this.selected_maePeriodo.PERIODO_DIAOBR);
    this.selected_maePeriodo.PERIODO_DIAEMP   = Number(this.selected_maePeriodo.PERIODO_DIAEMP);
    this.selected_maePeriodo.PERIODO_DIASANIO = Number(this.selected_maePeriodo.PERIODO_DIASANIO);
    
    let valido = true;
    for( const key in this.selected_maePeriodo ){ // recorrer campos de un objeto
      console.log( key);
      if( this.selected_maePeriodo[key] === null || this.selected_maePeriodo[key] === '') {
       if(key!="PERIODO_CODIGO"){
        valido = false;
       }      
      }
    }
    console.log(this.selected_maePeriodo);
    if( valido )  {
      this.enviarEditado();
    } else {
      
      this.mensajeAlerta('info', 'Información', 'Se han encontrado campos vacíos');

    }

  }

  opcionBorrar(){
    this.confirmationService.confirm({
      message: '¿Está seguro que desea eliminar el periodo?',
      header: 'Eliminar Periodo',
      icon: 'pi pi-exclamation-triangle',
      key: 'NomMaePerDialog',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarPeriodo();
      },
      reject: () => {
      }
    });
  }

  eliminarPeriodo(){
    if( this.selected_maePeriodo.nuevo === undefined ){
      this.maeperiodoService.borrar( this.selected_maePeriodo ).then( () => {
        this.mensajeAlerta('success', 'Información', ' ¡Periodo eliminado exitosamente!');
        this.cargar();
        this.auditoriaGralService.registrarAuditoria('nom_maeperiodo', this.selected_maePeriodo.PERIODO_CODIGO, 'E',
              '', '01', '', '', '', '').toPromise().then(() => {
              });
      } ).catch( e => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      } )
    }
  }

  enviarEditado(){
    this.selected_maePeriodo.PERIODO_CODIGO = Number(this.selected_maePeriodo.PERIODO_CODIGO);
    
    this.maeperiodoService.actualizaPeriodo(this.selected_maePeriodo).then( () =>{
      this.mensajeAlerta('success', 'Información', '¡Periodo actualizado exitosamente!');
      this.cargar();
      let au=this.selected_maePeriodo.nuevo==true?'I':'A';
      this.auditoriaGralService.registrarAuditoria('nom_maeperiodo', this.selected_maePeriodo.PERIODO_CODIGO, au,
      '', '01', '', '', '', '').toPromise().then(() => {
      });
    }).catch( e => {
      
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }  );
  }

  cargar() {
    this.btnNuevo =  false;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.btnRegresar = false;
    this.getData();
  }

  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'nommaepertoast',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
    
  }

mensajeAlertaDialog(tipo, titulo, mensaje) {
  this.messageService.add({
    key: 'rubDialog',
    severity: tipo,
    summary: titulo,
    detail: mensaje
  });
  
}
}
