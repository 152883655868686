import { Injectable } from '@angular/core';
import { Comencordcom } from '../cominterfaces/comencordcom';
import { Comdetordcom } from '../cominterfaces/comdetordcom';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { Observable } from 'rxjs';
import { VenEncped } from '../../ven/veninterfaces/venencped';
import { VenDetped } from '../../ven/veninterfaces/vendetped';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';

@Injectable({
  providedIn: 'root'
})
export class ComencordcomService {


  public encOrdSeleccionadaarray: Comencordcom[] = [];
  public detordenesarray: Comdetordcom[][] = [];
  public dblPorcDescGlbOrdarray: number[] = [];
  public dblValorDescGlbOrdarray: number[] = [];


  public decimalesCantidadOrd: number = 2;
  public decimalesPrecioOrd: number;
  public decimalesTotalOrd: number;
  public dblPorcIvaPed = 12;
    //CDPJ
    public porcetariiva:number;
    public codtariiva:string;
    //CDPJ
  public way: string;


  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario, private confIniciales: ConfInicialesService,
    private init: NuevoComponentService) {

    this.way = sessionStorage.getItem('way');
  }

  refrescarURL() {
    if (this.way === null) {
      this.way = sessionStorage.getItem('way');
    }
  }

  getEncOrden(): Observable<any[]> {
    this.way = sessionStorage.getItem('way');
    return this.http.post<any[]>(this.way + '/compras/ordencompra/41dd75d98db5d8', {
      elementos: {},

    }, this.confIniciales.httpOptions());
  }

  getEncabezado(numero): Promise<any[]> {
    this.way = sessionStorage.getItem('way');
    return this.http.post<any[]>(this.way + '/compras/ordencompra/10775d76296e', {
      elementos: {
        ENCORDCOM_NUMERO: numero === undefined ? '' : numero
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDetOrden(ordenCodigo: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/ordencompra/41dd6bbb8db5d8', {
      elementos: {
        ENCORDCOM_NUMERO: ordenCodigo === undefined ? '' : ordenCodigo
      }
    }, this.confIniciales.httpOptions());
  }

  guardarENCORD(encOrd): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/ordencompra/10f2b6d56dd766000', {
      elementos: {
        p_ENCORDCOM_NUMERO: encOrd.ENCORDCOM_NUMERO,
        p_COM_CODIGO: '01',
        P_ENCORDCOM_REFERENCIA: encOrd.ENCORDCOM_REFERENCIA,
        p_ENCORDCOM_CONTACTO: encOrd.ENCORDCOM_CONTACTO,
        p_ENCORDCOM_FECHAEMISION: encOrd.ENCORDCOM_FECHAEMISION,
        p_ENCORDCOM_FECHAENTREGA: encOrd.ENCORDCOM_FECHAENTREGA,
        p_ENCORDCOM_IVA: encOrd.ENCORDCOM_IVA,
        p_ENCORDCOM_ESTADO: encOrd.ENCORDCOM_ESTADO,
        p_ENCORDCOM_OBSERVACION: encOrd.ENCORDCOM_OBSERVACION,
        p_ENCORDCOM_TOTAL: Number(encOrd.ENCORDCOM_TOTAL),
        p_ENCORDCOM_TOTALNETO: Number(encOrd.ENCORDCOM_TOTALNETO),
        p_ENCORDCOM_VALORIVA: Number(encOrd.ENCORDCOM_VALORIVA),
        p_ENCORDCOM_PORCEIVA: Number(encOrd.ENCORDCOM_PORCEIVA),
        p_ENCORDCOM_VALORICE: Number(encOrd.ENCORDCOM_VALORICE),
        p_ENCORDCOM_BASEIVA: Number(encOrd.ENCORDCOM_BASEIVA),
        p_ENCORDCOM_BASEICE: Number(encOrd.ENCORDCOM_BASEICE),
        p_ENCORDCOM_BASECERO: Number(encOrd.ENCORDCOM_BASECERO),
        p_ENCORDCOM_OTROS: Number(encOrd.ENCORDCOM_OTROS),
        p_ENCORDCOM_DOCUMENTACION: encOrd.ENCORDCOM_DOCUMENTACION,
        p_ENCORDCOM_NUMNOTREC: encOrd.ENCORDCOM_NUMNOTREC,
        P_ENCORDCOM_REFERENCIA_ADI: encOrd.ENCORDCOM_REFERENCIA_ADI,
        p_PRO_CODIGO: encOrd.PRO_CODIGO,
        p_ENCORDCOM_VALORDES: Number(encOrd.ENCORDCOM_VALORDES),
        p_ENCORDCOM_PORCEDES: Number(encOrd.ENCORDCOM_PORCEDES),
        p_ENCORDCOM_TIPODES: encOrd.ENCORDCOM_TIPODES,
        P_USUIDENTIFICACION: encOrd.USUIDENTIFICACION,
        P_ENCORDCOM_ARCHIVO: encOrd.ENCORDCOM_ARCHIVO,
       
      },
    }, this.confIniciales.httpOptions());
  }

  actualizarEstado(ordenCodigo, ordenEstado): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/ordencompra/533be555dcea9c0', {
      elementos: {
        ENCORDCOM_NUMERO: ordenCodigo,
        encordcom_estadooc: ordenEstado
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarTipoOC(ordenCodigo, ordentipo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/ordencompra/f65aaebbb2ce300', {
      elementos: {
        ENCORDCOM_NUMERO: ordenCodigo,
        ENCORDCOM_TIPOOC: ordentipo
      }
    }, this.confIniciales.httpOptions());
  }

  // insertarDetalle(comdetord: Comdetordcom): Observable<any[]> {
  //   return this.http.post<any[]>(this.way + '/compras/ordencompra/12bf1dbeb5dd55000', {
  //     elementos: {
  //       p_ENCORDCOM_numero: comdetord.ENCORDCOM_NUMERO,
  //       p_COM_codigo: comdetord.COM_CODIGO,
  //       p_DETORDCOM_linea: comdetord.DETORDCOM_LINEA,
  //       p_DETORDCOM_tipodet: comdetord.DETORDCOM_TIPODET,
  //       p_BOD_codigo: comdetord.BOD_CODIGO,
  //       p_DETORDCOM_codigo: comdetord.DETORDCOM_CODIGO,
  //       p_DETORDCOM_descripcion: comdetord.DETORDCOM_DESCRIPCION,
  //       p_DETORDCOM_unidad: comdetord.DETORDCOM_UNIDAD,
  //       p_DETORDCOM_cantidad: Number(comdetord.DETORDCOM_CANTIDAD),
  //       p_DETORDCOM_despacho: Number(comdetord.DETORDCOM_DESPACHO),
  //       p_DETORDCOM_costo: Number(comdetord.DETORDCOM_COSTO),
  //       p_DETORDCOM_descuento: Number(comdetord.DETORDCOM_DESCUENTO),
  //       p_DETORDCOM_total: Number(comdetord.DETORDCOM_TOTAL),
  //       p_DETORDCOM_tribiva: comdetord.DETORDCOM_TRIBIVA,
  //       p_DETORDCOM_iva: Number(comdetord.DETORDCOM_IVA),
  //       p_DETORDCOM_tribice: comdetord.DETORDCOM_TRIBICE,
  //       p_DETORDCOM_ice: Number(comdetord.DETORDCOM_ICE),
  //       p_DETORDCOM_porceice: Number(comdetord.DETORDCOM_PORCEICE),
  //       p_DETORDCOM_baseiva: Number(comdetord.DETORDCOM_BASEIVA),
  //       p_DETORDCOM_baseice: Number(comdetord.DETORDCOM_BASEICE),
  //       p_DETORDCOM_basecero: Number(comdetord.DETORDCOM_BASECERO),
  //       p_DETORDCOM_descuento2: Number(comdetord.DETORDCOM_DESCUENTO2),
  //       p_DETORDCOM_descuento3: Number(comdetord.DETORDCOM_DESCUENTO3),
  //       p_DETORDCOM_VALORDES: Number(comdetord.DETORDCOM_VALORDES),
  //       p_DETORDCOM_PORCEDES: Number(comdetord.DETORDCOM_PORCEDES),
  //       p_ENCREQ_NUMERO: comdetord.ENCREQ_NUMERO,
  //       P_DETREQ_LINEA: Number(comdetord.DETREQ_LINEA),
  //       p_detordcom_cantidadund: 0
  //     },
  //   }, this.confIniciales.httpOptions());
  // }
  insertarDetalle(comdetord: Comdetordcom){
    return this.http.post<any[]>(this.way + '/compras/ordencompra/12bf1dbeb5dd55000', {
      elementos: {
        p_ENCORDCOM_numero: comdetord.ENCORDCOM_NUMERO,
        p_COM_codigo: comdetord.COM_CODIGO,
        p_DETORDCOM_linea: comdetord.DETORDCOM_LINEA,
        p_DETORDCOM_tipodet: comdetord.DETORDCOM_TIPODET,
        p_BOD_codigo: comdetord.BOD_CODIGO,
        p_DETORDCOM_codigo: comdetord.DETORDCOM_CODIGO,
        p_DETORDCOM_descripcion: comdetord.DETORDCOM_DESCRIPCION,
        p_DETORDCOM_unidad: comdetord.DETORDCOM_UNIDAD,
        p_DETORDCOM_cantidad: Number(comdetord.DETORDCOM_CANTIDAD),
        p_DETORDCOM_despacho: Number(comdetord.DETORDCOM_DESPACHO),
        p_DETORDCOM_costo: Number(comdetord.DETORDCOM_COSTO),
        p_DETORDCOM_descuento: Number(comdetord.DETORDCOM_DESCUENTO),
        p_DETORDCOM_total: Number(comdetord.DETORDCOM_TOTAL),
        p_DETORDCOM_tribiva: comdetord.DETORDCOM_TRIBIVA,
        p_DETORDCOM_iva: Number(comdetord.DETORDCOM_IVA),
        p_DETORDCOM_tribice: comdetord.DETORDCOM_TRIBICE,
        p_DETORDCOM_ice: Number(comdetord.DETORDCOM_ICE),
        p_DETORDCOM_porceice: Number(comdetord.DETORDCOM_PORCEICE),
        p_DETORDCOM_baseiva: Number(comdetord.DETORDCOM_BASEIVA),
        p_DETORDCOM_baseice: Number(comdetord.DETORDCOM_BASEICE),
        p_DETORDCOM_basecero: Number(comdetord.DETORDCOM_BASECERO),
        p_DETORDCOM_descuento2: Number(comdetord.DETORDCOM_DESCUENTO2),
        p_DETORDCOM_descuento3: Number(comdetord.DETORDCOM_DESCUENTO3),
        p_DETORDCOM_VALORDES: Number(comdetord.DETORDCOM_VALORDES),
        p_DETORDCOM_PORCEDES: Number(comdetord.DETORDCOM_PORCEDES),
        p_ENCREQ_NUMERO: comdetord.ENCREQ_NUMERO,
        P_DETREQ_LINEA: Number(comdetord.DETREQ_LINEA),
        p_detordcom_cantidadund: 0,
        p_detordcom_poriva:comdetord.DETORDCOM_PORIVA,
        p_tariiva_codigo:comdetord.TARIIVA_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();;
  }
  eliminarDetOrd(detOrd: Comdetordcom): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/ordencompra/7565695daeee380', {
      elementos: {
        p_ENCORDCOM_numero: detOrd.ENCORDCOM_NUMERO,
        p_COM_codigo: '01',
        p_DETORDCOM_linea: detOrd.DETORDCOM_LINEA
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarEnc(encOrd: Comencordcom): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/ordencompra/7565695dd766380', {
      elementos: {
        p_ENCORDCOM_NUMERO: encOrd.ENCORDCOM_NUMERO,
        p_COM_CODIGO: '01',
      },
    }, this.confIniciales.httpOptions());
  }
  obtenerClaveIva(): Observable<any> {
    return this.http.post<any>(this.way + '/compras/ordencompra/30bebaeedb2ab0000000000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
//CDPJ
consulexisEncNotrec(encOrd: Comencordcom) {
  return this.http.post<any[]>(this.way + '/compras/ordencompra/198bf3d5eab6e', {
    elementos: {
      p_ENCORDCOM_NUMERO: encOrd.ENCORDCOM_NUMERO,
      p_COM_CODIGO: '01',
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
consulexisEncFacpro(encOrd: Comencordcom) {
  return this.http.post<any[]>(this.way + '/compras/ordencompra/3317e7aae', {
    elementos: {
      p_ENCORDCOM_NUMERO2: encOrd.ENCORDCOM_NUMERO,
      p_COM_CODIGO: '01',
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
consulexisEncLiquiPro(encOrd: Comencordcom) {
  return this.http.post<any[]>(this.way + '/compras/ordencompra/662fcf57ae', {
    elementos: {
      p_ENCORDCOM_NUMERO3: encOrd.ENCORDCOM_NUMERO,
      p_COM_CODIGO: '01',
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
encontrarArtCntaySer(strArtCodigo, strtipo) {
  return this.http.post<any[]>(this.way + '/compras/ordencompra/75d98bf76adab8000000', {
    elementos: {
      ArtCodigo: strArtCodigo,
      strTIPO: strtipo
    }
  }, this.confIniciales.httpOptions()).toPromise();
}
encontrarBodCodigo(bodcodigo) {
  return this.http.post<any[]>(this.way + '/compras/ordencompra/1d7662fddab6bc000000000', {
    elementos: {
      BodCodigo: bodcodigo
      
    }
  }, this.confIniciales.httpOptions()).toPromise();
}
encontrarArttribIva(strArtCodigo,strtipo) {
  return this.http.post<any[]>(this.way + '/compras/ordencompra/75d98bf76adab800000000000', {
    elementos: {
      ArtCodigo: strArtCodigo,
      strTIPOI: strtipo
    }
  }, this.confIniciales.httpOptions()).toPromise();
}
EncontrarProvedor(strprovecodigo) {
  return this.http.post(this.way + '/compras/ordencompra/ebb317eed5bcf00000000', {
    elementos: {
      pro_codigo: strprovecodigo,
      
    }
  }, this.confIniciales.httpOptions()).toPromise();
}
//CDPJ
  insertarUsuidentificacion(usuario): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/ordencompra/12bf1dbefb9e93000', {
      elementos: {
        USUIDENTIFICACION: usuario.USUIDENTIFICACION,
        ENCORDCOM_NUMERO: usuario.ENCORDCOM_NUMERO
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerNombrePro(proCodigo: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/ordencompra/c2fb7c597b76780', {
      elementos: {
        PRO_CODIGO: proCodigo === undefined ? '' : proCodigo
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerOpcionesDrop(tipoRef): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/ordencompra/185f71964b176f000', {
      elementos: {
        ref_tipo: tipoRef,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  ejecutarComando(strNumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/ordencompra/e9b99eeab6cc5800', {
      elementos: {
        ord_numero: strNumero
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerClave(): Observable<any> {
    return this.http.post<any>(this.way + '/compras/ordencompra/c2faebbb6caac00', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  consultarImagen(artCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/ordencompra/198bf256541d7', {
      elementos: {
        ART_CODIGO: artCodigo
      }
    }, this.confIniciales.httpOptions());
  }

  cambiarEstadoOrden(encord: Comencordcom, estado): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/ordencompra/c5596ee754dc6000', {
      elementos: {
        p_ENCORDCOM_numero: encord.ENCORDCOM_NUMERO,
        p_COM_codigo: '01',
        p_ENCORDCOM_estado: estado
      },
    }, this.confIniciales.httpOptions());
  }

  registrosRelacionadosFAC(strNumero): Observable<any[]> {
    return this.http.post<any>(this.way + '/compras/ordencompra/662fcf57aadbd40', {
      elementos: {
        numero: strNumero
      },
    }, this.confIniciales.httpOptions());
  }

  registrosRelacionadosNOTA(strNumero): Observable<any[]> {
    return this.http.post<any>(this.way + '/compras/ordencompra/cc5f9eaf55bbe000', {
      elementos: {
        numero: strNumero
      }
    }, this.confIniciales.httpOptions());
  }


  calcularTotal() {
    let dblValorTotal: number;
    let dblCostoTotalItem = 0;
    let dblValorIceItem = 0;
    let dblBaseIceItem = 0;
    let dblBaseIceTotal = 0;
    let dblValorIceTotal = 0;
    let dblBaseCeroTotal = 0;
    let dblBaseIvaTotal = 0;
    let dblValorIvaTotal = 0;
    let dblValorNeto = 0;
    this.dblPorcIvaPed=Number( this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_PORCEIVA);//CDPJ
    for (let fila = 0; fila < this.detordenesarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      const item = this.detordenesarray[this.init.tabs[this.init.tabindex].indice][fila];
      if (item.DETORDCOM_TIPODET !== '*') {
        if (item.DETORDCOM_CODIGO !== '' && item.DETORDCOM_CODIGO !== null) {
          //dblCostoTotalItem = item.DETORDCOM_CANTIDAD * item.DETORDCOM_COSTO;
          //CDPJ
        let val=10 ** this.decimalesTotalOrd;
        let total=Math.round(item.DETORDCOM_CANTIDAD * val * item.DETORDCOM_COSTO) / val;
        dblCostoTotalItem = Number(total.toFixed(this.decimalesTotalOrd));
        console.log('TOTALITEM',dblCostoTotalItem);
        //CDPJ
          const dblDsct = dblCostoTotalItem * (item.DETORDCOM_DESCUENTO / 100);
          dblCostoTotalItem = Number(dblCostoTotalItem) - Number(dblDsct);
          item.DETORDCOM_TOTAL = dblCostoTotalItem; // costo total por línea de detalle
          item.DETORDCOM_TOTAL = Number(item.DETORDCOM_TOTAL).toFixed(this.decimalesTotalOrd);

          // cálculo del ice por línea de detalle
          if (item.DETORDCOM_TRIBICE !== null && item.DETORDCOM_TRIBICE !== '') {
            if (item.DETORDCOM_TRIBICE === 'S') { // si tributa ice
              dblBaseIceItem = item.DETORDCOM_CANTIDAD * item.DETORDCOM_COSTO;
              item.DETORDCOM_BASEICE = dblBaseIceItem;
              if (item.DETORDCOM_PORCEICE > 0) {
                dblValorIceItem = dblBaseIceItem * item.DETORDCOM_PORCEICE / 100;
              } else if (item.DETORDCOM_ICE > 0) {
                dblValorIceItem = item.DETORDCOM_ICE;
              }
              item.DETORDCOM_ICE = dblValorIceItem;
            }
          }
          // càlculo del iva por línea de detalle
          let dblBaseIvaItem = 0;
          let dblValorIvaItem = 0;
          if (item.DETORDCOM_TRIBIVA === 'S') {
            dblBaseIvaItem = dblCostoTotalItem * (1 - Number(this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice]) / 100);
          } else {
            dblBaseIvaItem = 0;
          }
          if (item.DETORDCOM_TRIBIVA === 'S') {
            if (item.DETORDCOM_TRIBICE === 'S') {
              dblBaseIvaItem = dblBaseIvaItem + item.DETORDCOM_ICE;
            }
            item.DETORDCOM_BASECERO = 0;
            dblValorIvaItem = dblBaseIvaItem * Number(item.DETORDCOM_PORIVA) / 100; // IVA 12% DEFECTO antes this.dblPorcIvaPed

            item.DETORDCOM_IVA = dblValorIvaItem;
          } else {
            item.DETORDCOM_BASECERO = 0;
            dblBaseIvaItem = 0;
            item.DETORDCOM_BASECERO = (dblCostoTotalItem * (1 - Number(this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice]) / 100));
            item.DETORDCOM_IVA = 0;
          }
          item.DETORDCOM_BASEIVA = dblBaseIvaItem;
        }
      }
    }
    // TOTALES DEL DOCUMENTO
    for (let fila2 = 0; fila2 < this.detordenesarray[this.init.tabs[this.init.tabindex].indice].length; fila2++) {
      dblValorNeto = Number(dblValorNeto) + Number(this.detordenesarray[this.init.tabs[this.init.tabindex].indice][fila2].DETORDCOM_TOTAL);
      if (this.detordenesarray[this.init.tabs[this.init.tabindex].indice][fila2].DETORDCOM_TIPODET !== '*') {
        if (this.detordenesarray[this.init.tabs[this.init.tabindex].indice][fila2].DETORDCOM_TRIBICE === 'S') {
          dblValorIceTotal = dblValorIceTotal + Number(this.detordenesarray[this.init.tabs[this.init.tabindex].indice][fila2].DETORDCOM_ICE);
          dblBaseIceTotal = dblBaseIceTotal + Number(this.detordenesarray[this.init.tabs[this.init.tabindex].indice][fila2].DETORDCOM_BASEICE);
        }
      }
      dblBaseCeroTotal = dblBaseCeroTotal + Number(this.detordenesarray[this.init.tabs[this.init.tabindex].indice][fila2].DETORDCOM_BASECERO);
      dblBaseIvaTotal = dblBaseIvaTotal + Number(this.detordenesarray[this.init.tabs[this.init.tabindex].indice][fila2].DETORDCOM_BASEIVA);
      dblValorIvaTotal = dblValorIvaTotal + Number(this.detordenesarray[this.init.tabs[this.init.tabindex].indice][fila2].DETORDCOM_IVA);
    }
    for (let fila = 1; fila <= this.detordenesarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      this.detordenesarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETORDCOM_CANTIDAD = Number(this.detordenesarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETORDCOM_CANTIDAD).toFixed
        (this.decimalesCantidadOrd);
      this.detordenesarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETORDCOM_COSTO = Number(this.detordenesarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETORDCOM_COSTO).toFixed(this.decimalesPrecioOrd);
      this.detordenesarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETORDCOM_TOTAL = Number(this.detordenesarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETORDCOM_TOTAL).toFixed(this.decimalesTotalOrd);
      this.detordenesarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETORDCOM_DESCUENTO = Number(this.detordenesarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETORDCOM_DESCUENTO).toFixed(this.confIniciales.getNumDecSist());
    }
    dblValorTotal = Number(dblValorNeto) + Number(dblValorIvaTotal) + Number(dblValorIceTotal) - Number(this.dblValorDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice]);
    this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_TOTALNETO = String(dblValorNeto);
    this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_TOTALNETO = Number(this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_TOTALNETO).toFixed
      (this.confIniciales.getNumDecSist());
    this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_VALORIVA = String(dblValorIvaTotal);
    this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_VALORIVA = Number(this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_VALORIVA).toFixed(this.confIniciales.getNumDecSist());
    this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_VALORICE = String(dblValorIceTotal);
    this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_VALORICE = Number(this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_VALORICE).toFixed(this.confIniciales.getNumDecSist());
    this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_BASEICE = String(dblBaseIceTotal);
    this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_BASEICE = Number(this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_BASEICE).toFixed(this.confIniciales.getNumDecSist());
    this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_BASECERO = String(dblBaseCeroTotal);
    this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_BASECERO = Number(this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_BASECERO).toFixed(this.confIniciales.getNumDecSist());
    this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_BASEIVA = String(dblBaseIvaTotal);
    this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_BASEIVA = Number(this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_BASEIVA).toFixed(this.confIniciales.getNumDecSist());
    this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_TOTAL = String(dblValorTotal);
    this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_TOTAL = Number(this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_TOTAL).toFixed(this.confIniciales.getNumDecSist());
    this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_PORCEDES = this.dblPorcDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice];
    this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_PORCEDES = Number(this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_PORCEDES).toFixed(this.confIniciales.getNumDecSist());
    this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_VALORDES = this.dblValorDescGlbOrdarray[this.init.tabs[this.init.tabindex].indice];
    this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_VALORDES = Number(this.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_VALORDES).toFixed(this.confIniciales.getNumDecSist());
    // colocar los valores en los labels del pie de la factura
  }
  //CDPJ
  obtenernotrec(ENCORDCOM_NUMERO) {
    return this.http.post<any>(this.way + '/compras/ordencompra/185f5d776ef8ef000', {
      elementos: {
        ENCORDCOM_NUMERO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerfacturaprov(ENCORDCOM_NUMERO) {
    return this.http.post<any>(this.way + '/compras/ordencompra/30bebaeedbd4ce000000000', {
      elementos: {
        ENCORDCOM_NUMERO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  verificaEstadoOrdcom(ENCORDCOM_NUMERO): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/ordencompra/fbb727c98a773800000000000', {
      elementos: {
        p_ENCORDCOM_NUMERO: ENCORDCOM_NUMERO,
        p_COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  obtenerbasesiva(numero) {
    return this.http.post<any>(this.way + '/compras/ordencompra/107756ae3b92fb00000000', {
      elementos: {
        p_ordcom_numero:numero
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizabasesiva(numero) {
    return this.http.post<any>(this.way + '/compras/ordencompra/a6771b6b3165a80000000', {
      elementos: {
        p_encordcom_numero:numero,
        p_com_codigo:'01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
}
