<a href="{{src}}" >Descargar</a>
<p-button (click)="abrir()">Descargar</p-button>
<p-button (click)="descargar()">Descargar 2</p-button>

<div class="pdf-container">
	<pdf-viewer [src]="src"
				style="display: block; height: 100vh; width: 100%"
				[original-size]="false"
	></pdf-viewer>
</div>
<span>Web</span>
<div class="pdf-container">
	<pdf-viewer [src]="src1"
				style="display: block; height: 100vh; width: 100%"
				[original-size]="false"
	></pdf-viewer>
</div>

