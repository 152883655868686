import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Invcalpreform} from '../invinterfaces/invcalpreform';
import {Observable} from 'rxjs';
import {Invcalprepre} from '../invinterfaces/Invcalprepre';
import {log, isNullOrUndefined} from 'util';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class InvactpreciosService {
  
  get formulas(): Invcalpreform[] {
    return this._invcalpreform;
  }
  
  set formulas(value: Invcalpreform[]) {
    this._invcalpreform = value;
  }
  
  get precios(): Invcalprepre[] {
    return this._invcalprepre;
  }
  
  set precios(value: Invcalprepre[]) {
    this._invcalprepre = value;
  }
  

  private _invcalpreform: Invcalpreform[];
  private _invcalprepre: Invcalprepre[];
  public way: string;
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              private confIniciales: ConfInicialesService,
              public usuario: Usuario) {
  
    this.way = sessionStorage.getItem('way');
  }
  
  
  obtenerGruposArticulos(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/actualizarprecios/bpt02pz26a1rs07', {      
      elementos: {},
    }, this.confIniciales.httpOptions());

  }
  
  obtenerFormulas(): Observable<any[]> {
    
    return this.http.post<any[]>(this.way + '/inventario/actualizarprecios/ookr4t6e18rbwoz', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  ermaeforprecioprecod(strFORPRE_CODIGO){
   
    return this.http.post<any[]>(this.way + '/inventario/actualizarprecios/44cb7cylhxfzlg2', {      
      elementos: {
        FORPRE_CODIGO:strFORPRE_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }
  insertarFormula(formula: Invcalpreform): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/inventario/actualizarprecios/eji046xmrxrsxej', {      
      elementos: {
        p_forpre_codigo: formula.FORPRE_CODIGO,
        p_com_codigo: '01',
        p_forpre_nombre: formula.FORPRE_NOMBRE,
        p_forpre_nomsql: formula.FORPRE_NOMSQL,
        p_forpre_estado: 1,
      },
    }, this.confIniciales.httpOptions());
  }
  
  eliminarFormula(formula: Invcalpreform): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/inventario/actualizarprecios/o5qxvx1d5xoh7jp', {      
      elementos: {
        p_forpre_codigo: formula.FORPRE_CODIGO,
        p_com_codigo: '01',
      },
    }, this.confIniciales.httpOptions());
  }
  
  consultarListasGrupo(grupcodigo: string): Observable<any[]> {
   if(isNullOrUndefined(grupcodigo)){
    grupcodigo="";
   }
    return this.http.post<any[]>(this.way + '/inventario/actualizarprecios/7316u05tcspqj2', {      
      elementos: {
        p_Grupo: grupcodigo,
        p_COM_CODIGO: '01',
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  cargarTablaPrecios(strfecha: string, strhora: string, strgrupcodigo: string): Observable<any[]> {
 
    return this.http.post<any[]>(this.way + '/inventario/actualizarprecios/e9hklf01g12ncnd', {      
      elementos: {
        fecha:strfecha,
        hora:strhora,
        grupcodigo:strgrupcodigo
      },
    }, this.confIniciales.httpOptions());
  }
  
  cargarTablaPreciosFiltrada(strfecha: string, strhora: string, strgrupcodigo: string): Observable<any[]> {
  
   
    return this.http.post<any[]>(this.way + '/inventario/actualizarprecios/0uf6zm7l1cy2j5l', {      
      elementos: {
        fecha:strfecha,
        hora:strhora,
        grupcodigo:strgrupcodigo
      },
    }, this.confIniciales.httpOptions());
  }
  
  actualizarPrecioArt(artcod , unicod, artprecod , artprepre ) {
   
    return this.http.post<any[]>(this.way + '/inventario/actualizarprecios/u5vgtq0ycbgryaq', {      
      elementos: {
        p_art_codigo : artcod,
        p_com_codigo : '01',
        p_artpre_codigo : artprecod ,
        p_artpre_precio : artprepre,
        p_uni_codigo : unicod,
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  actualizarTempPrecioArt(artcod , unicod, artprecod , artprepre ) {
  
    return this.http.post<any[]>(this.way + '/inventario/actualizarprecios/351qdm13vaix5fk', {      
      elementos: {
        p_art_codigo : artcod,
        p_com_codigo : '01',
        p_artpre_codigo : artprecod ,
        p_artpre_precio : artprepre,
        p_uni_codigo : unicod,
      },
    }, this.confIniciales.httpOptions());
  }
  
  actualizarTempPrecioArtP(artcod , unicod, artprecod , artprepre ): Promise<any[]> {
 
    return this.http.post<any[]>(this.way + '/inventario/actualizarprecios/qticlcnin2g6qrc', {      
      elementos: {
        p_art_codigo : artcod,
        p_com_codigo : '01',
        p_artpre_codigo : artprecod ,
        p_artpre_precio : artprepre,
        p_uni_codigo : unicod,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  
  ejecutarFormula(strartcod, strformula, strartprecod, strunicod): Promise<any[]> {
    
    return this.http.post<any[]>(this.way + '/inventario/actualizarprecios/yoqlhlflosaid0x', {      
      elementos: {
       
        formula:strformula,
        artprecod: strartprecod,
        unicod:strunicod,
        artcod:strartcod
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  
}
