<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="invactprecKey"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarActPrec" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonGuardar]="btnGuardar"
						 [botonRegresar]="btnCancelar"
						 [barraBotonesDesplaz]="true"
						 [botonVisto]="false" [barraBotones3Alt]="false"
						 (signal)="manejarSenales($event)">
		</app-invmaebarra>
	</div>

	<div class="p-col-12" style="margin-top: -20px">
		<p-tabView [(activeIndex)]="activeIndex" (activeIndexChange)="cambiarIndices()">
			<p-tabPanel header="Precios" [style]="{'width':'100%', 'height':'calc(100vh - 250px)'}"
						[disabled]="btnNuevoGrid">
				<div class="divgrups">
					<div class="p-grid" style="margin-top: 10px; margin-bottom: 10px">
						<div class="p-col-2">
							<span>	Grupo Artículos: </span>
						</div>
						<div class="p-col-2">
							<p-dropdown id="grup_articulos" [style]="{'width': '300px','height':'25px'}"
										[panelStyle]="{'width':'300px'}"
										[(ngModel)]="grupArtSeleccionada"
										[options]="opcionesGrupArt"
										(ngModelChange)="obtenerListaPrecios(grupArtSeleccionada)"
							>
							</p-dropdown>
						</div>
						<div class="p-col-3"></div>
						<div class="p-col-1">

							<div style="height: 50px ; overflow: scroll ; overflow-x: hidden ; border: 1px solid #005594  ">

								<table>
									<tr *ngFor="let i of opcionesListaPreciosCheck">
										<td>
											<p-checkbox binary="true"
														[(ngModel)]="i.value"
														(click)="agregarListaAct(i.label , i.value)"></p-checkbox>
										</td>
										<td>
											{{i.label}}
										</td>
									</tr>
								</table>

							</div>

						</div>
						<div class="p-col-4"></div>
						<div class="p-col-2">
							<p-checkbox binary="true" label="Seleccionar Todos" [(ngModel)]="selectAll"
										(click)="seleccionarTodos()"></p-checkbox>
						</div>
						<div class="p-col-10"></div>

					</div>
				</div>
				<app-aggridsaci
						[width]=""
						[height]="largo"
						[enableSorting]="true"
						[enableFilter]="true"
						[frameworkComponents]="frameworkComponents"
						[animateRows]="true"
						[rowSelection]="rowSelection"
						[columnDefs]="columnDefsActPrecios2"
						[defaultColDef]="defaultColPrecios"
						[rowData]="arregloDinPrecios"
						[rowStyle]="rowStyle"
						[singleClickEdit]="false"
						[botones]="false"
						(cambios)="cambio2($event)"
				></app-aggridsaci>
			</p-tabPanel>

			<p-tabPanel header="Fórmula" [style]="{'width':'100%', 'height':'calc(100vh - 250px)'}">
				<div class="divgrups">
					<div class="p-grid" style="margin-top: 10px; margin-bottom: 5px ; margin-left: 50px">
						<div class="p-col-2">
							<span>	Fórmulas Existentes </span>
						</div>
						<div class="p-col-10"></div>
						<div class="divgrups">
							<div class="p-grid" style="margin-top: 5px; margin-bottom: 5px ; margin-left: 20px">
								<div class="p-col-8">
									<app-aggridsaci [width]=""
													[height]="largo2"
													[enableSorting]="true"
													[enableFilter]="true"
													[frameworkComponents]="frameworkComponents"
													[animateRows]="true"
													[rowSelection]="rowSelection"
													[rowData]="actpreService.formulas"
													[columnDefs]="columnDefsFormulas"
													[defaultColDef]="defaultColDefFormulas"
													[rowStyle]="rowStyle"
													[singleClickEdit]="false"
													[botones]="true"
													[mostrarGuardar]="false"
													[botonNuevo]="btnNuevoGrid"
													[botonGuardar]="btnGuardarGrid"
													[botonBorrar]="btnBorrarGrid"
													[botonRegresar]="btnCancelarGrid"
													(selected)="seleccionFormulas($event)"
													(cambios)="cambio($event)"
													align="left"
													style="justify-items: end"
									></app-aggridsaci>
								</div>
								<div class="p-col-4">
									<div class="p-col-12">
										<span>	VARIABLES A USAR </span>
									</div>
									<div class="p-col-12">
										<span>	PVPLista - Precio Actual de la Lita </span>
									</div>
									<div class="p-col-12">
										<span>	UCC - Último Costo de Compra </span>
									</div>
									<div class="p-col-12">
										<span>	UCNR - Último Costo de la Nota de Recepción </span>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>

				<div class="divgrups">
					<div class="p-grid" style="margin-top: 10px; margin-bottom: 10px">
						<div class="p-col-2">
							<span>	Aplicar la Fórmula </span>
						</div>
						<div class="p-col-10"></div>
						<div class="p-grid" style="margin-top: 10px; margin-bottom: 5px ; margin-left: 50px">
							<div class="p-col-2">
								<span>	Fórmula:  </span>
							</div>
							<div class="p-col-4">
								<span>	{{formulaSeleccionada.FORPRE_NOMSQL}} </span>
							</div>
							<div class="p-col-1"></div>
							<div class="p-col-3">
								<button class="aceptar" pButton
										(click)="ejecutarFormulaPromise()"
										style="background-image: url(../../../assets/images/iconos/btnAceptar.png); line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; margin-left: 25px">
									Calcular Fórmula
								</button>
							</div>
							<div class="p-col-2"></div>
							<div class="p-col-2">
								<span>	Lista de Precios: </span>
							</div>
							<div class="p-col-2">
								<p-dropdown id="lista_precios" [style]="{'width': '300px','height':'90%'}"
											[panelStyle]="{'width':'300px'}"
											[(ngModel)]="listPrecSeleccionada"
											[options]="opcionesListaPrecios"
								>
								</p-dropdown>
							</div>
							<div class="p-col-8"></div>
						</div>

					</div>
				</div>


			</p-tabPanel>
		</p-tabView>
	</div>
</div>

