<button type="button" style="width: 50px; height: 50px"
		 (click)="generar()"></button>

<div class="container">
	<h2 id="header">
		<strong>Annual Sales</strong>
		<small class="text-muted">Jan 2015 - Dec 2015</small>
	</h2>

	<div class="p-grid">
		<div class="p-col-12">
			<div class="card shadow">
				<h4 class="card-header">Revenue <span class="tag tag-success" id="revenue-tag">$15,341,110</span></h4>
				<div class="card-block">
					<div id="revenue-column-chart"></div>
				</div>
			</div>
		</div>
	</div> <!-- row -->

	<div class="p-grid">
		<div class="p-col-12 p-md-12 p-lg-6">
			<div class="card shadow">
				<h4 class="card-header">Product Wise Revenue</h4>
				<div class="card-block">
					<div id="products-revenue-pie-chart"></div>
				</div>
			</div>
		</div>
		<div class="p-col-12 p-md-12 p-lg-6">
			<div class="card shadow">
				<h4 class="card-header">Orders</h4>
				<div class="card-block">
					<div id="orders-spline-chart"></div>
				</div>
			</div>
		</div>
	</div>

</div> <!-- container -->
