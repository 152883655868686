<p-toast [style]="{marginTop: '30px'}" position="top-center" key="contipodiario"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="segOrgDialog" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
						 [botonRegresar]="btnRegresar" [botonPrimero]="btnPrimero" [botonAnterior]="btnAnterior"
						 [botonSiguiente]="btnSiguiente" [botonUltimo]="btnUltimo" [barraBotonesCRUD]="false"
						 [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"
						 [barraBotones5]="true"
						 [botonBuscar]="false"
						 (signal)="manejarSenalest($event)"></app-invmaebarra>


	</div>
<!-- 	<div class="p-col-12">
		<p-tabView>
			<p-tabPanel header="Tipo de Diario">
				<div class="divgrups">
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-2">
									<span>Código</span>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<label for="TIPDIA_CODIGO"></label>
									<input id="TIPDIA_CODIGO" type="text" pInputText size="14"
										   [(ngModel)]="tipodiarioSeleccionado.TIPDIA_CODIGO" [maxLength]="4"
										   (keydown.enter)="setFocus($event)" (keydown)="cambio()" autocomplete="off">

								</div>
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<span>Mandatorio</span>
								</div>
								<div class="p-col-3">
									<input type="text" pInputText size="1"
										   [(ngModel)]="tipodiarioSeleccionado.TIPDIA_MANDATORIO" [disabled]="inputNum"
										   (keydown.enter)="setFocus($event)" (keydown)="cambio()"
										   autocomplete="off">
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-7"></div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -25px">
							<div class="p-grid">
								<div class="p-col-2">
									<span>Nombre</span>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-8">
									<label for="TIPDIA_NOMBRE"></label>
									<input id="TIPDIA_NOMBRE" type="text" pInputText size="50"
										   [(ngModel)]="tipodiarioSeleccionado.TIPDIA_NOMBRE" [maxLength]="35"
										   (keydown.enter)="setFocus($event)" (keydown)="cambio()"
										   autocomplete="off">
								</div>
								<div class="p-col-1"></div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -12px; margin-bottom: -15px">
							<div class="p-grid">
								<div class="p-col-2">
									<span> No. Actual</span>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-3 text-left">
									<label for="TIPDIA_NROACTUAL"></label>
									<input id="TIPDIA_NROACTUAL" type="text" pInputText (input)="cambio()"
										   [(ngModel)]="tipodiarioSeleccionado.TIPDIA_NROACTUAL" [maxLength]="10"
										   (keydown.enter)="setFocus($event)" (keydown)="cambio()"
										   autocomplete="off">
								</div>
							</div>
						</div>
					</div>
				</div>
			</p-tabPanel>
		</p-tabView>
	</div>
</div> -->
<!--<p-dialog header="Búsqueda de datos" [(visible)]="displayDialogBus2" [responsive]="true"-->
<!--		  [transitionOptions]="'0ms'"-->
<!--		  [modal]="false"-->
<!--		  [style]="{width: '70vw', height: '70vh'}">-->
<!--	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloBus"-->
<!--					 (opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>-->
<!--</p-dialog> -->
 <div style="overflow: hidden" class="p-col-12">
	<p-tabView>
		<p-tabPanel header="Tipos de diarios">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="false"
							[enableFilter]="true"
							[rowData]="conmaetipodiariov"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefConMaeTip"
							[defaultColDef]="defaultColDefconmaetipodiario"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)=selectConMaeTipo($event)
							(cambios)="cambioModulo($event)"

			></app-aggridsaci>
		</p-tabPanel>
	</p-tabView>
</div> 
<!-- <p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBus"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [closable]="false"
		  [style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>-->


<!-- <p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog> -->


