import {Component, OnInit, ViewChild} from '@angular/core';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {InvtrnajustesService} from '../invservicios/invtrnajustes.service';
import {InvDetAjuInvNeg, InvTrnAjustes} from '../invinterfaces/invtrnajustes';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {InvbusquedaService} from '../invservicios/invbusqueda.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {Usuario} from '../../usuario';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {DatePipe} from '@angular/common';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {CxpDetCuota} from '../../cxp/cxpinterfaces/cxpenccuota';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';

@Component({
  selector: 'app-invtrnajustes',
  templateUrl: './invtrnajustes.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class InvtrnajustesComponent implements OnInit {
  
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  /*btnNuevo: boolean;
  btnGuardar: boolean;
  btnEliminar: boolean;
  btnBuscar: boolean;*/
  
  // Tabla
  public frameworkComponents;
  rowStyle;
  defaultColTrnAjustes;
  defaultColTrnAjustesAut;
  rowSelection = 'single';
  largo: string;
  
  
  indicador: any;
  detalleAjusteSel: InvDetAjuInvNeg = {};
  
  lblExistencia; lblCostoPromedio; lblUltCostoCompra; lblUltCostoProduccion;
  
  // Busqueda
  displayDialogBus: boolean;
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  where: string;
  opcion: string;
  
  columnaActual = '';
  selectTab = 0;
  disabledTab1 = false;
  
  permisos: SegMaePermiso;
  displayAsiContable: boolean;
  NumAsiRegCont: string;
  
  FechaRegAct;
  HoraRegAct;
  
  columnDefstrnAjustes = [
    { // 0
      headerName: 'Bodega', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 100,
      editable: (params) => {
        const edit = this.isEdit(params.data);
        return edit;
      },
      // editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 25,
            opcionbuscar: true,
            tienecalculos: true,
          }
        }
    },
    { // 0
      headerName: 'Artículo', field: 'ART_CODIGO', cellEditor: 'cellPrueba', width: 100,
      editable: (params) => {
        const edit = this.isEdit(params.data);
        return edit;
      },
      // editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 25,
            opcionbuscar: true,
            tienecalculos: true,
          }
        }
    },
    { // 1
      headerName: 'Nombre', field: 'ART_NOMBRE', cellEditor: 'cellPrueba', width: 300,
      editable: false,
    },
    { // 2
      headerName: 'Existencia Act.', field: 'ART_EXISTENCIA', cellEditor: 'cellPrueba', width: 100,
      editable: false,
    },
    { // 3
      headerName: 'Costo Total', field: 'ART_COSTO', cellEditor: 'cellPrueba', width: 100,
      editable: false,
    },
    { // 4
      headerName: 'Costo Ajuste', field: 'COSTO_AJUSTE', cellEditor: 'cellPrueba', width: 120, cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 2,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 25,
            tienecalculos: true
          }
        }
      
    },
    { // 5
      headerName: 'Total Ajuste', field: 'TRNART_COSTOT', cellEditor: 'cellPrueba', width: 100,
      editable: false,
    },
    { // 6
      headerName: 'Cuenta Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 25,
            opcionbuscar: true,
            tienecalculos: true,
          }
        }
    },
    { // 7
      headerName: 'Centro C.', field: 'CEN_CODIGO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 0,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 25,
            opcionbuscar: true,
            tienecalculos: true
          }
        }
    }
  ];
  
  columnDefstrnAjustesAut = [
    { // 0
      headerName: 'Artículo', field: 'ART_CODIGO', cellEditor: 'cellPrueba', width: 130, editable: false,
    },
    { // 1
      headerName: 'Nombre', field: 'ART_NOMBRE', cellEditor: 'cellPrueba', width: 400, editable: false,
    },
    { // 2
      headerName: 'Fecha', field: 'TRNART_FECHAES', cellEditor: 'cellPrueba', width: 120, editable: true,
    },
    { // 3
      headerName: 'Hora', field: 'TRNART_HORA', cellEditor: 'cellPrueba', width: 90, editable: false,
    },
    { // 4
      headerName: 'Número', field: 'TRNART_NUMERO', cellEditor: 'cellPrueba', width: 130,  editable: false,
    },
    { // 5
      headerName: 'Costo Total', field: 'TRNART_COSTOT', cellEditor: 'cellPrueba', width: 120, cellStyle: {textAlign: 'right'},
      editable: false,
    }
  ];
  
  constructor(public invTrnAjusteService: InvtrnajustesService, private init: NuevoComponentService,
              public busquedaSer: InvbusquedaService, private  auditoriaGralService: AuditoriagralService, private permisosService: PermisosService,
              private message: MessageService, public usuario: Usuario, private errorService: ErroresBaseDatosService,
              private datePipe: DatePipe, private confirmationService: ConfirmationService,
              private confIniciales: ConfInicialesService) {
    this.agTable();
    this.invTrnAjusteService.detTrnAjustes[this.indicador] = [];
    this.invTrnAjusteService.detTrnAjustesAut[this.indicador] = [];
    this.invTrnAjusteService.encTrnAjustes[this.indicador] = {};
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    
    this.defaultColTrnAjustes = {
      editable: true,
      width: 100,
      objeto: 'invtrnajustes',
      resizable: true
    };
    this.defaultColTrnAjustesAut = {
      editable: true,
      width: 100,
      objeto: 'invtrnajustesaut',
      resizable: true
    };
  }
  
  async ngOnInit() {
    this.getPermisos();
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.invTrnAjusteService.detTrnAjustes[this.indicador] = [];
    this.invTrnAjusteService.detTrnAjustesAut[this.indicador] = [];
    this.invTrnAjusteService.encTrnAjustes[this.indicador] = {};
    this.invTrnAjusteService.encTrnAjustes[this.indicador] = {
      ASI_NRO: '',
      COMPROBANTE: '',
      FECHA: new Date(),
      HORA: new Date()
    };
    
    console.log(this.invTrnAjusteService.encTrnAjustes[this.indicador]);
    this.largo = '450';
    this.activaBotones(false, true, true, false, false);
    
    const rs = await this.invTrnAjusteService.getDecimales('frmINV_TRNAJUSTES');
    this.invTrnAjusteService.configDecimales = rs;
    
    this.columnDefstrnAjustes[4].cellEditorParams = {
      values: {
        mayusculas: false,
        numeros: true,
        alphabetic: false,
        saltoslinea: 1,
        newrow: false,
        numerodecimales: this.invTrnAjusteService.obtenerConfig('COSTO', 'kardex'),
        obligatorio: false,
        fecha: false,
        tamanocelda: 25,
        tienecalculos: true
      }
    };
    this.aggrid.refreshColumnDefs();
    // await this.listarDatos('');
    await this.listarDatosAut('');
    this.invTrnAjusteService.detTrnAjustes[this.indicador] = [];
    this.activaBotonesDet(false, false, true);
    
  }
  
  async getPermisos() {
    console.log(this.init.tabs[this.init.tabindex - 1]);
    console.log(this.init.tabs[this.init.tabindex - 1].component);
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisos = data[0];
        } else {
          this.permisos = null;
        }
      } else {
        this.permisos = null;
      }
    } catch (e) {
      this.permisos = null;
      this.mensajeAlerta('error', 'Error', 'No se pudo obtener los permisos del formulario');
    }
  }
  
  activaBotones(bolNuevo, bolGuardar, bolCancelar, bolEliminar, bolBuscar) {
    this.invTrnAjusteService.btnNuevo[this.indicador] = bolNuevo;
    this.invTrnAjusteService.btnBuscar[this.indicador] = bolBuscar;
    this.invTrnAjusteService.btnGuardar[this.indicador] = bolGuardar;
    this.invTrnAjusteService.btnEliminar[this.indicador] = bolEliminar;
  }
  
  activaBotonesDet(bolNuevo, bolEliminar, bolCancelar) {
    this.invTrnAjusteService.btnNuevoDet[this.indicador] = bolNuevo;
    this.invTrnAjusteService.btnEliminarDet[this.indicador] = bolEliminar;
    this.invTrnAjusteService.btnCancelarDet[this.indicador] = bolCancelar;
  }
  
  async manejarSenales(valor) {
    if (valor === 'Buscar') {
      this.buscarRegistro();
    }
    if (valor === 'Nuevo') {
      this.nuevoRegistro();
    }
    if (valor === 'Guardar') {
      this.guardarDocumento();
    }
    if (valor === 'Borrar') {
      this.eliminarRegistro();
    }
    if (valor === 'Cancelar') {
      if (this.invTrnAjusteService.encTrnAjustes[this.indicador].COMPROBANTE === '') {
        this.limpiarDatos();
        document.getElementById('divPrincipal').style.pointerEvents = 'none';
      } else {
        await this.mostrarEncabezado(this.invTrnAjusteService.encTrnAjustes[this.indicador].COMPROBANTE);
        await this.listarDatos(this.invTrnAjusteService.encTrnAjustes[this.indicador].COMPROBANTE);
        document.getElementById('divPrincipal').style.pointerEvents = 'all';
      }
      this.disabledTab1 = false;
      this.activaBotones(false, true, true, false, false);
    }
    
    if (valor === 'Asiento') {
      if (this.invTrnAjusteService.encTrnAjustes[this.indicador].COMPROBANTE !== ''
        && this.invTrnAjusteService.btnGuardar[this.indicador] === true) {
        this.generarAsiento();
        this.mensajeAlerta('success', 'Información', 'Asiento generado exitosamente...!!! ');
      }
    }
    
    if (valor === 'AsientoVer') {
      if (this.invTrnAjusteService.btnGuardar[this.indicador] === true) {
        this.verAsientoContable();
      }
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
  async generarAsiento() {
    try {
      await this.invTrnAjusteService.generarAsiento();
      const rs = await this.invTrnAjusteService.encontrarAsiNro(this.invTrnAjusteService.encTrnAjustes[this.indicador].COMPROBANTE);
      this.invTrnAjusteService.encTrnAjustes[this.indicador].ASI_NRO = rs === null ? '' : rs[0].ASI_NRO;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso guardar kardex no termino satisfactoriamente.... ! ' + mensaje);
    }
  }
  
  async guardarDocumento() {
    this.eliminarLineaBlanco();
    if (this.invTrnAjusteService.encTrnAjustes[this.indicador].COMPROBANTE === '') {
      if (await this.verificarDatosEncDetTrn()) {
        if (await this.guardarEncDoc()) {
          if (await this.guardarDetDoc()) {
            let indice = 0;
            for (indice = 0; indice < this.invTrnAjusteService.detTrnAjustes[this.indicador].length; indice++) {
              const item  = this.invTrnAjusteService.detTrnAjustes[this.indicador][indice];
              try {
                await this.invTrnAjusteService.insertarTrnKardex(item);
                this.activaBotones(false, true, false, false, false);
                this.activaBotonesDet(false, false, true);
              } catch (err) {
                let mensaje: string;
                mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
                this.mensajeAlerta('error', 'Error', '! Proceso guardar kardex no termino satisfactoriamente.... ! ' + mensaje);
                return false;
              }
            }
            this.disabledTab1 = false;
            this.mensajeAlerta('success', 'Información', 'Registro guardado exitosamente...!!!');
            this.generarAsiento();
          }
        }
      }
    } else {
      if (await this.verificarDatosActEncDetTrn()) {
        if (await this.actualizarEncDoc()) {
          if (await this.eliminarTrnKardex()) {
            if (await this.actualizarDetDoc()) {
              if (await this.guardarDetDoc()) {
                let indice = 0;
                for (indice = 0; indice < this.invTrnAjusteService.detTrnAjustes[this.indicador].length; indice++) {
                  const item  = this.invTrnAjusteService.detTrnAjustes[this.indicador][indice];
                  try {
                    await this.invTrnAjusteService.insertarTrnKardex(item);
                    this.activaBotones(false, true, false, false, false);
                    this.activaBotonesDet(false, false, true);
                  } catch (err) {
                    let mensaje: string;
                    mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
                    this.mensajeAlerta('error', 'Error', '! Proceso guardar kardex no termino satisfactoriamente.... ! ' + mensaje);
                    return false;
                  }
                }
                await  this.generarAsiento();
                this.disabledTab1 = false;
                this.mensajeAlerta('success', 'Información', 'Registro actualizado exitosamente...!!!');
              }
            }
          }
        }
      }
      
    }
  }
  
  
  async actualizarDetDoc() {
    try {
      await this.invTrnAjusteService.actualizarDetDoc(this.invTrnAjusteService.encTrnAjustes[this.indicador].COMPROBANTE);
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso eliminar detalle no termino satisfactoriamente.... ! ' + mensaje);
      return false;
    }
    return true;
  }
  
  async eliminarTrnKardex() {
    for (const item of this.invTrnAjusteService.detTrnAjustes[this.indicador]) {
      try {
        await this.invTrnAjusteService.eliminarTrnKardex(item);
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', '! Proceso eliminar kardex no termino satisfactoriamente.... ! ' + mensaje);
        return false;
      }
    }
    return true;
  }
  
  async actualizarEncDoc() {
    try {
      await this.invTrnAjusteService.actualizarEncAjuInvNeg();
      this.FechaRegAct = this.invTrnAjusteService.encTrnAjustes[this.indicador].FECHA;
      this.HoraRegAct = this.invTrnAjusteService.encTrnAjustes[this.indicador].HORA;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso actualizar encabezado no termino satisfactoriamente.... ! ' + mensaje);
      return false;
    }
    return true;
  }
  
  async verificarDatosActEncDetTrn() {
    if (this.invTrnAjusteService.encTrnAjustes[this.indicador].FECHA === '' ||
      this.invTrnAjusteService.encTrnAjustes[this.indicador].FECHA === null) {
      this.mensajeAlerta('error', 'Error', 'Fecha incorrecta');
      return false;
    }
    if (this.invTrnAjusteService.encTrnAjustes[this.indicador].HORA === '' ||
      this.invTrnAjusteService.encTrnAjustes[this.indicador].HORA === null) {
      this.mensajeAlerta('error', 'Error', 'Hora incorrecta');
      return false;
    }
    
    let fecha =  this.FechaRegAct;
    if (fecha.toString().includes('-')) {
      fecha = this.datePipe.transform(fecha, 'dd/MM/yyyy');
    }
    
    let fechaAct =  this.invTrnAjusteService.encTrnAjustes[this.indicador].FECHA;
    if (fechaAct.toString().includes('-')) {
      fechaAct = this.datePipe.transform(fechaAct, 'dd/MM/yyyy');
    }
    
    let strHora = '';
    
    if (this.HoraRegAct.toString().includes('-')) {
      const time = this.HoraRegAct;
      const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
      const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
      strHora = hora + ':' + minutos;
    } else {
      strHora = this.HoraRegAct;
    }
    
    let strHoraAct = '';
    
    if (this.invTrnAjusteService.encTrnAjustes[this.indicador].HORA.toString().includes('-')) {
      const time = this.invTrnAjusteService.encTrnAjustes[this.indicador].HORA;
      const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
      const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
      strHoraAct = hora + ':' + minutos;
    } else {
      strHoraAct = this.invTrnAjusteService.encTrnAjustes[this.indicador].HORA;
    }
    
    try {
      const rs = await this.invTrnAjusteService.verificarexistenciaAjuste(strHoraAct, fechaAct);
      
      if (rs.length > 0) {
        const fechaReg = this.datePipe.transform(rs[0].FECHA, 'dd/MM/yyyy');
        
        if (rs[0].HORA === strHora && fecha === fechaReg) {
        
        } else {
          this.mensajeAlerta('error', 'Error', 'Ya existe un ajuste de costo en la misma fecha y hora');
          return false;
        }
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error',  mensaje);
      return false;
    }
    
    for (const item of this.invTrnAjusteService.detTrnAjustes[this.indicador]) {
      if (item.ART_CODIGO === '') {
        this.mensajeAlerta('error', 'Error', 'Código de artículo incorrecto');
        return false;
      }
      if (item.BOD_CODIGO === '') {
        this.mensajeAlerta('error', 'Error', 'Bodega incorrecta');
        return false;
      }
      if (Number(item.COSTO_AJUSTE) === 0 || item.COSTO_AJUSTE === '') {
        this.mensajeAlerta('error', 'Error', 'Costo de ajuste incorrecto');
        return false;
      }
      if (item.CON_CODIGO === '') {
        this.mensajeAlerta('error', 'Error', 'Cuenta contable incorrecta');
        return false;
      }
      if (item.CON_CENTRO === 'Si') {
        if (String(item.CEN_CODIGO).trim() === '') {
          this.mensajeAlerta('error', 'Error', 'Ingrese el centro de costos');
          return false;
        }
      }
    }
    
    
    
    return true;
  }
  
  async guardarDetDoc() {
    let indice = 0;
    for (indice = 0; indice < this.invTrnAjusteService.detTrnAjustes[this.indicador].length; indice++) {
      try {
        const rs = await this.invTrnAjusteService.insertarDetAjuNeg(this.invTrnAjusteService.detTrnAjustes[this.indicador][indice]);
        this.invTrnAjusteService.detTrnAjustes[this.indicador][indice].LINEA = rs[0][':B1'];
        this.invTrnAjusteService.detTrnAjustes[this.indicador][indice].COMPROBANTE = this.invTrnAjusteService.encTrnAjustes[this.indicador].COMPROBANTE;
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', '! Proceso guardar detalle no termino satisfactoriamente.... ! ' + mensaje);
        return false;
      }
    }
    return true;
  }
  
  async guardarEncDoc() {
    try {
      const rs = await this.invTrnAjusteService.guardarEncAjuInvNeg();
      this.invTrnAjusteService.encTrnAjustes[this.indicador].COMPROBANTE = rs[0].COMPROBANTE;
      this.FechaRegAct = this.invTrnAjusteService.encTrnAjustes[this.indicador].FECHA;
      this.HoraRegAct = this.invTrnAjusteService.encTrnAjustes[this.indicador].HORA;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso guardar encabezado no termino satisfactoriamente.... ! ' + mensaje);
      return false;
    }
    
    return true;
  }
  
  async verificarDatosEncDetTrn() {
    if (this.invTrnAjusteService.encTrnAjustes[this.indicador].FECHA === '' ||
      this.invTrnAjusteService.encTrnAjustes[this.indicador].FECHA === null) {
      this.mensajeAlerta('error', 'Error', 'Fecha incorrecta');
      return false;
    }
    if (this.invTrnAjusteService.encTrnAjustes[this.indicador].HORA === '' ||
      this.invTrnAjusteService.encTrnAjustes[this.indicador].HORA === null) {
      this.mensajeAlerta('error', 'Error', 'Hora incorrecta');
      return false;
    }
    
    let fecha =  this.invTrnAjusteService.encTrnAjustes[this.indicador].FECHA;
    if (fecha.toString().includes('-')) {
      fecha = this.datePipe.transform(fecha, 'dd/MM/yyyy');
    }
    
    const time = this.invTrnAjusteService.encTrnAjustes[this.indicador].HORA;
    const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
    const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
    const strHora = hora + ':' + minutos;

    try {
      const rs = await this.invTrnAjusteService.contarRegistrosAjuste(strHora, fecha);
      if (rs[0].REGISTRO > 0) {
        this.mensajeAlerta('error', 'Error', 'Ya existe un ajuste de costo en la misma fecha y hora');
        return false;
      }
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error',  mensaje);
      return false;
    }
    
    for (const item of this.invTrnAjusteService.detTrnAjustes[this.indicador]) {
      if (item.ART_CODIGO === '') {
        this.mensajeAlerta('error', 'Error', 'Código de artículo incorrecto');
        return false;
      }
      if (item.BOD_CODIGO === '') {
        this.mensajeAlerta('error', 'Error', 'Bodega incorrecta');
        return false;
      }
      if (Number(item.COSTO_AJUSTE) === 0 || item.COSTO_AJUSTE === '') {
        this.mensajeAlerta('error', 'Error', 'Costo de ajuste incorrecto');
        return false;
      }
      if (item.CON_CODIGO === '') {
        this.mensajeAlerta('error', 'Error', 'Cuenta contable incorrecta');
        return false;
      }
      if (item.CON_CENTRO === 'Si') {
        if (String(item.CEN_CODIGO).trim() === '') {
          this.mensajeAlerta('error', 'Error', 'Ingrese el centro de costos');
          return false;
        }
      }
    }
    
    
    
    return true;
  }
  
  eliminarRegistro() {
    if (this.invTrnAjusteService.encTrnAjustes[this.indicador].COMPROBANTE !== '') {
      this.confirmationService.confirm({
        message: '¿ Está seguro de eliminar el documento ?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'invtrnajustesconf',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.eliminar();
        },
        reject: () => {
        }
      });
    } else {
      this.limpiarDatos();
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
    }
  }
  
  async eliminar() {
    try {
      // Eliminar asiento contable
      await this.invTrnAjusteService.eliminarAsientoCont();
      // Eliminar TrnKardex
      for (const item of this.invTrnAjusteService.detTrnAjustes[this.indicador]) {
        await this.invTrnAjusteService.eliminarTrnKardex(item);
      }
      
      await this.invTrnAjusteService.eliminarDetAjuNeg();
      await this.invTrnAjusteService.eliminarEncAjuNeg();
      this.limpiarDatos();
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      this.activaBotones(false, true, true, false, false);
      this.mensajeAlerta('success', 'Información', 'Registro eliminado exitosamente...!!!');
      this.disabledTab1 = false;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso eliminar transacción de inventario no terminó satisfactoriamente.... ! ' + mensaje);
    }
    
    
    
  }
  
  nuevoRegistro() {
    this.selectTab = 0;
    document.getElementById('divPrincipal').style.pointerEvents = 'all';
    this.disabledTab1 = true;
    this.limpiarDatos();
    // this.activaBotones(true, false, false, true, true);
    this.nuevoElemento();
  }
  
  limpiarDatos() {
    this.invTrnAjusteService.encTrnAjustes[this.indicador].HORA = new Date();
    this.invTrnAjusteService.encTrnAjustes[this.indicador].FECHA = new Date();
    this.invTrnAjusteService.encTrnAjustes[this.indicador].COMPROBANTE = '';
    this.invTrnAjusteService.encTrnAjustes[this.indicador].ASI_NRO = '';
    this.invTrnAjusteService.detTrnAjustes[this.indicador] = [];
  }
  
  async nuevoElemento() {
    
    let item: InvDetAjuInvNeg = {};
    let strCodArticulo = '';
    let strConCodigo = '';
    let strCenCodigo = '';
    let strConCentro = '';
    let strSentencia = '';
    
    
    if (Object.keys(this.detalleAjusteSel).length > 0) {
      item = this.detalleAjusteSel;
      strCodArticulo = item.ART_CODIGO;
      strConCodigo = item.CON_CODIGO;
      strCenCodigo = item.CEN_CODIGO;
      strConCentro = item.CON_CENTRO;
      
    }
    
    strSentencia = 'select * from inv_maebodega';
    strSentencia = strSentencia + ' where bod_tipo is null and rownum=1 ';
    
    item = {};
    item.BOD_CODIGO = '';
    item.ART_CODIGO = '';
    item.ART_NOMBRE = '';
    item.CODIGO_AUXILIAR = '';
    item.TRNART_CANTIDAD = 0;
    item.TRNART_COSTOT = Number(0).toFixed(this.invTrnAjusteService.obtenerConfig('COSTO', 'kardex'));
    item.TRNART_ORIGEN = 'INV';
    item.TRNART_REFERENCIA = 'AJUSTE MANUAL';
    item.CON_CODIGO = strConCodigo;
    item.CEN_CODIGO = strCenCodigo;
    item.CON_CENTRO = strConCentro;
    item.NUMERO = this.invTrnAjusteService.detTrnAjustes[this.indicador].length + 1;
    item.LINEA = 0;
    item.ART_EXISTENCIA = '';
    item.ART_COSTO = '';
    item.COSTO_AJUSTE = '';
    item.COMPROBANTE = '';
    
    
    const indi =  this.invTrnAjusteService.detTrnAjustes[this.indicador].indexOf(this.detalleAjusteSel);
    if (indi === -1) {
      this.invTrnAjusteService.detTrnAjustes[this.indicador].push(item);
      this.detalleAjusteSel = this.invTrnAjusteService.detTrnAjustes[this.indicador]
        [this.invTrnAjusteService.detTrnAjustes[this.indicador].length - 1];
      this.aggrid.refreshaggrid(this.invTrnAjusteService.detTrnAjustes[this.indicador], this.defaultColTrnAjustes.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColTrnAjustes.objeto].setFocusedCell(
        this.invTrnAjusteService.detTrnAjustes[this.indicador].length - 1, 'BOD_CODIGO');
    } else {
      this.invTrnAjusteService.detTrnAjustes[this.indicador].splice(indi + 1, 0, item);
      this.aggrid.refreshaggridindex(this.invTrnAjusteService.detTrnAjustes[this.indicador], this.defaultColTrnAjustes.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColTrnAjustes.objeto].setFocusedCell(
        indi + 1, 'BOD_CODIGO');
    }
    this.activaBotonesDet(true, true, false);
    this.activaBotones(true, false, true, true, true);
  }
  
  async listarDatos(StrComprobante) {
    
    const lstDetAjuInvNeg: InvDetAjuInvNeg[] = [];
    
    try {
      const rs = await this.invTrnAjusteService.listarDatosAjuste(StrComprobante);
      if (rs !== null) {
        let numero = 0;
        for (const ajuste of rs) {
          numero ++;
          const item: InvDetAjuInvNeg = {};
          item.ART_CODIGO = ajuste.ART_CODIGO;
          item.ART_NOMBRE = ajuste.ART_NOMBRE;
          item.BOD_CODIGO = ajuste.BOD_CODIGO;
          item.CEN_CODIGO = ajuste.CEN_CODIGO === null ? '' : ajuste.CEN_CODIGO;
          item.CODIGO_AUXILIAR = 1;
          item.CON_CENTRO = ajuste.CON_CENTRO === null ? '' : ajuste.CON_CENTRO;
          item.CON_CODIGO = ajuste.CON_CODIGO === null ? '' : ajuste.CON_CODIGO;
          item.TRNART_COSTOT = ajuste.TRNART_COSTOT === null ?
            Number(0).toFixed(this.invTrnAjusteService.obtenerConfig('COSTO', 'kardex')) :
            Number(ajuste.TRNART_COSTOT).toFixed(this.invTrnAjusteService.obtenerConfig('COSTO', 'kardex'));
          
          item.TRNART_ORIGEN = ajuste.TRNART_ORIGEN === null ? '' : ajuste.TRNART_ORIGEN;
          item.TRNART_REFERENCIA = ajuste.TRNART_REFERENCIA === null ? '' : ajuste.TRNART_REFERENCIA;
          item.NUMERO = numero;
          item.LINEA = ajuste.LINEA;
          item.COMPROBANTE = ajuste.COMPROBANTE;
          item.COSTO_AJUSTE = Number(ajuste.COSTO_AJUSTE).toFixed(2);
          item.ART_COSTO = Number(ajuste.ART_COSTO).toFixed(2);
          item.ART_EXISTENCIA = Number(ajuste.ART_EXISTENCIA).toFixed(2);
          
          lstDetAjuInvNeg.push(item);
        }
        
        this.invTrnAjusteService.detTrnAjustes[this.indicador] = lstDetAjuInvNeg;
      }
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error',  mensaje);
    }
    
    this.activaBotonesDet(false, false, true);
  }
  
  async listarDatosAut(StrNumero) {
    
    const lstInvTrnKardexAju: InvTrnAjustes[] = [];
    
    try {
      const rs = await this.invTrnAjusteService.listarDatosAut(String(StrNumero).trim());
      if (rs !== null) {
        let numero = 0;
        for (const ajuste of rs) {
          numero ++;
          const item: InvTrnAjustes = {};
          item.ART_CODIGO = ajuste.ART_CODIGO;
          item.ART_NOMBRE = ajuste.ART_NOMBRE;
          item.BOD_CODIGO = ajuste.BOD_CODIGO;
          item.CEN_CODIGO = ajuste.CEN_CODIGO === null ? '' : ajuste.CEN_CODIGO;
          item.CODIGO_AUXILIAR = 1;
          item.COM_CODIGO = ajuste.COM_CODIGO;
          item.CON_CENTRO = ajuste.CON_CENTRO === null ? '' : ajuste.CON_CENTRO;
          item.CON_CODIGO = ajuste.CON_CODIGO === null ? '' : ajuste.CON_CODIGO;
          item.TRNART_ARTEXT = ajuste.TRNART_ARTEXT === null ? '' : ajuste.TRNART_ARTEXT;
          item.TRNART_CANTIDAD = 0;
          
          item.TRNART_COSTOT = ajuste.TRNART_COSTOT === null ?
            Number(0).toFixed(this.invTrnAjusteService.obtenerConfig('COSTO', 'kardex')) :
            Number(ajuste.TRNART_COSTOT).toFixed(this.invTrnAjusteService.obtenerConfig('COSTO', 'kardex'));
          
          item.TRNART_FECHAES = ajuste.TRNART_FECHAES === null ? '' : ajuste.TRNART_FECHAES;
          if (item.TRNART_FECHAES.toString().includes('-') && item.TRNART_FECHAES !== '') {
            item.TRNART_FECHAES = this.datePipe.transform(item.TRNART_FECHAES, 'dd/MM/yyyy');
          }
          item.TRNART_HORA = ajuste.TRNART_HORA === null ? '' : ajuste.TRNART_HORA;
          item.TRNART_NUMERO = ajuste.TRNART_NUMERO === null ? '' : ajuste.TRNART_NUMERO;
          item.TRNART_ORIGEN = ajuste.TRNART_ORIGEN === null ? '' : ajuste.TRNART_ORIGEN;
          item.TRNART_REFERENCIA = ajuste.TRNART_REFERENCIA === null ? '' : ajuste.TRNART_REFERENCIA;
          item.TRNART_TIPO = ajuste.TRNART_TIPO === null ? '' : ajuste.TRNART_TIPO;
          item.ASI_NRO = ajuste.ASI_NRO === null ? '' : ajuste.ASI_NRO;
          item.NUMERO = numero;
          
          lstInvTrnKardexAju.push(item);
        }
        
        this.invTrnAjusteService.detTrnAjustesAut[this.indicador] = lstInvTrnKardexAju;
      }
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error',  mensaje);
    }
  }
  
  seleccionDetalle(valor) {
    if (valor === 'nuevo') {
      this.nuevoElemento();
    }
    if (valor === 'eliminar') {
      this.eliminarLineaDetalle();
    }
    if (valor === 'cancelar') {
      this.cancelarAcciones();
      this.activaBotonesDet(false, false, true);
    }
    
    if (valor.object !== undefined) {
      this.detalleAjusteSel = valor.object;
    }
  }
  
  cancelarAcciones() {
    let intIndiceLista = 0;
    let item: InvDetAjuInvNeg = {};
    for (intIndiceLista = 0; intIndiceLista < this.invTrnAjusteService.detTrnAjustes[this.indicador].length; intIndiceLista++ ) {
      item = this.invTrnAjusteService.detTrnAjustes[this.indicador][intIndiceLista];
      if (item.ART_CODIGO === '' || item.BOD_CODIGO === '' ) {
        this.invTrnAjusteService.detTrnAjustes[this.indicador].splice(this.invTrnAjusteService.detTrnAjustes[this.indicador].findIndex(
          cuota => cuota.NUMERO === this.detalleAjusteSel.NUMERO),
          1);
        
      }
    }
    this.aggrid.refreshaggrid(this.invTrnAjusteService.detTrnAjustes[this.indicador], this.defaultColTrnAjustes.objeto);
  }
  
  eliminarLineaBlanco() {
    let intIndiceLista = 0;
    let item: InvDetAjuInvNeg = {};
    for (intIndiceLista = 0; intIndiceLista < this.invTrnAjusteService.detTrnAjustes[this.indicador].length; intIndiceLista++ ) {
      item = this.invTrnAjusteService.detTrnAjustes[this.indicador][intIndiceLista];
      if (item.ART_CODIGO === '' || item.BOD_CODIGO === '' ) {
        this.invTrnAjusteService.detTrnAjustes[this.indicador].splice(this.invTrnAjusteService.detTrnAjustes[this.indicador].findIndex(
          cuota => cuota.NUMERO === this.detalleAjusteSel.NUMERO),
          1);
        
      }
    }
    this.aggrid.refreshaggrid(this.invTrnAjusteService.detTrnAjustes[this.indicador], this.defaultColTrnAjustes.objeto);
  }
  
  eliminarLineaDetalle() {
    
    if (Object.keys(this.detalleAjusteSel).length > 0) {
      this.confirmationService.confirm({
        message: '¿ Está seguro de eliminar el registro ?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'invtrnajustesconf',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.eliminarDet();
        },
        reject: () => {
        }
      });
      
    } else {
      this.mensajeAlerta('info', 'Información', 'Seleccione la fila que desea eliminar');
    }
  }
  async eliminarDet() {
    this.invTrnAjusteService.detTrnAjustes[this.indicador].splice(this.invTrnAjusteService.detTrnAjustes[this.indicador].findIndex(
      ajuste => ajuste.NUMERO === this.detalleAjusteSel.NUMERO),
      1);
    this.aggrid.refreshaggrid(this.invTrnAjusteService.detTrnAjustes[this.indicador], this.defaultColTrnAjustes.objeto);
    
    this.activaBotones(true, false, true, true, true);
  }
  
  
  async MostrarDatosAdicionales() {
    let dblExistencia = 0;
    let dblCostoPromedio = 0;
    let dblUltimoCostoComp = 0;
    let dblUltimoCostoProd = 0;
    const item: InvTrnAjustes = this.detalleAjusteSel;
    
    if (item.CODIGO_AUXILIAR === '') {
      this.lblExistencia = '';
      this.lblUltCostoProduccion = '';
      this.lblUltCostoCompra = '';
      this.lblCostoPromedio = '';
      return;
    }
    
    try {
      const fecha = item.TRNART_FECHAES;
      const fechaE = fecha.toLocaleString('en-GB').split(',');
      const rs = await this.invTrnAjusteService.MostrarDatosAdicionales(item.ART_CODIGO, fechaE[0], item.TRNART_HORA);
      
      if (rs !== null) {
        dblExistencia = rs[0].EXISTENCIA;
        dblCostoPromedio = rs[0].COSTOPROMEDIO;
        dblUltimoCostoComp = rs[0].ULTIMOCOSTOCOMPRA;
        dblUltimoCostoProd = rs[0].ULTIMOCOSTOPROD;
        this.lblExistencia = Number(dblExistencia).toFixed(this.invTrnAjusteService.obtenerConfig('COSTO', 'kardex'));
        this.lblExistencia = this.init.moneyValidation(this.lblExistencia);
        this.lblCostoPromedio = Number(dblCostoPromedio).toFixed(this.invTrnAjusteService.obtenerConfig('COSTO', 'kardex'));
        this.lblCostoPromedio = this.init.moneyValidation(this.lblCostoPromedio);
        this.lblUltCostoCompra = Number(dblUltimoCostoComp).toFixed(this.invTrnAjusteService.obtenerConfig('COSTO', 'kardex'));
        this.lblUltCostoCompra = this.init.moneyValidation(this.lblUltCostoCompra);
        this.lblUltCostoProduccion = Number(dblUltimoCostoProd).toFixed(this.invTrnAjusteService.obtenerConfig('COSTO', 'kardex'));
        this.lblUltCostoProduccion = this.init.moneyValidation(this.lblUltCostoProduccion);
      } else {
        this.lblExistencia = '';
        this.lblUltCostoProduccion = '';
        this.lblUltCostoCompra = '';
        this.lblCostoPromedio = '';
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Extraer datos adicionales no terminó satisfactoriamente.... ! ' + mensaje);
    }
    
  }
  
  buscarRegistro() {
    this.opcion = 'buscarAjustes';
    this.types = [
      {label: 'Nº COMPROBANTE', value: 'COMPROBANTE'},
      {label: 'FECHA', value: 'FECHA'},
      {label: 'HORA', value: 'HORA'},
      {label: 'ASI_NRO', value: 'ASI_NRO'},
    ];
    this.busquedacampos = ['', '', '', ''];
    this.consulta = '*';
    this.tabla = 'INV_ENCAJUINVNEG';
    this.where = '';
    
    this.busquedaSer.busquedaInvEncAjuInvNeg1().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.arregloCons.map((dato) => {
        if (dato.FECHA !== null) {
          dato.FECHA = this.datePipe.transform(dato.FECHA, 'dd/MM/yyyy');
        } else {
          dato.FECHA = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });
  }
  
  async manejarSeleccion(valor) {
    if (this.opcion === 'buscarAjustes') {
      await this.mostrarEncabezado(valor.COMPROBANTE);
      await this.listarDatos(valor.COMPROBANTE);
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
    }
    this.displayDialogBusquedaFast = false;
  }
  
  async mostrarEncabezado(strComprobante) {
    try {
      const rs = await this.invTrnAjusteService.mostrarEncabezado(strComprobante);
      if (rs !== null) {
        this.invTrnAjusteService.encTrnAjustes[this.indicador].COMPROBANTE = rs[0].COMPROBANTE;
        this.invTrnAjusteService.encTrnAjustes[this.indicador].FECHA = new Date(rs[0].FECHA);
        this.invTrnAjusteService.encTrnAjustes[this.indicador].HORA = rs[0].HORA;
        this.FechaRegAct = new Date(rs[0].FECHA);
        this.HoraRegAct = rs[0].HORA;
        this.invTrnAjusteService.encTrnAjustes[this.indicador].ASI_NRO = rs[0].ASI_NRO;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Extraer datos encabezado no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }
  
  cambios(event) {
    this.activaBotones(true, false, false, true, true);
    this.activaBotonesDet(true, true, false);
  }
  
  cambioEnc(event) {
    this.activaBotones(true, false, false, true, true);
  }
  
  isEdit(param): boolean {
    if (param.CODIGO_AUXILIAR === 1) {
      return false;
    } else {
      return true;
    }
  }
  
  
  verAsientoContable() {
    if (this.invTrnAjusteService.encTrnAjustes[this.indicador].ASI_NRO !== ''
      && this.invTrnAjusteService.encTrnAjustes[this.indicador].ASI_NRO !== null) {
      this.NumAsiRegCont = this.invTrnAjusteService.encTrnAjustes[this.indicador].ASI_NRO;
      this.displayAsiContable = true;
    } else {
      this.mensajeAlerta('info', 'Información', 'No ha generado el Asiento Contable');
    }
    
  }
  
  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }
  
  
  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'invtrnajustes',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
  
}




