import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { InvmaearticuloService } from '../invservicios/invmaearticulo.service';
import { PermisosService } from 'src/app/advantage/advantageservices/permisos.service';
import { MessageService, ConfirmationService, SelectItem } from 'primeng';
import { InvbusquedaService } from '../invservicios/invbusqueda.service';
import { Usuario } from 'src/app/usuario';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { DatePipe } from '@angular/common';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { InvTrnFichaTecnica } from '../invinterfaces/invtrnfichatecnica';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';

@Component({
  selector: 'app-invtrnfichatecnica',
  templateUrl: './invtrnfichatecnica.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class InvtrnfichatecnicaComponent implements OnInit {

  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  @Input() art_codigo: string;
  @Input() art_nombre: string;
  @Output() signalSalir = new EventEmitter<boolean>();

  btnNuevo: boolean;
  btnGuardar = true;
  btnBorrar = true;
  btnRegresar: boolean;
 
  selectTab = 0;
  intItemSelec=0;

  boolIndex1 = false;
  boolIndex0 = false;

  lblCodArticulo = '';
  lblNomArticulo = '';
  txtCodigo = '';
  dateTimeFechaVigencia = new Date();
  txtVersion = '';
  txtTitulo = '';
  txtCliCodigo = '';
  lblCLInombre = '';
  txtDeclaracion = '';
  txtCalidadRegulacion = '';
  txtAplicacion = '';
  txtComposicion = '';
  txtVidaAnaquel= '';
  txtEstadoFisico = '';
  txtColor = '';
  txtPerfilAromatico = '';
  txtPresentacion = '';
  txtManejoProcedimiento = '';
  txtCertificadoAnalitico = '';
  txtRevisado = '';

  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];
  consulta: string;
  tabla: string;
  where: string;
  opcionbusqueda: string;
  displayDialogBusquedaFast = false;
  displayDialogBusqueda = false;

  // Tabla
  public frameworkComponents;
  rowStyle;
  defaultFisicoQuimico;
  defaultMicrobiologica;
  rowSelection = 'multiple';
  largo: string;

  detalleFisicoQuim: InvTrnFichaTecnica[] = [];
  detalleMicrobiologica: InvTrnFichaTecnica[] = [];
  detalleFisQuimSel: InvTrnFichaTecnica = {};
  detalleMicrobioSel: InvTrnFichaTecnica = {};

  columnDefsFisicoQuim = [
    {
      headerName: 'Parámetro', field: 'TRNFIC_PARAMETRO', cellEditor: 'cellPrueba', width: 200, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            fecha: false,
            tamanocelda: 50,
            tienecalculos: true
          }
        }
    },
    {
      headerName: 'Método', field: 'TRNFIC_METODO', cellEditor: 'cellPrueba', width: 200, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 50,
            tienecalculos: true
          }
        }
    },
    {
      headerName: 'Límite', field: 'TRNFIC_RESULTADO', cellEditor: 'cellPrueba', width: 200, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 20,
            tienecalculos: true
          }
        }
    }
  ];

  columnDefsMicrobio = [
    {
      headerName: 'Parámetro', field: 'TRNFIC_PARAMETRO', cellEditor: 'cellPrueba', width: 200, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            fecha: false,
            tamanocelda: 50,
            tienecalculos: true
          }
        }
    },
    {
      headerName: 'Método', field: 'TRNFIC_METODO', cellEditor: 'cellPrueba', width: 200, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 50,
            tienecalculos: true
          }
        }
    },
    {
      headerName: 'Límite', field: 'TRNFIC_RESULTADO', cellEditor: 'cellPrueba', width: 200, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 20,
            tienecalculos: true
          }
        }
    }
  ];


  constructor(public artService: InvmaearticuloService,
    private permisosService: PermisosService, private message: MessageService, public usuario: Usuario,
    private errorService: ErroresBaseDatosService, private busquedaSer: InvbusquedaService,
    private datePipe: DatePipe, private auditoriagral: AuditoriagralService,
    private confirmationService: ConfirmationService, private confIniciales: ConfInicialesService,
    private init: NuevoComponentService) { 

      this.agTable();
      this.detalleFisicoQuim = [];
      this.detalleMicrobiologica = [];
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    
    this.defaultFisicoQuimico = {
      editable: true,
      width: 500,
      objeto: 'invtrnftfisquim',
    };
    
    this.defaultMicrobiologica = {
      editable: true,
      width: 500,
      objeto: 'invtrnftmicrobio',
    };
    
  }

  async ngOnInit() {
    this.largo = '580';
    this.lblCodArticulo = this.art_codigo;
    this.lblNomArticulo = this.art_nombre;
    await this.desplegarInformacion();
    await this.LlenarFichaTecFisicoQuimica(this.lblCodArticulo);
    await this.LlenarFichaCMicrobiologicas(this.lblCodArticulo);
  }

  async manejarSenales(valor) {
    
    if (valor === 'Guardar') {
      if (this.selectTab === 0)		{
					await this.actualizarArticulo();
      }
      if(this.selectTab === 1) {
        await this.grabarFisicoQuimica();
        await this.grabarMicrobiologica();
      }
	
				this.activarBoton('G');
    }
    
    if (valor === 'Salir') {
      //this.salir();
    }
    
    if (valor === 'Cancelar') {
      await this.desplegarInformacion();
			await this.LlenarFichaTecFisicoQuimica(this.lblCodArticulo);
			await this.LlenarFichaCMicrobiologicas(this.lblCodArticulo);
      this.btnGuardar = true;
      this.selectTab = 0;
      this.boolIndex0 = false;
      this.boolIndex1 = false;	
    }

  }

  async grabarFisicoQuimica() {
    let i = 0;
    const parametros: any[] = [];

		await this.eliminarFisicoQuimico();
		
		for (const item of this.detalleFisicoQuim) {

			parametros[0] = this.lblCodArticulo;		
			parametros[1] = '01';
			parametros[2] = String(item.TRNFIC_PARAMETRO).trim();
			parametros[3] = String(item.TRNFIC_METODO).trim();
			parametros[4] = String(item.TRNFIC_RESULTADO).trim();
			parametros[5] = 'F';
			parametros[6] = String(i + 1);
			
			if (String(item.TRNFIC_PARAMETRO).trim() !== '' && String(item.TRNFIC_METODO).trim() !== '') {
				try {
					await this.artService.guardarFisQuim(parametros);
				} catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', '! Proceso eliminar línea de detalle  no terminó satisfactoriamente.... ! ' + mensaje);	
				}
			} 
      i++;
		}
  }

  async eliminarFisicoQuimico() {
		const parametros: any[] = [];
		
		parametros[0] = this.lblCodArticulo;		
		parametros[1] = '01';
		parametros[2] = 'F';
		
		try {
			await this.artService.eliminarTodoFTecnica(parametros);
		
		} catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso eliminar ficha técnica.... ! ' + mensaje);			
		}
  }

  async grabarMicrobiologica() {
    let i = 0;
    const parametros: any[] = [];

		await this.eliminarMicrobiologicas();
		
		for (const item of this.detalleMicrobiologica) {

			parametros[0] = this.lblCodArticulo;		
			parametros[1] = '01';
			parametros[2] = String(item.TRNFIC_PARAMETRO).trim();
			parametros[3] = String(item.TRNFIC_METODO).trim();
			parametros[4] = String(item.TRNFIC_RESULTADO).trim();
			parametros[5] = 'M';
			parametros[6] = String(i + 1);
			
			if (String(item.TRNFIC_PARAMETRO).trim() !== '' && String(item.TRNFIC_METODO).trim() !== '') {
				try {
					await this.artService.guardarFisQuim(parametros);
				} catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', '! Proceso eliminar línea de detalle  no terminó satisfactoriamente.... ! ' + mensaje);	
				}
			} 
      i++;
		}
  }

  async eliminarMicrobiologicas() {
    const parametros: any[] = [];
		
		parametros[0] = this.lblCodArticulo;		
		parametros[1] = '01';
		parametros[2] = 'M';
		
		try {
			await this.artService.eliminarTodoFTecnica(parametros);
		
		} catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso eliminar ficha técnica.... ! ' + mensaje);			
		}
  }

  seleccionDetMicrobiol(valor) {
    if (valor === 'nuevo') {
      this.nuevaLineaMicrobio();
      
    }
    if (valor === 'eliminar') {
      if (Object.keys(this.detalleMicrobioSel).length > 0) {
        this.confirmationService.confirm({
          message: '¿ Está seguro de eliminar el registro ?',
          header: 'Confirmación',
          icon: 'pi pi-exclamation-triangle',
          key: 'trnfichaTecConf',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.eliminarMicrobio();
          },
          reject: () => {
          }
        });
      } else {
        this.mensajeAlerta('error', 'Error', '! Seleccione una fila.... !');
      }
      
    }
    
    if (valor.object !== undefined) {
      this.detalleMicrobioSel = valor.object;
    }
  }

  async eliminarMicrobio() {
    const strVarClave = this.detalleMicrobioSel.TRNFIC_PARAMETRO;
    const parametros:any[] = [];
    let strDato = '';
			if (strVarClave !== '') {

				parametros[0] = this.lblCodArticulo;		
				parametros[1] = '01';
				parametros[2] = strVarClave;
				
				try {
					
					await this.artService.eliminarFisQuim(parametros);
				
					if (strVarClave !== '') {
            strDato = '/' + this.lblCodArticulo + '/' + strVarClave + '/';
            this.auditoriagral.registrarAuditoria('INV_TRNFICHATECNICA', strDato, 'E',
             this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });
          }

          this.detalleMicrobiologica.splice(this.detalleMicrobiologica.findIndex(
            item => item.TRNFIC_PARAMETRO === this.detalleMicrobioSel.TRNFIC_PARAMETRO),
            1);
          this.aggrid.refreshaggrid(this.detalleMicrobiologica, this.defaultMicrobiologica.objeto);
          this.activarBoton('M');
				} catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', '! Proceso eliminar línea de detalle  no terminó satisfactoriamente.... ! ' + mensaje);	
				}
			}
  }

  nuevaLineaMicrobio() {
    const item: InvTrnFichaTecnica = {};
    item.COM_CODIGO = '01';
    item.ART_CODIGO = '';
    item.TRNFIC_METODO = '';
    item.TRNFIC_ORDEN = '';
    item.TRNFIC_PARAMETRO = '';
    item.TRNFIC_RESULTADO = '';
    item.TRNFIC_TIPO = '';
    
    
    const indi = this.detalleMicrobiologica.indexOf(this.detalleMicrobioSel);
    if (indi === -1) {
      this.detalleMicrobiologica.push(item);
      this.detalleMicrobioSel = this.detalleMicrobiologica
        [this.detalleMicrobiologica.length - 1];
      this.aggrid.refreshaggrid(this.detalleMicrobiologica, this.defaultMicrobiologica.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultMicrobiologica.objeto].setFocusedCell(
        this.detalleMicrobiologica.length - 1, 'TRNFIC_PARAMETRO');
    } else {
      this.detalleMicrobiologica.splice(indi + 1, 0, item);
      this.aggrid.refreshaggridindex(this.detalleMicrobiologica, this.defaultMicrobiologica.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultMicrobiologica.objeto].setFocusedCell(
        indi + 1, 'TRNFIC_PARAMETRO');
    }

  }



  seleccionDetFisQuim(valor) {
    if (valor === 'nuevo') {
      this.nuevaLineaFisQuim();
      
    }
    if (valor === 'eliminar') {
      if (Object.keys(this.detalleFisQuimSel).length > 0) {
        this.confirmationService.confirm({
          message: '¿ Está seguro de eliminar el registro ?',
          header: 'Confirmación',
          icon: 'pi pi-exclamation-triangle',
          key: 'trnfichaTecConf',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.eliminarFisQuim();
          },
          reject: () => {
          }
        });
      } else {
        this.mensajeAlerta('error', 'Error', '! Seleccione una fila.... !');
      }
      
    }
    
    if (valor.object !== undefined) {
      this.detalleFisQuimSel = valor.object;
    }
  }

  async eliminarFisQuim() {
    const strVarClave = this.detalleFisQuimSel.TRNFIC_PARAMETRO;
    const parametros:any[] = [];
    let strDato = '';
			if (strVarClave !== '') {

				parametros[0] = this.lblCodArticulo;		
				parametros[1] = '01';
				parametros[2] = strVarClave;
				
				try {
					
					await this.artService.eliminarFisQuim(parametros);
				
					if (strVarClave !== '') {
            strDato = '/' + this.lblCodArticulo + '/' + strVarClave + '/';
            this.auditoriagral.registrarAuditoria('INV_TRNFICHATECNICA', strDato, 'E',
             this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });
          }

				} catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', '! Proceso eliminar línea de detalle  no terminó satisfactoriamente.... ! ' + mensaje);	
				}
      }

      this.detalleFisicoQuim.splice(this.detalleFisicoQuim.findIndex(
        item => item.TRNFIC_PARAMETRO === this.detalleFisQuimSel.TRNFIC_PARAMETRO),
        1);
      this.aggrid.refreshaggrid(this.detalleFisicoQuim, this.defaultFisicoQuimico.objeto);
      this.activarBoton('M');
  }

  nuevaLineaFisQuim() {
    const item: InvTrnFichaTecnica = {};
    item.COM_CODIGO = '01';
    item.ART_CODIGO = '';
    item.TRNFIC_METODO = '';
    item.TRNFIC_ORDEN = '';
    item.TRNFIC_PARAMETRO = '';
    item.TRNFIC_RESULTADO = '';
    item.TRNFIC_TIPO = '';
    
    
    const indi = this.detalleFisicoQuim.indexOf(this.detalleFisQuimSel);
    if (indi === -1) {
      this.detalleFisicoQuim.push(item);
      this.detalleFisQuimSel = this.detalleFisicoQuim
        [this.detalleFisicoQuim.length - 1];
      this.aggrid.refreshaggrid(this.detalleFisicoQuim, this.defaultFisicoQuimico.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultFisicoQuimico.objeto].setFocusedCell(
        this.detalleFisicoQuim.length - 1, 'TRNFIC_PARAMETRO');
    } else {
      this.detalleFisicoQuim.splice(indi + 1, 0, item);
      this.aggrid.refreshaggridindex(this.detalleFisicoQuim, this.defaultFisicoQuimico.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultFisicoQuimico.objeto].setFocusedCell(
        indi + 1, 'TRNFIC_PARAMETRO');
    }
  }

  async LlenarFichaCMicrobiologicas(strArtCodigo) {
    let Sw = 0;
    try {
      const rs = await this.artService.obtenerDatosMicrobiologicas(strArtCodigo);
      const detalle = [];
			for (const reg of rs) {
				detalle.push(reg);
				Sw = 1;
      }
      this.detalleMicrobiologica = detalle;

		} catch (err) {
			let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }

    if (Sw == 0) {
      const detalle2 = [];
			const item: InvTrnFichaTecnica = {};
			item.TRNFIC_PARAMETRO = 'Recuento de m.o. aerobios mesófilos';
			item.TRNFIC_METODO = 'AOAC 986.33';
			item.TRNFIC_RESULTADO = 'ufc/g';
			detalle2.push(item);
      const item2: InvTrnFichaTecnica = {};
      item2.TRNFIC_PARAMETRO = 'Recuento de mohos y levaduras';
			item2.TRNFIC_METODO = 'AOCA 986.33';
			item2.TRNFIC_RESULTADO = 'ufc/g';
			detalle2.push(item2);
      const item3: InvTrnFichaTecnica = {};
      item3.TRNFIC_PARAMETRO = 'NMP de Coliformes Totales';
			item3.TRNFIC_METODO = 'AOAC 966.24';
			item3.TRNFIC_RESULTADO = 'NMP/g';
			detalle2.push(item3);
      const item4: InvTrnFichaTecnica = {};
      item4.TRNFIC_PARAMETRO = 'NMP de Coliformes Fecales';
			item4.TRNFIC_METODO = 'AOAC 966.24';
			item4.TRNFIC_RESULTADO = 'NMP/g';
      detalle2.push(item4);
      const item5: InvTrnFichaTecnica = {};
      item5.TRNFIC_PARAMETRO = '%Ausencia / Presencia de salomenalla sp';
			item5.TRNFIC_METODO = 'AOAC 967.25';
			item5.TRNFIC_RESULTADO = 'ufc/25g';
      detalle2.push(item5);

      this.detalleMicrobiologica = detalle2;
		}
  }

  async LlenarFichaTecFisicoQuimica(strArtCodigo) {
    let Sw = 0;
    try {
      const rs = await this.artService.obtenerDatosFisQuim(strArtCodigo);
      const detalle = [];
			for (const reg of rs) {
				detalle.push(reg);
				Sw = 1;
      }
      this.detalleFisicoQuim = detalle;

		} catch (err) {
			let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
    
    if (Sw == 0) {
      const detalle2 = [];
			const item: InvTrnFichaTecnica = {};
			item.TRNFIC_PARAMETRO = 'pH';
			item.TRNFIC_METODO = 'Potenciómetro(25ºC)';
			item.TRNFIC_RESULTADO = '+/-1.0';
			detalle2.push(item);
      const item2: InvTrnFichaTecnica = {};
      item2.TRNFIC_PARAMETRO = 'Densidad';
			item2.TRNFIC_METODO = 'Picnómetro(25ºC)';
			item2.TRNFIC_RESULTADO = '+/-0.1';
      detalle2.push(item2);
      const item3: InvTrnFichaTecnica = {};
      item3.TRNFIC_PARAMETRO = '%sólidos solubles';
			item3.TRNFIC_METODO = 'Refractómetro(25ºC)';
			item3.TRNFIC_RESULTADO = '+/-2.0';
			detalle2.push(item3);
      const item4: InvTrnFichaTecnica = {};
      item4.TRNFIC_PARAMETRO = 'IR';
			item4.TRNFIC_METODO = 'Tabla IR soluciones a 20º C';
			item4.TRNFIC_RESULTADO = '0.0044';
			detalle2.push(item4);
      const item5: InvTrnFichaTecnica = {};
      item5.TRNFIC_PARAMETRO = '%Humedad';
			item5.TRNFIC_METODO = 'Pérdida por calentamiento';
			item5.TRNFIC_RESULTADO = 'Máx.%';
			detalle2.push(item5);
      const item6: InvTrnFichaTecnica = {};
      item6.TRNFIC_PARAMETRO = '% de Sal';
			item6.TRNFIC_METODO = 'Método interno';
			item6.TRNFIC_RESULTADO = '+/-2.0';
			detalle2.push(item6);
      const item7: InvTrnFichaTecnica = {};
      item7.TRNFIC_PARAMETRO = 'Sustancias extrañas';
			item7.TRNFIC_METODO = 'Inspección';
			item7.TRNFIC_RESULTADO = '+/-0.25*';
      detalle2.push(item7);
      this.detalleFisicoQuim = detalle2;
    }
    
  }



  async actualizarArticulo() {
		if (String(this.txtCodigo).trim() === ''){
      this.mensajeAlerta('info', 'Información', 'Ingrese el código de la ficha técnica')
			return;
		}
		
		const parametros: any[] = [];
		const rs = null;
		
		const strFechaVigencia = this.datePipe.transform(this.dateTimeFechaVigencia, 'dd/MM/yyyy');
	
		parametros[0] = this.lblCodArticulo;
		parametros[1] = '01'; 	
		parametros[2] = String(this.txtCliCodigo).trim();
		parametros[3] = String(this.txtDeclaracion).trim(); 
		parametros[4] = String(this.txtCalidadRegulacion).trim(); 
		parametros[5] = String(this.txtAplicacion).trim(); 
		parametros[6] = String(this.txtComposicion).trim(); 
		parametros[7]= String(this.txtVidaAnaquel).trim(); 
		parametros[8]= String(this.txtEstadoFisico).trim(); 
		parametros[9]= String(this.txtColor).trim(); 
		parametros[10]= String(this.txtPerfilAromatico).trim(); 
		parametros[11]= String(this.txtPresentacion).trim(); 
		parametros[12]= String(this.txtManejoProcedimiento).trim(); 
		parametros[13]= String(this.txtCertificadoAnalitico).trim(); 
		parametros[14]= String(this.txtCodigo).trim(); 
		parametros[15]= strFechaVigencia;
		parametros[16]= String(this.txtVersion).trim();
		parametros[17]= String(this.txtTitulo).trim();
		parametros[18]= String(this.txtRevisado).trim();
		
		try {
			await this.artService.actualizarFichaTecnica(parametros);
			this.activarBoton('G');
		} catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'Error al actualizar datos del artículo ' + mensaje);
		}
	
		
	}

  onTab1Change(event) {
    this.selectTab = event.index
    /* if (this.btnGuardar === false) {
      this.selectTab = this.intItemSelec;
    } else {
      this.intItemSelec = event.index;
    } */
    
  }
  cambiarFoco(evento) {
    if (evento.target.id === 'codigo') {
      document.getElementById('version').focus();
    }
    if (evento.target.id === 'version') {
      document.getElementById('titulo').focus();
    }
    if (evento.target.id === 'titulo') {
      document.getElementById('clicodigo').focus();
    }
    if (evento.target.id === 'clicodigo') {
      document.getElementById('declaracion').focus();
    }
    if (evento.target.id === 'declaracion') {
      document.getElementById('calidad').focus();
    }
    if (evento.target.id === 'calidad') {
      document.getElementById('aplicacion').focus();
    }
    if (evento.target.id === 'aplicacion') {
      document.getElementById('composicion').focus();
    }
    if (evento.target.id === 'composicion') {
      document.getElementById('anaquel').focus();
    }
    if (evento.target.id === 'anaquel') {
      document.getElementById('estadofisico').focus();
    }
    if (evento.target.id === 'estadofisico') {
      document.getElementById('color').focus();
    }
    if (evento.target.id === 'color') {
      document.getElementById('aromatico').focus();
    }
    if (evento.target.id === 'aromatico') {
      document.getElementById('presentacion').focus();
    }
    if (evento.target.id === 'presentacion') {
      document.getElementById('manejo').focus();
    }
    if (evento.target.id === 'manejo') {
      document.getElementById('certificado').focus();
    }
    if (evento.target.id === 'certificado') {
      document.getElementById('revisado').focus();
    }
  }

  buscarCliente(parametro) {
    this.opcionbusqueda = 'cliente';
    this.types = [
      {label: 'CÓDIGO', value: 'CLI_CODIGO'},
      {label: 'NOMBRE', value: 'CLI_NOMBRE'},
      {label: 'RUC/IDE', value: 'CLI_RUCIDE'},
      {label: 'GRUPO', value: 'GRU_CODIGO'},
      {label: 'VENDEDOR', value: 'VEN_CODIGO'},
      {label: 'CODIGO CONTABLE 1', value: 'CON_CODIGO1'},
    ];
    this.busquedacampos = [parametro, '', '', '', '', ''];
    this.consulta = '*';
    this.where = '';
    this.tabla = 'VEN_MAECLIENTE';
    
    this.artService.encontarMarCliente(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaVenMaeCliente3(parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaVenMaeCliente3(parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'cliente') {
      this.txtCliCodigo = opcion.CLI_CODIGO;
      this.lblCLInombre = opcion.CLI_NOMBRE;
    }
    
    this.displayDialogBusqueda = false;
    this.displayDialogBusquedaFast = false;
  }

  activarBoton(Accion) {
    console.log(this.boolIndex0);
    if (Accion === 'A' || Accion === 'D') {
			// tabFichaTecnica.setEnabled(true);
		  this.btnGuardar = false;
		} else if (Accion === 'M') {
			this.btnGuardar = false;
			
			if (this.selectTab === 0) {
        this.boolIndex0 = false;
        this.boolIndex1 = true;
      }
      if (this.selectTab === 1) {
        this.boolIndex0 = true;
        this.boolIndex1 = false;
			}   
		} else if (Accion === 'C') {
			this.btnGuardar = true;
		
		} else if (Accion === 'G') {
      this.btnGuardar = true;
      this.boolIndex0 = false;
      this.boolIndex1 = false;
			
		} else if (Accion === 'I') {
			this.btnGuardar = true;
		}
	}

  async desplegarInformacion() {
    let strCliNombre = '';
    let rs;
    
    let Sw = 0;

    try {
      rs = await this.artService.deplegarInformacion(this.lblCodArticulo);
      for (const art of rs) {
        this.txtCliCodigo = art.ART_FTCODCLI === null ? '' : art.ART_FTCODCLI;
        this.txtDeclaracion = art.ART_FTDECLARACION === null ? '' : art.ART_FTDECLARACION;
				this.txtCalidadRegulacion = art.ART_FTCALIDAD === null ? '' : art.ART_FTCALIDAD;
				this.txtAplicacion = art.ART_FTAPLICACION === null ? '' : art.ART_FTAPLICACION;
				this.txtComposicion = art.ART_FTCOMPOSICION === null ? '' : art.ART_FTCOMPOSICION;
				this.txtVidaAnaquel = art.ART_FTVIDAANAQUEL === null ? '' : art.ART_FTVIDAANAQUEL;
				this.txtEstadoFisico = art.ART_FTESTADOFISICO === null ? '' : art.ART_FTESTADOFISICO;
				this.txtColor = art.ART_FTCOLOR === null ? '' : art.ART_FTCOLOR;
				this.txtPerfilAromatico = art.ART_FTPERFILARO === null ? '' : art.ART_FTPERFILARO;
				this.txtPresentacion = art.ART_FTPRESENTACION === null ? '' : art.ART_FTPRESENTACION;
				this.txtManejoProcedimiento = art.ART_FTMANEJO === null ? '' : art.ART_FTMANEJO;
				this.txtCertificadoAnalitico = art.ART_FTCERTIFICADO === null ? '' : art.ART_FTCERTIFICADO;
				if (art.ART_FTCODIGO !== null) {
					this.txtCodigo = art.ART_FTCODIGO;
					// txtCodigo.setForeground(SWTResourceManager.getColor(128, 128, 0));
				} else {
          this.txtCodigo = 'FT0001-1342';
					Sw = 1;
				}
			
				if (art.ART_FTFECHAVIGENCIA !== null) {
          this.dateTimeFechaVigencia = new Date (art.ART_FTFECHAVIGENCIA);
				} else {
          this.dateTimeFechaVigencia = new Date(2007, 10, 1);
				}
				
				this.txtVersion = art.ART_FTVERSION === null ? '01' : art.ART_FTVERSION;
				this.txtTitulo = art.ART_FTTITULO === null ? 'FICHA TECNICA - PROVISIONAL' : art.ART_FTTITULO;
				this.txtRevisado = art.ART_FTREVISADO === null ? '' : art.ART_FTREVISADO;	
      }

    } catch(err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso artículo no termino satisfactoriamente ! ' + mensaje);
    } 
    try {
      const rs1  = await this.artService.erCliNombre(this.txtCliCodigo);
			strCliNombre = rs1 === null ? '' : rs1[0].CLI_NOMBRE;
			this.lblCLInombre = strCliNombre;

		} catch (err) {
			let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
    
    
		if (Sw == 1) {
			this.txtDeclaracion= await this.traerDeclaracion(this.lblCodArticulo) + '. Aditivo alimentario ';
	    this.txtCalidadRegulacion = 'Esta materia prima puede ser usada en alimentos considerando la Regulación Nacional de Alimentos.';
	    this.txtAplicacion = 'Industria alimenticia. Específicamente para ' + await this.traerTrnAplicacion(this.lblCodArticulo) + ' etc.';
	    this.txtComposicion = await this.traerComposionQuimica(this.lblCodArticulo) + ', etc. Son sustancias aromáticas permitidas, contiene ingredientes que pudieran sufrir variaciones en valores físico-químicos, pero por tratarse de una formula balanceada no se ven afectadas sus características gustativas.';
	    this.txtVidaAnaquel = '6, 8 o 12  Meses en su empaque original en condiciones de almacenamiento adecuadas  y sin sufrir ningún tipo de contaminación.';
	    this.txtEstadoFisico = 'LÍQUIDO / POLVO de aspecto homogéneo, libre de partículas y material extraño.';
	    this.txtColor = '';
      this.txtPerfilAromatico = await this.traerPerfilAromatico(this.lblCodArticulo) + ', etc';
      this.txtPresentacion = 'Envases plásticos PEHD grado alimenticio. Rotulación con: nombre comercial del producto, código, número de lote, peso neto, peso ';
      this.txtPresentacion = this.txtPresentacion + ' bruto, fecha elaboración y caducidad, y nombre del cliente.';
      this.txtManejoProcedimiento = 'Mantener cerrado en su empaque en un lugar seco y fresco, protegiéndolo de la luz, la intemperie, el polvo y la contaminación.';
	    this.txtManejoProcedimiento = ' En estas condiciones el producto tendrá una vida útil de 6, 8 o 12 meses.  Se recomienda usar el programa FI-FO (El que primero entra, primero sale)';
	    this.txtCertificadoAnalitico = 'Cada lote que se envíe llevará su correspondiente Certificado  de Calidad en el cual consta además de sus características físico-químicas y sensoriales el número de lote, fecha de producción y fecha de vencimiento.';
	  }
  }

  async traerDeclaracion(CodArticulo) {
		let cadena = '';
		let rs;
		cadena = '';
		
		try {
			rs = await this.artService.obtenerDeclaracion(CodArticulo);
			for (const item of rs) {
				if (item.GRUP_NOMBRE !== null) {
					cadena = item.GRUP_NOMBRE;
				}
				
				if (item.REF_NOMBRE !== null) {
					if (cadena == '') {
						cadena = item.REF_NOMBRE;
					} else {
						cadena = cadena + ', ' + item.REF_NOMBRE;
					}
				}	
			}

		} catch (err) {
			let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
		}
		
		return cadena;
  }
  
  async traerTrnAplicacion(CodArticulo) {
		let cadena = '';
		let rs;
		cadena = '';
		
		try {
      rs = await this.artService.obtenerTrnAplicacion(CodArticulo);
			for (const item of rs) {
				if(cadena !== '') {
					cadena = cadena + ' - ' + item.TRNAPLI_NOMBRE;
				} else {
					cadena = item.TRNAPLI_NOMBRE;
				}  
			}

		} catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso de traer la aplicación no termino satisfactoriamente ! ' + mensaje);
		}
		
	   return cadena;

  }
  
  async traerComposionQuimica(CodArticulo) {
		let cadena = '';
		let rs;
		cadena = '';
		
		try {
			rs = await this.artService.obtenerComposicionQuim(CodArticulo);
			for (const item of rs) {
				 if (cadena !== '') {
					 cadena = cadena + ' - ' + item.TRNCOMQUI_NOMBRE;
				 } else {
					 cadena = item.TRNCOMQUI_NOMBRE;
				 }
		        
			}

		} catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso de traer la compisición quimica no termino satisfactoriamente ! ' + mensaje);
		}
		
		return cadena;
  }
  
  async traerPerfilAromatico(CodArticulo) {
		let cadena = '';
		let rs = null;
		cadena = '';
		
		try {
			rs = await this.artService.obtenerPerfilAromatico(CodArticulo);
			for(const item of rs) {
				 if (cadena !== '') {
					 cadena = cadena + ' - ' + item.COMART_DESCRIPCION;
				 } else {
					 cadena = item.COMART_DESCRIPCION;
				 }
			}

		} catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso de traer el perfil aromático no termino satisfactoriamente ! ' + mensaje);	
		}
		
		return cadena;
	}

  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'trnfichatec',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

  cambio(event) {
    this.activarBoton('M');
  }
  

}
