import {AnexgenerarxmlService} from '../anexservicios/anexgenerarxml.service';
import {Injectable} from '@angular/core';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {MessageService} from 'primeng';
import {XMLAnexoTransaccional} from './XMLAnexoTransaccional';

@Injectable({
  providedIn: 'root'
})

export class SRIXMLGenerator {
  public  ANEXO_TRANSACCIONAL = 0xAB1;
  public  RENDIMIENTO_FINANCIERO = 0xAB2;
  public  RETENCION_COMPRA = 0xAB3;
  public  RETENCION_RENDIMIENTO_FINANCIERO = 0xAB4;
  
  private path;
  private anio: number;
  private perInf: string;
  private strSoloFactFisicas = '';
  private intNCNOFAC = 0;
  private strMicroempresas = '';
  
  
  
  constructor(public generarXmlService: AnexgenerarxmlService, private errorService: ErroresBaseDatosService, public message: MessageService,
              public xmlAnexoTransaccional: XMLAnexoTransaccional) {
  
  }
  
  
  public SRIXMLGenerator(path, tipeDocument, perInf, anio, actualiza, strSoloFactFisicas, intNCNOFAC, strMicroempresas) {
    this.path = path;
    this.perInf = perInf;
    this.anio = anio;
    this.strSoloFactFisicas = strSoloFactFisicas;
    this.strMicroempresas = strMicroempresas;
    this.intNCNOFAC = intNCNOFAC;
    this.generarDocumento(tipeDocument, actualiza);
  }
  
  async generarDocumento(tipoDocumento, actualiza) {
    
    let xml;
    let documento;
    
  
    switch (tipoDocumento) {
      case this.ANEXO_TRANSACCIONAL:
        const xmlDoc: object = {};
        // @ts-ignore
        xmlDoc.iva = {};
        
        const elemento = await this.createHeader(xmlDoc);
        // @ts-ignore
        xmlDoc.iva = elemento;
        documento = await this.xmlAnexoTransaccional.XMLAnexoTransaccional(xmlDoc, this.perInf, this.anio, this.strSoloFactFisicas,
          this.intNCNOFAC, this.strMicroempresas);
        
  
        let rs;
        try {
    
          rs = await this.generarXmlService.obtenerXML(documento);
          
          xml = rs;
          this.generarXmlService.proceso = false;
    
          const blob = new Blob([xml], {type: 'text/xml'});
          const element = document.createElement('a');
          element.setAttribute('download', this.generateName(tipoDocumento));
          element.setAttribute('href', window.URL.createObjectURL(blob));
          element.click();
          
    
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error',  mensaje);
        }
        
        break;
      
    }
    
    
    
    
  }
  
  generateName(tipeDocument): string {
    let name = '';
    switch (tipeDocument) {
    case this.ANEXO_TRANSACCIONAL:
      name = this.generarXmlService.usuarioEsquema.esquema + '_AT' + this.perInf + this.anio;
      break;
    case this.RENDIMIENTO_FINANCIERO:
      name = this.generarXmlService.usuarioEsquema.esquema + '_AT' + this.perInf + this.anio;
      break;
    case this.RETENCION_COMPRA:
      name = this.generarXmlService.usuarioEsquema.esquema + '_AT' + this.perInf + this.anio;
      break;
    case this.RETENCION_RENDIMIENTO_FINANCIERO:
      name = this.generarXmlService.usuarioEsquema.esquema + '_AT' + this.perInf + this.anio;
      break;
    }
    return name;
  }
  
  async createHeader(elemento) {
    let strMes = '';
    const element: object = new Object();
    
    if (this.strMicroempresas === 'SI') {
      if (this.perInf === '01' || this.perInf === '02' || this.perInf === '03' || this.perInf === '04' || this.perInf === '05' || this.perInf === '06') {
        strMes = '06';
      } else {
        strMes = '12';
      }
    
    } else {
      strMes = this.perInf;
    }
  
    try {
      const  dblTotalVentas = await this.totalVentas(this.perInf , String(this.anio), this.strSoloFactFisicas);
      const data = await this.generarXmlService.obtenerCompania();
      if (data.length > 0) {
        const row = data[0];
        // @ts-ignore
        element.TipoIDInformante = 'R';
        // @ts-ignore
        element.IdInformante = row.COMRUCI === null ? '' : row.COMRUCI;
        // @ts-ignore
        element.razonSocial = row.COMNOMBREP === null ? '' : row.COMNOMBREP;
        // @ts-ignore
        element.Anio = '' + this.anio;
        // @ts-ignore
        element.Mes = '' + strMes;
      
        if (this.strMicroempresas === 'SI') {
          // @ts-ignore
          element.regimenMicroempresa =  'SI';
        }
        // @ts-ignore
        element.numEstabRuc = row.COMCANTIDAD === null ? '' : String(row.COMCANTIDAD);
        // @ts-ignore
        element.totalVentas = '' + Number(dblTotalVentas).toFixed(2);
        // @ts-ignore
        element.codigoOperativo = 'IVA';
      
      
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  
  
  
  
    return element;
  }
  
  async totalVentas(strPerInf, strAnio, strSoloFacFisicas) {
    let dblTotalVentas = 0;
    
    const parametros = [];
    parametros[0] = strPerInf;
    parametros[1] = strAnio;
    parametros[2] = '01';
    parametros[3] = strSoloFacFisicas;
    parametros[4] = this.strMicroempresas;
    
    try {
      const rs = await this.generarXmlService.totalVentas(parametros);
      if (rs.length > 0) {
        dblTotalVentas = rs[0]['NVL(:B1,0)'];
      }
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso leer ventas totales de facturación no terminó satisfactoriamente.... ! ' +  mensaje);
    }
    
    return dblTotalVentas;
    
  }
  
  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'anexgenxml',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
  
  
  
}


