import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from 'src/app/usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { NomUtilidades } from '../nominterfaces/nomutilidades';

@Injectable({
  providedIn: 'root'
})
export class NomutilidadesService {

  public way: string;
  public detalleUtilidades: NomUtilidades[] = [];

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario, 
    private confIniciales: ConfInicialesService) { 
      this.way = sessionStorage.getItem('way');
    }

    buscarUtilidades(intCodigo) {
      console.log(intCodigo);
      return this.http.post<any[]>(this.way + '/nom/utilidades/c2fb5973beeca80', {
          elementos: {
            CODIGO: intCodigo
          }
        }, this.confIniciales.httpOptions()
      ).toPromise();
    }

    existeUtilidad(strAnio) {
      return this.http.post<any[]>(this.way + '/nom/utilidades/77192e77dd95640', {
          elementos: {
            ANIO: strAnio
          }
        }, this.confIniciales.httpOptions()
      ).toPromise();
    }

    listarInformacion(parametro) {
      return this.http.post<any[]>(this.way + '/nom/utilidades/2b2e76577e376', {
        elementos: {
          p_anio: parametro[0],
          p_com_codigo: parametro[1]
        }
      }, this.confIniciales.httpOptions()
      ).toPromise();
    }

    eliminarUtilidad(parametro) {
      return this.http.post<any[]>(this.way + '/nom/utilidades/eacad2f765593800', {
        elementos: {
          p_nom_trnuticodigo: parametro[0],
          p_com_codigo: parametro[1]
        }
      }, this.confIniciales.httpOptions()
      ).toPromise();
    }

    liquidarUtilidades(parametro) {
      return this.http.post<any[]>(this.way + '/nom/utilidades/1596bdd9564d53000', {
        elementos: {
          p_com_codigo: parametro[0],
          p_NOM_TRNUTICODIGO: parametro[1]
        }
      }, this.confIniciales.httpOptions()
      ).toPromise();
    }

    obtenerClave(accion) {
    console.log(accion);
      if (accion === 'Reversar') {
        return this.http.post<any>(this.way + '/nom/utilidades/107772ae736efc000', {
            elementos: {},
          }, this.confIniciales.httpOptions()
        ).toPromise();
      }
      if (accion === 'Confirmar') {
        return this.http.post<any>(this.way + '/nom/utilidades/107772ae736efc000', {
            elementos: {},
          }, this.confIniciales.httpOptions()
        ).toPromise();
      }
    }

    guardarEncUtilidad(parametro) {
      console.log(parametro);
      return this.http.post<any[]>(this.way + '/nom/utilidades/43cadbaecf7654', {
        elementos: {
          p_nom_trnuticodigo: parametro[0],
          p_com_codigo: parametro[1],
          p_anio: parametro[2],
          p_descripcion: parametro[3],
          p_nom_importe_utilidad: parametro[4],
          p_usuidentificacion: parametro[5]
        }
      }, this.confIniciales.httpOptions()
      ).toPromise();
    }

    actualizarEstadoRever(strFecha, intCodigo) {
      return this.http.post<any[]>(this.way + '/nom/utilidades/533bceb76efbb80', {
        elementos: {
          FECHA: strFecha,
          CODIGO: intCodigo
        }
      }, this.confIniciales.httpOptions()
      ).toPromise();
    }

    actualizarEstadoConfirm(strFecha, intCodigo) {
      return this.http.post<any[]>(this.way + '/nom/utilidades/a6779d6b3177c800', {
        elementos: {
          FECHA: strFecha,
          CODIGO: intCodigo
        }
      }, this.confIniciales.httpOptions()
      ).toPromise();
    }

    guardarDetUtilidad(parametro) {
      console.log(parametro);
      return this.http.post<any[]>(this.way + '/nom/utilidades/4afc6bbbeecab0', {
        elementos: {
          p_nom_trnuticodigo: parametro[0],
          p_emp_codigo: Number(parametro[1]),
          p_com_codigo: parametro[2],
          p_nom_secuencia: parametro[3],
          p_nom_diaslaborados: Number(parametro[4]),
          p_nom_tipopagoutil: parametro[5],
          p_nom_jornadaparcialper: parametro[6],
          p_nom_horas_jorparpersem: parametro[7],
          p_nom_discapacidad: parametro[8],
          p_nom_rucempcom: parametro[9],
          p_nom_dectercero: Number(parametro[10]),
          p_nom_deccuarto: Number(parametro[11]),
          p_nom_participacion_uti: Number(parametro[12]),
          p_nom_salarios_per: Number(parametro[13]),
          p_nom_fondos_reserva: Number(parametro[14]),
          p_nom_comisiones: Number(parametro[15]),
          p_nom_beneficios_adiefe: Number(parametro[16]),
          p_nom_anticipo_util: Number(parametro[17]),
          p_nom_retencion_jud: Number(parametro[18]),
          p_nom_impuesto_ret: Number(parametro[19]),
          p_nom_saldig: parametro[20],
          p_nom_tipopago_saldig: parametro[21],
          p_nom_utilidad: Number(parametro[22]),
          p_nom_descprov: Number(parametro[23]),
          p_nom_formpagocod: parametro[24],
          p_nom_formpagodesc: parametro[25],
          p_nom_bancpagocod: parametro[26],
          p_nom_bancpagodesc: parametro[27],
          p_nom_tipocuencod: parametro[28],
          p_nom_tipocuendesc: parametro[29],
          p_nom_numecuen: parametro[30],
          p_nom_netoapagar: parametro[31],
          p_nom_numcarfam: parametro[32],
        }
      }, this.confIniciales.httpOptions()
      ).toPromise();
    }

  totalLineaPagarUtilidad(item: NomUtilidades) {
    let utilidad = 0;
    let descuento_utilidad = 0;
    let anticipo_utilidad = 0;
    let retencion_utilidad = 0;
    let impuesto_utilidad = 0;
    let TotalValorBruto = 0;

    // utilidad del año actual
    utilidad = Number(item.NOM_UTILIDAD);
    // descuento de las utilidad
    descuento_utilidad = Number(item.NOM_DESCPROV);
    // anticipo de la utilidad
    anticipo_utilidad = Number(item.NOM_ANTICIPO_UTIL);
    // retención de la utilidad
    retencion_utilidad = Number(item.NOM_RETENCION_JUD);
    // impuesto de la retención
    impuesto_utilidad = Number(item.NOM_IMPUESTO_RET);
    
    TotalValorBruto = utilidad - descuento_utilidad - anticipo_utilidad - retencion_utilidad - impuesto_utilidad;
    
    return TotalValorBruto;

  }

  FunListaMaestroCargas() {
    return this.http.post<any[]>(this.way + '/nom/utilidades/20eeb2cbdbb38e0000', {
        elementos: {},
      }, this.confIniciales.httpOptions()).toPromise();
  }

  generarArchivo(strProceso, codigo) {
    return this.http.post<any[]>(this.way + '/nom/utilidades/41d772b6c8cbf8', {
        elementos: {
          p_NOM_TRNUTICODIGO: codigo,
          p_com_codigo: '01',
          p_usu_identificacion: this.usuario.identificacion
        },
        elemento: {
          PROCESO: strProceso
        },
      }, this.confIniciales.httpOptions()).toPromise();
  }

  erProcesoArchivo(codigo) {
    return this.http.post<any[]>(this.way + '/nom/utilidades/3b79de18adb232', {
        elementos: {
          CODIGO: codigo
        },
      }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarLineaDetalle(parametros) {
    return this.http.post<any[]>(this.way + '/nom/utilidades/3ab2b56576bbaa', {
        elementos: {
          p_nom_trnuticodigo: parametros[0],
          p_com_codigo: parametros[1],
          p_emp_codigo: parametros[2],
          p_nom_sencuencia: parametros[3],
        },
      }, this.confIniciales.httpOptions()).toPromise();
  }




  funGenArchTexto(codigo: number){
    return this.http.post<any>(this.way + '/nom/utilidades/83aeadb3ae', {
      elementos: {
        p_NOM_TRNUTICODIGO: codigo,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

}
