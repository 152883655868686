<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="descargas"></p-toast>
<p-tieredMenu #menu [model]="itemMenuXml" [popup]="true" (click)="accionContextMenuXml($event, documentoSeleccionado)">
</p-tieredMenu>
<p-tieredMenu #menupdf [model]="itemMenuPdf" [popup]="true"
	(click)="accionContextMenuPdf($event, documentoSeleccionado)"></p-tieredMenu>

<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px; width: 99%; background-color: white">
	<div class="p-col-12"></div>
	<div class="p-col-12"></div>
	<div class="p-col-fixed" style="width: 75px;"></div>

	<div class="p-col-fixed" style="width: 230px">
		<img height="100" src="../../../assets/layout/images/Logo_Marca.png" width="300">
	</div>
	<div class="p-col" style="text-align: right">
		<span class="blue-color"> COMPROBANTES ELECTRÓNICOS</span>
	</div>
	<div class="p-col-fixed" style="width: 75px;"></div>

	<div class="p-col-12"></div>
	<div class="p-col-12"></div>
	<div class="p-col-fixed" style="width: 75px;"></div>
	<div class="p-col-fixed" style="width: 230px; text-align: right;background-color: #0f1c5c; align-items: end">
		<span class="white-text">Bienvenido&nbsp;</span>
		<div class="p-col-12"></div>

		<span class="orange-text"> {{usuarioDescargas.CLI_NOMBRE}}</span>
		<div class="p-col-12"></div>

		<p-tabMenu #tabs styleClass="menu" [style]="{'width':'190px'}" [model]="items" activeItem="index"
			(click)="seleccionarTabs(tabs.activeItem)"></p-tabMenu>
	</div>
	<div class="p-col-9">

		<div *ngIf="index===0">
			<div class="p-col-12" style="background-color: #dddddd; text-align: center">LISTADO DE COMPROBANTES EMITIDOS
				POR EL USUARIO
			</div>

			<div class="p-col-12">
				<p-table #dt styleClass="ui-table-customers" [value]="documentos" [(selection)]="selectedDocumentos"
					dataKey="id" [rowHover]="true" [rows]="10" [rowsPerPageOptions]="[10,25,50,100]"
					[showCurrentPageReport]="true" [paginator]="true" [filterDelay]="0"
					[globalFilterFields]="['TRNDOC_PRO_RUCIDE','TRNDOC_NUMDOC','TRNDOC_FECHA','TRNDOC_CLAVEACCESO_FE']"
					currentPageReportTemplate=" Mostrando pagina {currentPage} de {totalPages} paginas."
					scrollable="true">
					<ng-template pTemplate="caption">
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<div class="p-col-12"></div>
						<span class="fcn"> </span>
						Facturas
						<span class="lc"> </span>
						Liquidación de compras&nbsp;
						<span class="nc"> </span>
						Notas de crédito&nbsp;
						<span class="nd"> </span>
						Notas de débito&nbsp;
						<span class="cr"> </span>
						Retenciones&nbsp;
						<span class="gr"> </span>
						Guías de remisión&nbsp;
						<div class="p-col-12"></div>
						<div class="ui-table-globalfilter-container">
							<input pInputText type="text" (input)="dt.filterGlobal($event.target.value,'contains')"
								placeholder="Buscar" />
						</div>
						<div class="p-col-12"></div>
					</ng-template>
					<ng-template pTemplate="header">
						<tr>
							<th pSortableColumn="TRNDOC_TIPODES" [ngStyle]="{'width':'50px'}">
								<p-sortIcon field="TRNDOC_TIPODES"></p-sortIcon>
							</th>
							<th pSortableColumn="TRNDOC_PRO_RUCIDE" [ngStyle]="{'width':'150px'}">R.U.C Emisor
								<p-sortIcon field="emisor"></p-sortIcon>
							</th>
							<th pSortableColumn="TRNDOC_NUMDOC" [ngStyle]="{'width':'150px'}">Núm de Doc
								<p-sortIcon field="TRNDOC_NUMDOC"></p-sortIcon>
							</th>
							<th pSortableColumn="TRNDOC_FECHA" [ngStyle]="{'width':'180px'}">Fecha de Emisión
								<p-sortIcon field="TRNDOC_FECHA"></p-sortIcon>
							</th>
							<th pSortableColumn="TRNDOC_CLAVEACCESO_FE" [ngStyle]="{'width':'500px'}">Clave de Acceso
								<p-sortIcon field="TRNDOC_CLAVEACCESO_FE"></p-sortIcon>
							</th>
							<th pSortableColumn="xml" [ngStyle]="{'width':'50px'}">XML
								<p-sortIcon field="xml"></p-sortIcon>
							</th>
							<th pSortableColumn="pdf" [ngStyle]="{'width':'50px'}">PDF
								<p-sortIcon field="pdf"></p-sortIcon>
							</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-documentos>
						<tr class="ui-selectable-row">
							<td [ngStyle]="{'width':'50px'}">
								<span [class]="''+documentos.TRNDOC_TIPODES"> </span>

							</td>
							<td [ngStyle]="{'width':'150px'}">
								{{documentos.TRNDOC_PRO_RUCIDE}}
							</td>
							<td [ngStyle]="{'width':'150px'}">
								{{documentos.TRNDOC_NUMDOC}}
							</td>
							<td [ngStyle]="{'width':'180px'}">
								{{documentos.TRNDOC_FECHA}}
							</td>
							<td [ngStyle]="{'width':'500px'}">
								{{documentos.TRNDOC_CLAVEACCESO_FE}}
							</td>
							<td [ngStyle]="{'width':'50px'}">
								<img height="18" width="18" src="../../../assets/images/iconos/025-file-1.png"
									(click)="menu.toggle($event); acciones(documentos)">
							</td>
							<td [ngStyle]="{'width':'50px'}">
								<img height="18" width="18" src="../../../assets/images/iconos/023-pdf-1.png"
									(click)="menupdf.toggle($event); acciones(documentos)">
							</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage">
						<tr>
							<td colspan="8" style="text-align: left">Ningun dato disponible en esta tabla</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</div>
		<div *ngIf="index===1">
			<div class="p-col-12" style="background-color: #dddddd; text-align: center">BÚSQUEDA DE COMPROBANTES
			</div>
			<div class="p-col-12">
				<div class="p-col-8">Clave de Acceso&nbsp;
				</div>
				<div class="p-col-8">
					<input type="text" style="width: 300px" name="clave" id="clave" [(ngModel)]="clave">
				</div>
				<div class="p-col-12"></div>
				<div class="p-col-8">Número de Documento&nbsp;</div>
				<div class="p-col-8">
					<input type="text" name="numd" id="numd" [(ngModel)]="numdoc">
				</div>

				<div class="p-col-8">Empresa&nbsp;</div>
				<div class="p-col-8">
					<input type="text" name="emp" id="emp" [(ngModel)]="proveedor">
				</div>

				<div class="p-col-8">Fecha de Emisión&nbsp; <span>Desde-Hasta</span></div>
				<div class="p-col-8">
					<p-calendar id="fechadesde" [inputStyle]="{width: '80%', height: '20px'}" [(ngModel)]="fecha"
						dateFormat="dd/mm/yy">
					</p-calendar>
					<span>- </span>
					<p-calendar id="fechahasta" [inputStyle]="{width: '80%', height: '20px'}" [(ngModel)]="fechah"
						dateFormat="dd/mm/yy">
					</p-calendar>
				</div>

				<div class="p-col-8">Tipo de Documento&nbsp;</div>
				<div class="p-col-8">
					<p-dropdown [options]="cmbdocumentos" [style]="{'width':'200px'}" optionLabel="name"
						(onClick)="llenarcmbDocs()" (onChange)="busqdoc($event)"></p-dropdown>
				</div>

				<div class="p-col-12"></div>
				<label id="btn_buscar" type="button" style="background-color:#0f1c5c;" class="btn white-text"
					(click)="consultarComprobantes()">Buscar</label>
				<p-table #dt styleClass="ui-table-customers" [value]="documentosBusqueda" [rowHover]="true" [rows]="10"
					[rowsPerPageOptions]="[10,25,50,100]" [showCurrentPageReport]="true" [paginator]="true"
					[filterDelay]="0"
					[globalFilterFields]="['TRNDOC_PRO_RUCIDE','TRNDOC_NUMDOC','TRNDOC_FECHA','TRNDOC_CLAVEACCESO_FE']"
					currentPageReportTemplate=" Mostrando pagina {currentPage} de {totalPages} paginas."
					scrollable="true">
					<ng-template pTemplate="caption">
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<div class="p-col-12"></div>
						<span class="fcn"> </span>
						Facturas
						<span class="lc"> </span>
						Liquidación de compras&nbsp;
						<span class="nc"> </span>
						Notas de crédito&nbsp;
						<span class="nd"> </span>
						Notas de débito&nbsp;
						<span class="cr"> </span>
						Retenciones&nbsp;
						<span class="gr"> </span>
						Guías de remisión&nbsp;
						<div class="p-col-12"></div>

						<div class="p-col-12"></div>
					</ng-template>
					<ng-template pTemplate="header">
						<tr>
							<th pSortableColumn="TRNDOC_TIPODES" [ngStyle]="{'width':'50px'}">
								<p-sortIcon field="TRNDOC_TIPODES"></p-sortIcon>
							</th>
							<th pSortableColumn="TRNDOC_PRO_RUCIDE" [ngStyle]="{'width':'150px'}">R.U.C Emisor
								<p-sortIcon field="emisor"></p-sortIcon>
							</th>
							<th pSortableColumn="TRNDOC_NUMDOC" [ngStyle]="{'width':'150px'}">Núm de Doc
								<p-sortIcon field="TRNDOC_NUMDOC"></p-sortIcon>
							</th>
							<th pSortableColumn="TRNDOC_FECHA" [ngStyle]="{'width':'180px'}">Fecha de Emisión
								<p-sortIcon field="TRNDOC_FECHA"></p-sortIcon>
							</th>
							<th pSortableColumn="TRNDOC_CLAVEACCESO_FE" [ngStyle]="{'width':'500px'}">Clave de Acceso
								<p-sortIcon field="TRNDOC_CLAVEACCESO_FE"></p-sortIcon>
							</th>
							<th pSortableColumn="xml" [ngStyle]="{'width':'50px'}">XML
								<p-sortIcon field="xml"></p-sortIcon>
							</th>
							<th pSortableColumn="pdf" [ngStyle]="{'width':'50px'}">PDF
								<p-sortIcon field="pdf"></p-sortIcon>
							</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-documentosBusqueda>
						<tr class="ui-selectable-row">
							<td [ngStyle]="{'width':'50px'}">
								<span [class]="''+documentosBusqueda.TRNDOC_TIPODES"></span>
							</td>
							<td [ngStyle]="{'width':'150px'}">
								{{documentosBusqueda.TRNDOC_PRO_RUCIDE}}
							</td>
							<td [ngStyle]="{'width':'150px'}">
								{{documentosBusqueda.TRNDOC_NUMDOC}}
							</td>
							<td [ngStyle]="{'width':'180px'}">
								{{documentosBusqueda.TRNDOC_FECHA}}
							</td>
							<td [ngStyle]="{'width':'500px'}">
								{{documentosBusqueda.TRNDOC_CLAVEACCESO_FE}}
							</td>
							<td [ngStyle]="{'width':'50px'}">
								<img height="18" width="18" src="../../../assets/images/iconos/025-file-1.png"
									(click)="menu.toggle($event); acciones(documentosBusqueda)">
							</td>
							<td [ngStyle]="{'width':'50px'}">
								<img height="18" width="18" src="../../../assets/images/iconos/023-pdf-1.png"
									(click)="menupdf.toggle($event); acciones(documentosBusqueda)">
							</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage">
						<tr>
							<td colspan="8" style="text-align: left">Ningun dato disponible en esta tabla</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</div>
		<div *ngIf="index===2">
			<div class="p-col-12" style="background-color: #dddddd; text-align: center">CAMBIAR CONTRASEÑA DEL USUARIO
			</div>
			<div class="p-col-12">
				<div class="p-col-8">Contraseña Actual&nbsp;
					<span style="color: red; margin-right: 100px">(*)</span>
					<input type="password" [(ngModel)]="currentpsw" name="current_psw" id="current_psw">

				</div>

				<div class="p-col-12"></div>
				<div class="p-col-8">Nueva Contraseña&nbsp;
					<span style="color: red; margin-right: 100px">(*)</span>

					<input type="password" [(ngModel)]="newpsw" name="new_psw" id="new_psw"></div>
				<div class="p-col-12"></div>
				<div class="p-col-8">Confirmar Nueva Contraseña&nbsp;
					<span style="color: red; margin-right: 35px">(*)</span>

					<input type="password" [(ngModel)]="newspsw" name="confirm_new_psw" id="confirm_new_psw"></div>
				<div class="p-col-12"></div>
				<div class="p-col-4">
					<label id="btn_cambiar" type="button" style="background-color:#0f1c5c;" class="btn white-text"
						(click)="changePSW()">Actualizar Contraseña</label>
				</div>
			</div>
		</div>

		<div *ngIf="index===3">
			salir()

		</div>

	</div>
	<div class="p-col-fixed" style="width: 75px;"></div>
	<div class="p-col-12"></div>
	<div class="p-col-fixed" style="width: 75px;"></div>
	<div class="p-col-10" style="text-align: left;background-color: #0f1c5c">
		<span class="white-text">&copy; Derechos reservados 2020</span>

	</div>
	<div class="p-col-fixed" style="width: 75px;"></div>


</div>