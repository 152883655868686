import { Injectable, destroyPlatform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from 'src/app/usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import { DatePipe } from '@angular/common';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { nomdetImpuRent, nomcabImpuRent } from '../nominterfaces/nomrhucabimpurent';
import { isNullOrUndefined } from 'util';
import { nomliqudetalle, nomliquvarios, nomliqucabecera } from '../nominterfaces/nomliqudeciterc';
import { NomcabimpurentserviceService } from './nomcabimpurentservice.service';
import { MessageService } from 'primeng';
import { areAllEquivalent } from '@angular/compiler/src/output/output_ast';
@Injectable({
  providedIn: 'root'
})
export class NomliqudecitercService {

  public way: string;

  public nomliqdet: nomliqudetalle[] = [];
  public nomliquvar: nomliquvarios[] = [];
  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private messageService: MessageService,
    private confIniciales: ConfInicialesService,
    public impurentService: NomcabimpurentserviceService,
    private pipe: DatePipe) {
    this.way = sessionStorage.getItem('way');
    this.usuario.identificacion;
  }
  async primeroliqu() {
    return await this.http.post(this.way + '/nom/nomliqudeciterc/gwdaspc4d0i1sph', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async ultimoliqu() {
    return await this.http.post(this.way + '/nom/nomliqudeciterc/pu7hnl0fi44vu25', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async anteriorliqu(ENCLIQUBENESOCI_CODIGO) {
    return await this.http.post(this.way + '/nom/nomliqudeciterc/kfqsp831l31djel', {
      elementos: {
        lblENCLIQUBENESOCI_CODIGO: ENCLIQUBENESOCI_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async siguienteliqu(ENCLIQUBENESOCI_CODIGO) {
    return await this.http.post(this.way + '/nom/nomliqudeciterc/fo0oooz3oleext0', {
      elementos: {
        lblENCLIQUBENESOCI_CODIGO: ENCLIQUBENESOCI_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async lisliqucabecera(ENCLIQUBENESOCI_CODIGO) {
    return await this.http.post(this.way + '/nom/nomliqudeciterc/nrcd089xb5dn2az', {
      elementos: {
        lblENCLIQUBENESOCI_CODIGO: ENCLIQUBENESOCI_CODIGO === null ? 0 : ENCLIQUBENESOCI_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async lisliqudetalle(ENCLIQUBENESOCI_CODIGO, ajuste: nomliqucabecera) {
    return await this.http.post(this.way + '/nom/nomliqudeciterc/5jhcrf9h654kzq1', {
      elementos: {
        lblENCLIQUBENESOCI_CODIGO: ENCLIQUBENESOCI_CODIGO,
        p_maerubro_codrubr: String(ajuste.MAERUBRO_CODRUBR),
        p_feciniprov: this.cambiarfechanull(ajuste.ENCLIQUBENESOCI_FECINIPROV),
        p_fecfinprov: this.cambiarfechanull(ajuste.ENCLIQUBENESOCI_FECFINPROV),
        p_periodo_codigo: parseInt(ajuste.PERIODO_CODIGO)
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }
  async lisliquvarios(ENCLIQUBENESOCI_CODIGO) {
    return await this.http.post(this.way + '/nom/nomliqudeciterc/v2fezh8fb671z8l', {
      elementos: {
        lblENCLIQUBENESOCI_CODIGO: ENCLIQUBENESOCI_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async busIDEmpl(parametro) {
    let resp = "";
    if (parametro == null || parametro == undefined) {
      parametro = '';
    }

    return new Promise<any>(async (resolve) => {
      await this.impurentService.erIDEmpl(parametro).then(eR => {
        if (eR !== null) {
          if (eR[0] !== undefined) {
            resolve(eR[0]);
          } else {
            resolve(resp);
          }
        } else {
          resolve(resp);
        }
      });
    });
  }
  async convertcabecera(cabe: nomliqucabecera) {
    cabe.ENCLIQUBENESOCI_FECINI = this.cambiarfechanull(cabe.ENCLIQUBENESOCI_FECINI);
    cabe.ENCLIQUBENESOCI_FECFIN = this.cambiarfechanull(cabe.ENCLIQUBENESOCI_FECFIN);
    cabe.ENCLIQUBENESOCI_FECINIPROV = this.cambiarfechanull(cabe.ENCLIQUBENESOCI_FECINIPROV);
    cabe.ENCLIQUBENESOCI_FECFINPROV = this.cambiarfechanull(cabe.ENCLIQUBENESOCI_FECFINPROV);
    cabe.PERIODO_FECINI = this.cambiarfechanull(cabe.PERIODO_FECINI);
    cabe.PERIODO_FECFIN = this.cambiarfechanull(cabe.PERIODO_FECFIN);
    cabe.ENCLIQUBENESOCI_SALABASIUNIF = this.cambiarFloatFixed(cabe.ENCLIQUBENESOCI_SALABASIUNIF);
    const descrip = await this.bustablarubro(cabe.MAERUBRO_CODRUBR).then(res => {
      cabe.descRubro = res[0].REF_NOMBRE;
    })
    return cabe;
  }
  /*aceptar*/
  funVerificaInformCabecera(cab: nomliqucabecera) {
    let error = "";
    if (cab.MAERUBRO_CODRUBR == "") {
      return "Error, ingrese el rubro";
    }
    if (cab.ENCLIQUBENESOCI_FECINIPROV == "") {
      return "Error, ingrese la fecha inicial de provisión del rubro en el maestro de rubros";
    }
    if (cab.ENCLIQUBENESOCI_FECFINPROV == "") {
      return "Error, ingrese la fecha final de provisión del rubro en el maestro de rubros";
    }
    if (cab.PERIODO_CODIGO == "") {
      return "Error, ingrese el periodo";
    }
    if (cab.ENCLIQUBENESOCI_FECINI == "") {
      return "Error, ingrese la fecha inicial del periodo";
    }
    if (cab.PERIODO_FECFIN == "") {
      return "Error, ingrese la fecha final del periodo";
    }
    let f1: string[] = cab.PERIODO_FECINI.split('/');
    let f2: string[] = cab.ENCLIQUBENESOCI_FECFINPROV.split('/');
    let feini: Date = new Date(parseInt(f1[2]), parseInt(f1[1]) - 1, parseInt(f1[0]));
    let fefin: Date = new Date(parseInt(f2[2]), parseInt(f2[1]) - 1, parseInt(f2[0]));
    if (fefin >= feini) {
      return "Error, la fecha final de la provisión debe ser menor al período en proceso";
    }
    return error;
  }
  async conVeriProvGenePerio(cod: nomliqucabecera) {

    return await this.http.post(this.way + '/nom/nomliqudeciterc/ngv0zi08r27luxi', {
      elementos: {
        txtENCLIQUBENESOCI_FECINIPROV: String(cod.ENCLIQUBENESOCI_FECINIPROV),
        txtENCLIQUBENESOCI_FECFINPROV: String(cod.ENCLIQUBENESOCI_FECFINPROV),
        txtMAERUBRO_CODRUBR: String(cod.MAERUBRO_CODRUBR)
      },
    }, this.confIniciales.httpOptions()).toPromise();

  }

  conVeriProvGenePerioNew(cod: nomliqucabecera): Promise<[]> {
    return this.http.post<[]>(this.way + '/nom/nomliqudeciterc/ngv0zi08r27luxi', {
      elementos: {
        txtENCLIQUBENESOCI_FECINIPROV: String(cod.ENCLIQUBENESOCI_FECINIPROV),
        txtENCLIQUBENESOCI_FECFINPROV: String(cod.ENCLIQUBENESOCI_FECFINPROV),
        txtMAERUBRO_CODRUBR: String(cod.MAERUBRO_CODRUBR)
      },
    }, this.confIniciales.httpOptions()).toPromise();

  }
  async funEliminaDetalleBeneSoci(cod: nomliqucabecera) {

    return await this.http.post(this.way + '/nom/nomliqudeciterc/fxc6zwf47bgix14', {
      elementos: {
        p_encliqubenesoci_codigo: parseInt(cod.ENCLIQUBENESOCI_CODIGO)
      },
    }, this.confIniciales.httpOptions()).toPromise();

  }

  funEliminaDetalleBeneSociNew(cod: nomliqucabecera): Promise<[]> {

    return this.http.post<[]>(this.way + '/nom/nomliqudeciterc/fxc6zwf47bgix14', {
      elementos: {
        p_encliqubenesoci_codigo: parseInt(cod.ENCLIQUBENESOCI_CODIGO)
      },
    }, this.confIniciales.httpOptions()).toPromise();

  }

  async elimtrapenliqbensocrol(cod: nomliqucabecera) {

    return await this.http.post(this.way + '/nom/nomliqudeciterc/j72232d6jj0nl6k', {
      elementos: {
        p_MAERUBRO_CODRUBR: cod.MAERUBRO_CODRUBR,
        p_TRNBENESOCI_FECINIPROV: cod.ENCLIQUBENESOCI_FECINIPROV,
        p_TRNBENESOCI_FECfinPROV: cod.ENCLIQUBENESOCI_FECFINPROV
      },
    }, this.confIniciales.httpOptions()).toPromise();

  }
  async insertrapenliqbensocrol(cabe: nomliqucabecera) {

    return await this.http.post(this.way + '/nom/nomliqudeciterc/tsywketfc9hsbkq', {
      elementos: {
        p_MAERUBRO_CODRUBR: cabe.MAERUBRO_CODRUBR,
        p_TRNBENESOCI_FECINIPROV: cabe.ENCLIQUBENESOCI_FECINIPROV,
        p_TRNBENESOCI_FECfinPROV: cabe.ENCLIQUBENESOCI_FECFINPROV,
        p_PERIODO_FECINI: cabe.PERIODO_FECINI
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //
  async funlistadecimos(cabe: nomliqucabecera) {

    return await this.http.post(this.way + '/nom/nomliqudeciterc/xo3hy8ls9n5o6gw', {
      elementos: {
        p_maerubro_codrubr: cabe.MAERUBRO_CODRUBR,
        p_fecini: cabe.ENCLIQUBENESOCI_FECINIPROV,
        p_fecfin: cabe.ENCLIQUBENESOCI_FECFINPROV,
        p_com_codigo: '01',
        p_periodo_codigo: cabe.PERIODO_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async funlistadecimosWeb(cabe: nomliqucabecera) {
    console.log(cabe);
    return await this.http.post(this.way + '/nom/nomliqudeciterc/wu8smosu7suxloz', {
      elementos: {
        p_maerubro_codrubr: cabe.MAERUBRO_CODRUBR,
        p_fecini: cabe.ENCLIQUBENESOCI_FECINIPROV,
        p_fecfin: cabe.ENCLIQUBENESOCI_FECFINPROV,
        p_com_codigo: '01',
        p_periodo_codigo: cabe.PERIODO_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  /*fin aceptar*/
  /*fin nuevo*/
  async periodoActual() {
    return await this.http.post(this.way + '/nom/nomliqudeciterc/fd4tac4z1br14jb', {
      elementos: {
        p_com_codigo: '01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async sueldoActual() {
    return await this.http.post(this.way + '/nom/nomliqudeciterc/gb4rinovgi84q2h', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  /*fin nuevo*/
  /* borrar*/
  async borrarliq(cabe: nomliqucabecera, lblASI_NRO) {
    return await this.http.post(this.way + '/nom/nomliqudeciterc/3li6vmshil9mcom', {
      elementos: {
        p_encliqubenesoci_codigo: parseInt(cabe.ENCLIQUBENESOCI_CODIGO),
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  /*fin borrar*/

  /*opcion guardar*/
  async guardarcabliqNew(cabe: nomliqucabecera, usuario: string) {
    console.log(cabe);
    console.log(usuario);
    let as = {
      p_encliqubenesoci_codigo: cabe.ENCLIQUBENESOCI_CODIGO,
      p_com_codigo: '01',
      p_usu_identificacion: usuario,
      p_encliqubenesoci_feciniprov: cabe.ENCLIQUBENESOCI_FECINIPROV,
      p_encliqubenesoci_fecfinprov: cabe.ENCLIQUBENESOCI_FECFINPROV,
      p_periodo_codigo: cabe.PERIODO_CODIGO,
      p_periodo_fecini: cabe.PERIODO_FECINI,
      p_periodo_fecfin: cabe.PERIODO_FECFIN,
      p_encliqubenesoci_salabasiunif: parseFloat(cabe.ENCLIQUBENESOCI_SALABASIUNIF),
      p_maerubro_codrubr: cabe.MAERUBRO_CODRUBR,
    }
    console.log(as);
    return this.http.post<[]>(this.way + '/nom/nomliqudeciterc/76rf1qstd5dbtcx', {
      elementos: {
        p_encliqubenesoci_codigo: cabe.ENCLIQUBENESOCI_CODIGO,
        p_com_codigo: '01',
        p_usu_identificacion: usuario,
        p_encliqubenesoci_feciniprov: cabe.ENCLIQUBENESOCI_FECINIPROV,
        p_encliqubenesoci_fecfinprov: cabe.ENCLIQUBENESOCI_FECFINPROV,
        p_periodo_codigo: cabe.PERIODO_CODIGO,
        p_periodo_fecini: cabe.PERIODO_FECINI,
        p_periodo_fecfin: cabe.PERIODO_FECFIN,
        p_encliqubenesoci_salabasiunif: parseFloat(cabe.ENCLIQUBENESOCI_SALABASIUNIF),
        p_maerubro_codrubr: cabe.MAERUBRO_CODRUBR,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async guardarcabliq(cabe: nomliqucabecera, usuario: string) {
    console.log(cabe);
    console.log(usuario);
    let as = {
      p_encliqubenesoci_codigo: cabe.ENCLIQUBENESOCI_CODIGO,
      p_com_codigo: '01',
      p_usu_identificacion: usuario,
      p_encliqubenesoci_feciniprov: cabe.ENCLIQUBENESOCI_FECINIPROV,
      p_encliqubenesoci_fecfinprov: cabe.ENCLIQUBENESOCI_FECFINPROV,
      p_periodo_codigo: cabe.PERIODO_CODIGO,
      p_periodo_fecini: cabe.PERIODO_FECINI,
      p_periodo_fecfin: cabe.PERIODO_FECFIN,
      p_encliqubenesoci_salabasiunif: parseFloat(cabe.ENCLIQUBENESOCI_SALABASIUNIF),
      p_maerubro_codrubr: cabe.MAERUBRO_CODRUBR,
    }
    console.log(as);
    return await this.http.post(this.way + '/nom/nomliqudeciterc/76rf1qstd5dbtcx', {
      elementos: {
        p_encliqubenesoci_codigo: cabe.ENCLIQUBENESOCI_CODIGO,
        p_com_codigo: '01',
        p_usu_identificacion: usuario,
        p_encliqubenesoci_feciniprov: cabe.ENCLIQUBENESOCI_FECINIPROV,
        p_encliqubenesoci_fecfinprov: cabe.ENCLIQUBENESOCI_FECFINPROV,
        p_periodo_codigo: cabe.PERIODO_CODIGO,
        p_periodo_fecini: cabe.PERIODO_FECINI,
        p_periodo_fecfin: cabe.PERIODO_FECFIN,
        p_encliqubenesoci_salabasiunif: parseFloat(cabe.ENCLIQUBENESOCI_SALABASIUNIF),
        p_maerubro_codrubr: cabe.MAERUBRO_CODRUBR,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async guardardetliq(det: nomliqudetalle, orden, ENCLIQUBENESOCI_CODIGO) {
    let elementos = {
      p_encliqubenesoci_codigo: ENCLIQUBENESOCI_CODIGO,
      p_detliqubenesoci_numsec: det.DETLIQUBENESOCI_NUMSEC,
      p_com_codigo: '01',
      p_emp_codigo: parseInt(det.EMP_CODIGO),
      p_detliqubenesoci_cargiessdesc: det.DETLIQUBENESOCI_CARGIESSDESC,
      p_detliqubenesoci_sexo: det.DETLIQUBENESOCI_SEXO,
      p_detliqubenesoci_porcdisc: det.DETLIQUBENESOCI_PORCDISC,
      p_detliqubenesoci_trabjornparc: det.DETLIQUBENESOCI_TRABJORNPARC,
      p_detliqubenesoci_horatiemparc: this.cambiarFloat(det.DETLIQUBENESOCI_HORATIEMPARC),
      p_detliqubenesoci_acumhoraprov: this.cambiarFloat(det.DETLIQUBENESOCI_ACUMHORAPROV),
      p_detliqubenesoci_acumdiaprtot: this.cambiarFloat(det.DETLIQUBENESOCI_ACUMDIAPRTOT),
      p_detliqubenesoci_acumbasprtot: this.cambiarFloat(det.DETLIQUBENESOCI_ACUMBASPRTOT),
      p_detliqubenesoci_acumvalprtot: this.cambiarFloat(det.DETLIQUBENESOCI_ACUMVALPRTOT),
      p_detliqubenesoci_acumdiapaga: this.cambiarFloat(det.DETLIQUBENESOCI_ACUMDIAPAGA),
      p_detliqubenesoci_acumbasprpag: this.cambiarFloat(det.ETLIQUBENESOCI_ACUMBASPRPAG),
      p_detliqubenesoci_acumvalprpag: this.cambiarFloat(det.DETLIQUBENESOCI_ACUMVALPRPAG),
      p_detliqubenesoci_ajusprov: this.cambiarFloat(det.AJUSTE_PROV),
      p_detliqubenesoci_antiprov: this.cambiarFloat(det.DETLIQUBENESOCI_ANTIPROV),
      p_detliqubenesoci_descprov: this.cambiarFloat(det.DETLIQUBENESOCI_DESCPROV),
      p_detliqubenesoci_formpagodesc: det.DETLIQUBENESOCI_FORMPAGODESC,
      p_detliqubenesoci_bancpagodesc: det.DETLIQUBENESOCI_BANCPAGODESC,
      p_detliqubenesoci_tipocuendesc: det.DETLIQUBENESOCI_TIPOCUENDESC,
      p_detliqubenesoci_numecuen: det.DETLIQUBENESOCI_NUMECUEN,
      p_detliqubenesoci_orden: parseInt(orden),
      p_detliqubenesoci_cargiesscod: det.DETLIQUBENESOCI_CARGIESSCOD,
      p_detliqubenesoci_formpagocod: det.DETLIQUBENESOCI_FORMPAGOCOD,
      p_detliqubenesoci_tipocuencod: det.DETLIQUBENESOCI_TIPOCUENCOD,
      p_detliqubenesoci_bancpagocod: det.DETLIQUBENESOCI_BANCPAGOCOD,
      p_detliqubenesoci_netoapagar: this.cambiarFloat(det.DETLIQUBENESOCI_NETOAPAGAR),
      p_detliqubenesoci_descretejudi: this.cambiarFloat(det.DETLIQUBENESOCI_DESCRETEJUDI),
    };
    console.log(elementos);
    return await this.http.post(this.way + '/nom/nomliqudeciterc/l5i4r6q01acxp2m', {
      elementos: {
        p_encliqubenesoci_codigo: ENCLIQUBENESOCI_CODIGO,
        p_detliqubenesoci_numsec: det.DETLIQUBENESOCI_NUMSEC,
        p_com_codigo: '01',
        p_emp_codigo: parseInt(det.EMP_CODIGO),
        p_detliqubenesoci_cargiessdesc: det.DETLIQUBENESOCI_CARGIESSDESC,
        p_detliqubenesoci_sexo: det.DETLIQUBENESOCI_SEXO,
        p_detliqubenesoci_porcdisc: det.DETLIQUBENESOCI_PORCDISC,
        p_detliqubenesoci_trabjornparc: det.DETLIQUBENESOCI_TRABJORNPARC,
        p_detliqubenesoci_horatiemparc: this.cambiarFloat(det.DETLIQUBENESOCI_HORATIEMPARC),
        p_detliqubenesoci_acumhoraprov: this.cambiarFloat(det.DETLIQUBENESOCI_ACUMHORAPROV),
        p_detliqubenesoci_acumdiaprtot: this.cambiarFloat(det.DETLIQUBENESOCI_ACUMDIAPRTOT),
        p_detliqubenesoci_acumbasprtot: this.cambiarFloat(det.DETLIQUBENESOCI_ACUMBASPRTOT),
        p_detliqubenesoci_acumvalprtot: this.cambiarFloat(det.DETLIQUBENESOCI_ACUMVALPRTOT),
        p_detliqubenesoci_acumdiapaga: this.cambiarFloat(det.DETLIQUBENESOCI_ACUMDIAPAGA),
        p_detliqubenesoci_acumbasprpag: this.cambiarFloat(det.ETLIQUBENESOCI_ACUMBASPRPAG),
        p_detliqubenesoci_acumvalprpag: this.cambiarFloat(det.DETLIQUBENESOCI_ACUMVALPRPAG),
        p_detliqubenesoci_ajusprov: this.cambiarFloat(det.AJUSTE_PROV),
        p_detliqubenesoci_antiprov: this.cambiarFloat(det.DETLIQUBENESOCI_ANTIPROV),
        p_detliqubenesoci_descprov: this.cambiarFloat(det.DETLIQUBENESOCI_DESCPROV),
        p_detliqubenesoci_formpagodesc: det.DETLIQUBENESOCI_FORMPAGODESC,
        p_detliqubenesoci_bancpagodesc: det.DETLIQUBENESOCI_BANCPAGODESC,
        p_detliqubenesoci_tipocuendesc: det.DETLIQUBENESOCI_TIPOCUENDESC,
        p_detliqubenesoci_numecuen: det.DETLIQUBENESOCI_NUMECUEN,
        p_detliqubenesoci_orden: parseInt(orden),
        p_detliqubenesoci_cargiesscod: det.DETLIQUBENESOCI_CARGIESSCOD,
        p_detliqubenesoci_formpagocod: det.DETLIQUBENESOCI_FORMPAGOCOD,
        p_detliqubenesoci_tipocuencod: det.DETLIQUBENESOCI_TIPOCUENCOD,
        p_detliqubenesoci_bancpagocod: det.DETLIQUBENESOCI_BANCPAGOCOD,
        p_detliqubenesoci_netoapagar: this.cambiarFloat(det.DETLIQUBENESOCI_NETOAPAGAR),
        p_detliqubenesoci_descretejudi: this.cambiarFloat(det.DETLIQUBENESOCI_DESCRETEJUDI),
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //
  async guardarvarliq(vari: nomliquvarios, orden, numero) {
    return await this.http.post(this.way + '/nom/nomliqudeciterc/wgdy8x99eo67h0i', {
      elementos: {
        p_encliqubenesoci_codigo: parseInt(numero),
        p_detliqubensocvar_numsec: parseInt(vari.DETLIQUBENSOCVAR_NUMSEC),
        p_com_codigo: '01',
        p_detliqubensocvar_descripcion: vari.DETLIQUBENSOCVAR_DESCRIPCION,
        p_detliqubensocvar_debe: parseFloat(vari.DETLIQUBENSOCVAR_DEBE),
        p_detliqubensocvar_orden: parseInt(orden),
        p_con_codigo: vari.CON_CODIGO,
        p_con_comcodigo: vari.CON_COMCODIGO,
        p_detliqubensocvar_haber: parseFloat(vari.DETLIQUBENSOCVAR_HABER),
        p_cen_codigo: vari.CEN_CODIGO,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  /*fin guardar*/
  /*eliminar varios*/
  async eliminaLineaDetVarios(vari: nomliquvarios) {
    return await this.http.post(this.way + '/nom/nomliqudeciterc/v71ayiil1tivrnb', {
      elementos: {
        p_encliqubenesoci_codigo: parseInt(vari.ENCLIQUBENESOCI_CODIGO),
        p_detliqubensocvar_numsec: parseInt(vari.DETLIQUBENSOCVAR_NUMSEC)
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  /*eliminar varios*/
  /*eliminar asiento */
  async elimasieconta(cabe: nomliqucabecera, asi_nro) {
    return await this.http.post(this.way + '/nom/nomliqudeciterc/6nkobp25zhs3g5f', {
      elementos: {
        p_encliqubenesoci_codigo: parseInt(cabe.ENCLIQUBENESOCI_CODIGO),
        p_asi_nro: asi_nro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  /*eliminar asiento */
  //
  funCalcTotaNetoAPagar(det: nomliqudetalle) {    
    let tota = det.DETLIQUBENESOCI_ACUMVALPRTOT + det.DETLIQUBENESOCI_AJUSPROV;    
    tota = tota - det.DETLIQUBENESOCI_ANTIPROV;
    tota = tota - det.DETLIQUBENESOCI_DESCPROV;
    tota = tota - det.DETLIQUBENESOCI_DESCRETEJUDI;
    return tota;
  }
  //botones
  async funCtrlAsienContableCuadrado(guardar: boolean, lblENCLIQUBENESOCI_CODIGO) {
    if (guardar == true) {
      return true;
    }
    if (lblENCLIQUBENESOCI_CODIGO == 0) {
      return true;
    }
    try {
      const asicua = await this.validarAsientoCuadro(lblENCLIQUBENESOCI_CODIGO);
      if (asicua[0].V_RETORNO == 0) {
        return false;
      } else {
        return true;
      }
    } catch (e) {
      return false;
    }

    return true;
  }
  async validarAsientoCuadro(encliqubenesoci_codigo) {

    return await this.http.post(this.way + '/nom/nomliqudeciterc/74u2wiltxwikc2o', {
      elementos: {
        p_encliqubenesoci_codigo: parseInt(encliqubenesoci_codigo),
        p_com_codigo: '01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async generarasiento(encliqubenesoci_codigo, user) {

    return await this.http.post(this.way + '/nom/nomliqudeciterc/28tht7e3sy84oxy', {
      elementos: {
        p_encliqubenesoci_codigo: parseInt(encliqubenesoci_codigo),
        p_com_codigo: '01',
        p_usuidentificacion: user,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async elimdeciatablatransac(cabe: nomliqucabecera) {

    return await this.http.post(this.way + '/nom/nomliqudeciterc/3qx44osx6nyb5s8', {
      elementos: {
        p_MAERUBRO_CODRUBR: cabe.MAERUBRO_CODRUBR,
        p_TRNBENESOCI_FECINIPROV: cabe.ENCLIQUBENESOCI_FECFINPROV,
        p_TRNBENESOCI_FECfinPROV: cabe.ENCLIQUBENESOCI_FECFINPROV
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async consubenesocpend(cabe: nomliqucabecera) {

    return await this.http.post(this.way + '/nom/nomliqudeciterc/tsywketfc9hsbkq', {
      elementos: {
        p_maerubro_codrubr: cabe.MAERUBRO_CODRUBR,
        p_fecini: cabe.PERIODO_FECINI,
        p_fecfin: cabe.PERIODO_FECFIN,
        p_com_codigo: '01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async busquedaTabla(ajuste: nomliqucabecera, deta: nomliqudetalle[]) {
    try {
      let i = 0;
      for (let a of deta) {
        try {
          let ape_nom: String[] = a.NOMBRE.split(' ');
          a.DETLIQUBENESOCI_AJUSPROV = a.AJUSTE_PROV;
          a.EMP_NOMBRE = ape_nom[2] + " " + ape_nom[3];
          a.EMP_APELLIDO = ape_nom[0] + " " + ape_nom[1];
          a.EMP_CODIGO2 = a.EMP_CODIGO2;
          a.dblTOTA_APAGAR = this.cambiarFloatFixed(this.funCalcTotaNetoAPagar(a));
          a.DETLIQUBENESOCI_HORATIEMPARC = this.cambiarFloatFixed(a.DETLIQUBENESOCI_HORATIEMPARC);
          a.DETLIQUBENESOCI_ACUMHORAPROV = this.cambiarFloatFixed(a.DETLIQUBENESOCI_ACUMHORAPROV);
          a.DETLIQUBENESOCI_ACUMDIAPRTOT = this.cambiarFloatFixed(a.DETLIQUBENESOCI_ACUMDIAPRTOT);
          a.DETLIQUBENESOCI_ACUMBASPRTOT = this.cambiarFloatFixed(a.DETLIQUBENESOCI_ACUMBASPRTOT);
          a.DETLIQUBENESOCI_ACUMVALPRTOT = this.cambiarFloatFixed(a.DETLIQUBENESOCI_ACUMVALPRTOT);
          a.DETLIQUBENESOCI_ACUMDIAPAGA = this.cambiarFloatFixed(a.DETLIQUBENESOCI_ACUMDIAPAGA);
          a.DETLIQUBENESOCI_ACUMBASPRPAG = this.cambiarFloatFixed(a.DETLIQUBENESOCI_ACUMBASPRPAG);
          a.DETLIQUBENESOCI_ACUMVALPRPAG = this.cambiarFloatFixed(a.DETLIQUBENESOCI_ACUMVALPRPAG);
          a.DETLIQUBENESOCI_AJUSPROV = this.cambiarFloatFixed(a.DETLIQUBENESOCI_AJUSPROV);
          a.DETLIQUBENESOCI_ANTIPROV = this.cambiarFloatFixed(a.DETLIQUBENESOCI_ANTIPROV);
          a.DETLIQUBENESOCI_DESCPROV = this.cambiarFloatFixed(a.DETLIQUBENESOCI_DESCPROV);
          a.NUMCAB = i;
          i++;
        } catch (e) {
          return;
        }
      };
      return deta;
    } catch (e) {

    }

  }
  async busquedaTabla2(ajuste: nomliqucabecera, deta: nomliqudetalle[]) {
    try {
      let i = 0;
      for (let a of deta) {
        try {
          if (isNullOrUndefined(a.DETLIQUBENESOCI_DESCRETEJUDI)) {
            a.DETLIQUBENESOCI_DESCRETEJUDI = 0;
          }
          if (isNullOrUndefined(a.DETLIQUBENESOCI_ANTIPROV)) {
            a.DETLIQUBENESOCI_ANTIPROV = 0;
          }
          if (isNullOrUndefined(a.DETLIQUBENESOCI_DESCPROV)) {
            a.DETLIQUBENESOCI_DESCPROV = 0;
          }
          a.DETLIQUBENESOCI_AJUSPROV = a.AJUSTE_PROV;
          a.EMP_CODIGO2 = a.EMP_CODIGO2;
          a.DETLIQUBENESOCI_NUMSEC = 0;
          a.dblTOTA_APAGAR = this.cambiarFloatFixed(this.funCalcTotaNetoAPagar(a));
          a.DETLIQUBENESOCI_HORATIEMPARC = this.cambiarFloatFixed(a.DETLIQUBENESOCI_HORATIEMPARC);
          a.DETLIQUBENESOCI_ACUMHORAPROV = this.cambiarFloatFixed(a.DETLIQUBENESOCI_ACUMHORAPROV);
          a.DETLIQUBENESOCI_ACUMDIAPRTOT = this.cambiarFloatFixed(a.DETLIQUBENESOCI_ACUMDIAPRTOT);
          a.DETLIQUBENESOCI_ACUMBASPRTOT = this.cambiarFloatFixed(a.DETLIQUBENESOCI_ACUMBASPRTOT);
          a.DETLIQUBENESOCI_ACUMVALPRTOT = this.cambiarFloatFixed(a.DETLIQUBENESOCI_ACUMVALPRTOT);
          a.DETLIQUBENESOCI_ACUMDIAPAGA = this.cambiarFloatFixed(a.DETLIQUBENESOCI_ACUMDIAPAGA);
          a.DETLIQUBENESOCI_ACUMBASPRPAG = this.cambiarFloatFixed(a.DETLIQUBENESOCI_ACUMBASPRPAG);
          a.DETLIQUBENESOCI_ACUMVALPRPAG = this.cambiarFloatFixed(a.DETLIQUBENESOCI_ACUMVALPRPAG);
          a.DETLIQUBENESOCI_AJUSPROV = this.cambiarFloatFixed(a.DETLIQUBENESOCI_AJUSPROV);
          a.DETLIQUBENESOCI_ANTIPROV = this.cambiarFloatFixed(a.DETLIQUBENESOCI_ANTIPROV);
          a.DETLIQUBENESOCI_DESCPROV = this.cambiarFloatFixed(a.DETLIQUBENESOCI_DESCPROV);
          a.NUMCAB = i;
          i++;
        } catch (e) {
          console.log(e.error);
          return;
        }
      };
      return deta;
    } catch (e) {

    }

  }
  //botones
  //busqueda
  async funllenaInformaciondecimotercero(cabe: nomliqucabecera) {
    console.log('entra')
    return await this.http.post(this.way + '/nom/nomliqudeciterc/0kots4ywz3grqq5', {
      elementos: {
        strMAERUBRO_CODRUBR: cabe.MAERUBRO_CODRUBR,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //bus
  //ejecliqui
  async ejecliqui(cabe: nomliqucabecera) {
    let fec = new Date();
    return await this.http.post(this.way + '/nom/nomliqudeciterc/y2sir4pjuyep53v', {
      elementos: {
        fecha: this.cambiarfechanull(fec),
        lblENCLIQUBENESOCI_CODIGO: cabe.ENCLIQUBENESOCI_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async funConfirmaTransLiquBeneSoci(cabe: nomliqucabecera) {

    return await this.http.post(this.way + '/nom/nomliqudeciterc/o5l32chhgd1uyhu', {
      elementos: {

        p_MAERUBRO_CODRUBR: cabe.MAERUBRO_CODRUBR,
        p_TRNBENESOCI_FECINIPROV: cabe.ENCLIQUBENESOCI_FECINIPROV,
        p_TRNBENESOCI_FECfinPROV: cabe.ENCLIQUBENESOCI_FECFINPROV
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async funActualizaEstadoProvision(cabe: nomliqucabecera, estado: string) {

    return await this.http.post(this.way + '/nom/nomliqudeciterc/3kwfdbuvdiv0y53', {
      elementos: {
        p_encliqubenesoci_codigo: parseInt(cabe.ENCLIQUBENESOCI_CODIGO),
        p_detrolprov_estado: estado,
        p_maerubro_codrubr: cabe.MAERUBRO_CODRUBR,
        p_trnbenesoci_feciniprov: cabe.ENCLIQUBENESOCI_FECINIPROV,
        p_trnbenesoci_fecfinprov: cabe.ENCLIQUBENESOCI_FECFINPROV,
        p_periodo_fecini: cabe.PERIODO_FECINI
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //
  //reverliq
  async reverliq(cabe: nomliqucabecera) {
    let fec = new Date();
    return await this.http.post(this.way + '/nom/nomliqudeciterc/mi5kypdh5nx0kaf', {
      elementos: {
        fecha: this.cambiarfechanull(fec),
        lblENCLIQUBENESOCI_CODIGO: cabe.ENCLIQUBENESOCI_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async funReversaTransLiquBeneSoci(cabe: nomliqucabecera) {

    return await this.http.post(this.way + '/nom/nomliqudeciterc/13vgwtjukkos81l', {
      elementos: {
        p_MAERUBRO_CODRUBR: cabe.MAERUBRO_CODRUBR,
        p_TRNBENESOCI_FECINIPROV: cabe.ENCLIQUBENESOCI_FECINIPROV,
        p_TRNBENESOCI_FECfinPROV: cabe.ENCLIQUBENESOCI_FECFINPROV
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //
  funObtieneTotales() {
    let i = 0;
    let dblHORA_TIEMPARC = 0;
    let dblACUM_HORAPROV = 0;
    let dblACUM_DIASPROVTOTA = 0;
    let dblACUM_BASEPROVTOTA = 0;
    let dblACUM_VALOPROVTOTA = 0;
    let dblACUM_DIASPAGA = 0;
    let dblACUM_BASEPROVPAGA = 0;
    let dblACUM_VALOPROVPAGA = 0;
    let dblAJUS_PROV = 0;
    let dblANTI_PROV = 0;
    let dblDESC_PROV = 0;
    let dblDETLIQUBENESOCI_DESCRETEJUDI = 0;
    let dblDETLIQUBENESOCI_NETOAPAGAR = 0;
    for (let a of this.nomliqdet) {
      // console.log(a.DETLIQUBENESOCI_DESCRETEJUDI);

      dblHORA_TIEMPARC = dblHORA_TIEMPARC + parseFloat(a.DETLIQUBENESOCI_HORATIEMPARC == null ? 0 : a.DETLIQUBENESOCI_HORATIEMPARC);
      dblACUM_HORAPROV = dblACUM_HORAPROV + parseFloat(a.DETLIQUBENESOCI_ACUMHORAPROV == null ? 0 : a.DETLIQUBENESOCI_ACUMHORAPROV);
      dblACUM_DIASPROVTOTA = dblACUM_DIASPROVTOTA + parseFloat(a.DETLIQUBENESOCI_ACUMDIAPRTOT == null ? 0 : a.DETLIQUBENESOCI_ACUMDIAPRTOT);
      dblACUM_BASEPROVTOTA = dblACUM_BASEPROVTOTA + parseFloat(a.DETLIQUBENESOCI_ACUMBASPRTOT == null ? 0 : a.DETLIQUBENESOCI_ACUMBASPRTOT);
      dblACUM_VALOPROVTOTA = dblACUM_VALOPROVTOTA + parseFloat(a.DETLIQUBENESOCI_ACUMVALPRTOT == null ? 0 : a.DETLIQUBENESOCI_ACUMVALPRTOT);
      dblACUM_DIASPAGA = dblACUM_DIASPAGA + parseFloat(a.DETLIQUBENESOCI_ACUMDIAPAGA == null ? 0 : a.DETLIQUBENESOCI_ACUMDIAPAGA);
      dblACUM_BASEPROVPAGA = dblACUM_BASEPROVPAGA + parseFloat(a.DETLIQUBENESOCI_ACUMBASPRPAG == null ? 0 : a.DETLIQUBENESOCI_ACUMBASPRPAG);
      dblACUM_VALOPROVPAGA = dblACUM_VALOPROVPAGA + parseFloat(a.DETLIQUBENESOCI_ACUMVALPRPAG == null ? 0 : a.DETLIQUBENESOCI_ACUMVALPRPAG);
      dblAJUS_PROV = dblAJUS_PROV + parseFloat(a.DETLIQUBENESOCI_AJUSPROV == null ? 0 : a.DETLIQUBENESOCI_AJUSPROV);
      dblANTI_PROV = dblANTI_PROV + parseFloat(a.DETLIQUBENESOCI_ANTIPROV == null ? 0 : a.DETLIQUBENESOCI_ANTIPROV);
      dblDESC_PROV = dblDESC_PROV + parseFloat(a.DETLIQUBENESOCI_DESCPROV == null ? 0 : a.DETLIQUBENESOCI_DESCPROV);
      dblDETLIQUBENESOCI_DESCRETEJUDI = dblDETLIQUBENESOCI_DESCRETEJUDI + parseFloat(a.DETLIQUBENESOCI_DESCRETEJUDI == null ? 0 : a.DETLIQUBENESOCI_DESCRETEJUDI);
      dblDETLIQUBENESOCI_NETOAPAGAR = dblDETLIQUBENESOCI_NETOAPAGAR + parseFloat(a.dblTOTA_APAGAR == null ? 0 : a.dblTOTA_APAGAR);      
    }

    let respuesta = [];
    respuesta.push(dblHORA_TIEMPARC);
    respuesta.push(dblACUM_HORAPROV);
    respuesta.push(dblACUM_DIASPROVTOTA);
    respuesta.push(dblACUM_BASEPROVTOTA);
    respuesta.push(dblACUM_VALOPROVTOTA);
    respuesta.push(dblACUM_DIASPAGA);
    respuesta.push(dblACUM_BASEPROVPAGA);
    respuesta.push(dblACUM_VALOPROVPAGA);
    respuesta.push(dblAJUS_PROV);
    respuesta.push(dblANTI_PROV);
    respuesta.push(dblDESC_PROV);
    respuesta.push(dblDETLIQUBENESOCI_DESCRETEJUDI);
    respuesta.push(dblDETLIQUBENESOCI_NETOAPAGAR);
    //console.log(respuesta)
    return respuesta;
  }
  calculosParciales() {
    for (let a of this.nomliqdet) {
      a.dblTOTA_APAGAR = this.cambiarFloatFixed(this.funCalcTotaNetoAPagar(a));

    }

  }
  async funObtiValoAjusDeciCuarXFila(ajuste: nomliqucabecera, det: nomliqudetalle) {
    try {
      const ajust = await this.valorajustedecicuar(ajuste, det.EMP_CODIGO);
      return ajust;
    } catch (e) {
      console.log(e.error);
      return e;

    }

  }
  async valorajustedecicuar(ajuste: nomliqucabecera, empcodigo) {

    return await this.http.post(this.way + '/nom/nomliqudeciterc/9wj01gzlb6lyl2q', {
      elementos: {
        p_maerubro_codrubr: String(ajuste.MAERUBRO_CODRUBR),
        p_feciniprov: this.cambiarfechanull(ajuste.ENCLIQUBENESOCI_FECINIPROV),
        p_fecfinprov: this.cambiarfechanull(ajuste.ENCLIQUBENESOCI_FECFINPROV),
        p_emp_codigo: parseInt(empcodigo),
        p_periodo_codigo: parseInt(ajuste.PERIODO_CODIGO)
      },
    }, this.confIniciales.httpOptions()).toPromise();

  }
  cambiarfechanull(valor) {
    let res = "";
    if (isNullOrUndefined(valor)) {
      return res;
    } else {
      try {
        if (valor.toString().includes('-')) {
          res = this.pipe.transform(valor, 'dd/MM/yyyy');
          return res;
        } else {
          return valor;
        }
      } catch (err) {
        return valor;
      }
    }
  }

  async buscarbeneficiosSoc() {
    return await this.http.post(this.way + '/nom/nomliqudeciterc/zsci3e5qfroei61', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async busRubro(strparametro: string) {

    return await this.http.post(this.way + '/nom/nomliqudeciterc/9n0e5g396clsemx', {
      elementos: {
        parametro: strparametro
      },
    }, this.confIniciales.httpOptions()).toPromise();

  }
  async bustablarubro(strparametro: string) {

    return await this.http.post(this.way + '/nom/nomliqudeciterc/pcih73rhywot8j3', {
      elementos: {
        parametro: strparametro
      },
    }, this.confIniciales.httpOptions()).toPromise();

  }

  cambiarFloatFixed(valor) {
    let res = "0.00";
    let resp;
    if (isNullOrUndefined(valor)) {
      return res;
    } else {
      resp = (parseFloat(valor)).toFixed(2);
      if (isNaN(resp)) {
        return res;
      } else {
        return resp;
      }
    }
  }
  cambiarFloat(valor) {
    // console.log(valor);
    let res = 0.00;
    let resp;
    if (isNullOrUndefined(valor)) {
      return res;
    } else {
      resp = parseFloat(valor);
      if (isNaN(resp)) {
        return res;
      } else {
        return resp;
      }
    }
  }
  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'novedad',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
  }
}
