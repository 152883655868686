import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErroresBaseDatosService {
  mensaje: string;
  
  constructor() {
  }
  
  generarMensajeError(message): string {
    if (message === 'ORA-02292') {
      this.mensaje = 'La clave primaria ya existe';
    }
    if (message === 'ORA-00001') {
      this.mensaje = 'La clave única ya existe (Unique Constraint)';
    }
    if (message === 'ORA-02291') {
      this.mensaje = 'Violación de Integridad de la base de datos';
    }
    if (message === 'ORA-01403') {
      this.mensaje = 'No Data Found';
    }
    
    
    return this.mensaje;
  }
  
  generarMensajeErrorCompleto(error): string {
    const message = error.substr(0, 9)
    let mensaje = '';
    if (message === 'ORA-02292') {
      if(error.indexOf('violated - child record found')!=-1){
        mensaje = 'El elemento tiene registros secundarios';
      }else{
        mensaje = 'La clave primaria ya existe';  
      }
     
    }
    if (message === 'ORA-00001') {
      mensaje = 'La clave única ya existe (Unique Constraint)';
    }
    if (message === 'ORA-02291') {
      mensaje = 'Violación de Integridad de la base de datos';
    }
    if (message === 'ORA-01403') {
      mensaje = 'No Data Found';
    }
    if (message === 'ORA-01400') {
      mensaje = 'cannot insert NULL';
    }
    if (message === 'ORA-12899') {
      mensaje = 'Valor demasiado largo para la columna ';
      const mensaje2 = String(error).split('"."');
      const columna = String(mensaje2[2]).split('"');
      const valorMax = String(columna[1]).split(")");
      mensaje = mensaje + columna[0] + ' ' + String(valorMax[0]).slice(2);
    }

    
    
    const ini = error.indexOf(' at ');
    error = error.substr(ini);
    
    const fin = error.indexOf('ORA-');
    error = error.substr(0, fin);
    
    return mensaje + error;
  }
}
