<div class="p-grid" style="width: 99%">
	<div class="p-col-12">
		<app-invmaebarra (signal)="manejarSenales($event)" [barraBotones3]="barraBotones2" [barraBotonesAsiCon]="false"
			[botonBorrar]="botonBorrar" [botonNuevo]="botonNuevo" [botonGuardar]="botonGuardar"
			[botonRegresar]="botonRegresar" [botonPrimero]="!botonPrimero" [botonAnterior]="!botonAnterior"
			[botonSiguiente]="!botonSiguiente" [botonUltimo]="!botonUltimo" [barraBotones5]="false"
			 [botonAnular]="botonAnular" [botonAsiento]="true" [botonverAsiento]="true"
            [botonPago]="true" [botonPagoCredito]="true" [botonBuscar]="true" [botonXML]="true"
         
			[botonReenviarCorreo]="true" [botonAlmacenardoc]="false" ></app-invmaebarra>
	</div>
</div>
<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px; width: 99%;">
	<!--LINEA 1-->
	<div class="p-col-2">
		<label for="ENCFAC_GRUPO"></label>
		<input class="frm" id="ENCFAC_GRUPO" type="text" pInputText
			style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
			[disabled]="true" [(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_GRUPO"
			(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
	</div>
	<div class="p-col-1">
		<label for="ENCFAC_ESTADO"></label>
		<input class="frm" id="ENCFAC_ESTADO" type="text" pInputText
			style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
			[disabled]="true" [(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_ESTADO"
			(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
	</div>
	<div class="p-col-1">
		<div *ngIf="encfacService.encfacarray[indicador].ENCFAC_IMPRESO==='IMPRESA'">
			<label for="ENCFAC_IMPRESO"></label>
			<input class="frm" id="ENCFAC_IMPRESO" type="text" pInputText
				style="background-color: #EFFFF7; text-align: center; color: rgb(0,128,0); font-weight: bold"
				[disabled]="true" [(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_IMPRESO"
				(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
		</div>
	</div>
	<div class="p-col-1">
	</div>
	<div class="p-col-1">
		<label for="ENCFAC_ESTADO_FE"></label>
		<input class="frm" id="ENCFAC_ESTADO_FE" type="text" pInputText
			style="background-color: rgb(40,138,210); font-weight: bold; color: white;" [disabled]="true"
			[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_ESTADO_FE" (keydown.enter)="setFocus($event)"
			(keydown)="botonesmodificar()" autocomplete="off">
	</div>
	<div class="p-col-2">
		<label for="ENCFAC_AUTORIZACION_FE"></label>
		<input class="frm" id="ENCFAC_AUTORIZACION_FE" type="text" pInputText
			style="background-color: rgb(40,138,210); font-weight: bold; color: white;" [disabled]="true"
			[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_AUTORIZACION_FE" (keydown.enter)="setFocus($event)"
			(keydown)="botonesmodificar()" autocomplete="off">
	</div>
	<div class="p-col-2">
		<label for="ENCFAC_CLAVEACCESO_FE"></label>
		<input class="frm" id="ENCFAC_CLAVEACCESO_FE" type="text" pInputText
			style="background-color: rgb(40,138,210); font-weight: bold; color: white;" [disabled]="true"
			[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_CLAVEACCESO_FE" (keydown.enter)="setFocus($event)"
			(keydown)="botonesmodificar()" autocomplete="off">
	</div>
	<div class="p-col-2">
		<div class="p-grid">
			<div class="p-col-1"></div>
			<div class="p-col-8">
				<label for="ASI_NRO"></label>
				<input class="frm" id="ASI_NRO" type="text" pInputText style="background-color: #EFFFF7"
					[disabled]="true" [(ngModel)]="encfacService.encfacarray[indicador].ASI_NRO"
					(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
			</div>
		</div>
	</div>
</div>
<p-tabView [(activeIndex)]="index" (onChange)="changeTabs()" [style]="{'width':'98%'}">
	<p-tabPanel header="Factura">
		<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 270px)'}">
			<div class="contorno" id="contorno">
				<div class="divgrups">
					<div class="p-grid">
						<!--LINEA 1-->
						<div class="p-col-1">
							<span>Número:</span>
						</div>
						<div class="p-col-2">
							<label for="ENCFAC_NUMERO"></label>
							<input class="frm" id="ENCFAC_NUMERO" type="text" pInputText
								[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_NUMERO"
								(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
						</div>
						<div class="p-col-1">
							<span>Autorización:</span>
						</div>
						<div class="p-col-1">
							<label for="ENCFAC_AUTORIZACION"></label>
							<input class="frm" id="ENCFAC_AUTORIZACION" type="text" pInputText
								[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_AUTORIZACION"
								(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
						</div>
						<div class="p-col-1">
							<div *ngIf="CONSIDNV === 1">
								<p-checkbox name="notadeventa" value="" label="" binary="{{chknotadeventa}}"
									[(ngModel)]="chknotadeventa"></p-checkbox>
								<span>Nota de Venta</span>
							</div>
						</div>
						<div class="p-col-1">
							<span>Saldo Cliente:</span>
						</div>
						<div class="p-col-1">
							<label for="ENCFAC_SALDOCLIENTE"></label>
							<input class="frm" id="ENCFAC_SALDOCLIENTE" type="text" pInputText style="color: blue;"
								disabled [(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_SALDOCLIENTE"
								(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
						</div>
						<div class="p-col-4">
						</div>
						<!--LINEA 2-->
						<div class="p-col-12" style="margin-top: -7px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>F.Emisión: </span>
								</div>
								<div class="p-col-1">
									<p-calendar id="ENCFAC_FECHAEMISION" [inputStyle]="{width: '100%', height: '20px'}"
										[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_FECHAEMISION"
										[readonlyInput]="true" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()"[disabled]="boolfeh">
									</p-calendar>
								</div>
								<div class="p-col-1">
									<p-calendar id="ENCFAC_HORAEMISION" [inputStyle]="{width: '80%', height: '20px'}"
										[timeOnly]="true"
										[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_HORAEMISION"
										[readonlyInput]="true" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()"[disabled]="boolfeh">
									</p-calendar>
								</div>
								<div class="p-col-1">
								</div>
								<div class="p-col-2">
									<div *ngIf="labelPromocion===true">
										<span>Revise Promociones</span>
									</div>
								</div>
								<div class="p-col-1">
									<span>Días Crédito:</span>
								</div>
								<div class="p-col-2">
									<label for="ENCFAC_DIASCRDT"></label>
									<input class="frm" id="ENCFAC_DIASCRDT" type="text" pInputText
										[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_DIASCRDT"
										(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										autocomplete="off" [disabled]="bloqbdfac">
								</div>
								<div class="p-col-1">
									<span>F.Vencimiento: </span>
								</div>
								<div class="p-col-1">
									<p-calendar id="ENCFAC_FECHAVENCIMIENTO"
										[inputStyle]="{width: '100%', height: '20px'}"
										[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_FECHAVENCIMIENTO"
										[readonlyInput]="true" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()">
									</p-calendar>
								</div>
							</div>
						</div>
						<!--LINEA 3-->
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Cliente:</span>
								</div>
								<div class="p-col-1">
									<label for="CLI_CODIGO"></label>
									<input class="frm" id="CLI_CODIGO" type="text" pInputText [disabled]="bolCliente"
										[(ngModel)]="encfacService.encfacarray[indicador].CLI_CODIGO"
										(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="busquedacliente(encfacService.encfacarray[indicador].CLI_CODIGO)"></button>
								</div>
								<div class="p-col-3">
									<label for="GRUP_NOMBRE"></label>
									<input class="frm" id="GRUP_NOMBRE" type="text" pInputText disabled
										style="color: blue;"
										[(ngModel)]="encfacService.encfacarray[indicador].CLI_NOMBRE"
										autocomplete="off">
								</div>
								<div class="p-col-1">
									<span>Listas Precio:</span>
								</div>
								<div class="p-col-2">
									<p-dropdown [options]="cmblistasprecio"
										[style]="{'minWidth': '100%', 'width':'100%'}" optionLabel="name"
										[panelStyle]="{'width':'110%'}" [(ngModel)]="selectlistasprecio"
										(onChange)="busqlistasprecio($event)"></p-dropdown>
								</div>
								<div class="p-col-3">
								</div>
							</div>
						</div>
						<!--LINEA 4-->
						<div class="p-col-12" style="margin-top: -20px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Vendedor:</span>
								</div>
								<div class="p-col-1">
									<label for="VEN_CODIGO"></label>
									<input class="frm" id="VEN_CODIGO" type="text" pInputText
										[(ngModel)]="encfacService.encfacarray[indicador].VEN_CODIGO"
										(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="busquedavendedor(encfacService.encfacarray[indicador].VEN_CODIGO)"></button>
								</div>
								<div class="p-col-3">
									<label for="VEN_NOMBRE"></label>
									<input class="frm" id="VEN_NOMBRE" type="text" pInputText disabled
										[(ngModel)]="encfacService.encfacarray[indicador].VEN_NOMBRE"
										autocomplete="off">
								</div>
								<div class="p-col-1">
									<span>IVA:</span>
								</div>
								<div class="p-col-2">
									<p-dropdown [disabled]="encfacService.detfacarray[indicador].length !== 0"
										[options]="cmbivaaux" [style]="{'minWidth': '100%', 'width':'100%'}"
										optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectiva"
										(onChange)="busqiva($event)"></p-dropdown>
								</div>
								<div class="p-col-1">
									<span>%IVA:</span>
								</div>
								<div class="p-col-1">
									<p-dropdown id="ENCFAC_PORCEIVA" [options]="cmbporceiva"
										[style]="{'minWidth': '100%', 'width':'100%'}" optionLabel="name"
										[panelStyle]="{'width':'110%'}" [(ngModel)]="selectporceiva"
										[disabled]="disablediva" (onChange)="busqporceiva($event)"></p-dropdown>
								</div>
								<div class="p-col-1">
								</div>
							</div>
						</div>
						<!--LINEA 5-->
						<div class="p-col-12" style="margin-top: -20px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Referencia:</span>
								</div>
								<div class="p-col-1">
									<label for="ENCFAC_REFERENCIA"></label>
									<input class="frm" id="ENCFAC_REFERENCIA" type="text" pInputText
										[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_REFERENCIA"
										(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										autocomplete="off">
								</div>
								<div class="p-col-1">
									<div *ngIf="REFMAE === 1 || REFLOCMED === 1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"></button>
									</div>
								</div>
								<div class="p-col-3">
									<div *ngIf="REFMAE === 1 || REFLOCMED === 1">
										<label for="ENCFAC_REFERENCIANOMBRE"></label>
										<input class="frm" id="ENCFAC_REFERENCIANOMBRE" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_REFERENCIANOMBRE"
											autocomplete="off">
									</div>
								</div>
								<div class="p-col-1">
									<span>Bodega:</span>
								</div>
								<div class="p-col-3">
									<p-dropdown [options]="cmbbodega" [style]="{'minWidth': '100%', 'width':'100%'}"
										optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectbodega"
										(onChange)="busqbodega($event)" [disabled]="bloqbdfac"></p-dropdown>
								</div>
								<div class="p-col-2">
									<p-dropdown [options]="cmbtipofactura"
										[style]="{'minWidth': '100%', 'width':'100%'}" optionLabel="name"
										[panelStyle]="{'width':'110%'}" [(ngModel)]="selecttipofactura"
										(onChange)="busqtipofactura($event)"></p-dropdown>
								</div>
							</div>
						</div>
						<!--LINEA 6-->
						<div class="p-col-12" style="margin-top: -18px; margin-bottom: -15px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Comentario:</span>
								</div>
								<div class="p-col-5">
									<label for="ENCFAC_COMENTARIO"></label>
									<input class="frm" id="ENCFAC_COMENTARIO" type="text" pInputText
										[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_COMENTARIO"
										(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										autocomplete="off">
								</div>
								<div class="p-col-2">
									<div *ngIf="NUMITEMFAC > 0">
										<span>Items Disponibles:</span>
									</div>
								</div>
								<div class="p-col-1">
									<div *ngIf="NUMITEMFAC > 0">
										<label for="ENCFAC_ITEMSDISPONIBLES"></label>
										<input class="frm" id="ENCFAC_ITEMSDISPONIBLES" type="text" pInputText
											style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_ITEMSDISPONIBLES"
											[disabled]="true" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off">
									</div>
								</div>
								<div class="p-col-1">
									<div *ngIf="PUNVTAS === 1">
										<span>Puntos:</span>
									</div>
								</div>
								<div class="p-col-1">
									<div *ngIf="PUNVTAS === 1">
										<label for="ENCFAC_PUNTOS"></label>
										<input class="frm" id="ENCFAC_PUNTOS" type="text" pInputText
											[(ngModel)]="encfacService.encfacarray[this.indicador].ENCFAC_PUNTOS"
											(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
											autocomplete="off">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--<div id="contornodetalle">-->
			<app-aggridsaci (keydown.f4)="disminuirPrecioIva()" [width]="1380" [height]="480" [enableSorting]="true"
				[enableFilter]="true" [rowData]="encfacService.detfacarray[indicador]"
				[frameworkComponents]="frameworkComponents" [animateRows]="true" [rowSelection]="rowSelection"
				[columnDefs]="columnDefsDetfac" [defaultColDef]="defaultColDef" [rowStyle]="rowStyle"
				[singleClickEdit]="false" [botones]="false"  (selected)="selecciondetfac($event)"
				(rdbtipodet)="rdbtipodet($event)" [comboOpciones]="comboOpciones" [tipodetalle]="tipodetalle"
				[mostrarGuardar]="false" 
				(cambios)="cambio($event)" [btnVervehiculo]="true"></app-aggridsaci>
			<!--</div>-->
			<div class="divgrupstabla">
				<div class="p-grid">
					<div class="p-col-6">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-2">
								<span class="obligatorio">Neto:</span>
							</div>
							<div class="p-col-2">
								<span class="obligatorio">Descuento:</span>
							</div>
							<div class="p-col-2">
								<span class="obligatorio">Base I.V.A.:</span>
							</div>
							<div class="p-col-2">
								<span class="obligatorio">I.V.A.:</span>
							</div>
							<div class="p-col-2">
								<span class="obligatorio">Base I.C.E.:</span>
							</div>
						</div>
					</div>
					<div class="p-col-6">
						<div class="p-grid">
							<!-- <div class="p-col-1"></div> -->
							<div class="p-col-2">
								<span class="obligatorio">IRBPNR:</span>
							</div>
							<div class="p-col-2">
								<span class="obligatorio">I.C.E.:</span>
							</div>
							<div class="p-col-2">
								<span class="obligatorio">Base Cero:</span>
							</div>
							<div class="p-col-2">
								<span class="obligatorio">B.No Imp.Iva:</span>
							</div>
							<div class="p-col-2">
								<span class="obligatorio">Servicio:</span>
							</div>
							<div class="p-col-2">
								<span class="obligatorio">TOTAL:</span>
							</div>
							<div class="p-col-2"></div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -32px">
						<div class="p-grid">
							<div class="p-col-6">
								<div class="p-grid">
									<div class="p-col-1"></div>
									<div class="p-col-2">
										<label for="ENCFAC_TOTALNETO"></label>
										<input class="totales" id="ENCFAC_TOTALNETO" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_TOTALNETO"
											autocomplete="off">
									</div>
									<div class="p-col-2">
										<label for="ENCFAC_VALORDES"></label>
										<input class="totales" id="ENCFAC_VALORDES" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_VALORDES"
											autocomplete="off">
									</div>
									<div class="p-col-2">
										<label for="ENCFAC_BASEIVA"></label>
										<input class="totales" id="ENCFAC_BASEIVA" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_BASEIVA"
											autocomplete="off">
									</div>
									<div class="p-col-2">
										<label for="ENCFAC_VALORIVA"></label>
										<input class="totales" id="ENCFAC_VALORIVA" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_VALORIVA"
											autocomplete="off" style="text-align: right">
									</div>
									<div class="p-col-2">
										<label for="ENCFAC_BASEICE"></label>
										<input class="totales" id="ENCFAC_BASEICE" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_BASEICE"
											autocomplete="off" style="text-align: right">
									</div>
								</div>
							</div>
							<div class="p-col-6">
								<div class="p-grid">
									<!-- <div class="p-col-1"></div> -->
									<div class="p-col-2">
										<label for="ENCFAC_VALORIRBPNR"></label>
										<input class="totales" id="ENCFAC_VALORIRBPNR" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_VALORIRBPNR"
											autocomplete="off" style="text-align: right">
									</div>
									<div class="p-col-2">
										<label for="ENCFAC_VALORICE"></label>
										<input class="totales" id="ENCFAC_VALORICE" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_VALORICE"
											autocomplete="off" style="text-align: right">
									</div>
									<div class="p-col-2">
										<label for="ENCFAC_BASECERO"></label>
										<input class="totales" id="ENCFAC_BASECERO" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_BASECERO"
											autocomplete="off" style="text-align: right">
									</div>
									<div class="p-col-2">
										<label for="ENCFAC_BASENOOBJIVA"></label>
										<input class="totales" id="ENCFAC_BASENOOBJIVA" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_BASENOOBJIVA"
											autocomplete="off" style="text-align: right">
									</div>
									<div class="p-col-2">
										<label for="ENCFAC_SERVICIO"></label>
										<input class="totales" id="ENCFAC_SERVICIO" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_SERVICIO"
											autocomplete="off" style="text-align: right">
									</div>
									<div class="p-col-2">
										<label for="ENCFAC_TOTAL"></label>
										<input class="totales" id="ENCFAC_TOTAL" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_TOTAL"
											autocomplete="off" style="text-align: right">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="p-grid">
					<div class="p-col-6">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<span class="obligatorio">TotalCant:</span>
								</div>
								<div class="p-col-2">
									<span class="obligatorio">PVP:</span>
								</div>
								<div class="p-col-2">
									<span class="obligatorio">C.Física:</span>
								</div>
								<div class="p-col-2">
									<span class="obligatorio">Otros Descuentos:</span>
								</div>
								<div class="p-col-2">
									<span></span>
								</div>
							</div>
						</div>
						<div class="p-col-6">
							<div class="p-grid">
								<div class="p-col-4">
									<div class="p-grid">
										<div class="p-col-5"
											style="margin-top: 10px; height: 25%; background-color: rgb(128, 128, 255)">
										</div>
										<div class="p-col-7">
											<span>Existencia Bajo Mín.</span>
										</div>
									</div>
								</div>
								<div class="p-col-4">
									<div class="p-grid">
										<div class="p-col-5"
											style="margin-top: 10px; height: 25%; background-color: rgb(255, 98, 98)">
										</div>
										<div class="p-col-7">
											<span>Precio Bajo Costo</span>
										</div>
									</div>
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-5"
											style="margin-top: 10px; height: 25%; background-color: rgb(128, 255, 128)">
										</div>
										<div class="p-col-7">
											<span>Promociones</span>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="p-col-12" style="margin-top: -28px">
							<div class="p-grid">
								<div class="p-col-6">
									<div class="p-grid">
										<div class="p-col-1"></div>
										<div class="p-col-2">
											<label for="ENCFAC_TOTALCANT"></label>
											<input class="totales" id="ENCFAC_TOTALCANT" type="text" pInputText disabled
												[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_TOTALCANT" autocomplete="off">
										</div>
										<div class="p-col-2">
											<label for="ENCFAC_PVP"></label>
											<input class="totales" id="ENCFAC_PVP" type="text" pInputText disabled
												[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_PVP" autocomplete="off">
										</div>
										<div class="p-col-2">
											<label for="ENCFAC_CFISICA"></label>
											<input class="totales" id="ENCFAC_CFISICA" type="text" pInputText disabled
												[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_CFISICA" autocomplete="off">
										</div>
										<div class="p-col-2">
											<label for="ENCFAC_OTRDSCTOS"></label>
											<input class="totales" id="ENCFAC_OTRDSCTOS" type="text" pInputText disabled
												[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_OTRDSCTOS"
												autocomplete="off">
										</div>
										<div class="p-col-2">
											
										</div>
									</div>
								</div>
								<div class="p-col-6">
									<div class="p-grid">
										<div class="p-col-5">
											<div class="p-grid">
												<div class="p-col-4"
													style="margin-top: 10px; height: 25%; background-color: rgb(234, 250, 93)">
												</div>
												<div class="p-col-8">
													<span>Total Incluido en el Descuento Global</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
				</div>
			</div>
		</p-scrollPanel>
	</p-tabPanel>     
</p-tabView>
<p-dialog header="Reportes" [(visible)]="displayDialogReportes" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: '400px'}">
	<app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
		[strNumDoc]="encfacService.encfacarray[indicador].ENCFAC_NUMERO" [strCampo]="'ENCFAC_NUMERO'"
		(cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>