import {Component, OnInit} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {UsuarioEsquema} from './usuarioEsquema';
import {Usuario} from './usuario';
import {Router} from '@angular/router';
import {UtilitariosService} from './advantage/advantageservices/utilitarios.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {
  
  interval;
  logotipo;

  constructor(public app: AppMainComponent, 
              public usuario: Usuario, private route: Router, public uS: UtilitariosService) {
  }
  
  ngOnInit(): void {
    this.logotipo = sessionStorage.getItem('logotipo');
    if (this.uS.control === 1) {
      this.startTimer();
    }
  }
  
  startTimer() {
    this.uS.control = this.uS.control + 1;
    this.interval = setInterval(() => {
      if (this.uS.timeLeft > 0) {
        // this.uS.timeLeft--;
      } else {
        this.route.navigate(['/login']);
      }
    }, 1000);
  }
  
}

