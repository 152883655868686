import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { ActMaeKardex } from '../actinterfaces/actmaekardex';
import { MessageService } from 'primeng';

@Injectable({
  providedIn: 'root'
})
export class ActenckardexService {

  private way: string;

  public btnNuevo:boolean[] = [];
  public btnGuardar:boolean[] = [];
  public btnBorrar:boolean[] = [];
  

  public detKardexArray: ActMaeKardex[][] = [];
  public detKardexn_Array: ActMaeKardex[][] = [];

  constructor(
    private http: HttpClient, 
    private confIniciales: ConfInicialesService, 
    private init: NuevoComponentService,
    private message: MessageService
  ) { 
    this.way = sessionStorage.getItem('way');
  }

  activaBotones(strTipo, bolNuevo, bolGuardar, bolCancelar, bolEliminar, bolInicio, bolAnterior, bolSiguiente, bolUltimo,bolBuscar,
    bolImprimir) {

      this.btnGuardar[this.init.tabs[this.init.tabindex].indice] = !bolGuardar;
      this.btnNuevo[this.init.tabs[this.init.tabindex].indice] = !bolNuevo;
      this.btnBorrar[this.init.tabs[this.init.tabindex].indice] = !bolEliminar;
  } //Fin activar botones

  obtenerDatos(strCodigo) {
    return this.http.post<any[]>(this.way + '/act/enckardex/b639ddab6d57700', {
      elementos: {
        codigo: strCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  listarDatosDet(strCodigo) {
    return this.http.post<any[]>(this.way + '/act/enckardex/2b2e754d5771c', {
      elementos: {
        p_enckardex_codigo: Number(strCodigo)
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  navegarRegistro(parametros) {
    return this.http.post<any[]>(this.way + '/act/enckardex/5d5f7436e84b9c', {
      elementos: {
        p_tipo: parametros[0],
        p_com_codigo: parametros[1],
        p_enckardex_codigo: parametros[2]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  consultarActDepre(parametros) {
    return this.http.post<any[]>(this.way + '/act/enckardex/3317e794ceb15c', {
      elementos: {
        p_fecha: parametros[0]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  verificarDepreciacion1(strCodigo) {
    return this.http.post<any[]>(this.way + '/act/enckardex/3eedc9ed76761', {
      elementos: {
        codigo: strCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  verificarDepreciacion2(strCodigo, strFecha) {
    return this.http.post<any[]>(this.way + '/act/enckardex/3eedc9ed76762', {
      elementos: {
        codigo: strCodigo,
        fecha: strFecha
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  fechaUltimaDeprec(strCodigo) {
    return this.http.post<any[]>(this.way + '/act/enckardex/3dccf57ad7676e', {
      elementos: {
        codigo: strCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  depreciacionAcumulada(parametros) {
    return this.http.post<any[]>(this.way + '/act/enckardex/35d9da99eb7aaa', {
      elementos: {
        p_act_codigo: parametros[0],
        p_fecha: parametros[1]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  fechamenosdias(parametros) {
    return this.http.post<any[]>(this.way + '/act/enckardex/1f675f1c6c95c', {
      elementos: {
        p_fecha: parametros[0],
        p_num_dias: parametros[1]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerUltimaFDepre(parametros) {
    return this.http.post<any[]>(this.way + '/act/enckardex/617df55ee6bb3c', {
      elementos: {
        p_act_codigo: parametros[0],
        p_enckardex_codigo: parametros[1]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerDiferfechameses(parametros) {
    return this.http.post<any[]>(this.way + '/act/enckardex/364f7b996771dc', {
      elementos: {
        p_fecini: parametros[0],
        p_fecfin: parametros[1]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  guardarEncKardex(parametros) {
    return this.http.post<any[]>(this.way + '/act/enckardex/21e56dac56dae', {
      elementos: {
        p_enckardex_codigo: parametros[0],
        p_enckardex_comprobante: parametros[1],
        p_enckardex_fecha: parametros[2],
        p_enckardex_comentario1: parametros[3],
        p_enckardex_comentario2: parametros[4],
        p_com_codigo: parametros[5],
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarDetKardex(parametros) {
    return this.http.post<any[]>(this.way + '/act/enckardex/756566bbac56dc0', {
      elementos: {
        p_enckardex_codigo: parametros[0],
        p_detkardex_numsec: parametros[1]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  guardarDetKardex(parametros) {
    return this.http.post<any[]>(this.way + '/act/enckardex/43cadb5dd62b6c', {
      elementos: {
        p_enckardex_codigo: parametros[0],
        p_detkardex_numsec: parametros[1],
        p_com_codigo: parametros[2],
        p_act_codigo: parametros[3],
        p_detkardex_descripcion: parametros[4],
        p_detkardex_fingreso: parametros[5],
        p_detkardex_vidautil: parametros[6],
        p_detkardex_costo: parametros[7],
        p_detkardex_importe: parametros[8],
        p_detkardex_anio: parametros[9],
        p_detkardex_mes: parametros[10],
        p_detkardex_orden: parametros[11],
        p_detkardex_feccompra: parametros[12],
        p_enckardex_fecha: parametros[13],
        p_detkardex_VALRSDL: parametros[14],
        p_detkardex_valdepreciado: parametros[15],
        p_detkardex_fecultmdprc: parametros[16],
        p_detkardex_dprcacum: parametros[17],
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarKardex(parametros) {
    return this.http.post<any[]>(this.way + '/act/enckardex/1d595a4c56dae', {
      elementos: {
        p_enckardex_codigo: parametros[0]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarAsientoKardex(parametros) {
    return this.http.post<any[]>(this.way + '/act/enckardex/41d772b92662fc', {
      elementos: {
        p_enckardex_codigo: parametros[0],
        p_com_codigo: parametros[1],
        p_usuidentificacion: parametros[2]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

}
