export class nomcabImpuRent  {
    CABIMPURENT_ANIO:any;
    CABIMPURENT_CODIGO:any;
    CABIMPURENT_DESCRIPCION:any;
    CABIMPURENT_ESTADO:any;
    COM_CODIGO:any;
}
export class nomdetImpuRent  {
    CABIMPURENT_CODIGO?;
    COM_CODIGO?;
    DETIMPURENT_ALIM?;
    DETIMPURENT_BASEIMPO?;
    DETIMPURENT_D13?;
    DETIMPURENT_D14?;
    DETIMPURENT_DEDUCDISCA?;
    DETIMPURENT_DEDUCTERCE?;
    DETIMPURENT_DEDUGPARTYCULT?;
    DETIMPURENT_EDUC?;
    DETIMPURENT_FRE?;
    DETIMPURENT_IESS?;
    DETIMPURENT_IESS_OE?;
    DETIMPURENT_IMPURENTASUM?;
    DETIMPURENT_INGRGRAV_OE?;
    DETIMPURENT_IREASUM?;
    DETIMPURENT_IREASUM_PP?;
    DETIMPURENT_IREPORRET?;
    DETIMPURENT_IRERENT_OE?;
    DETIMPURENT_IRERET?;
    DETIMPURENT_IRERET_PP?;
    DETIMPURENT_NUMSEC?;
    DETIMPURENT_ORDEN?;
    DETIMPURENT_OTROINGR?;
    DETIMPURENT_OTROINGR_NGAI?;
    DETIMPURENT_OTROINGR_NGNA?;
    DETIMPURENT_OTROINGR_NGNI?;
    DETIMPURENT_OTROINGR_NI?;
    DETIMPURENT_SALDIGNO?;
    DETIMPURENT_SALU?;
    DETIMPURENT_SISTSAL?;
    DETIMPURENT_SUELDO?;
    DETIMPURENT_TOTAEGRE?;
    DETIMPURENT_TOTAINGR?;
    DETIMPURENT_UTI?;
    DETIMPURENT_VALOIMPURENT?;
    DETIMPURENT_VEST?;
    DETIMPURENT_VIVI?;
    EMP_CODIGO?;
    EMP_RUCIDE?;
    EMP_NOMBRE?;
    EMP_FECINGRESO?;
    EMP_FECSALIDA?;
    EMP_CODIGO2?;
    NUMCAB?:number;
    NUEVO?;
    EMP_ENFCATASTROFICA?;
}