
<p-confirmDialog [transitionOptions]="'0ms'" key="NomMaeTabRetDialog" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 99%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
						 [botonRegresar]="btnRegresar" [barraBotonesCRUD]="false"
						 [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true"
						 (signal)="manejarSenales($event)"></app-invmaebarra>
	</div>

</div>
<div style="overflow: hidden" class="p-col-12">
	<p-toast [style]="{marginTop: '30px'}" position="top-center" key="nommaetabrettoast"></p-toast>
	<p-tabView>
		<p-tabPanel header="Tabla del Impuesto a la Renta">
			<div class="divgrups">
				<div class="p-grid" style="margin-top: 10px; margin-bottom: 10px">
					<div class="p-col-1">
						<span>Año:</span>
					</div>
					<div class="p-col-2">
						<p-dropdown id="anio" type="number"  optionLabel="anio" [options]="anios"
									[(ngModel)]="anioSeleccionado"
									(onChange)="buscarAnio($event)"
									editable="true"
									
									[showClear]="true" placeholder="Seleccione un año">
						</p-dropdown>
					</div>
				</div>
			</div>
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="false"
							[enableFilter]="true"
							[rowData]="maeTablitaRetService.nommaeTablitaRet"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefMaeTablitaRet"
							[defaultColDef]="defaultColDefMaeTablitaRet"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)=selectMaeTablitaRet($event)
							(cambios)="cambio($event)"

			></app-aggridsaci>
		</p-tabPanel>
	</p-tabView>
</div>
