import {Component, OnInit} from '@angular/core';
import {CellEditorPrueba} from '../config/cell-edit-prueba';
import {SaciMaeauditoria} from '../advantageinterfaces/sacimaeauditoria';
import {AuditoriagralService} from '../advantageservices/auditoriagral.service';
import {MessageService} from 'primeng';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {DatePipe} from '@angular/common';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Component({
  selector: 'app-sacimaeauditoria',
  templateUrl: './sacimaeauditoria.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class SacimaeauditoriaComponent implements OnInit {
  
  sacimaeauditoria: SaciMaeauditoria[];
  consulta: string;
  desde: string;
  hasta: string;
  cmbmodulo: any[];
  selectmodulo: any = [];
  cmbobjeto: any[];
  selectobjeto: any = [];
  cmbide: any[];
  selectide: any = [];
  dato: string;
  selectoperacion: any = [];
  cmboperacion: any[];
  
  strNombreForma: string;
  auxreporte: number = 0;
  displayDialogReportes: boolean;
  
  frameworkComponents: any;
  rowSelection: any;
  columnDefs = [
    {
      headerName: 'Fecha', field: 'AUD_FECHAHORA', width: 120
    },
    {
      headerName: 'Objeto', field: 'AUD_OBJETO', width: 180
    },
    {
      headerName: 'Dato', field: 'AUD_DATO', width: 200
    },
    {
      headerName: 'Operación', field: 'AUD_OPERACION', width: 100
    },
    {
      headerName: 'Identificación', field: 'USU_IDENTIFICACION', width: 150
    },
    /*{
      headerName: 'Quien', field: 'AUD_QUIEN', width: 150
    },*/
    {
      headerName: 'Equipo', field: 'AUD_IDEQUIPO', width: 150
    },
    {
      headerName: 'Ip. Pública', field: 'AUD_IPPUBLICA', width: 150
    },
  ];
  defaultColDef: any;
  rowStyle: any;
  
  constructor(private auditoriagral: AuditoriagralService, 
              private init: NuevoComponentService,
              private messageService: MessageService,
              private errorService: ErroresBaseDatosService, 
              private datePipe: DatePipe) {
    this.agTable();
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    
    this.defaultColDef = {
      editable: true,
      width: 100,
      objeto: 'sacimaeauditoria'
    };
  }
  
  ngOnInit(): void {
    this.llenarModulos();
    this.llenarIdentificacion();
    this.llenarOperacion();
    this.botonesmodificar();
  }
  
  ver(consulta) {
    const sql = 'select * from saci_maeauditoria where ' + consulta + ' order by AUD_FECHAHORA desc';
    this.auditoriagral.consultarAuditoria(consulta).subscribe(data => {
      const dataformat: any[] = [];
      for (const rs of data) {
        rs.AUD_FECHAHORA = this.datePipe.transform(rs.AUD_FECHAHORA, 'dd/MM/yyyy HH:mm:ss');//CDPJ ANTES dd/MM/yyyy HH:mm AHORA dd/MM/yyyy HH:mm:ss
        dataformat.push(rs);
      }
      this.sacimaeauditoria = dataformat;
      const sqldelete = 'delete saci_tmpmaeauditoria';
      this.auditoriagral.eliminarAuditoria().subscribe(() => {
        const sqlinsert = 'insert into saci_tmpmaeauditoria (aud_fechahora,aud_objeto,aud_dato,aud_operacion,usu_identificacion,aud_idequipo,aud_ippublica)' +
          ' select aud_fechahora,aud_objeto,aud_dato,aud_operacion,usu_identificacion,aud_idequipo,aud_ippublica' +
          ' from saci_maeauditoria where ' + consulta + ' order by AUD_FECHAHORA desc';
        this.auditoriagral.insertarAuditoria(consulta).subscribe();
      });
    }, error1 => {
      const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'audi',
        severity: 'error',
        summary: 'Error',
        detail: error
      });
    });
  }
  
  hacerConsulta(data) {
    let consulta = '';
    if (data === 'hoy') {
      consulta = 'to_char(aud_fechahora,\'DD/MM/YYYY\') = to_char(sysdate,\'DD/MM/YYYY\')';
    }
    if (data === 'ultimahora') {
      consulta = 'aud_fechahora > sysdate - 1/24';
    }
    if (data === 'input') {
      consulta = this.consulta;
    }
    
    this.ver(consulta);
  }
  
  manejarSenales(valor) {
    switch (valor) {
      case 'Salir':
        this.salir();
        break;
      case 'Visto':
        this.hacerConsulta('input');
        break;
      case 'Imprimir':
        this.strNombreForma = 'frmSACI_MAEAUDITORIA';
        this.auxreporte++;
        this.displayDialogReportes = true;
        break;
    }
  }
  
  botonesmodificar() {
    let desde = '';
    let hasta = '';
    
    if (this.desde === '' || this.desde === undefined || this.desde === null) {
      desde = 'to_char(sysdate,\'DD/MM/YYYY\')';
    } else {
      desde = '\'' + this.datePipe.transform(this.desde, 'dd/MM/yyyy') + '\'';
    }
    if (this.hasta === '' || this.hasta === undefined || this.hasta === null) {
      hasta = 'to_char(sysdate,\'DD/MM/YYYY\')';
    } else {
      hasta = '\'' + this.datePipe.transform(this.hasta, 'dd/MM/yyyy') + '\'';
    }
    
    this.consulta = 'to_date(to_char(aud_fechahora,\'DD/MM/YYYY\'),\'DD/MM/YYYY\') between to_date(' +
      desde + ',\'DD/MM/YYYY\')';
    this.consulta = this.consulta + ' and to_date(' + hasta + ',\'DD/MM/YYYY\')';
    
    if (this.selectobjeto.codigo !== undefined && this.selectobjeto.codigo !== '' && this.selectobjeto.codigo !== null) {
      this.consulta = this.consulta + ' and aud_objeto like \'%' + this.selectobjeto.codigo + '%\'';
    }
    
    if (this.selectide.codigo !== undefined && this.selectide.codigo !== '' && this.selectide.codigo !== null) {
      this.consulta = this.consulta + ' and aud_objeto like \'' + this.selectide.codigo + '\'';
    }
    
    if (this.selectoperacion.codigo !== undefined && this.selectoperacion.codigo !== '' && this.selectoperacion.codigo !== null) {
      this.consulta = this.consulta + ' and aud_operacion like \'' + this.selectoperacion.codigo + '\'';
    }
    
    if (this.dato !== undefined && this.dato !== '' && this.dato !== null) {
      this.consulta = this.consulta + ' and aud_dato like \'%' + this.dato + '%\'';
    }
  }
  
  llenarModulos() {
    const arraymodulos = [];
    this.cmbmodulo = [];
    this.selectobjeto = {codigo: '', name: ''};
    
    const strConsulta = 'SELECT MODPREFIJO, MODNOMBRE FROM SEG_MAEMODULO ORDER BY MODPREFIJO';
    this.auditoriagral.obtenermodulos().subscribe(data => {
      let i = 0;
      for (const rs of data) {
        if (i === 0) {
          this.selectmodulo = {codigo: rs.MODPREFIJO, name: rs.MODNOMBRE};
        }
        i++;
        arraymodulos.push({codigo: rs.MODPREFIJO, name: rs.MODNOMBRE});
      }
      arraymodulos.push({codigo: 'SACI_', name: 'SACI_'});
      this.cmbmodulo = arraymodulos;
    });
  }
  
  llenarObjetos() {
    const arrayobjetos = [];
    this.cmbobjeto = [];
    
    const modulo = this.selectmodulo.codigo;
    const strConsulta = 'SELECT DISTINCT AUD_OBJETO FROM SACI_MAEAUDITORIA'
      + ' WHERE AUD_OBJETO LIKE \'' + modulo + '%\' ORDER BY AUD_OBJETO';
    
    this.auditoriagral.obtenerObjetos(modulo).subscribe(data => {
      for (const rs of data) {
        arrayobjetos.push({codigo: rs.AUD_OBJETO, name: rs.AUD_OBJETO});
      }
      arrayobjetos.push({codigo: '', name: ''});
      this.selectobjeto = {codigo: '', name: ''};
      this.cmbobjeto = arrayobjetos;
      this.botonesmodificar();
    });
  }
  
  llenarIdentificacion() {
    const arrayide = [];
    this.cmbide = [];
    
    const strConsulta = 'SELECT DISTINCT USU_IDENTIFICACION FROM SACI_MAEAUDITORIA';
    
    this.auditoriagral.obtenerIdentificacion().subscribe(data => {
      for (const rs of data) {
        arrayide.push({codigo: rs.USU_IDENTIFICACION, name: rs.USU_IDENTIFICACION});
      }
      arrayide.push({codigo: '', name: ''});
      this.selectide = {codigo: '', name: ''};
      this.cmbide = arrayide;
    });
  }
  
  llenarOperacion() {
    const arrayoperacion = [];
    this.cmboperacion = [];
    
    const strConsulta = 'SELECT DISTINCT AUD_OPERACION FROM SACI_MAEAUDITORIA';
    
    this.auditoriagral.obtenerOperacion().subscribe(data => {
      for (const rs of data) {
        arrayoperacion.push({codigo: rs.AUD_OPERACION, name: rs.AUD_OPERACION});
      }
      arrayoperacion.push({codigo: '', name: ''});
      this.selectoperacion = {codigo: '', name: ''};
      this.cmboperacion = arrayoperacion;
    });
  }
  
  cerrarReportes() {
    this.displayDialogReportes = false;
  }
  
  salir() {
    this.init.tabs.splice(this.init.tabindex, 1);
  }
}
