import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {Observable} from 'rxjs';
import {BanTrnbanN} from '../baninterfaces/bantrnbanN';

@Injectable({
  providedIn: 'root'
})
export class BantrnbanService {
  
  get banTrnban(): BanTrnbanN[] {
    return this._trnban;
  }
  
  set banTrnban(value: BanTrnbanN[]) {
    this._trnban = value;
  }
  
  private _trnban: BanTrnbanN[];
  private way: string;
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }
  
  consultarMovBancos(opcion, strFiltro, condicion): Observable<any[]> {
    
    return this.http.post<any[]>(this.way + '/ban/movimientobancos/c2fb6c7d6abb300', {
        elementos: {
          filtro: strFiltro
        }
      }, this.confIniciales.httpOptions()
      
    );
  }

  consultarMovBancosProm(opcion, strFiltro, condicion) {
    
    return this.http.post<any[]>(this.way + '/ban/movimientobancos/c2fb6c7d6abb300', {
        elementos: {
          filtro: strFiltro
        }
      }, this.confIniciales.httpOptions()
      
    ).toPromise();
  }
  
  
}
