import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Maesubcategoria} from '../Interfaces/maesubcategoria';
import {Observable} from 'rxjs';
import {UsuarioEsquema} from '../usuarioEsquema';
import {Usuario} from '../usuario';
import {MaecategoriaService} from './maecategoria.service';
import { ConfInicialesService } from './conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class MaesubcategoriaService {
 
  public way: string;
  public respuesta: any;
  private C_CAT_CODIGO: string;
  public P_CAT_CODIGO: string;
  public P_SUB_CODIGO: string;

  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private categoriaService: MaecategoriaService,
              private confIniciales: ConfInicialesService) {
    this.usuarioEsquema.loadStorage();
    this.usuario.loadStorage();
    this.way = sessionStorage.getItem('way');
  }

  recogerPantalla(subcategoria: Maesubcategoria): Observable<any[]> {
    this.P_CAT_CODIGO = subcategoria.CAT_CODIGO.toString();
    this.P_SUB_CODIGO = subcategoria.SUB_CODIGO.toString();
    return this.http.post<any[]>(this.way + '/pdv/subcategoria/1b73310caafd55000', {
      elementos: {
        CAT_CODIGO: subcategoria.CAT_CODIGO,
        SUB_CODIGO: subcategoria.SUB_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }


  recogerProductos(subcategoria: Maesubcategoria): Observable<any[]> {
    this.P_CAT_CODIGO = subcategoria.CAT_CODIGO.toString();
    this.P_SUB_CODIGO = subcategoria.SUB_CODIGO.toString();
    return this.http.post<any[]>(this.way + '/pdv/subcategoria/1b73310cef0df3000', {
      elementos: {
        CAT_CODIGO: subcategoria.CAT_CODIGO,
        SUB_CODIGO: subcategoria.SUB_CODIGO,
        ARTPRE_CODIGO: 'A'
      },
    }, this.confIniciales.httpOptions());
  }

  agregarSubcategoria(subCat: Maesubcategoria) {
    this.C_CAT_CODIGO = this.categoriaService.recogerCATCODIGO();
    return this.http.post(this.way + '/pdv/subcategoria/5436e873cb62bc0', {
      elementos: {
        CAT_CODIGO: this.C_CAT_CODIGO,
        COM_CODIGO: '01',
        SUB_CODIGO: subCat.SUB_CODIGO,
        SUB_NOMBRE: subCat.SUB_NOMBRE,
        SUB_CORTO: subCat.SUB_CORTO
      }
    }, this.confIniciales.httpOptions()).subscribe(respuesta => {
      console.log(respuesta);
      console.log('Subcategoria insertada');
    }, error => {
      console.log(error);
      console.log('Error');
    });

  }

  actualizarSubCat(subCat: Maesubcategoria) {
    this.http.post(this.way + '/pdv/subcategoria/533bee796c575c0', {
      elementos: {
        SUB_NOMBRE: subCat.SUB_NOMBRE,
        SUB_CORTO: subCat.SUB_CORTO,
        SUB_CODIGO: subCat.SUB_CODIGO
      }
    }, this.confIniciales.httpOptions()).subscribe(respuesta => {
      console.log(respuesta);
      console.log('Subcategoria actualizada');
    }, error => {
      console.log(error);
      console.log('Error');
    });
  }

  eliminarSubCategoria(subCat: Maesubcategoria) {
    this.http.post(this.way + '/pdv/subcategoria/eacad2bf3cb62800', {
      elementos: {
        SUB_CODIGO: subCat.SUB_CODIGO
      }
    }, this.confIniciales.httpOptions()).subscribe(respuesta => {
      console.log(respuesta);
      console.log('Subcategoria eliminada');
    }, error => {
      console.log(error);
      console.log('Error');
    });
  }

  recogerCATCODIGO() {
    return this.P_CAT_CODIGO.toString();
  }

  recogerSUBCODIGO() {
    return this.P_SUB_CODIGO.toString();
  }
}
