<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="uploadfiles"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="uploadfilesconf" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" *ngIf="allPermisos">
    <div class="p-col-1">
        <span>Archivo:</span>
    </div>
    <div class="p-col-4">
        <input id="DOC_NOMBRE" type="text" pInputText disabled autocomplete="off" [(ngModel)]="documentName">
    </div>
    <div class="p-col-1">
        <div class="divButton" style="background-image: url(../../../assets/images/iconos/buscar.png);"
            (click)="fileInput.value = null; documentName =''; documentbase64 = '' ">
            <input #fileInput type="file" style="opacity: 0.0; position: absolute; 
            top: 0; left: 0; bottom: 0; right: 0; width: 25px; height: 25px; cursor: pointer;"
                (change)="onBasicUpload(fileInput.files)" />
        </div>
    </div>
    <div class="p-col-1">
        <button style="background-image: url(../../../assets/images/iconos/visto.png); 
        background-size: contain !important; width: 25px; height: 25px" (click)="uploadDocument()"
            title="Subir Archivo"></button>
    </div>
    <div class="p-col-4">
    </div>
    <div class="p-col-1">
        <!-- <button style="background-image: url(../../../assets/images/iconos/salir.png);
					background-size: contain !important; width: 25px; height: 25px" (click)="salir()" title="Salir">
        </button> -->
    </div>
    <div class="p-col-12" style="text-align: center">
        <p-table [value]="detalleDocumentos">
            <ng-template pTemplate="header">
            </ng-template>
            <ng-template pTemplate="body" let-documento>
                <tr>
                    <td>
                        <span style="float: left"> {{documento.NOMBRE_ORIGINAL}}</span>
                        <span style="float: right;"> {{documento.TAMANIO_DOCUMENTO}}</span>
                    </td>
                    <td style="text-align: center;">
                        <button style="background-image: url(../../../assets/images/iconos/014-view.png); 
            background-size: contain !important; width: 35px; height: 35px"
                            (click)="visualizar(documento.NOMBRE_FISICO, documento.NOMBRE_ORIGINAL)"
                            title="Visualizar"></button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <button style="background-image: url(../../../assets/images/iconos/downloadfile2.png); 
        background-size: contain !important; width: 35px; height: 35px"
                            (click)="descargar(documento.NOMBRE_FISICO, documento.NOMBRE_ORIGINAL)"
                            title="Descargar"></button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <button style="background-image: url(../../../assets/images/iconos/039-eliminar.png); 
        background-size: contain !important; width: 35px; height: 35px" (click)="confirmarEliminar(documento.NOMBRE_FISICO)"
                            title="Eliminar"></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

</div>

<div class="p-grid" *ngIf="!allPermisos">
    <div class="p-col-12" style="text-align: center">
        <p-table [value]="detalleDocumentos">
            <ng-template pTemplate="header">
            </ng-template>
            <ng-template pTemplate="body" let-documento>
                <tr>
                    <td>
                        <span style="float: left;"> {{documento.NOMBRE_ORIGINAL}}</span>
                        <span style="float: right;"> {{documento.TAMANIO_DOCUMENTO}}</span>
                    </td>
                    <td style="text-align: center;">
                        <button style="background-image: url(../../../assets/images/iconos/014-view.png); 
            background-size: contain !important; width: 25px; height: 25px"
                            (click)="visualizar(documento.NOMBRE_FISICO, documento.NOMBRE_ORIGINAL)"
                            title="Visualizar"></button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <button style="background-image: url(../../../assets/images/iconos/downloadfile2.png); 
        background-size: contain !important; width: 25px; height: 25px"
                            (click)="descargar(documento.NOMBRE_FISICO, documento.NOMBRE_ORIGINAL)"
                            title="Descargar"></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

</div>