<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="conforma"></p-toast>
<div class="p-grid" style="width: 99%">
	<div class="p-col-12">
		<app-invmaebarra [barraBotonesCRUD]="true"
						 [barraBotonesDesplaz]="true"
						 [barraBotones3]="true"
						 [barraBotonesAsiCon]="true"
						 [barraBotones5]="true"
						 (signal)="manejarSenales($event)"></app-invmaebarra>
	</div>
</div>
<div class="divgrups">
	<div class="p-grid">
		<div class="p-col-12"></div>
		<div class="p-col-12"></div>
		<div class="p-col-12"></div>
		<div class="p-col-12"></div>
		<div class="p-col-12"></div>
		<div class="p-col-12"></div>
		<div class="p-col-12"></div>
		<div class="p-col-12"></div>
		<div class="p-col-12"></div>
		<div class="p-col-5"></div>
		<div class="p-col-2">
			<button class="sincss" pButton (click)="conformacion()">Activación
			</button>
		</div>
		<div class="p-col-2">
			<button class="sincss" pButton (click)="enviaractivacion()">Correo
			</button>
		</div>
		<div class="p-col-5"></div>
		<div class="p-col-12"></div>
		<div class="p-col-12"></div>
		<div class="p-col-12"></div>
		<div class="p-col-12"></div>
		<div class="p-col-12"></div>
		<div class="p-col-12"></div>
		<div class="p-col-12"></div>
		<div class="p-col-12"></div>
		<div class="p-col-12"></div>
	</div>
</div>
