<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="firmardoc"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="firmardocConf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid">
	<div class="p-col-12">
		<app-invmaebarra [barraBotonesCRUD]="true" [barraBotonesDesplaz]="true" [barraConEncasil]="true" [barraBotones3]="true"
						 [barraBotones5]="true" (signal)="manejarSenales($event)">
		</app-invmaebarra>
	</div>
</div>
<div class="divgrups">
	<div class="p-grid">
		<div class="p-col-12" style="margin-top: 10px; margin-bottom: 20px">
			<div class="p-grid">
				<div class="p-col-1">
					<span style="float: right">Desde:</span>
				</div>
				<div class="p-col-1">
					<p-calendar [(ngModel)]="dateDesde"
								dateFormat="dd/mm/yy" [inputStyle]="{'width': '100px'}"
								inputId="Calendario"
								[readonlyInput]="true"></p-calendar>
				</div>
				<div class="p-col-1">
					<span style="float: right">Hasta:</span>
				</div>
				<div class="p-col-1">
					<p-calendar [(ngModel)]="dateHasta"
								dateFormat="dd/mm/yy" [inputStyle]="{'width': '100px'}"
								inputId="Calendario"
								[readonlyInput]="true"></p-calendar>
				</div>
				<div class="p-col-1">
				</div>
				<button pButton style="background-image: url(../../../assets/images/iconos/067-binocular.png);
						background-color: rgb(232,243,250); width: 35px; height: 35px"
						title="Filtrar documentos" (click)="aceptar()">
				</button>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-12">
					<p-tabView>
						<p-tabPanel header="Facturas de Venta">
							<div class="divgrups">
								<div class="p-grid">
									<div class="p-col-12">
										<div class="p-grid">
											<div class="p-col-2">
												<p-checkbox label="Seleccionar Todo" binary="true" [(ngModel)]="chkseleccionarTodoFact" (onChange)="selectTodosFact()">
												</p-checkbox>
											</div>
											<button pButton style="background-image: url(../../../assets/images/iconos/009-connect-1.png);
											background-color: rgb(232,243,250); width: 35px; height: 35px"
													title="Firmar documentos" [disabled]="firmadocService.btnFirmarFact[indicador]"
													(click)="firmar('facturas')">
											</button>
											<div class="p-col-7">
											</div>
											<div class="p-col-2">
												<p-checkbox label="Notas de Ventas" binary="true" [(ngModel)]="chkseleccionarNotas">
												</p-checkbox>
											</div>
										</div>
									</div>
								</div>
							</div>
							<app-aggridsaci [width]=""
											[height]="largo"
											[enableSorting]="true"
											[enableFilter]="true"
											[rowData]="firmadocService.detalleVenFacturas[indicador]"
											[frameworkComponents]="frameworkComponents"
											[animateRows]="true"
											[rowSelection]="rowSelection"
											[columnDefs]="columnDefsVenFacturas"
											[defaultColDef]="defaultVenFacturas"
											[rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false">
							</app-aggridsaci>
						</p-tabPanel>
						<p-tabPanel header="Retenciones">
							<div class="divgrups">
								<div class="p-grid">
									<div class="p-col-12">
										<div class="p-grid">
											<div class="p-col-2">
												<p-checkbox label="Seleccionar Todo" binary="true" [(ngModel)]="chkseleccionarTodoRet"
															(onChange)="selectTodosRet()">
												</p-checkbox>
											</div>
											<button pButton style="background-image: url(../../../assets/images/iconos/009-connect-1.png);
											background-color: rgb(232,243,250); width: 35px; height: 35px"
													title="Firmar documentos" [disabled]="firmadocService.btnFirmarRet[indicador]"
													(click)="firmar('retenciones')">
											</button>
										</div>
									</div>
								</div>
							</div>
							<app-aggridsaci [width]=""
											[height]="largo"
											[enableSorting]="true"
											[enableFilter]="true"
											[rowData]="firmadocService.detalleVenRetenciones[indicador]"
											[frameworkComponents]="frameworkComponents"
											[animateRows]="true"
											[rowSelection]="rowSelection"
											[columnDefs]="columnDefsVenRetenciones"
											[defaultColDef]="defaultVenRetenciones"
											[rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false">
							</app-aggridsaci>
						</p-tabPanel>
						<p-tabPanel header="Guía Remisión">
							<div class="divgrups">
								<div class="p-grid">
									<div class="p-col-12">
										<div class="p-grid">
											<div class="p-col-2">
												<p-checkbox label="Seleccionar Todo" binary="true" [(ngModel)]="chkseleccionarTodoGuiaRem"
															(onChange)="selectTodosGuia()">
												</p-checkbox>
											</div>
											<button pButton style="background-image: url(../../../assets/images/iconos/009-connect-1.png);
											background-color: rgb(232,243,250); width: 35px; height: 35px"
													title="Firmar documentos" [disabled]="firmadocService.btnFirmarGuia[indicador]"
													(click)="firmar('guiasRemision')">
											</button>
										</div>
									</div>
								</div>
							</div>
							<app-aggridsaci [width]=""
											[height]="largo"
											[enableSorting]="true"
											[enableFilter]="true"
											[rowData]="firmadocService.detalleVenGuiaRemisiones[indicador]"
											[frameworkComponents]="frameworkComponents"
											[animateRows]="true"
											[rowSelection]="rowSelection"
											[columnDefs]="columnDefsVenGuiaRemision"
											[defaultColDef]="defaultVenGuiaRemision"
											[rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false">
							</app-aggridsaci>
						</p-tabPanel>
						<p-tabPanel header="Nota Crédito">
							<div class="divgrups">
								<div class="p-grid">
									<div class="p-col-12">
										<div class="p-grid">
											<div class="p-col-2">
												<p-checkbox label="Seleccionar Todo" binary="true" [(ngModel)]="chkseleccionarTodoCredito"
															(onChange)="selectTodosCred()">
												</p-checkbox>
											</div>
											<button pButton style="background-image: url(../../../assets/images/iconos/009-connect-1.png);
											background-color: rgb(232,243,250); width: 35px; height: 35px"
													title="Firmar documentos" [disabled]="firmadocService.btnFirmarCred[indicador]"
													(click)="firmar('creditos')">
											</button>
										</div>
									</div>
								</div>
							</div>
							<app-aggridsaci [width]=""
											[height]="largo"
											[enableSorting]="true"
											[enableFilter]="true"
											[rowData]="firmadocService.detalleVenCreditos[indicador]"
											[frameworkComponents]="frameworkComponents"
											[animateRows]="true"
											[rowSelection]="rowSelection"
											[columnDefs]="columnDefsVenCredito"
											[defaultColDef]="defaultVenCreditos"
											[rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false">
							</app-aggridsaci>
						</p-tabPanel>
						<p-tabPanel header="Nota Débito">
							<div class="divgrups">
								<div class="p-grid">
									<div class="p-col-12">
										<div class="p-grid">
											<div class="p-col-2">
												<p-checkbox label="Seleccionar Todo" binary="true" [(ngModel)]="chkseleccionarTodoDebito"
															(onChange)="selectTodosDeb()">
												</p-checkbox>
											</div>
											<button pButton style="background-image: url(../../../assets/images/iconos/009-connect-1.png);
											background-color: rgb(232,243,250); width: 35px; height: 35px"
													title="Firmar documentos" [disabled]="firmadocService.btnFirmarDeb[indicador]"
													(click)="firmar('debitos')">
											</button>
										</div>
									</div>
								</div>
							</div>
							<app-aggridsaci [width]=""
											[height]="largo"
											[enableSorting]="true"
											[enableFilter]="true"
											[rowData]="firmadocService.detalleVenDebitos[indicador]"
											[frameworkComponents]="frameworkComponents"
											[animateRows]="true"
											[rowSelection]="rowSelection"
											[columnDefs]="columnDefsVenDebito"
											[defaultColDef]="defaultVenDebitos"
											[rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false">
							</app-aggridsaci>
						</p-tabPanel>
						<p-tabPanel header="Liquidación de Compra">
							<div class="divgrups">
								<div class="p-grid">
									<div class="p-col-12">
										<div class="p-grid">
											<div class="p-col-2">
												<p-checkbox label="Seleccionar Todo" binary="true" [(ngModel)]="chkseleccionarTodoLiquidacion"
															(onChange)="selectTodosLiq()">
												</p-checkbox>
											</div>
											<button pButton style="background-image: url(../../../assets/images/iconos/009-connect-1.png);
											background-color: rgb(232,243,250); width: 35px; height: 35px"
													title="Firmar documentos" [disabled]="firmadocService.btnFirmarLiq[indicador]"
													(click)="firmar('liquidaciones')">
											</button>
										</div>
									</div>
								</div>
							</div>
							<app-aggridsaci [width]=""
											[height]="largo"
											[enableSorting]="true"
											[enableFilter]="true"
											[rowData]="firmadocService.detalleVenLiquidacion[indicador]"
											[frameworkComponents]="frameworkComponents"
											[animateRows]="true"
											[rowSelection]="rowSelection"
											[columnDefs]="columnDefsVenLiquidacion"
											[defaultColDef]="defaultVenLiquidaciones"
											[rowStyle]="rowStyle"
											[singleClickEdit]="false"
											[botones]="false">
							</app-aggridsaci>
						</p-tabPanel>
					</p-tabView>
				</div>
			</div>
		</div>

	</div>
</div>

<p-dialog header="Clave de Autorización" [(visible)]="claveDialog"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '40vw', height: 'auto'} ">
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							   (keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>

<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
</div>