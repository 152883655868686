import { Component, OnInit } from '@angular/core';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import { ConinicontadorService } from '../conservicios/coninicontador.service';
@Component({
  selector: 'app-condlginicializarcontadores',
  templateUrl: './condlginicializarcontadores.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class CondlginicializarcontadoresComponent implements OnInit {
  displayDialogAutorizacion: boolean;
  displayConteCon: boolean;
  bolAutorizacion: boolean;
  autorizacionCodigo: string;
  claveAuth: string;
  txtContad: string;
  constructor(auditoriagral: AuditoriagralService,
    private messageService: MessageService, 
    private errorService: ErroresBaseDatosService,
    public init: NuevoComponentService,
    private auditoriaGralService: AuditoriagralService,
    private confirmationService: ConfirmationService,
    private coniniconser:ConinicontadorService) { 
     this.displayDialogAutorizacion=true;
     this.displayConteCon=false;
     this.txtContad="0000";
    }

  ngOnInit(): void {
  }
  onHideAutorizacion() {
   
    
    if (this.bolAutorizacion == false) {
      this.init.tabs.splice(this.init.tabindex, 1);

    }else if(this.bolAutorizacion == true){

    }else{
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  comprobarClave() {
    this.coniniconser.obtenerClave().then((clave) => {
      let claveReal;
      
      claveReal = clave[0].AUTORIZACION_CLAVE;
      if (claveReal == this.claveAuth) {
        this.bolAutorizacion=true
        this.accionesAutorizacion();
      } else if (clave != this.claveAuth) {
        this.mensajeAlerta('error','Clave Autorización','La clave que se ingresó es incorrecta, intente nuevamente');
      }
    }); 
  }

  accionesAutorizacion() {
    
    this.displayDialogAutorizacion=false;
    this.displayConteCon=true;
  
  }

  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'autorizacion',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
    
  }
  mensajeAlertaCon(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'contadoresCon',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
    
  }
  manejarSenales(elemento){
    switch (elemento) {
      case 'Visto':
        this.comprobarClave();
       
        break;
      case 'Salir':
       this.init.tabs.splice(this.init.tabindex, 1);
        break;
    
    }
  }
  opcionInicializar(){
    let valor="";
    let audi="";
   
      this.confirmationService.confirm({
        message: '¿Esta seguro de inicializar los contadores de los asientos con la referencia '+this.txtContad+'?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'segNumDialog',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {
          if(this.txtContad=='0'){
            valor='0000';
          }else{
              valor=this.txtContad;
          };
          audi="INICIALIZA/"+valor;
          this.coniniconser.inicializarContadores(valor,'1').then(res=>{
            console.log('entra');
            this.mensajeAlertaCon('success', 'Información', '¡Proceso inicializar contadores terminó satisfactoriamente!');
            this.auditoriaGralService.registrarAuditoria('CON_MAETIPDIA', audi, 'A',
            '', '01', '', '', '', '').toPromise().then(() => {
            });
          }).catch(e=>{
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
            this.mensajeAlertaCon('error', 'Error', mensaje);
          }); 
        },
        reject: () => {
        }
      })
    
  }

  opcionCambios(){
    let valor="";
    let audi="";
   
      this.confirmationService.confirm({
        message: '¿Esta seguro de cambiar los contadores de los asientos con la referencia '+this.txtContad+'?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'segNumCamDialog',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {
          if(this.txtContad=='0'){
              valor='0000';
          }else{
              valor=this.txtContad;
          };
          audi="CAMBIA/"+valor;
          this.coniniconser.cambiarNumeracion(valor).then(res=>{
            this.mensajeAlertaCon('success', 'Información', '¡Proceso cambiar contadores terminó satisfactoriamente!');
            this.auditoriaGralService.registrarAuditoria('CON_MAETIPDIA', audi, 'A',
            '', '01', '', '', '', '').toPromise().then(() => {
            });
          }).catch(e=>{
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
            this.mensajeAlertaCon('error', 'Error', mensaje);
          }); 
        },
        reject: () => {
        }
      })
    
  }
}
