import { Component, OnInit,ViewChild } from '@angular/core';
import { Usuario } from '../../usuario';
import { ConfirmationService, MessageService, SelectItem } from 'primeng';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { DatePipe } from '@angular/common';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import { bantrninversiones } from "../baninterfaces/bantrninversiones";
import {BantrninversionesService  } from "../banservicios/bantrninversiones.service";
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { SaciTrnretencion } from '../../interfaces/sacitrnretencion';
@Component({
  selector: 'app-bantrninversiones',
  templateUrl: './bantrninversiones.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class BantrninversionesComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;
  btnBuscar: boolean;
  bantrninversion: bantrninversiones = {};
  lblNombreBanco:String='';
  tarjdis:boolean=false;
  selectTab = 0;
  largo: string;
  indicador: any;
  dettrnretencion: SaciTrnretencion={};//CDPJ
  public frameworkComponents;
  rowStyle;
  rowSelection = 'multiple';
  defaultbantrninv
  finplazoSeleccionado: string;
  disabledTabDatos:boolean=false;
  disabledTabInteres:boolean=false;
  disabledTabImpu:boolean=false;
  disabledTabDiavenci:boolean=false;
  disabledTabPrecan:boolean=false;
  permisoInver: SegMaePermiso;
  opcion: string;
  types: SelectItem[];
  tabla: string;
  busquedacampos: string[];
  arregloBus: any[];
  displayDialogBusquedaFast: boolean;

  lblNomCtaCert:string='';
  lblNomCtaInteres:string='';
  lblNomCtaOtrosInteres:string='';
  lblNomCtaFPAper:string='';
  lblNomCtaFPCierre:string='';
  lblCntaIsd:string='';
  lblNomCtaBanco:string='';
  lblNombreEntFin:string='';
  constructor(
    public usuario: Usuario, private permisosService: PermisosService,
    private message: MessageService, private errorService: ErroresBaseDatosService,
    private datePipe: DatePipe, private busquedaSer: InvbusquedaService,
    private auditoriagral: AuditoriagralService, private confirmationService: ConfirmationService,
    private utilitariosService: UtilitariosService,
    private confIniciales: ConfInicialesService,
    public bantrninversionesService:BantrninversionesService,
    private init: NuevoComponentService,
  ) { 
    this.agTable();

  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultbantrninv = {
      objeto: 'bantrninver',
      resizable: true
    };
 
  }
  columnDefsInvtrninver=[
    {
      headerName: 'Fecha', field: 'TRNINTINV_FECHA', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 1,
          obligatorio: false,
          fecha: false,
          tamanocelda: 50,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Días Trnascurridos', field: 'TRNINTINV_DIAS', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 1,
          obligatorio: false,
          fecha: false,
          tamanocelda: 50,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Interés Mensual', field: 'TRNINTINV_INTERES', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 1,
          obligatorio: false,
          fecha: false,
          tamanocelda: 50,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Asiento', field: 'ASI_NRO', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 1,
          obligatorio: false,
          fecha: false,
          tamanocelda: 50,
          tienecalculos: true,
        }
      }
    },
  ]
  async ngOnInit() {
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.btnBuscar = false;
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.bantrninversion={};
    this.bantrninversionesService.detbantrninversion=[]
    this.dettrnretencion={}
    this.largo = '400';
    this.selectTab=0;
    this.tarjdis=false;
    //await this.getConfiguracion();
    await this.irnuevo();
    await this.getPermisos();
  }
  ngOnDestroy(): void {
    this.confIniciales.instanciasTrninversiones--;
  }
  async getPermisos() {
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisoInver = data[0];
        } else {
          this.permisoInver = null;
        }
      } else {
        this.permisoInver = null;
      }
    } catch (e) {
      this.permisoInver = null;
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Error',
        detail: 'No se pudo obtener los permisos del formulario'
      });
    }
  }
  manejarSenales(valor){
    if (valor === 'Nuevo') {
      if(this.selectTab !== 0){
        this.selectTab=0;
        this.cambiarbotones(true,true,false,true);
        this.irnuevo();
        document.getElementById('divPrincipal').style.pointerEvents = 'all';
        document.getElementById('ENTFIN_CODIGO').focus();
      }else{
        this.cambiarbotones(true,true,false,true);
        this.irnuevo();
        document.getElementById('divPrincipal').style.pointerEvents = 'all';
        document.getElementById('ENTFIN_CODIGO').focus();
      }
      //this.nuevo();
    }
    if (valor === 'Cancelar') {
      this.cancelar();
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }

  }
  async irnuevo(){
    this.disabledTabInteres=true;
    this.disabledTabImpu=true;
    this.disabledTabDiavenci=true;
    this.disabledTabPrecan=true;
    this.bantrninversion={
      BANINV_CERTIFICADO:'',BANINV_CERTIFICADOAPER:'',COM_CODIGO:'',BAN_CODIGO:'',BANINV_FECHAEMISION:this.datePipe.transform(new Date(), 'dd/MM/yyyy'),
      BANINV_FECHAVENCIMIENTO:this.datePipe.transform(new Date(), 'dd/MM/yyyy'),BANINV_VALORNOMINAL:Number('0').toFixed(2),BANINV_DIASPLAZO:'',BANINV_INTERESANUAL:Number('0').toFixed(2),
      BANINV_RENTABILIDADVENC:Number('0').toFixed(2),BANINV_INTERESDIARIO:Number('0').toFixed(2),BANINV_ORIGENFONDOS:'',BANINV_DESTINOFONDOS:'',BANINV_CTACERTIFICADO:'',BANINV_CTAINTERES:'',
      BANINV_CTAOTROSINT:'',BANINV_ESTADO:'',ASI_NRO_APER:'',BANINV_CTAALTBANCO:'',BANINV_FPAPER:'',BANINV_FPCIERRE:'',
      BANINV_ISD:'',BANINV_TIPOFINPLAZO:'',BANINV_FECHAPRECANCELA:'',BANINV_TASACASTIGO:'',BANINV_INTPRECANCELA:'',
      ASI_PRECANCELA:'',ASI_FINPLAZO:'',ASI_CTAISD:'',ENTFIN_CODIGO:'',BANINV_ARCHIVO:'',BANINV_DIFERENCIA:'',TRNBAN_NUMERO:'',
      TRNBAN_NUMEROPRE:'',
      };
     
  }
  cambiarFoco(evento){
    if (evento.target.id === 'ENTFIN_CODIGO') {
      
       this.buscarBanco(evento.target.value);
     }
     if (evento.target.id === 'BANINV_CERTIFICADO') {

      document.getElementById('BANINV_CERTIFICADOAPER').focus();
     }
     if (evento.target.id === 'BANINV_CERTIFICADOAPER') {
     
      document.getElementById('BANINV_FECHAEMISION').focus();
     }
     if (evento.target.id === 'BANINV_FECHAEMISION') {
   
      document.getElementById('BANINV_FPAPER').focus();
     }
     if (evento.target.id === 'BANINV_FPAPER') {
     
     this.buscarConcodigo(evento.target.value,'CONCODIGO1');
     }
     if (evento.target.id === 'BANINV_DIASPLAZO') {
      document.getElementById('BANINV_FPCIERRE').focus();
     }
     if (evento.target.id === 'BANINV_FPCIERRE') {
      this.buscarConcodigo(evento.target.value,'CONCODIGO2');
     }
     if (evento.target.id === 'BANINV_FECHAVENCIMIENTO') {

      document.getElementById('BANINV_CTACERTIFICADO').focus();
     }
     if (evento.target.id === 'BANINV_CTACERTIFICADO') {
       
      this.buscarConcodigo(evento.target.value,'CONCODIGO3');
     }
     if (evento.target.id === 'BANINV_VALORNOMINAL') {
     
      document.getElementById('BANINV_CTAINTERES').focus();
      }
      if (evento.target.id === 'BANINV_CTAINTERES') {
        this.buscarConcodigo(evento.target.value,'CONCODIGO4');
      }
      if (evento.target.id === 'BANINV_INTERESANUAL') {
     
        document.getElementById('BANINV_CTAOTROSINT').focus();
      }
      if (evento.target.id === 'BANINV_CTAOTROSINT') {
        this.buscarConcodigo(evento.target.value,'CONCODIGO5');
      }
      if (evento.target.id === 'BANINV_RENTABILIDADVENC') {
        document.getElementById('BANINV_CTAALTBANCO').focus();
      }
      if (evento.target.id === 'BANINV_CTAALTBANCO') {
        this.buscarConcodigo(evento.target.value,'CONCODIGO6');
      }
      if (evento.target.id === 'BANINV_INTERESDIARIO') {
     
        document.getElementById('BANINV_ORIGENFONDOS').focus();
      }
      if (evento.target.id === 'BANINV_ORIGENFONDOS') {
     
        document.getElementById('BANINV_DESTINOFONDOS').focus();
      }
      
  }
  cancelar(){
    if( this.bantrninversion.ASI_NRO_APER === ''){
      this.irnuevo();
      this.cambiarbotones(false,false,true,false);
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
    }
  }
  cambiarbotones(val1,val2,val3,val4){
    this.btnBuscar = val1;
    this.btnNuevo = val2;
    this.btnGuardar = val3;
    this.btnBorrar = val4;
  }
  buscarBanco(parametro){

  }
  buscarConcodigo1(parametro){
    this.opcion = 'CUENTAS1';
    this.types = [
      {label: 'CÓDIGO', value: 'CON_CODIGO'},
      {label: 'NOMBRE', value: 'CON_NOMBRE'}
    ];
    this.busquedacampos = ['', ''];
    this.tabla = 'CON_MAECON';
    this.busquedaSer.busquedaConMaeCon2().subscribe((res: any[]) => {
      this.arregloBus = res;
      this.displayDialogBusquedaFast = true;
    });
  }
  buscarConcodigo(parametro,numcuentas){
    this.opcion = numcuentas;
    this.types = [
      { label: 'Codigo', value: 'CON_CODIGO' },
      { label: 'Nombre', value: 'CON_NOMBRE' },
      { label: 'Cnt.Costos', value: 'CON_CENTRO' }
    ];

    this.busquedacampos = ['', '', ''];
    this.tabla = 'CON_MAECON';
    this.bantrninversionesService.encontrarConNombre(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          if (parametro.substr(-1, 1) === '.') {
            this.message.add({
              key: 'inversion',
              severity: 'error',
              summary: 'Cuenta Contable',
              detail: 'No se puede elegir esa cuenta contable'
            });
            switch (this.opcion) {
              case 'CONCODIGO1': {
                this.bantrninversion.BANINV_FPAPER = '';
                this.lblNomCtaFPAper = '';
                break;
              }
              case 'CONCODIGO2': {
                this.bantrninversion.BANINV_FPCIERRE = '';
                this.lblNomCtaFPCierre = '';
              }
              case 'CONCODIGO3': {
                this.bantrninversion.BANINV_CTACERTIFICADO = '';
                this.lblNomCtaCert = '';
                break;
              }           
              case 'CONCODIGO4': {
                this.bantrninversion.BANINV_CTAINTERES = '';
                this.lblNomCtaInteres = '';
                break;
              }      
              case 'CONCODIGO5': {
                this.bantrninversion.BANINV_CTAOTROSINT = '';
                this.lblNomCtaOtrosInteres = '';
                break;
              }           
              case 'CONCODIGO6': {
                this.bantrninversion.BANINV_CTAALTBANCO = '';
                this.lblNomCtaBanco = '';
                break;
              }
              default: {
                break;
              }
            }
            return
          } 
          switch (this.opcion) {  
            case 'CONCODIGO1': {
              this.bantrninversion.BANINV_FPAPER = parametro;
              this.lblNomCtaFPAper = eR[0].CON_NOMBRE;
              break;
            }
            case 'CONCODIGO2': {
              this.bantrninversion.BANINV_FPCIERRE = parametro;
              this.lblNomCtaFPCierre = eR[0].CON_NOMBRE;
            }
            case 'CONCODIGO3': {
              this.bantrninversion.BANINV_CTACERTIFICADO =parametro;
              this.lblNomCtaCert =  eR[0].CON_NOMBRE;
              break;
            }           
            case 'CONCODIGO4': {
              this.bantrninversion.BANINV_CTAINTERES = parametro;
              this.lblNomCtaInteres =  eR[0].CON_NOMBRE;
              break;
            }      
            case 'CONCODIGO5': {
              this.bantrninversion.BANINV_CTAOTROSINT = parametro;
              this.lblNomCtaOtrosInteres =  eR[0].CON_NOMBRE;
              break;
            }           
            case 'CONCODIGO6': {
              this.bantrninversion.BANINV_CTAALTBANCO = parametro;
              this.lblNomCtaBanco =  eR[0].CON_NOMBRE;
              break;
            }
            default: {
              break;
            }
          }
          
          this.cambiarbotones(true,true,false,true);
          
        } else {
          this.busquedaSer.busquedaConMaeCon().subscribe((datos: any[]) => {
            this.arregloBus= datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaConMaeCon().subscribe((datos: any[]) => {
          this.arregloBus = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  onTab1Change(event) {
    this.activateTab(event.index);
  }
  
  activateTab(tabNumber) {
    this.selectTab = tabNumber;
    
  }
  manejarSeleccion(opcion){  
    switch (this.opcion) {  
      case 'CONCODIGO1': {
        if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
          this.message.add({
            key: 'inversion',
            severity: 'error',
            summary: 'Cuenta Contable',
            detail: 'No se puede elegir esa cuenta contable'
          });
          this.bantrninversion.BANINV_FPAPER = '';
          this.lblNomCtaFPAper = '';
          return;
        }
        this.bantrninversion.BANINV_FPAPER = opcion.CON_CODIGO;
        this.lblNomCtaFPAper = opcion.CON_NOMBRE;
        document.getElementById('BANINV_DIASPLAZO').focus();
        break;
      }
      case 'CONCODIGO2': {
        if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
          this.message.add({
            key: 'inversion',
            severity: 'error',
            summary: 'Cuenta Contable',
            detail: 'No se puede elegir esa cuenta contable'
          });
          this.bantrninversion.BANINV_FPCIERRE = '';
          this.lblNomCtaFPCierre = '';
          return;
        }
        this.bantrninversion.BANINV_FPCIERRE = opcion.CON_CODIGO;
        this.lblNomCtaFPCierre = opcion.CON_NOMBRE;
        document.getElementById('BANINV_FECHAVENCIMIENTO').focus();
        break;
      }
      case 'CONCODIGO3': {
        if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
          this.message.add({
            key: 'inversion',
            severity: 'error',
            summary: 'Cuenta Contable',
            detail: 'No se puede elegir esa cuenta contable'
          });
          this.bantrninversion.BANINV_CTACERTIFICADO = '';
          this.lblNomCtaCert = '';
          return;
        }
        this.bantrninversion.BANINV_CTACERTIFICADO = opcion.CON_CODIGO;
        this.lblNomCtaCert =  opcion.CON_NOMBRE;
        document.getElementById('BANINV_VALORNOMINAL').focus();
        break;
      }           
      case 'CONCODIGO4': {
        if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
          this.message.add({
            key: 'inversion',
            severity: 'error',
            summary: 'Cuenta Contable',
            detail: 'No se puede elegir esa cuenta contable'
          });
          this.bantrninversion.BANINV_CTAINTERES = '';
          this.lblNomCtaInteres = '';
          return;
        }
        this.bantrninversion.BANINV_CTAINTERES = opcion.CON_CODIGO;
        this.lblNomCtaInteres =  opcion.CON_NOMBRE;
        document.getElementById('BANINV_INTERESANUAL').focus();
        break;
      }      
      case 'CONCODIGO5': {
        if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
          this.message.add({
            key: 'inversion',
            severity: 'error',
            summary: 'Cuenta Contable',
            detail: 'No se puede elegir esa cuenta contable'
          });
          this.bantrninversion.BANINV_CTAOTROSINT = '';
          this.lblNomCtaOtrosInteres = '';
          return;
        }
        this.bantrninversion.BANINV_CTAOTROSINT = opcion.CON_CODIGO;
        this.lblNomCtaOtrosInteres =  opcion.CON_NOMBRE;
        document.getElementById('BANINV_RENTABILIDADVENC').focus();
        break;
      }           
      case 'CONCODIGO6': {
        if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
          this.message.add({
            key: 'inversion',
            severity: 'error',
            summary: 'Cuenta Contable',
            detail: 'No se puede elegir esa cuenta contable'
          });
          this.bantrninversion.BANINV_CTAALTBANCO = '';
          this.lblNomCtaBanco = '';
          return;
        }
        this.bantrninversion.BANINV_CTAALTBANCO = opcion.CON_CODIGO;
        this.lblNomCtaBanco =  opcion.CON_NOMBRE;
        document.getElementById('BANINV_INTERESDIARIO').focus();
        break;
      }
      default: {
        break;
      }
    }
    this.displayDialogBusquedaFast = false;
  }
  solonumero(ref){
    const input=ref.target;
    let valor=input.value;
    const regex=/^-?\d*(\.\d*)?$/
    if(regex.test(valor)){
      input.value= valor;
    }else{
      input.value=valor.replace(/[^-?\d.]/g,'');
      console.log(input.value.indexOf('-'))

      if (input.value.indexOf('-')>0){
        input.value=input.value.replace('-','')
      }else{
        input.value=input.value.replace(/(?!^)-/g,'')
      }

      const parts = input.value.split('.')
        if(parts.length>2){
          input.value=parts[0]+'.'+parts.slice(1).join('')
        }
      
    }
    
    console.log(input.value)
  }
  generarinteres(){}
  selecciontrninver(evento){}
  cambiofinplazo(){}
  procesar(){}
  reversar(){}
  procesarprecancela(){}
}
