import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class SegclaveautorizaService {
  
  public way: string;
  
  constructor(private http: HttpClient,public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,private confIniciales: ConfInicialesService) { 
 
    this.way = sessionStorage.getItem('way');
    this.usuario.identificacion;
    
  }
  async busActivarValor(codigo){
    console.log(codigo);
    var paquete = 'SEG_PCK_MAEAUTORIZACION_C.SEG_SP_consultarAutorizacion';
    var filtro = " AUTORIZACION_CODIGO='"+codigo+"' AND COM_CODIGO='01'";
    var opcion = "CON";
    var condicion = "";
    return  await this.http.post(this.way + '/nom/claveautoriza/bbhzws1oqs6ftbq', {
      elementos: {
        p_opcion: opcion,
        p_filtro: filtro,
        p_condicion: condicion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async validarActivarValor(password,codigo) {
    var boo: boolean = false;
    await this.busActivarValor(codigo).then(res => {
      if (String(password) == res[0].AUTORIZACION_CLAVE) {
        boo = true;
      } else if (String(password) != res[0].AUTORIZACION_CLAVE) {
        boo = false;
      }
    })
    return boo;
  }
}
