//
// SACI WEB
// Rutina: Maestro de Promocion
// Propietario: Danny Alvarez
// Modificado por: DA
// Fecha de creación: 30-10-2019
// Fecha de Modificación: 29-11-2019
//

import {Component, DoCheck, ElementRef, OnChanges, OnInit, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {AggridBanComponent} from '../../ban/aggrid-ban/aggrid-ban.component';
import {VenmaepromocionService} from '../venservicios/venmaepromocion.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {Venmaepromocion} from '../veninterfaces/venmaepromocion';
import {DatePipe} from '@angular/common';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Component({
  selector: 'app-venmaepromocion',
  templateUrl: './venmaepromocion.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class VenmaepromocionComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  actualizar: boolean;
  codigoAux: any;
  nuevoCampo: boolean;
  tipoBusqueda: string;
  types: SelectItem[];
  arregloCons: any[];
  tabla: string;
  busquedacampos: string[];
  displayDialogBusquedaFast: boolean;
  permisoPromocion: SegMaePermiso;
  date1: any;
  date2: any;
  largo: string;
  promoSeleccionada: Venmaepromocion;
  public frameworkComponents;
  rowStyle;
  defaultColDefPromo;
  rowSelection = 'multiple';
  /*fun() {
    this.inputE1.nativeElement.disabled = true;
  }*/
  columnDefsPromo = [
    {
      headerName: 'Nombre', field: 'MAEPRM_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: false,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80
          }
        }
    },
    {
      headerName: 'Condicion', cellEditor: 'agSelectCellEditor', field: 'MAEPRM_NOMSQL', editable: true, width: 150,
      cellEditorParams: {
        values: ['VEN_FNC_CANTIDAD']
      }
    },
    {
      headerName: 'Desde', field: 'MAEPRM_FECINI', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: true
          }
        }
    },
    {
      headerName: 'Hasta', field: 'MAEPRM_FECFIN', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: true
          }
        }
    },
    {
      headerName: 'Tipo', cellEditor: 'agSelectCellEditor', field: 'MAEPRM_TIPO', editable: true, width: 75,
      cellEditorParams:
        {
          values: ['A', 'S']
        }
    },
    {
      headerName: 'Grupo', field: 'GRUP_CODIGO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 30,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Art/Srv', field: 'MAEPRM_ARTSRV', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 30,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Cant.', field: 'MAEPRM_ARTCANT', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10
          }
        }
    },
    {
      headerName: 'U', cellEditor: 'agSelectCellEditor', field: 'MAEPRM_UNIDAD', editable: true, width: 100,
      cellEditorParams: {
        values: []
      }
    },
    {
      headerName: 'Art/Srv', field: 'MAEPRM_ARTORG', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 30,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Cant.', field: 'MAEPRM_CANTREG', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10
          }
        }
    },
    {
      headerName: 'U', cellEditor: 'agSelectCellEditor', field: 'MAEPRM_UNIORG', editable: true, width: 100,
      cellEditorParams: {
        values: []
      }
    },
    {
      headerName: 'Iva', cellRendererFramework: AggridBanComponent, field: 'MAEPRM_TRIBIVA', editable: false, width: 80
    }
  ];
  
  constructor(public ServicioPromo: VenmaepromocionService, private datePipe: DatePipe, private message: MessageService,
              private errorService: ErroresBaseDatosService, private confirmationService: ConfirmationService,
              private permisosService: PermisosService, private auditoriagral: AuditoriagralService,
              private init: NuevoComponentService) {
    this.agTable();
    this.permisoPromocion = {};
    this.ServicioPromo.venmaepromo = [];
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefPromo = {
      editable: true,
      width: 130,
      objeto: 'maePromocion'
    };
  }
  
 /* ngDoCheck(): void {
    this.aggrid.isEditableProG(true);
    if (this.promoSeleccionada.GRUP_CODIGO === null || this.promoSeleccionada.GRUP_CODIGO === '') {
      this.aggrid.isEditableProA(false);
    } else {
      this.aggrid.isEditableProA(true);
    }
  }*/
  
  ngOnInit() {
    this.tipoBusqueda = 'contains';
    this.permisosService.getListaPermisos('VEN', '1', '693').subscribe((res) => {
      this.permisoPromocion = res[0];
    });
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.largo = '258';
    this.date1 = new Date();
    this.date2 = new Date();
    this.ServicioPromo.getPromo().subscribe((res) => {
        if (res !== null) {
          this.ServicioPromo.venmaepromo = res;
        }
        res.map((prom) => {
          prom.MAEPRM_FECINI = this.datePipe.transform(prom.MAEPRM_FECINI.toLocaleString().substr(0, 10),
            'dd/MM/yyyy');
          prom.MAEPRM_FECFIN = this.datePipe.transform(prom.MAEPRM_FECFIN.toLocaleString().substr(0, 10),
            'dd/MM/yyyy');
          if (prom.MAEPRM_TRIBIVA === '1') {
            prom.MAEPRM_TRIBIVA = true;
          } else if (prom.MAEPRM_TRIBIVA === '0') {
            prom.MAEPRM_TRIBIVA = false;
          }
        });
        this.ServicioPromo.venmaepromo = res;
      }
    );
  }
  
  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      if (this.permisoPromocion.PERINSERCION === 1) {
        this.btnNuevo = true;
        this.btnGuardar = false;
        this.btnBorrar = false;
        this.btnRegresar = false;
        this.nuevoCampo = true;
        this.promoSeleccionada = {
          COM_CODIGO: '01',
          GRUP_CODIGO: '',
          MAEPRM_ARTCANT: 0,
          MAEPRM_ARTORG: '',
          MAEPRM_ARTSRV: '',
          MAEPRM_CANTREG: 0,
          MAEPRM_CODIGO: null,
          MAEPRM_FECFIN: this.date2.toLocaleString().substr(0, 10),
          MAEPRM_FECINI: this.date1.toLocaleString().substr(0, 10),
          MAEPRM_NOMBRE: '',
          MAEPRM_NOMSQL: '',
          MAEPRM_PRECIO: '',
          MAEPRM_TIPO: 'A',
          MAEPRM_TRIBIVA: false,
          MAEPRM_UNIDAD: '',
          MAEPRM_UNIORG: '',
          MAEPRM_VALMAX: 0,
          MAEPRM_VALMIN: 0
        };
        
        this.ServicioPromo.venmaepromo.push(this.promoSeleccionada);
        this.aggrid.refreshaggrid(this.ServicioPromo.venmaepromo, this.defaultColDefPromo.objeto);
      } else if (this.permisoPromocion.PERINSERCION === 0) {
        console.log(this.permisoPromocion);
        this.message.add({
          key: 'maepro',
          severity: 'error',
          summary: 'Inserción de Promocion',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
      this.displayDialogBusquedaFast = false;
    }
    if (valor === 'Guardar') {
      if (this.permisoPromocion.PERACTUALIZACION === 1) {
        this.btnGuardar = true;
        this.btnNuevo = false;
        this.btnBorrar = false;
        this.btnRegresar = true;
        if (this.promoSeleccionada.MAEPRM_FECFIN.includes('/')) {
        } else if (this.promoSeleccionada.MAEPRM_FECFIN.includes('-')) {
          this.promoSeleccionada.MAEPRM_FECFIN =
            this.datePipe.transform(this.promoSeleccionada.MAEPRM_FECFIN.toLocaleString().substr(0, 10),
              'dd/MM/yyyy');
        }
        if (this.promoSeleccionada.MAEPRM_FECINI.includes('/')) {
        } else if (this.promoSeleccionada.MAEPRM_FECINI.includes('-')) {
          this.promoSeleccionada.MAEPRM_FECINI =
            this.datePipe.transform(this.promoSeleccionada.MAEPRM_FECINI.toLocaleString().substr(0, 10),
              'dd/MM/yyyy');
        }
        if (this.promoSeleccionada.MAEPRM_TRIBIVA === true) {
          this.promoSeleccionada.MAEPRM_TRIBIVA = '1';
        } else if (this.promoSeleccionada.MAEPRM_TRIBIVA === false) {
          this.promoSeleccionada.MAEPRM_TRIBIVA = '0';
        }
        this.ServicioPromo.insertarPromocion(this.promoSeleccionada).subscribe((res1) => {
          console.log(res1);
          this.message.add({
            key: 'maepro',
            severity: 'success',
            summary: 'Insercion exitosa',
            detail: 'Se ingreso la promoción correctamente'
          });
          this.auditoriagral.registrarAuditoria('VEN_MAEPROMOCION', String(this.promoSeleccionada.MAEPRM_CODIGO), 'I', '',
            '01', '', '', '', '').subscribe(() => {
          });
        }, error1 => {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
          this.message.add({
            key: 'maepro',
            severity: 'error',
            summary: 'Actualización de Promocion',
            detail: mensaje
          });
        });
      } else if (this.permisoPromocion.PERACTUALIZACION === 0) {
        this.message.add({
          key: 'maepro',
          severity: 'error',
          summary: 'Actualización de Promocion',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }
    }
    if (valor === 'Borrar') {
      if (this.permisoPromocion.PERELIMACION === 1) {
        if (this.promoSeleccionada === undefined) {
          this.message.add({
            key: 'maepro',
            severity: 'error',
            summary: 'Eliminacion de promoción',
            detail: 'Seleccion Incorrecta, no se ha seleccionado una promoción'
          });
        } else {
          this.confirmarpromo();
        }
      } else if (this.permisoPromocion.PERELIMACION === 0) {
        this.message.add({
          key: 'maepro',
          severity: 'error',
          summary: 'Eliminación de Promocion',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
      }
    }
    
    if (valor === 'Cancelar') {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.ServicioPromo.getPromo().subscribe((res) => {
        res.map((prom) => {
          prom.MAEPRM_FECINI = this.datePipe.transform(prom.MAEPRM_FECINI.toLocaleString().substr(0, 10),
            'dd/MM/yyyy');
          prom.MAEPRM_FECFIN = this.datePipe.transform(prom.MAEPRM_FECFIN.toLocaleString().substr(0, 10),
            'dd/MM/yyyy');
          if (prom.MAEPRM_TRIBIVA === '1') {
            prom.MAEPRM_TRIBIVA = true;
          } else if (prom.MAEPRM_TRIBIVA === '0') {
            prom.MAEPRM_TRIBIVA = false;
          }
        });
        this.ServicioPromo.venmaepromo = res;
        this.aggrid.refreshaggrid(this.ServicioPromo.venmaepromo, this.defaultColDefPromo.objeto);
      });
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
  confirmarpromo() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar la promocion',
      header: 'Eliminar Promocion',
      icon: 'pi pi-exclamation-triangle',
      key: 'MaePro',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarpromo();
      },
      reject: () => {
      }
    });
  }
  
  eliminarpromo() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.ServicioPromo.eliminarPromo(this.promoSeleccionada).subscribe(() => {
      this.message.add({
        key: 'maepro',
        severity: 'success',
        summary: 'Eliminación exitosa',
        detail: 'Se eliminó la promoción correctamente'
      });
      this.auditoriagral.registrarAuditoria('VEN_MAEPROMOCION', String(this.promoSeleccionada.MAEPRM_CODIGO), 'E', '',
        '01', '', '', '', '').subscribe(() => {
      });
      this.ServicioPromo.venmaepromo = this.ServicioPromo.venmaepromo.filter((val, j) => j !==
        this.ServicioPromo.venmaepromo.indexOf(this.promoSeleccionada));
      this.aggrid.refreshaggrid(this.ServicioPromo.venmaepromo, this.defaultColDefPromo.objeto);
    }, error1 => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.message.add({
        key: 'maepro',
        severity: 'error',
        summary: 'Eliminación de la promocion',
        detail: mensaje
      });
    });
  }
  
  selectedPromo(valor) {
    if (valor.object !== null) {
      this.promoSeleccionada = valor.object;
    }
  }
  
  cambio(cambio) {
    this.btnGuardar = false;
    this.btnRegresar = false;
  }
}
