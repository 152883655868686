import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  DoCheck,
  Input,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  AfterViewChecked, ChangeDetectorRef
} from '@angular/core';
import {Maearticulo} from '../../../interfaces/maearticulo';
import {Maecliente} from '../../../interfaces/maecliente';
import {Maevendedor} from '../../../interfaces/maevendedor';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PedpedidoService} from '../../venservicios/pedpedido.service';
import {CalculospedidosService} from '../../venservicios/calculospedidos.service';
import {VenEncped} from '../../veninterfaces/venencped';
import {VenDetped} from '../../veninterfaces/vendetped';


@Component({
  selector: 'app-pedcalculadora',
  templateUrl: './pedcalculadora.component.html',
  styleUrls: ['./pedcalculadora.component.css']
})
export class PedcalculadoraComponent implements OnInit, DoCheck, OnChanges, AfterViewInit, AfterViewChecked {

  @Output() valor = new EventEmitter<number>();
  @Output() codCliente = new EventEmitter<string>();
  @Output() numfact = new EventEmitter<string>();
  @Output() openTeclado = new EventEmitter<string>();
  @Output() estado = new EventEmitter<string>();
  @Output() pagada = new EventEmitter<boolean>();
  @Input() inpcliente: Maecliente;
  @Input() inpvendedor: Maevendedor;
  @Input() auxiliar: any;
  @Input() pedido: string;
  @Input() inppedido: VenEncped;
  @Input() inpchkpedido: boolean;
  @Input() inpDate: Date;
  @Input() nomArt: any;
  @Input() facPagada: boolean;
  @Input() initPrin: number;
  @Input() producto: Maearticulo;
  @Input() fechainicial: Date;
  @Input() fechafinal: Date;
  @Input() valorDscGlobalP: number;
  @Input() valorDscGlobalV: number;
  @Input() desenfoque: boolean;
  productobol = false;
  venencped: VenEncped;
  respuestadet: any;
  cantidad: string;
  prodSeleccionado: Maearticulo;
  indexProdSeleccionado: number;
  respuesta: any;
  string = '';
  stringTeclado = '';
  selectedArticulos: Maearticulo[];
  filteredArticuloSingle: any[];
  objsel: any[] = [];
  query: any = undefined;
  darFocus: [number] = [0];
  dscGlobal: boolean;
  dscGValor: boolean;
  dscGPorcentaje: boolean;
  dscSeleccionado: string;
  valorInvalidoV: boolean;
  valorInvalidoP: boolean;
  subtotal: [any];
  botonDsc: boolean;
  valorDscGV: number;
  valorDscGP: number;

  constructor(private pedidoServ: PedpedidoService, private confirmationService: ConfirmationService,
              private calc: CalculospedidosService, private messageService: MessageService, private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.facPagada = false;
    this.darFocus[this.initPrin - 1] = 0;
    this.dscGlobal = false;
    this.dscGValor = true;
    this.dscGPorcentaje = true;
    this.valorDscGlobalP = undefined;
    this.valorDscGlobalV = undefined;
    this.subtotal = [0];
    this.valorInvalidoV = false;
    this.valorInvalidoP = false;
    this.botonDsc = true;
  }

  ngAfterViewInit(): void {
    this.cd.detach();
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  ngDoCheck(): void {
    this.prodSeleccionado = this.calc.recibirArt();
    this.indexProdSeleccionado = this.calc.recibirIndex();
    this.string = this.calc.returnCalcValue();
    this.darFocus = this.calc.getFocus();
    this.subtotal = this.calc.entregarSubtotal();
    if (this.darFocus[this.initPrin - 1] === 1 && this.facPagada === false) {
      this.enfocar();
    }
    this.calc.recibirValorTeclado(this.stringTeclado);
    if (this.dscSeleccionado === 'dscGValor') {
      this.dscGValor = false;
      this.dscGPorcentaje = true;
    } else if (this.dscSeleccionado === 'dscGPorcentaje') {
      this.dscGPorcentaje = false;
      this.dscGValor = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propertyName in changes) {
      if (propertyName === 'nomArt' && this.nomArt !== (undefined || null)) {
        if (this.nomArt !== (undefined || null || 9999999999999)) {
          this.filterArticuloSingle(this.nomArt);
        } else if (this.nomArt === 9999999999999) {
          this.nomArt = '';
          this.openTeclado.emit('cerrar');
        }
      } else if (propertyName === 'producto') {
        this.vaciarArreglo();
      } else if (propertyName === 'valorDscGlobalP') {
        if (this.valorDscGlobalP !== (undefined || null || 9999999999999)) {
          this.valorDscGP = this.valorDscGlobalP;
          this.comprobarValor();
        } else if (this.valorDscGlobalP === 9999999999999) {
          this.openTeclado.emit('cerrar');
        }
      } else if (propertyName === 'valorDscGlobalV') {
        if (this.valorDscGlobalV !== (undefined || null || 9999999999999)) {
          this.valorDscGV = this.valorDscGlobalV;
          this.comprobarValor();
        } else if (this.valorDscGlobalV === 9999999999999) {
          this.openTeclado.emit('cerrar');
        }
      } else if (propertyName === 'desenfoque') {
        if (this.desenfoque === true && this.darFocus[this.initPrin - 1] === 0) {
          this.desenfocar();
        }
      }
    }
  }

  teclado(valor: string) {
    this.calc.editFocus(0, this.initPrin);
    this.openTeclado.emit(valor);
  }

  enviarNumero(num: number) {
    this.productobol = false;
    this.cantidad = this.cantidad + '' + num;
    this.valor.emit(num);
  }

  aplicarDescuentoGlobal() {
    this.dscGlobal = true;
    this.darFocus[this.initPrin - 1] = 0;
    this.vaciarArreglo();
  }

  enviarDscGlobal() {
    this.dscGlobal = false;
    this.calc.descGlobalV2(this.valorDscGV, this.valorDscGP, this.initPrin, this.calc.recibirFacComun()[this.initPrin - 1]);
    this.valorDscGlobalP = undefined;
    this.valorDscGlobalV = undefined;
  }

  comprobarValor() {
    if (this.dscGValor === false) {
      this.valorDscGP = Number(this.valorDscGV * 100) / Number(this.subtotal[this.initPrin - 1]);
      this.valorDscGP = Number(this.valorDscGP.toFixed(2));
      if (this.valorDscGV > Number(this.subtotal[this.initPrin - 1])) {
        this.valorInvalidoV = true;
        this.botonDsc = true;
      } else {
        this.valorInvalidoV = false;
        this.botonDsc = false;
      }
    } else if (this.dscGPorcentaje === false) {
      this.valorDscGV = Number(this.valorDscGP * this.subtotal[this.initPrin - 1]) / 100;
      this.valorDscGV = Number(this.valorDscGV.toFixed(2));
      if (this.valorDscGP > 100) {
        this.valorInvalidoP = true;
        this.botonDsc = true;
      } else {
        this.valorInvalidoP = false;
        this.botonDsc = false;
      }
    }
  }

  confirmarEliminar() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar esta pedido',
      header: 'Eliminar pedido Actual',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarCalc',
      accept: () => {
        this.eliminarpedido();
      },
      reject: () => {
      }
    });
  }

  eliminarpedido() {
    if (this.inppedido.ENCPED_ESTADO === 'PAGADA') {
      this.messageService.add({
        key: 'calc',
        severity: 'warn',
        summary: 'No se pudo eliminar',
        detail: 'El pedido cuenta con una forma de pago y no se puede eliminar',
      });
      return;
    }
    this.calc.eliminarFac(this.initPrin);
    const aux1 = this.eliminarFac();
    if (aux1 === 0) {
      this.messageService.add({
        key: 'calc',
        severity: 'success',
        summary: 'Pedido Eliminado',
        detail: 'Se eliminó el pedido de la base de datos',
      });
    } else {
      this.messageService.add({
        key: 'calc',
        severity: 'error',
        summary: 'Pedido No Eliminado',
        detail: 'Ocurrio un error al eliminar el pedido',
      });
    }
    this.codCliente.emit('9999999999999');
  }

  eliminarDetalle(): number {
    let respuesta = [];
    this.pedidoServ.borrarTodoDetalle(this.inppedido.ENCPED_NUMERO).subscribe((res) => {
      respuesta = res;
    });
    if (respuesta.length === 0) {
      return 0;
    } else {
      return 1;
    }
  }

  eliminarFac(): number {
    let response = [];
    this.pedidoServ.eliminarEncPed(this.inppedido.ENCPED_NUMERO).subscribe((res) => {
      response = res;
    });
    if (response.length === 0) {
      return 0;
    } else {
      return 1;
    }

  }

  guardar() {
    this.darFocus[this.initPrin - 1] = 0;


    if (this.validarcobrar() === false) {
      return;
    }

    this.venencped = {};
    if (this.inppedido.ENCPED_NUMERO === undefined) {
      this.venencped.ENCPED_NUMERO = null;
      this.venencped.ENCPED_ESTADO = 'P';
      this.venencped.ENCPED_FORMAPAGO = '';
      this.messageService.add({
        key: 'calc',
        severity: 'success',
        summary: 'Pedido Guardada',
        detail: 'Se guardo la pedido',
      });
    } else {
      this.pedidoServ.borrarTodoDetalle(this.inppedido.ENCPED_NUMERO).subscribe(() => {
      });
      this.venencped.ENCPED_NUMERO = this.inppedido.ENCPED_NUMERO;
      if (this.inppedido.ENCPED_FORMAPAGO === undefined || this.inppedido.ENCPED_FORMAPAGO === null ||
        this.inppedido.ENCPED_FORMAPAGO === '') {
        this.venencped.ENCPED_FORMAPAGO = '';
      } else {
        this.venencped.ENCPED_FORMAPAGO = this.inppedido.ENCPED_FORMAPAGO;
      }
      if (this.inppedido.ENCPED_ESTADO === 'PAGADA') {
        this.venencped.ENCPED_ESTADO = 'G';
      } else {
        this.venencped.ENCPED_ESTADO = 'P';
      }
      this.messageService.add({
        key: 'calc',
        severity: 'success',
        summary: 'pedido Guardada',
        detail: 'Se Actualizo el Pedido',
      });
    }
    this.venencped.ENCPED_FECHAEMISION = this.inpDate.toLocaleString('en-GB', {timeZone: 'America/Bogota'}).replace(',', '').substr(0, 10);
    this.venencped.ENCPED_FECHAENTREGA = this.inpDate.toLocaleString('en-GB', {timeZone: 'UTC'}).substr(0, 10);
    this.venencped.CLI_CODIGO = this.inpcliente.CLI_CODIGO;
    this.venencped.CLI_NOMBRE = this.inpcliente.CLI_NOMBRE;
    this.venencped.BOD_CODIGO = this.inpvendedor.BOD_CODIGO;
    this.venencped.VEN_CODIGO = this.inpvendedor.VEN_CODIGO;
    this.venencped.ENCPED_LISTA = 'A';
    this.venencped.ENCPED_TOTAL = Number(this.calc.entregarTotal()[this.initPrin - 1]);
    this.venencped.ENCPED_TOTALNETO = Number(this.calc.entregarSubtotal()[this.initPrin - 1]);
    this.venencped.ENCPED_PORCEIVA = '12';
    this.venencped.ENCPED_VALORIVA = Number(this.calc.entregarIVA()[this.initPrin - 1]);
    this.venencped.ENCPED_PORCEDES = Number(this.calc.entregarDescuentoP()[this.initPrin - 1]);
    this.venencped.ENCPED_VALORDES = Number(this.calc.entregarDescuento()[this.initPrin - 1]);
    this.venencped.ENCPED_VALORICE = 0;
    this.venencped.ENCPED_PORCEICE = 0;
    this.venencped.ENCPED_IVA = '1';
    this.venencped.ENCPED_BASEIVA = Number(this.calc.entregarBaseIVA()[this.initPrin - 1]);
    this.venencped.ENCPED_BASEICE = 0;
    this.venencped.ENCPED_BASECERO = Number(this.calc.entregarBaseCero()[this.initPrin - 1]);
    this.venencped.ENCPED_GRUPO = null;
    this.venencped.ENCPED_TIPODSCTO = null;
    this.venencped.ENCPED_REFCLI = 'PE 8787';
    // if (this.inpchkpedido === false) {
    //   this.venencped.ENCPED_NOTADEVENTA = 'S';
    // } else {
    // this.venencped.ENCPED_NOTADEVENTA = null;
    // }

    this.pedidoServ.insertarpedido(this.venencped).subscribe((respuesta: any[]) => {
      this.respuesta = respuesta;
      console.log(this.respuesta);
      this.numfact.emit(this.respuesta[0].v_ENCPED_numero);
      for (let i = 0; i < this.calc.recibirFacComun()[this.initPrin - 1].length; i++) {
        const vendetped: VenDetped = {};
        const detalle: Maearticulo = this.calc.recibirFacComun()[this.initPrin - 1][i];
        vendetped.DETPED_ORDEN = i + 1;
        vendetped.DETPED_LINEA = 0;
        vendetped.LINEA = i + 1;
        vendetped.ENCPED_NUMERO = this.respuesta[0].v_ENCPED_numero;
        vendetped.DETPED_TIPODET = 'A';
        vendetped.BOD_CODIGO = '001';
        vendetped.DETPED_CODIGO = detalle.ART_CODIGO;
        vendetped.DETPED_DESCRIPCION = detalle.ART_NOMBREC;
        vendetped.DETPED_TRIBIVA = detalle.ART_TRIBUTAIVA;
        vendetped.DETPED_TRIBICE = 'N';
        vendetped.DETPED_CANTIDAD = Number(detalle.CANTIDAD);
        vendetped.DETPED_PRECIO = Number(detalle.ARTPRE_PRECIO);
        vendetped.DETPED_DESCUENTO = Number(detalle.DESCUENTO);
        vendetped.DETPED_TOTAL = Number(detalle.CANTIDAD) * Number(detalle.ARTPRE_PRECIO);
        if (detalle.ART_TRIBUTAIVA === 'S') {
          vendetped.DETPED_IVA = (detalle.ARTPRE_PRECIO * detalle.CANTIDAD
            - ((detalle.DESCUENTO / 100) * (detalle.ARTPRE_PRECIO * detalle.CANTIDAD))) * 12 / 100;
        } else {
          vendetped.DETPED_IVA = 0;
        }
        vendetped.DETPED_ICE = 0;
        vendetped.DETPED_LISTA = 'A';
        if (detalle.ART_TRIBUTAIVA === 'S') {
          vendetped.DETPED_BASEIVA = (detalle.ARTPRE_PRECIO * detalle.CANTIDAD
            - ((detalle.DESCUENTO / 100) * (detalle.ARTPRE_PRECIO * detalle.CANTIDAD)));
          vendetped.DETPED_BASECERO = 0;
        } else {
          vendetped.DETPED_BASEIVA = 0;
          vendetped.DETPED_BASECERO = (detalle.ARTPRE_PRECIO * detalle.CANTIDAD
            - ((detalle.DESCUENTO / 100) * (detalle.ARTPRE_PRECIO * detalle.CANTIDAD)));
        }
        vendetped.DETPED_BASEICE = 0;
        vendetped.DETPED_PORCEICE = 0;
        vendetped.ENCPED_NUMERO = null;
        vendetped.ENCPED_NUMERO = this.respuesta[0].v_ENCPED_numero;
        vendetped.DETPED_PROMOCION = 0;
        vendetped.DETPED_ORDEN = i + 1;
        vendetped.DETPED_UNIDAD = detalle.ART_UNIDADVENTA;
        vendetped.MAEPROVTA_CODIGO = '';
        vendetped.DETPED_CODALTERNO = '';
        vendetped.DETPED_NUMBLO = 0;
        vendetped.DETPED_DESPACHO = 0;

        this.pedidoServ.insertarDetalle(vendetped).subscribe((response: any[]) => {
          console.log(response);
          vendetped.DETPED_LINEA = vendetped.LINEA;
          this.respuestadet = response;
        });
      }
    }, error1 => {
      console.log(error1.error);
    });
  }

  buscarProducto() {
    this.calc.editFocus(0, this.initPrin);
    this.productobol = true;
    /*this.pedidoServ.recogerCodigoProd(this.stringTeclado).subscribe((respuesta: any[]) => {
      if (respuesta.length === 0) {
        this.messageService.add({
          key: 'calc',
          severity: 'info',
          summary: 'Productos Seleccionados',
          detail: 'No existe producto con ese nombre'
        });
        this.stringTeclado = undefined;
      } else {
        console.log(respuesta[0]);
        this.codProd = respuesta[0].ART_CODIGO;
        this.pedidoServ.recogerProducto(this.codProd).subscribe((respuestas: any[]) => {
          this.selectedArticulos = respuestas;
          this.calc.llenarFac(this.selectedArticulos[0], '0');
          this.stringTeclado = undefined;
        });
      }
    });*/
  }

  borrarArreglo() {
    this.calc.reducirCant();
  }

  buscarArticulo() {
    this.pedidoServ.recogerProducto(this.stringTeclado).subscribe((respuesta: any[]) => {
      if (respuesta.length === 0) {
        this.messageService.add({
          key: 'calc',
          severity: 'warn',
          summary: 'Productos Seleccionados',
          detail: 'No existe producto con ese codigo'
        });
        this.selectedArticulos = respuesta;
        this.stringTeclado = undefined;
      } else {
        this.selectedArticulos = respuesta;
        if (this.inppedido.ENCPED_NUMERO === undefined || this.inppedido.ENCPED_NUMERO === null || this.inppedido.ENCPED_NUMERO === '') {
          this.calc.llenarFac(this.selectedArticulos[0], '0', this.initPrin);
        } else {
          this.calc.llenarFac(this.selectedArticulos[0], '1', this.initPrin);
        }
        // this.calc.llenarFac(this.selectedArticulos[0], '0');
        this.stringTeclado = undefined;
      }
    });
  }

  vaciarArreglo() {
    this.stringTeclado = '';
    this.calc.vaciarCant();
  }

  validarcobrar() {
    const dateini = new Date(this.fechainicial);
    const datefin = new Date(this.fechafinal);

    if (this.inpDate >= datefin) {
      this.messageService.add({
        key: 'calc',
        severity: 'error',
        summary: 'Periodo Contable',
        detail: 'Fecha fuera del periodo contable'
      });
      return false;
    }

    if (this.inpDate <= dateini) {
      this.messageService.add({
        key: 'calc',
        severity: 'error',
        summary: 'Periodo Contable',
        detail: 'Fecha fuera del periodo contable'
      });
      return false;
    }


    if (this.inpcliente.CLI_CODIGO === undefined || this.inpcliente.CLI_CODIGO === null || this.inpcliente.CLI_CODIGO === '' ||
      this.inpcliente.CLI_NOMBRE === undefined || this.inpcliente.CLI_NOMBRE === null || this.inpcliente.CLI_CODIGO === '') {
      this.messageService.add({
        key: 'calc',
        severity: 'error',
        summary: 'Ingrese cliente',
        detail: 'No existe cliente'
      });
      return false;
    }

    if (this.inpvendedor.VEN_CODIGO === undefined || this.inpvendedor.VEN_CODIGO === null || this.inpvendedor.VEN_CODIGO === '') {
      this.messageService.add({
        key: 'calc',
        severity: 'error',
        summary: 'Ingrese vendedor',
        detail: 'No existe vendedor'
      });
      return false;
    }

    if (this.inpvendedor.CAJ_CODIGO === undefined || this.inpvendedor.CAJ_CODIGO === null || this.inpvendedor.CAJ_CODIGO === '') {
      this.messageService.add({
        key: 'calc',
        severity: 'warn',
        summary: 'Vendedor',
        detail: 'El vendedor seleccionado no tiene caja asignada'
      });
      return false;
    }

    if (this.inpvendedor.BOD_CODIGO === undefined || this.inpvendedor.BOD_CODIGO === null || this.inpvendedor.BOD_CODIGO === '') {
      this.messageService.add({
        key: 'calc',
        severity: 'warn',
        summary: 'Vendedor',
        detail: 'El vendedor seleccionado no tiene bodega asignada'
      });
      return false;
    }


    if (this.auxiliar === '0') {
      if (this.calc.entregarpedido()[this.initPrin - 1].length === 0) {
        this.messageService.add({
          key: 'calc',
          severity: 'error',
          summary: 'Ingrese artículos',
          detail: 'No existen productos en el detalle'
        });
        return false;
      }
    } else {
      if (this.calc.recibirDet()[this.initPrin - 1].length === 0) {
        this.messageService.add({
          key: 'calc',
          severity: 'error',
          summary: 'Ingrese artículos',
          detail: 'No existen productos en el detalle'
        });
        return false;
      }
    }
    return true;

  }

  onKeyPress() {
    this.buscarArticulo();
  }

  enfocar() {
    if (document.getElementById('search-bar') !== null) {
      document.getElementById('search-bar').focus();
    }
  }

  desenfocar() {
    if (document.getElementById('search-bar') !== null) {
      document.getElementById('search-bar').blur();
    }
  }

  /*ARTICULO*/

  filterArticuloSingle(event) {
    if (event.query === undefined) {
      this.query = event;
    } else {
      this.query = event.query;
    }
    this.pedidoServ.recogerArticulosFilter().subscribe((respuesta: any[]) => {
      this.filteredArticuloSingle = this.filterArticulo(this.query, respuesta);
    });
  }

  filterArticulo(query, articulos: any[]): any[] {
    const filtered: any[] = [];
    for (let i = 0; i < articulos.length; i++) {
      const art = articulos[i];
      if (art.ART_NOMBREC.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(art.ART_NOMBREC);
        this.objsel.push(art);
      }
    }
    return filtered;
  }


  public onSelectArticulo(art): void {
    let codart = '';
    for (const i of this.objsel) {
      if (i.ART_NOMBREC === art) {
        codart = i.ART_CODIGO;
      }
    }

    this.pedidoServ.recogerProducto(codart).subscribe((respuesta: any[]) => {
      if (respuesta.length === 0) {
        this.messageService.add({
          key: 'calc',
          severity: 'warn',
          summary: 'Productos Seleccionados',
          detail: 'No existe producto con ese codigo'
        });
        this.selectedArticulos = respuesta;
        this.stringTeclado = undefined;
      } else {
        this.selectedArticulos = respuesta;
        // console.log('articulo', this.selectedArticulos);
        if (this.inppedido.ENCPED_NUMERO === undefined || this.inppedido.ENCPED_NUMERO === null || this.inppedido.ENCPED_NUMERO === '') {
          this.calc.llenarFac(this.selectedArticulos[0], '0', this.initPrin);
        } else {
          this.calc.llenarFac(this.selectedArticulos[0], '1', this.initPrin);
        }
        this.stringTeclado = undefined;
      }
      this.nomArt = '';
    });
    this.productobol = false;
    this.openTeclado.emit('cerrar');
  }

}
