<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="invreserva"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="invreservaconf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="botonNuevo" [botonGuardar]="botonGuardar" [botonBorrar]="botonBorrar"
						 [botonRegresar]="botonRegresar"
						 (signal)="manejarSenales($event)" [barraBotonesDesplaz]="true" [barraBotones3]="true"
						 [barraBotonesAsiCon]="true" [imprimir]="false"
						 [barraBotones5]="false"
						 [botonBuscar]="botonBuscar"
						 [botonBinocular]="true"
		></app-invmaebarra>
	</div>
</div>

<div class="divgrups">
	<div class="p-grid">
		<div class="p-col-1">
			<span style="float: right">Bodega Origen:</span>
		</div>
		<div class="p-col-2" style="text-align: left">
			<div class="p-grid">
				<div class="p-col-8">
					<input id="BOD_CODIGO" type="text" pInputText autocomplete="off"
						   [(ngModel)]="txtBodCodigo">
				</div>
				<div class="p-col-4">
					<button type="button"
							style="background-image: url(../../../assets/images/iconos/buscar.png);"
							class="littlebuttons" (click)="buscarBodega(txtBodCodigo)"></button>
				</div>
			</div>
		</div>
		<div class="p-col-2" style="text-align: left">
			<input id="BAN_NOMBRE" type="text" pInputText disabled autocomplete="off" [(ngModel)]="txtBodNombre">
		</div>
		<div class="p-col-3">
		</div>

		<div class="p-col-2" style="text-align: left">
			<p-checkbox label="Ocultar caducados" binary="{{chkcaducados}}"
						[(ngModel)]="chkcaducados" (onChange)="ocultarCaducados()"></p-checkbox>

		</div>
		<div class="p-col-2" style="text-align: left">
			<p-checkbox label="Visualizar despachados" binary="{{chkentregados}}"
						[(ngModel)]="chkentregados" (onChange)="visualizarDespachados()"></p-checkbox>
		</div>

	</div>
</div>
<app-aggridsaci [width]=""
				[height]="'375'"
				[enableSorting]="true"
				[enableFilter]="true"
				[rowData]="reservasService.detalleReservas[indicador]"
				[frameworkComponents]="frameworkComponents"
				[animateRows]="true"
				[rowSelection]="rowSelection"
				[columnDefs]="columnDefsDetalle"
				[defaultColDef]="defaultColDefDetalle"
				[rowStyle]="rowStyle"
				[singleClickEdit]="false"
				[botones]="false"
				(selected)="selecciondetalle($event)"
				(cambios)="cambio($event)">
</app-aggridsaci>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: 'auto'} ">
	<app-busquedadlgmae [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
						[where]="condicion"
						[busquedaParams]="busquedacampos"
						(opcionSeleccionada)="manejarSeleccion($event)"
						[busquedaCompuesta]="false"></app-busquedadlgmae>
</p-dialog>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast1"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>
