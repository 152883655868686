//
// SACI WEB
// Rutina: Pto Vta Principal
// Propietario: Victor Perez, Wilson Ramos
// Modificado por: VP, WR
// Fecha de creación: 2019
// Fecha de Modificación: 2019
//
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Maecliente } from '../../interfaces/maecliente';
import { Maevendedor } from '../../interfaces/maevendedor';
import { CalculosServiceService } from '../../servicios/calculos-service.service';
import { Maearticulo } from '../../interfaces/maearticulo';
import { VenEncfac } from '../../interfaces/venencfac';
import { PdvfacturaService } from '../../servicios/pdvfactura.service';
import { VenDetfac } from '../../interfaces/vendetfac';
import { ConfirmationService, MessageService } from 'primeng/api';
import Keyboard from 'simple-keyboard';
import { CxcTrncobro } from '../../interfaces/cxctrncobro';
import { SaciTrnretencion } from '../../interfaces/sacitrnretencion';
import { CxcTrnpago } from '../../interfaces/cxctrnpago';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { PdvcalculadoraComponent } from '../pdvcalculadora/pdvcalculadora.component';
import { DatePipe } from '@angular/common';
import { toNumbers } from '@angular/compiler-cli/src/diagnostics/typescript_version';


@Component({
  selector: 'app-pdvprincipal',
  templateUrl: './pdvprincipal.component.html',
  styleUrls: ['../../../../node_modules/simple-keyboard/build/css/index.css',
    './pdvprincipal.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false
})
export class PdvprincipalComponent implements OnInit, DoCheck, OnDestroy, AfterViewChecked, AfterViewInit {
  // @ts-ignore
  @ViewChild(PdvcalculadoraComponent) calculadora: PdvcalculadoraComponent;
  public producto: Maearticulo;
  public bolcobrar: boolean;
  date1: any;
  chkfactura: any;
  datosiniciales: any;
  codCli: string;
  codVen: string;
  cliente: string;
  vendedor: string;
  nombrecliente: string;
  nombrevendedor: string;
  subtotal: [any] = [0];
  iva: [any] = [0];
  total: [any] = [0];
  tr: any;
  asientoNum: any = 0;
  invalidDates: Array<Date>;
  minDate: Date;
  maxDate: Date;
  es: any;
  today: any;
  string: any;
  seleccionadaBarra = true;
  desenfocar: boolean;
  // dialogs
  displayDialog: boolean;
  displayDialogVendedor: boolean;
  displayDialogBuscarFacturas: boolean;
  displayDialogClave: boolean;
  displayDialogTeclado: boolean;
  displayDialogNewCli: boolean;
  displayDialogRazonAnular: boolean;
  razonanular: string;
  codigobloqueado: boolean;
  maecliente: Maecliente = {};
  filteredClientesSingle: any[];
  filteredNameClientesSingle: any[];
  maevendedor: Maevendedor = {};
  facSeleccionada: VenEncfac = {};
  facturas: VenEncfac[] = [];
  detfactura: VenDetfac[] = [];
  trncobro: CxcTrncobro[] = [];
  trnretencion: SaciTrnretencion[] = [];
  trnpago: CxcTrnpago[] = [];
  filteredVendedorSingle: any[];
  filteredNameVendedorSingle: any[];
  keyboard: Keyboard;
  value: any;
  infoCliente: any;
  titulo: any = 'Datos Factura';
  datos: any;
  auxiliar: any = '0';
  new = false;
  newven = true;
  firsttime = false;
  clavevendedor: any = '';
  vendedorinicial: any = '';
  vendedornombreini: any = '';
  pagada: boolean;
  alturateclado = '';
  anchoteclado = '';
  posicionBarra = 'bottom';
  efectivo: any;
  numero = null;
  numeroCta = null;
  numRetencion = null;
  numBase = null;
  numeroImporte = null;
  productoBuscar = null;
  dscValor = null;
  dscPorcentaje = null;
  tecladoenPantalla: boolean;
  decimalesConfigVenENCFAC = [];
  auxcodigovendedor = '';
  auxnombrevendedor = '';
  auxcajavendedor = '';
  auxbodegavendedor = '';
  autodialog = true;
  auxcodigocliente = '';
  auxnombrecliente = '';
  auxruccliente = '';
  auxdireccioncliente = '';
  auxtelefonocliente = '';
  auxcorreocliente = '';
  ide = '';
  instanciaPrin = 0;
  busquedaFac: any;
  fechainicial: Date;
  fechafinal: Date;
  inputsIniciales: boolean;
  fechaInput: boolean;
  clisel: any[] = [];
  valorcedulaI: boolean;
  valorRucI: boolean;
  cmbtipoide: any[];
  cmbtipoideaux: any[];

  constructor(private facturaserv: PdvfacturaService, private calc: CalculosServiceService, private messageService: MessageService,
    private confirm: ConfirmationService, private cd: ChangeDetectorRef, private initConf: ConfInicialesService,
    private initCom: NuevoComponentService, private datePipe: DatePipe, private init: NuevoComponentService) {
  }

  ngOnInit() {
    this.desenfocar = false;
    this.inputsIniciales = true;
    this.initCom.componenteIniciado();
    this.instanciaPrin = this.initCom.obtenerComponenteNum();
    this.initConf.getConfiguracion('ACTTECPAN').subscribe((respuesta) => {
      if (respuesta[0].CFG_VALOR1 === 1) {
        this.tecladoenPantalla = true;
      } else if (respuesta[0].CFG_VALOR1 === 0) {
        this.tecladoenPantalla = false;
      }
    });
    this.initConf.getDecimales('frmVEN_ENCFAC').subscribe((respuesta) => {
      this.decimalesConfigVenENCFAC = respuesta;
      this.calc.setDecimalesConfig(this.decimalesConfigVenENCFAC);
    });
    this.calendar();
    this.date1 = new Date();
    this.chkfactura = true;
    this.titulo2();
    this.cliente = '';
    this.vendedor = '';
    this.firsttime = true;
    this.iva[this.instanciaPrin - 1] = 0;
    this.subtotal[this.instanciaPrin - 1] = 0;
    this.total[this.instanciaPrin - 1] = 0;
    this.calc.setFocus(0, this.instanciaPrin);
    this.pagada = false;
    this.efectivo = '';
    this.numero = null;
    this.displayDialogTeclado = false;
    this.numeroCta = null;
    this.numeroImporte = null;
    this.numRetencion = null;
    this.numBase = null;
    this.productoBuscar = null;
    this.dscValor = null;
    this.dscPorcentaje = null;
    this.busquedaFac = null;
    this.valorcedulaI = false;
    this.valorRucI = false;
    this.fechaInput = false;
    this.facturaserv.obtenerPeriodoContable().subscribe((periodo: any[]) => {
      for (const pe of periodo) {
        this.fechainicial = pe.PER_FECHAINICIAL;
        this.fechafinal = pe.PER_FECHAFINAL;
      }
    });
    this.codigobloqueado = false;

    this.cmbtipoideaux = [{ codigo: 'R', name: 'R - (Ruc)' },
    { codigo: 'C', name: 'C - (Cédula)' },
    { codigo: 'P', name: 'P - (Pasaporte)' },
    { codigo: 'PP', name: 'PP - (Placa)' }];
  }

  ngAfterViewInit(): void {
    this.cd.detach();
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  ngDoCheck(): void {
    this.iva = this.calc.entregarIVA();
    this.subtotal = this.calc.entregarSubtotal();
    this.total = this.calc.entregarTotal();
  }

  ngOnDestroy(): void {
    this.initCom.componenteEliminado();
    this.iva[this.instanciaPrin - 1] = 0;
    this.subtotal[this.instanciaPrin - 1] = 0;
    this.total[this.instanciaPrin - 1] = 0;
    this.cd.detach();
  }

  reiniciarFac(codCli) {
    this.facSeleccionada = {};
    let codigocliente = '';
    let nombrecliente = '';
    this.facturaserv.erCliCodigoCliNombre().subscribe(er => {
        if (er !== null) {
          if (er[0] !== undefined) {
            codigocliente = er[0].CLI_CODIGO;
            nombrecliente = er[0].CLI_NOMBRE;
          }
        }
        if (codCli === codigocliente) {
          this.codCli = codCli;
          this.nombrecliente = nombrecliente;
        } else {
          this.nuevo();
        }
        this.titulo2();
      });
  }

  iniciarBarra() {
    this.seleccionadaBarra = true;
  }

  iniciarTeclado(valor: string) {
    if (valor === 'cliente' || valor === 'vendedor' || valor === 'newfac'
      || valor === 'buscarFac' || valor === 'cliCod' || valor === 'cliNom'
      || valor === 'venNom' || valor === 'venCod' || valor === 'cedula' || valor === 'nombreCli' || valor === 'razonanular') {
      this.keyboard = new Keyboard({
        onChange: input => this.onChange(input, valor),
        onKeyPress: button => this.onKeyPress(button, valor)
      });
      this.alturateclado = '40vh';
      this.anchoteclado = '100vw';
      this.posicionBarra = 'bottom';
    } else if (valor === 'producto' || valor === 'dirCli' || valor === 'telCli' || valor === 'mailCli') {
      this.keyboard = new Keyboard({
        onChange: input => this.onChange(input, valor),
        onKeyPress: button => this.onKeyPress(button, valor)
      });
      this.alturateclado = '40vh';
      this.anchoteclado = '100vw';
      this.posicionBarra = 'top';
    } else if (valor === 'numericoEfectivo') {
      this.keyboard = new Keyboard({
        onChange: input => this.onChange(input, valor),
        onKeyPress: button => this.onKeyPress(button, valor),
        layout: {
          default: ['1 2 3 +10', '4 5 6 +20', '7 8 9 +50', '. 0 C +100', 'borrar {enter}']
        },
        theme: 'hg-theme-default hg-layout-numeric numeric-theme'
      });
      this.alturateclado = '40vh';
      this.anchoteclado = '20vw';
      this.posicionBarra = 'right';
    } else {
      this.keyboard = new Keyboard({
        onChange: input => this.onChange(input, valor),
        onKeyPress: button => this.onKeyPress(button, valor),
        layout: {
          default: ['1 2 3', '4 5 6', '7 8 9', '. 0 C', '{bksp} {enter}']
        },
        theme: 'hg-theme-default hg-layout-numeric numeric-theme'
      });
      this.alturateclado = '40vh';
      this.anchoteclado = '20vw';
      this.posicionBarra = 'right';
    }
  }

  manejarShift() {
    const currentLayout = this.keyboard.options.layoutName;
    const shiftToggle = currentLayout === 'default' ? 'shift' : 'default';
    this.keyboard.setOptions({
      layoutName: shiftToggle,
    });
  }


  onChange(input, valor) {
    if (valor === 'cliente') {
      this.codCli = input;
    } else if (valor === 'vendedor') {
      this.codVen = input;
    } else if (valor === 'newfac') {
      this.clavevendedor = input;
    } else if (valor === 'numericoEfectivo') {
      // this.efectivo = Number(input);
    } else if (valor === 'numericoNum') {
      this.numero = input;
    } else if (valor === 'numericoCta') {
      this.numeroCta = input;
    } else if (valor === 'numericoNumRet') {
      this.numRetencion = input;
    } else if (valor === 'numericoBase') {
      this.numBase = input;
    } else if (valor === 'numericoImporte') {
      this.numeroImporte = input;
    } else if (valor === 'producto') {
      this.productoBuscar = input;
    } else if (valor === 'dscValor') {
      this.dscValor = input;
    } else if (valor === 'dscPorcentaje') {
      this.dscPorcentaje = input;
    } else if (valor === 'buscarFac') {
      this.busquedaFac = input;
    } else if (valor === 'cliCod') {
      this.auxcodigocliente = input;
    } else if (valor === 'cliNom') {
      this.auxnombrecliente = input;
    } else if (valor === 'venCod') {
      this.auxcodigovendedor = input;
    } else if (valor === 'venNom') {
      this.auxnombrevendedor = input;
    } else if (valor === 'cedula') {
      this.auxcodigocliente = input;
    } else if (valor === 'nombreCli') {
      this.auxnombrecliente = input;
    } else if (valor === 'dirCli') {
      this.auxdireccioncliente = input;
    } else if (valor === 'telCli') {
      this.auxtelefonocliente = input;
    } else if (valor === 'mailCli') {
      this.auxcorreocliente = input;
    }
  }

  onKeyPress(button, valor) {
    if (button === '{enter}' && valor === 'cliente') {
      this.manejarEnter();
      this.onSelectCliente2(this.codCli);
    } else if (button === '{enter}' && valor === 'vendedor') {
      this.manejarEnter();
      this.onSelectvendedor2(this.codVen);
    } else if (button === '{enter}' && valor === 'newfac') {
      this.manejarEnter();
      this.seleccionarClaveVendedor();
    } else if (button === 'C' && valor === 'numericoEfectivo') {
      this.efectivo = '';
    } else if (button === '1' && valor === 'numericoEfectivo') {
      this.efectivo = this.efectivo + '1';
    } else if (button === '2' && valor === 'numericoEfectivo') {
      this.efectivo = this.efectivo + '2';
    } else if (button === '3' && valor === 'numericoEfectivo') {
      this.efectivo = this.efectivo + '3';
    } else if (button === '4' && valor === 'numericoEfectivo') {
      this.efectivo = this.efectivo + '4';
    } else if (button === '5' && valor === 'numericoEfectivo') {
      this.efectivo = this.efectivo + '5';
    } else if (button === '6' && valor === 'numericoEfectivo') {
      this.efectivo = this.efectivo + '6';
    } else if (button === '7' && valor === 'numericoEfectivo') {
      this.efectivo = this.efectivo + '7';
    } else if (button === '8' && valor === 'numericoEfectivo') {
      this.efectivo = this.efectivo + '8';
    } else if (button === '9' && valor === 'numericoEfectivo') {
      this.efectivo = this.efectivo + '9';
    } else if (button === '0' && valor === 'numericoEfectivo') {
      this.efectivo = this.efectivo + '0';
    } else if (button === '.' && valor === 'numericoEfectivo') {
      this.efectivo = this.efectivo + '.';
    } else if (button === '{enter}' && valor === 'numericoEfectivo') {
      this.efectivo = 9999999999999;
    } else if (button === 'borrar' && valor === 'numericoEfectivo') {
      this.efectivo = this.efectivo.toString().substr(0, this.efectivo.toString().length - 1);
    } else if (button === '+10' && valor === 'numericoEfectivo') {
      this.efectivo = Number(this.efectivo) + Number(10);
    } else if (button === '+20' && valor === 'numericoEfectivo') {
      this.efectivo = Number(this.efectivo) + Number(20);
    } else if (button === '+50' && valor === 'numericoEfectivo') {
      this.efectivo = Number(this.efectivo) + Number(50);
    } else if (button === '+100' && valor === 'numericoEfectivo') {
      this.efectivo = Number(this.efectivo) + Number(100);
    } else if (button === '{enter}' && valor === 'numericoNum') {
      this.numero = 9999999999999;
    } else if (button === '{enter}' && valor === 'numericoCta') {
      this.numeroCta = 9999999999999;
    } else if (button === '{enter}' && valor === 'numericoNumRet') {
      this.numRetencion = 9999999999999;
    } else if (button === '{enter}' && valor === 'numericoBase') {
      this.numBase = 9999999999999;
    } else if (button === '{enter}' && valor === 'numericoImporte') {
      this.numeroImporte = 9999999999999;
    } else if (button === '{enter}' && valor === 'producto') {
      this.productoBuscar = 9999999999999;
    } else if (button === '{enter}' && valor === 'dscValor') {
      this.dscValor = 9999999999999;
    } else if (button === '{enter}' && valor === 'dscPorcentaje') {
      this.dscPorcentaje = 9999999999999;
    } else if (button === '{enter}' && valor === 'buscarFac') {
      this.manejarEnter();
    } else if (button === '{enter}' && valor === 'cliCod') {
      this.manejarEnter();
    } else if (button === '{enter}' && valor === 'cliNom') {
      this.manejarEnter();
    } else if (button === '{enter}' && valor === 'venCod') {
      this.manejarEnter();
    } else if (button === '{enter}' && valor === 'venNom') {
      this.manejarEnter();
    } else if (button === '{enter}' && valor === 'cedula') {
      this.manejarEnter();
    } else if (button === '{enter}' && valor === 'nombreCli') {
      this.manejarEnter();
    } else if (button === '{enter}' && valor === 'dirCli') {
      this.manejarEnter();
    } else if (button === '{enter}' && valor === 'telCli') {
      this.manejarEnter();
    } else if (button === '{enter}' && valor === 'mailCli') {
      this.manejarEnter();
    } else if (button === '{shift}' || button === '{lock}') {
      this.manejarShift();
    }
  }


  manejarEnter() {
    this.displayDialogTeclado = false;
    this.keyboard = null;
    this.desenfocar = false;
  }

  barraSelected() {
    this.seleccionadaBarra = false;
  }

  onInputChange(event: any) {
    if (this.tecladoenPantalla === true) {
      this.keyboard.setInput(event.target.value);
    } else {
      return;
    }
  }

  enviarProducto(prod: Maearticulo) {
    this.calc.llenarFac(prod, this.auxiliar, this.instanciaPrin);
    this.producto = prod;
    this.calc.vaciarCant();
  }

  enviarNum(num: number) {
    this.calc.calcValue(num);
  }

  cobrar(bol: boolean) {
    this.bolcobrar = bol;
    this.titulo2();
  }

  numfact(numerof: string) {
    this.facSeleccionada.ENCFAC_NUMERO = numerof;
    this.facSeleccionada.ENCFAC_SECACTUAL = numerof.substr(numerof.length - 9, numerof.length);
    this.facturaserv.getAutorizacion(numerof).subscribe((res2) => {
      for (const i of res2) {
        this.facSeleccionada.ENCFAC_AUTORIZACION = i.ENCFAC_AUTORIZACION;
      }
    });
    this.titulo2();

  }

  estado(estadoout: string) {
    this.asientoNum = estadoout;
    this.facSeleccionada.ENCFAC_ESTADO = estadoout;
  }

  asiento(asiento: string) {
    this.facSeleccionada.ASI_NRO = asiento;
  }

  facPagada(pagada: boolean) {
    this.pagada = pagada;
    if (this.pagada === true) {
      this.fechaInput = true;
      this.inputsIniciales = true;
    } else if (this.pagada === false) {
      this.inputsIniciales = false;
      this.fechaInput = true;
    }
    // this.darFocus
  }

  clnpago(reversar: boolean) {
    if (reversar === true) {
      this.trncobro = [];
      this.trnretencion = [];
      this.trnpago = [];
    }
  }

  calendar() {
    this.es = {
      firstDayOfWeek: 1,
      dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
      dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
      dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
      monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo',
        'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
      monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
      today: 'Hoy',
      clear: 'Borrar'
    };

    this.tr = {
      firstDayOfWeek: 1
    };

    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const prevMonth = (month === 0) ? 11 : month - 1;
    const prevYear = (prevMonth === 11) ? year - 1 : year;
    const nextMonth = (month === 11) ? 0 : month + 1;
    const nextYear = (nextMonth === 0) ? year + 1 : year;
    this.minDate = new Date();
    this.minDate.setMonth(prevMonth);
    this.minDate.setFullYear(prevYear);
    this.maxDate = new Date();
    this.maxDate.setMonth(nextMonth);
    this.maxDate.setFullYear(nextYear);
    const invalidDate = new Date();
    invalidDate.setDate(today.getDate() + 1);
    this.invalidDates = [invalidDate];
  }

  showDialogCliente() {
    this.calc.editFocus(0, this.instanciaPrin);
    this.maecliente = {};
    this.cleanauxscliente();
    this.autodialog = true;
    this.displayDialog = true;
    this.displayDialogVendedor = false;
  }

  showDialogVendedor() {
    this.calc.editFocus(0, this.instanciaPrin);
    this.maevendedor = {};
    this.cleanauxsvendedor();
    this.autodialog = true;
    this.displayDialogVendedor = true;
    this.displayDialog = false;
  }

  showDialogFactura() {
    this.calc.editFocus(0, this.instanciaPrin);
    if (this.facSeleccionada.ENCFAC_SECACTUAL !== (undefined && null)) {
      if (this.facSeleccionada.ASI_NRO === undefined || this.facSeleccionada.ASI_NRO === null || this.facSeleccionada.ASI_NRO === '') {
        if (this.facSeleccionada.ENCFAC_ESTADO === undefined || this.facSeleccionada.ENCFAC_ESTADO === 'PENDIENTE') {
          this.messageService.add({
            key: 'principal',
            severity: 'error',
            summary: 'Información',
            detail: 'La factura no tiene una forma de pago'
          });
          return;
        }
      }
    }

    this.displayDialogBuscarFacturas = true;
    this.displayDialog = false;

    this.facturaserv.getFacturas().subscribe((respuesta: any[]) => {
      this.facturas = respuesta;
      this.facturas.map((fac) => {
        fac.ENCFAC_FECHAEMISION = this.datePipe.transform(fac.ENCFAC_FECHAEMISION.toLocaleString().substr(0, 10),
          'dd/MM/yyyy');
      });

    });
  }

  anular() {
    if (this.facSeleccionada.ENCFAC_ESTADO === 'PENDIENTE') {
      this.displayDialogRazonAnular = true;
    } else {
      this.messageService.add({
        key: 'principal',
        severity: 'error',
        summary: 'Información',
        detail: 'No es posible anular la factura'
      });
    }
  }

  anularfac() {
    if (this.razonanular !== '' && this.razonanular !== null && this.razonanular !== undefined) {
      this.facturaserv.anularFactura(this.facSeleccionada.ENCFAC_NUMERO, this.razonanular).subscribe(() => {
        this.facSeleccionada.ENCFAC_ESTADO = 'ANULADA';
        this.messageService.add({
          key: 'principal',
          severity: 'success',
          summary: 'Información',
          detail: 'Factura Anulada'
        });
        this.displayDialogRazonAnular = false;
      });
      if (this.facSeleccionada.ASI_NRO !== null) {
        this.facturaserv.anularAsiento(this.facSeleccionada.ASI_NRO).subscribe(() => {
          this.messageService.add({
            key: 'principal',
            severity: 'success',
            summary: 'Información',
            detail: 'Asiento Contable Anulado'
          });
        });
      }
    } else {
      this.messageService.add({
        key: 'principal',
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese la razón'
      });
    }
  }

  nuevo() {
    this.displayDialog = false;
    this.displayDialogVendedor = false;
    this.displayDialogBuscarFacturas = false;
    this.displayDialogClave = false;
    this.displayDialogTeclado = false;
    this.displayDialogNewCli = false;
    this.displayDialogRazonAnular = false;

    if (this.facSeleccionada.ENCFAC_SECACTUAL !== (undefined && null)) {
      if (this.facSeleccionada.ASI_NRO === undefined || this.facSeleccionada.ASI_NRO === null || this.facSeleccionada.ASI_NRO === '') {
        if (this.facSeleccionada.ENCFAC_ESTADO === undefined || this.facSeleccionada.ENCFAC_ESTADO === 'PENDIENTE') {
          this.messageService.add({
            key: 'principal',
            severity: 'error',
            summary: 'Información',
            detail: 'La factura no tiene una forma de pago'
          });
          return;
        }
      }
    }

    if (this.firsttime === true) {
      this.maevendedor.VEN_CODIGO = null;
      this.nombrevendedor = null;
      this.displayDialogClave = true;
      this.firsttime = false;
    }

    this.new = false;
    this.facSeleccionada = {};
    this.date1 = new Date();
    this.chkfactura = true;
    this.maecliente.CLI_CODIGO = null;
    this.nombrecliente = null;
    this.maevendedor.VEN_CODIGO = this.vendedorinicial;
    this.codVen = this.vendedorinicial;
    this.nombrevendedor = this.vendedornombreini;
    this.maevendedor.VEN_NOMBRE = this.vendedornombreini;
    this.nombrevendedor = this.vendedornombreini;
    this.facturaserv.erCliCodigoCliNombre().subscribe(er => {
        if (er !== null) {
          if (er[0] !== undefined) {
            this.codCli = er[0].CLI_CODIGO;
            this.nombrecliente = er[0].CLI_NOMBRE;
          }
        }
        this.maecliente.CLI_CODIGO = this.codCli;
        this.maecliente.CLI_NOMBRE = this.nombrecliente;
        this.trncobro = [];
        this.trnpago = [];
        this.trnretencion = [];
        this.bolcobrar = false;
        this.calc.limpiar(this.instanciaPrin);
        this.titulo2();
        this.pagada = false;
        this.fechaInput = false;
        this.inputsIniciales = false;
        this.auxiliar = '0';
      });
  }

  teclado(valor: string) {
    this.calc.editFocus(0, this.instanciaPrin);
    this.desenfocar = true;
    if (this.tecladoenPantalla === true) {
      if (valor === 'cliente') {
        this.codCli = '';
        this.iniciarTeclado(valor);
        this.displayDialogTeclado = true;
      } else if (valor === 'vendedor') {
        this.codVen = '';
        this.iniciarTeclado(valor);
        this.displayDialogTeclado = true;
      } else if (valor === 'newfac') {
        this.iniciarTeclado(valor);
        this.displayDialogTeclado = true;
      } else if (valor === 'cerrar') {
        this.manejarEnter();
      } else if (valor === 'razonanular') {
        this.iniciarTeclado(valor);
      } else {
        this.iniciarTeclado(valor);
        if (valor === 'numericoEfectivo') {
          this.efectivo = '';
        }
        if (valor === 'numericoImporte') {
          this.numeroImporte = '';
        }
        if (valor === 'numericoBase') {
          this.numBase = '';
        }
        this.displayDialogTeclado = true;
      }
    } else {
      return;
    }
  }


  seleccionarFactura() {
    let decPrecio;
    let decDescuento;
    document.getElementById('divPrincipal').style.pointerEvents = 'all';
    document.getElementById('divPrincipal').style.opacity = '1';
    for (const conf of this.decimalesConfigVenENCFAC) {
      if (conf.NUMDEC_CAMPO === 'PRECIO') {
        decPrecio = conf.NUMDEC_NUMDEC;
      } else if (conf.NUMDEC_CAMPO === 'DESCUENTO') {
        decDescuento = conf.NUMDEC_NUMDEC;
      }
    }
    this.trncobro = [];
    this.trnpago = [];
    this.trnretencion = [];
    this.new = true;
    this.bolcobrar = false;
    this.calc.limpiar(this.instanciaPrin);
    this.auxiliar = '1';
    this.facSeleccionada = this.calc.recibirFac();

    if (this.facSeleccionada.ENCFAC_ESTADO === 'P') {
      this.facSeleccionada.ENCFAC_ESTADO = 'PENDIENTE';
      this.pagada = false;
      this.calc.editFocus(1, this.instanciaPrin);
      this.facturaserv.getTrnResumen(this.facSeleccionada.ENCFAC_NUMERO).subscribe((respuresu: any[]) => {
        if (respuresu.length > 1) {
          this.pagada = true;
        }
      });
      this.inputsIniciales = false;
    } else if (this.facSeleccionada.ENCFAC_ESTADO === 'G') {
      this.calc.editFocus(0, this.instanciaPrin);
      this.facSeleccionada.ENCFAC_ESTADO = 'PAGADA';
      this.pagada = true;
      this.inputsIniciales = true;
      this.fechaInput = true;
    } else if (this.facSeleccionada.ENCFAC_ESTADO === 'A') {
      this.facSeleccionada.ENCFAC_ESTADO = 'ANULADA';
      this.pagada = true;
      this.inputsIniciales = true;
      this.fechaInput = true;
    }

    this.date1 = new Date(parseInt(this.facSeleccionada.ENCFAC_FECHAEMISION.substr(6, 4)),
      parseInt(this.facSeleccionada.ENCFAC_FECHAEMISION.substr(3, 2)),
      parseInt(this.facSeleccionada.ENCFAC_FECHAEMISION.substr(0, 2)));

    this.chkfactura = this.facSeleccionada.ENCFAC_NOTADEVENTA !== 'S';

    this.codCli = this.facSeleccionada.CLI_CODIGO;
    this.nombrecliente = this.facSeleccionada.CLI_NOMBRE;
    this.maecliente.CLI_CODIGO = this.facSeleccionada.CLI_CODIGO;
    this.maecliente.CLI_NOMBRE = this.facSeleccionada.CLI_NOMBRE;
    this.maevendedor.VEN_CODIGO = this.facSeleccionada.VEN_CODIGO;
    this.codVen = this.facSeleccionada.VEN_CODIGO;

    this.facturaserv.getMaeVendedor().subscribe((respuesta: any[]) => {
      for (const d of respuesta) {
        if (d.VEN_CODIGO === this.codVen) {
          this.nombrevendedor = d.VEN_NOMBRE;
          this.maevendedor.BOD_CODIGO = d.BOD_CODIGO;
          this.maevendedor.CAJ_CODIGO = d.CAJ_CODIGO;
        }
      }
    });

    this.facturaserv.getDetalleFactura(this.facSeleccionada.ENCFAC_NUMERO).subscribe((respuesta: any[]) => {
      let i = 1;
      this.detfactura = respuesta;
      for (const detfactura of this.detfactura) {
        const lstarticulos: Maearticulo = {};
        lstarticulos.ART_CODIGO = detfactura.DETFAC_CODIGO;
        lstarticulos.ARTPRE_PRECIO = detfactura.DETFAC_PRECIO.toFixed(decPrecio);
        lstarticulos.ART_NOMBRE = detfactura.DETFAC_DESCRIPCION;
        lstarticulos.ART_NOMBREC = detfactura.DETFAC_DESCRIPCION;
        lstarticulos.DESCUENTO = detfactura.DETFAC_DESCUENTO.toFixed(decDescuento);
        lstarticulos.CANTIDAD = detfactura.DETFAC_CANTIDAD;
        lstarticulos.ART_UNIDADVENTA = detfactura.DETFAC_UNIDAD;
        if (detfactura.DETFAC_TRIBIVA === 'S') {
          lstarticulos.ART_TRIBUTAIVA = 'S';
        } else {
          lstarticulos.ART_TRIBUTAIVA = 'N';
        }
        lstarticulos.DETFAC_LINEA3 = 0;
        // console.log(lstarticulos);
        this.calc.enviarDet(lstarticulos, i, this.instanciaPrin);
        i++;
      }
      if (this.facSeleccionada.ENCFAC_VALORDES > 0) {
        this.calc.descGlobalV2(this.facSeleccionada.ENCFAC_VALORDES,
          this.facSeleccionada.ENCFAC_PORCEDES, this.instanciaPrin, this.calc.recibirFacComun()[this.instanciaPrin - 1]);
      }
    });

    this.facturaserv.getTrnDocumento(this.facSeleccionada.ENCFAC_NUMERO).subscribe((respuesta: any[]) => {
      if (respuesta.length !== 0) {
        this.facturaserv.getTrnCobro(respuesta[0].TRNCOBRO_NRODOC).subscribe((respuesta2: any[]) => {
          this.trncobro = respuesta2;
        });
        this.facturaserv.getTrnRetencion(respuesta[0].TRNCOBRO_NRODOC).subscribe((respuesta2: any[]) => {
          this.trnretencion = respuesta2;
        });
        this.facturaserv.getTrnPago(respuesta[0].TRNCOBRO_NRODOC).subscribe((respuesta2: any[]) => {
          this.trnpago = respuesta2;
        });
      }
    });

    this.facturaserv.getInfoCredito(this.maecliente.CLI_CODIGO).subscribe((respuesta2: any[]) => {
      for (const i of respuesta2) {
        this.maecliente.CLI_LIMCREDIT = i.CLI_LIMCREDIT;
        this.maecliente.CLI_SALDO = i.SALDO;
        this.maecliente.CLI_DIACREDIT = i.CLI_DIACREDIT;
      }
    });

    this.displayDialogBuscarFacturas = false;
    this.titulo2();
  }

  selectedFac(fac: VenEncfac, index: number) {
    this.calc.enviarFac(fac, index);
  }

  public onSelectCliente2(cat): void {
    this.manejarEnter();
    this.facturaserv.getClienteCodigo(cat).subscribe((respuesta: any[]) => {
      if (respuesta.length === 0) {
        this.confirm.confirm({
          message: 'Desea agregar al cliente como cliente nuevo',
          header: 'El cliente no esta registrado',
          icon: 'pi pi-exclamation-triangle',
          key: 'principal',
          accept: () => {
            this.calc.editFocus(0, this.instanciaPrin);
            this.displayDialogNewCli = true;
            this.auxcodigocliente = this.codCli;
          },
          reject: () => {
          }
        });
      } else {
        this.maecliente = respuesta[0];
        this.nombrecliente = this.maecliente.CLI_NOMBRE;
      }
    });

  }


  public onSelectvendedor2(cat): void {
    this.manejarEnter();
    this.facturaserv.getVendedorCodigo(cat).subscribe((respuesta: any[]) => {
      if (respuesta.length === 0) {
        this.messageService.add({
          key: 'principal',
          severity: 'error',
          summary: 'Información',
          detail: 'El vendedor no existe'
        });
      } else {
        this.maevendedor = respuesta[0];
        this.nombrevendedor = this.maevendedor.VEN_NOMBRE;
      }
    });
  }


  /*
  AUTOCOMPLETE
  */


  filtervendedorSingle(event) {
    const query = event.query;
    this.facturaserv.getMaeVendedor().subscribe((respuesta: any[]) => {
      this.filteredVendedorSingle = this.filtervendedor(query, respuesta);
    });
  }


  filtervendedor(query, vendedor: any[]): any[] {
    const filtered: any[] = [];
    for (let i = 0; i < vendedor.length; i++) {
      const ven = vendedor[i];
      if (ven.VEN_CODIGO.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(ven);
      }
    }
    return filtered;
  }

  filterClienteSingle(event) {
    const query = event.query;
    this.facturaserv.getMaeCliente().subscribe((respuesta: any[]) => {
      this.filteredClientesSingle = this.filterCliente(query, respuesta);
    });
  }

  filterCliente(query, clientes: any[]): any[] {
    const filtered: any[] = [];
    for (let i = 0; i < clientes.length; i++) {
      const cli = clientes[i];
      if (cli.CLI_CODIGO.indexOf(query) === 0) {
        filtered.push(cli.CLI_CODIGO);
        this.clisel.push(cli);
      }
    }
    return filtered;
  }


  /*
  NAMES
  */

  filterNamevendedorsSingle(event) {
    const query = event.query;
    this.facturaserv.getMaeVendedor().subscribe((respuesta: any[]) => {
      this.filteredNameVendedorSingle = this.filterNamevendedor(query, respuesta);
    });
  }

  filterNameClientesSingle(event) {
    const query = event.query;
    this.facturaserv.getMaeCliente().subscribe((respuesta: any[]) => {
      this.filteredNameClientesSingle = this.filterNameCliente(query, respuesta);
    });
  }

  filterNamevendedor(query, vendedores: any[]): any[] {
    const filtered: any[] = [];
    for (const i of vendedores) {
      const ven = vendedores[i];
      if (ven.VEN_NOMBRE.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(ven);
      }
    }
    return filtered;
  }

  filterNameCliente(query, names: any[]): any[] {
    const filtered: any[] = [];
    for (let i = 0; i < names.length; i++) {
      const ven = names[i];
      if (ven.CLI_NOMBRE.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(ven.CLI_NOMBRE);
        this.clisel.push(ven);
      }
    }
    return filtered;
  }


  public onSelectvendedor(ven): void {
    this.auxcodigovendedor = ven.VEN_CODIGO;
    this.auxnombrevendedor = ven.VEN_NOMBRE;
    this.auxcajavendedor = ven.CAJ_CODIGO;
    this.auxbodegavendedor = ven.BOD_CODIGO;
    this.autodialog = false;
  }

  public onSelectNameVen(ven): void {
    this.auxcodigovendedor = ven.VEN_CODIGO;
    this.auxnombrevendedor = ven.VEN_NOMBRE;
    this.auxcajavendedor = ven.CAJ_CODIGO;
    this.auxbodegavendedor = ven.BOD_CODIGO;
    this.autodialog = false;
  }

  public onSelectCliente(cat): void {
    this.teclado('cerrar');
    for (const i of this.clisel) {
      if (i.CLI_CODIGO === cat) {
        this.auxcodigocliente = i.CLI_CODIGO;
        this.auxnombrecliente = i.CLI_NOMBRE;
        this.auxruccliente = i.CLI_RUCIDE;
        this.auxdireccioncliente = i.CLI_DIRECCION1;
        this.auxtelefonocliente = i.CLI_TELEFONO1;
        this.auxcorreocliente = i.CLI_CORREO;
      }
    }
    this.autodialog = false;
  }

  public onSelectNameCli(cat): void {
    this.teclado('cerrar');
    for (const i of this.clisel) {
      if (i.CLI_NOMBRE === cat) {
        this.auxcodigocliente = i.CLI_CODIGO;
        this.auxnombrecliente = i.CLI_NOMBRE;
        this.auxruccliente = i.CLI_RUCIDE;
        this.auxdireccioncliente = i.CLI_DIRECCION1;
        this.auxtelefonocliente = i.CLI_TELEFONO1;
        this.auxcorreocliente = i.CLI_CORREO;
      }
    }
    this.autodialog = false;

  }

  seleccionarVendedor() {
    this.displayDialogVendedor = false;
    this.calc.editFocus(1, this.instanciaPrin);
    this.maevendedor.VEN_CODIGO = this.auxcodigovendedor;
    this.maevendedor.VEN_NOMBRE = this.auxnombrevendedor;
    this.maevendedor.CAJ_CODIGO = this.auxcajavendedor;
    this.maevendedor.BOD_CODIGO = this.auxbodegavendedor;
    this.codVen = this.maevendedor.VEN_CODIGO;
    this.nombrevendedor = this.maevendedor.VEN_NOMBRE;
    this.titulo2();
  }

  seleccionarCliente() {
    this.facturaserv.getClienteCodigo(this.auxcodigocliente).subscribe((respuesta: any[]) => {
      if (respuesta.length === 0) {
        this.facturaserv.erCliCodigoCliNombre().subscribe(er => {
            if (er !== null) {
              if (er[0] !== undefined) {
                this.codCli = er[0].CLI_CODIGO;
                this.nombrecliente = er[0].CLI_NOMBRE;
              }
            }
            this.maecliente.CLI_CODIGO = this.codCli;
            this.maecliente.CLI_NOMBRE = this.nombrecliente;
            this.messageService.add({
              key: 'principal',
              severity: 'error',
              summary: 'Información',
              detail: 'Cliente no existe. Guarde el cliente'
            });
            return;
          });
      } else {
        this.displayDialog = false;
        this.displayDialogNewCli = false;
        this.calc.editFocus(1, this.instanciaPrin);
        this.maecliente.CLI_CODIGO = this.auxcodigocliente;
        this.maecliente.CLI_NOMBRE = this.auxnombrecliente;
        this.maecliente.CLI_RUCIDE = this.auxruccliente;
        this.maecliente.CLI_DIRECCION1 = this.auxdireccioncliente;
        this.maecliente.CLI_TELEFONO1 = this.auxtelefonocliente;
        this.codCli = this.maecliente.CLI_CODIGO;
        this.nombrecliente = this.maecliente.CLI_NOMBRE;
        this.titulo2();
      }
    });


  }


  titulo2() {
    this.facturaserv.getUsuario().subscribe((respuesta: any[]) => {
      for (const res of respuesta) {
        if (this.maevendedor.VEN_CODIGO === undefined) {
          this.maevendedor.VEN_CODIGO = '';
        }
        this.datosiniciales = res.USUIDENTIFICACION + '/' + this.maevendedor.VEN_CODIGO;
        if (this.facSeleccionada.ENCFAC_SECACTUAL === undefined) {
          this.datos = 'Fecha:  ' + this.date1.toLocaleString().substr(0, 10);
        } else {
          this.datos = 'Fecha:  ' +
            this.date1.toLocaleString().substr(0, 10) +
            '      Factura: ' + this.facSeleccionada.ENCFAC_SECACTUAL;
        }
        this.infoCliente = '***' + this.titulo + ' ***' + this.datos + '***********' + this.datosiniciales;
      }
    });

  }


  seleccionarClaveVendedor() {
    this.manejarEnter();
    this.calc.editFocus(0, this.instanciaPrin);
    this.facturaserv.getClaveVendedor(this.clavevendedor).subscribe((respuesta: any[]) => {
      if (respuesta.length > 0) {
        for (const res of respuesta) {
          document.getElementById('divPrincipal').style.pointerEvents = 'all';
          document.getElementById('divPrincipal').style.opacity = '1';
          this.vendedorinicial = res.VEN_CODIGO;
          this.vendedornombreini = res.VEN_NOMBRE;
          this.maevendedor.VEN_CODIGO = this.vendedorinicial;
          this.maevendedor.VEN_NOMBRE = this.vendedornombreini;
          this.maevendedor.BOD_CODIGO = res.BOD_CODIGO;
          this.maevendedor.CAJ_CODIGO = res.CAJ_CODIGO;
          this.codVen = this.vendedorinicial;
          this.nombrevendedor = this.vendedornombreini;
          this.facturaserv.erCliCodigoCliNombre().subscribe(er => {
              if (er !== null) {
                if (er[0] !== undefined) {
                  this.codCli = er[0].CLI_CODIGO;
                  this.nombrecliente = er[0].CLI_NOMBRE;
                }
              }
              this.facturaserv.getClienteCodigo(this.codCli).subscribe((respuesta2) => {
                this.maecliente = respuesta2[0];
              });
              this.titulo2();
              this.newven = true;
              this.displayDialogClave = false;
              this.inputsIniciales = false;
              this.calc.editFocus(1, this.instanciaPrin);
            });
        }
      } else {
        this.messageService.add({
          key: 'principal',
          severity: 'error',
          summary: 'Clave Incorrecta',
          detail: 'No existe vendedor'
        });
        this.calc.editFocus(0, this.instanciaPrin);
      }
    });

  }

  cerrarClave() {
    if (this.maevendedor.VEN_CODIGO === '') {
      this.firsttime = true;
    }
  }

  clean() {
    this.auxcodigocliente = '';
    this.auxnombrecliente = '';
    this.autodialog = true;
  }

  modificar() {
    if (this.auxcodigocliente !== '') {
      this.codigobloqueado = true;
      this.displayDialogNewCli = true;
    }
  }

  cleanauxsvendedor() {
    this.auxcodigovendedor = '';
    this.auxnombrevendedor = '';
    this.auxcajavendedor = '';
    this.auxbodegavendedor = '';
  }

  cleanauxscliente() {
    this.auxcodigovendedor = '';
    this.auxcodigocliente = '';
    this.auxnombrecliente = '';
    this.auxruccliente = '';
    this.auxdireccioncliente = '';
    this.auxtelefonocliente = '';
  }

  opcion(ide) {
    this.ide = ide.value.codigo;
    this.validar();
  }

  llenarcmbtipoide() {
    this.cmbtipoide = this.cmbtipoideaux;
  }

  public onSelectClienteDialog(cat): void {
    this.manejarEnter();
    this.facturaserv.getClienteCodigo(cat).subscribe((respuesta: any[]) => {
      if (respuesta.length === 0) {
        this.confirm.confirm({
          message: 'Desea agregar al cliente como cliente nuevo',
          header: 'El cliente no esta registrado',
          icon: 'pi pi-exclamation-triangle',
          key: 'principal',
          accept: () => {
            this.codigobloqueado = false;
            this.displayDialog = false;
            this.calc.editFocus(0, this.instanciaPrin);
            this.displayDialogNewCli = true;
          },
          reject: () => {
          }
        });
      } else {
        this.maecliente = respuesta[0];
        this.nombrecliente = this.maecliente.CLI_NOMBRE;
      }
    });
  }

  buscarcedula() {

    this.facturaserv.getCedula(this.auxcodigocliente).subscribe((respuesta: any) => {
      console.log(respuesta);
      this.auxnombrecliente = respuesta.nombre;
      this.auxruccliente = respuesta.identity;
      this.auxdireccioncliente = respuesta.direccion;
      this.auxtelefonocliente = respuesta.telefono;
      this.auxcorreocliente = '';
    });
  }

  save() {
    if (this.codigobloqueado === true) {
      console.log(this.auxcorreocliente);
      this.facturaserv.actualizarCliente(this.auxcodigocliente,
        this.auxnombrecliente,
        this.auxdireccioncliente,
        this.auxtelefonocliente,
        this.auxcorreocliente).subscribe((respuesta: any) => {
          if (respuesta.length === 0) {
            this.messageService.add({
              key: 'principal',
              severity: 'success',
              summary: 'Información',
              detail: 'Cliente actualizado exitosamente'
            });
          } else {
            this.messageService.add({
              key: 'principal',
              severity: 'error',
              summary: 'Información',
              detail: 'No se pudo actualizar el cliente'
            });
          }
        });
      return;
    }

    if (this.ide === '') {
      this.messageService.add({
        key: 'principal',
        severity: 'error',
        summary: 'Información Incorrecta',
        detail: 'Ingrese el tipo de identificación'
      });
      return;
    }

    this.validar();
    if (this.valorcedulaI === true || this.valorRucI === true) {
      this.messageService.add({
        key: 'principal',
        severity: 'error',
        summary: 'Información Incorrecta',
        detail: 'Uno de los campos ingresados es inválido'
      });
    } else {
      let grupo = '';
      let cuenta1 = '';
      let cuenta2 = '';
      this.facturaserv.datosultimocli().subscribe((periodo: any[]) => {
        for (const pe of periodo) {
          grupo = pe.GRU_CODIGO;
          cuenta1 = pe.CON_CODIGO1;
          cuenta2 = pe.CON_CODIGO2;
        }
        const fecha = new Date().toLocaleString('en-GB', { timeZone: 'UTC' }).substr(0, 10);
        this.facturaserv.insertarCliente(this.auxcodigocliente, grupo, this.codVen,
          this.auxnombrecliente, this.ide, this.auxdireccioncliente, this.auxtelefonocliente,
          this.auxcorreocliente, fecha, cuenta1, cuenta2).subscribe((respuesta: any) => {
            if (respuesta.length === 0) {
              this.messageService.add({
                key: 'principal',
                severity: 'success',
                summary: 'Información',
                detail: 'Cliente ingresado exitosamente'
              });
            } else {
              this.messageService.add({
                key: 'principal',
                severity: 'error',
                summary: 'Información',
                detail: 'No se pudo guardar el cliente'
              });
            }
          });
      });
    }
  }

  productobo() {
    this.calculadora.buscarProducto();
  }

  controlCobrar() {
    this.calculadora.regresarPago();
  }

  validar() {
    this.valorRucI = false;
    this.valorcedulaI = false;
    if (this.ide === 'C') {
      let total = 0;
      const longitud = this.auxcodigocliente.length;
      const longcheck = longitud - 1;

      if (this.auxcodigocliente !== '' && longitud <= 10) {
        for (let i = 0; i < longcheck; i++) {
          if (i % 2 === 0) {
            let aux = Number(this.auxcodigocliente.charAt(i)) * 2;
            if (aux > 9) {
              aux -= 9;
            }
            total += aux;
          } else {
            total += parseInt(this.auxcodigocliente.charAt(i)); // parseInt o concatenar� en lugar de sumar
          }
        }

        total = total % 10 ? 10 - total % 10 : 0;

        this.valorcedulaI = Number(this.auxcodigocliente.charAt(longitud - 1)) !== total;
      }
    } else if (this.ide === 'R') {
      const numero = this.auxcodigocliente;
      const long = numero.length;
      let valor;
      let acu = 0;
      if (numero === '') {
        this.valorRucI = true;
      } else {
        for (let i = 0; i < long; i++) {
          valor = numero.substr(i, i + 1);
          if (valor === 0 || 1 || 2 || 3 || 4 || 5 || 6 || 7
            || 8 || 9) {
            acu = acu + 1;
          }
        }
        if (acu === long) {
          while (numero.substr(10, 13) !== '001') {
            this.valorRucI = true;
            return;
          }
          while (Number(numero.substr(0, 2)) > 24) {
            this.valorRucI = true;
            return;
          }
          this.valorRucI = false;
          const porcion1 = numero.substr(2, 3);
          if (Number(porcion1) < 6) {
            this.valorRucI = false;
          } else {
            if (Number(porcion1) === 6) {
              this.valorRucI = false;
            } else {
              if (Number(porcion1) === 9) {
                this.valorRucI = false;
              }
            }
          }
        } else {
          this.valorRucI = true;
        }
      }
    }
  }

  salir() {
    this.init.tabs.splice(this.init.tabindex, 1);
  }
}
