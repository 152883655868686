<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="maeempresa"></p-toast>
<div class="p-grid" style="width: 99%">
    <div class="p-col-12">
        <app-invmaebarra [barraBotonesCRUD]="true" [barraBotonesDesplaz]="true" [barraBotones3]="true"
            [barraBotonesAsiCon]="true" [barraBotones5]="true" (signal)="manejarSenales($event)"></app-invmaebarra>
    </div>
</div>
<div class="divgrups">
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-3">
                </div>
                <div class="p-col-1">
                    NOMBRE:
                </div>
                <div class="p-col-3"><input id="empresanombre" type="text" pInputText [(ngModel)]="empresanombre"
                        (keydown.enter)="cambiarFoco($event)"></div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-3">
                </div>
                <div class="p-col-1">
                    ESQUEMA:
                </div>
                <div class="p-col-3"><input id="empresaesquema" type="text" pInputText [(ngModel)]="empresaesquema"
                        (keydown.enter)="cambiarFoco($event)"></div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-3">
                </div>
                <div class="p-col-1">
                    USUARIO:
                </div>
                <div class="p-col-3"><input id="empresausuario" type="text" pInputText [(ngModel)]="empresausuario"
                        (keydown.enter)="cambiarFoco($event)"></div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-3">
                </div>
                <div class="p-col-1">
                    PASSWORD:
                </div>
                <div class="p-col-3"><input id="empresapassword" type="text" pInputText [(ngModel)]="empresapassword"
                        (keydown.enter)="cambiarFoco($event)"></div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-2"></div>
                <div class="p-col-2">
                    <button class="sincss" pButton (click)="ingresarempresa()">Ingresar
                    </button>
                </div>
                <div class="p-col-2">
                    <button class="sincss" pButton (click)="actualizarempresa()">Actualizar
                    </button>
                </div>
                <div class="p-col-2">
                    <button class="sincss" pButton (click)="eliminarempresa()">Eliminar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>