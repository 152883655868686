import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilitariosService } from '../../advantage/advantageservices/utilitarios.service';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { ConfirmationService, MessageService } from 'primeng';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { SegComConexion, SegInfadicional, SegMaeCompania, SegMaeReferencia, SegMaeSucursal } from '../seginterfaces/segmaecompania';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { SegmaecompaniaService } from '../segservicios/segmaecompania.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { Usuario } from '../../usuario';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver/FileSaver';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';

@Component({
  selector: 'app-segmaecompania',
  templateUrl: './segmaecompania.component.html',
  styleUrls: ['../../advantage/styles/saciwebmaecom.css']
})
export class SegmaecompaniaComponent implements OnInit {

  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  // Botones
  btnGuardar: boolean;
  btnRegresar: boolean;

  // Tabla
  public frameworkComponents;
  rowStyle;
  defaultSegMaeSuc;
  defaultSegInfFact;
  defaultSegInfGuiaRem;
  defaultSegInfLiqCom;
  defaultSegComConexion;
  defaultSegInfdevfac; //GSRF
  rowSelection = 'multiple';
  largo: string;
  ancho = '100';


  imgBlob: any;

  documentName = '';
  documentSize = 0;
  documentbase64: any = '';
  documentType: any = '';

  indicador: any;
  index: any;
  // detSegComConexion: SegComConexion[] = [];


  btnNuevoGrid: boolean;
  btnGuardarGrid: boolean;
  btnBorrarGrid: boolean;
  btnCancelarGrid: boolean;

  // Variables

  chkbtnCONREGMIC = false;
  chkbtnCONRIMPE = false;
  chkbtnAgenteret = false;
  txtCorreoLiquidaciones = '';
  txtUbicacionXMLAut = '';
  txtLocalizacionLog = '';
  txtConformacion = '';
  txtConformacionsetEnabled = false;
  txtCorreoRetenciones = '';
  txtCorreoNotasDebito = '';
  txtCorreoGuiasRemision = '';
  txtCorreoNotasCredito = '';
  txtCorreoFacturas = '';
  txtUbicacionArchivos = '';
  cmbObligadoLLevarCont;
  strCmbObligadoCont: any;
  txtConEspecial = '';
  cmbTipoAmbiente;
  strCmbTipoAmbiente: any;
  txtLocalizacionArchivo = '';
  txtLocalizacionLogo2 = '';
  txtLocalizacionLogo1 = '';
  txtNumeroEst = '';
  txtNombreCorto = '';
  txtLocalFormulario = '';
  txtRucContador = '';
  txtContador = '';
  txtCICoordinador = '';
  txtCoordinador = '';
  txtNombreP = '';
  txtTipoId = 'r';
  txtPuntoVenta = '';
  txtEstablecimiento = '';
  txtRepreLegal = '';
  txtRuc = '';
  cboProvin;
  strCmbProvin: any;
  txtLocalReport = '';
  txtEmail = '';
  txtFax = '';
  txtTelf2 = '';
  txtTelf1 = '';
  txtDirecc = '';
  txtRazSoc = '';
  txtAutRet = '';
  txtCodigo = '';
  provincia;
  codProvincia = '';
  serieRet;
  establecimiento;
  puntoVenta;
  nroSerieRet;
  chkbtnFacElecAct = false;
  chkbtnProdAct = false;
  chkbtnSubsidio = false;
  chkbtnTransporte = false;
  strTipoAmbienteFE = '';
  intItemSelec = 0;
  txtCorreoAcceso = '';
  txtTelefonoAcceso = '';
  chkAceptarConexiones = false;
  chkUsarPingAcceso = false;
  disabledEmailPing = false;
  disabledTabConexion = true;
  visibleTabConexion = false;
  rutalogo = '';
  pestaniaconexiones = false;
  txtAlmacenamiento = '';
  txtresolucion = '';
  fechacaducidad = '';

    //CDPJ
    cmbregimen: any[];
    selectregimen: any;
    txtpwd1:string='';
    txtpwd2:string='';
    //CDPJ
  // solo para prueba cedula correcta
  rucCorrecto: number;
  tipo = '';
  strLogoCom = '';
  permisos: SegMaePermiso;

  displayAcciones: boolean;

  // Conexiones
  detalleConexionSel: SegComConexion = {};
  btnNuevoConexion;
  btnEliminarConexion;
  btnCancelarConexion;

  // Informacion Adicional

  detalleSucursalSel: SegMaeSucursal = {};
  detalleInfoFactSel: SegInfadicional = {};
  detalleInfoGuiaRemSel: SegInfadicional = {};
  detalleInfoLiqComSel: SegInfadicional = {};
  detalleInfodevfacSel: SegInfadicional = {};//GSRF

  // autorizacion
  claveAuth: string;
  autorizacionCodigo: string;
  autorizacionCodigoAux: string;
  bolAutorizacion: boolean;
  displayDialogAutorizacion: boolean;
  autorizacionCodigoRuc:string='AUTRUCID';//CDPJ
  displayDialogAutorizacionRuc: boolean;//CDPJ

  columnDefsSegMaeSucursal = [
    {
      headerName: 'Código', field: 'SUC_CODIGO', cellEditor: 'cellPrueba', width: 110, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          fecha: false,
          tamanocelda: 5,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Dirección', field: 'SUC_DESCRIPCION', cellEditor: 'cellPrueba', width: 450, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: true
        }
      }
    }
  ];

  columnDefsSegMaeSucursalNoTransportista = this.columnDefsSegMaeSucursal;

  columnDefsSegMaeSucursalTransportista = [
    {
      headerName: 'Código', field: 'SUC_CODIGO', cellEditor: 'cellPrueba', width: 110, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          fecha: false,
          tamanocelda: 5,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Dirección', field: 'SUC_DESCRIPCION', cellEditor: 'cellPrueba', width: 120, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'RUC', field: 'SUC_RUCTRANSPORTISTA', cellEditor: 'cellPrueba', width: 120, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Razon Social', field: 'SUC_RAZONSOCIALTRANSPOR', cellEditor: 'cellPrueba', width: 120, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Regimen', field: 'SUC_REGIMEN', cellEditor: 'cellPrueba', width: 120, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: true
        }
      }
    }
  ];

  columnDefsSegInfFact = [
    {
      headerName: 'Código', field: 'INFAD_CODIGO', cellEditor: 'cellPrueba', width: 110, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Información Adicional', field: 'INFAD_DESCRIPCION', cellEditor: 'cellPrueba', width: 450, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: true
        }
      }
    }
  ];

  columnDefsSegInfGuiRem = [
    {
      headerName: 'Código', field: 'INFAD_CODIGO', cellEditor: 'cellPrueba', width: 110, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Información Adicional', field: 'INFAD_DESCRIPCION', cellEditor: 'cellPrueba', width: 450, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: true
        }
      }
    }
  ];

  columnDefsSegInfLiqCom = [
    {
      headerName: 'Código', field: 'INFAD_CODIGO', cellEditor: 'cellPrueba', width: 110, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Información Adicional', field: 'INFAD_DESCRIPCION', cellEditor: 'cellPrueba', width: 450, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: true
        }
      }
    }
  ];

  columnDefsSegComConexion = [
    {
      headerName: 'IP Pública', field: 'IP_NUMERO', cellEditor: 'cellPrueba', width: 110, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          fecha: false,
          tamanocelda: 15,
          tienecalculos: true

        }
      }
    },
    {
      headerName: 'Descripción', field: 'IP_DESCRIPCION', cellEditor: 'cellPrueba', width: 300, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: true
        }
      }
    }
  ];
      //GSRF
      columnDefsSegInfdevfac  = [
        {
          headerName: 'Código', field: 'INFAD_CODIGO', cellEditor: 'cellPrueba', width: 110, editable: true,
          cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 0,
              fecha: false,
              tamanocelda: 100,
              tienecalculos: true
            }
          }
        },
        {
          headerName: 'Información Adicional', field: 'INFAD_DESCRIPCION', cellEditor: 'cellPrueba', width: 450, editable: true,
          cellEditorParams:
          {
            values: {
              mayusculas: false,
              numeros: true,
              alphabetic: true,
              saltoslinea: 1,
              newrow: false,
              numerodecimales: 0,
              obligatorio: false,
              fecha: false,
              tamanocelda: 100,
              tienecalculos: true
            }
          }
        }
      ];
      //GSRF
  constructor(private utilitariosService: UtilitariosService, public usuarioEsquema: UsuarioEsquema,
    private messageService: MessageService, private errorService: ErroresBaseDatosService,
    public segmaecompaniaService: SegmaecompaniaService, private init: NuevoComponentService,
    private permisosService: PermisosService, public usuario: Usuario, private confirmationService: ConfirmationService,
    private auditoriaGralService: AuditoriagralService, private datePipe: DatePipe,
    private confIniciales: ConfInicialesService) {
    this.agTable();
    this.segmaecompaniaService.detSucursalArray[this.indicador] = [];
    this.segmaecompaniaService.detInfoFactArray[this.indicador] = [];
    this.segmaecompaniaService.detInfoGuiaRemArray[this.indicador] = [];
    this.segmaecompaniaService.detInfoLiqComArray[this.indicador] = [];
    this.segmaecompaniaService.detConexionArray[this.indicador] = [];
    this.segmaecompaniaService.detInfoDevfacArray[this.indicador] = [];//GSRF

  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultSegMaeSuc = {
      editable: true,
      width: 500,
      objeto: 'segMaeSucCom',
      resizable: true
    };

    this.defaultSegInfFact = {
      editable: true,
      objeto: 'segInfAdiFactCom',
      resizable: true
    };

    this.defaultSegInfGuiaRem = {
      editable: true,
      objeto: 'segInfAdiGuiRemCom',
      resizable: true
    };

    this.defaultSegComConexion = {
      editable: true,
      width: 600,
      objeto: 'segComConexion',
      resizable: true
    };

    this.defaultSegInfLiqCom = {
      editable: true,
      objeto: 'segInfAdiLiqComCom',
      resizable: true
    };
    this.defaultSegInfdevfac = {
      editable: true,
      objeto: 'segInfAdidevfac',
      resizable: true
    };


  }

  async ngOnInit() {
    this.largo = '580';

    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;

    this.segmaecompaniaService.detSucursalArray[this.indicador] = [];
    this.segmaecompaniaService.detInfoFactArray[this.indicador] = [];
    this.segmaecompaniaService.detInfoGuiaRemArray[this.indicador] = [];
    this.segmaecompaniaService.detInfoLiqComArray[this.indicador] = [];
    this.segmaecompaniaService.detConexionArray[this.indicador] = [];
    this.segmaecompaniaService.detInfoDevfacArray[this.indicador] = [];//GSRF

    this.pestaniaconexiones = await this.utilitariosService.esmarcasaci();
    //CDPJ
    this.cmbregimen = [{ codigo: 'regemp', name: 'Regimen Rimpe - Emprendedor' },
    { codigo: 'regnp', name: 'Regimen Rimpe - Negocio Popular' },
    { codigo: '', name: '' }];
    //CDPJ

    this.btnGuardar = true;
    this.cmbObligadoLLevarCont = [];
    this.cmbObligadoLLevarCont.push({ name: '', codigo: 0 }, { name: 'SI', codigo: 1 }, { name: 'NO', codigo: 2 });
    this.tiposDeAmbiente();
    await this.mostrarDatos();
    await this.listarProvincias();
    await this.mostrarDatoSuc();
    this.permisosService.getListaPermisos('SEG', '1', '2200').subscribe((permiso) => {
      this.permisos = permiso[0];
    });

    this.btnCancelarConexion = false;
    this.btnNuevoConexion = false;
    this.btnEliminarConexion = false;
    this.listarConexion();
    this.mostrarDatoAdiFac();
    this.mostrarDatoAdiGre();
    this.mostrarDatoAdiLiq();
    this.mostrarDatoAdidevfac();//GSRF

    if (this.chkbtnTransporte === true) {
      this.columnDefsSegMaeSucursal = this.columnDefsSegMaeSucursalTransportista;
      this.aggrid.refreshColumnDefsIndice(this.indicador, this.defaultSegMaeSuc.objeto, this.columnDefsSegMaeSucursal);
      this.aggrid.refreshaggrid(this.segmaecompaniaService.detSucursalArray[this.indicador], this.defaultSegMaeSuc.objeto);
    }
  }

  manejarSenales(valor) {
    if (valor === 'Guardar') {
      this.actualizar();
    }

    if (valor === 'Cancelar') {
      this.cancelar();
    }

    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }

  actualizar() {
    if (this.permisos != null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERACTUALIZACION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }

    let intBanderaControl = 0;
    let intControlCedula = 0;
    const codigoCpia = this.txtCodigo;
    const ruc = this.txtRuc;
    const rucContador = this.txtRucContador;
    const ciCoordinador = this.txtCICoordinador + '001';
    const razonSoc = this.txtRazSoc;
    const nombreCorto = this.txtNombreCorto;
    const nroEstablecimiento = this.txtEstablecimiento;
    const nroPuntoVenta = this.txtPuntoVenta;
    let strTipoAmbiente = '';
    let strFacElecAct = '';
    let strProdAct = '';
    let strSubsidio = '';
    let strTransporte = '';
    let strConRegMic = '';
    let strAgenteret = '';
    let strConexion = '';
    let strPing = '';
    let strConRimpe = '';
    let strConRimpe_NP='';//CDPJ


    if (ruc.length === 0 || razonSoc.length === 0 || nombreCorto.length === 0) {
      this.mensajeAlerta('eror', 'Error', 'Campos requeridos.');
      return;
    }

    if (this.chkUsarPingAcceso === true) {
      if (String(this.txtCorreoAcceso).trim() === '' || this.txtTelefonoAcceso === undefined ||
        String(this.txtTelefonoAcceso).trim() === '' || this.txtTelefonoAcceso === undefined) {
        this.mensajeAlerta('info', 'PIN de Acceso', 'El correo y teléfono celular son obligatorios');
        return;
      }
    }

    this.btnGuardar = true;
    // btnEjecutar.setEnabled(false);

    if (nroEstablecimiento.length === 3 && nroPuntoVenta.length === 3) {
      this.nroSerieRet = nroEstablecimiento.concat(nroPuntoVenta);
    } else {
      this.mensajeAlerta('error', 'Error número de dígitos', 'No actualizó correctamente, no menos de 3 dígitos para Establecimiento o Punto de Venta');
      intBanderaControl = 1;
      // btnCancelar.setEnabled(true);
    }

    this.codProvincia = this.strCmbProvin.REF_CODIGO;

    try {
      if (this.txtTelf1.length === 0 || this.txtTelf2.length === 0) {
        this.txtTelf1 = '';
        this.txtTelf2 = '';
      } else if (this.txtTelf1.length < 9 || this.txtTelf2.length < 9) {
        this.mensajeAlerta('error', 'Error número de dígitos', 'No ingresó correctamente número de teléfono');
        intBanderaControl = 1;
        // btnCancelar.setEnabled(true);
      }

      if (this.txtAutRet.length < 1) {
        this.mensajeAlerta('error', 'Error número de dígitos', 'La autorización de la retención es incorrecta');
        intBanderaControl = 1;
        // btnCancelar.setEnabled(true);
      }

      const cedula = ruc.substring(0, 10);
      this.tipo = this.txtTipoId;
      if (this.tipo === 'c') {
        const cedCorrecta = this.ValidaCedula(cedula);

        if (cedCorrecta === 1) {
          this.mensajeAlerta('info', 'Validación Ruc', 'Cédula correcta');
          intBanderaControl = 0;
        } else {
          this.mensajeAlerta('info', 'Validación Ruc', 'Cédula Incorrecta');
          intBanderaControl = 1;
        }
      } else if (this.txtTipoId === 'r') {
        const rucCorrecto = this.ValidaRuc(ruc);

        if (rucCorrecto === 0) {
          this.mensajeAlerta('info', 'Validación Ruc', 'Ruc Incorrecto');
          intBanderaControl = 1;
          
        } else {
          this.rucCorrecto = 0;
        }
      }
      const rucContadorCorrecto = this.ValidaRuc(rucContador);
      if (rucContadorCorrecto === 0) {
        this.mensajeAlerta('info', 'Validación Ruc', 'Ruc del Contador Incorrecto');
        intBanderaControl = 1;
      }
      const ciCoordinadorCorrecto = this.ValidaRuc(ciCoordinador);
      if (ciCoordinadorCorrecto === 0) {
        this.mensajeAlerta('info', 'Validación IDE', 'IDE del Representante Incorrecto');
        intControlCedula = 1;
      }

      strConexion = '';
      if (this.chkAceptarConexiones === true) {
        strConexion = '1';
      }

      strPing = '';
      if (this.chkUsarPingAcceso === true) {
        strPing = '1';
      } else {
        this.txtCorreoAcceso = '';
        this.txtTelefonoAcceso = '';
      }

      if (this.chkUsarPingAcceso === true && (String(this.txtCorreoAcceso).trim() === '' || String(this.txtTelefonoAcceso).trim() === '')) {
        this.mensajeAlerta('info', 'PIN de Acceso', 'Ingrese el correo y el teléfono celular del PIN de acceso');
        return;
      }

      strTipoAmbiente = '';
      if (this.strCmbTipoAmbiente.name === 'Pruebas') {
        strTipoAmbiente = '1';
      }
      if (this.strCmbTipoAmbiente.name === 'Producción') {
        strTipoAmbiente = '2';
      }

      strFacElecAct = '';
      strProdAct = '';
      strSubsidio = '';
      strTransporte = '';

      if (this.chkbtnFacElecAct === true) {
        strFacElecAct = '1';
      }
      if (this.chkbtnProdAct === true) {
        strProdAct = '1';
      }
      if (this.chkbtnSubsidio === true) {
        strSubsidio = '1';
      }

      if (this.chkbtnTransporte === true) {
        strTransporte = '1';
      }

      if(intBanderaControl === 1){//CDPJ
        this.displayDialogAutorizacionRuc = true;//CDPJ
      }else{
        if (this.nroSerieRet !== null && this.rucCorrecto === 0 // && this.txtTelf1.length === 9 && this.txtTelf2.length === 9
          && this.txtAutRet.length > 1 && intBanderaControl === 0 && intControlCedula === 0) {
  
          if (this.strTipoAmbienteFE === '1' && strTipoAmbiente === '2') {   // si esta en producción
            this.confirmationService.confirm({
              message: '¿ Está seguro de cambiar el ambiente de pruebas a producción ?',
              header: 'Pregunta',
              icon: 'pi pi-exclamation-triangle',
              key: 'compania',
              acceptLabel: 'Si',
              rejectLabel: 'No',
              accept: () => {
                this.EliminarDocumentosPruebaFE();
                if (this.chkbtnCONREGMIC === true) {
                  strConRegMic = '1';
                }
                if (this.chkbtnAgenteret === true) {
                  strAgenteret = '1';
                }
                // if (this.chkbtnCONRIMPE === true) {
                //   strConRimpe = '1';
                // }
  
                 //CDPJ
              if(this.selectregimen.codigo === 'regemp'){
                strConRimpe='1';
                strConRimpe_NP='0';
               }else if( this.selectregimen.codigo === 'regnp'){
                strConRimpe='0';
                strConRimpe_NP='1';
               } else if( this.selectregimen.codigo === ''){
                strConRimpe='';
                strConRimpe_NP='';
               };
               //CDPJ
                this.actualizarCom(codigoCpia, ruc, razonSoc, this.txtDirecc, this.txtTelf1,
                  this.txtTelf2, this.txtFax, this.txtRepreLegal, this.txtEmail,
                  this.codProvincia, this.txtLocalReport, this.txtAutRet, this.nroSerieRet, nombreCorto, this.txtCoordinador,
                  this.txtCICoordinador, this.txtContador, this.txtRucContador, this.txtLocalFormulario,
                  nombreCorto, Number(this.txtNumeroEst), this.txtLocalizacionLogo1, this.txtLocalizacionLogo2, this.txtLocalizacionArchivo,
                  strTipoAmbiente, strFacElecAct, strProdAct, this.txtConEspecial, this.strCmbObligadoCont.name, this.txtUbicacionArchivos,
                  this.txtCorreoFacturas, this.txtCorreoNotasCredito, this.txtCorreoGuiasRemision, this.txtCorreoNotasDebito, this.txtCorreoRetenciones,
                  this.txtLocalizacionLog, strSubsidio, this.txtUbicacionXMLAut, strTransporte, this.txtCorreoLiquidaciones, strConRegMic, strAgenteret,
                  strConexion, strPing, this.txtCorreoAcceso, this.txtTelefonoAcceso, this.txtresolucion, strConRimpe,strConRimpe_NP);
                  this.cancelar();//CDPJ
                this.strTipoAmbienteFE = strTipoAmbiente;
              },
              reject: () => {
                return;
              }
            });
  
          } else {
            if (this.chkbtnCONREGMIC === true) {
              strConRegMic = '1';
            }
            if (this.chkbtnAgenteret === true) {
              strAgenteret = '1';
            }
            // if (this.chkbtnCONRIMPE === true) {
            //   strConRimpe = '1';
            // }
            //CDPJ
            if(this.selectregimen.codigo === 'regemp'){
              strConRimpe='1';
              strConRimpe_NP='0';
             }else if( this.selectregimen.codigo === 'regnp'){
              strConRimpe='0';
              strConRimpe_NP='1';
             } else if( this.selectregimen.codigo === ''){
              strConRimpe='';
              strConRimpe_NP='';
             };
             //CDPJ
  
            this.actualizarCom(codigoCpia, ruc, razonSoc, this.txtDirecc, this.txtTelf1,
              this.txtTelf2, this.txtFax, this.txtRepreLegal, this.txtEmail,
              this.codProvincia, this.txtLocalReport, this.txtAutRet, this.nroSerieRet, nombreCorto, this.txtCoordinador,
              this.txtCICoordinador, this.txtContador, this.txtRucContador, this.txtLocalFormulario,
              nombreCorto, Number(this.txtNumeroEst), this.txtLocalizacionLogo1, this.txtLocalizacionLogo2, this.txtLocalizacionArchivo,
              strTipoAmbiente, strFacElecAct, strProdAct, this.txtConEspecial, this.strCmbObligadoCont.name, this.txtUbicacionArchivos,
              this.txtCorreoFacturas, this.txtCorreoNotasCredito, this.txtCorreoGuiasRemision, this.txtCorreoNotasDebito, this.txtCorreoRetenciones,
              this.txtLocalizacionLog, strSubsidio, this.txtUbicacionXMLAut, strTransporte, this.txtCorreoLiquidaciones, strConRegMic, strAgenteret,
              strConexion, strPing, this.txtCorreoAcceso, this.txtTelefonoAcceso, this.txtresolucion, strConRimpe,strConRimpe_NP);
              this.cancelar();//CDPJ
            this.strTipoAmbienteFE = strTipoAmbiente;
          }
  
        } else {
          // btnCancelar.setEnabled(true);
        }
      }
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se actualizó correctamente ' + mensaje);
      return;
    }

  }

  async EliminarDocumentosPruebaFE() {
    try {
      await this.segmaecompaniaService.eliminarDocumentosPruebaFE();

      this.auditoriaGralService.registrarAuditoria('SEG_MAECOMPANIA', '01/ELIM-DOCPRU-FE', 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });

      this.txtConformacionsetEnabled = true;

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso eliminar documentos de prueba no terminó satisfactoriamente.... ! ' + mensaje);
      return;
    }
  }

  async actualizarCom(codigoCpia, ruc, razonSoc, comDireccion, comTelefono1, comTelefono2, comFax, comRepresentante, comCorreo, comProvincia,
    comLocalizacionRpt, comAutSriRet, comSerieRet, comNombreP, comCoordinador, comCICoordinador, comContador, comRUCContador,
    comLocalizacionFor, comNombreCorto, ComCantidad, strLocalizacionLogo1, strLocalizacionLogo2, strLocalizacionArchivo, strAmbFacElec,
    FacElecAct, ProdAct, comContribuyenteespecial, comObligadocontabilidad, comUbicacionarchivos, comCorreocopiafac, comCorreocopianc,
    comCorreocopiagre, comCorreocopiand, comCorreocopiaret, comubicacionlog, comSubsisio, comUbicacionXmlAut, comTransporte,
    comCorreocopialiq, comConregmic, comAgenteret, comConexion, comPing, comCorreoPing, comTelefonoPing, txtresolucion, strConRimpe,strConRimpe_NP) {

    try {

      await this.segmaecompaniaService.actualizarCompania(codigoCpia, ruc, razonSoc, comDireccion, comTelefono1, comTelefono2, comFax,
        comRepresentante, comCorreo, comProvincia, comLocalizacionRpt, comAutSriRet, comSerieRet, comNombreP, comCoordinador,
        comCICoordinador, comContador, comRUCContador, comLocalizacionFor, comNombreCorto, ComCantidad, strLocalizacionLogo1,
        strLocalizacionLogo2, strLocalizacionArchivo, strAmbFacElec, FacElecAct, ProdAct, comContribuyenteespecial,
        comObligadocontabilidad, comUbicacionarchivos, comCorreocopiafac, comCorreocopianc, comCorreocopiagre, comCorreocopiand,
        comCorreocopiaret, comubicacionlog, comSubsisio, comUbicacionXmlAut, comTransporte,
        comCorreocopialiq, comConregmic, comAgenteret, txtresolucion, strConRimpe,strConRimpe_NP);
      await this.segmaecompaniaService.actualizarAccesoConexion(comConexion, comPing, comCorreoPing, comTelefonoPing);
      this.mensajeAlerta('success', 'Información', 'Compania actualizada exitosamente...!!! ');
        this.cancelar();//CDPJ
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se actualizó correctamente ' + mensaje);
      return;
    }

  }

  ValidaCedula(cedula): number {
    let sum1 = 0;
    let sum2 = 0;
    let digito;
    let mayorNueve;
    let estadoCedula = 0;

    if (cedula.length === 10 || cedula.length === 11) {

      let i;
      let mod;
      for (i = 1; i <= 9; i++) {
        const digitoCedcrc = cedula.charAt(i - 1);
        const digitoCed = String(digitoCedcrc);
        digito = Number(digitoCed);
        mod = i % 2;
        if (i % 2 === 0) {
          sum1 = sum1 + digito;
        } else {
          mayorNueve = digito * 2 > 9 ? (digito * 2) - 9 : digito * 2;
          sum2 = sum2 + mayorNueve;
        }
      }
      digito = Number(String(sum1 + sum2).substring(1, 2));
      if (String(10 - digito) === cedula.substring(9, 10)) {
        return estadoCedula = 1;
      }

      return estadoCedula = 0;
    } // fin if length() de cedula

    return estadoCedula;
  }

  ValidaRuc(ruc): number {
    let nroCedula;
    let tercerDigito;
    let decimoDigito;
    let decimoDigitoaux;

    let sum = 0;
    let contador = 0;
    let contadorPosicion = 0;
    let cociente;
    let decena;
    let verificador;
    let estadoRuc;
    let digitosRuc;

    if (ruc.length !== 13) {
      return estadoRuc = 0;
    }
    digitosRuc = ruc.substring(10, 13);
    if (digitosRuc === '001') {
      estadoRuc = 1;
    } else {
      return estadoRuc = 0;
    }
    if (ruc.length <= 0) {
      return estadoRuc = 0;
    }

    nroCedula = ruc.substring(0, 10); // cedula
    tercerDigito = ruc.substring(2, 3);
    decimoDigitoaux = ruc.substring(9, 10);


    if (nroCedula.length === 0 || ruc.length !== 13) {
      return estadoRuc = 0;
    }
    // El tercer dígito es siempre menor a 6: 0, 1, 2, 3, 4, 5 para Personas
    // Naturales
    if (Number(tercerDigito) >= 0 && Number(tercerDigito) <= 6 && Number(decimoDigitoaux) !== 0) {
      const Idprovincia = ruc.substring(0, 2); // obtengo el tercer dígito

      if ((Number(Idprovincia) >= 1 && Number(Idprovincia) <= 24) || Number(Idprovincia) === 30) {
        decimoDigito = ruc.substring(9, 10);

        do {
          contadorPosicion = (2 * contador) + 1;
          const digCedula = nroCedula.charAt(contadorPosicion - 1);
          const digiCed = String(digCedula);
          let multi = Number(digiCed) * 2;
          if (multi >= 10) {
            multi = 1 + multi % 10;
          }
          sum = sum + multi;
          contador++;
        } while (contadorPosicion < 9);

        contador = 1;
        contadorPosicion = 1;
        do {
          contadorPosicion = 2 * contador;
          const digCedula = nroCedula.charAt(contadorPosicion - 1);
          const digiCed = String(digCedula);
          sum = sum + Number(digiCed);
          contador++;
        } while (contadorPosicion < 8);

        cociente = sum / 10;
        decena = (Math.trunc(cociente) + 1) * 10;
        verificador = decena - sum;
        if (verificador === 10) {
          verificador = 0;
        }
        if (verificador === Number(decimoDigito)) {
          estadoRuc = 1;
        } else {
          estadoRuc = 0;
        }
      } else { // fin del if rango provincias
        return estadoRuc = 0;
      }
      // fin if personas naturales
    } else if (Number(tercerDigito) === 6 && Number(tercerDigito) === 0) { // El tercer dígito 6 para Sociedades Públicas
      const digUno = Number(nroCedula.substring(0, 1));
      const digDos = Number(nroCedula.substring(1, 2));
      const digTres = Number(nroCedula.substring(2, 3));
      const digCuatro = Number(nroCedula.substring(3, 4));
      const digCinco = Number(nroCedula.substring(4, 5));
      const digSeis = Number(nroCedula.substring(5, 6));
      const digSiete = Number(nroCedula.substring(6, 7));
      const digOcho = Number(nroCedula.substring(7, 8));
      const digNueve = Number(nroCedula.substring(8, 9));

      sum = digUno * 3 + digDos * 2 + digTres * 7 + digCuatro * 6
        + digCinco * 5 + digSeis * 4 + digSiete * 3 + digOcho * 2;

      do {
        sum = sum - 11;
      } while (sum > 11);
      let digVerif = 11 - sum;

      if (digVerif === 10) {
        digVerif = 0;
        return estadoRuc = 0;
      } else {
        if (digVerif !== digNueve) {
          return estadoRuc = 0;
        } else {
          return estadoRuc = 1;
        }
      }
      // fin de if Sociedades Públicas
    } else if (Number(tercerDigito) === 9) { // El tercer dígito 9 para Sociedades Privadas y Extranjeros sin cédula

      const digUno = Number(nroCedula.substring(0, 1));
      const digDos = Number(nroCedula.substring(1, 2));
      const digTres = Number(nroCedula.substring(2, 3));
      const digCuatro = Number(nroCedula.substring(3, 4));
      const digCinco = Number(nroCedula.substring(4, 5));
      const digSeis = Number(nroCedula.substring(5, 6));
      const digSiete = Number(nroCedula.substring(6, 7));
      const digOcho = Number(nroCedula.substring(7, 8));
      const digNueve = Number(nroCedula.substring(8, 9));
      const digDiez = Number(nroCedula.substring(9, 10));

      sum = digUno * 4 + digDos * 3 + digTres * 2 + digCuatro * 7
        + digCinco * 6 + digSeis * 5 + digSiete * 4 + digOcho * 3
        + digNueve * 2;

      do {
        sum = sum - 11;
      } while (sum > 11);
      let digVerif = 11 - sum;

      if (digVerif === 10) {
        digVerif = 0;
        return estadoRuc = 0;
      } else {
        if (digVerif !== digDiez) {
          return estadoRuc = 0;
        } else {
          return estadoRuc = 1;
        }
      }
    }// fin de if Sociedades Privadas y Extranjeros sin cédula
    return estadoRuc;
  }

  cancelar() {
    this.btnGuardar = true;
    // btnEjecutar.setEnabled(false);
    this.mostrarDatos();
    this.actualizarProvincia(this.provincia);
    this.listarConexion();
    this.mostrarDatoSuc();
    this.mostrarDatoAdiFac();
    this.mostrarDatoAdiGre();
    this.mostrarDatoAdiLiq();
    this.mostrarDatoAdidevfac();//GSRF
  }

  actualizarProvincia(provin) {

    for (const prov of this.cboProvin) {
      if (prov.REF_CODIGO === provin) {
        this.strCmbProvin = prov;
      }
    }
  }

  async mostrarDatos() {
    let compania: SegMaeCompania[] = [];
    compania = await this.listar('T');
    const tamanioUtilizado = await this.calcularMegas();
    let strTipoAmbiente = '';
    let strFacEleAct = '';
    let strProdAct = '';
    let strSubsidio = '';
    let strTransporte = '';
    let strConRegMic = '';
    let strAgenteret = '';
    let strConexion = '';
    let strPing = '';
    let strConRimpe = '';
    let strConRimpe_NP='';
    for (const item of compania) {
      if (item != null) {
        this.strLogoCom = item.COM_LOGO;
        this.txtCodigo = item.COMCODIGO;
        this.txtRuc = item.COMRUCI;
        this.txtAutRet = item.COMAUTSRI_RET === null ? '' : item.COMAUTSRI_RET;

        if (item.COMSERIE_RET !== null) {
          this.serieRet = item.COMSERIE_RET;
          this.establecimiento = this.serieRet.substring(0, 3);
          this.txtEstablecimiento = this.establecimiento;
          this.puntoVenta = this.serieRet.substring(3, 6);
          this.txtPuntoVenta = this.puntoVenta;
        } else {
          this.txtEstablecimiento = '';
          this.txtPuntoVenta = '';
        }

        this.txtRazSoc = item.COMNOMBRE === null ? '' : item.COMNOMBRE;
        this.txtRepreLegal = item.COMREPRESENTANTE === null ? '' : item.COMREPRESENTANTE;
        this.txtDirecc = item.COMDIRECCION === null ? '' : item.COMDIRECCION;
        this.txtTelf1 = item.COMTELEFONO1 === null ? '' : item.COMTELEFONO1;
        this.txtTelf2 = item.COMTELEFONO2 === null ? '' : item.COMTELEFONO2;
        this.txtFax = item.COMFAX === null ? '' : item.COMFAX;
        this.txtEmail = item.COMCORREO === null ? '' : item.COMCORREO;
        this.txtLocalReport = item.COMLOCALIZACION_RPT === null ? '' : item.COMLOCALIZACION_RPT;
        this.txtNombreP = item.COMNOMBREP === null ? '' : item.COMNOMBREP;
        this.txtCoordinador = item.COMCOORDINADOR === null ? '' : item.COMCOORDINADOR;
        this.txtCICoordinador = item.COMCICOORDINADOR === null ? '' : item.COMCICOORDINADOR;
        this.txtContador = item.COMCONTADOR === null ? '' : item.COMCONTADOR;
        this.txtRucContador = item.COMRUCCONTADOR === null ? '' : item.COMRUCCONTADOR;
        this.txtLocalFormulario = item.COMLOCALIZACION_FOR === null ? '' : item.COMLOCALIZACION_FOR;
        this.provincia = item.COMPROVINCIA;
        this.txtNombreCorto = item.COMNOMBRECORTO === null ? '' : item.COMNOMBRECORTO;
        this.txtNumeroEst = item.COMCANTIDAD;
        this.txtLocalizacionLogo1 = item.COMLOCALIZACIONLOG1 === null ? '' : item.COMLOCALIZACIONLOG1;
        this.txtLocalizacionLogo2 = item.COMLOCALIZACIONLOG2 === null ? '' : item.COMLOCALIZACIONLOG2;
        this.txtLocalizacionArchivo = item.COMLOCALIZACIONARCHIVO === null ? '' : item.COMLOCALIZACIONARCHIVO;
        this.txtConEspecial = item.COM_CONTRIBUYENTEESPECIAL === null ? '' : item.COM_CONTRIBUYENTEESPECIAL;
        this.txtCorreoAcceso = item.COM_CORREOPING === null ? '' : item.COM_CORREOPING;
        this.txtTelefonoAcceso = item.COM_TELEFONOPING === null ? '' : item.COM_TELEFONOPING;
        this.txtAlmacenamiento = Number(tamanioUtilizado).toFixed(4) + '/' + item.COM_CAPACIDADOCUMENTOS + ' MB'
        if (item.COM_OBLIGADOCONTABILIDAD === null) {
          this.strCmbObligadoCont = this.cmbObligadoLLevarCont[0];
        } else {
          for (const a of this.cmbObligadoLLevarCont) {
            if (a.name === item.COM_OBLIGADOCONTABILIDAD) {
              this.strCmbObligadoCont = a;
            }
          }
        }

        this.txtUbicacionArchivos = item.COM_UBICACIONARCHIVOS === null ? '' : item.COM_UBICACIONARCHIVOS;
        this.txtCorreoFacturas = item.COM_CORREOCOPIAFAC === null ? '' : item.COM_CORREOCOPIAFAC;
        this.txtCorreoNotasCredito = item.COM_CORREOCOPIANC === null ? '' : item.COM_CORREOCOPIANC;
        this.txtCorreoGuiasRemision = item.COM_CORREOCOPIAGRE === null ? '' : item.COM_CORREOCOPIAGRE;
        this.txtCorreoNotasDebito = item.COM_CORREOCOPIAND === null ? '' : item.COM_CORREOCOPIAND;
        this.txtCorreoRetenciones = item.COM_CORREOCOPIARET === null ? '' : item.COM_CORREOCOPIARET;
        this.txtCorreoLiquidaciones = item.COM_CORREOCOPIALIQ === null ? '' : item.COM_CORREOCOPIALIQ;
        this.txtConformacion = item.COM_CONFORMACION === null ? '' : item.COM_CONFORMACION;
        this.fechacaducidad = this.datePipe.transform(item.COM_FECHACADFIRMAELE, 'dd/MM/yyyy');
        this.txtresolucion = item.COM_NUMAGERET;
        this.txtLocalizacionLog = item.COM_UBICACIONLOG === null ? '' : item.COM_UBICACIONLOG;
        this.rutalogo = item.COM_RUTALOGO;

        if (String(this.txtConformacion).trim() !== '') {
          this.txtConformacionsetEnabled = false;
        } else {
          this.txtConformacionsetEnabled = true;
        }

        strTipoAmbiente = item.COM_TIPOAMBFACTELEC === null ? '' : item.COM_TIPOAMBFACTELEC;
        strFacEleAct = item.COM_FEACTIVADA === null ? '' : item.COM_FEACTIVADA;
        strProdAct = item.COM_PRODACTIVADA === null ? '' : item.COM_PRODACTIVADA;
        strSubsidio = item.COM_SUBSIDIO === null ? '' : item.COM_SUBSIDIO;
        strTransporte = item.COM_TRANSPORTE === null ? '' : item.COM_TRANSPORTE;
        strConRegMic = item.COM_CONREGMIC === null ? '' : item.COM_CONREGMIC;
        strAgenteret = item.COM_AGENTERET === null ? '' : item.COM_AGENTERET;
        strConexion = item.COM_CONEXION;
        strPing = item.COM_PING;
        strConRimpe = item.COM_CONREGRIMPE === null ? '' : item.COM_CONREGRIMPE;
        strConRimpe_NP=item.COM_CONREGRIMPE_NP === null || item.COM_CONREGRIMPE_NP === undefined ? '' : item.COM_CONREGRIMPE_NP;//CDPJ

        if (strFacEleAct === '1') {
          this.chkbtnFacElecAct = true;
        } else {
          this.chkbtnFacElecAct = false;
        }

        if (strProdAct === '1') {
          this.chkbtnProdAct = true;
        } else {
          this.chkbtnProdAct = false;
        }

        if (strSubsidio.toString() === '1') {
          this.chkbtnSubsidio = true;
        } else {
          this.chkbtnSubsidio = false;
        }

        if (strTransporte === '1') {
          this.chkbtnTransporte = true;
        } else {
          this.chkbtnTransporte = false;
        }

        this.strCmbTipoAmbiente = this.cmbTipoAmbiente[0];

        if (strTipoAmbiente === '1') {
          this.strCmbTipoAmbiente = this.cmbTipoAmbiente[1];
        }
        if (strTipoAmbiente === '2') {
          this.strCmbTipoAmbiente = this.cmbTipoAmbiente[2];
        }

        this.strTipoAmbienteFE = strTipoAmbiente;

        this.txtUbicacionXMLAut = item.COM_UBICACIONXMLAUT === null ? '' : item.COM_UBICACIONXMLAUT;

        // if (strConRegMic === '1') {
        //   this.chkbtnCONREGMIC = true;
        // } else {
        //   this.chkbtnCONREGMIC = false;
        // }

        if (strConRimpe === '1') {
          this.chkbtnCONRIMPE = true;
        } else {
          this.chkbtnCONRIMPE = false;
        }

        if (strAgenteret === '1') {
          this.chkbtnAgenteret = true;
        } else {
          this.chkbtnAgenteret = false;
        }

        if (strConexion === '1') {
          this.chkAceptarConexiones = true;
          this.visibleTabConexion = true;
        } else {
          this.chkAceptarConexiones = false;
          this.visibleTabConexion = false;
        }

        if (strPing === '1') {
          this.chkUsarPingAcceso = true;
          this.disabledEmailPing = true;
        } else {
          this.chkUsarPingAcceso = false;
          this.disabledEmailPing = false;
        }

        this.btnGuardar = true;

        // btnCancelar.setEnabled(false);
        // btnEjecutar.setEnabled(false);
        //CDPJ
        if (strConRimpe === '1') {
          this.selectregimen = { codigo:'regemp' , name: 'Regimen Rimpe - Emprendedor' }
         }else if (strConRimpe_NP === '1') {
           this.selectregimen = { codigo:'regnp' , name: 'Regimen Rimpe - Negocio Popular' }
         }else{
           this.selectregimen = { codigo:'' , name: '' }
         }
       //CDPJ
      }
    }
  }

  async listar(opcion) {
    const item: SegMaeCompania = {};
    const compania: SegMaeCompania[] = [];
    try {
      const rs = await this.segmaecompaniaService.listar('T');
      if (rs !== null) {
        for (const com of rs) {
          item.COMCODIGO = com.COMCODIGO;
          item.COMRUCI = com.COMRUCI;
          item.COMNOMBRE = com.COMNOMBRE;
          item.COMDIRECCION = com.COMDIRECCION;
          item.COMTELEFONO1 = com.COMTELEFONO1;
          item.COMTELEFONO2 = com.COMTELEFONO2;
          item.COMFAX = com.COMFAX;
          item.COMREPRESENTANTE = com.COMREPRESENTANTE;
          item.COMCORREO = com.COMCORREO;
          item.COMPROVINCIA = com.COMPROVINCIA;
          item.COMLOCALIZACION_RPT = com.COMLOCALIZACION_RPT;
          item.COMAUTSRI_RET = com.COMAUTSRI_RET;
          item.COMSERIE_RET = com.COMSERIE_RET;
          item.COMNOMBREP = com.COMNOMBREP;
          item.COMCOORDINADOR = com.COMCOORDINADOR;
          item.COMCICOORDINADOR = com.COMCICOORDINADOR;
          item.COMCONTADOR = com.COMCONTADOR;
          item.COMRUCCONTADOR = com.COMRUCCONTADOR;
          item.COMLOCALIZACION_FOR = com.COMLOCALIZACION_FOR;
          item.COMNOMBRECORTO = com.COMNOMBRECORTO;
          item.COMCANTIDAD = com.COMCANTIDAD === 0 ? 0 : com.COMCANTIDAD;
          item.COMLOCALIZACIONLOG1 = com.COMLOCALIZACIONLOG1 === null ? '' : com.COMLOCALIZACIONLOG1;
          item.COMLOCALIZACIONLOG2 = com.COMLOCALIZACIONLOG2 === null ? '' : com.COMLOCALIZACIONLOG2;
          item.COMLOCALIZACIONARCHIVO = com.COMLOCALIZACIONARCHIVO === null ? '' : com.COMLOCALIZACIONARCHIVO;
          item.COM_TIPOAMBFACTELEC = com.COM_TIPOAMBFACTELEC === null ? '' : com.COM_TIPOAMBFACTELEC;
          item.COM_FEACTIVADA = com.COM_FEACTIVADA === null ? '' : com.COM_FEACTIVADA;
          item.COM_PRODACTIVADA = com.COM_PRODACTIVADA === null ? '' : com.COM_PRODACTIVADA;
          item.COM_SUBSIDIO = com.COM_SUBSIDIO === null ? '' : com.COM_SUBSIDIO;
          item.COM_TRANSPORTE = com.COM_TRANSPORTE === null ? '' : com.COM_TRANSPORTE;
          item.COM_CONTRIBUYENTEESPECIAL = com.COM_CONTRIBUYENTEESPECIAL === null ? '' : com.COM_CONTRIBUYENTEESPECIAL;
          item.COM_OBLIGADOCONTABILIDAD = com.COM_OBLIGADOCONTABILIDAD === null ? '' : com.COM_OBLIGADOCONTABILIDAD;
          item.COM_UBICACIONARCHIVOS = com.COM_UBICACIONARCHIVOS === null ? '' : com.COM_UBICACIONARCHIVOS;
          item.COM_CORREOCOPIAFAC = com.COM_CORREOCOPIAFAC === null ? '' : com.COM_CORREOCOPIAFAC;
          item.COM_CORREOCOPIANC = com.COM_CORREOCOPIANC === null ? '' : com.COM_CORREOCOPIANC;
          item.COM_CORREOCOPIAGRE = com.COM_CORREOCOPIAGRE === null ? '' : com.COM_CORREOCOPIAGRE;
          item.COM_CORREOCOPIAND = com.COM_CORREOCOPIAND === null ? '' : com.COM_CORREOCOPIAND;
          item.COM_CORREOCOPIARET = com.COM_CORREOCOPIARET === null ? '' : com.COM_CORREOCOPIARET;
          item.COM_CORREOCOPIALIQ = com.COM_CORREOCOPIALIQ === null ? '' : com.COM_CORREOCOPIALIQ;
          item.COM_CONFORMACION = com.COM_CONFORMACION === null ? '' : com.COM_CONFORMACION;
          item.COM_FECHACADFIRMAELE = com.COM_FECHACADFIRMAELE === null ? '' : com.COM_FECHACADFIRMAELE;
          item.COM_UBICACIONLOG = com.COM_UBICACIONLOG === null ? '' : com.COM_UBICACIONLOG;
          item.COM_UBICACIONXMLAUT = com.COM_UBICACIONXMLAUT === null ? '' : com.COM_UBICACIONXMLAUT;
          item.COM_CONREGMIC = com.COM_CONREGMIC === null ? '' : com.COM_CONREGMIC;
          item.COM_AGENTERET = com.COM_AGENTERET === null ? '' : com.COM_AGENTERET;
          item.COM_LOGO = com.COM_LOGO === null ? '' : com.COM_LOGO;
          item.COM_CONEXION = com.COM_CONEXION === null ? '' : com.COM_CONEXION;
          item.COM_PING = com.COM_PING === null ? '' : com.COM_PING;
          item.COM_CORREOPING = com.COM_CORREOPING === null ? '' : com.COM_CORREOPING;
          item.COM_TELEFONOPING = com.COM_TELEFONOPING === null ? '' : com.COM_TELEFONOPING;
          item.COM_RUTALOGO = com.COM_RUTALOGO === null ? '' : com.COM_RUTALOGO;
          item.COM_CAPACIDADOCUMENTOS = com.COM_CAPACIDADOCUMENTOS === null ? 0 : com.COM_CAPACIDADOCUMENTOS;
          item.COM_NUMAGERET = com.COM_NUMAGERET === null ? '' : com.COM_NUMAGERET;
          item.COM_CONREGRIMPE = com.COM_CONREGRIMPE === null ? '' : com.COM_CONREGRIMPE;
          item.COM_CONREGRIMPE_NP=com.COM_CONREGRIMPE_NP === null || com.COM_CONREGRIMPE_NP === undefined  ? '' : com.COM_CONREGRIMPE_NP; //CDPJ
        }
      } else {
        return null;
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
    compania.push(item);

    return compania;
  }

  cambioUsarPing(e) {

    if (e.checked) {
      this.disabledEmailPing = true;
    } else if (!e.checked) {
      this.disabledEmailPing = false;
    }

    this.habilitarBotones();
  }

  cambioAceptConexion(e) {

    if (e.checked) {
      // this.disabledTabConexion = false;
      this.visibleTabConexion = true;
    } else if (!e.checked) {
      this.visibleTabConexion = false;
      // this.disabledTabConexion = true;
    }

    this.habilitarBotones();
  }

  async listarProvincias() {
    const datos: SegMaeReferencia[] = await this.consultarReferencia('PV', 'REF_CODIGO', '');
    this.cboProvin = [];
    for (const item of datos) {
      this.cboProvin.push(item);
    }

    this.actualizarProvincia(this.provincia);
  }

  async consultarReferencia(tipo, orden, selecc) {

    const datos: SegMaeReferencia[] = [];
    try {
      const rs = await this.segmaecompaniaService.consultarReferencia(tipo, orden, selecc);
      if (rs.length > 0) {
        for (const prov of rs) {
          const item: SegMaeReferencia = {};
          item.REF_TIPO = prov.TIPO;
          item.REF_CODIGO = prov['CÓDIGO'];
          item.REF_NOMBRE = prov.NOMBRE;
          item.REF_NOMBREC = prov['Nombre Corto'];
          datos.push(item);
        }

      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }

    return datos;
  }

  tiposDeAmbiente() {
    this.cmbTipoAmbiente = [];
    this.cmbTipoAmbiente.push({ name: '', codigo: 0 }, { name: 'Pruebas', codigo: 1 }, { name: 'Producción', codigo: 2 });
  }

  mensajeAlerta(tipo, titulo, msg) {
    this.messageService.add({
      key: 'compania',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

  acciones(valor) {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 0; i < elemento.length; i++) {
        elemento[i].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 0; i < elemento.length; i++) {
        elemento[i].className = 'layout-config';
      }
    }
  }

  cambio(event) {
    this.habilitarBotones();
  }

  cambioChkT(event) {
    this.habilitarBotones();
    this.index = 2;
    if (this.chkbtnTransporte === true) {
      this.columnDefsSegMaeSucursal = this.columnDefsSegMaeSucursalTransportista;
    } else {
      this.columnDefsSegMaeSucursal = this.columnDefsSegMaeSucursalNoTransportista;
    }
    this.aggrid.refreshColumnDefsIndice(this.indicador, this.defaultSegMaeSuc.objeto, this.columnDefsSegMaeSucursal);
    this.aggrid.refreshaggrid(this.segmaecompaniaService.detSucursalArray[this.indicador], this.defaultSegMaeSuc.objeto);
  }

  habilitarBotones() {
    this.btnGuardar = false;
    //  this.btnEjecutar = false;
  }


  async listarConexion() {
    const detalleConexion: SegComConexion[] = [];

    try {
      const rs = await this.segmaecompaniaService.listarIPPublica();
      this.segmaecompaniaService.detConexionArray[this.indicador] = [];
      if (rs !== null) {
        for (const ip of rs) {
          const item: SegComConexion = {};
          item.COM_CODIGO = ip.COM_CODIGO;
          item.IP_DESCRIPCION = ip.IP_DESCRIPCION;
          item.IP_CODIGO = ip.IP_CODIGO;
          item.IP_NUMERO = ip.IP_NUMERO;
          detalleConexion.push(item);
        }
        this.segmaecompaniaService.detConexionArray[this.indicador] = detalleConexion;
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  }

  seleccionDetConexion(valor) {
    if (valor === 'nuevo') {
      this.nuevoElementoConexion();

    }
    if (valor === 'eliminar') {

      if (Object.keys(this.detalleConexionSel).length > 0) {
        this.confirmarElimDetConexion();
      } else {
        this.mensajeAlerta('error', 'Error', '! Seleccione una fila.... !');
      }
    }
    if (valor === 'cancelar') {
      this.listarConexion();
    }

    if (valor.object !== undefined) {
      this.detalleConexionSel = valor.object;
    }
  }

  nuevoElementoConexion() {
    const item: SegComConexion = {};
    item.COM_CODIGO = '01';
    item.IP_CODIGO = 0;
    item.IP_DESCRIPCION = '';
    item.IP_NUMERO = '';


    const indi = this.segmaecompaniaService.detConexionArray[this.indicador].indexOf(this.detalleConexionSel);
    if (indi === -1) {
      this.segmaecompaniaService.detConexionArray[this.indicador].push(item);
      this.detalleConexionSel = this.segmaecompaniaService.detConexionArray[this.indicador]
      [this.segmaecompaniaService.detConexionArray[this.indicador].length - 1];
      this.aggrid.refreshaggrid(this.segmaecompaniaService.detConexionArray[this.indicador], this.defaultSegComConexion.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultSegComConexion.objeto].setFocusedCell(
        this.segmaecompaniaService.detConexionArray[this.indicador].length - 1, 'IP_NUMERO');
    } else {
      this.segmaecompaniaService.detConexionArray[this.indicador].splice(indi + 1, 0, item);
      this.aggrid.refreshaggridindex(this.segmaecompaniaService.detConexionArray[this.indicador], this.defaultSegComConexion.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultSegComConexion.objeto].setFocusedCell(
        indi + 1, 'IP_NUMERO');
    }

  }

  confirmarElimDetConexion() {
    this.confirmationService.confirm({
      message: '¿ Está seguro de eliminar el registro ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'compania',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarConexion();
      },
      reject: () => {
      }
    });
  }

  async eliminarConexion() {
    try {

      await this.segmaecompaniaService.eliminarDetConexion(this.detalleConexionSel.COM_CODIGO, this.detalleConexionSel.IP_CODIGO);

      this.auditoriaGralService.registrarAuditoria('SEG_MAEIPPUBLICA', this.detalleConexionSel.IP_CODIGO + '/'
        + this.detalleConexionSel.IP_NUMERO, 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
      this.segmaecompaniaService.detConexionArray[this.indicador].splice(this.segmaecompaniaService.detConexionArray[this.indicador].findIndex(
        item => item.IP_CODIGO === this.detalleConexionSel.IP_CODIGO && item.COM_CODIGO === this.detalleConexionSel.COM_CODIGO),
        1);
      this.aggrid.refreshaggrid(this.segmaecompaniaService.detConexionArray[this.indicador], this.defaultSegComConexion.objeto);
      this.mensajeAlerta('success', 'Información', 'IP Pública eliminada exitosamente...!!!');

    } catch (err) {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso eliminar  no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }

  async mostrarDatoSuc() {
    const detalleSucursal: SegMaeSucursal[] = [];
    try {
      const rs = await this.segmaecompaniaService.listarSucursal();
      this.segmaecompaniaService.detSucursalArray[this.indicador] = [];
      if (rs !== null) {
        for (const suc of rs) {
          const item: SegMaeSucursal = {};
          item.INT_NUMSEC = 1;
          item.SUC_CODIGO = suc.SUC_CODIGO === null ? '' : suc.SUC_CODIGO;
          item.SUC_DESCRIPCION = suc.SUC_DESCRIPCION === null ? '' : suc.SUC_DESCRIPCION;
          item.SUC_REGIMEN = suc.SUC_REGIMEN === null ? '' : suc.SUC_REGIMEN;
          item.SUC_RAZONSOCIALTRANSPOR = suc.SUC_RAZONSOCIALTRANSPOR === null ? '' : suc.SUC_RAZONSOCIALTRANSPOR;
          item.SUC_RUCTRANSPORTISTA = suc.SUC_RUCTRANSPORTISTA === null ? '' : suc.SUC_RUCTRANSPORTISTA;
          item.COM_CODIGO = suc.COM_CODIGO === null ? '' : suc.COM_CODIGO;

          detalleSucursal.push(item);
        }
        this.segmaecompaniaService.detSucursalArray[this.indicador] = detalleSucursal;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  }

  seleccionDetSucursal(valor) {
    if (valor === 'nuevo') {
      this.nuevoElementoSucursal();

    }
    if (valor === 'eliminar') {
      if (Object.keys(this.detalleSucursalSel).length > 0) {
        this.confirmationService.confirm({
          message: '¿ Está seguro de eliminar el registro ?',
          header: 'Confirmación',
          icon: 'pi pi-exclamation-triangle',
          key: 'compania',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.eliminarSucursal();
          },
          reject: () => {
          }
        });
      } else {
        this.mensajeAlerta('error', 'Error', '! Seleccione una fila.... !');
      }

    }
    if (valor === 'cancelar') {
      this.mostrarDatoSuc();
    }

    if (valor.object !== undefined) {
      this.detalleSucursalSel = valor.object;
    }
  }

  async eliminarSucursal() {
    try {

      await this.segmaecompaniaService.eliminarDetSucursal(this.detalleSucursalSel.SUC_CODIGO, '01');

      this.auditoriaGralService.registrarAuditoria('SEG_MAESUCURSAL', this.detalleSucursalSel.SUC_CODIGO, 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
      this.segmaecompaniaService.detSucursalArray[this.indicador].splice(this.segmaecompaniaService.detSucursalArray[this.indicador].findIndex(
        item => item.SUC_CODIGO === this.detalleSucursalSel.SUC_CODIGO && item.INT_NUMSEC === this.detalleSucursalSel.INT_NUMSEC),
        1);
      this.aggrid.refreshaggrid(this.segmaecompaniaService.detSucursalArray[this.indicador], this.defaultSegMaeSuc.objeto);
      this.mensajeAlerta('success', 'Información', 'Registro eliminado exitosamente...!!!');

    } catch (err) {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso eliminar  no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }

  nuevoElementoSucursal() {
    const item: SegMaeSucursal = {};
    item.COM_CODIGO = '01';
    item.INT_NUMSEC = 0;
    item.SUC_CODIGO = '';
    item.SUC_DESCRIPCION = '';
    item.SUC_RUCTRANSPORTISTA = '';
    item.SUC_RAZONSOCIALTRANSPOR = '';
    item.SUC_REGIMEN = '';


    const indi = this.segmaecompaniaService.detSucursalArray[this.indicador].indexOf(this.detalleSucursalSel);
    if (indi === -1) {
      this.segmaecompaniaService.detSucursalArray[this.indicador].push(item);
      this.detalleSucursalSel = this.segmaecompaniaService.detSucursalArray[this.indicador]
      [this.segmaecompaniaService.detSucursalArray[this.indicador].length - 1];
      this.aggrid.refreshaggrid(this.segmaecompaniaService.detSucursalArray[this.indicador], this.defaultSegMaeSuc.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultSegMaeSuc.objeto].setFocusedCell(
        this.segmaecompaniaService.detSucursalArray[this.indicador].length - 1, 'SUC_CODIGO');
    } else {
      this.segmaecompaniaService.detSucursalArray[this.indicador].splice(indi + 1, 0, item);
      this.aggrid.refreshaggridindex(this.segmaecompaniaService.detSucursalArray[this.indicador], this.defaultSegMaeSuc.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultSegMaeSuc.objeto].setFocusedCell(
        indi + 1, 'SUC_CODIGO');
    }

  }

  async mostrarDatoAdiFac() {
    const detalleInfoAdiFact: SegInfadicional[] = [];
    try {
      const rs = await this.segmaecompaniaService.listarInfoAdiFact();
      this.segmaecompaniaService.detInfoFactArray[this.indicador] = [];
      if (rs !== null) {
        for (const fact of rs) {
          const item: SegInfadicional = {};
          item.INFAD_NUMSEC = fact.INFAD_NUMSEC;
          item.INFAD_CODIGO = fact.INFAD_CODIGO;
          item.COM_CODIGO = fact.COM_CODIGO;
          item.INFAD_DESCRIPCION = fact.INFAD_DESCRIPCION;
          detalleInfoAdiFact.push(item);
        }
        this.segmaecompaniaService.detInfoFactArray[this.indicador] = detalleInfoAdiFact;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  }

  seleccionDetInfoFact(valor) {
    if (valor === 'nuevo') {
      this.nuevoElementoInfoFact();

    }
    if (valor === 'eliminar') {
      if (Object.keys(this.detalleInfoFactSel).length > 0) {
        this.confirmationService.confirm({
          message: '¿ Está seguro de eliminar el registro ?',
          header: 'Confirmación',
          icon: 'pi pi-exclamation-triangle',
          key: 'compania',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.eliminarInfoFact();
          },
          reject: () => {
          }
        });
      } else {
        this.mensajeAlerta('error', 'Error', '! Seleccione una fila.... !');
      }

    }
    if (valor === 'cancelar') {
      this.mostrarDatoAdiFac();
    }

    if (valor.object !== undefined) {
      this.detalleInfoFactSel = valor.object;
    }

  }

  nuevoElementoInfoFact() {
    const item: SegInfadicional = {};
    item.COM_CODIGO = '01';
    item.INFAD_CODIGO = '';
    item.INFAD_DESCRIPCION = '';
    item.INFAD_NUMSEC = 0;

    const indi = this.segmaecompaniaService.detInfoFactArray[this.indicador].indexOf(this.detalleInfoFactSel);
    if (indi === -1) {
      this.segmaecompaniaService.detInfoFactArray[this.indicador].push(item);
      this.detalleInfoFactSel = this.segmaecompaniaService.detInfoFactArray[this.indicador]
      [this.segmaecompaniaService.detInfoFactArray[this.indicador].length - 1];
      this.aggrid.refreshaggrid(this.segmaecompaniaService.detInfoFactArray[this.indicador], this.defaultSegInfFact.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultSegInfFact.objeto].setFocusedCell(
        this.segmaecompaniaService.detInfoFactArray[this.indicador].length - 1, 'INFAD_CODIGO');
    } else {
      this.segmaecompaniaService.detInfoFactArray[this.indicador].splice(indi + 1, 0, item);
      this.aggrid.refreshaggridindex(this.segmaecompaniaService.detInfoFactArray[this.indicador], this.defaultSegInfFact.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultSegInfFact.objeto].setFocusedCell(
        indi + 1, 'INFAD_CODIGO');
    }

  }

  async eliminarInfoFact() {
    try {

      await this.segmaecompaniaService.eliminarDetInfoAdiFact('01', Number(this.detalleInfoFactSel.INFAD_NUMSEC));

      this.auditoriaGralService.registrarAuditoria('SEG_INFADIFAC', this.detalleInfoFactSel.INFAD_CODIGO, 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
      this.segmaecompaniaService.detInfoFactArray[this.indicador].splice(this.segmaecompaniaService.detInfoFactArray[this.indicador].findIndex(
        item => item.INFAD_CODIGO === this.detalleInfoFactSel.INFAD_CODIGO && item.INFAD_NUMSEC === this.detalleInfoFactSel.INFAD_NUMSEC),
        1);
      this.aggrid.refreshaggrid(this.segmaecompaniaService.detInfoFactArray[this.indicador], this.defaultSegInfFact.objeto);
      this.mensajeAlerta('success', 'Información', 'Registro eliminado exitosamente...!!!');

    } catch (err) {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso eliminar  no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }

  async mostrarDatoAdiGre() {
    const detalleInfoAdiGuiaRem: SegInfadicional[] = [];
    try {
      const rs = await this.segmaecompaniaService.listarInfoAdiGuiaRem();
      this.segmaecompaniaService.detInfoGuiaRemArray[this.indicador] = [];
      if (rs !== null) {
        for (const fact of rs) {
          const item: SegInfadicional = {};
          item.INFAD_NUMSEC = fact.INFAD_NUMSEC;
          item.INFAD_CODIGO = fact.INFAD_CODIGO;
          item.COM_CODIGO = fact.COM_CODIGO;
          item.INFAD_DESCRIPCION = fact.INFAD_DESCRIPCION;
          detalleInfoAdiGuiaRem.push(item);
        }
        this.segmaecompaniaService.detInfoGuiaRemArray[this.indicador] = detalleInfoAdiGuiaRem;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  }

  seleccionDetInfoGuiaRem(valor) {
    if (valor === 'nuevo') {
      this.nuevoElementoInfoGuiaRem();

    }
    if (valor === 'eliminar') {
      if (Object.keys(this.detalleInfoGuiaRemSel).length > 0) {
        this.confirmationService.confirm({
          message: '¿ Está seguro de eliminar el registro ?',
          header: 'Confirmación',
          icon: 'pi pi-exclamation-triangle',
          key: 'compania',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.eliminarInfoGuiaRem();
          },
          reject: () => {
          }
        });
      } else {
        this.mensajeAlerta('error', 'Error', '! Seleccione una fila.... !');
      }

    }
    if (valor === 'cancelar') {
      this.mostrarDatoAdiGre();
    }

    if (valor.object !== undefined) {
      this.detalleInfoGuiaRemSel = valor.object;
    }
  }

  nuevoElementoInfoGuiaRem() {
    const item: SegInfadicional = {};
    item.COM_CODIGO = '01';
    item.INFAD_CODIGO = '';
    item.INFAD_DESCRIPCION = '';
    item.INFAD_NUMSEC = 0;

    const indi = this.segmaecompaniaService.detInfoGuiaRemArray[this.indicador].indexOf(this.detalleInfoGuiaRemSel);
    if (indi === -1) {
      this.segmaecompaniaService.detInfoGuiaRemArray[this.indicador].push(item);
      this.detalleInfoGuiaRemSel = this.segmaecompaniaService.detInfoGuiaRemArray[this.indicador]
      [this.segmaecompaniaService.detInfoGuiaRemArray[this.indicador].length - 1];
      this.aggrid.refreshaggrid(this.segmaecompaniaService.detInfoGuiaRemArray[this.indicador], this.defaultSegInfGuiaRem.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultSegInfGuiaRem.objeto].setFocusedCell(
        this.segmaecompaniaService.detInfoGuiaRemArray[this.indicador].length - 1, 'INFAD_CODIGO');
    } else {
      this.segmaecompaniaService.detInfoGuiaRemArray[this.indicador].splice(indi + 1, 0, item);
      this.aggrid.refreshaggridindex(this.segmaecompaniaService.detInfoGuiaRemArray[this.indicador], this.defaultSegInfGuiaRem.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultSegInfGuiaRem.objeto].setFocusedCell(
        indi + 1, 'INFAD_CODIGO');
    }

  }

  async eliminarInfoGuiaRem() {
    try {

      await this.segmaecompaniaService.eliminarDetInfoAdiGuiaRem('01', Number(this.detalleInfoGuiaRemSel.INFAD_NUMSEC));

      this.auditoriaGralService.registrarAuditoria('SEG_INFADIGRE', this.detalleInfoGuiaRemSel.INFAD_CODIGO, 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
      this.segmaecompaniaService.detInfoGuiaRemArray[this.indicador].splice(this.segmaecompaniaService.detInfoGuiaRemArray[this.indicador].findIndex(
        item => item.INFAD_CODIGO === this.detalleInfoGuiaRemSel.INFAD_CODIGO && item.INFAD_NUMSEC === this.detalleInfoGuiaRemSel.INFAD_NUMSEC),
        1);
      this.aggrid.refreshaggrid(this.segmaecompaniaService.detInfoGuiaRemArray[this.indicador], this.defaultSegInfGuiaRem.objeto);
      this.mensajeAlerta('success', 'Información', 'Registro eliminado exitosamente...!!!');

    } catch (err) {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso eliminar  no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }

  async mostrarDatoAdiLiq() {
    const detalleInfoLiqCom: SegInfadicional[] = [];
    try {
      const rs = await this.segmaecompaniaService.listarInfoAdiLiqCom();
      this.segmaecompaniaService.detInfoLiqComArray[this.indicador] = [];
      if (rs !== null) {
        for (const fact of rs) {
          const item: SegInfadicional = {};
          item.INFAD_NUMSEC = fact.INFAD_NUMSEC;
          item.INFAD_CODIGO = fact.INFAD_CODIGO;
          item.COM_CODIGO = fact.COM_CODIGO;
          item.INFAD_DESCRIPCION = fact.INFAD_DESCRIPCION;
          detalleInfoLiqCom.push(item);
        }
        this.segmaecompaniaService.detInfoLiqComArray[this.indicador] = detalleInfoLiqCom;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  }

  seleccionDetInfoLiqCom(valor) {
    if (valor === 'nuevo') {
      this.nuevoElementoInfoLiqCom();

    }
    if (valor === 'eliminar') {
      if (Object.keys(this.detalleInfoLiqComSel).length > 0) {
        this.confirmationService.confirm({
          message: '¿ Está seguro de eliminar el registro ?',
          header: 'Confirmación',
          icon: 'pi pi-exclamation-triangle',
          key: 'compania',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.eliminarInfoLiqCom();
          },
          reject: () => {
          }
        });
      } else {
        this.mensajeAlerta('error', 'Error', '! Seleccione una fila.... !');
      }

    }
    if (valor === 'cancelar') {
      this.mostrarDatoAdiLiq();
    }

    if (valor.object !== undefined) {
      this.detalleInfoLiqComSel = valor.object;
    }
  }

  nuevoElementoInfoLiqCom() {
    const item: SegInfadicional = {};
    item.COM_CODIGO = '01';
    item.INFAD_CODIGO = '';
    item.INFAD_DESCRIPCION = '';
    item.INFAD_NUMSEC = 0;

    const indi = this.segmaecompaniaService.detInfoLiqComArray[this.indicador].indexOf(this.detalleInfoLiqComSel);
    if (indi === -1) {
      this.segmaecompaniaService.detInfoLiqComArray[this.indicador].push(item);
      this.detalleInfoLiqComSel = this.segmaecompaniaService.detInfoLiqComArray[this.indicador]
      [this.segmaecompaniaService.detInfoLiqComArray[this.indicador].length - 1];
      this.aggrid.refreshaggrid(this.segmaecompaniaService.detInfoLiqComArray[this.indicador], this.defaultSegInfLiqCom.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultSegInfLiqCom.objeto].setFocusedCell(
        this.segmaecompaniaService.detInfoLiqComArray[this.indicador].length - 1, 'INFAD_CODIGO');
    } else {
      this.segmaecompaniaService.detInfoLiqComArray[this.indicador].splice(indi + 1, 0, item);
      this.aggrid.refreshaggridindex(this.segmaecompaniaService.detInfoLiqComArray[this.indicador], this.defaultSegInfLiqCom.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultSegInfLiqCom.objeto].setFocusedCell(
        indi + 1, 'INFAD_CODIGO');
    }

  }

  async eliminarInfoLiqCom() {
    try {

      await this.segmaecompaniaService.eliminarDetInfoAdiLiqCom('01', Number(this.detalleInfoLiqComSel.INFAD_NUMSEC));

      this.auditoriaGralService.registrarAuditoria('SEG_INFADILIQ', this.detalleInfoGuiaRemSel.INFAD_CODIGO, 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
      this.segmaecompaniaService.detInfoLiqComArray[this.indicador].splice(this.segmaecompaniaService.detInfoLiqComArray[this.indicador].findIndex(
        item => item.INFAD_CODIGO === this.detalleInfoLiqComSel.INFAD_CODIGO && item.INFAD_NUMSEC === this.detalleInfoLiqComSel.INFAD_NUMSEC),
        1);
      this.aggrid.refreshaggrid(this.segmaecompaniaService.detInfoLiqComArray[this.indicador], this.defaultSegInfLiqCom.objeto);
      this.mensajeAlerta('success', 'Información', 'Registro eliminado exitosamente...!!!');

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso eliminar  no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }

  cambiarFoco(evento) {
    if (evento.target.id === 'rucCompania') {
      document.getElementById('razonSocial').focus();
    }

    if (evento.target.id === 'razonSocial') {
      document.getElementById('nombreComercial').focus();
    }
    if (evento.target.id === 'nombreComercial') {
      document.getElementById('cmbProvincia').focus();
    }
    if (evento.target.id === 'cmbProvincia') {
      document.getElementById('direccion').focus();
    }
    if (evento.target.id === 'direccion') {
      document.getElementById('telefono1').focus();
    }
    if (evento.target.id === 'telefono1') {
      document.getElementById('telefono2').focus();
    }
    if (evento.target.id === 'telefono2') {
      document.getElementById('correo').focus();
    }
    if (evento.target.id === 'correo') {
      document.getElementById('autoRetencion').focus();
    }
    if (evento.target.id === 'autoRetencion') {
      document.getElementById('numEstab').focus();
    }
    if (evento.target.id === 'numEstab') {
      document.getElementById('establecimiento').focus();
    }
    if (evento.target.id === 'establecimiento') {
      document.getElementById('puntoventa').focus();
    }
    if (evento.target.id === 'puntoventa') {
      document.getElementById('contriEsp').focus();
    }
    if (evento.target.id === 'contriEsp') {
      document.getElementById('llevaContabilidad').focus();
    }
    if (evento.target.id === 'llevaContabilidad') {
      document.getElementById('idRepLegal').focus();
    }
    if (evento.target.id === 'idRepLegal') {
      document.getElementById('nomRepLegal').focus();
    }
    if (evento.target.id === 'nomRepLegal') {
      document.getElementById('rucContador').focus();
    }
    if (evento.target.id === 'rucContador') {
      document.getElementById('nomContador').focus();
    }

    if (evento.target.id === 'cmbTipoAmbiente') {
      document.getElementById('correoFact').focus();
    }
    if (evento.target.id === 'correoFact') {
      document.getElementById('correoNotaCred').focus();
    }
    if (evento.target.id === 'correoNotaCred') {
      document.getElementById('correoGuiaRem').focus();
    }
    if (evento.target.id === 'correoGuiaRem') {
      document.getElementById('correoNotaDeb').focus();
    }
    if (evento.target.id === 'correoNotaDeb') {
      document.getElementById('correoReten').focus();
    }
    if (evento.target.id === 'correoReten') {
      document.getElementById('correoLiqCom').focus();
    }
  }


  onBasicUpload(event) {
    const fileReader = new FileReader();
    for (const file of event.files) {
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        localStorage.setItem('imgblob', fileReader.result + '');
      };
    }
  }
  async onBasicUploadP(event) {
    console.log(event);
    console.log(event.files)
    this.documentName = event.files[0].name;
    this.documentSize = event.files[0].size;
    this.documentType = this.documentName.split('.').slice(-1);
   
    this.documentbase64 = await this.readfile2(event.files[0]);
    console.log(this.documentbase64);
    console.log(this.documentName);
    console.log(this.documentType);
  }
  readfile2(event){
    return new Promise((res,rej)=>{
      let inp=event;
      let reader =new FileReader();
      reader.onload= function(e){
        res(reader.result);
      };
      reader.readAsBinaryString(inp);
     
    });

  }
  onRemove(event){
    this.documentName = '';
    this.documentSize = 0;
    this.documentType = '';
    this.documentbase64 = '';
  }
  async uploadFirma() {
    if (this.documentName === '' || this.documentbase64 === '') {
      //this.mensajeAlerta('warn','Mensaje', 'Seleccione un archivo');  
      this.messageService.add({
        key: 'compania',
        severity: 'warn',
        summary: 'Información',
        detail: 'Seleccione un archivo.'
      });
    } else{
      this.documentName=this.txtRuc;
      if (String(this.documentType).toLowerCase() !== 'p12' ) {
        //this.mensajeAlerta('warn', 'Mensaje', 'Tipo de archivo no admitido');
        this.messageService.add({
          key: 'compania',
          severity: 'warn',
          summary: 'Información',
          detail: 'Tipo de archivo no admitido.'
        });
        return;
      }
      const tamKB = this.documentSize/1024;
      const tamMB = tamKB/1024;
      console.log('tamMB',tamMB)
      if(tamMB >= 1){
        this.messageService.add({
          key: 'compania',
          severity: 'warn',
          summary: 'Información',
          detail: 'El tamaño del archivo no puede ser mayor a 1 MB.'
        });
        return;
      }
      if(this.txtpwd1 === null || this.txtpwd1 === undefined || String(this.txtpwd1).trim() === ''){
        this.messageService.add({
          key: 'compania',
          severity: 'warn',
          summary: 'Información',
          detail: 'El campo contraseña no puede estar vacío.'
        });
        return;
      }
      if(String(this.txtpwd1).trim() !== String(this.txtpwd2).trim()){
        this.messageService.add({
          key: 'compania',
          severity: 'warn',
          summary: 'Información',
          detail: 'Las contraseñas no coinciden.'
        });
        return;
      }
      try {
        const res: any = await this.segmaecompaniaService.uploadFirma(this.documentbase64, this.documentName);
        await this.segmaecompaniaService.actualizapwd(String(this.txtpwd1).trim());
        this.messageService.add({
          key: 'compania',
          severity: 'success',
          summary: 'Información',
          detail: 'Firma y contraseña guardadas exitosamente.'
        });
      } catch (error) {
        console.log(error)
      }
    }
  }
  cargar() {
    this.imgBlob = localStorage.getItem('imgblob');
    this.utilitariosService.updateLogoCompañia(this.imgBlob);
    this.utilitariosService.updateLogotipo(this.imgBlob, this.txtRuc);
    this.messageService.add({
      key: 'compania',
      severity: 'success',
      summary: 'Información',
      detail: 'Logo actualizado correctamente.'
    });
    this.strLogoCom = this.imgBlob;
  }

  actrutalogo() {
    this.utilitariosService.updateRutaLogo(this.rutalogo);
    this.messageService.add({
      key: 'compania',
      severity: 'success',
      summary: 'Información',
      detail: 'Logo de Reportes actualizado correctamente.'
    });
  }

  async calcularMegas() {
    let tamanio = 0;
    try {
      const rs = await this.segmaecompaniaService.obtenerMegasUitilizadas();
      tamanio = rs[0].TAMANIO;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', err.error);
    }

    return tamanio;
  }

  async activacion(event) {
    const rs = await this.confIniciales.generaactivacionfe();
    const blob = new Blob([rs.return], { type: 'text/plain' });
    saveAs(blob, this.usuarioEsquema.usuario + '.txt');
  }

  async correo(event) {
    let ruc;
    let empresa;
    const data = await this.confIniciales.getRuc();
    for (const rs of data) {
      ruc = rs.COMRUCI;
      empresa = rs.COMNOMBRE;
    }

    const conforma = await this.confIniciales.generaactivacionfe();
    //GSRF
    console.log('fe---->',ruc)
    // if(ruc === '1708190911001' || ruc === '170819091100S' 
    // ||ruc === '099215449700F' ||ruc === '1721288221001' 
    // || ruc==='1712117975001' || ruc==='1793143865001'
    // || ruc==='1708190911001' || ruc==='1706076104001'
    // || ruc ==='1704598513001' || ruc ==='2290352080001'
    // || ruc ==='1793168833001' || ruc ==='0925395733001'
    // || ruc ==='1791856910001' || ruc ==='1791323548001'
    // || ruc ==='1792326222001' || ruc ==='1793031331001'
    // || ruc ==='0993373823001' || ruc ==='1792155495001'
    // || ruc ==='1710095108001' || ruc ==='1792088674001'
    // || ruc ==='0992149213001' || ruc ==='1793082025001'
    // || ruc=== '1793095518001' || ruc=== '1801982636001'
    // || ruc=== '1792014778001' || ruc=== '0993252921001'
    // || ruc=== '1792581532001' || ruc=== '1002157871001'
    // || ruc=== '1792363640001' || ruc=== '1792506816001'
    // || ruc=== '1792028272001' || ruc=== '1791264169001'
    // || ruc=== '1706728944001' || ruc=== '1714402490001'
    // || ruc=== '0992937238001' || ruc=== '1791050487001'
    // || ruc=== '1792788862002' || ruc=== '1793203069001'
    // || ruc=== '1792902630001' || ruc=== '0890039278001'
    // || ruc=== '0992869844001' || ruc=== '0993378980001'
    // || ruc=== '0992306122001' || ruc=== '0990337640001'
    // || ruc=== '0990871434001' || ruc=== '0992622296001'
    // || ruc=== '0992629991001' || ruc=== '0992391944001'
    // || ruc=== '0992760648001' || ruc=== '0992154497001'
    // || ruc=== '0991448284001' ){
    const essaci = await this.utilitariosService.esmarcasaci();
    if(essaci === true){
      this.utilitariosService.enviarCorreoActivacionawsfe(ruc, empresa, conforma.return);
      this.messageService.add({
        key: 'compania',
        severity: 'success',
        summary: 'Correo enviado',
        detail: 'El correo de activación ha sido enviado a \'sacierpactivaciones@gmail.com\''
      });
    }else{
      this.utilitariosService.enviarCorreoActivacion(ruc, empresa, conforma.return);
      this.messageService.add({
        key: 'compania',
        severity: 'success',
        summary: 'Correo enviado',
        detail: 'El correo de activación ha sido enviado a \'sacierpactivaciones@gmail.com\''
      }); 
    }
    //GSRF
  }

  async check(event) {
    this.claveAuth = '';
    this.autorizacionCodigo = 'AUTCONFE';
    this.autorizacionCodigoAux = 'CHECK';
    this.bolAutorizacion = false;
    this.displayDialogAutorizacion = true;

  }

  equis(event) {
    this.claveAuth = '';
    this.autorizacionCodigo = 'AUTCONFE';
    this.autorizacionCodigoAux = 'EQUIS';
    this.bolAutorizacion = false;
    this.displayDialogAutorizacion = true;
  }

  comprobarClave() {
    this.segmaecompaniaService.obtenerClave(this.autorizacionCodigo).subscribe((clave) => {
      let claveReal;
      claveReal = clave[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        this.bolAutorizacion = true;
        this.accionesAutorizacion();
      } else if (clave !== this.claveAuth) {
        this.messageService.add({
          key: 'autorizacion',
          severity: 'error',
          summary: 'Clave Autorización',
          detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
        });
      }
    });
  }
  //CDPJ
  comprobarClaveRuc() {
    this.segmaecompaniaService.obtenerClave(this.autorizacionCodigoRuc).subscribe((clave) => {
      let claveReal;
      claveReal = clave[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        this.bolAutorizacion = true;
        this.accionesAutorizacionRuc();
      } else if (clave !== this.claveAuth) {
        this.messageService.add({
          key: 'autorizacion',
          severity: 'error',
          summary: 'Clave Autorización',
          detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
        });
      }
    });
  }
  //CDPJ
//CDPJ
async accionesAutorizacionRuc() {
  if (this.permisos != null) {
    if (this.permisos.PERTODO === 0) {
      if (this.permisos.PERACTUALIZACION === 0) {
        this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
          ' , no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
        return;
      }
    }
  }
  console.log('1')
  if (this.autorizacionCodigoRuc === 'AUTRUCID') {
    let intBanderaControl = 0;
    let intControlCedula = 0;
    const codigoCpia = this.txtCodigo;
    const ruc = this.txtRuc;
    const rucContador = this.txtRucContador;
    const ciCoordinador = this.txtCICoordinador + '001';
    const razonSoc = this.txtRazSoc;
    const nombreCorto = this.txtNombreCorto;
    const nroEstablecimiento = this.txtEstablecimiento;
    const nroPuntoVenta = this.txtPuntoVenta;
    let strTipoAmbiente = '';
    let strFacElecAct = '';
    let strProdAct = '';
    let strSubsidio = '';
    let strTransporte = '';
    let strConRegMic = '';
    let strAgenteret = '';
    let strConexion = '';
    let strPing = '';
    let strConRimpe = '';
    let strConRimpe_NP='';
    if (ruc.length === 0 || razonSoc.length === 0 || nombreCorto.length === 0) {
      this.mensajeAlerta('eror', 'Error', 'Campos requeridos.');
      return;
    }
    if (this.chkUsarPingAcceso === true) {
      if (String(this.txtCorreoAcceso).trim() === '' || this.txtTelefonoAcceso === undefined ||
        String(this.txtTelefonoAcceso).trim() === '' || this.txtTelefonoAcceso === undefined) {
        this.mensajeAlerta('info', 'PIN de Acceso', 'El correo y teléfono celular son obligatorios');
        return;
      }
    }

    this.btnGuardar = true;
    if (nroEstablecimiento.length === 3 && nroPuntoVenta.length === 3) {
      this.nroSerieRet = nroEstablecimiento.concat(nroPuntoVenta);
    } else {
      this.mensajeAlerta('error', 'Error número de dígitos', 'No actualizó correctamente, no menos de 3 dígitos para Establecimiento o Punto de Venta');
      intBanderaControl = 1;
      // btnCancelar.setEnabled(true);
    }
 
    this.codProvincia = this.strCmbProvin.REF_CODIGO;
    try {
      if (this.txtTelf1.length === 0 || this.txtTelf2.length === 0) {
        this.txtTelf1 = '';
        this.txtTelf2 = '';
      } else if (this.txtTelf1.length < 9 || this.txtTelf2.length < 9) {
        this.mensajeAlerta('error', 'Error número de dígitos', 'No ingresó correctamente número de teléfono');
        intBanderaControl = 1;
        // btnCancelar.setEnabled(true);
      }
      
    if (this.txtAutRet.length < 1) {
      this.mensajeAlerta('error', 'Error número de dígitos', 'La autorización de la retención es incorrecta');
      intBanderaControl = 1;
      // btnCancelar.setEnabled(true);
    }

    const cedula = ruc.substring(0, 10);
    this.tipo = this.txtTipoId;
    if (this.tipo === 'c') {
      const cedCorrecta = this.ValidaCedula(cedula);

      if (cedCorrecta === 1) {
        this.mensajeAlerta('info', 'Validación Ruc', 'Cédula correcta');
        intBanderaControl = 0;
      } else {
        this.mensajeAlerta('info', 'Validación Ruc', 'Cédula Incorrecta');
        intBanderaControl = 1;
      }
    } 
      
      // const rucContadorCorrecto = this.ValidaRuc(rucContador);
      // if (rucContadorCorrecto === 0) {
      //   this.mensajeAlerta('info', 'Validación Ruc', 'Ruc del Contador Incorrecto');
      //   intBanderaControl = 1;
      // }
      // const ciCoordinadorCorrecto = this.ValidaRuc(ciCoordinador);
      // if (ciCoordinadorCorrecto === 0) {
      //   this.mensajeAlerta('info', 'Validación IDE', 'IDE del Representante Incorrecto');
      //   intControlCedula = 1;
      // }

      strConexion = '';
      if (this.chkAceptarConexiones === true) {
        strConexion = '1';
      }

      strPing = '';
      if (this.chkUsarPingAcceso === true) {
        strPing = '1';
      } else {
        this.txtCorreoAcceso = '';
        this.txtTelefonoAcceso = '';
      }

      if (this.chkUsarPingAcceso === true && (String(this.txtCorreoAcceso).trim() === '' || String(this.txtTelefonoAcceso).trim() === '')) {
        this.mensajeAlerta('info', 'PIN de Acceso', 'Ingrese el correo y el teléfono celular del PIN de acceso');
        return;
      }

      strTipoAmbiente = '';
      if (this.strCmbTipoAmbiente.name === 'Pruebas') {
        strTipoAmbiente = '1';
      }
      if (this.strCmbTipoAmbiente.name === 'Producción') {
        strTipoAmbiente = '2';
      }

      strFacElecAct = '';
      strProdAct = '';
      strSubsidio = '';
      strTransporte = '';

      if (this.chkbtnFacElecAct === true) {
        strFacElecAct = '1';
      }
      if (this.chkbtnProdAct === true) {
        strProdAct = '1';
      }
      if (this.chkbtnSubsidio === true) {
        strSubsidio = '1';
      }

      if (this.chkbtnTransporte === true) {
        strTransporte = '1';
      }

      console.log('2');
      console.log('3',this.nroSerieRet);
      console.log('4',this.rucCorrecto);
      console.log('5',this.txtAutRet.length);
      console.log('6',intBanderaControl);
      console.log('7',intControlCedula);
      this.rucCorrecto = 0;
      if (this.nroSerieRet !== null && this.rucCorrecto === 0 // && this.txtTelf1.length === 9 && this.txtTelf2.length === 9
        && this.txtAutRet.length > 1 && intBanderaControl === 0 && intControlCedula === 0) {

        if (this.strTipoAmbienteFE === '1' && strTipoAmbiente === '2') {   // si esta en producción
          this.confirmationService.confirm({
            message: '¿ Está seguro de cambiar el ambiente de pruebas a producción ?',
            header: 'Pregunta',
            icon: 'pi pi-exclamation-triangle',
            key: 'compania',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            accept: () => {
              this.EliminarDocumentosPruebaFE();
              if (this.chkbtnCONREGMIC === true) {
                strConRegMic = '1';
              }
              if (this.chkbtnAgenteret === true) {
                strAgenteret = '1';
              }
              // if (this.chkbtnCONRIMPE === true) {
              //   strConRimpe = '1';
              // }
                //CDPJ
                if(this.selectregimen.codigo === 'regemp'){
                  strConRimpe='1';
                  strConRimpe_NP='0';
                 }else if( this.selectregimen.codigo === 'regnp'){
                  strConRimpe='0';
                  strConRimpe_NP='1';
                 } else if( this.selectregimen.codigo === ''){
                  strConRimpe='';
                  strConRimpe_NP='';
                 };
                 //CDPJ

              this.actualizarCom(codigoCpia, ruc, razonSoc, this.txtDirecc, this.txtTelf1,
                this.txtTelf2, this.txtFax, this.txtRepreLegal, this.txtEmail,
                this.codProvincia, this.txtLocalReport, this.txtAutRet, this.nroSerieRet, nombreCorto, this.txtCoordinador,
                this.txtCICoordinador, this.txtContador, this.txtRucContador, this.txtLocalFormulario,
                nombreCorto, Number(this.txtNumeroEst), this.txtLocalizacionLogo1, this.txtLocalizacionLogo2, this.txtLocalizacionArchivo,
                strTipoAmbiente, strFacElecAct, strProdAct, this.txtConEspecial, this.strCmbObligadoCont.name, this.txtUbicacionArchivos,
                this.txtCorreoFacturas, this.txtCorreoNotasCredito, this.txtCorreoGuiasRemision, this.txtCorreoNotasDebito, this.txtCorreoRetenciones,
                this.txtLocalizacionLog, strSubsidio, this.txtUbicacionXMLAut, strTransporte, this.txtCorreoLiquidaciones, strConRegMic, strAgenteret,
                strConexion, strPing, this.txtCorreoAcceso, this.txtTelefonoAcceso, this.txtresolucion, strConRimpe,strConRimpe_NP);

              this.strTipoAmbienteFE = strTipoAmbiente;
            },
            reject: () => {
              return;
            }
          });

        } else {
          if (this.chkbtnCONREGMIC === true) {
            strConRegMic = '1';
          }
          if (this.chkbtnAgenteret === true) {
            strAgenteret = '1';
          }
          // if (this.chkbtnCONRIMPE === true) {
          //   strConRimpe = '1';
          // }
            //CDPJ
            if(this.selectregimen.codigo === 'regemp'){
              strConRimpe='1';
              strConRimpe_NP='0';
             }else if( this.selectregimen.codigo === 'regnp'){
              strConRimpe='0';
              strConRimpe_NP='1';
             } else if( this.selectregimen.codigo === ''){
              strConRimpe='';
              strConRimpe_NP='';
             };
             //CDPJ

          this.actualizarCom(codigoCpia, ruc, razonSoc, this.txtDirecc, this.txtTelf1,
            this.txtTelf2, this.txtFax, this.txtRepreLegal, this.txtEmail,
            this.codProvincia, this.txtLocalReport, this.txtAutRet, this.nroSerieRet, nombreCorto, this.txtCoordinador,
            this.txtCICoordinador, this.txtContador, this.txtRucContador, this.txtLocalFormulario,
            nombreCorto, Number(this.txtNumeroEst), this.txtLocalizacionLogo1, this.txtLocalizacionLogo2, this.txtLocalizacionArchivo,
            strTipoAmbiente, strFacElecAct, strProdAct, this.txtConEspecial, this.strCmbObligadoCont.name, this.txtUbicacionArchivos,
            this.txtCorreoFacturas, this.txtCorreoNotasCredito, this.txtCorreoGuiasRemision, this.txtCorreoNotasDebito, this.txtCorreoRetenciones,
            this.txtLocalizacionLog, strSubsidio, this.txtUbicacionXMLAut, strTransporte, this.txtCorreoLiquidaciones, strConRegMic, strAgenteret,
            strConexion, strPing, this.txtCorreoAcceso, this.txtTelefonoAcceso, this.txtresolucion, strConRimpe,strConRimpe_NP);

          this.strTipoAmbienteFE = strTipoAmbiente;
          console.log('1')
        }

      } else {
        // btnCancelar.setEnabled(true);
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se actualizó correctamente ' + mensaje);
      return;
    }
        
     
  }
  this.displayDialogAutorizacionRuc = false;
}
onHideAutorizacionRuc() {
  if (this.bolAutorizacion === false) {
    if (this.autorizacionCodigoRuc === 'AUTRUCID') {
      this.messageService.add({
        key: 'compania',
        severity: 'warn',
        summary: 'Activación',
        detail: 'Debe ingresar correctamente la clave de autorización.'
      });
    }
  }
}
  async accionesAutorizacion() {
    if (this.autorizacionCodigo === 'AUTCONFE') {
      if (this.autorizacionCodigoAux === 'EQUIS') {
        this.auditoriaGralService.registrarAuditoria('SEG_MAECOMPANIA', this.txtCodigo + '/ELIM-AUT-COM', 'I',
          this.usuario.identificacion, '01', '', '', '', '').subscribe();
        this.txtConformacionsetEnabled = true;
      } else {
        ///GSRF
        try {
          await this.confIniciales.actualizardatosactivacion(this.txtConformacion);
          this.messageService.add({
            key: 'compania',
            severity: 'success',
            summary: 'Activación',
            detail: 'La activación se ha realizado correctamente'
          });
          this.auditoriaGralService.registrarAuditoria('SEG_MAECOMPANIA', this.txtCodigo + '/CONF-AUT-COM', 'I',
            this.usuario.identificacion, '01', '', '', '', '').subscribe();
          this.txtConformacionsetEnabled = false;
          this.cancelar();
          } catch (error) {
            this.displayDialogAutorizacion = false;
            this.messageService.add({
              key: 'compania',
              severity: 'error',
              summary: 'Activación',
              detail: 'Activación incorrecta'
            });
          }
  
          ///GSRF
      }
    }
    this.displayDialogAutorizacion = false;
  }

  onHideAutorizacion() {
    if (this.bolAutorizacion === false) {
      if (this.autorizacionCodigo === 'AUTCONFE') {
        this.messageService.add({
          key: 'compania',
          severity: 'warn',
          summary: 'Activación',
          detail: 'Debe ingresar correctamente la clave de autorización.'
        });
      }
    }
  }
    //GSRF
  seleccionDetInfoDevFac(valor) {
    if (valor === 'nuevo') {
      this.nuevoElementoInfodevfac();

    }
    if (valor === 'eliminar') {
      if (Object.keys(this.detalleInfodevfacSel).length > 0) {
        this.confirmationService.confirm({
          message: '¿ Está seguro de eliminar el registro ?',
          header: 'Confirmación',
          icon: 'pi pi-exclamation-triangle',
          key: 'compania',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.eliminarInfodevfac();
          },
          reject: () => {
          }
        });
      } else {
        this.mensajeAlerta('error', 'Error', '! Seleccione una fila.... !');
      }

    }
    if (valor === 'cancelar') {
      this.mostrarDatoAdidevfac();
    }

    if (valor.object !== undefined) {
      this.detalleInfodevfacSel = valor.object;
    }
  }

  nuevoElementoInfodevfac() {
    const item: SegInfadicional = {};
    item.COM_CODIGO = '01';
    item.INFAD_CODIGO = '';
    item.INFAD_DESCRIPCION = '';
    item.INFAD_NUMSEC = 0;

    const indi = this.segmaecompaniaService.detInfoDevfacArray[this.indicador].indexOf(this.detalleInfodevfacSel);
    if (indi === -1) {
      this.segmaecompaniaService.detInfoDevfacArray[this.indicador].push(item);
      this.detalleInfoLiqComSel = this.segmaecompaniaService.detInfoDevfacArray[this.indicador]
      [this.segmaecompaniaService.detInfoDevfacArray[this.indicador].length - 1];
      this.aggrid.refreshaggrid(this.segmaecompaniaService.detInfoDevfacArray[this.indicador], this.defaultSegInfdevfac.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultSegInfdevfac.objeto].setFocusedCell(
        this.segmaecompaniaService.detInfoDevfacArray[this.indicador].length - 1, 'INFAD_CODIGO');
    } else {
      this.segmaecompaniaService.detInfoDevfacArray[this.indicador].splice(indi + 1, 0, item);
      this.aggrid.refreshaggridindex(this.segmaecompaniaService.detInfoDevfacArray[this.indicador], this.defaultSegInfdevfac.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultSegInfdevfac.objeto].setFocusedCell(
        indi + 1, 'INFAD_CODIGO');
    }

  }

  async mostrarDatoAdidevfac() {
    const detalleInfodevfac: SegInfadicional[] = [];
    try {
      const rs = await this.segmaecompaniaService.listarInfoAdidevfac();
      this.segmaecompaniaService.detInfoDevfacArray[this.indicador] = [];
      if (rs !== null) {
        for (const fact of rs) {
          const item: SegInfadicional = {};
          item.INFAD_NUMSEC = fact.INFAD_NUMSEC;
          item.INFAD_CODIGO = fact.INFAD_CODIGO;
          item.COM_CODIGO = fact.COM_CODIGO;
          item.INFAD_DESCRIPCION = fact.INFAD_DESCRIPCION;
          detalleInfodevfac.push(item);
        }
        this.segmaecompaniaService.detInfoDevfacArray[this.indicador] = detalleInfodevfac;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  }

  async eliminarInfodevfac() {
    try {

      await this.segmaecompaniaService.eliminarDetInfoAdidevfac('01', Number(this.detalleInfodevfacSel.INFAD_NUMSEC));

      this.auditoriaGralService.registrarAuditoria('SEG_INFADIDEVFAC', this.detalleInfodevfacSel.INFAD_CODIGO, 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
      this.segmaecompaniaService.detInfoDevfacArray[this.indicador].splice(this.segmaecompaniaService.detInfoDevfacArray[this.indicador].findIndex(
        item => item.INFAD_CODIGO === this.detalleInfodevfacSel.INFAD_CODIGO && item.INFAD_NUMSEC === this.detalleInfodevfacSel.INFAD_NUMSEC),
        1);
      this.aggrid.refreshaggrid(this.segmaecompaniaService.detInfoDevfacArray[this.indicador], this.defaultSegInfdevfac.objeto);
      this.mensajeAlerta('success', 'Información', 'Registro eliminado exitosamente...!!!');

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso eliminar  no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }

  //GSRF
}
