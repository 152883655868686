<p-toast [style]="{marginTop: '30px'}" position="top-center" key="venmaeProvtoast"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="venmaeProvDialog" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 99%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
			[botonRegresar]="btnRegresar" [barraBotonesCRUD]="false"  
			[barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true"
																	 
										
			(signal)="manejarSenales($event)"></app-invmaebarra>
	</div>
				
</div>
<div style="overflow: hidden" class="p-col-12">
	<p-tabView (onChange)="onTab1Change($event)" [(activeIndex)]="indexTab">
		<p-tabPanel header="Provincias">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="venmaeprovincias"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefVenmaeprovincias"
							[defaultColDef]="defaultColDefVenmaeprovincias"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)=selectVenmaeprovincias($event)
							(cambios)="cambioModulo($event)"

			></app-aggridsaci>
		</p-tabPanel>
		<p-tabPanel header="Cantones" [disabled]="disabledTab1">
			<div class="divgrups"  style="height: 50px;" *ngIf="visibleGrid === true">
				<div class="p-grid" style="margin-top: 3px; margin-bottom: 5px">
					<div class="p-col-5">
						<span><strong>Provincia:</strong> {{selectedVenmaeprovincias.PROV_NOMBRE}} </span>
						<span style="margin-left: 5px;"><strong>Código: {{selectedVenmaeprovincias.PROV_CODIGO}}</strong> </span>
					</div>
				</div>
			</div>
			<app-aggridsaci *ngIf="visibleGrid === true" [width]=""
							[height]="largot"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="venmaecanton"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefVenmaeCantones"
							[defaultColDef]="defaultColDefVenmaecanton"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)="selectMaeciudad($event)"
							(cambios)="cambioCiudad($event)"

			></app-aggridsaci>
		</p-tabPanel>
		<p-tabPanel header="Parroquias" [disabled]="disabledTab2">
			<div class="divgrups" style="height: 62px;" *ngIf="visibleGrid2 === true">
				<div class="p-grid" style="margin-top: 0px; margin-bottom: 0px">
					<div class="p-col-7" style="margin-top: 0px; margin-bottom: 0px;padding: 0;">
						<span><strong> Provincia: </strong> {{selectedVenmaeprovincias.PROV_NOMBRE}} </span>
						<span  style="margin-left: 5px;"><strong> Código: </strong> {{selectedVenmaeprovincias.PROV_CODIGO}}</span>
					</div>
					<div  class="p-col-7" style="margin-top: 0px; margin-bottom: 0px;padding: 0;">
						<span><strong>Cantón: </strong> {{selectedVenmaecanton.CAN_DESCRIPCION}}</span>	
						<span style="margin-left: 5px;"><strong> Código:</strong> {{selectedVenmaecanton.CAN_CODIGO}}</span>
					</div>
				</div>
			</div>
			<app-aggridsaci #tabla *ngIf="visibleGrid2 === true" [width]=""
							[height]="largotb"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="venmaeparroquia"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefVenParroquias"
							[defaultColDef]="defaultColDefVenmaeparroquias"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)="selectMaeParroquia($event)"
							(cambios)="cambioParroquia($event)"

			></app-aggridsaci>
		</p-tabPanel>
	</p-tabView>
</div>