import { Component, OnInit, Input } from '@angular/core';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { MessageService } from 'primeng';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { NomexpliqubenesocarchtxtService } from '../nomservicios/nomexpliqubenesocarchtxt.service';

@Component({
  selector: 'app-nomexpliqubenesocarchtxt',
  templateUrl: './nomexpliqubenesocarchtxt.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NomexpliqubenesocarchtxtComponent implements OnInit {
  @Input() codigo:string;
  cmbFormato: any[] = [];
  selectFormato: any;
  
  cargarCombo= false;

  separacion = ',';
  btnVisto = true;
  nombreTxt = '';
  constructor(private init: NuevoComponentService,
    private archtxtService: NomexpliqubenesocarchtxtService,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService) { }

  ngOnInit(): void {
    this.cmbFormato.push({COM_CODIGO:'',MAEEXDAARCHPLAN_CODIGO:'',
    MAEEXDAARCHPLAN_NOMBRE:'',MAEEXDAARCHPLAN_PROCESO:''})
    this.archtxtService.listamaestrocargas().then((res:any)=>{
      console.log(res);
      for(let a of res){
        this.cmbFormato.push(a);
      };
      this.cargarCombo=true;
    })
  }
  async manejarSenales(valor){
    switch (valor) {
      case 'Salir':
       this.init.tabs.splice(this.init.tabindex, 1);
      break;
      case 'Visto':
        console.log(this.selectFormato)
        if(this.selectFormato.MAEEXDAARCHPLAN_NOMBRE !='' && this.selectFormato.MAEEXDAARCHPLAN_CODIGO !='' && this.nombreTxt!="" ){ 
            console.log('entra'); 
            //this.buscarArchCodigo();
             //this.archtxtService.ejecmaestrocargas
            const ar=await this.archtxtService.ejecmaestrocargas(this.selectFormato.MAEEXDAARCHPLAN_PROCESO,this.codigo);
            console.log(ar)
            const ge:any=await this.archtxtService.funGenArchTexto(this.codigo);
            console.log(ge);
            if( ge !== null ){
        
              let texto = '';
      
                for( let linea of ge ){
                  texto = texto + linea.TMPLIBESOARCHTEXT_TEXTO + '\r\n';
                }
              console.log(texto);
              this.download( this.nombreTxt, texto );
      
            }else {
              this.mensajeAlerta('error', 'Error', 'No se ha encontrado información para generar el archivo');
            }
          } else {
          this.mensajeAlerta('error', 'Error', 'Seleccione un reporte');
        } 
      break;
      default:
      break;
    }
  }
  seleccionarFormato(elemento){
    console.log(elemento)
  }
  download(filename, text) {
    if( filename === '' ){
      this.mensajeAlerta('error', 'Error', 'Ingrese un nombre de archivo');
    } else {

      const blob = new Blob([text], {type: 'text/plain'});
      const element = document.createElement('a');
      element.setAttribute('download', filename);
      element.setAttribute('href', window.URL.createObjectURL(blob));
      element.click();
    }
  }
  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'archTexto',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
  }
}
