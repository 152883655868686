import { Injectable } from '@angular/core';
import {InvCodigoAlterno} from '../invinterfaces/invcodigoalterno';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {HttpClient} from '@angular/common/http';
import {Usuario} from '../../usuario';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {DatePipe} from '@angular/common';
import {MessageService} from 'primeng';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class InvtrndatosaltService {
  
  private url: string;
  public way: string;
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private init: NuevoComponentService,
              private confIniciales: ConfInicialesService,
              private datePipe: DatePipe, private messageService: MessageService, private errorService: ErroresBaseDatosService,
              private auditoriagral: AuditoriagralService) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }
  
  
 
  getmostrarDatos(strTipo,strCodigo){
    return this.http.post<any[]>(this.way + '/inventario/datosalternos/mb8pr1kayuj2a70', {      
      elementos: {
        Tipo:strTipo,
        Codigo:strCodigo
      },
      }, this.confIniciales.httpOptions()).toPromise();
  }
  guardarCodigo(item: InvCodigoAlterno) {
   
    return this.http.post<any[]>(this.way + '/inventario/datosalternos/8ay8efni11etrpd', {      
      elementos: {
        p_codigo_alt: item.TRNDATOS_CODIGOALT,
        p_com_codigo: item.COM_CODIGO,
        p_tipo: item.TRNDATOS_TIPO,
        p_art_codigo: item.ART_CODIGO,
        p_ser_codigo: item.SER_CODIGO
      },
      }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarCodigo(item: InvCodigoAlterno) {
   
    return this.http.post<any[]>(this.way + '/inventario/datosalternos/x09svhfgrvbpzk1', {      
      elementos: {
        p_codigo_alt: item.TRNDATOS_CODIGOALT,
        p_com_codigo: item.COM_CODIGO
      },
      }, this.confIniciales.httpOptions()).toPromise();
    
  }
  

  erartcoddatosalt(strTRNDATOS_CODIGOALT){
    return this.http.post<any[]>(this.way + '/inventario/datosalternos/03zmj71ec1bzhjw', {      
      elementos: {
        TRNDATOS_CODIGOALT:strTRNDATOS_CODIGOALT
      },
      }, this.confIniciales.httpOptions()).toPromise();
  }
  erartnomdatosalt(strART_CODIGO){
    return this.http.post<any[]>(this.way + '/inventario/datosalternos/whqnp3qbp461nrl', {      
      elementos: {
        ART_CODIGO:strART_CODIGO
      },
      }, this.confIniciales.httpOptions()).toPromise();
  }

  erdescripservicio(strSER_CODIGO){
    return this.http.post<any[]>(this.way + '/inventario/datosalternos/2hok5c3gf6g1vn0', {      
      elementos: {
        SER_CODIGO:strSER_CODIGO
      },
      }, this.confIniciales.httpOptions()).toPromise();
  }
}
