<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="venexistenciabod"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="venexistenciabodconf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid">
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-5">
			</div>
			<div class="p-col-1">
				<button  pButton (click)="visualizarImagen()" [disabled]="disabledVerImagen"
						 style="background-image: url(../../../assets/images/iconos/013-life-saver.png);background-size: contain !important; width: 30px; height: 30px"
						 title="Visualizar imagen">
				</button>
			</div>
			<div class="p-col-1">
				<button pButton (click)="reservar()"
						style="background-image: url(../../../assets/images/iconos/visto.png);background-size: contain !important; width: 30px; height: 30px"
						title="Reservar">
				</button>
			</div>
			<div class="p-col-4">
			</div>
			<div class="p-col-1">
				<button style="background-image: url(../../../assets/images/iconos/salir.png);
					background-size: contain !important; width: 30px; height: 30px" (click)="salir()">
				</button>
			</div>
		</div>
	</div>
</div>
<div class="p-grid">
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-12" style="margin-top: 20px">
				<div class="p-grid">
					<div class="p-col-1">
						<span>Artículo:</span>
					</div>
					<div class="p-col-2" style="text-align: left">
						<div class="p-grid">
							<div class="p-col-8">
								<input id="ART_CODIGO" type="text" pInputText
									   autocomplete="off" [(ngModel)]="txtArtCodigo">
							</div>
							<div class="p-col-4">
								<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="buscarArticulo()"
								></button>
							</div>
						</div>
					</div>
					<div class="p-col-3" style="text-align: left">
						<input id="ART_DESCRIPCION" type="text" pInputText disabled
							   autocomplete="off" [(ngModel)]="txtArtDescripcion">
					</div>
					<div class="p-col-1">
					</div>
					<div class="p-col-1">
						<span style="font-weight: bold">Lista {{txtListaPrecio}}</span>
					</div>
					<div class="p-col-1">
					</div>
					<div class="p-col-1">

					</div>
					<div class="p-col-1">
					</div>
				</div>
			</div>
		</div>
		<!--			 <img src="../../../assets/images/bg-03.jpg" width="100px">-->

		<app-aggridsaci [width]=""
						[height]="'525'"
						[enableSorting]="true"
						[enableFilter]="true"
						[rowData]="detExistenciaBodegas"
						[frameworkComponents]="frameworkComponents"
						[animateRows]="true"
						[rowSelection]="rowSelection"
						[columnDefs]="columnDefsExistenciaBodegas"
						[defaultColDef]="defaultColExistBod"
						[rowStyle]="rowStyle"
						[botones]="false"
						[singleClickEdit]="false"
						(selected)="selecciondetalle($event)"
		>
		</app-aggridsaci>
	</div>
</div>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Imagen" [(visible)]="displayDialogImagenArt"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '350px', height: '310px'}">
	<div style="text-align: center">
		<img [src]="txtArtPathFoto" style='width:200px; height:200px; '>
	</div>
</p-dialog>

<p-dialog header="Reserva de Inventario" [(visible)]="displayDialogReserva"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '600px', height: 'auto'}">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="venreserva"></p-toast>

	<div class="divgrups" *ngIf="displayDialogReserva">
		<div class="p-grid">
			<div class="p-col-2">
				<span>Bodega Origen:</span>
			</div>
			<div class="p-col-3" style="text-align: left">
				<input id="BOD_CODIGO" type="text" pInputText autocomplete="off"
					   [(ngModel)]="detalleSeleccionado.BOD_CODIGO" disabled>
			</div>
			<div class="p-col-7" style="text-align: left">
				<input id="BOD_NOMBRE" type="text" pInputText autocomplete="off"
					   [(ngModel)]="detalleSeleccionado.BOD_NOMBRE" disabled>
			</div>
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-2">
						<span>Artículo:</span>
					</div>
					<div class="p-col-3" style="text-align: left">
						<input id="ART_CODIGO2" type="text" pInputText autocomplete="off"
							   [(ngModel)]="txtArtCodigo" disabled>
					</div>
					<div class="p-col-7" style="text-align: left">
						<input id="ART_NOMBRE2" type="text" pInputText autocomplete="off"
							   [(ngModel)]="txtArtDescripcion" disabled>
					</div>
				</div>
			</div>
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-2">
						<span>Cantidad:</span>
					</div>
					<div class="p-col-2" style="text-align: left">
						<input id="RES_CANTIDAD" type="number" pInputText autocomplete="off"
							   [(ngModel)]="txtCantidadReserva">
					</div>
				</div>
			</div>
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-2">
						<span>Observación:</span>
					</div>
					<div class="p-col-10" style="text-align: left">
						<textarea [rows]="2" [cols]="70" pInputTextarea autoResize="autoResize" [(ngModel)]="txtObservacion"></textarea>
					</div>
				</div>
			</div>
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-3">
					</div>
					<div class="p-col-2">
						<p-button label="Reservar" (click)="guardarReserva()"> </p-button>
					</div>
					<div class="p-col-2">
					</div>
					<div class="p-col-2">
						<p-button label="Cancelar" (click)="cancelarReserva()"> </p-button>
					</div>
					<div class="p-col-3">
					</div>

				</div>
			</div>
		</div>
	</div>

</p-dialog>

<p-dialog header="Clave de Autorización" [(visible)]="claveDialog"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '25vw', height: 'auto'} ">
	<div class="divgrups" *ngIf="claveDialog">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-3">
						<label for="usuario" style="font-weight: bold">Usuario:</label>
					</div>
					<div class="p-col-1"></div>
					<div class="p-col-6">
						<input type="text" pInputText id="usuario" [(ngModel)]="usuario.identificacion"
							   disabled>
					</div>
					<div class="p-col-1"></div>
					<div class="p-col-1"></div>
					<div class="p-col-3">
						<label for="clave" style="font-weight: bold">Clave:</label>
					</div>
					<div class="p-col-1"></div>
					<div class="p-col-6">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							   (keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>
