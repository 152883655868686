//
// SACI WEB
// Rutina: Maestro de banco de caja
// Propietario: Danny Alvarez
// Modificado por: DA
// Fecha de creación: 13-11-2019
// Fecha de Modificación: 29-11-2019
//

import {Component, OnInit, ViewChild} from '@angular/core';
import {VenmaepromocionService} from '../../ven/venservicios/venmaepromocion.service';
import {DatePipe} from '@angular/common';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {BancajaService} from '../banservicios/bancaja.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {Venmaepromocion} from '../../ven/veninterfaces/venmaepromocion';
import {Banmaecaja} from '../baninterfaces/banmaecaja';
import {AggridBanComponent} from '../aggrid-ban/aggrid-ban.component';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {Bantrncaja} from '../baninterfaces/bantrncaja';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {BanTrnCajaTrn} from '../baninterfaces/bantrncajatrn';

@Component({
  selector: 'app-ban-caja',
  templateUrl: './ban-caja.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class BanCajaComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  actualizar: boolean;
  codigoAux: any;
  nuevoCampo: boolean;
  largo: string;
  bancajaseleccionada: Banmaecaja;
  tipoBusqueda: string;
  permisoCaja: SegMaePermiso;
  arregloCons: any[];
  public frameworkComponents;
  rowStyle;
  defaultColDefBanCaja;
  defaultColDefBanTrnCaja;
  rowSelection = 'multiple';
  indexTab = 0;
  
  // Busqueda
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloBus: any[];
  condicion: string;
  opcion: string;
  
  columnDefsBanCaja = [
    {
      headerName: 'Código', field: 'CAJ_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10
          }
        }
    },
    {
      headerName: 'Descripción', field: 'CAJ_DESCRIPCION', cellEditor: 'cellPrueba', width: 200,  editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: false,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 200
          }
        }
    },
    {
      headerName: 'Ref.Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 150,  editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      onCellDoubleClicked: (params) => {
        this.validaUtilizacionCaja(params.data);
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 35,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Centro de costos', field: 'CEN_CODIGO', cellEditor: 'cellPrueba', width: 150,  editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 35,
            opcionbuscar: true
          }
        }
    },
  ];
  columnDefsBanTrnCaja = [
    {
      headerName: 'Fecha', field: 'TRNCAJ_FECHA', cellEditor: 'cellPrueba', width: 110, editable: 'false',
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: true,
            tamanocelda: 10
          }
        }
    },
    {
      headerName: 'Número', field: 'TRNCAJ_NUMERO', cellEditor: 'cellPrueba', width: 130, editable: 'false',
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 200
          }
        }
    },
    {
      headerName: 'Tipo', field: 'TRNCAJ_TIPO', cellEditor: 'cellPrueba', width: 95, editable: 'false',
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: false,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 35
          }
        }
    },
    {
      headerName: 'Número Doc', field: 'TRNCAJ_DOCNRO', cellEditor: 'cellPrueba', width: 150, editable: 'false',
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 35,
          }
        }
    },
    {
      headerName: 'Descripción', field: 'TRNCAJ_DESCRIPCION', cellEditor: 'cellPrueba', width: 300, editable: 'false',
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 35,
          }
        }
    },
    {
      headerName: 'Fecha de vencimiento', field: 'TRNCAJ_FECHAVENCI', cellEditor: 'cellPrueba', width: 175, editable: 'false',
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
          }
        }
    },
    {
      headerName: 'Debe', field: 'TRNCAJ_IMPORTE', cellEditor: 'cellPrueba', width: 120, editable: 'false', cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: true,
            tamanocelda: 35,
          }
        }
    },
    {
      headerName: 'Haber', field: 'TRNCAJ_PAGADO', cellEditor: 'cellPrueba', width: 120, editable: 'false', cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: true,
            tamanocelda: 35,
          }
        }
    },
    {
      headerName: 'Saldo', field: 'TRNCAJ_SALDO', cellEditor: 'cellPrueba', width: 120, editable: 'false', cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: true,
            tamanocelda: 35,
          }
        }
    },
  ];
  
  constructor(public BancajaServices: BancajaService, private datePipe: DatePipe, private message: MessageService,
              private errorService: ErroresBaseDatosService, private confirmationService: ConfirmationService,
              private permisosService: PermisosService, private auditoriagral: AuditoriagralService,
              private busqService: InvbusquedaService, private init: NuevoComponentService) {
    this.agTable();
    this.permisoCaja = {};
    this.BancajaServices.banmaecaja = [];
    this.arregloCons = [];
    this.buscarCon();
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefBanCaja = {
      editable: true,
      width: 130,
      objeto: 'maeBanCaja'
    };
    this.defaultColDefBanTrnCaja = {
      editable: true,
      width: 130,
      objeto: 'maeBanTrnCaja'
    };
  }
  
  ngOnInit() {
    this.tipoBusqueda = 'contains';
    this.permisosService.getListaPermisos('BAN', '1', '27').subscribe((res) => {
      this.permisoCaja = res[0];
    });
    
    this.BancajaServices.banmaecaja.map((caja) => {
      if (caja.CON_CODIGO !== null) {
        this.arregloCons.map((con) => {
          if (con.CON_CODIGO === caja.CON_CODIGO) {
            caja.CON_CENTRO = con.CON_CENTRO;
          }
        });
      } else {
        caja.CON_CENTRO = 'No';
      }
    });
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.actualizar = false;
    this.nuevoCampo = false;
    this.codigoAux = 0;
    this.BancajaServices.getBanCaja().subscribe((res) => {
      if (res !== null) {

        this.BancajaServices.banmaecaja = res;

        this.BancajaServices.banmaecaja.map(item => {
          item.EDITABLE = false;
        });
      
      }
    });
    this.largo = '258';
  }
  
  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      if (this.permisoCaja.PERINSERCION === 1) {
        this.btnNuevo = true;
        this.btnGuardar = false;
        this.btnBorrar = false;
        this.btnRegresar = false;
        this.nuevoCampo = true;
        // grid.isEditable(true);
        this.bancajaseleccionada = {
          CAJ_CODIGO: '',
          CAJ_DESCRIPCION: '',
          CON_CODIGO: '',
          COM_CODIGO: '01',
          CEN_CODIGO: '',
          EDITABLE: true
        };
        //this.aggrid.isEditable(true);
        this.BancajaServices.banmaecaja.push(this.bancajaseleccionada);
        this.aggrid.refreshaggrid(this.BancajaServices.banmaecaja, this.defaultColDefBanCaja.objeto);
      } else if (this.permisoCaja.PERINSERCION === 0) {
        console.log(this.bancajaseleccionada.CON_CODIGO);
        this.message.add({
          key: 'maebancaja',
          severity: 'error',
          summary: 'Inserción de Caja',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    }
    if (valor === 'Guardar') {
      if (this.permisoCaja.PERACTUALIZACION === 1) {
        this.btnGuardar = true;
        this.btnNuevo = false;
        this.btnBorrar = false;
        this.btnRegresar = true;
        this.BancajaServices.existeBanCaja(this.bancajaseleccionada.CAJ_CODIGO).subscribe((existe) => {
          console.log(existe);
          if (existe.length > 0) {
            this.BancajaServices.actualizarBanCaja(this.bancajaseleccionada, this.codigoAux).subscribe(() => {
              this.message.add({
                key: 'maebancaja',
                severity: 'success',
                summary: 'Actualización exitosa',
                detail: 'Se actualizó la caja correctamente'
              });
              this.auditoriagral.registrarAuditoria('BAN_MAECAJ', String(this.bancajaseleccionada.CAJ_CODIGO), 'A', '',
                '01', '', '', '', '').subscribe(() => {
              });
              //this.aggrid.isEditable(false);
            }, error1 => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.message.add({
                key: 'maebancaja',
                severity: 'error',
                summary: 'Actualización de caja',
                detail: mensaje
              });
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
              this.BancajaServices.getBanCaja().subscribe((res) => {
                this.BancajaServices.banmaecaja = res;
                this.BancajaServices.banmaecaja.map(item => {
                  item.EDITABLE = false;
                });
                this.aggrid.refreshaggrid(this.BancajaServices.banmaecaja, this.defaultColDefBanCaja.objeto);
              });
            });
          } else {
            this.BancajaServices.insertarBanCaja(this.bancajaseleccionada).subscribe(() => {
              this.message.add({
                key: 'maebancaja',
                severity: 'success',
                summary: 'Inserción exitosa',
                detail: 'Se insertó la caja correctamente'
              });
              this.auditoriagral.registrarAuditoria('BAN_MAECAJ', String(this.bancajaseleccionada.CAJ_CODIGO), 'I', '',
                '01', '', '', '', '').subscribe(() => {
              });
              // this.aggrid.isEditable(false);
              this.nuevoCampo = false;
            }, error1 => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.message.add({
                key: 'maebancaja',
                severity: 'error',
                summary: 'Inserción de la caja',
                detail: mensaje
              });
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
              this.BancajaServices.getBanCaja().subscribe((res) => {
                this.BancajaServices.banmaecaja = res;
                this.BancajaServices.banmaecaja.map(item => {
                  item.EDITABLE = false;
                });
                this.aggrid.refreshaggrid(this.BancajaServices.banmaecaja, this.defaultColDefBanCaja.objeto);
              });
            });
          }
        });
      } else if (this.permisoCaja.PERACTUALIZACION === 0) {
        this.message.add({
          key: 'maebancaja',
          severity: 'error',
          summary: 'Actualización de Caja',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }
    }
    if (valor === 'Borrar') {
      if (this.permisoCaja.PERELIMACION === 1) {
        if (this.bancajaseleccionada === undefined) {
          this.message.add({
            key: 'maebancaja',
            severity: 'error',
            summary: 'Eliminacion de caja',
            detail: 'Seleccion Incorrecta, no se ha seleccionado una caja'
          });
        } else {
          this.confirmarBanCaja();
         // this.aggrid.isEditable(false);
        }
      } else if (this.permisoCaja.PERELIMACION === 0) {
        this.message.add({
          key: 'maebancaja',
          severity: 'error',
          summary: 'Eliminación de Caja',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
      }
    }
    if (valor === 'Cancelar') {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      // this.aggrid.isEditable(false);
      this.BancajaServices.getBanCaja().subscribe((res) => {
        this.BancajaServices.banmaecaja = res;
        this.BancajaServices.banmaecaja.map(item => {
          item.EDITABLE = false;
        });
        this.aggrid.refreshaggrid(this.BancajaServices.banmaecaja, this.defaultColDefBanCaja.objeto);
      });
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
    if (valor === 'Buscar') {
      if (this.indexTab === 0) {
        this.buscarCaja();
        this.BancajaServices.getBanCaja().subscribe((res) => {
          if (res !== null) {
            this.BancajaServices.banmaecaja = res;
            this.BancajaServices.banmaecaja.map(item => {
              item.EDITABLE = false;
            });
          }
        });
      }
      
      if (this.indexTab === 1) {
        // buscarEstadoCaja();
      }
      
    }
  }
  
  buscarCaja() {
    this.opcion = 'BUSCAR_CAJA';
    this.types = [
      {label: 'CODIGO', value: 'CAJ_CODIGO'},
      {label: 'DESCRIPCION', value: 'CAJ_DESCRIPCION'},
      {label: 'REF. CONTABLE', value: 'CON_CODIGO'},
      {label: 'CENTRO COSTO', value: 'CEN_CODIGO'}
    ];
    
    this.busquedacampos = ['', '', '', ''];
    this.consulta = 'CAJ_CODIGO, CAJ_DESCRIPCION, CON_CODIGO, CEN_CODIGO';
    this.tabla = 'BAN_MAECAJ';
    this.condicion = '';
    this.arregloCons = [];
    this.displayDialogBusquedaFast = true;
  }
  
  manejarSeleccion(valor) {
    if (this.opcion === 'BUSCAR_CAJA') {
      this.displayDialogBusquedaFast = false;
      this.llenarTabla(valor);
    }
  }
  
  llenarTabla(datos) {
    let cont = 0;
    const detalle: Banmaecaja[] = [];
    for (const dato of datos) {
      cont++;
      const itemTrnCajas: Banmaecaja = {};
      
      itemTrnCajas.CAJ_CODIGO = dato.CAJ_CODIGO;
      itemTrnCajas.CAJ_DESCRIPCION = dato.CAJ_DESCRIPCION;
      itemTrnCajas.CEN_CODIGO = dato.CEN_NOMBRE;
      itemTrnCajas.CON_CODIGO = dato.CON_CODIGO;
      itemTrnCajas.EDITABLE = false;
      detalle.push(itemTrnCajas);
      
    }
    this.BancajaServices.banmaecaja = detalle;
    this.aggrid.refreshaggrid(this.BancajaServices.banmaecaja, this.defaultColDefBanCaja.objeto);
  }
  
  selectedBanCaja(valor) {
    if (valor.object !== null) {
      this.bancajaseleccionada = valor.object;
      /*
      this.BancajaServices.getTrnCaja(this.bancajaseleccionada.CAJ_CODIGO).subscribe((res) => {
        this.BancajaServices.bantrnmaecaja = [];
        res.map((trnCaj) => {
          console.log(res.indexOf(trnCaj));
          let trnCaja: Bantrncaja;
          trnCaja = {
            ASI_FECHA: '',
            ASI_NRO: '',
            BANCLI_CODIGO: '',
            BAN_CODIGO: '',
            CAJ_CODIGO: '',
            CEN_CODIGO: '',
            CLI_CODIGO: '',
            COM_CODIGO: '01',
            PCVTRNPAGO_NUMSEC: '',
            PCVTRNPAGO_TRNCOBRO_CODIGO: '',
            TRNCAJ_ANULADO: '',
            TRNCAJ_AUTORIZADO: '',
            TRNCAJ_DEPOSITONRO: '',
            TRNCAJ_DESCRIPCION: '',
            TRNCAJ_DOCNRO: '',
            TRNCAJ_ESTADO: '',
            TRNCAJ_FECHA: '',
            TRNCAJ_FECHACIERRE: '',
            TRNCAJ_FECHAVENCI: '',
            TRNCAJ_FLAG: '',
            TRNCAJ_FORMAPAGO: '',
            TRNCAJ_IMPORTE: '',
            TRNCAJ_NUMERO: '',
            TRNCAJ_ORIGEN: '',
            TRNCAJ_PAGADO: '',
            TRNCAJ_SALDO: '',
            TRNCAJ_TIPO: '',
            TRNPAGO_NROCTA: '',
            TRNTARJETA_CODIGO: '',
            USU_IDENTIFICACION: ''
          };
          this.BancajaServices.bantrnmaecaja.push(trnCaja);
          this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_FECHA = trnCaj.Fecha;
          this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_FECHA =
            this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_FECHA.toLocaleString().substr(0, 10);
          this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_FECHA =
            this.datePipe.transform(this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_FECHA, 'dd/MM/yyyy');
          this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_NUMERO = trnCaj.Numero;
          this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_TIPO = trnCaj.Tipo;
          this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_FECHAVENCI = trnCaj.FVencimiento;
          if (this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_FECHAVENCI !== null) {
            this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_FECHAVENCI =
              this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_FECHAVENCI.toLocaleString().substr(0, 10);
            this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_FECHAVENCI =
              this.datePipe.transform(this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_FECHAVENCI, 'dd/MM/yyyy');
          }
          this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_DESCRIPCION = trnCaj.Descripcion;
          this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_DOCNRO = trnCaj.Doc;
          this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_SALDO = trnCaj.Saldo;
          this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_SALDO =
            this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_SALDO.toFixed(2);
          this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_PAGADO = trnCaj.Haber;
          this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_PAGADO =
            this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_PAGADO.toFixed(2);
          this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_IMPORTE = trnCaj.Debe;
          this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_IMPORTE =
            this.BancajaServices.bantrnmaecaja[res.indexOf(trnCaj)].TRNCAJ_IMPORTE.toFixed(2);
        });
      });
      this.aggrid.refreshaggrid(this.BancajaServices.bantrnmaecaja, this.defaultColDefBanTrnCaja.objeto);
      
       */
    }
  }
  
  cambio(cambio) {
    this.btnGuardar = false;
    this.btnRegresar = false;
  }
  
  confirmarBanCaja() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar la Caja',
      header: 'Eliminar Caja',
      icon: 'pi pi-exclamation-triangle',
      key: 'MaeBanCaja',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarBanCaja();
      },
      reject: () => {
      }
    });
  }
  
  async eliminarBanCaja() {

    console.log(await this.validaUtilizacionCaja2(this.bancajaseleccionada.CAJ_CODIGO));

    if (await this.validaUtilizacionCaja2(this.bancajaseleccionada.CAJ_CODIGO) === true) {
      this.message.add({
        key: 'maebancaja',
        severity: 'error',
        summary: 'Error',
        detail: 'No se ha podido eliminar el registro seleccionado, la cuenta está en movimiento'
      });
      return;
    } else {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.BancajaServices.eliminarBanCaja(this.bancajaseleccionada).subscribe(() => {
        this.message.add({
          key: 'maebancaja',
          severity: 'success',
          summary: 'Eliminación exitosa',
          detail: 'Se eliminó la caja correctamente'
        });
        this.auditoriagral.registrarAuditoria('BAN_MAECAJ', String(this.bancajaseleccionada.CAJ_CODIGO), 'E', '',
          '01', '', '', '', '').subscribe(() => {});
        this.BancajaServices.banmaecaja = this.BancajaServices.banmaecaja.filter((val, j) => j !==
          this.BancajaServices.banmaecaja.indexOf(this.bancajaseleccionada));
        this.aggrid.refreshaggrid(this.BancajaServices.banmaecaja, this.defaultColDefBanCaja.objeto);
      }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.message.add({
          key: 'maebancaja',
          severity: 'error',
          summary: 'Inserción de la caja',
          detail: mensaje
        });
      });
    }
  }
  buscarCon() {
    this.busqService.busquedaConMaeCon().subscribe((res: any[]) => {
      this.arregloCons = res;
    });
  }
  
  async onTab1Change(event) {
    if (event.index === 1) {
      if (this.bancajaseleccionada !== undefined) {
        try {
          this.BancajaServices.bantrnmaecaja = [];
          const rs = await this.BancajaServices.getTrnCajaProm(this.bancajaseleccionada.CAJ_CODIGO);
          if (rs !== null) {
            for (const dato of rs) {
              const item: Bantrncaja = {};
              item.TRNCAJ_FECHA = dato.Fecha === null ? '' : dato.Fecha;
              if (item.TRNCAJ_FECHA.toString().includes('-') && item.TRNCAJ_FECHA  !== '') {
                item.TRNCAJ_FECHA   = this.datePipe.transform(item.TRNCAJ_FECHA, 'dd/MM/yyyy');
              }
              item.TRNCAJ_NUMERO = dato['Número'];
              item.TRNCAJ_TIPO = dato.Tipo;
              item.TRNCAJ_FECHAVENCI = dato['F.Vencimiento'] === null ? '' : dato['F.Vencimiento'];
              if (item.TRNCAJ_FECHAVENCI.toString().includes('-') && item.TRNCAJ_FECHAVENCI  !== '') {
                item.TRNCAJ_FECHAVENCI   = this.datePipe.transform(item.TRNCAJ_FECHAVENCI, 'dd/MM/yyyy');
              }
              item.TRNCAJ_DESCRIPCION = dato['Descripción'];
              item.TRNCAJ_DOCNRO = dato['Doc.#'];
              item.TRNCAJ_SALDO = Number(dato.Saldo).toFixed(2);
              // item.TRNCAJ_PAGADO = Number(dato.Haber).toFixed(2);
              if (dato.Haber < 0) {
                item.TRNCAJ_PAGADO = Number(dato.Haber * -1).toFixed(2);
              } else {
                item.TRNCAJ_PAGADO = Number(dato.Haber).toFixed(2);
              }
              item.TRNCAJ_IMPORTE = Number(dato.Debe).toFixed(2);
              
              
              this.BancajaServices.bantrnmaecaja.push(item);
            }
            this.aggrid.refreshaggrid(this.BancajaServices.bantrnmaecaja, this.defaultColDefBanTrnCaja.objeto);
          }
          
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.message.add({
            key: 'maebancaja',
            severity: 'error',
            summary: 'Error',
            detail: mensaje
          });
        }
        this.sumaSaldo();
        
      }
    }
  }
  
  sumaSaldo() {
    let anterior  = Number(this.BancajaServices.bantrnmaecaja[0].TRNCAJ_IMPORTE)  - Number(this.BancajaServices.bantrnmaecaja[0].TRNCAJ_PAGADO);
    for (let i = 1; i < this.BancajaServices.bantrnmaecaja.length; i++) {
      const item = this.BancajaServices.bantrnmaecaja[i];
      anterior += Number(this.BancajaServices.bantrnmaecaja[i].TRNCAJ_IMPORTE)  - Number(this.BancajaServices.bantrnmaecaja[i].TRNCAJ_PAGADO);
      this.BancajaServices.bantrnmaecaja[i].TRNCAJ_SALDO = Number(anterior).toFixed(2);
    }
    this.aggrid.refreshaggrid(this.BancajaServices.bantrnmaecaja, this.defaultColDefBanTrnCaja.objeto);
  }

  isEdit(data): boolean {
    console.log(data);
    if (data.EDITABLE === false) {
      return false;
    } else {
      return true;
    }
  }

  async validaUtilizacionCaja(data){

		const codigo1 = String(data.CAJ_CODIGO);
	
		try {
      const resConsulta = await this.BancajaServices.existeBanCajaProm(codigo1);
      if (resConsulta.length > 0) {
        this.message.add({
          key: 'maebancaja',
          severity: 'error',
          summary: 'Error',
          detail: 'No se puede hacer cambios la cuenta contable ya está siendo utilizada'
        });

        return true;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.message.add({
        key: 'maebancaja',
        severity: 'error',
        summary: 'Error',
        detail: mensaje
      });
		}
		
		return false;
  }
  
  async validaUtilizacionCaja2(data){

		const codigo1 = String(data);
	
		try {
      const resConsulta = await this.BancajaServices.existeBanCajaProm(codigo1);

      if (resConsulta.length > 0) {
        return true;
      }

    } catch (err) {
      return true;
    }
    
    return false;
		
	}
}

