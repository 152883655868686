import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { VenEncped } from '../veninterfaces/venencped';
import { Observable } from 'rxjs';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class VenpedidosService {
  get encped(): VenEncped[] {
    return this._encped;
  }

  set encped(value: VenEncped[]) {
    this._encped = value;
  }

  public url: any;
  private _encped: VenEncped[];
  public way: string;
  constructor(private http: HttpClient, private confIniciales: ConfInicialesService,
    public usuarioEsquema: UsuarioEsquema, public usuario: Usuario) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }
  getEncPed(cliCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/venpedidos/hrbmc1hsfkxb4i5', {
      elementos: {
        CLI_CODIGO: cliCodigo
      },
    }, this.confIniciales.httpOptions());
  }
  getDetPedidos(pedidoCodigo: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/venpedidos/mnpiiav2i243d3t', {
      elementos: {
        ENCPED_NUMERO: pedidoCodigo
      },
    }, this.confIniciales.httpOptions());
  }
}
