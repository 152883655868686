<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="pedido"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="pedidoConf" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 98%;">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
			[botonBorrar]="btnBorrar" (signal)="manejarSenales($event)" [botonAnterior]="botonAnterior"
			[botonPrimero]="botonPrimero" [botonUltimo]="botonUltimo" [botonSiguiente]="botonSiguiente"
			[barraBotones3]="true" [barraPedido]="false" [botonAnular]="btnAnular" [botonBuscar]="btnBuscar"
			[botonAlmacenardoc]="true" [botonBinocular]="true"
			[botonFrmCorreos]="false" [barraCartera]="false"></app-invmaebarra>
	</div>
</div>
<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px; width: 99%;">
	<div class="p-col-2" style="margin-bottom: 5px;">
		<input type="text" pInputText
			style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
			[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_NUMERO" [disabled]="true">
	</div>
	<div class="p-col-3"></div>
	<div class="p-col-2" style="margin-bottom: 5px;">
		<input type="text" pInputText
			style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
			[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_ESTADO" [disabled]="true">
	</div>
	<div class="p-col-2" style="margin-bottom: 5px;">
		<input type="text" pInputText  *ngIf="bolEstadoEsp"
			style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
			[(ngModel)]="estadoespecial" [disabled]="true">
	</div>
	<div class="p-col-3"></div>
</div>

<p-tabView [(activeIndex)]="index" (onChange)="changeTabs()">
	<p-tabPanel header="Pedido">
		<div class="divgrups" id="divPrincipal">
			<div class="p-grid">
				<!--LINEA1-->
				<div class="p-col-1">
					<span>Comprobante:</span>
				</div>
				<div class="p-col-2">
					<input  type="text" id="comprobante" (input)="cambio($event)" (keydown.enter)="cambiarFoco($event)"
						pInputText
						[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_REFERENCIA"
						autocomplete="off" [disabled]="pedidobolean" >
				</div>
				<div class="p-col-2" style="text-align: left">
					<p-checkbox label="Cotización" [(ngModel)]="cotizacionBoolean" binary="true"
						(ngModelChange)="cambio($event)"></p-checkbox>
				</div>
				<div class="p-col-2">
					<span>Fecha Emisión:</span>
				</div>
				<div class="p-col-1">
					<p-calendar
						[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_FECHAEMISION"
						dateFormat="dd/mm/yy" [inputStyle]="{'width': '80px'}" (ngModelChange)="cambio($event)">
					</p-calendar>
				</div>
				<div class="p-col-1">
					<span>I.V.A.</span>
				</div>
				<div class="p-col-3">
					<div class="p-grid p-justify-between">
						<div class="p-col-2">
							<p-dropdown [options]="opcionIvaDesc" [style]="{'minWidth': '100px','width': '80px'}"
								[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_IVA"
								[disabled]="ivaDescBoolean"></p-dropdown>

						</div>
						<div class="p-col-4"></div>
						<div class="p-col-2">
							<!-- <p-dropdown [options]="opcionIva" [disabled]="disablediva"
								[(ngModel)]="encpedService.dblPorcIvaPed[indicador]"
								(ngModelChange)="encpedService.calcularTotal()"
								[style]="{'minWidth': '100px','width': '80px'}"></p-dropdown> -->

								<p-dropdown [options]="opcionIva" [style]="{'minWidth': '100%', 'width': '100%'}"
                                        optionLabel="name" 
                                        (onChange)="cambiavalorIva($event)"
										[(ngModel)]="selectcombtipoIva"
										[disabled]="disablediva"
							            ></p-dropdown>
						</div>
						<div class="p-col-4"></div>

					</div>
				</div>
				<!--LINEA2-->
				<div class="p-col-12" style="margin-top: -17px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Cliente:</span>
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-8">
									<input type="text" id="cliente" (input)="cambio($event)"
										(keydown.enter)="cambiarFoco($event)" pInputText
										[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].CLI_CODIGO"
										autocomplete="off">
								</div>
								<div class="p-col-4">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="abrirClientes()"></button>
								</div>
							</div>
						</div>
						<div class="p-col-2">
							<span style="color: blue">{{nombreCliente}}</span>
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-10">
									<span>Fecha Entrega:</span>
								</div>
								<div class="p-col-2">
									<p-checkbox [(ngModel)]="fechaBoolean" binary="true"
										(ngModelChange)="cambio($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-1">
							<p-calendar [disabled]="!fechaBoolean"
								[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_FECHAENTREGA"
								dateFormat="dd/mm/yy" [inputStyle]="{'width': '80px'}" (ngModelChange)="cambio($event)">
							</p-calendar>
						</div>
						<div class="p-col-1">
							<span>Lista</span>
						</div>
						<div class="p-col-1">
							<p-dropdown [style]="{'minWidth': '100px','width': '80px'}" [options]="opcionesprecio"
								[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_LISTA">
							</p-dropdown>
						</div>
					</div>
				</div>
				<!--LINEA3-->
				<div class="p-col-12" style="margin-top: -20px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Ref Cliente:</span>
						</div>
						<div class="p-col-2">
							<input id="refCliente" (input)="cambio($event)" (keydown.enter)="cambiarFoco($event)"
								type="text" pInputText
								[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_REFCLI"
								autocomplete="off">
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-2">
							<span>Fecha Validez:</span>
						</div>
						<div class="p-col-1">
							<p-calendar
								[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_FECHAVALIDEZ"
								dateFormat="dd/mm/yy" [inputStyle]="{'width': '80px'}" (ngModelChange)="cambio($event)">
							</p-calendar>
						</div>
						<div class="p-col-1">
							<span>Bodega:</span>
						</div>
						<div class="p-col-1">
							<input type="text" id="bodega" (input)="cambio($event)"
								(keydown.enter)="cambiarFoco($event)" pInputText
								[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].BOD_CODIGO"
								autocomplete="off" [disabled]="blobodBoolean">
						</div>
						<div class="p-col-1" style="text-align: left">
							<button type="button"
								style="background-image: url(../../../assets/images/iconos/buscar.png);"
								(click)="abrirBodega()" [disabled]="blobodBoolean"></button>
						</div>
					</div>
				</div>
				<!--LINEA4-->
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Vendedor:</span>
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-8">
									<input type="text" id="vendedor" (input)="cambio($event)"
										(keydown.enter)="cambiarFoco($event)" pInputText
										[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].VEN_CODIGO"
										autocomplete="off">
								</div>
								<div class="p-col-4">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="abrirVendedor()"></button>
								</div>
							</div>
						</div>
						<div class="p-col-2">
							<span style="color: blue">{{nombreVendedor}}</span>
						</div>
						<!--								<div class="p-col-1"></div>-->
						<div class="p-col-2">
							<span>Orden:</span>
						</div>
						<div class="p-col-1">
							<input type="text" (input)="cambio($event)" pInputText
								[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_ORDEN"
								style="width: 80px" autocomplete="off">
						</div>
						<div class="p-col-1">
							<p-calendar [timeOnly]="true" [(ngModel)]="horaSistema" [inputStyle]="{'width': '100px'}"
								(ngModelChange)="cambio($event)"></p-calendar>
						</div>
					</div>
				</div>
				<!--LINEA5-->
				<div class="p-col-12" style="margin-top: -20px; margin-bottom: -15px;">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Observación:</span>
						</div>
						<div class="p-col-9">
							<input type="text" pInputText (input)="cambio($event)" id="observacion"
								(keydown.enter)="cambiarFoco($event)"
								[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_OBSERVACION"
								autocomplete="off">
						</div>
						<div class="p-col-2"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="divg">
			<app-aggridsaci *ngIf="detallePedidoSeleccionado === undefined" [width]="1650" [height]="largo"
				[enableSorting]="false" [enableFilter]="true" [rowData]="encpedService.detpedidosarray[indicador]"
				[frameworkComponents]="frameworkComponents" [animateRows]="true" [rowSelection]="rowSelection"
				[columnDefs]="columnDefsPedidoDetalle" [defaultColDef]="defaultPedidoDetalle" [rowStyle]="rowStyle"
				[singleClickEdit]="false" [botones]="true" [mostrarGuardar]="false" [botonesAdicionales]="true"
				[comboOpciones]="comboOpciones" [tipodetalle]="tipodetalle" (selected)="seleccionDetallePed($event)"
				(cambios)="cambio($event)"
				(keydown.f12)="aggrid.gridApi.getFocusedCell().column.colId === 'DETPED_TOTAL' ? nuevoDetallePed():''"
				[botonNuevo]="btnNuevoGrid" [botonGuardar]="btnGuardarGrid" [botonBorrar]="btnBorrarGrid"
				[botonRegresar]="btnCancelarGrid" [botonImagen]="btnImagenGrid" [botonVerificarExis]="btnVerificarExis"
				[btnVerExistenciaBodPed]="false" [btnVerInfoPedArt]="false" [btnVervehiculo]="false" ></app-aggridsaci>
			<app-aggridsaci
				*ngIf="detallePedidoSeleccionado !== undefined && detallePedidoSeleccionado.DETPED_TIPODET !== ''"
				[width]="1650" [height]="largo" [enableSorting]="false" [enableFilter]="true"
				[rowData]="encpedService.detpedidosarray[indicador]" [frameworkComponents]="frameworkComponents"
				[animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefsPedidoDetalle"
				[defaultColDef]="defaultPedidoDetalle" [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true"
				[mostrarGuardar]="false" [botonesAdicionales]="true" [comboOpciones]="comboOpciones"
				[tipodetalle]="detallePedidoSeleccionado.DETPED_TIPODET" (selected)="seleccionDetallePed($event)"
				(keydown.f12)="aggrid.gridApi.getFocusedCell().column.colId === 'DETPED_TOTAL' ? nuevoDetallePed():''"
				(cambios)="cambio($event)" (rdbtipodet)="cambiarTipo($event)" [botonNuevo]="btnNuevoGrid"
				[botonGuardar]="btnGuardarGrid" [botonBorrar]="btnBorrarGrid" [botonRegresar]="btnCancelarGrid"
				[botonImagen]="btnImagenGrid" [botonVerificarExis]="btnVerificarExis" [imagenProducto]="imagenProducto"
				[btnVerExistenciaBodPed]="false" [btnVerInfoPedArt]="false" [btnVervehiculo]="false"></app-aggridsaci>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Estado de Cuenta" [disabled]="btnNuevo && index<1">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Cliente:</span>
				</div>
				<div class="p-col-6">
					<span
						style="color: blue">{{encpedService.encabezadoPedidoSeleccionadoarray[indicador].CLI_CODIGO + ' - ' + nombreCliente}}</span>
				</div>
				<div class="p-col-4">
				</div>
			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largoestcta" [enableSorting]="true" [enableFilter]="true"
			[rowData]="cxctmptrnresumen" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsCxctmptrnresumen"
			[defaultColDef]="defaultColDefCxctmptrnresumen" [rowStyle]="rowStyle" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Saldos" [disabled]="btnNuevo && index<1">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Cliente:</span>
				</div>
				<div class="p-col-6">
					<span
						style="color: blue">{{encpedService.encabezadoPedidoSeleccionadoarray[indicador].CLI_CODIGO + ' - ' + nombreCliente}}</span>
				</div>
				<div class="p-col-4">
				</div>
			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largoestcta" [enableSorting]="true" [enableFilter]="true"
			[rowData]="cxctrnresumen" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsCxctrnresumen"
			[defaultColDef]="defaultColDefCxctrnresumen" [rowStyle]="rowStyle" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Estadísticas" [disabled]="disabledEstadisticas">
		<div class="p-grid">
			<!--LINEA 1-->
			<div class="p-col-6">
				<div class="p-grid">
					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-4">
								<button pButton (click)="mostrarEstadisticas()"
									style="background-image: url(../../../assets/images/iconos/cogwheel.png); width: 40px; height: 40px; background-color: transparent;"
									title="Generar estadísticas">
								</button>
							</div>
							<div class="p-col-4">
								<span style="font-size: 16px; font-weight: bold; text-align: center">VENTAS POR
									MESES</span>
							</div>
							<div class="p-col-4">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 20px;">
						<p-chart [options]="options" type="bar" [data]="dataVentasAcum" height="415px"></p-chart>
					</div>
				</div>
			</div>
			<div class="p-col-6">
				<div class="p-grid">
					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-3">
							</div>
							<div class="p-col-6">
								<span style="font-size: 16px; font-weight: bold;">
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									PRODUCTOS MÁS VENDIDOS</span>
							</div>
							<div class="p-col-3">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 10px;">
						<div class="p-grid">
							<div class="p-col-3">
							</div>
							<div class="p-col-2">
								<span>Ordenar por:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="cantidad" inputId="orden1"
									(onClick)="orderByTopProd('cantidad')"></p-radioButton>
								<label> Cantidad </label>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="precio" inputId="orden2"
									(onClick)="orderByTopProd('total')"></p-radioButton>
								<label> Total </label>
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px;">
						<app-aggridsaci [width]="" [height]="'390'" [enableSorting]="true" [enableFilter]="true"
							[rowData]="detTopProductos" [frameworkComponents]="frameworkComponents" [animateRows]="true"
							[rowSelection]="rowSelection" [columnDefs]="columnDefsTopProductos"
							[defaultColDef]="defaultColTopProductos" [rowStyle]="rowStyleTopProd" [botones]="false"
							[singleClickEdit]="false">
						</app-aggridsaci>
					</div>
				</div>
			</div>
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-12">
					</div>
				</div>
			</div>
			<div class="p-col-6">
				<div class="p-grid">

					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-4">
							</div>
							<div class="p-col-2">
								<span style="font-size: 16px; font-weight: bold; text-align: center">DOCUMENTOS</span>
							</div>
							<div class="p-col-5">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Documentos por Cobrar:</span>
							</div>
							<div class="p-col-2">
								<input id="TOTAL_DXC" type="text" pInputText readonly style="text-align: right"
									[(ngModel)]="totalDocXCobrar" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>{{totalDocXCobrarPorc}} %</span>
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Documentos Vencidos:</span>
							</div>
							<div class="p-col-2">
								<input id="TOTAL_DOCVENCIDOS" type="text" pInputText readonly style="text-align: right"
									[(ngModel)]="totalDocVencidos" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>{{totalDocVencidosPorc}} %</span>
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Cheques Posfechados:</span>
							</div>
							<div class="p-col-2">
								<input id="TOTAL_CHPOSFECHADOS" type="text" pInputText readonly
									style="text-align: right" [(ngModel)]="totalChPosfechados" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>{{totalChPosfechadosPorc}} %</span>
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold; float: right">Total:
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
							</div>
							<div class="p-col-2">
								<input id="TOTAL_DOCUMENTOS" type="text" pInputText readonly
									style="text-align: right; font-weight: bold" [(ngModel)]="totalDocumentos"
									autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Cheques Protestados:</span>
							</div>
							<div class="p-col-1">
								<input id="TOTAL_CHPROTESTADOS" type="text" pInputText readonly
									style="text-align: right" [(ngModel)]="totalChProtestados" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Pedidos Pendientes:</span>
							</div>
							<div class="p-col-1">
								<input id="TOTAL_PEDIDOPEND" type="text" pInputText readonly style="text-align: right"
									[(ngModel)]="totalPedPendientes" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Pedidos Satisfechos:</span>
							</div>
							<div class="p-col-1">
								<input id="TOTAL_PEDSATISF" type="text" pInputText readonly style="text-align: right"
									[(ngModel)]="totalPedSatisfechos" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold; ">Primera Factura: </span>
							</div>
							<div class="p-col-2">
								<input id="FechaPrimeraFact" type="text" pInputText readonly style="text-align: right"
									[(ngModel)]="fechaPrimeraFact" autocomplete="off">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="p-col-6">
				<div class="p-grid">
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-4">
							</div>
							<div class="p-col-4">
								<span style="font-size: 16px; font-weight: bold; text-align: center">DETALLE DE
									FACTURAS</span>
							</div>
							<div class="p-col-4">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 10px;">
						<div class="p-grid">
							<div class="p-col-3">
							</div>
							<div class="p-col-2">
								<span>Ordenar por:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="fecha" inputId="orden1"
									(onClick)="orderByDetFacturas('fecha')"></p-radioButton>
								<label> Fecha </label>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="producto" inputId="orden2"
									(onClick)="orderByDetFacturas('producto')"></p-radioButton>
								<label> Producto </label>
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<app-aggridsaci [width]="" [height]="'390'" [enableSorting]="true" [enableFilter]="true"
							[rowData]="detFacturas" [frameworkComponents]="frameworkComponents" [animateRows]="true"
							[rowSelection]="rowSelection" [columnDefs]="columnDefsDetFacturas"
							[defaultColDef]="defaultColDetFacturas" [rowStyle]="rowStyleDetFacts" [botones]="false"
							[singleClickEdit]="false">
						</app-aggridsaci>
					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
</p-tabView>
<div class="divgrupstabla">
	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-4">
					<div class="p-grid">
						<div class="p-col-4">
							<span class="obligatorio">Neto</span>
						</div>
						<div class="p-col-4">
							<span class="obligatorio">Dscto (Valor)</span>
						</div>
						<div class="p-col-4">
							<span class="obligatorio">Dscto (%)</span>
						</div>
					</div>
				</div>
				<div class="p-col-4">
					<div class="p-grid">
						<div class="p-col-4">
							<span class="obligatorio">Base I.V.A.</span>
						</div>
						<div class="p-col-4">
							<span class="obligatorio">Valor I.V.A.</span>
						</div>
						<div class="p-col-4">
							<span class="obligatorio">Base I.C.E.</span>
						</div>
					</div>
				</div>
				<div class="p-col-4">
					<div class="p-grid">
						<div class="p-col-4">
							<span class="obligatorio">Valor I.C.E.</span>
						</div>
						<div class="p-col-4">
							<span class="obligatorio">Base Cero</span>
						</div>
						<div class="p-col-4">
							<span class="obligatorio">TOTAL</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="p-col-12" style="margin-top: -20px">
			<div class="p-grid">
				<div class="p-col-4">
					<div class="p-grid">
						<div class="p-col-4">
							<input type="text" class="totales" pInputText
								[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_TOTALNETO"
								[disabled]="true">
						</div>
						<div class="p-col-4">
							<input type="text" class="totales" pInputText
								[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_VALORDES"
								[disabled]="true">
						</div>
						<div class="p-col-4">
							<input type="text" class="totales" pInputText
								[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_PORCEDES"
								[disabled]="true">
						</div>
					</div>
				</div>
				<div class="p-col-4">
					<div class="p-grid">
						<div class="p-col-4">
							<input type="text" class="totales" pInputText
								[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_BASEIVA"
								[disabled]="true">
						</div>
						<div class="p-col-4">
							<input type="text" class="totales" pInputText
								[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_VALORIVA"
								[disabled]="true">
						</div>
						<div class="p-col-4">
							<input type="text" class="totales" pInputText
								[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_BASEICE"
								[disabled]="true">
						</div>
					</div>
				</div>
				<div class="p-col-4">
					<div class="p-grid">
						<div class="p-col-4">
							<input type="text" class="totales" pInputText
								[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_VALORICE"
								[disabled]="true">
						</div>
						<div class="p-col-4">
							<input type="text" class="totales" pInputText
								[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_BASECERO"
								[disabled]="true">
						</div>
						<div class="p-col-4">
							<input type="text" class="totales" pInputText
								[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_TOTAL"
								[disabled]="true">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<p-dialog header="Seleccione la opción" [(visible)]="displayDialogBus" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width:'90vw', height:'80vh'}">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloBus"
		(opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
		[busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)" [consulta]="consulta">
	</app-busquedadlg>
</p-dialog>
<p-dialog header="Descuento Global" [(visible)]="displayDialogDescuento" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: '50vh'}">
	<div class="p-grid">
		<div class="p-col-12">
			<button class="littlebuttons" pButton (click)="aplicarDescuento()"
				style="background-image: url(../../../assets/images/iconos/visto.png);">
			</button>
		</div>
		<div class="p-col-12">
			<p-tabView>
				<p-tabPanel header="Tipo Descuento">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-3">
							<p-radioButton name="opcionDescuento" label="Por Valor" value="dscValor"
								[(ngModel)]="dscSeleccionado" (ngModelChange)="cambioDescuento()"></p-radioButton>
						</div>
						<div class="p-col-3"></div>
						<div class="p-col-3">
							<p-radioButton name="opcionDescuento" label="En Porcentaje" value="dscPorcentaje"
								[(ngModel)]="dscSeleccionado" (ngModelChange)="cambioDescuento()"></p-radioButton>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-1"></div>
						<div class="p-col-4">
							<input type="text" pInputText pKeyFilter="num" (input)="comprobarValor()"
								(keydown.enter)="aplicarDescuento()" [(ngModel)]="valorDscValor"
								[disabled]="booleanValor" appTwoDigitDecimaNumber>
							<p-message *ngIf="valorInvalidoV === true" severity="error"
								text="Valor de descuento no válido"></p-message>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-4">
							<input type="text" pInputText pKeyFilter="num" (input)="comprobarValor()"
								(keydown.enter)="aplicarDescuento()" [(ngModel)]="valorDscPorcentaje"
								[disabled]="booleanPorcentaje" appTwoDigitDecimaNumber>
							<p-message *ngIf="valorInvalidoP === true" severity="error" text="Porcentaje no válido ">
							</p-message>
						</div>
						<div class="p-col-1"></div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</div>
	</div>
</p-dialog>
<div class="layout-config">
	<div class="layout-config-content-wrapper">
		<a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones($event)">
			<i class="pi pi-bars" style="cursor: pointer"></i>
		</a>
		<p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
			[dismissible]="false" [showCloseIcon]="false">
			<br>
			<br>
			<br>
			<p-tabView>
				<p-tabPanel header="Acciones">
					<div class="p-grid">
						<!-- GSRF -->
						<div class="p-col-12">
							<i class="pi pi-folder archivoOpc"></i><span (click)="cambioestado()"
								class="archivoOpc">Cambio de estado de pedido</span>
						</div>
						<!-- GSRF -->
						<div class="p-col-12">
							<i class="pi pi-folder archivoOpc"></i><span (click)="abrirClave()"
								class="archivoOpc">Estado especial del pedido</span>
						</div>
						<div class="p-col-12">
							<i class="pi pi-folder archivoOpc"></i><span (click)="modificariva()"
								class="archivoOpc">Modificar Porcentaje del Iva</span>
						</div>
						<div class="p-col-12">
							<i class="pi pi-caret-right archivoOpc"></i><span (click)="actbasesiva()"
								class="archivoOpc">Actualizar bases IVA</span>
						</div>
						<div class="p-col-12">
							<i class="pi pi-folder archivoOpc"></i><span (click)="verbasesiva()"
								class="archivoOpc">Visualizar bases IVA</span>
						</div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-sidebar>
	</div>
</div>
<p-dialog header="Clave Autorización" [(visible)]="claveDialog" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: 'auto'} ">
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							(keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog header="Estado del pedido" [(visible)]="estadopedDialog" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '60vw', height: 'auto'} ">
	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-2">
					<input type="text" pInputText
						style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
						[(ngModel)]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_NUMERO"
						[disabled]="true">
				</div>
				<div class="p-col-3"></div>
				<div class="p-col-2">
					<span>Estado Actual:</span>
				</div>
				<div class="p-col-1">
					<span *ngIf="estadoPedidoActual !== undefined"
						style="color: red">{{estadoPedidoActual}}</span>
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-1">
					<button type="button" style="background-image: url(../../../assets/images/iconos/guardar.png);"
						(click)="guardarEstPed()"></button>
				</div>
				<div class="p-col-1">
					<button type="button" style="background-image: url(../../../assets/images/iconos/salir.png);"
						(click)="salirEstPed()"></button>
				</div>
			</div>
		</div>
		<div class="divgrups">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-2">
						<span>Cambiar Estado:</span>
					</div>
					<div class="p-col-2">
						<p-dropdown [options]="opcionEstado" [(ngModel)]="estadoValor" [style]="{'width': '200px'}">
						</p-dropdown>
					</div>
					<div class="p-col-8"></div>
				</div>
			</div>
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-2">
						<span>Razón:</span>
					</div>
					<div class="p-col-10">
						<input type="text" style="width: 100%" [(ngModel)]="razonValor">
					</div>
				</div>
			</div>
			<p-table [scrollable]="true" scrollHeight="300px" [value]="estadoPedido">
				<ng-template pTemplate="colgroup" let-columns>
					<colgroup>
						<col style="width: 100px">
						<col style="width: 100px">
						<col style="width: 150px">
						<col style="width: 100px">
						<col style="width: 300px">
					</colgroup>
				</ng-template>
				<ng-template pTemplate="header">
					<tr class="ui-table-scrollable-wrapper">
						<th style="width: 100px">Fecha</th>
						<th style="width: 100px">Hora</th>
						<th style="width: 150px">Estado</th>
						<th style="width: 100px">Usuario</th>
						<th style="width: 300px">Razón</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-rowData let-estado>
					<tr>
						<td style="height: 36px">{{estado.PEDEST_FECHA}}</td>
						<td>{{estado.PEDEST_HORA}}</td>
						<td>{{estado.PEDEST_ESTADO}}</td>
						<td style="height: 36px">
							{{estado.PEDEST_USUARIO}}
						</td>
						<td style="height: 36px">
							{{estado.PEDEST_RAZON}}
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>
</p-dialog>

<p-dialog header="Reportes" [(visible)]="displayDialogReportes" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: '400px'}">
	<app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
		[strNumDoc]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_NUMERO"
		[strform]="'Si'"
		[strCampo]="'ENCPED_NUMERO'" (cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>

<p-dialog header="Existencia por bodega" [(visible)]="displayDialogExistBod" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '500px'}">
	<app-existenciabodega *ngIf="displayDialogExistBod" [txtArtCodigo]="txtArtCodigo"
		[txtArtDescripcion]="txtArtDescripcion" [txtListaPrecio]="txtListaPrecio" [iva]="txtIva"
		[txtCliente]="txtObservacionRes" [txtBodegaDes]="txtBodegaRes" (signalSalir)="salirExistenciasBod()">
	</app-existenciabodega>
</p-dialog>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'VENENCPED'"
		[txtNumComprobante]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_NUMERO"
		(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>

<p-dialog header="Detalle" [(visible)]="displayDialogInfoArt" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '30vw', height: 'auto'}">
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<span style="color: blue;">{{detallePedidoSeleccionado.DETPED_CODIGO}} -
					{{detallePedidoSeleccionado.DETPED_DESCRIPCION}}</span>
			</div>
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-4">
						<span>Título:</span>
					</div>
					<div class="p-col-5">
						<span>{{ART_LIB_TITULO}}</span>
					</div>
				</div>
			</div>
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-4">
						<span>Autor:</span>
					</div>
					<div class="p-col-5">
						<span>{{ART_LIB_AUTOR}}</span>
					</div>
				</div>
			</div>
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-4">
						<span>Editorial:</span>
					</div>
					<div class="p-col-5">
						<span>{{ART_LIB_EDITORIAL}}</span>
					</div>
				</div>
			</div>
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-4">
						<span>Origen:</span>
					</div>
					<div class="p-col-5">
						<span>{{ART_LIB_ORIGEN}}</span>
					</div>
				</div>
			</div>
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-4">
						<span>Tamaño:</span>
					</div>
					<div class="p-col-5">
						<span>{{ART_LIB_TAMANIO}}</span>
					</div>
				</div>
			</div>
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-4">
						<span>Encuadernación:</span>
					</div>
					<div class="p-col-5">
						<span>{{ART_LIB_ENCUADERNACION}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>
<!-- ///////////////////////nuevo vehiculo////////////////////// -->
<p-dialog header="Datos Vehículo" [(visible)]="displayvehiculo" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '85vw', height: '85vh'} " [focusOnShow]="true" appendTo="body" >
	
<div class="divgrups">
    <div class="p-grid">
        <div class="p-col-2">
            <span>Artículo</span>
        </div>
        <div class="p-col-10">
            <span>{{this.detallePedidoSeleccionado.DETPED_CODIGO + ' - ' + this.detallePedidoSeleccionado.DETPED_DESCRIPCION }}</span>
        </div>
    </div>
</div>
<div class="divgrups">
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>RAMV:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_RAMV"></label>
					<input class="frm" id="ART_RAMV" type="text" pInputText disabled 
					value="{{ART_RAMV}}">
                </div>
                <div class="p-col-1">
                    <span>Motor:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_MOTOR"></label>
					<input class="frm" id="ART_MOTOR" type="text" pInputText disabled
					value="{{ART_MOTOR}}">
                </div>
            </div>

        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Modelo SRI:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_MODELOSRI"></label>
					<input class="frm" id="ART_MODELOSRI" type="text" pInputText disabled
					value="{{ART_MODELOSRI}}">
                </div>
                <div class="p-col-1">
                    <span>Serie:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_SERIEV"></label>
					<input class="frm" id="ART_SERIEV" type="text" pInputText disabled
					value="{{ART_SERIEV}}">
                </div>
            </div>

        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Capacidad:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_CAPACIDAD"></label>
					<input class="frm" id="ART_CAPACIDAD" type="text" pInputText disabled
					value="{{ART_CAPACIDAD}}">
                </div>
                <div class="p-col-1">
                    <span>Cilidraje:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_CILINDRAJE"></label>
					<input class="frm" id="ART_CILINDRAJE" type="text" pInputText disabled
					value="{{ART_CILINDRAJE}}">

                </div>
            </div>

        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Color:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_COLOR"></label>
					<input class="frm" id="ART_COLOR" type="text" pInputText disabled
					value="{{ART_COLOR}}">
                </div>
                <div class="p-col-1">
                    <span>Año:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_ANIO"></label>
					<input class="frm" id="ART_ANIO" type="text" pInputText disabled
					value="{{ART_ANIO}}">
                </div>
                
            </div>

        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Marca:</span>
                </div>
                <div class="p-col-5">
                    <div class="p-grid">
                        <div class="p-col-3">
                            <label for="ART_MARCAV"></label>
							<input class="frm" id="ART_MARCAV" type="text"  pInputText disabled
							value="{{ART_MARCAV}}">
                        </div>
                        <div class="p-col-7">
                            <label for="ART_MARVEH"></label>
							<input class="frm" id="ART_MARVEH" type="text" pInputText disabled
							value="{{ART_MARVEH}}">
                        </div>
                    </div>
                </div>
                <div class="p-col-2">
                    <span>Tonelaje:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_TONELAJE"></label>
					<input class="frm" id="ART_TONELAJE" type="text" pInputText disabled
					value="{{ART_TONELAJE}}">
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Clase:</span>
                </div>
                <div class="p-col-5">
                    <div class="p-grid">
                        <div class="p-col-3">
                            <label for="ART_CLASE"></label>
							<input class="frm" id="ART_CLASE" type="text" pInputText disabled
							value="{{ART_CLASE}}">
                        </div>
                        
                        <div class="p-col-7">
                            <label for="ART_CLAVEH"></label>
							<input class="frm" id="ART_CLAVEH" type="text" pInputText disabled
							value="{{ART_CLAVEH}}">
                        </div>
                    </div>
                </div>
                <div class="p-col-2">
                    <span>Número de ejes:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_NEJES"></label>
					<input class="frm" id="ART_NEJES" type="text" pInputText disabled
					value="{{ART_NEJES}}">
                </div>

            </div>
        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Tipo de vehiculo:</span>
                </div>
                <div class="p-col-5">
                    <div class="p-grid">
                        <div class="p-col-3">
                            <label for="ART_TIPOV"></label>
							<input class="frm" id="ART_TIPOV" type="text" pInputText disabled
							value="{{ART_TIPOV}}">
                        </div>
                        <div class="p-col-7">
                            <label for="ART_TIPVEH"></label>
							<input class="frm" id="ART_TIPVEH" type="text" pInputText disabled
							value="{{ART_TIPVEH}}">
                        </div>
                    </div>
                </div>
                <div class="p-col-2">
                    <span>Número de ruedas:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_NRUEDAS"></label>
					<input class="frm" id="ART_NRUEDAS" type="text" pInputText disabled
					value="{{ART_NRUEDAS}}">
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Combustible:</span>
                </div>
                <div class="p-col-5">
                    <div class="p-grid">
                        <div class="p-col-3">
                            <label for="ART_COMBUSTIBLE"></label>
							<input class="frm" id="ART_COMBUSTIBLE" type="text" pInputText disabled
							value="{{ART_COMBUSTIBLE}}">
                        </div>
                        
                        <div class="p-col-7">
                            <label for="ART_COMVEH"></label>
							<input class="frm" id="ART_COMVEH" type="text" pInputText disabled
							value="{{ART_COMVEH}}">
                        </div>
                    </div>
                </div>
                <div class="p-col-2">
                    <span>Fecha de fabricación:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_FFABRICA"></label>
					<input class="frm" id="ART_FFABRICA" pInputText disabled
					value="{{ART_FFABRICA}}">
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Dispositivo:</span>
                </div>
                <div class="p-col-5">
                    <div class="p-grid">
                        <div class="p-col-3">
                            <label for="ART_DISPOSITIVO"></label>
							<input class="frm" id="ART_DISPOSITIVO" type="text" pInputText disabled
							value="{{ART_DISPOSITIVO}}">
                        </div>
                        
                        <div class="p-col-7">
                            <label for="ART_DISVEH"></label>
							<input class="frm" id="ART_DISVEH" type="text" pInputText disabled
							value="{{ART_DISVEH}}">
                        </div>
                    </div>
                </div>
                <div class="p-col-2">
                    <span>Modelo agencia de tránsito:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_MODELOAT"></label>
					<input class="frm" id="ART_MODELOAT" type="text" pInputText disabled
					value="{{ART_MODELOAT}}">
                </div>
            </div>
        </div>

        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>País de origen:</span>
                </div>
                <div class="p-col-5">
                    <div class="p-grid">
                        <div class="p-col-3">
                            <label for="ART_PORIGEN"></label>
							<input class="frm" id="ART_PORIGEN" type="text" pInputText disabled
							value="{{ART_PORIGEN}}">
                        </div>
                        
                        <div class="p-col-7">
                            <label for="ART_P"></label>
							<input class="frm" id="ART_P" type="text" pInputText disabled
							value="{{ART_P}}">
                        </div>
                    </div>
                </div>
                <div class="p-col-2">
                    <span>Detalle de pago:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_DPAGO"></label>
					<input class="frm" id="ART_DPAGO" type="text" pInputText disabled
					value="{{ART_DPAGO}}">
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Banco de Pago:</span>
                </div>
                <div class="p-col-5">
                    <div class="p-grid">
                        <div class="p-col-3">
                            <label for="ART_BANCO"></label>
							<input class="frm" id="ART_BANCO" type="text" pInputText disabled
							value="{{ART_BANCO}}">
                            
                        </div>
                        
                        <div class="p-col-7">
                            <label for="ART_NBANCO"></label>
							<input class="frm" id="ART_NBANCO" type="text" pInputText disabled
							value="{{ART_NBANCO}}">
                        </div>
                    </div>
                </div>
                <div class="p-col-6">
                    <p style="text-align: center;">
                        <button class=button_largos type="button"
							style="background-image: url(../../../assets/images/iconspdv/boton-ver-imagen.png); width: 7em"
							(click)="verimagen(detallePedidoSeleccionado.DETPED_CODIGO)"></button>
                    </p>
                </div>

            </div>
        </div>
    </div>
</div>
</p-dialog>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentosim" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentosim" [txtOrigen]="'Invmaearticulo'"
		[txtNumComprobante]="detallePedidoSeleccionado.DETPED_CODIGO" (signalSalir)="recibirSenalesDocumentos($event)">
	</app-uploadfiles>
</p-dialog>

<!-- GSRF -->
<p-dialog header="Pedido" [(visible)]="displaycambioestado" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '65vw', height: '65vh'} " [focusOnShow]="true" appendTo="body" >
	<div class="p-col-12">
		<h3 style="text-align: center;">Guias de Remisión</h3>
	</div>
	<!-- <br> -->
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-2">
						<h4 >Codigo</h4>
					</div>
					<div class="p-col-3">
						<h4>Número</h4>
					</div>
					<div class="p-col-3">
						<h4 >Fecha</h4>
					</div>
					<div class="p-col-2">
						<h4 >Código Cliente</h4>
					</div>
					<div class="p-col-2">
						<h4 >Total</h4>
					</div>
				</div>
			</div>
		</div>
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-2">
						<span>{{this.codigogre}}</span>
					</div>
					<div class="p-col-3">
						<span>{{this.numerogre}}</span>
					</div>
					<div class="p-col-3">
						<span>{{this.fechagre }}</span>
					</div>
					<div class="p-col-2">
						<span>{{this.cligre}}</span>
					</div>
					<div class="p-col-2">
						<span>{{this.totgre}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="p-col-12">
		<h3 style="text-align: center;">Facturas</h3>
	</div>
	<!-- <br> -->
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-2">
						<h4 >Número</h4>
					</div>
					<div class="p-col-3">
						<h4 >Fecha</h4>
					</div>
					<div class="p-col-3">
						<h4 >Código Cliente</h4>
					</div>
					<div class="p-col-2">
						<h4 >Nombre cliente</h4>
					</div>
					<div class="p-col-2">
						<h4 >Total</h4>
					</div>
				</div>
			</div>
		</div>
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-2">
						<span>{{this.codigofac}} </span>
					</div>
					<div class="p-col-3">
						<span>{{this.fechafac}} </span>
					</div>
					<div class="p-col-3">
						<span>{{this.clifac }} </span>
					</div>
					<div class="p-col-2">
						<span>{{this.clinomfac}} </span>
					</div>
					<div class="p-col-2">
						<span>{{this.totfac}} </span>
					</div>
				</div>
			</div>
		</div>
		
	</div>
	<div class="p-col-12">
		<p style="text-align: center;">
			<button class=button_largos type="button"
				style="background-image: url(../../../assets/images/iconspdv/boton-largo-cambio-estado.png); width:5em; height: 3em "
				(click)="cambiarestadopen()"></button>
		</p>
	</div>
	
</p-dialog>
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	<p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
	</p-dialog>
</div>
<!-- CDPJ -->
<p-dialog header="Formularios de Correos" [(visible)]="displayDialogfrmcorreos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: '400px'}">
	<app-sacifrmenviocorreos [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
	[strNumDoc]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].ENCPED_NUMERO"
	[strform]="'Si'"
	[strCampo]="'ENCPED_NUMERO'"
	[strCliProvCod]="encpedService.encabezadoPedidoSeleccionadoarray[indicador].CLI_CODIGO"
	(cerrar)="cerrarfrmcorreos()"></app-sacifrmenviocorreos>
</p-dialog>
<p-dialog header="Bases IVA" [(visible)]="displaybasesiva" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '55vw', height: '55vh'} " [focusOnShow]="true" appendTo="body" >

	<app-aggridsaci [width]="-800" [height]="-800" [enableSorting]="true" [enableFilter]="true"
			[rowData]="basesiva" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsBasesIva"
			[defaultColDef]="defaultColBasesIva" [rowStyle]="rowStyle" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>



</p-dialog>
<!-- CDPJ -->