
<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="anexmaecodsriKey"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmaranexmaecodsri" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
						 [botonRegresar]="btnRegresar"  [barraBotonesCRUD]="false"
						 [barraBotonesDesplaz]="true"
						  [barraBotones3]="true" [barraBotonesAsiCon]="true"
						 [barraBotones5]="false" [botonBuscar]="true"
						 (signal)="manejarSenales($event)">
		</app-invmaebarra>
	</div>
</div>

<p-tabView>
	<p-tabPanel header="Datos">
		<div class="divgrups">
			<div class="p-grid"style="margin-top: 10px; margin-bottom: 10px">
				<div class="p-col-2">
					<span>	Tipo :  </span>
				</div>
				<div class="p-col-3">
					<p-dropdown id="tipo" [style]="{'width': '300px','height':'25px'}"
								[panelStyle]="{'width':'300px'}"
								[(ngModel)]="tipoSeleccionado"
								[options]="opcionesTipo"
								(ngModelChange)="obtenerCodSRI(tipoSeleccionado)"

					>
					</p-dropdown>
				</div>
			</div>
		</div>

		<div style="overflow: hidden" class="p-col-12">
			<p-tabView>
				<app-aggridsaci [width]=""
								[height]="largo"
								[enableSorting]="false"
								[enableFilter]="true"
								[rowData]="anexmaecodsriService.codSRI"
								[frameworkComponents]="frameworkComponents"
								[animateRows]="true"
								[rowSelection]="rowSelection"
								[columnDefs]="columnDefsCodSRI"
								[defaultColDef]="defaultColDefCodSRI"
								[rowStyle]="rowStyle"
								[singleClickEdit]="false"
								[botones]="false"
								(selected)="selectedCodSRI($event)"
								(cambios)="cambio($event)"
				></app-aggridsaci>
			</p-tabView>
		</div>
	</p-tabPanel>
</p-tabView>







