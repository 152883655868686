//
// SACI WEB
// Rutina: Ingreso de control de calidad
// Propietario: Victor Perez
// Modificado por: VP
// Fecha de creación: 29-07-2021
// Fecha de Modificación: 29-07-2021
//

import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
// Ag-grid
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { ProdencordproService } from '../prodservicios/prodencordpro.service';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { InvTrncontrolcalidad } from '../prodinterfaces/invtrncontrolcalidad';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';

@Component({
  selector: 'app-prodcontrolcalidad',
  templateUrl: './prodcontrolcalidad.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class ProdcontrolcalidadComponent implements OnInit, OnChanges {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  @Input() auxiliar: string;
  @Input() indicador: string;
  @Input() EncOrdPro: string;
  @Input() ArtCodigo: string;
  @Input() ArtNombre: string;
  @Output() cerrar = new EventEmitter<boolean>();

  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  barraBotones2: boolean;

  booleanRegimen: boolean;
  booleanParaiso: boolean;
  booleanRegimenP: boolean;
  consulta: string;

  dscSeleccionado: string;
  valorDscValor: number;
  valorDscPorcentaje: number;
  btnRegresar: boolean;

  displayDialogBusqueda: boolean;
  displayDialogBusquedaFast: boolean;
  displayDialogAutorizacion: boolean;
  displayDialogReportes: boolean;

  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];
  tabla: string;
  where: string;
  opcionbusqueda: string;

  index: number;

  groupCompRet: boolean;
  bolPagoRegFiscal: boolean;

  detalleFisicoQuim: InvTrncontrolcalidad[] = [];
  detalleFisQuimSel: InvTrncontrolcalidad = {};

  // autorizacion
  claveAuth: string;
  autorizacionCodigo: string;
  bolAutorizacion: boolean;

  auxreporte: number = 0;
  strNombreForma: string;

  largo: string;
  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  defaultColDef;

  columnDefs = [
    {
      headerName: 'Parámetro', field: 'TRNCC_PARAMETRO', cellEditor: 'cellPrueba', width: 200, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          fecha: false,
          tamanocelda: 50,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Método', field: 'TRNCC_METODO', cellEditor: 'cellPrueba', width: 200, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 50,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Resultado', field: 'TRNCC_RESULTADO', cellEditor: 'cellPrueba', width: 200, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 20,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Límite', field: 'TRNCC_LIMITE', cellEditor: 'cellPrueba', width: 200, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 20,
          tienecalculos: true
        }
      }
    }
  ];

  constructor(public encordproService: ProdencordproService, private init: NuevoComponentService,
    private auditoriagral: AuditoriagralService, private datePipe: DatePipe,
    private messageService: MessageService,private confirmationService: ConfirmationService) {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultColDef = {
      editable: true,
      width: 100,
      objeto: 'datosanexos'
    };
  }

  ngOnInit() {
    this.largo = '500';
    this.index = 0;

    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = true;
    this.barraBotones2 = true;

    this.groupCompRet = false;
    this.bolPagoRegFiscal = false;

    this.encordproService.trncontrolcalidad[this.indicador] = {};

  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const e in changes) {
      if (e === 'auxiliar' && this.ArtCodigo !== undefined) {
        this.mostrarDatos();
      }
    }
  }

  manejarSenales(valor) {
    if (valor === 'Guardar') {
      this.guardarRegistro();
    }

    if (valor === 'Borrar') {
       //this.cerrar.emit(true);
       this.eliminarProdTrncalidad()
    }

    if (valor === 'Cancelar') {
      this.mostrarDatos();
    }

    if (valor === 'Visto') {
      this.ok();
    }

    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }

    if (valor === 'Salir') {
      this.cerrar.emit(true);
    }
  }


  cerrarReportes() {
    this.displayDialogReportes = false;
  }

  manejarSeleccion(opcion) {
  }

  botonesmodificar() {
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
  }

  botonesnuevo() {
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = false;
  }

  setFocus(event) {
  }

  cambio() {
  }

  seleccionDetFisQuim(valor) {
    if (valor === 'nuevo') {
      this.nuevaLineaFisQuim();
    }
    if (valor === 'eliminar') {
       if (Object.keys(this.detalleFisQuimSel).length > 0) {
         this.confirmationService.confirm({
           message: '¿ Está seguro de eliminar el registro ?',
           header: 'Confirmación',
           icon: 'pi pi-exclamation-triangle',
           key: 'confirmcontcal',
           acceptLabel: 'Si',
           rejectLabel: 'No',
           accept: () => {
             this.eliminarFisQuim();
           },
           reject: () => {
            this.confirmationService.close();
           }
         });
       } else {
         //this.mensajeAlerta('error', 'Error', '! Seleccione una fila.... !');
         this.messageService.add({
          key: 'contcal',
          severity: 'error',
          summary: 'Error',
          detail: '! Seleccione una fila.... !'
        });
       }
       
    }

    if (valor.object !== undefined) {
      this.detalleFisQuimSel = valor.object;
    }
  }
//CDPJ
eliminarFisQuim(){
  if (this.detalleFisQuimSel.TRNCC_ORDEN !== '') {
    this.encordproService.eliminarDetAnFiQu(this.detalleFisQuimSel).subscribe(() => {
      this.detalleFisicoQuim.splice(this.detalleFisicoQuim.findIndex(
        detalle => detalle.TRNCC_ORDEN === this.detalleFisQuimSel.TRNCC_ORDEN), 1);

        this.aggrid.refreshaggrid(this.detalleFisicoQuim, this.defaultColDef.objeto);
      this.messageService.add({
        key: 'contcal',
        severity: 'success',
        summary: 'Información',
        detail: 'El detalle se eliminó correctamente'
      });
    });
    this.auditoriagral.registrarAuditoria('PROD_TRNCONTROLCALIDAD',
  this.EncOrdPro+'/'+this.detalleFisQuimSel.TRNCC_PARAMETRO, 'E',
  '', '01', '', '', '', '').subscribe();
  } else {
    this.detalleFisicoQuim.splice(this.detalleFisicoQuim.findIndex(
      detalle => detalle.TRNCC_ORDEN === this.detalleFisQuimSel.TRNCC_ORDEN), 1);
      this.aggrid.refreshaggrid(this.detalleFisicoQuim, this.defaultColDef.objeto);
      this.messageService.add({
        key: 'contcal',
        severity: 'success',
        summary: 'Información',
        detail: 'El detalle se eliminó correctamente'
      });
  }
  this.botonesnuevo();
}

async eliminarProdTrncalidad(){
  await this.encordproService.elimTrnContCalidadAll(this.EncOrdPro);
  this.messageService.add({
    key: 'contcal',
    severity: 'success',
    summary: 'Información',
    detail: 'El registro se eliminó correctamente'
  });
  this.auditoriagral.registrarAuditoria('PROD_TRNCONTROLCALIDAD',
  this.EncOrdPro, 'E',
  '', '01', '', '', '', '').subscribe();
  this.limpiarDatos()
}
limpiarDatos(){
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_FECHAVIGENCIA = null;
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_CODIGO = "";
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_VERSION = "";
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_ESTADOFISICO = "";
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_COLOR = "";
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_SABOR = "";
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_LUGAR = "";
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_RECOMENDACIONES = "";
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_NOTA = "";
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_OBSERVACIONES = "";
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_REVISADO = "";
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_OLOR = "";
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_FECHACADUCIDAD = null;
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_FECHAULTIMOANA = null;
      this.detalleFisicoQuim=[]
}
//CDPJ

  nuevaLineaFisQuim() {
    const item: InvTrncontrolcalidad = {};
    item.COM_CODIGO = '01';
    item.ART_CODIGO = this.ArtCodigo;
    item.TRNCC_METODO = '';
    item.TRNCC_ORDEN = '';
    item.TRNCC_PARAMETRO = '';
    item.TRNCC_RESULTADO = '';
    item.TRNCC_LIMITE = '';


    const indi = this.detalleFisicoQuim.indexOf(this.detalleFisQuimSel);
    if (indi === -1) {
      this.detalleFisicoQuim.push(item);
      this.detalleFisQuimSel = this.detalleFisicoQuim
      [this.detalleFisicoQuim.length - 1];
      this.aggrid.refreshaggrid(this.detalleFisicoQuim, this.defaultColDef.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(
        this.detalleFisicoQuim.length - 1, 'TRNCC_PARAMETRO');
    } else {
      this.detalleFisicoQuim.splice(indi + 1, 0, item);
      this.aggrid.refreshaggridindex(this.detalleFisicoQuim, this.defaultColDef.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(
        indi + 1, 'TRNCC_PARAMETRO');
    }
    this.botonesmodificar()
  }

  async mostrarDatos() {
    if (this.encordproService.trncontrolcalidad[this.indicador] === undefined) {
      this.encordproService.trncontrolcalidad[this.indicador] = {};
    }
    this.encordproService.trncontrolcalidad[this.indicador].ART_CODIGO = this.ArtCodigo;
    this.encordproService.trncontrolcalidad[this.indicador].ART_NOMBRE = this.ArtNombre;
    this.encordproService.trncontrolcalidad[this.indicador].ENCORDPRO_NUMERO = this.EncOrdPro;
    // document.getElementById('txtCodigo').focus();

    const data: any = await this.encordproService.gettrncontrolcalidad(this.EncOrdPro);
    if (data !== null) {
      for (const rs of data) {
      rs.TRNCON_FECHACADUCIDAD = rs.TRNCON_FECHACADUCIDAD=== null || rs.TRNCON_FECHACADUCIDAD === undefined || rs.TRNCON_FECHACADUCIDAD=== ''?'': new Date(rs.TRNCON_FECHACADUCIDAD);
      rs.TRNCON_FECHAULTIMOANA = rs.TRNCON_FECHAULTIMOANA === null || rs.TRNCON_FECHAULTIMOANA === undefined || rs.TRNCON_FECHAULTIMOANA === ''?'': new Date(rs.TRNCON_FECHAULTIMOANA);
      rs.TRNCON_FECHAVIGENCIA = rs.TRNCON_FECHAVIGENCIA === null || rs.TRNCON_FECHAVIGENCIA === undefined || rs.TRNCON_FECHAVIGENCIA === ''?'':new Date(rs.TRNCON_FECHAVIGENCIA);
      
        this.encordproService.trncontrolcalidad[this.indicador].TRNCON_ESTADOFISICO = rs.TRNCON_ESTADOFISICO;
        this.encordproService.trncontrolcalidad[this.indicador].TRNCON_COLOR = rs.TRNCON_COLOR;
        this.encordproService.trncontrolcalidad[this.indicador].TRNCON_OLOR = rs.TRNCON_OLOR;
        this.encordproService.trncontrolcalidad[this.indicador].TRNCON_SABOR = rs.TRNCON_SABOR;
        this.encordproService.trncontrolcalidad[this.indicador].TRNCON_LUGAR = rs.TRNCON_LUGAR;
        this.encordproService.trncontrolcalidad[this.indicador].TRNCON_RECOMENDACIONES = rs.TRNCON_RECOMENDACIONES;
        this.encordproService.trncontrolcalidad[this.indicador].TRNCON_NOTA = rs.TRNCON_NOTA;
        this.encordproService.trncontrolcalidad[this.indicador].TRNCON_OBSERVACIONES = rs.TRNCON_OBSERVACIONES;
        this.encordproService.trncontrolcalidad[this.indicador].TRNCON_ESTADO = rs.TRNCON_ESTADO;
        this.encordproService.trncontrolcalidad[this.indicador].TRNCON_FECHACADUCIDAD = rs.TRNCON_FECHACADUCIDAD;
        this.encordproService.trncontrolcalidad[this.indicador].TRNCON_FECHAULTIMOANA = rs.TRNCON_FECHAULTIMOANA;
        this.encordproService.trncontrolcalidad[this.indicador].TRNCON_CODIGO = rs.TRNCON_CODIGO;
        this.encordproService.trncontrolcalidad[this.indicador].TRNCON_VERSION = rs.TRNCON_VERSION;
        this.encordproService.trncontrolcalidad[this.indicador].TRNCON_REVISADO = rs.TRNCON_REVISADO;
        this.encordproService.trncontrolcalidad[this.indicador].TRNCON_FECHAVIGENCIA = rs.TRNCON_FECHAVIGENCIA;
      }
    } else {
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_FECHAVIGENCIA = new Date();
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_CODIGO = "RC0012-7853";
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_VERSION = "01";
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_ESTADOFISICO = "Líquido. Libre de partículas extrañas. Polvo. Libre de partículas o materia extraña.";
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_COLOR = "Característico. Conforme a estandarización";
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_SABOR = "Característico. Conforme a estandarización";
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_LUGAR = "Lab.Ctrol. de Calidad Magic Flavors S.A.";
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_RECOMENDACIONES = "Se recomienda que el producto se deje en maduración por lo menos 48 horas después de elaborado.";
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_NOTA = "No se acepta devoluciones pasado los 15 días de emisión de la factura.";
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_OBSERVACIONES = "";
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_REVISADO = "";
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_OLOR = "";
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_FECHACADUCIDAD = new Date();
      this.encordproService.trncontrolcalidad[this.indicador].TRNCON_FECHAULTIMOANA = new Date();
    }
    this.modeloAnalisisFQlistarDatos(this.ArtCodigo,this.EncOrdPro);
  }

  async modeloAnalisisFQlistarDatos(ArtCodigo,EncOrdPro) {
    this.detalleFisicoQuim=[]//CDPJ
    const data: any = await this.encordproService.getanalisisfq(ArtCodigo,EncOrdPro);
    if (data !== null) {
      this.detalleFisicoQuim = data;
    }
    this.botonesnuevo();//CDPJ
  }


  guardarRegistro() {
    if (this.index == 0) {
      this.guardarDatos();
    }

    if (this.index == 1) {
      this.GrabarControlCalidad();
    }
  }

  async guardarDatos() {
    //this.EliminarCertificadoCalidad();

    const item = this.encordproService.trncontrolcalidad[this.indicador];
    
    if (this.encordproService.trncontrolcalidad[this.indicador].TRNCON_FECHACADUCIDAD !== null) {
      if (this.encordproService.trncontrolcalidad[this.indicador].TRNCON_FECHACADUCIDAD.toString().includes('-')) {
        item.TRNCON_FECHACADUCIDAD = this.datePipe.transform(this.encordproService.trncontrolcalidad[this.indicador].TRNCON_FECHACADUCIDAD, 'dd/MM/yyyy');
      }
    }
    if (this.encordproService.trncontrolcalidad[this.indicador].TRNCON_FECHAULTIMOANA !== null) {
      if (this.encordproService.trncontrolcalidad[this.indicador].TRNCON_FECHAULTIMOANA.toString().includes('-')) {
        item.TRNCON_FECHAULTIMOANA = this.datePipe.transform(this.encordproService.trncontrolcalidad[this.indicador].TRNCON_FECHAULTIMOANA, 'dd/MM/yyyy');
      }
    }
    if (this.encordproService.trncontrolcalidad[this.indicador].TRNCON_FECHAVIGENCIA !== null) {
      if (this.encordproService.trncontrolcalidad[this.indicador].TRNCON_FECHAVIGENCIA.toString().includes('-')) {
        item.TRNCON_FECHAVIGENCIA = this.datePipe.transform(this.encordproService.trncontrolcalidad[this.indicador].TRNCON_FECHAVIGENCIA, 'dd/MM/yyyy');
      }
    }
    await this.encordproService.insertarContCalidad(item);
    this.auditoriagral.registrarAuditoria('PROD_TRNCONTROLCALIDAD',
      this.EncOrdPro, 'I',
      '', '01', '', '', '', '').subscribe();
    this.messageService.add({
      key: 'contcal',
      severity: 'success',
      summary: 'Información',
      detail: 'Información guardada correctamente.'
    });
    this.botonesnuevo();
  }

  async  GrabarControlCalidad() {

    this.EliminarCertificadoCalidad();
    let i = 0;
    for (const item of this.detalleFisicoQuim) {
      item.ENCORDPRO_NUMERO = this.EncOrdPro;
      item.ART_CODIGO = this.ArtCodigo;
      item.TRNCC_ORDEN = i;
      i++;
      if (item.TRNCC_RESULTADO !== ''
        && item.TRNCC_LIMITE !== '') {
        await this.encordproService.insertarTrnContCalidad(item);
      }
    }

    this.messageService.add({
      key: 'contcal',
      severity: 'success',
      summary: 'Información',
      detail: 'Información guardada correctamente.'
    });
    this.botonesnuevo();
  }

  async EliminarCertificadoCalidad() {
    await this.encordproService.elimTrnContCalidad(this.EncOrdPro);
  }

  ok() {
    this.claveAuth = '';
    this.autorizacionCodigo = 'OKCONCAL';
    this.displayDialogAutorizacion = true;
  }

  onHideAutorizacion() {
    if (this.bolAutorizacion === false) {
      if (this.autorizacionCodigo === 'OKCONCAL') {
        this.messageService.add({
          key: 'contcal',
          severity: 'error',
          summary: 'Información',
          detail: 'Debe ingresar correctamente la clave.'
        });
      }
    }
  }

  comprobarClave() {
    this.encordproService.obtenerClave(this.autorizacionCodigo).subscribe((clave) => {
      let claveReal;
      claveReal = clave[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        this.accionesAutorizacion();
      } else if (clave !== this.claveAuth) {
        this.messageService.add({
          key: 'autorizacion',
          severity: 'error',
          summary: 'Clave Autorización',
          detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
        });
      }
    });
  }

  accionesAutorizacion() {
    if (this.autorizacionCodigo === 'OKCONCAL') {
      this.ok2();
    }
    this.displayDialogAutorizacion = false;
  }

  async ok2() {
    await this.encordproService.okControlCalidad(this.EncOrdPro);
    this.auditoriagral.registrarAuditoria('PROD_TRNCONTROLCALIDAD',
      this.EncOrdPro + '/OK', 'I', '', '01', '', '', '', '').subscribe();

    this.mostrarDatos();
  }

}

