import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from 'src/app/usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { MessageService } from 'primeng';
import { CostosIndirectos } from '../prodinterfaces/prodmaecostoind';

@Injectable({
  providedIn: 'root'
})
export class ProdmaecostoindService {

  private way: string;
  public url: string;

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) { 
      this.way = sessionStorage.getItem('way');
      this.url = sessionStorage.getItem('url');
    }


    llenarDatos(){
    
      return this.http.post<any[]>(this.way + '/produccion/costosind/nz0llu78f8b36jo', {
          elementos: {
          },
        }, this.confIniciales.httpOptions()).toPromise();
    }


    insCostoIndirecto(costoInd :CostosIndirectos){
      costoInd.CIN_COSTO_U = Number(costoInd.CIN_COSTO_U);
      return  this.http.post<any[]>(this.way + '/produccion/costosind/gkk2t38nwaq0w5s', {
        elementos: {
          P_CIN_CODIGO: costoInd.CIN_CODIGO, 
          P_COM_CODIGO: costoInd.COM_CODIGO,
          P_CIN_NOMBRE: costoInd.CIN_NOMBRE,
          P_CIN_COSTO_U: costoInd.CIN_COSTO_U,
          P_CON_CODIGO: costoInd.CON_CODIGO,
          P_CEN_CODIGO: costoInd.CEN_CODIGO,
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }


    actCostoIndirecto(costoInd :CostosIndirectos){
      costoInd.CIN_COSTO_U = Number(costoInd.CIN_COSTO_U);
      console.log(costoInd);
      return  this.http.post<any[]>(this.way + '/produccion/costosind/q4v6y26pq59u0xl', {
        elementos: {
          P_CIN_CODIGO: costoInd.CIN_CODIGO, 
          P_COM_CODIGO: costoInd.COM_CODIGO,
          P_CIN_NOMBRE: costoInd.CIN_NOMBRE,
          P_CIN_COSTO_U: costoInd.CIN_COSTO_U,
          P_CON_CODIGO: costoInd.CON_CODIGO,
          P_CEN_CODIGO: costoInd.CEN_CODIGO,
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }

    eliCostoIndirecto(costoInd :CostosIndirectos){
      return  this.http.post<any[]>(this.way + '/produccion/costosind/zgbpo951apnuudq', {
        elementos: {
          P_CIN_CODIGO: costoInd.CIN_CODIGO, 
          P_COM_CODIGO: costoInd.COM_CODIGO
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
}
