import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Usuario } from 'src/app/usuario';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { AggridBanComponent } from 'src/app/ban/aggrid-ban/aggrid-ban.component';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { Prodcostfech } from '../prodinterfaces/costosfecha';
import { ProdcostosfechaService } from '../prodservicios/prodcostosfecha.service';
import { ConfirmationService, MessageService, SelectItem } from 'primeng';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Component({
  selector: 'app-prodcostosxfecha',
  templateUrl: './prodcostosxfecha.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class ProdcostosxfechaComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  displayDialogBusquedaFast: boolean;
  displayDialogBusquedaFastEstCiv: boolean;
  displayDialogReportes: boolean;

  span_esta_civil: string = "";
  arregloEstCiv: any[];
  types: SelectItem[];
  busquedacampos = [];
  tabla: string;
  consulta: string;
  arregloCons: any[] = [];
  arrlegoBus = [];
  where: string;
  opcion: string;
  condicion: string;

  // reporte
  auxreporte: number = 0;
  strNombreForma: string;

  btnGuardar: boolean;
  btnNuevo: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  btnBuscar: boolean;
  fecha = new Date();
  identiUsuario = '';
  selectedcostfec = new Prodcostfech();
  selecteddetcostfec = new Prodcostfech();
  pMes: any;
  indexTab: any;
  cmbMes = [{ label: "", codigo: '' }, { label: "01 ENERO", codigo: '01' }, { label: "02 FEBRERO", codigo: '02' },
  { label: "03 MARZO", codigo: '03' }, { label: "04 ABRIL", codigo: '04' },
  { label: "05 MAYO", codigo: '05' },
  { label: "06 JUNIO", codigo: '06' }, { label: "07 JULIO", codigo: '07' },
  { label: "08 AGOSTO", codigo: '08' },
  { label: "09 SEPTIEMBRE", codigo: '09' },
  { label: "10 OCTUBRE", codigo: '10' },
  { label: "11 NOVIEMBRE", codigo: '11' },
  { label: "12 DICIEMBRE", codigo: '12' }
  ];
  public frameworkComponents;
  rowStyle;
  largo = '500';
  defaultColDefcostfech;
  rowSelection = 'multiple';

  editar = false;

  displayDialogDocumentos = false;

  columncostfech = [
    {
      headerName: 'Cin Código', editable: true, field: 'CIN_CODIGO_1', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: false,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Nombre', editable: false, field: 'CIN_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: false,

        }
      }
    },
    {
      headerName: 'Costo Estimado', editable: true, field: 'CINF_COSTO', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: false,

        }
      }
    },
    {
      headerName: 'Distribución',
      field: 'CINF_DISTRIBUCION',
      cellRendererFramework: AggridBanComponent,
      editable: false,
      width: 110,
      onCellClicked: () => {
        this.botonesmodificar();
      }
    }
  ]
  
  constructor(private datePipe: DatePipe,
    private init: NuevoComponentService,
    public prodcosfech: ProdcostosfechaService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public usuario: Usuario, private confIniciales: ConfInicialesService) {
    this.datePipe.transform(this.fecha, 'dd/MM/yy');
    this.identiUsuario = this.usuario.identificacion;
    this.btnGuardar = true;
    this.btnNuevo = false;
    this.btnBorrar = false;
    this.btnRegresar = false;
    this.btnBuscar = false;
    this.agTable();
  }

  ngOnInit(): void {
  }
  async manejarSenales(valor) {
    switch (valor) {
      case 'Nuevo':
        this.opcionNuevo();
        break;
      case 'Guardar':
        this.opcionGuardar();
        break;
      case 'Borrar':
        this.opcionBorrar();
        break;
      case 'Cancelar':
        this.opcionRecargar();
        break;
      case 'Buscar':
        this.buscarCondicion();
        break;
      case 'Imprimir':
        this.strNombreForma = this.init.tabs[this.init.tabindex].component;
        this.auxreporte++;
        this.displayDialogReportes = true;
        break;
      case 'Almacenardoc':
        if (this.selectedcostfec.CINF_DOCUMENTO !== '') {
          const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
          if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
            this.displayDialogDocumentos = true;
          } else {
            this.messageService.add({
              key: 'costosFech',
              severity: 'info',
              summary: 'Información',
              detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
            });
          }
          
        }
      break;
      case 'Salir':
        this.prodcosfech.costosfec = [];
        this.init.tabs.splice(this.init.tabindex, 1);
        break;
    };
    return;
  }
  botonesmodificar() {
    this.btnGuardar = false;
    this.btnNuevo = true;
    this.btnRegresar = false;
    this.btnBuscar = true;
  }
  setFocus(event) {

  }
  cambioOptionLicen(event) {
    this.selectedcostfec.CINF_MES = this.pMes.codigo;
    this.btnGuardar = false;
    this.btnNuevo = true;
    this.btnRegresar = false;
    this.btnBuscar = true;
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.defaultColDefcostfech = {
      editable: true,
      width: 130,
      objeto: 'prodcostfech'
    };
  }
  seleccostfech(valor) {
    if (valor === 'nuevo') {
      this.nuevodetcostfac();
    }

    if (valor === 'eliminar') {
      this.eliminardetcostfac();
    }

    if (valor === 'cancelar') {
      //await  this.cancelarIngFijo();
    }

    if (valor.object !== undefined) {
      this.selecteddetcostfec = valor.object;
    }
  }
  nuevodetcostfac() {
    this.aggrid.isEditable(true);
    let fe = new Date();
    const newModulo: Prodcostfech = {
      CINF_DOCUMENTO: this.selectedcostfec.CINF_DOCUMENTO,
      CIN_CODIGO_1: '',
      COM_CODIGO: '01',
      CINF_COSTO: '',
      CINF_FECHA: '',
      CINF_MES: this.pMes.codigo,
      CEN_CODIGO: this.selectedcostfec.CEN_CODIGO,
      CINF_ANIO: this.selectedcostfec.CINF_ANIO,
      CIN_NOMBRE: '',
      NUEVO: true,
      NUMCOS: this.prodcosfech.costosfec.length,
      CINF_DISTRIBUCION: false
    };
    try {
      this.prodcosfech.costosfec.push(newModulo);
      this.aggrid.refreshaggrid(this.prodcosfech.costosfec, this.defaultColDefcostfech.objeto);
      const indice = this.prodcosfech.costosfec.indexOf(newModulo);
      this.aggrid.gridApi.setFocusedCell(indice, 'CIN_CODIGO_1');
    } catch (err) {
      console.log(err)
    }
  }
  eliminardetcostfac() {
    if (this.selecteddetcostfec !== undefined) {
      this.confirmationService.confirm({
        message: '¿Está seguro que desea eliminar?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'NomdetCostFech',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {
          try {
            this.prodcosfech.detelimcostosfech(this.selecteddetcostfec).then(res => {
              this.mensajeAlerta('success', 'Información', '¡Se eliminó correctamente!');
              this.opcionRecargar();
            })
          } catch (e) {
            this.mensajeAlerta('error', 'Error', 'Error al realizar la transacción');

          }


        },
        reject: () => {
        }
      })
    }
  }
  cambiocostfech() {
    this.btnGuardar = false;
    this.btnNuevo = true;
    this.btnRegresar = false;
    this.btnBuscar = true;
  }

  opcionNuevo() {
    let nu = new Prodcostfech();
    this.selectedcostfec = nu;
    this.selecteddetcostfec = {};
    let fe = new Date();
    this.selectedcostfec.CINF_ANIO = fe.getFullYear();

    this.prodcosfech.costosfec = [];
    this.selectedcostfec.NUEVO = true;
    this.span_esta_civil = "";
    this.pMes = { label: "", codigo: '' };
    this.editar = true;
  }
  async opcionGuardar() {
    this.aggrid.gridApi.stopEditing();

    try {
      this.selectedcostfec.CINF_MES = this.pMes.codigo;
      //this.selecteddetcostfec.CINF_MES=this.pMes.codigo;
      if (this.prodcosfech.costosfec.length == 0) {
        let valor = this.prodcosfech.validar2(this.selectedcostfec);
        if (valor.length != 0) {

          this.mensajeAlerta('error', 'Error', valor);
        } else {
          this.opcionRecargar();
          this.btnGuardar = true;
          this.btnNuevo = false;
          this.btnRegresar = false;
          this.btnBorrar = false;
          this.btnBuscar = false;
        }

      } else {
        let error = [];
        for (let costfec of this.prodcosfech.costosfec) {

          //error =this.prodcosfech.validar(this.selectedcostfec,this.selecteddetcostfec)
          error = this.prodcosfech.validar(this.selectedcostfec, costfec)
        }

        if (error.length != 0) {

          this.mensajeAlerta('error', 'Error', error);
          return;
        } else {
          /**
           * Revisar codigo en visual: Se guarda la cabecera y el detalle en un solo documento.
           * Si se guardan varios detalles, se crean varios documentos.
           * Por ello se dejó para que solo se guarde el primer detalle.
           */
          //for (let costfec of this.prodcosfech.costosfec) {

            //console.log(costfec);
            if (this.prodcosfech.costosfec[0].NUEVO == true) {
              await this.prodcosfech.guardarcostosfech(this.prodcosfech.costosfec[0]);/* .then(res=>{
                   
                
              }); */
            } else {
              await this.prodcosfech.actucostosfech(this.prodcosfech.costosfec[0]);//.then(re=>{
              //this.mensajeAlerta('success', 'Información', '¡Se agrego correctamente!');         
              /* this.opcionRecargar();
              this.btnGuardar=true;
              this.btnNuevo=false;
              this.btnRegresar=false;
              this.btnBorrar=false; */
              //});
            }
         // }
          this.mensajeAlerta('success', 'Información', '¡Se agrego correctamente!');
          this.opcionRecargar();
          this.btnGuardar = true;
          this.btnNuevo = false;
          this.btnRegresar = false;
          this.btnBorrar = false;
          this.btnBuscar = false;
        }
      }
    } catch (e) {
      console.log(e);
      this.mensajeAlerta('error', 'Error', 'Error al realizar la transacción ' + e.error);
    }
  }

  opcionBorrar() {
    if (this.selectedcostfec !== undefined) {
      this.confirmationService.confirm({
        message: '¿Está seguro que desea eliminar?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'NomCostFech',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {
          try {
            this.prodcosfech.elimcostosfech(this.selectedcostfec).then(res => {
              this.mensajeAlerta('success', 'Información', '¡Se eliminó correctamente!');
              let nu = new Prodcostfech();
              this.selectedcostfec = nu;
              this.prodcosfech.costosfec = [];
              this.opcionNuevo();
            })
          } catch (e) {
            this.mensajeAlerta('error', 'Error', 'Error al realizar la transacción');

          }


        },
        reject: () => {
        }
      })
    }
  }
  opcionRecargar() {
    this.span_esta_civil = "";
    this.pMes = { label: "", codigo: '' };
    try {
      this.prodcosfech.obtenerdetcostosfech(this.selectedcostfec).then((res: any) => {
        this.prodcosfech.costosfec = res;
        this.actualizarMes(this.selectedcostfec.CINF_MES);
        this.llenar(this.selectedcostfec.CEN_CODIGO);

        let i = 0;
        for (let a of res) {
          this.prodcosfech.costosfec[i].NUEVO = false;
          this.prodcosfech.costosfec[i].CIN_CODIGO_1 = a.CIN_CODIGO;
          this.prodcosfech.costosfec[i].CINF_COSTO = this.prodcosfech.cambiarFloatFixed(a.CINF_COSTO);
          this.prodcosfech.costosfec[i].CINF_DISTRIBUCION=Number(this.prodcosfech.costosfec[i].CINF_DISTRIBUCION) === 0?false:Number(this.prodcosfech.costosfec[i].CINF_DISTRIBUCION) === 1?true:''//CDPJ
          i++;
        }
      });
      this.btnGuardar = true;
      this.btnNuevo = false;
      this.btnRegresar = false;
      this.btnBorrar = false;
      this.btnBuscar = false;
    } catch (e) {
      this.opcionNuevo();
      this.btnGuardar = true;
      this.btnNuevo = false;
      this.btnRegresar = false;
      this.btnBorrar = false;
      this.btnBuscar = false;
    }
  }
  buscarCondicion() {
    this.types = [
      { label: 'DOCUMENTO', value: 'CINF_DOCUMENTO' },
      { label: 'FECHA', value: 'CINF_MES' },
    ];
    this.busquedacampos = ['', '', '', ''];
    this.consulta = '*';
    this.tabla = 'PROD_TRNCOSTOINDXFECHA';    
    this.where = '1=1 ORDER BY CIN_CODIGO';

    this.prodcosfech.busquedaTabla1().then((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'costosFech',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
  }
  async manejarSeleccionbus(condicion) {
    this.span_esta_civil = "";
    this.pMes = { label: "", codigo: '' };

    this.displayDialogBusquedaFast = false;
    this.selectedcostfec = condicion;
    this.selectedcostfec.NUEVO = false;
    try {
      this.prodcosfech.obtenerdetcostosfech(this.selectedcostfec).then((res: any) => {
        this.editar = true;
        this.btnNuevo = true;
        this.prodcosfech.costosfec = res;
        this.actualizarMes(this.selectedcostfec.CINF_MES);
        this.llenar(this.selectedcostfec.CEN_CODIGO);

        let i = 0;
        for (let a of res) {
          this.prodcosfech.costosfec[i].NUEVO = false;
          this.prodcosfech.costosfec[i].CIN_CODIGO_1 = a.CIN_CODIGO;
          this.prodcosfech.costosfec[i].CINF_COSTO = this.prodcosfech.cambiarFloatFixed(a.CINF_COSTO);
          i++;
        }
      })
    } catch (e) {

    }
    /* this.selectedNomEmpl = {...condicion};
    this.llenarFormulario(this.selectedNomEmpl); */
    //document.getElementById('divPrincipal').style.pointerEvents = 'all';
  }
  busCen(parametro) {
    if (parametro == null || parametro == undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarEstCivil';
    this.types = [
      { label: 'CODIGO', value: 'CEN_CODIGO' },
      { label: 'NOMBRE', value: 'CEN_NOMBRE' },
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = 'CEN_CODIGO, CEN_NOMBRE';
    this.tabla = 'CON_MAECEN';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\'';
    this.prodcosfech.encontrarRegistro1(parametro).then((res: any[]) => {
        if (res != null) {
          if (res[0] != undefined) {

            this.span_esta_civil = res[0].CEN_NOMBRE;
            //document.getElementById('inp_CORREOEMPRE').focus();
          } else {
            this.prodcosfech.busquedaTabla2(parametro).then((re: any[]) => {
              this.arregloEstCiv = re;
              this.displayDialogBusquedaFastEstCiv = true;
            })
          }
        } else {
          this.prodcosfech.busquedaTabla2(parametro).then((ret: any[]) => {
            this.arregloEstCiv = ret;
            this.displayDialogBusquedaFastEstCiv = true;
          })
        }
      });
  }
  manejarSeleccionEstCiv(event) {
    this.displayDialogBusquedaFastEstCiv = false;
    this.selectedcostfec.CEN_CODIGO = event.CEN_CODIGO;
    this.span_esta_civil = event.CEN_NOMBRE;

  }
  actualizarMes(valor) {
    try {
      for (const a of this.cmbMes) {
        if (a.codigo === String(valor)) {
          this.pMes = { label: a.label, codigo: a.codigo }
        }
      }
    } catch (err) {
    }
  }
  llenar(parametro) {
    if (parametro == null || parametro == undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarEstCivil';
    this.types = [
      { label: 'CODIGO', value: 'CEN_CODIGO' },
      { label: 'NOMBRE', value: 'CEN_NOMBRE' },
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = 'CEN_CODIGO, CEN_NOMBRE';
    this.tabla = 'CON_MAECEN';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\'';
    this.prodcosfech.encontrarRegistro1(parametro).then((res: any[]) => {
        if (res != null) {

          if (res[0] != undefined) {
            this.span_esta_civil = res[0].CEN_NOMBRE;
          } else {
            this.span_esta_civil = "";
          }
        } else {
          this.span_esta_civil = "";
        }
      });
  }

  cerrarReportes() {
    this.displayDialogReportes = false;
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos  = false;
    }
  }

}
