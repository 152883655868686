import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { MessageService } from 'primeng/api';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { DatePipe } from '@angular/common';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { VenEncfacZ } from '../veninterfaces/venencfacz';
import { VenDetfacZ } from '../veninterfaces/vendetfacz';


@Injectable({
  providedIn: 'root'
})
export class VenencptovtaService {
  public url: string;
  public way: string;

  public encfac: VenEncfacZ[] = [];
  public detfac: VenDetfacZ[][] = [];

  public dblValorTotal = [];
  public dblCostoTotalItem = [];
  public dblValorIceItem = [];
  public dblBaseIceItem = [];
  public dblBaseIceTotal = [];
  public dblValorIceTotal = [];
  public dblBaseCeroTotal = [];
  public dblBaseIvaTotal = [];
  public dblValorIvaTotal = [];
  public dblValorNeto = [];

  public hmpConfiguracion: any[];
  //public dblPorcDescGlb = []
  //public dblValorDescGlb = [];
  public dblPorcDescGlb: any;
  public dblValorDescGlb: any;
  public dblPorcIva: any;

  public NUMDECPRECIO: number;
  public NUMDECCANTIDAD: number;
  public NUMDECTOTAL: number;
  //CDPJ
  public porcetariiva:number;
  public codtariiva:string;
  //CDPJ
  private indicador = 0;

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private messageService: MessageService,
    private confIniciales: ConfInicialesService,
    private datePipe: DatePipe,
    private init: NuevoComponentService
  ) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }

  refrescarURL() {
    if (this.url === null) {
      this.url = sessionStorage.getItem('url');
    }
    if (this.way === null) {
      this.way = sessionStorage.getItem('way');
    }
  }

  insertarEncabezadoFact(item): Promise<any> {
    console.log(item.BOD_CODIGO);
    return this.http.post<any>(this.way + '/ventas/puntodeventa/257e3b7d56dd760000', {
      elementos: {
        p_ENCFAC_numero: item.ENCFAC_NUMERO,
        p_ENCFAC_numero_1: item.ENCFAC_NUMERO_1,
        p_COM_codigo_2: '01',
        p_ENCFAC_fechaemision_3: item.ENCFAC_FECHAEMISION,
        p_ENCFAC_fechaentrega_4: item.ENCFAC_FECHAENTREGA,
        p_ENCFAC_estado_5: item.ENCFAC_ESTADO,
        p_CLI_codigo_6: item.CLI_CODIGO,
        p_CLI_nombre_7: item.CLI_NOMBRE,
        p_BOD_codigo_8: item.BOD_CODIGO,
        p_VEN_codigo_9: item.VEN_CODIGO,
        p_ENCFFA_codigo: item.ENCFFA_CODIGO,
        p_ENCFAC_formapago_10: item.ENCFAC_FORMAPAGO,
        p_ENCFAC_referencia: item.ENCFAC_REFERENCIA,
        p_ENCFAC_tipo_11: item.ENCFAC_TIPO,
        p_ENCFAC_lista_12: item.ENCFAC_LISTA,
        p_ENCFAC_fechavencimiento_13: item.ENCFAC_FECHAVENCIMIENTO,
        p_ENCFAC_total_14: item.ENCFAC_TOTAL,
        p_ENCFAC_totalneto: item.ENCFAC_TOTALNETO,
        p_ENCFAC_porceiva_15: item.ENCFAC_PORCEIVA,
        p_ENCFAC_valoriva_16: item.ENCFAC_VALORIVA,
        p_ENCFAC_porcecar_17: item.ENCFAC_PORCECAR,
        p_ENCFAC_valorcar_18: item.ENCFAC_VALORCAR,
        p_ENCFAC_porcertc_19: item.ENCFAC_PORCERTC,
        p_ENCFAC_valorrtc_20: item.ENCFAC_VALORRTC,
        p_ENCFAC_porcedes_21: item.ENCFAC_PORCEDES,
        p_ENCFAC_valordes_22: item.ENCFAC_VALORDES,
        p_ENCFAC_valordev_23: item.ENCFAC_VALORDEV,
        p_ENCFAC_porceint_26: item.ENCFAC_PORCEINT,
        p_ENCFAC_valorint_27: item.ENCFAC_VALORINT,
        p_ENCFAC_porcerec_28: item.ENCFAC_PORCEREC,
        p_ENCFAC_valorrec_29: item.ENCFAC_VALORREC,
        p_ENCFAC_VALORICE: Number(item.ENCFAC_VALORICE),
        p_ENCFAC_PORCEICE: Number(item.ENCFAC_PORCEICE),
        p_ENCFAC_impreso_30: item.ENCFAC_IMPRESO,
        p_ENCFAC_comentario_31: item.ENCFAC_COMENTARIO,
        p_ENCFAC_tipofac_32: item.ENCFAC_TIPOFAC,
        p_ENCFAC_generafac_33: item.ENCFAC_GENERAFAC,
        p_ENCFAC_numerodob_34: item.ENCFAC_NUMERODOB,
        p_ENCFAC_direccion_35: item.ENCFAC_DIRECCION,
        p_ENCFAC_telefono_36: item.ENCFAC_TELEFONO,
        p_ENCFAC_rucide_37: item.ENCFAC_RUCIDE,
        p_ENCFAC_chofer_38: item.ENCFAC_CHOFER,
        p_ENCFAC_carro_39: item.ENCFAC_CARRO,
        p_ENCFAC_placa_40: item.ENCFAC_PLACA,
        p_ENCFAC_iva_41: item.ENCFAC_IVA,
        p_ENCFAC_baseiva: item.ENCFAC_BASEIVA,
        p_ENCFAC_baseice: item.ENCFAC_BASEICE,
        p_ENCFAC_basecero: item.ENCFAC_BASECERO,
        p_ENCFAC_otros: item.ENCFAC_OTROS,
        p_ENCFAC_grupo_42: item.ENCFAC_GRUPO,
        p_encfac_diascrdt: item.ENCFAC_DIASCRDT,
        p_encfac_clidsct: item.ENCFAC_CLIDSCT,
        p_usu_identificacion: item.USU_IDENTIFICACION,
        p_ENCFAC_TIPODSCTO: item.ENCFAC_TIPODSCTO,
        p_ENCFAC_ORIGEN: item.ENCFAC_ORIGEN,
        p_ENCFAC_secactual: item.ENCFAC_SECACTUAL,
        p_ENCFAC_refcli: item.ENCFAC_REFCLI,
        p_ENCFAC_NOTADEVENTA: item.ENCFAC_NOTADEVENTA,
        p_ENCFAC_AUTORIZACION: item.ENCFAC_AUTORIZACION,
        p_ENCFAC_PLACA_CLI: item.ENCFAC_PLACA_CLI,
        p_ENCFAC_VENTA: item.ENCFAC_VENTA,
        p_ENCFAC_TIPOFACTURA: item.ENCFAC_TIPOFACTURA,//CDPJ
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarDetalleFactura(item): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/95f8edf5aeebd80', {
      elementos: {
        p_DETFAC_linea: '0',
        p_ENCFAC_numero_1: item.ENCFAC_NUMERO,
        p_COM_codigo_2: item.COM_CODIGO,
        p_DETFAC_linea_3: item.DETFAC_LINEA_3,
        p_DETFAC_tipodet_4: item.DETFAC_TIPODET,
        p_BOD_codigo_5: item.BOD_CODIGO,
        p_DETFAC_codigo_6: item.DETFAC_CODIGO,
        p_DETFAC_descripcion_7: item.DETFAC_DESCRIPCION,
        p_DETFAC_tribiva_8: item.DETFAC_TRIBIVA,
        p_DETFAC_tribice_9: item.DETFAC_TRIBICE,
        p_DETFAC_simbolo_10: item.DETFAC_UNIDAD,
        p_DETFAC_cantidad_11: item.DETFAC_CANTIDAD,
        p_DETFAC_precio_12: item.DETFAC_PRECIO,
        p_DETFAC_descuento_13: item.DETFAC_DESCUENTO,
        p_DETFAC_total_14: item.DETFAC_TOTAL,
        p_DETFAC_iva_15: item.DETFAC_IVA,
        p_DETFAC_ice_16: item.DETFAC_ICE,
        p_DETFAC_lista_17: item.DETFAC_LISTA,
        p_DETFAC_baseiva_18: item.DETFAC_BASEIVA,
        p_DETFAC_baseice_19: item.DETFAC_BASEICE,
        p_DETFAC_basecero_20: item.DETFAC_BASECERO,
        p_DETFAC_porceice_21: item.DETFAC_PORCEICE,
        p_ENCCOT_numero: item.ENCCOT_NUMERO,
        p_DETCOT_linea: item.DETCOT_LINEA,
        p_ENCPED_numero: item.ENCPED_NUMERO,
        p_DETPED_linea: item.DETPED_LINEA,
        p_ENCGRE_numero: item.ENCGRE_NUMERO,
        p_DETGRE_linea: item.DETGRE_LINEA,
        p_UNI_CODIGO: item.DETFAC_UNIDAD,
        p_DETFAC_tipo_22: item.DETFAC_TIPO,
        p_DETFAC_codigoref_23: item.DETFAC_CODIGOREF,
        p_ENCFAC_numero: item.ENCFAC_NUMERO,
        p_detfac_promocion: item.DETFAC_PROMOCION,
        p_detfac_orden: item.DETFAC_ORDEN,
        p_detfac_linorgprom: item.DETFAC_LINORGPROM,
        p_maeprm_codigo: item.MAEPRM_CODIGO,
        p_DETFAC_FECHAREALIZACION: '',
        p_detfac_precioc: item.DETFAC_PRECIOC,
        p_cen_codigo: item.CEN_CODIGO,
        p_detfac_bulto: item.DETFAC_BULTO,
        p_detfac_cantidadb: item.DETFAC_CANTIDADB,
        p_detfac_tiposri: item.DETFAC_TIPOSRI,
        p_detfac_signo: item.DETFAC_SIGNO,
        p_detfac_artacambio: item.DETFAC_ARTACAMBIO,
        p_ordtra_numero: item.ORDTRA_NUMERO,
        p_detordtra_linea: item.DETORDTRA_LINEA,
        p_encreq_numero: item.ENCREQ_NUMERO,
        p_detreq_linea: item.DETREQ_LINEA,
        p_detfac_basenoobjiva: item.DETFAC_BASENOOBJIVA,
        p_detfac_tribasenoobjiva: item.DETFAC_TRIBASENOOBJIVA,
        p_encmat_numero: item.ENCMAT_NUMERO,
        p_detmat_codigo: item.DETMAT_CODIGO,
        p_detfac_ordenlote: item.DETFAC_ORDENLOTE,
        p_DETFAC_cantidadund: item.DETFAC_CANTIDADUND,
        p_DETFAC_IRBPNR:  '',//GSRF
        p_DETFAC_VALORIRBPNR: '',//GSRF
        p_DETFAC_INCLUENDSCTO: '',//CDPJ
        p_DETFAC_PORIVA:item.DETFAC_PORIVA,//CDPJ
        p_TARIIVA_CODIGO:item.TARIIVA_CODIGO,//CDPJ

      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erpBusquedaCliente(strValor, strValue, strParams) {
    return this.http.post(this.way + '/ventas/facturas/tq8cgrtwkhkurwk', {

      elementos: {
        Valor: strValor,
        Value: strValue,
        Params: strParams
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }

  getEncfac(encfacnumero): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/662fcf55d763d40', {
      elementos: {
        p_Tipo: 'R',
        p_Cadena: '',
        p_condicion: 'ENCFAC_NUMERO=\'' + encfacnumero + '\''
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }



  getDetfac(strNumFactura): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/10775aeebd4c', {
      elementos: {
        strNumFactura
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDatosCli(strCodCliente): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/41dd6abb8e32b0', {
      elementos: {
        strCodCliente
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  canpartesreservadas(CodArticulo, CodigoBodega, strFechaInicio, strHora) {
    return this.http.post(this.way + '/produccion/ordenprod/62afdcab7d773800000000000', {
      elementos: {
        CodArticulo, CodigoBodega, strFechaInicio, strHora
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  ecpGetCantArticulo(strArtCodigo, strBodCodigo, strReferencia) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/gea5cej9l1dpq9c', {
      elementos: {
        ArtCodigo: strArtCodigo,
        BodCodigo: strBodCodigo,
        Referencia: strReferencia,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }


  getunidadcosteo(CodArticulo) {
    return this.http.post(this.way + '/produccion/ordenprod/41ddf5e4d5359800000', {
      elementos: {
        CodArticulo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  transformaunidad(Uni_codigo, str_artunidadcosteo, Cantidad) {
    return this.http.post(this.way + '/produccion/ordenprod/eed57e3f1bb2c0000000', {
      elementos: {
        Uni_codigo, str_artunidadcosteo, Cantidad
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  ecpGetCantArtCosteo(detFacCodigo) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/inm32qrfoknvvk3', {

      elementos: {
        DETFAC_CODIGO: detFacCodigo
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }

  getUsuarioFactura(lblNumFact): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/10777dcf3d4c', {
      elementos: {
        lblNumFact
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getFormaPago(lblNumFact): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/83bafc6ecacaa00', {
      elementos: {
        lblNumFact
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getSaldoFactura(NumFact): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/617d75ddbe2aac0', {
      elementos: {
        NumFact
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getArtSerialFlag(articulo): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/83baadf78edc98000000', {
      elementos: {
        articulo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getGuiaRemision(strNumDoc, articulo): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/41dd87a4adbad400000', {
      elementos: {
        strNumDoc, articulo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  contarSeriales(articulo, strNumDoc, linea): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/198bf55be3b7250000', {
      elementos: {
        articulo, strNumDoc, linea
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }


  obtenerClave(CodAut, Clave): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/c2faebbb6caac00', {
      elementos: {
        CodAut, Clave
      }
    }, this.confIniciales.httpOptions());
  }

  consultarNumeracion(cadena): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/3317e7abd56efe000', {
      elementos: {
        p_Tipo: 'C',
        p_Cadena: cadena,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarEstadoReferencia(strEstado): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/41dd773aa6e3700000000000', {
      elementos: {
        strEstado
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarPorcentajeIva(): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/83bb9c6d8ebf58000000', {
      elementos: {
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getdatosvendedor(strVenClave): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/83bad5771cfbb0000000', {
      elementos: {
        strVenClave
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getlistapreciosVendedor(strVenCodigo): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/41ddacb9d567700000000000', {
      elementos: {
        strVenCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrardatoscli(strValorCajTexto): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/ebb317eed5b6a80000000', {
      elementos: {
        strValorCajTexto
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  verificarExisteCli(strCliCodigo): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/3eedc9f262b6e60000000', {
      elementos: {
        strCliCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }


  verificarExisteVen(strVenCodigo): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/7ddb93e4c56dcc000000', {
      elementos: {
        strVenCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarNumeroFactura(txtReferencia): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/3aecc5fbb56efe00000', {
      elementos: {
        txtReferencia
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarestado(lblNumFact, strNumNVOrg): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/54cef95592b7940000', {
      elementos: {
        lblNumFact, strNumNVOrg
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarorgimp(strNumNVOrg, lblAsiento, lblNumFact): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/94cef95592b7980000', {
      elementos: {
        strNumNVOrg, lblAsiento, lblNumFact
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarTrnKardexFac(strNumFact): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/a677caac95bef00000000', {
      elementos: {
        p_encfac_numero: strNumFact,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  agregarPromocionesFac(strNumFact, cfg_codigo_1, cfg_codigo_2): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/a86dd056f3bc58000000000000', {
      elementos: {
        p_encfac_numero: strNumFact,
        p_com_codigo: '01',
        p_cfg_codigo_1: cfg_codigo_1,
        p_cfg_codigo_2: cfg_codigo_2
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  consultarArticuloSelProm(strCodArticulo) {
    const FPLOTSIM = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FPLOTSIM').CFG_VALOR1;
    const strFecha = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    const strHora = this.datePipe.transform(new Date(), 'HH:mm');

    return this.http.post<any[]>(this.way + '/ventas/facturas/w4fejsnym7i0pmt', {
      elementos: {
        LISTA: 'A',
        BOD_CODIGO: '001',
        strFecha: strFecha + '',
        strHora: strHora + '',
        ART_CODIGO: strCodArticulo,
        COM_CODIGO: '01'
      },
      datos: {
        FPLOTSIM: FPLOTSIM,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  crearNuevoItemServ(): VenDetfacZ {
    this.indicador = this.init.tabs[this.init.tabindex].indice;
    const strCodBodega = this.encfac[this.init.tabs[this.init.tabindex].indice].BOD_CODIGO;

    const detfac: VenDetfacZ = {};

    const ARTANTBOD = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ARTANTBOD').CFG_VALOR1;
    if (ARTANTBOD === 1) {
      detfac.BOD_CODIGO = '';
    } else {
      detfac.BOD_CODIGO = strCodBodega;
    }

    const BODCENCOS = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'BODCENCOS').CFG_VALOR1;
    if (BODCENCOS === 1) {
      detfac.CEN_CODIGO = strCodBodega;
    } else {
      detfac.CEN_CODIGO = '';
    }

    const CENCOSBOD = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CENCOSBOD').CFG_VALOR1;
    if (CENCOSBOD === 1) {
      this.erCrearNuevoItemServ(strCodBodega).subscribe(cen => {
        if (cen[0] !== undefined) {
          detfac.CEN_CODIGO = cen[0].CEN_CODIGO;
        }
      });
    } else {
      detfac.CEN_CODIGO = '';
    }

    detfac.ENCFAC_NUMERO = this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_NUMERO;
    detfac.COM_CODIGO = '01';
    detfac.DETCOT_LINEA = 0;
    detfac.DETFAC_ARTACAMBIO = '';
    detfac.DETFAC_BASECERO = 0;
    detfac.DETFAC_BASEICE = 0;
    detfac.DETFAC_BASEIVA = 0;
    detfac.DETFAC_CANTIDAD = 1;
    detfac.DETFAC_PRECIO = 0;
    detfac.DETFAC_DESCUENTO = 0;
    detfac.DETFAC_TOTAL = 0;
    detfac.DETFAC_CODIGO = '';
    detfac.DETFAC_CODIGOREF = '';
    detfac.DETFAC_CONCODCSTVTA = '';
    detfac.DETFAC_CONCODINV = '';
    detfac.DETFAC_DESCRIPCION = '';
    detfac.DETFAC_LINEA = this.detfac[this.init.tabs[this.init.tabindex].indice].length + 1;
    detfac.DETFAC_LISTA = this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_LISTA;
    detfac.DETFAC_LINEA_3 = 0;
    detfac.ENCFAC_IVA = this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_IVA;
    // datos iniciales
    detfac.DETFAC_PORCEICE = 0;
    detfac.ENCCOT_NUMERO = '';
    detfac.ENCPED_NUMERO = '';
    detfac.ENCGRE_NUMERO = '';
    detfac.DETGRE_LINEA = '';
    detfac.DETFAC_PROMOCION = '0';
    detfac.DETFAC_LINORGPROM = '';
    detfac.MAEPRM_CODIGO = '';
    detfac.DETFAC_PRECIOC = 0;
    detfac.DETFAC_TIPOSRI = '';
    detfac.ORDTRA_NUMERO = '';
    detfac.DETORDTRA_LINEA = '';
    detfac.ENCREQ_NUMERO = '';
    detfac.DETREQ_LINEA = '';
    detfac.ENCMAT_NUMERO = '';
    detfac.DETMAT_CODIGO = '';
    detfac.DETFAC_CANTIDADUND = '';
    detfac.DETFAC_BASENOOBJIVA = 0;
    detfac.DETFAC_ICE = 0;
    detfac.DETFAC_IVA = 0;
    detfac.DETFAC_TRIBIVA = '';
    detfac.DETFAC_TRIBICE = '';
    detfac.DETFAC_TRIBASENOOBJIVA = '';
    detfac.UNI_SIMBOLO = '';
    detfac.DETPED_LINEA = '';
    detfac.DETFAC_UNIDAD = '';
    detfac.ART_MULTIUNIDAD = '';

    return detfac;
  }

  erCrearNuevoItemServ(strCodBodega) {
    return this.http.post(this.way + '/ventas/facturas/w0qsbp43koievfy', {
      elementos: {
        CodBodega: strCodBodega
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarArticulo(getDetFacCodigo) {
    return this.http.post(this.way + '/ventas/puntodeventa/ebb317eed5b5700000000', {
      elementos: {
        getDetFacCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  calcularTotalesFact2() {
    /*   const listaDetalle = [];
   
       for (const item of this.detfac[this.init.tabs[this.init.tabindex].indice]) {
   
         const itemTot = {};
   
         itemTot.setDblBaseCero = item.DETFAC_BASECERO;
         itemTot.setDblBaseIce = item.DETFAC_BASEICE;
         itemTot.setDblBaseIva = item.DETFAC_BASEIVA;
         itemTot.setDblCantidad = item.DETFAC_CANTIDAD;
         itemTot.setDblDescuento = item.DETFAC_DESCUENTO;
         itemTot.setDblIce = item.DETFAC_ICE;
         itemTot.setDblIva = item.DETFAC_IVA;
         itemTot.setDblPorceIce = item.DETFAC_PORCEICE;
         itemTot.setDblPrecio = item.DETFAC_PRECIO;
         itemTot.setDblTotal = item.DETFAC_TOTAL;
         itemTot.setStrCodigo = item.DETFAC_CODIGO;
         itemTot.setStrPromocion = item.DETFAC_PROMOCION;
         itemTot.setStrTipoDet = item.DETFAC_TIPODET;
         itemTot.setStrTribIce = item.DETFAC_TRIBICE;
         itemTot.setStrTribIva = item.DETFAC_TRIBIVA;
   
         listaDetalle.push(itemTot);
       }*/
    /*
        this.calcularTotales(listaDetalle, NUMDECTOTAL,
          dlgPtoVta.NUMDECSIST, dlgPtoVta.getTablaFactura(),
          dlgPtoVta.dblValorDescGlb, dlgPtoVta.dblPorcDescGlb,
          dlgPtoVta.dblPorcIva, dlgPtoVta.getHmpConfiguracion().get("DESICE"), 0, dlgPtoVta.lblDescuento,
          dlgPtoVta.lblNeto, dlgPtoVta.lblIce, dlgPtoVta.lblIva,
          dlgPtoVta.lblBaseCero, dlgPtoVta.lblBaseIva,
          dlgPtoVta.lblBaseIce, dlgPtoVta.lblTotal,
          dlgPtoVta.lblBaseNoImpIva);
    */
    /*
        if (listaDetalle.length > 0) {
          let index = 0;
          for (const item of this.detfac[this.init.tabs[this.init.tabindex].indice]) {        
            let itemTot = listaDetalle[index];
    
            item.DETFAC_TOTAL = itemTot.getDblTotal;
            item.DETFAC_BASECERO = itemTot.getDblBaseCero;
            item.DETFAC_BASEICE = itemTot.getDblBaseIce;
            item.DETFAC_BASEIVA = itemTot.getDblBaseIva;
            item.DETFAC_ICE = itemTot.getDblIce;
            item.DETFAC_IVA = itemTot.getDblIva;
            
          }
        }
    
        this.dblValorTotal = UtilidadesVen.dblValorTotal;
        this.dblCostoTotalItem = UtilidadesVen.dblCostoTotalItem;
        dlgPtoVta.dblValorIceItem = UtilidadesVen.dblValorIceItem;
        dlgPtoVta.dblBaseIceItem = UtilidadesVen.dblBaseIceItem;
        dlgPtoVta.dblBaseIceTotal = UtilidadesVen.dblBaseIceTotal;
        dlgPtoVta.dblValorIceTotal = UtilidadesVen.dblValorIceTotal;
        dlgPtoVta.dblBaseCeroTotal = UtilidadesVen.dblBaseCeroTotal;
        dlgPtoVta.dblBaseIvaTotal = UtilidadesVen.dblBaseIvaTotal;
        dlgPtoVta.dblValorIvaTotal = UtilidadesVen.dblValorIvaTotal;
        dlgPtoVta.dblValorNeto = UtilidadesVen.dblValorNeto;
    */

  }

  calcularTotalesFact() {
    console.log('calcularTotalesFact');
    this.dblPorcIva = 12;
    const DESICE = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DESICE').CFG_VALOR1;
    const ICEBIVAV = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ICEBIVAV').CFG_VALOR1;
    let dblValorTotal;
    let dblCostoTotalItem = 0;
    let dblValorIceItem = 0;
    let dblBaseIceItem = 0;
    let dblBaseIceTotal = 0;
    let dblValorIceTotal = 0;
    let dblBaseCeroTotal = 0;
    let dblBaseNoObjetoIva = 0;
    let dblBaseIvaTotal = 0;
    let dblValorIvaTotal = 0;
    let dblValorNeto = 0;
 // decimales del detalle de la factura
//  for (let fila = 1; fila <= this.detfac[this.init.tabs[this.init.tabindex].indice].length; fila++) {
//   this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_CANTIDAD =
//     Number(this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_CANTIDAD).toFixed(this.NUMDECCANTIDAD);
//   this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PRECIO =
//     Number(this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PRECIO).toFixed(this.NUMDECPRECIO);
//   this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TOTAL =
//     Number(this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TOTAL).toFixed(this.NUMDECTOTAL);
//   this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_DESCUENTO =
//     Number(this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_DESCUENTO).toFixed(this.confIniciales.getNumDecSist());
// }





    for (let fila = 1; fila <= this.detfac[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      if (this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TIPODET !== '*') {
        if (this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].ENCPED_NUMERO === null ||
          this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].ENCPED_NUMERO === undefined) {
          this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETPED_LINEA = fila;
        }
      }

      if (this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TIPODET !== '*') {
        if (this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_CODIGO.length > 0) {
          //dblCostoTotalItem = this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_CANTIDAD *
            //this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PRECIO;
            //CDPJ
          let val=10 ** this.NUMDECTOTAL;
          let total=Math.round(this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_CANTIDAD
             * val * this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PRECIO) / val;
          dblCostoTotalItem = Number(total.toFixed(this.NUMDECTOTAL));
          console.log(dblCostoTotalItem);
          //CDPJ
          const dblDsct = dblCostoTotalItem * (this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_DESCUENTO / 100);
          dblCostoTotalItem = dblCostoTotalItem - dblDsct;

         // dblCostoTotalItem=Number(Number(dblCostoTotalItem).toFixed(this.NUMDECTOTAL));//CDPJ
         dblCostoTotalItem=this.redondearDecimales(dblCostoTotalItem)
          //this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TOTAL = dblCostoTotalItem; // costo total por línea de detalle
          this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TOTAL = Number(dblCostoTotalItem.toFixed(this.NUMDECTOTAL)); // costo total por línea de detalle CDPJ

          // cálculo del ice por línea de detalle
          if (this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TRIBICE != null) { // si tributa ice
            if (this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TRIBICE === 'S') {
              if (DESICE === 1) { // si desglosa ice en la factura
                dblBaseIceItem = this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_CANTIDAD *
                  this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PRECIO;
                this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASEICE = dblBaseIceItem;

                if (this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PORCEICE > 0) {
                  dblValorIceItem = dblBaseIceItem * this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PORCEICE / 100;
                } else if (this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_ICE > 0) {
                  dblValorIceItem = this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_ICE;
                }

                this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_ICE = dblValorIceItem;
              }
            }
          }

          // càlculo del iva por línea de detalle
          let dblBaseIvaItem = 0;
          let dblValorIvaItem = 0;

          if (this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PROMOCION === '0' &&
            this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TRIBIVA === 'S') {
            dblBaseIvaItem = dblCostoTotalItem * (1 - this.dblPorcDescGlb/ 100);
          } else if (this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PROMOCION === '1' &&
            this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_DESCUENTO === 100
            && this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TRIBIVA === 'S') {
            dblBaseIvaItem = this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_CANTIDAD *
              this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PRECIO;
          } else if (this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PROMOCION === '1' &&
            this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TRIBIVA === 'S') {
            dblBaseIvaItem = dblCostoTotalItem * (1 - this.dblPorcDescGlb / 100);
          } else {
            dblBaseIvaItem = 0;
          }

          if (this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TRIBIVA === 'S') {

            if (this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TRIBICE === 'S') {

              if (ICEBIVAV === 1) {// si ice forma parte de la base imponible
                dblBaseIvaItem = dblBaseIvaItem + this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_ICE;
              }
            }


            this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASECERO = 0;
            this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASENOOBJIVA = 0;

            dblValorIvaItem = dblBaseIvaItem * (Number(this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PORIVA) / 100);

            this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_IVA = dblValorIvaItem;
          } else {

            this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASECERO = 0;
            this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASENOOBJIVA = 0;

            if (this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TRIBASENOOBJIVA !== 'S') {
              this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASECERO =
                dblCostoTotalItem * (1 - this.dblPorcDescGlb / 100);
            } else {
              this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASENOOBJIVA =
                dblCostoTotalItem * (1 - this.dblPorcDescGlb / 100);
            }

            dblBaseIvaItem = 0;
            this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_IVA = 0;
          }

          this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASEIVA = dblBaseIvaItem;
        }
      }
      this.sumarCantidadTotal();
    }

    // TOTALES DEL DOCUMENTO
    for (let fila = 1; fila <= this.detfac[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      if (this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TIPODET !== '*') {
        dblValorNeto = dblValorNeto + Number (Number(this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TOTAL).toFixed(this.NUMDECTOTAL));//CDPJ
console.log('detfac',this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TOTAL)
        if (this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TRIBICE === 'S') {
          dblValorIceTotal = dblValorIceTotal + this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_ICE;
          dblBaseIceTotal = dblBaseIceTotal + this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASEICE;
        }
      }

      dblBaseCeroTotal = dblBaseCeroTotal + this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASECERO;
      dblBaseIvaTotal = dblBaseIvaTotal + this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASEIVA;
      dblValorIvaTotal = dblValorIvaTotal + this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_IVA;
      dblBaseNoObjetoIva = dblBaseNoObjetoIva + this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASENOOBJIVA;

    }
    // cálculo del total de la factura

    this.dblValorDescGlb = dblValorNeto * this.dblPorcDescGlb / 100;
    dblValorTotal = dblValorNeto + dblValorIvaTotal + dblValorIceTotal - this.dblValorDescGlb;

    // decimales del detalle de la factura
    for (let fila = 1; fila <= this.detfac[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_CANTIDAD =
        Number(this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_CANTIDAD).toFixed(this.NUMDECCANTIDAD);
      this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PRECIO =
        Number(this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PRECIO).toFixed(this.NUMDECPRECIO);
      this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TOTAL =
        Number(this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TOTAL).toFixed(this.NUMDECTOTAL);
      this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_DESCUENTO =
        Number(this.detfac[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_DESCUENTO).toFixed(this.confIniciales.getNumDecSist());
    }
console.log('neto',dblValorNeto);
    // colocar los valores en los labels del pie de la factura
    this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_TOTALNETO = Number(dblValorNeto).toFixed(this.confIniciales.getNumDecSist());
    this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_PORCEDES = Number(this.dblPorcDescGlb).toFixed(2);
    this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORDES = Number(this.dblValorDescGlb).toFixed(2);
    this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORICE = Number(dblValorIceTotal).toFixed(this.confIniciales.getNumDecSist());
    this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASEICE = Number(dblBaseIceTotal).toFixed(this.confIniciales.getNumDecSist());
    this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASECERO = Number(dblBaseCeroTotal).toFixed(this.confIniciales.getNumDecSist());
    this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASEIVA = Number(dblBaseIvaTotal).toFixed(this.confIniciales.getNumDecSist());
    this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORIVA = Number(dblValorIvaTotal).toFixed(this.confIniciales.getNumDecSist());
    this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_TOTAL = Number(dblValorTotal).toFixed(this.confIniciales.getNumDecSist());
    this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASENOOBJIVA =
      Number(dblBaseNoObjetoIva).toFixed(this.confIniciales.getNumDecSist());

    this.dblValorTotal[this.init.tabs[this.init.tabindex].indice] = dblValorTotal;
    this.dblCostoTotalItem[this.init.tabs[this.init.tabindex].indice] = dblCostoTotalItem;
    this.dblValorIceItem[this.init.tabs[this.init.tabindex].indice] = dblValorIceItem;
    this.dblBaseIceItem[this.init.tabs[this.init.tabindex].indice] = dblBaseIceItem;
    this.dblBaseIceTotal[this.init.tabs[this.init.tabindex].indice] = dblBaseIceTotal;
    this.dblValorIceTotal[this.init.tabs[this.init.tabindex].indice] = dblValorIceTotal;
    this.dblBaseCeroTotal[this.init.tabs[this.init.tabindex].indice] = dblBaseCeroTotal;
    this.dblBaseIvaTotal[this.init.tabs[this.init.tabindex].indice] = dblBaseIvaTotal;
    this.dblValorIvaTotal[this.init.tabs[this.init.tabindex].indice] = dblValorIvaTotal;
    this.dblValorNeto[this.init.tabs[this.init.tabindex].indice] = dblValorNeto;

    this.comas();
  }

  sumarCantidadTotal() {
    let dblTotal = 0;
    for (const item of this.detfac[this.init.tabs[this.init.tabindex].indice]) {
      if (item.DETFAC_TIPODET === 'A') { // si es artículo cambiar el color del precio y la cantidad
        dblTotal = dblTotal + Number(item.DETFAC_CANTIDAD);
      }
    }

    this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_TOTALCANT = dblTotal.toFixed(2);
  }
    //CDPJ
    redondearDecimales(num) {
      let val=0
      console.log(num);
      val=(Math.round((num + Number.EPSILON) *100)/100);
      val=Number(Number(val).toFixed(this.NUMDECTOTAL));
      console.log(val.toFixed(2))
      //console.log('>><<<<<',total)
      return val;
    }
    //CDPJ

  comas() {
    this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_TOTALNETO = this.init.moneyValidation(this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_TOTALNETO);
    this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_PORCEDES = this.init.moneyValidation(this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_PORCEDES);
    this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORDES = this.init.moneyValidation(this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORDES);
    this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORICE = this.init.moneyValidation(this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORICE);
    this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASEICE = this.init.moneyValidation(this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASEICE);
    this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASECERO = this.init.moneyValidation(this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASECERO);
    this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASEIVA = this.init.moneyValidation(this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASEIVA);
    this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORIVA = this.init.moneyValidation(this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORIVA);
    this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_TOTAL = this.init.moneyValidation(this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_TOTAL);
    this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASENOOBJIVA = this.init.moneyValidation(this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASENOOBJIVA);
  
  console.log('final',this.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_TOTALNETO)
}



  encontrarreferencia(reftipo) {
    return this.http.post(this.way + '/ventas/puntodeventa/3aecc5fbb56f6e0000000000', {
      elementos: {
        reftipo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarcliente(txtRuc, strGruCodigo, strCodigoVendedor, txtNombre, strNombreCorto, strTipoIde,
    txtDireccion, txtTelefono, txtEmail1, strCuentaContable, cli_fechaing,strctan) {
    return this.http.post(this.way + '/ventas/puntodeventa/4afc76faadb2b400000', {
      elementos: {
        txtRuc, strGruCodigo, strCodigoVendedor, txtNombre, strNombreCorto, strTipoIde,
        txtDireccion, txtTelefono, txtEmail1, strCuentaContable, cli_fechaing,strctan
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
//CDPJ
actualizarcliente(txtRuc, strGruCodigo, strCodigoVendedor, txtNombre, strNombreCorto, strTipoIde,
  txtDireccion, txtTelefono, txtEmail1, strCuentaContable,strctan) {
  return this.http.post<any[]>(this.way + '/ventas/puntodeventa/299df2ab2', {
    elementos: {
      txtRuc, strGruCodigo, strCodigoVendedor, txtNombre, strNombreCorto, strTipoIde,
      txtDireccion, txtTelefono, txtEmail1, strCuentaContable,strctan
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
//CDPJ
//CDPJ
verificaserie(usuario,strTipoNum)  {
  return this.http.post<any[]>(this.way + '/ventas/puntodeventa/3eedc9f262b7c80000', {
    elementos: {
      usuario,
      strTipoNum
    }
  }, this.confIniciales.httpOptions());
}
cantidadequivalencia(DETFAC_UNIDAD){
  return this.http.post(this.way + '/ventas/puntodeventa/41dd62afd9354c000000000000000', {
    elementos: {
      DETFAC_UNIDAD
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
//CDPJ
  actualizarValidacion(valida, clicodigo) {
    return this.http.post<any>(this.way + '/ventas/cliente/299df2ab256fea0000000000', {
      elementos: {
        valida, clicodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }


  encontrarEstadoCli(txtCodCliente) {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/ebb317eed5b7700000000', {
      elementos: {
        txtCodCliente
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  contarTrnBanDoc(txtCodCliente) {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/83bbdddd6abb700', {
      elementos: {
        txtCodCliente
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarTipoCli(txtCodCliente) {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/75d98bf76adf64000000', {
      elementos: {
        txtCodCliente
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  desplegarvendedor(txtCodCliente) {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/d773357415bfb80000000', {
      elementos: {
        txtCodCliente
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  seleccionarRegistro(tipo, cadena, condicion) {
    return this.http.post<any>(this.way + '/ventas/facturas/1c755ccebd4cf0000', {
      elementos: {
        p_tipo: tipo,
        p_Cadena: cadena,
        p_condicion: condicion
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarTrnDoc(numDoc, tipoDoc) {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/1d595a5756fbbc0000', {
      elementos: {
        TRNDOC_NUMDOC: numDoc,
        TRNDOC_TIPO: tipoDoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  busquedaVenEncFac(condicion) {
    return this.http.post(this.way + '/ventas/puntodeventa/17ee315b7a99df0000', {
      elementos: {
        condicion
      },
    }, this.confIniciales.httpOptions());
  }

  existePagosCxC(encfacnumero) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/1f056ebe2ab270000', {
      elementos: {
        TRNDOCUMENTO_NRODOC: encfacnumero,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarFactura(strNumFact) {
    return this.http.post<any>(this.way + '/ventas/facturas/7565693d4cefb80', {
      elementos: {
        p_ENCFAC_numero_1: strNumFact,
        p_COM_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizaEstadoFactura(strNumFact, strCodCliente) {
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/b05y8h0amat3jwv', {
      elementos: {
        p_com_codigo: '01',
        p_cli_codigo: strCodCliente,
        p_encfac_numero: strNumFact
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizaFormaPagoFact(strNumFact, strTipoFP) {
    return this.http.post<any>(this.way + '/ventas/pagocontado/9iaxy04f9w9toch', {
      elementos: {},
      datos: {
        TipoFP: strTipoFP,
        NumFact: strNumFact
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerEstadoFactura(strNumero) {
    return this.http.post(this.way + '/ventas/devfactura/edbaec7a98ee7800', {
      elementos: {
        encfac_numero: strNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  existePagoDirecto(NumFac) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/icwisntz58696rf', {
      elementos: {},
      datos: {
        NumFac
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  verificarpermobjclave(strDialogo, strObjeto, intPestania, usuarioConectado) {
    return this.http.post<any[]>(this.way + '/ventas/puntodeventa/1f76e4f9315bcc000000000', {
      elementos: {
        strDialogo, strObjeto, intPestania, usuarioConectado
      }
    }, this.confIniciales.httpOptions());
  }

  verificarpermobjdialogo(strDialogo, usuarioConectado) {
    return this.http.post<any[]>(this.way + '/ventas/puntodeventa/7ddb93e4c56f300000000', {
      elementos: {
        strDialogo, usuarioConectado
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerSaldoClienteFac(strCodliente, strNumFac) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/deniiusvrcaf0f6', {
      elementos: {
        CODCLIENTE: strCodliente,
        COM_CODIGO: '01',
        NUMFAC: strNumFac
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarAsientoContable(numfact, tipdia, asicomprobante) {
    return this.http.post<any>(this.way + '/ventas/facturas/h96wodnruz2lymi', {
      elementos: {
        p_ENCFAC_numero: numfact,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: tipdia,
        p_asi_comprobante: asicomprobante
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarAsientoContableSinCxc(numfact, tipdia, asicomprobante) {
    return this.http.post<any>(this.way + '/ventas/facturas/caw03q7bp0zdgvd', {
      elementos: {
        p_ENCFAC_numero: numfact,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: tipdia,
        p_asi_comprobante: asicomprobante
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erGenerarAsiento(encFacNumero) {
    return this.http.post(this.way + '/ventas/facturas/l0bt8h7bixv9kxw', {
      elementos: {
        ENCFAC_NUMERO: encFacNumero,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
 
  cambioarticulo(lblNumFact) {
    return this.http.post(this.way + '/ventas/facturas/18ab2e5856fb260000', {
      elementos: {
        lblNumFact,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarDetalleFact(detfac) {
    return this.http.post<any>(this.way + '/ventas/facturas/3ab2b49aeebd4c', {
      elementos: {
        p_ENCFAC_numero_1: detfac.ENCFAC_NUMERO,
        p_DETFAC_linea_2: detfac.DETFAC_LINEA,
        p_COM_codigo_3: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

//CDPJ
erBusquedaVendedor(strCampo, strTabla, strValue, strParam) {

  return this.http.post(this.way + '/ventas/facturas/3rw6uc890a4nleo', {

    elementos: {
      CAMPO: strCampo,
      VALOR: strTabla,
      VALUE: strValue,
      PARAM: strParam
    },

  }, this.confIniciales.httpOptions());

}


erpBusquedaServicios(strValor, strValue, strParams) {
  return this.http.post(this.way + '/ventas/puntodeventa/76f2bf735e735400000000000', {

    elementos: {
      Valor: strValor,
      Value: strValue,
      Params: strParams
    },

  }, this.confIniciales.httpOptions()).toPromise();
}
getdatosBodUsuario(struser)  {

  return this.http.post<any>(this.way + '/ventas/puntodeventa/1d7662fddab6bc00000000000000', {

    elementos: {
      USU_IDENTIFICACION: struser,
    },

  }, this.confIniciales.httpOptions()).toPromise();

}
leerValidarRucsFacElec() {

  return this.http.post<any[]>(this.way + '/seg/compania/198bf3d5eefcce000000000', {
    elementos: {},
    esquema: {
      usuario: this.usuarioEsquema.esquema,
      password: this.usuarioEsquema.password
    }
  }, this.confIniciales.httpOptions()).toPromise();;
}
/*  obtenerClave(CodAut, Clave): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/puntodeventa/c2faebbb6caac00', {
      elementos: {
        CodAut, Clave
      }
    }, this.confIniciales.httpOptions());
  }*/
//CDPJ




}
