<p-toast [style]="{marginTop: '41vh'}" position="top-center"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmlogin" title="Pregunta"
				 appendTo="body"></p-confirmDialog>
<div class="login-body">
	<div class="card login-panel ui-fluid" id="login-panel">
		<div class="login-panel-content">
			<div class="p-grid">				
				<div *ngIf="loginactivo==true" class="p-col-3">
				</div>
				<div *ngIf="loginactivo==true" class="p-col-9">
					<div class="p-col-12">
						<button (click)="openLogin(1)" pButton type="button" icon="pi pi-download"
								label="Descargar"></button>
					</div>
					<div class="p-col-12">
						<button (click)="openLogin(2)" pButton type="button" icon="pi pi-globe"
								label="Ingresar"></button>
					</div>
				</div>


				<!--si es Descarga-->
				<div *ngIf="loginsel==1" class="p-col-12" style="padding-top: 30%">
					<div class="p-col-12 username-container">
						<!-- <h:panelGroup> -->
						<label>ID</label>
						<div class="login-input">
							<input id="imputDescargas" [(ngModel)]="idDescargas" type="text" pInputText>
						</div>
						<!-- </h:panelGroup> -->
					</div>
					<div class="p-col-12 password-container">
						<!-- <h:panelGroup> -->
						<label>Contraseña</label>
						<div class="login-input">

							<input [(ngModel)]="passDescargas" (keydown)="loginwithEnterDescargas($event)" pPassword
								   type="password"/>
						</div>
						<!-- </h:panelGroup> -->
					</div>
					<div class="p-col-12">
						<!-- <h:panelGroup> -->

						<a routerLink="/recuperarpasswd">Olvido su contraseña</a>

						<!-- </h:panelGroup> -->
					</div>
					<div *ngIf="captchaexiste==true" class="p-col-12">
						<p-captcha siteKey="{{secretKeyCaptcha}}" (onResponse)="showResponse($event)"></p-captcha>
					</div>
					<div class="p-col-12 p-sm-6 p-md-6">
						<button (click)="loginDescargas()" pButton type="button" icon="pi pi-user"
								label="Ingresar"></button>
					</div>
					<div class="p-col-12 p-sm-6 p-md-6">
						<button (click)="back()" pButton type="button" icon="pi pi-undo" class="ui-button-secondary"
								label="Regresar"></button>
					</div>
				</div>


				<!--si es empresa-->
				<div *ngIf="loginsel==2" class="p-col-12" style="padding-top: 30%">
					<div class="p-col-12">
						<!--	<label>Empresa</label>
							<p-dropdown [options]="empresas" [(ngModel)]="empresasel"
										optionLabel="EMPRESA_NOMBRE"></p-dropdown>
										-->
						<label>Empresa</label>
						<div class="login-input">
							<input id="empresa" [(ngModel)]="empresasel" type="text" pInputText>
						</div>
					</div>
					<div class="p-col-12 username-container">
						<!-- <h:panelGroup> -->
						<label>Usuario</label>
						<div class="login-input">
							<input id="input" [(ngModel)]="usuario" type="text" pInputText>
						</div>
						<!-- </h:panelGroup> -->
					</div>
					<div class="p-col-12 password-container">
						<!-- <h:panelGroup> -->
						<label>Contraseña</label>
						<div class="login-input">

							<input [(ngModel)]="pass" (keydown)="loginwithEnter($event)" pPassword type="password"/>
						</div>
						<!-- </h:panelGroup> -->
					</div>
					<div *ngIf="captchaexiste==true" class="p-col-12">
						<p-captcha siteKey="{{secretKeyCaptcha}}" (onResponse)="showResponse($event)"></p-captcha>
					</div>
					<div class="p-col-12 p-sm-6 p-md-6">
						<button (click)="login()" pButton type="button" icon="pi pi-user" label="Ingresar"></button>
					</div>
					<div class="p-col-12 p-sm-6 p-md-6">
						<button (click)="back()" pButton type="button" icon="pi pi-undo" class="ui-button-secondary"
								label="Regresar"></button>
					</div>
				</div>
				<!--si es contador-->
				<div *ngIf="loginsel==3" class="p-col-12" style="padding-top: 30%">
					<div class="p-col-12 username-container">
						<!-- <h:panelGroup> -->
						<label>ID</label>
						<div class="login-input">
							<input id="idcontador" [(ngModel)]="idcontador" type="text" pInputText>
						</div>
						<!-- </h:panelGroup> -->
					</div>
					<div class="p-col-12 password-container">
						<!-- <h:panelGroup> -->
						<label>Contraseña</label>
						<div class="login-input">

							<input [(ngModel)]="passcontador" (keydown)="loginSoyContador($event)" pPassword
								   type="password"/>
						</div>
						<!-- </h:panelGroup> -->
					</div>
					<div *ngIf="existecontador==true">
						<div class="p-col-12">
							<label>Empresa</label>
							<p-dropdown [options]="empresasContador" [(ngModel)]="empresasel"
										optionLabel="EMPRESA_NOMBRE"></p-dropdown>
						</div>
						<div class="p-col-12 username-container">
							<!-- <h:panelGroup> -->
							<label>Usuario</label>
							<div class="login-input">
								<input id="inputcontador" [(ngModel)]="usuario" type="text" pInputText>
							</div>
							<!-- </h:panelGroup> -->
						</div>
						<div class="p-col-12 password-container">
							<!-- <h:panelGroup> -->
							<label>Contraseña</label>
							<div class="login-input">

								<input [(ngModel)]="pass" (keydown)="loginwithEnter($event)" pPassword
									   type="password"/>
							</div>
							<!-- </h:panelGroup> -->
						</div>
					</div>
					<div class="p-col-12 p-sm-6 p-md-6">
						<button (click)="loginContador()" pButton type="button" icon="pi pi-user"
								label="Ingresar"></button>
					</div>
					<div class="p-col-12 p-sm-6 p-md-6">
						<button (click)="back()" pButton type="button" icon="pi pi-undo" class="ui-button-secondary"
								label="Regresar"></button>
					</div>
				</div>
				<!--<div class="p-col-12 p-sm-6 p-md-6 logo-container">
					<span class="guest-sign-in">Mi IP: {{ ipAddress }}</span>
				</div>-->
			</div>
		</div>
	</div>
</div>

<p-dialog header="Token" [(visible)]="displayToken"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [closable]="true"
		  [showHeader]="true">
	<div style="background-color: rgb(235,245,251); padding: 1.5em;">
		<div class="p-col-12" style="text-align: center">
			<input style="font-size: 25pt; width: 150px" type="password" [(ngModel)]="tokenusuario" (keydown)="enterToken($event)" pInputText autocomplete="off">
		</div>
	</div>
</p-dialog>
