<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="datosalt"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="datosaltconf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<app-invmaebarra [botonNuevo]="botonNuevo" [botonGuardar]="botonGuardar" [botonBorrar]="botonBorrar"
				 [botonRegresar]="botonRegresar"
				 (signal)="manejarSenales($event)" [barraBotonesDesplaz]="true" [barraBotones3]="true"
				 [barraBotonesAsiCon]="true" [barraBotones5]="true" [imprimir]="false"
></app-invmaebarra>
<div class="divgrups">
	<div class="p-grid">
		<div class="p-col-1">
			<span>Código:</span>
		</div>
		<div class="p-col-3">
			<input id="codigoArtSer" type="text" pInputText disabled
				   [(ngModel)]="strCodigo" autocomplete="off">
		</div>
		<div class="p-col-2">
			<span style="float: right">Descripción:</span>
		</div>
		<div class="p-col-5">
			<input id="descripcionArtSer" type="text" pInputText disabled
				   [(ngModel)]="strDescripcion" autocomplete="off">
		</div>
		<div class="p-col-1">
		</div>
		<div class="p-col-12">
			<app-aggridsaci [width]=""
							[height]="'650'"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="detalle"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsDetalle"
							[defaultColDef]="defaultColDefDetalle"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)="selecciondetalle($event)"
							[notlistenkey]="true"
							(click)="selecciondetalle($event)">
			</app-aggridsaci>
		</div>

	</div>
</div>
