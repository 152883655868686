import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Nommaetiporol} from '../nominterfaces/nommaetiporol';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class NommaetiporolService {
  
  public way: string;

  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService
  ) {
    this.way = sessionStorage.getItem('way');
   
  }
  
  cargarTipoRoles(){
    let strSentencia = 'select * from nom_maetiporol';
    
    return this.http.post<any[]>(this.way + '/nom/tiporoles/617decb3bc55dc', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  insertar( nommaeTipoRol: Nommaetiporol ) {
    
    let strSentencia = 'insert into nom_maetiporol ( tiprol_codigo, tiprol_nombre )' +
      'VALUES (' +
      '\'' + nommaeTipoRol.TIPROL_CODIGO + '\',' +
      '\'' + nommaeTipoRol.TIPROL_NOMBRE + '\')';
    
    
    return this.http.post<any[]>(this.way + '/nom/tiporoles/12bf1dbef659c7000', {
        elementos: {
          TIPROL_CODIGO: nommaeTipoRol.TIPROL_CODIGO,
          TIPROL_NOMBRE: nommaeTipoRol.TIPROL_NOMBRE
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  actualizar( nommaeTipoRol: Nommaetiporol ) {
    
    let strSentencia = 'update nom_maetiporol  ' +
      'set tiprol_codigo = \'' + nommaeTipoRol.TIPROL_CODIGO + '\',' +
      ' tiprol_nombre = \'' + nommaeTipoRol.TIPROL_NOMBRE + '\' ' +
      'where tiprol_codigo = \'' + nommaeTipoRol.old_TIPROL_CODIGO + '\'';
    
    return this.http.post<any[]>(this.way + '/nom/tiporoles/533be57659c6f00', {
        elementos: {
          TIPROL_CODIGO: nommaeTipoRol.TIPROL_CODIGO,
          TIPROL_NOMBRE: nommaeTipoRol.TIPROL_NOMBRE,
          old_TIPROL_CODIGO: nommaeTipoRol.old_TIPROL_CODIGO
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
    
  }
  
  borrar(  nommaeTipoRol: Nommaetiporol ) {
    let strSentencia = 'delete from nom_maetiporol  ' +
      'where tiprol_codigo = \'' + nommaeTipoRol.old_TIPROL_CODIGO + '\'';
      
    return this.http.post<any[]>(this.way + '/nom/tiporoles/7565697659c6f00', {
        elementos: {
          old_TIPROL_CODIGO: nommaeTipoRol.old_TIPROL_CODIGO
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
    
  }
}

