import { Component, OnInit, ViewChild } from '@angular/core';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { TecMaeetiqueta } from '../tecinterfaces/tecmaeetiqueta';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { TecmaeetiquetaService } from '../tecservicios/tecmaeetiqueta.service';
import { SegMaePermiso } from 'src/app/advantage/advantageinterfaces/segmaepermiso';
import { MessageService, ConfirmationService } from 'primeng';
import { PermisosService } from 'src/app/advantage/advantageservices/permisos.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-tecmaeetiqueta',
  templateUrl: './tecmaeetiqueta.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class TecmaeetiquetaComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;

  tecmaeetiqueta: TecMaeetiqueta[];
  etiquetaSeleccionado: TecMaeetiqueta;

  permisoEtiqueta: SegMaePermiso;
  messagekey = 'maeetiqueta';
  confirmkey = 'confirmmaeetiqueta';

  largo: string;
  ancho: string;
  public frameworkComponents;
  rowStyle;
  defaultColDef;
  rowSelection = 'multiple';

  columnDefs = [
    {
      headerName: 'Código', field: 'ETI_CODIGO', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
        }
      }
    },
    {
      headerName: 'Nombre', field: 'ETI_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 30
        }
      }
    }
  ]

  constructor(public etiquetaService: TecmaeetiquetaService, public init: NuevoComponentService,
    private messageService: MessageService, private permisosService: PermisosService,
    private confirmationService: ConfirmationService, private datePipe: DatePipe) {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultColDef = {
      editable: true,
      width: 100,
      objeto: 'maeetiqueta'
    };
  }

  async ngOnInit() {
    this.largo = '400';
    this.ancho = '50';

    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;

    this.tecmaeetiqueta = [];

    await this.getPermisos();
    await this.cancelar();
  }

  async getPermisos() {
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisoEtiqueta = data[0];
        } else {
          this.permisoEtiqueta = null;
        }
      } else {
        this.permisoEtiqueta = null;
      }
    } catch (e) {
      this.permisoEtiqueta = null;
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Error',
        detail: 'No se pudo obtener los permisos del formulario'
      });
    }
  }

  botonesModificar() {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = false;
    this.btnRegresar = false;
  }

  seleccion(valor) {
    if (valor.object !== null) {
      this.etiquetaSeleccionado = valor.object;
    }
  }

  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      this.nuevo();
    }
    if (valor === 'Guardar') {
      this.guardar();
    }
    if (valor === 'Borrar') {
      this.eliminar();
    }
    if (valor === 'Cancelar') {
      this.cancelar();
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }

  nuevo() {
    if (this.permisoEtiqueta.PERINSERCION === 1) {
      this.botonesModificar();

      const etiquetanuevo = {
        ETI_CODIGO: '',
        ETI_NOMBRE: '',
        CLAVE: ''
      };

      const indi = this.tecmaeetiqueta.indexOf(this.etiquetaSeleccionado);

      if (indi === -1) {
        etiquetanuevo.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.tecmaeetiqueta.push(etiquetanuevo);
        this.etiquetaSeleccionado = etiquetanuevo;
        this.aggrid.refreshaggrid(this.tecmaeetiqueta, this.defaultColDef.objeto);
      } else {
        etiquetanuevo.CLAVE = (indi + 1) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.tecmaeetiqueta.splice(indi + 1, 0, etiquetanuevo);
        this.etiquetaSeleccionado = etiquetanuevo;
        this.aggrid.refreshaggridindex(this.tecmaeetiqueta, this.defaultColDef.objeto, indi);
      }
    } else if (this.permisoEtiqueta.PERINSERCION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de inserción, acción denegada'
      });
    }
  }

  async guardar() {

    const data: any = await this.etiquetaService.encontraretiqueta(this.etiquetaSeleccionado.ETI_CODIGO);

    if (data.length === 0) {
      this.insertar();
    } else {
      this.actualizar();
    }

    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
  }

  async insertar() {
    if (this.permisoEtiqueta.PERINSERCION === 1) {
      await this.etiquetaService.insertaretiqueta(this.etiquetaSeleccionado);
      this.messageService.add({
        key: this.messagekey,
        severity: 'success',
        summary: 'Etiqueta',
        detail: 'Etiqueta guardada correctamente'
      });
    } else if (this.permisoEtiqueta.PERINSERCION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de inserción, acción denegada'
      });
    }
  }

  async actualizar() {
    if (this.permisoEtiqueta.PERACTUALIZACION === 1) {
      await this.etiquetaService.actualizaretiqueta(this.etiquetaSeleccionado);
      this.messageService.add({
        key: this.messagekey,
        severity: 'success',
        summary: 'Etiqueta',
        detail: 'Etiqueta actualizada correctamente'
      });
    } else if (this.permisoEtiqueta.PERACTUALIZACION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de inserción, acción denegada'
      });
    }
  }

  eliminar() {
    if (this.permisoEtiqueta.PERELIMACION === 1) {
      this.confirmationService.confirm({
        message: 'Esta seguro que desea eliminar la etiqueta',
        header: 'Eliminar etiqueta',
        icon: 'pi pi-exclamation-triangle',
        key: this.confirmkey,
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.etiquetaService.eliminaretiqueta(this.etiquetaSeleccionado);
          this.tecmaeetiqueta.splice(this.tecmaeetiqueta.findIndex(
            eti => eti.CLAVE === this.etiquetaSeleccionado.CLAVE), 1);
          this.aggrid.refreshaggrid(this.tecmaeetiqueta, this.defaultColDef.objeto);
          this.messageService.add({
            key: this.messagekey,
            severity: 'success',
            summary: 'Etiqueta',
            detail: 'Etiqueta eliminada correctamente'
          });
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    } else if (this.permisoEtiqueta.PERELIMACION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de inserción, acción denegada'
      });
    }
  }

  async cancelar() {
    if (this.permisoEtiqueta.PERSELECCION === 1) {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      const data: any = await this.etiquetaService.getetiquetas();
      for (const rs of data) {
        rs.CLAVE = rs.ETI_CODIGO;
      }
      this.tecmaeetiqueta = data;
    } else if (this.permisoEtiqueta.PERSELECCION === 0) {
      this.messageService.add({
        key: this.messagekey,
        severity: 'error',
        summary: 'Inserción',
        detail: 'El usuario no tiene permisos de selección, acción denegada'
      });
    }
  }

}
