<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="actmaeactivo"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="actmaeactivoConf" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 100%">
    <div class="p-col-12">
        <app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
            [botonBorrar]="btnBorrar" (signal)="manejarSenales($event)" [botonAnterior]="botonAnterior"
            [botonPrimero]="botonPrimero" [botonUltimo]="botonUltimo" [botonSiguiente]="botonSiguiente"
            [barraBotones3]="true" [botonBuscar]="btnBuscar" [botonBinocular]="true"  [botonAlmacenardoc]="true">
        </app-invmaebarra>
    </div>
</div>

<p-tabView [style]="{'width':'100%'}" [activeIndex]="selectTab">
    <p-tabPanel header="Generales">
        <p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 270px)'}">
            <div id="divPrincipal">
                <div class="divgrups">
                    <div class="p-grid">
                        <div class="p-col-1">
                            <span>Código:</span>
                        </div>
                        <div class="p-col-2">
                            <input type="text" id="codigo" pInputText [(ngModel)]="txtActCod" disabled>
                        </div>
                        <div class="p-col-4"></div>
                        <div class="p-col-1">
                            <span>Grupo:</span>
                        </div>
                        <div class="p-col-2">
                            <div class="p-grid">
                                <div class="p-col-10">
                                    <input type="text" id="cod_actgrupo" pInputText [(ngModel)]="txtActGru" (input)="cambio($event)"
                                    (keydown.enter)="cambiarFoco($event)">
                                </div>
                                <div class="p-col-2">
                                    <button type="button"
                                    style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                    class="littlebuttons"
                                    (click)="busquedaGrupo(txtActGru)"></button>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2">
                            <input type="text" pInputText [(ngModel)]="lblGruNom" disabled>
                        </div>
                        <div class="p-col-12" style="margin-top: -12px">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Nombre:</span>
                                </div>
                                <div class="p-col-4">
                                    <input type="text" id="actnombre" pInputText [(ngModel)]="txtActNom" (input)="cambio($event)"
                                    (keydown.enter)="cambiarFoco($event)">
                                </div>
                                <div class="p-col-2"></div>
                                <div class="p-col-1">
                                    <span>Alias:</span>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" id="aliasnombre" pInputText [(ngModel)]="txtActNomCor" (input)="cambio($event)"
                                    (keydown.enter)="cambiarFoco($event)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="divgrups">
                    <div class="p-grid">
                        <div class="p-col-1">
                            <span>Orígen:</span>
                        </div>
                        <div class="p-col-1">
                            <p-dropdown [style]="{'minWidth': '100%', 'width':'100%'}"
                                        [(ngModel)]="strOrigen"
                                        optionLabel="name" [options]="cmbOrig"
                                        [panelStyle]="{'width':'100%'}" (onChange)="cambio($event)"
                                        inputId="origen"
                                        (keydown.enter)="cambiarFoco($event)">
                            </p-dropdown>
                        </div>
                        <div class="p-col-5"></div>
                        <div class="p-col-1">
                            <span>Lugar de Origen:</span>
                        </div>
                        <div class="p-col-4">
                            <input type="text" id="lugOrigen" pInputText [(ngModel)]="txtLugOrig" (input)="cambio($event)"
                            (keydown.enter)="cambiarFoco($event)">
                        </div>
                        <div class="p-col-12"  style="margin-top: -8px;">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Ciudad:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-10">
                                            <input type="text" id="ciudad" pInputText [(ngModel)]="txtCiuCod" (input)="cambio($event)"
                                            (keydown.enter)="cambiarFoco($event)">
                                        </div>
                                        <div class="p-col-2">
                                            <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                            class="littlebuttons"
                                            (click)="busquedaCiudad(txtCiuCod)"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-3">
                                    <input type="text" pInputText [(ngModel)]="lblCiuNomb" disabled>
                                </div>
                                <div class="p-col-1"></div>
                                <div class="p-col-1">
                                    <span>Oficina:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-10">
                                            <input type="text" id="oficina" pInputText [(ngModel)]="txtCodOfi" (input)="cambio($event)"
                                            (keydown.enter)="cambiarFoco($event)">
                                        </div>
                                        <div class="p-col-2">
                                            <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                            class="littlebuttons"
                                            (click)="busquedaOficina(txtCodOfi)"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" pInputText [(ngModel)]="lblNomOfi" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12" style="margin-top: -12px;">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Area:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-10">
                                            <input type="text" id="area" pInputText [(ngModel)]="txtCodArea" (input)="cambio($event)"
                                            (keydown.enter)="cambiarFoco($event)">
                                        </div>
                                        <div class="p-col-2">
                                            <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                            class="littlebuttons"
                                            (click)="busquedaArea(txtCodArea)"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-3">
                                    <input type="text" pInputText [(ngModel)]="lblNomArea" disabled>
                                </div>
                                <div class="p-col-1"></div>
                                <div class="p-col-1">
                                    <span>Departamento:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-10">
                                            <input type="text" id="departamento" pInputText [(ngModel)]="txtCodDep" (input)="cambio($event)"
                                            (keydown.enter)="cambiarFoco($event)">
                                        </div>
                                        <div class="p-col-2">
                                            <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                            class="littlebuttons"
                                            (click)="busquedaDepartamento(txtCodDep)"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" pInputText [(ngModel)]="lblNomDep" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12"  style="margin-top: -12px;">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Proceso:</span>
                                </div>
                                <div class="p-col-1">
                                    <p-dropdown [style]="{'minWidth': '100%', 'width':'100%'}"
                                        [(ngModel)]="strProceso"
                                        optionLabel="name" [options]="cmbProc"
                                        [panelStyle]="{'width':'100%'}" (onChange)="cambio($event)"
                                        inputId="proceso"
                                        (keydown.enter)="cambiarFoco($event)">
                                    </p-dropdown>
                                </div>
                                <div class="p-col-1"></div>
                                <div class="p-col-1">
                                    <span>Estado:</span>
                                </div>
                                <div class="p-col-1">
                                    <p-dropdown [style]="{'minWidth': '100%', 'width':'100%'}"
                                        [(ngModel)]="strEstado"
                                        optionLabel="name" [options]="cmbEsta"
                                        [panelStyle]="{'width':'100%'}" (onChange)="cambio($event)"
                                        inputId="estado"
                                        (keydown.enter)="cambiarFoco($event)">
                                    </p-dropdown>
                                </div>
                                <div class="p-col-2"></div>
                                 <div class="p-col-1">
                                    <span>Tipo Usuario:</span>
                                </div>
                                <div class="p-col-1">
                                    <p-dropdown [style]="{'minWidth': '100%', 'width':'100%'}"
                                        [(ngModel)]="strTipUsuario"
                                        optionLabel="name" [options]="cmbUso"
                                        [panelStyle]="{'width':'100%'}" (onChange)="cambio($event)"
                                        inputId="tipousuario"
                                        (keydown.enter)="cambiarFoco($event)" placeholder="">
                                    </p-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12"  style="margin-top: -12px;">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Usuario1:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-10">
                                            <input type="text" id="codcli1" pInputText [(ngModel)]="txtCodCli1" (input)="cambio($event)"
                                            (keydown.enter)="cambiarFoco($event)">
                                        </div>
                                        <div class="p-col-2">
                                            <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                            class="littlebuttons"
                                            (click)="busquedaUsuario1(txtCodCli1)"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-4">
                                    <input type="text" pInputText [(ngModel)]="lblNomCli1" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12" style="margin-top: -12px;">
                            <div class="p-grid" >
                                <div class="p-col-1">
                                    <span>Usuario2:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-10">
                                            <input type="text" id="codcli2" pInputText [(ngModel)]="txtCodCli2" (input)="cambio($event)"
                                            (keydown.enter)="cambiarFoco($event)">
                                        </div>
                                        <div class="p-col-2">
                                            <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                            class="littlebuttons"
                                            (click)="busquedaUsuario2(txtCodCli2)"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-4">
                                    <input type="text" pInputText [(ngModel)]="lblNomCli2" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12" style="margin-top: -12px;">
                            <div class="p-grid" >
                                <div class="p-col-1">
                                    <span>Propietario:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-10">
                                            <input type="text" id="codpropietario" pInputText [(ngModel)]="txtCodProp" (input)="cambio($event)"
                                            (keydown.enter)="cambiarFoco($event)">
                                        </div>
                                        <div class="p-col-2">
                                            <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                            class="littlebuttons"
                                            (click)="busquedaPropietario(txtCodProp)"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-4">
                                    <input type="text" pInputText [(ngModel)]="lblNomProp" disabled>
                                </div>
                                <div class="p-col-1">
                                    <span>Fecha Uso:</span>
                                </div>
                                <div class="p-col-4">
                                    <p-calendar [(ngModel)]="dateFecUso"
                                    [readonlyInput]="false"
                                    dateFormat="dd/mm/yy" [inputStyle]="{'width': '100px'}"
                                    (ngModelChange)="cambio($event)" inputId="fechaUsu"
                                    (keydown.enter)="cambiarFoco($event)"></p-calendar>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12" style="margin-top: -12px;">
                            <div class="p-grid" >
                                <div class="p-col-1">
                                    <span>Proveedor:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-10">
                                            <input type="text" id="codprov" pInputText [(ngModel)]="txtCodProv" (input)="cambio($event)"
                                            (keydown.enter)="cambiarFoco($event)">
                                        </div>
                                        <div class="p-col-2">
                                            <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                            class="littlebuttons"
                                            (click)="busquedaProveedor(txtCodProv)"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-4">
                                    <input type="text" pInputText [(ngModel)]="lblNomProv" disabled>
                                </div>
                                <div class="p-col-1">
                                    <span>Fecha Fabricación:</span>
                                </div>
                                <div class="p-col-4">
                                    <p-calendar [(ngModel)]="dateFecFabr"
                                    [readonlyInput]="false"
                                    dateFormat="dd/mm/yy" [inputStyle]="{'width': '100px'}"
                                    (ngModelChange)="cambio($event)" inputId="fechaFab"
                                    (keydown.enter)="cambiarFoco($event)"></p-calendar>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12" style="margin-top: -12px;">
                            <div class="p-grid" >
                                <div class="p-col-1">
                                    <span>Fecha Compra:</span>
                                </div>
                                <div class="p-col-1">
                                    <p-calendar [(ngModel)]="dateFecComp"
                                    [readonlyInput]="false"
                                    dateFormat="dd/mm/yy" [inputStyle]="{'width': '100px'}"
                                    (ngModelChange)="cambio($event)" inputId="fechaFab"
                                    (keydown.enter)="cambiarFoco($event)"></p-calendar>
                                </div>
                                <div class="p-col-1"></div>
                                <div class="p-col-2">
                                    <span>#Comprobante Compra:</span>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" id="numcomprc" pInputText [(ngModel)]="txtCompComp" (input)="cambio($event)"
                                    (keydown.enter)="cambiarFoco($event)">
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12" style="margin-top: -10px;">
                            <div class="p-grid" >
                                <div class="p-col-1">
                                    <span>Marca:</span>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" id="marca" pInputText [(ngModel)]="txtMarc" (input)="cambio($event)"
                                    (keydown.enter)="cambiarFoco($event)">
                                </div>
                                <div class="p-col-2">
                                    <span>Modelo:</span>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" id="modelo" pInputText [(ngModel)]="txtMode" (input)="cambio($event)"
                                    (keydown.enter)="cambiarFoco($event)">
                                </div>
                                <div class="p-col-1">
                                    <span>Serie/Num.Motor:</span>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" id="serienum" pInputText [(ngModel)]="txtNumSeri" (input)="cambio($event)"
                                    (keydown.enter)="cambiarFoco($event)">
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12" style="margin-top: -10px;">
                            <div class="p-grid" >
                                <div class="p-col-1">
                                    <span>Num. Chasis:</span>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" id="chasis" pInputText [(ngModel)]="txtChas" (input)="cambio($event)"
                                    (keydown.enter)="cambiarFoco($event)">
                                </div>
                                <div class="p-col-2">
                                    <span>Num. Placa:</span>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" id="mumplaca" pInputText [(ngModel)]="txtPlac" (input)="cambio($event)"
                                    (keydown.enter)="cambiarFoco($event)">
                                </div>
                                <div class="p-col-1">
                                    <span>Num. Poliza:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-10">
                                            <input type="text" id="numpoli" pInputText [(ngModel)]="txtNumPoli" (input)="cambio($event)"
                                            (keydown.enter)="cambiarFoco($event)">
                                        </div>
                                        <div class="p-col-2">
                                            <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                            class="littlebuttons"
                                            (click)="busquedaPoliza(txtNumPoli)"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" pInputText [(ngModel)]="lblNumPoli" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12" style="margin-top: -12px;">
                            <div class="p-grid" >
                                <div class="p-col-1">
                                    <span>Color:</span>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" id="color" pInputText [(ngModel)]="txtColo" (input)="cambio($event)"
                                    (keydown.enter)="cambiarFoco($event)">
                                </div>
                                
                                <div class="p-col-2">
                                    <span>#Comprobante Egreso:</span>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" id="numcompegr" pInputText [(ngModel)]="txtCompEgre" (input)="cambio($event)"
                                    (keydown.enter)="cambiarFoco($event)">
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12"  style="margin-top: -10px;">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Cuenta Activo:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-10">
                                            <input type="text" id="ctaactivo" pInputText [(ngModel)]="txtCtaActi" (input)="cambio($event)"
                                            (keydown.enter)="cambiarFoco($event)">
                                        </div>
                                        <div class="p-col-2">
                                            <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                            class="littlebuttons"
                                            (click)="busquedaCtaAct(txtCtaActi)"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-3">
                                    <input type="text" pInputText [(ngModel)]="lblNomCtaActi" disabled>
                                </div>
                                <div class="p-col-1"></div>
                                <div class="p-col-1">
                                    <span>CC:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-10">
                                            <input type="text" id="cc1" pInputText [(ngModel)]="txtCodCcAct" (input)="cambio($event)"
                                            (keydown.enter)="cambiarFoco($event)">
                                        </div>
                                        <div class="p-col-2">
                                            <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                            class="littlebuttons"
                                            (click)="busquedaCCActivo(txtCodCcAct)"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" pInputText [(ngModel)]="lblNomCcAct" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12"  style="margin-top: -12px;">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Cta.Depreciación Acumulada:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-10">
                                            <input type="text" id="ctadpacum" pInputText [(ngModel)]="txtCodCtaDepr" (input)="cambio($event)"
                                            (keydown.enter)="cambiarFoco($event)">
                                        </div>
                                        <div class="p-col-2">
                                            <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                            class="littlebuttons"
                                            (click)="busquedaCtaDepr(txtCodCtaDepr)"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-3">
                                    <input type="text" pInputText [(ngModel)]="lblNomCtaDepr" disabled>
                                </div>
                                <div class="p-col-1"></div>
                                <div class="p-col-1">
                                    <span>CC:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-10">
                                            <input type="text" id="ccdep" pInputText [(ngModel)]="txtCodCcDep" (input)="cambio($event)"
                                            (keydown.enter)="cambiarFoco($event)">
                                        </div>
                                        <div class="p-col-2">
                                            <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                            class="littlebuttons"
                                            (click)="busquedaCCDepr(txtCodCcDep)"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" pInputText [(ngModel)]="lblNomCcDep" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12"  style="margin-top: -12px;">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Cuenta Gasto:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-10">
                                            <input type="text" id="ctagasto" pInputText [(ngModel)]="txtCodGas" (input)="cambio($event)"
                                            (keydown.enter)="cambiarFoco($event)">
                                        </div>
                                        <div class="p-col-2">
                                            <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                            class="littlebuttons"
                                            (click)="busquedaCtaGas(txtCodGas)"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-3">
                                    <input type="text" pInputText [(ngModel)]="lblNomCtaGas" disabled>
                                </div>
                                <div class="p-col-1"></div>
                                <div class="p-col-1">
                                    <span>CC:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-10">
                                            <input type="text" id="ccgasto" pInputText [(ngModel)]="txtCodCcGas" (input)="cambio($event)"
                                            (keydown.enter)="cambiarFoco($event)">
                                        </div>
                                        <div class="p-col-2">
                                            <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                            class="littlebuttons"
                                            (click)="busquedaCCGasto(txtCodCcGas)"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-2">
                                    <input type="text" pInputText [(ngModel)]="lblNomCcGas" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12" style="margin-top: -12px;">
                            <div class="p-grid" >
                                <div class="p-col-1">
                                    <span>Comentario:</span>
                                </div>
                                <div class="p-col-10">
                                    <input type="text" id="comentario" pInputText [(ngModel)]="txtCome" (input)="cambio($event)"
                                    (keydown.enter)="cambiarFoco($event)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </p-scrollPanel>
    </p-tabPanel>
    <p-tabPanel header="Adicionales">
        <div id="divPrincipal2">
            <div class="divgrups">
                <div class="p-grid">
                    <div class="p-col-12">
                        <span style="font-weight: bold">Costos - Vida Util - Depreciación</span>
                    </div>
                    <div class="p-col-12">
                        <div class="p-grid" >
                            <div class="p-col-2">
                                <span>Costo del Activo:</span>
                            </div>
                            <div class="p-col-1">
                                <input style="text-align: right;" type="text" id="costoactivo" pInputText [(ngModel)]="txtCostAct" appTwoDigitDecimaNumber (input)="cambio($event)"
                                (keydown.enter)="cambiarFoco($event)">
                            </div>
                            <div class="p-col-1"></div>
                            <div class="p-col-2">
                                <span>Costo en Moneda Origen:</span>
                            </div>
                            <div class="p-col-1">
                                <input style="text-align: right;" type="text" id="costomonori" pInputText [(ngModel)]="txtCosMonOri" appTwoDigitDecimaNumber (input)="cambio($event)"
                                (keydown.enter)="cambiarFoco($event)">
                            </div>
                            <div class="p-col-1"></div>
                            <div class="p-col-1">
                                <span>Moneda Origen:</span>
                            </div>
                            <div class="p-col-2">
                                <div class="p-grid">
                                    <div class="p-col-10">
                                        <input type="text" id="monorigen" pInputText [(ngModel)]="txtCodMonOri" (input)="cambio($event)"
                                        (keydown.enter)="cambiarFoco($event)">
                                    </div>
                                    <div class="p-col-2">
                                        <button type="button"
                                        style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                        class="littlebuttons"
                                        (click)="busquedaMoneda(txtCodMonOri)"></button>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-1">
                                <input type="text" pInputText [(ngModel)]="lblNomMonOri" disabled>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12" style="margin-top: -12px;">
                        <div class="p-grid" >
                            <div class="p-col-2">
                                <span>Cotizacion de Cambio:</span>
                            </div>
                            <div class="p-col-1">
                                <input style="text-align: right;" type="text" id="cotizacambio" pInputText [(ngModel)]="txtCotCamb" appTwoDigitDecimaNumber (input)="cambio($event)"
                                (keydown.enter)="cambiarFoco($event)">
                            </div>
                            <div class="p-col-1"></div>
                            <div class="p-col-2">
                                <span>Vida Util (en Años y Decimales) :</span>
                            </div>
                            <div class="p-col-1">
                                <input style="text-align: right;" type="text" id="vidautil" pInputText [(ngModel)]="txtVidaUtil" appTwoDigitDecimaNumber (input)="cambio($event)"
                                (keydown.enter)="cambiarFoco($event)">
                            </div>
                            <div class="p-col-1"></div>
                            <div class="p-col-2">
                                <span>Depreciación Acumulada:</span>
                            </div>
                            <div class="p-col-1">
                                <input style="text-align: right;" type="text" id="depreacum" pInputText [(ngModel)]="txtDeprAcum" appTwoDigitDecimaNumber (input)="cambio($event)"
                                (keydown.enter)="cambiarFoco($event)">
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12" style="margin-top: -12px;">
                        <div class="p-grid" >
                            <div class="p-col-2">
                                <span>Ultima Depreciación:</span>
                            </div>
                            <div class="p-col-1">
                                <p-calendar [(ngModel)]="dateUltiDepr"
                                    [readonlyInput]="false"
                                    dateFormat="dd/mm/yy" [inputStyle]="{'width': '100px'}"
                                    (ngModelChange)="cambio($event)" inputId="dateultidepr"
                                    (keydown.enter)="cambiarFoco($event)"></p-calendar>
                            </div>
                            <div class="p-col-1"></div>
                            <div class="p-col-2">
                                <span>Valor Residual:</span>
                            </div>
                            <div class="p-col-1">
                                <input style="text-align: right;" type="text" id="valorresi" pInputText [(ngModel)]="txtValoResi" appTwoDigitDecimaNumber (input)="cambio($event)"
                                (keydown.enter)="cambiarFoco($event)">
                            </div>
                            <div class="p-col-1"></div>
                            <div class="p-col-2">
                                <span>Fecha de Registro:</span>
                            </div>
                            <div class="p-col-2">
                                <p-calendar [(ngModel)]="dateFechRegi"
                                    [readonlyInput]="false"
                                    dateFormat="dd/mm/yy" [inputStyle]="{'width': '100px'}"
                                    (ngModelChange)="cambio($event)" inputId="datefechregi"
                                    (keydown.enter)="cambiarFoco($event)"></p-calendar>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12">
                        <span style="font-weight: bold">Mantenimiento</span>
                    </div>
                    <div class="p-col-12">
                        <div class="p-grid" >
                            <div class="p-col-2">
                                <span>Proveedor:</span>
                            </div>
                            <div class="p-col-2">
                                <div class="p-grid">
                                    <div class="p-col-10">
                                        <input type="text" id="codprovmant" pInputText [(ngModel)]="txtCodProvMant" (input)="cambio($event)"
                                        (keydown.enter)="cambiarFoco($event)">
                                    </div>
                                    <div class="p-col-2">
                                        <button type="button"
                                        style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                        class="littlebuttons"
                                        (click)="busquedaProvMant(txtCodProvMant)"></button>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-3">
                                <input type="text" pInputText [(ngModel)]="lblNomProvMant" disabled>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12" style="margin-top: -12px;">
                        <div class="p-grid" >
                            <div class="p-col-2">
                                <span>Tipo Mantenimiento:</span>
                            </div>
                            <div class="p-col-1">
                                <input type="text" id="tipomant" pInputText [(ngModel)]="txtTipoMant" (input)="cambio($event)"
                                (keydown.enter)="cambiarFoco($event)">
                            </div>
                            <div class="p-col-1"></div>
                            <div class="p-col-1">
                                <span>Frecuencia:</span>
                            </div>
                            <div class="p-col-2">
                                <div class="p-grid">
                                    <div class="p-col-10">
                                        <input type="text" id="frecuencia" pInputText [(ngModel)]="txtCodFreMant" (input)="cambio($event)"
                                        (keydown.enter)="cambiarFoco($event)">
                                    </div>
                                    <div class="p-col-2">
                                        <button type="button"
                                        style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                        class="littlebuttons"
                                        (click)="busquedaFrecMant(txtCodFreMant)"></button>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-2">
                                <input type="text" pInputText [(ngModel)]="lblNombFreMant" disabled>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12" style="margin-top: -12px;">
                        <div class="p-grid" >
                            <div class="p-col-2">
                                <span>Observación:</span>
                            </div>
                            <div class="p-col-10">
                                <input type="text" id="obsermant" pInputText [(ngModel)]="txtObsMant" (input)="cambio($event)"
                                (keydown.enter)="cambiarFoco($event)">
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12">
                        <span style="font-weight: bold">Garantía</span>
                    </div>
                    <div class="p-col-12">
                        <div class="p-grid" >
                            <div class="p-col-2">
                                <span>Tiempo de Garantía:</span>
                            </div>
                            <div class="p-col-1">
                                <input style="text-align: right;" type="text" id="tiempogara" pInputText [(ngModel)]="txtTiemGara" appTwoDigitDecimaNumber (input)="cambio($event)"
                                (keydown.enter)="cambiarFoco($event)">
                            </div>
                            <div class="p-col-1">
                                <p-dropdown [style]="{'minWidth': '100%', 'width':'100%'}"
                                            [(ngModel)]="strTipoGara"
                                            optionLabel="name" [options]="cmbTipoGara"
                                            [panelStyle]="{'width':'100%'}" (onChange)="cambio($event)"
                                            inputId="tipogara"
                                            (keydown.enter)="cambiarFoco($event)">
                                </p-dropdown>
                            </div>
                            <div class="p-col-4">
                            </div>
                            <div class="p-col-1">
                                <span>Valor de Garantía:</span>
                            </div>
                            <div class="p-col-1">
                                <input style="text-align: right;" type="text" id="valorgara" pInputText [(ngModel)]="txtValoGara" appTwoDigitDecimaNumber (input)="cambio($event)"
                                (keydown.enter)="cambiarFoco($event)">
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12" style="margin-top: -12px;">
                        <div class="p-grid" >
                            <div class="p-col-2">
                                <span>Fecha Inicial:</span>
                            </div>
                            <div class="p-col-1">
                                <p-calendar [(ngModel)]="dateFecIniGara"
                                [readonlyInput]="false"
                                dateFormat="dd/mm/yy" [inputStyle]="{'width': '100px'}"
                                (ngModelChange)="cambio($event)" inputId="datefeinigara"
                                (keydown.enter)="cambiarFoco($event)"></p-calendar>
                            </div>
                            <div class="p-col-1"></div>
                            <div class="p-col-1">
                                <span>Fecha Final:</span>
                            </div>
                            <div class="p-col-1">
                                <p-calendar [(ngModel)]="dateFecFinGara"
                                [readonlyInput]="false"
                                dateFormat="dd/mm/yy" [inputStyle]="{'width': '100px'}"
                                (ngModelChange)="cambio($event)" inputId="datefefingara"
                                (keydown.enter)="cambiarFoco($event)"></p-calendar>
                            </div>
                            <div class="p-col-2"></div>
                            <div class="p-col-2">
                                <p-checkbox label="Sello de Seguridad" binary="true" [(ngModel)]="btnSellSegu">
                                </p-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12" style="margin-top: -12px;">
                        <div class="p-grid" >
                            <div class="p-col-2">
                                <span>Cobertura:</span>
                            </div>
                            <div class="p-col-10">
                                <input type="text" id="cobegara" pInputText [(ngModel)]="txtCobeGara" (input)="cambio($event)"
                                (keydown.enter)="cambiarFoco($event)">
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12" style="margin-top: -12px;">
                        <div class="p-grid" >
                            <div class="p-col-2">
                                <span>Excepciones:</span>
                            </div>
                            <div class="p-col-10">
                                <input type="text" id="excepgara" pInputText [(ngModel)]="txtExceGara" (input)="cambio($event)"
                                (keydown.enter)="cambiarFoco($event)">
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12" style="margin-top: -12px;">
                        <div class="p-grid" >
                            <div class="p-col-2">
                                <span>Adicionales:</span>
                            </div>
                            <div class="p-col-10">
                                <input type="text" id="adicionales" pInputText [(ngModel)]="txtAdicGara" (input)="cambio($event)"
                                (keydown.enter)="cambiarFoco($event)">
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12" style="margin-top: -12px;">
                        <div class="p-grid" >
                            <div class="p-col-2">
                                <span>Observación:</span>
                            </div>
                            <div class="p-col-10">
                                <input type="text" id="obsgara" pInputText [(ngModel)]="txtObsGara" (input)="cambio($event)"
                                (keydown.enter)="cambiarFoco($event)">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Novedades">
        <app-aggridsaci [width]=""
				[height]="largo"
				[enableSorting]="true"
				[enableFilter]="true"
				[rowData]="detalleActTrnTransfer"
				[frameworkComponents]="frameworkComponents"
				[animateRows]="true"
				[rowSelection]="rowSelection"
				[columnDefs]="columnDefsActTrnTransf"
				[defaultColDef]="defaultActTrnTransf"
				[rowStyle]="rowStyle"
				[singleClickEdit]="false"
				[botones]="false"
				[mostrarGuardar]="false"
				[botonesAdicionales]="false">
        </app-aggridsaci>
    </p-tabPanel>
    <p-tabPanel header="Kardex">
        <app-aggridsaci [width]=""
				[height]="largo"
				[enableSorting]="true"
				[enableFilter]="true"
				[rowData]="detalleKardex"
				[frameworkComponents]="frameworkComponents"
				[animateRows]="true"
				[rowSelection]="rowSelection"
				[columnDefs]="columnDefsActDetKardex"
				[defaultColDef]="defaultActDetKardex"
				[rowStyle]="rowStyle"
				[singleClickEdit]="false"
				[botones]="false"
				[mostrarGuardar]="false"
				[botonesAdicionales]="false">
        </app-aggridsaci>
    </p-tabPanel>
</p-tabView>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>
<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'ACTMAEACTIVO'"
		[txtNumComprobante]="txtActCod"
		(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>
