<p-toast [style]="{marginTop: '41px'}" position="top-center" key="depositoTarjeta"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="depositoTarjetaconf" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 98%;">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
						 [botonBorrar]="btnBorrar"
						 (signal)="manejarSenales($event)" [botonAnterior]="botonAnterior" [botonPrimero]="botonPrimero"
						 [botonUltimo]="botonUltimo"
						 [botonSiguiente]="botonSiguiente" [barraBotones3]="true" [barraBotonesAsiCon]="false"
						 [botonAsiento]="false" [botonverAsiento]="false" [botonBuscar]="btnBuscar">
		</app-invmaebarra>
	</div>
</div>
<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px; width: 99%;">
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-1">
				<input type="text" pInputText
					   style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
					   disabled [(ngModel)]="txtTrnTarjetaCodigo">
			</div>
			<div class="p-col-11"></div>
		</div>
	</div>
</div>
<p-tabView (onChange)="onTab1Change($event)" [activeIndex]="selectTab">
	<p-tabPanel header="Conciliación">
		<div class="divgrups" id="divPrincipal">
			<div class="p-grid">
				<!--LINE 1-->
				<div class="p-col-10" style="text-align: right">
				</div>
				<div class="p-col-1" style="text-align: right">
					<span>Asiento:</span>
				</div>
				<div class="p-col-1" style="text-align: left">
					<input id="asiento" type="text" pInputText autocomplete="off" [(ngModel)]="lblAsientoRet" disabled>
				</div>
				<!--LINE 2-->
				<div class="p-col-12" style="margin-top: -12px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Tarjeta:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<input id="codigoTarjeta" type="text" pInputText autocomplete="off"
								   [(ngModel)]="txtCodTarjeta" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
						</div>
						<div class="p-col-1" style="text-align: left">
							<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									class="littlebuttons" (click)="buscarTarjeta(txtCodTarjeta)"></button>
						</div>
						<div class="p-col-2" style="text-align: left">
							<input id="nombreTarjeta" type="text" pInputText autocomplete="off"
								   [(ngModel)]="lblNombreTarjeta" disabled>
						</div>
						<div class="p-col-1" style="text-align: right">
							<span>Depositado en:</span>
						</div>
						<div class="p-col-2" style="text-align: left">
							<input id="depositado" type="text" pInputText autocomplete="off"
								   [(ngModel)]="lblDepConciliacion" disabled>
						</div>
						<div class="p-col-3" style="text-align: left">
						</div>
					</div>
				</div>
				<!--LINE 3-->
				<div class="p-col-12" style="margin-top: -12px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Fecha:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<p-calendar id="fechaEmision" [inputStyle]="{width: '100%', height: '20px'}"
										dateFormat="dd/mm/yy" [(ngModel)]="dateTimFechaEmision">
							</p-calendar>
						</div>
						<div class="p-col-1" style="text-align: left">
						</div>
						<div class="p-col-1" style="text-align: right">
							<span>Comprobante:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<input id="comprobante" type="text" pInputText autocomplete="off"
								   [(ngModel)]="txtNumeroComprobante" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
						</div>
						<div class="p-col-1" style="text-align: right">
							<span>Origen:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<input id="origen" type="text" pInputText autocomplete="off" disabled [(ngModel)]="lblOrigen"
								   style="text-align: center">
						</div>
						<div class="p-col-1" style="text-align: left">
						</div>
						<div class="p-col-2" style="text-align: left">
							<p-checkbox label="Asiento múltiple" binary="true" [(ngModel)]="CHKAsientoMultiple">
							</p-checkbox>
						</div>
						<div class="p-col-1" style="text-align: right"></div>
					</div>
				</div>
				<!--LINE 4-->
				<div class="p-col-12" style="margin-top: -12px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Valor Cobrado:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<input id="valorCobrado" type="text" pInputText autocomplete="off" style="text-align: right"
								   [(ngModel)]="txtValorCobrado" disabled>
						</div>
						<div class="p-col-1" style="text-align: right"></div>
						<div class="p-col-1">
							<span>Base:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<input id="base" type="text" pInputText autocomplete="off" [(ngModel)]="txtBase"
								   style="text-align: right" (keydown.enter)="cambiarFoco($event)">
						</div>
						<div class="p-col-1" style="text-align: right">
							<span>Porcentaje:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<input id="porcentaje" type="text" pInputText autocomplete="off" style="text-align: right"
								   [(ngModel)]="txtPorcentaje" (keydown.enter)="cambiarFoco($event)">
						</div>
						<div class="p-col-1" style="text-align: right">
							<span>Comisión:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<input id="comision" type="text" pInputText autocomplete="off" [(ngModel)]="txtComision"
								   style="text-align: right" (keydown.enter)="cambiarFoco($event)">
						</div>
						<div class="p-col-3" style="text-align: left">
						</div>
					</div>
				</div>
				<!--LINE 3-->
				<div class="p-col-12" style="margin-top: -12px; margin-bottom: -15px;">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Observaciones:</span>
						</div>
						<div class="p-col-8">
							<input id="observaciones" type="text" pInputText [(ngModel)]="txtObservaciones" 
							(keydown.enter)="cambiarFoco($event)" autocomplete="off">
						</div>

						<div class="p-col-1">
						</div>
					</div>
				</div>
				<!--LINE 3-->

			</div>
		</div>
		<p-tabView  [(activeIndex)]="index">
			<p-tabPanel header="Voucher">
				<div class="divgrups">
					<div class="p-grid">
						<!--LINE 1-->
						<div class="p-col-10">
						</div>
						<div class="p-col-2" style="margin-bottom: -5px;">
							<p-checkbox label="Seleccionar Todo" binary="true" [(ngModel)]="chkseleccionarTodo" (onChange)="selectAll($event)">
							</p-checkbox>
						</div>
					</div>
				</div>
				<app-aggridsaci [width]=""
								[height]="largo"
								[enableSorting]="true"
								[enableFilter]="true"
								[rowData]="detDepositoTarjeta"
								[frameworkComponents]="frameworkComponents"
								[animateRows]="true"
								[rowSelection]="rowSelection"
								[columnDefs]="columnDefsBanTarjeta"
								[defaultColDef]="defaultColBanTarjeta"
								[rowStyle]="rowStyle"
								[singleClickEdit]="false"
								[botones]="false">
				</app-aggridsaci>
			</p-tabPanel>
			<p-tabPanel header="Retenciones">
				<app-aggridsaci [width]=""
								[height]="largo"
								[enableSorting]="true"
								[enableFilter]="true"
								[rowData]="trnTarjetaService.detModeloRetencion[indicador]"
								[frameworkComponents]="frameworkComponents"
								[animateRows]="true"
								[rowSelection]="rowSelection"
								[columnDefs]="columnDefsBanTarRetencion"
								[defaultColDef]="defaultColBanTarRetencion"
								[rowStyle]="rowStyle"
								[singleClickEdit]="false"
								[botones]="true"
								[botonNuevo]="btnNuevoGridRet"
								[botonBorrar]="btnBorrarGridRet"
								[botonRegresar]="btnCancelarGridRet"
								[mostrarGuardar]="false"
								(selected)="seleccionDetRetencion($event)"
								(cambios)="cambio($event)"
				>
				</app-aggridsaci>
				<div class="divgrups">
					<div class="p-grid">
						<!--LINE 1-->
						<div class="p-col-10">
						</div>
						<div class="p-col-1">
							<span style="font-weight: bold">Total Retenciones:</span>
						</div>
						<div class="p-col-1" style="text-align: left" style="margin-bottom: -15px;">
							<input id="totalRetenciones" type="text" pInputText autocomplete="off" [(ngModel)]="trnTarjetaService.lblTotalRetenciones" disabled>
						</div>
					</div>
				</div>
			</p-tabPanel>
			<p-tabPanel header="Varios">
				<app-aggridsaci [width]=""
								[height]="largo"
								[enableSorting]="true"
								[enableFilter]="true"
								[rowData]="trnTarjetaService.detModeloVarios[indicador]"
								[frameworkComponents]="frameworkComponents"
								[animateRows]="true"
								[rowSelection]="rowSelection"
								[columnDefs]="columnDefsBanTarVarios"
								[defaultColDef]="defaultColBanTarVarios"
								[rowStyle]="rowStyle"
								[singleClickEdit]="false"
								[botones]="true"
								[botonNuevo]="btnNuevoGridVar"
								[botonBorrar]="btnBorrarGridVar"
								[botonRegresar]="btnCancelarGridVar"
								[mostrarGuardar]="false"
								(selected)="seleccionDetVarios($event)"
								(cambios)="cambio($event)"
				>
				</app-aggridsaci>
				<div class="divgrups">
					<div class="p-grid">
						<!--LINE 1-->
						<div class="p-col-10">
						</div>
						<div class="p-col-1">
							<span style="font-weight: bold">Total Varios:</span>
						</div>
						<div class="p-col-1" style="text-align: left" style="margin-bottom: -15px;">
							<input id="totalVarios" type="text" pInputText autocomplete="off" [(ngModel)]="trnTarjetaService.lblTotalVarios" disabled>
						</div>
					</div>
				</div>
			</p-tabPanel>
		</p-tabView>

		<div class="divgrupstabla">
			<div class="p-grid">
				<div class="p-col-2">
				</div>
				<div class="p-col-1">
					<span class="obligatorio">Débitos:</span>
				</div>
				<div class="p-col-1">
					<input id="debitos" type="text" pInputText autocomplete="off" disabled class="totales"
						   [(ngModel)]="lblTotalDebitos">
				</div>
				<div class="p-col-1">
					<span class="obligatorio">Créditos:</span>
				</div>
				<div class="p-col-1">
					<input id="creditos" type="text" pInputText autocomplete="off" disabled [(ngModel)]="lblCreditos"
						   class="totales">
				</div>
				<div class="p-col-1">
					<span class="obligatorio">Total Importe:</span>
				</div>
				<div class="p-col-1">
					<input id="totalImporte" type="text" pInputText autocomplete="off" disabled class="totales"
						   [(ngModel)]="lblTotalImporte">
				</div>
				<div class="p-col-2" style="text-align: left">
				</div>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Bancos">
		<div class="divgrups" id="divPrincipal2">
			<div class="p-grid">
				<!--LINE 1-->
				<div class="p-col-10" style="text-align: right">
				</div>
				<div class="p-col-1" style="text-align: right">
					<span>Asiento Déposito:</span>
				</div>
				<div class="p-col-1" style="text-align: left">
					<input id="asientoDeposito" type="text" pInputText autocomplete="off" disabled
						   [(ngModel)]="lblAsiento">
				</div>
				<!--LINE 2-->
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Banco:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<input id="codigoBanco" type="text" pInputText autocomplete="off" [(ngModel)]="txtCodBanco" (keydown.enter)="cambiarFoco($event)">
						</div>
						<div class="p-col-1" style="text-align: left">
							<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									class="littlebuttons" (click)="buscarBanco(txtCodBanco)"></button>
						</div>
						<div class="p-col-2" style="text-align: left">
							<input id="nombreBanco" type="text" pInputText disabled autocomplete="off"
								   [(ngModel)]="lblNombreBanco">
						</div>
						<div class="p-col-6" style="text-align: left">
						</div>
					</div>
				</div>
				<!--LINE 3-->
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Fecha:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<p-calendar id="fechaBanco" [inputStyle]="{width: '100%', height: '20px'}"
										dateFormat="dd/mm/yy" [(ngModel)]="dateTimeFechaDeposito"
										(keydown.enter)="cambiarFoco($event)">
							</p-calendar>
						</div>
						<div class="p-col-1" style="text-align: left">
						</div>
						<div class="p-col-1" style="text-align: right">
							<span>Comprobante:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<input id="comprobanteBanco" type="text" pInputText  autocomplete="off"
								   [disabled]="editTxtComprobante" [(ngModel)]="txtComprobante" (keydown.enter)="cambiarFoco($event)">
						</div>
						<div class="p-col-1" style="text-align: right">
							<span>Origen:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<input id="origenBanco" type="text" pInputText autocomplete="off" disabled style="text-align: center" [(ngModel)]="lblOrigen2">
						</div>
						<div class="p-col-5" style="text-align: right"></div>
					</div>
				</div>
				<!--LINE 4-->
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Importe:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<input id="importeBanco" type="text" pInputText autocomplete="off" 
							[(ngModel)]="txtImporte" style="text-align: right" (keydown.enter)="cambiarFoco($event)">
						</div>
						<div class="p-col-1" style="text-align: left">
						</div>
						<div class="p-col-1" style="text-align: right">
							<span>Concepto:</span>
						</div>
						<div class="p-col-4" style="text-align: left">
							<input id="conceptoBanco" type="text" pInputText autocomplete="off"
								   [(ngModel)]="txtConceptoDeposito" (keydown.enter)="cambiarFoco($event)">
						</div>
						<div class="p-col-4" style="text-align: left">
						</div>
					</div>
				</div>
				<!--LINE 3-->
				<div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px;">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Dep.Pend:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<input id="pendienteBanco" type="text" pInputText [(ngModel)]="txtDepPend" 
							 style="text-align: right" (keydown.enter)="cambiarFoco($event)">
						</div>
						<div class="p-col-9">
						</div>
					</div>
				</div>
				<!--LINE 3-->
			</div>
		</div>
		<p-tabView>
			<p-tabPanel header="Conciliaciones">
				<app-aggridsaci [width]=""
								[height]="largo"
								[enableSorting]="true"
								[enableFilter]="true"
								[rowData]="detModeloConciliacion"
								[frameworkComponents]="frameworkComponents"
								[animateRows]="true"
								[rowSelection]="rowSelection"
								[columnDefs]="columnDefsBanTarConcil"
								[defaultColDef]="defaultColBanTarConcil"
								[rowStyle]="rowStyle"
								[singleClickEdit]="false"
								[botones]="false">
				</app-aggridsaci>
			</p-tabPanel>
		</p-tabView>
	</p-tabPanel>
</p-tabView>
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBus"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [closable]="false"
		  [style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>
<p-dialog header="Clave Autorización" [(visible)]="claveDialog"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '40vw', height: 'auto'} ">
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							   (keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>
<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width:'80vw', height:'120vh'}"
		  [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="NumAsiRegCont" [abiertoDesde]="'bantrncaja'"
					(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>