import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class NumeracionService {
  public way: string;
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }
  
  obtenerNumeracionC(docRef): Observable<any[]>  {
    docRef = docRef === undefined ? '' : docRef;
     return this.http.post<any[]>(this.way + '/advantage/numeracion/617dbfaceda98c', {
      elementos: {
        NUM_DOCREF: docRef
      }
    }, this.confIniciales.httpOptions());
  }
  
  obtenerNumeracionTipo(tipo, docRef): Observable<any[]>  {
    return this.http.post<any[]>(this.way + '/advantage/numeracion/c2fb7f59dbecb00', {
      elementos: {
        NUM_DOCREF: docRef,
        NUM_CLASE: tipo
      }
    }, this.confIniciales.httpOptions());
  }
}
