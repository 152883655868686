<p-toast [style]="{marginTop: '41px'}" position="top-center" key="invtrnajustes"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="invtrnajustesconf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra (signal)="manejarSenales($event)" [botonNuevo]="invTrnAjusteService.btnNuevo[indicador]"
						 [botonGuardar]="invTrnAjusteService.btnGuardar[indicador]"
						 [botonBorrar]="invTrnAjusteService.btnEliminar[indicador]" [botonRegresar]="false"
						 [barraBotonesDesplaz]="true" [barraBotones3]="true"
						 [barraBotonesAsiCon]="false" [barraBotones5]="false"
						 [botonAsiento]="false" [botonverAsiento]="false"
						 [botonBuscar]="invTrnAjusteService.btnBuscar[indicador]"
						 [barraBotones3]="true" [botonBinocular]="true"
		></app-invmaebarra>
	</div>
</div>
<p-tabView [(activeIndex)]="selectTab">
	<p-tabPanel header="Ajuste costos inventario">
		<div class="divgrups" id="divPrincipal">
			<div class="p-grid">
				<!--LINE 1-->
				<div class="p-col-10">
				</div>
				<div class="p-col-2">
					<input id="asiNro" type="text" pInputText autocomplete="off" disabled
						   [(ngModel)]="invTrnAjusteService.encTrnAjustes[indicador].ASI_NRO">
				</div>
				<!--LINE 2-->
				<div class="p-col-12" style="margin-top: -5px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Comprobante:</span>
						</div>
						<div class="p-col-2">
							<input id="comprobante" type="text" pInputText autocomplete="off" disabled
								   [(ngModel)]="invTrnAjusteService.encTrnAjustes[indicador].COMPROBANTE">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -10px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Fecha:</span>
						</div>
						<div class="p-col-2" style="text-align: left">
							<p-calendar id="fecha" [inputStyle]="{width: '70%', height: '20px'}"
										dateFormat="dd/mm/yy"
										[(ngModel)]="invTrnAjusteService.encTrnAjustes[indicador].FECHA"
										(ngModelChange)="cambioEnc($event)">
							</p-calendar>
						</div>
					</div>
				</div>
				<!--LINE 3-->
				<div class="p-col-12" style="margin-top: -10px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Hora:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<p-calendar id="horaEmision"
										[inputStyle]="{'width': '100%'}" [timeOnly]="true"
										[(ngModel)]="invTrnAjusteService.encTrnAjustes[indicador].HORA"
										(ngModelChange)="cambioEnc($event)"></p-calendar>
						</div>
					</div>
				</div>
			</div>
		</div>
		<app-aggridsaci [width]=""
						[height]="largo"
						[enableSorting]="true"
						[enableFilter]="true"
						[rowData]="invTrnAjusteService.detTrnAjustes[indicador]"
						[frameworkComponents]="frameworkComponents"
						[animateRows]="true"
						[rowSelection]="rowSelection"
						[columnDefs]="columnDefstrnAjustes"
						[defaultColDef]="defaultColTrnAjustes"
						[rowStyle]="rowStyle"
						[singleClickEdit]="false"
						[botones]="true"
						[mostrarGuardar]="false"
						[botonNuevo]="invTrnAjusteService.btnNuevoDet[indicador]"
						[botonBorrar]="invTrnAjusteService.btnEliminarDet[indicador]"
						[botonRegresar]="invTrnAjusteService.btnCancelarDet[indicador]"
						(selected)="seleccionDetalle($event)"
						(cambios)="cambios($event)"

		></app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Ajuste costos inventario negativo automático" [disabled]="disabledTab1">
		<app-aggridsaci [width]=""
						[height]="largo"
						[enableSorting]="true"
						[enableFilter]="true"
						[rowData]="invTrnAjusteService.detTrnAjustesAut[indicador]"
						[frameworkComponents]="frameworkComponents"
						[animateRows]="true"
						[rowSelection]="rowSelection"
						[columnDefs]="columnDefstrnAjustesAut"
						[defaultColDef]="defaultColTrnAjustesAut"
						[rowStyle]="rowStyle"
						[singleClickEdit]="false"
						[botones]="false"
						[mostrarGuardar]="false"
		></app-aggridsaci>
	</p-tabPanel>
</p-tabView>
<!--<div class="divgrups" style="border: 1px solid #8c8c8c;" id="detalle">-->
<!--	<div class="p-grid" style="text-align: left;">-->
<!--		<div class="p-col-12">-->
<!--			<app-aggridsaci [width]=""-->
<!--							[height]="largo"-->
<!--							[enableSorting]="true"-->
<!--							[enableFilter]="true"-->
<!--							[rowData]="invTrnAjusteService.detTrnAjustes[indicador]"-->
<!--							[frameworkComponents]="frameworkComponents"-->
<!--							[animateRows]="true"-->
<!--							[rowSelection]="rowSelection"-->
<!--							[columnDefs]="columnDefstrnAjustes"-->
<!--							[defaultColDef]="defaultColTrnAjustes"-->
<!--							[rowStyle]="rowStyle"-->
<!--							[singleClickEdit]="false"-->
<!--							[botones]="false"-->
<!--							[mostrarGuardar]="false"-->
<!--							(selected)="seleccionDetalle($event)"-->
<!--							(cambios)="cambios($event)"-->
<!--			></app-aggridsaci>-->
<!--		</div>-->
<!--		<div class="p-col-12">-->
<!--			<div class="divgrupstabla">-->
<!--				&lt;!&ndash;				<div class="p-col-12">&ndash;&gt;-->
<!--				<div class="p-grid">-->
<!--					<div class="p-col-1">-->
<!--						<span class="obligatorio">Existencia: </span>-->
<!--					</div>-->
<!--					<div class="p-col-1">-->
<!--						<input class="totales" type="text" pInputText [disabled]="true"-->
<!--							   [(ngModel)]="lblExistencia">-->
<!--					</div>-->
<!--					<div class="p-col-1"></div>-->
<!--					<div class="p-col-1">-->
<!--						<span class="obligatorio">Costo Promedio: </span>-->
<!--					</div>-->
<!--					<div class="p-col-1">-->
<!--						<input class="totales" type="text" pInputText [disabled]="true"-->
<!--							   [(ngModel)]="lblCostoPromedio">-->
<!--					</div>-->
<!--					<div class="p-col-2">-->
<!--						<span class="obligatorio">Ult.Costo Compra: </span>-->
<!--					</div>-->
<!--					<div class="p-col-1">-->
<!--						<input class="totales" type="text" pInputText [disabled]="true"-->
<!--							   [(ngModel)]="lblUltCostoCompra">-->
<!--					</div>-->
<!--					<div class="p-col-2">-->
<!--						<span class="obligatorio">Ult.Costo Producción: </span>-->
<!--					</div>-->
<!--					<div class="p-col-1">-->
<!--						<input class="totales" type="text" pInputText [disabled]="true"-->
<!--							   [(ngModel)]="lblUltCostoProduccion">-->
<!--					</div>-->
<!--					<div class="p-col-1"></div>-->
<!--				</div>-->
<!--				&lt;!&ndash;				</div>&ndash;&gt;-->
<!--			</div>-->
<!--		</div>-->
<!--	</div>-->
<!--</div>-->
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width:'80vw', height:'120vh'}"
		  [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="NumAsiRegCont" [abiertoDesde]="'ajusCosto'"
					(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>
