import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { TecDetmant } from '../tecinterfaces/tecdetmant';

@Injectable({
  providedIn: 'root'
})
export class TecencmantService {

  public way: string;
  public tecdetmant: TecDetmant[][] = [];

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }

  getequiposcliente(clicodigo) {
    return this.http.post(this.way + '/serviciotecnico/equiposcliente/10775daf4b38e300000000', {
      elementos: {
        clicodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getencmant(item) {
    return this.http.post(this.way + '/serviciotecnico/equiposcliente/20eebaecb2afd', {
      elementos: {
        clicodigo: item.CLI_CODIGO,
        equicodigo: item.EQUI_CODIGO,
        mantserie: item.MANT_SERIE,
        comcodigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getdetmant(item) {
    return this.http.post(this.way + '/serviciotecnico/equiposcliente/20eeb5ddb2afd', {
      elementos: {
        clicodigo: item.CLI_CODIGO,
        equicodigo: item.EQUI_CODIGO,
        mantserie: item.MANT_SERIE,
        comcodigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getequiposetiquetas(equicodigo) {
    return this.http.post(this.way + '/serviciotecnico/equipos/10775daf4b38e3000', {
      elementos: {
        equicodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarequipocliente(clicodigo, equicodigo, mantserie, comcodigo) {
    return this.http.post(this.way + '/serviciotecnico/equiposcliente/1d7662fddab6ed000000', {
      elementos: {
        clicodigo, equicodigo, mantserie, comcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarencmant(item) {
    return this.http.post(this.way + '/serviciotecnico/equiposcliente/4afc76faadbaec00000', {
      elementos: {
        p_cli_codigo: item.CLI_CODIGO,
        p_equi_codigo: item.EQUI_CODIGO,
        p_mant_serie: item.MANT_SERIE,
        p_com_codigo: '01',
        p_mant_marca: item.MANT_MARCA,
        p_mant_modelo: item.MANT_MODELO,
        p_mant_origen: item.MANT_ORIGEN,
        p_mant_dias_mante: item.MANT_DIAS_MANTE,
        p_mant_ult_mante: item.MANT_ULT_MANTE,
        p_mant_prx_mante: item.MANT_PRX_MANTE,
        p_art_codigo: item.ART_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertardetmant(item) {
    return this.http.post(this.way + '/serviciotecnico/equiposcliente/4afc76faadb5dc00000', {
      elementos: {
        p_cli_codigo: item.CLI_CODIGO,
        p_equi_codigo: item.EQUI_CODIGO,
        p_mant_serie: item.MANT_SERIE,
        p_com_codigo: '01',
        p_eti_codigo: item.ETI_CODIGO,
        p_detmat_descripcion: item.DETMAT_DESCRIPCION,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarencmant(item) {
    return this.http.post(this.way + '/serviciotecnico/equiposcliente/533be5564adbb0000000', {
      elementos: {
        p_cli_codigo: item.CLI_CODIGO,
        p_equi_codigo: item.EQUI_CODIGO,
        p_mant_serie: item.MANT_SERIE,
        p_com_codigo: '01',
        p_mant_marca: item.MANT_MARCA,
        p_mant_modelo: item.MANT_MODELO,
        p_mant_origen: item.MANT_ORIGEN,
        p_mant_dias_mante: item.MANT_DIAS_MANTE,
        p_mant_ult_mante: item.MANT_ULT_MANTE,
        p_mant_prx_mante: item.MANT_PRX_MANTE,
        p_art_codigo: item.ART_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarencmant(item) {
    return this.http.post(this.way + '/serviciotecnico/equiposcliente/3ab2b4aeadbaec00000', {
      elementos: {
        p_cli_codigo: item.CLI_CODIGO,
        p_equi_codigo: item.EQUI_CODIGO,
        p_mant_serie: item.MANT_SERIE,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminardetmantall(item) {
    return this.http.post(this.way + '/serviciotecnico/equiposcliente/1d595a5756daef000000000', {
      elementos: {
        p_cli_codigo: item.CLI_CODIGO,
        p_equi_codigo: item.EQUI_CODIGO,
        p_mant_serie: item.MANT_SERIE,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminardetmant(item) {
    return this.http.post(this.way + '/serviciotecnico/equiposcliente/3ab2b4aeadb5de00000', {
      elementos: {
        p_cli_codigo: item.CLI_CODIGO,
        p_equi_codigo: item.EQUI_CODIGO,
        p_mant_serie: item.MANT_SERIE,
        p_com_codigo: '01',
        p_eti_codigo: item.ETI_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erpBusquedaCliente(strValor, strValue, strParams) {
    return this.http.post(this.way + '/ventas/facturas/tq8cgrtwkhkurwk', {

      elementos: {
        Valor: strValor,
        Value: strValue,
        Params: strParams
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrararticulo(articulo) {
    return this.http.post(this.way + '/produccion/ordenprod/ebb317eed5b5700000000', {
      elementos: {
        articulo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarequipo(codigo) {
    return this.http.post(this.way + '/serviciotecnico/equipos/3aecc5fbb56dda00000', {
      elementos: {
        codigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontraretiqueta(codigo) {
    return this.http.post(this.way + '/serviciotecnico/etiquetas/ebb317eed5b7780000000', {
      elementos: {
        codigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarmarcaequipo(equicodigo) {
    return this.http.post(this.way + '/serviciotecnico/equiposcliente/20eed95b629db00000', {
      elementos: {
        equicodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarmodeloequipo(equicodigo) {
    return this.http.post(this.way + '/serviciotecnico/equiposcliente/41ddb61aeae1dc00000', {
      elementos: {
        equicodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarorigenequipo(equicodigo) {
    return this.http.post(this.way + '/serviciotecnico/equiposcliente/41ddc6e5075ddc00000', {
      elementos: {
        equicodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }


  encontrarequipoordtra(item) {
    return this.http.post(this.way + '/serviciotecnico/equiposcliente/ebb317eed5b7680000000', {
      elementos: {
        clicodigo: item.CLI_CODIGO,
        equicodigo: item.EQUI_CODIGO,
        mantserie: item.MANT_SERIE,
        comcodigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  
}