import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {MessageService} from 'primeng';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {DatePipe} from '@angular/common';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Injectable({
  providedIn: 'root'
})
export class ExistenciabodegaService {
  
  private way: string;
  constructor(
    private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private confIniciales: ConfInicialesService,
    private datePipe: DatePipe,
    private init: NuevoComponentService
  ) {
   
    this.way = sessionStorage.getItem('way');
  
  }
  
  existenciaPorBodega(artCodigo, artPreCodigo) {
    const fecha = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    const hora = this.datePipe.transform(new Date(), 'hh:mm');
    
    return this.http.post<any[]>(this.way + '/ventas/existenciabodega/3b8c97338daf0c', {
      elementos: {
        p_art_codigo_1: artCodigo,
        p_artpre_codigo_2: artPreCodigo,
        p_fecha: fecha,
        p_hora: hora,
        p_com_codigo_2: '01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
    
  }
  
  artUnidadCosteo(strArtCodigo) {
    return this.http.post<any[]>(this.way + '/ventas/existenciabodega/107755befaf266000', {
        elementos: {
          art_codigo: strArtCodigo
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  usuarioClave(strIdentificacion) {
    return this.http.post<any[]>(this.way + '/ventas/existenciabodega/41ddf73ccaabf0', {
        elementos: {
          identificacion: strIdentificacion
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  obtenerImgArticulo(strArtCodigo) {
    return this.http.post<any[]>(this.way + '/ventas/existenciabodega/20eecad056fb260000', {
        elementos: {
          art_codigo: strArtCodigo
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
}
