<div class="p-grid" style="width: 99%;">
	<div class="p-col-12">
        <app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
                         [botonBorrar]="btnBorrar"
                         (signal)="manejarSenales($event)" [barraBotonesDesplaz] = "false"
                         [botonBuscar]="btnBuscar"  [botonSiguiente]="botonSiguiente"  [botonAnterior]="botonAnterior"
                         [botonPrimero]="botonPrimero" [botonUltimo]="botonUltimo"
                         [barraBotones3]="true" [botonBinocular]="true"
                         [barraBotones5]="false" [btnLiqBenSoc]="true"
                         [btnConLiq]="btnConLiq" [btnReveEstLiq]="btnReveEstLiq"
                         [botonBorrAsieCont]="btnBorrAsieCo" [botonGenArText]="btnGenArText"
                         [botonVisAsieCont]="btnVisAsiC"  [botonGenAsieCont]="btnGenAsiC"
                         [botonAlmacenardoc] = "true" >
        </app-invmaebarra>
	</div>
</div>
<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="novedad"></p-toast>
	
<p-confirmDialog [transitionOptions]="'0ms'" key="NomDetLiq" title="Confirmar Acción"
                 appendTo="body"></p-confirmDialog>
<p-confirmDialog [transitionOptions]="'0ms'" key="NomVarLiq" title="Confirmar Acción"
                 appendTo="body"></p-confirmDialog>
<p-confirmDialog [transitionOptions]="'0ms'" key="nomCaba" title="Confirmar Acción"
                 appendTo="body"></p-confirmDialog>
<ng-container *ngIf="displayDialogClave==true">
    <p-dialog header="Clave Autorización" [(visible)]="displayDialogClave"
                 [transitionOptions]="'0ms'"
                 [modal]="true"
                 appendTo="body"
                 [style]="{width: '70vw', height: '35vh'} ">
        <app-segclaveautoriza  [codigo]="cod" (salirclave)="salirc($event)" (autorizado)="autoriza($event)"></app-segclaveautoriza>
    </p-dialog>
</ng-container>
<ng-container *ngIf="displayDialog2Clave==true">
    <p-dialog header="Clave Autorización" [(visible)]="displayDialog2Clave"
                 [transitionOptions]="'0ms'"
                 [modal]="true"
                 appendTo="body"
                 [style]="{width: '70vw', height: '35vh'} ">
        <app-segclaveautoriza  [codigo]="cod" (salirclave)="salirc($event)" (autorizado)="autorizados($event)"></app-segclaveautoriza>
    </p-dialog>
</ng-container>   
<ng-container *ngIf="displaydialogTxt">
        <p-dialog header="Periodo" [(visible)]="displaydialogTxt"
                              [transitionOptions]="'0ms'"
                              [modal]="true"
                              appendTo="body"
                              (onHide)="onHideIngre()"
                              [style]="{width: '70vw', height: 'auto'} ">
            <p-toast [style]="{marginTop: '41vh'}" position="top-center" key="rubDialog"></p-toast>
             <app-nomexpliqubenesocarchtxt [codigo]="selectcabe.ENCLIQUBENESOCI_CODIGO"></app-nomexpliqubenesocarchtxt>                       
        </p-dialog>
</ng-container>
<div style="overflow: hidden" id="divPrincipal" class="p-col-12">
   

        <div class="divgrups">
            <div class="p-grid">
                <div class="p-col-1">
                    <span style="text-align: center; color: rgb(0,0,255); font-weight: bold;font-size: 15px;">{{selectcabe.ENCLIQUBENESOCI_CODIGO}}</span>
                </div>
                <div class="p-col-2">                                
                </div>
                <div class="p-col-2">
                    <span style="text-align:left; color: rgb(0,0,0); font-weight: bold;font-size: 15px;">{{selectcabe.ENCLIQUBENESOCI_ESTADO}}</span>          
                </div>                
                <div class="p-col-2">
                    <span style="text-align:left; color: rgb(0,0,255); font-weight: bold;font-size: 15px;">{{selectcabe.ASI_NRO}}</span>          
                </div>
                <div class="p-col-1">
                </div>
                <div class="p-col-4">
                    <table style="width: 100%;text-align: left;">
                        <tr style="background-color: rgba(66, 214, 240, 0.829);">
                            <td style="border: 1px solid; text-align: center;">{{identiUsuario}}</td>
                            <td style="border: 1px solid; text-align: center;">{{fecha | date: 'dd/MM/yyyy' }}</td>
                            <td style="border: 1px solid; text-align: center;">{{fecha | date: 'hh:mm:ss' }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>             
        <div class="divgrups">
            <div class="p-grid">
                <div class="p-col-12">
                    <div class="p-grid">
                                        
                        <div class="p-col-2">
                            <span>Rubro:</span>
                        </div>
                        <div class="p-col-2">
                            <input class="frm" id="rubrocod" type="text" pInputText 
                            [(ngModel)]="selectcabe.MAERUBRO_CODRUBR"
                            
                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"          
                            autocomplete="off" >
                        </div>
                        <div class="p-col-1">
                            <button type="button"
                                        style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                        (click)="setFocus($event)" (click)="busRubro(selectcabe.MAERUBRO_CODRUBR)"></button>
                        </div>
                    
                        <div class="p-col-3" style="text-align: left">
                            <input id="span_descRubro" type="text" [(ngModel)]="selectcabe.descRubro" pInputText disabled autocomplete="off" >
                        </div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-2">	
                            <span>Fecha Provisión:</span>
                        </div>
                        <div class="p-col-2">
                            <p-calendar id="pFecProvIni" [inputStyle]="{width: '100%', height: '20px'}"
                                                    [(ngModel)]="selectcabe.ENCLIQUBENESOCI_FECINIPROV" 
                                                    dateFormat="dd/mm/yy"
                                                    (ngModelChange)="cambio($event)" disabled="true">
                                        </p-calendar>
                        </div>
                        <div class="p-col-2">
                            <p-calendar id="pFecProvFin" [inputStyle]="{width: '100%', height: '20px'}"
                                                    [(ngModel)]="selectcabe.ENCLIQUBENESOCI_FECFINPROV" 
                                                    dateFormat="dd/mm/yy"
                                                    (ngModelChange)="cambio($event)" disabled="true">
                            </p-calendar>
                        </div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-2">	
                            <span>Periodo:</span>
                        </div>
                        <div class="p-col-2">
                            <p-calendar id="pPerIni" [inputStyle]="{width: '100%', height: '20px'}"
                            [(ngModel)]="selectcabe.PERIODO_FECINI" 
                            dateFormat="dd/mm/yy"
                            (ngModelChange)="cambio($event)" disabled="true"> 
                            </p-calendar>
                        </div>
                        <div class="p-col-2">
                            <p-calendar id="pPerFin" [inputStyle]="{width: '100%', height: '20px'}"
                            [(ngModel)]="selectcabe.PERIODO_FECFIN" 
                            dateFormat="dd/mm/yy"
                            (ngModelChange)="cambio($event)" disabled="true">
                            </p-calendar>
                        </div>
                        <div class="p-col-2">
                            <span style="text-align: center; color: rgb(169,50,38); font-weight: bold;font-size: 15px;">{{selectcabe.PERIODO_CODIGO}}</span>
                        </div>    
                    </div>
                    <div class="p-grid">
                        <div class="p-col-2">	
                            <span>Salario Básico Unificado:</span>
                        </div>
                        <div class="p-col-2">
                        </div>
                        <div class="p-col-2">
                            <input type="text" id="inpSALABASIUNIF" [(ngModel)]="selectcabe.ENCLIQUBENESOCI_SALABASIUNIF"  (keydown.enter)="setFocus($event)" style="width: 100%;text-align: right;" (keydown)="botonesmodificar()"  autocomplete="off"  pInputText disabled="true">
                        </div>
                        <div class="p-col-2">
                            <button  pButton class="aceptar" style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 99% ;margin-bottom: 5px;" (click)="opcionAceptar()">Aceptar</button>	
                        </div>
                    </div>
                </div> 
            </div>
        </div>

        <p-tabView (onChange)="onTab1Change($event)" [(activeIndex)]="indexTab">
            <p-tabPanel header="Detalle" >        
                <app-aggridsaci #detalle
                                [width]=""
                                [height]="largo"
                                [enableSorting]="false"
                                [enableFilter]="true"
                                [rowData]="liqservice.nomliqdet"
                                [frameworkComponents]="frameworkComponents"
                                [animateRows]="true"
                                [rowSelection]="rowSelection"
                                [columnDefs]="columnnomliqdcab"
                                [defaultColDef]="defaultColDefDetliqdcab"
                                [rowStyle]="rowStyle"
                                [singleClickEdit]="false"
                                [botones]="true"
                                (selected)="selecnomliqdcab($event)"
                                [mostrarGuardar]="false"
                                (cambios)="cambionomliqdcab()"
                                [botonesAdicionales]="true"
                                [botonGuardar]="true"
                                [botonNuevo]="true"
                                [botonBorrar]="true"
                                [botonRegresar]="true"
                                [botonBuscar]="false"
                ></app-aggridsaci>
            </p-tabPanel>
            <p-tabPanel header="Varios" >
                <app-aggridsaci  #varios
                                [width]=""
                                [height]="largo"
                                [enableSorting]="false"
                                [enableFilter]="true"
                                [rowData]="liqservice.nomliquvar"
                                [frameworkComponents]="frameworkComponents"
                                [animateRows]="true"
                                [rowSelection]="rowSelection"
                                [columnDefs]="columnnomliqdvar"
                                [defaultColDef]="defaultColDefDetliqdvar"
                                [rowStyle]="rowStyle"
                                [singleClickEdit]="false"
                                [botones]="true"
                                (selected)="selecnomliqdvar($event)"
                                [mostrarGuardar]="false"
                                (cambios)="cambionomliqdvar($event)"
                                [botonesAdicionales]="true"
                ></app-aggridsaci>
            </p-tabPanel>
        
        <div class="divgrups">
            <div class="p-grid">
                <div class="p-col-12">
                    <div class="p-grid">                                    
                        <div class="p-col-2">
                            <span>Horas Tiempo Parcial:</span>
                        </div>
                        <div class="p-col-1">
                            <input class="frm" id="inp_HORA_TIEMPARC" type="text" pInputText 
                            [(ngModel)]="dblHORA_TIEMPARC"
                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"          
                            autocomplete="off" style="text-align: right;" disabled>
                        </div>
                        <div class="p-col-2">
                            <span>Acumulado Base de Provisión Total</span>
                        </div>
                        <div class="p-col-1">
                            <input class="frm" id="inp_ACUM_BASEPROVTOTA" type="text" pInputText 
                            [(ngModel)]="dblACUM_BASEPROVTOTA"
                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"          
                            autocomplete="off" style="text-align: right;" disabled>
                        </div>
                        <div class="p-col-2">
                            <span>Acumulado de Base de Provisión Pagados:</span>
                        </div>
                        <div class="p-col-1">
                            <input class="frm" id="inp_ACUM_BASEPROVPAGA" type="text" pInputText 
                            [(ngModel)]="dblACUM_BASEPROVPAGA"
                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"          
                            autocomplete="off" style="text-align: right;" disabled>
                        </div>
                        <div class="p-col-2">
                            <span>Anticipo Provisión:</span>
                        </div>
                        <div class="p-col-1">
                            <input class="frm" id="inp_ANTI_PROV" type="text" pInputText 
                            [(ngModel)]="dblANTI_PROV"
                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"          
                            autocomplete="off" style="text-align: right;" disabled>
                        </div>  
                    </div>
                    <div class="p-grid">                                    
                        <div class="p-col-2">
                            <span>Acumulado de Horas Provisión:</span>
                        </div>
                        <div class="p-col-1">
                            <input class="frm" id="inp_ACUM_HORAPROV" type="text" pInputText 
                            [(ngModel)]="dblACUM_HORAPROV"
                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"          
                            autocomplete="off" style="text-align: right;" disabled>
                        </div>
                        <div class="p-col-2">
                            <span>Acumulado de Valor de Provisión Total:</span>
                        </div>
                        <div class="p-col-1">
                            <input class="frm" id="inp_ACUM_VALOPROVTOTA" type="text" pInputText 
                            [(ngModel)]="dblACUM_VALOPROVTOTA"
                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"          
                            autocomplete="off" style="text-align: right;" disabled>
                        </div>
                        <div class="p-col-2">
                            <span>Acumulado de Valor de Provisión Pagados:</span>
                        </div>
                        <div class="p-col-1">
                            <input class="frm" id="inp_ACUM_VALOPROVPAGA" type="text" pInputText 
                            [(ngModel)]="dblACUM_VALOPROVPAGA"
                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"          
                            autocomplete="off" style="text-align: right;" disabled>
                        </div>
                        <div class="p-col-2">
                            <span>Descuento Provisión:</span>
                        </div>
                        <div class="p-col-1">
                            <input class="frm" id="inp_DESC_PROV" type="text" pInputText 
                            [(ngModel)]="dblDESC_PROV"
                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"          
                            autocomplete="off" style="text-align: right;" disabled>
                        </div>
                        
                    </div> 
                    <div class="p-grid">                                    
                        <div class="p-col-2">
                            <span>Acumulado de Días Provisión Total:</span>
                        </div>
                        <div class="p-col-1">
                            <input class="frm" id="inp_ACUM_DIASPROVTOTA" type="text" pInputText 
                            [(ngModel)]="dblACUM_DIASPROVTOTA"
                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"          
                            autocomplete="off" style="text-align: right;" disabled>
                        </div>
                        <div class="p-col-2">
                            <span>Acumulado de Días Pagados:</span>
                        </div>
                        <div class="p-col-1">
                            <input class="frm" id="inp_ACUM_DIASPAGA" type="text" pInputText 
                            [(ngModel)]="dblACUM_DIASPAGA"
                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"          
                            autocomplete="off" style="text-align: right;" disabled>
                        </div>
                        <div class="p-col-2">
                            <span>Ajuste Provisión:</span>
                        </div>
                        <div class="p-col-1">
                            <input class="frm" id="inp_AJUS_PROV" type="text" pInputText 
                            [(ngModel)]="dblAJUS_PROV"
                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"          
                            autocomplete="off" style="text-align: right;" disabled>
                        </div>
                        <div class="p-col-2">
                            <span>Descuento por Retencion Judicial:</span>
                        </div>
                        <div class="p-col-1">
                            <input class="frm" id="inp_DESCRETEJUDI" type="text" pInputText 
                            [(ngModel)]="dblDETLIQUBENESOCI_DESCRETEJUDI"
                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"          
                            autocomplete="off" style="text-align: right;" disabled>
                        </div>
                        
                    </div>
                    <div class="p-grid">                                    
                        <div class="p-col-9">
                        </div>
                        <div class="p-col-2">
                            <span>Neto a Pagar:</span>
                        </div>
                        <div class="p-col-1">
                            <input class="frm" id="inp_NETOAPAGAR" type="text" pInputText 
                            [(ngModel)]="dblDETLIQUBENESOCI_NETOAPAGAR"
                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"          
                            autocomplete="off" style="text-align: right;" disabled>
                        </div>
                        
                    </div>                       
                </div>
            </div>
        </div>
    
    </p-tabView>
    
    <p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
        [transitionOptions]="'0ms'"
        [modal]="true"
        appendTo="body"
        [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
                [consulta]="consulta" [busquedaParams]="busquedacampos"
                (opcionSeleccionada)="manejarSeleccionbus($event)"></app-busquedadlg>
    </p-dialog>
    <p-dialog header="Busqueda de Datos" [(visible)]="dispDiausFastRubro"
        [transitionOptions]="'0ms'"
        [modal]="true"
        appendTo="body"
        [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloRubro" [consulta]="consulta" [tabla]="tabla"
                [where]="where"
                [busquedaParams]="busquedacampos"
                (opcionSeleccionada)="manejarRubro($event)"></app-busquedadlg>
    </p-dialog>

    <p-dialog header="Asiento Contable" [(visible)]="displayAsiContable"
            [transitionOptions]="'0ms'"
            [modal]="true"
            appendTo="body"
            [style]="{width:'90vw', height:'120vh'}"
            [draggable]="false">
        <app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="asiento" [abiertoDesde]="'nomliqudeciterc'"
                        (signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
    </p-dialog>
    <p-dialog header="Busqueda de Datos 1" [(visible)]="displayDialogBusqArray"
            [transitionOptions]="'0ms'"
            [modal]="true"
            appendTo="body"
            [style]="{width: '70vw', height: '70vh'} ">
        <app-busquedadlgmae [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
                            [where]="condicion"
                            [busquedaParams]="busquedacampos"
                            (opcionSeleccionada)="manejarSelecGridbus($event)"
                            [busquedaCompuesta]="false"></app-busquedadlgmae>
    </p-dialog>
</div>

<p-dialog header="Reportes" [(visible)]="displayDialogReportes" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '40vw', height: '400px'}">
    <app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
        [strNumDoc]="selectcabe.MAERUBRO_CODRUBR" [strCampo]="'MAERUBRO_CODRUBR'"
        (cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'NOMLIQDECTER'" [txtNumComprobante] = "selectcabe.MAERUBRO_CODRUBR"
	(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>