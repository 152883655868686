<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="cxctrnretenciones"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="conftrnretenciones" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 99%;">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
			[botonBorrar]="btnBorrar" [botonverAsiento]="botonVerAsiento" (signal)="manejarSenales($event)"
			[botonAnterior]="botonAnterior" [botonPrimero]="botonPrimero" [botonUltimo]="botonUltimo"
			[botonBuscar]="botonBuscar" [botonAsiento]="botonAsiento" [botonSiguiente]="botonSiguiente"
			[barraCartera]="false" [barraBotones3]="true" [botonAnular]="botonAnular" [botonXML]="botonXML"
			[botonReenviarCorreo]="botonReenviarCorreo"></app-invmaebarra>
	</div>
	<div class="divgrups2" style="margin-top: -10px; margin-bottom: -10px;">
		<div class="p-col-12">
			<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px;">
				<div class="p-col-2">
					<input type="text" pInputText
						[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_NRODOC"
						[disabled]="true" autocomplete="off">
				</div>
				<div class="p-col-1">
					<input type="text" pInputText *ngIf="booleanAnulado === true" value="ANULADO"
						style="color: darkgreen; font-weight: bold" autocomplete="off">
				</div>
				<div class="p-col-2">
					<input type="text" pInputText [(ngModel)]="impresoString"
						style="color: darkgreen; font-weight: bold" autocomplete="off">
				</div>
				<div class="p-col-1">
					<input type="text" pInputText style="background-color: darkblue; font-weight: bold; color: white;"
						[disabled]="true"
						[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBROND_ESTADO_FE"
						autocomplete="off">
				</div>
				<div class="p-col-2">
					<input type="text" pInputText style="background-color: darkblue; font-weight: bold; color: white;"
						[disabled]="true"
						[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBROND_AUTORIZACION_FE"
						autocomplete="off">
				</div>
				<div class="p-col-2">
					<input type="text" pInputText style="background-color: darkblue; font-weight: bold; color: white;"
						[disabled]="true"
						[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBROND_CLAVEACCESO_FE"
						autocomplete="off">
				</div>
				<div class="p-col-2">
					<input type="text" pInputText
						[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].ASI_NRO"
						[disabled]="true" autocomplete="off">
				</div>
			</div>
		</div>
	</div>
	<div class="p-col-12">
		<p-tabView [(activeIndex)]="activeIndex" (onChange)="changeTabs()">
			<p-tabPanel header="General">
				<div class="divgrups" id="divPrincipal">
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Cliente:</span>
								</div>
								<div class="p-col-2">
									<input type="text" id="cliente" (keydown.enter)="cambiarFoco($event)"
										(input)="cambio($event)"
										[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].CLI_CODIGO"
										pInputText style="width: 100%" autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="abrirClientes()"></button>
								</div>
								<div class="p-col-3">
									<span
										style="width: 100%; color:blue">{{cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].CLI_NOMBRE}}</span>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Saldo Actual:</span>
								</div>
								<div class="p-col-2">
									<span
										style="width: 100%; color:blue">{{cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].SALDO}}</span>
								</div>
								<div class="p-col-1"></div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -17px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Vendedor:</span>
								</div>
								<div class="p-col-2">
									<input type="text" pInputText style="width: 100%" id="vendedor"
										(keydown.enter)="cambiarFoco($event)" (input)="cambio($event)"
										[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].VEN_CODIGO"
										autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="abrirVendedor()"></button>
								</div>
								<div class="p-col-3">
									<span
										style="width: 100%;">{{cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].VEN_NOMBRE}}</span>
								</div>
								<div class="p-col-1"></div>
								<div id="errasiento" class="p-col-4" style="display: none;" >
									<h3  style="font-weight: bold;margin-top: -5px; background-color: red; color: seashell; 
									text-align: center; ">
										ERROR EN EL ASIENTO CONTABLE <br> por favor revise la información</h3>
								</div>
							</div>
						</div>
						<!-- <span style="font-weight: bold;margin-top: -15px">Documento</span> -->
						<div class="p-col-12" style="margin-top: -7px">
							<div class="p-grid">
								<!-- <div class="p-col-1">
									<span>Tipo:</span>
								</div>
								<div class="p-col-2">
									<p-dropdown [style]="{'width': '100%'}" [options]="opcionesTipoDoc"
										[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC"
										(ngModelChange)="cambiarTipoCartera()"></p-dropdown>
								</div> -->
								<div class="p-col-1">
									<span>Número:</span>
								</div>
								<div class="p-col-2">
									<input type="text" id="numeroCartera" [disabled]="booleanNumero" pInputText
										style="width: 100%" (input)="cambio($event)"
										(keydown.enter)="cambiarFoco($event)"
										[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_NROCOMPROBANTE"
										autocomplete="off">
								</div>
								<div class="p-col-1">
									<span>Fecha:</span>
								</div>
								<div class="p-col-1">
									<p-calendar [inputStyle]="{'width': '100%'}" dateFormat="dd/mm/yy"
										(ngModelChange)="cambio($event)"
										[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_FECHATRN">
									</p-calendar>
								</div>
								<!-- <div class="p-col-2">
									<p-checkbox label="Saldo Inicial" binary="true" [(ngModel)]="saldoInicial"
										[disabled]="bolsaldoIni" (ngModelChange)="cambioSaldo()"></p-checkbox>
								</div> -->
								<!-- <div class="p-col-1">
									<span *ngIf="booleanBodega === true">Bodega</span>
								</div>
								<div class="p-col-1">
									<input type="text" id="bodega" pInputText style="width: 100%"
										(input)="cambio($event)" (keydown.enter)="cambiarFoco($event)"
										*ngIf="booleanBodega === true"
										[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].BOD_CODIGO"
										autocomplete="off">
								</div> -->
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Concepto:</span>
								</div>
								<div class="p-col-11">
									<input type="text" id="concepto" (keydown.enter)="cambiarFoco($event)"
										(input)="cambio($event)" pInputText style="width: 100%"
										[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_CONCEPTO"
										autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px; margin-bottom: -15px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Origen:</span>
								</div>
								<div class="p-col-4">
									<span
										style="width: 100%; text-align: left">{{cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].ORIGEN}}</span>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Realizado Por:</span>
								</div>
								<div class="p-col-4">
									<span
										style="width: 100%; text-align: left">{{cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].REALIZADO}}</span>
								</div>
								<div class="p-col-1"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="p-grid">
					<div class="divgrups3">
						<div class="p col-12">
							<div class="p-grid">
								<div class="p-col-12">
									<p-tabView [(activeIndex)]="indiceTablas" (activeIndexChange)="actualizarTablas()">
										<p-tabPanel header="Documentos">
											<app-aggridsaci
												*ngIf="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC !== 'FC' && cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC !== 'ANC' && cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC !== 'ND' && cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC !== 'NDA'"
												[width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
												[rowData]="cxctrnretencionesService.cxctrnresumenarray[indicador]"
												[frameworkComponents]="frameworkComponents" [animateRows]="true"
												[rowSelection]="rowSelection" [columnDefs]="columDefCarteraDoc"
												[defaultColDef]="defaultColumnDefCarteraDoc" [rowStyle]="rowStyle"
												[singleClickEdit]="false" [botones]="false" [mostrarGuardar]="false"
												(selected)="seleccionDocumento($event)" (cambios)="cambio($event)"
												[suppressCellSelection]="editarCeldas"
												[tipodetalleactivo]="tipodetalleactivo"></app-aggridsaci>
											<div class="p-grid"
												*ngIf="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC !== 'FC' && cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC !== 'ANC' && cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC !== 'ND' && cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC !== 'NDA'">
												<div class="p-col-3">
													<div class="p-grid">
														<div class="p-col-4"
															style="margin-top: 10px; height: 25%; background-color: #AAFF00">
														</div>
														<div class="p-col-8">
															<span>Facturas Generadas por cuotas</span>
														</div>
													</div>
												</div>
												<div class="p-col-5"></div>
												<div class="p-col-2"
													*ngIf="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC === 'NC'">
													<span style="font-weight: bold">Total Nota de Crédito</span>
												</div>
												<div class="p-col-1"
													*ngIf="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC === 'NC'">
													<input type="text" style="font-weight: bold" pInputText
														[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TOTALNC"
														autocomplete="off">
												</div>
											</div>
											<div class="p-grid"
												*ngIf="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC === 'FC' || cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC === 'ANC' ||
											cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC === 'ND' || cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC === 'NDA'">
												<div class="p-col-12"></div>
												<div class="p-col-12"></div>
												<div class="p-col-12"></div>
												<div class="p-col-12"></div>
												<div class="p-col-12"></div>
												<div class="p-col-12"></div>
												<div class="p-col-12"></div>
												<div class="p-col-12"></div>
												<div class="p-col-12">
													<div class="p-grid">
														<div class="p-col-1"></div>
														<div class="p-col-1">
															<span>Importe:</span>
														</div>
														<div class="p-col-2">
															<input type="text" id="importe" pInputText
																style="width: 100%"
																(keydown.enter)="cambiarFoco($event)"
																[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_IMPORTE"
																(click)="$event.target.select()" pKeyFilter="num"
																autocomplete="off">
														</div>
														<div class="p-col-2"></div>
														<div class="p-col-2">
															<span
																*ngIf="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC === 'FC' || cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC === 'ND' || cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC === 'NDA'">Fecha
																Vencimiento:</span>
															<span
																*ngIf="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC === 'ANC'">Saldo:</span>
														</div>
														<div class="p-col-2">
															<p-calendar
																*ngIf="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC === 'FC' || cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC === 'ND' || cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC === 'NDA'"
																[inputStyle]="{'width': '100%'}" dateFormat="dd/mm/yy"
																[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_FECHAVENCE">
															</p-calendar>
															<input type="text" pInputText id="saldo"
																(keydown.enter)="cambiarFoco($event)"
																*ngIf="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC === 'ANC'"
																[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].SALDOANC"
																disabled>
														</div>
														<div class="p-col-2"></div>
													</div>
												</div>
												<div class="p-col-12"></div>
												<div class="p-col-12"></div>
												<div class="p-col-12"></div>
												<div class="p-col-12"></div>
												<div class="p-col-12"></div>
												<div class="p-col-12"></div>
											</div>
										</p-tabPanel>
										<p-tabPanel *ngIf="tabRetenciones === true" header="Retenciones">
											<div class="p-grid">
												<div class="p-col-1">
													<span style="font-weight: bold">Factura:</span>
												</div>
												<div class="p-col-2">
													<input type="text" [disabled]="true" pInputText
														[(ngModel)]="numeroFactura" autocomplete="off">
												</div>
												<div class="p-col-2">
													<p-checkbox label="Retención Electrónica" binary="true"
														(click)="cambio($event)" [(ngModel)]="retencionElect" [disabled] = "!btneditConcil">
													</p-checkbox>
												</div>
												<div class="p-col-7">
													<span
														style="color: rgb(0, 0, 255); font-weight: bold">{{lblAplicaRegMicroempresas}}</span>
												</div>
											</div>
											<app-aggridsaci *ngIf="tabRetenciones === true" [width]="" [height]="largo"
												[enableSorting]="false" [enableFilter]="true"
												[rowData]="cxctrnretencionesService.cxctrnretencionarray[indicador]"
												[frameworkComponents]="frameworkComponents" [animateRows]="true"
												[rowSelection]="rowSelection" [columnDefs]="columDefCarteraRetencion"
												[defaultColDef]="defaultColumnDefCarteraRet" [rowStyle]="rowStyle"
												[singleClickEdit]="false" [botones]="btneditConcil" [mostrarGuardar]="false"
												[botonesAdicionales]="true" [btnXmlret]="false"
												(selected)="seleccionRetencion($event)" (cambios)="cambio($event)"
												[suppressCellSelection]="editarCeldas"
												[tipodetalleactivo]="tipodetalleactivo"></app-aggridsaci>
											<div class="p-grid">
												<div class="p-col-9"></div>
												<div class="p-col-2">
													<span style="font-weight: bold">Total Retenciones por Fact.</span>
												</div>
												<div class="p-col-1">
													<input type="text" style="font-weight: bold" pInputText
														[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TOTALRET"
														autocomplete="off">
												</div>
											</div>
										</p-tabPanel>
										<!-- <p-tabPanel *ngIf="tabOtrosGastos === true" header="Otros Gastos">
											<app-aggridsaci *ngIf="tabOtrosGastos === true" [width]="" [height]="largo"
												[enableSorting]="true" [enableFilter]="true"
												[rowData]="cxctrnretencionesService.cxctrnotrosgarray[indicador]"
												[frameworkComponents]="frameworkComponents" [animateRows]="true"
												[rowSelection]="rowSelection" [columnDefs]="columDefCarteraOtrosG"
												[defaultColDef]="defaultColumnDefCarteraOtrosG" [rowStyle]="rowStyle"
												[singleClickEdit]="false" [botones]="btneditConcil" [mostrarGuardar]="false"
												(selected)="seleccionGastos($event)" (cambios)="cambio($event)"
												[suppressCellSelection]="editarCeldas"
												[tipodetalleactivo]="tipodetalleactivo"></app-aggridsaci>
										</p-tabPanel> -->
										<!-- <p-tabPanel *ngIf="tabFormaPago === true" header="Forma Pago">
											<app-aggridsaci *ngIf="tabFormaPago === true" [width]="" [height]="largo"
												[enableSorting]="true" [enableFilter]="true"
												[rowData]="cxctrnretencionesService.cxctrnformapagoarray[indicador]"
												[frameworkComponents]="frameworkComponents" [animateRows]="true"
												[rowSelection]="rowSelection" [columnDefs]="columDefCarteraFormaPago"
												[defaultColDef]="defaultColumnDefCarteraFormaPago"
												[rowStyle]="rowStyleFp" [singleClickEdit]="false" [botones]="btneditConcil"
												[mostrarGuardar]="false" (cambios)="cambio($event)"
												(selected)="seleccionFormaPago($event)"
												[suppressCellSelection]="editarCeldas"
												[tipodetalleactivo]="tipodetalleactivo"></app-aggridsaci>
											<div class="p-grid">
												<div class="p-col-2">
													<div class="p-grid">
														<div class="p-col-4">
															<p-colorPicker [disabled]="true"
																[(ngModel)]="choosedColorG"></p-colorPicker>
														</div>
														<div class="p-col-8">
															<span>Documentos Depositados</span>
														</div>
													</div>
												</div>
												<div class="p-col-2">
													<div class="p-grid">
														<div class="p-col-4">
															<p-colorPicker [disabled]="true"
																[(ngModel)]="choosedColorSB"></p-colorPicker>
														</div>
														<div class="p-col-8">
															<span>Documentos Conciliados</span>
														</div>
													</div>
												</div>
											</div>
										</p-tabPanel> -->
										<!-- <p-tabPanel *ngIf="tabVarios === true" header="Varios">
											<app-aggridsaci *ngIf="tabVarios === true" [width]="" [height]="largo"
												[enableSorting]="true" [enableFilter]="true"
												[rowData]="cxctrnretencionesService.cxctrnvariosarray[indicador]"
												[frameworkComponents]="frameworkComponents" [animateRows]="true"
												[rowSelection]="rowSelection" [columnDefs]="columDefCarteraVarios"
												[defaultColDef]="defaultColumnDefCarteraVarios" [rowStyle]="rowStyle"
												[singleClickEdit]="false" [botones]="true" [mostrarGuardar]="false"
												(selected)="seleccionVarios($event)" (cambios)="cambio($event)"
												[suppressCellSelection]="editarCeldas"
												[tipodetalleactivo]="tipodetalleactivo"></app-aggridsaci>
											<div class="p-grid">
												<div class="p-col-9"></div>
												<div class="p-col-2"
													*ngIf="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC === 'NC'">
													<span style="font-weight: bold">Total Nota de Crédito.</span>
												</div>
												<div class="p-col-2"
													*ngIf="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC === 'ND'">
													<span style="font-weight: bold">Total Nota de Débito.</span>
												</div>
												<div class="p-col-1"
													*ngIf="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC === 'NC'">
													<input type="text" style="font-weight: bold" pInputText
														[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TOTALNC"
														autocomplete="off">
												</div>
												<div class="p-col-1"
													*ngIf="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC === 'ND'">
													<input type="text" style="font-weight: bold" pInputText
														[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TOTALND"
														autocomplete="off">
												</div>
											</div>
										</p-tabPanel> -->
										<!-- <p-tabPanel *ngIf="tabEstadoCuentaAnt === true"
											header="Estado de Cuenta Anticipo">
											<app-aggridsaci *ngIf="tabEstadoCuentaAnt === true" [width]=""
												[height]="largo" [enableSorting]="true" [enableFilter]="true"
												[rowData]="cxctrnretencionesService.cxctrnanticiposarray[indicador]"
												[frameworkComponents]="frameworkComponents" [animateRows]="true"
												[rowSelection]="rowSelection" [columnDefs]="columDefCarteraAnticipo"
												[defaultColDef]="defaultColumnDefCarteraAnticipo"
												[rowStyle]="rowStyleECAnc" [singleClickEdit]="false" [botones]="false"
												[mostrarGuardar]="false" (selected)="seleccionAnt()"
												(cambios)="cambio($event)" [suppressCellSelection]="editarCeldas"
												[tipodetalleactivo]="tipodetalleactivo"></app-aggridsaci>
										</p-tabPanel> -->
										<!-- <p-tabPanel *ngIf="tabDebitoBancario === true" header="Débito Bancario">
											<div class="p-grid">
												<div class="p-col-1"></div>
												<div class="p-col-1">
													<span>Banco:</span>
												</div>
												<div class="p-col-1">
													<input type="text" pInputText id="banco"
														(keydown.enter)="cambiarFoco($event)"
														[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].BAN_CODIGO"
														autocomplete="off">
												</div>
												<div class="p-col-1">
													<button type="button"
														style="background-image: url(../../../assets/images/iconos/buscar.png);"
														(click)="abrirBancos()"></button>
												</div>
												<div class="p-col-2">
													<span>{{cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].BAN_BANCO}}</span>
												</div>
												<div class="p-col-1"></div>
												<div class="p-col-1">
													<span>Ref Contable:</span>
												</div>
												<div class="p-col-2">
													<span>{{cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].CON_CODIGO}}</span>
												</div>
												<div class="p-col-2"></div>
											</div>
											<app-aggridsaci *ngIf="tabDebitoBancario === true" [width]=""
												[height]="largo" [enableSorting]="true" [enableFilter]="true"
												[rowData]="cxctrnretencionesService.cxcdebitobancarioarray[indicador]"
												[frameworkComponents]="frameworkComponents" [animateRows]="true"
												[rowSelection]="rowSelection" [columnDefs]="columDefCarteraDebito"
												[defaultColDef]="defaultColumnDefCarteraDebito" [rowStyle]="rowStyle"
												[singleClickEdit]="false" [botones]="false" [mostrarGuardar]="false"
												(selected)="seleccionDebito()" (cambios)="cambio($event)"
												[suppressCellSelection]="editarCeldas"
												[tipodetalleactivo]="tipodetalleactivo"></app-aggridsaci>
											<div class="p-grid">
												<div class="p-col-6"></div>
												<div class="p-col-2">
													<span style="font-weight: bold">Total Nota Débito</span>
												</div>
												<div class="p-col-1">
													<input type="text" style="font-weight: bold" pInputText
														[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TOTALND"
														autocomplete="off">
												</div>
												<div class="p-col-3"></div>
											</div>
										</p-tabPanel> -->
										<!-- <p-tabPanel *ngIf="tabFormaPagoDev === true" header="Forma de Pago">
											<app-aggridsaci *ngIf="tabFormaPagoDev === true" [width]="" [height]="largo"
												[enableSorting]="true" [enableFilter]="true"
												[rowData]="cxctrnretencionesService.cxctrnformapagodevarray[indicador]"
												[frameworkComponents]="frameworkComponents" [animateRows]="true"
												[rowSelection]="rowSelection" [columnDefs]="columDefCarteraFormaPagoDev"
												[defaultColDef]="defaultColumnDefCarteraFormaPagoDev"
												[rowStyle]="rowStyleFpdev" [singleClickEdit]="false" [botones]="true"
												[mostrarGuardar]="false" (cambios)="cambio($event)"
												(selected)="seleccionFormaPagoDev($event)"></app-aggridsaci>
											<div class="p-grid">
												<div class="p-col-3">
													<div class="p-grid">
														<div class="p-col-4">
															<p-colorPicker [disabled]="true"
																[(ngModel)]="choosedColorSB"></p-colorPicker>
														</div>
														<div class="p-col-8">
															<span>Documentos Conciliados</span>
														</div>
													</div>
												</div>
											</div>
										</p-tabPanel> -->
									</p-tabView>
									<div class="divgrupstabla">
										<div class="p-grid">
											<span style="font-weight: bold; text-align: left">Totales</span>
											<div class="p-col-12" style="margin-top: -10px;">
												<div class="p-grid">
													<div class="p-col-1">
														<span class="obligatorio">Documentos</span>
													</div>
													<div class="p-col-1"></div>
													<div class="p-col-1">
														<span class="obligatorio">Retenciones</span>
													</div>
													<div class="p-col-1"></div>
													<!-- <div class="p-col-1">
														<span class="obligatorio">Otros Gastos</span>
													</div> -->
													<div class="p-col-1"></div>
													<!-- <div class="p-col-1">
														<span class="obligatorio">Forma de Pago</span>
													</div> -->
													<div class="p-col-1"></div>
													<!-- <div class="p-col-1">
														<span class="obligatorio">Anticipo</span>
													</div> -->
													<div class="p-col-1"></div>
													<!-- <div class="p-col-1">
														<span
															*ngIf="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC !== 'DAN'"
															class="obligatorio">A Cobrar</span>
														<span
															*ngIf="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC === 'DAN'"
															class="obligatorio">A Pagar</span>
													</div> -->
													<div class="p-col-1"></div>
												</div>
											</div>
											<div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px">
												<div class="p-grid">
													<div class="p-col-1">
														<input type="text" class="totales" pInputText
															[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TOTALDOC"
															[disabled]="true" autocomplete="off">
													</div>
													<div class="p-col-1"></div>
													<div class="p-col-1">
														<input type="text" class="totales" pInputText
															[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TOTALRET"
															[disabled]="true" autocomplete="off">
													</div>
													<div class="p-col-1"></div>
													<!-- <div class="p-col-1">
														<input type="text" class="totales" pInputText
															[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TOTALOTROSG"
															[disabled]="true" autocomplete="off">
													</div> -->
													<div class="p-col-1"></div>
													<!-- <div class="p-col-1">
														<input type="text" class="totales" pInputText
															[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TOTALFORMAPAGO"
															[disabled]="true" autocomplete="off">
													</div> -->
													<div class="p-col-1"></div>
													<!-- <div class="p-col-1">
														<input type="text" class="totales" pInputText
															[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TOTALANTICIPO"
															[disabled]="true" autocomplete="off">
													</div> -->
													<div class="p-col-1"></div>
													<!-- <div class="p-col-1">
														<input type="text" class="totales" pInputText
															[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TOTALACOBRAR"
															[disabled]="true" autocomplete="off">
													</div> -->
													<div class="p-col-1"></div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</p-tabPanel>
			<p-tabPanel header="Estado de Cuenta">
				<div class="divgrups">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Cliente:</span>
						</div>
						<div class="p-col-6">
							<span
								style="color: blue">{{cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].CLI_CODIGO + ' - ' + cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].CLI_NOMBRE}}</span>
						</div>
						<div class="p-col-4">
						</div>
					</div>
				</div>
				<app-aggridsaci [width]="" [height]="largoestcta" [enableSorting]="true" [enableFilter]="true"
					[rowData]="cxctmptrnresumen" [frameworkComponents]="frameworkComponents" [animateRows]="true"
					[rowSelection]="rowSelection" [columnDefs]="columnDefsCxctmptrnresumen"
					[defaultColDef]="defaultColDefCxctmptrnresumen" [rowStyle]="rowStyleEC" [botones]="false"
					[singleClickEdit]="false"></app-aggridsaci>
			</p-tabPanel>
			<p-tabPanel header="Saldos">
				<div class="divgrups">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Cliente:</span>
						</div>
						<div class="p-col-6">
							<span
								style="color: blue">{{cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].CLI_CODIGO + ' - ' + cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].CLI_NOMBRE}}</span>
						</div>
						<div class="p-col-4">
						</div>
					</div>
				</div>
				<app-aggridsaci [width]="" [height]="largoestcta" [enableSorting]="true" [enableFilter]="true"
					[rowData]="cxctrnresumen" [frameworkComponents]="frameworkComponents" [animateRows]="true"
					[rowSelection]="rowSelection" [columnDefs]="columnDefsCxctrnresumen"
					[defaultColDef]="defaultColDefCxctrnresumen" [rowStyle]="rowStyleSaldo" [botones]="false"
					[singleClickEdit]="false"></app-aggridsaci>
			</p-tabPanel>
			<!-- <p-tabPanel header="Anexos" *ngIf="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC === 'ND' ||
						cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].TRNCOBRO_TIPODOC === 'NC'">
				<div class="divgrups3">
					<span style="font-weight: bold">Datos Generales</span>
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<span>Id del sustento tributario:</span>
								</div>
								<div class="p-col-1">
									<input type="text" style="width: 100%" [disabled]="false"
										(keydown.enter)="setFocus($event)"
										[(ngModel)]="anexoSeleccionado.TRNANEX_IDCRETRI" id="TRNANEX_IDCRETRI"
										autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="abrirSustentoTrib()"></button>
								</div>
								<div class="p-col-4">
									<input type="text" style="width: 100%" [disabled]="true"
										[(ngModel)]="anexoSeleccionado.DESCIDCRETRI" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -19px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<span>Tipo de Id. del Cliente:</span>
								</div>
								<div class="p-col-1">
									<input type="text" style="width: 100%" [disabled]="true"
										[(ngModel)]="anexoSeleccionado.IDSUSTRIB" autocomplete="off">
								</div>
								<div class="p-col-1">
								</div>
								<div class="p-col-4">
									<input type="text" style="width: 100%" [disabled]="true"
										[(ngModel)]="anexoSeleccionado.DESIDSUSTRIB" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<span>C. de Tipo de Comprobante:</span>
								</div>
								<div class="p-col-1">
									<input type="text" style="width: 100%" [disabled]="boltrnaextipcom"
										[(ngModel)]="anexoSeleccionado.TRNANEX_TIPCOM" autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button" [disabled]="boltrnaextipcom"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="abrirTipoComprobante()"></button>
								</div>
								<div class="p-col-4">
									<input type="text" style="width: 100%" [disabled]="boltrnaextipcom"
										[(ngModel)]="anexoSeleccionado.DESCTIPOCOMP" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -18px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<span>No. de Id del cliente RUC:</span>
								</div>
								<div class="p-col-3"><input type="text" style="width: 100%"
										*ngIf="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].CLI_CODIGO !== ''"
										[disabled]="true"
										[(ngModel)]="cxctrnretencionesService.encabezadoCarteraActivaarray[indicador].CLI_CODIGO"
										autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<span>Periodo o Mes Informado:</span>
								</div>
								<div class="p-col-1"><input type="text" style="width: 100%" [disabled]="true"
										[(ngModel)]="anexoSeleccionado.PERIODOINF" autocomplete="off"></div>
								<div class="p-col-2"><input type="text" style="width: 100%" [disabled]="true"
										[(ngModel)]="anexoSeleccionado.DESPERIODOINF" autocomplete="off"></div>
							</div>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-6">
							<span style="font-weight: bold">Datos del Documento</span>
						</div>
						<div class="p-col-6">
							<span style="font-weight: bold">Datos del Documento Modificado</span>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<span>Fecha del Registro Contable:</span>
								</div>
								<div class="p-col-2"><input type="text" style="width: 100%" [disabled]="true"
										[(ngModel)]="anexoSeleccionado.FECHACONTAB" autocomplete="off"></div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<span>Nro. de serie del Comp.:</span>
								</div>
								<div class="p-col-2"><input type="text" style="width: 100%" [disabled]="true"
										[(ngModel)]="anexoSeleccionado.SERIECOMP" autocomplete="off"></div>
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<span>Tipo de Comprobante Modificado:</span>
								</div>
								<div class="p-col-1">
									<input type="text" style="width: 100%" [disabled]="bolnc" (input)="cambio($event)"
										(keydown.enter)="setFocus($event)"
										[(ngModel)]="anexoSeleccionado.TRNANEX_TIPCOMMOD" [maxLength]="2"
										id="TIPOCOMPMODIF" autocomplete="off">
								</div>
								<div class="p-col-2">
									<input type="text" style="width: 100%" [disabled]="true"
										[(ngModel)]="anexoSeleccionado.DESCTIPOCOMPMODIFICADO" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<span>Nro. de secuencia del Comp.:</span>
								</div>
								<div class="p-col-2"><input type="text" style="width: 100%" [disabled]="true"
										[(ngModel)]="anexoSeleccionado.SECUENCIALCOMP"></div>
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<span>Nro de Serie:</span>
								</div>
								<div class="p-col-2">
									<input type="text" style="width: 100%" [disabled]="bolnc" (input)="cambio($event)"
										(keydown.enter)="setFocus($event)" [maxLength]="6"
										[(ngModel)]="anexoSeleccionado.TRNANEX_SERIEDOCMOD" id="SERIEDOCMODIF">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<span>Fecha de emisión del Comp.:</span>
								</div>
								<div class="p-col-2"><input type="text" style="width: 100%" [disabled]="true"
										[(ngModel)]="anexoSeleccionado.FECHAEMISIONCOMP" autocomplete="off"></div>
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<span>Nro de Secuencia:</span>
								</div>
								<div class="p-col-2">
									<input type="text" style="width: 100%" [disabled]="bolnc" (input)="cambio($event)"
										(keydown.enter)="setFocus($event)" [maxLength]="9"
										[(ngModel)]="anexoSeleccionado.TRNANEX_SECDOCMOD" id="SECUENCIADOCMODIF"
										autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<span>Nro. de Autorización del Comp.:</span>
								</div>
								<div class="p-col-3">
									<input type="text" style="width: 100%" [disabled]="bolnc"
										(keydown.enter)="setFocus($event)" (input)="cambio($event)"
										[(ngModel)]="anexoSeleccionado.TRNANEX_AUTORIZACION" [maxLength]="49"
										id="TRNANEX_AUTORIZACION" autocomplete="off">
								</div>
								<div class="p-col-2">
									<span>Nro de Autorización:</span>
								</div>
								<div class="p-col-3">
									<input type="text" style="width: 100%" [disabled]="bolnc"
										(keydown.enter)="setFocus($event)" (input)="cambio($event)"
										[(ngModel)]="anexoSeleccionado.TRNANEX_AUTDOCMOD" [maxLength]="49"
										id="AUTORIZACIONDOCMODIF" autocomplete="off">
								</div>
							</div>
						</div>
					</div>
					<span style="font-weight: bold">Impuestos</span>
					<div class="p-grid">
						<div class="p-col-2"></div>
						<div class="p-col-1">
							<span>Impuestos</span>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-1">
							<span>Tipos SRI</span>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<span>Base Imp. Tarifa 0%:</span>
								</div>
								<div class="p-col-2"><input type="text" style="width: 100%; text-align: right"
										[disabled]="bolnc" (input)="cambio($event)" (keydown.enter)="setFocus($event)"
										[(ngModel)]="anexoSeleccionado.TRNANEX_BASEIMPTARCERO" appTwoDigitDecimaNumber
										id="TRNANEX_BASEIMPTARCERO" autocomplete="off">
								</div>
								<div class="p-col-1">
									<input type="text" style="width: 100%" [disabled]="bolnc"
										(keydown.enter)="setFocus($event)" (input)="cambio($event)"
										[(ngModel)]="anexoSeleccionado.TRNANEX_TIPOSRITARCERO"
										id="TRNANEX_TIPOSRITARCERO" autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="buscarReferencia('TRNANEX_TIPOSRITARCERO')"></button>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<span style="font-weight: bold">Retenciones IVA:</span>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -18px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<span>Base Imp. Gravada de IVA:</span>
								</div>
								<div class="p-col-2"><input type="text" style="width: 100%; text-align: right"
										[disabled]="bolnc" (input)="cambio($event)" (keydown.enter)="setFocus($event)"
										[(ngModel)]="anexoSeleccionado.TRNANEX_BASEIMPGRABADAIVA"
										appTwoDigitDecimaNumber id="TRNANEX_BASEIMPGRABADAIVA" autocomplete="off">
								</div>
								<div class="p-col-1">
									<input type="text" style="width: 100%" [disabled]="bolnc"
										(keydown.enter)="setFocus($event)" (input)="cambio($event)"
										[(ngModel)]="anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA"
										id="TRNANEX_TIPOSRIGRAVADA" autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="buscarReferencia('TRNANEX_TIPOSRIGRAVADA')"></button>
								</div>
								<div class="p-col-2">
									<span>Monto Retención IVA-Bienes:</span>
								</div>
								<div class="p-col-2">
									<input type="text" style="width: 100%; text-align: right" [disabled]="bolnc"
										(keydown.enter)="setFocus($event)" (input)="cambio($event)"
										[(ngModel)]="anexoSeleccionado.TRNANEX_MONTORETIVABIE" appTwoDigitDecimaNumber
										id="TRNANEX_MONTORETIVABIE" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -18px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<span>Monto IVA:</span>
								</div>
								<div class="p-col-2"><input type="text" style="width: 100%; text-align: right"
										[disabled]="bolnc" (input)="cambio($event)" (keydown.enter)="setFocus($event)"
										[(ngModel)]="anexoSeleccionado.TRNANEX_MONTOIVA" appTwoDigitDecimaNumber
										id="TRNANEX_MONTOIVA" autocomplete="off"></div>
								<div class="p-col-2">
								</div>
								<div class="p-col-2">
									<span>Monto Retención de IVA-Servicios:</span>
								</div>
								<div class="p-col-2">
									<input type="text" style="width: 100%; text-align: right" [disabled]="bolnc"
										(input)="cambio($event)" (keydown.enter)="setFocus($event)"
										[(ngModel)]="anexoSeleccionado.TRNANEX_MONTORETIVASER" appTwoDigitDecimaNumber
										id="TRNANEX_MONTORETIVASER" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<span>Base Imp. no Obj de IVA:</span>
								</div>
								<div class="p-col-2"><input type="text" style="width: 100%; text-align: right"
										[disabled]="bolnc" (keydown.enter)="setFocus($event)" (input)="cambio($event)"
										[(ngModel)]="anexoSeleccionado.TRNANEX_BASEIMPNOOBJETOIVA"
										appTwoDigitDecimaNumber id="TRNANEX_BASEIMPNOOBJETOIVA" autocomplete="off">
								</div>
								<div class="p-col-2">
								</div>
								<div class="p-col-2">
									<span>Retención IVA 100%:</span>
								</div>
								<div class="p-col-2">
									<input type="text" style="width: 100%; text-align: right" [disabled]="bolnc"
										(keydown.enter)="setFocus($event)" (input)="cambio($event)"
										[(ngModel)]="anexoSeleccionado.TRNANEX_RETVACIEN" appTwoDigitDecimaNumber
										id="TRNANEX_RETVACIEN" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px; margin-bottom: -5px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<span>Monto ICE:</span>
								</div>
								<div class="p-col-2"><input type="text" style="width: 100%; text-align: right"
										[disabled]="bolnc" (keydown.enter)="setFocus($event)" (input)="cambio($event)"
										[(ngModel)]="anexoSeleccionado.TRNANEX_MONTOICE" appTwoDigitDecimaNumber
										id="TRNANEX_MONTOICE" autocomplete="off"></div>
							</div>
						</div>
					</div>
				</div>
			</p-tabPanel> -->
		</p-tabView>
	</div>
	<div class="p-col-12">
		<span *ngIf="lblDocCierre" style="color: darkgreen; text-align: center; font-weight: bold">Documento en Cierre
			de Caja</span>
	</div>

</div>
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla"
		[busquedaParams]="busquedacampos" [where]="where" (opcionSeleccionada)="manejarSeleccion($event)"
		[consulta]="consulta" [busquedaCompuesta]="busquedaCompuesta"></app-busquedadlg>
</p-dialog>

<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width:'90vw', height:'120vh'}" [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="asiento" [abiertoDesde]="'cxctrncobro'"
		(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	<p-dialog header="Clave Autorización" [(visible)]="claveDialog" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '60vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="autorizacion"></p-toast>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-4">
					<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-4">
					<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
						(keydown.enter)="comprobarClave()" placeholder="Ingrese la clave" autocomplete="off">
				</div>
				<div class="p-col-1"></div>
			</div>
		</div>
	</div>
</p-dialog>
</div>
<p-dialog header="Cargar Retención" [(visible)]="displayDialogXml" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '400px'}">
	<div class="divgrups">
		<div class="ui-g ui-fluid">
			<div class="p-grid">
				<div class="p-col-1">
				</div>
				<div class="p-col-11"></div>
				<div class="p-col-12">
					<label for="claveacce"></label>
					<input class="frm" id="claveacce" type="text" pInputText [(ngModel)]="claveacceso"
						autocomplete="off">
				</div>
				<div class="p-col-8">
				</div>
				<div class="p-col-2">
					<button type="button" class="big"
						style="background-image: url(../../../assets/images/iconos/visto.png);"
						(click)="cargardetallexml()"></button>
				</div>
				<div class="p-col-2">
					<button type="button" class="big"
						style="background-image: url(../../../assets/images/iconos/borrar.png);"
						(click)="cancelardetallexml()"></button>
				</div>

			</div>

		</div>
	</div>
</p-dialog>