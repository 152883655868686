//
// SACI WEB
// Rutina: Ingreso de Pedidos
// Propietario: Wilson Ramos
// Modificado por: WR
// Fecha de creación: 27-11-2019
// Fecha de Modificación: 30-12-2019
//

import { InvmaearticuloService } from '../../inv/invservicios/invmaearticulo.service';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { VenDetped } from '../veninterfaces/vendetped';
import { VenEncped } from '../veninterfaces/venencped';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { VenencpedService } from '../venservicios/venencped.service';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { Usuario } from '../../usuario';
import { DatePipe } from '@angular/common';
import { UtilitariosService } from '../../advantage/advantageservices/utilitarios.service';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { VenmaeclienteService } from '../venservicios/venmaecliente.service';
import { Cxctmptrnresumen } from '../veninterfaces/cxctmptrnresumen';
import { Cxctrnresumen } from '../veninterfaces/cxctrnresumen';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { VenDetFacturas, VenTopProductos } from '../veninterfaces/venestadisticas';
import { VenestadisticaService } from '../venservicios/venestadistica.service';
import { ComExistenciaBodegas } from '../../com/cominterfaces/comestadisticas';
import { Venencpedbasesiva } from "../veninterfaces/venencpedbasesiba";

@Component({
  selector: 'app-venencped',
  templateUrl: './venencped.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class VenencpedComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;
  btnNuevoGrid: boolean;
  btnGuardarGrid: boolean;
  btnBorrarGrid: boolean;
  btnCancelarGrid: boolean;
  btnImagenGrid: boolean;
  btnVerificarExis: boolean;
  btnAnular: boolean;
  btnBuscar: boolean;
  largo: string;
  auxiliar: number;
  decimalesCantidadPed: number;
  decimalesPrecioPed: number;
  decimalesTotalPed: number;
  decimalesDescPed: number;
  decimalesDespacho: number;
  numdecsist: number;
  imagenProducto: string;
  valorInvalidoV: boolean;
  valorInvalidoP: boolean;
  alineacion: string;
  consulta: string;
  where:string;
  configuracionExistencia: number;
  permisoPedido: SegMaePermiso;
  busquedacampos: any[];
  cxctmptrnresumen: Cxctmptrnresumen[];
  cxctrnresumen: Cxctrnresumen[];
  arregloCons: any[];
  estadoPedido: any[];
  estadoPedidoActual: any;
  tabla: string;
  indice: number;
  ivaDescBoolean: boolean;
  cotizacionBoolean: boolean;
  horaSistema: any;
  dscSeleccionado: string;
  valorDscValor: number;
  valorDscPorcentaje: number;
  estadoValor: string;
  razonValor: string;
  //opcionIva: SelectItem[];
  opcionIva: any[];//CDPJ
  selectcombtipoIva: any;//CDPJ
  iva:number//CDPJ
  displaybasesiva:boolean=false;
  basesiva:Venencpedbasesiva[]=[]
  defaultColBasesIva;
  opcion: string;
  opcionIvaDesc: SelectItem[];
  opcionEstado: SelectItem[];
  nombreCliente: string;
  nombreVendedor: string;
  opcionesprecio: SelectItem[];
  dscClienteActual: number;
  types: SelectItem[];
  listaprecios: any[];
  fechaBoolean: boolean;
  booleanValor: boolean;
  displayAcciones: boolean;
  booleanPorcentaje: boolean;
  maxDscto: number;
  spin = false;
  public frameworkComponents;
  encabezadosPedidos: VenEncped[];
  detallePedidoSeleccionado: VenDetped;
  detallePedidoSeleccionadoAux: VenDetped;
  encabezadoPedidoSeleccionadoAux: VenEncped;
  comboOpciones: any[];
  arregloBus: any[];
  claveAuth: string;
  displayDialogBus: boolean;
  displayDialogBusquedaFast: boolean;
  displayDialogDescuento: boolean;
  claveDialog: boolean;
  estadopedDialog: boolean;
  isNewLine: boolean;
  rowStyle;
  defaultPedidoDetalle;
  rowSelection = 'multiple';
  tipodetalle = '';
  defaultColDefCxctmptrnresumen;
  defaultColDefCxctrnresumen;
  largoestcta: string;

  descglobaconfig: any;//CDPJ
  descGlobal: number = 0;//CDPJ
  displayDialogfrmcorreos:boolean;//CDPJ

  indicador: any;

  // Estadistica

  dataVentasAcum: any;
  detTopProductos: VenTopProductos[] = [];
  detFacturas: VenDetFacturas[] = [];
  defaultColTopProductos;
  defaultColDetFacturas;
  rowStyleTopProd;
  rowStyleDetFacts;
  disabledEstadisticas = true;
  totalDocXCobrar: any = '';
  totalDocXCobrarPorc: any = '';
  totalDocVencidos: any = '';
  totalDocVencidosPorc: any = '';
  totalChPosfechados: any = '';
  totalChPosfechadosPorc: any = '';
  totalDocumentos: any = '';
  totalPedPendientes: any = '';
  totalPedSatisfechos: any = '';
  fechaPrimeraFact: any = '';
  totalChProtestados: any = '';
  options = {
    legend: {
      display: false
    }
  };
  index = 0;
  bolestcta: boolean;
  bolsaldo: boolean;

  // Existencia por bodega

  displayDialogExistBod = false;
  txtArtCodigo = '';
  txtArtDescripcion = '';
  txtListaPrecio = '';
  txtObservacionRes = '';
  txtIva;
  txtBodegaRes = '';

  // Pedidos por valor
  intACTPEDVAL = 0;
  displayDialogDocumentos = false;

  //CDPJ CENTRO DE COSTOS DE LA BODEGA
  intCCPIPED = 0;
  ART_LIB_TITULO = '';
  ART_LIB_AUTOR = '';
  ART_LIB_EDITORIAL = '';
  ART_LIB_ORIGEN = '';
  ART_LIB_TAMANIO = '';
  ART_LIB_ENCUADERNACION = '';
  displayDialogInfoArt = false;
  ///// nuevo vehiculo
  ART_RAMV = '';
  ART_MODELOSRI = '';
  ART_MODELOAT = '';
  ART_MARCAV = '';
  ART_CLASE = '';
  ART_TIPOV = '';
  ART_CILINDRAJE = '';
  ART_ANIO = '';
  ART_TONELAJE = '';
  ART_COLOR = '';
  ART_PORIGEN = '';
  ART_MOTOR = '';
  ART_SERIEV = '';
  ART_NRUEDAS = '';
  ART_NEJES = '';
  ART_CAPACIDAD = '';
  ART_COMBUSTIBLE = '';
  ART_BANCO = '';
  ART_NBANCO = '';
  ART_DPAGO = '';
  ART_FFABRICA = '';
  ART_DISPOSITIVO = '';
  ART_MARVEH = '';
  ART_CLAVEH = '';
  ART_TIPVEH = '';
  ART_COMVEH = '';
  ART_DISVEH = '';
  ART_P = '';
  displayvehiculo = false;
  displayDialogDocumentosim = false;
  ped_refrencia = '';
  blobodBoolean: boolean;
  //GSRF
  displaycambioestado = false;
  codigogre = '';
  numerogre = '';
  fechagre = '';
  cligre = '';
  totgre = '';
  codigofac = '';
  fechafac = '';
  clifac = '';
  clinomfac = '';
  totfac = '';

  columnDefsCxctmptrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC'
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Debe', field: 'DEBE', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Haber', field: 'HABER', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: { textAlign: 'right' },
    },
  ];

  columnDefsCxctrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC'
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Fecha Vence', field: 'TRNCOBRO_FECHAVENCE', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Importe', field: 'TRNCOBRO_IMPORTE', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Pagado', field: 'PAGADO', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: { textAlign: 'right' },
    },
  ];

  columnDefsPedidoDetalle = [
    { // 0 
      headerName: 'Bodega', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', editable: (params) => {
        const existDesp = this.pruebaCambio(params.data);
        return existDesp;
      }, width: 100, pinned: 'left',
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Código', field: 'DETPED_CODIGO', cellEditor: 'cellPrueba', editable: (params) => {
        const existDesp = this.pruebaCambio(params.data);
        return existDesp;
      }, width: 100, pinned: 'left', 
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 25,
          opcionbuscar: true
        }
      }
    },
    { // 2
      headerName: 'Descripción', field: 'DETPED_DESCRIPCION', cellEditor: 'cellPrueba', editable: (params) => {
        const existDesp = this.pruebaCambio(params.data);
        return existDesp;
      }, width: 200, pinned: 'left',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 80
        }
      }
    },
    { // 3
      headerName: 'IVA', field: 'DETPED_TRIBIVA', cellEditor: 'cellPrueba', editable: (params) => {
        const existDesp = this.pruebaCambio(params.data);
        return existDesp;
      }, width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          regexp: /^[S]$|^[N]$|^[s]$|^[n]$/,
          tamanocelda: 1,
          tienecalculos: true
        }
      }
    },
    { // 4
      headerName: 'ICE', field: 'DETPED_TRIBICE', cellEditor: 'cellPrueba', editable: (params) => {
        const existDesp = this.pruebaCambio(params.data);
        return existDesp;
      }, width: 80,
      cellEditorParams:
      {
        values: {
          true: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          regexp: /^[S]$|^[N]$|^[s]$|^[n]$/,
          tamanocelda: 1,
          tienecalculos: true
        }
      }
    },
    { // 5
      headerName: 'Unidad', field: 'DETPED_UNIDAD', cellEditor: 'agSelectCellEditor', editable: (params) => {
        const existDesp = this.pruebaCambio(params.data);
        return existDesp;
      }, width: 100,
      cellEditorParams:
      {
        values: []
      }
    },
    { // 6
      headerName: 'Solicita', field: 'DETPED_CANTIDADPED', editable: false, width: 100
    },
    { // 7
      headerName: 'Cantidad', field: 'DETPED_CANTIDAD',/* editable: (params) => {
        const existDesp = this.pruebaCambio(params.data);
        return existDesp;
      }*/ cellEditor: 'cellPrueba', width: 100, //cellStyle: { background: 'transparent', textAlign: 'right' },
      cellStyle: (params) => {
        const color = this.colorearCantidad(params.data);
        return { background: color, textAlign: 'right' };
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 3,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    { // 8
      headerName: 'Despacho',
      field: 'DETPED_DESPACHO',
      cellEditor: 'cellPrueba',
      editable: (params) => {
        return false;
      },
      width: 100,
      cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          int: true
        }
      }
    },
    { // 9
      headerName: 'Facturado',
      field: 'DETPED_FACTURADO',
      cellEditor: 'cellPrueba',
      editable: false,
      width: 100,
      cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          int: true
        }
      }
    },
    { // 10
      headerName: 'Precio', field: 'DETPED_PRECIO', cellEditor: 'cellPrueba', editable: (params) => {
        const existDesp = this.pruebaCambio(params.data);
        return existDesp;
      }, width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    { // 11
      headerName: 'Dsct(%)', field: 'DETPED_DESCUENTO', cellEditor: 'cellPrueba', editable: (params) => {
        const existDesp = this.pruebaCambio(params.data);
        return existDesp;
      }, width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 3,
          tienecalculos: true,
          int: true
        }
      }
    },
    { // 12
      headerName: 'Total', field: 'DETPED_TOTAL', cellEditor: 'cellPrueba', width: 200, cellStyle: { textAlign: 'right' }, editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          int: true
        }
      }
    },
    // {
    //   headerName: 'Lst.', field: 'DETPED_LISTA', cellEditor: 'agSelectCellEditor', editable: true, width: 80,
    //   cellEditorParams:
    //     {
    //       values: []
    //     }
    // }
    { // 13
      headerName: 'Lst.', field: 'DETPED_LISTA', cellEditor: 'agSelectCellEditor', editable: (params) => {
        const existDesp = this.pruebaCambio(params.data);
        return existDesp;
      }, width: 80,
      onCellValueChanged: (params) => {
        this.cambioListPrec(params.data);
      },

      cellEditorParams:
      {
        values: []
      }
    },
    { // 14 CDPJ
      headerName: 'C.Costos', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 35
        }
      }
    },
    { // 15
      headerName: '%IVA', field: 'DETPED_PORIVA', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          int: true,
          opcionbuscar: true
        }
      }
    }
  ];

  columnDefsTopProductos = [
    {
      headerName: 'Producto', field: 'DETFAC_CODIGO', width: 115, editable: false
    },
    {
      headerName: 'Descripción', field: 'DETFAC_DESCRIPCION', width: 190, editable: false
    },
    {
      headerName: 'Cantidad', field: 'CANTIDAD_TOTAL', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Precio', field: 'DETFAC_PRECIO', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Total', field: 'TOTAL', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Stock', field: 'STOCK', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];

  columnDefsDetFacturas = [
    {
      headerName: 'Fecha', field: 'ENCFAC_FECHAEMISION', width: 90, editable: false
    },
    {
      headerName: 'Factura', field: 'ENCFAC_NUMERO', width: 140, editable: false
    },
    {
      headerName: 'Producto', field: 'DETFAC_CODIGO', width: 90, editable: false
    },
    {
      headerName: 'Descripción', field: 'DETFAC_DESCRIPCION', width: 100, editable: false
    },
    {
      headerName: 'Cantidad', field: 'DETFAC_CANTIDAD', width: 80, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Precio', field: 'DETFAC_PRECIO', width: 80, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Total', field: 'DETFAC_TOTAL', width: 80, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];

  //CDPJ
  columnDefsBasesIva = [
    {
      headerName: '%IVA', field: 'TARIIVA_DESCRIPCION', width: 115, editable: false
    },
    {
      headerName: 'Base', field: 'IVA_BASE', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Valor IVA', field: 'IVA_VALOR', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];
  //CDPJ
  auxreporte: number = 0;
  strNombreForma: string;
  displayDialogReportes: boolean;

  GRUPO: string = '';
  str_NumPed: string = '';//CDPJ
  strn_numcot:string='';//CDPJ
  globalNemonico: any;
  globalSerie: any;
  docref:string='';//CDPJ
  nemonico:string=''//CDPJ
  serie:string=''//CDPJ

  disablediva: boolean = true;
  estadoespecial: string = '';
  bolEstadoEsp: boolean = false;
  pedidobolean: boolean = false;

  constructor(public encpedService: VenencpedService, private busquedaSer: InvbusquedaService, public usuario: Usuario,
    private message: MessageService, private datePipe: DatePipe,
    private confirmationService: ConfirmationService, private utilitariosService: UtilitariosService,
    private permisosService: PermisosService, private auditoriagral: AuditoriagralService,
    private errorService: ErroresBaseDatosService, private cliService: VenmaeclienteService,
    private confIniciales: ConfInicialesService, private init: NuevoComponentService,
    private venEstadisticasService: VenestadisticaService, private busquedaVeh: InvmaearticuloService, private messageService: MessageService) {
    this.agTable();
    this.detallePedidoSeleccionado = {};
    this.detallePedidoSeleccionadoAux = {};
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador] = {};
    this.encabezadoPedidoSeleccionadoAux = {};
    this.encpedService.detpedidosarray[this.indicador] = [];
    this.opcionesprecio = [];
    this.listaprecios = [];
    // this.usuario.loadStorage();
    this.tipodetalle = 'A';
    this.cxctmptrnresumen = [];
    this.cxctrnresumen = [];

    this.detTopProductos = [];
    this.detFacturas = [];

  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.rowStyle = (params) => {
      if (params.data.DETPED_TIPODET === 'Y') {
        return { background: 'rgb(255, 204, 255)' };
      }
    };

    this.rowStyleTopProd = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if (params.data.DETFAC_CODIGO === '' || params.data.DETFAC_CODIGO === null) {
        return { background: 'rgb( 164, 255, 167)' };
      }
    };

    this.rowStyleDetFacts = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultPedidoDetalle = {
      editable: true,
      width: 100,
      objeto: 'vendetped',
      resizable: true
    };
    this.defaultColDefCxctmptrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctmptrnresumen',
      resizable: true
    };

    this.defaultColDefCxctrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctrnresumen',
      resizable: true
    };

    this.defaultColTopProductos = {
      editable: true,
      width: 100,
      objeto: 'topProductos',
      resizable: true
    };

    this.defaultColDetFacturas = {
      editable: true,
      width: 100,
      objeto: 'detalleFacturas',
      resizable: true
    };
    //CDPJ
    this.defaultColBasesIva = {
      editable: false,
      width: 100,
      objeto: 'basesiva',
    };
    //CDPJ
  }

  async ngOnInit() {

    this.encpedService.refrescarURL();

    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;

    this.confIniciales.getDecimales('frmVEN_ENCPED').subscribe((dec) => {
      if (dec !== null) {
        dec.map((decimales) => {
          if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
            this.decimalesCantidadPed = Number(decimales.NUMDEC_NUMDEC);
            this.decimalesDespacho = Number(decimales.NUMDEC_NUMDEC);
            this.encpedService.decimalesCantidadPed = this.decimalesCantidadPed;
          } else if (decimales.NUMDEC_CAMPO === 'PRECIO') {
            this.decimalesPrecioPed = Number(decimales.NUMDEC_NUMDEC);
            this.encpedService.decimalesPrecioPed = this.decimalesPrecioPed;
          } else if (decimales.NUMDEC_CAMPO === 'TOTAL') {
            this.decimalesTotalPed = Number(decimales.NUMDEC_NUMDEC);
            this.encpedService.decimalesTotalPed = this.decimalesTotalPed;
          } else if (decimales.NUMDEC_CAMPO === 'DESCUENTO') {
            this.decimalesDescPed = Number(decimales.NUMDEC_NUMDEC);
            this.encpedService.decimalesDescPed = this.decimalesDescPed;
          }
        });


        this.columnDefsPedidoDetalle[7].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.decimalesCantidadPed,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        };

        this.columnDefsPedidoDetalle[8].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.decimalesDespacho,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            int: true
          }
        };

        this.columnDefsPedidoDetalle[10].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.decimalesPrecioPed,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        };

        this.columnDefsPedidoDetalle[11].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.decimalesDescPed,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        };

        this.columnDefsPedidoDetalle[12].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.decimalesTotalPed,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            int: true
          }
        };
      }
    });
    this.auxiliar = 0;

    this.encpedService.hmpConfiguracion = await this.auditoriagral.obtenerConfiguracionProm();
    this.str_NumPed = this.encpedService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMPED').CFG_VALOR3;
    this.strn_numcot= this.encpedService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMCOT').CFG_VALOR3;//CDPJ

    this.confIniciales.getConfiguracion('PEDEXTNEG').subscribe((conf) => {
      this.configuracionExistencia = Number(conf[0].CFG_VALOR1);
    });
    this.confIniciales.getConfiguracion('ESTPEDESP').subscribe((conf) => {
      this.bolEstadoEsp = Number(conf[0].CFG_VALOR1) === 1;
    });

    this.permisosService.verificarPermisosObjConClave('frmVEN_ENCPED',
      'CmdDescuento', 0).subscribe((per) => {
        this.encpedService.permiObjClave = per[0].RESULT;
      });
       //CDPJ
    this.confIniciales.getConfiguracion('DESGLOFP').subscribe((conf) => {
      this.descglobaconfig = Number(conf[0].CFG_VALOR1) === 1;
      
    });
    //CDPJ
    this.numdecsist = this.confIniciales.getNumDecSist();
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = true;
    this.btnRegresar = true;
    this.btnNuevoGrid = true;
    this.btnGuardarGrid = true;
    this.btnCancelarGrid = true;
    this.btnBorrarGrid = true;
    this.btnImagenGrid = false;
    this.btnVerificarExis = false;
    this.botonPrimero = false;
    this.botonAnterior = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
    this.btnBuscar = false;
    this.btnAnular = true;
    this.cotizacionBoolean = false;
    this.fechaBoolean = false;
    this.alineacion = 'right';
    this.valorInvalidoP = false;
    this.valorInvalidoV = false;
    this.blobodBoolean = false;//GSRF
    this.displayDialogfrmcorreos=false;//CDPJ
    //nuevo
    this.largoestcta = '420';
    this.valorDscValor = 0;
    this.valorDscPorcentaje = 0;
    this.displayDialogBusquedaFast = false;
    this.displayDialogDescuento = false;
    this.displayAcciones = false;
    this.dscClienteActual = 0;
    this.maxDscto = 0;
    this.claveAuth = '';
    this.claveDialog = false;
    this.ivaDescBoolean = false;
    this.estadoValor = '';
    this.razonValor = '';
    this.consulta = '';
    this.estadoPedido = [];
    this.estadoPedidoActual = undefined;
    this.estadopedDialog = false;
    this.imagenProducto = '';
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador] = { ENCPED_NUMERO: '' };
    this.permisosService.getListaPermisos('VEN', '6', '78').subscribe((permiso) => {
      this.permisoPedido = permiso[0];
    });
    this.comboOpciones = [{ label: 'A', value: 'A' },
    { label: 'S', value: 'S' }, { label: '*', value: '*' }];
    this.tipodetalle = 'A';
    //this.opcionIva = [{ label: '12', value: '12' }, { label: '8', value: '8' }];
    //this.encpedService.dblPorcIvaPed[this.indicador] = this.opcionIva[0].value;
    this.opcionIvaDesc = [{ label: 'Neto + IVA', value: '1' },
    { label: 'Incluido IVA', value: '2' }, { label: 'Exento', value: '0' }];
    this.opcionEstado = [{ label: '', value: '' }, { label: 'CERRADO STANDBY', value: 'CERRADO STANDBY' }, {
      label: 'CERRADO PERDIDO',
      value: 'CERRADO PERDIDO'
    }];
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAEMISION = new Date();
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAENTREGA = new Date();
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAVALIDEZ = new Date();
    this.horaSistema = new Date();
    this.displayDialogBus = false;
    this.arregloBus = [];
    this.opcion = '';
    this.detallePedidoSeleccionado = {};
    this.dscSeleccionado = 'dscPorcentaje';
    this.booleanPorcentaje = false;
    this.booleanValor = true;
    this.encpedService.getListaPrecios().subscribe((res) => {
      this.listaprecios = res;
      this.listaprecios.map((precio) => {
        let nuevoItem: any;
        nuevoItem = { label: precio.ARTPRE_CODIGO, value: precio.ARTPRE_CODIGO };
        this.opcionesprecio.push(nuevoItem);
      });
    });
    this.largo = '-5800';
    this.indice = 0;
    this.encpedService.getEncPedidos().subscribe((res) => {
      this.encabezadosPedidos = res;
    });
    this.encpedService.detpedidosarray[this.indicador] = [];
    this.cxctmptrnresumen = [];
    this.cxctrnresumen = [];
    this.detTopProductos = [];
    this.detFacturas = [];
    this.bolestcta = false;
    this.bolsaldo = false;

    await this.obtenerConfigPedVal();
    this.iva = await this.confIniciales.obtenerImpuesto('I', 'A');
    await this.confIniciales.obtenerSrifeTariIva()
    await this.llenarcombo()
  }
//CDPJ
ngOnDestroy(): void {
  this.confIniciales.instanciaPedidos--;
}
async llenarcombo(){
  // this.cmbtipoIva = [
  // { codigo: '12', name: '12' },
  // { codigo: '8', name: '8' }
  // ];
  //CDPJ
  this.opcionIva=[];
  for(let i=0;  this.confIniciales.srifetariiva[i];i++){
    let item=this.confIniciales.srifetariiva[i]
    if(item.TARIIVA_CODIGO !=='6' &&  item.TARIIVA_CODIGO !=='7'  && item.TARIIVA_CODIGO !== '-1'){
      this.opcionIva.push({codigo:item.TARIIVA_CODIGO,name:item.TARIIVA_PORCENTAJE.toString()})
    } 
  }
  console.log(this.opcionIva)
  for(const data of this.opcionIva){
    if(Number(data.name) === Number(this.iva)){
      this.selectcombtipoIva={codigo:data.codigo,name:data.name}
      this.encpedService.porcetariiva=Number(this.selectcombtipoIva.name)
      this.encpedService.codtariiva=this.selectcombtipoIva.codigo
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_PORCEIVA=this.encpedService.porcetariiva
    }
  }
  
  //CDPJ
}
cambiavalorIva(select){
  this.selectcombtipoIva.codigo=select.value.codigo
  this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_PORCEIVA=Number(select.value.name);
  //console.log(this.encpedService.dblPorcIvaPed)
  this.encpedService.porcetariiva=Number(select.value.name)
  this.encpedService.codtariiva=select.value.codigo
}
//CDPJ
  async manejarSenales(valor) {
    if (valor !== 'Nuevo' && valor !== 'Cancelar'
      && valor !== 'Primero' && valor !== 'Anterior' && valor !== 'Siguiente' && valor !== 'Ultimo'
      && valor !== 'Buscar' && valor !== 'Imprimir' && valor !== 'AsientoVer' && valor !== 'Salir') {
      console.log(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAEMISION);
      let dia = new Date().getDate();
      let mes = new Date().getMonth();
      let anio = new Date().getFullYear();
      // Verifica el periodo contable
      try {
        dia = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAEMISION.getDate();
        mes = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAEMISION.getMonth();
        anio = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAEMISION.getFullYear();
      } catch (e) {
        const tmpfecha = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAEMISION.substr(0, 10);
        const splfec = tmpfecha.split('/');
        const fecha = new Date(splfec[2], splfec[1] - 1, splfec[0]);
        dia = fecha.getDate();
        mes = fecha.getMonth();
        anio = fecha.getFullYear();
      }

      if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
        this.message.add({
          key: 'pedido',
          severity: 'info',
          summary: 'Información',
          detail: 'Error, fecha debe de estar dentro del período contable'
        });
        return;
      }
    }

    this.closeSidebar();
    if (this.index === 3) {
      return;
    }
    // const elemento = document.getElementsByClassName('layout-config');
    // for (let i = 0; i < elemento.length; i++) {
    //   elemento[i].className = 'layout-config';
    // }
    if (valor === 'Primero') {
      this.encpedService.getEncPedidos().subscribe((res) => {
        this.encabezadosPedidos = res;
      });
      this.indice = 0;
      if (this.botonUltimo === true) {
        this.botonUltimo = !this.botonUltimo;
      }
      if (this.botonSiguiente === true) {
        this.botonSiguiente = !this.botonSiguiente;
      }
      this.botonPrimero = true;
      this.botonAnterior = true;
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador] = this.encabezadosPedidos[this.indice];
      this.obtenerDatos();
    } else if (valor === 'Siguiente') {
      this.encpedService.getEncPedidos().subscribe((res) => {
        this.encabezadosPedidos = res;
      });
      this.indice++;
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador] = this.encabezadosPedidos[this.indice];
      if (this.botonPrimero === true && this.botonAnterior === true) {
        this.botonPrimero = false;
        this.botonAnterior = false;
      }
      if (this.indice >= this.encabezadosPedidos.length - 1) {
        this.botonUltimo = true;
        this.botonSiguiente = true;
        if (this.indice === this.encabezadosPedidos.length - 1) {
          this.obtenerDatos();
        } else {
          return;
        }
      } else {
        this.obtenerDatos();
      }
    } else if (valor === 'Anterior') {
      this.encpedService.getEncPedidos().subscribe((res) => {
        this.encabezadosPedidos = res;
      });
      this.indice--;
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador] = this.encabezadosPedidos[this.indice];
      if (this.botonUltimo === true && this.botonSiguiente === true) {
        this.botonUltimo = false;
        this.botonSiguiente = false;
      }
      if (this.indice <= 0) {
        this.botonPrimero = true;
        this.botonAnterior = true;
        if (this.indice === 0) {
          this.obtenerDatos();
        } else {
          return;
        }
      } else {
        this.obtenerDatos();
      }
    } else if (valor === 'Ultimo') {
      this.encpedService.getEncPedidos().subscribe((res) => {
        this.encabezadosPedidos = res;
      });
      this.indice = this.encabezadosPedidos.length - 1;
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador] = this.encabezadosPedidos[this.indice];
      if (this.botonPrimero === true) {
        this.botonPrimero = !this.botonPrimero;
      }
      if (this.botonAnterior === true) {
        this.botonAnterior = !this.botonAnterior;
      }
      this.botonSiguiente = true;
      this.botonUltimo = true;
      this.obtenerDatos();
    } else if (valor === 'Nuevo') {
      //GSRF
      let BLOQ_BOD = this.encpedService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PEDBLOBOD').CFG_VALOR1;
      console.log('-->',BLOQ_BOD)
      if(BLOQ_BOD === 1){
        console.log('Aqui')
        this.blobodBoolean=true;
      }
      //GSRF
      if (this.permisoPedido.PERINSERCION === 1) {
        this.nuevoPedido('N');
        this.disabledEstadisticas = true;
        this.bolestcta = false;
        this.bolsaldo = false;
      } else if (this.permisoPedido.PERINSERCION === 0) {
        this.message.add({
          key: 'pedido',
          severity: 'error',
          summary: 'Inserción de Pedido',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    } else if (valor === 'Buscar') {
      this.buscarPedidos();
    } else if (valor === 'Guardar') {
      //GSRF
      let condiciog = Number(this.encpedService.getConfiguraciones('GUANUMPED'));
      console.log(condiciog);
      if (condiciog === 1) {
        this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_REFERENCIA = this.ped_refrencia;
      }
      //GSRF
      const NOPEDCERO = Number(this.encpedService.getConfiguraciones('NOPEDCERO'));
      if (this.permisoPedido.PERACTUALIZACION === 1) {
        const rs = await this.encpedService.erBodCodigo1(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].BOD_CODIGO);
         //CDPJ
         let encpedestado='';
         const data = await this.encpedService.obtenerEncpedEstado(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO);
         const data1 = await this.encpedService.EncontrarCliente(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO).catch(e=>{
          this.spin=false//CDPJ
        });
         if(data !== null && data !== undefined){
           for(const rs1 of data){
             encpedestado=rs1.ENCPED_ESTADO
           }
         }
       //CDPJ
       console.log('encpedestado',encpedestado)
       if(encpedestado === 'O'){
         this.message.add({
           key: 'pedido',
           severity: 'error',
           summary: 'Inserción de Pedido',
           detail: 'El Pedido ya se encuentra en estado \'OK\''
         });
         this.spin=false;
         this.cancelarEdicionPedido();
         return;
       }if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO === '' ||
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_REFERENCIA === '' ||
          this.encpedService.detpedidosarray[this.indicador].length === 0) {
          this.message.add({
            key: 'pedido',
            severity: 'error',
            summary: 'Inserción de Pedido',
            detail: 'No existe un cliente, comprobante o el detalle está vacio'
          });
          this.spin=false;
        } else if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAEMISION === '' ||
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAEMISION === null ||
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAVALIDEZ
          === '' || this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAVALIDEZ === null) {
          this.message.add({
            key: 'pedido',
            severity: 'error',
            summary: 'Inserción de Pedido',
            detail: 'Ingrese fechas validas para guardar el pedido'
          });
          this.spin=false;
        }else if(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].BOD_CODIGO === null ||
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].BOD_CODIGO === ''
          || this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].BOD_CODIGO === undefined){
            this.message.add({
              key: 'pedido',
              severity: 'error',
              summary: 'Inserción de Pedido',
              detail: 'Ingrese una bodega'
            });
            this.spin=false;
            return;
        }else if (rs === null || rs === undefined || rs === ''){
          this.message.add({
            key: 'pedido',
            severity: 'error',
            summary: 'Inserción de Pedido',
            detail: 'Error, Código de la bodega '+this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].BOD_CODIGO+ ' es incorrecto'
          });
          this.spin=false;
        return;
        }else if (data1 === null || data1 === undefined || data1 === '') {
          this.messageService.add({
            key: 'pedido',
            severity: 'error',
            summary: 'Inserción de Pedido',
            detail: 'No existe el cliente '+ this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO
          });
          this.spin = false;
        return;
        //CDPJ
    } else {
          if (this.encpedService.detpedidosarray[this.indicador].length !== 0) {
            let errores = 0;
            await Promise.all(this.encpedService.detpedidosarray[this.indicador].map( async (ped) => {
              if (ped.DETPED_TIPODET === '*' || ped.DETPED_TIPODET === 'Y') {
                errores += 0;
              } else {
                try {
                  const rsdata: any = await this.encpedService.encontrarArtCntaySer(ped.DETPED_CODIGO, ped.DETPED_TIPODET);
                const rs = await this.encpedService.erBodCodigo1(ped.BOD_CODIGO);
                let strCodigo = 0;
                if (rsdata !== null) {
                  for (const rs of rsdata) {
                    strCodigo = rs.NUMERO;
                  }
                }
                if (ped.DETPED_CODIGO === '' && ped.DETPED_TIPODET !== '*') {
                  //this.encpedService.detpedidosarray[this.indicador] = this.encpedService.detpedidosarray[this.indicador].filter((val, j) => j !==
                    //this.encpedService.detpedidosarray[this.indicador].indexOf(ped));
                  //this.aggrid.refreshaggrid(this.encpedService.detpedidosarray[this.indicador], this.defaultPedidoDetalle.objeto);
                  this.message.add({
                    key: 'pedido',
                    severity: 'error',
                    summary: 'Inserción de Pedido',
                    detail: 'El código en el detalle está vacío'
                  });
                  this.spin=false;
                  errores++;
                } else  if (ped.DETPED_CODIGO === '') {
                  this.encpedService.detpedidosarray[this.indicador] = this.encpedService.detpedidosarray[this.indicador].filter((val, j) => j !==
                    this.encpedService.detpedidosarray[this.indicador].indexOf(ped));
                  this.aggrid.refreshaggrid(this.encpedService.detpedidosarray[this.indicador], this.defaultPedidoDetalle.objeto);
                } else if (ped.BOD_CODIGO === '') {
                  this.message.add({
                    key: 'pedido',
                    severity: 'error',
                    summary: 'Inserción de Pedido',
                    detail: 'Ingrese una bodega al detalle del pedido'
                  });
                  this.spin=false;
                  errores++;
                } else if (Number(ped.DETPED_CANTIDAD) <= 0) {
                  this.message.add({
                    key: 'pedido',
                    severity: 'error',
                    summary: 'Inserción de Pedido',
                    detail: 'Ingrese una cantidad valida al detalle del pedido'
                  });
                  this.spin=false;
                  errores++;
                } else if (Number(ped.DETPED_PRECIO) === 0 && NOPEDCERO === 1) {
                  this.message.add({
                    key: 'pedido',
                    severity: 'error',
                    summary: 'Inserción de Pedido',
                    detail: 'Ingrese un precio valido al detalle del pedido'
                  });
                  this.spin=false;
                  errores++;
                }else if(ped.DETPED_TIPODET === 'A' && strCodigo === 0){//CDPJ
                  this.message.add({
                    key: 'pedido',
                    severity: 'error',
                    summary: 'Inserción de Pedido',
                    detail: 'El artículo '+ ped.DETPED_CODIGO +' no existe'
                  });
                  this.spin=false;
                  errores++;
                }else if(ped.DETPED_TIPODET === 'S' && strCodigo === 0){
                  this.message.add({
                    key: 'pedido',
                    severity: 'error',
                    summary: 'Inserción de Pedido',
                    detail: 'El servicio '+ ped.DETPED_CODIGO +' no existe'
                  });
                  this.spin=false;
                  errores++;
                }else if(ped.BOD_CODIGO !== null ||
                  ped.BOD_CODIGO !== ''
                  || ped.BOD_CODIGO !== undefined){
                    if (rs === null || rs === undefined || rs === ''){
                      this.message.add({
                        key: 'pedido',
                        severity: 'error',
                        summary: 'Inserción de Pedido',
                        detail: 'Error, Código de la bodega '+ped.BOD_CODIGO+ ' es incorrecto'
                      });
                      this.spin=false;
                      errores++;
                    }
                } if(ped.DETPED_PORIVA === null || ped.DETPED_PORIVA === undefined || ped.DETPED_PORIVA === ''){
                  this.message.add({
                    key: 'pedido',
                    severity: 'error',
                    summary: 'Error en IVA',
                    detail: 'El campo %IVA no puede estar vacio, a menos que sea un comentario'
                  });
                  this.spin=false;
                  errores++;             
              } else if(ped.TARIIVA_CODIGO === null || ped.TARIIVA_CODIGO === undefined || ped.TARIIVA_CODIGO === ''){       
                this.message.add({
                  key: 'pedido',
                  severity: 'error',
                  summary: 'Error en IVA',
                  detail: 'Falta seleccionar el tipo de porcentaje del código '+ped.DETPED_CODIGO
                });
                this.spin=false;
                  errores++;               
              }else if(ped.DETPED_TRIBIVA === 'S' && ped.DETPED_PORIVA !== null && ped.DETPED_PORIVA !== undefined && ped.DETPED_PORIVA !== ''){
                let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === ped.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) !== 0 && Number(ped.DETPED_PORIVA) !== 0
                && Number(element.TARIIVA_PORCENTAJE) ===  Number(ped.DETPED_PORIVA))
                if(data === undefined || data === null){
                  this.message.add({
                    key: 'pedido',
                    severity: 'error',
                    summary: 'Error en IVA',
                    detail: 'El %IVA con el porcentaje '+ped.DETPED_PORIVA+' no es parte de los porcentajes que puede seleccionar cuando se tributa IVA.'
                  });
                  this.spin=false;
                  errores++;             
                }
              }else if(ped.DETPED_TRIBIVA === 'N'
              && ped.DETPED_PORIVA !== null && ped.DETPED_PORIVA !== undefined && ped.DETPED_PORIVA !== ''){
                let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === ped.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) === 0 && Number(ped.DETPED_PORIVA) === 0
                && Number(element.TARIIVA_PORCENTAJE) ===  Number(ped.DETPED_PORIVA))
                console.log(data)
                console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === ped.TARIIVA_CODIGO))
                if(data === undefined || data === null){
                  this.message.add({
                    key: 'pedido',
                    severity: 'error',
                    summary: 'Error en IVA',
                    detail: 'El %IVA con el porcentaje '+ped.DETPED_PORIVA+' no es parte de los porcentajes que puede seleccionar cuando no se tributan IVA.'
                  });
                  this.spin=false;
                  errores++;
                }
              }
                //CDPJ
                } catch (error) {
                  this.spin=false;
                }
                //CDPJ

                //CDPJ
              }
            }));
            if (errores === 0) {
              this.guardarPedido();
            }
          }
        }
      } else if (this.permisoPedido.PERACTUALIZACION === 0) {
        this.message.add({
          key: 'pedido',
          severity: 'error',
          summary: 'Actualización de Pedido',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }
    } else if (valor === 'Borrar') {
      if (this.permisoPedido.PERELIMACION === 1) {
        this.confirmarPedido();
      } else if (this.permisoPedido.PERELIMACION === 0) {
        this.message.add({
          key: 'pedido',
          severity: 'error',
          summary: 'Eliminación de Pedido',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
      }
    } else if (valor === 'Cancelar') {
      this.cancelarEdicionPedido();
    } else if (valor === 'Descuento') {
      if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador] !== undefined) {
        if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO === 'A'
          || this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO === 'ANULADO' ||
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO === 'O'
          || this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO === 'OK') {
          this.displayDialogDescuento = false;
        } else {
         // this.displayDialogDescuento = true;
         //CDPJ
         const descGlobal = await this.encpedService.desglobalCliente(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO);
         if (descGlobal !== null && descGlobal !== undefined) {
           for (const data of descGlobal) {
             this.descGlobal = data.DESCUENTO

           }
         }
         if (Number(this.init.quitarComas(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_TOTAL)) > 0) {
           console.log('des1',this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORDES)
           console.log('des',this.descglobaconfig)
           if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORDES === undefined
             || this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORDES === null
             || this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORDES === ''
             || Number(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORDES) === 0) {
               console.log(this.descGlobal);
               if(this.descglobaconfig === true){
                 this.valorDscPorcentaje = this.descGlobal;
                 this.valorDscValor = 0;
               }else{
                 this.valorDscPorcentaje = 0;
                 this.valorDscValor = 0;
               }        
           } else {
             this.valorDscValor = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORDES;
             this.valorDscPorcentaje = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_PORCEDES;
             const strdscporce = Number(this.init.quitarComas(this.valorDscPorcentaje)).toFixed(this.confIniciales.getNumDecSist());
             this.valorDscPorcentaje = Number(strdscporce);
           }
     
           if (this.valorDscPorcentaje > 0) {
           } else {
             if (Number(this.dscClienteActual) > 0) {
               this.valorDscPorcentaje = Number(this.dscClienteActual);
             }
           }
     
           this.displayDialogDescuento = true;
         }
         
         //CDPJ
        }

      }
    } else if (valor === 'ClaseNumeracion') {
      this.confirmarClaseNumeracion();
    } else if (valor === 'Anular') {
      this.confirmarAnular();
    }
    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
    if (valor === 'Almacenardoc') {
      if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO !== '') {
        const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.message.add({
            key: 'pedido',
            severity: 'info',
            summary: 'Información',
            detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
          });
        }

      }
    }
    //CDPJ
    if (valor === 'FormularioCorreos'){
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogfrmcorreos=true
    }
    //CDPJ
  }

  cerrarReportes() {
    this.displayDialogReportes = false;
  }

  nuevoPedido(parametro) {
    if (parametro === 'N') { // N para nuevo
      this.btnNuevo = true;
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      // document.getElementById('divPrincipal').style.opacity = '1.0';
      // document.getElementById('divPrincipal2').style.pointerEvents = 'all';
      this.aggrid.habilitarGrid();
      // document.getElementById('divPrincipal2').style.opacity = '1.0';
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.btnRegresar = false;
      this.botonUltimo = true;
      this.botonAnterior = true;
      this.botonSiguiente = true;
      this.botonPrimero = true;
      this.ivaDescBoolean = false;
      this.btnAnular = false;
      this.btnNuevoGrid = false;
      this.btnBorrarGrid = false;
      this.btnImagenGrid = false;
      this.btnCancelarGrid = false;

      this.obtenerNumPedido();

      document.getElementById('comprobante').focus();
      this.indice = -1;
    } else if (parametro === 'C') { // C para cancelar
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = true;
      this.botonUltimo = false;
      this.botonAnterior = false;
      this.botonPrimero = false;
      this.botonSiguiente = false;
      this.ivaDescBoolean = true;
    }
    this.llenarcombo();
    this.busquedaSer.encontrarRegistro46(this.usuario.identificacion).subscribe((res) => {
      const bodega = res[0].BOD_CODIGO === null ? '' : res[0].BOD_CODIGO;
      const nuevoPedido = {
        ENCPED_NUMERO: '', COM_CODIGO: '01', CLI_CODIGO: '', CLI_NOMBRE: '', VEN_CODIGO: '',
        ENCPED_FECHAEMISION: new Date(), ENCPED_FECHAENTREGA: new Date(), ENCPED_IVA: '1', ENCPED_ESTADO: 'PENDIENTE',
        ENCPED_ORDEN: '', ENCPED_LISTA: 'A', ENCPED_OBSERVACION: '', ENCPED_TOTAL: 0, ENCPED_TOTALNETO: 0,
        ENCPED_VALORDES: 0, ENCPED_PORCEDES: 0, ENCPED_VALORIVA: 0, ENCPED_PORCEIVA: Number(this.selectcombtipoIva.name), ENCPED_VALORICE:
          0, ENCPED_PORCEICE: '', ENCPED_BASEIVA: 0, ENCPED_BASEICE: 0, ENCPED_BASECERO: 0, ENCPED_GRUPO:
          this.GRUPO, ENCPED_REFERENCIA: '',//GSRF
        ENCPED_FECHAVALIDEZ: new Date(), BOD_CODIGO: bodega, ENCPED_TIPO: '', ENCPED_TIPODSCTO:
          '', ENCPED_REFCLI: '', CLI_CODIGO_EMPRESARIA: '', ENCPED_JEFEZONA: '', ENCPED_CAMPANIA: '', USUIDENTIFICACION:
          this.usuario.identificacion, ENCPED_FORMAPAGO: '', ENCPED_TERMCOND: ''
      };
      //GSRF
      let condicio5 = Number(this.encpedService.getConfiguraciones('PEDVAL5'));
      let condiciod = Number(this.encpedService.getConfiguraciones2('DIAVALPED'));
      console.log(condicio5);
      console.log(condiciod);
      if (condicio5 === 1) {
        nuevoPedido.ENCPED_FECHAVALIDEZ.setDate(nuevoPedido.ENCPED_FECHAVALIDEZ.getDate() + 1856);
      } else {
        nuevoPedido.ENCPED_FECHAVALIDEZ.setDate(nuevoPedido.ENCPED_FECHAVALIDEZ.getDate() + condiciod);
      }
      let condiciog = Number(this.encpedService.getConfiguraciones('GUANUMPED'));
      console.log(condiciog);
      if (condiciog === 0) {
        nuevoPedido.ENCPED_REFERENCIA = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_REFERENCIA;
      } else {
        this.pedidobolean = true;
      }

      //GSRF
      this.nombreCliente = '';
      this.nombreVendedor = '';
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador] = nuevoPedido;
      this.encpedService.dblPorcDescGlbPedarray[this.indicador] = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_PORCEDES;
      this.encpedService.dblValorDescGlbPedarray[this.indicador] = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORDES;
      this.fechaBoolean = false;
      this.encpedService.detpedidosarray[this.indicador] = [];
    });
  }

  async guardarPedido() {
    this.spin = true;
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_TOTALNETO = this.init.quitarComas(
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_TOTALNETO);
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORIVA = this.init.quitarComas(
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORIVA);
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORICE = this.init.quitarComas(
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORICE);
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_BASEICE = this.init.quitarComas(
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_BASEICE);
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_BASECERO = this.init.quitarComas(
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_BASECERO);
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_BASEIVA = this.init.quitarComas(
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_BASEIVA);
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_TOTAL = this.init.quitarComas(
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_TOTAL);
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_PORCEDES = this.init.quitarComas(
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_PORCEDES);
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORDES = this.init.quitarComas(
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORDES);

    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_TIPODSCTO = Number(
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORDES) != 0 ? (this.dscSeleccionado === 'dscValor' ? 'V' : 'P') : '';


    if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAEMISION.toString().includes('-')) {
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAEMISION = this.datePipe.transform(
        this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAEMISION,
        'dd/MM/yyyy');
    } else if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAEMISION.toString().includes('/')) {
    }
    if (this.fechaBoolean === true) {
      if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAENTREGA.toString().includes('-')) {
        this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAENTREGA = this.datePipe.transform(
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAENTREGA, 'dd/MM/yyyy');
      } else if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAENTREGA.toString().includes('/')) {
      }
    } else if (this.fechaBoolean === false) {
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAENTREGA = '';
    }
    if (this.cotizacionBoolean === true) {
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_TIPO = 'C';
    }
        //CDPJ
        this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_DOCREF=this.docref === null || this.docref === undefined?'':this.docref
        this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NEMONICO=this.nemonico === null || this.nemonico === undefined?'':this.nemonico
        this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_SERIE=this.serie === null || this.serie === undefined?'':this.serie
        this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_GRUPO= this.GRUPO === null || this.GRUPO === undefined?'':this.GRUPO;
        //CDPJ
    if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAVALIDEZ.toString().includes('-')) {
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAVALIDEZ = this.datePipe.transform(this.encpedService
        .encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAVALIDEZ
        , 'dd/MM/yyyy');
    } else if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAVALIDEZ.toString().includes('/')) {
    }
    if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO === 'PENDIENTE') {
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO = 'P';
    }
    //this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_PORCEIVA = this.selectcombtipoIva.codigo;

    try {
      const res = await this.encpedService.guardarENCPEDProm(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador]);
      if (res !== null) {
        if (this.indice !== -1) {
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO = res[0].ENCPED_numero;
          this.encpedService.insertarUsuidentificacion(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador]).subscribe(() => {
          });
          for (let i = 0; i < this.encpedService.detpedidosarray[this.indicador].length; i++) {
            this.encpedService.detpedidosarray[this.indicador][i].ENCPED_NUMERO = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO;
            //CDPJ
            if(this.encpedService.detpedidosarray[this.indicador][i].DETPED_TIPODET === '*'){
              this.encpedService.detpedidosarray[this.indicador][i].DETPED_PORIVA=0;
              this.encpedService.detpedidosarray[this.indicador][i].TARIIVA_CODIGO='-1';
            }
            this.encpedService.detpedidosarray[this.indicador][i].DETPED_PORIVA=Number(this.encpedService.detpedidosarray[this.indicador][i].DETPED_PORIVA);
            //CDPJ
            await this.encpedService.insertarDetalleProm(this.encpedService.detpedidosarray[this.indicador][i]).catch(e=>{
              this.spin=false;
            });
            if (this.encpedService.detpedidosarray[this.indicador][i].DETPED_LINEA === 0) {
              this.encpedService.detpedidosarray[this.indicador][i].DETPED_LINEA = this.encpedService.detpedidosarray[this.indicador][i].LINEA;
              this.auditoriagral.registrarAuditoria('VEN_DETPED', String(this.encpedService.detpedidosarray[this.indicador][i].ENCPED_NUMERO+'/'+ this.encpedService.detpedidosarray[this.indicador][i].DETPED_CODIGO), 'I',
                '', '01', '', '', '', '').subscribe(() => {
                });
                this.auditoriagral.registrarAuditoria('VEN_DETPED',  String(this.encpedService.detpedidosarray[this.indicador][i].ENCPED_NUMERO+'/'+ this.encpedService.detpedidosarray[this.indicador][i].DETPED_CODIGO)+ '/' 
                +'PorIva:'+ this.encpedService.detpedidosarray[this.indicador][i].DETPED_PORIVA + '/' + 'CodTarIva:'+ this.encpedService.detpedidosarray[this.indicador][i].TARIIVA_CODIGO, 'I', 
                '', '01', '', '', '', '').subscribe(() => {
                });
            } else if (this.encpedService.detpedidosarray[this.indicador][i].DETPED_LINEA !== 0) {
              this.auditoriagral.registrarAuditoria('VEN_DETPED', String(this.encpedService.detpedidosarray[this.indicador][i].ENCPED_NUMERO+'/'+ this.encpedService.detpedidosarray[this.indicador][i].DETPED_CODIGO), 'A',
                '', '01', '', '', '', '').subscribe(() => {
                });
                this.auditoriagral.registrarAuditoria('VEN_DETPED',  String(this.encpedService.detpedidosarray[this.indicador][i].ENCPED_NUMERO+'/'+ this.encpedService.detpedidosarray[this.indicador][i].DETPED_CODIGO)+ '/' 
                +'PorIva:'+ this.encpedService.detpedidosarray[this.indicador][i].DETPED_PORIVA + '/' + 'CodTarIva:'+ this.encpedService.detpedidosarray[this.indicador][i].TARIIVA_CODIGO, 'A', 
                '', '01', '', '', '', '').subscribe(() => {
                });
            }
          }

        await this.encpedService.verificarActualizarEstPed(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO);
          this.message.add({
            key: 'pedido',
            severity: 'success',
            summary: 'Actualización de Pedido',
            detail: 'El pedido se actualizó correctamente'
          });
          this.spin=false;
          this.btnGuardar = true;
          this.btnNuevo = false;
          this.botonPrimero = false;
          this.botonAnterior = false;
          this.botonSiguiente = false;
          this.botonUltimo = false;
          this.auditoriagral.registrarAuditoria('VEN_ENCPED', String(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO)
            , 'A', '', '01', '', '', '', '').subscribe(() => {
            });
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO = 'PENDIENTE';

          this.encpedService.erGetClientCodigo(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO).subscribe((tipoCli) => {
            if (String(tipoCli[0].CLI_TIPOIDE) === '6') {
              this.disabledEstadisticas = true;
            } else {
              this.disabledEstadisticas = false;
            }
          });

        } else if (this.indice === -1) {
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO = res[0].v_ENCPED_numero;
          this.encpedService.insertarUsuidentificacion(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador]).subscribe(() => {
          });

          for (let i = 0; i < this.encpedService.detpedidosarray[this.indicador].length; i++) {
            this.encpedService.detpedidosarray[this.indicador][i].ENCPED_NUMERO = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO;
            //CDPJ
            if(this.encpedService.detpedidosarray[this.indicador][i].DETPED_TIPODET === '*'){
              this.encpedService.detpedidosarray[this.indicador][i].DETPED_PORIVA=0;
              this.encpedService.detpedidosarray[this.indicador][i].TARIIVA_CODIGO='-1';
            }
            this.encpedService.detpedidosarray[this.indicador][i].DETPED_PORIVA=Number(this.encpedService.detpedidosarray[this.indicador][i].DETPED_PORIVA);
            //CDPJ
            await this.encpedService.insertarDetalleProm(this.encpedService.detpedidosarray[this.indicador][i]).catch(e=>{
              this.spin=false;
            });
            if (this.encpedService.detpedidosarray[this.indicador][i].DETPED_LINEA === 0) {
              this.encpedService.detpedidosarray[this.indicador][i].DETPED_LINEA = this.encpedService.detpedidosarray[this.indicador][i].LINEA;
              this.auditoriagral.registrarAuditoria('VEN_DETPED', String(this.encpedService.detpedidosarray[this.indicador][i].ENCPED_NUMERO+'/'+ this.encpedService.detpedidosarray[this.indicador][i].DETPED_CODIGO), 'I',
                '', '01', '', '', '', '').subscribe(() => {
                });
                this.auditoriagral.registrarAuditoria('VEN_DETPED',  String(this.encpedService.detpedidosarray[this.indicador][i].ENCPED_NUMERO+'/'+ this.encpedService.detpedidosarray[this.indicador][i].DETPED_CODIGO)+ '/' 
                +'PorIva:'+ this.encpedService.detpedidosarray[this.indicador][i].DETPED_PORIVA + '/' + 'CodTarIva:'+ this.encpedService.detpedidosarray[this.indicador][i].TARIIVA_CODIGO, 'I', 
                '', '01', '', '', '', '').subscribe(() => {
                });
            } else if (this.encpedService.detpedidosarray[this.indicador][i].DETPED_LINEA !== 0) {
              this.auditoriagral.registrarAuditoria('VEN_DETPED', String(this.encpedService.detpedidosarray[this.indicador][i].ENCPED_NUMERO+'/'+ this.encpedService.detpedidosarray[this.indicador][i].DETPED_CODIGO), 'A',
                '', '01', '', '', '', '').subscribe(() => {
                });
                this.auditoriagral.registrarAuditoria('VEN_DETPED',  String(this.encpedService.detpedidosarray[this.indicador][i].ENCPED_NUMERO+'/'+ this.encpedService.detpedidosarray[this.indicador][i].DETPED_CODIGO)+ '/' 
                +'PorIva:'+ this.encpedService.detpedidosarray[this.indicador][i].DETPED_PORIVA + '/' + 'CodTarIva:'+ this.encpedService.detpedidosarray[this.indicador][i].TARIIVA_CODIGO, 'A', 
                '', '01', '', '', '', '').subscribe(() => {
                });
            }
          }
          await this.encpedService.verificarActualizarEstPed(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO).catch(e=>{
            this.spin=false;
          });

          this.message.add({
            key: 'pedido',
            severity: 'success',
            summary: 'Inserción de Pedido',
            detail: 'El pedido se insertó correctamente'
          });
          this.spin=false;
          // if (this.str_NumPed === 'C') {
          //   this.ActualizarNumero(this.GRUPO, 'PED', this.globalNemonico, this.globalSerie);
          // }
          if (this.str_NumPed === 'C') {
            //CDPJ
            if(this.docref === 'COT'){
              this.ActualizarNumero(this.GRUPO, 'COT', this.globalNemonico, this.globalSerie);
            }else{
              this.ActualizarNumero(this.GRUPO, 'PED', this.globalNemonico, this.globalSerie);
            }
            //CDPJ

          }
          this.disabledEstadisticas = false;
          this.botonPrimero = false;
          this.botonAnterior = false;
          this.botonSiguiente = false;
          this.botonUltimo = false;
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.btnRegresar = true;
          this.btnAnular = false;
          this.indice = this.encabezadosPedidos.length + 1;
          this.auditoriagral.registrarAuditoria('VEN_ENCPED', String(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO)
            , 'I',
            '', '01', '', '', '', '').subscribe(() => {
            });
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO = 'PENDIENTE';
          this.encpedService.erGetClientCodigo(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO).subscribe((tipoCli) => {
            if (String(tipoCli[0].CLI_TIPOIDE) === '6') {
              this.disabledEstadisticas = true;
            } else {
              this.disabledEstadisticas = false;
            }
          });

        }
      }

      const res1 = await this.encpedService.getEncPedidosProm();
      this.encabezadosPedidos = res1;

      this.cancelarEdicionPedido();
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.message.add({
        key: 'pedido',
        severity: 'error',
        summary: 'Inserción de Pedido',
        detail: mensaje
      });
      this.spin=false;
    }

    // Old
    /*this.encpedService.guardarENCPED(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador]).subscribe((res) => {
      if (res !== null) {
        if (this.indice !== -1) {
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO = res[0].ENCPED_numero;
          this.encpedService.insertarUsuidentificacion(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador]).subscribe(() => {
          });
          this.encpedService.detpedidosarray[this.indicador].map((pedido) => {
            pedido.ENCPED_NUMERO = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO;
            this.encpedService.insertarDetalle(pedido).subscribe(() => {
              if (pedido.DETPED_LINEA === 0) {
                pedido.DETPED_LINEA = pedido.LINEA;
                this.auditoriagral.registrarAuditoria('VEN_DETPED', String(pedido.ENCPED_NUMERO), 'I',
                  '', '01', '', '', '', '').subscribe(() => {
                });
              } else if (pedido.DETPED_LINEA !== 0) {
                this.auditoriagral.registrarAuditoria('VEN_DETPED', String(pedido.ENCPED_NUMERO), 'A',
                  '', '01', '', '', '', '').subscribe(() => {
                });
              }
            });
          });
          this.message.add({
            key: 'pedido',
            severity: 'success',
            summary: 'Actualización de Pedido',
            detail: 'El pedido se actualizó correctamente'
          });
          this.btnGuardar = true;
          this.btnNuevo = false;
          this.botonPrimero = false;
          this.botonAnterior = false;
          this.botonSiguiente = false;
          this.botonUltimo = false;
          this.auditoriagral.registrarAuditoria('VEN_ENCPED', String(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO)
            , 'A', '', '01', '', '', '', '').subscribe(() => {
          });
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO = 'PENDIENTE';
          
          this.encpedService.erGetClientCodigo(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO).subscribe((tipoCli) => {
            if (String(tipoCli[0].CLI_TIPOIDE) === '6') {
              this.disabledEstadisticas = true;
            } else {
              this.disabledEstadisticas = false;
            }
          });
          
          
        } else if (this.indice === -1) {
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO = res[0].v_ENCPED_numero;
          this.encpedService.insertarUsuidentificacion(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador]).subscribe(() => {
          });
          this.encpedService.detpedidosarray[this.indicador].map((pedido) => {
            pedido.ENCPED_NUMERO = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO;
            this.encpedService.insertarDetalle(pedido).subscribe(() => {
              if (pedido.DETPED_LINEA === 0) {
                pedido.DETPED_LINEA = pedido.LINEA;
                this.auditoriagral.registrarAuditoria('VEN_DETPED', String(pedido.ENCPED_NUMERO), 'I',
                  '', '01', '', '', '', '').subscribe(() => {
                });
              } else if (pedido.DETPED_LINEA !== 0) {
                this.auditoriagral.registrarAuditoria('VEN_DETPED', String(pedido.ENCPED_NUMERO), 'A',
                  '', '01', '', '', '', '').subscribe(() => {
                });
              }
            });
          });
          this.message.add({
            key: 'pedido',
            severity: 'success',
            summary: 'Inserción de Pedido',
            detail: 'El pedido se insertó correctamente'
          });
          this.disabledEstadisticas = false;
          this.botonPrimero = false;
          this.botonAnterior = false;
          this.botonSiguiente = false;
          this.botonUltimo = false;
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.btnRegresar = true;
          this.btnAnular = false;
          this.indice = this.encabezadosPedidos.length + 1;
          this.auditoriagral.registrarAuditoria('VEN_ENCPED', String(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO)
            , 'I',
            '', '01', '', '', '', '').subscribe(() => {
          });
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO = 'PENDIENTE';
          this.encpedService.erGetClientCodigo( this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO).subscribe((tipoCli) => {
            if (String(tipoCli[0].CLI_TIPOIDE) === '6') {
              this.disabledEstadisticas = true;
            } else {
              this.disabledEstadisticas = false;
            }
          });
        }
      }
      this.encpedService.getEncPedidos().subscribe((res1) => {
        this.encabezadosPedidos = res1;
      });
    }, error1 => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
      this.message.add({
        key: 'pedido',
        severity: 'error',
        summary: 'Inserción de Pedido',
        detail: mensaje
      });
    });*/
    this.spin = false;
  }

  confirmarPedido() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el pedido',
      header: 'Eliminar Pedido',
      icon: 'pi pi-exclamation-triangle',
      key: 'pedidoConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.eliminarPedido();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  eliminarPedido() {
    if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador] !== undefined) {
      let numDesp = 0;
      for (let fila = 0; fila < this.encpedService.detpedidosarray[this.indicador].length; fila++) {
        const item = this.encpedService.detpedidosarray[this.indicador][fila];
        if (Number(item.DETPED_DESPACHO) > 0) {
          numDesp++;
        }
      }
      if (numDesp > 0) {
        return this.message.add({
          key: 'pedido',
          severity: 'error',
          summary: 'Error',
          detail: 'No es posible eliminar el pedido debido a que ha sido parcialmente despachado'
        });
      }

      this.encpedService.eliminarEnc(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador]).subscribe(() => {
        this.message.add({
          key: 'pedido',
          severity: 'success',
          summary: 'Eliminación de Pedido',
          detail: 'El pedido se eliminó correctamente'
        });
        this.auditoriagral.registrarAuditoria('VEN_ENCPED', String(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO),
          'E',
          '', '01', '', '', '', '').subscribe(() => {
          });
        this.encpedService.detpedidosarray[this.indicador].map(() => {
          this.auditoriagral.registrarAuditoria('VEN_DETPED', String(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO)
            , 'E',
            '', '01', '', '', '', '').subscribe(() => {
            });
        });
        this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador] = {
          ENCPED_NUMERO: '', COM_CODIGO: '01', CLI_CODIGO: '', CLI_NOMBRE: '', VEN_CODIGO: '',
          ENCPED_FECHAEMISION: new Date(), ENCPED_FECHAENTREGA: new Date(), ENCPED_IVA: '1', ENCPED_ESTADO: '',
          ENCPED_ORDEN: '', ENCPED_LISTA: 'A', ENCPED_OBSERVACION: '', ENCPED_TOTAL: 0, ENCPED_TOTALNETO: 0,
          ENCPED_VALORDES: 0, ENCPED_PORCEDES: 0, ENCPED_VALORIVA: 0, ENCPED_PORCEIVA: '', ENCPED_VALORICE:
            0, ENCPED_PORCEICE: '', ENCPED_BASEIVA: 0, ENCPED_BASEICE: 0, ENCPED_BASECERO: 0, ENCPED_GRUPO:
            '', ENCPED_REFERENCIA: '', ENCPED_FECHAVALIDEZ: new Date(), BOD_CODIGO: '001', ENCPED_TIPO: '', ENCPED_TIPODSCTO:
            '', ENCPED_REFCLI: '', CLI_CODIGO_EMPRESARIA: '', ENCPED_JEFEZONA: '', ENCPED_CAMPANIA: '', USUIDENTIFICACION:
            this.usuario.identificacion, ENCPED_FORMAPAGO: '', ENCPED_TERMCOND: ''
        };

        document.getElementById('divPrincipal').style.pointerEvents = 'none';
        // document.getElementById('divPrincipal2').style.pointerEvents = 'none';
        this.aggrid.bloquearGrid();

        this.encpedService.detpedidosarray[this.indicador] = [];
        this.nombreVendedor = '';
        this.nombreCliente = '';
        this.btnNuevo = false;
        this.btnGuardar = true;
        this.btnBorrar = true;
        this.btnRegresar = true;
        this.btnImagenGrid = true;
        this.btnNuevoGrid = true;
        this.btnBorrarGrid = true;
        this.btnCancelarGrid = true;

        this.encpedService.getEncPedidos().subscribe((res1) => {
          this.encabezadosPedidos = res1;
        });
      });


    } else {
      this.message.add({
        key: 'pedido',
        severity: 'error',
        summary: 'Eliminación de Pedido',
        detail: 'Seleccione un pedido para eliminarlo'
      });
    }
  }

  cancelarEdicionPedido() {
    if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO === '' && this.btnNuevo === true) {
      this.nuevoPedido('C');
      this.disabledEstadisticas = true;
    } else if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO !== '') {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.botonPrimero = false;
      this.botonSiguiente = false;
      this.botonAnterior = false;
      this.botonUltimo = false;
      this.spin=false;
      this.pedidoCancelado();
    }
  }

  pedidoCancelado() {
    this.encpedService.getEncPedidos().subscribe((res) => {
      res.map((pedido) => {
        if (pedido.ENCPED_NUMERO === this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO) {
          this.encabezadoPedidoSeleccionadoAux = pedido;
        }
      });
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador] = this.encabezadoPedidoSeleccionadoAux;
      this.obtenerDatos();
    });
    this.btnNuevo = false;
    this.btnGuardar = true;

    if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO === 'A'
      || this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO === 'ANULADO' ||
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO === 'O' ||
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO === 'OK') {
      this.btnBorrar = true;
    } else {
      this.btnBorrar = false;
    }
    

  }

  obtenerDatos() {
    this.bolestcta = false;
    this.bolsaldo = false;
    this.ped_refrencia=this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_REFERENCIA;//CDPJ
    if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO === null) {
    }
    if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAENTREGA === null) {
      this.fechaBoolean = false;
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAENTREGA = new Date();
    } else if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAENTREGA !== '') {
      this.fechaBoolean = true;
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAENTREGA = new Date(
        this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAENTREGA);
    }
     //CDPJ
     for (const j of this.opcionIva) {
      if (Number(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_PORCEIVA) === Number(j.name)) {
        this.selectcombtipoIva = { codigo: j.codigo, name: j.name };
        this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_PORCEIVA =Number(this.selectcombtipoIva.name);
      }
    }
    let porcentaje=this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_PORCEIVA
    let data1=this.confIniciales.srifetariiva.find(element => Number(element.TARIIVA_PORCENTAJE) === Number(porcentaje))
    this.encpedService.porcetariiva=Number(porcentaje);
    this.encpedService.codtariiva=data1.TARIIVA_CODIGO;
    //CDPJ
    if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO === 'P' ||
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO === 'PENDIENTE') {
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO = 'PENDIENTE';
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      // document.getElementById('divPrincipal').style.opacity = '1.0';
      // document.getElementById('divPrincipal2').style.pointerEvents = 'all';
      this.aggrid.habilitarGrid();
      // document.getElementById('divPrincipal2').style.opacity = '1.0';
      this.btnNuevo = false;
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnCancelarGrid = false;
      this.btnBorrarGrid = false;
      this.btnAnular = false;
      this.btnBorrar = false;
    } else if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO === 'O'
      || this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO === 'OK') {
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO = 'OK';
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      // document.getElementById('divPrincipal').style.opacity = '0.7';
      // document.getElementById('divPrincipal2').style.pointerEvents = 'none';
      this.aggrid.bloquearGrid();
      // document.getElementById('divPrincipal2').style.opacity = '0.7';
      this.btnNuevo = false;
      this.btnBorrar = true;
      this.btnGuardar = true;
      this.btnNuevoGrid = true;
      this.btnGuardarGrid = true;
      this.btnCancelarGrid = true;
      this.btnBorrarGrid = true;
      this.btnImagenGrid = true;
      this.btnAnular = true;
    } else if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO === 'A'
      || this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO === 'ANULADO') {
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO = 'ANULADO';
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      // document.getElementById('divPrincipal').style.opacity = '1.0';
      // document.getElementById('divPrincipal2').style.pointerEvents = 'none';
      this.aggrid.bloquearGrid();
      // document.getElementById('divPrincipal2').style.opacity = '1.0';
      this.btnNuevo = false;
      this.btnBorrar = true;
      this.btnGuardar = true;
      this.btnNuevoGrid = true;
      this.btnGuardarGrid = true;
      this.btnCancelarGrid = true;
      this.btnBorrarGrid = true;
      this.btnImagenGrid = true;
      this.btnAnular = true;
    }
    if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_TIPO === 'C') {
      this.cotizacionBoolean = true;
    } else if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_TIPO !== 'C') {
      this.cotizacionBoolean = false;
    }
    this.valorDscValor = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORDES;
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORDES = Number(
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORDES).toFixed(this.numdecsist);
    this.encpedService.dblValorDescGlbPedarray[this.indicador] = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORDES;
    this.valorDscPorcentaje = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_PORCEDES;
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_PORCEDES = Number(
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_PORCEDES).toFixed(this.numdecsist);
    this.encpedService.dblPorcDescGlbPedarray[this.indicador] = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_PORCEDES;
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORIVA = Number(
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORIVA).toFixed(this.numdecsist);
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_BASEIVA = Number(
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_BASEIVA).toFixed(this.numdecsist);
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORICE = Number(
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORICE).toFixed(this.numdecsist);
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_BASEICE = Number(
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_BASEICE).toFixed(this.numdecsist);
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_BASECERO = Number(
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_BASECERO).toFixed(this.numdecsist);
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_TOTALNETO = Number(
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_TOTALNETO).toFixed(this.numdecsist);
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_TOTAL = Number(
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_TOTAL).toFixed(this.numdecsist);

    if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_TIPODSCTO == 'V') {
      this.dscSeleccionado = 'dscValor';
      this.booleanValor = false;
      this.booleanPorcentaje = true;
    }

    if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_TIPODSCTO == 'P') {
      this.dscSeleccionado = 'dscPorcentaje';
      this.booleanValor = true;
      this.booleanPorcentaje = false;
    }

    this.encpedService.erGetClientCodigo(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO).subscribe((tipoCli) => {
      if (String(tipoCli[0].CLI_TIPOIDE) === '6') {
        this.disabledEstadisticas = true;
      } else {
        this.disabledEstadisticas = false;
      }
    });
    this.encpedService.obtenerNombreCli(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO).subscribe((res) => {
      this.nombreCliente = res[0].CLI_NOMBRE;
    });
    if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].VEN_CODIGO !== null) {
      this.encpedService.obtenerNombreVen(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].VEN_CODIGO).subscribe((res1) => {
        this.nombreVendedor = res1[0].VEN_NOMBRE;
      });
    } else {
      this.nombreVendedor = '';
    }
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAEMISION = new Date(
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAEMISION);
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAVALIDEZ = new Date(
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_FECHAVALIDEZ);
    this.horaSistema = new Date();
    this.encpedService.comas();
    this.encpedService.dblPorcIvaPed[this.indicador] = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_PORCEIVA;
    if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO !== '') {
      this.encpedService.getDetPedidos(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO).subscribe((res) => {
        if (res !== null  && res.length !== 0) {
          this.encpedService.detpedidosarray[this.indicador] = res;
          // const opcPedido = [];
          this.encpedService.detpedidosarray[this.indicador].map((pedido) => {
            const opcPedido = [];
            const opcLista = [];
            opcPedido.push(pedido.DETPED_UNIDAD);
            this.columnDefsPedidoDetalle[5].cellEditorParams = {
              values: opcPedido
            };
            this.aggrid.refreshColumnDefs();
            pedido.DETPED_PRECIO = pedido.DETPED_PRECIO.toFixed(this.decimalesPrecioPed);
            pedido.DETPED_CANTIDAD = pedido.DETPED_CANTIDAD.toFixed(this.decimalesCantidadPed);
            pedido.DETPED_TOTAL = Number(pedido.DETPED_TOTAL).toFixed(this.decimalesTotalPed);
            pedido.DETPED_DESPACHO = Number(pedido.DETPED_DESPACHO).toFixed(this.decimalesDespacho);
            pedido.DETPED_DESCUENTO = Number(pedido.DETPED_DESCUENTO).toFixed(this.decimalesDescPed);
            pedido.DETPED_FACTURADO = pedido.DETPED_FACTURADO === null ? null : Number(pedido.DETPED_FACTURADO).toFixed(2);
            //  this.utilitariosService.getListaPrecioArt(pedido.DETPED_CODIGO).subscribe
            pedido.CEN_CODIGO = pedido.CEN_CODIGO === undefined ? '' : pedido.CEN_CODIGO//CDPJ
            this.utilitariosService.verificarLista(pedido.DETPED_CODIGO).subscribe((res1) => {
              res1.map((ped) => {
                opcLista.push(ped.ARTPRE_CODIGO);
              });
              this.columnDefsPedidoDetalle[12].cellEditorParams = {
                values: opcLista
              };
              this.aggrid.refreshColumnDefs();
            });
          });
          this.detallePedidoSeleccionado = this.encpedService.detpedidosarray[this.indicador][0];
        }
      });
    }this.spin=false;
  }

  async seleccionDetallePed(valor) {
    if (valor === 'nuevo') {
      console.log(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].BOD_CODIGO)
      if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].BOD_CODIGO === null || this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].BOD_CODIGO === undefined || this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].BOD_CODIGO === '') {
        this.message.add({
          key: 'pedido',
          severity: 'error',
          summary: 'Inserción de Detalle',
          detail: 'Ingrese bodega antes de crear una linea nueva'
        });
      } else {
        this.nuevoDetallePed();
      }
    } else if (valor === 'eliminar') {
      //this.confirmEliminarDetPed();
      let encpedestado=''
      //CDPJ
        const data = await this.encpedService.obtenerEncpedEstado(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO);
        if(data !== null && data !== undefined){
          for(const rs1 of data){
            encpedestado=rs1.ENCPED_ESTADO
          }
        }
      console.log('encpedestado',encpedestado)
      if(encpedestado === 'O'){
        this.message.add({
          key: 'pedido',
          severity: 'error',
          summary: 'Inserción de Pedido',
          detail: 'El Pedido ya se encuentra en estado \'OK\''
        });
        this.cancelarEdicionPedido();
        return;
      }else{
        this.confirmEliminarDetPed();
      }
    //CDPJ
    } else if (valor === 'cancelar') {
      this.cancelarDetPed();
    }

    if (valor === 'existenciasBod') {
      let encpedestado=''
      //CDPJ
        const data = await this.encpedService.obtenerEncpedEstado(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO);
        if(data !== null && data !== undefined){
          for(const rs1 of data){
            encpedestado=rs1.ENCPED_ESTADO
          }
        }
      console.log('encpedestado',encpedestado)
      if(encpedestado === 'O'){
        this.message.add({
          key: 'pedido',
          severity: 'error',
          summary: 'Inserción de Pedido',
          detail: 'El Pedido ya se encuentra en estado \'OK\''
        });
        this.cancelarEdicionPedido();
        return;
      }else{
        this.txtListaPrecio = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_LISTA;
        this.txtIva = this.opcionIva[0].label;
        this.txtBodegaRes = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].BOD_CODIGO;
  
        this.txtObservacionRes = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO + '-' +
          this.nombreCliente;
  
        if (this.detallePedidoSeleccionado === undefined) {
          this.txtArtCodigo = '';
          this.txtArtDescripcion = '';
        } else if (Object.keys(this.detallePedidoSeleccionado).length > 0) {
  
          if (this.detallePedidoSeleccionado.DETPED_CODIGO === '' || this.detallePedidoSeleccionado.DETPED_TIPODET === '*'
            || this.detallePedidoSeleccionado.DETPED_TIPODET === 'S' || this.detallePedidoSeleccionado.DETPED_TIPODET === 'C') {
            this.txtArtCodigo = '';
            this.txtArtDescripcion = '';
            this.displayDialogExistBod = true;
          } else {
            this.txtArtCodigo = this.detallePedidoSeleccionado.DETPED_CODIGO;
            this.txtArtDescripcion = this.detallePedidoSeleccionado.DETPED_DESCRIPCION;
            this.displayDialogExistBod = true;
          }
        }
      }
    //CDPJ
      // this.txtListaPrecio = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_LISTA;
      // this.txtIva = this.opcionIva[0].label;
      // this.txtBodegaRes = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].BOD_CODIGO;

      // this.txtObservacionRes = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO + '-' +
      //   this.nombreCliente;

      // if (this.detallePedidoSeleccionado === undefined) {
      //   this.txtArtCodigo = '';
      //   this.txtArtDescripcion = '';
      // } else if (Object.keys(this.detallePedidoSeleccionado).length > 0) {

      //   if (this.detallePedidoSeleccionado.DETPED_CODIGO === '' || this.detallePedidoSeleccionado.DETPED_TIPODET === '*'
      //     || this.detallePedidoSeleccionado.DETPED_TIPODET === 'S' || this.detallePedidoSeleccionado.DETPED_TIPODET === 'C') {
      //     this.txtArtCodigo = '';
      //     this.txtArtDescripcion = '';
      //     this.displayDialogExistBod = true;
      //   } else {
      //     this.txtArtCodigo = this.detallePedidoSeleccionado.DETPED_CODIGO;
      //     this.txtArtDescripcion = this.detallePedidoSeleccionado.DETPED_DESCRIPCION;
      //     this.displayDialogExistBod = true;
      //   }
      // }
    }

    if (valor === 'verInfoArtPed') {
      console.log(this.detallePedidoSeleccionado);
      if (this.detallePedidoSeleccionado.DETPED_TIPODET === 'A') {
        const data = await this.encpedService.consultarInfoArticulo(this.detallePedidoSeleccionado.DETPED_CODIGO);
        for (const rs of data) {
          this.ART_LIB_TITULO = rs.ART_LIB_TITULO === null ? '' : rs.ART_LIB_TITULO;
          this.ART_LIB_AUTOR = rs.ART_LIB_AUTOR === null ? '' : rs.ART_LIB_AUTOR;
          this.ART_LIB_EDITORIAL = rs.ART_LIB_EDITORIAL === null ? '' : rs.ART_LIB_EDITORIAL;
          this.ART_LIB_ORIGEN = rs.ART_LIB_ORIGEN === null ? '' : rs.ART_LIB_ORIGEN;
          this.ART_LIB_TAMANIO = rs.ART_LIB_TAMANIO === null ? '' : rs.ART_LIB_TAMANIO;
          this.ART_LIB_ENCUADERNACION = rs.ART_LIB_ENCUADERNACION === null ? '' : rs.ART_LIB_ENCUADERNACION;

        }
        this.displayDialogInfoArt = true;
      }
    }
    ///////////////////////////vehiculo

    if (valor === 'verInfoArtVeh') {
      console.log(this.detallePedidoSeleccionado);
      this.ART_RAMV = '';
      this.ART_MODELOSRI = '';
      this.ART_MODELOAT = '';
      this.ART_MARCAV = '';
      this.ART_CLASE = '';
      this.ART_TIPOV = '';
      this.ART_CILINDRAJE = '';
      this.ART_ANIO = '';
      this.ART_TONELAJE = '';
      this.ART_COLOR = '';
      this.ART_PORIGEN = '';
      this.ART_MOTOR = '';
      this.ART_SERIEV = '';
      this.ART_NRUEDAS = '';
      this.ART_NEJES = '';
      this.ART_CAPACIDAD = '';
      this.ART_COMBUSTIBLE = '';
      this.ART_BANCO = '';
      this.ART_DPAGO = '';
      this.ART_FFABRICA = '';
      this.ART_DISPOSITIVO = '';
      this.ART_MARVEH = '';
      this.ART_CLAVEH = '';
      this.ART_TIPVEH = '';
      this.ART_COMVEH = '';
      this.ART_DISVEH = '';
      this.ART_P = '';

      if (this.detallePedidoSeleccionado.DETPED_TIPODET === 'A') {
        this.busquedaVeh.getArticulo(this.detallePedidoSeleccionado.DETPED_CODIGO).subscribe(async (res) => {
          for (const i of res) {
            this.ART_RAMV = i.ART_RAMV === null ? '' : i.ART_RAMV;
            this.ART_MODELOSRI = i.ART_MODELOSRI === null ? '' : i.ART_MODELOSRI;
            this.ART_MODELOAT = i.ART_MODELOAT === null ? '' : i.ART_MODELOAT;
            this.ART_MARCAV = i.ART_MARCAV === null ? '' : i.ART_MARCAV;
            this.ART_CLASE = i.ART_CLASE === null ? '' : i.ART_CLASE;
            this.ART_TIPOV = i.ART_TIPOV === null ? '' : i.ART_TIPOV;
            this.ART_CILINDRAJE = i.ART_CILINDRAJE === null ? '' : i.ART_CILINDRAJE;
            this.ART_ANIO = i.ART_ANIO === null ? '' : i.ART_ANIO;
            this.ART_TONELAJE = i.ART_TONELAJE === null ? '' : i.ART_TONELAJE;
            this.ART_COLOR = i.ART_COLOR === null ? '' : i.ART_COLOR;
            this.ART_PORIGEN = i.ART_PORIGEN === null ? '' : i.ART_PORIGEN;
            this.ART_MOTOR = i.ART_MOTOR === null ? '' : i.ART_MOTOR;
            this.ART_SERIEV = i.ART_SERIEV === null ? '' : i.ART_SERIEV;
            this.ART_NRUEDAS = i.ART_NRUEDAS === null ? '' : i.ART_NRUEDAS;
            this.ART_NEJES = i.ART_NEJES === null ? '' : i.ART_NEJES;
            this.ART_CAPACIDAD = i.ART_CAPACIDAD === null ? '' : i.ART_CAPACIDAD;
            this.ART_COMBUSTIBLE = i.ART_COMBUSTIBLE === null ? '' : i.ART_COMBUSTIBLE;
            this.ART_BANCO = i.ART_BANCO === null ? '' : i.ART_BANCO;
            this.ART_DPAGO = i.ART_DPAGO === null ? '' : i.ART_DPAGO;
            this.ART_FFABRICA = i.ART_FFABRICA === null ? '' : this.datePipe.transform(i.ART_FFABRICA, 'dd/MM/yyyy');
            this.ART_DISPOSITIVO = i.ART_DISPOSITIVO === null ? '' : i.ART_DISPOSITIVO;

            if (i.ART_MARCAV !== null) {
              this.busquedaVeh.encontrarRefNombreMaeRef(i.ART_MARCAV, 'MARVEH').subscribe((formsriven) => {
                if (formsriven[0] !== undefined) {
                  this.ART_MARVEH = formsriven[0].REF_NOMBRE;
                }
              });
            }
            if (i.ART_CLASE !== null) {
              this.busquedaVeh.encontrarRefNombreMaeRef(i.ART_CLASE, 'CLAVEH').subscribe((formsriven) => {
                if (formsriven[0] !== undefined) {
                  this.ART_CLAVEH = formsriven[0].REF_NOMBRE;
                }
              });
            }
            if (i.ART_TIPOV !== null) {
              this.busquedaVeh.encontrarRefNombreMaeRef(i.ART_TIPOV, 'TIPVEH').subscribe((formsriven) => {
                if (formsriven[0] !== undefined) {
                  this.ART_TIPVEH = formsriven[0].REF_NOMBRE;
                }
              });
            }
            if (i.ART_COMBUSTIBLE !== null) {
              this.busquedaVeh.encontrarRefNombreMaeRef(i.ART_COMBUSTIBLE, 'COMVEH').subscribe((formsriven) => {
                if (formsriven[0] !== undefined) {
                  this.ART_COMVEH = formsriven[0].REF_NOMBRE;
                }
              });
            }
            if (i.ART_DISPOSITIVO !== null) {
              this.busquedaVeh.encontrarRefNombreMaeRef(i.ART_DISPOSITIVO, 'DISVEH').subscribe((formsriven) => {
                if (formsriven[0] !== undefined) {
                  this.ART_DISVEH = formsriven[0].REF_NOMBRE;
                }
              });
            }
            if (i.ART_PORIGEN !== null) {
              this.busquedaVeh.encontrarRefNombreMaeRef(i.ART_PORIGEN, 'P').subscribe((formsriven) => {
                if (formsriven[0] !== undefined) {
                  this.ART_P = formsriven[0].REF_NOMBRE;
                }
              });
            }
            if (i.ART_BANCO !== null) {
              this.cliService.erNombreBanco(i.ART_BANCO).subscribe((formsricom) => {
                if (formsricom !== null) {
                  if (formsricom[0] !== undefined) {
                    this.ART_NBANCO = formsricom[0].BANCLI_NOMBRE;
                  }
                }
              });
            }
            if (i.ART_RAMV !== null) {
              this.displayvehiculo = true;
            } else {
              this.messageService.add({
                key: 'pedido',
                severity: 'error',
                summary: 'Detalle Vehículo',
                detail: 'El articulo seleccionado no es un vehículo.'
              });
            }
          }
        });

      }
    }

    if (valor.object !== undefined) {
      this.detallePedidoSeleccionado = valor.object;

      if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultPedidoDetalle.objeto].getFocusedCell().column.colId === 'DETPED_DESPACHO') {
        this.aggrid.refreshColumnDefs();
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultPedidoDetalle.objeto].setFocusedCell(
          this.encpedService.detpedidosarray[this.indicador].indexOf(this.detallePedidoSeleccionado), 'DETPED_PRECIO');
      }

      if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultPedidoDetalle.objeto].getFocusedCell().column.colId === 'DETPED_UNIDAD') {
        if (this.detallePedidoSeleccionado.ART_MULTIUNIDAD === 'S') {
          const opciones = [];
          // opciones.push(this.detallePedidoSeleccionado.DETPED_UNIDAD);

          // this.utilitariosService.getUnidadesEqui( this.detallePedidoSeleccionado.DETPED_UNIDAD).subscribe((res) => {
          //   if (res !== null) {
          //     res.map((unidades) => {
          //       opciones.push(unidades.UNI_CODIGODESTINO);
          //     });
          //   }
          // });
          let intNumReg = 1;
          this.utilitariosService.obtenerUnidadEqui(this.detallePedidoSeleccionado.DETPED_CODIGO).subscribe((res) => {
            if (res !== null) {

              res.forEach((re) => {
                if (intNumReg === 1) {
                  opciones.push(re.UNI_CODIGO);
                }
                opciones.push(re.UNI_CODIGODESTINO);
                intNumReg = intNumReg + 1;
              });

            }
          });

          this.encpedService.detpedidosarray[this.indicador][this.encpedService.detpedidosarray[this.indicador].indexOf(
            this.detallePedidoSeleccionado)].DETPED_UNIDAD = this.detallePedidoSeleccionado.DETPED_UNIDAD;
          this.columnDefsPedidoDetalle[5].cellEditor = 'agSelectCellEditor';
          this.columnDefsPedidoDetalle[5].editable = true;
          this.columnDefsPedidoDetalle[5].cellEditorParams = {
            values: opciones
          };
          this.aggrid.refreshColumnDefs();
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultPedidoDetalle.objeto].setFocusedCell(
            this.encpedService.detpedidosarray[this.indicador].indexOf(this.detallePedidoSeleccionado), 'DETPED_UNIDAD');
        } else if (this.detallePedidoSeleccionado.ART_MULTIUNIDAD === 'N') {
          const opciones = [];
          opciones.push(this.detallePedidoSeleccionado.DETPED_UNIDAD);
          this.encpedService.detpedidosarray[this.indicador][this.encpedService.detpedidosarray[this.indicador].indexOf(
            this.detallePedidoSeleccionado)].DETPED_UNIDAD = this.detallePedidoSeleccionado.DETPED_UNIDAD;
          this.columnDefsPedidoDetalle[5].cellEditor = 'cellPrueba';
          this.columnDefsPedidoDetalle[5].editable = false;
          this.aggrid.refreshColumnDefs();
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultPedidoDetalle.objeto].setFocusedCell(
            this.encpedService.detpedidosarray[this.indicador].indexOf(this.detallePedidoSeleccionado), 'DETPED_UNIDAD');
        }
      } else if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultPedidoDetalle.objeto].getFocusedCell().column.colId === 'DETPED_LISTA') {
        // this.utilitariosService.getListaPrecioArt(this.detallePedidoSeleccionado.DETPED_CODIGO).subscribe
        this.utilitariosService.verificarLista(this.detallePedidoSeleccionado.DETPED_CODIGO).subscribe((res1) => {
          const opciones1 = [];
          opciones1.push(this.encpedService.detpedidosarray[this.indicador][this.encpedService.detpedidosarray[this.indicador].indexOf(
            this.detallePedidoSeleccionado)].DETPED_LISTA);
          res1.map((codigo) => {
            if (codigo.ARTPRE_CODIGO !== opciones1[0]) {
              opciones1.push(codigo.ARTPRE_CODIGO);
            }
          });
          this.columnDefsPedidoDetalle[12].cellEditorParams = {
            values: opciones1
          };
          this.aggrid.refreshColumnDefs();
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultPedidoDetalle.objeto].setFocusedCell(
            this.encpedService.detpedidosarray[this.indicador].indexOf(this.detallePedidoSeleccionado), 'DETPED_LISTA');
          res1.map((codigoPre) => {
            if (this.encpedService.detpedidosarray[this.indicador][this.encpedService.detpedidosarray[this.indicador].indexOf(
              this.detallePedidoSeleccionado)].DETPED_LISTA === codigoPre.ARTPRE_CODIGO) {
              // console.log(codigoPre.ARTPRE_PRECIO);
              // this.encpedService.detpedidosarray[this.indicador][this.encpedService.detpedidosarray
              // [this.indicador].indexOf(this.detallePedidoSeleccionado)].DETPED_PRECIO =
              // Number(codigoPre.ARTPRE_PRECIO).toFixed(this.decimalesPrecioPed);
              // this.aggrid.refreshColumnDefs();
            }
          });
        });
      }
      if (this.detallePedidoSeleccionado.DETPED_CODIGO !== null && this.detallePedidoSeleccionado.DETPED_CODIGO !== '') {
        this.encpedService.consultarImagen(this.detallePedidoSeleccionado.DETPED_CODIGO).subscribe((res) => {
          if (res.length !== 0) {
            this.imagenProducto = res[0].ART_IMAGEN;
          } else if (res.length === 0) {
            this.imagenProducto = '';
          }
        });
      }


    }
  }

  async nuevoDetallePed() {

    if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO !== '') {
      const rs = await this.encpedService.erBodCodigo1(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].BOD_CODIGO);
      this.btnNuevoGrid = true;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;

      if (rs === null || rs === undefined || rs === ''){
        this.message.add({
          key: 'pedido',
          severity: 'error',
          summary: 'Ingreso Notas de Recepción',
          detail: 'Error, Código de la bodega '+this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].BOD_CODIGO+ ' es incorrecto'
        });
       
      return;
      }else{
      const linea = this.encpedService.detpedidosarray[this.indicador].length;

      if (this.detallePedidoSeleccionado === undefined) {
        this.detallePedidoSeleccionado = {};
      }
      if (this.encpedService.detpedidosarray[this.indicador].length === 0 && this.detallePedidoSeleccionado.DETPED_TIPODET === undefined) {
        const cenodigo = await this.encpedService.getbodcencodigoprom(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].BOD_CODIGO)//CDPJ

        const nuevodetPedido = {
          ENCPED_NUMERO: '',
          COM_CODIGO: '01',
          LINEA: Number(linea + 1),
          DETPED_LINEA: 0,
          DETPED_TIPODET: 'A',
          DETPED_CODIGO: '',
          DETPED_DESCRIPCION: '',
          DETPED_UNIDAD: '',
          DETPED_CANTIDAD: '',
          DETPED_DESPACHO: '',
          DETPED_PRECIO: '',
          DETPED_DESCUENTO: 0,
          DETPED_TOTAL: 0,
          DETPED_TRIBIVA: '',
          DETPED_IVA: 0,
          DETPED_TRIBICE: '',
          DETPED_ICE: 0,
          DETPED_PORCEICE: '',
          DETPED_LISTA: this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_LISTA,
          DETPED_BASEIVA: 0,
          DETPED_BASEICE: 0,
          DETPED_BASECERO: 0,
          DETPED_ORDEN: 0,
          DETPED_NUMBLO: 0,
          BOD_CODIGO: this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].BOD_CODIGO,
          DETPED_CANTIDADPED: '',
          DETPED_CODALTERNO: '',
          DETPED_PROMOCION: '',
          MAEPROVTA_CODIGO: '',
          DETPED_RELACION: '',
          DETPED_ZONACONTROL: '',
          DETPED_UBICACION: '',
          DETPED_CARACTERISTICAS: '',
          DETPED_POSICION: '',
          ENCPED_IVA: this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_IVA,
          PRECIO: '',
          ART_MULTIUNIDAD: 'N',
          DETPED_LOTE: '',
          DETPED_FACTURADO: null,
          CEN_CODIGO: '',
          DETPED_PORIVA:this.encpedService.porcetariiva,
          TARIIVA_CODIGO:''
        };
        //CDPJ
        if (this.intCCPIPED === 1) {
          if (cenodigo === null || cenodigo === undefined) {
            nuevodetPedido.CEN_CODIGO = '';
          } else {
            nuevodetPedido.CEN_CODIGO = cenodigo[0].CEN_CODIGO === null || cenodigo[0].CEN_CODIGO === undefined ? '' : cenodigo[0].CEN_CODIGO;
          }
        } else {
          nuevodetPedido.CEN_CODIGO = '';
        }

        //CDPJ
        if (this.detallePedidoSeleccionado !== undefined && this.detallePedidoSeleccionado !== null) {
          const indi = this.encpedService.detpedidosarray[this.indicador].indexOf(this.detallePedidoSeleccionado);

          if (indi === -1) {
            this.encpedService.detpedidosarray[this.indicador].push(nuevodetPedido);
            this.detallePedidoSeleccionado = nuevodetPedido;
             //CDPJ
             if(this.detallePedidoSeleccionado.DETPED_TIPODET === '*'){
              this.detallePedidoSeleccionado.DETPED_PORIVA=0;
              this.detallePedidoSeleccionado.TARIIVA_CODIGO='-1';
            }
            //CDPJ
            this.aggrid.refreshaggrid(this.encpedService.detpedidosarray[this.indicador], this.defaultPedidoDetalle.objeto);
            this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultPedidoDetalle.objeto].setFocusedCell(
              this.encpedService.detpedidosarray[this.indicador].length - 1, 'DETPED_CODIGO');
          } else {
            this.encpedService.detpedidosarray[this.indicador].splice(indi + 1, 0, nuevodetPedido);
            this.detallePedidoSeleccionado = nuevodetPedido;
            //CDPJ
            if(this.detallePedidoSeleccionado.DETPED_TIPODET === '*'){
              this.detallePedidoSeleccionado.DETPED_PORIVA=0;
              this.detallePedidoSeleccionado.TARIIVA_CODIGO='-1';
            }
            //CDPJ
            this.aggrid.refreshaggridindex(this.encpedService.detpedidosarray[this.indicador], this.defaultPedidoDetalle.objeto, indi);
            this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultPedidoDetalle.objeto].setFocusedCell(
              indi + 1, 'DETPED_CODIGO');
          }
        }
        console.log(linea);
        this.ivaDescBoolean = true;
      } else if (this.encpedService.detpedidosarray[this.indicador].length !== 0 || this.tipodetalle !== '') {
        // if (this.encpedService.detpedidosarray[this.indicador].length !== 0 || this.detallePedidoSeleccionado.DETPED_TIPODET !== '') {
          const cenodigo = await this.encpedService.getbodcencodigoprom(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].BOD_CODIGO);//CDPJ
        const nuevodetPedido = {
          ENCPED_NUMERO: '',
          COM_CODIGO: '01',
          LINEA: Number(linea + 1),
          DETPED_LINEA: 0,
          DETPED_TIPODET: this.tipodetalle,
          DETPED_CODIGO: '',
          DETPED_DESCRIPCION: '',
          DETPED_UNIDAD: '',
          DETPED_CANTIDAD: '',
          DETPED_DESPACHO: '',
          DETPED_PRECIO: '',
          DETPED_DESCUENTO: 0,
          DETPED_TOTAL: 0,
          DETPED_TRIBIVA: '',
          DETPED_IVA: 0,
          DETPED_TRIBICE: '',
          DETPED_ICE: 0,
          DETPED_PORCEICE: '',
          DETPED_LISTA: this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_LISTA,
          DETPED_BASEIVA: 0,
          DETPED_BASEICE: 0,
          DETPED_BASECERO: 0,
          DETPED_ORDEN: 0,
          DETPED_NUMBLO: 0,
          BOD_CODIGO: this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].BOD_CODIGO,
          DETPED_CANTIDADPED: '',
          DETPED_CODALTERNO: '',
          DETPED_PROMOCION: '',
          MAEPROVTA_CODIGO: '',
          DETPED_RELACION: '',
          DETPED_ZONACONTROL: '',
          DETPED_UBICACION: '',
          DETPED_CARACTERISTICAS: '',
          DETPED_POSICION: '',
          ENCPED_IVA: this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_IVA,
          PRECIO: '',
          ART_MULTIUNIDAD: 'N',
          DETPED_LOTE: '',
          CEN_CODIGO:'',
          DETPED_PORIVA:0,
          TARIIVA_CODIGO:''
        };
        //CDPJ
        if (this.intCCPIPED === 1) {
          if (cenodigo === null || cenodigo === undefined) {
            nuevodetPedido.CEN_CODIGO = '';
          } else {
            nuevodetPedido.CEN_CODIGO = cenodigo[0].CEN_CODIGO === null || cenodigo[0].CEN_CODIGO === undefined ? '' : cenodigo[0].CEN_CODIGO;
          }
        } else {
          nuevodetPedido.CEN_CODIGO = '';
        }

        //CDPJ
        if (this.detallePedidoSeleccionado !== undefined && this.detallePedidoSeleccionado !== null) {

          const indi = this.encpedService.detpedidosarray[this.indicador].indexOf(this.detallePedidoSeleccionado);

          if (indi === -1) {
            this.encpedService.detpedidosarray[this.indicador].push(nuevodetPedido);
            this.detallePedidoSeleccionado = nuevodetPedido;
            //CDPJ
            if(this.detallePedidoSeleccionado.DETPED_TIPODET === '*'){
              this.detallePedidoSeleccionado.DETPED_PORIVA=0;
              this.detallePedidoSeleccionado.TARIIVA_CODIGO='-1';
            }
            //CDPJ
            this.aggrid.refreshaggrid(this.encpedService.detpedidosarray[this.indicador], this.defaultPedidoDetalle.objeto);
            this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultPedidoDetalle.objeto].setFocusedCell(
              this.encpedService.detpedidosarray[this.indicador].length - 1, 'DETPED_CODIGO');
          } else {
            this.encpedService.detpedidosarray[this.indicador].splice(indi + 1, 0, nuevodetPedido);
            this.detallePedidoSeleccionado = nuevodetPedido;
             //CDPJ
             if(this.detallePedidoSeleccionado.DETPED_TIPODET === '*'){
              this.detallePedidoSeleccionado.DETPED_PORIVA=0;
              this.detallePedidoSeleccionado.TARIIVA_CODIGO='-1';
            }
            //CDPJ
            // this.aggrid.refreshaggrid(this.encpedService.detpedidosarray[this.indicador], this.defaultPedidoDetalle.objeto);
            this.aggrid.refreshaggridindex(this.encpedService.detpedidosarray[this.indicador], this.defaultPedidoDetalle.objeto, indi);
            this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultPedidoDetalle.objeto].setFocusedCell(
              indi + 1, 'DETPED_CODIGO');
          }
        }
        console.log(linea);
        this.ivaDescBoolean = true;
      }
    }
    } else {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      this.message.add({
        key: 'pedido',
        severity: 'error',
        summary: 'Inserción de detalle del pedido',
        detail: 'Primero ingrese un cliente'
      });
      document.getElementById('cliente').focus();
    }
  }

  confirmEliminarDetPed() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el registro seleccionado',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'pedidoConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.eliminardetPed();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  async eliminardetPed() {
    if (this.detallePedidoSeleccionado !== undefined) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      if (Number(this.detallePedidoSeleccionado.DETPED_DESPACHO) > 0) {
        return;
      }

      if (this.detallePedidoSeleccionado.DETPED_LINEA === 0) {
        this.encpedService.detpedidosarray[this.indicador] = this.encpedService.detpedidosarray[this.indicador].filter((val, j) => j !==
          this.encpedService.detpedidosarray[this.indicador].indexOf(this.detallePedidoSeleccionado));
        this.aggrid.refreshaggrid(this.encpedService.detpedidosarray[this.indicador], this.defaultPedidoDetalle.objeto);
        this.encpedService.calcularTotal();
      } else {
        this.spin=true;
        this.encpedService.eliminarDetPed(this.detallePedidoSeleccionado).subscribe(() => {
          this.encpedService.detpedidosarray[this.indicador] = this.encpedService.detpedidosarray[this.indicador].filter((val, j) => j !==
            this.encpedService.detpedidosarray[this.indicador].indexOf(this.detallePedidoSeleccionado));
          this.aggrid.refreshaggrid(this.encpedService.detpedidosarray[this.indicador], this.defaultPedidoDetalle.objeto);
          this.auditoriagral.registrarAuditoria('VEN_DETPED', String(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO+'/'+this.detallePedidoSeleccionado.DETPED_CODIGO), 'E', //GSRF
            '', '01', '', '', '', '').subscribe(() => {
            });
          this.encpedService.calcularTotal();
        });
        //CDPJ
        
        this.aggrid.refreshaggrid(this.encpedService.detpedidosarray[this.indicador], this.defaultPedidoDetalle.objeto);
        await this.manejarSenales('Guardar')
        //CDPJ
      }
    }
  }

  cancelarDetPed() {

    this.btnNuevoGrid = false;
    this.btnGuardarGrid = true;

    if (this.detallePedidoSeleccionado.DETPED_CODIGO === '') {

      this.encpedService.detpedidosarray[this.indicador].splice(this.encpedService.detpedidosarray[this.indicador].findIndex(
        ped => ped.DETPED_LINEA === this.detallePedidoSeleccionado.DETPED_LINEA && ped.LINEA === this.detallePedidoSeleccionado.LINEA),
        1);
    }

    this.aggrid.refreshColumnDefs();
    this.aggrid.refreshaggrid(this.encpedService.detpedidosarray[this.indicador], this.defaultPedidoDetalle.objeto);
    this.encpedService.calcularTotal();

    /* if (this.detallePedidoSeleccionado.DETPED_LINEA === 0) {
       
   
       this.btnNuevoGrid = false;
       this.btnGuardarGrid = true;
       this.encpedService.detpedidosarray[this.indicador] = this.encpedService.detpedidosarray[this.indicador].filter((val, j) => j !==
         this.encpedService.detpedidosarray[this.indicador].indexOf(this.detallePedidoSeleccionado));
       this.detallePedidoSeleccionado = {};
       
     } else if (this.detallePedidoSeleccionado.DETPED_LINEA !== 0) {
       console.log('Ingresa', this.detallePedidoSeleccionado.DETPED_CODIGO);
       this.btnNuevoGrid = false;
       this.btnGuardarGrid = true;
       this.encpedService.getDetPedidos(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO).subscribe((res) => {
         this.encpedService.detpedidosarray[this.indicador] = res;
         this.encpedService.detpedidosarray[this.indicador].map((detalle) => {
           if (detalle.DETPED_LINEA === this.detallePedidoSeleccionado.DETPED_LINEA) {
             this.detallePedidoSeleccionadoAux = detalle;
           }
         });
         this.detallePedidoSeleccionado = this.detallePedidoSeleccionadoAux;
         const opcPedido = [];
         const opcLista = [];
         opcPedido.push(this.detallePedidoSeleccionado.DETPED_UNIDAD);
         this.columnDefsPedidoDetalle[5].cellEditorParams = {
           values: opcPedido
         };
         this.aggrid.refreshColumnDefs();
         this.utilitariosService.getListaPrecioArt(this.detallePedidoSeleccionado.DETPED_CODIGO).subscribe((res1) => {
           res1.map((ped) => {
             opcLista.push(ped.ARTPRE_CODIGO);
           });
           this.columnDefsPedidoDetalle[12].cellEditorParams = {
             values: opcLista
           };
           this.aggrid.refreshColumnDefs();
         });
         this.aggrid.refreshaggrid(this.encpedService.detpedidosarray[this.indicador], this.defaultPedidoDetalle.objeto);
         this.encpedService.calcularTotal();
       });
     }*/
  }

  cambio(cambio) {
    this.encpedService.cotizacionBooleanarray[this.indicador] = this.cotizacionBoolean;
    if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador] !== undefined) {
      if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO === 'PENDIENTE') {
        this.btnGuardar = false;
        this.btnRegresar = false;
        this.btnNuevo = true;
        this.btnNuevoGrid = false;
        this.botonUltimo = true;
        this.botonAnterior = true;
        this.botonSiguiente = true;
        this.botonPrimero = true;
      }
    }
    this.disabledEstadisticas = true;
  }

  buscarPedidos() {
    this.opcion = 'BUSCAR';
    this.types = [
      { label: 'NÚMERO', value: 'ENCPED_NUMERO' },
      { label: 'COMPROBANTE', value: 'ENCPED_REFERENCIA' },
      { label: 'FEC. EMISIÓN', value: 'ENCPED_FECHAEMISION' },
      { label: 'FEC. ENTREGA', value: 'ENCPED_FECHAENTREGA' },
      { label: 'ESTADO', value: 'ENCPED_ESTADO' },
      { label: 'NOMBRE CLIENTE', value: 'CLI_NOMBRE' },
      { label: 'COD CLIENTE', value: 'CLI_CODIGO' },
      { label: 'COD_VENDEDOR', value: 'VEN_CODIGO' },
      { label: 'OBSERVACIÓN', value: 'ENCPED_OBSERVACION' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_ENCPED';
    this.consulta = 'ENCPED_NUMERO, ENCPED_REFERENCIA, ENCPED_FECHAEMISION, ENCPED_FECHAENTREGA,' +
      'ENCPED_ESTADO, CLI_CODIGO, VEN_CODIGO, ENCPED_OBSERVACION, VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO, \'01\') AS CLI_NOMBRE ';
    this.where='ENCPED_NUMERO IS NOT NULL ORDER BY ENCPED_FECHAEMISION DESC';
    this.busquedaSer.busquedaVenEncped3().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.arregloCons.map((registro) => {
        if (registro.ENCPED_FECHAEMISION !== null) {
          registro.ENCPED_FECHAEMISION = this.datePipe.transform(registro.ENCPED_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          registro.ENCPED_FECHAEMISION = '';
        }
        if (registro.ENCPED_FECHAENTREGA !== null) {
          registro.ENCPED_FECHAENTREGA = this.datePipe.transform(registro.ENCPED_FECHAENTREGA, 'dd/MM/yyyy');
        } else {
          registro.ENCPED_FECHAENTREGA = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });
  }

  manejarSeleccion(opcion) {
    if (this.opcion === 'numeracion') {
      let strNumActual = "";
      let strNemonico = "";
      let strSerie = "";
      if (opcion.NUM_SECACTUAL.length > 0) {
        strNumActual = opcion.NUM_SECACTUAL;
        this.GRUPO = opcion.NUM_CLASE;
        strNemonico = opcion.NUM_NEMONICO;
        this.globalNemonico = strNemonico;
        strSerie = opcion.NUM_SERIE;
        this.globalSerie = strSerie;
        //GSRF
        let condiciog = Number(this.encpedService.getConfiguraciones('GUANUMPED'));
        console.log(condiciog);
        if (condiciog === 0) {
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_REFERENCIA = strNemonico + "" + strSerie + "" + strNumActual;
        } else {
          this.ped_refrencia = strNemonico + "" + strSerie + "" + strNumActual;
        }
        //GSRF
        //CDPJ
        if(opcion.NUM_DOCREF === 'COT'){
          this.cotizacionBoolean=true;
          this.docref='COT'
        }else{
          this.cotizacionBoolean=false;
          this.docref=''
        }
        this.nemonico=strNemonico;
        this.serie=strSerie;
        //CDPJ
      } else {
        this.message.add({
          key: 'pedido',
          severity: 'error',
          summary: 'Información',
          detail: 'Debe escoger una numeración'
        });
      }
    }
    if (this.opcion === 'BUSCAR') {
      this.encpedService.getEncPedidos().subscribe((res) => {
        this.encabezadosPedidos = res;
        this.encabezadosPedidos.map((enca) => {
          if (enca.ENCPED_NUMERO === opcion.ENCPED_NUMERO) {
            this.indice = this.encabezadosPedidos.indexOf(enca);
          }
        });
        if (this.indice === this.encabezadosPedidos.length - 1) {
          this.botonUltimo = true;
          this.botonSiguiente = true;
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador] = this.encabezadosPedidos[this.indice];
          this.obtenerDatos();
        } else if (this.indice === 0) {
          this.botonPrimero = true;
          this.botonAnterior = true;
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador] = this.encabezadosPedidos[this.indice];
          this.obtenerDatos();
        } else {
          this.botonPrimero = false;
          this.botonAnterior = false;
          this.botonSiguiente = false;
          this.botonUltimo = false;
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador] = this.encabezadosPedidos[this.indice];
          this.obtenerDatos();
        }
        this.displayDialogBusquedaFast = false;
      });
      // this.encabezadosPedidos.map((enca) => {
      //   if (enca.ENCPED_NUMERO === opcion.ENCPED_NUMERO) {
      //     this.indice = this.encabezadosPedidos.indexOf(enca);
      //     console.log(enca.ENCPED_NUMERO);
      //     console.log(opcion.ENCPED_NUMERO);
      //     console.log(enca.ENCPED_ESTADO);
      //     console.log(opcion.ENCPED_ESTADO);
      //   }
      // });
      // if (this.indice === this.encabezadosPedidos.length - 1) {
      //   this.botonUltimo = true;
      //   this.botonSiguiente = true;
      //   this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador] = this.encabezadosPedidos[this.indice];
      //   this.obtenerDatos();
      // } else if (this.indice === 0) {
      //   this.botonPrimero = true;
      //   this.botonAnterior = true;
      //   this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador] = this.encabezadosPedidos[this.indice];
      //   this.obtenerDatos();
      // } else {
      //   this.botonPrimero = false;
      //   this.botonAnterior = false;
      //   this.botonSiguiente = false;
      //   this.botonUltimo = false;
      //   this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador] = this.encabezadosPedidos[this.indice];
      //   this.obtenerDatos();
      // }
      // this.displayDialogBusquedaFast = false;
    } else if (this.opcion === 'BUSQUEDACLI') {
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO = opcion.CLI_CODIGO;
      if (opcion.CLI_LISTA !== null && opcion.CLI_LISTA !== undefined) {
        this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_LISTA = opcion.CLI_LISTA;
      }
      if (opcion.CLI_DESCUENTO !== null) {
        this.dscClienteActual = opcion.CLI_DESCUENTO;
      } else if (opcion.CLI_DESCUENTO === null) {
        this.dscClienteActual = 0;
      }
      if (opcion.CLI_DESCUENTOLIM !== null) {
        this.maxDscto = opcion.CLI_DESCUENTOLIM;
      } else if (opcion.CLI_DESCUENTOLIM === null) {
        this.maxDscto = 0;
      }
      this.nombreCliente = opcion.CLI_NOMBRE;
      if (opcion.CLI_IVA !== undefined) {
        if (opcion.CLI_IVA === null) {
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_IVA = '0';
        } else {
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_IVA = opcion.CLI_IVA;
        }
      }
      if (opcion.VEN_CODIGO !== null) {
        this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].VEN_CODIGO = opcion.VEN_CODIGO;
      } else {
        this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].VEN_CODIGO = '';
      }
      if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].VEN_CODIGO !== '') {
        this.encpedService.obtenerNombreVen(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].VEN_CODIGO).subscribe((res2) => {
          this.nombreVendedor = res2[0].VEN_NOMBRE;
        });
      }
      this.btnNuevoGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.botonPrimero = true;
      this.botonSiguiente = true;
      this.botonAnterior = true;
      this.botonUltimo = true;
      document.getElementById('refCliente').focus();
    } else if (this.opcion === 'BUSQUEDAVEN') {
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].VEN_CODIGO = opcion.VEN_CODIGO;
      if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].VEN_CODIGO !== '') {
        this.encpedService.obtenerNombreVen(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].VEN_CODIGO).subscribe((res2) => {
          this.nombreVendedor = res2[0].VEN_NOMBRE;
        });
      }
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.botonPrimero = true;
      this.botonSiguiente = true;
      this.botonAnterior = true;
      this.botonUltimo = true;
      document.getElementById('observacion').focus();
    } else if (this.opcion === 'BUSQUEDABOD') {
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].BOD_CODIGO = opcion.BOD_CODIGO;
      this.nuevoDetallePed();
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.botonPrimero = true;
      this.botonSiguiente = true;
      this.botonAnterior = true;
      this.botonUltimo = true;
    }
    /////////////////////vehiculo///////////
    if (this.opcion === 'verimagen') {
      this.verimagen(opcion.detallePedidoSeleccionado.DETPED_CODIGO);
    }
    this.displayDialogBusquedaFast = false;
  }

  cambiarFoco(evento) {
    if (evento.target.id === 'comprobante') {
      document.getElementById('cliente').focus();
    } else if (evento.target.id === 'cliente') {
      this.busquedaSer.encontrarRegistro44(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO).subscribe((res) => {
        if (res !== null) {
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_NOMBRE = res[0].CLI_NOMBRE;
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO = res[0].CLI_CODIGO;
          if (res[0].CLI_LISTA !== null && res[0].CLI_LISTA !== undefined) {
            this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_LISTA = res[0].CLI_LISTA;
          }
          if (res[0].CLI_DESCUENTO !== null) {
            this.dscClienteActual = res[0].CLI_DESCUENTO;
          } else if (res[0].CLI_DESCUENTO === null) {
            this.dscClienteActual = 0;
          }
          if (res[0].CLI_DESCUENTOLIM !== null) {
            this.maxDscto = res[0].CLI_DESCUENTOLIM;
          } else if (res[0].CLI_DESCUENTOLIM === null) {
            this.maxDscto = 0;
          }

          this.nombreCliente = res[0].CLI_NOMBRE;
          if (res[0].CLI_IVA !== undefined) {
            if (res[0].CLI_IVA === null) {
              this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_IVA = '0';
            } else {
              this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_IVA = res[0].CLI_IVA;
            }
          }
          if (res[0].VEN_CODIGO !== null) {
            this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].VEN_CODIGO = res[0].VEN_CODIGO;
          } else {
            this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].VEN_CODIGO = '';
          }
          if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].VEN_CODIGO !== '') {
            this.encpedService.obtenerNombreVen(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].VEN_CODIGO).subscribe((res2) => {
              this.nombreVendedor = res2[0].VEN_NOMBRE;
            });
          }
          this.displayDialogBusquedaFast = false;
          this.btnNuevoGrid = false;
          this.btnBorrarGrid = false;
          this.btnCancelarGrid = false;
          this.btnNuevo = true;
          this.btnGuardar = false;
          this.btnBorrar = false;
          this.botonPrimero = true;
          this.botonSiguiente = true;
          this.botonAnterior = true;
          this.botonUltimo = true;
          document.getElementById('refCliente').focus();
        } else {
          this.abrirClientes();
        }
      });
    } else if (evento.target.id === 'refCliente') {
      document.getElementById('vendedor').focus();
    } else if (evento.target.id === 'vendedor') {
      this.busquedaSer.encontrarRegistro45(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].VEN_CODIGO).subscribe((resven) => {
        if (resven !== null) {
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].VEN_CODIGO = resven[0].VEN_CODIGO;
          if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].VEN_CODIGO !== '') {
            this.encpedService.obtenerNombreVen(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].VEN_CODIGO).subscribe((res2) => {
              this.nombreVendedor = res2[0].VEN_NOMBRE;
            });
          }
          this.displayDialogBusquedaFast = false;
          this.btnNuevo = true;
          this.btnGuardar = false;
          this.btnBorrar = false;
          this.botonPrimero = true;
          this.botonSiguiente = true;
          this.botonAnterior = true;
          this.botonUltimo = true;
          document.getElementById('observacion').focus();
        } else {
          this.abrirVendedor();
        }
      });
    } else if (evento.target.id === 'bodega') {
      this.busquedaSer.encontrarRegistro40(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].BOD_CODIGO).subscribe((resbod) => {
        if (resbod !== null) {
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].BOD_CODIGO = resbod[0].BOD_CODIGO;
          this.displayDialogBusquedaFast = false;
          this.nuevoDetallePed();
          this.btnNuevo = true;
          this.btnGuardar = false;
          this.btnBorrar = false;
          this.botonPrimero = true;
          this.botonSiguiente = true;
          this.botonAnterior = true;
          this.botonUltimo = true;
        } else {
          this.abrirBodega();
        }
      });
    } else if (evento.target.id === 'observacion') {
      document.getElementById('bodega').focus();
    }
  }

  abrirClientes() {
    this.opcion = 'BUSQUEDACLI';
    this.types = [
      { label: 'Código', value: 'CLI_CODIGO' },
      { label: 'Nombre', value: 'CLI_NOMBRE' },
      { label: 'RUCIDE', value: 'CLI_RUCIDE' },
      { label: 'Zona', value: 'ZONA_NOMBRE' },
      { label: 'Vendedor', value: 'VEN_NOMBRE' },
      { label: 'Grupo', value: 'GRU_CODIGO' },
      { label: 'Contacto', value: 'CLI_CONTACTO' },
      { label: 'Placa', value: 'CLI_PLACA' },
      { label: 'Nombreec', value: 'CLI_NOMBREC' },
    ];
    // CAMPOS NECESARIOS BUSQUEDA FAST
    this.busquedacampos = [this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO, '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_MAECLIENTE';
    this.consulta = 'CLI_CODIGO, CLI_NOMBRE, CLI_RUCIDE, CLI_ZONA, VEN_CODIGO, VEN_FNC_NOMBRE_VENDEDOR(VEN_CODIGO) AS' +
      ' VEN_NOMBRE, GRU_CODIGO, CLI_CONTACTO, CLI_PLACA, CLI_NOMBREC, VEN_FNC_OBTENER_ZONACLI(CLI_ZONA) AS ZONA_NOMBRE';
    this.where='';
    this.busquedaSer.busquedaVenMaeCliente6(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  abrirVendedor() {
    this.opcion = 'BUSQUEDAVEN';
    this.types = [
      { label: 'Codigo', value: 'VEN_CODIGO' },
      { label: 'Nombre', value: 'VEN_NOMBRE' }
    ];
    this.busquedacampos = [this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].VEN_CODIGO, ''];
    this.tabla = 'ven_maevendedor';
    this.consulta = '*';
    this.where='';
    this.busquedaSer.busquedaVenMaeVendedor2(this.types[0].value + ' LIKE \'%' +
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].VEN_CODIGO + '%\'').subscribe((datos: any[]) => {
        this.arregloCons = datos;
        this.displayDialogBusquedaFast = true;
      });
  }

  abrirBodega() {
    this.opcion = 'BUSQUEDABOD';
    this.types = [
      { label: 'Código', value: 'BOD_CODIGO' },
      { label: 'Nombre', value: 'BOD_NOMBRE' }
    ];
    this.busquedacampos = [this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].BOD_CODIGO, ''];
    this.tabla = 'inv_maebodega';
    this.consulta = '*';
    this.where='';
    this.busquedaSer.busquedaInvMaeBodega1(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].BOD_CODIGO).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  comprobarValor() {
    if (this.booleanValor === false) {
      this.valorDscPorcentaje = Number(this.valorDscValor * 100) / Number(
        this.init.quitarComas(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_TOTALNETO));
      this.valorDscPorcentaje = Number(this.valorDscPorcentaje.toFixed(2));
      this.valorInvalidoV = this.valorDscValor > Number(this.init.quitarComas(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_TOTALNETO));
    } else if (this.booleanPorcentaje === false) {
      this.valorDscValor = Number(this.valorDscPorcentaje * Number(this.init.quitarComas(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_TOTALNETO))) / 100;
      this.valorDscValor = Number(this.valorDscValor.toFixed(2));
      this.valorInvalidoP = this.valorDscPorcentaje > 100;
    }
  }

  cambioDescuento() {
    if (this.dscSeleccionado === 'dscValor') {
      this.booleanValor = false;
      this.booleanPorcentaje = true;
    } else if (this.dscSeleccionado === 'dscPorcentaje') {
      this.booleanPorcentaje = false;
      this.booleanValor = true;
    }
  }

  aplicarDescuento() {
    if (this.valorDscPorcentaje > 100) {
      this.displayDialogDescuento = false;
      return this.message.add({
        key: 'pedido',
        severity: 'error',
        summary: 'Descuento',
        detail: 'El descuento no puede ser mayor al 100%',

      });
    }

    const aux = (this.booleanValor) ? this.encpedService.calculaDscto(this.valorDscValor,
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_TOTALNETO, this.booleanValor) : this.valorDscValor;

    let tipo = '';

    if (this.booleanValor === false) {

      if (this.maxDscto < (this.valorDscValor * 100 / Number(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_TOTAL))) {
        // if (this.dscClienteActual < (this.valorDscValor * 100 / Number(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_TOTAL))) {
        tipo = 'valor';
        this.confirmarDescuento(tipo);
      } else {
        tipo = 'valor';
        this.descuentoAplicado(tipo);
      }
    }
    if (this.booleanPorcentaje === false) {
      if (this.maxDscto < this.valorDscPorcentaje) {
        // if (this.dscClienteActual < this.valorDscPorcentaje) {
        tipo = 'porcentaje';
        this.confirmarDescuento(tipo);
      } else {
        tipo = 'porcentaje';
        this.descuentoAplicado(tipo);
      }
    }
  }

  confirmarDescuento(tipo) {
    this.confirmationService.confirm({
      message: 'El cliente seleccionado tiene un limite máximo de descuento de ' + this.maxDscto.toFixed(2) + '%. ' +
        'Desea aplicar un descuento superior ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'pedidoConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.descuentoAplicado(tipo);
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
    // this.confirmationService.confirm({
    //   message: 'Esta seguro que desea aplicar un descuento mayor',
    //   header: 'El cliente tiene un descuento menor',
    //   icon: 'pi pi-exclamation-triangle',
    //   key: 'pedidoConf',
    //   acceptLabel: 'Si',
    //   rejectLabel: 'No',
    //   accept: () => {
    //     this.descuentoAplicado(tipo);
    //   },
    //   reject: () => {
    //   }
    // });
  }

  confirmarClaseNumeracion() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar la clase de numeración',
      header: 'Eliminar Clase de Numeración',
      icon: 'pi pi-exclamation-triangle',
      key: 'pedidoConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.GRUPO = '';
        this.confirmationService.close();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  confirmarAnular() {
    this.confirmationService.confirm({
      message: 'Realmente desea anular el pedido actual',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'pedidoConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.anularPedido();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  anularPedido() {

    if (this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador] !== undefined) {
      let numDesp = 0;
      for (let fila = 0; fila < this.encpedService.detpedidosarray[this.indicador].length; fila++) {
        const item = this.encpedService.detpedidosarray[this.indicador][fila];
        if (Number(item.DETPED_DESPACHO) > 0) {
          numDesp++;
        }

      }
      if (numDesp > 0) {
        return this.message.add({
          key: 'pedido',
          severity: 'error',
          summary: 'Error',
          detail: 'No es posible anular el pedido debido a que el mismo ya se encuentra despachado'
        });
      }
    }
    this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO = 'ANULADO';
    this.encpedService.anularPedido(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador]).subscribe(() => {
      this.message.add({
        key: 'pedido',
        severity: 'success',
        summary: 'Anular Pedido',
        detail: 'El pedido fue anulado'
      });
    });
      //CDPJ
      this.auditoriagral.registrarAuditoria('VEN_ENCPED', String(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO)
      , 'AN', '', '01', '', '', '', '').subscribe(() => {
      });
//CDPJ
    document.getElementById('divPrincipal').style.pointerEvents = 'none';
    // document.getElementById('divPrincipal').style.opacity = '1.0';
    // document.getElementById('divPrincipal2').style.pointerEvents = 'none';
    // document.getElementById('divPrincipal2').style.opacity = '1.0';
    this.aggrid.bloquearGrid();

    this.btnNuevoGrid = true;
    this.btnGuardarGrid = true;
    this.btnCancelarGrid = true;
    this.btnBorrarGrid = true;
    this.btnImagenGrid = true;
    this.btnBorrar = true;
    this.btnGuardar = true;
    this.btnAnular = true;
  }

  descuentoAplicado(tipo) {

    this.displayDialogDescuento = false;
    this.btnGuardar = false;
    this.btnNuevo = true;
    if (tipo === 'valor') {
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORDES = this.valorDscValor;
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_PORCEDES = (
        this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORDES
        * 100) / Number(this.init.quitarComas(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_TOTALNETO));
      this.valorDscPorcentaje = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_PORCEDES;
      this.encpedService.dblPorcDescGlbPedarray[this.indicador] = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_PORCEDES;
      this.encpedService.dblValorDescGlbPedarray[this.indicador] = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORDES;

      this.encpedService.calcularTotal();
    } else if (tipo === 'porcentaje') {
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_PORCEDES = this.valorDscPorcentaje;
      this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORDES = Number(
        Number(this.init.quitarComas(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_TOTALNETO)) * this.valorDscPorcentaje / 100);
      this.valorDscValor = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORDES;
      this.encpedService.dblPorcDescGlbPedarray[this.indicador] = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_PORCEDES;
      this.encpedService.dblValorDescGlbPedarray[this.indicador] = this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_VALORDES;

      this.encpedService.calcularTotal();

    }
  }

  acciones(valor) {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 0; i < elemento.length; i++) {
        elemento[i].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 0; i < elemento.length; i++) {
        elemento[i].className = 'layout-config';
      }
    }
  }


  closeSidebar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }

  abrirClave() {
    this.closeSidebar();
    this.claveDialog = true;
  }

  comprobarClave() {
    this.encpedService.obtenerClave().subscribe((clave) => {
      let claveReal;
      claveReal = clave[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        this.abrirestadoespecialpedido();
      } else if (clave !== this.claveAuth) {
        this.message.add({
          key: 'pedido',
          severity: 'error',
          summary: 'Clave Autorización',
          detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
        });
      }
    });
  }

  salirEstPed() {
    this.estadopedDialog = false;

  }

  guardarEstPed() {
    if (this.estadoValor !== '') {
      this.encpedService.insertarEstadoPedido(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador], this.estadoValor,
        this.razonValor).subscribe(() => {
          this.encpedService.obtenerEstadoPedido(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador]).subscribe((res1) => {
            this.estadoPedido = res1;
          });
        });
    }
  }

  cambiarTipo(valor) {
    this.tipodetalle = valor;
    // console.log(this.encpedService.detpedidosarray[this.indicador]);
    // this.encpedService.detpedidosarray[this.indicador].map((pedido) => {
    //   if (pedido.DETPED_LINEA === this.detallePedidoSeleccionado.DETPED_LINEA) {
    //     pedido.DETPED_TIPODET = valor;
    //     this.detallePedidoSeleccionado.DETPED_TIPODET = valor;
    //   }
    // });
  }

  cambioListPrec(lista) {
    // this.utilitariosService.getListaPrecioArt(this.detallePedidoSeleccionado.DETPED_CODIGO).subscribe
    this.utilitariosService.verificarLista(this.detallePedidoSeleccionado.DETPED_CODIGO).subscribe((res1) => {
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultPedidoDetalle.objeto].setFocusedCell(
        this.encpedService.detpedidosarray[this.indicador].indexOf(this.detallePedidoSeleccionado), 'DETPED_LISTA');
      res1.map((codigoPre) => {
        if (this.encpedService.detpedidosarray[this.indicador][this.encpedService.detpedidosarray[this.indicador].indexOf(
          this.detallePedidoSeleccionado)].DETPED_LISTA === codigoPre.ARTPRE_CODIGO) {
          lista.DETPED_PRECIO = Number(codigoPre.ARTPRE_PRECIO).toFixed(this.decimalesPrecioPed);
          this.calcularDescuento();
          this.encpedService.calcularTotal();
          this.aggrid.refreshColumnDefs();
          this.aggrid.refreshaggrid(this.encpedService.detpedidosarray[this.indicador], this.defaultPedidoDetalle.objeto);
        }
      });

    });
  }
  //GSRF
  async calcularDescuento() {
    try {
      //console.log('antes', itemDet.DETFAC_LISTA);
      const dato = await this.utilitariosService.erArtPreDescuento(this.detallePedidoSeleccionado.DETPED_CODIGO, this.detallePedidoSeleccionado.DETPED_LISTA);
      console.log(dato);
      //console.log('1.0',dato['ARTPRE_DESCUENTO']);
      if (dato !== null) {
        ///console.log('1',dato[0].ARTPRE_DESCUENTO);
        if (dato.length > 0) {
          //console.log('2',dato[0]);
          if (dato[0].ARTPRE_CODIGO !== null) {
            this.detallePedidoSeleccionado.DETPED_DESCUENTO = Number(dato[0].ARTPRE_DESCUENTO);
            console.log('descuento', this.detallePedidoSeleccionado.DETPED_DESCUENTO);
          } else {
            this.detallePedidoSeleccionado.DETPED_DESCUENTO = 0;
          }
        } else {
          this.detallePedidoSeleccionado.DETPED_DESCUENTO = 0;
        }

      } else {
        this.detallePedidoSeleccionado.DETPED_DESCUENTO = 0;
      }
    } catch (err) {

    }
    this.encpedService.calcularTotal();
    this.aggrid.refreshColumnDefs();
    this.aggrid.refreshaggrid(this.encpedService.detpedidosarray[this.indicador], this.defaultPedidoDetalle.objeto);
  }
  //GSRF

  pruebaCambio(param): boolean {
    if (param.DETPED_DESPACHO > 0) {
      return false;
    } else {
      return true;
    }
  }

  mostrarEstadisticas() {
    this.calcularDocumentos();
    this.ventasAcumuladas();
    this.topProductos();
    this.detalleVentas();
    this.pedidosPendientes();
    this.pedidosOk();
    this.primeraFactura();
    this.chequeProtestado();
  }

  async primeraFactura() {
    try {
      const rs6 = await this.venEstadisticasService.primeraFactura(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO);
      this.fechaPrimeraFact = this.datePipe.transform(rs6[0].ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
    } catch (err) {

    }

  }

  async pedidosPendientes() {

    try {
      const rs4 = await this.venEstadisticasService.cantidadPedidos(
        this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO, 'P');
      this.totalPedPendientes = rs4[0].CANTIDAD;
    } catch (err) {

    }
  }

  async pedidosOk() {
    // PEDIDOS ESTADO OK

    try {
      const rs5 = await this.venEstadisticasService.cantidadPedidos(
        this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO, 'OK');
      this.totalPedSatisfechos = rs5[0].CANTIDAD;

    } catch (err) {

    }
  }

  async chequeProtestado() {
    // CHEQUES PROTESTADOS

    try {

      const rs7 = await this.venEstadisticasService.cantidadChProtestados(
        this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO);
      this.totalChProtestados = rs7[0].TOTAL;

    } catch (err) {

    }
  }

  async detalleVentas() {
    // DETALLE FACTURAS
    const detalle: VenDetFacturas[] = [];

    try {
      const rs8 = await this.venEstadisticasService.detalleVentas(
        this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO, 'fecha');

      this.detFacturas = [];
      if (rs8 !== null) {
        for (const item of rs8) {
          const det: VenDetFacturas = {};
          det.ENCFAC_NUMERO = item.ENCFAC_NUMERO;
          det.ENCFAC_FECHAEMISION = this.datePipe.transform(item.ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
          det.DETFAC_CODIGO = item.DETFAC_CODIGO;
          det.DETFAC_DESCRIPCION = item.DETFAC_DESCRIPCION;
          det.DETFAC_CANTIDAD = Number(item.DETFAC_CANTIDAD).toFixed(2);
          det.DETFAC_PRECIO = Number(item.DETFAC_PRECIO).toFixed(2);
          det.DETFAC_TOTAL = Number(item.DETFAC_TOTAL).toFixed(2);
          detalle.push(det);
        }

        this.detFacturas = detalle;
      }
    } catch (err) {

    }

  }

  async calcularDocumentos() {

    try {
      const rs1 = await this.venEstadisticasService.encontrarDocumentos('PORCOBRAR',
        this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO);
      this.totalDocXCobrar = this.init.moneyValidation(Number(rs1[0].SALDO).toFixed(2));
      const rs2 = await this.venEstadisticasService.encontrarDocumentos('VENCIDOS',
        this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO);
      this.totalDocVencidos = this.init.moneyValidation(Number(rs2[0].SALDO).toFixed(2));
      const rs3 = await this.venEstadisticasService.encontrarDocumentos('POSFECHADOS',
        this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO);
      this.totalChPosfechados = this.init.moneyValidation(Number(rs3[0].SALDO).toFixed(2));

      this.totalDocumentos = Number(rs1[0].SALDO) + Number(rs2[0].SALDO) + Number(rs3[0].SALDO);
      this.totalDocXCobrarPorc = this.totalDocumentos > 0 ?
        this.init.moneyValidation(Number(rs1[0].SALDO * 100 / this.totalDocumentos).toFixed(2)) : 0;
      this.totalDocVencidosPorc = this.totalDocumentos > 0 ?
        this.init.moneyValidation(Number(rs2[0].SALDO * 100 / this.totalDocumentos).toFixed(2)) : 0;
      this.totalChPosfechadosPorc = this.totalDocumentos > 0 ?
        this.init.moneyValidation(Number(rs3[0].SALDO * 100 / this.totalDocumentos).toFixed(2)) : 0;

      this.totalDocumentos = this.init.moneyValidation(Number(this.totalDocumentos).toFixed(2));

    } catch (err) {

    }
  }

  async orderByDetFacturas(tipo) {

    this.detFacturas = [];
    const detalle: VenDetFacturas[] = [];
    let rs8;

    try {
      if (tipo === 'fecha') {
        rs8 = await this.venEstadisticasService.detalleVentas(
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO, 'fecha');
      }

      if (tipo === 'producto') {
        rs8 = await this.venEstadisticasService.detalleVentas(
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO, 'producto');
      }

      if (rs8 !== null) {
        for (const item of rs8) {
          const det: VenDetFacturas = {};
          det.ENCFAC_NUMERO = item.ENCFAC_NUMERO;
          det.ENCFAC_FECHAEMISION = this.datePipe.transform(item.ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
          det.DETFAC_CODIGO = item.DETFAC_CODIGO;
          det.DETFAC_DESCRIPCION = item.DETFAC_DESCRIPCION;
          det.DETFAC_CANTIDAD = Number(item.DETFAC_CANTIDAD).toFixed(2);
          det.DETFAC_PRECIO = Number(item.DETFAC_PRECIO).toFixed(2);
          det.DETFAC_TOTAL = Number(item.DETFAC_TOTAL).toFixed(2);
          detalle.push(det);
        }

        this.detFacturas = detalle;
      }

    } catch (err) {

    }
  }

  async ventasAcumuladas() {

    const fechaInicial = new Date();
    fechaInicial.setMonth(fechaInicial.getMonth() - 12);
    fechaInicial.setDate(1);

    const fechaAct = new Date();
    const rangoFechas: any[] = [];

    for (let i = 1; fechaInicial <= fechaAct; i++) {
      rangoFechas.push(this.datePipe.transform(fechaInicial, 'dd/MM/yyyy'));
      fechaInicial.setMonth(fechaInicial.getMonth() + 1);
    }

    try {

      const rs = await this.venEstadisticasService.ventasAcumuladas(
        this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO);


      if (rs !== null) {

        const index1 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[0]);
        const valor1 = index1 >= 0 ? rs[index1].VENTA_TOTAL : 0;
        const index2 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[1]);
        const valor2 = index2 >= 0 ? rs[index2].VENTA_TOTAL : 0;
        const index3 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[2]);
        const valor3 = index3 >= 0 ? rs[index3].VENTA_TOTAL : 0;
        const index4 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[3]);
        const valor4 = index4 >= 0 ? rs[index4].VENTA_TOTAL : 0;
        const index5 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[4]);
        const valor5 = index5 >= 0 ? rs[index5].VENTA_TOTAL : 0;
        const index6 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[5]);
        const valor6 = index6 >= 0 ? rs[index6].VENTA_TOTAL : 0;
        const index7 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[6]);
        const valor7 = index7 >= 0 ? rs[index7].VENTA_TOTAL : 0;
        const index8 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[7]);
        const valor8 = index8 >= 0 ? rs[index8].VENTA_TOTAL : 0;
        const index9 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[8]);
        const valor9 = index9 >= 0 ? rs[index9].VENTA_TOTAL : 0;
        const index10 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[9]);
        const valor10 = index10 >= 0 ? rs[index10].VENTA_TOTAL : 0;
        const index11 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[10]);
        const valor11 = index11 >= 0 ? rs[index11].VENTA_TOTAL : 0;
        const index12 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[11]);
        const valor12 = index12 >= 0 ? rs[index12].VENTA_TOTAL : 0;
        const index13 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[12]);
        const valor13 = index13 >= 0 ? rs[index13].VENTA_TOTAL : 0;

        this.dataVentasAcum = {
          labels: [
            String(rangoFechas[0]).substring(8, 10) + '/' + String(rangoFechas[0]).substring(3, 5),
            String(rangoFechas[1]).substring(8, 10) + '/' + String(rangoFechas[1]).substring(3, 5),
            String(rangoFechas[2]).substring(8, 10) + '/' + String(rangoFechas[2]).substring(3, 5),
            String(rangoFechas[3]).substring(8, 10) + '/' + String(rangoFechas[3]).substring(3, 5),
            String(rangoFechas[4]).substring(8, 10) + '/' + String(rangoFechas[4]).substring(3, 5),
            String(rangoFechas[5]).substring(8, 10) + '/' + String(rangoFechas[5]).substring(3, 5),
            String(rangoFechas[6]).substring(8, 10) + '/' + String(rangoFechas[6]).substring(3, 5),
            String(rangoFechas[7]).substring(8, 10) + '/' + String(rangoFechas[7]).substring(3, 5),
            String(rangoFechas[8]).substring(8, 10) + '/' + String(rangoFechas[8]).substring(3, 5),
            String(rangoFechas[9]).substring(8, 10) + '/' + String(rangoFechas[9]).substring(3, 5),
            String(rangoFechas[10]).substring(8, 10) + '/' + String(rangoFechas[10]).substring(3, 5),
            String(rangoFechas[11]).substring(8, 10) + '/' + String(rangoFechas[11]).substring(3, 5),
            String(rangoFechas[12]).substring(8, 10) + '/' + String(rangoFechas[12]).substring(3, 5),
          ],
          datasets: [{
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [valor1, valor2, valor3, valor4, valor5, valor6, valor7,
              valor8, valor9, valor10, valor11, valor12, valor13]
          }
          ]
        };

      } else {
        this.dataVentasAcum = {
          labels: [
            String(rangoFechas[0]).substring(8, 10) + '/' + String(rangoFechas[0]).substring(3, 5),
            String(rangoFechas[1]).substring(8, 10) + '/' + String(rangoFechas[1]).substring(3, 5),
            String(rangoFechas[2]).substring(8, 10) + '/' + String(rangoFechas[2]).substring(3, 5),
            String(rangoFechas[3]).substring(8, 10) + '/' + String(rangoFechas[3]).substring(3, 5),
            String(rangoFechas[4]).substring(8, 10) + '/' + String(rangoFechas[4]).substring(3, 5),
            String(rangoFechas[5]).substring(8, 10) + '/' + String(rangoFechas[5]).substring(3, 5),
            String(rangoFechas[6]).substring(8, 10) + '/' + String(rangoFechas[6]).substring(3, 5),
            String(rangoFechas[7]).substring(8, 10) + '/' + String(rangoFechas[7]).substring(3, 5),
            String(rangoFechas[8]).substring(8, 10) + '/' + String(rangoFechas[8]).substring(3, 5),
            String(rangoFechas[9]).substring(8, 10) + '/' + String(rangoFechas[9]).substring(3, 5),
            String(rangoFechas[10]).substring(8, 10) + '/' + String(rangoFechas[10]).substring(3, 5),
            String(rangoFechas[11]).substring(8, 10) + '/' + String(rangoFechas[11]).substring(3, 5),
            String(rangoFechas[12]).substring(8, 10) + '/' + String(rangoFechas[12]).substring(3, 5),
          ],
          datasets: [{
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
          ]
        };
      }
    } catch (err) {

    }
  }

  async topProductos() {
    this.detTopProductos = [];
    let totalCantidad = 0;
    let totalPrecio = 0;
    let total = 0;

    const detalle: VenTopProductos[] = [];

    try {
      const rs = await this.venEstadisticasService.topProductos(
        this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO, 'cantidad');
      if (rs !== null) {
        for (const item of rs) {
          const det: VenTopProductos = {};
          det.DETFAC_CODIGO = item.DETFAC_CODIGO;
          det.DETFAC_DESCRIPCION = item.DETFAC_DESCRIPCION;
          det.CANTIDAD_TOTAL = Number(item.CANTIDAD_TOTAL).toFixed(2);
          totalCantidad = totalCantidad + Number(item.CANTIDAD_TOTAL);
          det.DETFAC_PRECIO = Number(item.DETFAC_PRECIO).toFixed(2);
          totalPrecio = totalPrecio + Number(item.DETFAC_PRECIO);
          det.TOTAL = Number(item.TOTAL).toFixed(2);
          total = total + Number(item.TOTAL);
          det.STOCK = Number(item.STOCK).toFixed(2);
          detalle.push(det);
        }
        const totales: VenTopProductos = {};
        totales.DETFAC_CODIGO = '';
        totales.DETFAC_DESCRIPCION = 'TOTAL';
        totales.CANTIDAD_TOTAL = Number(totalCantidad).toFixed(2);
        totales.DETFAC_PRECIO = Number(totalPrecio).toFixed(2);
        totales.TOTAL = Number(total).toFixed(2);
        totales.STOCK = '';
        detalle.push(totales);

        this.detTopProductos = detalle;
      }

    } catch (err) {

    }
  }

  async orderByTopProd(tipo) {
    this.detTopProductos = [];
    let totalCantidad = 0;
    let totalPrecio = 0;
    let total = 0;
    const detalle: VenTopProductos[] = [];
    let rs;

    try {

      if (tipo === 'total') {
        rs = await this.venEstadisticasService.topProductos(
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO, 'total');
      }

      if (tipo === 'cantidad') {
        rs = await this.venEstadisticasService.topProductos(
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO, 'cantidad');
      }

      if (rs !== null) {
        for (const item of rs) {
          const det: VenTopProductos = {};
          det.DETFAC_CODIGO = item.DETFAC_CODIGO;
          det.DETFAC_DESCRIPCION = item.DETFAC_DESCRIPCION;
          det.CANTIDAD_TOTAL = Number(item.CANTIDAD_TOTAL).toFixed(2);
          totalCantidad = totalCantidad + Number(item.CANTIDAD_TOTAL);
          det.DETFAC_PRECIO = Number(item.DETFAC_PRECIO).toFixed(2);
          totalPrecio = totalPrecio + Number(item.DETFAC_PRECIO);
          det.TOTAL = Number(item.TOTAL).toFixed(2);
          total = total + Number(item.TOTAL);
          det.STOCK = Number(item.STOCK).toFixed(2);
          detalle.push(det);
        }
        const totales: VenTopProductos = {};
        totales.DETFAC_CODIGO = '';
        totales.DETFAC_DESCRIPCION = 'TOTAL';
        totales.CANTIDAD_TOTAL = Number(totalCantidad).toFixed(2);
        totales.DETFAC_PRECIO = Number(totalPrecio).toFixed(2);
        totales.TOTAL = Number(total).toFixed(2);
        totales.STOCK = '';
        detalle.push(totales);

        this.detTopProductos = detalle;
      }

    } catch (err) {

    }
  }

  async changeTabs() {
    if (this.index > 0) {
      this.btnNuevo = true;
      this.btnGuardar = true;
      this.btnBorrar = true;
      this.btnRegresar = true;
      this.botonPrimero = true;
      this.botonAnterior = true;
      this.botonSiguiente = true;
      this.botonUltimo = true;
    } else {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.botonPrimero = false;
      this.botonAnterior = false;
      this.botonSiguiente = false;
      this.botonUltimo = false;
    }
    if (this.index === 1) {
      if (this.bolestcta === false) {
        this.cxctmptrnresumen = [];
        this.bolestcta = true;
        this.cxctmptrnresumen = await this.cliService.getInfoEstadoCuenta(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO,
          'pedido');
      }
    }
    if (this.index === 2) {
      if (this.bolsaldo === false) {
        this.cxctrnresumen = [];
        this.bolsaldo = true;
        this.cxctrnresumen = await this.cliService.getInfoSaldos(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].CLI_CODIGO,
          'pedido');
      }
    }
    if (this.index === 3) {
      this.dataVentasAcum = {
        labels: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
        datasets: [{
          label: 'Ventas',
          backgroundColor: '#42A5F5',
          borderColor: '#1E88E5',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
        ]
      };

      this.totalDocumentos = '';
      this.totalChPosfechadosPorc = '';
      this.totalDocVencidosPorc = '';
      this.totalDocXCobrar = '';
      this.totalDocXCobrarPorc = '';
      this.totalChPosfechados = '';
      this.totalDocVencidos = '';
      this.totalPedPendientes = '';
      this.totalPedSatisfechos = '';
      this.fechaPrimeraFact = '';
      this.detTopProductos = [];
      this.detFacturas = [];
      this.totalChProtestados = '';

    }
  }


  salirExistenciasBod() {
    this.displayDialogExistBod = false;
  }

  async obtenerConfigPedVal() {
    this.encpedService.hmpConfiguracion = await this.auditoriagral.obtenerConfiguracionProm();

    this.intACTPEDVAL = Number(this.encpedService.getConfiguraciones('ACTPEDVAL'));

    if (this.intACTPEDVAL === 1) {
      this.columnDefsPedidoDetalle.splice(8, 1);
    }

    if (this.intACTPEDVAL === 0) {
      this.columnDefsPedidoDetalle.splice(9, 1);
    }
    //CDPJ
    this.intCCPIPED = Number(this.encpedService.getConfiguraciones('CCPIPED'))
    console.log('cen', this.intCCPIPED)
    if (this.intCCPIPED === 0 || this.intCCPIPED === null || this.intCCPIPED === undefined || isNaN(this.intCCPIPED) === true) {
      console.log('cen', this.intCCPIPED)
      this.columnDefsPedidoDetalle.splice(13, 1);
    }
    //CDPJ

    this.aggrid.refreshColumnDefs();
  }


  async obtenerNumPedido() {
    let bolNum = false;
    let strNumActual = "";
    let strNemonico = "";
    let strSerie = "";

    if (this.str_NumPed === 'G') { // numeración general (clase de doc)
    } else { // numeración por punto de venta      
      if (this.GRUPO === '') {
        this.busquedanumeracion();
      } else {
        strNumActual = await this.obtenerNumSiguiente();
        strSerie = this.globalSerie;
        strNemonico = this.globalNemonico;
        //GSRF
        let condiciog = Number(this.encpedService.getConfiguraciones('GUANUMPED'));
        console.log(condiciog);
        if (condiciog === 0) {
          this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_REFERENCIA = strNemonico + "" + strSerie + "" + strNumActual;
        } else {
          this.ped_refrencia = strNemonico + "" + strSerie + "" + strNumActual;
        }
        //GSRF
      }
    }

    return bolNum;
  }

  async obtenerNumSiguiente() {
    let strNumActual = "";
    const data: any = await this.encpedService.obtenernumsig(this.GRUPO);
    for (const rs of data) {
      strNumActual = rs.NUM_SECACTUAL;
    }
    return strNumActual;
  }


  busquedanumeracion() {
    this.opcion = 'numeracion';
    this.types = [
      { label: 'Descripción', value: 'NUM_CLASE' },
      { label: 'Nemónico', value: 'NUM_NEMONICO' },
      { label: 'Serie', value: 'NUM_SERIE' },
      { label: 'Actual', value: 'NUM_SECACTUAL' }
    ];
    this.busquedacampos = ['', '', '', ''];
    this.consulta = '*';
    this.tabla = 'SACI_MAENUMERACION';
    this.where='';
    // this.busquedaSer.busquedaSaciMaeNumeracion6().subscribe((datos: any[]) => {
    //   this.arregloCons = datos;
    //   this.displayDialogBusquedaFast = true;
    // });
    if(this.strn_numcot === 'C'){
      this.busquedaSer.busquedaSaciMaeNumeracion6D().subscribe((datos: any[]) => {
        this.arregloCons = datos;
        this.displayDialogBusquedaFast = true;
      });
    }else{
      this.busquedaSer.busquedaSaciMaeNumeracion6().subscribe((datos: any[]) => {
        this.arregloCons = datos;
        this.displayDialogBusquedaFast = true;
      });
    }
  }

  ActualizarNumero(Clase, DocRef, Nemonico, Serie) {
    this.encpedService.actualizarnumeracion(Clase, DocRef, Nemonico, Serie);
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos = false;
    }
  }

  modificariva() {
    this.closeSidebar();
    this.disablediva = false;
  }

  ////////////////////////////vehiculo
  async verimagen(parametro) {
    this.opcion = 'verimagen';
    if (this.detallePedidoSeleccionado.DETPED_CODIGO !== '') {
      const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
      if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
        this.displayDialogDocumentosim = true;
      } else {
        this.messageService.add({
          key: 'pedido',
          severity: 'info',
          summary: 'Información',
          detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
        });
      }

    }
  }

  async estadoactual() {

		/*String strSentencia = "";
		
		strSentencia = "SELECT PEDEST_ESTADO FROM VEN_PEDIDOESTADO WHERE PEDEST_ENCPEDNUMERO = '"
				+ lblCodigo.getText()
				+ "' ORDER BY PEDEST_FECHA DESC, PEDEST_HORA DESC";*/
    const data = await this.encpedService.getestadoactual(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO);
    for (const rs of data) {
      if (rs !== '' && rs !== null && rs !== undefined) {
        console.log(rs);
        // this.estadoespecial = rs.;
      } else {
        this.estadoespecial = "ABIERTA";
      }
    }
  }

  async abrirestadoespecialpedido() {
    this.claveDialog = false;
    await this.verificarEstados();
    await this.mostrarEstadoPedido();
    this.estadopedDialog = true;
    this.claveAuth = '';
  }

  async mostrarEstadoPedido() {
    const data: any = await this.encpedService.obtenerEstadoPedidoAsync(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador]);
    this.estadoPedido = data;
    let rs;
    for (const rs1 of data) {
      rs = rs1;
    }
    if (rs !== undefined) {
      this.estadoPedidoActual = rs.PEDEST_ESTADO;
    }
  }

  async verificarEstados() {
    this.opcionEstado = [];
    const data: any = await this.encpedService.obtenerEstadoPedidoAsync(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador]);
    this.estadoPedido = data;
    let rs;
    for (const rs1 of data) {
      rs = rs1;
    }
    if (rs !== undefined) {
      this.estadoPedidoActual = rs.PEDEST_ESTADO;
    } else {
      this.estadoPedidoActual = "ABIERTA";
      await this.encpedService.insertarEstadoPedidoAsync(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador], 'ABIERTA',
        'El pedido ha sido abierto');
    }

    const data2 = await this.encpedService.obtenerencpedestado(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO);
    for (const rs2 of data2) {
      let est = [];

      est.push("");
      const ejeest = await this.encpedService.obtenerestadosespeciales(this.estadoPedidoActual);


      for (const rsest of ejeest) {
        est.push(rsest.REF_NOMBRE);
      }

      for (let a = 0; a < est.length; a++) {
        this.opcionEstado.push({ label: est[a], value: est[a] });
      }

      if (rs2.ENCPED_ESTADO === "A") {

        /*String strSentenciaval = "";
        ResultSet rsval = null;
        int val = 0;
        strSentenciaval = "SELECT REF_VALOR FROM SACI_MAEREFERENCIA WHERE REF_TIPO='ESTPED'"
          + "AND REF_NOMBRE='"
          + lblEstadoActual.getText() + "'";
        EjecutarSP ejeval = new EjecutarSP();
        ejeval = new EjecutarSP();
        try {
          rsval = ejeval.EjecutaSentencia(strSentenciaval);
          if (rsval.next()) {
            val = rsval.getInt(1);
          }
        
        if (val == 4) {
          String strSentencianom = "";
          ResultSet rsnom = null;
          strSentencianom = "SELECT REF_NOMBRE FROM SACI_MAEREFERENCIA WHERE REF_TIPO='ESTPED'"
            + "AND REF_VALOR='" + (val - 1) + "'";
          EjecutarSP ejenom = new EjecutarSP();
          ejenom = new EjecutarSP();
          try {
            rsnom = ejenom
              .EjecutaSentencia(strSentencianom);
            if (rsnom.next()) {
              cmbEstado.setItem(1, rsnom.getString(1));
            }
          } catch (SQLException e) {
            ErrorDialog.openError(shell, "Error",
              "Error en la consulta", Utilidades
                .createStatus(e));
            e.printStackTrace();
            eje.cierraResultSet();
            UtilidadesConexion.liberarResultset(rs);
          } finally {
            UtilidadesConexion.liberarResultset(rs);
            eje.cierraResultSet();
          }
        }*/
      }
    }

  }

  ////GSRF
  async cambioestado() {
    this.codigogre = '';
    this.numerogre = '';
    this.fechagre = '<--- Sin Guias de Remisión --->';
    this.cligre = '';
    this.totgre = '';
    this.codigofac = '';
    this.fechafac = '';
    this.clifac = '<--- Sin Facturas --->';
    this.clinomfac = '';
    this.totfac = '';
    const data = await this.encpedService.obtenerguiaremision(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO);
    for (const rs of data) {
      this.codigogre = rs.ENCGRE_CODIGO === null ? '' : rs.ENCGRE_CODIGO;
      this.numerogre = rs.ENCGRE_NUMERO === null ? '' : rs.ENCGRE_NUMERO;
      this.fechagre = rs.ENCGRE_FECHAEMISION === null ? '' : this.datePipe.transform(rs.ENCGRE_FECHAEMISION, 'dd/MM/yyyy');
      this.cligre = rs.CLI_CODIGO === null ? '' : rs.CLI_CODIGO;
      this.totgre = rs.ENCGRE_TOTAL === null ? '' : rs.ENCGRE_TOTAL;
    };

    const data1 = await this.encpedService.obtenerfactura(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO);
    for (const rs1 of data1) {
      this.codigofac = rs1.ENCFAC_NUMERO === null ? '' : rs1.ENCFAC_NUMERO;
      this.fechafac = rs1.ENCFAC_FECHAEMISION === null ? '' : this.datePipe.transform(rs1.ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
      this.clifac = rs1.CLI_CODIGO === null ? '' : rs1.CLI_CODIGO;
      this.clinomfac = rs1.CLI_NOMBRE === null ? '' : rs1.CLI_NOMBRE;
      this.totfac = rs1.ENCFAC_TOTAL === null ? '' : rs1.ENCFAC_TOTAL;
    };
    this.closeSidebar();
    this.displaycambioestado = true;
  }

  async cambiarestadopen() {

    //console.log('+++++++',this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO);
    if(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_ESTADO == 'OK'){
      if (this.fechagre == '<--- Sin Guias de Remisión --->' && this.clifac == '<--- Sin Facturas --->') {
        this.encpedService.verificaEstadoPedidos(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO).subscribe(() => {
        });
        await this.encpedService.verificarActualizarEstPed(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO);
      
        this.displaycambioestado = false;
        this.cancelarEdicionPedido();
        this.cancelarEdicionPedido();
      } else {
        this.displaycambioestado = false;
        this.cancelarEdicionPedido();
        this.cancelarEdicionPedido();
  
        this.messageService.add({
          key: 'pedido',
          severity: 'info',
          summary: 'Información',
          detail: 'No se puede cambiar estado a pendiente por que el pedido tiene guias o facturas'
        });
  
      }
    } else {
         this.encpedService.verificaEstadoPedidos(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO).subscribe(() => {
        });
         await this.encpedService.verificarActualizarEstPed(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO);
        
        this.displaycambioestado = false;
        this.cancelarEdicionPedido();
        this.cancelarEdicionPedido();
      }

    //this.pedidoCancelado();
    this.cancelarEdicionPedido();
  }
  colorearCantidad(existencia) {
    console.log('Existencia ', existencia);
    console.log('Existencia ', existencia);
 if(existencia.DETPED_CODIGO !== ''){
   if (existencia.COLOR === 'SI') {
     //this.encpedService.color = false;
     return 'rgb(255, 98, 98)';
   } else {
     return 'transparent';
   }
 }else{
   return 'transparent';
 }
}
  ////GSRF
  // CDPJ
  cerrarfrmcorreos(){
    this.displayDialogfrmcorreos=false;
  }
  // CDPJ
  async verbasesiva(){
    this.closeSidebar()
    this.basesiva=[]
    const data = await this.encpedService.obtenerbasesiva(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO);
    if(data !== null && data !== undefined){
     this.basesiva=data
     for (const data2 of  this.basesiva){
       data2.TARIIVA_PORCENTAJE=isNaN(Number(data2.TARIIVA_PORCENTAJE))?Number(0).toFixed(2):Number(data2.TARIIVA_PORCENTAJE).toFixed(2)
       data2.IVA_BASE=isNaN(Number(data2.IVA_BASE))?Number(0).toFixed(2):Number(data2.IVA_BASE).toFixed(2)
       data2.IVA_VALOR=isNaN(Number(data2.IVA_VALOR))?Number(0).toFixed(2):Number(data2.IVA_VALOR).toFixed(2)
     }
    }
    this.displaybasesiva=true;
  }
  async actbasesiva(){
    this.spin=true;
    this.closeSidebar();
    try {
      await this.encpedService.actualizabasesiva(this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO);
      this.messageService.add({
        key: 'pedido',
        severity: 'success',
        summary: 'Información',
        detail: 'Bases IVA actualizadas correctamente'
      });
      this.auditoriagral.registrarAuditoria('VEN_ENCPED', this.encpedService.encabezadoPedidoSeleccionadoarray[this.indicador].ENCPED_NUMERO+'/'+'ACCIONES_ACT_VALORES_BASES_IVA', 'A', 
      '', '01', '', '', '', '').subscribe(() => {
      });
      this.spin=false;
    } catch (error) {
      this.messageService.add({
        key: 'pedido',
        severity: 'error',
        summary: 'Información',
        detail: 'Error al actualiza las bases IVA'+ error
      });
      this.spin=false;
    }
    
  }
  //cdpj
}

