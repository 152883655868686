import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from 'src/app/usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class NomencierreperiodoService {

  private way: string;
  public url: string;

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) {
      this.way = sessionStorage.getItem('way');
      this.url = sessionStorage.getItem('url');
     }



  buscarPeriodo(){
    return this.http.post<any[]>(this.way + '/nom/cierreperiodo/eg3r2t3r0a1qwpg', {
      elementos: {
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  funGeneraCierrePeriodo(perCodigo) {
    return this.http.post(this.way + '/nom/cierreperiodo/5icmn7hk37fwi3f', {
      elementos: {
        p_periodo_codigo: perCodigo,
        p_com_codigo: '01',
        p_usuidentificacion: this.usuario.identificacion,

      },
    }, this.confIniciales.httpOptions()).toPromise();
  }



  funValidaExisteRegistroParaPeriodo(lblPERIODO){
  
    return this.http.post<any[]>(this.way + '/nom/cierreperiodo/cprf8oduokcje1c', {
        elementos: {
          LblPERIODO: lblPERIODO
        },
      }, this.confIniciales.httpOptions()).toPromise();
  }

  funGlbActivaPeridioEnProceso(){
    return  this.http.post<any[]>(this.way + '/nom/cierreperiodo/ra1x2sdi41ic28x', {
      
      elementos: {
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
}
