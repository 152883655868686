import { Component, OnInit, ViewChild } from '@angular/core';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { InvtrnreservasService } from 'src/app/inv/invservicios/invtrnreservas.service';
import { ConsultaLotes, ConsultaLotesDesp } from '../veninterfaces/consultalotes';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { SelectItem, MessageService } from 'primeng';
import { InvconsultalotesService } from '../venservicios/invconsultalotes.service';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { DatePipe } from '@angular/common';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';

@Component({
  selector: 'app-invconsultalotes',
  templateUrl: './invconsultalotes.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class InvconsultalotesComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  botonBuscar: boolean;

  txtArtCodigo = '';
  txtArtNombre = '';

  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  defaultColDefDetLotes;
  defaultColDefDetDespacho;
  largo: string;
  indicador: any;

  detalleLotes: ConsultaLotes[] = [];
  detalleDespacho: ConsultaLotesDesp[] = [];
  detalleSeleccionado: ConsultaLotes = {};

  displayDialogBusquedaFast: boolean;
  displayDialogBusquedaFast1: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  condicion: string;
  opcion: string;
  where = '';

  lblArtNombre = '';
  lblNroLote = '';
  selectTab = 0;

  cmbFiltroLote: any[];
  strFiltroLote: any;

  txtValorFiltro = '';

  cmbFiltroDesp: any[];
  strFiltroDesp: any;
  txtValorFiltroDesp = '';
  txtFechaDesp;

  columnDefsDetLotes = [
    { // 3
      headerName: 'RUC', field: 'PRO_CODIGO', width: 125 , editable: false
    },
    { // 3
      headerName: 'Razón Social', field: 'PRO_NOMBRE', width: 225 , editable: false
    },
    { // 3
      headerName: 'F.Emisión', field: 'FECHA_EMISION', width: 100, editable: false,
    },
    { // 3
      headerName: 'Nota Recepción', field: 'ENCNOTREC_NUMERO', width: 120, editable: false,
    },
    { // 3
      headerName: 'Factura', field: 'ENCFACPRO_NUMERO', width: 120,  editable: false,
    },
    { // 3
      headerName: 'Lote', field: 'LOTE_NUMERO', width: 125, editable: false,
    },
    {
      headerName: 'F.Vencimiento', field: 'TRNLOTE_FECHAVENCIMIENTO', width: 100, editable: false,
    },
    { // 3
      headerName: 'Reg.Sanitario', field: 'LOTE_REGSANITARIO', width: 100, editable: false, 
    },
    { // 3
      headerName: 'Cantidad', field: 'TRNLOTE_CANTIDAD', width: 100, editable: false, cellStyle: {textAlign: 'right'}
    },
    { // 3
      headerName: 'Despachado', field: 'DESPACHADO', width: 100, editable: false, cellStyle: {textAlign: 'right'}
    },
    { // 3
      headerName: 'Devoluciones', field: 'DEV_COMPRAS', width: 100, editable: false, cellStyle: {textAlign: 'right'}
    },
    { // 3
      headerName: 'Dev.Clientes', field: 'DEV_VENTAS', width: 100, editable: false, cellStyle: {textAlign: 'right'}
    }
  ];

  columnDefsDetDespacho = [
    { // 3
      headerName: 'RUC', field: 'CLI_CODIGO', width: 125 , editable: false
    },
    { // 3
      headerName: 'Razón Social', field: 'CLI_NOMBRE', width: 225 , editable: false
    },
    { // 3
      headerName: 'F.Emisión', field: 'FECHA_EMISION', width: 100, editable: false,
    },
    { // 3
      headerName: 'Vendedor', field: 'VEN_NOMBRE', width: 120, editable: false,
    },,
    { // 3
      headerName: 'Guía Remisión', field: 'ENCGRE_NUMERO', width: 120, editable: false,
    },
    { // 3
      headerName: 'Factura', field: 'ENCFAC_NUMERO', width: 120,  editable: false,
    },
    { // 3
      headerName: 'Lote', field: 'LOTE_NUMERO', width: 125, editable: false,
    },
    { // 3
      headerName: 'Cantidad', field: 'TRNLOTE_CANTIDAD', width: 100, editable: false, cellStyle: {textAlign: 'right'}
    },
    { // 3
      headerName: 'Elaborado', field: 'ELABORADO', width: 100, editable: false,
    },
    { // 3
      headerName: 'Transportista', field: 'TRANSPORT_CODIGO', width: 120, editable: false,
    },
    { // 3
      headerName: 'Placa', field: 'ENCGRE_PLACATNTR', width: 100, editable: false,
    } ,
    { // 3
      headerName: 'Cooler', field: 'ENCGRE_COOLERSERIAL', width: 100, editable: false,
    }
  ];

  constructor(public consultarloteService: InvconsultalotesService, 
    private busquedaSer: InvbusquedaService, private message: MessageService,
    private datePipe: DatePipe, private init: NuevoComponentService) {
    this.detalleLotes = [];
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    
    this.defaultColDefDetLotes = {
      editable: false,
      width: 80,
      objeto: 'invconslotes'
    };

    this.defaultColDefDetDespacho = {
      editable: false,
      width: 80,
      objeto: 'invconsdespacho'
    };
  }

  ngOnInit(): void {

    this.cmbFiltroLote = [];
    this.cmbFiltroLote.push({ codigo: 0, name: '' },{ codigo: 1, name: 'Lote' }, { codigo: 2, name: 'Proveedor' }, { codigo: 3, name: 'Cliente' });
    this.strFiltroLote = this.cmbFiltroLote[0];

    this.cmbFiltroDesp = [];
    this.cmbFiltroDesp.push({ codigo: 0, name: '' },{ codigo: 1, name: 'Cliente' }, { codigo: 2, name: 'Fecha' }, { codigo: 3, name: 'Vendedor' }, { codigo: 4, name: 'Cooler' });
    this.strFiltroDesp = this.cmbFiltroDesp[0];

    this.listarLotes('T');

  }

  async manejarSenales(valor) {
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }

  }

  cambioDropdown(e) {
    this.txtValorFiltro = '';
    this.detalleLotes = [];

  }

  cambioDropdownDesp(e) {
    this.txtValorFiltroDesp = '';
    this.detalleDespacho = [];

  }

  selecciondetalle(params) {
    if (params.object === undefined) {
      return;
    }
    
    this.detalleSeleccionado = params.object;
  }

  buscarLote(parametro) {
    if (this.strFiltroLote.codigo === 0) {
      this.listarLotes('T');
    }
    if (this.strFiltroLote.codigo === 1) {
      this.listarLotes('L');
    }
    if (this.strFiltroLote.codigo === 2) {
      this.listarLotes('P');
    }
    if (this.strFiltroLote.codigo === 3) {
      this.listarLotes('C');
    }
  }

  buscarDespacho(parametro) {
    console.log(this.strFiltroDesp.codigo );
    if (this.strFiltroDesp.codigo === 0) {
      this.consultarDespacho('I');
    }
    if (this.strFiltroDesp.codigo === 1) {
      this.consultarDespacho('C');
    }
    if (this.strFiltroDesp.codigo === 2) {
      this.consultarDespacho('F');
    }
    if (this.strFiltroDesp.codigo === 3) {
      this.consultarDespacho('V');
    }
    if (this.strFiltroDesp.codigo === 4) {
      this.consultarDespacho('CS');
    }
  }

  manejarSeleccion(valor) {
    
    
  }

  async listarLotes(tipo) {
    this.detalleLotes = [];
    let rs;
    
    try {
      if (tipo === 'T') {
        rs =  await this.consultarloteService.ConsultarLotes(tipo, '', '', '');
      }
      if (tipo === 'L') {
        rs =  await this.consultarloteService.ConsultarLotes(tipo, this.txtValorFiltro, '', '');
      }
      if (tipo === 'P') {
        rs =  await this.consultarloteService.ConsultarLotes(tipo, '', this.txtValorFiltro, '');
      }
      if (tipo === 'C') {
        rs =  await this.consultarloteService.ConsultarLotes(tipo, '', '', this.txtValorFiltro);
      }

      if (rs !== null) {
        rs.map(item => {
          item.FECHA_EMISION = this.datePipe.transform(item.FECHA_EMISION,
            'dd/MM/yyyy');
          item.TRNLOTE_FECHAVENCIMIENTO = this.datePipe.transform(item.TRNLOTE_FECHAVENCIMIENTO,
              'dd/MM/yyyy');
          item.TRNLOTE_CANTIDAD = Number(item.TRNLOTE_CANTIDAD).toFixed(2);
        });
        this.detalleLotes = rs;
      } else {
        this.mensajeAlerta('info', 'Información', 'No existe información de lotes');
      }
    } catch(err) {
      this.mensajeAlerta('error', 'Error', err.error);
    }

  }

  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'invconsulotes',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

  onTab1Change(event) {
    this.selectTab = event.index;

    if (this.selectTab === 0 ) {
      this.strFiltroDesp = this.cmbFiltroDesp[0];
    } 

    if (this.selectTab === 1 ) {
      this.txtValorFiltro = '';
      if (Object.keys(this.detalleSeleccionado).length > 0) {
        this.consultarDespacho('I')
      }
    } 

  }

  async consultarDespacho(tipo) {
    this.detalleDespacho = [];
    let rs;
    
    try {
      if (tipo === 'I') {
        rs =  await this.consultarloteService.ConsultarDespacho(tipo, this.detalleSeleccionado.LOTE_NUMERO, '', '', '', '');
      }
      if (tipo === 'C') {
        rs =  await this.consultarloteService.ConsultarDespacho(tipo, this.detalleSeleccionado.LOTE_NUMERO, this.txtValorFiltroDesp, '', '', '');
      }
      if (tipo === 'F') {
        const fecha = this.datePipe.transform(this.txtFechaDesp, 'dd/MM/yyyy');
        rs =  await this.consultarloteService.ConsultarDespacho(tipo, this.detalleSeleccionado.LOTE_NUMERO, '', fecha, '', '');
      }
      if (tipo === 'V') {
        rs =  await this.consultarloteService.ConsultarDespacho(tipo, this.detalleSeleccionado.LOTE_NUMERO, '', '', this.txtValorFiltroDesp, '');
      }
      if (tipo === 'CS') {
        rs =  await this.consultarloteService.ConsultarDespacho(tipo, this.detalleSeleccionado.LOTE_NUMERO, '', '', '', this.txtValorFiltroDesp);
      }

      console.log(rs);

      if (rs !== null) {
        rs.map(item => {
          item.FECHA_EMISION = this.datePipe.transform(item.FECHA_EMISION,
            'dd/MM/yyyy');
          item.TRNLOTE_CANTIDAD = Number(item.TRNLOTE_CANTIDAD).toFixed(2);
        });
        this.detalleDespacho = rs;
      } else {
        this.mensajeAlerta('info', 'Información', 'No existe información de despachos');
      }
    
    } catch(err) {
      this.mensajeAlerta('error', 'Error', err.error);
    }

  }



}
