<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="Cierre Contable"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarAsientoContable" title="Confirmar Acción"
				 appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 99%">
	<div class="p-col-12">
		<app-invmaebarra [barraBotonesCRUD]="true"
						 [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"
						 [barraBotones5]="true"
						 (signal)="manejarSenales($event)"></app-invmaebarra>
	</div>
</div>
<div class="divgrups">
	<div style="height: 350px">
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-3"></div>
				<div class="p-col-2">
					<span>Desde: </span>
				</div>
				<div class="p-col-2">
					<p-calendar id="fechaInicial" [inputStyle]="{width: '85px', height: '20px'}" dateFormat="dd/mm/yy"
								[(ngModel)]="dateTimeDesde" [disabled]="inputDes"></p-calendar>
				</div>
				<div class="p-col-4"></div>
				<div class="p-col-1">
				</div>
			</div>
		</div>
		<div class="p-col-12" style="margin-top: -15px">
			<div class="p-grid">
				<div class="p-col-3"></div>
				<div class="p-col-2">
					<span>Hasta: </span>
				</div>
				<div class="p-col-2" style="margin-bottom: 20px">
					<p-calendar id="fechafinal" [inputStyle]="{width: '85px', height: '20px'}" dateFormat="dd/mm/yy"
								[(ngModel)]="dateTimeHasta" [disabled]="inputDes"></p-calendar>
				</div>
				<div class="p-col-5"></div>
			</div>
		</div>
		<div class="p-col-12" style="margin-top: -25px">
			<div class="p-grid">
				<div class="p-col-3"></div>
				<div class="p-col-3">
					<div class="p-grid">
						<div class="p-col-5">
							<span>Cuenta Contable:</span>
						</div>
						<div class="p-col-5">
							<label for="CONCODIGO"></label>
							<input class="frm" id="CONCODIGO" type="text" pInputText
								   [(ngModel)]="CONCODIGO"
								   (keydown.enter)="busquedaconcodigo()"
								   autocomplete="off">
						</div>
						<div class="p-col-2">
							<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="busquedaconcodigo()"></button>
						</div>
					</div>
				</div>
				<div class="p-col-3">
					<label for="CONNOMBRE"></label>
					<input class="frm" id="CONNOMBRE" type="text" pInputText disabled
						   [(ngModel)]="CONNOMBRE"
						   autocomplete="off">
				</div>
				<div class="p-col-3"></div>
			</div>
		</div>
		<div class="p-col-12" style="margin-top: -10px; margin-bottom: -10px">
			<div class="p-grid">
				<div class="p-col-5"></div>
				<div class="p-col-2">
					<input type="button" value="Ejecutar" (click)="confirmarAsientoContable()" style="width: 70px">
				</div>
			</div>
		</div>
	</div>
</div>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>
