<p-toast [style]="{marginTop: '30px'}" position="top-center" key="msegMaeNumeraciong"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="dsegNumeraciong" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog> 
<div class="p-grid" style="width: 99%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
						 [botonRegresar]="btnRegresar" [barraBotonesCRUD]="false"  
						 [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true"
													 
						
						 (signal)="manejarSenales($event)"></app-invmaebarra>
	</div>

</div>
<div style="overflow: hidden" class="p-col-12">
	<p-tabView>
		<p-tabPanel header="Numeración por Clase">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="segmaenumeraciongService.segMaeNumeraciong"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefMaeNumeraciong"
							[defaultColDef]="defaultColDefSegMaeNumeraciong"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)=selectMaeSegMaeNumeraciog($event)
							(cambios)="cambioModulo($event)"

			></app-aggridsaci>
		</p-tabPanel>
	</p-tabView>
</div>
