import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {Segmaeautorizacion} from '../seginterfaces/segmaeautorizacion';

@Injectable({
  providedIn: 'root'
})
export class SegmaeautorizacionService {
 
  public way: string;
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, private confIniciales: ConfInicialesService) {
 
    this.way = sessionStorage.getItem('way');
  }
  
  consultaAutorizacion(where) {
    const parametro1 = 'T';
    
    let parametro2 = 'COM_CODIGO = \'01\'';
    if (where != null) {
      parametro2 += ' AND ' + where;
    }
    const parametro3 = '';
    
    return this.http.post<any[]>(this.way + '/seg/autorizacion/662fcf555eee380', {
      elementos: {
        p_opcion: parametro1,
        p_filtro: parametro2,
        p_condicion: parametro3
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  
  actualizar(maeAutorizacion: Segmaeautorizacion) {
    let strSentencia = 'update SEG_MAEAUTORIZACION  ' +
      'set autorizacion_clave = \'' + maeAutorizacion.Clave + '\'' +
      'where autorizacion_codigo = \'' + maeAutorizacion.Código + '\'';
    console.log(strSentencia);
    return this.http.post<any[]>(this.way + '/seg/autorizacion/533be5555eee380', {
        sql: strSentencia,
        elementos: {
          Clave: maeAutorizacion.Clave,
          Codigo: maeAutorizacion.Código
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
}


