<!--<p>
  maepantalla works!
</p>-->
<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="message"></p-toast>
<!--<p-table [columns]="cols" [value]="maepantallas" dataKey="ART_CODIGO" selectionMode="single" [(selection)]="selectedMaePantalla"
  (onRowSelect)="onRowSelect($event)" [paginator]="true" [rows]="15">-->
<p-table [autoLayout]="true" [value]="selectedPantalla" dataKey="ART_CODIGO" editMode="row" [rows]="6"
		 [paginator]="true">
	<ng-template pTemplate="caption">
		Categoria={{prodCATCODIGO}}  Subcategoría={{prodSUBCODIGO}}
	</ng-template>
	<ng-template pTemplate="header">
		<tr>
			<th>Articulo</th>
			<th>Nombre</th>
			<th>Orden</th>
			<th>Peso</th>
			<th>Imagen</th>
			<th></th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
		<tr [pEditableRow]="rowData">
			<td>
				{{rowData.ART_CODIGO}}
			</td>
			<td>
				{{rowData.ART_NOMBRE}}
			</td>
			<td>
				<p-cellEditor>
					<ng-template pTemplate="input">
						<input pInputText type="text" [(ngModel)]="rowData.PANT_ORDEN" required
							   [ngStyle]="{'width':'80%'}">
					</ng-template>
					<ng-template pTemplate="output">
						{{rowData.PANT_ORDEN}}
					</ng-template>
				</p-cellEditor>
			</td>
			<td>
				<p-cellEditor>
					<ng-template pTemplate="input">
						<p-checkbox name="group1" value="" label="Activar" [(ngModel)]="rowData.PANT_PESOSEL"
									binary="rowData.PANT_PESOSEL"></p-checkbox>
					</ng-template>
					<ng-template pTemplate="output">
						<p-checkbox disabled="true" name="group1" value="" label="" binary="rowData.PANT_PESOSEL"
									[(ngModel)]="rowData.PANT_PESOSEL"></p-checkbox>
					</ng-template>
				</p-cellEditor>
			</td>
			<td>
					<p-fileUpload *ngIf="rowData.CHOSE" mode="basic" name="myfile[]" accept="image/*"
								  auto="auto" customUpload="true" (uploadHandler)="onBasicUpload($event)  ">
					</p-fileUpload>
				<img alt="producto" style='display:block; width:75px; height:75px' [attr.src]='rowData.ART_IMAGEN'/>
			</td>
			<td style="text-align:center">
				<button *ngIf="!editing" pButton type="button" pInitEditableRow icon="pi pi-pencil"
						class="ui-button-info"
						(click)="onRowEditInit(rowData)"></button>
				<button *ngIf="editing" pButton type="button" pSaveEditableRow icon="pi pi-check"
						class="ui-button-success"
						style="margin-right: .5em" (click)="onRowEditSave(rowData)"></button>
				<button *ngIf="editing" pButton type="button" pCancelEditableRow icon="pi pi-replay"
						class="ui-button-warning"
						(click)="onRowEditCancel(rowData)" style="margin-right: .5em"></button>
				<button *ngIf="editing" pButton type="button" pCancelEditableRow icon="pi pi-times"
						class="ui-button-danger"
						(click)="onRowEditDelete(rowData)"></button>
			</td>
		</tr>
	</ng-template>
	<ng-template pTemplate="summary" let-rowData>
		<div style="text-align:left">
			<button type="button" pButton icon="fa fa-plus" (click)="showDialogToAdd()" label="Add"></button>
		</div>
	</ng-template>
</p-table>

<!--ADD-->
<p-dialog header="Configuración de Pantalla" [(visible)]="displayDialog" [responsive]="true"
		  [modal]="false"
		  [style]="{width: '500px'}">
	<div class="ui-g ui-fluid" *ngIf="maepantalla">
		<div class="ui-g-12">
			<div class="ui-g-4">
				<label for="CAT_CODIGO">Categoría</label>
			</div>
			<div class="ui-g-8">
				<input [disabled]="true" pInputText id="CAT_CODIGO" [placeholder]="prodCATCODIGO"
					   [(ngModel)]="maepantalla.CAT_CODIGO"/>
			</div>
		</div>
		<div class="ui-g-12">
			<div class="ui-g-4">
				<label for="SUB_CODIGO">Subcategoría</label>
			</div>
			<div class="ui-g-8">
				<input [disabled]="true" pInputText id="SUB_CODIGO" [placeholder]="prodSUBCODIGO"
					   [(ngModel)]="maepantalla.SUB_CODIGO"/>
			</div>
		</div>
		<div class="ui-g-12">
			<div class="ui-g-4">
				<label for="ART_CODIGO">Artículo</label>
			</div>
			<div class="ui-g-6">
				<input *ngIf="!autodialog" pInputText type="text" disabled="true" [(ngModel)]="auxcodigoarticulo">
				<p-autoComplete *ngIf="autodialog" [(ngModel)]="auxcodigoarticulo"
								[suggestions]="filteredArticulosSingle"
								(completeMethod)="filterArticuloSingle($event)" id="ART_CODIGO" field="ART_CODIGO"
								[size]="30"
								placeholder="Codigo" (onSelect)="onSelectArticulo(auxcodigoarticulo)"
								[minLength]="1"></p-autoComplete>
			</div>
			<div class="ui-g-2">
				<button type="button" pButton icon="pi pi-replay" (click)="clean()" label="L"></button>
			</div>
		</div>
		<div class="ui-g-12">
			<div class="ui-g-4">
				<label for="ART_NOMBRE">Nombre del Artículo</label>
			</div>
			<div class="ui-g-8">
				<input *ngIf="!autodialog" pInputText type="text" disabled="true" [(ngModel)]="auxnombrearticulo">
				<p-autoComplete *ngIf="autodialog" [(ngModel)]="auxnombrearticulo"
								[suggestions]="filteredNameArticulosSingleAux"
								(completeMethod)="filterNameArticuloSingle($event)" id="ART_NOMBRE" field="ART_NOMBRE"
								[size]="30"
								placeholder="Nombre" (onSelect)="onSelectName(auxnombrearticulo)"
								[minLength]="1"></p-autoComplete>
			</div>
		</div>
		<div class="ui-g-12">
			<div class="ui-g-4">
				<label for="PANT_ORDEN">Orden</label>
			</div>
			<div class="ui-g-8">
				<input pInputText id="PANT_ORDEN" [(ngModel)]="maepantalla.PANT_ORDEN"/>
			</div>
		</div>
		<div class="ui-g-12">
			<div class="ui-g-4">
				<label for="PANT_PESO">Peso</label>
			</div>
			<div class="ui-g-8">
				<div class="ui-g" style="width:250px;margin-bottom:10px">
					<p-checkbox id="PANT_PESO" name="group1" value="" label="Activar"
								[(ngModel)]="maepantalla.PANT_PESOSEL"
								binary="maepantalla.PANT_PESOSEL"></p-checkbox>
				</div>
			</div>
		</div>
		<div class="ui-g-12">
			<div class="ui-g-4">
				<label for="ART_IMAGEN">Imagen</label>
			</div>
			<div class="ui-g-8">
				<p-fileUpload id="ART_IMAGEN" name="myfile[]" multiple="multiple" accept="image/*"
							  auto="auto" customUpload="true" (uploadHandler)="onBasicUpload($event)"></p-fileUpload>
			</div>
		</div>
	</div>
	<p-footer>
		<div class="ui-dialog-buttonpane ui-helper-clearfix">
			<button type="button" pButton icon="fa fa-close" (click)="delete()" label="Delete"></button>
			<button type="button" pButton icon="fa fa-check" (click)="save()" label="Save"></button>
		</div>
	</p-footer>
</p-dialog>
