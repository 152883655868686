import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Observable} from 'rxjs';
import {Venmaedescuentos} from '../veninterfaces/venmaedescuentos';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';


@Injectable({
  providedIn: 'root'
})
export class VenmaedescuentosService {
  get venmaedescuentos(): Venmaedescuentos[] {
    return this._venmaedescuentos;
  }
  
  set venmaedescuentos(value: Venmaedescuentos[]) {
    this._venmaedescuentos = value;
  }
  
  public url: string;
  public way: string;
  private _venmaedescuentos: Venmaedescuentos[];
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }
  
  getDescuentos(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/descuentos/10775aee33cebf000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  insertarDescuento(descuento: Venmaedescuentos): Observable<any[]> {
    return this.http.post<any>(this.way + '/ventas/descuentos/4afc76fad771a000000', {
      elementos: {
        p_maedscto_codigo: descuento.MAEDSCTO_CODIGO,
        p_com_codigo: '01',
        p_maedscto_nombre: descuento.MAEDSCTO_NOMBRE,
        p_maedscto_tipo: descuento.MAEDSCTO_TIPO,
        p_maedscto_artsvr: descuento.MAEDSCTO_ARTSVR,
        p_maedscto_porcentaje: descuento.MAEDSCTO_PORCENTAJE,
        p_maedscto_desde: descuento.MAEDSCTO_DESDE,
        p_maedscto_hasta: descuento.MAEDSCTO_HASTA
      }
    }, this.confIniciales.httpOptions());
  }
  
  actualizarDescuento(codigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/descuentos/299df2ab2', {
      elementos: {
        p_COM_CODIGO: '01',
        p_MAEDSCTO_CODIGO: codigo
      },
    }, this.confIniciales.httpOptions());
  }
  
  eliminarDescuento(descuento: Venmaedescuentos): Observable<any[]> {
    return this.http.post<any>(this.way + '/ventas/descuentos/7565695d5b6bb80', {
      elementos: {
        p_maedscto_codigo: descuento.MAEDSCTO_CODIGO,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }
}
