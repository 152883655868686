<p-toast [style]="{marginTop: '30px'}" position="top-center" key="funGEjecutaSP"></p-toast>
<div class="p-grid" style="width: 99%">
    <div class="p-col-12">
        <app-invmaebarra [barraBotonesCRUD]="true" [barraBotonesDesplaz]="true" [barraBotones3]="true"
            [barraBotonesAsiCon]="true" [barraBotones5]="true" (signal)="manejarSenales($event)"></app-invmaebarra>
    </div>
</div> 
<div class="divgrups">
    <div class="p-grid">
        <div class="p-col-2">
            <span style="margin-left: 20px;">Tipo Rol:</span>
        </div>
        <div class="p-col-1">
            <input class="frm"  type="text" pInputText style="width: 50%;"
            [(ngModel)]="inputRolUno.codigo"     >
            <button type="button"
                    style="background-image: url(../../../assets/images/iconos/buscar.png);"
                    class="littlebuttons" (click)="buscarRol('rolUno')" ></button>
        </div>
        
        <div class="p-col-5">
            <input class="frm"  type="text" pInputText  style="width: 80%;"
            [(ngModel)]="inputRolUno.nombre"       >
        </div>
        <div class="p-col-3">
            <span>Procesar anticipo de quincena</span>
            
        </div>
        <div class="p-col-1">
            <p-checkbox binary="true"  [(ngModel)]="procAnticipo">
            </p-checkbox>
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-2">
        </div>
        <div class="p-col-1">
            <input class="frm"  type="text" pInputText  style="width: 50%;"
            [(ngModel)]="inputRolDos.codigo"   >
            <button type="button"
                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                class="littlebuttons" (click)="buscarRol('rolDos')" ></button>
        </div>
        
        <div class="p-col-5">
            <input class="frm"  type="text" pInputText  style="width: 80%;"
            [(ngModel)]="inputRolDos.nombre"      >
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-2">
            <span style="margin-left: 20px;">Empleado:</span>
        </div>
        <div class="p-col-2">
            <input class="frm"  type="text" pInputText  
              [(ngModel)]="inputEmpleadoUno.id"     >
        </div>
        <div class="p-col-1">
            <button type="button"
                    style="background-image: url(../../../assets/images/iconos/buscar.png);"
                    class="littlebuttons" (click)="buscarEmpleado('empUno')"></button>
        </div>
        <div class="p-col-6">
            <input class="frm"  type="text" pInputText  
            [(ngModel)]="inputEmpleadoUno.nombre"  >
        </div>
        <div class="p-col-1">
            <span>{{  inputEmpleadoUno.cod !== '' ? inputEmpleadoUno.cod : 'Cod'  }}</span>
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-2">
            <span></span>
        </div>
        <div class="p-col-2">
            <input class="frm"  type="text" pInputText  
            [(ngModel)]="inputEmpleadoDos.id"   >
        </div>
        <div class="p-col-1">
            <button type="button"
                    style="background-image: url(../../../assets/images/iconos/buscar.png);"
                    class="littlebuttons" (click)="buscarEmpleado('empDos')"></button>
        </div>
        <div class="p-col-6">
            <input class="frm"  type="text" pInputText  
                [(ngModel)]="inputEmpleadoDos.nombre"  >
        </div>
        <div class="p-col-1">
            <span>{{  inputEmpleadoDos.cod !== '' ? inputEmpleadoDos.cod : 'Cod'  }}</span>
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-6">
            <span style="font-size: medium;">{{fechasPeriodo}}</span>
        </div>
        <div class="p-col-2">
        </div>
        <div class="p-col-2">
            <button  pButton class="aceptar" style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 49% ;margin-bottom: 5px;" (click)="cancelar()" >Cancelar</button>	
    
        </div>
        <div class="p-col-2">
            <button  pButton class="aceptar" style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 49%;margin-left: 5px;margin-bottom: 5px;" (click)="aceptar()">Aceptar</button>
        </div>
    </div>
</div>


<p-confirmDialog [transitionOptions]="'0ms'" key="confirmAntiQuin" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>
