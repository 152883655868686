<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="venconsulped"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="venconsulpedconf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid">
	<div class="p-col-12">
		<app-invmaebarra [barraBotonesCRUD]="false" [barraBotonesDesplaz]="true" [barraBotones3]="true"
        [barraBotonesAsiCon]="true" [barraBotones5]="true" (signal)="manejarSenales($event)" 
        [botonRegresar]="botonRegresar"    [botonGuardar]="botonGuardar"
		[botonAnular]="btnAnular" [barraBotones3]="true" [barraConEncasil]="false" ></app-invmaebarra>
	</div>
</div>
<p-tabView (onChange)="onTab1Change($event)" [activeIndex]="selectTab">
	<p-tabPanel header="Pedidos Pendientes">
		<app-aggridsaci [width]=""
				[height]="380"
				[enableSorting]="true"
				[enableFilter]="true"
				[rowData]="detalleencped"
				[frameworkComponents]="frameworkComponents"
				[animateRows]="true"
				[rowSelection]="rowSelection"
				[columnDefs]="columnDefsDetLotes"
				[defaultColDef]="defaultColDefDetLotes"
				[rowStyle]="rowStyle"
				[singleClickEdit]="false"
				[botones]="false"
				(selected)="selecciondetalle($event)">
		</app-aggridsaci>
    </p-tabPanel>
</p-tabView>
<p-dialog header="Clave Autorización" [(visible)]="claveDialog" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: 'auto'} ">
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							(keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>


