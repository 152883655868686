<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="message"></p-toast>
<p-scrollPanel [style]="{'width':'100%', 'height':'100%'}">
  <p-accordion (onOpen)="ontabopen($event)" (onClose)="oncloseopen()" [activeIndex]="index">
    <!--<p-accordionTab [selected]="toogleCatCollapsed" header="{{legendCat}}" (click)="toogleCat(toogleCatCollapsed)">-->
    <p-accordionTab [transitionOptions]="'0ms'">
      <p-header>
        <span>{{legendCat}}</span>
      </p-header>
      <div *ngIf="!seleccionoCat" class="centerSaci">
        <button [disabled]="pagada" class="btn-grad" pButton (click)="mostrarSubcats(categorias)" label="{{categorias.CAT_NOMBRE}}"
                *ngFor="let categorias of totalCats;"></button>
      </div>
      <div style="text-align: center">
        <p-progressSpinner *ngIf="spin" [style]="{width: '50px', height: '50px'}"
                           animationDuration=".3s"></p-progressSpinner>
        <div *ngIf="seleccionoCat && !spin && !seleccionoSubCat">
          <button class="button_home" *ngIf="seleccionoCat || seleccionoSubCat" (click)="botonhome()" type="button" pButton
                  icon="pi pi-angle-double-left"></button>
          <button class="btn-grad_sub" pButton (click)="mostrarArticulos(subCats)" label="{{subCats.SUB_NOMBRE}}"
                  *ngFor="let subCats of selectedSubcats"></button>
        </div>
      </div>
      <div *ngIf="seleccionoSubCat && !spin" style="text-align: center">
        <p-progressSpinner *ngIf="spin" [style]="{width: '50px', height: '50px'}"
                           animationDuration=".3s"></p-progressSpinner>
        <div class="text-left">
          <button class="button_home" *ngIf="seleccionoCat || seleccionoSubCat" (click)="botonhome()" type="button" pButton
                  icon="pi pi-angle-double-left"></button>
        </div>
        <div class="p-grid">
          <div class="p-col-3" *ngFor="let articulos of selectedArticulos">
            <p-card (click)="artSeleccionado(articulos)" [style]="{width: '145px', height: '190px'}">
                <p-header>
                <div class="p-grid">
                  <div class="p-col-12">
                    <span class="badge badge-pill badge-primary" style="float: right; font: 12px 'Arial';">${{articulos.ARTPRE_PRECIO}}</span>
                  </div>
                  <div class="p-col-12">
                    <div class="text-center" style="text-align: center">
                      <img [ngStyle]="{'width':'60px', 'height':'60px'}" src="{{articulos.ART_IMAGEN}}" alt="Producto"
                           [title]="'Imagen artículo ' + articulos.ART_NOMBREC ">
                    </div>
                  </div>
                </div>
              </p-header>
              <div>
                {{articulos.ART_NOMBREC}}
              </div>
            </p-card>
          </div>
        </div>
      </div>
    </p-accordionTab>
  </p-accordion>
</p-scrollPanel>
