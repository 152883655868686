import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ComTrndistribucioncc} from '../cominterfaces/comtrndistribucioncc';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class ComtrndistribucionccService {
  
  public way: string;
  
  private _distribucionCC: ComTrndistribucioncc[];
  public hmpConfiguracion: any[];
  public dblValor: number;
  public total: any;
  public decimalesvalorDet: number;//CDPJ
  public decimalesTOTALDetFacPro: number;
  
  private _auxsalto: boolean;
  
  public grids: any[] = [];
  
  get distribucionCC(): ComTrndistribucioncc[] {
    return this._distribucionCC;
  }
  
  set distribucionCC(value: ComTrndistribucioncc[]) {
    this._distribucionCC = value;
  }
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService
  ) {
    this.way = sessionStorage.getItem('way');
  }
  
  InsertarDistribucionCC(item): Observable<any> {
    return this.http.post<any>(this.way+ '/compras/distribucioncc/257e3b7d6cb9da0000', {
      elementos: {
        p_encfacpro_numero: item.ENCFACPRO_NUMERO,
        p_detfacpro_linea: item.DETFACPRO_LINEA,
        p_com_codigo: item.COM_CODIGO,
        p_cen_codigo: item.CEN_CODIGO,
        p_trndis_valor: Number(item.TRNDIS_VALOR),
        p_trndis_porce: Number(item.TRNDIS_PORCE),
        p_con_codigo: item.CON_CODIGO,
        p_trndis_linea: item.TRNDIS_LINEA,
        p_tipgas_codigo: item.TIPGAS_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }
  
  ActualDistribucionCC(item): Observable<any> {
    return this.http.post<any>(this.way+ '/compras/distribucioncc/533be6cb9ddc980', {
      elementos: {
        p_encfacpro_numero: item.ENCFACPRO_NUMERO,
        p_detfacpro_linea: item.DETFACPRO_LINEA,
        p_com_codigo: item.COM_CODIGO,
        p_cen_codigo: item.CEN_CODIGO,
        p_trndis_valor: Number(item.TRNDIS_VALOR),
        p_trndis_porce: Number(item.TRNDIS_PORCE),
        p_con_codigo: item.CON_CODIGO,
        p_trndis_linea: item.TRNDIS_LINEA,
        p_tipgas_codigo: item.TIPGAS_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }
  
  EliminarDistribucionCC(item): Observable<any> {
    return this.http.post<any>(this.way+ '/compras/distribucioncc/756566cb9ddc980', {
      elementos: {
        p_encfacpro_numero: item.ENCFACPRO_NUMERO,
        p_detfacpro_linea: item.DETFACPRO_LINEA,
        p_com_codigo: item.COM_CODIGO,
        p_trndis_linea: item.TRNDIS_LINEA
      }
    }, this.confIniciales.httpOptions());
  }
  
  listarAutomatica(encfacpronumero, detfacprolinea): Observable<any[]> {
    return this.http.post<any>(this.way+ '/compras/distribucioncc/565ceab6af7718', {
      elementos: {
        p_encfacpro_numero: encfacpronumero,
        p_detfacpro_linea: detfacprolinea,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  
  
  listardatos(strencfacpronumero, strdetfacprolinea): Observable<any[]> {
    return this.http.post<any[]>(this.way+ '/compras/distribucioncc/565ceab6d5771c', {
        elementos: {
          encfacpronumero: strencfacpronumero,
          detfacprolinea: strdetfacprolinea
        }
    }, this.confIniciales.httpOptions());
  }

  erContipgas(strConcodigo) {
    return this.http.post(this.way+ '/compras/distribucioncc/3b6cc5fb2cc15c', {
      elementos: {
        CON_CODIGO: strConcodigo
      },
    }, this.confIniciales.httpOptions());
  }
  
  calcular(intCol, distriCCSelected) {
    //console.log(distriCCSelected)
    if (intCol === 'TRNDIS_PORCE') {
      this.calcularValorXPorcentaje(4, distriCCSelected);
    }
    
    if (intCol === 'TRNDIS_VALOR') {
      this.calcularValorXPorcentaje(5, distriCCSelected);//CDPJ ANTES DECIA 4 AHORA 5 
    }
  }
  
  calcularValorXPorcentaje(intCol, distriCCSelected) {
    
    //const intIndiceFila = this.distribucionCC.findIndex(distri =>
      //distri.CLAVE === distriCCSelected.CLAVE);
      let intIndiceFila=0
      if(distriCCSelected.LINEA === 0){
          intIndiceFila = this.distribucionCC.findIndex(distri =>
            distri.CLAVE === distriCCSelected.CLAVE);
      }else{
        intIndiceFila = this.distribucionCC.findIndex(distri =>
          distri.TRNDIS_LINEA === distriCCSelected.TRNDIS_LINEA);
      }
    let dblPorcentaje = 0;
    let dblValorXPorce = 0;
    const dblValor = this.dblValor;
    
    
    let item: ComTrndistribucioncc;
    item = this.distribucionCC[intIndiceFila];
    
    if (intCol === 4) { // de porcentaje a valor
      dblPorcentaje = Number(item.TRNDIS_PORCE) / 100;
      dblValorXPorce = dblValor * dblPorcentaje;
      
      item.TRNDIS_VALOR = dblValorXPorce;
    }
    if (intCol === 5) { // de valor a porcentaje
      
      if (item.TRNDIS_PORCE === 0) {
        dblValorXPorce = Number(item.TRNDIS_VALOR);
        dblPorcentaje = (100 * dblValorXPorce) / dblValor;
        
        item.TRNDIS_PORCE = dblPorcentaje;
      }
    }
    
    
    this.calcularTotal();
  }
  
  calcularTotal() {
    let intIndiceLista = 0;
    let item: ComTrndistribucioncc;
    let dblTotal = 0;
    
    for (intIndiceLista = 0; intIndiceLista < this.distribucionCC.length; intIndiceLista++) {
      item = this.distribucionCC[intIndiceLista];
      dblTotal = dblTotal + Number(item.TRNDIS_VALOR);
    }
    
    this.total = dblTotal.toFixed(this.decimalesTOTALDetFacPro);
    
    for (intIndiceLista = 0; intIndiceLista < this.distribucionCC.length; intIndiceLista++) {
      item = this.distribucionCC[intIndiceLista];
      item.TRNDIS_PORCE = Number(item.TRNDIS_PORCE).toFixed(this.decimalesTOTALDetFacPro);
      item.TRNDIS_VALOR = Number(item.TRNDIS_VALOR).toFixed(this.decimalesvalorDet);
    }
    
  }
}



