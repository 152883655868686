import { Component, OnInit, ViewChild } from '@angular/core';
import { NomempleadosService } from '../nomservicios/nomempleados.service';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { nomIngrFijo } from '../nominterfaces/nomingrfijo';
import { nomEgreFijo } from '../nominterfaces/nomegrefijo';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import { AggridBanComponent } from 'src/app/ban/aggrid-ban/aggrid-ban.component';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { NommaeEmpleado } from '../nominterfaces/nomempleado';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { isNullOrUndefined } from 'util';
import { NgForm } from '@angular/forms';
import {TabViewNav,TabView} from 'primeng/tabview'
import { DatePipe } from '@angular/common';
import { nomVacaciones } from '../nominterfaces/nomvacaciones';
import { nomCargaFami } from '../nominterfaces/nomcargasfam';
import { async } from '@angular/core/testing';

@Component({
  selector: 'app-nomempleado',
  templateUrl: './nomempleado.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NomempleadoComponent implements OnInit {
  @ViewChild('ingrFijo') aggrid: AggridsaciComponent;
  @ViewChild('egreFijo') egraggrid: AggridsaciComponent;
  @ViewChild('CarFam') cfggrid: AggridsaciComponent;
  @ViewChild('vaca') vaca: AggridsaciComponent;
  @ViewChild('tabs') tab: TabView;
  displaydialogadi:boolean;
  displaydatosAdi:boolean;
  displaydialogadiglob:boolean;
  displaydatosadiglob:boolean;
  dataempleado:NommaeEmpleado;
  /* Activar Valores */
  displayDialogActivarValores:boolean;
  claveAuth:string="";
  bolvaloresActi:boolean=false;
  /*NOMBRES*/
  inputcodigo2:string="";
  inputanombre:string="";
  inputapellido:string="";
  /*Botones*/
  btnGuardar: boolean;
  btnNuevo: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  botonAnterior: boolean;
  botonPrimero: boolean;
  botonUltimo: boolean;
  botonSiguiente: boolean;
  btnBuscar: boolean;
  spin=false
  /*Busqueda*/
  selectedNomEmpl:NommaeEmpleado= new NommaeEmpleado;
  displayDialogBusquedaFast:boolean;
  types: SelectItem[];
  busquedacampos = [];
  tabla: string;
  consulta: string;
  arregloCons: any[] = [];
  arrlegoBus = [];
  where: string;
  opcion: string;
  condicion: string;
  visi:boolean;
  /*TABS*/ 
  indexTab=0;
  disabledTab0:boolean=false;
  disabledTab1:boolean=false;
  disabledTab2:boolean=false;
  disabledTab3:boolean=false;
  disabledTab4:boolean=false;
  disabledTab5:boolean=false;
  disabledTab6:boolean=false;
  valortipoid:any;
  cmbtipoid:any[]=[];
  
  /* Licencia*/
  cmbLicen=[{tipo:'', codigo:''},{tipo:'Tipo A', codigo:'A'},{tipo:'Tipo B', codigo:'B'},{tipo:'Tipo F', codigo:'F'},{tipo:'Tipo C', codigo:'C'},
       {tipo:'Tipo A1', codigo:'A1'}, {tipo:'Tipo C1', codigo:'C1'}, {tipo:'Tipo D', codigo:'D'}, {tipo:'Tipo D1', codigo:'D1'},
       {tipo:'Tipo E', codigo:'E'}, {tipo:'Tipo E1', codigo:'E1'}, {tipo:'Tipo G', codigo:'G'} ]
  pLicen:any;
  /*Fecha caducidad */  
    pFecCadu:string="";
  /* Carnet*/
    inpCarnet:string="";
  /*Estado Civil */
  arregloEstCiv:any[];
  displayDialogBusquedaFastEstCiv:boolean;
  inp_estaCiv:string="";
  span_esta_civil:string="";
  /*Correo Empresarial */
  inp_correoEmp:string="";
 

  /*Correo Personal */
  inp_correoPer="";
  /*Celular */
  inp_Cel="";
  /* Nacionalidad */
  cmbNacio:any[]=[];
  valorNacio:any;
  /* Lugar de nacimiento */
  /*Celula Militar */
  inp_CelMil="";
  /*Fecha Nacimiento*/
  pFechaNac:Date;
  /*Sexo*/
  arregloSex =[];
  displayDialogBusquedaFastSex:boolean;
  
  span_sex:string="";
  /*Fecha Ingreso*/
  inpFechaIngreso:string="";
  /*Boolean*/
  chkactivo:boolean=false;
  /*Fecha Contrato*/
  inpFechaCont:string="";
  /*Fecha de Salida*/
  inpFechaSal:string="";
  /* Plazo Contrato */
  valPlazoContrato:any[]=[];
  valPlaContra:any;
  /*Causa Salida*/
  valCausaSalida:any[]=[];
  valCausaSal:any;
  /*Detalle Salida*/
  inputCausaSal:string="";
  /*Departamento */
  cmbDepart:any[]=[];
  valorDepart:any;
  /* Jefe Inm */
  cmbJefInme=[];
  span_JefInme:string;
  displayDialogBusquedaFastJefInme:boolean;
   /* Jefe Ext */
   cmbJefExt=[];
   span_JefExt:string;
   displayDialogBusquedaFastJefExt:boolean;
  /* Centro de Costos*/
  arregloCenCos =[];
  inp_centroCost:string="";
  span_centroCos:string="";
  displayDialogBusquedaFastCenCos :boolean;

  /*Cuenta Contable */
  arregloCuenCont =[];
  inp_cuentaConta:string="";
  displayDialogBusquedaFastCuenCont:boolean;
  /* Cargo Empresa */
  inpCargEmpre:string="";
  displayDialogBusquedaFastCargEmpr:boolean;
  arregloCargEmpr =[];
  span_CarEmpr:string="";
  /* Cargo IESS */
  inpCargaIess:string="";
  displayDialogBusquedaFastCargIess:boolean;
  arregloCargIess =[];
  span_CarIess:string="";
  /* Establecimiento donde trabaja */
  inpEstTra:string="";
  span_EstTrab:string="";
  displayDialogBusquedaFastEstTrab:boolean;
  arregloEstTrab =[];
   /* Tipo Empleado */
   inpTipEmpl:string="";
   span_TipEmpl:string="";
   displayDialogBusquedaFastTipEmpl:boolean;
   arregloTipEmpl =[];
   /* Direccion */
   inpDirecc:string="";
    /* Calle */
   inpCalle:string="";
    /* Casa */
   inpCasa:string="";
   /* Provincia */
   cmbProv:any[]=[];
   valorProv:any;
   /* Ciudad */
   cmbCiud:any[]=[];
   valorCiud:any;
   cuidadbool:boolean; 
   /* Telefono 1 */
   inptelf1:string="";
   /* Telefono 2 */
   inptelf2:string="";
   /* Tipo Rol */
   inpTipoRol:string="";
   arregloTipoRol =[];
   span_TipoRol:string="";
   displayDialogBusquedaFastTipRol :boolean;
   
   /*Otros Datos */
   otrosDatos:boolean;
   /*Con trab resp a su disp */
   cmbConTrabRescDisc:any[]=[];
   valorConTrabRescDisc:any;
   /*Tipo sistema Salario neto*/
   cmbTIPOSISTSALANETO:any[]=[];
   valorTIPOSISTSALANETO:any;
   /*Residencia del trabajador*/
   cmbRESITRAB:any[]=[];
   valorRESITRAB:any;
    /*País de Residencia*/
    cmbPAISRESI:any[]=[];
    valorPAISRESI:any;
    /*Aplica convenio para evitar doble tributación*/
    cmbAPLICONVEVITDOBLTRIB:any[]=[];
    valorAPLICONVEVITDOBLTRIB:any;
    /*Vendedor*/
    cmbVEN_CODIGO:any[]=[];
    valorVEN_CODIGO:any;
    /*Cliente */
    span_CliCodigo:string="";
    arregloCliCodigo=[];
    displayDialogBusquedaFastCliCodigo :boolean;
    /*Forma  pago */
    span_CODIGOSXP:string="";
    arregloCODIGOSXP=[];
    displayDialogBusquedaFastCODIGOSXP :boolean;
    /*Banco Compañia */
    span_CODIGOEMP:string="";
    arregloCODIGOEMP=[];
    displayDialogBusquedaFastCODIGOEMP :boolean;

    /*Banco Compañia */
    span_BAN_CODIGOEMP:string="";
    arregloBAN_CODIGOEMP=[];
    displayDialogBusquedaFastBAN_CODIGOEMP:boolean;

    /* Banco Empleado */
    span_CODIBANCEMPL:string="";
    arregloCODIBANCEMPL=[];
    displayDialogBusquedaFastCODIBANCEMPL:boolean;
    /* FORMA PAGO */
    span_FORMPAGO:string="";
    arregloFORMPAGO=[];
    displayDialogBusquedaFastFORMPAGO:boolean;
    /*Tipo Cuenta */
    cmbTipCuent:any[]=[];
    varTipCuent:any;
    /*Remuneración Salarial Unificada */
    displayDialogBusquedaFastCODIREMUSALAUNIF:boolean;
    arregloCODIREMUSALAUNIF=[];
    /* Alimentación */
    displayDialogBusquedaFastCON_CODIALIM:boolean;
    arregloCON_CODIALIM=[];
    /*  Bonificación por Responsabilidad  */
    displayDialogBusquedaFastCON_CODIBONIRESP:boolean;
    arregloCON_CODIBONIRESP=[];
    /*  Bonificación por Responsabilidad etiqueta */
    displayDialogBusquedaFastCON_CODIETI1ING:boolean;
    arregloCON_CODIETI1ING=[];
    /* Otros Ingresos Gravables */
    displayDialogBusquedaFastCTACOMPSALA:boolean;
    arregloCTACOMPSALA=[];
    /* Otros Ingresos Gravables Etiqueta*/
    displayDialogBusquedaFastCON_CODIETI2ING:boolean;
    arregloCON_CODIETI2ING=[];
    /*Ley de galapagos*/
    displayDialogBusquedaFastCTACALC75:boolean;
    arregloCTACALC75=[];
    chkEMP_CALC75:boolean;
    /* Anticipo quincenal */
    displayDialogBusquedaFastCON_CODIANTI:boolean;
    arregloCON_CODIANTI=[];
    /* Seguro P */
    displayDialogBusquedaFastCON_CODISEGUPRIV:boolean;
    arregloCON_CODISEGUPRIV=[];
    /* Préstamo quirograf */
    displayDialogBusquedaFastCON_CODIPRESIESSQ:boolean;
    arregloCON_CODIPRESIESSQ=[];
    /* Movilidad */
    displayDialogBusquedaFastCON_CODIMOVI:boolean;
    arregloCON_CODIMOVI=[];
   /* Préstamo hipotec. */
    displayDialogBusquedaFastCON_CODIPRESHIPO:boolean;
    arregloCON_CODIPRESHIPO=[];
  /* Teléfono: */
    displayDialogBusquedaFastCON_CODITELE:boolean;
    arregloCON_CODITELE=[];
  /* Préstamo empresa */
    displayDialogBusquedaFastCON_CODIGOPRES:boolean;
    arregloCON_CODIGOPRES=[];
    /* Región */
    displayDialogBusquedaFastEMP_DECICUARTREGI:boolean;
    arregloEMP_DECICUARTREGI=[];
    chkEMP_FNDSRSRVA:boolean=false;
    chkEMP_VACACALC:boolean=false;
    chkEMP_DECITERCCALC:boolean=false;
    chkEMP_DECICUARCALC:boolean=false;
    chksalida:boolean=false;
    calsalifech:boolean=true;
    pagarretener="";
    cvacaciones="";
    rdecimotercero="";
    rdecimocuarto="";
    /*Empresa Impuesto renta*/
    chkImpRent:boolean=false;
    chkJorPar:boolean=false;
    /*valores fomulario*/
    valoresformu:boolean=false;
    vacacionestable:boolean=false;

    cmbEnfermedadCatastrof:any[]=[];
   valorEnfermedadCatastrof:any;
  /*Tabla Ingresos Fijos*/
  ingregreFijos:boolean=false;
  displaydialogPerEmpl:boolean=false;
  largo1='550';
  btnNuevoIngrFij:boolean=false;
  btnGuardarIngrFij:boolean=true;
  btnBorrarIngrFij:boolean=false;
  btnCancelarIngrFij:boolean=false;
  emp_codaux:string=''
  public frameworkComponents;
  rowStyle;
  rowSelection = 'multiple';
  //nommaenomIngrFijo:nomIngrFijo[]=[];
  defaultColDefMaeIngrFijo;
  selectednomIngrFijo:nomIngrFijo;
  boolcmbt:boolean=false//CDPJ
  validacheck:boolean=false;
  chkactivoOri:boolean//CDPJ
  etiqueta1='Etiqueta';//CDPJ
  etiqueta2='Etiqueta';//CDPJ
  columnDefMaeIngrFijo=[
    {
      headerName: 'Cuenta',  editable: (params) => {
        const boolEdit = this.isEditIngre(params.data) ;
        return boolEdit;
      }, field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 80,  pinned: 'left', 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: false,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Descripción', editable: (params) => {
        const boolEdit = this.isEditIngre(params.data) ;
        return boolEdit;
      }, field: 'MAEINGRFIJO_DESCRIPCION', cellEditor: 'cellPrueba', width: 150,  pinned: 'left',
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'CC',editable: (params) => {
        const boolEdit = this.isEditIngre(params.data) ;
        return boolEdit;
      }, field: 'CEN_CODIGO', cellEditor: 'cellPrueba', width: 80, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Importe', editable: (params) => {
        const boolEdit = this.isEditIngre(params.data) ;
        return boolEdit;
      }, field: 'MAEINGRFIJO_IMPORTE', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Iess',  field: 'MAEINGRFIJO_APORIESS',  cellRendererFramework: AggridBanComponent, editable: (params) => {
        const boolEdit = this.isEditIngre(params.data) ;
        return boolEdit;
      }, width: 100, onCellClicked: (params) => {
        this.seleccionItem(params.data); 
      }
    },
    {
      headerName: '#Cuotas', editable: (params) => {
        const boolEdit = this.isEditIngre(params.data) ;
        return boolEdit;
      }, field: 'MAEINGRFIJO_NROCUOT', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'F.Registro', editable: (params) => {
        const boolEdit = this.isEditIngre(params.data) ;
        return boolEdit;
      }, field: 'MAEINGRFIJO_FECHREGI', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: true,
          tamanocelda: 20,
          tienecalculos: true,
         
        }
      }, onCellValueChanged: (params) => {
        this.cambioModulo(params);
      }
    },
    {
      headerName: 'F.Inicio', editable: (params) => {
        const boolEdit = this.isEditIngre(params.data) ;
        return boolEdit;
      }, field: 'MAEINGRFIJO_FECINI', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: true,
          tamanocelda: 20,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'F.Fin', editable: (params) => {
        const boolEdit = this.isEditIngre(params.data) ;
        return boolEdit;
      }, field: 'MAEINGRFIJO_FECFIN', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: true,
          tamanocelda: 20,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Abono', editable: (params) => {
        const boolEdit = this.isEditIngre(params.data) ;
        return boolEdit;
      }, field: 'MAEINGRFIJO_ABONO', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Saldo', editable: (params) => {
        const boolEdit = this.isEditIngre(params.data) ;
        return boolEdit;
      }, field: 'MAEINGRFIJO_SALDO', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    
    {
      headerName: 'Rubro', editable: (params) => {
        const boolEdit = this.isEditIngre(params.data) ;
        return boolEdit;
      }, field: 'MAERUBRO_CODIGO2', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Periodo Código', editable: (params) => {
        const boolEdit = this.isEditIngre(params.data) ;
        return boolEdit;
      }, field: 'MAEPERIODO_CODIGO', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    }
  ];
  /*Tabla Egresos*/
  //nommaeEgreFijo:nomEgreFijo[]=[];
  
  displaydialogEgrePerEmpl:boolean=false;
  defaultColDefNomEgreFijo;
  selectednomEgreFijo:nomEgreFijo;
  columnDefNomEgreFijo=[
    {
      headerName: 'Cuenta', editable: (params) => {
        const boolEdit = this.isEditEgre(params.data) ;
        return boolEdit;
      }, field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 80,  pinned: 'left',
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: false,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Descripción', editable: (params) => {
        const boolEdit = this.isEditEgre(params.data) ;
        return boolEdit;
      }, field: 'MAEEGREFIJO_DESCRIPCION', cellEditor: 'cellPrueba', width: 150, pinned: 'left',
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'CC',  editable: (params) => {
        const boolEdit = this.isEditEgre(params.data) ;
        return boolEdit;
      }, field: 'CEN_CODIGO', cellEditor: 'cellPrueba', width: 80, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Importe',  editable: (params) => {
        const boolEdit = this.isEditEgre(params.data) ;
        return boolEdit;
      }, field: 'MAEEGREFIJO_IMPORTE', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: '#Cuotas',  editable: (params) => {
        const boolEdit = this.isEditEgre(params.data) ;
        return boolEdit;
      }, field: 'MAEEGREFIJO_NROCUOT', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'F.Registro',  editable: (params) => {
        const boolEdit = this.isEditEgre(params.data) ;
        return boolEdit;
      }, field: 'MAEEGREFIJO_FECHREGI', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: true,
            tamanocelda: 20,
            tienecalculos: true,
          }
        }, onCellValueChanged: (params) => {
          this.cambioModuloEgre(params);
        }
    },
    {
      headerName: 'F.Inicio',  editable: (params) => {
        const boolEdit = this.isEditEgre(params.data) ;
        return boolEdit;
      }, field: 'MAEEGREFIJO_FECINI', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: true,
            tamanocelda: 20,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: 'F.Fin',  editable: (params) => {
        const boolEdit = this.isEditEgre(params.data) ;
        return boolEdit;
      }, field: 'MAEEGREFIJO_FECFIN', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: true,
            tamanocelda: 20,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: 'Abono',  editable: (params) => {
        const boolEdit = this.isEditEgre(params.data) ;
        return boolEdit;
      }, field: 'MAEEGREFIJO_ABONO', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Saldo', editable: (params) => {
        const boolEdit = this.isEditEgre(params.data) ;
        return boolEdit;
      }, field: 'MAEEGREFIJO_SALDO', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Rubro',  editable: (params) => {
        const boolEdit = this.isEditEgre(params.data) ;
        return boolEdit;
      }, field: 'MAERUBRO_CODIGO2', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Periodo Código',  editable: (params) => {
        const boolEdit = this.isEditEgre(params.data) ;
        return boolEdit;
      }, field: 'MAEPERIODO_CODIGO', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    }
  ];
  /*Tabla 2*/
  displayAcciones:boolean;
  /*Tabla Vacaciones*/
  largo2=500;
  displaydialogemplvac:boolean=false;
  vacacionestabla:boolean=false;
  defaultColDefVacaciones;
  selectedVacaciones:nomVacaciones;
  columnDefVacaciones=[
    {
      headerName: 'Tipo', editable: false, field: 'TRANVACAEMPL_TIPO', cellEditor: 'cellPrueba',onCellClicked: (params) => {
        this.selecVac(params.data);  
      }, width: 80,  
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Periodo', editable: false, field: 'TRANVACAEMPL_PERIODO', cellEditor: 'cellPrueba',onCellClicked: (params) => {
        this.selecVac(params.data);  
      }, width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Desde', editable: false, field: 'TRANVACAEMPL_FECINIPERI', cellEditor: 'cellPrueba',onCellClicked: (params) => {
        this.selecVac(params.data);  
      }, width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Hasta', editable: false, field: 'TRANVACAEMPL_FECFINPERI', cellEditor: 'cellPrueba',onCellClicked: (params) => {
        this.selecVac(params.data);  
      }, width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'D. Periodo', editable: false, field: 'TRANVACAEMPL_DIASPERI', cellEditor: 'cellPrueba',onCellClicked: (params) => {
        this.selecVac(params.data);  
      }, width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'D. Prorrateados', editable: false, field: 'TRANVACAEMPL_DIASPRORR', cellEditor: 'cellPrueba',onCellClicked: (params) => {
        this.selecVac(params.data);  
      }, width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'B. Acumulada', editable: false, field: 'TRANVACAEMPL_BASEACUM', cellEditor: 'cellPrueba',onCellClicked: (params) => {
        this.selecVac(params.data);  
      }, width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Valor', editable: false, field: 'TRANVACAEMPL_VALOR', cellEditor: 'cellPrueba',onCellClicked: (params) => {
        this.selecVac(params.data);  
      }, width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Estatus', editable: false, field: 'TRANVACAEMPL_ESTADO', cellEditor: 'cellPrueba',onCellClicked: (params) => {
        this.selecVac(params.data);  
      }, width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Saldo Días', editable: false, field: 'TRANVACAEMPL_SALDODIAS', cellEditor: 'cellPrueba',onCellClicked: (params) => {
        this.selecVac(params.data);  
      }, width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'S. ValorProv', editable: false, field: 'TRANVACAEMPL_SALDO', cellEditor: 'cellPrueba',onCellClicked: (params) => {
        this.selecVac(params.data);  
      }, width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'F. SalidaVaca', editable: false, field: 'TRANVACAEMPL_FECHSALIVACA', cellEditor: 'cellPrueba',onCellClicked: (params) => {
        this.selecVac(params.data);  
      }, width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    }
    ,
    {
      headerName: 'F. RegresoVaca.', editable: false, field: 'TRANVACAEMPL_FECHREGRVACA', cellEditor: 'cellPrueba',onCellClicked: (params) => {
        this.selecVac(params.data);  
      }, width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Observaciones', editable: false, field: 'TRANVACAEMPL_OBSERVACIONES', cellEditor: 'cellPrueba',onCellClicked: (params) => {
        this.selecVac(params.data);  
      }, width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    }
    ,
  ];
  /*Tabla carga familiar */
  carFamtabla:boolean=false;
  btnNuevoCarFam:boolean=false;
  btnGuardarCarFam:boolean=true;
  btnBorrarCarFam:boolean=false;
  btnCancelarCarFam:boolean=false;
  nomCargaFamil:nomCargaFami[]=[];
  defaultColDefnomCargaFami;
  largo3=500;
  selectednomCargaFami:nomCargaFami;
  columnnomCargaFami=[
    {
      headerName: 'Nombre', editable: true, field: 'EMPCARGFAMI_NOMBRE', cellEditor: 'cellPrueba', width: 150,  
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 100,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'T.ID', editable: true, field: 'EMPCARGFAMI_TIPOIDE', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'ID', editable: true, field: 'EMPCARGFAMI_RUCIDE', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }, onCellValueChanged: (params) => {
          this.cambioValidarid(params);
        }
    },
    {
      headerName: 'Sexo', editable: true, field: 'EMPCARGFAMI_SEXO', cellEditor: 'cellPrueba', width: 80, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Relación', editable: true, field: 'EMPCARGFAMI_TIPORELA2', cellEditor: 'cellPrueba', width: 100, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'F.Nacimiento', editable: true, field: 'EMPCARGFAMI_FECHNACI', cellEditor: 'cellPrueba', width: 100, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: true,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }, onCellValueChanged: (params) => {
          this.cambioFechaNac(params);
        }
    },
    {
      headerName: 'Disc', field: 'EMPCARGFAMI_DISC',  cellRendererFramework: AggridBanComponent, editable:false, width: 80, onCellClicked: (params) => {
        this.seleccionDisc(params.data); 
      }
    
    },
    {
      headerName: '%Disc.', editable: true, field: 'EMPCARGFAMI_PORCDISC', cellEditor: 'cellPrueba', width: 80, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Carnet Conadis', editable: true, field: 'EMPCARGFAMI_CARNCONA', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Estatus Utilidades', editable: true, field: 'EMPCARGFAMI_ESTATUS', cellEditor: 'cellPrueba', width: 80, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
            opcionbuscar: true
          }
        }
    }
  ];
  constructor( public nomempleadosService:NomempleadosService, 
               private busquedaSer: InvbusquedaService,
               private init: NuevoComponentService,
               private pipe: DatePipe,
               private messageService: MessageService,
               private errorService: ErroresBaseDatosService,
               private confirmationService: ConfirmationService,
               private auditoriaGralService: AuditoriagralService,
               private datePipe: DatePipe,
               ) { 
    this.displayAcciones=false;
    this.agTable();
  }

   ngOnInit(): void {
    this.inicio();
  }
  async inicio(){
    this.btnGuardar=true;
    this.btnNuevo=false;
    this.btnBorrar=false;
    this.btnRegresar=false;
    this.botonAnterior=false;
    this.botonPrimero=false;
    this.botonUltimo=false;
    this.btnBuscar=false;
    this.botonSiguiente=false;
    this.displaydialogadiglob=false;
    this.displaydatosadiglob=false;           
    this.displaydialogadi=false;
    this.displaydatosAdi=false;
    this.displayDialogBusquedaFastEstCiv=false;
    this.llenarVACIOS();
    this.cmbtipoid =  [];
    this.visi=false;
    this.llenarCampos();
    await this.traeretiquetas()
    this.cuidadbool=false; 
    this.cmbEnfermedadCatastrof = [];
    
    console.log(this.cmbEnfermedadCatastrof);
    try{
    document.getElementById('divPrincipal').style.pointerEvents = 'none';
    }catch(e){

    }
    
  }
  //CDPJ
 async traeretiquetas(){
  try {
   const etiqueta=await this.nomempleadosService.encontrarEtiquetas();
   console.log(etiqueta)
   if(etiqueta !== null && etiqueta !== undefined){
     if(etiqueta.length >0){
       for(const data of etiqueta){
         if(data.REF_CODIGO === 'ETI1'){
           this.etiqueta1=data.REF_NOMBRE
         }else if(data.REF_CODIGO === 'ETI2'){
           this.etiqueta2=data.REF_NOMBRE
         }
       }
     }
   }
  } catch (error) {   
  }
}
 //CDPJ
  /*Llenar campos*/
  async llenarCampos(){
    const ci= await this.nomempleadosService.bustipoID().then((ids:any)=>{
      return ids;
    });
    const plazoContrato= await this.nomempleadosService.busPlazoContrato().then(plazo=>{
      return plazo;
    });
    const causaDespido= await this.nomempleadosService.busCausaDespido().then(causaDes=>{
      return causaDes;
    });
    const departamento= await this.nomempleadosService.busDepartamento().then(depar=>{
      return depar;
    });
    const provincia= await this.nomempleadosService.busProvincia().then(pro=>{
      return pro;
    });
    const nacionalidad= await this.nomempleadosService.busPAISRESI().then(nac=>{
      return nac;
    });
    const ConTrabRescDisc= await this.nomempleadosService.busConTrabRescDisc().then(disc=>{
      return disc;
    });
    const TIPOSISTSALANETO= await this.nomempleadosService.busTIPOSISTSALANETO().then(tiposis=>{
      return tiposis;
    });
    const RESITRAB= await this.nomempleadosService.busRESITRAB().then(resitrab=>{
      return resitrab;
    });
    const PAISRESI= await this.nomempleadosService.busPAISRESI().then(paisreci=>{
      return paisreci;
    });
    const convenios= await this.nomempleadosService.busAplicaConvenio().then(convenio=>{
      return convenio;
    });
    const VENCODIGO= await this.nomempleadosService.busVEN_CODIGO().then(venCod=>{
      return venCod;
    });
    const TIPCUENTA= await this.nomempleadosService.busEMP_TIPCTABANC().then(tipcuent=>{
      console.log('entra')
      console.log(tipcuent);
      return tipcuent;
    });
    
    Promise.all([ ci, plazoContrato,causaDespido,departamento,provincia,nacionalidad,ConTrabRescDisc
                 ,TIPOSISTSALANETO,RESITRAB,PAISRESI,convenios,VENCODIGO,TIPCUENTA]).then(res=>{
      
      this.cmbtipoid.push({refnombre: '', REF_CODIGO: ''});
      for(let id of res[0]){
        this.cmbtipoid.push({refnombre: id.REF_NOMBRE, REF_CODIGO: id.REF_CODIGO});
      
      } 
      this.valPlazoContrato.push({refnombrea:'', REF_CODIGO: ''});
      for(let plazo of res[1]){
        this.valPlazoContrato.push({refnombrea: plazo.REF_NOMBRE, REF_CODIGO: plazo.REF_CODIGO});
      }
      this.valCausaSalida.push({refnombreb: '', REF_CODIGO: ''});
      for(let sal of res[2]){
        this.valCausaSalida.push({refnombreb: sal.REF_NOMBRE, REF_CODIGO: sal.REF_CODIGO});
        
      }
      this.cmbDepart.push({depnombre: '', DEP_CODIGO: ''});
      for(let dep of res[3]){
        this.cmbDepart.push({depnombre: dep.DEP_NOMBRE, DEP_CODIGO: dep.DEP_CODIGO});
        
      }
      this.cmbProv.push({provnombre: '', PRO_CODIGO: ''});
      for(let prov of res[4]){
        this.cmbProv.push({provnombre: prov.PRO_NOMBRE, PRO_CODIGO: prov.PRO_CODIGO});
      }
      this.cmbNacio.push({nacnombre: '', NAC_CODIGO: ''});
      for(let nac of res[5]){
        this.cmbNacio.push({nacnombre: nac.CODSRI_DESCRIPCION, NAC_CODIGO: nac.CODSRI_CODIGO});
        this.visi=true;
      }
      //Aqui Jona
      this.cmbConTrabRescDisc.push({CODSRI_DESCRIPCION: '', CODSRI_CODIGO: ''});
      for(let contradis of res[6]){
        this.cmbConTrabRescDisc.push({CODSRI_DESCRIPCION: contradis.CODSRI_DESCRIPCION, CODSRI_CODIGO: contradis.CODSRI_CODIGO});
      }
      this.cmbTIPOSISTSALANETO.push({CODSRI_DESCRIPCION: '', CODSRI_CODIGO: ''});
      for(let tiposis of res[7]){  
        this.cmbTIPOSISTSALANETO.push({CODSRI_DESCRIPCION: tiposis.CODSRI_DESCRIPCION, CODSRI_CODIGO: tiposis.CODSRI_CODIGO});
      }
      this.cmbRESITRAB.push({CODSRI_DESCRIPCION: '', CODSRI_CODIGO: ''});
      for(let tiposis of res[8]){ 
        this.cmbRESITRAB.push({CODSRI_DESCRIPCION: tiposis.CODSRI_DESCRIPCION, CODSRI_CODIGO: tiposis.CODSRI_CODIGO});
         
      }
      this.cmbPAISRESI.push({CODSRI_DESCRIPCION: '', CODSRI_CODIGO: ''});
      for(let paisreci of res[9]){ 
        this.cmbPAISRESI.push({CODSRI_DESCRIPCION: paisreci.CODSRI_DESCRIPCION, CODSRI_CODIGO: paisreci.CODSRI_CODIGO});
      }
      this.cmbAPLICONVEVITDOBLTRIB.push({CODSRI_DESCRIPCION: '', CODSRI_CODIGO: ''});
      for(let conve of res[10]){ 
        this.cmbAPLICONVEVITDOBLTRIB.push({CODSRI_DESCRIPCION: conve.CODSRI_DESCRIPCION, CODSRI_CODIGO: conve.CODSRI_CODIGO});
         
      }
      this.cmbVEN_CODIGO.push({VEN_NOMBRE: '', VEN_CODIGO: ''});
      for(let venCod of res[11]){ 
        this.cmbVEN_CODIGO.push({VEN_NOMBRE: venCod.VEN_NOMBRE, VEN_CODIGO: venCod.VEN_CODIGO});
        
      }
      this.cmbTipCuent.push({REF_NOMBRE: '', REF_CODIGO: ''});
      for(let tipcuent of res[12]){ 
        console.log(tipcuent);
        this.cmbTipCuent.push({REF_NOMBRE: tipcuent.REF_NOMBRE, REF_CODIGO: tipcuent.REF_CODIGO});
        this.otrosDatos=true;  
      }
      console.log(this.cmbTipCuent);
      this.cmbEnfermedadCatastrof.push({EMP_ENFCATASTROFICA: ''}, {EMP_ENFCATASTROFICA: 'SI'}, {EMP_ENFCATASTROFICA: 'NO'});
    this.valorEnfermedadCatastrof = this.cmbEnfermedadCatastrof[0];
    console.log(this.cmbEnfermedadCatastrof);
    })
  }
  busquedaEstCivil(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarEstCivil';
    this.types = [
      {label: 'CODIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'},
      {label: 'NOMBRE CORTO', value: 'REF_NOMBREC'},
    ];
    this.busquedacampos = [parametro, '',''];
    this.consulta = 'REF_CODIGO, REF_NOMBRE, REF_NOMBREC, REF_TIPO';
    this.tabla = 'SACI_MAEREFERENCIA';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" and ref_Tipo = 'ESTCIV'";
    this.nomempleadosService.encontrarRegistro2(parametro).then((res: any[]) => {
      if(res!=null){
        if (res[0] != undefined) {
          
          this.span_esta_civil=res[0].REF_NOMBRE;
          document.getElementById('inp_CORREOEMPRE').focus();
        }  else{
          this.busquedaSer.busquedaSaciMaeReferencia7(this.where).toPromise().then((re: any[])=>{
            this.arregloEstCiv = re;
            this.displayDialogBusquedaFastEstCiv = true;
          })
        }
      }else{
        this.busquedaSer.busquedaSaciMaeReferencia7(this.where).toPromise().then((ret: any[])=>{
          this.arregloEstCiv = ret;
          this.displayDialogBusquedaFastEstCiv = true;
        })
      }
    });
  }
 
  manejarSeleccionEstCiv(event){
   
    this.selectedNomEmpl.EMP_ESTACIVIL=event.REF_CODIGO;
    this.span_esta_civil=event.REF_NOMBRE;
    this.displayDialogBusquedaFastEstCiv = false;
    this.botonesmodificar();
    document.getElementById('inp_CORREOEMPRE').focus();
  }
 
  /*Sexo */
  busquedaSexo(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarEstCivil';
    this.types = [
      {label: 'CODIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'},
      {label: 'NOMBRE CORTO', value: 'REF_NOMBREC'},
    ];
    this.busquedacampos = [parametro, '',''];
    this.consulta = 'REF_CODIGO, REF_NOMBRE, REF_NOMBREC, REF_TIPO';
    this.tabla = 'SACI_MAEREFERENCIA';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" and ref_Tipo = 'SEX'";
    
    this.nomempleadosService.encontrarRegistro3(parametro).then((res: any[]) => {
      if(res!=null){
        if (res[0] != undefined) {
          this.span_sex=res[0].REF_NOMBRE;
          document.getElementById('inpDETASALI').focus();
        }else{
          this.busquedaSer.busquedaSaciMaeReferencia7(this.where).subscribe((res: any[]) => {
            this.arregloSex = res;
            this.displayDialogBusquedaFastSex = true;
          });
        }
      }else{
        this.busquedaSer.busquedaSaciMaeReferencia7(this.where).subscribe((res: any[]) => {
          this.arregloSex = res;
          this.displayDialogBusquedaFastSex = true;
        });
      }
    });
  }
  manejarSeleccionSex(event){
    
    this.selectedNomEmpl.EMP_SEXO=event.REF_CODIGO;
    document.getElementById('inpDETASALI').focus();
    this. span_sex=event.REF_NOMBRE;
    this.botonesmodificar();
    this.displayDialogBusquedaFastSex = false;
  }
  /*Jefe Inmediato */
  busqueJefInme(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarJefeInm';
    this.types = [
      {label: 'CODIGO', value: 'EMP_CODIGO'},
      {label: 'NOMBRE', value: 'EMP_NOMBRE'},
      {label: 'APELLIDO', value: 'EMP_APELLIDO'},
    ];
    this.busquedacampos = [parametro, '',''];
    this.consulta = 'EMP_CODIGO,EMP_NOMBRE, EMP_APELLIDO';
    this.tabla = 'NOM_MAEEMPLEADO '; 
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" ORDER BY EMP_CODIGO";  
       
    this.nomempleadosService.encontrarRegistro4(parametro).then((res: any[]) => {
      if(res!=null){
       
        if (res[0] != undefined) {  
          this.span_JefInme=res[0].EMP_APELLIDO+" "+res[0].EMP_NOMBRE;
          document.getElementById('inpjefeExterno').focus();
        }  else{
          this.busquedaSer.busquedaNomMaeEmpleado1(this.where).subscribe((res: any[]) => {
            this.cmbJefInme = res;
            this.displayDialogBusquedaFastJefInme = true;
          });
        }
      }else{
        this.busquedaSer.busquedaNomMaeEmpleado1(this.where).subscribe((res: any[]) => {
          this.cmbJefInme = res;
          this.displayDialogBusquedaFastJefInme = true;
          document.getElementById('inpjefeExterno').focus();
        });
      }
    });

  }
  manejarSeleccionJefInm(event){
    this.selectedNomEmpl.EMP_JEFEINMD=event.EMP_CODIGO
    this.span_JefInme=event.EMP_APELLIDO +" " +event.EMP_NOMBRE;
    this.displayDialogBusquedaFastJefInme = false;
    this.botonesmodificar();
    document.getElementById('inpjefeExterno').focus();
  }

   /*Jefe Externo*/
  busqueJefExt(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarJefeExt';
    this.types = [
      {label: 'CODIGO', value: 'EMP_CODIGO'},
      {label: 'NOMBRE', value: 'EMP_NOMBRE'},
      {label: 'APELLIDO', value: 'EMP_APELLIDO'},
    ];
    this.busquedacampos = [parametro, '',''];
    this.consulta = 'EMP_CODIGO,EMP_NOMBRE, EMP_APELLIDO';
    this.tabla = 'NOM_MAEEMPLEADO ';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" ORDER BY EMP_CODIGO";  
       
    this.nomempleadosService.encontrarRegistro4(parametro).then((res: any[]) => {
      if(res!=null){
        if (res[0] != undefined) {
          this.span_JefExt=res[0].EMP_APELLIDO+" "+res[0].EMP_NOMBRE;
          document.getElementById('inpcentroCostos').focus();
        }  else{
          this.busquedaSer.busquedaNomMaeEmpleado1(this.where).subscribe((res: any[]) => {
            this.cmbJefExt  = res;
            this.displayDialogBusquedaFastJefExt = true;
          });
        }
      }else{
        this.busquedaSer.busquedaNomMaeEmpleado1(this.where).subscribe((res: any[]) => {
          this.cmbJefExt = res;
          this.displayDialogBusquedaFastJefExt = true;          
        });
      }
    });

  }
  manejarSeleccionJefExt(event){
    this.selectedNomEmpl.EMP_JEFEEXT=event.EMP_CODIGO;
    this.span_JefExt=event.EMP_APELLIDO +" " +event.EMP_NOMBRE;
    this.displayDialogBusquedaFastJefExt  = false;
    this.botonesmodificar();
    document.getElementById('inpcentroCostos').focus();
  }
  /* Centro de Costos  */
  busCentroCostos(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCentroCostos';
    this.types = [
      {label: 'CODIGO', value: 'CEN_CODIGO'},
      {label: 'NOMBRE', value: 'CEN_NOMBRE'},
      
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = 'CEN_CODIGO, CEN_NOMBRE';
    this.tabla = 'con_maecen';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\'';
    
    this.nomempleadosService.encontrarRegistro5(parametro).then((res: any[]) => {
      if(res!=null){ 
        if (res[0] != undefined) {
          this.span_centroCos=res[0].CEN_NOMBRE;
          document.getElementById('inpcuentaConta').focus();
        }  else{
          this.busquedaSer.busquedaConMaeCen5(this.where).subscribe((res: any[]) => {
            this.arregloCenCos = res;
           
            this.displayDialogBusquedaFastCenCos = true;
          });
        }
      }else{
        this.busquedaSer.busquedaConMaeCen5(this.where).subscribe((res: any[]) => {
          this.arregloCenCos = res;
         
          this.displayDialogBusquedaFastCenCos = true;
        });
      }
    });
  }
  manejarSeleccionCentroCos(event){
    this.selectedNomEmpl.EMP_CENTCOST= event.CEN_CODIGO;
    document.getElementById('inpcuentaConta').focus();
    this.span_centroCos=event.CEN_NOMBRE;
    this.botonesmodificar();
    this.displayDialogBusquedaFastCenCos = false;
  }
  /* Cuenta Contable */
  busCuentaCons(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCuentaCons';
    this.types = [
      {label: 'CODIGO', value: 'CON_CODIGO'},
      {label: 'NOMBRE', value: 'CON_NOMBRE'},
      {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
      //CNT. COSTOS
    ];
    this.busquedacampos = [parametro, '',''];
    this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
    this.tabla = 'CON_MAECON';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
   
    this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
      if(res!=null){
        if (res[0] != undefined) {
          if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
            this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
              this.arregloCuenCont = res;
              this.displayDialogBusquedaFastCuenCont = true;
            });
          }else{
            this.selectedNomEmpl.EMP_CONCODIGO=res[0].CON_CODIGO;
            document.getElementById('inpCargEmpr').focus();
          }
        }  else{
          this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
            this.arregloCuenCont = res;
            this.displayDialogBusquedaFastCuenCont = true;
          });
        }
      }else{
        this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
          this.arregloCuenCont = res;
          this.displayDialogBusquedaFastCuenCont = true;
        });
      }
    });
  }
  manejarSeleccionCuenCont(event){
    if (event.CON_CODIGO.substr(-1, 1) === '.') {
    }else{
      this.selectedNomEmpl.EMP_CONCODIGO=event.CON_CODIGO;
      this.displayDialogBusquedaFastCuenCont = false;
      this.botonesmodificar();
      document.getElementById('inpCargEmpr').focus();
    }
  }
/* Cargo Empresa */
  busCarEmpr(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCargoEmpre';
    this.types = [
      {label: 'CODIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'},
      {label: 'NOMBRE CORTO', value: 'REF_NOMBREC'},
      //CNT. COSTOS
    ];
    this.busquedacampos = [parametro, '',''];
    this.consulta = 'REF_CODIGO, REF_NOMBRE, REF_NOMBREC, REF_TIPO, COM_CODIGO';
    this.tabla = 'SACI_MAEREFERENCIA';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND REF_TIPO = 'NOMCAR' AND COM_CODIGO = '01' ORDER BY REF_CODIGO";
    
    this.nomempleadosService.encontrarRegistro7(parametro, 'NOMCAR').then((res: any[]) => {
      if(res!=null){
        if (res[0] != undefined) {         
          this.span_CarEmpr=res[0].REF_NOMBRE
          document.getElementById('inpCargIess').focus();
        }else{
          this.busquedaSer.busquedaSaciMaeReferencia7(this.where).subscribe((res: any[]) => {
            this.arregloCargEmpr = res;
         
            this.displayDialogBusquedaFastCargEmpr = true;
          });

        }
      }else{
        this.busquedaSer.busquedaSaciMaeReferencia7(this.where).subscribe((res: any[]) => {
          this.arregloCargEmpr = res;
          this.displayDialogBusquedaFastCargEmpr = true;
        });
      }
    });

  
  }
  manejarSeleccionCarEmpr(event){
    this.selectedNomEmpl.EMP_CARGO=event.REF_CODIGO;
    this.displayDialogBusquedaFastCargEmpr = false;
    this.span_CarEmpr=event.REF_NOMBRE;
    this.botonesmodificar();
    document.getElementById('inpCargIess').focus();
  }

  /* Cargo Iess */
  busCargIees(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCargoIess';
    this.types = [
      {label: 'CODIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'},
      {label: 'NOMBRE CORTO', value: 'REF_NOMBREC'},
      //CNT. COSTOS
    ];
    this.busquedacampos = [parametro, '',''];
    this.consulta = 'REF_CODIGO, REF_NOMBRE, REF_NOMBREC, REF_TIPO, COM_CODIGO';
    this.tabla = 'SACI_MAEREFERENCIA';

    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND REF_TIPO = 'NOMCIE' AND COM_CODIGO = '01' ORDER BY REF_CODIGO";
    
    this.nomempleadosService.encontrarRegistro7(parametro, 'NOMCIE').then((res: any[]) => {
      if(res!=null){
        if (res[0] != undefined) {
          this.span_CarIess=res[0].REF_NOMBRE
          document.getElementById('inpEstTrab').focus();
        }else{
          this.busquedaSer.busquedaSaciMaeReferencia7(this.where).subscribe((res: any[]) => {
            this.arregloCargIess= res;
            this.displayDialogBusquedaFastCargIess = true;
          });

        }
      }else{
        this.busquedaSer.busquedaSaciMaeReferencia7(this.where).subscribe((res: any[]) => {
          this.arregloCargIess= res;
          this.displayDialogBusquedaFastCargIess = true;
        });
      }
    });
  
  }

  /* Establecimiento donde trabaja */
  manejarSeleccCargIess(event){
    this.span_CarIess=event.REF_NOMBRE;
    this.selectedNomEmpl.EMP_CARGIESS=event.REF_CODIGO;
    this.displayDialogBusquedaFastCargIess = false;
    this.botonesmodificar();
    document.getElementById('inpEstTrab').focus();
  }
  busEstTrab(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCargoIess';
    this.types = [
      {label: 'CODIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'},
      {label: 'NOMBRE CORTO', value: 'REF_NOMBREC'},
      //CNT. COSTOS
    ];
    this.busquedacampos = [parametro, '',''];
    this.consulta = 'REF_CODIGO, REF_NOMBRE, REF_NOMBREC, REF_TIPO, COM_CODIGO';
    this.tabla = 'SACI_MAEREFERENCIA';

    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND REF_TIPO = 'NOMETR' AND COM_CODIGO = '01' ORDER BY REF_CODIGO";
    
    this.nomempleadosService.encontrarRegistro7(parametro, 'NOMETR').then((res: any[]) => {
      if(res!=null){
        if (res[0] != undefined) {    
          this.span_EstTrab=res[0].REF_NOMBRE
          document.getElementById('inpTipEmple').focus();
        }  else{
          this.busquedaSer.busquedaSaciMaeReferencia7(this.where).subscribe((res: any[]) => {
            this.arregloEstTrab= res;
            this.displayDialogBusquedaFastEstTrab = true;
          });

        }
      }else{
        this.busquedaSer.busquedaSaciMaeReferencia7(this.where).subscribe((res: any[]) => {
          this.arregloEstTrab= res;
         
          this.displayDialogBusquedaFastEstTrab = true;
        });
      }
    });
    
  }
  manejarSeleccionEstTrab(event){
    this.selectedNomEmpl.EMP_ESTATRAB=event.REF_CODIGO;
    this.span_EstTrab=event.REF_NOMBRE;
    this.displayDialogBusquedaFastEstTrab = false;
    this.botonesmodificar();
    document.getElementById('inpTipEmple').focus();
  }
   /* Tipo Empleado */

  busTipEmpl(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCargoIess';
    this.types = [
      {label: 'CODIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'},
      {label: 'NOMBRE CORTO', value: 'REF_NOMBREC'},
      //CNT. COSTOS
    ];
    this.busquedacampos = [parametro, '',''];
    this.consulta = 'REF_CODIGO, REF_NOMBRE, REF_NOMBREC, REF_TIPO, COM_CODIGO';
    this.tabla = 'SACI_MAEREFERENCIA';

    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND REF_TIPO = 'NOMTEM' AND COM_CODIGO = '01' ORDER BY REF_CODIGO";
    
    this.nomempleadosService.encontrarRegistro7(parametro, 'NOMTEM').then((res: any[]) => {
      if(res!=null){
        if (res[0] != undefined) {
          this.span_TipEmpl=res[0].REF_NOMBRE
          document.getElementById('inpEMPDIRECCION').focus();
        }  else{
          this.busquedaSer.busquedaSaciMaeReferencia7(this.where).subscribe((res: any[]) => {
            this.arregloTipEmpl= res;
            this.displayDialogBusquedaFastTipEmpl = true;
          });

        }
      }else{
        this.busquedaSer.busquedaSaciMaeReferencia7(this.where).subscribe((res: any[]) => {
          this.arregloTipEmpl= res;
          this.displayDialogBusquedaFastTipEmpl = true;
        });
      }
    });
  }
   manejarSeleccTipEmpl(event){
    this.selectedNomEmpl.EMP_TIPOEMPL=event.REF_CODIGO;
    this.span_TipEmpl=event.REF_NOMBRE;
    this.botonesmodificar();
    this.displayDialogBusquedaFastTipEmpl = false;
   }
     /* Tipo Rol */
  busTipoRol(parametro){
      if (parametro == null || parametro== undefined) {
        parametro = '';
      }
      this.opcion = 'BuscarTIPROL';
      this.types = [
        {label: 'CODIGO', value: 'TIPROL_CODIGO'},
        {label: 'NOMBRE', value: 'TIPROL_NOMBRE'},
      ];
      this.busquedacampos = [parametro, ''];
      this.consulta = 'TIPROL_CODIGO, TIPROL_NOMBRE';
      this.tabla = 'nom_maetiporol';
  
      this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+"  ORDER BY TIPROL_CODIGO";
      
      this.nomempleadosService.encontrarRegistro8(parametro).then((res: any[]) => {
        if(res!=null){
          if (res[0] != undefined) {
            this.span_TipoRol=res[0].TIPROL_NOMBRE;
          }  else{
            this.busquedaSer.busquedaNomMaeTipoRol1(parametro).subscribe((res: any[]) => {
              this.arregloTipoRol= res;
              this.displayDialogBusquedaFastTipRol = true;
            });
          }
        }else{
          this.busquedaSer.busquedaNomMaeTipoRol1(parametro).subscribe((res: any[]) => {
            this.arregloTipoRol= res;           
            this.displayDialogBusquedaFastTipRol = true;
          });
        }
      });
      
  }
  manejarSeleccTipRol(event){
      this.selectedNomEmpl.TIPROL_CODIGO=event.TIPROL_CODIGO;
      this.span_TipoRol=event.TIPROL_NOMBRE;
      this.botonesmodificar();
      this.displayDialogBusquedaFastTipRol = false;
  }
  busCliCodigo(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCliCod';
    this.types = [
      {label: 'RUC', value: 'CLI_RUCIDE'},
      {label: 'NOMBRE', value: 'CLI_NOMBRE'},
      {label: 'CÓDIGO', value: 'CLI_CODIGO'},
      
    ];
    this.busquedacampos = [parametro, '',''];
    this.consulta = "cli_codigo,gru_codigo,ven_codigo,cli_nombre,VEN_FNC_OBTENER_ZONACLI(NVL(CLI_ZONA,'NN')) as cli_zona,cli_rucide,cli_inactivo,cli_direccion1";
    this.tabla = 'VEN_MAECLIENTE';

    this.where = this.types[2].value + ' LIKE \'' + parametro + '%\''+"  ORDER BY CLI_NOMBRE";
    
    this.nomempleadosService.encontrarRegistro9(parametro).then((res: any[]) => {
      if(res!=null){
        
        if (res[0] != undefined) {
          
          this.span_CliCodigo=res[0].CLI_NOMBRE;
          
        }  else{
          this.busquedaSer.busquedaVenMaeCliente4(parametro).subscribe((res: any[]) => {
            this.arregloCliCodigo= res;;
            this.displayDialogBusquedaFastCliCodigo = true;
          });

        }
      }else{
        this.busquedaSer.busquedaVenMaeCliente4(parametro).subscribe((res: any[]) => {
          this.arregloCliCodigo= res;
          this.displayDialogBusquedaFastCliCodigo = true;
        });
      }
    });
  }
  manejarSeleccCliCodigo(event){
    this.selectedNomEmpl.CLI_CODIGO=event.CLI_CODIGO;
    this.span_CliCodigo=event.CLI_NOMBRE;
    this.botonesmodificar();
    this.displayDialogBusquedaFastCliCodigo = false;
  }

  busCODIGOSXP(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCODIGOSXP';
    this.types = [
      {label: 'CODIGO', value: 'CON_CODIGO'},
      {label: 'NOMBRE', value: 'CON_NOMBRE'},
      {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
    ];
    this.busquedacampos = [parametro, '', ''];
    this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
    this.tabla = 'CON_MAECON';
    this.where = 'COM_CODIGO = \'01\' ORDER BY CON_CODIGO';
   
    this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
      if(res!=null){
        if (res[0] != undefined) {

          if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
            this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
              this.arregloCODIGOSXP = res;
              this.displayDialogBusquedaFastCODIGOSXP = true;
            });
          }else{
            this.selectedNomEmpl.CON_CODIGOSXP=res[0].CON_CODIGO;
            this.span_CODIGOSXP=res[0].CON_NOMBRE;
            document.getElementById('inpBANCODIGOEMP').focus();
          }
         
        }  else{
          this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
            this.arregloCODIGOSXP = res;
            this.displayDialogBusquedaFastCODIGOSXP = true;
          });

        }
      }else{
        this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
          this.arregloCODIGOSXP = res;
          this.displayDialogBusquedaFastCODIGOSXP = true;
        });
      }
    });

  }
  manejarCODIGOSXP(event){
    this.selectedNomEmpl.CON_CODIGOSXP=event.CON_CODIGO;
    this.span_CODIGOSXP=event.CON_NOMBRE;
    this.displayDialogBusquedaFastCODIGOSXP = false;
    this.botonesmodificar();
    document.getElementById('inpBANCODIGOEMP').focus();
  }

  
  busBAN_CODIGOEMP(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BAN_CODIGOEMP';
    this.types = [
      {label: 'CODIGO', value: 'BAN_CODIGO'},
      {label: 'BANCO', value: 'BAN_BANCO'},
      {label: 'CUENTA NRO', value: 'BAN_CTANRO'},
      {label: 'JEFE CUENTA', value: 'BAN_JEFECTA'},
      {label: 'SUCURSAL', value: 'BAN_SUCURSAL'},
      {label: 'DIRECCION', value: 'BAN_DIRECCION'},
      {label: 'TELEFONO 1', value: 'BAN_TELEFONO1'},
      {label: 'TELEFONO 2', value: 'BAN_TELEFONO2'},
      {label: 'CORREO', value: 'BAN_CORREO'},
      {label: 'CODIGO CONTABLE', value: 'CON_CODIGO'},
      {label: 'CEN_CODIGO', value: 'CEN_CODIGO'},
      {label: 'TIPO CUENTA', value: 'BAN_TIPOCUENTA'},
      {label: 'DOC. PAGAR', value: 'CON_CODIGO_2'},
      {label: 'PROCESO', value: 'BAN_PROCESO_CM'},
      {label: 'DIRECTORIO CM', value: 'BAN_DIRECTORIO_CM'},
      {label: 'TELCEL', value: 'BAN_TELEFONOCEL'},
      {label: 'ULT. CHEQUE', value: 'BAN_ULTIMOCHEQUE'}
    
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'BAN_MAEBAN';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY BAN_CODIGO";   
    this.nomempleadosService.encontrarRegistro10(parametro).then((res: any[]) => {
      if(res!=null){        
        if (res[0] != undefined) {      
          this.selectedNomEmpl.BAN_CODIGOEMP=res[0].BAN_CODIGO;
          this.span_CODIGOEMP=res[0].BAN_BANCO;
          document.getElementById('inpCODIBANCEMPL').focus();
        }  else{
          this.busquedaSer.busquedaBanMaeBan3(parametro).subscribe((res: any[]) => {
            this.arregloBAN_CODIGOEMP = res;
            this.displayDialogBusquedaFastBAN_CODIGOEMP = true;
          });
        }
      }else{
        this.busquedaSer.busquedaBanMaeBan3(parametro).subscribe((res: any[]) => {
          this.arregloBAN_CODIGOEMP = res;
          this.displayDialogBusquedaFastBAN_CODIGOEMP = true;
        });
      }
    });

  }
  manejarBAN_CODIGOEMP(event){
    this.selectedNomEmpl.BAN_CODIGOEMP=event.BAN_CODIGO;
    this.span_CODIGOEMP=event.BAN_BANCO;
    this.displayDialogBusquedaFastBAN_CODIGOEMP = false;
    this.botonesmodificar();
    document.getElementById('inpCODIBANCEMPL').focus();
  }
  busCODIBANCEMPL(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCODIBANCEMPL';
    this.types = [
      {label: 'CODIGO', value: 'BANCLI_CODIGO'},
      {label: 'NOMBRE', value: 'BANCLI_NOMBRE'},
      {label: 'ESTADO', value: 'BANCLI_ESTADO'},
      {label: 'COMPAÑIA', value: 'COM_CODIGO'},
      
    ];
    //bancli_codigo,bancli_nombre, bancli_estado , com_Codigo
    this.busquedacampos = [parametro, '','',''];
    this.consulta = 'BANCLI_CODIGO,BANCLI_NOMBRE, BANCLI_ESTADO, COM_CODIGO';
    this.tabla = 'CXC_MAEBANCLI';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ";
   
    this.nomempleadosService.encontrarRegistro11(parametro).then((res: any[]) => {
      if(res!=null){
        if (res[0] != undefined) {
          this.selectedNomEmpl.EMP_CODIBANCEMPL=res[0].BANCLI_CODIGO;
          this.span_CODIBANCEMPL=res[0].BANCLI_NOMBRE;
          document.getElementById('inpFORMPAGO').focus();
          
        }  else{
          this.busquedaSer.busquedaCXCMaeBanCli2(parametro).subscribe((res: any[]) => {
            this.arregloCODIBANCEMPL = res;
            this.displayDialogBusquedaFastCODIBANCEMPL = true;
          });

        }
      }else{
        this.busquedaSer.busquedaCXCMaeBanCli2(parametro).subscribe((res: any[]) => {
          this.arregloCODIBANCEMPL = res;
          this.displayDialogBusquedaFastCODIBANCEMPL = true;
        });
      }
    });

  }
  manejarCODIBANCEMPL(event){
    this.selectedNomEmpl.EMP_CODIBANCEMPL=event.BANCLI_CODIGO;
    this.span_CODIBANCEMPL=event.BANCLI_NOMBRE;
    this.displayDialogBusquedaFastCODIBANCEMPL = false;
    this.botonesmodificar();
    document.getElementById('inpFORMPAGO').focus();
  }
  busFORMPAGO(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCODIBANCEMPL';
    this.types = [
      {label: 'CODIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'},
      {label: 'NOMBRE CORTO', value: 'REF_NOMBREC'},
      
    ];
    
    this.busquedacampos = [parametro, '',''];
    this.consulta = 'REF_CODIGO,REF_NOMBRE, REF_NOMBREC,REF_TIPO';
    this.tabla = 'SACI_MAEREFERENCIA';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" and REF_tipo = 'NOMFP' order by ref_codigo ";
   
    this.nomempleadosService.encontrarRegistro12(parametro).then((res: any[]) => {
      if(res!=null){       
        if (res[0] != undefined) {          
          this.selectedNomEmpl.EMP_FORMPAGO=res[0].REF_CODIGO;
          this.span_FORMPAGO=res[0].REF_NOMBRE;
          document.getElementById('inpCTAAHORROS').focus();
        }  else{
          this.busquedaSer.busquedaSaciMaeReferencia7(this.where).subscribe((res: any[]) => {
            this.arregloFORMPAGO = res;
            this.displayDialogBusquedaFastFORMPAGO = true;
          });
        }
      }else{
        this.busquedaSer.busquedaSaciMaeReferencia7(this.where).subscribe((res: any[]) => {
          this.arregloFORMPAGO = res;
          this.displayDialogBusquedaFastFORMPAGO = true;
        });
      }
    });
  }
  manejarEMP_FORMPAGO(event){
    this.selectedNomEmpl.EMP_FORMPAGO=event.REF_CODIGO;
    this.span_FORMPAGO=event.REF_NOMBRE;
    this.displayDialogBusquedaFastFORMPAGO= false;
    document.getElementById('inpCTAAHORROS').focus();
    this.botonesmodificar();
  }
  busCODIREMUSALAUNIF(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCODIREMUSALAUNIF';
    this.types = [
      {label: 'CODIGO', value: 'CON_CODIGO'},
      {label: 'NOMBRE', value: 'CON_NOMBRE'},
      {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
    ];
    this.busquedacampos = [parametro, '',''];
    this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
    this.tabla = 'CON_MAECON';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
    this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
      if(res!=null){
        if (res[0] != undefined) {
          if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
            this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
              this.arregloCODIREMUSALAUNIF = res;
              this.displayDialogBusquedaFastCODIREMUSALAUNIF = true;
            });
          }else{
            this.selectedNomEmpl.CON_CODIREMUSALAUNIF=res[0].CON_CODIGO;
            document.getElementById('inpALIMENTACION').focus();
          }
          
        }else{
          this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
            this.arregloCODIREMUSALAUNIF = res;
            this.displayDialogBusquedaFastCODIREMUSALAUNIF = true;
          });

        }
      }else{
        this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
          this.arregloCODIREMUSALAUNIF = res;
          this.displayDialogBusquedaFastCODIREMUSALAUNIF = true;
        });
      }
    });
  }
  manejarCODIREMUSALAUNIF(event){
    if (event.CON_CODIGO.substr(-1, 1) === '.') {
    }else{
      this.selectedNomEmpl.CON_CODIREMUSALAUNIF=event.CON_CODIGO;
      this.displayDialogBusquedaFastCODIREMUSALAUNIF = false;
      document.getElementById('inpALIMENTACION').focus();
      this.botonesmodificar();
    }
  }
  busCON_CODIALIM(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCODIREMUSALAUNIF';
    this.types = [
      {label: 'CODIGO', value: 'CON_CODIGO'},
      {label: 'NOMBRE', value: 'CON_NOMBRE'},
      {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
    ];
    this.busquedacampos = [parametro, '',''];
    this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
    this.tabla = 'CON_MAECON';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
   
    this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
      if(res!=null){
        if (res[0] != undefined) {
          if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
            this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
              this.arregloCON_CODIALIM = res;
              this.displayDialogBusquedaFastCON_CODIALIM= true;
            });
          }else{
            this.selectedNomEmpl.CON_CODIALIM=res[0].CON_CODIGO;
            document.getElementById('inpBONRESP').focus();
          }          
        }else{
          this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
            this.arregloCON_CODIALIM= res;
            this.displayDialogBusquedaFastCON_CODIALIM= true;
          });

        }
      }else{
        this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
          this.arregloCON_CODIALIM = res;
          this.displayDialogBusquedaFastCON_CODIALIM= true;
        });
      }
    });
  }
  manejarCON_CODIALIM(event){
    if (event.CON_CODIGO.substr(-1, 1) === '.') {
    }else{
      this.selectedNomEmpl.CON_CODIALIM=event.CON_CODIGO;
      this.displayDialogBusquedaFastCON_CODIALIM = false;
      this.botonesmodificar();
      document.getElementById('inpBONRESP').focus();
    }
  }
  busCON_CODIBONIRESP(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCODIREMUSALAUNIF';
    this.types = [
      {label: 'CODIGO', value: 'CON_CODIGO'},
      {label: 'NOMBRE', value: 'CON_NOMBRE'},
      {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
    ];
    this.busquedacampos = [parametro, '',''];
    this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
    this.tabla = 'CON_MAECON';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
    this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
      if(res!=null){
        if (res[0] != undefined) {
          if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
            this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
              this.arregloCON_CODIBONIRESP = res;
              this.displayDialogBusquedaFastCON_CODIBONIRESP= true;
            });
          }else{
            this.selectedNomEmpl.CON_CODIBONIRESP=res[0].CON_CODIGO;
            document.getElementById('inpETI1ING').focus();
          }
        }else{
          this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
            this.arregloCON_CODIBONIRESP= res;
            this.displayDialogBusquedaFastCON_CODIBONIRESP= true;
          });
        }
      }else{
        this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
          this.arregloCON_CODIBONIRESP= res;
          this.displayDialogBusquedaFastCON_CODIBONIRESP= true;
        });
      }
    });
  }
  manejarCON_CODIBONIRESP(event){
    if (event.CON_CODIGO.substr(-1, 1) === '.') {
    }else{
      this.selectedNomEmpl.CON_CODIBONIRESP=event.CON_CODIGO;
      this.displayDialogBusquedaFastCON_CODIBONIRESP= false;
      this.botonesmodificar();
      document.getElementById('inpETI1ING').focus();
    }
  }
  busCON_CODIETI1ING(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCODIREMUSALAUNIF';
    this.types = [
      {label: 'CODIGO', value: 'CON_CODIGO'},
      {label: 'NOMBRE', value: 'CON_NOMBRE'},
      {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
    ];
    this.busquedacampos = [parametro, '',''];
    this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
    this.tabla = 'CON_MAECON';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
   
    this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
      if(res!=null){
        if (res[0] != undefined) {
          if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
            this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
              this.arregloCON_CODIETI1ING = res;
              this.displayDialogBusquedaFastCON_CODIETI1ING= true;
            });
          }else{
            this.selectedNomEmpl.CON_CODIETI1ING=res[0].CON_CODIGO;
            document.getElementById('inpCOMPSALA').focus();
          }
          
        }else{
          this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
            this.arregloCON_CODIETI1ING= res;
            this.displayDialogBusquedaFastCON_CODIETI1ING= true;
          });

        }
      }else{
        this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
          this.arregloCON_CODIETI1ING= res;
          this.displayDialogBusquedaFastCON_CODIETI1ING= true;
        });
      }
    });
  }
  manejarCON_CODIETI1ING(event){
    if (event.CON_CODIGO.substr(-1, 1) === '.') {
    }else{
      this.selectedNomEmpl.CON_CODIETI1ING=event.CON_CODIGO;
      this.displayDialogBusquedaFastCON_CODIETI1ING= false;
      document.getElementById('inpCOMPSALA').focus();
      this.botonesmodificar();
    }
  }
  
  busCTACOMPSALA(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCompSala';
    this.types = [
      {label: 'CODIGO', value: 'CON_CODIGO'},
      {label: 'NOMBRE', value: 'CON_NOMBRE'},
      {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
      //CNT. COSTOS
    ];
    this.busquedacampos = [parametro, '',''];
    this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
    this.tabla = 'CON_MAECON';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
   
    this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
      if(res!=null){
        if (res[0] != undefined) {
          if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
            this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
              this.arregloCTACOMPSALA = res;
              this.displayDialogBusquedaFastCTACOMPSALA= true;
            });
          }else{
            this.selectedNomEmpl.EMP_CTACOMPSALA=res[0].CON_CODIGO;
            document.getElementById('inpETI2ING').focus();
          }
          
        }else{
          this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
            this.arregloCTACOMPSALA = res;
              this.displayDialogBusquedaFastCTACOMPSALA= true;
          });

        }
      }else{
        this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
          this.arregloCTACOMPSALA = res;
          this.displayDialogBusquedaFastCTACOMPSALA= true;
        });
      }
    });
  }
  manejarCTACOMPSALA(event){
    if (event.CON_CODIGO.substr(-1, 1) === '.') {
    }else{
      this.selectedNomEmpl.EMP_CTACOMPSALA=event.CON_CODIGO;
      this.displayDialogBusquedaFastCTACOMPSALA= false;
      this.botonesmodificar();
      document.getElementById('inpETI2ING').focus();
    }
  }
 
  busCON_CODIETI2ING(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCODIETI2ING';
    this.types = [
      {label: 'CODIGO', value: 'CON_CODIGO'},
      {label: 'NOMBRE', value: 'CON_NOMBRE'},
      {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
    ];
    this.busquedacampos = [parametro, '',''];
    this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
    this.tabla = 'CON_MAECON';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
   
    this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
      if(res!=null){
        if (res[0] != undefined) {
          if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
            this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
              this.arregloCON_CODIETI2ING = res;
              this.displayDialogBusquedaFastCON_CODIETI2ING= true;
            });
          }else{
            this.selectedNomEmpl.CON_CODIETI2ING=res[0].CON_CODIGO;
            document.getElementById('inpCTACALC75').focus();
          }
          
        }else{
          this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
            this.arregloCON_CODIETI2ING = res;
              this.displayDialogBusquedaFastCON_CODIETI2ING= true;
          });

        }
      }else{
        this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
          this.arregloCON_CODIETI2ING = res;
          this.displayDialogBusquedaFastCON_CODIETI2ING= true;
        });
      }
    });
  }
  manejarCON_CODIETI2ING(event){
    if (event.CON_CODIGO.substr(-1, 1) === '.') {
    }else{
      this.selectedNomEmpl.CON_CODIETI2ING=event.CON_CODIGO;
      this.displayDialogBusquedaFastCON_CODIETI2ING= false;
      this.botonesmodificar();
      document.getElementById('inpCTACALC75').focus();
    }
  }
   
   busCTACALC75(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCODIETI2ING';
    this.types = [
      {label: 'CODIGO', value: 'CON_CODIGO'},
      {label: 'NOMBRE', value: 'CON_NOMBRE'},
      {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
      //CNT. COSTOS
    ];
    this.busquedacampos = [parametro, '',''];
    this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
    this.tabla = 'CON_MAECON';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
   
    this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
      if(res!=null){
        if (res[0] != undefined) {
          if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
            this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
              this.arregloCTACALC75 = res;
              this.displayDialogBusquedaFastCTACALC75= true;
            });
          }else{
            this.selectedNomEmpl.EMP_CTACALC75=res[0].CON_CODIGO;
            document.getElementById('inpANTICIPO').focus();
          }
          
        }  else{
          this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
            this.arregloCTACALC75 = res;
            this.displayDialogBusquedaFastCTACALC75= true;
          });

        }
      }else{
        this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
          this.arregloCTACALC75 = res;
          this.displayDialogBusquedaFastCTACALC75= true;
        });
      }
    });
  }
  manejarCTACALC75(event){
    if (event.CON_CODIGO.substr(-1, 1) === '.') {
    }else{
      this.selectedNomEmpl.EMP_CTACALC75=event.CON_CODIGO;
      this.displayDialogBusquedaFastCTACALC75= false;
      this.botonesmodificar();
      document.getElementById('inpANTICIPO').focus();
    }
  }
  
  busCON_CODIANTI(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCODIETI2ING';
    this.types = [
      {label: 'CODIGO', value: 'CON_CODIGO'},
      {label: 'NOMBRE', value: 'CON_NOMBRE'},
      {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
    ];
    this.busquedacampos = [parametro, '',''];
    this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
    this.tabla = 'CON_MAECON';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
   
    this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
      if(res!=null){
        if (res[0] != undefined) {
          if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
            this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
              this.arregloCON_CODIANTI = res;
              this.displayDialogBusquedaFastCON_CODIANTI= true;
            });
          }else{
            this.selectedNomEmpl.CON_CODIANTI=res[0].CON_CODIGO;
            document.getElementById('inpSEGUROFP').focus();
            
          } 
        }else{
          this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
            this.arregloCON_CODIANTI = res;
            this.displayDialogBusquedaFastCON_CODIANTI= true;
          });

        }
      }else{
        this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
          this.arregloCON_CODIANTI = res;
          this.displayDialogBusquedaFastCON_CODIANTI= true;
        });
      }
    });
  }
  manejarCON_CODIANTI(event){
    if (event.CON_CODIGO.substr(-1, 1) === '.') {
    }else{
        this.selectedNomEmpl.CON_CODIANTI= event.CON_CODIGO;
        this.displayDialogBusquedaFastCON_CODIANTI= false;
        this.botonesmodificar();
        document.getElementById('inpSEGUROFP').focus();
    }
  }
  
  busCON_CODISEGUPRIV(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCODIETI2ING';
    this.types = [
      {label: 'CODIGO', value: 'CON_CODIGO'},
      {label: 'NOMBRE', value: 'CON_NOMBRE'},
      {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
    ];
    this.busquedacampos = [parametro, '',''];
    this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
    this.tabla = 'CON_MAECON';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
   
    this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
      if(res!=null){
        if (res[0] != undefined) {
          if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
            this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
              this.arregloCON_CODISEGUPRIV = res;
              this.displayDialogBusquedaFastCON_CODISEGUPRIV= true;
            });
          }else{
            this.selectedNomEmpl.CON_CODISEGUPRIV=res[0].CON_CODIGO;
            document.getElementById('inpPRESIESS').focus();
          }
          
        }else{
          this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
            this.arregloCON_CODISEGUPRIV = res;
            this.displayDialogBusquedaFastCON_CODISEGUPRIV= true;
          });

        }
      }else{
        this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
            this.arregloCON_CODISEGUPRIV  = res;
            this.displayDialogBusquedaFastCON_CODISEGUPRIV= true;
        });
      }
    });
  }
  manejarCON_CODISEGUPRIV(event){
    if (event.CON_CODIGO.substr(-1, 1) === '.') {
    }else{
      this.selectedNomEmpl.CON_CODISEGUPRIV = event.CON_CODIGO;
        this.displayDialogBusquedaFastCON_CODISEGUPRIV= false;
        this.botonesmodificar();
        document.getElementById('inpPRESIESS').focus();
    }
  }
  busCON_CODIPRESIESSQ(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCODIPRESIESSQ';
    this.types = [
      {label: 'CODIGO', value: 'CON_CODIGO'},
      {label: 'NOMBRE', value: 'CON_NOMBRE'},
      {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
    ];
    this.busquedacampos = [parametro, '',''];
    this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
    this.tabla = 'CON_MAECON';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
   
    this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
      if(res!=null){
        if (res[0] != undefined) {
          if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
            this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
              this.arregloCON_CODIPRESIESSQ = res;
              this.displayDialogBusquedaFastCON_CODIPRESIESSQ= true;
            });
          }else{
            this.selectedNomEmpl.CON_CODIPRESIESSQ=res[0].CON_CODIGO;
            document.getElementById('inpMOVILIZACION').focus();
          }
          
        }  else{
          this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
            this.arregloCON_CODIPRESIESSQ = res;
              this.displayDialogBusquedaFastCON_CODIPRESIESSQ= true;
          });

        }
      }else{
        this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
          this.arregloCON_CODIPRESIESSQ = res;
          this.displayDialogBusquedaFastCON_CODIPRESIESSQ= true;
        });
      }
    });
  }
  manejarCON_CODIPRESIESSQ(event){
    if (event.CON_CODIGO.substr(-1, 1) === '.') {
    }else{
      this.selectedNomEmpl.CON_CODIPRESIESSQ= event.CON_CODIGO;
      this.displayDialogBusquedaFastCON_CODIPRESIESSQ= false;
      this.botonesmodificar();
      document.getElementById('inpMOVILIZACION').focus();
    }
  }
 
 busCON_CODIMOVI(parametro){
  if (parametro == null || parametro== undefined) {
    parametro = '';
  }
  this.opcion = 'BuscarCODIETI2ING';
  this.types = [
    {label: 'CODIGO', value: 'CON_CODIGO'},
    {label: 'NOMBRE', value: 'CON_NOMBRE'},
    {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
    //CNT. COSTOS
  ];
  this.busquedacampos = [parametro, '',''];
  this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
  this.tabla = 'CON_MAECON';
  this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
 
  this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
    if(res!=null){
      if (res[0] != undefined) {
        if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
          this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
            this.arregloCON_CODIMOVI = res;
            this.displayDialogBusquedaFastCON_CODIMOVI= true;
          });
        }else{
          this.selectedNomEmpl.CON_CODIMOVI=res[0].CON_CODIGO;
          document.getElementById('inpPRESTHIPOTECARIO').focus();
        }
        
      }  else{
        this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
          this.arregloCON_CODIMOVI = res;
          this.displayDialogBusquedaFastCON_CODIMOVI= true;
        });

      }
    }else{
      this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
        this.arregloCON_CODIMOVI = res;
        this.displayDialogBusquedaFastCON_CODIMOVI= true;
      });
    }
  });
 }
 manejarCON_CODIMOVIQ(event){
  if (event.CON_CODIGO.substr(-1, 1) === '.') {
  }else{
    this.selectedNomEmpl.CON_CODIMOVI= event.CON_CODIGO;
    this.displayDialogBusquedaFastCON_CODIMOVI= false;
    this.botonesmodificar();
    document.getElementById('inpPRESTHIPOTECARIO').focus();
  }
 }
 busCON_CODIPRESHIPO(parametro){
  if (parametro == null || parametro== undefined) {
    parametro = '';
  }
  this.opcion = 'BuscarCODIETI2ING';
  this.types = [
    {label: 'CODIGO', value: 'CON_CODIGO'},
    {label: 'NOMBRE', value: 'CON_NOMBRE'},
    {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
  ];
  this.busquedacampos = [parametro, '',''];
  this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
  this.tabla = 'CON_MAECON';
  this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
 
  this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
    if(res!=null){
      if (res[0] != undefined) {
        if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
          this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
            this.arregloCON_CODIPRESHIPO = res;
            this.displayDialogBusquedaFastCON_CODIPRESHIPO= true;

          });
        }else{
          this.selectedNomEmpl.CON_CODIPRESHIPO=res[0].CON_CODIGO;
          document.getElementById('inpTELEFONO').focus();
        }
        
      }  else{
        this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
          this.arregloCON_CODIPRESHIPO = res;
          this.displayDialogBusquedaFastCON_CODIPRESHIPO= true;
        });

      }
    }else{
      this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
        this.arregloCON_CODIPRESHIPO = res;
        this.displayDialogBusquedaFastCON_CODIPRESHIPO= true;
      });
    }
  });
 }
 manejarCON_CODIPRESHIPO(event){
  if (event.CON_CODIGO.substr(-1, 1) === '.') {
  }else{
    this.selectedNomEmpl.CON_CODIPRESHIPO= event.CON_CODIGO;
    this.displayDialogBusquedaFastCON_CODIPRESHIPO= false;
    this.botonesmodificar();
    document.getElementById('inpTELEFONO').focus();
  }
 }
 
 busCON_CODITELE(parametro){
  if (parametro == null || parametro== undefined) {
    parametro = '';
  }
  this.opcion = 'BuscarCODIETI2ING';
  this.types = [
    {label: 'CODIGO', value: 'CON_CODIGO'},
    {label: 'NOMBRE', value: 'CON_NOMBRE'},
    {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
    //CNT. COSTOS
  ];
  this.busquedacampos = [parametro, '',''];
  this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
  this.tabla = 'CON_MAECON';
  this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
 
  this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
    if(res!=null){
      if (res[0] != undefined) {
        if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
          this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
            this.arregloCON_CODITELE= res;
            this.displayDialogBusquedaFastCON_CODITELE= true;
          });
        }else{
          this.selectedNomEmpl.CON_CODITELE=res[0].CON_CODIGO;
          document.getElementById('inpPRESTAMO').focus();
        }
        
      }else{
        this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
          this.arregloCON_CODITELE= res;
          this.displayDialogBusquedaFastCON_CODITELE= true;
        });

      }
    }else{
      this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
        this.arregloCON_CODITELE= res;
        this.displayDialogBusquedaFastCON_CODITELE= true;
      });
    }
  });
 }
 manejarCON_CODITELE(event){
  if (event.CON_CODIGO.substr(-1, 1) === '.') {
  }else{
    this.selectedNomEmpl.CON_CODITELE = event.CON_CODIGO;
    this.displayDialogBusquedaFastCON_CODITELE= false;
    this.botonesmodificar();
    document.getElementById('inpPRESTAMO').focus();
  }
 }
 busCON_CODIGOPRES(parametro){
  if (parametro == null || parametro== undefined) {
    parametro = '';
  }
  this.opcion = 'BuscarCODIETI2ING';
  this.types = [
    {label: 'CODIGO', value: 'CON_CODIGO'},
    {label: 'NOMBRE', value: 'CON_NOMBRE'},
    {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
    //CNT. COSTOS
  ];
  this.busquedacampos = [parametro, '',''];
  this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
  this.tabla = 'CON_MAECON';
  this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
 
  this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
    if(res!=null){
      if (res[0] != undefined) {
        if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
          this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
            this.arregloCON_CODIGOPRES= res;
            this.displayDialogBusquedaFastCON_CODIGOPRES= true;
           
          });
        }else{
          this.selectedNomEmpl.CON_CODIGOPRES=res[0].CON_CODIGO;
          document.getElementById('inpPORCAPORINDI').focus();
        }
        
      }  else{
        this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
          this.arregloCON_CODIGOPRES= res;
          this.displayDialogBusquedaFastCON_CODIGOPRES= true;
        });

      }
    }else{
      this.busquedaSer.busquedaConMaeCon4(this.where).subscribe((res: any[]) => {
        this.arregloCON_CODIGOPRES= res;
        this.displayDialogBusquedaFastCON_CODIGOPRES= true;
      });
    }
  });
 }

 manejarCON_CODIGOPRES(event){
  if (event.CON_CODIGO.substr(-1, 1) === '.') {
  }else{
    this.selectedNomEmpl.CON_CODIGOPRES = event.CON_CODIGO;
    this.displayDialogBusquedaFastCON_CODIGOPRES= false;
    this.botonesmodificar();
    document.getElementById('inpPORCAPORINDI').focus();
  }
 }
  /*JONA1 */
 busEMP_DECICUARTREGI(parametro){
  if (parametro == null || parametro== undefined) {
    parametro = '';
  }
  this.opcion = 'BuscarCODIETI2ING';
  this.types = [
    {label: 'CODIGO', value: 'REF_CODIGO'},
    {label: 'NOMBRE', value: 'REF_NOMBRE'},
    {label: 'NOMBRE CORTO', value: 'REF_NOMBREC'},
    //CNT. COSTOS
  ];
  this.busquedacampos = [parametro, '',''];
  this.consulta = 'REF_CODIGO, REF_NOMBRE, REF_NOMBREC,REF_TIPO';
  this.tabla = 'SACI_MAEREFERENCIA';
  this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND   REF_TIPO  = 'NOMREG'";
 
  this.nomempleadosService.encontrarRegistro12(parametro).then((res: any[]) => {
    if(res!=null){
      if (res[0] != undefined) {
        if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
          this.busquedaSer.busquedaSaciMaeReferencia7(this.where).subscribe((res: any[]) => {
            this.arregloEMP_DECICUARTREGI= res;
            this.displayDialogBusquedaFastEMP_DECICUARTREGI= true;
          });
        }else{
          this.selectedNomEmpl.EMP_DECICUARTREGI=res[0].CON_CODIGO;
        }
        
      }  else{
        this.busquedaSer.busquedaSaciMaeReferencia7(this.where).subscribe((res: any[]) => {
          this.arregloEMP_DECICUARTREGI= res;
          this.displayDialogBusquedaFastEMP_DECICUARTREGI= true;
        });

      }
    }else{
      this.busquedaSer.busquedaSaciMaeReferencia7(this.where).subscribe((res: any[]) => {
        this.arregloEMP_DECICUARTREGI= res;
            this.displayDialogBusquedaFastEMP_DECICUARTREGI= true;
      });
    }
  });
 }
 manejarEMP_DECICUARTREGI(event){
  this.selectedNomEmpl.EMP_DECICUARTREGI=event.REF_CODIGO;
   this.displayDialogBusquedaFastEMP_DECICUARTREGI= false;
   this.botonesmodificar();
}
 //pruebas Jona
 fondosreserva(){
   
   if(this.pagarretener=="val1"){
      this.selectedNomEmpl.EMP_FNDRSVPAGAR=1;
      this.selectedNomEmpl.EMP_FNDRSVRRETENER=0;
   }else if(this.pagarretener=="val2"){
      this.selectedNomEmpl.EMP_FNDRSVPAGAR=0;
      this.selectedNomEmpl.EMP_FNDRSVRRETENER=1;
   }else{
    this.selectedNomEmpl.EMP_FNDRSVPAGAR=null;
    this.selectedNomEmpl.EMP_FNDRSVRRETENER=null;
   }
 }
 
 llenarfondosreserva(){
    if(isNullOrUndefined(this.selectedNomEmpl.EMP_FNDRSVPAGAR)){
      return this.pagarretener="";
    }else{
      (this.selectedNomEmpl.EMP_FNDRSVPAGAR==1)?this.pagarretener="val1":this.pagarretener="val2";
      (this.selectedNomEmpl.EMP_FNDRSVRRETENER==1)?this.pagarretener="val2":this.pagarretener="val1";
    }
  }
 vacaciones(){
 
    if(this.cvacaciones=="val1"){
      this.selectedNomEmpl.EMP_VACAPAGA=1;
      this.selectedNomEmpl.EMP_VACARETI=0;
    }else if(this.cvacaciones=="val2"){
      this.selectedNomEmpl.EMP_VACAPAGA=0;
      this.selectedNomEmpl.EMP_VACARETI=1;
    }else{
      this.selectedNomEmpl.EMP_VACAPAGA=null;
      this.selectedNomEmpl.EMP_VACARETI=null;
    }
 }
 llenarvacaciones(){
    if(isNullOrUndefined(this.selectedNomEmpl.EMP_VACAPAGA)){
      return this.cvacaciones="";
    }else{
      (this.selectedNomEmpl.EMP_VACAPAGA==1)?this.cvacaciones="val1":this.cvacaciones="val2";
      (this.selectedNomEmpl.EMP_VACARETI==1)?this.cvacaciones="val2":this.cvacaciones="val1";
    }
    
  }
 decimotercero(){
  
    if(this.rdecimotercero=="val1"){
      this.selectedNomEmpl.EMP_DECITERCPAGA=1;
      this.selectedNomEmpl.EMP_DECITERCRETI=0;
    }else if(this.rdecimotercero=="val2"){
      this.selectedNomEmpl.EMP_DECITERCPAGA=0;
      this.selectedNomEmpl.EMP_DECITERCRETI=1;
    }else{
      this.selectedNomEmpl.EMP_DECITERCPAGA=null;
      this.selectedNomEmpl.EMP_DECITERCRETI=null;
    }
 }
 llenardecimotercero(){
    if(isNullOrUndefined(this.selectedNomEmpl.EMP_DECITERCPAGA)){
      return this.rdecimotercero="";
    }else{
      (this.selectedNomEmpl.EMP_DECITERCPAGA==1)?this.rdecimotercero="val1":this.rdecimotercero="val2";
      (this.selectedNomEmpl.EMP_DECITERCRETI==1)?this.rdecimotercero="val2":this.rdecimotercero="val1";
    }
  }
 decimocuarto(){
    if(this.rdecimocuarto=="val1"){
      this.selectedNomEmpl.EMP_DECICUARPAGA=1;
      this.selectedNomEmpl.EMP_DECICUARRETI=0;
    }else if(this.rdecimocuarto=="val2"){
      this.selectedNomEmpl.EMP_DECICUARPAGA=0;
      this.selectedNomEmpl.EMP_DECICUARRETI=1;
    }else{
      this.selectedNomEmpl.EMP_DECICUARPAGA=null;
      this.selectedNomEmpl.EMP_DECICUARRETI=null;
    }
 }
 llenardecimocuarto(){
  if(isNullOrUndefined(this.selectedNomEmpl.EMP_DECICUARPAGA)){
    return this.rdecimocuarto="";
  }else{
    (this.selectedNomEmpl.EMP_DECICUARPAGA==1)?this.rdecimocuarto="val1":this.rdecimocuarto="val2";
    (this.selectedNomEmpl.EMP_DECICUARRETI==1)?this.rdecimocuarto="val2":this.rdecimocuarto="val1";
  }
  
  }
 
 
    cambioOption(event){
        this.selectedNomEmpl.EMP_TIPOIDE=this.valortipoid.REF_CODIGO;
         //CDPJ
         console.log(this.selectedNomEmpl.EMP_TIPOIDE)
         if(this.selectedNomEmpl.EMP_TIPOIDE === '2'){
          document.getElementById('inp_EMP_RUCIDE').setAttribute('maxlength', '10');
          this.selectedNomEmpl.EMP_RUCIDE=''
        }else if (this.selectedNomEmpl.EMP_TIPOIDE === '3'){
          document.getElementById('inp_EMP_RUCIDE').setAttribute('maxlength', '13');
          this.selectedNomEmpl.EMP_RUCIDE=''
        }else if (this.selectedNomEmpl.EMP_TIPOIDE === '4'){
          document.getElementById('inp_EMP_RUCIDE').setAttribute('maxlength', '15');
          this.selectedNomEmpl.EMP_RUCIDE=''
        }else{
          document.getElementById('inp_EMP_RUCIDE').setAttribute('maxlength', '0');
          this.selectedNomEmpl.EMP_RUCIDE=''
        }
        //CDPJ
        this.botonesmodificar();
    }
    cambioOptionNac(event){
      this.selectedNomEmpl.EMP_NACIONALIDAD=this.valorNacio.NAC_CODIGO;
      this.botonesmodificar();
    }
    cambioOptionLicen(event){
      this.selectedNomEmpl.EMP_LICENCIA=this.pLicen.codigo;
      this.botonesmodificar();
    }
    cambioCausaSal(event){
      this.selectedNomEmpl.EMP_CAUSSALI=this.valCausaSal.REF_CODIGO;
      this.botonesmodificar();
    }
    cambioPlazoContra(){
      this.selectedNomEmpl.EMP_PLAZOCONTRATO=this.valPlaContra.REF_CODIGO;
      this.botonesmodificar();
    }
    cambioDepart(){
      this.selectedNomEmpl.DEP_CODIGO=this.valorDepart.DEP_CODIGO;
      this.botonesmodificar();
    }

    async cambioProv(){
      this.selectedNomEmpl.PROV_CODIGO=this.valorProv.PRO_CODIGO;
      this.cuidadbool=false; 
      this.cmbCiud=[];
      this.cmbCiud.push({refnombre: '', PRO_CODIGO: ''});
      await this.nomempleadosService.busCiudad(this.selectedNomEmpl.PROV_CODIGO).then((res:any)=>{
        try{
          for(let cui of res){
            this.cmbCiud.push({refnombre: cui.PRO_NOMBRE, PRO_CODIGO: cui.PRO_CODIGO});
          }
          this.cuidadbool=true;
        }catch(e){

        }
      });
    
    }
    cambioCiud(event){
      this.selectedNomEmpl.PROV_CODIGOC=this.valorCiud.PRO_CODIGO;
      this.botonesmodificar();
    }
    async cargarCiudad(PROV_CODIGO){
      try{
      this.cmbCiud=[];
      this.cuidadbool=false; 
      this.cmbCiud.push({refnombre: '', PRO_CODIGO: ''});
      await this.nomempleadosService.busCiudad(PROV_CODIGO).then((res:any)=>{
        try{
          for(let cui of res){
            this.cmbCiud.push({refnombre: cui.PRO_NOMBRE, PRO_CODIGO: cui.PRO_CODIGO});
          }
        }catch(e){
          console.log(e.error);
        }
      })
      }catch(err){
      }
    }
    cambioConTrabRescDisc($event){
      this.selectedNomEmpl.EMP_CONDTRABRESPDISC=this.valorConTrabRescDisc.CODSRI_CODIGO;
      this.botonesmodificar();
    }
    cambioEnfermCatastrof($event){
      this.selectedNomEmpl.EMP_ENFCATASTROFICA=this.valorEnfermedadCatastrof.EMP_ENFCATASTROFICA;
      this.botonesmodificar();
    }
    cambioTIPOSISTSALANETO($event){
      this.selectedNomEmpl.EMP_TIPOSISTSALANETO=this.valorTIPOSISTSALANETO.CODSRI_CODIGO;
      this.botonesmodificar();
    }
    cambioRESITRAB($event){
      this.selectedNomEmpl.EMP_RESITRAB=this.valorRESITRAB.CODSRI_CODIGO;
      this.botonesmodificar();
      this.habilitarcombo();
    }
    cambioPAISRESI($event){
      this.selectedNomEmpl.EMP_PAISRESI=this.valorPAISRESI.CODSRI_CODIGO;
      this.botonesmodificar();
    }
    cambioAPLICONVEVITDOBLTRIB($event){
      this.selectedNomEmpl.EMP_APLICONVEVITDOBLTRIB=this.valorAPLICONVEVITDOBLTRIB.CODSRI_CODIGO;
      this.botonesmodificar();
    }
    cambioVEN_CODIGO($event){
      this.selectedNomEmpl.VEN_CODIGO=this.valorVEN_CODIGO.VEN_CODIGO;
      this.botonesmodificar();  
    }
    cambioTipCuent($event){
      this.selectedNomEmpl.EMP_TIPCTABANC=this.varTipCuent.REF_CODIGO;
      this.botonesmodificar();
    }
    cambiarFoco2(event){
    }
    cambiarFocoNac(event){

    } //CDPJ
    habilitarcombo(){
      console.log(this.selectedNomEmpl.EMP_RESITRAB)
      if(this.selectedNomEmpl.EMP_RESITRAB === '01'){
        this.boolcmbt=true
        this.selectedNomEmpl.EMP_APLICONVEVITDOBLTRIB=''
        this.selectedNomEmpl.EMP_PAISRESI='';
        this.valorPAISRESI ={CODSRI_DESCRIPCION: '', CODSRI_CODIGO: ''};
        this.valorAPLICONVEVITDOBLTRIB ={CODSRI_DESCRIPCION: '', CODSRI_CODIGO: ''};
      }else if(this.selectedNomEmpl.EMP_RESITRAB === '02'){
        this.boolcmbt=false
      }
      
    }
    //CDPJ
    actualizarTipID(EMP_TIPOIDE) {
      try{
       for (const a of this.cmbtipoid) {
        if (a.REF_CODIGO === String(EMP_TIPOIDE)) {
          this.valortipoid ={refnombre: a.refnombre, REF_CODIGO: a.REF_CODIGO}
        }
      } 
      }catch(err){
      }
    }
    actualizarNacio(EMP_NACIONALIDAD) {
       for (const a of this.cmbNacio) {
        if (a.NAC_CODIGO == String(EMP_NACIONALIDAD)) {
          this.valorNacio ={nacnombre: a.nacnombre, NAC_CODIGO: a.NAC_CODIGO}
        }
      }   
    }
    actualizarLicEmpr(EMP_LICENCIA) {
       for (const a of this.cmbLicen) {
        if (a.codigo === String(EMP_LICENCIA)) { 
          this.pLicen ={tipo: a.tipo, codigo: a.codigo}
        }
      } 
      
    }
    actualizarPlazoContra(EMP_PLAZOCONTRATO) {
      for (const a of this.valPlazoContrato) {
       if (a.REF_CODIGO === String(EMP_PLAZOCONTRATO)) {
         this.valPlaContra ={refnombrea: a.refnombrea, REF_CODIGO: a.REF_CODIGO}
       }
     } 
     
   }
  actualizarCausaSalida(EMP_CAUSSALI) {
    for (const a of this.valCausaSalida) { 
     if (a.REF_CODIGO === String(EMP_CAUSSALI)) {
       this.valCausaSal ={refnombreb: a.refnombreb,REF_CODIGO: a.REF_CODIGO}
     }
   }  
  }
  actualizarDepart(DEP_CODIGO) {
     
    for (const a of this.cmbDepart) {     
     if (a.DEP_CODIGO === String(DEP_CODIGO)) {
       this.valorDepart ={depnombre: a.depnombre, DEP_CODIGO: a.DEP_CODIGO}
     }
   }  
  }  
  actualizarProv(PROV_CODIGO) {   
    try{  
      for (const a of this.cmbProv) {
        if (a.PRO_CODIGO === String(PROV_CODIGO)) {
          this.valorProv ={provnombre: a.provnombre,PRO_CODIGO: a.PRO_CODIGO}
        }
      } 
    }catch(e){
    } 
  }
  actualizarCiud(PROV_CODIGOC) {
    try{
      for (const a of this.cmbCiud) {
        if (a.PRO_CODIGO === String(PROV_CODIGOC)) {
          this.valorCiud ={refnombre: a.refnombre,PRO_CODIGO: a.PRO_CODIGO}
        }
      }
      this.cuidadbool=true; 
    }catch(e){
    } 
  
  }
  onTab1Change(event){
    this.tab.tabPanels.length;
    if(this.tab.tabPanels.length==6){
      switch(event.index){
        case 0:
          this.llenarGenerales(this.selectedNomEmpl);
          break;
        case 1:
          this.llenarOtrosDatos(this.selectedNomEmpl);
          break;
        case 2:
          this.llenarFormaPago(this.selectedNomEmpl);
          break;
        case 3:
          //this.botonesEgreIngr()
          this.tablas();
          break;
        case 4:
          this.tablasVaca();
          break;
        case 5:
          this.tablasCarFam();
          break;
      }
    }else{
      switch(event.index){
        case 0:
          this.llenarGenerales(this.selectedNomEmpl);
          break;
        case 1:
          this.llenarValores(this.selectedNomEmpl);
          break;
        case 2:
          this.llenarOtrosDatos(this.selectedNomEmpl);
          break;
        case 3:
          this.llenarFormaPago(this.selectedNomEmpl)
          break;
        case 4:
          this.tablas();
          break;
        case 5:
          this.tablasVaca();
          break;
        case 6:
          this.tablasCarFam();
          break;
      }
    }
     
    
    
  }
  /*Cambios en le menu*/
  botonesmodificar() {
    
    this.btnGuardar=false;
    this.btnNuevo=true;
    this.btnBorrar=true;
    this.btnRegresar=false;
    this.botonAnterior=true;
    this.botonPrimero=true;
    this.botonUltimo=true;
    this.btnBuscar=true;
    this.botonSiguiente=true;
  }
  botonesmodificar1(){
    this.btnGuardar=false;
    this.btnNuevo=true;
    this.btnBorrar=true;
    this.btnRegresar=false;
    this.botonAnterior=true;
    this.botonPrimero=true;
    this.botonUltimo=true;
    this.btnBuscar=true;
    this.botonSiguiente=true;
  }
  botonesmodificar2(){
    this.btnGuardar=false;
    this.btnNuevo=true;
    this.btnBorrar=true;
    this.btnRegresar=false;
    this.botonAnterior=true;
    this.botonPrimero=true;
    this.botonUltimo=true;
    this.btnBuscar=true;
    this.botonSiguiente=true;
  }
  botonesmodificar3(){
    this.btnGuardar=false;
    this.btnNuevo=true;
    this.btnBorrar=true;
    this.btnRegresar=false;
    this.botonAnterior=true;
    this.botonPrimero=true;
    this.botonUltimo=true;
    this.btnBuscar=true;
    this.botonSiguiente=true;
  }
  setFocus(event){
    let element: any;
    switch(event.target.id){
      case 'inp_EMP_RUCIDE':
        element = document.getElementById('inp_estaCivi');
        break;
      case 'inp_estaCivi':
        this.busquedaEstCivil(event.target.value);
        break;  
      case 'inp_CORREOEMPRE':
        element = document.getElementById('inp_CORREOPERS');
        break;  
      case 'inp_CORREOPERS':
        element = document.getElementById('inp_lugarnacimiento');  
        break; 
      case 'inp_CORREOPERS':
        element = document.getElementById('inpEMPCARNET');  
        break; 
      case 'inpEMPCARNET':
        element = document.getElementById('inpEMPCELULAR');  
        break;
      case 'inpEMPCELULAR':
        element = document.getElementById('inpEMPCEDUMILI');  
        break; 
      case 'inpEMPCEDUMILI':
          element = document.getElementById('inpsexo');  
          break; 
      case 'inpsexo':
        this.busquedaSexo(event.target.value);
        break;  
      case 'inpDETASALI':
        element = document.getElementById('inpjefeInmediato');  
        break; 
      case 'inpjefeInmediato':
        this.busqueJefInme(event.target.value);  
        break;
      case 'inpjefeExterno':
        this.busqueJefExt(event.target.value);  
        break;
      case 'inpcentroCostos':
        this.busCentroCostos(event.target.value);  
        break;
      case 'inpEMPDIRECCION':
        element = document.getElementById('inpEMPCALLE');
        break;
      case 'inpEMPCALLE':
        element = document.getElementById('inpEMPCASA');
        break;
      case 'inpEMPCASA':
        element = document.getElementById('inpEMPTELEFONO');
        break;
      case 'inpEMPTELEFONO':
        element = document.getElementById('inp2EMPTELEFONO');
        break;
      case 'inp2EMPTELEFONO':
        element = document.getElementById('inpTipRol');
        break;
      case 'inpEMPREMSALUNI':
        element = document.getElementById('inpCODIREMUSALAUNIF');
        break;
      case 'inpCODIREMUSALAUNIF':
        this.busCODIREMUSALAUNIF(event.target.value);
        break;
      case 'inpALIMENTACION':
        element = document.getElementById('inpCODIALIM');
        break;
      case 'inpBONRESP':
        element = document.getElementById('inpCODIBONIRESP');
        break;
      case 'inpETI1ING':
        element = document.getElementById('inpCODIETI1ING');
        break;
      case 'inpCOMPSALA':
        element = document.getElementById('inpCTACOMPSALA');
        break;
      case 'inpETI2ING':
        element = document.getElementById('inpCODIETI2ING');
        break;
      case 'inpANTICIPO':
        element = document.getElementById('inpCODIANTI');
        break;
      case 'inpSEGUROFP':
        element = document.getElementById('inpCODISEGUPRIV');
        break;
      case 'inpPRESIESS':
        element = document.getElementById('inpCODIPRESIESSQ');
        break;
      case 'inpMOVILIZACION':
        element = document.getElementById('inpCODIMOVI');
        break;
      case 'inpPRESTHIPOTECARIO':
        element = document.getElementById('inpCODIPRESHIPO');
        break;
      case 'inpTELEFONO':
        element = document.getElementById('inpCODITELE');
        break;
      case 'inpPRESTAMO':
        element = document.getElementById('inpCODIGOPRES');
        break
      case 'inpPORCAPORINDI':
        element = document.getElementById('inpPORCAPORPATR');
        break
      case 'inpPORCAPORPATR':
          element = document.getElementById('inpPORCFONDRESER');
          break
      case 'inpPORCDISC':
          element = document.getElementById('inpCARNCONA');
          break
      case 'inpCARNCONA':
          element = document.getElementById('inpDETADISC');
          break
      case 'inpDETADISC':
          element = document.getElementById('inpRUCIDEDISCSUST');
          break
      case 'inpRUCIDEDISCSUST':
          element = document.getElementById('inpNOMBRDISCSUST');
          break
      case 'inpNOMBRDISCSUST':
          element = document.getElementById('inpHORATIEMPARC');
          break
      case 'inpHORATIEMPARC':
          element = document.getElementById('inpCLICODIGO');
          break
    }
    if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }
  }
  busquedagrupo(){

  }
  
  acciones(){
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className = 'layout-config';
      }
    }
  }
  agregarPeriodo(){

  }
  
  async manejarSenales(valor) {
    switch (valor) {
      case 'Nuevo':
        this.opcionNuevo();
        break;
      case 'Guardar':
        this.opcionGuardar();
        break;
      case 'Borrar':
        this.opcionBorrar();
        break;
      case 'Cancelar':
        this.opcionRecargar();
        break;
      case 'Salir':
        this.init.tabs.splice(  this.init.tabindex, 1);
        break;
      case 'Primero':
        await this.Primero();
        break;
      case 'Anterior':
        this.Anterior();
        break;
      case 'Siguiente': 
        this.Siguiente();
        break;
      case 'Ultimo':
        await this.Ultimo();
        break;
      case 'Buscar':
        this.buscarCondicion();
        break;
    }
  }
  //jona clave
  manejarSenalesActivar(valor){
    switch (valor) {
      case 'Visto':
        this.opcionVisto();
        break;
      case 'Salir':
        this.displayDialogActivarValores=false;
        this.claveAuth="";
        break;
    }
  }
 async  opcionVisto(){
    var resul=await this.nomempleadosService.validarActivarValor(this.claveAuth)
    if(resul==true){
      this.displayDialogActivarValores=false;
      
      //Jona Visto
      //valoresformu:boolean=false;
    //vacacionestable:boolean=false;
      if(this.valoresformu==true){
        this.bolvaloresActi=true;
      }
      if(this.vacacionestable==true){
        this.displaydialogemplvac=true;
      }
    }else{
      this.mensajeAlertaAuto('error', 'Error', 'Contraseña incorrecta');
    }
  }
  onHideActivarVal(){
    this.displayDialogActivarValores=false;
    this.claveAuth="";
  }
  mensajeAlertaAuto(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'autorizacion',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
    
  }
  closeSidebar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }
  cambio(elemento){
    this.btnGuardar=false;
    this.btnNuevo=true;
    this.btnBorrar=true;
    this.btnRegresar=false;
    this.botonAnterior=true;
    this.botonPrimero=true;
    this.botonUltimo=true;
    this.btnBuscar=true;
    this.botonSiguiente=true;
  }
  /*Opción Nuevo*/
 
  opcionNuevo(){
    //TAB0
    let empleado:NommaeEmpleado= new NommaeEmpleado;
    this.selectedNomEmpl=empleado;
    this.selectedNomEmpl.NUEVO=true;
    this.reiniciarCampos();
    this.llenarVACIOS();
    this.indexTab=0;
    this.disabledTab1=false;
    this.disabledTab1=false;
    this.disabledTab2=false;
    this.disabledTab3=false;
    this.disabledTab4=false;
    this.disabledTab5=false;
    this.botonAnterior=true;
    this.botonPrimero=true;
    this.botonUltimo=true;
    this.botonSiguiente=true;
    this.btnGuardar=true;
    this.btnBorrar=true;
    this.emp_codaux='';
    try{
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
    }catch(e){

    }
    
  }

  reiniciarCampos(){
    this.span_sex="";
    this.span_JefInme="";
    this.span_JefExt="";
    this.span_centroCos="";
    this.span_CarEmpr="";
    this.span_EstTrab="";
    this.span_CarIess="";
    this.span_TipoRol="";
    this.span_TipEmpl="";
    this.span_esta_civil="";
    this.inputcodigo2="";
    this.inputanombre="";
    this.inputapellido="";
    this.valorNacio ={refnombre: '', REF_CODIGO: ''};
    this.valortipoid={refnombre:'', REF_CODIGO: ''};
    this.pLicen={tipo:'', codigo: ''};
    this.valorProv={refnombre: '', PRO_CODIGO: ''};
    this.cmbCiud=[];
    this.valCausaSal={refnombreb: '', REF_CODIGO: ''};
    this.valorDepart={depnombre: '', DEP_CODIGO:  ''};
    this.valPlaContra={refnombrea:'', REF_CODIGO:''};
    this.pagarretener="";
    this.cvacaciones="";
    this.rdecimotercero="";
    this.rdecimocuarto="";
    this.valorConTrabRescDisc ={CODSRI_DESCRIPCION:'', CODSRI_CODIGO: ''};
    this.valorTIPOSISTSALANETO ={CODSRI_DESCRIPCION: '', CODSRI_CODIGO: ''};
    this.valorRESITRAB ={CODSRI_DESCRIPCION: '', CODSRI_CODIGO: ''};
    this.valorAPLICONVEVITDOBLTRIB ={CODSRI_DESCRIPCION: '', CODSRI_CODIGO: ''};
    this.valorVEN_CODIGO ={VEN_NOMBRE: '', VEN_CODIGO: ''};
    this.varTipCuent ={REF_NOMBRE: '', REF_CODIGO: ''};
    this.span_CODIGOSXP="";
    this.span_CODIGOEMP="";
    this.span_CODIBANCEMPL="";
    this.span_FORMPAGO="";
    this.span_CliCodigo="";
    this.chkactivo=false;
    this.chkEMP_CALC75=false;
    this.chkEMP_FNDSRSRVA=false;
    this.chkEMP_VACACALC=false;
    this.chkEMP_DECITERCCALC=false;
    this.chkEMP_DECICUARCALC=false;
    this.chkImpRent=false;
    this.chkJorPar=false;
    

  }
  async opcionRecargar(){
    
    
    if(this.tab.tabPanels.length==7){
      if(this.indexTab<=2){
        await this.formulario();
      }else if(this.indexTab==3){
        this.tablas();
      }else if(this.indexTab==4){
        await this.tablasVaca();
      }else if(this.indexTab==5){
        await this.tablasCarFam();
      }
    }else{
      if(this.indexTab<=3){
        await this.formulario();
      }else if(this.indexTab==4){
    
        this.tablas();
      }else if(this.indexTab==5){
        await this.tablasVaca();
      }else if(this.indexTab==6){
        await this.tablasCarFam();
      }
     
    }
    //Jona recargar
  }
  async formulario(){
    this.btnGuardar=true;
    this.btnNuevo=false;
    this.btnBorrar=false;
    this.btnRegresar=false;
    this.botonAnterior=false;
    this.botonPrimero=false;
    this.botonUltimo=false;
    this.botonSiguiente=false;
    this.btnBuscar=false;
    this.disabledTab1=false;
    this.disabledTab1=false;
    this.disabledTab2=false;
    this.disabledTab3=false;
    this.disabledTab4=false;
    this.disabledTab5=false;
    if(this.selectedNomEmpl.NUEVO==true){
       this.reiniciarCampos();
      await this.llenarVACIOS();
    }else{
      const re = await this.nomempleadosService.selectEmpleadoProm(this.selectedNomEmpl.EMP_CODIGO);
      await this.llenarVACIOS();
        this.reiniciarCampos()
      await this.llenarFormulario(re[0]); 
    }
  }
  valiOnlyNumbers(valor:string){
   
   let onlynum=/^([0-9]){10}/;
   let va2=onlynum.test(valor);
  return va2;
  
  }
  validarCedula(cedula:string){
    let ci:number[]=[];
    let sum:number=0;
    if(this.valiOnlyNumbers(cedula)==false || cedula.length!=10){
      return false;
    }
    let pro:number=parseInt(cedula.substr(0,2));
    if(pro>=1 && pro<=24 || pro==30){
      for(let id of cedula){
        ci.push(parseInt(id));
      };
      for(let a=0; a<9;a++){
        let su:number=0;
        if(((a+1)%2)==1){
          su=ci[a]*2;
          if(su>9){
            su=su-9;
          }
          sum+=su;
        }else if(((a+1)%2)==0){
          sum+=ci[a];
        }
      }
      let as= sum%10;
      let val:number=0;
      if(as==0){
        val=0;
        if(ci[9]==val){
          return true;
        }else{
          return false;
        }
      }else{
       val=10-as;
       if(ci[9]==val){
        return true;
      }else{
        return false;
      }
      }
    }else{
      return false;
    }  
  }
  validarPasaporte(pas:string){
    let numlet=/^([A-Za-z0-9]){3,13}$/;
    let va2=numlet.test(pas);
    return va2;
  }
  validarDiscapacidad(valor){
    let onlynum=/^([0-9])/;
    let va2=onlynum.test(valor);
    if(va2==false){
      return false;
    }
    let num=parseInt(valor);
    if(num>=0 && num<=100){
      return true;
    }else{
      return false;
    }
  }
  validadorRUC(ruc:string){
    let rucarr:number[]=[];
    let rucsub:string=ruc.substr(0,10);
    let personaJuridica=[4,3,2,7,6,5,4,3,2];
    let instipublica=[3,2,7,6,5,4,3,2]
    if(this.valiOnlyNumbers(ruc)==false || ruc.length!=13){
      return false;
    }
    for(let id of ruc){
      rucarr.push(parseInt(id));
    };
    if(rucarr[2]>=1&&rucarr[2]<=5){
      if(this.validarCedula(rucsub)==true){
        return true;
      }else{
        return false;
      }
    }else if(rucarr[2]==6){
      if(this.rucvalidador(rucarr,instipublica,8)==true){
        return true;
      }else{
        return false;
      } 
    }else if(rucarr[2]==9){    
      if(this.rucvalidador(rucarr, personaJuridica,9)==true){       
        return true;
      }else{    
        return false;
      }
    }
  }
  rucvalidador(ruc:number[],valid:number[], limit:number){
    let sum:number=0;
    for(let i=0;i<limit;i++){
      switch(i){
        case 0:
          sum+=ruc[0]*valid[0];
          break;
        case 1:
          sum+=ruc[1]*valid[1];
          break;
        case 2:
          sum+=ruc[2]*valid[2];
          break;
        case 3:
          sum+=ruc[3]*valid[3];
          break;
        case 4:
          sum+=ruc[4]*valid[4];
          break;
        case 5:
          sum+=ruc[5]*valid[5];
          break;
        case 6:
          sum+=ruc[6]*valid[6];
          break;
        case 7:
          sum+=ruc[7]*valid[7];
          break;
        case 8:
          sum+=ruc[8]*valid[8];
          break; 
      }
    }
    let mruc:number=sum%11;
    if(mruc==0){
      if(ruc[limit]==0){
        return true;
      }else{
        return false;
      }
    }else{
      let val=11-mruc;
      if(val==ruc[limit]){
        return true;
      }else{
        return false;
      }
    }
  }
  opcionGuardar(){
    if(this.tab.tabPanels.length==6){//SE REGRESÓ A 6 
      if(this.indexTab<=2){
        this.obtenerCheks();
        const valida=this.validarGenerales().then(async vale=>{
          if(vale.errors==false){
            try{
              this.nomempleadosService.agregarEmpleado(this.selectedNomEmpl).then(res=>{
                this.selectedNomEmpl.NUEVO=false;
                this.selectedNomEmpl.EMP_CODIGO=Number(res[0][":B1"]);
                this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
                
                this.nomempleadosService.generarPeriodoVacaciones(this.selectedNomEmpl.EMP_CODIGO).then( res=>{              
                });
                if(this.emp_codaux === '' ){
                  this.auditoriaGralService.registrarAuditoria('NOM_MAEEMPLEADO', 'EMP_CODIGO'+'/'+this.selectedNomEmpl.EMP_CODIGO, 'I',
                        '', '01', '', '', '', '').toPromise().then(() => {
                        }); 
                }else{
                  this.auditoriaGralService.registrarAuditoria('NOM_MAEEMPLEADO',  'EMP_CODIGO'+'/'+this.selectedNomEmpl.EMP_CODIGO, 'A',
                        '', '01', '', '', '', '').toPromise().then(() => {
                        }); 
                }
                this.opcionRecargar();
              });
            }catch(err){
              this.mensajeAlerta('error', 'Error','Ha Ocurrido Un Error En La Transacción');
            }
        }else{
          this.mensajeAlerta('error', 'Error','Los siguientes campos: '+vale.message);
        }
        });  
      }else if(this.indexTab==3){
          this.guar();
      }
      
    }else{
      if(this.indexTab<=3){
        this.obtenerCheks();
        const valida=this.validarGenerales().then(async vale=>{   
          if(vale.errors==false){
            try{
              this.nomempleadosService.agregarEmpleado(this.selectedNomEmpl).then(res=>{
                this.selectedNomEmpl.NUEVO=false;
                this.selectedNomEmpl.EMP_CODIGO=Number(res[0][":B1"]);
                this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
                
                this.nomempleadosService.generarPeriodoVacaciones(this.selectedNomEmpl.EMP_CODIGO).then( res=>{              
                });
                if(this.emp_codaux === '' ){
                  this.auditoriaGralService.registrarAuditoria('NOM_MAEEMPLEADO', 'EMP_CODIGO'+'/'+this.selectedNomEmpl.EMP_CODIGO, 'I',
                        '', '01', '', '', '', '').toPromise().then(() => {
                        }); 
                }else{
                  this.auditoriaGralService.registrarAuditoria('NOM_MAEEMPLEADO',  'EMP_CODIGO'+'/'+this.selectedNomEmpl.EMP_CODIGO, 'A',
                        '', '01', '', '', '', '').toPromise().then(() => {
                        }); 
                }
                this.opcionRecargar();
              });
            }catch(err){
              this.mensajeAlerta('error', 'Error','Ha Ocurrido Un Error En La Transacción');
            }
          }else{
            this.mensajeAlerta('error', 'Error','Los siguientes campos: '+vale.message);
          }
        });  
      }else if(this.indexTab==4){
        this.guar();
      }
      
    }
  }
  obtenerCheks(){
    this.selectedNomEmpl.EMP_ACTIVO=(this.chkactivo)==true?1:0;
    this.selectedNomEmpl.EMP_CALC75=(this.chkEMP_CALC75)==true?1:0;
    this.selectedNomEmpl.EMP_FNDSRSRVA=(this.chkEMP_FNDSRSRVA)==true?1:0;
    this.selectedNomEmpl.EMP_VACACALC=(this.chkEMP_VACACALC)==true?1:0;
    this.selectedNomEmpl.EMP_DECITERCCALC=(this.chkEMP_DECITERCCALC)==true?1:0;
    this.selectedNomEmpl.EMP_DECICUARCALC=(this.chkEMP_DECICUARCALC)==true?1:0;
    this.selectedNomEmpl.EMP_EMPRASUMIMPURENT=(this.chkImpRent)==true?1:0;
    this.selectedNomEmpl.EMP_TRABJORNPARC=(this.chkJorPar)==true?1:0;
  }
  async guar(){
    this.aggrid.gridApi.stopEditing();
    this.egraggrid.gridApi.stopEditing();
    if(this.nomempleadosService.nommaenomIngrFijo.length!=0){
      if(this.nomempleadosService.nommaeEgreFijo.length!=0){
        let egrval:any[]=[];
        let ingval:any[]=[];
        let iingr:number[]=[];
        let iegre:number[]=[];
        let ig:number=1;
        for( let ving of this.nomempleadosService.nommaenomIngrFijo ){
          let pas=this.validarIngresos(ving);
          if(pas.length>0){
            ingval.push(pas);
            iingr.push(ig);
          } 
          ig++;
        }
        let eg:number=1;
        for( let vegr of  this.nomempleadosService.nommaeEgreFijo ){
          let pes=this.validarEgresos(vegr);
          if(pes.length>0){
            egrval.push(pes);
            iegre.push(eg);
          } 
          eg++;
        }
        if(egrval.length==0&&ingval.length==0){
          try{
            for(let ingre of this.nomempleadosService.nommaenomIngrFijo){
              const act= await this.actIngr(ingre);
            }
            for(let ingre of this.nomempleadosService.nommaenomIngrFijo){
              if(ingre.MAEINGRFIJO_NROCUOT!=0&&ingre.MAEINGRFIJO_SALDO==0 && ingre.MAEPERIODO_CODIGO!=''){
                const act= await this.geneIngr(ingre);
              }
            }
            for(let egres of this.nomempleadosService.nommaeEgreFijo){
              const act= await this.actEgr(egres);
            }
            for(let egres of this.nomempleadosService.nommaeEgreFijo){
              if(parseInt(egres.MAEEGREFIJO_NROCUOT)!=0 && parseFloat(egres.MAEEGREFIJO_SALDO)==0 && egres.MAEPERIODO_CODIGO!=''){
                const act= await this.geneEgre(egres);
              }   
            }
            await this.cancelarIngFijo();
            if(isNullOrUndefined(this.selectednomIngrFijo)==false){
              let index= this.nomempleadosService.nommaenomIngrFijo.map(x=>{return x.NUMEGRFIJ}).indexOf(this.selectednomIngrFijo.NUMEGRFIJ);
              this.aggrid.gridApi.setFocusedCell(index , 'CON_CODIGO');
            }
            await this.cancelarEgreFijo();
            if(isNullOrUndefined(this.selectednomEgreFijo)==false){
              let index2= this.nomempleadosService.nommaeEgreFijo.map(x=>{return x.NUMEGRFIJ}).indexOf(this.selectednomEgreFijo.NUMEGRFIJ);             
              this.egraggrid.gridApi.setFocusedCell(index2 , 'CON_CODIGO');
            }
            this.botonesguardarFijo();
            this.mensajeAlerta('success', 'Información', '¡Ingreso y Egresos Actualizados!');
            if(this.selectedNomEmpl.EMP_CODIGO === null || this.selectedNomEmpl.EMP_CODIGO === undefined ||this.selectedNomEmpl.EMP_CODIGO === '' ){
              this.auditoriaGralService.registrarAuditoria('NOM_MAEEMPLEADOIE', 'EMP_CODIGO'+'/'+this.selectedNomEmpl.EMP_CODIGO, 'I',
                    '', '01', '', '', '', '').toPromise().then(() => {
                    }); 
            }else{
              this.auditoriaGralService.registrarAuditoria('NOM_MAEEMPLEADOIE',  'EMP_CODIGO'+'/'+this.selectedNomEmpl.EMP_CODIGO, 'A',
                    '', '01', '', '', '', '').toPromise().then(() => {
                    }); 
            }
          }catch(e){
            this.mensajeAlerta('error', 'Error', e.error);
          }
        }else{
          let i=0;
          let msg="";
          let msg2="";
          if(ingval.length>0){
            msg="En tabla Ingresos: ";
          }
          if(egrval.length>0){
            msg2="En tabla Egresos:";
          }
          for(let a of ingval){           
            msg+=" "+ this.mensajeErrorVacios(a,iingr[i]);
            i++;
          }
          i=0;
          for(let b of egrval){
            msg2+=" "+ this.mensajeErrorVacios(b,iegre[i]);
            i++;
          }
          if(msg!=""){
            this.mensajeAlerta('info', 'Información', msg);
          }
          if(msg2!=""){
            this.mensajeAlerta('info', 'Información', msg2);
          }
        }
      }else{      
        let ingval:any[]=[];
        let iingr:number[]=[];
        let ig:number=1;
        for( let ving of this.nomempleadosService.nommaenomIngrFijo ){
          let pas=this.validarIngresos(ving);
          if(pas.length>0){
            ingval.push(pas);
            iingr.push(ig);
          } 
          ig++;
        }
       
        if(ingval.length==0){
          try{
            for(let ingre of this.nomempleadosService.nommaenomIngrFijo){
              const act= await this.actIngr(ingre);
            }
            for(let ingre of this.nomempleadosService.nommaenomIngrFijo){
              if(ingre.MAEINGRFIJO_NROCUOT!=0&&ingre.MAEINGRFIJO_SALDO==0 && ingre.MAEPERIODO_CODIGO!=''){
                const act= await this.geneIngr(ingre);
              }
            }

            await this.cancelarIngFijo();
            if(isNullOrUndefined(this.selectednomIngrFijo)==false){
              let index= this.nomempleadosService.nommaenomIngrFijo.map(x=>{return x.NUMEGRFIJ}).indexOf(this.selectednomIngrFijo.NUMEGRFIJ);
              this.aggrid.gridApi.setFocusedCell(index , 'CON_CODIGO');
            }
            this.botonesguardarFijo();
            this.mensajeAlerta('success', 'Información', '¡Ingreso y Egresos Actualizados!');
            if(this.selectedNomEmpl.EMP_CODIGO === null || this.selectedNomEmpl.EMP_CODIGO === undefined ||this.selectedNomEmpl.EMP_CODIGO === '' ){
              this.auditoriaGralService.registrarAuditoria('NOM_MAEEMPLEADOIE', 'EMP_CODIGO'+'/'+this.selectedNomEmpl.EMP_CODIGO, 'I',
                    '', '01', '', '', '', '').toPromise().then(() => {
                    }); 
            }else{
              this.auditoriaGralService.registrarAuditoria('NOM_MAEEMPLEADOIE',  'EMP_CODIGO'+'/'+this.selectedNomEmpl.EMP_CODIGO, 'A',
                    '', '01', '', '', '', '').toPromise().then(() => {
                    }); 
            }
          }catch(e){
            this.mensajeAlerta('error', 'Error', e.error);
          }
        }else{
          let i=0;
          let msg="";
          if(ingval.length>0){
            msg="En tabla Ingresos: ";
          }
          for(let a of ingval){           
            msg+=" "+ this.mensajeErrorVacios(a,iingr[i]);
            i++;
          }
          if(msg!=""){
            this.mensajeAlerta('info', 'Información', msg);
          }
        }
      }
    }else{
      if(this.nomempleadosService.nommaeEgreFijo.length!=0){
        let egrval:any[]=[];
        let eg:number=1;
        let iegre:number[]=[];
        for( let vegr of  this.nomempleadosService.nommaeEgreFijo ){
          let pes=this.validarEgresos(vegr);
          if(pes.length>0){
            egrval.push(pes);
            iegre.push(eg);
          } 
          eg++;
        }
       
        if(egrval.length==0){
          try{
            for(let egres of this.nomempleadosService.nommaeEgreFijo){
              const act= await this.actEgr(egres);
            }
            for(let egres of this.nomempleadosService.nommaeEgreFijo){
              if(egres.MAEEGREFIJO_NROCUOT!=0 && egres.MAEEGREFIJO_SALDO==0 && egres.MAEPERIODO_CODIGO!=''){
                const act= await this.geneEgre(egres);
              }       
            }
            await this.cancelarEgreFijo();
            if(isNullOrUndefined(this.selectednomEgreFijo)==false){
              let index2= this.nomempleadosService.nommaeEgreFijo.map(x=>{return x.NUMEGRFIJ}).indexOf(this.selectednomEgreFijo.NUMEGRFIJ);             
              this.egraggrid.gridApi.setFocusedCell(index2 , 'CON_CODIGO');
            }
            this.botonesguardarFijo();
            this.mensajeAlerta('success', 'Información', '¡Ingresos y Egresos Actualizados!');

            if(this.selectedNomEmpl.EMP_CODIGO === null || this.selectedNomEmpl.EMP_CODIGO === undefined ||this.selectedNomEmpl.EMP_CODIGO === '' ){
              this.auditoriaGralService.registrarAuditoria('NOM_MAEEMPLEADOIE', 'EMP_CODIGO'+'/'+this.selectedNomEmpl.EMP_CODIGO, 'I',
                    '', '01', '', '', '', '').toPromise().then(() => {
                    }); 
            }else{
              this.auditoriaGralService.registrarAuditoria('NOM_MAEEMPLEADOIE',  'EMP_CODIGO'+'/'+this.selectedNomEmpl.EMP_CODIGO, 'A',
                    '', '01', '', '', '', '').toPromise().then(() => {
                    }); 
            }
          }catch(e){
            this.mensajeAlerta('error', 'Error', e.error);
          }  
          }else{
            let i=0;    
            let msg2="";
            if(egrval.length>0){
              msg2="En tabla Egresos:";
            }
            for(let b of egrval){
              msg2+=" "+ this.mensajeErrorVacios(b,iegre[i]);
              i++;
            }
            if(msg2!=""){
              this.mensajeAlerta('info', 'Información', msg2);
            }
        }
      }
    }
 }
 mensajeErrorVacios(men,pos){
  let mensaje=""
  for(let i=0;i<men.length;i++){
    if(i==0){
      mensaje+=men[0];
    }else{
      mensaje+=", "+men[i];
    }
    mensaje+=" en la columna "+pos;
  }
  return mensaje;
  //this.mensajeAlerta('info', 'Información', mensaje);
 }
 async actIngr(ing:nomIngrFijo){
  try{
    const rs = await this.nomempleadosService.actuinfoingrfijo(ing,this.selectedNomEmpl.EMP_CODIGO,ing.NUMEGRFIJ);
    this.nomempleadosService.nommaenomIngrFijo[ing.NUMEGRFIJ].MAEINGRFIJO_CODIGO=rs[0][":B1"]; 
  }catch(e){
    console.error(e);
      this.mensajeAlerta('error', 'Error', e.error);
  } 
}
async geneIngr(ing:nomIngrFijo){
  await this.nomempleadosService.genecuotingrfijo(this.selectednomIngrFijo).then(async res=>{ 
  }).catch( e => {
    console.error(e);
  } )
}
async actEgr(egre){
  try{
      await this.nomempleadosService.actuinfoegrefijo(egre,this.selectedNomEmpl.EMP_CODIGO,egre.NUMEGRFIJ).then(async x=>{
          this.nomempleadosService.nommaeEgreFijo[egre.NUMEGRFIJ].MAEEGREFIJO_CODIGO=x[0][":B1"];
      })    
  }catch(e){
    console.error(e);
    this.mensajeAlerta('error', 'Error', e.error);
  }    
}
async geneEgre(egre){
  try{
      await this.nomempleadosService.genecuotegrefijo(egre).then(async re=>{
          
      })
  }catch(e){
    console.error(e);
      //this.mensajeAlerta('error', 'Error', e.error);
  }    
}

 async guardarIngr(){
   try{
           if(parseInt(this.selectednomIngrFijo.MAEINGRFIJO_NROCUOT)>0){
             await this.nomempleadosService.actuinfoingrfijo(this.selectednomIngrFijo,this.selectedNomEmpl.EMP_CODIGO,this.selectednomIngrFijo.NUMEGRFIJ).then(async x=>{
               this.selectednomIngrFijo.MAEINGRFIJO_CODIGO=x[0][":B1"];
               await this.nomempleadosService.genecuotingrfijo(this.selectednomIngrFijo).then(async res=>{ 
                 this.mensajeAlerta('success', 'Información', '¡Ingreso fijo agregado!');               
               });
             });
           }else{
             this.mensajeAlerta('info', 'Información', 'El número de coutas no puede ser menor o igual a 0 en Ingreso Fijo');
           }
     }catch(e){
      console.error(e);
        this.mensajeAlerta('error', 'Error', e.error);
     } 
 }
  botonesguardarFijo(){
    this.btnGuardar=true;
    this.btnNuevo=false;
    this.btnBorrar=true;
    this.btnRegresar=false;
    this.botonAnterior=false;
    this.botonPrimero=false;
    this.botonUltimo=false;
    this.btnBuscar=false;
    this.botonSiguiente=false;
  }
  validarIngresos(nomIngrFijo:nomIngrFijo){ 
    let valido:string[]= [];
   /* if(nomIngrFijo.CON_CODIGO === null||nomIngrFijo.CON_CODIGO=== ''){
     valido = false;
   }
   if(nomIngrFijo.MAEINGRFIJO_DESCRIPCION=== null||nomIngrFijo.MAEINGRFIJO_DESCRIPCION=== ''){
     valido = false;
   } */
   if(nomIngrFijo.MAEINGRFIJO_FECHREGI=== null||nomIngrFijo.MAEINGRFIJO_FECHREGI=== ''){
     valido.push('Ingrese F.Registro');
   }
   if(nomIngrFijo.MAEINGRFIJO_FECINI=== null||nomIngrFijo.MAEINGRFIJO_FECINI=== ''){
    valido.push('Ingrese F.Inicio');
   }
   if(nomIngrFijo.MAEINGRFIJO_FECFIN=== null||nomIngrFijo.MAEINGRFIJO_FECFIN=== ''){
    valido.push('Ingrese F.Fin');
   }
   if(nomIngrFijo.MAERUBRO_CODIGO2=== null||nomIngrFijo.MAERUBRO_CODIGO2=== ''){
    valido.push('Ingrese código del rubro');
   }
   /* if(nomIngrFijo.MAEPERIODO_CODIGO=== null||nomIngrFijo.MAEPERIODO_CODIGO=== ''){
    valido.push('Ingrese código del periodo');
   } */
   return valido;
 }
 validarEgresos(nomEgreFijo:nomEgreFijo){
  let valido:string[]= [];
  /* if(nomEgreFijo.CON_CODIGO === null||nomEgreFijo.CON_CODIGO=== ''){
    valido = false;
  }
  if(nomEgreFijo.MAEEGREFIJO_DESCRIPCION=== null||nomEgreFijo.MAEEGREFIJO_DESCRIPCION=== ''){
    valido = false;
  } */
  if(nomEgreFijo.MAEEGREFIJO_FECHREGI=== null||nomEgreFijo.MAEEGREFIJO_FECHREGI=== ''){
    valido.push('Ingrese F.Registro');
  }
  if(nomEgreFijo.MAEEGREFIJO_FECINI=== null||nomEgreFijo.MAEEGREFIJO_FECINI=== ''){
    valido.push('Ingrese F.Inicio');
  }
  if(nomEgreFijo.MAEEGREFIJO_FECFIN=== null||nomEgreFijo.MAEEGREFIJO_FECFIN=== ''){
    valido.push('Ingrese F.Fin');
  }
  if(nomEgreFijo.MAERUBRO_CODIGO2=== null||nomEgreFijo.MAERUBRO_CODIGO2=== ''){
    valido.push('Ingrese código del rubro');
  }
 /*  if(nomEgreFijo.MAEPERIODO_CODIGO=== null||nomEgreFijo.MAEPERIODO_CODIGO=== ''){
    valido.push('Ingrese código del periodo');
  } */
 return valido;
}
  opcionBorrar(){
      if ( this.selectedNomEmpl !== undefined ) {
        this.confirmationService.confirm({
          message: '¿Está seguro que desea eliminar?',
          header: 'Confirmación',
          icon: 'pi pi-exclamation-triangle',
          key: 'NomMaeEmplEliminar',
          acceptLabel: 'Sí',
          rejectLabel: 'No',
          accept: () => {
            this.nomempleadosService.eliminarEmpleado(this.selectedNomEmpl.EMP_CODIGO).then(async res=>{
              this.mensajeAlerta('success', 'Información', '¡Empleado eliminado!');
              await this.nomempleadosService.selectPrimero().then(async res=>{
                await this.nomempleadosService.selectEmpleado(res[0].EMP_CODIGO).then(async re=>{
                  this.llenarFormulario(re[0]);
                  
                })
              })
               this.auditoriaGralService.registrarAuditoria('NOM_MAEEMPLEADO',  'EMP_CODIGO'+'/'+this.emp_codaux, 'E',
              '', '01', '', '', '', '').toPromise().then(() => {
              }); 
            }).catch(e=>{
              let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
            this.mensajeAlerta('error', 'Error', mensaje);
            })
          },
          reject: () => {
          }
        })
      }
  }
  
  
  async validarGenerales(){
    let mensaje="";
    let error:boolean=false;
    if(this.selectedNomEmpl.EMP_NOMBRE==""|| isNullOrUndefined(this.selectedNomEmpl.EMP_NOMBRE)){
      mensaje+='Nombres esta vacío';
      error=true;
    }
    if(this.selectedNomEmpl.EMP_APELLIDO=="" || isNullOrUndefined(this.selectedNomEmpl.EMP_APELLIDO)){
      mensaje+=', Apellidos esta vacío';
      error=true;
    }
    if(this.selectedNomEmpl.DEP_CODIGO=="" || isNullOrUndefined(this.selectedNomEmpl.DEP_CODIGO)){
      mensaje+=', Departamento esta vacío';
      error=true;
    }
    if(this.selectedNomEmpl.EMP_RUCIDE=="" || isNullOrUndefined(this.selectedNomEmpl.EMP_RUCIDE)){
      mensaje+=', Cédula/Pasaporte esta vacío';
      error=true;
    }
    
    if(this.selectedNomEmpl.EMP_PORCDISC=="" || isNullOrUndefined(this.selectedNomEmpl.EMP_PORCDISC)){
    }else if(this.validarDiscapacidad(this.selectedNomEmpl.EMP_PORCDISC)==false){
      mensaje+=', %Discapacidad no válida';
      error=true;
    }
    if(this.selectedNomEmpl.EMP_FECINGRESO=="" || isNullOrUndefined(this.selectedNomEmpl.EMP_FECINGRESO)){
      mensaje+=', Fecha de ingreso esta vacío';
      error=true;
    }
   if(this.valortipoid.REF_CODIGO=="" || isNullOrUndefined(this.valortipoid.REF_CODIGO)){
    mensaje+=', Tipo de identificación no seleccionado';
    error=true;
   }else{
   
      switch(parseInt(this.valortipoid.REF_CODIGO)){
        case 2:
         
          if(this.validarCedula(this.selectedNomEmpl.EMP_RUCIDE)==true){
            
          }else{
            mensaje+=', Cédula incorrecta';
            error=true;
          };
          break;
        case 3:
         
          if(this.validarPasaporte(this.selectedNomEmpl.EMP_RUCIDE)==true){
           
          }else{
            mensaje+=', Cédula incorrecta';
            error=true;
          };
          break
        case 4:
          if(this.validarPasaporte(this.selectedNomEmpl.EMP_RUCIDE)==true){
           
          }else{
            mensaje+=', Documento inválido';
            error=true;
          };
          break
      }
    }
    
    if(this.selectedNomEmpl.EMP_CODIGO=="" || isNullOrUndefined(this.selectedNomEmpl.EMP_CODIGO)){
      await this.nomempleadosService.verificarFecha(this.selectedNomEmpl.EMP_RUCIDE,this.selectedNomEmpl.EMP_FECINGRESO).then(res=>{
       
        if(parseInt(res[0][":B1"])==1){
        }else{
          mensaje+=', Existe otro empleado con el mismo RUC y no cumple con la condición fecha de ingreso mayor a la fecha de salida';
          error=true;
        }
      })
     }else{
     }
     
     //CDPJ
     if (this.selectedNomEmpl.CON_CODIGOSXP !== null && this.selectedNomEmpl.CON_CODIGOSXP !== undefined && this.selectedNomEmpl.CON_CODIGOSXP !== '') {//GSRF
      if (this.selectedNomEmpl.CON_CODIGOSXP.substr(-1, 1) === '.') {
        mensaje+=', En Cta Contable Sueldo por Pagar,no se puede elegir esa cuenta contable';
        error=true;
      }
     }
    
    if (this.selectedNomEmpl.CON_CODIGOSXP !== null && this.selectedNomEmpl.CON_CODIGOSXP !== undefined && this.selectedNomEmpl.CON_CODIGOSXP !== '') {
      const data = await this.nomempleadosService.EncontrarCnta(this.selectedNomEmpl.CON_CODIGOSXP);
    if (data !== null && data !== undefined) {
      for (const data1 of data) {
        if (Number(data1.NUMERO) === 0) {
          mensaje+=', En Cta Contable Sueldo por Pagar, la cuenta contable no existe';
         // document.getElementById('inpCODIGOSXP').focus();
          error=true;
        }
      }
    }
      
     
    }
    let fechainicio=''//CDPJ
    let fechasalida=''//CDPJ

    fechainicio=this.selectedNomEmpl.EMP_FECINGRESO
    fechasalida=this.selectedNomEmpl.EMP_FECSALIDA
    // fechainicio.setHours(0,0,0,0)
    // fechasalida.setHours(0,0,0,0);
    console.log(fechainicio)
    console.log(fechasalida)

    if (fechasalida < fechainicio && this.chksalida === true) {
      mensaje+=', La fecha de salida no puede ser menor a la fecha de ingreso';
    

      error=true;
    }
    console.log('activecheck',this.chkactivo)
    console.log('activeori',this.chkactivoOri)
    console.log('fecsa',this.cambiarfechanull(this.selectedNomEmpl.EMP_FECSALIDA))
    if(this.cambiarfechanull(this.selectedNomEmpl.EMP_FECSALIDA) === ''
    && this.chkactivoOri === true
    && this.chkactivo === false
    && this.selectedNomEmpl.EMP_CODIGO !== null
    && this.selectedNomEmpl.EMP_CODIGO !== undefined
    && this.selectedNomEmpl.EMP_CODIGO !== ''){
      mensaje+=', El check de activo solo se puede quitar si está ingresada la fecha de salida';
      error=true;
    }

    //CDPJ
    //CDPJ
    return {errors:error,message:mensaje};
   
  }
  validarfechaingreso(){

  }
  async Primero(){

    
    const res = await this.nomempleadosService.selectPrimeroProm();
    const re = await this.nomempleadosService.selectEmpleadoProm(res[0].EMP_CODIGO);
    await this.llenarVACIOS();
    this.reiniciarCampos();
    await this.llenarFormulario(re[0]);
        try{
          document.getElementById('divPrincipal').style.pointerEvents = 'all';
        }catch(e){

        }
    /*await this.nomempleadosService.selectPrimero().then(async res=>{
      console.log(res);
      await this.nomempleadosService.selectEmpleado(res[0].EMP_CODIGO).then(async re=>{
        console.log(re);
        this.llenarVACIOS();
        this.reiniciarCampos();
        await this.llenarFormulario(re[0]);
        try{
          document.getElementById('divPrincipal').style.pointerEvents = 'all';
        }catch(e){

        }
       
      })
    })*/
  };
  async Ultimo(){
   
    const res = await this.nomempleadosService.selectUltimoProm();
    const re = await this.nomempleadosService.selectEmpleadoProm(res[0].EMP_CODIGO);
    await this.llenarVACIOS();
    this.reiniciarCampos();
    await this.llenarFormulario(re[0]);
    try{
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
    }catch(e){

    }  
    /*this.nomempleadosService.selectUltimo().then( async res=>{
      await this.nomempleadosService.selectEmpleado(res[0].EMP_CODIGO).then(async re=>{
        this.llenarVACIOS();
        this.reiniciarCampos();
        await this.llenarFormulario(re[0]);
        try{
         document.getElementById('divPrincipal').style.pointerEvents = 'all';
        }catch(e){

        }  
      })
    })*/
  }
  async Anterior(){ 
    
    const res = await this.nomempleadosService.selectAnterior(this.selectedNomEmpl.EMP_APELLIDO,this.selectedNomEmpl.EMP_NOMBRE);
    const re = await this.nomempleadosService.selectEmpleadoProm(res[0].EMP_CODIGO);
    await this.llenarVACIOS();
    this.reiniciarCampos();
    console.log(re);
    await this.llenarFormulario(re[0]);
    try{
    document.getElementById('divPrincipal').style.pointerEvents = 'all';
    }catch(e){

    } 
      

    /* this.nomempleadosService.selectAnterior(this.selectedNomEmpl.EMP_APELLIDO,this.selectedNomEmpl.EMP_NOMBRE).then(async res=>{
      await this.nomempleadosService.selectEmpleado(res[0].EMP_CODIGO).then(async re=>{
      
        await this.llenarFormulario(re[0]);
        try{
        document.getElementById('divPrincipal').style.pointerEvents = 'all';
        }catch(e){

        }  
      })

    })*/
  }
  async Siguiente(){
    
    const res = await this.nomempleadosService.selectSiguiente(this.selectedNomEmpl.EMP_APELLIDO,this.selectedNomEmpl.EMP_NOMBRE);
    const re = await this.nomempleadosService.selectEmpleadoProm(res[0].EMP_CODIGO);
    await this.llenarVACIOS();
    this.reiniciarCampos();
    console.log(re);
    await this.llenarFormulario(re[0]);         
        try{
          document.getElementById('divPrincipal').style.pointerEvents = 'all';
        }catch(e){

        }
    /*this.nomempleadosService.selectSiguiente(this.selectedNomEmpl.EMP_APELLIDO,this.selectedNomEmpl.EMP_NOMBRE).then(async res=>{
      await this.nomempleadosService.selectEmpleado(res[0].EMP_CODIGO).then(async re=>{      
        this.llenarVACIOS();
        this.reiniciarCampos();
        await this.llenarFormulario(re[0]);         
        try{
          document.getElementById('divPrincipal').style.pointerEvents = 'all';
        }catch(e){

        }
      })
    })*/
  }
  async llenarFormulario(nommaeEmpleado:NommaeEmpleado){
    this.spin=true;
    this.indexTab = 0;
        this.selectedNomEmpl=nommaeEmpleado;
        this.selectedNomEmpl.NUEVO=false;

        console.log(this.tab.tabPanels.length);
       
        if(this.tab.tabPanels.length==6){
          switch(this.indexTab){
            case 0:
              try{
                await this.llenarGenerales(this.selectedNomEmpl);
                await this.llenarValores(this.selectedNomEmpl);
              }catch(e){

              }
              break;
            case 1:
              this.llenarOtrosDatos(this.selectedNomEmpl);
              break;
            case 2:
              this.llenarFormaPago(this.selectedNomEmpl)
              break;
            case 3:
              this.tablas();
              break;
            case 4:
              this.tablasVaca();
              break;
            case 5:
              this.tablasCarFam();                
              break;
          }
        }else{
          switch(this.indexTab){
            case 0:
              try{
                this.llenarGenerales(this.selectedNomEmpl);
                this.llenarValores(this.selectedNomEmpl);
              }catch(e){

              }
              break;
            case 1:
              this.llenarValores(this.selectedNomEmpl);
              break;
            case 2:
              this.llenarOtrosDatos(this.selectedNomEmpl);
              break;
            case 3:
              this.llenarFormaPago(this.selectedNomEmpl)
              break;
            case 4:
                this.tablas();
                break;
            case 5:
                this.tablasVaca();
                break;
            case 6:
              this.tablasCarFam();                
              break;
          }
        }
        this.spin=false;
  }
    async llenarGenerales(nommaeEmpleado){
      this.chkactivo=this.cambiarchkAct(this.selectedNomEmpl.EMP_ACTIVO);
        console.log(nommaeEmpleado);
        this.actualizarTipID(nommaeEmpleado.EMP_TIPOIDE);
        this.actualizarNacio(nommaeEmpleado.EMP_NACIONALIDAD);
        this.actualizarLicEmpr(nommaeEmpleado.EMP_LICENCIA);
        this.actualizarPlazoContra(nommaeEmpleado.EMP_PLAZOCONTRATO) ;
        this.actualizarCausaSalida(nommaeEmpleado.EMP_CAUSSALI);
        this.actualizarDepart(nommaeEmpleado.DEP_CODIGO);
        this.actualizarProv(nommaeEmpleado.PROV_CODIGO);
        await this.cargarCiudad(nommaeEmpleado.PROV_CODIGO);
        this.actualizarCiud(nommaeEmpleado.PROV_CODIGOC);
        this.selectedNomEmpl.EMP_FECHNACI=this.cambiarfechanull(nommaeEmpleado.EMP_FECHNACI);
        this.selectedNomEmpl.EMP_FECINGRESO=this.cambiarfechanull(nommaeEmpleado.EMP_FECINGRESO);
        this.selectedNomEmpl.EMP_FECHACONTRATO=this.cambiarfechanull(nommaeEmpleado.EMP_FECHACONTRATO);
        this.selectedNomEmpl.EMP_FECSALIDA=this.cambiarfechanull(nommaeEmpleado.EMP_FECSALIDA);  
        this.selectedNomEmpl.EMP_LICENCADUCA=this.cambiarfechanull(nommaeEmpleado.EMP_LICENCADUCA);  
        this.selectedNomEmpl.EMP_LUGARNACI=nommaeEmpleado.EMP_LUGARNACI; 
        this.emp_codaux=nommaeEmpleado.EMP_CODIGO//CDPJ
        if(this.selectedNomEmpl.EMP_FECSALIDA==""){
          this.chksalida=false;
          this.calsalifech=true;
        }else{
          this.chksalida=true;
          this.calsalifech=false;
        } 
        console.log(this.selectedNomEmpl);
        await this.llenarEmpleado(this.selectedNomEmpl); 
    }
    cambiarfechanull(valor){
      let res="";
      if(isNullOrUndefined(valor)){
        return res;
      }else{
        try{
          if(valor.toString().includes('-')){
            res=this.pipe.transform(valor,'dd/MM/yyyy');
            return res;
          }else{
            return valor;
          }
        }catch(err){         
          return valor;
        } 
      }
    }
  //CDPJ
  cambiarfechanullValida(valor){
    let res="";
    if(isNullOrUndefined(valor)){
      return res;
    }else{
      try{
        console.log('valor',valor)
        if(valor.toString().includes('-')){
          res=this.datePipe.transform(valor,'dd/MM/yyyy');
          return res;
        }else{
          return valor;
        }
      }catch(err){         
        return valor;
      } 
    }
  }
  //CDPJ
    llenarValores(nommaeEmpleado:NommaeEmpleado){
      this.selectedNomEmpl.EMP_REMSALUNI=this.cambiarFloatFixed(this.selectedNomEmpl.EMP_REMSALUNI);
      this.selectedNomEmpl.EMP_ALIMENTACION=this.cambiarFloatFixed(this.selectedNomEmpl.EMP_ALIMENTACION);
      this.selectedNomEmpl.EMP_BONRESP=this.cambiarFloatFixed(this.selectedNomEmpl.EMP_BONRESP);
      this.selectedNomEmpl.EMP_ETI1ING=this.cambiarFloatFixed(this.selectedNomEmpl.EMP_ETI1ING);
      this.selectedNomEmpl.EMP_COMPSALA=this.cambiarFloatFixed(this.selectedNomEmpl.EMP_COMPSALA);
      this.selectedNomEmpl.EMP_ETI2ING=this.cambiarFloatFixed(this.selectedNomEmpl.EMP_ETI2ING);
      this.selectedNomEmpl.EMP_ANTICIPO=this.cambiarFloatFixed(this.selectedNomEmpl.EMP_ANTICIPO);
      this.selectedNomEmpl.EMP_SEGUROFP=this.cambiarFloatFixed(this.selectedNomEmpl.EMP_SEGUROFP);
      this.selectedNomEmpl.EMP_PRESIESS=this.cambiarFloatFixed(this.selectedNomEmpl.EMP_PRESIESS);
      this.selectedNomEmpl.EMP_MOVILIZACION=this.cambiarFloatFixed(this.selectedNomEmpl.EMP_MOVILIZACION);
      this.selectedNomEmpl.EMP_PRESTHIPOTECARIO=this.cambiarFloatFixed(this.selectedNomEmpl.EMP_PRESTHIPOTECARIO);
      this.selectedNomEmpl.EMP_PRESTAMO=this.cambiarFloatFixed(this.selectedNomEmpl.EMP_PRESTAMO);
      this.selectedNomEmpl.EMP_TELEFONO=this.cambiarFloatFixed(this.selectedNomEmpl.EMP_TELEFONO);
      this.selectedNomEmpl.EMP_PORCAPORINDI=this.cambiarFloatFixed(this.selectedNomEmpl.EMP_PORCAPORINDI);
      this.selectedNomEmpl.EMP_PORCAPORPATR=this.cambiarFloatFixed(this.selectedNomEmpl.EMP_PORCAPORPATR);
      this.selectedNomEmpl.EMP_PORCFONDRESER=this.cambiarFloatFixed(this.selectedNomEmpl.EMP_PORCFONDRESER);
      this.chkEMP_CALC75=this.cambiarchk(this.selectedNomEmpl.EMP_CALC75);
      this.chkEMP_FNDSRSRVA=this.cambiarchk(this.selectedNomEmpl.EMP_FNDSRSRVA);
      this.chkEMP_VACACALC=this.cambiarchk(this.selectedNomEmpl.EMP_VACACALC);
      this.chkEMP_DECITERCCALC=this.cambiarchk(this.selectedNomEmpl.EMP_DECITERCCALC);
      this.chkEMP_DECICUARCALC=this.cambiarchk(this.selectedNomEmpl.EMP_DECICUARCALC);
      this.llenarfondosreserva(); 
      this.llenarvacaciones(); 
      this.llenardecimotercero(); 
      this.llenardecimocuarto();
      this.llenarCODIREMUSALAUNIF(nommaeEmpleado.CON_CODIREMUSALAUNIF);
      this.llenarCON_CODIALIM(nommaeEmpleado.CON_CODIALIM);
      this.llenarCON_CODIBONIRESP(nommaeEmpleado.CON_CODIBONIRESP);
      this.llenarCON_CODIETI1ING(nommaeEmpleado.CON_CODIETI1ING);
      this.llenarCTACOMPSALA(nommaeEmpleado.EMP_CTACOMPSALA);
      this.llenarCON_CODIETI2ING(nommaeEmpleado.CON_CODIETI2ING);
      this.llenarCTACALC75(nommaeEmpleado.EMP_CTACALC75);
      this.llenarCON_CODIANTI(nommaeEmpleado.CON_CODIANTI);
      this.llenarCON_CODISEGUPRIV(nommaeEmpleado.CON_CODISEGUPRIV);
      this.llenarCON_CODIPRESIESSQ(nommaeEmpleado.CON_CODIPRESIESSQ);
      this.llenarCON_CODIMOVI(nommaeEmpleado.CON_CODIMOVI);
      this.llenarCON_CODIPRESHIPO(nommaeEmpleado.CON_CODIPRESHIPO);
      this.llenarCON_CODITELE(nommaeEmpleado.CON_CODITELE);
      this.llenarCON_CODIGOPRES(nommaeEmpleado.CON_CODIGOPRES);
    }
    cambiarFloatFixed(valor){
      let res="";
      let resp;
      if(isNullOrUndefined(valor)){
        return res;
      }else{
        resp=(parseFloat(valor)).toFixed(2);
        if(isNaN(resp)){
          return res;
        }else{
          return resp;
        }
        
      }
    }
    cambiarchk(valor){
      if(isNullOrUndefined(valor)){
        return false;
      }
      let num=parseInt(valor);
      if(num===0){
        return false;
      }else if(num===1){
        return true;
      }
    }
    //CDPJ
    cambiarchkAct(valor){
      if(isNullOrUndefined(valor)){

        return false;
      }
      let num=parseInt(valor);
      if(num===0){
        this.validacheck=false;
        this.chkactivoOri=false
        return false;
      }else if(num===1){
        console.log(this.cambiarfechanull(this.selectedNomEmpl.EMP_FECSALIDA))
        if(this.cambiarfechanull(this.selectedNomEmpl.EMP_FECSALIDA) === ''){
          this.validacheck=true;
        }else{
          this.validacheck=false;
        }
        console.log('chek',this.validacheck)
        this.chkactivoOri=true
        return true;
      }
    }
    //CDPJ
    llenarCODIREMUSALAUNIF(parametro){
      if (parametro == null || parametro== undefined) {
        parametro = '';
      }
      this.opcion = 'BuscarCODIREMUSALAUNIF';
      this.types = [
        {label: 'CODIGO', value: 'CON_CODIGO'},
        {label: 'NOMBRE', value: 'CON_NOMBRE'},
        {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
      ];
      this.busquedacampos = [parametro, ''];
      this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
      this.tabla = 'CON_MAECON';
      this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
      this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
        if(res!=null){
          if (res[0] != undefined) {
            if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
              this.selectedNomEmpl.CON_CODIREMUSALAUNIF='';
            }else{
              this.selectedNomEmpl.CON_CODIREMUSALAUNIF=res[0].CON_CODIGO;
            }
          }else{
            this.selectedNomEmpl.CON_CODIREMUSALAUNIF='';
          }
        }else{
          this.selectedNomEmpl.CON_CODIREMUSALAUNIF='';
        }
      });
    }
    llenarCON_CODIALIM(parametro){
      if (parametro == null || parametro== undefined) {
        parametro = '';
      }
      this.opcion = 'BuscarCODIREMUSALAUNIF';
      this.types = [
        {label: 'CODIGO', value: 'CON_CODIGO'},
        {label: 'NOMBRE', value: 'CON_NOMBRE'},
        {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
      ];
      this.busquedacampos = [parametro, ''];
      this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
      this.tabla = 'CON_MAECON';
      this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
     
      this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
        if(res!=null){
          if (res[0] != undefined) {
            if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
              this.selectedNomEmpl.CON_CODIALIM='';
            }else{
              this.selectedNomEmpl.CON_CODIALIM=res[0].CON_CODIGO;
            }          
          }else{
            this.selectedNomEmpl.CON_CODIALIM='';
          }
        }else{
          this.selectedNomEmpl.CON_CODIALIM='';
        }
      });
    };
    llenarCON_CODIBONIRESP(parametro){
      if (parametro == null || parametro== undefined) {
        parametro = '';
      }
      this.opcion = 'BuscarCODIREMUSALAUNIF';
      this.types = [
        {label: 'CODIGO', value: 'CON_CODIGO'},
        {label: 'NOMBRE', value: 'CON_NOMBRE'},
        {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
      ];
      this.busquedacampos = [parametro, ''];
      this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
      this.tabla = 'CON_MAECON';
      this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
      this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
        if(res!=null){
          if (res[0] != undefined) {
            if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
              this.selectedNomEmpl.CON_CODIBONIRESP='';
            }else{
              this.selectedNomEmpl.CON_CODIBONIRESP=res[0].CON_CODIGO;
            }
          }else{
            this.selectedNomEmpl.CON_CODIBONIRESP='';
          }
        }else{
          this.selectedNomEmpl.CON_CODIBONIRESP='';
        }
      });
    }
    llenarCON_CODIETI1ING(parametro){
      if (parametro == null || parametro== undefined) {
        parametro = '';
      }
      this.opcion = 'BuscarCODIREMUSALAUNIF';
      this.types = [
        {label: 'CODIGO', value: 'CON_CODIGO'},
        {label: 'NOMBRE', value: 'CON_NOMBRE'},
        {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
      ];
      this.busquedacampos = [parametro, ''];
      this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
      this.tabla = 'CON_MAECON';
      this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
     
      this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
        if(res!=null){
          if (res[0] != undefined) {
            if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
              this.selectedNomEmpl.CON_CODIETI1ING='';
            }else{
              this.selectedNomEmpl.CON_CODIETI1ING=res[0].CON_CODIGO;
            }
            
          }else{
            this.selectedNomEmpl.CON_CODIETI1ING='';
          }
        }else{
          this.selectedNomEmpl.CON_CODIETI1ING='';
        }
      });
    }
    llenarCTACOMPSALA(parametro){
      if (parametro == null || parametro== undefined) {
        parametro = '';
      }
      this.opcion = 'BuscarCompSala';
      this.types = [
        {label: 'CODIGO', value: 'CON_CODIGO'},
        {label: 'NOMBRE', value: 'CON_NOMBRE'},
        {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
        //CNT. COSTOS
      ];
      this.busquedacampos = [parametro, ''];
      this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
      this.tabla = 'CON_MAECON';
      this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
     
      this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
        if(res!=null){
          if (res[0] != undefined) {
            if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
              this.selectedNomEmpl.EMP_CTACOMPSALA='';
            }else{
              this.selectedNomEmpl.EMP_CTACOMPSALA=res[0].CON_CODIGO;
            }
          }else{
            this.selectedNomEmpl.EMP_CTACOMPSALA='';
          }
        }else{
          this.selectedNomEmpl.EMP_CTACOMPSALA='';
        }
      });
    }
    llenarCON_CODIETI2ING(parametro){
      if (parametro == null || parametro== undefined) {
        parametro = '';
      }
      this.opcion = 'BuscarCODIETI2ING';
      this.types = [
        {label: 'CODIGO', value: 'CON_CODIGO'},
        {label: 'NOMBRE', value: 'CON_NOMBRE'},
        {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
      ];
      this.busquedacampos = [parametro, ''];
      this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
      this.tabla = 'CON_MAECON';
      this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
     
      this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
        if(res!=null){
          if (res[0] != undefined) {
            if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
              this.selectedNomEmpl.CON_CODIETI2ING='';
            }else{
              this.selectedNomEmpl.CON_CODIETI2ING=res[0].CON_CODIGO;
            }
            
          }else{
            this.selectedNomEmpl.CON_CODIETI2ING='';
          }
        }else{
          this.selectedNomEmpl.CON_CODIETI2ING='';
        }
      });
    }
    llenarCTACALC75(parametro){
      if (parametro == null || parametro== undefined) {
        parametro = '';
      }
      this.opcion = 'BuscarCODIETI2ING';
      this.types = [
        {label: 'CODIGO', value: 'CON_CODIGO'},
        {label: 'NOMBRE', value: 'CON_NOMBRE'},
        {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
        //CNT. COSTOS
      ];
      this.busquedacampos = [parametro, ''];
      this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
      this.tabla = 'CON_MAECON';
      this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
     
      this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
        if(res!=null){
          if (res[0] != undefined) {
            if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
              this.selectedNomEmpl.EMP_CTACALC75='';
            }else{
              this.selectedNomEmpl.EMP_CTACALC75=res[0].CON_CODIGO;
            }
            
          }else{
            this.selectedNomEmpl.EMP_CTACALC75='';
          }
        }else{
          this.selectedNomEmpl.EMP_CTACALC75='';
        }
      });
    }
    llenarCON_CODIANTI(parametro){
      if (parametro == null || parametro== undefined) {
        parametro = '';
      }
      this.opcion = 'BuscarCODIETI2ING';
      this.types = [
        {label: 'CODIGO', value: 'CON_CODIGO'},
        {label: 'NOMBRE', value: 'CON_NOMBRE'},
        {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
      ];
      this.busquedacampos = [parametro, ''];
      this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
      this.tabla = 'CON_MAECON';
      this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
     
      this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
        if(res!=null){
          if (res[0] != undefined) {
            if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
              this.selectedNomEmpl.CON_CODIANTI='';
            }else{
              this.selectedNomEmpl.CON_CODIANTI=res[0].CON_CODIGO;
            } 
          }else{
            this.selectedNomEmpl.CON_CODIANTI='';
          }
        }else{
          this.selectedNomEmpl.CON_CODIANTI='';
        }
      });
    }
    llenarCON_CODISEGUPRIV(parametro){
      if (parametro == null || parametro== undefined) {
        parametro = '';
      }
      this.opcion = 'BuscarCODIETI2ING';
      this.types = [
        {label: 'CODIGO', value: 'CON_CODIGO'},
        {label: 'NOMBRE', value: 'CON_NOMBRE'},
        {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
      ];
      this.busquedacampos = [parametro, ''];
      this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
      this.tabla = 'CON_MAECON';
      this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
     
      this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
        if(res!=null){
          if (res[0] != undefined) {
            if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
              this.selectedNomEmpl.CON_CODISEGUPRIV='';
            }else{
              this.selectedNomEmpl.CON_CODISEGUPRIV=res[0].CON_CODIGO;
            }
            
          }else{
            this.selectedNomEmpl.CON_CODISEGUPRIV='';
          }
        }else{
          this.selectedNomEmpl.CON_CODISEGUPRIV='';
        }
      });
    }
    llenarCON_CODIPRESIESSQ(parametro){
      if (parametro == null || parametro== undefined) {
        parametro = '';
      }
      this.opcion = 'BuscarCODIPRESIESSQ';
      this.types = [
        {label: 'CODIGO', value: 'CON_CODIGO'},
        {label: 'NOMBRE', value: 'CON_NOMBRE'},
        {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
      ];
      this.busquedacampos = [parametro, ''];
      this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
      this.tabla = 'CON_MAECON';
      this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
     
      this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
        if(res!=null){
          if (res[0] != undefined) {
            if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
              this.selectedNomEmpl.CON_CODIPRESIESSQ='';
            }else{
              this.selectedNomEmpl.CON_CODIPRESIESSQ=res[0].CON_CODIGO;
            }
            
          }else{
            this.selectedNomEmpl.CON_CODIPRESIESSQ='';
          }
        }else{
          this.selectedNomEmpl.CON_CODIPRESIESSQ='';
        }
      });
    }
    llenarCON_CODIMOVI(parametro){
      if (parametro == null || parametro== undefined) {
        parametro = '';
      }
      this.opcion = 'BuscarCODIETI2ING';
      this.types = [
        {label: 'CODIGO', value: 'CON_CODIGO'},
        {label: 'NOMBRE', value: 'CON_NOMBRE'},
        {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
        //CNT. COSTOS
      ];
      this.busquedacampos = [parametro, ''];
      this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
      this.tabla = 'CON_MAECON';
      this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
     
      this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
        if(res!=null){
          if (res[0] != undefined) {
            if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
              this.selectedNomEmpl.CON_CODIMOVI='';
            }else{
              this.selectedNomEmpl.CON_CODIMOVI=res[0].CON_CODIGO;
            }
            
          }  else{
            this.selectedNomEmpl.CON_CODIMOVI='';
          }
        }else{
          this.selectedNomEmpl.CON_CODIMOVI='';
        }
      });
    }
    llenarCON_CODIPRESHIPO(parametro){
      if (parametro == null || parametro== undefined) {
        parametro = '';
      }
      this.opcion = 'BuscarCODIETI2ING';
      this.types = [
        {label: 'CODIGO', value: 'CON_CODIGO'},
        {label: 'NOMBRE', value: 'CON_NOMBRE'},
        {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
      ];
      this.busquedacampos = [parametro, ''];
      this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
      this.tabla = 'CON_MAECON';
      this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
     
      this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
        if(res!=null){
          if (res[0] != undefined) {
            if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
              this.selectedNomEmpl.CON_CODIPRESHIPO='';
            }else{
              this.selectedNomEmpl.CON_CODIPRESHIPO=res[0].CON_CODIGO;
            }
            
          }else{
            this.selectedNomEmpl.CON_CODIPRESHIPO='';
          }
        }else{
          this.selectedNomEmpl.CON_CODIPRESHIPO='';
        }
      });
    }
    llenarCON_CODITELE(parametro){
      if (parametro == null || parametro== undefined) {
        parametro = '';
      }
      this.opcion = 'BuscarCODIETI2ING';
      this.types = [
        {label: 'CODIGO', value: 'CON_CODIGO'},
        {label: 'NOMBRE', value: 'CON_NOMBRE'},
        {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
        //CNT. COSTOS
      ];
      this.busquedacampos = [parametro, ''];
      this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
      this.tabla = 'CON_MAECON';
      this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
     
      this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
        if(res!=null){
          if (res[0] != undefined) {
            if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
              this.selectedNomEmpl.CON_CODITELE='';
            }else{
              this.selectedNomEmpl.CON_CODITELE=res[0].CON_CODIGO;
            }
            
          }else{
            this.selectedNomEmpl.CON_CODITELE='';
          }
        }else{
          this.selectedNomEmpl.CON_CODITELE='';
        }
      });
    }
    llenarCON_CODIGOPRES(parametro){
      if (parametro == null || parametro== undefined) {
        parametro = '';
      }
      this.opcion = 'BuscarCODIETI2ING';
      this.types = [
        {label: 'CODIGO', value: 'CON_CODIGO'},
        {label: 'NOMBRE', value: 'CON_NOMBRE'},
        {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
        //CNT. COSTOS
      ];
      this.busquedacampos = [parametro, ''];
      this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
      this.tabla = 'CON_MAECON';
      this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
     
      this.nomempleadosService.encontrarRegistro6(parametro).then((res: any[]) => {
        if(res!=null){
          if (res[0] != undefined) {
            if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
              this.selectedNomEmpl.CON_CODIGOPRES='';
            }else{
              this.selectedNomEmpl.CON_CODIGOPRES=res[0].CON_CODIGO;
            }
            
          }  else{
            this.selectedNomEmpl.CON_CODIGOPRES='';
          }
        }else{
          this.selectedNomEmpl.CON_CODIGOPRES='';
        }
      });
    }
    llenarOtrosDatos(nommaeEmpleado:NommaeEmpleado){
      this.actualizarTrabRescDisc(nommaeEmpleado.EMP_CONDTRABRESPDISC);
      this.actualizarTIPOSISTSALANETO(nommaeEmpleado.EMP_TIPOSISTSALANETO);
      this.actualizarRESITRAB(nommaeEmpleado.EMP_RESITRAB);
      this.actualizarcmbPAISRESI(nommaeEmpleado.EMP_PAISRESI);
      this.actualizarAPLICONVEVITDOBLTRIB(nommaeEmpleado.EMP_APLICONVEVITDOBLTRIB);
      this.actualizarVEN_CODIGO(nommaeEmpleado.VEN_CODIGO);
      this.llenarOtrosDatosCLiente(nommaeEmpleado.CLI_CODIGO);
      this.chkImpRent=this.cambiarchk(this.selectedNomEmpl.EMP_EMPRASUMIMPURENT);
      this.chkJorPar=this.cambiarchk(this.selectedNomEmpl.EMP_TRABJORNPARC);
      
      this.actualizarEnfermedadCatastrofica(nommaeEmpleado.EMP_ENFCATASTROFICA);
      this.habilitarcombo();//CDPJ
    }
    actualizarEnfermedadCatastrofica(EMP_ENFCATASTROFICA) {
      if (EMP_ENFCATASTROFICA === null || EMP_ENFCATASTROFICA === '') {
        this.valorEnfermedadCatastrof = this.cmbEnfermedadCatastrof[0];
      } else if (EMP_ENFCATASTROFICA === 'SI') {
        this.valorEnfermedadCatastrof = this.cmbEnfermedadCatastrof[1];
      } else {
        if (EMP_ENFCATASTROFICA === 'NO') {
          this.valorEnfermedadCatastrof = this.cmbEnfermedadCatastrof[2];
        }
      }
    }
    actualizarTrabRescDisc(EMP_CONDTRABRESPDISC) {
      for (const a of this.cmbConTrabRescDisc) {
        if (a.CODSRI_CODIGO == String(EMP_CONDTRABRESPDISC)) {
          this.valorConTrabRescDisc ={CODSRI_DESCRIPCION: a.CODSRI_DESCRIPCION, CODSRI_CODIGO: a.CODSRI_CODIGO}
        }
      } 
    }
  actualizarTIPOSISTSALANETO(EMP_TIPOSISTSALANETO) {
    for (const a of this.cmbTIPOSISTSALANETO) {
      if (a.CODSRI_CODIGO == String(EMP_TIPOSISTSALANETO)) {
        this.valorTIPOSISTSALANETO ={CODSRI_DESCRIPCION: a.CODSRI_DESCRIPCION, CODSRI_CODIGO: a.CODSRI_CODIGO}
      }
    } 
  }
  actualizarRESITRAB(EMP_RESITRAB) {
    for (const a of this.cmbRESITRAB) {
      if (a.CODSRI_CODIGO == String(EMP_RESITRAB)) {
        this.valorRESITRAB ={CODSRI_DESCRIPCION: a.CODSRI_DESCRIPCION, CODSRI_CODIGO: a.CODSRI_CODIGO};
      }
    } 
  }
  actualizarcmbPAISRESI(EMP_PAISRESI) {
    for (const a of this.cmbPAISRESI) {
      if (a.CODSRI_CODIGO === String(EMP_PAISRESI)) {
        this.valorPAISRESI ={CODSRI_DESCRIPCION: a.CODSRI_DESCRIPCION, CODSRI_CODIGO: a.CODSRI_CODIGO};
        }
    } 
  }
  actualizarAPLICONVEVITDOBLTRIB(EMP_APLICONVEVITDOBLTRIB) {
    for (const a of this.cmbAPLICONVEVITDOBLTRIB) {
      if (a.CODSRI_CODIGO == String(EMP_APLICONVEVITDOBLTRIB)) {
        this.valorAPLICONVEVITDOBLTRIB ={CODSRI_DESCRIPCION: a.CODSRI_DESCRIPCION, CODSRI_CODIGO: a.CODSRI_CODIGO};
        }
    } 
  }
  actualizarVEN_CODIGO(VEN_CODIGO) {
    for (const a of this.cmbVEN_CODIGO) {
      if (a.VEN_CODIGO == String(VEN_CODIGO)) {
        this.valorVEN_CODIGO ={VEN_NOMBRE: a.VEN_NOMBRE, VEN_CODIGO: a.VEN_CODIGO};
        }
    } 
  }

  llenarOtrosDatosCLiente(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCliCod';
    this.types = [
      {label: 'RUC', value: 'CLI_RUCIDE'},
      {label: 'NOMBRE', value: 'CLI_NOMBRE'},
      {label: 'CÓDIGO', value: 'CLI_CODIGO'},
      
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = "cli_codigo,gru_codigo,ven_codigo,cli_nombre,VEN_FNC_OBTENER_ZONACLI(NVL(CLI_ZONA,'NN')) as cli_zona,cli_rucide,cli_inactivo,cli_direccion1";
    this.tabla = 'VEN_MAECLIENTE';

    this.where = this.types[2].value + ' LIKE \'' + parametro + '%\''+"  ORDER BY CLI_NOMBRE";
    this.nomempleadosService.encontrarRegistro9(parametro).then((res: any[]) => {
      if(res!=null){
        if (res[0] != undefined) {
          this.span_CliCodigo=res[0].CLI_NOMBRE;
        }else{
          this.span_CliCodigo="";
        }
      }else{
        this.span_CliCodigo="";
      }
    });
  }
/*E3J*/
  llenarFormaPago(nommaeEmpleado:NommaeEmpleado){

    this.actualizarTipCuent(nommaeEmpleado.EMP_TIPCTABANC);
    this.llenarCODIGOSXP(nommaeEmpleado.CON_CODIGOSXP); 
    this.llenarBAN_CODIGOEMP(nommaeEmpleado.BAN_CODIGOEMP);
    this.llenarCODIBANCEMPL(nommaeEmpleado.EMP_CODIBANCEMPL);
    this.llenarFORMPAGO(nommaeEmpleado.EMP_FORMPAGO);
  }
  actualizarTipCuent(EMP_TIPCTABANC) {
    for (const a of this.cmbTipCuent) {
      if (a.REF_CODIGO === String(EMP_TIPCTABANC)) {
        this.varTipCuent ={REF_NOMBRE: a.REF_NOMBRE, REF_CODIGO: a.REF_CODIGO};
        }
    } 
  }
  llenarCODIGOSXP(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCODIGOSXP';
    this.types = [
      {label: 'CODIGO', value: 'CON_CODIGO'},
      {label: 'NOMBRE', value: 'CON_NOMBRE'},
      {label: 'CNT. COSTOS', value: 'CON_CENTRO'},
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
    this.tabla = 'CON_MAECON';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY CON_CODIGO";
   
    this.nomempleadosService.encontrarRegistro13(parametro).then((res: any[]) => {
      if(res!=null){
        if (res[0] != undefined) {
          this.span_CODIGOSXP=res[0].CON_NOMBRE;
        }else{
          this.span_CODIGOSXP='';
        }
      }else{
        this.span_CODIGOSXP='';
      }
    });
  }
  llenarBAN_CODIGOEMP(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BAN_CODIGOEMP';
    this.types = [
      {label: 'CODIGO', value: 'BAN_CODIGO'},
      {label: 'BANCO', value: 'BAN_BANCO'},
      {label: 'CUENTA NRO', value: 'BAN_CTANRO'},
      {label: 'JEFE CUENTA', value: 'BAN_JEFECTA'},
      {label: 'SUCURSAL', value: 'BAN_SUCURSAL'},
      {label: 'DIRECCION', value: 'BAN_DIRECCION'},
      {label: 'TELEFONO 1', value: 'BAN_TELEFONO1'},
      {label: 'TELEFONO 2', value: 'BAN_TELEFONO2'},
      {label: 'CORREO', value: 'BAN_CORREO'},
      {label: 'CODIGO CONTABLE', value: 'CON_CODIGO'},
      {label: 'CEN_CODIGO', value: 'CEN_CODIGO'},
      {label: 'TIPO CUENTA', value: 'BAN_TIPOCUENTA'},
      {label: 'DOC. PAGAR', value: 'CON_CODIGO_2'},
      {label: 'PROCESO', value: 'BAN_PROCESO_CM'},
      {label: 'DIRECTORIO CM', value: 'BAN_DIRECTORIO_CM'},
      {label: 'TELCEL', value: 'BAN_TELEFONOCEL'},
      {label: 'ULT. CHEQUE', value: 'BAN_ULTIMOCHEQUE'}
    
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = '*';
    this.tabla = 'BAN_MAEBAN';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ORDER BY BAN_CODIGO";   
    this.nomempleadosService.encontrarRegistro10(parametro).then((res: any[]) => {
      if(res!=null){        
        if (res[0] != undefined) {      
          this.span_CODIGOEMP=res[0].BAN_BANCO; 
        }else{
          this.span_CODIGOEMP='';
        }
      }else{
        this.span_CODIGOEMP='';
      }
    });
  }
  llenarCODIBANCEMPL(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCODIBANCEMPL';
    this.types = [
      {label: 'CODIGO', value: 'BANCLI_CODIGO'},
      {label: 'NOMBRE', value: 'BANCLI_NOMBRE'},
      {label: 'ESTADO', value: 'BANCLI_ESTADO'},
      {label: 'COMPAÑIA', value: 'COM_CODIGO'},
      
    ];
  
    this.busquedacampos = [parametro, ''];
    this.consulta = 'BANCLI_CODIGO,BANCLI_NOMBRE, BANCLI_ESTADO, COM_CODIGO';
    this.tabla = 'CXC_MAEBANCLI';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" AND  COM_CODIGO = '01' ";
   
    this.nomempleadosService.encontrarRegistro11(parametro).then((res: any[]) => {
      if(res!=null){
        if (res[0] != undefined) {
          this.span_CODIBANCEMPL=res[0].BANCLI_NOMBRE;
        }else{
          this.span_CODIBANCEMPL='';
        }
      }else{
        this.span_CODIBANCEMPL='';
      }
    });

  }
  llenarFORMPAGO(parametro){
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCODIBANCEMPL';
    this.types = [
      {label: 'CODIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'},
      {label: 'NOMBRE CORTO', value: 'REF_NOMBREC'},
      
    ];
    
    this.busquedacampos = [parametro, ''];
    this.consulta = 'REF_CODIGO,REF_NOMBRE, REF_NOMBREC,REF_TIPO';
    this.tabla = 'SACI_MAEREFERENCIA';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\''+" and REF_tipo = 'NOMFP' order by ref_codigo ";
   
    this.nomempleadosService.encontrarRegistro12(parametro).then((res: any[]) => {
      if(res!=null){       
        if (res[0] != undefined) {          
          this.span_FORMPAGO=res[0].REF_NOMBRE;         
        }else{
          this.span_FORMPAGO='';  
        }
      }else{
        this.span_FORMPAGO='';  
      }
    });
  }
  onHideDatAdi(){
    this.displaydialogadi=false;
  }

  async llenarEmpleado(empleado:NommaeEmpleado){
       
    this.types = [
      {label: 'CODIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'},
      {label: 'NOMBRE CORTO', value: 'REF_NOMBREC'},
    ];
    this.consulta = 'REF_CODIGO, REF_NOMBRE, REF_NOMBREC, REF_TIPO';
    this.tabla = 'SACI_MAEREFERENCIA';
    const resEC = await this.nomempleadosService.encontrarRegistro7(empleado.EMP_ESTACIVIL, 'ESTCIV');
    console.log(resEC);
    if(resEC!=null){
        
      if (resEC[0] != undefined) {
        this.span_esta_civil=resEC[0].REF_NOMBRE;
      }  else{
        this.span_esta_civil="";
      }
    }else{
      this.span_esta_civil="";
    }

    this.types = [
      {label: 'CODIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'},
      {label: 'NOMBRE CORTO', value: 'REF_NOMBREC'},
    ];
    
    this.consulta = 'REF_CODIGO, REF_NOMBRE, REF_NOMBREC, REF_TIPO';
    this.tabla = 'SACI_MAEREFERENCIA';
   
    const resS = await this.nomempleadosService.encontrarRegistro7(empleado.EMP_SEXO, 'SEX');
    if(resS!=null){
      if (resS[0] != undefined) {
        this.span_sex=resS[0].REF_NOMBRE;
       
      }else{
        this.span_sex="";
      }
    }else{
      this.span_sex="";
    }

    this.types = [
      {label: 'CODIGO', value: 'EMP_CODIGO'},
      {label: 'NOMBRE', value: 'EMP_NOMBRE'},
      {label: 'APELLIDO', value: 'EMP_APELLIDO'},
    ];
    
    this.consulta = 'EMP_CODIGO,EMP_NOMBRE, EMP_APELLIDO';
    this.tabla = 'NOM_MAEEMPLEADO ';
   
    const resJI = await this.nomempleadosService.encontrarRegistro4(empleado.EMP_JEFEINMD);
    if(resJI!=null){
      if (resJI[0] != undefined) {
        this.span_JefInme=resJI[0].EMP_APELLIDO+" "+resJI[0].EMP_NOMBRE; 
      }  else{
        this.span_JefInme="";
      }
    }else{
      this.span_JefInme="";
    }

    this.types = [
      {label: 'CODIGO', value: 'EMP_CODIGO'},
      {label: 'NOMBRE', value: 'EMP_NOMBRE'},
      {label: 'APELLIDO', value: 'EMP_APELLIDO'},
    ];
    
    this.consulta = 'EMP_CODIGO,EMP_NOMBRE, EMP_APELLIDO';
    this.tabla = 'NOM_MAEEMPLEADO ';
   
    const resJE = await this.nomempleadosService.encontrarRegistro4(empleado.EMP_JEFEEXT);
    if(resJE!=null){
      if (resJE[0] != undefined) {
        this.span_JefExt=resJE[0].EMP_APELLIDO+" "+resJE[0].EMP_NOMBRE; 
      }  else{
        this.span_JefExt="";
      }
    }else{
      this.span_JefExt="";
    }

    this.types = [
      {label: 'CODIGO', value: 'CEN_CODIGO'},
      {label: 'NOMBRE', value: 'CEN_NOMBRE'},
    ];
   
    this.consulta = 'CEN_CODIGO, CEN_NOMBRE';
    this.tabla = 'con_maecen';

    const resCC = await this.nomempleadosService.encontrarRegistro14(empleado.EMP_CENTCOST);
    if(resCC!=null){
      if (resCC[0] != undefined) {
        this.span_centroCos=resCC[0].CEN_NOMBRE;
      } else{
        this.span_centroCos="";
      }
    }else{
      this.span_centroCos="";
    }

    this.types = [
      {label: 'CODIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'},
      {label: 'NOMBRE CORTO', value: 'REF_NOMBREC'},
    ];
    
    this.consulta = 'REF_CODIGO, REF_NOMBRE, REF_NOMBREC, REF_TIPO, COM_CODIGO';
    this.tabla = 'SACI_MAEREFERENCIA';

    const resNomC = await this.nomempleadosService.encontrarRegistro7(empleado.EMP_CARGO, 'NOMCAR');
    if(resNomC!=null){
        
      if (resNomC[0] != undefined) {
        this.span_CarEmpr=resNomC[0].REF_NOMBRE;
     
      }  else{
        this.span_CarEmpr="";
      }
    }else{
      this.span_CarEmpr="";
    }

    this.types = [
      {label: 'CODIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'},
      {label: 'NOMBRE CORTO', value: 'REF_NOMBREC'},
    ];
   
    this.consulta = 'REF_CODIGO, REF_NOMBRE, REF_NOMBREC, REF_TIPO, COM_CODIGO';
    this.tabla = 'SACI_MAEREFERENCIA';

    const resCarIess = await this.nomempleadosService.encontrarRegistro7(empleado.EMP_CARGIESS,'NOMCIE');
    if(resCarIess!=null){
      if (resCarIess[0] != undefined) {  
        this.span_CarIess=resCarIess[0].REF_NOMBRE;  
      }  else{
        this.span_CarIess="";
      }
    }else{
      this.span_CarIess="";
    }

    this.types = [
      {label: 'CODIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'},
      {label: 'NOMBRE CORTO', value: 'REF_NOMBREC'},
    ];
    this.consulta = 'REF_CODIGO, REF_NOMBRE, REF_NOMBREC, REF_TIPO, COM_CODIGO';
    this.tabla = 'SACI_MAEREFERENCIA';
    
    const resEstTr = await this.nomempleadosService.encontrarRegistro7(empleado.EMP_ESTATRAB, 'NOMETR');
    if(resEstTr!=null){
      if (resEstTr[0] != undefined) {
        this.span_EstTrab=resEstTr[0].REF_NOMBRE;
      }else{
        this.span_EstTrab="";
      }
    }else{
      this.span_EstTrab="";
    }

    this.types = [
      {label: 'CODIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'},
      {label: 'NOMBRE CORTO', value: 'REF_NOMBREC'},
    ];
    
    this.consulta = 'REF_CODIGO, REF_NOMBRE, REF_NOMBREC, REF_TIPO, COM_CODIGO';
    this.tabla = 'SACI_MAEREFERENCIA';

    const resTipE = await this.nomempleadosService.encontrarRegistro7(empleado.EMP_TIPOEMPL, 'NOMTEM');
    if(resTipE!=null){
      if (resTipE[0] != undefined) {
        this.span_TipEmpl=resTipE[0].REF_NOMBRE;
      }else{
        this.span_TipEmpl="";
      }
    }else{
      this.span_TipEmpl="";
    }

    this.types = [
      {label: 'CODIGO', value: 'TIPROL_CODIGO'},
      {label: 'NOMBRE', value: 'TIPROL_NOMBRE'},
    ];
    
    this.consulta = 'TIPROL_CODIGO, TIPROL_NOMBRE';
    this.tabla = 'nom_maetiporol';

    const resTipR = await this.nomempleadosService.encontrarRegistro8(empleado.TIPROL_CODIGO);
    if(resTipR!=null){
        
      if (resTipR[0] != undefined) {
        this.span_TipoRol=resTipR[0].TIPROL_NOMBRE;
      }else{
        this.span_TipoRol="";
      }
    }else{
      this.span_TipoRol="";
    }
  }
  /*Tabla 1*/
  activarValores(){
    this.displayDialogActivarValores=true;
    this.valoresformu=true;
    this.vacacionestable=false;
    this.closeSidebar();
  }
  
  agregarDatosAdi(){
  
    if( this.selectedNomEmpl.EMP_CODIGO2!="" &&  this.selectedNomEmpl.EMP_CODIGO2!=null &&  this.selectedNomEmpl.EMP_CODIGO2!=undefined ){
      if(this.selectedNomEmpl.EMP_APELLIDO!="" && this.selectedNomEmpl.EMP_APELLIDO!=null && this.selectedNomEmpl.EMP_APELLIDO!=undefined){
        if(this.selectedNomEmpl.EMP_NOMBRE!="" && this.selectedNomEmpl.EMP_NOMBRE!=null && this.selectedNomEmpl.EMP_NOMBRE!=undefined){
          this.displaydatosAdi=true;
          this.displaydialogadi=true;
         
          this.closeSidebar();
        }else{
          this.mensajeAlertaAuto('error', 'Error', 'Seleccione un empleado');
          this.displaydatosAdi = false;
        }
      }else{
        this.mensajeAlertaAuto('error', 'Error', 'Seleccione un empleado');
        this.displaydatosAdi = false;
      }
    }else{
      this.mensajeAlertaAuto('error', 'Error', 'Seleccione un empleado');
      this.displaydatosAdi= false;
    }
     
  }
  agregarDatosAdiglob(){
    
    if( this.selectedNomEmpl.EMP_CODIGO2!="" &&  this.selectedNomEmpl.EMP_CODIGO2!=null &&  this.selectedNomEmpl.EMP_CODIGO2!=undefined ){
      if(this.selectedNomEmpl.EMP_APELLIDO!="" && this.selectedNomEmpl.EMP_APELLIDO!=null && this.selectedNomEmpl.EMP_APELLIDO!=undefined){
        if(this.selectedNomEmpl.EMP_NOMBRE!="" && this.selectedNomEmpl.EMP_NOMBRE!=null && this.selectedNomEmpl.EMP_NOMBRE!=undefined){
          this.displaydialogadiglob=true;
          this.displaydialogadiglob = true;
         
          this.closeSidebar();
        }else{
          this.mensajeAlertaAuto('error', 'Error', 'Seleccione un empleado');
          this.displaydialogadiglob= false;
        }
      }else{
        this.mensajeAlertaAuto('error', 'Error', 'Seleccione un empleado');
        this.displaydialogadiglob= false;
      }
    }else{
      this.mensajeAlertaAuto('error', 'Error', 'Seleccione un empleado');
      this.displaydialogadiglob= false;
    }
  }
  selectMaeDatAdi(event){

  }
  cambioDatAdi(event){

  }
  manejarSenalesAdi(event){

  }
 
  onHideAutorizacion(){
    this.displaydatosAdi = false;
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefMaeIngrFijo = {
      editable: true,
      width: 130,
      objeto: 'nomMaeIngrFij'
    };
    this.defaultColDefNomEgreFijo= {
      editable: true,
      width: 130,
      objeto: 'nomMaeEgrFij'
    };
    this.defaultColDefVacaciones= {
      editable: true,
      width: 130,
      objeto: 'nomMaeVacaciones'
    };
    this.defaultColDefnomCargaFami= {
      editable: true,
      width: 130,
      objeto: 'nomMaeCargaFami'
    };
    
  }
  /*Busqueda Fast*/
  async manejarSeleccionbus(condicion){
    this.displayDialogBusquedaFast = false;
    await this.llenarVACIOS();
    this.reiniciarCampos();
    const re = await this.nomempleadosService.selectEmpleadoProm(condicion.EMP_CODIGO);
    await this.llenarFormulario(re[0]);
    try{
      document.getElementById('divPrincipal').style.pointerEvents = 'all';

    }catch(e){

    } 
   /* await this.nomempleadosService.selectEmpleado(condicion.EMP_CODIGO).then(async re=>{
      console.log(re);
      
      await this.llenarFormulario(re[0]);
      try{
        document.getElementById('divPrincipal').style.pointerEvents = 'all';

      }catch(e){
  
      }         
    })*/
  }
  buscarCondicion(){
    this.types = [
      {label: 'CÓDIGO', value: 'EMP_CODIGO'},
      {label: 'NOMBRES', value: 'EMP_NOMBRE'},
      {label: 'APELLIDOS', value: 'EMP_APELLIDO'},
      {label: 'RUCIDE', value: 'EMP_RUCIDE'},
    ];
    this.busquedacampos = ['', '', '', ''];
    this.consulta = 'TO_CHAR(emp_codigo) AS EMP_CODIGO, emp_nombre ,emp_apellido ,EMP_RUCIDE ,EMP_FECINGRESO';
    this.tabla = 'nom_maeempleado';
    this.where = 'ROWNUM<50';
    //select TO_CHAR(emp_codigo) as "Código", emp_nombre as "Nombres",emp_apellido as "Apellidos",EMP_RUCIDE as "RucIde",EMP_FECINGRESO AS "Ingreso"
    //from nom_maeempleado order by emp_apellido, emp_nombre,EMP_FECINGRESO DESC
    this.nomempleadosService.busquedaTabla().then((datos: any[]) => {
      this.spin=true;
      console.log(datos)
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
      this.spin=false;
    });
  
  }

  salir(entrada){
    if(entrada=='salir'){
      this.displaydatosAdi=false;
    }
  }
  salirClo(entrada){
    if(entrada=='salir'){
      this.displaydatosAdi=false;
    }
  }

  async tablas(){
    this.btnGuardar=true;
    this.btnNuevo=false;
    this.btnBorrar=true;
    this.btnRegresar=false;
    this.botonAnterior=false;
    this.botonPrimero=false;
    this.botonUltimo=false;
    this.btnBuscar=false;
    this.botonSiguiente=false;
    this.selectednomEgreFijo=null;
    this.selectednomIngrFijo=null;
    try{
      this.nomempleadosService.nommaenomIngrFijo=[];
      this.nomempleadosService.nommaeEgreFijo=[];
      await this.nomempleadosService.empleadoingrefijo(this.selectedNomEmpl.EMP_CODIGO).then(async(res:any)=>{
        try{  
          this.nomempleadosService.nommaenomIngrFijo=res;
          let i=0;
          this.nomempleadosService.nommaenomIngrFijo.map(async x=>{
            x.MAEINGRFIJO_IMPORTE=parseFloat(this.numberEmplPer(x.MAEINGRFIJO_IMPORTE)).toFixed(2);
            x.MAEINGRFIJO_NROCUOT=parseFloat(this.numberEmplPer(x.MAEINGRFIJO_NROCUOT)).toFixed(2);
            x.MAEINGRFIJO_SALDO=parseFloat(this.numberEmplPer(x.MAEINGRFIJO_SALDO)).toFixed(2);
            x.MAEINGRFIJO_ABONO=parseFloat(this.numberEmplPer(x.MAEINGRFIJO_ABONO)).toFixed(2);
            x.MAEINGRFIJO_FECINI=this.cambiarfechanull(x.MAEINGRFIJO_FECINI);
            x.MAEINGRFIJO_FECFIN=this.cambiarfechanull(x.MAEINGRFIJO_FECFIN);
            x.MAEINGRFIJO_FECHREGI=this.cambiarfechanull(x.MAEINGRFIJO_FECHREGI);
            x.MAEINGRFIJO_APORIESS=(x.MAEINGRFIJO_APORIESS=="1")?true:false;
            x.MAEPERIODO_CODIGO="";
            x.NUMEGRFIJ=i++;            
          });
          for(let vali of this.nomempleadosService.nommaenomIngrFijo){
            await this.busPeriodoIngEmp(vali.MAERUBRO_CODIGO).then(re=>{  
              vali.MAERUBRO_CODIGO2=re;
            });
          }
          for(let vali of this.nomempleadosService.nommaenomIngrFijo){
            const per=await this.busPeriodoVal(vali.MAEINGRFIJO_FECINI).then(re=>{
              vali.MAEPERIODO_CODIGO=re;
            });
          }
        }catch(e){
          this.nomempleadosService.nommaenomIngrFijo=[];
        }
      });
      await this.nomempleadosService.empleadoegrefijo(this.selectedNomEmpl.EMP_CODIGO).then(async(res:any)=>{
          try{  
            this.nomempleadosService.nommaeEgreFijo=res;
            let i=0;
            this.nomempleadosService.nommaeEgreFijo.map(async x=>{
              x.MAEEGREFIJO_IMPORTE=parseFloat(this.numberEmplPer(x.MAEEGREFIJO_IMPORTE)).toFixed(2);
              x.MAEEGREFIJO_NROCUOT=parseFloat(this.numberEmplPer(x.MAEEGREFIJO_NROCUOT)).toFixed(2);
              x.MAEEGREFIJO_SALDO=parseFloat(this.numberEmplPer(x.MAEEGREFIJO_SALDO)).toFixed(2);
              x.MAEEGREFIJO_ABONO=parseFloat(this.numberEmplPer(x.MAEEGREFIJO_ABONO)).toFixed(2);
              
              x.MAEEGREFIJO_FECINI=this.cambiarfechanull(x.MAEEGREFIJO_FECINI);
              x.MAEEGREFIJO_FECFIN=this.cambiarfechanull(x.MAEEGREFIJO_FECFIN);
              x.MAEEGREFIJO_FECHREGI=this.cambiarfechanull(x.MAEEGREFIJO_FECHREGI);
              x.NUMEGRFIJ=i++;
              x.MAEPERIODO_CODIGO="";
            });
            for(let vali of this.nomempleadosService.nommaeEgreFijo){
              await this.busPeriodoEgrEmp( vali.MAERUBRO_CODIGO).then(re=>{
                vali.MAERUBRO_CODIGO2=re;
              });
            }
            for(let vali of this.nomempleadosService.nommaeEgreFijo){
              const per=await this.busPeriodoVal(vali.MAEEGREFIJO_FECINI).then(re=>{
                vali.MAEPERIODO_CODIGO=re;
              });
            }
          }catch(e){ 
            this.nomempleadosService.nommaeEgreFijo=[]; 
          }
      });
      this.ingregreFijos=true;
      this.aggrid.refreshaggrid(this.nomempleadosService.nommaenomIngrFijo, this.defaultColDefMaeIngrFijo.objeto );
      this.egraggrid.refreshaggrid(this.nomempleadosService.nommaeEgreFijo, this.defaultColDefNomEgreFijo.objeto );    
    }catch(err){
      
    }
  };

  numberEmplPer(valor){
    let retr;
    if(isNullOrUndefined(valor)==true ||valor==""){
      return retr="0";
    }else{
      return valor;
    }
  }
  async busPeriodoIngEmp(parametro){
    let resp="";
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.types = [
      { label: 'Código', value: 'MAERUBRO_CODRUBR' },
      { label: 'Nombre', value: 'MAERUBRO_NOMBRE' },
    ];
    this.busquedacampos = [parametro, '', ''];
    this.consulta = 'MAERUBRO_CODIGO,MAERUBRO_CODRUBR,MAERUBRO_NOMBRE,COM_CODIGO,MAERUBRO_TIPO';
    this.tabla = ' NOM_MAERUBRO';
    this.where = " MAERUBRO_TIPO = 'INGRESO' AND COM_CODIGO = '01'";
    return new Promise<any>(async (resolve)=>{
     await this.nomempleadosService.encontrarRegistro15(parametro).then(eR => {
      if (eR !== null) {
          if (eR[0] !== undefined) {
            resolve(eR[0].MAERUBRO_CODRUBR);
          } else {
            resolve(resp);
          }
        } else {
          resolve(resp);
        }
      });
    });
  }
  onHideIngre(){
    let index= this.nomempleadosService.nommaenomIngrFijo.map(x=>{return x.NUMEGRFIJ}).indexOf(this.selectednomIngrFijo.NUMEGRFIJ);
    this.aggrid.refreshaggrid(this.nomempleadosService.nommaenomIngrFijo, this.defaultColDefMaeIngrFijo.objeto ); 
    this.nomempleadosService.nommaenomIngrFijo[index].MAEINGRFIJO_FECHREGI=this.cambiarfechanull(this.selectednomIngrFijo.MAEINGRFIJO_FECHREGI); 
    this.aggrid.gridApi.setFocusedCell(index , 'MAEINGRFIJO_ABONO'); 
  }
  onHideEgre(){
    let index= this.nomempleadosService.nommaeEgreFijo.map(x=>{return x.NUMEGRFIJ}).indexOf(this.selectednomEgreFijo.NUMEGRFIJ);
    this.nomempleadosService.nommaeEgreFijo[index].MAEEGREFIJO_FECHREGI=this.cambiarfechanull(this.selectednomEgreFijo.MAEEGREFIJO_FECHREGI); 
    this.egraggrid.refreshaggrid(this.nomempleadosService.nommaeEgreFijo, this.defaultColDefNomEgreFijo.objeto );
    this.egraggrid.gridApi.setFocusedCell(index , 'MAEEGREFIJO_ABONO');
  }
  tablasVaca(){
    this.btnGuardar=true;
    this.btnNuevo=false;
    this.btnBorrar=true;
    this.btnRegresar=false;
    this.botonAnterior=false;
    this.botonPrimero=false;
    this.botonUltimo=false;
    this.btnBuscar=false;
    this.botonSiguiente=false;
    this.nomempleadosService.listavacaciones(this.selectedNomEmpl.EMP_CODIGO).then((res:any)=>{
      try{
        this.nomempleadosService.nomVacaciones=res;
        this.nomempleadosService.nomVacaciones.map(x=>{
          x.TRANVACAEMPL_PERIODO=this.numberEmplPer(x.TRANVACAEMPL_PERIODO);
          x.TRANVACAEMPL_DIASPRORR=parseFloat(this.numberEmplPer(x.TRANVACAEMPL_DIASPRORR)).toFixed(4);
          x.TRANVACAEMPL_BASEACUM=parseFloat(this.numberEmplPer(x.TRANVACAEMPL_BASEACUM)).toFixed(4);
          x.TRANVACAEMPL_VALOR=parseFloat(this.numberEmplPer(x.TRANVACAEMPL_VALOR)).toFixed(2);
          x.TRANVACAEMPL_FECHSALIVACA=this.cambiarfechanull(x.TRANVACAEMPL_FECHSALIVACA);
          x.TRANVACAEMPL_FECHREGRVACA=this.cambiarfechanull(x.TRANVACAEMPL_FECHREGRVACA);
          x.TRANVACAEMPL_FECINIPERI=this.cambiarfechanull(x.TRANVACAEMPL_FECINIPERI);
          x.TRANVACAEMPL_FECFINPERI=this.cambiarfechanull(x.TRANVACAEMPL_FECFINPERI);
        });
        let sal=0;
        let saldias=0;
        let salp=0;
        let saldiasp=0;
        for(var va=0;va<this.nomempleadosService.nomVacaciones.length;va++){
          let i=(va==0)?1:va-1;
          saldias=parseFloat(this.numberEmplPer(this.nomempleadosService.nomVacaciones[va].TRANVACAEMPL_DIASPRORR));
          sal=parseFloat(this.numberEmplPer(this.nomempleadosService.nomVacaciones[va].TRANVACAEMPL_VALOR));
          if(va==0){
            this.nomempleadosService.nomVacaciones[va].TRANVACAEMPL_SALDODIAS=saldias.toFixed(4);
            this.nomempleadosService.nomVacaciones[va].TRANVACAEMPL_SALDO=sal.toFixed(4);
          }else{
            saldiasp=parseFloat(this.nomempleadosService.nomVacaciones[i].TRANVACAEMPL_SALDODIAS);
            salp=parseFloat(this.nomempleadosService.nomVacaciones[i].TRANVACAEMPL_SALDO);
            this.nomempleadosService.nomVacaciones[va].TRANVACAEMPL_SALDODIAS=(saldias+saldiasp).toFixed(4);
            this.nomempleadosService.nomVacaciones[va].TRANVACAEMPL_SALDO=(sal+salp).toFixed(4);
          }
        }
      }catch(e){
        this.nomempleadosService.nomVacaciones=[];
      }
      this.vacacionestabla=true;
    })
  }
  salirvac(elemento){
    if(elemento=='salir'){
      this.displaydialogemplvac=false;
      this.vacacionestable==false;
      this.tablasVaca();
    }
  }
  async tablasCarFam(){

    this.btnGuardar=true;
    this.btnNuevo=false;
    this.btnBorrar=true;
    this.btnRegresar=false;
    this.botonAnterior=false;
    this.botonPrimero=false;
    this.botonUltimo=false;
    this.btnBuscar=false;
    this.botonSiguiente=false;
    let i=0;
    await this.nomempleadosService.cargafamiliar(this.selectedNomEmpl.EMP_CODIGO).then(async (res:any)=>{
      try{  
        this.nomempleadosService.nomCargaFamil=res;
        this.nomempleadosService.nomCargaFamil.map(x=>{
          x.EMPCARGFAMI_DISC=(x.EMPCARGFAMI_DISC=="N")?false:true;
          x.NUMCARFA=i++;
          if(isNullOrUndefined(x.EMPCARGFAMI_PORCDISC)||x.EMPCARGFAMI_PORCDISC==''){
            x.EMPCARGFAMI_PORCDISC='';
          }else{
            x.EMPCARGFAMI_PORCDISC=parseFloat(this.numberEmplPer(x.EMPCARGFAMI_PORCDISC)).toFixed(2);
          }
          x.EMPCARGFAMI_FECHNACI=this.cambiarfechanull(x.EMPCARGFAMI_FECHNACI);
        });
        for(let vali of this.nomempleadosService.nomCargaFamil){
          const pro= await this.busquedaAGTipoRela(vali.EMPCARGFAMI_TIPORELA).then(re=>{
            vali.EMPCARGFAMI_TIPORELA2=re;
          })
        };
      }catch(e){
        this.nomempleadosService.nomCargaFamil=[];
      }  
    }); 
    
    this.carFamtabla=true;
    this.btnNuevoCarFam=false;
    this.btnGuardarCarFam=true;
    this.btnBorrarCarFam=false;
    this.btnCancelarCarFam=false;
    this.cfggrid.refreshaggrid(this.nomempleadosService.nomCargaFamil, this.defaultColDefnomCargaFami.objeto );
  }
  busquedaAGTipoRela(parametro){
    let resp="";
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' },
      { label: 'Nombre C', value: 'REF_NOMBREC' },
    ];

    if (parametro === null || parametro === undefined) {
      parametro = '';
    }
    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.tabla = ' saci_maereferencia';
    this.where = " ref_tipo='NOMPAR' ";

    
    return new Promise<any>(async (resolve)=>{
      this.nomempleadosService.encontrarRegistro16(parametro).then(eR => {
        if (eR !== null) {
            if (eR[0] !== undefined) {
              resolve(eR[0].REF_NOMBRE);
              //this.manejarSeleccion(eR[0]);
            }else{
              resolve(resp);
            }
          }else{
            resolve(resp);
          }
        });
    })
  }
  async busPeriodoEgrEmp(parametro){
    let resp="";
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    this.types = [
      { label: 'Código', value: 'MAERUBRO_CODRUBR' },
      { label: 'Nombre', value: 'MAERUBRO_NOMBRE' },
    ];
    this.busquedacampos = [parametro, '', ''];
    this.consulta = 'MAERUBRO_CODIGO,MAERUBRO_CODRUBR,MAERUBRO_NOMBRE,COM_CODIGO,MAERUBRO_TIPO';
    this.tabla = ' NOM_MAERUBRO';
    this.where = " MAERUBRO_TIPO = 'DESCUENTO' AND COM_CODIGO = '01'";
    return new Promise<any>(async (resolve)=>{
     await this.nomempleadosService.encontrarRegistro17(parametro).then(eR => {
      if (eR !== null) {
          if (eR[0] !== undefined) {
            resolve(eR[0].MAERUBRO_CODRUBR);
          } else {
            resolve(resp);
          }
        } else {
          resolve(resp);
        }
      });
    });
  }
 async  ingresosFijo(valor){
    if (valor === 'nuevo') {
      this.nuevoIngrFijo();
    }

    if (valor === 'eliminar') {
      this.eliminarIngFijo();
    }

    if (valor === 'cancelar') {
      await  this.cancelarIngFijo();
    }

    if (valor.object !== undefined) {
      this.selectednomIngrFijo=valor.object;
    }

  }
  seleccionItem(param){
    this.btnGuardar=false;
    this.btnNuevo=true;
    this.btnBorrar=true;
    this.btnRegresar=false;
    this.botonAnterior=true;
    this.botonPrimero=true;
    this.botonUltimo=true;
    this.btnBuscar=true;
    this.botonSiguiente=true;
  }
  cambioIngrFijo(event){
      
    this.btnGuardar=false;
    this.btnNuevo=true;
    this.btnBorrar=true;
    this.btnRegresar=false;
    this.botonAnterior=true;
    this.botonPrimero=true;
    this.botonUltimo=true;
    this.btnBuscar=true;
    this.botonSiguiente=true;
  }
  nuevoIngrFijo(){
    let dat=new Date();
    this.aggrid.isEditable(true);
    const newModulo: nomIngrFijo={  
      CON_CODIGO:'',
      MAEINGRFIJO_DESCRIPCION:'',
      CEN_CODIGO:'',
      MAEINGRFIJO_IMPORTE:'',
      MAEINGRFIJO_APORIESS:false,
      MAEINGRFIJO_NROCUOT:'',
      MAEINGRFIJO_FECHREGI:this.cambiarfechanull(dat),
      MAEINGRFIJO_FECINI:this.cambiarfechanull(dat),
      MAEINGRFIJO_FECFIN:this.cambiarfechanull(dat),
      MAEINGRFIJO_ABONO:'',
      MAEINGRFIJO_SALDO:'',
      MAERUBRO_CODIGO:'',
      NUEVO:true,
      NUMEGRFIJ:this.nomempleadosService.nommaenomIngrFijo.length
    };
    try{
      this.nomempleadosService.nommaenomIngrFijo.push(newModulo); 
      this.aggrid.refreshaggrid(this.nomempleadosService.nommaenomIngrFijo, this.defaultColDefMaeIngrFijo.objeto ); 
      const indice= this.nomempleadosService.nommaenomIngrFijo.indexOf(newModulo);
      this.aggrid.gridApi.setFocusedCell(indice , 'CON_CODIGO');
    }catch(err){
    }
  }
  geneIngNum(){
    if(this.nomempleadosService.nommaenomIngrFijo.length==0){
      return this.nomempleadosService.nommaenomIngrFijo.length;
    }else if(this.nomempleadosService.nommaenomIngrFijo.length==1){ 
      let num=1
      return num;
    }else{
      this.nomempleadosService.nommaenomIngrFijo.length+1
    }
  }
  cambioModulo(param){
    
    if(param.newValue!=""){
      this.displaydialogPerEmpl=true;
    }
  }
  cambioModuloEgre(param){
    
    if(param.newValue!=""){
      this.displaydialogEgrePerEmpl=true;
    }
  }
  llenarPerEmpl(elemento){
  
    try{
      let index= this.nomempleadosService.nommaenomIngrFijo.map(x=>{return x.NUMEGRFIJ}).indexOf(this.selectednomIngrFijo.NUMEGRFIJ);
      this.nomempleadosService.nommaenomIngrFijo[index].MAEINGRFIJO_FECINI=elemento[0];
      this.nomempleadosService.nommaenomIngrFijo[index].MAEINGRFIJO_FECFIN=elemento[1];
      this.nomempleadosService.nommaenomIngrFijo[index].MAEPERIODO_CODIGO=elemento[2];
      this.nomempleadosService.nommaenomIngrFijo[index].MAEINGRFIJO_FECHREGI=this.cambiarfechanull(this.selectednomIngrFijo.MAEINGRFIJO_FECHREGI); 
      this.aggrid.refreshaggrid(this.nomempleadosService.nommaenomIngrFijo, this.defaultColDefMaeIngrFijo.objeto ); ;
      this.aggrid.gridApi.setFocusedCell(index , 'MAEINGRFIJO_ABONO'); 
    }catch(err){
      
    }
  }
  salirPerEmpl(elemento){
    if(elemento=='salir'){
      this.displaydialogPerEmpl=false;
      let index= this.nomempleadosService.nommaenomIngrFijo.map(x=>{return x.NUMEGRFIJ}).indexOf(this.selectednomIngrFijo.NUMEGRFIJ);
      this.nomempleadosService.nommaenomIngrFijo[index].MAEINGRFIJO_FECHREGI=this.cambiarfechanull(this.selectednomIngrFijo.MAEINGRFIJO_FECHREGI); 
      this.aggrid.refreshaggrid(this.nomempleadosService.nommaenomIngrFijo, this.defaultColDefMaeIngrFijo.objeto );
      this.aggrid.gridApi.setFocusedCell(index , 'MAEINGRFIJO_ABONO'); 
    }
  }
  llenarEgrePerEmpl(elemento){
    try{
      let index= this.nomempleadosService.nommaeEgreFijo.map(x=>{return x.NUMEGRFIJ}).indexOf(this.selectednomEgreFijo.NUMEGRFIJ);
      this.nomempleadosService.nommaeEgreFijo[index].MAEEGREFIJO_FECINI=elemento[0];
      this.nomempleadosService.nommaeEgreFijo[index].MAEEGREFIJO_FECFIN=elemento[1];
      this.nomempleadosService.nommaeEgreFijo[index].MAEPERIODO_CODIGO=elemento[2]; 
      this.nomempleadosService.nommaeEgreFijo[index].MAEEGREFIJO_FECHREGI=this.cambiarfechanull(this.selectednomEgreFijo.MAEEGREFIJO_FECHREGI); 
      this.egraggrid.refreshaggrid(this.nomempleadosService.nommaeEgreFijo, this.defaultColDefNomEgreFijo.objeto );
      this.egraggrid.gridApi.setFocusedCell(index , 'MAEEGREFIJO_ABONO');
    }catch(err){
     
    }
  }
  salirEgrePerEmpl(elemento){
    if(elemento=='salir'){
      this.displaydialogEgrePerEmpl=false;
      let index= this.nomempleadosService.nommaeEgreFijo.map(x=>{return x.NUMEGRFIJ}).indexOf(this.selectednomEgreFijo.NUMEGRFIJ);
      this.nomempleadosService.nommaeEgreFijo[index].MAEEGREFIJO_FECHREGI=this.cambiarfechanull(this.selectednomEgreFijo.MAEEGREFIJO_FECHREGI); 
      this.egraggrid.refreshaggrid(this.nomempleadosService.nommaeEgreFijo, this.defaultColDefNomEgreFijo.objeto );
      this.egraggrid.gridApi.setFocusedCell(index , 'MAEEGREFIJO_ABONO');
    }
  }
  eliminarIngFijo(){
    if (this.selectednomIngrFijo!== undefined ) {
      if(this.selectednomIngrFijo.MAEINGRFIJO_ABONO==""){
        this.selectednomIngrFijo.MAEINGRFIJO_ABONO=0;
      }
      if(parseFloat(this.selectednomIngrFijo.MAEINGRFIJO_ABONO)==0 ||isNullOrUndefined(this.selectednomIngrFijo.MAEINGRFIJO_ABONO) ){
        this.confirmationService.confirm({
          message: '¿Está seguro que desea eliminar?',
          header: 'Confirmación',
          icon: 'pi pi-exclamation-triangle',
          key: 'NomEmpleIngFijoEli',
          acceptLabel: 'Sí',
          rejectLabel: 'No',
          accept: () => {
            try{
              this.nomempleadosService.eliminarempleadoingrefijo(this.selectednomIngrFijo.MAEINGRFIJO_CODIGO).then(async ()=>{
                this.mensajeAlerta('success', 'Información', '¡Registro eliminado!');
                await this.cancelarIngFijo();
               });
            }catch(e){
              let mensaje: string;
              mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
              this.mensajeAlerta('error', 'Error', mensaje);
            }
           
            /* this.nonmaeRub.eliminarRubro(this.selectedNomMaeRub.MAERUBRO_CODIGO).then(res=>{
              this.mensajeAlerta('success', 'Información', '¡Registro eliminado!');
              this.cargar();
              this.auditoriaGralService.registrarAuditoria('nom_maerubro', this.selectedNomMaeRub.MAERUBRO_CODIGO, 'E',
            '', '01', '', '', '', '').toPromise().then(() => {
            });
            }).catch(e=>{
             
            }) */
          },
          reject: () => {
          }
        })
      }else{
        this.mensajeAlerta('info', 'Información', 'No se puede eliminar este registro tiene abonos registrados');
      } 
     
    }
  }

  async busPeriodoVal(parametro){
    let resp="";
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    
    this.busquedacampos = [parametro, '', ''];
    this.consulta = ' PERIODO_CODIGO,PERIODO_FECINI';
    this.tabla = ' NOM_MAEPERIODO';
    this.where = " PERIODO_FECINI='"+parametro+"'";
    return new Promise<any>(async (resolve)=>{
     await this.nomempleadosService.encontrarRegistro18(parametro).then(eR => {
      if (eR !== null) {
          if (eR[0] !== undefined) {
            resolve(eR[0].PERIODO_CODIGO);
          } else {
            resolve(resp);
          }
        } else {
          resolve(resp);
        }
      });
    });
  }
  async cancelarIngFijo(){
    try{
      this.nomempleadosService.nommaenomIngrFijo=[];
      await this.nomempleadosService.empleadoingrefijo(this.selectedNomEmpl.EMP_CODIGO).then(async (res:any)=>{
        try{
          this.nomempleadosService.nommaenomIngrFijo=res;
          let i=0;
          this.nomempleadosService.nommaenomIngrFijo.map(async x=>{
            x.MAEINGRFIJO_IMPORTE=parseFloat(this.numberEmplPer(x.MAEINGRFIJO_IMPORTE)).toFixed(2);
            x.MAEINGRFIJO_NROCUOT=parseFloat(this.numberEmplPer(x.MAEINGRFIJO_NROCUOT)).toFixed(2);
            x.MAEINGRFIJO_SALDO=parseFloat(this.numberEmplPer(x.MAEINGRFIJO_SALDO)).toFixed(2);
            x.MAEINGRFIJO_ABONO=parseFloat(this.numberEmplPer(x.MAEINGRFIJO_ABONO)).toFixed(2);
            x.MAEINGRFIJO_FECINI=this.cambiarfechanull(x.MAEINGRFIJO_FECINI);
            x.MAEINGRFIJO_FECFIN=this.cambiarfechanull(x.MAEINGRFIJO_FECFIN);
            x.MAEINGRFIJO_FECHREGI=this.cambiarfechanull(x.MAEINGRFIJO_FECHREGI);
            x.MAEINGRFIJO_APORIESS=(x.MAEINGRFIJO_APORIESS=="1")?true:false;
            x.MAEPERIODO_CODIGO="";
            x.NUMEGRFIJ=i++;
          });
          for(let vali of this.nomempleadosService.nommaenomIngrFijo){
            const per=await this.busPeriodoIngEmp(vali.MAERUBRO_CODIGO).then(re=>{
              vali.MAERUBRO_CODIGO2=re;
            });
          }
          for(let vali of this.nomempleadosService.nommaenomIngrFijo){
            const per=await this.busPeriodoVal(vali.MAEINGRFIJO_FECINI).then(re=>{
              vali.MAEPERIODO_CODIGO=re;
            });
          }
        }catch(e){
          this.nomempleadosService.nommaenomIngrFijo=[];
        }
      });
      this.aggrid.refreshaggrid(this.nomempleadosService.nommaenomIngrFijo, this.defaultColDefMaeIngrFijo.objeto );
     
      this.selectednomIngrFijo=null;

    }catch(err){
    
    }
  }
  
  async egresosFijo(valor){
    if (valor === 'nuevo') {
      this.nuevoEgreFijo();
    }

    if (valor === 'eliminar') {
      this.eliminarEgreFijo();
    }

    if (valor === 'cancelar') {
      await this.cancelarEgreFijo();
    }
    if (valor.object !== undefined) {
      this.selectednomEgreFijo=valor.object;
    }
  }
  nuevoEgreFijo(){
    this.egraggrid.isEditable(true);
    let dat=new Date();
    const newModulo: nomEgreFijo={  
      CON_CODIGO:'',
      MAEEGREFIJO_CODIGO:'',
      MAEEGREFIJO_DESCRIPCION:'',
      CEN_CODIGO:'',
      MAEEGREFIJO_IMPORTE:'',  
      MAEEGREFIJO_NROCUOT:'',
      MAEEGREFIJO_FECHREGI:this.cambiarfechanull(dat),
      MAEEGREFIJO_FECINI:this.cambiarfechanull(dat),
      MAEEGREFIJO_FECFIN:this.cambiarfechanull(dat), 
      MAEEGREFIJO_ABONO:0,  
      MAEEGREFIJO_SALDO:0, 
      MAERUBRO_CODIGO2:'',
      MAERUBRO_CODIGO:'',
      NUEVO:true,
      NUMEGRFIJ:this.nomempleadosService.nommaeEgreFijo.length     
    };
    try{
      this.nomempleadosService.nommaeEgreFijo.push(newModulo); 
      this.egraggrid.refreshaggrid(this.nomempleadosService.nommaeEgreFijo, this.defaultColDefNomEgreFijo.objeto ); 
      const indice= this.nomempleadosService.nommaeEgreFijo.indexOf(newModulo);
      this.egraggrid.gridApi.setFocusedCell(indice , 'CON_CODIGO');
    }catch(err){
      
    }
  }
  eliminarEgreFijo(){
    if ( this.selectednomEgreFijo !== undefined ) {
        if(this.selectednomEgreFijo.MAEEGREFIJO_ABONO==""){
          this.selectednomEgreFijo.MAEEGREFIJO_ABONO=0;
        }
      if(parseFloat(this.selectednomEgreFijo.MAEEGREFIJO_ABONO) ==0||isNullOrUndefined(this.selectednomEgreFijo.MAEEGREFIJO_ABONO)){
        this.confirmationService.confirm({
          message: '¿Está seguro que desea eliminar?',
          header: 'Confirmación',
          icon: 'pi pi-exclamation-triangle',
          key: 'NomEmpleEgreFijoEli',
          acceptLabel: 'Sí',
          rejectLabel: 'No',
          accept: () => {
            try{
              this.nomempleadosService.eliminarempleadoegrefijo(this.selectednomEgreFijo.MAEEGREFIJO_CODIGO).then(async ()=>{
                this.mensajeAlerta('success', 'Información', '¡Registro eliminado!');
                await this.cancelarEgreFijo();
              });
            }catch(e){
              let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
              this.mensajeAlerta('error', 'Error', mensaje);
            }
          
            /* this.nonmaeRub.eliminarRubro(this.selectedNomMaeRub.MAERUBRO_CODIGO).then(res=>{
              this.mensajeAlerta('success', 'Información', '¡Registro eliminado!');
              this.cargar();
              this.auditoriaGralService.registrarAuditoria('nom_maerubro', this.selectedNomMaeRub.MAERUBRO_CODIGO, 'E',
            '', '01', '', '', '', '').toPromise().then(() => {
            });
            }).catch(e=>{
              let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
            this.mensajeAlerta('error', 'Error', mensaje);
            }) */
          },
          reject: () => {
          }
        })
      }else{
        this.mensajeAlerta('info', 'Información', 'No se puede eliminar este registro tiene abonos registrados');
      }

    }
  
  }
  cambioEgrFijo(event){
    this.btnGuardar=false;
    this.btnNuevo=true;
    this.btnBorrar=true;
    this.btnRegresar=false;
    this.botonAnterior=true;
    this.botonPrimero=true;
    this.botonUltimo=true;
    this.btnBuscar=true;
    this.botonSiguiente=true;
  }
  async cancelarEgreFijo(){
  
    this.nomempleadosService.nommaeEgreFijo=[];
    try{
      await this.nomempleadosService.empleadoegrefijo(this.selectedNomEmpl.EMP_CODIGO).then(async (res:any)=>{
        try{
          this.nomempleadosService.nommaeEgreFijo=res;
          let i=0;
          this.nomempleadosService.nommaeEgreFijo.map(async x=>{
            x.MAEEGREFIJO_IMPORTE=parseFloat(this.numberEmplPer(x.MAEEGREFIJO_IMPORTE)).toFixed(2);
            x.MAEEGREFIJO_NROCUOT=parseFloat(this.numberEmplPer(x.MAEEGREFIJO_NROCUOT)).toFixed(2);
            x.MAEEGREFIJO_SALDO=parseFloat(this.numberEmplPer(x.MAEEGREFIJO_SALDO)).toFixed(2);
            x.MAEEGREFIJO_ABONO=parseFloat(this.numberEmplPer(x.MAEEGREFIJO_ABONO)).toFixed(2);
            x.MAEEGREFIJO_FECINI=this.parseFecha(x.MAEEGREFIJO_FECINI);
            x.MAEEGREFIJO_FECFIN=this.parseFecha(x.MAEEGREFIJO_FECFIN);
            x.MAEEGREFIJO_FECHREGI=this.parseFecha(x.MAEEGREFIJO_FECHREGI);
            x.NUMEGRFIJ=i++;
            x.MAEPERIODO_CODIGO="";
          });

          for(let vali of this.nomempleadosService.nommaeEgreFijo){
            await this.busPeriodoEgrEmp( vali.MAERUBRO_CODIGO).then(re=>{
              vali.MAERUBRO_CODIGO2=re;
            });
          }
          for(let vali of this.nomempleadosService.nommaeEgreFijo){
            const per=await this.busPeriodoVal(vali.MAEEGREFIJO_FECINI).then(re=>{
              vali.MAEPERIODO_CODIGO=re;
            });
          }
        }catch(e){
          this.nomempleadosService.nommaeEgreFijo=[];
        }
      });
      this.egraggrid.refreshaggrid(this.nomempleadosService.nommaeEgreFijo, this.defaultColDefNomEgreFijo.objeto );
      this.selectednomEgreFijo=null;
      
    }catch(err){
      
    }
  }
  parseFecha(valor){
    if(isNullOrUndefined(valor)){
      return "";
    }else{
      return this.pipe.transform(valor,'dd/MM/yyyy')
    }
   
  }
  selecVacaciones(event){

  }
  cambioVacaciones(event){

  }
  selecVac(val){
    this.activarValoresVac();
  }
  activarValoresVac(){
    this.displayDialogActivarValores=true;
    this.valoresformu=false;
    this.vacacionestable=true;
   
  }
  onhideVaca(){
    this.displayDialogActivarValores=false;
    this.vacacionestable=false;
     this.tablasVaca();
  }
  async selecCargaFami(valor){
    
    if (valor === 'nuevo') {
      this.nuevoCargaFami();
    }

    if (valor === 'eliminar') {
      this.eliminarCargaFami();
    }

    if (valor === 'cancelar') {
      await this.cancelarCargaFami();
    }
    if (valor === 'guardar') {
      await this.guardarCargaFami();
    }
    if (valor.object !== undefined) {
      this.selectednomCargaFami=valor.object;
    }
  }
  cambioCargaFami(event){
    this.btnNuevoCarFam=true;
    this.btnGuardarCarFam=false;
    this.btnBorrarCarFam=true;
    this.btnCancelarCarFam=false;
  }
  botoneModCf(){
    this.btnNuevoCarFam=false;
    this.btnGuardarCarFam=true;
    this.btnBorrarCarFam=false;
    this.btnCancelarCarFam=false;
  }
  //CDPJ
  botoneModCf2(){
    this.btnNuevoCarFam=false;
    this.btnGuardarCarFam=false;
    this.btnBorrarCarFam=true;
    this.btnCancelarCarFam=true;
    this.selectednomCargaFami=null;
  }
  //CDPJ
  seleccionDisc(params){
    this.btnNuevoCarFam=true;
    this.btnGuardarCarFam=false;
    this.btnBorrarCarFam=true;
    this.btnCancelarCarFam=false;
  }
  cambioFechaNac(param){
    let index= this.nomempleadosService.nomCargaFamil.map(x=>{return x.NUMCARFA}).indexOf(this.selectednomCargaFami.NUMCARFA);
    this.nomempleadosService.nomCargaFamil[index].EMPCARGFAMI_FECHNACI=this.cambiarfechanull(this.selectednomCargaFami.EMPCARGFAMI_FECHNACI);
    this.cfggrid.refreshaggrid(this.nomempleadosService.nomCargaFamil, this.defaultColDefnomCargaFami.objeto );
    this.cfggrid.gridApi.setFocusedCell(index , 'EMPCARGFAMI_DISC');
  }
  eliminarCargaFami(){
    if ( this.selectednomCargaFami !== undefined ) {
      this.confirmationService.confirm({
        message: '¿Está seguro que desea eliminar?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'NomEmpleCargaEli',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {
          try{
            this.nomempleadosService.elimicargfami(this.selectednomCargaFami.EMPCARGFAMI_CODIGO).then(async ()=>{
              this.mensajeAlerta('success', 'Información', '¡Registro eliminado!');
              await this.cancelarCargaFami();
            });
          }catch(e){
            let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
            this.mensajeAlerta('error', 'Error', mensaje);
          }
        
          /* this.nonmaeRub.eliminarRubro(this.selectedNomMaeRub.MAERUBRO_CODIGO).then(res=>{
            this.mensajeAlerta('success', 'Información', '¡Registro eliminado!');
            this.cargar();
            this.auditoriaGralService.registrarAuditoria('nom_maerubro', this.selectedNomMaeRub.MAERUBRO_CODIGO, 'E',
          '', '01', '', '', '', '').toPromise().then(() => {
          });
          }).catch(e=>{
            let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
          }) */
        },
        reject: () => {
        }
      })
    }
  }
  nuevoCargaFami(){
    this.btnNuevoCarFam=false;
    this.btnGuardarCarFam=false;
    this.btnBorrarCarFam=false;
    this.btnCancelarCarFam=false;
    this.cfggrid.isEditable(true);
    const newModulo: nomCargaFami={  
      COM_CODIGO:'',
      EMPCARGFAMI_CARNCONA:'',
      EMPCARGFAMI_CODIGO:'',
      EMPCARGFAMI_DISC:'',
      EMPCARGFAMI_ESTATUS:'',
      EMPCARGFAMI_FECHNACI:'',
      EMPCARGFAMI_NOMBRE:'',
      EMPCARGFAMI_PORCDISC:'',
      EMPCARGFAMI_RUCIDE:'',
      EMPCARGFAMI_SEXO:'',
      EMPCARGFAMI_TIPOIDE:'',
      EMPCARGFAMI_TIPORELA:'',
      NUMCARFA:this.nomempleadosService.nomCargaFamil.length+1,
      NUEVO:true
     
    };
    try{
      this.nomempleadosService.nomCargaFamil.push(newModulo); 
      this.cfggrid.refreshaggrid(this.nomempleadosService.nomCargaFamil, this.defaultColDefnomCargaFami.objeto ); 
      this.selectednomCargaFami=newModulo;//CDPJ
      const indice= this.nomempleadosService.nomCargaFamil.indexOf(newModulo);
      this.cfggrid.gridApi.setFocusedCell(indice , 'EMPCARGFAMI_NOMBRE');
    }catch(err){
     
    }
  }
  async cancelarCargaFami(){
    let i=0;
    await this.nomempleadosService.cargafamiliar(this.selectedNomEmpl.EMP_CODIGO).then(async (res:any)=>{
      try{  
        this.nomempleadosService.nomCargaFamil=res;
        this.nomempleadosService.nomCargaFamil.map(x=>{
          x.EMPCARGFAMI_DISC=(x.EMPCARGFAMI_DISC=="N")?false:true;
          x.NUMCARFA=i++;
          if(isNullOrUndefined(x.EMPCARGFAMI_PORCDISC)||x.EMPCARGFAMI_PORCDISC==''){
            x.EMPCARGFAMI_PORCDISC='';
          }else{
            x.EMPCARGFAMI_PORCDISC=parseFloat(this.numberEmplPer(x.EMPCARGFAMI_PORCDISC)).toFixed(2);
          }
          x.EMPCARGFAMI_FECHNACI=this.cambiarfechanull(x.EMPCARGFAMI_FECHNACI);
        });
        for(let vali of this.nomempleadosService.nomCargaFamil){
          const pro= await this.busquedaAGTipoRela(vali.EMPCARGFAMI_TIPORELA).then(re=>{
            vali.EMPCARGFAMI_TIPORELA2=re;
          })
        };
      }catch(e){
        this.nomempleadosService.nomCargaFamil=[];
      }  
    }); 
    
    this.btnNuevoCarFam=false;
    this.btnGuardarCarFam=true;
    this.btnBorrarCarFam=false;
    this.btnCancelarCarFam=false;
    this.cfggrid.refreshaggrid(this.nomempleadosService.nomCargaFamil, this.defaultColDefnomCargaFami.objeto );
  }
  cambioValidarid(param){
    let value:boolean=true;
  
    if(this.selectednomCargaFami.EMPCARGFAMI_TIPOIDE=='CEDULA'){
      if(this.selectednomCargaFami.EMPCARGFAMI_RUCIDE.length>10){
        this.mensajeAlerta('info', 'Información','No puede ingresar más de 10 dígitos si el tipo de ID es cédula');
        return;
      }
      let vali:boolean=this.validarCedula(this.selectednomCargaFami.EMPCARGFAMI_RUCIDE);
      if(vali==false){
        this.mensajeAlerta('info', 'Información','Cédula está incorrecta');
        
      }
    }else{
      let valid:boolean=this.validarPasaporte(this.selectednomCargaFami.EMPCARGFAMI_RUCIDE);
      if(this.selectednomCargaFami.EMPCARGFAMI_RUCIDE.length>13){
        this.mensajeAlerta('info', 'Información','No puede ingresar más de 10 dígitos si el tipo de ID es pasaporte');
        return;
      }
      if(valid==false){
        this.mensajeAlerta('info', 'Información','Pasaporte está incorrecto');
       
      }
    }
  }
  async guardarCargaFami(){
    if(this.selectedNomEmpl.NUEVO==false){
      this.cfggrid.gridApi.stopEditing();
      //CDPJ
      if(this.selectedNomEmpl.EMP_NUMCARGAS !== null && this.selectedNomEmpl.EMP_NUMCARGAS !== undefined
        && this.selectedNomEmpl.EMP_NUMCARGAS !=='' && this.nomempleadosService.nomCargaFamil.length === 0){
          this.nomempleadosService.actunumcarga(this.selectedNomEmpl.EMP_CODIGO,this.selectedNomEmpl.EMP_NUMCARGAS).then(async res=>{
            await this.cancelarCargaFami();
          })
      }
      //CDPJ
    if(this.selectednomCargaFami!=null){
      let validos:string[]=this.validarVaciosCarEmp();
        if(validos.length==0){
          let valor:string[]=this.validarCarFami();
            if(valor.length==0){
              try{
                this.nomempleadosService.actucargfami(this.selectednomCargaFami,this.selectedNomEmpl.EMP_CODIGO).then(async resp=>{
                  this.nomempleadosService.actunumcarga(this.selectedNomEmpl.EMP_CODIGO,this.selectedNomEmpl.EMP_NUMCARGAS).then(async res=>{
                    this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
                    await this.cancelarCargaFami();
                    let index= this.nomempleadosService.nomCargaFamil.map(x=>{return x.NUMCARFA}).indexOf(this.selectednomCargaFami.NUMCARFA);
                    this.cfggrid.refreshaggrid(this.nomempleadosService.nomCargaFamil, this.defaultColDefnomCargaFami.objeto );
                    this.cfggrid.gridApi.setFocusedCell(index , 'EMPCARGFAMI_NOMBRE');
                    await this.nomempleadosService.selectEmpleado(this.selectedNomEmpl.EMP_CODIGO).then(async re=>{      
                      this.selectedNomEmpl.EMP_NUMCARGAS=re[0].EMP_NUMCARGAS;        
                    })
                  })
                  
                });
              }catch(e){
                this.mensajeAlertaAuto('error', 'Error', e);
              }
            }else{
              let mensaje=""
              for(let i=0;i<valor.length;i++){
                if(i==0){
                  mensaje+=valor[0];
                }else{
                  mensaje+=", "+valor[i];
                }
              }
              this.mensajeAlerta('info', 'Información', mensaje);
            }
        }else{
            let mensaje=""
            for(let i=0;i<validos.length;i++){
              if(i==0){
                mensaje+=validos[0];
              }else{
                mensaje+=", "+validos[i];
              }
            }
            this.mensajeAlerta('info', 'Información', mensaje);
        }
      }else{
          this.nomempleadosService.actunumcarga(this.selectedNomEmpl.EMP_CODIGO,this.selectedNomEmpl.EMP_NUMCARGAS).then(async res=>{
            this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
            await this.cancelarCargaFami();
            await this.nomempleadosService.selectEmpleado(this.selectedNomEmpl.EMP_CODIGO).then(async re=>{      
              this.selectedNomEmpl.EMP_NUMCARGAS=re[0].EMP_NUMCARGAS;        
            })
            // let index= this.nomempleadosService.nomCargaFamil.map(x=>{return x.NUMCARFA}).indexOf(this.selectednomCargaFami.NUMCARFA);
            // this.cfggrid.refreshaggrid(this.nomempleadosService.nomCargaFamil, this.defaultColDefnomCargaFami.objeto );
            // this.cfggrid.gridApi.setFocusedCell(index , 'EMPCARGFAMI_NOMBRE');
            //CDPJ
            if(this.nomempleadosService.nomCargaFamil.length>0){
              let index= this.nomempleadosService.nomCargaFamil.map(x=>{return x.NUMCARFA}).indexOf(this.selectednomCargaFami.NUMCARFA);
            this.cfggrid.refreshaggrid(this.nomempleadosService.nomCargaFamil, this.defaultColDefnomCargaFami.objeto );
            this.cfggrid.gridApi.setFocusedCell(index , 'EMPCARGFAMI_NOMBRE');
            }
            //CDPJ
          })
      }
    }else{
        this.mensajeAlerta('info', 'Información', 'No se puede realizar esta acción');
    }
  }
  validarCarFami(){
    //return 0;
    let value:boolean=true;
    let valor:any[]=[]
    if(this.selectednomCargaFami.EMPCARGFAMI_TIPOIDE=='CEDULA'){
      let vali:boolean=this.validarCedula(this.selectednomCargaFami.EMPCARGFAMI_RUCIDE);
      if(vali==false){
       
        valor.push('Cédula está incorrecta');
      }
    }else{
      let valid:boolean=this.validarPasaporte(this.selectednomCargaFami.EMPCARGFAMI_RUCIDE);
      if(valid==false){
       
        valor.push('Pasaporte está incorrecto');
      }
    }
    if(isNullOrUndefined(this.selectednomCargaFami.EMPCARGFAMI_PORCDISC)||this.selectednomCargaFami.EMPCARGFAMI_PORCDISC==''){

    }else{
      if(this.validarDiscapacidad(this.selectednomCargaFami.EMPCARGFAMI_PORCDISC)==false){
       
        valor.push('El porcentaje de discapcidad no puede ser menor que 0  ni mayor que 100');
      }
    }
    return valor;
  }
  validarVaciosCarEmp(){
    let msg:string[]=[];
    if(isNullOrUndefined(this.selectednomCargaFami.EMPCARGFAMI_NOMBRE)||this.selectednomCargaFami.EMPCARGFAMI_NOMBRE==''){
      msg.push("El campo Nombre es obligatorio");
    }
    if(isNullOrUndefined(this.selectednomCargaFami.EMPCARGFAMI_TIPOIDE)||this.selectednomCargaFami.EMPCARGFAMI_TIPOIDE==''){
      msg.push("El campo T.ID es obligatorio");
    }
    if(isNullOrUndefined(this.selectednomCargaFami.EMPCARGFAMI_RUCIDE)||this.selectednomCargaFami.EMPCARGFAMI_RUCIDE==''){
      msg.push("El campo ID es obligatorio");
    }
    if(isNullOrUndefined(this.selectednomCargaFami.EMPCARGFAMI_ESTATUS)||this.selectednomCargaFami.EMPCARGFAMI_ESTATUS==''){
      msg.push("El campo Estatus Utilidades es obligatorio");
    }
    return msg;
  }
  async llenarVACIOS(){
    this.selectedNomEmpl.BAN_CODIGOEMP="";
    this.selectedNomEmpl.CLI_CODIGO="";
    this.selectedNomEmpl.COM_CODIGO="";
    this.selectedNomEmpl.CON_CODIALIM="";
    this.selectedNomEmpl.CON_CODIANTI="";
    this.selectedNomEmpl.CON_CODIBONIRESP="";
    this.selectedNomEmpl.CON_CODIETI1ING="";
    this.selectedNomEmpl.CON_CODIETI2ING="";
    this.selectedNomEmpl.CON_CODIGOPRES="";
    this.selectedNomEmpl.CON_CODIGOSXP="";
    this.selectedNomEmpl.CON_CODIMOVI="";
    this.selectedNomEmpl.CON_CODIPRESHIPO="";
    this.selectedNomEmpl.CON_CODIPRESIESSQ="";
    this.selectedNomEmpl.CON_CODIREMUSALAUNIF="";
    this.selectedNomEmpl.CON_CODISEGUPRIV="";
    this.selectedNomEmpl.CON_CODITELE="";
    this.selectedNomEmpl.DEP_CODIGO="";
    this.selectedNomEmpl.EMP_ACTIVO="";
    this.selectedNomEmpl.EMP_ALIMENTACION="";
    this.selectedNomEmpl.EMP_ALIMTRIBIESS="";
    this.selectedNomEmpl.EMP_ANTICIPO="";
    this.selectedNomEmpl.EMP_APELLIDO="";
    this.selectedNomEmpl.EMP_APLICONVEVITDOBLTRIB="";
    this.selectedNomEmpl.EMP_BONRESP="";
    this.selectedNomEmpl.EMP_CALC75="";
    this.selectedNomEmpl.EMP_CALCDECCUARTO="";
    this.selectedNomEmpl.EMP_CALCDECTERCERO="";
    this.selectedNomEmpl.EMP_CALCVACACIONES="";
    this.selectedNomEmpl.EMP_CALLE="";
    this.selectedNomEmpl.EMP_CARGIESS="";
    this.selectedNomEmpl.EMP_CARGO="";
    this.selectedNomEmpl.EMP_CARNCONA="";
    this.selectedNomEmpl.EMP_CASA="";
    this.selectedNomEmpl.EMP_CAUSSALI="";
    this.selectedNomEmpl.EMP_CEDUMILI="";
    this.selectedNomEmpl.EMP_CELULAR="";
    this.selectedNomEmpl.EMP_CENTCOST="";
    this.selectedNomEmpl.EMP_CODIBANCEMPL="";
    this.selectedNomEmpl.EMP_CODIGO=null;
    this.selectedNomEmpl.EMP_CODIGO2="";
    this.selectedNomEmpl.EMP_COMPSALA="";
    this.selectedNomEmpl.EMP_CONCODIGO="";
    this.selectedNomEmpl.EMP_CONDTRABRESPDISC="";
    this.selectedNomEmpl.EMP_CORREO="";
    this.selectedNomEmpl.EMP_CTAAHORROS="";
    this.selectedNomEmpl.EMP_CTACALC75="";
    this.selectedNomEmpl.EMP_CTACOMPSALA="";
    this.selectedNomEmpl.EMP_DECICUARCALC="";
    this.selectedNomEmpl.EMP_DECICUARPAGA="";
    this.selectedNomEmpl.EMP_DECICUARRETI="";
    this.selectedNomEmpl.EMP_DECICUARTREGI="";
    this.selectedNomEmpl.EMP_DECITERCCALC="";
    this.selectedNomEmpl.EMP_DECITERCPAGA="";
    this.selectedNomEmpl.EMP_DECITERCRETI="";
    this.selectedNomEmpl.EMP_DETADISC="";
    this.selectedNomEmpl.EMP_DETASALI="";
    this.selectedNomEmpl.EMP_DIRECCION="";
    this.selectedNomEmpl.EMP_DISCSUST="";
    this.selectedNomEmpl.EMP_EMPRASUMIMPURENT="";
    this.selectedNomEmpl.EMP_ESTACIVIL="";
    this.selectedNomEmpl.EMP_ESTATRAB="";
    this.selectedNomEmpl.EMP_ETI1ING="";
    this.selectedNomEmpl.EMP_ETI1INGTRIBIESS="";
    this.selectedNomEmpl.EMP_ETI2ING="";
    this.selectedNomEmpl.EMP_ETI2INGTRIBIESS="";
    this.selectedNomEmpl.EMP_FECHACONTRATO="";
    this.selectedNomEmpl.EMP_FECHNACI="";
    this.selectedNomEmpl.EMP_FECINGRESO="";
    this.selectedNomEmpl.EMP_FECSALIDA="";
    this.selectedNomEmpl.EMP_FNDRSVPAGAR="";
    this.selectedNomEmpl.EMP_FNDRSVRRETENER="";
    this.selectedNomEmpl.EMP_FNDSRSRVA="";
    this.selectedNomEmpl.EMP_FORMPAGO="";
    this.selectedNomEmpl.EMP_HORATIEMPARC="";
    this.selectedNomEmpl.EMP_MOVILIZACION="";
    this.selectedNomEmpl.EMP_NOMBRDISCSUST="";
    this.selectedNomEmpl.EMP_NOMBRE="";
    this.selectedNomEmpl.EMP_NUMCARGAS="";
    this.selectedNomEmpl.EMP_PAISRESI="";
    this.selectedNomEmpl.EMP_PLAZOCONTRATO="";
    console.log(Number(await this.FunGlbObtieneCnfgSistemaFlt('NOMAPIND')).toFixed(2));
    this.selectedNomEmpl.EMP_PORCAPORINDI = Number(await this.FunGlbObtieneCnfgSistemaFlt('NOMAPIND')).toFixed(2);
    this.selectedNomEmpl.EMP_PORCAPORPATR = Number(await this.FunGlbObtieneCnfgSistemaFlt('NOMAPPATR')).toFixed(2);
    this.selectedNomEmpl.EMP_PORCDISC="";
    this.selectedNomEmpl.EMP_PORCFONDRESER = Number(await this.FunGlbObtieneCnfgSistemaFlt('NOMPRFNRSV')).toFixed(2);
    this.selectedNomEmpl.EMP_PRESIESS="";
    this.selectedNomEmpl.EMP_PRESTAMO="";
    this.selectedNomEmpl.EMP_PRESTHIPOTECARIO="";
    this.selectedNomEmpl.EMP_REMSALUNI="";
    this.selectedNomEmpl.EMP_RESITRAB="";
    this.selectedNomEmpl.EMP_ROLADMN="";
    this.selectedNomEmpl.EMP_ROLVEND="";
    this.selectedNomEmpl.EMP_RUCIDE="";
    this.selectedNomEmpl.EMP_RUCIDEDISCSUST="";
    this.selectedNomEmpl.EMP_SEGUROFP=""; 
    this.selectedNomEmpl.EMP_SEXO=""; 
    this.selectedNomEmpl.EMP_TELEFONO="";
    this.selectedNomEmpl.EMP_TELEFONO1="";
    this.selectedNomEmpl.EMP_TELEFONO2="";
    this.selectedNomEmpl.EMP_TIPCTABANC="";
    this.selectedNomEmpl.EMP_TIPOEMPL="";
    this.selectedNomEmpl.EMP_TIPOIDE="";
    this.selectedNomEmpl.EMP_TIPOSISTSALANETO="";
    this.selectedNomEmpl.EMP_TIPROL_CODIGO_03="";
    this.selectedNomEmpl.EMP_TIPROL_CODIGO_04="";
    this.selectedNomEmpl.EMP_TRABJORNPARC="";
    this.selectedNomEmpl.EMP_VACACALC="";
    this.selectedNomEmpl.EMP_VACAPAGA="";
    this.selectedNomEmpl.EMP_VACARETI="";
    this.selectedNomEmpl.PROV_CODIGO="";
    this.selectedNomEmpl.PROV_CODIGOC="";
    this.selectedNomEmpl.TIPROL_CODIGO="";
    this.selectedNomEmpl.VEN_CODIGO="";
    this.selectedNomEmpl.EMP_CORREOEMPRE="";
    this.selectedNomEmpl.EMP_CORREOPERS="";
    this.selectedNomEmpl.EMP_NACIONALIDAD="";
    this.selectedNomEmpl.EMP_LICENCADUCA ="";
    this.selectedNomEmpl.EMP_LICENCIA="";
    this.selectedNomEmpl.EMP_CARNET="";
    this.selectedNomEmpl.EMP_LUGARNACI="";
    this.selectedNomEmpl.EMP_JEFEINMD="";
    this.selectedNomEmpl.EMP_JEFEEXT="";
    this.selectedNomEmpl.EMP_ENFCATASTROFICA="";
    this.rdecimocuarto="";
    this.rdecimotercero="";
    this.cvacaciones="";
    this.pagarretener="";
}
activochk(){
  this.calsalifech = !this.chksalida;
  this.botonesmodificar();
  this.selectedNomEmpl.EMP_ACTIVO=(this.chkactivo)==true?1:0;
}
impuestochk(){
  this.botonesmodificar();
  this.selectedNomEmpl.EMP_EMPRASUMIMPURENT=(this.chkImpRent)==true?1:0;
}
joparchk(){
  this.botonesmodificar();
  this.selectedNomEmpl.EMP_TRABJORNPARC=(this.chkJorPar)==true?1:0;
}
ecalcchk(){
  this.botonesmodificar();
  this.selectedNomEmpl.EMP_CALC75=(this.chkEMP_CALC75)==true?1:0;
}
fndsrsrvachk(){
  this.botonesmodificar();
  this.selectedNomEmpl.EMP_FNDSRSRVA=(this.chkEMP_FNDSRSRVA)==true?1:0;
}
vaccalcchk(){
  this.botonesmodificar();
  this.selectedNomEmpl.EMP_VACACALC=(this.chkEMP_VACACALC)==true?1:0;
}
decimotercerchk(){
  this.botonesmodificar();
  this.selectedNomEmpl.EMP_DECITERCCALC=(this.chkEMP_DECITERCCALC)==true?1:0;
}
decimocuartochk(){
  this.botonesmodificar();
  this.selectedNomEmpl.EMP_DECICUARCALC=(this.chkEMP_DECICUARCALC)==true?1:0;
}
isEditIngre(params){
  if(this.selectednomIngrFijo.NUEVO==true){
    return true;
  }else{
    if(parseFloat(this.selectednomIngrFijo.MAEINGRFIJO_ABONO)!=0){
      return false;
    }else{
      return true;
    }
  }
 
}
isEditEgre(params){
  if(this.selectednomEgreFijo.NUEVO==true){
    return true;
  }else{
    if(parseFloat(this.selectednomEgreFijo.MAEEGREFIJO_ABONO)!=0){
      return false;
    }else{
      return true;
    }
  }
}
mensajeAlerta(tipo, titulo, msg) {
  this. messageService.add({
    key: 'maeEmpleadob',
    severity: tipo,
    summary: titulo,
    detail: msg
  });
}

  async FunGlbObtieneCnfgSistemaFlt(cfg_codigo) {
    let FunGlbObtieneCnfgSistemaFlt = 0;
    try {
      const rs = await this.nomempleadosService.obtenerConfig(cfg_codigo);
      if (rs !== null) {
        FunGlbObtieneCnfgSistemaFlt = rs[0].CFG_VALOR1 === null ? 0: rs[0].CFG_VALOR1;
      } else {
        FunGlbObtieneCnfgSistemaFlt = 0;
      }
    } catch (err) {
      this.mensajeAlerta('error', 'Error', err.error);
      FunGlbObtieneCnfgSistemaFlt = 0;
    }
    return FunGlbObtieneCnfgSistemaFlt;
  }

}
