import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { Observable } from 'rxjs';
import { VenEnctraped } from '../veninterfaces/venenctraped';
import { VenDectraped } from '../veninterfaces/vendectraped';
import { Usuario } from 'src/app/usuario';


@Injectable({
  providedIn: 'root'
})
export class VentransforpedService {

  venEnctraped: VenEnctraped[] = [];
  lstVenEncPed: VenDectraped[][] = [];
  public way: string;

  constructor(private http: HttpClient, private confIniciales: ConfInicialesService,
    public usuario: Usuario) {
    this.way = sessionStorage.getItem('way');
  }

  metodo(cliCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/ventransforped/jdhfishf', {
      elementos: {
        CLI_CODIGO: cliCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  mostrarenctraped(strComprobante): any {
    return this.http.post(this.way + '/ventas/transformarpedidos/b639ddab6ebb38000000', {
      elementos: {
        strComprobante
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  mostrarpedidos(chkpendientes): any {
    return this.http.post(this.way + '/ventas/transformarpedidos/2d8e776ade5cda0000', {
      elementos: {
        chkpendientes
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  mostrardectraped(NroComprobante): any {
    return this.http.post(this.way + '/ventas/transformarpedidos/b639ddab6d7338000000', {
      elementos: {
        NroComprobante
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  buscardectraped(columna): any {
    return this.http.post(this.way + '/ventas/transformarpedidos/2fdc62b6d733bc00000', {
      elementos: {
        columna
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarenctraped(documento, fecha, comentario): any {
    return this.http.post(this.way + '/ventas/transformarpedidos/12bf1dbeab6ebb00000000', {
      elementos: {
        p_enctraped_codigo: documento,
        p_com_codigo: '01',
        p_enctraped_fechaemision: fecha,
        p_enctraped_comentario: comentario,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertardettraped(numero, item): any {
    return this.http.post(this.way + '/ventas/transformarpedidos/12bf1dbeab6d7700000000', {
      elementos: {
        p_enctraped_codigo: numero,
        p_com_codigo: '01',
        p_dectraped_numero: item.DECTRAPED_NUMERO,
        p_dectraped_encfacnumero: item.DECTRAPED_ENCFACNUMERO,
        p_dectraped_comprobante: item.DECTRAPED_COMPROBANTE,
        p_dectraped_fechaemision: item.DECTRAPED_FECHAEMISION,
        p_dectraped_estado: item.DECTRAPED_ESTADO,
        p_dectraped_clicodigo: item.DECTRAPED_CLICODIGO,
        p_dectraped_vencodigo: item.DECTRAPED_VENCODIGO,
        p_dectraped_observacion: item.DECTRAPED_OBSERVACION
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  transformar(pedido, grupo, nemonico, serie, actual, autorizacion, comprobante, fecha): any {
    return this.http.post(this.way + '/ventas/transformarpedidos/3bb55f8fc6ecae000000000', {
      elementos: {
        p_encped_numero: pedido,
        p_grupo: grupo,
        p_encfac_nemonico: nemonico,
        p_encfac_serie: serie,
        p_encfac_secactual: actual,
        p_encfac_numaut: autorizacion,
        p_com_codigo: '01',
        p_usuidentificacion: this.usuario.identificacion,
        p_comprobante: comprobante,
        p_fechaemision: fecha
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  navegarregistro(tipo, codigo): any {
    return this.http.post(this.way + '/ventas/transformarpedidos/71d573192c5d5c0000000000', {
      elementos: {
        p_tipo: tipo,
        p_com_codigo: '01',
        p_enctraped_codigo: codigo,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getexistar(): any {
    return this.http.post(this.way + '/ventas/transformarpedidos/41dd73392e755c', {
      elementos: {
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminartransforped(comprobante): any {
    return this.http.post(this.way + '/ventas/transformarpedidos/eacad2bab7dda80000000', {
      elementos: {
        p_enctraped_codigo: comprobante,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }  

}
