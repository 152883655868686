import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {Observable} from 'rxjs';
import {SaciMaeGuiObjPer, SaciMaeNumeracion} from '../seginterfaces/segmaeusuario';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';

@Injectable({
  providedIn: 'root'
})
export class SegmaeusuarioService {
  
  public detnumeracionarray: SaciMaeNumeracion[][] = [];
  public detguiobjpermiarray: SaciMaeGuiObjPer[][] = [];
  public strSuperUserSelect: any = [];
  
  private way: string;
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, private confIniciales: ConfInicialesService,
              private init: NuevoComponentService) {
    this.way = sessionStorage.getItem('way');
  }
  
  consultaRegistro(tipo, usuario, condicion) {
    return this.http.post<any[]>(this.way + '/seg/usuario/198bf3db7425c', {
      elementos: {
        p_opcion: tipo,
        p_USUidentificacionp: usuario,
        p_condicion: condicion,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erVenNombre(strVenCodigo) {
    return this.http.post(this.way + '/seg/usuario/3b7f75ef8b2f6e', {
      elementos: {
        VEN_CODIGO: strVenCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erPerfilCodigo(strPerfilCodigo) {
    return this.http.post(this.way + '/seg/usuario/76f2edbe55661c0', {
      elementos: {
        USUIDENTIFICACION: strPerfilCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  consultarMaePerfil(codCompania) {
    const strSentencia = 'Select * from Seg_Maeperfil where com_codigo=\'' + codCompania + '\'';
    
    return this.http.post<any[]>(this.way + '/seg/usuario/3317e594ecbb70', {
        elementos: {
          COM_CODIGO: codCompania
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  insertarUsuario(usuIdentificacion, usuClave, usuRuci, usuApellido, usuNombre, comCodigo, usuPerfil, usuFechaInicio, usuFechaFinal, bodCodigo,
    bodCodigoDev, strVenCodigo, strCorreoPin, strTelefonoPin, usuautenticacion, almacenarDoc, visualizarDoc,verpreconexist,vertotcxc,
      usu_todclientes ,//GSRF
    usu_todpedidos,//GSRF
    ) {// CDPJ AGREGUE verpreconexist y vertotcxc
        return this.http.post<any[]>(this.way + '/seg/usuario/12bf1dbefb9e57000', {
        elementos: {
        p_USUidentificacion_1: usuIdentificacion,
        p_USUclave_2: usuClave,
        p_USUruci_3: usuRuci,
        p_USUapellido_4: usuApellido,
        p_USUnombre_5: usuNombre,
        p_COMcodigo_6: comCodigo,
        p_PERFIL_codigo_7: usuPerfil,
        p_USUfechainicio_8: usuFechaInicio,
        p_USUfechafinal_9: usuFechaFinal,
        p_bod_codigo: bodCodigo,
        p_BOD_CODIGO_DEV: bodCodigoDev,
        p_ven_codigo: strVenCodigo,
        p_usu_correoPin: strCorreoPin,
        p_usu_telefonoPin: strTelefonoPin,
        p_usuautenticacion: usuautenticacion,
        p_usu_almacenardoc: almacenarDoc,
        p_usu_visualizardoc: visualizarDoc,
        p_usu_verpreciosconsultexist:verpreconexist,
        p_usu_todclientes:usu_todclientes,
        p_usu_todpedidos:usu_todpedidos,
        p_usu_vertotcxc:vertotcxc

        }
      }, this.confIniciales.httpOptions()).toPromise();
    }

  actualizarUsuario(usuIdentificacion, usuClave, usuRuci, usuApellido, usuNombre, comCodigo, usuPerfil, usuFechaInicio, usuFechaFinal,
          bodCodigo, bodCodigoDev, strVenCodigo, strCorreoPin, strTelefonoPin, usuautenticacion, almacenarDoc, visualizarDoc,verpreconexist,vertotcxc
          ,usu_todclientes,//GSRF
        usu_todpedidos,//GSRF
      ) {// CDPJAGREGUE verpreconexist) {
    return this.http.post<any[]>(this.way + '/seg/usuario/a677caafb9e57000', {
        elementos: {
        p_USUidentificacion_1: usuIdentificacion,
        p_USUclave_2: usuClave,
        p_USUruci_3: usuRuci,
        p_USUapellido_4: usuApellido,
        p_USUnombre_5: usuNombre,
        p_COMcodigo_6: '01',
        p_PERFIL_codigo_7: usuPerfil,
        p_USUfechainicio_8: usuFechaInicio,
        p_USUfechafinal_9: usuFechaFinal,
        p_bod_codigo: bodCodigo,
        p_BOD_CODIGO_DEV: bodCodigoDev,
        p_ven_codigo: strVenCodigo,
        p_usu_correoPin: strCorreoPin,
        p_usu_telefonoPin: strTelefonoPin,
        p_usuautenticacion: usuautenticacion,
        p_usu_almacenardoc: almacenarDoc,
        p_usu_visualizardoc: visualizarDoc,
        p_usu_verpreciosconsultexist:verpreconexist,
        p_usu_todclientes:usu_todclientes,
        p_usu_todpedidos:usu_todpedidos,
        p_usu_vertotcxc:vertotcxc
        }
     }, this.confIniciales.httpOptions()).toPromise();
    }
      
  consultarTransacciones(strIdentificacion) {
    return this.http.post<any[]>(this.way + '/seg/usuario/cc5f9eaf76abf000', {
        elementos: {
          USU_IDENTIFICACION: strIdentificacion
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  eliminarUsuario(usuIdentificacion, comCodigo) {
    return this.http.post<any[]>(this.way + '/seg/usuario/eacad2bfb9e57000', {
      elementos: {
        p_USUidentificacion_1: usuIdentificacion,
        p_COMcodigo_2: comCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarNumeracion(numClase, comCodigo, numDocref, numNemonico, numSerie) {
    return this.http.post<any[]>(this.way + '/seg/usuario/eacafeb3b6a64800', {
      elementos: {
        p_NUM_clase: numClase,
        p_COM_codigo: comCodigo,
        p_NUM_docref: numDocref,
        p_NUM_nemonico: numNemonico,
        p_NUM_serie: numSerie
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erMaeVendedor(strParametro): Observable<any> {
    return this.http.post(this.way + '/seg/usuario/edd94efbaed73800', {
      elementos: {
        VEN_CODIGO: strParametro
      },
    }, this.confIniciales.httpOptions());
  }
  
  obtenerModulos() {
    return this.http.post<any[]>(this.way + '/seg/usuario/c2faebdb0df5700', {
      elementos: {
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerMenu(prefijo) {
    return this.http.post<any[]>(this.way + '/seg/usuario/617d75ddbb3b00', {
      elementos: {
        p_prefijo: prefijo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerOpciones() {
    const strSentencia = 'select * from seg_maeopcion  order by modprefijo,mencodigo,opccodigo';
    
    return this.http.post<any[]>(this.way + '/seg/usuario/185f5d7c6592c6000', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  obtenerPermisos(usuario, compania) {
    const strSentencia = 'select * from seg_maepermiso where USUidentificacion=\'' + usuario + '\' ' +
      ' and COMcodigo= \'' + compania + '\' order by modprefijo,mencodigo, opccodigo ';
    return this.http.post<any[]>(this.way + '/seg/usuario/c2faecbb7697300', {
        elementos: {
          USUIDENTIFICACION: usuario,
          COM_CODIGO: compania
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  eliminarPermisos(usuario, compania) {
    return this.http.post<any[]>(this.way + '/seg/usuario/75656cbb7697300', {
      elementos: {
        p_USUidentificacion_1: usuario,
        p_COMcodigo_2: compania
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  insertarPermisos(usuarioId, comCodigo, perSeleccion, perInsercion, perActualizacion, perEliminacion, perTodo, opcCodigo, menCodigo, modPrefijo) {
    return this.http.post<any[]>(this.way + '/seg/usuario/12bf1dbee5dbb5000', {
      elementos: {
        p_USUidentificacion_1: usuarioId,
        p_COMcodigo_2: comCodigo,
        p_PERseleccion_3: perSeleccion,
        p_PERinsercion_4: perInsercion,
        p_PERactualizacion_5: perActualizacion,
        p_PERelimacion_6: perEliminacion,
        p_PERtodo_7: perTodo,
        p_OPCcodigo_8: opcCodigo,
        p_MENcodigo_9: menCodigo,
        p_MODprefijo_10: modPrefijo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  consultarUsuarios(compania) {
    const strSentencia = 'select * from seg_maeusuario where comcodigo=\'' + compania + '\' order by usuNombre ';
    
    return this.http.post<any[]>(this.way + '/seg/usuario/198bf3d5f73cae0000', {
        elementos: {
          COM_CODIGO: compania
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  copiarPermisos(opcion, comCodigo, usuIdentificacionOrg, usuIdentificacionDest, modPrefijo, menCodigo) {
    return this.http.post<any[]>(this.way + '/seg/usuario/198cc95bcbb7690000', {
      elementos: {
        p_opcion: opcion,
        p_COMcodigo: '01',
        p_USUidentificacionOrg: usuIdentificacionOrg,
        p_USUidentificacionDest: usuIdentificacionDest,
        p_MODprefijo: modPrefijo,
        p_MENcodigo: menCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  listarNumeraciones(strNumClase) {
    const strSentencia = 'select * from saci_maenumeracion where num_clase like nvl(\'' + String(strNumClase).trim() + '\',\'%%\') and com_codigo=\'01\'';
    
    return this.http.post<any[]>(this.way + '/seg/usuario/acb9d55fd676d80', {
        elementos: {
          NUM_CLASE: String(strNumClase).trim()
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  eliminarDetNumeracion(numClase, comCodigo, numDocRef, numNemonico, numSerie) {
    
    return this.http.post<any[]>(this.way + '/seg/usuario/1d595a4d776feb0000', {
      elementos: {
        p_NUM_clase: numClase,
        p_COM_codigo: comCodigo,
        p_NUM_docref: numDocRef,
        p_NUM_nemonico: numNemonico,
        p_NUM_serie: numSerie
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  ejecutarSentencia(strNumero, strDocref) {
    return this.http.post<any[]>(this.way + '/seg/usuario/185f6feb6dd977000', {
        elementos: {
          NUM_CLASE: strNumero,
          NUM_DOCREF: strDocref
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  insertarNumeracion(numClase, comCodigo, numDocRef, numNemonico, numSerie, numSecInicial, numSecFinal, numSecActual, numTipo, numAutorizacion,
                     numFechaCad, numFechaIni, numAutoImpresor, numAutorizacionAnt,num_fe) {
    return this.http.post<any[]>(this.way + '/seg/usuario/95f8edf6feb3b8000000', {
      elementos: {
        p_NUM_clase: numClase,
        p_COM_codigo: comCodigo,
        p_NUM_docref: numDocRef,
        p_NUM_nemonico: numNemonico,
        p_NUM_serie: numSerie,
        p_NUM_secinicial: numSecInicial,
        p_NUM_secfinal: numSecFinal,
        p_NUM_secactual: numSecActual,
        p_NUM_tipo: numTipo,
        p_NUM_autorizacion: numAutorizacion,
        p_NUM_fechacad: numFechaCad,
        p_NUM_fechaini: numFechaIni,
        p_NUM_autoimpresor: numAutoImpresor,
        p_NUM_autorizacionant: numAutorizacionAnt,
        p_Num_fe:num_fe
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  listarModuloPerEsp() {
    let strConsulta = 'SELECT DISTINCT O.MOD_PREFIJO,M.MODNOMBRE FROM SEG_MAEMODULO M,SACI_MAEGUIOBJ O ';
    strConsulta = strConsulta + ' WHERE M.MODPREFIJO=O.MOD_PREFIJO ORDER BY M.MODNOMBRE';
    
    return this.http.post<any[]>(this.way + '/seg/usuario/2b2e76d86e5db80000', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  listarUsuariosPerEsp() {
    let strConsulta = 'SELECT DISTINCT P.USUIDENTIFICACION,U.USUCLAVE, U.USUAPELLIDO,U.USUNOMBRE';
    strConsulta = strConsulta + ' FROM SEG_MAEUSUARIO U,SEG_MAEPERMISO P ';
    strConsulta = strConsulta + ' WHERE U.USUIDENTIFICACION=P.USUIDENTIFICACION AND U.COMCODIGO=P.COMCODIGO AND ';
    strConsulta = strConsulta + ' U.COMCODIGO= \'01\'';
    
    return this.http.post<any[]>(this.way + '/seg/usuario/15973bee7b2edc000', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  listarOpcionesPerEsp(strPrefijo) {
    let strConsulta = 'SELECT DISTINCT O.OPCNOMBRE,O.OPCCODIGO,O.OPCOBJETO FROM SEG_MAEOPCION O,SACI_MAEGUIOBJ GO  WHERE ';
    strConsulta = strConsulta + 'O.OPCOBJETO=GO.GUIOBJ_FORMA AND ';
    strConsulta = strConsulta + ' O.MODPREFIJO=\'' + strPrefijo + '\'';
    
    return this.http.post<any[]>(this.way + '/seg/usuario/15973b8cb32edc000', {
        elementos: {
          MODPREFIJO: strPrefijo
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  mostrarSuperUsuario(strIdUsuario) {
    let strConsulta = 'select distinct usu_identificacionsup,usunombre,usuapellido, usuidentificacion, usuclave from SACI_MAEGUIOBJPER a,seg_maeusuario b ' +
      'where usu_identificacion=\'' + strIdUsuario + '\'';
    strConsulta = strConsulta + ' and a.usu_identificacionsup=b.usuidentificacion and';
    strConsulta = strConsulta + ' a.COM_CODIGO= \'01\'';
    
    console.log(strConsulta);
    return this.http.post<any[]>(this.way + '/seg/usuario/2d8e776adf3da00000', {
        elementos: {
          USU_IDENTIFICACION: strIdUsuario
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  listarPermisosEspeciales(guiObjForma, modPrefijo, usuIdentificacion) {
    console.log(guiObjForma);
    console.log(modPrefijo);
    console.log(usuIdentificacion);
    return this.http.post<any[]>(this.way + '/seg/usuario/acb9dcbb7677300', {
      elementos: {
        p_guiobj_forma: guiObjForma,
        p_mod_prefijo: modPrefijo,
        p_usu_identificacion: usuIdentificacion,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  guardarPerEspeciales(guiobjCodigo, usuIdentificacion, comCodigo, guiobjperEstado, guiobjperPerfil, guiobjperDescripcion, guiobjperCodigo,
                       opccodigo, guiobjpersupEstado, usuIdentificacionSup, usucaveSup, guiobjperDescMax, guiobjperDescMaxV, existe) {
    return this.http.post<any[]>(this.way + '/seg/usuario/10f2b6dcbb6ee6000', {
      elementos: {
        p_GUIOBJ_CODIGO: guiobjCodigo,
        p_USU_IDENTIFICACION: usuIdentificacion,
        p_COM_CODIGO: comCodigo,
        p_GUIOBJPER_ESTADO: guiobjperEstado,
        p_GUIOBJPER_PERFIL: guiobjperPerfil,
        p_GUIOBJPER_DESCRIPCION: guiobjperDescripcion,
        p_GUIOBJPER_CODIGO: guiobjperCodigo,
        p_OPCCODIGO: opccodigo,
        p_GUIOBJPERSUP_ESTADO: guiobjpersupEstado,
        p_USU_IDENTIFICACIONSUP: usuIdentificacionSup,
        p_USU_CLAVESUP: usucaveSup,
        p_GUIOBJPER_DSCTOMAXvta: guiobjperDescMax,
        p_GUIOBJPER_DSCTOMAXVTAV: guiobjperDescMaxV,
        p_existe: existe
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  actualizarNumeracion(numClase, comCodigo, numDocRef, numNemonico, numSerie, numSecInicial, numSecFinal, numSecActual, numAutorizacion,
                       numFechaCad, numFechaIni, numAutoImpresor,num_fe) {
    
    return this.http.post<any[]>(this.way + '/seg/usuario/a677caacafeb3800', {
      elementos: {
        p_NUM_clase: numClase,
        p_COM_codigo: comCodigo,
        p_NUM_docref: numDocRef,
        p_NUM_nemonico: numNemonico,
        p_NUM_serie: numSerie,
        p_NUM_secinicial: numSecInicial,
        p_NUM_secfinal: numSecFinal,
        p_NUM_secactual: numSecActual,
        p_NUM_autorizacion: numAutorizacion,
        p_NUM_fechacad: numFechaCad,
        p_NUM_fechaini: numFechaIni,
        p_NUM_autoimpresor: numAutoImpresor,
        p_Num_fe:num_fe
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
}
