import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { MessageService } from 'primeng/api';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { DatePipe } from '@angular/common';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { InvDettransfer } from '../invinterfaces/invdettransfer';
import { InvTrnvariostrf } from '../invinterfaces/invtrnvariostrf';
import { InvEnctransfer } from '../invinterfaces/invenctransfer';

@Injectable({
  providedIn: 'root'
})
export class InvenctransferService {

 
  public way: string;
  invenctransferarray: InvEnctransfer[] = [];
  invdettransferarray: InvDettransfer[][] = [];
  invtrnvariostrfarray: InvTrnvariostrf[][] = [];

  public decTOTAL: number;
  public decTOTALDET: number;
  public decdetcantidad: number;
  public decdetcosto: number;
  public horacam: any;

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private confIniciales: ConfInicialesService,
    private datePipe: DatePipe,
    private init: NuevoComponentService,

  ) {
    
    this.way = sessionStorage.getItem('way');
  }

  erFindTransportistas(tabla, value, parametro) {
    return this.http.post(this.way + '/inventario/transferencias/4ckso0dilg4r3be', {
      elementos: {
        VALOR: tabla,
        VALUE: value,
        PARAMETRO: parametro
      },
    }, this.confIniciales.httpOptions());
  }


  erFindTransferNro(codigo) {
    return this.http.post(this.way + '/inventario/transferencias/8n2ypxrdm6bs7vq', {
      elementos: {
        ART_CODIGO: codigo
      },
    }, this.confIniciales.httpOptions());
  }

  erRealTransfer(transferNro) {
    return this.http.post(this.way + '/inventario/transferencias/pzwgmaytlo7ius1', {
      elementos: {
        TRANSFER_NRO: transferNro
      },
    }, this.confIniciales.httpOptions());
  }

  erFindPlacaTransportistas(codigo) {
    return this.http.post(this.way + '/inventario/transferencias/830dugb8uovbl3f', {
      elementos: {
        TRANSPORT_CODIGO: codigo
      },
    }, this.confIniciales.httpOptions());
  }

  erFindCodigoTransportistas(codigo) {
    return this.http.post(this.way + '/inventario/transferencias/ouyow4gfc13cf9r', {
      elementos: {
        TRANSPORT_CODIGO: codigo
      },
    }, this.confIniciales.httpOptions());
  }

  erFindBodega(strTabla, value, parametro) {
    return this.http.post(this.way + '/inventario/transferencias/6yntf2p7974qm0v', {
      elementos: {
        VALOR: strTabla,
        VALUE: value,
        PARAMETRO: parametro
      },
    }, this.confIniciales.httpOptions());
  }

  erFindBodDestino(bod_destino) {
    return this.http.post(this.way + '/inventario/transferencias/9rfpsylxmggs6dm', {
      elementos: {
        BOD_DESTINO: bod_destino
      },
    }, this.confIniciales.httpOptions());


  }


  erNombreEstado(strEstado) {

    return this.http.post(this.way + '/inventario/transferencias/p7ro35w06kq6893', {
      elementos: {
        ESTADO: strEstado
      },
    }, this.confIniciales.httpOptions());

  }


  ecGetCostoProm(strArtCodigo, strFecha, strHora) {
    return this.http.post<any[]>(this.way + '/inventario/transferencias/hv8bcjijlxvgcre', {
      elementos: {
        ArtCodigo: strArtCodigo,
        Fecha: strFecha,
        Hora: strHora
      }
    }, this.confIniciales.httpOptions());
  }

  ecGetExistArt(strArtCodigo, strBodCodigo, strFecha, strHora) {
    return this.http.post<any[]>(this.way + '/inventario/transferencias/mhzlzl7xjbublcr', {
      elementos: {
        ArtCodigo: strArtCodigo,
        BodCodigo: strBodCodigo,
        Fecha: strFecha,
        Hora: strHora
      }
    }, this.confIniciales.httpOptions());
  }


  ecListConfTransfer() {
    return this.http.post<any[]>(this.way + '/inventario/transferencias/ga348leufghot3y', {
      elementos: {}
    }, this.confIniciales.httpOptions());
  }

  seleccionarRegistro(tipo, cadena, condicion): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/transferencias/x2rw140lowtyeum', {
      elementos: {
        p_tipo: tipo,
        p_Cadena: cadena,
        p_condicion: condicion
      }
    }, this.confIniciales.httpOptions());
  }

  consultarEncTransfer(tipo, cadena, condicion): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/transferencias/un20xeqsi5u0mbg', {
      elementos: {
        p_tipo: tipo,
        p_Cadena: cadena,
        p_condicion: condicion
      }
    }, this.confIniciales.httpOptions());
  }

  getEnctransfer(transfernumero): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/inventario/transferencias/n7cf2an6byhe2ew', {
      elementos: {
        TRANSFER_NRO: transfernumero
      }
    }, this.confIniciales.httpOptions());
  }

  getDettransfer(transfernumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/transferencias/9dzowov4yswf3zj', {
      elementos: {
        TRANSFER_NRO: transfernumero
      },
    }, this.confIniciales.httpOptions());
  }

  insertarEnctransfer(item): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/transferencias/23jjpsq0wd28uwu', {
      elementos: {
        p_COM_codigo: item.COM_CODIGO,
        p_TRANSFER_fechaemision: item.TRANSFER_FECHAEMISION,
        p_TRANSFER_fechaautoriza: item.TRANSFER_FECHAAUTORIZA,
        p_TRANSFER_fechatransfer: item.TRANSFER_FECHATRANSFER,
        p_BOD_codigo: item.BOD_CODIGO,
        p_TRANSFER_comentario: item.TRANSFER_COMENTARIO,
        p_USUIDENTIFICACION: this.usuario.identificacion,
        p_TRANSFER_estado: item.TRANSFER_ESTADO,
        p_TRANSFER_comprobante: item.TRANSFER_COMPROBANTE,
        p_TRANSFER_personaautoriza: item.TRANSFER_PERSONAAUTORIZA,
        p_TRANSFER_tipo: item.TRANSFER_TIPO,
        P_TRANSPORT_codigo: item.TRANSPORT_CODIGO,
        p_TRANSFER_REFER: item.TRANSFER_REFER,
        p_TRANSFER_FLAG: item.TRANSFER_FLAG,
        p_maemotivo_codigo: '',
        p_numtra_sigla: item.MUESTRARIO,
        p_cli_codigo: item.CLI_CODIGO,
        p_transfer_referencia: item.TRANSFER_REFERENCIA,
        p_pro_codigo: item.PRO_CODIGO,
        p_pro_nombre: item.PRO_NOMBRE,
        p_tranfer_hora: item.TRANSFER_HORA,
      }
    }, this.confIniciales.httpOptions());
  }

  insertarEnctransferProm(item) {
    return this.http.post<any>(this.way + '/inventario/transferencias/sg120d2t8we0fej', {
      elementos: {
        p_COM_codigo: item.COM_CODIGO,
        p_TRANSFER_fechaemision: item.TRANSFER_FECHAEMISION,
        p_TRANSFER_fechaautoriza: item.TRANSFER_FECHAAUTORIZA,
        p_TRANSFER_fechatransfer: item.TRANSFER_FECHATRANSFER,
        p_BOD_codigo: item.BOD_CODIGO,
        p_TRANSFER_comentario: item.TRANSFER_COMENTARIO,
        p_USUIDENTIFICACION: this.usuario.identificacion,
        p_TRANSFER_estado: item.TRANSFER_ESTADO,
        p_TRANSFER_comprobante: item.TRANSFER_COMPROBANTE,
        p_TRANSFER_personaautoriza: item.TRANSFER_PERSONAAUTORIZA,
        p_TRANSFER_tipo: item.TRANSFER_TIPO,
        P_TRANSPORT_codigo: item.TRANSPORT_CODIGO,
        p_TRANSFER_REFER: item.TRANSFER_REFER,
        p_TRANSFER_FLAG: item.TRANSFER_FLAG,
        p_maemotivo_codigo: '',
        p_numtra_sigla: item.MUESTRARIO,
        p_cli_codigo: item.CLI_CODIGO,
        p_transfer_referencia: item.TRANSFER_REFERENCIA,
        p_pro_codigo: item.PRO_CODIGO,
        p_pro_nombre: item.PRO_NOMBRE,
        p_tranfer_hora: item.TRANSFER_HORA,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarEnctransfer(item): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/transferencias/35ov5i4worqsyj2', {
      elementos: {
        p_TRANSFER_nro: item.TRANSFER_NRO,
        p_COM_codigo: item.COM_CODIGO,
        p_TRANSFER_fechaemision: item.TRANSFER_FECHAEMISION,
        p_TRANSFER_fechaautoriza: item.TRANSFER_FECHAAUTORIZA,
        p_TRANSFER_fechatransfer: item.TRANSFER_FECHATRANSFER,
        p_BOD_codigo: item.BOD_CODIGO,
        p_TRANSFER_comentario: item.TRANSFER_COMENTARIO,
        p_USUIDENTIFICACION: this.usuario.identificacion,
        p_TRANSFER_estado: item.TRANSFER_ESTADO,
        p_TRANSFER_comprobante: item.TRANSFER_COMPROBANTE,
        p_TRANSFER_personaautoriza: item.TRANSFER_PERSONAAUTORIZA,
        p_TRANSFER_tipo: item.TRANSFER_TIPO,
        P_TRANSPORT_codigo: item.TRANSPORT_CODIGO,
        p_maemotivo_codigo: '',
        p_cli_codigo: item.CLI_CODIGO,
        p_transfer_referencia: item.TRANSFER_REFERENCIA,
        p_pro_codigo: item.PRO_CODIGO,
        p_pro_nombre: item.PRO_NOMBRE,
        p_tranfer_hora: item.TRANSFER_HORA,
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarEnctransferProm(item) {
    return this.http.post<any>(this.way + '/inventario/transferencias/w5n64j8cjbycf9d', {
      elementos: {
        p_TRANSFER_nro: item.TRANSFER_NRO,
        p_COM_codigo: item.COM_CODIGO,
        p_TRANSFER_fechaemision: item.TRANSFER_FECHAEMISION,
        p_TRANSFER_fechaautoriza: item.TRANSFER_FECHAAUTORIZA,
        p_TRANSFER_fechatransfer: item.TRANSFER_FECHATRANSFER,
        p_BOD_codigo: item.BOD_CODIGO,
        p_TRANSFER_comentario: item.TRANSFER_COMENTARIO,
        p_USUIDENTIFICACION: this.usuario.identificacion,
        p_TRANSFER_estado: item.TRANSFER_ESTADO,
        p_TRANSFER_comprobante: item.TRANSFER_COMPROBANTE,
        p_TRANSFER_personaautoriza: item.TRANSFER_PERSONAAUTORIZA,
        p_TRANSFER_tipo: item.TRANSFER_TIPO,
        P_TRANSPORT_codigo: item.TRANSPORT_CODIGO,
        p_maemotivo_codigo: '',
        p_cli_codigo: item.CLI_CODIGO,
        p_transfer_referencia: item.TRANSFER_REFERENCIA,
        p_pro_codigo: item.PRO_CODIGO,
        p_pro_nombre: item.PRO_NOMBRE,
        p_tranfer_hora: item.TRANSFER_HORA,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarDetTransfer(item): Promise<any> {
    return this.http.post<any>(this.way + '/inventario/transferencias/h4ta2mpscx3at8c', {
      elementos: {
        p_TRANSFER_nro: item.TRANSFER_NRO,
        p_COM_codigo: item.COM_CODIGO,
        p_ART_codigo: item.ART_CODIGO,
        p_BOD_codigo: item.BOD_CODIGO,
        p_DETTRANSFER_cantidad: item.DETTRANSFER_CANTIDAD,
        p_DETTRANSFER_costototal: item.DETTRANSFER_COSTOTOTAL,
        p_uni_codigo: item.UNI_CODIGO,
        p_DETTRANSFER_FLAG: item.DETTRANSFER_FLAG,
        p_DETTRANSFER_COSTOUNIT: item.DETTRANSFER_COSTOUNIT,
        p_DETTRANSFER_REFERENCIA: item.DETTRANSFER_REFERENCIA,
        p_TRNRES_numero: item.TRNRES_NUMERO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarDetTransfer(item): Promise<any> {
    return this.http.post<any>(this.way + '/inventario/transferencias/wqw48l0e4n63omh', {
      elementos: {
        p_TRANSFER_nro: item.TRANSFER_NRO,
        p_COM_codigo: item.COM_CODIGO,
        p_ART_codigo: item.ART_CODIGO,
        p_BOD_codigo: item.BOD_CODIGO,
        p_DETTRANSFER_cantidad: item.DETTRANSFER_CANTIDAD,
        p_DETTRANSFER_costototal: item.DETTRANSFER_COSTOTOTAL,
        p_uni_codigo: item.UNI_CODIGO,
        p_DETTRANSFER_COSTOUNIT: item.DETTRANSFER_COSTOUNIT,
        p_DETTRANSFER_LINEA: item.DETTRANSFER_LINEA,
        p_DETTRANSFER_REFERENCIA: item.DETTRANSFER_REFERENCIA
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarEncTransfer(transfernro): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/transferencias/padzxtlqgdnvurd', {
      elementos: {
        p_TRANSFER_nro: transfernro,
        p_COM_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarDetTransfer(item): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/transferencias/us89xlkklqdpwc1', {
      elementos: {
        p_TRANSFER_nro: item.TRANSFER_NRO,
        p_COM_codigo: '01',
        p_dettransfer_linea: item.DETTRANSFER_LINEA
      }
    }, this.confIniciales.httpOptions());
  }

  registroTrnKardex(trnartnumero, fecha): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/transferencias/xnr7e788wtgv5z0', {
      elementos: {
        p_numero: trnartnumero,
        p_com_codigo: '01',
        p_FechaTransfer: fecha
      }
    }, this.confIniciales.httpOptions());
  }

  recalculaCostoArtDoc(strCadena, strFecha): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/transferencias/8dyy1l9f6x4133l', {
      elementos: {
        p_cadena: strCadena,
        p_com_codigo: '01',
        p_fecha: strFecha

      }
    }, this.confIniciales.httpOptions());
  }


  obtenerExistenciaBodega(strTipo, strArtCodigo, strBodCodigo, strFecha, strHora): Promise<any> {

    return this.http.post<any[]>(this.way + '/inventario/transferencias/yb495349nj4ojgg', {
      elementos: {
        tipo: strTipo,
        art_codigo: strArtCodigo,
        fecha: strFecha,
        hora: strHora,
        bod_codigo: strBodCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
//CDPJ
obtenerExistenciaBodegaD(strTipo, strArtCodigo, strBodCodigo, strFecha, strHora):  Observable<any> {

  return this.http.post<any[]>(this.way + '/inventario/transferencias/yb495349nj4ojgg', {
    elementos: {
      tipo: strTipo,
      art_codigo: strArtCodigo,
      fecha: strFecha,
      hora: strHora,
      bod_codigo: strBodCodigo
    },
  }, this.confIniciales.httpOptions());
}
//CDPJ
  obtenerClave(clave): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/transferencias/drpe0wndxc07urh', {
      elementos: {
        clave: clave
      }
    }, this.confIniciales.httpOptions());
  }


  calcularCostoTotal(): number {
    let intIndiceLista = 0;
    let item: InvDettransfer = {};
    let dblCostoTotal = 0;
    let dblCostoTotaDet = 0;

    for (intIndiceLista = 0; intIndiceLista < this.invdettransferarray[this.init.tabs[this.init.tabindex].indice].length; intIndiceLista++) {
      item = this.invdettransferarray[this.init.tabs[this.init.tabindex].indice][intIndiceLista];

      if (item.ART_CODIGO !== '') {
        dblCostoTotaDet = 0;
        dblCostoTotaDet = Number(item.DETTRANSFER_CANTIDAD) * Number(item.DETTRANSFER_COSTOUNIT);
        item.DETTRANSFER_COSTOTOTAL = dblCostoTotaDet;
        dblCostoTotal = dblCostoTotal + dblCostoTotaDet;
      }
    }

    for (const rs of this.invdettransferarray[this.init.tabs[this.init.tabindex].indice]) {
      rs.DETTRANSFER_CANTIDAD = Number(rs.DETTRANSFER_CANTIDAD).toFixed(this.decdetcantidad);
      rs.DETTRANSFER_COSTOUNIT = Number(rs.DETTRANSFER_COSTOUNIT).toFixed(this.decdetcosto);
      rs.DETTRANSFER_COSTOTOTAL = Number(rs.DETTRANSFER_COSTOTOTAL).toFixed(this.decTOTALDET);
    }

    this.invenctransferarray[this.init.tabs[this.init.tabindex].indice].TOTAL = dblCostoTotal.toFixed(this.decTOTAL);

    return dblCostoTotal;
  }

  /*calcularTotalVarios(): number {
    let intIndiceLista = 0;
    let item: InvTrnvariostrf;
    let dblTotalImporte = 0;
    
    // Total Importe en varios
    for (intIndiceLista = 0; intIndiceLista < this.invtrnvariostrfarray[this.init.tabs[this.init.tabindex].indice].length; intIndiceLista++) {
      item = this.invtrnvariostrfarray[this.init.tabs[this.init.tabindex].indice][intIndiceLista];
      if (item.CON_CODIGO !== '') {
        dblTotalImporte = dblTotalImporte + item.TRNVARIOS_IMPORTE;
      }
    }
    
    this.invenctransferarray[this.init.tabs[this.init.tabindex].indice].TOTALVARIOS = dblTotalImporte.toFixed(this.decTOTAL);
    
    return dblTotalImporte;
    
  } // fin validaTotalVarios*/


  recibirReservas(art_codigo, strFecha, strHora) {
    return this.http.post<any[]>(this.way + '/inventario/transferencias/6xm82vfr7tp8ha2', {
      elementos: {
        ART_CODIGO: art_codigo,
        Fecha: strFecha,
        Hora: strHora
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  reversarTransferencia(transfer_NRO) {
    return this.http.post<any[]>(this.way + '/inventario/transferencias/i35siaaurnlz6u3', {
      elementos: {
        TRANSFER_NRO: transfer_NRO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  cambiarEstadoReserva(trnresNumero, estado) {
    return this.http.post<any>(this.way + '/inventario/transferencias/c67pjx7j2bluyq9', {
      elementos: {
        p_TRNRES_numero: trnresNumero,
        p_TRNRES_estado: estado,
        p_COM_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarEntregaReserva(trnresNumero, cantidad, estado) {
    return this.http.post<any>(this.way + '/inventario/transferencias/j37vnl6zow5twid', {
      elementos: {
        p_TRNRES_numero: trnresNumero,
        p_TRNRES_entregado: cantidad,
        p_TRNRES_estado: estado,
        p_COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erpBusquedaCliente(strValor, strValue, strParams) {
    return this.http.post(this.way + '/ventas/facturas/tq8cgrtwkhkurwk', {

      elementos: {
        Valor: strValor,
        Value: strValue,
        Params: strParams
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }
    //CDPJ
    validarSeriales(articulo, strNumDoc, intLinea): Promise<any[]> {
      return this.http.post<any[]>(this.way + '/inventario/transferencias/1ddbfeaac9aae400000000', {
        elementos: {
          art: articulo,
          numDoc: strNumDoc,
          linea: intLinea
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }
    //CDPJ
}

