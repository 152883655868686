import {Injectable, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {CxpTrncobroPagodoc} from '../cominterfaces/cxptrncobropagodoc';
import {CxpTrndocumento} from '../cominterfaces/cxptrndocumento';
import {CxpDettrncobro} from '../cominterfaces/cxpdettrncobro';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class CxptrnpagodocumentoService {
  public way: string;
  
  private _trncobro: CxpTrncobroPagodoc = {};
  public _documentos: CxpTrndocumento  [];
  private _varios: CxpDettrncobro  [];
  public hmpConfiguracion: any[];
  public dblValor: number;
  public total: any;
  public decimalesTOTALDetFacPro: number;
  public decimalesdetpagoDoc: number;
  public decimalesdetDocumento: number;
  public check:number;

  public grids: any[] = [];
  
  get trncobro(): CxpTrncobroPagodoc {
    return this._trncobro;
  }
  
  set trncobro(value: CxpTrncobroPagodoc) {
    this._trncobro = value;
  }
  
  get varios(): CxpDettrncobro[] {
    return this._varios;
  }
  
  set varios(value: CxpDettrncobro[]) {
    this._varios = value;
  }
  //**** 
get documentos(): CxpTrndocumento[] {
  return this._documentos;
}

set documentos(value: CxpTrndocumento[]) {
  this._documentos = value;
}
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }
    sumarcheck(valor){
    if (valor === true) {
      this.check = this.check + 1;
    } else {
      this.check = this.check - 1;
    }
  }
  insert_trncobro_pagodoc(item): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxp/trnpagodoc/257e77776617960000', {
      elementos: {
        p_trncobro_nrodoc: item.TRNCOBRO_NRODOC,
        p_com_codigo: item.COM_CODIGO,
        p_trncobro_fechatrn: item.TRNCOBRO_FECHATRN,
        p_trncobro_tipodoc: item.TRNCOBRO_TIPODOC,
        p_pro_codigo: item.PRO_CODIGO,
        p_trncobro_importe: item.TRNCOBRO_IMPORTE,
        p_trncobro_concepto: item.TRNCOBRO_CONCEPTO,
        p_trncobro_fechavence: item.TRNCOBRO_FECHAVENCE,
        p_aplorg_codigo: item.APLORG_CODIGO,
        p_trncobro_referenciaexterna: item.TRNCOBRO_REFERENCIAEXTERNA,
        p_trnban_beneficiario: item.TRNBAN_BENEFICIARIO,
        p_trncobro_flag: item.TRNCOBRO_FLAG,
        p_usu_identificacion: item.TRNCOBRO_USUARIO_ACT,
        p_tipdia_codigo: item.TIPDIA_CODIGO,
        p_con_codigo: item.CON_CODIGO,
        p_trnpago_numero: item.TRNPAGO_NUMERO,
        p_trnpago_tipo: item.TRNPAGO_TIPO,
        p_caj_codigo: item.CAJ_CODIGO,
        p_trnpago_fecha: item.TRNPAGO_FECHA,
        p_ban_codigo: item.BAN_CODIGO,
        p_trnban_tipo: item.TRNBAN_TIPO,
        p_trnban_numero: item.TRNBAN_NUMERO,
        p_trncobro_pagoelectronico: item.TRNCOBRO_PAGOELECTRONICO,
        
      }
    }, this.confIniciales.httpOptions());
  }
  insert_trncobro_pagodocProm(item): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trnpagodoc/257e77776617960000', {
      elementos: {
        p_trncobro_nrodoc: item.TRNCOBRO_NRODOC,
        p_com_codigo: item.COM_CODIGO,
        p_trncobro_fechatrn: item.TRNCOBRO_FECHATRN,
        p_trncobro_tipodoc: item.TRNCOBRO_TIPODOC,
        p_pro_codigo: item.PRO_CODIGO,
        p_trncobro_importe: item.TRNCOBRO_IMPORTE,
        p_trncobro_concepto: item.TRNCOBRO_CONCEPTO,
        p_trncobro_fechavence: item.TRNCOBRO_FECHAVENCE,
        p_aplorg_codigo: item.APLORG_CODIGO,
        p_trncobro_referenciaexterna: item.TRNCOBRO_REFERENCIAEXTERNA,
        p_trnban_beneficiario: item.TRNBAN_BENEFICIARIO,
        p_trncobro_flag: item.TRNCOBRO_FLAG,
        p_usu_identificacion: item.TRNCOBRO_USUARIO_ACT,
        p_tipdia_codigo: item.TIPDIA_CODIGO,
        p_con_codigo: item.CON_CODIGO,
        p_trnpago_numero: item.TRNPAGO_NUMERO,
        p_trnpago_tipo: item.TRNPAGO_TIPO,
        p_caj_codigo: item.CAJ_CODIGO,
        p_trnpago_fecha: item.TRNPAGO_FECHA,
        p_ban_codigo: item.BAN_CODIGO,
        p_trnban_tipo: item.TRNBAN_TIPO,
        p_trnban_numero: item.TRNBAN_NUMERO,
        p_trncobro_pagoelectronico: item.TRNCOBRO_PAGOELECTRONICO,
        
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  insertarTRNDOCUMENTO(item): Promise<any> {
    return this.http.post<any>(this.way + '/cxp/trnpagodoc/12bf3bbbb70cf6000', {
      elementos: {
        p_trncobro_nrodoc: item.TRNCOBRO_NRODOC,
        p_pro_codigo: item.PRO_CODIGO,
        p_com_codigo: item.COM_CODIGO,
        p_trndocumento_nrodoc: item.TRNDOCUMENTO_NRODOC,
        p_trndocumento_tipodoc: item.TRNDOCUMENTO_TIPODOC,
        p_trndocumento_importe: item.TRNDOCUMENTO_IMPORTE,
        p_trndocumento_descuento: item.TRNDOCUMENTO_DESCUENTO,
        p_trndocumento_tipodscto: item.TRNDOCUMENTO_TIPODSCTO,
        p_trndocumento_ref: item.TRNDOCUMENTO_REF,
        p_trndocumento_flag: item.TRNDOCUMENTO_FLAG
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  insertar_DETTRNCOBRO(item): Promise<any> {
    return this.http.post<any>(this.way + '/cxp/trnpagodoc/4afc6bbbbbb30c', {
      elementos: {
        p_TRNCOBRO_NRODOC: item.TRNCOBRO_NRODOC,
        p_COM_CODIGO: item.COM_CODIGO,
        p_DETTRNCOBRO_NROSEC: item.DETTRNCOBRO_NROSEC,
        p_CON_CODIGO: item.CON_CODIGO,
        p_DETTRNCOBRO_IMPORTE: item.DETTRNCOBRO_IMPORTE,
        p_DETTRNCOBRO_REFERENCIA: item.DETTRNCOBRO_REFERENCIA,
        p_TRNRETENCION_NRO: item.TRNRETENCION_NRO,
        p_RETENCION_CODIGO: item.RETENCION_CODIGO,
        p_encfac_numero: item.ENCFAC_NUMERO,
        p_encfac_comcodigo: item.ENCFAC_COMCODIGO,
        p_encfac_clicodigo: item.ENCFAC_CLICODIGO,
        p_ban_codigo: item.BAN_CODIGO,
        p_trnban_tipo: item.TRNBAN_TIPO,
        p_trnban_numero: item.TRNBAN_NUMERO,
        p_cen_codigo: item.CEN_CODIGO,
        p_pro_codigo: item.PRO_CODIGO,
        p_dettrncobro_tipo: item.DETTRNCOBRO_TIPO,
        p_dettrncobro_comprobante: item.DETTRNCOBRO_COMPROBANTE,
        p_tipgas_codigo: item.TIPGAS_CODIGO,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  
  eliminarTRNDOCUMENTO(trncobronrodoc): Promise<any> {
    return this.http.post<any>(this.way + '/cxp/trnpagodoc/75656eeeedc33c0', {
      elementos: {
        p_trncobro_nrodoc: trncobronrodoc,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarTrnCobro_PagDoc(trncobronrodoc): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trnpagodoc/7565dddf2a83700', {
      elementos: {
        p_trncobro_nrodoc: trncobronrodoc,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  anularTrnCobro(trncobronrodoc): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxp/trnpagodoc/55fd5eeeecc2f80', {
      elementos: {
        p_trncobro_nrodoc: trncobronrodoc,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  generaAsientoPagoProvProm(trncobronrodoc, tipdiacodigo, asicomprobante) {
    return this.http.post<any>(this.way + '/cxp/trnpagodoc/1075d5c96550c6000', {
      elementos: {
        p_trncobro_nrodoc: trncobronrodoc,
        p_com_codigo: '01',
        p_tipdia_codigo: tipdiacodigo,
        p_usuario: this.usuario.identificacion,
        p_asi_comprobante: asicomprobante
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  generaTrnCajdsdAsntProm(asinro) {
    return this.http.post<any>(this.way + '/cxp/trnpagodoc/83afd62a6de3580', {
      elementos: {
        p_asi_nro: asinro,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  actualizaEstadoFactura(strNumFact, strCodCliente): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trnpagodoc/14cef3ad7a99d', {
      elementos: {
        p_com_codigo: '01',
        p_cli_codigo: strCodCliente,
        p_encfac_numero: strNumFact
      },
    }, this.confIniciales.httpOptions());
  }
  
  actualizaTrnBanProm(itemban) {
    return this.http.post<any>(this.way + '/cxp/trnpagodoc/299df2bbbbad58', {
      elementos: {
        p_ban_codigo: itemban.BAN_CODIGO,
        p_trnban_tipo: itemban.TRNBAN_TIPO,
        p_trnban_numero: itemban.TRNBAN_NUMERO,
        p_trnban_fecha: itemban.TRNBAN_FECHA,
        p_trnban_importe: itemban.TRNBAN_IMPORTE,
        p_trnban_concepto: itemban.TRNBAN_CONCEPTO,
        p_trnban_beneficiario: itemban.TRNBAN_BENEFICIARIO,
        p_com_codigo: itemban.COM_CODIGO,
        p_asi_nro: itemban.ASI_NRO,
        p_trnban_origen: itemban.TRNBAN_ORIGEN,
        p_trncobro_nrodoc: itemban.TRNCOBRO_NRODOC,
        p_dettrncobro_nrosec: itemban.DETTRNCOBRO_NROSEC,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  actualizaEstadoFacpro(strCodProv, strTrnCobroNroDoc): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxp/trnpagodoc/14cef3afcef1f', {
      elementos: {
        p_pro_codigo: strCodProv,
        p_com_codigo: '01',
        p_trncobro_nrodoc: strTrnCobroNroDoc
      }
    }, this.confIniciales.httpOptions());
  }
  
  insertarNotaDebitoXAnt(item): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trnpagodoc/95f97c75ae5ab00', {
      elementos: {
        p_trncobro_nrodoc: item.TRNCOBRO_NRODOC,
        p_com_codigo: item.COM_CODIGO,
        p_TRNCOBRO_FECHATRN: item.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_TIPODOC: item.TRNCOBRO_TIPODOC,
        p_TRNCOBRO_NROCOMPROBANTE: item.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_CONCEPTO: item.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_IMPORTE: item.TRNCOBRO_IMPORTE,
        p_TRNCOBRO_FECHAVENCE: item.TRNCOBRO_FECHAVENCE,
        p_pro_CODIGO: item.PRO_CODIGO,
        p_APLORG_CODIGO: item.APLORG_CODIGO,
        p_trncobro_usuario_act: this.usuario.identificacion,
        p_trncobro_fecharecepcion: item.TRNCOBRO_FECHARECEPCION,
        p_trncobro_referenciaexterna: item.TRNCOBRO_REFERENCIAEXTERNA,
        p_con_codigo: item.CON_CODIGO,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  getDatosProveedor(procodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trnpagodoc/83bad5773bc7e00', {
      elementos: {
        PRO_CODIGO: procodigo,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  getDatosProveedorProm(procodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trnpagodoc/83bad5773bc7e00', {
      elementos: {
        PRO_CODIGO: procodigo,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  consultarDetTrnCobro(trncobronrodoc): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trnpagodoc/662fc6bbacc2f80', {
      elementos: {
        p_TRNCOBRO_NRODOC: trncobronrodoc,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  GENERA_ABONO_CANJEProm(trncobronrodoc) {
    return this.http.post<any>(this.way + '/cxp/trnpagodoc/20eba97862af3', {
      elementos: {
        p_trncobro_nrodoc: trncobronrodoc,
        p_com_codigo: '01',
        p_usu_identificacion: this.usuario.identificacion
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  erMaeProveedor(strParametro) {
    return this.http.post(this.way + '/cxp/trnpagodoc/76eca76778fb9c0', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erProNombre(strParametro) {
    return this.http.post(this.way + '/cxp/trnpagodoc/3b79de2f8b2f6e', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erConTipGas(strParametro) {
    return this.http.post(this.way + '/cxp/trnpagodoc/3b6cc5fb2cc15c', {
      elementos: {
        CON_CODIGO: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erTipGasCodigo() {
    return this.http.post(this.way + '/cxp/trnpagodoc/3b7d9660ae330c', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  erBanBanco(strParametro) {
    return this.http.post(this.way + '/cxp/trnpagodoc/1db5aaeb55d98', {
      elementos: {
        BAN_CODIGO: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erCajDescripcion(strParametro) {
    return this.http.post(this.way + '/cxp/trnpagodoc/edb1536bb8cdc800', {
      elementos: {
        CAJ_CODIGO: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erBanBanco2(strParametro) {
    return this.http.post(this.way + '/cxp/trnpagodoc/3b6b55d6abb302', {
      elementos: {
        BAN_CODIGO: strParametro
      },
    }, this.confIniciales.httpOptions());
  }
  
  erCajDescripcion2(strParametro) {
    return this.http.post(this.way + '/cxp/trnpagodoc/3b6c54daee3362', {
      elementos: {
        CAJ_CODIGO: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erAsiComprobante(strParametro) {
    return this.http.post(this.way + '/cxp/trnpagodoc/76d5c93316cef00', {
      elementos: {
        ASI_NRO: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erConCodigoAct(strParametro) {
    return this.http.post(this.way + '/cxp/trnpagodoc/3b6cc5d986a99c', {
      elementos: {
        ART_CODIGO: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erConCodigo(strParametro) {
    return this.http.post(this.way + '/cxp/trnpagodoc/3b6cc5d986ca18', {
      elementos: {
        SERCOM_CODIGO: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erConCentro(strParametro) {
    return this.http.post(this.way + '/cxp/trnpagodoc/3b6cc5d8ebf778', {
      elementos: {
        CON_CODIGO: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  consultarMaeTipDia(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trnpagodoc/cc5f9653bb2cb800', {
      elementos: {}
    }, this.confIniciales.httpOptions());
  }

  consultarPagoDoc(strNumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trnpagodoc/3317e6550c370c', {
      elementos: {
        TrnCobroNroDoc: strNumero
      }
    }, this.confIniciales.httpOptions());
  }

  consultarCXPTrnPago(strNumero, strProCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trnpagodoc/662fc667bbbe540', {
      elementos: {
        TrnCobroNroDoc: strNumero,
        PRO_CODIGO: strProCodigo
      }
    }, this.confIniciales.httpOptions());
  }

  consultarBantrnban(strAsiNro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trnpagodoc/cc5f8b55fbbbb000', {
      elementos: {
        ASI_NRO: strAsiNro
      }
    }, this.confIniciales.httpOptions());
  }

  consultarDocumentos(strTrnCobroNroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trnpagodoc/662fc6e19eb3b00', {
      elementos: {
        TrnCobroNroDoc: strTrnCobroNroDoc
      }
    }, this.confIniciales.httpOptions());
  }

  consultarAbonos(strTrnCobroNroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trnpagodoc/198bf14bc5f1c', {
      elementos: {
        TRNCOBRO_NRODOC: strTrnCobroNroDoc
      }
    }, this.confIniciales.httpOptions());
  }
  
  consultarVerifTransCash(strDocumento, strProCodigo, strTipo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trnpagodoc/cc5f9f76fbb62800', {
      elementos: {
        Documento: strDocumento,
        ProCodigo: strProCodigo,
        Tipo: strTipo
      }
    }, this.confIniciales.httpOptions());
  }

  consultarDatos(strProCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trnpagodoc/3317e7aad5771c', {
      elementos: {
        PRO_CODIGO: strProCodigo
      }
    }, this.confIniciales.httpOptions());
  }

  consultarSaldoDocumento(strCodigoAnticipo, strCodigoProveedor): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trnpagodoc/3317e2aad6e19e', {
      elementos: {
        CodigoAnticipo: strCodigoAnticipo,
        CodigoProveedor: strCodigoProveedor
      }
    }, this.confIniciales.httpOptions());
  }

  consultarAsientos(strNroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trnpagodoc/cc5f9e5724ebf800', {
      elementos: {
        TRNCOBRO_NRODOC: strNroDoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  consultarDatosDocPagados(strCodigoProveedor, strNumeroFactura): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trnpagodoc/cc5f8d575b866800', {
      elementos: {
        CodigoProveedor: strCodigoProveedor,
        NumeroFactura: strNumeroFactura
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  consultarMovBancosVarios(strNroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trnpagodoc/cc5f96c7d6ac0000', {
      elementos: {
        TRNCOBRO_NRODOC: strNroDoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarDetTrnCobro(strNroDoc, strCobro, strNroSec): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trnpagodoc/14ceb5ddeeeecc0000', {
      elementos: {
        NumeroDoc: strNroDoc,
        TRNCOBRO_NRODOC: strCobro,
        DETTRNCOBRO_NROSEC: strNroSec
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarNotaDebito(strComprobante): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trnpagodoc/3ab2b5f1d6b972', {
      elementos: {
        DETTRNCOBRO_COMPROBANTE: strComprobante
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  seleccionarRegistro(tipo, trncobronrodoc): Observable<any> {
    return this.http.post<any>(this.way + '/cxp/trnpagodoc/71d5dba12e7778', {
      elementos: {
        p_tipo: tipo,
        p_com_codigo: '01',
        p_trncobro_nrodoc: trncobronrodoc,
      }
    }, this.confIniciales.httpOptions());
  }
  
  consultaUltimoCheque(trncobronrodoc): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxp/trnpagodoc/3317e7abd645da', {
      elementos: {
        p_BAN_codigop: trncobronrodoc,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  
  calcularImporteTotal() {
    let intIndiceLista = 0;
    
    let item: CxpTrndocumento;
    let itemVarios: CxpDettrncobro;
    let dblTotalImporte = 0;
    let dblTotalImporteVarios = 0;
    
    // Total de los documentos seleccionados
    for (intIndiceLista = 0; intIndiceLista < this.documentos.length; intIndiceLista++) {
      item = this.documentos[intIndiceLista];
      if(item.SELECCIONADOFPCOM === false){
        item.TRNDOCUMENTO_IMPORTE=Number(0).toFixed(2);
      }
      dblTotalImporte = dblTotalImporte + Number(item.TRNDOCUMENTO_IMPORTE) - Number(item.TRNDOCUMENTO_DESCUENTO);
    }
    
    // Total de varios
    for (intIndiceLista = 0; intIndiceLista < this.varios.length; intIndiceLista++) {
      itemVarios = this.varios[intIndiceLista];
      dblTotalImporteVarios = dblTotalImporteVarios + Number(itemVarios.DEBE) + Number(itemVarios.HABER) * -1;
    }
    
    this.varios.map(aux => {
      aux.DEBE = Number(aux.DEBE).toFixed(2);
      aux.HABER = Number(aux.HABER).toFixed(2);
    });
    
    this.documentos.map(aux => {
      aux.TRNDOCUMENTO_IMPORTE = Number(aux.TRNDOCUMENTO_IMPORTE).toFixed(2);
      aux.TRNDOCUMENTO_DESCUENTO = Number(aux.TRNDOCUMENTO_DESCUENTO).toFixed(2);
    });
    
    const value = Number(dblTotalImporte) + Number(dblTotalImporteVarios);
    this.trncobro.TRNCOBRO_IMPORTE = value.toFixed(2);
    
  }
  
  buscarDetFacPendiente(strCodigoDocumento, strCodCliente): Observable<any> {
    
    let strSentencia = 'select a.com_codigo,a.trncobro_nrodoc,a.trncobro_nrocomprobante, a.trncobro_tipodoc,a.cli_codigo,c.con_nombre,con_codigo1, ';
    strSentencia = strSentencia + ' (select sum(trncobro_importe) from cxc_trnresumen ';
    strSentencia = strSentencia + ' where cli_codigo=a.cli_codigo';
    strSentencia = strSentencia + ' and trncobro_referencia=a.trncobro_nrocomprobante) as saldo,con_centro';
    strSentencia = strSentencia + ' from cxc_trnresumen  a, ven_maecliente b,con_maecon c';
    strSentencia = strSentencia + ' where a.cli_codigo=\'' + strCodCliente + '\'';
    strSentencia = strSentencia + ' and trncobro_nrocomprobante=\'' + strCodigoDocumento + '\'';
    strSentencia = strSentencia + ' and a.cli_codigo=b.cli_codigo and b.con_codigo1=c.con_codigo';
    
    
    return this.http.post<any[]>(this.way + '/cxp/trnpagodoc/bf71aeebd4ccb800', {
      elementos: {
        CodCliente: strCodCliente,
        CodigoDocumento: strCodigoDocumento
      }
    }, this.confIniciales.httpOptions());
  }
  
  buscarAntPendiente(StrCodigoAnticipo): Observable<any> {
    
    return this.http.post<any[]>(this.way + '/cxp/trnpagodoc/2fdc55fb975db2', {
      elementos: {
        CodigoAnticipo: StrCodigoAnticipo
      }
    }, this.confIniciales.httpOptions());
  }

  erConCentroProm(strParametro) {
    return this.http.post(this.way + '/cxp/trnpagodoc/3b6cc5d8ebf778', {
      elementos: {
        CON_CODIGO: strParametro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erConNombreProm(strParametro) {
    return this.http.post(this.way + '/cxp/trnpagodoc/3b6cc5ef8b2f6e', {
      elementos: {
        CON_CODIGO: strParametro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erTrnBanConciliaProm(strTrnBanNumero, strBanCodigo, strBanTipo) {
    return this.http.post(this.way + '/cxp/trnpagodoc/1dbeeeeb55d98c0000', {
      elementos: {
        TRNBAN_NUMERO: strTrnBanNumero,
        BAN_CODIGO: strBanCodigo,
        TRNBAN_TIPO: strBanTipo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erConTipGasProm(strParametro) {
    return this.http.post(this.way + '/cxp/trnpagodoc/3b6cc5fb2cc15c', {
      elementos: {
        CON_CODIGO: strParametro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erConCodigo2Prom(strParametro) {
    return this.http.post(this.way + '/cxp/trnpagodoc/3b6cc5d986ca02', {
      elementos: {
        BAN_CODIGO: strParametro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarLineaVarios(trncobronrodoc, nrosec) {
    return this.http.post<any>(this.way + '/cxp/trnpagodoc/1d595ab2bfd5b', {
      elementos: {
        p_trncobro_nrodoc: trncobronrodoc,
        p_com_codigo: '01',
        p_dettrncobro_nrosec: nrosec
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
}
