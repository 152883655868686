<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="commaeproveedor"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="commaeproveedorConfirmar" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
			[botonBuscar]="btnBuscar" [botonRegresar]="btnRegresar" [botonPrimero]="btnPrimero"
			[botonAnterior]="btnAnterior" [botonSiguiente]="btnSiguiente" [botonUltimo]="btnUltimo"
			[barraBotonesCRUD]="false" [barraBotonesDesplaz]="false" [barraBotones3]="true" [barraBotonesAsiCon]="true"
			[barraBotones5]="false" [botonAlmacenardoc]="true" (signal)="manejarSenales($event)"></app-invmaebarra>
	</div>
</div>

<p-tabView [(activeIndex)]="index" (onChange)="changeTabs()">
	<p-tabPanel header="Generales">
		<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 250px)'}">
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-1">
								<span class="obligatorio">Tipo ID:</span>
							</div>
							<div class="p-col-5">
								<div class="p-grid">
									<div class="p-col-3">
										<p-dropdown [options]="cmbide"
											[style]="{'minWidth': '100%', 'width':'100%', 'height': '24px'}"
											optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectide"
											(onChange)="busqide($event)"></p-dropdown>
									</div>
									<div class="p-col-2">
										<span class="obligatorio">RUC / IDE</span>
									</div>
									<div class="p-col-4">
										<label for="PRO_RUCIDE"></label>
										<input id="PRO_RUCIDE" type="text" pInputText
											[(ngModel)]="proveedorSeleccionado.PRO_RUCIDE"
											(keydown.enter)="setFocus($event)" (focusout)="setFocus($event)"
											(keydown)="botmodclirucide()" autocomplete="off"
											style="text-transform: uppercase;">
									</div>
									<div class="p-col-1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/visto.png);"
											(click)="validar(proveedorSeleccionado.PRO_RUCIDE)"></button>
									</div>
									<div class="p-col-2">
										<span>{{lblvalida}}</span>
									</div>
								</div>
							</div>
							<div class="p-col-1">
								<span class="obligatorio">Código</span>
							</div>
							<div class="p-col-3">
								<label for="PRO_CODIGO"></label>
								<input class="frm" id="PRO_CODIGO" type="text" pInputText
									[(ngModel)]="proveedorSeleccionado.PRO_CODIGO" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off" [disabled]="nuevoproveedor"
									style="text-transform: uppercase;">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -25px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-1">
								<span class="obligatorio">Nombre:</span>
							</div>
							<div class="p-col-5">
								<label for="PRO_NOMBRE"></label>
								<input class="frm" id="PRO_NOMBRE" type="text" pInputText
									[(ngModel)]="proveedorSeleccionado.PRO_NOMBRE" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" (focusout)="setFocus($event)" autocomplete="off">
							</div>
							<div class="p-col-1">
								<span class="obligatorio">Nombre Corto:</span>
							</div>
							<div class="p-col-3">
								<label for="PRO_NOMBREC"></label>
								<input class="frm" id="PRO_NOMBREC" type="text" pInputText maxlength="12"
									[(ngModel)]="proveedorSeleccionado.PRO_NOMBREC" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-1">
								<span>Contacto:</span>
							</div>
							<div class="p-col-5">
								<label for="PRO_CONTACTO"></label>
								<input class="frm" id="PRO_CONTACTO" type="text" pInputText
									[(ngModel)]="proveedorSeleccionado.PRO_CONTACTO" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>

							<div class="p-col-1">
								<span>Fecha Ingreso</span>
							</div>

							<div class="p-col-3">
								<p-calendar id="PRO_FECING" [inputStyle]="{width: '80%', height: '20px'}"
									[(ngModel)]="strFecha" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()">
								</p-calendar>
							</div>
							<div class="p-col-1"></div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px; margin-bottom: -20px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-1">
								<span class="obligatorio">Grupo:</span>
							</div>
							<div class="p-col-1">
								<label for="GRU_CODIGO"></label>
								<input class="frm" id="GRU_CODIGO" type="text" pInputText
									[(ngModel)]="proveedorSeleccionado.GRU_CODIGO" (keydown.enter)="busquedagrupo()"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-1">
								<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="busquedagrupo()"></button>
							</div>
							<div class="p-col-5">
								<span id="GRU_NOMBRE">{{grupnombre}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-1">
								<span class="obligatorio">Dirección 1:</span>
							</div>
							<div class="p-col-7">
								<label for="PRO_DIRECCION1"></label>
								<input class="frm" id="PRO_DIRECCION1" type="text" pInputText
									[(ngModel)]="proveedorSeleccionado.PRO_DIRECCION1"
									(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
									autocomplete="off">
							</div>
							<div class="p-col-3"></div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-1">
								<span>Dirección 2:</span>
							</div>
							<div class="p-col-7">
								<label for="PRO_DIRECCION2"></label>
								<input class="frm" id="PRO_DIRECCION2" type="text" pInputText
									[(ngModel)]="proveedorSeleccionado.PRO_DIRECCION2"
									(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
									autocomplete="off">
							</div>
							<div class="p-col-3"></div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-1">
								<span class="obligatorio">Teléfono 1:</span>
							</div>
							<div class="p-col-2">
								<label for="PRO_TELEFONO1"></label>
								<input class="frm" id="PRO_TELEFONO1" type="text" pInputText
									[(ngModel)]="proveedorSeleccionado.PRO_TELEFONO1" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>Teléfono 2:</span>
							</div>
							<div class="p-col-2">
								<label for="PRO_TELEFONO2"></label>
								<input class="frm" id="PRO_TELEFONO2" type="text" pInputText
									[(ngModel)]="proveedorSeleccionado.PRO_TELEFONO2" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-3"></div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-1">
								<span>Fax</span>
							</div>
							<div class="p-col-2">
								<input class="frm" id="PRO_FAX" type="text" pInputText
									[(ngModel)]="proveedorSeleccionado.PRO_FAX" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>Cód. Postal:</span>
							</div>
							<div class="p-col-2">
								<input class="frm" id="PRO_CODPOSTAL" type="text" pInputText
									[(ngModel)]="proveedorSeleccionado.PRO_CODPOSTAL" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-3"></div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-1">
								<span class="obligatorio">Email 1:</span>
							</div>
							<div class="p-col-4">
								<label for="PRO_CORREO"></label>
								<input class="frm" id="PRO_CORREO" type="text" pInputText
									[(ngModel)]="proveedorSeleccionado.PRO_CORREO" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-5"></div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-1">
								<span>Email 2:</span>
							</div>
							<div class="p-col-4">
								<label for="PRO_CORREO2"></label>
								<input class="frm" id="PRO_CORREO2" type="text" pInputText
									[(ngModel)]="proveedorSeleccionado.PRO_CORREO2" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-5"></div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px; margin-bottom: -20px">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-1">
								<span>Zona:</span>
							</div>
							<div class="p-col-1">
								<label for="PRO_ZONA"></label>
								<input class="frm" id="PRO_ZONA" type="text" pInputText
									[(ngModel)]="proveedorSeleccionado.PRO_ZONA" (keydown.enter)="busquedazona()"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-1">
								<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="busquedazona()"></button>
							</div>
							<div class="p-col-1">
								<span id="ZONA">{{zonanombre}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</p-scrollPanel>
	</p-tabPanel>
	<p-tabPanel header="Administrativos">

		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-12">
					<div class="p-grid">
						<div class="txtAlign" class="p-col-2">
							<label for="PRO_CODIGO"></label>
							<input id="PRO_CODIGOA" type="text" pInputText
								[(ngModel)]="proveedorSeleccionado.PRO_CODIGO" autocomplete="off"
								[disabled]="nuevoproveedor">
						</div>

						<div class="p-col-6">
							<label for="PRO_NOMBRE"></label>
							<input class="label" class="frm" id="PRO_NOMBREA" type="text" pInputText
								[(ngModel)]="proveedorSeleccionado.PRO_NOMBRE" autocomplete="off" [disabled]="true">
						</div>

						<div class="p-col-2"></div>
						<div class="p-col-2"></div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-9">
							<div class="p-grid">
								<div class="p-col-2">
									<span class="txtAlign">Contribuyente:</span>
								</div>
								<div class="p-col-2">
									<p-dropdown [options]="cmbcontri" [style]="{'minWidth': '100%', 'width':'100%'}"
										styleClass="txtAlign" optionLabel="name" [(ngModel)]="selectcontri"
										(onChange)="busqcontri($event)"></p-dropdown>
								</div>
								<div class="p-col-2">
									<span>Tipo de proveedor:</span>
								</div>
								<div class="p-col-2">
									<input id="PRO_TIPO" type="text" pInputText
										[(ngModel)]="proveedorSeleccionado.PRO_TIPOA"
										(keydown.enter)="busquedatipopcodigo()" (keydown)="botonesmodificar()"
										autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="busquedatipopcodigo()"></button>

								</div>
								<div class="p-col-3">
									<div class="p-grid">
										<div class="p-col-8">
											<span>Parte relacionada:</span>
										</div>
										<div class="p-col-4">
											<p-dropdown [options]="cmbparteR"
												[style]="{'minWidth': '100%', 'width':'100%'}" optionLabel="name"
												[panelStyle]="{'width':'100%'}" [(ngModel)]="selectparter"
												(onChange)="busqparte($event)"></p-dropdown>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="p-col-1">
							<span>Denominación:</span>
						</div>
						<div class="p-col-1">
							<p-dropdown [options]="cmbdeno" [style]="{'minWidth': '100%', 'width':'110%'}"
								optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectdeno"
								(onChange)="busqdeno($event)" [disabled]="true"></p-dropdown>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -25px">
					<div class="p-grid">
						<div class="p-col-9">
							<div class="p-grid">
								<div class="p-col-2">
									<span class="txtAlign">Descuento:</span>
								</div>
								<div class="p-col-2">
									<input id="PRO_DESCUENTO" type="text" pInputText [style]="{'width':'80'}"
										[(ngModel)]="proveedorSeleccionado.PRO_DESCUENTO"
										(keydown.enter)="setFocus($event)" autocomplete="off"
										(keydown)="botonesmodificar()">
								</div>
								<div class="p-col-2">
									<span>Límite de crédito:</span>
								</div>
								<div class="p-col-1">
									<input id="PRO_LIMCREDIT" type="text" pInputText
										[(ngModel)]="proveedorSeleccionado.PRO_LIMCREDIT"
										(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()">
								</div>
							</div>
						</div>
						<div class="p-col-1">
							<span class="txtAlign">Días crédito:</span>
						</div>
						<div class="p-col-1">
							<input class="txtAlign" id="PRO_DIACREDIT" type="text" pInputText
								[(ngModel)]="proveedorSeleccionado.PRO_DIACREDIT" (keydown.enter)="setFocus($event)"
								autocomplete="off" (keydown)="botonesmodificar()">
						</div>
					</div>
				</div>
				<div class="p-col-9">
					<div class="p-grid">
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-12">
									<span> Referencias Contables:</span>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -10px">
							<div class="p-grid">
								<div class="p-col-2">
									<span> Cuentas por pagar:</span>
								</div>
								<div class="p-col-2">
									<input class="frm2" id="CON_CODIGO1" type="text" pInputText
										[(ngModel)]="proveedorSeleccionado.CON_CODIGO1"
										(keydown.enter)="busquedaconcodigo('1')" (keydown)="botonesmodificar()"
										autocomplete="off">

								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="busquedaconcodigo('1')"></button>
								</div>
								<div class="p-col-7">
									<label class="label">{{connombre}}&nbsp;&nbsp;</label>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -17px">
							<div class="p-grid">
								<div class="p-col-2">
									<span class="txtAlign">Anticipos:</span>
								</div>
								<div class="p-col-2">
									<input class="frm2" id="CON_CODIGO2" type="text" pInputText
										[(ngModel)]="proveedorSeleccionado.CON_CODIGO2"
										(keydown.enter)="busquedaconcodigo('2')" (keydown)="botonesmodificar()"
										autocomplete="off">

								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="busquedaconcodigo('2')"></button>
								</div>
								<div class="p-col-7">
									<label class="label">{{con2nombre}}</label>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -18px">
							<div class="p-grid">
								<div class="p-col-2">
									<span class="txtAlign">Centro de Costos:</span>
								</div>
								<div class="p-col-2">
									<input class="frm2" id="CEN_CODIGO" type="text" pInputText
										[(ngModel)]="proveedorSeleccionado.CEN_CODIGO"
										(keydown.enter)="busquedacencodigo()" (keydown)="botonesmodificar()"
										autocomplete="off">

								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="busquedacencodigo()"></button>
								</div>
								<div class="p-col-7">

									<label class="label">{{cennombre}}</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="p-col-3">
					<div class="p-grid">
						<div class="p-col-12">
							<p-checkbox class="txtAlign" id="chkfacelectro" name="chkfacelectro" value="" label=""
								binary="{{chkfacelectro}}" [(ngModel)]="chkfacelectro" (click)="botonesmodificar()"
								(keydown.enter)="setFocus($event)"></p-checkbox>
							&nbsp;<span class="txtAlign">&nbsp;Facturación Electrónica &nbsp;&nbsp;</span>
						</div>
						<div class="p-col-12">
							<p-checkbox class="txtAlign" id="chktransporte" name="chktransporte" value="" label=""
								binary="{{chktransporte}}" [(ngModel)]="chktransporte" (click)="botonesmodificar()"
								(keydown.enter)="setFocus($event)"></p-checkbox>
							<span class="txtAlign">&nbsp;Empresa de transporte con socios</span>
						</div>
						<div class="p-col-12"hidden="true">
							<p-checkbox class="txtAlign" id="chkconregmic" name="chkconregmic" value="" label=""
								binary="{{chkconregmic}}" [(ngModel)]="chkconregmic" (click)="botonesmodificar()"
								(keydown.enter)="setFocus($event)"></p-checkbox>
							<span class="txtAlign">&nbsp;Contribuyente régimen Microempresas</span>
						</div>
						<div class="p-col-12" >
							<p-checkbox class="txtAlign" id="chkconregrimpe" name="chkconregrimpe" value="" label=""
								binary="{{chkconregrimpe}}" [(ngModel)]="chkconregrimpe" (click)="botonesmodificar()"
								(keydown.enter)="setFocus($event)"></p-checkbox>
							<span class="txtAlign">&nbsp;Régimen RIMPE </span>
						</div>
						<div class="p-col-12">
							<p-checkbox class="txtAlign" id="chkagenteret" name="chkagenteret" value="" label=""
								binary="{{chkagenteret}}" [(ngModel)]="chkagenteret" (click)="botonesmodificar()"
								(keydown.enter)="setFocus($event)"></p-checkbox>
							<span class="txtAlign">&nbsp;Agente de retención</span>
						</div>
					</div>
				</div>
				<div class="p-col-9" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-2">
							<span class="txtAlign">Número de Serie:</span>
						</div>
						<div class="p-col-2">
							<input id="PRO_NUMSERIE" type="text" maxLength="6" pInputText
								[(ngModel)]="proveedorSeleccionado.PRO_NUMSERIE" (keydown.enter)="setFocus($event)"
								autocomplete="off" (keydown)="botonesmodificar()">
						</div>
						<div class="p-col-2">
							<span>Número de Autorización:</span>
						</div>
						<div class="p-col-6">
							<input class="frm" id="PRO_NUMAUTORIZACION" type="text" pInputText
								[(ngModel)]="proveedorSeleccionado.PRO_NUMAUTORIZACION" (keydown)="botonesmodificar()"
								(keydown.enter)="setFocus($event)" autocomplete="off">
						</div>
					</div>
				</div>
				<div class="p-col-9" style="margin-top: -15px; margin-bottom: -15px;">
					<div class="p-grid">
						<div class="p-col-2">
							<span class="txtAlign">Observaciones:</span>
						</div>
						<div class="p-col-10">
							<input class="frm" id="PRO_OBSERVACION" type="text" pInputText
								[(ngModel)]="proveedorSeleccionado.PRO_OBSERVACION" (keydown)="botonesmodificar()"
								autocomplete="off">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="p-grid">
			<div class="p-col-2">
				<span class="txtAlign">Autorizaciones SRI:</span>

			</div>
			<div class="p-col-1"></div>
			<div class="p-col-2"></div>
			<div class="p-col-1"></div>
			<div class="p-col-2">
				<!-- <p-checkbox id="chkexcluye"
							 name="chkexcluye" value="" label="" binary="{{chkexcluye}}"
							 [(ngModel)]="chkexcluye" (click)="botonesmodificar()"
							 (keydown.enter)="setFocus($event)"></p-checkbox>
				 <span> Todos Documentos &nbsp;&nbsp;&nbsp;&nbsp; </span> -->
			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largo1" [enableSorting]="true" [enableFilter]="true"
			[rowData]="proveedorServicio.commaeproveedorADM" [frameworkComponents]="frameworkComponents"
			[animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefsProv"
			[defaultColDef]="defaultProv" [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true"
			[mostrarGuardar]="false" [botonesAdicionales]="false" (selected)="senialestablaADM($event)"
			(cambios)="cambio($event)"></app-aggridsaci>

	</p-tabPanel>

	<p-tabPanel header="Estado de Cuenta">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Proveedor:</span>
				</div>

				<div class="p-col-6">
					<input class="label" class="frm" id="PRO_CODNOMBRE" type="text" pInputText [(ngModel)]="codnombre"
						autocomplete="off" [disabled]="true">
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-2"></div>


			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
			[rowData]="cxctmptrnresumen" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsCxctmptrnresumen" [defaultColDef]="defaultProvEC"
			[rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="false" [mostrarGuardar]="false"
			[botonesAdicionales]="false"></app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Saldos">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Proveedor:</span>
				</div>

				<div class="p-col-6">
					<input class="label" class="frm" id="PRO_CODNOMBR1" type="text" pInputText [(ngModel)]="codnombre"
						autocomplete="off" [disabled]="true">
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-2"></div>


			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
			[rowData]="cxctrnresumen" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsCxctrnresumen" [defaultColDef]="defaultProvS"
			[rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="false" [mostrarGuardar]="false"
			[botonesAdicionales]="false"></app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Cash Management" [disabled]="flagCashM">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-2">
							<span class="obligatorio">Forma de pago:</span>
						</div>
						<div class="p-col-2">
							<p-dropdown [options]="cmbformap" [style]="{'minWidth': '100%', 'width':'100%'}"
								optionLabel="name" [panelStyle]="{'width':'110%'}" (onClick)="obtenerFormap()"
								(onChange)="busqformap($event)"></p-dropdown>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-2">
							<span class="obligatorio">Entidad Financiera:</span>
						</div>
						<div class="p-col-2">
							<p-dropdown [options]="cmbentiF" [style]="{'minWidth': '100%', 'width':'100%'}"
								optionLabel="name" [panelStyle]="{'width':'110%'}" (onClick)="obtenerEntidadF()"
								(onChange)="busqentiF($event)" [disabled]="flagFormadePago"></p-dropdown>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-2">
							<span class="obligatorio">Tipo de Cuenta:</span>
						</div>
						<div class="p-col-2">
							<p-dropdown [options]="cmbtipoC" [style]="{'minWidth': '100%', 'width':'100%'}"
								optionLabel="name" [panelStyle]="{'width':'110%'}" (onClick)="obtenerTiposC()"
								(onChange)="busqetipoC($event)" [disabled]="flagFormadePago"></p-dropdown>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-2">
							<span class="obligatorio">N de cuenta:</span>
						</div>
						<div class="p-col-2">
							<input class="frm" id="COM_NUMCTACM" type="text" pInputText
								[(ngModel)]="proveedorSeleccionado.COM_NUMCTACM" (keydown.enter)="setFocus($event)"
								(keydown)="botonesmodificar()" (focusout)="setFocus($event)"
								[disabled]="flagFormadePago">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-2">
							<span class="obligatorio">Beneficiario:</span>
						</div>
						<div class="p-col-2">
							<input class="frm" id="COM_BENFCM" type="text" pInputText [(ngModel)]="COM_BENFCMAUX"
								(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
								(focusout)="setFocus($event)">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-2">
							<span class="obligatorio">Tipo de Identificación:</span>
						</div>
						<div class="p-col-2">
							<p-dropdown [options]="cmbidecash"
								[style]="{'minWidth': '100%', 'width':'100%', 'heigth':'20px'}" optionLabel="name"
								[panelStyle]="{'width':'110%'}" [(ngModel)]="selectideticash"
								(onChange)="busqideCM($event)"></p-dropdown>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-2">
							<span class="obligatorio">Id.identificación:</span>
						</div>
						<div class="p-col-2">
							<input class="frm" id="PRO_IDCM" type="text" pInputText [(ngModel)]="PRO_IDCMAUX"
								autocomplete="off">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-2">
							<span class="obligatorio">Dirección:</span>
						</div>
						<div class="p-col-2">
							<input class="frm" id="COM_DIFBENFCM" type="text" pInputText [(ngModel)]="COM_DIFBENFCMAUX"
								(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
								(focusout)="setFocus($event)">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-2">
							<span class="obligatorio">Ciudad:</span>
						</div>
						<div class="p-col-2">
							<input class="frm" id="PRO_CIUDADCM" type="text" pInputText
								[(ngModel)]="proveedorSeleccionado.PRO_CIUDADCM" (keydown.enter)="setFocus($event)"
								(keydown)="botonesmodificar()" (focusout)="setFocus($event)">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-2">
							<span class="obligatorio">Télefono(Celular):</span>
						</div>
						<div class="p-col-2">
							<input class="frm" id="COM_TELFBENFCM" type="text" pInputText
								[(ngModel)]="proveedorSeleccionado.COM_TELFBENFCM" (keydown.enter)="setFocus($event)"
								(keydown)="botonesmodificar()" (focusout)="setFocus($event)">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-2">
							<span class="obligatorio">Localidad de pago:</span>
						</div>
						<div class="p-col-2">
							<input class="frm" id="PRO_LOCPAGCM" type="text" pInputText
								[(ngModel)]="proveedorSeleccionado.PRO_LOCPAGCM" (keydown.enter)="setFocus($event)"
								(keydown)="botonesmodificar()" (focusout)="setFocus($event)">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-2">
							<span class="obligatorio">Email:</span>
						</div>
						<div class="p-col-2">
							<input class="frm" id="PRO_EMAILCM" type="text" pInputText
								[(ngModel)]="proveedorSeleccionado.PRO_EMAILCM" (keydown.enter)="setFocus($event)"
								(keydown)="botonesmodificar()" autocomplete="off">
						</div>
					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
	<!--	<p-tabPanel header="Movimientos">-->
	<!--		<div class="divgrups">-->
	<!--			<div class="p-grid">-->
	<!--				<div class="p-col-2">-->
	<!--					<span>Proveedores:</span>-->
	<!--				</div>-->

	<!--				<div class="p-col-4">-->
	<!--					<input class="label" class="frm" id="PRO_CODNOMRE1" type="text" pInputText-->
	<!--						   [(ngModel)]="codnombre"-->
	<!--						   autocomplete="off" [disabled]="true">-->
	<!--				</div>-->
	<!--				<button type="button"-->
	<!--						style="background-image: url(../../../assets/images/iconos/flash.png);"-->
	<!--						(click)="llenartablaMOV()"></button>-->
	<!--				<div class="p-col-2"></div>-->
	<!--				<div class="p-col-2"></div>-->
	<!--				&lt;!&ndash;-->
	<!--				<div class="p-col-2">-->
	<!--					<span>Desde:</span>-->
	<!--				</div>-->
	<!--				<div class="p-col-2">-->
	<!--					<p-calendar id="PRO_FECDESDE" [inputStyle]="{width: '80%', height: '20px'}"-->
	<!--								[(ngModel)]="FECHA_DESDE"-->
	<!--								dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"-->
	<!--								(keydown)="botonesmodificar()">-->
	<!--					</p-calendar>-->
	<!--				</div>-->
	<!--				<div class="p-col-2">-->
	<!--					<span>Hasta:</span>-->
	<!--				</div>-->
	<!--				<div class="p-col-2">-->
	<!--					<p-calendar id="PRO_FECHASTA" [inputStyle]="{width: '80%', height: '20px'}"-->
	<!--								[(ngModel)]="FECHA_HASTA"-->
	<!--								dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"-->
	<!--					>-->
	<!--					</p-calendar>-->
	<!--				</div>-->
	<!--				<div class="p-col-2">-->
	<!--					<button type="button"-->
	<!--							style="background-image: url(../../../assets/images/iconos/buscar.png);"-->
	<!--							(click)="llenartablamovF()"-->
	<!--					></button>-->

	<!--				</div>-->
	<!--&ndash;&gt;-->
	<!--			</div>-->
	<!--		</div>-->
	<!--		<app-aggridsaci [width]=""-->
	<!--						[height]="largo"-->
	<!--						[enableSorting]="true"-->
	<!--						[enableFilter]="true"-->
	<!--						[rowData]="proveedorServicio.commaeproveedorMov"-->
	<!--						[frameworkComponents]="frameworkComponents"-->
	<!--						[animateRows]="true"-->
	<!--						[rowSelection]="rowSelection"-->
	<!--						[columnDefs]="columnDefsMovimiento"-->
	<!--						[defaultColDef]="defaultProvMv"-->
	<!--						[rowStyle]="rowStyle"-->
	<!--						[singleClickEdit]="false"-->
	<!--						[botones]="false"-->
	<!--						[mostrarGuardar]="false"-->
	<!--						[botonesAdicionales]="false"-->
	<!--		></app-aggridsaci>-->


	<!--	</p-tabPanel>-->
	<p-tabPanel header="Estadísticas" [disabled]="disabledEstadisticas">
		<div class="p-grid">
			<!--LINEA 1-->
			<div class="p-col-6">
				<div class="p-grid">
					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-4">
								<button pButton (click)="mostrarEstadisticas()"
									style="background-image: url(../../../assets/images/iconos/cogwheel.png); width: 40px; height: 40px; background-color: transparent;"
									title="Generar estadísticas">
								</button>
							</div>
							<div class="p-col-4">
								<span style="font-size: 16px; font-weight: bold; text-align: center">COMPRAS POR
									MESES</span>
							</div>
							<div class="p-col-4">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 20px;">
						<p-chart [options]="options" type="bar" [data]="dataComprasProveedor" height="415px"></p-chart>
					</div>
				</div>
			</div>
			<div class="p-col-6">
				<div class="p-grid">
					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-3">
							</div>
							<div class="p-col-6">
								<span style="font-size: 16px; font-weight: bold;">
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									PRODUCTOS MÁS COMPRADOS</span>
							</div>
							<div class="p-col-3">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 10px;">
						<div class="p-grid">
							<div class="p-col-3">
							</div>
							<div class="p-col-2">
								<span>Ordenar por:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="cantidad" (onClick)="orderByTopProd('cantidad')"
									inputId="orden1"></p-radioButton>
								<label> Cantidad </label>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="precio" (onClick)="orderByTopProd('total')"
									inputId="orden2"></p-radioButton>
								<label> Total </label>
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px;">
						<app-aggridsaci [width]="" [height]="'390'" [enableSorting]="true" [enableFilter]="true"
							[rowData]="detTopProductos" [frameworkComponents]="frameworkComponents" [animateRows]="true"
							[rowSelection]="rowSelection" [columnDefs]="columnDefsTopProductos"
							[defaultColDef]="defaultColTopProductos" [rowStyle]="rowStyleTopProd" [botones]="false"
							[singleClickEdit]="false">
						</app-aggridsaci>
					</div>
				</div>
			</div>
			<div class="p-col-12" style="margin-top: 20px;">
				<div class="p-grid">
					<div class="p-col-5">
					</div>
					<div class="p-col-2">
						<span style="font-size: 16px; font-weight: bold;">
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							FORMULARIO</span>
					</div>
					<div class="p-col-5">
					</div>

					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-1">
								<span>Artículo:</span>
							</div>

							<div class="p-col-2" style="text-align: left">
								<div class="p-grid">
									<div class="p-col-8">
										<input id="ART_CODIGO" type="text" pInputText autocomplete="off"
											[(ngModel)]="txtArtCodigo">
									</div>
									<div class="p-col-4">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="buscarArticulo()"></button>
									</div>
								</div>
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-1">
								<span>Unidad:</span>
							</div>
							<div class="p-col-1">
								<input id="ART_UNIDAD" type="text" pInputText disabled [(ngModel)]="txtArtUnidad"
									autocomplete="off">
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-7">
										<span>Existencia Máxima:</span>
									</div>
									<div class="p-col-5">
										<input id="ART_EXISTMAX" type="text" pInputText disabled autocomplete="off"
											[(ngModel)]="txtExistenciaMax">
									</div>
								</div>

							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -5px;">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-1">
								<span>Descripción:</span>
							</div>

							<div class="p-col-2" style="text-align: left">
								<input id="ART_DESCRIPCION" type="text" pInputText disabled autocomplete="off"
									[(ngModel)]="txtArtDescripcion">
							</div>

							<div class="p-col-2">
								<!--								<span>Rotación:</span>-->
							</div>
							<div class="p-col-1">
								<!--								<input id="ART_ROTACION" type="text" pInputText disabled [(ngModel)]="txtArtRotacion"-->
								<!--									   autocomplete="off">-->
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-7">
										<span>Existencia Mínima:</span>
									</div>
									<div class="p-col-5">
										<input id="ART_EXISTMIN" type="text" pInputText disabled
											[(ngModel)]="txtExistenciaMin" autocomplete="off">
									</div>
								</div>

							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 20px;">
						<p-table [value]="detalleExistencia">
							<ng-template pTemplate="header">
								<tr>
									<th>Existencia</th>
									<th>Ordenes de Compra</th>
									<th>Pedidos Clientes</th>
									<th>Saldo</th>
									<th>Existencia Mínima</th>
									<th>Comprar</th>
									<th>Últ. Costo Compra</th>
									<th>Total Compra</th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-existencia>
								<tr>
									<td style="text-align: right">{{existencia.EXISTENCIA}}</td>
									<td style="text-align: right">{{existencia.ORDENES_COMPRA}}</td>
									<td style="text-align: right">{{existencia.PEDIDOS_CLIENTES}}</td>
									<td style="text-align: right">{{existencia.SALDO}}</td>
									<td style="text-align: right">{{existencia.EXIST_MIN}}</td>
									<td style="text-align: right">{{existencia.COMPRAR}}</td>
									<td style="text-align: right">{{existencia.ULTCOSTO_COM}}</td>
									<td style="text-align: right">{{existencia.TOTAL_COMPRA}}</td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</div>
				<p-tabView>
					<p-tabPanel header="Movimientos">
						<div class="p-col-12" style="margin-top: 10px;">
							<div class="p-grid">
								<div class="p-col-3">
								</div>
								<div class="p-col-2">
									<span>Ordenar por:</span>
								</div>
								<div class="p-col-2" style="text-align: left">
									<p-radioButton name="orden" value="fecha" (onClick)="llenarMovByProvArt('fecha')"
										inputId="orden1"></p-radioButton>
									<label> Fecha </label>
								</div>
								<div class="p-col-2" style="text-align: left">
									<p-radioButton name="orden" value="costo" (onClick)="llenarMovByProvArt('costo')"
										inputId="orden2"></p-radioButton>
									<label> Costo </label>
								</div>
							</div>
						</div>
						<app-aggridsaci [width]="" [height]="'450'" [enableSorting]="true" [enableFilter]="true"
							[rowData]="detMovimientosProv" [frameworkComponents]="frameworkComponents"
							[animateRows]="true" [rowSelection]="rowSelection"
							[columnDefs]="columnDefsMovimientosByProv" [defaultColDef]="defaultColMovByProv"
							[rowStyle]="rowStyle" [botones]="false" [singleClickEdit]="false">
						</app-aggridsaci>


					</p-tabPanel>
					<p-tabPanel header="Existencia por Bodega">
						<app-aggridsaci [width]="" [height]="'450'" [enableSorting]="true" [enableFilter]="true"
							[rowData]="detExistenciaBodegas" [frameworkComponents]="frameworkComponents"
							[animateRows]="true" [rowSelection]="rowSelection"
							[columnDefs]="columnDefsExistenciaBodegas" [defaultColDef]="defaultColExistBod"
							[rowStyle]="rowStyle" [botones]="false" [singleClickEdit]="false">
						</app-aggridsaci>
					</p-tabPanel>
					<p-tabPanel header="Ventas vs Compras">
						<div class="p-grid">
							<!--LINEA 1-->
							<div class="p-col-6">
								<div class="p-grid">
									<div class="p-col-12">
										<p-chart type="bar" [data]="dataVentasVsCompras" height="415px"></p-chart>
									</div>
								</div>
							</div>
							<div class="p-col-6">
								<div class="p-grid">
									<div class="p-col-4">
									</div>
									<div class="p-col-4"><span style="font-size: 16px; font-weight: bold;">
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											Índice de Provisión</span>
									</div>
									<div class="p-col-4">
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Inventario inicial:</span>
											</div>
											<div class="p-col-2">
												<input id="INVENTARIO_INICIAL" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtInventarioIni" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>(+) Compras del periodo:</span>
											</div>
											<div class="p-col-2">
												<input id="COMPRAS_PERIODO" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtComprasPeriodo" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Total disponible:</span>
											</div>
											<div class="p-col-2">
												<input id="TOTAL_DISPONIBLE" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtTotalDisponible"
													style="text-align: right; background-color: yellow">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Ventas del último periodo:</span>
											</div>
											<div class="p-col-2">
												<input id="VENTA_ULTIMOPER" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtVenUltimoPer" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Ventas promedio 10 periodos anteriores:</span>
											</div>
											<div class="p-col-2">
												<input id="VENTASPROM_PERANT" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtVenPromPerAnt" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Índice de provisión último periodo:</span>
											</div>
											<div class="p-col-2">
												<input id="INDPROV_ULTPER" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtIndiceProvUltPer"
													style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Índice de provisión 10 últimos periodos:</span>
											</div>
											<div class="p-col-2">
												<input id="INDPROV" type="text" pInputText autocomplete="off" readonly
													[(ngModel)]="txtIndiceProvPeriodos" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Compras planificadas:</span>
											</div>
											<div class="p-col-2">
												<input id="COMPRAS_PLANIFICADAS" type="number" pInputText
													autocomplete="off" [(ngModel)]="txtComprasPlanificadas"
													style="text-align: right">
											</div>
											<div class="p-col-2">
												<button pButton (click)="calcularIndice()"
													style="background-image: url(../../../assets/images/iconos/cogwheel.png);background-color: transparent;"
													title="Calcular Índice">
												</button>
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Total disponible:</span>
											</div>
											<div class="p-col-2">
												<input id="totalDisponible" type="text" pInputText readonly
													autocomplete="off" [(ngModel)]="txtTotalDisponibleCom"
													style="text-align: right; background-color: yellow">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Índice de provisión último periodo:</span>
											</div>
											<div class="p-col-2">
												<input id="INDPROV_ULT" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtIndiceProvUltPer2"
													style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Índice de provisión 10 últimos periodos:</span>
											</div>
											<div class="p-col-2">
												<input id="INDPRO" type="text" pInputText autocomplete="off" readonly
													[(ngModel)]="txtIndiceProvPeriodos2" style="text-align: right">
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</p-tabPanel>
				</p-tabView>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Retenciones">
		<div class="p-col-7">
			<app-aggridsaci [width]="" [height]="500" [enableSorting]="true" [enableFilter]="true"
				[rowData]="proveedorServicio.commaeproveedorRt" [frameworkComponents]="frameworkComponents"
				[animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefsRetencion"
				[defaultColDef]="defaultProvRt" [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="false"
				[mostrarGuardar]="false" [botonesAdicionales]="false" (guardarEnter)="guardarEnters($event)"
				(selected)="manejarSenales($event)"></app-aggridsaci>
		</div>
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Cuenta:</span>
				</div>

				<div class="p-col-4">
					<input class="label" class="frm" id="CUENTA" type="text" pInputText
						[(ngModel)]="proveedorSeleccionado.CON_CODIGO_RET332" autocomplete="off"
						(keydown)="botonesmodificar()">
				</div>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Anexos">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-5">
							<span class="txtAlign">Pago local o al exterior:</span>
						</div>
						<div class="p-col-1">
							<input class="frm" id="PAGOLOCALEXT" type="text" pInputText
								[(ngModel)]="proveedorSeleccionado.PRO_PAGOLOCALEXT"
								(keydown.enter)="busquedapagoLocaloExterior()" [disabled]="false">
						</div>
						<div class="p-col-1">
							<button type="button"
								style="background-image: url(../../../assets/images/iconos/buscar.png);"
								(click)="busquedapagoLocaloExterior()"></button>
						</div>
						<div class="p-col-3">
							<input class="frm" id="conlnombre" type="text" pInputText [(ngModel)]="conlnombre"
								autocomplete="off" [disabled]="true">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-5">
							<span class="txtAlign">Tipo de régimen fiscal del exterior:</span>
						</div>
						<div class="p-col-5">
							<p-dropdown id="cmbTipoRegFiscal" [options]="cmbTipoRegFiscal"
								[style]="{'minWidth': '100%', 'width':'100%', 'height':'100%'}" optionLabel="name"
								[panelStyle]="{'width':'110%'}" [(ngModel)]="selecttiporegfis"
								(onChange)="busqTipoRegFiscal($event)"></p-dropdown>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-5">
							<span class="txtAlign">País al que se realiza el pago en régimen general:</span>
						</div>
						<div class="p-col-1">
							<input class="frm" id="conpaiscod" type="text" pInputText [(ngModel)]="conpaiscod"
								(keydown.enter)="busquedapagoPais()" autocomplete="off" [disabled]="regpais">
						</div>
						<div class="p-col-1">
							<button type="button"
								style="background-image: url(../../../assets/images/iconos/buscar.png);"
								(click)="busquedapagoPais()" [disabled]="regpais"></button>
						</div>
						<div class="p-col-3">
							<input class="label" class="frm" id=conpaisnombre type="text" pInputText
								[(ngModel)]="conpaisnombre" autocomplete="off" [disabled]="true">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-5">
							<span class="txtAlign">País al que se realiza el pago paraíso físcal:</span>
						</div>
						<div class="p-col-1">
							<input class="label" class="frm" id="conparaisocod" type="text" pInputText
								[(ngModel)]="conparaisocod" autocomplete="off" [disabled]="paraisofiscal"
								(keydown.enter)="busquedapagoParaiso()">
						</div>
						<div class="p-col-1">
							<button type="button"
								style="background-image: url(../../../assets/images/iconos/buscar.png);"
								(click)="busquedapagoParaiso()" [disabled]="paraisofiscal"></button>
						</div>
						<div class="p-col-3">
							<input class="label" class="frm" id="conparaisonombre" type="text" pInputText
								[(ngModel)]="conparaisonombre" autocomplete="off" [disabled]="true">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-5">
							<span class="txtAlign"> Denominación del régimen fiscal preferente o juridicción de menor
								imposición:</span>
						</div>
						<div class="p-col-5">
							<input class="label" class="frm" id="PRO_DENOMINACION" type="text" pInputText
								[(ngModel)]="proveedorSeleccionado.PRO_DENOMINACION" autocomplete="off"
								[disabled]="denominacion">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-5">
							<span class="txtAlign">País al que se efectúa el pago:</span>
						</div>
						<div class="p-col-1">
							<input class="label" class="frm" id="conepaiscod" type="text" pInputText
								[(ngModel)]="conepaiscod" autocomplete="off" [disabled]="denominacion"
								(keydown.enter)="busquedapagoEfectuadoPais()">
						</div>
						<div class="p-col-1">
							<button type="button"
								style="background-image: url(../../../assets/images/iconos/buscar.png);"
								(click)="busquedapagoEfectuadoPais()" [disabled]="denominacion"></button>
						</div>
						<div class="p-col-3">
							<input class="label" class="frm" id="conepaisnombre" type="text" pInputText
								[(ngModel)]="conepaisnombre" autocomplete="off" [disabled]="true">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-5">
							<span class="txtAlign">Aplica convenio de doble tributación en el pago:</span>
						</div>
						<div class="p-col-1">
							<!-- <p-dropdown [options]="cmbanex1" [style]="{'minWidth': '100%', 'width':'100%'}"
								optionLabel="name" [panelStyle]="{'width':'110%'}" (onClick)="llenarcomboanex1()"
								(onChange)="busqanex1($event)"></p-dropdown> -->
								<p-dropdown [options]="cmbanexaux" [style]="{'width':'100%'}"
										optionLabel="name" [panelStyle]="{'width':'110%'}"
										[(ngModel)]="selectconvdobletrib"
										[disabled]="boolcmbtiporegimen"
							></p-dropdown>
						</div>
						<div class="p-col-5"></div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px">
					<div class="p-grid">
						<div class="p-col-5">
							<span class="txtAlign">Pago al exterior sujeto a retención en aplicación a la norma
								legal:</span>
						</div>
						<div class="p-col-1">
							<!-- <p-dropdown [options]="cmbanex2" [style]="{'minWidth': '100%', 'width':'100%'}"
								optionLabel="name" [panelStyle]="{'width':'110%'}" (onClick)="llenarcomboanex2()"
								(onChange)="busqanex2($event)"></p-dropdown> -->
								<p-dropdown [options]="cmbanexaux" [style]="{'width':'100%'}"
										optionLabel="name" [panelStyle]="{'width':'110%'}"
										[(ngModel)]="selectpagoexterior"
										[disabled]="boolcmbtiporegimen"></p-dropdown>
						</div>
						<div class="p-col-6"></div>
					</div>
				</div>
				<div class="p-col-2">
					<span class="txtAlign">Forma de pago:</span>
				</div>
			</div>
			<div class="p-col-7">
				<app-aggridsaci [width]="" [height]="700" [enableSorting]="true" [enableFilter]="true"
					[rowData]="proveedorServicio.commaeproveedorFP" [frameworkComponents]="frameworkComponents"
					[animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefsAnexos"
					[defaultColDef]="defaultProvFp" [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true"
					[mostrarGuardar]="false" [botonesAdicionales]="true" (selected)="senialestablaFormapago($event)"
					(cambios)="cambio($event)" align="left"></app-aggridsaci>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Reglas y Condiciones">
		<div class="p-grid">
			<div class="p-col-12">
				<textarea style="width: 100%; height: 95%" (input)="cambio(true)" autoResize="autoResize"
					id="PRO_REGCOND" name="garantia" pInputTextarea [cols]="90" [rows]="8"
					[(ngModel)]="proveedorSeleccionado.PRO_REGCOND"></textarea>
			</div>
		</div>
	</p-tabPanel>
</p-tabView>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusqueda" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
		(opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla"
		[busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'COMMAEPROVEEDOR'"
		[txtNumComprobante]="proveedorSeleccionado.PRO_CODIGO" (signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>


<p-dialog header="Clave Autorización" [(visible)]="displayDialogAutorizacion" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" (onHide)="onHideAutorizacion()" [style]="{width: '40vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="autorizacion"></p-toast>
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							(keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>