<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="docelec">
	<p-confirmDialog [transitionOptions]="'0ms'" key="venutidoc" title="Confirmar Acción"
					 appendTo="body"></p-confirmDialog>
</p-toast>
<div class="p-grid" style="width: 98%" (keydown.f9)="seleccionarTodo()" id="div">
	<div class="p-col-12">
		<p-tabView [(activeIndex)]="activeIndex">
			<p-tabPanel header="FILTROS">
				<div class="divgrups"> <!-- Primera Separación -->
					<div class="p-grid">
						<!-- Primera Fila -->
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-4">
									<span>Fecha Desde:</span>
									<p-calendar id="fechaDesde" [inputStyle]="{width: '60%', height: '20px'}"
												dateFormat="dd/mm/yy"
												[(ngModel)]="dateDesde" (ngModelChange)="fechaB()"></p-calendar>

								</div>
								<div class="p-col-3">
									<span> Fecha Hasta:</span>
									<p-calendar id="fechaHasta" [inputStyle]="{width: '60%', height: '20px'}"
												dateFormat="dd/mm/yy"
												[(ngModel)]="dateHasta" (ngModelChange)="fechaB()"></p-calendar>
								</div>
								<div class="p-col-1">
									<button type="button" (click)="consultarDocumentos()"
											[disabled]="botonListo"
											style="background-image: url(../../../assets/images/iconos/visto.png)">
									</button>
								</div>
								<div class="p-col-2">
								</div>
								<div class="p-col-2">
									<button type="button"
											style="background-image: url(../../../assets/images/iconos/salir.png);"
											(click)="salir()"></button>
								</div>
							</div>
						</div>
						<!-- Segunda Fila -->
						<div class="p-col-8">
							<div class="p-grid">
								<div class="p-col-12">
									<div class="p-grid">
										<div class="p-col-3">
											<span>Tipo:</span>
										</div>
										<div class="p-col-1">
										</div>
										<div class="p-col-6">
											<p-dropdown [style]="{'minwidth':'100%','width':'100%'}"
														[options]="optionsT"
														[panelStyle]="{'width':'100%'}"
														(onClick)="llenarcomboide()"
														(onChange)="cambioTipo($event)" [(ngModel)]="opcionDocumento"
														(ngModelChange)="habilitarBoton()"
											></p-dropdown>
										</div>
									</div>
								</div>
								<!-- Tercera Fila -->
								<div class="p-col-12" style="margin-top: -15px">
									<div class="p-grid">
										<div class="p-col-3">
											<!--<span style="font-weight: bold; margin-left: -12px">Cliente:</span>-->
											<span>{{lblclipro}}</span>
										</div>
										<div class="p-col-1">
										</div>
										<div class="p-col-3">
											<input type="text" pInputText
												   style="width: 100%" [(ngModel)]="codcliente"
												   (keydown.enter)="seleccionBusqueda()">
										</div>
										<div class="p-col-1">
											<button class="littlebuttons" pButton (click)="seleccionBusqueda()"
													style="background-image: url(../../../assets/images/iconos/buscar.png); margin-left: -5px"
											></button>
										</div>
										<div class="p-col-4">
											<span>{{nombreCliente}}</span>
										</div>
									</div>
								</div>
								<!-- Cuarta Fila -->
								<div class="p-col-12" style="margin-top: -15px">
									<div class="p-grid">
										<div class="p-col-3">
											<span>Estado:</span>
										</div>
										<div class="p-col-1">
										</div>
										<div class="p-col-6">
											<p-dropdown [style]="{'minWidth':'100%', 'width':'100%'}"
														[options]="optionsE"
														[optionLabel]="opcionDropDown" [panelStyle]="{'width':'100%'}"
														(onChange)="cambioEstado($event)"
														[(ngModel)]="opcSelectOri"></p-dropdown>
										</div>
									</div>

								</div>
								<!-- Quinta Fila -->
								<div class="p-col-12" style="margin-top: -15px">
									<div class="p-grid" style="display: none">
										<div class="p-col-2">
											<span style="font-weight: bold; margin-left: -1px">Ubicación de descarga:</span>
										</div>

										<div class="p-col-5" style="margin-left: 0px">
											<input type="text" pInputText
												   style="width: 100%"
											>
										</div>
										<div class="p-col-1">
											<button class="littlebuttons" pButton (click)="seleccionarTodo()"
													style="background-image: url(../../../assets/images/iconos/buscar.png); margin-left: -5px"
											></button>
										</div>
										<div class="p-col-4">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="p-col-4">
							<div class="p-grid">
								<div class="p-col-12">
									<button class="aceptar" pButton (click)="reenviar()"
											[disabled]="botonesBoolean"
											style="background-image: url(../../../assets/images/iconos/btnAceptar.png); line-height: 2.5em; margin-top: -5px; margin-left: -10px; float: right; width: 75%; height: 75%">
										Reenviar Correo
									</button>
								</div>
								<!-- Tercera Fila -->
								<div class="p-col-12" style="margin-top: -30px">
									<button class="aceptar" pButton (click)="autorizar()"
											[disabled]="botonesBoolean"
											style="background-image: url(../../../assets/images/iconos/btnAceptar.png); line-height: 2.5em; margin-top: 2px; margin-left: 5px; float: right; width: 75%; height: 70%">
										Verificar Autorización
									</button>
								</div>
								<!-- cuarta Fila -->
								<div class="p-col-12" style="margin-top: -35px">
									<button class="aceptar" pButton (click)="refirmar()"
											[disabled]="botonesBoolean"
											style="background-image: url(../../../assets/images/iconos/btnAceptar.png); line-height: 2.5em; margin-top: 10px; margin-left: 5px; float: right; width: 75%; height: 70%">
										Firmar Documento Rechazado
									</button>
								</div>
								<!-- CDPJ -->
								<!-- QUINTA Fila -->
								<div class="p-col-12" style="margin-top: -30px">
									<button class="aceptar" pButton (click)="continuarFEL()"
											[disabled]="botonesBoolean"
											style="background-image: url(../../../assets/images/iconos/btnAceptar.png); line-height: 2.5em; margin-top: 10px; margin-left: 5px; float: right; width: 75%; height: 60%">
										Continuar proceso de firma electrónica
									</button>
								</div>
								<!-- CDPJ -->
								<!-- CDPJ -->
								<!-- SEXTA Fila -->
								<div class="p-col-12" style="margin-top: -35px">
									<button class="aceptar" pButton (click)="reversarFEL()"
											[disabled]="botonesBoolean"
											style="background-image: url(../../../assets/images/iconos/btnAceptar.png); line-height: 2.5em; margin-top: 10px; margin-left: 5px; float: right; width: 75%; height: 70%">
										Reversar proceso de firma electrónica
									</button>
								</div>
								<!-- CDPJ -->
							</div>
						</div>
					</div>
				</div>
				<div class="divgrups"> <!-- Segunda separación -->
					<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px">
						<div class="p-col-2">
							<p-checkbox label="Seleccionar Todos"
										binary="true"
										(onChange)="seleccionarTodo()" [(ngModel)]="desSelect"
										(ngModelChange)="habilitarBotones()"
							></p-checkbox>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-4 text-center">
							<p-progressSpinner *ngIf="spin" [style]="{width: '50px', height: '30px'}"
											   animationDuration=".5s"></p-progressSpinner>
						</div>
					</div>
				</div>
				<app-aggridsaci
						[width]=""
						[height]="largo"
						[enableSorting]="true"
						[enableFilter]="true"
						[rowData]="documentos"
						[frameworkComponents]="frameworkComponents"
						[animateRows]="true"
						[rowSelection]="rowSelection"
						[columnDefs]="columnDefsDocumentos"
						[defaultColDef]="defaultColDefDocumentos"
						[rowStyle]="rowStyle"
						[singleClickEdit]="false"
						[botones]="false"
						(selected)="seleccionFila($event)"
				></app-aggridsaci>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-1">
							<span class="obligatorio">Resultado:</span>
						</div>
						<div class="p-col-11">
							<input type="text" pInputText [(ngModel)]="resultado"
								   style="width: 100%">
						</div>
					</div>
				</div>
			</p-tabPanel>
		</p-tabView>
	</div>
</div>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '70vw', height: '70vh'}" [focusOnShow]="true">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>
