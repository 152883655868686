import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { Cxctrnresumen } from '../cxcinterfaces/cxctrnresumen';
import { Cxctrncobro } from '../cxcinterfaces/cxctrncobro';
import { Cxctrnretencion } from '../cxcinterfaces/cxctrnretencion';
import { Cxctrnotrosg } from '../cxcinterfaces/cxctrnotrosg';
import { Cxctrnformapago } from '../cxcinterfaces/cxctrnformapago';
import { Cxctrnvarios } from '../cxcinterfaces/cxctrnvarios';
import { Cxctrndebitobancario } from '../cxcinterfaces/cxctrndebitobancario';
import { Cxctrnanticipos } from '../cxcinterfaces/cxctrnanticipos';
import { Cxctrnformapagodev } from '../cxcinterfaces/cxctrnformapagodev';
import { Cxctrnanexos } from '../cxcinterfaces/cxctrnanexos';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import { DatePipe } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class CxctrnretencionesService {
  public way: string;
  public url: string;

  public encabezadoCarteraActivaarray: Cxctrncobro[] = [];
  public tipoDocumentoarray: string[] = [];
  public cxctrnresumenarray: Cxctrnresumen[][] = [];
  public cxctrnfaccanceladasarray: Cxctrnresumen[][] = [];
  public cxctrnretencionarray: Cxctrnretencion[][] = [];
  public cxctrnotrosgarray: Cxctrnotrosg[][] = [];
  public cxctrnformapagoarray: Cxctrnformapago[][] = [];
  public cxctrnformapagodevarray: Cxctrnformapagodev[][] = [];
  public cxctrnvariosarray: Cxctrnvarios[][] = [];
  public cxcdebitobancarioarray: Cxctrndebitobancario[][] = [];
  public cxctrnanticiposarray: Cxctrnanticipos[][] = [];
  public dblTotalDocarray: number[] = [];
  public dblTotalRetarray: number[] = [];
  public dblTotalOtrosGarray: number[] = [];
  public dblTotalFormaPagoarray: number[] = [];
  public dblTotalFormaDevarray: number[] = [];
  public dblTotalVariosarray: number[] = [];
  public dblTotalNDarray: number[] = [];
  public dblTotalNCarray: number[] = [];
  public dblTotalCobrararray: number[] = [];

  constructor(private http: HttpClient, public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario, private confIniciales: ConfInicialesService,
    private message: MessageService, private init: NuevoComponentService,
    private utilitariosService: UtilitariosService, private datePipe: DatePipe) {
    this.way = sessionStorage.getItem('way');
  }
  refrescarURL() {
    if (this.url === null) {
      this.url = sessionStorage.getItem('url');
    }
    if (this.way === null) {
      this.way = sessionStorage.getItem('way');
    }
  }
  
  getListaReferencias(codCli): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/10776b2e755b74000', {
      elementos: {
        CLI_CODIGO: codCli
      },
    }, this.confIniciales.httpOptions());
  }
  getAsiento(codigo: string): Observable<any> {
    return this.http.post<any>(this.way + '/cxc/cxctrnretenciones/617d5724cc5fa8', {
      elementos: {
        TRNCOBRO_NRODOC: codigo
      }
    }, this.confIniciales.httpOptions());
  }
  getOrigen(aplorgcodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/107771b941d7', {
      elementos: {
        APLORG_CODIGO: aplorgcodigo
      },
    }, this.confIniciales.httpOptions());
  }
  getRealizado(usuidentificacion): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/83bbb72ab2', {
      elementos: {
        USUIDENTIFICACION: usuidentificacion
      },
    }, this.confIniciales.httpOptions());
  }
  getEncCarteraProm(cobroNro): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/10775d76315beb000', {
      elementos: {
        TRNCOBRO_NRODOC: cobroNro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erMaeclienteProm(strClicodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/76eca732b275fc0', {
      elementos: {
        CLI_CODIGO: strClicodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerDocumentosProm(opcion, numeroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/c2fadc33d675f80', {
      elementos: {
        p_opcion: opcion,
        p_filtro: 'TRNCOBRO_NRODOC = \'' + numeroDoc + '\'',
        p_condicion: 'COM_CODIGO = \'01\''
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  calcularTotalDoc() {
    this.dblTotalDocarray[this.init.tabs[this.init.tabindex].indice] = 0;
    if (this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TRNCOBRO_TIPODOC !== 'NC') {
      if (this.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice].length !== 0) {
        for (let fila = 1; fila <= this.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
          const item = this.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice][fila - 1];
          if (item.SELECCIONADO === true && this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TRNCOBRO_NRODOC === '') {
            if (Number(item.TRNCOBRO_IMPORTE) > Number(item.TRNCOBRO_SALDOINI)) {
              this.message.add({
                key: 'cartera',
                severity: 'error',
                summary: 'Información',
                detail: 'El importe no puede ser mayor que el saldo'
              });
              item.TRNCOBRO_IMPORTE = item.TRNCOBRO_SALDOINI;
              item.TRNCOBRO_IMPORTE = Number(item.TRNCOBRO_IMPORTE).toFixed(2);
            } else {
              item.TRNCOBRO_IMPORTE = Number(item.TRNCOBRO_IMPORTE).toFixed(2);
            }
          } else if (item.SELECCIONADO === false) {
            item.TRNCOBRO_IMPORTE = 0;
          }
          this.dblTotalDocarray[this.init.tabs[this.init.tabindex].indice] += Number(item.TRNCOBRO_IMPORTE);
          this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALDOC = this.dblTotalDocarray[this.init.tabs[this.init.tabindex].indice].toFixed(2);
          this.calcularTotalCobrar();
        }
      } else {
        if (this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].SALDOANC === undefined) {
          this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].SALDOANC = 0;
        }
        this.dblTotalDocarray[this.init.tabs[this.init.tabindex].indice] += this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].SALDOANC;
        this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALDOC = Number(this.dblTotalDocarray[this.init.tabs[this.init.tabindex].indice]).toFixed(2);
        this.calcularTotalCobrar();
      }
    } else {
      this.calcularTotalNC();
    }
  }

  calcularTotalNC() {
    this.dblTotalNCarray[this.init.tabs[this.init.tabindex].indice] = 0;
    for (let fila = 1; fila <= this.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      const item = this.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice][fila - 1];
      if (item.SELECCIONADO === true) {
        this.dblTotalNCarray[this.init.tabs[this.init.tabindex].indice] += Number(item.TRNCOBRO_IMPORTE);
      } else {
        item.TRNCOBRO_IMPORTE = 0;
      }
    }
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALNC = this.dblTotalNCarray[this.init.tabs[this.init.tabindex].indice].toFixed(2);
  }
  calcularTotalCobrar() {
    this.dblTotalCobrararray[this.init.tabs[this.init.tabindex].indice] = 0;
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALACOBRAR = 0;
    this.dblTotalCobrararray[this.init.tabs[this.init.tabindex].indice] = Number(this.dblTotalDocarray[this.init.tabs[this.init.tabindex].indice]) - Number(this.dblTotalNCarray[this.init.tabs[this.init.tabindex].indice]) - Number(this.dblTotalOtrosGarray[this.init.tabs[this.init.tabindex].indice]) - Number(this.dblTotalRetarray[this.init.tabs[this.init.tabindex].indice]);
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALACOBRAR = this.dblTotalCobrararray[this.init.tabs[this.init.tabindex].indice].toFixed(2);
  }
  calcularTotalRetenido(intFilaActual) {
    let dblTotalFila = 0;
    let dblBase = 0;
    let dblPorcentaje = 0;
    this.dblTotalRetarray[this.init.tabs[this.init.tabindex].indice] = 0;
    // let dblTotalRetFac = 0;
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALRET = 0;
    console.log(this.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice]);
    for (let fila = 1; fila <= this.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      const itemR = this.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1];
      if (itemR.TRNRETENCION_NRO !== '') {
        if (itemR.TRNRETENCION_NRO.length !== 0) {
          if (itemR.TRNRETENCION_NRO.length < 9 && itemR.TRNRETENCION_NRO.length !== 0) {
            do {
              itemR.TRNRETENCION_NRO = '0' + itemR.TRNRETENCION_NRO;
            } while (itemR.TRNRETENCION_NRO.length < 9);
          } else if (itemR.TRNRETENCION_NRO.length < 9 && itemR.TRNRETENCION_NRO.length === 0) {
            itemR.TRNRETENCION_NRO = '1';
            do {
              itemR.TRNRETENCION_NRO = '0' + itemR.TRNRETENCION_NRO;
            } while (itemR.TRNRETENCION_NRO.length < 9);
          }
        }
      }
      dblBase = Number(itemR.TRNRETENCION_BASE);
      dblPorcentaje = Number(itemR.TRNRETENCION_PORCENTAJE);
      //dblTotalFila = dblBase * dblPorcentaje / 100;
       //CDPJ
       dblTotalFila = dblBase * (dblPorcentaje/100);
       //CDPJ
      itemR.TRNRETENCION_BASE = Number(itemR.TRNRETENCION_BASE).toFixed(2);
      itemR.TRNRETENCION_TOTALRETENIDO = dblTotalFila.toFixed(2);
      this.dblTotalRetarray[this.init.tabs[this.init.tabindex].indice] += Number(itemR.TRNRETENCION_TOTALRETENIDO);
    }
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALRET = this.dblTotalRetarray[this.init.tabs[this.init.tabindex].indice].toFixed(2);
    this.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice].map((resu) => {
      if (this.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice].length !== 0) {
        if (resu.TRNCOBRO_NRODOC === this.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][0].ENCFAC_NUMERO && this.tipoDocumentoarray[this.init.tabs[this.init.tabindex].indice] === 'RT') {
          resu.TRNCOBRO_IMPORTE = this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALRET;
        }
      }
    });
    this.calcularTotalDoc();
    /*for (const fila = 1; fila <= listaRetFact.size(); fila++ )
    {
      SaciTrnRetencion itemR = listaRetFact.get(fila-1);
    
      dblTotalRetFac= dblTotalRetFac + itemR.getTrnretencionTotalretenido();
    }
  
    dlgCxC.lblTotalRetFac.setText( Validations.numberValidation("" + dblTotalRetFac, 15, dlgCxC.NUMDECRET,
      true, false) );

//		SI ES UNA RETENCION COLOCA EL VALOR TOTAL DE LA RETENCION EN EL IMPORTE DEL DOCUMENTO
    if (this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TRNCOBRO_TIPODOC === 'RT') {
      int filaSel = dlgCxC.getTablaDoc().getCellSelection()[0].y;
      if (dlgCxC.modeloDoc.listaDocPorCobrar.size()>0)
      {
        CxcTrnCobro item = dlgCxC.modeloDoc.listaDocPorCobrar.get(filaSel-1);
      
        item.setTrnCobroImporte(Double.parseDouble(dlgCxC.lblTotalRetFac.getText().replace(",", "")));
      
        dlgCxC.modeloDoc.calcularTotalDoc();
      }
    }*/
    this.calcularTotalCobrar();
  }
  obtenerTipoRetProm(numeroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/30bef659c6ddd', {
      elementos: {
        TRNCOBRO_NRODOC: numeroDoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerRetencionesProm(numeroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/185f76eebaec96000', {
      elementos: {
        TRNCOBRO_NRODOC: numeroDoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getOrigenProm(aplorgcodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/107771b941d7', {
      elementos: {
        APLORG_CODIGO: aplorgcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getRealizadoProm(usuidentificacion): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/83bbb72ab2', {
      elementos: {
        USUIDENTIFICACION: usuidentificacion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerSaldoCliente(cliCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/617de2aadc32b0', {
      elementos: {
        CLI_CODIGO: cliCodigo,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  erCountSacitrnretencion(strReferencia): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/198f5fbdddf6ef0000', {
      elementos: {
        TRNRETENCION_REFERENCIA: strReferencia
      },
    }, this.confIniciales.httpOptions());
  }
  anularAsiento(asi): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/2afeaab9275fb8', {
      elementos: {
        p_ASI_nro: asi,
        p_COM_codigo: '01',
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarAsiento(asi): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/756565724cc5fc0', {
      elementos: {
        p_ASI_nro_1: asi,
        p_COM_codigo: '01',
      },
    }, this.confIniciales.httpOptions());
  }
  eliminarTrnCobroProm(docNro) {    
    return this.http.post<any>(this.way + '/cxc/cxctrnretenciones/eacad2eeeecc3000', {
      elementos: {
        TRNCOBRO_NRODOC: docNro,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarAsiento(cobro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/299df2b9275fb8', {
      elementos: {
        p_nro_doc: cobro.TRNCOBRO_NRODOC,
        p_asi_nro: cobro.ASI_NRO,
        p_asi_origen: 'CXC',
        p_com_codigo: '01',
      },
    }, this.confIniciales.httpOptions());
  }
  anularDocumento(cobroNroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/2afeaab6dc33d6', {
      elementos: {
        TRNCOBRO_NRODOC: cobroNroDoc,
      }
    }, this.confIniciales.httpOptions());
  }

  anularAsientoDoc(asiNro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/55fd55724ebb700', {
      elementos: {
        asi_nro: asiNro,
      }
    }, this.confIniciales.httpOptions());
  }
  actualizarestadoFac(doc): Observable<any[]> {
    console.log(doc);
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/299df73ad7a99c', {
      elementos: {
        p_com_codigo: '01',
        p_cli_codigo: doc.CLI_CODIGO,
        p_encfac_numero: doc.TRNCOBRO_NRODOC,
      },
    }, this.confIniciales.httpOptions());
  }
  eliminarDocAnulado(documento): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/3ab2b370c55fd4', {
      elementos: {
        trncobro_nrodoc: documento.TRNCOBRO_NRODOC,
        cli_codigo: documento.CLI_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarDocAnuladoEnc(documento): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/eac9b862afeab800', {
      elementos: {
        trncobro_nrodoc: documento.TRNCOBRO_NRODOC,
        cli_codigo: documento.CLI_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }
  eliminarRetencionAnulado(documento): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/7565b775d762b00', {
      elementos: {
        trncobro_nrodoc: documento.TRNCOBRO_NRODOC,
        cli_codigo: documento.CLI_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }
  generarAsientoRT(cobro: Cxctrncobro): Observable<any[]> {
    console.log(cobro.TRNCOBRO_NRODOC, cobro.TRNCOBRO_NROCOMPROBANTE);
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/41d75724ebf77c', {
      elementos: {
        p_trncobro_nrodoc: cobro.TRNCOBRO_NRODOC,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'ARTC',
        p_asi_comprobante: cobro.TRNCOBRO_NROCOMPROBANTE,
      },
    }, this.confIniciales.httpOptions());
  }
  getDetConencasilProm(strAsiNro, strOrigen) {
    return this.http.post<any[]>(this.way + '/cont/conencasil/83bad77598bbb00', {
      elementos: {
        p_asi_nro: strAsiNro,
        p_com_codigo: '01',
        p_origen: strOrigen,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getDocCobrados(cliCodigo, numFac): Observable<any[]> {
    let sentencia = '';
    if (cliCodigo !== '') {
      sentencia += ' AND D.CLI_CODIGO=\'' + cliCodigo + '\'';
    }
    if (numFac !== '') {
      sentencia += ' AND UPPER(D.TRNDOCUMENTO_NRODOC) LIKE \'%' + numFac + '%\'';
    }

    const sqlQ = 'SELECT D.TRNDOCUMENTO_NRODOC,C.TRNCOBRO_NRODOC,C.TRNCOBRO_FECHATRN,D.TRNDOCUMENTO_IMPORTE, D.CLI_CODIGO, C.TRNCOBRO_NROCOMPROBANTE ' +
      'FROM CXC_TRNDOCUMENTO D,CXC_TRNCOBRO C WHERE D.CLI_CODIGO = C.CLI_CODIGO AND D.TRNCOBRO_NRODOC=C.TRNCOBRO_NRODOC AND D.COM_CODIGO=' +
      'C.COM_CODIGO AND C.COM_CODIGO=\'01\'' + sentencia + ' ORDER BY D.TRNDOCUMENTO_NRODOC ASC,C.TRNCOBRO_FECHATRN ASC';

    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/83badc330bda980', {
      elementos: {
        strsentencia: sentencia
      },
    }, this.confIniciales.httpOptions());
  }
  obtenerRetenciones(numeroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/185f76eebaec96000', {
      elementos: {
        TRNCOBRO_NRODOC: numeroDoc
      },
    }, this.confIniciales.httpOptions());
  }
  insertarCobroP(cobro: Cxctrncobro, tipoDoc): Promise<any[]> {
    console.log(cobro);
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/95f8edf54cc2f80', {
      elementos: {
        p_TRNCOBRO_nroDoc: cobro.TRNCOBRO_NRODOC,
        p_TRNCOBRO_nroComprobante: cobro.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_tipoDoc: tipoDoc,
        p_TRNCOBRO_fechaTrn: cobro.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_concepto: cobro.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_importe: Number(cobro.TRNCOBRO_IMPORTE),
        p_TRNCOBRO_fechavence: cobro.TRNCOBRO_FECHAVENCE,
        p_APLORG_codigo: cobro.APLORG_CODIGO,
        p_TRNCOBRO_referenciaExterna: cobro.TRNCOBRO_REFERENCIAEXTERNA,
        p_CLI_codigo: cobro.CLI_CODIGO,
        p_VEN_codigo: cobro.VEN_CODIGO,
        p_COM_codigo: '01',
        p_ASI_NRO: cobro.ASI_NRO,
        p_USU_IDENTIFICACION: cobro.USU_IDENTIFICACION,
        p_TRNCOBRO_FLAG: Number(cobro.TRNCOBRO_FLAG),
        p_BAN_CODIGO: cobro.BAN_CODIGO,
        p_TRNCOBRO_EFECTIVO: Number(cobro.TRNCOBRO_EFECTIVO),
        p_TRNCOBRO_CAMBIO: Number(cobro.TRNCOBRO_CAMBIO),
        p_CON_CODIGO: cobro.CON_CODIGO,
        p_TRNCOBRO_SALDOINI: Number(cobro.TRNCOBRO_SALDOINI),
        p_BOD_CODIGO: cobro.BOD_CODIGO,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerSaldoClienteP(cliCodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/617de2aadc32b0', {
      elementos: {
        CLI_CODIGO: cliCodigo,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  insertarDocPorCobrarP(cobro: Cxctrnresumen, nroDoc, nroComp): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/12bf1b8665985f000', {
      elementos: {
        p_TRNCOBRO_nroDoc_1: nroDoc,
        p_TRNDOCUMENTO_nroDoc_2: cobro.TRNCOBRO_NRODOC,
        p_TRNDOCUMENTO_importe_3: Number(cobro.TRNCOBRO_IMPORTE),
        p_COM_codigo_4: '01',
        p_CLI_codigo_5: cobro.CLI_CODIGO,
        p_TRNDOCUMENTO_REF: cobro.STRCUOTAFAC,
        p_TRNCOBRO_NROCOMPROBANTE: nroComp,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eliminarTrnRetencionProm(strCondicion): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/75656eeefb775c0', {
      elementos: {
        condicion: strCondicion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarTipoRetencionProm(retencion, tipo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/533bd9671b775c000000', {
      elementos: {
        trnretencion_tiporet: tipo,
        trnretencion_referencia: retencion.ENCFAC_NUMERO,
        cli_codigo: retencion.CLI_CODIGO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  generarAsientoRTProm(cobro: Cxctrncobro): Promise<any[]> {
    console.log(cobro.TRNCOBRO_NRODOC, cobro.TRNCOBRO_NROCOMPROBANTE);
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/41d75724ebf77c', {
      elementos: {
        p_trncobro_nrodoc: cobro.TRNCOBRO_NRODOC,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'ARTC',
        p_asi_comprobante: cobro.TRNCOBRO_NROCOMPROBANTE,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarestadoFacProm(doc) {
    console.log(doc);
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/299df73ad7a99c', {
      elementos: {
        p_com_codigo: '01',
        p_cli_codigo: doc.CLI_CODIGO,
        p_encfac_numero: doc.TRNCOBRO_NRODOC,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getAsientoProm(codigo: string) {
    return this.http.post<any>(this.way + '/cxc/cxctrnretenciones/617d5724cc5fa8', {
      elementos: {
        TRNCOBRO_NRODOC: codigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  insertarRetencionP(retencion: Cxctrnretencion, nroDoc, nroComp): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/4afceeefb775d800000', {
      elementos: {
        p_retencion_codigo_1: retencion.RETENCION_CODIGO,
        p_trnretencion_descripcion_4: retencion.TRNRETENCION_DESCRIPCION,
        p_trnretencion_nro_1: retencion.TRNRETENCION_NRO,
        p_trnretencion_base_5: Number(retencion.TRNRETENCION_BASE),
        p_trnretencion_porcentaje_6: Number(retencion.TRNRETENCION_PORCENTAJE),
        p_trnretencion_totalretenido_7: Number(retencion.TRNRETENCION_TOTALRETENIDO),
        p_trncobro_nrodoc_8: nroDoc,
        p_com_codigo_3: '01',
        p_trnretencion_origen: retencion.TRNRETENCION_ORIGEN,
        p_con_codigo: retencion.CON_CODIGO,
        p_cen_codigo: retencion.CEN_CODIGO,
        p_cli_codigo: retencion.CLI_CODIGO,
        p_ENCFAC_NUMERO: retencion.ENCFAC_NUMERO,
        p_TRNCOBRO_NROCOMPROBANTE: nroComp,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarCobroProm(cobro: Cxctrncobro, tipoDoc) {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/14cef94cc2f78', {
      elementos: {
        p_TRNCOBRO_nroDoc: cobro.TRNCOBRO_NRODOC,
        p_TRNCOBRO_nroComprobante: cobro.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_tipoDoc: tipoDoc,
        p_TRNCOBRO_fechaTrn: cobro.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_concepto: cobro.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_importe: Number(cobro.TRNCOBRO_IMPORTE),
        p_TRNCOBRO_fechavence: cobro.TRNCOBRO_FECHAVENCE,
        p_APLORG_codigo: cobro.APLORG_CODIGO,
        p_TRNCOBRO_referenciaExterna: cobro.TRNCOBRO_REFERENCIAEXTERNA,
        p_CLI_codigo: cobro.CLI_CODIGO,
        p_VEN_codigo: cobro.VEN_CODIGO,
        p_COM_codigo: '01',
        p_ASI_NRO: cobro.ASI_NRO,
        p_USU_IDENTIFICACION: cobro.USU_IDENTIFICACION,
        p_TRNCOBRO_FLAG: Number(cobro.TRNCOBRO_FLAG),
        p_BAN_CODIGO: cobro.BAN_CODIGO,
        p_TRNCOBRO_EFECTIVO: Number(cobro.TRNCOBRO_EFECTIVO),
        p_TRNCOBRO_CAMBIO: Number(cobro.TRNCOBRO_CAMBIO),
        p_CON_CODIGO: cobro.CON_CODIGO,
        p_TRNCOBRO_SALDOINI: Number(cobro.TRNCOBRO_SALDOINI),
        p_BOD_CODIGO: cobro.BOD_CODIGO,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eliminarTrnDocumentoProm(strCondicion) {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/75656eeeedc33c0', {
      elementos: {
        condicion: strCondicion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erClinombreProm(strClicodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/3b6cacaf8b2f6e', {
      elementos: {
        CLI_CODIGO: strClicodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erVennombreProm(strVencodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/3b7f75ef8b2f6e', {
      elementos: {
        VEN_CODIGO: strVencodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actPagadoDETCUOTA(item): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/a6772a835dd67800', {
      elementos: {
        p_ENCFAC_NUMERO: item.ENCFAC_NUMERO,
        p_CLI_CODIGO: item.CLI_CODIGO,
        p_COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erRetencioncodigoProm(strRetencioncod): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/ededdd75d986c800', {
      elementos: {
        RETENCION_CODIGO: strRetencioncod
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erConcodigo3Prom(strcodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/3b6cc5d986ca04', {
      elementos: {
        CON_CODIGO: strcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarNumeroComprobante(strAsiNro, strNroDoc) {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/533bebfaccc5b40', {
      elementos: {
        ASI_NRO: strAsiNro,
        NRODOC: strNroDoc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  asibangettrnpago(strTrnCobroNroDoc): Promise<any[]> {    
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/5724b55e0eef78000000', {
      elementos: {
        strTrnCobroNroDoc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerNumeracion(tipodoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/30bebafeb3b6a60000', {
      elementos: {
        NUM_DOCREF: tipodoc,
      }
    }, this.confIniciales.httpOptions());
  }
  consultarDocumentosACobrar(opcion, condicion): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/198bf3d56e18a60000', {
      elementos: {
        p_opcion: opcion,
        p_filtro: 'COM_CODIGO LIKE \'01\'',
        p_condicion: condicion
      },
    }, this.confIniciales.httpOptions());
  }
  getListaClientes(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/20eed65cea995a0000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  leerConfiguracionDatosElectronicos(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/2ae76daaee18f80000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  obtenerClave(accion): Observable<any> {
    if (accion === 'activarXML') {
      return this.http.post<any>(this.way + '/cxc/carteraactiva/30beb2aafa99d', {
        elementos: {},
      }, this.confIniciales.httpOptions());
    }  else {
      return this.http.post<any>(this.way + '/cxc/cxctrnretenciones/c2facaabeaf7600', {
        elementos: {},
      }, this.confIniciales.httpOptions());
    }

  }
  getxml(claveacc) {
    return this.http.post<any[]>(this.way + '/seg/compania/1aee315b', {
      elementos: {
        claveacceso: claveacc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  encnomretencion(codigoretencion) {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/20eedf165eddb8000000000', {
      elementos: {
        codret: codigoretencion,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  encconcodigoretencion(codigoretencion) {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/1077598bb30d940000000000000', {
      elementos: {
        codret: codigoretencion,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  enccodigoretencion(codigoretencion) {
    return this.http.post<any[]>(this.way + '/cxc/cxctrnretenciones/20eeb30d9431b8000000000', {
      elementos: {
        codret: codigoretencion,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
}
