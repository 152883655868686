//
// SACI WEB
// Rutina: Pto Vta Cobrar
// Propietario: Victor Perez
// Modificado por: VP
// Fecha de creación: 2019
// Fecha de Modificación: 2019
//
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PdvfacturaService} from '../../servicios/pdvfactura.service';
import {VenEncfac} from '../../interfaces/venencfac';
import {Maecliente} from '../../interfaces/maecliente';
import {Maevendedor} from '../../interfaces/maevendedor';
import {CalculosServiceService} from '../../servicios/calculos-service.service';
import {MessageService, SelectItem} from 'primeng/api';
import {TipoPago} from '../../interfaces/tipopago';
import {TipoRetencion} from '../../interfaces/tiporetencion';
import {BanMaeban} from '../../interfaces/banmaeban';
import {SaciMaeretencion} from '../../interfaces/sacimaeretencion';
import {AnexMaecodsri} from '../../interfaces/anexmaecodsri';
import {CxcTrncobro} from '../../interfaces/cxctrncobro';
import {Usuario} from '../../usuario';
import {SaciTrnretencion} from '../../interfaces/sacitrnretencion';
import {CxcTrnpago} from '../../interfaces/cxctrnpago';
import {CxcMaebancli} from '../../interfaces/cxcmaebancli';
import {CxcMaetarjeta} from '../../interfaces/cxcmaetarjeta';
import {ServicioFacturacionPDF} from '../../servicios/servicio.facturaPDF';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

@Component({
  selector: 'app-pdvcobrar',
  templateUrl: './pdvcobrar.component.html',
  styleUrls: ['./pdvcobrar.component.css'],
  preserveWhitespaces: false
})

export class PdvcobrarComponent implements OnInit, OnChanges {
  @Output() bolcob = new EventEmitter<boolean>();
  @Output() numfact = new EventEmitter<string>();
  @Output() estado = new EventEmitter<string>();
  @Output() asiento = new EventEmitter<string>();
  @Output() pagada = new EventEmitter<boolean>();
  @Output() teclado = new EventEmitter<string>();
  @Output() clnpago = new EventEmitter<boolean>();
  
  @Input() inpcliente: Maecliente;
  @Input() inpvendedor: Maevendedor;
  @Input() inpDate: Date;
  @Input() inpchkfactura: boolean;
  @Input() inpfactura: VenEncfac;
  @Input() trncobro: CxcTrncobro[];
  @Input() trnretencion: SaciTrnretencion[];
  @Input() trnpago: CxcTrnpago[];
  @Input() valorTeclado: number;
  @Input() numeroTipoPago: number;
  @Input() numeroCta: number;
  @Input() numeroImporte: number;
  @Input() numeroRetencion: number;
  @Input() numBase: number;
  @Input() instanciaPri: number;
  respuesta: any;
  menupago: any;
  imprimir: any;
  formasdepago: SelectItem[];
  
  tiposdepago: TipoPago[] = [];
  fpago: any = {};
  fdisp: any = {};
  tipopago: TipoPago = {};
  
  tiposderetencion: TipoRetencion[] = [];
  tiporetencion: TipoRetencion = {};
  
  totalapagar: number;
  efectivo: number;
  cambio: number;
  efectivocomma: string;
  displayDialogBancos: boolean;
  bancoSelec: BanMaeban = {};
  bancos: BanMaeban[] = [];
  bancoSelecCH: CxcMaebancli = {};
  bancosCH: CxcMaebancli[] = [];
  bancoSelecTA: CxcMaetarjeta = {};
  bancosTA: CxcMaetarjeta[] = [];
  tipo = '';
  
  displayDialogCodsri: boolean;
  codsriSelec: AnexMaecodsri = {};
  codssri: AnexMaecodsri[] = [];
  
  displayDialogRetencion: boolean;
  retencionSelec: SaciMaeretencion = {};
  retenciones: SaciMaeretencion[] = [];
  
  filaselec: number;
  existeasiento: boolean;
  posPago: number;
  posRet: number;
  blobpdf: any;
  
  
  private serfact = new ServicioFacturacionPDF();
  
  
  constructor(private servicePdv: PdvfacturaService, private calc: CalculosServiceService,
              private messageService: MessageService, public usuario: Usuario) {
    this.formasdepago = [{label: 'Cheque', value: 'Cheque'},
      {label: 'Deposito', value: 'Deposito'},
      {label: 'Tarjeta', value: 'Tarjeta'}
    ];
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }
  
  
  ngOnInit() {
    this.menupago = false;
    this.imprimir = false;
    this.totalapagar = 0;
    this.cambio = 0;
    this.efectivo = 0;
    this.tiposdepago = [];
    this.fpago[0] = [{codigo: '1', name: 'Cheque'},
      {codigo: '2', name: 'Deposito'},
      {codigo: '3', name: 'Tarjeta'}];
    this.fdisp[0] = [{codigo: 'DF', name: 'DF'},
      {codigo: 'MD', name: 'MD'}];
    this.tiposderetencion = [];
    this.existeasiento = false;
    this.posPago = null;
    this.valorTeclado = null;
    this.numeroTipoPago = null;
    this.numeroCta = null;
    this.numeroRetencion = null;
    this.numBase = null;
    this.posRet = null;
    
    if (this.trncobro.length === 0) {
      this.existeasiento = false;
      this.menupago = true;
      this.totalapagar = Number(this.calc.entregarTotal()[this.instanciaPri - 1]);
      this.cambio = this.totalapagar * (-1);
      this.efectivo = 0;
      this.tiposdepago = [];
      this.tiposderetencion = [];
      if (this.cambio < 0) {
        this.addlineafpnuevo();
        this.addlinearetnuevo();
      }
    } else {
      this.existeasiento = true;
      this.totalapagar = Number(this.calc.entregarTotal()[this.instanciaPri - 1]);
      this.cambio = this.trncobro[0].TRNCOBRO_CAMBIO;
      this.efectivo = this.trncobro[0].TRNCOBRO_EFECTIVO;
      this.efectivocomma = this.moneyValidation(this.efectivo + '');
      
      for (const i of this.trnpago) {
        if (i.TRNPAGO_TIPO !== 'EF') {
          if (i.TRNPAGO_TIPO === 'DP') {
            this.servicePdv.getBancosDP().subscribe((respuesta: any[]) => {
              this.tipopago = {};
              this.tipopago.TIPO = 'Depósito';
              this.tipopago.NUMERO = i.TRNPAGO_NUMERO;
              this.tipopago.BANCO = i.BANCLI_CODIGO;
              this.tipopago.NROCUENTA = i.TRNPAGO_NROCTA;
              this.tipopago.IMPORTE = i.TRNPAGO_IMPORTE;
              this.tipopago.IMPORTECOMMA = this.moneyValidation(i.TRNPAGO_IMPORTE + '');
              this.tipopago.CODSRI = i.TRNPAGO_CODSRI;
              this.tipopago.DISPOSITIVO = i.TRNPAGO_TIPDIS;
              for (const u of respuesta) {
                if (i.BANCLI_CODIGO === u.BAN_CODIGO) {
                  this.tipopago.NOMBREBANCO = u.BAN_BANCO;
                }
              }
              this.tiposdepago.push(this.tipopago);
              this.fpago[this.tiposdepago.indexOf(this.tipopago)] = [{codigo: 'DP', name: 'Déposito'}];
            });
          }
          if (i.TRNPAGO_TIPO === 'CH') {
            this.servicePdv.getBancosCH().subscribe((respuesta: any[]) => {
              this.tipopago = {};
              this.tipopago.TIPO = 'Cheque';
              this.tipopago.NUMERO = i.TRNPAGO_NUMERO;
              this.tipopago.BANCO = i.BANCLI_CODIGO;
              this.tipopago.NROCUENTA = i.TRNPAGO_NROCTA;
              this.tipopago.IMPORTE = i.TRNPAGO_IMPORTE;
              this.tipopago.IMPORTECOMMA = this.moneyValidation(i.TRNPAGO_IMPORTE + '');
              this.tipopago.CODSRI = i.TRNPAGO_CODSRI;
              this.tipopago.DISPOSITIVO = i.TRNPAGO_TIPDIS;
              for (const u of respuesta) {
                if (i.BANCLI_CODIGO === u.BANCLI_CODIGO) {
                  this.tipopago.NOMBREBANCO = u.BANCLI_NOMBRE;
                }
              }
              this.tiposdepago.push(this.tipopago);
              this.fpago[this.tiposdepago.indexOf(this.tipopago)] = [{codigo: 'CH', name: 'Cheque'}];
            });
          }
          if (i.TRNPAGO_TIPO === 'TA') {
            this.servicePdv.getBancosTA().subscribe((respuesta: any[]) => {
              this.tipopago = {};
              this.tipopago.TIPO = 'Tarjeta';
              this.tipopago.NUMERO = i.TRNPAGO_NUMERO;
              this.tipopago.BANCO = i.BANCLI_CODIGO;
              this.tipopago.NROCUENTA = i.TRNPAGO_NROCTA;
              this.tipopago.IMPORTE = i.TRNPAGO_IMPORTE;
              this.tipopago.IMPORTECOMMA = this.moneyValidation(i.TRNPAGO_IMPORTE + '');
              this.tipopago.CODSRI = i.TRNPAGO_CODSRI;
              this.tipopago.DISPOSITIVO = i.TRNPAGO_TIPDIS;
              for (const u of respuesta) {
                if (i.BANCLI_CODIGO === u.TAR_CODIGO) {
                  this.tipopago.NOMBREBANCO = u.TAR_DESCRIPCION;
                }
              }
              this.tiposdepago.push(this.tipopago);
              this.fpago[this.tiposdepago.indexOf(this.tipopago)] = [{codigo: 'TA', name: 'Tarjeta'}];
              if (i.TRNPAGO_TIPDIS === 'DF') {
                this.fdisp[this.tiposdepago.indexOf(this.tipopago)] = [{codigo: 'DF', name: 'DF'}];
              }
              if (i.TRNPAGO_TIPDIS === 'MD') {
                this.fdisp[this.tiposdepago.indexOf(this.tipopago)] = [{codigo: 'MD', name: 'MD'}];
              }
            });
          }
        }
      }
      
      for (const i of this.trnretencion) {
        this.tiporetencion = {};
        this.tiporetencion.CODIGO = i.RETENCION_CODIGO;
        this.tiporetencion.DESCRIPCION = i.TRNRETENCION_DESCRIPCION;
        this.tiporetencion.NUMERO = i.TRNRETENCION_NRO;
        this.tiporetencion.BASE = i.TRNRETENCION_BASE;
        this.tiporetencion.BASECOMMA = this.moneyValidation(this.tiporetencion.BASE + '');
        this.tiporetencion.PORCENTAJE = i.TRNRETENCION_PORCENTAJE;
        this.tiporetencion.TOTALRETENIDO = i.TRNRETENCION_TOTALRETENIDO;
        this.tiporetencion.TOTALRETENIDOCOMMA = this.moneyValidation(this.tiporetencion.TOTALRETENIDO + '');
        this.tiposderetencion.push(this.tiporetencion);
      }
    }
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    for (const propertyName in changes) {
      if (propertyName === 'valorTeclado') {
        if (this.valorTeclado !== undefined && this.valorTeclado !== null && this.valorTeclado !== 9999999999999) {
          this.efectivocomma = this.valorTeclado + '';
        } else if (this.valorTeclado === 9999999999999) {
          this.calculaCambio();
        }
      } else if (propertyName === 'numeroTipoPago' && this.posPago !== (null || undefined)) {
        if (this.numeroTipoPago !== (undefined || null || 9999999999999)) {
          this.tiposdepago[this.posPago].NUMERO = this.numeroTipoPago;
        } else if (this.numeroTipoPago === 9999999999999) {
          this.teclado.emit('cerrar');
        }
      } else if (propertyName === 'numeroCta' && this.posPago !== (null || undefined)) {
        if (this.numeroCta !== (undefined || null || 9999999999999)) {
          this.tiposdepago[this.posPago].NROCUENTA = this.numeroCta;
        } else if (this.numeroCta === 9999999999999) {
          this.teclado.emit('cerrar');
        }
      } else if (propertyName === 'numeroImporte' && this.posPago !== (null || undefined)) {
        if (this.numeroImporte !== undefined && this.numeroImporte !== null && this.numeroImporte !== 9999999999999) {
          this.tiposdepago[this.posPago].IMPORTECOMMA = this.numeroImporte;
        } else if (this.numeroImporte === 9999999999999) {
          this.calculaCambioFormaPago();
        }
      } else if (propertyName === 'numeroRetencion' && this.posRet !== (null || undefined)) {
        if (this.numeroRetencion !== (undefined || null || 9999999999999)) {
          this.tiposderetencion[this.posRet].NUMERO = this.numeroRetencion;
        } else if (this.numeroRetencion === 9999999999999) {
          this.teclado.emit('cerrar');
        }
      } else if (propertyName === 'numBase' && this.posRet !== (null || undefined)) {
        if (this.numBase !== undefined && this.numBase !== null && this.numBase !== 9999999999999) {
          this.tiposderetencion[this.posRet].BASECOMMA = this.numBase;
        } else if (this.numBase === 9999999999999) {
          this.calculaCambioRetencion();
        }
      }
    }
  }
  
  enviarTeclado(valor: string) {
    if (valor === 'numericoEfectivo' && this.efectivo === 0) {
      // this.efectivocomma = undefined;
      this.teclado.emit(valor);
    } else {
      this.teclado.emit(valor);
    }
    
    if (valor === 'numericoEfectivo') {
      if (this.efectivocomma === undefined) {
        return;
      }
      this.efectivocomma = this.quitComma(this.efectivocomma + '') + '';
    }
  }
  
  enviarTecladoFormaPago(valor: string, indi: number) {
    this.posPago = indi;
    this.enviarTeclado(valor);
    if (valor === 'numericoImporte') {
      if (this.tiposdepago[indi].IMPORTECOMMA === undefined) {
        return;
      }
      this.tiposdepago[indi].IMPORTECOMMA = this.quitComma(this.tiposdepago[indi].IMPORTECOMMA + '') + '';
    }
  }
  
  enviarTecladoRetencion(valor: string, indi: number) {
    this.posRet = indi;
    this.enviarTeclado(valor);
    if (valor === 'numericoBase') {
      if (this.tiposderetencion[indi].BASECOMMA === undefined) {
        return;
      }
      this.tiposderetencion[indi].BASECOMMA = this.quitComma(this.tiposderetencion[indi].BASECOMMA + '') + '';
    }
    
    
  }
  
  /* guardarFactura() {

    this.venencfac = {};
    if (this.inpfactura.ENCFAC_NUMERO === undefined) {
      this.venencfac.ENCFAC_NUMERO = null;
      this.venencfac.ENCFAC_DIRECCION = this.inpcliente.CLI_DIRECCION1;
      this.venencfac.ENCFAC_TELEFONO = this.inpcliente.CLI_TELEFONO1;
      this.venencfac.ENCFAC_RUCIDE = this.inpcliente.CLI_RUCIDE;
      this.venencfac.ENCFAC_AUTORIZACION = null;
      this.messageService.add({
        key: 'cobrar',
        severity: 'success',
        summary: 'Factura Guardada',
        detail: 'Se guardo la factura',
      });
    } else {
      this.servicePdv.borrarTodoDetalle(this.inpfactura.ENCFAC_NUMERO).subscribe((res) => {
      });
      this.venencfac.ENCFAC_NUMERO = this.inpfactura.ENCFAC_NUMERO;
      this.venencfac.ENCFAC_DIRECCION = this.inpfactura.ENCFAC_DIRECCION;
      this.venencfac.ENCFAC_TELEFONO = this.inpfactura.ENCFAC_TELEFONO;
      this.venencfac.ENCFAC_RUCIDE = this.inpfactura.ENCFAC_RUCIDE;
      this.venencfac.ENCFAC_AUTORIZACION = this.inpfactura.ENCFAC_AUTORIZACION;
      this.messageService.add({
        key: 'cobrar',
        severity: 'success',
        summary: 'Factura Guardada',
        detail: 'Se Actualizo la factura',
      });
    }
    this.venencfac.ENCFAC_FECHAEMISION = this.inpDate.toLocaleString('en-GB', {timeZone: 'UTC'}).substr(0, 10);
    this.venencfac.ENCFAC_FECHAENTREGA = this.inpDate.toLocaleString('en-GB', {timeZone: 'UTC'}).substr(0, 10);
    this.venencfac.ENCFAC_ESTADO = 'P';
    this.venencfac.CLI_CODIGO = this.inpcliente.CLI_CODIGO;
    this.venencfac.CLI_NOMBRE = this.inpcliente.CLI_NOMBRE;
    this.venencfac.BOD_CODIGO = '001';
    this.venencfac.VEN_CODIGO = this.inpvendedor.VEN_CODIGO;
    this.venencfac.ENCFAC_LISTA = 'A';
    this.venencfac.ENCFAC_FECHAVENCIMIENTO = this.inpDate.toLocaleString('en-GB', {timeZone: 'UTC'}).substr(0, 10);
    this.venencfac.ENCFAC_TOTAL = Number(this.calc.entregarTotal()[this.instanciaPri - 1]);
    this.venencfac.ENCFAC_TOTALNETO = Number(this.calc.entregarSubtotal()[this.instanciaPri - 1]);
    this.venencfac.ENCFAC_PORCEIVA = '12';
    this.venencfac.ENCFAC_VALORIVA = Number(this.calc.entregarIVA()[this.instanciaPri - 1]);
    this.venencfac.ENCFAC_PORCECAR = 0;
    this.venencfac.ENCFAC_VALORCAR = 0;
    this.venencfac.ENCFAC_PORCEDES = 0;
    this.venencfac.ENCFAC_VALORDES = 0;
    this.venencfac.ENCFAC_VALORDEV = 0;
    this.venencfac.ENCFAC_PORCEINT = 0;
    this.venencfac.ENCFAC_VALORINT = 0;
    this.venencfac.ENCFAC_PORCEREC = 0;
    this.venencfac.ENCFAC_VALORREC = 0;
    this.venencfac.ENCFAC_VALORICE = 0;
    this.venencfac.ENCFAC_PORCEICE = 0;
    this.venencfac.ENCFAC_IMPRESO = 'N';
    this.venencfac.ENCFAC_COMENTARIO = 'VICTOR';
    this.venencfac.ENCFAC_TIPOFAC = 'S';
    this.venencfac.ENCFAC_GENERAFAC = 'N';
    this.venencfac.ENCFAC_CHOFER = '';
    this.venencfac.ENCFAC_CARRO = '';
    this.venencfac.ENCFAC_PLACA = '';
    this.venencfac.ENCFAC_IVA = '1';  // verificar
    this.venencfac.ENCFAC_BASEIVA = 0;
    this.venencfac.ENCFAC_BASEICE = 0;
    this.venencfac.ENCFAC_BASECERO = 0;
    this.venencfac.ENCFAC_OTROS = 0;
    this.venencfac.ENCFAC_GRUPO = null;
    this.venencfac.ENCFAC_DIASCRDT = 0;
    this.venencfac.ENCFAC_CLIDSCT = 0;
    this.venencfac.ENCFAC_ORIGEN = 'FAC';
    this.venencfac.ENCFAC_TIPODSCTO = null;
    this.venencfac.ENCFAC_SECACTUAL = null;
    this.venencfac.ENCFAC_REFCLI = null;
    if (this.inpchkfactura === false) {
      this.venencfac.ENCFAC_NOTADEVENTA = 'S';
    } else {
      this.venencfac.ENCFAC_NOTADEVENTA = null;
    }
    this.venencfac.ENCFAC_PLACA_CLI = null;

    this.servicePdv.insertarFacturaPtoVta(this.venencfac).subscribe((respuesta: any[]) => {
      this.respuesta = respuesta;
      this.numfact.emit(this.respuesta['0'][':B2']);
      for (let i = 0; i < this.calc.recibirFacComun()[this.instanciaPri - 1].length; i++) {
        const vendetfac: VenDetfac = {};
        const detalle: Maearticulo = this.calc.recibirFacComun()[this.instanciaPri - 1][i];
        vendetfac.DETFAC_ORDEN = i + 1;
        vendetfac.DETFAC_LINEA = i + 1;
        vendetfac.ENCFAC_NUMERO = this.respuesta['0'][':B2'];
        vendetfac.DETFAC_LINEA_3 = 0;
        vendetfac.DETFAC_TIPODET = 'A';
        vendetfac.BOD_CODIGO = '001';
        vendetfac.DETFAC_CODIGO = detalle.ART_CODIGO;
        vendetfac.DETFAC_DESCRIPCION = detalle.ART_NOMBREC;
        vendetfac.DETFAC_TRIBIVA = 'S';
        vendetfac.DETFAC_TRIBICE = 'N';
        vendetfac.DETFAC_SIGNO = null;
        vendetfac.DETFAC_CANTIDAD = Number(detalle.CANTIDAD);
        vendetfac.DETFAC_PRECIO = Number(detalle.ARTPRE_PRECIO);
        vendetfac.DETFAC_DESCUENTO = Number(detalle.DESCUENTO);
        vendetfac.DETFAC_TOTAL = Number(detalle.CANTIDAD) * Number(detalle.ARTPRE_PRECIO);
        vendetfac.DETFAC_IVA = 0;
        vendetfac.DETFAC_ICE = 0;
        vendetfac.DETFAC_LISTA = 'A';
        vendetfac.DETFAC_BASEIVA = 0;
        vendetfac.DETFAC_BASEICE = 0;
        vendetfac.DETFAC_BASECERO = 0;
        vendetfac.DETFAC_PORCEICE = 0;
        vendetfac.ENCCOT_NUMERO = null;
        vendetfac.DETCOT_LINEA = 0;
        vendetfac.ENCPED_NUMERO = null;
        vendetfac.DETPED_LINEA = i + 1;
        vendetfac.ENCGRE_NUMERO = null;
        vendetfac.DETGRE_LINEA = 0;
        vendetfac.UNI_SIMBOLO = null;
        vendetfac.DETFAC_TIPO = null;
        vendetfac.DETFAC_CODIGOREF = null;
        vendetfac.ENCFAC_NUMERO = this.respuesta['0'][':B2'];
        vendetfac.DETFAC_PROMOCION = null;
        vendetfac.DETFAC_ORDEN = i;
        vendetfac.DETFAC_LINORGPROM = 0;
        vendetfac.MAEPRM_CODIGO = 0;
        vendetfac.DETFAC_FECHAREALIZACION = null;
        vendetfac.DETFAC_PRECIOC = 0;
        vendetfac.CEN_CODIGO = null;
        vendetfac.DETFAC_BULTO = 0;
        vendetfac.DETFAC_CANTIDADB = 0;
        vendetfac.DETFAC_TIPOSRI = null;
        vendetfac.DETFAC_SIGNO = null;
        vendetfac.DETFAC_ARTACAMBIO = null;
        vendetfac.ORDTRA_NUMERO = null;
        vendetfac.DETORDTRA_LINEA = 0;
        vendetfac.ENCREQ_NUMERO = null;
        vendetfac.DETREQ_LINEA = 0;
        vendetfac.DETFAC_BASENOOBJIVA = 0;
        vendetfac.DETFAC_TRIBASENOOBJIVA = null;
        vendetfac.ENCMAT_NUMERO = null;
        vendetfac.DETMAT_CODIGO = null;
        vendetfac.DETFAC_ORDENLOTE = null;
        this.servicePdv.insertarDetalle(vendetfac).subscribe((response: any[]) => {
          this.respuestadet = response;
        });
      }
      this.servicePdv.actualizarkardex(this.respuesta['0'][':B2']).subscribe((resp) => {
      });
    });
  }*/
  
  contado() {
    if (this.inpfactura.ENCFAC_NUMERO === undefined || this.inpfactura.ENCFAC_NUMERO === null || this.inpfactura.ENCFAC_NUMERO === '') {
      this.messageService.add({
        key: 'cobrar',
        severity: 'error',
        summary: 'Guardar Factura',
        detail: 'Primero guarde la factura.'
      });
      return;
    }
    
    if (this.inpfactura.ASI_NRO !== undefined && this.inpfactura.ASI_NRO !== null &&
      this.inpfactura.ASI_NRO !== '') {
      this.messageService.add({
        key: 'cobrar',
        severity: 'error',
        summary: 'Factura Pagada',
        detail: 'La factura esta pagada a credito'
      });
      return;
    }
    this.menupago = false;
  }
  
  credito() {
    if (this.inpfactura.ENCFAC_NUMERO === undefined || this.inpfactura.ENCFAC_NUMERO === null || this.inpfactura.ENCFAC_NUMERO === '') {
      this.messageService.add({
        key: 'cobrar',
        severity: 'error',
        summary: 'Guardar Factura',
        detail: 'Primero guarde la factura.'
      });
      return;
    }
    
    if (this.inpcliente.CLI_CODIGO === '9999999999999') {
      this.messageService.add({
        key: 'cobrar',
        severity: 'error',
        summary: 'Consumidor Final',
        detail: 'No hay credito para el consumidor final'
      });
      return;
    }
    
    let creditodisponible;
    
    creditodisponible = Number(this.inpcliente.CLI_LIMCREDIT) - Number(this.inpcliente.CLI_SALDO);
    if (creditodisponible < 0 && this.inpcliente.CLI_LIMCREDIT !== 0) {
      this.messageService.add({
        key: 'cobrar',
        severity: 'error',
        summary: 'Error',
        detail: 'Credito no disponible'
      });
      return;
    }
    
    this.servicePdv.generarAsientoContableCredito(this.inpfactura.ENCFAC_NUMERO).subscribe(() => {
      this.messageService.add({
        key: 'cobrar',
        severity: 'success',
        summary: 'Asiento Contable',
        detail: 'Asiento generado correctamente',
      });
      let asinro = '';
      this.servicePdv.retornarAsiento(this.inpfactura.ENCFAC_NUMERO).subscribe((respuasi: any[]) => {
        for (const i of respuasi) {
          asinro = i.ASI_NRO;
        }
        this.estado.emit('PENDIENTE');
        this.asiento.emit(asinro);
        this.pagada.emit(false);
      });
    });
    
    this.servicePdv.actualizarFormaPago(this.inpfactura.ENCFAC_NUMERO, 'C').subscribe(() => {
    });
    
    this.menupago = true;
    
  }
  
  addlineafpnuevo() {
    if (this.cambio >= 0) {
      return;
    }
    
    this.tipopago = {};
    this.tipopago.TIPO = 'Cheque';
    this.tipopago.NUMERO = '';
    this.tipopago.BANCO = '';
    this.tipopago.NOMBREBANCO = '';
    this.tipopago.NROCUENTA = '';
    this.tipopago.IMPORTE = this.cambio * (-1);
    this.tipopago.DISPOSITIVO = '';
    this.servicePdv.getFormaPago().subscribe((respuesta: any[]) => {
      for (const q of respuesta) {
        if (q.FORMAPAGO_TIPO === 'CH') {
          if (q.FORMAPAGO_CODIGOSRI !== undefined && q.FORMAPAGO_CODIGOSRI !== null && q.FORMAPAGO_CODIGOSRI !== '') {
            this.tipopago.CODSRI = q.FORMAPAGO_CODIGOSRI;
          } else {
            this.tipopago.CODSRI = '';
          }
        }
      }
    });
    this.tiposdepago.push(this.tipopago);
  }
  
  addlineafp() {
    this.calculaCambioFormaPago();
    
    if (this.cambio >= 0) {
      this.messageService.add({
        key: 'cobrar',
        severity: 'error',
        summary: 'Forma de Pago Completa',
        detail: 'No es posible añadir forma de pago.'
      });
      return;
    }
    
    const lgth = this.tiposdepago.length - 1;
    if (this.tiposdepago[lgth].NUMERO === '' ||
      this.tiposdepago[lgth].IMPORTE === '' ||
      this.tiposdepago[lgth].BANCO === '') {
      this.messageService.add({
        key: 'cobrar',
        severity: 'error',
        summary: 'Forma de Pago',
        detail: 'Complete todos los campos'
      });
      return;
    }
    
    this.tipopago = {};
    this.tipopago.TIPO = 'Cheque';
    this.tipopago.NUMERO = '';
    this.tipopago.BANCO = '';
    this.tipopago.NOMBREBANCO = '';
    this.tipopago.NROCUENTA = '';
    this.tipopago.IMPORTE = this.cambio * (-1);
    this.tipopago.DISPOSITIVO = '';
    this.servicePdv.getFormaPago().subscribe((respuesta: any[]) => {
      for (const q of respuesta) {
        if (q.FORMAPAGO_TIPO === 'CH') {
          if (q.FORMAPAGO_CODIGOSRI !== undefined && q.FORMAPAGO_CODIGOSRI !== null && q.FORMAPAGO_CODIGOSRI !== '') {
            this.tipopago.CODSRI = q.FORMAPAGO_CODIGOSRI;
          } else {
            this.tipopago.CODSRI = '';
          }
        }
      }
    });
    this.tiposdepago.push(this.tipopago);
  }
  
  
  dellineafp(indi) {
    if (this.tiposdepago.length > 1) {
      this.tiposdepago.splice(indi, 1);
    }
  }
  
  addlinearetnuevo() {
    if (this.cambio >= 0) {
      return;
    }
    this.tiporetencion = {};
    this.tiporetencion.CODIGO = '';
    this.tiporetencion.DESCRIPCION = '';
    this.tiporetencion.NUMERO = '';
    this.tiporetencion.BASE = '';
    this.tiporetencion.PORCENTAJE = '';
    this.tiporetencion.TOTALRETENIDO = '';
    this.tiposderetencion.push(this.tiporetencion);
  }
  
  addlinearet() {
    // this.calculaCambioRetencion();
    
    if (this.cambio >= 0) {
      this.messageService.add({
        key: 'cobrar',
        severity: 'error',
        summary: 'Retención',
        detail: 'Forma de pago completa'
      });
      return;
    }
    
    const lgth = this.tiposderetencion.length - 1;
    if (this.tiposderetencion[lgth].CODIGO === '' ||
      this.tiposderetencion[lgth].NUMERO === '' ||
      this.tiposderetencion[lgth].BASE === '') {
      this.messageService.add({
        key: 'cobrar',
        severity: 'error',
        summary: 'Retencion',
        detail: 'Complete todos los campos'
      });
      return;
    }
    
    this.tiporetencion = {};
    this.tiporetencion.CODIGO = '';
    this.tiporetencion.DESCRIPCION = '';
    this.tiporetencion.NUMERO = '';
    this.tiporetencion.BASE = '';
    this.tiporetencion.PORCENTAJE = '';
    this.tiporetencion.TOTALRETENIDO = '';
    this.tiposderetencion.push(this.tiporetencion);
  }
  
  dellinearet(indi) {
    if (this.tiposderetencion.length > 1) {
      this.tiposderetencion.splice(indi, 1);
    }
  }
  
  
  calculaCambio() {
    if (this.efectivocomma === undefined) {
      return;
    }
    
    this.efectivo = this.quitComma(this.efectivocomma);
    this.efectivocomma = this.moneyValidation(this.efectivo.toFixed(2) + '');
    
    let suma = 0;
    for (const i of this.tiposdepago) {
      if (i.NUMERO !== '') {
        suma = suma + Number(i.IMPORTE);
      }
    }
    for (const i of this.tiposderetencion) {
      if (i.CODIGO !== '') {
        suma = suma + Number(i.TOTALRETENIDO);
      }
    }
    
    this.cambio = (Number(this.efectivo) + Number(suma)) - this.totalapagar;
    this.teclado.emit('cerrar');
    document.getElementById('focus').focus();
  }
  
  calculaCambioFormaPago() {
    let suma = 0;
    for (const i of this.tiposdepago) {
      if (i.NUMERO !== '') {
        if (i.IMPORTECOMMA === undefined) {
          return;
        }
        i.IMPORTE = this.quitComma(i.IMPORTECOMMA);
        i.IMPORTECOMMA = this.moneyValidation(i.IMPORTE + '');
        suma = suma + Number(i.IMPORTE);
      }
    }
    for (const i of this.tiposderetencion) {
      if (i.CODIGO !== '') {
        i.TOTALRETENIDO = this.quitComma(i.TOTALRETENIDOCOMMA);
        i.TOTALRETENIDOCOMMA = this.moneyValidation(i.TOTALRETENIDO + '');
        suma = suma + Number(i.TOTALRETENIDO);
      }
    }
    this.cambio = (Number(this.efectivo) + Number(suma)) - this.totalapagar;
    this.teclado.emit('cerrar');
    document.getElementById('focus').focus();
  }
  
  calculaCambioRetencion() {
    for (const i of this.tiposderetencion) {
      if (i.BASECOMMA === undefined) {
        return;
      }
      i.BASE = this.quitComma(i.BASECOMMA + '');
      i.BASECOMMA = this.moneyValidation(i.BASE + '');
      i.TOTALRETENIDO = i.BASE * i.PORCENTAJE / 100;
      i.TOTALRETENIDO = i.TOTALRETENIDO.toFixed(2);
      i.TOTALRETENIDOCOMMA = this.moneyValidation(i.TOTALRETENIDO + '');
    }
    
    let suma = 0;
    for (const i of this.tiposdepago) {
      if (i.NUMERO !== '') {
        i.IMPORTE = this.quitComma(i.IMPORTECOMMA + '');
        i.IMPORTECOMMA = this.moneyValidation(i.IMPORTE + '');
        suma = suma + Number(i.IMPORTE);
      }
    }
    for (const i of this.tiposderetencion) {
      i.TOTALRETENIDO = this.quitComma(i.TOTALRETENIDOCOMMA);
      i.TOTALRETENIDOCOMMA = this.moneyValidation(i.TOTALRETENIDO + '');
      suma = suma + Number(i.TOTALRETENIDO);
    }
    
    this.cambio = (Number(this.efectivo) + Number(suma)) - this.totalapagar;
    this.teclado.emit('cerrar');
    this.addlinearet();
    document.getElementById('focus').focus();
    
  }
  
  showDialogBancos(indi) {
    this.teclado.emit('cerrar');
    this.displayDialogBancos = true;
    this.filaselec = indi;
    if (this.tiposdepago[indi].TIPO === 'Depósito') {
      this.tipo = 'DP';
      this.servicePdv.getBancosDP().subscribe((respuesta: any[]) => {
        this.bancos = respuesta;
      });
    }
    if (this.tiposdepago[indi].TIPO === 'Cheque') {
      this.tipo = 'CH';
      this.servicePdv.getBancosCH().subscribe((respuesta: any[]) => {
        this.bancosCH = respuesta;
      });
    }
    if (this.tiposdepago[indi].TIPO === 'Tarjeta') {
      this.tipo = 'TA';
      this.servicePdv.getBancosTA().subscribe((respuesta: any[]) => {
        this.bancosTA = respuesta;
      });
    }
  }
  
  selectedBan(ban: BanMaeban, index: number) {
    this.calc.enviarBan(ban, index);
  }
  
  seleccionarBanco() {
    this.bancoSelec = this.calc.recibirBan();
    this.tiposdepago[this.filaselec].BANCO = this.bancoSelec.BAN_CODIGO;
    this.tiposdepago[this.filaselec].NOMBREBANCO = this.bancoSelec.BAN_BANCO;
    this.displayDialogBancos = false;
  }
  
  selectedBanCH(ban: CxcMaebancli, index: number) {
    this.calc.enviarBanCH(ban, index);
  }
  
  seleccionarBancoCH() {
    this.bancoSelecCH = this.calc.recibirBanCH();
    this.tiposdepago[this.filaselec].BANCO = this.bancoSelecCH.BANCLI_CODIGO;
    this.tiposdepago[this.filaselec].NOMBREBANCO = this.bancoSelecCH.BANCLI_NOMBRE;
    this.displayDialogBancos = false;
  }
  
  selectedBanTA(ban: CxcMaetarjeta, index: number) {
    this.calc.enviarBanTA(ban, index);
  }
  
  seleccionarBancoTA() {
    this.bancoSelecTA = this.calc.recibirBanTA();
    this.tiposdepago[this.filaselec].BANCO = this.bancoSelecTA.TAR_CODIGO;
    this.tiposdepago[this.filaselec].NOMBREBANCO = this.bancoSelecTA.TAR_DESCRIPCION;
    this.tiposdepago[this.filaselec].CODSRI = this.bancoSelecTA.TAR_CODSRI;
    this.displayDialogBancos = false;
  }
  
  showDialogCodsri(indi) {
    this.teclado.emit('cerrar');
    this.displayDialogCodsri = true;
    this.filaselec = indi;
    if (this.tiposdepago[indi].TIPO === 'Tarjeta') {
      this.servicePdv.getCodsri('FT').subscribe((respuesta: any[]) => {
        this.codssri = respuesta;
      });
    } else {
      this.servicePdv.getCodsri('CF').subscribe((respuesta: any[]) => {
        this.codssri = respuesta;
      });
    }
  }
  
  selectedCodsri(codsri: AnexMaecodsri, index: number) {
    this.calc.enviarCodsri(codsri, index);
  }
  
  seleccionarCodsri() {
    this.codsriSelec = this.calc.recibirCodsri();
    this.tiposdepago[this.filaselec].CODSRI = this.codsriSelec.CODSRI_CODIGO;
    this.displayDialogCodsri = false;
  }
  
  
  showDialogRetenciones(indi) {
    this.teclado.emit('cerrar');
    this.displayDialogRetencion = true;
    this.filaselec = indi;
    this.servicePdv.getRetenciones().subscribe((respuesta: any[]) => {
      this.retenciones = respuesta;
    });
  }
  
  selectedRet(ret: SaciMaeretencion, index: number) {
    this.calc.enviarRet(ret, index);
  }
  
  seleccionarRetencion() {
    this.retencionSelec = this.calc.recibirRet();
    this.tiposderetencion[this.filaselec].CODIGO = this.retencionSelec.RETENCION_CODIGO;
    this.tiposderetencion[this.filaselec].DESCRIPCION = this.retencionSelec.RETENCION_NOMBRE;
    this.tiposderetencion[this.filaselec].PORCENTAJE = this.retencionSelec.RETENCION_PORCENTAJE;
    this.displayDialogRetencion = false;
  }
  
  salir() {
    this.menupago = true;
    this.calc.editFocus(1, this.instanciaPri);
    this.bolcob.emit(false);
  }
  
  opcion(data, indi) {
    this.tiposdepago[indi].TIPO = data.value.name;
    if (this.tiposdepago[indi].TIPO === 'Cheque') {
      this.servicePdv.getFormaPago().subscribe((respuesta: any[]) => {
        for (const q of respuesta) {
          if (q.FORMAPAGO_TIPO === 'CH') {
            if (q.FORMAPAGO_CODIGOSRI !== undefined && q.FORMAPAGO_CODIGOSRI !== null && q.FORMAPAGO_CODIGOSRI !== '') {
              this.tiposdepago[indi].CODSRI = q.FORMAPAGO_CODIGOSRI;
            } else {
              this.tiposdepago[indi].CODSRI = '';
            }
          }
        }
      });
    }
    if (this.tiposdepago[indi].TIPO === 'Depósito') {
      this.servicePdv.getFormaPago().subscribe((respuesta: any[]) => {
        for (const q of respuesta) {
          if (q.FORMAPAGO_TIPO === 'DP') {
            if (q.FORMAPAGO_CODIGOSRI !== undefined && q.FORMAPAGO_CODIGOSRI !== null && q.FORMAPAGO_CODIGOSRI !== '') {
              this.tiposdepago[indi].CODSRI = q.FORMAPAGO_CODIGOSRI;
            } else {
              this.tiposdepago[indi].CODSRI = '';
            }
          }
        }
      });
    }
    if (this.tiposdepago[indi].TIPO === 'Tarjeta') {
      this.servicePdv.getFormaPago().subscribe((respuesta: any[]) => {
        for (const q of respuesta) {
          if (q.FORMAPAGO_TIPO === 'TA') {
            if (q.FORMAPAGO_CODIGOSRI !== undefined && q.FORMAPAGO_CODIGOSRI !== null && q.FORMAPAGO_CODIGOSRI !== '') {
              this.tiposdepago[indi].CODSRI = q.FORMAPAGO_CODIGOSRI;
            } else {
              this.tiposdepago[indi].CODSRI = '';
            }
          }
        }
      });
    }
    this.tiposdepago[indi].DISPOSITIVO = '';
  }
  
  opciondis(data, indi) {
    this.tiposdepago[indi].DISPOSITIVO = data.value.name;
  }
  
  imprimirFactura() {
    this.servicePdv.ejecutarVenSpFactura(this.inpfactura.ENCFAC_NUMERO).subscribe(() => {
      this.servicePdv.obtenerDatosFactura().subscribe((datos: any[]) => {
        const dd = this.serfact.construirFactura(datos);
        // pdfMake.createPdf(dd).open();
        const blobpdf = pdfMake.createPdf(dd);
        blobpdf.getDataUrl((blob) => {
          this.blobpdf = blob;
          this.menupago = true;
          this.imprimir = true;
          
        });
      });
    });
  }
  
  cobrarFactura() {
    this.calculaCambio();
    if (this.menupago === true) {
      this.messageService.add({
        key: 'cobrar',
        severity: 'error',
        summary: 'Forma de Pago',
        detail: 'Ingrese la forma de pago'
      });
      return;
    }
    
    if (this.cambio < 0) {
      this.messageService.add({
        key: 'cobrar',
        severity: 'error',
        summary: 'Forma de Pago',
        detail: 'Complete la forma de pago'
      });
      return;
    }
    
    let valores = 0;
    let importeefectivo = 0;
    for (const i of this.tiposderetencion) {
      if (i.BASE !== '' &&
        i.CODIGO !== '' &&
        i.DESCRIPCION !== '' &&
        i.NUMERO !== '' &&
        i.PORCENTAJE !== '' &&
        i.TOTALRETENIDO !== '') {
        valores = valores + Number(i.TOTALRETENIDO);
      }
    }
    for (const i of this.tiposdepago) {
      if (i.TIPO !== '' &&
        i.NUMERO !== '' &&
        i.BANCO !== '' &&
        i.NOMBREBANCO !== '' &&
        i.IMPORTE !== '' &&
        i.NROCUENTA !== '') {
        valores = valores + Number(i.IMPORTE);
      }
    }
    
    importeefectivo = Number(this.calc.entregarTotal()[this.instanciaPri - 1]) - valores;
    
    const cxctrncobro: CxcTrncobro = {};
    
    cxctrncobro.APLORG_CODIGO = 'FAC';
    cxctrncobro.ASI_NRO = '';
    cxctrncobro.BAN_CODIGO = '';
    cxctrncobro.CLI_CODIGO = this.inpcliente.CLI_CODIGO;
    cxctrncobro.COM_CODIGO = '01';
    cxctrncobro.TRNCOBRO_CONCEPTO = 'PAGO DIRECTO FAC. ' + this.inpfactura.ENCFAC_NUMERO;
    cxctrncobro.TRNCOBRO_FECHATRN = this.inpDate.toLocaleString('en-GB', {timeZone: 'UTC'}).substr(0, 10);
    cxctrncobro.TRNCOBRO_FECHAVENCE = '';
    cxctrncobro.TRNCOBRO_FLAG = 1;
    cxctrncobro.TRNCOBRO_IMPORTE = +Number(this.calc.entregarTotal()[this.instanciaPri - 1]);
    cxctrncobro.TRNCOBRO_NROCOMPROBANTE = '';
    cxctrncobro.TRNCOBRO_NRODOC = '';
    cxctrncobro.TRNCOBRO_REFERENCIAEXTERNA = '';
    cxctrncobro.TRNCOBRO_TIPODOC = 'CA';
    cxctrncobro.USU_IDENTIFICACION = this.usuario.identificacion;
    cxctrncobro.VEN_CODIGO = this.inpvendedor.VEN_CODIGO;
    cxctrncobro.TRNCOBRO_CAMBIO = +this.cambio.toFixed(2);
    cxctrncobro.TRNCOBRO_EFECTIVO = +this.efectivo;
    cxctrncobro.CON_CODIGO = null;
    cxctrncobro.TRNCOBRO_SALDOINI = 0;
    cxctrncobro.BOD_CODIGO = this.inpvendedor.BOD_CODIGO;
    this.trncobro.push(cxctrncobro);
    
    this.servicePdv.insertarTrnCobro(cxctrncobro).subscribe((respuesta: any[]) => {
      this.respuesta = respuesta;
      cxctrncobro.TRNCOBRO_NRODOC = this.respuesta['0'].TRNCOBRO_NRODOC;
      cxctrncobro.TRNCOBRO_NRODOC_2 = this.inpfactura.ENCFAC_NUMERO;
      cxctrncobro.TRNCOBRO_IMPORTE = +Number(this.calc.entregarTotal()[this.instanciaPri - 1]);
      cxctrncobro.COM_CODIGO = '01';
      cxctrncobro.CLI_CODIGO = this.inpcliente.CLI_CODIGO;
      cxctrncobro.CUOTAFAC = 'F';
      cxctrncobro.TRNCOBRO_NROCOMPROBANTE = this.respuesta['0'].TRNCOBRO_NROCOMPROBANTE;
      
      this.servicePdv.insertarTrnDocumento(cxctrncobro).subscribe(() => {
        for (const i of this.tiposderetencion) {
          if (i.BASE !== '' &&
            i.CODIGO !== '' &&
            i.DESCRIPCION !== '' &&
            i.NUMERO !== '' &&
            i.PORCENTAJE !== '' &&
            i.TOTALRETENIDO !== '') {
            const sacitrnretencion: SaciTrnretencion = {};
            sacitrnretencion.RETENCION_CODIGO = i.CODIGO;
            sacitrnretencion.TRNRETENCION_DESCRIPCION = i.DESCRIPCION;
            sacitrnretencion.TRNRETENCION_NRO = i.NUMERO;
            sacitrnretencion.TRNRETENCION_BASE = +i.BASE;
            sacitrnretencion.TRNRETENCION_PORCENTAJE = +i.PORCENTAJE;
            sacitrnretencion.TRNRETENCION_TOTALRETENIDO = +i.TOTALRETENIDO;
            sacitrnretencion.TRNCOBRO_NRODOC = this.respuesta['0'].TRNCOBRO_NRODOC;
            sacitrnretencion.COM_CODIGO = '01';
            sacitrnretencion.TRNRETENCION_ORIGEN = 'FAC';
            sacitrnretencion.CON_CODIGO = '1.01.01.01.01';
            sacitrnretencion.CEN_CODIGO = null;
            sacitrnretencion.CLI_CODIGO = this.inpcliente.CLI_CODIGO;
            sacitrnretencion.TRNRETENCION_REFERENCIA = this.inpfactura.ENCFAC_NUMERO;
            sacitrnretencion.TRNCOBRO_NROCOMPROBANTE = this.respuesta['0'].TRNCOBRO_NRODOC;
            
            this.servicePdv.insertarTrnRetencion(sacitrnretencion).subscribe(() => {
            });
          }
        }
        
        if (this.efectivo > 0) {
          const cxctrnpago: CxcTrnpago = {};
          const date: Date = new Date();
          let strdate = date.toLocaleString('en-GB', {timeZone: 'America/Bogota'});
          strdate = strdate.replace(' ', '').replace('/', '');
          strdate = strdate.replace('/', '').replace(':', '');
          strdate = strdate.replace(':', '').replace(',', '');
          
          cxctrnpago.TRNPAGO_TIPO = 'EF';
          cxctrnpago.CAJ_CODIGO = this.inpvendedor.CAJ_CODIGO;
          cxctrnpago.TRNPAGO_NUMERO = '1' + strdate;
          cxctrnpago.TRNPAGO_FECHA = this.inpDate.toLocaleString('en-GB', {timeZone: 'UTC'}).substr(0, 10);
          cxctrnpago.BANCLI_CODIGO = null;
          cxctrnpago.TRNPAGO_NROCTA = null;
          cxctrnpago.TRNPAGO_CONCEPTO = 'PAGO DIRECTO FAC.' + this.inpfactura.ENCFAC_NUMERO;
          cxctrnpago.TRNPAGO_IMPORTE = importeefectivo;
          cxctrnpago.TRNPAGO_NUMEROANT = '';
          cxctrnpago.CON_CODIGO = '1.01.01.01.01';
          cxctrnpago.TRNCOBRO_NRODOC = this.respuesta['0'].TRNCOBRO_NRODOC;
          cxctrnpago.COM_CODIGO = '01';
          cxctrnpago.TRNCOBRO_NROCOMPROBANTE = this.respuesta['0'].TRNCOBRO_NRODOC;
          cxctrnpago.CLI_CODIGO = this.inpcliente.CLI_CODIGO;
          cxctrnpago.TRNPAGO_CODSRI = '01';
          cxctrnpago.TRNCHEPOS_NUMDOC = '';
          cxctrnpago.TRNPAGO_TIPDIS = null;
          
          this.servicePdv.insertarFormaPago(cxctrnpago).subscribe(() => {
          });
          
        }
        
        for (const i of this.tiposdepago) {
          if (i.TIPO !== '' &&
            i.NUMERO !== '' &&
            i.BANCO !== '' &&
            i.NOMBREBANCO !== '' &&
            i.IMPORTE !== '' &&
            i.NROCUENTA !== '') {
            
            const cxctrnpago: CxcTrnpago = {};
            
            if (i.TIPO === 'Cheque') {
              cxctrnpago.TRNPAGO_TIPO = 'CH';
            }
            if (i.TIPO === 'Depósito') {
              cxctrnpago.TRNPAGO_TIPO = 'DP';
            }
            if (i.TIPO === 'Tarjeta') {
              cxctrnpago.TRNPAGO_TIPO = 'TA';
            }
            cxctrnpago.CAJ_CODIGO = this.inpvendedor.CAJ_CODIGO;
            cxctrnpago.TRNPAGO_NUMERO = i.NUMERO;
            cxctrnpago.TRNPAGO_FECHA = this.inpDate.toLocaleString('en-GB', {timeZone: 'UTC'}).substr(0, 10);
            cxctrnpago.BANCLI_CODIGO = i.BANCO;
            cxctrnpago.TRNPAGO_NROCTA = i.NROCUENTA;
            cxctrnpago.TRNPAGO_CONCEPTO = 'PAGO DIRECTO FAC.' + this.inpfactura.ENCFAC_NUMERO;
            cxctrnpago.TRNPAGO_IMPORTE = +i.IMPORTE;
            cxctrnpago.TRNPAGO_NUMEROANT = '';
            cxctrnpago.CON_CODIGO = '1.01.01.01.01';
            cxctrnpago.TRNCOBRO_NRODOC = this.respuesta['0'].TRNCOBRO_NRODOC;
            cxctrnpago.COM_CODIGO = '01';
            cxctrnpago.TRNCOBRO_NROCOMPROBANTE = this.respuesta['0'].TRNCOBRO_NRODOC;
            cxctrnpago.CLI_CODIGO = this.inpcliente.CLI_CODIGO;
            cxctrnpago.TRNPAGO_CODSRI = i.CODSRI;
            cxctrnpago.TRNCHEPOS_NUMDOC = '';
            
            if (i.TIPO === 'Tarjeta') {
              cxctrnpago.TRNPAGO_TIPDIS = i.DISPOSITIVO;
            } else {
              cxctrnpago.TRNPAGO_TIPDIS = null;
            }
            
            this.servicePdv.insertarFormaPago(cxctrnpago).subscribe(() => {
            });
          }
        }
        
        this.messageService.add({
          key: 'cobrar',
          severity: 'info',
          summary: 'Forma de pago',
          detail: 'Se guardo la forma de pago',
        });
        
        this.servicePdv.generarAsientoContable(this.inpfactura.ENCFAC_NUMERO, this.respuesta['0'].TRNCOBRO_NRODOC)
          .subscribe(() => {
            this.messageService.add({
              key: 'cobrar',
              severity: 'success',
              summary: 'Asiento Contable',
              detail: 'Asiento generado correctamente',
            });
            
            let asinro = '';
            this.servicePdv.retornarAsiento(this.inpfactura.ENCFAC_NUMERO).subscribe((respuasi: any[]) => {
              for (const i of respuasi) {
                asinro = i.ASI_NRO;
              }
              this.asiento.emit(asinro);
              this.pagada.emit(true);
            });
          });
        
        this.servicePdv.actualizarFormaPago(this.inpfactura.ENCFAC_NUMERO, 'T').subscribe(() => {
        });
        
        this.servicePdv.actualizarEstado(this.inpfactura.ENCFAC_NUMERO, 'G').subscribe(() => {
        });
        this.estado.emit('PAGADA');
        this.existeasiento = true;
      });
    });
  }
  
  
  reversarformapago() {
    if (this.trncobro.length === 0) {
      this.messageService.add({
        key: 'cobrar',
        severity: 'warn',
        summary: 'Forma de pago',
        detail: 'No existe forma de pago',
      });
    } else {
      this.servicePdv.eliminarTrnCobro(this.trncobro['0'].TRNCOBRO_NRODOC).subscribe(() => {
        this.existeasiento = false;
        this.menupago = true;
        this.totalapagar = Number(this.calc.entregarTotal()[this.instanciaPri - 1]);
        this.cambio = this.totalapagar * (-1);
        this.efectivo = 0;
        this.tiposdepago = [];
        this.tiposderetencion = [];
        this.trncobro = [];
        if (this.cambio < 0) {
          this.addlineafpnuevo();
          this.addlinearetnuevo();
        }
        this.messageService.add({
          key: 'cobrar',
          severity: 'info',
          summary: 'Forma de pago',
          detail: 'Forma de pago eliminada',
        });
        this.menupago = true;
        this.trncobro = [];
        this.trnpago = [];
        this.trnretencion = [];
        this.bolcob.emit(false);
        this.clnpago.emit(true);
        
      });
      
      this.servicePdv.actualizarFormaPago(this.inpfactura.ENCFAC_NUMERO, '').subscribe(() => {
      });
      
      this.servicePdv.actualizarEstado(this.inpfactura.ENCFAC_NUMERO, 'P').subscribe(() => {
      });
      
      if (this.inpfactura.ASI_NRO !== undefined && this.inpfactura.ASI_NRO !== null && this.inpfactura.ASI_NRO !== '') {
        this.servicePdv.anularAsiento(this.inpfactura.ASI_NRO).subscribe(() => {
          this.messageService.add({
            key: 'cobrar',
            severity: 'success',
            summary: 'Asiento Contable',
            detail: 'Asiento anulado',
          });
        });
      }
      
      /*this.servicePdv.eliminarDetasi(this.inpfactura.ENCFAC_NUMERO).subscribe((respuesta3: any[]) => {
        console.log(respuesta3);
        this.servicePdv.eliminarEncasi(this.inpfactura.ENCFAC_NUMERO).subscribe((respu: any[]) => {
          console.log(respu);
          this.messageService.add({
            key: 'cobrar',
            severity: 'success',
            summary: 'Asiento Contable',
            detail: 'Asiento eliminado',
          });
        });
      });*/
      
      this.servicePdv.limpiarAsiento(this.inpfactura.ENCFAC_NUMERO, '').subscribe(() => {
      });
      
      this.estado.emit('PENDIENTE');
      this.asiento.emit('');
      this.pagada.emit(false);
      
    }
  }
  
  moneyValidation(campo) {
    if (campo.indexOf(',') !== -1) {
      return campo;
    }
    
    let arreglo = [];
    let asase: string;
    const auxil = campo + '';
    const punto = auxil.split('.');
    while (punto[0].replace(/-/g, '').length > 3) {
      arreglo.push(punto[0].substr(punto[0].length - 3, punto[0].length));
      punto[0] = punto[0].substr(0, punto[0].length - 3);
    }
    
    asase = punto[0];
    arreglo = arreglo.reverse();
    for (const i of arreglo) {
      asase = asase + ',' + i;
    }
    
    if (punto[1] !== undefined) {
      if (punto[1].length > 0) {
        asase = asase + '.' + punto[1];
      }
    }
    
    return asase;
    
  }
  
  
  quitComma(valor) {
    return Number(valor.replace(/,/g, ''));
  }
  
  llenarcombotpagos(i) {
    this.fpago[i] = [{codigo: '1', name: 'Cheque'},
      {codigo: '2', name: 'Deposito'},
      {codigo: '3', name: 'Tarjeta'}];
  }
  
  llenarcombotdisp(i) {
    this.fdisp[i] = [{codigo: 'DF', name: 'DF'},
      {codigo: 'MD', name: 'MD'}];
  }
  
}
