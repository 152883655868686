//
// SACI WEB
// Rutina: Maestro de Clientes
// Propietario: Sandy Simbaña
// Modificado por: SS
// Fecha de creación: 2019
// Fecha de Modificación: 29-11-2019
//

import {Component, OnInit, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {CxcformaPago} from '../cxcinterfaces/cxcforma-pago';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {CxcomisionService} from '../cxcservicios/cxcomision.service';
import {CxcformaPagoService} from '../cxcservicios/cxcforma-pago.service';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Component({
  selector: 'app-cxcforma-pago',
  templateUrl: './cxcforma-pago.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class CxcformaPagoComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  actualizar: boolean;
  nuevoCampo: boolean;
  permisoFormPago: SegMaePermiso;
  codigoAux: any;
  arregloCons: any[];
  arregloSRI: any[];
  largo: string;
  formaPagoseleccionada: CxcformaPago;
  public frameworkComponents;
  rowStyle;
  desCodigo: boolean;
  defaultColDefCxcformaPago;
  rowSelection = 'multiple';
  columnDefsCxcformaPago = [
    {
      headerName: 'Tipo', field: 'FORMAPAGO_TIPO', cellEditor: 'cellPrueba', width: 200, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: false,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 4,
            tienecalculos: true
          }
        }
    },
    {
      headerName: 'Nombre', field: 'FORMAPAGO_NOMBRE', cellEditor: 'cellPrueba', width: 250,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: false,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 45,
            tienecalculos: true
          }
        }
    },
    {
      headerName: 'Caja', field: 'CAJ_CODIGO', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 10,
            opcionbuscar: true,
            tienecalculos: true
          }
        }
    },
    {
      headerName: 'CodigoSri', field: 'FORMAPAGO_CODIGOSRI', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 10,
            opcionbuscar: true
          }
        }
    },
  ];
  
  constructor(public formapagosService: CxcformaPagoService, private message: MessageService,
              private errorService: ErroresBaseDatosService, private confirmationService: ConfirmationService,
              private busqService: InvbusquedaService, private permisosService: PermisosService,
              private auditoriagral: AuditoriagralService, private init: NuevoComponentService) {
    this.agTable();
    this.formapagosService.formaPagoServicio = [];
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefCxcformaPago = {
      editable: true,
      width: 100,
      objeto: 'formaPago'
    };
  }
  
  ngOnInit() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.actualizar = false;
    this.nuevoCampo = false;
    this.codigoAux = 0;
    this.cargarFormaPago();
    this.buscarCaja();
    this.buscarSRI();
    
    this.permisosService.getListaPermisos('CXC', '1', '98').subscribe((res) => {
      this.permisoFormPago = res[0];
    });
    this.largo = '430';
  }
  
  cargarFormaPago() {
    this.formapagosService.getFormaPagoServicio().subscribe((res) => {
      if (res !== null) {
        let linea = 0;
        this.formapagosService.formaPagoServicio = [];
        res.map( fp => {
          linea += 1 ;
          let objFP: CxcformaPago;
          objFP = {};
          objFP.FORMAPAGO_TIPO = fp.FORMAPAGO_TIPO;
          objFP.FORMAPAGO_NOMBRE = fp.FORMAPAGO_NOMBRE;
          objFP.COM_CODIGO = fp.COM_CODIGO;
          objFP.CAJ_CODIGO = fp.CAJ_CODIGO;
          objFP.FORMAPAGO_H = fp.FORMAPAGO_H;
          objFP.FORMAPAGO_DEST = fp.FORMAPAGO_DEST;
          objFP.FORMAPAGO_CODIGOSRI = fp.FORMAPAGO_CODIGOSRI;
          objFP.LINEA = String(linea);
  
          this.formapagosService.formaPagoServicio.push(objFP);
  
  
        });
        this.aggrid.refreshaggrid(this.formapagosService.formaPagoServicio, this.defaultColDefCxcformaPago.objeto);
      }
    });
  }
  
  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      if (this.permisoFormPago.PERINSERCION === 1) {
        this.btnNuevo = true;
        this.btnGuardar = false;
        this.btnBorrar = false;
        this.btnRegresar = false;
        this.nuevoCampo = true;
        this.aggrid.isEditable(true);
        
        const linea = this.formapagosService.formaPagoServicio.length;
        this.formaPagoseleccionada = {
          FORMAPAGO_TIPO: '',
          FORMAPAGO_NOMBRE: '',
          COM_CODIGO: '01',
          CAJ_CODIGO: '',
          FORMAPAGO_H: '',
          FORMAPAGO_DEST: '',
          FORMAPAGO_CODIGOSRI: '',
          LINEA: String(linea + 1)
        };
        this.formapagosService.formaPagoServicio.push(this.formaPagoseleccionada);
        this.aggrid.refreshaggrid(this.formapagosService.formaPagoServicio, this.defaultColDefCxcformaPago.objeto);
      } else if (this.permisoFormPago.PERINSERCION === 0) {
        this.message.add({
          key: 'formaPago',
          severity: 'error',
          summary: 'Inserción de Forma de Pago',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    }
    if (valor === 'Guardar') {
      if (this.permisoFormPago.PERINSERCION === 1) {
        this.btnNuevo = false;
        this.btnGuardar = true;
        this.btnBorrar = false;
        this.btnRegresar = true;
        
        this.guardarFP();
        
        /*
        this.formapagosService.encontrarRegistro('FORMAPAGO_TIPO', 'CXC_MAEFORMAPAGO',
          'FORMAPAGO_TIPO =\'' + this.formaPagoseleccionada.FORMAPAGO_TIPO + '\'').subscribe(existe => {
          if (existe !== null) {
            let coincidencias: number;
            coincidencias = 0;
            this.arregloCons.map((con) => {
              if (this.formaPagoseleccionada.CAJ_CODIGO === con.CAJ_CODIGO) {
                coincidencias++;
              }
            });
            let coincidencias2: number;
            coincidencias2 = 0;
            this.arregloSRI.map((con) => {
              if (this.formaPagoseleccionada.FORMAPAGO_CODIGOSRI === con.CODSRI_CODIGO) {
                coincidencias2++;
              }
            });
            if (coincidencias !== 0) {
              if (this.formaPagoseleccionada.FORMAPAGO_CODIGOSRI === '' || coincidencias2 !== 0) {
                this.formapagosService.actualizarFormaPago(this.formaPagoseleccionada, this.codigoAux).subscribe(() => {
                  this.message.add({
                    key: 'formaPago',
                    severity: 'success',
                    summary: 'Actualización exitosa',
                    detail: 'Se actualizó la Forma de pago correctamente'
                  });
                  this.auditoriagral.registrarAuditoria('CXC_MAEFORMAPAGO', String(this.formaPagoseleccionada.FORMAPAGO_TIPO), 'A',
                    '', '01', '', '', '', '').subscribe(() => {
                  });
                  this.aggrid.isEditable(false);
                }, error1 => {
                  let mensaje: string;
                  mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                  this.message.add({
                    key: 'formaPago',
                    severity: 'error',
                    summary: 'Actualización de la Forma de Pago',
                    detail: mensaje
                  });
                  this.btnNuevo = false;
                  this.btnGuardar = true;
                  this.btnBorrar = false;
                  this.btnRegresar = true;
                  this.formapagosService.getFormaPagoServicio().subscribe((res) => {
                    this.formapagosService.formaPagoServicio = res;
                    this.aggrid.refreshaggrid(this.formapagosService.formaPagoServicio, this.defaultColDefCxcformaPago.objeto);
                  });
                });
              }
            }
            if (coincidencias !== 0 && this.formaPagoseleccionada.FORMAPAGO_CODIGOSRI !== '') {
              if (coincidencias2 === 0) {
                this.message.add({
                  key: 'formaPago',
                  severity: 'error',
                  summary: 'Error en Codigo SRI',
                  detail: 'El valor de Codigo SRI ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
                });
                this.formaPagoseleccionada.FORMAPAGO_CODIGOSRI = '';
                this.aggrid.refreshaggrid(this.formapagosService.formaPagoServicio, this.defaultColDefCxcformaPago.objeto);
                
              }
            } else if (coincidencias === 0) {
              if (coincidencias2 === 0 || coincidencias2 !== 0 || this.formaPagoseleccionada.FORMAPAGO_CODIGOSRI === '') {
                this.message.add({
                  key: 'formaPago',
                  severity: 'error',
                  summary: 'Error en Caja',
                  detail: 'El valor de caja ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
                });
                this.formaPagoseleccionada.CAJ_CODIGO = '';
                this.aggrid.refreshaggrid(this.formapagosService.formaPagoServicio, this.defaultColDefCxcformaPago.objeto);
              }
            }
          } else if (this.actualizar === false) {
            let coincidencias: number;
            coincidencias = 0;
            this.arregloCons.map((con) => {
              if (this.formaPagoseleccionada.CAJ_CODIGO === con.CAJ_CODIGO) {
                coincidencias++;
              }
            });
            let coincidencias2: number;
            coincidencias2 = 0;
            this.arregloSRI.map((con) => {
              if (this.formaPagoseleccionada.FORMAPAGO_CODIGOSRI === con.CODSRI_CODIGO) {
                coincidencias2++;
              }
            });
            if (coincidencias !== 0) {
              if (this.formaPagoseleccionada.FORMAPAGO_CODIGOSRI === '' || coincidencias2 !== 0) {
                this.formapagosService.insertarFormaPago(this.formaPagoseleccionada).subscribe(() => {
                  this.message.add({
                    key: 'formaPago',
                    severity: 'success',
                    summary: 'Inserción exitosa',
                    detail: 'Se insertó Forma de pago correctamente'
                  });
                  this.auditoriagral.registrarAuditoria('CXC_MAEFORMAPAGO', String(this.formaPagoseleccionada.FORMAPAGO_TIPO), 'I',
                    '', '01', '', '', '', '').subscribe(() => {
                  });
                  this.aggrid.isEditable(false);
                  this.nuevoCampo = false;
                }, error1 => {
                  let mensaje: string;
                  mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                  this.message.add({
                    key: 'formaPago',
                    severity: 'error',
                    summary: 'Inserción de Forma de Pago',
                    detail: mensaje
                  });
                  this.btnNuevo = false;
                  this.btnGuardar = true;
                  this.btnBorrar = false;
                  this.btnRegresar = true;
                  this.formapagosService.getFormaPagoServicio().subscribe((res) => {
                    this.formapagosService.formaPagoServicio = res;
                    this.aggrid.refreshaggrid(this.formapagosService.formaPagoServicio, this.defaultColDefCxcformaPago.objeto);
                  });
                });
              }
            }
            if (coincidencias !== 0 && this.formaPagoseleccionada.FORMAPAGO_CODIGOSRI !== '') {
              if (coincidencias2 === 0) {
                this.message.add({
                  key: 'formaPago',
                  severity: 'error',
                  summary: 'Error en Codigo SRI',
                  detail: 'El valor de Codigo SRI ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
                });
                this.formaPagoseleccionada.FORMAPAGO_CODIGOSRI = '';
                this.aggrid.refreshaggrid(this.formapagosService.formaPagoServicio, this.defaultColDefCxcformaPago.objeto);
                
              }
            } else if (coincidencias === 0) {
              if (coincidencias2 === 0 || coincidencias2 !== 0 || this.formaPagoseleccionada.FORMAPAGO_CODIGOSRI === '') {
                this.message.add({
                  key: 'formaPago',
                  severity: 'error',
                  summary: 'Error en el valor de Caja',
                  detail: 'El valor de caja ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
                });
                this.formaPagoseleccionada.CAJ_CODIGO = '';
                this.aggrid.refreshaggrid(this.formapagosService.formaPagoServicio, this.defaultColDefCxcformaPago.objeto);
              }
            }
          }
        });*/
      } else if (this.permisoFormPago.PERACTUALIZACION === 0) {
        this.message.add({
          key: 'formaPago',
          severity: 'error',
          summary: 'Actualización de Forma de Pago',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }
    }
    if (valor === 'Borrar') {
      if (this.permisoFormPago.PERELIMACION === 1) {
        this.btnNuevo = false;
        this.aggrid.isEditable(this.btnNuevo);
        if (this.formaPagoseleccionada === undefined) {
          this.message.add({
            key: 'formaPago',
            severity: 'error',
            summary: 'Eliminacion de Forma de Pago',
            detail: 'Seleccion Incorrecta, no se ha seleccionado una forma de pago'
          });
        } else {
          this.confirmarFormaPago();
        }
      } else if (this.permisoFormPago.PERELIMACION === 0) {
        this.message.add({
          key: 'formaPago',
          severity: 'error',
          summary: 'Eliminación de Forma de Pago',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
      }
    }
    if (valor === 'Cancelar') {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.aggrid.isEditable(false);
      this.cargarFormaPago();
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
  guardarFP() {
    if (this.formaPagoseleccionada.FORMAPAGO_TIPO === '' || this.formaPagoseleccionada.FORMAPAGO_NOMBRE === '') {
      this.message.add({
        key: 'formaPago',
        severity: 'error',
        summary: 'Forma de Pago',
        detail: 'Los campos tipo y nombre de la forma de pago son obligatorios'
      });
      this.aggrid.gridApi.setFocusedCell(Number(this.formaPagoseleccionada.LINEA) - 1 , 'FORMAPAGO_TIPO');
    } else {
      if (this.existeCaja(this.formaPagoseleccionada.CAJ_CODIGO) === true ) {
        if (this.existeCodSri(this.formaPagoseleccionada.FORMAPAGO_CODIGOSRI) === true ) {
          if (this.nuevoCampo === true ) {
            this.formapagosService.insertarFormaPago(this.formaPagoseleccionada).subscribe(() => {
              this.message.add({
                key: 'formaPago',
                severity: 'success',
                summary: 'Inserción exitosa',
                detail: 'Se insertó Forma de pago correctamente'
              });
              this.auditoriagral.registrarAuditoria('CXC_MAEFORMAPAGO', String(this.formaPagoseleccionada.FORMAPAGO_TIPO), 'I',
                '', '01', '', '', '', '').subscribe(() => {
              });
             // this.aggrid.isEditable(false);
              this.nuevoCampo = false;

              this.aggrid.gridApi.setFocusedCell(Number(this.formaPagoseleccionada.LINEA) - 1 , 'CAJ_CODIGO');
  
  
  
  
            }, error1 => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.message.add({
                key: 'formaPago',
                severity: 'error',
                summary: 'Inserción de Forma de Pago',
                detail: mensaje
              });
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
              this.cargarFormaPago();
              this.nuevoCampo = false;
  
            });
          }  else if (this.nuevoCampo === false) {
            this.formapagosService.actualizarFormaPago(this.formaPagoseleccionada, this.codigoAux).subscribe(() => {
              this.message.add({
                key: 'formaPago',
                severity: 'success',
                summary: 'Actualización exitosa',
                detail: 'Se actualizó la Forma de pago correctamente'
              });
              this.auditoriagral.registrarAuditoria('CXC_MAEFORMAPAGO', String(this.formaPagoseleccionada.FORMAPAGO_TIPO), 'A',
                '', '01', '', '', '', '').subscribe(() => {
              });
              this.aggrid.isEditable(false);
  
              this.aggrid.gridApi.setFocusedCell(Number(this.formaPagoseleccionada.LINEA) - 1 , 'CAJ_CODIGO');
  
  
            }, error1 => {
              /* let mensaje: string;
              mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9)); */
              this.message.add({
                key: 'formaPago',
                severity: 'error',
                summary: 'Actualización de la Forma de Pago',
                detail: error1.error
              });
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
              this.cargarFormaPago();
            });
          }
    
          } else {
          this.message.add({
            key: 'formaPago',
            severity: 'error',
            summary: 'Error en Codigo SRI',
            detail: 'El valor de Codigo SRI ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
          });
        }
      } else {
        this.message.add({
          key: 'formaPago',
          severity: 'error',
          summary: 'Error en el valor de Caja',
          detail: 'El valor de caja ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
        });
      }
    
    }
    
    
    
  }
  
  existeCaja(caja): boolean {
    let existe = false ;
    if (caja !== '') {
      if (caja !== null) {
        this.arregloCons.map((con) => {
          if (caja === con.CAJ_CODIGO) {
            existe = true;
          }
        });
      } else {
        existe = true;
      }
    } else if (caja === '') {
      existe = true ;
    }
    return existe;
  }
  
  existeCodSri(codSRI): boolean {
    let existe = false ;
    if (codSRI !== '') {
      if (codSRI !== null) {
        this.arregloSRI.map((con) => {
          if (codSRI === con.CODSRI_CODIGO) {
            existe = true;
          }
        });
      } else {
        existe = true;
      }
    } else if (codSRI === '') {
      existe = true ;
    }
    return existe;
  }
  
  
  
  seleccionFormaPago(valor) {
    if (valor.object !== null) {
      this.formaPagoseleccionada = valor.object;
      if (this.nuevoCampo === true) {
        this.actualizar = false;
      } else if (this.nuevoCampo === false) {
        if (this.formaPagoseleccionada !== undefined) {
          this.actualizar = true;
          this.codigoAux = this.formaPagoseleccionada.FORMAPAGO_TIPO;
        }
      }
    }
  }
  
  
  cambio(event) {
    this.btnGuardar = false;
    this.btnRegresar = false;
  }
  
  confirmarFormaPago() {
    this.confirmationService.confirm({
      message: '¿Esta seguro que desea eliminar esta Forma de Pago?',
      header: 'Eliminar Forma de Pago',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarFormaPago',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarFormaPago();
      },
      reject: () => {
      }
    });
  }
  
  eliminarFormaPago() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.formapagosService.eliminarFormaPago(this.formaPagoseleccionada).subscribe(() => {
      this.message.add({
        key: 'formaPago',
        severity: 'success',
        summary: 'Eliminación exitosa',
        detail: 'Se eliminó la Forma de Pago correctamente'
      });
      this.auditoriagral.registrarAuditoria('CXC_MAEFORMAPAGO', String(this.formaPagoseleccionada.FORMAPAGO_TIPO), 'E',
        '', '01', '', '', '', '').subscribe(() => {
      });
      this.formapagosService.formaPagoServicio = this.formapagosService.formaPagoServicio.filter((val, j) => j !==
        this.formapagosService.formaPagoServicio.indexOf(this.formaPagoseleccionada));
      this.aggrid.refreshaggrid(this.formapagosService.formaPagoServicio, this.defaultColDefCxcformaPago.objeto);
    });
  }
  
  buscarCaja() {
    this.busqService.busquedaBanMaeCaj().subscribe((res: any[]) => {
      this.arregloCons = res;
    });
  }
  
  buscarSRI() {
    this.busqService.busquedaAnexMaeCodSri14().subscribe((res: any[]) => {
      this.arregloSRI = res;
    });
  }
  
  controlGuardar(valor) {
    this.manejarSenales('Guardar');
  }
  
  
}
