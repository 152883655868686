import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { NomproccontabService } from '../nomservicios/nomproccontab.service';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import { AggridBanComponent } from 'src/app/ban/aggrid-ban/aggrid-ban.component';

import { NomProcContab } from '../nominterfaces/nomproccontab';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { MessageService } from 'primeng';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { Usuario } from 'src/app/usuario';
import { UtilitariosService } from '../../advantage/advantageservices/utilitarios.service';//CDPJ
@Component({
  selector: 'app-nomproccontab',
  templateUrl: './nomproccontab.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NomproccontabComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  fechaIni;
  fechaFin;

  defaultColDefRolPagos;
  public frameworkComponents;

  rowStyle;
  largo = '258';
  rowSelection = 'multiple';
  rolesPago: NomProcContab [] = [];

  nomProcContas: NomProcContab [];

  radioButton= 'val1';
  valSelect = 'valSelect'
  spin:boolean;//CDPJ
  fechaValidaPeriodo;
  columnDefRolPagos = [
    {
      headerName: 'S', field: 'CONTABILIZAR',  cellRendererFramework: AggridBanComponent,
       editable: (params) => {
        return false;
      }, cellEditor: 'cellPrueba', width: 50, onCellClicked: (params) => {
        this.seleccionItem(params.data);  
      }
    },
    {
      headerName: 'Período', field: 'PERIODO_CODIGO', editable: false, cellEditor: 'cellPrueba', width: 100,  cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: 'Fecha', field: 'fecha',editable: false, cellEditor: 'cellPrueba', width: 150,  cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: 'Tipo rol', field: 'nomRol', editable: false,cellEditor: 'cellPrueba', width: 150, cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 600,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: 'Asiento', field: 'ASI_NRO', editable: false, cellEditor: 'cellPrueba', width: 150, cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 600,
            tienecalculos: true,
          }
        }
    },
  ];
  
  constructor(private init: NuevoComponentService,
    private contabSrv: NomproccontabService,
    private messageService: MessageService,
    public usuario: Usuario,
    private errorService: ErroresBaseDatosService, private auditoriagral: AuditoriagralService, private utilitariosService: UtilitariosService,)
  {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefRolPagos = {
      editable: true,
      width: 130,
      objeto: 'rolPagos'
    };

    this.rowStyle = (params) => {
      
      if (params.data.ASI_NRO === null) {
        return {background: 'rgb(255,255,128)'};
      }


    }
  }


  seleccionItem(param: NomProcContab){
    //param.CONTABILIZAR = true;
    
  }

  ngOnInit(): void {
    this.fechaIni = new Date();
    this.fechaFin = new Date();
    this.spin=false;//CDPJ
  }

  consultarRoles(){
    if( this.radioButton === 'val1' ){
      this.getRoles('1');
    } else if ( this.radioButton === 'val2' ){
      this.getRoles('2');
    }
  }

  async getRoles(opcion){
    await this.contabSrv.getRoles(opcion, this.convertDate(this.fechaIni), this.convertDate( this.fechaFin ) ).then( r => {
  
      //this.nomProcContas = [...r]
      this.rolesPago = r;
      if( this.rolesPago !== null ) { 
        this.setTipoRol();
      }
      
    }).catch( e => {
      console.error(e);
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    } );
  }


  async setTipoRol(){
    for(let rolConta of this.rolesPago ) {
      await this.contabSrv.getTipoRol(rolConta.TIPROL_CODIGO).then( res => {

        if( res !==  null ){
          if( res.length > 0 ){
            rolConta.nomRol = res[0].TIPROL_NOMBRE;
            rolConta.fecha = this.convertDate(rolConta.PERIODO_FECFIN);
          }
        }
        
        
        this.aggrid.refreshaggrid(this.rolesPago, this.defaultColDefRolPagos.objeto);
      } ).catch( e => {
        console.error(e);
        let mensaje: string;
        mensaje = this.errorService.generarMensajeError(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      } );
    }
    
  }

  convertDate(date: string) {
    const fecha = new Date( date );

    let month = '' + (fecha.getMonth() + 1);
    let day = '' + fecha.getDate();
    let year = fecha.getFullYear();

    if( month.length < 2 ) { month = '0' + month }
    if( day.length < 2 ) { day = '0' + day }

    return `${day}/${month}/${year}`;


  }
  selectTodos(event){
    if( event.checked ){
      this.rolesPago.map( rol => {
        rol.CONTABILIZAR = true;
      } )
    } else {
      this.rolesPago.map( rol => {
        rol.CONTABILIZAR = false;
      } )
    }
    this.aggrid.refreshaggrid(this.rolesPago, this.defaultColDefRolPagos.objeto);
  }

  async manejarSenales(valor){

    //periodo
if(valor !=='Salir'){
    console.log(this.fechaIni);
    let dia = new Date().getDate();
    let mes = new Date().getMonth();
    let anio = new Date().getFullYear();
    // Verifica el periodo contable
    try {
      dia = this.fechaIni.getDate();
      mes = this.fechaIni.getMonth();
      anio = this.fechaIni.getFullYear();
    } catch (e) {
      const tmpfecha = this.fechaIni.substr(0, 10);
      const splfec = tmpfecha.split('/');
      const fecha = new Date(splfec[2], splfec[1] - 1, splfec[0]);
      dia = fecha.getDate();
      mes = fecha.getMonth();
      anio = fecha.getFullYear();
    }

    if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
      this.mensajeAlerta('error', 'Error', 'Error, fecha debe de estar dentro del período contable');
      
      return;
    }
}







    switch (valor) {
      case 'Salir':
       this.init.tabs.splice(this.init.tabindex, 1);
      break;
      case 'Visto':
        this.seleccionarConta();
      break;
      default:
      break;
    }
  }

 async  seleccionarConta(){
  this.spin=true;//CDPJ
   let huboError = false;
    for( let  rol of  this.rolesPago)  {
      if (rol.CONTABILIZAR){
        if( await this.contabilizar(rol) ) {

          if( await !this.setAsientoNulo(rol)){
            huboError = true;
          }
        }else {
          huboError= true;
        }
      }
      
    }   
    if( !huboError ){
      this.mensajeAlerta('success', 'Éxito', `Proceso exitoso`);
      this.spin=false;//CDPJ
    }
  }

  async contabilizar(rol: NomProcContab){
    return await this.contabSrv.contabilizar(rol.PERIODO_CODIGO, rol.TIPROL_CODIGO).then( async () => {
       await this.auditoriagral.registrarAuditoria('NOM_ENCROLINDI', `CONTABILIZACION PERIODO: ${rol.PERIODO_CODIGO}`, 'A',
      this.usuario.identificacion, '01', '', '', '', '');
      return true;
    } ).catch( e => {
      console.error(e.error);
      let mensaje: string;
      mensaje = e.error;
      try{
        mensaje = mensaje.split("ORA-06512")[0];
      }catch{
        mensaje = 'Hubo un problema contabilizando los roles'
      }
      this.mensajeAlerta('error', 'Error', mensaje);
      this.spin=false;//CDPJ
      return false;
    } )
  }

  async setAsientoNulo(rol: NomProcContab){
    return await this.contabSrv.buscarAsiento(rol.PERIODO_CODIGO, rol.TIPROL_CODIGO).then( res => {
     
      let rolAsi = this.rolesPago.find( rolp => (rol.PERIODO_CODIGO === rolp.PERIODO_CODIGO && rol.TIPROL_CODIGO === rolp.TIPROL_CODIGO )  );
      if( rolAsi ){
        rolAsi.ASI_NRO = res[0].ASI_NRO;
      }
      this.aggrid.refreshaggrid(this.rolesPago, this.defaultColDefRolPagos.objeto);
      return true;
    } ). catch( e => {
      console.error(e);
      
      this.mensajeAlerta('error', 'Error', 'Hubo un problema buscando el asiento');
      this.spin=false;//CDPJ
      return false;
    } )
  }

  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'contaRol',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
  }

}
