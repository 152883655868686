import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {PrmtCnfgImpr, Segmaereppantalla} from '../seginterfaces/segmaereppantalla';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';



@Injectable({
  providedIn: 'root'
})
export class SegmaereppantallaService {
  
  private way: string;
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  } 
  
  consultarGenCondicion(opcion: string, codigo?: string) {
    let cod = '';
    if ( codigo ) { cod = codigo; }
    return this.http.post<any[]>(this.way + '/seg/reppantalla/662fcf41d766300', {
      elementos: {
        p_codigo: cod, // varchar,
        p_empresa: '01', // varchar,
        p_opcion: opcion , // varchar,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  
  consultarParametrosCondicion(cnfgimprCodigo: string) {
    
    return this.http.post<any[]>(this.way + '/seg/reppantalla/662f956d5666300', {
      elementos: {
        p_codigo: cnfgimprCodigo,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  guardarParametrosCondicion( parametro: PrmtCnfgImpr ) {
    return this.http.post<any[]>(this.way + '/seg/reppantalla/10f2b6dcab7666000', {
      elementos: {
        p_cnfgimpr_codigo: parametro.CNFGIMPR_CODIGO,
        p_com_codigo: parametro.COM_CODIGO,
        p_prmtcnfgimpr_codigo: parametro.PRMTCNFGIMPR_CODIGO,
        p_prmtcnfgimpr_origen: parametro.PRMTCNFGIMPR_ORIGEN,
        p_prmtcnfgimpr_tipo: parametro.PRMTCNFGIMPR_TIPO,
        p_prmtcnfgimpr_valor: parametro.PRMTCNFGIMPR_VALOR,
        p_prmtcnfgimpr_orden: parametro.PRMTCNFGIMPR_ORDEN,
        p_prmtcnfgimpr_tipodato: parametro.PRMTCNFGIMPR_TIPODATO,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarParametrosCondicion( parametro: PrmtCnfgImpr ) {
    return this.http.post<any[]>(this.way + '/seg/reppantalla/eacad956eccc6000', {
      elementos: {
        p_cnfgimpr_codigo: parametro.CNFGIMPR_CODIGO,
        p_com_codigo: parametro.COM_CODIGO,
        p_prmtcnfgimpr_codigo: parametro.PRMTCNFGIMPR_CODIGO,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  guardarDatosGenCondicion(segMaeRepPantalla: Segmaereppantalla) {
    return this.http.post<any[]>(this.way + '/seg/reppantalla/8795b6abb075d80', {
      elementos: {
        p_cnfgimpr_codigo:        segMaeRepPantalla.CNFGIMPR_CODIGO,
        p_com_codigo:             '01',
        p_cnfgimpr_forma:         segMaeRepPantalla.CNFGIMPR_FORMA,
        p_cnfgimpr_reporte:       segMaeRepPantalla.CNFGIMPR_REPORTE,
        p_cnfgimpr_nombre:        segMaeRepPantalla.CNFGIMPR_NOMBRE,
        p_cnfgimpr_sp:            segMaeRepPantalla.CNFGIMPR_SP,
        p_cnfgimpr_tabla:         segMaeRepPantalla.CNFGIMPR_TABLA,
        p_cnfgimpr_localizacion:  segMaeRepPantalla.CNFGIMPR_LOCALIZACION,
        p_cnfgimpr_parametro:     segMaeRepPantalla.CNFGIMPR_PARAMETRO,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarDatosGenCondicion(segMaeRepPantalla: Segmaereppantalla) {
    return this.http.post<any[]>(this.way + '/seg/reppantalla/eacacd5760ebb000', {
      elementos: {
        p_cnfgimpr_codigo:        segMaeRepPantalla.CNFGIMPR_CODIGO,
        p_com_codigo:             '01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  busquedaTabla(strCampo, strTabla, strCondicion) {
    return this.http.post(this.way + '/seg/reppantalla/edd94e63ef095800', {
      elementos: {},
    }, this.confIniciales.httpOptions()
    ).toPromise();
  }
}

