import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'hammerjs';
/*
import { HttpClient } from '@angular/common/http';

var http: HttpClient;
var titulo: '';

http.get('./assets/config.json').subscribe(respuesta => {
  let aux: any = respuesta;  
  titulo = aux.titulo;    
});
*/
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
