import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Nommaetablitaret} from '../nominterfaces/nommaetablitaret';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
@Injectable({
  providedIn: 'root'
})
export class NommaetablitaretService {
  public way: string;
  public nommaeTablitaRet: Nommaetablitaret[] = [];
  constructor(
    private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService
  ) {
    this.way = sessionStorage.getItem('way');
  }
  
  getCodigoActual(){
    let strSentencia = 'SELECT MAX(MAETABLITARET_CODIGO)+1 FROM nom_maetablitaret';
    
    return this.http.post<any[]>(this.way + '/nom/tablitaret/c2facc354cef980', {
        elementos: {}
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  ListaAnios() {
    let strSentencia = 'select distinct maetablitaret_anio from nom_maetablitaret';
    
    return this.http.post<any[]>(this.way + '/nom/tablitaret/617dacb9d55e58', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  cargarTablita(anio: string) {
    let strSentencia = 'select * from nom_maetablitaret ' +
      'where maetablitaret_anio = \'' + anio + '\' order by maetablitaret_codigo';
    
    return this.http.post<any[]>(this.way + '/nom/tablitaret/62b705754bacbc0', {
        elementos: {
          MAETABLITARET_ANIO: anio
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  insertar( nommaeTablitaRet: Nommaetablitaret ) {
    console.log(nommaeTablitaRet);
    let strSentencia = 'insert into nom_maetablitaret ( MAETABLITARET_CODIGO, COM_CODIGO,' +
      ' MAETABLITARET_FRACBASICA, MAETABLITARET_MAXFRACBSC, MAETABLITARET_IMPFRCBSC, MAETABLITARET_EXCEDENTE, MAETABLITARET_ANIO )' +
      'VALUES (' +
      '\'' + nommaeTablitaRet.MAETABLITARET_CODIGO      + '\',' +
      '\'' + nommaeTablitaRet.COM_CODIGO                + '\',' +
      '\'' + nommaeTablitaRet.MAETABLITARET_FRACBASICA  + '\',' +
      '\'' + nommaeTablitaRet.MAETABLITARET_MAXFRACBSC  + '\',' +
      '\'' + nommaeTablitaRet.MAETABLITARET_IMPFRCBSC   + '\',' +
      '\'' + nommaeTablitaRet.MAETABLITARET_EXCEDENTE   + '\',' +
      '\'' + nommaeTablitaRet.MAETABLITARET_ANIO        + '\')';
    
    console.log(strSentencia);
    return this.http.post<any[]>(this.way + '/nom/tablitaret/95f8edf54bacb80', {
        elementos: {
          MAETABLITARET_CODIGO: nommaeTablitaRet.MAETABLITARET_CODIGO,
          COM_CODIGO: nommaeTablitaRet.COM_CODIGO,
          MAETABLITARET_FRACBASICA: nommaeTablitaRet.MAETABLITARET_FRACBASICA,
          MAETABLITARET_MAXFRACBSC: nommaeTablitaRet.MAETABLITARET_MAXFRACBSC,
          MAETABLITARET_IMPFRCBSC: nommaeTablitaRet.MAETABLITARET_IMPFRCBSC,
          MAETABLITARET_EXCEDENTE: nommaeTablitaRet.MAETABLITARET_EXCEDENTE,
          MAETABLITARET_ANIO: nommaeTablitaRet.MAETABLITARET_ANIO
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  actualizar( nommaeTablitaRet: Nommaetablitaret ) {
    
    let strSentencia = 'update nom_maetablitaret  ' +
      'set MAETABLITARET_FRACBASICA = \'' + nommaeTablitaRet.MAETABLITARET_FRACBASICA + '\',' +
      ' MAETABLITARET_MAXFRACBSC = \'' + nommaeTablitaRet.MAETABLITARET_MAXFRACBSC + '\',' +
      ' MAETABLITARET_IMPFRCBSC = \'' + nommaeTablitaRet.MAETABLITARET_IMPFRCBSC + '\',' +
      ' MAETABLITARET_EXCEDENTE = \'' + nommaeTablitaRet.MAETABLITARET_EXCEDENTE + '\' ' +
      'where MAETABLITARET_CODIGO = \'' + nommaeTablitaRet.MAETABLITARET_CODIGO + '\'';
    
    console.log( strSentencia );
    return this.http.post<any[]>(this.way + '/nom/tablitaret/533be5565d52ec0', {
        elementos: {
          MAETABLITARET_FRACBASICA: nommaeTablitaRet.MAETABLITARET_FRACBASICA,
          MAETABLITARET_MAXFRACBSC: nommaeTablitaRet.MAETABLITARET_MAXFRACBSC,
          MAETABLITARET_IMPFRCBSC: nommaeTablitaRet.MAETABLITARET_IMPFRCBSC,
          MAETABLITARET_EXCEDENTE: nommaeTablitaRet.MAETABLITARET_EXCEDENTE,
          MAETABLITARET_CODIGO: nommaeTablitaRet.MAETABLITARET_CODIGO
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  borrar(  nommaeTablitaRet: Nommaetablitaret ) {
    let strSentencia = 'delete from nom_maetablitaret  ' +
      'where MAETABLITARET_CODIGO = ' + nommaeTablitaRet.MAETABLITARET_CODIGO  + '';
    
    return this.http.post<any[]>(this.way + '/nom/tablitaret/eacad2bf54bac800', {
        elementos: {
          MAETABLITARET_CODIGO: nommaeTablitaRet.MAETABLITARET_CODIGO
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
}
