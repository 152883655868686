<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="nomutilidades"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="nomutilidadesConf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 100%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
                         [botonBorrar]="btnBorrar"
                         [barraBotonesDesplaz]="true" [botonVisto]="false" [botonUtiProcesar]="true" [barraCashmng]="true" [barraBotones3Alt]="false"
						 (signal)="manejarSenales($event)" [barraBotones3]="true" [barraConEncasil]="false"
						  [botonBuscar]="btnBuscar" [botonBinocular]="true" [botonAlmacenardoc] = "true">
		</app-invmaebarra>
	</div>
</div>
<div class="divgrups" id="divPrincipal">
    <div class="p-grid">
        <div class="p-col-1">
            <span>Num:</span>
        </div>
        <div class="p-col-1">
            <input type="text" pInputText disabled  [(ngModel)]="lblNumLiqUti" style="color: mediumblue; text-align: center; font-weight: bold">
        </div>
        <div class="p-col-3"></div>
        <div class="p-col-1">
            <input type="text" pInputText disabled [(ngModel)]="lblEstado" style="color: mediumblue; text-align: center; font-weight: bold">
        </div>
        <div class="p-col-3">
        </div>
        <div class="p-col-1">
            <input  style="background-color: rgb(114, 154, 230); text-align: center; font-weight: bold"type="text" pInputText readonly [(ngModel)]="txtusuarioModulo">
        </div>
        <div class="p-col-1">
            <input  style="background-color: rgb(114, 154, 230); text-align: center; font-weight: bold"type="text" pInputText readonly [(ngModel)]="txtfecha">
        </div>
        <div class="p-col-1">
            <input  style="background-color: rgb(114, 154, 230); text-align: center; font-weight: bold"type="text" pInputText readonly [(ngModel)]="txthora">
        </div>
        <div class="p-col-12" style="margin-top: -5px">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Año:</span>
                </div>
                <div class="p-col-1">
                    <input autocomplete="off" type="text" pInputText [(ngModel)]="txtCABOTROSIEG_ANIO" [disabled]="boolTxtAnio">
                </div>
                <div class="p-col-1">
                    <span>Descripción:</span>
                </div>
                <div class="p-col-5">
                    <input autocomplete="off" type="text" pInputText [(ngModel)]="txtCABIMPURENT_DESCRIPCION" [disabled]="boolTxtDescripcion">
                </div>
                <div class="p-col-1">
                    <button (click)="aceptar()" pButton class="aceptar" [disabled]="boolBtnAceptar"
                    style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 100px; ">
                         Aceptar
                     </button>
                </div>
            </div>
        </div>
        <div class="p-col-12" style="margin-top: -10px">
            <div class="p-grid">
                <div class="p-col-2">  
                </div>
                <div class="p-col-1">
                    <span>Utilidad a repartir:</span>
                </div>
                <div class="p-col-1">
                    <input autocomplete="off" appTwoDigitDecimaNumber type="text" pInputText [(ngModel)]="txtTRNCOBRO_importe" [disabled]="boolTxtImporte">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="divgrups" id="divPrincipal6">
    <div class="p-grid">
        <div class="p-col-1">
            <span>Buscar:</span>
        </div>
        <div class="p-col-2">
            <p-dropdown [options]="cmbFiltro"
                [style]="{'width': '100%', 'min-width': '100%'}"
                [(ngModel)]="strCmbFiltro"
                optionLabel="filtro"  inputId="inputFiltro"
            ></p-dropdown>
        </div>
        <div class="p-col-2">
            <input autocomplete="off" type="text" pInputText [(ngModel)]="txtDatoBuscar">
        </div>
        <div class="p-col-1">
            <button type="button"
            style="background-image: url(../../../assets/images/iconos/067-binocular.png);"
            class="littlebuttons" (click)="buscarFiltro(txtDatoBuscar)"></button>
        </div>
        <div class="p-col-5">
        </div>
        <div class="p-col-1">
            <p-colorPicker [disabled]="true" [(ngModel)]="choosedColorG"></p-colorPicker>
        </div>
    </div>
</div> -->
<div class="divgrups" id="divPrincipal5">
    <app-aggridsaci [width]=""
                    [height]="largo"
                    [enableSorting]="false"
                    [enableFilter]="true"
                    [rowData]="utilidadesService.detalleUtilidades"
                    [frameworkComponents]="frameworkComponents"
                    [animateRows]="true"
                    [rowSelection]="rowSelection"
                    [columnDefs]="columnDefsNomUtilidades"
                    [defaultColDef]="defaultNomUtilidades"
                    [rowStyle]="rowStyle"
                    [singleClickEdit]="false"
                    [botones]="true"
                    [mostrarGuardar]="false"
                    [botonesAdicionales]="false"
                    (selected)="seleccionDetUtilidades($event)"
                    (cambios)="cambio($event)"
                    [botonNuevo]="btnNuevoGrid"
                    [botonGuardar]="btnGuardarGrid"
                    [botonBorrar]="btnBorrarGrid"
                    [botonRegresar]="btnCancelarGrid"
    >
    </app-aggridsaci>
</div>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaMae"
      [transitionOptions]="'0ms'"
      [modal]="true"
      appendTo="body"
      [style]="{width: '70vw', height: '70vh'} ">
  <app-busquedadlgmae [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
            [where]="condicion"
            [busquedaParams]="busquedacampos"
            (opcionSeleccionada)="manejarSelecGridbus($event)"
            [busquedaCompuesta]="false"></app-busquedadlgmae>
</p-dialog>

<div style=" 	position: fixed;
				z-index: 999;
				height: 2em;
				width: 2em;
				overflow: visible;
				margin: auto;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;

				">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '120px'}" animationDuration=".5s">
	</p-progressSpinner>
</div>

<p-dialog header="Clave de Autorización" [(visible)]="claveDialog"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '40vw', height: 'auto'} ">
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							   (keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog header="Exportar datos hacia archivos planos" [(visible)]="displayDialogArchPlano"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
          [style]="{width: '40vw', height: 'auto'} ">
          <p-toast [style]="{marginTop: '41vh'}" position="top-center" key="nomutilarch"></p-toast>
          <div class="p-grid" style="width: 99%">
            <div class="p-col-12">
                <app-invmaebarra [barraBotonesCRUD]="true" [barraBotonesDesplaz]="true" [barraBotones3]="true"
                [botonVisto]="false" [barraBotonesAsiCon]="true" [barraBotones5]="true" [barraBotones3Alt]="false" (signal)="manejarSenalesArchPlan($event)"></app-invmaebarra>
            </div>
        </div> 
        <div class="divgrups" style="height: 250px;">
            <div class="p-grid" style="margin-top: 20px;">
                <div class="p-col-1"></div>
                <div class="p-col-3">
                    <span>Reporte:</span>
                    
                </div>
                <div class="p-col-5">
                    <p-dropdown 
                                [options]="cmbFormato" 
                                [style]="{'minWidth': '100%', 'width':'100%'}"
                                optionLabel="MAEPROEXPLIQUTIL_NOMBRE" 
                                optionValue="MAEPROEXPLIQUTIL_CODIGO"
                                [panelStyle]="{'width':'110%'}"
                                [(ngModel)]="selectFormato"
                               >
                    </p-dropdown>
                </div>
            </div>
            <div class="p-grid">
                <div class="p-col-1"></div>
                <div class="p-col-3">
                    <span>Archivo:</span>
                    
                </div>
                <div class="p-col-5">
                    <input  class="frm" 
                            type="text" 
                            pInputText
                            [(ngModel)]="nombreTxt"
                            placeholder="Inserte un nombre"
                            >
                </div>
            </div>
        </div>

        </p-dialog>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'NOMUTILIDADES'" [txtNumComprobante] = "lblNumLiqUti"
	(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>


