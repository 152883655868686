<div class="progress-spinner" *ngIf="progress">
	<p-progressSpinner></p-progressSpinner>
</div>
<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="encfacpropago"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarencfacpropago" title="Pregunta" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra (signal)="manejarSenales($event)" [barraBotones3]="barraBotones2" [barraBotonesAsiCon]="false"
			[botonBorrar]="botonBorrar" [botonNuevo]="botonNuevo" [botonGuardar]="botonGuardar"
			[botonRegresar]="botonRegresar" [barraBotones5]="false" [barraCxpPagoDoc]="false"
			[botonPrimero]="disBotonesDialog" [botonAnterior]="disBotonesDialog" [botonSiguiente]="disBotonesDialog"
			[botonUltimo]="disBotonesDialog" [botonAnular]="botonAnular" [botonverAsiento]="false"
			[botonBuscar]="disBotonesDialog"
			[botonFrmCorreos]="false" [barraCartera]="false"></app-invmaebarra>
	</div>
</div>
<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px; width: 99%;">
	<!--LINEA 1-->
	<div class="p-col-2">
		<label for="TRNCOBRO_NRODOC"></label>
		<input class="frm" id="TRNCOBRO_NRODOC" type="text" pInputText disabled
			style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
			[disabled]="true" [(ngModel)]="cxptrnpagodocService.trncobro.TRNCOBRO_NRODOC"
			(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
	</div>
	<div class="p-col-3">
	</div>
	<div class="p-col-2">
		<label for="ANULADO"></label>
		<input class="frm" id="ANULADO" type="text" pInputText disabled
			style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
			[disabled]="true" [(ngModel)]="anulado" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
			autocomplete="off">
	</div>
	<div class="p-col-1">
	</div>
	<div class="p-col-1">
		<div style="display: none">
			<label for="TRNPAGO_NUMERO"></label>
			<input class="frm" id="TRNPAGO_NUMERO" type="text" pInputText style="background-color: #EFFFF7" disabled
				[disabled]="true" [(ngModel)]="cxptrnpagodocService.trncobro.TRNPAGO_NUMERO"
				(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
		</div>
	</div>
	<div class="p-col-1">
	</div>
	<div class="p-col-2">
		<label for="IMPRESO"></label>
		<input class="frm" id="IMPRESO" type="text" pInputText disabled
			style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
			[disabled]="true" [(ngModel)]="impreso" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
			autocomplete="off">
	</div>
</div>
<p-tabView [(activeIndex)]="index" [style]="{'width':'98%'}">
	<p-tabPanel header="Pagos">
		<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 280px)'}">
			<div id="divPrincipal" class="divgrups">
				<div class="p-grid">
					<!--LINEA 1-->
					<div class="p-col-12" style="margin-top: -5px">
						<div class="p-grid">
							<div class="p-col-9">
							</div>
							<div class="p-col-1">
								<span>Asiento:</span>
							</div>
							<div class="p-col-2">
								<p-dropdown [options]="cmbasiento" [style]="{'minWidth': '100%', 'width':'100%'}"
									optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectasiento">
								</p-dropdown>
							</div>
						</div>
					</div>
					<!--LINEA 2-->
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-1">
								<span>Proveedor:</span>
							</div>
							<div class="p-col-1">
								<label for="PRO_CODIGO"></label>
								<input class="frm" id="PRO_CODIGO" type="text" pInputText [disabled]="discodproveedor"
									[(ngModel)]="cxptrnpagodocService.trncobro.PRO_CODIGO"
									(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
									autocomplete="off">
							</div>
							<div class="p-col-1">
								<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="busquedaproveedor(cxptrnpagodocService.trncobro.PRO_CODIGO)"
									[disabled]="discodproveedor"></button>
							</div>
							<div class="p-col-2">
								<label for="PRO_NOMBRE"></label>
								<input class="frm" id="PRO_NOMBRE" type="text" pInputText disabled
									[(ngModel)]="cxptrnpagodocService.trncobro.PRO_NOMBRE" autocomplete="off">
							</div>
							<div class="p-col-1">
								<span>Emisión:</span>
							</div>
							<div class="p-col-1">
								<p-calendar id="ENCFACPRO_FECHAEMISION" [inputStyle]="{width: '100%', height: '20px'}"
									[(ngModel)]="cxptrnpagodocService.trncobro.TRNCOBRO_FECHATRN"
									[readonlyInput]="false" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()">
								</p-calendar>
							</div>
							<div class="p-col-1">
								<span>Tipo Doc:</span>
							</div>
							<div class="p-col-2">
								<p-dropdown id="TIPODOC" [options]="cmbtipodoc"
									[style]="{'minWidth': '100%', 'width':'100%'}" optionLabel="name"
									[panelStyle]="{'width':'110%'}" [(ngModel)]="selecttipodoc"
									(keydown.enter)="setFocus($event)" (onChange)="verificarTipoDoc()"></p-dropdown>
							</div>
							<div class="p-col-1">
								<span>Origen:</span>
							</div>
							<div class="p-col-1">
								<label for="ORIGEN"></label>
								<input class="frm" id="ORIGEN" type="text" pInputText disabled
									[(ngModel)]="cxptrnpagodocService.trncobro.APLORG_CODIGO" autocomplete="off">
							</div>
						</div>
					</div>
					<!--LINEA 3-->
					<div class="p-col-12" style="margin-top: -20px">
						<div *ngIf="selectfp.codigo==='Cheque'">
							<div class="p-grid">
								<div class="p-col-1">
									<span>F.P:</span>
								</div>
								<div class="p-col-5">
									<div class="p-grid">
										<div class="p-col-3">
											<p-dropdown [options]="cmbfp" [style]="{'minWidth': '100%', 'width':'100%'}"
												optionLabel="name" [panelStyle]="{'width':'110%'}"
												[(ngModel)]="selectfp" (onChange)="busqfp()"></p-dropdown>
										</div>
										<div class="p-col-2">
											<span>Banco:</span>
										</div>
										<div class="p-col-2">
											<label for="CODBANCO"></label>
											<input class="frm" id="CODBANCO" type="text" pInputText
												[(ngModel)]="cxptrnpagodocService.trncobro.BAN_CODIGO"
												(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
												autocomplete="off" [disabled]="bolbanco">
										</div>
										<div class="p-col-1">
											<button type="button"
												style="background-image: url(../../../assets/images/iconos/buscar.png);"
												(click)="busquedabanco(cxptrnpagodocService.trncobro.BAN_CODIGO)"></button>
										</div>
										<div class="p-col-4">
											<label for="NOMBANCO"></label>
											<input class="frm" id="NOMBANCO" type="text" pInputText disabled
												[(ngModel)]="cxptrnpagodocService.trncobro.BAN_NOMBRE"
												autocomplete="off">
										</div>
									</div>
								</div>
								<div class="p-col-4">
									<div class="p-grid">
										<div class="p-col-1">
											<span>Num:</span>
										</div>
										<div class="p-col-3">
											<label for="NUMCHEQUE"></label>
											<input class="frm" id="NUMCHEQUE" type="text" pInputText
												[disabled]="disnumcheque"
												[(ngModel)]="cxptrnpagodocService.trncobro.TRNBAN_NUMERO"
												(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
												autocomplete="off" [disabled]="bolbanco">
										</div>
										<div class="p-col-1">
											<span>Ben:</span>
										</div>
										<div class="p-col-7">
											<label for="BENEFICIARIO"></label>
											<input class="frm" id="BENEFICIARIO" type="text" pInputText
												[(ngModel)]="cxptrnpagodocService.trncobro.TRNBAN_BENEFICIARIO"
												(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
												autocomplete="off">
										</div>
									</div>
								</div>
								<div class="p-col-1">
									<span>Cobro:</span>
								</div>
								<div class="p-col-1">
									<p-calendar id="ENCFACPRO_FECHACHEQUE"
										[inputStyle]="{width: '100%', height: '20px'}"
										[(ngModel)]="cxptrnpagodocService.trncobro.TRNPAGO_FECHA"
										[readonlyInput]="false" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()">
									</p-calendar>
								</div>
							</div>
						</div>
						<div *ngIf="selectfp.codigo==='Efectivo'">
							<div class="p-grid">
								<div class="p-col-1">
									<span>F.P:</span>
								</div>
								<div class="p-col-1">
									<p-dropdown [options]="cmbfp" [style]="{'minWidth': '100%', 'width':'100%'}"
										optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectfp"
										(onChange)="busqfp()"></p-dropdown>
								</div>
								<div class="p-col-1">
								</div>
								<div class="p-col-1">
									<span>Caja:</span>
								</div>
								<div class="p-col-1">
									<label for="CAJ_CODIGO"></label>
									<input class="frm" id="CAJ_CODIGO" type="text" pInputText
										[(ngModel)]="cxptrnpagodocService.trncobro.CAJ_CODIGO"
										(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="busquedacaja(cxptrnpagodocService.trncobro.CAJ_CODIGO)"></button>
								</div>
								<div class="p-col-2">
									<label for="CAJ_NOMBRE"></label>
									<input class="frm" id="CAJ_NOMBRE" type="text" pInputText disabled
										[(ngModel)]="cxptrnpagodocService.trncobro.CAJ_NOMBRE" autocomplete="off">
								</div>
							</div>
						</div>
						<div *ngIf="selectfp.codigo!=='Efectivo' && selectfp.codigo!=='Cheque'">
							<div class="p-grid">
								<div class="p-col-1">
									<span>F.P:</span>
								</div>
								<div class="p-col-1">
									<p-dropdown [options]="cmbfp" [style]="{'minWidth': '100%', 'width':'100%'}"
										optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectfp"
										(onChange)="busqfp()"></p-dropdown>
								</div>
							</div>
						</div>
					</div>
					<!--LINEA 4-->
					<div class="p-col-12" style="margin-top: -23px">
						<div *ngIf="selectfp.codigo==='Cheque'">
							<div class="p-grid">
								<div class="p-col-3">
									<p-checkbox id="chkpagoelectronico" name="chkpagoelectronico" value="" label=""
										binary="{{chkpagoelectronico}}" [(ngModel)]="chkpagoelectronico"
										(click)="botonesmodificar()" (keydown.enter)="setFocus($event)"
										(onChange)="cambioPagoElec($event)"></p-checkbox>
									<span>Pago Eléctronico</span>
								</div>
							</div>
						</div>
					</div>
					<!--LINEA 5-->
					<div class="p-col-12" style="margin-top: -17px; margin-bottom: -15px">
						<div class="p-grid">
							<div class="p-col-1">
								<span>Concepto:</span>
							</div>
							<div class="p-col-4">
								<label for="CONCEPTO"></label>
								<input class="frm" id="CONCEPTO" type="text" pInputText
									[(ngModel)]="cxptrnpagodocService.trncobro.TRNCOBRO_CONCEPTO"
									(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
									autocomplete="off">
							</div>
							<div class="p-col-1">
								<span>Importe:</span>
							</div>
							<div class="p-col-1">
								<label for="IMPORTE"></label>
								<input class="frm" id="IMPORTE" type="text" pInputText [disabled]="disimporte"
									[(ngModel)]="cxptrnpagodocService.trncobro.TRNCOBRO_IMPORTE"
									(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off"
									disabled>
							</div>
							<div class="p-col-1">
								<span>Td:</span>
							</div>
							<div class="p-col-1">
								<p-dropdown [options]="cmbtd" [style]="{'minWidth': '100%', 'width':'100%'}"
									[disabled]="discmbtipodiario" optionLabel="name" [panelStyle]="{'width':'110%'}"
									[(ngModel)]="selecttd"></p-dropdown>
							</div>
							<div class="p-col-1">
								<span>Comprobante:</span>
							</div>
							<div class="p-col-2">
								<label for="COMPROBANTE"></label>
								<input class="frm" id="COMPROBANTE" type="text" pInputText
									[disabled]="disasicomprobante"
									[(ngModel)]="cxptrnpagodocService.trncobro.TRNCOBRO_REFERENCIAEXTERNA"
									(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
									autocomplete="off">
							</div>
						</div>
					</div>
				</div>
			</div>

			<p-tabView [(activeIndex)]="index1" [style]="{'width':'98%'}">
				<p-tabPanel header="Documentos" >
					<app-aggridsaci [width]="" [height]="450"  [enableFilter]="true"
						[rowData]="cxptrnpagodocService.documentos" [frameworkComponents]="frameworkComponents"
						[animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefsDocumentos"
						[defaultColDef]="defaultColDefDocumentos" [rowStyle]="rowStyle" [singleClickEdit]="false"
						[botones]="false" (selected)="seleccionDocumento($event)" (cambios)="cambio($event)"
						align="left" style="justify-items: end"></app-aggridsaci>
				</p-tabPanel>
				<p-tabPanel header="Varios">
					<div class="p-grid">
						<div class="p-col-12">
							<app-aggridsaci [width]="" [height]="largovar"  [enableFilter]="true"
								[rowData]="cxptrnpagodocService.varios" [frameworkComponents]="frameworkComponents"
								[animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefsVarios"
								[defaultColDef]="defaultColDefVarios" [rowStyle]="rowStyle" [singleClickEdit]="false"
								[botones]="true" [mostrarGuardar]="false" (selected)="seleccionVarios($event)"
								(cambios)="cambio($event)" [botonNuevo]="btnNuevoGrid" [botonBorrar]="btnBorrarGrid"
								[botonesAdicionales]="true" [botonCXC]="btnCXCGrid" [botonAnticipo]="false" align="left"
								style="justify-items: end"></app-aggridsaci>
						</div>
						<div class="p-col-1">
							<span>Abonos CXC:</span>
						</div>
						<div class="p-col-2">
							<p-dropdown [options]="cmbabono" [style]="{'minWidth': '100%', 'width':'100%'}"
								optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectabono">
							</p-dropdown>
						</div>
						<div class="p-col-5">
						</div>
						<div class="p-col-2">
							<label style="background-color: rgb(255, 255, 0);">___</label>
							<span>ANTICIPOS</span>
						</div>
						<div class="p-col-2">
							<label style="background-color: rgb(0, 255, 0);">___</label>
							<span>CXC</span>
						</div>
					</div>
				</p-tabPanel>
			</p-tabView>
			<span style="color: red; font-size: 16px">{{lblMensaje}}</span>
		</p-scrollPanel>
	</p-tabPanel>
</p-tabView>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusqueda" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
		(opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
		[busquedaParams]="busquedacampos" [consulta]="consulta" (opcionSeleccionada)="manejarSeleccion($event)">
	</app-busquedadlg>
</p-dialog>

<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width:'90vw', height:'120vh'}" [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="asiento" [abiertoDesde]="'comencfacpro'"
		(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>

<p-dialog header="Buscar Cancelaciones/Abonos de Documentos" [(visible)]="displayBusDocPagados"
	[transitionOptions]="'0ms'" [modal]="true" appendTo="body" [style]="{width:'60vw', height:'90vh'}"
	[draggable]="false">
	<app-cxpbuscardocpagados *ngIf="displayBusDocPagados" (detallesSeleccionado)="recibirNumeroDoc($event)"
		(signalSalir)="recibirSenalesDocPagado($event)"></app-cxpbuscardocpagados>
</p-dialog>

<p-dialog header="Reportes" [(visible)]="displayDialogReportes" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: '400px'}">
	<app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma" [strNumDoc]="asiento"
		[strCampo]="'ASI_NRO'" (cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>
<!-- CDPJ -->
<p-dialog header="Formularios de Correos" [(visible)]="displayDialogfrmcorreos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: '400px'}">
	<app-sacifrmenviocorreos [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
	[strNumDoc]="asiento" [strCampo]="'ASI_NRO'"
	[strCliProvCod]="cxptrnpagodocService.trncobro.PRO_CODIGO"
		(cerrar)="cerrarfrmcorreos()"></app-sacifrmenviocorreos>
</p-dialog>
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	<p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
	</p-dialog>
</div>
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	<p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
	</p-dialog>
</div>
<!-- CDPJ -->