import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { InvrecalCostoPMD } from '../invinterfaces/invrecalCostoPMD';
import { Observable } from 'rxjs';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class InvrecalcostopmdServiceService {

  get costosArticulos(): InvrecalCostoPMD[] {
    return this._invrecalCostoPMD;
  }

  set costosArticulos(value: InvrecalCostoPMD[]) {
    this._invrecalCostoPMD = value;
  }

  public url: string;
  private _invrecalCostoPMD: InvrecalCostoPMD[];
  public way: string;
  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    private confIniciales: ConfInicialesService,
    public usuario: Usuario) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }

  obtenerCostoArticulos(filtroBusqueda: string): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/inventario/recalcularcostoprom/150o2hhxdkptqa8', {
      elementos: {
        p_opcion: 'T',
        p_filtro: 'art_activo=\'S\' and com_codigo =\'01\' ' + filtroBusqueda,
        p_condicion: '',
      },
    }, this.confIniciales.httpOptions());

  }

  recalArtProd(fechadesde: string, artcodigo: string): Observable<any> {

    return this.http.post<any[]>(this.way + '/inventario/recalcularcostoprom/ktqeuxlfnuyn86v', {
      elementos: {
        p_art_codigo: artcodigo,
        p_com_codigo: '01',
        p_fecha: fechadesde,
      },
    }, this.confIniciales.httpOptions());
  }

  recalArtProdProm(fechadesde: string, artcodigo: string) {

    return this.http.post<any[]>(this.way + '/inventario/recalcularcostoprom/ktqeuxlfnuyn86v', {
      elementos: {
        p_art_codigo: artcodigo,
        p_com_codigo: '01',
        p_fecha: fechadesde,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  recalArtNoProd(fechadesde: string, artcodigo: string): Observable<any> {

    return this.http.post<any[]>(this.way + '/inventario/recalcularcostoprom/yzxx39edeo7mrpn', {
      elementos: {
        p_art_codigo: artcodigo,
        p_com_codigo: '01',
        p_fecha: fechadesde,
      },
    }, this.confIniciales.httpOptions());
  }

  recalArtNoProdProm(fechadesde: string, artcodigo: string) {

    return this.http.post<any[]>(this.way + '/inventario/recalcularcostoprom/yzxx39edeo7mrpn', {
      elementos: {
        p_art_codigo: artcodigo,
        p_com_codigo: '01',
        p_fecha: fechadesde,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erGrupoCodnom(strparametro) {
    return this.http.post<any[]>(this.way + '/inventario/recalcularcostoprom/br1mnpn0vt9d67a', {
      elementos: {
        parametro: strparametro
      },
    }, this.confIniciales.httpOptions());
  }
  erArtCodNom(strparametro) {
    return this.http.post<any[]>(this.way + '/inventario/recalcularcostoprom/kkmmoj1b3dwvu8c', {
      elementos: {
        parametro: strparametro
      },
    }, this.confIniciales.httpOptions());
  }

  recalcularCostoPromedioProd(fechainicial, fechafinal, horainicial, horafinal) {
    return this.http.post<any[]>(this.way + '/inventario/recalcularcostoprom/6dcc5559eaab6c0', {
      elementos: {
        p_fecha_inicial: fechainicial,
        p_fecha_final: fechafinal,
        p_hora_ini: horainicial,
        p_hora_fin: horafinal,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }


}
