import { Component, OnInit, ViewChild } from '@angular/core';
import { Usuario } from '../../usuario';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { Cxctmptrnresumen } from '../../ven/veninterfaces/cxctmptrnresumen';
import { Cxctrnresumen } from '../../ven/veninterfaces/cxctrnresumen';
import { Comencdevfac } from '../../com/cominterfaces/comencdevfac';
import { Cxctrndebitobancario } from '../cxcinterfaces/cxctrndebitobancario';
import { Cxctrnretencion } from '../cxcinterfaces/cxctrnretencion';
import { Cxctrnotrosg } from '../cxcinterfaces/cxctrnotrosg';
import { Cxctrnformapago } from '../cxcinterfaces/cxctrnformapago';
import { Cxctrnanexos } from '../cxcinterfaces/cxctrnanexos';
import { Cxctrnvarios } from '../cxcinterfaces/cxctrnvarios';
import { Cxctrncobro } from '../cxcinterfaces/cxctrncobro';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { AggridBanComponent } from '../../ban/aggrid-ban/aggrid-ban.component';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { Cxctrnanticipos } from '../cxcinterfaces/cxctrnanticipos';
import { CxcTrncobro } from 'src/app/interfaces/cxctrncobro';
import {CxctrncancelacionService} from '../cxcservicios/cxctrncancelacion.service'
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { SegMaeCompania } from '../../ven/veninterfaces/segmaecompania';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { DatePipe } from '@angular/common';
import { VenmaeclienteService } from '../../ven/venservicios/venmaecliente.service';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { UtilitariosService } from '../../advantage/advantageservices/utilitarios.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
@Component({
  selector: 'app-cxctrncancelacion',
  templateUrl: './cxctrncancelacion.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class CxctrncancelacionComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  hmpConfiguracion: any[];
  indicador: any;
  numeroFactura: string;
    // barra
    btnGuardar: boolean;
    btnRegresar: boolean;
    btnBorrar: boolean;
    botonAnular: boolean;
    botonXML: boolean;
    botonReenviarCorreo: boolean;
    botonAsiento: boolean;
    botonPago: boolean;
    botonBuscar: boolean;
    botonVerAsiento: boolean;
    botonAnterior: boolean;
    botonPrimero: boolean;
    botonUltimo: boolean;
    botonSiguiente: boolean;
    barraBotones2: boolean;
    btnNuevo: boolean;
 // interfaces
 trnresumenSeleccionado: Cxctrnresumen;
 facturaPend: Cxctrnresumen;
 detalleRetencionSeleccionado: Cxctrnretencion;
 detalleVariosSeleccionado: Cxctrnvarios;
 detalleVariosSeleccionadoAux: Cxctrnvarios;
 detalleOtrasFSeleccionada: Cxctrnotrosg;
 detalleFormaPago: Cxctrnformapago;
 detalleFormaPagoDev: Cxctrnformapago;
 detalleRetencionSeleccionadoAux: Cxctrnretencion;
 detalleOtrasFSeleccionadaAux: Cxctrnotrosg;
 detalleFormaPagoAux: Cxctrnformapago;
 detalleFormaPagoDevAux: Cxctrnformapago;
 cxctmptrnresumen: Cxctmptrnresumen[];
 cxctrnresumen: Cxctrnresumen[];
 encabezadosCartera: Comencdevfac[];
 anexoSeleccionado: Cxctrnanexos;
 permisosCartera: SegMaePermiso;
 editpagofr = true;
 editConcil = true;
 displayDialogfrmcorreos:boolean;//CDPJ
 // aggrid
 rowSelection = 'multiple';
 public frameworkComponents;
 rowStyle;
 rowStyleEC;
 rowStyleSaldo;
 rowStyleECAnc;
 rowStyleFp;
 rowStyleFpdev;
 defaultColumnDefCarteraDoc;
 defaultColumnDefCancelacionRet;
 defaultColumnDefCancelacionOtrosG;
 defaultColumnDefCancelacionFormaPago;
 defaultColumnDefCarteraFormaPagoDev;
 defaultColumnDefCarteraVarios;
 defaultColumnDefCarteraDebito;
 defaultColumnDefCarteraAnticipo;
 defaultColDefCxctmptrnresumen;
 defaultColDefCxctrnresumen;
 defaultColumnDefFacCanceladas;
 comboOpciones: any[];
 largo: string;
 largoRef: string;

 ecanticipo: any[];
 lblDocCierre = false;

 bolNuevo = false;
 listaDoc: CxcTrncobro[] = [];
 datosfe: SegMaeCompania = {};

 btneditConcil = true;
// busqueda
opcionbusqueda: string;
types: SelectItem[];
arregloCons: any[];
arregloBus: any[];
busquedacampos: string[];
tabla: string;
where: string;
consulta: string;
busquedaCompuesta: boolean;
bolestcta: boolean;
bolsaldo: boolean;
opcion: string;
 // dialogs
 displayDialogDetFac: boolean;
 displayDialogPedidos: boolean;
 displayDialogAsiento: boolean;
 displayAsiContable: boolean;
 displayDialogSeriales: boolean;
 displayDialogDescuento: boolean;
 displayDialogBusquedaFast: boolean;
 displayDialogBus: boolean;
 displayDialogPago: boolean;
 dialogFacCanceladas: boolean;
 displayAcciones: boolean;
 displayLog: boolean;
 displayDialogReportes: boolean;
 claveDialog: boolean;
 spin = false;
 auxiliar: number;
auxContEC: number;
debeTotal: number;
haberTotal: number;
indiceTablas: number;
totalimporte: number;
totalpagado: number;
totalsaldo: number;
totalApagar: number;
valorDscValor: number;
valorDscPorcentaje: number;
numdecsist: number;
dscClienteActual: number;
numeroActual: number;
indice: number;
i: number;
activeIndex: number;
indiceCliente: number;
indiceDocRef: number;
listaSaldos: any;
itemTipoDoc: any;
listaClientes: any[];
listaReferencias: any[];
numeracionActual: any;
fechaEmision: any;
fechaInicio: any;
fechaFin: any;
horaSistema: any;
horaInicio: any;
horaFin: any;
listaprecios: any[];
tabDocumentos: boolean;
booleanAnulado: boolean;
tabVarios: boolean;
tabRetenciones: boolean;
tabOtrosGastos: boolean;
booleanNumero: boolean;
tabFormaPago: boolean;
tabFormaPagoDev: boolean;
tabEstadoCuentaAnt: boolean;
tabDebitoBancario: boolean;
valorInvalidoV: boolean;
saldoInicial: boolean;
bolsaldoIni: boolean;
valorInvalidoP: boolean;
numeracionDialog: boolean;
editarCeldas: boolean;
booleanValor: boolean;
booleanDatosPantalla: boolean;
booleanPorcentaje: boolean;
booleanRegimen: boolean;
booleanParaiso: boolean;
booleanRegimenP: boolean;
booleanRefrendo: boolean;
booleanImpuestoRenta: boolean;
booleanBodega: boolean;
btnNuevoGrid: boolean;
btnGuardarGrid: boolean;
btnBorrarGrid: boolean;
btnCancelarGrid: boolean;
btnVerificarExis: boolean;

devboolean: boolean;
impboolean: boolean;
expboolean: boolean;
otrosboolean: boolean;
transboolean: boolean;
cotizacionBoolean: boolean;
porcIvaBoolean: boolean;
retencionElect: boolean;
bolnc: boolean;
choosedColor: string;
claveExportNuevo: string;
tipodetalleactivo: string;
botonFacNR: string;
impresoString: string;
grupo1: string;
numSerie: string;
nombrePaisSeleccionado: string;
codigoPaisRegimen: string;
codigoPaisParaiso: string;
dscSeleccionado: string;
asiento: string;

claveAuth: string;
saldoCliente: string;
nombreProveedor: string;
logResultado: string;
accionClave: string;
cobroCodigo: string;

numDocRef: string;
imagenProducto: string;
estadoRef: string;
largoestcta: string;
choosedColorG: string;
choosedColorSB: string;
nombrebodega: string;
codClienteFac: string;
nombreClienteFac: string;
nroFacCancelada: string;
lblAplicaRegMicroempresas: string;
tipodetalle = '';
boltrnaextipcom: boolean;
auxreporte: number = 0;
strNombreForma: string;
  constructor(
  public cxctrncancelacionService:CxctrncancelacionService,
  private confIniciales: ConfInicialesService,
  private init: NuevoComponentService,
  private busquedaSer: InvbusquedaService,
  private datePipe: DatePipe,
  private message: MessageService,
  private cliService: VenmaeclienteService,
  public usuario: Usuario,
  private permisosService: PermisosService,
  private utilitariosService: UtilitariosService,
  private confirmationService: ConfirmationService,
  private auditoriagral: AuditoriagralService
  ) { 
    this.agTable();
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.data.APLORG_CODIGO === 'SISTEMA' &&
        params.data.TRNCOBRO_CONCEPTO.includes('CUOTA')) {
        return { background: 'rgb(255, 255, 170)' };
      }

      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }

      if ((params.data.TRNCOBRO_FECHATRN === '' || params.data.TRNCOBRO_FECHATRN === null) &&
        (this.activeIndex === 1 || this.activeIndex === 2)) {
        return { background: '#AAFF00' };
      }
      /*if (params.data.TRNCOBRO_NRODOC === '' && this.activeIndex === 1) {
        return {background: '#AAFF00'};
      }*/
      /*if (params.data.APLORG_CODIGO === 'SISTEMA') {
        return {background: '#AAFF00'};
      }*/

    };

    this.rowStyleEC = (params) => {
      if (params.data.TRNCOBRO_TIPO !== 'A') {
        if (params.data.TRNCOBRO_FECHATRN === '') {
          return { background: 'rgb( 255, 255, 170)' };
        } else {
          return { background: 'rgb( 196, 225, 255)' };
        }
      }
    };

    this.rowStyleSaldo = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if (params.data.TRNCOBRO_FECHATRN === '') {
        return { background: 'rgb( 255, 255, 170)' };
      }
    };

    this.rowStyleECAnc = (params) => {
      if (params.data.TRNPAGO_FECHA === '') {
        return { background: 'rgb( 255, 255, 170)' };
      } else {
        return { background: 'rgb( 196, 225, 255)' };
      }
    };

    this.rowStyleFp = (params) => {
      if (params.data.APLORG_CODIGO === 'SISTEMA' &&
        params.data.TRNCOBRO_CONCEPTO.includes('CUOTA')) {
        return { background: 'rgb(255, 255, 170)' };
      }
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if ((params.data.TRNCOBRO_FECHATRN === '' || params.data.TRNCOBRO_FECHATRN === null) &&
        (this.activeIndex === 1 || this.activeIndex === 2)) {
        return { background: '#AAFF00' };
      }
      if (params.data.COLORDOCDEP === true) {
        return { background: 'rgb(255, 207, 185)' };
      }
      if (params.data.COLORDOCCONC === true) {
        return { background: 'rgb(135, 234, 130)' };
      }
    };

    this.rowStyleFpdev = (params) => {
      if (params.data.APLORG_CODIGO === 'SISTEMA' &&
        params.data.TRNCOBRO_CONCEPTO.includes('CUOTA')) {
        return { background: 'rgb(255, 255, 170)' };
      }
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if ((params.data.TRNCOBRO_FECHATRN === '' || params.data.TRNCOBRO_FECHATRN === null) &&
        (this.activeIndex === 1 || this.activeIndex === 2)) {
        return { background: '#AAFF00' };
      }
      if (params.data.COLORDOCDEP === true) {
        return { background: 'rgb(255, 207, 185)' };
      }
      if (params.data.COLORDOCCONC === true) {
        return { background: 'rgb(135, 234, 130)' };
      }
    };

    this.defaultColumnDefCarteraDoc = {
      editable: true,
      width: 100,
      objeto: 'cxctrncancelaciondocumentos'
    };
    this.defaultColumnDefCancelacionRet = {
      editable: true,
      width: 100,
      objeto: 'cxctrncancelacionretenciones'
    };
    this.defaultColumnDefCancelacionOtrosG = {
      editable: true,
      width: 100,
      objeto: 'cxctrncancelacionotrosgastos'
    };
    this.defaultColumnDefCancelacionFormaPago = {
      editable: true,
      width: 100,
      objeto: 'cxctrncancelacionformapago'
    };
    this.defaultColumnDefCarteraFormaPagoDev = {
      editable: true,
      width: 100,
      objeto: 'cxcformapagodev'
    };
    this.defaultColumnDefCarteraVarios = {
      editable: true,
      width: 100,
      objeto: 'cxcvarios'
    };
    this.defaultColumnDefCarteraDebito = {
      editable: true,
      width: 100,
      objeto: 'cxcdepositoban'
    };
    this.defaultColumnDefCarteraAnticipo = {
      editable: true,
      width: 100,
      objeto: 'cxcanticipo'
    };
    this.defaultColDefCxctmptrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctmptrnresumen'
    };

    this.defaultColDefCxctrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctrnresumen'
    };
    this.defaultColumnDefFacCanceladas = {
      editable: true,
      width: 100,
      objeto: 'cxcfacCanceladas'
    };
  }
  columnDefsCxctmptrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC', width: 100,
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Debe', field: 'DEBE', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Haber', field: 'HABER', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: { textAlign: 'right' },
    },
  ];
  columnDefsCxctrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC'
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHAVENCE', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Importe', field: 'TRNCOBRO_IMPORTE', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Pagado', field: 'PAGADO', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: { textAlign: 'right' },
    },
  ];
  columDefCarteraDoc = [
    {
      headerName: 'S/N',
      field: 'SELECCIONADO',
      cellRendererFramework: AggridBanComponent,
      editable: (params) => {
        return false;
      },
      width: 50,
      onCellClicked: (params) => {
        this.docSeleccionado(params);
      }
    },
    {
      headerName: 'Nro. Documento', field: 'TRNCOBRO_NRODOC', cellEditor: 'cellPrueba', width: 180, editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 50,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: true,
          tamanocelda: 32
        }
      }
    },
    {
      headerName: 'Fecha Vec.', field: 'TRNCOBRO_FECHAVENCE', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: true,
          tamanocelda: 32,
        }
      }
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', cellEditor: 'cellPrueba', width: 200, editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 200,
        }
      }
    },
    {
      headerName: 'Saldo', field: 'TRNCOBRO_SALDOINI', cellEditor: 'agSelectCellEditor', width: 100, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Importe', field: 'TRNCOBRO_IMPORTE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Vendedor', field: 'VEN_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 5,
          tienecalculos: true,
          int: true
        }
      }
    }];
  columDefCarteraRetencion = [
    {
      headerName: 'Código', field: 'RETENCION_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 25,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Descripción', field: 'TRNRETENCION_DESCRIPCION', cellEditor: 'cellPrueba', width: 250, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 200
        }
      }
    },
    {
      headerName: 'Número', field: 'TRNRETENCION_NRO', cellEditor: 'cellPrueba', width: 150, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 15,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Base', field: 'TRNRETENCION_BASE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: '%', field: 'TRNRETENCION_PORCENTAJE', cellEditor: 'agSelectCellEditor', width: 80, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'T.Retenido', field: 'TRNRETENCION_TOTALRETENIDO', cellEditor: 'cellPrueba', width: 120, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    }];
  columDefCarteraOtrosG = [
    {
      headerName: 'Código', field: 'COMISION_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 25,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Descripción', field: 'TRNCOMISION_DESCRIPCION', cellEditor: 'cellPrueba', width: 250, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 200
        }
      }
    },
    {
      headerName: 'Número', field: 'TRNCOMISION_NRO', cellEditor: 'cellPrueba', width: 150, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 35,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Importe', field: 'TRNCOMISION_TOTALRETENIDO', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    }];
  columDefCarteraFormaPago = [
    {
      headerName: 'Tipo', field: 'TRNPAGO_TIPO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 4,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Código', field: 'CAJ_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        const boolEdit = this.idEditcajcodigoFormapago(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Número', field: 'TRNPAGO_NUMERO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 45,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Fecha', field: 'TRNPAGO_FECHA', cellEditor: 'cellPrueba', width: 150, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: true,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Ban', field: 'BANCLI_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 35,
          tienecalculos: true,
          int: true,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Nombre', field: 'BANCLI_NOMBRE', cellEditor: 'cellPrueba', width: 200, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Número Cuenta', field: 'TRNPAGO_NROCTA', cellEditor: 'cellPrueba', width: 100,
      editable: p => {
        return p.data.TRNPAGO_TIPO === 'TA' || p.data.TRNPAGO_TIPO === 'CH';
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 25,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Concepto', field: 'TRNPAGO_CONCEPTO', cellEditor: 'cellPrueba', width: 150, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 1000,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Importe', field: 'TRNPAGO_IMPORTE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'C.Sri', field: 'TRNPAGO_CODSRI', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 2,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Disp.', field: 'TRNPAGO_TIPDIS', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 4,
          tienecalculos: true
        }
      }
    }];
  ngOnInit() {
    this.indiceTablas = 0;
    this.usuario.loadStorage();
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador] = {};
    this.detalleRetencionSeleccionado = {};
    this.detalleVariosSeleccionado = {};
    this.detalleRetencionSeleccionadoAux = {};
    this.detalleOtrasFSeleccionada={}
    this.detalleOtrasFSeleccionadaAux = {};
    this.cxctrncancelacionService.cxctrnresumenarray[this.indicador] = [];
    this.cxctrncancelacionService.cxctrnretencionarray[this.indicador] = [];
    this.cxctrncancelacionService.cxctrnvariosarray[this.indicador] = [];
    this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador] = [];
    this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador] = [];
    this.cxctrncancelacionService.cxcdebitobancarioarray[this.indicador] = [];
    this.cxctrncancelacionService.cxctrnanticiposarray[this.indicador] = [];
    this.cxctrncancelacionService.cxctrnformapagodevarray[this.indicador] = [];
    this.tipodetalleactivo = 'all';
    this.booleanNumero = true;
    this.tabDocumentos = true;
    this.tabFormaPago = true;
    this.booleanAnulado = false;
    this.tabOtrosGastos = true;
    this.tabRetenciones = true;
    this.activeIndex = 0;
    this.largo = '630';
    this.largoestcta = '420';
    this.permisosService.getListaPermisos('CXC', '8', '1061').subscribe((permiso) => {
      this.permisosCartera = permiso[0];
    });
    this.auditoriagral.obtenerConfiguracion1().subscribe((conf) => {
      this.hmpConfiguracion = conf;
    });
    this.choosedColorG = '#804000';
    this.choosedColorSB = '#00FF00';
    this.choosedColor = '#AAFF00';
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.botonPrimero = false;
    this.botonAnterior = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
    this.botonAnular = false;
    this.botonXML = true;
    this.botonReenviarCorreo = true;
    this.botonAsiento = false;
    this.botonVerAsiento = false;
    this.botonBuscar = false;
    this.editarCeldas = false;
    this.cxctrncancelacionService.getListaClientes().subscribe((resCli) => {
      this.listaClientes = resCli;
    });
    this.botonPago = false;
    this.cxctrncancelacionService.leerConfiguracionDatosElectronicos().subscribe(data => {
      for (const rs of data) {
        this.datosfe = rs;
      }
    });
  }
  ngOnDestroy(): void {
    this.confIniciales.instanciasTrncancelacion--;
  }
  
  async manejarSenales(valor){
    if (valor !== 'Nuevo' && valor !== 'Cancelar'
      && valor !== 'Primero' && valor !== 'Anterior' && valor !== 'Siguiente' && valor !== 'Ultimo'
      && valor !== 'Buscar' && valor !== 'Imprimir' && valor !== 'AsientoVer' && valor !== 'Salir'
      && valor !== 'facturasAbonadas') {
      let dia = new Date().getDate();
      let mes = new Date().getMonth();
      let anio = new Date().getFullYear();
      try {
        // Verifica el periodo contable
        dia = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN === undefined ? dia : this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.getDate();
        mes = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN === undefined ? mes : this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.getMonth();
        anio = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN === undefined ? anio : this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.getFullYear();
      } catch {
        const tmpfecha = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.substr(0, 10);
        console.log(tmpfecha);
        const splfec = tmpfecha.split('/');
        const fecha = new Date(splfec[2], splfec[1] - 1, splfec[0]);
        dia = fecha.getDate();
        mes = fecha.getMonth();
        anio = fecha.getFullYear();
      }

      if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
        this.message.add({
          key: 'cxctrncancelacion',
          severity: 'info',
          summary: 'Información',
          detail: 'Error, fecha debe de estar dentro del período contable'
        });
        return;
      }
    }
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
    if (valor === 'Primero') {
      this.primero();
    } else if (valor === 'Siguiente') {
      this.siguiente();
    } else if (valor === 'Anterior') {
      this.anterior();
    } else if (valor === 'Ultimo') {
      this.ultimo();
    } else if (valor === 'Nuevo') {
      this.nuevo();
    } else if (valor === 'Borrar') {
      this.borrar();
    } else if (valor === 'Guardar') {
      // this.guardarNew();
      this.guardar();
    } else if (valor === 'Cancelar') {
      this.cancelarEdicionCartera();
    } else if (valor === 'Anular') {
      this.abrirClave('clave');
    } else if (valor === 'Buscar') {
      this.buscarCartera();
    } else if (valor === 'Asiento') {
      this.generarasiento();
    } else if (valor === 'AsientoVer') {
      this.asientover();
     } //else if (valor === 'Firmar') {
    //   this.insertarEleTrnDoc();
    // } 
    else if (valor === 'EnviarCorreo') {
      //this.reenviarCorreo();
    } else if (valor === 'facturasAbonadas') {
      this.abrirFacturasAbonadas();
    }

    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }
     //CDPJ
     if (valor === 'FormularioCorreos'){
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogfrmcorreos=true
    }
    //CDPJ
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  consultarPosicionCliente(valor) {
    if (valor === 'P') {
      this.indiceCliente = 0;
    } else if (valor === 'S') {
      if (this.indiceCliente < this.listaClientes.length - 1) {
        this.indiceCliente++;
      } else {
        this.indiceCliente = this.listaClientes.length - 1;
        valor = 'U';
      }
    } else if (valor === 'A') {
      if (this.indiceCliente > 0) {
        this.indiceCliente--;
      } else {
        this.indiceCliente = 0;
        valor = 'P';
      }
    } else if (valor === 'U') {
      this.indiceCliente = this.listaClientes.length - 1;
    }
    this.consultarReferencias(valor);
  }
  consultarReferencias(valor) {
    this.cxctrncancelacionService.getListaReferencias(this.listaClientes[this.indiceCliente].CLI_CODIGO).subscribe((resRef) => {
      this.listaReferencias = resRef;
      if (valor === 'P' || valor === 'S') {
        this.indiceDocRef = 0;
      } else if (valor === 'A' || valor === 'U') {
        this.indiceDocRef = this.listaReferencias.length - 1;
      }
      this.obtenerDatos(this.listaReferencias[this.indiceDocRef].TRNCOBRO_REFERENCIAEXTERNA);
    });
  }
  primero() {
    this.editpagofr = true;
    this.consultarPosicionCliente('P');
    if (this.botonUltimo === true) {
      this.botonUltimo = !this.botonUltimo;
    }
    if (this.botonSiguiente === true) {
      this.botonSiguiente = !this.botonSiguiente;
    }
    this.botonPrimero = true;
    this.botonAnterior = true;
  }
  siguiente() {
    this.editpagofr = true;
    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE.length === 0) {
      this.consultarPosicionCliente('S');
      return;
    }
    if (this.indiceDocRef < this.listaReferencias.length - 1) {
      this.indiceDocRef++;
    } else {
      this.consultarPosicionCliente('S');
      return;
    }
    if (this.botonPrimero === true && this.botonAnterior === true) {
      this.botonPrimero = false;
      this.botonAnterior = false;
    }
    this.consultarReferencias('');
  }
  anterior() {
    this.editpagofr = true;
    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE.length === 0) {
      this.consultarPosicionCliente('A');
      return;
    }
    if (this.indiceDocRef > 0) {
      this.indiceDocRef--;
    } else {
      this.consultarPosicionCliente('A');
      return;
    }
    if (this.botonUltimo === true && this.botonSiguiente === true) {
      this.botonUltimo = false;
      this.botonSiguiente = false;
    }
    this.consultarReferencias('');
  }
  ultimo() {
    this.editpagofr = true;
    this.consultarPosicionCliente('U');
    if (this.botonPrimero === true) {
      this.botonPrimero = !this.botonPrimero;
    }
    if (this.botonAnterior === true) {
      this.botonAnterior = !this.botonAnterior;
    }
    this.botonUltimo = true;
    this.botonSiguiente = true;
  }
  async borrar() {
    this.editpagofr = true;
    // if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
    //   if (Number(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].SALDOANC) !==
    //     Number(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE)) {
    //     this.message.add({
    //       key: 'cxctrncancelacion',
    //       severity: 'error',
    //       summary: 'Anular Cancelación',
    //       detail: 'No se puede Eliminar el anticipo ya está siendo utilizado'
    //     });
    //     return;
    //   }
    // }
    // conciliado
    if (this.permisosCartera.PERELIMACION === 1) {
      if (this.activeIndex === 0) {

        if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC.length > 0) {
          // ver que los documentos no tengan pagos para la eliminación
          // if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC' ||
          //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
          //   if (await this.existePagoDoc()) {
          //     return;
          //   }
          // }

          // verificar que el cheque girado por la devolución de anticipos
          // no haya sido conciliado
          if (await this.chequeConciliado('eliminar')) {
            return;
          }

          // verificar que el cheque cobrado no haya sido depositado
          if (await this.chequeDepositado('eliminar')) {
            return;
          }

          // verificar que el efectivo no haya sido depositado
          if (await this.efectivoDepositado('eliminar')) {
            return;
          }

          // verificar que los depósitos no hayan sido conciliados
          if (await this.depositoConciliado('eliminar')) {
            return;
          }

          // verificar que los vouchers no hayan sido conciliados
          if (await this.tarjetaConciliada('eliminar')) {
            return;
          }

          // verificar que el documento no esté en el cierre de caja
          if (await this.documentoCierre('eliminar')) {
            return;
          }
          // verificar si el anticipo ya fue utilizado y no dejar
          // eliminarlo
          // if (this.anticipoUtilizado('eliminar')) {
          //   return;
          // }
          // verifica si existe algun anticipo asociado y si este anticipo ha sido utilizado
          if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CI') {
            if (await this.cxc_fnc_verificar_ant_util(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC) !== 0) {
              this.message.add({
                key: 'cxctrncancelacion',
                severity: 'error',
                summary: 'Información',
                detail: 'No se puede eliminar la transacción, ya que tiene asociado un anticipo ya utilizado'
              });
              return;
            }
          }
          this.confirmarCartera();
        } else {
          this.message.add({
            key: 'cxctrncancelacion',
            severity: 'error',
            summary: 'Información',
            detail: 'No hay un documento para la eliminación.'
          });
        }
      } else if (this.activeIndex === 3) {
        this.message.add({
          key: 'cxctrncancelacion',
          severity: 'error',
          summary: 'Eliminación de Anexos',
          detail: 'No se puede eliminar un anexo, solo modificar'
        });
      }
    } else if (this.permisosCartera.PERELIMACION === 0) {
      this.message.add({
        key: 'cxctrncancelacion',
        severity: 'error',
        summary: 'Eliminación de Cancelación',
        detail: 'El usuario no tiene permisos de eliminación, acción denegada'
      });
    }
    document.getElementById("errasiento").style.display = 'none';
  }
  async cxc_fnc_verificar_ant_util(strTrnCobro) {
    let intSw = 0;

    
    try {
      const data = await this.cxctrncancelacionService.cxc_fnc_verificar_ant_util(strTrnCobro);

      for (const rs of data) {
        intSw = rs.DATO;
      }
    } catch (error) {
      console.log(error);
      this.message.add({
        key: 'cxctrncancelacion',
        severity: 'success',
        summary: 'Error',
        detail: 'No se puede verificar si existe algun anticipo asociado'
      });
    }

    return intSw;
  }
  confirmarCartera() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el documento',
      header: 'Eliminar Cancelación',
      icon: 'pi pi-exclamation-triangle',
      key: 'confCxctrncancelacion',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.eliminarCartera();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }
  async eliminarCartera() {
    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador] !== undefined) {
      // if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC' ||
      //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
      //   this.cxctrncancelacionService.existePagoDoc(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((comp) => {
      //     if (comp.length !== 0) {
      //       this.message.add({
      //         key: 'cxctrncancelacion',
      //         severity: 'error',
      //         summary: 'Eliminación de Cancelación',
      //         detail: 'El documento tiene cobros, por lo que no se puede eliminar'
      //       });
      //       return;
      //     }
      //   });
      // }
      if (this.tabFormaPago === true) {
        if (this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].length !== 0) {
          this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].map(async (formap) => {
            if (formap.TRNPAGO_TIPO === 'ANCL') {
              const eR = await this.cxctrncancelacionService.erTrnpagorefer(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
              let strNumDocAnt = '';
              if (eR !== undefined) {
                if (eR[0].TRNPAGO_REFER !== null) {
                  strNumDocAnt = eR[0].TRNPAGO_REFER;
                }
              }

              await this.cxctrncancelacionService.eliminarTrnCobroProm(strNumDocAnt);
              this.eliminarPagosCuotas();
              this.cxctrncancelacionService.eliminarNDATrnResumen(formap.TRNCOBRO_NRODOC).subscribe(() => {
                console.log('Se actualiza');
              }, error1 => {
                this.message.add({
                  key: 'cxctrncancelacion',
                  severity: 'error',
                  summary: 'Eliminación de Cancelación',
                  detail: 'No se pudo eliminar la cancelación, error ' + error1.error
                });
              });
              this.auditoriagral.registrarAuditoria('CXC_TRNCANCELACION', String(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
                '', '01', '', '', '', '').subscribe(() => {
                  this.message.add({
                    key: 'cxctrncancelacion',
                    severity: 'success',
                    summary: 'Eliminación de Cancelación',
                    detail: 'Se eliminó la cancelación correctamente'
                  });
                  this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador] = {
                    SALDO: '',
                    TRNCOBRO_TIPODOC: '',
                    TOTALFORMAPAGO: 0,
                    TOTALND: 0,
                    TRNCOBRO_IMPORTE: 0,
                    TOTALNC: 0,
                    TOTALOTROSG: 0,
                    TOTALRET: 0,
                    TOTALACOBRAR: 0,
                    TOTALDOC: 0,
                    BAN_BANCO: '',
                    BAN_CODIGO: '',
                    TRNCOBRO_NROCOMPROBANTE: '',
                    TRNCOBRO_SALDOINI: '',
                    CON_CODIGO: '',
                    TRNCOBRO_NRODOC: '',
                    TOTALANTICIPO: '',
                    TRNCOBRO_FECHAVENCE: '',
                    CLI_NOMBRE: '',
                    REALIZADO: '',
                    ORIGEN: '',
                    VEN_NOMBRE: '',
                    APLORG_CODIGO: 'CXC',
                    USU_IDENTIFICACION: this.usuario.identificacion,
                    TRNCOBRO_CONCEPTO: '',
                    BOD_CODIGO: '',
                    TRNCOBRO_FECHATRN: new Date(),
                    VEN_CODIGO: '',
                    CLI_CODIGO: '',
                    ASI_NRO: '',
                    CABROLADMN_CODIGO: '',
                    CABROLVEND_CODIGO: '',
                    COM_CODIGO: '01',
                    COM_CODIGO_CXP: '01',
                    COM_TIPOAMBFACTELEC: '',
                    EMP_CODIGO: '',
                    EMP_CODIGOVEND: '',
                    TRNCOBRO_ANULADO: '',
                    TRNCOBRO_CAMBIO: '',
                    TRNCOBRO_CUADRE: '',
                    TRNCOBRO_EFECTIVO: '',
                    TRNCOBRO_FLAG: '1',
                    TRNCOBRO_IMPRESO: '',
                    TRNCOBRO_LOCALIZACIONXMLRET: '',
                    TRNCOBRO_NRODOC_CXP: '',
                    TRNCOBRO_NRODOC_OLD: '',
                    TRNCOBRO_PATHPDF_FE: '',
                    TRNCOBRO_PATHXMLNOAUTO_FE: '',
                    TRNCOBRO_PATHXMLRET: '',
                    TRNCOBRO_REFERENCIAEXTERNA: '',
                    TRNCOBROND_AUTORIZACION_FE: '',
                    TRNCOBROND_CLAVEACCESO_FE: '',
                    TRNCOBROND_ESTADO_FE: '',
                    TRNCOBROND_FECHAAUT_FE: '',
                    TRNCOBROND_NUMFACTELEC: ''
                  };
                  this.booleanBodega = false;
                  this.saldoInicial = false;
                  this.cxctrncancelacionService.cxctrnresumenarray[this.indicador] = [];
                  this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador] = [];
                  this.cxctrncancelacionService.cxctrnanticiposarray[this.indicador] = [];
                });


            }
          });
        }
      }

      if (this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ELIASIELI').CFG_VALOR1 === 0) {
        if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO !== '' && this.saldoInicial === false) {
          this.cxctrncancelacionService.anularAsiento(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO).subscribe(() => {
          });
        } else if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO !== '' && this.saldoInicial === true) {
          this.cxctrncancelacionService.actualizarAsiento(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
          });
        }
      } else {
        if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO !== '' && this.saldoInicial === false) {
          this.cxctrncancelacionService.eliminarAsiento(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO).subscribe(() => {
          });
        } else if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO !== '' && this.saldoInicial === true) {
          this.cxctrncancelacionService.actualizarAsiento(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
          });
        }
      }
      ////GSRF
     // this.eliminarFormaPagoDev();
      ////GSRF

      this.cxctrncancelacionService.eliminarAntAsoc(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe();
      try {
         //CDPJ 
         if(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'IMOBANCXCV').CFG_VALOR1 === 1){
          await this.cxctrncancelacionService.eliminarMovBan(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC)
         }      
         //CDPJ
        await this.cxctrncancelacionService.eliminarTrnCobroProm(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC)
        this.eliminarPagosCuotas();
        this.cxctrncancelacionService.eliminarNDATrnResumen(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
        });
        //GSRF 
        const claveac = this.cxctrncancelacionService.cxctrnretencionarray[this.indicador]
        console.log('1',claveac)
        console.log(claveac[0])
        if (claveac !== undefined && claveac !== null){
          if(claveac[0] !== undefined && claveac[0] !== null && claveac[0] !== ''){
            console.log('<<<<<<enta1r>>>>>>',  claveac[0].TRNRETENCION_AUTORIZACION)
          if( claveac[0].TRNRETENCION_AUTORIZACION !== null &&  claveac[0].TRNRETENCION_AUTORIZACION !=='' &&  claveac[0].TRNRETENCION_AUTORIZACION!==undefined){
            console.log('<<<<<<enta99991r>>>>>>')
            this.cxctrncancelacionService.deleteimptable(claveac[0].TRNRETENCION_AUTORIZACION)
            }
          }  
        }
        
        //GSRF
        this.auditoriagral.registrarAuditoria('CXC_TRNCANCELACION', String(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
          '', '01', '', '', '', '').subscribe(() => {
            this.message.add({
              key: 'cxctrncancelacion',
              severity: 'success',
              summary: 'Eliminación de Cancelación',
              detail: 'Se eliminó la cancelación exitosamente'
            });
            this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador] = {
              SALDO: '',
              TRNCOBRO_TIPODOC: '',
              TOTALFORMAPAGO: 0,
              TOTALND: 0,
              TRNCOBRO_IMPORTE: 0,
              TOTALNC: 0,
              TOTALOTROSG: 0,
              TOTALRET: 0,
              TOTALACOBRAR: 0,
              TOTALDOC: 0,
              BAN_BANCO: '',
              BAN_CODIGO: '',
              TRNCOBRO_NROCOMPROBANTE: '',
              TRNCOBRO_SALDOINI: '',
              CON_CODIGO: '',
              TRNCOBRO_NRODOC: '',
              TOTALANTICIPO: '',
              TRNCOBRO_FECHAVENCE: '',
              CLI_NOMBRE: '',
              REALIZADO: '',
              ORIGEN: '',
              VEN_NOMBRE: '',
              APLORG_CODIGO: 'CXC',
              USU_IDENTIFICACION: this.usuario.identificacion,
              TRNCOBRO_CONCEPTO: '',
              BOD_CODIGO: '',
              TRNCOBRO_FECHATRN: new Date(),
              VEN_CODIGO: '',
              CLI_CODIGO: '',
              ASI_NRO: '',
              CABROLADMN_CODIGO: '',
              CABROLVEND_CODIGO: '',
              COM_CODIGO: '01',
              COM_CODIGO_CXP: '01',
              COM_TIPOAMBFACTELEC: '',
              EMP_CODIGO: '',
              EMP_CODIGOVEND: '',
              TRNCOBRO_ANULADO: '',
              TRNCOBRO_CAMBIO: '',
              TRNCOBRO_CUADRE: '',
              TRNCOBRO_EFECTIVO: '',
              TRNCOBRO_FLAG: '1',
              TRNCOBRO_IMPRESO: '',
              TRNCOBRO_LOCALIZACIONXMLRET: '',
              TRNCOBRO_NRODOC_CXP: '',
              TRNCOBRO_NRODOC_OLD: '',
              TRNCOBRO_PATHPDF_FE: '',
              TRNCOBRO_PATHXMLNOAUTO_FE: '',
              TRNCOBRO_PATHXMLRET: '',
              TRNCOBRO_REFERENCIAEXTERNA: '',
              TRNCOBROND_AUTORIZACION_FE: '',
              TRNCOBROND_CLAVEACCESO_FE: '',
              TRNCOBROND_ESTADO_FE: '',
              TRNCOBROND_FECHAAUT_FE: '',
              TRNCOBROND_NUMFACTELEC: ''
            };
            this.booleanBodega = false;
            this.saldoInicial = false;
            this.cxctrncancelacionService.cxctrnresumenarray[this.indicador] = [];
            this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador] = [];
            this.cxctrncancelacionService.cxctrnformapagodevarray[this.indicador] = [];
            this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador] = [];
            this.cxctrncancelacionService.cxctrnretencionarray[this.indicador] = [];
            this.cxctrncancelacionService.cxcdebitobancarioarray[this.indicador] = [];
            this.cxctrncancelacionService.cxctrnvariosarray[this.indicador] = [];
            this.cxctrncancelacionService.cxctrnanticiposarray[this.indicador] = [];
          });
        if (this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].length !== 0) {
          this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].map((doc) => {
            if (doc.APLORG_CODIGO === 'SISTEMA') {
              this.cxctrncancelacionService.actualizarPagadoCuota(doc).subscribe(() => {
              });
            }
            if (doc.SELECCIONADO === true && doc.APLORG_CODIGO !== 'SISTEMA') {
              this.cxctrncancelacionService.actualizarestadoFac(doc).subscribe(() => {
              });
            }
          });
        }
      } catch (error1) {
        this.message.add({
          key: 'cxctrncancelacion',
          severity: 'error',
          summary: 'Eliminación de Cancelación',
          detail: 'No se pudo eliminar la cancelación, error ' + error1
        });
        console.log(error1)
      }
    } else {
      this.message.add({
        key: 'cxctrncancelacion',
        severity: 'error',
        summary: 'Eliminación de Cancelación',
        detail: 'Seleccione una cancelación para ser eliminada'
      });
    }
  }
  async guardar() {

    if (this.activeIndex === 0) {
      this.spin=true//CDPJ
      // validar la fecha de cobro
      if (this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].length > 0) {
        const strNOVFECCAN = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOVFECCAN').CFG_VALOR1 === null ? '' : this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOVFECCAN').CFG_VALOR1;
        if (strNOVFECCAN === 0) {
          if (!this.controlarFechaCobro()) {
            this.mensajeAlerta('info', 'Información', 'La fecha del cobro es menor a la fecha de los documentos.');
            this.spin=false//CDPJ
            return;
          }
          //GSRF
          if (!this.controlarFechaforpago()) {
            this.mensajeAlerta('info', 'Información', 'La fecha de pago es menor a la fecha de los documentos.');
            this.spin=false//CDPJ
            return;
          }
          // if (!this.controlarFechaforpagodev()) {
          //   this.mensajeAlerta('info', 'Información', 'La fecha de pago es menor a la fecha de los documentos.');
          //   this.spin=false//CDPJ
          //   return;
          // }
          //GSRF
        }
      }
      console.log(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC);
      // this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCarteraFormaPago.objeto];
      if (this.permisosCartera.PERACTUALIZACION === 1) {
        if (this.booleanNumero === false) {
          if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
            this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
            this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
            if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE !== '') {
              if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
                const exis = await this.cxctrncancelacionService.verificarComprobanteUnico('N',
                  this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE, '');

                if (exis[0][':B1'] !== '0' && exis[0][':B1'] !== null && exis[0][':B1'] !== undefined) {
                  this.message.add({
                    key: 'cxctrncancelacion',
                    severity: 'error',
                    summary: 'Inserción de Cancelación',
                    detail: 'El numero de comprobante ya existe'
                  });
                  this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
                  document.getElementById('numeroCartera').focus();
                  this.spin=false//CDPJ
                  return;
                }

              } else if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
                let numeroFac = '';
                if (this.cxctrnresumen.length !== 0) {
                  for (const resumen of this.cxctrnresumen) {
                    if (resumen.SELECCIONADO === true) {
                      numeroFac = resumen.TRNCOBRO_NRODOC;
                      const exis = await this.cxctrncancelacionService.verificarComprobanteUnico('M',
                        this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE, numeroFac).catch(e=>{
                          this.spin=false//CDPJ
                        });
                      console.log(exis);
                      if (exis[0][':B1'] !== '0') {
                        this.message.add({
                          key: 'cxctrncancelacion',
                          severity: 'error',
                          summary: 'Inserción de Cancelación',
                          detail: 'El numero de comprobante ya existe'
                        });
                        this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
                        document.getElementById('numeroCartera').focus();
                        this.spin=false//CDPJ
                        return;
                      } else {

                      }

                    }
                  }
                }
              }
              if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === '') {
                this.message.add({
                  key: 'cxctrncancelacion',
                  severity: 'error',
                  summary: 'Inserción de Cancelación',
                  detail: 'Escoja un tipo de documento'
                });
                this.spin=false//CDPJ
                return;
              } else if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO === '') {
                this.message.add({
                  key: 'cxctrncancelacion',
                  severity: 'error',
                  summary: 'Inserción de Cancelación',
                  detail: 'Escoja un cliente'
                });
                this.spin=false//CDPJ
                return;
              } else if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO === '') {
                this.message.add({
                  key: 'cxctrncancelacion',
                  severity: 'error',
                  summary: 'Inserción de Cancelación',
                  detail: 'Escoja un vendedor'
                });
                this.spin=false//CDPJ
                return;
              } else {
                let auxiliar = 0;
                let seleccionados = 0;
                if (this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].length !== 0) {
                  this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].map((resumen) => {
                    if (resumen.SELECCIONADO === true) {
                      seleccionados++;
                    }
                  });

                  if (seleccionados === 0) {
                    this.message.add({
                      key: 'cxctrncancelacion',
                      severity: 'error',
                      summary: 'Inserción de Cancelación',
                      detail: 'Ningún documento ha sido ' +
                        'seleccionado.'
                    });
                    this.spin=false//CDPJ
                    return;
                  }

                  if (Number(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC) === 0) {
                    this.message.add({
                      key: 'cxctrncancelacion',
                      severity: 'error',
                      summary: 'Inserción de Cancelación',
                      detail: 'El total debe ser diferente de cero'
                    });
                    this.spin=false//CDPJ
                    return;
                  }
                }
                if (this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].length !== 0) {
                  this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].map((formap) => {
                    if (formap.TRNPAGO_TIPO === 'TA' || formap.TRNPAGO_TIPO === 'CH') {
                      if (formap.BANCLI_CODIGO === '' || formap.TRNPAGO_NROCTA === '' || formap.TRNPAGO_NUMERO === '') { // formap.TRNPAGO_NUMERO
                        this.message.add({
                          key: 'cxctrncancelacion',
                          severity: 'error',
                          summary: 'Inserción de Cancelación',
                          detail: 'No se puede insertar el registro por que falta código de banco, número de pago, concepto o número de cuenta'
                        });
                        this.spin=false//CDPJ
                        auxiliar++;
                      }
                    }
                    let fecenc = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN;
                    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.length !== 10) {
                      fecenc = this.datePipe.transform(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
                    }
                    console.log(1, formap.TRNPAGO_TIPO, formap.TRNPAGO_FECHA, fecenc);
                    /* if (formap.TRNPAGO_TIPO === 'EF' || formap.TRNPAGO_TIPO === 'TA') { // formap.TRNPAGO_TIPO === 'DP' || 
                      if (formap.TRNPAGO_FECHA !== fecenc) {
                        this.message.add({
                          key: 'cartera',
                          severity: 'error',
                          summary: 'Inserción de Cancelación',
                          detail: 'Fecha de pago incorrecta'
                        });
                        auxiliar++;
                      }
                    } */
                  });
                  if (auxiliar === 0) {
                    this.guardarCartera();
                  }
                } else if (this.cxctrncancelacionService.cxctrnretencionarray[this.indicador].length !== 0) {
                  let auxiliar1 = 0;
                  let cont = 0;
                  let nroretencion = '';
                  this.cxctrncancelacionService.cxctrnretencionarray[this.indicador].map((ret) => {
                    if (ret.RETENCION_CODIGO === '' || ret.TRNRETENCION_NRO === '' || ret.TRNRETENCION_NRO === null) {
                      this.message.add({
                        key: 'cxctrncancelacion',
                        severity: 'error',
                        summary: 'Inserción de Cancelación',
                        detail: 'No se puede insertar el registro por que falta el código o número de retención'
                      });
                      this.spin=false//CDPJ
                      auxiliar1++;
                    }

                    // Verificar número de la retención

                    cont++;
                    if (cont > 1) {
                      if (nroretencion !== ret.TRNRETENCION_NRO) {
                        this.message.add({
                          key: 'cxctrncancelacion',
                          severity: 'error',
                          summary: 'Error',
                          detail: 'El número de la retención debe ser el mismo'
                        });
                        this.spin=false//CDPJ
                        auxiliar1++;
                      }
                    } else {
                      nroretencion = ret.TRNRETENCION_NRO;
                    }
                  });

                  if (auxiliar1 === 0) {
                    this.guardarCartera();
                  }
                } else {
                  this.guardarCartera();
                }
              }
            } else {
              if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === '') {
                this.message.add({
                  key: 'cxctrncancelacion',
                  severity: 'error',
                  summary: 'Inserción de Cancelación',
                  detail: 'Escoja un tipo de documento'
                });
                this.spin=false//CDPJ
                return;
              } else if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO === '') {
                this.message.add({
                  key: 'cxctrncancelacion',
                  severity: 'error',
                  summary: 'Inserción de Cancelación',
                  detail: 'Escoja un cliente'
                });
                this.spin=false//CDPJ
                return;
              } else if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO === '') {
                this.message.add({
                  key: 'cxctrncancelacion',
                  severity: 'error',
                  summary: 'Inserción de Cancelación',
                  detail: 'Escoja un vendedor'
                });
                this.spin=false//CDPJ
                return;
              } else {
                let auxiliar = 0;
                let seleccionados = 0;
                if (this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].length !== 0) {
                  this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].map((resumen) => {
                    if (resumen.SELECCIONADO === true) {
                      seleccionados++;
                    }
                  });
                  if (Number(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC) === 0) {
                    this.message.add({
                      key: 'cxctrncancelacion',
                      severity: 'error',
                      summary: 'Inserción de Cancelación',
                      detail: 'El total debe ser diferente de cero'
                    });
                    this.spin=false//CDPJ
                    return;
                  }
                  if (seleccionados === 0) {
                    this.message.add({
                      key: 'cxctrncancelacion',
                      severity: 'error',
                      summary: 'Inserción de Cancelación',
                      detail: 'Ningún documento ha sido ' +
                        'seleccionado.'
                    });
                    this.spin=false//CDPJ
                    return;
                  }
                }

                /* if (seleccionados === 0) {
                  this.message.add({
                    key: 'cartera',
                    severity: 'error',
                    summary: 'Inserción de Cancelación',
                    detail: 'Ningún documento ha sido ' +
                      'seleccionado.'
                  });
                  return;
                } */
                if (this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].length !== 0) {
                  this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].map((formap) => {
                    if (formap.TRNPAGO_TIPO === 'TA' || formap.TRNPAGO_TIPO === 'CH') { // formap.TRNPAGO_TIPO === 'DP' || formap.TRNPAGO_TIPO === 'CH'
                      if (formap.BANCLI_CODIGO === '' || formap.TRNPAGO_NROCTA === '' || formap.TRNPAGO_NUMERO === '') {
                        this.message.add({
                          key: 'cxctrncancelacion',
                          severity: 'error',
                          summary: 'Inserción de Cancelación',
                          detail: 'No se puede insertar el registro por que falta código de banco, número de pago, concepto o número de cuenta'
                        });
                        this.spin=false//CDPJ
                        auxiliar++;
                      }
                    }
                    let fecenc = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN;
                    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.length !== 10) {
                      fecenc = this.datePipe.transform(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
                    }
                    console.log(2, formap.TRNPAGO_TIPO, formap.TRNPAGO_FECHA, fecenc);
                    /* if ( formap.TRNPAGO_TIPO === 'EF' || formap.TRNPAGO_TIPO === 'TA') { // formap.TRNPAGO_TIPO === 'DP' 
                      if (formap.TRNPAGO_FECHA !== fecenc) {
                        this.message.add({
                          key: 'cartera',
                          severity: 'error',
                          summary: 'Inserción de Cancelación',
                          detail: 'Fecha de pago incorrecta'
                        });
                        auxiliar++;
                      }
                    } */
                  });
                  if (auxiliar === 0) {
                    this.guardarCartera();
                  }
                } else if (this.cxctrncancelacionService.cxctrnretencionarray[this.indicador].length !== 0) {
                  let auxiliar1 = 0;
                  let cont = 0;
                  let nroretencion = '';
                  this.cxctrncancelacionService.cxctrnretencionarray[this.indicador].map((ret) => {
                    if (ret.RETENCION_CODIGO === '' || ret.TRNRETENCION_NRO === '' || ret.TRNRETENCION_NRO === null) {
                      this.message.add({
                        key: 'cxctrncancelacion',
                        severity: 'error',
                        summary: 'Inserción de Cancelación',
                        detail: 'No se puede insertar el registro por que falta el código o número de retención'
                      });
                      this.spin=false//CDPJ
                      auxiliar1++;
                    }

                    // Verificar número de la retención

                    cont++;
                    if (cont > 1) {
                      if (nroretencion !== ret.TRNRETENCION_NRO) {
                        this.message.add({
                          key: 'cxctrncancelacion',
                          severity: 'error',
                          summary: 'Error',
                          detail: 'El número de la retención debe ser el mismo'
                        });
                        this.spin=false//CDPJ
                        auxiliar1++;
                      }
                    } else {
                      nroretencion = ret.TRNRETENCION_NRO;
                    }
                  });

                  if (auxiliar1 === 0) {
                    this.guardarCartera();
                  }
                } else {
                  this.guardarCartera();
                }
              }
            }
          }
        } else if (this.booleanNumero === true) {
          if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === '') {
            this.message.add({
              key: 'cxctrncancelacion',
              severity: 'error',
              summary: 'Inserción de Cancelación',
              detail: 'Escoja un tipo de documento'
            });
            this.spin=false//CDPJ
            return;
          } else if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO === '') {
            this.message.add({
              key: 'cxctrncancelacion',
              severity: 'error',
              summary: 'Inserción de Cancelación',
              detail: 'Escoja un cliente'
            });
            this.spin=false//CDPJ
            return;
          } else if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO === '') {
            this.message.add({
              key: 'cxctrncancelacion',
              severity: 'error',
              summary: 'Inserción de Cancelación',
              detail: 'Escoja un vendedor'
            });
            this.spin=false//CDPJ
            return;
          } else {
            let seleccionados = 0;
            if (this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].length !== 0) {
              this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].map((resumen) => {
                if (resumen.SELECCIONADO === true) {
                  seleccionados++;
                }
              });

              if (seleccionados === 0) {
                this.message.add({
                  key: 'cxctrncancelacion',
                  severity: 'error',
                  summary: 'Inserción de Cancelación',
                  detail: 'Ningún documento ha sido ' +
                    'seleccionado.'
                });
                this.spin=false//CDPJ
                return;
              }

              // if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'NC'
              //   && this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'RT') {
              //   if (Number(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC) === 0) {
              //     this.message.add({
              //       key: 'cartera',
              //       severity: 'error',
              //       summary: 'Inserción de Cancelación',
              //       detail: 'El total debe ser diferente de cero'
              //     });
              //     this.spin=false//CDPJ
              //     return;
              //   }
              // }
            }
            if (this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].length !== 0) {
              let auxiliar = 0;
              this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].map((formap) => {
                if (formap.TRNPAGO_TIPO === 'TA' || formap.TRNPAGO_TIPO === 'CH') { // formap.TRNPAGO_TIPO === 'DP' || formap.TRNPAGO_TIPO === 'CH'
                  if (formap.BANCLI_CODIGO === '' || formap.TRNPAGO_NROCTA === '' || formap.TRNPAGO_NUMERO === '') {
                    this.message.add({
                      key: 'cxctrncancelacion',
                      severity: 'error',
                      summary: 'Inserción de Cancelación',
                      detail: 'No se puede insertar el registro por que falta código de banco, número de pago, concepto o número de cuenta'
                    });
                    this.spin=false//CDPJ
                    auxiliar++;
                  }
                }

                let fecenc = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN;
                if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.length !== 10) {
                  fecenc = this.datePipe.transform(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
                }
                console.log(4, formap.TRNPAGO_TIPO, formap.TRNPAGO_FECHA, fecenc);
                /* if (formap.TRNPAGO_TIPO === 'EF' || formap.TRNPAGO_TIPO === 'TA') { //formap.TRNPAGO_TIPO === 'DP'
                  if (formap.TRNPAGO_FECHA !== fecenc) {
                    this.message.add({
                      key: 'cartera',
                      severity: 'error',
                      summary: 'Inserción de Cancelación',
                      detail: 'Fecha de pago incorrecta'
                    });
                    auxiliar++;
                  }
                } */
              });
              if (auxiliar === 0) {
                this.guardarCartera();
              }
            } else if (this.cxctrncancelacionService.cxctrnretencionarray[this.indicador].length !== 0) {

              let auxiliar1 = 0;
              let cont = 0;
              let nroretencion = '';
              if (this.verificarImporteTotalRet() === false) {
                auxiliar1++;
              }

              this.cxctrncancelacionService.cxctrnretencionarray[this.indicador].map((ret) => {
                if (ret.RETENCION_CODIGO === '' || ret.TRNRETENCION_NRO === '' || ret.TRNRETENCION_NRO === null) {
                  this.message.add({
                    key: 'cxctrncancelacion',
                    severity: 'error',
                    summary: 'Inserción de Cancelación',
                    detail: 'No se puede insertar el registro por que falta el código o número de retención'
                  });
                  this.spin=false//CDPJ
                  auxiliar1++;
                }

                // Verificar número de la retención

                cont++;
                if (cont > 1) {
                  if (nroretencion !== ret.TRNRETENCION_NRO) {
                    this.message.add({
                      key: 'cxctrncancelacion',
                      severity: 'error',
                      summary: 'Error',
                      detail: 'El número de la retención debe ser el mismo'
                    });
                    this.spin=false//CDPJ
                    auxiliar1++;
                  }
                } else {
                  nroretencion = ret.TRNRETENCION_NRO;
                }
              });

              if (auxiliar1 === 0) {
                this.guardarCartera();
              }
            } else {
              this.guardarCartera();
            }
          }
        }
      } else if (this.permisosCartera.PERACTUALIZACION === 0) {
        this.message.add({
          key: 'cxctrncancelacion',
          severity: 'error',
          summary: 'Actualización de Cancelación',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
        this.spin=false//CDPJ
      }
    }

    // if (this.activeIndex === 3) {
    //   this.validacionTiposSri();
    // }
  }
  async guardarCartera() {
    this.editpagofr = true;
    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.toString().includes('-')) {
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN =
        this.datePipe.transform(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN,
          'dd/MM/yyyy');
    } else if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.toString().includes('/')) {
    }
    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE.toString().includes('-')) {
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE =
        this.datePipe.transform(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE,
          'dd/MM/yyyy');
    } else if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE.toString().includes('/')) {
    }
     if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
      await this.saveCAABANC();
    }  
  }
  async saveCAABANC() {
    console.log('saveCAABANC');
    //GSRF
    for (const item of this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador]) {
      console.log('-->',item.CAJ_CODIGO)
      let caja = await this.cxctrncancelacionService.getcaja(item.CAJ_CODIGO)
      let numdep = await this.cxctrncancelacionService.getDP(item.TRNPAGO_NUMERO,item.BANCLI_CODIGO);
      console.log('-->',item.TRNPAGO_TIPO)
      console.log('-->',caja.length)
      console.log('-->',numdep.length)
      if(item.TRNPAGO_TIPO !=='DP' && item.TRNPAGO_TIPO === 'EF'){
      if(caja.length === 0 ){
        this.message.add({
          key: 'cxctrncancelacion',
          severity: 'error',
          summary: 'Inserción de Cancelación',
          detail: 'Caja no existe'
        });
        this.spin=false//CDPJ
        return;
      }
    }

    if(item.TRNPAGO_TIPO ==='DP'){
      if (item.BANCLI_CODIGO === null || item.BANCLI_CODIGO === '' || item.BANCLI_CODIGO === undefined ){
        this.message.add({
          key: 'cxctrncancelacion',
          severity: 'error',
          summary: 'Inserción de Cancelación',
          detail: 'Ingrese Banco'
        });
        this.spin=false//CDPJ
        return;
      }
      if (item.TRNPAGO_NUMERO === null || item.TRNPAGO_NUMERO === '' || item.TRNPAGO_NUMERO === undefined ){
        this.message.add({
          key: 'cxctrncancelacion',
          severity: 'error',
          summary: 'Inserción de Cancelación',
          detail: 'Ingrese Número'
        });
        this.spin=false//CDPJ
        return;
      }
      if(numdep.length !== 0 ){
        this.message.add({
          key: 'cxctrncancelacion',
          severity: 'error',
          summary: 'Inserción de Cancelación',
          detail: 'El número ya existe'
        });
        this.spin=false//CDPJ
        return;
      }
    }
      };

     
      //GSRF
    if ((Number(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR) !==
      Number(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO)) && this.saldoInicial === false) {
      this.message.add({
        key: 'cxctrncancelacion',
        severity: 'error',
        summary: 'Inserción de Cancelación',
        detail: 'El total de la forma de pago es distinto al total a cobrar'
      });
      this.spin=false//CDPJ
      return;
    }
     if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA') {
      console.log('3');
      let numeroDoc = '';
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
      if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
        console.log('4');
        this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE =
          this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC;
        let cobro;
        try {
          cobro = await this.cxctrncancelacionService.insertarCobroP(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador],
            this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC);
          console.log('1 Insertar Cobro');
          this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
          if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE === '') {
            console.log(cobro[0]);
            this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = cobro[0].TRNCOBRO_NRODOC;
          }

          const saldo = await this.cxctrncancelacionService.obtenerSaldoClienteP(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO);
          console.log('2 Obtiene saldo cliente');
          this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;

          if (this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].length !== 0) {
            for (const resumen of this.cxctrncancelacionService.cxctrnresumenarray[this.indicador]) {
              if (resumen.SELECCIONADO === true) {
                resumen.CLI_CODIGO = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                numeroDoc = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
                resumen.STRCUOTAFAC = 'F';
                if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                  resumen.STRCUOTAFAC = 'C';
                }
                await this.cxctrncancelacionService.insertarDocPorCobrarP(resumen, this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                  this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
                console.log('3 insertarDocPorCobrar');
                if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                  await this.actualizarCuotasPagadas(resumen);
                  console.log('4 actualizarCuotasPagadas');
                }
                this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
                  '', '01', '', '', '', '').subscribe(() => {
                  });
              }
            }
          }

          this.auditoriagral.registrarAuditoria('CXC_TRNCANCELACION', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'I',
            '', '01', '', '', '', '').subscribe(() => {
              this.message.add({
                key: 'cxctrncancelacion',
                severity: 'success',
                summary: 'Inserción de Cancelación',
                detail: 'Se ha insertado el documento exitosamente'
              });
              this.spin=false//CDPJ
            });

          if (this.cxctrncancelacionService.cxctrnretencionarray[this.indicador].length !== 0) {
            for (const ret of this.cxctrncancelacionService.cxctrnretencionarray[this.indicador]) {
              ret.CLI_CODIGO = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
              if (ret.CON_CODIGO === '') {
                ret.CON_CODIGO = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
              }
              ret.CEN_CODIGO = '';
              ret.ENCFAC_NUMERO = this.numeroFactura;
              await this.cxctrncancelacionService.insertarRetencionP(ret, this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
              console.log('5 insertarRetencion');
              if (this.retencionElect === true) {
                await this.cxctrncancelacionService.actualizarTipoRetencionProm(ret, 'E');
                console.log('6 insertarRetencion');
              } else if (this.retencionElect === false) {
                await this.cxctrncancelacionService.actualizarTipoRetencionProm(ret, 'F');
                console.log('6 insertarRetencion');
              }
            }
          }

          if (this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador].length !== 0) {
            for (const com of this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador]) {
              if (com.CON_CODIGO === '' || com.CON_CODIGO === null) {
                com.CON_CODIGO = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
              }

              await this.cxctrncancelacionService.insertarComisionProm(com, this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
              console.log('7 insertarComision');

              com.TRNCOBRO_NRODOC = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
              this.auditoriagral.registrarAuditoria('SACI_TRNCOMISION', String(com.TRNCOMISION_NRO + '/' + cobro[0].TRNCOBRO_NRODOC), 'I',
                '', '01', '', '', '', '').subscribe(() => {
                });
            }
          }


        } catch (err) {
          this.message.add({
            key: 'cxctrncancelacion',
            severity: 'error',
            summary: 'Error',
            detail: err.error
          });
          this.spin=false//CDPJ
        }
        try {
          if (this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].length !== 0) {
            await this.cxctrncancelacionService.eliminarFormaPagoProm(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
            console.log('8 eliminarFormaPago');
            for (const formap of this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador]) {
              //if (formap.TRNPAGO_TIPO === 'DP') {
              //  await this.cxctrncancelacionService.eliminarDPBancoProm(formap.TRNPAGO_NUMERO);
              //  console.log('9 eliminarDPBanco');
              //}
              formap.CLI_CODIGO = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
              if (formap.TRNPAGO_CONCEPTO === '' || formap.TRNPAGO_CONCEPTO === null) {
                formap.TRNPAGO_CONCEPTO = '*';
              }

              await this.cxctrncancelacionService.insertarFormadePagoProm(formap, this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
              console.log('10 insertarFormadePago');
              console.log(formap.TRNPAGO_TIPO);
              if (formap.TRNPAGO_TIPO === 'ANCL') {
                const itemND: Cxctrncobro = {};
                itemND.TRNCOBRO_NRODOC = '';
                itemND.TRNCOBRO_NROCOMPROBANTE = '';
                itemND.TRNCOBRO_FECHATRN = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN;
                itemND.TRNCOBRO_CONCEPTO = 'NOTA DE DEBITO POR EL ANTICIPO ' + formap.TRNPAGO_NUMERO;
                itemND.TRNCOBRO_IMPORTE = formap.TRNPAGO_IMPORTE;
                itemND.TRNCOBRO_FECHAVENCE = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE === null ? null :
                  this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE;
                itemND.APLORG_CODIGO = 'SISTEMA';
                itemND.TRNCOBRO_REFERENCIAEXTERNA = null;
                itemND.CLI_CODIGO = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                itemND.VEN_CODIGO = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO;
                itemND.ASI_NRO = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO;
                itemND.USU_IDENTIFICACION = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].USU_IDENTIFICACION;
                itemND.TRNCOBRO_FLAG = '1';
                itemND.BAN_CODIGO = null;
                itemND.TRNCOBRO_EFECTIVO = '0.0';
                itemND.TRNCOBRO_CAMBIO = '0.0';
                itemND.CON_CODIGO = null;
                itemND.TRNCOBRO_SALDOINI = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI;
                itemND.BOD_CODIGO = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO;

                console.log(itemND);
                const data = await this.cxctrncancelacionService.insertarCobroP(itemND, 'NDA');
                console.log('11 insertarCobro NDA');
                console.log(data);
                let strNumDocAnt = '';
                let strTrnCobroNroDoc = '';
                let strTrnCobroNroComprobante = '';

                const eR = await this.cxctrncancelacionService.erTrncobronrodocProm(formap.TRNPAGO_NUMERO);
                console.log(eR);
                console.log('12 encontrar TRNCOBRO_NRODOC');
                if (eR !== undefined) {
                  if (eR[0].TRNCOBRO_NRODOC !== null) {
                    strNumDocAnt = eR[0].TRNCOBRO_NRODOC;
                  }
                }

                for (const rs of data) {
                  strTrnCobroNroDoc = rs.TRNCOBRO_NRODOC;
                  strTrnCobroNroComprobante = rs.TRNCOBRO_NROCOMPROBANTE;
                }
                // actualizar la referencia de la nota de débito con el número del anticipo strTrnCobroNroDoc
                await this.cxctrncancelacionService.actualizarNotaDebitoAnticipoProm(strTrnCobroNroDoc, strNumDocAnt);
                console.log('12 actualizarNotaDebitoAnticipo');
                await this.cxctrncancelacionService.actualizarFormaPagoNDProm(strTrnCobroNroDoc,
                  formap.TRNPAGO_NUMERO, this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
                console.log('12 actualizarFormaPagoND');
              }
              this.auditoriagral.registrarAuditoria('CXC_TRNPAGO', String(cobro[0].TRNCOBRO_NRODOC + '/' + formap.TRNPAGO_NUMERO), 'I',
                '', '01', '', '', '', '').subscribe(() => {
                });
            }
          }
        } catch (err) {
          this.message.add({
            key: 'cxctrncancelacion',
            severity: 'error',
            summary: 'Forma de Pago',
            detail: 'No se guardo la forma de pago, error ' + err.error
          });
          this.spin=false//CDPJ
        }
        try {
          await this.cxctrncancelacionService.generarAsientoCIProm(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador]);
          console.log('12 generarAsientoCI');
          const res = await this.cxctrncancelacionService.getAsientoProm(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
          console.log('12 getAsiento', res);
          this.asiento = res[0].ASI_NRO;
          this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
          if (this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].length !== 0) {
            for (const resumen of this.cxctrncancelacionService.cxctrnresumenarray[this.indicador]) {
              console.log(resumen);
              if (resumen.SELECCIONADO === true) {
                resumen.CLI_CODIGO = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                await this.cxctrncancelacionService.actualizarestadoFacProm(resumen);
                console.log('actualizarestadoFac');
              }
            }
          }
          this.actualizarNumeroAsiento(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
          this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
            '', '01', '', '', '', '').subscribe(() => {
              this.message.add({
                key: 'cxctrncancelacion',
                severity: 'success',
                summary: 'Asiento Contable',
                detail: 'Se ha generado el asiento contable'
              });
              this.spin=false//CDPJ
            });

        } catch (err) {
          this.message.add({
            key: 'cxctrncancelacion',
            severity: 'error',
            summary: 'Asiento Contable',
            detail: 'No se ha generado el asiento contable, error' + err.error
          });
          this.spin=false//CDPJ
        }

        this.cambiarBotones();
        await this.cancelarEdicionCartera();

        if (this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NROCMPRAUT').CFG_VALOR1 === 1) {
          this.cxctrncancelacionService.actualizarNumeracion(this.numSerie).subscribe((res) => {
            console.log(res);
          });
        }

      } else if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
        this.message.add({
          key: 'cxctrncancelacion',
          severity: 'error',
          summary: 'Cancelación',
          detail: 'No es posible actualizar el documento. Elimine y vuelva a ingresar.'
        });
        this.spin=false//CDPJ
        return;

        this.cxctrncancelacionService.actualizarCobro(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador],
          this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
            this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
            this.cxctrncancelacionService.obtenerSaldoCliente(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
              this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
              this.cxctrncancelacionService.eliminarTrnDocumento('TRNCOBRO_NRODOC = \'' +
                this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'').subscribe(() => {
                  if (this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].length !== 0) {
                    this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].map((resumen) => {
                      if (resumen.SELECCIONADO === true) {
                        numeroDoc = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
                        resumen.CLI_CODIGO = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                        resumen.STRCUOTAFAC = 'F';
                        if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                          resumen.STRCUOTAFAC = 'C';
                        }
                        this.cxctrncancelacionService.insertarDocPorCobrar(resumen, this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                          this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                            if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                              this.actualizarCuotasPagadas(resumen);
                            }
                            this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
                              '', '01', '', '', '', '').subscribe(() => {
                              });
                          });
                      }
                    });
                  }
                  if (this.cxctrncancelacionService.cxctrnretencionarray[this.indicador].length !== 0) {
                    this.cxctrncancelacionService.eliminarTrnRetencion('TRNCOBRO_NRODOC = \'' +
                      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC +
                      '\' AND COM_CODIGO = \'01\' AND TRNRETENCION_ORIGEN = \'CXC\'').subscribe(() => {
                        this.cxctrncancelacionService.cxctrnretencionarray[this.indicador].map((ret) => {
                          ret.CLI_CODIGO = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                          if (ret.CON_CODIGO === '') {
                            ret.CON_CODIGO = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
                          }
                          ret.CEN_CODIGO = '';
                          ret.ENCFAC_NUMERO = this.numeroFactura;
                          this.cxctrncancelacionService.insertarRetencion(ret, this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                            this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                              if (this.retencionElect === true) {
                                this.cxctrncancelacionService.actualizarTipoRetencion(ret, 'E').subscribe(() => {
                                });
                              } else if (this.retencionElect === false) {
                                this.cxctrncancelacionService.actualizarTipoRetencion(ret, 'F').subscribe(() => {
                                });
                              }
                            });
                        });
                      });
                  }
                  if (this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador].length !== 0) {
                    this.cxctrncancelacionService.eliminarTrnComision(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
                      this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador].map((com) => {
                        if (com.CON_CODIGO === '' || com.CON_CODIGO === null) {
                          com.CON_CODIGO = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
                        }
                        this.cxctrncancelacionService.insertarComision(com, this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                          this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                            com.TRNCOBRO_NRODOC = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
                            this.auditoriagral.registrarAuditoria('SACI_TRNCOMISION', String(com.TRNCOMISION_NRO + '/' + cobro[0].TRNCOBRO_NRODOC), 'I',
                              '', '01', '', '', '', '').subscribe(() => {
                              });
                          });
                      });
                    });
                  }
                  if (this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].length !== 0) {
                    this.cxctrncancelacionService.eliminarFormaPago(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
                      this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].map((formap) => {
                        if (formap.TRNPAGO_TIPO === 'DP') {
                          this.cxctrncancelacionService.eliminarDPBanco(formap.TRNPAGO_NUMERO).subscribe(() => {
                          });
                        }
                        formap.CLI_CODIGO = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                        if (formap.TRNPAGO_CONCEPTO === '' || formap.TRNPAGO_CONCEPTO === null) {
                          formap.TRNPAGO_CONCEPTO = '*';
                        }
                        this.cxctrncancelacionService.insertarFormadePago(formap, this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                          this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(async () => {
                            if (formap.TRNPAGO_TIPO === 'ANCL') {
                              const itemND: Cxctrncobro = {};
                              itemND.TRNCOBRO_NRODOC = '';
                              itemND.TRNCOBRO_NROCOMPROBANTE = '';
                              itemND.TRNCOBRO_FECHATRN = this.datePipe.transform(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
                              itemND.TRNCOBRO_CONCEPTO = 'NOTA DE DEBITO POR EL ANTICIPO ' + formap.TRNPAGO_NUMERO;
                              itemND.TRNCOBRO_IMPORTE = formap.TRNPAGO_IMPORTE;
                              itemND.TRNCOBRO_FECHAVENCE = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE === null ? null :
                                this.datePipe.transform(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
                              itemND.APLORG_CODIGO = 'SISTEMA';
                              itemND.TRNCOBRO_REFERENCIAEXTERNA = null;
                              itemND.CLI_CODIGO = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                              itemND.VEN_CODIGO = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO;
                              itemND.ASI_NRO = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO;
                              itemND.USU_IDENTIFICACION = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].USU_IDENTIFICACION;
                              itemND.TRNCOBRO_FLAG = '1';
                              itemND.BAN_CODIGO = null;
                              itemND.TRNCOBRO_EFECTIVO = '0.0';
                              itemND.TRNCOBRO_CAMBIO = '0.0';
                              itemND.CON_CODIGO = null;
                              itemND.TRNCOBRO_SALDOINI = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI;
                              itemND.BOD_CODIGO = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO;

                              let strNumDocAnt = '';

                              const eRf = await this.cxctrncancelacionService.erTrnpagorefer(formap.TRNCOBRO_NRODOC);
                              if (eRf !== null) {
                                for (const rs of eRf) {
                                  strNumDocAnt = rs.TRNPAGO_REFER;
                                }
                              }

                              console.log(strNumDocAnt);
                              let data: any;
                              if (strNumDocAnt === '') {
                                let strTrnCobroNroDoc = '';
                                let strTrnCobroNroComprobante = '';
                                data = await this.cxctrncancelacionService.insertarCobroP(itemND, 'NDA');
                                console.log('11 insertarCobro NDA');
                                console.log(data);
                                const eR = await this.cxctrncancelacionService.erTrncobronrodocProm(formap.TRNPAGO_NUMERO);
                                console.log(eR);
                                console.log('12 encontrar TRNCOBRO_NRODOC');
                                if (eR !== undefined) {
                                  if (eR[0].TRNCOBRO_NRODOC !== null) {
                                    strNumDocAnt = eR[0].TRNCOBRO_NRODOC;
                                  }
                                }
                                for (const rs of data) {
                                  strTrnCobroNroDoc = rs.TRNCOBRO_NRODOC;
                                  strTrnCobroNroComprobante = rs.TRNCOBRO_NROCOMPROBANTE;
                                }
                                // actualizar la referencia de la nota de débito con el número del anticipo strTrnCobroNroDoc
                                await this.cxctrncancelacionService.actualizarNotaDebitoAnticipoProm(strTrnCobroNroDoc, strNumDocAnt);
                                console.log('12 actualizarNotaDebitoAnticipo');
                                await this.cxctrncancelacionService.actualizarFormaPagoNDProm(strTrnCobroNroDoc,
                                  formap.TRNPAGO_NUMERO, this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
                                console.log('12 actualizarFormaPagoND');
                              } else {
                                data = await this.cxctrncancelacionService.actualizarCobroProm(itemND, 'NDA');
                                console.log('11 actualizarCobro NDA');
                              }
                            }
                            this.cxctrncancelacionService.generarAsientoCI(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                              this.cxctrncancelacionService.getAsiento(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
                                this.asiento = res[0].ASI_NRO;
                                this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
                                if (this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].length !== 0) {
                                  this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].map((resumen) => {
                                    if (resumen.SELECCIONADO === true) {
                                      resumen.CLI_CODIGO = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                                      this.cxctrncancelacionService.actualizarestadoFac(resumen).subscribe(() => {
                                      }, error1 => {
                                        console.log(error1.error);
                                      });
                                    }
                                  });
                                }
                                this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
                                  '', '01', '', '', '', '').subscribe(() => {
                                  });
                              });
                            }, () => {
                            });
                            this.auditoriagral.registrarAuditoria('CXC_TRNPAGO', String(cobro[0].TRNCOBRO_NRODOC + '/' + formap.TRNPAGO_NUMERO), 'I',
                              '', '01', '', '', '', '').subscribe(() => {
                              });
                          }, error1 => {
                            this.message.add({
                              key: 'cxctrncancelacion',
                              severity: 'error',
                              summary: 'Forma de Pago',
                              detail: 'No se guardo la forma de pago, error ' + error1.error
                            });
                          });
                      });
                    });
                  }
                  this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'A',
                    '', '01', '', '', '', '').subscribe(() => {
                      this.message.add({
                        key: 'cxctrncancelacion',
                        severity: 'success',
                        summary: 'Inserción de Cancelación',
                        detail: 'Se ha insertado el documento exitosamente'
                      });
                    });
                  this.cambiarBotones();
                  this.cancelarEdicionCartera();
                  setTimeout(() => {
                    this.cxctrncancelacionService.generarAsientoCI(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                      this.cxctrncancelacionService.getAsiento(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
                        this.asiento = res[0].ASI_NRO;
                        this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
                        if (this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].length !== 0) {
                          this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].map((resumen) => {
                            if (resumen.SELECCIONADO === true) {
                              resumen.CLI_CODIGO = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                              this.cxctrncancelacionService.actualizarestadoFac(resumen).subscribe(() => {
                              }, error1 => {
                                console.log(error1.error);
                              });
                            }
                          });
                        }
                        this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
                          '', '01', '', '', '', '').subscribe(() => {
                            this.message.add({
                              key: 'cxctrncancelacion',
                              severity: 'success',
                              summary: 'Asiento Contable',
                              detail: 'Se ha generado el asiento contable'
                            });
                          });
                      });
                    }, error1 => {
                      this.message.add({
                        key: 'cxctrncancelacion',
                        severity: 'error',
                        summary: 'Asiento Contable',
                        detail: 'No se ha generado el asiento, error ' + error1.error
                      });
                    });
                  }, 500);
                });
            });
            // this.cambiarBotones();
            // this.consultarPestañas();
          });
      }
    }
  }
  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'cxctrncancelacion',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
  cambiarBotones() {
    this.botonPrimero = false;
    this.botonAnterior = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.botonBuscar = false;
    this.botonAsiento = false;
    this.botonVerAsiento = false;
    this.botonAnular = false;
  }
  async actualizarNumeroComprobante(strAsiNro) {
    if (this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMASICOM').CFG_VALOR1 === 0) {
      return;
    }

    if (strAsiNro === '') {
      return;
    }

    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE !== '') {//si no esta en blanco el comprobante lo respeta
      return;
    }

    try {
      await this.cxctrncancelacionService.actualizarNumeroComprobante(strAsiNro, this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = strAsiNro;
    } catch (err) {
      this.spin=false//CDPJ
      this.mensajeAlerta('error', 'Error', '! Proceso actualiza numero de comprobante  no terminó satisfactoriamente.... ! ' + err.error);
    }

  }
  async verificarComprobanteUnico() {
    let bolVerificar = true;
    const strParam: any[] = [];
    if (this.bolNuevo) {
      strParam[0] = 'N';
      strParam[3] = '';
    } else {
      strParam[0] = 'M';
      if (this.cxctrnresumen.length > 0) {
        strParam[3] = this.cxctrnresumen[0].TRNCOBRO_NRODOC;
      } else {
        strParam[3] = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
      }
    }

    strParam[1] = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
    strParam[2] = '01';
    /*try {
      const rs = await this.cxctrncancelacionService.verificarComprobanteUnicoNew(strParam);
      console.log(rs[0]["'0'"]);
      console.log(rs[0][':B1']);
      if (rs[0][':B1'] !== undefined) {
        bolVerificar = false;
      }
			/*if (rs.next()) {
				if (!rs.getString(1).equals("0"))
					bolVerificar = false;
			} 
    }*//* catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede verificar si el comprobante es único ' + mensaje);
    }*/
    return bolVerificar;
  }
  controlarFechaforpago() {
    let bolCorrecto = true;
    let strFechaDoc = this.obtenerFechaDoc();
    for (const item of this.cxctrncancelacionService.cxctrnresumenarray[this.indicador]) {
      if (item.SELECCIONADO !== null) {
        if (item.SELECCIONADO) {
          const strFechaFact = this.dateValidation(item.TRNCOBRO_FECHATRN);
          for (const item1 of this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador]) {
            if (item1.TRNPAGO_TIPO !== null) {
              const strFechaFact1 = this.dateValidation(item1.TRNPAGO_FECHA);
              // console.log(strFechaFact1.getTime() > strFechaFact.getTime());
              //console.log(strFechaFact1.getTime(), strFechaFact.getTime());
              if (strFechaFact.getTime() > strFechaFact1.getTime()) {
                bolCorrecto = false;
              }
            }
          }
        }
      }
    };

    return bolCorrecto;
  }
  verificarImporteTotalRet(): boolean {
    let bolVerificar = true;
    for (const itemDoc of this.cxctrncancelacionService.cxctrnresumenarray[this.indicador]) {

      if (itemDoc.SELECCIONADO === true) {
        let dblTotalRet = 0;
        for (const itemRet of this.cxctrncancelacionService.cxctrnretencionarray[this.indicador]) {

          if (itemDoc.TRNCOBRO_NRODOC === itemRet.ENCFAC_NUMERO) {
            dblTotalRet = dblTotalRet + Number(itemRet.TRNRETENCION_TOTALRETENIDO);
          }
        }

        dblTotalRet = Number(Number(dblTotalRet).toFixed(2));

        if (Number(itemDoc.TRNCOBRO_IMPORTE) != dblTotalRet) {
          this.mensajeAlerta('error', 'Error', 'El importe de la factura ' + itemDoc.TRNCOBRO_NRODOC +
            ' no es igual al total de las retenciones');
          bolVerificar = false;
          return bolVerificar;
        }
      }
    }

    return bolVerificar;
  }
  controlarFechaCobro() {
    let bolCorrecto = true;
    let strFechaDoc = this.obtenerFechaDoc();

    for (const item of this.cxctrncancelacionService.cxctrnresumenarray[this.indicador]) {
      if (item.SELECCIONADO !== null) {
        if (item.SELECCIONADO) {
          const strFechaFact = this.dateValidation(item.TRNCOBRO_FECHATRN);
          console.log(strFechaFact.getTime() > strFechaDoc.getTime());
          console.log(strFechaFact.getTime(), strFechaDoc.getTime());
          if (strFechaFact.getTime() > strFechaDoc.getTime()) {
            bolCorrecto = false;
          }
        }
      }
    }

    return bolCorrecto;
  }
  obtenerFechaDoc() {
    let strFecha: any = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN;
    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.toString().includes('-')) {
      strFecha = this.datePipe.transform(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN,
        'dd/MM/yyyy');
    }
    const fechaAct = String(strFecha).split('/');
    const fechaNew = new Date(Number(fechaAct[2]), Number(fechaAct[1]) - 1, Number(fechaAct[0]));

    return fechaNew;
  }
  dateValidation(fecha) {
    let strFecha: any = fecha;
    if (fecha.toString().includes('-')) {
      strFecha = this.datePipe.transform(fecha, 'dd/MM/yyyy');
    }
    const fechaAct = String(strFecha).split('/');
    const fechaNew = new Date(Number(fechaAct[2]), Number(fechaAct[1]) - 1, Number(fechaAct[0]));

    return fechaNew;
  }
  nuevo() {
    this.editConcil = true;
    this.lblDocCierre = false;
    //this.opcionesTipoDoc = [];
    // this.cxctrncancelacionService.llenarTipoDoc().subscribe((tipodoc) => {
    //   tipodoc.map((doc) => {
    //     this.itemTipoDoc = { label: doc.NOMBRE, value: doc.CODIGO };
    //     this.opcionesTipoDoc.push(this.itemTipoDoc);
    //   });
    //   this.opcionesTipoDoc.push({ label: '', value: '' });
    // });

    if (this.permisosCartera.PERINSERCION === 1) {
      this.nuevaCartera('N');
    } else if (this.permisosCartera.PERINSERCION === 0) {
      this.message.add({
        key: 'cxctrncancelacion',
        severity: 'error',
        summary: 'Inserción de Cancelación',
        detail: 'El usuario no tiene permisos de inserción, acción denegada'
      });
    }
    document.getElementById("errasiento").style.display = 'none';
  }
  nuevaCartera(parametro) {
    this.bolNuevo = true;
    if (parametro === 'N') { // N para nuevo
      this.btnNuevo = true;
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      document.getElementById('divPrincipal').style.opacity = '1.0';
      /*this.columnDefsDVFDetalle.map((columna) => {
        columna.editable = true;
      });*/
      // this.aggrid.refreshColumnDefs2(this.columnDefsDVFDetalle);
      this.numeroFactura = '';
      this.editarCeldas = false;
      this.tipodetalleactivo = 'all';
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.btnRegresar = false;
      this.botonUltimo = true;
      this.botonAnterior = true;
      this.botonSiguiente = true;
      this.botonPrimero = true;
      this.botonPago = true;
      this.botonAnular = true;
      this.botonBuscar = true;
      this.botonAsiento = true;
      this.botonVerAsiento = true;
      this.porcIvaBoolean = true;
      this.booleanDatosPantalla = false;
      this.tabVarios = false;
      this.booleanNumero = false;
      this.cxctrncancelacionService.dblTotalRetarray[this.indicador] = 0;
      this.cxctrncancelacionService.dblTotalDocarray[this.indicador] = 0;
      this.cxctrncancelacionService.dblTotalFormaDevarray[this.indicador] = 0;
      this.cxctrncancelacionService.dblTotalNDarray[this.indicador] = 0;
      this.cxctrncancelacionService.dblTotalFormaPagoarray[this.indicador] = 0;
      this.cxctrncancelacionService.dblTotalOtrosGarray[this.indicador] = 0;
      this.cxctrncancelacionService.dblTotalNCarray[this.indicador] = 0;
      this.cxctrncancelacionService.dblTotalCobrararray[this.indicador] = 0;
      //this.tabDebitoBancario = false;
     // this.tabFormaPagoDev = false;
      this.tabRetenciones = true;
      this.tabOtrosGastos = true;
      //this.tabEstadoCuentaAnt = false;
      this.tabFormaPago = true;
      this.impresoString = '';
      this.retencionElect = true;
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador] = {
        SALDO: '',
        TRNCOBRO_TIPODOC: 'CA',
        TOTALFORMAPAGO: 0,
        TOTALND: 0,
        TRNCOBRO_IMPORTE: 0,
        TOTALNC: 0,
        TOTALOTROSG: 0,
        TOTALRET: 0,
        TOTALACOBRAR: 0,
        TOTALDOC: 0,
        BAN_BANCO: '',
        BAN_CODIGO: '',
        TRNCOBRO_NROCOMPROBANTE: '',
        TRNCOBRO_SALDOINI: '',
        CON_CODIGO: '',
        TRNCOBRO_NRODOC: '',
        TOTALANTICIPO: '',
        TRNCOBRO_FECHAVENCE: '',
        CLI_NOMBRE: '',
        REALIZADO: '',
        ORIGEN: '',
        VEN_NOMBRE: '',
        APLORG_CODIGO: 'CXC',
        USU_IDENTIFICACION: this.usuario.identificacion,
        TRNCOBRO_CONCEPTO: '',
        BOD_CODIGO: '',
        TRNCOBRO_FECHATRN: new Date(),
        VEN_CODIGO: '',
        CLI_CODIGO: '',
        ASI_NRO: '',
        CABROLADMN_CODIGO: '',
        CABROLVEND_CODIGO: '',
        COM_CODIGO: '01',
        COM_CODIGO_CXP: '01',
        COM_TIPOAMBFACTELEC: '',
        EMP_CODIGO: '',
        EMP_CODIGOVEND: '',
        TRNCOBRO_ANULADO: '',
        TRNCOBRO_CAMBIO: '',
        TRNCOBRO_CUADRE: '',
        TRNCOBRO_EFECTIVO: '',
        TRNCOBRO_FLAG: '1',
        TRNCOBRO_IMPRESO: '',
        TRNCOBRO_LOCALIZACIONXMLRET: '',
        TRNCOBRO_NRODOC_CXP: '',
        TRNCOBRO_NRODOC_OLD: '',
        TRNCOBRO_PATHPDF_FE: '',
        TRNCOBRO_PATHXMLNOAUTO_FE: '',
        TRNCOBRO_PATHXMLRET: '',
        TRNCOBRO_REFERENCIAEXTERNA: '',
        TRNCOBROND_AUTORIZACION_FE: '',
        TRNCOBROND_CLAVEACCESO_FE: '',
        TRNCOBROND_ESTADO_FE: '',
        TRNCOBROND_FECHAAUT_FE: '',
        TRNCOBROND_NUMFACTELEC: ''
      };
      document.getElementById('cliente').focus();
      this.cxctrncancelacionService.getOrigen(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe((resApl) => {
        if (resApl.length !== 0) {
          this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ORIGEN =
            this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO + '-' + resApl[0].APLORG_NOMBRE;
        } else {
          this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ORIGEN =
            this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO + '-';
        }
      });
      this.cxctrncancelacionService.getRealizado(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].USU_IDENTIFICACION).subscribe((resReal) => {
        this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].REALIZADO = resReal[0].NOMBRE;
      });
      this.indice = -1;
      this.booleanBodega = false;
      this.saldoInicial = false;
      this.booleanAnulado = false;
      this.cxctrncancelacionService.cxctrnresumenarray[this.indicador] = [];
      this.cxctrncancelacionService.cxctrnretencionarray[this.indicador] = [];
      this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador] = [];
      this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador] = [];
      this.cxctrncancelacionService.cxctrnformapagodevarray[this.indicador] = [];
      this.cxctrncancelacionService.cxctrnanticiposarray[this.indicador] = [];
      this.lblAplicaRegMicroempresas = '';
      this.lblAplicaRegMicroempresas = '';
      this.bolestcta = false;
      this.bolsaldo = false;
      this.btneditConcil = true;
    } else if (parametro === 'C') { // C para cancelar
      this.indiceTablas = 0;
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.botonUltimo = false;
      this.botonAnterior = false;
      this.botonPrimero = false;
      this.botonSiguiente = false;
      this.botonBuscar = false;
      this.botonPago = false;
      this.botonAnular = false;
      this.botonAsiento = false;
      this.botonVerAsiento = false;
      this.porcIvaBoolean = true;
      this.booleanDatosPantalla = false;
      // this.tabVarios = false;
      // this.tabDebitoBancario = false;
      // this.tabFormaPagoDev = false;
      this.tabRetenciones = true;
      this.numeroFactura = '';
       this.tabOtrosGastos = true;
      this.cxctrncancelacionService.dblTotalRetarray[this.indicador] = 0;
      this.cxctrncancelacionService.dblTotalDocarray[this.indicador] = 0;
      this.cxctrncancelacionService.dblTotalFormaDevarray[this.indicador] = 0;
      this.cxctrncancelacionService.dblTotalNDarray[this.indicador] = 0;
      this.cxctrncancelacionService.dblTotalFormaPagoarray[this.indicador] = 0;
      this.cxctrncancelacionService.dblTotalOtrosGarray[this.indicador] = 0;
      this.cxctrncancelacionService.dblTotalNCarray[this.indicador] = 0;
      this.cxctrncancelacionService.dblTotalCobrararray[this.indicador] = 0;
      this.tabEstadoCuentaAnt = false;
      this.tabFormaPago = true;
      this.impresoString = '';
      this.retencionElect = true;
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador] = {
        SALDO: '',
        TRNCOBRO_TIPODOC: '',
        TOTALFORMAPAGO: 0,
        TOTALND: 0,
        TRNCOBRO_IMPORTE: 0,
        TOTALNC: 0,
        TOTALOTROSG: 0,
        TOTALRET: 0,
        TOTALACOBRAR: 0,
        TOTALDOC: 0,
        BAN_BANCO: '',
        BAN_CODIGO: '',
        TRNCOBRO_NROCOMPROBANTE: '',
        TRNCOBRO_SALDOINI: '',
        CON_CODIGO: '',
        TRNCOBRO_NRODOC: '',
        TOTALANTICIPO: '',
        TRNCOBRO_FECHAVENCE: '',
        CLI_NOMBRE: '',
        REALIZADO: '',
        ORIGEN: '',
        VEN_NOMBRE: '',
        APLORG_CODIGO: 'CXC',
        USU_IDENTIFICACION: this.usuario.identificacion,
        TRNCOBRO_CONCEPTO: '',
        BOD_CODIGO: '',
        TRNCOBRO_FECHATRN: new Date(),
        VEN_CODIGO: '',
        CLI_CODIGO: '',
        ASI_NRO: '',
        CABROLADMN_CODIGO: '',
        CABROLVEND_CODIGO: '',
        COM_CODIGO: '01',
        COM_CODIGO_CXP: '01',
        COM_TIPOAMBFACTELEC: '',
        EMP_CODIGO: '',
        EMP_CODIGOVEND: '',
        TRNCOBRO_ANULADO: '',
        TRNCOBRO_CAMBIO: '',
        TRNCOBRO_CUADRE: '',
        TRNCOBRO_EFECTIVO: '',
        TRNCOBRO_FLAG: '1',
        TRNCOBRO_IMPRESO: '',
        TRNCOBRO_LOCALIZACIONXMLRET: '',
        TRNCOBRO_NRODOC_CXP: '',
        TRNCOBRO_NRODOC_OLD: '',
        TRNCOBRO_PATHPDF_FE: '',
        TRNCOBRO_PATHXMLNOAUTO_FE: '',
        TRNCOBRO_PATHXMLRET: '',
        TRNCOBRO_REFERENCIAEXTERNA: '',
        TRNCOBROND_AUTORIZACION_FE: '',
        TRNCOBROND_CLAVEACCESO_FE: '',
        TRNCOBROND_ESTADO_FE: '',
        TRNCOBROND_FECHAAUT_FE: '',
        TRNCOBROND_NUMFACTELEC: ''
      };
      this.booleanBodega = false;
      this.booleanAnulado = false;
      this.saldoInicial = false;
      this.cxctrncancelacionService.cxctrnresumenarray[this.indicador] = [];
      this.cxctrncancelacionService.cxctrnretencionarray[this.indicador] = [];
      this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador] = [];
      this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador] = [];
      this.cxctrncancelacionService.cxctrnformapagodevarray[this.indicador] = [];
      this.cxctrncancelacionService.cxctrnanticiposarray[this.indicador] = [];
      this.lblAplicaRegMicroempresas = '';
      this.btneditConcil = true;
    }
  }
  abrirClave(accion) {
    this.displayAcciones = false;
    this.accionClave = accion;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
    this.claveDialog = true;
  }
  buscarCartera() {
    this.editpagofr = true;
    this.opcion = 'BUSCAR';
    this.busquedaCompuesta = false;
    this.types = [
      { label: 'NÚMERO', value: 'TRNCOBRO_NRODOC' },
      { label: 'TIPO DOC', value: 'TRNCOBRO_TIPODOC' },
      { label: 'FECHA TRANS', value: 'TRNCOBRO_FECHATRN' },
      { label: 'COD_CLIENTE', value: 'CLI_CODIGO' },
      { label: 'CLIENTE', value: 'CLI_NOMBRE' },
      { label: 'CONCEPTO', value: 'TRNCOBRO_CONCEPTO' },
      { label: 'IMPORTE', value: 'TRNCOBRO_IMPORTE' },
      { label: 'FECHA VENC', value: 'TRNCOBRO_FECHAVENCE' },
      { label: 'REF_EXTERNA', value: 'TRNCOBRO_REFERENCIAEXTERNA' },
      { label: 'COMPROBANTE', value: 'TRNCOBRO_NROCOMPROBANTE' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', '', ''];
    this.tabla = 'CXC_TRNCOBRO';
    this.consulta = 'CXC_TRNCOBRO.*, VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO, \'01\') AS CLI_NOMBRE';
    this.where = ' trncobro_tipodoc=\'CA\' AND CXC_TRNCOBRO.TRNCOBRO_NRODOC is not null order by CXC_TRNCOBRO.TRNCOBRO_FECHATRN desc';
    this.spin = true;
    this.busquedaSer.busquedaCXCTrnCancelacion1().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.arregloCons.map((registro) => {
        if (registro.TRNCOBRO_IMPORTE !== null) {
          registro.TRNCOBRO_IMPORTE = Number(registro.TRNCOBRO_IMPORTE).toFixed(2);
        }
        if (registro.TRNCOBRO_FECHATRN !== null) {
          registro.TRNCOBRO_FECHATRN = this.datePipe.transform(registro.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
        } else {
          registro.TRNCOBRO_FECHATRN = '';
        }
        if (registro.TRNCOBRO_FECHAVENCE !== null) {
          registro.TRNCOBRO_FECHAVENCE = this.datePipe.transform(registro.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
        } else {
          registro.TRNCOBRO_FECHAVENCE = '';
        }
      });
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }
  generarasiento() {
    if (this.btnGuardar === true) {
      if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO !== 'CXC') {
        this.message.add({
          key: 'cxctrncancelacion',
          severity: 'error',
          summary: 'Asiento Cancelación',
          detail: 'No se puede generar un asiento debido a que el documento proviene de otro origen'
        });
        return;
      } else {
        if (this.saldoInicial === false) {
          this.confirmarAsiento();
        }
      }
    } else {
      this.message.add({
        key: 'cxctrncancelacion',
        severity: 'error',
        summary: 'Asiento Cancelación',
        detail: 'No se puede generar un asiento debido a que la cancelación  no está guardada'
      });
    }
  }
  comprobarClave() {
    this.cxctrncancelacionService.obtenerClave(this.accionClave).subscribe((clave) => {
       
        let claveReal;
        claveReal = clave[0].AUTORIZACION_CLAVE;
        if (claveReal === this.claveAuth) {
          this.claveDialog = false;
          this.confirmarAnularCar();
          this.claveAuth = '';
        } else if (clave !== this.claveAuth) {
          this.message.add({
            key: 'cxctrncancelacion',
            severity: 'error',
            summary: 'Clave Autorización',
            detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
          });
        }
      
    });
  }
  confirmarAnularCar() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea anular la cancelación',
      header: 'Anular Cancelación',
      icon: 'pi pi-exclamation-triangle',
      key: 'confCxctrncancelacion',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        //this.anularCartera();
        this.finalmenteAnular();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }
  finalmenteAnular() {
    // if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN') {
    //   this.cxctrncancelacionService.eliminarCaja(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
    //     this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
    //       '', '01', '', '', '', '').subscribe(() => {
    //       });
    //   });
    //   this.cxctrncancelacionService.eliminarBanco(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
    //     this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
    //       '', '01', '', '', '', '').subscribe(() => {
    //       });
    //   });
    // }
    if (this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].length !== 0) {
      this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].map(async (formap) => {
        if (formap.TRNPAGO_TIPO === 'ANCL') {
          //GSRF
          const eR = await this.cxctrncancelacionService.erTrnpagorefer(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
              let strNumDocAnt = '';
              if (eR !== undefined) {
                if (eR[0].TRNPAGO_REFER !== null) {
                  strNumDocAnt = eR[0].TRNPAGO_REFER;
                }
              }
          console.log(strNumDocAnt)
          await this.cxctrncancelacionService.eliminarTrnCobroProm(strNumDocAnt);
          //GSRF
          //await this.cxctrncancelacionService.eliminarTrnCobroProm(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
          this.eliminarPagosCuotas();
          this.cxctrncancelacionService.eliminarNDATrnResumen(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
          });
          this.auditoriagral.registrarAuditoria('CXC_TRNCANCELACION', String(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
            '', '01', '', '', '', '').subscribe(() => {
            });

        }
      });
    }
    this.cxctrncancelacionService.anularDocumento(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
      if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO !== '') {
        this.cxctrncancelacionService.anularAsientoDoc(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO).subscribe(() => {
        });
      }
      // if (this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].length !== 0) {
      //   this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].map((doc) => {
      //     if (doc.SELECCIONADO === true) {
      //       this.cxctrncancelacionService.actualizarestadoFac(doc).subscribe(() => {
      //       });
      //     }
      //   });
      // }
      this.cxctrncancelacionService.eliminarDocAnulado(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
      });
      this.cxctrncancelacionService.eliminarDocAnuladoEnc(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
      });
      this.cxctrncancelacionService.eliminarPagoAnulado(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
      });
      this.cxctrncancelacionService.eliminarRetencionAnulado(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
      });
      //CDPJ
      if (this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].length !== 0) {
        this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].map(async (doc) => {
          if (doc.SELECCIONADO === true) {
            await this.cxctrncancelacionService.actualizarestadoFac(doc).toPromise().catch(e=>{
              
            })
          }
        });
      }
      //CDPJ
      this.auditoriagral.registrarAuditoria('CXC_TRNCANCELACION', String(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC) + '/' +
        String(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE), 'AN',
        '', '01', '', '', '', '').subscribe(() => {
          this.message.add({
            key: 'cxctrncancelacion',
            severity: 'success',
            summary: 'Anular Cancelación',
            detail: 'La cancelación fue anulada'
          });
          this.booleanAnulado = true;
          this.cxctrncancelacionService.obtenerSaldoCliente(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
            this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
            this.cancelarEdicionCartera();
          });
        });
    });
  }
  confirmarAsiento() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea generar el asiento',
      header: 'Asiento Cancelación',
      icon: 'pi pi-exclamation-triangle',
      key: 'confCxctrncancelacion',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
       
         if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
          this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA') {
          this.cxctrncancelacionService.generarAsientoCI(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
            this.cxctrncancelacionService.getAsiento(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
              this.asiento = res[0].ASI_NRO;
              this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
              this.actualizarNumeroAsiento(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
              this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
                '', '01', '', '', '', '').subscribe(() => {
                  this.message.add({
                    key: 'cxctrncancelacion',
                    severity: 'success',
                    summary: 'Asiento Contable',
                    detail: 'Se ha generado el asiento contable'
                  });
                });
            });
          }, error1 => {
            this.message.add({
              key: 'cxctrncancelacion',
              severity: 'error',
              summary: 'Asiento Contable',
              detail: 'No se ha generado el asiento, error ' + error1.error
            });
          });
        }
        ///GSRF
    this.cxctrncancelacionService.getAsiento(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(async(res)  => {
      console.log('----***',res)
      this.asiento = res[0].ASI_NRO;
      if (this.asiento !== null) {
        const det = await this.cxctrncancelacionService.getDetConencasilProm(this.asiento, 'CXC');
        console.log('----***',det);
        let sum = 0;
        for (const aux of det) {
          sum=aux.DETASI_IMPORTE + sum;
        }
        console.log('----***',Number(sum.toFixed(2)));
        if(Number(sum.toFixed(2)) !== 0){
          document.getElementById("errasiento").style.display = '';
        }else{
          document.getElementById("errasiento").style.display = 'none';
        }
      }else{
        document.getElementById("errasiento").style.display = 'none';
      } 
    });
    //GSRF
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }
  async actualizarNumeroAsiento(strAsiNro) {
    const strTrnCobroNroDoc = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
    // FORMAS DE PAGO

    let lstFormaPago = [];

    const data = await this.cxctrncancelacionService.asibangettrnpago(strTrnCobroNroDoc);
    //CDPJ
    if(data !== null && data !== undefined){
      for (const rs of data) {
        lstFormaPago.push({
          strBanCodigo: rs.CAJ_CODIGO,
          strBanNumero: rs.TRNPAGO_NUMERO,
          strBanTipo: rs.TRNPAGO_TIPO
        });
      }
  
      // Actualiza en numero del asiento
  
      // Lee cada una de las formas de pago y actualiza el número del asiento en la transaccion de bancos
  
      for (const item of lstFormaPago) {
        if (item.strBanTipo === "DP") {
          await this.cxctrncancelacionService.updateasientobantrnban(strAsiNro, item.strBanCodigo, item.strBanTipo, item.strBanNumero);
        } else {
          await this.cxctrncancelacionService.updateasientobantrncaj(strAsiNro, item.strBanCodigo, item.strBanTipo, item.strBanNumero);
        }
      }
    }
    //CDPJ
  }
  asientover() {
    this.cxctrncancelacionService.getAsiento(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
      this.asiento = res[0].ASI_NRO;
      if (this.asiento === null) {
        this.message.add({
          key: 'cxctrncancelacion',
          severity: 'error',
          summary: 'Visualizacion de Asiento Contable',
          detail: 'Esta cancelación no tiene un asiento contable'
        });
      } else {
        this.auxiliar++;
        // this.displayDialogAsiento = true;
        this.displayAsiContable = true;
      }
    });
  }
  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }
  abrirFacturasAbonadas() {
    this.codClienteFac = '';
    this.nombreClienteFac = '';
    this.nroFacCancelada = '';
    this.cxctrncancelacionService.cxctrnfaccanceladasarray[this.indicador] = [];
    this.dialogFacCanceladas = true;
  }
  eliminarPagosCuotas() {
    console.log('ELIMINAR CUOTAS');
    console.log(this.cxctrncancelacionService.cxctrnresumenarray[this.indicador]);
    if (this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].length !== 0) {
      this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].map((item) => {
        console.log(item.STRCUOTAFAC);
        if (item.STRCUOTAFAC === 'C') {
          const data = {
            TRNCOBRO_NRODOC: item.TRNCOBRO_NRODOC,
            CLI_CODIGO: this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO
          };
          this.actualizarCuotasPagadas(data);
          //GSRF
          this.auditoriagral.registrarAuditoria('CXC_TRNCANCELACION', String(item.TRNCOBRO_NRODOC +'/'+this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
          '', '01', '', '', '', '').subscribe(() => {
          });
          //GSRF
        }
      });
    }
  }
  async actualizarCuotasPagadas(item) {
    const data = {
      ENCFAC_NUMERO: item.TRNCOBRO_NRODOC,
      CLI_CODIGO: item.CLI_CODIGO
    };
    await this.cxctrncancelacionService.actPagadoDETCUOTA(data);
  }
  async cancelarEdicionCartera() {
    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '' && this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== undefined) {
      const res = await this.cxctrncancelacionService.getEncCarteraProm(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
      if (res.length === 0) {
        this.nuevaCartera('C');
      } else {
        this.btnNuevo = false;
        this.btnGuardar = true;
        this.btnBorrar = false;
        this.botonAnterior = false;
        this.botonPrimero = false;
        this.botonUltimo = false;
        this.botonSiguiente = false;
        this.botonBuscar = false;
        this.botonPago = false;
        this.botonAnular = false;
        this.botonAsiento = false;
        this.botonVerAsiento = false;
        const array: any = [];
        this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador] = array;//res[0];
        await this.obtenerDatos(res[0].TRNCOBRO_NRODOC);
      }
      /* this.cxctrncancelacionService.getEncCartera(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
        if (res.length === 0) {
          this.nuevaCartera('C');
        } else {
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.botonAnterior = false;
          this.botonPrimero = false;
          this.botonUltimo = false;
          this.botonSiguiente = false;
          this.botonBuscar = false;
          this.botonPago = false;
          this.botonAnular = false;
          this.botonAsiento = false;
          this.botonVerAsiento = false;
          this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador] = res[0];
          this.obtenerDatos(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
        }
      }); */
    } else {
      this.nuevaCartera('C');
    }
  }
  isEdit(data): boolean {
    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '' && this.editpagofr === true ) {
      return false;
    }
    return true;
  }
  idEditcajcodigoFormapago(data):boolean{
    console.log(data.TRNCAJ_TIPO)
    if ((this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '' && this.editpagofr === true)||
    ((this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' || this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB') &&( data.TRNPAGO_TIPO=== 'DP'))) 
    {
      console.log('entro')
      return false;
    }else{
      return true;
    }
  }
  seleccionDocumento(valor) {
    /*if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT') {
      
      if (valor.object !== undefined) {
        this.numeroFactura = valor.object.TRNCOBRO_NRODOC;
      }
    }*/
  }
  isEditFP(data) {
    //CDPJ
    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT' ||
    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC' ||
    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN') {
      this.editConcil=false
  }
    //CDPJ
    if (this.editConcil) {
      return true;
    }
    return false;
  }
  docSeleccionado(valor) {
    if (valor.data.SELECCIONADO === true && this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
      valor.data.TRNCOBRO_IMPORTE = valor.data.TRNCOBRO_SALDOINI;
      if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
        this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
        this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT') {
        this.numeroFactura = valor.data.TRNCOBRO_NRODOC;
      }
      this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
      const indi = this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].indexOf(valor.data);
      if (indi === -1) {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColumnDefCarteraDoc.objeto].setFocusedCell(0, 'SELECCIONADO');
      } else {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColumnDefCarteraDoc.objeto].setFocusedCell(indi, 'SELECCIONADO');
      }
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColumnDefCarteraDoc.objeto].forEachNode((node) => {
          const h = indi;
          if (Number(node.id) === h) {
            node.setSelected(true);
          }
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
            this.defaultColumnDefCarteraDoc.objeto].ensureIndexVisible(h, 'bottom');
        });
      this.cxctrncancelacionService.calcularTotalDoc();
    }

    if (valor.data.SELECCIONADO === false) {
      valor.data.TRNCOBRO_IMPORTE = 0;
      this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
      const indi = this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].indexOf(valor.data);
      if (indi === -1) {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColumnDefCarteraDoc.objeto].setFocusedCell(0, 'SELECCIONADO');
      } else {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColumnDefCarteraDoc.objeto].setFocusedCell(indi, 'SELECCIONADO');
      }
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColumnDefCarteraDoc.objeto].forEachNode((node) => {
          const h = indi;
          if (Number(node.id) === h) {
            node.setSelected(true);
          }
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
            this.defaultColumnDefCarteraDoc.objeto].ensureIndexVisible(h, 'bottom');
        });
      this.cxctrncancelacionService.calcularTotalDoc();
    }
  }
  cambio(cambio) {
    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador] !== undefined) {
      this.btnGuardar = false;
      this.btnRegresar = false;
      this.btnNuevo = true;
      this.btnNuevoGrid = false;
    }
  }
  cerrarReportes() {
    this.displayDialogReportes = false;
  }
  cerrarfrmcorreos(){
    this.displayDialogfrmcorreos=false;
  }
  cambiarFoco(evento) {
    if (evento.target.id === 'cliente') {
      this.busquedaSer.encontrarRegistro44(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((resCli) => {
        if (resCli !== null) {
          this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO = resCli[0].CLI_CODIGO;
          this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_NOMBRE = resCli[0].CLI_NOMBRE;
          this.cxctrncancelacionService.obtenerSaldoCliente(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((res) => {
            this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].SALDO = Number(res[0].SALDO).toFixed(2);
          });
          this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO = resCli[0].VEN_CODIGO;
          this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].VEN_NOMBRE = resCli[0].VEN_NOMBRE;
          this.displayDialogBusquedaFast = false;
          this.btnNuevoGrid = false;
          this.btnBorrarGrid = false;
          this.btnCancelarGrid = false;
          this.btnNuevo = true;
          this.btnGuardar = false;
          this.btnBorrar = false;
          this.botonPrimero = true;
          this.botonSiguiente = true;
          this.botonAnterior = true;
          this.botonUltimo = true;
          document.getElementById('vendedor').focus();
          // if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== '') {
          //   this.cambiarTipoCartera();
          // }
          if (resCli[0].CLI_CONREGMIC === 'S'
            || resCli[0].CLI_AGENTERET === 'S') {
            if (resCli[0].CLI_CONREGMIC === 'S'
              && resCli[0].CLI_AGENTERET === 'S') {
              this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas y agente de retención';
            } else {
              if (resCli[0].CLI_CONREGMIC === 'S') {

                this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas';
              }
              if (resCli[0].CLI_AGENTERET === 'S') {

                this.lblAplicaRegMicroempresas = 'Cliente aplica agente de retención';
              }
            }
          } else {
            this.lblAplicaRegMicroempresas = '';
          }
        } else {
          this.abrirClientes();
        }
      });
    }  else if (evento.target.id === 'vendedor') {
      this.busquedaSer.encontrarRegistro45(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO).subscribe((resVen) => {
        if (resVen !== null) {
          this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO = resVen[0].VEN_CODIGO;
          this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].VEN_NOMBRE = resVen[0].VEN_NOMBRE;
          this.displayDialogBusquedaFast = false;
          this.btnNuevo = true;
          this.btnGuardar = false;
          this.btnBorrar = false;
          this.botonPrimero = true;
          this.botonSiguiente = true;
          this.botonAnterior = true;
          this.botonUltimo = true;
          document.getElementById('numeroCartera').focus();
        } else {
          this.abrirVendedor();
        }
      });
      
    } else if (evento.target.id === 'numeroCartera') {
     
        document.getElementById('concepto').focus();
   
    }  else if (evento.target.id === 'concepto') {
      if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
        this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC' ||
        this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN' ||
        this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA') {
      } else {
        document.getElementById('importe').focus();
      }
    }
  }
  abrirClientesFacAbonadas() {
    this.opcion = 'BUSQUEDACLIFAC';
    this.types = [
      { label: 'Código', value: 'CLI_CODIGO' },
      { label: 'Nombre', value: 'CLI_NOMBRE' },
      { label: 'RUCIDE', value: 'CLI_RUCIDE' },
      { label: 'Zona', value: 'ZONA_NOMBRE' },
      { label: 'Vendedor', value: 'VEN_NOMBRE' },
      { label: 'Grupo', value: 'GRU_CODIGO' },
      { label: 'Contacto', value: 'CLI_CONTACTO' },
      { label: 'Placa', value: 'CLI_PLACA' },
      { label: 'Nombreec', value: 'CLI_NOMBREC' },
    ];
    this.busquedacampos = [this.codClienteFac, '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_MAECLIENTE';
    this.consulta = 'ven_maecliente.*,  VEN_FNC_NOMBRE_VENDEDOR(VEN_CODIGO) AS' +
      ' VEN_NOMBRE, VEN_FNC_OBTENER_ZONACLI(CLI_ZONA) AS ZONA_NOMBRE';
    this.where = '';
    this.spin = true;
    this.busquedaSer.busquedaVenMaeCliente1(this.codClienteFac).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }
  abrirClientes() {
    this.opcion = 'BUSQUEDACLI';
    this.types = [
      { label: 'Código', value: 'CLI_CODIGO' },
      { label: 'Nombre', value: 'CLI_NOMBRE' },
      { label: 'RUCIDE', value: 'CLI_RUCIDE' },
      { label: 'Zona', value: 'ZONA_NOMBRE' },
      { label: 'Vendedor', value: 'VEN_NOMBRE' },
      { label: 'Grupo', value: 'GRU_CODIGO' },
      { label: 'Contacto', value: 'CLI_CONTACTO' },
      { label: 'Placa', value: 'CLI_PLACA' },
      { label: 'Nombreec', value: 'CLI_NOMBREC' },
    ];
    this.busquedacampos = [this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO, '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_MAECLIENTE';
    this.consulta = 'ven_maecliente.*,  VEN_FNC_NOMBRE_VENDEDOR(VEN_CODIGO) AS' +
      ' VEN_NOMBRE, VEN_FNC_OBTENER_ZONACLI(CLI_ZONA) AS ZONA_NOMBRE';
    this.where = '';
    this.spin = true;
    this.busquedaSer.busquedaVenMaeCliente1(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }
  abrirVendedor() {
    this.opcion = 'BUSQUEDAVEN';
    this.types = [
      { label: 'Código', value: 'VEN_CODIGO' },
      { label: 'Nombre', value: 'VEN_NOMBRE' }
    ];
    this.busquedacampos = [this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO, ''];
    this.tabla = 'ven_maevendedor';
    this.consulta = '*';
    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO === '' || this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO === null) {
      this.where = '';
    } else if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO !== '') {
      this.where = this.types[0].value + ' LIKE \'%' +
        this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO + '%\'';
    }
    this.spin = true;
    this.busquedaSer.busquedaVenMaeVendedor2(this.where).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }
  buscarFacturas() {
    this.cxctrncancelacionService.getDocCobrados(this.codClienteFac, this.nroFacCancelada).subscribe((facpendientes) => {
      facpendientes.map((facturas) => {
        facturas.TRNCOBRO_FECHATRN = this.datePipe.transform(facturas.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
      });
      this.cxctrncancelacionService.cxctrnfaccanceladasarray[this.indicador] = facpendientes;
    });
  }
  actualizarTablas() {
    if (this.indiceTablas === 0) {
      if (this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].length !== 0) {
        this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
      }
    }
    // if (this.tabVarios === true) {
    //   if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
    //     this.cxctrncancelacionService.calcularTotalND();
    //   }
    // }
    // //GSRF
    // if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
    //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].SALDOANC = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE;
    //   this.cxctrncancelacionService.calcularTotalDoc();
    // }  
    // //GSRF
  }
  async changeTabs() {
    if (this.activeIndex === 1) {
      if (this.bolestcta === false) {
        this.cxctmptrnresumen = [];
        this.bolestcta = true;
        this.cxctmptrnresumen = await this.cliService.getInfoEstadoCuenta(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO,
          'cxctrncancelacion');
      }
    }
    if (this.activeIndex === 2) {
      if (this.bolsaldo === false) {
        this.cxctrnresumen = [];
        this.bolsaldo = true;
        this.cxctrnresumen = await this.cliService.getInfoSaldos(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO,
          'cxctrncancelacion');
      }
    }
    // if (this.activeIndex === 3) {
    //   if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND'
    //     || this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
    //     this.cargarAnexos();
    //   }
    // }
  }
  cambioSaldo() {
    if (this.saldoInicial === true) {
      this.tabVarios = false;
      this.tabDebitoBancario = false;
      this.tabFormaPagoDev = false;
      this.tabRetenciones = false;
      this.tabOtrosGastos = false;
      this.tabEstadoCuentaAnt = false;
      //CDPJ
      if(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC'){
        this.tabEstadoCuentaAnt = true;
      }
      //CDPJ
      this.tabFormaPago = false;
    } else if (this.saldoInicial === false) {
      const numeroDoc = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
      this.cambiarTipoCartera();
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = numeroDoc;
    }
  }
  cambiarTipoCartera() {
    // this.disimporte=false;
    // this.cxctrncancelacionService.cxctrnresumenarray[this.indicador] = [];
    // this.cxctrncancelacionService.cxctrnretencionarray[this.indicador] = [];
    // this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador] = [];
    // this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador] = [];
    // this.cxctrncancelacionService.cxctrnformapagodevarray[this.indicador] = [];
    // this.cxctrncancelacionService.cxctrnanticiposarray[this.indicador] = [];
    // this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC = 0;
    // this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC =
    //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC.toFixed(2);
    // this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO = 0;
    // this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO =
    //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO.toFixed(2);
    // this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO = 0;
    // this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO =
    //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO.toFixed(2);
    // this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG = 0;
    // this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG =
    //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG.toFixed(2);
    // this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR = 0;
    // this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR =
    //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR.toFixed(2);
    // this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALRET = 0;
    // this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALRET =
    //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALRET.toFixed(2);
    // this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALNC = 0;
    // this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].SALDO =
    //   Number(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].SALDO).toFixed(2);
    // this.tabVarios = false;
    // this.tabDebitoBancario = false;
    // this.tabFormaPagoDev = false;
    // this.tabRetenciones = false;
    // this.tabOtrosGastos = false;
    // this.tabEstadoCuentaAnt = false;
    // this.tabFormaPago = false;
    // this.obtenerNumeracionDoc();

    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC'
      || this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA'
      || this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB'
      || this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT'
      || this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN'
      || this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
      this.bolsaldoIni = true;
    } else {
      this.bolsaldoIni = false;
    }

    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO === '') {
      this.message.add({
        key: 'cxctrncancelacion',
        severity: 'error',
        summary: 'Información',
        detail: 'Seleccione un cliente'
      });
      document.getElementById('cliente').focus();
    } else {
      // if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC') {
      //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC = 'FC';
      //   this.booleanBodega = true;
      //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE = new Date();
      //   this.saldoInicial = true;
      //   this.booleanNumero = false;
      //   this.bolsaldoIni = true;
      //   setTimeout(() => {
      //     document.getElementById('numeroCartera').focus();
      //   }, 1000);
      // }

      // if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN') {
      //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC = 'DAN';
      //   this.cxctrncancelacionService.cxctrnresumenarray[this.indicador] = [];
      //   this.booleanBodega = false;
      //   this.saldoInicial = false;
      //   this.tabFormaPagoDev = true;
      //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
      //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
      //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
      //   this.cxctrncancelacionService.consultarDocumentosACobrar('ANC', 'UPPER(CLI_CODIGO) LIKE UPPER(\'' +
      //     this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO + '\')').subscribe((res) => {
      //       if (res !== null) {
      //         res.map((devanc) => {
      //           if (devanc.SALDO > 0) {
      //             const obj: Cxctrnresumen = {};
      //             obj.SELECCIONADO = false;
      //             obj.TRNCOBRO_NRODOC = devanc.DOCUMENTO;
      //             obj.TRNCOBRO_FECHATRN = this.datePipe.transform(devanc.FECHA, 'dd/MM/yyyy');
      //             if (devanc.FECHAVENCE !== null) {
      //               obj.TRNCOBRO_FECHAVENCE = this.datePipe.transform(devanc.FECHAVENCE, 'dd/MM/yyyy');
      //             } else {
      //               obj.TRNCOBRO_FECHAVENCE = '';
      //             }
      //             obj.TRNCOBRO_CONCEPTO = devanc.CONCEPTO;
      //             obj.TRNCOBRO_SALDOINI = Number(devanc.SALDO).toFixed(2);
      //             obj.TRNCOBRO_IMPORTE = 0;
      //             if (devanc.APLORG_CODIGO !== null || devanc.APLORG_CODIGO !== undefined) {
      //               obj.APLORG_CODIGO = devanc.APLORG_CODIGO;
      //             }
      //             if (devanc.VENDEDOR !== null) {
      //               obj.VEN_CODIGO = devanc.VENDEDOR;
      //             } else {
      //               obj.VEN_CODIGO = '';
      //             }
      //             this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].push(obj);
      //           }
      //         });
      //         this.aggrid.refreshGridData(this.cxctrncancelacionService.cxctrnresumenarray[this.indicador]);
      //       }
      //     });
      // }

      // if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
      //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC = 'ANC';
      //   this.booleanBodega = false;
      //   this.saldoInicial = false;
      //   this.tabFormaPago = true;
      //   this.tabEstadoCuentaAnt = true;
      //   this.booleanNumero = false;
      //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
      //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
      //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
      //   this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador] = [];
      //   this.cxctrncancelacionService.cxctrnanticiposarray[this.indicador] = [];
      //   setTimeout(() => {
      //     document.getElementById('numeroCartera').focus();
      //   }, 1000);
      // }

      // if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
      //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC = 'NC';
      //   this.cxctrncancelacionService.cxctrnresumenarray[this.indicador] = [];
      //   this.booleanBodega = false;
      //   this.saldoInicial = false;
      //   this.tabVarios = true;
      //   this.booleanNumero = false;
      //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
      //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
      //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
      //   this.cxctrncancelacionService.cxctrnvariosarray[this.indicador] = [];
      //   //CDPJ
      //   if(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CCCAVNC').CFG_VALOR1 !== 1){
      //     this.columDefCarteraVarios.splice(5, 1);
      //     this.aggrid.refreshColumnDefs();
      //   }
      //   //CDPJ
      //   this.cxctrncancelacionService.consultarDocumentosACobrar('ON', 'UPPER(CLI_CODIGO) LIKE UPPER(\'' +
      //     this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO + '\')').subscribe((res) => {
      //       if (res !== null) {
      //         res.map((devanc) => {
      //           if (devanc.SALDO > 0) {
      //             const obj: Cxctrnresumen = {};
      //             obj.SELECCIONADO = false;
      //             obj.TRNCOBRO_NRODOC = devanc.DOCUMENTO;
      //             obj.TRNCOBRO_FECHATRN = this.datePipe.transform(devanc.FECHA, 'dd/MM/yyyy');
      //             if (devanc.FECHAVENCE !== null) {
      //               obj.TRNCOBRO_FECHAVENCE = this.datePipe.transform(devanc.FECHAVENCE, 'dd/MM/yyyy');
      //             } else {
      //               obj.TRNCOBRO_FECHAVENCE = '';
      //             }
      //             obj.TRNCOBRO_CONCEPTO = devanc.CONCEPTO;
      //             obj.TRNCOBRO_SALDOINI = Number(devanc.SALDO).toFixed(2);
      //             obj.TRNCOBRO_IMPORTE = 0;
      //             if (devanc.APLORG_CODIGO !== null || devanc.APLORG_CODIGO !== undefined) {
      //               obj.APLORG_CODIGO = devanc.APLORG_CODIGO;
      //             }
      //             if (devanc.VENDEDOR !== null) {
      //               obj.VEN_CODIGO = devanc.VENDEDOR;
      //             } else {
      //               obj.VEN_CODIGO = '';
      //             }
      //             this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].push(obj);
      //           }
      //         });
      //         //CDPJ
      //         this.aggrid.refreshGridData(this.cxctrncancelacionService.cxctrnresumenarray[this.indicador]);
      //         //CDPJ
      //         //this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
      //         setTimeout(() => {
      //           this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
      //           document.getElementById('numeroCartera').focus();
      //         }, 1000);
      //       }
      //     });
      // }

      // if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
      //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC = 'ND';
      //   this.booleanBodega = false;
      //   this.saldoInicial = false;
      //   this.tabVarios = true;
      //   this.tabDebitoBancario = true;
      //   this.cxctrncancelacionService.cxctrnvariosarray[this.indicador] = [];
      //   this.cxctrncancelacionService.cxcdebitobancarioarray[this.indicador] = [];
      //   this.booleanNumero = false;
      //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
      //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
      //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
      //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE = new Date();
      //   //CDPJ
      //   this.columDefCarteraVarios.splice(5, 1);
      //     this.aggrid.refreshColumnDefs();
      //   //CDPJ
      //   setTimeout(() => {
      //     document.getElementById('numeroCartera').focus();
      //   }, 1000);
      // }

      if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
        this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA') {
        this.cxctrncancelacionService.tipoDocumentoarray[this.indicador] = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC;
        this.cxctrncancelacionService.cxctrnresumenarray[this.indicador] = [];
        this.booleanBodega = false;
        this.saldoInicial = false;
        this.tabRetenciones = true;
        this.tabOtrosGastos = true;
        this.tabFormaPago = true;
        this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
        this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
        this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
        this.cxctrncancelacionService.cxctrnretencionarray[this.indicador] = [];
        this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador] = [];
        this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador] = [];
        this.cxctrncancelacionService.consultarDocumentosACobrar('ON', 'UPPER(CLI_CODIGO) LIKE UPPER(\'' +
          this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO + '\')').subscribe((res) => {
            if (res !== null) {
              res.map((devanc) => {
                if (devanc.SALDO > 0) {
                  const obj: Cxctrnresumen = {};
                  obj.SELECCIONADO = false;
                  obj.TRNCOBRO_NRODOC = devanc.DOCUMENTO;
                  obj.TRNCOBRO_FECHATRN = this.datePipe.transform(devanc.FECHA, 'dd/MM/yyyy');
                  if (devanc.FECHAVENCE !== null) {
                    obj.TRNCOBRO_FECHAVENCE = this.datePipe.transform(devanc.FECHAVENCE, 'dd/MM/yyyy');
                  } else {
                    obj.TRNCOBRO_FECHAVENCE = '';
                  }
                  obj.TRNCOBRO_CONCEPTO = devanc.CONCEPTO === undefined || devanc.CONCEPTO === null ? '' : devanc.CONCEPTO;
                  obj.TRNCOBRO_SALDOINI = Number(devanc.SALDO).toFixed(2);
                  obj.TRNCOBRO_IMPORTE = 0;
                  if (devanc.APLORG_CODIGO !== null || devanc.APLORG_CODIGO !== undefined) {
                    obj.APLORG_CODIGO = devanc.APLORG_CODIGO;
                  }
                  if (devanc.VENDEDOR !== null) {
                    obj.VEN_CODIGO = devanc.VENDEDOR;
                  } else {
                    obj.VEN_CODIGO = '';
                  }
                  obj.TRNCOBRO_TIPODOC = devanc.TRNCOBRO_TIPODOC;
                  this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].push(obj);
                }
              });
              //CDPJ
              this.aggrid.refreshGridData(this.cxctrncancelacionService.cxctrnresumenarray[this.indicador]);
              //CDPJ
              //this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
              console.log(this.cxctrncancelacionService.cxctrnresumenarray[this.indicador]);
              setTimeout(() => {
                document.getElementById('numeroCartera').focus();
              }, 1000);
            }
          });
      }

      // if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT') {
      //   this.cxctrncancelacionService.tipoDocumentoarray[this.indicador] = 'RT';
      //   this.cxctrncancelacionService.cxctrnresumenarray[this.indicador] = [];
      //   this.booleanBodega = false;
      //   this.saldoInicial = false;
      //   this.tabRetenciones = true;
      //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
      //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
      //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
      //   this.cxctrncancelacionService.cxctrnretencionarray[this.indicador] = [];
      //   this.cxctrncancelacionService.consultarDocumentosACobrar('ON', 'UPPER(CLI_CODIGO) LIKE UPPER(\'' +
      //     this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO + '\')').subscribe((res) => {
      //       if (res !== null) {
      //         res.map((devanc) => {
      //           if (devanc.SALDO > 0) {
      //             const obj: Cxctrnresumen = {};
      //             obj.SELECCIONADO = false;
      //             obj.TRNCOBRO_NRODOC = devanc.DOCUMENTO;
      //             obj.TRNCOBRO_FECHATRN = this.datePipe.transform(devanc.FECHA, 'dd/MM/yyyy');
      //             if (devanc.FECHAVENCE !== null) {
      //               obj.TRNCOBRO_FECHAVENCE = this.datePipe.transform(devanc.FECHAVENCE, 'dd/MM/yyyy');
      //             } else {
      //               obj.TRNCOBRO_FECHAVENCE = '';
      //             }
      //             obj.TRNCOBRO_CONCEPTO = devanc.CONCEPTO;
      //             obj.TRNCOBRO_SALDOINI = Number(devanc.SALDO).toFixed(2);
      //             obj.TRNCOBRO_IMPORTE = 0;
      //             if (devanc.APLORG_CODIGO !== null || devanc.APLORG_CODIGO !== undefined) {
      //               obj.APLORG_CODIGO = devanc.APLORG_CODIGO;
      //             }
      //             if (devanc.VENDEDOR !== null) {
      //               obj.VEN_CODIGO = devanc.VENDEDOR;
      //             } else {
      //               obj.VEN_CODIGO = '';
      //             }
      //             this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].push(obj);
      //           }
      //         });
      //         //CDPJ
      //         this.aggrid.refreshGridData(this.cxctrncancelacionService.cxctrnresumenarray[this.indicador]);
      //         //CDPJ
      //         //this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
      //         setTimeout(() => {
      //           document.getElementById('numeroCartera').focus();
      //         }, 1000);
      //       }
      //     });
      // }
    }
  }
  manejarSeleccion(opcion) {
    if (this.opcion === 'BUSCAR') {
      this.listaClientes.map((cliente) => {
        if (cliente.CLI_CODIGO === opcion.CLI_CODIGO) {
          this.indiceCliente = this.listaClientes.indexOf(cliente);
          this.cxctrncancelacionService.getListaReferencias(cliente.CLI_CODIGO).subscribe((ref) => {
            this.listaReferencias = ref;
            this.listaReferencias.map((refL) => {
              if (refL.TRNCOBRO_REFERENCIAEXTERNA === opcion.TRNCOBRO_NRODOC) {
                this.indiceDocRef = this.listaReferencias.indexOf(refL);
              }
            });
          });
        }
      });
      this.obtenerDatos(opcion.TRNCOBRO_NRODOC);
      this.displayDialogBusquedaFast = false;
    } else if (this.opcion === 'BUSQUEDACLI') {
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO = opcion.CLI_CODIGO;
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_NOMBRE = opcion.CLI_NOMBRE;
      this.cxctrncancelacionService.obtenerSaldoCliente(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((res) => {
        this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].SALDO = Number(res[0].SALDO).toFixed(2);
      });
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO = opcion.VEN_CODIGO === null ? '' : opcion.VEN_CODIGO;
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].VEN_NOMBRE = opcion.VEN_NOMBRE === null ? '' : opcion.VEN_NOMBRE;
      this.displayDialogBusquedaFast = false;
      this.btnNuevoGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.botonPrimero = true;
      this.botonSiguiente = true;
      this.botonAnterior = true;
      this.botonUltimo = true;
      this.busquedaSer.encontrarRegistro44(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((resCli) => {
        if (resCli !== null) {
          if (resCli[0].CLI_CONREGMIC === 'S'
            || resCli[0].CLI_AGENTERET === 'S') {
            if (resCli[0].CLI_CONREGMIC === 'S'
              && resCli[0].CLI_AGENTERET === 'S') {
              this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas y agente de retención';
            } else {
              if (resCli[0].CLI_CONREGMIC === 'S') {
                this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas';
              }
              if (resCli[0].CLI_AGENTERET === 'S') {
                this.lblAplicaRegMicroempresas = 'Cliente aplica agente de retención';
              }
            }
          } else {
            this.lblAplicaRegMicroempresas = '';
          }
        }
      });
      document.getElementById('vendedor').focus();
      if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== '') {
        this.cambiarTipoCartera();
      }
     } //else if (this.opcion === 'BUSQUEDACLIFAC') {
    //   this.codClienteFac = opcion.CLI_CODIGO;
    //   this.nombreClienteFac = opcion.CLI_NOMBRE;
    //   this.cxctrncancelacionService.getDocCobrados(this.codClienteFac, this.nroFacCancelada).subscribe((facpendientes) => {
    //     facpendientes.map((facturas) => {
    //       facturas.TRNCOBRO_FECHATRN = this.datePipe.transform(facturas.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
    //     });
    //     this.cxctrncancelacionService.cxctrnfaccanceladasarray[this.indicador] = facpendientes;
    //   });
    //   this.displayDialogBusquedaFast = false;
    //   document.getElementById('nroFacCanc').focus();
    // } 
    else if (this.opcion === 'BUSQUEDABOD') {
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = opcion.BOD_CODIGO;
      this.displayDialogBusquedaFast = false;
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.botonPrimero = true;
      this.botonSiguiente = true;
      this.botonAnterior = true;
      this.botonUltimo = true;
      document.getElementById('concepto').focus();
    } else if (this.opcion === 'BUSQUEDAVEN') {
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO = opcion.VEN_CODIGO;
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].VEN_NOMBRE = opcion.VEN_NOMBRE;
      this.displayDialogBusquedaFast = false;
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.botonPrimero = true;
      this.botonSiguiente = true;
      this.botonAnterior = true;
      this.botonUltimo = true;
    } //else if (this.opcion === 'BUSQUEDABAN') {
    //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO = opcion.BAN_CODIGO;
    //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].BAN_BANCO = opcion.BAN_BANCO;
    //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO = opcion.CON_CODIGO;
    //   this.displayDialogBusquedaFast = false;
    //   this.cxctrncancelacionService.cxcdebitobancarioarray[this.indicador] = [];
    //   if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].BAN_BANCO !== null || this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].BAN_BANCO !== '') {
    //     this.tabVarios = false;
    //     if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO !== '') {
    //       this.cxctrncancelacionService.obtenerChequesCli(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((cheques) => {
    //         if (cheques !== null) {
    //           cheques.map((cheque) => {
    //             const obj: Cxctrndebitobancario = {};
    //             obj.SELECCIONADO = false;
    //             obj.TRNPAGO_FECHA = this.datePipe.transform(cheque.TRNPAGO_FECHA, 'dd/MM/yyyy');
    //             obj.TRNPAGO_TIPO = cheque.TRNPAGO_TIPO;
    //             obj.TRNPAGO_NUMERO = cheque.TRNPAGO_NUMERO;
    //             obj.TRNPAGO_IMPORTE = Number(cheque.TRNPAGO_IMPORTE).toFixed(2);
    //             obj.TRNPAGO_NROCTA = cheque.TRNPAGO_NROCTA;
    //             obj.BANCLI_CODIGO = cheque.BANCLI_CODIGO;
    //             obj.BANCLI_NOMBRE = cheque.BANCLI_NOMBRE;
    //             obj.TRNCOBRO_NRODOC = cheque.TRNCOBRO_NROCOMPROBANTE;
    //             this.cxctrncancelacionService.cxcdebitobancarioarray[this.indicador].push(obj);
    //           });
    //         }
    //         this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxcdebitobancarioarray[this.indicador], this.defaultColumnDefCarteraDebito.objeto);
    //       });
    //     }
    //   } else {
    //     this.tabVarios = true;
    //   }
    // }

    if (this.opcion === 'TRNANEX_IDCRETRI') {
      this.anexoSeleccionado.TRNANEX_IDCRETRI = opcion.CODSRI_CODIGO;
      this.anexoSeleccionado.DESCIDCRETRI = opcion.CODSRI_DESCRIPCION;
      document.getElementById('TRNANEX_MONTOIVA').focus();
    }

    if (this.opcion === 'TRNANEX_TIPOSRITARCERO') {
      this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO = opcion.REF_CODIGO;
      document.getElementById('TRNANEX_BASEIMPGRABADAIVA').focus();
    }

    if (this.opcion === 'TRNANEX_TIPOSRIGRAVADA') {
      this.anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA = opcion.REF_CODIGO;
      document.getElementById('TRNANEX_MONTOIVA').focus();
    }

    this.displayDialogBus = false;
    this.displayDialogBusquedaFast = false;
  }
  async obtenerDatos(valor) {
    ///GSRF    
    this.cxctrncancelacionService.cxctrnresumenarray[this.indicador] = [];
    this.cxctrncancelacionService.cxctrnretencionarray[this.indicador] = [];
    this.cxctrncancelacionService.cxctrnvariosarray[this.indicador] = [];
    this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador] = [];
    this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador] = [];
    this.cxctrncancelacionService.cxcdebitobancarioarray[this.indicador] = [];
    this.cxctrncancelacionService.cxctrnanticiposarray[this.indicador] = [];
    this.cxctrncancelacionService.cxctrnformapagodevarray[this.indicador] = [];
    this.cxctrncancelacionService.dblTotalDocarray[this.indicador] = 0;
    this.cxctrncancelacionService.dblTotalRetarray[this.indicador] = 0;
    this.cxctrncancelacionService.dblTotalOtrosGarray[this.indicador] = 0;
    this.cxctrncancelacionService.dblTotalFormaPagoarray[this.indicador] = 0;
    this.cxctrncancelacionService.dblTotalFormaDevarray[this.indicador] = 0;
    this.cxctrncancelacionService.dblTotalVariosarray[this.indicador] = 0;
    this.cxctrncancelacionService.dblTotalNDarray[this.indicador] = 0;
    this.cxctrncancelacionService.dblTotalNCarray[this.indicador] = 0;
    this.cxctrncancelacionService.dblTotalCobrararray[this.indicador] = 0;
    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador] = {};
    ///GSRF
    // this.cxctrncancelacionService.dblTotalNCarray[this.indicador] = 0;
    //this.cxctrncancelacionService.dblTotalOtrosGarray[this.indicador] = 0;
    //this.cxctrncancelacionService.dblTotalFormaPagoarray[this.indicador] = 0;
    //this.cxctrncancelacionService.dblTotalFormaDevarray[this.indicador] = 0;
    //this.cxctrncancelacionService.dblTotalRetarray[this.indicador] = 0;
    //this.cxctrncancelacionService.dblTotalDocarray[this.indicador] = 0;
    // this.cxctrncancelacionService.dblTotalNDarray[this.indicador] = 0;
    this.numeroFactura = '';
    this.booleanAnulado = false;
    const res = await this.cxctrncancelacionService.getEncCarteraProm(valor);
    console.log(res[0]);
    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador] = res[0];
    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_ANULADO === 1) {
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      document.getElementById('divPrincipal').style.opacity = '0.7';
      this.booleanAnulado = true;
      this.btnBorrar = true;
    }

    // if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NDA') {
    //   this.itemTipoDoc = { label: 'NOTA DE DEBITO', value: 'NDA' };
    //   this.opcionesTipoDoc.push(this.itemTipoDoc);
    // }

    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC = 0;
    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC =
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC.toFixed(2);
    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO = 0;
    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO =
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO.toFixed(2);
    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO = 0;
    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO =
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO.toFixed(2);
    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG = 0;
    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG =
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG.toFixed(2);
    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR = 0;
    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR =
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR.toFixed(2);
    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALRET = 0;
    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALRET =
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALRET.toFixed(2);
    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALNC = 0;
    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].SALDO =
      Number(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].SALDO).toFixed(2);

    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'FC' && this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'ANC' &&
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'ND' && this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'NDA') {
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = null;
    } else {
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE =
        this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE.toFixed(2);
    }


    console.log(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador]);

    const data = await this.cxctrncancelacionService.erMaeclienteProm(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO);
    for (const resCli of data) {
      if (resCli.CLI_CONREGMIC === 'S'
        || resCli.CLI_AGENTERET === 'S') {
        if (resCli.CLI_CONREGMIC === 'S'
          && resCli.CLI_AGENTERET === 'S') {
          this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas y agente de retención';
        } else {
          if (resCli.CLI_CONREGMIC === 'S') {
            this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas';
          }
          if (resCli.CLI_AGENTERET === 'S') {
            this.lblAplicaRegMicroempresas = 'Cliente aplica agente de retención';
          }
        }
      } else {
        this.lblAplicaRegMicroempresas = '';
      }
    }

    // this.consultarPestañas();
    //await this.cargarAnexos();
    //GSRF
    // if ( this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
    // this.disimporte = true;
    //  }else{
    //   this.disimporte = false;
    //  }

     //GSRF
    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC'
      || this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA'
      || this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB'
      || this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT'
      || this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN'
      || this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
      this.bolsaldoIni = true;
    } else {
      this.bolsaldoIni = false;
    }
    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC.includes('NC') === true ||
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC.includes('ND') === true) {
      if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC.includes('ANC') === false &&
        this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC.includes('NDA') === false &&
        this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC.includes('NDR') === false) {
        this.bolnc = false;
      } else {
        this.bolnc = true;
      }
    } else {
      this.bolnc = true;
    }

    // const bolCierre = await this.documentoCierre('');

    // if (bolCierre) {
    //   document.getElementById('divPrincipal').style.pointerEvents = 'none';
    //   this.btnBorrar = true;
    //   this.botonAnular = true;
    // }



    // if (bolCierre) {
    //   this.lblDocCierre = true;
    // }

    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO !== 'CXC') {
      this.impresoString = 'IMPRESO';
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      document.getElementById('divPrincipal').style.opacity = '0.7';
      this.btnBorrar = true;
      this.botonAnular = true;
      /*this.columDefCarteraDoc.map((columna) => {
        columna.editable = false;
      });
      this.aggrid.refreshColumnDefs2(this.columDefCarteraDoc);
      this.editarCeldas = true;
      this.tipodetalleactivo = 'none';*/
    } else if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO === 'CXC') {
      this.impresoString = '';
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      document.getElementById('divPrincipal').style.opacity = '1.0';
      this.botonAnular = false;
      this.btnBorrar = false;
      /*this.columDefCarteraDoc.map((columna) => {
        columna.editable = true;
      });
      this.aggrid.refreshColumnDefs2(this.columDefCarteraDoc);
      this.editarCeldas = false;
      this.tipodetalleactivo = 'all';*/
    }

    console.log(new Date(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN));
    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN =
      new Date(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN);
    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE === null) {
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE =
        new Date(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN);
    } else {
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE =
        new Date(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE);
      console.log(new Date(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE));
    }

    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC') {
      this.booleanBodega = true;
      this.tabVarios = false;
      this.tabRetenciones = false;
      this.tabOtrosGastos = false;
      this.tabFormaPago = false;
      this.tabFormaPagoDev = false;
      this.tabEstadoCuentaAnt = false;
      this.tabDebitoBancario = false;
    } else {
      this.booleanBodega = false;
     
       if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
        this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA') {
        this.tabRetenciones = true;
        this.tabOtrosGastos = true;
        this.tabFormaPago = true;
        this.tabEstadoCuentaAnt = false;
        this.tabVarios = false;
        this.tabDebitoBancario = false;
        this.tabFormaPagoDev = false;
        const resTrnRes = await this.cxctrncancelacionService.obtenerDocumentosProm('DT', this.cxctrncancelacionService.encabezadoCarteraActivaarray
        [this.indicador].TRNCOBRO_NRODOC);

        this.cxctrncancelacionService.cxctrnresumenarray[this.indicador] = [];
        if (resTrnRes !== null) {
          for (const resumen of resTrnRes) {
            let objRes: Cxctrnresumen;
            objRes = {};
            objRes.TRNCOBRO_NRODOC = resumen.TRNDOCUMENTO_NRODOC;
            objRes.TRNCOBRO_FECHATRN = this.datePipe.transform(resumen.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
            objRes.TRNCOBRO_FECHAVENCE = this.datePipe.transform(resumen.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
            objRes.TRNCOBRO_CONCEPTO = resumen.TRNCOBRO_CONCEPTO;
            objRes.TRNCOBRO_SALDOINI = resumen.SALDOFAC;
            objRes.TRNCOBRO_IMPORTE = String(resumen.TRNDOCUMENTO_IMPORTE.toFixed(2));
            objRes.VEN_CODIGO = resumen.VEN_CODIGO;
            objRes.STRCUOTAFAC = resumen.TRNDOCUMENTO_REF;
            objRes.SELECCIONADO = true;
            objRes.CLI_CODIGO = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
            this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].push(objRes);
          }

        }
        if (this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].length !== 0) {
          this.numeroFactura = this.cxctrncancelacionService.cxctrnresumenarray[this.indicador][0].TRNCOBRO_NRODOC;
        }

        this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
        this.cxctrncancelacionService.calcularTotalDoc();

        const retenciones = await this.cxctrncancelacionService.obtenerRetencionesProm(this.cxctrncancelacionService.encabezadoCarteraActivaarray
        [this.indicador].TRNCOBRO_NRODOC);
        console.log(retenciones);
        this.cxctrncancelacionService.cxctrnretencionarray[this.indicador] = [];
        if (retenciones.length !== 0) {
          this.cxctrncancelacionService.cxctrnretencionarray[this.indicador] = retenciones;
          //CDPJ
          for (const itemRet of this.cxctrncancelacionService.cxctrnretencionarray[this.indicador]) {
            itemRet.NUEVALINEA=itemRet.LINEA;
          }
          //CDPJ
          this.cxctrncancelacionService.calcularTotalRetenido(-1);
        }

        const otrosG = await this.cxctrncancelacionService.obtenerOtrosGastosProm(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
        if (otrosG !== null) {
          this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador] = otrosG;
          this.cxctrncancelacionService.calcularTotalOtrosG();
        }

        const formapago = await this.cxctrncancelacionService.obtenerFormadePagoProm(this.cxctrncancelacionService.encabezadoCarteraActivaarray
        [this.indicador].TRNCOBRO_NRODOC);
        console.log(formapago);
        if (formapago !== null) {
          const arreglo = [];
          for (const form of formapago) {
            form.TRNPAGO_FECHA = this.datePipe.transform(form.TRNPAGO_FECHA, 'dd/MM/yyyy');
            if (form.TRNPAGO_TIPO === 'DP') {
              form.CAJ_CODIGO=''
            }
            if (form.TRNPAGO_TIPO === 'CH') {
              const banN = await this.cxctrncancelacionService.erBanclinombreProm(form.BANCLI_CODIGO);
              if (banN !== null) {
                form.BANCLI_NOMBRE = banN[0].BANCLI_NOMBRE;
              }
            } else if (form.TRNPAGO_TIPO === 'TA') {
              const banN = await this.cxctrncancelacionService.erTarDescripcion(form.BANCLI_CODIGO);
              if (banN !== null) {
                form.BANCLI_NOMBRE = banN[0].TAR_DESCRIPCION;
              }
            } else {
              const banN = await this.cxctrncancelacionService.erBanbancoProm(form.BANCLI_CODIGO);
              if (banN !== null) {
                form.BANCLI_NOMBRE = banN[0].BAN_BANCO;
              }
            }
            arreglo.push(form);
          }

          this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador] = arreglo;
          this.cxctrncancelacionService.calcularTotalFormaPago();
          await this.efectivoDepositado('COLOR');
          await this.chequeDepositado('COLOR');
          await this.depositoConciliado('COLOR');
          await this.tarjetaConciliada('COLOR');
        }

      }
    }

    if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI === 1) {
      this.saldoInicial = true;
      this.bolsaldoIni = true;
      this.tabVarios = false;
      this.tabDebitoBancario = false;
      this.tabFormaPagoDev = false;
      this.tabRetenciones = false;
      this.tabOtrosGastos = false;
      this.tabEstadoCuentaAnt = false;
      //CDPJ
      // if(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC'){
      //   this.tabEstadoCuentaAnt = true;
      // }
      //CDPJ
      this.tabFormaPago = false;
    } else if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI === 0) {
      this.saldoInicial = false;
    }

    const resApl = await this.cxctrncancelacionService.getOrigenProm(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO);
    if (resApl.length !== 0) {
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ORIGEN =
        this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO + '-' + resApl[0].APLORG_NOMBRE;
    } else {
      this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].ORIGEN =
        this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO + '-';
    }

    const resReal = await this.cxctrncancelacionService.getRealizadoProm(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].USU_IDENTIFICACION);
    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].REALIZADO = resReal[0].NOMBRE;

    this.bolestcta = false;
    this.bolsaldo = false;

    const bolCierre2 = await this.documentoCierre('');

    if (await this.chequeConciliado('') || await this.chequeDepositado('')
      || await this.depositoConciliado('') || await this.efectivoDepositado('')
      || await this.tarjetaConciliada('') || bolCierre2) {
      this.btneditConcil = false;
      this.editConcil = false;
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      document.getElementById('divPrincipal').style.opacity = '0.7';
    } else {
      this.btneditConcil = true;
      this.editConcil = true;
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      document.getElementById('divPrincipal').style.opacity = '1.0';
    }
      ///GSRF
    this.cxctrncancelacionService.getAsiento(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(async(res)  => {
      console.log('----***',res)
      this.asiento = res[0].ASI_NRO;
      if (this.asiento !== null) {
        const det = await this.cxctrncancelacionService.getDetConencasilProm(this.asiento, 'CXC');
        console.log('----***',det);
        let sum = 0;
        for (const aux of det) {
          sum=aux.DETASI_IMPORTE + sum;
        }
        console.log('----new',Number(sum.toFixed(2)));
        if(Number(sum.toFixed(2)) !== 0){
          document.getElementById("errasiento").style.display = '';
        }else{
          document.getElementById("errasiento").style.display = 'none';
        }
      }else{
        document.getElementById("errasiento").style.display = 'none';
      } 
    });
    //GSRF
  }
  async efectivoDepositado(strProceso) {
    let bolDep = false;

    if (this.tabFormaPago === true) {
      for (let fila = 1; fila <= this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].length; fila++) {
        if (this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_TIPO === 'EF') {
          const strNumDep = await this.efectivoDepositadoUtil(
            this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO,
            this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador][fila - 1].CAJ_CODIGO,
            this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
          console.log(strNumDep);
          if (strNumDep.length > 0) {
            if (strProceso !== 'COLOR') {
              if (String(strProceso).length > 0) {
                this.message.add({
                  key: 'cxctrncancelacion',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'No puede '
                    + strProceso
                    + ' el cobro, el efectivo '
                    + this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO
                    + ' está depositado.'
                    + '\r\rNro. Depósito: '
                    + strNumDep
                });
              }
              bolDep = true;
              break;
            } else {
              this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador][fila - 1].COLORDOCDEP = true;
              this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCancelacionFormaPago.objeto);
            }
          }
        }
      }
    }
    return bolDep;
  }

  async depositoConciliado(strProceso) {
    let bolConc = false;

    if (this.tabFormaPago === true) {
      for (let fila = 1; fila <= this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].length; fila++) {
        if (this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_TIPO === 'DP') {
          if (await this.documentoConciliado(this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO,
            this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador][fila - 1].BANCLI_CODIGO,
            this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_TIPO)) {
            if (strProceso !== 'COLOR') {
              if (strProceso.length > 0) {
                this.message.add({
                  key: 'cxctrncancelacion',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'No puede '
                    + strProceso
                    + ' el pago, el depósito '
                    + this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO
                    + ' está conciliado.'
                });
              }
              bolConc = true;
              break;
            } else {
              this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador][fila - 1].COLORDOCCONC = true;
              this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCancelacionFormaPago.objeto);

            }
          }
        }
      }
    }
    return bolConc;
  }
  async efectivoDepositadoUtil(strNumero, strCodCaja, strDocNro) {
    let strDepositado = '';

    console.log(strNumero, strCodCaja, strDocNro);
    try {
      const er = await this.cxctrncancelacionService.erTrncajdepositonro2Prom(strNumero, strDocNro, 'EF', strCodCaja);

      console.log(er);

      if (er !== null) {
        if (er[0] !== undefined) {
          strDepositado = er[0].TRNCAJ_DEPOSITONRO === null ? '' : er[0].TRNCAJ_DEPOSITONRO;
        }
      }
    } catch (error1) {
      this.message.add({
        key: 'cxctrncancelacion',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede verificar si el efectivo está depositado.'
      });
    }

    return strDepositado;
  }
  async documentoConciliado(strNumDoc, strCodBanco, strTipoDoc) {
    let strConcilia = '';
    let bolConcilia = false;
    try {
      const er = await this.cxctrncancelacionService.erTrnbanconciliaProm(strNumDoc, strCodBanco, strTipoDoc);
      if (er !== null) {
        if (er[0] !== undefined) {
          strConcilia = er[0].TRNBAN_CONCILIA;
        }
      }

      console.log(strConcilia);

      if (Number(strConcilia) === 1) {
        bolConcilia = true;
      }
    } catch (error) {
      this.message.add({
        key: 'cxctrncancelacion',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede verificar si el cheque está conciliado.'
      });
    }

    console.log(bolConcilia);

    return bolConcilia;

  }
  async chequeDepositado(strProceso) {
    let bolDep = false;

    if (this.tabFormaPago === true) {
      for (let fila = 1; fila <= this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].length; fila++) {
        if (this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_TIPO === 'CH') {
          const strNumDep = await this.chequeDepositadoUtil(
            this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO,
            this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador][fila - 1].BANCLI_CODIGO,
            this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
          console.log(strNumDep);
          if (strNumDep.length > 0) {
            if (strProceso !== 'COLOR') {
              if (String(strProceso).length > 0) {
                this.message.add({
                  key: 'cxctrncancelacion',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'No puede '
                    + strProceso
                    + ' el cobro, el cheque '
                    + this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO
                    + ' está depositado.'
                    + '\r\rNro. Depósito: '
                    + strNumDep
                });
              }
              bolDep = true;
              break;
            } else {
              this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador][fila - 1].COLORDOCDEP = true;
              this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCancelacionFormaPago.objeto);

            }
          }

        }
      }
    }
    return bolDep;
  }
  async chequeDepositadoUtil(strNumCheque, strCodBanco, strDocumento) {
    let strDepositado = '';
    console.log(strNumCheque, strCodBanco, strDocumento);
    try {
      const er = await this.cxctrncancelacionService.erTrncajdepositonroProm(strNumCheque, strCodBanco, 'CH', strDocumento);
      if (er !== null) {
        if (er[0] !== undefined) {
          strDepositado = er[0].TRNCAJ_DEPOSITONRO === null ? '' : er[0].TRNCAJ_DEPOSITONRO;
        }
      }
    } catch (error1) {
      this.message.add({
        key: 'cxctrncancelacion',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede verificar si el cheque está depositado.'
      });
    }

    return strDepositado;
  }
  async tarjetaConciliadaUtil(strNumero, strCodCaja, strDocNro) {
    let bolConc = false;
    let strCodConc = '';
    try {
      const er = await this.cxctrncancelacionService.erTrntarjetacodigoProm(strNumero, strDocNro, 'TA', strCodCaja);
      if (er !== null) {
        if (er[0] !== undefined) {
          strCodConc = er[0].TRNTARJETA_CODIGO === null ? '' : er[0].TRNTARJETA_CODIGO;
        }
      }
      if (String(strCodConc).length > 0) {
        bolConc = true;
      }
    } catch (error1) {
      this.message.add({
        key: 'cxctrncancelacion',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede verificar si la tarjeta está conciliada.'
      });
    }
    return bolConc;
  }

  async tarjetaConciliada(strProceso) {
    let bolConc = false;

    if (this.tabFormaPago === true) {
      for (let fila = 1; fila <= this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].length; fila++) {
        if (this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_TIPO === 'TA') {
          if (await this.tarjetaConciliadaUtil(this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO,
            this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador][fila - 1].CAJ_CODIGO,
            this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE)) {
            if (strProceso !== 'COLOR') {
              if (strProceso.length > 0) {
                this.message.add({
                  key: 'cxctrncancelacion',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'No puede '
                    + strProceso
                    + ' el pago, el voucher '
                    + this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO
                    + ' está conciliado.'
                });
              }
              bolConc = true;
              break;
            } else {
              this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador][fila - 1].COLORDOCCONC = true;
              this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCancelacionFormaPago.objeto);

            }
          }
        }
      }
    }
    return bolConc;
  }
  async documentoCierre(strProceso) {
    let bolCierre = false;
    let strCierre: any = '0';

    let strNumDoc = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;

    if (Object.keys(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador]).length > 0) {
      strNumDoc = this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
    }


    try {
      strCierre = await this.cxctrncancelacionService.erTrncobrocuadreProm(strNumDoc);

      if (strCierre[0].TRNCOBRO_CUADRE === '1') {
        if (String(strProceso).length > 0) {
          this.message.add({
            key: 'cxctrncancelacion',
            severity: 'info',
            summary: 'Información',
            detail: 'No puede ' + strProceso + ' el pago, el documento está en el cierre de caja.'
          });
        }
        bolCierre = true;
      }
    } catch (err) {
      this.message.add({
        key: 'cxctrncancelacion',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede verificar si el documento ya está en el cierre de caja.'
      });
    }

    return bolCierre;
  }
  async chequeConciliado(strProceso) {
    let bolConc = false;

    if (this.tabFormaPagoDev !== false) {
      for (let fila = 1; fila <= this.cxctrncancelacionService.cxctrnformapagodevarray[this.indicador].length; fila++) {
        if (this.cxctrncancelacionService.cxctrnformapagodevarray[this.indicador][fila - 1].TRNCAJ_TIPO === 'CH') {
          if (await this.documentoConciliado(this.cxctrncancelacionService.cxctrnformapagodevarray[this.indicador][fila - 1].TRNCAJ_NUMERO,
            this.cxctrncancelacionService.cxctrnformapagodevarray[this.indicador][fila - 1].BAN_CODIGO,
            this.cxctrncancelacionService.cxctrnformapagodevarray[this.indicador][fila - 1].TRNCAJ_TIPO)) {
            if (strProceso !== 'COLOR') {
              if (String(strProceso).length > 0) {
                this.message.add({
                  key: 'cxctrncancelacion',
                  severity: 'success',
                  summary: 'Información',
                  detail: 'No puede '
                    + strProceso
                    + ' el pago, el cheque '
                    + this.cxctrncancelacionService.cxctrnformapagodevarray[this.indicador][fila - 1].TRNCAJ_NUMERO
                    + ' está conciliado.'
                });
              }
              bolConc = true;
              break;
            } else {
              // modeloFPDev.ponerColorDocConc(fila);
            }

          }
        }
      }
    }
    return bolConc;
  }
  seleccionRetencion(valor) {
    if (valor === 'nuevo') {
      this.nuevaRetencion();
    } else if (valor === 'eliminar') {
      this.confirmarEliminarRetencion();
    } else if (valor === 'cancelar') {
      this.cancelarRetencion();
    }
    if (valor.object !== undefined) {
      this.detalleRetencionSeleccionado = valor.object;
    }
  }
  seleccionGastos(valor) {
    if (valor === 'nuevo') {
      this.nuevosGastos();
    } else if (valor === 'eliminar') {
      this.confirmarEliminarOtrosG();
    } else if (valor === 'cancelar') {
      this.cancelarOtrosG();
    }
    if (valor.object !== undefined) {
      this.detalleOtrasFSeleccionada = valor.object;
    }
  }

  seleccionFormaPago(valor) {
    if (valor === 'nuevo') {
      if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO !== 'CXC') {
        return;
      }
      this.nuevaFormaPago();
    } else if (valor === 'eliminar') {
      if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO !== 'CXC') {
        return;
      }
      //GSRF
      console.log('entra-*-*-*-*-*',this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC )
      console.log('entra-*-*-*-*-*',this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC )
      if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '' 
      && this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== null
      && this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== undefined) {
        if(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC ==='AB'
          ||this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC ==='CA'){
            this.message.add({
              key: 'cxctrncancelacion',
              severity: 'error',
              summary: 'Cancelación',
              detail: 'No es posible eliminar la forma de pago. Elimine el documento y vuelva a ingresar.'
            });
          return;
        }
        
      }
      //GSRF
      this.confirmarEliminarFormaPago();
    } else if (valor === 'cancelar') {
      if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO !== 'CXC') {
        return;
      }
      this.cancelarFormaPago();
    }else if (valor === 'generaconcepto') {
      let seleccionados = 0;
    if (this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].length !== 0) {
      this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].map((resumen) => {
        if (resumen.SELECCIONADO === true) {
          seleccionados++;
        }
      });
    }else{
      this.message.add({
        key: 'cxctrncancelacion',
        severity: 'error',
        summary: 'Inserción de Cancelación',
        detail: 'Ningún documento ha sido ' +
          'seleccionado. '
      });
      this.spin=false//CDPJ
      return;
    }
    if (seleccionados === 0) {
      this.message.add({
        key: 'cxctrncancelacion',
        severity: 'error',
        summary: 'Inserción de Cancelación',
        detail: 'Ningún documento ha sido ' +
          'seleccionado.'
      });
      this.spin=false//CDPJ
      return;
    }
      if (this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO !== 'CXC') {
        return;
      }else if(this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].length === 0){
        this.message.add({
          key: 'cxctrncancelacion',
          severity: 'error',
          summary: 'Abono',
          detail: 'Ingrese una forma de pago al detalle'
        });
      return;
      }
      this.generarconcepto()
    }
    if (valor.object !== undefined) {
      this.detalleFormaPago = valor.object;
    }
  }
  nuevaRetencion() {
    if (this.numeroFactura !== '') {
      let intNumRet = 0;
      this.cxctrncancelacionService.erCountSacitrnretencion(this.numeroFactura).subscribe(er => {
        if (er !== null) {
          if (er[0] !== undefined) {
            intNumRet = Number(er[0].COUN);
          }
        }

        if (intNumRet > 0) {
          this.message.add({
            key: 'cxctrncancelacion',
            severity: 'error',
            summary: 'Información',
            detail: 'La factura ya tiene ingresada una retención.'
          });
        } else {
          this.editpagofr = false;
          this.btnNuevoGrid = true;
          this.btnGuardarGrid = false;
          this.btnBorrarGrid = false;
          this.btnCancelarGrid = false;
          const linea = this.cxctrncancelacionService.cxctrnretencionarray[this.indicador].length;
          const nuevodetalle = {
            RETENCION_CODIGO: '',
            TRNRETENCION_DESCRIPCION: '',
            TRNRETENCION_NRO: '',
            TRNRETENCION_BASE: 0,
            TRNRETENCION_PORCENTAJE: 0,
            TRNRETENCION_TOTALRETENIDO: 0,
            ENCFAC_NUMERO: this.numeroFactura,
            CLI_CODIGO: this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO,
            CEN_CODIGO: '',
            CON_CODIGO: '',
            TRNRETENCION_ORIGEN: 'CXC',
            LINEA: String(linea + 1),
            NUEVALINEA:0//CDPJ
          };
          if (this.detalleRetencionSeleccionado !== undefined && this.detalleRetencionSeleccionado !== null) {
            const indi = this.cxctrncancelacionService.cxctrnretencionarray[this.indicador].indexOf(this.detalleRetencionSeleccionado);
            if (indi === -1) {
              this.cxctrncancelacionService.cxctrnretencionarray[this.indicador].push(nuevodetalle);
              this.detalleRetencionSeleccionado = this.cxctrncancelacionService.cxctrnretencionarray[this.indicador][this.cxctrncancelacionService.cxctrnretencionarray[this.indicador].length - 1];
              this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnretencionarray[this.indicador], this.defaultColumnDefCancelacionRet.objeto);
              this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCancelacionRet.objeto].setFocusedCell(this.cxctrncancelacionService.cxctrnretencionarray[this.indicador].length - 1, 'RETENCION_CODIGO');
            } else {
              //CDPJ
              this.cxctrncancelacionService.cxctrnretencionarray[this.indicador].splice(indi + 1, 0, nuevodetalle);
              this.detalleRetencionSeleccionado = nuevodetalle;
              this.aggrid.refreshaggridindex(this.cxctrncancelacionService.cxctrnretencionarray[this.indicador], this.defaultColumnDefCancelacionRet.objeto, indi);
              //CDPJ
              this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCancelacionRet.objeto].setFocusedCell(indi + 1, 'RETENCION_CODIGO');
            }
          }
        }
      });
    } else {
      this.editpagofr = false;
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      this.message.add({
        key: 'cxctrncancelacion',
        severity: 'error',
        summary: 'Información',
        detail: 'Escoja una factura...'
      });
    }
  }

  nuevosGastos() {
    this.editpagofr = false;
    this.btnNuevoGrid = true;
    this.btnGuardarGrid = false;
    this.btnBorrarGrid = false;
    this.btnCancelarGrid = false;
    const linea = this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador].length;
    const nuevodetalle = {
      COMISION_CODIGO: '',
      TRNCOMISION_DESCRIPCION: '',
      TRNCOMISION_NRO: '',
      TRNCOMISION_TOTALRETENIDO: 0,
      LINEA: String(linea + 1),
      TRNCOMISION_BASE: 0,
      TRNCOMISION_PORCENTAJE: 0,
      TRNCOBRO_NRODOC: this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
      TRNCOMISION_ORIGEN: 'CXC',
      CLI_CODIGO: this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO,
      CON_CODIGO: '',
      TRNCOBRO_NROCOMPROBANTE: this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE,
      TRNCOMISION_FLAG: ''
    };
    if (this.detalleOtrasFSeleccionada !== undefined && this.detalleOtrasFSeleccionada !== null) {
      const indi = this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador].indexOf(this.detalleOtrasFSeleccionada);
      if (indi === -1) {
        this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador].push(nuevodetalle);
        this.detalleOtrasFSeleccionada = this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador][this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador].length - 1];
        this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador], this.defaultColumnDefCancelacionOtrosG.objeto);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCancelacionOtrosG.objeto].setFocusedCell(this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador].length - 1, 'COMISION_CODIGO');
      } else {
        this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador].splice(indi + 1, 0, nuevodetalle);
        this.aggrid.refreshaggridindex(this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador], this.defaultColumnDefCancelacionOtrosG.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCancelacionOtrosG.objeto].setFocusedCell(indi + 1, 'COMISION_CODIGO');
      }
    }
  }

  nuevaFormaPago() {
    this.editpagofr = false;
    this.btnNuevoGrid = true;
    this.btnGuardarGrid = false;
    this.btnBorrarGrid = false;
    this.btnCancelarGrid = false;
    const linea = this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].length;
    const nuevodetalle = {
      TRNCOBRO_NRODOC: '',
      TRNPAGO_TIPO: '',
      CAJ_CODIGO: '',
      TRNPAGO_NUMERO: '',
      TRNPAGO_CONCEPTO: this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_CONCEPTO,
      TRNPAGO_IMPORTE:
        Number(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR) - Number(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO),
      BANCLI_CODIGO: '',
      BANCLI_NOMBRE: '',
      TRNPAGO_NROCTA: '',
      TRNPAGO_CODSRI: '',
      TRNPAGO_TIPDIS: '',
      TRNPAGO_FECHA: this.datePipe.transform(new Date(), 'dd/MM/yyyy'),
      CON_CODIGO: '',
      CLI_CODIGO: this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO,
      TRNCHEPOS_NUMDOC: '',
      LINEA: String(linea + 1)
    };
    this.detalleFormaPago = nuevodetalle;
    if (this.detalleFormaPago !== undefined && this.detalleFormaPago !== null) {
      const indi = this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].indexOf(this.detalleFormaPago);
      if (indi === -1) {
        this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].push(nuevodetalle);
        this.detalleFormaPago = this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador][this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].length - 1];
        this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCancelacionFormaPago.objeto);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCancelacionFormaPago.objeto].setFocusedCell(this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].length - 1, 'TRNPAGO_TIPO');
        this.cxctrncancelacionService.calcularTotalFormaPago();
      } else {
        this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].splice(indi + 1, 0, nuevodetalle);
        this.aggrid.refreshaggridindex(this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCancelacionFormaPago.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCancelacionFormaPago.objeto].setFocusedCell(indi + 1, 'TRNPAGO_TIPO');
        this.cxctrncancelacionService.calcularTotalFormaPago();
      }
    }
  }
  confirmarEliminarRetencion() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el registro seleccionado',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'confCxctrncancelacion',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.eliminarRetencion();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }
  eliminarRetencion() {
    if (this.detalleRetencionSeleccionado !== undefined) {
      this.editpagofr = false;
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      if (this.detalleRetencionSeleccionado.NUEVALINEA === 0) {
        this.cxctrncancelacionService.cxctrnretencionarray[this.indicador] = this.cxctrncancelacionService.cxctrnretencionarray[this.indicador].filter((val, j) => j !==
          this.cxctrncancelacionService.cxctrnretencionarray[this.indicador].indexOf(this.detalleRetencionSeleccionado));
        this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnretencionarray[this.indicador], this.defaultColumnDefCancelacionRet.objeto);
        this.cxctrncancelacionService.calcularTotalRetenido(0);
      } else {
        // this.cxctrncancelacionService.eliminarTrnRetencion('TRNCOBRO_NRODOC = \'' +
        //   this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\' AND TRNRETENCION_ORIGEN = \'CXC\'').subscribe(() => {
        //     this.cxctrncancelacionService.cxctrnretencionarray[this.indicador] = this.cxctrncancelacionService.cxctrnretencionarray[this.indicador].filter((val, j) => j !==
        //       this.cxctrncancelacionService.cxctrnretencionarray[this.indicador].indexOf(this.detalleRetencionSeleccionado));
        //     this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnretencionarray[this.indicador], this.defaultColumnDefAbonoRet.objeto);
        //     this.cxctrncancelacionService.calcularTotalRetenido(0);
        //   });
        this.message.add({
          key: 'cxctrncancelacion',
          severity: 'error',
          summary: 'Cancelación',
          detail: 'No es posible eliminar las retenciones guardadas. Elimine y vuelva a ingresar todo el documento.'
        });
        return;
      }
    }
  }
  cancelarRetencion() {
    if (this.detalleRetencionSeleccionado.LINEA === 0) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = true;
      this.cxctrncancelacionService.cxctrnretencionarray[this.indicador] = this.cxctrncancelacionService.cxctrnretencionarray[this.indicador].filter((val, j) => j !==
        this.cxctrncancelacionService.cxctrnretencionarray[this.indicador].indexOf(this.detalleRetencionSeleccionado));
      this.detalleFormaPago = {};
    } else if (this.detalleRetencionSeleccionado.LINEA !== 0) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = true;
      this.cxctrncancelacionService.obtenerRetenciones(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
        this.cxctrncancelacionService.cxctrnretencionarray[this.indicador] = res;
        this.cxctrncancelacionService.cxctrnretencionarray[this.indicador].map((detalle) => {
          if (detalle.LINEA === this.detalleRetencionSeleccionado.LINEA) {
            this.detalleRetencionSeleccionadoAux
              = detalle;
          }
        });
        this.detalleRetencionSeleccionado = this.detalleRetencionSeleccionadoAux;
        this.cxctrncancelacionService.calcularTotalRetenido(0);
      });
    }
  }
  confirmarEliminarOtrosG() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el registro seleccionado',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'confCxctrncancelacion',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.eliminarOtrosG();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }
  eliminarOtrosG() {
    if (this.detalleOtrasFSeleccionada !== undefined) {
      this.editpagofr = false;
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      if (this.detalleOtrasFSeleccionada.TRNCOBRO_NRODOC === '') {
        this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador] = this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador].filter((val, j) => j !==
          this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador].indexOf(this.detalleOtrasFSeleccionada));
        this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador], this.defaultColumnDefCancelacionOtrosG.objeto);
        this.cxctrncancelacionService.calcularTotalOtrosG();
      } else {
        this.cxctrncancelacionService.eliminarCaja(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
          this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
            '', '01', '', '', '', '').subscribe(() => {
            });
        });
        this.cxctrncancelacionService.eliminarBanco(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
          this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
            '', '01', '', '', '', '').subscribe(() => {
            });
        });
        this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador] = this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador].filter((val, j) => j !==
          this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador].indexOf(this.detalleFormaPago));
        this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador], this.defaultColumnDefCancelacionOtrosG.objeto);
        this.cxctrncancelacionService.calcularTotalOtrosG();
      }
    }
  }
  cancelarOtrosG() {
    if (this.detalleOtrasFSeleccionada.LINEA === 0) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = true;
      this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador] = this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador].filter((val, j) => j !==
        this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador].indexOf(this.detalleFormaPago));
      this.detalleFormaPago = {};
    } else if (this.detalleOtrasFSeleccionada.LINEA !== 0) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = true;
      this.cxctrncancelacionService.obtenerOtrosGastos(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
        this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador] = res;
        this.cxctrncancelacionService.cxctrnotrosgarray[this.indicador].map((detalle) => {
          if (detalle.LINEA === this.detalleOtrasFSeleccionada.LINEA) {
            this.detalleOtrasFSeleccionadaAux
              = detalle;
          }
        });
        this.detalleOtrasFSeleccionada = this.detalleOtrasFSeleccionadaAux;
        this.cxctrncancelacionService.calcularTotalOtrosG();
      });
    }
  }
  confirmarEliminarFormaPago() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el registro seleccionado',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'confCxctrncancelacion',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.eliminarFormaPago();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }
  eliminarFormaPago() {
    //this.editpagofr = false;
    if (this.detalleFormaPago !== undefined) {
      this.editpagofr = false;
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      if (this.detalleFormaPago.TRNCOBRO_NRODOC === '') {
        this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador] = this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].filter((val, j) => j !==
          this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].indexOf(this.detalleFormaPago));
        this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCancelacionFormaPago.objeto);
        this.cxctrncancelacionService.calcularTotalFormaPago();
      } else {
        this.cxctrncancelacionService.eliminarFormaPago(this.detalleFormaPago.TRNCOBRO_NRODOC).subscribe(() => {
          if (this.detalleFormaPago.TRNPAGO_TIPO === 'DP') {
            this.cxctrncancelacionService.eliminarDPBanco(this.detalleFormaPago.TRNPAGO_NUMERO).subscribe(() => {
            });
          }
          this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador] = this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].filter((val, j) => j !==
            this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].indexOf(this.detalleFormaPago));
          this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCancelacionFormaPago.objeto);
          this.auditoriagral.registrarAuditoria('CXC_TRNPAGO',
            String(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '/' +
              this.detalleFormaPago.TRNPAGO_NUMERO), 'I',
            '', '01', '', '', '', '').subscribe(() => {
            });
          this.cxctrncancelacionService.calcularTotalFormaPago();
        });
      }
    }
  }
  cancelarFormaPago() {
    this.editpagofr = true;
    if (this.detalleFormaPago.LINEA !== undefined && this.detalleFormaPago.TRNPAGO_TIPO.length === 0) {
      this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].splice(
        this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].findIndex(det =>
          det.LINEA === this.detalleFormaPago.LINEA && det.TRNPAGO_TIPO === this.detalleFormaPago.TRNPAGO_TIPO), 1);
      if (this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].length > 0) {
        this.detalleFormaPago = this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador]
        [this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].length - 1];
      }
      this.cxctrncancelacionService.calcularTotalFormaPago();
      this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCancelacionFormaPago.objeto);
    }
    /*
      if (this.detalleFormaPago.LINEA === 0) {
        this.btnNuevoGrid = false;
        this.btnGuardarGrid = true;
        this.cxctrncancelacionService.cxctrnformapago = this.cxctrncancelacionService.cxctrnformapago.filter((val, j) => j !==
          this.cxctrncancelacionService.cxctrnformapago.indexOf(this.detalleFormaPago));
        this.detalleFormaPago = {};
      } else if (this.detalleFormaPago.LINEA !== 0) {
        this.btnNuevoGrid = false;
        this.btnGuardarGrid = true;
        this.cxctrncancelacionService.obtenerFormadePago(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
          this.cxctrncancelacionService.cxctrnformapago = res;
          this.cxctrncancelacionService.cxctrnformapago.map((detalle) => {
            if (detalle.LINEA === this.detalleFormaPago.LINEA) {
              this.detalleFormaPagoAux
                = detalle;
            }
          });
          this.detalleFormaPago = this.detalleFormaPagoAux;
          this.cxctrncancelacionService.calcularTotalFormaPago();
        });
      }*/
  }
  generarconcepto(){
    let concepto='Cancelación a factura/s ';
    let facturas='';
    let formasdepago='';
    this.cxctrncancelacionService.cxctrnresumenarray[this.indicador].map((resumen) => {
      if (resumen.SELECCIONADO === true) {
        facturas=facturas+resumen.TRNCOBRO_NRODOC + ' / ';
      }
    }); 
    this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].map((formpag)=>{
      formasdepago=formasdepago+formpag.TRNPAGO_TIPO+' '+formpag.TRNPAGO_NUMERO+'/ '
    })  
    concepto=concepto+facturas+formasdepago;
    this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_CONCEPTO=concepto;

    this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador].map((formpag)=>{
      formpag.TRNPAGO_CONCEPTO=concepto
      
    }) 
    this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCancelacionFormaPago.objeto);
  }
}
