
<div style="height: 98%">
<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="autorizacion"></p-toast>
	<div class="p-grid" style="width: 98%">
		<div class="p-col-12">
			<app-invmaebarra [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"
							 [barraBotones5]="true" [botonVisto]="false" [barraBotones3Alt]="false" [barraBotonesCRUD]="true"
							 (signal)="manejarSenalesActivar($event)"
							 style="text-align: left;">
			</app-invmaebarra>
		</div>
	</div>
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							   (keydown.enter)="opcionVisto()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</div>
