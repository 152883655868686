<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="maeautortoast"></p-toast>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="true" [botonGuardar]="btnGuardar" [botonBorrar]="true" [barraBotonesDesplaz]="true"
						 [barraBotones3]="true"  [botonBuscar]="btnBuscar"
						 (signal)="manejarSenales($event)">
		</app-invmaebarra>
	</div>
</div>
<div style="overflow: hidden;margin-top: -10px;" class="p-col-12">
	<p-tabView>
		<p-tabPanel header="Periodo">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="segmaeAutorizaciones"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefMaeAutorizacion"
							[defaultColDef]="defaultColDefMaeAutorizaciones"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)=selectMaePeriodo($event)
							(cambios)="cambio($event)"

			></app-aggridsaci>
		</p-tabPanel>
	</p-tabView>
</div>
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [responsive]="true"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlgmae [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
						[where]="condicion"
						[busquedaParams]="busquedacampos"
						(opcionSeleccionada)="manejarSeleccion($event)"
						[busquedaCompuesta]="false"></app-busquedadlgmae>
</p-dialog>
