import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ComDetfacpro} from '../cominterfaces/comdetfacpro';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {DatePipe} from '@angular/common';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {ActualizacionpreciosfpService} from '../comservicios/actualizacionpreciosfp.service';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {AggridBanComponent} from '../../ban/aggrid-ban/aggrid-ban.component';
import {ActualizacionPreciosFp} from '../cominterfaces/actualizacionpreciosfp';

@Component({
  selector: 'app-actualizacionpreciosfp',
  templateUrl: './actualizacionpreciosfp.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class ActualizacionpreciosfpComponent implements OnInit, OnChanges {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  @Input() auxiliar: any;
  @Input() strNumFact: string;
  @Input() hmpConfiguracion: any[];
  @Output() cerrar = new EventEmitter<boolean>();
  
  largo: string;
  
  listdetalle: ComDetfacpro[];
  detalleSeleccionado: ComDetfacpro;
  
  detalleprecioSeleccionado: ActualizacionPreciosFp;
  
  chkseleccionartodo: boolean;
  
  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  defaultColDefDet;
  defaultColDefDetPrecios;
  
  columnDefsDet = [
    {
      headerName: 'Código', field: 'DETFACPRO_CODIGO', width: 150
    },
    {
      headerName: 'Descripción', field: 'DETFACPRO_DESCRIPCION', width: 300
    }
  ];
  
  columnDefsDetPrecios = [
    { // 0
      headerName: 'Sel.',
      field: 'SELECCIONADO',
      cellRendererFramework: AggridBanComponent,
      editable: false,
      width: 50,
      onCellClicked: (params) => {
        this.docSeleccionado(params);
      }
    },
    {
      headerName: 'Lista', field: 'ARTPRE_CODIGO', width: 50,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: false,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            clave: false,
            tamanocelda: 0
          }
        }
    },
    {
      headerName: 'PrecioActual', field: 'ARTPRE_PRECIO', width: 100, cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: false,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            clave: false,
            tamanocelda: 0
          }
        }
    },
    {
      headerName: 'Unidad', field: 'UNI_CODIGO', width: 80,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: false,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            clave: false,
            tamanocelda: 0
          }
        }
    },
    {
      headerName: '%Utilidad', field: 'ARTPRE_PORCENTAJE', width: 100, cellStyle: {textAlign: 'right'}, cellEditor: 'cellPrueba',
      editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        }
    },
    {
      headerName: 'Ult.CostoCompra', field: 'ULTIMOCOSTOCOMPRA', width: 120, cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: false,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            clave: false,
            tamanocelda: 0
          }
        }
    },
    {
      headerName: 'CostoPromedio', field: 'COSTOPROMEDIO', width: 120, cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: false,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            clave: false,
            tamanocelda: 0
          }
        }
    },
    {
      headerName: 'PrecioNuevo', field: 'PRECIO', width: 120, cellStyle: {textAlign: 'right'}, cellEditor: 'cellPrueba',
      editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        }
    },
    {
      headerName: 'Incremento', field: 'INCREMENTO', width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: false,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            clave: false,
            tamanocelda: 0
          }
        }
    }
  ];
  
  
  constructor(public actpreService: ActualizacionpreciosfpService, private datePipe: DatePipe,
              private messageService: MessageService, private confirmationService: ConfirmationService,
              private errorService: ErroresBaseDatosService, private auditoriagral: AuditoriagralService,
              private confIniciales: ConfInicialesService) {
    this.agTable();
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    
    this.defaultColDefDet = {
      editable: false,
      width: 100,
      objeto: 'actprefp'
    };
    
    this.defaultColDefDetPrecios = {
      editable: false,
      width: 100,
      objeto: 'actpreciosfp'
    };
  }
  
  ngOnInit() {
    this.largo = '620';
    
    this.detalleSeleccionado = {};
    this.chkseleccionartodo = false;
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    for (const e in changes) {
      if (e === 'auxiliar' && this.strNumFact !== undefined) {
        this.listarDatos(this.strNumFact);
      }
    }
  }
  
  manejarSenales(valor) {
    if (valor === 'Visto') {
      let cont = 0;
      for (let intIndiceLista = 0; intIndiceLista < this.actpreService.listdetalleprecios.length; intIndiceLista++) {
        if (this.actpreService.listdetalleprecios[intIndiceLista].SELECCIONADO === true) {
          cont++;
        }
      }
      
      if (cont === 0) {
        this.messageService.add({
          key: 'actprefp',
          severity: 'warn',
          summary: 'Información',
          detail: 'Ninguna lista de precios fue seleccionada'
        });
      } else {
        this.confirmationService.confirm({
          message: 'Está seguro de realizar la actualización de los precios?',
          header: 'Pregunta',
          icon: 'pi pi-exclamation-triangle',
          key: 'actprefpconf',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.actualizaTablaInvMaeArtPrecio();
          },
          reject: () => {
          }
        });
      }
    }
    
    if (valor === 'Salir') {
      this.cerrar.emit(true);
    }
    
  }
  
  selecciondetalle(params) {
    if (params.object !== undefined) {
      this.detalleSeleccionado = params.object;
      this.chkseleccionartodo = false;
      
      this.mostrarPrecios(this.detalleSeleccionado.DETFACPRO_CODIGO, this.detalleSeleccionado.DETFACPRO_LINEA);
      
      this.desplegarDescripciones();
    }
  }
  
  clickclick() {
    this.mostrarPrecios(this.detalleSeleccionado.DETFACPRO_CODIGO, this.detalleSeleccionado.DETFACPRO_LINEA);
    
    this.desplegarDescripciones();
    
  }
  
  
  selecciondetalleprecio(params) {
    if (params.object !== undefined) {
      this.detalleprecioSeleccionado = params.object;
    }
  }
  
  mostrarPrecios(strCodArticulo, intDetFacProLinea) {
    let dblIncremento = 0;
    
    this.actpreService.calcularPreciosVentas(this.strNumFact, strCodArticulo, intDetFacProLinea).subscribe(data => {
      if (data === null) {
        return;
      }
      
      this.actpreService.listdetalleprecios = data;
      
      for (const item of this.actpreService.listdetalleprecios) {
        item.ARTPRE_PRECIO = Number(item.ARTPRE_PRECIO).toFixed(2);
        item.ARTPRE_PORCENTAJE = Number(item.ARTPRE_PORCENTAJE).toFixed(2);
        item.PRECIO = Number(item.PRECIO).toFixed(2);
        item.ULTIMOCOSTOCOMPRA = Number(item.ULTIMOCOSTOCOMPRA).toFixed(2);
        item.COSTOPROMEDIO = Number(item.COSTOPROMEDIO).toFixed(4);
        item.SELECCIONADO = false;
        
        // Incremento
        dblIncremento = Number(item.PRECIO) - Number(item.ARTPRE_PRECIO);
        item.INCREMENTO = Number(dblIncremento).toFixed(2);
      }
    });
  }
  
  desplegarDescripciones() {
    this.actpreService.encontrarArtCodigoAlt2(this.detalleSeleccionado.DETFACPRO_CODIGO).subscribe(er => {
      if (er !== null) {
        if (er[0] !== undefined) {
          this.detalleSeleccionado.DETFACPRO_CODIGOALT = er[0].ART_CODIGOALT2;
        }
      }
    });
  }
  
  listarDatos(strNumFact) {
    this.listdetalle = [];
    this.actpreService.listdetalleprecios = [];
    let strCadena = '';
    const lista = [];
    
    this.actpreService.ejecutarConsulta1(strNumFact).subscribe(data => {
      for (const rs of data) {
        const item: ComDetfacpro = {};
        
        item.DETFACPRO_CODIGO = rs.DETFACPRO_CODIGO;
        item.DETFACPRO_DESCRIPCION = rs.DETFACPRO_DESCRIPCION;
        item.DETFACPRO_LINEA = rs.DETFACPRO_LINEA;
        lista.push(item);
      }
      
      this.listdetalle = lista;
      
    });
  }
  
  actualizaDatosArticulos() {
    this.confirmationService.confirm({
      message: 'Está seguro de actualizar los datos',
      header: 'Actualizar',
      icon: 'pi pi-exclamation-triangle',
      key: 'actprefpconf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        
        let intIndiceLista = 0;
        let item: ActualizacionPreciosFp;
        let strSentencia = '';
        
        
        // Actualiza el codigo alterno
        this.actpreService.ejecutarConsulta2(this.detalleSeleccionado.DETFACPRO_CODIGOALT, this.detalleSeleccionado.DETFACPRO_CODIGO).subscribe(consulta => {
          strSentencia = '';
          
          // actualiza el porcentaje de utilidad
          
          for (intIndiceLista = 0; intIndiceLista < this.actpreService.listdetalleprecios.length; intIndiceLista++) {
            item = this.actpreService.listdetalleprecios[intIndiceLista];
            this.actpreService.ejecutarConsulta3(item.ARTPRE_PORCENTAJE, item.ART_CODIGO, item.UNI_CODIGO, item.ARTPRE_CODIGO).subscribe();
          }
          
          const lista = [];
          
          for (const aux of this.actpreService.listdetalleprecios) {
            lista.push(aux);
          }
          
          this.actpreService.listdetalleprecios = lista;
          
          this.messageService.add({
            key: 'actprefp',
            severity: 'success',
            summary: 'Información',
            detail: '! Datos Actualizados correctamente !'
          });
        });
      }, reject: () => {
      }
    });
    
  }
  
  docSeleccionado(valor) {
  }
  
  seleccionarTodo() {
    const lista = [];
    
    for (const item of this.actpreService.listdetalleprecios) {
      item.SELECCIONADO = this.chkseleccionartodo;
      lista.push(item);
    }
    
    this.actpreService.listdetalleprecios = lista;
  }
  
  actualizaTablaInvMaeArtPrecio() {
    
    let intIndiceLista = 0;
    let item: ActualizacionPreciosFp;
    let strSentencia = '';
    let sw = 0;
    
    const bolACTCPAP = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ACTCPAP').CFG_VALOR1;
    
    for (intIndiceLista = 0; intIndiceLista < this.actpreService.listdetalleprecios.length; intIndiceLista++) {
      item = this.actpreService.listdetalleprecios[intIndiceLista];
      
      if (item.SELECCIONADO === true) {
        // actualiza en la tabla de precios
        strSentencia = 'update inv_maeartprecio SET ARTPRE_PRECIO= ' + item.PRECIO;
        if (bolACTCPAP === 1) {
          strSentencia = strSentencia + ' , artpre_costo=' + '\'' + item.COSTOPROMEDIO + '\'';
        }
        strSentencia = strSentencia + ' where com_codigo=' + '\'01\'';
        strSentencia = strSentencia + ' and art_codigo=' + '\'' + item.ART_CODIGO + '\'';
        strSentencia = strSentencia + ' and uni_codigo=' + '\'' + item.UNI_CODIGO + '\'';
        strSentencia = strSentencia + ' and artpre_codigo=' + '\'' + item.ARTPRE_CODIGO + '\'';
        
        this.actpreService.ejecutarConsulta4(bolACTCPAP, item.PRECIO, item.COSTOPROMEDIO, item.ART_CODIGO, item.UNI_CODIGO, item.ARTPRE_CODIGO).subscribe(data => {
        
        }, error => {
          sw = 1;
        });
        
      }
    }
    // Si no existio ningun problema
    if (sw === 0) {
      this.messageService.add({
        key: 'actprefp',
        severity: 'success',
        summary: 'Información',
        detail: 'Precios Actualizados correctamente'
      });
    }
  }
}
