//
// SACI WEB
// Rutina: Pto Vta Maestro
// Propietario: Victor Perez, Wilson Ramos
// Modificado por: VP, WR
// Fecha de creación: 2019
// Fecha de Modificación: 2019
//
import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {Maesubcategoria} from '../../interfaces/maesubcategoria';
import {MaePantalla} from '../../interfaces/maepantalla';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Component({
  selector: 'app-maestro',
  templateUrl: './maestro.component.html',
  styleUrls: ['./maestro.component.css'],
  preserveWhitespaces: false
})
export class MaestroComponent implements OnInit {
  public subcats: Maesubcategoria[];
  public pantallas: MaePantalla[];
  activeIndex: number;
  items1: MenuItem[];
  items2: MenuItem[];
  activeItem: MenuItem;
  display = false;
  index = 0;
  hideSubCategoria = true;
  hidePantalla = true;
  
  constructor(private init: NuevoComponentService) {
  }
  
  ngOnInit() {
    this.activeIndex = 0;
    this.items1 = [
      {label: 'Categoria', icon: 'fa fa-fw fa-bar-chart'},
      {label: 'Subcategoria', icon: 'fa fa-fw fa-calendar'},
      {label: 'Artículo', icon: 'fa fa-fw fa-book'},
    ];
    
    this.items2 = [
      {label: 'Categoria', icon: 'fa fa-fw fa-bar-chart'},
      {label: 'Subcategoria', icon: 'fa fa-fw fa-calendar'},
      {label: 'Artículo', icon: 'fa fa-fw fa-book'},
    ];
    
    this.activeItem = this.items2[0];
  }
  
  irPantallSubCat(subCategoria: Maesubcategoria[]) {
    this.subcats = subCategoria;
    this.hideSubCategoria = false;
    this.activeIndex = 1;
  }
  
  irPantallaProd(pant: MaePantalla[]) {
    this.pantallas = pant;
    this.activeIndex = 2;
    this.hidePantalla = false;
  }
  
  salir() {
    this.init.tabs.splice(this.init.tabindex, 1);
  }
}
