import { Injectable } from '@angular/core';
import {Venmaegrupos} from '../../ven/veninterfaces/venmaegrupos';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Observable} from 'rxjs';
import {Commaegrupo} from '../cominterfaces/commaegrupo';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class CommaegrupoService {

  get commaegrupo(): Commaegrupo[] {
    return this._commaegrupo;
  }

  set commaegrupo(value: Commaegrupo[]) {
    this._commaegrupo = value;
  }
  
  private _commaegrupo: Commaegrupo[];
  public way: string;
  constructor(private http: HttpClient,
              private confIniciales: ConfInicialesService,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario) {
    
    this.way = sessionStorage.getItem('way');
  }
  getGrupos(): Observable<any[]> {
    
    return this.http.post<any[]>(this.way + '/compras/grupos/swfzealtg92e0rt', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  insertarGrupo(grupo: Commaegrupo): Observable<any[]> {
 
    return this.http.post<any[]>(this.way + '/compras/grupos/gmeph76rvg7go26', {      
      elementos: {
        p_GRU_tipo: grupo.GRU_TIPO,
        p_GRU_codigo: grupo.GRU_CODIGO,
        p_GRU_nombre: grupo.GRU_NOMBRE,
        p_CON_codigo: grupo.CON_CODIGO,
        p_CON_codigodes: grupo.CON_CODIGODES,
        p_GRU_pordes: grupo.GRU_PORDES,
        p_CEN_codigo: grupo.CEN_CODIGO,
      },
    }, this.confIniciales.httpOptions());
  }
  actualizarGrupo(grupo: Commaegrupo, codigo): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/compras/grupos/xv1tf3oe3xavm67', {      
      elementos: {
        p_GRU_tipo: grupo.GRU_TIPO,
        p_GRU_codigoa: codigo,
        p_GRU_codigo: grupo.GRU_CODIGO,
        p_GRU_nombre: grupo.GRU_NOMBRE,
        p_CON_codigo: grupo.CON_CODIGO,
        p_CON_codigodes: grupo.CON_CODIGODES,
        p_GRU_pordes: grupo.GRU_PORDES,
        p_CEN_codigo: grupo.CEN_CODIGO,
      },
    }, this.confIniciales.httpOptions());
  }
  eliminarGrupo(grupo: Commaegrupo): Observable<any[]> {
   
    return this.http.post<any[]>(this.way + '/compras/grupos/3yik17m0jex4o75', {      
      elementos: {
        p_GRU_tipo: grupo.GRU_TIPO,
        p_GRU_codigo: grupo.GRU_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }

}
