//
// SACI WEB
// Rutina: Pago al contado
// Propietario: Victor Perez
// Modificado por: VP
// Fecha de creación: 16-12-2019
// Fecha de Modificación: 03-01-2020
// Actividades: Nuevo
//

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { PagocontadoService } from '../venservicios/pagocontado.service';
import { SaciTrnretencion } from '../../interfaces/sacitrnretencion';
import { VenEncfacZ } from '../veninterfaces/venencfacz';
import { CxcTrnpago } from '../../interfaces/cxctrnpago';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { DatePipe } from '@angular/common';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { CxcTrncobro } from '../../interfaces/cxctrncobro';
import { VenencfacService } from '../venservicios/venencfac.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { VenencptovtaService } from '../venservicios/venencptovta.service';
import { finalize } from 'rxjs/operators';
import {CxccarteraactivaService} from '../../cxc/cxcservicios/cxccarteraactiva.service'//CDPJ
//import { element } from 'protractor';

@Component({
  selector: 'app-pagocontado',
  templateUrl: './pagocontado.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class PagocontadoComponent implements OnInit, OnChanges {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  @Input() auxiliar: any;
  @Input() strNumFact: any;
  @Input() encfac: VenEncfacZ;
  @Input() modulo: string;//CDPJ
  @Input() ven_codigo: string;//CDPJ
  @Output() salir = new EventEmitter<boolean>();

  index: number;
  index1: number;//CDPJ
  spin: boolean;//CDPJ
  largo: string;
  largo2: string;

  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  barraBotones2: boolean;

  botonNuevoGrid: boolean;
  botonBorrarGrid: boolean;
  botonRegresarGrid: boolean;

  displayDialogAsiento: boolean;
  displayAsiContable: boolean;
  auxiliar1: number;
  asiento: any;

  trncobronrodoc: string;
  saldo: string;
  efectivo: string;
  cambio: string;

  pagoSeleccionado: CxcTrnpago;
  retenSeleccionado: SaciTrnretencion;

  hmpConfiguracion: any[];

  strFechaCobro: string;

  strTipoDoc = '';
  strFormaPago = '';

  claveDialog: boolean;
  claveAuth: string;
  lblAplicaRegMicroempresas: string;
  btnefectivo: boolean;//CDPJ
  btngridGn: boolean;//CDPJ
  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  defaultColDef;
  defaultColDefRet;
  columnDefsPago = [
    { // 0
      headerName: 'T', field: 'TRNPAGO_TIPO', cellEditor: 'cellPrueba', width: 50,
      editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true,
        }
      }
    }, { // 1
      headerName: 'Código', field: 'CAJ_CODIGO', cellEditor: 'cellPrueba', width: 80,
      editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true,
        }
      }
    }, { // 2
      headerName: 'Número', field: 'TRNPAGO_NUMERO', cellEditor: 'cellPrueba', width: 120,
      editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    }, { // 3
      headerName: 'Fecha', field: 'TRNPAGO_FECHA', cellEditor: 'cellPrueba', width: 110,
      editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: true,
          clave: false,
          tamanocelda: 10,
          tienecalculos: true
        }
      }
    }, { // 4
      headerName: 'Ban.', field: 'BANCLI_CODIGO', cellEditor: 'cellPrueba', width: 70,
      // editable: true,
      editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true,
        }
      }
    }, { // 5
      headerName: 'Nombre', field: 'BANCLI_NOMBRE', cellEditor: 'cellPrueba', width: 110,
      // editable: true,
      editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    }, { // 6
      headerName: 'Nro. Cta.', field: 'TRNPAGO_NROCTA', cellEditor: 'cellPrueba', width: 110,
      editable: p => {
        return p.data.TRNPAGO_TIPO !== 'DP' && this.btnefectivo === false;//CDPJ && this.btnefectivo === false
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    }, { // 7
      headerName: 'Importe', field: 'TRNPAGO_IMPORTE', cellEditor: 'cellPrueba', width: 90, cellStyle: { textAlign: 'right' },
      // editable: true,
      editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    }, { // 8
      headerName: 'CodSri', field: 'TRNPAGO_CODSRI', cellEditor: 'cellPrueba', width: 80,
      // editable: true,
      editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    }, { // 9
      headerName: 'Disp', field: 'TRNPAGO_TIPDIS', cellEditor: 'cellPrueba', width: 50,
      // editable: true,
      editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    }];

  columnDefsRetencion = [
    { // 0
      headerName: 'Código', field: 'RETENCION_CODIGO', cellEditor: 'cellPrueba',
      // editable: true,
      editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true,
        }
      }
    }, { // 1
      headerName: 'Descripción', field: 'TRNRETENCION_DESCRIPCION', cellEditor: 'cellPrueba', width: 200,
      // editable: true,
      editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    }, { // 2
      headerName: 'Número', field: 'TRNRETENCION_NRO', cellEditor: 'cellPrueba', width: 130,
      // editable: true,
      editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 15,//CDPJ
          tienecalculos: true
        }
      }
    }, { // 3
      headerName: 'Base', field: 'TRNRETENCION_BASE', cellEditor: 'cellPrueba', width: 110, cellStyle: { textAlign: 'right' },
      // editable: true,
      editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: true,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          tienecalculos: true
        }
      }
    }, { // 4
      headerName: '%', field: 'TRNRETENCION_PORCENTAJE', cellEditor: 'cellPrueba', width: 70, cellStyle: { textAlign: 'right' },
      editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    }, { // 5
      headerName: 'T.Retenido', field: 'TRNRETENCION_TOTALRETENIDO', cellEditor: 'cellPrueba', width: 110, cellStyle: { textAlign: 'right' },
      // editable: true,
      editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },//CDPJ
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    }];

  constructor(public pagconservice: PagocontadoService, private datePipe: DatePipe, private messageService: MessageService,
    private auditoriagral: AuditoriagralService, public encfacService: VenencfacService,
    private confirmationService: ConfirmationService, private confIniciales: ConfInicialesService,
    private errorService: ErroresBaseDatosService, private init: NuevoComponentService,
    public encptovtaService: VenencptovtaService, public cxcCarteraService:CxccarteraactivaService) {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultColDef = {
      editable: true,
      width: 80,
      objeto: 'pagcon'
    };

    this.defaultColDefRet = {
      editable: true,
      width: 80,
      objeto: 'retpagcon'
    };
  }

  ngOnInit() {
    this.index = 1;
    this.pagconservice.pagos = [];
    this.largo = '620';
    this.largo2 = '650';
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = true;
    this.botonRegresar = true;
    this.barraBotones2 = true;
    this.auditoriagral.obtenerConfiguracion1().subscribe((conf) => {
      this.hmpConfiguracion = conf;
      this.pagconservice.hmpConfiguracion = conf;
      this.pagconservice.CAJXVEN = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CAJXVEN').CFG_VALOR1;

    });

    this.claveAuth = '';
    this.claveDialog = false;

    this.displayDialogAsiento = false;
    this.displayAsiContable = false;
    this.asiento = '';
    this.auxiliar1 = 0;
    //document.getElementById('contorno').style.pointerEvents = 'all';//CDPJ
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const e in changes) {
      if (e === 'auxiliar' && this.strNumFact !== null) {
        this.pagconservice.modulo = this.modulo;//CDPJ
        this.pagconservice.ven_codigo = this.ven_codigo;//CDPJ
        this.lblAplicaRegMicroempresas = '';
        this.trncobronrodoc = '';
        this.pagconservice.pagos = [];
        this.pagconservice.reten = [];
        this.efectivo = '';
        this.cambio = '';
        this.index = 1;

        this.pagconservice.obtenerSaldoFactura(this.strNumFact).subscribe(saldo => {
          if (saldo != null) {
            if (saldo[0] !== undefined) {
              this.saldo = saldo[0].SALDO;
              this.saldo = Number(this.saldo).toFixed(this.confIniciales.getNumDecSist());
            }
          }
        });

        this.pagconservice.erFindTransfer(this.strNumFact).subscribe(formsricom => {

          if (formsricom != null) {
            if (formsricom[0] !== undefined) {
              console.log('porque');


              this.btnefectivo = true//CDPJ
              this.btngridGn = false;
              this.buttondha();//CDPJ
              this.aggrid.bloquearGrid();//CDPJ
              this.aggrid.bloquearGrid();//CDPJ
              this.botonBorrar = false;
              this.trncobronrodoc = formsricom[0].TRNCOBRO_NRODOC;
              this.consultarCancelacionAbono(this.trncobronrodoc);
            }
            else {
              console.log('1');

              document.getElementById('contorno').style.pointerEvents = 'all';//CDPJ
            }
          } else {
            console.log('2');
            this.btnefectivo = false;//CDPJ
            document.getElementById('contorno').style.pointerEvents = 'all';//CDPJ
          }
        });

        this.strFechaCobro = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
        this.pagconservice.erVenCliCodigo(this.encfac.CLI_CODIGO).subscribe(data => {
          if (data !== null) {
            if (data[0] !== undefined) {
              const resCli = data[0];
              if (resCli.CLI_CONREGMIC === 'S'
                || resCli.CLI_AGENTERET === 'S') {
                if (resCli.CLI_CONREGMIC === 'S'
                  && resCli.CLI_AGENTERET === 'S') {
                  this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas y agente de retención';
                } else {
                  if (resCli.CLI_CONREGMIC === 'S') {
                    this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas';
                  }
                  if (resCli.CLI_AGENTERET === 'S') {
                    this.lblAplicaRegMicroempresas = 'Cliente aplica agente de retención';
                  }
                }
              } else {
                this.lblAplicaRegMicroempresas = '';
              }
            }
          }
        });
      }
    }
  }

  gridsnull() {
    if (this.btnefectivo === true) {
      this.buttondha();
    } else {
      this.buttonmod();
    }

  }

  setFocus(event) {
    // let element: any;
    switch (event.target.id) {
      case 'EFECTIVO': {
        let obtenerTotalRetenido = 0;

        for (const reten of this.pagconservice.reten) {
          obtenerTotalRetenido = obtenerTotalRetenido + Number(reten.TRNRETENCION_TOTALRETENIDO);
        }

        let change = Number(this.efectivo) - Number(this.init.quitarComas(this.encfac.ENCFAC_TOTAL)) + obtenerTotalRetenido;
        if (change > 0) {
        } else {
          change = 0;
        }
        this.cambio = change.toFixed(2);
        this.efectivo = Number(this.efectivo).toFixed(2);

        const intINGRAPFAC = this.hmpConfiguracion.find(element1 => element1.CFG_CODIGO === 'INGRAPFAC').CFG_VALOR1;

        if (intINGRAPFAC === 0) {
          break;
        }

        if (this.pagconservice.pagos.length === 0) {
          if (Number(this.cambio) === 0) {
            if (Number(this.efectivo) > 0) {
              this.nuevaLineaDetalle(Number(this.efectivo), 'EF');
            } else {
              this.nuevaLineaDetalle(Number(this.init.quitarComas(this.encfac.ENCFAC_TOTAL)), '');
            }
          } else {
            this.nuevaLineaDetalle(Number(this.init.quitarComas(this.encfac.ENCFAC_TOTAL)), 'EF');
          }
        } else {
          if (Number(this.cambio) === 0) {
            const item = this.pagconservice.pagos[0];
            if (item.TRNPAGO_TIPO === 'EF') {
              item.TRNPAGO_IMPORTE = this.efectivo;
            }
          }
        }
        //this.aggrid.refreshaggrid(this.pagconservice.detfac[this.index], this.defaultColDef.objeto);

        break;
      }
      default: {
        break;
      }
    }

    /*if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }*/
  }

  nuevaLineaDetalle(dblEfectivoPag, strTipoPago) {
    if (dblEfectivoPag > 0) {
      this.nuevaLineaDetalle2(dblEfectivoPag, strTipoPago);
    } else {
      this.nuevaLineaDetalle2(Number(this.init.quitarComas(this.encfac.ENCFAC_TOTAL)), strTipoPago);
    }
  }

  nuevaLineaDetalle2(dblEfectivoPag, strTipoPago) {
    this.botonBorrarGrid = false;//CDPJ
    const item: CxcTrnpago = {
      TRNCOBRO_NRODOC: '',
      TRNPAGO_TIPO: '',
      CAJ_CODIGO: '',
      TRNPAGO_NUMERO: '',
      TRNPAGO_FECHA: this.datePipe.transform(new Date(), 'dd/MM/yyyy'),
      TRNPAGO_CONCEPTO: 'PAGO DIRECTO',
      TRNPAGO_IMPORTE: 0,
      COM_CODIGO: '01',
      CON_CODIGO: '',
      TRNCOBRO_NROCOMPROBANTE: '',
      CXP_TRNCOBRO_CODIGO: '',
      CLI_CODIGO: '',
      BANCLI_CODIGO: '',
      TRNPAGO_NROCTA: '',
      TRNPAGO_FLAG: '1',
      TRNPAGO_REFER: '',
      CABROLADMN_CODIGO: '',
      EMP_CODIGO: '',
      CABROLVEND_CODIGO: '',
      EMP_CODIGOVEND: '',
      TRNPAGO_CODSRI: '01',
      TRNCHEPOS_NUMDOC: '',
      TRNPAGO_TIPDIS: '',
      TRNPAGO_NUMEROANT: '',
      BANCLI_NOMBRE: '',
    };
    item.BANCLI_CODIGO = '';
    item.BANCLI_NOMBRE = '';
    this.pagconservice.erCodigoFormapago(strTipoPago).subscribe(er => {
      let strCaja = '';
      if (er !== null) {
        if (er[0] !== undefined) {
          strCaja = er[0].CAJ_CODIGO === null ? '' : er[0].CAJ_CODIGO;
        }
      }

      const intAsignarCajaVend = this.hmpConfiguracion.find(element1 => element1.CFG_CODIGO === 'CAJXVEN').CFG_VALOR1;
      //CDPJ
      const pvcajavende = this.hmpConfiguracion.find(element1 => element1.CFG_CODIGO === 'PVCAJADEPV').CFG_VALOR1;
      //CDPJ
      let vencodigo = this.encfac.VEN_CODIGO;
      if (this.modulo === 'ptovta') {
        console.log('ptovta', this.ven_codigo);
        vencodigo = this.ven_codigo;
      }
      this.pagconservice.erCodigoVendedor(vencodigo).subscribe(er2 => {
        console.log(er2)
        if (er2 !== null) {
          if (er2[0] !== undefined) {
            if (intAsignarCajaVend === 1 && this.modulo === 'ventas') {

              strCaja = er2[0].CAJ_CODIGO === null ? '' : er2[0].CAJ_CODIGO;//CDPJ
            }//CDPJ
            if (pvcajavende === 1 && this.modulo === 'ptovta') {
              console.log(this.modulo)
              strCaja = er2[0].CAJ_CODIGO === null ? '' : er2[0].CAJ_CODIGO;
            }//CDPJ
          }
        }
        item.CAJ_CODIGO = strCaja;
        item.CLI_CODIGO = this.encfac.CLI_CODIGO;
        item.COM_CODIGO = '01';
        this.pagconservice.erCodigobanCaja(item.CAJ_CODIGO).subscribe(er3 => {
          if (er3 !== null) {
            if (er3[0] !== undefined) {
              item.CON_CODIGO = er3[0].CON_CODIGO;
            }
          }
          item.CXP_TRNCOBRO_CODIGO = '';
          item.TRNCOBRO_NROCOMPROBANTE = '';
          item.TRNCOBRO_NRODOC = '';
          item.TRNPAGO_CONCEPTO = 'PAGO DIRECTO';
          item.TRNPAGO_FECHA = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
          item.TRNPAGO_FLAG = 1;
          item.TRNPAGO_CODSRI = '';
          item.TRNPAGO_CODSRI = '01';
          item.TRNPAGO_TIPDIS = '';

          let obtenerTotalPagado = 0;
          let obtenerTotalRetenido = 0;
          for (const pago of this.pagconservice.pagos) {
            obtenerTotalPagado = obtenerTotalPagado + Number(pago.TRNPAGO_IMPORTE);
          }
          for (const reten of this.pagconservice.reten) {
            obtenerTotalRetenido = obtenerTotalRetenido + Number(reten.TRNRETENCION_TOTALRETENIDO);
          }

          if (this.pagconservice.pagos.length === 0) {
            item.TRNPAGO_IMPORTE = dblEfectivoPag - obtenerTotalRetenido;
            item.TRNPAGO_IMPORTE = item.TRNPAGO_IMPORTE.toFixed(this.confIniciales.getNumDecSist());
          } else {
            let dblImporte = Number(this.init.quitarComas(this.encfac.ENCFAC_TOTAL)) - obtenerTotalPagado - obtenerTotalRetenido;

            dblImporte = Number(dblImporte.toFixed(this.confIniciales.getNumDecSist()));

            if (dblImporte > 0) {
              item.TRNPAGO_IMPORTE = dblImporte;
            } else if (dblImporte < 0) {
              item.TRNPAGO_IMPORTE = 0;
              this.messageService.add({
                key: 'pagcon',
                severity: 'warn',
                summary: 'Información',
                detail: 'El pago es mayor al valor de la factura.'
              });
            } else if (dblImporte === 0) {
              return;
            }
          }

          item.TRNPAGO_NROCTA = '';
          item.TRNPAGO_REFER = '';
          item.TRNPAGO_TIPO = strTipoPago;
          item.TRNPAGO_NUMERO = '';

          if (strTipoPago === 'EF') {
            const strNumFila = this.pagconservice.pagos.length + 1;
            const strFechaActual = this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
            item.TRNPAGO_NUMERO = strNumFila + '' + strFechaActual;
            item.TRNPAGO_CODSRI = '01';
          }

          const indi = this.pagconservice.pagos.indexOf(this.pagoSeleccionado);

          if (indi === -1) {
            item.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
            this.pagconservice.pagos.push(item);
            this.pagoSeleccionado = item;
            this.aggrid.refreshaggrid(this.pagconservice.pagos, this.defaultColDef.objeto);
            this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
              this.defaultColDef.objeto].setFocusedCell(0, 'TRNPAGO_TIPO');//CDPJ


          } else {
            item.CLAVE = (indi + 1) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
            this.pagconservice.pagos.splice(indi + 1, 0, item);
            this.pagoSeleccionado = item;
            this.aggrid.refreshaggridindex(this.pagconservice.pagos, this.defaultColDef.objeto, indi);
            this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
              this.defaultColDef.objeto].setFocusedCell(this.pagconservice.pagos.length - 1, 'TRNPAGO_TIPO');//CDPJ
          }
        });
      });
    });

  }

  botonesmodificar() {
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = false;
  }

  seleccionpago(params) {
    if (params === 'nuevo') {
      this.nuevo();
      return;
    }

    if (params === 'cancelar') {
      this.cancelar();
      return;
    }

    if (params === 'eliminar') {
      this.eliminar();
      return;
    }


    if (params.object === undefined) {
      return;
    }

    this.pagoSeleccionado = params.object;
    if (this.pagoSeleccionado.CLAVE === undefined) {
      this.pagoSeleccionado.CLAVE = this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
    }
    this.init.auxsalto = false;
  }

  seleccionretencion(params) {
    if (params === 'nuevo') {
      this.nuevo();
      return;
    }

    if (params === 'cancelar') {
      this.cancelar();
      return;
    }

    if (params === 'eliminar') {
      this.eliminar();
      return;
    }

    if (params.object === undefined) {
      return;
    }

    this.retenSeleccionado = params.object;
    if (this.retenSeleccionado.CLAVE === undefined) {
      this.retenSeleccionado.CLAVE = this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
    }
    this.init.auxsalto = false;
  }

  manejarSenales(valor) {
    if (valor === 'Guardar') {
      this.guardarRegistro();
    }

    if (valor === 'Borrar') {
      this.claveAuth = '';
      this.claveDialog = true;
    }

    if (valor === 'Cancelar') {
      this.cancelarPago();
    }

    if (valor === 'AsientoVer') {
      this.verAsiento();
    }

    if (valor === 'Salir') {
      this.salir.emit(true);
    }

  }

  cancelarPago() {
    this.pagconservice.pagos = [];
    this.pagconservice.reten = [];

    if (this.trncobronrodoc.length > 0) {
      this.consultarCancelacionAbono(this.trncobronrodoc);
    } else {
      this.limpiarCancelacion();
    }
  }

  consultarCancelacionAbono(strNumComprobante) {
    this.pagconservice.consultarTrnCobro('T',
      'TRNCOBRO_NRODOC = \'' + strNumComprobante
      + '\' AND C.COM_CODIGO = \'01\'', '').subscribe(canc => {

        if (canc != null) {
          if (canc[0] !== undefined) {
            // document.getElementById('contorno').style.pointerEvents = 'none';//CDPJ
            this.btnefectivo = true//CDPJ
            this.aggrid.bloquearGrid();//CDPJ
            this.buttondha();//CDPJ
            this.cambio = canc[0].TRNCOBRO_CAMBIO.toFixed(this.confIniciales.getNumDecSist());
            this.efectivo = canc[0].TRNCOBRO_EFECTIVO.toFixed(this.confIniciales.getNumDecSist());
            this.pagconservice.consultarFormaPago(strNumComprobante).subscribe((res) => {
              this.buttondha();//CDPJ
              this.pagconservice.pagos = res;
              this.pagconservice.pagos.map((p, index) => {
                p.TRNPAGO_IMPORTE = p.TRNPAGO_IMPORTE.toFixed(this.confIniciales.getNumDecSist());

                if (p.TRNPAGO_FECHA !== null) {
                  p.TRNPAGO_FECHA = this.datePipe.transform(p.TRNPAGO_FECHA, 'dd/MM/yyyy');
                }



                if (p.TRNPAGO_TIPO === 'CH') {
                  this.pagconservice.erCodigoBanCliente(p.BANCLI_CODIGO).subscribe(ban => {
                    if (ban != null) {
                      if (ban[0] !== undefined) {
                        this.pagconservice.pagos[index].BANCLI_NOMBRE = ban[0].BANCLI_NOMBRE;
                        this.aggrid.refreshaggrid(this.pagconservice.pagos, this.defaultColDef.objeto);
                      }
                    }
                  });
                }
                if (p.TRNPAGO_TIPO === 'TA') {
                  this.pagconservice.erTarjetaCodigo(p.BANCLI_CODIGO).subscribe(tar => {
                    if (tar != null) {
                      if (tar[0] !== undefined) {
                        this.pagconservice.pagos[index].BANCLI_NOMBRE = tar[0].TAR_DESCRIPCION;
                        this.aggrid.refreshaggrid(this.pagconservice.pagos, this.defaultColDef.objeto);
                      }
                    }
                  });
                }
                //CDPJ
                if (p.TRNPAGO_TIPO === 'DP') {
                  p.CAJ_CODIGO=''
                  this.pagconservice.erBannombreDP(p.BANCLI_CODIGO).subscribe(tar => {
                    if (tar != null) {
                      if (tar[0] !== undefined) {
                        this.pagconservice.pagos[index].BANCLI_NOMBRE = tar[0].BAN_BANCO;
                        this.aggrid.refreshaggrid(this.pagconservice.pagos, this.defaultColDef.objeto);
                      }
                    }
                  });
                }
                //CDPJ
              });
            });
            this.pagconservice.consultarTrnRetencion(strNumComprobante).subscribe((res) => {
              this.pagconservice.reten = res;
              this.pagconservice.reten.map((p, index) => {
                p.TRNRETENCION_BASE = p.TRNRETENCION_BASE.toFixed(this.confIniciales.getNumDecSist());
                p.TRNRETENCION_TOTALRETENIDO = p.TRNRETENCION_TOTALRETENIDO.toFixed(this.confIniciales.getNumDecSist());
                p.TRNRETENCION_PORCENTAJE = p.TRNRETENCION_PORCENTAJE.toFixed(this.confIniciales.getNumDecSist());
              });
            });
          }
        }
      });
  }

  nuevo() {
    this.botonNuevoGrid = true;
    this.botonGuardar = false;
    this.botonBorrarGrid = false;//CDPJ
    if (this.index === 1) {
      let saldodetalle = 0;
      let obtenerTotalPagado = 0;
      let obtenerTotalRetenido = 0;
      for (const pago of this.pagconservice.pagos) {
        obtenerTotalPagado = obtenerTotalPagado + Number(pago.TRNPAGO_IMPORTE);
      }
      for (const reten of this.pagconservice.reten) {
        obtenerTotalRetenido = obtenerTotalRetenido + Number(reten.TRNRETENCION_TOTALRETENIDO);
      }

      saldodetalle = Number(this.saldo) - obtenerTotalPagado - obtenerTotalRetenido;
      saldodetalle = Number(saldodetalle.toFixed(this.confIniciales.getNumDecSist()));
      const pagoNuevo: CxcTrnpago = {
        TRNCOBRO_NRODOC: '',
        TRNPAGO_TIPO: '',
        CAJ_CODIGO: '',
        TRNPAGO_NUMERO: '',
        TRNPAGO_FECHA: this.datePipe.transform(new Date(), 'dd/MM/yyyy'),
        TRNPAGO_CONCEPTO: 'PAGO DIRECTO',
        TRNPAGO_IMPORTE: saldodetalle,
        COM_CODIGO: '01',
        CON_CODIGO: '',
        TRNCOBRO_NROCOMPROBANTE: '',
        CXP_TRNCOBRO_CODIGO: '',
        CLI_CODIGO: '',
        BANCLI_CODIGO: '',
        TRNPAGO_NROCTA: '',
        TRNPAGO_FLAG: '1',
        TRNPAGO_REFER: '',
        CABROLADMN_CODIGO: '',
        EMP_CODIGO: '',
        CABROLVEND_CODIGO: '',
        EMP_CODIGOVEND: '',
        TRNPAGO_CODSRI: '01',
        TRNCHEPOS_NUMDOC: '',
        TRNPAGO_TIPDIS: '',
        TRNPAGO_NUMEROANT: '',
        BANCLI_NOMBRE: '',
      };

      if (saldodetalle > 0) {
        pagoNuevo.TRNPAGO_IMPORTE = saldodetalle.toFixed(this.confIniciales.getNumDecSist());
      }

      if (saldodetalle < 0) {
        this.messageService.add({
          key: 'pagcon',
          severity: 'warn',
          summary: 'Información',
          detail: 'El pago es mayor al valor de la factura.'
        });
        return;
      }

      if (saldodetalle === 0) {
        this.messageService.add({
          key: 'pagcon',
          severity: 'warn',
          summary: 'Información',
          detail: 'El saldo es igual a cero.'
        });
        return;
      }

      const indi = this.pagconservice.pagos.indexOf(this.pagoSeleccionado);

      if (indi === -1) {
        pagoNuevo.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.pagconservice.pagos.push(pagoNuevo);
        this.pagoSeleccionado = pagoNuevo;
        this.aggrid.refreshaggrid(this.pagconservice.pagos, this.defaultColDef.objeto);

        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDef.objeto].setFocusedCell(0, 'TRNPAGO_TIPO');//CDPJ
      } else {
        pagoNuevo.CLAVE = (indi + 1) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.pagconservice.pagos.splice(indi + 1, 0, pagoNuevo);
        this.pagoSeleccionado = pagoNuevo;
        this.aggrid.refreshaggridindex(this.pagconservice.pagos, this.defaultColDef.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDef.objeto].setFocusedCell(this.pagconservice.pagos.length - 1, 'TRNPAGO_TIPO');//CDPJ
      }
    }

    if (this.index === 0) {
      const retenNuevo: SaciTrnretencion = {
        TRNCOBRO_NRODOC: '',
        TRNRETENCION_NRO: '',
        RETENCION_CODIGO: '',
        COM_CODIGO: '01',
        TRNRETENCION_DESCRIPCION: '',
        TRNRETENCION_BASE: '',
        TRNRETENCION_PORCENTAJE: '',
        TRNRETENCION_TOTALRETENIDO: '',
        TRNRETENCION_ORIGEN: '',
        PRO_CODIGO: '',
        CON_CODIGO: '',
        CLI_CODIGO: '',
        CEN_CODIGO: '',
        TRNRETENCION_REFERENCIA: '',
        TRNRETENCION_FLAG: '',
        TRNRETENCION_SERIE: '',
        TRNRETENCION_AUTORIZACION: '',
        TRNRETENCION_BASE0: '',
        TRNRETENCION_BASEG: '',
        TRNRETENCION_BASENG: '',
        TRNCOBRO_NROCOMPROBANTE: '',
        COM_CODIGO_CXC: '',
        TRNRETENCION_ESTADO: '',
        TRNRETENCION_FECHAEMISION: '',
        TRNRETENCION_FECHAPAGODIV: '',
        TRNRETENCION_IMPRENPAGADO: '',
        TRNRETENCION_ANIOUTIL: '',
        TRNRETENCION_TIPORET: '',
      };

      const indi = this.pagconservice.reten.indexOf(this.retenSeleccionado);
      if (indi === -1) {
        retenNuevo.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.pagconservice.reten.push(retenNuevo);
        this.aggrid.refreshaggrid(this.pagconservice.reten, this.defaultColDefRet.objeto);
      } else {
        retenNuevo.CLAVE = (indi + 1) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.pagconservice.reten.splice(indi + 1, 0, retenNuevo);
        this.aggrid.refreshaggridindex(this.pagconservice.reten, this.defaultColDefRet.objeto, indi);
      }
    }
  }

  eliminar() {
    if (this.index === 1) {
      if (this.pagconservice.pagos)
        this.pagconservice.pagos.splice(this.pagconservice.pagos.findIndex(
          pago => pago.CLAVE === this.pagoSeleccionado.CLAVE),
          1);
      this.aggrid.refreshaggrid(this.pagconservice.pagos, this.defaultColDef.objeto);
    }

    if (this.index === 0) {
      this.pagconservice.reten.splice(this.pagconservice.reten.findIndex(
        ret => ret.CLAVE === this.retenSeleccionado.CLAVE),
        1);
      this.aggrid.refreshaggrid(this.pagconservice.reten, this.defaultColDefRet.objeto);
    }
    //this.buttonmod();//CDPJ---
    this.botonBorrarGrid = false;//CDPJ
    this.botonNuevoGrid = false;//CDPJ
  }

  cancelar() {
    if (this.index === 1) {
      for (let i = this.pagconservice.pagos.length; i > 0; i--) {
        if (this.pagconservice.pagos[i - 1].TRNPAGO_TIPO === '' && this.pagconservice.pagos[i - 1].TRNPAGO_IMPORTE <= 0) {
          this.pagconservice.pagos.splice(i - 1,
            1);
        }
      }
      this.aggrid.refreshaggrid(this.pagconservice.pagos, this.defaultColDef.objeto);
    }

    if (this.index === 0) {
      for (let i = this.pagconservice.reten.length; i > 0; i--) {
        if (this.pagconservice.reten[i - 1].RETENCION_CODIGO === '') {
          this.pagconservice.reten.splice(i - 1,
            1);
        }
      }
      this.aggrid.refreshaggrid(this.pagconservice.reten, this.defaultColDefRet.objeto);
    }
    this.buttonmod();
  }

  buttonmod() {
    this.botonNuevoGrid = false;
    this.botonBorrarGrid = true;
    this.botonRegresarGrid = false;
  }
  //CDPJ
  buttondha() {
    this.botonNuevoGrid = true;
    this.botonBorrarGrid = true;
    this.botonRegresarGrid = true;
  }
  isEditFP(params) {
    if (this.btnefectivo === false) {
      return true;
    } else {
      return false;
    }


  }
  //CDPJ
  cambiosbotones(event) {
    if (event === true) {
      if (this.btnefectivo === false) {
        this.botonBorrarGrid = false;
      }
    }
  }

  guardarRegistro() {

    // VALIDACIONES DE LA FORMA DE PAGO
    //CDPJ
    this.spin = true;
    this.botonGuardar = true;
    this.botonBorrar = true;
    //CDPJ
    if (this.pagconservice.pagos.length === 0) {
      this.messageService.add({
        key: 'pagcon',
        severity: 'warn',
        summary: 'Información',
        detail: 'Ingrese la forma de pago.'
      });
      //CDPJ
      this.spin = false;
      this.botonGuardar = false;
      //CDPJ

      return;
    } else {
      this.eliminarUltimaLineaFP();
      this.verificarTipoCliente();
    }

  }

  finalmenteGuardar() {
    let obtenerTotalPagado = 0;
    let obtenerTotalRetenido = 0;
    for (const pago of this.pagconservice.pagos) {
      obtenerTotalPagado = obtenerTotalPagado + Number(pago.TRNPAGO_IMPORTE);
    }
    for (const reten of this.pagconservice.reten) {
      obtenerTotalRetenido = obtenerTotalRetenido + Number(reten.TRNRETENCION_TOTALRETENIDO);
    }
    const dblTotalCobro = obtenerTotalPagado;
    const dblTotalRet = obtenerTotalRetenido;
    let dblTotalPagoFact = dblTotalCobro + dblTotalRet;
    dblTotalPagoFact = Number(dblTotalPagoFact.toFixed(this.confIniciales.getNumDecSist()));

    const intCfgAbonoPagoDir = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ABOPD').CFG_VALOR1;
    const intCfgAutFacVen = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'AUTFCVEN').CFG_VALOR1;

    if (intCfgAbonoPagoDir === 0) {
      if (Number(this.init.quitarComas(this.encfac.ENCFAC_TOTAL)) < dblTotalPagoFact) {
        this.messageService.add({
          key: 'pagcon',
          severity: 'warn',
          summary: 'Error',
          detail: 'El total del pago es mayor al total de la factura.'
        });
        //CDPJ
        this.spin = false;
        this.botonGuardar = false;
        //CDPJ
        return;
      }

      if (Number(this.init.quitarComas(this.encfac.ENCFAC_TOTAL)) > dblTotalPagoFact) {
        this.messageService.add({
          key: 'pagcon',
          severity: 'warn',
          summary: 'Error',
          detail: 'El total del pago es menor al total de la factura.'
        });
        //CDPJ
        this.spin = false;
        this.botonGuardar = false;
        //CDPJ
        return;
      }

      if (intCfgAutFacVen === 1 && this.encfac.CLI_LIMCREDIT > 0) {
        if (Number(this.init.quitarComas(this.encfac.ENCFAC_TOTAL)) > dblTotalPagoFact) {
          this.messageService.add({
            key: 'pagcon',
            severity: 'warn',
            summary: 'Error',
            detail: 'Tiene que ser cancelado la totalidad de la factura.'
          });
          //CDPJ
          this.spin = false;
          this.botonGuardar = false;
          //CDPJ
          return;
        }
      }
    }

    if (dblTotalPagoFact <= 0) {
      this.messageService.add({
        key: 'pagcon',
        severity: 'warn',
        summary: 'Error',
        detail: 'No existe ningún pago'
      });
      //CDPJ
      this.spin = false;
      this.botonGuardar = false;
      //CDPJ
      return;
    }

    this.guardarCancelacionRetencion(dblTotalPagoFact);
  }

  guardarCancelacionRetencion(dblTotalPagoFact) {

    // identificar si es una cancelación o un abono
    if (Number(this.init.quitarComas(this.encfac.ENCFAC_TOTAL)) === dblTotalPagoFact) {
      this.strTipoDoc = 'CA';
      this.strFormaPago = 'T';
    } else {
      this.strTipoDoc = 'AB';
      this.strFormaPago = 'C';
    }

    if (this.trncobronrodoc === undefined) {
      this.trncobronrodoc = '';
    }
    if (this.trncobronrodoc.length === 0) { // INSERTAR
      this.insertarCancelacionAbono(dblTotalPagoFact, this.strTipoDoc);
    } else { // ACTUALIZAR
    }

  }

  async insertarCancelacionAbono(dblTotalPagoFact, strTipoDoc) {

    let trncobro: CxcTrncobro;
    trncobro = {
      TRNCOBRO_NRODOC: '',
      TRNCOBRO_NROCOMPROBANTE: '',
      TRNCOBRO_TIPODOC: '',
      TRNCOBRO_FECHATRN: '',
      TRNCOBRO_IMPORTE: '',
      TRNCOBRO_CONCEPTO: '',
      TRNCOBRO_FECHAVENCE: '',
      CLI_CODIGO: '',
      APLORG_CODIGO: '',
      TRNCOBRO_REFERENCIAEXTERNA: '',
      VEN_CODIGO: '',
      COM_CODIGO: '',
      ASI_NRO: '',
      USU_IDENTIFICACION: '',
      TRNCOBRO_FLAG: '',
      BAN_CODIGO: '',
      TRNCOBRO_EFECTIVO: '',
      TRNCOBRO_CAMBIO: '',
      CON_CODIGO: '',
      TRNCOBRO_ANULADO: '',
      TRNCOBRO_SALDOINI: '',
      TRNCOBRO_NRODOC_CXP: '',
      COM_CODIGO_CXP: '',
      BOD_CODIGO: '',
      TRNCOBRO_CUADRE: '',
      TRNCOBRO_IMPRESO: '',
      CABROLADMN_CODIGO: '',
      EMP_CODIGO: '',
      CABROLVEND_CODIGO: '',
      EMP_CODIGOVEND: '',
      TRNCOBROND_FECHAAUT_FE: '',
      TRNCOBROND_ESTADO_FE: '',
      TRNCOBROND_AUTORIZACION_FE: '',
      TRNCOBROND_CLAVEACCESO_FE: '',
      TRNCOBROND_NUMFACTELEC: '',
      TRNCOBRO_LOCALIZACIONXMLRET: '',
      TRNCOBRO_PATHXMLRET: '',
      COM_TIPOAMBFACTELEC: '',
      TRNCOBRO_PATHXMLNOAUTO_FE: '',
      TRNCOBRO_PATHPDF_FE: '',
      CUOTAFAC: '',
      TRNCOBRO_NRODOC_2: ''
    };
    trncobro.APLORG_CODIGO = 'FAC';
    trncobro.ASI_NRO = '';
    trncobro.BAN_CODIGO = '';
    trncobro.CLI_CODIGO = this.encfac.CLI_CODIGO;
    trncobro.COM_CODIGO = '01';
    trncobro.TRNCOBRO_CONCEPTO = 'PAGO DIRECTO FAC. ' + this.encfac.ENCFAC_NUMERO;
    trncobro.TRNCOBRO_FECHATRN = this.datePipe.transform(this.encfac.ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
    trncobro.TRNCOBRO_FECHAVENCE = '';
    trncobro.TRNCOBRO_FLAG = 1;
    trncobro.TRNCOBRO_IMPORTE = dblTotalPagoFact;
    trncobro.TRNCOBRO_NROCOMPROBANTE = '';
    trncobro.TRNCOBRO_NRODOC = '';
    trncobro.TRNCOBRO_REFERENCIAEXTERNA = '';
    trncobro.TRNCOBRO_TIPODOC = strTipoDoc;
    trncobro.VEN_CODIGO = this.encfac.VEN_CODIGO;
    if (this.cambio !== undefined) {
      trncobro.TRNCOBRO_CAMBIO = Number(this.cambio);
    } else {
      trncobro.TRNCOBRO_CAMBIO = 0;
    }
    if (this.efectivo !== undefined) {
      trncobro.TRNCOBRO_EFECTIVO = Number(this.efectivo);
    } else {
      trncobro.TRNCOBRO_EFECTIVO = 0;
    }
    trncobro.TRNCOBRO_SALDOINI = 0;
    try {
      const ins = await this.pagconservice.insertarTrnCobro(trncobro).toPromise()
      for (const i of ins) {
        trncobro.TRNCOBRO_NRODOC = this.encfac.ENCFAC_NUMERO;
        trncobro.CUOTAFAC = 'F';
        this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', i.TRNCOBRO_NRODOC + '/' + i.TRNCOBRO_NROCOMPROBANTE,
          'I', '', '01', '', '', '', '').subscribe();
        await this.insertarTrnDoc(i, trncobro)

      }
    } catch (error1) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
      this.messageService.add({
        key: 'pagcon',
        severity: 'error',
        summary: 'Error al insertar el cobro',
        detail: mensaje
      });
      this.spin = false;
      console.log(error1)
    }

    // try {
    //   const ins= await this.pagconservice.insertarTrnCobro(trncobro).toPromise()
    //   for (const i of ins) { 
    //     trncobro.TRNCOBRO_NRODOC = this.encfac.ENCFAC_NUMERO;
    //     trncobro.CUOTAFAC = 'F';
    //     this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', i.TRNCOBRO_NRODOC + '/' + i.TRNCOBRO_NROCOMPROBANTE,
    //       'I', '', '01', '', '', '', '').subscribe();
  
    //     this.pagconservice.insertarTrnDocumento(i.TRNCOBRO_NRODOC, i.TRNCOBRO_NROCOMPROBANTE, trncobro).
    //     pipe(finalize(async () => { await this.generarasientopipe() })).subscribe(async res => {
    //       this.trncobronrodoc = i.TRNCOBRO_NRODOC;
    //       this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', i.TRNCOBRO_NRODOC + '/' +
    //         this.encfac.ENCFAC_NUMERO, 'I',
    //         '', '01', '', '', '', '').subscribe();
    //       for (const ret of this.pagconservice.reten) {
    //         ret.TRNCOBRO_NRODOC = i.TRNCOBRO_NRODOC;
    //         ret.TRNCOBRO_NROCOMPROBANTE = i.TRNCOBRO_NROCOMPROBANTE;
    //         ret.ENCFAC_NUMERO = this.encfac.ENCFAC_NUMERO;
    //         ret.TRNRETENCION_BASE = Number(ret.TRNRETENCION_BASE);
    //         ret.TRNRETENCION_TOTALRETENIDO = Number(ret.TRNRETENCION_TOTALRETENIDO);
    //         ret.TRNRETENCION_PORCENTAJE = Number(ret.TRNRETENCION_PORCENTAJE);
    //         ret.CLI_CODIGO = this.encfac.CLI_CODIGO;
    //         this.pagconservice.insertarTrnRetencion(ret).subscribe(() => {
    //           this.auditoriagral.registrarAuditoria('SACI_TRNRETENCION', i.TRNCOBRO_NRODOC + '/' + ret.TRNRETENCION_NRO,
    //             'I', '', '01', '', '', '', '').subscribe();
    //         });
    //       }
    //       await Promise.all(this.pagconservice.pagos.map(async pago =>{
    //         if (pago.CAJ_CODIGO !== null && pago.TRNPAGO_TIPO !== 'DP') {
    //         } else {
    //           pago.CAJ_CODIGO = pago.BANCLI_CODIGO;
    //         }
  
    //         if (pago.TRNCOBRO_NRODOC.length > 0) {
  
    //         } else {
    //           pago.TRNCOBRO_NRODOC = i.TRNCOBRO_NRODOC;
    //         }
    //         this.botonGuardar = true;
    //         this.botonBorrar = false;
    //         this.botonRegresar = true;
    //         pago.TRNPAGO_IMPORTE = Number(pago.TRNPAGO_IMPORTE);
    //         pago.CLI_CODIGO = this.encfac.CLI_CODIGO;
    //         if (pago.TRNPAGO_FECHA.indexOf('-') > 0) {
    //           pago.TRNPAGO_FECHA = this.auditoriagral.transformarFecha(pago.TRNPAGO_FECHA);
    //         }
    //         try {
    //           await this.pagconservice.insertarFormaPagoProm(i.TRNCOBRO_NROCOMPROBANTE, this.encfac.ENCFAC_NUMERO, pago)
    //           this.auditoriagral.registrarAuditoria('CXC_TRNPAGO', i.TRNCOBRO_NRODOC + '/' + pago.TRNPAGO_NUMERO,
    //             'I', '', '01', '', '', '', '').subscribe();
    //         } catch (error1) {
    //           let mensaje: string;
    //           mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
    //           this.messageService.add({
    //             key: 'pagcon',
    //             severity: 'error',
    //             summary: 'Forma de Pago',
    //             detail: mensaje
    //           });
    //           this.spin = false;
    //           //this.botonGuardar = false;
    //           this.reversarCancelacion(true);
    //         }
            
    //       }))
          
    //     });
    //   }
    // } catch (error1) {
    //     let mensaje: string;
    //           mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
    //           this.messageService.add({
    //             key: 'pagcon',
    //             severity: 'error',
    //             summary: 'Forma de Pago',
    //             detail: mensaje
    //           });
    //           this.spin = false;
    // }
    // this.pagconservice.insertarTrnCobro(trncobro).subscribe(ins => {
    //   for (const i of ins) {
    //     trncobro.TRNCOBRO_NRODOC = this.encfac.ENCFAC_NUMERO;
    //     trncobro.CUOTAFAC = 'F';
    //     this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', i.TRNCOBRO_NRODOC + '/' + i.TRNCOBRO_NROCOMPROBANTE,
    //       'I', '', '01', '', '', '', '').subscribe();
    //     this.pagconservice.insertarTrnDocumento(i.TRNCOBRO_NRODOC, i.TRNCOBRO_NROCOMPROBANTE, trncobro).pipe(finalize(async () => { await this.generarasientopipe() })).subscribe(async res => {
    //       this.trncobronrodoc = i.TRNCOBRO_NRODOC;
    //       this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', i.TRNCOBRO_NRODOC + '/' +
    //         this.encfac.ENCFAC_NUMERO, 'I',
    //         '', '01', '', '', '', '').subscribe();
    //       for (const ret of this.pagconservice.reten) {
    //         ret.TRNCOBRO_NRODOC = i.TRNCOBRO_NRODOC;
    //         ret.TRNCOBRO_NROCOMPROBANTE = i.TRNCOBRO_NROCOMPROBANTE;
    //         ret.ENCFAC_NUMERO = this.encfac.ENCFAC_NUMERO;
    //         ret.TRNRETENCION_BASE = Number(ret.TRNRETENCION_BASE);
    //         ret.TRNRETENCION_TOTALRETENIDO = Number(ret.TRNRETENCION_TOTALRETENIDO);
    //         ret.TRNRETENCION_PORCENTAJE = Number(ret.TRNRETENCION_PORCENTAJE);
    //         ret.CLI_CODIGO = this.encfac.CLI_CODIGO;
    //         this.pagconservice.insertarTrnRetencion(ret).subscribe(() => {
    //           this.auditoriagral.registrarAuditoria('SACI_TRNRETENCION', i.TRNCOBRO_NRODOC + '/' + ret.TRNRETENCION_NRO,
    //             'I', '', '01', '', '', '', '').subscribe();
    //         });
    //       }
    //       await Promise.all(this.pagconservice.pagos.map(async pago =>{
    //         if (pago.CAJ_CODIGO !== null && pago.TRNPAGO_TIPO !== 'DP') {
    //         } else {
    //           pago.CAJ_CODIGO = pago.BANCLI_CODIGO;
    //         }

    //         if (pago.TRNCOBRO_NRODOC.length > 0) {

    //         } else {
    //           pago.TRNCOBRO_NRODOC = i.TRNCOBRO_NRODOC;
    //         }
    //         this.botonGuardar = true;
    //         this.botonBorrar = false;
    //         this.botonRegresar = true;
    //         pago.TRNPAGO_IMPORTE = Number(pago.TRNPAGO_IMPORTE);
    //         pago.CLI_CODIGO = this.encfac.CLI_CODIGO;
    //         if (pago.TRNPAGO_FECHA.indexOf('-') > 0) {
    //           pago.TRNPAGO_FECHA = this.auditoriagral.transformarFecha(pago.TRNPAGO_FECHA);
    //         }
    //         try {
    //           await this.pagconservice.insertarFormaPagoProm(i.TRNCOBRO_NROCOMPROBANTE, this.encfac.ENCFAC_NUMERO, pago)
    //           this.auditoriagral.registrarAuditoria('CXC_TRNPAGO', i.TRNCOBRO_NRODOC + '/' + pago.TRNPAGO_NUMERO,
    //             'I', '', '01', '', '', '', '').subscribe();
    //         } catch (error1) {
    //           let mensaje: string;
    //           mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
    //           this.messageService.add({
    //             key: 'pagcon',
    //             severity: 'error',
    //             summary: 'Forma de Pago',
    //             detail: mensaje
    //           });
    //           this.spin = false;
    //           //this.botonGuardar = false;
    //           this.reversarCancelacion(true);
    //         }
    //         // this.pagconservice.insertarFormaPago(i.TRNCOBRO_NROCOMPROBANTE, this.encfac.ENCFAC_NUMERO, pago).subscribe(r => {
    //         //   this.auditoriagral.registrarAuditoria('CXC_TRNPAGO', i.TRNCOBRO_NRODOC + '/' + pago.TRNPAGO_NUMERO,
    //         //     'I', '', '01', '', '', '', '').subscribe();
    //         //   // this.encfacService.generarAsientoContableSinCxc(this.encfac.ENCFAC_NUMERO, 'CI',
    //         //   //   this.trncobronrodoc).subscribe(asi => {
    //         //   //     this.encfacService.erEncontrarFactura(this.encfac.ENCFAC_NUMERO).subscribe(cen => {
    //         //   //       if (cen[0] !== undefined) {
    //         //   //         this.encfac.ASI_NRO = cen[0].ASI_NRO;
    //         //   //         this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
    //         //   //           this.encfac.ASI_NRO + '/VEN', 'I',
    //         //   //           '', '01', '', '', '', '').subscribe(() => {
    //         //   //           });
    //         //   //         const INGRAPFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INGRAPFAC').CFG_VALOR1;
    //         //   //         if (INGRAPFAC === 0) {// ingreso rapido de la factura
    //         //   //           this.messageService.add({
    //         //   //             key: 'pagcon',
    //         //   //             severity: 'success',
    //         //   //             summary: 'Información',
    //         //   //             detail: 'El Asiento Contable se generó correctamente'
    //         //   //           });
    //         //   //           //CDPJ
    //         //   //           this.spin=false;
    //         //   //           //CDPJ
    //         //   //         }
    //         //   //         this.messageService.add({
    //         //   //           key: 'pagcon',
    //         //   //           severity: 'success',
    //         //   //           summary: 'Información',
    //         //   //           detail: 'La forma de pago se guardo correctamente'
    //         //   //         });
    //         //   //         //CDPJ
    //         //   //         this.spin=false;
    //         //   //         //CDPJ
    //         //   //         this.cancelarPago();//CDPJ
    //         //   //         this.botonNuevoGrid = true;//CDPJ----
    //         //   //       }
    //         //   //     });
    //         //   //   }, error1 => {
    //         //   //     let mensaje: string;
    //         //   //     mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
    //         //   //     this.messageService.add({
    //         //   //       key: 'pagcon',
    //         //   //       severity: 'error',
    //         //   //       summary: 'Asiento Contable',
    //         //   //       detail: mensaje
    //         //   //     });
    //         //   //     this.spin=false;
    //         //   //     //this.botonGuardar = false;
    //         //   //     this.reversarCancelacion(true);
    //         //   //   });
    //         //   // this.pagconservice.actualizaEstadoFactura(this.strNumFact, this.encfac.CLI_CODIGO).subscribe(() => {
    //         //   //   this.pagconservice.actualizaFormaPagoFact(this.strNumFact, this.strFormaPago).subscribe(() => {
    //         //   //     this.pagconservice.obtenerSaldoFactura(this.strNumFact).subscribe(saldo => {
    //         //   //       if (saldo != null) {
    //         //   //         if (saldo[0] !== undefined) {
    //         //   //           this.saldo = saldo[0].SALDO;
    //         //   //           this.saldo = Number(this.saldo).toFixed(this.confIniciales.getNumDecSist());
    //         //   //         }
    //         //   //       }
    //         //   //     });
    //         //   //   });
    //         //   // });
    //         // }, error1 => {
    //         //   let mensaje: string;
    //         //   mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
    //         //   this.messageService.add({
    //         //     key: 'pagcon',
    //         //     severity: 'error',
    //         //     summary: 'Forma de Pago',
    //         //     detail: mensaje
    //         //   });
    //         //   this.spin = false;
    //         //   //this.botonGuardar = false;
    //         //   this.reversarCancelacion(true);
    //         // });
    //       }))
    //       // for (const pago of this.pagconservice.pagos) {
    //       //   if (pago.CAJ_CODIGO !== null && pago.TRNPAGO_TIPO !== 'DP') {
    //       //   } else {
    //       //     pago.CAJ_CODIGO = pago.BANCLI_CODIGO;
    //       //   }

    //       //   if (pago.TRNCOBRO_NRODOC.length > 0) {

    //       //   } else {
    //       //     pago.TRNCOBRO_NRODOC = i.TRNCOBRO_NRODOC;
    //       //   }
    //       //   this.botonGuardar = true;
    //       //   this.botonBorrar = false;
    //       //   this.botonRegresar = true;
    //       //   pago.TRNPAGO_IMPORTE = Number(pago.TRNPAGO_IMPORTE);
    //       //   pago.CLI_CODIGO = this.encfac.CLI_CODIGO;
    //       //   if (pago.TRNPAGO_FECHA.indexOf('-') > 0) {
    //       //     pago.TRNPAGO_FECHA = this.auditoriagral.transformarFecha(pago.TRNPAGO_FECHA);
    //       //   }

    //       //   this.pagconservice.insertarFormaPago(i.TRNCOBRO_NROCOMPROBANTE, this.encfac.ENCFAC_NUMERO, pago).subscribe(r => {
    //       //     this.auditoriagral.registrarAuditoria('CXC_TRNPAGO', i.TRNCOBRO_NRODOC + '/' + pago.TRNPAGO_NUMERO,
    //       //       'I', '', '01', '', '', '', '').subscribe();
    //       //     // this.encfacService.generarAsientoContableSinCxc(this.encfac.ENCFAC_NUMERO, 'CI',
    //       //     //   this.trncobronrodoc).subscribe(asi => {
    //       //     //     this.encfacService.erEncontrarFactura(this.encfac.ENCFAC_NUMERO).subscribe(cen => {
    //       //     //       if (cen[0] !== undefined) {
    //       //     //         this.encfac.ASI_NRO = cen[0].ASI_NRO;
    //       //     //         this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
    //       //     //           this.encfac.ASI_NRO + '/VEN', 'I',
    //       //     //           '', '01', '', '', '', '').subscribe(() => {
    //       //     //           });
    //       //     //         const INGRAPFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INGRAPFAC').CFG_VALOR1;
    //       //     //         if (INGRAPFAC === 0) {// ingreso rapido de la factura
    //       //     //           this.messageService.add({
    //       //     //             key: 'pagcon',
    //       //     //             severity: 'success',
    //       //     //             summary: 'Información',
    //       //     //             detail: 'El Asiento Contable se generó correctamente'
    //       //     //           });
    //       //     //           //CDPJ
    //       //     //           this.spin=false;
    //       //     //           //CDPJ
    //       //     //         }
    //       //     //         this.messageService.add({
    //       //     //           key: 'pagcon',
    //       //     //           severity: 'success',
    //       //     //           summary: 'Información',
    //       //     //           detail: 'La forma de pago se guardo correctamente'
    //       //     //         });
    //       //     //         //CDPJ
    //       //     //         this.spin=false;
    //       //     //         //CDPJ
    //       //     //         this.cancelarPago();//CDPJ
    //       //     //         this.botonNuevoGrid = true;//CDPJ----
    //       //     //       }
    //       //     //     });
    //       //     //   }, error1 => {
    //       //     //     let mensaje: string;
    //       //     //     mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
    //       //     //     this.messageService.add({
    //       //     //       key: 'pagcon',
    //       //     //       severity: 'error',
    //       //     //       summary: 'Asiento Contable',
    //       //     //       detail: mensaje
    //       //     //     });
    //       //     //     this.spin=false;
    //       //     //     //this.botonGuardar = false;
    //       //     //     this.reversarCancelacion(true);
    //       //     //   });
    //       //     // this.pagconservice.actualizaEstadoFactura(this.strNumFact, this.encfac.CLI_CODIGO).subscribe(() => {
    //       //     //   this.pagconservice.actualizaFormaPagoFact(this.strNumFact, this.strFormaPago).subscribe(() => {
    //       //     //     this.pagconservice.obtenerSaldoFactura(this.strNumFact).subscribe(saldo => {
    //       //     //       if (saldo != null) {
    //       //     //         if (saldo[0] !== undefined) {
    //       //     //           this.saldo = saldo[0].SALDO;
    //       //     //           this.saldo = Number(this.saldo).toFixed(this.confIniciales.getNumDecSist());
    //       //     //         }
    //       //     //       }
    //       //     //     });
    //       //     //   });
    //       //     // });
    //       //   }, error1 => {
    //       //     let mensaje: string;
    //       //     mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
    //       //     this.messageService.add({
    //       //       key: 'pagcon',
    //       //       severity: 'error',
    //       //       summary: 'Forma de Pago',
    //       //       detail: mensaje
    //       //     });
    //       //     this.spin = false;
    //       //     //this.botonGuardar = false;
    //       //     this.reversarCancelacion(true);
    //       //   });
    //       // }
    //     });
    //   }
    // });
    // // this.encfacService.generarAsientoContableSinCxc(this.encfac.ENCFAC_NUMERO, 'CI',
    // //   this.trncobronrodoc).pipe(finalize(() => { this.encontrarFactura()})).subscribe(asi => {        
    // //   }, error1 => {
    // //     let mensaje: string;
    // //     mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
    // //     this.messageService.add({
    // //       key: 'pagcon',
    // //       severity: 'error',
    // //       summary: 'Asiento Contable',
    // //       detail: mensaje
    // //     });
    // //     this.spin = false;
    // //     //this.botonGuardar = false;
    // //     this.reversarCancelacion(true);
    // //   });

    
  }
  //CDPJ
  async insertarTrnDoc(i, trncobro) {
    try {
      const rp = await this.pagconservice.insertarTrnDocumento(i.TRNCOBRO_NRODOC, i.TRNCOBRO_NROCOMPROBANTE, trncobro).toPromise()
      //pipe(finalize(async () => { await this.generarasientopipe() })).subscribe(async res => {
        this.trncobronrodoc = i.TRNCOBRO_NRODOC;
        this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', i.TRNCOBRO_NRODOC + '/' +
          this.encfac.ENCFAC_NUMERO, 'I',
          '', '01', '', '', '', '').subscribe();
        await this.insertretencion(i);
    } catch (error1) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
      this.messageService.add({
        key: 'pagcon',
        severity: 'error',
        summary: 'Error al insertar el documento',
        detail: mensaje
      });
      this.spin = false;
      console.log(error1)
    }
   
    
  }
  async insertretencion(i) {
    for (const ret of this.pagconservice.reten) {
      ret.TRNCOBRO_NRODOC = i.TRNCOBRO_NRODOC;
      ret.TRNCOBRO_NROCOMPROBANTE = i.TRNCOBRO_NROCOMPROBANTE;
      ret.ENCFAC_NUMERO = this.encfac.ENCFAC_NUMERO;
      ret.TRNRETENCION_BASE = Number(ret.TRNRETENCION_BASE);
      ret.TRNRETENCION_TOTALRETENIDO = Number(ret.TRNRETENCION_TOTALRETENIDO);
      ret.TRNRETENCION_PORCENTAJE = Number(ret.TRNRETENCION_PORCENTAJE);
      ret.CLI_CODIGO = this.encfac.CLI_CODIGO;
      await this.pagconservice.insertarTrnRetencion(ret).toPromise()
      this.auditoriagral.registrarAuditoria('SACI_TRNRETENCION', i.TRNCOBRO_NRODOC + '/' + ret.TRNRETENCION_NRO,
        'I', '', '01', '', '', '', '').subscribe();
    }
    await this.insertarFormaPago(i);
  }
  async insertarFormaPago(i) {
      for (const pago of this.pagconservice.pagos) {
      if (pago.CAJ_CODIGO !== null && pago.TRNPAGO_TIPO !== 'DP') {
      } else {
        pago.CAJ_CODIGO = pago.BANCLI_CODIGO;
      }

      if (pago.TRNCOBRO_NRODOC.length > 0) {

      } else {
        pago.TRNCOBRO_NRODOC = i.TRNCOBRO_NRODOC;
      }
      this.botonGuardar = true;
      this.botonBorrar = false;
      this.botonRegresar = true;
      pago.TRNPAGO_IMPORTE = Number(pago.TRNPAGO_IMPORTE);
      pago.CLI_CODIGO = this.encfac.CLI_CODIGO;
      if (pago.TRNPAGO_FECHA.indexOf('-') > 0) {
        pago.TRNPAGO_FECHA = this.auditoriagral.transformarFecha(pago.TRNPAGO_FECHA);
      }
      try {
        await this.pagconservice.insertarFormaPagoProm(i.TRNCOBRO_NROCOMPROBANTE, this.encfac.ENCFAC_NUMERO, pago)
//INGRESAR EL NDA CDPJ
if (pago.TRNPAGO_TIPO === 'ANCL') {
  const itemND: CxcTrncobro = {};
  itemND.TRNCOBRO_NRODOC = '';
  itemND.TRNCOBRO_NROCOMPROBANTE = '';
  itemND.TRNCOBRO_FECHATRN = this.datePipe.transform(this.encfac.ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
  itemND.TRNCOBRO_CONCEPTO = 'NOTA DE DEBITO POR EL ANTICIPO ' + pago.TRNPAGO_NUMERO;
  itemND.TRNCOBRO_IMPORTE = pago.TRNPAGO_IMPORTE;
  itemND.TRNCOBRO_FECHAVENCE = ''
  itemND.APLORG_CODIGO = 'SISTEMA';
  itemND.TRNCOBRO_REFERENCIAEXTERNA = null;
  itemND.CLI_CODIGO = this.encfac.CLI_CODIGO;
  itemND.VEN_CODIGO = this.encfac.VEN_CODIGO;
  itemND.ASI_NRO = ''
  itemND.USU_IDENTIFICACION = this.encfac.USU_IDENTIFICACION;
  itemND.TRNCOBRO_FLAG = '1';
  itemND.BAN_CODIGO = null;
  itemND.TRNCOBRO_EFECTIVO = '0.0';
  itemND.TRNCOBRO_CAMBIO = '0.0';
  itemND.CON_CODIGO = null;
  itemND.TRNCOBRO_SALDOINI = 0;
  itemND.BOD_CODIGO = this.encfac.BOD_CODIGO;

  console.log(itemND);
  const data = await this.cxcCarteraService.insertarCobroP(itemND, 'NDA');
  console.log('11 insertarCobro NDA');
  console.log(data);
  let strNumDocAnt = '';
  let strTrnCobroNroDoc = '';
  let strTrnCobroNroComprobante = '';

  const eR = await this.cxcCarteraService.erTrncobronrodocProm(pago.TRNPAGO_NUMERO);
  console.log(eR);
  console.log('12 encontrar TRNCOBRO_NRODOC');
  if (eR !== undefined) {
    if (eR[0].TRNCOBRO_NRODOC !== null) {
      strNumDocAnt = eR[0].TRNCOBRO_NRODOC;
    }
  }

  for (const rs of data) {
    strTrnCobroNroDoc = rs.TRNCOBRO_NRODOC;
    strTrnCobroNroComprobante = rs.TRNCOBRO_NROCOMPROBANTE;
  }
  // actualizar la referencia de la nota de débito con el número del anticipo strTrnCobroNroDoc
  await this.cxcCarteraService.actualizarNotaDebitoAnticipoProm(strTrnCobroNroDoc, strNumDocAnt);
  console.log('12 actualizarNotaDebitoAnticipo');
  await this.cxcCarteraService.actualizarFormaPagoNDProm(strTrnCobroNroDoc,
    pago.TRNPAGO_NUMERO, i.TRNCOBRO_NRODOC);
  console.log('12 actualizarFormaPagoND');
}
//CDPJ
        this.auditoriagral.registrarAuditoria('CXC_TRNPAGO', i.TRNCOBRO_NRODOC + '/' + pago.TRNPAGO_NUMERO,
          'I', '', '01', '', '', '', '').subscribe();

    this.auditoriagral.registrarAuditoria('VEN_ENCFAC', this.encfac.ENCFAC_NUMERO+'/FP/'+'T', 'I',
    '', '01', '', '', '', '').subscribe();
//CDPJ

      } catch (error1) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
        this.messageService.add({
          key: 'pagcon',
          severity: 'error',
          summary: 'Forma de Pago',
          detail: mensaje
        });
        this.spin = false;
        //this.botonGuardar = false;
        this.reversarCancelacion(true);
      }
    };
    await this.generarasientopipe();
  }
  async generarasientopipe(){
    console.log('pipe',this.trncobronrodoc)
    this.encfacService.generarAsientoContableSinCxc(this.encfac.ENCFAC_NUMERO, 'CI',
      this.trncobronrodoc).pipe(finalize(() => { this.encontrarFactura()})).subscribe(asi => {

      }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
        this.messageService.add({
          key: 'pagcon',
          severity: 'error',
          summary: 'Asiento Contable',
          detail: mensaje
        });
        this.spin = false;
        //this.botonGuardar = false;
        this.reversarCancelacion(true);
      });
      
  }
  actualizarformapago() {
    this.pagconservice.actualizaFormaPagoFact(this.strNumFact, this.strFormaPago).pipe(finalize(() => {
      this.obtenersaldo()
    })).subscribe(() => {

    });
  }
  obtenersaldo() {
    this.pagconservice.obtenerSaldoFactura(this.strNumFact).subscribe(saldo => {
      if (saldo != null) {
        if (saldo[0] !== undefined) {
          this.saldo = saldo[0].SALDO;
          this.saldo = Number(this.saldo).toFixed(this.confIniciales.getNumDecSist());
        }
      }
    });
  }
  encontrarFactura(){
    this.encfacService.erEncontrarFactura(this.encfac.ENCFAC_NUMERO).pipe(finalize(()=>{
      this.pagconservice.actualizaEstadoFactura(this.strNumFact, this.encfac.CLI_CODIGO).pipe(finalize(() => {
        this.actualizarformapago()
      })).subscribe(() => { });
    })).subscribe( async cen => {
      if (cen[0] !== undefined) {
        this.encfac.ASI_NRO = cen[0].ASI_NRO;
        await this.pagconservice.actualizarAsiNroCxc(this.trncobronrodoc, this.encfac.ASI_NRO).toPromise().catch(e=>{
          this.messageService.add({
            key: 'pagcon',
            severity: 'error',
            summary: 'Asiento Contable',
            detail: 'Error al actualizar el número del asiento en cuentas por cobrar'
          });
          this.spin = false;
        })
        this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
          this.encfac.ASI_NRO + '/VEN', 'I',
          '', '01', '', '', '', '').subscribe(() => {
          });
        const INGRAPFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INGRAPFAC').CFG_VALOR1;
        if (INGRAPFAC === 0) {// ingreso rapido de la factura
          this.messageService.add({
            key: 'pagcon',
            severity: 'success',
            summary: 'Información',
            detail: 'El Asiento Contable se generó correctamente'
          });
          //CDPJ
          this.spin = false;
          //CDPJ
        }
        this.messageService.add({
          key: 'pagcon',
          severity: 'success',
          summary: 'Información',
          detail: 'La forma de pago se guardo correctamente'
        });
        //CDPJ
        this.spin = false;
        //CDPJ
        this.cancelarPago();//CDPJ
        this.botonNuevoGrid = true;//CDPJ----
        setTimeout(() => {

          if(this.modulo==='ptovta'){
            this.salir.emit(true);
          }
      }, 1000);
      }
    });
  }
  //CDPJ

  eliminarUltimaLineaFP() {
    const intUltimaFila = this.pagconservice.pagos.length - 1;
    if (this.pagconservice.pagos[intUltimaFila].TRNPAGO_TIPO.length === 0) {
      this.pagconservice.pagos.splice(intUltimaFila,
        1);
      this.aggrid.refreshaggrid(this.pagconservice.pagos, this.defaultColDef.objeto);
    }
  }

  eliminarUltimaLineaRet() {
    const intUltimaFila = this.pagconservice.reten.length - 1;
    if (this.pagconservice.reten.length !== 0) {
      if (this.pagconservice.reten[intUltimaFila].RETENCION_CODIGO.length === 0) {
        this.pagconservice.reten.splice(intUltimaFila,
          1);
        this.aggrid.refreshaggrid(this.pagconservice.reten, this.defaultColDefRet.objeto);
      }
    }
  }

  async faltaDatosRet() {
    let errores = 0;
    let cont = 0;
    let nroretencion = '';

    for (const ret of this.pagconservice.reten) {
      if (ret.RETENCION_CODIGO.length === 0) {
        this.messageService.add({
          key: 'pagcon',
          severity: 'warn',
          summary: 'Información',
          detail: 'Ingrese el código de la Retención.'
        });
        //CDPJ
        this.spin = false;
        this.botonGuardar = false;
        //CDPJ
        errores++;
        return;
      } else {
        const a = await this.pagconservice.erpCodigoRet(ret.RETENCION_CODIGO);
        if (a === null) {
          this.messageService.add({
            key: 'pagcon',
            severity: 'warn',
            summary: 'Información',
            detail: 'La retención no existe.'
          });
          //CDPJ
          this.spin = false;
          this.botonGuardar = false;
          //CDPJ
          errores++;
          return;
        }

        if (ret.TRNRETENCION_DESCRIPCION.length === 0 || ret.TRNRETENCION_NRO.length === 0) {
          this.messageService.add({
            key: 'pagcon',
            severity: 'error',
            summary: 'Error',
            detail: 'La descripción y el número de retención son obligatorios.'
          });
          //CDPJ
          this.spin = false;
          this.botonGuardar = false;
          //CDPJ
          errores++;
          return;
        }

        if (ret.TRNRETENCION_BASE <= 0) {
          this.messageService.add({
            key: 'pagcon',
            severity: 'error',
            summary: 'Error',
            detail: 'La base de la retención no puede ser menor o igual a cero.'
          });
          //CDPJ
          this.spin = false;
          this.botonGuardar = false;
          //CDPJ
          errores++;
          return;
        }

        if (ret.TRNRETENCION_PORCENTAJE < 0) {
          this.messageService.add({
            key: 'pagcon',
            severity: 'error',
            summary: 'Error',
            detail: 'El porcentaje de la retención no puede ser menor a cero.'
          });
          //CDPJ
          this.spin = false;
          this.botonGuardar = false;
          //CDPJ
          errores++;
          return;
        }

        if (ret.TRNRETENCION_TOTALRETENIDO < 0) {
          this.messageService.add({
            key: 'pagcon',
            severity: 'error',
            summary: 'Error',
            detail: 'El total de la retención no puede ser menor a cero.'
          });
          //CDPJ
          this.spin = false;
          this.botonGuardar = false;
          //CDPJ
          errores++;
          return;
        }

        // Verificar número de la retención

        cont++;
        if (cont > 1) {
          if (nroretencion !== ret.TRNRETENCION_NRO) {
            this.messageService.add({
              key: 'pagcon',
              severity: 'error',
              summary: 'Error',
              detail: 'El número de la retención debe ser el mismo'
            });
            //CDPJ
            this.spin = false;
            this.botonGuardar = false;
            //CDPJ
            errores++;
            return;
          }
        } else {
          nroretencion = ret.TRNRETENCION_NRO;
        }

      }
    }


    if (errores === 0) {
      this.finalmenteGuardar();
    }

  }

  verificarTipoCliente() {
    let clicodigo = '';
    if (this.modulo === 'ptovta') {
      clicodigo = this.encptovtaService.encfac[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO;
    } else {
      clicodigo = this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO;
    }

    const FACTIPCLI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FACTIPCLI').CFG_VALOR1;
    if (FACTIPCLI === 1) {
      this.encfacService.erVerificarTipoCliente(clicodigo).subscribe(er => {
        let strTipoCli = '';
        if (er !== null) {
          if (er[0] !== undefined) {
            strTipoCli = er[0].CLI_TIPO;
          }
        }
        let aux = 0;
        for (const pago of this.pagconservice.pagos) {
          if (pago.TRNPAGO_TIPO !== 'EF') {
            aux++;
          }
        }
        if (aux !== 0 && strTipoCli === '3') {
          this.messageService.add({
            key: 'pagcon',
            severity: 'error',
            summary: 'Información',
            detail: 'Al cliente solo se le permite pagos en Efectivo'
          });
          //CDPJ
          this.spin = false;
          this.botonGuardar = false;
          //CDPJ
          return;
        } else {
          this.faltaDatosFP();
        }
      });
    } else {
      this.faltaDatosFP();
    }
  }

  async faltaDatosFP() {
    let errores = 0;
    for (const pago of this.pagconservice.pagos) {
      if (pago.TRNPAGO_TIPO.length === 0) {
        this.messageService.add({
          key: 'pagcon',
          severity: 'warn',
          summary: 'Información',
          detail: 'Ingrese el tipo de pago.'
        });
        //CDPJ
        this.spin = false;
        this.botonGuardar = false;
        //CDPJ
        errores++;
      } else {
        let numero=0
        const a = await this.pagconservice.erpTipoFormaPago(pago.TRNPAGO_TIPO);
        //CDPJ
        const exispago= await this.pagconservice.erpExisFormaPago(pago.BANCLI_CODIGO,pago.TRNPAGO_TIPO,pago.TRNPAGO_NUMERO);
        if(exispago !== null){
          numero=Number(exispago[0].NUMERO)
        }
        //CDPJ
        
        if (a === null) {
          this.messageService.add({
            key: 'pagcon',
            severity: 'warn',
            summary: 'Información',
            detail: 'La forma de pago no existe.'
          });
          //CDPJ
          this.spin = false;
          this.botonGuardar = false;
          //CDPJ
          errores++;
        }
        if (pago.TRNPAGO_NUMERO.length === 0 || pago.TRNPAGO_FECHA.length === 0) {
          this.messageService.add({
            key: 'pagcon',
            severity: 'error',
            summary: 'Error',
            detail: 'La información está incompleta.'
          });
          //CDPJ
          this.spin = false;
          this.botonGuardar = false;
          //CDPJ
          errores++;
        }
        if (pago.TRNPAGO_TIPO !== 'DP') {
          if (pago.CAJ_CODIGO === null || pago.CAJ_CODIGO === '' || pago.CAJ_CODIGO === undefined) {//CDPJ
            this.messageService.add({
              key: 'pagcon',
              severity: 'error',
              summary: 'Error',
              detail: 'Ingrese la caja.'
            });
            //CDPJ
            this.spin = false;
            this.botonGuardar = false;
            //CDPJ
            errores++;
          }
        } else if (pago.TRNPAGO_TIPO === 'DP') {
          pago.CAJ_CODIGO = ''
        }
        if (pago.TRNPAGO_TIPO === 'TA' || pago.TRNPAGO_TIPO === 'CH' || pago.TRNPAGO_TIPO === 'DP') {
          if (pago.BANCLI_CODIGO.length === 0 || pago.BANCLI_NOMBRE.length === 0) {
            this.messageService.add({
              key: 'pagcon',
              severity: 'error',
              summary: 'Error',
              detail: 'La información está incompleta.'
            });
            //CDPJ
            this.spin = false;
            this.botonGuardar = false;
            //CDPJ
            errores++;
          }
        }
        if (pago.TRNPAGO_IMPORTE <= 0) {
          this.messageService.add({
            key: 'pagcon',
            severity: 'error',
            summary: 'Error',
            detail: 'El pago no puede ser menor o igual a cero.'
          });
          //CDPJ
          this.spin = false;
          this.botonGuardar = false;
          //CDPJ
          errores++;
        }
        //CDPJ
        if (pago.TRNPAGO_TIPO === 'DP' && numero > 0) {
          this.messageService.add({
            key: 'pagcon',
            severity: 'error',
            summary: 'Error',
            detail: 'El depósito '+pago.TRNPAGO_NUMERO+' ya existe.'
          });
          //CDPJ
          this.spin = false;
          this.botonGuardar = false;
          //CDPJ
          errores++;
        }
        if (pago.TRNPAGO_TIPO === 'TA' ) {
          if (pago.TRNPAGO_NROCTA.length === 0) {
            this.messageService.add({
              key: 'pagcon',
              severity: 'error',
              summary: 'Error',
              detail: 'No se puede insertar el registro por que falta el número de cuenta'
            });
            //CDPJ
            this.spin = false;
            this.botonGuardar = false;
            //CDPJ
            errores++;
          }
        }
        //CDPJ
      }
    }

    console.log('errores', errores)
    if (errores === 0) {
      await this.verificarExistenciaCaja();//CDPJ
      //this.verificarExistenciaCaja()
    }

  }

  async verificarExistenciaCaja() {
    let errores = 0;

    for (const pago of this.pagconservice.pagos) {
      if (pago.TRNPAGO_TIPO !== 'DP') {
        const a = await this.pagconservice.erBanCajaCodigo(pago.CAJ_CODIGO);
        if (a === null) {
          this.messageService.add({
            key: 'pagcon',
            severity: 'error',
            summary: 'Error',
            detail: 'No existe la caja.'
          });
          //CDPJ
          this.spin = false;
          this.botonGuardar = false;
          //CDPJ
          errores++;
        }
      }
    }
    console.log('errores2', errores)
    if (errores === 0) {
      this.controlarFechaCobro();
      // this.controlarFechaCobro();//CDPJ
    }
  }

  async controlarFechaCobro() {
    let errores = 0;
    let strFechaFact = '';
    let dateFechaFact = new Date();
    let dateFechaCobro = new Date();
    const fecha = await this.pagconservice.erpFechaEmisionEncFac(this.encfac.ENCFAC_NUMERO);
    if (fecha != null) {
      if (fecha[0] !== undefined) {
        strFechaFact = fecha[0].ENCFAC_FECHAEMISION;
      }
    }

    for (const pago of this.pagconservice.pagos) {
      const strFechaCobro = pago.TRNPAGO_FECHA;

      if (strFechaCobro.toString().includes('/')) {
        dateFechaCobro = new Date(Number(strFechaCobro.substr(6, 4)),
          Number(strFechaCobro.substr(3, 2)) - 1,
          Number(strFechaCobro.substr(0, 2)));
      } else if (strFechaCobro.toString().includes('-')) {
        const dateaux = new Date(strFechaCobro);
        dateaux.setDate(dateaux.getDate() + 1);
        dateFechaCobro = new Date(dateaux);
      }

      dateFechaFact = new Date(strFechaFact);

      dateFechaFact.setHours(0);
      dateFechaFact.setMinutes(0);
      dateFechaFact.setSeconds(0);
      dateFechaFact.setMilliseconds(0);

      dateFechaCobro.setHours(0);
      dateFechaCobro.setMinutes(0);
      dateFechaCobro.setSeconds(0);
      dateFechaCobro.setMilliseconds(0);

      if (dateFechaFact > dateFechaCobro) {
        this.messageService.add({
          key: 'pagcon',
          severity: 'error',
          summary: 'Error',
          detail: 'La fecha de cobro no puede ser menor a ' +
            'la fecha de la factura. Fecha de la factura: ' + this.datePipe.transform(strFechaFact, 'dd/MM/yyyy')
        });
        //CDPJ
        this.spin = false;
        this.botonGuardar = false;
        //CDPJ
        errores++;
      }
    }

    if (errores === 0) {
      await this.verificarExistenciaBancoTarj();
      // this.verificarExistenciaBancoTarj();//CDPJ
    }
  }

  async verificarExistenciaBancoTarj() {
    let errores = 0;

    for (const pago of this.pagconservice.pagos) {
      if (pago.TRNPAGO_TIPO === 'CH') {
        const intNumBanco = await this.pagconservice.erpBanCliCodigo(pago.BANCLI_CODIGO);
        if (intNumBanco != null) {
          if (intNumBanco[0] !== undefined) {
            if (intNumBanco[0]['COUNT(*)'] === 0) {
              this.messageService.add({
                key: 'pagcon',
                severity: 'error',
                summary: 'Error',
                detail: 'El Banco no existe'
              });
              //CDPJ
              this.spin = false;
              this.botonGuardar = false;
              //CDPJ
              errores++;
            }
          }
        }
      } else if (pago.TRNPAGO_TIPO === 'TA') {
        const intNumTarj = await this.pagconservice.erpContarTarjetaCod(pago.BANCLI_CODIGO);
        if (intNumTarj != null) {
          if (intNumTarj[0] !== undefined) {
            if (intNumTarj[0]['COUNT(*)'] === 0) {
              this.messageService.add({
                key: 'pagcon',
                severity: 'error',
                summary: 'Error',
                detail: 'La tarjeta de crédito no existe'
              });
              //CDPJ
              this.spin = false;
              this.botonGuardar = false;
              //CDPJ
              errores++;
            }
          }
        }
      } else if (pago.TRNPAGO_TIPO === 'DP') {
        const intNumTarj = await this.pagconservice.erpBanCliCodigoDP(pago.BANCLI_CODIGO);
        if (intNumTarj != null) {
          if (intNumTarj[0] !== undefined) {
            if (intNumTarj[0]['COUNT(*)'] === 0) {
              this.messageService.add({
                key: 'pagcon',
                severity: 'error',
                summary: 'Error',
                detail: 'El Banco no existe'
              });
              //CDPJ
              this.spin = false;
              this.botonGuardar = false;
              //CDPJ
              errores++;
            }
          }
        }
      }
    }

    if (errores === 0) {
      this.verificarCodSri();
      // this.verificarCodSri();//CDPJ
    }
  }

  async verificarCodSri() {
    let errores = 0;
    for (const pago of this.pagconservice.pagos) {
      if (pago.TRNPAGO_TIPO !== '' && pago.TRNPAGO_CODSRI !== '') {
        if (pago.TRNPAGO_TIPO === 'TA') {
          const er = await this.pagconservice.erpCodMaeSri(pago.TRNPAGO_CODSRI);
          if (er === null) {
            this.messageService.add({
              key: 'pagcon',
              severity: 'warn',
              summary: 'Información',
              detail: 'El código SRI en la forma de pago es incorrecto, por favor verifique.'
            });
            //CDPJ
            this.spin = false;
            this.botonGuardar = false;
            //CDPJ
            errores++;
          }
        } else {
          const er = await this.pagconservice.erpCodMaeSriCF(pago.TRNPAGO_CODSRI);
          if (er === null) {
            this.messageService.add({
              key: 'pagcon',
              severity: 'warn',
              summary: 'Información',
              detail: 'El código SRI en la forma de pago es incorrecto, por favor verifique.'
            });
            //CDPJ
            this.spin = false;
            this.botonGuardar = false;
            //CDPJ
            errores++;
          }
        }
      }
    }

    if (errores === 0) {
      // VALIDACIONES DE LAS RETENCIONES
      if (this.pagconservice.reten.length > 0) {
        this.eliminarUltimaLineaRet();
        this.faltaDatosRet();
      } else {
        this.finalmenteGuardar();
      }
    }
  }

  comprobarClave() {
    this.pagconservice.obtenerClave('REVPAGO').subscribe((clave) => {
      let claveReal;
      claveReal = clave[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        this.confirmationService.confirm({
          message: 'Está seguro de reversar el pago?',
          header: 'Reversar Pago',
          icon: 'pi pi-exclamation-triangle',
          key: 'pagconconf',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.reversarCancelacion(false);
          },
          reject: () => {
          }
        });
        this.claveDialog = false;
      } else if (clave !== this.claveAuth) {
        this.messageService.add({
          key: 'pagcon',
          severity: 'error',
          summary: 'Clave Autorización',
          detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
        });
      }
    });
  }

  async reversarCancelacion(rollback) {
    //document.getElementById('contorno').style.pointerEvents = 'all';//CDPJ
    // const strNumDoc = this.trncobronrodoc;
    // const strCondicion = 'TRNCOBRO_NRODOC = \'' + strNumDoc
    //   + '\' AND COM_CODIGO = \'01\'';

    // this.pagconservice.eliminarTrnCobro(strCondicion, strNumDoc).subscribe(eli => {
    //   this.pagconservice.eliminarTrnCobro2(strCondicion, strNumDoc).subscribe(eli2 => {
    //     if (!rollback) {
    //       this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', strNumDoc, 'E', '',
    //         '01', '', '', '', '').subscribe(() => {
    //         });
    //     }
    //     if (rollback) {
    //       this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', strNumDoc + '-rollbackCXC', 'E', '',
    //         '01', '', '', '', '').subscribe(() => {
    //         });
    //     }
    //     this.pagconservice.actualizaEstadoFactura(this.strNumFact, this.encfac.CLI_CODIGO).subscribe(act => {
    //       this.pagconservice.actualizaFormaPagoFact(this.strNumFact, '').subscribe(actfp => {
    //         if (this.encfac.ASI_NRO != null) {
    //           if (this.encfac.ASI_NRO.length > 0) {
    //             this.pagconservice.anularAsientoContable(this.encfac.ASI_NRO).subscribe(anuasi => {
    //               this.pagconservice.actualizarAsiNroFact(this.strNumFact).subscribe();
    //             });
    //           }
    //         }
    //         this.limpiarCancelacion();
    //       });
    //     });
    //   });
    // }
    // );
    //document.getElementById('contorno').style.pointerEvents = 'all';//CDPJ
    const strNumDoc = this.trncobronrodoc;
    let errores = 0;
    let numero = '';
    let strnumerodoc = this.trncobronrodoc;
    //CDPJ CONSULTAR DEPOSITO Y CONCILIACIONES
    for (let p of this.pagconservice.pagos) {
      if (p.TRNPAGO_TIPO !== 'DP') {
        //strnumerodoc = p.TRNPAGO_NUMERO;
        //}
        //this.pagconservice.pagos.map((p, index) => {
        console.log(strNumDoc);
        const res = await this.pagconservice.consultardeposito(strnumerodoc, p.TRNPAGO_TIPO)//.subscribe((res) => {
        //console.log(res[0])
        //console.log(res[0].TRNBAN_TIPO)
        if (res !== null && res !== undefined) {
          if (res[0] !== null && res[0] !== undefined && res[0] !== '') {
            if (res[0].TRNBAN_TIPO !== ''
              && res[0].TRNBAN_TIPO !== null
              && res[0].TRNBAN_TIPO !== undefined) {
              this.messageService.add({
                key: 'pagcon',
                severity: 'error',
                summary: 'Documentos depositados',
                detail: 'No se puede eliminar porque el pago tiene documentos depositados'
              });
              numero = res[0].TRNBAN_NUMERO;
              errores = errores + 1;
            }
          }
        }
      }
      //  });
      if (p.TRNPAGO_TIPO === 'DP') {
        numero = p.TRNPAGO_NUMERO;
        const res1 = await this.pagconservice.consultarconciliacion(numero, p.BANCLI_CODIGO)//.subscribe((res) => {
        if (res1 !== null && res1 !== undefined) {
          if (res1[0] !== null && res1[0] !== undefined && res1[0] !== '') {
            if (res1[0].NUMERO !== ''
              && res1[0].NUMERO !== null
              && res1[0].NUMERO !== undefined
              && res1[0].NUMERO !== 0) {
              this.messageService.add({
                key: 'pagcon',
                severity: 'error',
                summary: 'Documentos Conciliados',
                detail: 'No se puede eliminar porque el pago tiene documentos conciliados'
              });
              //numero=res[0].TRNBAN_NUMERO;
              errores = errores + 1;
            }
          }
        }
        // });
      }
    }//)

    console.log('errores', errores);
    if (errores === 0) {
      const strCondicion = 'TRNCOBRO_NRODOC = \'' + strNumDoc
        + '\' AND COM_CODIGO = \'01\'';

      this.pagconservice.eliminarTrnCobro(strCondicion, strNumDoc).subscribe(eli => {
        this.pagconservice.eliminarTrnCobro2(strCondicion, strNumDoc).subscribe(eli2 => {
          if (!rollback) {
            this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', strNumDoc, 'E', '',
              '01', '', '', '', '').subscribe(() => {
              });
          }
          if (rollback) {
            this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', strNumDoc + '-rollbackCXC', 'E', '',
              '01', '', '', '', '').subscribe(() => {
              });
          }
          this.pagconservice.actualizaEstadoFactura(this.strNumFact, this.encfac.CLI_CODIGO).subscribe(act => {
            this.pagconservice.actualizaFormaPagoFact(this.strNumFact, '').subscribe(actfp => {
              if (this.encfac.ASI_NRO != null) {
                if (this.encfac.ASI_NRO.length > 0) {
                  this.pagconservice.anularAsientoContable(this.encfac.ASI_NRO).subscribe(anuasi => {
                    this.pagconservice.actualizarAsiNroFact(this.strNumFact).subscribe();
                  });
                }
              }
              this.limpiarCancelacion();
            });
          });
        });
      }
      );
    }
  }

  limpiarCancelacion() {
    this.btnefectivo = false//CDPJ
    this.aggrid.habilitarGrid();//CDPJ
    this.buttonmod();//CDPJ
    this.pagconservice.pagos = [];
    this.pagconservice.reten = [];
    this.botonBorrar = true;//CDPJ
    this.spin = false;//CDPJ
    this.efectivo = '';
    this.cambio = '';
    this.trncobronrodoc = '';
    this.encfac.ASI_NRO = '';

    this.pagconservice.obtenerSaldoFactura(this.strNumFact).subscribe(saldo => {
      if (saldo != null) {
        if (saldo[0] !== undefined) {
          this.saldo = saldo[0].SALDO;
          this.saldo = Number(this.saldo).toFixed(this.confIniciales.getNumDecSist());
        }
      }
    });

    this.pagconservice.erCobroResumenRef(this.strNumFact).subscribe(formsricom => {
      if (formsricom != null) {
        if (formsricom[0] !== undefined) {
          this.botonBorrar = false;
          this.trncobronrodoc = formsricom[0].TRNCOBRO_NRODOC;
          this.consultarCancelacionAbono(this.trncobronrodoc);
        }
      }
    });

    this.strFechaCobro = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
  }

  verAsiento() {
    this.asiento = this.encfac.ASI_NRO;
    if (this.asiento === null) {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Información',
        detail: 'No ha generado el Asiento Contable'
      });
    } else {
      this.auxiliar1++;
      // this.displayDialogAsiento = true;
      this.displayAsiContable = true;
    }
  }


  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }
}

