<div class="layout-topbar">
	<!--<a class="layout-right-panel-button layout-topbar-icon" (click)="app.onRightMenuClick($event)" href="#">
		<i class="pi pi-ellipsis-v"></i>
	</a>-->
	<a href="#" class="layout-menu-button layout-topbar-icon" (click)="app.onMenuButtonClick($event)">
		<i class="pi pi-bars"></i>
	</a>
	<img src="../assets/layout/images/Logo_Marca_2.png"
		style="position: absolute; padding-left: 1%; width: 15vw; height: 98%">
	<!--<span class="guest-sign-in" style="padding-left: 15vw; color: whitesmoke">
			<< Versión 8.0 Revisión 11 - 18/12/2020 >> Se cerrará en {{uS.timeLeft}} segundos</span>-->
	<!--<span class="guest-sign-in" style="padding-left: 15vw; color: whitesmoke">
			<< Versión 9.0 Revisión 15 - 22/01/2020 >></span>-->
	<div style="position: absolute; top: 1px; text-align: right; right: 17vw; width: 30vw; height: 70%; top: 30%">
		<i class="pi pi-user"></i>
		<span class="guest-sign-in" style="color: whitesmoke; font-weight: bold ">
			{{usuario.nombre + ' ' + usuario.apellido}}</span>
	</div>
	<!--<span class="guest-sign-in" style="padding-left: 20vw; color: whitesmoke">
			{{usuarioEsquema.esquema}}</span>-->
	<img src="{{logotipo}}" style="position: absolute; top: 1%; right: 0.1%; width: 15vw; height: 98%">
</div>