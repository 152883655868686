<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="cxcCobranzas"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="cxccobro" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 98%">
    <div class="p-col-12">
        <app-invmaebarra (signal)="manejarSenales($event)" [botonNuevo]="true" [botonGuardar]="true"
            [botonBorrar]="botonBorrar" [botonRegresar]="false" [barraBotonesDesplaz]="true"
            [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true"
            [botonFrmCorreos]="boolformscorreo" [barraCartera]="false" [botonEditDatosCli]="boolformscorreo"
            ></app-invmaebarra>
    </div>
</div>
<p-tabView [(activeIndex)]="index" (onChange)="changeTabs()" [style]="{'width':'98%'}">
    <p-tabPanel header="General">
        <p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 270px)'}">
            <div class="contorno" id="contorno">
                <div class="divgrups">
                    <div class="p-grid">
                        <!--LINEA 1-->
                        <div class="p-col-1">
                            <span>Cliente:</span>
                        </div>
                        <div class="p-col-3">
                            <label for="lblcliente"></label>
                            <input class="frm" id="lblcliente" type="text" pInputText disabled [(ngModel)]="lblcliente"
                                style="color: blue; font-weight: bold;" autocomplete="off">
                        </div>
                        <div class="p-col-1">
                            <span>Teléfonos:</span>
                        </div>
                        <div class="p-col-2">
                            <label for="lbltelefono"></label>
                            <input class="frm" id="lbltelefono" type="text" pInputText disabled
                                [(ngModel)]="lbltelefono" style="color: blue; font-weight: bold;"
                                autocomplete="off">
                        </div>
                       
                        <div class="p-col-1">
                            <span>Ordenar:</span>
                        </div>
                        <div class="p-col-3">
                                <p-dropdown [options]="opcionesOrderBy" [style]="{'width':'100%'}"
                                optionLabel="name" [panelStyle]="{'width':'110%'}"
                                (onChange)="busqorderby($event)"
                                [(ngModel)]="selecttipoorderby"
                                ></p-dropdown>
                                       
                        </div>
                        <div class="p-col-1">
                            <p-checkbox label="Aplicar" binary="true"
                                (click)="aplicarorderby()" [(ngModel)]="chkaplicaorderby" [disabled] = "disabledorderby">
                            </p-checkbox>
                        </div>
                        <!--LINEA 2-->
                        <div class="p-col-12" style="margin-top: -10px">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Contacto:</span>
                                </div>
                                <div class="p-col-3">
                                    <label for="lblcontacto"></label>
                                    <input class="frm" id="lblcontacto" type="text" pInputText disabled
                                        style="color: blue; font-weight: bold;"
                                        [(ngModel)]="lblcontacto" autocomplete="off">
                                </div>   
                                <div class="p-col-1">
                                    <span>Vendedor:</span>
                                </div>
                                <div class="p-col-2">
                                    <label for="lblvendedor"></label>
                                    <input class="frm" id="lblvendedor" type="text" pInputText disabled
                                        style="color: blue; font-weight: bold;"
                                        [(ngModel)]="lblvendedor" autocomplete="off">
                                </div>
                                <div class="p-col-1">
                                    <span>Correo:</span>
                                </div>
                                <div class="p-col-3">
                                    <label for="lblcorreo"></label>
                                    <input class="frm" id="lblcorreo" type="text" pInputText disabled
                                        style="color: blue; font-weight: bold;"
                                        [(ngModel)]="lblcorreo" autocomplete="off">
                                </div>
                            </div>
                        </div>
                        <!--LINEA 3-->
                        <div class="p-col-12" style="margin-top: -10px">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Dirección:</span>
                                </div>
                                <div class="p-col-3">
                                    <label for="lbldireccion"></label>
                                    <input class="frm" id="lbldireccion" type="text" pInputText disabled
                                        style="color: blue; font-weight: bold;"
                                        [(ngModel)]="lbldireccion" autocomplete="off">
                                </div>

                                <div class="p-col-1">
                                    <span>Observacion:</span>
                                </div>
                                <div class="p-col-5">
                                    <label for="lblobservacion"></label>
                                    <input class="frm" id="lblobservacion" type="text" pInputText disabled
                                        style="color: blue; font-weight: bold;"
                                        [(ngModel)]="lblobservacion" autocomplete="off">
                                </div>
                                <!-- <div class="p-col-1">
                                    <span>Saldo:</span>
                                </div>
                                <div class="p-col-1">
                                    <label for="lblsaldo"></label>
                                    <input class="frm" id="lblsaldo" type="text" pInputText disabled
                                        style="text-align: right; color: blue; font-weight: bold;" [(ngModel)]="lblsaldo"
                                        autocomplete="off">
                                </div> -->
                            </div>
                        </div>
                        <!--LINEA 4-->
                        <div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Saldo del Cliente:</span>
                                </div>
                                <div class="p-col-1">
                                    <label for="lblsaldoCli"></label>
                                    <input class="frm" id="lblsaldoCli" type="text" pInputText disabled
                                        style="text-align: right; color: blue; font-weight: bold;" [(ngModel)]="lblsaldoCli"
                                        autocomplete="off">
                                </div>

                                <div class="p-col-1">
                                    <span>Saldo de Cartera:</span>
                                </div>
                                <div class="p-col-1">
                                    <label for="lblsaldo"></label>
                                    <input class="frm" id="lblsaldo" type="text" pInputText disabled
                                        style="text-align: right; color: blue; font-weight: bold;" [(ngModel)]="lblsaldo"
                                        autocomplete="off">
                                </div>
                                <div class="p-col-1">
                                    <span>Seleccionar Todos:</span>
                                </div>
                                <div class="p-col-1">
                                    <label for="lblsaldo"></label>
                                    <p-checkbox value="valSelect" (onChange)="seleccionarall($event)"></p-checkbox>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-aggridsaci 
            [width]=""
            [height]="largo" 
            [enableSorting]="true" 
            [enableFilter]="true"
            [rowData]="cxctrncobranzasService.trncobranza" 
            [frameworkComponents]="frameworkComponents"
            [animateRows]="true" 
            [rowSelection]="rowSelection" 
            [columnDefs]="columnDefsCxcTrnCob"
            [defaultColDef]="defaultCxcTrnCob" 
            [rowStyle]="rowStyle" 
            [singleClickEdit]="false" 
            [botones]="false"
            [mostrarGuardar]="false" 
            [botonesAdicionales]="false" 
            [comboOpciones]=""
            (selected)="seleccionItem($event)" 
            [tipodetalle]="">
            </app-aggridsaci>
        </p-scrollPanel>
    </p-tabPanel>
  <p-tabPanel header="Estado de Cuenta" [disabled]="diasabledtabs">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Cliente:</span>
				</div>
				<div class="p-col-6">
					<span>{{lblclicodigo + ' - ' + lblcliente}}</span>
				</div>
				<div class="p-col-2">
				</div>
				 <!-- <div class="p-col-2">
					<button class="medium" style="background-image: url(../../assets/images/iconos/024-message.png)"
						(click)="enviarEstadoCuenta()">
					</button>
				</div>  -->
			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largoestcta" [enableSorting]="true" [enableFilter]="true"
			[rowData]="cxctmptrnresumen" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsCxctmptrnresumen"
			[defaultColDef]="defaultColDefCxctmptrnresumen" [rowStyle]="rowStyleEC" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>
    </p-tabPanel>
    
	<p-tabPanel header="Saldos" [disabled]="diasabledtabs">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Cliente:</span>
				</div>
				<div class="p-col-6">
					<span >{{lblclicodigo + ' - ' + lblcliente}}</span>
				</div>
				<div class="p-col-4">
				</div>
			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largoestcta" [enableSorting]="true" [enableFilter]="true"
			[rowData]="cxctrnresumen" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsCxctrnresumen"
			[defaultColDef]="defaultColDefCxctrnresumen" [rowStyle]="rowStyleSaldo" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>
    </p-tabPanel> 
    
    <p-tabPanel header="Resumen" [disabled]="diasabledtabsR">
        <div class="contorno" id="contorno2">
            <div class="divgrups">
                <div class="p-grid">
                    <!--LINEA 1-->
                    <div class="p-col-1">
                        <span>Cliente:</span>
                    </div>
                    <div class="p-col-3">
                        <label for="lblcliente"></label>
                        <input class="frm" id="lblcliente" type="text" pInputText disabled [(ngModel)]="lblcliente"
                            style="color: blue; font-weight: bold;" autocomplete="off">
                    </div>
                    <div class="p-col-1">
                        <span>Teléfonos:</span>
                    </div>
                    <div class="p-col-2">
                        <label for="lbltelefono"></label>
                        <input class="frm" id="lbltelefono" type="text" pInputText disabled
                            [(ngModel)]="lbltelefono" style="color: blue; font-weight: bold;"
                            autocomplete="off">
                    </div>
                   
                    <div class="p-col-1">
                        <span>Ordenar:</span>
                    </div>
                    <div class="p-col-3">
                            <p-dropdown [options]="opcionesOrderBy" [style]="{'width':'100%'}"
                            optionLabel="name" [panelStyle]="{'width':'110%'}"
                            (onChange)="busqorderby($event)"
                            [(ngModel)]="selecttipoorderby"
                            [disabled] = "true"
                            ></p-dropdown>
                                   
                    </div>
                    <div class="p-col-1">
                        <p-checkbox label="Aplicar" binary="true"
                            (click)="aplicarorderby()" [(ngModel)]="chkaplicaorderby" [disabled] = "true">
                        </p-checkbox>
                    </div>
                    <!--LINEA 2-->
                    <div class="p-col-12" style="margin-top: -10px">
                        <div class="p-grid">
                            <div class="p-col-1">
                                <span>Contacto:</span>
                            </div>
                            <div class="p-col-3">
                                <label for="lblcontacto"></label>
                                <input class="frm" id="lblcontacto" type="text" pInputText disabled
                                    style="color: blue; font-weight: bold;"
                                    [(ngModel)]="lblcontacto" autocomplete="off">
                            </div>   
                            <div class="p-col-1">
                                <span>Vendedor:</span>
                            </div>
                            <div class="p-col-2">
                                <label for="lblvendedor"></label>
                                <input class="frm" id="lblvendedor" type="text" pInputText disabled
                                    style="color: blue; font-weight: bold;"
                                    [(ngModel)]="lblvendedor" autocomplete="off">
                            </div>
                            <div class="p-col-1">
                                <span>Correo:</span>
                            </div>
                            <div class="p-col-3">
                                <label for="lblcorreo"></label>
                                <input class="frm" id="lblcorreo" type="text" pInputText disabled
                                    style="color: blue; font-weight: bold;"
                                    [(ngModel)]="lblcorreo" autocomplete="off">
                            </div>
                        </div>
                    </div>
                     <!--LINEA 3-->
                     <div class="p-col-12" style="margin-top: -10px">
                        <div class="p-grid">
                            <div class="p-col-1">
                                <span>Dirección:</span>
                            </div>
                            <div class="p-col-3">
                                <label for="lbldireccion"></label>
                                <input class="frm" id="lbldireccion" type="text" pInputText disabled
                                    style="color: blue; font-weight: bold;"
                                    [(ngModel)]="lbldireccion" autocomplete="off">
                            </div>
    
                            <div class="p-col-1">
                                <span>Observacion:</span>
                            </div>
                            <div class="p-col-5">
                                <label for="lblobservacion"></label>
                                <input class="frm" id="lblobservacion" type="text" pInputText disabled
                                    style="color: blue; font-weight: bold;"
                                    [(ngModel)]="lblobservacion" autocomplete="off">
                            </div>
                            <!-- <div class="p-col-1">
                                <span>Saldo:</span>
                            </div>
                            <div class="p-col-1">
                                <label for="lblsaldo"></label>
                                <input class="frm" id="lblsaldo" type="text" pInputText disabled
                                    style="text-align: right; color: blue; font-weight: bold;" [(ngModel)]="lblsaldo"
                                    autocomplete="off">
                            </div> -->
                        </div>
                    </div>
                    <!--LINEA 4-->
                    <div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px">
                        <div class="p-grid">
                            <div class="p-col-1">
                                <span>Saldo del Cliente:</span>
                            </div>
                            <div class="p-col-1">
                                <label for="lblsaldoCli"></label>
                                <input class="frm" id="lblsaldoCli" type="text" pInputText disabled
                                    style="text-align: right; color: blue; font-weight: bold;" [(ngModel)]="lblsaldoCli"
                                    autocomplete="off">
                            </div>
    
                            <div class="p-col-1">
                                <span>Saldo de Cartera:</span>
                            </div>
                            <div class="p-col-1">
                                <label for="lblsaldo"></label>
                                <input class="frm" id="lblsaldo" type="text" pInputText disabled
                                    style="text-align: right; color: blue; font-weight: bold;" [(ngModel)]="lblsaldo"
                                    autocomplete="off">
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <app-aggridsaci 
        [width]="" 
        [height]="largoestcta"
        [enableSorting]="true" 
        [enableFilter]="true"
        [rowData]="cxctrncobranzasService.cxctrcobnresumen"
        [frameworkComponents]="frameworkComponents" 
        [animateRows]="true"
        [rowSelection]="rowSelection" 
        [columnDefs]="columnDefsCxcCobtrnresumen"
        [defaultColDef]="defaultColDefCxcCobtrnresumen" 
        [rowStyle]="rowStyle" 
        [botones]="false"
        [singleClickEdit]="false"
        (selected)="seleccionItemCob($event)" ></app-aggridsaci>
	</p-tabPanel> 
</p-tabView>

<p-dialog header="Historial" [(visible)]="displayDialogCxcTrnHis" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width:'80vw', height:'120vh'}" [closable]="false" [draggable]="false">
    <app-cxctrnedithist *ngIf="displayDialogCxcTrnHis" [strnumfac]="trncobro" [clicodigo]="clicodigo"
        [secuencial]="secuncial" (signalSalir)="recibirSenalesCxcEdithis($event)"></app-cxctrnedithist>
</p-dialog>

<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
    <p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
    </p-progressSpinner>
    <!-- <p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
	</p-dialog> -->
</div>
<!-- CDPJ -->
<p-dialog header="Formularios de Correos" [(visible)]="displayDialogfrmcorreos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: '400px'}">
	<app-sacifrmenviocorreos [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
		[strNumDoc]="detallecobSelected.CLI_CODIGO"
		[strCampo]="'CLI_CODIGO'"
		[strNumDoc2]=""
		[strCampo2]="''" 
		[strCliProvCod]="detallecobSelected.CLI_CODIGO"
		(cerrar)="cerrarfrmcorreos()"></app-sacifrmenviocorreos>
</p-dialog>
<p-dialog *ngIf="displayDialogeditdatoscli === true" header="Actualizar Datos del Cliente" [(visible)]="displayDialogeditdatoscli" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'}" >
    <app-cxceditdatoscliente 
    [stCliCodigo]="detallecobSelected.CLI_CODIGO"
	
	(salir)="cerrareditdatoscli()">
	</app-cxceditdatoscliente>
</p-dialog>
<p-dialog *ngIf="displayDialofencfac === true" header="Facturas de Ventas" [(visible)]="displayDialofencfac" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '90vw', height: '90vh'}" >
    <app-cxcconsulfacven 
    [stnumfac]="detalleSelected.TRNCOBRO_NRODOC"
	(salir)="cerrarencfac()">
	</app-cxcconsulfacven>
</p-dialog>
<!-- CDPJ -->