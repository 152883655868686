<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="invconsulotes"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="invconsulotesconf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid">
	<div class="p-col-12">
		<app-invmaebarra [barraBotonesCRUD]="true" [barraBotonesDesplaz]="true" [barraBotones3]="true"
        [barraBotonesAsiCon]="true" [barraBotones5]="true" (signal)="manejarSenales($event)"
		></app-invmaebarra>
	</div>
</div>
<p-tabView (onChange)="onTab1Change($event)" [activeIndex]="selectTab">
	<p-tabPanel header="Lotes">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-1">
					<span style="float: right">Filtro:</span>
				</div>
				<div class="p-col-2">
					<p-dropdown [style]="{'width':'100%'}"
								[(ngModel)]="strFiltroLote"
								optionLabel="name" [options]="cmbFiltroLote"
								[panelStyle]="{'width':'110%'}" (onChange)="cambioDropdown($event)"
								inputId="tipoFiltroLote">
					</p-dropdown>
				</div>
				<div class="p-col-2">
					<div class="p-grid">
						<div class="p-col-8">
							<input id="FILTRO_LOTE" type="text" pInputText autocomplete="off"
								   [(ngModel)]="txtValorFiltro">
						</div>
						<div class="p-col-4">
							<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									class="littlebuttons" (click)="buscarLote(txtValorFiltro)"></button>
						</div>
					</div>
				</div>
				
			</div>
		</div>
		<app-aggridsaci [width]=""
				[height]="'450'"
				[enableSorting]="true"
				[enableFilter]="true"
				[rowData]="detalleLotes"
				[frameworkComponents]="frameworkComponents"
				[animateRows]="true"
				[rowSelection]="rowSelection"
				[columnDefs]="columnDefsDetLotes"
				[defaultColDef]="defaultColDefDetLotes"
				[rowStyle]="rowStyle"
				[singleClickEdit]="false"
				[botones]="false"
				(selected)="selecciondetalle($event)">
		</app-aggridsaci>
    </p-tabPanel>
    <p-tabPanel header="Despachos">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-1">
					<span style="float: right">Filtro:</span>
				</div>
				<div class="p-col-2">
					<p-dropdown [style]="{'width':'100%'}"
								[(ngModel)]="strFiltroDesp"
								optionLabel="name" [options]="cmbFiltroDesp"
								[panelStyle]="{'width':'110%'}" (onChange)="cambioDropdownDesp($event)"
								inputId="tipoFiltroDesp">
					</p-dropdown>
				</div>
				<div class="p-col-2">
					<div class="p-grid">
						<div class="p-col-8" *ngIf="strFiltroDesp.codigo !== 2">
							<input id="FILTRO_DESP" type="text" pInputText autocomplete="off"
								   [(ngModel)]="txtValorFiltroDesp">
						</div>
						<div class="p-col-8" *ngIf="strFiltroDesp.codigo === 2">
							<p-calendar [(ngModel)]="txtFechaDesp"
											dateFormat="dd/mm/yy" [inputStyle]="{'width': '120px'}"
											 inputId="Calendario"></p-calendar>
						</div>
						<div class="p-col-4">
							<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									class="littlebuttons" (click)="buscarDespacho(txtValorFiltroDesp)"></button>
						</div>
					</div>
				</div>
				
			</div>
		</div>
		<app-aggridsaci [width]=""
				[height]="'450'"
				[enableSorting]="true"
				[enableFilter]="true"
				[rowData]="detalleDespacho"
				[frameworkComponents]="frameworkComponents"
				[animateRows]="true"
				[rowSelection]="rowSelection"
				[columnDefs]="columnDefsDetDespacho"
				[defaultColDef]="defaultColDefDetDespacho"
				[rowStyle]="rowStyle"
				[singleClickEdit]="false"
				[botones]="false">
		</app-aggridsaci>

    </p-tabPanel>
</p-tabView>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast1"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>