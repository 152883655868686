import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import { SegmaeReferencia } from '../seginterfaces/segmaereferencia';
@Injectable({
  providedIn: 'root'
})
export class SegmaereferenciaService {

  public way: string;

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario, private confIniciales: ConfInicialesService) { 
      this.way = sessionStorage.getItem('way');
    this.usuario.identificacion;
    }

  consultarReferencias(){
    let strSentencia='select * from saci_maetiporef order by tip_codigo'
    return this.http.post<any[]>(this.way + '/seg/referencia/3317e7abb73ddc', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  consultarReferenciaSelect(where:string){
    console.log(where);
    const parametro1 = 'T';
    
    /* let condicion =  ' com_codigo='+ '01' +' and  tip_Codigo is not null ';
    if (where != null) {
      condicion += ' AND ' + where;
    } */
    let condicion:string;
    if(where!=null || where!=''){
      condicion=`REF_TIPO = '${where}'`;
    }else{
      condicion=' REF_TIPO IS NOT NULL';
    }
    const parametro3 = '';
    console.log(condicion);
    return this.http.post<any[]>(this.way + '/seg/referencia/662fcdb9eedf1c0', {
      elementos: {
        p_REF_tipo:parametro1 , // varchar,    
        p_REF_orden: condicion, //  varchar,  
        p_REF_selecc:  parametro3, //  varchar,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  insertarReferencia(valor:string,codigo:SegmaeReferencia){
    console.log(valor);
    console.log(codigo);
    return this.http.post<any[]>(this.way + '/seg/referencia/12bf1dbeedcf77000', {
      elementos: {
        p_REF_tipo_1: valor, // varchar,    
        p_REF_codigo_2:codigo.Código, //  varchar,  
        p_REF_nombre_3: codigo.Nombre , //  varchar,
        p_REF_nombrec_4:codigo["Nombre Corto"],
        p_com_codigo:'01',
        p_REF_valor :codigo.Valor
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarReferencia(valor:string,codigo:SegmaeReferencia){
    return this.http.post<any[]>(this.way + '/seg/referencia/533bedb9eedbb00', {
      elementos: {
        p_REF_tipo_1:valor,
        p_REF_codigo_2:codigo.Código,
        p_REF_tipo_3:valor,
        p_REF_codigo_4:codigo.Código,
        p_REF_nombre_5:codigo.Nombre,
        p_REF_nombrec_6:codigo["Nombre Corto"],
        p_com_codigo:'01',
        p_REF_valor:codigo.Valor,

      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eliminarReferencia(valor:string,codigo:SegmaeReferencia){
    return this.http.post<any[]>(this.way + '/seg/referencia/3ab2b6dcf76dd8', {
      elementos: {
        p_REF_tipo_1:valor,
        p_REF_codigo_2:codigo.Código,
        p_com_codigo:'01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

}
