import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConMaeImpuesto, SaciMaeNumDec, VenMaeImpuesto,SacieImpuestoSri} from '../seginterfaces/sacimaeconfiguracion';
import {MessageService} from 'primeng';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class SacimaeconfiguracionService {
  
  public detConMaeImpArray: ConMaeImpuesto[][] = [];
  public detSaciMaeNumDecArray: SaciMaeNumDec[][] = [];
  public detVenMaeImpArray: VenMaeImpuesto[][] = [];
  public detMaeImpSriArray: SacieImpuestoSri[][]=[];//CDPJ
  private url: string;
  private way: string;
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, private messageService: MessageService,
              private init: NuevoComponentService,
              private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }
  
  presentarValoresInventario(strSentencia) {
    return this.http.post<any[]>(this.way + '/seg/configuracion/c2faebb3177ca00', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  actualizarImpuestos(strConCodigo, strCodigo) {
    return this.http.post<any[]>(this.way + '/seg/configuracion/a677caacad9f3800', {
        elementos: {
          CON_CODIGO: strConCodigo,
          CODIGO: strCodigo
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  validarCuentaCont(strConCodigo) {
    return this.http.post<any[]>(this.way + '/seg/configuracion/faaacf3afd66300', {
        elementos: {
          CON_CODIGO: strConCodigo
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  actualizarMaeNumDec(numdec, numdeccampo, numdecforma, numdectipdoc) {
    return this.http.post<any[]>(this.way + '/seg/configuracion/533bebfacd73240', {
        elementos: {
          NUMDEC_NUMDEC: numdec,
          NUMDEC_CAMPO: numdeccampo,
          NUMDEC_FORMA: numdecforma,
          NUMDEC_TIPDOC: numdectipdoc
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  actualizarProcentImpuesto(impporcentaje, impvalor, imptipo, imptarifa) {
    return this.http.post<any[]>(this.way + '/seg/configuracion/533b9c6d8d73240', {
        elementos: {
          IMP_PORCENTAJE: impporcentaje,
          IMP_VALOR: impvalor,
          IMP_TIPO: imptipo,
          IMP_TARIFA: imptarifa
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  actualizarValorLista(strValor3, strCodigo) {
    return this.http.post<any[]>(this.way + '/seg/configuracion/a677eaae37597000', {
        elementos: {
          CFG_VALOR3: strValor3,
          CFG_CODIGO: strCodigo
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  actualizarValor3(strValor3, strCodigo) {
    return this.http.post<any[]>(this.way + '/seg/configuracion/a677caacbeaae000', {
        elementos: {
          CFG_VALOR3: strValor3,
          CFG_CODIGO: strCodigo
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  actualizarValor2(strValor2, strCodigo) {
    return this.http.post<any[]>(this.way + '/seg/configuracion/299df2ab2faaa2', {
        elementos: {
          CFG_VALOR2: strValor2,
          CFG_CODIGO: strCodigo
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  actualizarValor1(strValor1, strCodigo) {
    return this.http.post<any[]>(this.way + '/seg/configuracion/299df2ab2faaa0', {
        elementos: {
          CFG_VALOR1: strValor1,
          CFG_CODIGO: strCodigo
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  getConMaeImpuestos() {
    return this.http.post<any[]>(this.way + '/seg/configuracion/83bacc5eca74b00', {
        elementos: {
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  getSaciMaeNumDec() {
    return this.http.post<any[]>(this.way + '/seg/configuracion/83bb653afeb3600', {
        elementos: {
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  getVenMaeImpuestos() {
    return this.http.post<any[]>(this.way + '/seg/configuracion/20eefeca95b3e80000', {
        elementos: {
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  erCliNombre1(strCliCodigo) {
    return this.http.post(this.way + '/seg/configuracion/76d9595f165edc0', {
      elementos: {
        CLI_CODIGO: strCliCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erCliNombre2(strCliCodigo) {
    return this.http.post(this.way + '/seg/configuracion/3b6cacaf8b2f62', {
      elementos: {
        CLI_CODIGO: strCliCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erGruNombre1(strGruCodigo) {
    return this.http.post(this.way + '/seg/configuracion/3b70dfaf8b2f60', {
      elementos: {
        GRU_CODIGO: strGruCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erGruNombre2(strGruCodigo) {
    return this.http.post(this.way + '/seg/configuracion/3b70dfaf8b2f62', {
      elementos: {
        GRU_CODIGO: strGruCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erConNombre1(strConCodigo) {
    return this.http.post(this.way + '/seg/configuracion/1db662f7c5961', {
      elementos: {
        CON_CODIGO: strConCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erConNombre2(strConCodigo) {
    return this.http.post(this.way + '/seg/configuracion/1db662f7c5962', {
      elementos: {
        CON_CODIGO: strConCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erVenMaeCliente(strCliCodigo) {
    return this.http.post(this.way + '/seg/configuracion/76feebd94e65640', {
      elementos: {
        CLI_CODIGO: strCliCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erVenMaeGrupo(strGruCodigo) {
    return this.http.post(this.way + '/seg/configuracion/76feebd94e86fc0', {
      elementos: {
        GRU_CODIGO: strGruCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erConMaeCon(strConCodigo) {
    return this.http.post(this.way + '/seg/configuracion/3b6cc5eca73318', {
      elementos: {
        CON_CODIGO: strConCodigo
      },
    }, this.confIniciales.httpOptions());
  }
  
  async validarCuentaContable(itemSel: ConMaeImpuesto) {
    if (itemSel.CON_CODIGO !== '') {
      const cuenta = await this.validarCuenta(itemSel.CON_CODIGO);
      console.log(cuenta);
      if ( cuenta === false ) {
        this.mensajeAlerta('info', 'Información', 'La cuenta contable no existe o es una Cuenta de Mayor');
        this.detConMaeImpArray[this.init.tabs[this.init.tabindex].indice].map(item => {
          if (itemSel.CODIGO  === item.CODIGO) {
            item.CON_CODIGO = '';
            this.actualizarCodContable(item);
          }
        });
      } else {
        this.actualizarCodContable(itemSel);
      }
    }
  }
  
  async actualizarCodContable(itemSel: ConMaeImpuesto) {
    let codCtble = '';
    let codImpuesto;
    
    codCtble = itemSel.CON_CODIGO;
    codImpuesto = itemSel.CODIGO;
    
    try {
      let consulta = '';
      consulta = 'update con_maeimpuestos set con_codigo= \'' + codCtble + '\' where codigo = \'' + codImpuesto + '\'';
      await this.actualizarImpuestos(codCtble, codImpuesto);
    } catch (err) {
      this.mensajeAlerta('info', 'Información', 'No se ha grabado correctamente');
      return;
      
    }
    
  }
  
  async validarCuenta(cuenta) {
    let consulta = '';
    let resultado = false;
    
    consulta = 'SELECT CON_FNC_VALIDARCUENTA(\'' + cuenta + '\'' + ',' + '\'01\'' + ') as resultado'
      + ' FROM DUAL';
    console.log(consulta);
    try {
      const rs = await this.validarCuentaCont(cuenta);
      if (rs != null) {
        console.log(rs[0].RESULTADO);
        if (rs[0].RESULTADO === 1) {
          resultado = false;
        } else if (rs[0].RESULTADO === 0) {
          resultado = true;
        }
      }
    } catch (err) {}
    console.log(resultado);
    return resultado;
  }
  
  async actualizarNumDec(item: SaciMaeNumDec) {
    let intNumDecimales;
    let strNumDecimalCampo; let strDecimalForma; let strDecTipoDoc;
    
    intNumDecimales = item.NUMDEC_NUMDEC;
    strNumDecimalCampo = String(item.NUMDEC_CAMPO).trim();
    strDecimalForma = String(item.NUMDEC_FORMA).trim();
    strDecTipoDoc = String(item.NUMDEC_TIPDOC).trim();
    
    if (String(intNumDecimales) !== '') {
      try {
        let consulta = '';
        consulta = 'update SACI_MAENUMDEC set numdec_numdec='  + intNumDecimales + ' where numdec_campo = \'' + strNumDecimalCampo
          + '\' and numdec_forma = \'' + strDecimalForma + '\' and numdec_tipdoc = \'' + strDecTipoDoc + '\'';
        
        await this.actualizarMaeNumDec(intNumDecimales, strNumDecimalCampo, strDecimalForma,  strDecTipoDoc);
        this.mensajeAlerta('success', 'Infomación', 'Registro actualizado exitosamente... !!!');
        
      } catch (err) {
        this.mensajeAlerta('error', 'Error', 'No se ha grabado correctamente');
        return;
        
      }
    }
  }
  
  async actualizarPorImp(item: VenMaeImpuesto) {
    let strImpuestoTipo; let strImpuestoTarifa;
    let fltImpPorcentaje; let fltImpValor;
    
    
    fltImpPorcentaje = Number(item.IMP_PORCENTAJE);
    fltImpValor = Number(item.IMP_VALOR);
    strImpuestoTipo = item.IMP_TIPO;
    strImpuestoTarifa = item.IMP_TARIFA;
    
    try {
      let consulta = '';
      consulta = 'update VEN_MAEIMPUESTO set imp_porcentaje= ' + fltImpPorcentaje +  ', imp_valor = ' + fltImpValor
        + ' where imp_tipo = \'' + strImpuestoTipo + '\' and imp_tarifa = \'' + strImpuestoTarifa + '\'';
      
      await this.actualizarProcentImpuesto(fltImpPorcentaje, fltImpValor, strImpuestoTipo, strImpuestoTarifa);
      this.mensajeAlerta('success', 'Infomación', 'Registro actualizado exitosamente... !!!');
    } catch (err) {
      this.mensajeAlerta('error', 'Error', 'No se ha grabado correctamente');
      return;
    }
  }
  
  mensajeAlerta(tipo, titulo, msg) {
    this.messageService.add({
      key: 'saciMaeConfig',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
  //CDPJ
  genMaeImpuestosSri() {
    return this.http.post<any[]>(this.way + '/seg/configuracion/3317e7ab653a5600000', {
        elementos: {
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  eliminarimpsri(impcod) {
    return this.http.post<any[]>(this.way + '/seg/configuracion/7565695d5be6e400000000000', {//CAMBIAR
        elementos: {
          p_tariiva_codigo:impcod
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  guardarimp(impsri:SacieImpuestoSri) {
    return this.http.post<any[]>(this.way + '/seg/configuracion/12bf1dbe6e4f7700000000', {
        elementos: {
          p_tariiva_codigo:impsri.TARIIVA_CODIGO,
          p_tariiva_porcentaje:impsri.TARIIVA_PORCENTAJE,
          p_tariiva_descripcion:impsri.TARIIVA_DESCRIPCION,
          p_tariiva_activo:impsri.TARIIVA_ACTIVO
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  actualizaimp(impsri:SacieImpuestoSri) {
    return this.http.post<any[]>(this.way + '/seg/configuracion/299df2ab2', {
        elementos: {
          p_tariiva_codigo:impsri.TARIIVA_CODIGO,
          p_tariiva_porcentaje:impsri.TARIIVA_PORCENTAJE,
          p_tariiva_descripcion:impsri.TARIIVA_DESCRIPCION,
          p_tariiva_activo:impsri.TARIIVA_ACTIVO
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  //CDPJ
}
