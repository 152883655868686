import { Component, OnInit,ViewChild } from '@angular/core';
import { SegmaetiprefService } from '../segservicios/segmaetipref.service';
import { SegMaeConsultaTipoRef } from '../seginterfaces/segmaetipref';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import { AggridBanComponent } from 'src/app/ban/aggrid-ban/aggrid-ban.component';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';


@Component({
  selector: 'app-segmaetipref',
  templateUrl: './segmaetipref.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class SegmaetiprefComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  defaultColDefSegConsultaTipoRef;
  public frameworkComponents;
  rowStyle;
  largo = '258';
  rowSelection = 'multiple';
  segmaeConsultaTipoRef: SegMaeConsultaTipoRef[] = [];
  selectedsegmaeConsultaTipoRef: SegMaeConsultaTipoRef; 
 
  opcion: string;
  types: SelectItem[];
  
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  condicion: string;
  displayDialogBusquedaFast: boolean;

  btnGuardar: boolean;
  btnNuevo = true;
  btnBorrar: boolean;
  btnRegresar: boolean;
  btnBuscar: boolean;
  btnRecargar:boolean;
  columnDefMaeConsultaTipoRef = [
    {
      headerName: '', field: 'NUMERO', editable: false, width: 50,
    },
    {
      headerName: 'Código.', field: 'TIP_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Descripción', field: 'TIP_DESCRIPCION', cellEditor: 'cellPrueba', width: 400, editable:true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 120,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Editable', field: 'EDITABLE',cellRendererFramework: AggridBanComponent, editable:false, width: 100, onCellClicked: (params) => {
        this.seleccionItem(params.data);  
      }
    },

  ]
  constructor( private segmaetiprefService: SegmaetiprefService,
               private messageService: MessageService,
               private errorService: ErroresBaseDatosService,
               private confirmationService: ConfirmationService,
               private auditoriaGralService: AuditoriagralService,
               private init: NuevoComponentService
              ) { 
                this.agTable();
              }

  ngOnInit(): void {
    this.btnNuevo =  false;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.btnRegresar = false;
    this.btnBuscar = false;
    this.btnRecargar=false;
    this.segmaetiprefService.consultaRef(null).then(res=>{
      this.segmaeConsultaTipoRef=res;
      
      this.segmaeConsultaTipoRef.map( x => {
        x.NUMERO = this.segmaeConsultaTipoRef.indexOf( x ) + 1;
        x.EDITABLE= (x.TIP_EDITABLE==1)?true:false;
        x.NUEVO=false;
      } );
    }).catch(e=>console.log(e.error));
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefSegConsultaTipoRef = {
      editable: true,
      width: 130,
      objeto: 'maeConsultaTipoRef'
    };
  }

  selectMaeConsultaTipoRef(elemento){ 
     
      if (elemento.object !== null) {
        this.selectedsegmaeConsultaTipoRef = elemento.object;
        this.selectedsegmaeConsultaTipoRef.TIP_EDITABLE=(this.selectedsegmaeConsultaTipoRef.EDITABLE==true)?1:0;
        this.segmaeConsultaTipoRef[this.selectedsegmaeConsultaTipoRef.NUMERO-1].TIP_EDITABLE=(this.selectedsegmaeConsultaTipoRef.EDITABLE==true)?1:0;
       
      }
       
  }
  cambioModulo(event){ 
    this.btnGuardar = false;
    this.btnRegresar = false;
    this.btnNuevo = true;
  }
  seleccionItem(param){
    
    this.selectedsegmaeConsultaTipoRef=param;
    this.btnNuevo =  true;
    this.btnGuardar = false;
    this.btnBorrar  = false;
    this.btnRegresar = false;
  
    this.selectedsegmaeConsultaTipoRef.TIP_EDITABLE=(this.selectedsegmaeConsultaTipoRef.EDITABLE==true)?1:0;
        this.segmaeConsultaTipoRef[this.selectedsegmaeConsultaTipoRef.NUMERO-1].TIP_EDITABLE=(this.selectedsegmaeConsultaTipoRef.EDITABLE==true)?1:0;
    
  }
  manejarSenales(valor){ 
    switch (valor) {
      case 'Nuevo':
        this.opcionNuevo();
        break;
      case 'Guardar':
        this.opcionGuardar();
        break;
      case 'Borrar':
        this.opcionEliminar();
        break;
      case 'Cancelar':
        this.cargar();
        break;
      case 'Buscar':
        this.buscarRegistro();
        break;
      case 'Salir':
       this.init.tabs.splice(this.init.tabindex, 1);
        break;
    
    }
  }
  opcionNuevo(){
    this.btnNuevo = true;
    this.btnBorrar = false;
    this.btnRegresar = false;
    this.aggrid.isEditable(true);
    this.nuevoModulo();
  }
  nuevoModulo() {
      const newModulo:  SegMaeConsultaTipoRef= {  
        COM_CODIGO:'',
        TIP_CODIGO:'',
        TIP_DESCRIPCION:'',
        TIP_EDITABLE:'',
        NUMERO:this.segmaeConsultaTipoRef.length +1,
        EDITABLE:false,
        NUEVO:true
    };
    
    this.segmaeConsultaTipoRef.push(newModulo); 
    this.aggrid.refreshaggrid(this.segmaeConsultaTipoRef, this.defaultColDefSegConsultaTipoRef.objeto ); 
    const indice= this.segmaeConsultaTipoRef.indexOf(newModulo);
    this.aggrid.gridApi.setFocusedCell(indice , 'TIP_CODIGO');
    
  }
  opcionGuardar() {
    let paquete = '';
  
    const modulo: SegMaeConsultaTipoRef = {
        
        TIP_CODIGO:  this.selectedsegmaeConsultaTipoRef.TIP_CODIGO,
        TIP_DESCRIPCION: this.selectedsegmaeConsultaTipoRef.TIP_DESCRIPCION,
        EDITABLE: this.selectedsegmaeConsultaTipoRef.TIP_EDITABLE
       };
       let valido = true;
      
    for ( const key in modulo ) { 
      if ( modulo[key] === null || modulo[key] === '') {
        valido = false;
      }
    }
    if ( valido ) {
      
      if(this.selectedsegmaeConsultaTipoRef.NUEVO==true){
        this.aggrid.gridApi.stopEditing();
        paquete='SACI_PCK_MAETIPREF.SACI_SP_insertarTipRef';
        this.segmaetiprefService.ingresarTipoRef(paquete,modulo.TIP_CODIGO,modulo.TIP_DESCRIPCION,modulo.EDITABLE).then(()=>{
          this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
          this.cargar();
          this.auditoriaGralService.registrarAuditoria('SACI_MAETIPOREF', String(this.selectedsegmaeConsultaTipoRef.TIP_CODIGO), 'I',
          '', '01', '', '', '', '').toPromise().then(() => {
          });
        }).catch(e=>{
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        }); 
      
      }else if(this.selectedsegmaeConsultaTipoRef.NUEVO==false){
      
        this.aggrid.gridApi.stopEditing();
        paquete='SACI_PCK_MAETIPREF.SACI_SP_actualizaTipRef';
        this.segmaetiprefService.actualizarTipoRef(paquete,this.selectedsegmaeConsultaTipoRef.TIP_CODIGO,this.selectedsegmaeConsultaTipoRef.TIP_DESCRIPCION,this.selectedsegmaeConsultaTipoRef.EDITABLE).then(res=>{
          this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
          this.cargar();
          this.auditoriaGralService.registrarAuditoria('SACI_MAETIPOREF', String(this.selectedsegmaeConsultaTipoRef.TIP_CODIGO), 'A',
          '', '01', '', '', '', '').toPromise().then(() => {
          });
        }).catch(e=>{
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        }); 
          
      }
 
    }else{
     
      this.mensajeAlerta('info', 'Información', 'Se han encontrado campos vacíos');
    }
  }
opcionEliminar(){
  let paquete='SACI_PCK_MAETIPREF.SACI_SP_eliminaTipRef';
  if ( this.selectedsegmaeConsultaTipoRef !== undefined ) {
    this.confirmationService.confirm({
      message: '¿Está seguro que desea eliminar este registro?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'segOrgDialog',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        this.segmaetiprefService.eliminarTipoRef(paquete, this.selectedsegmaeConsultaTipoRef.TIP_CODIGO).then(res=>{
          this.mensajeAlerta('success', 'Información', '¡Modulo eliminado!');
          this.cargar();
          this.auditoriaGralService.registrarAuditoria('SACI_MAETIPOREF', String(this.selectedsegmaeConsultaTipoRef.TIP_CODIGO), 'E',
          '', '01', '', '', '', '').toPromise().then(() => {
          });
        }).catch(e=>{
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        }); 
      },
      reject: () => {
      }
    })
  }
}
  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'segMaeOrgtoast',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
    
  }

  async cargar() {
    this.btnNuevo =  false;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.btnRegresar = false;
    this.btnBuscar = false;
    this.segmaetiprefService.consultaRef(null).then(res=>{
      this.segmaeConsultaTipoRef=res;
      
      this.segmaeConsultaTipoRef.map( x => {
        x.NUMERO = this.segmaeConsultaTipoRef.indexOf( x ) + 1;
        x.EDITABLE= (x.TIP_EDITABLE==1)?true:false;
        x.NUEVO=false;
      } );
    }).catch(e=>console.log(e));
  }
  buscarRegistro() {
    this.opcion = 'BUSCAR';
    this.types = [
      {label: 'CODIGO', value: 'TIP_CODIGO'},
      {label: 'DESCRIPCION', value: 'TIP_DESCRIPCION'},
      {label: 'EDITABLE', value: 'TIP_EDITABLE'},
    ];
    
    this.busquedacampos = ['', '', ''];
    this.consulta = '*';
    this.tabla = 'SACI_MAETIPOREF';
    this.condicion = '';
    this.arregloCons = [];
    this.displayDialogBusquedaFast = true; 
  }

  
  manejarSeleccion(valor) {
    
    if (this.opcion === 'BUSCAR') {
      this.displayDialogBusquedaFast = false;
      this.llenarTabla(valor);
     
    }
  }
  llenarTabla(datos){
    let cont = 0;
    const maeConsultaTipoRef: SegMaeConsultaTipoRef[] = [];
    const passegmaeConsultaTipoRef: SegMaeConsultaTipoRef = {};
   for (const dato of datos) {
      cont++;
      passegmaeConsultaTipoRef.COM_CODIGO=dato.COM_CODIGO;
      passegmaeConsultaTipoRef.TIP_CODIGO=dato.TIP_CODIGO;
      passegmaeConsultaTipoRef.TIP_DESCRIPCION=dato.TIP_DESCRIPCION;
      passegmaeConsultaTipoRef.TIP_EDITABLE=dato.TIP_EDITABLE;
      maeConsultaTipoRef.push(passegmaeConsultaTipoRef);

    } 
    this.segmaeConsultaTipoRef=maeConsultaTipoRef;
    this.segmaeConsultaTipoRef.map( x => {
      x.NUMERO = this.segmaeConsultaTipoRef.indexOf( x ) + 1;
      x.EDITABLE= (x.TIP_EDITABLE==1)?true:false;
      x.NUEVO=false;
    } );
  }
}
