import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {MessageService} from 'primeng';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnexgenerarxmlService {
  
  private way: string;
  
  public proceso = false;
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, private confIniciales: ConfInicialesService,
              public message: MessageService) {
    this.way = sessionStorage.getItem('way');

  }
  
  
  totalVentas(parametros: any[]) {
    return this.http.post<any[]>(this.way + '/anex/xml/771d557eebf55c', {
      elementos: {
        p_perInf: parametros[0],
        p_anio: parametros[1],
        p_com_codigo: parametros[2],
        p_solofacturasFisicas: parametros[3],
        p_microempresa: parametros[4],
      }
    },
      this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerCompania() {
    return this.http.post<any[]>(this.way + '/anex/xml/c2fae662d955e80', {
        elementos: {},
      
      },
      this.confIniciales.httpOptions()).toPromise();
  }
  
  consultarTrnCompras(anios, strMeses) {
    
    return this.http.post<any[]>(this.way + '/anex/xml/662fcf57bbbb300', {
      elementos: {
        anio: anios,
        meses: strMeses
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  tipoAmbienteFactEle() {
    return this.http.post<any[]>(this.way + '/anex/xml/ecb2ab2dea63a80', {
        elementos: {},
        
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  pagoExteriorDvc(numDev) {
    return this.http.post<any[]>(this.way + '/anex/xml/caa18737ecc5b00', {
        elementos: {
          numero: numDev
        }
        
      }, this.confIniciales.httpOptions()).toPromise();
  }
  
  pagoExteriorFP(numDev) {
    return this.http.post<any[]>(this.way + '/anex/xml/caa1873d4ccef00', {
        elementos: {
          numero: numDev
        },
       
      }, this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerCobroImporte(numero) {
    return this.http.post<any[]>(this.way + '/anex/xml/6617bc4ad9c6fc0', {
        elementos: {
          nrodoc: numero
        },
        
      }, this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerTrnCobro(numDoc) {
    return this.http.post<any[]>(this.way + '/anex/xml/c2faeeeeecc2f80', {
        elementos: {
          nrodoc: numDoc
        },
      }, this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerFactPro(strReferencia, strSerie, strProCodigo) {
    return this.http.post<any[]>(this.way + '/anex/xml/c2fae7a99dcef00', {
        elementos: {
          referencia: strReferencia,
          serie: strSerie,
          pro_codigo: strProCodigo
        },
        
      }, this.confIniciales.httpOptions()).toPromise();
  }
  
  verificaFormaPagoCXP(strNumDoc) {
    return this.http.post<any[]>(this.way + '/anex/xml/fbb727eed954300', {
        elementos: {
          nrodoc: strNumDoc
        },
        
      }, this.confIniciales.httpOptions()).toPromise();
  }
  
  verificaFormaPago(strReferencia, strSerie, strProCodigo) {
    return this.http.post<any[]>(this.way + '/anex/xml/fbb727e376caa00', {
        elementos: {
          referencia: strReferencia,
          serie: strSerie,
          pro_codigo: strProCodigo
        },
      
      }, this.confIniciales.httpOptions()).toPromise();
  }
  
  verificaRetencionesEsp(strReferencia, strSerie, strProCodigo) {
    return this.http.post<any[]>(this.way + '/anex/xml/7ddb93f6eebb98', {
        elementos: {
          referencia: strReferencia,
          serie: strSerie,
          pro_codigo: strProCodigo
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  autorizacionElectronica(intNumLinea, strPerInf) {
    return this.http.post<any[]>(this.way + '/anex/xml/2bddc6e4eab99c', {
        elementos: {
          numlinea: intNumLinea,
          perinf: strPerInf
        },
        
      }, this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerRetCodsri(strRetCodigo) {
    return this.http.post<any[]>(this.way + '/anex/xml/c2fbb775986f380', {
        elementos: {
          ret_codigo: strRetCodigo
        },
      
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  verificarReembolsos(intReferencia, strSerie, strProCodigo) {
    return this.http.post<any[]>(this.way + '/anex/xml/fbb727edceb2f00', {
        elementos: {
          referencia: Number(intReferencia),
          serie: strSerie,
          pro_codigo: strProCodigo
        },
      
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  obtenerReembolsos(intReferencia, strSerie, strProCodigo) {
    return this.http.post<any[]>(this.way + '/anex/xml/617d76dceb2f14', {
        elementos: {
          referencia: intReferencia,
          serie: strSerie,
          pro_codigo: strProCodigo
        },
       
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  obtenerReembolsosFp(intReferencia, strSerie, strProCodigo, strIdePro, strSerieEst, strPtoEmi, strComPvta) {
    return this.http.post<any[]>(this.way + '/anex/xml/617d76dceb2df8', {
        elementos: {
          referencia: intReferencia,
          serie: strSerie,
          pro_codigo: strProCodigo,
          ide_pro: strIdePro,
          serie_est: strSerieEst,
          serie_ptoemi: strPtoEmi,
          sec_compvta: strComPvta
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  obtenerXML(objet) {
    return this.http.post<any[]>(this.way + '/anex/xml/30bebaeedb70c', {
        elementos: {
          objeto: objet
        },
        
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  getFormaPagoCXP(strNumDoc) {
    return this.http.post<any[]>(this.way + '/anex/xml/1f8dd9595430c', {
        elementos: {
          nrodoc: strNumDoc
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  getFormaPago(intReferencia, strSerie, strProCodigo) {
    return this.http.post<any[]>(this.way + '/anex/xml/fc6ecacaa18', {
        elementos: {
          referencia: intReferencia,
          serie: strSerie,
          pro_codigo: strProCodigo
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  obtenerCodSri(strCodigo) {
    return this.http.post<any[]>(this.way + '/anex/xml/30beb9986f372', {
        elementos: {
          codsri_ref: strCodigo
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  consultarTrnVentas(anios, strMeses) {
    return this.http.post<any[]>(this.way + '/anex/xml/cc5f9dddfeebf800', {
      elementos: {
        anio: anios,
        meses: strMeses
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getExportacionesDevVentas (strFechaini, strFechafin) {
    return this.http.post<any[]>(this.way + '/anex/xml/41dd765aeffdd8', {
      elementos: {
        fechaIni: strFechaini,
        fechaFin: strFechafin
      },
    }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  getFormaPagoVentas(strClirucide)  {
    return this.http.post<any[]>(this.way + '/anex/xml/41dd7e550fbafc', {
        elementos: {
          cli_rucide: strClirucide
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  getExportacionesVentas(intAnio, strMeses)  {
    return this.http.post<any[]>(this.way + '/anex/xml/41dd767eebf55c', {
        elementos: {
          anio: intAnio,
          meses: strMeses
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  ejecutarSentencia1(strFechaini, strfechaFin)  {
    return this.http.post<any[]>(this.way + '/anex/xml/3a6ee3afd75d80', {
        elementos: {
          fechaIni: strFechaini,
          fechaFin: strfechaFin
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  ejecutarSentencia2(strFechaini, strfechaFin)  {
    return this.http.post<any[]>(this.way + '/anex/xml/3a6ee3afd75d82', {
        elementos: {
          fechaIni: strFechaini,
          fechaFin: strfechaFin
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  ejecutarSentencia3()  {
    return this.http.post<any[]>(this.way + '/anex/xml/3a6ee3afd75d84', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  ejecutarSentencia4()  {
    return this.http.post<any[]>(this.way + '/anex/xml/3a6ee3afd75d834', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }


  totalVentasXSerie(strParams) {
    return this.http.post<any[]>(this.way + '/anex/xml/771dfbafc76e50', {
      elementos: {
        p_perInf: strParams[0],
        p_anio: strParams[1],
        p_serie: strParams[2],
        p_com_codigo: strParams[3],
        p_solofacturasFisicas: strParams[4],
        p_microempresa: strParams[5],
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  exportacionDe(strParams) {
    return this.http.post<any[]>(this.way + '/anex/xml/10775aaebb8cce000', {
      elementos: {
        p_encfac_numero: strParams[0],
        p_com_codigo: strParams[1],
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDatosExportacionConRefrendo(strParams) {
    return this.http.post<any[]>(this.way + '/anex/xml/10775aaebb8cce000', {
      elementos: {
        p_encfac_numero: strParams[0],
        p_com_codigo: strParams[1],
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDatosExportacionSinRefrendo(strParams) {
    return this.http.post<any[]>(this.way + '/anex/xml/10775aaebb8cce000', {
      elementos: {
        p_encfac_numero: strParams[0],
        p_com_codigo: strParams[1],
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDatosExportacion03(strParams) {
    return this.http.post<any[]>(this.way + '/anex/xml/10775aaebb8cce000', {
      elementos: {
        p_encfac_numero: strParams[0],
        p_com_codigo: strParams[1],
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  exportacionDeDev(strParams) {
    return this.http.post<any[]>(this.way + '/anex/xml/83bad575c01b600', {
      elementos: {
        p_encdvf_numero: strParams[0],
        p_com_codigo: strParams[1]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDatosExportacionConRefrendoDev(strParams) {
    return this.http.post<any[]>(this.way + '/anex/xml/83bad575c01b600', {
      elementos: {
        p_encdvf_numero: strParams[0],
        p_com_codigo: strParams[1]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDatosExportacionSinRefrendoDev(strParams): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/anex/xml/83bad575c01b600', {
      elementos: {
        p_encdvf_numero: strParams[0],
        p_com_codigo: strParams[1]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDatosExportacion03Dev(strParams): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/anex/xml/83bad575c01b600', {
      elementos: {
        p_encdvf_numero: strParams[0],
        p_com_codigo: strParams[1]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  consultaAnulados(anios, strMeses): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/anex/xml/cc5f9dddd57f5800', {
      elementos: {
        anio: anios,
        meses: strMeses
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
}
