import { Injectable } from '@angular/core';
import {Maecategoria} from '../interfaces/maecategoria';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UsuarioEsquema} from '../usuarioEsquema';
import {Usuario} from '../usuario';
import {Maesubcategoria} from '../interfaces/maesubcategoria';
import { ConfInicialesService } from './conf-iniciales.service';



@Injectable()

export class MaecategoriaService {

  public way: string;
  public CAT_CODIGO: string;
  public respuesta: any;
  constructor(private http: HttpClient, public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, private confIniciales: ConfInicialesService) {
    this.usuarioEsquema.loadStorage();
    this.usuario.loadStorage();
  
    this.way = sessionStorage.getItem('way');
  }

  getMaeCategorias(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/pdv/categoria/107758aeba18dd000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  agregarCategoria(categoria: Maecategoria) {
    this.http.post(this.way + '/pdv/categoria/5436e82b6c575c0', {
      elementos: {
        CAT_CODIGO: categoria.CAT_CODIGO,
        COM_CODIGO: '01',
        CAT_NOMBRE: categoria.CAT_NOMBRE,
        CAT_CORTO: categoria.CAT_CORTO
      }
    }, this.confIniciales.httpOptions()).subscribe(respuesta => {
      console.log(respuesta);
      console.log('Categoria Insertada');
    }, error => {
      console.log(error);
      console.log('Error');
    });


  }
  actualizarCategoria(categoria: Maecategoria) {
    this.http.post(this.way + '/pdv/categoria/a677cc575d0c7000', {
      elementos: {
        CAT_NOMBRE: categoria.CAT_NOMBRE,
        CAT_CORTO: categoria.CAT_CORTO,
        CAT_CODIGO: categoria.CAT_CODIGO
      }
    }, this.confIniciales.httpOptions()).subscribe(respuesta => {
      console.log(respuesta);
      console.log('Categoria actualizada');
    }, error => {
      console.log(error);
      console.log('Error');
    });
  }

  eliminarCategoria(categoria: Maecategoria) {
    this.http.post(this.way + '/pdv/categoria/eacad2bab6c57800', {
      elementos: {
        CAT_CODIGO: categoria.CAT_CODIGO
      }
    }, this.confIniciales.httpOptions()).subscribe(respuesta => {
      console.log(respuesta);
      console.log('Categoria eliminada');
    }, error => {
      console.log(error);
      console.log('Error');
    });
  }
  recogerCategoria(subCat: Maesubcategoria): Observable<any[]> {
    this.CAT_CODIGO = subCat.CAT_CODIGO.toString();
    return this.http.post<any[]>(this.way + '/pdv/categoria/1b7331076d8aec000', {
      elementos: {
        CAT_CODIGO: subCat.CAT_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }

  recogerCATCODIGO() {
    return this.CAT_CODIGO.toString();
  }


}

