import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Cxccomision} from '../cxcinterfaces/cxccomision';
import {Observable} from 'rxjs';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';


@Injectable({
  providedIn: 'root'
})
export class CxcomisionService {

  public way: string;
  private _comisionesServicio: Cxccomision[];

  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }

  get comisionesServicio(): Cxccomision[] {
    return this._comisionesServicio;
  }

  set comisionesServicio(value: Cxccomision[]) {
    this._comisionesServicio = value;
  }

  getComisionServicio(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/comisiones/83bacc5a5c77000', {
        elementos: {},
    }, this.confIniciales.httpOptions());
  }

  actualizarComision(comision: Cxccomision, codigoAux): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/comisiones/533be662d2e4b00', {
      elementos: {
        p_COMISION_codigo_1: codigoAux,
        p_COMISION_codigo_2: comision.COMISION_CODIGO,
        p_COMISION_nombre_3: comision.COMISION_NOMBRE,
        p_COMISION_porcentaje_4: comision.COMISION_PORCENTAJE,
        p_con_codigo: comision.CON_CODIGO,
        p_COM_codigo: '01',
        p_APLORG_codigo: comision.APLORG_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }

  insertarComision(comision: Cxccomision): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/comisiones/257e3b7d662d2e0000', {
      elementos: {
        p_COMISION_codigo_1: comision.COMISION_CODIGO,
        p_COMISION_nombre_2: comision.COMISION_NOMBRE,
        p_COMISION_porcentaje_3: comision.COMISION_PORCENTAJE,
        p_con_codigo: comision.CON_CODIGO,
        p_COM_codigo: '01',
        p_APLORG_codigo: comision.APLORG_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarComision(comision: Cxccomision): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/comisiones/75656662d2e4b00', {
      elementos: {
        p_COMISION_codigo_1: comision.COMISION_CODIGO,
        p_COM_codigo: '01',
        P_APLORG_codigo: comision.APLORG_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }

  erComisioncodigo(strCodigo) {
    return this.http.post(this.way + '/cxc/comisiones/76d98b4b8cc3640', {
      elementos: {
        COMISION_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }
   //CDPJ
   encontrarArtCntaySer(strArtCodigo, strtipo) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/75d98bf76adab8000000', {
      elementos: {
        ArtCodigo: strArtCodigo,
        strTIPO: strtipo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
}


