import { Component, OnInit, ViewChild, ɵConsole } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Usuario } from 'src/app/usuario';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { PermisosService } from 'src/app/advantage/advantageservices/permisos.service';
import { MessageService, SelectItem, ConfirmationService } from 'primeng';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { NomcabimpurentserviceService } from '../nomservicios/nomcabimpurentservice.service';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { AggridBanComponent } from 'src/app/ban/aggrid-ban/aggrid-ban.component';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { nomcabImpuRent, nomdetImpuRent } from '../nominterfaces/nomrhucabimpurent';
import { isNullOrUndefined } from 'util';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';


@Component({
  selector: 'app-nomrhucabimpurent',
  templateUrl: './nomrhucabimpurent.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NomrhucabimpurentComponent implements OnInit {
  @ViewChild('impuRent') aggrid: AggridsaciComponent;
  cargoDatos: boolean = false;
  cargaTabla: boolean = true;
  btnGuardar: boolean;
  btnNuevo: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  botonAnterior: boolean;
  botonPrimero: boolean;
  botonUltimo: boolean;
  botonSiguiente: boolean;
  btnBuscar: boolean;
  fecha = new Date();
  indexTab: number;
  identiUsuario = '';
  cabImpuRent: nomcabImpuRent = new nomcabImpuRent;
  displayDialogBusquedaFast: boolean = false;
  selectednomdetImpuRent: nomdetImpuRent;
  types: SelectItem[];
  busquedacampos = [];
  tabla: string;
  consulta: string;
  arregloCons: any[] = [];
  arrlegoBus = [];
  where: string;
  opcion: string;
  condicion: string;
  displayDialogClave: boolean = false;
  displayDialogenexml: boolean = false;
  cod: string = "";
  namexml: string = "";
  btnGenexml: boolean = true;
  btnRecImpRen: boolean = true;
  btnCamLiq: boolean = true;
  btnRevEstLiq: boolean = false;
  opcionret: number = 0;
  public frameworkComponents;
  rowStyle;
  largo = '500';
  defaultColDefDetImpuRent;
  rowSelection = 'multiple';
  activarboto: boolean = true;


  displayDialogReportes: boolean;
  // reporte
  auxreporte: number = 0;
  strNombreForma: string;
  displayDialogDocumentos = false;

  columnnomDetImpuRent = [
    {
      headerName: 'Código Empleado', editable: true, field: 'EMP_CODIGO', pinned: 'left', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: false,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Cédula o Pasaporte  del Retenido', editable: false,
      field: 'EMP_RUCIDE', cellEditor: 'cellPrueba', pinned: 'left', width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,

        }
      }
    },
    {
      headerName: 'Nombre del trabajador', editable: false, pinned: 'left', field: 'EMP_NOMBRE', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
        }
      }
    },
    {
      headerName: 'Fecha Ingreso', editable: false, field: 'EMP_FECINGRESO', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: true,
          tamanocelda: 80,
          tienecalculos: false
        }
      }
    },
    {
      headerName: 'Fecha Salida', editable: false, field: 'EMP_FECSALIDA', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: true,
          tamanocelda: 80,
          tienecalculos: false
        }
      }
    },
    {
      headerName: 'Sistema de salario neto', editable: false, field: 'DETIMPURENT_SISTSAL', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: true,
          tamanocelda: 80,
          tienecalculos: false,
        }
      }
    },
    {
      headerName: 'Sueldos y salarios', field: 'DETIMPURENT_SUELDO', cellEditor: 'cellPrueba', editable: false, width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: true,
          tamanocelda: 80,
          tienecalculos: false,
        }
      }
    },
    {
      headerName: 'Sobresueldos, comisiones, bonos y otros ingresos gravados APORTABLES AL IESS',
      editable: false, field: 'DETIMPURENT_OTROINGR', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }
    },
    {
      headerName: 'Sobresueldos, comisiones, bonos y otros ingresos gravados NO APORTABLES AL IESS',
      editable: false, field: 'DETIMPURENT_OTROINGR_NI', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }
    },
    {
      headerName: 'Ingresos gravados generados con otros empleadores', editable: true, field: 'DETIMPURENT_INGRGRAV_OE', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
        this.cellcambios10_19_20_21_31_32_33(params);
      }
    },
    {
      headerName: 'Impuesto a la Renta asumido por este empleador', editable: false,
      field: 'DETIMPURENT_IREASUM', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }
    },
    {
      headerName: 'Participación utilidades', editable: true, field: 'DETIMPURENT_UTI', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
        this.cellcambios(params);
      }
    },
    {
      headerName: 'Décimo tercer sueldo', editable: false, field: 'DETIMPURENT_D13', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }
    },
    {
      headerName: 'Décimo cuarto sueldo', editable: false, field: 'DETIMPURENT_D14', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }
    },
    {
      headerName: 'Fondo de Reserva (Informativo)', editable: false, field: 'DETIMPURENT_FRE', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }
    },
    {
      headerName: 'Compensación económica Salario Digno (Informativo)', editable: true,
      field: 'DETIMPURENT_SALDIGNO', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
        this.cellcambios11(params);
      }
    },
    {
      headerName: 'Otros ingresos en relación de dependencia que no constituyen renta gravada APORTABLES AL IESS',
      editable: false, field: 'DETIMPURENT_OTROINGR_NGAI', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }
    },
    {
      headerName: 'Otros ingresos en relación de dependencia que no constituyen renta gravada NO APORTABLES AL IESS',
      editable: false, field: 'DETIMPURENT_OTROINGR_NGNI', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }
    },
    {
      headerName: 'TOTAL Ingresos gravados con este empleador (Informativo)',
      editable: false, field: 'DETIMPURENT_TOTAINGR', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
        this.cellcambios10_19_20_21_31_32_33(params);
      }
    },
    {
      headerName: 'Aporte personal IESS con este empleador', editable: false,
      field: 'DETIMPURENT_IESS', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
        this.cellcambios10_19_20_21_31_32_33(params);
      }
    },
    {
      headerName: 'Aporte personal IESS con otros empleadores', editable: true,
      field: 'DETIMPURENT_IESS_OE', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
        this.cellcambios10_19_20_21_31_32_33(params);
      }
    },
    {
      headerName: 'Deducción Gastos Personales - Vivienda', editable: true,
      field: 'DETIMPURENT_VIVI', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
        this.cellcambios22to27(params);
      }
    },
    {
      headerName: 'Deducción Gastos Personales - Salud', editable: true,
      field: 'DETIMPURENT_SALU', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
        this.cellcambios22to27(params);
      }
    },
    {
      headerName: 'Deducción Gastos Personales - Educación', editable: true,
      field: 'DETIMPURENT_EDUC', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
        this.cellcambios22to27(params);
      }
    },
    {
      headerName: 'Deducción Gastos Personales - Alimentación', editable: true,
      field: 'DETIMPURENT_ALIM', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
        this.cellcambios22to27(params);
      }
    },
    {
      headerName: 'Deducción Gastos Personales - Vestimenta', editable: true,
      field: 'DETIMPURENT_VEST', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
        this.cellcambios22to27(params);
      }
    },
    {
      headerName: 'Deducción Gastos Personales - por Arte y Cultura', editable: true,
      field: 'DETIMPURENT_DEDUGPARTYCULT', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
        this.cellcambios22to27(params);
      }
    },
    /*  {
       headerName: 'Disponible', editable: true, field: 'DISPONIBLE1', cellEditor: 'cellPrueba', width: 0, 
       cellEditorParams:
         {
           values: {
             mayusculas: false,
             numeros: true,
             alphabetic: true,
             saltoslinea: 1,
             newrow: false,
             numerodecimales: 2,
             obligatorio: true,
             fecha: false,
             tamanocelda: 80,
             tienecalculos: false,
             
           }
         }
     },
     {
       headerName: 'Disponible', editable: true, field: 'DISPONIBLE2', cellEditor: 'cellPrueba', width: 0, 
       cellEditorParams:
         {
           values: {
             mayusculas: false,
             numeros: true,
             alphabetic: true,
             saltoslinea: 1,
             newrow: false,
             numerodecimales: 2,
             obligatorio: true,
             fecha: false,
             tamanocelda: 80,
             tienecalculos: false,
             
           }
         }
     },
     {
       headerName: 'Disponible', editable: true, field: 'DISPONIBLE3', cellEditor: 'cellPrueba', width: 0, 
       cellEditorParams:
         {
           values: {
             mayusculas: false,
             numeros: true,
             alphabetic: true,
             saltoslinea: 1,
             newrow: false,
             numerodecimales: 2,
             obligatorio: true,
             fecha: false,
             tamanocelda: 80,
             tienecalculos: false,
             
           }
         }
     }, */
    {
      headerName: 'Total Deducciones', editable: false, field: 'DETIMPURENT_TOTAEGRE', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
        this.cellcambios10_19_20_21_31_32_33(params);
      }
    },
    {
      headerName: 'Exoneraciones discapacidad', editable: true, field: 'DETIMPURENT_DEDUCDISCA', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
        this.cellcambios10_19_20_21_31_32_33(params);
      }
    },
    {
      headerName: 'Exoneraciones tercera edad', editable: true, field: 'DETIMPURENT_DEDUCTERCE', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
        this.cellcambios10_19_20_21_31_32_33(params);
      }
    },
    {
      headerName: 'Base Imponible gravada', editable: false, field: 'DETIMPURENT_BASEIMPO', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }
    },
    {
      headerName: 'Impuesto a la Renta Causado', editable: false, field: 'DETIMPURENT_VALOIMPURENT', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
        this.cellcambios35_36_37_38_39_40(params);
      }
    },
    {
      headerName: 'Valor del impuesto retenido y asumido por otros empleadores durante el período declarado',
      editable: true, field: 'DETIMPURENT_IRERENT_OE', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
        this.cellcambios35_36_37_38_39_40(params);
      }
    },
    {
      headerName: 'Valor del impuesto asumido por este empleador período actual', editable: false,
      field: 'DETIMPURENT_IREASUM_PP', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
        this.cellcambios35_36_37_38_39_40(params);
      }
    },
    {
      headerName: 'Valor del impuesto asumido por este empleador todos los períodos', editable: false,
      field: 'DETIMPURENT_IMPURENTASUM', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
        this.cellcambios35_36_37_38_39_40(params);
      }
    },
    {
      headerName: 'Valor del impuesto retenido al trabajador por este empleador período actual', editable: false,
      field: 'DETIMPURENT_IRERET_PP', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
        this.cellcambios35_36_37_38_39_40(params);
      }
    },
    {
      headerName: 'Valor del impuesto retenido al trabajador por este empleador todos los períodos', editable: false,
      field: 'DETIMPURENT_IRERET', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }, onCellValueChanged: (params) => {
        this.cellcambios35_36_37_38_39_40(params);
      }
    },
    {
      headerName: 'IRE POR RETENER', editable: false, field: 'DETIMPURENT_IREPORRET', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,
          int: true
        }
      }
    },
    {
      headerName: 'detimpurent_numsec', editable: false, field: 'DETIMPURENT_NUMSEC', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,

        }
      }
    },
    {
      headerName: 'Enf.Catastrófica', editable: false, field: 'EMP_ENFCATASTROFICA', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: false,

        }
      }
    }
  ];
  constructor(public impurentService: NomcabimpurentserviceService, private init: NuevoComponentService,
    private permisosService: PermisosService,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private confirmationService: ConfirmationService,
    private auditoriaGralService: AuditoriagralService,
    private datePipe: DatePipe,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) {
    this.agTable();
    this.datePipe.transform(this.fecha, 'dd/MM/yy');
    this.identiUsuario = this.usuario.identificacion;
    this.impurentService.detImpuRent = [];
  }

  ngOnInit(): void {
    this.btnGuardar = true;
    this.btnNuevo = false;
    this.btnBorrar = true;
    this.btnRegresar = true;
    this.botonAnterior = false;
    this.botonPrimero = false;
    this.botonUltimo = false;
    this.btnBuscar = false;
    this.botonSiguiente = false;

  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.defaultColDefDetImpuRent = {
      editable: true,
      width: 130,
      objeto: 'nomCabimpurent'
    };
  }
  onTab1Change(elemento) {

  }
  async manejarSenales(valor) {
    switch (valor) {
      case 'Nuevo':
        this.opcionNuevo();
        break;
      case 'Guardar':
        this.opcionGuardar();
        break;
      case 'Borrar':
        this.opcionBorrar();
        break;
      case 'Cancelar':
        this.opcionRecargar();
        break;
      case 'Salir':
        this.init.tabs.splice(this.init.tabindex, 1);
        break;
      case 'Primero':
        await this.Primero();
        break;
      case 'Anterior':
        await this.Anterior();
        break;
      case 'Siguiente':
        await this.Siguiente();
        break;
      case 'Ultimo':
        await this.Ultimo();
        break;
      case 'Buscar':
        this.buscarCondicion();
        break;
      case 'gxml':
        this.generarxml();
        break;
      case 'rimrent':
        this.recaImpuRent();
        break;
      case 'camliq':
        this.cambLiqui();
        break;
      case 'reesliq':
        this.reversar();
        break;
      case 'Almacenardoc':
          if (this.cabImpuRent.CABIMPURENT_CODIGO !== '') {
            const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
            if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
              this.displayDialogDocumentos = true;
            } else {
              this.messageService.add({
                key: 'inpurent',
                severity: 'info',
                summary: 'Información',
                detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
              });
            }
            
          }
      break;
      case 'Imprimir':
        this.strNombreForma = this.init.tabs[this.init.tabindex].component;
        this.auxreporte++;
        this.displayDialogReportes = true;
        break;

    }
  }
  opcionNuevo() {
    let va: nomcabImpuRent = new nomcabImpuRent;
    this.cabImpuRent = va;
    this.cabImpuRent.CABIMPURENT_ESTADO = 'EN PROCESO';
    this.cabImpuRent.CABIMPURENT_CODIGO = null;
    document.getElementById('divPrincipal').style.pointerEvents = 'all';
    this.impurentService.obtieneaniofinper().then(re => {
      this.cabImpuRent.CABIMPURENT_ANIO = re[0]["TO_CHAR(PER_FECHAFINAL,'YYYY')"]
    })
    this.impurentService.detImpuRent = [];
    this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
  }
  async opcionRecargar() {
    let detImpuRentAux: nomdetImpuRent[] = [];
    this.impurentService.detImpuRent = [];
    this.cargoDatos = true;
    await this.impurentService.listcabimpurent(this.cabImpuRent.CABIMPURENT_CODIGO).then(async (res: any) => {
      this.cabImpuRent = res[0];
      this.cabImpuRent.CABIMPURENT_ESTADO = (this.cabImpuRent.CABIMPURENT_ESTADO == null) ? 'En Proceso' : res[0].CABIMPURENT_ESTADO;
      await this.impurentService.listdetimpurent(this.cabImpuRent.CABIMPURENT_CODIGO).then(async (as: any) => {
        detImpuRentAux = as;
        let i = 0;
        this.impurentService.detImpuRent.map(x => {
          x.NUMCAB = i++;
          x.EMP_RUCIDE = x.EMP_CODIGO2;
          x.EMP_FECINGRESO = this.impurentService.cambiarfechanull(x.EMP_FECINGRESO);
          x.EMP_FECSALIDA = this.impurentService.cambiarfechanull(x.EMP_FECSALIDA);
          x.DETIMPURENT_ALIM = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_ALIM);
          x.DETIMPURENT_BASEIMPO = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_BASEIMPO);
          x.DETIMPURENT_D13 = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_D13);
          x.DETIMPURENT_D14 = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_D14);
          x.DETIMPURENT_DEDUCDISCA = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_DEDUCDISCA);
          x.DETIMPURENT_DEDUCTERCE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_DEDUCTERCE);
          x.DETIMPURENT_DEDUGPARTYCULT = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_DEDUGPARTYCULT);
          x.DETIMPURENT_EDUC = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_EDUC);
          x.DETIMPURENT_FRE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_FRE);
          x.DETIMPURENT_IESS = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IESS);
          x.DETIMPURENT_IESS_OE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IESS_OE);
          x.DETIMPURENT_IMPURENTASUM = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IMPURENTASUM);
          x.DETIMPURENT_INGRGRAV_OE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_INGRGRAV_OE);
          x.DETIMPURENT_IREASUM = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IREASUM);
          x.DETIMPURENT_IREASUM_PP = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IREASUM_PP);
          x.DETIMPURENT_IREPORRET = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IREPORRET);
          x.DETIMPURENT_IRERENT_OE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IRERENT_OE);
          x.DETIMPURENT_IRERET = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IRERET);
          x.DETIMPURENT_IRERET_PP = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IRERET_PP);
          x.DETIMPURENT_ORDEN = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_ORDEN);
          x.DETIMPURENT_OTROINGR = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR);
          x.DETIMPURENT_OTROINGR_NGAI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NGAI);
          x.DETIMPURENT_OTROINGR_NGNA = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NGNA);
          x.DETIMPURENT_OTROINGR_NGNI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NGNI);
          x.DETIMPURENT_OTROINGR_NI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NI);
          x.DETIMPURENT_SALDIGNO = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_SALDIGNO);
          x.DETIMPURENT_SALU = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_SALU);
          x.DETIMPURENT_SUELDO = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_SUELDO);
          x.DETIMPURENT_TOTAEGRE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_TOTAEGRE);
          x.DETIMPURENT_TOTAINGR = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_TOTAINGR);
          x.DETIMPURENT_UTI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_UTI);
          x.DETIMPURENT_VALOIMPURENT = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_VALOIMPURENT);
          x.DETIMPURENT_VEST = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_VEST);
          x.DETIMPURENT_VIVI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_VIVI);
        });
        for (let ren of this.impurentService.detImpuRent) {
          this.funActualizaFloat(ren);
          this.funTotalBaseImponibleGrava(ren);
          this.funTotalIngresosGravaConEsteProveedor(ren);
          this.funTotalDeducciones(ren);
          this.funTotalIrePorRetener(ren);
          this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[ren.NUMCAB]);
        }
        this.cargoDatos = false;
        this.activarbotones();
        //this.impurentService.detImpuRent.sort((a,b)=>a.DETIMPURENT_NUMSEC-b.DETIMPURENT_NUMSEC);
        //this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto );
        setTimeout(() => {
          this.aggrid.gridApi.setFocusedCell(0, 'EMP_CODIGO');
        }, 900);
      })
    })
  }



  bloqueaCaposPantalla(valor: string) {

    if (valor == 'LIQUIDADO') {
      return false;
    } else {
      return true;
    }
  }
  async Primero() {
    let detImpuRentAux: nomdetImpuRent[] = [];
    this.impurentService.detImpuRent = [];
    this.cargoDatos = true;
    await this.impurentService.primerocabi().then(async x => {
      console.log(x);
      await this.impurentService.listcabimpurent(x[0]["MIN(CABIMPURENT_CODIGO)"]).then(async (res: any) => {
        this.cabImpuRent = res[0];
        if (this.cabImpuRent.CABIMPURENT_ESTADO == null || this.cabImpuRent.CABIMPURENT_ESTADO == 'EN PROCESO' || this.cabImpuRent.CABIMPURENT_ESTADO == 'PROCESO') {
          document.getElementById('divPrincipal').style.pointerEvents = 'all';
        } else {
          document.getElementById('divPrincipal').style.pointerEvents = 'none';
        }
        this.cabImpuRent.CABIMPURENT_ESTADO = (this.cabImpuRent.CABIMPURENT_ESTADO == null) ? 'EN PROCESO' : res[0].CABIMPURENT_ESTADO;
        await this.impurentService.listdetimpurent(x[0]["MIN(CABIMPURENT_CODIGO)"]).then(async (as: any) => {
          detImpuRentAux = as;
          let i = 0;
          detImpuRentAux.map(x => {
            x.NUMCAB = i++;
            x.EMP_CODIGO = x.EMP_CODIGO == 0 ? "" : x.EMP_CODIGO;
            x.EMP_RUCIDE = x.EMP_CODIGO2;
            x.EMP_FECINGRESO = this.impurentService.cambiarfechanull(x.EMP_FECINGRESO);
            x.EMP_FECSALIDA = this.impurentService.cambiarfechanull(x.EMP_FECSALIDA);
            x.CABIMPURENT_CODIGO = this.cabImpuRent.CABIMPURENT_CODIGO;
            x.DETIMPURENT_ALIM = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_ALIM);
            x.DETIMPURENT_BASEIMPO = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_BASEIMPO);
            x.DETIMPURENT_D13 = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_D13);
            x.DETIMPURENT_D14 = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_D14);
            x.DETIMPURENT_DEDUCDISCA = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_DEDUCDISCA);
            x.DETIMPURENT_DEDUCTERCE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_DEDUCTERCE);
            x.DETIMPURENT_DEDUGPARTYCULT = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_DEDUGPARTYCULT);
            x.DETIMPURENT_EDUC = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_EDUC);
            x.DETIMPURENT_FRE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_FRE);
            x.DETIMPURENT_IESS = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IESS);
            x.DETIMPURENT_IESS_OE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IESS_OE);
            x.DETIMPURENT_IMPURENTASUM = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IMPURENTASUM);
            x.DETIMPURENT_INGRGRAV_OE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_INGRGRAV_OE);
            x.DETIMPURENT_IREASUM = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IREASUM);
            x.DETIMPURENT_IREASUM_PP = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IREASUM_PP);
            x.DETIMPURENT_IREPORRET = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IREPORRET);
            x.DETIMPURENT_IRERENT_OE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IRERENT_OE);
            x.DETIMPURENT_IRERET = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IRERET);
            x.DETIMPURENT_IRERET_PP = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IRERET_PP);
            x.DETIMPURENT_ORDEN = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_ORDEN);
            x.DETIMPURENT_OTROINGR = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR);
            x.DETIMPURENT_OTROINGR_NGAI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NGAI);
            x.DETIMPURENT_OTROINGR_NGNA = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NGNA);
            x.DETIMPURENT_OTROINGR_NGNI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NGNI);
            x.DETIMPURENT_OTROINGR_NI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NI);
            x.DETIMPURENT_SALDIGNO = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_SALDIGNO);
            x.DETIMPURENT_SALU = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_SALU);
            x.DETIMPURENT_SUELDO = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_SUELDO);
            x.DETIMPURENT_TOTAEGRE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_TOTAEGRE);
            x.DETIMPURENT_TOTAINGR = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_TOTAINGR);
            x.DETIMPURENT_UTI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_UTI);
            x.DETIMPURENT_VALOIMPURENT = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_VALOIMPURENT);
            x.DETIMPURENT_VEST = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_VEST);
            x.DETIMPURENT_VIVI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_VIVI);
          });
          this.impurentService.detImpuRent = [...detImpuRentAux];
          for (let ren of this.impurentService.detImpuRent) {
            this.funActualizaFloat(ren);
            this.funTotalBaseImponibleGrava(ren);
            this.funTotalIngresosGravaConEsteProveedor(ren);
            this.funTotalDeducciones(ren);
            this.funTotalIrePorRetener(ren);
            this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[ren.NUMCAB]);
          }
          this.cargoDatos = false;
          this.activarbotones();
          setTimeout(() => {
            this.aggrid.gridApi.setFocusedCell(0, 'EMP_CODIGO');
          }, 900);

        })
      })
    })
  }

  async Anterior() {
    let detImpuRentAux: nomdetImpuRent[] = [];
    this.impurentService.detImpuRent = [];
    this.cargoDatos = true;
    if (isNullOrUndefined(this.cabImpuRent.CABIMPURENT_CODIGO)) {
      let va: nomcabImpuRent = new nomcabImpuRent;
      this.cabImpuRent = va;
      this.cabImpuRent.CABIMPURENT_ESTADO = 'EN PROCESO';
      this.impurentService.obtieneaniofinper().then(re => {
        this.cabImpuRent.CABIMPURENT_ANIO = re[0]["TO_CHAR(PER_FECHAFINAL,'YYYY')"]
      })
      this.impurentService.detImpuRent = [];
      this.cargoDatos = false;
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);

    } else {
      await this.impurentService.anteriorcabi(this.cabImpuRent.CABIMPURENT_CODIGO).then(async x => {
        console.log(x);
        await this.impurentService.listcabimpurent(x[0]["MAX(CABIMPURENT_CODIGO)"]).then(async (res: any) => {
          this.cabImpuRent = res[0];

          if (this.cabImpuRent.CABIMPURENT_ESTADO == null || this.cabImpuRent.CABIMPURENT_ESTADO == 'EN PROCESO') {
            document.getElementById('divPrincipal').style.pointerEvents = 'all';
          } else {
            document.getElementById('divPrincipal').style.pointerEvents = 'none';
          }
          this.cabImpuRent.CABIMPURENT_ESTADO = (this.cabImpuRent.CABIMPURENT_ESTADO == null) ? 'En Proceso' : res[0].CABIMPURENT_ESTADO;
          await this.impurentService.listdetimpurent(x[0]["MAX(CABIMPURENT_CODIGO)"]).then(async (as: any) => {
            detImpuRentAux = as;
            let i = 0;
            detImpuRentAux.map(x => {
              x.NUMCAB = i++;
              x.EMP_CODIGO = x.EMP_CODIGO == 0 ? "" : x.EMP_CODIGO;
              x.EMP_RUCIDE = x.EMP_CODIGO2;
              x.EMP_FECINGRESO = this.impurentService.cambiarfechanull(x.EMP_FECINGRESO);
              x.EMP_FECSALIDA = this.impurentService.cambiarfechanull(x.EMP_FECSALIDA);
              x.CABIMPURENT_CODIGO = this.cabImpuRent.CABIMPURENT_CODIGO;
              x.DETIMPURENT_ALIM = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_ALIM);
              x.DETIMPURENT_BASEIMPO = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_BASEIMPO);
              x.DETIMPURENT_D13 = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_D13);
              x.DETIMPURENT_D14 = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_D14);
              x.DETIMPURENT_DEDUCDISCA = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_DEDUCDISCA);
              x.DETIMPURENT_DEDUCTERCE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_DEDUCTERCE);
              x.DETIMPURENT_DEDUGPARTYCULT = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_DEDUGPARTYCULT);
              x.DETIMPURENT_EDUC = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_EDUC);
              x.DETIMPURENT_FRE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_FRE);
              x.DETIMPURENT_IESS = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IESS);
              x.DETIMPURENT_IESS_OE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IESS_OE);
              x.DETIMPURENT_IMPURENTASUM = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IMPURENTASUM);
              x.DETIMPURENT_INGRGRAV_OE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_INGRGRAV_OE);
              x.DETIMPURENT_IREASUM = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IREASUM);
              x.DETIMPURENT_IREASUM_PP = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IREASUM_PP);
              x.DETIMPURENT_IREPORRET = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IREPORRET);
              x.DETIMPURENT_IRERENT_OE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IRERENT_OE);
              x.DETIMPURENT_IRERET = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IRERET);
              x.DETIMPURENT_IRERET_PP = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IRERET_PP);
              x.DETIMPURENT_ORDEN = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_ORDEN);
              x.DETIMPURENT_OTROINGR = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR);
              x.DETIMPURENT_OTROINGR_NGAI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NGAI);
              x.DETIMPURENT_OTROINGR_NGNA = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NGNA);
              x.DETIMPURENT_OTROINGR_NGNI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NGNI);
              x.DETIMPURENT_OTROINGR_NI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NI);
              x.DETIMPURENT_SALDIGNO = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_SALDIGNO);
              x.DETIMPURENT_SALU = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_SALU);
              x.DETIMPURENT_SUELDO = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_SUELDO);
              x.DETIMPURENT_TOTAEGRE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_TOTAEGRE);
              x.DETIMPURENT_TOTAINGR = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_TOTAINGR);
              x.DETIMPURENT_UTI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_UTI);
              x.DETIMPURENT_VALOIMPURENT = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_VALOIMPURENT);
              x.DETIMPURENT_VEST = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_VEST);
              x.DETIMPURENT_VIVI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_VIVI);
            });
            this.impurentService.detImpuRent = detImpuRentAux;
            for (let ren of this.impurentService.detImpuRent) {
              this.funActualizaFloat(ren);
              this.funTotalBaseImponibleGrava(ren);
              this.funTotalIngresosGravaConEsteProveedor(ren);
              this.funTotalDeducciones(ren);
              this.funTotalIrePorRetener(ren);
              this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[ren.NUMCAB]);
            }
            this.activarbotones();
            this.cargoDatos = false;
            //this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto );
            setTimeout(() => {
              this.aggrid.gridApi.setFocusedCell(0, 'EMP_CODIGO');
            }, 900);

          })
        })
      })
    }



  }
  async Siguiente() {
    let detImpuRentAux: nomdetImpuRent[] = [];
    this.impurentService.detImpuRent = [];
    this.cargoDatos = true;
    await this.impurentService.siguientecabi(this.cabImpuRent.CABIMPURENT_CODIGO).then(async x => {
      await this.impurentService.listcabimpurent(x[0]["MIN(CABIMPURENT_CODIGO)"]).then(async (res: any) => {
        this.cabImpuRent = res[0];
        if (this.cabImpuRent.CABIMPURENT_ESTADO == null || this.cabImpuRent.CABIMPURENT_ESTADO == 'EN PROCESO' || this.cabImpuRent.CABIMPURENT_ESTADO == 'PROCESO') {
          document.getElementById('divPrincipal').style.pointerEvents = 'all';
        } else {
          document.getElementById('divPrincipal').style.pointerEvents = 'none';
        }
        this.cabImpuRent.CABIMPURENT_ESTADO = (this.cabImpuRent.CABIMPURENT_ESTADO == null) ? 'En Proceso' : res[0].CABIMPURENT_ESTADO;
        await this.impurentService.listdetimpurent(x[0]["MIN(CABIMPURENT_CODIGO)"]).then(async (as: any) => {
          detImpuRentAux = as;

          let i = 0;
          detImpuRentAux.map(x => {
            x.NUMCAB = i++;
            x.EMP_CODIGO = x.EMP_CODIGO == 0 ? "" : x.EMP_CODIGO;
            x.EMP_RUCIDE = x.EMP_CODIGO2;
            x.EMP_FECINGRESO = this.impurentService.cambiarfechanull(x.EMP_FECINGRESO);
            x.EMP_FECSALIDA = this.impurentService.cambiarfechanull(x.EMP_FECSALIDA);
            x.CABIMPURENT_CODIGO = this.cabImpuRent.CABIMPURENT_CODIGO;
            x.DETIMPURENT_ALIM = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_ALIM);
            x.DETIMPURENT_BASEIMPO = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_BASEIMPO);
            x.DETIMPURENT_D13 = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_D13);
            x.DETIMPURENT_D14 = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_D14);
            x.DETIMPURENT_DEDUCDISCA = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_DEDUCDISCA);
            x.DETIMPURENT_DEDUCTERCE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_DEDUCTERCE);
            x.DETIMPURENT_DEDUGPARTYCULT = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_DEDUGPARTYCULT);
            x.DETIMPURENT_EDUC = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_EDUC);
            x.DETIMPURENT_FRE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_FRE);
            x.DETIMPURENT_IESS = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IESS);
            x.DETIMPURENT_IESS_OE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IESS_OE);
            x.DETIMPURENT_IMPURENTASUM = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IMPURENTASUM);
            x.DETIMPURENT_INGRGRAV_OE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_INGRGRAV_OE);
            x.DETIMPURENT_IREASUM = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IREASUM);
            x.DETIMPURENT_IREASUM_PP = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IREASUM_PP);
            x.DETIMPURENT_IREPORRET = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IREPORRET);
            x.DETIMPURENT_IRERENT_OE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IRERENT_OE);
            x.DETIMPURENT_IRERET = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IRERET);
            x.DETIMPURENT_IRERET_PP = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IRERET_PP);
            x.DETIMPURENT_ORDEN = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_ORDEN);
            x.DETIMPURENT_OTROINGR = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR);
            x.DETIMPURENT_OTROINGR_NGAI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NGAI);
            x.DETIMPURENT_OTROINGR_NGNA = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NGNA);
            x.DETIMPURENT_OTROINGR_NGNI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NGNI);
            x.DETIMPURENT_OTROINGR_NI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NI);
            x.DETIMPURENT_SALDIGNO = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_SALDIGNO);
            x.DETIMPURENT_SALU = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_SALU);
            x.DETIMPURENT_SUELDO = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_SUELDO);
            x.DETIMPURENT_TOTAEGRE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_TOTAEGRE);
            x.DETIMPURENT_TOTAINGR = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_TOTAINGR);
            x.DETIMPURENT_UTI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_UTI);
            x.DETIMPURENT_VALOIMPURENT = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_VALOIMPURENT);
            x.DETIMPURENT_VEST = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_VEST);
            x.DETIMPURENT_VIVI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_VIVI);
          });
          this.impurentService.detImpuRent = detImpuRentAux;
          for (let ren of this.impurentService.detImpuRent) {
            this.funActualizaFloat(ren);
            this.funTotalBaseImponibleGrava(ren);
            this.funTotalIngresosGravaConEsteProveedor(ren);
            this.funTotalDeducciones(ren);
            this.funTotalIrePorRetener(ren);
            this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[ren.NUMCAB]);
          }
          this.cargoDatos = false;
          this.activarbotones();
          //this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto );
          setTimeout(() => {
            this.aggrid.gridApi.setFocusedCell(0, 'EMP_CODIGO');
          }, 900);


        })
      })
    })

  }
  async Ultimo() {
    let detImpuRentAux: nomdetImpuRent[] = [];
    this.impurentService.detImpuRent = [];
    this.cargoDatos = true;
    await this.impurentService.ultimocabi().then(async x => {
      await this.impurentService.listcabimpurent(x[0]["MAX(CABIMPURENT_CODIGO)"]).then(async (res: any) => {
        this.cabImpuRent = res[0];
        console.log(this.cabImpuRent);
        if (this.cabImpuRent.CABIMPURENT_ESTADO == null || this.cabImpuRent.CABIMPURENT_ESTADO == 'EN PROCESO' || this.cabImpuRent.CABIMPURENT_ESTADO == 'PROCESO') {
          document.getElementById('divPrincipal').style.pointerEvents = 'all';
        } else {
          document.getElementById('divPrincipal').style.pointerEvents = 'none';
        }
        this.cabImpuRent.CABIMPURENT_ESTADO = (this.cabImpuRent.CABIMPURENT_ESTADO == null) ? 'En Proceso' : res[0].CABIMPURENT_ESTADO;
        await this.impurentService.listdetimpurent(x[0]["MAX(CABIMPURENT_CODIGO)"]).then(async (as: any) => {
          detImpuRentAux = as;
          let i = 0;
          detImpuRentAux.map(x => {
            x.NUMCAB = i++;
            x.EMP_CODIGO = x.EMP_CODIGO == 0 ? "" : x.EMP_CODIGO;
            x.EMP_RUCIDE = x.EMP_CODIGO2;
            x.EMP_FECINGRESO = this.impurentService.cambiarfechanull(x.EMP_FECINGRESO);
            x.EMP_FECSALIDA = this.impurentService.cambiarfechanull(x.EMP_FECSALIDA);
            x.CABIMPURENT_CODIGO = this.cabImpuRent.CABIMPURENT_CODIGO;
            x.DETIMPURENT_ALIM = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_ALIM);
            x.DETIMPURENT_BASEIMPO = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_BASEIMPO);
            x.DETIMPURENT_D13 = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_D13);
            x.DETIMPURENT_D14 = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_D14);
            x.DETIMPURENT_DEDUCDISCA = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_DEDUCDISCA);
            x.DETIMPURENT_DEDUCTERCE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_DEDUCTERCE);
            x.DETIMPURENT_DEDUGPARTYCULT = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_DEDUGPARTYCULT);
            x.DETIMPURENT_EDUC = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_EDUC);
            x.DETIMPURENT_FRE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_FRE);
            x.DETIMPURENT_IESS = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IESS);
            x.DETIMPURENT_IESS_OE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IESS_OE);
            x.DETIMPURENT_IMPURENTASUM = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IMPURENTASUM);
            x.DETIMPURENT_INGRGRAV_OE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_INGRGRAV_OE);
            x.DETIMPURENT_IREASUM = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IREASUM);
            x.DETIMPURENT_IREASUM_PP = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IREASUM_PP);
            x.DETIMPURENT_IREPORRET = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IREPORRET);
            x.DETIMPURENT_IRERENT_OE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IRERENT_OE);
            x.DETIMPURENT_IRERET = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IRERET);
            x.DETIMPURENT_IRERET_PP = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IRERET_PP);
            x.DETIMPURENT_ORDEN = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_ORDEN);
            x.DETIMPURENT_OTROINGR = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR);
            x.DETIMPURENT_OTROINGR_NGAI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NGAI);
            x.DETIMPURENT_OTROINGR_NGNA = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NGNA);
            x.DETIMPURENT_OTROINGR_NGNI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NGNI);
            x.DETIMPURENT_OTROINGR_NI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NI);
            x.DETIMPURENT_SALDIGNO = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_SALDIGNO);
            x.DETIMPURENT_SALU = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_SALU);
            x.DETIMPURENT_SUELDO = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_SUELDO);
            x.DETIMPURENT_TOTAEGRE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_TOTAEGRE);
            x.DETIMPURENT_TOTAINGR = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_TOTAINGR);
            x.DETIMPURENT_UTI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_UTI);
            x.DETIMPURENT_VALOIMPURENT = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_VALOIMPURENT);
            x.DETIMPURENT_VEST = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_VEST);
            x.DETIMPURENT_VIVI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_VIVI);
          });
          this.impurentService.detImpuRent = detImpuRentAux;
          for (let ren of this.impurentService.detImpuRent) {
            this.funActualizaFloat(ren);
            this.funTotalBaseImponibleGrava(ren);
            this.funTotalIngresosGravaConEsteProveedor(ren);
            this.funTotalDeducciones(ren);
            this.funTotalIrePorRetener(ren);
            this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[ren.NUMCAB]);
          }

          this.cargoDatos = false;
          //this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto );
          setTimeout(() => {
            this.aggrid.gridApi.setFocusedCell(0, 'EMP_CODIGO');
          }, 600);
          this.activarbotones();
        })
      })
    })
  }
  activarbotones() {
    this.btnGuardar = true;
    this.btnNuevo = false;
    this.btnBorrar = false;
    this.btnRegresar = false;
    this.botonAnterior = false;
    this.botonPrimero = false;
    this.botonUltimo = false;
    this.botonSiguiente = false;
    this.btnBuscar = false;
  }
  async opcionGuardar() {
    this.aggrid.gridApi.stopEditing();
    this.impurentService.detImpuRent.length;
    if (this.impurentService.detImpuRent.length != 0) {
      if (this.cabImpuRent.CABIMPURENT_ANIO != "") {
        try {
          await this.impurentService.actuacabimpurent(this.cabImpuRent).then(async re => {
            this.cabImpuRent.CABIMPURENT_CODIGO = re[0][":B1"];
            for (let ren of this.impurentService.detImpuRent) {
              this.funActualizaFloat(ren);
              this.funTotalBaseImponibleGrava(ren);
              this.funTotalIngresosGravaConEsteProveedor(ren);
              this.funTotalDeducciones(ren);
              this.funTotalIrePorRetener(ren);
              ren.CABIMPURENT_CODIGO = re[0][":B1"];
              const a = await this.impurentService.actuadetimpurent(ren).then(async re => {

              });
            }
            this.mensajetoAlerta('success', 'Información', '¡Actualizado!');
            this.activarbotones();
            let au = (this.cabImpuRent.CABIMPURENT_CODIGO == 0) ? 'I' : 'A';
            this.auditoriaGralService.registrarAuditoria('nom_cabimpurent', this.cabImpuRent.CABIMPURENT_CODIGO, au,
              '', '01', '', '', '', '').toPromise().then(() => {
              });
            //await this.opcionRecargar();
          }).catch(e => {
            this.mensajetoAlerta('error', 'Error', e.error);
          })
        } catch (e) {
          this.mensajetoAlerta('error', 'Error', e.error);
        }
      } else {
        this.mensajetoAlerta('error', 'Error', 'Ingrese el año');
      }

    }
  }

  opcionBorrar() {
    if (this.cabImpuRent !== undefined) {
      this.confirmationService.confirm({
        message: '¿Está seguro que desea eliminar?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'NomImpRenta',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {
          try {
            this.impurentService.eliminarcabimpurent(this.cabImpuRent.CABIMPURENT_CODIGO).then(async () => {
              this.mensajeAlerta('success', 'Información', '¡Registro eliminado!');
              let va: nomcabImpuRent = new nomcabImpuRent;
              this.cabImpuRent = va;
              this.cabImpuRent.CABIMPURENT_ESTADO = 'EN PROCESO';
              this.auditoriaGralService.registrarAuditoria('nom_cabimpurent', this.cabImpuRent.CABIMPURENT_CODIGO, 'E',
                '', '01', '', '', '', '').toPromise().then(() => {
                });
              this.impurentService.obtieneaniofinper().then(re => {
                this.cabImpuRent.CABIMPURENT_ANIO = re[0]["TO_CHAR(PER_FECHAFINAL,'YYYY')"]
              })
              this.impurentService.detImpuRent = [];
              this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
            });
          } catch (e) {
            let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
            this.mensajetoAlerta('error', 'Error', e.error);
          }

          /* this.nonmaeRub.eliminarRubro(this.selectedNomMaeRub.MAERUBRO_CODIGO).then(res=>{
            this.mensajeAlerta('success', 'Información', '¡Registro eliminado!');
            this.cargar();
            this.auditoriaGralService.registrarAuditoria('nom_maerubro', this.selectedNomMaeRub.MAERUBRO_CODIGO, 'E',
          '', '01', '', '', '', '').toPromise().then(() => {
          });
          }).catch(e=>{
            let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
          }) */
        },
        reject: () => {
        }
      })
    }
  }

  selecImpuRent(valor) {
    if (valor === 'nuevo') {
      this.nuevodetImpuRent();
    }

    if (valor === 'eliminar') {
      this.eliminardetRent();
    }

    if (valor === 'cancelar') {
      this.cancelarImpuRent();
    }

    if (valor.object !== undefined) {
      this.selectednomdetImpuRent = valor.object;
    }
  }
  cambioImpuRent() {
    this.btnGuardar = false;
    this.btnNuevo = true;
    this.btnBorrar = false;
    this.btnRegresar = false;
    this.botonAnterior = false;
    this.botonPrimero = false;
    this.botonUltimo = false;
    this.btnBuscar = false;
    this.botonSiguiente = false;
  }
  nuevodetImpuRent() {
    let dat = new Date();
    this.aggrid.isEditable(true);
    const newModulo: nomdetImpuRent = {
      CABIMPURENT_CODIGO: null,
      COM_CODIGO: '01',
      DETIMPURENT_ALIM: '',
      DETIMPURENT_BASEIMPO: '',
      DETIMPURENT_D13: '',
      DETIMPURENT_D14: '',
      DETIMPURENT_DEDUCDISCA: '',
      DETIMPURENT_DEDUCTERCE: '',
      DETIMPURENT_DEDUGPARTYCULT: '',
      DETIMPURENT_EDUC: '',
      DETIMPURENT_FRE: '',
      DETIMPURENT_IESS: '',
      DETIMPURENT_IESS_OE: '',
      DETIMPURENT_IMPURENTASUM: '',
      DETIMPURENT_INGRGRAV_OE: '',
      DETIMPURENT_IREASUM: '',
      DETIMPURENT_IREASUM_PP: '',
      DETIMPURENT_IREPORRET: '',
      DETIMPURENT_IRERENT_OE: '',
      DETIMPURENT_IRERET: '',
      DETIMPURENT_IRERET_PP: '',
      DETIMPURENT_NUMSEC: '',
      DETIMPURENT_ORDEN: '',
      DETIMPURENT_OTROINGR: '',
      DETIMPURENT_OTROINGR_NGAI: '',
      DETIMPURENT_OTROINGR_NGNA: '',
      DETIMPURENT_OTROINGR_NGNI: '',
      DETIMPURENT_OTROINGR_NI: '',
      DETIMPURENT_SALDIGNO: '',
      DETIMPURENT_SALU: '',
      DETIMPURENT_SISTSAL: '',
      DETIMPURENT_SUELDO: '',
      DETIMPURENT_TOTAEGRE: '',
      DETIMPURENT_TOTAINGR: '',
      DETIMPURENT_UTI: '',
      DETIMPURENT_VALOIMPURENT: '',
      DETIMPURENT_VEST: '',
      DETIMPURENT_VIVI: '',
      EMP_CODIGO: '',
      EMP_NOMBRE: '',
      NUEVO: true,
      NUMCAB: this.impurentService.detImpuRent.length
    };
    try {
      this.impurentService.detImpuRent.push(newModulo);
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
      const indice = this.impurentService.detImpuRent.indexOf(newModulo);
      setTimeout(() => {
        this.aggrid.gridApi.setFocusedCell(indice, 'EMP_CODIGO');
      }, 1000);


    } catch (err) {
    }
  }
  eliminardetRent() {
    if (this.selectednomdetImpuRent !== undefined) {
      this.confirmationService.confirm({
        message: '¿Está seguro que desea eliminar?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'NomdetImpRenta',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {
          try {
            this.impurentService.borrarimpurent(this.cabImpuRent.CABIMPURENT_CODIGO, this.selectednomdetImpuRent.DETIMPURENT_NUMSEC).then(async () => {
              this.mensajetoAlerta('success', 'Información', '¡Registro eliminado!');
              await this.cancelarImpuRent();
            });
          } catch (e) {
            let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
            this.mensajetoAlerta('error', 'Error', mensaje);
          }

          /* this.nonmaeRub.eliminarRubro(this.selectedNomMaeRub.MAERUBRO_CODIGO).then(res=>{
            this.mensajeAlerta('success', 'Información', '¡Registro eliminado!');
            this.cargar();
            this.auditoriaGralService.registrarAuditoria('nom_maerubro', this.selectedNomMaeRub.MAERUBRO_CODIGO, 'E',
          '', '01', '', '', '', '').toPromise().then(() => {
          });
          }).catch(e=>{
            let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
          }) */
        },
        reject: () => {
        }
      })
    }
  }
  async cancelarImpuRent() {
    let detImpuRentAux: nomdetImpuRent[] = [];
    this.impurentService.detImpuRent = [];
    this.cargoDatos = true;
    await this.impurentService.listdetimpurent(this.cabImpuRent.CABIMPURENT_CODIGO).then(async (as: any) => {
      detImpuRentAux = as;
      let i = 0;
      detImpuRentAux.map(x => {
        x.NUMCAB = i++;
        x.EMP_CODIGO = x.EMP_CODIGO == 0 ? "" : x.EMP_CODIGO;
        x.EMP_RUCIDE = x.EMP_CODIGO2;
        x.EMP_FECINGRESO = this.impurentService.cambiarfechanull(x.EMP_FECINGRESO);
        x.EMP_FECSALIDA = this.impurentService.cambiarfechanull(x.EMP_FECSALIDA);
        x.CABIMPURENT_CODIGO = this.cabImpuRent.CABIMPURENT_CODIGO;
        x.DETIMPURENT_ALIM = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_ALIM);
        x.DETIMPURENT_BASEIMPO = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_BASEIMPO);
        x.DETIMPURENT_D13 = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_D13);
        x.DETIMPURENT_D14 = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_D14);
        x.DETIMPURENT_DEDUCDISCA = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_DEDUCDISCA);
        x.DETIMPURENT_DEDUCTERCE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_DEDUCTERCE);
        x.DETIMPURENT_DEDUGPARTYCULT = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_DEDUGPARTYCULT);
        x.DETIMPURENT_EDUC = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_EDUC);
        x.DETIMPURENT_FRE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_FRE);
        x.DETIMPURENT_IESS = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IESS);
        x.DETIMPURENT_IESS_OE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IESS_OE);
        x.DETIMPURENT_IMPURENTASUM = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IMPURENTASUM);
        x.DETIMPURENT_INGRGRAV_OE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_INGRGRAV_OE);
        x.DETIMPURENT_IREASUM = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IREASUM);
        x.DETIMPURENT_IREASUM_PP = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IREASUM_PP);
        x.DETIMPURENT_IREPORRET = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IREPORRET);
        x.DETIMPURENT_IRERENT_OE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IRERENT_OE);
        x.DETIMPURENT_IRERET = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IRERET);
        x.DETIMPURENT_IRERET_PP = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IRERET_PP);
        x.DETIMPURENT_ORDEN = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_ORDEN);
        x.DETIMPURENT_OTROINGR = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR);
        x.DETIMPURENT_OTROINGR_NGAI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NGAI);
        x.DETIMPURENT_OTROINGR_NGNA = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NGNA);
        x.DETIMPURENT_OTROINGR_NGNI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NGNI);
        x.DETIMPURENT_OTROINGR_NI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NI);
        x.DETIMPURENT_SALDIGNO = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_SALDIGNO);
        x.DETIMPURENT_SALU = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_SALU);
        x.DETIMPURENT_SUELDO = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_SUELDO);
        x.DETIMPURENT_TOTAEGRE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_TOTAEGRE);
        x.DETIMPURENT_TOTAINGR = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_TOTAINGR);
        x.DETIMPURENT_UTI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_UTI);
        x.DETIMPURENT_VALOIMPURENT = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_VALOIMPURENT);
        x.DETIMPURENT_VEST = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_VEST);
        x.DETIMPURENT_VIVI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_VIVI);

      });
      this.impurentService.detImpuRent = detImpuRentAux;
      for (let ren of this.impurentService.detImpuRent) {
        this.funActualizaFloat(ren);
        this.funTotalBaseImponibleGrava(ren);
        this.funTotalIngresosGravaConEsteProveedor(ren);
        this.funTotalDeducciones(ren);
        this.funTotalIrePorRetener(ren);
        this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[ren.NUMCAB]);
      }
      this.activarbotones();
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
      this.aggrid.gridApi.setFocusedCell(0, 'EMP_CODIGO');
      this.cargoDatos = false;
    })
  }
  async manejarSeleccionbus(elemento) {
    this.displayDialogBusquedaFast = false;
    let detImpuRentAux: nomdetImpuRent[] = [];
    this.impurentService.detImpuRent = [];
    this.cargoDatos = true;
    this.cabImpuRent = { ...elemento };
    this.cabImpuRent.CABIMPURENT_ESTADO = (this.cabImpuRent.CABIMPURENT_ESTADO == null) ? 'EN PROCESO' : this.cabImpuRent.CABIMPURENT_ESTADO.CABIMPURENT_ESTADO;
    if (this.cabImpuRent.CABIMPURENT_ESTADO == null || this.cabImpuRent.CABIMPURENT_ESTADO == 'EN PROCESO' || this.cabImpuRent.CABIMPURENT_ESTADO == 'PROCESO') {
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
    } else {
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
    }
    await this.impurentService.listdetimpurent(this.cabImpuRent.CABIMPURENT_CODIGO).then(async (as: any) => {
      detImpuRentAux = as;
      let i = 0;
      detImpuRentAux.map(x => {
        x.NUMCAB = i++;
        x.EMP_CODIGO = x.EMP_CODIGO == 0 ? "" : x.EMP_CODIGO;
        x.EMP_RUCIDE = x.EMP_CODIGO2;
        x.EMP_FECINGRESO = this.impurentService.cambiarfechanull(x.EMP_FECINGRESO);
        x.EMP_FECSALIDA = this.impurentService.cambiarfechanull(x.EMP_FECSALIDA);
        x.DETIMPURENT_ALIM = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_ALIM);
        x.DETIMPURENT_BASEIMPO = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_BASEIMPO);
        x.DETIMPURENT_D13 = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_D13);
        x.DETIMPURENT_D14 = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_D14);
        x.DETIMPURENT_DEDUCDISCA = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_DEDUCDISCA);
        x.DETIMPURENT_DEDUCTERCE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_DEDUCTERCE);
        x.DETIMPURENT_DEDUGPARTYCULT = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_DEDUGPARTYCULT);
        x.DETIMPURENT_EDUC = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_EDUC);
        x.DETIMPURENT_FRE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_FRE);
        x.DETIMPURENT_IESS = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IESS);
        x.DETIMPURENT_IESS_OE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IESS_OE);
        x.DETIMPURENT_IMPURENTASUM = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IMPURENTASUM);
        x.DETIMPURENT_INGRGRAV_OE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_INGRGRAV_OE);
        x.DETIMPURENT_IREASUM = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IREASUM);
        x.DETIMPURENT_IREASUM_PP = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IREASUM_PP);
        x.DETIMPURENT_IREPORRET = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IREPORRET);
        x.DETIMPURENT_IRERENT_OE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IRERENT_OE);
        x.DETIMPURENT_IRERET = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IRERET);
        x.DETIMPURENT_IRERET_PP = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_IRERET_PP);
        x.DETIMPURENT_ORDEN = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_ORDEN);
        x.DETIMPURENT_OTROINGR = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR);
        x.DETIMPURENT_OTROINGR_NGAI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NGAI);
        x.DETIMPURENT_OTROINGR_NGNA = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NGNA);
        x.DETIMPURENT_OTROINGR_NGNI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NGNI);
        x.DETIMPURENT_OTROINGR_NI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_OTROINGR_NI);
        x.DETIMPURENT_SALDIGNO = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_SALDIGNO);
        x.DETIMPURENT_SALU = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_SALU);
        x.DETIMPURENT_SUELDO = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_SUELDO);
        x.DETIMPURENT_TOTAEGRE = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_TOTAEGRE);
        x.DETIMPURENT_TOTAINGR = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_TOTAINGR);
        x.DETIMPURENT_UTI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_UTI);
        x.DETIMPURENT_VALOIMPURENT = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_VALOIMPURENT);
        x.DETIMPURENT_VEST = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_VEST);
        x.DETIMPURENT_VIVI = this.impurentService.cambiarFloatFixed(x.DETIMPURENT_VIVI);
      });
      this.impurentService.detImpuRent = detImpuRentAux;
      for (let ren of this.impurentService.detImpuRent) {
        this.funActualizaFloat(ren);
        this.funTotalBaseImponibleGrava(ren);
        this.funTotalIngresosGravaConEsteProveedor(ren);
        this.funTotalDeducciones(ren);
        this.funTotalIrePorRetener(ren);
        this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[ren.NUMCAB]);
      }
      //this.impurentService.detImpuRent.sort((a,b)=>a.DETIMPURENT_NUMSEC-b.DETIMPURENT_NUMSEC);
      this.cargoDatos = false;
      this.activarbotones();
      //this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto );
      setTimeout(() => {
        this.aggrid.gridApi.setFocusedCell(0, 'EMP_CODIGO');
      }, 900);


    })
  }
  async busIDEmpl(parametro) {
    let resp = "";
    if (parametro == null || parametro == undefined) {
      parametro = '';
    }

    return new Promise<any>(async (resolve) => {
      await this.impurentService.erIDEmpl(parametro).then(eR => {
        if (eR !== null) {
          if (eR[0] !== undefined) {
            resolve(eR[0]);
          } else {
            resolve(resp);
          }
        } else {
          resolve(resp);
        }
      });
    });
  }
  buscarCondicion() {
    this.types = [
      { label: 'CÓDIGO', value: 'CABIMPURENT_CODIGO' },
      { label: 'DESCRIPCION', value: 'CABIMPURENT_DESCRIPCION' }
    ];
    this.busquedacampos = ['', ''];
    this.consulta = '';
    this.tabla = 'nom_cabimpurent';
    this.where = '';
    this.impurentService.btimpurent().then((x: any) => {

      this.arregloCons = x;
      this.displayDialogBusquedaFast = true;
    })
    /* this.nomempleadosService.busquedaTabla('*', 'nom_maeempleado ','').then((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    }); */

  }
  salirc(elemento) {
    console.log(elemento);
    if (elemento == 'salir') {
      this.cod = "";
      this.displayDialogClave = false;
    } else {

    }

  }
  autoriza(elemento) {
    console.log(elemento);
    switch (this.opcionret) {
      case 1:
        if (elemento == true) {
          this.impurentService.cambLiqui(this.cabImpuRent.CABIMPURENT_CODIGO).then(re => {
            console.log('liquidado');
            this.displayDialogClave = false;
            this.cod = "";
            this.cabImpuRent.CABIMPURENT_ESTADO = "LIQUIDADO";
            this.btnCamLiq = false;
            this.btnRevEstLiq = true;
            document.getElementById('divPrincipal').style.pointerEvents = 'none';
            this.activarboto = false;
          })
        } else {

        }
        break;
      case 2:
        if (elemento == true) {
          this.impurentService.reversarimpurent(this.cabImpuRent.CABIMPURENT_CODIGO).then(res => {
            console.log('en proceso');
            this.displayDialogClave = false;
            this.cod = "";
            this.cabImpuRent.CABIMPURENT_ESTADO = "EN PROCESO";
            this.btnCamLiq = true;
            this.btnRevEstLiq = false;
            document.getElementById('divPrincipal').style.pointerEvents = 'all';
            this.activarboto = true;
          })
        } else {

        }
        break;
    }
  }
  generarxml() {
    console.log(this.cabImpuRent.CABIMPURENT_ANIO);
    if (isNullOrUndefined(this.cabImpuRent.CABIMPURENT_ANIO) || this.cabImpuRent.CABIMPURENT_ANIO == "") {

    } else {
      this.displayDialogenexml = true;
      this.namexml = "";
    }
  }
  async recaImpuRent() {
    try {
      if (isNullOrUndefined(this.cabImpuRent.CABIMPURENT_ANIO) || this.cabImpuRent.CABIMPURENT_ANIO == "") {

      } else {
        const impurent = await this.impurentService.recaimpurent(this.cabImpuRent.CABIMPURENT_ANIO);
        await this.opcionRecargar();
      }
    } catch (e) {
      console.log(e.error);
      this.mensajetoAlerta('error', 'Error', e.error);
    }

  }

  cambLiqui() {
    console.log(this.cabImpuRent.CABIMPURENT_ANIO);
    if (isNullOrUndefined(this.cabImpuRent.CABIMPURENT_ANIO) || this.cabImpuRent.CABIMPURENT_ANIO == "") {

    } else {
      this.cod = 'CNFLIMR';
      this.displayDialogClave = true;
      this.opcionret = 1;
    }

  }
  reversar() {

    if (isNullOrUndefined(this.cabImpuRent.CABIMPURENT_ANIO) || this.cabImpuRent.CABIMPURENT_ANIO == "") {

    } else {
      this.cod = 'CNFLIMR';
      this.displayDialogClave = true;
      this.opcionret = 2;
    }

  }
  manejarSenalesgexml(valor) {
    switch (valor) {
      case 'Visto':
        this.opcionvisxml();
        break;
      case 'Salir':
        this.displayDialogenexml = false;

        break;
    }

  }
  //1generaxml
  async opcionvisxml() {
    try {
      const ge = await this.impurentService.generarxml(this.cabImpuRent.CABIMPURENT_ANIO, this.identiUsuario).then(res => {
        this.impurentService.genarchtexto(this.cabImpuRent.CABIMPURENT_ANIO).then((as: any) => {
          console.log(as);
          let xml = "";//[0]
          console.log(this.namexml);
          for (let xg of as) {
            console.log(xg);
            xml += xg.XMLREDEP_DESCLINEA + '\r\n';
          }
          const blob = new Blob([xml], { type: 'text/xml' });
          const element = document.createElement('a');
          element.setAttribute('download', this.generateName(this.namexml));
          element.setAttribute('href', window.URL.createObjectURL(blob));
          element.click();
          console.log(xml);
          this.displayDialogenexml = false;
          this.namexml = "";
          this.mensajetoAlerta('success', 'Información', '¡Proceso terminado!');
        })
      })
    } catch (e) {
      this.displayDialogenexml = false;
      this.namexml = "";
      this.mensajetoAlerta('error', 'Error', e.error);
    }
  }
  generateName(nombre: string) {
    if (nombre == "" || isNullOrUndefined(nombre)) {
      return this.impurentService.nombreAletorio();
    } else {
      return nombre;
    }

  }
  funActualizaMatrizConDatos(elemento) {
    this.impurentService.detImpuRent[elemento.NUMCAB];
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_ALIM = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_ALIM);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_BASEIMPO = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_BASEIMPO);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_D13 = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_D13);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_D14 = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_D14);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_DEDUCDISCA = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_DEDUCDISCA);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_DEDUCTERCE = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_DEDUCTERCE);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_DEDUGPARTYCULT = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_DEDUGPARTYCULT);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_EDUC = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_EDUC);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_FRE = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_FRE);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IESS = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IESS);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IESS_OE = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IESS_OE);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IMPURENTASUM = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IMPURENTASUM);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_INGRGRAV_OE = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_INGRGRAV_OE);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IREASUM = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IREASUM);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IREASUM_PP = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IREASUM_PP);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IREPORRET = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IREPORRET);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IRERENT_OE = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IRERENT_OE);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IRERET = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IRERET);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IRERET_PP = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IRERET_PP);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_ORDEN = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_ORDEN);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_OTROINGR = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_OTROINGR);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_OTROINGR_NGAI = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_OTROINGR_NGAI);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_OTROINGR_NGNA = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_OTROINGR_NGNA);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_OTROINGR_NGNI = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_OTROINGR_NGNI);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_OTROINGR_NI = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_OTROINGR_NI);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_SALDIGNO = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_SALDIGNO);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_SALU = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_SALU);
    //this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_SISTSAL=this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_SISTSAL);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_SUELDO = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_SUELDO);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_TOTAEGRE = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_TOTAEGRE);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_TOTAINGR = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_TOTAINGR);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_UTI = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_UTI);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_VALOIMPURENT = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_VALOIMPURENT);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_VEST = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_VEST);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_VIVI = this.impurentService.cambiarFloatFixed(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_VIVI);
    //this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto ); 
    //this.aggrid.gridApi.setFocusedCell(elemento.NUMCAB , 'DETIMPURENT_IREASUM');
  }
  funActualizaFloat(elemento) {
    this.impurentService.detImpuRent[elemento.NUMCAB];
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_ALIM = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_ALIM);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_BASEIMPO = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_BASEIMPO);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_D13 = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_D13);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_D14 = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_D14);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_DEDUCDISCA = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_DEDUCDISCA);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_DEDUCTERCE = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_DEDUCTERCE);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_DEDUGPARTYCULT = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_DEDUGPARTYCULT);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_EDUC = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_EDUC);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_FRE = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_FRE);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IESS = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IESS);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IESS_OE = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IESS_OE);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IMPURENTASUM = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IMPURENTASUM);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_INGRGRAV_OE = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_INGRGRAV_OE);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IREASUM = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IREASUM);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IREASUM_PP = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IREASUM_PP);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IREPORRET = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IREPORRET);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IRERENT_OE = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IRERENT_OE);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IRERET = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IRERET);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IRERET_PP = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IRERET_PP);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_ORDEN = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_ORDEN);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_OTROINGR = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_OTROINGR);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_OTROINGR_NGAI = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_OTROINGR_NGAI);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_OTROINGR_NGNA = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_OTROINGR_NGNA);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_OTROINGR_NGNI = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_OTROINGR_NGNI);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_OTROINGR_NI = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_OTROINGR_NI);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_SALDIGNO = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_SALDIGNO);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_SALU = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_SALU);
    //this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_SISTSAL=this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_SISTSAL);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_SUELDO = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_SUELDO);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_TOTAEGRE = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_TOTAEGRE);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_TOTAINGR = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_TOTAINGR);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_UTI = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_UTI);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_VALOIMPURENT = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_VALOIMPURENT);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_VEST = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_VEST);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_VIVI = this.impurentService.cambiarFloat(this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_VIVI);
    //this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto ); 
    //this.aggrid.gridApi.setFocusedCell(elemento.NUMCAB , 'DETIMPURENT_IREASUM');
  }
  funTotalBaseImponibleGrava(elemento: nomdetImpuRent) {
    let totalDeducciones: number = 0;
    let par: number = 0;
    totalDeducciones = elemento.DETIMPURENT_INGRGRAV_OE + elemento.DETIMPURENT_TOTAINGR;
    par = elemento.DETIMPURENT_IESS;
    par += elemento.DETIMPURENT_IESS_OE + elemento.DETIMPURENT_TOTAEGRE;
    par += elemento.DETIMPURENT_DEDUCDISCA + elemento.DETIMPURENT_DEDUCTERCE;
    let tot = totalDeducciones - par;
    console.log(tot);
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_BASEIMPO = tot;
    //this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto ); 
  }
  funTotalIngresosGravaConEsteProveedor(elemento: nomdetImpuRent) {
    let totalIngGravaConEsteProve: number = 0;
    totalIngGravaConEsteProve = elemento.DETIMPURENT_SUELDO;
    totalIngGravaConEsteProve += elemento.DETIMPURENT_OTROINGR + elemento.DETIMPURENT_OTROINGR_NI;
    totalIngGravaConEsteProve += elemento.DETIMPURENT_IREASUM + elemento.DETIMPURENT_UTI;
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_TOTAINGR = elemento.DETIMPURENT_TOTAINGR = totalIngGravaConEsteProve;
  }
  funTotalDeducciones(elemento: nomdetImpuRent) {
    let totalDeducciones: number = 0;
    totalDeducciones = elemento.DETIMPURENT_VIVI;
    totalDeducciones += elemento.DETIMPURENT_SALU + elemento.DETIMPURENT_EDUC;
    totalDeducciones += elemento.DETIMPURENT_ALIM + elemento.DETIMPURENT_VEST;
    totalDeducciones += elemento.DETIMPURENT_DEDUGPARTYCULT;
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_TOTAEGRE = totalDeducciones;
  }
  funTotalIrePorRetener(elemento: nomdetImpuRent) {
    let totalDeducciones: number = 0;
    let parci: number = 0;
    parci = elemento.DETIMPURENT_IRERENT_OE + elemento.DETIMPURENT_IMPURENTASUM;
    parci += elemento.DETIMPURENT_IREASUM_PP + elemento.DETIMPURENT_IRERET;
    parci += elemento.DETIMPURENT_IRERET_PP;
    totalDeducciones = elemento.DETIMPURENT_VALOIMPURENT - parci;
    this.impurentService.detImpuRent[elemento.NUMCAB].DETIMPURENT_IREPORRET = totalDeducciones;
  }
  //funTotalBaseImponibleGrava
  //funTotalIngresosGravaConEsteProveedor
  //funTotalDeducciones
  //funTotalIrePorRetener
  pruebas(params) {
    console.log(params);
  }
  //azaim
  cellcambios(params) {
    let cmb: nomdetImpuRent = params.data;
    console.log(cmb);
    console.log(params.colDef.field);
    if (params.colDef.field == "DETIMPURENT_UTI") {
      this.funActualizaFloat(cmb);
      console.log(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalIngresosGravaConEsteProveedor(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalDeducciones(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
      this.aggrid.gridApi.setFocusedCell(cmb.NUMCAB, 'DETIMPURENT_UTI');
    }
  }
  cellcambios22to27(params) {
    let cmb: nomdetImpuRent = params.data;
    console.log(cmb);
    console.log(params.colDef.field);

    if (params.colDef.field == "DETIMPURENT_VIVI") {
      this.funActualizaFloat(cmb);
      console.log(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalDeducciones(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalBaseImponibleGrava(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
      this.aggrid.gridApi.setFocusedCell(cmb.NUMCAB, 'DETIMPURENT_VIVI');
    }
    if (params.colDef.field == "DETIMPURENT_SALU") {
      this.funActualizaFloat(cmb);
      console.log(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalDeducciones(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalBaseImponibleGrava(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
      this.aggrid.gridApi.setFocusedCell(cmb.NUMCAB, 'DETIMPURENT_SALU');
    }
    if (params.colDef.field == "DETIMPURENT_EDUC") {
      this.funActualizaFloat(cmb);
      console.log(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalDeducciones(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalBaseImponibleGrava(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
      this.aggrid.gridApi.setFocusedCell(cmb.NUMCAB, 'DETIMPURENT_EDUC');
    }
    if (params.colDef.field == "DETIMPURENT_ALIM") {
      this.funActualizaFloat(cmb);
      console.log(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalDeducciones(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalBaseImponibleGrava(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
      this.aggrid.gridApi.setFocusedCell(cmb.NUMCAB, 'DETIMPURENT_ALIM');
    }
    if (params.colDef.field == "DETIMPURENT_VEST") {
      this.funActualizaFloat(cmb);
      console.log(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalDeducciones(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalBaseImponibleGrava(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
      this.aggrid.gridApi.setFocusedCell(cmb.NUMCAB, 'DETIMPURENT_VEST');
    }
    if (params.colDef.field == "DETIMPURENT_DEDUGPARTYCULT") {
      this.funActualizaFloat(cmb);
      console.log(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalDeducciones(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalBaseImponibleGrava(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
      this.aggrid.gridApi.setFocusedCell(cmb.NUMCAB, 'DETIMPURENT_DEDUGPARTYCULT');
    }
  }
  cellcambios10_19_20_21_31_32_33(params) {
    let cmb: nomdetImpuRent = params.data;
    console.log(cmb);
    console.log(params.colDef.field);
    //DETIMPURENT_INGRGRAV_OE//DETIMPURENT_TOTAINGR
    //DETIMPURENT_IESS//DETIMPURENT_IESS_OE
    //DETIMPURENT_TOTAEGRE//DETIMPURENT_DEDUCDISCA
    //DETIMPURENT_DEDUCTERCE
    if (params.colDef.field == "DETIMPURENT_INGRGRAV_OE") {
      this.funActualizaFloat(cmb);
      console.log(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalBaseImponibleGrava(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
      this.aggrid.gridApi.setFocusedCell(cmb.NUMCAB, 'DETIMPURENT_INGRGRAV_OE');
    }
    if (params.colDef.field == "DETIMPURENT_TOTAINGR") {
      this.funActualizaFloat(cmb);
      console.log(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalBaseImponibleGrava(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
      this.aggrid.gridApi.setFocusedCell(cmb.NUMCAB, 'DETIMPURENT_TOTAINGR');
    }
    if (params.colDef.field == "DETIMPURENT_IESS") {
      this.funActualizaFloat(cmb);
      console.log(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalBaseImponibleGrava(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
      this.aggrid.gridApi.setFocusedCell(cmb.NUMCAB, 'DETIMPURENT_IESS');
    }
    if (params.colDef.field == "DETIMPURENT_IESS_OE") {
      this.funActualizaFloat(cmb);
      console.log(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalBaseImponibleGrava(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
      this.aggrid.gridApi.setFocusedCell(cmb.NUMCAB, 'DETIMPURENT_IESS_OE');
    }
    if (params.colDef.field == "DETIMPURENT_TOTAEGRE") {
      this.funActualizaFloat(cmb);
      console.log(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalBaseImponibleGrava(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
      this.aggrid.gridApi.setFocusedCell(cmb.NUMCAB, 'DETIMPURENT_TOTAEGRE');
    }
    if (params.colDef.field == "DETIMPURENT_DEDUCDISCA") {
      this.funActualizaFloat(cmb);
      console.log(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalBaseImponibleGrava(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
      this.aggrid.gridApi.setFocusedCell(cmb.NUMCAB, 'DETIMPURENT_DEDUCDISCA');
    }
    if (params.colDef.field == "DETIMPURENT_DEDUCTERCE") {
      this.funActualizaFloat(cmb);
      console.log(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalBaseImponibleGrava(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
      this.aggrid.gridApi.setFocusedCell(cmb.NUMCAB, 'DETIMPURENT_DEDUCTERCE');
    }
  }
  cellcambios35_36_37_38_39_40(params) {
    let cmb: nomdetImpuRent = params.data;
    console.log(cmb);
    console.log(params.colDef.field);
    if (params.colDef.field == "DETIMPURENT_VALOIMPURENT") {
      this.funActualizaFloat(cmb);
      console.log(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalIrePorRetener(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
      this.aggrid.gridApi.setFocusedCell(cmb.NUMCAB, 'DETIMPURENT_VALOIMPURENT');
    }
    if (params.colDef.field == "DETIMPURENT_IRERENT_OE") {
      this.funActualizaFloat(cmb);
      console.log(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalIrePorRetener(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
      this.aggrid.gridApi.setFocusedCell(cmb.NUMCAB, 'DETIMPURENT_IRERENT_OE');
    }
    if (params.colDef.field == "DETIMPURENT_IREASUM_PP") {
      this.funActualizaFloat(cmb);
      console.log(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalIrePorRetener(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
      this.aggrid.gridApi.setFocusedCell(cmb.NUMCAB, 'DETIMPURENT_IREASUM_PP');
    }
    if (params.colDef.field == "DETIMPURENT_IMPURENTASUM") {
      this.funActualizaFloat(cmb);
      console.log(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalIrePorRetener(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
      this.aggrid.gridApi.setFocusedCell(cmb.NUMCAB, 'DETIMPURENT_IMPURENTASUM');
    }
    if (params.colDef.field == "DETIMPURENT_IRERET_PP") {
      this.funActualizaFloat(cmb);
      console.log(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalIrePorRetener(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
      this.aggrid.gridApi.setFocusedCell(cmb.NUMCAB, 'DETIMPURENT_IRERET_PP');
    }
    if (params.colDef.field == "DETIMPURENT_IRERET") {
      this.funActualizaFloat(cmb);
      console.log(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funTotalIrePorRetener(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
      this.aggrid.gridApi.setFocusedCell(cmb.NUMCAB, 'DETIMPURENT_IRERET');
    }
  }
  cellcambios11(params) {
    let cmb: nomdetImpuRent = params.data;
    console.log(cmb);
    console.log(params.colDef.field);
    if (params.colDef.field == "DETIMPURENT_SALDIGNO") {
      this.funActualizaMatrizConDatos(this.impurentService.detImpuRent[cmb.NUMCAB]);
      this.aggrid.refreshaggrid(this.impurentService.detImpuRent, this.defaultColDefDetImpuRent.objeto);
      this.aggrid.gridApi.setFocusedCell(cmb.NUMCAB, 'DETIMPURENT_SALDIGNO');
    }
  }
  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'autorizacion',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });

  }
  mensajetoAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'inpurent',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
  }

  cerrarReportes() {
    this.displayDialogReportes = false;
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos  = false;
    }
  }

}
