<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="trnmodificarCaja"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="trnmodificarCajaConf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
						 [botonBorrar]="btnBorrar" [barraBotonesDesplaz]="true" [barraBotones3]="true"
						 [barraBotonesAsiCon]="false" [botonverAsiento]="false"
						 (signal)="manejarSenales($event)">
		</app-invmaebarra>
	</div>
</div>
<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px; width: 99%;">
	<div class="p-col-12">
		<div class="p-grid">
			<div class="p-col-8"></div>
			<div class="p-col-2">
				<span>Nro. de Asiento:</span>
			</div>
			<div class="p-col-2" style="margin-bottom: 5px;">
				<input type="text" pInputText style="color: mediumblue; text-align: center; font-weight: bold" disabled [(ngModel)]="lblAsiNro">
			</div>
		</div>
	</div>
</div>

<p-tabView (onChange)="onTab1Change($event)" [activeIndex]="selectTab">
	<p-tabPanel header="Movimientos de Caja">
		<div class="divgrups" id="divPrincipal">
			<div class="p-grid">
				<!--LINE 1-->
				<div class="p-col-1">
					<span>Caja Nro.:</span>
				</div>
				<div class="p-col-1">
					<input id="codCaja" type="text" pInputText autocomplete="off" [(ngModel)]="txtCajCodigo" (input)="cambio($event)"
						   (keydown.enter)="cambiarFoco($event)" [disabled]="disabledCajCodigo">
				</div>
				<div class="p-col-2" style="text-align: left">
					<input id="nombreCaja" type="text" pInputText disabled autocomplete="off" [(ngModel)]="lblDato">
				</div>
				<div class="p-col-1">
					<button type="button"
							style="background-image: url(../../../assets/images/iconos/buscar.png);" (click)="buscarCaja(txtCajCodigo)" [hidden]="hiddenBtnCajCod"></button>
				</div>
				<div class="p-col-1">
					<span>Fecha:</span>
				</div>
				<div class="p-col-2" style="text-align: left">
					<p-calendar id="fecha" [inputStyle]="{width: '70%', height: '20px'}"
								dateFormat="dd/mm/yy" [(ngModel)]="dateTimeFecha">
					</p-calendar>
				</div>

				<!--LINE 2-->
				<div class="p-col-12" style="margin-top: -10px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Tipo:</span>
						</div>
						<div class="p-col-2" style="text-align: left">
							<p-dropdown optionLabel="name" inputId="cmbTipo" [options]="cmbTipo" [(ngModel)]="strTipoCmb"
										(onChange)="cambioCmb($event)" (keydown.enter)="cambiarFoco($event)" [disabled]="disabledCmbTipo"></p-dropdown>
						</div>
						<div class="p-col-2">
						</div>
						<div class="p-col-1" [hidden]="boolVisibleFechaVen">
							<span>Fecha Cobro:</span>
						</div>
						<div class="p-col-2" style="text-align: left" [hidden]="boolVisibleFechaVen">
							<p-calendar id="fechaCobro" [inputStyle]="{width: '70%', height: '20px'}"
										dateFormat="dd/mm/yy" [(ngModel)]="dateTimeFechaVencimiento">
							</p-calendar>
						</div>
						<div class="p-col-1">
						</div>
					</div>
				</div>
				<!--LINE 3-->
				<div class="p-col-12" style="margin-top: -10px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Número:</span>
						</div>
						<div class="p-col-2">
							<input id="numero" type="text" pInputText [(ngModel)]="txtTrnCajNumero" (input)="cambio($event)"
								   (keydown.enter)="cambiarFoco($event)" [disabled]="disabledTxtNumero">
						</div>
						<div class="p-col-2">
						</div>
						<div class="p-col-2">
							<span>Banco:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<input id="bancoCodigo" type="text" pInputText autocomplete="off" [(ngModel)]="txtCodReferencia" [disabled] = "disabledBanCod" (input)="cambio($event)">
						</div>
						<div class="p-col-1" style="text-align: left">
							<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);" [disabled]="disabledBanCod" (click)="buscarBancos(txtCodReferencia)"></button>
						</div>
						<div class="p-col-2" style="text-align: left">
							<input id="nombreBanco" type="text" pInputText disabled autocomplete="off" [(ngModel)]="lblDescripcionRef">
						</div>
					</div>
				</div>
				<!--LINE 3-->
				<div class="p-col-12" style="margin-top: -17px;">
					<div class="p-grid">
							<div class="p-col-1">
								<span>Importe:</span>
							</div>
							<div class="p-col-1">
								<input id="importe" type="text" pInputText [(ngModel)]="txtTrnCajImporte" style="text-align: right" (input)="cambio($event)"
									   (keydown.enter)="cambiarFoco($event)">
							</div>
							<div class="p-col-3">
							</div>
							<div class="p-col-2">
								<span>Centro de Costos:</span>
							</div>
							<div class="p-col-1" style="text-align: left">
								<input id="cenCostos" type="text" pInputText autocomplete="off" [(ngModel)]="txtCenCodigo" (input)="cambio($event)">
							</div>
							<div class="p-col-1" style="text-align: left">
								<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);" (click)="buscarCenCosto(txtCenCodigo)"></button>
							</div>
							<div class="p-col-2" style="text-align: left">
								<input id="nombreCenCosto" type="text" pInputText disabled autocomplete="off" [(ngModel)]="lblCc">
							</div>
					</div>
				</div>
				<!--LINE 4-->
				<div class="p-col-12" style="margin-top: -17px;">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Descripción:</span>
						</div>
						<div class="p-col-5">
							<textarea id="descripcion" pInputTextarea  [rows]="3" [cols]="37" [(ngModel)]="txtTrnCajDescripcion" (input)="cambio($event)" (keydown.enter)="cambiarFoco($event)"></textarea>
<!--							<input id="descripcion" type="text" pInputText [(ngModel)]="txtTrnCajDescripcion" (input)="cambio($event)" (keydown.enter)="cambiarFoco($event)">-->
						</div>
					</div>
				</div>
				<!--LINE 4-->
				<div class="p-col-12" style="margin-top: -10px;">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Anulado:</span>
						</div>
						<div class="p-col-1">
							<p-checkbox binary="true" [(ngModel)]="btnChkTrnCajAnulado" (onChange)="cambioAnulado($event)">
							</p-checkbox>
						</div>
					</div>
				</div>
			</div>
			<div style="height: 50px;">

			</div>
			<div id="divPrincipal2" style="height: 75px;" >
				<div class="p-grid" *ngIf="groupDatosOrigen === true">
					<!--LINE 1-->
					<div class="p-col-1">
						<span>Origen:</span>
					</div>
					<div class="p-col-2">
						<input id="origen" type="text" pInputText autocomplete="off" style="text-align: center" disabled [(ngModel)]="lblTrnCajOrigen">
					</div>
					<div class="p-col-1">
						<span>Doc Nro:</span>
					</div>
					<div class="p-col-2" style="text-align: left">
						<input id="docNro" type="text" pInputText autocomplete="off" style="text-align: center" disabled [(ngModel)]="lblTrnCajDocNro">
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
								<span>Deposito Nro:</span>
							</div>
							<div class="p-col-2">
								<input id="depositoNro" type="text" pInputText autocomplete="off" style="text-align: center" disabled [(ngModel)]="lblTrnDepositoNro">
							</div>
							<div class="p-col-1">
								<span>Banco:</span>
							</div>
							<div class="p-col-2" style="text-align: left" style="margin-bottom: -15px;">
								<input id="banco" type="text" pInputText autocomplete="off" style="text-align: center" disabled [(ngModel)]="lblBanNombre">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Varios" [disabled]="boolIndex1">
		<app-aggridsaci [width]=""
						[height]="largo"
						[enableSorting]="true"
						[enableFilter]="true"
						[rowData]="bantrnCajaService.detModeloVarCaja"
						[frameworkComponents]="frameworkComponents"
						[animateRows]="true"
						[rowSelection]="rowSelection"
						[columnDefs]="columnDefsBanCajVarios"
						[defaultColDef]="defaultBanCajVarios"
						[rowStyle]="rowStyle"
						[singleClickEdit]="false"
						[botones]="true"
						[mostrarGuardar]="false"
						(selected)="seleccionDetVarios($event)"
						(cambios)="cambiogriddetvar($event)"
						[botonNuevo]="btnNuevoGrid"
						[botonBorrar]="btnBorrarGrid"
						[botonRegresar]="btnCancelarGrid">
		</app-aggridsaci>
	</p-tabPanel>
</p-tabView>
<div class="divgrupstabla">
	<!--				<div class="p-col-12">-->
	<div class="p-grid">
		<div class="p-col-5"></div>
		<div class="p-col-1">
			<span class="obligatorio">Importe: </span>
		</div>
		<div class="p-col-1">
			<input class="totales" type="text" pInputText [disabled]="true" [(ngModel)]="txtComprobImporte">
		</div>
		<div class="p-col-5"></div>
	</div>
	<!--				</div>-->
</div>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBus"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [closable]="false"
		  [style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Clave Autorización" [(visible)]="claveDialog"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '40vw', height: 'auto'} ">
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							   (keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>
<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width:'80vw', height:'120vh'}"
		  [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="NumAsiRegCont" [abiertoDesde]="'bantrnTarjeta'"
					(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>

