import { Component, OnInit, ViewChild } from '@angular/core';
import { VenDectraped } from '../veninterfaces/vendectraped';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';

import { AggridBanComponent } from 'src/app/ban/aggrid-ban/aggrid-ban.component';
import { VentransforpedService } from '../venservicios/ventransforped.service';
import { DatePipe } from '@angular/common';
import { SelectItem, MessageService, ConfirmationService } from 'primeng';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { GenerarpedidosService } from '../venservicios/generarpedidos.service';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';


@Component({
  selector: 'app-ventransforped',
  templateUrl: './ventransforped.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class VentransforpedComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  hmpConfiguracion: any[];
  indicador: any;

  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnBuscar: boolean;
  btnRegresar: boolean;
  btnPrimero: boolean;
  btnAnterior: boolean;
  btnSiguiente: boolean;
  btnUltimo: boolean;

  chkseltodo: any;
  chkselpedido: any;

  data: string;
  fecha = new Date();

  TxtPedidosTo = 0;
  TxtFacturasGen = 0;
  TxtPedidosNo = 0;

  strNumActual = '';
  strNemonico = '';
  strSerie = '';
  strNumAut = '';
  strGrupo = '';

  // dialogs  
  displayDialogBusquedaFast: boolean;
  displayDialogAutorizacion: boolean;

  // dropdowns
  cmbInCriteria: any[];
  selectcombo: any;
  txtCriteria: string;

  // busqueda
  opcionbusqueda: string;
  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];
  tabla: string;
  where: string;

  // autorizacion
  claveAuth: string;
  autorizacionCodigo: string;
  bolAutorizacion: boolean;

  // aggrid
  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  defaultColDef;
  largo: string;

  columnDefs = [
    {
      headerName: 'S.', field: 'CHECK',
      cellRendererFramework: AggridBanComponent,
      editable: false,
      width: 50,
      onCellClicked: (params) => {
        this.seleccionItem(params.data);
      }
    },
    {
      headerName: 'Número Pedido',
      field: 'DECTRAPED_NUMERO',
      editable: false,
      width: 175
    },
    {
      headerName: 'Factura',
      field: 'DECTRAPED_ENCFACNUMERO',
      editable: false,
      width: 250,
      cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'Comprobante',
      field: 'DECTRAPED_COMPROBANTE',
      editable: false,
      width: 175
    },
    {
      headerName: 'Fecha Emisión',
      field: 'DECTRAPED_FECHAEMISION',
      editable: false,
      width: 175
    },
    {
      headerName: 'Est.',
      field: 'DECTRAPED_ESTADO',
      editable: false,
      width: 175
    },
    {
      headerName: 'Cliente',
      field: 'CLI_NOMBRE',
      editable: false,
      width: 175
    },
    {
      headerName: 'Cód. Cliente',
      field: 'DECTRAPED_CLICODIGO',
      editable: false,
      width: 175
    },
    {
      headerName: 'Grupo',
      field: 'GRU_CODIGO',
      editable: false,
      width: 175
    },
    {
      headerName: 'Cód. Vendedor',
      field: 'DECTRAPED_VENCODIGO',
      editable: false,
      width: 175
    },
    {
      headerName: 'Observación',
      field: 'DECTRAPED_OBSERVACION',
      editable: false,
      width: 175
    }
  ]

  constructor(public transforpedService: VentransforpedService, private init: NuevoComponentService,
    private datePipe: DatePipe, private busqService: InvbusquedaService,
    private messageService: MessageService, private auditoriagral: AuditoriagralService,
    private genpedservice: GenerarpedidosService, private confirmationService: ConfirmationService,
    private utilitariosService: UtilitariosService) {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultColDef = {
      editable: true,
      width: 100,
      objeto: 'transforped',
      resizable: true
    };
  }

  ngOnInit(): void {
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.btnPrimero = false;
    this.btnAnterior = false;
    this.btnSiguiente = false;
    this.btnUltimo = false;
    this.btnBuscar = false;

    this.largo = '430';
    this.limpiarDatos();
    this.mostrarPedidos('');

    this.cmbInCriteria = [{ codigo: '', name: '' }];
    for (let rs of this.columnDefs) {
      this.cmbInCriteria.push({ codigo: rs.headerName, name: rs.headerName });
    }
    this.txtCriteria = '';

    this.auditoriagral.obtenerConfiguracion1().subscribe((conf) => {
      this.hmpConfiguracion = conf;
    });
  }

  limpiarDatos() {
    this.transforpedService.venEnctraped[this.indicador] = {
      ENCTRAPED_CODIGO: '',
      COM_CODIGO: '01',
      ENCTRAPED_FECHAEMISION: new Date(),
      ENCTRAPED_COMENTARIO: ''
    };
    this.transforpedService.lstVenEncPed[this.indicador] = [];
    this.txtCriteria = '';
  }

  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      this.limpiarDatos();
      this.mostrarPedidos('');
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = true;
      document.getElementById('COMENTARIO').focus();
    }
    if (valor === 'Guardar') {
      this.guardarRegistro();
    }
    if (valor === 'Borrar') {
      this.eliminarRegistro();
    }
    if (valor === 'Cancelar') {
      this.cancelar();
    }
    if (valor === 'Primero') {
      this.seleccionarRegistro('P');
    }
    if (valor === 'Anterior') {
      this.seleccionarRegistro('A');
    }
    if (valor === 'Siguiente') {
      this.seleccionarRegistro('S');
    }
    if (valor === 'Ultimo') {
      this.seleccionarRegistro('U');
    }
    if (valor === 'Visto') {
      this.cargarPedidosenFacturas();
    }
    if (valor === 'Buscar') {
      this.buscar();
    }
    if (valor === 'Salir') {
      this.salir();
    }
  }

  cancelar() {
    const strVenEncCom = this.transforpedService.venEnctraped[this.indicador].ENCTRAPED_CODIGO;
    if (strVenEncCom != "") {
      this.mostrarDatos(strVenEncCom);
      this.mostrarPedidos(strVenEncCom);
    }
  }

  async seleccionarRegistro(strTipo) {
    const data = await this.transforpedService.navegarregistro(strTipo, this.transforpedService.venEnctraped[this.indicador].ENCTRAPED_CODIGO);
    let strVenEncCom = "";
    let i = 0;
    if (data !== null) {
      for (const rs of data) {
        strVenEncCom = rs.ENCTRAPED_CODIGO == null ? "" : rs.ENCTRAPED_CODIGO;
        break;
      }
    }
    if (strVenEncCom != "") {
      this.mostrarDatos(strVenEncCom);
      this.mostrarPedidos(strVenEncCom);
    }
  }

  eliminarRegistro() {
    let strComprobante = this.transforpedService.venEnctraped[this.indicador].ENCTRAPED_CODIGO;
    if (strComprobante.length > 0) {

      for (const m of this.transforpedService.lstVenEncPed[this.indicador]) {
        if (m.DECTRAPED_ENCFACNUMERO !== '') {
          this.messageService.add({
            key: 'tranforped',
            severity: 'error',
            summary: 'Información',
            detail: "No es posible eliminar. Esta transformación ya tiene facturas generadas."
          });
          return;
        }
      }

      this.confirmationService.confirm({
        message: "Está seguro de "
          + "eliminar la transformación " + strComprobante
          + "?",
        header: 'Reversar Pago',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmartranforped',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: async () => {
          await this.transforpedService.eliminartransforped(strComprobante);
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.btnRegresar = true;;
          this.limpiarDatos();
        },
        reject: () => {
        }
      });
    } else {
      this.messageService.add({
        key: 'tranforped',
        severity: 'error',
        summary: 'Información',
        detail: 'No hay un documento para la eliminación.'
      });
    }
  }



  buscar() {
    this.opcionbusqueda = 'enctraped';
    this.types = [
      { label: 'Código', value: 'ENCTRAPED_CODIGO' },
      { label: 'Fecha', value: 'ENCTRAPED_FECHAEMISION' },
      { label: 'Comentario', value: 'ENCTRAPED_COMENTARIO' },
    ];
    this.busquedacampos = ['', '', ''];
    this.tabla = 'VEN_ENCTRAPED';
    this.where = '';

    this.busqService.busquedaVenEncTraPed1().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.arregloCons.map((registro) => {
        if (registro.ENCTRAPED_FECHAEMISION !== null) {
          registro.ENCTRAPED_FECHAEMISION = this.datePipe.transform(registro.ENCTRAPED_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          registro.ENCTRAPED_FECHAEMISION = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });
  }

  manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'enctraped') {
      this.mostrarDatos(opcion.ENCTRAPED_CODIGO);
      this.mostrarPedidos(opcion.ENCTRAPED_CODIGO);
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
    }
    if (this.opcionbusqueda === 'numeracion') {
      this.strNumActual = opcion.NUM_SECACTUAL;
      this.strNemonico = opcion.NUM_NEMONICO;
      this.strSerie = opcion.NUM_SERIE;
      this.strNumAut = opcion.NUM_AUTORIZACION;
      this.strGrupo = opcion.NUM_CLASE;
      this.continuarCarga();
    }
    this.displayDialogBusquedaFast = false;
  }

  async mostrarDatos(strComprobante) {
    this.limpiarDatos();
    const data = await this.transforpedService.mostrarenctraped(strComprobante);
    for (const rs of data) {
      this.transforpedService.venEnctraped[this.indicador].ENCTRAPED_CODIGO = rs.ENCTRAPED_CODIGO == null ? '' : rs.ENCTRAPED_CODIGO;
      this.transforpedService.venEnctraped[this.indicador].COM_CODIGO = '01';
      this.transforpedService.venEnctraped[this.indicador].ENCTRAPED_FECHAEMISION = new Date(rs.ENCTRAPED_FECHAEMISION);
      this.transforpedService.venEnctraped[this.indicador].ENCTRAPED_COMENTARIO = rs.ENCTRAPED_COMENTARIO == null ? '' : rs.ENCTRAPED_COMENTARIO;
      this.selectcombo = { codigo: '', name: '' }
      this.txtCriteria = '';
    }
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
  }

  setFocus(valor) {
  }

  botonesmodificar() {
  }

  salir() {
    this.init.tabs.splice(this.init.tabindex, 1);
  }

  seleccionItem(valor) {
    console.log(valor);
  }

  selecciontabla(valor) {
    console.log(valor);
  }

  cambio(valor) {
    console.log(valor);
  }

  async mostrarPedidos(NroComprobante) {
    this.transforpedService.lstVenEncPed[this.indicador] = [];
    if (NroComprobante.length === 0) {
      const data = await this.transforpedService.mostrarpedidos(true);
      for (const rs of data) {
        let item: VenDectraped = {};
        item.CHECK = false;
        item.DECTRAPED_NUMERO = rs.ENCPED_NUMERO === null ? '' : rs.ENCPED_NUMERO;
        item.DECTRAPED_COMPROBANTE = rs.ENCPED_REFERENCIA === null ? '' : rs.ENCPED_REFERENCIA;
        item.DECTRAPED_FECHAEMISION = this.datePipe.transform(rs.ENCPED_FECHAEMISION, 'dd/MM/yyyy');
        item.DECTRAPED_ESTADO = rs.ENCPED_ESTADO === null ? '' : rs.ENCPED_ESTADO;
        item.DECTRAPED_CLICODIGO = rs.CLI_CODIGO === null ? '' : rs.CLI_CODIGO;
        item.CLI_NOMBRE = rs.CLI_NOMBRE === null ? '' : rs.CLI_NOMBRE;
        item.DECTRAPED_VENCODIGO = rs.VEN_CODIGO === null ? '' : rs.VEN_CODIGO;
        item.DECTRAPED_OBSERVACION = rs.ENCPED_OBSERVACION === null ? '' : rs.ENCPED_OBSERVACION;
        item.GRU_CODIGO = rs.GRU_CODIGO === null ? '' : rs.GRU_CODIGO;
        item.DECTRAPED_ENCFACNUMERO = '';
        this.transforpedService.lstVenEncPed[this.indicador].push(item);
      }
      this.aggrid.refreshaggrid(this.transforpedService.lstVenEncPed[this.indicador], this.defaultColDef.objeto);
    } else {
      const data = await this.transforpedService.mostrardectraped(NroComprobante);
      for (const rs of data) {
        let item: VenDectraped = {};
        item.CHECK = false;
        item.DECTRAPED_NUMERO = rs.DECTRAPED_NUMERO === null ? '' : rs.DECTRAPED_NUMERO;
        item.DECTRAPED_COMPROBANTE = rs.DECTRAPED_COMPROBANTE === null ? '' : rs.DECTRAPED_COMPROBANTE;
        item.DECTRAPED_FECHAEMISION = this.datePipe.transform(rs.DECTRAPED_FECHAEMISION, 'dd/MM/yyyy');
        item.DECTRAPED_ESTADO = rs.DECTRAPED_ESTADO === null ? '' : rs.DECTRAPED_ESTADO;
        item.DECTRAPED_CLICODIGO = rs.DECTRAPED_CLICODIGO === null ? '' : rs.DECTRAPED_CLICODIGO;
        item.CLI_NOMBRE = rs.CLI_NOMBRE === null ? '' : rs.CLI_NOMBRE;
        item.DECTRAPED_VENCODIGO = rs.DECTRAPED_VENCODIGO === null ? '' : rs.DECTRAPED_VENCODIGO;
        item.DECTRAPED_OBSERVACION = rs.DECTRAPED_OBSERVACION === null ? '' : rs.DECTRAPED_OBSERVACION;
        item.GRU_CODIGO = rs.GRU_CODIGO === null ? '' : rs.GRU_CODIGO;
        item.DECTRAPED_ENCFACNUMERO = rs.DECTRAPED_ENCFACNUMERO === null ? '' : rs.DECTRAPED_ENCFACNUMERO;
        this.transforpedService.lstVenEncPed[this.indicador].push(item);
      }
      this.aggrid.refreshaggrid(this.transforpedService.lstVenEncPed[this.indicador], this.defaultColDef.objeto);
    }
    this.aggrid.visualizarArriba(this.defaultColDef.objeto);

    this.TxtPedidosTo = this.transforpedService.lstVenEncPed[this.indicador].length;
    let cont1 = 0, cont2 = 0;
    for (let w = 0; w < this.transforpedService.lstVenEncPed[this.indicador].length; w++) {
      if (this.transforpedService.lstVenEncPed[this.indicador][w].DECTRAPED_ENCFACNUMERO === "")
        cont2++;
      else
        cont1++;
    }
    this.TxtFacturasGen = cont1;
    this.TxtPedidosNo = cont2;
  }

  selectTodosFact() {
    for (let intIndiceLista = 0; intIndiceLista < this.transforpedService.lstVenEncPed[this.indicador].length; intIndiceLista++) {
      if (this.chkseltodo.length === 0) {
        this.transforpedService.lstVenEncPed[this.indicador][intIndiceLista].CHECK = false;
      } else {
        this.transforpedService.lstVenEncPed[this.indicador][intIndiceLista].CHECK = true;
      }
    }
    this.aggrid.refreshaggrid(this.transforpedService.lstVenEncPed[this.indicador], this.defaultColDef.objeto);
    this.aggrid.visualizarArriba(this.defaultColDef.objeto);
  }

  selectPedidos() {
    for (let intIndiceLista = 0; intIndiceLista < this.transforpedService.lstVenEncPed[this.indicador].length; intIndiceLista++) {
      if (this.transforpedService.lstVenEncPed[this.indicador][intIndiceLista].DECTRAPED_ESTADO === 'P') {
        if (this.chkselpedido.length === 0) {
          this.transforpedService.lstVenEncPed[this.indicador][intIndiceLista].CHECK = false;
        } else {
          this.transforpedService.lstVenEncPed[this.indicador][intIndiceLista].CHECK = true;
        }
      }
    }
    this.aggrid.refreshaggrid(this.transforpedService.lstVenEncPed[this.indicador], this.defaultColDef.objeto);
    this.aggrid.visualizarArriba(this.defaultColDef.objeto);
  }

  traerpedidos() {
    if (this.selectcombo.name !== ''
      && this.txtCriteria !== '')
      this.modelobuscar(this.selectcombo.name, this.txtCriteria);
    else
      this.modelobuscar('', '');
  }

  async modelobuscar(criterio, texto) {
    let columna = "";

    for (const m of this.transforpedService.lstVenEncPed[this.indicador]) {
      if (m.DECTRAPED_ENCFACNUMERO !== '') {
        this.messageService.add({
          key: 'tranforped',
          severity: 'error',
          summary: 'Información',
          detail: 'Esta transformación ya tiene facturas generadas.'
        });
        return;
      }
    }

    if (texto !== '') {
      if (criterio === "Número Pedido")
        columna = " AND a.ENCPED_NUMERO LIKE '%" + texto + "%'";
      else if (criterio === "Comprobante")
        columna = " AND a.ENCPED_REFERENCIA LIKE '%" + texto + "%'";
      else if (criterio === "Fecha Emisión")
        columna = " AND a.ENCPED_FECHAEMISION=TO_DATE('" + texto
          + "','DD/MM/YYYY')";
      else if (criterio === "Est")
        columna = " AND a.ENCPED_ESTADO LIKE '%" + texto + "%'";
      else if (criterio === "Cliente")
        columna = " AND b.CLI_NOMBRE LIKE '%" + texto + "%'";
      else if (criterio === "Cód. Cliente")
        columna = " AND a.CLI_CODIGO LIKE '%" + texto + "%'";
      else if (criterio === "Cód. Vendedor")
        columna = " AND a.VEN_CODIGO LIKE '%" + texto + "%'";
      else if (criterio === "Observación")
        columna = " AND a.ENCPED_OBSERVACION LIKE '%" + texto + "%'";
      else if (criterio === "Grupo")
        columna = " AND b.GRU_CODIGO LIKE '%" + texto + "%'";
      else
        columna = "";
    }

    if (this.chkselpedido === true) {
      columna = columna + " AND a.encped_estado = 'P'";
    }

    const data = await this.transforpedService.buscardectraped(columna);
    this.transforpedService.lstVenEncPed[this.indicador] = [];
    for (const rs of data) {
      const item: VenDectraped = {};
      item.CHECK = false;
      item.DECTRAPED_NUMERO = rs.ENCPED_NUMERO === null ? '' : rs.ENCPED_NUMERO;
      item.DECTRAPED_COMPROBANTE = rs.ENCPED_REFERENCIA === null ? '' : rs.ENCPED_REFERENCIA;
      item.DECTRAPED_FECHAEMISION = this.datePipe.transform(rs.ENCPED_FECHAEMISION, 'dd/MM/yyyy');
      item.DECTRAPED_ESTADO = rs.ENCPED_ESTADO === null ? '' : rs.ENCPED_ESTADO;
      item.DECTRAPED_CLICODIGO = rs.CLI_CODIGO === null ? '' : rs.CLI_CODIGO;
      item.CLI_NOMBRE = rs.CLI_NOMBRE === null ? '' : rs.CLI_NOMBRE;
      item.DECTRAPED_VENCODIGO = rs.VEN_CODIGO === null ? '' : rs.VEN_CODIGO;
      item.DECTRAPED_OBSERVACION = rs.ENCPED_OBSERVACION === null ? '' : rs.ENCPED_OBSERVACION;
      item.GRU_CODIGO = rs.GRU_CODIGO === null ? '' : rs.GRU_CODIGO;
      item.DECTRAPED_ENCFACNUMERO = '';
      this.transforpedService.lstVenEncPed[this.indicador].push(item);
    }
    this.aggrid.refreshaggrid(this.transforpedService.lstVenEncPed[this.indicador], this.defaultColDef.objeto);
  }

  busquedanumeracion() {
    this.opcionbusqueda = 'numeracion';
    this.types = [
      { label: 'Descripción', value: 'NUM_CLASE' },
      { label: 'Nemónico', value: 'NUM_NEMONICO' },
      { label: 'Serie', value: 'NUM_SERIE' },
      { label: 'Actual', value: 'NUM_SECACTUAL' },
      { label: 'Autorización', value: 'NUM_AUTORIZACION' }
    ];
    this.busquedacampos = ['', '', '', '', ''];
    this.tabla = 'SACI_MAENUMERACION'
    this.where = '(NUM_DOCREF=\'FAC\' OR NUM_DOCREF = ' +
      '\'NV\') AND NUM_TIPO = \'C\'';

    this.busqService.busquedaSaciMaeNumeracion7().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  cargarPedidosenFacturas() {
    const NUMPORVEN = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMPORVEN').CFG_VALOR1;

    if (NUMPORVEN == 0) {
      this.busquedanumeracion();
    } else {
      this.messageService.add({
        key: 'tranforped',
        severity: 'error',
        summary: 'Información',
        detail: 'Configure el sistema para trabajar con una numeración'
      });
    }
  }

  continuarCarga() {
    if (this.transforpedService.venEnctraped[this.indicador].ENCTRAPED_CODIGO.length == 0) {
      this.messageService.add({
        key: 'tranforped',
        severity: 'error',
        summary: 'Información',
        detail: 'Guarde esta transacción'
      });
      return;
    }

    this.claveAuth = '';
    this.autorizacionCodigo = 'TRAPED';
    this.displayDialogAutorizacion = true;

  }

  comprobarClave() {
    this.genpedservice.obtenerClave(this.autorizacionCodigo).subscribe((clave) => {
      let claveReal;
      claveReal = clave[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        this.accionesAutorizacion();
      } else if (clave !== this.claveAuth) {
        this.messageService.add({
          key: 'autorizacion',
          severity: 'error',
          summary: 'Clave Autorización',
          detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
        });
      }
    });
  }

  accionesAutorizacion() {
    if (this.autorizacionCodigo === 'TRAPED') {
      this.continuarCargaSigue();
      this.displayDialogAutorizacion = false;
    }
  }

  async continuarCargaSigue() {
    let pedido = '';
    let grupo = '';
    let nemonico = '';
    let serie = '';
    let actual = '';
    let autorizacion = '';
    let comprobante = '';
    let fecha = this.datePipe.transform(this.fecha, 'yyyy-MM-dd');
    if (this.strNumActual.length === 0) {
      this.messageService.add({
        key: 'tranforped',
        severity: 'error',
        summary: 'Información',
        detail: 'Debe escoger una numeración'
      });
      return;
    }

    for (const rs of this.transforpedService.lstVenEncPed[this.indicador]) {
      if (rs.CHECK === true) {
        pedido = rs.DECTRAPED_NUMERO;
        grupo = this.strGrupo;
        nemonico = this.strNemonico;
        serie = this.strSerie;
        actual = this.strNumActual;
        autorizacion = this.strNumAut;
        comprobante = this.transforpedService.venEnctraped[this.indicador].ENCTRAPED_CODIGO;        
        const data = await this.transforpedService.transformar(pedido, grupo, nemonico,
          serie, actual, autorizacion, comprobante, fecha);
        for (const rs of data) {
          // console.log(rs);[':B1']
          if (rs[1] === 2) {
            this.messageService.add({
              key: 'tranforped',
              severity: 'error',
              summary: 'Información',
              detail: "No se creo la factura del pedido "
                + rs.DECTRAPED_NUMERO + ". Tiene un valor total $0.00"
            });
          }
          if (rs[1] === 1) {
            const exis = await this.transforpedService.getexistar();
            for (const rsaux of exis) {
              this.messageService.add({
                key: 'tranforped',
                severity: 'error',
                summary: 'Información',
                detail: "No se creo la factura del pedido "
                  + rs.DECTRAPED_NUMERO
                  + "  verifique las existencias \n Articulo: "
                  + rsaux.ART_CODIGO
                  + " Existencias: "
                  + rsaux.TRNART_EXISTENCIA
                  + " Cantidad: "
                  + rsaux.TRNART_CANTIDAD
              });
            }
          }
        }
      }
    }
    this.mostrarPedidos(this.transforpedService.venEnctraped[this.indicador].ENCTRAPED_CODIGO);
  }

  async guardarRegistro() {
    const strFechaInicio = this.datePipe.transform(this.fecha, 'yyyy-MM-dd');
    const dia = this.fecha.getDate();
    const mes = this.fecha.getMonth();
    const anio = this.fecha.getFullYear();
    if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
      this.messageService.add({
        key: 'tranforped',
        severity: 'info',
        summary: 'Información',
        detail: 'Error, fecha debe de estar dentro del período contable'
      });
      return;
    }

    const data = await this.transforpedService.insertarenctraped(this.transforpedService.venEnctraped[this.indicador].ENCTRAPED_CODIGO,
      strFechaInicio, this.transforpedService.venEnctraped[this.indicador].ENCTRAPED_COMENTARIO)
    for (const rs of data) {
      this.transforpedService.venEnctraped[this.indicador].ENCTRAPED_CODIGO = rs.ENCTRAPED_CODIGO;
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
    }

    this.insertarDetallePedido(this.transforpedService.venEnctraped[this.indicador].ENCTRAPED_CODIGO);
  }

  async insertarDetallePedido(strComprobante) {
    for (const item of this.transforpedService.lstVenEncPed[this.indicador]) {
      if (item.DECTRAPED_NUMERO !== "" && item.CHECK === true) {
        await this.transforpedService.insertardettraped(strComprobante, item);
      }
    }

    this.mostrarPedidos(strComprobante);
  }

  onHideAutorizacion() {
    if (this.bolAutorizacion === false) {
      if (this.autorizacionCodigo === 'TRAPED') {
        this.messageService.add({
          key: 'tranforped',
          severity: 'error',
          summary: 'Información',
          detail: 'Debe ingresar correctamente'
            + ' la clave para la transformación.'
        });
      }
    }
  }
}
