import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ComnotrecDetalle} from '../cominterfaces/comnotrecDetalle';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class ComdetnotrecService {
  
  public way: string;
  public detnotrec: ComnotrecDetalle[];
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }
  
  getDetnotRec(encnotrecnumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/notasrecepcion/83bad776f8eee00', {
        elementos: {
          NUMERO: encnotrecnumero
        }
    }, this.confIniciales.httpOptions());
  }
  eliminarDetnotRec(encnotrecnumero, detalle): Observable<any[]> {{
    return this.http.post<any[]>(this.way + '/compras/notasrecepcion/7564d776f8eedc0', {
      elementos: {
        p_ENCNOTREC_numero: encnotrecnumero,
      p_COM_codigo: '01',
      p_DETNOTREC_linea: detalle.DETNOTREC_LINEA
      }
    }, this.confIniciales.httpOptions());
  }
  
  }
}
