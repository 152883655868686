import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { MessageService } from 'primeng';
import { ActMaeArea } from '../actinterfaces/actmaearea';

@Injectable({
  providedIn: 'root'
})
export class SacimaereferenciaactarService {

  private way: string;

  public btnNuevo:boolean[] = [];
  public btnGuardar:boolean[] = [];
  public btnBorrar:boolean[] = [];
  public btnBuscar:boolean[] = [];

  public detmaeReferenciaArray: ActMaeArea[][] = [];


  constructor(private http: HttpClient, 
    private confIniciales: ConfInicialesService, 
    private init: NuevoComponentService,
    private message: MessageService) { 
      this.way = sessionStorage.getItem('way');
  }

  consultarReferencia(where:string) {
    let condicion:string;
    if(where!=null || where!=''){
      condicion=`REF_TIPO = '${where}'`;
    }else{
      condicion=' REF_TIPO IS NOT NULL';
    }
    const parametro3 = '';

    return this.http.post<any[]>(this.way + '/seg/referencia/662fcdb9eedf1c0', {
      elementos: {
        p_opcion: 'T',
        p_filtro: condicion,
        p_condicion:  parametro3, //  varchar,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarReferencia(tipo,detalle:ActMaeArea){
    return this.http.post<any[]>(this.way + '/seg/referencia/12bf1dbeedcf77000', {
      elementos: {
        p_REF_tipo_1: tipo, // varchar,    
        p_REF_codigo_2:detalle.REF_CODIGO, //  varchar,  
        p_REF_nombre_3: detalle.REF_NOMBRE, //  varchar,
        p_REF_nombrec_4: detalle.REF_NOMBREC,
        p_com_codigo:'01',
        p_REF_valor : detalle.REF_VALOR
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarReferencia(tipo:string,detalle:ActMaeArea){
    return this.http.post<any[]>(this.way + '/seg/referencia/533bedb9eedbb00', {
      elementos: {
        p_REF_tipo_1:tipo,
        p_REF_codigo_2:detalle.REF_CODIGO,
        p_REF_tipo_3:detalle.REF_TIPO,
        p_REF_codigo_4:detalle.REF_CODIGO,
        p_REF_nombre_5:detalle.REF_NOMBRE,
        p_REF_nombrec_6:detalle.REF_NOMBREC,
        p_com_codigo:'01',
        p_REF_valor:detalle.REF_VALOR,

      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarReferencia(valor:string,detalle:ActMaeArea){
    return this.http.post<any[]>(this.way + '/seg/referencia/3ab2b6dcf76dd8', {
      elementos: {
        p_REF_tipo_1:valor,
        p_REF_codigo_2:detalle.REF_CODIGO,
        p_com_codigo:'01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  
}
