import { Component, OnInit, ViewChild } from '@angular/core';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { VenmaeserviciosService } from '../venservicios/venmaeservicios.service';
import { Venmaeservicios } from '../veninterfaces/venmaeservicios';
import { ConfirmationService, MessageService,SelectItem } from 'primeng/api';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';//CDPJ
import { InvmaearticuloService } from "../../inv/invservicios/invmaearticulo.service";
@Component({
  selector: 'app-venmaeservicios',
  templateUrl: './venmaeservicios.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class VenmaeserviciosComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  actualizar: boolean;
  codigoAux: any;
  nuevoCampo: boolean;
  permisoVenServicios: SegMaePermiso;
  largo: string;
  arregloCons: any[];
  servicioSeleccionado: Venmaeservicios;

  public frameworkComponents;
  rowStyle;
  desCodigo: boolean;
  defaultColDefServicios;
  rowSelection = 'multiple';
  //CDPJ
  tariivacod='';
  tariivaporcentaje:string;
  tariivadescripcion='';
  displayDialogBusquedaFasttariva=false;
  claveDialog = false;
  accion=''
  claveAuth='';
  displayDialogactiva=false;
  displayAcciones: boolean;
  types: SelectItem[];
  busquedacampos: string[];
  tabla: string;
  opcionbusqueda: string
  where = '';
  //CDPJ
  editConCodigo = false;

  columnDefsServicios = [
    {
      headerName: 'Código', field: 'SER_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 20
        }
      }
    },
    {
      headerName: 'Descripción', field: 'SER_DESCRIPCION', cellEditor: 'cellPrueba', width: 250,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80
        }
      }
    },
    {
      headerName: 'Cta.Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 150, onCellClicked: (params) => {
        this.selectCONCODIGO(params.data);
      }, editable: (params) => {
        this.isEdit(params.data);
        const boolEdit = this.editConCodigo;
        console.log(boolEdit);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 35
        }
      }
    },
    {
      headerName: 'Precio', field: 'SER_PRECIO', cellEditor: 'cellPrueba', width: 80, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10
        }
      }
    },
    {
      headerName: 'IVA', field: 'SER_TRIBUTAIVA', cellEditor: 'agSelectCellEditor', width: 80,onCellValueChanged: (params) => {
        this.cambioCentro(params);
      },
      cellEditorParams:
      {
        values: ['N', 'S']//CDPJ
      }
    },
    {
      headerName: 'Tarifa', field: 'SER_PORCEIVA', cellEditor: 'cellPrueba', width: 80, cellStyle: { textAlign: 'left' },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Centro de Costos', field: 'CEN_CODIGO', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35
        }
      }
    },
    {
      headerName: 'Form.SRI', field: 'SER_FORMSRIVTAS', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 20
        }
      }
    },
    {
      headerName: 'BaseNoObjIva', field: 'SER_BASENOOBJIVA', cellEditor: 'cellPrueba', width: 130,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 1
        }
      }
    }];

  constructor(public serviService: VenmaeserviciosService,
    private message: MessageService,
    private errorService: ErroresBaseDatosService, private confirmationService: ConfirmationService,
    private busqService: InvbusquedaService, private permisosService: PermisosService,
    private auditoriagral: AuditoriagralService, private init: NuevoComponentService,
    private confIniciales: ConfInicialesService,public artService: InvmaearticuloService) {
    this.agTable();
    this.serviService.venmaeservicios = [];
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.defaultColDefServicios = {
      editable: true,
      width: 100,
      objeto: 'maeservicios'
    };
  }

 async ngOnInit() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.actualizar = false;
    this.nuevoCampo = false;
    this.codigoAux = 0;
    this.permisosService.getListaPermisos('VEN', '1', '67').subscribe((res) => {
      this.permisoVenServicios = res[0];
    });
    this.serviService.getServicios().subscribe((res) => {
      for (let rs of res) {
        rs.SER_PRECIO = rs.SER_PRECIO.toFixed(2);
      }
      this.serviService.venmaeservicios = res;
    });
    this.largo = '258';
    this.buscarCon();
    await this.confIniciales.obtenerSrifeTariIva()
    
  }

  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      if (this.permisoVenServicios.PERINSERCION === 1) {
        this.btnNuevo = true;
        this.btnGuardar = false;
        this.btnBorrar = false;
        this.btnRegresar = false;
        this.aggrid.isEditable(true);
        this.servicioSeleccionado = {
          SER_CODIGO: '',
          COM_CODIGO: '01',
          SER_DESCRIPCION: '',
          CON_CODIGO: '',
          SER_PRECIO: '0.0',
          SER_TRIBUTAIVA: '',
          CEN_CODIGO: '',
          SER_FORMSRIVTAS: '',
          SER_BASENOOBJIVA: '',
          SER_PORCEIVA:0,
          TARIIVA_CODIGO:''
        };
        this.serviService.venmaeservicios.push(this.servicioSeleccionado);
        this.aggrid.refreshaggrid(this.serviService.venmaeservicios, this.defaultColDefServicios.objeto);
      } else if (this.permisoVenServicios.PERINSERCION === 0) {
        this.message.add({
          key: 'servicios',
          severity: 'error',
          summary: 'Inserción de Servicio',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    }
    if (valor === 'Guardar') {
      // if (this.permisoVenServicios.PERACTUALIZACION === 1) {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.serviService.frSerCodigo(this.servicioSeleccionado.SER_CODIGO).subscribe( async existe => {
        if (existe !== null) {
          let coincidencias: number;
          coincidencias = 0;
          const rsdata: any = await this.serviService.encontrarArtCntaySer(this.servicioSeleccionado.CON_CODIGO, 'C');
          //let strCodigo = 0;
          if (rsdata !== null) {
            for (const rs of rsdata) {
              coincidencias = rs.NUMERO;
            }
          }
          // this.arregloCons.map((con) => {
          //   if (this.servicioSeleccionado.CON_CODIGO === con.CON_CODIGO) {
          //     coincidencias++;
          //   }
          // });

          if (coincidencias !== 0) {
            //CDPJ
            if(this.servicioSeleccionado.TARIIVA_CODIGO === null || this.servicioSeleccionado.TARIIVA_CODIGO === undefined || this.servicioSeleccionado.TARIIVA_CODIGO === ''){
              this.message.add({
                key: 'servicios',
                severity: 'error',
                summary: 'Error en IVA',
                detail: 'El Código '+this.servicioSeleccionado.SER_CODIGO+', no tiene un código asignado en el campo tarifa, vuelva a ingresar el porcentaje.'
              });
              this.btnGuardar = false;
              return;
            }
            if(this.servicioSeleccionado.SER_PORCEIVA === null || this.servicioSeleccionado.SER_PORCEIVA === undefined || this.servicioSeleccionado.SER_PORCEIVA === ''){
              this.message.add({
                key: 'servicios',
                severity: 'error',
                summary: 'Error en IVA',
                detail: 'El campo Tarifa, no puede estar vacío.'
              });
              this.btnGuardar = false;
              return;
            }
            if(this.servicioSeleccionado.SER_TRIBUTAIVA === 'S' && this.servicioSeleccionado.SER_PORCEIVA !== null && this.servicioSeleccionado.SER_PORCEIVA !== undefined && this.servicioSeleccionado.SER_PORCEIVA !== ''){
              let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === this.servicioSeleccionado.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) !== 0
              && Number(element.TARIIVA_PORCENTAJE) === Number(this.servicioSeleccionado.SER_PORCEIVA))
              if(data === undefined || data === null){
                this.message.add({
                  key: 'servicios',
                  severity: 'error',
                  summary: 'Error en IVA',
                  detail: 'La tarifa con el porcentaje '+this.servicioSeleccionado.SER_PORCEIVA+' no es parte de los porcentajes que puede seleccionar para servicios que tributan IVA.'
                });
                this.btnGuardar = false;
                return;
              }
            }
            if(this.servicioSeleccionado.SER_TRIBUTAIVA === 'N'
            && this.servicioSeleccionado.SER_PORCEIVA !== null && this.servicioSeleccionado.SER_PORCEIVA !== undefined && this.servicioSeleccionado.SER_PORCEIVA !== ''){
              let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === this.servicioSeleccionado.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) === 0
              && Number(element.TARIIVA_PORCENTAJE) === Number(this.servicioSeleccionado.SER_PORCEIVA))
              console.log(data)
              console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === this.servicioSeleccionado.TARIIVA_CODIGO))
              if(data === undefined || data === null){
                this.message.add({
                  key: 'servicios',
                  severity: 'error',
                  summary: 'Error en IVA',
                  detail: 'La tarifa con el porcentaje '+this.servicioSeleccionado.SER_PORCEIVA+' no es parte de los porcentajes que puede seleccionar para servicios que no tributan IVA.'
                });
                this.btnGuardar = false;
                return;
              }
            }
            //CDPJ
            if (this.servicioSeleccionado.SER_TRIBUTAIVA !== 'S' && this.servicioSeleccionado.SER_TRIBUTAIVA !== 'N') {
              this.message.add({
                key: 'servicios',
                severity: 'error',
                summary: 'Error en IVA',
                detail: 'Valor de IVA incorrecto, utilice S o N'
              });
              this.servicioSeleccionado.SER_TRIBUTAIVA = '';
              this.aggrid.refreshaggrid(this.serviService.venmaeservicios, this.defaultColDefServicios.objeto);
            } else if (this.servicioSeleccionado.SER_TRIBUTAIVA === 'S' || this.servicioSeleccionado.SER_TRIBUTAIVA === 'N') {
              this.serviService.actualizarServicio(this.servicioSeleccionado, this.codigoAux).subscribe(() => {
                this.message.add({
                  key: 'servicios',
                  severity: 'success',
                  summary: 'Actualización exitosa',
                  detail: 'Se actualizó Servicio correctamente'
                });
                this.auditoriagral.registrarAuditoria('VEN_MAEGRUPO', String(this.servicioSeleccionado.SER_CODIGO), 'A', '',
                  '01', '', '', '', '').subscribe(() => {
                  });
                this.serviService.venmaeservicios = this.serviService.venmaeservicios.filter((val, j) => j !==
                  this.serviService.venmaeservicios.indexOf(this.serviService.getServicios()
                  [this.serviService.venmaeservicios.length - 1]));
                this.aggrid.isEditable(false);
              }, error1 => {
                let mensaje: string;
                mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                this.message.add({
                  key: 'servicios',
                  severity: 'error',
                  summary: 'Actualización de Servicio',
                  detail: mensaje
                });
                this.btnNuevo = false;
                this.btnGuardar = true;
                this.btnBorrar = false;
                this.btnRegresar = true;
                this.serviService.getServicios().subscribe((res) => {
                  this.serviService.venmaeservicios = res;
                  this.aggrid.refreshaggrid(this.serviService.venmaeservicios, this.defaultColDefServicios.objeto);
                });
              });
            }
          } else if (coincidencias === 0) {
            this.message.add({
              key: 'servicios',
              severity: 'error',
              summary: 'Error en Cuenta Contable',
              detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente o escoja una de la ventana de búsqueda'
            });
            this.servicioSeleccionado.CON_CODIGO = '';
            this.aggrid.refreshaggrid(this.serviService.venmaeservicios, this.defaultColDefServicios.objeto);
          }
        } else if (existe === null) {
          let coincidencias2: number;
          coincidencias2 = 0;
          const rsdata: any = await this.serviService.encontrarArtCntaySer(this.servicioSeleccionado.CON_CODIGO, 'C');
          //let strCodigo = 0;
          if (rsdata !== null) {
            for (const rs of rsdata) {
              coincidencias2 = rs.NUMERO;
            }
          }
          // this.arregloCons.map((con) => {
          //   if (this.servicioSeleccionado.CON_CODIGO === con.CON_CODIGO) {
          //     coincidencias2++;
          //   }
          // });
          //CDPJ
          if(this.servicioSeleccionado.TARIIVA_CODIGO === null || this.servicioSeleccionado.TARIIVA_CODIGO === undefined || this.servicioSeleccionado.TARIIVA_CODIGO === ''){
            this.message.add({
              key: 'servicios',
              severity: 'error',
              summary: 'Error en IVA',
              detail: 'El Código '+this.servicioSeleccionado.SER_CODIGO+', no tiene un código asignado en el campo tarifa, vuelva a ingresar el porcentaje.'
            });
            this.btnGuardar = false;
            return;
          }
          if(this.servicioSeleccionado.SER_PORCEIVA === null || this.servicioSeleccionado.SER_PORCEIVA === undefined || this.servicioSeleccionado.SER_PORCEIVA === ''){
            this.message.add({
              key: 'servicios',
              severity: 'error',
              summary: 'Error en IVA',
              detail: 'El campo Tarifa, no puede estar vacío.'
            });
            this.btnGuardar = false;
            return;
          }
          if(this.servicioSeleccionado.SER_TRIBUTAIVA === 'S' && this.servicioSeleccionado.SER_PORCEIVA !== null && this.servicioSeleccionado.SER_PORCEIVA !== undefined && this.servicioSeleccionado.SER_PORCEIVA !== ''){
            let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === this.servicioSeleccionado.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) !== 0
            && Number(element.TARIIVA_PORCENTAJE) === Number(this.servicioSeleccionado.SER_PORCEIVA))
            if(data === undefined || data === null){
              this.message.add({
                key: 'servicios',
                severity: 'error',
                summary: 'Error en IVA',
                detail: 'La tarifa con el porcentaje '+this.servicioSeleccionado.SER_PORCEIVA+' no es parte de los porcentajes que puede seleccionar para servicios que tributan IVA.'
              });
              this.btnGuardar = false;
              return;
            }
          }
          if(this.servicioSeleccionado.SER_TRIBUTAIVA === 'N'
          && this.servicioSeleccionado.SER_PORCEIVA !== null && this.servicioSeleccionado.SER_PORCEIVA !== undefined && this.servicioSeleccionado.SER_PORCEIVA !== ''){
            let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === this.servicioSeleccionado.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) === 0
            && Number(element.TARIIVA_PORCENTAJE) === Number(this.servicioSeleccionado.SER_PORCEIVA))
            console.log(data)
            console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === this.servicioSeleccionado.TARIIVA_CODIGO))
            if(data === undefined || data === null){
              this.message.add({
                key: 'servicios',
                severity: 'error',
                summary: 'Error en IVA',
                detail: 'La tarifa con el porcentaje '+this.servicioSeleccionado.SER_PORCEIVA+' no es parte de los porcentajes que puede seleccionar para servicios que no tributan IVA.'
              });
              this.btnGuardar = false;
              return;
            }
          }
          //CDPJ
          if (coincidencias2 !== 0) {
            if (this.servicioSeleccionado.SER_TRIBUTAIVA !== 'S' && this.servicioSeleccionado.SER_TRIBUTAIVA !== 'N' &&
              this.servicioSeleccionado.SER_TRIBUTAIVA !== '') {
              this.message.add({
                key: 'servicios',
                severity: 'error',
                summary: 'Error en IVA',
                detail: 'Valor de IVA incorrecto, utilice S o N'
              });
              this.servicioSeleccionado.SER_TRIBUTAIVA = '';
              this.aggrid.refreshaggrid(this.serviService.venmaeservicios, this.defaultColDefServicios.objeto);
            } else if (this.servicioSeleccionado.SER_TRIBUTAIVA === 'S' || this.servicioSeleccionado.SER_TRIBUTAIVA === 'N' ||
              this.servicioSeleccionado.SER_TRIBUTAIVA === '') {
              this.serviService.insertarServicio(this.servicioSeleccionado).subscribe(() => {
                this.message.add({
                  key: 'servicios',
                  severity: 'success',
                  summary: 'Inserción exitosa',
                  detail: 'Se insertó el servicio correctamente'
                });
                this.auditoriagral.registrarAuditoria('VEN_MAEGRUPO', String(this.servicioSeleccionado.SER_CODIGO), 'I', '',
                  '01', '', '', '', '').subscribe(() => {
                  });
                this.serviService.venmaeservicios = this.serviService.venmaeservicios.filter((val, j) => j !==
                  this.serviService.venmaeservicios.indexOf(this.serviService.getServicios()
                  [this.serviService.venmaeservicios.length - 1]));
                this.aggrid.isEditable(false);
                this.nuevoCampo = false;
              }, error1 => {
                let mensaje: string;
                mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                this.message.add({
                  key: 'servicios',
                  severity: 'error',
                  summary: 'Inserción de Servicios',
                  detail: mensaje
                });
                this.btnNuevo = false;
                this.btnGuardar = true;
                this.btnBorrar = false;
                this.btnRegresar = true;
                this.serviService.getServicios().subscribe((res) => {
                  this.serviService.venmaeservicios = res;
                  this.aggrid.refreshaggrid(this.serviService.venmaeservicios, this.defaultColDefServicios.objeto);
                });
              });
            }
          } else if (coincidencias2 === 0) {
            this.message.add({
              key: 'servicios',
              severity: 'error',
              summary: 'Error en Cuenta contable',
              detail: 'La cuenta contable ingresada no existe, por favor ingrese una existente ' +
                'o escoja una de la ventana de búsqueda'
            });
            this.servicioSeleccionado.CON_CODIGO = '';
            this.aggrid.refreshaggrid(this.serviService.venmaeservicios, this.defaultColDefServicios.objeto);
          }
        }
      });
    }
    /////////////////////////
    if (valor === 'Borrar') {
      if (this.permisoVenServicios.PERELIMACION === 1) {
        this.aggrid.isEditable(false);
        if (this.servicioSeleccionado === undefined) {
          this.message.add({
            key: 'servicios',
            severity: 'error',
            summary: 'Eliminación de Servicio',
            detail: 'Selección Incorrecta, no se ha seleccionado un servicio'
          });
        } else {
          this.confirmarServicio();
        }
      } else if (this.permisoVenServicios.PERELIMACION === 0) {
        this.message.add({
          key: ' servicios',
          severity: 'error',
          summary: 'Eliminación de Servicio',
          detail: 'El usuario no tiene permisos de elimminación, acción denegada'
        });
      }
    }
    if (valor === 'Cancelar') {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.aggrid.isEditable(false);
      this.serviService.getServicios().subscribe((res) => {
        this.serviService.venmaeservicios = res;
        this.aggrid.refreshaggrid(this.serviService.venmaeservicios, this.defaultColDefServicios.objeto);
      });
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }

  confirmarServicio() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea elminiar el servicio',
      header: 'Eliminar Servicio',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarServicios',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarServicio();
      },
      reject: () => {
      }
    });
  }

  selectedServicio(valor) {
    if (valor.object !== null) {
      this.servicioSeleccionado = valor.object;
      this.serviService.auxsalto = false;
      if (this.servicioSeleccionado !== undefined) {
        this.codigoAux = this.servicioSeleccionado.SER_CODIGO;
      }
      if (this.nuevoCampo === true) {
        this.actualizar = false;
      } else if (this.nuevoCampo === false) {
        this.actualizar = true;
      }
    }
  }

  async eliminarServicio() {

    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;

    if (await this.VerificarMovServicios() === false) {
      this.serviService.eliminarServicio(this.servicioSeleccionado).subscribe(() => {
        this.message.add({
          key: 'servicios',
          severity: 'success',
          summary: 'Eliminación exitosa',
          detail: 'Se eliminó el servicio correctamente'
        });
        this.auditoriagral.registrarAuditoria('VEN_MAECIUDAD', String(this.servicioSeleccionado.SER_CODIGO), 'E', '',
          '01', '', '', '', '').subscribe(() => {
          });
        this.serviService.venmaeservicios = this.serviService.venmaeservicios.filter((val, j) => j !==
          this.serviService.venmaeservicios.indexOf(this.serviService.getServicios()[this.serviService.venmaeservicios.length - 1]));
        this.aggrid.isEditable(false);
        this.serviService.venmaeservicios = this.serviService.venmaeservicios.filter((val, j) => j !==
          this.serviService.venmaeservicios.indexOf(this.servicioSeleccionado));
        this.aggrid.refreshaggrid(this.serviService.venmaeservicios, this.defaultColDefServicios.objeto);
      }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.message.add({
          key: 'servicios',
          severity: 'error',
          summary: 'Eliminaciòn del Servicio',
          detail: mensaje
        });
      });
    }
  }

  cambio() {
    this.btnGuardar = false;
    this.btnRegresar = false;
  }

  buscarCon() {
    this.busqService.busquedaConMaeCon().subscribe((res: any[]) => {
      this.arregloCons = res;
    });
  }

  async verifica_detdvf(conCodigo: string) {
    let rsNumDev = 0;
    try {
      const rs = await this.serviService.verifica_detdvf(conCodigo);
      for (const item of rs) {
        rsNumDev = (item.CANT === null ? 0 : (Number(item.CANT)));
        return rsNumDev;
      }
    } catch (err) {
      this.message.add({
        key: 'servicios',
        severity: 'error',
        summary: 'Eliminación del Servicio',
        detail: err.error
      });
    }
    return rsNumDev;

  }

  async verifica_detfac(conCodigo: string) {
    let rsNumTrasac = 0;

    try {
      const rs = await this.serviService.verifica_detfac(conCodigo);
      for (const item of rs) {
        rsNumTrasac = (item.CANT === null ? 0 : (Number(item.CANT)));
        return rsNumTrasac;
      }
    } catch (err) {
      this.message.add({
        key: 'servicios',
        severity: 'error',
        summary: 'Eliminación del Servicio',
        detail: err.error
      });
    }
    return rsNumTrasac;
  }

  async verifica_detped(conCodigo: string) {
    let rsNumFac = 0;
    try {
      const rs = await this.serviService.verifica_detped(conCodigo);
      for (const item of rs) {
        rsNumFac = (item.CANT === null ? 0 : (Number(item.CANT)));
        return rsNumFac;
      }
    } catch (err) {
      this.message.add({
        key: 'servicios',
        severity: 'error',
        summary: 'Eliminación del Servicio',
        detail: err.error
      });
    }
    return rsNumFac;
  }

  async verifica_detgre(conCodigo: string) {
    let rsNumNotas = 0;

    try {
      const rs = await this.serviService.verifica_detgre(conCodigo);
      for (const item of rs) {
        rsNumNotas = (item.CANT === null ? 0 : (Number(item.CANT)));
        return rsNumNotas;
      }

    } catch (err) {
      this.message.add({
        key: 'servicios',
        severity: 'error',
        summary: 'Eliminación del Servicio',
        detail: err.error
      });
    }
    return rsNumNotas;

  }

  async VerificarMovServicios() {

    const verificaMov_detdvf = await this.verifica_detdvf(this.servicioSeleccionado.SER_CODIGO);
    const verificaMov_detfac = await this.verifica_detfac(this.servicioSeleccionado.SER_CODIGO);
    const verificaMov_detped = await this.verifica_detped(this.servicioSeleccionado.SER_CODIGO);
    const verificaMov_detgre = await this.verifica_detgre(this.servicioSeleccionado.SER_CODIGO);

    if (verificaMov_detdvf <= 0) {

      if (verificaMov_detfac <= 0) {

        if (verificaMov_detped <= 0) {

          if (verificaMov_detgre <= 0) {
            return false;
          } else {
            this.message.add({
              key: 'servicios',
              severity: 'error',
              summary: 'ERROR',
              detail: 'No se pudo eliminar el servicio por que tiene movimientos realizados'
            });
            return true;
          }
        } else {
          this.message.add({
            key: 'servicios',
            severity: 'error',
            summary: 'ERROR',
            detail: 'No se pudo eliminar el servicio por que tiene movimientos realizados'
          });
          return true;
        }
      } else {
        this.message.add({
          key: 'servicios',
          severity: 'error',
          summary: 'ERROR',
          detail: 'No se pudo eliminar el servicio por que tiene movimientos realizados'
        });
        return true;
      }

    } else {
      this.message.add({
        key: 'servicios',
        severity: 'error',
        summary: 'ERROR',
        detail: 'No se pudo eliminar el servicio por que tiene movimientos realizados'
      });
      return true;
    }
    return false;
  }

  async verifica_uso_servicio(conCodigo: string) {
    let rsNumNotas = 0;

    try {
      const rs = await this.serviService.verifica_usoservi(conCodigo);

      for (const item of rs) {
        rsNumNotas = (item.CANT == null ? 0 : (Number(item.CANT)));
        return rsNumNotas;

      }

    } catch (err) {
      this.message.add({
        key: 'servicios',
        severity: 'error',
        summary: 'Eliminación del Servicio',
        detail: err.error
      });
    }
    return rsNumNotas;

  }

  async selectCONCODIGO(data) {
    if (await this.verifica_uso_servicio(data.SER_CODIGO) === 1) {
      this.message.add({
        key: 'servicios',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede hacer  cambios la cuenta contable ya está siendo utilizada'
      });
    }
  }

  async isEdit(data) {
    if (await this.verifica_uso_servicio(data.SER_CODIGO) === 1) {
      this.editConCodigo = false;
    } else {
      this.editConCodigo = true;
    }

  };
  cambioCentro(event) {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = false;
    this.btnRegresar = false;

  }
//CDPJ
cancelariva(){
  this.tariivaporcentaje='';
  this.tariivadescripcion='';
  this.tariivacod=''
}
actualizariva(){
  this.closeSidebar();
  this.claveDialog = true;
  this.accion='claveiva'

}
comprobarClave() {
  if(this.accion === 'claveiva'){
    this.serviService.obtenerClaveIva().subscribe((clave) => {
      let claveReal;
      claveReal = clave[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        this.claveDialog = false;
        //this.boolIva=false
        this.claveAuth = '';
        this.displayDialogactiva=true;
      } else if (clave !== this.claveAuth) {
        this.claveAuth = '';
        this.message.add({
          key: 'commaeserclave',
          severity: 'error',
          summary: 'Clave Autorización',
          detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
        });
      }
    });
  }
}
async aplicarActualizar(){
  if(this.tariivaporcentaje === null || this.tariivaporcentaje ===  undefined || this.tariivaporcentaje === ''){
    this.message.add({
      key: 'artiva',
      severity: 'warn',
      summary: 'Información',
      detail: 'El campo IVA, no puede estar vacío'
    });
    return;
  }
  if(this.tariivaporcentaje !== null && this.tariivaporcentaje !==  undefined && this.tariivaporcentaje !== ''){
    let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === this.tariivacod && Number(element.TARIIVA_PORCENTAJE) === Number(this.tariivaporcentaje))
    if(data === undefined || data === null){
      this.message.add({
        key: 'artiva',
        severity: 'error',
        summary: 'Error en IVA',
        detail: 'El %IVA con el porcentaje '+this.tariivaporcentaje+' no es parte de los porcentajes que puede seleccionar para actualizar.'
      });
      return;
    }
  }
  await this.finalmenteactualiza()
}
async finalmenteactualiza(){
  try {
    await this.serviService.actualizarporceiva(this.tariivacod,Number(this.tariivaporcentaje));
this.auditoriagral.registrarAuditoria('VEN_MAESERVICIO','porcentaje'+'/'+
this.tariivaporcentaje+'/'+'codporcentaje'+'/'+this.tariivacod, 'A', '', '01', '', '', '', '').subscribe();
this.message.add({
  key: 'artiva',
  severity: 'success',
  summary: 'Información',
  detail: 'Actualización exitosa'
});
  } catch (error) {
    
  }

}
closeSidebar() {
  this.displayAcciones = false;
  const elemento = document.getElementsByClassName('layout-config');
  for (let i = 1; i <= elemento.length; i++) {
    elemento[i - 1].className = 'layout-config';
  }
}
acciones() {
  this.displayAcciones = !this.displayAcciones;
  const elemento = document.getElementsByClassName('layout-config');
  if (this.displayAcciones === true) {
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className += ' layout-config-active';
    }
  } else if (this.displayAcciones === false) {
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }
}
busquedaivacod(parametro) {
  this.opcionbusqueda = 'busquedatariivacod';
  this.types = [
    { label: 'Código', value: 'TARIIVA_CODIGO' },
    { label: 'Descripcion', value: 'TARIIVA_DESCRIPCION' },
    { label: 'Porcentaje', value: 'TARIIVA_PORCENTAJE' }
  ];

  this.busquedacampos = [parametro,'', '', ''];
  this.tabla = 'SRIFE_TARIIVA';
  this.where = 'TARIIVA_ACTIVO=\'S\'';
  //if(this.chktributaiva === true){
    this.where=this.where+' and TARIIVA_PORCENTAJE<>0 '
  //}
  // else{
  //   this.where=this.where+' and TARIIVA_PORCENTAJE=0 '
  // }
  this.artService.encontrartariivacod(parametro).subscribe(eR => {
    if (eR !== null) {
      if (eR[0] !== undefined) {
        this.tariivacod = eR[0].TARIIVA_CODIGO;
        this.tariivadescripcion = eR[0].TARIIVA_DESCRIPCION;
      } else {
        this.busqService.busquedaSrifeTariIva(this.where).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFasttariva = true;
        });
      }
    } else {
      this.busqService.busquedaSrifeTariIva(this.where).subscribe((datos: any[]) => {
        this.arregloCons = datos;
        // this.displayDialogBusqueda = true;
        this.displayDialogBusquedaFasttariva = true;
      });
    }
  });
}
manejarSeleccion(opcion) {
  if(this.opcionbusqueda === 'busquedatariivacod'){
    this.tariivacod=opcion.TARIIVA_CODIGO;
    this.tariivaporcentaje=opcion.TARIIVA_PORCENTAJE
    this.tariivadescripcion=opcion.TARIIVA_DESCRIPCION;
    this.displayDialogBusquedaFasttariva=false;
  }
}
//CDPJ
}
