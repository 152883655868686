import { Component, OnInit, ViewChild } from '@angular/core';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { SegMaePermiso } from 'src/app/advantage/advantageinterfaces/segmaepermiso';
import { VenencptovtaService } from '../venservicios/venencptovta.service';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { SelectItem, MessageService, ConfirmationService } from 'primeng';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { VenEncfacZ } from '../veninterfaces/venencfacz';
import { VenencfacService } from '../venservicios/venencfac.service';
import { VenDetfacZ } from '../veninterfaces/vendetfacz';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { PermisosService } from 'src/app/advantage/advantageservices/permisos.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { DatePipe } from '@angular/common';
import { Usuario } from 'src/app/usuario';
import { SaciMaeNumeracion } from 'src/app/seg/seginterfaces/segmaeusuario';
import { SegMaeCompania } from '../veninterfaces/segmaecompania';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { ComponentSource } from 'ag-grid/dist/lib/components/framework/componentResolver';
//import { copyFileSync } from 'fs';
import { Eletrndocumento } from '../veninterfaces/eletrndocumento';//cdpj
import { FormBuilder } from '@angular/forms';//CDPJ
import { PagocontadoService } from '../venservicios/pagocontado.service';//CDPJ
import {Venencfacbasesiva  } from "../veninterfaces/venencfacbasesiva";//CDPJ
import { finalize } from 'rxjs/operators';
//GSRF
import { SacimaecfngimprService } from 'src/app/advantage/advantageservices/sacimaecfngimpr.service';
import { SaciMaecfngimpr } from 'src/app/advantage/advantageinterfaces/sacimaecfngimpr';
import { ReportesService } from '../../servicios/reporte.service';
@Component({
  selector: 'app-venencptovta',
  templateUrl: './venencptovta.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class VenencptovtaComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  hmpConfiguracion: any[];
  indicador: any;
  index: number;
  permisoPtoVta: SegMaePermiso;

  // barra
  barraBotones2: boolean;
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  botonFactura: boolean;
  botonAnular: boolean;
  botonXML: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;

  // dialogs
  displayDialogBusqueda: boolean;
  displayDialogBusquedaFast: boolean;
  displayAcciones: boolean;
  displayDialogPagoContado: boolean;
  displayDialogPagoCredito: boolean;
  displayDialogNuevoCliente: boolean;
  displayDialogDescuento: boolean;
  displayAsiContable: boolean;
  displayDialogReportes: boolean;

  spin = false;

  // busqueda
  opcionbusqueda: string;
  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];
  tabla: string;
  where: string;

  // dropdowns
  cmbventa: any[];
  selectventa: any;
  cmblista: any[];
  selectlista: any;
  //cmbiva: any[];
  cmbporceiva: any[];//CDPJ
  selectcombtipoIva: any;//CDPJ
  selectiva: any;

  // checkbox
  chkNotaVenta: boolean;
  chkFactura: boolean;
  boolpago = true;//CDPJ
  // aggrid
  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  defaultColDef;
  largo: string;

  asiento: any;

  strCodBodega: string;
  dblPorcIva: number;
  strFormaPago: string = '';
  bolImportarNV = false;
  strTipoNum: string;
  glbBolEsValor: boolean;
  listaEncFact: any[];
  bolConsultar: boolean;

  lblCodArt: string;
  lblUnidad: string;
  lblCantidad: string;
  lblDsctoArt: string;
  lblSaldoCliente: string;
  lblExistencia: string;
  lblPVP: string;

  auxiliar: number;
  auxiliar1: number;
  strNumFact: any;
  //CDPJ
  strFechaSer: any;
  displayDialogSeriales: boolean;
  txtRuc: any;
  decimalesPrecioPTOV: any
  decimalesTotalPTOV: any
  ven_codigo: string;
  bolvencodigo: boolean;
  logResultado: string;
  displayDialogLog: boolean;
  //ValoresReporte: string[][] = [];
  boolauth:boolean
  //CDPJ
  listaPedidos: any[];

  dblExistArt = 0;
  strNombreCliente = '';
  strValorCajTexto = '';

  labelChqProt: boolean;
  labelPromocionsetVisible: boolean;

  strCondNavegar = "NVL(ENCFAC_ORIGEN,'A')<>'VEN' AND COM_CODIGO = '01' AND USU_IDENTIFICACION=\'" + this.encptovtaService.usuario.identificacion + "\'";

  intNumItems = 0;
  intNumItemsDisp = 0;

  strNumAsi = '';
  bolGenAsiento = true;
  bolVisualizarAsiento = true;

  strNombreForma: string;
  auxreporte: number = 0;
  //CDPJ
  strTipoCliD: string;
  txtCorreo1: string;
  txtCorreo2: string;
  displayDialogActualizacionFE: boolean;
  //CDPJ
  //GSRF
  reportes: any[] = [];
  reporteSeleccionado: SaciMaecfngimpr;
  CNFGIMPR_CODIGO: number;
  COM_CODIGO: string;
  CNFGIMPR_FORMA: string;
  CNFGIMPR_REPORTE: string;
  CNFGIMPR_NOMBRE: string;
  CNFGIMPR_SP: string;
  CNFGIMPR_TABLA: string;
  CNFGIMPR_LOCALIZACION: string;
  CNFGIMPR_PARAMETRO: string;
  ValoresReporte: string[][] = [];
  ValoresParamentros: string[][] = [];
  intNumfilasVP: number;
  intNumFilasVR: number;
  strConsultaRP: string;
  strParametro: string;
  ValorParametroIDE: string;

  //strObjeto: string;
  //GSRF
  columnDefsDetfac = [
    { // 0
      headerName: 'Código', field: 'DETFAC_CODIGO', cellEditor: 'cellPrueba',
      editable: (params) => {
        return params.data.ENCPED_NUMERO.length === 0;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 25,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Descripción', field: 'DETFAC_DESCRIPCION', cellEditor: 'cellPrueba', width: 200,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 200
        }
      }
    },
    { // 2
      headerName: 'Iva', field: 'DETFAC_TRIBIVA', cellEditor: 'cellPrueba', width: 60,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tienecalculos: true,
          regexp: /^[S]$|^[N]$|^[s]$|^[n]$/,
          noblanco: true
        }
      }
    },
    { // 3
      headerName: 'Unidad', field: 'DETFAC_UNIDAD', cellEditor: 'agSelectCellEditor', editable: true, width: 80,
      onCellValueChanged: (params) => {
        //this.cambiarPrecioUnidad(params);
      },
      cellEditorParams:
      {
        values: []
      }
    },
    { // 4
      headerName: 'Cantidad', field: 'DETFAC_CANTIDAD', cellEditor: 'cellPrueba', width: 90,
      onCellClicked: (params) => {
        //this.abrirLotes(params.data);
      },
      cellStyle: (params) => {
        /* const color = this.colorearCantidad(params.data);
         return { background: color, textAlign: 'right' };*/
      },
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 5
      headerName: 'Dc (%)', field: 'DETFAC_DESCUENTO', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 90,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 6
      headerName: 'Precio', field: 'DETFAC_PRECIO', cellEditor: 'cellPrueba', width: 90,
      // , editable: true,
      cellStyle: (params) => {
        /*const color = this.colorearPrecio(params.data);
        return { background: color, textAlign: 'right' };*/
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true,
          autorizacion: true
        }
      }
    },
    { // 7
      headerName: 'Total', field: 'DETFAC_TOTAL', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' }, width: 90,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    },
    { // 8
      headerName: 'Lista', field: 'DETFAC_LISTA', cellEditor: 'agSelectCellEditor', editable: true, width: 80,
      onCellValueChanged: (params) => {
        this.changelistaprecios(params.data);
      },
      cellEditorParams: {
        values: []
      }
    },
    { // 9
      headerName: 'Ice', field: 'DETFAC_TRIBICE', cellEditor: 'cellPrueba', width: 60,
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: true,
          tienecalculos: true,
          regexp: /^[S]$|^[N]$|^[s]$|^[n]$/,
          noblanco: true
        }
      }
    },
    { // CDPJ
      headerName: '%IVA', field: 'DETFAC_PORIVA', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          int: true,
          opcionbuscar: true
        }
      }
    }
  ];
//CDPJ
columnDefsBasesIva = [
  {
    headerName: '%IVA', field: 'TARIIVA_DESCRIPCION', width: 115, editable: false
  },
  {
    headerName: 'Base', field: 'IVA_BASE', width: 90, cellStyle: { textAlign: 'right' }, editable: false
  },
  {
    headerName: 'Valor IVA', field: 'IVA_VALOR', width: 90, cellStyle: { textAlign: 'right' }, editable: false
  }
];
//CDPJ
  NUMDECTOTAL = 2;
  NUMDECSIST = 2;
  NUMDECCANTIDAD = 2;
  NUMDECPRECIO = 2;
  lblImpresosetVisible: boolean = false;
  lblObsAnulada = '';
  lblObsAnuladasetVisible: false;
  messageKey = "encptovta";

  dblSaldoCliente = 0;
  strCodConsFinal = "";

  intDiasCredito = 0;
  dblCliLimCredit = 0;
  dblPorcentajeDes = 0;
  strTargetaDesc = "";
  detfacSelected: VenDetfacZ;
  bolClave = true;
  strCodVendedor = "";
  strCodVendedor2 = "";
  intNumReg = 0;

  bolNuevo = true;
  strNemonico = '';
  strSerie = '';
  strNumDoc = '';
  strFecha = '';
  strHora = '';
  intTamNumFact = 0;

  // autorizacion
  claveAuth: string;
  autorizacionCodigo: string;
  bolAutorizacion: boolean;
  displayDialogAutorizacion: boolean;
  displayDialogSupervisor: boolean;

  // supervision
  usuarioSup: string;
  claveSup: string;
  strDialogo: string;
  strObjeto: string;
  intPestaniaCol: number;
  bolSupervisor: boolean;

  // descuento global
  booleanValor: boolean;
  booleanPorcentaje: boolean;
  valorDscValor: number;
  valorDscPorcentaje: number;
  dscClienteActual: number;
  dscSeleccionado: string;

  txtReferenciasetEditable: boolean;
  btnReferenciasetEnabled: boolean;
  txtCodVendedorsetEditable = true;
  btnVendedorsetEnabled = true;
  txtCodClientesetEditable = false;
  txtNumerosetEditable = true;
  btnClientesetEnabled = false;
  bolCantPed = false;
  bolCantDev = false;
  dblLimConFin = 0;
  chkFacturasetEnabled = true;
  chkNotaVentasetEnabled = true;

  datosfe: SegMaeCompania;
  strNumNVOrg = '';

  razonanular: string;
  displayDialogRazon: boolean;

  StrUsuRazEli = '';
  rbtFECCUO: string;
  auxpago = '';
  auxcuotas: number;//CDPJ
  displayDialogCuotas: boolean;//CDPJ
  continuar: boolean;//CDPJ
  myForm;//CDPJ
  boolfacturaElec//CDPJ
  displaybasesiva:boolean=false;
  basesiva:Venencfacbasesiva[]=[]
  defaultColBasesIva;
  iva:number
  constructor(public encptovtaService: VenencptovtaService, public init: NuevoComponentService,
    private busqService: InvbusquedaService, public encfacService: VenencfacService,
    private utilitariosService: UtilitariosService, private confIniciales: ConfInicialesService,
    private messageService: MessageService, private permisosService: PermisosService,
    private auditoriagral: AuditoriagralService, private datePipe: DatePipe, public usuario: Usuario,
    private confirmationService: ConfirmationService, private errorService: ErroresBaseDatosService,
    private cfngimprService: SacimaecfngimprService, private reporteSer: ReportesService //gdrf
    , private forms: FormBuilder,//CDPJ
    private pagconservice: PagocontadoService//CDPJ
  ) {
    this.agTable();
    this.myForm = this.forms.group({
      myRadio: ['FEC', []],
    });
    this.rbtFECCUO = 'FEC';
    //console.log(this.myForm.value);
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    //CDPJ
    this.rowStyle = (params) => {
      if (params.data.DETFAC_TIPODET === 'Y') {
        return { background: 'rgb(255,207,185)' };
      }
    };
    //CDPJ
    this.defaultColDef = {
      editable: true,
      width: 100,
      objeto: 'detfacpv',
      resizable: true
    };
      //CDPJ
      this.defaultColBasesIva = {
        editable: false,
        width: 100,
        objeto: 'basesiva',
      };
      //CDPJ
  }

  async ngOnInit(){
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.largo = '600';

    this.encptovtaService.refrescarURL();
    this.encfacService.refrescarURL();
    this.index = 0;
    this.barraBotones2 = true;
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
    this.botonFactura = false;
    this.botonAnular = false;
    this.botonXML = false;
    this.botonPrimero = true;
    this.botonAnterior = true;
    this.botonSiguiente = true;
    this.botonUltimo = true;
    //CDPJ
    //CDPJ
    this.boolfacturaElec= await this.encptovtaService.leerValidarRucsFacElec().catch(e=>{
      
    });
    console.log(this.boolfacturaElec)
    //CDPJ
    this.auxcuotas = 0;
    this.labelChqProt = false;
    this.labelPromocionsetVisible = false;

    this.encptovtaService.encfac[this.indicador] = {
      ENCFAC_NUMERO: '',
      ENCFAC_REFERENCIA: ''
    };
    this.encptovtaService.detfac[this.indicador] = [];

    this.chkNotaVenta = false;
    this.chkFactura = true;

    // this.cmbiva = [{ codigo: '12', name: '12' },
    // { codigo: '14', name: '14' }];
    //CDPJ
    
    this.cmbventa = [{ codigo: '0', name: 'Directa' },
    { codigo: '1', name: 'eCommerce' }];

    this.encfacService.obtenerListasPrecios().subscribe((olp) => {
      this.cmblista = [];
      for (const lp of olp) {
        this.cmblista.push({ codigo: lp.ARTPRE_CODIGO, name: lp.ARTPRE_CODIGO });
      }
    });

    this.auxiliar = 0;
    this.boolauth=true;
      //CDPJ
      // this.encptovtaService.verificaserie(this.usuario.identificacion).subscribe(er => {
      //   if (er !== null) {
      //     if (er[0] !== undefined) {
      //       //console.log(er[0]);
      //       console.log(er[0].NUMERO);
      //       if (er[0].NUMERO === 0) {
      //         this.strCondNavegar = "NVL(ENCFAC_ORIGEN,'A')<>'VEN' AND COM_CODIGO = '01'";
      //       }
      //     }
      //   }
      // });
      this.encptovtaService.verificaserie(this.usuario.identificacion,'FAC').subscribe(er => {
        if (er !== null) {
          if (er[0] !== undefined) {
            //console.log(er[0]);
            this.encptovtaService.verificaserie(this.usuario.identificacion,'NV').subscribe(er2 => {
              if (er !== null) {
                if (er[0] !== undefined) {
                  if (er[0].NUMERO === 0 && er2[0].NUMERO === 0) {
                    console.log('ER',er[0].NUMERO,er2[0].NUMERO);
                    this.strCondNavegar = "NVL(ENCFAC_ORIGEN,'A')<>'VEN' AND COM_CODIGO = '01'";
                  }
                }}          
            });          
          }
        }
      });
      this.encptovtaService.encfac[this.indicador].CLI_CODIGO = '';
      //CDPJ
    this.getConfiguracion();
    this.getPermisos();
    this.encfacService.leerConfiguracionDatosElectronicos().subscribe(data => {
      for (const rs of data) {
        this.datosfe = rs;
      }
    });
    this.decimales();
    this.aplicarPermisosObj();
    // if(this.usuario.codperfil === 'ADMIN'){
    //   this.strCondNavegar = "NVL(ENCFAC_ORIGEN,'A')<>'VEN' AND COM_CODIGO = '01'";
    // }
    this.iva = await this.confIniciales.obtenerImpuesto('I', 'A');
    await this.confIniciales.obtenerSrifeTariIva();
    await this.llenarCombo();

  }

  getConfiguracion() {
    this.auditoriagral.obtenerConfiguracion1().subscribe((conf) => {
      this.hmpConfiguracion = conf;
      this.encptovtaService.hmpConfiguracion = conf;
      // obtener el código del consumidor final

      const CODCONFIN = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CODCONFIN').CFG_VALOR3;
      const NOFACCF = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOFACCF').CFG_VALOR1;

      if (CODCONFIN != null
        && NOFACCF == 0)
        this.strCodConsFinal = CODCONFIN;

      const NUMITEMPV = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMITEMPV').CFG_VALOR2;
      this.encptovtaService.encfac[this.indicador].ENCFAC_MAXIMOITEMS = NUMITEMPV;
      this.encptovtaService.encfac[this.indicador].ENCFAC_ITEMSDISPONIBLES = NUMITEMPV;
      this.intNumItems = this.encptovtaService.encfac[this.indicador].ENCFAC_ITEMSDISPONIBLES;
      const LIMCONFIN = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'LIMCONFIN').CFG_VALOR2;
      if (LIMCONFIN !== null && LIMCONFIN !== undefined && LIMCONFIN !== '') {
        this.dblLimConFin = LIMCONFIN;
      } else {
        this.dblLimConFin = 0;
      }
      //configuracion para que el usuario administrador vea todas las facturas de punto de venta
    //CDPJ
    const PAVFPT = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PAVFPT').CFG_VALOR1;
    console.log(this.usuario.codperfil);
    if(PAVFPT === 1 && this.usuario.codperfil === 'ADMIN'){
      this.strCondNavegar = "NVL(ENCFAC_ORIGEN,'A')<>'VEN' AND COM_CODIGO = '01'";
    }else{
      this.strCondNavegar=this.strCondNavegar;
    }
    //CDPJ
  
    });



  }
//CDPJ
async llenarCombo(){
  //CDPJ
   this.cmbporceiva=[];
   for(let i=0;  this.confIniciales.srifetariiva[i];i++){
     let item=this.confIniciales.srifetariiva[i]
     if(item.TARIIVA_CODIGO !=='6' &&  item.TARIIVA_CODIGO !=='7'  && item.TARIIVA_CODIGO !== '-1'){
       this.cmbporceiva.push({codigo:item.TARIIVA_CODIGO,name:item.TARIIVA_PORCENTAJE.toString()})
     }
   }
   console.log(this.cmbporceiva)
   for(const data of this.cmbporceiva){
     if(Number(data.name) === Number(this.iva)){
       this.selectcombtipoIva={codigo:data.codigo,name:data.name}
       this.encptovtaService.porcetariiva=Number(this.selectcombtipoIva.name)
       this.encptovtaService.codtariiva=this.selectcombtipoIva.codigo
       this.encptovtaService.encfac[this.indicador].ENCFAC_PORCEIVA =this.encptovtaService.porcetariiva
     }
   }
   //CDPJ
  }
  cambiavalorIva(select){
    this.selectcombtipoIva.codigo=select.value.codigo
    this.encptovtaService.encfac[this.indicador].ENCFAC_PORCEIVA=Number(select.value.name);
    //console.log(this.encordService.dblPorcIvaPed)
    this.encptovtaService.porcetariiva=Number(select.value.name)
    this.encptovtaService.codtariiva=select.value.codigo
  }
//CDPJ
  async getPermisos() {
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisoPtoVta = data[0];
        } else {
          this.permisoPtoVta = null;
        }
      } else {
        this.permisoPtoVta = null;
      }
    } catch (e) {
      this.permisoPtoVta = null;
      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Error',
        detail: 'No se pudo obtener los permisos del formulario'
      });
    }
  }

  decimales() {
    this.confIniciales.getDecimales('frmVEN_ENCPTOVTA').subscribe((dec) => {
      if (dec !== null) {
        // this.encptovtaService.NUMDECPRECIO = this.confIniciales.getNumDecSist();
        // this.encptovtaService.NUMDECCANTIDAD = this.confIniciales.getNumDecSist()
        // this.encptovtaService.NUMDECTOTAL = this.confIniciales.getNumDecSist();
        dec.map((decimales) => {
          if (decimales.NUMDEC_CAMPO === 'PRECIO') {
            this.encptovtaService.NUMDECPRECIO = Number(decimales.NUMDEC_NUMDEC);
          } else if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
            this.encptovtaService.NUMDECCANTIDAD = Number(decimales.NUMDEC_NUMDEC);
          } else if (decimales.NUMDEC_CAMPO === 'TOTAL') {
            this.encptovtaService.NUMDECTOTAL = Number(decimales.NUMDEC_NUMDEC);
          }
        });

        this.columnDefsDetfac[4].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.encptovtaService.NUMDECCANTIDAD,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };
        this.columnDefsDetfac[6].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.encptovtaService.NUMDECPRECIO,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };
        this.columnDefsDetfac[5].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.confIniciales.getNumDecSist(),
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };
        this.columnDefsDetfac[7].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.encptovtaService.NUMDECTOTAL,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };
        this.aggrid.refreshColumnDefs();
      }
    });

  }

  async manejarSenales(valor) {
    document.getElementById('contorno').style.pointerEvents = 'all';

    if (valor !== 'Nuevo' && valor !== 'Cancelar'
      && valor !== 'Primero' && valor !== 'Anterior' && valor !== 'Siguiente' && valor !== 'Ultimo'
      && valor !== 'Buscar' && valor !== 'Imprimir' && valor !== 'AsientoVer' && valor !== 'Salir') {
      // Verifica el periodo contable
      const dia = this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAEMISION.getDate();
      const mes = this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAEMISION.getMonth();
      const anio = this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAEMISION.getFullYear();


      if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
        this.messageService.add({
          key: this.messageKey,
          severity: 'info',
          summary: 'Información',
          detail: 'Error, fecha debe de estar dentro del período contable'
        });
        return;
      }
    }

    this.botonPrimero = true;
    this.botonAnterior = true;
    this.botonSiguiente = true;
    this.botonUltimo = true;

    if (this.hmpConfiguracion === undefined) {
      this.getConfiguracion();
    }

    if (valor === 'Nuevo') {
      //await this.nuevoRegistro();
      //CDPJ
 // verifica si existe la forma de pago en la factura anterior a la búsqueda
 if (this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO.length > 0 && this.botonGuardar === true) {
  if (await this.verificarExisteFP() === false)
    return;
}
      if(this.chkFactura === true){
        this.strTipoNum='FAC'
      }else if(this.chkNotaVenta === true){
        this.strTipoNum='NV'
      }
      this.encptovtaService.verificaserie(this.usuario.identificacion,this.strTipoNum).subscribe(async er => {
        if (er !== null) {
          if (er[0] !== undefined) {
            //console.log(er[0]);
            console.log(er[0].NUMERO);
            if (er[0].NUMERO !== 0) {
              await this.nuevoRegistro();
            }
            else {
              this.messageService.add({
                key: this.messageKey,
                severity: 'error',
                summary: 'Información',
                detail: 'El usuario '
                  + this.encptovtaService.usuario.identificacion
                  + ', no tiene registrado una serie para facturar en punto de venta. Consulte con el Administrador del Sistema.'
              });
              return;
            }
          }
        }
      });
      //CDPJ
    }
    if (valor === 'Guardar') {
      this.guardarRegistro();
    }
    if (valor === 'Borrar') {
      this.eliminarRegistro();
    }
    if (valor === 'Cancelar') {
      this.cancelarRegistro();
    }
    if (valor === 'Primero') {
       // verifica si existe la forma de pago en la factura anterior a la búsqueda
    if (this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO.length > 0 && this.botonGuardar === true) {
      if (await this.verificarExisteFP() === false)
        return;
    }
      this.navegarFactura("P", this.strCondNavegar);
      this.bolvencodigo = true;
      console.log('booll', this.bolvencodigo)
    }
    if (valor === 'Anterior') {
       // verifica si existe la forma de pago en la factura anterior a la búsqueda
    if (this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO.length > 0 && this.botonGuardar === true) {
      if (await this.verificarExisteFP() === false)
        return;
    }
      this.facturaAnterior();
      this.bolvencodigo = true;
    }
    if (valor === 'Siguiente') {
       // verifica si existe la forma de pago en la factura anterior a la búsqueda
    if (this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO.length > 0 && this.botonGuardar === true) {
      if (await this.verificarExisteFP() === false)
        return;
    }
      this.facturaSiguiente();
      this.bolvencodigo = true;
    }
    if (valor === 'Ultimo') {
       // verifica si existe la forma de pago en la factura anterior a la búsqueda
    if (this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO.length > 0 && this.botonGuardar === true) {
      if (await this.verificarExisteFP() === false)
        return;
    }
      this.bolvencodigo = true;
      this.navegarFactura("U", this.strCondNavegar);
    }
    if (valor === 'Buscar') {
       // verifica si existe la forma de pago en la factura anterior a la búsqueda
    if (this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO.length > 0 && this.botonGuardar === true) {
      if (await this.verificarExisteFP() === false)
        return;
    }
      this.buscarRegistro();

    }
    if (valor === 'Descuento') {
      this.aplicarDescuentoGlobal();
    }
    if (valor === 'Pago') {
      this.PagoFactura();
    }
    if (valor === 'Credito') {
      this.PagoCred();
    }
    if (valor === 'Asiento') {
      this.generarAsiento();
    }
    if (valor === 'AsientoVer') {
      this.visualizarAsiento();
    }
    if (valor === 'Anular') {
      this.anularRegistro();
    }
    if (valor === 'Firmar') {
      this.firmar();
    }
    if (valor === 'EnviarCorreo') {
      if (this.datosfe.COM_FEACTIVADA === '0') {
        return;
      }
      this.actualizarEstadoParaReenviarCorreo(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);
    }
    if (valor === 'ClaseNumeracion') {
      // this.encptovtaService.encfac[this.indicador].ENCFAC_GRUPO = '';
    }
    if (valor === 'Imprimir') {
      // this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      // this.auxreporte++;
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
      //   //GSRF
      //   this.FunCnfgMtrzRpt('ENCFAC_NUMERO', this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);
      //   this.cfngimprService.CargarReportesPantalla(this.strNombreForma, '01','F').subscribe((data) => {
      //     this.reportes = data;
      //     console.log(this.reportes);
      //     console.log(data.CNFGIMPR_CODIGO);
      //     //this.reporteSeleccionado.CNFGIMPR_CODIGO=data[0].CNFGIMPR_CODIGO;
      //     this.reportes.map((rs)=>{
      //       console.log(rs.CNFGIMPR_CODIGO);
      //       this.CNFGIMPR_CODIGO=rs.CNFGIMPR_CODIGO;
      //       this.COM_CODIGO=rs.COM_CODIGO;
      //       this.CNFGIMPR_FORMA=rs.CNFGIMPR_FORMA	;
      //       this.CNFGIMPR_REPORTE=rs.CNFGIMPR_REPORTE;
      //       this.CNFGIMPR_NOMBRE=rs.CNFGIMPR_NOMBRE;
      //       this.CNFGIMPR_SP=rs.CNFGIMPR_SP;
      //       this.CNFGIMPR_TABLA=rs.CNFGIMPR_TABLA;
      //       this.CNFGIMPR_LOCALIZACION=rs.CNFGIMPR_LOCALIZACION;
      //       this.CNFGIMPR_PARAMETRO=rs.CNFGIMPR_PARAMETRO;
      //       console.log(this.CNFGIMPR_CODIGO);

      //   });
      //     //console.log(this.reporteSeleccionado);
      //     this.visualizarReporte();

      //   });
      // //this.displayDialogReportes = true;
      // //GSRF
    }
    if (valor === 'Salir') {
       // verifica si existe la forma de pago en la factura anterior a la búsqueda
    if (this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO.length > 0 && this.botonGuardar === true) {
      if (await this.verificarExisteFP() === false)
        return;
    }
      this.init.tabs.splice(this.init.tabindex, 1);
    }

    this.closeSidebar();
  }

  async buscarRegistro() {
    // verificar si hay una clave para buscar las facturas
    const GENCLAAUT = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'GENCLAAUT').CFG_VALOR1;
		/*if (GENCLAAUT == 0) {

			if (Utilidades.verificarPermisosObjConClave("frmVEN_ENCPTOVTA",
					"cmdbuscar", 0))
				if (!buscarConClaveSup())
					return;
		} else {
			DlgAutorizacion autoriza = new DlgAutorizacion(shell, "BUFCPV");
			autoriza.open();

			if (!DlgAutorizacion.glbBolIsValid) {
				MessageDialog.openInformation(getParent(), "Información",
						"Debe ingresar correctamente"
								+ " la clave para buscar la facturas.");
				return;
			}

		}*/

    if (await this.ValidarSeriales(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO) === false) {
      return;
    }

    if (this.permisoPtoVta !== null) {
      if (this.permisoPtoVta.PERTODO === 0) {
        if (this.permisoPtoVta.PERSELECCION === 0) {
          this.messageService.add({
            key: this.messageKey,
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario '
              + this.encptovtaService.usuario.identificacion
              + ', no tiene permiso de consulta. Consulte con el Administrador del Sistema.'
          });
          return;
        }
      }
    }

    // verifica si existe la forma de pago en la factura anterior a la búsqueda
    if (this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO.length > 0 && this.botonGuardar === true) {
      if (await this.verificarExisteFP() === false)
        return;
    }
    /*
        Utilidades.glb_str_codigo = null;
        Utilidades.glb_str_nombre = null;
        tablaFactura.cerrarEditorSinGrabar();
    
        String strConsulta = "VEN_PCK_ENCFAC_C.VEN_SP_consultarENCFAC_1 ";
        Utilidades.obtenerPeriodoContable();
        String strFiltro = ;
        BusquedaDlg.glbStrCondition = strFiltro;
        BusquedaDlg dialogoBusqueda = new BusquedaDlg(shell, strConsulta, "",
            strFiltro);
        dialogoBusqueda.open();*/

    this.busquedafactura();


  }

  busquedafactura() {
    this.opcionbusqueda = 'factura';
    this.types = [
      { label: 'NÚMERO', value: 'ENCFAC_NUMERO' },
      { label: 'F_EMISIÓN', value: 'ENCFAC_FECHAEMISION' },
      { label: 'ESTADO', value: 'ENCFAC_ESTADO' },
      { label: 'COD_CLIENTE', value: 'CLI_CODIGO' },
      { label: 'NOMBRE_CLIENTE', value: 'CLI_NOMBRE' },
      { label: 'RUC', value: 'ENCFAC_RUCIDE' },
      { label: 'BODEGA', value: 'BOD_CODIGO' },
      { label: 'VENDEDOR', value: 'VEN_CODIGO' },
      { label: 'TOTAL', value: 'ENCFAC_TOTAL' },
      { label: 'NETO', value: 'ENCFAC_TOTALNETO' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_ENCFAC';
    this.where = this.strCondNavegar;

    this.spin = true;
    this.encptovtaService.busquedaVenEncFac(this.strCondNavegar).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.arregloCons.map((registro) => {
        if (registro.ENCFAC_FECHAEMISION !== null) {
          registro.ENCFAC_FECHAEMISION = this.datePipe.transform(registro.ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          registro.ENCFAC_FECHAEMISION = '';
        }
        this.bolvencodigo = true;
      });
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }

  facturaAnterior() {
    if (this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO.length > 0) {
      let strCondicion = this.strCondNavegar + " AND ENCFAC_NUMERO < '"
        + this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO + "'";

      this.navegarFactura("A", strCondicion);
    } else
      this.navegarFactura("P", this.strCondNavegar);
  }

  facturaSiguiente() {
    if (this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO.length > 0) {
      let strCondicion = this.strCondNavegar + " AND ENCFAC_NUMERO > '"
        + this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO + "'";

      this.navegarFactura("S", strCondicion);
    } else
      this.navegarFactura("U", this.strCondNavegar);
  }

  acciones() {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className = 'layout-config';
      }
    }
  }

  closeSidebar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }

  botonesmodificar() {
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = false;
  }

  botonesinicio() {
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = false;
  }


  habilitarBotones(nuevo, guardar, borrar, regresar) {
    this.botonNuevo = !nuevo;
    this.botonGuardar = !guardar;
    this.botonBorrar = !borrar;
    this.botonRegresar = !regresar;
  }

  setFocus(event) {
    let element: any;
    switch (event.target.id) {
      case 'txtNumero': {
        element = document.getElementById('txtCodCliente');
        break;
      }
      case 'txtCodCliente': {
        this.txtRuc = (<HTMLInputElement>document.getElementById("txtCodCliente")).value
        const PARESCLI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PARESCLI').CFG_VALOR1;
        if (PARESCLI == 0) {
          if(this.strCodConsFinal !== null && this.strCodConsFinal !== undefined && this.strCodConsFinal !== ''){
            this.nuevaLinea();//CDPJ
          }else{
            this.buscarCliente(false)
          }
          ;
        }else {
          // if (this.encptovtaService.encfac[this.indicador].CLI_CODIGO === "1") {
          //   this.encptovtaService.encfac[this.indicador].CLI_CODIGO = "";
          if(this.strCodConsFinal !== null && this.strCodConsFinal !== undefined && this.strCodConsFinal !== ''){
            this.nuevaLinea();//CDPJ
          }else{
            this.datosNuevoCliente();
          }
          

          // } else {
          //   this.buscarCliente(false);
          // }

        }

        break;
      }
      case 'txtCodVendedor': {
        this.busquedavendedor(event.target.value);//CDPJ
        break;
      }
      //CDPJ
      case 'txtNumDiasCredito': {
        this.calcularFechaVencimiento();
        break;
      }
      //CDPJ
      default: {
        break;
      }
    }

    if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }

  }

  async busquedacliente(parametro) {
    if (parametro === null) {
      parametro = '';
    }
    this.opcionbusqueda = 'cliente';
    this.types = [
      { label: 'Código', value: 'CLI_CODIGO' },
      { label: 'Nombre', value: 'CLI_NOMBRE' },
      { label: 'RucIde', value: 'CLI_RUCIDE' },
      { label: 'Zona', value: 'CLI_ZONA' },
      { label: 'Grupo', value: 'GRU_CODIGO' },
      { label: 'Contacto', value: 'CLI_CONTACTO' },
      { label: 'Placa', value: 'CLI_PLACA' },
      { label: 'NombreC', value: 'CLI_NOMBREC' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_MAECLIENTE';
    this.where = '';

    if (parametro !== '' && parametro !== undefined && parametro !== null) {
      const eR = await this.encptovtaService.erpBusquedaCliente(this.tabla, this.types[0].value, parametro);
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
          return;
        }
      }
    }

    this.spin = true;
    this.busqService.busquedaVenMaeCliente3(parametro).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });

  }

  async busquedaclienteplaca(parametro) {
    if (parametro === null) {
      parametro = '';
    }
    this.opcionbusqueda = 'cliente';
    this.types = [
      { label: 'Código', value: 'CLI_CODIGO' },
      { label: 'Nombre', value: 'CLI_NOMBRE' },
      { label: 'RucIde', value: 'CLI_RUCIDE' },
      { label: 'Placa', value: 'CLI_PLACA' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_MAECLIENTE';
    this.where = '';

    if (parametro !== '' && parametro !== undefined && parametro !== null) {
      const eR = await this.encptovtaService.erpBusquedaCliente(this.tabla, this.types[0].value, parametro);
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
          return;
        }
      }
    }

    this.spin = true;
    this.busqService.busquedaVenMaeCliente3(parametro).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });

  }

  manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'factura') {
      if (opcion.ENCFAC_NUMERO != null)
        this.navegarFactura("R", this.strCondNavegar + " AND ENCFAC_numero = '"
          + opcion.ENCFAC_NUMERO + "'");
    }
    if (this.opcionbusqueda === 'cliente') {
      this.buscarClienteDlg2(opcion);

    } if (this.opcionbusqueda === 'vendedor') {//CDPJ
      this.encptovtaService.encfac[this.indicador].VEN_CODIGO = opcion.VEN_CODIGO;
      this.encptovtaService.encfac[this.indicador].VEN_NOMBRE = opcion.VEN_NOMBRE;
      //document.getElementById('').focus();
    }//CDPJ
    if (this.opcionbusqueda === 'servicios') {

      this.cargarDatosServicio(opcion);

      //document.getElementById('').focus();
    }

    //CDPJ
    this.displayDialogBusquedaFast = false;



  }
  //CDPJ

  cargarDatosServicio(opcion) {
    console.log(opcion)

    this.detfacSelected.DETFAC_CODIGO = opcion.SER_CODIGO;
    this.encptovtaService.detfac[this.indicador][this.encptovtaService.detfac[this.indicador].length - 1].DETFAC_DESCRIPCION = opcion.SER_DESCRIPCION;
    //this.detfacSelected.DETFAC_IVA=opcion.SER_TRIBUTAIVA;
    this.detfacSelected.DETFAC_CANTIDAD = 1;
    this.detfacSelected.DETFAC_DESCUENTO = 0;
    this.detfacSelected.DETFAC_PRECIO = opcion.SER_PRECIO === null || opcion.SER_PRECIO === undefined ? 0 : opcion.SER_PRECIO;
    this.detfacSelected.DETFAC_LISTA = this.detfacSelected.DETFAC_LISTA;
    //this.detfacSelected.DETFAC_ICE='N';
    this.detfacSelected.DETFAC_TRIBIVA = opcion.SER_TRIBUTAIVA; // si tributa iva
    this.detfacSelected.DETFAC_TRIBICE = 'N'; // si tributa ice
    this.encptovtaService.calcularTotalesFact();
    this.aggrid.refreshaggrid(this.encptovtaService.detfac[this.indicador], this.defaultColDef.objeto);
  }
  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }
  agregarSeriales() {
    this.strNumFact = this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO;
    this.strFechaSer = this.datePipe.transform(this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
    this.displayDialogSeriales = true;

    this.closeSidebar();
  }
  cerrarSeriales() {
    this.displayDialogSeriales = false;
  }





  //CDPJ
  accion1() {

  }

  busqVentas(lp) {
    this.encptovtaService.encfac[this.indicador].ENCFAC_VENTA = lp.value.codigo;
  }
  //CDPJ
  busqlistasprecio(lp) {
    console.log('lista', lp.value.codigo)
    this.encptovtaService.encfac[this.indicador].ENCFAC_LISTA = lp.value.codigo;
  }

  desglosarIvaItem() {
    console.log('selected', this.detfacSelected)
    //const dblPorIva = Number(this.selectiva.codigo);//CDPJ
    const intFCPRINIVA = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PVPREAIVA').CFG_VALOR1;
    if (this.detfacSelected !== undefined
      && this.detfacSelected !== null
      && this.detfacSelected !== '') {
      if (intFCPRINIVA === 1 && this.detfacSelected.DETFAC_TRIBIVA === 'S') {
        //this.detfacSelected.DETFAC_PRECIO = this.detfacSelected.DETFAC_PRECIO / (1 + this.encptovtaService.encfac[this.indicador].ENCFAC_PORCEIVA / 100);
        //CDPJ
        this.detfacSelected.DETFAC_PRECIO = Number(this.detfacSelected.DETFAC_PRECIO) / Number((100 + this.detfacSelected.DETFAC_PORIVA) / 100);
        this.detfacSelected.DETFAC_PRECIO = this.detfacSelected.DETFAC_PRECIO.toFixed(this.encptovtaService.NUMDECPRECIO)
        //CDPJ
        this.encptovtaService.calcularTotalesFact();
        this.aggrid.refreshaggrid(this.encptovtaService.detfac[this.indicador], this.defaultColDef.objeto);
      } else {
        this.messageService.add({
          key: this.messageKey,
          severity: 'error',
          summary: 'Información',
          detail: 'No está activada la configuración para incluir el I.V.A. ' +
            'o el artículo no tributa I.V.A. '

        });
        return;
      }

    } else {
      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Información',
        detail: 'Debe seleccionar una línea para aplicar el desglose del IVA'

      });
      return;

    }

  }








  //CDPJ
  cambio(cambio) {
    console.log('cambio', cambio);
    if (cambio === true) {
      this.botonGuardar = false;
      this.botonRegresar = false;
      this.botonNuevo = true;
    }
  }
  //CDPJ
  pagoTarjetaCredito() {

    const TTAPTOC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'TTAPTOC').CFG_VALOR3;
    console.log(TTAPTOC);
    if (TTAPTOC !== null) {


      if (TTAPTOC !== undefined) {
        this.buscarFormaPago(TTAPTOC);
      }

    } else {
      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Información',
        detail: 'No existe un servicio registrado para el pago con tarjeta de crédito'

      });
      return;
    }
    console.log('c');

    // if (strTipo.compareTo("TD") == 0) {// pago con tarjeta de débito
    //   try {
    //     strCodServicio = eje.encontrarRegistro("cfg_valor3",
    //         "saci_maeconfiguracion", "cfg_codigo=" + "'"
    //             + "TTAPTOD" + "'");
    //   } catch (SQLException e) {
    //     // TODO Auto-generated catch block
    //     e.printStackTrace();
    //   }

    // }

    // if (strTipo.compareTo("TC") == 0) {// pago con tarjeta de crédito
    //   try {
    //     strCodServicio = eje.encontrarRegistro("cfg_valor3",
    //         "saci_maeconfiguracion", "cfg_codigo=" + "'"
    //             + "TTAPTOC" + "'");
    //   } catch (SQLException e) {
    //     // TODO Auto-generated catch block
    //     e.printStackTrace();
    //   }
    // }

    // if (modeloFactura.verificaIngServiciosDuplicados(strCodServicio) == true) {
    //   return;

    // } else {
    //   nuevaLinea2(strCodServicio);
    //   tablaFactura.cerrarEditorSinGrabar();
    //   tablaFactura.redraw();
    //   tablaFactura.setFocus();
    // }

  }
  pagoTarjetaDebito() {
    const TTAPTOD = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'TTAPTOD').CFG_VALOR3;
    console.log(TTAPTOD);
    if (TTAPTOD !== null) {

      if (TTAPTOD !== undefined) {
        this.buscarFormaPago(TTAPTOD);
      }
    } else {
      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Información',
        detail: 'No existe un servicio registrado para el pago con tarjeta de débito'

      });
      return;
    }
    console.log('d');
  }

  async buscarFormaPago(parametro) {
    let a = 0;
    for (const item of this.encptovtaService.detfac[this.indicador]) {
      if (item.DETFAC_CODIGO === parametro) {
        a++;
      }
    }

    if (a === 0) {
      if (parametro === null) {
        parametro = '';
      }
      this.opcionbusqueda = 'servicios';
      this.types = [
        { label: 'Código', value: 'SER_CODIGO' },
        { label: 'Descripcio', value: 'SER_DESCRIPCION' },
        { label: 'Cnta contable', value: 'CON_CODIGO' },
        { label: 'Precio', value: 'SER_PRECIO' },
        { label: 'Tributa IVA', value: 'SER_TRIBUTAIVA' }
      ];
      this.busquedacampos = ['', '', '', '', ''];
      this.tabla = 'VEN_MAESERVICIO';
      this.where = 'SER_CODIGO=\'' + parametro + '\'';


      this.spin = true;
      this.busqService.busquedaServicios(this.where).subscribe((datos: any[]) => {
        this.arregloCons = datos;
        this.spin = false;
        //this.displayDialogBusquedaFast = true;
      });
      if (parametro !== '' && parametro !== undefined && parametro !== null) {
        const eR = await this.encptovtaService.erpBusquedaServicios(this.tabla, this.types[0].value, parametro)
        if (eR !== null) {
          if (eR[0] !== undefined) {
            this.nuevaLineaDetalle("S");
            this.manejarSeleccion(eR[0]);
            return;
          }
        }
      }
    }
    else {


    }

  }
  // abrirDialogBusqueda(consulta, tabla, condicion) {
  //   this.busqService.busquedaTabla(consulta, tabla, condicion).subscribe((datos: any[]) => {
  //     this.arregloCons = datos;
  //     this.displayDialogBusquedaFast = true;
  //   });
  // }

  //CDPJ
  //CDPJ
  changelistaprecios(itemDet) {
    if (itemDet.DETFAC_UNIDAD !== null) {
      if (itemDet.DETFAC_UNIDAD.length > 0) {
        let dblPrecio = 0;
        this.encfacService.ecChangelistaprecios(itemDet.DETFAC_CODIGO, itemDet.DETFAC_LISTA, itemDet.DETFAC_UNIDAD).subscribe(data => {
          for (const rs of data) {
            dblPrecio = rs.PRECIO;
          }
          console.log('dblPrecio',dblPrecio)
          if(dblPrecio>0){
            const FCPRINIVA = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PVPREAIVA').CFG_VALOR1;
          // configuración que indica si el precio en los artículos está
          // incluído el iva
          if (FCPRINIVA === 1 && itemDet.DETFAC_TRIBIVA === 'S') {
            dblPrecio = dblPrecio / (1 + Number(itemDet.DETFAC_PORIVA) / 100);
          }

          if (itemDet.DETFAC_TRIBIVA === 'S') {
            if (dblPrecio > 0) {
              if (itemDet.DETFAC_PORIVA === '2') {
                dblPrecio = dblPrecio
                  / (1 + Number(itemDet.DETFAC_PORIVA) / 100);
              }
            }
          }


          this.calcularDescuento(itemDet);//CDPJ
          itemDet.DETFAC_PRECIO = dblPrecio;
          this.encptovtaService.calcularTotalesFact();
          this.aggrid.refreshaggrid(this.encptovtaService.detfac[this.indicador], this.defaultColDef.objeto);
          this.nuevaLinea();
          }else{
           // this.nuevaLinea();
          }
          

          
        });
      }
    }
  }
  //CDPJ
  async calcularDescuento(itemDet) {
    try {
      //console.log('antes', itemDet.DETFAC_LISTA);
      const dato = await this.encfacService.erArtPreDescuento(itemDet.DETFAC_CODIGO, itemDet.DETFAC_LISTA);
      console.log(dato);
      //console.log('1.0',dato['ARTPRE_DESCUENTO']);
      if (dato !== null) {
        ///console.log('1',dato[0].ARTPRE_DESCUENTO);
        if (dato.length > 0) {
          //console.log('2',dato[0]);
          if (dato[0].ARTPRE_CODIGO !== null) {
            itemDet.DETFAC_DESCUENTO = Number(dato[0].ARTPRE_DESCUENTO);
            console.log('descuento', itemDet.DETFAC_DESCUENTO);
          } else {
            itemDet.DETFAC_DESCUENTO = 0;
          }
        } else {
          itemDet.DETFAC_DESCUENTO = 0;
        }

      } else {
        itemDet.DETFAC_DESCUENTO = 0;
      }
    } catch (err) {

    }
    this.aggrid.refreshaggrid(this.encptovtaService.detfac[this.indicador], this.defaultColDef.objeto);
  }
  //CDPJ
  //CDPJ
  async selecciondetfac(params) {

    if (params === 'nuevo') {
      this.nuevaLinea();
      return;
    }

    if (params === 'cancelar') {
      // this.cancelardetalle();
      return;
    }

    if (params === 'eliminar') {
      this.eliminarLineaDetalle();
      return;
    }

    if (params === 'agPed') {
      // this.agregarPedido();
      return;
    }

    if (params.object !== undefined) {
      if (this.encptovtaService.detfac[this.indicador].length === 0)
        return;

      this.detfacSelected = params.object;

      this.cargarEncDatosArticulo(this.detfacSelected);

      // descuento por linea de detalle

      /* if (col == 6 && !item.getDetFacTipoDet().equals("*")
         && lblEstado.getText().equals("PENDIENTE")
         && !item.getDetFacPromocion().equals("1")
         && !lblImpreso.getVisible() && !bolImportarNV
         && !item.getDetFacSigno().equals("-"))// precio
       {
 
         if (hmpConfiguracion.get("GENCLAAUT") == 0) {
           if (Utilidades.verificarPermisosObjConClave(
             "frmVEN_ENCPTOVTA", "vspdatos", 9)
             && !bolDsctMaxVtaLin) // si tiene clave para modificar el precio
           {
             DlgClaveSupervisor dlgClaveSup = new DlgClaveSupervisor(
               new Shell(), "frmVEN_ENCPTOVTA",
               "vspdatos", 9);
             dlgClaveSup.open();
 
             if (dlgClaveSup.bolClaveSup == false) {
               MessageDialog
                 .openInformation(
                   shell,
                   "Información",
                   "Debe ingresar correctamente"
                   + " la clave de Supervisor para modificar el descuento.");
               bolDsctMaxVtaLin = false;
             } else
               bolDsctMaxVtaLin = true;
           }
         } else {
           DlgAutorizacion autoriza = new DlgAutorizacion(
             shell, "MOPRPV");
           autoriza.open();
 
           if (!DlgAutorizacion.glbBolIsValid
             && !bolDsctMaxVtaLin) {
             MessageDialog
               .openInformation(
                 shell,
                 "Información",
                 "Debe ingresar correctamente"
                 + " la clave para modificar el descuento.");
             bolDsctMaxVtaLin = false;
           } else
             bolDsctMaxVtaLin = true;
         }
       }
       // precio
 
       if (col == 7 && !item.getDetFacTipoDet().equals("*")
         && lblEstado.getText().equals("PENDIENTE")
         && !item.getDetFacPromocion().equals("1")
         && !lblImpreso.getVisible() && !bolImportarNV
         && !item.getDetFacSigno().equals("-"))// precio
       {
 
         if (hmpConfiguracion.get("GENCLAAUT") == 0) {
 
           if (Utilidades.verificarPermisosObjConClave(
             "frmVEN_ENCPTOVTA", "vspdatos", 10)
             && !bolModPrecio) // si tiene clave para modificar el precio
           {
             DlgClaveSupervisor dlgClaveSup = new DlgClaveSupervisor(
               new Shell(), "frmVEN_ENCPTOVTA",
               "vspdatos", 10);
             dlgClaveSup.open();
 
             if (dlgClaveSup.bolClaveSup == false) {
               MessageDialog
                 .openInformation(
                   shell,
                   "Información",
                   "Debe ingresar correctamente"
                   + " la clave de Supervisor para modificar el precio.");
               bolModPrecio = false;
             } else
               bolModPrecio = true;
           }
         } else {
           DlgAutorizacion autoriza = new DlgAutorizacion(
             shell, "MOPRPV");
           autoriza.open();
 
           if (!DlgAutorizacion.glbBolIsValid && !bolModPrecio) {
             MessageDialog
               .openInformation(
                 shell,
                 "Información",
                 "Debe ingresar correctamente"
                 + " la clave para modificar el precio.");
             bolModPrecio = false;
           } else
             bolModPrecio = true;
         }
       }
 */
    }
  }

  async navegarFactura(strOpcion, strCondicion) {
    // Verifica los permisos
    if (this.permisoPtoVta !== null) {
      if (this.permisoPtoVta.PERTODO === 0) {
        if (this.permisoPtoVta.PERSELECCION === 0) {
          this.messageService.add({
            key: this.messageKey,
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario '
              + this.encptovtaService.usuario.identificacion
              + ', no tiene permiso de consulta. Consulte con el Administrador del Sistema.'
          });
          return;
        }
      }
    }

    if (this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO.length > 0 && this.botonGuardar === true) {
      if (await this.verificarExisteFP() === false)
        return;
    }

    if (await this.verificarSaldoFacturaPagadas() == false) {
      return;
    }

    if (await this.ValidarSeriales(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO) === false) {
      return;
    }

    const ddd: any = await this.encptovtaService.seleccionarRegistro(strOpcion, ' ', strCondicion);
    let numfac = '';
    for (const rs of ddd) {
      numfac = rs.ENCFAC_NUMERO;
    }


    this.consultarFacturas(numfac);
    this.actualizarDatosElectronicos(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);

    this.deleteTrnDocumento(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO, "01");

  }

  async consultarFacturas(strNum) {
    console.log(strNum);
    this.bolNuevo = false;
    // cmbPorIva.setEnabled(false);
    if (await this.consultarEncabezadoFactura(strNum) === true) {
      if (await this.consultarDetalleFactura(strNum) === true) {
        this.aggrid.habilitarGrid();
        /*	modeloFactura.cambiarSignoDetalle();
          groupDet.setEnabled(true);
          mnuAcciones.setEnabled(true);
          mnuEditar.setEnabled(true);
          mnuVer.setEnabled(true);
  
          modeloFactura.colorearFilasEspeciales(); // colorear componentes y promociones
  */
        // Verifica el periodo contable
        const dia = this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAEMISION.getDate();
        const mes = this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAEMISION.getMonth();
        const anio = this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAEMISION.getFullYear();


        if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === true) {

          // verificar el estado de la factura para habilitar o no los botones
          if (this.encptovtaService.encfac[this.indicador].TXT_NUMERO.length > 0)
            this.verificarEstadoFactura();
        } else {
          document.getElementById('contorno').style.pointerEvents = 'none';
          this.boolpago = true;//CDPJ
          this.aggrid.bloquearGrid();
          /*groupEnc.setEnabled(false);
          toolBarDetalle.setEnabled(false);
          mnuNuevaLinDet.setEnabled(false);
          mnuElimLinDet.setEnabled(false);*/
          // groupTipoDet.setEnabled(false);
          this.habilitarBotones(true, false, false, false);
        }
      }

			/*if (lblEstadoFE.getText().compareTo("AUT") == 0
					|| lblEstadoFE.getText().compareTo("PROCE") == 0
					|| lblEstadoFE.getText().compareTo("NOTIF") == 0
					|| lblEstado.getText().compareTo("ANULADA") == 0) {
				btnGenerarFacturaElectronica.setEnabled(false);
			} else
				btnGenerarFacturaElectronica.setEnabled(true);

			if (Utilidades.glb_str_facEleActivada.compareTo("1") == 0
					&& Utilidades.glb_str_prodFacEleActivada.compareTo("1") == 0
					&& lblAutorizacionFE.getText().compareTo("") != 0)
				btnAnular.setEnabled(false);*/

    }
  }

  async consultarDetalleFactura(encfaccodigo) {
    let bolConsultar = false;
    const data = await this.encptovtaService.getDetfac(encfaccodigo);

    let fila = 1;
    for (const rs of data) {
      console.log('detalle', rs)
      let item: VenDetfacZ;
      item = rs;

      if (rs.DETFAC_TRIBIVA !== null)
        item.DETFAC_TRIBIVA = rs.DETFAC_TRIBIVA;
      else
        item.DETFAC_TRIBIVA = "N";

      item.ENCPED_NUMERO = rs.ENCPED_NUMERO === null ? "" : rs.ENCPED_NUMERO;
      item.ENCGRE_NUMERO = rs.ENCGRE_NUMERO === null ? "" : rs.ENCGRE_NUMERO;
      item.DETFAC_UNIDAD = rs.DETFAC_UNIDAD === null ? "" : rs.DETFAC_UNIDAD;
      if (this.bolImportarNV === false)
        item.ENCFAC_NUMERO = rs.ENCFAC_NUMERO;
      else
        item.ENCFAC_NUMERO = "";
      item.DETFAC_SIGNO = rs.DETFAC_SIGNO === null ? "" : rs.DETFAC_SIGNO;

      item.DETFAC_CANTIDAD = Number(item.DETFAC_CANTIDAD).toFixed(this.encptovtaService.NUMDECCANTIDAD);
      item.DETFAC_PRECIO = Number(item.DETFAC_PRECIO).toFixed(this.encptovtaService.NUMDECPRECIO);
      item.DETFAC_TOTAL = Number(item.DETFAC_TOTAL).toFixed(this.encptovtaService.NUMDECTOTAL);
      item.DETFAC_DESCUENTO = Number(item.DETFAC_DESCUENTO).toFixed(this.confIniciales.getNumDecSist());

      this.encptovtaService.detfac[this.indicador].push(item);
      fila = fila + 1;
      item.DETFAC_LINEA_3 = rs.DETFAC_LINEA;
    }

    this.aggrid.refreshaggrid(this.encptovtaService.detfac[this.indicador], this.defaultColDef.objeto);
    // obtener el número de items disponibles
    console.log('detalle', this.encptovtaService.detfac[this.indicador].length)
    if (this.intNumItems > 0) {
      console.log('items', this.intNumItems);
      this.intNumItemsDisp = this.intNumItems - Number(this.encptovtaService.detfac[this.indicador].length);
      if (this.intNumItemsDisp >= 0) {
        console.log('2', this.intNumItemsDisp);
        this.encptovtaService.encfac[this.indicador].ENCFAC_ITEMSDISPONIBLES = Number(this.intNumItemsDisp);
        console.log('3');
      }
      else {
        console.log('2');
        this.encptovtaService.encfac[this.indicador].ENCFAC_ITEMSDISPONIBLES = "0";
      }

    }




    // seleccionar primer item de la factura y colocar el tipo de detalle de ese item
    if (this.encptovtaService.detfac[this.indicador].length > 0) {
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(0, 'BOD_CODIGO');
    }

    bolConsultar = true;
    this.encptovtaService.calcularTotalesFact();

    return bolConsultar;
  }

  async consultarEncabezadoFactura(encfaccodigo) {
    this.bolConsultar = false;
    if (encfaccodigo === '') {
      return this.bolConsultar;
    }
    if (this.bolImportarNV === false){
      this.limpiarDatos();
    }
    let resp = await this.encptovtaService.getEncfac(encfaccodigo);
    this.listaEncFact = [];
   
    for (const rs of resp) {
      const item: VenEncfacZ = {};
      item.ENCFAC_NUMERO = rs.ENCFAC_NUMERO;
      item.COM_CODIGO = rs.COM_CODIGO;
      item.ENCFAC_FECHAEMISION = rs.ENCFAC_FECHAEMISION;
      item.ENCFAC_IVA = rs.ENCFAC_IVA;
      item.ENCFAC_ESTADO = rs.ENCFAC_ESTADO;
      item.CLI_CODIGO = rs.CLI_CODIGO;
      item.CLI_NOMBRE = rs.CLI_NOMBRE;
      item.BOD_CODIGO = rs.BOD_CODIGO;
      this.strCodBodega = rs.BOD_CODIGO === null ? "" : rs.BOD_CODIGO;
      item.VEN_CODIGO = rs.VEN_CODIGO;
      item.ENCFAC_REFERENCIA = rs.ENCFAC_REFERENCIA;
      item.ENCFAC_TIPO = rs.ENCFAC_TIPO;
      item.ENCFAC_TIPOFACTURA = rs.ENCFAC_TIPOFACTURA === null || rs.ENCFAC_TIPOFACTURA === undefined ? '18' : rs.ENCFAC_TIPOFACTURA
      item.ENCFAC_LISTA = rs.ENCFAC_LISTA;
      item.ENCFAC_FECHAVENCIMIENTO = rs.ENCFAC_FECHAVENCIMIENTO;
      item.ENCFAC_TOTAL = rs.ENCFAC_TOTAL;
      item.ENCFAC_TOTALNETO = rs.ENCFAC_TOTALNETO;
      item.ENCFAC_VALORDES = rs.ENCFAC_VALORDES;
      this.encptovtaService.dblValorDescGlb = rs.ENCFAC_VALORDES;
      item.ENCFAC_PORCEDES = rs.ENCFAC_PORCEDES;
      this.encptovtaService.dblPorcDescGlb = rs.ENCFAC_PORCEDES;
      item.ENCFAC_VALORIVA = rs.ENCFAC_VALORIVA;
      item.ENCFAC_PORCEIVA = rs.ENCFAC_PORCEIVA;
      this.dblPorcIva = rs.ENCFAC_PORCEIVA;
      item.ENCFAC_VALORICE = rs.ENCFAC_VALORICE;
      item.ENCFAC_PORCEICE = rs.ENCFAC_PORCEICE;
      item.ENCFAC_BASEIVA = rs.ENCFAC_BASEIVA;
      item.ENCFAC_BASEICE = rs.ENCFAC_BASEICE;
      item.ENCFAC_BASECERO = rs.ENCFAC_BASECERO;
      item.ASI_NRO = rs.ASI_NRO;
      item.ENCFAC_DIASCRDT = rs.ENCFAC_DIASCRDT;
      item.ENCFAC_NOTADEVENTA = rs.ENCFAC_NOTADEVENTA;
      item.ENCFAC_COMENTARIO = rs.ENCFAC_COMENTARIO;
      item.ENCFAC_RUCIDE = rs.ENCFAC_RUCIDE;
      item.ENCFAC_DIRECCION = rs.ENCFAC_DIRECCION;
      item.ENCFAC_DIRECCION2 = rs.ENCFAC_DIRECCION2;
      item.ENCFAC_TELEFONO = rs.ENCFAC_TELEFONO;
      item.ENCFAC_TELEFONO2 = rs.ENCFAC_TELEFONO2;
      item.ENCFAC_FAX = rs.ENCFAC_FAX;
      item.ENCFAC_CORREO = rs.ENCFAC_CORREO;
      item.ENCFAC_GRUPO = rs.ENCFAC_GRUPO;
      item.ENCFAC_IMPRESO = rs.ENCFAC_IMPRESO;
      item.ENCFAC_REFCLI = rs.ENCFAC_REFCLI;
      item.ENCFAC_FORMAPAGO = rs.ENCFAC_FORMAPAGO === null ? "" : rs.ENCFAC_FORMAPAGO;
      //CDPJ
      console.log('refcli',rs.ENCFAC_REFCLI)
      item.USU_IDENTIFICACION=rs.USU_IDENTIFICACION === undefined ? "" : rs.USU_IDENTIFICACION === null ? "" : rs.USU_IDENTIFICACION;
      item.ENCFAC_ESTADO_FE = rs.ENCFAC_ESTADO_FE === undefined ? "" : rs.ENCFAC_ESTADO_FE === null ? "" : rs.ENCFAC_ESTADO_FE;
      item.ENCFAC_AUTORIZACION_FE = rs.ENCFAC_AUTORIZACION_FE === undefined ? "" : rs.ENCFAC_AUTORIZACION_FE === null ? "" : rs.ENCFAC_AUTORIZACION_FE;
      item.ENCFAC_CLAVEACCESO_FE = rs.ENCFAC_CLAVEACCESO_FE === undefined ? "" : rs.ENCFAC_CLAVEACCESO_FE === null ? "" : rs.ENCFAC_CLAVEACCESO_FE;
      //CDPJ

      console.log(rs.ENCFAC_FORMAPAGO);
      if (rs.ENCFAC_FORMAPAGO !== null)
        this.strFormaPago = rs.ENCFAC_FORMAPAGO;
      console.log(this.strFormaPago);
      item.ENCFAC_TIPODSCTO = rs.ENCFAC_TIPODSCTO;
      item.ENCFAC_AUTORIZACION = rs.ENCFAC_AUTORIZACION;
      item.ENCFAC_NEMONICO = rs.ENCFAC_NEMONICO;
      if (!this.bolImportarNV)
        this.strTipoNum = rs.ENCFAC_NEMONICO;
      item.ENCFAC_OBSERVACION = rs.ENCFAC_OBSERVACION;

      if (rs.ENCFAC_PLACA_CLI != null)
        item.ENCFAC_PLACA_CLI = rs.ENCFAC_PLACA_CLI;
      else
        item.ENCFAC_PLACA_CLI = "";

      if (item.ENCFAC_TIPODSCTO != null) {
        if (item.ENCFAC_TIPODSCTO = 'V')
          this.glbBolEsValor = true;
        else if (item.ENCFAC_TIPODSCTO = 'P')
          this.glbBolEsValor = false;
      }

      item.ENCFAC_VENTA === null ? "" : rs.ENCFAC_VENTA;
      this.listaEncFact.push(item);
      console.log(this.listaEncFact);
      if (this.listaEncFact.length > 0) {
        this.cargarDatosEncabezado();
        this.bolConsultar = true;
      }
    }
    //CDPJ
    const clitipo: any = await this.encptovtaService.encontrarTipoCli(this.encptovtaService.encfac[this.indicador].CLI_CODIGO);

    for (const rs of clitipo) {
      //console.log('entro')
      this.strTipoCliD = rs.CLI_TIPO;
    }
    //CDPJ
    return this.bolConsultar;
  }

  limpiarDatos() {

    this.encptovtaService.dblValorTotal[this.indicador] = 0;
    this.encptovtaService.dblCostoTotalItem[this.indicador] = 0;
    this.encptovtaService.dblValorIceItem[this.indicador] = 0;
    this.encptovtaService.dblBaseIceItem[this.indicador] = 0;
    this.encptovtaService.dblPorcDescGlb = 0;
    this.encptovtaService.dblBaseIceTotal[this.indicador] = 0;
    this.encptovtaService.dblValorIceTotal[this.indicador] = 0;
    this.encptovtaService.dblBaseCeroTotal[this.indicador] = 0;
    this.encptovtaService.dblBaseIvaTotal[this.indicador] = 0;
    this.encptovtaService.dblValorIvaTotal[this.indicador] = 0;
    this.encptovtaService.dblValorNeto[this.indicador] = 0;
    this.encptovtaService.dblValorDescGlb = 0;


    this.encptovtaService.encfac[this.indicador].ENCFAC_DIASCRDT = '0';
    this.encptovtaService.encfac[this.indicador].TXT_NUMERO = '';
    this.txtNumerosetEditable = true;
    this.encptovtaService.encfac[this.indicador].ENCFAC_AUTORIZACION = '';
    this.encptovtaService.encfac[this.indicador].CLI_CODIGO = '';
    this.encptovtaService.encfac[this.indicador].CLI_NOMBRE = '';
    if (this.encptovtaService.encfac[this.indicador].VEN_CODIGO === undefined) {
      this.encptovtaService.encfac[this.indicador].VEN_CODIGO = '';
    }
    if (this.encptovtaService.encfac[this.indicador].VEN_NOMBRE === undefined) {
      this.encptovtaService.encfac[this.indicador].VEN_NOMBRE = '';
    }

    if (this.encptovtaService.encfac[this.indicador].BOD_CODIGO === undefined) {
      this.encptovtaService.encfac[this.indicador].BOD_CODIGO = '';
    }
    this.encptovtaService.encfac[this.indicador].VEN_CODIGO = this.encptovtaService.encfac[this.indicador].VEN_CODIGO;
    this.encptovtaService.encfac[this.indicador].VEN_NOMBRE = this.encptovtaService.encfac[this.indicador].VEN_NOMBRE;
    this.encptovtaService.encfac[this.indicador].BOD_CODIGO = this.encptovtaService.encfac[this.indicador].BOD_CODIGO;

    this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAEMISION = new Date();
    this.encptovtaService.encfac[this.indicador].ENCFAC_HORAEMISION = new Date();

    this.obtenerFechaHoraDoc();

    /*		dateTimeVenc.setDay(cal.get(Calendar.DATE));
        dateTimeVenc.setMonth(cal.get(Calendar.MONTH));
        dateTimeVenc.setYear(cal.get(Calendar.YEAR));
        dateTimeVenc.setEnabled(true);*/

    this.encptovtaService.encfac[this.indicador].ENCFAC_REFCLI = '';
    this.encptovtaService.encfac[this.indicador].ENCFAC_REFERENCIA = '';


    // selecciona el primer item de la lista de precios		
    //CDPJ
    // selecciona el primer item de la lista de precios
    if (this.cmblista === undefined) {
      this.cargarListasPrecios();
    }

    if (this.cmblista.length > 0) {
      const j = this.cmblista[0];
      this.selectlista = { codigo: j.codigo, name: j.name };
      this.encptovtaService.encfac[this.indicador].ENCFAC_LISTA = j.codigo;
    }
    this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO = ''
    // this.selectiva = this.cmbiva[0];
    // this.encptovtaService.encfac[this.indicador].ENCFAC_IVA = this.selectiva.codigo;

    // for (const k of this.cmbiva) {
    //   if (k.codigo === '12') {
    //     this.selectiva = { codigo: k.codigo, name: k.name };
    //     this.encptovtaService.encfac[this.indicador].ENCFAC_PORCEIVA = k.codigo;
    //   }
    // }
    this.llenarCombo()
    //CDPJ


    this.txtCodClientesetEditable = true;
    this.btnClientesetEnabled = true;

    this.lblCodArt = '';
    this.lblUnidad = '';
    this.lblCantidad = Number("0").toFixed(this.confIniciales.getNumDecSist());
    this.lblDsctoArt = Number("0").toFixed(this.confIniciales.getNumDecSist());
    this.lblExistencia = Number("0").toFixed(this.confIniciales.getNumDecSist());
    this.lblPVP = Number("0").toFixed(this.NUMDECPRECIO);

    this.encptovtaService.encfac[this.indicador].ENCFAC_TOTALNETO = Number("0").toFixed(this.NUMDECTOTAL);
    this.encptovtaService.encfac[this.indicador].ENCFAC_DESCUENTO = Number("0").toFixed(this.NUMDECTOTAL);
    this.encptovtaService.encfac[this.indicador].ENCFAC_VALORDES = Number("0").toFixed(this.NUMDECTOTAL);
    this.encptovtaService.encfac[this.indicador].ENCFAC_PORCEDES = Number("0").toFixed(this.NUMDECTOTAL);
    this.encptovtaService.encfac[this.indicador].ENCFAC_VALORIVA = Number("0").toFixed(this.NUMDECTOTAL);
    this.encptovtaService.encfac[this.indicador].ENCFAC_VALORICE = Number("0").toFixed(this.NUMDECTOTAL);
    this.encptovtaService.encfac[this.indicador].ENCFAC_BASECERO = Number("0").toFixed(this.NUMDECTOTAL);
    this.encptovtaService.encfac[this.indicador].ENCFAC_BASEIVA = Number("0").toFixed(this.NUMDECTOTAL);
    this.encptovtaService.encfac[this.indicador].ENCFAC_BASEICE = Number("0").toFixed(this.NUMDECTOTAL);
    this.encptovtaService.encfac[this.indicador].ENCFAC_TOTAL = Number("0").toFixed(this.NUMDECTOTAL);
    this.encptovtaService.encfac[this.indicador].ENCFAC_VALORDES = Number("0").toFixed(this.NUMDECTOTAL);
    /*
    toolBarDetalle.setEnabled(true);
    mnuNuevaLinDet.setEnabled(true);*/

    this.encptovtaService.encfac[this.indicador].ASI_NRO = '';
    this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO = '';
    document.getElementById('contorno').style.pointerEvents = 'all';
    this.aggrid.habilitarGrid();
    /*groupEnc.setEnabled(true);
    groupDet.setEnabled(true);
    cmpTipoDoc.setEnabled(true);*/
    this.selectventa = { codigo: '0', name: 'Directa' };

    // limpiar la tabla

    this.encptovtaService.detfac[this.indicador] = [];
    this.aggrid.refreshaggrid(this.encptovtaService.detfac[this.indicador], this.defaultColDef.objeto);

    // número de items disponibles

    this.encptovtaService.encfac[this.indicador].ENCFAC_ITEMSDISPONIBLES = this.encptovtaService.encfac[this.indicador].ENCFAC_MAXIMOITEMS;

    // totales
    //this.encptovtaService.calcularTotalesFact();//CDPJ---

    this.strFormaPago = '';

    /*if (Utilidades.verificarPermisosObjConClave("frmVEN_ENCPTOVTA", "vspdatos", 9))
      bolDsctMaxVtaLin = false;

    if (Utilidades.verificarPermisosObjConClave("frmVEN_ENCPTOVTA", "vspdatos", 10))
      bolModPrecio = false;
    */
    this.bolCantPed = false;
    this.bolCantDev = false;

    // limpiar aviso de las promociones
    this.labelPromocionsetVisible = false;
    this.labelChqProt = false;

    // colocar tipo de documento en nota de venta
    // this.seleccionarNV(false);

    //CDPJ
    if(this.chkNotaVenta === true){
      this.seleccionarNV(true);
    }else if(this.chkFactura === true){
      this.seleccionarNV(false);
    }
    //CDPJ
    this.chkNotaVentasetEnabled = true;

    this.bolImportarNV = false;


    this.lblObsAnuladasetVisible = false;

    this.lblImpresosetVisible = false;

    this.encptovtaService.encfac[this.indicador].ENCFAC_SALDOCLIENTE = 0;

    this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO_FE = '';
    this.encptovtaService.encfac[this.indicador].ENCFAC_AUTORIZACION_FE = '';
    this.encptovtaService.encfac[this.indicador].ENCFAC_CLAVEACCESO_FE = '';

    this.encptovtaService.encfac[this.indicador].ENCFAC_PLACA_CLI = '';
    this.encptovtaService.encfac[this.indicador].ENCFAC_PLACA = '';
    //CDPJ
    this.encptovtaService.encfac[this.indicador].ENCFAC_TIPOFACTURA = '18';
    //CDPJ
    this.listaPedidos = [];
  }

  async completarDatosCliente(strCodCliente) {
    this.dblSaldoCliente = 0;
    // obtener el saldo del cliente

    this.encptovtaService.encfac[this.indicador].ENCFAC_SALDOCLIENTE = 0;

    if (this.encptovtaService.encfac[this.indicador].CLI_CODIGO !== this.strCodConsFinal) {
      const saldo = await this.encfacService.obtenerSaldoCliente(this.encptovtaService.encfac[this.indicador].CLI_CODIGO);
      if (saldo[0] !== undefined) {
        this.encptovtaService.encfac[this.indicador].ENCFAC_SALDOCLIENTE = saldo[0].SALDO.toFixed(this.confIniciales.getNumDecSist());
      }
    }

    // obtener días de credito, límite de crédito asignado en la ficha del cliente
    const data = await this.encptovtaService.getDatosCli(strCodCliente);
    let strListaPrec = "";

    for (const rs of data) {
      if (rs.CLI_DIACREDIT > 0)
        this.intDiasCredito = rs.CLI_DIACREDIT;

      if (rs.CLI_LISTA !== null)
        strListaPrec = rs.CLI_LISTA;

      if (rs.CLI_LIMCREDIT > 0)
        this.dblCliLimCredit = rs.CLI_LIMCREDIT;

      if (rs.CLI_DESCUENTO > 0)
        this.dblPorcentajeDes = rs.CLI_DESCUENTO;

      if (rs.CLI_TARDSC !== null)
        this.strTargetaDesc = rs.CLI_TARDSC;
    }

    if (this.encptovtaService.encfac[this.indicador].ENCFAC_DIASCRDT !== ''
      || this.encptovtaService.encfac[this.indicador].ENCFAC_DIASCRDT === '0')
      this.encptovtaService.encfac[this.indicador].ENCFAC_DIASCRDT = this.intDiasCredito;

    // calcular fecha de vencimiento de acuerdo a los días de crédito
    this.calcularFechaVencimiento();

    // cargar la lista de precios asignada al cliente

    if (strListaPrec.length > 0) {
      for (const i of this.cmblista) {
        if (i.name === strListaPrec) {
          this.selectlista = i;
        }
      }
    }

  }

  async cargarDatosEncabezado() {
    let item: VenEncfacZ;

    
      

    if (this.listaEncFact !== null)
      item = this.listaEncFact[0];
    else
      return;

    // NUMERO FACTURA
    if (this.bolImportarNV === false) {

      //this.dblPorcIva = item.ENCFAC_PORCEIVA;

      // for (const i of this.cmbiva) {
      //   if (Number(i.name) === this.dblPorcIva) {
      //     this.selectiva = i;
      //   }
      // }
     

      this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO = item.ENCFAC_NUMERO;
      console.log(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);
      this.encptovtaService.encfac[this.indicador].TXT_NUMERO = item.ENCFAC_NUMERO.replace(item.ENCFAC_NEMONICO, '');
      console.log(item.ENCFAC_NEMONICO);
      console.log(item.ENCFAC_NUMERO.replace(item.ENCFAC_NEMONICO, ''));
      // this.lblNumFactgetVisible = true;

      // FECHA EMISION
      this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAEMISION = new Date(item.ENCFAC_FECHAEMISION);
      this.encptovtaService.encfac[this.indicador].ENCFAC_HORAEMISION = new Date(item.ENCFAC_FECHAEMISION);

    }
    this.encptovtaService.encfac[this.indicador].ENCFAC_PORCEIVA=item.ENCFAC_PORCEIVA
    console.log('item.ENCFAC_PORCEIVA',item.ENCFAC_PORCEIVA)
    for (const j of this.cmbporceiva) {
      if (Number(this.encptovtaService.encfac[this.indicador].ENCFAC_PORCEIVA) === Number(j.name)) {
        this.selectcombtipoIva = { codigo: j.codigo, name: j.name };
        this.encptovtaService.encfac[this.indicador].ENCFAC_PORCEIVA=Number(this.selectcombtipoIva.name)
      }
    }
    let porcentaje=this.encptovtaService.encfac[this.indicador].ENCFAC_PORCEIVA
    let data1=this.confIniciales.srifetariiva.find(element => Number(element.TARIIVA_PORCENTAJE) === Number(porcentaje))
    this.encptovtaService.porcetariiva=Number(porcentaje);
    this.encptovtaService.codtariiva=data1.TARIIVA_CODIGO;
    // habilitar/deshabilitar según configuración la caja de texto de la numeración del documento
    const PVDNROFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PVDNROFAC').CFG_VALOR1;
    if (PVDNROFAC === 1) {
      this.txtNumerosetEditable = false;
      //document.getElementById('txtNumero').setAttribute('disabled', 'true');
    }
    else {
      this.txtNumerosetEditable = true;
      //document.getElementById('txtNumero').setAttribute('disabled', 'false');
    }
    // CLIENTE
    this.encptovtaService.encfac[this.indicador].CLI_CODIGO = item.CLI_CODIGO;
    this.encptovtaService.encfac[this.indicador].CLI_NOMBRE = item.CLI_NOMBRE == null ? '' : item.CLI_NOMBRE;

    if (this.bolImportarNV === false) {
      this.txtCodClientesetEditable = false;
      this.btnClientesetEnabled = false;
    }
    this.completarDatosCliente(item.CLI_CODIGO);
    this.encptovtaService.encfac[this.indicador].BOD_CODIGO = item.BOD_CODIGO;
    this.encptovtaService.encfac[this.indicador].ENCFAC_AUTORIZACION = item.ENCFAC_AUTORIZACION;
    this.encptovtaService.encfac[this.indicador].ENCFAC_REFCLI=item.ENCFAC_REFCLI;//CDPJ
    this.encptovtaService.encfac[this.indicador].USU_IDENTIFICACION=item.USU_IDENTIFICACION;//CDPJ
    if (this.bolImportarNV === false) {
      // VENDEDOR
      if (item.VEN_CODIGO !== null) {
        const er = await this.encfacService.erpDatosVendedor(item.VEN_CODIGO);
        if (er !== null) {
          if (er[0] !== undefined) {
            this.encptovtaService.encfac[this.indicador].VEN_CODIGO = item.VEN_CODIGO;
            this.encptovtaService.encfac[this.indicador].VEN_NOMBRE = er[0].VEN_NOMBRE;
            this.strCodVendedor = this.encptovtaService.encfac[this.indicador].VEN_CODIGO;
            // if (this.bolvencodigo === true) {
            //   console.log('nuevo');
            //   this.ven_codigo = item.VEN_CODIGO
            // }
            console.log('clave', this.bolClave);
            if (this.bolClave === true) {
              console.log('nuevo');
              this.ven_codigo = item.VEN_CODIGO
            }


          }
        }
        await this.colocarBodegaVendedor(this.strCodVendedor);
        console.log('colocarBodegaVendedor');
      } else {
        this.encptovtaService.encfac[this.indicador].VEN_NOMBRE = '';
      }
    }
    // lista de precios
    if (item.ENCFAC_LISTA.length > 0) {
      for (const i of this.cmblista) {
        if (i.name === item.ENCFAC_LISTA) {
          this.selectlista = i;
        }
      }
    }

    this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAVENCIMIENTO = new Date(item.ENCFAC_FECHAVENCIMIENTO);
    this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAVENCIMIENTO = new Date(item.ENCFAC_FECHAVENCIMIENTO);



    if (this.bolImportarNV === false) {
      // nota de venta
      if (item.ENCFAC_NOTADEVENTA != null) {
        if (item.ENCFAC_NOTADEVENTA === "S")
          this.seleccionarNV(true);
      } else
        this.seleccionarNV(false);
    }
    this.encptovtaService.encfac[this.indicador].ENCFAC_TOTALNETO = Number(item.ENCFAC_TOTALNETO).toFixed(this.NUMDECTOTAL);
    this.encptovtaService.encfac[this.indicador].ENCFAC_VALORDES = Number(item.ENCFAC_VALORDES).toFixed(this.NUMDECTOTAL);
    this.encptovtaService.encfac[this.indicador].ENCFAC_PORCEDES = Number(item.ENCFAC_PORCEDES).toFixed(this.NUMDECSIST);
    this.encptovtaService.encfac[this.indicador].ENCFAC_BASEIVA = Number(item.ENCFAC_BASEIVA).toFixed(this.NUMDECTOTAL);
    this.encptovtaService.encfac[this.indicador].ENCFAC_VALORIVA = Number(item.ENCFAC_VALORIVA).toFixed(this.NUMDECTOTAL);
    this.encptovtaService.encfac[this.indicador].ENCFAC_BASEICE = Number(item.ENCFAC_BASEICE).toFixed(this.NUMDECTOTAL);
    this.encptovtaService.encfac[this.indicador].ENCFAC_VALORICE = Number(item.ENCFAC_VALORICE).toFixed(this.NUMDECTOTAL);
    this.encptovtaService.encfac[this.indicador].ENCFAC_BASECERO = Number(item.ENCFAC_BASECERO).toFixed(this.NUMDECTOTAL);
    this.encptovtaService.encfac[this.indicador].ENCFAC_TOTAL = Number(item.ENCFAC_TOTAL).toFixed(this.NUMDECSIST);

    const dblValorTotal = Number(item.ENCFAC_TOTAL);

    // estado de la factura
    if (item.ENCFAC_ESTADO === "P") {
      this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO = "PENDIENTE";
      document.getElementById('contorno').style.pointerEvents = 'all';
      this.boolpago = false;//CDPJ
      this.aggrid.habilitarGrid();
      /*groupEnc.setEnabled(true);
      toolBarDetalle.setEnabled(true);
      mnuNuevaLinDet.setEnabled(true);
      txtNumero.setEditable(false);*/
    } else if (item.ENCFAC_ESTADO === "G" || item.ENCFAC_ESTADO === "CM" || item.ENCFAC_ESTADO === "A") {
      if (item.ENCFAC_ESTADO === "G") {
        this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO = "PAGADA";
        this.boolpago = true;//CDPJ
        document.getElementById('contorno').style.pointerEvents = 'none';
      }

      if (item.ENCFAC_ESTADO === "A") {
        document.getElementById('contorno').style.pointerEvents = 'none';
        this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO = "ANULADA";
        this.boolpago = true;//CDPJ
      }
      if (item.ENCFAC_ESTADO === "CM") {
        document.getElementById('contorno').style.pointerEvents = 'none';
        this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO = "CAMBIO";
      }

      if (this.bolImportarNV === false) {
        document.getElementById('contorno').style.pointerEvents = 'none';
        this.boolpago = true;//CDPJ
        this.aggrid.bloquearGrid();
        /* groupEnc.setEnabled(false);
         toolBarDetalle.setEnabled(false);
         mnuNuevaLinDet.setEnabled(false);
         mnuElimLinDet.setEnabled(false);*/
      }
    }
    // observación cuando está anulada
    this.lblObsAnulada = item.ENCFAC_OBSERVACION == null ? "" : item.ENCFAC_OBSERVACION;

    if (this.bolImportarNV === false) {
      // tipo de documento
      // this.cmpTipoDocsetEnabled = false;

      // REFERENCIA EN CASO DE CAMBIO DE ARTICULOS
      this.encptovtaService.encfac[this.indicador].ENCFAC_REFERENCIA = item.ENCFAC_REFERENCIA == null ? "" : item.ENCFAC_REFERENCIA;
      this.txtReferenciasetEditable = false;
    }

    // impresión de la factura
    if (item.ENCFAC_IMPRESO === "S") {
      this.lblImpresosetVisible === true;
      this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO = "IMPRESA";
    } else
      this.lblImpresosetVisible === false;

    // forma de pago de la factura
    this.strFormaPago = item.ENCFAC_FORMAPAGO;
    console.log(this.strFormaPago);
    // tipo de venta
    if (item.ENCFAC_VENTA === '2') {
      this.selectventa = { codigo: '1', name: 'eCommerce' };
    } else {
      this.selectventa = { codigo: '0', name: 'Directa' };
    }

    this.actualizarDatosElectronicos(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);
    //CDPJ
    const clitipo: any = await this.encptovtaService.encontrarTipoCli(this.encptovtaService.encfac[this.indicador].CLI_CODIGO);

    for (const rs of clitipo) {
      //console.log('entro')
      this.strTipoCliD = rs.CLI_TIPO;
    }

    //firma
    this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO_FE = item.ENCFAC_ESTADO_FE;
    this.encptovtaService.encfac[this.indicador].ENCFAC_AUTORIZACION_FE = item.ENCFAC_AUTORIZACION_FE;
    this.encptovtaService.encfac[this.indicador].ENCFAC_CLAVEACCESO_FE = item.ENCFAC_CLAVEACCESO_FE;
    //Firma
    //Asiento
    if (item.ASI_NRO === null || item.ASI_NRO === undefined || item.ASI_NRO === '') {
      this.encptovtaService.encfac[this.indicador].ASI_NRO = '';
    } else {
      this.encptovtaService.encfac[this.indicador].ASI_NRO = item.ASI_NRO;
    }

    //Asiento
    //items disponibles
    // const NUMITEMPV = this.numitempv;this.intNumItemsDisp
    // console.log('maximo',NUMITEMPV);

    // if (NUMITEMPV !== null && NUMITEMPV !== undefined && NUMITEMPV > 0 ){

    //     let itemdisponibles = Number(this.encptovtaService.encfac[this.indicador].ENCFAC_ITEMSDISPONIBLES - this.encptovtaService.detfac[this.indicador].length);
    //       if(itemdisponibles < 0){
    //          itemdisponibles=0;
    //       }
    //     this.encptovtaService.encfac[this.indicador].ENCFAC_ITEMSDISPONIBLES = itemdisponibles;

    // }else{

    // }




    // //items disponibles

    //CDPJ
  }

  actualizarDatosElectronicos(dat) {

  }
  //CDPJ
  actualizarDatos(strTipoCorreo, strCodigo) {
    if (this.txtCorreo1 === null) {
      this.txtCorreo1 = '';
    }

    if (this.txtCorreo2 === null) {
      this.txtCorreo2 = '';
    }

    if (this.txtCorreo1.length === 0) {

      this.encfacService.erActualizarDatos().subscribe(er => {
        let correocopia = '';
        if (er !== null) {
          if (er[0] !== undefined) {
            correocopia = er[0].COM_CORREOCOPIAFAC;
          }
        }

        this.txtCorreo1 = correocopia;
      });


    }

    let strSentencia = '';


    if (strTipoCorreo === 'C') { // clientes

      this.encfacService.ecActualizarCorreoCli(this.txtCorreo1, this.txtCorreo2, strCodigo).subscribe();
      this.auditoriagral.registrarAuditoria('VEN_MAECLIENTE', this.txtCorreo1 + '/' + this.txtCorreo2 + '/' + strCodigo, 'A',
        '', '01', '', '', '', '').subscribe();
    }


    if (strTipoCorreo === 'P') {// proveedores

      this.encfacService.ecActualizarCorreoPro(this.txtCorreo1, this.txtCorreo2, strCodigo).subscribe();

    }

    this.displayDialogActualizacionFE = false;

  }


  //CDPJ

  //GSRF
  seleccionarNV(bolSeleccion) {
    console.log(bolSeleccion)
    console.log(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO)
    console.log(this.encptovtaService.detfac[this.indicador].length) 
    if (this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO ===''){
      if(this.encptovtaService.detfac[this.indicador].length === 0){
        this.checkok(bolSeleccion);
      }else{
        this.confirmationService.confirm({
          message: 'Seguro desea cambiar de tipo de documento?',
          header: 'Pregunta',
          icon: 'pi pi-exclamation-triangle',
          key: 'confirmarencptovta',
          accept: () => { // si cambia documento
            this.checkok(bolSeleccion);
            this.encptovtaService.detfac[this.indicador] = [];
          },
          reject: () => { 
            this.checkcancel();
          }
        });
      }
    }else{
      this.checkcancel();
    }
    
  }
  checkok(bolSeleccion){
    if (bolSeleccion) {
      console.log('press nv');
      this.chkFactura = false;
      this.chkNotaVenta = true;
      this.strTipoNum = "NV";
      this.encptovtaService.encfac[this.indicador].tipopv = 'NV';
    } else {
      this.chkFactura = true;
      this.chkNotaVenta = false;
      this.strTipoNum = "FAC";
       this.encptovtaService.encfac[this.indicador].tipopv = 'FAC';
       console.log('press fac');
    }
  }
  checkcancel(){
    if (this.strTipoNum ==="FAC") {
      this.chkFactura = true;
      this.chkNotaVenta = false;
      this.strTipoNum = "FAC";
       this.encptovtaService.encfac[this.indicador].tipopv = 'FAC';
       console.log('press fac');
    } 
    if (this.strTipoNum ==="NV") {
       console.log('press nv');
      this.chkFactura = false;
      this.chkNotaVenta = true;
      this.strTipoNum = "NV";
      this.encptovtaService.encfac[this.indicador].tipopv = 'NV';
    }
  }
  //GSRF

  calcularFechaVencimiento() {
    const dateTimeFecIni: Date = new Date(this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAEMISION);

    this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAVENCIMIENTO = this.obtenerFechaVence(dateTimeFecIni.getDate(),
      dateTimeFecIni.getMonth(), dateTimeFecIni.getFullYear());
  }

  obtenerFechaVence(dia, mes, anio): Date {
    let fecha1: Date;
    fecha1 = new Date(anio, mes, dia);

    fecha1.setDate(dia + (this.encptovtaService.encfac[this.indicador].ENCFAC_DIASCRDT === '' ? 0 :
      Number(this.encptovtaService.encfac[this.indicador].ENCFAC_DIASCRDT)));

    return fecha1;
  }

  async nuevoRegistro() {
    // Verifica los permisos
    if (this.permisoPtoVta !== null) {
      if (this.permisoPtoVta.PERTODO === 0) {
        if (this.permisoPtoVta.PERINSERCION === 0) {
          this.messageService.add({
            key: this.messageKey,
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario '
              + this.encptovtaService.usuario.identificacion
              + ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.'
          });
          return;
        }
      }
    }

    if (this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO.length > 0 && this.botonGuardar === true) {
      if (await this.verificarExisteFP() === false)
        return;
    }

    if (await this.verificarSaldoFacturaPagadas() === false) {
      return;
    }

    if (await this.ValidarSeriales(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO) === false) {
      return;
    }

    if (this.bolClave === true) {

      const NOPEDCLAV = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOPEDCLAV').CFG_VALOR1;
      if (NOPEDCLAV == 0) {
        const GENCLAAUT = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'GENCLAAUT').CFG_VALOR1;
        if (GENCLAAUT == 0) {

          this.claveAuth = '';
          this.autorizacionCodigo = 'PTOVTA';
          this.bolAutorizacion = false;
          this.displayDialogAutorizacion = true;

        } else {
          this.claveAuth = '';
          this.autorizacionCodigo = 'INCLVEPV';
          this.bolAutorizacion = false;
          this.displayDialogAutorizacion = true;

        }
      } else {
        this.nuevoRegistroSigue();
      }
    } else {
      this.nuevoRegistroSigue();
    }
  }

  async nuevoRegistroSigue() {
    this.limpiarDatos();
    console.log('vendedor1', this.strCodVendedor2);
    await this.colocarBodegaVendedor(this.strCodVendedor2);
    console.log(this.continuar);
    if (this.continuar === true) {
      this.bolvencodigo = false;
      this.listaEncFact = [];
      document.getElementById('contorno').style.pointerEvents = 'all';
      this.boolpago = false;//CDPJ
      this.aggrid.habilitarGrid();
      // cmbPorIva.setEnabled(false);
      const SELNVPV = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'SELNVPV').CFG_VALOR1;
      if (SELNVPV === 1) {
        this.chkNotaVenta = true;
        this.chkFacturasetEnabled = false;
        this.chkFactura = false;
        this.strTipoNum = "NV";
      }
      // habilitar/deshabilitar según configuración la caja de texto de la numeración del documento
      const PVDNROFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PVDNROFAC').CFG_VALOR1;
      if (PVDNROFAC === 1) {
        this.txtNumerosetEditable = false;
        //document.getElementById('txtNumero').setAttribute('disabled', 'true');
      }
      else {
        this.txtNumerosetEditable = true;
        //document.getElementById('txtNumero').setAttribute('disabled', 'false');
      }

      // obtener el número de la factura
      const NROPVGRB = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NROPVGRB').CFG_VALOR1;
      if (NROPVGRB == 0) // generar el número al inicio de la facturación
      { this.bolNuevo = await this.obtenerNumFact(); }
      else { this.bolNuevo = true; }

      if (this.bolNuevo === false) {
        console.log('entro despues');
        this.limpiarDatos();
        this.botonesmodificar();
        document.getElementById('contorno').style.pointerEvents = 'none';
        this.boolpago = true;//CDPJ
        this.aggrid.bloquearGrid();
        /*groupEnc.setEnabled(false);
        groupDet.setEnabled(false);*/

        /* cmpTipoDoc.setEnabled(false);
         mnuAcciones.setEnabled(false);
         mnuEditar.setEnabled(false);
         mnuVer.setEnabled(false);*/
        return;
      }

      let intTamanioLista = 0
      let strNumAut = '';
      //CDPJ
      const auth = await this.obtenerNumeracionCaja();
      if (auth.length > 0) {
        const itemNum = auth[0];
        if (itemNum.NUM_AUTORIZACION !== null || itemNum.NUM_AUTORIZACION !== undefined || itemNum.NUM_AUTORIZACION !== '') {
          strNumAut = itemNum.NUM_AUTORIZACION;
          this.encptovtaService.encfac[this.indicador].ENCFAC_AUTORIZACION = strNumAut;
        }
        else {
          this.messageService.add({
            key: this.messageKey,
            severity: 'error',
            summary: 'Información',
            detail: 'No existe autorización registrada en la numeración de puntos de venta'
              + 'Consulte con el Administrador del Sistema.'
          });
          return;
        }
      }

      //CDPJ

      // colocar el estado de la factura a pendiente
      this.colocarEstadoFactura("P");

      // obtener el porcentaje del iva YA SE OBTUVO EN EL PROCESO DE LLENAR COMBO
      // this.dblPorcIva = await this.obtenerPorcIva();
      // for (const i of this.cmbiva) {
      //   if (Number(i.name) === this.dblPorcIva) {
      //     this.selectiva = i;
      //   }
      // }

      // colocar la bodega y el vendedor

      /*
          lblNumFact.setVisible(true);
          lblEstado.setVisible(true);
          lblBodega.setVisible(true);
          mnuAcciones.setEnabled(true);
          mnuEditar.setEnabled(true);
          mnuVer.setEnabled(true);*/
      this.txtReferenciasetEditable = false;
      this.btnReferenciasetEnabled = false;


      const INGTOUCH = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INGTOUCH').CFG_VALOR1;
      // colocar el consumidor final
      if (INGTOUCH === 0) {
        this.encptovtaService.encfac[this.indicador].CLI_CODIGO = this.strCodConsFinal;
        this.buscarCliente(true);
      } else {
        document.getElementById('txtRefCliente').focus();
      }

      this.botonesmodificar();
      /*mnuAgregarPed.setEnabled(true);
      mnuActCambioArt.setEnabled(true);
      mnuImpNV.setEnabled(true);		
   
      cmbListas.setEnabled(true);*/

      const CONCALCAN = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CONCALCAN').CFG_VALOR1;
      if (CONCALCAN === 1) {
        this.buscarCliente(false);
      }
      const intFCPRINIVA = this.encptovtaService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PVPREAIVA').CFG_VALOR1;
      if(intFCPRINIVA === 1){
        this.encptovtaService.encfac[this.indicador].ENCFAC_IVA='2';
      }else{
        this.encptovtaService.encfac[this.indicador].ENCFAC_IVA='1';
      }
    } else {
      console.log('entro else');
      this.limpiarDatos();
      this.botonesinicio();
      //this.autorizacionCodigo = 'PTOVTA';
      this.bolClave = true;
      document.getElementById('contorno').style.pointerEvents = 'none';
      this.boolpago = true;//CDPJ
      this.aggrid.bloquearGrid();
    }

  }

  async cargarEncDatosArticulo(item) {
    // còdigo y nombre del artículo
    if (item.DETFAC_TIPODET !== "*") {
      this.lblCodArt = item.DETFAC_CODIGO + "\t" + item.DETFAC_DESCRIPCION;
    }
    else {
      this.lblCodArt = '';
    }

    // unidad    
    this.lblUnidad = item.DETFAC_UNIDAD;
    // cantidad facturada
    this.lblCantidad = Number(item.DETFAC_CANTIDAD).toFixed(this.NUMDECCANTIDAD);    // descuento en línea del artículo
    this.lblDsctoArt = Number(item.DETFAC_DESCUENTO).toFixed(this.NUMDECSIST);

    // precio de venta al público
    let dblPrecio = Number(item.DETFAC_TOTAL) + Number(item.DETFAC_IVA) + Number(item.DETFAC_ICE);

    console.log(item.DETFAC_TOTAL);
    console.log(item.DETFAC_IVA);
    console.log(item.DETFAC_ICE);
    if (item.DETFAC_CANTIDAD !== 0) {
      console.log(dblPrecio, item.DETFAC_CANTIDAD)
      dblPrecio = dblPrecio / Number(item.DETFAC_CANTIDAD);
    }
    this.lblPVP = dblPrecio.toFixed(this.NUMDECPRECIO);

    // existencia del artículo seleccionado
    if (item.DETFAC_CODIGO.length > 0) {
      let dblExistencia = await this.obtenerExistenciaArticulo(item.DETFAC_CODIGO, item.BOD_CODIGO, "");
      let strUnidadCosteo = await this.obtenerUnidadCosteoArticulo(item.DETFAC_CODIGO);
      this.lblExistencia = Number(dblExistencia).toFixed(this.NUMDECSIST) + " " + strUnidadCosteo;
    } else
      this.lblExistencia = Number("0").toFixed(this.NUMDECSIST);

  }

  async existenciaArtSinCantActual(strArtCodigo, strBodCodigo,
    strReferencia, strFecha, strHora) {
    console.log(strArtCodigo, strBodCodigo,
      strReferencia, strFecha, strHora);
    let dblCantPartesReserv = 0;
    let dblCantKardex = 0;

    const data1: any = await this.encptovtaService.canpartesreservadas(strArtCodigo, strBodCodigo, strFecha, strHora);
    //		Extrae las partes incluídas en un artículo compuesto
    for (const rs1 of data1) {
      dblCantPartesReserv = rs1.EXISTENCIA;
    }
    const data2: any = await this.encptovtaService.ecpGetCantArticulo(strArtCodigo, strBodCodigo, strReferencia);
    //		extrae la cantidad del artículo en el kardex

    for (const rs2 of data2) {
      dblCantKardex = rs2.CANTIDAD;
    }

    return Number(dblCantPartesReserv) + Number(dblCantKardex);
  }

  async obtenerExistenciaArticulo(strArtCodigo, strBodCodigo, strReferencia) {
    let dblExistencia = "0";
    let dblCantUnidadCosteo = 0;
    let strFecha = this.datePipe.transform(this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
    let strHora = this.datePipe.transform(this.encptovtaService.encfac[this.indicador].ENCFAC_HORAEMISION, 'hh:mm');
    // Cantidad de artículos existentes en el detalle de la factura
    let dblArtDet = await this.existenciaArtSinCantActual(
      strArtCodigo, strBodCodigo, strReferencia, strFecha,
      strHora);
    for (const item of this.encptovtaService.detfac[this.indicador]) {

      let dblCantUnidad = 0;

      if (item.DETFAC_CODIGO != null) {
        if (item.DETFAC_CODIGO === strArtCodigo
          && item.BOD_CODIGO === strBodCodigo) {
          dblCantUnidad = await this.cantArtDetFacEnUniCosteo(strArtCodigo, item.DETFAC_UNIDAD, item.DETFAC_CANTIDAD);
        }
      }

      dblCantUnidadCosteo = dblCantUnidadCosteo + dblCantUnidad;
    }

    dblExistencia = (Number(dblArtDet) - dblCantUnidadCosteo).toFixed(this.NUMDECSIST)

    return dblExistencia;
  }

  async obtenerUnidadCosteoArticulo(strArtCodigo) {
    let strUnidad = "";
    const data = await this.encptovtaService.ecpGetCantArtCosteo(strArtCodigo);

    for (const rs of data) {
      strUnidad = rs.UNIDAD;
    }

    return strUnidad;
  }


  async cantArtDetFacEnUniCosteo(strArtCodigo, strUnidad, dblCantidad) {
    let dblCant = 0;

    let strUnidadCosteo = "";

    // obtener la unidad de costeo del artículo
    const data: any = await this.encptovtaService.getunidadcosteo(strArtCodigo);
    if (data !== null) {
      for (const rs of data) {
        if (rs.ART_UNIDADCOSTEO !== null) {
          strUnidadCosteo = rs.ART_UNIDADCOSTEO
        }
      }
    }

    // obtener la cantidad del artículo según la unidad de costeo
    if (strUnidad !== strUnidadCosteo) {
      const data2: any = await this.encptovtaService.transformaunidad(strUnidad, strUnidadCosteo, dblCantidad);
      for (const rs of data2) {
        dblCant = Number(rs.EXISTENCIA)
      }
    }
    else
      dblCant = dblCantidad;

    return dblCant;
  }



  async verificarExisteFP() {
    let bolExiste = true;
    // verificar si el usuario que está conectado es el mismo que hizo la factura
    let strUsuarioFact = "";

    const data = await this.encptovtaService.getUsuarioFactura(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);
    for (const rs of data) {
      strUsuarioFact = rs.USU_IDENTIFICACION;
    }

    if (strUsuarioFact !== this.usuario.identificacion)
      return bolExiste;

    if (this.strFormaPago.length == 0)
      await this.obtenerFormaPagoFact();

    if (this.strFormaPago.length == 0
      && this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO !== "ANULADA"
      && this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO !== "CAMBIO") {

      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Información',
        detail: "La factura no tiene una forma" + " de pago"
      });
      bolExiste = false;
    }

    // verificar que la factura de consumidor final no quede pendiente según
    // la configuración
    if (bolExiste) {
      const FACCONF = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FACCONF').CFG_VALOR1;
      if (FACCONF == 0) {
        if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO === "PENDIENTE"
          && this.encptovtaService.encfac[this.indicador].CLI_CODIGO === this.strCodConsFinal) {
          this.messageService.add({
            key: this.messageKey,
            severity: 'error',
            summary: 'Información',
            detail: "La factura debe ser pagada en su totalidad, "
              + "el Cliente es Consumidor final"
          });
          bolExiste = false;
        }
      }
    }
    return bolExiste;
  }

  async verificarSaldoFacturaPagadas() {
    let dblSaldoFactura = 0;
    // Si esta pagada
    if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO === "PAGADA") {
      // Verifica que tenga saldo cero
      dblSaldoFactura = await this.obtenerSaldoFactura(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);

      if (dblSaldoFactura > 0) // si tiene saldo
      {
        this.messageService.add({
          key: this.messageKey,
          severity: 'error',
          summary: 'Información',
          detail: "La factura tiene estado pagada pero no tiene registrado la cancelación, por favor consulte con el administrador del Sistema"
        });
        return false;
      } else
        return true;
    } else
      return true;
  }

  async ValidarSeriales(strNumDoc) {
    let articulo;
    let cantidad = 0;
    let artserialflag = "", guiaremision = "";
    let linea = 0;


    if (strNumDoc === "") {
      return true;
    }


    if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO === "PAGADA"
      || this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO === "ANULADA") {
      return true;
    }


    for (const item of this.encptovtaService.detfac[this.indicador]) {
      articulo = item.DETFAC_CODIGO;
      cantidad = item.DETFAC_CANTIDAD;
      linea = item.DETFAC_LINEA;

      const d1 = await this.encptovtaService.getArtSerialFlag(articulo);
      for (const rs of d1) {
        artserialflag = rs.ART_SERIALFLAG;
      }

      const d2 = await this.encptovtaService.getGuiaRemision(strNumDoc, articulo);
      for (const rs of d2) {
        guiaremision = rs.ENCGRE_NUMERO;
      }

      if (artserialflag === "S" && guiaremision === null) {
        const d3 = await this.encptovtaService.contarSeriales(articulo, strNumDoc, linea)

        for (const rs of d3) {
          if (Number(rs.CONT) !== Number(cantidad)) {
            this.messageService.add({
              key: this.messageKey,
              severity: 'error',
              summary: 'Información',
              detail: "Ingrese los seriales."
            });
            return false;
          }
        }
      }
    }

    return true;
  }

  async obtenerFormaPagoFact() {
    const data = await this.encptovtaService.getFormaPago(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);

    for (const rs of data) {
      console.log(rs);
      this.strFormaPago = rs.ENCFAC_FORMAPAGO === null ? '' : rs.ENCFAC_FORMAPAGO;
    }
  }

  async obtenerSaldoFactura(strNumFact) {
    let dblSaldo = 0;

    const data = await this.encptovtaService.getSaldoFactura(strNumFact);

    for (const rs of data) {
      dblSaldo = rs.SALDO;
    }

    return dblSaldo;
  }


  comprobarClave() {
    if (this.autorizacionCodigo !== 'PTOVTA') {
      this.encptovtaService.obtenerClave(this.autorizacionCodigo, '').subscribe((clave) => {
        let claveReal;
        claveReal = clave[0].AUTORIZACION_CLAVE;
        if (claveReal === this.claveAuth) {
          this.accionesAutorizacion();
        } else if (clave !== this.claveAuth) {
          this.messageService.add({
            key: 'autorizacion',
            severity: 'error',
            summary: 'Clave Autorización',
            detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
          });
        }
      });
    } else {
      this.encptovtaService.obtenerClave(this.autorizacionCodigo, this.claveAuth).subscribe((clave) => {
        this.intNumReg = 0
        for (const rs of clave) {
          if (rs.CONT == 1)
            this.intNumReg = 1;
        }
        this.strCodVendedor2 = this.claveAuth;

        if (this.intNumReg == 0) {
          this.messageService.add({
            key: 'autorizacion',
            severity: 'error',
            summary: 'Información',
            detail: 'La clave está incorrecta...'
          });
          this.claveAuth = '';
        } else {
          this.accionesAutorizacion();
          this.displayDialogAutorizacion = false;
        }
      });
    }
  }

  accionesAutorizacion() {
    this.bolAutorizacion = true;
    if (this.autorizacionCodigo === 'PTOVTA') {
      if (this.intNumReg == 0) {
        this.messageService.add({
          key: 'autorizacion',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese la clave del vendedor.'
        });
        return;
      } else {

        this.bolClave = false;
        this.nuevoRegistroSigue();
      }
    }
    if (this.autorizacionCodigo === 'INCLVEPV') {
      this.bolClave = false;
      this.nuevoRegistroSigue();
    }
  }
  //CDPJ


  //CDPJ
  onHideAutorizacion() {
    if (this.bolAutorizacion === false) {
      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Información',
        detail: 'Debe ingresar correctamente'
          + ' la clave para la Facturación.'
      });
    }
  }

  async obtenerNumeracionCaja() {
    let listaNumCaja = [];

    const cadena = "COM_codigo = '01'  AND (NUM_docref = '" + this.strTipoNum
      + "') AND NUM_clase = '" + this.usuario.identificacion
      + "' AND NUM_tipo is null";

    const data = await this.encptovtaService.consultarNumeracion(cadena);

    for (const rs of data) {
      const item: SaciMaeNumeracion = {};

      item.NUM_CLASE = rs.NUM_CLASE;
      item.NUM_NEMONICO = rs.NUM_NEMONICO;
      item.NUM_SERIE = rs.NUM_SERIE;
      item.NUM_SECACTUAL = rs.NUM_SECACTUAL;
      item.NUM_SECFINAL = rs.NUM_SECFINAL;
      item.NUM_DOCREF = rs.NUM_DOCREF;
      item.NUM_AUTORIZACION = rs.NUM_AUTORIZACION;
      if (rs.NUM_FECHACAD != null)
        item.NUM_FECHACAD = rs.NUM_FECHACAD

      listaNumCaja.push(item);
    }

    return listaNumCaja;
  }

  async obtenerNumFact() {
    let bolNum = false;
    let intTamanioLista = 0;
    let intNumFinal = 0;
    let intNumActual = 0;
    let strNumActual = "";
    let strNumAut = "";
    let strFechaCad = null;

    if (this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO == 0) {

      const listaNumCaja = await this.obtenerNumeracionCaja();
      if (listaNumCaja.length > 0) {
        const itemNum = listaNumCaja[0];
        intTamanioLista = itemNum.NUM_SECACTUAL.length > 0 ? 1 : 0;
        if (intTamanioLista > 0) {
          intNumActual = Number(itemNum.NUM_SECACTUAL.trim());
          intNumFinal = Number(itemNum.NUM_SECFINAL.trim());
          strNumActual = itemNum.NUM_SECACTUAL.trim();
          this.strNemonico = itemNum.NUM_NEMONICO.trim();
          this.strSerie = itemNum.NUM_SERIE.trim();
          strNumAut = itemNum.NUM_AUTORIZACION;
          strFechaCad = new Date(itemNum.NUM_FECHACAD);
        }
      }
    }

    this.intTamNumFact = strNumActual.length; // tamaño del número secuencial de la factura

    this.strNumDoc = this.strSerie + strNumActual;

    // fecha de caducidad
    if (strFechaCad != null && strFechaCad.length > 0) {
      console.log(strFechaCad, '<', this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAEMISION);
      if (strFechaCad < this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAEMISION) {
        this.messageService.add({
          key: this.messageKey,
          severity: 'error',
          summary: 'Información',
          detail: "La numeración de la factura está caducada"
        });
        this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO = '';

        return bolNum = false;
      }
    }

    // número de autorización
    if (strNumAut != null)
      this.encptovtaService.encfac[this.indicador].ENCFAC_AUTORIZACION = strNumAut;

    if (intTamanioLista == 0) {
      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Información',
        detail: "No existe una numeración para la Factura. "
          + "Consulte con el Administrador."
      });
    } else if (intNumActual >= intNumFinal) {
      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Información',
        detail: "Se terminó el secuencial de la Factura."
      });
    } else {
      document.getElementById('txtCodCliente').focus();
      bolNum = true;
      this.encptovtaService.encfac[this.indicador].TXT_NUMERO = this.strNumDoc;

    }

    return bolNum;
  }

  async colocarEstadoFactura(strEstado) {
    const data = await this.encptovtaService.encontrarEstadoReferencia(strEstado);

    for (const rs of data) {
      const strNombreEstado = rs.REF_NOMBREC;
      if (strNombreEstado.length > 0)
        this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO = strNombreEstado;
      else if (strEstado === "P")
        this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO = "PENDIENTE";
      else if (strEstado === "G")
        this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO = "PAGADA";
    }

  }

  async obtenerPorcIva() {
    let dblPorcIva = 0;

    const data = await this.encptovtaService.encontrarPorcentajeIva();

    for (const rs of data) {
      const strPorcIva = rs.IMP_PORCENTAJE;
      if (String(strPorcIva).length > 0)
        dblPorcIva = Number(strPorcIva);
    }
    return dblPorcIva;
  }

  async colocarBodegaVendedor(strVenClave) {

    const data = await this.encptovtaService.getdatosvendedor(strVenClave)

    for (const rs of data) {

      if (this.bolImportarNV === false) {
        if (rs.BOD_CODIGO === null || rs.BOD_CODIGO === undefined || rs.BOD_CODIGO === '') {
          console.log('e1', this.continuar);
          this.continuar = false;
          console.log('e2', this.continuar);
          const clave = await this.encptovtaService.getdatosBodUsuario(this.usuario.identificacion)
          //console.log('e3', this.continuar);
          for (const er of clave) {
            console.log('e4', this.continuar);
            console.log('bodega usuario', er);
            if (er.BOD_CODIGO === null || er.BOD_CODIGO === undefined || er.BOD_CODIGO === '') {
              this.messageService.add({
                key: this.messageKey,
                severity: 'error',
                summary: 'Información',
                detail: "Ingrese una bodega en el maestro del vendedor."
              });
              //console.log('e5', this.continuar);

              this.continuar = false;
              console.log('e6', this.continuar);
              return;
            } else {
              console.log('1', this.continuar);

              this.continuar = true;
              //console.log('1.1', this.continuar);
              this.encptovtaService.encfac[this.indicador].BOD_CODIGO = er.BOD_CODIGO;
              this.strCodBodega = this.encptovtaService.encfac[this.indicador].BOD_CODIGO;
              this.encptovtaService.encfac[this.indicador].VEN_CODIGO = rs.VEN_CODIGO;
              this.encptovtaService.encfac[this.indicador].VEN_NOMBRE = rs.VEN_NOMBRE;
              this.cargarListasPrecios();
              this.bolvencodigo = false;
              this.ven_codigo = this.encptovtaService.encfac[this.indicador].VEN_CODIGO
            }
          }


        } else {
          console.log('1', this.continuar);
          this.continuar = true;
          console.log('1.2', this.continuar);
          this.encptovtaService.encfac[this.indicador].BOD_CODIGO = rs.BOD_CODIGO;
          this.strCodBodega = this.encptovtaService.encfac[this.indicador].BOD_CODIGO;
          this.encptovtaService.encfac[this.indicador].VEN_CODIGO = rs.VEN_CODIGO;
          this.encptovtaService.encfac[this.indicador].VEN_NOMBRE = rs.VEN_NOMBRE;
          this.cargarListasPrecios();
          this.bolvencodigo = false;
          this.ven_codigo = this.encptovtaService.encfac[this.indicador].VEN_CODIGO
        }

      }

    }
    console.log('final', this.continuar);
  }

  async cargarListasPrecios() {
    const LSTPXVEN = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'LSTPXVEN').CFG_VALOR1;
    if (LSTPXVEN === 1) {
      const listaPrecios = await this.obtenerListaPrecVendedor(this.encptovtaService.encfac[this.indicador].VEN_CODIGO);
      this.cmblista = [];
      for (const lp of listaPrecios) {
        this.cmblista.push({ codigo: lp, name: lp });
      }

      if (this.cmblista.length > 0) {
        this.selectlista = this.cmblista[0];
      }
    } else {

      this.encfacService.obtenerListasPrecios().subscribe((olp) => {
        this.cmblista = [];
        for (const lp of olp) {
          this.cmblista.push({ codigo: lp.ARTPRE_CODIGO, name: lp.ARTPRE_CODIGO });
        }

        if (this.cmblista.length > 0) {
          this.selectlista = this.cmblista[0];
        }
      });
    }
  }

  async obtenerListaPrecVendedor(strVenCodigo) {
    let listaPrecios = [];

    const data = await this.encptovtaService.getlistapreciosVendedor(strVenCodigo);

    for (const rs of data) {
      listaPrecios.push(rs.ARTPRE_CODIGO);
    }
    return listaPrecios;
  }

  limpiarReferencia() {
    this.encptovtaService.encfac[this.indicador].ENCFAC_REFERENCIA = '';
    this.encptovtaService.detfac[this.indicador] = [];
    this.encptovtaService.calcularTotalesFact();
  }

  obtenerFechaHoraDoc() {
    this.strFecha = this.datePipe.transform(this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
    // this.strFecha = this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAEMISION;
    this.strHora = this.datePipe.transform(this.encptovtaService.encfac[this.indicador].ENCFAC_HORAEMISION, 'hh:mm');
  }

  async buscarCliente(bolNuevoDoc) {
    let strNombreCliente = "";
    let strplaca = "";
    let strCliLimCredit = "";
    let strValorCajTexto = this.encptovtaService.encfac[this.indicador].CLI_CODIGO;
    let strCodigoGrupo = "";
    this.txtRuc = (<HTMLInputElement>document.getElementById("txtCodCliente")).value

    if (this.encptovtaService.encfac[this.indicador].ENCFAC_REFERENCIA === undefined
      || this.encptovtaService.encfac[this.indicador].ENCFAC_REFERENCIA === null) {
      this.encptovtaService.encfac[this.indicador].ENCFAC_REFERENCIA = '';
    }

    if (this.encptovtaService.encfac[this.indicador].ENCFAC_REFERENCIA.length > 0) {
      this.limpiarReferencia();
    }


    this.obtenerFechaHoraDoc();

    this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAVENCIMIENTO = new Date();

    //if (strValorCajTexto.length > 0) { // si ingreso de datos en la caja de texto
    //console.log('caja', strValorCajTexto);
    //this.buscarClienteDlg(strNombreCliente, strValorCajTexto);
    //     const data = await this.encptovtaService.encontrardatoscli(strValorCajTexto);
    // if (data!== null )
    //     for (const strDatos of data) {
    //       strplaca = strDatos.CLI_PLACA;
    //       strNombreCliente = strDatos.CLI_NOMBRE;
    //       strCliLimCredit = strDatos.CLI_LIMCREDIT;
    //       strCodigoGrupo = strDatos.GRU_CODIGO;
    //     }

    //     if (strNombreCliente === "") {
    //       if (strValorCajTexto.length === 10) {
    //         if (strNombreCliente !== "") {
    //           strValorCajTexto = strValorCajTexto + "001";
    //           this.encptovtaService.encfac[this.indicador].CLI_CODIGO = strValorCajTexto;
    //         }
    //       }
    //     }

    //}

    const PVDNROFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PVDNROFAC').CFG_VALOR1;
    if (PVDNROFAC === 1 || bolNuevoDoc === false) {
      console.log('entro a buscar')
      this.buscarClienteDlg(strNombreCliente, strValorCajTexto);
    } else { document.getElementById('txtCodCliente').focus(); }
    const INGESPFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INGESPFAC').CFG_VALOR1;
    if (INGESPFAC === 1) {
      document.getElementById('txtRefCliente').focus();
    }
    if (this.VerificaConsumidorFinal(strCodigoGrupo) === true) {
      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Información',
        detail: "No puede facturar a un consumidor final"
      });
      //this.encptovtaService.encfac[this.indicador].CLI_CODIGO = '';
      //this.encptovtaService.encfac[this.indicador].CLI_NOMBRE = '';

      return;
    }
  }


  VerificaConsumidorFinal(strCodigoGrupo): boolean {

    const NOFACCF = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOFACCF').CFG_VALOR1;
    if (NOFACCF == 0)
      return false;

    const GRUPOCF = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'GRUPOCF').CFG_VALOR2;
    if (GRUPOCF === strCodigoGrupo)
      return true;
    else
      return false;

  }

  botoncliente() {
    //this.encptovtaService.encfac[this.indicador].CLI_CODIGO = '';
    //this.encptovtaService.encfac[this.indicador].CLI_NOMBRE = '';

    //this.buscarCliente(false);
    //const INGESPFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INGESPFAC').CFG_VALOR1;
    const PARESCLI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PARESCLI').CFG_VALOR1;
    this.txtRuc = (<HTMLInputElement>document.getElementById("txtCodCliente")).value
    //console.log(INGESPFAC, PARESCLI);
    if (PARESCLI == 0) {
      this.buscarCliente(false);
    }
    else {
      //this.encptovtaService.encfac[this.indicador].CLI_CODIGO = '';
      this.datosNuevoCliente();
      document.getElementById('txtRefCliente').focus();
    }
    this.desplegarVendedor();
    this.cargarListasPrecios();
  }

  buscarClienteDlg(strNombreCliente, strValorCajTexto) {

    // groupDet.setEnabled(true);    

    //if (strNombreCliente.length === 0) { // si el valor que ingreso esta incorrecto

    // const INGESPFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INGESPFAC').CFG_VALOR1;
    // const PARESCLI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PARESCLI').CFG_VALOR1;
    // console.log(INGESPFAC, PARESCLI);
    // if (INGESPFAC === 0 && PARESCLI == 0) {

    const BUSSIMCLI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'BUSSIMCLI').CFG_VALOR1;

    this.strNombreCliente = strNombreCliente;
    this.strValorCajTexto = strValorCajTexto;

    if (BUSSIMCLI === 1) {
      this.busquedaclienteplaca(this.encptovtaService.encfac[this.indicador].CLI_CODIGO);
    } else {
      this.busquedacliente(this.encptovtaService.encfac[this.indicador].CLI_CODIGO);
    }

    // } else {
    //   //this.encptovtaService.encfac[this.indicador].CLI_CODIGO = '';
    //   this.datosNuevoCliente();
    //   document.getElementById('txtRefCliente').focus();
    // }

    // } else // si el valor que fue ingresado es correcto lo despliega

    //   this.encptovtaService.encfac[this.indicador].CLI_NOMBRE = strNombreCliente;

  }

  async  buscarClienteDlg2(opcion) {
    let strTipoCli = "";

    if (opcion.CLI_CODIGO != null) {
      this.encptovtaService.encfac[this.indicador].CLI_NOMBRE = opcion.CLI_NOMBRE;
      this.encptovtaService.encfac[this.indicador].CLI_CODIGO = opcion.CLI_CODIGO;
    }

    const BUSSIMCLI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'BUSSIMCLI').CFG_VALOR1;
    if (BUSSIMCLI == 1) {
      let strplaca = opcion.CLI_PLACA;
      this.encptovtaService.encfac[this.indicador].ENCFAC_PLACA_CLI = strplaca;
    }

    if (this.encptovtaService.encfac[this.indicador].CLI_CODIGO.length > 0) {
      const CNTRLESCLI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CNTRLESCLI').CFG_VALOR1;
      if (CNTRLESCLI == 1) { // dejar facturar al cliente si está desactivado    
        if (await this.controlarEstadoCliente() == false)
          return;
      }

      // verificar si existen notas de débito por cheques protestados
      this.verificarNDXCliente();

      // controlar la facturación de acuerdo al tipo de cliente dependiendo de la configuración
      const FACTIPCLI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FACTIPCLI').CFG_VALOR1;
      if (FACTIPCLI == 1) {

        const data: any = await this.encptovtaService.encontrarTipoCli(this.encptovtaService.encfac[this.indicador].CLI_CODIGO);

        for (const rs of data) {
          console.log('entro')
          strTipoCli = rs.CLI_TIPO;
        }
        this.strTipoCliD = strTipoCli//CDPJ
        if (strTipoCli === "2") {
          this.messageService.add({
            key: this.messageKey,
            severity: 'error',
            summary: 'Información',
            detail: "El  Cliente está Bloqueado."
          });
          this.aggrid.bloquearGrid();
          // groupDet.setEnabled(false);
          return;
        }
      }
    }

    if (this.encptovtaService.encfac[this.indicador].CLI_CODIGO.length > 0)
      this.completarDatosCliente(this.encptovtaService.encfac[this.indicador].CLI_CODIGO); // información adicional del cliente tomando en cuenta la configuración

    if (this.encptovtaService.encfac[this.indicador].CLI_CODIGO.length > 0) {
      if (this.txtReferenciasetEditable === false) {
        if (this.encptovtaService.detfac[this.indicador].length == 0)
          this.nuevaLinea();
        const PVCURLD = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PVCURLD').CFG_VALOR1;
        if (PVCURLD == 1) { // colocar el cursor en la línea del detalle al crear una factura             
          /*tablaFactura.setSelection(1, 1, true);
          tablaFactura.openEditorInFocus();*/
        } else
          document.getElementById('txtRefCliente').focus();

      } else {
        if (this.txtReferenciasetEditable) {
          document.getElementById('txtReferencia').focus();
        }
      }

      // verificar si se puede cambiar el vendedor con clave entonces habilito el campo
      if (await this.verificarPermisosObjConClave("frmVEN_ENCPTOVTA", "TxtVENcodigo", 0)) // si puede cambiar el vendedor con clave
      {
        this.txtCodVendedorsetEditable = true;
        this.btnVendedorsetEnabled = true;
      }

    } else
      document.getElementById('txtCodCliente').focus();



  }

  async  verificarPermisosObjConClave(strDialogo,
    strObjeto, intPestania) {
    let bolVerificar = false;

    const data: any = await this.encptovtaService.verificarpermobjclave(strDialogo,
      strObjeto, intPestania, this.encptovtaService.usuario.identificacion);

    for (const rsObjDlg of data) {
      if (rsObjDlg.CONT > 0)
        bolVerificar = true;
    }
    return bolVerificar;
  }

  nuevaLinea() {
    if (this.permisoPtoVta !== null) {
      if (this.permisoPtoVta.PERTODO === 0) {
        if (this.permisoPtoVta.PERINSERCION === 0) {
          this.messageService.add({
            key: this.messageKey,
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario '
              + this.encptovtaService.usuario.identificacion
              + ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.'
          });
          return;
        }
      }
    }

    if (this.encptovtaService.encfac[this.indicador].CLI_CODIGO.length == 0) {
      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese el cliente.'
      });
      document.getElementById('txtCodCliente').focus();
      return;
    }

    this.nuevaLineaDetalle("A");
		/*btnCancelar.setEnabled(true);
		toolBarDetalle.getItem(1).setEnabled(true);
		mnuElimLinDet.setEnabled(true);
		toolBarDetalle.getItem(2).setEnabled(true);
		if (!bolNuevo)
			activarBotMod();
*/
    const CONCALCAN = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CONCALCAN').CFG_VALOR1;
    if (CONCALCAN == 1) {
      /*
			UtilidadesVen.dblCantidadVenderPV = 0;
			Utilidades.glb_str_codigo = "";
			DlgCalculaCantidaVen DlgCalculaCant = new DlgCalculaCantidaVen(
					shell, cmbListas.getText(), obtenerPorcIvaSele());
			DlgCalculaCant.open();

			if (modeloFactura.getListaDetFact().size() == 0)
				return;
			System.out.println("aaaaaa");
			if (Utilidades.glb_str_codigo.compareTo("") != 0) {
				System.out.println("bbbbbb");
				VenDetFac item = modeloFactura.getListaDetFact().get(
						tablaFactura.getCellSelection()[0].y - 1);
				System.out.println("ccccccccc");
				item.setDetFacCodigo(Utilidades.glb_str_codigo);
				if (listaPrecios.size() > 0) {
					cmbListas.setText(listaPrecios.get(0));
					cmbListas.setData(listaPrecios.get(0));
				}
				System.out.println("Utilidades.glb_str_codigo    "
						+ Utilidades.glb_str_codigo);
				modeloFactura.insertarElemento();
				System.out.println("ddddd");
				item.setDetFacCantidad(UtilidadesVen.dblCantidadVenderPV);
				modeloFactura.calcularTotalesFact();
				calcularDsctoGlobal(DlgDescuento.glbBolEsValor, dblValorNeto);
				tablaFactura.redraw();
			}

			if (hmpConfiguracion.get("FORPAGAUT") == 1) {
				if (MessageDialog.openQuestion(shell, "Pregunta",
						"Está seguro de guardar la factura ?")) {
					guardarRegistro();
				}
			}*/
    }
  }

  datosNuevoCliente() {
    if (this.strCodConsFinal === this.txtRuc) {
      this.txtRuc = ''
    } else {
      this.txtRuc = (<HTMLInputElement>document.getElementById("txtCodCliente")).value
    }
    this.displayDialogNuevoCliente = true;
  }

  nuevaLineaDetalle(strTipoDetalle) {
		/*if (this.intNumItems > 0) {
			this.intNumItemsDisp = this.intNumItems - (this.getRowCount() - 1);
			if (controlNumItems() == false) {
				dlgPtoVta.toolBarDetalle.getItem(0).setEnabled(false);
				dlgPtoVta.mnuNuevaLinDet.setEnabled(false);
				return;
			} else {
				dlgPtoVta.toolBarDetalle.getItem(0).setEnabled(true);
				dlgPtoVta.mnuNuevaLinDet.setEnabled(true);
			}
		}*/

    const NUMITEMPV = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMITEMPV').CFG_VALOR2;
    console.log('maximo', NUMITEMPV);
    // actualizar número de items disponibles
    if (NUMITEMPV !== null && NUMITEMPV !== undefined && NUMITEMPV > 0) {
      console.log('maximo', NUMITEMPV);

      if (this.encptovtaService.encfac[this.indicador].ENCFAC_ITEMSDISPONIBLES > 0) {
        const item = this.crearNuevoItem(strTipoDetalle);
        this.encptovtaService.detfac[this.indicador].push(item);
        this.encptovtaService.encfac[this.indicador].ENCFAC_ITEMSDISPONIBLES = Number(this.encptovtaService.encfac[this.indicador].ENCFAC_ITEMSDISPONIBLES) - 1;
      } else {
        this.messageService.add({
          key: this.messageKey,
          severity: 'error',
          summary: 'Información',
          detail: 'El número de items disponibles es cero'
        });
        return;
      }
    } else {
      const item = this.crearNuevoItem(strTipoDetalle);
      this.encptovtaService.detfac[this.indicador].push(item);
    }
		/*if (this.encptovtaService.detfac[this.indicador].length > 0
				&& dlgPtoVta.getTablaFactura().getCellSelection().length > 0) {
			if (dlgPtoVta.getTablaFactura().getCellSelection()[0].y > 0
					&& dlgPtoVta.getTablaFactura().getCellSelection()[0].y <= listaDetFact.size()) {
				if (dlgPtoVta.getTablaFactura().getCellSelection()[0].y < listaDetFact
						.size()) {
					if (listaDetFact.get(dlgPtoVta.getTablaFactura().getCellSelection()[0].y)
							.getDetFacTipoDet().equals("Y")) {
						dlgPtoVta.getTablaFactura().setSelection(
										dlgPtoVta.getTablaFactura().getCellSelection()[0].x,
										dlgPtoVta.getTablaFactura().getCellSelection()[0].y + 1,	true);
						this.nuevaLineaDetalle(strTipoDetalle);
						return;
					}
				}
				filaNueva = dlgPtoVta.getTablaFactura().getCellSelection()[0].y;
			}
		} else
			filaNueva = this.encptovtaService.detfac[this.indicador].length;
    */
    // LINEA
    //item.DETFAC_LINEA = 0;



    this.aggrid.refreshaggrid(this.encptovtaService.detfac[this.indicador], this.defaultColDef.objeto);
    /*
        colorearFilasEspeciales();
        dlgPtoVta.getTablaFactura().redraw();
        dlgPtoVta.getTablaFactura().setSelection(1, filaNueva + 1, true);
        dlgPtoVta.getTablaFactura().openEditorInFocus();
        dlgPtoVta.toolBarDetalle.getItem(0).setEnabled(false); // nuevo detalle
        dlgPtoVta.mnuNuevaLinDet.setEnabled(false);*/
  }

  crearNuevoItem(strTipoDetalle): any {

    const item: VenDetfacZ = {};
    console.log('valor', this.selectlista.codigo);
    item.CLAVE = this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
    item.BOD_CODIGO = this.strCodBodega;
    item.CEN_CODIGO = "";
    item.COM_CODIGO = '01';
    item.DETCOT_LINEA = 0;
    item.DETFAC_ARTACAMBIO = "";
    item.DETFAC_BASECERO = 0;
    item.DETFAC_BASEICE = 0;
    item.DETFAC_BASEIVA = 0;
    item.DETFAC_CANTIDAD = 0;
    item.DETFAC_CODIGO = "";
    item.DETFAC_CODIGOREF = "";
    item.DETFAC_CONCODCSTVTA = "";
    item.DETFAC_CONCODINV = "";
    item.DETFAC_DESCRIPCION = "";
    item.DETFAC_DESCUENTO = 0;
    item.DETFAC_DESPACHO = 0;
    item.DETFAC_FLAG = 0;
    item.DETFAC_HORA = "";
    item.DETFAC_ICE = 0;
    item.DETFAC_IVA = 0//this.selectiva.codigo;//CDPJ
    item.DETFAC_LINORGPROM = 0;
    item.DETFAC_LISTA = this.selectlista.codigo;
    item.DETFAC_ORDEN = 0;
    item.DETFAC_LINEA = this.encptovtaService.detfac[this.indicador].length + 1;//CDPJ
    item.DETFAC_LINEA_3 = 0;//CDPJ
    item.DETFAC_PORCEICE = 0;
    item.DETFAC_PRECIO = 0;
    item.DETFAC_PROMOCION = "0";
    item.DETFAC_SERIAL = "";
    item.DETFAC_SERIAL = "";
    item.DETFAC_SIGNO = "";
    item.DETFAC_TIPO = "";
    item.DETFAC_TIPODET = strTipoDetalle;
    item.DETFAC_TOTAL = 0;
    item.DETFAC_TRIBICE = "";
    item.DETFAC_TRIBIVA = "";
    item.DETFAC_TRIBIVA = "";
    item.DETFAC_UNIDAD = "";
    item.DETFAC_VALCSTVTA = 0;
    item.DETFAC_VALINV = 0;
    item.DETGRE_LINEA = 0;
    item.DETPED_LINEA = 0;
    item.ENCCOT_NUMERO = "";
    item.ENCFAC_NUMERO = "";
    item.ENCGRE_NUMERO = "";
    item.ENCPED_NUMERO = "";
    item.MAEPRM_CODIGO = 0;
    item.UNI_SIMBOLO = "";
    item.DETFAC_TIPOSRI = "";
    item.DETFAC_SIGNO = "+";
    //item.ENCFAC_IVA = this.selectiva.codigo;
    item.DETFAC_PORIVA=Number(this.encptovtaService.porcetariiva);
    item.TARIIVA_CODIGO='';

    this.detfacSelected = item;//CDPJ
    return item;
  }
  //GSRF  
  async calcularcantidad(fac){
    let cantidatot=0
    for (const fact of this.encptovtaService.detfac[this.indicador]) {
      if(fact.DETFAC_CODIGO === fac.DETFAC_CODIGO
        && fact.BOD_CODIGO === fac.BOD_CODIGO
        && fact.DETFAC_UNIDAD === fac.DETFAC_UNIDAD){
        //console.log('detalle cant',fac.DETFAC_CANTIDAD);
        cantidatot= cantidatot + Number(fact.DETFAC_CANTIDAD)
        
      }
    }
    return cantidatot
  }
//GSRF

  async guardarRegistro() {
    if (this.bolCantPed) { // verificó que la cantidad del pedido es menor a lo facturado		
      this.bolCantPed = false;
      return;
    }

    if (this.bolCantDev) { // verificó que la cantidad devuelta es menor a lo facturado	
      this.bolCantDev = false;
      return;
    }

    // verificar la existencia del cliente
    if (await this.verificarExisteCliente(this.encptovtaService.encfac[this.indicador].CLI_CODIGO) === false) {
      this.encptovtaService.encfac[this.indicador].CLI_NOMBRE = '';
      const INGESPFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INGESPFAC').CFG_VALOR1;
      if (INGESPFAC == 0)
        return;
      else
        this.colocaCodigoConsumidorFinal();
    }

    // verificar la existencia del vendedor
    if (await this.verificarExisteVendedor(this.encptovtaService.encfac[this.indicador].VEN_CODIGO) === false) {
      this.encptovtaService.encfac[this.indicador].VEN_NOMBRE = '';
      return;
    }
    //CDPJ
    let val = -1;
    if (this.strCodConsFinal !== '') {
      val = this.encptovtaService.encfac[this.indicador].CLI_CODIGO.toString().indexOf(this.strCodConsFinal);
    } else {
      val = -1;
    }

    //CDPJ
    console.log('valor1', this.dblLimConFin);
    if (val !== -1 && this.dblLimConFin > 0) {
      console.log('valor1', this.dblLimConFin);
      if (await this.ValidarLimiteConsumidorFinal() === false) {
        console.log('valor3', this.dblLimConFin);
        this.messageService.add({
          key: this.messageKey,
          severity: 'error',
          summary: 'Información',
          detail: 'El total de la factura supera el límite ingresado para el consumidor final'
        });
        return;
      }
    }

    //GSRF
    for (const fac of this.encptovtaService.detfac[this.indicador]) {
      let cantidatot=0
      cantidatot= await this.calcularcantidad(fac)
      console.log('cant ----->',cantidatot)
      if (cantidatot > fac.EXISTENCIA) { 
            this.messageService.add({
              key: this.messageKey,
              severity: 'error',
              summary: 'Información',
              detail: 'No se pueden facturar existencias negativas'+
                ' del artículo '+fac.DETFAC_CODIGO +''
            });
            return;
        }
     }
    //GSRF
    /*
        tablaFactura.cerrarEditorGrabando();
        modeloFactura.eliminarUltimaLineaDetalle();*/
    this.guardarFactura();
  }

  guardarRegistro2() {
    if (this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO.length > 0
      && (this.datosfe.COM_TIPOAMBFACTELEC === '1' || this.datosfe.COM_TIPOAMBFACTELEC === '2')
      && this.chkNotaVenta == false) {

      const PANACTEMA = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PANACTEMA').CFG_VALOR1;
      if (PANACTEMA == 1) {

        if (this.datosfe.COM_TIPOAMBFACTELEC === '1' || this.datosfe.COM_TIPOAMBFACTELEC === '2') { // Si esta en ambiente producción
          // Si tiene fecha de caducidad de la firma electrónica ingresada
          if (this.datosfe.COM_FECHACADFIRMAELE !== '') {
            /*DlgActCorreosElectro dlgCorreosElec = new DlgActCorreosElectro(
              shell, 1, "C", txtCodCliente.getText());
            dlgCorreosElec.open();
            */
          } else // no tiene asignado la fecha de caducidad de la firma electrónica
            return;

        }
      }

      if (this.chkNotaVenta == false) {
        this.insertarEleTrnDocumento(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);
      }
    }
  }


  async guardarFactura() {
    let bolGuardar = false;

    if (this.bolNuevo) { // nueva factura		
      if (this.permisoPtoVta !== null) {
        if (this.permisoPtoVta.PERTODO === 0) {
          if (this.permisoPtoVta.PERINSERCION === 0) {
            this.messageService.add({
              key: this.messageKey,
              severity: 'error',
              summary: 'Información',
              detail: 'El usuario '
                + this.encptovtaService.usuario.identificacion
                + ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.'
            });
            return;
          }
        }
      }
    } else { // modificar la factura
      if (this.permisoPtoVta !== null) {
        if (this.permisoPtoVta.PERTODO === 0) {
          if (this.permisoPtoVta.PERINSERCION === 0) {
            this.messageService.add({
              key: this.messageKey,
              severity: 'error',
              summary: 'Información',
              detail: 'El usuario '
                + this.encptovtaService.usuario.identificacion
                + ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.'
            });
            return;
          }
        }
      }
    }

    if (await this.informacionCompleta()) {
			/*tablaFactura.cerrarEditorGrabando();
			modeloFactura.calcularTotalesFact();
			calcularDsctoGlobal(DlgDescuento.glbBolEsValor, dblValorNeto);*/


      if (await this.insertarEncabezadoFact()) {
        const FACSINKAR = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FACSINKAR').CFG_VALOR1;
        if (FACSINKAR === 0) {
          if(this.chkNotaVenta === true ){//CDPJ
            this.strTipoNum='NV'
          }else if(this.chkFactura === true){
            this.strTipoNum='FAC'
          }
          console.log(this.strTipoNum);//CDPJ
          console.log(this.encptovtaService.encfac[this.indicador].TXT_NUMERO)//CDPJ
          this.actualizarKardex(this.strTipoNum + this.encptovtaService.encfac[this.indicador].TXT_NUMERO);
        }

        if (this.bolNuevo && this.bolImportarNV === false) {
          const PVPROAUFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PVPROAUFAC').CFG_VALOR1;
          if (PVPROAUFAC == 1) { // agregar las promociones automáticamente          
            this.agregarPromAutomatica();
          }
        }

        // verificar el estado de la factura con el cambio de mercadería
        if (await this.cambioArticulo() == false && this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO.length > 0 && this.bolImportarNV == false) {
          let intResp = 0;

          let intIngSeriales = 0;

          if (await this.ValidarSeriales(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO) === true) {
            intIngSeriales = 1;
          }
          const FORPAGAUT = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FORPAGAUT').CFG_VALOR1;
          if (FORPAGAUT == 1) {
            this.auxiliar++;
            this.strNumFact = this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO;
            //this.displayDialogPagoContado = true;
            this.auxpago = 'guardarFactura';
            this.cerrarPago()
            console.log('FORPAGAUT')
            
            /*DlgPagoContado dlgContado = new DlgPagoContado(shell, lblTotal.getText(), txtCodCliente.getText().trim(), 
              lblNumFact.getText().trim(), "", txtCodVendedor.getText().trim(), bolVisualizarAsiento, 
              true, hmpConfiguracion.get("PVASTFECDV"), hmpConfiguracion.get("PVCAJADEPV"), dblCliLimCredit,
              lblEstadoFE.getText(), "PTOVTA", this, intIngSeriales);
            dlgContado.createContents();
            dlgContado.formaPagoAuto();*/
          } else {
            const INGESPFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INGESPFAC').CFG_VALOR1;
            if (INGESPFAC == 0) {
              this.confirmationService.confirm({
                message: 'Pago al Contado?',
                header: 'Pregunta',
                icon: 'pi pi-exclamation-triangle',
                key: 'confirmarencptovta',
                accept: () => { // SI (Pago Contado)
                  this.confirmationService.close();
                  this.PagoFacturaContado(intIngSeriales);
                },
                reject: () => { // (Pago Crédito)
                  this.confirmationService.close();
                  this.PagoFacturaCredito();
                }
              });
            }
          }
          //CDPJ
          const intPANACTEMA = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PANACTEMA').CFG_VALOR1;
          if (intPANACTEMA === 1) {
            if (this.datosfe.COM_FEACTIVADA === '1') { // Si esta en ambiente producción
              this.leerDatos('C', this.encptovtaService.encfac[this.indicador].CLI_CODIGO);
              this.displayDialogActualizacionFE = true;
            }
          }
          //CDPJ
        } else {

          this.guardarFactura2()
        }
      }

    }
  }

  guardarFactura2() {

    // generar el asiento contable
    if (this.strFormaPago === "C" && this.encptovtaService.encfac[this.indicador].ASI_NRO.length == 0)
      this.generarAsientoContable();


    // actualizar el origen de la importación
    if (this.bolImportarNV)
      this.actualizarDocOrg();

    // preguntar si se quiere imprimir la factura
    if (this.strFormaPago.length > 0) {

      let bolImprimir = false;
      const INGESPFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INGESPFAC').CFG_VALOR1;
      if (INGESPFAC == 0) {
        this.confirmationService.confirm({
          message: 'Desea imprimir ahora?',
          header: 'Pregunta',
          icon: 'pi pi-exclamation-triangle',
          key: 'confirmarencptovta',
          accept: () => { // SI (Pago Contado)
            this.confirmationService.close();
            const PVIMPDIR = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PVIMPDIR').CFG_VALOR1;
            if (PVIMPDIR == 1) {
              /*
               frmSACI_maecfngimprDlg.intPVIMPDIR = hmpConfiguracion
                 .get("PVIMPDIR");
               frmSACI_maecfngimprDlg.intINGESPFAC = hmpConfiguracion
                 .get("INGESPFAC");
               frmSACI_maecfngimprDlg.strNotaVentaPV = "PV";
               frmSACI_maecfngimprDlg.strNombreForma = "frmVEN_ENCPTOVTA";
               Utilidades.FunCnfgMtrzRpt("ENCFAC_NUMERO",
                 lblNumFact.getText(), shell);
               frmSACI_maecfngimprDlg frmSACImaecfngimprDlg = new frmSACI_maecfngimprDlg(
                 shell, SWT.NONE);
               frmSACImaecfngimprDlg.ImprimirReporte();
               */
            }
          },
          reject: () => { // (Pago Crédito)
            this.confirmationService.close();
            /*frmSACI_maecfngimprDlg.strNombreForma = "frmVEN_ENCPTOVTA";
            Utilidades.FunCnfgMtrzRpt("ENCFAC_NUMERO",
              lblNumFact.getText(), shell);
            frmSACI_maecfngimprDlg frmSACImaecfngimprDlg = new frmSACI_maecfngimprDlg(
              shell, SWT.NONE);
            frmSACImaecfngimprDlg.open();
 
            if (ReporteVista.intbtnPresionado == 1)
              actualizarImpresoFact();*/
          }
        });
      } else
        bolImprimir = true;
    }

    this.bolNuevo = false;
    this.bolImportarNV = false;
  }


  async informacionCompleta() {
    let bolCompleto = true;

    // verificar cliente
    if (this.encptovtaService.encfac[this.indicador].CLI_CODIGO.length == 0) {
      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese el cliente.'
      });
      bolCompleto = false;

      if (this.txtCodClientesetEditable === true)
        document.getElementById('txtCodCliente').focus();

      return bolCompleto;
    }
    // verificar vendedor
    if (this.encptovtaService.encfac[this.indicador].VEN_CODIGO.length == 0) {
      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese el vendedor.'
      });
      bolCompleto = false;

      // if (this.txtCodClientesetEditable === true)
      //   document.getElementById('txtReferencia').focus();

      return bolCompleto;
    }
    // verificar autorizacion
    if (this.encptovtaService.encfac[this.indicador].ENCFAC_AUTORIZACION.length == 0) {
      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese la autorización'
      });
      bolCompleto = false;
      document.getElementById('lblAutorizacion').focus();
      return bolCompleto;
    }
//CDPJ
if (this.chkFactura === false && this.chkNotaVenta === false) {
  this.messageService.add({
    key: this.messageKey,
    severity: 'error',
    summary: 'Información',
    detail: 'Elija entre Nota de Venta o Factura'
  });
  bolCompleto = false;
  return bolCompleto;
}
//CDPJ
    // verificar que ingrese el número secuencial de la factura
    const NROPVGRB = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NROPVGRB').CFG_VALOR1;
    const PVDNROFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PVDNROFAC').CFG_VALOR1;
    if (NROPVGRB == 0) { // Número de factura al grabar		
      if (PVDNROFAC == 0) {
        if (this.encptovtaService.encfac[this.indicador].TXT_NUMERO.length == 0) {
          this.messageService.add({
            key: this.messageKey,
            severity: 'error',
            summary: 'Información',
            detail: 'Ingrese el número secuencial de la factura.'
          });
          bolCompleto = false;

          if (this.txtNumerosetEditable)
            document.getElementById('txtNumero').focus();

          return bolCompleto;
        }
      }
    }

    // verificar que no exista items con cantidad cero, código en blanco, y existencia de los códigos
    if (await this.verificaInfDetalle())
      return false;

    // verificar el valor facturado sea mayor a cero cuando es cambio de artículo
    if (this.bolNuevo) {
      if (this.encptovtaService.dblValorTotal[this.indicador] < 0 && this.txtReferenciasetEditable === true) {
        this.messageService.add({
          key: this.messageKey,
          severity: 'error',
          summary: 'Error',
          detail: "El valor que falta por facturar es "
            + this.encptovtaService.dblValorTotal[this.indicador]
        });
        return false;
      }
    }
    const PLAOBLIFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PLAOBLIFAC').CFG_VALOR1;
    if (PLAOBLIFAC == 1) {
      if (this.encptovtaService.encfac[this.indicador].ENCFAC_PLACA_CLI === "") {
        this.messageService.add({
          key: this.messageKey,
          severity: 'error',
          summary: 'Información',
          detail: "Ingrese la placa por favor"
        });
        document.getElementById('txtPlaca').focus();
        return false;
      }

    }

    // verificar que la referencia exista en el caso de un cambio de artículo
    if (this.txtReferenciasetEditable) {
      if (this.encptovtaService.encfac[this.indicador].ENCFAC_REFERENCIA.length > 0) {
        let strNumeroRef = "";

        const data = await this.encptovtaService.encontrarNumeroFactura(this.encptovtaService.encfac[this.indicador].ENCFAC_REFERENCIA)

        for (const rs of data) {
          strNumeroRef = rs.ENCFAC_NUMERO;
        }

        if (strNumeroRef.length == 0) {
          this.messageService.add({
            key: this.messageKey,
            severity: 'error',
            summary: 'Error',
            detail: "No existe el número de la factura a la cual se hace referencia"
              + " para el cambio."
          });
          return false;
        }
      }
    }

    return bolCompleto;
  }

  async verificarExisteCliente(strCliCodigo) {
    let bolExiste = false;


    const data = await this.encptovtaService.verificarExisteCli(strCliCodigo);

    for (const rs of data) {
      if (rs.CONT > 0)
        bolExiste = true;
      else
        this.messageService.add({
          key: this.messageKey,
          severity: 'error',
          summary: 'Error',
          detail: 'No existe el cliente'
        });
    }

    return bolExiste;
  }

  colocaCodigoConsumidorFinal() {
    this.encptovtaService.encfac[this.indicador].CLI_CODIGO = this.strCodConsFinal;
    document.getElementById('txtCodCliente').focus();
    this.buscarCliente(true);
  }

  async verificarExisteVendedor(strVenCodigo) {
    let bolExiste = true;

    const data = await this.encptovtaService.verificarExisteVen(strVenCodigo);

    for (const rs of data) {
      if (rs.CONT = 0) {
        bolExiste = true;
        this.messageService.add({
          key: this.messageKey,
          severity: 'error',
          summary: 'Error',
          detail: 'No existe el vendedor'
        });
      }
    }

    return bolExiste;
  }


  async ValidarLimiteConsumidorFinal() {
    let fac_total = Number(this.encptovtaService.encfac[this.indicador].ENCFAC_TOTAL);
    let limte = Number(this.dblLimConFin)
    console.log('encfac_1', this.encptovtaService.encfac[this.indicador].ENCFAC_TOTAL);
    console.log('encfac_', this.dblLimConFin);
    if (fac_total >= limte) {
      console.log('encfac_2', this.encptovtaService.encfac[this.indicador].ENCFAC_TOTAL);
      return false;
    } else {
      return true;
    }


  }

  async  insertarEncabezadoFact() {
    //CDPJ
    this.encptovtaService.encfac[this.indicador].ENCFAC_TOTALNETO = this.init.quitarComas(this.encptovtaService.encfac[this.indicador].ENCFAC_TOTALNETO);
    this.encptovtaService.encfac[this.indicador].ENCFAC_PORCEDES = this.init.quitarComas(this.encptovtaService.encfac[this.indicador].ENCFAC_PORCEDES);
    this.encptovtaService.encfac[this.indicador].ENCFAC_VALORDES = this.init.quitarComas(this.encptovtaService.encfac[this.indicador].ENCFAC_VALORDES);
    this.encptovtaService.encfac[this.indicador].ENCFAC_VALORICE = this.init.quitarComas(this.encptovtaService.encfac[this.indicador].ENCFAC_VALORICE);
    this.encptovtaService.encfac[this.indicador].ENCFAC_BASEICE = this.init.quitarComas(this.encptovtaService.encfac[this.indicador].ENCFAC_BASEICE);
    this.encptovtaService.encfac[this.indicador].ENCFAC_BASECERO = this.init.quitarComas(this.encptovtaService.encfac[this.indicador].ENCFAC_BASECERO);
    this.encptovtaService.encfac[this.indicador].ENCFAC_BASEIVA = this.init.quitarComas(this.encptovtaService.encfac[this.indicador].ENCFAC_BASEIVA);
    this.encptovtaService.encfac[this.indicador].ENCFAC_VALORIVA = this.init.quitarComas(this.encptovtaService.encfac[this.indicador].ENCFAC_VALORIVA);
    this.encptovtaService.encfac[this.indicador].ENCFAC_TOTAL = this.init.quitarComas(this.encptovtaService.encfac[this.indicador].ENCFAC_TOTAL);
    this.encptovtaService.encfac[this.indicador].ENCFAC_BASENOOBJIVA = this.init.quitarComas(this.encptovtaService.encfac[this.indicador].ENCFAC_BASENOOBJIVA);
    //CDPJ

     let bolInsertar = false;
    // console.log(this.selectiva.codigo)
    // let dblPorIvaSel = this.selectiva.codigo;

    this.obtenerFechaHoraDoc();
    const item: any = {};
    if (this.bolNuevo === true) {
      item.ENCFAC_NUMERO = "";
      //CDPJ
    }
    else {
      item.ENCFAC_NUMERO = this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO;
      //CDPJ
    }
    if (this.encptovtaService.encfac[this.indicador].TXT_NUMERO === '') {
      item.ENCFAC_NUMERO_1 = this.encptovtaService.encfac[this.indicador].TXT_NUMERO;
    }
    item.ENCFAC_NUMERO_1 = this.encptovtaService.encfac[this.indicador].TXT_NUMERO;
    item.ENCFAC_FECHAEMISION = this.strFecha + " " + this.strHora;
    item.ENCFAC_FECHAENTREGA = this.strFecha;


    // estado de la factura
    if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO === "PENDIENTE")
      item.ENCFAC_ESTADO = 'P';
    else if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO === "PAGADA")
      item.ENCFAC_ESTADO = 'G';
    else if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO === "ANULADA")
      item.ENCFAC_ESTADO = 'A';
    else if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO === "CAMBIO")
      item.ENCFAC_ESTADO = 'CM';


    item.CLI_CODIGO = this.encptovtaService.encfac[this.indicador].CLI_CODIGO;
    item.CLI_NOMBRE = this.encptovtaService.encfac[this.indicador].CLI_NOMBRE;
    item.BOD_CODIGO = this.encptovtaService.encfac[this.indicador].BOD_CODIGO;
    item.VEN_CODIGO = this.encptovtaService.encfac[this.indicador].VEN_CODIGO;
    item.ENCFFA_CODIGO = '';

    if (this.listaEncFact.length == 0)
      item.ENCFAC_FORMAPAGO = ""; // forma de pago
    else
      item.ENCFAC_FORMAPAGO = this.listaEncFact[0].ENCFAC_FORMAPAGO;

    item.ENCFAC_REFERENCIA = this.encptovtaService.encfac[this.indicador].ENCFAC_REFERENCIA;
    item.ENCFAC_TIPO = '';
    item.ENCFAC_LISTA = this.selectlista.codigo;

    // fecha de vencimiento
    item.ENCFAC_FECHAVENCIMIENTO = this.datePipe.transform(this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAVENCIMIENTO, 'dd/MM/yyyy');
    item.ENCFAC_TOTAL = Number(this.encptovtaService.encfac[this.indicador].ENCFAC_TOTAL);
    item.ENCFAC_TOTALNETO = Number(this.encptovtaService.encfac[this.indicador].ENCFAC_TOTALNETO);
    item.ENCFAC_PORCEIVA = Number(this.encptovtaService.encfac[this.indicador].ENCFAC_PORCEIVA);
    item.ENCFAC_VALORIVA = Number(this.encptovtaService.encfac[this.indicador].ENCFAC_VALORIVA);
    
    //validar numeros CDPJ
    item.ENCFAC_TOTAL =  isNaN(item.ENCFAC_TOTAL) == true || item.ENCFAC_TOTAL == null || item.ENCFAC_TOTAL == undefined || item.ENCFAC_TOTAL == ''? 0 : item.ENCFAC_TOTAL;
    item.ENCFAC_TOTALNETO = isNaN(item.ENCFAC_TOTALNETO) == true || item.ENCFAC_TOTALNETO == null || item.ENCFAC_TOTALNETO == undefined || item.ENCFAC_TOTALNETO == ''?  0 : item.ENCFAC_TOTALNETO;
    item.ENCFAC_PORCEIVA =  isNaN(item.ENCFAC_PORCEIVA) == true || item.ENCFAC_PORCEIVA == null || item.ENCFAC_PORCEIVA == undefined || item.ENCFAC_PORCEIVA == ''? 0 : item.ENCFAC_PORCEIVA ;
    item.ENCFAC_VALORIVA =  isNaN(item.ENCFAC_VALORIVA) ==  true || item.ENCFAC_VALORIVA == null || item.ENCFAC_VALORIVA == undefined || item.ENCFAC_VALORIVA == ''? 0 : item.ENCFAC_VALORIVA;
    //CDPJ
    item.ENCFAC_PORCECAR = "0";
    item.ENCFAC_VALORCAR = "0";
    item.ENCFAC_PORCERTC = "0";
    item.ENCFAC_VALORRTC = "0";
    item.ENCFAC_PORCEDES = Number(this.encptovtaService.dblPorcDescGlb);// porcentaje descuento global
    item.ENCFAC_VALORDES = Number(this.encptovtaService.dblValorDescGlb);// valor descuento  global
    item.ENCFAC_VALORDEV = "0";
    item.ENCFAC_PORCEINT = "0";
    item.ENCFAC_VALORINT = "0";
    item.ENCFAC_PORCEREC = "0";
    item.ENCFAC_VALORREC = "0";
    item.ENCFAC_VALORICE = Number(this.encptovtaService.dblValorIceItem[this.indicador]);// valor ice
    item.ENCFAC_PORCEICE = "0";
    item.ENCFAC_IMPRESO = "N";
    if (this.listaEncFact.length > 0)
      item.ENCFAC_COMENTARIO = this.listaEncFact[0].ENCFAC_COMENTARIO == null ? ""
        : this.listaEncFact[0].ENCFAC_COMENTARIO; // comentario
    else
      item.ENCFAC_COMENTARIO = "";
    item.ENCFAC_TIPOFAC = "S";
    item.ENCFAC_GENERAFAC = "N";
    item.ENCFAC_NUMERODOB = "";
    item.ENCFAC_DIRECCION = ""; // direccion1
    item.ENCFAC_TELEFONO = ""; // telefono
    item.ENCFAC_RUCIDE = ""; // ruc
    item.ENCFAC_CHOFER = ""; // chofer pendiente
    item.ENCFAC_CARRO = ""; // carro pendiente
    item.ENCFAC_PLACA = ""; // placa pendiente
    // tipo de iva
    const PVPREAIVA = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PVPREAIVA').CFG_VALOR1;
    if (PVPREAIVA == 0) // neto + iva
      item.ENCFAC_IVA = "1";
    else
      // incluído iva
      item.ENCFAC_IVA = "2";
    item.ENCFAC_BASEIVA = Number(this.encptovtaService.encfac[this.indicador].ENCFAC_BASEIVA); // base iva
    item.ENCFAC_BASEICE = Number(this.encptovtaService.encfac[this.indicador].ENCFAC_BASEICE); // base ice
    item.ENCFAC_BASECERO = Number(this.encptovtaService.encfac[this.indicador].ENCFAC_BASECERO); // base cero
    //CDPJ
    item.ENCFAC_BASEIVA =  isNaN(item.ENCFAC_BASEIVA) == true ||  item.ENCFAC_BASEIVA == null || item.ENCFAC_BASEIVA == undefined || item.ENCFAC_BASEIVA == ''? 0: item.ENCFAC_BASEIVA;
    item.ENCFAC_BASEICE =  isNaN(item.ENCFAC_BASEICE) == true || item.ENCFAC_BASEICE == null || item.ENCFAC_BASEICE == undefined || item.ENCFAC_BASEICE == ''? 0: item.ENCFAC_BASEICE;
    item.ENCFAC_BASECERO =  isNaN(item.ENCFAC_BASECERO) == true || item.ENCFAC_BASECERO == null || item.ENCFAC_BASECERO == undefined || item.ENCFAC_BASECERO == ''? 0: item.ENCFAC_BASECERO;
    //CDPJ

    item.ENCFAC_OTROS = ""; // otros
    item.ENCFAC_GRUPO = ""; // grupo de facturas
    item.ENCFAC_DIASCRDT = this.encptovtaService.encfac[this.indicador].ENCFAC_DIASCRDT; // días de crédito
    item.ENCFAC_CLIDSCT = "0"; // descuento cliente
    
    item.USU_IDENTIFICACION = this.encptovtaService.usuario.identificacion; // usuario
    console.log('usu',this.encptovtaService.encfac[this.indicador].USU_IDENTIFICACION)
    //CDPJ
    if(this.encptovtaService.encfac[this.indicador].USU_IDENTIFICACION !== null 
      && this.encptovtaService.encfac[this.indicador].USU_IDENTIFICACION !== undefined
      && this.encptovtaService.encfac[this.indicador].USU_IDENTIFICACION !== ''){
        item.USU_IDENTIFICACION = this.encptovtaService.encfac[this.indicador].USU_IDENTIFICACION
      }
    //CDPJ

    if (this.encptovtaService.dblPorcDescGlb > 0) { // tipo de descuento		
      if (this.glbBolEsValor)
        item.ENCFAC_TIPODSCTO = "V";
      else
        item.ENCFAC_TIPODSCTO = "P";
    } else
      item.ENCFAC_TIPODSCTO = "";
    item.ENCFAC_ORIGEN = "PTOVTA"; // origen

    if (this.encptovtaService.encfac[this.indicador].TXT_NUMERO.length >= this.intTamNumFact)
      item.ENCFAC_SECACTUAL = this.encptovtaService.encfac[this.indicador].TXT_NUMERO.substring(
        this.encptovtaService.encfac[this.indicador].TXT_NUMERO.length - this.intTamNumFact); // número fact
    else
      item.ENCFAC_SECACTUAL = "";

    item.ENCFAC_REFCLI = this.encptovtaService.encfac[this.indicador].ENCFAC_REFCLI; // referencia
    if (this.chkNotaVenta === true) // nota de venta
      item.ENCFAC_NOTADEVENTA = "S";
    else
      item.ENCFAC_NOTADEVENTA = "";
    item.ENCFAC_AUTORIZACION = this.encptovtaService.encfac[this.indicador].ENCFAC_AUTORIZACION; // número de
    // autorizacion

    item.ENCFAC_PLACA_CLI = this.encptovtaService.encfac[this.indicador].ENCFAC_PLACA;
    if (this.selectventa.name === "eCommerce") {
      item.ENCFAC_VENTA = "2"; // tipo de venta
    } else {
      item.ENCFAC_VENTA = "1"; // tipo de venta
    }
    //CDPJ
    item.ENCFAC_TIPOFACTURA = this.encptovtaService.encfac[this.indicador].ENCFAC_TIPOFACTURA;
    item.ENCFAC_PORCEIVA =Number(this.encptovtaService.porcetariiva);
    //CDPJ
    const data = await this.encptovtaService.insertarEncabezadoFact(item);

    for (const rs of data) {
      if (rs[":B2"].length > 0) {
        let strNumFactura = rs[":B2"];
        const NROPVGRB = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NROPVGRB').CFG_VALOR1;
        if (NROPVGRB === 1) { // Número de factura al grabar        
          this.encptovtaService.encfac[this.indicador].TXT_NUMERO = strNumFactura.replace(this.strTipoNum, "");
          //this.encptovtaService.encfac[this.indicador].ENCFAC_AUTORIZACION = this.obtenerAutorizacionFact(strNumFactura);
        }
        bolInsertar = await this.insertarDetalleFactura(strNumFactura);

        if (bolInsertar) {
          // actualizar el número secuencial de la factura
          if (this.bolNuevo) {
            this.auditoriagral.registrarAuditoria('VEN_ENCFAC', strNumFactura + "/VEN", 'I',
              '', '01', '', '', '', '').subscribe();
            const NROPVGRB = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NROPVGRB').CFG_VALOR1;
            if (NROPVGRB == 1) // Número de factura al grabar
              this.obtenerNumeracionFact(strNumFactura);
            // si esta desactivado que se genere el numero de factura al guardar y si el numero de la factura esta activado
            if (NROPVGRB == 0)
              this.actualizarNumeracionPV(this.obtenerUsuarioFact(strNumFactura),
                this.strTipoNum, this.strNemonico, this.strSerie);

            this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO = strNumFactura;


          } else
            this.auditoriagral.registrarAuditoria('VEN_ENCFAC', strNumFactura + "/VEN", 'A',
              '', '01', '', '', '', '').subscribe();
          if (this.bolImportarNV === false) {
            this.botonesinicio();

            // extraer los pedidos incluídos en la factura
            this.extraerPedidos(strNumFactura);

            if (this.listaPedidos.length > 0) {
              this.verificarActualizarEstPed(); // actualiza el estado del pedido
              this.actualizarFechaEntregaPed(); // actualiza la fecha de entrega del pedido
            }
          } else
            this.verificarEstadoFactura();

          this.txtCodClientesetEditable = false;
          this.btnClientesetEnabled = false;
          this.txtNumerosetEditable = false;
          // this.cmpTipoDocsetEnabled = false;

          if (this.bolImportarNV === false) {
            // actualizar el despacho del pedido
            if (this.listaPedidos.length > 0)
              this.actualizarDespachoPedido();
          }
          // actualizar el saldo del cliente

        } else {
          if (this.bolNuevo)
            this.eliminarFactura(strNumFactura, true);
        }
        this.consultarFacturas(strNumFactura);//CDPJ
      }

    }
    //CDPJ
    const clitipo: any = await this.encptovtaService.encontrarTipoCli(this.encptovtaService.encfac[this.indicador].CLI_CODIGO);

    for (const rs of clitipo) {
      //console.log('entro')
      this.strTipoCliD = rs.CLI_TIPO;
    }
    //CDPJ

    return bolInsertar;

  }

  async actualizarKardex(strNumFact) {
    await this.encptovtaService.actualizarTrnKardexFac(strNumFact);

  }

  async agregarPromAutomatica() {
    await this.encptovtaService.agregarPromocionesFac(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO,
      "PVPREAIVA", "PVPROAUFAC")
    /*
  
    ejecutaSentencia.EjecutaSP(strProceso, 4, strParam, false);
    this.consultarFacturas("R", strCondNavegar + " AND ENCFAC_numero = '" + lblNumFact.getText() + "'");
  */
    this.encptovtaService.calcularTotalesFact();
    // verificar la existencia para la promoción caso contrario no se aplica
    this.verificarExistProm();

  }
  //CDPJ
  async leerDatos(strTipoCorreo, strCodigo) {
    if (strTipoCorreo === 'C') { // clientes
      // this.encfacService.erDatosCliCorreo(strCodigo).subscribe(er => {
      //   if (er !== null) {
      //     if (er[0] !== undefined) {
      //       this.txtCorreo1 = er[0].CLI_CORREO;
      //       this.txtCorreo2 = er[0].CLI_CORREO2;
      //     }
      //   }
      // });
      const correos = await this.encfacService.erDatosCliCorreo(strCodigo);
      console.log('--++-- correos',correos)
      if (correos !== null) {
            if (correos[0] !== undefined) {
              this.txtCorreo1 = correos[0].CLI_CORREO;
              this.txtCorreo2 = correos[0].CLI_CORREO2;
            }
          }
    }

    if (strTipoCorreo === 'P') {
      this.encfacService.erDatosProCorreo(strCodigo).subscribe(er => {
        if (er !== null) {
          if (er[0] !== undefined) {
            this.txtCorreo1 = er[0].PRO_CORREO;
            this.txtCorreo2 = er[0].PRO_CORREO2;
          }
        }
      });
    }
  }

  //CDPJ
  async cambioArticulo() {
    let bolCambio = false;

    if (this.encptovtaService.encfac[this.indicador].ENCFAC_REFERENCIA.length > 0) {
      if (this.encptovtaService.dblValorTotal[this.indicador] == 0) {
        await this.encptovtaService.cambioarticulo(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);
        this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO = 'CAMBIO';
        this.verificarEstadoFactura();
        bolCambio = true;
      }
    }

    return bolCambio;
  }

  async PagoFacturaContado(intIngSeriales) {
    if (this.botonGuardar === false) {
      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Información',
        detail: 'Guarde la factura antes de realizar el pago.'
      });
      return;
    }
    //CDPJ
    if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO === 'ANULADA') {
      this.messageService.add({
        key: 'encptovta',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede pagar la Factura'
          + ' porque está anulada.'
      });
      return;
    }
    //CDPJ
    // DlgPagoContado.intINGESPFAC = hmpConfiguracion.get("INGESPFAC");

    if (Number(this.init.quitarComas(this.encptovtaService.encfac[this.indicador].ENCFAC_TOTAL)) == 0) { // si el forma de pago		
      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Información',
        detail: 'El valor de la factura es cero.'
      });
      const FACVALCER = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FACVALCER').CFG_VALOR1;
      if (FACVALCER == 1) { // Si el sistema permite guardar facturas con el valor de cero        
        this.encptovtaService.actualizaEstadoFactura(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO,
          this.encptovtaService.encfac[this.indicador].CLI_CODIGO);
        this.encptovtaService.actualizaFormaPagoFact(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO, "T");
        this.strFormaPago = "T";
        this.colocarEstadoFactura(await this.obtenerEstadoFactura(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO));
        this.verificarEstadoFactura();
      }
      return;
    }

    // si la factura está pagada no presentar el mensaje
    let intNumPagos = await this.existePagoDirecto();

    if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO == "PENDIENTE") {
      if (intNumPagos == 0 && this.strFormaPago === "") {

        this.auxiliar++;
        this.strNumFact = this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO;
        this.displayDialogPagoContado = true;
        this.auxpago = 'PagoFacturaContado';
        /*DlgPagoContado dlgContado = new DlgPagoContado(shell, lblTotal
            .getText(), txtCodCliente.getText().trim(), lblNumFact
            .getText().trim(), "", txtCodVendedor.getText().trim(),
            bolVisualizarAsiento, true, hmpConfiguracion
                .get("PVASTFECDV"), hmpConfiguracion
                .get("PVCAJADEPV"), dblCliLimCredit,
            lblEstadoFE.getText(), "PTOVTA", this, intIngSeriales);
        dlgContado.open();*/
      } else if (intNumPagos == 1)
        this.presentarPagoDirecto();
      else
        this.messageService.add({
          key: this.messageKey,
          severity: 'error',
          summary: 'Error',
          detail: 'El pago de la factura está a crédito.'
        });
    } else {
      if (intNumPagos == 1)
        this.presentarPagoDirecto();
      else {
        if (intNumPagos == 0 && this.strFormaPago !== "C") {
          this.auxiliar++;
          this.strNumFact = this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO;
          this.displayDialogPagoContado = true;
          this.auxpago = 'PagoFacturaContado';
          /*DlgPagoContado dlgContado = new DlgPagoContado(shell,
              lblTotal.getText(), txtCodCliente.getText().trim(),
              lblNumFact.getText().trim(), "", txtCodVendedor
                  .getText().trim(), bolVisualizarAsiento,
              true, hmpConfiguracion.get("PVASTFECDV"),
              hmpConfiguracion.get("PVCAJADEPV"),
              dblCliLimCredit, lblEstadoFE.getText(), "PTOVTA",
              this, intIngSeriales);
          dlgContado.open();*/
        } else
          this.messageService.add({
            key: this.messageKey,
            severity: 'error',
            summary: 'Información',
            detail: 'El pago se realizó desde cuentas por cobrar'
          });
      }
    }

  }

  async PagoFacturaContado2() {
    // cambiar el estado de acuerdo al pago realizado
    this.colocarEstadoFactura(await this.obtenerEstadoFactura(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO));
    this.verificarEstadoFactura();
    // actualizar el saldo del cliente

    let intNumPagos = await this.existePagoDirecto();
    if (this.encptovtaService.encfac[this.indicador].ASI_NRO.length == 0 && intNumPagos == 1) {
      this.encptovtaService.encfac[this.indicador].ASI_NRO = this.strNumAsi;
    } else if (this.encptovtaService.encfac[this.indicador].ASI_NRO !== this.strNumAsi) {
      this.encptovtaService.encfac[this.indicador].ASI_NRO = this.strNumAsi;
    }

    if ((this.strNumAsi.length == 0 && intNumPagos == 1)
      || (this.strNumAsi.length == 0 && this.strFormaPago.length == 0)) {
      this.encptovtaService.encfac[this.indicador].ASI_NRO = '';
    }
  }

  async PagoFacturaCredito() {
    console.log('tipo', this.strTipoCliD)
    if (this.bolNuevo)
      this.strFormaPago = "";
    if (this.creditoConsumFinal() === false) { // si la factura de consumidor final se puede pagar a crédito
      return;
    }
 //CDPJ
 if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO === 'ANULADA') {
  this.messageService.add({
    key: 'encptovta',
    severity: 'error',
    summary: 'Error',
    detail: 'No se puede pagar la Factura'
      + ' porque está anulada.'
  });
  return;
}
//CDPJ
    // no se puede pagar a crédito si es una cambio de artículo
    if (this.encptovtaService.encfac[this.indicador].ENCFAC_REFERENCIA.length > 0) {
      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Información',
        detail: 'No puede Pagar a crédito, es un cambio de artículo.'
      });
      return;
    } else if (this.strTipoCliD === '3') {
      //CDPJ

      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Información',
        detail: 'El cliente solo tiene permitido pagos en efectivo.'

      });
      return;
    } else {
      console.log(1);
      if (await this.verificarPermisosObjConClave("frmVEN_ENCPTOVTA", "CmdPagoACredito", 0)) {
        this.pagoCreditoConClave(); // verificar si no necesita clave de supervisor para pagar a crédito       
      } else {
        this.PagoFacturaCredito2();
      }
    }
  }

  async PagoFacturaCredito2() {
    console.log(2);
    if (this.botonGuardar === false) {
      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Información',
        detail: 'Guarde la factura antes de realizar el pago.'
      });
      return;
    }

    if (Number(this.init.quitarComas(this.encptovtaService.encfac[this.indicador].ENCFAC_TOTAL)) == 0) { // si el valor de la factura es
      // cero no pregunta la forma de pago		
      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Información',
        detail: 'El valor de la factura es cero.'
      });
      return;
    }

    if (this.strFormaPago.length == 0) {

      let intCfgAutFacVen = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PVAUTFCVEN').CFG_VALOR1;
      // verificar según configuración si se autoriza a la facturación si se excede en el lím de crédito
      if (await this.autorizarFacturacionLimCredito(this.encptovtaService.encfac[this.indicador].CLI_CODIGO,
        this.dblCliLimCredit, intCfgAutFacVen, this.encptovtaService.dblValorTotal[this.indicador], this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO) === false) {
        this.messageService.add({
          key: this.messageKey,
          severity: 'error',
          summary: 'Información',
          detail: "Debe ingresar correctamente"
            + " la clave de Supervisor para sobrepasar el límite de crédito."
        });
        return;
      }
    }

    // si la factura está pagada no presentar el mensaje		
    let intNumPagos = await this.existePagoDirecto();

    if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO == "PENDIENTE") {
      if (intNumPagos === 0 && this.strFormaPago === '') {
        /*DlgOpcionCredito dlgOpcion = new DlgOpcionCredito(shell, this);
        dlgOpcion.open();

        // 1 cuotas - 0 vencimiento - -1 se salió sin escoger
        if (this.intOpcionSel == 0) {// vencimiento
          this.encptovtaService.actualizaFormaPagoFact(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO, "C");
          this.strFormaPago = "C";
        }*/
        //CDPJ
        this.myForm = this.forms.group({
          myRadio: ['FEC', []],
        });
        this.rbtFECCUO = 'FEC';
        //console.log(this.myForm.value);
        //CDPJ
        this.displayDialogPagoCredito = true;
      } else if (intNumPagos == 1)
        this.messageService.add({
          key: this.messageKey,
          severity: 'error',
          summary: 'Error',
          detail: 'El pago de la factura está al contado'
        });
      else if (intNumPagos == 0 && this.strFormaPago.length > 0)
        this.messageService.add({
          key: this.messageKey,
          severity: 'error',
          summary: 'Error',
          detail: 'El pago de la factura está a crédito'
        });
      else
        this.messageService.add({
          key: this.messageKey,
          severity: 'error',
          summary: 'Error',
          detail: 'El pago de la factura se realizó desde cuentas por cobrar'
        });
    } else { // mostrar cuotas si es que existe o presentar mensaje de pagos desde cxc
      if (intNumPagos == 1)
        this.messageService.add({
          key: this.messageKey,
          severity: 'error',
          summary: 'Error',
          detail: 'El pago de la factura está al contado'
        });
      else
        this.messageService.add({
          key: this.messageKey,
          severity: 'error',
          summary: 'Error',
          detail: 'El pago de la factura se realizó desde cuentas por cobrar'
        });
    }

    // cambiar el estado de acuerdo al pago realizado
    this.colocarEstadoFactura(await this.obtenerEstadoFactura(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO));
    this.verificarEstadoFactura();

  }

  async generarAsientoContable() {
    let strTipoDiario = "AF";

    const dia = this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAEMISION.getDate();
    const mes = this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAEMISION.getMonth();
    const anio = this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAEMISION.getFullYear();

    // verificar que el documento esté dentro del periodo contable
    if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
      this.messageService.add({
        key: this.messageKey,
        severity: 'info',
        summary: 'Información',
        detail: 'Error, fecha debe de estar dentro del período contable'
      });
      return;
    }


    if (this.encptovtaService.encfac[this.indicador].TXT_NUMERO.length > 0) {
      let data: any;
      if (this.strFormaPago === "T") {
        data = await this.encptovtaService.generarAsientoContableSinCxc(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO, 'CI',
          this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO.substr(
            this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO.length - 7))
      } else {
        data = await this.encptovtaService.generarAsientoContable(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO, 'AF',
          this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO.substr(
            this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO.length - 7));
      }

      const ddasi: any = await this.encptovtaService.erGenerarAsiento(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);
      let strNumAsi = '';
      // Recuperar el número del asiento
      for (const rs of ddasi) {
        strNumAsi = rs.ASI_NRO;
      }

      if (strNumAsi.length > 0) {
        this.encptovtaService.encfac[this.indicador].ASI_NRO = strNumAsi;
        this.auditoriagral.registrarAuditoria('CON_ENCASI_L', strNumAsi + "/VEN", 'I',
          '', '01', '', '', '', '').subscribe();
        this.messageService.add({
          key: this.messageKey,
          severity: 'success',
          summary: 'Información',
          detail: "El Asiento Contable se generó correctamente"
        });;
      }

    } else
      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Información',
        detail: "Escoja una factura, para generar el Asiento Contable"
      });

  }

  creditoConsumFinal() {
    let bolCredito = true;

    let FACCONF = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FACCONF').CFG_VALOR1;
    if (FACCONF == 0) {
      if (this.encptovtaService.encfac[this.indicador].CLI_CODIGO === this.strCodConsFinal
        && this.strFormaPago.length == 0) { // pendiente cambio mercadería
        this.messageService.add({
          key: this.messageKey,
          severity: 'error',
          summary: 'Información',
          detail: "No puede Pagar a crédito, el Cliente es Consumidor final"
        });
        bolCredito = false;
      }
    }

    return bolCredito;
  }

  async insertarEleTrnDocumento(strNumDoc) {

    /* if (Utilidades.glb_int_controlFacturacionEle == 0)
       return;
  */
    if (strNumDoc.length <= 0)
      return;

    if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO_FE === "VA") {
      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Información',
        detail: "Documento ya fue procesado"
      });
      return;
    }

    if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO_FE === "AT"
      || this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO_FE === "NT"
      || this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO === "ANULADA") {
      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Información',
        detail: "Documento no puede se autorizado"
      });
      return;
    }

    // let strTipoFac = "";
    //let strFechaServidor = "";

    // verifica si existe la forma de pago en la factura anterior a la búsqueda
    if (strNumDoc.length > 0 && this.botonGuardar === true) {
      if (await this.verificarExisteFP() === false)
        return;
    }
    const CLAAUTFE = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CLAAUTFE').CFG_VALOR1;
    if (CLAAUTFE == 1) { // si esta activado la seguridad par firmar electrónicamente		
      /*DlgAutorizacion autoriza = new DlgAutorizacion(shell, "AUTFE");
      autoriza.open();
  
      if (!DlgAutorizacion.glbBolIsValid) {
        this.messageService.add({
          key: this.messageKey,
          severity: 'error',
          summary: 'Información',
          detail:  "Debe ingresar correctamente"
          + " la clave para firmar electrónicamente"
        });
        return;
      }*/
    }
    //CDPJ
    // this.confirmationService.confirm({
    //   message: "Está seguro de firmar el " + "documento "
    //     + strNumDoc + "?",
    //   header: 'Pregunta',
    //   icon: 'pi pi-exclamation-triangle',
    //   key: 'confirmarencptovta',
    //   accept: () => {
    //     //this.confirmationService.close();

    //   },
    //   reject: () => {
    //     this.confirmationService.close();
    //     return;
    //   }
    // });
    //CDPJ
    console.log('paso de confirmación')
    //strTipoFac = "FCN";

    /*
        strFechaServidor = eje.encontrarRegistro("to_char(sysdate,'yyyy-mm-dd')", "dual", "");
    
    
        // Si esta en pruebas o producción
        if (Utilidades.glb_str_TipoAmbienteFacElec.compareTo("1") == 0
          || Utilidades.glb_str_TipoAmbienteFacElec.compareTo("2") == 0) { // Si esta en ambiente producción		
          // Si tiene fecha de caducidad de la firma electrónica ingresada
          if (Utilidades.glb_str_fechaCaducidadFacElec.compareTo("") != 0) {
            if (java.sql.Date.valueOf(strFechaServidor).after(java.sql.Date.valueOf(Utilidades.glb_str_fechaCaducidadFacElec))) {
              MessageDialog
                .openInformation(
                  new Shell(),
                  "Información",
                  "Error, No puede firmar ningún documento electrónico porque esta caducada la fecha de validez de la firma elctrónica");
              return;
            }
          } else // no tiene asignado la fecha de caducidad de la firma electrónica
            return;
    
          String[][] parametros = new String[9][2];
    
          parametros[0][0] = strNumDoc;
          parametros[0][1] = "S";
          parametros[1][0] = strTipoFac;// tipo de factura
          parametros[1][1] = "S";
          parametros[2][0] = "VA"; // estado
          parametros[2][1] = "S";
          parametros[3][0] = ""; // resultado
          parametros[3][1] = "S";
          parametros[4][0] = ""; // ubicación del archivo
          parametros[4][1] = "S";
          parametros[5][0] = ""; // secuencial
          parametros[5][1] = "S";
          parametros[6][0] = Conexion.usuarioConectado; // usuario
          parametros[6][1] = "S";
          parametros[7][0] = Conexion.COMPANIA; // usuario
          parametros[7][1] = "S";
          parametros[8][0] = Utilidades.glb_str_TipoAmbienteFacElec; // tipo
          // de
          // ambiente
          parametros[8][1] = "S";
    
          eje.EjecutaSP(
            "ELE_PCK_TRNDOCUMENTO.ele_sp_insertarTrnDocumento", 9,
            parametros, false);
          lblEstadoFE.setText("VA");
          btnAnular.setEnabled(false);
          verificarEstadoFactura();
    
        } else // no tiene asigando ningun tipo de facturacion electronica
          return;
    */
    const strNumDocD = this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO;
    const strFecha = this.datePipe.transform(this.encptovtaService.encfac[this.indicador].ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
    let strTipoFac = '';
    const strCodigo = this.encptovtaService.encfac[this.indicador].ENCFAC_TIPOFACTURA;
    console.log('tipo', strCodigo)
    // const strFechaServidor = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    const strFechaServidor = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    // Si esta en pruebas o producción
    if (this.datosfe.COM_TIPOAMBFACTELEC === '1'
      || this.datosfe.COM_TIPOAMBFACTELEC === '2') {
      if (this.datosfe.COM_FECHACADFIRMAELE != null) {
        if (this.datosfe.COM_FECHACADFIRMAELE.length > 0) {
          if (new Date(strFechaServidor) > new Date(this.datosfe.COM_FECHACADFIRMAELE)) {
            this.messageService.add({
              key: this.messageKey,
              severity: 'error',
              summary: 'Error',
              detail: 'No puede firmar ningún documento electrónico porque esta caducada la fecha de validez de la firma electrónica'
            });
            return;
          }
        } else {
          this.messageService.add({
            key: this.messageKey,
            severity: 'error',
            summary: 'Error',
            detail: 'No existe fecha de caducidad de la firma electrónica'
          });
          return;
        }
      } else {
        this.messageService.add({
          key: this.messageKey,
          severity: 'error',
          summary: 'Error',
          detail: 'No existe fecha de caducidad de la firma electrónica'
        });
        return;
      }

      if (strCodigo === '18' || strCodigo === '') { // factura
        // norma
        strTipoFac = 'FCN';
      }
      if (strCodigo === '01') {// factura de exportación
        strTipoFac = 'FCE';
      }
      if (strCodigo === '41') { // factura De reembolso
        strTipoFac = 'FCR';
      }
      if (strCodigo === '370') {// factura de operadora de
        // transporte
        strTipoFac = 'FOT';
      }
      if (strCodigo === '371') {// Comprobante Socio A Operadora
        // De Transporte
        strTipoFac = 'COT';
      }

      const item: Eletrndocumento = {};
      item.TRNDOC_NUMDOC = strNumDocD;
      item.TRNDOC_TIPO = strTipoFac;
      item.TRNDOC_ESTADO = 'VA';
      item.TRNDOC_RESULTADO = '';
      item.TRNDOC_UBICAARCH = '';
      item.TRNDOC_SECUENCIAL = '';
      item.COM_CODIGO = '01';
      item.TRNDOC_TIPOAMB_FE = this.datosfe.COM_TIPOAMBFACTELEC;

      this.encfacService.insertarEleTrnDocumento(item).subscribe(async () => {
        this.messageService.add({
          key: this.messageKey,
          severity: 'success',
          summary: 'Información',
          detail: 'Proceso de firma electrónica iniciado correctamente.'
        });
        const data = await this.utilitariosService.procesoFirmaElectronica(strNumDocD, strFecha);
        if (data !== 'NOAUTORIZADO') {
          this.messageService.add({
            key: this.messageKey,
            severity: 'warn',
            summary: 'Información',
            detail: data
          });
          this.consultarFacturas(strNumDocD);
        }else{
          this.spin = true;
          await setTimeout(async() => {
            
            await this.refrescarDatosFE('proceso');
           this.spin = false;
           this.messageService.add({
            key: this.messageKey,
            severity: 'success',
            summary: 'Información',
            detail: 'Documento procesado'
          });
          }, 6000);
          
        }
        
      }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
        this.messageService.add({
          key: this.messageKey,
          severity: 'error',
          summary: 'Error',
          detail: mensaje
        });
      });
    }

  }

  onHideEvent() {
    this.cancelar();
  }

  cancelar() {
    //CDPJ
    const encfacnumero = this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO;
    this.limpiarDatos();
    this.consultarFacturas(encfacnumero);

    //CDPJ
  }

  salirDialog() {
    this.displayDialogBusqueda = false;
    this.displayDialogBusquedaFast = false;
    this.displayDialogPagoCredito = false;
    this.displayDialogAutorizacion = false;
    this.displayDialogRazon = false;
    this.displayAsiContable = false;
    this.displayDialogReportes = false;
    this.displayDialogCuotas = false;
    this.displayDialogSeriales = false;
    /*this.displayDialogAsiento = false;
    this.displayDialogDescuento = false;    
    this.displayDialogSeriales = false;
    
    this.displayDialogLotes = false;
    this.displayDialogLog = false;
    this.displayDialogDatosVentaArticulo = false;*/
    this.displayDialogSupervisor = false;


  }

  cerrarPago() {
    if (this.auxpago === 'presentarPagoDirecto') {
    }

    if (this.auxpago === 'guardarFactura') {
      this.guardarFactura2();
    }

    if (this.auxpago === 'PagoFacturaContado') {
      this.PagoFacturaContado2();
    }


    this.displayDialogPagoContado = false;

  }

  salirNuevoCliente(event) {
    let txtcodcli;
    let txtnomcli;
    this.displayDialogNuevoCliente = false;
    this.encptovtaService.encfac[this.indicador].CLI_CODIGO = event.code;
    this.encptovtaService.encfac[this.indicador].CLI_NOMBRE = event.name;
    txtcodcli = this.encptovtaService.encfac[this.indicador].CLI_CODIGO;
    txtnomcli = this.encptovtaService.encfac[this.indicador].CLI_NOMBRE;

    if (txtcodcli === '' && txtnomcli === '') {
      this.encptovtaService.encfac[this.indicador].CLI_CODIGO = this.strCodConsFinal;
      this.buscarCliente(false);
    }
    this.nuevaLinea()//CDPJ
  }


  obtenerAutorizacionFact(strNumFact) {
    let strNumAut = "";

		/*
			strNumAut = ejecutaSentencia.encontrarRegistro("ENCFAC_AUTORIZACION", "VEN_ENCFAC", "ENCFAC_NUMERO = '" + 
					strNumFact + "' AND COM_CODIGO = '" + Conexion.COMPANIA + "'");
		*/

    return strNumAut;
  }

  async insertarDetalleFactura(strNumFact) {
    let bolInsertar = true;

    let fila = 0;
    for (const item of this.encptovtaService.detfac[this.indicador]) {
      fila++;
      const itemdet: VenDetfacZ = {};
      itemdet.ENCFAC_NUMERO = strNumFact;
      itemdet.DETFAC_LINEA = item.DETFAC_LINEA;//CDPJ
      itemdet.ENCFAC_NUMERO = item.ENCFAC_NUMERO; // nùmero de la factura
      itemdet.COM_CODIGO = '01'; // compania
      itemdet.DETFAC_LINEA_3 = item.DETFAC_LINEA_3; // línea de detalle
      itemdet.DETFAC_TIPODET = item.DETFAC_TIPODET; // tipo de detalle
      itemdet.BOD_CODIGO = item.BOD_CODIGO; // bodega
      itemdet.DETFAC_CODIGO = item.DETFAC_CODIGO; // código del item
      itemdet.DETFAC_DESCRIPCION = item.DETFAC_DESCRIPCION; // descripción
      itemdet.DETFAC_TRIBIVA = item.DETFAC_TRIBIVA; // si tributa iva
      itemdet.DETFAC_TRIBICE = item.DETFAC_TRIBICE; // si tributa ice
      itemdet.DETFAC_UNIDAD = item.DETFAC_UNIDAD; // unidad
      itemdet.DETFAC_CANTIDAD = Number(item.DETFAC_CANTIDAD); // cantidad

      if (item.DETFAC_SIGNO !== "-")
        itemdet.DETFAC_PRECIO = Number(item.DETFAC_PRECIO); // precio
      else
        itemdet.DETFAC_PRECIO = Number(item.DETFAC_PRECIO) * (-1); // precio
      itemdet.DETFAC_DESCUENTO = Number(item.DETFAC_DESCUENTO); // descuento
      if (item.DETFAC_SIGNO !== "-")
        itemdet.DETFAC_TOTAL = Number(item.DETFAC_TOTAL); // total
      else
        itemdet.DETFAC_TOTAL = Number(item.DETFAC_TOTAL) * (-1); // total
      itemdet.DETFAC_IVA = Number(item.DETFAC_IVA); // iva
      itemdet.DETFAC_ICE = Number(item.DETFAC_ICE); // ice
      itemdet.DETFAC_LISTA = item.DETFAC_LISTA; // lista de precios
      if (item.DETFAC_SIGNO !== "-")
        itemdet.DETFAC_BASEIVA = Number(item.DETFAC_BASEIVA); // base  iva
      else
        itemdet.DETFAC_BASEIVA = Number(item.DETFAC_BASEIVA * (-1)); // base iva
      itemdet.DETFAC_BASEICE = Number(item.DETFAC_BASEICE); // base ice;
      itemdet.DETFAC_BASECERO = Number(item.DETFAC_BASECERO); // base cero;
      itemdet.DETFAC_PORCEICE = Number(item.DETFAC_PORCEICE); // porcentaje ice
      itemdet.ENCCOT_NUMERO = item.ENCCOT_NUMERO;
      itemdet.DETCOT_LINEA = Number(item.DETCOT_LINEA);
      itemdet.ENCPED_NUMERO = item.ENCPED_NUMERO; // número de pedido
      itemdet.DETPED_LINEA = Number(item.DETPED_LINEA); // línea del pedido
      itemdet.ENCGRE_NUMERO = item.ENCGRE_NUMERO; // número de la guía de remisión
      itemdet.DETGRE_LINEA = Number(item.DETGRE_LINEA); // línea de la guía
      itemdet.DETFAC_UNIDAD = item.DETFAC_UNIDAD; // unidad de venta
      itemdet.DETFAC_TIPO = "";
      itemdet.DETFAC_CODIGOREF = "";
      itemdet.ENCFAC_NUMERO = strNumFact; // número de la factura
      itemdet.DETFAC_PROMOCION = item.DETFAC_PROMOCION; // si es promoción
      itemdet.DETFAC_ORDEN = fila;
      itemdet.DETFAC_LINORGPROM = Number(item.DETFAC_LINORGPROM);
      itemdet.MAEPRM_CODIGO = Number(item.MAEPRM_CODIGO); // revisar
      itemdet.DETFAC_FECHAREALIZACION = item.DETFAC_FECHAREALIZACION;
      itemdet.DETFAC_PRECIOC = Number(item.DETFAC_PRECIOC); // precio del componente
      itemdet.CEN_CODIGO = item.CEN_CODIGO === null ? '' : item.CEN_CODIGO; // centro de costos
      itemdet.DETFAC_BULTO = ""; // bulto
      itemdet.DETFAC_CANTIDADB = "0"; // bulto
      itemdet.DETFAC_TIPOSRI = item.DETFAC_TIPOSRI; // tipo para formulario del sri
      itemdet.DETFAC_SIGNO = item.DETFAC_SIGNO; // SIGNO
      itemdet.DETFAC_ARTACAMBIO = item.DETFAC_ARTACAMBIO; // cambio de artículo
      itemdet.ORDTRA_NUMERO = ""; // orden de compra
      itemdet.DETORDTRA_LINEA = "0"; // linea
      itemdet.ENCREQ_NUMERO = ""; // requisicion
      itemdet.DETREQ_LINEA = "0"; // linea
      itemdet.DETFAC_BASENOOBJIVA = "0.0"; // 
      itemdet.DETFAC_TRIBASENOOBJIVA = "";
      itemdet.ENCMAT_NUMERO = ""; // matricula        
      itemdet.DETMAT_CODIGO = "0"; // matricula        
      itemdet.DETFAC_ORDENLOTE = "0"; // orden de lote        
      itemdet.DETFAC_CANTIDADUND = "0.0"; // cantidad en unidades
      //CDPJ
      itemdet.DETFAC_PORIVA=Number(item.DETFAC_PORIVA);
      itemdet.TARIIVA_CODIGO=item.TARIIVA_CODIGO;
      //CDPJ

      try {
        const dd = await this.encptovtaService.insertarDetalleFactura(itemdet);
        for (const rs of dd) {
          if (itemdet.DETFAC_LINEA_3 === 0) {
            item.DETFAC_LINEA_3 = rs.DETFAC_LINEA;
            this.auditoriagral.registrarAuditoria('VEN_DETFAC', strNumFact + "/" + item.DETFAC_CODIGO, 'I',
              '', '01', '', '', '', '').subscribe();
              this.auditoriagral.registrarAuditoria('VEN_DETFAC', strNumFact + '/' + item.DETFAC_CODIGO + '/' 
                  +'PorIva:'+ item.DETFAC_PORIVA + '/' + 'CodTarIva:'+item.TARIIVA_CODIGO, 'I', 
                  '', '01', '', '', '', '').subscribe(() => {
                  });
          } else{
            this.auditoriagral.registrarAuditoria('VEN_DETFAC', strNumFact + "/" + item.DETFAC_CODIGO, 'A',
            '', '01', '', '', '', '').subscribe();
            this.auditoriagral.registrarAuditoria('VEN_DETFAC', strNumFact + '/' + item.DETFAC_CODIGO + '/' 
                +'PorIva:'+ item.DETFAC_PORIVA + '/' + 'CodTarIva:'+item.TARIIVA_CODIGO, 'A', 
                '', '01', '', '', '', '').subscribe(() => {
                });
          }
            
        }

      } catch (e) {
        bolInsertar = false;
      }
    }

    return bolInsertar;
  }

  obtenerNumeracionFact(strNumFact) {
    /*let strSql = "SELECT * FROM VEN_ENCFAC WHERE ENCFAC_NUMERO = '"
        + strNumFact + "' AND COM_CODIGO = '" + Conexion.COMPANIA + "'";
 
  	
      rs = ejecutaSentencia.EjecutaSentencia(strSql);
 
      if (rs.next()) {
        strNemonico = rs.getString("ENCFAC_NEMONICO");
        strSerie = rs.getString("ENCFAC_SERIE");
      }*/

  }

  actualizarNumeracionPV(strClase, strTipoDoc,
    strNemonico, strSerie) {
    let bolActualizar = false;
    /*EjecutarSP ejecutaSentencia = new EjecutarSP();
    ResultSet rs = null;
    String strProceso = "SACI_PCK_MAENUMERACIONG.SACI_SP_actnumerosiguientePV";
  
    String[][] strParam = new String[5][2];
  
    strParam[0][0] = strClase;
    strParam[1][0] = Conexion.COMPANIA;
    strParam[2][0] = strTipoDoc;
    strParam[3][0] = strNemonico;
    strParam[4][0] = strSerie;
  
    for (int i = 0; i <= 4; i++)
      strParam[i][1] = "S";
  
    
      rs = ejecutaSentencia.EjecutaSP(strProceso, 6, strParam, true);
  
      if (rs.next()) {
        if (rs.getInt(1) == 0)
          bolActualizar = true;
        else if (rs.getInt(1) == 1)
          bolActualizar = false;
      }
    */

    return bolActualizar;
  }

  obtenerUsuarioFact(strNumDoc) {
    let strUsuario = "";

    /*
      strUsuario = ejecutaSentencia.encontrarRegistro(
          "USU_IDENTIFICACION", "VEN_ENCFAC", "ENCFAC_NUMERO ='"
              + strNumDoc + "' AND COM_CODIGO = '"
              + Conexion.COMPANIA + "'");
    */

    return strUsuario;
  }

  extraerPedidos(strNumFact) {
    /*
      String strNumPed = ejecutaSentencia.encontrarRegistro(
          "COUNT(DISTINCT ENCPED_NUMERO)", "VEN_DETFAC",
          "ENCFAC_NUMERO = '" + strNumFact + "' AND COM_CODIGO = '"
              + Conexion.COMPANIA
              + "' AND NOT(ENCPED_NUMERO IS NULL)");
 
      int intNumPed = 0;
 
      if (strNumPed.length() > 0)
        intNumPed = Integer.parseInt(strNumPed);
 
      if (intNumPed > 0) {
        String strSql = "SELECT DISTINCT ENCPED_NUMERO, DETPED_LINEA FROM VEN_DETFAC";
        strSql = strSql + " WHERE ENCFAC_NUMERO = '" + strNumFact + "'";
        strSql = strSql + " AND COM_CODIGO = '" + Conexion.COMPANIA
            + "' AND NOT(ENCPED_NUMERO IS NULL)";
 
        rs = ejecutaSentencia.EjecutaSentencia(strSql);
 
        while (rs.next()) {
          VenDetFac item = new VenDetFac();
 
          item.setEncPedNumero(rs.getString("ENCPED_NUMERO"));
          item.setDetPedLinea(rs.getInt("DETPED_LINEA"));
 
          listaPedidos.add(item);
        }
      }
    */
  }

  verificarActualizarEstPed() {
    /*String strProceso = "VEN_PCK_ENCPED.VEN_sp_VerificarPedido ";
    String[][] strParam = new String[2][2];
 
    for (int index = 0; index < listaPedidos.size(); index++) {
      strParam[0][0] = listaPedidos.get(index).getEncPedNumero();
      strParam[0][1] = "S";
      strParam[1][0] = Conexion.COMPANIA;
      strParam[1][1] = "S";
 
      try {
        ejecutaSentencia.EjecutaSP(strProceso, 2, strParam, false);
      } catch (SQLException e) {
        e.printStackTrace();
        ErrorDialog.openError(shell, "Error",
            "No se puede actualizar el estado de los pedidos",
            Utilidades.createStatus(e));
      } finally {
        ejecutaSentencia.cierraResultSet();
      }
    }*/
  }

  actualizarFechaEntregaPed() {
    /*
    for (int index = 0; index < listaPedidos.size(); index++) {
      // extraer la fecha de entrega
      String strSql = "SELECT DISTINCT A.ENCFAC_NUMERO, A.ENCFAC_FECHAEMISION AS FECHAEMISION FROM VEN_ENCFAC A, VEN_DETFAC B ";
      strSql = strSql + " WHERE A.ENCFAC_NUMERO=B.ENCFAC_NUMERO";
      strSql = strSql
          + " AND A.COM_CODIGO=B.COM_CODIGO AND B.ENCPED_NUMERO = '"
          + listaPedidos.get(index).getEncPedNumero() + "'";
      strSql = strSql + " AND B.COM_CODIGO = '" + Conexion.COMPANIA + "'";
      strSql = strSql
          + " AND A.ENCFAC_ESTADO<>'A' ORDER BY A.ENCFAC_FECHAEMISION ASC";
 
      String strFechaEnt = "";
      try {
        rs = ejecutaSentencia.EjecutaSentencia(strSql);
 
        String strProceso = "VEN_PCK_ENCPED.VEN_sp_ActualizaFechaEntrega ";
        String[][] strParam = new String[3][2];
 
        if (rs.next()) {
          SimpleDateFormat formatoFecha = new SimpleDateFormat(
              "yyyy-MM-dd");
          int[] fechadesglosada = new int[3];
 
          try {
            fechadesglosada = Utilidades.procesarFecha(formatoFecha
                .parse(rs.getString("FECHAEMISION")));
            strFechaEnt = Utilidades.ConvierteFechaAString(
                fechadesglosada[0], fechadesglosada[1],
                fechadesglosada[2]);
 
          } catch (ParseException e1) {
            e1.printStackTrace();
            MessageDialog.openError(shell, "Error",
                "No se puede obtener la fecha de emisión.");
          }
        }
 
        strParam[0][0] = listaPedidos.get(index).getEncPedNumero();
        strParam[0][1] = "S";
        strParam[1][0] = strFechaEnt;
        strParam[1][1] = "S";
        strParam[2][0] = Conexion.COMPANIA;
        strParam[2][1] = "S";
 
        ejecutaSentencia.EjecutaSP(strProceso, 3, strParam, false);
      } catch (SQLException e) {
        e.printStackTrace();
        ErrorDialog.openError(shell, "Error",
            "No se puede actualizar la fecha de entrega "
                + "en el pedido", Utilidades.createStatus(e));
      } finally {
        ejecutaSentencia.cierraResultSet();
        UtilidadesConexion.liberarResultset(rs);
      }
    }*/
  }

  verificarEstadoFactura() {
    if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO === "ANULADA") {
      this.habilitarBotones(true, false, false, false);
      document.getElementById('contorno').style.pointerEvents = 'none';
      this.boolpago = true;//CDPJ
      this.aggrid.bloquearGrid();
      /*
      groupEnc.setEnabled(false);
      toolBarDetalle.setEnabled(false);
      mnuNuevaLinDet.setEnabled(false);
      mnuElimLinDet.setEnabled(false);
      // groupTipoDet.setEnabled(false);
      mnuAgregarPed.setEnabled(false);
      mnuAgregarProm.setEnabled(false);
      mnuActCambioArt.setEnabled(false);
      mnuEditarDet.setEnabled(false);
      mnuImpNV.setEnabled(false);
      lblObsAnulada.setVisible(true);*/
    } else if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO === "PENDIENTE") {
      this.habilitarBotones(true, false, true, false);
      document.getElementById('contorno').style.pointerEvents = 'all';
      this.boolpago = false;//CDPJ
      this.aggrid.habilitarGrid();
      /*
      groupEnc.setEnabled(true);
      toolBarDetalle.setEnabled(true);
      mnuNuevaLinDet.setEnabled(true);
      toolBarDetalle.getItem(1).setEnabled(true);
      mnuElimLinDet.setEnabled(true);
      // groupTipoDet.setEnabled(true);
      mnuAgregarPed.setEnabled(true);
      mnuAgregarProm.setEnabled(true);
      mnuActCambioArt.setEnabled(false);
      mnuEditarDet.setEnabled(true);
      mnuImpNV.setEnabled(false);
*/
      const BLOQPVIMP = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'BLOQPVIMP').CFG_VALOR1;
      if (BLOQPVIMP == 1) {
        if (this.lblImpresosetVisible === true) {
          this.habilitarBotones(true, false, false, false);
          document.getElementById('contorno').style.pointerEvents = 'none';
          this.boolpago = true;//CDPJ
          this.aggrid.bloquearGrid();
          /*
        groupEnc.setEnabled(false);
        toolBarDetalle.setEnabled(false);
        mnuNuevaLinDet.setEnabled(false);
        mnuElimLinDet.setEnabled(false);
        // groupTipoDet.setEnabled(false);
        mnuAgregarPed.setEnabled(false);
        mnuAgregarProm.setEnabled(false);
        mnuActCambioArt.setEnabled(false);
        mnuEditarDet.setEnabled(false);
        mnuImpNV.setEnabled(false);
        btnPagoCred.setEnabled(true);
        btnPagoFactura.setEnabled(true);*/
        }
      }

    } else if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO === "PAGADA"
      || this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO === "CAMBIO"
      || this.encptovtaService.encfac[this.indicador].ENCFAC_AUTORIZACION_FE !== "") {
      this.habilitarBotones(true, false, false, false);
      document.getElementById('contorno').style.pointerEvents = 'none';
      this.boolpago = true;//CDPJ
      this.aggrid.bloquearGrid();
      /*
  groupEnc.setEnabled(false);
  toolBarDetalle.setEnabled(false);
  mnuNuevaLinDet.setEnabled(false);
  mnuElimLinDet.setEnabled(false);
  // groupTipoDet.setEnabled(false);
  mnuAgregarPed.setEnabled(false);
  mnuAgregarProm.setEnabled(false);
  mnuActCambioArt.setEnabled(false);
  mnuEditarDet.setEnabled(false);
  mnuImpNV.setEnabled(false);*/
    }

    else if (this.encptovtaService.encfac[this.indicador].ENCFAC_AUTORIZACION_FE !== "") {
      this.habilitarBotones(true, false, false, false);
      document.getElementById('contorno').style.pointerEvents = 'none';
      this.boolpago = true;//CDPJ
      this.aggrid.bloquearGrid();
      /*groupEnc.setEnabled(false);
      toolBarDetalle.setEnabled(false);
      mnuNuevaLinDet.setEnabled(false);
      mnuElimLinDet.setEnabled(false);
      // groupTipoDet.setEnabled(false);
      mnuAgregarPed.setEnabled(false);
      mnuAgregarProm.setEnabled(false);
      mnuActCambioArt.setEnabled(false);
      mnuEditarDet.setEnabled(false);
      mnuImpNV.setEnabled(false);*/
    }

  }

  actualizarDespachoPedido() {
    /*String strProceso = "VEN_PCK_DETPED.VEN_SP_ACTUALIZAR_DESPACHOPED";
    String[][] strParam = new String[3][2];
 
    for (int fila = 1; fila <= dlgPtoVta.listaPedidos.size(); fila++) {
      VenDetFac item = dlgPtoVta.listaPedidos.get(fila - 1);
 
      strParam[0][0] = item.getEncPedNumero();
      strParam[0][1] = "S";
      strParam[1][0] = String.valueOf(item.getDetPedLinea());
      strParam[1][1] = "I";
      strParam[2][0] = Conexion.COMPANIA;
      strParam[2][1] = "S";
 
  	
        ejecutaSentencia.EjecutaSP(strProceso, 3, strParam, false);
  	
    }*/
  }

  async eliminarFactura(strNumFact, rollback) {
    let bolEliminar = false;

    await this.encptovtaService.eliminarFactura(strNumFact);

    this.extraerPedidos(strNumFact);

    this.verificarActualizarEstPed();
    this.actualizarFechaEntregaPed();

    this.actualizarDespachoPedido();

    if (this.encptovtaService.encfac[this.indicador].ASI_NRO.length > 0) {
      this.anularAsientoContable(this.encptovtaService.encfac[this.indicador].ASI_NRO);
    }
    const item = this.encptovtaService.detfac[this.indicador];

    if (!this.bolNuevo)
      this.limpiarDatos();

    document.getElementById('contorno').style.pointerEvents = 'none';
    this.boolpago = true;//CDPJ
    this.aggrid.bloquearGrid();
    /*groupEnc.setEnabled(false);
    groupDet.setEnabled(false);*/
    bolEliminar = true;
    this.encptovtaService.encfac[this.indicador].ENCFAC_AUTORIZACION = '';

    if (rollback == false) {
      const USURAZELI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'USURAZELI').CFG_VALOR1;
      if (USURAZELI == 1) {
        this.auditoriagral.registrarAuditoria('VEN_ENCFAC', strNumFact + "/VEN" + this.StrUsuRazEli, 'E',
          '', '01', '', '', '', '').subscribe();
        //CDPJ
        for (const item2 of item) {
          this.auditoriagral.registrarAuditoria('VEN_DETFAC', strNumFact + "/" + item2.DETFAC_CODIGO, 'E',
            '', '01', '', '', '', '').subscribe();
        }
        //CDPJ
      } else {
        this.auditoriagral.registrarAuditoria('VEN_ENCFAC', strNumFact + "/VEN", 'E',
          '', '01', '', '', '', '').subscribe();
        //CDPJ
        for (const item2 of item) {
          this.auditoriagral.registrarAuditoria('VEN_DETFAC', strNumFact + "/" + item2.DETFAC_CODIGO, 'E',
            '', '01', '', '', '', '').subscribe();
        }
        //CDPJ
      }
    }

    return bolEliminar;
  }

  async verificaInfDetalle() {
    let bolVerificar = false;

    if (this.encptovtaService.detfac[this.indicador].length == 0) {
      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Información',
        detail: "La factura no tiene items en el" + " detalle."
      });

      return bolVerificar = true;
    }
    let fila = 0;
    for (const item of this.encptovtaService.detfac[this.indicador]) {
      let strCodigo = "";
      fila++;
      if (item.DETFAC_TIPODET !== "*") { // si no es un comentario que verifique			
        if (item.DETFAC_CODIGO.length > 0) { // verifica la cantidad igual a cero
          if (item.DETFAC_TIPODET !== "*" && item.DETFAC_TIPODET !== "C") {
            if (Number(item.DETFAC_CANTIDAD) == 0) {
              this.messageService.add({
                key: this.messageKey,
                severity: 'error',
                summary: 'Información',
                detail: "No se puede ingresar un "
                  + "artículo con cantidad cero."
              });
              bolVerificar = true;
              break;
            }
          }

          // verificar que la descripción no esté vacía
          if (item.DETFAC_DESCRIPCION.length == 0) {
            this.messageService.add({
              key: this.messageKey,
              severity: 'error',
              summary: 'Información',
              detail: "La descripción del item "
                + "no puede estar en blanco."
            });
            bolVerificar = true;
            break;
          }

          // verifica la existencia del código
          if (item.DETFAC_TIPODET === "A") { // ARTICULOS			            
            const rsdata: any = await this.encptovtaService.encontrarArticulo(item.DETFAC_CODIGO);

            if (rsdata !== null) {
              for (const rs of rsdata) {
                strCodigo = rs.ART_CODIGO;
              }
            }

            if (strCodigo.length == 0) {
              this.messageService.add({
                key: this.messageKey,
                severity: 'error',
                summary: 'Información',
                detail: "El código del artículo de la fila " + fila
                  + " no existe."
              });
              bolVerificar = true;
              break;
            }
          }
          //CDPJ
           //CDPJ
           if(item.TARIIVA_CODIGO === null || item.TARIIVA_CODIGO === undefined || item.TARIIVA_CODIGO === ''){
            this.messageService.add({
              key: this.messageKey,
              severity: 'error',
              summary: 'Error en IVA',
              detail: 'Falta seleccionar el tipo de porcentaje del código '+item.DETFAC_CODIGO
            });
            bolVerificar = true;
              break;                     
        }
          else if(item.DETFAC_PORIVA === null || item.DETFAC_PORIVA === undefined || item.DETFAC_PORIVA === ''){
            this.messageService.add({
              key: this.messageKey,
              severity: 'error',
              summary: 'Error en IVA',
              detail: 'El campo %IVA no puede estar vacio, a menos que sea un comentario'
            });
            bolVerificar = true;
              break;          
          
        }else if(item.DETFAC_TRIBIVA === 'S' && item.TARIIVA_CODIGO !== null && item.TARIIVA_CODIGO !== undefined && item.TARIIVA_CODIGO !== ''){
          let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === item.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) !== 0 && Number(item.DETFAC_PORIVA) !== 0 &&  Number(element.TARIIVA_PORCENTAJE) === Number(item.DETFAC_PORIVA))
          if(data === undefined || data === null){
            this.messageService.add({
              key: this.messageKey,
              severity: 'error',
              summary: 'Error en IVA',
              detail: 'El %IVA con el porcentaje '+item.DETFAC_PORIVA+' no es parte de los porcentajes que puede seleccionar cuando se tributa IVA.'
            });
            bolVerificar = true;
              break;            
          }
        }else if(item.DETFAC_TRIBIVA === 'N'
        && item.TARIIVA_CODIGO !== null && item.TARIIVA_CODIGO !== undefined && item.TARIIVA_CODIGO !== ''){
          let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === item.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) === 0 && Number(item.DETFAC_PORIVA) === 0 &&  Number(element.TARIIVA_PORCENTAJE) === Number(item.DETFAC_PORIVA))
          console.log(data)
          console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === item.TARIIVA_CODIGO))
          if(data === undefined || data === null){
            this.messageService.add({
              key: this.messageKey,
              severity: 'error',
              summary: 'Error en IVA',
              detail: 'El %IVA con el porcentaje '+item.DETFAC_PORIVA+' no es parte de los porcentajes que puede seleccionar cuando no se tributan IVA.'
            });
            bolVerificar = true;
              break;
          }
        }
          //CDPJ
          //CDPJ

        } else { // verifica que el código está en blanco        
          this.messageService.add({
            key: this.messageKey,
            severity: 'error',
            summary: 'Información',
            detail: "Debe ingresar el código en el "
              + "detalle de la factura."
          });
          bolVerificar = true;
          break;
        }

        if (item.ENCPED_NUMERO != null
          && item.ENCPED_NUMERO != ''
          && item.DETFAC_TIPODET === "A") // si es pedido que verifique la existencia del artículo
        {
          if (await this.verificarExistenciaArticulo(item) === false) {
            bolVerificar = true;
            // dlgPtoVta.getTablaFactura().redraw();
            break;
          }
        }

        // según configuración verificar que no se facture con precio cero
        if (item.DETFAC_PROMOCION !== "1") {
          const FACNOPCER = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FACNOPCER').CFG_VALOR1;
          if (FACNOPCER == 1) {
            if (item.DETFAC_PRECIO == 0) {
              this.messageService.add({
                key: this.messageKey,
                severity: 'error',
                summary: 'Información',
                detail: "No se puede facturar con precio cero."
              });
              bolVerificar = true;
              break;
            }
          }
        }
      }
    }

    return bolVerificar;
  }

  async verificarExistenciaArticulo(itemDet) {
    let bolVerificar = true;
    let dblCantidadDisp = 0;

    const PVFACEXNEG = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PVFACEXNEG').CFG_VALOR1;
    if (PVFACEXNEG == 0) { // no permitir facturar con existencias negativas

      this.dblExistArt = Number(await this.obtenerExistenciaArticulo(itemDet.DETFAC_CODIGO,
        itemDet.BOD_CODIGO, this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO));

      if (this.dblExistArt < 0 && itemDet.ENCGRE_NUMERO.length == 0
        && itemDet.DETFAC_SIGNO !== "-") {
        let dblCantUniCosteo = await this.cantArtDetFacEnUniCosteo(itemDet.DETFAC_CODIGO,
          itemDet.DETFAC_UNIDAD, itemDet.DETFAC_CANTIDAD);
        // if (dblExistArt + dblCantUniCosteo >= 0) {
        if (this.dblExistArt >= 0) {
          if (itemDet.DETFAC_TIPODET !== "Y") // si no es componente
          {
            dblCantidadDisp = this.dblExistArt + dblCantUniCosteo;
            dblCantidadDisp = await this.cantArtDetFacEnUniCosteo(itemDet.DETFAC_CODIGO, itemDet.DETFAC_UNIDAD, dblCantidadDisp);
          }
        } else {
          // transformar a unidades de venta
          let strUniCosteo = await this.obtenerUnidadCosteoArticulo(itemDet.DETFAC_CODIGO);
          let dblExistencia = await this.obtenerExistenciaArticulo(itemDet.DETFAC_CODIGO, itemDet.BOD_CODIGO,
            this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO) + dblCantUniCosteo;
          dblCantidadDisp = await this.transformarUnidad(strUniCosteo, itemDet.DETFAC_UNIDAD, dblExistencia);
        }
        this.messageService.add({
          key: this.messageKey,
          severity: 'error',
          summary: 'Información',
          detail: "La cantidad ingresada es mayor a la existencia del artículo."
        });
        itemDet.DETFAC_CANTIDAD = dblCantidadDisp;
        return false;
      } else
        dblCantidadDisp = this.dblExistArt;
    }
    return bolVerificar;
  }

  async transformarUnidad(strUniOrg, strUniDest, dblCantidad) {
    let dblCant = dblCantidad;

    const data: any = await this.encptovtaService.transformaunidad(strUniOrg, strUniDest, dblCantidad)

    for (const rs of data) {
      dblCant = Number(rs.EXISTENCIA);
    }

    return dblCant;
  }

  async actualizarDocOrg() {

    await this.encptovtaService.actualizarestado(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO, this.strNumNVOrg)

    await this.encptovtaService.actualizarorgimp(this.strNumNVOrg, this.encptovtaService.encfac[this.indicador].ASI_NRO,
      this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);


  }

  async verificarExistProm() {
    let fila = 0;
    for (const item of this.encptovtaService.detfac[this.indicador]) {
      fila++;

      if (item.DETFAC_PROMOCION === "1") {
        this.dblExistArt = Number(await this.obtenerExistenciaArticulo(item.DETFAC_CODIGO,
          item.BOD_CODIGO, item.ENCFAC_NUMERO));
        if (this.dblExistArt <= 0) {
          if (await this.eliminarDetalleFact(item)) {
            /*listaDetFact.remove(item);
            this.colorearFilasEspeciales(); // coloca los colores de promociones
            dlgPtoVta.getTablaFactura().redraw();*/
          }
        }
      }
    }
  }

  async eliminarDetalleFact(item) {
    let bolEliminar = true;

    const USURAZELI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'USURAZELI').CFG_VALOR1;
    if (USURAZELI == 1) {
      /*DlgUsuarioRazon dlgUsuRaz = new DlgUsuarioRazon(dlgPtoVta
            .getParent(), dlgPtoVta, "PtoVta");
        dlgUsuRaz.open();*/
    }

    if (item.ENCFAC_NUMERO.length > 0) {

      await this.encptovtaService.eliminarDetalleFact(item);

      /*  this.eliminarSeriales(item.getEncFacNumero(), "VEN",
                Long.valueOf(item.getDetFacLinea()), "L",
                "");
 
        if (dlgPtoVta.getHmpConfiguracion().get("USURAZELI") == 1) {
          SaciMaeAuditoriaGral.registrarAuditoria("VEN_DETFAC",
              item.getEncFacNumero() + "/"
                  + item.getDetFacCodigo() + "/VEN"
                  + dlgPtoVta.StrUsuRazEli, "E",
              Conexion.usuarioConectado, Conexion.COMPANIA,
              " ", " ", " ", FechaHoraSistema
                  .fechaHraSistema());
          dlgPtoVta.bolNuevo = false;
 
        } else {
          SaciMaeAuditoriaGral.registrarAuditoria("VEN_DETFAC",
              item.getEncFacNumero() + "/"
                  + item.getDetFacCodigo() + "/VEN", "E",
              Conexion.usuarioConectado, Conexion.COMPANIA,
              " ", " ", " ", FechaHoraSistema
                  .fechaHraSistema());
          dlgPtoVta.bolNuevo = false;
        }
      */
    }

    return bolEliminar;
  }


  async controlarEstadoCliente() {
    let bolActivo = true;
    let strEstadoCli = "";

    const data: any = await this.encptovtaService.encontrarEstadoCli(this.encptovtaService.encfac[this.indicador].CLI_CODIGO);

    for (const rs of data) {
      strEstadoCli = rs.CLI_ESTADO;
    }

    if (strEstadoCli === 'D') {
      this.confirmationService.confirm({
        message: 'El cliente '
          + this.encptovtaService.encfac[this.indicador].CLI_CODIGO
          + '  NO está activo. Desea Continuar facturando?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencptovta',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          const GENCLAAUT = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'GENCLAAUT').CFG_VALOR1;
          if (GENCLAAUT === 0) {
            this.strDialogo = 'frmVEN_ENCFAC';
            this.strObjeto = 'cmdclicodigo';
            this.intPestaniaCol = 0;
            this.usuarioSup = '';
            this.claveSup = '';
            this.bolSupervisor = false;
            this.displayDialogSupervisor = true;
          } else {
            this.claveAuth = '';
            this.autorizacionCodigo = 'FCCLDA';
            this.bolAutorizacion = false;
            this.displayDialogAutorizacion = true;
          }
          bolActivo = true;
        },
        reject: () => {
          this.confirmationService.close();
          this.encptovtaService.encfac[this.indicador].CLI_CODIGO = '';
          this.encptovtaService.encfac[this.indicador].CLI_NOMBRE = '';
          document.getElementById('CLI_CODIGO').focus();
          bolActivo = false;
        }
      });
    } else {
      bolActivo = false;
    }

    return bolActivo;
  }


  onHideSupervisor() {
    if (this.bolSupervisor === false) {
      if (this.strObjeto === 'cmdclicodigo') {
        this.messageService.add({
          key: 'encptovta',
          severity: 'error',
          summary: 'Información',
          detail: 'Debe ingresar correctamente'
            + ' la clave para la Facturación.'
        });
        this.encptovtaService.encfac[this.indicador].CLI_CODIGO = '';
        this.encptovtaService.encfac[this.indicador].CLI_NOMBRE = '';
        document.getElementById('CLI_CODIGO').focus();
      }
      if (this.strObjeto === 'CmdPagoACredito') {
        this.messageService.add({
          key: 'encptovta',
          severity: 'error',
          summary: 'Información',
          detail: "Debe ingresar correctamente"
            + " la clave de Supervisor para el pago a crédito."
        });
      }
    }
  }

  async verificarNDXCliente() {
    const NUMCHEPRO = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMCHEPRO').CFG_VALOR1;
    if (NUMCHEPRO == 0) // número de cheques protestados para alertar el pago
      return;

    const data: any = await this.encptovtaService.contarTrnBanDoc(this.encptovtaService.encfac[this.indicador].CLI_CODIGO);

    for (const rs of data) {
      if (rs.getInt(1) >= NUMCHEPRO)
        this.labelChqProt = true;
    }
  }


  validarClave() {
    if (this.usuarioSup.length > 0) {
      this.verificarUsuarioSupervisor();  // verifica si el usuario es supervisor
    } else {
      this.messageService.add({
        key: 'encptovta',
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese el usuario.'
      });
    }

  }

  verificarUsuarioSupervisor() {


    this.encfacService.ecVerificarUsuarioSupervisor(this.usuarioSup).subscribe(eC => {
      for (const rs of eC) {
        if (rs.COUN > 0) {
          if (this.claveSup.length > 0) {
            this.verificarClaveSupervisor();
          } else {
            this.messageService.add({
              key: 'encptovta',
              severity: 'error',
              summary: 'Información',
              detail: 'Ingrese la clave.'
            });
          }
        } else {
          this.messageService.add({
            key: 'encptovta',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario ' + this.usuarioSup + ' no es Supervisor o No existe'
          });
        }
      }
    });
  }

  verificarClaveSupervisor() {
    let strSql = 'SELECT NVL(S.USU_CLAVESUP,\'\') AS USUCLAVESUP FROM  SACI_MAEGUIOBJ O, SACI_MAEGUIOBJPER S WHERE ';
    strSql = strSql + ' O.GUIOBJ_CODIGO = S.GUIOBJ_CODIGO AND ';
    strSql = strSql + ' O.GUIOBJ_FORMA = \'' + this.strDialogo + '\' AND ';
    strSql = strSql + ' O.GUIOBJ_NOMBOBJ = \'' + this.strObjeto + '\' AND ';
    strSql = strSql + ' S.COM_CODIGO = \'01\' AND ';
    strSql = strSql + ' S.USU_IDENTIFICACION = \'' + this.encptovtaService.usuario.identificacion + '\' AND ';
    strSql = strSql + ' S.USU_IDENTIFICACIONSUP = \'' + this.usuarioSup + '\'';
    if (this.intPestaniaCol > 0) {
      strSql = strSql + ' AND O.GUIOBJ_TABCOL = ' + this.intPestaniaCol;
    }

    this.encfacService.ecVerificarClaveSupervisor(this.strDialogo, this.strObjeto, this.encptovtaService.usuario.identificacion, this.usuarioSup, this.intPestaniaCol).subscribe(data => {
      if (data.length === 0) {
        this.messageService.add({
          key: 'encptovta',
          severity: 'error',
          summary: 'Información',
          detail: 'Asigne la clave en los permisos ' +
            'especiales del usuario.'
        });
      } else {
        for (const rs of data) {
          if (rs.USUCLAVESUP !== null) {
            if (rs.USUCLAVESUP.length > 0) {
              if (rs.USUCLAVESUP === this.claveSup) {
                this.bolSupervisor = true;
                this.accionesSupervisor();
              } else {
                this.messageService.add({
                  key: 'encptovta',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'La clave está incorrecta..'
                });
              }
            } else {
              this.messageService.add({
                key: 'encptovta',
                severity: 'error',
                summary: 'Información',
                detail: 'Asigne la clave en los permisos ' +
                  'especiales del usuario.'
              });
            }
          } else {
            this.messageService.add({
              key: 'encptovta',
              severity: 'error',
              summary: 'Información',
              detail: 'Asigne la clave en los permisos ' +
                'especiales del usuario.'
            });
          }
        }
      }
    });
  }


  accionesSupervisor() {
    if (this.strObjeto === 'cmdclicodigo') {
      this.displayDialogSupervisor = false;
    }

    if (this.strObjeto === 'CmdPagoACredito') {
      this.PagoFacturaCredito2();
    }
  }

  async desplegarVendedor() {
    const VENFUNCLI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'VENFUNCLI').CFG_VALOR1;
    if (VENFUNCLI == 1) { // seleccionar el vendedor en funcion del cliente

      this.dblCliLimCredit = 0;
      let strVenPlaca = "";

      const data: any = await this.encptovtaService.desplegarvendedor(this.encptovtaService.encfac[this.indicador].CLI_CODIGO);
      let strVenCodigo = "";
      let strVenNombre = "";

      for (const rs of data) {

        strVenCodigo = rs.VEN_CODIGO;
        strVenNombre = rs.VEN_NOMBRE;
        strVenPlaca = rs.CLI_PLACA;

        this.dblCliLimCredit = Number(rs.CLI_LIMCREDIT);

        this.encptovtaService.encfac[this.indicador].VEN_CODIGO = strVenCodigo;
        this.encptovtaService.encfac[this.indicador].VEN_NOMBRE = strVenNombre;
        this.encptovtaService.encfac[this.indicador].ENCFAC_PLACA = strVenPlaca;
      }
    }

  }

  async deleteTrnDocumento(trnDocNumdoc, trnDocTipo) {
    let resultado = false;
    await this.encptovtaService.eliminarTrnDoc(trnDocNumdoc, trnDocTipo);
    return resultado;
  }

  async anularRegistro() {
    // verificar que el documento esté dentro del periodo contable

    const GENCLAAUT = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'GENCLAAUT').CFG_VALOR1;
    // verificar si hay una clave para anular la factura
    if (GENCLAAUT == 0) {
      /* if (Utilidades.verificarPermisosObjConClave("frmVEN_ENCPTOVTA",
         "CmdAnular", 0))
         if (!anularConClaveSup())
           return;*/
    } else {
      /*DlgAutorizacion autoriza = new DlgAutorizacion(shell, "ANULAPV");
      autoriza.open();

      if (!DlgAutorizacion.glbBolIsValid) {
        MessageDialog.openInformation(getParent(), "Información",
          "Debe ingresar correctamente"
          + " la clave para buscar la facturas.");
        return;
      }*/
    }

    // verificar si la factura tiene pagos realizados
    const pagos = await this.encptovtaService.existePagosCxC(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);
    if (pagos['0']['COUNT(TRNDOCUMENTO_NRODOC)'] > 0) {
      this.messageService.add({
        key: 'encptovta',
        severity: 'error',
        summary: 'Error',
        detail: 'La factura tiene registrado pagos.'
      });
      return;
    } else {
      this.indicarRazon();
    }

  }

  indicarRazon() {
    this.razonanular = '';
    this.displayDialogRazon = true;
  }


  cancelarAnularFac() {
    this.razonanular = '';
    this.displayDialogRazon = false;
  }

  anularFactura() {
    let strRazon = this.razonanular;
    if (strRazon === null || strRazon === undefined) {
      strRazon = '';
    }

    if (strRazon.length === 0) {
      this.messageService.add({
        key: 'encptovta',
        severity: 'warn',
        summary: 'Información',
        detail: 'Para anular la factura se debe ingresar la razón.'
      });
      return;
    }
    /*
        this.encfacService.anularFactura(strRazon, this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO).subscribe(() => {
          if (this.encptovtaService.encfac[this.indicador].ASI_NRO !== null) {
            if (this.encptovtaService.encfac[this.indicador].ASI_NRO.length > 0) {
              this.pagconservice.anularAsientoContable(this.encptovtaService.encfac[this.indicador].ASI_NRO).subscribe();
            }
          }
          this.auditoriagral.registrarAuditoria('VEN_ENCFAC', this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO + '/VEN', 'Anular',
            '', '01', '', '', '', '').subscribe();
    
          this.encfacService.ecAnularFactDistri(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO).subscribe();
          this.encfacService.ecVenInfoAdiFact(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO).subscribe();
    
          // pedidos
          this.extraerPedidos(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);
          this.extraerNumeroPedidos(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO, 'A');
    
          // guías de remisión
          this.extraerGuiasRem(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO, 'A');
          this.extraerNumeroGuias(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO, 'A');
    
          this.extraerNumeroOrdenesTrabajo(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);
    
    
          this.messageService.add({
            key: 'encptovta',
            severity: 'success',
            summary: 'Anular Factura',
            detail: 'Factura anulada correctamente'
          });
          this.encontrarFactura(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO, '');
          this.displayDialogRazon = false;
        });
    */
    //cdpj
    this.encfacService.anularFactura(strRazon, this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO).subscribe(() => {
      if (this.encptovtaService.encfac[this.indicador].ASI_NRO !== null) {
        if (this.encptovtaService.encfac[this.indicador].ASI_NRO.length > 0) {
          this.pagconservice.anularAsientoContable(this.encptovtaService.encfac[this.indicador].ASI_NRO).subscribe();
        }
      }
      this.auditoriagral.registrarAuditoria('VEN_ENCFAC', this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO + '/VEN', 'Anular',
        '', '01', '', '', '', '').subscribe();

      this.encfacService.ecAnularFactDistri(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO).subscribe();
      this.encfacService.ecVenInfoAdiFact(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO).subscribe();

      //pedidos
      this.extraerPedidos(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);
      //this.extraerNumeroPedidos(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO, 'A');

      //guías de remisión
      //this.extraerGuiasRem(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO, 'A');
      //this.extraerNumeroGuias(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO, 'A');

      //this.extraerNumeroOrdenesTrabajo(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);


      this.messageService.add({
        key: 'encptovta',
        severity: 'success',
        summary: 'Anular Factura',
        detail: 'Factura anulada correctamente'
      });
      this.consultarFacturas(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);
      this.displayDialogRazon = false;
    });
    //cdpj
  }

  async eliminarRegistro() {
    let strNumFact = this.encptovtaService.encfac[this.indicador].TXT_NUMERO;
    this.strNumFact = this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO;

    // verificar que el documento esté dentro del periodo contable
    if (this.permisoPtoVta !== null) {
      if (this.permisoPtoVta.PERTODO === 0) {
        if (this.permisoPtoVta.PERELIMACION === 0) {
          this.messageService.add({
            key: this.messageKey,
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario '
              + this.encptovtaService.usuario.identificacion
              + ', no tiene permiso de eliminación. Consulte con el Administrador del Sistema.'
          });
          return;
        }
      }
    }
    const PVNELINIMP = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PVNELINIMP').CFG_VALOR1;
    if (PVNELINIMP == 1) {
      if (this.lblImpresosetVisible == true) {
        this.messageService.add({
          key: this.messageKey,
          severity: 'error',
          summary: 'Información',
          detail: 'No se puede eliminar una Factura'
            + ' que ya está impresa.'
        });
        return;
      }
    }

    const GENCLAAUT = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'GENCLAAUT').CFG_VALOR1;
    if (GENCLAAUT == 0) {
      /*if (Utilidades.verificarPermisosObjConClave("frmVEN_ENCPTOVTA",
        "cmdborrar", 0))
        if (!eliminarConClaveSup())
          return;*/
    } else {
      /*DlgAutorizacion autoriza = new DlgAutorizacion(shell, "ELFCPV");
      autoriza.open();

      if (!DlgAutorizacion.glbBolIsValid) {
        MessageDialog.openInformation(getParent(), "Información",
          "Debe ingresar correctamente"
          + " la clave para buscar la facturas.");
        return;
      }*/
    }

    const pagos = await this.encptovtaService.existePagosCxC(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);
    if (pagos['0']['COUNT(TRNDOCUMENTO_NRODOC)'] > 0) {
      this.messageService.add({
        key: 'encptovta',
        severity: 'error',
        summary: 'Error',
        detail: 'La factura tiene registrado pagos.'
      });
      return;
    } else {
      this.eliminarRegistro2();
    }
  }

  eliminarRegistro2() {
    if (this.strNumFact.length > 0) {
      let strDoc = "Nota de Venta";

      if (this.chkFactura === true)
        strDoc = "Factura";

      this.confirmationService.confirm({
        message: "Está seguro de " + "eliminar la " + strDoc
          + " " + this.strNumFact + "?",
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencptovta',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          const USURAZELI = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'USURAZELI').CFG_VALOR1;
          if (USURAZELI == 1) {
            /*DlgUsuarioRazon dlgUsuRaz = new DlgUsuarioRazon(
              getParent(), this, "PtoVta");
            dlgUsuRaz.open();*/
          } else {
            this.eliminarRegistro3();
          }


        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    } else
      this.messageService.add({
        key: 'encptovta',
        severity: 'error',
        summary: 'Información',
        detail: 'No hay un documento para la eliminación.'
      });

  }

  eliminarRegistro3() {
    this.extraerPedidos(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);

    if (this.eliminarFactura(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO, false)) {
      // this.eliminarSeriales(strNumFactura, "VEN", Long.valueOf("0"), "", "");
      this.botonesinicio();
    }
  }

  async anularAsientoContable(strAsiNro) {
    let bolAnular = false;
    try {
      await this.encfacService.anularAsientoContable(strAsiNro);
      bolAnular = true;
    }
    catch (e) {
      console.log(e);
    }

    return bolAnular;
  }

  aplicarDescuentoGlobal() {

    if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO === 'ANULADA') {
      this.messageService.add({
        key: 'encptovta',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede aplicar descuento a la Factura'
          + ' que está anulada.'
      });
      return;
    }

    if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO === 'PAGADA') {
      this.messageService.add({
        key: 'encptovta',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede aplicar descuento a la Factura'
          + ' que está pagada.'
      });
      return;
    }

    if (Number(this.init.quitarComas(this.encptovtaService.encfac[this.indicador].ENCFAC_TOTAL)) > 0) {
      if (this.encptovtaService.encfac[this.indicador].ENCFAC_VALORDES === undefined
        || this.encptovtaService.encfac[this.indicador].ENCFAC_VALORDES === null
        || this.encptovtaService.encfac[this.indicador].ENCFAC_VALORDES === '') {
        this.valorDscPorcentaje = 0;
        this.valorDscValor = 0;
      } else {
        this.valorDscValor = this.encptovtaService.encfac[this.indicador].ENCFAC_VALORDES;
        this.valorDscPorcentaje = this.encptovtaService.encfac[this.indicador].ENCFAC_PORCEDES;
        const strdscporce = Number(this.init.quitarComas(this.valorDscPorcentaje)).toFixed(this.confIniciales.getNumDecSist());
        this.valorDscPorcentaje = Number(strdscporce);
      }

      if (this.valorDscPorcentaje > 0) {
      } else {
        if (Number(this.dscClienteActual) > 0) {
          this.valorDscPorcentaje = Number(this.dscClienteActual);
        }
      }

      this.displayDialogDescuento = true;
    }
  }

  aplicarDescuento() {

    if (this.booleanPorcentaje === false) {
      this.valorDscValor = Number(this.init.quitarComas(this.encptovtaService.encfac[this.indicador].ENCFAC_TOTAL)) * this.valorDscPorcentaje / 100;
    }

    if (this.booleanValor === false) {
      this.valorDscPorcentaje = this.valorDscValor * 100 / Number(this.init.quitarComas(this.encptovtaService.encfac[this.indicador].ENCFAC_TOTAL));
    }

    if (Number(this.encptovtaService.encfac[this.indicador].CLI_DESCUENTOLIM) > 0) {
      if (this.valorDscPorcentaje > Number(this.encptovtaService.encfac[this.indicador].CLI_DESCUENTOLIM)) {
        this.messageService.add({
          key: 'encptovta',
          severity: 'warn',
          summary: 'Información',
          detail: 'El Descuento máximo que se puede '
            + 'aplicar al cliente es '
            + this.encptovtaService.encfac[this.indicador].CLI_DESCUENTOLIM
            + '%.'
        });
        this.displayDialogDescuento = false;
        this.valorDscPorcentaje = Number(this.encptovtaService.encfac[this.indicador].CLI_DESCUENTOLIM);
        this.valorDscValor = 0;
        return;
      }
    }

    let tipo = '';
    if (this.booleanValor === false) {
      tipo = 'valor';
      if (this.dscClienteActual < (this.valorDscValor * 100 / Number(this.init.quitarComas(this.encptovtaService.encfac[this.indicador].ENCFAC_TOTAL)))) {
        this.confirmarDescuento(tipo);
      } else {
        this.descuentoAplicado(tipo);
      }
    }
    if (this.booleanPorcentaje === false) {
      tipo = 'porcentaje';
      if (this.dscClienteActual < this.valorDscValor) {
        this.confirmarDescuento(tipo);
      } else {
        this.descuentoAplicado(tipo);
      }
    }
  }


  confirmarDescuento(tipo) {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea aplicar un descuento mayor',
      header: 'El cliente tiene un descuento menor',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencptovta',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.descuentoAplicado(tipo);
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  descuentoAplicado(tipo) {
    this.displayDialogDescuento = false;
    this.botonGuardar = false;
    this.botonNuevo = true;

    if (tipo === 'valor') {
      this.encptovtaService.dblValorDescGlb = this.valorDscValor;
      this.encptovtaService.dblPorcDescGlb = (this.encptovtaService.dblValorDescGlb * 100) /
        Number(this.init.quitarComas(this.encptovtaService.encfac[this.indicador].ENCFAC_TOTALNETO));
      this.valorDscPorcentaje = this.encptovtaService.dblPorcDescGlb;
    }

    if (tipo === 'porcentaje') {
      this.encptovtaService.dblPorcDescGlb = this.valorDscPorcentaje;
      this.encptovtaService.dblValorDescGlb = Number(
        Number(this.init.quitarComas(this.encptovtaService.encfac[this.indicador].ENCFAC_TOTALNETO)) * this.valorDscPorcentaje / 100);
      this.valorDscValor = this.encptovtaService.dblValorDescGlb;
    }

    this.encptovtaService.calcularTotalesFact();
  }

  cambioDescuento() {
    if (this.dscSeleccionado === 'dscValor') {
      this.booleanValor = false;
      this.booleanPorcentaje = true;
    }

    if (this.dscSeleccionado === 'dscPorcentaje') {
      this.booleanPorcentaje = false;
      this.booleanValor = true;
    }
  }

  comprobarValor() {
    if (this.booleanValor === false) {
      if (Number(this.valorDscValor) > Number(this.init.quitarComas(this.encptovtaService.encfac[this.indicador].ENCFAC_TOTAL))) {
        this.valorDscValor = Number(this.init.quitarComas(this.encptovtaService.encfac[this.indicador].ENCFAC_TOTAL));
      }
    }

    if (this.booleanPorcentaje === false) {
      if (this.valorDscPorcentaje > 100) {
        this.valorDscPorcentaje = 100;
      }
    }
  }

  async PagoFactura() {

    let intIngSeriales = 0;

    if (await this.ValidarSeriales(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO))
      intIngSeriales = 1;

    // if(ValidarSeriales(lblNumFact.getText()))
    this.PagoFacturaContado(intIngSeriales);
  }

  async  PagoCred() {
    if (await this.ValidarSeriales(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO))
      this.PagoFacturaCredito();
  }


  cancelarRegistro() {
    this.botonesinicio();
    if (!this.bolNuevo) {
      this.navegarFactura("R", this.strCondNavegar + " AND ENCFAC_numero = '"
        + this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO + "'");
    } else {
      this.limpiarDatos();
      document.getElementById('contorno').style.pointerEvents = 'none';
      this.boolpago = true;//CDPJ
      this.aggrid.bloquearGrid();
			/*groupEnc.setEnabled(false);
      groupDet.setEnabled(false);*/
      this.encptovtaService.encfac[this.indicador].ENCFAC_AUTORIZACION = '';
      /*cmpTipoDoc.setEnabled(false);
        mnuAcciones.setEnabled(false);
        mnuEditar.setEnabled(false);
        mnuVer.setEnabled(false);*/
    }/*
		toolBarDetalle.getItem(0).setEnabled(true); // nueva linea
		toolBarDetalle.getItem(1).setEnabled(true); // eliminar linea
		toolBarDetalle.getItem(2).setEnabled(false); // cancelar linea
*/
  }

  async obtenerEstadoFactura(strNumFact) {
    let strEstado = "";
    const data: any = await this.encptovtaService.obtenerEstadoFactura(strNumFact);
    for (const rs of data) {
      strEstado = rs.ENCFAC_ESTADO;
    }
    return strEstado;
  }

  async existePagoDirecto() {
    let intNumPago = 0;
    const data: any = await this.encptovtaService.existePagoDirecto(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);

    for (const rs of data) {
      intNumPago = rs.COUNT;
    }

    return intNumPago;
  }

  async presentarPagoDirecto() {

    let intIngSeriales = 0;

    if (await this.ValidarSeriales(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO))
      intIngSeriales = 1;

    /*
  let strNumComp = "";
	
    strNumComp = ejecutaSentencia
        .encontrarRegistro(
            "TRNCOBRO_NRODOC",
            "CXC_TRNRESUMEN",
            "TRNCOBRO_REFERENCIA = '"
                + lblNumFact.getText().trim()
                + "' AND COM_CODIGO = '"
                + Conexion.COMPANIA
                + "' AND TRNCOBRO_TIPODOC IN ('CA', 'AB') AND APLORG_CODIGO = 'FAC'");
  */

    this.auxiliar++;
    this.strNumFact = this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO;
    this.displayDialogPagoContado = true;
    this.auxpago = 'presentarPagoDirecto';
    /*
    DlgPagoContado dlgContado = new DlgPagoContado(shell, lblTotal
    .getText(), txtCodCliente.getText().trim(), lblNumFact
    .getText().trim(), strNumComp, "", bolVisualizarAsiento, true,
    hmpConfiguracion.get("PVASTFECDV"), hmpConfiguracion
        .get("PVCAJADEPV"), dblCliLimCredit, lblEstadoFE
        .getText(), "PTOVTA", this, intIngSeriales);
    dlgContado.open();
  */
  }

  async  autorizarFacturacionLimCredito(strCliCodigo, dblCliLimCredit,
    intCfgAutFacVen, dblTotalFact, strNumFac) {
    let bolAut = true;
    let dblSaldoCli = 0;

    if (intCfgAutFacVen == 1 && dblCliLimCredit > 0) {
      dblSaldoCli = await this.obtenerSaldoClienteFac(strCliCodigo, strNumFac);

      dblSaldoCli = dblSaldoCli + dblTotalFact;

      if (dblSaldoCli > dblCliLimCredit) {
        this.messageService.add({
          key: 'encptovta',
          severity: 'error',
          summary: 'Información',
          detail: "El saldo del Cliente es " +
            dblSaldoCli.toFixed(this.confIniciales.getNumDecSist()) + " y su límite de crédito es de " + dblCliLimCredit
        });

        /* DlgClaveAutorizacion dlgClaveAut = new DlgClaveAutorizacion(new Shell(), "AUTFCVEN");
         dlgClaveAut.open();
 
         if (dlgClaveAut.intNumReg == 0)
           bolAut = false;*/
      }
    }

    return bolAut;
  }

  pagoCreditoConClave() {
    let bol = true;

    this.strDialogo = 'frmVEN_ENCPTOVTA';
    this.strObjeto = 'CmdPagoACredito';
    this.intPestaniaCol = 0;
    this.usuarioSup = '';
    this.claveSup = '';
    this.bolSupervisor = false;
    this.displayDialogSupervisor = true;

  }

  async obtenerSaldoClienteFac(strCliCodigo, strNumFac) {
    let dblSaldo = 0;
    const data = await this.encptovtaService.obtenerSaldoClienteFac(strCliCodigo, strNumFac)
    for (const rs of data) {
      dblSaldo = Number(rs.SALDO);
    }
    return dblSaldo;
  }
  //CDPJ
  cerrarCuotas(event) {
    if (event) {
      this.encfacService.generarAsientoContable(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO, 'AF',
        this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO.substr(
          this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO.length - 7)).subscribe(() => {
            this.encfacService.erCerrarCuotas(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO).subscribe(cen => {
              if (cen[0] !== undefined) {
                this.encfacService.actualizaFormaPagoFact(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO, 'C').subscribe(() => {
                  this.cancelar();
                });
                this.encptovtaService.encfac[this.indicador].ASI_NRO = cen[0].ASI_NRO;
                const INGRAPFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INGRAPFAC').CFG_VALOR1;
                if (INGRAPFAC === 0) {// ingreso rapido de la factura
                  this.messageService.add({
                    key: 'encfac',
                    severity: 'success',
                    summary: 'Información',
                    detail: 'El Asiento Contable se generó correctamente'
                  });
                }
              }
            });
          }, error1 => {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.messageService.add({
              key: 'encfac',
              severity: 'error',
              summary: 'Asiento Contable',
              detail: mensaje
            });
          });
      this.displayDialogCuotas = false;
    } else {
      this.displayDialogCuotas = false;
    }
  }
  //CDPJ
  obtenerSeleccion() {
    if (this.rbtFECCUO !== undefined) {
      if (this.rbtFECCUO === 'CUO') {
        // cuotas
        this.auxcuotas++;
        this.displayDialogCuotas = true;
      }

      if (this.rbtFECCUO === 'FEC') {
        this.encfacService.generarAsientoContable(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO, 'AF',
          this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO.substr(
            this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO.length - 7)).subscribe(() => {
              this.encfacService.erGetSeleccion(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO).pipe(finalize( async () => { await this.actualizaFPCredito()})).subscribe(cen => {
                if (cen[0] !== undefined) {
                  
                  this.encptovtaService.encfac[this.indicador].ASI_NRO = cen[0].ASI_NRO;
                  this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
                    this.encptovtaService.encfac[this.indicador].ASI_NRO + '/VEN', 'I',
                    '', '01', '', '', '', '').subscribe();
                  const INGRAPFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INGRAPFAC').CFG_VALOR1;
                  if (INGRAPFAC === 0) { // ingreso rapido de la factura
                    this.messageService.add({
                      key: 'encptovta',
                      severity: 'success',
                      summary: 'Información',
                      detail: 'El Asiento Contable se generó correctamente'
                    });
                  }
                }
              });
            }, error1 => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
              this.messageService.add({
                key: 'encptovta',
                severity: 'error',
                summary: 'Asiento Contable',
                detail: mensaje
              });
            });
      }
      this.displayDialogPagoCredito = false;
    } else {
      this.messageService.add({
        key: 'encptovta',
        severity: 'warn',
        summary: 'Información',
        detail: 'Seleccione una opción'
      });
    }
  }
//CDPJ
async actualizaFPCredito (){
  const data=await  this.encfacService.actualizaFormaPagoFact(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO, 'C').toPromise().catch(e=>{
    let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
            this.messageService.add({
              key: 'encfaencptovtac',
              severity: 'error',
              summary: 'Asiento Contable',
              detail: mensaje
            });
  })//.subscribe(() => {
    this.cancelar();
  //});
}
//CDPJ
  verAsiento() {
    this.asiento = this.encptovtaService.encfac[this.indicador].ASI_NRO;
    if (this.asiento === null || this.asiento === undefined || this.asiento === '') {
      this.messageService.add({
        key: 'encptovta',
        severity: 'error',
        summary: 'Información',
        detail: 'No ha generado el Asiento Contable'
      });
    } else {
      this.auxiliar1++;
      this.displayAsiContable = true;
    }
  }

  generarAsiento() {
    if (!this.bolGenAsiento) { // si no tiene los permisos especiales    
      this.messageService.add({
        key: 'encptovta',
        severity: 'error',
        summary: 'Información',
        detail: "El usuario "
          + this.encptovtaService.usuario.identificacion
          + " no tiene el permiso especial asignado."
      });
      return;
    }

    if (this.strFormaPago.length > 0)
      this.generarAsientoContable();
    else
      this.messageService.add({
        key: 'encptovta',
        severity: 'error',
        summary: 'Información',
        detail: "La factura no tiene forma de pago."
      });
  }

  async aplicarPermisosObj() {
    const rsObjDlg: any = await this.encptovtaService.verificarpermobjdialogo("frmVEN_ENCPTOVTA", this.encptovtaService.usuario.identificacion);
    for (const rs of rsObjDlg) {
      if (rs.GUIOBJ_NOMBOBJ === "cmdGenerarAsiento") { // habilita el botón para generar el asiento
        if (rs.GUIOBJPER_ESTADO == 1)
          this.bolGenAsiento = true;
        else if (rs.GUIOBJPER_ESTADO == 0) {
          this.bolGenAsiento = false;
        }
      } else if (rs.getString(1).equals("cmdasilote")) { // habilita el botón para visualizar el asiento				
        if (rs.GUIOBJPER_ESTADO == 1)
          this.bolVisualizarAsiento = true;
        else if (rs.GUIOBJPER_ESTADO == 0) {
          this.bolVisualizarAsiento = false;
        }
      }
    }
  }

  visualizarAsiento() {
		/*if (UtilidadesCont.verificarVisulizacionAsiento() == false)
			return;
*/
    if (!this.bolVisualizarAsiento) { // no tiene permiso especial para visualizar el asiento
      this.messageService.add({
        key: 'encptovta',
        severity: 'error',
        summary: 'Información',
        detail: "El usuario " + this.encptovtaService.usuario.identificacion
          + " no tiene el permiso especial asignado."
      });
      return;
    }

    this.verAsiento();
  }

  firmar() {
    if (this.datosfe.COM_FEACTIVADA === '0') {
      return;
    }
    // GSRF
    if (this.chkNotaVenta === true){
      this.messageService.add({
        key: 'encptovta',
        severity: 'error',
        summary: 'Información',
        detail: 'La nota de venta no puede ser firmada'
      });
      return;
    }
    //GSRF
    if (this.chkNotaVenta === false)
      this.insertarEleTrnDocumento(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);
  }

  async actualizarEstadoParaReenviarCorreo(strNumDoc) {

    if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO === 'ANULADA') {
      this.messageService.add({
        key: 'encptovta',
        severity: 'error',
        summary: 'Información',
        detail: 'La factura está anulada'
      });
      return;
    }

    const data = await this.encfacService.actualizarDatosElecProm(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);
    for (const rs of data) {
      this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO_FE = rs.ENCFAC_ESTADO_FE;
      this.encptovtaService.encfac[this.indicador].ENCFAC_AUTORIZACION_FE = rs.ENCFAC_AUTORIZACION_FE;
      this.encptovtaService.encfac[this.indicador].ENCFAC_CLAVEACCESO_FE = rs.ENCFAC_CLAVEACCESO_FE;
    }

    if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO_FE === 'NT') {
      try {
        await this.encfacService.actualizarEstadoFE(strNumDoc);
        const data = await this.utilitariosService.reenviarCorreo(strNumDoc);
        this.messageService.add({
          key: 'encptovta',
          severity: 'success',
          summary: 'Información',
          detail: data
        });
        this.auditoriagral.registrarAuditoria('ELE_TRNDOCUMENTO', strNumDoc + 'VEN_REV_CORREO', 'A',
          '', '01', '', '', '', '').subscribe();
        this.messageService.add({
          key: 'encptovta',
          severity: 'success',
          summary: 'Información',
          detail: 'Proceso actualizar estado para reenvió de correo  terminó satisfactoriamente'
        });
        this.refrescarDatosFE('interno');
      } catch (e) {
        e.printStackTrace();
        this.messageService.add({
          key: 'encptovta',
          severity: 'error',
          summary: 'Error',
          detail: 'Proceso actualizar estado para reenvió de correo no terminó satisfactoriamente'
        });
      }
    } else {
      this.messageService.add({
        key: 'encptovta',
        severity: 'error',
        summary: 'Información',
        detail: 'Solo puede reenviar el correo si la factura esta notificada'
      });
    }
  }

  refrescarDatosFE(accion) {
    this.closeSidebar();
    if (this.datosfe.COM_FEACTIVADA === '0') {
      return;
    }

    this.encfacService.actualizarDatosElectronicos(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO).subscribe(data => {
      for (const rs of data) {
        this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO_FE = rs.ENCFAC_ESTADO_FE;
        this.encptovtaService.encfac[this.indicador].ENCFAC_AUTORIZACION_FE = rs.ENCFAC_AUTORIZACION_FE;
        this.encptovtaService.encfac[this.indicador].ENCFAC_CLAVEACCESO_FE = rs.ENCFAC_CLAVEACCESO_FE;
      }
      if (accion === 'acciones') {
        this.messageService.add({
          key: 'encptovta',
          severity: 'success',
          summary: 'Información',
          detail: 'Datos actualizados'
        });
      }
      if (accion === 'proceso'){
        this.messageService.add({
          key: 'encfac',
          severity: 'success',
          summary: 'Información',
          detail: 'Documento procesado'
        });
      }
    });
  }

  async eliminarLineaDetalle() {

    if (await this.verificarPermisosObjConClave("frmVEN_ENCPTOVTA", "CmdBorrarD", 0)) {
			/*if (!eliminarLineaConClave()) // verificar si no necesita clave de
				// supervisor para eliminar la línea
				return;*/
    }

    if (this.permisoPtoVta !== null) {
      if (this.permisoPtoVta.PERTODO === 0) {
        if (this.permisoPtoVta.PERACTUALIZACION === 0) {
          this.messageService.add({
            key: this.messageKey,
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario '
              + this.encptovtaService.usuario.identificacion
              + ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.'
          });
          return;
        }
      }
    }

    if (this.detfacSelected !== undefined
      && this.encptovtaService.detfac[this.indicador].length > 0) {
      //			intIndex = tablaFactura.getCellSelection()[0].y;
      const INGESPFAC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'INGESPFAC').CFG_VALOR1;
      if (INGESPFAC == 0) {

        this.confirmationService.confirm({
          message: 'Está seguro de eliminar el registro?',
          header: 'Pregunta',
          icon: 'pi pi-exclamation-triangle',
          key: 'confirmarencptovta',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.confirmationService.close();
            this.eliminarLineaDetalle2();
          },
          reject: () => {
            this.confirmationService.close();
          }
        });
      }
      else
        this.eliminarLineaDetalle2();



    } else
      this.messageService.add({
        key: this.messageKey,
        severity: 'error',
        summary: 'Información',
        detail: "Seleccione la fila que desea eliminar."
      });

  }

  async eliminarLineaDetalle2() {
    let bolEliminar = false;

    let intIndex = -1;

    let item = this.detfacSelected;


    // extrae los pedidos en la factura
    if (item.ENCFAC_NUMERO != null) {
      if (item.ENCFAC_NUMERO.length > 0) {
        this.extraerPedidos(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);
        // extraerGuiasRem(item.getEncFacNumero());
      }
    }


    if (await this.eliminarDetalleFact(item)) {
      /*modeloFactura.getListaDetFact().remove(item);
      modeloFactura.colorearFilasEspeciales(); // coloca los colores de promociones
      tablaFactura.redraw();
      modeloFactura.actualizarDespachoPedido();
      verificarActualizarEstPed();

      if (intIndex > 1)
        tablaFactura.setSelection(1, intIndex - 1, true);
      else
        tablaFactura.setSelection(1, intIndex, true);

      if (modeloFactura.getListaDetFact().size() > 0) {
        if (modeloFactura.getListaDetFact().get(
          modeloFactura.getListaDetFact().size() - 1)
          .getDetFacCodigo().length() == 0)
          toolBarDetalle.getItem(0).setEnabled(
            toolBarDetalle.getItem(0).getEnabled()); // Nueva
        // Linea
      } else
        toolBarDetalle.getItem(0).setEnabled(true);

      toolBarDetalle.getItem(1).setEnabled(true); // Eliminar Linea
      toolBarDetalle.getItem(2).setEnabled(false); // Cancelar Linea

      mnuNuevaLinDet.setEnabled(true);
      mnuElimLinDet.setEnabled(true);

      // modeloFactura.deshabilitarTipoDet(true);

      if (modeloFactura.getListaDetFact().size() == 0) {
        // habilitar el botón nuevo si está deshabilitado
        if (!toolBarDetalle.getItem(0).getEnabled())
          toolBarDetalle.getItem(0).setEnabled(true);
      }

      // colocar el número de items disponibles
      String strItemDisp = String
        .valueOf(modeloFactura.intNumItems
          - modeloFactura.getListaDetFact().size());
      lblItemsDisp.setText(strItemDisp);
*/
      // guardar cambios de los totales



      this.encptovtaService.calcularTotalesFact();

      // if (this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO !== "")
      //   this.guardarFactura();

    }
    //CDPJ
    if (this.detfacSelected !== undefined && this.encptovtaService.detfac[this.indicador].length > 0) {
      console.log('tamaño', this.encptovtaService.detfac[this.indicador].length)
      console.log(this.detfacSelected);
      console.log('linea', this.detfacSelected.DETFAC_LINEA_3);
      if (this.detfacSelected.DETFAC_LINEA_3 !== 0) {
        console.log('linea2', this.detfacSelected.DETFAC_LINEA);
        this.encfacService.eliminarDetalleFact(this.detfacSelected).subscribe(() => {

          this.encptovtaService.detfac[this.indicador].splice(this.encptovtaService.detfac[this.indicador].findIndex(
            detfac => detfac.DETFAC_LINEA === this.detfacSelected.DETFAC_LINEA),
            1);
          this.messageService.add({
            key: 'encptovta',
            severity: 'success',
            summary: 'Información',
            detail: 'El detalle se eliminó correctamente'
          });
          console.log('detalle menos1', this.encptovtaService.detfac)
          if (this.encptovtaService.detfac[this.indicador].length > 0) {
            this.detfacSelected = this.encptovtaService.detfac[this.indicador][this.encptovtaService.detfac[this.indicador].length - 1];
          }
          this.encptovtaService.calcularTotalesFact();
          this.aggrid.refreshaggrid(this.encptovtaService.detfac[this.indicador], this.defaultColDef.objeto);

          if (this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO !== '') {
            //this.guardar();
            //this.guardarFactura();
          }
        });
        //CDPJ-

        this.auditoriagral.registrarAuditoria('VEN_DETFAC', this.detfacSelected.ENCFAC_NUMERO + "/" + this.detfacSelected.DETFAC_CODIGO, 'E',
          '', '01', '', '', '', '').subscribe();
        //CDPJ
      } else {

        this.encptovtaService.detfac[this.indicador].splice(this.encptovtaService.detfac[this.indicador].findIndex(
          detfac => detfac.DETFAC_LINEA === this.detfacSelected.DETFAC_LINEA
            && detfac.DETFAC_LINEA_3 === this.detfacSelected.DETFAC_LINEA_3),
          1);
        console.log('detalle menos', this.encptovtaService.detfac)
        this.messageService.add({
          key: 'encptovta',
          severity: 'success',
          summary: 'Información',
          detail: 'El detalle se eliminó correctamente'
        });
        if (this.encptovtaService.detfac[this.indicador].length > 0) {
          this.detfacSelected = this.encptovtaService.detfac[this.indicador][this.encptovtaService.detfac[this.indicador].length - 1];
        }
        this.encptovtaService.calcularTotalesFact();
        this.aggrid.refreshaggrid(this.encptovtaService.detfac[this.indicador], this.defaultColDef.objeto);
      }

      const NUMITEMPV = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMITEMPV').CFG_VALOR2;
      console.log('maximo', NUMITEMPV);
      // actualizar número de items disponibles
      if (NUMITEMPV !== null && NUMITEMPV !== undefined && NUMITEMPV > 0) {
        //console.log('maximo',NUMITEMPV);        
        //if(this.encptovtaService.encfac[this.indicador].ENCFAC_ITEMSDISPONIBLES > 0){
        //const item = this.crearNuevoItem(strTipoDetalle);
        //this.encptovtaService.detfac[this.indicador].push(item);
        //this.encptovtaService.encfac[this.indicador].ENCFAC_ITEMSDISPONIBLES = Number(this.encptovtaService.encfac[this.indicador].ENCFAC_ITEMSDISPONIBLES) - 1;
        const itemdisponibles = Number(this.encptovtaService.encfac[this.indicador].ENCFAC_ITEMSDISPONIBLES);
        this.encptovtaService.encfac[this.indicador].ENCFAC_ITEMSDISPONIBLES = itemdisponibles + 1;
        // }else{
        //   this.messageService.add({
        //     key: this.messageKey,
        //     severity: 'error',
        //     summary: 'Información',
        //     detail: 'El número de items disponibles es cero'
        //   });
        //   return;
        // }
      } else {
        //const item = this.crearNuevoItem(strTipoDetalle);
        //this.encptovtaService.detfac[this.indicador].push(item);
      }
    }
    this.encptovtaService.calcularTotalesFact();
    //CDPJ
  }

  //CDPJ
  busquedavendedor(parametro) {
    if (parametro === null) {
      parametro = '';
    }
    this.opcionbusqueda = 'vendedor';
    this.types = [
      { label: 'Código', value: 'VEN_CODIGO' },
      { label: 'Nombre', value: 'VEN_NOMBRE' }
    ];
    this.busquedacampos = [parametro, ''];
    this.tabla = 'VEN_MAEVENDEDOR';
    this.where = '';

    this.encptovtaService.erBusquedaVendedor(this.types[1].value, this.tabla, this.types[0].value, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.encptovtaService.encfac[this.indicador].VEN_NOMBRE = eR[0].VEN_NOMBRE;
          document.getElementById('txtRefCliente').focus();
        } else {
          this.spin = true;
          this.busqService.busquedaVenMaeVendedor3(parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.spin = false;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.spin = true;
        this.busqService.busquedaVenMaeVendedor3(parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.spin = false;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }
  activarFacturaAnuladaFE() {
    this.closeSidebar();


    if (this.datosfe.COM_FEACTIVADA === '0') {
      return;
    }

    if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO !== 'PENDIENTE') {
      return;
    }

    this.claveAuth = '';
    this.autorizacionCodigo = 'ACTFAFIR';
    this.displayDialogAutorizacion = true;

  }
  async reversarFE() {
    this.closeSidebar();
    if (this.datosfe.COM_FEACTIVADA === '0') {
      return;
    }
    const strNumDoc = this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO
    if (strNumDoc === '' || strNumDoc === null || strNumDoc === undefined) {
      this.messageService.add({
        key: 'encptovta',
        severity: 'error',
        summary: 'Información',
        detail: 'No ha seleccionado un documento'
      });
      return;
    }

    const data = await this.encfacService.actualizarDatosElecProm(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);
    for (const rs of data) {
      this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO_FE = rs.ENCFAC_ESTADO_FE;
      this.encptovtaService.encfac[this.indicador].ENCFAC_AUTORIZACION_FE = rs.ENCFAC_AUTORIZACION_FE;
      this.encptovtaService.encfac[this.indicador].ENCFAC_CLAVEACCESO_FE = rs.ENCFAC_CLAVEACCESO_FE;
    }

    if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO === "ANULADA") {
      return;
    }

    if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO_FE !== "AT"
      && this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO_FE !== "NT") {

      this.confirmationService.confirm({
        message: 'Está seguro de reversar el proceso?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencptovta',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          try {
            this.utilitariosService.reversarProcesoFE(strNumDoc, '01');
            this.utilitariosService.reversarProcesoFEClave(this.encptovtaService.encfac[this.indicador].ENCFAC_CLAVEACCESO_FE);
            this.auditoriagral.registrarAuditoria('ELE_TRNDOCUMENTO', strNumDoc + 'VEN_REVERSAR_FE', 'E',
              '', '01', '', '', '', '').subscribe();
            this.messageService.add({
              key: 'encptovta',
              severity: 'success',
              summary: 'Información',
              detail: 'Reversar proceso de inicio de firma electrónica terminó satisfactoriamente'
            });
            this.refrescarDatosFE('interno');
          } catch (e) {
            this.messageService.add({
              key: 'encptovta',
              severity: 'error',
              summary: 'Error',
              detail: 'Reversar proceso de inicio de firma electrónica no terminó satisfactoriamente'
            });
          }
        }, reject: () => {
          this.confirmationService.close();
        }
      });
    } else {
      this.messageService.add({
        key: 'encptovta',
        severity: 'error',
        summary: 'Información',
        detail: 'Solo puede reversar el proceso de facturación electrónica si el documento no esta NT(Autorizado) ó AT(Notificado)'
      });
    }
  }
  async continuarFE() {
    this.closeSidebar();
    const msgclave = 'encptovta';
    const strNumDoc = this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO;

    if (strNumDoc === '' || strNumDoc === null || strNumDoc === undefined) {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'No ha seleccionado un documento'
      });
      return;
    }

    if (this.datosfe.COM_FEACTIVADA === '0') {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'Facturación electrónica no está activada.'
      });
      return;
    }

    if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO_FE === ''
      || this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO_FE === undefined
      || this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO_FE === null) {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'El documento no ha iniciado el proceso de firma electrónica.'
      });
      return;
    }

    if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO_FE === 'VA') {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'El documento se encuentra en su estado inicial, reverse el proceso de firma electrónica.'
      });
      return;
    }

    if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO_FE === 'NT') {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'El documento se encuentra notificado.'
      });
      return;
    }


    if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO_FE === 'NA') {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'Visualize el log. Corrija el documento y reverse el proceso de firma electrónica.'
      });
      return;
    }

    this.spin = true;
    const data = await this.utilitariosService.continuarFirmaElectronica(strNumDoc);
    this.spin = false;
    if (data !== 'NOAUTORIZADO') {
      this.messageService.add({
        key: msgclave,
        severity: 'success',
        summary: 'Información',
        detail: data
      });
    }
  }
  visualizarLog() {
    this.closeSidebar();
    this.logResultado = '';
    if (this.encptovtaService.encfac[this.indicador].ENCFAC_ESTADO !== 'ANULADA') {
      this.encfacService.obtenerLogFE(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO, '01').subscribe((res) => {
        if (res.length !== 0) {
          if (res[0] !== null) {
            this.logResultado = JSON.stringify(res[0].TRNDOC_RESULTADO);
          }
        }
        this.displayDialogLog = true;
      });
    } else {
      return;
    }
  }
  //CDPJ
  //CDPJ
  //GSRF
  //  verReporte() {
  //   if (this.reporteSeleccionado !== 0) {
  //     this.visualizarReporte();
  //   } else {
  //     this.messageService.add({
  //       key: 'cfngimpr',
  //       severity: 'error',
  //       summary: 'Información',
  //       detail: 'No existe ningún dato. Consulte con el Administrador'
  //     });
  //   }
  // }
  visualizarReporte() {
    console.log(this.reportes);

    console.log(this.CNFGIMPR_CODIGO);
    const strCodigoParam = this.CNFGIMPR_CODIGO;
    this.CargarParametrosReporte(strCodigoParam, 'visualizar');
  }
  CargarParametrosReporte(strCodigoParam, accion) {
    let i = 0;
    console.log(strCodigoParam);
    this.cfngimprService.consultaPrmtCnfgImprT(strCodigoParam, '01').subscribe(data => {
      for (const rs of data) {
        this.ValoresParamentros[i] = [];
        this.ValoresParamentros[i][0] = rs.PRMTCNFGIMPR_TIPO;
        this.ValoresParamentros[i][1] = rs.PRMTCNFGIMPR_VALOR;
        this.ValoresParamentros[i][2] = rs.PRMTCNFGIMPR_TIPODATO;
        i = i + 1;
      }
      this.intNumfilasVP = i;

      if (accion === 'visualizar') {
        this.visualizarReporte2();
      }
    });
  }

  visualizarReporte2() {
    let strPantallaArchivoRP = '';
    let strConsulta = this.FormarConsulta().trim();

    if (this.CNFGIMPR_SP !== null) {
      console.log('aqui');
      if (this.strParametro === '1') {
        strPantallaArchivoRP = 'PANTALLA' + this.CNFGIMPR_REPORTE;
      } else {
        strPantallaArchivoRP = this.CNFGIMPR_REPORTE;
      }
      console.log('aqui 1');
      this.cfngimprService.EjecutaSentenciaUpdate(this.FormarConsultaProceso()).subscribe();
      strConsulta = 'where ' + strConsulta.substring(4).trim() + ' AND PARAMETRO_IDE = ' + '\'' + this.ValorParametroIDE + '\'';
      setTimeout(() => {
        console.log('aqui 2');
        const mediaType = 'application/pdf';
        this.reporteSer.generarReporte(strPantallaArchivoRP, strConsulta, false, '').subscribe(response => {
          const blob = new Blob([response], { type: mediaType });
          const fileURL = URL.createObjectURL(blob);

          console.log('aqui 3');
          window.open(fileURL).print();
          console.log('aqui 3');
        }, error => {
          this.messageService.add({
            key: 'cfngimpr',
            severity: 'error',
            summary: 'Error',
            detail: 'Error al generar el reporte. Revise la configuración'
          });
        });
      }, 1000);

    } else {
      if (this.strParametro === '1') {
        strPantallaArchivoRP = 'PANTALLA' + this.CNFGIMPR_REPORTE;
      } else {
        strPantallaArchivoRP = this.CNFGIMPR_REPORTE;
      }
      setTimeout(() => {
        const mediaType = 'application/pdf';
        this.reporteSer.generarReporte(strPantallaArchivoRP, strConsulta, false, '').subscribe(response => {
          const blob = new Blob([response], { type: mediaType });
          const fileURL = URL.createObjectURL(blob);

          window.open(fileURL).print();
        }, error => {
          this.messageService.add({
            key: 'cfngimpr',
            severity: 'error',
            summary: 'Error',
            detail: 'Error al generar el reporte. Revise la configuración'
          });
        });
      }, 2000);
    }
  }

  FormarConsulta() {
    let strCadena = '';
    let strValorParam = '';
    let strCadenaFinal = '';

    for (let i = 0; i < this.intNumfilasVP; i++) {
      if (this.ValoresParamentros[i][0] === 'T') {
        strCadena = this.ValoresParamentros[i][1];
        strCadenaFinal = strCadenaFinal + ' ' + strCadena;
      } else {
        const strValor = this.ValoresParamentros[i][1];
        const strTipoValor = this.ValoresParamentros[i][2];
        for (let j = 0; j < this.intNumFilasVR; j++) {
          if (strValor === this.ValoresReporte[j][0]) {
            strValorParam = this.ValoresReporte[j][1];
            if (strTipoValor === 'S') {
              strValorParam = '\'' + strValorParam + '\'';
            }
            strCadenaFinal = strCadenaFinal + ' ' + strValorParam;
          }
        }
      }
    }

    if (this.CNFGIMPR_TABLA != null && this.CNFGIMPR_SP == null) {
      strCadenaFinal = ' WHERE ' + strCadenaFinal;
    } else {
      strCadenaFinal = ' AND ' + strCadenaFinal;
    }

    return strCadenaFinal;
  }

  FormarConsultaProceso() {
    const fechaSistema = new Date();
    const lngdfechaSistema = fechaSistema.getTime();
    const strParametroIDE = lngdfechaSistema.toString();
    this.ValorParametroIDE = strParametroIDE;

    let strValorParam = '';
    let strCadenaFinal = '';
    for (let i = 0; i < this.intNumfilasVP; i++) {
      if (this.ValoresParamentros[i][0] === 'C') {
        const strValor = this.ValoresParamentros[i][1];
        const strTipoValor = this.ValoresParamentros[i][2];
        for (let j = 0; j < this.intNumFilasVR; j++) {
          if (strValor === this.ValoresReporte[j][0]) {
            strValorParam = this.ValoresReporte[j][1];
            if (strTipoValor === 'S') {
              strValorParam = ',\'' + strValorParam + '\'';
            }
            strCadenaFinal = strCadenaFinal + '' + strValorParam;
          }
        }
      }
    }
    strCadenaFinal = strCadenaFinal.substring(1);
    this.strObjeto = this.CNFGIMPR_TABLA;
    strCadenaFinal = 'BEGIN ' + this.CNFGIMPR_SP + '(' + strCadenaFinal + ',\'' + strParametroIDE + '\'' + ');END;';
    return strCadenaFinal;
  }

  FunCnfgMtrzRpt(strCampo, strNumDoc) {
    const intNumeroDatos = 2;
    this.ValoresReporte[0] = [];
    this.ValoresReporte[1] = [];
    this.ValoresReporte[0][0] = strCampo;
    this.ValoresReporte[0][1] = strNumDoc.trim();
    this.ValoresReporte[1][0] = 'COM_CODIGO';
    this.ValoresReporte[1][1] = '01';
    this.intNumFilasVR = intNumeroDatos;
  }
  //GSRF
  // CDPJ
  async verbasesiva(){
    this.closeSidebar()
    this.basesiva=[]
    const data = await this.encfacService.obtenerbasesiva(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);
    if(data !== null && data !== undefined){
     this.basesiva=data
     for (const data2 of  this.basesiva){
       data2.TARIIVA_PORCENTAJE=isNaN(Number(data2.TARIIVA_PORCENTAJE))?Number(0).toFixed(2):Number(data2.TARIIVA_PORCENTAJE).toFixed(2)
       data2.IVA_BASE=isNaN(Number(data2.IVA_BASE))?Number(0).toFixed(2):Number(data2.IVA_BASE).toFixed(2)
       data2.IVA_VALOR=isNaN(Number(data2.IVA_VALOR))?Number(0).toFixed(2):Number(data2.IVA_VALOR).toFixed(2)
     }
    }
    this.displaybasesiva=true;

  }
  async actbasesiva(){
    this.spin=true;
    this.closeSidebar();
    try {
      await this.encfacService.actualizabasesiva(this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO);
      this.messageService.add({
        key: 'encptovta',
        severity: 'success',
        summary: 'Información',
        detail: 'Bases IVA actualizadas correctamente'
      });
      this.auditoriagral.registrarAuditoria('VEN_ENCFACPTOVTA',this.encptovtaService.encfac[this.indicador].ENCFAC_NUMERO+'/'+'ACCIONES_ACT_VALORES_BASES_IVA', 'A', 
        '', '01', '', '', '', '').subscribe(() => {
        });
      this.spin=false;
    } catch (error) {
      this.messageService.add({
        key: 'encptovta',
        severity: 'error',
        summary: 'Información',
        detail: 'Error al actualiza las bases IVA'+ error
      });
      this.spin=false;
    }
    
  }
  //cdpj
}
