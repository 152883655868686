import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { MessageService } from 'primeng/api';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { DatePipe } from '@angular/common';
import { ComEncfacpro } from '../cominterfaces/comencfacpro';
import { SaciTrnretencion } from '../../interfaces/sacitrnretencion';
import { ComTrncargos } from '../cominterfaces/comtrncargos';
import { ComDetfacpro } from '../cominterfaces/comdetfacpro';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';

@Injectable({
  providedIn: 'root'
})
export class ComencfacproService {

  public way: string;

  public encfacproarray: ComEncfacpro[] = [];
  public detfacproarray: ComDetfacpro[][] = [];
  public trncargosarray: ComTrncargos[][] = [];
  public trnretencionarray: SaciTrnretencion[][] = [];
  public encretarray: SaciTrnretencion[] = [];
  public strFacturaExportadaarray: string[] = [];
  public strTipoDescuentoarray: string[] = [];
  public lblTotalDscValorarray: string[] = [];
  public strTipoIvaarray: string[] = [];
  public totalcargosarray: string[] = [];
  public dblPorcDescGlbarray: any[] = [];
  public dblValorDescGlbarray: any[] = [];

  public hmpConfiguracion: any[];
  public dblPorcIva: any;
  decimalesBASEret: number;
  decimalesBASE0ret: number;
  decimalesBASEGret: number;
  decimalesBASENGret: number;
  decimalesPORCENTAJEret: number;
  decimalesTOTALret: number;
  decimalesCANTIDADfac: number;
  decimalesCOSTOfac: number;
  decimalesTOTALDetFacPro: number;
  decimalesTOTALdetfac: number;
  decimalesPrecios: number;
  decimalesCantUnd: number;
  spin=false;//CDPJ
  //CDPJ
  public tariivacod:string;
  public porceiva:number;
  //CDPJ
  public idcellfacpro = '';//GSRF
  public numcellfacpro: number;//GSRF

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private confIniciales: ConfInicialesService,
    private datePipe: DatePipe,
    private init: NuevoComponentService
  ) {
    this.way = sessionStorage.getItem('way');
  }

  insertarEncabezadoFactPromise(item): Promise<any[]> {
    return this.http.post<any>(this.way + '/compras/factproveedores/4afc75d8a5bd4c', {
      elementos: {
        p_encfacpro_numero: item.ENCFACPRO_NUMERO,
        p_com_codigo: item.COM_CODIGO,
        p_encfacpro_fechaemision: item.ENCFACPRO_FECHAEMISION,
        p_encfacpro_fechavencimiento: item.ENCFACPRO_FECHAVENCIMIENTO,
        p_encfacpro_fecharec: item.ENCFACPRO_FECHAREC,
        p_encfacpro_iva: item.ENCFACPRO_IVA,
        p_encfacpro_estado: item.ENCFACPRO_ESTADO,
        p_pro_codigo: item.PRO_CODIGO,
        p_encfacpro_contacto: item.ENCFACPRO_CONTACTO,
        p_encfacpro_referencia: item.ENCFACPRO_REFERENCIA,
        p_encfacpro_total: Number(item.ENCFACPRO_TOTAL.toFixed(2)),
        p_encfacpro_totalneto: item.ENCFACPRO_TOTALNETO,
        p_encfacpro_valordes: item.ENCFACPRO_VALORDES,
        p_encfacpro_porcedes: item.ENCFACPRO_PORCEDES,
        p_encfacpro_valoriva: item.ENCFACPRO_VALORIVA,
        p_encfacpro_porceiva: item.ENCFACPRO_PORCEIVA,
        p_encfacpro_valorice: item.ENCFACPRO_VALORICE,
        p_encfacpro_baseiva: item.ENCFACPRO_BASEIVA,
        p_encfacpro_baseice: item.ENCFACPRO_BASEICE,
        p_encfacpro_basecero: item.ENCFACPRO_BASECERO,
        p_encfacpro_comentario: item.ENCFACPRO_COMENTARIO,
        p_encfacpro_otros: item.ENCFACPRO_OTROS,
        p_encfacpro_distcargos: item.ENCFACPRO_DISTCARGOS,
        p_encfacpro_numdiasplazo: item.ENCFACPRO_NUMDIASPLAZO,
        p_encfacpro_idcretri: item.ENCFACPRO_IDCRETRI,
        p_encfacpro_serie: item.ENCFACPRO_SERIE,
        p_encfacpro_autorizacion: item.ENCFACPRO_AUTORIZACION,
        p_encfacpro_tipcom: item.ENCFACPRO_TIPCOM,
        p_maemotivo_codigo: item.MAEMOTIVO_CODIGO,
        P_encfacpro_fechacadfac: item.ENCFACPRO_FECHACADFAC,
        p_encfacpro_numingreso: item.ENCFACPRO_NUMINGRESO,
        p_encfacpro_numliquidacion: item.ENCFACPRO_NUMLIQUIDACION,
        p_encfacpro_tipodes: item.ENCFACPRO_TIPODES,
        p_usu_identificacion: this.usuario.identificacion,
        p_encfacpro_gasto: item.ENCFACPRO_GASTO,
        p_encordcom_numero: item.ENCORDCOM_NUMERO,
        p_encfacpro_noobjetoiva: item.ENCFACPRO_NOOBJETOIVA,
        P_encfacpro_basenoobjiva: item.ENCFACPRO_BASENOOBJIVA,
        p_trnformapago_codigo: item.TRNFORMAPAGO_CODIGO,
        p_encfacpro_flag:   item.ENCFACPRO_FLAG,
        p_encfacpro_origen: item.ENCFACPRO_ORIGEN === null || item.ENCFACPRO_ORIGEN  === undefined?'':item.ENCFACPRO_ORIGEN ,
        
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarEncabezadoFact(item): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/4afc75d8a5bd4c', {

      elementos: {
        p_encfacpro_numero: item.ENCFACPRO_NUMERO,
        p_com_codigo: item.COM_CODIGO,
        p_encfacpro_fechaemision: item.ENCFACPRO_FECHAEMISION,
        p_encfacpro_fechavencimiento: item.ENCFACPRO_FECHAVENCIMIENTO,
        p_encfacpro_fecharec: item.ENCFACPRO_FECHAREC,
        p_encfacpro_iva: item.ENCFACPRO_IVA,
        p_encfacpro_estado: item.ENCFACPRO_ESTADO,
        p_pro_codigo: item.PRO_CODIGO,
        p_encfacpro_contacto: item.ENCFACPRO_CONTACTO,
        p_encfacpro_referencia: item.ENCFACPRO_REFERENCIA,
        p_encfacpro_total: item.ENCFACPRO_TOTAL,
        p_encfacpro_totalneto: item.ENCFACPRO_TOTALNETO,
        p_encfacpro_valordes: item.ENCFACPRO_VALORDES,
        p_encfacpro_porcedes: item.ENCFACPRO_PORCEDES,
        p_encfacpro_valoriva: item.ENCFACPRO_VALORIVA,
        p_encfacpro_porceiva: item.ENCFACPRO_PORCEIVA,
        p_encfacpro_valorice: item.ENCFACPRO_VALORICE,
        p_encfacpro_baseiva: item.ENCFACPRO_BASEIVA,
        p_encfacpro_baseice: item.ENCFACPRO_BASEICE,
        p_encfacpro_basecero: item.ENCFACPRO_BASECERO,
        p_encfacpro_comentario: item.ENCFACPRO_COMENTARIO,
        p_encfacpro_otros: item.ENCFACPRO_OTROS,
        p_encfacpro_distcargos: item.ENCFACPRO_DISTCARGOS,
        p_encfacpro_numdiasplazo: item.ENCFACPRO_NUMDIASPLAZO,
        p_encfacpro_idcretri: item.ENCFACPRO_IDCRETRI,
        p_encfacpro_serie: item.ENCFACPRO_SERIE,
        p_encfacpro_autorizacion: item.ENCFACPRO_AUTORIZACION,
        p_encfacpro_tipcom: item.ENCFACPRO_TIPCOM,
        p_maemotivo_codigo: item.MAEMOTIVO_CODIGO,
        P_encfacpro_fechacadfac: item.ENCFACPRO_FECHACADFAC,
        p_encfacpro_numingreso: item.ENCFACPRO_NUMINGRESO,
        p_encfacpro_numliquidacion: item.ENCFACPRO_NUMLIQUIDACION,
        p_encfacpro_tipodes: item.ENCFACPRO_TIPODES,
        p_usu_identificacion: this.usuario.identificacion,
        p_encfacpro_gasto: item.ENCFACPRO_GASTO,
        p_encordcom_numero: item.ENCORDCOM_NUMERO,
        p_encfacpro_noobjetoiva: item.ENCFACPRO_NOOBJETOIVA,
        P_encfacpro_basenoobjiva: item.ENCFACPRO_BASENOOBJIVA,
        p_trnformapago_codigo: item.TRNFORMAPAGO_CODIGO,
        p_encfacpro_origen: '',
      }
    }, this.confIniciales.httpOptions());
  }


  insertarDetalleFacturaPromise(item): Promise<any[]> {
    return this.http.post<any>(this.way + '/compras/factproveedores/4afc735dd53d4c', {
      elementos: {
        p_encfacpro_numero: item.ENCFACPRO_NUMERO,
        p_com_codigo: item.COM_CODIGO,
        p_detfacpro_linea: item.DETFACPRO_LINEA,
        p_detfacpro_tipodet: item.DETFACPRO_TIPODET,
        p_bod_codigo: item.BOD_CODIGO,
        p_detfacpro_codigo: item.DETFACPRO_CODIGO,
        p_detfacpro_descripcion: item.DETFACPRO_DESCRIPCION,
        p_detfacpro_unidad: item.DETFACPRO_UNIDAD,
        p_detfacpro_cantidad: item.DETFACPRO_CANTIDAD,
        p_detfacpro_entregado: item.DETFACPRO_ENTREGADO,
        p_detfacpro_costo: item.DETFACPRO_COSTO,
        p_detfacpro_valdes: item.DETFACPRO_VALDES,
        p_detfacpro_pordes: item.DETFACPRO_PORDES,
        p_detfacpro_total: item.DETFACPRO_TOTAL,
        p_detfacpro_poriva: item.DETFACPRO_PORIVA,
        p_detfacpro_iva: Number(item.DETFACPRO_IVA.toFixed(2)),
        p_detfacpro_tribice: item.DETFACPRO_TRIBICE,
        p_detfacpro_ice: item.DETFACPRO_ICE,
        p_detfacpro_porceice: item.DETFACPRO_PORCEICE,
        p_detfacpro_baseiva: item.DETFACPRO_BASEIVA,
        p_detfacpro_baseice: item.DETFACPRO_BASEICE,
        p_detfacpro_basecero: item.DETFACPRO_BASECERO,
        p_encordcom_numero: item.ENCORDCOM_NUMERO,
        p_detordcom_linea: item.DETORDCOM_LINEA,
        p_encnotrec_numero: item.ENCNOTREC_NUMERO,
        p_detnotrec_linea: item.DETNOTREC_LINEA,
        p_detfacpro_promocion: item.DETFACPRO_PROMOCION,
        p_detfacpro_totalincp: item.DETFACPRO_TOTALINCP,
        p_detfacpro_pordes2: item.DETFACPRO_PORDES2,
        p_detfacpro_pordes3: item.DETFACPRO_PORDES3,
        p_cen_codigo: item.CEN_CODIGO,
        p_detfacpro_factor: item.DETFACPRO_FACTOR,
        p_detfacpro_preciofob: item.DETFACPRO_PRECIOFOB,
        p_detfacpro_esquemadoc: item.DETFACPRO_ESQUEMADOC,
        p_detfacpro_tiposri: item.DETFACPRO_TIPOSRI,
        p_detfacpro_precioa: item.DETFACPRO_PRECIOA,
        p_detfacpro_preciob: item.DETFACPRO_PRECIOB,
        p_detfacpro_precioc: item.DETFACPRO_PRECIOC,
        p_trnsolfac_codigo: item.TRNSOLFAC_CODIGO,
        p_trnsolfac_linea: item.TRNSOLFAC_LINEA,
        p_encreq_numero: item.ENCREQ_NUMERO,
        p_detreq_linea: item.DETREQ_LINEA,
        p_encgre_codigo: item.ENCGRE_CODIGO,
        p_detgre_linea: item.DETGRE_LINEA,
        p_detfacpro_basenoobjiva: item.DETFACPRO_BASENOOBJIVA,
        p_detfacpro_tribasenoobjiva: item.DETFACPRO_TRIBASENOOBJIVA,
        p_detfacpro_cantidadund: item.DETFACPRO_CANTIDADUND,
        p_tipgas_codigo: item.TIPGAS_CODIGO,
        p_detfacpro_po: '',
        p_detfacpro_ajuste: item.DETFACPRO_AJUSTE,
        p_tariiva_codigo:item.TARIIVA_CODIGO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarDetalleFactura(item): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/4afc735dd53d4c', {
      elementos: {
        p_encfacpro_numero: item.ENCFACPRO_NUMERO,
        p_com_codigo: item.COM_CODIGO,
        p_detfacpro_linea: item.DETFACPRO_LINEA,
        p_detfacpro_tipodet: item.DETFACPRO_TIPODET,
        p_bod_codigo: item.BOD_CODIGO,
        p_detfacpro_codigo: item.DETFACPRO_CODIGO,
        p_detfacpro_descripcion: item.DETFACPRO_DESCRIPCION,
        p_detfacpro_unidad: item.DETFACPRO_UNIDAD,
        p_detfacpro_cantidad: item.DETFACPRO_CANTIDAD,
        p_detfacpro_entregado: item.DETFACPRO_ENTREGADO,
        p_detfacpro_costo: item.DETFACPRO_COSTO,
        p_detfacpro_valdes: item.DETFACPRO_VALDES,
        p_detfacpro_pordes: item.DETFACPRO_PORDES,
        p_detfacpro_total: item.DETFACPRO_TOTAL,
        p_detfacpro_poriva: item.DETFACPRO_PORIVA,
        p_detfacpro_iva: item.DETFACPRO_IVA,
        p_detfacpro_tribice: item.DETFACPRO_TRIBICE,
        p_detfacpro_ice: item.DETFACPRO_ICE,
        p_detfacpro_porceice: item.DETFACPRO_PORCEICE,
        p_detfacpro_baseiva: item.DETFACPRO_BASEIVA,
        p_detfacpro_baseice: item.DETFACPRO_BASEICE,
        p_detfacpro_basecero: item.DETFACPRO_BASECERO,
        p_encordcom_numero: item.ENCORDCOM_NUMERO,
        p_detordcom_linea: item.DETORDCOM_LINEA,
        p_encnotrec_numero: item.ENCNOTREC_NUMERO,
        p_detnotrec_linea: item.DETNOTREC_LINEA,
        p_detfacpro_promocion: item.DETFACPRO_PROMOCION,
        p_detfacpro_totalincp: item.DETFACPRO_TOTALINCP,
        p_detfacpro_pordes2: item.DETFACPRO_PORDES2,
        p_detfacpro_pordes3: item.DETFACPRO_PORDES3,
        p_cen_codigo: item.CEN_CODIGO,
        p_detfacpro_factor: item.DETFACPRO_FACTOR,
        p_detfacpro_preciofob: item.DETFACPRO_PRECIOFOB,
        p_detfacpro_esquemadoc: item.DETFACPRO_ESQUEMADOC,
        p_detfacpro_tiposri: item.DETFACPRO_TIPOSRI,
        p_detfacpro_precioa: item.DETFACPRO_PRECIOA,
        p_detfacpro_preciob: item.DETFACPRO_PRECIOB,
        p_detfacpro_precioc: item.DETFACPRO_PRECIOC,
        p_trnsolfac_codigo: item.TRNSOLFAC_CODIGO,
        p_trnsolfac_linea: item.TRNSOLFAC_LINEA,
        p_encreq_numero: item.ENCREQ_NUMERO,
        p_detreq_linea: item.DETREQ_LINEA,
        p_encgre_codigo: item.ENCGRE_CODIGO,
        p_detgre_linea: item.DETGRE_LINEA,
        p_detfacpro_basenoobjiva: item.DETFACPRO_BASENOOBJIVA,
        p_detfacpro_tribasenoobjiva: item.DETFACPRO_TRIBASENOOBJIVA,
        p_detfacpro_cantidadund: item.DETFACPRO_CANTIDADUND,
        p_tipgas_codigo: item.TIPGAS_CODIGO,
        p_detfacpro_po: '',
        p_detfacpro_ajuste: '',
        p_tariiva_codigo:item.TARIIVA_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }

  insertarDetalleFacturaProm(item): Promise<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/4afc735dd53d4c', {
      elementos: {
        p_encfacpro_numero: item.ENCFACPRO_NUMERO,
        p_com_codigo: item.COM_CODIGO,
        p_detfacpro_linea: item.DETFACPRO_LINEA,
        p_detfacpro_tipodet: item.DETFACPRO_TIPODET,
        p_bod_codigo: item.BOD_CODIGO,
        p_detfacpro_codigo: item.DETFACPRO_CODIGO,
        p_detfacpro_descripcion: item.DETFACPRO_DESCRIPCION,
        p_detfacpro_unidad: item.DETFACPRO_UNIDAD,
        p_detfacpro_cantidad: item.DETFACPRO_CANTIDAD,
        p_detfacpro_entregado: item.DETFACPRO_ENTREGADO,
        p_detfacpro_costo: item.DETFACPRO_COSTO,
        p_detfacpro_valdes: item.DETFACPRO_VALDES,
        p_detfacpro_pordes: item.DETFACPRO_PORDES,
        p_detfacpro_total: item.DETFACPRO_TOTAL,
        p_detfacpro_poriva: item.DETFACPRO_PORIVA,
        p_detfacpro_iva: item.DETFACPRO_IVA,
        p_detfacpro_tribice: item.DETFACPRO_TRIBICE,
        p_detfacpro_ice: item.DETFACPRO_ICE,
        p_detfacpro_porceice: item.DETFACPRO_PORCEICE,
        p_detfacpro_baseiva: item.DETFACPRO_BASEIVA,
        p_detfacpro_baseice: item.DETFACPRO_BASEICE,
        p_detfacpro_basecero: item.DETFACPRO_BASECERO,
        p_encordcom_numero: item.ENCORDCOM_NUMERO,
        p_detordcom_linea: item.DETORDCOM_LINEA,
        p_encnotrec_numero: item.ENCNOTREC_NUMERO,
        p_detnotrec_linea: item.DETNOTREC_LINEA,
        p_detfacpro_promocion: item.DETFACPRO_PROMOCION,
        p_detfacpro_totalincp: item.DETFACPRO_TOTALINCP,
        p_detfacpro_pordes2: item.DETFACPRO_PORDES2,
        p_detfacpro_pordes3: item.DETFACPRO_PORDES3,
        p_cen_codigo: item.CEN_CODIGO,
        p_detfacpro_factor: item.DETFACPRO_FACTOR,
        p_detfacpro_preciofob: item.DETFACPRO_PRECIOFOB,
        p_detfacpro_esquemadoc: item.DETFACPRO_ESQUEMADOC,
        p_detfacpro_tiposri: item.DETFACPRO_TIPOSRI,
        p_detfacpro_precioa: item.DETFACPRO_PRECIOA,
        p_detfacpro_preciob: item.DETFACPRO_PRECIOB,
        p_detfacpro_precioc: item.DETFACPRO_PRECIOC,
        p_trnsolfac_codigo: item.TRNSOLFAC_CODIGO,
        p_trnsolfac_linea: item.TRNSOLFAC_LINEA,
        p_encreq_numero: item.ENCREQ_NUMERO,
        p_detreq_linea: item.DETREQ_LINEA,
        p_encgre_codigo: item.ENCGRE_CODIGO,
        p_detgre_linea: item.DETGRE_LINEA,
        p_detfacpro_basenoobjiva: item.DETFACPRO_BASENOOBJIVA,
        p_detfacpro_tribasenoobjiva: item.DETFACPRO_TRIBASENOOBJIVA,
        p_detfacpro_cantidadund: item.DETFACPRO_CANTIDADUND,
        p_tipgas_codigo: item.TIPGAS_CODIGO,
        p_detfacpro_po: '',
        p_detfacpro_ajuste: '',
        p_tariiva_codigo:item.TARIIVA_CODIGO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarDetalleFactura_I(item): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/4afc6bbaf533b0', {
      elementos: {
        p_encfacpro_numero: item.ENCFACPRO_NUMERO,
        p_com_codigo: item.COM_CODIGO,
        p_detfacpro_linea: item.DETFACPRO_LINEA,
        p_detfacpro_tipodet: item.DETFACPRO_TIPODET,
        p_bod_codigo: item.BOD_CODIGO,
        p_detfacpro_codigo: item.DETFACPRO_CODIGO,
        p_detfacpro_descripcion: item.DETFACPRO_DESCRIPCION,
        p_detfacpro_unidad: item.DETFACPRO_UNIDAD,
        p_detfacpro_cantidad: item.DETFACPRO_CANTIDAD,
        p_detfacpro_entregado: item.DETFACPRO_ENTREGADO,
        p_detfacpro_costo: item.DETFACPRO_COSTO,
        p_detfacpro_valdes: item.DETFACPRO_VALDES,
        p_detfacpro_pordes: item.DETFACPRO_PORDES,
        p_detfacpro_total: item.DETFACPRO_TOTAL,
        p_detfacpro_poriva: item.DETFACPRO_PORIVA,
        p_detfacpro_iva: item.DETFACPRO_IVA,
        p_detfacpro_tribice: item.DETFACPRO_TRIBICE,
        p_detfacpro_ice: item.DETFACPRO_ICE,
        p_detfacpro_porceice: item.DETFACPRO_PORCEICE,
        p_detfacpro_baseiva: item.DETFACPRO_BASEIVA,
        p_detfacpro_baseice: item.DETFACPRO_BASEICE,
        p_detfacpro_basecero: item.DETFACPRO_BASECERO,
        p_encordcom_numero: item.ENCORDCOM_NUMERO,
        p_detordcom_linea: item.DETORDCOM_LINEA,
        p_encnotrec_numero: item.ENCNOTREC_NUMERO,
        p_detnotrec_linea: item.DETNOTREC_LINEA,
        p_detfacpro_promocion: item.DETFACPRO_PROMOCION,
        p_detfacpro_totalincp: item.DETFACPRO_TOTALINCP,
        p_detfacpro_pordes2: item.DETFACPRO_PORDES2,
        p_detfacpro_pordes3: item.DETFACPRO_PORDES3,
        p_cen_codigo: item.CEN_CODIGO,
        p_detfacpro_factor: item.DETFACPRO_FACTOR,
        p_detfacpro_preciofob: item.DETFACPRO_PRECIOFOB,
        p_detfacpro_esquemadoc: item.DETFACPRO_ESQUEMADOC,
        p_detfacpro_tiposri: item.DETFACPRO_TIPOSRI,
        p_detfacpro_precioa: item.DETFACPRO_PRECIOA,
        p_detfacpro_preciob: item.DETFACPRO_PRECIOB,
        p_detfacpro_precioc: item.DETFACPRO_PRECIOC,
        p_trnsolfac_codigo: item.TRNSOLFAC_CODIGO,
        p_trnsolfac_linea: item.TRNSOLFAC_LINEA,
        p_encreq_numero: item.ENCREQ_NUMERO,
        p_detreq_linea: item.DETREQ_LINEA,
        p_encgre_codigo: item.ENCGRE_CODIGO,
        p_detgre_linea: item.DETGRE_LINEA,
        p_detfacpro_basenoobjiva: item.DETFACPRO_BASENOOBJIVA,
        p_detfacpro_tribasenoobjiva: item.DETFACPRO_TRIBASENOOBJIVA,
        p_detfacpro_codigoalt: item.DETFACPRO_CODIGOALT,
        p_detfacpro_fracciones: item.DETFACPRO_FRACCIONES
      }
    }, this.confIniciales.httpOptions());
  }

  insertarCxpTrncobro(item): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/95f8ccf77766180', {
      elementos: {
        p_trncobro_nrodoc: item.TRNCOBRO_NRODOC,
        p_com_codigo: item.COM_CODIGO,
        p_TRNCOBRO_FECHATRN: item.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_TIPODOC: item.TRNCOBRO_TIPODOC,
        p_TRNCOBRO_NROCOMPROBANTE: item.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_CONCEPTO: item.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_IMPORTE: item.TRNCOBRO_IMPORTE,
        p_TRNCOBRO_FECHAVENCE: item.TRNCOBRO_FECHAVENCE,
        p_pro_CODIGO: item.PRO_CODIGO,
        p_APLORG_CODIGO: item.APLORG_CODIGO,
        p_trncobro_usuario_act: this.usuario.identificacion,
        p_trncobro_fecharecepcion: item.TRNCOBRO_FECHARECEPCION,
        p_trncobro_referenciaexterna: item.TRNCOBRO_REFERENCIAEXTERNA,
        p_con_codigo: item.CON_CODIGO,
      }
    }, this.confIniciales.httpOptions());
  }

  insertarCxpTrncobroProm(item): Promise<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/95f8ccf77766180', {
      elementos: {
        p_trncobro_nrodoc: item.TRNCOBRO_NRODOC,
        p_com_codigo: item.COM_CODIGO,
        p_TRNCOBRO_FECHATRN: item.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_TIPODOC: item.TRNCOBRO_TIPODOC,
        p_TRNCOBRO_NROCOMPROBANTE: item.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_CONCEPTO: item.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_IMPORTE: item.TRNCOBRO_IMPORTE,
        p_TRNCOBRO_FECHAVENCE: item.TRNCOBRO_FECHAVENCE,
        p_pro_CODIGO: item.PRO_CODIGO,
        p_APLORG_CODIGO: item.APLORG_CODIGO,
        p_trncobro_usuario_act: this.usuario.identificacion,
        p_trncobro_fecharecepcion: item.TRNCOBRO_FECHARECEPCION,
        p_trncobro_referenciaexterna: item.TRNCOBRO_REFERENCIAEXTERNA,
        p_con_codigo: item.CON_CODIGO,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarFactura(strNumFact): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/3ab2b3d4cefb6a', {
      elementos: {
        p_encfacpro_numero: strNumFact,
        p_com_codigo: '01',
        p_asi_nro: ''
      }
    }, this.confIniciales.httpOptions());
  }


  insertarTRNCARGOS(item): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/4afceeeec56e18', {
      elementos: {
        p_encfacpro_numero: item.ENCFACPRO_NUMERO,
        p_com_codigo: item.COM_CODIGO,
        p_cargo_codigo: item.CARGO_CODIGO,
        p_trncargos_importe: Number(item.TRNCARGOS_IMPORTE),
        P_con_codigo: item.CON_CODIGO,
        P_cen_codigo: item.CEN_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }

  extraerCodigo(strNumFact): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/7719dda9986ca00', {
      elementos: {
        p_ENCFACPRO_numero: strNumFact,
        p_COM_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  ActEstTransKardex(item2): Observable<any> {
    console.log(item2);
    return this.http.post<any>(this.way + '/compras/factproveedores/533aee77bb55140', {
      elementos: {
        p_encordcom_numero: item2.ENCORDCOM_NUMERO,
        p_encnotrec_numero: item2.ENCNOTREC_NUMERO,
        p_com_codigo: '01',
        p_encfacpro_numero: item2.ENCFACPRO_NUMERO,
        p_Esquema: item2.DETFACPRO_ESQUEMADOC
      }
    }, this.confIniciales.httpOptions());
  }

  existePagosCxC(encfacnumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/ee325ccaa0cce000', {
      elementos: {
        TRNDOCUMENTO_NRODOC: encfacnumero,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  consultarCargos(strencfacpronumero): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/198bf18adc31c', {
      elementos: {
        encfacpronumero: strencfacpronumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  ingDatosAdicCli(strNumFact): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/95e0d5754d93280', {
      elementos: {
        p_encfac_numero: strNumFact,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }


  mostrarTotalesFacExp(dblNeto, dblBaseIva, dblBaseICe, dblBaseCero,
    dblValorIva, dblValorIce, dblTotal, dblBaseNoObjetoIva) {
    // Neto
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_TOTALNETO = Number(dblNeto).toFixed(this.confIniciales.getNumDecSist());
    dblNeto = Number(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_TOTALNETO);
    // Base Iva
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASEIVA = Number(dblBaseIva).toFixed(this.confIniciales.getNumDecSist());
    dblBaseIva = Number(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASEIVA);

    // Base Ice
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASEICE = Number(dblBaseICe).toFixed(this.confIniciales.getNumDecSist());
    dblBaseICe = Number(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASEICE);

    // Base Cero
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASECERO = Number(dblBaseCero).toFixed(this.confIniciales.getNumDecSist());
    dblBaseICe = Number(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASECERO);

    // Valor del Iva
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_VALORIVA = Number(dblValorIva).toFixed(this.confIniciales.getNumDecSist());
    dblValorIva = Number(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_VALORIVA);

    // Valor Ice
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_VALORICE = Number(dblValorIce).toFixed(this.confIniciales.getNumDecSist());
    dblValorIce = Number(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_VALORICE);
    // Valor Total
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_TOTAL = Number(dblTotal).toFixed(this.confIniciales.getNumDecSist());
    dblTotal = Number(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_TOTAL);
    // Valor BaseNoObjetoIva
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASENOOBJIVA = Number(dblBaseNoObjetoIva).toFixed(this.confIniciales.getNumDecSist());
    dblBaseNoObjetoIva = Number(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASENOOBJIVA);
  }

  eliminarDetalleFact(detfacpro): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/3ab2b35dd53d4c', {
      elementos: {
        p_encfacpro_numero: detfacpro.ENCFACPRO_NUMERO,
        p_COM_codigo: '01',
        p_detfacpro_linea: detfacpro.DETFACPRO_LINEA
      }
    }, this.confIniciales.httpOptions());
  }


  seleccionarRegistro(tipo, encfacpronumero): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/71d57336e84b9c', {
      elementos: {
        p_tipo: tipo,
        p_com_codigo: '01',
        p_encfacpro_numero: encfacpronumero,
      }
    }, this.confIniciales.httpOptions());
  }

  getEncfacpro(encfacnumero): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/41dd75d8f5333c', {
      elementos: {
        ENCFACPRO_NUMERO: encfacnumero
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
//CDPJ
extraercobro(encfacnumero):Promise<any> {
  return this.http.post<any>(this.way + '/compras/factproveedores/3317e7abd56d4c00000', {
    elementos: {
      ENCFACPRO_NUMERO: encfacnumero
    }
  }, this.confIniciales.httpOptions()).toPromise();
}
getElectrndocumento(encfacnumero):Promise<any> {
  return this.http.post<any>(this.way + '/compras/factproveedores/41dd755cceeeec0000000000', {
    elementos: {
      ENCFACPRO_NUMERO: encfacnumero
    }
  }, this.confIniciales.httpOptions()).toPromise();
}
obtenerNumFE(encgrupo) {

  return this.http.post<any[]>(this.way + '/ventas/guiaremision/c2faebbb77f5a00', {
    elementos: {
      num_fe: encgrupo,
      
    }
  }, this.confIniciales.httpOptions()).toPromise();

}
leerValidarRucsFacElec() {

  return this.http.post<any[]>(this.way + '/seg/compania/198bf3d5eefcce000000000', {
    elementos: {},
    esquema: {
      usuario: this.usuarioEsquema.esquema,
      password: this.usuarioEsquema.password
    }
  }, this.confIniciales.httpOptions()).toPromise();;
}
verificarDetalleFacpro(EncFacpro){
  return this.http.post<any[]>(this.way + '/compras/factproveedores/198bf3d5eab6d7000000000000000000000000', {
    elementos: {
      p_numero:EncFacpro
    
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
getRevisarAbonos(EncFacpro){
  return this.http.post<any[]>(this.way + '/compras/factproveedores/3317e7abd52f180000000000', {
    elementos: {
      TRNDOCUMENTO_NRODOC: EncFacpro,
      COM_CODIGO: '01'
    
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
obtenerEstFE(): Observable<any[]> {
  return this.http.post<any[]>(this.way + '/ventas/facturas/198bf3d5eab6ee0000', {
    elementos: {   
    }
  }, this.confIniciales.httpOptions());
}
validarcencodigo(cencodigo) {

  return this.http.post<any[]>(this.way + '/ventas/guiaremision/1f5564d56d8ebf0000000000000', {
    elementos: {
      cen_codigo: cencodigo,
      con_codigo: '01'
    }
  }, this.confIniciales.httpOptions()).toPromise();

}
verificarDetalleDevFacproArtCntaSer(EncFacpro){
  return this.http.post<any[]>(this.way + '/compras/factproveedores/41dd56facbf55c0000000000', {
    elementos: {
      p_numero:EncFacpro
    
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
//CDPJ
  getDetfacpro(encfacnumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/41dd6bbaf5333c', {
      elementos: {
        ENCFACPRO_NUMERO: encfacnumero
      },
    }, this.confIniciales.httpOptions());
  }

  getDetfacproPromise(encfacnumero): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/10775aeebd4ccf000', {
      /*sql: 'SELECT D.*,A.ART_SERIALFLAG' +
        ' FROM COM_DETFACPRO D, INV_MAEARTICULO A' +
        ' WHERE D.DETFACPRO_CODIGO = A.ART_CODIGO AND ENCFACPRO_NUMERO=:ENCFACPRO_NUMERO ORDER BY DETFACPRO_LINEA',*/
      elementos: {
        ENCFACPRO_NUMERO: encfacnumero
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDatosProveedor(procodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/83bad5771ccef00', {
      elementos: {
        PRO_CODIGO: procodigo,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerNumeracionG(strTipoNum): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/617dbfaceda990', {
      elementos: {
        NUM_DOCREF: strTipoNum,
        COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
  }

  erTipGasCodigo1() {
    return this.http.post(this.way + '/compras/factproveedores/76fb2cc15c661c0', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  erConTipGas1(strConCodigo) {
    return this.http.post(this.way + '/compras/factproveedores/3b6cc5fb2cc140', {
      elementos: {
        CON_CODIGO: strConCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erConCodigo1(strRetencionCodigo) {
    return this.http.post(this.way + '/compras/factproveedores/1db662ecc3641', {
      elementos: {
        RETENCION_CODIGO: strRetencionCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erMaeProveedor(strParametro) {
    return this.http.post(this.way + '/compras/factproveedores/3b7653b3bc7dce', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erCodSriDescripcion1(strFechaEmision, strParametro) {
    return this.http.post(this.way + '/compras/factproveedores/3b6cc379b935c0', {
      elementos: {
        FechaEmision: strFechaEmision,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erCodSriDescripcion2(strFechaEmision, strTipoDoc, strParametro) {
    return this.http.post(this.way + '/compras/factproveedores/3b6cc379b935c2', {
      elementos: {
        FechaEmision: strFechaEmision,
        TipoDoc: strTipoDoc,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erBodNombre1(strParametro) {
    return this.http.post(this.way + '/compras/factproveedores/76d786df165edc0', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erCodSriDescripcion3(strFechaEmision, strCodigo) {
    return this.http.post(this.way + '/compras/factproveedores/3b6cc379b935c4', {
      elementos: {
        ENCFACPRO_FECHAEMISION: strFechaEmision,
        ENCFACPRO_IDCRETRI: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erCodSriDescripcion4(strFechaEmision, strCodigo) {
    return this.http.post(this.way + '/compras/factproveedores/76d986f3726bb80', {
      elementos: {
        ENCFACPRO_FECHAEMISION: strFechaEmision,
        ENCFACPRO_TIPCOM: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erBodNombre2(strBodCodigo) {
    return this.http.post(this.way + '/compras/factproveedores/76d786ba0abe2c0', {
      elementos: {
        BOD_CODIGO: strBodCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erCodSriDescripcion5(strCodigo) {
    return this.http.post(this.way + '/compras/factproveedores/76d986f3726bb85', {
      elementos: {
        ENCFACPRO_REGIMENTRANSPOR: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erEncfacproNumero1(strReferencia, strProcodigo, strSerie, strEnfacnumero, tipcom) {
    return this.http.post(this.way + '/compras/factproveedores/3b6ebb1eabfac0', {
      elementos: {
        ENCFACPRO_REFERENCIA: strReferencia,
        PRO_CODIGO: strProcodigo,
        ENCFACPRO_SERIE: strSerie,
        ENCFACPRO_NUMERO: strEnfacnumero,
        ENCFACPRO_TIPCOM: tipcom
      },
    }, this.confIniciales.httpOptions());
  }

  erCodSriDescripcion6(strCodigo) {
    return this.http.post(this.way + '/compras/factproveedores/76d986f3726bb86', {
      elementos: {
        ENCFACPRO_IDCRETRI: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erEncfacproNumero2(strSerie, strReferencia, strProcodigo) {
    return this.http.post(this.way + '/compras/factproveedores/3b6ebb1eabfac2', {
      elementos: {
        ENCFACPRO_SERIE: strSerie,
        ENCFACPRO_REFERENCIA: strReferencia,
        PRO_CODIGO: strProcodigo,
      },
    }, this.confIniciales.httpOptions());
  }

  erCodSriDescripcion7(strCodigo) {
    return this.http.post(this.way + '/compras/factproveedores/76d986f3726bb87', {
      elementos: {
        ENCFACPRO_TIPCOM: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erAutSriAutorizaSri1(strFecha, strProcodigo, strTipcom, strSerie, strReferencia) {
    return this.http.post(this.way + '/compras/factproveedores/76d5eeabdde6e40', {
      elementos: {
        ENCFACPRO_FECHAEMISION: strFecha,
        PRO_CODIGO: strProcodigo,
        ENCFACPRO_TIPCOM: strTipcom,
        ENCFACPRO_SERIE: strSerie,
        ENCFACPRO_REFERENCIA: strReferencia
      },
    }, this.confIniciales.httpOptions());
  }

  erEncfacproPagolocalext1(strEncfacproNumero) {
    return this.http.post(this.way + '/compras/factproveedores/76f2a862b862ac0', {
      elementos: {
        ENCFACPRO_NUMERO: strEncfacproNumero
      },
    }, this.confIniciales.httpOptions());
  }

  erCounttrnkardexotr1(strEncfacproNumero) {
    return this.http.post(this.way + '/compras/factproveedores/edb31ebf777c7800', {
      elementos: {
        ENCFACPRO_NUMERO: strEncfacproNumero
      },
    }, this.confIniciales.httpOptions());
  }

  erCounttrnkardexotr2(strNumSerie) {
    return this.http.post(this.way + '/compras/factproveedores/edb31ebf777c7802', {
      elementos: {
        TRNKARDEXOTR_NUMSERIE: strNumSerie
      },
    }, this.confIniciales.httpOptions());
  }

  erCounttrnkardexotr3(strNumSerie) {
    return this.http.post(this.way + '/compras/factproveedores/edb31ebf777c7803', {
      elementos: {
        TRNKARDEXOTR_NUMSERIE: strNumSerie
      },
    }, this.confIniciales.httpOptions());
  }

  erClicorreo1(strClicodigo) {
    return this.http.post(this.way + '/compras/factproveedores/3b6cac9d654aa0', {
      elementos: {
        CLI_CODIGO: strClicodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erClicorreo1Prom(strClicodigo) {
    return this.http.post(this.way + '/compras/factproveedores/3b6cac9d654aa0', {
      elementos: {
        CLI_CODIGO: strClicodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erProcorreo1(strProcodigo) {
    return this.http.post(this.way + '/compras/factproveedores/76f3bc331bdbb00', {
      elementos: {
        PRO_CODIGO: strProcodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erProcorreo1Prom(strProcodigo) {
    return this.http.post(this.way + '/compras/factproveedores/76f3bc331bdbb00', {
      elementos: {
        PRO_CODIGO: strProcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erComCorreoCopFac1() {
    return this.http.post(this.way + '/compras/factproveedores/76dd654aacc6600', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  erConCodigoRet332(strProcodigo) {
    return this.http.post(this.way + '/compras/factproveedores/76d98bb36ee8c80', {
      elementos: {
        PRO_CODIGO: strProcodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erCountMaeretprov1(strProcodigo) {
    return this.http.post(this.way + '/compras/factproveedores/6d98f5f6eee760', {
      elementos: {
        PRO_CODIGO: strProcodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erFechaSystem() {
    return this.http.post(this.way + '/compras/factproveedores/3b6f7322ae4b9c', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  erCountEncfacpro1(strNumero) {
    return this.http.post(this.way + '/compras/factproveedores/3b6cc79d763f3c', {
      elementos: {
        NUMBLOQARCHXML: strNumero
      },
    }, this.confIniciales.httpOptions());
  }

  erConCodigo1Proveedor1(strProcodigo) {
    return this.http.post(this.way + '/compras/factproveedores/76d98bb30d0e780', {
      elementos: {
        PRO_CODIGO: strProcodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erDatoEncfacpro1() {
    return this.http.post(this.way + '/compras/factproveedores/edb55dc3aef53000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  erMaeProveedor2(strParametro) {
    return this.http.post(this.way + '/compras/factproveedores/76eca76778fb9c0', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erCodSriDescripcion8(strFechaEmision, strParametro) {
    return this.http.post(this.way + '/compras/factproveedores/3b6cc379b935c8', {
      elementos: {
        FechaEmision: strFechaEmision,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erCodSriDescripcion9(strFechaEmision, strTipoDoc, strParametro) {
    return this.http.post(this.way + '/compras/factproveedores/3b6cc379b935c9', {
      elementos: {
        FechaEmision: strFechaEmision,
        TipoDoc: strTipoDoc,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erMaeCodSri1(strParametro) {
    return this.http.post(this.way + '/compras/factproveedores/76eca7330de6e40', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erCodSriDescripcion10(strParametro) {
    return this.http.post(this.way + '/compras/factproveedores/3b6cc379b935c10', {
      elementos: {
        TRNFORMAPAGO_CODIGO: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erEncfacproNumero3(strReferencia, strProcodigo, strSerie, strEnfacnumero) {
    return this.http.post(this.way + '/compras/factproveedores/3b6ebb1eabfac4', {
      elementos: {
        ENCFACPRO_REFERENCIA: strReferencia,
        PRO_CODIGO: strProcodigo,
        ENCFACPRO_SERIE: strSerie,
        ENCFACPRO_NUMERO: strEnfacnumero
      },
    }, this.confIniciales.httpOptions());
  }

  erEncfacproNumero4(strReferencia, strSerie, strEnfacnumero) {
    return this.http.post(this.way + '/compras/factproveedores/10775d763d4cc0000', {
      elementos: {
        ENCFACPRO_REFERENCIA: strReferencia,
        ENCFACPRO_SERIE: strSerie,
        ENCFACPRO_NUMERO: strEnfacnumero
      },
    }, this.confIniciales.httpOptions());
  }

  erArtCodigo1(strDetfacnumero) {
    return this.http.post(this.way + '/compras/factproveedores/3b6adf5986ca00', {
      elementos: {
        DETFACPRO_CODIGO: strDetfacnumero
      },
    }, this.confIniciales.httpOptions());
  }

  erUniCodigo1(strDetfacUnidad) {
    return this.http.post(this.way + '/compras/factproveedores/3b7ebc9986ca00', {
      elementos: {
        DETFACPRO_UNIDAD: strDetfacUnidad
      },
    }, this.confIniciales.httpOptions());
  }


  erConCodigo2(strDetfacCodigo) {
    return this.http.post(this.way + '/compras/factproveedores/1db662ecc3642', {
      elementos: {
        DETFACPRO_CODIGO: strDetfacCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erSerComCodigo1(strDetfacCodigo) {
    return this.http.post(this.way + '/compras/factproveedores/3b7c76d986ca00', {
      elementos: {
        DETFACPRO_CODIGO: strDetfacCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erConTipGas2(strDetfacCodigo) {
    return this.http.post(this.way + '/compras/factproveedores/76d98bf65982b80', {
      elementos: {
        DETFACPRO_CODIGO: strDetfacCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erArtSerialFlag1(strDetfacCodigo) {
    return this.http.post(this.way + '/compras/factproveedores/3b6adf78edbea0', {
      elementos: {
        DETFACPRO_CODIGO: strDetfacCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erEncNotRecNumero1(strNumero, strCodigo) {
    return this.http.post(this.way + '/compras/factproveedores/3b77c776e65fc0', {
      elementos: {
        ENCFACPRO_NUMERO: strNumero,
        DETFACPRO_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erNumSecActual1(strNumero) {
    return this.http.post(this.way + '/compras/factproveedores/76efeb71cc533c0', {
      elementos: {
        numclase: strNumero
      },
    }, this.confIniciales.httpOptions());
  }

  erNumSecActual1Prom(strNumero) {
    return this.http.post(this.way + '/compras/factproveedores/76efeb71cc533c0', {
      elementos: {
        numclase: strNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erRefNombrec1(strEstado) {
    return this.http.post(this.way + '/compras/factproveedores/76f6e7df165edc0', {
      elementos: {
        ENCFACPRO_ESTADO: strEstado
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erBodCodigo1(strCodigo) {
    return this.http.post(this.way + '/compras/factproveedores/76d786b30d94300', {
      elementos: {
        BOD_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erArtCodigo1Prom(strDetfacnumero) {
    return this.http.post(this.way + '/compras/factproveedores/3b6adf5986ca00', {
      elementos: {
        DETFACPRO_CODIGO: strDetfacnumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erUniCodigo1Prom(strDetfacUnidad) {
    return this.http.post(this.way + '/compras/factproveedores/3b7ebc9986ca00', {
      elementos: {
        DETFACPRO_UNIDAD: strDetfacUnidad
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erConCodigo2Prom(strDetfacCodigo) {
    return this.http.post(this.way + '/compras/factproveedores/1db662ecc3642', {
      elementos: {
        DETFACPRO_CODIGO: strDetfacCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }


  erConTipGas1Prom(strConCodigo) {
    return this.http.post(this.way + '/compras/factproveedores/3b6cc5fb2cc140', {
      elementos: {
        CON_CODIGO: strConCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erSerComCodigo1Prom(strDetfacCodigo) {
    return this.http.post(this.way + '/compras/factproveedores/3b7c76d986ca00', {
      elementos: {
        DETFACPRO_CODIGO: strDetfacCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erConTipGas2Prom(strDetfacCodigo) {
    return this.http.post(this.way + '/compras/factproveedores/76d98bf65982b80', {
      elementos: {
        DETFACPRO_CODIGO: strDetfacCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erEncfacproPagolocalext1Prom(strEncfacproNumero) {
    return this.http.post(this.way + '/compras/factproveedores/76f2a862b862ac0', {
      elementos: {
        ENCFACPRO_NUMERO: strEncfacproNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erAsiNro1Prom(strEncfacproNumero) {
    return this.http.post(this.way + '/compras/factproveedores/edab92bef01', {
      elementos: {
        ENCFACPRO_NUMERO: strEncfacproNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getBodegaUsuario(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/41dd5e1bee795c', {
      elementos: {
        USUIDENTIFICACION: this.usuario.identificacion,
        COMCODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerListasPrecios(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/41ddacb9d56770', {
      elementos: {
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerBodegasInternas(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/41dd5e1b2bf5dc', {
      elementos: {
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerSaldoCliente(strCodliente): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/83bbc555b865680', {
      elementos: {
        CODCLIENTE: strCodliente,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerSaldoClienteFac(strCodliente, strNumFac): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/20eef1556e195a0000', {
      elementos: {
        CODCLIENTE: strCodliente,
        COM_CODIGO: '01',
        NUMFAC: strNumFac
      }
    }, this.confIniciales.httpOptions());
  }

  consultarArticuloSel(strCodArticulo): Observable<any[]> {
    const FPLOTSIMc = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FPLOTSIM').CFG_VALOR1;
    return this.http.post<any[]>(this.way + '/compras/factproveedores/662fcf555bef1c0', {
      elementos: {
        strCodArticu: strCodArticulo,
        COM_CODIGO: '01'
      },
      elemento: {
        FPLOTSIM: FPLOTSIMc
      }
    }, this.confIniciales.httpOptions());
  }


  anularFactura(strRazon, txtNumero): Observable<any> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/157f554f533be', {
      elementos: {
        Razon: strRazon,
        Numero: txtNumero
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarEstadoDocumento(strNumero): Observable<any> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/299df3b9d6b70c', {
      elementos: {
        ENCFACPRO_NUMERO: strNumero
      }
    }, this.confIniciales.httpOptions());
  }


  insertarEleTrnDocumento(eletrndoc): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/95f8eabbbbbb700', {
      elementos: {
        p_trndoc_numdoc: eletrndoc.TRNDOC_NUMDOC,
        p_trndoc_tipo: eletrndoc.TRNDOC_TIPO,
        p_trndoc_estado: eletrndoc.TRNDOC_ESTADO,
        p_trndoc_resultado: eletrndoc.TRNDOC_RESULTADO,
        p_trndoc_ubicaarch: eletrndoc.TRNDOC_UBICAARCH,
        p_trndoc_secuencial: eletrndoc.TRNDOC_SECUENCIAL,
        p_usuidentificacion: this.usuario.identificacion,
        p_com_codigo: eletrndoc.COM_CODIGO,
        p_trndoc_tipoamb_fe: eletrndoc.TRNDOC_TIPOAMB_FE
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerLogFE(docNum: string, tipoFac: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/30bebaf5c41ee', {
      elementos: {
        TRNDOC_NUMDOC: docNum,
        TRNDOC_TIPO: tipoFac
      }
    }, this.confIniciales.httpOptions());
  }

  calcularTotales() {
    console.log('ENTER---FAC', this.idcellfacpro )
    this.dblPorcIva = 12;
    for (let fila = 1; fila <= this.detfacproarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      if (this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_TIPODET !== '*') {
        // if (this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_CODIGO.length > 0) {
        //if (Number(this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_VALDES) !== 0) {
          if (this.idcellfacpro === 'DETFACPRO_VALDES') {
          let dblValor1 = 0;
          let dblValor2 = 0;
          dblValor1 = this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_CANTIDAD *
            this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_COSTO;
          dblValor2 = (this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_VALDES * 100) / dblValor1;
          this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_PORDES = dblValor2;
        } else {
          //if (Number(this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_PORDES) !== 0) {
            if (this.idcellfacpro === 'DETFACPRO_PORDES'){  
            let dblValor1 = 0;
            let dblValor2 = 0;
            dblValor1 = this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_CANTIDAD *
              this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_COSTO;
            dblValor2 = (this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_PORDES * dblValor1) / 100;
            this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_VALDES = dblValor2;
          }
        }
        // }
      }
    }

    this.calcularCostoTotal();

    this.SumarTotal();

    // decimales del detalle de la factura
    for (let fila = 1; fila <= this.detfacproarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_CANTIDAD =
        Number(this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_CANTIDAD).toFixed(this.decimalesCANTIDADfac);
      this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_COSTO =
        Number(this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_COSTO).toFixed(this.decimalesCOSTOfac);
      this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_TOTAL =
        Number(this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_TOTAL).toFixed(this.decimalesTOTALDetFacPro);
      this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_PORDES =
        Number(this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_PORDES).toFixed(3);//GSRF
      this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_VALDES =
        Number(this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_VALDES).toFixed(this.decimalesCOSTOfac);//GSRF
      this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_PORDES2 =
        Number(this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_PORDES2).toFixed(this.confIniciales.getNumDecSist());
      this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_PORDES3 =
        Number(this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_PORDES3).toFixed(this.confIniciales.getNumDecSist());
      // this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_PORIVA =
      //   Number(this.detfacproarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFACPRO_PORIVA).toFixed(this.confIniciales.getNumDecSist());
    }

    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_TOTAL =
      Number(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_TOTAL).toFixed(this.confIniciales.getNumDecSist());
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_TOTALNETO =
      Number(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_TOTALNETO).toFixed(this.confIniciales.getNumDecSist());
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASEIVA =
      Number(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASEIVA).toFixed(this.confIniciales.getNumDecSist());
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASEICE =
      Number(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASEICE).toFixed(this.confIniciales.getNumDecSist());
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASECERO =
      Number(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASECERO).toFixed(this.confIniciales.getNumDecSist());
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_VALORIVA =
      Number(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_VALORIVA).toFixed(this.confIniciales.getNumDecSist());
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_VALORICE =
      Number(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_VALORICE).toFixed(this.confIniciales.getNumDecSist());
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASENOOBJIVA =
      Number(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASENOOBJIVA).toFixed(this.confIniciales.getNumDecSist());
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_VALORDES =
      Number(this.init.quitarComas(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_VALORDES)).toFixed(this.confIniciales.getNumDecSist());
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_PORCEDES =
      Number(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_PORCEDES).toFixed(this.confIniciales.getNumDecSist());

    this.comas();
  }

  comas() {
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_TOTAL = this.init.moneyValidation(this.init.quitarComas(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_TOTAL));
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_TOTALNETO = this.init.moneyValidation(this.init.quitarComas(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_TOTALNETO));
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASEIVA = this.init.moneyValidation(this.init.quitarComas(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASEIVA));
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASEICE = this.init.moneyValidation(this.init.quitarComas(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASEICE));
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASECERO = this.init.moneyValidation(this.init.quitarComas(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASECERO));
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_VALORIVA = this.init.moneyValidation(this.init.quitarComas(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_VALORIVA));
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_VALORICE = this.init.moneyValidation(this.init.quitarComas(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_VALORICE));
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASENOOBJIVA = this.init.moneyValidation(this.init.quitarComas(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASENOOBJIVA));
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_VALORDES = this.init.moneyValidation(this.init.quitarComas(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_VALORDES));
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_PORCEDES = this.init.moneyValidation(this.init.quitarComas(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_PORCEDES));
  }

  async sumarTotalCargos() {
    let sumcargos = 0;
    for (const cargo of this.trncargosarray[this.init.tabs[this.init.tabindex].indice]) {
      sumcargos = sumcargos + Number(cargo.TRNCARGOS_IMPORTE);
      cargo.TRNCARGOS_IMPORTE = Number(cargo.TRNCARGOS_IMPORTE).toFixed(this.confIniciales.getNumDecSist());
    }
    this.totalcargosarray[this.init.tabs[this.init.tabindex].indice] = sumcargos.toFixed(this.confIniciales.getNumDecSist());
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].TOTALCARGOS = this.totalcargosarray[this.init.tabs[this.init.tabindex].indice];
  }

  calcularTotalesRetencion() {
    let intIndiceLista = 0;

    let item: SaciTrnretencion = null;

    let dblBaseGeneral = 0;
    let dbltotal = 0;

    let dblTotalRetencion = 0;

    for (intIndiceLista = 0; intIndiceLista < this.trnretencionarray[this.init.tabs[this.init.tabindex].indice].length; intIndiceLista++) {
      item = this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][intIndiceLista];

      if (item.RETENCION_CODIGO !== '' || item.CON_CODIGO !== '') {
        if (item.RETENCION_TIPRET === 'RF') {
          dblBaseGeneral = Number(item.TRNRETENCION_BASE0) + Number(item.TRNRETENCION_BASEG) + Number(item.TRNRETENCION_BASENG);
          item.TRNRETENCION_BASE = dblBaseGeneral;
        } else {
          dblBaseGeneral = Number(item.TRNRETENCION_BASE);
        }
        dbltotal = dblBaseGeneral * Number(item.TRNRETENCION_PORCENTAJE) / 100;
        // Total retenido
        item.TRNRETENCION_TOTALRETENIDO = dbltotal;
      }

      if (item.RETENCION_CODIGO !== '' || item.CON_CODIGO !== '') {
        dblTotalRetencion = dblTotalRetencion + Number(this.FixedTo(item.TRNRETENCION_TOTALRETENIDO, this.confIniciales.getNumDecSist()));
      }
    }

    this.encretarray[this.init.tabs[this.init.tabindex].indice].TOTALRETENCIONES = Number(dblTotalRetencion).toFixed(this.confIniciales.getNumDecSist());
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].TOTALRETENCION = Number(dblTotalRetencion).toFixed(this.confIniciales.getNumDecSist());

    // decimales del detalle de retenciones
    for (let fila = 1; fila <= this.trnretencionarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_BASE0 = Number(this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_BASE0).toFixed(this.confIniciales.getNumDecSist());
      this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_BASEG = Number(this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_BASEG).toFixed(this.confIniciales.getNumDecSist());
      this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_BASENG = Number(this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_BASENG).toFixed(this.confIniciales.getNumDecSist());
      this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_BASE = Number(this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_BASE).toFixed(this.confIniciales.getNumDecSist());
      this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_PORCENTAJE = Number(this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_PORCENTAJE)
        .toFixed(this.confIniciales.getNumDecSist());
      this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_TOTALRETENIDO = Number(this.FixedTo(this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_TOTALRETENIDO, this.confIniciales.getNumDecSist()))
        .toFixed(this.confIniciales.getNumDecSist());
    }
    this.encretarray[this.init.tabs[this.init.tabindex].indice].TOTALRETENCIONES = Number(this.encretarray[this.init.tabs[this.init.tabindex].indice].TOTALRETENCIONES).toFixed(this.confIniciales.getNumDecSist());
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].TOTALRETENCION = Number(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].TOTALRETENCION).toFixed(this.confIniciales.getNumDecSist());
  }

  calcularTotalConRetenciones() {
    let dblTotalRetenciones = 0;
    let dblTotalCargos = 0;

    dblTotalRetenciones = Number(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].TOTALRETENCION);
    dblTotalCargos = Number(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].TOTALCARGOS);

    let dblSuma = 0;

    dblSuma = Number(this.init.quitarComas(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_TOTALNETO)) +
      Number(this.init.quitarComas(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_VALORICE)) +
      Number(this.init.quitarComas(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_VALORIVA)) +
      dblTotalCargos - dblTotalRetenciones;

    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_TOTAL = Number(dblSuma).toFixed(this.confIniciales.getNumDecSist());
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].TOTALRETENCION = Number(dblTotalRetenciones).toFixed(this.confIniciales.getNumDecSist());

    this.comas();
  }

  leerConfiguracionDatosElectronicos(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/2ae76daaee18f80000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }


  calcularCostoTotal(): number {
    let intIndiceLista = 0;

    let dblCostoTotal = 0;
    let dblValor1d = 0;
    let dblValor2d = 0;

    let dblValor1 = 0;
    let dblValor2 = 0;
    let dblValor3 = 0;
    let dblValor4 = 0;
    let dblValor5 = 0;
    let dblValor6 = 0;
    let dblValor7 = 0;

    for (intIndiceLista = 0; intIndiceLista < this.detfacproarray[this.init.tabs[this.init.tabindex].indice].length; intIndiceLista++) {
      const item = this.detfacproarray[this.init.tabs[this.init.tabindex].indice][intIndiceLista];
      {
        if (item.DETFACPRO_CODIGO !== '') {

          dblValor1 = 0;
          dblValor2 = 0;
          dblValor3 = 0;
          dblValor4 = 0;
          dblValor5 = 0;
          dblValor6 = 0;
          dblValor7 = 0;

          if (Number(item.DETFACPRO_VALDES) !== 0) {
            dblValor1d = 0;
            dblValor2d = 0;
            dblValor1d = Number(item.DETFACPRO_CANTIDAD)
              * Number(item.DETFACPRO_COSTO);
            dblValor2d = (Number(item.DETFACPRO_VALDES) * 100)
              / dblValor1d;
            item.DETFACPRO_PORDES = dblValor2d;

          }


          //dblValor1 = Number(item.DETFACPRO_CANTIDAD) * Number(item.DETFACPRO_COSTO);
          //CDPJ
          let val=10 ** this.decimalesTOTALDetFacPro;
          let total=Math.round(item.DETFACPRO_CANTIDAD * val * item.DETFACPRO_COSTO) / val;
          dblValor1 = Number(total.toFixed(this.decimalesTOTALDetFacPro));
          console.log('TOTALITEM',dblValor1);
          //CDPJ
          dblValor2 = (Number(item.DETFACPRO_PORDES) / 100) * dblValor1;
          dblValor3 = dblValor1 - dblValor2;
          dblValor4 = (Number(item.DETFACPRO_PORDES2) / 100) * dblValor3;
          dblValor5 = dblValor3 - dblValor4;
          dblValor6 = (Number(item.DETFACPRO_PORDES3) / 100) * dblValor5;
          dblValor7 = dblValor5 - dblValor6;


          item.DETFACPRO_TOTAL = dblValor7.toFixed(this.decimalesTOTALDetFacPro);

          //

          dblCostoTotal = dblCostoTotal + dblValor7;

        }
      }
    }
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_TOTALNETO = dblCostoTotal.toFixed(this.confIniciales.getNumDecSist());


    return dblCostoTotal;

  }

  async SumarTotal() {
    let dblBaseIva = 0;
    let dblBaseIce = 0;
    let dblBaseCero = 0;
    let dblOtros = 0;
    let dblBaseNoObjetoIva = 0;
    let dblBaseTIva = 0;
    let dblBaseTIce = 0;
    let dblBaseTCero = 0;
    let dblBaseTNoObjetoIva = 0;
    let dblSuma = 0;
    let dblSumaIva = 0;
    let dblSumaIce = 0;
    let dblTotalDet = 0;
    let dblVaDeIce = 0;
    let dblVaDeIva = 0;
    let dblVaDeCero = 0;
    let dblValNoObjetoIva = 0;
    let dblDescuentoP = 0;
    let dblDescuento = 0;
    let dblSumaTotal = 0;
    let intIndiceLista = 0;
    let dblTotalRetenciones = 0;
    let dblTotalCargos = 0;
    let dblSumaFac = 0;
    const ICEDESC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ICEDESC').CFG_VALOR1;
    const ICEBASEIVA = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ICEBASEIVA').CFG_VALOR1;

    if (this.strFacturaExportadaarray[this.init.tabs[this.init.tabindex].indice] === '2') {
      return;
    }

    if (this.strTipoDescuentoarray[this.init.tabs[this.init.tabindex].indice] === 'P') {
      this.dblValorDescGlbarray[this.init.tabs[this.init.tabindex].indice] = this.calcularCostoTotal()
        * this.dblPorcDescGlbarray[this.init.tabs[this.init.tabindex].indice] / 100;
    }

    if (this.strTipoDescuentoarray[this.init.tabs[this.init.tabindex].indice] === 'V') {
      this.dblPorcDescGlbarray[this.init.tabs[this.init.tabindex].indice] = this.dblValorDescGlbarray[this.init.tabs[this.init.tabindex].indice]
        * 100 / this.calcularCostoTotal();
    }

    dblDescuento = this.dblValorDescGlbarray[this.init.tabs[this.init.tabindex].indice]; // Descuento por valor
    dblDescuentoP = this.dblPorcDescGlbarray[this.init.tabs[this.init.tabindex].indice]; // Descuento por porcentaje

    this.lblTotalDscValorarray[this.init.tabs[this.init.tabindex].indice] = '0';

    for (intIndiceLista = 0; intIndiceLista < this.detfacproarray[this.init.tabs[this.init.tabindex].indice].length; intIndiceLista++) {
      const item = this.detfacproarray[this.init.tabs[this.init.tabindex].indice][intIndiceLista];
      if (item.DETFACPRO_CODIGO !== '') { // servicio, articulo, cuenta contable, activo, fijo
        if (item.DETFACPRO_TIPODET !== 'O') {
          dblSumaTotal = dblSumaTotal + Number(item.DETFACPRO_TOTAL);
          if (this.strTipoIvaarray[this.init.tabs[this.init.tabindex].indice] === 'Exento') {
            dblSumaIva = 0; // No suma iva
            if (item.DETFACPRO_TRIBICE === 'S') { // Si Tributa Ice
              if (ICEDESC === 1) {// Cálculo de la Base del ICE en compras sin que afecte el descuento
                dblBaseIce = Number(item.DETFACPRO_CANTIDAD) * Number(item.DETFACPRO_COSTO);
              } else {
                dblBaseIce = Number(item.DETFACPRO_TOTAL) * (1 - dblDescuentoP / 100);
              }

              if (Number(item.DETFACPRO_PORCEICE) !== 0) {
                dblVaDeIce = dblBaseIce
                  * Number(item.DETFACPRO_PORCEICE) / 100;
              } else {
                dblVaDeIce = Number(item.DETFACPRO_ICE) * Number(item.DETFACPRO_CANTIDAD);
              }
            } else {
              dblBaseIce = 0;
              dblVaDeIce = 0;
            }

            dblVaDeCero = Number(item.DETFACPRO_TOTAL) * (1 - dblDescuentoP / 100);
            dblBaseTIce = dblBaseTIce + Number(dblBaseIce);
            dblSumaIce = dblSumaIce + Number(dblVaDeIce);
            dblBaseTCero = dblBaseTCero + Number(dblVaDeCero);

          } // Fin si ex Exento

          if (this.strTipoIvaarray[this.init.tabs[this.init.tabindex].indice] === 'Incluido') {
            dblVaDeIce = 0;
            dblSumaIce = 0;
            dblTotalDet = Number(item.DETFACPRO_TOTAL)
              * (1 - dblDescuentoP / 100);
            if (Number(item.DETFACPRO_IVA) !== 0) { // Si aplica Iva
              dblBaseIva = Number(item.DETFACPRO_TOTAL)
                / (1 + Number(item.DETFACPRO_IVA) / 100);
              dblVaDeIva = dblBaseIva * Number(item.DETFACPRO_IVA)
                / 100;
              dblBaseCero = 0;
            } else {
              if (item.DETFACPRO_TRIBASENOOBJIVA === 'S') { // Si no aplica Iva
                dblBaseIva = 0;
                dblVaDeIva = 0;
                dblBaseCero = 0;
                dblBaseNoObjetoIva = dblTotalDet;
              } else { // Si no aplica Iva
                dblBaseIva = 0;
                dblVaDeIva = 0;
                dblBaseCero = dblTotalDet;
                dblBaseNoObjetoIva = 0;
              }
            }
            dblBaseTIva = dblBaseTIva + Number(dblBaseIva);
            dblSumaIva = dblSumaIva + Number(dblVaDeIva);
            dblBaseTCero = dblBaseTCero + Number(dblBaseCero);

          }// Fin Incluido

          if (this.strTipoIvaarray[this.init.tabs[this.init.tabindex].indice] === 'Neto + Iva') {
            if (item.DETFACPRO_TRIBICE === 'S') { // Si Tributa Ice
              if (ICEDESC === 1) { // Cálculo de la Base del ICE en compras sin que afecte el descuento
                dblBaseIce = Number(item.DETFACPRO_CANTIDAD)
                  * Number(item.DETFACPRO_COSTO);
              } else {
                dblBaseIce = Number(item.DETFACPRO_TOTAL)
                  * (1 - dblDescuentoP / 100);
              }

              if (Number(item.DETFACPRO_PORCEICE) !== 0) { // si el porcentaje del ice es <>0
                dblVaDeIce = dblBaseIce
                  * Number(item.DETFACPRO_PORCEICE) / 100; // Valor del ice
              } else {
                dblVaDeIce = Number(item.DETFACPRO_ICE)
                  * Number(item.DETFACPRO_CANTIDAD);
              }
            } else { // Fin Si tributa Ice
              // Si no tributa Ice
              dblBaseIce = 0;
              dblVaDeIce = 0;
            } // Fin si no tributa Ice

            dblBaseTIce = dblBaseTIce + Number(dblBaseIce);
            dblSumaIce = dblSumaIce + Number(dblVaDeIce);

            if (ICEBASEIVA === 1) {
              dblBaseIva = Number(item.DETFACPRO_ICE)
                * (1 - dblDescuentoP / 100);
              dblBaseIva = dblBaseIva + Number(dblVaDeIce);
            } else {
              dblBaseIva = Number(item.DETFACPRO_TOTAL)
                * (1 - dblDescuentoP / 100);
            }

            if (Number(item.DETFACPRO_PORIVA) !== 0) { // Si aplica Iva
              if (item.DETFACPRO_PROMOCION === 'S') {// Si se trata de una promoción considera el iva
                dblBaseIva = Number(item.DETFACPRO_CANTIDAD) * Number(item.DETFACPRO_COSTO);
                if (ICEBASEIVA === 1) {
                  dblBaseIva = Number(item.DETFACPRO_TOTAL) * (1 - dblDescuentoP / 100);
                  dblBaseIva = dblBaseIva + Number(dblVaDeIce);
                } else {
                  dblBaseIva = dblBaseIva
                    * (1 - dblDescuentoP / 100);
                }
              }// Fin si es una promoción

              dblVaDeIva = dblBaseIva * Number(item.DETFACPRO_PORIVA)
                / 100;
              dblVaDeCero = 0;
              dblValNoObjetoIva = 0;
            } else { // Fin Si aplica Iva
              /* No tributa Iva */
              // si tributa base no objeto de iva
              if (item.DETFACPRO_TRIBASENOOBJIVA === 'S') {
                dblVaDeIva = 0;
                dblVaDeCero = 0;
                dblValNoObjetoIva = dblBaseIva;
                dblBaseIva = 0;
              } else {
                dblVaDeIva = 0;
                dblVaDeCero = dblBaseIva;
                dblBaseIva = 0;
                dblValNoObjetoIva = 0;
                dblBaseNoObjetoIva = 0;
              }
            }

            dblSumaIva = dblSumaIva + Number(dblVaDeIva);
            dblBaseTIva = dblBaseTIva + Number(dblBaseIva);
            dblBaseTCero = dblBaseTCero + Number(dblVaDeCero);
            dblBaseTNoObjetoIva = dblBaseTNoObjetoIva
              + Number(dblValNoObjetoIva);

          } // Fin Si Neto + Iva

          item.DETFACPRO_IVA = dblVaDeIva;
          item.DETFACPRO_ICE = dblVaDeIce;
          item.DETFACPRO_BASEIVA = dblBaseIva;
          item.DETFACPRO_BASEICE = dblBaseIce;
          item.DETFACPRO_BASECERO = dblVaDeCero;
          item.DETFACPRO_BASENOOBJIVA = dblValNoObjetoIva;

        } else {// Fin si no Otros
          // Si es otros
          dblOtros = dblOtros + item.DETFACPRO_TOTAL;
        } // Fin si no es otros
      } // servicio,articulo,cuenta contable,activo fijo
    } // Fin de for

    if (this.encfacproarray[this.init.tabs[this.init.tabindex].indice].TOTALRETENCION === undefined) {
      await this.calcularTotalesRetencion();
    }
    if (this.encfacproarray[this.init.tabs[this.init.tabindex].indice].TOTALCARGOS === undefined) {
      await this.sumarTotalCargos();
    }

    dblTotalRetenciones = this.encfacproarray[this.init.tabs[this.init.tabindex].indice].TOTALRETENCION;
    dblTotalCargos = this.encfacproarray[this.init.tabs[this.init.tabindex].indice].TOTALCARGOS;

    if (this.strTipoIvaarray[this.init.tabs[this.init.tabindex].indice] === 'Exento') { // Neto

      this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_TOTALNETO = dblSumaTotal;
      this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASEIVA = 0;
      this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASEICE = dblBaseTIce;
      this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASECERO = dblBaseTCero;
      this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_VALORIVA = 0;
      this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_VALORICE = dblSumaIce;
      this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASENOOBJIVA = dblBaseTNoObjetoIva;
      dblSuma = Number(dblSumaTotal) + Number(dblSumaIce) + Number(dblSumaIva) - Number(dblDescuento)
        + Number(dblTotalCargos) - Number(dblTotalRetenciones);
      dblSumaFac = Number(dblSumaTotal) + Number(dblSumaIce) + Number(dblSumaIva) - Number(dblDescuento)
        + Number(dblTotalCargos);

      this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_TOTAL = dblSuma;
      this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_TOTALBD = dblSumaFac;//CDPJ
    } // Fin si Exento

    if (this.strTipoIvaarray[this.init.tabs[this.init.tabindex].indice] === 'Neto + Iva') { // Neto
      this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_TOTALNETO = dblSumaTotal;
      this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASEIVA = dblBaseTIva;
      this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASEICE = dblBaseTIce;
      this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASECERO = dblBaseTCero;
      this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_VALORIVA = dblSumaIva;
      this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_VALORICE = dblSumaIce;
      this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASENOOBJIVA = dblBaseTNoObjetoIva;
      dblSuma = Number(dblSumaTotal) + Number(dblSumaIce) + Number(dblSumaIva) - Number(dblDescuento)
        + Number(dblTotalCargos) - Number(dblTotalRetenciones);
      dblSumaFac = Number(dblSumaTotal) + Number(dblSumaIce) + Number(dblSumaIva) - Number(dblDescuento)
        + Number(dblTotalCargos);

      this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_TOTAL = dblSuma;
      this.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_TOTALBD = dblSumaFac;
    }

  } // FIN DE SUMAR TOTAL


  getNumSerieCmb(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/41ddbfadc76e50', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  getSaciMaeNumeraciong(strSerie): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/83bbc53256bfb00', {
      elementos: {
        TRNRETENCION_SERIE: strSerie
      },
    }, this.confIniciales.httpOptions());
  }


  getConCodigoMaeProv(strCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/20eeb317661b680000', {
      elementos: {
        PRO_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarTrnCargos(strNumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/75656eeeec56e00', {
      elementos: {
        ENCFACPRO_NUMERO: strNumero
      },
    }, this.confIniciales.httpOptions());
  }

  updatePagoLocalExt(strNumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/14cee550c570c70000', {
      elementos: {
        ENCFACPRO_NUMERO: strNumero
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarRetencioncxpTrnResum(strNumero, strProcodigo) {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/eacadb7759dcf000', {
      elementos: {
        ENCFACPRO_NUMERO: strNumero,
        PRO_CODIGO: strProcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarDatosTransp(strRuctranspor, strRazontranspor, strRegimentranspor, strNumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/a675aaee3bb56000', {
      elementos: {
        ENCFACPRO_RUCTRANSPORTISTA: strRuctranspor,
        ENCFACPRO_RAZONTRANSPORTISTA: strRazontranspor,
        ENCFACPRO_REGIMENTRANSPOR: strRegimentranspor,
        ENCFACPRO_NUMERO: strNumero
      },
    }, this.confIniciales.httpOptions());
  }

  actualizarNumRetFact(intNumretsec, strNumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/533b7f5b6eebd40', {
      elementos: {
        intNUMRETSEC: intNumretsec,
        ENCFACPRO_NUMERO: strNumero
      },
    }, this.confIniciales.httpOptions());
  }

  countSacitrnretencion(strNumero, strProcodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/662fdeeefb775c0', {
      elementos: {
        ENCFACPRO_NUMERO: strNumero,
        PRO_CODIGO: strProcodigo
      },
    }, this.confIniciales.httpOptions());
  }

  countComtrnReembolsos(strNumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/662fdeeefb73ac0', {
      elementos: {
        ENCFACPRO_NUMERO: strNumero
      },
    }, this.confIniciales.httpOptions());
  }

  countSacimaeObjper(strUsuario): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/cc5fb653b0b9e800', {
      elementos: {
        USU_IDENTIFICACIONSUP: strUsuario
      },
    }, this.confIniciales.httpOptions());
  }

  verificarPermisosSuperv(strDialogo, strObjeto, strUsuid, strUsuario, intPestaniaCols): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/1f76e4caabfcf6000', {
      elementos: {
        Dialogo: strDialogo,
        Objeto: strObjeto,
        Usuidentificacion: strUsuid,
        Usuariosup: strUsuario,
        intPestaniaCol: intPestaniaCols
      },
    }, this.confIniciales.httpOptions());
  }

  actualizarFormapagofact(strTipopago, strCodfacpro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/14cebf1bb2b2a80000', {
      elementos: {
        TipoPago: strTipopago,
        CodFacpro: strCodfacpro,
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarTrnkardexotr(strEncFacPro, strOrigen, lngLinea, strTipo, strCodArticulo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/eacbbbbd15b6e000', {
      elementos: {
        EncFacPro: strEncFacPro,
        Origen: strOrigen,
        CodArticulo: strCodArticulo,
        Linea: lngLinea,
        Tipo: strTipo
      },
    }, this.confIniciales.httpOptions());
  }

  getTrnkardexotrnumserie(strNumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/107768adb6feb7000', {
      elementos: {
        ENCFACPRO_NUMERO: strNumero
      },
    }, this.confIniciales.httpOptions());
  }

  actualizarCorreosClientes(strCorreo1, strCorreo2, strCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/a677ceb2a55e3000', {
      elementos: {
        Correo1: strCorreo1,
        Correo2: strCorreo2,
        Codigo: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  actualizarCorreosProvee(strCorreo1, strCorreo2, strCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/14cef9d654abcd0000', {
      elementos: {
        Correo1: strCorreo1,
        Correo2: strCorreo2,
        Codigo: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  countSacimaeobj(strDialogo, strObjeto, strUsuid, intPestaniaCols): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/198f5fb653a1e90000', {
      elementos: {
        Dialogo: strDialogo,
        Objeto: strObjeto,
        Usuidentificacion: strUsuid,
        intPestaniaCol: intPestaniaCols
      },
    }, this.confIniciales.httpOptions());
  }

  coutDetpresupuesto(strOrdCompra): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/198f5fad7773b70000', {
      elementos: {
        OrdCompra: strOrdCompra
      },
    }, this.confIniciales.httpOptions());
  }

  countEncrequisicion(strOrdCompra): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/198f5faebb36ed0000', {
      elementos: {
        OrdCompra: strOrdCompra
      },
    }, this.confIniciales.httpOptions());
  }

  getEncordcompra(strOrdCompra): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/83baebb31b6b300', {
      elementos: {
        EncOrdComNumero: strOrdCompra
      },
    }, this.confIniciales.httpOptions());
  }

  getDetordcompra(strOrdCompra): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/83bad7771b6b300', {
      elementos: {
        EncOrdComNumero: strOrdCompra
      },
    }, this.confIniciales.httpOptions());
  }


  getImportesReembolso(strNumFact): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/1077656ce37ddc000', {
      elementos: {
        NumFact: strNumFact
      },
    }, this.confIniciales.httpOptions());
  }

  getsaldofactprov(strCodFacpro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/107778aab70f53000', {
      elementos: {
        CodFacpro: strCodFacpro
      },
    }, this.confIniciales.httpOptions());
  }

  getprocodigoretpro(strProCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/41ddcef0cc377000000', {
      elementos: {
        ProCodigo: strProCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  getNumeroRetConsec(strCODRET1, strCODRET2, strCODRET3, strCODRET4, strCODRET5, strCODRET6): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/10776feb6ddd66000', {
      elementos: {
        CODRET1: strCODRET1,
        CODRET2: strCODRET2,
        CODRET3: strCODRET3,
        CODRET4: strCODRET4,
        CODRET5: strCODRET5,
        CODRET6: strCODRET6
      },
    }, this.confIniciales.httpOptions());
  }

  getDatosElectronicosFact(strNumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/83bad575d573d80', {
      elementos: {
        ENCFACPRO_NUMERO: strNumero
      },
    }, this.confIniciales.httpOptions());
  }

  getArtCodigosAlt(strCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/83baadf5986aa80', {
      elementos: {
        DETFACPRO_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  actualizarArtCodigosAlt(strCodigoAlt0, strCodigoAlt1, strCodigoAlt2, strCodigoAlt, strdetCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/83baadf5986aa80', {
      elementos: {
        CodigoAlt0: strCodigoAlt0,
        CodigoAlt1: strCodigoAlt1,
        CodigoAlt2: strCodigoAlt2,
        DETFACPRO_CODIGOALT: strCodigoAlt,
        DETFACPRO_CODIGO: strdetCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  actualizarDatosElect(strNumDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/299d6abaeab99c', {
      elementos: {
        TRNDOC_NUMDOC: strNumDoc
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarTrnCodAltArt(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/3ab2eeeecc355600000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  insertarTrnCodAlt1(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/12bf3bbbb30d55001', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  insertarTrnCodAlt2(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/12bf3bbbb30d55002', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  insertarTrnCodAlt3(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/12bf3bbbb30d55003', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }


  getArtcodigoTrnCodigoalt(strCodigoAlterno): Observable<any[]> {
    console.log(strCodigoAlterno);
    return this.http.post<any[]>(this.way + '/compras/factproveedores/41dd56facc37bc00000', {
      elementos: {
        CodigoAlterno: strCodigoAlterno
      },
    }, this.confIniciales.httpOptions());
  }

  getMaefarFaccion(strArtcodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/83bb6539eadbf80', {
      elementos: {
        ART_CODIGO: strArtcodigo
      },
    }, this.confIniciales.httpOptions());
  }

  actualizarDatosAdi(strObservaciones, strEncFacPro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/83baa675aaea980', {
      elementos: {
        Observaciones: strObservaciones,
        EncFacPro: strEncFacPro
      },
    }, this.confIniciales.httpOptions());
  }

  actualizarDatosUbidocelec(strPathXml, strEncFacPro, strnumerobloquearchivosxml): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/299d6abbe5c9b800000', {
      elementos: {
        PathXml: strPathXml,
        EncFacPro: strEncFacPro,
        numerobloquearchivosxml: strnumerobloquearchivosxml
      },
    }, this.confIniciales.httpOptions());
  }

  actualizarDistribGastosCostos(intTotalFac, intTotalCargo, strDistribCargos, strNumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/a675b2e760ae3000', {
      elementos: {
        ENCFACPRO_TOTALBD: intTotalFac,
        TOTALCARGOS: intTotalCargo,
        DistribCargos: strDistribCargos,
        ENCFACPRO_NUMERO: strNumero
      },
    }, this.confIniciales.httpOptions());
  }

  actualizarNumsecactual(strNumero, strNumclase): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/533b7f5b8e62980', {
      elementos: {
        numero: strNumero,
        numclase: strNumclase
      },
    }, this.confIniciales.httpOptions());
  }

  actualizarNumsecactualProm(strNumero, strNumclase): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/533b7f5b8e62980', {
      elementos: {
        numero: strNumero,
        numclase: strNumclase
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }


  getinfoadicliq(strNumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/10776577a9b265000', {
      elementos: {
        ENCFACPRO_NUMERO: strNumero
      },
    }, this.confIniciales.httpOptions());
  }

  getSumaCantidadFactProd(strCodNotRec, strCodArticulo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/107779eb67786c000', {
      elementos: {
        CodNotRec: strCodNotRec,
        CodArticulo: strCodArticulo
      },
    }, this.confIniciales.httpOptions());
  }


  getDetdevCantidad(strNumero, strCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/10775aeeb5df63000', {
      elementos: {
        DETDEV_NUMERO: strNumero,
        DETNOTREC_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  getDetOrdenCompra2(strNumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/10775aeee36d66000', {
      elementos: {
        EncNotRecNumero: strNumero
      },
    }, this.confIniciales.httpOptions());
  }

  getEncnotrec(strNumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/83baebb2f8eee00', {
      elementos: {
        EncNotRecNumero: strNumero
      },
    }, this.confIniciales.httpOptions());
  }

  countTrnkardexotr(strArticulo, strNumDoc, intLinea): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/cbf7bbbd15b6e000', {
      elementos: {
        Articulo: strArticulo,
        NumDoc: strNumDoc,
        Linea: intLinea
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarSaciTrnRetencion(strNumero, strProcodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/eacb8a64bbbbf000', {
      elementos: {
        ENCFACPRO_NUMERO: strNumero,
        PRO_CODIGO: strProcodigo
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarSaciTrnRetencionProm(strNumero, strProcodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/eacb8a64bbbbf000', {
      elementos: {
        ENCFACPRO_NUMERO: strNumero,
        PRO_CODIGO: strProcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getSaciMaeNumeraciongProm(strSerie): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/83bbc53256bfb00', {
      elementos: {
        TRNRETENCION_SERIE: strSerie
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getSumaCantidadFactProdProm(strCodNotRec, strCodArticulo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/107779eb67786c000', {
      elementos: {
        CodNotRec: strCodNotRec,
        CodArticulo: strCodArticulo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  updatePagoLocalExtProm(strNumero): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/14cee550c570c70000', {
      elementos: {
        ENCFACPRO_NUMERO: strNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getEncnotrecProm(strNumero): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/83baebb2f8eee00', {
      elementos: {
        EncNotRecNumero: strNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDetOrdenCompra2Prom(strNumero): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/10775aeee36d66000', {
      elementos: {
        EncNotRecNumero: strNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDetdevCantidadProm(strNumero, strCodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/10775aeeb5df63000', {
      elementos: {
        DETDEV_NUMERO: strNumero,
        DETNOTREC_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  countTrnkardexotrProm(strArticulo, strNumDoc, intLinea): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/cbf7bbbd15b6e000', {
      elementos: {
        Articulo: strArticulo,
        NumDoc: strNumDoc,
        Linea: intLinea
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDatosretencion(strNumero): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/41dd6abbb775d8', {
      elementos: {
        ENCFACPRO_NUMERO: strNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  verifExistenciaRetencion(strNumRetencion, strNumeroSerie, strNumero): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/fbb7277192e6e00', {
      elementos: {
        NumRetencion: strNumRetencion,
        NumeroSerie: strNumeroSerie,
        ENCFACPRO_NUMERO: strNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }


  guardarRetencion(trnretencion, encret): Promise<any[]> {
    return this.http.post<any>(this.way + '/compras/factproveedores/21e56dbb775d760000', {
      elementos: {
        p_trnretencion_nro: trnretencion.TRNRETENCION_NRO,
        p_retencion_codigo: trnretencion.RETENCION_CODIGO,
        p_com_codigo: trnretencion.COM_CODIGO,
        p_trnretencion_desc: trnretencion.TRNRETENCION_DESCRIPCION,
        p_trnretencion_base: Number(trnretencion.TRNRETENCION_BASE),
        p_trnretencion_porc: Number(trnretencion.TRNRETENCION_PORCENTAJE),
        p_trnretencion_totr: Number(trnretencion.TRNRETENCION_TOTALRETENIDO),
        p_trncobro_nrocomprobante: trnretencion.ENCFAC_NUMERO,
        p_trnretencion_origen: 'COM',
        p_pro_codigo: trnretencion.PRO_CODIGO,
        p_retencion_nroant: '',
        p_CON_codigo: trnretencion.CON_CODIGO,
        p_cen_codigo: '',
        p_trnretencion_serie: trnretencion.TRNRETENCION_SERIE,
        p_trnretencion_autorizacion: trnretencion.TRNRETENCION_AUTORIZACION,
        p_trnretencion_baseg: Number(trnretencion.TRNRETENCION_BASEG),
        p_trnretencion_base0: Number(trnretencion.TRNRETENCION_BASE0),
        p_trnretencion_baseng: Number(trnretencion.TRNRETENCION_BASENG),
        p_trnretencion_estado: 0,
        p_trnretencion_referencia: trnretencion.ENCFAC_NUMERO,
        p_trnretencion_fechaEmision: trnretencion.TRNRETENCION_FECHAEMISION,
        p_trnretencion_fechapagodiv: trnretencion.TRNRETENCION_FECHAPAGODIV,
        p_trnretencion_imprenpagado: trnretencion.TRNRETENCION_IMPRENPAGADO,
        p_trnretencion_anioutil: trnretencion.TRNRETENCION_ANIOUTIL,
        p_trnretencion_docref: encret.DOCREF
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarNumGAct(strDocref, strSecActual): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/299dbfad0533be', {
      elementos: {
        p_COM_codigo_1: '01',
        p_NUM_docref_2: strDocref,
        p_NUM_secactual_6: strSecActual
      }
    }, this.confIniciales.httpOptions());
  }


  GenerarAsientoContable(encfacpronumero): Promise<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/83aeae4998bf580', {
      elementos: {
        p_encfacpro_numero: encfacpronumero,
        p_com_codigo: '01',
        p_tipdia_codigo: 'AFC',
        p_origen: 'COM',
        p_usuario: this.usuario.identificacion
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  AnularRetencion(encfacpronumero): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/157f576eebaec', {
      elementos: {
        p_encfacpro_numero: encfacpronumero,
        p_com_codigo: '01',
        p_trnretencion_origen: 'COM'
      }
    }, this.confIniciales.httpOptions());
  }

  recalcularCostosArtEliminados(strDocumento, strFecha): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/36e62aacc73aae0000', {
      elementos: {
        p_documento: strDocumento,
        p_com_codigo: '01',
        p_fecha: strFecha
      }
    }, this.confIniciales.httpOptions());
  }

  CambiarEstadoENCFACPRO(lblNumFac, lblAsiNro): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/18ab2ddcebaec80000', {
      elementos: {
        p_ENCFACPRO_numero: lblNumFac,
        p_COM_codigo: '01',
        p_estado: 'A',
        p_asi_nro: lblAsiNro
      }
    }, this.confIniciales.httpOptions());
  }

  recalculaCostosUno(articulo, fecha): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/6dcc55598e77d8', {
      elementos: {
        p_art_codigo: articulo,
        p_com_codigo: '01',
        p_fecha: fecha
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarOC(numerooc): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/a677caacb0c', {
      elementos: {
        p_encordcom_numero: numerooc,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarOCProm(numerooc): Promise<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/a677caacb0c', {
      elementos: {
        p_encordcom_numero: numerooc,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  importarEncfacpro(item): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/12b67aebb1ea66000', {
      elementos: {
        p_encfacpro_numero: item.ENCFACPRO_NUMERO,
        p_com_codigo: item.COM_CODIGO,
        p_encfacpro_fechaemision: item.ENCFACPRO_FECHAEMISION,
        p_encfacpro_fechavencimiento: item.ENCFACPRO_FECHAVENCIMIENTO,
        p_encfacpro_fecharec: item.ENCFACPRO_FECHAREC,
        p_encfacpro_iva: item.ENCFACPRO_IVA,
        p_encfacpro_estado: item.ENCFACPRO_ESTADO,
        p_pro_codigo: item.PRO_CODIGO,
        p_encfacpro_contacto: item.ENCFACPRO_CONTACTO,
        p_encfacpro_referencia: item.ENCFACPRO_REFERENCIA,
        p_encfacpro_total: item.ENCFACPRO_TOTAL,
        p_encfacpro_totalneto: item.ENCFACPRO_TOTALNETO,
        p_encfacpro_valordes: item.ENCFACPRO_VALORDES,
        p_encfacpro_porcedes: item.ENCFACPRO_PORCEDES,
        p_encfacpro_valoriva: item.ENCFACPRO_VALORIVA,
        p_encfacpro_porceiva: item.ENCFACPRO_PORCEIVA,
        p_encfacpro_valorice: item.ENCFACPRO_VALORICE,
        p_encfacpro_baseiva: item.ENCFACPRO_BASEIVA,
        p_encfacpro_baseice: item.ENCFACPRO_BASEICE,
        p_encfacpro_basecero: item.ENCFACPRO_BASECERO,
        p_encfacpro_comentario: item.ENCFACPRO_COMENTARIO,
        p_encfacpro_otros: item.ENCFACPRO_OTROS,
        p_encfacpro_distcargos: item.ENCFACPRO_DISTCARGOS,
        p_encfacpro_numdiasplazo: item.ENCFACPRO_NUMDIASPLAZO,
        p_encfacpro_idcretri: item.ENCFACPRO_IDCRETRI,
        p_encfacpro_serie: item.ENCFACPRO_SERIE,
        p_encfacpro_autorizacion: item.ENCFACPRO_AUTORIZACION,
        p_encfacpro_tipcom: item.ENCFACPRO_TIPCOM,
        p_maemotivo_codigo: item.MAEMOTIVO_CODIGO,
        P_encfacpro_fechacadfac: item.ENCFACPRO_FECHACADFAC,
        p_encfacpro_numingreso: item.ENCFACPRO_NUMINGRESO,
        p_encfacpro_numliquidacion: item.ENCFACPRO_NUMLIQUIDACION,
        p_encfacpro_tipodes: item.ENCFACPRO_TIPODES,
        p_usu_identificacion: this.usuario.identificacion,
        p_encfacpro_gasto: item.ENCFACPRO_GASTO,
        p_encordcom_numero: item.ENCORDCOM_NUMERO,
        p_encfacpro_noobjetoiva: item.ENCFACPRO_NOOBJETOIVA,
        P_encfacpro_basenoobjiva: item.ENCFACPRO_BASENOOBJIVA
      }
    }, this.confIniciales.httpOptions());
  }

  importarDetfacpro(item): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/12b67ad775ea66000', {
      elementos: {
        p_encfacpro_numero: item.ENCFACPRO_NUMERO,
        p_com_codigo: item.COM_CODIGO,
        p_detfacpro_linea: item.DETFACPRO_LINEA,
        p_detfacpro_tipodet: item.DETFACPRO_TIPODET,
        p_bod_codigo: item.BOD_CODIGO,
        p_detfacpro_codigo: item.DETFACPRO_CODIGO,
        p_detfacpro_descripcion: item.DETFACPRO_DESCRIPCION,
        p_detfacpro_unidad: item.DETFACPRO_UNIDAD,
        p_detfacpro_cantidad: item.DETFACPRO_CANTIDAD,
        p_detfacpro_entregado: item.DETFACPRO_ENTREGADO,
        p_detfacpro_costo: item.DETFACPRO_COSTO,
        p_detfacpro_valdes: item.DETFACPRO_VALDES,
        p_detfacpro_pordes: item.DETFACPRO_PORDES,
        p_detfacpro_total: item.DETFACPRO_TOTAL,
        p_detfacpro_poriva: item.DETFACPRO_PORIVA,
        p_detfacpro_iva: item.DETFACPRO_IVA,
        p_detfacpro_tribice: item.DETFACPRO_TRIBICE,
        p_detfacpro_ice: item.DETFACPRO_ICE,
        p_detfacpro_porceice: item.DETFACPRO_PORCEICE,
        p_detfacpro_baseiva: item.DETFACPRO_BASEIVA,
        p_detfacpro_baseice: item.DETFACPRO_BASEICE,
        p_detfacpro_basecero: item.DETFACPRO_BASECERO,
        p_encordcom_numero: item.ENCORDCOM_NUMERO,
        p_detordcom_linea: item.DETORDCOM_LINEA,
        p_encnotrec_numero: item.ENCNOTREC_NUMERO,
        p_detnotrec_linea: item.DETNOTREC_LINEA,
        p_detfacpro_promocion: item.DETFACPRO_PROMOCION,
        p_detfacpro_totalincp: item.DETFACPRO_TOTALINCP,
        p_detfacpro_pordes2: item.DETFACPRO_PORDES2,
        p_detfacpro_pordes3: item.DETFACPRO_PORDES3,
        p_cen_codigo: item.CEN_CODIGO,
        p_detfacpro_factor: item.DETFACPRO_FACTOR,
        p_detfacpro_preciofob: item.DETFACPRO_PRECIOFOB,
        p_detfacpro_esquemadoc: item.DETFACPRO_ESQUEMADOC,
        p_detfacpro_tiposri: item.DETFACPRO_TIPOSRI,
        p_detfacpro_precioa: item.DETFACPRO_PRECIOA,
        p_detfacpro_preciob: item.DETFACPRO_PRECIOB,
        p_detfacpro_precioc: item.DETFACPRO_PRECIOC,
        p_trnsolfac_codigo: item.TRNSOLFAC_CODIGO,
        p_trnsolfac_linea: item.TRNSOLFAC_LINEA,
        p_encreq_numero: item.ENCREQ_NUMERO,
        p_detreq_linea: item.DETREQ_LINEA,
        p_encgre_codigo: item.ENCGRE_CODIGO,
        p_detgre_linea: item.DETGRE_LINEA,
        p_detfacpro_basenoobjiva: item.DETFACPRO_BASENOOBJIVA,
        p_detfacpro_tribasenoobjiva: item.DETFACPRO_TRIBASENOOBJIVA
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarFacturasSubidas(bloque): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/7564f533bcf2e40', {
      elementos: {
        p_numero: bloque
      }
    }, this.confIniciales.httpOptions());
  }

  distribuirCargosCom(numero): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/365ceb15b83316', {
      elementos: {
        p_numero: numero,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }


  // LIQUIDACION DE COMPRA

  insertarEncabezadoLiq(item): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/4afc76dd765658', {
      elementos: {
        p_encfacpro_numero: item.ENCFACPRO_NUMERO,
        p_com_codigo: item.COM_CODIGO,
        p_encfacpro_fechaemision: item.ENCFACPRO_FECHAEMISION,
        p_encfacpro_fechavencimiento: item.ENCFACPRO_FECHAVENCIMIENTO,
        p_encfacpro_fecharec: item.ENCFACPRO_FECHAREC,
        p_encfacpro_iva: item.ENCFACPRO_IVA,
        p_encfacpro_estado: item.ENCFACPRO_ESTADO,
        p_pro_codigo: item.PRO_CODIGO,
        p_encfacpro_contacto: item.ENCFACPRO_CONTACTO,
        p_encfacpro_referencia: item.ENCFACPRO_REFERENCIA,
        p_encfacpro_total: item.ENCFACPRO_TOTAL,
        p_encfacpro_totalneto: item.ENCFACPRO_TOTALNETO,
        p_encfacpro_valordes: item.ENCFACPRO_VALORDES,
        p_encfacpro_porcedes: item.ENCFACPRO_PORCEDES,
        p_encfacpro_valoriva: item.ENCFACPRO_VALORIVA,
        p_encfacpro_porceiva: item.ENCFACPRO_PORCEIVA,
        p_encfacpro_valorice: item.ENCFACPRO_VALORICE,
        p_encfacpro_baseiva: item.ENCFACPRO_BASEIVA,
        p_encfacpro_baseice: item.ENCFACPRO_BASEICE,
        p_encfacpro_basecero: item.ENCFACPRO_BASECERO,
        p_encfacpro_comentario: item.ENCFACPRO_COMENTARIO,
        p_encfacpro_otros: item.ENCFACPRO_OTROS,
        p_encfacpro_distcargos: item.ENCFACPRO_DISTCARGOS,
        p_encfacpro_numdiasplazo: item.ENCFACPRO_NUMDIASPLAZO,
        p_encfacpro_idcretri: item.ENCFACPRO_IDCRETRI,
        p_encfacpro_serie: item.ENCFACPRO_SERIE,
        p_encfacpro_autorizacion: item.ENCFACPRO_AUTORIZACION,
        p_encfacpro_tipcom: item.ENCFACPRO_TIPCOM,
        p_maemotivo_codigo: item.MAEMOTIVO_CODIGO,
        P_encfacpro_fechacadfac: item.ENCFACPRO_FECHACADFAC,
        p_encfacpro_numingreso: item.ENCFACPRO_NUMINGRESO,
        p_encfacpro_numliquidacion: item.ENCFACPRO_NUMLIQUIDACION,
        p_encfacpro_tipodes: item.ENCFACPRO_TIPODES,
        p_usu_identificacion: this.usuario.identificacion,
        p_encfacpro_gasto: item.ENCFACPRO_GASTO,
        p_encordcom_numero: item.ENCORDCOM_NUMERO,
        p_encfacpro_noobjetoiva: item.ENCFACPRO_NOOBJETOIVA,
        P_encfacpro_basenoobjiva: item.ENCFACPRO_BASENOOBJIVA,
        p_trnformapago_codigo: item.TRNFORMAPAGO_CODIGO,
        p_encfacpro_origen: 'LIQ',
      }
    }, this.confIniciales.httpOptions());
  }

  insertarEncabezadoLiqProm(item): Promise<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/4afc76dd765658', {
      elementos: {
        p_encfacpro_numero: item.ENCFACPRO_NUMERO,
        p_com_codigo: item.COM_CODIGO,
        p_encfacpro_fechaemision: item.ENCFACPRO_FECHAEMISION,
        p_encfacpro_fechavencimiento: item.ENCFACPRO_FECHAVENCIMIENTO,
        p_encfacpro_fecharec: item.ENCFACPRO_FECHAREC,
        p_encfacpro_iva: item.ENCFACPRO_IVA,
        p_encfacpro_estado: item.ENCFACPRO_ESTADO,
        p_pro_codigo: item.PRO_CODIGO,
        p_encfacpro_contacto: item.ENCFACPRO_CONTACTO,
        p_encfacpro_referencia: item.ENCFACPRO_REFERENCIA,
        p_encfacpro_total: item.ENCFACPRO_TOTAL,
        p_encfacpro_totalneto: item.ENCFACPRO_TOTALNETO,
        p_encfacpro_valordes: item.ENCFACPRO_VALORDES,
        p_encfacpro_porcedes: item.ENCFACPRO_PORCEDES,
        p_encfacpro_valoriva: item.ENCFACPRO_VALORIVA,
        p_encfacpro_porceiva: item.ENCFACPRO_PORCEIVA,
        p_encfacpro_valorice: item.ENCFACPRO_VALORICE,
        p_encfacpro_baseiva: item.ENCFACPRO_BASEIVA,
        p_encfacpro_baseice: item.ENCFACPRO_BASEICE,
        p_encfacpro_basecero: item.ENCFACPRO_BASECERO,
        p_encfacpro_comentario: item.ENCFACPRO_COMENTARIO,
        p_encfacpro_otros: item.ENCFACPRO_OTROS,
        p_encfacpro_distcargos: item.ENCFACPRO_DISTCARGOS,
        p_encfacpro_numdiasplazo: item.ENCFACPRO_NUMDIASPLAZO,
        p_encfacpro_idcretri: item.ENCFACPRO_IDCRETRI,
        p_encfacpro_serie: item.ENCFACPRO_SERIE,
        p_encfacpro_autorizacion: item.ENCFACPRO_AUTORIZACION,
        p_encfacpro_tipcom: item.ENCFACPRO_TIPCOM,
        p_maemotivo_codigo: item.MAEMOTIVO_CODIGO,
        P_encfacpro_fechacadfac: item.ENCFACPRO_FECHACADFAC,
        p_encfacpro_numingreso: item.ENCFACPRO_NUMINGRESO,
        p_encfacpro_numliquidacion: item.ENCFACPRO_NUMLIQUIDACION,
        p_encfacpro_tipodes: item.ENCFACPRO_TIPODES,
        p_usu_identificacion: this.usuario.identificacion,
        p_encfacpro_gasto: item.ENCFACPRO_GASTO,
        p_encordcom_numero: item.ENCORDCOM_NUMERO,
        p_encfacpro_noobjetoiva: item.ENCFACPRO_NOOBJETOIVA,
        P_encfacpro_basenoobjiva: item.ENCFACPRO_BASENOOBJIVA,
        p_trnformapago_codigo: item.TRNFORMAPAGO_CODIGO,
        p_encfacpro_origen: 'LIQ',
        p_encfacpro_grupo: item.ENCFACPRO_GRUPO,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  ingDatosAdicLiq(encfacpronumero): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/95e0d5754d95680', {
      elementos: {
        p_encfacpro_numero: encfacpronumero,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  ingDatosAdicLiqProm(encfacpronumero): Promise<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/95e0d5754d95680', {
      elementos: {
        p_encfacpro_numero: encfacpronumero,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  seleccionarRegistroLiq(tipo, encfacpronumero): Observable<any> {
    return this.http.post<any>(this.way + '/compras/factproveedores/e3aae6776e85680', {
      elementos: {
        p_tipo: tipo,
        p_com_codigo: '01',
        p_encfacpro_numero: encfacpronumero,
      }
    }, this.confIniciales.httpOptions());
  }

  erConCodigo1Proveedor1Prom(strProcodigo) {
    return this.http.post(this.way + '/compras/factproveedores/76d98bb30d0e780', {
      elementos: {
        PRO_CODIGO: strProcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  ertrndatosCodalt(strCodigoAlt) {
    return this.http.post(this.way + '/compras/factproveedores/1dbeeeed5759870000', {
      elementos: {
        DETFACPRO_CODIGOALT: strCodigoAlt
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getMaefarFaccionProm(strArtcodigo) {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/83bb6539eadbf80', {
      elementos: {
        ART_CODIGO: strArtcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getInvtrndatosalt(strArtcodigo) {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/83bbddddaaeaa80', {
      elementos: {
        CodigoPrincipal: strArtcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarArtTrnDatosAlt(strcodigoAlt, strCodigodet) {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/12bf15beb55dc3000', {
      elementos: {
        DETFACPRO_CODIGOALT: strcodigoAlt,
        DETFACPRO_CODIGO: strCodigodet
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarSerTrnDatosAlt(strcodigoAlt, strCodigodet) {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/12bf38edb55dc3000', {
      elementos: {
        DETFACPRO_CODIGOALT: strcodigoAlt,
        DETFACPRO_CODIGO: strCodigodet
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }


  insertarDetalleFactura_IProm(item) {
    return this.http.post<any>(this.way + '/compras/factproveedores/4afc6bbaf533b0', {
      elementos: {
        p_encfacpro_numero: item.ENCFACPRO_NUMERO,
        p_com_codigo: item.COM_CODIGO,
        p_detfacpro_linea: item.DETFACPRO_LINEA,
        p_detfacpro_tipodet: item.DETFACPRO_TIPODET,
        p_bod_codigo: item.BOD_CODIGO,
        p_detfacpro_codigo: item.DETFACPRO_CODIGO,
        p_detfacpro_descripcion: item.DETFACPRO_DESCRIPCION,
        p_detfacpro_unidad: item.DETFACPRO_UNIDAD,
        p_detfacpro_cantidad: item.DETFACPRO_CANTIDAD,
        p_detfacpro_entregado: item.DETFACPRO_ENTREGADO,
        p_detfacpro_costo: item.DETFACPRO_COSTO,
        p_detfacpro_valdes: item.DETFACPRO_VALDES,
        p_detfacpro_pordes: item.DETFACPRO_PORDES,
        p_detfacpro_total: item.DETFACPRO_TOTAL,
        p_detfacpro_poriva: item.DETFACPRO_PORIVA,
        p_detfacpro_iva: item.DETFACPRO_IVA,
        p_detfacpro_tribice: item.DETFACPRO_TRIBICE,
        p_detfacpro_ice: item.DETFACPRO_ICE,
        p_detfacpro_porceice: item.DETFACPRO_PORCEICE,
        p_detfacpro_baseiva: item.DETFACPRO_BASEIVA,
        p_detfacpro_baseice: item.DETFACPRO_BASEICE,
        p_detfacpro_basecero: item.DETFACPRO_BASECERO,
        p_encordcom_numero: item.ENCORDCOM_NUMERO,
        p_detordcom_linea: item.DETORDCOM_LINEA,
        p_encnotrec_numero: item.ENCNOTREC_NUMERO,
        p_detnotrec_linea: item.DETNOTREC_LINEA,
        p_detfacpro_promocion: item.DETFACPRO_PROMOCION,
        p_detfacpro_totalincp: item.DETFACPRO_TOTALINCP,
        p_detfacpro_pordes2: item.DETFACPRO_PORDES2,
        p_detfacpro_pordes3: item.DETFACPRO_PORDES3,
        p_cen_codigo: item.CEN_CODIGO,
        p_detfacpro_factor: item.DETFACPRO_FACTOR,
        p_detfacpro_preciofob: item.DETFACPRO_PRECIOFOB,
        p_detfacpro_esquemadoc: item.DETFACPRO_ESQUEMADOC,
        p_detfacpro_tiposri: item.DETFACPRO_TIPOSRI,
        p_detfacpro_precioa: item.DETFACPRO_PRECIOA,
        p_detfacpro_preciob: item.DETFACPRO_PRECIOB,
        p_detfacpro_precioc: item.DETFACPRO_PRECIOC,
        p_trnsolfac_codigo: item.TRNSOLFAC_CODIGO,
        p_trnsolfac_linea: item.TRNSOLFAC_LINEA,
        p_encreq_numero: item.ENCREQ_NUMERO,
        p_detreq_linea: item.DETREQ_LINEA,
        p_encgre_codigo: item.ENCGRE_CODIGO,
        p_detgre_linea: item.DETGRE_LINEA,
        p_detfacpro_basenoobjiva: item.DETFACPRO_BASENOOBJIVA,
        p_detfacpro_tribasenoobjiva: item.DETFACPRO_TRIBASENOOBJIVA,
        p_detfacpro_codigoalt: item.DETFACPRO_CODIGOALT,
        p_detfacpro_fracciones: item.DETFACPRO_FRACCIONES,
        p_tariiva_codigo:item.TARIIVA_CODIGO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  ActualizaDatosProm(strNumeroFactura) {
    return this.http.post<any>(this.way + '/compras/factproveedores/299df2ab26abb8', {
      elementos: {
        p_encfacpro_numero: strNumeroFactura,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getProcodigo(prorucide) {
    return this.http.post<any>(this.way + '/compras/factproveedores/83bb9de1986ca00', {
      elementos: {
        prorucide
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerPermisoUsuario(strIdUsuario) {
    return this.http.post<any[]>(this.way + '/compras/docalmacenados/c2fb976ed2f7400', {
      elementos: {
        USUARIO: strIdUsuario
      }
    }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  verificarKardex(documento) {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/3eedc9f262b7460000', {
      elementos: {
        documento
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  FixedTo2(valor): number {
    let aux;
    let fix;
    console.log(valor.toFixed(20));
    aux = valor * 10;
    console.log(aux);
    fix = aux.toFixed(20);
    console.log(fix);
    console.log(Number(fix) / 10)
    return Number(fix) / 10;
  }

  FixedTo(data, val) {
    var numbers = '';

    for (var i = 0; i < val; i++) {
      numbers += '0';
    }
    var s = 1 + numbers;
    var spot = "." + numbers;
    var value = (Math.round(parseFloat(data) * Number(s)) / Number(s)).toString();
    var d = value.toString().split(".");
    if (d.length == 1) {
      value = value.toString() + spot;
      return value;
    }
    if (d.length > 1) {
      if (d[1].length < 2) {
        value = value.toString() + "0";
      }
      return value;
    }

  }

  getGuiaRemision(strCodigoGuiaRemision) {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/41dd87a4adbad400000', {
      elementos: {
        strCodigoGuiaRemision
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDetalleGuiaRemision(strCodigoGuiaRemision) {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/20eeb5dd87a4ae000000000', {
      elementos: {
        strCodigoGuiaRemision
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  calcularSoloTotalRetenido() {
    let dblTotalRetencion = 0;

    for (const item of this.trnretencionarray[this.init.tabs[this.init.tabindex].indice]) {    
      if (item.RETENCION_CODIGO !== '' || item.CON_CODIGO !== '') {
        dblTotalRetencion = dblTotalRetencion + Number(this.FixedTo(item.TRNRETENCION_TOTALRETENIDO, this.confIniciales.getNumDecSist()));
      }
    }

    this.encretarray[this.init.tabs[this.init.tabindex].indice].TOTALRETENCIONES = Number(dblTotalRetencion).toFixed(this.confIniciales.getNumDecSist());
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].TOTALRETENCION = Number(dblTotalRetencion).toFixed(this.confIniciales.getNumDecSist());

    // decimales del detalle de retenciones
    for (let fila = 1; fila <= this.trnretencionarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_BASE0 = Number(this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_BASE0).toFixed(this.confIniciales.getNumDecSist());
      this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_BASEG = Number(this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_BASEG).toFixed(this.confIniciales.getNumDecSist());
      this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_BASENG = Number(this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_BASENG).toFixed(this.confIniciales.getNumDecSist());
      this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_BASE = Number(this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_BASE).toFixed(this.confIniciales.getNumDecSist());
      this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_PORCENTAJE = Number(this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_PORCENTAJE)
        .toFixed(this.confIniciales.getNumDecSist());
      this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_TOTALRETENIDO = Number(this.FixedTo(this.trnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNRETENCION_TOTALRETENIDO, this.confIniciales.getNumDecSist()))
        .toFixed(this.confIniciales.getNumDecSist());
    }
    this.encretarray[this.init.tabs[this.init.tabindex].indice].TOTALRETENCIONES = Number(this.encretarray[this.init.tabs[this.init.tabindex].indice].TOTALRETENCIONES).toFixed(this.confIniciales.getNumDecSist());
    this.encfacproarray[this.init.tabs[this.init.tabindex].indice].TOTALRETENCION = Number(this.encfacproarray[this.init.tabs[this.init.tabindex].indice].TOTALRETENCION).toFixed(this.confIniciales.getNumDecSist());
  }
  //GSRF
  getDatosProve(procodigo) {
    return this.http.post<any>(this.way + '/compras/factproveedores/83bb9de1986ca00317', {
      elementos: {
        procodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  deleteimptable(auto) {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/1aeabbae95b3d50000', {
      elementos: {
        autorizacion: auto
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getEncfacproimp(encfacnumero): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/41dd75d8f5333c00000', {
      elementos: {
        ENCFACPRO_NUMERO: encfacnumero
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getDetfacproimp(encfacnumero): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/41dd6bbaf5333c00000', {
      elementos: {
        ENCFACPRO_NUMERO: encfacnumero
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  updatearthomo(item, PRO_CODIGO): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/1ecb55d72b7d8e000', {
     elementos: {
        art_codigo:item.DETFACPRO_CODIGO,
        detfac_tipo:item.DETFACPRO_TIPODET,
        unidad:item.DETFACPRO_UNIDAD,
        nombre:item.DETFACPRO_DESCRIPCION,
        pro_codigo:PRO_CODIGO,
        codigo_imp:item.DETFACPRO_CODIGO_IMP,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  deleteencimp(ENCFACPRO_NUMERO_IMP): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/6baaeeb9d764ac0', {
     elementos: {
        encfacpronumero:ENCFACPRO_NUMERO_IMP,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  deletedetimp(ENCFACPRO_NUMERO_IMP): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/6baaeeb9aeecac0', {
      elementos: {
        encfacpronumero:ENCFACPRO_NUMERO_IMP,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  buscarthomo(codigo,pro_codigo){
    return  this.http.post<any[]>(this.way + '/compras/factproveedores/2fdc62b7d8e2d8', {
      elementos: {
        codigo: codigo,
        pro_codigo: pro_codigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //GSRF
  //CDPJ
  obtenerbasesiva(numero) {
    return this.http.post<any>(this.way + '/compras/factproveedores/83bab571dc97d800000000000', {
      elementos: {
        p_facpro_numero:numero
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizabasesiva(numero) {
    return this.http.post<any>(this.way + '/compras/factproveedores/a675ea667785a80000000', {
      elementos: {
        p_encfacpro_numero:numero,
        p_com_codigo:'01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getfrmformpagfac(numero) {
    return this.http.post<any>(this.way + '/compras/factproveedores/20eebf1bb6550c0000', {
      elementos: {
        p_facpro_numero:numero
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getexisfrmformpagfac(numero) {
    return this.http.post<any>(this.way + '/compras/factproveedores/83bae', {
      elementos: {
        CODSRI_CODIGO:numero
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
}
