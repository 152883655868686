import {Injectable} from '@angular/core';
import {CxcMaeTarjetaCredito} from '../../interfaces/cxcmaetarjetacredito';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Observable} from 'rxjs';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class CxctarjetaCreditoService {
  
  get auxsalto(): boolean {
    return this._auxsalto;
  }
  
  set auxsalto(value: boolean) {
    this._auxsalto = value;
  }
  
  get cxcmaeTarjetaCredito(): CxcMaeTarjetaCredito[] {
    return this._cxcmaeTarjetaCredito;
  }
  
  set cxcmaeTarjetaCredito(value: CxcMaeTarjetaCredito[]) {
    this._cxcmaeTarjetaCredito = value;
  }
  
  public way: string;
  private _auxsalto: boolean;
  private _cxcmaeTarjetaCredito: CxcMaeTarjetaCredito[];
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService) {

    this.way = sessionStorage.getItem('way');
  }
  
  getTarjetasCreditoClient(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/tarjetacredito/20eef55b9b36e60000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  existeTarjetasCredito(tarjetacodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/tarjetacredito/1dc6755b9b99b70000', {
      elementos: {TAR_CODIGO: tarjetacodigo},
    }, this.confIniciales.httpOptions()
    );
  }
  
  insertarTarjetaCredito(tarjetaCredito: CxcMaeTarjetaCredito): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/tarjetacredito/12bf1dbef55b67000', {
      elementos: {
        p_TAR_codigo_1: tarjetaCredito.TAR_CODIGO,
        p_TAR_descripcion_2: tarjetaCredito.TAR_DESCRIPCION,
        p_TAR_porcentaje_3: tarjetaCredito.TAR_PORCENTAJE,
        p_CON_codigo_4: tarjetaCredito.CON_CODIGO,
        p_COM_codigo_5: tarjetaCredito.COM_CODIGO,
        p_con_codigocom: tarjetaCredito.CON_CODIGOCOM,
        p_con_codigorfte: tarjetaCredito.CON_CODIGORFTE,
        p_con_codigofiva: tarjetaCredito.CON_CODIGOFIVA,
        p_tar_ruc: tarjetaCredito.TAR_RUC,
        p_tar_codsri: tarjetaCredito.TAR_CODSRI,
      }
    }, this.confIniciales.httpOptions());
  }
  
  actualizarTarjetaCredito(tarjetaCredito: CxcMaeTarjetaCredito, codigo): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/tarjetacredito/a677aadcddd67000', {
      elementos: {
        p_TAR_codigo_anterior: codigo,
        p_TAR_codigo_3: tarjetaCredito.TAR_CODIGO,
        p_TAR_descripcion_4: tarjetaCredito.TAR_DESCRIPCION,
        p_TAR_porcentaje_5: tarjetaCredito.TAR_PORCENTAJE,
        p_CON_codigo_2: tarjetaCredito.CON_CODIGO,
        p_COM_codigo_5: '01',
        p_con_codigocom: tarjetaCredito.CON_CODIGOCOM,
        p_con_codigorfte: tarjetaCredito.CON_CODIGORFTE,
        p_con_codigofiva: tarjetaCredito.CON_CODIGOFIVA,
        p_tar_ruc: tarjetaCredito.TAR_RUC,
        p_tar_codsri: tarjetaCredito.TAR_CODSRI,
      }
    }, this.confIniciales.httpOptions());
  }
  
  eliminarTarjetaCredito(tarjetaCredito: CxcMaeTarjetaCredito): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxc/tarjetacredito/75656eab7366dc0', {
      elementos: {
        p_TAR_codigo_1: tarjetaCredito.TAR_CODIGO,
        p_COM_codigo_2: '01',
      }
    }, this.confIniciales.httpOptions());
  }
  
  erTarcodigo(strTarcodigo) {
    return this.http.post(this.way + '/cxc/tarjetacredito/3b7d56d986ca18', {
      elementos: {
        TAR_CODIGO: strTarcodigo
      },
    }, this.confIniciales.httpOptions());
  }
}
