<div class="p-grid">
	<div class="p-col-12">
		<p-table #BUS selectionMode="single" editMode="row" [(selection)]="selectedItem"
				 [globalFilterFields]="[tipoSeleccionado]"
				 [value]="arregloConsulta" [scrollable]="true" scrollHeight="250px">
			<ng-template pTemplate="caption">
				<div class="p-grid">
					<div class="p-col-8 text-left">
						<p-selectButton (click)="BUS.filterGlobal(busquedaParams, tipoBusqueda)"
										[options]="arrayOptions"
										[(ngModel)]="tipoSeleccionado"></p-selectButton>
					</div>
					<div class="p-col-4 text-left">
						<div class="p-grid">
							<div class="p-col-12">
								<p-radioButton (click)="BUS.filterGlobal(busquedaParams, tipoBusqueda)" name="grupo1"
											   label="Coincidencias" value="contains"
											   [(ngModel)]="tipoBusqueda"></p-radioButton>
							</div>
							<div class="p-col-12">
								<p-radioButton (click)="BUS.filterGlobal(busquedaParams, tipoBusqueda)" name="grupo1"
											   label="Palabras Completas" value="equals"
											   [(ngModel)]="tipoBusqueda"></p-radioButton>
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="text-left">
							<i class="fa fa-search" style="margin: 4px 4px 0 0"
							   (click)="BUS.filterGlobal(busquedaParams, tipoBusqueda)"></i>
							<input id="inputBusqueda2" type="text" pInputText size="50" [(ngModel)]="busquedaParams"
								   (input)="BUS.filterGlobal(busquedaParams, tipoBusqueda)" autocomplete="off"
								   style="width: auto">
						</div>
					</div>
				</div>
			</ng-template>
			<ng-template pTemplate="colgroup" let-columns>
				<colgroup>
					<col *ngFor="let head of arrayOptions" id="{{head.label}}">
				</colgroup>
			</ng-template>
			<ng-template pTemplate="header">
				<tr class="ui-table-scrollable-wrapper">
					<th *ngFor="let head of arrayOptions">{{head.label}}</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body"
						 let-rowData>
				<tr [pSelectableRow]="rowData" (dblclick)="selectedOption(rowData)"
					(keydown.enter)="selectedOption(rowData)" id="tr">
					<td *ngFor="let data of arrayOptions">{{rowData[data.value]}}</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>
