import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {SaciMaecfngimpr} from '../advantageinterfaces/sacimaecfngimpr';
import {SacimaecfngimprService} from '../advantageservices/sacimaecfngimpr.service';
import {ConfirmationService, MessageService} from 'primeng';
import {ReportesService} from '../../servicios/reporte.service';

@Component({
  selector: 'app-sacimaecfngimpr',
  templateUrl: './sacimaecfngimpr.component.html',
  styleUrls: ['./sacimaecfngimpr.component.css']
})
export class SacimaecfngimprComponent implements OnInit, OnChanges {
  @Input() auxiliar: any;
  @Input() strNombreForma: any;
  @Input() strNumDoc: any;
  @Input() strNotaVentaPV: any;
  @Input() strCampo: any;
  @Input() strCampo2: any;
  @Input() strNumDoc2: any;
  @Input() strform:any;//CDPJ
  @Output() cerrar = new EventEmitter<boolean>();
  
  reportes: SaciMaecfngimpr[] = [];
  reporteSeleccionado: SaciMaecfngimpr;
  
  ValoresReporte: string[][] = [];
  ValoresParamentros: string[][] = [];
  intNumfilasVP: number;
  intNumFilasVR: number;
  strConsultaRP: string;
  strParametro: string;
  ValorParametroIDE: string;
  strObjeto: string;
  
  constructor(private cfngimprService: SacimaecfngimprService, private messageService: MessageService,
              private confirmationService: ConfirmationService, private reporteSer: ReportesService) {
  }
  
  ngOnInit(): void {
    this.reporteSeleccionado = {};
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    for (const e in changes) {
      if (e === 'auxiliar' && this.strNombreForma !== undefined) {
        this.listarreportes();
      }
    }
  }
  
  listarreportes() {
    this.intNumfilasVP = 0;
    this.intNumFilasVR = 0;
    let strDatos = '';
    if (this.strNotaVentaPV === 'PV') {
      strDatos = 'PV';
    } else {
      strDatos = 'F';
    }
    console.log(this.strCampo, this.strNumDoc);
    if (this.strNumDoc === undefined) {
      this.strNumDoc = '';
    }

    if (this.strNombreForma === 'frmBAN_consilbancaria' || this.strNombreForma === 'frmCXC_TRNCOBRO') {
      this.FunCnfgMtrzRptConcil(this.strCampo, this.strNumDoc, this.strCampo2, this.strNumDoc2);
    } else {
      this.FunCnfgMtrzRpt(this.strCampo, this.strNumDoc);
    }
    
    
    this.cfngimprService.CargarReportesPantalla(this.strNombreForma, '01', strDatos).subscribe(data => {
      this.reportes = data;
    });
  }
  
  manejarSenales(valor) {
    if (valor === 'Reporte') {
      this.verReporte();
    }
    if (valor === 'Imprimir') {
      this.imprimir();
    }
    if (valor === 'Salir') {
      this.cerrar.emit(true);
    }
  }
  
  visualizarValorconsulta() {
    if (this.reporteSeleccionado === undefined) {
      this.messageService.add({
        key: 'cfngimpr',
        severity: 'success',
        summary: 'Información',
        detail: 'Seleccione un reporte'
      });
      return;
    }
    
    const strCodigoParam = this.reporteSeleccionado.CNFGIMPR_CODIGO;
    this.CargarParametrosReporte(strCodigoParam, 'mensaje');
  }
  
  CargarParametrosReporte(strCodigoParam, accion) {
    let i = 0;
    console.log(strCodigoParam);
    this.cfngimprService.consultaPrmtCnfgImprT(strCodigoParam, '01').subscribe(data => {
      for (const rs of data) {
        this.ValoresParamentros[i] = [];
        this.ValoresParamentros[i][0] = rs.PRMTCNFGIMPR_TIPO;
        this.ValoresParamentros[i][1] = rs.PRMTCNFGIMPR_VALOR;
        this.ValoresParamentros[i][2] = rs.PRMTCNFGIMPR_TIPODATO;
        i = i + 1;
      }
      this.intNumfilasVP = i;
      
      if (accion === 'mensaje') {
        this.strConsultaRP = this.FormarConsulta().trim();
        this.strConsultaRP = this.strConsultaRP.substring(3);
        this.confirmationService.confirm({
          message: this.strConsultaRP,
          header: 'Reporte',
          icon: 'pi pi-info-circle',
          key: 'confcfngimpr',
          acceptLabel: 'Ok',
          rejectVisible: false,
          accept: () => {
          }
        });
      }
      
      if (accion === 'visualizar') {
        this.visualizarReporte2();
      }
    });
  }
  
  FormarConsulta() {
    let strCadena = '';
    let strValorParam = '';
    let strCadenaFinal = '';
    
    for (let i = 0; i < this.intNumfilasVP; i++) {
      if (this.ValoresParamentros[i][0] === 'T') {
        strCadena = this.ValoresParamentros[i][1];
        strCadenaFinal = strCadenaFinal + ' ' + strCadena;
      } else {
        const strValor = this.ValoresParamentros[i][1];
        const strTipoValor = this.ValoresParamentros[i][2];
        for (let j = 0; j < this.intNumFilasVR; j++) {
          if (strValor === this.ValoresReporte[j][0]) {
            strValorParam = this.ValoresReporte[j][1];
            if (strTipoValor === 'S') {
              strValorParam = '\'' + strValorParam + '\'';
            }
            strCadenaFinal = strCadenaFinal + ' ' + strValorParam;
          }
        }
      }
    }
    
    if (this.reporteSeleccionado.CNFGIMPR_TABLA != null && this.reporteSeleccionado.CNFGIMPR_SP == null) {
      strCadenaFinal = ' WHERE ' + strCadenaFinal;
    } else {
      strCadenaFinal = ' AND ' + strCadenaFinal;
    }
    
    return strCadenaFinal;
  }
  
  verReporte() {
    if (this.reportes.length >= 0) {
      this.visualizarReporte();
    } else {
      this.messageService.add({
        key: 'cfngimpr',
        severity: 'error',
        summary: 'Información',
        detail: 'No existe ningún dato. Consulte con el Administrador'
      });
    }
  }
  
  visualizarReporte() {
    const strCodigoParam = this.reporteSeleccionado.CNFGIMPR_CODIGO;
    this.CargarParametrosReporte(strCodigoParam, 'visualizar');
  }
  
  visualizarReporte2() {
    let strPantallaArchivoRP = '';
    let strConsulta = this.FormarConsulta().trim();
    
    if (this.reporteSeleccionado.CNFGIMPR_SP !== null) {
      
      if (this.strParametro === '1') {
        strPantallaArchivoRP = 'PANTALLA' + this.reporteSeleccionado.CNFGIMPR_REPORTE;
      } else {
        strPantallaArchivoRP = this.reporteSeleccionado.CNFGIMPR_REPORTE;
      }
      
      this.cfngimprService.EjecutaSentenciaUpdate(this.FormarConsultaProceso()).subscribe();
      strConsulta = 'where ' + strConsulta.substring(4).trim() + ' AND PARAMETRO_IDE = ' + '\'' + this.ValorParametroIDE + '\'';
      
      /*setTimeout(() => {
        console.log(strConsulta);
        document.getElementById('areporte').click();
        
        const urlreporte = sessionStorage.getItem('urlreporte');
        const usuarioEsquema = JSON.parse(sessionStorage.getItem('usuarioEsquema'));
        const esquema = usuarioEsquema.esquema;
        let NOMBREREPORTE = strPantallaArchivoRP;
        NOMBREREPORTE = NOMBREREPORTE.substr(0, NOMBREREPORTE.length - 7);
        const src = urlreporte + NOMBREREPORTE + '.pdf?j_username=' + esquema + '&j_password=jasperadmin';
        // src = src + '&j_querryString=' + strConsulta;
        console.log(src);
        // http://localhost:8081/jasperserver/rest_v2/reports/reports/VEN_OP1_NOTAVENTA.pdf?j_username=DEMO&j_password=jasperadmin
        fetch(src).then(res => res.blob()).then(blob => {
          const fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        });
      }, 2000);*/
      
      
      setTimeout(() => {
        const mediaType = 'application/pdf';
        this.reporteSer.generarReporte(strPantallaArchivoRP, strConsulta, false, '').subscribe(response => {
          const blob = new Blob([response], {type: mediaType});
          const fileURL = URL.createObjectURL(blob);
          
          window.open(fileURL);
        }, error => {
          this.messageService.add({
            key: 'cfngimpr',
            severity: 'error',
            summary: 'Error',
            detail: 'Error al generar el reporte. Revise la configuración'
          });
        });
      }, 2000);
      /*
      Map < String, Object > param = new HashMap<String, Object>();
      param.put('querryString', strConsulta);
      param.put('SUBREPORT_DIR', AsistenteReportes.seleccionarInputStreamPantalla(strLocalizacionRP));
      param.put('NOMBRE_REPORTE', strNombreReporte);
      param.put('REPORT_LOCALE', new java.util.Locale('en', 'US'));
      
      try {
        AsistenteReportes.mostrarReportePantalla(strPantallaArchivoRP, param, strLocalizacionRP, 'P');
      }
      */
    } else {
      if (this.strParametro === '1') {
        strPantallaArchivoRP = 'PANTALLA' + this.reporteSeleccionado.CNFGIMPR_REPORTE;
      } else {
        strPantallaArchivoRP = this.reporteSeleccionado.CNFGIMPR_REPORTE;
      }
      
      setTimeout(() => {
        const mediaType = 'application/pdf';
        this.reporteSer.generarReporte(strPantallaArchivoRP, strConsulta, false, '').subscribe(response => {
          const blob = new Blob([response], {type: mediaType});
          const fileURL = URL.createObjectURL(blob);
          
          window.open(fileURL);
        }, error => {
          this.messageService.add({
            key: 'cfngimpr',
            severity: 'error',
            summary: 'Error',
            detail: 'Error al generar el reporte. Revise la configuración'
          });
        });
        /*console.log(strConsulta);
        document.getElementById('areporte').click();*/
        
        /*const urlreporte = sessionStorage.getItem('urlreporte');
        const usuarioEsquema = JSON.parse(sessionStorage.getItem('usuarioEsquema'));
        const esquema = usuarioEsquema.esquema;
        let NOMBREREPORTE = strPantallaArchivoRP;
        NOMBREREPORTE = NOMBREREPORTE.substr(0, NOMBREREPORTE.length - 7);
        const src = urlreporte + NOMBREREPORTE + '.pdf?j_username=' + esquema + '&j_password=jasperadmin';
        // src = src + '&j_querryString=' + strConsulta;
        console.log(src);
        // http://localhost:8081/jasperserver/rest_v2/reports/reports/VEN_OP1_NOTAVENTA.pdf?j_username=DEMO&j_password=jasperadmin
        fetch(src).then(res => res.blob()).then(blob => {
          const fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        });*/
      }, 2000);
      /*
      Map < String, Object > param = new HashMap<String, Object>();
      //System.out.println("Valor de la consulta: "+ strConsulta);
      param.put('querryString', strConsulta);
      param.put('SUBREPORT_DIR', AsistenteReportes.seleccionarInputStreamPantalla(strLocalizacionRP));
      param.put('NOMBRE_REPORTE', strNombreReporte);
      param.put('REPORT_LOCALE', new java.util.Locale('en', 'US'));
      
      try {
        AsistenteReportes.mostrarReportePantalla(strPantallaArchivoRP, param, strLocalizacionRP, '');
      }*/
    }
  }
  
  imprimir() {
  
  }
  
  mandarinforeporte(reporte) {
    if (reporte !== undefined) {
      this.reporteSeleccionado = reporte;
    }
  }
  
  FunCnfgMtrzRpt(strCampo, strNumDoc) {
    const intNumeroDatos = 2;
    this.ValoresReporte[0] = [];
    this.ValoresReporte[1] = [];
    this.ValoresReporte[0][0] = strCampo;
    //this.ValoresReporte[0][1] = strNumDoc.trim();
    this.ValoresReporte[0][1] = strNumDoc.toString().trim();//CDPJ
    this.ValoresReporte[1][0] = 'COM_CODIGO';
    this.ValoresReporte[1][1] = '01';
    this.intNumFilasVR = intNumeroDatos;
  }

  FunCnfgMtrzRptConcil(strCampo, strNumDoc, strCampo2, strNumDoc2) {
    const intNumeroDatos = 3;
    this.ValoresReporte[0] = [];
    this.ValoresReporte[1] = [];
    this.ValoresReporte[2] = [];
    this.ValoresReporte[0][0] = strCampo;
    this.ValoresReporte[0][1] = strNumDoc.trim();
    this.ValoresReporte[1][0] = strCampo2;
    this.ValoresReporte[1][1] = strNumDoc2.trim();
    this.ValoresReporte[2][0] = 'COM_CODIGO';
    this.ValoresReporte[2][1] = '01';
    this.intNumFilasVR = intNumeroDatos;
  }
  
  ejecutarReporte() {
    /*this.mensaje = '';
    this.msgs = [];
    console.log(this.reporteSeleccionado);
    if (this.reporteSeleccionado.RL_OBJETO.toString().includes('TMP')) {
      this.reporteSer.eliminarTabla(this.reporteSeleccionado.RL_OBJETO).subscribe(data => {
        this.reporteSer.ejecutarProcedure(this.etiquetas2, this.etiquetas, this.reporteSeleccionado).subscribe(() => {
          this.mensaje = 'Ejecución exitosa';
          this.msgs.push({severity: 'success', summary: '', detail: this.mensaje});
        }, error1 => {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
          this.mensaje = 'Error al ejecutar el proceso';
          this.msgs.push({severity: 'error', summary: '', detail: this.mensaje});
        });
      });
    } else {
      this.reporteSer.ejecutarProcedure(this.etiquetas2, this.etiquetas, this.reporteSeleccionado).subscribe(() => {
        this.mensaje = 'Ejecución exitosa';
        this.msgs.push({severity: 'success', summary: '', detail: this.mensaje});
      }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
        this.mensaje = 'Error al ejecutar el proceso';
        this.msgs.push({severity: 'error', summary: '', detail: this.mensaje});
      });
    }*/
  }
  
  FormarConsultaProceso() {
    const fechaSistema = new Date();
    const lngdfechaSistema = fechaSistema.getTime();
    const strParametroIDE = lngdfechaSistema.toString();
    this.ValorParametroIDE = strParametroIDE;
    
    let strValorParam = '';
    let strCadenaFinal = '';
    for (let i = 0; i < this.intNumfilasVP; i++) {
      if (this.ValoresParamentros[i][0] === 'C') {
        const strValor = this.ValoresParamentros[i][1];
        const strTipoValor = this.ValoresParamentros[i][2];
        for (let j = 0; j < this.intNumFilasVR; j++) {
          if (strValor === this.ValoresReporte[j][0]) {
            strValorParam = this.ValoresReporte[j][1];
            if (strTipoValor === 'S') {
              strValorParam = ',\'' + strValorParam + '\'';
            }
            strCadenaFinal = strCadenaFinal + '' + strValorParam;
          }
        }
      }
    }
    strCadenaFinal = strCadenaFinal.substring(1);
    this.strObjeto = this.reporteSeleccionado.CNFGIMPR_TABLA;
    //strCadenaFinal = 'BEGIN ' + this.reporteSeleccionado.CNFGIMPR_SP + '(' + strCadenaFinal + ',\'' + strParametroIDE + '\'' + ');END;';
    //CDPJ
    console.log('cadena final',strCadenaFinal);
    console.log('esq',this.cfngimprService.usuarioEsquema.esquema);
    console.log('str',this.strform)
    if(this.cfngimprService.usuarioEsquema.esquema === 'UIO1792788862001' && this.strform === 'Si'){
      strCadenaFinal = 'BEGIN ' + this.reporteSeleccionado.CNFGIMPR_SP + '(' + strCadenaFinal + ',\'' + strParametroIDE + '\'' + ',\'' + this.cfngimprService.usuario.identificacion + '\'' +  ');END;';
    }else{
      strCadenaFinal = 'BEGIN ' + this.reporteSeleccionado.CNFGIMPR_SP + '(' + strCadenaFinal + ',\'' + strParametroIDE + '\'' + ');END;';
    }
    console.log('cadena final2',strCadenaFinal);
    //CDPJ
    return strCadenaFinal;
  }
}
