import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Usuario} from '../../usuario';
import {BancashmngService} from '../banservicios/bancashmng.service';
import {MessageService} from 'primeng';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';

@Component({
  selector: 'app-bancashmngedit',
  templateUrl: './bancashmngedit.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class BancashmngeditComponent implements OnInit {
  
  @Input() strCodProveedor: string;
  @Input() encCashmngCodigo: number;
  @Output() signalGuardarCash = new EventEmitter<boolean>();
  @Output() signalGuardarProv = new EventEmitter<boolean>();
  @Output() signalSalir = new EventEmitter<boolean>();
  
  cmbFormaPago: any[];
  strFormaPago: any;
  cmbEntidadFin: any[];
  strEntidadFin: any;
  cmbTipoCuenta: any[];
  strTipoCuenta: any;
  cmbTipoId: any[];
  strTipoId: any;
  
  txtNumeroCuenta: string;
  txtBeneficiario: string;
  txtIdIdentificacion: string;
  txtDireccion: string;
  txtCiudad: string;
  txtTelefono: string;
  txtLocalidadPago: string;
  txtEmail: string;
  txtCodigoProveedor: string;
  
  disabledCmbEntfin: boolean;
  disabledCmbTipCta: boolean;
  disabledNumCta: boolean;
  

  constructor( public usuario: Usuario,
               public banCashmngService: BancashmngService,
               private message: MessageService,
               private errorService: ErroresBaseDatosService,
               private auditoriagral: AuditoriagralService) { }

  async ngOnInit() {
  
    this.disabledCmbEntfin = false;
    this.disabledCmbTipCta = false;
    this.disabledNumCta = false;
    await this.ConfiguracionInicial();
    if ((this.strCodProveedor !== undefined && this.strCodProveedor !== '')
      && (this.encCashmngCodigo !== undefined && String(this.encCashmngCodigo) !== '')) {
      await this.mostrarDatos(this.strCodProveedor, this.encCashmngCodigo);
    }
    
  }
  
  async ConfiguracionInicial() {
    // FORMAS DE PAGO
    try {
      const rs = await this.banCashmngService.formaPago();
      if (rs !== null) {
        this.cmbFormaPago = [];
        this.cmbFormaPago.push({codigo: '', name: ''});
        for (const dato of rs) {
          this.cmbFormaPago.push({codigo: dato.REF_CODIGO, name: dato.REF_NOMBRE});
        }
        this.strFormaPago = this.cmbFormaPago[0];
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  
    // entidad financiera
    try {
      const rs = await this.banCashmngService.entidadFinanciera();
  
      if (rs !== null) {
        this.cmbEntidadFin = [];
        this.cmbEntidadFin.push({codigo: '', name: ''});
        for (const dato of rs) {
          this.cmbEntidadFin.push({codigo: dato.REF_CODIGO, name: dato.REF_NOMBRE});
        }
        this.strEntidadFin = this.cmbEntidadFin[0];
      }
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  
    // tipos de cuenta
    
    try {
      const rs = await this.banCashmngService.tipoCuenta();
      if (rs !== null) {
        this.cmbTipoCuenta = [];
        this.cmbTipoCuenta.push({codigo: '', name: ''});
        for (const dato of rs) {
          this.cmbTipoCuenta.push({codigo: dato.REF_CODIGO, name: dato.REF_NOMBRE});
        }
        this.strTipoCuenta = this.cmbTipoCuenta[0];
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  
    this.cmbTipoId = [];
    this.cmbTipoId.push({codigo: '', name: ''}, {codigo: 'C', name: 'Cédula'}, {codigo: 'R', name: 'RUC'},
      {codigo: 'P', name: 'Pasaporte'}, {codigo: 'N', name: 'No disponible'} );
    this.strTipoId = this.cmbTipoId[0];
  
  }
  
  async mostrarDatos(strProCodigo, strEnccashCodigo) {
    try {
      const rs = await this.banCashmngService.obtenerDatosCashmgnEdit(strProCodigo, strEnccashCodigo);
      if (rs !== null) {
        // Datos del Cash
        // Forma de Pago
        for (const dato of this.cmbFormaPago) {
          if (dato.codigo === rs[0].COM_FORPAGCM) {
            this.strFormaPago = {codigo: dato.codigo, name: dato.name};
          }
        }
        // Entidad financiera
  
        for (const dato of this.cmbEntidadFin) {
          if (dato.codigo === rs[0].COM_BANCODIGOCM) {
            this.strEntidadFin = {codigo: dato.codigo, name: dato.name};
          }
        }
        // Tipo de cuenta
  
        for (const dato of this.cmbTipoCuenta) {
          if (dato.codigo === rs[0].COM_TIPOCTACM) {
            this.strTipoCuenta = {codigo: dato.codigo, name: dato.name};
          }
        }
        // Tipo de indetificación
  
        for (const dato of this.cmbTipoId) {
          if (dato.codigo === rs[0].PRO_TIPOIDENTIFCM) {
            this.strTipoId = {codigo: dato.codigo, name: dato.name};
          }
        }
        
        this.txtNumeroCuenta = rs[0].COM_NUMCTACM === null ? '' : rs[0].COM_NUMCTACM;
        this.txtBeneficiario = rs[0].COM_BENFCM === null ? '' : rs[0].COM_BENFCM;
        this.txtIdIdentificacion = rs[0].PRO_IDCM === null ? '' : rs[0].PRO_IDCM;
        this.txtDireccion =  rs[0].COM_DIFBENFCM === null ? '' : rs[0].COM_DIFBENFCM;
        this.txtCiudad = rs[0].PRO_CIUDADCM === null ? '' : rs[0].PRO_CIUDADCM;
        this.txtTelefono = rs[0].COM_TELFBENFCM === null ? '' : rs[0].COM_TELFBENFCM;
        this.txtLocalidadPago = rs[0].PRO_LOCPAGCM === null ? '' : rs[0].PRO_LOCPAGCM;
        this.txtEmail  = rs[0].PRO_EMAILCM === null ? '' : rs[0].PRO_EMAILCM;
        this.txtCodigoProveedor = rs[0].PRO_CODIGO === null ? '' : rs[0].PRO_CODIGO;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  }
  
  async guardarDatosCashManagement() {
    if (String(this.encCashmngCodigo) !== '') {
      let strCodigoFormaPago = '';
      let strBanCodigoCash = '';
      let strTipoCuenta = '';
      let strTipoIdentificacion = '';
  
      // Forma de Pago
      strCodigoFormaPago = this.strFormaPago.codigo;
      // Codigo del banco
      strBanCodigoCash = this.strEntidadFin.codigo;
      // número de teléfono
      if (strBanCodigoCash === '0032') {
        if (this.txtTelefono.length > 0) {
          if (this.txtTelefono.length !== 10
            && this.txtTelefono.substring(0, 1) === '09') {
            this.mensajeAlerta('info', 'Información', 'El número de telefono es incorrecto');
            return;
          }
        } else {
          this.mensajeAlerta('info', 'Información', 'El número de telefono es incorrecto');
          return;
        }
      }
      // Tipo de cuenta
      strTipoCuenta = this.strTipoCuenta.codigo;
  
      // Tipo de identificación
      strTipoIdentificacion = this.strTipoId.codigo;
      
      
      try {
        await this.banCashmngService.guardarDatosCashManagement(this.strCodProveedor, strBanCodigoCash, strTipoCuenta,
          this.txtNumeroCuenta, strCodigoFormaPago, this.txtBeneficiario, this.txtDireccion, this.txtTelefono, strTipoIdentificacion,
          this.txtIdIdentificacion, this.txtCiudad, this.txtLocalidadPago, this.txtEmail, this.encCashmngCodigo);
        
        this.auditoriagral.registrarAuditoria('BAN_DETCASHMNG', String(this.encCashmngCodigo), 'A',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
        
    
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      }
  
      this.signalGuardarCash.emit(true);
    }
  }
  
  guardarDatosMaeProv() {
    if (String(this.encCashmngCodigo) === '') {
      this.guardarDatosFichaProv();
      // frmBan_Cashmng.mostrarComprobantes();
      // shell.close();
    } else {
      this.guardarDatosFichaProv();
     // shell.close();
    }
  }
  
  async guardarDatosFichaProv() {
    let strCodigoFormaPago = '';
    let strBanCodigoCash = '';
    let strTipoCuenta = '';
    let strTipoIdentificacion = '';
  
    // Forma de Pago
    strCodigoFormaPago = this.strFormaPago.codigo;
    // Codigo del banco
    strBanCodigoCash = this.strEntidadFin.codigo;
    // número de teléfono
    if (strBanCodigoCash === '0032') {
      if (this.txtTelefono.length > 0) {
        if (this.txtTelefono.length !== 10
          && this.txtTelefono.substring(0, 1) === '09') {
          this.mensajeAlerta('info', 'Información', 'El número de telefono es incorrecto');
          return;
        }
      } else {
        this.mensajeAlerta('info', 'Información', 'El número de telefono es incorrecto');
        return;
      }
    }
    // Tipo de cuenta
    strTipoCuenta = this.strTipoCuenta.codigo;
  
    // Tipo de identificación
    strTipoIdentificacion = this.strTipoId.codigo;
    
    try {
      await this.banCashmngService.guardarDatosFichaProv(this.strCodProveedor, strBanCodigoCash, strTipoCuenta, this.txtNumeroCuenta,
        strCodigoFormaPago, this.txtBeneficiario, this.txtDireccion, this.txtTelefono, strTipoIdentificacion, this.txtIdIdentificacion,
        this.txtCiudad, this.txtLocalidadPago, this.txtEmail);
      this.auditoriagral.registrarAuditoria('COM_MAEPROVEEDOR', 'CASHMNG/' + this.strCodProveedor , 'A',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
    } catch (err) {
  
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  
    this.signalGuardarProv.emit(true);
  }
  
  salir() {
    this.signalSalir.emit(true);
  }
  
  cambioFormaPago(valor) {
    let strCodigoFormaPago = '';
    strCodigoFormaPago = this.strFormaPago.codigo;
    if (strCodigoFormaPago === 'CTA') {
      this.disabledCmbEntfin = false;
      this.disabledCmbTipCta = false;
      this.disabledNumCta = false;
      // this.strEntidadFin = this.cmbEntidadFin[0];
      // this.strTipoCuenta = this.cmbTipoCuenta[0];
      this.txtNumeroCuenta = '';
      
    } else {
      this.disabledCmbEntfin = true;
      this.disabledCmbTipCta = true;
      this.disabledNumCta = true;
    }
    
  }
  
  cambiarFoco(evento) {
    
    if (evento.target.id === 'inputFormaPago') {
      document.getElementById('inputEntidadFin').focus();
    }
    if (evento.target.id === 'inputEntidadFin') {
      document.getElementById('inputTipoCta').focus();
    }
    if (evento.target.id === 'inputTipoCta') {
      document.getElementById('numeroCuenta').focus();
    }
    if (evento.target.id === 'numeroCuenta') {
      document.getElementById('beneficiario').focus();
    }
    if (evento.target.id === 'beneficiario') {
      document.getElementById('inputTipoId').focus();
    }
    if (evento.target.id === 'inputTipoId') {
      document.getElementById('idIdentificacion').focus();
    }
    if (evento.target.id === 'idIdentificacion') {
      document.getElementById('direccion').focus();
    }
    if (evento.target.id === 'direccion') {
      document.getElementById('ciudad').focus();
    }
    if (evento.target.id === 'ciudad') {
      document.getElementById('telefono').focus();
    }
    if (evento.target.id === 'telefono') {
      document.getElementById('localidad').focus();
    }
    if (evento.target.id === 'localidad') {
      document.getElementById('email').focus();
    }
    
    
    
  }
  
  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'bancashmngedit',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

}
