<p-toast [style]="{marginTop: '30px'}" position="top-center" key="costInd"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmCostoInd" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<div class="p-col-12">
            <app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
                             [botonRegresar]="btnRegresar" [barraBotonesCRUD]="false"
                             [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true"
                             (signal)="manejarSenales($event)"></app-invmaebarra>
        </div>
	</div>
</div>
<div>
    <table style="margin-left: 50vw;">
        <tr style="background-color: rgba(66, 214, 240, 0.829);">
            <td style="border: 1px solid; text-align: center;">{{identiUsuario}}</td>
            <td style="border: 1px solid; text-align: center;">{{fecha | date: 'dd/MM/yyyy' }}</td>
            <td style="border: 1px solid; text-align: center;">{{fecha | date: 'hh:mm' }}</td>
        </tr>
    </table>
</div>
<div style="overflow: hidden" class="p-col-12">
    <p-tabView>
        <p-tabPanel header="Costos indirectos">

            <app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="false"
							[enableFilter]="true"
							[rowData]="costosInd"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefDetCostoInd"
							[defaultColDef]="defaultColDefCostoInd"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
                            [botones]="false"
                            [botonesAdicionales]="false"
                            (cambios)="cambio($event)"
                            (selected)="seleccion($event)"
            >
</app-aggridsaci>

        </p-tabPanel>
    </p-tabView>
</div>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastCuenCont"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCuenCont" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccionCuenCont($event)"></app-busquedadlg>
</p-dialog>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaCentroCostos"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccionCentroCost($event)"></app-busquedadlg>
</p-dialog>
