import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {MessageService} from 'primeng';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class ComestadisticasService {
  
 
  public way: string;
  constructor(private http: HttpClient,
              private confIniciales: ConfInicialesService,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private messageService: MessageService,
              private init: NuevoComponentService) {
   
    this.way = sessionStorage.getItem('way');
  }
  
  comprasAcumuladas(strProCodigo) {
 return this.http.post<any[]>(this.way + '/compras/comestadisticas/0gxipt6r6wbcugk', {      
  elementos: {
    p_pro_codigo_1: strProCodigo,
  },
}, this.confIniciales.httpOptions()).toPromise();
  }
  
  topProductos(strProCodigo, strOrden) {
    
    return this.http.post<any[]>(this.way + '/compras/comestadisticas/i977bdyun8xskzq', {      
      elementos: {
        p_pro_codigo_1: strProCodigo,
        p_orden_2: strOrden
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  movimientoArtProv(strArtCodigo, strOrden) {
   
    return this.http.post<any[]>(this.way + '/compras/comestadisticas/fnsqaugjog3qov7', {      
      elementos: {
        p_art_codigo_1: strArtCodigo,
        p_orden_2: strOrden,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  ultimoCostoCompra(strArtCodigo) {
 
    return this.http.post<any[]>(this.way + '/compras/comestadisticas/9n4cbxw2ggzslkp', {      
      elementos: {
        p_art_codigo_1: strArtCodigo,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  existenciaArticulo(strArtCodigo) {

    return this.http.post<any[]>(this.way + '/compras/comestadisticas/355nr2i0mbq5yd6', {      
      elementos: {
        p_art_codigo_1: strArtCodigo,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  cantidadOrdCompra(strArtCodigo) {
    return this.http.post<any[]>(this.way + '/compras/comestadisticas/gf8cstg3gf71iq4', {      
      elementos: {
        p_art_codigo_1: strArtCodigo,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  cantidadPedidos(strArtCodigo) {
   
    return this.http.post<any[]>(this.way + '/compras/comestadisticas/68yelqwuuzoyr3u', {      
      elementos: {
        p_art_codigo_1: strArtCodigo,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  llenarExistenciaBod(strArtCodigo) {
   
    return this.http.post<any[]>(this.way + '/compras/comestadisticas/0r7skq0ij3dm04m', {      
      elementos: {
        p_art_codigo_1: strArtCodigo,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  ventasVsCompras(strArtCodigo, strTipo) {
    return this.http.post<any[]>(this.way + '/compras/comestadisticas/o1sgq32avmhk60v', {      
      elementos: {
        p_art_codigo_1: strArtCodigo,
        p_tipo_2: strTipo,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  obtenerExistenciaArtIndProv(strArtCodigo) {
  
    return this.http.post<any[]>(this.way + '/compras/comestadisticas/h8ij86q7jlpeio0', {      
      elementos: {
        p_art_codigo_1: strArtCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  comprasPeriodo(strArtCodigo) {
  
    return this.http.post<any[]>(this.way + '/compras/comestadisticas/29z8upf2ifupx1i', {      
      elementos: {
        p_art_codigo_1: strArtCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise()
  }
  
  comprasUltPeriodo(strArtCodigo) {

    
    return this.http.post<any[]>(this.way + '/compras/comestadisticas/huhdhocssyih1am', {      
      elementos: {
        p_art_codigo_1: strArtCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise()
  }
  
  ventasPeriodo(strArtCodigo) {
   
    return this.http.post<any[]>(this.way + '/compras/comestadisticas/f86u9a4i7irfkow', {      
      elementos: {
        p_art_codigo_1: strArtCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise()
  }
  
}
