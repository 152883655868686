import { Injectable } from '@angular/core';
import { VenEncfacZ } from '../../ven/veninterfaces/venencfacz';
import { VenDetfacZ } from '../../ven/veninterfaces/vendetfacz';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { Observable, of } from 'rxjs';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
@Injectable({
  providedIn: 'root'
})
export class CxcconsulfacvenService {
  public url: string;
  public way: string;

  public encfacarray: VenEncfacZ[] = [];
  public detfacarray: VenDetfacZ[][] = [];
  public hmpConfiguracion: any[];
  public dblPorcDescGlb: any;
  public dblValorDescGlb: any;
  public dblValorotrdscto:any;//CDPJ
  public dblValorotrdsctoDif:any;//CDPJ
  public dblValorotrdsctoBcero:any;
  public dblPorcIva: any;
  public decimalesCantidadFac: number;
  public decimalesPrecioFac: number;
  public decimalesTotalFac: number;
  public decimalesDescFac: number;
  public grids: any[] = [];

  constructor(
    private http: HttpClient,
    private confIniciales: ConfInicialesService,
    private init: NuevoComponentService,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
  ) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
   }
  refrescarURL() {
    if (this.url === null) {
      this.url = sessionStorage.getItem('url');
    }
    if (this.way === null) {
      this.way = sessionStorage.getItem('way');
    }
  }
  getEncfac(encfacnumero): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/662fcf55d763d40', {
      elementos: {
        p_Tipo: 'R',
        p_Cadena: '',
        p_condicion: 'ENCFAC_NUMERO=\'' + encfacnumero + '\''
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  getfacexp(numfac): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/41dd7a98e', {
      elementos: {
        NUMFAC: numfac,
        COM_CODIGO:'01'
      }
    }, this.confIniciales.httpOptions());
  }
  obtenerNumFE(encgrupo) {

    return this.http.post<any[]>(this.way + '/ventas/guiaremision/c2faebbb77f5a00', {
      elementos: {
        num_fe: encgrupo,
        
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  desglobalCliente(clicodigo) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/cc5f9eaf55b6b800000000000000000', {
      elementos: {
        cli_codigo: clicodigo,
        com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getDetfac(strNumFact, strFecha, strHora): Promise<any[]> {
    return this.http.post<any>(this.way + '/ventas/facturas/662fcf55aeebd40', {
      elementos: {
        p_encfac_numero: strNumFact,
        p_fecha: 'TO_DATE(\'' + strFecha + '\',\'DD/MM/YYYY HH24:MI:SS\')',
        p_hora: strHora,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerCostoCompraGen(strArtCodigo, strFecha, strHora): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/usa07pcexrzkj20', {
      elementos: {},
      datos: {
        ArtCodigo: strArtCodigo,
        Fecha: strFecha,
        Hora: strHora
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerCantMinArt(strArtCodigo, strBodCodigo) {

    return this.http.post<any[]>(this.way + '/ventas/facturas/8x04eukezy5ak5d', {
      elementos: {},
      datos: {
        ArtCodigo: strArtCodigo,
        BodCodigo: strBodCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  ecExtraerNumeroPedidos(numFact) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/klx6n4bx7362usk', {

      elementos: {
        NumFact: numFact,
      },

    }, this.confIniciales.httpOptions());
  }
  verificarActualizarEstPed(encpednumero): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/4yruu4q43shk64r', {
      esquema: this.usuarioEsquema,
      elementoEjecutar: 'VEN_PCK_ENCPED.VEN_sp_VerificarPedido',
      elementos: {
        p_ENCPED_numero: encpednumero,
        p_COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  ecActualizarFechaEntregaPed(pedido) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/ryocfwg5thyb4yu', {

      elementos: {
        Pedido: pedido,
      },

    }, this.confIniciales.httpOptions());
  }
  erGetClienteCodigo(cliCodigo) {
    return this.http.post(this.way + '/ventas/facturas/772ufkd85qb57gd', {
     elementos: {
        CLI_CODIGO: cliCodigo
      },
    }, this.confIniciales.httpOptions());
  }
  actualizaDespachoPedido(encpednumero): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/a3pxeaegfltzjcr', {
      elementos: {
        p_encped_numero: encpednumero,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  obtenerListasPrecios(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/yxn1xb19h7umuqc', {
      elementos: {
        COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  obtenerBodegasInternas() {
    return this.http.post<any[]>(this.way + '/ventas/facturas/vcqb2fcwrmrcbny', {
      sql: 'SELECT BOD_CODIGO,BOD_NOMBRE FROM INV_MAEBODEGA WHERE COM_CODIGO = :COM_CODIGO AND BOD_TIPO IS NULL ORDER BY BOD_NOMBRE',
      elementos: {
        COM_CODIGO: '01'
      },
      esquema: {
        usuario: this.usuarioEsquema.esquema,
        password: this.usuarioEsquema.password
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  consultarPedido(strCodPedido): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/ventas/facturas/7hz01xdjlliki5t', {
      elementos: {},
      datos: {
        CodPedido: strCodPedido
      }
    }, this.confIniciales.httpOptions());
  }
  actualizaFacturadoPedido(encpednumero): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/14cebd4cefb2e70000', {
      elementos: {
        p_encped_numero: encpednumero,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  verificarActualizarEstPedFact(encpednumero): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/3eedc9fceb72e60000', {
      elementos: {
        p_ENCPED_numero: encpednumero,
        p_COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  ActualizaFechaEntrega(encpednumero, fechaentrega): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/f6gfrb9f0ansja1', {
      esquema: this.usuarioEsquema,
      elementoEjecutar: 'VEN_PCK_ENCPED.VEN_sp_ActualizaFechaEntrega',
      elementos: {
        p_ENCPED_numero: encpednumero,
        p_ENCPED_fechaentrega: fechaentrega,
        p_COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  comas() {
    console.log(this.encfacarray[this.init.tabs[this.init.tabindex].indice])
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_TOTALNETO = this.init.moneyValidation(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_TOTALNETO);
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_PORCEDES = this.init.moneyValidation(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_PORCEDES);
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORDES = this.init.moneyValidation(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORDES);
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORICE = this.init.moneyValidation(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORICE);
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASEICE = this.init.moneyValidation(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASEICE);
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASECERO = this.init.moneyValidation(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASECERO);
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASEIVA = this.init.moneyValidation(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASEIVA);
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORIVA = this.init.moneyValidation(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORIVA);
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_TOTAL = this.init.moneyValidation(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_TOTAL);
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASENOOBJIVA = this.init.moneyValidation(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASENOOBJIVA);
  }
  async comprobarExistencia(item) {
    let dblCantMin = 0;
    let dblExist = 0;

    if (item.DETFAC_TIPODET === 'A') { // si es artículo cambiar el color del precio y la cantidad
      if (item.DETFAC_TIPODET !== 'Y') {
        const data = await this.obtenerCantMinArt(item
          .DETFAC_CODIGO, item.BOD_CODIGO);
        for (const rs of data) {
          dblCantMin = rs.ARTEXT_MINIMA;
          // dblExist = rs.EXISTENCIA;
        }
        item.ART_CANTMIN = dblCantMin;
        // item.EXISTENCIA = dblExist;
      }
    }
  }
  
}
