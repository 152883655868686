<app-aggridsaci [width]=""
				[height]="'500'"
				[enableSorting]="true"
				[enableFilter]="true"
				[rowData]="numeraciones"
				[frameworkComponents]="frameworkComponents"
				[animateRows]="true"
				[rowSelection]="rowSelection"
				[columnDefs]="columnDefsNumeracion"
				[defaultColDef]="defaultColDefNumeracion"
				[rowStyle]="rowStyle"
				[botones]="false"
				(selected)="numeracionSeleccionada($event)"
				[singleClickEdit]="false"></app-aggridsaci>