<p-toast [style]="{marginTop: '30px'}" position="top-center" key="conasidestoast"></p-toast>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"
						 [barraBotones5]="true" [barraBotones3Alt]="false" [botonVisto]="false" [barraBotonesCRUD]="true"
						 (signal)="manejarSenales($event)"
						 style="text-align: left;">
		</app-invmaebarra>
	</div>
</div>

	<p-tabView >
		<p-tabPanel header="Asientos descuadrados">
            <div class="divgrups"  style="height: 110px; margin-bottom: 0px;" >              
				<div class="p-grid">
                    <div class="p-col-6">
                        <div class="p-grid">
                            <div class="p-col-1">
                                <span>Desde:</span>
                            </div>
                            <div class="p-col-4" >
                                <p-calendar id="fechaExpedicion" dateFormat="dd/mm/yy"  [style]="{'width': '100%', 'min-width': '100%'}"
                                            [(ngModel)]="dateDesde" (ngModelChange)="cambio($event)"></p-calendar>
                            </div>
                            
                            <div class="p-col-1">
                                <span>Hasta:</span>
                            </div>
                            <div class="p-col-4" >
                                <p-calendar id="fechaCaducidad" dateFormat="dd/mm/yy"  [style]="{'width': '100%', 'min-width': '100%'}"
                                            [(ngModel)]="dateHasta" (ngModelChange)="cambioHasta($event)"></p-calendar>
                            </div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-1">
                                <span>Tipo:</span>
                            </div>
                            <div class="p-col-7" >
                                <p-dropdown [style]="{'width': '95%', 'min-width': '95%'}" optionLabel="label" [(ngModel)]="strOptionSelect"
                                            inputId="cmbOptionsGe" [options]="opcion" (onChange)="cambioOption($event)" (keydown.enter)="cambiarFoco2($event)">
                                </p-dropdown>
                            </div>
                        </div>
                    </div>   
                    <div class="p-col-6"  >
                        <div class="p-grid"  >
                            <div class="p-col-1" style="margin-top: -7px">
                                <span  style="font-size: 13px;">Asientos:</span>
                            </div>
                            <div class="p-col-5" style="margin-top: -7px">
                            </div>
                            <div class="p-col-2" style="margin-top: -7px">
                                <span  style="font-size: 13px;">Id Ref:</span>
                            </div>
                        </div>
                        <div class="p-grid" >
                            <div class="p-col-6" style="margin-top: -7px">
                                <input id="nombreAsientos" type="text" style="font-size: 20px; height: 25px;" pInputText [(ngModel)]="txtAsientos"  maxlength="45" >
                            </div>
                            <div class="p-col-6" style="margin-top: -7px">
                                <input id="idreferencia" type="text" style="font-size: 20px; height: 25px;" pInputText [(ngModel)]="txtidreferencia"  maxlength="45" >
                            </div>
                        </div>
                        <div class="p-grid" >
                            <div class="p-col-1" style="margin-top: -7px">
                                <span style="font-size: 13px;">Comprobante:</span>
                            </div>
                            <div class="p-col-5" style="margin-top: -7px">
                            </div>
                            <div class="p-col-1" style="margin-top: -7px">
                                <span  style="font-size: 13px;">Referencia:</span>
                            </div>
                        </div>
                        <div class="p-grid" >
                            <div class="p-col-6" style="margin-top: -7px">
                                <input id="nombreComprobante" type="text" style="font-size: 20px; height: 25px;" pInputText [(ngModel)]="txtComprobante"  maxlength="45">
                            </div>
                            <div class="p-col-6" style="margin-top: -7px">
                                <input id="nombreref" type="text" style="font-size: 12px; height: 25px;" pInputText [(ngModel)]="txtnomreferencia"  maxlength="45">
                            </div>
                        </div>
                    </div>
                        <!-- <div class="p-col-12"  style="margin-top: -7px"> -->
                            
                        <!-- </div> -->
                </div>
            </div>           
            <app-aggridsaci *ngIf="tablevis==true" [width]="" 
            [height]="largo"
            [enableSorting]="true"
            [enableFilter]="true"
            [rowData]="conAsiDesT"
            [frameworkComponents]="frameworkComponents"
            [animateRows]="true"
            [rowSelection]="rowSelection"
            [columnDefs]="columnDefConAsiDesT"
            [defaultColDef]="defaultColDefConAsiDesT"
            [rowStyle]="rowStyle"
            [singleClickEdit]="false"
            [botones]="false"
            (selected)=selectDefConAsiDesT($event)
            (cambios)="cambioDefConAsiDesT($event)"

            ></app-aggridsaci> 
            <app-aggridsaci *ngIf="tablevis===false" [width]="" 
            [height]="largo"
            [enableSorting]="true"
            [enableFilter]="true"
            [rowData]="conAsiDesD"
            [frameworkComponents]="frameworkComponents"
            [animateRows]="true"
            [rowSelection]="rowSelection"
            [columnDefs]="columnDefConAsiDesD"
            [defaultColDef]="defaultColDefConAsiDesD"
            [rowStyle]="rowStyle"
            [singleClickEdit]="false"
            [botones]="false"
            (selected)=selectDefConAsiDesD($event)
            (cambios)="cambioDefConAsiDesD($event)"

            ></app-aggridsaci> 
			
        </p-tabPanel>
    </p-tabView>

