<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="trnlote"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="trnloteconf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="divgrups">
	<div class="p-grid">
		<div class="p-col-12">
			<div class="divgrups" aria-valuetext="Lotes">
				<div class="p-grid">
					<div class="p-col-2">
						<span>Artículo:</span>
					</div>
					<div class="p-col-3">
						<label for="ARTICULO"></label>
						<input class="frm" id="ARTICULO" type="text" pInputText [(ngModel)]="strCodigo" disabled
							   autocomplete="off">
					</div>
					<div class="p-col-1">
					</div>
					<div class="p-col-6">
						<label for="NOMBRE"></label>
						<input class="frm" id="NOMBRE" type="text" pInputText [(ngModel)]="strDescripcion" disabled
							   autocomplete="off">
					</div>
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="datos"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsDetalle"
							[defaultColDef]="defaultColDefDetalle"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false">
			</app-aggridsaci>
		</div>
	</div>
</div>
