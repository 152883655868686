<p-toast [style]="{marginTop: '30px'}" position="top-center" key="rolProceso3"></p-toast>

<div class="divgrups"   id="contorno" style="width: 70vw; height: 70vh;">

    <div class="p-grid">

        <div class="p-col-4">

            <span>Período:</span>

        </div>

        <div class="p-col-8">
            <div  style="height: 180px;">
                <app-aggridsaci #gridDiasVaca  [width]="" style="margin-top: -5px;"
                    [height]="largo"
                    [enableSorting]="false"
                    [enableFilter]="false"
                    [rowData]="vacaDisponibles"
                    [frameworkComponents]="frameworkComponents"
                    [animateRows]="true"
                    [rowSelection]="rowSelection"
                    [columnDefs]="columnDefDiasVaca"
                    [defaultColDef]="defaultColDefDiasVaca"
                    [rowStyle]="rowStyle"
                    [singleClickEdit]="false"
                    [botones]="false"

                ></app-aggridsaci>
            </div>

        </div>

    </div>

    <div class="p-grid">

        <div class="p-col-4">
            <span>Días de vacación disponibles</span>
        </div>

        <div class="p-col-2">
            <input class="frm"  type="text" pInputText readonly="readonly" [(ngModel)]="txtTRANVACAEMPL_SALDODIAS">
        </div>

        <div class="p-col-6"></div>

    </div>

    <div class="p-grid">

        <div class="p-col-4">
            <span>Valor vacaciones</span>
        </div>

        <div class="p-col-2">
            <input class="frm"  type="text" pInputText readonly="readonly" [(ngModel)]="txtTRANVACAEMPL_VALOR">
        </div>

        <div class="p-col-6"></div>

    </div>

    <div class="p-grid">

        <div class="p-col-4">
            <span>Fecha Inicia:</span>
        </div>

        <div class="p-col-2">
            <p-calendar id="inpFechaSali" [inputStyle]="{width: '100%', height: '20px'}"
                    [(ngModel)]="dtpEMP_FECHINI" 
                    dateFormat="dd/mm/yy" 
                    (ngModelChange)="setDtpEMP_FECHINI($event)">
            </p-calendar>
        </div>

        <div class="p-col-6"></div>

    </div>

    <div class="p-grid">

        <div class="p-col-4">
            <span>Fecha Fin:</span>
        </div>

        <div class="p-col-2">
            <p-calendar id="inpFechaSali" [inputStyle]="{width: '100%', height: '20px'}"
                    [(ngModel)]="dtpEMP_FECHFIN" 
                    dateFormat="dd/mm/yy" 
            >
            </p-calendar>
        </div>

        <div class="p-col-6"></div>

    </div>

    <div class="p-grid">

        <div class="p-col-4">
            <span>Observaciones:</span>
        </div>

        <div class="p-col-6">
            <input class="frm"  type="text" pInputText  id="observaciones" [(ngModel)]="txtTRANVACAEMPL_OBSERVACIONES" >
        </div>

        <div class="p-col-2"></div>

    </div>

    <div class="p-grid">
        <div class="p-col-4">
            
        </div>

        <div class="p-col-3">
            <button  
                pButton class="aceptar" 
                style="padding: 0.5em; font-family: Arial, serif;  height: 30px; width: 100%;"
                (click)="cmdCalcVacaciones()">
                Obtener Vacaciones
            </button>
        </div>

        <div class="p-col-5"></div>
    </div>


    <div class="p-grid">

        <div class="p-col-4">

            <span>Tomada:</span>

        </div>

        <div class="p-col-8">
            <div  style="height: 180px;">
                <app-aggridsaci #gridTabla2  [width]="" style="margin-top: -5px;"
                    [height]="largo"
                    [enableSorting]="false"
                    [enableFilter]="false"
                    [rowData]="vacaDisponibles"
                    [frameworkComponents]="frameworkComponents"
                    [animateRows]="true"
                    [rowSelection]="rowSelection"
                    [columnDefs]="columnDefVacaXpe"
                    [defaultColDef]="defaultColDefVacaXpe"
                    [rowStyle]="rowStyle"
                    [singleClickEdit]="false"
                    [botones]="false"

            ></app-aggridsaci>

            </div>

        </div>

    </div>

    <div class="p-grid">
        <div class="p-col-1">

            <span>{{lblRUBRO_CODRUBR}}</span>

        </div>

        <div class="p-col-1"></div>


        <div class="p-col-1">
            <span>Periodo</span>
            <span> {{lblPERIODO_CODIGO}}</span>

        </div>
        <div class="p-col-1"></div>

        <div class="p-col-2">

            <span>{{lblPERIODO_FECINI}}</span>

        </div>
        <div class="p-col-1"></div>


        <div class="p-col-1">

            <span>{{lblEMP_CODIGO}}</span>

        </div>

        <div class="p-col-2">
            <button  
                pButton 
                class="aceptar" 
                style="padding: 0.5em; font-family: Arial, serif;width: 100%"
                (click)="cmd_aceptar()"   >
                Aceptar
            </button>
        </div>

        <div class="p-col-2">
            <button  
                pButton class="aceptar" 
                style="padding: 0.5em; font-family: Arial, serif;width: 100%"
                (click)="cmd_cancelar()"
                >
                Cancelar
            </button>
        </div>

    </div>

    <div class="p-grid">

        <div class="p-col-1">

            <span>{{lblENCROLINDI_CODIGO}}</span>

        </div>


        <div class="p-col-1">

            <span>{{lblNUM_DIASVACA}}</span>

        </div>

    </div>



</div>
