<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="recalcstpmd"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarrecalcstpmd" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"
						 [barraBotones5]="true" [botonVisto]="false" [barraBotonesCRUD]="true" [barraBotones3Alt]="false"
						 (signal)="manejarSenales($event)"
						 style="text-align: left;">
		</app-invmaebarra>
	</div>
</div>

<div class="p-grid general" style="width: 99%; margin-top: -20px">
	<div class="p-col-12"></div>
	<div class="p-col-12">
		<div class="divgrups">
			<div class="p-grid" style="margin-top: 10px; margin-bottom: 10px">
				<div class="p-col-1">
				</div>
				<div class="p-col-1">
					<span>  Grupo: </span>
				</div>
				<div class="p-col-2">
					<label for="grupo"></label>
					<input class="frm" id="grupo" type="text" pInputText
						   [(ngModel)]="busGRUPCODIGO"
						   (keydown.enter)="setFocus($event)"
					>
				</div>
				<div class="p-col-1">
					<button type="button" (click)="abrirGrupos('')"
							style="background-image: url(../../../assets/images/iconos/buscar.png);"
					>
					</button>
				</div>
				<div class="p-col-3">
					<label for="grupo2"></label>
					<input class="frm" id="grupo2" type="text" pInputText
						   [(ngModel)]="busGRUPNOMBRE"
						   [disabled]="enableinputs"
					>
				</div>
				<div class="p-col-3">
					<!--<button class="aceptar" pButton
							(click)="reemplazarLista()"
							style="background-image: url(../../../assets/images/iconos/btnAceptar.png);
							line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; margin-left: 25px">
						Reemplazar la lista de artículos
					</button>-->
					<button class="sincss" pButton (click)="reemplazarLista()">Reemplazar la lista de artículos
					</button>
				</div>
				<div class="p-col-1"></div>
				<div class="p-col-1">
				</div>
				<div class="p-col-1">
					<span>  Artículos: </span>
				</div>
				<div class="p-col-2">
					<label for="articulo"></label>
					<input class="frm" id="articulo" type="text" pInputText
						   [(ngModel)]="busARTCODIGO"
						   (keydown.enter)="setFocus($event)"
					>
				</div>
				<div class="p-col-1">
					<button type="button" (click)="abrirArticulos('')"
							style="background-image: url(../../../assets/images/iconos/buscar.png);"
					>
					</button>
				</div>
				<div class="p-col-3">
					<label for="articulo2"></label>
					<input class="frm" id="articulo2" type="text" pInputText
						   [(ngModel)]="busARTNOMBRE"
						   [disabled]="enableinputs"
					>
				</div>
				<div class="p-col-3">
					<!--<button class="aceptar" pButton
							(click)="adicionarLista()"
							style="background-image: url(../../../assets/images/iconos/btnAceptar.png); line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; margin-left: 25px">
						Adicionar a la lista de artículos
					</button>-->
					<button class="sincss" pButton (click)="adicionarLista()">Adicionar a la lista de artículos
					</button>
				</div>
				<div class="p-col-1"></div>
				<div class="p-col-1">
				</div>
				<div class="p-col-1">
					<span>Desde:</span>
				</div>
				<div class="p-col-2">
					<p-calendar id="desdefec" [inputStyle]="{width: '100%', height: '20px'}" dateFormat="dd/mm/yy"
								[(ngModel)]="fechadesde"></p-calendar>
				</div>
				<div class="p-col-8"></div>
				<div class="p-col-1">
				</div>
				<div class="p-col-2">
					<p-checkbox binary="true" label=" Traer todo el inventario" [(ngModel)]="showallinv"
								(click)="mostrartodos()"></p-checkbox>
				</div>
				<div class="p-col-7"></div>
				<div class="p-col-2">
					<p-checkbox binary="true" label="Seleccionar Todos" [(ngModel)]="selectAll"
								(click)="seleccionarTodos()"></p-checkbox>
				</div>
			</div>
		</div>
	</div>
	<div class="p-col-12"  style="margin-top: -30px;">
		<app-aggridsaci
				[width]=""
				[height]="largo"
				[enableSorting]="true"
				[enableFilter]="true"
				[rowData]="invrecalcostopmdService.costosArticulos"
				[frameworkComponents]="frameworkComponents"
				[animateRows]="true"
				[rowSelection]="rowSelection"
				[columnDefs]="columnDefsRecal"
				[defaultColDef]="defaultColRecal"
				[rowStyle]="rowStyle"
				[singleClickEdit]="false"
				[botones]="false"
		></app-aggridsaci>
	</div>
	
	<div class="p-col-12"  style="margin-top: 1px;">
		<p-progressBar [value]="porcentaje"></p-progressBar>
	</div>
</div>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [responsive]="true"
		  [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloBus" [tabla]="tabla"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>
<!-- GSRF -->
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
</div>