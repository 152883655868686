import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ConfInicialesService } from './servicios/conf-iniciales.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {

  constructor(public route: Router, private confIniciales: ConfInicialesService) {
    window.addEventListener('beforeunload', (e) => {
      e.preventDefault();
      if (confIniciales.msgclosereload === true) {
    route.navigate(['login']);
   confIniciales.cerrarSesion();
        const confirmationMessage = '\o/';
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    });
    window.addEventListener('popstate', (e) => {
      e.preventDefault();
   if (confIniciales.msgclosereload === true) {
      route.navigate(['login']);
      confIniciales.cerrarSesion();
        const confirmationMessage = false;
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    });  
  }
  

}
