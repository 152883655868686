import { Component, OnInit, ViewChild } from '@angular/core';
import { ActMaeArea } from '../actinterfaces/actmaearea';
import { SacimaereferenciaactarService } from '../actservicios/sacimaereferenciaactar.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { PermisosService } from 'src/app/advantage/advantageservices/permisos.service';
import { MessageService, ConfirmationService, SelectItem } from 'primeng';
import { Usuario } from 'src/app/usuario';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { DatePipe } from '@angular/common';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { SegMaePermiso } from 'src/app/advantage/advantageinterfaces/segmaepermiso';

@Component({
  selector: 'app-sacimaereferenciaactdp',
  templateUrl: './sacimaereferenciaactdp.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class SacimaereferenciaactdpComponent implements OnInit {

  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  indicador: any;

  // Permisos
  permisos: SegMaePermiso;

  public frameworkComponents;
  rowStyle;
  defaultSaciMaeRef;
  rowSelection = 'multiple';
  largo: string;
  ancho: string;

  detalleReferSelect: ActMaeArea = {};

  where: string;
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  condicion: string;
  opcion: string;

  columnDefActMaeArea = [
    {
      headerName: 'Código', field: 'REF_CODIGO', cellEditor: 'cellPrueba', width: 120,  editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 20
          }
        }
    },
    {
      headerName: 'Nombre', field: 'REF_NOMBRE', cellEditor: 'cellPrueba', width: 350, editable: true,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 100,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: 'Nombre C', field: 'REF_NOMBREC', cellEditor: 'cellPrueba', width: 150, editable:true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 25,
            tienecalculos: true,
          }
        }
    }
  ]


  constructor(public actmaeareaService:SacimaereferenciaactarService, 
    private auditoriaGralService: AuditoriagralService,
    private permisosService: PermisosService, private message: MessageService, public usuario: Usuario,
    private errorService: ErroresBaseDatosService, private busquedaSer: InvbusquedaService,
    private datePipe: DatePipe, private confirmationService: ConfirmationService, 
    private confIniciales: ConfInicialesService, private init: NuevoComponentService) { 
      this.actmaeareaService.detmaeReferenciaArray[this.indicador] = []
      this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.defaultSaciMaeRef = {
      editable: true,
      width: 100,
      objeto: 'actmaerefdp',
      resizable: true
    };
  }
  

  async ngOnInit() {
    this.largo = '360';
    this.ancho = '60';
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.actmaeareaService.btnGuardar[this.indicador] = true;
    this.actmaeareaService.btnNuevo[this.indicador] = false;
    this.actmaeareaService.btnBorrar[this.indicador] = false;
    this.actmaeareaService.btnBuscar[this.indicador]= false;
    this.actmaeareaService.detmaeReferenciaArray[this.indicador] = []
    await this.getPermisos();
    await this.llenarDatos();
   
  }
  async manejarSenales(valor) {
    if (valor === 'Nuevo') {
      this.nuevoRegistro();
    }
    if (valor === 'Borrar') {
      this.eliminarRegistro();
    }
    if (valor === 'Guardar') {
     this.guardarRegistro();
      
    }
    if (valor === 'Cancelar') {
     await this.llenarDatos();
     this.activaBotones('G',true,false,false,true,true,true,true,true,true,true);
    }
    
    if (valor === 'Buscar') {
      await this.llenarDatos();
      this.buscarRegistro();
    }

    if (valor === 'Salir') {
     
      this.init.tabs.splice(this.init.tabindex, 1);
          
    }
  }

  manejarSeleccion(valor) {
    if (this.opcion === 'BUSCAR') {
      this.displayDialogBusquedaFast = false;
      this.llenarTabla(valor);
    }
  }

  llenarTabla(datos) {
    this.actmaeareaService.detmaeReferenciaArray[this.indicador] = datos;
    let numLin = 0;
    this.actmaeareaService.detmaeReferenciaArray[this.indicador].map( item => {
      numLin ++;
      item.IS_NEW_ROW = false;
    });
    this.aggrid.refreshaggrid(this.actmaeareaService.detmaeReferenciaArray[this.indicador], this.defaultSaciMaeRef.objeto);
  }

  buscarRegistro() {
    this.opcion = 'BUSCAR';
    this.types = [
      {label: 'CÓDIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'},
      {label: 'NOMBRE CORTO', value: 'REF_NOMBREC'}
    ];
  
    this.busquedacampos = ['', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'saci_maereferencia';
    this.condicion = 'REF_tipo=\'ACTDP\'';
    this.arregloCons = [];
    this.displayDialogBusquedaFast = true;
  }

  async eliminarRegistro () {
    if (this.permisos !== null ) {
      if (this.permisos.PERTODO === 0 ) {
        if (this.permisos.PERELIMACION === 0 ) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ', no tiene permiso de eliminación. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }
    
    if (Object.keys(this.detalleReferSelect).length > 0) {
      let auditoria=`${this.detalleReferSelect.REF_TIPO}/${this.detalleReferSelect.REF_CODIGO}`;
      this.confirmationService.confirm({
        message: '¿Está seguro que desea eliminar este registro?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'actmaedptoConf',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: async () => {

          try {
            await this.actmaeareaService.eliminarReferencia(this.detalleReferSelect.REF_TIPO, this.detalleReferSelect);
            this.mensajeAlerta('success', 'Información', 'Registro eliminado exitosamente..!!!');
            this.auditoriaGralService.registrarAuditoria('SACI_MAEREFERENCIA', auditoria, 'E',
            '', '01', '', '', '', '').toPromise().then(() => {
            });

            this.actmaeareaService.detmaeReferenciaArray[this.indicador] = this.actmaeareaService.detmaeReferenciaArray[this.indicador].filter((val, j) => j !==
            this.actmaeareaService.detmaeReferenciaArray[this.indicador].indexOf(this.detalleReferSelect));
            this.aggrid.refreshaggrid(this.actmaeareaService.detmaeReferenciaArray[this.indicador], this.defaultSaciMaeRef.objeto);

			      this.activaBotones('G',true,false,false,true,true,true,true,true,true,true);
          } catch (err) {
            this.mensajeAlerta('error','Error', err.error);
          }

          
        },
        reject: () => {
        }
      })
    } else {
      this.mensajeAlerta('info','Información', 'Seleccione una fila');
    }

      
    
  }
  async guardarRegistro () {
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultSaciMaeRef.objeto].stopEditing();
    
    if (this.detalleReferSelect.REF_CODIGO ==='' || this.detalleReferSelect.REF_NOMBRE === '') {
      this.mensajeAlerta('info', 'Información', 'Los campos código y nombre son obligatorios.. !!!');
      return;
    }
    let auditoria=`${this.detalleReferSelect.REF_TIPO}/${this.detalleReferSelect.REF_TIPO}`;
    if(this.detalleReferSelect.IS_NEW_ROW){
      if (this.permisos !== null ) {
        if (this.permisos.PERTODO === 0 ) {
          if (this.permisos.PERINSERCION === 0 ) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.');
            return;
          }
        }
      }
      await this.actmaeareaService.insertarReferencia(this.detalleReferSelect.REF_TIPO, this.detalleReferSelect);
      this.mensajeAlerta('success', 'Información','Registro guardado exitosamente...!!!');
      this.auditoriaGralService.registrarAuditoria('SACI_MAEREFERENCIA', auditoria, 'I',
        '', '01', '', '', '', '').toPromise().then(() => {
      });
      await this.llenarDatos();
    } else if(!this.detalleReferSelect.IS_NEW_ROW){
      if (this.permisos !== null ) {
        if (this.permisos.PERTODO === 0 ) {
          if (this.permisos.PERACTUALIZACION === 0 ) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
            return;
          }
        }
      }
      await this.actmaeareaService.actualizarReferencia(this.detalleReferSelect.REF_TIPO, this.detalleReferSelect)
      this.mensajeAlerta('success', 'Información','Registro actualizado exitosamente...!!!');
      this.auditoriaGralService.registrarAuditoria('SACI_MAEREFERENCIA', auditoria, 'A',
        '', '01', '', '', '', '').toPromise().then(() => {
      });  
      await this.llenarDatos();
    }

    this.activaBotones('G',true,false,false,true,true,true,true,true,true,true);
    
  }

  nuevoRegistro() {
    const linea = this.actmaeareaService.detmaeReferenciaArray[this.indicador].length;
    const nuevodetalle: ActMaeArea = {
      IS_NEW_ROW: true,
      REF_CODIGO: '',
      REF_NOMBRE: '',
      REF_NOMBREC: '',
      REF_VALOR: '',
      REF_TIPO: 'ACTDP'
    };
         
    const indi = this.actmaeareaService.detmaeReferenciaArray[this.indicador].indexOf(this.detalleReferSelect);
    if (indi === -1) {
      this.actmaeareaService.detmaeReferenciaArray[this.indicador].push(nuevodetalle);
      this.detalleReferSelect = this.actmaeareaService.detmaeReferenciaArray[this.indicador][this.actmaeareaService.detmaeReferenciaArray[this.indicador].length - 1];
      this.aggrid.refreshaggrid(this.actmaeareaService.detmaeReferenciaArray[this.indicador], this.defaultSaciMaeRef.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultSaciMaeRef.objeto].setFocusedCell(
        this.actmaeareaService.detmaeReferenciaArray[this.indicador].length - 1, 'REF_CODIGO');
    } else {
      this.detalleReferSelect = nuevodetalle;
      this.actmaeareaService.detmaeReferenciaArray[this.indicador].splice(indi + 1, 0, nuevodetalle);
      this.aggrid.refreshaggridindex(this.actmaeareaService.detmaeReferenciaArray[this.indicador], this.defaultSaciMaeRef.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultSaciMaeRef.objeto].setFocusedCell(
      indi + 1, 'REF_CODIGO');
    }

    this.activaBotones('M',false,true,true,false,false,false,false,false,false,false); 
  }

  activaBotones(strTipo,bolNuevo,bolGuardar,bolCancelar,bolEliminar,bolInicio,bolAnterior,bolSiguiente,bolUltimo,bolBuscar,bolImprimir) {
    this.actmaeareaService.btnGuardar[this.indicador] = !bolGuardar;
    this.actmaeareaService.btnNuevo[this.indicador] = !bolNuevo; 
    this.actmaeareaService.btnBorrar[this.indicador] = !bolEliminar;
  }

  async llenarDatos () {
    this.actmaeareaService.detmaeReferenciaArray[this.indicador] = []
    try{
      const rs = await this.actmaeareaService.consultarReferencia('ACTDP');
      const detalle = []
      if (rs !== null) {
        for (const det of rs) {
          const item: ActMaeArea = {};
          item.IS_NEW_ROW = false;
          item.REF_CODIGO = det['Código'];
          item.REF_NOMBRE = det['Nombre'];
          item.REF_NOMBREC = det['Nombre Corto'];
          item.REF_TIPO = det['Tipo'];
          item.REF_VALOR = det['Valor'];
          detalle.push(item);
        }
        this.actmaeareaService.detmaeReferenciaArray[this.indicador] = detalle;
      }
      
    } catch (err) {
      this.mensajeAlerta('error', 'Error', err.error);
    }
  }

  cambio(event) {
   this.activaBotones('M',false,true,true,false,false,false,false,false,false,false); 
  }

  async getPermisos() {
   
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisos = data[0];
        } else {
          this.permisos = null;
        }
      } else {
        this.permisos = null;
      }
    } catch (e) {
      this.permisos = null;
      this.mensajeAlerta('error', 'Error', 'No se pudo obtener los permisos del formulario');
    }
  }

  selectActMaeArea(valor) {

    if (valor.object !== undefined) {
      this.detalleReferSelect = valor.object;
    }
  }

  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'actmaedpto',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

  isEdit(data): boolean {
    if (data.IS_NEW_ROW) {
      return true;
    } else {
      return false;
    }

  }

}
