<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="enctransfer"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmenctransfer" title="Pregunta"
				 appendTo="body"></p-confirmDialog>
<div class="layout-config">
	<div class="layout-config-content-wrapper">
		<a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones()">
			<i class="pi pi-bars" style="cursor: pointer"></i>
		</a>
		<p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
				   [dismissible]="true" [showCloseIcon]="false">
			<br>
			<br>
			<br>
			<p-tabView>
				<p-tabPanel header="Acciones">
					<div class="p-grid">
						<div class="p-col-12">
							<i class="pi pi-caret-right archivoOpc"></i><span (click)="agregarSeriales()"
																			  class="archivoOpc">Ingresar Seriales</span>
						</div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-sidebar>
	</div>
</div>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra (signal)="manejarSenales($event)"
						 [botonBorrar]="botonBorrar"
						 [botonNuevo]="botonNuevo"
						 [botonGuardar]="botonGuardar"
						 [botonRegresar]="botonRegresar"
						 [botonPrimero]="!botonPrimero"
						 [botonAnterior]="!botonAnterior"
						 [botonSiguiente]="!botonSiguiente"
						 [botonUltimo]="!botonUltimo"
						 [barraBotones5]="false"
						 [barraTransferencia]="false"
						 [botonBuscar]="false"
						 [botonBinocular]="true"
						 [botonAutorizar]="botonAutorizar"
						 [botonAlmacenardoc] = "true"
		></app-invmaebarra>
	</div>
</div>
<div class="p-grid" style="margin-top: -10px; margin-bottom: -7px; width: 99%;">
	<!--LINEA 1-->
	<div class="p-col-9">
	</div>
	<div class="p-col-2">
		<label for="lblAsiNro"></label>
		<input class="frm" id="lblAsiNro" type="text" pInputText
			   style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
			   [disabled]="true" [(ngModel)]="enctransferService.invenctransferarray[indicador].ASI_NRO"
			   (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
	</div>
</div>
<p-tabView [(activeIndex)]="index" [style]="{'width':'98%'}">
	<p-tabPanel header="Datos Generales">
		<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 270px)'}">
			<div class="contorno" id="contorno">
				<div class="divgrups">
					<div class="p-grid">
						<!--LINEA 1-->
						<div class="p-col-2">
							<label for="lblNumero"></label>
							<input class="frm" id="lblNumero" type="text" pInputText
								   style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
								   [disabled]="true"
								   [(ngModel)]="enctransferService.invenctransferarray[indicador].TRANSFER_NRO"
								   (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-2">
							<label for="lblEstado"></label>
							<input class="frm" id="lblEstado" type="text" pInputText
								   style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
								   [disabled]="true"
								   [(ngModel)]="enctransferService.invenctransferarray[indicador].TRANSFER_ESTADO"
								   (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
						</div>
						<!--LINEA 2-->
						<div class="p-col-12" style="margin-top: -1px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Comprobante:</span>
								</div>
								<div class="p-col-6">
									<div class="p-grid">
										<div class="p-col-6">
											<label for="txtComprobante"></label>
											<input class="frm" id="txtComprobante" type="text" pInputText
												   [(ngModel)]="enctransferService.invenctransferarray[indicador].TRANSFER_COMPROBANTE"
												   (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
												   autocomplete="off">
										</div>
										<div class="p-col-6">
											<div class="p-grid">
												<div class="p-col-4">
													<p-checkbox name="notadeventa" value="" label=""
																binary="{{chkguiaremision}}"
																[(ngModel)]="chkguiaremision"></p-checkbox>
												</div>
												<div class="p-col-8">
													<span>Nota de Venta</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="p-col-5">
									<div class="p-grid">
										<div class="p-col-3">
											<span>Autorización:</span>
										</div>
										<div class="p-col-3">
											<p-calendar id="dateTimeFechaAutoriza" [disabled]="!boldateAutoriza"
														[inputStyle]="{width: '100%', height: '20px'}"
														[readonlyInput]="true" dateFormat="dd/mm/yy"
														[(ngModel)]="enctransferService.invenctransferarray[indicador].TRANSFER_FECHAAUTORIZA"
														(keydown.enter)="setFocus($event)"
														(keydown)="botonesmodificar()">
											</p-calendar>
										</div>
										<div class="p-col-3">
											<span>Transportista:</span>
										</div>
										<div class="p-col-3">
											<div class="p-grid">
												<div class="p-col-9">
													<label for="txtCodTransportista"></label>
													<input class="frm" id="txtCodTransportista" type="text" pInputText
														   [(ngModel)]="enctransferService.invenctransferarray[indicador].TRANSPORT_CODIGO"
														   (keydown.enter)="setFocus($event)"
														   (keydown)="botonesmodificar()"
														   autocomplete="off">
												</div>
												<div class="p-col-2">
													<button type="button"
															style="background-image: url(../../../assets/images/iconos/buscar.png);"
															(click)="busquedaTransportista(enctransferService.invenctransferarray[indicador].TRANSPORT_CODIGO)"></button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!--LINEA 3-->
						<div class="p-col-12" style="margin-top: -35px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Fecha Emisión:</span>
								</div>
								<div class="p-col-6">
									<div class="p-grid">
										<div class="p-col-6">
											<p-calendar id="dateTimeFechaEmision"
														[inputStyle]="{width: '100%', height: '20px'}"
														[readonlyInput]="true" dateFormat="dd/mm/yy"
														[(ngModel)]="enctransferService.invenctransferarray[indicador].TRANSFER_FECHAEMISION"
														(keydown.enter)="setFocus($event)"
														(keydown)="botonesmodificar()">
											</p-calendar>
										</div>
										<div class="p-col-6">
											<div class="p-grid">
												<div class="p-col-2">
													<span>Hora</span>
												</div>
												<div class="p-col-1"></div>
												<div class="p-col-6">
													<p-calendar id="TimeHora"
																[inputStyle]="{width: '80%', height: '20px'}"
																[timeOnly]="true"
																[(ngModel)]="enctransferService.invenctransferarray[indicador].TRANSFER_HORA"
																[readonlyInput]="true" dateFormat="dd/mm/yy"
																(keydown.enter)="setFocus($event)"
																(keydown)="botonesmodificar()">
													</p-calendar>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="p-col-5">
									<div class="p-grid">
										<div class="p-col-3">
											<span>Autorizado por:</span>
										</div>
										<div class="p-col-3">
											<label for="txtNombreAutoriza"></label>
											<input class="frm" id="txtNombreAutoriza" type="text" pInputText
												   [(ngModel)]="enctransferService.invenctransferarray[indicador].TRANSFER_PERSONAAUTORIZA"
												   (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
												   autocomplete="off">
										</div>
										<div class="p-col-3">
											<span>Nombre:</span>
										</div>
										<div class="p-col-3">
											<label for="lblNombreTrans"></label>
											<input class="frm" id="lblNombreTrans" type="text" pInputText disabled
												   [(ngModel)]="enctransferService.invenctransferarray[indicador].TRANSPORT_NOMBRE"
												   (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
												   autocomplete="off">
										</div>
									</div>
								</div>
							</div>
						</div>
						<!--LINEA 4-->
						<div class="p-col-12" style="margin-top: -28px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Bodega Origen:</span>
								</div>
								<div class="p-col-6">
									<div class="p-grid">
										<div class="p-col-6">
											<div class="p-grid">
												<div class="p-col-10">
													<label for="txtCodBodOrigen"></label>
													<input class="frm" id="txtCodBodOrigen" type="text" pInputText
														   [(ngModel)]="enctransferService.invenctransferarray[indicador].BOD_CODIGO"
														   (keydown.enter)="setFocus($event)"
														   (keydown)="botonesmodificar()"
														   [disabled]="disablebod"
														   autocomplete="off">
												</div>
												<div class="p-col-2">
													<button type="button"
															style="background-image: url(../../../assets/images/iconos/buscar.png);"
															(click)="busquedaBodega(enctransferService.invenctransferarray[indicador].BOD_CODIGO, 'origen')"></button>
												</div>
											</div>
										</div>
										<div class="p-col-6">
											<label for="lblNomBodOrigen"></label>
											<input class="frm" id="lblNomBodOrigen" type="text" pInputText
												   [(ngModel)]="enctransferService.invenctransferarray[indicador].BOD_NOMBRE"
												   (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
												   autocomplete="off">
										</div>
									</div>
								</div>
								<div class="p-col-5">
									<div class="p-grid">
										<div class="p-col-3">
											<span>Transferencia:</span>
										</div>
										<div class="p-col-3">
											<p-calendar id="dateTimeFechaTransf"
														[inputStyle]="{width: '100%', height: '20px'}"
														[readonlyInput]="true" dateFormat="dd/mm/yy"
														[(ngModel)]="enctransferService.invenctransferarray[indicador].TRANSFER_FECHATRANSFER"
														(keydown.enter)="setFocus($event)"
														(keydown)="botonesmodificar()">
											</p-calendar>
										</div>
										<div class="p-col-3">
											<span>Automóvil:</span>
										</div>
										<div class="p-col-3">
											<label for="lblAutomovil"></label>
											<input class="frm" id="lblAutomovil" type="text" pInputText disabled
												   [(ngModel)]="enctransferService.invenctransferarray[indicador].TRANSPORT_AUTO"
												   (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
												   autocomplete="off">
										</div>
									</div>
								</div>
							</div>
						</div>
						<!--LINEA 5-->
						<div class="p-col-12" style="margin-top: -33px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Bodega Destino:</span>
								</div>
								<div class="p-col-6">
									<div class="p-grid">
										<div class="p-col-6">
											<div class="p-grid">
												<div class="p-col-10">
													<label for="txtCodBodDestino"></label>
													<input class="frm" id="txtCodBodDestino" type="text" pInputText
														   [(ngModel)]="enctransferService.invenctransferarray[indicador].BOD_DESTINO"
														   (keydown.enter)="setFocus($event)"
														   (keydown)="botonesmodificar()"
														   [disabled]="disablebod"
														   autocomplete="off">
												</div>
												<div class="p-col-2">
													<button type="button"
															style="background-image: url(../../../assets/images/iconos/buscar.png);"
															(click)="busquedaBodega(enctransferService.invenctransferarray[indicador].BOD_DESTINO, 'destino')"></button>
												</div>
											</div>
										</div>
										<div class="p-col-6">
											<label for="lblNomBodDestino"></label>
											<input class="frm" id="lblNomBodDestino" type="text" pInputText
												   [(ngModel)]="enctransferService.invenctransferarray[indicador].BOD_DESTINONOMBRE"
												   (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
												   autocomplete="off">
										</div>
									</div>
								</div>
								<div class="p-col-5">
									<div class="p-grid">
										<div class="p-col-3">
											<span>Referencia:</span>
										</div>
										<div class="p-col-3">
											<label for="txtReferencia"></label>
											<input class="frm" id="txtReferencia" type="text" pInputText
												   [(ngModel)]="enctransferService.invenctransferarray[indicador].TRANSFER_REFERENCIA"
												   (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
												   autocomplete="off">
										</div>
										<div class="p-col-3">
											<span>Placa:</span>
										</div>
										<div class="p-col-3">
											<label for="lblPlaca"></label>
											<input class="frm" id="lblPlaca" type="text" pInputText disabled
												   [(ngModel)]="enctransferService.invenctransferarray[indicador].TRANSPORT_PLACA"
												   (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
												   autocomplete="off">
										</div>
									</div>
								</div>
							</div>
						</div>
						<!--LINEA 6-->
						<div class="p-col-12" style="margin-top: -33px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Comentario:</span>
								</div>
								<div class="p-col-11">
									<label for="txtComentario"></label>
									<input class="frm" id="txtComentario" type="text" pInputText
										   [(ngModel)]="enctransferService.invenctransferarray[indicador].TRANSFER_COMENTARIO"
										   (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										   autocomplete="off">
								</div>
							</div>
						</div>
						<!--LINEA 7-->
						<div class="p-col-12" style="margin-top: -15px; margin-bottom: -24px">
							<div class="p-grid">
								<div class="p-col-7" *ngIf="groupDatosPorNumAdic">
									<div class="divgrups">
										<div class="p-grid">
											<div class="p-col-12" style="margin-top: -1px; margin-bottom: -20px">
												<div class="p-grid">
													<div class="p-col-2">
														<span>Muestrario:</span>
													</div>
													<div class="p-col-2">
														<label for="lblMuestrario"></label>
														<input class="frm" id="lblMuestrario" type="text" pInputText
															   [(ngModel)]="enctransferService.invenctransferarray[indicador].MUESTRARIO"
															   (keydown.enter)="setFocus($event)"
															   (keydown)="botonesmodificar()"
															   autocomplete="off">
													</div>
													<div class="p-col-2">
														<span>Cliente:</span>
													</div>
													<div class="p-col-3">
														<div class="p-grid">
															<div class="p-col-10">
																<label for="txtCodCliente"></label>
																<input class="frm" id="txtCodCliente" type="text"
																	   pInputText
																	   [(ngModel)]="enctransferService.invenctransferarray[indicador].CLI_CODIGO"
																	   (keydown.enter)="setFocus($event)"
																	   (keydown)="botonesmodificar()"
																	   autocomplete="off">
															</div>
															<div class="p-col-2">
																<button type="button"
																		style="background-image: url(../../../assets/images/iconos/buscar.png);"
																		(click)="busquedacliente(enctransferService.invenctransferarray[indicador].CLI_CODIGO)"></button>
															</div>
														</div>
													</div>
													<div class="p-col-3">
														<label for="lblNombreCliente"></label>
														<input class="frm" id="lblNombreCliente" type="text" pInputText
															   [(ngModel)]="enctransferService.invenctransferarray[indicador].CLI_NOMBRE"
															   (keydown.enter)="setFocus($event)"
															   (keydown)="botonesmodificar()"
															   autocomplete="off">
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="p-col-5" *ngIf="groupCodProv">
									<div class="divgrups">
										<div class="p-grid">
											<div class="p-col-12" style="margin-top: -1px; margin-bottom: -20px">
												<div class="p-grid">
													<div class="p-col-4">
														<span>Proveedor:</span>
													</div>
													<div class="p-col-4">
														<div class="p-grid">
															<div class="p-col-10">
																<label for="txtCodProveedor"></label>
																<input class="frm" id="txtCodProveedor" type="text"
																	   pInputText
																	   [(ngModel)]="enctransferService.invenctransferarray[indicador].PRO_CODIGO"
																	   (keydown.enter)="setFocus($event)"
																	   (keydown)="botonesmodificar()"
																	   autocomplete="off">
															</div>
															<div class="p-col-2">
																<button type="button"
																		style="background-image: url(../../../assets/images/iconos/buscar.png);"
																		(click)="busquedaBodega(enctransferService.invenctransferarray[indicador].PRO_CODIGO, 'origen')"></button>
															</div>
														</div>
													</div>
													<div class="p-col-4">
														<label for="lbNomProveedor"></label>
														<input class="frm" id="lbNomProveedor" type="text" pInputText
															   [(ngModel)]="enctransferService.invenctransferarray[indicador].PRO_NOMBRE"
															   (keydown.enter)="setFocus($event)"
															   (keydown)="botonesmodificar()"
															   autocomplete="off">
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<app-aggridsaci [width]=""
								[height]="largo"
								[enableSorting]="true"
								[enableFilter]="true"
								[rowData]="enctransferService.invdettransferarray[indicador]"
								[frameworkComponents]="frameworkComponents"
								[animateRows]="true"
								[rowSelection]="rowSelection"
								[columnDefs]="columnDefsDettransfer"
								[defaultColDef]="defaultColDef"
								[rowStyle]="rowStyle"
								[singleClickEdit]="false"
								[botones]="true"
								(selected)="selecciondettransfer($event)"
								[mostrarGuardar]="false"
								[botonesAdicionales]="true"
								[botonTransferencia]="botonTransferencia"
								[btnInsertarReservas]="false"
								(cambios)="cambio($event)"
								(click)="selecciondettransfer($event)"
				></app-aggridsaci>
				<div class="p-grid" style="margin-top: -15px; margin-bottom: -30px">
					<div class="p-col-7"></div>
					<div class="p-col-1">
						<span>Total:</span>
					</div>
					<div class="p-col-2">
						<label for="lblTotal"></label>
						<input class="frm" id="lblTotal" type="text" pInputText disabled
							   [(ngModel)]="enctransferService.invenctransferarray[indicador].TOTAL"
							   (keydown.enter)="setFocus($event)"
							   (keydown)="botonesmodificar()"
							   autocomplete="off">
					</div>
				</div>
			</div>
		</p-scrollPanel>
	</p-tabPanel>
	<!--<p-tabPanel header="Varios">
		<app-aggridsaci [width]=""
						[height]="largo"
						[enableSorting]="true"
						[enableFilter]="true"
						[rowData]="enctransferService.invtrnvariostrfarray[indicador]"
						[frameworkComponents]="frameworkComponents"
						[animateRows]="true"
						[rowSelection]="rowSelection"
						[columnDefs]="columnDefsTrnVariosTrf"
						[defaultColDef]="defaultColDefVarios"
						[rowStyle]="rowStyle"
						[singleClickEdit]="false"
						[botones]="true"
						(selected)="selecciontrnvariostrf($event)"
						[mostrarGuardar]="false"
						[botonesAdicionales]="true"
						[botonTransferencia]="botonTransferencia"
						(cambios)="cambio($event)"
						(click)="selecciontrnvariostrf($event)"
		></app-aggridsaci>
	</p-tabPanel>-->
</p-tabView>

<p-dialog header="Clave de Autorización" [(visible)]="displayDialogAutorizacion"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  (onHide)="onHideAutorizacion()"
		  [style]="{width: '40vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="autorizacion"></p-toast>
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							   (keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog header="Ingreso de Seriales" [(visible)]="displayDialogSeriales"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '50vw', height: 'auto'}">
	<app-invtrnkardexotr [strNumFact]="strNumFact" [strFechaSer]="strFechaSer" [strTipoDoc]="'TRF'"
						 [strTipoTrn]="'SA'" (cerrar)="cerrarSeriales()" [strnBodega]= "enctransferService.invenctransferarray[indicador].BOD_CODIGO"
						 [strnBoddestino]="enctransferService.invenctransferarray[indicador].BOD_DESTINO"></app-invtrnkardexotr>
</p-dialog>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusqueda"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [closable]="true"
		  [style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Reportes" [(visible)]="displayDialogReportes"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '40vw', height: '400px'}">
	<app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
						 [strNumDoc]="enctransferService.invenctransferarray[indicador].TRANSFER_NRO"
						 [strCampo]="'TRANSFER_NRO'" (cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>

<p-dialog header="Selección de datos" [(visible)]="displayDialogReservas"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width:'70vw', height:'auto'}">
	<app-invreservas *ngIf="displayDialogReservas" [bodCodigo]="bodCodigoReserva" [bodDestino]="bodDestinoReserva" [fechaCaduca]="fechaReserva"
					 (detallesSeleccionados)="recibirReservas($event)" (signalSalir)="recibirSenalesReservas($event)"
					 [indicador]="indicador"></app-invreservas>
</p-dialog>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'INVENCTRANSFER'" [txtNumComprobante] = "enctransferService.invenctransferarray[indicador].TRANSFER_NRO"
	(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>
