<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="segMaePerfiltoast"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="segPerfilDialog" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>

<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar" [barraBotonesDesplaz]="true"
						 [barraBotones3]="true"  [botonBuscar]="btnBuscar"
						 (signal)="manejarSenales($event)">
		</app-invmaebarra>
	</div>
</div>
<div style="overflow: hidden; margin-top: -10px;" class="p-col-12">
	<p-tabView (onChange)="onTab1Change()" [(activeIndex)]="indexTab">
		<p-tabPanel header="Perfiles" [disabled]="activateTabs[0]">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="segmaePerfil"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefMaePerfil"
							[defaultColDef]="defaultColDefMaePerfiles"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)=selectMaePerfil($event)
							(cambios)="cambio($event)"

			></app-aggridsaci>
		</p-tabPanel>
		<p-tabPanel header="Permisos Personalizados" [disabled]="activateTabs[1]">
			<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 270px)'}">
				<div class="divgrups">
					<div class="p-grid" style="margin-top: 10px; margin-bottom: 10px">
						<div class="p-col-4">
							<span *ngIf="selectedMaePerfil">Perfil: {{selectedMaePerfil.Nombre}}</span>
						</div>
					</div>
				</div>
				<div class="divgrups">


					<p-tree [value]="treeData" selectionMode="checkbox" (onNodeSelect)="permisoSelect($event)"
							(onNodeUnselect)="permisoUnselect($event)" [(selection)]="selectedFiles2" styleClass="arbol" ></p-tree>



				</div>
			</p-scrollPanel>
		</p-tabPanel>
	</p-tabView>

	<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [responsive]="true"
			  [transitionOptions]="'0ms'"
			  [modal]="true"
			  appendTo="body"
			  [style]="{width: '70vw', height: '70vh'} ">
		<app-busquedadlgmae [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
							[where]="condicion"
							[busquedaParams]="busquedacampos"
							(opcionSeleccionada)="manejarSeleccion($event)"
							[busquedaCompuesta]="false"></app-busquedadlgmae>
	</p-dialog>
	<div style=" 	position: fixed;
				z-index: 999;
				height: 2em;
				width: 2em;
				overflow: visible;
				margin: auto;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;

				" >
		<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}"
						   animationDuration=".5s"></p-progressSpinner>
	</div>
</div>