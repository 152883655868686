import { Component, OnInit ,ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import { AggridBanComponent } from 'src/app/ban/aggrid-ban/aggrid-ban.component';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';

import { NomempleadosService } from '../nomservicios/nomempleados.service';
import { isNullOrUndefined } from 'util';
import { DatePipe } from '@angular/common';
import { NommaeEmpleado } from '../nominterfaces/nomempleado';
import { nomVacaciones } from '../nominterfaces/nomvacaciones';

@Component({
  selector: 'app-nomepleadovac',
  templateUrl: './nomepleadovac.component.html',
  styleUrls: ['./nomepleadovac.component.css']
})
export class NomepleadovacComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  @Input() selNomEmpl:NommaeEmpleado;
  @Output() salirvac= new EventEmitter<string>();

  types: SelectItem[];
  busquedacampos = [];
  tabla: string;
  consulta: string;
  arregloCons: any[] = [];
  arrlegoBus = [];
  where: string;
  opcion: string;
  condicion: string;
  btnGuardar: boolean;
  btnNuevo = true;
  btnBorrar: boolean;
  btnRegresar: boolean;
  btnBuscar: boolean;
  btnRecargar:boolean;
  public frameworkComponents;
  rowStyle;
  largo = '500';
  rowSelection = 'multiple';
  vacacionestabla:boolean=false;
  defaultColDefVacaciones;
  selectedVacaciones:nomVacaciones ;
  columnDefVacaciones=[
    {
      headerName: 'Tipo', editable: (params) => {
        const boolEdit = this.isEdit(params.data) ;
        return boolEdit;
      }, field: 'TRANVACAEMPL_TIPO', cellEditor: 'cellPrueba', width: 80,  
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: false,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Periodo', editable: true, field: 'TRANVACAEMPL_PERIODO', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Desde', editable: true, field: 'TRANVACAEMPL_FECINIPERI', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: true,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }, onCellValueChanged: (params) => {
          this.cambioDesde(params);
        }
    },
    {
      headerName: 'Hasta', editable: true, field: 'TRANVACAEMPL_FECFINPERI', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: true,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }, onCellValueChanged: (params) => {
          this.cambioHasta(params);
        }
    },
    {
      headerName: 'D. Periodo', editable: true, field: 'TRANVACAEMPL_DIASPERI', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'D. Prorrateados', editable: true, field: 'TRANVACAEMPL_DIASPRORR', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }, onCellValueChanged: (params) => {
          this.cambioDPro(params);
        }
    },
    {
      headerName: 'B. Acumulada', editable: true, field: 'TRANVACAEMPL_BASEACUM', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Valor', editable: true, field: 'TRANVACAEMPL_VALOR', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Estatus', editable: true, field: 'TRANVACAEMPL_ESTADO', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Saldo Días', editable: true, field: 'TRANVACAEMPL_SALDODIAS', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'S. ValorProv', editable: true, field: 'TRANVACAEMPL_SALDO', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'F. SalidaVaca', editable: true, field: 'TRANVACAEMPL_FECHSALIVACA', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: true,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }, onCellValueChanged: (params) => {
          this.cambioSalVaca(params);
        }
    }
    ,
    {
      headerName: 'F. RegresoVaca.', editable: true, field: 'TRANVACAEMPL_FECHREGRVACA', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: true,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }, onCellValueChanged: (params) => {
          this.cambioEntVaca(params);
        }
    },
    {
      headerName: 'Observaciones', editable: true, field: 'TRANVACAEMPL_OBSERVACIONES', cellEditor: 'cellPrueba', width: 150, 
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 80,
            tienecalculos: false,
          }
        }
    }
    ,
  ];
  constructor( private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private confirmationService: ConfirmationService,
    private auditoriaGralService: AuditoriagralService,
    private init: NuevoComponentService,
    public nomempleadosService:NomempleadosService,
    private pipe: DatePipe
   ) {
     console.log('funciona')
    this.agTable();
    }

  ngOnInit(): void {
    console.log(this.selNomEmpl);
    console.log('si llega');
    this.btnNuevo =  false;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.btnRegresar = false;
    this.btnBuscar = false;
    this.btnRecargar=false;
    let i=0;
    this.nomempleadosService.listavacaciones(this.selNomEmpl.EMP_CODIGO).then((res:any)=>{
      this.nomempleadosService.nomcrudVaca=res;
      this.nomempleadosService.nomcrudVaca.map(x=>{
        x.TRANVACAEMPL_PERIODO=this.numberEmplPer(x.TRANVACAEMPL_PERIODO);
        x.TRANVACAEMPL_DIASPRORR=parseFloat(this.numberEmplPer(x.TRANVACAEMPL_DIASPRORR)).toFixed(4);
        x.TRANVACAEMPL_BASEACUM=parseFloat(this.numberEmplPer(x.TRANVACAEMPL_BASEACUM)).toFixed(4);
        x.TRANVACAEMPL_VALOR=parseFloat(this.numberEmplPer(x.TRANVACAEMPL_VALOR)).toFixed(2);
        x.TRANVACAEMPL_FECHSALIVACA=this.cambiarfechanull(x.TRANVACAEMPL_FECHSALIVACA);
        x.TRANVACAEMPL_FECHREGRVACA=this.cambiarfechanull(x.TRANVACAEMPL_FECHREGRVACA);
        x.TRANVACAEMPL_FECINIPERI=this.cambiarfechanull(x.TRANVACAEMPL_FECINIPERI);
        x.TRANVACAEMPL_FECFINPERI=this.cambiarfechanull(x.TRANVACAEMPL_FECFINPERI);
        x.NUMVAC=i++;
      });
      let sal=0;
      let saldias=0;
      let salp=0;
      let saldiasp=0;
      for(var va=0;va<this.nomempleadosService.nomcrudVaca.length;va++){
        let i=(va==0)?1:va-1;
         saldias=parseFloat(this.numberEmplPer(this.nomempleadosService.nomcrudVaca[va].TRANVACAEMPL_DIASPRORR));
         sal=parseFloat(this.numberEmplPer(this.nomempleadosService.nomcrudVaca[va].TRANVACAEMPL_VALOR));
         
         if(va==0){
          this.nomempleadosService.nomcrudVaca[va].TRANVACAEMPL_SALDODIAS=saldias.toFixed(4);
          this.nomempleadosService.nomcrudVaca[va].TRANVACAEMPL_SALDO=sal.toFixed(4);
         }else{
          saldiasp=parseFloat(this.nomempleadosService.nomcrudVaca[i].TRANVACAEMPL_SALDODIAS);
          salp=parseFloat(this.nomempleadosService.nomcrudVaca[i].TRANVACAEMPL_SALDO);
          this.nomempleadosService.nomcrudVaca[va].TRANVACAEMPL_SALDODIAS=(saldias+saldiasp).toFixed(4);
          this.nomempleadosService.nomcrudVaca[va].TRANVACAEMPL_SALDO=(sal+salp).toFixed(4);
         }
      }
    })
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefVacaciones= {
      editable: true,
      width: 130,
      objeto: 'nomMaeCrudVaca'
    };
  }
  isEdit(param){
    if(this.selectedVacaciones.NUEVO==false){
      return false;
    }else if(this.selectedVacaciones.NUEVO==true){
      return true;
    }
  }
  numberEmplPer(valor){
    let retr;
    if(isNullOrUndefined(valor)==true ||valor==""){
      return retr="0";
    }else{
      return valor;
    }
  }
  cambiarfechanull(valor){
    let res="";
    if(isNullOrUndefined(valor)){
      return res;
    }else{
      try{
        if(valor.toString().includes('-')){
          res=this.pipe.transform(valor,'dd/MM/yyyy');
          return res;
        }else{
          return valor;
        }
      }catch(err){         
        return valor;
      } 
    }
  }
  manejarSenales(valor){ 
    switch (valor) {
      case 'Nuevo':
        this.opcionNuevo();
        break;
      case 'Guardar':
        this.opcionGuardar();
        break;
      case 'Borrar':
        this.opcionEliminar();
        break;
      case 'Cancelar':
        this.cargar();
        break;
      case 'Salir':
        this.salirvac.emit('salir');
        break;
    
    }
  }
  opcionNuevo(){
     this.btnNuevo = true;
    this.btnBorrar = false;
    this.btnRegresar = false;
    this.aggrid.isEditable(true);
    this.nuevoModulo();
  }
  nuevoModulo() {
      const newModulo:  nomVacaciones = {  
        COM_CODIGO:'01',
        DETROLINDI_NUMSEC:'',
        EMP_CODIGO:'',
        ENCANTEMP_NRODOCUMENTO:'',
        ENCROLINDI_CODIGO:'',
        ENCROLINDI_CODIGOCIERRE:'',
        PERIODO_CODIGO:'',
        TRANVACAEMPL_ABONDIA:'',
        TRANVACAEMPL_ABONO:'',
        TRANVACAEMPL_BASEACUM:'',
        TRANVACAEMPL_CODIGO:'',
        TRANVACAEMPL_DIASPERI:'',
        TRANVACAEMPL_DIASPRORR:'',
        TRANVACAEMPL_ESTADO:'',
        TRANVACAEMPL_FECFINPERI:'',
        TRANVACAEMPL_FECHREGRVACA:'',
        TRANVACAEMPL_FECHSALIVACA:'',
        TRANVACAEMPL_FECINIPERI:'',
        TRANVACAEMPL_OBSERVACIONES:'',
        TRANVACAEMPL_PERIODO:'',
        TRANVACAEMPL_SALDO:'',
        TRANVACAEMPL_SALDODIAS:'',
        TRANVACAEMPL_SALDOPROV:'',
        TRANVACAEMPL_TIPO:'',
        TRANVACAEMPL_VALOR:'',
        NUEVO:true,
        NUMVAC:this.nomempleadosService.nomcrudVaca.length +1
    };
    
    this.nomempleadosService.nomcrudVaca.push(newModulo); 
    this.aggrid.refreshaggrid(this.nomempleadosService.nomcrudVaca, this.defaultColDefVacaciones.objeto ); 
    const indice= this.nomempleadosService.nomcrudVaca.indexOf(newModulo);
    this.aggrid.gridApi.setFocusedCell(indice , 'TRANVACAEMPL_TIPO'); 
    
  }
  cambioDesde(elemento){
      let index= this.nomempleadosService.nomcrudVaca.map(x=>{return x.NUMVAC}).indexOf(this.selectedVacaciones.NUMVAC);
      this.nomempleadosService.nomcrudVaca[index].TRANVACAEMPL_FECINIPERI=this.cambiarfechanull(this.selectedVacaciones.TRANVACAEMPL_FECINIPERI);
      this.aggrid.refreshaggrid(this.nomempleadosService.nomcrudVaca, this.defaultColDefVacaciones.objeto );
      this.aggrid.gridApi.setFocusedCell(index , 'TRANVACAEMPL_FECFINPERI');
    }
  cambioHasta(elemento){
      let index= this.nomempleadosService.nomcrudVaca.map(x=>{return x.NUMVAC}).indexOf(this.selectedVacaciones.NUMVAC);
      this.nomempleadosService.nomcrudVaca[index].TRANVACAEMPL_FECFINPERI=this.cambiarfechanull(this.selectedVacaciones.TRANVACAEMPL_FECFINPERI);
      this.aggrid.refreshaggrid(this.nomempleadosService.nomcrudVaca, this.defaultColDefVacaciones.objeto );
      this.aggrid.gridApi.setFocusedCell(index , 'TRANVACAEMPL_DIASPERI');
  }
  cambioSalVaca(elemento){
    let index= this.nomempleadosService.nomcrudVaca.map(x=>{return x.NUMVAC}).indexOf(this.selectedVacaciones.NUMVAC);
      this.nomempleadosService.nomcrudVaca[index].TRANVACAEMPL_FECHSALIVACA=this.cambiarfechanull(this.selectedVacaciones.TRANVACAEMPL_FECHSALIVACA);
      this.aggrid.refreshaggrid(this.nomempleadosService.nomcrudVaca, this.defaultColDefVacaciones.objeto );
      this.aggrid.gridApi.setFocusedCell(index , 'TRANVACAEMPL_FECHREGRVACA');
  }
  cambioEntVaca(elemento){
    let index= this.nomempleadosService.nomcrudVaca.map(x=>{return x.NUMVAC}).indexOf(this.selectedVacaciones.NUMVAC);
      this.nomempleadosService.nomcrudVaca[index].TRANVACAEMPL_FECHREGRVACA=this.cambiarfechanull(this.selectedVacaciones.TRANVACAEMPL_FECHREGRVACA);
      this.aggrid.refreshaggrid(this.nomempleadosService.nomcrudVaca, this.defaultColDefVacaciones.objeto );
      this.aggrid.gridApi.setFocusedCell(index , 'TRANVACAEMPL_OBSERVACIONES');
  }
  async opcionGuardar() {
    this.aggrid.gridApi.stopEditing();
    let valor=this.validarNulos(this.selectedVacaciones);
    if(valor.length==0){
      let vaneg=this.validarSaldosNeg();
      if(vaneg>=0){
        let neg=this.validarSaldosNegativos(this.selectedVacaciones);
        if(neg.length==0){
          try{
            await this.busPeriodoVac(this.selectedVacaciones.TRANVACAEMPL_FECINIPERI,this.selectedVacaciones.TRANVACAEMPL_FECFINPERI).then(async res=>{
              if(res==""){
                this.mensajeAlerta('error', 'Error', 'No existe el rango de fechas en el maestro de periodos');
              }else{
                this.selectedVacaciones.PERIODO_CODIGO=res;
                await this.nomempleadosService.verificaexistprovvac(this.selNomEmpl.EMP_CODIGO,this.selectedVacaciones.TRANVACAEMPL_FECINIPERI,this.selectedVacaciones.TRANVACAEMPL_FECFINPERI).then(async (x:any)=>{
                    if(parseInt(x[0].NUMREG)>1){
                      this.mensajeAlerta('error', 'Error', 'No puede ingresar más de una provisión en el mismo período de tiempo');
                    }else{
                      await this.nomempleadosService.actualilineadetvacacion(this.selectedVacaciones,this.selNomEmpl.EMP_CODIGO).then(re=>{
                        this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
                        this.cargar();
                        let index2= this.nomempleadosService.nomcrudVaca.map(x=>{return x.NUMVAC}).indexOf(this.selectedVacaciones.NUMVAC);
                        this.aggrid.refreshaggrid(this.nomempleadosService.nomcrudVaca, this.defaultColDefVacaciones.objeto );
                        this.aggrid.gridApi.setFocusedCell(index2 , 'TRANVACAEMPL_TIPO');
                      })
                      
                    }
                });
               
              }
            })
          }catch(e){
            let mensaje:string= e.error;   
            this.mensajeAlerta('error', 'Error', mensaje);
          }
          
        }else{
          let mensaje=""
          for(let i=0;i<neg.length;i++){
            if(i==0){
              mensaje+=neg[0];
            }else{
              mensaje+=", "+neg[i];
            }
          }
          this.mensajeAlerta('error', 'Error', mensaje);
        }
      }else{
        this.mensajeAlerta('error', 'Error', 'El saldo en días no puede ser menor a cero');
      };
      
    }else{
      let mensaje=""
        for(let i=0;i<valor.length;i++){
          if(i==0){
            mensaje+=valor[0];
          }else{
            mensaje+=", "+valor[i];
          }
        }
        this.mensajeAlerta('info', 'Información', mensaje);
    }
    
   
   /*  let paquete = '';
  
    const modulo: SegMaeConsultaTipoRef = {
        
        TIP_CODIGO:  this.selectedsegmaeConsultaTipoRef.TIP_CODIGO,
        TIP_DESCRIPCION: this.selectedsegmaeConsultaTipoRef.TIP_DESCRIPCION,
        EDITABLE: this.selectedsegmaeConsultaTipoRef.TIP_EDITABLE
       };
       let valido = true;
      
    for ( const key in modulo ) { 
      if ( modulo[key] === null || modulo[key] === '') {
        valido = false;
      }
    }
    if ( valido ) {
      
      if(this.selectedsegmaeConsultaTipoRef.NUEVO==true){
        this.aggrid.gridApi.stopEditing();
        paquete='SACI_PCK_MAETIPREF.SACI_SP_insertarTipRef';
        this.segmaetiprefService.ingresarTipoRef(paquete,modulo.TIP_CODIGO,modulo.TIP_DESCRIPCION,modulo.EDITABLE).then(()=>{
          this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
          this.cargar();
          this.auditoriaGralService.registrarAuditoria('SACI_MAETIPOREF', String(this.selectedsegmaeConsultaTipoRef.TIP_CODIGO), 'I',
          '', '01', '', '', '', '').toPromise().then(() => {
          });
        }).catch(e=>{
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        }); 
      
      }else if(this.selectedsegmaeConsultaTipoRef.NUEVO==false){
      
        this.aggrid.gridApi.stopEditing();
        paquete='SACI_PCK_MAETIPREF.SACI_SP_actualizaTipRef';
        this.segmaetiprefService.actualizarTipoRef(paquete,this.selectedsegmaeConsultaTipoRef.TIP_CODIGO,this.selectedsegmaeConsultaTipoRef.TIP_DESCRIPCION,this.selectedsegmaeConsultaTipoRef.EDITABLE).then(res=>{
          this.mensajeAlerta('success', 'Información', '¡Registro exitoso!');
          this.cargar();
          this.auditoriaGralService.registrarAuditoria('SACI_MAETIPOREF', String(this.selectedsegmaeConsultaTipoRef.TIP_CODIGO), 'A',
          '', '01', '', '', '', '').toPromise().then(() => {
          });
        }).catch(e=>{
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        }); 
          
      }
 
    }else{
     
      this.mensajeAlerta('info', 'Información', 'Se han encontrado campos vacíos');
    } */
  }
  validarSaldosNeg(){
    
    let saldias=0;
    let sum=0;
    for(var va=0;va<this.nomempleadosService.nomcrudVaca.length;va++){;
      saldias=parseFloat(this.numberEmplPer(this.nomempleadosService.nomcrudVaca[va].TRANVACAEMPL_DIASPRORR));
      sum=sum+saldias; 
    }
    return sum;
  }
  validarSaldosNegativos(vaca:nomVacaciones){
    let a=parseFloat(vaca.TRANVACAEMPL_DIASPRORR);
    let res:string[]=[]
    if(vaca.TRANVACAEMPL_TIPO=="PROVISION"){
      if(a<=0){
        res.push('El valor de la provisión no puede ser mayor menor o igual a cero');
      }
    }else if(vaca.TRANVACAEMPL_TIPO=="VACG"){
      if(a>=0){
        res.push('El valor de las vacaciones no puede ser mayor o igual a cero');
      }
    }else if(vaca.TRANVACAEMPL_TIPO=="VACP"){
      if(a>=0){
        res.push('El valor de las vacaciones no puede ser mayor o igual a cero');
      }
    }else{
      res.push('Seleccione un tipo de vacación');
    }
    return res;
  }
  validarNulos(vaca:nomVacaciones){
    //TRANVACAEMPL_TIPO,TRANVACAEMPL_PERIODO,TRANVACAEMPL_FECINIPERI,TRANVACAEMPL_FECFINPERI,TRANVACAEMPL_DIASPRORR
    let valor:string[]=[]
    if(isNullOrUndefined(vaca.TRANVACAEMPL_TIPO) ||vaca.TRANVACAEMPL_TIPO==''){
      valor.push("El campo Tipo es obligatorio ");
    }
    if(isNullOrUndefined(vaca.TRANVACAEMPL_PERIODO) ||vaca.TRANVACAEMPL_PERIODO==''){
      valor.push("El campo Periodo es obligatorio ");
    }
    if(isNullOrUndefined(vaca.TRANVACAEMPL_FECINIPERI) ||vaca.TRANVACAEMPL_FECINIPERI==''){
      valor.push("El campo Desde es obligatorio ");
    }
    if(isNullOrUndefined(vaca.TRANVACAEMPL_FECFINPERI) ||vaca.TRANVACAEMPL_FECFINPERI==''){
      valor.push("El campo Hasta es obligatorio ");
    }
    if(isNullOrUndefined(vaca.TRANVACAEMPL_DIASPRORR) ||vaca.TRANVACAEMPL_DIASPRORR==''){
      valor.push("El campo D.Prorrogados es obligatorio ");
    }
    return valor;
  }
opcionEliminar(){
    //let paquete='SACI_PCK_MAETIPREF.SACI_SP_eliminaTipRef';
    if ( this.selectedVacaciones !== undefined ) {
      this.confirmationService.confirm({
        message: '¿Está seguro que desea eliminar este registro?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'nomEmpVac',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {
          this.nomempleadosService.eliminalineadetvacacion(this.selectedVacaciones.TRANVACAEMPL_CODIGO).then(res=>{
            this.mensajeAlerta('success', 'Información', '¡Modulo eliminado!');
            this.cargar();
           /*  this.auditoriaGralService.registrarAuditoria('', String(this.selectedVacaciones.ENCROLINDI_CODIGO), 'E',
            '', '01', '', '', '', '').toPromise().then(() => {
            }); */
          }).catch(e=>{
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
            this.mensajeAlerta('error', 'Error', mensaje);
          }); 
        },
        reject: () => {
        }
      })
    } 
  }
  async cargar() {
    this.btnNuevo =  false;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.btnRegresar = false;
    this.btnBuscar = false;
    let i=0;
    this.nomempleadosService.listavacaciones(this.selNomEmpl.EMP_CODIGO).then((res:any)=>{
      this.nomempleadosService.nomcrudVaca=res;
      this.nomempleadosService.nomcrudVaca.map(x=>{
        x.TRANVACAEMPL_PERIODO=this.numberEmplPer(x.TRANVACAEMPL_PERIODO);
        x.TRANVACAEMPL_DIASPRORR=parseFloat(this.numberEmplPer(x.TRANVACAEMPL_DIASPRORR)).toFixed(4);
        x.TRANVACAEMPL_BASEACUM=parseFloat(this.numberEmplPer(x.TRANVACAEMPL_BASEACUM)).toFixed(4);
        x.TRANVACAEMPL_VALOR=parseFloat(this.numberEmplPer(x.TRANVACAEMPL_VALOR)).toFixed(2);
        x.TRANVACAEMPL_FECHSALIVACA=this.cambiarfechanull(x.TRANVACAEMPL_FECHSALIVACA);
        x.TRANVACAEMPL_FECHREGRVACA=this.cambiarfechanull(x.TRANVACAEMPL_FECHREGRVACA);
        x.TRANVACAEMPL_FECINIPERI=this.cambiarfechanull(x.TRANVACAEMPL_FECINIPERI);
        x.TRANVACAEMPL_FECFINPERI=this.cambiarfechanull(x.TRANVACAEMPL_FECFINPERI);
        x.NUMVAC=i++;
      });
      let sal=0;
      let saldias=0;
      let salp=0;
      let saldiasp=0;
      for(var va=0;va<this.nomempleadosService.nomcrudVaca.length;va++){
        let i=(va==0)?1:va-1;
         saldias=parseFloat(this.numberEmplPer(this.nomempleadosService.nomcrudVaca[va].TRANVACAEMPL_DIASPRORR));
         sal=parseFloat(this.numberEmplPer(this.nomempleadosService.nomcrudVaca[va].TRANVACAEMPL_VALOR));
         
         if(va==0){
          this.nomempleadosService.nomcrudVaca[va].TRANVACAEMPL_SALDODIAS=saldias.toFixed(4);
          this.nomempleadosService.nomcrudVaca[va].TRANVACAEMPL_SALDO=sal.toFixed(4);
         }else{
          saldiasp=parseFloat(this.nomempleadosService.nomcrudVaca[i].TRANVACAEMPL_SALDODIAS);
          salp=parseFloat(this.nomempleadosService.nomcrudVaca[i].TRANVACAEMPL_SALDO);
          this.nomempleadosService.nomcrudVaca[va].TRANVACAEMPL_SALDODIAS=(saldias+saldiasp).toFixed(4);
          this.nomempleadosService.nomcrudVaca[va].TRANVACAEMPL_SALDO=(sal+salp).toFixed(4);
         }
      }
    })
  }
  selecVacaciones(valor){
    if (valor.object !== undefined) {
      this.selectedVacaciones=valor.object;
    }
  }
  cambioVacaciones(event){
    this.btnGuardar = false;
    this.btnRegresar = false;
    this.btnNuevo = true;
  }
  cambioDPro(params){
    
    let a=parseFloat(this.selectedVacaciones.TRANVACAEMPL_DIASPRORR);
    if(this.selectedVacaciones.TRANVACAEMPL_TIPO=="PROVISION"){
      if(a<=0){
        this.mensajeAlerta('info', 'Información', 'El valor de la provisión no puede ser mayor menor o igual a cero');
      }else{
        const indice= this.nomempleadosService.nomcrudVaca.indexOf(this.selectedVacaciones);
        this.aggrid.gridApi.setFocusedCell(indice , 'TRANVACAEMPL_BASEACUM');
      }
    }else if(this.selectedVacaciones.TRANVACAEMPL_TIPO=="VACG"){
      if(a>=0){
        this.mensajeAlerta('info', 'Información', 'El valor de las vacaciones no puede ser mayor o igual a cero');
      }else{
        const indice= this.nomempleadosService.nomcrudVaca.indexOf(this.selectedVacaciones);
        this.aggrid.gridApi.setFocusedCell(indice , 'TRANVACAEMPL_BASEACUM');
      }
    }else if(this.selectedVacaciones.TRANVACAEMPL_TIPO=="VACP"){
      if(a>=0){
        this.mensajeAlerta('info', 'Información', 'El valor de las vacaciones no puede ser mayor o igual a cero');
      }else{
        const indice= this.nomempleadosService.nomcrudVaca.indexOf(this.selectedVacaciones);
        this.aggrid.gridApi.setFocusedCell(indice , 'TRANVACAEMPL_BASEACUM');
      }
    }else{
      this.mensajeAlerta('info', 'Información', 'Seleccione un tipo de vacación');
      const indice= this.nomempleadosService.nomcrudVaca.indexOf(this.selectedVacaciones);
      this.aggrid.gridApi.setFocusedCell(indice , 'TRANVACAEMPL_TIPO');
    }
  }
  async busPeriodoVac(parametro, parametro2){
    let resp="";
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    if (parametro2 == null || parametro2== undefined) {
      parametro2 = '';
    }
   
    this.busquedacampos = [parametro, '', ''];
    this.consulta = ' PERIODO_CODIGO,PERIODO_FECINI,PERIODO_FECFIN';
    this.tabla = ' NOM_MAEPERIODO';
    this.where = " PERIODO_FECINI='"+this.cambiarfechanull(parametro)+"' and PERIODO_FECFIN='"+this.cambiarfechanull(parametro2)+"'";
    return new Promise<any>(async (resolve)=>{
     await this.nomempleadosService.encontrarRegistro1(this.cambiarfechanull(parametro), this.cambiarfechanull(parametro2)).then(eR => {
      if (eR !== null) {
          if (eR[0] !== undefined) {
            resolve(eR[0].PERIODO_CODIGO);
          } else {
            resolve(resp);
          }
        } else {
          resolve(resp);
        }
      });
    });
  }
  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'segMaeOrgtoast',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
    
  }
}
