<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="edittrn"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="edittrnhis" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 98%">
    <div class="p-col-12">
        <app-invmaebarra (signal)="manejarSenales($event)" [botonNuevo]="botonNuevo" [botonGuardar]="botonGuardar"
            [botonBorrar]="botonBorrar" [botonRegresar]="botonRegresar" [barraBotonesDesplaz]="oculto"
            [barraBotones3]="oculto" [barraBotonesAsiCon]="oculto" [barraBotones5]="oculto"></app-invmaebarra>
    </div>
</div>
<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 270px)'}">
    <div class="divgrups">
        <div class="p-grid">
            <!--LINEA 1-->
            <div class="p-col-2">
                <p-checkbox label="Cargar todo el historial" binary="true"
                    (click)="cambiobloc($event)" [(ngModel)]="buscarallfac" [disabled] = "false">
                </p-checkbox>
            </div>
        </div>
    </div>
    <app-aggridsaci [width]="" [height]="largo" [enableSorting]="false" [enableFilter]="true"
                [rowData]="cxctrnedithistService.cxctrnhisto" [frameworkComponents]="frameworkComponents"
                [animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefsCxcTrnediHis"
                [defaultColDef]="defaultCxcTrnedithis" [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="false"
                (selected)="seleccionedithistor($event)" (cambios)="cambio($event)" align="left"
                style="justify-items: end"></app-aggridsaci>
</p-scrollPanel>
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
    <p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
    </p-progressSpinner>
    <!-- <p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
	</p-dialog> -->
</div>