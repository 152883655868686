<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="segCambioClave"></p-toast>

<p-card  header="Cambio de clave" [style] = "{'width':'25rem',
	 'background-color': 'rgb(232, 242, 255)',
	  'margin': 'auto'}">
	<div class="p-grid" style="width: 98%">
		<div class="p-col-12">
			<app-invmaebarra [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"
							 [barraBotones5]="true" [botonVisto]="btnVisto" [barraBotonesCRUD]="true" [barraBotones3Alt]="false"
							 (signal)="manejarSenales($event)"
							 style="text-align: left;">
			</app-invmaebarra>
		</div>
	</div>
	<div class="p-grid">
		<div class="p-col-4">
			<label>Clave actual</label>
		</div>
		<div class="p-col-8">

			<input type="password" pInputText [(ngModel)]="claveActual"
				   (change)="activarVisto($event)"
				   autocomplete="off">
		</div>
		<div class="p-col-4">
			<label>Nueva clave</label>
		</div>
		<div class="p-col-8">

			<input type="password" pInputText [(ngModel)]="nuevaClave"
				   autocomplete="off">
		</div>
		<div class="p-col-4">
			<label>Confirmar clave</label>
		</div>
		<div class="p-col-8">

			<input type="password" pInputText [(ngModel)]="confirmClave"
				   autocomplete="off">
		</div>
	</div>

	<label>Usuario: {{usuario}} - Fecha: {{fecha}} - Hora: {{hora}}</label>


</p-card>
