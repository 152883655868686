import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import { SegMaeNumeraciong } from '../seginterfaces/segmaenumeraciong';
import { DatePipe } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class SegmaenumeraciongService {
  private way: string;
  public segMaeNumeraciong: SegMaeNumeraciong[] = [];
  constructor(private http: HttpClient,
    private datePipe: DatePipe,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario, private confIniciales: ConfInicialesService) { 
      this.way = sessionStorage.getItem('way');
      this.usuario.identificacion;
    }
    consultarNumeracionG(){
      const parametro1 ='num_docref';
      const parametro2= ' com_codigo='+ '01' 
      return this.http.post<any[]>(this.way + '/seg/numeraciong/20eedfd676d4ca0000', {
        elementos: {
          p_NUM_orden:parametro1, // varchar,    
          p_NUM_selecc:parametro2, //  varchar,  
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    insertarNumeracionG(paquete:string,valor: SegMaeNumeraciong ){
     // valor.NUM_FECHACAD=this.datePipe.transform( valor.NUM_FECHACAD, 'dd/MM/yyyy')
      
      return this.http.post<any[]>(this.way + '/seg/numeraciong/95f8edf6feb3b80', {
        elementos: {
          p_COM_codigo_1:'01',
          p_NUM_docref_2:valor["D/R"],
          p_NUM_serie_3:valor.Serie,
          p_NUM_secinicial_4:valor.Inicio,
          p_NUM_secfinal_5:valor.Final,
          p_NUM_secactual_6:valor.Actual,
          p_NUM_autorizacion_7:valor.Autorización,
          p_NUM_fechacad:valor.NUM_FECHACAD,
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }

    actualizarNumeracionG(paquete:string,valor: SegMaeNumeraciong ){
      return this.http.post<any[]>(this.way + '/seg/numeraciong/14cedfd676d4c90000', {
        elementos: {
          p_COM_codigo_1:'01',
          p_NUM_docref_2:valor["D/R"],
          p_NUM_serie_3:valor.Serie,
          p_NUM_secinicial_4:valor.Inicio,
          p_NUM_secfinal_5:valor.Final,
          p_NUM_secactual_6:valor.Actual,
          p_NUM_autorizacion_7:valor.Autorización,
          p_NUM_fechacad:valor.NUM_FECHACAD,
        },
      }, this.confIniciales.httpOptions()).toPromise();
    
    }

    eliminarNumeracionG(paquete:string,valor: SegMaeNumeraciong ){
     
      return this.http.post<any[]>(this.way + '/seg/numeraciong/75656bfaceda980', {
        esquema: this.usuarioEsquema,
        elementoEjecutar:  paquete,
        elementos: {
          p_COM_codigo_1: '01',
          p_NUM_docref_2: valor,                  
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
}
