<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="tom"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="tomConf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>

				 <div class="p-grid" style="width: 98%">
					<div class="p-col-12">
						<app-invmaebarra [botonNuevo]="tomFisService.botonNuevo" [botonGuardar]="tomFisService.botonGuardar" [botonRegresar]="tomFisService.botonRegresar"
										 [botonBorrar]="tomFisService.botonBorrar"
										 (signal)="manejarSenales($event)" [botonAnterior]="tomFisService.botonAnterior" [botonPrimero]="tomFisService.botonPrimero"
										 [botonUltimo]="tomFisService.botonUltimo"
										 [botonSiguiente]="tomFisService.botonSiguiente" [barraBotones3Alt]="false" [barraBotones3]="true"
										 [barraBotonesAsiCon]="false"
										 [botonVisto]="tomFisService.btnVisto"
										 [botonReversar]="tomFisService.btnReversar"
										 [botonBinocular]="true"
										 [botonBuscar]="tomFisService.btnBuscar" [botonAsiento]="tomFisService.btnGenAsiento"
										 [botonverAsiento]="tomFisService.btnVerAsiento"
										 [botonAlmacenardoc] = "true"></app-invmaebarra>
					</div>
				</div>

<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px; width: 99%;">
	<div class="p-col-5"></div>
	<div class="p-col-1">
		<span>{{tomFisService.encTomaFisica[indicador].ANULADO}}</span>
	</div>
	<div class="p-col-4"></div>
	<div class="p-col-2" style="margin-bottom: 5px;">
		<input  type="text" pInputText style="background-color: #EFFFF7"
				[disabled]="true"
				[(ngModel)]="tomFisService.encTomaFisica[indicador].ASI_NRO"
				autocomplete="off">
	</div>
</div>
<p-tabView [(activeIndex)]="activeIndex" (activeIndexChange)="cambioTab()">
	<p-tabPanel header="Toma Física" [disabled]="disabledTab0">
		<div class="divgrups" id="divPrincipal">
			<div class="p-grid">
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Número. Doc</span>
						</div>
						<div class="p-col-2">
							<input pInputText type="text" size="14"
								   (input)="cambio()" [disabled]="true"
								   [(ngModel)]="tomFisService.encTomaFisica[indicador].TRNTOMAFISICA_NRO" autocomplete="off">
						</div>
						<div class="p-col-9">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-4">
									<label for="fecha">Fecha:</label>
									<p-calendar id="fecha" [inputStyle]="{width: '100%', height: '20px'}"
												[(ngModel)]="tomFisService.encTomaFisica[indicador].TRNTOMAFISICA_FECHA"
												(ngModelChange)="cambio()" dateFormat="dd/mm/yy"
												[disabled]="disabledDateTime" [readonlyInput]="true"></p-calendar>
								</div>
								<div class="p-col-1">
									<span>dd/MM/YYYY</span>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-3">
									<label for="hora">Hora:</label>
									<p-calendar id="hora" [timeOnly]="true"
												[inputStyle]="{width: '120px', height: '20px'}"
												[(ngModel)]="tomFisService.encTomaFisica[indicador].TRNTOMAFISICA_HORA"
												(ngModelChange)="cambio()"
												[disabled]="disabledTime" [readonlyInput]="true"></p-calendar>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px;">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Comentario:</span>
						</div>
						<div class="p-col-11">
							<input pInputText type="text" style="width: 100%" id="comentario"
								   (keydown.enter)="nuevaToma()"
								   (input)="cambio()"
								   [(ngModel)]="tomFisService.encTomaFisica[indicador].TRNTOMAFISICA_COMENTARIO"
								   autocomplete="off">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="divPrincipal2">
			<app-aggridsaci [width]="1200"
							[height]="largo"
							[enableSorting]="false"
							[enableFilter]="true"
							[rowData]="tomFisService.detTomaFisicaArray[indicador]"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsTomaF"
							[defaultColDef]="defaultColDefTomF"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="true"
							[mostrarGuardar]="false"
							[botonNuevo]="tomFisService.btnNuevoDet[indicador]"
							[botonBorrar]="tomFisService.btnEliminarDet[indicador]"
							[botonRegresar]="tomFisService.btnCancelarDet[indicador]"
							[botonesAdicionales]="true"
							[btnCopiarCostos]="false"
							[btnRecalcularCosto]="false"
							[btnAgitems]="false"
							[btnActCantKardex]="false"
							(cambios)="cambio()"
							(selected)="seleccionToma($event)"

			></app-aggridsaci>
		</div>
	</p-tabPanel>

	<p-tabPanel header="Varios" [disabled]="disabledTab1">
		<div id="divPrincipal3">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="false"
							[enableFilter]="true"
							[rowData]="tomFisService.detTomFisVarArray[indicador]"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsTomaFVar"
							[defaultColDef]="defaultColDefTomFVar"
							[rowStyle]="rowStyleVar"
							[singleClickEdit]="false"
							[botones]="true"
							[botonNuevo]="tomFisService.btnNuevoDetVar[indicador]"
							[botonBorrar]="tomFisService.btnEliminarDetVar[indicador]"
							[botonRegresar]="tomFisService.btnCancelarDetVar[indicador]"
							(cambios)="cambio()"
							[mostrarGuardar]="false"
							(selected)="seleccionTomaVar($event)"

			></app-aggridsaci>
		</div>
	</p-tabPanel>
</p-tabView>
<div class="divgrupstabla">
	<div class="p-grid">
		<div class="p-col-12" style="margin-bottom: -15px">
			<div class="p-grid">
				<div class="p-col-2">
					<div class="p-grid">
						<div class="p-col-4">
							<div class="cuadrado" style="width: 80px; height: 25px;background: #ffff99;"></div>
						</div>
						<div class="p-col-8">
							<span class="obligatorio">Ajuste Generado</span>
						</div>
					</div>
				</div>
				<div class="p-col-4">
					<div class="p-grid">
						<div class="p-col-6 text-right">
							<span class="obligatorio">Costo Total Ajuste:</span>
						</div>
						<div class="p-col-6 text-left">
							<input type="text" class="totales" disabled
								   [(ngModel)]="tomFisService.encTomaFisica[indicador].VALOR_TOTALAJU" autocomplete="off">
						</div>
					</div>
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-4">
					<div class="p-grid">
						<div class="p-col-6 text-right">
									<span class="obligatorio">Costo Total Toma
										Física:</span>
						</div>
						<div class="p-col-6 text-left">
							<input type="text" class="totales" disabled
								   [(ngModel)]="tomFisService.encTomaFisica[indicador].VALOR_TOTAL" autocomplete="off">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Seleccione la opción" [(visible)]="displayDialogBus"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width:'70vw', height:'70vh'}">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloBusq"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>

<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width:'90vw', height:'120vh'}"
		  [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="asiento" [abiertoDesde]="'invtomafisicat'"
					(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>
<!-- CDPJ -->
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
</div>
<!-- CDPJ -->

<p-dialog header="Clave de Autorización" [(visible)]="claveDialog"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '40vw', height: 'auto'} ">
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							   (keydown.enter)="comprobarClave()"
							   placeholder="Ingrese la clave" autocomplete="off">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>

</p-dialog>

<p-dialog header="Reportes" [(visible)]="displayDialogReportes"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '40vw', height: '400px'}">
	<app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
						 [strNumDoc]="tomFisService.encTomaFisica[indicador].TRNTOMAFISICA_NRO"
						 [strCampo]="'TRNTOMAFISICA_NRO'" (cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>
<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'INVTOMAFISICA'" [txtNumComprobante] = "tomFisService.encTomaFisica[indicador].TRNTOMAFISICA_NRO"
	(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>

