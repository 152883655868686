import { Component, EventEmitter,Input, Output, OnInit, ViewChild } from '@angular/core';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { InvtrnreservasService } from 'src/app/inv/invservicios/invtrnreservas.service';
import { ConsultaLotes, ConsultaLotesDesp, Consultaencpedido} from '../veninterfaces/consultalotes';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { ConfirmationService , SelectItem, MessageService } from 'primeng';
import { InvconsultalotesService } from '../venservicios/invconsultalotes.service';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { DatePipe } from '@angular/common';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { VenencpedService } from '../venservicios/venencped.service';
import {finalize} from 'rxjs/operators';//GSRF

@Component({
  selector: 'app-venconsulped',
  templateUrl: './venconsulped.component.html',
  styleUrls: ['./venconsulped.component.css']
})
export class VenconsulpedComponent implements OnInit {

  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  @Input() strNumFact?;
  @Output() cerrar = new EventEmitter<boolean>();
  
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  botonBuscar: boolean;
  btnAnular: boolean;

  txtArtCodigo = '';
  txtArtNombre = '';

  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  defaultColDefDetLotes;
  defaultColDefDetDespacho;
  largo: string;
  indicador: any;
  
  detalleencped: Consultaencpedido[] = [];
  detalleDespacho: ConsultaLotesDesp[] = [];
  detalleSeleccionado: Consultaencpedido = {};

  displayDialogBusquedaFast: boolean;
  displayDialogBusquedaFast1: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  condicion: string;
  opcion: string;
  where = '';

  lblArtNombre = '';
  lblNroLote = '';
  selectTab = 0;

  cmbFiltroLote: any[];
  strFiltroLote: any;

  txtValorFiltro = '';

  cmbFiltroDesp: any[];
  strFiltroDesp: any;
  txtValorFiltroDesp = '';
  txtFechaDesp;
  
  claveDialog: boolean;
  claveAuth: string;
  color1: string;
  color2: string;
  serie1: string;
  serie2: string;
  nserie1: number;
  nserie2: number;

  columnDefsDetLotes = [
    { // 3
      headerName: 'Fecha', field: 'ENCPED_FECHAEMISION', width: 100 , editable: false
    },
    { // 3
      headerName: 'Núnero', field: 'ENCPED_NUMERO', width: 130 , editable: false
    },
    { // 3
      headerName: 'Comprobante', field: 'ENCPED_REFERENCIA', width: 150 , editable: false
    },
    { // 3
      headerName: 'Id. Cliente', field: 'CLI_CODIGO', width: 100, editable: false,
    },
    { // 3
      headerName: 'Nom. cliente', field: 'CLI_NOMBRE', width: 130, editable: false,
    },
    { // 3
      headerName: 'Id. Vendedor', field: 'VEN_CODIGO', width: 80, editable: false,
    },
    { // 3
      headerName: 'Vendedor', field: 'VEN_NOMBRE', width: 130,  editable: false,
    },
    { // 3
      headerName: 'Bodega', field: 'BOD_CODIGO', width: 100, editable: false,
    },
    {
      headerName: 'F. Entrega', field: 'ENCPED_FECHAENTREGA', width: 100, editable: false,
    },
    { // 3
      headerName: 'F. Validez', field: 'ENCPED_FECHAVALIDEZ', width: 100, editable: false, 
    },
    { // 3
      headerName: 'Total Pedido', field: 'ENCPED_TOTAL', width: 80, editable: false, cellStyle: {textAlign: 'right'}
    }
  ];

  constructor(public encpedService: VenencpedService,  
    private busquedaSer: InvbusquedaService, private message: MessageService,
    private datePipe: DatePipe, private init: NuevoComponentService, private confirmationService: ConfirmationService) {
    this.detalleencped = [];
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
     
    this.rowStyle = (params) => {
      //console.log(params.data.ENCPED_REFERENCIA)
      if(params.data.ENCPED_REFERENCIA !== null && params.data.ENCPED_REFERENCIA !== undefined && params.data.ENCPED_REFERENCIA !== ''){
        if(params.data.ENCPED_REFERENCIA.substring(0,this.nserie1) === this.serie1 ){
          //console.log('aqui1 ',params.data.ENCPED_REFERENCIA.substring(0,this.nserie1) ,'--------', this.serie1 )
          return {background: this.color1};
        }
        if(params.data.ENCPED_REFERENCIA.substring(0,this.nserie2) === this.serie2){
         // console.log('aqui2 ',params.data.ENCPED_REFERENCIA.substring(0,this.nserie2) ,'--------', this.serie2 )
          return  {background: this.color2};
        }
      }
      // if (params.node.rowIndex % 2 !== 0) {
      //   return {background: '#D6EAF8'};
      // }
    };
    this.defaultColDefDetLotes = {
      editable: false,
      width: 80,
      objeto: 'invconslotes'
    };

    this.defaultColDefDetDespacho = {
      editable: false,
      width: 80,
      objeto: 'invconsdespacho'
    };
  }

  ngOnInit(): void {
    this.encpedService.refrescarURL();
    this.colorearserie();
    this.botonGuardar = true;
    this.btnAnular = true;
    this.listarpedido();
    this.color1 = '#A6D1F0';
    this.color2 = '#F5f2BE';
    this.nserie1 = 0;
    this.nserie2 = 0;
    this.serie1 = '';
    this.serie2 = '';
}

  async manejarSenales(valor) {
    if (valor === 'Salir') {
      if (this.strNumFact === undefined) {
        this.init.tabs.splice(this.init.tabindex, 1);
      } else {
        this.cerrar.emit(true);
      }
    }
    if (valor === 'Cancelar') {
      this.listarpedido();
    }
    if (valor === 'Anular') {
      this.confirmarAnular();
    }

  }

  selecciondetalle(params) {
    if (params.object === undefined) {
      return;
    }
    this.btnAnular = false;
    this.detalleSeleccionado = params.object;
    //console.log('---->',this.detalleSeleccionado)
  }

  async listarpedido() {
    this.detalleencped = [];
    
    try {
      this.detalleencped =  await this.encpedService.consultarpedpendiente();
      //console.log('---',this.detalleencped)
     // console.log('---',this.detalleencped.length)
      if ( this.detalleencped !== null) {
        this.detalleencped.map(item => {
          item.ENCPED_FECHAEMISION = this.datePipe.transform(item.ENCPED_FECHAEMISION,
            'dd/MM/yyyy');
          item.ENCPED_FECHAENTREGA = this.datePipe.transform(item.ENCPED_FECHAENTREGA,
              'dd/MM/yyyy');
          item.ENCPED_FECHAVALIDEZ = this.datePipe.transform(item.ENCPED_FECHAVALIDEZ,
              'dd/MM/yyyy');    
          item.ENCPED_TOTAL = Number(item.ENCPED_TOTAL).toFixed(2);
        });
        this.detalleencped =  this.detalleencped;
        
      } else {
        this.mensajeAlerta('info', 'Información', 'No existe información de pedidos');
      }
    } catch(err) {
      this.mensajeAlerta('error', 'Error', err.error);
    }

  }

  async colorearserie() {
     this.encpedService.obtenerserie().subscribe((serie) => {
      this.serie1=(serie[0].NUM_NEMONICO === null||serie[0].NUM_NEMONICO === undefined? '':serie[0].NUM_NEMONICO)+(serie[0].NUM_SERIE === null ||serie[0].NUM_SERIE === undefined?'':serie[0].NUM_SERIE);
      this.serie2=(serie[1].NUM_NEMONICO === null||serie[1].NUM_NEMONICO === undefined? '':serie[1].NUM_NEMONICO)+(serie[1].NUM_SERIE === null ||serie[1].NUM_SERIE === undefined?'':serie[1].NUM_SERIE);
      this.nserie1=this.serie1.length
      this.nserie2=this.serie2.length
      console.log('1-->',this.serie1);
      console.log('2-->',this.serie2);
      console.log('n1-->',this.nserie1);
      console.log('n2-->',this.nserie2);
    })
   
  }
  // colorearserie2(precio) {
  //   // console.log(this.serie1 +'--------'+ precio.ENCPED_REFERENCIA.substring(0,this.serie1.length));
  //   // console.log(this.serie2  +'---------'+precio.ENCPED_REFERENCIA.substring(0,this.serie2.length));
  //   if( precio.ENCPED_REFERENCIA.substring(0,this.serie1.length) === this.serie1 ){
  //     console.log('aqui1')
  //     return this.color1;
  //   }if(precio.ENCPED_REFERENCIA.substring(0,this.serie2.length) === this.serie2){
  //     console.log('aqui2')
  //     return this.color2;
  //   }
  //   // console.log('------aqui',precio.ENCPED_REFERENCIA)
  //   //     return this.color1;
  // }
  confirmarAnular() {
    this.confirmationService.confirm({
      message: 'Realmente desea anular el pedido seleccionado',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'venconsulpedconf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.encpedService.getDetPedidos(this.detalleSeleccionado.ENCPED_NUMERO).subscribe((ped) => {
          if (ped !== undefined) {
            let numDesp = 0;
            for (let fila = 0; fila < ped.length; fila++) {
              const item = ped[fila];
              if (Number(item.DETPED_DESPACHO) > 0) {
                numDesp++;
              }
      
            }
            
            //console.log(numDesp);
              if (numDesp > 0) {
                return this.message.add({
                  key: 'venconsulped',
                  severity: 'error',
                  summary: 'Error',
                  detail: 'No es posible anular el pedido debido a que el mismo ya tiene items despachados'
                });
              }
          }
          this.claveDialog = true;
        });
        
      },
      reject: () => {
        this.confirmationService.close();
        this.btnAnular = true;
      }
    });
  }
  comprobarClave() {
        this.encpedService.obtenerClaveanula().subscribe((clave) => {
          let claveReal;
          claveReal = clave[0].AUTORIZACION_CLAVE;
          if (claveReal === this.claveAuth) {
            this.claveDialog = false;
            this.anularPedido();
          }else{
            this.claveDialog = false;
            return this.message.add({
              key: 'venconsulped',
              severity: 'error',
              summary: 'Clave',
              detail: 'Clave Incorrecta'
            });
            this.btnAnular = true;
          }
        });
    
  }
  anularPedido() {
    
      this.encpedService.anularPedido(this.detalleSeleccionado).pipe(finalize(() => this.listarpedido())).subscribe(() => {
        console.log('entra');
        this.message.add({
          key: 'venconsulped',
          severity: 'success',
          summary: 'Anular Pedido',
          detail: 'El pedido fue anulado'
        });
      });
  
    this.claveAuth = '';
    this.btnAnular = true;
    
  }


  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'invconsulotes',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

  onTab1Change(event) {
    this.selectTab = event.index;

    if (this.selectTab === 0 ) {
      this.strFiltroDesp = this.cmbFiltroDesp[0];
    } 

    if (this.selectTab === 1 ) {
      this.txtValorFiltro = '';
      if (Object.keys(this.detalleSeleccionado).length > 0) {
        this.consultarDespacho('I')
      }
    } 

  }

  async consultarDespacho(tipo) {
    this.detalleDespacho = [];
    let rs;
    
    try {
      
      console.log(rs);

      if (rs !== null) {
        rs.map(item => {
          item.FECHA_EMISION = this.datePipe.transform(item.FECHA_EMISION,
            'dd/MM/yyyy');
          item.TRNLOTE_CANTIDAD = Number(item.TRNLOTE_CANTIDAD).toFixed(2);
        });
        this.detalleDespacho = rs;
      } else {
        this.mensajeAlerta('info', 'Información', 'No existe información de despachos');
      }
    
    } catch(err) {
      this.mensajeAlerta('error', 'Error', err.error);
    }

  }
  


}
