import { Component, OnInit } from '@angular/core';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { MessageService } from 'primeng';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import { InvrecalcostopmdServiceService } from '../invservicios/invrecalcostopmdService.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-invrecalcularcostopromprod',
  templateUrl: './invrecalcularcostopromprod.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class InvrecalcularcostopromprodComponent implements OnInit {

  fechainicial = new Date();
  fechafinal = new Date();
  horainicial = new Date();
  horafinal = new Date();
  spin = false;

  constructor(public invrecalcostopmdService: InvrecalcostopmdServiceService, private init: NuevoComponentService,
    private messageService: MessageService, private utilitariosService: UtilitariosService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
  }

  async manejarSenales(valor) {

    if (valor === 'Salir') {
      this.salir();
    }
  }

  salir() {
    this.init.tabs.splice(this.init.tabindex, 1);
  }

  async procesar() {
    const dia = this.fechainicial.getDate();
    const mes = this.fechainicial.getMonth();
    const anio = this.fechainicial.getFullYear();

    if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
      this.messageService.add({
        key: 'recalcstpromprod',
        severity: 'info',
        summary: 'Información',
        detail: 'Error, fecha debe de estar dentro del período contable'
      });
      return;
    }

    const strFechaInicial = this.datePipe.transform(this.fechainicial, 'dd/MM/yyyy');
    const strFechaFinal = this.datePipe.transform(this.fechafinal, 'dd/MM/yyyy');
    const strHoraIni = this.datePipe.transform(this.horainicial, 'HH:mm');
    const strHoraFin = this.datePipe.transform(this.horafinal, 'HH:mm');
    this.spin = true;
    await this.invrecalcostopmdService.recalcularCostoPromedioProd(strFechaInicial, strFechaFinal, strHoraIni, strHoraFin);
    this.spin = false;
    this.messageService.add({
      key: 'recalcstpromprod',
      severity: 'success',
      summary: 'Información',
      detail: 'Se terminó de procesar correctamente'
    });

  }

}