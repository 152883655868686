import {Component, OnInit, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {Invnumtransferencia} from '../invinterfaces/invnumtransferencia';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {InvnumtransferenciaService} from '../invservicios/invnumtransferencia.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Component({
  selector: 'app-invnumtransferencia',
  templateUrl: './invnumtransferencia.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class InvnumtransferenciaComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  actualizar: boolean;
  codigoAux: any;
  nuevoCampo: boolean;
  largo: string;
  numTransSeleccionado: Invnumtransferencia;
  public frameworkComponents;
  rowStyle;
  defaultColDefNumTrans;
  rowSelection = 'multiple';
  columnDefsNumTrans = [
    {
      headerName: 'Código', field: 'NUMTRA_SIGLA', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
             tamanocelda: 15
          }
        }
    },
    {
      headerName: 'Descripción', field: 'NUMTRA_DESCRIPCION', cellEditor: 'cellPrueba', width: 250,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 200
          }
        }
    },
    {
      headerName: 'Cliente', field: 'NUMTRA_CLIENTE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 1
          }
        }
    },
  ];
  constructor(public servicioNumTransfer: InvnumtransferenciaService , private message: MessageService,
              private errorService: ErroresBaseDatosService, private confirmationService: ConfirmationService,
              private init: NuevoComponentService) {
    this.agTable();
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefNumTrans = {
      editable: true,
      width: 100,
      objeto: 'maenumtrans'
    };
  }

  ngOnInit() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.actualizar = false;
    this.nuevoCampo = false;
    this.codigoAux = 0;
    this.servicioNumTransfer.getNumTrans().subscribe((res) => {
      this.servicioNumTransfer.commaenumtrans = res;
    });
    this.largo = '258';
  }
  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.btnRegresar = false;
      this.nuevoCampo = true;
      this.numTransSeleccionado = {
        COM_CODIGO: '01',
      NUMTRA_SIGLA: '',
      NUMTRA_DESCRIPCION: '',
      NUMTRA_CLIENTE: '',
      };

      this.servicioNumTransfer.commaenumtrans.push(this.numTransSeleccionado);
      this.aggrid.refreshaggrid(this.servicioNumTransfer.commaenumtrans, this.defaultColDefNumTrans.objeto);
    }
    if (valor === 'Guardar') {
      this.btnGuardar = true;
      this.btnNuevo = false;
      this.btnBorrar = false;
      this.btnRegresar = true;
      if (this.actualizar === true) {
        this.servicioNumTransfer.actualizarNumTrans(this.numTransSeleccionado, this.codigoAux).subscribe(() => {
          this.message.add({
            key: 'maenumtrans',
            severity: 'success',
            summary: 'Actualización exitosa',
            detail: 'Se actualizó el numero de transferencia correctamente'
          });
        }, error1 => {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
          this.message.add({
            key: 'maenumtrans',
            severity: 'error',
            summary: 'Actualización del número de transferencia',
            detail: mensaje
          });
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.btnRegresar = true;
          this.servicioNumTransfer.getNumTrans().subscribe((res) => {
            this.servicioNumTransfer.commaenumtrans = res;
            this.aggrid.refreshaggrid(this.servicioNumTransfer.commaenumtrans, this.defaultColDefNumTrans.objeto);
          });
        });
      } else if (this.actualizar === false) {
        this.servicioNumTransfer.insertarNumTrans(this.numTransSeleccionado).subscribe(() => {
          this.message.add({
            key: 'maenumtrans',
            severity: 'success',
            summary: 'Inserción exitosa',
            detail: 'Se insertó el número de tranferencia correctamente'
          });
          this.nuevoCampo = false;
        }, error1 => {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
          this.message.add({
            key: 'maenumtrans',
            severity: 'error',
            summary: 'Inserción del número de transferencia',
            detail: mensaje
          });
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.btnRegresar = true;
          this.servicioNumTransfer.getNumTrans().subscribe((res) => {
            this.servicioNumTransfer.commaenumtrans = res;
            this.aggrid.refreshaggrid(this.servicioNumTransfer.commaenumtrans, this.defaultColDefNumTrans.objeto);
          });
        });
      }
    }
    if (valor === 'Borrar') {
      this.confirmarTrans();
    }

    if (valor === 'Cancelar') {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.servicioNumTransfer.getNumTrans().subscribe((res) => {
        this.servicioNumTransfer.commaenumtrans = res;
        this.aggrid.refreshaggrid(this.servicioNumTransfer.commaenumtrans, this.defaultColDefNumTrans.objeto);
      });
    }
  
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  
  
  }

  selectedNumTrans(valor) {
    if (valor.object !== undefined) {
      this.numTransSeleccionado = valor.object;
      if (this.nuevoCampo === true) {
        this.actualizar = false;
      } else if (this.nuevoCampo === false) {
        this.actualizar = true;
        if (this.numTransSeleccionado !== undefined) {
          this.codigoAux = this.numTransSeleccionado.NUMTRA_SIGLA;
        }
      }
    }
  }

  confirmarTrans() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el número de transferencia',
      header: 'Eliminar número de tranferencia',
      icon: 'pi pi-exclamation-triangle',
      key: 'MaeNumTrans',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarTrans();
      },
      reject: () => {
      }
    });
  }

  eliminarTrans() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.servicioNumTransfer.eliminarNumTrans(this.numTransSeleccionado).subscribe(() => {
      this.message.add({
        key: 'maenumtrans',
        severity: 'success',
        summary: 'Eliminación exitosa',
        detail: 'Se eliminó el número de transferencia correctamente'
      });
      this.servicioNumTransfer.commaenumtrans = this.servicioNumTransfer.commaenumtrans.filter((val, j) => j !==
        this.servicioNumTransfer.commaenumtrans.indexOf(this.numTransSeleccionado));
      this.aggrid.refreshaggrid(this.servicioNumTransfer.commaenumtrans, this.defaultColDefNumTrans.objeto);
    }, error1 => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.message.add({
        key: 'maenumtrans',
        severity: 'error',
        summary: 'Inserción de el cargo',
        detail: mensaje
      });
    });
  }
  cambio(cambio) {
    this.btnGuardar = false;
    this.btnRegresar = false;
  }
}

