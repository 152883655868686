<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="maebancaja"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="MaeBanCaja" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>

<div class="p-grid" style="width: 99%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonBorrar]="btnBorrar"
						 [botonRegresar]="btnRegresar" [barraBotonesCRUD]="false"
						 [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"  [botonBuscar]="false" [botonBinocular]="true"
						 (signal)="manejarSenales($event)"></app-invmaebarra>
	</div>
</div>

<div style="overflow: hidden" class="p-col-12">
	<p-tabView (onChange)="onTab1Change($event)" [(activeIndex)]="indexTab">
		<p-tabPanel header="Caja">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="false"
							[enableFilter]="true"
							[rowData]="BancajaServices.banmaecaja"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsBanCaja"
							[defaultColDef]="defaultColDefBanCaja"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)="selectedBanCaja($event)"
							(cambios)="cambio($event)"

			></app-aggridsaci>
		</p-tabPanel>
		<p-tabPanel header="Estado Caja">
			<app-aggridsaci [width]=""
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="BancajaServices.bantrnmaecaja"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefsBanTrnCaja"
							[defaultColDef]="defaultColDefBanTrnCaja"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"

			></app-aggridsaci>
		</p-tabPanel>
	</p-tabView>
</div>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '43vh'} ">
	<app-busquedadlgmae [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
						[where]="condicion"
						[busquedaParams]="busquedacampos"
						(opcionSeleccionada)="manejarSeleccion($event)"
						[busquedaCompuesta]="false"></app-busquedadlgmae>
</p-dialog>
