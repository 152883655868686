import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import * as JsBarcode from 'jsbarcode/bin/JsBarcode';
import { EleTrnDocs } from '../descargasInterfaces/eletrndocumentos';
import { Observable } from 'rxjs';
import { Imagenes } from '../descargasInterfaces/imagenes';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class EletrndocumentosService {
  get eletrndocs(): EleTrnDocs[] {
    return this._eletrndocs;
  }

  set eletrndocs(value: EleTrnDocs[]) {
    this._eletrndocs = value;
  }

  public url: any;
  public way: any;
  private _eletrndocs: EleTrnDocs[];

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }

  getDocumentos(cliente): Observable<any[]> {
    console.log('getDocumentos');
    return this.http.post<any[]>(this.way + '/descargas/10775b867acebf000', {
      elementos: {
        COD_CLI: cliente.CLI_RUCIDE
      }
    });
  }

  actualizarPass(cliente, newpass): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/descargas/299df2ab2', {
      elementos: {
        COD_CLI: cliente.CLI_RUCIDE,
        new_pass: newpass
      }
    });
  }

  getPass(cliente): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/descargas/41ddcab9c', {
      elementos: {
        COD_CLI: cliente.CLI_RUCIDE
      }
    });
  }

  consultaComprobantes(CLI_RUCIDE, clave, numdoc, proveedor, fecha, fechah, tipodoc): Observable<any[]> {
    console.log('consultaComprobantes');
    return this.http.post<any[]>(this.way + '/descargas/cc5f9eaf55b660000000000000', {
      elementos: { CLI_RUCIDE, clave, numdoc, proveedor, fecha, fechah, tipodoc }
    });
  }

  obtenerDataXML(cod): Observable<any> {
    console.log(cod);
    return this.http.post(this.url + '/readfile', { codigo: cod });
  }

  obtenerXML(cod): Observable<any> {
    return this.http.post(this.url + '/readxml', { codigo: cod }, { responseType: 'blob' });
  }

  obtenerLogotipo(ruc): Observable<any>  {
    let logo: any;
    return  this.http.post<any[]>(this.way + '/descargas/41ddae218ecb38', {
      elementos: { PRO_CODIGO: ruc }
    });
    //console.log(data[0])
    //logo=data;
    // const fileReader = new FileReader();
    // fileReader.readAsDataURL(logo);
    // for (const rs of data) {
    //   logo = rs.PRO_LOGO;
    // }
    // if (logo === undefined || logo === null || logo === '') {
    //   logo = Imagenes.logo;
    // }
    return logo;
  }

  public async armarPDFFactura(data,TRNDOC_PRO_RUCIDE) {

    const infoTributaria = data.factura.infoTributaria[0];
    const infoFactura = data.factura.infoFactura[0];
    const facturaDetalles = data.factura.detalles[0].detalle;
    const infoAdicional = data.factura.infoAdicional[0].campoAdicional;

    const logotipo=await this.obtenerLogotipo(TRNDOC_PRO_RUCIDE).toPromise();

    let ambiente = 'Producción';
    if (infoTributaria.ambiente[0] === '1') {
      ambiente = 'Pruebas';
    }
    // console.log(infoTributaria);
    const dd: any = {
      content: [{
        alignment: 'justify',
        columns: [
          [
            {
              image: logotipo,
              width: 200,
              alignment: 'center',
            }, { text: '\n\n\n\n\n\n' },
            {
              style: 'tableExample',
              table: {
                widths: ['auto'],
                body: [
                  [{
                    border: [true, true, true, false],
                    text: infoTributaria.razonSocial[0], style: 'tableTitulo', alignment: 'center'
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'Dirección Matríz: ',
                        style: 'tablenegrita'
                      }, {
                        text: infoTributaria.dirMatriz[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'Dirección Sucursal: ',
                        style: 'tablenegrita'
                      }, {
                        text: this.existeDireccion(infoFactura)
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'Contribuyente Especial Nro: ',
                        style: 'tablenegrita'
                      }, {
                        text: this.existeContribucion(infoFactura)
                      }],
                  }],
                  [{
                    border: [true, false, true, true],
                    text: [
                      {
                        text: 'Obligado a llelet Contabilidad: ',
                        style: 'tablenegrita'
                      }, {
                        text: infoFactura.obligadoContabilidad[0]
                      }],
                  }]
                ]
              }
            }

          ],
          [
            {
              style: 'tableExample',
              table: {
                widths: ['auto'],
                body: [
                  [{
                    border: [true, true, true, false],
                    text: [
                      {
                        text: 'R.U.C: ',
                      }, {
                        text: infoTributaria.ruc[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    fillColor: '#000000',
                    text: [
                      {
                        text: 'FACTURA ',
                        style: 'tablaFactura',

                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'No. ',
                        style: 'tablenegrita'
                      }, {
                        text: infoTributaria.estab[0] + '-' + infoTributaria.ptoEmi[0] + '-' + infoTributaria.secuencial[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'NÚMERO DE AUTORIZACIÓN: ',
                        alignment: 'left',
                      }, {
                        text: infoTributaria.claveAcceso[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'FECHA Y HORA DE AUTORIZACIÓN: ',
                      }, {
                        text: infoFactura.fechaEmision[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'AMBIENTE: ',
                      }, {
                        text: ambiente
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'EMISIÓN: ',
                      }, {
                        text: 'Normal'
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'CLAVE DE ACCESO: ',
                        alignment: 'center',
                      }],
                  }],
                  [{
                    border: [true, false, true, true],
                    image: this.obtenerCodigoBarras(infoTributaria.claveAcceso[0]),
                    width: 250
                  }
                  ]
                ]
              }
            }
          ]
        ]
      },
      {
        style: 'tableExample',
        table: {
          widths: ['auto', '*', 'auto', 'auto'],
          body: [
            [
              {
                border: [true, true, false, false],
                text: 'Razón Social / Nombres y Apellidos: ',
                style: 'tablenegrita'
              },
              {
                border: [false, true, false, false],
                text: infoFactura.razonSocialComprador[0]
              },
              {
                border: [false, true, false, false],
                text: 'Identificación: ',
                style: 'tablenegrita'
              },
              {
                border: [false, true, true, false],
                text: infoFactura.identificacionComprador[0]
              }
            ],
            [
              {
                border: [true, false, false, true],
                text: 'Fecha Emisión:: ',
                style: 'tablenegrita'
              },
              {
                border: [false, false, false, true],
                text: infoFactura.fechaEmision[0]
              },
              {
                border: [false, false, false, true],
                text: 'Guía Remisión: ',
                style: 'tablenegrita'
              },
              {
                border: [false, false, true, true],
                text: this.existeGuiadeRemision(infoFactura)
              }
            ]
          ]
        }
      }, {
        style: 'tableDetalle',
        table: {
          widths: ['auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto'],
          body: [
            [
              {
                text: 'Cod. Principal',
                style: 'tablenegrita'
              },
              {
                text: 'Cod. Auxiliar',
                style: 'tablenegrita'
              },
              {
                text: 'Cant',
                style: 'tablenegrita'
              },
              {
                text: 'Descripción',
                style: 'tablenegrita'
              },
              {
                text: 'Precio Unitario',
                style: 'tablenegrita'
              },
              {
                text: 'Descuento',
                style: 'tablenegrita'
              },
              {
                text: 'Precio Total',
                style: 'tablenegrita'
              }
            ]
          ]
        }
      }, {
        columns: [
          [
            {
              style: 'tableExample',
              table: {
                widths: [240],
                body: [[{
                  border: [true, true, true, false],
                  text: 'INFORMACIÓN ADICIONAL',
                  style: 'tablenegrita',
                  alignment: 'center'
                }]]
              }
            }, {
              style: 'tableExample',
              table: {
                widths: ['*', 'auto'],
                body: [[{
                  text: 'FORMA DE PAGO',
                  style: 'tablenegrita',
                  alignment: 'center'
                }, {
                  text: 'VALOR',
                  style: 'tablenegrita',
                  alignment: 'center'
                }
                ]]
              }
            }
          ], [
            {
              style: 'tableExample',
              table: {
                widths: ['*', 'auto'],
                body: [
                  [{
                    text: 'SUBTOTAL 12%',
                    style: 'tablenegrita'
                  }, {
                    text: this.subtotalDoce(infoFactura.totalConImpuestos[0]).sub,
                    alignment: 'right'
                  }],
                  [{
                    text: 'SUBTOTAL 0%',
                    style: 'tablenegrita'
                  }, {
                    text: this.impuestos(infoFactura.totalConImpuestos[0], '2', '0').sub,
                    alignment: 'right'
                  }],
                  [{
                    text: 'SUBTOTAL no objeto de IVA',
                    style: 'tablenegrita'
                  }, {
                    text: this.impuestos(infoFactura.totalConImpuestos[0], '2', '6').sub,
                    alignment: 'right'
                  }],
                  [{
                    text: 'SUBTOTAL Exento de IVA',
                    style: 'tablenegrita'
                  }, {
                    text: this.impuestos(infoFactura.totalConImpuestos[0], '2', '7').sub,
                    alignment: 'right'
                  }],
                  [{
                    text: 'SUBTOTAL SIN IMPUESTOS',
                    style: 'tablenegrita'
                  }, {
                    text: infoFactura.totalSinImpuestos[0],
                    alignment: 'right'
                  }],
                  [{
                    text: 'DESCUENTO',
                    style: 'tablenegrita'
                  }, {
                    text: infoFactura.totalDescuento[0],
                    alignment: 'right'
                  }],
                  [{
                    text: 'ICE',
                    style: 'tablenegrita'
                  }, {
                    text: this.impuestos(infoFactura.totalConImpuestos[0], '3', '7').iva,
                    alignment: 'right'
                  }],
                  [{
                    text: 'IVA 12%',
                    style: 'tablenegrita'
                  }, {
                    text: this.subtotalDoce(infoFactura.totalConImpuestos[0]).iva,
                    alignment: 'right'
                  }],
                  [{
                    text: 'IRBPNR',
                    style: 'tablenegrita'
                  }, {
                    text: this.impuestos(infoFactura.totalConImpuestos[0], '5', '7').iva,
                    alignment: 'right'
                  }],
                  [{
                    text: 'PROPINA',
                    style: 'tablenegrita'
                  }, {
                    text: infoFactura.propina[0],
                    alignment: 'right'
                  }],
                  [{
                    text: 'VALOR TOTAL',
                    style: 'tablenegrita'
                  }, {
                    text: infoFactura.importeTotal[0],
                    alignment: 'right'
                  }],
                ]
              }
            }
          ]
        ]
      }
      ],
      styles: {
        header: {
          fontSize: 10,
          bold: true
        },
        bigger: {
          fontSize: 10,
          italics: true
        },
        tableExample: {
          margin: [0, 0, 0, 15]
        },
        tableTitulo: {
          bold: true,
          fontSize: 12,
        },
        tablenegrita: {
          bold: true
        },
        tablaFactura: {
          bold: true,
          fontSize: 14,
          alignment: 'center',
          color: '#FFFFFF'
        },
        tableDetalle: {
          fontSize: 8,
          margin: [0, 0, 0, 5]
        }
      },
      defaultStyle: {
        columnGap: 3,
        fontSize: 9,
      }
    };
    // se llena el detalle de la factura en la tabla correspondiente
    for (const deta of facturaDetalles) {
      let det: any;
      det = [
        {
          text: deta.codigoPrincipal[0]
        },
        {
          text: deta.codigoAuxiliar[0]
        }, {
          text: deta.cantidad[0],
          alignment: 'right'
        },
        {
          text: deta.descripcion[0]
        },
        {
          text: deta.precioUnitario[0],
          alignment: 'right'
        },
        {
          text: deta.descuento[0],
          alignment: 'right'
        },
        {
          text: deta.precioTotalSinImpuesto[0],
          alignment: 'right'
        },
      ];
      dd.content[2].table.body.push(det);
    }

    // llenar la informacion adicional
    if (infoAdicional !== undefined) {
      let cont = 0;
      for (const infad of infoAdicional) {
        cont++;
        let adicional: any;
        if (infoAdicional.length !== cont) {
          adicional = [{
            border: [true, false, true, false],
            text: infad.$.nombre + ': ' + infad._
          }];
        } else {
          adicional = [{
            border: [true, false, true, true],
            text: infad.$.nombre + ': ' + infad._
          }];
        }

        dd.content[3].columns[0][0].table.body.push(adicional);
      }
    }
    // llenar la informacion de la forma de pago
    for (const pago of infoFactura.pagos) {
      let formapago: any;
      // console.log('la forma de pago es: ');
      // console.log(pago.pago[0].formaPago);
      formapago = [{
        text: this.obtenerNombreFormadePago(pago.pago[0].formaPago[0])
      }, {
        text: pago.pago[0].total
      }];
      dd.content[3].columns[0][1].table.body.push(formapago);
    }
    return dd;
  }

  // metodo para armar el pdf de la liquidacion de compra

  public async armarPDFLiquidacion(data,TRNDOC_PRO_RUCIDE) {
    const infoTributaria = data.liquidacionCompra.infoTributaria[0];
    const infoFactura = data.liquidacionCompra.infoLiquidacionCompra[0];
    const facturaDetalles = data.liquidacionCompra.detalles[0].detalle;
    const infoAdicional = data.liquidacionCompra.infoAdicional[0].campoAdicional;

    const logotipo=await this.obtenerLogotipo(TRNDOC_PRO_RUCIDE).toPromise();

    let ambiente = 'Producción';
    if (infoTributaria.ambiente[0] === '1') {
      ambiente = 'Pruebas';
    }
    console.log(infoTributaria);
    const dd: any = {
      content: [{
        alignment: 'justify',
        columns: [
          [
            {
              image: logotipo,
              width: 200,
              alignment: 'center',
            }, { text: '\n\n\n\n\n\n' },
            {
              style: 'tableExample',
              table: {
                widths: ['auto'],
                body: [
                  [{
                    border: [true, true, true, false],
                    text: infoTributaria.razonSocial[0], style: 'tableTitulo', alignment: 'center'
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'Dirección Matríz: ',
                        style: 'tablenegrita'
                      }, {
                        text: infoTributaria.dirMatriz[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'Dirección Sucursal: ',
                        style: 'tablenegrita'
                      }, {
                        text: this.existeDireccion(infoFactura)
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'Contribuyente Especial Nro: ',
                        style: 'tablenegrita'
                      }, {
                        text: this.existeContribucion(infoFactura)
                      }],
                  }],
                  [{
                    border: [true, false, true, true],
                    text: [
                      {
                        text: 'Obligado a llelet Contabilidad: ',
                        style: 'tablenegrita'
                      }, {
                        text: infoFactura.obligadoContabilidad[0]
                      }],
                  }]
                ]
              }
            }

          ],
          [
            {
              style: 'tableExample',
              table: {
                widths: ['auto'],
                body: [
                  [{
                    border: [true, true, true, false],
                    text: [
                      {
                        text: 'R.U.C: ',
                      }, {
                        text: infoTributaria.ruc[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    fillColor: '#000000',
                    text: [
                      {
                        text: 'LIQUIDACIÓN DE COMPRA',
                        style: 'tablaFactura',

                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'No. ',
                        style: 'tablenegrita'
                      }, {
                        text: infoTributaria.estab[0] + '-' + infoTributaria.ptoEmi[0] + '-' + infoTributaria.secuencial[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'NÚMERO DE AUTORIZACIÓN: ',
                        alignment: 'left',
                      }, {
                        text: infoTributaria.claveAcceso[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'FECHA Y HORA DE AUTORIZACIÓN: ',
                      }, {
                        text: infoFactura.fechaEmision[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'AMBIENTE: ',
                      }, {
                        text: ambiente
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'EMISIÓN: ',
                      }, {
                        text: 'Normal'
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'CLAVE DE ACCESO: ',
                        alignment: 'center',
                      }],
                  }],
                  [{
                    border: [true, false, true, true],
                    image: this.obtenerCodigoBarras(infoTributaria.claveAcceso[0]),
                    width: 250
                  }
                  ]
                ]
              }
            }
          ]
        ]
      },
      {
        style: 'tableExample',
        table: {
          widths: ['auto', '*', 'auto', 'auto'],
          body: [
            [
              {
                border: [true, true, false, false],
                text: 'Nombres y Apellidos: ',
                style: 'tablenegrita'
              },
              {
                border: [false, true, false, false],
                text: infoFactura.razonSocialProveedor[0]
              },
              {
                border: [false, true, false, false],
                text: 'Identificación: ',
                style: 'tablenegrita'
              },
              {
                border: [false, true, true, false],
                text: infoFactura.identificacionProveedor[0]
              }
            ],
            [
              {
                border: [true, false, false, true],
                text: 'Fecha Emisión:: ',
                style: 'tablenegrita'
              },
              {
                border: [false, false, false, true],
                text: infoFactura.fechaEmision[0]
              },
              {
                border: [false, false, false, true],
                text: '',

              },
              {
                border: [false, false, true, true],
                text: ''
              }
            ]
          ]
        }
      }, {
        style: 'tableDetalle',
        table: {
          widths: ['auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto'],
          body: [
            [
              {
                text: 'Cod. Principal',
                style: 'tablenegrita'
              },
              {
                text: 'Cod. Auxiliar',
                style: 'tablenegrita'
              },
              {
                text: 'Cant',
                style: 'tablenegrita'
              },
              {
                text: 'Descripción',
                style: 'tablenegrita'
              },
              {
                text: 'Precio Unitario',
                style: 'tablenegrita'
              },
              {
                text: 'Descuento',
                style: 'tablenegrita'
              },
              {
                text: 'Precio Total',
                style: 'tablenegrita'
              }
            ]
          ]
        }
      }, {
        columns: [
          [
            {
              style: 'tableExample',
              table: {
                widths: [240],
                body: [[{
                  border: [true, true, true, false],
                  text: 'INFORMACIÓN ADICIONAL',
                  style: 'tablenegrita',
                  alignment: 'center'
                }]]
              }
            }, {
              style: 'tableExample',
              table: {
                widths: ['*', 'auto'],
                body: [[{
                  text: 'FORMA DE PAGO',
                  style: 'tablenegrita',
                  alignment: 'center'
                }, {
                  text: 'VALOR',
                  style: 'tablenegrita',
                  alignment: 'center'
                }
                ]]
              }
            }
          ], [
            {
              style: 'tableExample',
              table: {
                widths: ['*', 'auto'],
                body: [
                  [{
                    text: 'SUBTOTAL 12%',
                    style: 'tablenegrita'
                  }, {
                    text: this.subtotalDoce(infoFactura.totalConImpuestos[0]).sub,
                    alignment: 'right'
                  }],
                  [{
                    text: 'SUBTOTAL 0%',
                    style: 'tablenegrita'
                  }, {
                    text: this.impuestos(infoFactura.totalConImpuestos[0], '2', '0').sub,
                    alignment: 'right'
                  }],
                  [{
                    text: 'SUBTOTAL no objeto de IVA',
                    style: 'tablenegrita'
                  }, {
                    text: this.impuestos(infoFactura.totalConImpuestos[0], '2', '6').sub,
                    alignment: 'right'
                  }],
                  [{
                    text: 'SUBTOTAL Exento de IVA',
                    style: 'tablenegrita'
                  }, {
                    text: this.impuestos(infoFactura.totalConImpuestos[0], '2', '7').sub,
                    alignment: 'right'
                  }],
                  [{
                    text: 'SUBTOTAL SIN IMPUESTOS',
                    style: 'tablenegrita'
                  }, {
                    text: infoFactura.totalSinImpuestos[0],
                    alignment: 'right'
                  }],
                  [{
                    text: 'DESCUENTO',
                    style: 'tablenegrita'
                  }, {
                    text: infoFactura.totalDescuento[0],
                    alignment: 'right'
                  }],
                  [{
                    text: 'ICE',
                    style: 'tablenegrita'
                  }, {
                    text: this.impuestos(infoFactura.totalConImpuestos[0], '3', '7').iva,
                    alignment: 'right'
                  }],
                  [{
                    text: 'IVA 12%',
                    style: 'tablenegrita'
                  }, {
                    text: this.subtotalDoce(infoFactura.totalConImpuestos[0]).iva,
                    alignment: 'right'
                  }],
                  [{
                    text: 'IRBPNR',
                    style: 'tablenegrita'
                  }, {
                    text: this.impuestos(infoFactura.totalConImpuestos[0], '5', '7').iva,
                    alignment: 'right'
                  }],

                  [{
                    text: 'VALOR TOTAL',
                    style: 'tablenegrita'
                  }, {
                    text: infoFactura.importeTotal[0],
                    alignment: 'right'
                  }],
                ]
              }
            }
          ]
        ]
      }
      ],
      styles: {
        header: {
          fontSize: 10,
          bold: true
        },
        bigger: {
          fontSize: 10,
          italics: true
        },
        tableExample: {
          margin: [0, 0, 0, 15]
        },
        tableTitulo: {
          bold: true,
          fontSize: 12,
        },
        tablenegrita: {
          bold: true
        },
        tablaFactura: {
          bold: true,
          fontSize: 14,
          alignment: 'center',
          color: '#FFFFFF'
        },
        tableDetalle: {
          fontSize: 8,
          margin: [0, 0, 0, 5]
        }
      },
      defaultStyle: {
        columnGap: 3,
        fontSize: 9,
      }
    };
    // se llena el detalle de la factura en la tabla correspondiente
    for (const deta of facturaDetalles) {
      let det: any;
      det = [
        {
          text: deta.codigoPrincipal[0]
        },
        {
          text: deta.codigoAuxiliar[0]
        }, {
          text: deta.cantidad[0],
          alignment: 'right'
        },
        {
          text: deta.descripcion[0]
        },
        {
          text: deta.precioUnitario[0],
          alignment: 'right'
        },
        {
          text: deta.descuento[0],
          alignment: 'right'
        },
        {
          text: deta.precioTotalSinImpuesto[0],
          alignment: 'right'
        },
      ];
      dd.content[2].table.body.push(det);
    }

    // llenar la informacion adicional
    if (infoAdicional !== undefined) {
      let cont = 0;
      for (const infad of infoAdicional) {
        cont++;
        let adicional: any;
        if (infoAdicional.length !== cont) {
          adicional = [{
            border: [true, false, true, false],
            text: infad.$.nombre + ': ' + infad._
          }];
        } else {
          adicional = [{
            border: [true, false, true, true],
            text: infad.$.nombre + ': ' + infad._
          }];
        }

        dd.content[3].columns[0][0].table.body.push(adicional);
      }
    }
    // llenar la informacion de la forma de pago
    for (const pago of infoFactura.pagos) {
      let formapago: any;
      formapago = [{
        text: this.obtenerNombreFormadePago(pago.pago[0].formaPago[0])
      }, {
        text: pago.pago[0].total
      }];
      dd.content[3].columns[0][1].table.body.push(formapago);
    }
    return dd;
  }


  // metodo para armar el pdf de la nota de credito '04'
  public async armarPDFNotaCredito(data,TRNDOC_PRO_RUCIDE) {

    const infoTributaria = data.notaCredito.infoTributaria[0];
    const infoNotaCredito = data.notaCredito.infoNotaCredito[0];
    const facturaDetalles = data.notaCredito.detalles[0].detalle;
    const infoAdicional = data.notaCredito.infoAdicional[0].campoAdicional;

    const logotipo=await this.obtenerLogotipo(TRNDOC_PRO_RUCIDE).toPromise();

    let ambiente = 'Producción';
    if (infoTributaria.ambiente[0] === '1') {
      ambiente = 'Pruebas';
    }
    console.log(infoTributaria);
    const dd: any = {
      content: [{
        alignment: 'justify',
        columns: [
          [
            {
              image: logotipo,
              width: 200,
              alignment: 'center',
            }, { text: '\n\n\n\n\n\n' },
            {
              style: 'tableExample',
              table: {
                widths: ['auto'],
                body: [
                  [{
                    border: [true, true, true, false],
                    text: infoTributaria.razonSocial[0], style: 'tableTitulo', alignment: 'center'
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'Dirección Matríz: ',
                        style: 'tablenegrita'
                      }, {
                        text: infoTributaria.dirMatriz[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'Dirección Sucursal: ',
                        style: 'tablenegrita'
                      }, {
                        text: infoNotaCredito.dirEstablecimiento[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'Contribuyente Especial Nro: ',
                        style: 'tablenegrita'
                      }, {
                        text: infoNotaCredito.contribuyenteEspecial[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, true],
                    text: [
                      {
                        text: 'Obligado a llelet Contabilidad: ',
                        style: 'tablenegrita'
                      }, {
                        text: infoNotaCredito.obligadoContabilidad[0]
                      }],
                  }]
                ]
              }
            }

          ],
          [
            {
              style: 'tableExample',
              table: {
                widths: ['auto'],
                body: [
                  [{
                    border: [true, true, true, false],
                    text: [
                      {
                        text: 'R.U.C: ',
                      }, {
                        text: infoTributaria.ruc[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    fillColor: '#000000',
                    text: [
                      {
                        text: 'NOTA DE CRÉDITO ',
                        style: 'tablaFactura',

                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'No. ',
                        style: 'tablenegrita'
                      }, {
                        text: infoTributaria.estab[0] + '-' + infoTributaria.ptoEmi[0] + '-' + infoTributaria.secuencial[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'NÚMERO DE AUTORIZACIÓN: ',
                        alignment: 'left',
                      }, {
                        text: infoTributaria.claveAcceso[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'FECHA Y HORA DE AUTORIZACIÓN: ',
                      }, {
                        text: infoNotaCredito.fechaEmision[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'AMBIENTE: ',
                      }, {
                        text: ambiente
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'EMISIÓN: ',
                      }, {
                        text: 'Normal'
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'CLAVE DE ACCESO: ',
                        alignment: 'center',
                      }],
                  }],
                  [{
                    border: [true, false, true, true],
                    image: this.obtenerCodigoBarras(infoTributaria.claveAcceso[0]),
                    width: 250
                  }
                  ]
                ]
              }
            }
          ]
        ]
      },
      {
        style: 'tableExample',
        table: {
          widths: ['auto', '*', 'auto', 'auto'],
          body: [
            [
              {
                border: [true, true, false, false],
                text: 'Razón Social / Nombres y Apellidos: ',
                style: 'tablenegrita'
              },
              {
                border: [false, true, false, false],
                text: infoNotaCredito.razonSocialComprador[0]
              },
              {
                border: [false, true, false, false],
                text: 'Identificación: ',
                style: 'tablenegrita'
              },
              {
                border: [false, true, true, false],
                text: infoNotaCredito.identificacionComprador[0]
              }
            ],
            [
              {
                border: [true, false, false, false],
                text: 'Fecha Emisión: ',
                style: 'tablenegrita'
              },
              {
                border: [false, false, false, false],
                text: infoNotaCredito.fechaEmision[0]
              },
              {
                border: [false, false, false, false],
                text: '',
                style: 'tablenegrita'
              },
              {
                border: [false, false, true, false],
                text: ''
              }
            ],
            [
              {
                border: [true, false, true, false],
                text: '__________________________________________________________________________________________________________________',
                colSpan: 4,
                alignment: 'center'
              },
              {},
              {},
              {}
            ],
            [
              {
                border: [true, false, false, false],
                text: 'Comprobante que se modifica: ',
                style: 'tablenegrita'
              },
              {
                border: [false, false, false, false],
                text: ''
              },
              {
                border: [false, false, false, false],
                text: 'FACTURA:',
                style: 'tablenegrita'
              },
              {
                border: [false, false, true, false],
                text: infoNotaCredito.numDocModificado[0]
              }
            ],
            [
              {
                border: [true, false, false, false],
                text: 'Fecha de emisión (Comprobante a modificar):',
                style: 'tablenegrita'
              },
              {
                border: [false, false, false, false],
                text: infoNotaCredito.fechaEmisionDocSustento[0]
              },
              {
                border: [false, false, false, false],
                text: '',
                style: 'tablenegrita'
              },
              {
                border: [false, false, true, false],
                text: ''
              }
            ],
            [
              {
                border: [true, false, false, true],
                text: 'Razón de modificación:',
                style: 'tablenegrita'
              },
              {
                border: [false, false, false, true],
                text: infoNotaCredito.motivo[0]
              },
              {
                border: [false, false, false, true],
                text: '',
                style: 'tablenegrita'
              },
              {
                border: [false, false, true, true],
                text: ''
              }
            ],
          ]
        }
      }, {
        style: 'tableDetalle',
        table: {
          widths: ['auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto'],
          body: [
            [
              {
                text: 'Cod. Principal',
                style: 'tablenegrita'
              },
              {
                text: 'Cod. Auxiliar',
                style: 'tablenegrita'
              },
              {
                text: 'Cant',
                style: 'tablenegrita'
              },
              {
                text: 'Descripción',
                style: 'tablenegrita'
              },
              {
                text: 'Precio Unitario',
                style: 'tablenegrita'
              },
              {
                text: 'Descuento',
                style: 'tablenegrita'
              },
              {
                text: 'Precio Total',
                style: 'tablenegrita'
              }
            ]
          ]
        }
      }, {
        columns: [
          [
            {
              style: 'tableExample',
              table: {
                widths: [240],
                body: [[{
                  border: [true, true, true, false],
                  text: 'INFORMACIÓN ADICIONAL',
                  style: 'tablenegrita',
                  alignment: 'center'
                }]]
              }
            }
          ], [
            {
              style: 'tableExample',
              table: {
                widths: ['*', 'auto'],
                body: [
                  [{
                    text: 'SUBTOTAL 12%',
                    style: 'tablenegrita'
                  }, {
                    text: this.subtotalDoce(infoNotaCredito.totalConImpuestos[0]).sub,
                    alignment: 'right'
                  }],
                  [{
                    text: 'SUBTOTAL 0%',
                    style: 'tablenegrita'
                  }, {
                    text: this.impuestos(infoNotaCredito.totalConImpuestos[0], '2', '0').sub,
                    alignment: 'right'
                  }],
                  [{
                    text: 'SUBTOTAL no objeto de IVA',
                    style: 'tablenegrita'
                  }, {
                    text: this.impuestos(infoNotaCredito.totalConImpuestos[0], '2', '6').sub,
                    alignment: 'right'
                  }],
                  [{
                    text: 'SUBTOTAL Exento de IVA',
                    style: 'tablenegrita'
                  }, {
                    text: this.impuestos(infoNotaCredito.totalConImpuestos[0], '2', '7').sub,
                    alignment: 'right'
                  }],
                  [{
                    text: 'SUBTOTAL SIN IMPUESTOS',
                    style: 'tablenegrita'
                  }, {
                    text: infoNotaCredito.totalSinImpuestos[0],
                    alignment: 'right'
                  }],
                  [{
                    text: 'DESCUENTO',
                    style: 'tablenegrita'
                  }, {
                    text: this.sumTotalDescuento(facturaDetalles).toFixed(2),
                    alignment: 'right'
                  }],
                  [{
                    text: 'ICE',
                    style: 'tablenegrita'
                  }, {
                    text: this.impuestos(infoNotaCredito.totalConImpuestos[0], '3', '7').iva,
                    alignment: 'right'
                  }],
                  [{
                    text: 'IVA 12%',
                    style: 'tablenegrita'
                  }, {
                    text: this.subtotalDoce(infoNotaCredito.totalConImpuestos[0]).iva,
                    alignment: 'right'
                  }],
                  [{
                    text: 'IRBPNR',
                    style: 'tablenegrita'
                  }, {
                    text: this.impuestos(infoNotaCredito.totalConImpuestos[0], '5', '7').iva,
                    alignment: 'right'
                  }],
                  [{
                    text: 'VALOR TOTAL',
                    style: 'tablenegrita'
                  }, {
                    text: infoNotaCredito.valorModificacion[0],
                    alignment: 'right'
                  }],
                ]
              }
            }
          ]
        ]
      }
      ],
      styles: {
        header: {
          fontSize: 10,
          bold: true
        },
        bigger: {
          fontSize: 10,
          italics: true
        },
        tableExample: {
          margin: [0, 0, 0, 15]
        },
        tableTitulo: {
          bold: true,
          fontSize: 12,
        },
        tablenegrita: {
          bold: true
        },
        tablaFactura: {
          bold: true,
          fontSize: 14,
          alignment: 'center',
          color: '#FFFFFF'
        },
        tableDetalle: {
          fontSize: 8,
          margin: [0, 0, 0, 5]
        }
      },
      defaultStyle: {
        columnGap: 3,
        fontSize: 9,
      }
    };
    // se llena el detalle de la factura en la tabla correspondiente
    for (const deta of facturaDetalles) {
      let descuento = 0;
      if (deta.descuento) {
        descuento = Number(deta.descuento[0]);
      }
      let det: any;
      det = [
        {
          text: deta.codigoInterno[0]
        },
        {
          text: deta.codigoAdicional[0]
        }, {
          text: deta.cantidad[0],
          alignment: 'right'
        },
        {
          text: deta.descripcion[0]
        },
        {
          text: deta.precioUnitario[0],
          alignment: 'right'
        },
        {
          text: descuento.toFixed(2),
          alignment: 'right'
        },
        {
          text: deta.precioTotalSinImpuesto[0],
          alignment: 'right'
        },
      ];
      dd.content[2].table.body.push(det);
    }

    // llenar la informacion adicional
    if (infoAdicional !== undefined) {
      let cont = 0;
      for (const infad of infoAdicional) {
        cont++;
        let adicional: any;
        if (infoAdicional.length !== cont) {
          adicional = [{
            border: [true, false, true, false],
            text: infad.$.nombre + ': ' + infad._
          }];
        } else {
          adicional = [{
            border: [true, false, true, true],
            text: infad.$.nombre + ': ' + infad._
          }];
        }

        dd.content[3].columns[0][0].table.body.push(adicional);
      }
    }

    return dd;
  }

  public async armarPDFNotaDebito(data,TRNDOC_PRO_RUCIDE) {
    const infoTributaria = data.notaDebito.infoTributaria[0];
    const infoNotaDebito = data.notaDebito.infoNotaDebito[0];
    const infoMotivos = data.notaDebito.motivos[0].motivo;
    const infoAdicional = data.notaDebito.infoAdicional[0].campoAdicional;

    const logotipo=await this.obtenerLogotipo(TRNDOC_PRO_RUCIDE).toPromise();

    let direstablecimiento = '';
    if (infoNotaDebito.dirEstablecimiento) {
      direstablecimiento = infoNotaDebito.dirEstablecimiento[0];
    }

    let contribuyenteEspecial = '000';
    if (infoNotaDebito.contribuyenteEspecial) {
      contribuyenteEspecial = infoNotaDebito.contribuyenteEspecial[0];
    }

    let ambiente = 'Producción';
    if (infoTributaria.ambiente[0] === '1') {
      ambiente = 'Pruebas';
    }
    console.log(infoTributaria);
    const dd: any = {
      content: [{
        alignment: 'justify',
        columns: [
          [
            {
              image: logotipo,
              width: 200,
              alignment: 'center',
            }, { text: '\n\n\n\n\n\n' },
            {
              style: 'tableExample',
              table: {
                widths: ['auto'],
                body: [
                  [{
                    border: [true, true, true, false],
                    text: infoTributaria.razonSocial[0], style: 'tableTitulo', alignment: 'center'
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'Dirección Matríz: ',
                        style: 'tablenegrita'
                      }, {
                        text: infoTributaria.dirMatriz[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'Dirección Sucursal: ',
                        style: 'tablenegrita'
                      }, {
                        text: direstablecimiento
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'Contribuyente Especial Nro: ',
                        style: 'tablenegrita'
                      }, {
                        text: contribuyenteEspecial
                      }],
                  }],
                  [{
                    border: [true, false, true, true],
                    text: [
                      {
                        text: 'Obligado a llelet Contabilidad: ',
                        style: 'tablenegrita'
                      }, {
                        text: infoNotaDebito.obligadoContabilidad[0]
                      }],
                  }]
                ]
              }
            }

          ],
          [
            {
              style: 'tableExample',
              table: {
                widths: ['auto'],
                body: [
                  [{
                    border: [true, true, true, false],
                    text: [
                      {
                        text: 'R.U.C: ',
                      }, {
                        text: infoTributaria.ruc[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    fillColor: '#000000',
                    text: [
                      {
                        text: 'NOTA DE DÉBITO',
                        style: 'tablaFactura',

                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'No. ',
                        style: 'tablenegrita'
                      }, {
                        text: infoTributaria.estab[0] + '-' + infoTributaria.ptoEmi[0] + '-' + infoTributaria.secuencial[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'NÚMERO DE AUTORIZACIÓN: ',
                        alignment: 'left',
                      }, {
                        text: infoTributaria.claveAcceso[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'FECHA Y HORA DE AUTORIZACIÓN: ',
                      }, {
                        text: infoNotaDebito.fechaEmision[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'AMBIENTE: ',
                      }, {
                        text: ambiente
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'EMISIÓN: ',
                      }, {
                        text: 'Normal'
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'CLAVE DE ACCESO: ',
                        alignment: 'center',
                      }],
                  }],
                  [{
                    border: [true, false, true, true],
                    image: this.obtenerCodigoBarras(infoTributaria.claveAcceso[0]),
                    width: 250
                  }
                  ]
                ]
              }
            }
          ]
        ]
      },
      {
        style: 'tableExample',
        table: {
          widths: ['auto', '*', 'auto', 'auto'],
          body: [
            [
              {
                border: [true, true, false, false],
                text: 'Razón Social / Nombres y Apellidos: ',
                style: 'tablenegrita'
              },
              {
                border: [false, true, false, false],
                text: infoNotaDebito.razonSocialComprador[0]
              },
              {
                border: [false, true, false, false],
                text: 'Identificación: ',
                style: 'tablenegrita'
              },
              {
                border: [false, true, true, false],
                text: infoNotaDebito.identificacionComprador[0]
              }
            ],
            [
              {
                border: [true, false, false, false],
                text: 'Fecha Emisión: ',
                style: 'tablenegrita'
              },
              {
                border: [false, false, false, false],
                text: infoNotaDebito.fechaEmision[0]
              },
              {
                border: [false, false, false, false],
                text: '',
                style: 'tablenegrita'
              },
              {
                border: [false, false, true, false],
                text: ''
              }
            ],
            [
              {
                border: [true, false, true, false],
                text: '__________________________________________________________________________________________________________________',
                colSpan: 4,
                alignment: 'center'
              },
              {},
              {},
              {}
            ],
            [
              {
                border: [true, false, false, false],
                text: 'Comprobante que se modifica: ',
                style: 'tablenegrita'
              },
              {
                border: [false, false, false, false],
                text: ''
              },
              {
                border: [false, false, false, false],
                text: 'FACTURA:',
                style: 'tablenegrita'
              },
              {
                border: [false, false, true, false],
                text: infoNotaDebito.numDocModificado[0]
              }
            ],
            [
              {
                border: [true, false, false, true],
                text: 'Fecha de emisión (Comprobante a modificar):',
                style: 'tablenegrita'
              },
              {
                border: [false, false, false, true],
                text: infoNotaDebito.fechaEmisionDocSustento[0]
              },
              {
                border: [false, false, false, true],
                text: '',
                style: 'tablenegrita'
              },
              {
                border: [false, false, true, true],
                text: ''
              }
            ]
          ]
        }
      }, {
        style: 'tableDetalle',
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                text: 'RAZÓN DE LA MODIFICACIÓN',
                style: 'tablenegrita',
                alignment: 'center'
              },
              {
                text: 'VALOR DE LA MODIFICACIÓN',
                style: 'tablenegrita',
                alignment: 'center'
              }
            ]
          ]
        }
      }, {
        columns: [
          [
            {
              style: 'tableExample',
              table: {
                widths: [240],
                body: [[{
                  border: [true, true, true, false],
                  text: 'INFORMACIÓN ADICIONAL',
                  style: 'tablenegrita',
                  alignment: 'center'
                }]]
              }
            }
          ], [
            {
              style: 'tableExample',
              table: {
                widths: ['*', 'auto'],
                body: [
                  [{
                    text: 'SUBTOTAL 12%',
                    style: 'tablenegrita'
                  }, {
                    text: this.subtotalDoce(infoNotaDebito.impuestos[0]).sub,
                    alignment: 'right'
                  }],
                  [{
                    text: 'SUBTOTAL 0%',
                    style: 'tablenegrita'
                  }, {
                    text: this.impuestos(infoNotaDebito.impuestos[0], '2', '0').sub,
                    alignment: 'right'
                  }],
                  [{
                    text: 'SUBTOTAL no objeto de IVA',
                    style: 'tablenegrita'
                  }, {
                    text: this.impuestos(infoNotaDebito.impuestos[0], '2', '6').sub,
                    alignment: 'right'
                  }],
                  [{
                    text: 'SUBTOTAL Exento de IVA',
                    style: 'tablenegrita'
                  }, {
                    text: this.impuestos(infoNotaDebito.impuestos[0], '2', '7').sub,
                    alignment: 'right'
                  }],
                  [{
                    text: 'SUBTOTAL SIN IMPUESTOS',
                    style: 'tablenegrita'
                  }, {
                    text: infoNotaDebito.totalSinImpuestos[0],
                    alignment: 'right'
                  }],
                  [{
                    text: 'ICE',
                    style: 'tablenegrita'
                  }, {
                    text: this.impuestos(infoNotaDebito.impuestos[0], '3', '7').iva,
                    alignment: 'right'
                  }],
                  [{
                    text: 'IVA 12%',
                    style: 'tablenegrita'
                  }, {
                    text: this.subtotalDoce(infoNotaDebito.impuestos[0]).iva,
                    alignment: 'right'
                  }],
                  [{
                    text: 'IRBPNR',
                    style: 'tablenegrita'
                  }, {
                    text: this.impuestos(infoNotaDebito.impuestos[0], '5', '7').iva,
                    alignment: 'right'
                  }],
                  [{
                    text: 'VALOR TOTAL',
                    style: 'tablenegrita'
                  }, {
                    text: infoNotaDebito.valorTotal[0],
                    alignment: 'right'
                  }],
                ]
              }
            }
          ]
        ]
      }
      ],
      styles: {
        header: {
          fontSize: 10,
          bold: true
        },
        bigger: {
          fontSize: 10,
          italics: true
        },
        tableExample: {
          margin: [0, 0, 0, 15]
        },
        tableTitulo: {
          bold: true,
          fontSize: 12,
        },
        tablenegrita: {
          bold: true
        },
        tablaFactura: {
          bold: true,
          fontSize: 14,
          alignment: 'center',
          color: '#FFFFFF'
        },
        tableDetalle: {
          fontSize: 8,
          margin: [0, 0, 0, 5]
        }
      },
      defaultStyle: {
        columnGap: 3,
        fontSize: 9,
      }
    };
    // se llena los motivos de la nota de debito
    for (const deta of infoMotivos) {

      let det: any;
      det = [
        {
          text: deta.razon[0]
        },
        {
          text: deta.valor[0],
          alignment: 'right'
        }
      ];
      dd.content[2].table.body.push(det);
    }

    // llenar la informacion adicional
    if (infoAdicional !== undefined) {
      let cont = 0;
      for (const infad of infoAdicional) {
        cont++;
        let adicional: any;
        if (infoAdicional.length !== cont) {
          adicional = [{
            border: [true, false, true, false],
            text: infad.$.nombre + ': ' + infad._
          }];
        } else {
          adicional = [{
            border: [true, false, true, true],
            text: infad.$.nombre + ': ' + infad._
          }];
        }

        dd.content[3].columns[0][0].table.body.push(adicional);
      }
    }

    return dd;
  }

  public async armarPDFGuiaRemision(data,TRNDOC_PRO_RUCIDE) {
    const infoTributaria = data.guiaRemision.infoTributaria[0];
    const infoGuiaRemision = data.guiaRemision.infoGuiaRemision[0];
    const destinatarios = data.guiaRemision.destinatarios[0].destinatario;
    const infoAdicional = data.guiaRemision.infoAdicional[0].campoAdicional;

    const logotipo=await this.obtenerLogotipo(TRNDOC_PRO_RUCIDE).toPromise();

    let direstablecimiento = '';
    if (infoGuiaRemision.dirEstablecimiento) {
      direstablecimiento = infoGuiaRemision.dirEstablecimiento[0];
    }

    let contribuyenteEspecial = '000';
    if (infoGuiaRemision.contribuyenteEspecial) {
      contribuyenteEspecial = infoGuiaRemision.contribuyenteEspecial[0];
    }

    let numDocSustento = '';
    let numAutDocSustento = '';
    let fechaEmisionDocSustento = '';
    let docAduaneroUnico = '';
    let codEstabDestino = '';
    let ruta = '';

    if (destinatarios[0].numDocSustento) {
      numDocSustento = destinatarios[0].numDocSustento[0];
    }
    if (destinatarios[0].numAutDocSustento) {
      numAutDocSustento = destinatarios[0].numAutDocSustento[0];
    }
    if (destinatarios[0].fechaEmisionDocSustento) {
      fechaEmisionDocSustento = destinatarios[0].fechaEmisionDocSustento[0];
    }
    if (destinatarios[0].docAduaneroUnico) {
      docAduaneroUnico = destinatarios[0].docAduaneroUnico[0];
    }
    if (destinatarios[0].codEstabDestino) {
      codEstabDestino = destinatarios[0].codEstabDestino[0];
    }
    if (destinatarios[0].ruta) {
      ruta = destinatarios[0].ruta[0];
    }

    let ambiente = 'Producción';
    if (infoTributaria.ambiente[0] === '1') {
      ambiente = 'Pruebas';
    }
    console.log(infoTributaria);
    const dd: any = {
      content: [{
        alignment: 'justify',
        columns: [
          [
            {
              image: logotipo,
              width: 200,
              alignment: 'center',
            }, { text: '\n\n\n\n\n\n' },
            {
              style: 'tableExample',
              table: {
                widths: ['auto'],
                body: [
                  [{
                    border: [true, true, true, false],
                    text: infoTributaria.razonSocial[0], style: 'tableTitulo', alignment: 'center'
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'Dirección Matríz: ',
                        style: 'tablenegrita'
                      }, {
                        text: infoTributaria.dirMatriz[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'Dirección Sucursal: ',
                        style: 'tablenegrita'
                      }, {
                        text: direstablecimiento
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'Contribuyente Especial Nro: ',
                        style: 'tablenegrita'
                      }, {
                        text: contribuyenteEspecial
                      }],
                  }],
                  [{
                    border: [true, false, true, true],
                    text: [
                      {
                        text: 'Obligado a llelet Contabilidad: ',
                        style: 'tablenegrita'
                      }, {
                        text: infoGuiaRemision.obligadoContabilidad[0]
                      }],
                  }]
                ]
              }
            }

          ],
          [
            {
              style: 'tableExample',
              table: {
                widths: ['auto'],
                body: [
                  [{
                    border: [true, true, true, false],
                    text: [
                      {
                        text: 'R.U.C: ',
                      }, {
                        text: infoTributaria.ruc[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    fillColor: '#000000',
                    text: [
                      {
                        text: 'GUÍA DE REMISIÓN',
                        style: 'tablaFactura',

                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'No. ',
                        style: 'tablenegrita'
                      }, {
                        text: infoTributaria.estab[0] + '-' + infoTributaria.ptoEmi[0] + '-' + infoTributaria.secuencial[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'NÚMERO DE AUTORIZACIÓN: ',
                        alignment: 'left',
                      }, {
                        text: infoTributaria.claveAcceso[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'FECHA Y HORA DE AUTORIZACIÓN: ',
                      }, {
                        text: infoGuiaRemision.fechaIniTransporte[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'AMBIENTE: ',
                      }, {
                        text: ambiente
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'EMISIÓN: ',
                      }, {
                        text: 'Normal'
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'CLAVE DE ACCESO: ',
                        alignment: 'center',
                      }],
                  }],
                  [{
                    border: [true, false, true, true],
                    image: this.obtenerCodigoBarras(infoTributaria.claveAcceso[0]),
                    width: 250
                  }
                  ]
                ]
              }
            }
          ]
        ]
      },
      {
        style: 'tableExample',
        table: {
          widths: ['auto', '*', 'auto', 'auto'],
          body: [
            [
              {
                border: [true, true, false, false],
                text: 'Identificación (Transportista):',
                style: 'tablenegrita'
              },
              {
                border: [false, true, false, false],
                text: infoGuiaRemision.rucTransportista[0]
              },
              {
                border: [false, true, false, false],
                text: '',
                style: 'tablenegrita'
              },
              {
                border: [false, true, true, false],
                text: ''
              }
            ],
            [
              {
                border: [true, false, false, false],
                text: 'Razón Social / Nombres y Apellidos:',
                style: 'tablenegrita'
              },
              {
                border: [false, false, true, false],
                text: infoGuiaRemision.razonSocialTransportista[0],
                colSpan: 3,
              },
              {},
              {}
            ],
            [
              {
                border: [true, false, false, false],
                text: 'Placa:',
                style: 'tablenegrita'
              },
              {
                border: [false, false, false, false],
                text: infoGuiaRemision.placa[0]
              },
              {
                border: [false, false, false, false],
                text: '',
                style: 'tablenegrita'
              },
              {
                border: [false, false, true, false],
                text: ''
              }
            ],
            [
              {
                border: [true, false, false, false],
                text: 'Punto de Partida:',
                style: 'tablenegrita',
              },
              {
                border: [false, false, true, false],
                text: infoGuiaRemision.dirPartida[0],
                colSpan: 3,
              },
              {},
              {}
            ],
            [
              {
                border: [true, false, false, true],
                text: 'Fecha inicio Transporte:',
                style: 'tablenegrita'
              },
              {
                border: [false, false, false, true],
                text: infoGuiaRemision.fechaIniTransporte[0],
              },
              {
                border: [false, false, false, true],
                text: 'Fecha fin Transporte:',
                style: 'tablenegrita'
              },
              {
                border: [false, false, true, true],
                text: infoGuiaRemision.fechaFinTransporte[0]
              }
            ],
          ]
        }
      }, {
        style: 'tableExample',
        table: {
          widths: ['auto', '*'],
          body: [
            [
              {
                border: [true, true, false, false],
                text: 'Comprobante de Venta:'
              }, {
                border: [false, true, true, false],
                text: 'FACTURA:\t\t' + numDocSustento + '\t\t\t\tFecha de Emisión:\t\t' + fechaEmisionDocSustento,
              }
            ], [
              {
                border: [true, false, false, false],
                text: 'Número de Autorización:'
              },
              {
                border: [false, false, true, false],
                text: numAutDocSustento
              }
            ], [
              {
                border: [true, false, false, false],
                text: 'Motivo Traslado:'
              },
              {
                border: [false, false, true, false],
                text: destinatarios[0].motivoTraslado[0]
              }
            ],
            [
              {
                border: [true, false, false, false],
                text: 'Destino(Punto de llegada):'
              },
              {
                border: [false, false, true, false],
                text: destinatarios[0].dirDestinatario[0]
              }
            ],
            [
              {
                border: [true, false, false, false],
                text: 'Identificación(Destinatario):'
              },
              {
                border: [false, false, true, false],
                text: destinatarios[0].identificacionDestinatario[0]
              }
            ],
            [
              {
                border: [true, false, false, false],
                text: 'Razón Social/Nombres Apellidos:'
              },
              {
                border: [false, false, true, false],
                text: destinatarios[0].razonSocialDestinatario[0]
              }
            ],
            [
              {
                border: [true, false, false, false],
                text: 'Documento Aduanero:'
              },
              {
                border: [false, false, true, false],
                text: docAduaneroUnico
              }
            ],
            [
              {
                border: [true, false, false, false],
                text: 'Código Establecimiento Destino:'
              },
              {
                border: [false, false, true, false],
                text: codEstabDestino
              }
            ],
            [
              {
                border: [true, false, false, false],
                text: 'Ruta:'
              },
              {
                border: [false, false, true, false],
                text: ruta
              }
            ], [
              {
                border: [true, false, true, true],
                style: 'tableExample',
                colSpan: 2,
                table: {
                  widths: ['auto', '*', 'auto', 'auto'],
                  body: [
                    [
                      {
                        text: 'Cantidad',
                        alignment: 'center'
                      },
                      {
                        text: 'Descripción',
                        alignment: 'center'
                      },
                      {
                        text: 'Código Principal',
                        alignment: 'center'
                      },
                      {
                        text: 'Código Auxiliar',
                        alignment: 'center'
                      }
                    ]
                  ]
                }
              }, {}
            ]
          ]
        }
      }, {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            style: 'tableExample',
            table: {
              widths: ['auto'],
              body: [[{
                border: [true, true, true, false],
                text: 'INFORMACIÓN ADICIONAL',
                style: 'tablenegrita',
                alignment: 'center'
              }]]
            }
          },
          { width: '*', text: '' },
        ]
      }
      ],
      styles: {
        header: {
          fontSize: 10,
          bold: true
        },
        bigger: {
          fontSize: 10,
          italics: true
        },
        tableExample: {
          margin: [0, 0, 0, 15]
        },
        tableTitulo: {
          bold: true,
          fontSize: 12,
        },
        tablenegrita: {
          bold: true
        },
        tablaFactura: {
          bold: true,
          fontSize: 14,
          alignment: 'center',
          color: '#FFFFFF'
        },
        tableDetalle: {
          fontSize: 8,
          margin: [0, 0, 0, 5]
        }
      },
      defaultStyle: {
        columnGap: 3,
        fontSize: 9,
      }
    };
    // se llena la descripcion de la factura
    for (const deta of destinatarios[0].detalles[0].detalle) {

      let det: any;
      det = [
        {
          text: deta.cantidad[0]
        },
        {
          text: deta.descripcion[0],
        },
        {
          text: deta.codigoInterno[0],
        }, {
          text: deta.codigoAdicional[0],
        }
      ];
      dd.content[2].table.body[9][0].table.body.push(det);
    }

    // llenar la informacion adicional
    if (infoAdicional !== undefined) {
      let cont = 0;
      for (const infad of infoAdicional) {
        cont++;
        let adicional: any;
        if (infoAdicional.length !== cont) {
          adicional = [{
            border: [true, false, true, false],
            text: infad.$.nombre + ': ' + infad._
          }];
        } else {
          adicional = [{
            border: [true, false, true, true],
            text: infad.$.nombre + ': ' + infad._
          }];
        }

        dd.content[3].columns[1].table.body.push(adicional);
      }
    }

    return dd;
  }

  // metodo para armar el pdf de la retencion '07'
  public async armarPDFRetebcion(data,TRNDOC_PRO_RUCIDE) {
    const infoTributaria = data.comprobanteRetencion.infoTributaria[0];
    const infoCompRetencion = data.comprobanteRetencion.infoCompRetencion[0];
    //const impuestosRete = data.comprobanteRetencion.impuestos[0].impuesto;
    let impuestosRete=[]
    if(data.version === '2.0.0'){
      impuestosRete = data.docsSustento[0].docSustento[0].retenciones[0].retencion;
    }else if(data.version === '1.0.0') {
      impuestosRete = data.comprobanteRetencion.impuestos[0].impuesto;
    }
    const infoAdicional = data.comprobanteRetencion.infoAdicional[0].campoAdicional;

    const logotipo=await this.obtenerLogotipo(TRNDOC_PRO_RUCIDE).toPromise();

    let ambiente = 'Producción';
    if (infoTributaria.ambiente[0] === '1') {
      ambiente = 'Pruebas';
    }
    console.log(infoTributaria);
    const dd: any = {
      content: [{
        alignment: 'justify',
        columns: [
          [
            {
              image: logotipo,
              width: 200,
              alignment: 'center',
            }, { text: '\n\n\n\n\n\n' },
            {
              style: 'tableExample',
              table: {
                widths: ['auto'],
                body: [
                  [{
                    border: [true, true, true, false],
                    text: infoTributaria.razonSocial[0], style: 'tableTitulo', alignment: 'center'
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'Dirección Matríz: ',
                        style: 'tablenegrita'
                      }, {
                        text: infoTributaria.dirMatriz[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'Dirección Sucursal: ',
                        style: 'tablenegrita'
                      }, {
                        text: infoCompRetencion.dirEstablecimiento[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'Contribuyente Especial Nro: ',
                        style: 'tablenegrita'
                      }, {
                        text: infoCompRetencion.contribuyenteEspecial[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, true],
                    text: [
                      {
                        text: 'Obligado a llelet Contabilidad: ',
                        style: 'tablenegrita'
                      }, {
                        text: infoCompRetencion.obligadoContabilidad[0]
                      }],
                  }]
                ]
              }
            }

          ],
          [
            {
              style: 'tableExample',
              table: {
                widths: ['auto'],
                body: [
                  [{
                    border: [true, true, true, false],
                    text: [
                      {
                        text: 'R.U.C: ',
                      }, {
                        text: infoTributaria.ruc[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    fillColor: '#000000',
                    text: [
                      {
                        text: 'COMPROBANTE DE RETENCIÓN',
                        style: 'tablaFactura',

                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'No. ',
                        style: 'tablenegrita'
                      }, {
                        text: infoTributaria.estab[0] + '-' + infoTributaria.ptoEmi[0] + '-' + infoTributaria.secuencial[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'NÚMERO DE AUTORIZACIÓN: ',
                        alignment: 'left',
                      }, {
                        text: infoTributaria.claveAcceso[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'FECHA Y HORA DE AUTORIZACIÓN: ',
                      }, {
                        text: infoCompRetencion.fechaEmision[0]
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'AMBIENTE: ',
                      }, {
                        text: ambiente
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'EMISIÓN: ',
                      }, {
                        text: 'Normal'
                      }],
                  }],
                  [{
                    border: [true, false, true, false],
                    text: [
                      {
                        text: 'CLAVE DE ACCESO: ',
                        alignment: 'center',
                      }],
                  }],
                  [{
                    border: [true, false, true, true],
                    image: this.obtenerCodigoBarras(infoTributaria.claveAcceso[0]),
                    width: 250
                  }
                  ]
                ]
              }
            }
          ]
        ]
      },
      {
        style: 'tableExample',
        table: {
          widths: ['auto', '*', 'auto', 'auto'],
          body: [
            [
              {
                border: [true, true, false, false],
                text: 'Razón Social / Nombres y Apellidos: ',
                style: 'tablenegrita'
              },
              {
                border: [false, true, false, false],
                text: infoCompRetencion.razonSocialSujetoRetenido[0]
              },
              {
                border: [false, true, false, false],
                text: 'Identificación: ',
                style: 'tablenegrita'
              },
              {
                border: [false, true, true, false],
                text: infoCompRetencion.identificacionSujetoRetenido[0]
              }
            ],
            [
              {
                border: [true, false, false, true],
                text: 'Fecha Emisión:: ',
                style: 'tablenegrita'
              },
              {
                border: [false, false, false, true],
                text: infoCompRetencion.fechaEmision[0]
              },
              {
                border: [false, false, false, true],
                text: '',
                style: 'tablenegrita'
              },
              {
                border: [false, false, true, true],
                text: ''
              }
            ]
          ]
        }
      }, {
        style: 'tableDetalle',
        table: {
          widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
          body: [
            [
              {
                text: 'Comprobante',
                style: 'tablenegrita'
              },
              {
                text: 'Número',
                style: 'tablenegrita'
              },
              {
                text: 'Fecha Emisión',
                style: 'tablenegrita'
              },
              {
                text: 'Ejercicio Fiscal',
                style: 'tablenegrita'
              },
              {
                text: 'Base imponible para la retención',
                style: 'tablenegrita'
              },
              {
                text: 'IMPUESTO',
                style: 'tablenegrita'
              },
              {
                text: 'Porcentaje retención',
                style: 'tablenegrita'
              },
              {
                text: 'Valor Retenido',
                style: 'tablenegrita'
              }
            ]
          ]
        }
      }, {
        columns: [
          [
            {
              style: 'tableExample',
              table: {
                widths: [240],
                body: [[{
                  border: [true, true, true, false],
                  text: 'INFORMACIÓN ADICIONAL',
                  style: 'tablenegrita',
                  alignment: 'center'
                }]]
              }
            }

          ], [
            {
              style: 'tableExample',
              table: {
                widths: ['*', 'auto'],
                body: [
                  [{
                    text: 'Total Retenido:',
                    style: 'tablenegrita'
                  }, {
                    text: this.totalRetenido(impuestosRete),
                    alignment: 'right'
                  }],
                ]
              }
            }
          ]
        ]
      }
      ],
      styles: {
        header: {
          fontSize: 10,
          bold: true
        },
        bigger: {
          fontSize: 10,
          italics: true
        },
        tableExample: {
          margin: [0, 0, 0, 15]
        },
        tableTitulo: {
          bold: true,
          fontSize: 12,
        },
        tablenegrita: {
          bold: true
        },
        tablaFactura: {
          bold: true,
          fontSize: 14,
          alignment: 'center',
          color: '#FFFFFF'
        },
        tableDetalle: {
          fontSize: 8,
          margin: [0, 0, 0, 5]
        }
      },
      defaultStyle: {
        columnGap: 3,
        fontSize: 9,
      }
    };
    // se llena el detalle de la retencion en la tabla correspondiente
    for (const deta of impuestosRete) {
      let det: any;
      det = [
        {
          text: this.tipodeDocumento(deta.codDocSustento[0])
        },
        {
          text: deta.numDocSustento[0]
        }, {
          text: deta.fechaEmisionDocSustento[0],
        },
        {
          text: this.ejercicioFiscal(deta.fechaEmisionDocSustento[0])
        },
        {
          text: deta.baseImponible[0],
          alignment: 'right'
        },
        {
          text: this.impuestoNombre(deta.codigo[0]),

        },
        {
          text: deta.porcentajeRetener[0],
          alignment: 'right'
        },
        {
          text: deta.valorRetenido[0],
          alignment: 'right'
        },
      ];
      dd.content[2].table.body.push(det);
    }

    // llenar la informacion adicional
    if (infoAdicional !== undefined) {
      let cont = 0;
      for (const infad of infoAdicional) {
        cont++;
        let adicional: any;
        if (infoAdicional.length !== cont) {
          adicional = [{
            border: [true, false, true, false],
            text: infad.$.nombre + ': ' + infad._
          }];
        } else {
          adicional = [{
            border: [true, false, true, true],
            text: infad.$.nombre + ': ' + infad._
          }];
        }

        dd.content[3].columns[0][0].table.body.push(adicional);
      }
    }

    return dd;
  }

  obtenerNombreFormadePago(num: string) {

    switch (num) {
      case '01': {
        return 'SIN UTILIZACION DEL SISTEMA FINANCIERO';
      }
      case '15': {
        return 'COMPENSACIÓN DE DEUDAS ';
      }
      case '16': {
        return 'TARJETA DE DÉBITO';
      }
      case '17': {
        return 'DINERO ELECTRÓNICO';
      }
      case '18': {
        return 'TARJETA PREPAGO';
      }
      case '19': {
        return 'TARJETA DE CRÉDITO';
      }
      case '20': {
        return 'OTROS CON UTILIZACION DEL SISTEMA FINANCIERO';
      }
      case '21': {
        return 'ENDOSO DE TÍTULOS';
      }
      default: {
        return 'SIN UTILIZACION DEL SISTEMA FINANCIERO';
      }
    }
  }

  sumTotalDescuento(facturaDetalles) {
    let descuento = 0;
    for (const deta of facturaDetalles) {
      if (deta.descuento) {
        descuento += Number(deta.descuento[0]);
      }
    }
    return descuento;
  }

  subtotalDoce(impuestos): any {
    let imp: any;
    imp = {
      sub: 0,
      iva: 0
    };
    let descuento = 0;

    for (const val of impuestos.totalImpuesto) {
      if (val.codigo[0] === '2' && val.codigoPorcentaje[0] === '2') {
        if (val.descuentoAdicional !== undefined) {
          descuento = Number(val.descuentoAdicional[0]);
        }
        imp.sub = Number(val.baseImponible[0]) - Number(descuento);
        imp.iva = Number(val.valor[0]);
      }
    }
    imp.sub = imp.sub.toFixed(2);
    imp.iva = imp.iva.toFixed(2);
    return imp;
  }

  impuestos(impuestos, cod, porcentaje): any {
    let imp: any;
    imp = {
      sub: 0,
      iva: 0
    };

    for (const val of impuestos.totalImpuesto) {
      if (cod === '2') {
        if (val.codigo[0] === cod && val.codigoPorcentaje[0] === porcentaje) {
          imp.sub = Number(val.baseImponible[0]);
          imp.iva = Number(val.valor[0]);
        }
      } else {
        if (val.codigo[0] === cod) {
          imp.sub = Number(val.baseImponible[0]);
          imp.iva = Number(val.valor[0]);
        }
      }
    }
    imp.sub = imp.sub.toFixed(2);
    imp.iva = imp.iva.toFixed(2);
    return imp;
  }

  obtenerCodigoBarras(codigo) {
    const canvas = document.createElement('canvas');
    JsBarcode(canvas, codigo, { format: 'CODE128' });
    // console.log(canvas.toDataURL("image/png"));
    return canvas.toDataURL('image/png');
  }

  existeDireccion(infoFactura: any): string {
    try {
      let aux: any;
      aux = infoFactura.dirEstablecimiento[0];
      return aux;
    } catch (e) {
      return '';
    }
  }

  existeContribucion(infoFactura: any): string {
    try {
      let aux: any;
      aux = infoFactura.contribuyenteEspecial[0];
      return aux;
    } catch (e) {
      return '';
    }
  }

  existeGuiadeRemision(infoFactura: any): string {
    try {
      let aux: any;
      aux = infoFactura.guiaRemision[0];
      return aux;
    } catch (e) {
      return '';
    }
  }

  tipodeDocumento(cod): string {
    switch (cod) {
      case '01': {
        return 'FACTURA';
      }
      case '04': {
        return 'NOTA DE CRÉDITO';
      }
      case '05': {
        return 'NOTA DE DÉBITO';
      }
      case '06': {
        return 'GUÍA DE REMISIÓN';
      }
      case '07': {
        return 'COMPROBANTE DE RETENCIÓN';
      }
    }
  }

  ejercicioFiscal(fecha): string {
    return fecha.substr(-7);
  }

  impuestoNombre(cod): string {
    switch (cod) {
      case '1':
        return 'RENTA';
      case '2':
        return 'IVA';
      case '6':
        return 'ISD';

    }
  }

  totalRetenido(impuestos) {
    let total = 0;
    for (const imp of impuestos) {
      total += Number(imp.valorRetenido[0]);
    }
    return total.toFixed(2);
  }

}
