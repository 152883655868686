import { Component, OnInit } from '@angular/core';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { NomencierreperiodoService } from '../nomservicios/nomcierreperiodo.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { ConfirmationService, MessageService } from 'primeng';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
@Component({
  selector: 'app-nomcierreperiodo',
  templateUrl: './nomcierreperiodo.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NomcierreperiodoComponent implements OnInit {

  
  perCodigo = 0;
  periodoInicio = '';
  periodoFin= '';


  constructor(private init: NuevoComponentService,
    private cierrePeriodoSrv: NomencierreperiodoService,
    private auditoriagral: AuditoriagralService,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,) { }

  ngOnInit(): void {

    this.buscarPeriodo();
  }

  async buscarPeriodo(){

    await this.cierrePeriodoSrv.buscarPeriodo().then( per => {
       console.log(per);
      if( per !== null ){

        this.periodoInicio = this.convertDate( per[0].PERIODO_FECINI);
        this.periodoFin    = this.convertDate(per[0].PERIODO_FECFIN) ;
        this.perCodigo = per[0].PERIODO_CODIGO;
      }

    } ).catch( e => {
      console.error(e);
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    } );


  }

  convertDate(date: string) {
    const fecha = new Date( date );

    let month = '' + (fecha.getMonth() + 1);
    let day = '' + fecha.getDate();
    let year = fecha.getFullYear();

    if( month.length < 2 ) { month = '0' + month }
    if( day.length < 2 ) { day = '0' + day }

    return `${day}/${month}/${year}`;


  }

  async aceptar(){
    if(  this.perCodigo === 0 ){
      this.mensajeAlerta('error', 'Error', 'No existe periodo activo o en proceso');
      return;
    }

    if( await !this.funValidaExisteRegistroParaPeriodo()){
      return;
    }

    this.funGeneraCierrePeriodo();
  }

  async funGeneraCierrePeriodo(){
    console.log('funGeneraCierrePeriodo');
    
        await this.cierrePeriodoSrv.funGeneraCierrePeriodo(this.perCodigo).then( async () => {
          
          
          await this.auditoriagral.registrarAuditoria('NOM_CIERRE_PERIODO',
              this.perCodigo.toString(), 'A',
                '', '01', '', '', '', '').toPromise().then(() => {
                  this.funGlbActivaPeridioEnProceso();
              });
        } ).catch( e => {
          console.error(e);
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
        } );
    


   
  }

  cancelar(){
    this.init.tabs.splice(this.init.tabindex, 1);
  }


  manejarSenales(valor){
    switch (valor) {
      case 'Salir':
       this.init.tabs.splice(this.init.tabindex, 1);
      break;
    
    }
  }


  async funValidaExisteRegistroParaPeriodo(){
    return await this.cierrePeriodoSrv.funValidaExisteRegistroParaPeriodo(this.perCodigo).then( res=> {
      if( res === null || res.length === 0 ){
        this.mensajeAlerta('error', 'Error', 'Error no se han generado registros para este periodo');
        return false;
      }
      return true;
    } ).catch( e => {

      console.error(e);
      this.mensajeAlerta('error', 'Error', 'Error no se han generado registros para este periodo');
      return false;
    } );
  }


  async funGlbActivaPeridioEnProceso(){
    await this.cierrePeriodoSrv.funGlbActivaPeridioEnProceso().then( () => {
      this.mensajeAlerta('success', 'Información', 'Proceso terminó satisfactoriamente');
    } ).catch( e => {
      console.error(e);
      this.mensajeAlerta('error', 'Error', 'Hubo un problema activando el siguiente período');
    } );
  }

  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'generaCierre',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
  }

}
