<p-toast position="center" key="c" (onClose)="onReject()" [modal]="true" [baseZIndex]="5000">
	<ng-template let-message pTemplate="message">
		<div style="text-align: center">
			<i class="pi pi-exclamation-triangle" style="font-size: 3em"></i>
			<h3>{{message.summary}}</h3>
			<p>{{message.detail}}</p>
		</div>
		<div class="ui-g ui-fluid">
			<div class="ui-g-6">
				<button type="button" pButton label="SI" class="ui-button-success"></button>
			</div>
			<div class="ui-g-6">
				<button type="button" pButton (click)="onReject()" label="NO" class="ui-button-secondary"></button>
			</div>
		</div>
	</ng-template>
</p-toast>
<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="message"></p-toast>
<div style="width: 100%;">
	<p-table [autoLayout]="true" [reorderableColumns]="true" selectionMode="single" [columns]="cols"
			 [value]="categorias" editMode="row" [paginator]="true" [rows]="6" dataKey="CAT_CODIGO">
		<ng-template pTemplate="header" let-columns>
			<tr height="50px" width="100px">
				<th *ngFor="let col of columns" [pSortableColumn]="col.field">
					{{col.header}}
					<p-sortIcon [field]="col.field"></p-sortIcon>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-rowData let-rowData2 let-editing="editing" let-ri="rowIndex">
			<tr (click)="irPantalla(rowData)" [ngStyle]="{'height':'80px'}" [pEditableRow]="rowData">
				<td>
					{{rowData.CAT_CODIGO}}
				</td>
				<td style="width: 150px">
					<p-cellEditor>
						<ng-template pTemplate="input">
							<input pInputText type="text" [(ngModel)]="rowData.CAT_NOMBRE" maxlength="50"
								   [ngStyle]="{'width':'100%'}">
						</ng-template>
						<ng-template pTemplate="output">
							{{rowData.CAT_NOMBRE}}
						</ng-template>
					</p-cellEditor>
				</td>
				<td>
					<p-cellEditor>
						<ng-template pTemplate="input">
							<input pInputText type="text" [(ngModel)]="rowData.CAT_CORTO" maxlength="10"
								   [ngStyle]="{'width':'100%'}">
						</ng-template>
						<ng-template pTemplate="output">
							{{rowData.CAT_CORTO}}
						</ng-template>
					</p-cellEditor>
				</td>
				<td style="text-align:center">
					<button *ngIf="!editing" pButton type="button" pInitEditableRow icon="pi pi-pencil"
							class="ui-button-info"
							style="margin-right: .5em" (click)="onRowEditInit(rowData)"
							title="Editar Categoria"></button>
					<button *ngIf="editing" pButton type="button" pSaveEditableRow icon="pi pi-check"
							class="ui-button-success"
							style="margin-right: .5em" (click)="onRowEditSave(rowData)"
							title="Modificar Categoria"></button>
					<button *ngIf="editing" pButton type="button" pCancelEditableRow icon="pi pi-replay"
							class="ui-button-warning"
							(click)="onRowEditCancel()" style="margin-right: .5em"></button>
					<button *ngIf="editing" type="button" pButton (click)="showConfirm(rowData, ri)" pCancelEditableRow
							icon="pi pi-times" class="ui-button-danger" title="Eliminar Categoria"></button>

				</td>
			</tr>
		</ng-template>

		<ng-template pTemplate="summary" let-rowData>
			<div style="text-align:left">
				<button type="button" pButton icon="pi pi-star" (click)="showDialogToAdd()"
						label="Añadir nueva Categoria"></button>
			</div>
		</ng-template>
	</p-table>
</div>
<p-dialog (keyup.enter)="save()" header="Detalle Categoria" [(visible)]="displayDialog" [responsive]="true"
		  [modal]="false"
		  [style]="{width: '500px'}">
	<div class="ui-g ui-fluid" *ngIf="categoria">
		<div class="ui-g-12">
			<div class="ui-g-4">
				<label for="CAT_CODIGO">Código Categoria</label>
			</div>
			<div class="ui-g-8">
				<input pInputText id="CAT_CODIGO" [(ngModel)]="categoria.CAT_CODIGO" maxlength="30"/>
			</div>
		</div>
		<div class="ui-g-12">
			<div class="ui-g-4">
				<label for="CAT_NOMBRE">Nombre Categoria</label>
			</div>
			<div class="ui-g-8">
				<input pInputText id="CAT_NOMBRE" [(ngModel)]="categoria.CAT_NOMBRE" maxlength="50"/>
			</div>
		</div>
		<div class="ui-g-12">
			<div class="ui-g-4">
				<label for="CAT_CORTO">Nombre Corto</label>
			</div>
			<div class="ui-g-8">
				<input pInputText id="CAT_CORTO" [(ngModel)]="categoria.CAT_CORTO" maxlength="10"/>
			</div>
		</div>
	</div>
	<p-footer>
		<div class="ui-dialog-buttonpane ui-helper-clearfix">
			<button type="button" pButton icon="pi pi-check" (click)="save()" label="Save"></button>
		</div>
	</p-footer>
</p-dialog>

<p-confirmDialog appendTo="@(body)" [autoZIndex]="true" [closable]="true" [transitionOptions]="'0ms'"
				 [style]="{width:'50vw'}"></p-confirmDialog>
