import { Component, OnInit ,ViewChildren,QueryList, ViewChild} from '@angular/core';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import { AggridBanComponent } from 'src/app/ban/aggrid-ban/aggrid-ban.component';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { Venmaeprovincias, Venmaecanton,Venmaeparroquia } from '../veninterfaces/venmaeprovincias';
import { VenmaeprovinciasService } from '../venservicios/venmaeprovincias.service';
@Component({
  selector: 'app-venmaeprovincias',
  templateUrl: './venmaeprovincias.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class VenmaeprovinciasComponent implements OnInit {
  @ViewChildren(AggridsaciComponent) aggrid=new QueryList<AggridsaciComponent>();
  @ViewChild('tabla') table:AggridsaciComponent;
  btnGuardar: boolean;
  btnNuevo = true;
  btnBorrar: boolean;
  btnRegresar: boolean;
  indexTab = 0;
  visibleGrid = false;
  visibleGrid2 = false;
  disabledTab1 = true;
  disabledTab2 = true;
  colorColumna = '#C2F8F8';
  /*Provincias*/
  defaultColDefVenmaeprovincias;
  public frameworkComponents;
  rowStyle;
  largo = '258';
  rowSelection = 'multiple';
  venmaeprovincias: Venmaeprovincias[] = [];
  selectedVenmaeprovincias: Venmaeprovincias;
  /*Cantones*/
  largot='290';
  largotb='300';
  defaultColDefVenmaecanton;
  venmaecanton: Venmaecanton[] = [];//selectedVenmaeprovincias,venmaecanton
  selectedVenmaecanton: Venmaecanton;
  /*Parroquias*/ 
  defaultColDefVenmaeparroquias;
  venmaeparroquia: Venmaeparroquia[] = [];
  selectedVenmaeparroquia: Venmaeparroquia;
  columnDefVenmaeprovincias=[
    {
      headerName: '', field: 'NUMERO', editable: false, width: 50,cellStyle: { background: this.colorColumna}
    },
    {
      headerName: 'Código.', field: 'PROV_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEdit(params.data) ;
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas:false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Nombre', field: 'PROV_NOMBRE', cellEditor: 'cellPrueba', width: 400, editable:true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 30,
            tienecalculos: false,
          }
        }
    }
  ];
  columnDefVenmaeCantones=[
    {
      headerName: '', field: 'NUMERO', editable: false, width: 50,cellStyle: { background: this.colorColumna}
    },
    {
      headerName: 'Código.', field: 'CAN_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEditc(params.data) ;
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas:false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Nombre', field: 'CAN_DESCRIPCION', cellEditor: 'cellPrueba', width: 400, editable:true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 30,
            tienecalculos: false,
          }
        }
    }
  ];
  columnDefVenParroquias=[
    {
      headerName: '', field: 'NUMERO', editable: false, width: 50, cellStyle: { background: this.colorColumna}
    },
    {
      headerName: 'Código.', field: 'PARR_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEditpa(params.data) ;
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas:false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Nombre', field: 'PARR_DESCRIPCION', cellEditor: 'cellPrueba', width: 400, editable:true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 30,
            tienecalculos: false,
          }
        }
    }
  ];
  constructor(
    private venmaeprovinciasService:VenmaeprovinciasService,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private confirmationService: ConfirmationService,
    private auditoriaGralService: AuditoriagralService,
    private init: NuevoComponentService) { 
      this.agTable();
    }

  ngOnInit(): void {
    this.btnNuevo =  false;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.btnRegresar = false;
   
    this.venmaeprovinciasService.consultaProvincia().then(res=>{
      
      this.venmaeprovincias=res;
      this.venmaeprovincias.map(x=>{
        x.NUEVO=false;
        x.NUMERO = this.venmaeprovincias.indexOf( x ) + 1;
      })
    }).catch(e=>console.log(e));
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefVenmaeprovincias = {
      editable: true,
      width: 130,
      objeto: 'maeConsultaTipoRef'
    };
    this.defaultColDefVenmaecanton = {
      editable: true,
      width: 130,
      objeto: 'venmaecantont'
    };
    this.defaultColDefVenmaeparroquias = {
      editable: true,
      width: 130,
      objeto: 'venmaeparroquiat'
    };
  }
  manejarSenales(valor){ 
    switch (valor) {
      case 'Nuevo':
        this.nuevoModulotabs();
        break;
      case 'Guardar':
        this.opcionGuardartabs();
        break;
      case 'Borrar':
        this.opcionEliminarTabs();
        break;
      case 'Cancelar':
        this.cargar();
        break;
      case 'Buscar':
        //this.buscarRegistro();
        break;
      case 'Salir':
       this.init.tabs.splice(this.init.tabindex, 1);
        break;
    
    }
  }
  selectVenmaeprovincias(elemento){
    if (elemento.object !== null) {
     
      this.selectedVenmaeprovincias=elemento.object;
      if(this.selectedVenmaeprovincias.PROV_CODIGO!='' && this.selectedVenmaeprovincias.PROV_NOMBRE!=''){
        this.disabledTab1=false;    
      this.venmaeprovinciasService.consultaCanton(this.selectedVenmaeprovincias.PROV_CODIGO).then(res=>{
        this.venmaecanton=res;
        try{
          this.venmaecanton.map(x=>{
            x.NUMERO = this.venmaecanton.indexOf( x ) + 1;
            x.NUEVO=false;
          });
        }catch{
          this.venmaecanton=[];
        }
       
        this.visibleGrid=true;
      });
      }else{
        this.disabledTab1=true;
      }
      
    
      
    }

  }
  isEdit(param){
    if(this.selectedVenmaeprovincias.NUEVO==false){
      return false;
    }else if(this.selectedVenmaeprovincias.NUEVO==true){
      return true;
    }
  }
  isEditc(params){
    if(this.selectedVenmaecanton.NUEVO==false){
      return false;
    }else if(this.selectedVenmaecanton.NUEVO==true){
      return true;
    }
  }
  isEditpa(params){
    if(this.selectedVenmaeparroquia.NUEVO==false){
      return false;
    }else if(this.selectedVenmaeparroquia.NUEVO==true){
      return true;
    }
  }
  
  onTab1Change(event) {
    //this.maeProvinciaService.initBotones();
    if (this.indexTab === 0) {
      //this.visibleGrid = true;
      
      this.disabledTab1 = true;
      this.disabledTab2 = true;
      this.venmaeparroquia=[];
      this.venmaecanton=[];
      this.visibleGrid=false;
      this.visibleGrid2=false;
      this.butonRecar();
    } else if(this.indexTab === 1) {
      this.butonRecar();
      this.venmaeparroquia=[];
      //this.visibleGrid = false;
      this.disabledTab2 = true;
      if(this.selectedVenmaeprovincias.PROV_CODIGO!='' && this.selectedVenmaeprovincias.PROV_NOMBRE!=''){
        this.disabledTab1=false;
        
      this.venmaeprovinciasService.consultaCanton(this.selectedVenmaeprovincias.PROV_CODIGO).then(res=>{
        
        this.venmaecanton=res;
        try{
          this.venmaecanton.map(x=>{
            x.NUMERO = this.venmaecanton.indexOf( x ) + 1;
            x.NUEVO=false;
          });
        }catch{
          this.venmaecanton=[];
        }
       
        this.visibleGrid=true;
      });
      }else{
        this.disabledTab1=true;
      }
    
    
      this.visibleGrid2=false;
      
      
      
    }else if(this.indexTab === 2){
      this.visibleGrid=false;
      this.butonRecar();
    }
  }
  cambioModulo(elemento){
    this.btnGuardar = false;
    this.btnRegresar = false;
    this.btnNuevo = true;
  }
  opcionNuevo(){
    this.btnNuevo = true;
    this.btnBorrar = false;
    this.btnRegresar = false;
    //this.aggrid.isEditable(true);
    this.nuevoModulotabs();
  }
  
  nuevoModulotabs() {
    console.log(this.aggrid.toArray());
    console.log(this.table);
    if (this.indexTab === 0) {
      this.butonRecar();
      //this.aggrid.toArray()[0].isEditable(true);
      const newModulo:  Venmaeprovincias= {  
        PROV_CODIGO:'',
        PROV_NOMBRE:'',
        NUMERO:this.venmaeprovincias.length +1,
        NUEVO:true
      };
      try{
        this.venmaeprovincias.push(newModulo); 
        this.aggrid.toArray()[0].refreshaggrid(this.venmaeprovincias, this.defaultColDefVenmaeprovincias.objeto ); 
        const indice= this.venmaeprovincias.indexOf(newModulo);
        this.aggrid.toArray()[0].gridApi.setFocusedCell(indice , 'PROV_CODIGO');
      }catch(err){
        console.log(err)
      }  
    }else if (this.indexTab === 1){
      this.butonRecar();
      //this.aggrid.toArray()[1].isEditable(true);
      const newModulo:  Venmaecanton= {  
        CAN_CODIGO:'',
        COM_CODIGO:'',
        CAN_DESCRIPCION:'',
        NUEVO:true,
        NUMERO:this.venmaecanton.length+1,
      };
      try{
        this.venmaecanton.push(newModulo); 
        this.aggrid.toArray()[1].refreshaggrid(this.venmaecanton, this.defaultColDefVenmaecanton.objeto ); 
        const indice= this.venmaecanton.indexOf(newModulo);
        this.aggrid.toArray()[1].gridApi.setFocusedCell(indice , 'CAN_CODIGO');
      }catch(err){
        console.log(err)
      }  
    }else if (this.indexTab === 2){
      this.butonRecar();
      //this.table.isEditable(true);
      const newModulo:  Venmaeparroquia= {  
        PARR_CODIGO:'',
        COM_CODIGO:'',
        PARR_DESCRIPCION:'',
        NUMERO:this.venmaeparroquia.length +1,
        NUEVO:true
      };
      try{
        this.venmaeparroquia.push(newModulo); 
        this.table.refreshaggrid(this.venmaeparroquia, this.defaultColDefVenmaeparroquias.objeto ); 
        const indice= this.venmaeparroquia.indexOf(newModulo);
        this.table.gridApi.setFocusedCell(indice , 'PARR_CODIGO');
      }catch(err){
        console.log(err)
      }  
    }
 
  }


  selectMaeciudad(elemento){
    if (elemento.object !== null) {
      this.selectedVenmaecanton=elemento.object;
      if(this.selectedVenmaecanton.CAN_CODIGO!='' && this.selectedVenmaecanton.CAN_DESCRIPCION!=''){
        this.disabledTab2=false;
        this.venmaeprovinciasService.consultaParroquia(this.selectedVenmaecanton.CAN_CODIGO).then(res=>{
          this.venmaeparroquia=res;
          try{
            this.venmaeparroquia.map(x=>{
              x.NUMERO = this.venmaeparroquia.indexOf( x ) + 1;
              x.NUEVO=false;
            });
          }catch{
            this.venmaeparroquia=[];
          }
       
          this.visibleGrid2=true;
        });
      }
    
    }
  }
  cambioCiudad(elemento){
    this.btnGuardar = false;
    this.btnRegresar = false;
    this.btnNuevo = true;
  }
  selectMaeParroquia(elemento){
    if (elemento.object !== null) {
      this.selectedVenmaeparroquia=elemento.object;
    }
  }
  cambioParroquia(elemento){
    this.btnGuardar = false;
    this.btnRegresar = false;
    this.btnNuevo = true;
  }
  opcionGuardartabs(){
    if (this.indexTab === 0) {
      this.opcionGuardartab();
    }else if (this.indexTab === 1){
      this.opcionGuardarTab1();
    }else if (this.indexTab === 2){
      this.opcionGuardarTab2();
    }
  }
  opcionGuardartab(){
    const modulo: Venmaeprovincias = {
        PROV_CODIGO:  this.selectedVenmaeprovincias.PROV_CODIGO,
        PROV_NOMBRE: this.selectedVenmaeprovincias.PROV_CODIGO,
       };
       let valido = true;
      
    for ( const key in modulo ) { 
      if ( modulo[key] === null || modulo[key] === '') {
        valido = false;
      }
    }
    if ( valido ) {
      if(this.selectedVenmaeprovincias.NUEVO==true){
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefVenmaeprovincias.objeto].stopEditing();
        this.venmaeprovinciasService.insertarProvicia(this.selectedVenmaeprovincias).then(res=>{
          this.mensajeAlerta('success', 'Información', '¡Provincia agregada!');
          this.cargar();
          this.auditoriaGralService.registrarAuditoria('VEN_MAEPROVINCIA', String(this.selectedVenmaeprovincias.PROV_CODIGO), 'I',
          '', '01', '', '', '', '').toPromise().then(() => {
          });
        }).catch(e=>{
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        }); 
      }else if(this.selectedVenmaeprovincias.NUEVO==false){
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefVenmaeprovincias.objeto].stopEditing();
        this.venmaeprovinciasService.actualizarProvincia(this.selectedVenmaeprovincias).then(res=>{
          this.mensajeAlerta('success', 'Información', '¡Provincia actualizada!');
          this.cargar();
          this.auditoriaGralService.registrarAuditoria('VEN_MAEPROVINCIA', String(this.selectedVenmaeprovincias.PROV_CODIGO), 'A',
          '', '01', '', '', '', '').toPromise().then(() => {
          });
        }).catch(e=>{
          let mensaje: string;
          console.log(e);
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        }); 
      }
    }else{
      this.mensajeAlerta('info', 'Información', 'Se han encontrado campos vacíos');
    }
  }
  opcionGuardarTab1(){
    const modulo: Venmaecanton = {
      CAN_CODIGO:  this.selectedVenmaecanton.CAN_CODIGO,
      CAN_DESCRIPCION: this.selectedVenmaecanton.CAN_DESCRIPCION,      
     };
     let valido = true;
    
    for ( const key in modulo ) { 
      if ( modulo[key] === null || modulo[key] === '') {
        valido = false;
      }
    }
    console.log(this.selectedVenmaecanton);
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
    this.defaultColDefVenmaecanton.objeto].stopEditing();
    if ( valido ) {
      let audi=`${this.selectedVenmaeprovincias.PROV_CODIGO}/${this.selectedVenmaecanton.CAN_CODIGO}`;
      if(this.selectedVenmaecanton.NUEVO==true){
       
        this.venmaeprovinciasService.insertarCanton(this.selectedVenmaecanton, this.selectedVenmaeprovincias.PROV_CODIGO).then(res=>{
          this.mensajeAlerta('success', 'Información', '¡Cantón agregado!');
          this.cargar();
          this.auditoriaGralService.registrarAuditoria('VEN_MAECANTON', String(audi), 'I',
          '', '01', '', '', '', '').toPromise().then(() => {
          });
        }).catch(e=>{
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        }); 
      } else if(this.selectedVenmaecanton.NUEVO==false){
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefVenmaecanton.objeto].stopEditing();
        this.venmaeprovinciasService.actualizarCanton(this.selectedVenmaecanton, this.selectedVenmaeprovincias.PROV_CODIGO).then(res=>{
          this.mensajeAlerta('success', 'Información', '¡Cantón actualizado!');
          this.cargar();
          this.auditoriaGralService.registrarAuditoria('VEN_MAECANTON', String(audi), 'A',
          '', '01', '', '', '', '').toPromise().then(() => {
          });
        }).catch(e=>{
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        }); 
      } 
    }else{
      this.mensajeAlerta('info', 'Información', 'Se han encontrado campos vacíos en Cantón');
    }
  }
  opcionGuardarTab2(){
    const modulo:  Venmaeparroquia = {
      PARR_CODIGO:  this.selectedVenmaeparroquia.PARR_CODIGO,
      PARR_DESCRIPCION: this.selectedVenmaeparroquia.PARR_DESCRIPCION,
     };
     let valido = true;
    
    for ( const key in modulo ) { 
      if ( modulo[key] === null || modulo[key] === '') {
        valido = false;
      }
    }
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this. defaultColDefVenmaeparroquias.objeto].stopEditing();
    if ( valido ) {
      let audi=`${this.selectedVenmaecanton}/${this.selectedVenmaeparroquia}`;
      if(this.selectedVenmaeparroquia.NUEVO==true){
        this.venmaeprovinciasService.insertarParroquia(this.selectedVenmaeparroquia,this.selectedVenmaecanton.CAN_CODIGO).then(res=>{
          this.mensajeAlerta('success', 'Información', '¡Parroquia agregada!');
          this.cargar();
          this.auditoriaGralService.registrarAuditoria('VEN_MAEPARROQUIA', String(audi), 'I',
          '', '01', '', '', '', '').toPromise().then(() => {
          });
        }).catch(e=>{
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        }); 
      }else if(this.selectedVenmaecanton.NUEVO==false){
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this. defaultColDefVenmaeparroquias.objeto].stopEditing();
        this.venmaeprovinciasService.actualizarParroquia(this.selectedVenmaeparroquia,this.selectedVenmaecanton.CAN_CODIGO).then(res=>{
          this.mensajeAlerta('success', 'Información', '¡Parroquia actualizada!');
          this.cargar();
          this.auditoriaGralService.registrarAuditoria('VEN_MAEPARROQUIA', String(audi), 'A',
          '', '01', '', '', '', '').toPromise().then(() => {
          });
        }).catch(e=>{
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        }); 
      }  
    }else{
      this.mensajeAlerta('info', 'Información', 'Se han encontrado campos vacíos en Parroquia');
    }
  }
  opcionEliminarTabs(){
    if (this.indexTab === 0) {
      this.opcionEliminar();
    }else if (this.indexTab === 1){
      this.opcionEliminarTab1();
    }else if (this.indexTab === 2){
      this.opcionEliminarTab2();
    }
  }
  opcionEliminar(){
    if ( this.selectedVenmaeprovincias !== undefined ) {
      let audi=`${this.selectedVenmaeprovincias.PROV_CODIGO}`
      this.confirmationService.confirm({
        message: '¿Está seguro que desea eliminar esta provincia?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'venmaeProvDialog',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {
          this.venmaeprovinciasService.eliminarProvincia(this.selectedVenmaeprovincias).then(res=>{
            this.mensajeAlerta('success', 'Información', '¡Provincia eliminado!');
            this.cargar();
            this.auditoriaGralService.registrarAuditoria('VEN_MAEPROVINCIA', String(audi), 'E',
            '', '01', '', '', '', '').toPromise().then(() => {
            });
          }).catch(e=>{
            let mensaje: string;
            console.log(e);
            mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
            this.mensajeAlerta('error', 'Error', mensaje);
          }); 
        },
        reject: () => {
        }
      })
    }
  }
  opcionEliminarTab1(){
    if ( this.selectedVenmaecanton !== undefined ) {
      let audi=`${this.selectedVenmaeprovincias.PROV_CODIGO}/${this.selectedVenmaecanton.CAN_CODIGO}`
      this.confirmationService.confirm({
        message: '¿Está seguro que desea eliminar el cantón seleccionado?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'venmaeProvDialog',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {
          this.venmaeprovinciasService.eliminarCanton(this.selectedVenmaecanton,this.selectedVenmaeprovincias.PROV_CODIGO).then(res=>{
            this.mensajeAlerta('success', 'Información', '¡Cantón eliminado!');
            this.cargar();
            this.auditoriaGralService.registrarAuditoria('VEN_MAECANTON', String(audi), 'E',
            '', '01', '', '', '', '').toPromise().then(() => {
            });
          }).catch(e=>{
            console.log(e);
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
            this.mensajeAlerta('error', 'Error', mensaje);
          }); 
        },
        reject: () => {
        }
      })
    }
  }
  opcionEliminarTab2(){
    if ( this.selectedVenmaeparroquia !== undefined ) {
      let audi=`${this.selectedVenmaecanton.CAN_CODIGO}/${this.selectedVenmaeparroquia.PARR_CODIGO}`;
      this.confirmationService.confirm({
        message: '¿Está seguro que desea eliminar la parroquia seleccionada?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'venmaeProvDialog',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {
          this.venmaeprovinciasService.eliminarParroquia(this.selectedVenmaeparroquia,this.selectedVenmaecanton.CAN_CODIGO).then(res=>{
            this.mensajeAlerta('success', 'Información', '¡Parroquia eliminado!');
            this.cargar();
            this.auditoriaGralService.registrarAuditoria('VEN_MAEPARROQUIA', String(audi), 'E',
            '', '01', '', '', '', '').toPromise().then(() => {
            });
          }).catch(e=>{
            console.log(e);
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
            this.mensajeAlerta('error', 'Error', mensaje);
          }); 
        },
        reject: () => {
        }
      })
    }
  }
  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'venmaeProvtoast',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
    
  }
  cargar(){
    this.btnNuevo =  false;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.btnRegresar = false;
    
    if (this.indexTab === 0) {
      this.venmaeprovinciasService.consultaProvincia().then(res=>{
        this.venmaeprovincias=res;
        this.venmaeprovincias.map(x=>{
          x.NUEVO=false;
          x.NUMERO = this.venmaeprovincias.indexOf( x ) + 1;
        });
        this.selectedVenmaeprovincias={};
        this.disabledTab1=true;
        this.disabledTab2=true;
      }).catch(e=>console.log(e));
    }else if (this.indexTab === 1){
      this.venmaeprovinciasService.consultaCanton(this.selectedVenmaeprovincias.PROV_CODIGO).then(res=>{
        this.venmaecanton=res;
        this.venmaecanton.map(x=>{
          x.NUEVO=false;
          x.NUMERO = this.venmaecanton.indexOf( x ) + 1;
        });
        this.selectedVenmaecanton={};
        this.disabledTab2=true;
      }).catch(e=>console.log(e));
    }else if (this.indexTab === 2){
      this.venmaeprovinciasService.consultaParroquia(this.selectedVenmaecanton.CAN_CODIGO).then(res=>{
        this.venmaeparroquia=res;
        this.venmaeparroquia.map(x=>{
          x.NUEVO=false;
          x.NUMERO = this.venmaeparroquia.indexOf( x ) + 1;
        });
        this.selectedVenmaeparroquia={};
      }).catch(e=>console.log(e));
    }  
  }
  butonRecar(){
    this.btnNuevo =  false;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.btnRegresar = false;
  }

}
