import { Component, OnInit } from '@angular/core';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { VenmaeclienteService } from '../venservicios/venmaecliente.service';
import { MessageService, SelectItem } from 'primeng';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { GenerarpedidosService } from 'src/app/ven/venservicios/generarpedidos.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-generarpedidos',
  templateUrl: './generarpedidos.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class GenerarpedidosComponent implements OnInit {

  cmbtipo: any[];
  selecttipo: any;

  fecha = new Date();
  concepto: string = '';
  grupo: string = '';
  nombregrupo: string = '';
  chkprecio: boolean;
  cantidad: number;

  // busqueda
  opcionbusqueda: string;
  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];
  tabla: string;
  where: string;

  // dialogs
  displayDialogBusquedaFast: boolean;
  displayDialogAutorizacion: boolean;

  // autorizacion
  claveAuth: string;
  autorizacionCodigo: string;
  bolAutorizacion: boolean;

  strNumActual = "";
  strNemonico = "";
  strSerie = "";
  strGrupo = "";

  constructor(private genpedservice: GenerarpedidosService, private busqService: InvbusquedaService,
    private init: NuevoComponentService, private cliService: VenmaeclienteService,
    private messageService: MessageService, private auditoriagral: AuditoriagralService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.llenarcombos();
  }

  async llenarcombos() {
    const data = await this.cliService.getreferenciatisecl();
    this.cmbtipo = [{ codigo: '', name: '' }];
    this.selecttipo = { codigo: '', name: '' };
    for (const rs of data) {
      this.cmbtipo.push({ codigo: rs.REF_NOMBRE, name: rs.REF_NOMBRE });
    }
  }

  manejarSenales(valor) {
    if (valor === 'Visto') {
      this.aceptar();
    }
    if (valor === 'Salir') {
      this.salir();
    }
  }

  setFocus(event) {    
    switch (event.target.id) {
      case 'GRUPO': {
        this.busquedagrupo(event.target.value);
        break;
      }
    }
  }

  botonesmodificar() {

  }

  aceptar() {
    if (this.selecttipo.name === '') {
      this.messageService.add({
        key: 'genped',
        severity: 'error',
        summary: 'Error',
        detail: 'Ingrese el tipo'
      });
      return;
    }
    if (this.concepto === '') {
      this.messageService.add({
        key: 'genped',
        severity: 'error',
        summary: 'Error',
        detail: 'Ingrese un concepto'
      });
      return;
    }
    if (this.grupo === '') {
      this.messageService.add({
        key: 'genped',
        severity: 'error',
        summary: 'Error',
        detail: 'Ingrese el grupo de clientes'
      });
      return;
    }
    if (this.cantidad === 0) {
      this.messageService.add({
        key: 'genped',
        severity: 'error',
        summary: 'Error',
        detail: 'La cantidad de pedidos a generar es 0'
      });
      return;
    }
    this.busquedanumeracion();
  }

  async busquedagrupo(parametro) {
    if (parametro === null) {
      parametro = '';
    }
    this.opcionbusqueda = 'grupo';
    this.types = [
      { label: 'Código', value: 'GRU_CODIGO' },
      { label: 'Nombre', value: 'GRU_NOMBRE' },
      { label: 'Cta. Contable', value: 'CON_CODIGO' },
      { label: 'Dscto', value: 'CON_CODIGODES' },
      { label: 'Porce. Dscto', value: 'GRU_PORDES' },
      { label: 'Cnt. Costos', value: 'GRU_PORDES' }
    ];
    this.busquedacampos = [parametro, '', '', '', '', ''];
    this.tabla = 'VEN_MAEGRUPO';
    this.where = 'GRU_TIPO = \'CLI\'';

    if (parametro !== '' && parametro !== undefined && parametro !== null) {
      const eR = await this.genpedservice.erpBusquedaGrupo(this.tabla, this.types[0].value +
        ' LIKE \'' + parametro + '\' AND ' + this.where);
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
          return;
        }
      }
    }

    this.busqService.busquedaVenMaeGrupo2(parametro).subscribe((datos: any[]) => {
        this.arregloCons = datos;
        this.displayDialogBusquedaFast = true;
      });
  }

  manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'grupo') {
      this.grupo = opcion.GRU_CODIGO;
      this.nombregrupo = opcion.GRU_NOMBRE;
      this.consultarCantidad();
    }
    if (this.opcionbusqueda === 'numeracion') {
      this.generarPedidosPrev(opcion);
    }
    this.displayDialogBusquedaFast = false;
  }

  salir() {
    this.init.tabs.splice(this.init.tabindex, 1);
  }

  async consultarCantidad() {
    const data = await this.genpedservice.consultarcantidad(this.grupo, this.selecttipo.name);

    for (let rs_tipo of data) {
      this.cantidad = Number(rs_tipo.CANTIDAD);
    }
  }

  busquedanumeracion() {
    this.opcionbusqueda = 'numeracion';
    this.types = [
      { label: 'Descripción', value: 'NUM_CLASE' },
      { label: 'Nemónico', value: 'NUM_NEMONICO' },
      { label: 'Serie', value: 'NUM_SERIE' },
      { label: 'Actual', value: 'NUM_SECACTUAL' }
    ];
    this.busquedacampos = ['', '', '', ''];
    this.tabla = 'SACI_MAENUMERACION';
    this.where = 'NUM_DOCREF=\'PED\' OR NUM_DOCREF = \'COT\'';

    this.busqService.busquedaSaciMaeNumeracion3().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  generarPedidosPrev(itemNum) {

    // TODO Auto-generated method stub    
    let size = itemNum.NUM_SECACTUAL.length > 0 ? 1 : 0;
    if (size > 0) {
      this.strNumActual = itemNum.NUM_SECACTUAL;
      this.strNemonico = itemNum.NUM_NEMONICO;
      this.strSerie = itemNum.NUM_SERIE;
      this.strGrupo = itemNum.NUM_CLASE;
    } else {
      this.messageService.add({
        key: 'genped',
        severity: 'warn',
        summary: 'Información',
        detail: 'Debe escoger una numeración'
      });
      return;
    }

    this.claveAuth = '';
    this.autorizacionCodigo = 'TRAPED';
    this.displayDialogAutorizacion = true;
  }

  async generarPedidos() {
    var strFechaEmision = this.datePipe.transform(this.fecha, 'yyyy-MM-dd');

    try {
      await this.genpedservice.generarpedidos(this.selecttipo.name, strFechaEmision, this.concepto,
        this.grupo, this.strGrupo, this.strNemonico, this.strSerie, this.strNumActual, this.chkprecio == true ? '1' : '0');

      this.messageService.add({
        key: 'genped',
        severity: 'success',
        summary: 'Información',
        detail: 'Proceso Finalizado'
      });
    } catch (e) {
      this.messageService.add({
        key: 'genped',
        severity: 'error',
        summary: 'Error',
        detail: '! Proceso generar pedidos no terminó satisfactoriamente.... !'
      });
    }
  }

  comprobarClave() {
    this.genpedservice.obtenerClave(this.autorizacionCodigo).subscribe((clave) => {
      let claveReal;
      claveReal = clave[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        this.accionesAutorizacion();
      } else if (clave !== this.claveAuth) {
        this.messageService.add({
          key: 'autorizacion',
          severity: 'error',
          summary: 'Clave Autorización',
          detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
        });
      }
    });
  }

  accionesAutorizacion() {
    if (this.autorizacionCodigo === 'TRAPED') {
      this.generarPedidos();
      this.displayDialogAutorizacion = false;
    }
  }

  onHideAutorizacion() {
    if (this.bolAutorizacion === false) {
      if (this.autorizacionCodigo === 'TRAPED') {
        this.messageService.add({
          key: 'genped',
          severity: 'error',
          summary: 'Información',
          detail: 'Debe ingresar correctamente'
            + ' la clave para generar los pedidos.'
        });
      }
    }
  }
}
