<p-toast [style]="{marginTop: '30px'}" position="top-center" key="rolCerado"></p-toast>
<!-- CDPJ -->
<div class="layout-config">
    <div class="layout-config-content-wrapper">
        <a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones($event)">
            <i class="pi pi-bars" style="cursor: pointer"></i>
        </a>
        <p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
            [dismissible]="true" [showCloseIcon]="false">
            <br>
            <br>
            <br>
            <p-tabView>
                <p-tabPanel header="Acciones">
                    <div class="p-grid">
                        <p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 200px)'}">
                            <div class="p-col-12">
                                <i class="pi pi-caret-right archivoOpc"></i><span (click)="cambiarEstado()"
                                    class="archivoOpc">Cambiar Estado</span>
                            </div>
                        </p-scrollPanel>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </p-sidebar>
    </div>
</div>


<p-dialog header="Clave Autorización" [(visible)]="claveDialog" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: 'auto'} ">
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							(keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>
<!-- CDPJ -->
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="true" [botonGuardar]="true" [botonRegresar]="false"
						 [botonBorrar]="btonEliminar"
						 [botonAnterior]="botonAnterior" [botonPrimero]="botonPrimero"
                         [botonUltimo]="botonUltimo"
                         [botonAsiento]="false"
						 [botonSiguiente]="botonSiguiente" [barraBotones3]="true" [barraConEncasil]="true"
                          [botonBuscar]="btnBuscar" [botonBinocular]="true" [botonAsiento]="false"
                          [botonAlmacenardoc] = "true"
                          (signal)="manejarSenales($event)">
		</app-invmaebarra>
	</div>
</div>
<div class="divgrups">
    <div class="p-grid">
        <div class="p-col-2">
            <span style="margin-left: 20px; color: blue;">
                {{lblENCROLINDI_CODIGO}}
            </span>
        </div>
        <div class="p-col-2"></div>
        <div class="p-col-4"></div>
        <div class="p-col-4">
            <table style="width: 100%;">
                <tr style="background-color: rgba(66, 214, 240, 0.829);">
                    <td style="border: 1px solid; text-align: center;">{{identiUsuario}}</td>
                    <td style="border: 1px solid; text-align: center;">{{fecha | date: 'dd/MM/yyyy' }}</td>
                    <td style="border: 1px solid; text-align: center;">{{fecha | date: 'hh:mm' }}</td>
                </tr>
            </table>
        </div>
        
    </div>

    <div class="p-grid">
        <div class="p-col-2">
            
        </div>
        <div class="p-col-4">
            <p-dropdown 
                        [options]="cmbTIPROL" 
                        [style]="{'minWidth': '100%', 'width':'100%'}"
                        optionLabel="TIPROL_NOMBRE" 
                        optionValue="TIPROL_CODIGO"
                        [panelStyle]="{'width':'110%'}"
						[(ngModel)]="selectedRol"
                        (onChange)="selecTipoRol($event)">
            </p-dropdown>
        </div>
        <div class="p-col-1"></div>
        <div class="p-col-1">
            <span style="margin-left: 5px;">Asiento:</span>
            
        </div>
        <div class="p-col-4">
            <p-dropdown 
                        [options]="cmbASI_NRO" 
                        [style]="{'minWidth': '100%', 'width':'100%'}"
                        optionLabel="ASI_NRO" 
                        optionValue="ASI_NRO"
                        [panelStyle]="{'width':'110%'}"
						[(ngModel)]="selectedAsiento"
                        (onChange)="selecAsiento($event)">
                        
            </p-dropdown>
        </div>
    </div>

</div>
<div class="divgrups">
    
    <div class="p-grid">
        <div class="p-col-1">
            <span style="margin-left: 20px;">Año:</span>
        </div>
        <div class="p-col-1">
            <input class="frm"  type="text" pInputText  [(ngModel)]="txtENCROL_ANIO" readonly="readonly">
        </div>
        <div class="p-col-1">
            <span style="margin-left: 20px;">Rol:</span>
        </div>
        <div class="p-col-3">
            <input class="frm"  type="text" pInputText  [(ngModel)]="txtTIPOROL_CODIGO" readonly="readonly">
        </div>
        <div class="p-col-1">
            <span>Estado:</span>
        </div>
        <div class="p-col-3">
            <input class="frm"  type="text" pInputText  [(ngModel)]="txtENCROLINDI_ESTADO" readonly="readonly">
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-1">
            <span style="margin-left: 20px;">Período:</span>
        </div>
        <div class="p-col-2">
            <input class="frm"  type="text" pInputText   [(ngModel)]="txtPERIODO_FECINI" readonly="readonly">
        </div>
        <div class="p-col-2">
            <input class="frm"  type="text" pInputText [(ngModel)]="txtPERIODO_FECFIN" readonly="readonly">
        </div>
        <div class="p-col-1" style="color: blue;">
            <span>
                {{lblPERIODO_CODIGO}}
            </span>
        </div>
        <div class="p-col-1">
            <span >Fecha Ingreso:</span>
        </div>
        <div class="p-col-2">
            <input class="frm"  type="text" pInputText  [(ngModel)]="txtEMP_FECINGRESO"  readonly="readonly">
        </div>
        <div class="p-col-1">
            <span>Fecha Salida:</span>
        </div>
        <div class="p-col-2">
            <input class="frm"  type="text" pInputText  [(ngModel)]="txtEMP_FECSALIDA"  readonly="readonly">
        </div>
    </div>

    <div class="p-grid">

        <div class="p-col-1">
            <span style="margin-left: 20px;">Empleado:</span>
        </div>
        <div class="p-col-2">
            <input class="frm"  type="text" pInputText [(ngModel)]="txtEMP_RUCIDE" readonly="readonly">
        </div>
        <div class="p-col-3">
            <input class="frm"  type="text" pInputText  [(ngModel)]="txtEMP_NOMBRE" readonly="readonly">
        </div>

        <div class="p-col-1">
            <span style="color: blue;">{{lblEMP_CODIGO}}</span>
        </div>

        <div class="p-col-1"></div>

        <div class="p-col-2">
            <span style="margin-left: 20px;">Sueldo Mensual:</span>
        </div>
        <div class="p-col-2">
            <input class="frm"  type="text" pInputText [(ngModel)]="txtEMP_SUELDO"  readonly="readonly">
        </div>

    </div>

    
</div>

<div style="overflow: hidden" class="p-col-12">
    <p-tabView (onChange)="onTabChange($event)">
        <p-tabPanel header="Rol">
            <app-aggridsaci [width]="" 
							[height]="largo"
							[enableSorting]="false"
							[enableFilter]="false"
							[rowData]="detalleRoles"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefDetRol"
							[defaultColDef]="defaultColDefDetRol"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)="selectDetRol($event)"

			></app-aggridsaci>
        </p-tabPanel>
        <p-tabPanel header="Provisiones" >
            <app-aggridsaci [width]="" 
							[height]="largo"
							[enableSorting]="false"
							[enableFilter]="false"
							[rowData]="detalleProv"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefDetProv"
							[defaultColDef]="defaultColDefDetProv"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)="selectDetProv($event)"

			></app-aggridsaci>
        </p-tabPanel>
    </p-tabView>
</div>

<div class="divgrups">
    <div class="p-grid">
        <div class="p-col-9"></div>
        <div class="p-col-1">
            <span style="margin-left: 20px;">Totales:</span>
        </div>
        <div class="p-col-1">
            <input class="frm"  type="text" pInputText  [(ngModel)]="total.totalUno"  readonly="readonly">
        </div>
        <div class="p-col-1">
            <input class="frm"  type="text" pInputText   [(ngModel)]="total.totalDos"  readonly="readonly">
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-9"></div>
        <div class="p-col-1">
            <span style="margin-left: 20px;">Neto a Pagar:</span>
        </div>
        <div class="p-col-1">
        </div>
        <div class="p-col-1">
            <input class="frm"  type="text" pInputText [(ngModel)]="total.netoPagar"  readonly="readonly">
        </div>
    </div>
</div>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>



<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width:'90vw', height:'120vh'}"
		  [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="asiento" [abiertoDesde]="'rolesCerrados'"
					(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>

<p-dialog header="Reportes" [(visible)]="displayDialogReportes" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '40vw', height: '400px'}">
    <app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
        [strNumDoc]="lblENCROLINDI_CODIGO" [strCampo]="'ENCROLINDI_CODIGO'"
        (cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'NOMROLCERRADO'" [txtNumComprobante] = "lblENCROLINDI_CODIGO"
	(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>

<!-- GSRF -->
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	<p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
	</p-dialog>	
</div>
<!-- GSRF -->