import {Component, OnInit, ViewChild} from '@angular/core';
import {Nomprovincia} from '../nominterfaces/nommaeprovincia';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {MessageService, ConfirmationService} from 'primeng';
import {NuevoComponentService} from 'src/app/servicios/nuevo-component.service';
import {ErroresBaseDatosService} from 'src/app/servicios/errores-base-datos.service';
import {NommaeprovinciaService} from '../nomservicios/nommaeprovincia.service';

@Component({
  selector: 'app-nommaeprovincias',
  templateUrl: './nommaeprovincias.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NommaeprovinciasComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnGuardar: boolean;
  btnNuevo: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  
  defaultColDefMaeProv_provincia;
  defaultColDefMaeProv_ciudad;
  public frameworkComponents;
  
  rowStyle;
  largo = '258';
  rowSelection = 'multiple';
  nommaeprov_provincias: Nomprovincia[] = [];
  //nommaeprov_ciudades: Nomprovincia[] = [];
  selected_maeProvincia: Nomprovincia;
  selected_maeCiudad: Nomprovincia;
  indexTab = 0;
  disabledTab1 = true;
  pro_padre = '';
  visibleGrid = true;
  nombreProvincia = '';
  columnDefMaeProv_provincia = [
    {
      headerName: 'N', field: 'NUMERO', editable: false, cellEditor: 'cellPrueba', width: 50,
    },
    {
      headerName: 'Código', field: 'PRO_CODIGO', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: true,
          }
        }
    },
    {
      headerName: 'Nombre', field: 'PRO_NOMBRE', cellEditor: 'cellPrueba', width: 300,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 600,
            tienecalculos: true,
          }
        }
    },
  
  ];
  
  columnDefMaeProv_ciudad = [
    {
      headerName: 'N', field: 'NUMERO', editable: false, width: 50,
    },
    {
      headerName: 'Código', field: 'PRO_CODIGO', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 400,
            tienecalculos: false
          }
        }
    },
    {
      headerName: 'Nombre', field: 'PRO_NOMBRE', cellEditor: 'cellPrueba', width: 300,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 600,
            tienecalculos: false,
          }
        }
    },
  
  ];
  
  constructor(
    private messageService: MessageService,
    private init: NuevoComponentService,
    private errorService: ErroresBaseDatosService,
    private confirmationService: ConfirmationService,
    public maeProvinciaService: NommaeprovinciaService
  ) {
    this.maeProvinciaService.nommaeprov_provincia = [];
    this.maeProvinciaService.initBotones();
    this.agTable();
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefMaeProv_provincia = {
      editable: true,
      width: 130,
      objeto: 'maeProvincia'
    };
    this.defaultColDefMaeProv_ciudad = {
      editable: true,
      width: 130,
      objeto: 'maeCiudad'
    };
  }
  
  ngOnInit(): void {
    /*this.btnNuevo =  false;
    this.btnGuardar = true;
    this.btnBorrar  = false;
    this.btnRegresar = false;*/
    // this.nommaeprov_provincias = this.maeProvinciaService.nommaeprov_provincia;
    this.btnNuevo = this.maeProvinciaService.btnNuevo;
    this.btnGuardar = this.maeProvinciaService.btnGuardar;
    this.btnBorrar = this.maeProvinciaService.btnBorrar;
    this.btnRegresar = this.maeProvinciaService.btnRegresar;
    this.getProvincias();
  }
  
  manejarSenales(valor) {
    switch (valor) {
      case 'Nuevo':
        this.opcionNuevo();
        break;
      case 'Guardar':
        this.opcionGuardar();
        break;
      case 'Borrar':
        this.opcionBorrar();
        break;
      case 'Cancelar':
        this.cargar();
        break;
      case 'Salir':
        this.init.tabs.splice(this.init.tabindex, 1);
        break;
      
    }
    
    
  }
  
  
  getProvincias() {
    this.maeProvinciaService.cargarProvincias().then(res => {
      this.maeProvinciaService.nommaeprov_provincia = res;
      // this.aggrid.refreshaggrid(this.maeProvinciaService.nommaeprov_provincia, this.defaultColDefMaeProv_provincia.objeto);
    })
      .catch(e => {
        console.log(e);
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      });
  }
  
  getCiudadesProvincia(pro_codigo) {
    this.maeProvinciaService.cargarCiudades(pro_codigo).then(res => {
      this.maeProvinciaService.nommaeprov_ciudades = res;
      // this.nommaeprov_ciudades = this.maeProvinciaService.nommaeprov_ciudades;
      this.disabledTab1 = false;
      
    })
      .catch(e => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      });
  }
  
  selectMaeProv_provincia(elemento) {
    if ((elemento.object !== null && elemento.object !== undefined)
      && elemento.nameobject === 'maeProvincia') {
      this.selected_maeProvincia = elemento.object;
      if (this.selected_maeProvincia.nuevo === undefined &&
        this.selected_maeProvincia.editado === undefined) {
        this.selected_maeProvincia.old_PRO_CODIGO = this.selected_maeProvincia.PRO_CODIGO;
        this.pro_padre = this.selected_maeProvincia.old_PRO_CODIGO;
        this.nombreProvincia = this.selected_maeProvincia.PRO_NOMBRE;
        this.getCiudadesProvincia(this.selected_maeProvincia.old_PRO_CODIGO);
      }
      
    }
  }
  
  selectMaeProv_ciudad(elemento) {
    if ((elemento.object !== null || elemento.object !== undefined)
      && elemento.nameobject === 'maeCiudad') {
      this.selected_maeCiudad = elemento.object;
      if (this.selected_maeCiudad.nuevo === undefined &&
        this.selected_maeCiudad.editado === undefined) {
        this.selected_maeCiudad.old_PRO_CODIGO = this.selected_maeCiudad.PRO_CODIGO;
      }
    }
  }
  
  cambio(cambio) {
    this.maeProvinciaService.btnGuardar = false;
    this.maeProvinciaService.btnRegresar = false;
    if (this.selected_maeProvincia.nuevo === undefined) {
      this.selected_maeProvincia.editado = true;
    }
  }
  
  cambioCiudad(cambio) {
    this.maeProvinciaService.btnGuardar = false;
    this.maeProvinciaService.btnRegresar = false;
    if (this.selected_maeCiudad.nuevo === undefined) {
      this.selected_maeCiudad.editado = true;
    }
  }
  
  
  opcionNuevo() {
    this.maeProvinciaService.btnNuevo = true;
    this.maeProvinciaService.btnBorrar = false;
    this.maeProvinciaService.btnRegresar = false;
    this.aggrid.isEditable(true);
    
    const new_nommaeprovincia: Nomprovincia = {
      PRO_CODIGO: '',
      PRO_NOMBRE: '',
      PRO_PADRE: '',
      COM_CODIGO: '01',
      nuevo: true
      
    };
    if (this.indexTab === 0) {
      this.maeProvinciaService.nommaeprov_provincia.push(new_nommaeprovincia);
      this.aggrid.refreshaggrid(this.maeProvinciaService.nommaeprov_provincia, this.defaultColDefMaeProv_provincia.objeto);
      
    }
    
    if (this.indexTab === 1) {
      new_nommaeprovincia.PRO_PADRE = this.pro_padre;
      this.maeProvinciaService.nommaeprov_ciudades.push(new_nommaeprovincia);
      this.aggrid.refreshaggrid(this.maeProvinciaService.nommaeprov_ciudades, this.defaultColDefMaeProv_ciudad.objeto);
      
    }
    
    
    
  }
  
  onTab1Change(event) {
    this.maeProvinciaService.initBotones();
    if (this.indexTab === 0) {
      this.visibleGrid = true;
      
      this.disabledTab1 = true;
      
    } else {
      this.visibleGrid = false;
    }
  }
  
  opcionGuardar() {
    
    // this.aggrid.gridApi.stopEditing();
    
    
    if (this.indexTab === 0) {
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
      this.defaultColDefMaeProv_provincia.objeto].stopEditing();
      if ( this.selected_maeProvincia.nuevo && this.selected_maeProvincia.PRO_CODIGO !== ''
        && this.selected_maeProvincia.PRO_NOMBRE !== '' ) {
        
        this.enviarNuevo(this.selected_maeProvincia);
        
      } else if (  this.selected_maeProvincia.editado && this.selected_maeProvincia.PRO_CODIGO !== ''
        && this.selected_maeProvincia.PRO_NOMBRE !== '' ) {
        
        this.enviarEditado(this.selected_maeProvincia);
      }
    }
    
    
    if (this.indexTab === 1) {
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
      this.defaultColDefMaeProv_ciudad.objeto].stopEditing();
      if ( this.selected_maeCiudad.nuevo && this.selected_maeCiudad.PRO_CODIGO !== ''
        && this.selected_maeCiudad.PRO_NOMBRE !== '' ) {
        
        this.enviarNuevo(this.selected_maeCiudad );
        
      } else if (  this.selected_maeCiudad.editado && this.selected_maeCiudad.PRO_CODIGO !== ''
        && this.selected_maeCiudad.PRO_NOMBRE !== '' ) {
        
        this.enviarEditado(this.selected_maeCiudad);
      }
    }
    
  }
  
  async enviarNuevo(item: Nomprovincia) {
    
    this.maeProvinciaService.insertar( item ).then(async () => {
      this.mensajeAlerta('success', 'Información', '¡La ' + this.getNombreTab() + ' se ingresó exitosamente!');
      if ( this.indexTab === 0 ) {
        this.getProvincias();
      } else {
        this.getCiudadesProvincia(item.PRO_PADRE);
        //this.aggrid.refreshaggrid(this.maeProvinciaService.nommaeprov_provincia, this.defaultColDefMaeProv_provincia.objeto);
      }
      this.maeProvinciaService.initBotones();
    })
      .catch(e => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      });
  }
  
  async enviarEditado(item: Nomprovincia) {
    
    this.maeProvinciaService.actualizar( item ).then(async () => {
      this.mensajeAlerta('success', 'Información', '¡La ' + this.getNombreTab() + ' se actualizó exitosamente!');
      
      if ( this.indexTab === 0 ) {
        this.getProvincias();
      } else {
        this.getCiudadesProvincia(item.PRO_PADRE);
      }
      this.maeProvinciaService.initBotones();
    })
      .catch(e => {
        console.log(e);
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      });
    
  }
  
  opcionBorrar() {
    if ( this.selected_maeProvincia !== undefined || this.selected_maeCiudad!== undefined ) {
      this.confirmationService.confirm({
        message: '¿Está seguro que desea eliminar el registro?',
        header: 'Eliminar Grupo',
        icon: 'pi pi-exclamation-triangle',
        key: 'NomMaeProvDialog',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {
          this.borrarRegistro();
        },
        reject: () => {
        }
      });
    }
  }
  
  async borrarRegistro() {
    if ( this.indexTab === 0 ) {
      await this.maeProvinciaService.borrar(this.selected_maeProvincia).then( () => {
        this.getProvincias();
        this.maeProvinciaService.initBotones();
        this.mensajeAlerta('success', 'Información', '¡La ' + this.getNombreTab() + ' se eliminó exitosamente!');
        this.selected_maeProvincia = undefined;
      }  )
        .catch( e => {
          console.log(e);
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        } );
    } else {
      await this.maeProvinciaService.borrar(this.selected_maeCiudad).then( () => {
        this.getCiudadesProvincia(this.selected_maeCiudad.PRO_PADRE);
        this.maeProvinciaService.initBotones();
        this.mensajeAlerta('success', 'Información', '¡La ' + this.getNombreTab() + ' se eliminó exitosamente!');
        this.selected_maeCiudad = undefined;
      }  )
        .catch( e => {
          console.log(e);
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        } );
    }
  }
  
  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'nommaeprovtoast',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
    
  }

  cargar(){
    this.maeProvinciaService.initBotones();
    if ( this.indexTab === 0 ) {
      this.getProvincias();
    } else {
      this.getCiudadesProvincia(this.selected_maeCiudad.PRO_PADRE);
    }
  }
  
  getNombreTab(){
    const registro = ( this.indexTab === 0 ) ?  'provincia' : 'ciudad';
    return  registro;
  }
  
}
