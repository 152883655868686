import { Component, OnInit, ViewChild } from '@angular/core';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { PermisosService } from 'src/app/advantage/advantageservices/permisos.service';
import { MessageService, ConfirmationService, SelectItem } from 'primeng';
import { Usuario } from 'src/app/usuario';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { DatePipe } from '@angular/common';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { ActenckardexService } from '../actservicios/actenckardex.service';
import { AggridBanComponent } from 'src/app/ban/aggrid-ban/aggrid-ban.component';
import { ActMaeKardex } from '../actinterfaces/actmaekardex';
import { SegMaePermiso } from 'src/app/advantage/advantageinterfaces/segmaepermiso';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';

@Component({
  selector: 'app-actenckardex',
  templateUrl: './actenckardex.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class ActenckardexComponent implements OnInit {

  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  btnRegresar = false;
  botonPrimero = false;
  botonAnterior = false;
  botonSiguiente = false;
  botonUltimo = false;
  btnBuscar = false;

  indicador: any;

  lblAsiNro = '';
  lblUsuario = '';
  txtNumComp = '';
  dateFecha: any = '';
  txtOrig = '';
  txtCome1 = '';
  txtCome2 = '';
  txtNume = '';
  btnSeleccionar = false;
  txtActPie = '';
  txtDepIniAct = '';
  txtDepAcumKard = '';

  largo: string;
  public frameworkComponents;
  rowSelection = 'multiple';
  rowStyle;
  defaultActDetKardex;

  detalleDetKardexSel: ActMaeKardex={};

  // Permisos
  permisos: SegMaePermiso;

  // Busqueda
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  where: string;
  opcion: string;

  strTipoGuardar = '';

  spin = false;

  displayAsiContable: boolean;

  displayDialogDocumentos = false;

  columnDefsDetKardex = [
    {
      headerName: '', field: 'KAR_SEL', cellRendererFramework: AggridBanComponent, editable: false, width: 50, onCellClicked: (params) => {
        this.seleccionItem(params.data);
      }
    },
    {
      headerName: 'Código', field: 'ACT_CODIGO', editable: false, width: 175, onCellDoubleClicked: (params) => {
        this.doubleClick(params.data);
      }
    },
    {
      headerName: 'Nombre', field: 'DETKARDEX_DESCRIPCION', editable: false, width: 350,onCellDoubleClicked: (params) => {
        this.doubleClick(params.data);
      }
    },
    {
      headerName: 'F.Compra', field: 'DETKARDEX_FECCOMPRA', editable: false, width: 100,onCellDoubleClicked: (params) => {
        this.doubleClick(params.data);
      }
    },
    {
      headerName: 'V.Util', field: 'DETKARDEX_VIDAUTIL', editable: false, width: 100, cellStyle: {textAlign: 'right'}, onCellDoubleClicked: (params) => {
        this.doubleClick(params.data);
      }
    },
    {
      headerName: 'Costo', field: 'DETKARDEX_COSTO', editable: false, width: 100, cellStyle: { textAlign: 'right' }, onCellDoubleClicked: (params) => {
        this.doubleClick(params.data);
      }
    },
    {
      headerName: 'V.Residual', field: 'DETKARDEX_VALRSDL', editable: false, width: 100, cellStyle: { textAlign: 'right' },onCellDoubleClicked: (params) => {
        this.doubleClick(params.data);
      }
    },
    {
      headerName: 'Importe', field: 'DETKARDEX_IMPORTE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },onCellDoubleClicked: (params) => {
        this.doubleClick(params.data);
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        }
    }
  ];

  constructor( public actenckardexService: ActenckardexService,private auditoriaGralService: AuditoriagralService,
    private permisosService: PermisosService, private message: MessageService, public usuario: Usuario,
    private errorService: ErroresBaseDatosService, private busquedaSer: InvbusquedaService,
    private datePipe: DatePipe, private confirmationService: ConfirmationService, 
    private confIniciales: ConfInicialesService, private init: NuevoComponentService,
    private utilitariosService: UtilitariosService) { 

      this.agTable();
    }

    agTable() {
      this.frameworkComponents = {
        cellPrueba: CellEditorPrueba
      };
      this.rowStyle = (params) => {
        if (params.node.rowIndex % 2 !== 0) {
          return { background: '#D6EAF8' };
        }
      };
      this.defaultActDetKardex = {
        editable: true,
        width: 100,
        objeto: 'actenckardex',
        resizable: true
      };
    }

  async ngOnInit() {
    this.largo = '450';
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.actenckardexService.detKardexArray[this.indicador] = [];
    this.actenckardexService.detKardexn_Array[this.indicador] = [];
    this.actenckardexService.btnGuardar[this.indicador] = true;
    this.actenckardexService.btnNuevo[this.indicador] = false;
    this.actenckardexService.btnBorrar[this.indicador] = false;

    this.dateFecha = this.datePipe.transform(new Date(), 'dd/MM/yyyy');

    // this.actenckardexService.activaBotones('INI', true, false, true, true, true, true, true, true, true, true);
    await this.getPermisos();

  }

  async manejarSenales(valor) {
    if (valor === 'Primero') {
      this.seleccionarRegistro('P');
    }
    if (valor === 'Siguiente') {
      this.seleccionarRegistro('S');
    }
    if (valor === 'Anterior') {
      this.seleccionarRegistro('A');
    }
    if (valor === 'Ultimo') {
      this.seleccionarRegistro('U');
    }
    if (valor === 'Nuevo') {
      this.nuevoRegistro();
    }
    if (valor === 'Borrar') {
      this.eliminarRegistro();
    }
    if (valor === 'Guardar') {
      this.guardarRegistro();
    }
    if (valor === 'Cancelar') {
      this.cancelarRegistro();
    }
    if (valor === 'Buscar') {
      this.buscarRegistro();
    }
    if (valor === 'Imprimir') {
      
    }
    if (valor === 'Almacenardoc') {
      if (this.txtNume !== '') {
        const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.mensajeAlerta('info', 'Información','No tiene los permisos necesarios. Consulte con el Administrador del Sistema');
          
        }
        
      }
    }
    
    if (valor === 'Asiento') {
      this.spin = true;
			await	this.generarAsiContable();
			this.spin = false;
    }
    if (valor === 'AsientoVer') {
      if (this.lblAsiNro !== '') {
					this.displayAsiContable = true;
				} else {
          this.mensajeAlerta('info', 'Error', 'No ha generado el Asiento Contable');
        }
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }

  async generarAsiContable() {
    const parametros: any[] = [];
		
		parametros[0] = Number(this.txtNume);
		parametros[1] = '01';
		parametros[2]= this.usuario.identificacion;
		
		
		try {
			const rs = await this.actenckardexService.generarAsientoKardex(parametros);
		  for (const res of rs){
				if(res.ASI_NRO !== null) {
          this.lblAsiNro = res.ASI_NRO;
        } else {
          this.lblAsiNro = '';
        }  
			}
      this.mensajeAlerta('success', 'Información', 'Se ha generado el Asiento Contable con éxito');//CDPJ
		} catch (err) {
      this.mensajeAlerta('warn', 'Error', '! Proceso generarAsiContable  no terminó satisfactoriamente.... ! ' + err.error);
      this.spin = false;
    }
  }

  eliminarRegistro() {
    if (this.permisos !== null ) {
      if (this.permisos.PERTODO === 0 ) {
        if (this.permisos.PERELIMACION === 0 ) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ', no tiene permiso de eliminación. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }

    this.confirmationService.confirm({
      message: '¿ Está seguro de eliminar el registro ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'actenckardexConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: async () => {
        if (this.txtNume == '') {
          this.mensajeAlerta('info', 'Información', 'Seleccione el registro a Eliminar');
          return;
        }
        const Parametros: any[] = [];
        Parametros[0] = this.txtNume;
        try {
          await this.actenckardexService.eliminarKardex(Parametros);

          this.auditoriaGralService.registrarAuditoria('ACT_ENCKARDEX', this.txtNume, 'E',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });
            
          this.limpiarDatos();
          this.actenckardexService.detKardexArray[this.indicador] = [];
             
        } catch (err) {
          this.mensajeAlerta('warn', 'Error', '! Proceso eliminar datos no terminó satisfactoriamente.... ! ' + err.error);
        }
      },
      reject: () => {
      }
    });
  }

  async guardarRegistro() {
    this.spin=true;//CDPJ
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultActDetKardex.objeto].stopEditing();
    if (this.txtNume === '') /*Nuevo registro*/
			{
				this.strTipoGuardar = 'N';
        /*Verifica los permisos*/
        if (this.permisos !== null ) {
          if (this.permisos.PERTODO === 0 ) {
            if (this.permisos.PERINSERCION === 0 ) {
              this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
                ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.');
                this.spin=false;//CDPJ
              return;
            }
          }
        }
		} else { /*Modifica registro*/	
      this.strTipoGuardar = 'M';	
        /*Verifica los permisos*/
      if (this.permisos !== null ) {
        if (this.permisos.PERTODO === 0 ) {
          if (this.permisos.PERACTUALIZACION === 0 ) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
                ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
                this.spin=false;//CDPJ
             return;
          }
        }
      }
    }

    if (await this.faltaCampos() === true) { //Si los datos de la cabecera son correctos
    
			let strFechaEmision = '';
			
      const Parametros: any[] = [];
      
      let strDia='', strMes='', strAnio='';

      if (this.dateFecha.toString().includes('-')) {
        strDia = this.completarFecha(String(this.dateFecha.getDate()));
        strMes = this.completarFecha(String(this.dateFecha.getMonth() + 1));
        strAnio = this.completarFecha(String(this.dateFecha.getFullYear()));
      } else {
        const fechaArray = String(this.dateFecha).split('/');
        strDia = fechaArray[0];
        strMes = fechaArray[1];
        strAnio = fechaArray[2];
      }
				
			strFechaEmision  = strDia + '/' + strMes + '/' + strAnio;
			
				
			Parametros[0] = Number(this.txtNume);			
			Parametros[1] = this.txtNumComp;
			Parametros[2] = strFechaEmision;	 
			Parametros[3] = this.txtCome1;
			Parametros[4] = this.txtCome2; 		 		
			Parametros[5] = '01';	 		 		
				
			try {
        const rs = await this.actenckardexService.guardarEncKardex(Parametros);
					
				for (const res of rs) {
					if (this.strTipoGuardar === 'N')	{
						if(res.V_ENCKARDEX_CODIGO !== null) {
              this.txtNume = res.V_ENCKARDEX_CODIGO;
            } else {
              this.txtNume = '';
            }
							
						if(res.V_ENCKARDEX_COMPROBANTE !== null) {
              this.txtNumComp = res.V_ENCKARDEX_COMPROBANTE;
            } else {
              this.txtNumComp = '';
            }
              
            this.auditoriaGralService.registrarAuditoria('ACT_ENCKARDEX', this.txtNume, 'I',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });
						
					} else {
            this.auditoriaGralService.registrarAuditoria('ACT_ENCKARDEX', this.txtNume, 'A',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });
					}
					    
					await this.elimLineDetKardNoSele();
					await this.guardarDetalle();
					this.actenckardexService.activaBotones('G',true, false, false, true, true, true, true, true, true, true);
			    	    
				}
					
			} catch (err) {
        this.mensajeAlerta('warn', 'Error', '! Proceso guardar cabecera de la factura  no terminó satisfactoriamente.... !');
        this.spin=false;//CDPJ
			}
			
		}//Fin si los datos de la cabecera son correctos
    this.spin=false;//CDPJ
  }

  async guardarDetalle() {
    let intIndiceLista = 0;
		let item: ActMaeKardex;
    const Parametros: any[] = [];
    
    let strDia='', strMes='', strAnio='';

      if (this.dateFecha.toString().includes('-')) {
        strDia = this.completarFecha(String(this.dateFecha.getDate()));
        strMes = this.completarFecha(String(this.dateFecha.getMonth() + 1));
        strAnio = this.completarFecha(String(this.dateFecha.getFullYear()));
      } else {
        const fechaArray = String(this.dateFecha).split('/');
        strDia = fechaArray[0];
        strMes = fechaArray[1];
        strAnio = fechaArray[2];
      }
		
		
		for (intIndiceLista=0;intIndiceLista<this.actenckardexService.detKardexArray[this.indicador].length;intIndiceLista++) {
			item = this.actenckardexService.detKardexArray[this.indicador][intIndiceLista];
			
			if (item.ACT_CODIGO !== '' && Number(item.DETKARDEX_IMPORTE)>0 && item.KAR_SEL === true ) {
				//Código de la factura del proveedor
				Parametros[0] = Number(this.txtNume);
				Parametros[1] =Number(item.DETKARDEX_NUMSEC); //linea del detalle	
				Parametros[2] = '01';			
				Parametros[3] = item.ACT_CODIGO;//tipo del detalle
				Parametros[4] = item.DETKARDEX_DESCRIPCION;//bodega			
				Parametros[5] = item.DETKARDEX_FECCOMPRA;
				Parametros[6] = Number(item.DETKARDEX_VIDAUTIL);		
				Parametros[7] = Number(item.DETKARDEX_COSTO);
				Parametros[8] = Number(item.DETKARDEX_IMPORTE);
				Parametros[9] = Number(strAnio);
				Parametros[10] = Number(strMes);
				Parametros[11] = Number(intIndiceLista+1);
        Parametros[12] = item.DETKARDEX_FECCOMPRA;
				let strFecKard = strDia + '/' + strMes + '/' + strAnio;
				Parametros[13] = strFecKard;
				Parametros[14] = Number(item.DETKARDEX_VALRSDL);
				Parametros[15] = Number(item.DETKARDEX_VALDEPRECIADO);//setValoDepr
				Parametros[16] = item.DETKARDEX_FECULTMDPRC;
				Parametros[17] = Number(item.DETKARDEX_DPRCACUM);
				
				try {
          const rs = await this.actenckardexService.guardarDetKardex(Parametros);
					
					for (const res of rs) {
						if (Number(item.DETKARDEX_NUMSEC) == 0)	{
              item.DETKARDEX_NUMSEC = res.V_DETKARDEX_NUMSEC;
              this.actenckardexService.detKardexArray[this.indicador][intIndiceLista] = res.V_DETKARDEX_NUMSEC;
							this.auditoriaGralService.registrarAuditoria('ACT_DETKARDEX', this.txtNume + '/' + item.DETKARDEX_NUMSEC, 'I',
                this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
              });
						  
						} else {
              this.auditoriaGralService.registrarAuditoria('ACT_DETKARDEX', this.txtNume + '/' + item.DETKARDEX_NUMSEC, 'A',
                this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
              });
            }
					}
					
				} catch (err) {
          this.mensajeAlerta('warn', 'Error', '! Proceso guardar datos del detalle no terminó satisfactoriamente.... ! ' + err.error);
          this.spin=false;//CDPJ
				}
			}
		}//Fin de for
  }

  async faltaCampos() {

    let strDia='', strMes='', strAnio='';

    if (this.dateFecha.toString().includes('-')) {
      strDia = this.completarFecha(String(this.dateFecha.getDate()));
      strMes = this.completarFecha(String(this.dateFecha.getMonth()));
      strAnio = this.completarFecha(String(this.dateFecha.getFullYear()));
    } else {
      const fechaArray = String(this.dateFecha).split('/');
      strDia = fechaArray[0];
      strMes = String(Number(fechaArray[1])-1);
      strAnio = fechaArray[2];
    }

    if (await this.utilitariosService.verificarPeriodoContable(Number(strDia), Number(strMes), Number(strAnio)) === false) {
      this.mensajeAlerta('info', 'Información', 'Error, fecha debe de estar dentro del período contable');
      this.spin=false;//CDPJ
      return false;
    }

		
		//comentario1
		if (String(this.txtCome1).length === 0 ) {
      this.mensajeAlerta('info', 'Información', 'Error, Ingrese un comentario');
      document.getElementById('comentario').focus();
      this.spin=false;//CDPJ
			return false;	
		}
			
	    
	    
		return true;
	}

  nuevoRegistro() {
    document.getElementById('divPrincipal').style.pointerEvents = 'all';
		this.limpiarDatos();
		this.actenckardexService.detKardexArray[this.indicador] = [];
		this.actenckardexService.activaBotones('N', false, true, true, false, false, false, false, false, false, false);
  	this.lblUsuario = this.usuario.identificacion;
    document.getElementById('comprobante').focus();
  }

  buscarRegistro() {
    if (this.permisos !== null ) {
      if (this.permisos.PERTODO === 0 ) {
        if (this.permisos.PERSELECCION === 0 ) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ', no tiene permiso de Selección. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }

    this.opcion = 'BuscarRegistro';
    this.types = [
      { label: 'CÓDIGO', value: 'ENCKARDEX_CODIGO' },
      { label: 'COMPROBANTE', value: 'ENCKARDEX_COMPROBANTE' },
      { label: 'FECHA', value: 'ENCKARDEX_FECHA' },
      { label: 'COMENTARIO', value: 'ENCKARDEX_COMENTARIO1' }
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', ''];
    this.consulta = '*'
    this.tabla = 'act_enckardex';
    this.where = 'ROWNUM < 100';

    this.busquedaSer.busquedaActEncKardex1().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.displayDialogBusquedaFast = true;
    });
  }

  async cancelarRegistro() {
    this.mostrarDatos(this.txtNume);
  }

  async seleccionarRegistro(strTipo) {
		if (this.permisos !== null ) {
      if (this.permisos.PERTODO === 0 ) {
        if (this.permisos.PERSELECCION === 0 ) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ', no tiene permiso de Selección. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }
		
    const parametros: any[] = [];
    let strEncFacpro = '';

		parametros[0]= strTipo;
		parametros[1]= '01'; 
		parametros[2]= this.txtNume; 

		try {
      const rs = await this.actenckardexService.navegarRegistro(parametros);
      
      if (rs !== null) {
        if (rs.length > 0) {
          strEncFacpro = rs[0].ENCKARDEX_CODIGO === null ? '' : rs[0].ENCKARDEX_CODIGO;
        }
      }
      
			
		} catch (err) {
      this.mensajeAlerta('warn', 'Error', '! Error al seleccionar registro.... ! ' + err.error);
		}
		
		if (strEncFacpro !== '')
		{
			await this.mostrarDatos(strEncFacpro);

		}
		
		
	}

  async getPermisos() {
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisos = data[0];
        } else {
          this.permisos = null;
        }
      } else {
        this.permisos = null;
      }
    } catch (e) {
      this.permisos = null;
      this.mensajeAlerta('warn', 'Error', 'No se pudo obtener los permisos del formulario');
    }
  }

  cambiarFoco(evento) {
    if (evento.target.id === 'comprobante') {
      document.getElementById('comentario').focus();
    }
    if (evento.target.id === 'comentario') {
      document.getElementById('comentario2').focus();
    }
  }

  cambio(event) {
    this.actenckardexService.activaBotones('M', false, true, true, false, false, false, false, false, false, false);
  }

  async aceptar() {
    this.spin = true;
    let strFecha = '';
    if (this.dateFecha.toString().includes('-')) {
      strFecha = this.datePipe.transform(this.dateFecha, 'dd/MM/yyyy');
    } else {
      strFecha = this.dateFecha;
    }

    this.actenckardexService.detKardexArray[this.indicador] = [];
    await this.listaActivoNuevTrans(strFecha);
    this.actenckardexService.activaBotones('M', false, true, true, false, false, false, false, false, false, false);
    this.spin = false;
  
  }

  async listaActivoNuevTrans(strFecha) {
    this.actenckardexService.detKardexArray[this.indicador] = [];
    let intIndiceLista = 0;
    let item_n: ActMaeKardex = {};
    
    await this.listaActivoNuevTrans_aux(strFecha);
    
    let i = 0;
    let strActCod = '';
    let strFechaEmision = '',strDia='',strMes='',strAnio='';
    
    if (this.dateFecha.toString().includes('-')) {
      strDia = this.completarFecha(String(this.dateFecha.getDate()));
      strMes = this.completarFecha(String(this.dateFecha.getMonth() + 1));
      strAnio = this.completarFecha(String(this.dateFecha.getFullYear()));
    } else {
      const fechaArray = String(this.dateFecha).split('/');
      strDia = fechaArray[0];
      strMes = fechaArray[1];
      strAnio = fechaArray[2];
    }

    const strFechComp = strAnio + '-' + strMes + '-' + strDia;	

    const detalle = [];
    for (intIndiceLista=0;intIndiceLista<this.actenckardexService.detKardexn_Array[this.indicador].length;intIndiceLista++) {
			item_n = this.actenckardexService.detKardexn_Array[this.indicador][intIndiceLista];
		
			let item: ActMaeKardex = {};

 			strActCod = item_n.ACT_CODIGO;

			if (await this.FunGlbVerificaPuedeDepreciar(strActCod) === true)	{
				
				if (await this.FunVerificaFechaUltDprc(strActCod, strFechaEmision, item_n.DETKARDEX_FECULTMDPRC) === true) {
					
          item.KAR_SEL = false;
          item = item_n;
					detalle.push(item);
					
				}
				
			}
    }

    this.actenckardexService.detKardexArray[this.indicador] = detalle;
    

  }

  async FunVerificaFechaUltDprc(strActCod, strENCKARDEX_FECHA, strACT_FECULTMDPRC) {

	let strULTIMA_DPRC = '';
	try {
		const rs = await this.actenckardexService.fechaUltimaDeprec(strActCod);
		for (const res of rs)	{
			if (res.ENCKARDEX_FECHA !== null) {
        strULTIMA_DPRC = this.datePipe.transform(res.ENCKARDEX_FECHA, 'dd/MM/yyyy'); 
      }
				
		}
		
	} catch (err) {
    this.mensajeAlerta('warn', 'Error', '! Proceso listarDatos no terminó satisfactoriamente.... ! ' + err.error);
	}
	
	if (strULTIMA_DPRC === '') {
	    if (strACT_FECULTMDPRC !== '')   {
        const fecha1Array = String(strACT_FECULTMDPRC).split('/');
        const fecha2Array = String(strENCKARDEX_FECHA).split('/');
  
        const fecha1 = new Date(Number(fecha1Array[2]), Number(fecha1Array[1])- 1, Number(fecha1Array[0])).getTime();
        const fecha2 = new Date(Number(fecha2Array[2]), Number(fecha2Array[1])- 1, Number(fecha2Array[0])).getTime();
  
	      if (fecha1 > fecha2) {
	        return false;
	      }
	    }
	}
  
   return true;
		
	}

  async FunGlbVerificaPuedeDepreciar(strActCod) {

    let strFEC_ULTIMADEPRECIA = '';
    try {
      const rs = await this.actenckardexService.verificarDepreciacion1(strActCod);
      for (const res of rs)
      {
        if (res.ENCKARDEX_FECHA !== null) {
          strFEC_ULTIMADEPRECIA = this.datePipe.transform(res.ENCKARDEX_FECHA, 'dd/MM/yyyy');
        }
      } 
    } catch (err) {
      this.mensajeAlerta('warn', 'Error', '! Proceso listarDatos no terminó satisfactoriamente.... ! ' + err.error);			
    }

    if (strFEC_ULTIMADEPRECIA === '') {
      return  true;
    }
    
    let dblDETKARDEX_VALRSDL = 0;
    let dblDETKARDEX_VALDEPRECIADO=  0; 
    
    
    try {
      const rs = await this.actenckardexService.verificarDepreciacion2(strActCod, strFEC_ULTIMADEPRECIA);
      for (const res of rs)
      {
        dblDETKARDEX_VALRSDL = res.DETKARDEX_VALRSDL;
        dblDETKARDEX_VALDEPRECIADO = res.DETKARDEX_VALDEPRECIADO;
          
      }
      
    } catch (err) {
      this.mensajeAlerta('warn', 'Error', '! Proceso listarDatos no terminó satisfactoriamente.... ! ' + err.error);
    }
    
    
    if (dblDETKARDEX_VALRSDL != 0) {
      if (dblDETKARDEX_VALRSDL == dblDETKARDEX_VALDEPRECIADO) {
        return false;	
      } else {
        return  true;
      }
    } else {
      return  true;
    }
  }

  async listaActivoNuevTrans_aux(strFecha) {
    this.actenckardexService.detKardexn_Array[this.indicador] = [];
    let item: ActMaeKardex = {};
    
  
    const parametros:any[] = [];
    parametros[0] = strFecha;
  
    try {
      const rs = await this.actenckardexService.consultarActDepre(parametros);
      const detalle = [];
      for (const res of rs )  {
        item={};

        if (res.ACT_FECULTMDPRC !== null ) {
          item.DETKARDEX_FECULTMDPRC = this.datePipe.transform(res.ACT_FECULTMDPRC, 'dd/MM/yyyy');
        }
        item.KAR_SEL = false;
        item.ACT_CODIGO = res.ACT_CODIGO;
        item.DETKARDEX_DESCRIPCION = res.ACT_NOMBRE === null ? '' : res.ACT_NOMBRE;
        if (res.ACT_FECHACOMPRA !== null ) {
          item.DETKARDEX_FECCOMPRA = this.datePipe.transform(res.ACT_FECHACOMPRA, 'dd/MM/yyyy');
        }
        if (res.ACT_FECHRGSTR !== null ) {
          item.DETKARDEX_FINGRESO = this.datePipe.transform(res.ACT_FECHRGSTR, 'dd/MM/yyyy');
        }

        item.DETKARDEX_VIDAUTIL = res.ACT_VIDAUTIL === 0 ? Number(0).toFixed(2) :  Number(res.ACT_VIDAUTIL).toFixed(2);
        item.DETKARDEX_COSTO = res.ACT_COSTO === 0 ? Number(0).toFixed(2) :  Number(res.ACT_COSTO).toFixed(2);
        item.DETKARDEX_VALRSDL = res.ACT_VALRSDL === 0 ? Number(0).toFixed(2) :  Number(res.ACT_VALRSDL).toFixed(2);
        item.DETKARDEX_DPRCACUM = res.ACT_DPRCACUM === 0 ? Number(0).toFixed(2) :  Number(res.ACT_DPRCACUM).toFixed(2);
        
        
        item.DETKARDEX_VALDEPRECIADO = Number(0).toFixed(2);
        item.DETKARDEX_IMPORTE =  Number(0).toFixed(2);
       
        item.DETKARDEX_NUMSEC = 0;
        detalle.push(item);
          
      }

      this.actenckardexService.detKardexn_Array[this.indicador] = detalle;
      
    } catch (err) {
      this.mensajeAlerta('warn', 'Error', '! Proceso listar detalle kardex no terminó satisfactoriamente.... ! ' + err.error);	
    }	


  }

  completarFecha(strFecha): string {
    if (strFecha.length === 1) {
      return '0' + strFecha;
    } else {
      return strFecha;
    }
    
  }

  async selectAll(e) {
    this.spin=true;
      if (e.checked) {
        for (const item of this.actenckardexService.detKardexArray[this.indicador]){
        //this.actenckardexService.detKardexArray[this.indicador].map(async item => {
          item.KAR_SEL = true;
          //CDPJ
          const index = this.actenckardexService.detKardexArray[this.indicador].findIndex(i => i.ACT_CODIGO === item.ACT_CODIGO);
          item.DETKARDEX_IMPORTE= await this.FunObtieneValorDepreciacion(index);
          this.aggrid.refreshaggridindex(this.actenckardexService.detKardexArray[this.indicador], this.defaultActDetKardex.objeto, index -1);
          //CDPJ
        };//);
        
        //this.aggrid.refreshaggrid(this.actenckardexService.detKardexArray[this.indicador], this.defaultActDetKardex.objeto);
      }
      if (!e.checked) {
        for (const item of this.actenckardexService.detKardexArray[this.indicador]){
        //this.actenckardexService.detKardexArray[this.indicador].map(item => {
          item.KAR_SEL = false;
          item.DETKARDEX_IMPORTE = Number(0).toFixed(2);//CDPJ
        };//);
        this.aggrid.refreshaggrid(this.actenckardexService.detKardexArray[this.indicador], this.defaultActDetKardex.objeto);
      }

      this.spin=false;
  }

  async seleccionItem(valor) {
    const index = this.actenckardexService.detKardexArray[this.indicador].findIndex(i => i.ACT_CODIGO === valor.ACT_CODIGO);
    if (this.actenckardexService.detKardexArray[this.indicador][index].KAR_SEL === true) {
      this.actenckardexService.detKardexArray[this.indicador][index].DETKARDEX_IMPORTE = await this.FunObtieneValorDepreciacion(index);
    } else {
      this.actenckardexService.detKardexArray[this.indicador][index].DETKARDEX_IMPORTE = Number(0).toFixed(2);
    }

    this.aggrid.refreshaggridindex(this.actenckardexService.detKardexArray[this.indicador], this.defaultActDetKardex.objeto, index -1);
    this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultActDetKardex.objeto].setFocusedCell(
      index , 'KAR_SEL');
    this.actenckardexService.activaBotones('M', false, true, true, false, false, false, false, false, false, false);
  }

  seleccionDetKardex(valor) {
    if (valor.object !== undefined) {
      this.detalleDetKardexSel = valor.object;
    }
  }

  manejarSeleccion(valor) {

    if (this.opcion === 'BuscarRegistro') {
      if (valor.ENCKARDEX_CODIGO !== null) {
        this.mostrarDatos(valor.ENCKARDEX_CODIGO);
      }
    }

    this.displayDialogBusquedaFast = false;
  }

  async mostrarDatos(strEncKardCod) {
    if (strEncKardCod === '') {
      strEncKardCod = '0';
    }

    this.limpiarDatos();
    this.actenckardexService.detKardexArray[this.indicador] = [];
    
    try {
      const rs = await this.actenckardexService.obtenerDatos(strEncKardCod);
      if (rs !== null) {
        if (rs.length > 0) {
          
          this.txtNume = rs[0].ENCKARDEX_CODIGO;

          if (rs[0].ENCKARDEX_FECHA !== null) {
            this.dateFecha = this.datePipe.transform(rs[0].ENCKARDEX_FECHA, 'dd/MM/yyyy');
          }

          this.txtNumComp = rs[0].ENCKARDEX_COMPROBANTE === null ? '' :  rs[0].ENCKARDEX_COMPROBANTE;
          this.txtOrig = rs[0].ENCKARDEX_ORIGEN === null ? '' : rs[0].ENCKARDEX_ORIGEN;
          this.txtCome1 = rs[0].ENCKARDEX_COMENTARIO1 === null ? '' : rs[0].ENCKARDEX_COMENTARIO1;
          this.txtCome2 = rs[0].ENCKARDEX_COMENTARIO2 === null ? '' : rs[0].ENCKARDEX_COMENTARIO2;
          this.lblAsiNro= rs[0].ASI_NRO === null ? '' : rs[0].ASI_NRO;
          
          this.btnSeleccionar = false;
          document.getElementById('divPrincipal').style.pointerEvents = 'all';
          await this.mostrarDetalle(this.txtNume);
        }
      }
    } catch (err) {
      this.mensajeAlerta('warn', 'Error', '! Proceso desplegar datos no terminó satisfactoriamente.... ! ' + err.error)
    }

    this.actenckardexService.activaBotones('NAV', true, false, false, true, true, true, true, true, true, true);
  }

  async mostrarDetalle(strEncKardCod) {
    try {
      const rs =await this.actenckardexService.listarDatosDet(strEncKardCod);
      const detalle = [];
      if (rs !== null) {
        for (const res of rs) {
          const item: ActMaeKardex = {};
          item.KAR_SEL = true;
          item.ACT_CODIGO = res.ACT_CODIGO === null ? '' :  res.ACT_CODIGO;
          item.DETKARDEX_NUMSEC = res.DETKARDEX_NUMSEC;
          item.DETKARDEX_DESCRIPCION = res.DETKARDEX_DESCRIPCION === null ? '' :  res.DETKARDEX_DESCRIPCION;
          if (res.DETKARDEX_FECCOMPRA !== null) {
            item.DETKARDEX_FECCOMPRA = this.datePipe.transform(res.DETKARDEX_FECCOMPRA, 'dd/MM/yyyy');
          }
          if (res.DETKARDEX_FINGRESO !== null) {
            item.DETKARDEX_FINGRESO = this.datePipe.transform(res.DETKARDEX_FINGRESO, 'dd/MM/yyyy');
          }
  
          item.DETKARDEX_VIDAUTIL = res.DETKARDEX_VIDAUTIL === null ? Number(0).toFixed(2) :  Number(res.DETKARDEX_VIDAUTIL).toFixed(2);
          item.DETKARDEX_COSTO = res.DETKARDEX_COSTO === null ? Number(0).toFixed(2) :  Number(res.DETKARDEX_COSTO).toFixed(2);
          item.DETKARDEX_VALRSDL = res.DETKARDEX_VALRSDL === null ? Number(0).toFixed(2) :  Number(res.DETKARDEX_VALRSDL).toFixed(2);
          item.DETKARDEX_IMPORTE = res.DETKARDEX_IMPORTE === null ? Number(0).toFixed(2) :  Number(res.DETKARDEX_IMPORTE).toFixed(2);
          item.DETKARDEX_VALDEPRECIADO = res.DETKARDEX_VALDEPRECIADO === null ? Number(0).toFixed(2) :  Number(res.DETKARDEX_VALDEPRECIADO).toFixed(2);
          item.DETKARDEX_DPRCACUM = res.DETKARDEX_DPRCACUM === null ? Number(0).toFixed(2) :  Number(res.DETKARDEX_DPRCACUM).toFixed(2);
          
          if (res.DETKARDEX_FECULTMDPRC !== null) {
            item.DETKARDEX_FECULTMDPRC = this.datePipe.transform(res.DETKARDEX_FECULTMDPRC, 'dd/MM/yyyy');
          }
  
          if (item.DETKARDEX_FECCOMPRA === null) {
            item.DETKARDEX_FECCOMPRA = '';
          }
  
          if (item.DETKARDEX_FINGRESO === null) {
            item.DETKARDEX_FINGRESO = '';
          }
  
          if (item.DETKARDEX_FECULTMDPRC=== null) {
            item.DETKARDEX_FECULTMDPRC = '';
          }
  
          detalle.push(item);
        }
        this.actenckardexService.detKardexArray[this.indicador] = detalle;
      }
    
		} catch (err) {
      this.mensajeAlerta('warn', 'Error', '! Proceso listar detalle kardex no terminó satisfactoriamente.... ! ' + err.error);
					
		}
  }

  limpiarDatos() {
    this.txtNume = '';
		this.dateFecha = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
		this.txtNumComp = '';
		this.txtOrig = '';
		this.txtCome1 = '';
		this.txtCome2 = '';
		this.lblAsiNro = '';
		this.btnSeleccionar = false;
  }

  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'actenckardex',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

  async doubleClick(data) {
    let strActCod = data.ACT_CODIGO;
		let strActNom = data.DETKARDEX_DESCRIPCION;
    this.txtActPie = strActNom;
    
    let strDia='', strMes='', strAnio='';

    if (this.dateFecha.toString().includes('-')) {
       strDia = this.completarFecha(String(this.dateFecha.getDate()));
       strMes = this.completarFecha(String(this.dateFecha.getMonth() + 1));
       strAnio = this.completarFecha(String(this.dateFecha.getFullYear()));
    } else {
      const fechaArray = String(this.dateFecha).split('/');
      strDia = fechaArray[0];
      strMes = fechaArray[1];
      strAnio = fechaArray[2];
    }

    let strFecha = strAnio + '/' + strMes + '/' + strDia;
    
    
    const dblDepAcum = await this.FunObtieneDepreciacionAcumulada(strFecha,strActCod);
		this.txtDepAcumKard = Number(dblDepAcum).toFixed(2);

		const dblDeprAcumIni = data.DETKARDEX_DPRCACUM;
		this.txtDepIniAct = Number(dblDeprAcumIni).toFixed(2);

  }

  async FunObtieneDepreciacionAcumulada(strFecha, strActivo) { // lista las articulos para cuando se va a generar una nueva depreciacion
    
    let dblRetorno = 0;
    let parametros: any[] = [];
    parametros[0]=strActivo;
    parametros[1]=strFecha;
    try {
      const rs =await this.actenckardexService.depreciacionAcumulada(parametros);
      for (const res of rs) {
        dblRetorno = res.DETKARDEX_IMPORTE === 0 ? 0 : Number(Number(res.DETKARDEX_IMPORTE).toFixed(2));
      }
      
    } catch (err) {
      this.mensajeAlerta('warn', 'Error', '! Proceso FunObtieneDepreciacionAcumulada no terminó satisfactoriamente.... ! ' + err.error);		
      this.spin=false;//CDPJ
    }	
    return dblRetorno;
  }

  async FunObtieneValorDepreciacion(intFila) {
    let strFechaActivo = '';
    let dblRetorno = 0;
    let fecha:any[] = [];
    let item: ActMaeKardex = {};
    item = this.actenckardexService.detKardexArray[this.indicador][intFila];
    let strActCod = item.ACT_CODIGO;

    let strDia='', strMes='', strAnio='';

    if (this.dateFecha.toString().includes('-')) {
      strDia = this.completarFecha(String(this.dateFecha.getDate()));
      strMes = this.completarFecha(String(this.dateFecha.getMonth() + 1));
      strAnio = this.completarFecha(String(this.dateFecha.getFullYear()));
    } else {
      const fechaArray = String(this.dateFecha).split('/');
      strDia = fechaArray[0];
      strMes = fechaArray[1];
      strAnio = fechaArray[2];
    }
  
    let strFecha = strAnio + '/' + strMes + '/' + strDia;	

    
    const dblDeprAcum = await this.FunObtieneDepreciacionAcumulada(strFecha,strActCod);
    const dblVidaUtil = Number(item.DETKARDEX_VIDAUTIL);
    
    if (dblVidaUtil==0)  {
      this.mensajeAlerta('warn', 'Error', 'Error la vida util del activo ' + strActCod + ' no puede ser cero');
      this.spin=false;//CDPJ
      return 0;
    }
    
    const strFechUltiDepr = item.DETKARDEX_FECULTMDPRC;
    let strFechCompr = item.DETKARDEX_FECCOMPRA;
    let diaFechComp = 0;
    if (strFechUltiDepr === '' && strFechCompr === '') {
      this.mensajeAlerta('warn', 'Error', 'No estan registradas fechas de compra y/o fecha  última depreciación. Activo : ' + strActCod);
      this.spin=false;//CDPJ
      return 0;
    }
  
    try {
      fecha = this.procesarFecha(strFechCompr);
    } catch (err) {
      this.mensajeAlerta('warn', 'Error', 'No se puede obtener la fecha de compra del activo ' + strActCod);
      this.spin=false;//CDPJ
    }
  
    diaFechComp = fecha[0];
    
    if (strFechUltiDepr !== '') {
      strFechaActivo = strFechUltiDepr;
    } else {
      if (strFechCompr !== '') {
        if (diaFechComp <= 15)  {
          try {
            fecha = this.procesarFecha(strFechCompr);
          } catch (err) {
            this.mensajeAlerta('warn', 'Error', 'No se puede obtener la fecha de compra del activo ' + strActCod);
            this.spin=false;//CDPJ
          }
          
          strFechaActivo = '01'+ '/'+ (Number(fecha[1]) +1)  + '/'  + fecha[2];
          strFechaActivo = 	await this.obtieneFechaMenosDias(strFechaActivo,-1);
        } else {
        
          try {
            fecha = this.procesarFecha(strFechCompr);
          } catch (err) {
            this.mensajeAlerta('warn', 'Error', 'No se puede obtener la fecha de compra del activo ' + strActCod);
            this.spin=false;//CDPJ
          }
          
          fecha[0]=1;
          strFechCompr = fecha[0] + '/' +  (Number(fecha[1])+2)+ '/'+ fecha[2];
          strFechaActivo = await	this.obtieneFechaMenosDias(strFechCompr,-1);
        }
        
      }
    }
    
    let dblVAL_TOPEDEPRECIA = 0;
    let dblCosto = Number(item.DETKARDEX_COSTO);
    let dblACT_DPRCACUM = Number(item.DETKARDEX_DPRCACUM);
    let dbl_valResi = Number(item.DETKARDEX_VALRSDL);
    
    dblVAL_TOPEDEPRECIA=dblCosto -(dblDeprAcum + dblACT_DPRCACUM);
    if (dblVAL_TOPEDEPRECIA < dbl_valResi) {
      dblVAL_TOPEDEPRECIA = dbl_valResi;
    } else {
      dblVAL_TOPEDEPRECIA = 0;
    }
    
    const parametros: any[] = [];
    parametros[0]=strActCod;
    parametros[1]=Number(this.txtNume);

    try {
      const rs = await this.actenckardexService.obtenerUltimaFDepre(parametros);
      for (const res of rs) {
        if (res.ENCKARDEX_FECHA !== null) {
          strFechaActivo = this.datePipe.transform(res.ENCKARDEX_FECHA, 'dd/MM/yyyy');
        } else {
          console.log(strActCod);
          console.log(strFechaActivo)
        }
      }
      
    } catch (err) {
      this.mensajeAlerta('warn', 'Error', '! Proceso act_pck_ENCKARDEX_c.act_sp_obtiUltiFechDepr no terminó satisfactoriamente.... ! ' + err.error);	
      this.spin=false;//CDPJ
    }
  
    try {
      fecha = this.procesarFecha(strFechaActivo);
    } catch (err) {
      this.mensajeAlerta('warn', 'Error', 'No se puede obtener la fecha de compra del activo ' + strActCod);
      this.spin=false;//CDPJ
    }
    
    
    let dblVAL_DEPRECIADO = 0;
    if ( dblVAL_TOPEDEPRECIA == 0) {
      dblVAL_DEPRECIADO =dblCosto - dbl_valResi;	
    }	else {
      dblVAL_DEPRECIADO = dblVAL_TOPEDEPRECIA;
    }
    
  
    let dbl_valDepreDiaria = dblVAL_DEPRECIADO / ( dblVidaUtil * 12);// '--depreciacion anual
  
    if (this.dateFecha.toString().includes('-')) {
      strDia = this.completarFecha(String(this.dateFecha.getDate()));
      strMes = this.completarFecha(String(this.dateFecha.getMonth() + 1));
      strAnio = this.completarFecha(String(this.dateFecha.getFullYear()));
    } else {
      const fechaArray = String(this.dateFecha).split('/');
      strDia = fechaArray[0];
      strMes = fechaArray[1];
      strAnio = fechaArray[2];
    }

    const str_fechaKardex =  strDia + '/' + strMes + '/' + strAnio;
    
    const dbl_difDias = await this.obtieneDifFechaMeses(strFechaActivo,str_fechaKardex);

    
      let dblVALOR_DEPRECIA = 0;	
      if (dbl_difDias > 0) {
        
        dblVALOR_DEPRECIA = dbl_valDepreDiaria * dbl_difDias;
        const dblValo1 = dblDeprAcum + dblACT_DPRCACUM + dblVALOR_DEPRECIA;
        const dblValor2 = dblCosto - dbl_valResi;

        if (dblValo1<dblValor2) {
          let dblAUX=0;
          dblAUX = dblVALOR_DEPRECIA;
          dblVALOR_DEPRECIA = dblAUX;
        } else {
          dblVALOR_DEPRECIA = (dblCosto - dbl_valResi) - (Number(Number(dblDeprAcum).toFixed(2)) + dblACT_DPRCACUM);
        }
      } else {
        dblVALOR_DEPRECIA = 0;
      }	

      this.actenckardexService.detKardexArray[this.indicador][intFila].DETKARDEX_VALDEPRECIADO = Number(dblVAL_DEPRECIADO).toFixed(2);
     
      dblRetorno = dblVALOR_DEPRECIA;
      
    return dblRetorno;
  }

  procesarFecha(fecha) {
    let strDia='', strMes='', strAnio='';
    let fechaArray = [];

    if (fecha.toString().includes('-')) {
      strDia = this.completarFecha(String(fecha.getDate()));
      strMes = this.completarFecha(String(fecha.getMonth() + 1));
      strAnio = this.completarFecha(String(fecha.getFullYear()));
      fechaArray = String(strDia + '/' + strMes + '/' + strAnio).split('/');
    } else {
      fechaArray = String(fecha).split('/');
    }

    return fechaArray;
  }

  async obtieneFechaMenosDias(strFecha, numMeses) {
    let strRetorno = '';
    
    const parametros:any[] = [];
    parametros[0] = strFecha;
    parametros[1] = Number(numMeses);

    try {
      const rs  = await this.actenckardexService.fechamenosdias(parametros);
      for (const res of rs) {
        if (res.FECHA !== null) {
          strRetorno = this.datePipe.transform(res.FECHA, 'dd/MM/yyyy');
        }
      }
      
    } catch (err) {
      this.mensajeAlerta('warn', 'Error', '! Proceso obtieneFechMenoNumMeses no terminó satisfactoriamente.... ! ' + err.error);
      this.spin=false;//CDPJ
    }	
    
    return strRetorno;
  }

  async obtieneDifFechaMeses(fecIni, fecFin) {
    let intRetorno=0;
    const parametros: any[] = []
    parametros[0]=fecIni;
    parametros[1]=fecFin;
    try {
      const rs= await this.actenckardexService.obtenerDiferfechameses(parametros);
      for (const res of rs) {
        intRetorno = res.DIFERENCIA === 0 ? 0: Math.floor(res.DIFERENCIA);
      }
    } catch (err) {
      this.mensajeAlerta('warn', 'Error', '! Proceso obtieneDifFechaMeses no terminó satisfactoriamente.... ! ' + err.error);	
      this.spin=false;//CDPJ
    }	

    return intRetorno;
  }


  async elimLineDetKardNoSele() {
    let intIndiceLista = 0;
	  let item: ActMaeKardex;
	  const Parametros: any[] = [];
	
    for (intIndiceLista=0;intIndiceLista<this.actenckardexService.detKardexArray[this.indicador].length; intIndiceLista++) {
      item = this.actenckardexService.detKardexArray[this.indicador][intIndiceLista];
      
      if (item.KAR_SEL === false && item.DETKARDEX_NUMSEC > 0 ) {
        //Código de la factura del proveedor
        Parametros[0] = Number(this.txtNume);
        Parametros[1] = Number(item.DETKARDEX_NUMSEC); //linea del detalle
		
        try {
          await this.actenckardexService.eliminarDetKardex(Parametros);
          
        } catch (err) {
          this.mensajeAlerta('warn', 'Error', '! Proceso elimLineDetKardNoSele no terminó satisfactoriamente.... !' + err.error);
          this.spin=false;//CDPJ
        }
      }
    }//Fin de for
  }

  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos  = false;
    }
  }

  

}
