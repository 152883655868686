//
// SACI WEB
// Rutina: Maestro de bancos de clientes
// Propietario: Andrés Ruiz
// Modificado por: AR
// Fecha de creación: 2-11-2019
// Fecha de Modificación: 29-11-2019
//

import {Component, OnInit, ViewChild} from '@angular/core';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';


import {ConfirmationService, MessageService} from 'primeng/api';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {CxcbancosClienteService} from '../cxcservicios/cxcbancos-cliente.service';

import {CxcbancosCliente} from '../cxcinterfaces/cxcbancos-cliente';
import {EditableColumn} from 'primeng/table';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Component({
  selector: 'app-cxcbancos-cliente',
  templateUrl: './cxcbancos-cliente.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class CxcbancosClienteComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  actualizar: boolean;
  permisoBanco: SegMaePermiso;
  codigoAux: any;
  nuevoCampo: boolean;
  desCodigo: boolean;
  largo: string;
  bancoSeleccionado: CxcbancosCliente;
  public frameworkComponents;
  arregloCons: any[];
  rowStyle;
  defaultColDefBancos;
  rowSelection = 'multiple';
  columnDefsBancos = [
    {
      headerName: 'Código', field: 'BANCLI_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 4,
            tienecalculos: false ,
          }
        }
    },
    {
      headerName: 'Nombre', field: 'BANCLI_NOMBRE', cellEditor: 'cellPrueba', width: 250,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: false,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 45,
            tienecalculos: true
          }
        }
    },
    {
      headerName: 'Estado', field: 'BANCLI_ESTADO', cellEditor: 'agSelectCellEditor', width: 200,
      cellEditorParams:
        {
          values: ['ACTIVO', 'NO ACTIVO']
        }
    },
  ];
  constructor(public bancliservice: CxcbancosClienteService, private message: MessageService, private errorService: ErroresBaseDatosService,
              private confirmationService: ConfirmationService, private permisosService: PermisosService,
              private auditoriagral: AuditoriagralService, private init: NuevoComponentService) {
    this.agTable();
    this.permisoBanco = {};
    this.bancliservice.cxcmaebancli = [];
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefBancos = {
      editable: true,
      width: 100,
      objeto: 'cxcmaebancli'
    };
  }

  ngOnInit() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.actualizar = false;
    this.nuevoCampo = false;
    this.codigoAux = 0;
    this.permisosService.getListaPermisos('CXC', '1', '2900').subscribe((res) => {
      this.permisoBanco = res[0];
    });
    this.cargarBancosClientes();
    
    this.largo = '430';
  }
  
  cargarBancosClientes() {
    this.bancliservice.getbancoClient().subscribe((res) => {
      if (res !== null) {
        this.bancliservice.cxcmaebancli = res;
        this.bancliservice.cxcmaebancli.map((banco) => {
          if (banco.BANCLI_ESTADO === 1) {
            banco.BANCLI_ESTADO = 'ACTIVO';
          } else if (banco.BANCLI_ESTADO === 0) {
            banco.BANCLI_ESTADO = 'NO ACTIVO';
          }
        });
      }
    
    });
  }

  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      if (this.permisoBanco.PERINSERCION === 1) {
        this.btnNuevo = true;
        this.btnGuardar = false;
        this.btnBorrar = false;
        this.btnRegresar = false;
        this.nuevoCampo = true;
        this.aggrid.isEditable(true);
        this.bancoSeleccionado = {
          BANCLI_CODIGO: '',
          BANCLI_NOMBRE: '',
          BANCLI_ESTADO: 'ACTIVO',
          COM_CODIGO: '01',
        };
        this.bancliservice.cxcmaebancli.push(this.bancoSeleccionado);
        this.aggrid.refreshaggrid(this.bancliservice.cxcmaebancli, this.defaultColDefBancos.objeto);
      } else if (this.permisoBanco.PERINSERCION === 0) {
        this.message.add({
          key: 'cxcmaebancli',
          severity: 'error',
          summary: 'Inserción de Banco',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    }
    
    if (valor === 'Guardar') {
      if (this.permisoBanco.PERACTUALIZACION === 1) {
        this.btnGuardar = true;
        this.btnNuevo = false;
        this.btnBorrar = false;
        this.btnRegresar = true;
        
        this.guardarBancosCli();
        
        this.aggrid.isEditable(this.btnNuevo);
      } else if (this.permisoBanco.PERACTUALIZACION === 0) {
        this.message.add({
          key: 'cxcmaebancli',
          severity: 'error',
          summary: 'Actualización de Banco',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }
    }
    if (valor === 'Borrar') {
        if (this.permisoBanco.PERELIMACION === 1) {
          if (this.bancoSeleccionado === undefined) {
            this.message.add({
              key: 'cxcmaebancli',
              severity: 'error',
              summary: 'Eliminacion de Banco',
              detail: 'Seleccion Incorrecta, no se ha seleccionado un banco'
            });
          } else {
            this.btnNuevo = false;
            this.aggrid.isEditable(this.btnNuevo);
            this.confirmarBanco();
          }
      } else if (this.permisoBanco.PERELIMACION === 0) {
        this.message.add({
          key: 'cxcmaebancli',
          severity: 'error',
          summary: 'Eliminación de Banco',
          detail: 'El usuario no tiene permisos de eliminación, acción denegada'
        });
      }
    }

    if (valor === 'Cancelar') {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.aggrid.isEditable(this.btnNuevo);
      this.cargarBancosClientes();
    }
  
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
  guardarBancosCli() {
    
    if (this.bancoSeleccionado.BANCLI_CODIGO !== '' && this.bancoSeleccionado.BANCLI_NOMBRE !== '' ) {
      
      if (this.nuevoCampo) {
        this.bancliservice.insertarBanco(this.bancoSeleccionado).subscribe(() => {
          this.message.add({
            key: 'cxcmaebancli',
            severity: 'success',
            summary: 'Inserción exitosa',
            detail: 'Se insertó el banco correctamente'
          });
          this.auditoriagral.registrarAuditoria('CXC_MAEBANCLI', String(this.bancoSeleccionado.BANCLI_CODIGO), 'I',
            '', '01', '', '', '', '').subscribe(() => {});
          this.aggrid.isEditable(false);
          this.nuevoCampo = false;
        }, error1 => {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
          this.message.add({
            key: 'cxcmaebancli',
            severity: 'error',
            summary: 'No se pudo insertar el banco',
            detail: mensaje
          });
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.btnRegresar = true;
          this.cargarBancosClientes();
          this.nuevoCampo = false;
  
        });
      } else if (!this.nuevoCampo) {
        this.bancliservice.erBanclicodigo(this.bancoSeleccionado.BANCLI_CODIGO).subscribe(existe => {
          if (existe !== null ) {
            this.bancliservice.actualizarBanco(this.bancoSeleccionado ).subscribe(() => {
              this.message.add({
                key: 'cxcmaebancli',
                severity: 'success',
                summary: 'Actualización exitosa',
                detail: 'Se actualizó el banco correctamente'
              });
              this.auditoriagral.registrarAuditoria('CXC_MAEBANCLI', String(this.bancoSeleccionado.BANCLI_CODIGO), 'A',
                '', '01', '', '', '', '').subscribe(() => {});
            }, error1 => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.message.add({
                key: 'cxcmaebancli',
                severity: 'error',
                summary: 'Actualización del banco',
                detail: mensaje
              });
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
              this.cargarBancosClientes();
              this.nuevoCampo = false;
  
            });
          }
        });
      }
    } else {
      this.message.add({
        key: 'cxcmaebancli',
        severity: 'success',
        summary: 'Campos Obligatorios ',
        detail: 'El codigo y el nombre son campos obligatorios'
      });
    }
  }
  
  selectedBancos(valor) {
    if (valor.object !== null) {
      this.bancoSeleccionado = valor.object;
      if (this.nuevoCampo === true) {
        this.actualizar = false;
      } else if (this.nuevoCampo === false) {
        this.actualizar = true;
        if (this.bancoSeleccionado !== undefined) {
          this.codigoAux = this.bancoSeleccionado.BANCLI_CODIGO;
        }
      }
    }
  }
  confirmarBanco() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el banco',
      header: 'Eliminar banco',
      icon: 'pi pi-exclamation-triangle',
      key: 'cxcmaebancliConfirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarbanco();
      },
      reject: () => {
      }
    });
  }

  eliminarbanco() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.bancliservice.getTnrPago(this.bancoSeleccionado).subscribe(confirmacion => {
      if (confirmacion.length !== 0) {
        this.message.add({
          key: 'cxcmaebancli',
          severity: 'error',
          summary: 'Error eliminación banco',
          detail: 'No se ha podido eliminar el registro seleccionado por que ya tiene movimiento'
        });
      } else {
        this.bancliservice.eliminarBanco(this.bancoSeleccionado).subscribe(() => {
          this.message.add({
            key: 'cxcmaebancli',
            severity: 'success',
            summary: 'Eliminación exitosa',
            detail: 'Se eliminó el banco correctamente'
          });
          this.auditoriagral.registrarAuditoria('CXC_MAEBANCLI', String(this.bancoSeleccionado.BANCLI_CODIGO), 'E',
            '', '01', '', '', '', '').subscribe(() => {});
          this.bancliservice.cxcmaebancli = this.bancliservice.cxcmaebancli.filter((val, j) => j !==
              this.bancliservice.cxcmaebancli.indexOf(this.bancoSeleccionado));
          this.aggrid.refreshaggrid(this.bancliservice.cxcmaebancli, this.defaultColDefBancos.objeto);
        }, error1 => {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
          this.message.add({
            key: 'cxcmaebancli',
            severity: 'error',
            summary: 'Eliminacion de el banco',
            detail: mensaje
          });
        });
      }
    });
  }
  cambio(cambio) {
        this.btnGuardar = false;
        this.btnRegresar = false;
  }
  
  controlGuardar(valor) {
    this.manejarSenales('Guardar');
  }
}

