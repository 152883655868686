import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NomempleadosService } from '../nomservicios/nomempleados.service';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { Nommaedatosadi, NommaedatosadiEmp } from '../nominterfaces/nonmaedatosadi';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import { NommaeEmpleado } from '../nominterfaces/nomempleado';
import { NommatdatadiService } from '../nomservicios/nommatdatadi.service';
import { DatePipe } from '@angular/common';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { MessageService, ConfirmationService } from 'primeng';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';

@Component({
  selector: 'app-nommatdatadi',
  templateUrl: './nommatdatadi.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NommatdatadiComponent implements OnInit {
  @Input() datadiempl:NommaeEmpleado;
  @Output() salir= new EventEmitter<string>();
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  inputcodigo:string="";
  inputanomape:string="";
  btnGuardar: boolean;
  btnNuevo: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  largoAdi='450';
  rowStyle;
  rowSelection = 'multiple';
  nommaeDatAdi:NommaedatosadiEmp[]=[];
  defaultColDefMaeDatAdi;
  public frameworkComponents;
  selectedNomDatAdi:NommaedatosadiEmp;
  colorColumna = '#C2F8F8';
  columnDefMaeDatAdi=[
    {
      headerName: '', field: 'NUMERO', cellEditor: 'cellPrueba', editable: true, width: 100,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
           
          }
        }
    },
 
    {
      headerName: 'Etiqueta', editable: true, field: 'DATADI_ETIQUETA', cellEditor: 'cellPrueba', width: 150,  
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: false,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: false,
            
          }
        }
    },
    {
      headerName: 'Dato',  field: 'VALOR', cellEditor: 'cellPrueba',onCellClicked: (params) => {
        this.seleccionItem(params.data);  
      }, width: 400,
      
      editable: (params) => {
        const boolEdit = this.isEdit(params.data) ;
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: false,
            int:true
          }
        }
        
    }
  ];
  constructor(private init: NuevoComponentService,
    private nommatdatadiService: NommatdatadiService,
    private datePipe: DatePipe,
    private errorService: ErroresBaseDatosService,
    private messageService: MessageService,
    private auditoriaGralService: AuditoriagralService,
    private confirmationService: ConfirmationService
    //public nomempleadosService:NomempleadosService
    ) { 
    
    this.agTable();
  }

  ngOnInit(): void {
    console.log(this.datadiempl);
    this.inputcodigo=this.datadiempl.EMP_CODIGO
    this.inputanomape=this.datadiempl.EMP_APELLIDO+" "+this.datadiempl.EMP_NOMBRE;

    this.nommatdatadiService.selectDatosadimat().then(res=>{
      this.nommaeDatAdi=res;
      this.nommaeDatAdi.map(x=>{
        x.NUMERO= this.nommaeDatAdi.indexOf( x ) + 1;
        x.NUEVO=false;
      });
      this.nommatdatadiService.selectEmpl(this.datadiempl.EMP_CODIGO).then(res=>{
        this.nommaeDatAdi.map(x=>{
          if(x.DATADI_TIPODATO=='Date'){
            x.VALOR = this.datePipe.transform( res[0][x.DATADI_NOMBRECAMPO], 'dd/MM/yyyy' );
           
          }else{
            x.VALOR=res[0][x.DATADI_NOMBRECAMPO];
          }
        });
        console.log(this.nommaeDatAdi);
        this.aggrid.refreshaggrid(this.nommaeDatAdi, this.defaultColDefMaeDatAdi.objeto );
        this.aggrid.gridApi.setFocusedCell(0 , 'VALOR');
      }) 
    })
    
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefMaeDatAdi = {
      editable: true,
      width: 130,
      objeto: 'nomMaeRubros'
    };
  }

  selectMaeDatAdi(elemento){
    if (elemento.object !== null) {
      this.selectedNomDatAdi=elemento.object;
    }
  }
  cambioDatAdi($event){
    this.btnGuardar = false;
    this.btnRegresar = false;
  
  }
  manejarSenalesAdi(valor){
    switch (valor) {
      case 'Nuevo':
        
        break;
      case 'Guardar':
        this.opcionGuardar();
        break;
      case 'Borrar':
       
        break;
      case 'Cancelar':
        this.cargar();
        break;
      case 'Buscar':
       
        break;
      case 'Salir':
        this.salir.emit('salir');
        break;
    
    }
  }
  cargar(){
    this.nommatdatadiService.selectDatosadimat().then(res=>{
      this.nommaeDatAdi=res;
      this.nommaeDatAdi.map(x=>{
        x.NUMERO= this.nommaeDatAdi.indexOf( x ) + 1;
        x.NUEVO=false;
      });
      this.nommatdatadiService.selectEmpl(this.datadiempl.EMP_CODIGO).then(res=>{
        this.nommaeDatAdi.map(x=>{
          
          if(x.DATADI_TIPODATO=='Date'){
            try{

            x.VALOR = this.datePipe.transform( res[0][x.DATADI_NOMBRECAMPO], 'dd/MM/yyyy' );
            }catch{

            }  
          }else{
            try{
            x.VALOR=res[0][x.DATADI_NOMBRECAMPO];
            }catch{

            }  
          }
        });
        //console.log(this.nommaeDatAdi);
        this.aggrid.refreshaggrid(this.nommaeDatAdi, this.defaultColDefMaeDatAdi.objeto );
        this.aggrid.refreshaggrid(this.nommaeDatAdi, this.defaultColDefMaeDatAdi.objeto );
        this.aggrid.gridApi.setFocusedCell(0 , 'VALOR');
      }) 
    })
    
  }
 
  opcionGuardar(){
    this.aggrid.gridApi.stopEditing();
    console.log(this.selectedNomDatAdi);
    var strSentencia = "update nom_maeempleado set ";
    switch (this.selectedNomDatAdi.DATADI_TIPODATO) {
      case 'Varchar2':
        console.log('varchar2',this.selectedNomDatAdi.VALOR);
          strSentencia = strSentencia + this.selectedNomDatAdi.DATADI_NOMBRECAMPO + "='" + String(this.selectedNomDatAdi.VALOR) + "'"
          strSentencia = strSentencia + " where emp_codigo=" + this.datadiempl.EMP_CODIGO;
          this.nommatdatadiService.actualizar(strSentencia ).then(()=>{
            this.mensajeAlerta('success', 'Información', '¡Información Agregado!');
            this.cargar();
          }).catch(err=>{
            console.log(err)
          });
        break;
      case 'Number':
          strSentencia = strSentencia + this.selectedNomDatAdi.DATADI_NOMBRECAMPO + "=" + parseInt(this.selectedNomDatAdi.VALOR) + ""
          strSentencia = strSentencia + " where emp_codigo=" + this.datadiempl.EMP_CODIGO;
            this.nommatdatadiService.actualizar(strSentencia ).then(()=>{
              this.mensajeAlerta('success', 'Información', '¡Información Agregado!');
              this.cargar();
            }).catch(err=>{
              let mensaje: string;
              mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
              this.mensajeAlerta('error', 'Error', mensaje);
            });
       
        break;
      case 'Float':
        strSentencia = strSentencia + this.selectedNomDatAdi.DATADI_NOMBRECAMPO + "=" + parseFloat(this.selectedNomDatAdi.VALOR) + ""
        strSentencia = strSentencia + " where emp_codigo=" + this.datadiempl.EMP_CODIGO;
        console.log(strSentencia);
        this.nommatdatadiService.actualizar(strSentencia ).then(()=>{
          this.mensajeAlerta('success', 'Información', '¡Información Agregado!');
          this.cargar();
        }).catch(err=>{
          let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('error', 'Error', mensaje);
        });       
        
        break;
      case 'Date':
        
        if(this.selectedNomDatAdi.VALOR.includes('-')){
          this.selectedNomDatAdi.VALOR=this.datePipe.transform(this.selectedNomDatAdi.VALOR, 'dd/MM/yyyy');
        }else{
          this.selectedNomDatAdi.VALOR='';
        }
        strSentencia = strSentencia + this.selectedNomDatAdi.DATADI_NOMBRECAMPO + "=to_date('" + this.selectedNomDatAdi.VALOR + "','dd/mm/yyyy')"
        strSentencia = strSentencia + " where emp_codigo=" + this.datadiempl.EMP_CODIGO;
        this.nommatdatadiService.actualizar(strSentencia ).then(()=>{
          this.mensajeAlerta('success', 'Información', '¡Información Agregado!');
          this.cargar();
        }).catch(err=>{
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        });
        break;
    }
  }
   seleccionItem(data){
    this.selectedNomDatAdi=data;
    switch (this.selectedNomDatAdi.DATADI_TIPODATO) {
      case 'Varchar2':
        this.columnDefMaeDatAdi[2].cellEditorParams.values={
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 120,
          tienecalculos: false,
        }
        break;
      case 'Number':
        this.columnDefMaeDatAdi[2].cellEditorParams.values={
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: false,
          int:true
        }
        break;
      case 'Float':
        this.columnDefMaeDatAdi[2].cellEditorParams.values={
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 10,
          obligatorio: false,
          fecha: false,
          tamanocelda: 120,
          tienecalculos: false,
          int:true
        }
        break;
      case 'Date':
        this.columnDefMaeDatAdi[2].cellEditorParams.values={
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: true,
          tamanocelda: 120,
          tienecalculos: false, 
        }
        break;
    }
  } 
 isEdit(data){
      this.selectedNomDatAdi=data;
      if(this.selectedNomDatAdi.DATADI_TIPODATO=="Etiqueta"){    
        if (this.aggrid.gridApi.getFocusedCell().column.colId == 'VALOR') { 
          return false;
        }
       
      }else{
        if (this.aggrid.gridApi.getFocusedCell().column.colId == 'VALOR') { 
          return true;
        }
      }
  }
  mensajeAlerta(tipo, titulo, msg) {
    this. messageService.add({
      key: 'maeNomMatdatadit',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
}
