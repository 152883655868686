import { Injectable } from '@angular/core';

@Injectable()
export class UsuarioEsquema {
  esquema: string;
  usuario: string;
  password: string;
  activacion: string;  
  
  public saveStorage() {
    sessionStorage.setItem('usuarioEsquema', JSON.stringify({
      esquema: this.esquema,
      usuario: this.usuario,
      password: this.password,
      activacion: this.activacion      
    }));
  }
  
  public loadStorage() {
    const store = JSON.parse(sessionStorage.getItem('usuarioEsquema'));
    if (store == null) {
    } else {
      this.esquema = store.esquema;
      this.usuario = store.usuario;
      this.password = store.password;
      this.activacion = store.activacion;      
    }
  }
  
  public clearStorage() {
    sessionStorage.clear();
  }

}
