<div class="p-grid" style="width: 99%;">
	<div class="p-col-12">
        <app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="true"
            [botonBorrar]="btnBorrar"
            (signal)="manejarSenales($event)" 
            [botonBuscar]="true"  [barraBotonesCRUD]="false"  
            [barraBotonesDesplaz]="true"  [barraBotonesAsiCon]="true" [barraBotones5]="true"
            [barraBotones3]="true" [botonBinocular]="true" [botonRecargo]="false"
           >
        </app-invmaebarra>
	</div>
</div>
<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="novedad"></p-toast>
	
<p-confirmDialog [transitionOptions]="'0ms'" key="nomNovedad" title="Confirmar Acción"
                 appendTo="body"></p-confirmDialog>
    <div class="divgrups">
        <div class="p-grid">
            <div class="p-col-8">

            </div>
            <div class="p-col-4">
                <button  pButton class="aceptar" style="line-height: 2.5em; font-family: Arial, serif; margin-top: -5px; height: 30px; width: 99% ;margin-bottom: 5px;" (click)="opcionListarTodos()">Listar todas las novedades</button>	
            </div>    
        </div>
    </div>

    <p-tabView (onChange)="onTab1Change($event)" [(activeIndex)]="indexTab">
        <p-tabPanel header="Novedades" >
            <app-aggridsaci [width]=""
                            [height]="largo"
                            [enableSorting]="false"
                            [enableFilter]="true"
                            [rowData]="nomtrannovedadservice.nonnovedad"
                            [frameworkComponents]="frameworkComponents"
                            [animateRows]="true"
                            [rowSelection]="rowSelection"
                            [columnDefs]="columnnomTrannove"
                            [defaultColDef]="defaultColDefTrannoved"
                            [rowStyle]="rowStyle"
                            [singleClickEdit]="false"
                            [botones]="false"
                            [mostrarGuardar]="false"
                            (selected)="selecnonnovedad($event)"
                            (cambios)="cambiononnovedad()"
            ></app-aggridsaci> 
            <div  *ngIf="cargoDatos==true" style="position: absolute;top: 320px;width: 100%;z-index: 500;">
                <div style="display: flex;align-items: center;justify-content: center">
                    <p-progressSpinner *ngIf="cargoDatos==true" [style]="{width: '200px', height: '200px'}"
                        animationDuration=".5s"></p-progressSpinner>
                    </div>
            </div>
        </p-tabPanel>
    </p-tabView>

<!-- <p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
					 [consulta]="consulta" [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccionbus($event)"></app-busquedadlg>
</p-dialog> -->
