import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {BantrnbanEdit} from '../baninterfaces/bantrnbanedit';
import {Observable} from 'rxjs';
import {BanTrnbanVar} from '../baninterfaces/bantrnbanVar';
import {BanTrnbanCaj} from '../baninterfaces/bantrnbanCaj';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';


@Injectable({
  providedIn: 'root'
})
export class BantrnbaneditService {
  
  get banTrnBanVarios(): BanTrnbanVar[] {
    return this._trnBanVar;
  }
  
  set banTrnBanVarios(value: BanTrnbanVar[]) {
    this._trnBanVar = value;
  }
  
  get banTrnBanCaja(): BanTrnbanCaj[] {
    return this._trnBanCaja;
  }
  
  set banTrnBanCaja(value: BanTrnbanCaj[]) {
    this._trnBanCaja = value;
  }
  
  private _trnBanVar: BanTrnbanVar[];
  private _trnBanCaja: BanTrnbanCaj[];
  public hmpConfiguracion: any[];
  
  public banTrnbanSelect: BantrnbanEdit = {};
  public item: BantrnbanEdit = {};
  public boolCancelacion: boolean;
  public boolAbono: boolean;
  
  public glbStrCodigo = ''; // trnban_numero
  public glbStrNombre = ''; // trnban_tipo
  public glbStrCodigo2 = '';
  
  private way: string;
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, private confIniciales: ConfInicialesService, private init: NuevoComponentService) {
    this.way = sessionStorage.getItem('way');
  }
  
  cargarDatosCMBTipo(): Observable<any> {
    return this.http.post<any[]>(this.way + '/ban/movbancosedit/6abb8e32cbecb40', {
        elementos: {},
        esquema: {
          usuario: this.usuarioEsquema.esquema,
          password: this.usuarioEsquema.password
        }
      }, this.confIniciales.httpOptions()
    );
    
  }
  
  consultaNombreBan(banCodigo): Observable<any> {
    return this.http.post<any[]>(this.way + '/ban/movbancosedit/617dbe2cb55d98', {
        elementos: {
          ban_codigo: banCodigo
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  verificarPeriodoContable(intDia, intMes, intAnio): Observable<any> {
    let strDia = '';
    let strMes = '';
    let strAnio = '';
  
    strDia = this.completarFecha(intDia);
    strMes = this.completarFecha(intMes + 1);
    strAnio = this.completarFecha(intAnio);
  
    return this.http.post<any[]>(this.way + '/ban/movbancosedit/cbb72c370cc5f80', {
        elementos: {
          dia: strDia,
          mes: strMes,
          anio: strAnio
        },
      }, this.confIniciales.httpOptions()
    );
  }
  
  completarFecha(strFecha) {
    strFecha = strFecha.toString();
    if (strFecha.length === 1) {
      return '0' + strFecha;
    } else {
      return strFecha;
    }
  }
  
  cxp_fnc_verificar_trans_cash(strDocumento, strProCodigo, strTipo): Observable<any> {
    return this.http.post<any[]>(this.way + '/ban/movbancosedit/3eedc9fddaafc60000', {
        elementos: {
          documento: strDocumento,
          pro_codigo: strProCodigo,
          tipo: strTipo
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  num_Exist(tipoDoc): Observable<any> {
    return this.http.post<any[]>(this.way + '/ban/movbancosedit/2feb3b8c973ae', {
        elementos: {
          trnban_numero: this.banTrnbanSelect.TRNBAN_NUMERO,
          ban_codigo: this.banTrnbanSelect.BAN_CODIGO,
          tipo_doc: tipoDoc
        },
      }, this.confIniciales.httpOptions()
    );
  }
  
  existe_Banco(banCodigo): Observable<any> {
    return this.http.post<any[]>(this.way + '/ban/movbancosedit/77192e75cb55d80', {
        elementos: {
          ban_codigo: banCodigo
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  insertar(bancodigo, fechEmision, tipo, numero, numDoc, importe, concepto, beneficiario, AN, conCodigo,
           comcodigo, org, referencia, fechacont, strEncRequisicion, strCodProveedor, strPagoElectronico): Observable<any> {
    if (tipo !== 'CH') {
      fechacont = '';
    }
    
    return this.http.post<any[]>(this.way + '/ban/movbancosedit/4afcb63eb55d98', {
      elementos: {
        p_BAN_codigo_1: bancodigo,
        p_TRNBAN_fecha_2: fechEmision,
      p_TRNBAN_tipo_3: tipo,
      p_TRNBAN_numero_4: numero,
      p_TRNBAN_docnro_5: numDoc,
      p_TRNBAN_importe_6: Number(importe),
      p_TRNBAN_concepto_7: concepto,
      p_TRNBAN_beneficiario_9: beneficiario,
      p_TRNBAN_anulado_10: '' + AN,
      p_CON_codigo_11: conCodigo,
      p_COM_codigo_12: comcodigo,
      p_TRNBAN_origen_13: org,
      p_TRNBAN_NUMEROREFUSER: referencia,
      p_TRNBAN_FECHAVENCE: fechacont,
      p_ENCREQ_NUMERO: strEncRequisicion,
      P_PRO_CODIGO: this.banTrnbanSelect.PRO_CODIGO === undefined ? '' : strCodProveedor,
      p_TRNBAN_PAGOELEC: strPagoElectronico,
      },
    }, this.confIniciales.httpOptions());
  }
  
  consulta_con_cod(): Observable<any>  {
    return this.http.post<any[]>(this.way + '/ban/movbancosedit/cc5f9eaf598bb000', {
        elementos: {
          ban_codigo: this.banTrnbanSelect.BAN_CODIGO
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  actualiza_ultCH(): Observable<any> {
    return this.http.post<any[]>(this.way + '/ban/movbancosedit/533beaf59176bc0', {
        elementos: {
          trnban_numero: this.banTrnbanSelect.TRNBAN_NUMERO,
          ban_codigo: this.banTrnbanSelect.BAN_CODIGO
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  insertaAJ(codAjus, fechEmision, tipo, numero, numDoc, importe, concepto, beneficiario, AN, conCodigo, comcodigo, org, referencia): Observable<any> {
    return this.http.post<any[]>(this.way + '/ban/movbancosedit/257e3b7d56d53', {
      elementos: {
        p_TRNBANAJC_CODIGO: codAjus,
        p_TRNBANAJC_NUMERO: numero,
        p_TRNBANAJC_TIPO: tipo,
        p_TRNBANAJC_CONCEPTO: concepto,
        p_TRNBANAJC_IMPORTE: '' + importe,
        p_TRNBANAJC_ANULADO: '' + AN,
        p_TRNBANAJC_ORIGEN: org,
        p_TRNBANAJC_FECHAAJC: fechEmision,
        p_COM_CODIGO: comcodigo,
        p_USU_IDENTIFICACION: this.usuario.identificacion,
      },
    }, this.confIniciales.httpOptions());
  }
  
  encontrarProCodigo(strBanCodigo, strTipoDoc, strNumero) {
    return this.http.post(this.way + '/ban/movbancosedit/f95db9de1986c800', {
      elementos: {
        ban_codigo: strBanCodigo,
        trnban_tipo: strTipoDoc,
        trnban_numero: strNumero
      },
    }, this.confIniciales.httpOptions());
  }
  
  encontrarProNombre(strProCodigo) {
    return this.http.post(this.way + '/ban/movbancosedit/f95db9de2f8b3000', {
      elementos: {
        pro_codigo: strProCodigo
      },
    }, this.confIniciales.httpOptions());
  }
  
  encontrarSaldoInicial(strBanCodigo, strTipoDoc, strNumero) {
    return this.http.post(this.way + '/ban/movbancosedit/7caede2aadc4b00', {
      elementos: {
        ban_codigo: strBanCodigo,
        trnban_tipo: strTipoDoc,
        trnban_numero: strNumero
      },
    }, this.confIniciales.httpOptions());
  }
  
  encontrarTrnBanNumero(strBanCodigo, strTipoDoc, strNumero) {
    return this.http.post(this.way + '/ban/movbancosedit/f95dbdddd6abe000', {
      elementos: {
        ban_codigo: strBanCodigo,
        trnban_tipo: strTipoDoc,
        trnban_numero: strNumero
      },
    }, this.confIniciales.httpOptions());
  }
  encontrarTrnBanNumero1(strBanCodigo, strTipoDoc, strNumero) {
    return this.http.post(this.way + '/ban/movbancosedit/f95dbdddd6abe0001', {
      elementos: {
        ban_codigo: strBanCodigo,
        trnban_tipo: strTipoDoc,
        trnban_numero: strNumero
      },
    }, this.confIniciales.httpOptions());
  }
  
  encontrarTrnBanConcilia(strNumero, strBanCodigo, strTipoDoc) {
    return this.http.post(this.way + '/ban/movbancosedit/7caedeeeecc5d80', {
      elementos: {
        ban_codigo: strBanCodigo,
        trnban_tipo: strTipoDoc,
        trnban_numero: strNumero
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  eliminaTrnCajaDeposito(banCodigo, banTipo, numero): Observable<any> {
    return this.http.post(this.way + '/ban/movbancosedit/1d5977776bb38e0000', {
      elementos: {
        p_BAN_codigo_1: banCodigo,
        p_TRNBAN_tipo_2: banTipo,
        p_TRNBAN_numero_3: numero,
        p_COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  eliminaTrnCajaDepositoProm(banCodigo, banTipo, numero) {
    return this.http.post(this.way + '/ban/movbancosedit/1d5977776bb38e0000', {
      elementos: {
        p_BAN_codigo_1: banCodigo,
        p_TRNBAN_tipo_2: banTipo,
        p_TRNBAN_numero_3: numero,
        p_COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  actualizarSaldoinicial(saldoIni, tipoDoc): Observable<any> {
    return this.http.post<any[]>(this.way + '/ban/movbancosedit/299de2aadc4af2', {
        elementos: {
          trnban_saldoini: saldoIni,
          trnban_numero: this.banTrnbanSelect.TRNBAN_NUMERO,
          ban_codigo: this.banTrnbanSelect.BAN_CODIGO,
          trnban_tipo: tipoDoc
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  guardarVarios(banCodigo, trnbanTipo, TrnbanNumero, ConCodigo, CenCodigo, Descripcion, importe, codsec, comcodigo): Observable<any> {
    
    return this.http.post(this.way + '/ban/movbancosedit/10f2b6d56feadd000', {
      elementos: {
        p_BAN_CODIGO: banCodigo,
        p_TRNBAN_TIPO: trnbanTipo,
        p_TRNBAN_NUMERO: TrnbanNumero,
        p_CON_CODIGO:  ConCodigo,
        p_CEN_CODIGO: CenCodigo,
        p_TRNBANVAR_DESCRIPCION: Descripcion,
        p_TRNBANVAR_IMPORTE: Number(importe),
        p_TRNBANVAR_CODSEC: codsec,
        p_COM_CODIGO: comcodigo
      },
    }, this.confIniciales.httpOptions());
  }
  
  eliminarVarios(strTipo, strTrnCajNumero, strCajCodigo, strTrnCajDocNro, strCpnia): Observable<any>  {
    return this.http.post(this.way + '/ban/movbancosedit/eacad2babeadc800', {
      elementos: {
        p_BAN_CODIGO: strCajCodigo,
        p_TRNBAN_TIPO: strTipo,
        p_TRNBAN_NUMERO: strTrnCajNumero,
        p_COM_CODIGO: strCpnia
      },
    }, this.confIniciales.httpOptions());
  }
  
  fechaHraSistema(): string {
    
    const c = new Date();
    let fechaHora = '';
    
    let dia = String(c.getDate());
    const mes = String(c.getMonth() + 1);
    const annio = String(c.getFullYear());
    let hora = String(c.getHours());
    let minutos = String(c.getMinutes());
    let segundos = String(c.getSeconds());
    
    dia = this.completarFecha(dia);
    hora = this.completarFecha(hora);
    minutos = this.completarFecha(minutos);
    segundos = this.completarFecha(segundos);
    
    if (mes.length === 1) {
      fechaHora = dia + '/' + '0' + mes + '/' + annio + ' ' + hora + ':' + minutos + ':' + segundos;
    } else {
      fechaHora = dia + '/' + mes + '/' + annio + ' ' + hora + ':' + minutos + ':' + segundos;
    }
    
    return fechaHora;
  }
  
  generarAsiento(Bancodigo, Bantipo, bannumero, Banfecha, Banorigen, comcodigo, usuario ): Observable<any>  {
    return this.http.post<any[]>(this.way + '/ban/movbancosedit/1075ddb52b9276000', {
      elementos: {
        p_BAN_CODIGO: Bancodigo,
        p_TRNBAN_TIPO: Bantipo,
        p_TRNBAN_NUMERO: bannumero,
        p_TRNBAN_FECHA: Banfecha,
        p_ORIGEN: Banorigen,
        p_COM_CODIGO: comcodigo,
        p_USU_IDENTIFICACION: usuario,
      },
    }, this.confIniciales.httpOptions());
  }
  
  actualizaRegistro(bancodigo, fechEmision, tipo, numero, numDoc, importe, concepto, beneficiario, AN, conCodigo, comcodigo, org,
                    referencia, fechacont, strEncRequisicion, strCodProveedor, strPagoElectronico): Observable<any> {
    
    return this.http.post<any[]>(this.way + '/ban/movbancosedit/533be5557765c00', {
      elementos: {
        p_BAN_codigo_1: bancodigo,
        p_TRNBAN_tipo_2: tipo,
        p_TRNBAN_numero_3: numero,
        p_BAN_codigo_4: bancodigo,
        p_TRNBAN_fecha_5: fechEmision,
        p_TRNBAN_tipo_6: tipo,
        p_TRNBAN_numero_7: numero,
        p_TRNBAN_docnro_8: numDoc,
        p_TRNBAN_importe_9: '' + importe,
        p_TRNBAN_concepto_10: concepto,
        p_TRNBAN_beneficiario_14: beneficiario,
        p_TRNBAN_anulado_15: '' + AN,
        p_CON_codigo_16: conCodigo,
        p_COM_codigo_17: comcodigo,
        p_TRNBAN_origen_18: org,
        p_TRNBAN_NUMEROREFUSER: referencia,
        p_TRNBAN_FECHAVENCE: fechacont,
        p_ENCREQ_NUMERO: strEncRequisicion,
        P_PRO_CODIGO: strCodProveedor === null ? '' : strCodProveedor,
        p_TRNBAN_PAGOELEC: strPagoElectronico
      },
    }, this.confIniciales.httpOptions());
  }
  
  consultarDatos(strTrnbanTipo, strtrnbanNumero, strBanCodigo): Observable<any>  {
    return this.http.post<any[]>(this.way + '/ban/movbancosedit/cc5f9eaf7eadc800', {
        elementos: {
          trnban_tipo: strTrnbanTipo,
          trnban_numero: strtrnbanNumero,
          ban_codigo: strBanCodigo
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  anularAsientoContable(strAsiNro): Observable<any> {
    return this.http.post(this.way + '/ban/movbancosedit/eacad2bab9266000', {
      elementos: {
        p_ASI_nro: strAsiNro,
        p_COM_codigo: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  eliminarTRNBANAJCDET(bancodigo, bantipo): Observable<any> {
    return this.http.post(this.way + '/ban/movbancosedit/7565695d53635c0', {
      elementos: {
        p_TRNBANAJC_CODIGO: bancodigo,
        p_TRNBANAJC_TIPO: bantipo,
        p_COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  eliminar_TRNBANAJCVAR(bancodigo): Observable<any> {
    return this.http.post(this.way + '/ban/movbancosedit/eacad2baa6c70000', {
      elementos: {
        p_TRNBANAJC_CODIGO: bancodigo,
        p_COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  borra_ASI_NRO(asiNro): Observable<any> {
    return this.http.post<any[]>(this.way + '/ban/movbancosedit/1d595a5756d5c90000', {
        elementos: {
          asi_nro: asiNro
        },
      }, this.confIniciales.httpOptions()
    );
  }
  
  eliminarBancoMovimiento(bancodigo, bantipo, numero): Observable<any>  {
    return this.http.post(this.way + '/ban/movbancosedit/756565aaecc5b00', {
      elementos: {
        p_BAN_codigo_1: bancodigo,
        p_TRNBAN_tipo_2: bantipo,
        p_TRNBAN_numero_3: numero,
        p_COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  configCH(): Observable<any> {
    return this.http.post<any[]>(this.way + '/ban/movbancosedit/662ef9419176bc0', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    );
  }
  
  auto_numCHEQUE(): Observable<any> {
    return this.http.post<any[]>(this.way + '/ban/movbancosedit/2bddc5fd6645da', {
        elementos: {
          ban_codigo: this.banTrnbanSelect.BAN_CODIGO
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  consulta_max_docnum(strparam, cabecera): Observable<any> {
    return this.http.post<any[]>(this.way + '/ban/movbancosedit/617db2b8c6e1a0', {
        elementos: {
          param: strparam
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  cargarCaja(): Observable<any> {
    return this.http.post<any[]>(this.way + '/ban/movbancosedit/617d75ddb62a68', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    );
  }
  
  cargarBodega(): Observable<any> {
    return this.http.post<any[]>(this.way + '/ban/movbancosedit/617d75ddb5e1b0', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    );
  }
  
  consultarMovimiento( where, opcion, Bod): Observable<any> {
    
    let condicion = '';
    if (where === null) {
      condicion = ' AND ';
    }
    if (where !== null) {
      condicion = ' AND ' + where;
    }
    
    return this.http.post(this.way + '/ban/movbancosedit/662fcf57bbbb140', {
      elementos: {
        p_opcion: opcion,
        p_COM_codigo: '01',
        p_condicion: condicion,
        p_BAN_CODIGO: '',
        p_TRNBAN_NUMERO: '',
        p_TRNBAN_TIPO: '',
        p_BOD_CODIGO: Bod,
      },
    }, this.confIniciales.httpOptions());
  }
  
  depositos(banco, tipo, numero): Observable<any> {
    return this.http.post(this.way + '/ban/movbancosedit/62b7dddd8a9b5c0', {
      elementos: {
        p_BAN_CODIGO: banco,
        p_TRNBAN_NUMERO: numero,
        p_TRNBAN_TIPO: tipo,
        p_COM_CODIGO: '01',
      },
    }, this.confIniciales.httpOptions());
  }
  
  suma() {
    
    let b = 0;
    let a = 0;
    let c = 0;
    
    
    for (let i = 0; i < this.banTrnBanCaja.length; i++) {
      
      b += Number('' + this.banTrnBanCaja[i].TRNCAJDET_IMPORTE);
      b = Number('' + b);
      
      if ( this.banTrnBanCaja[i].TRNCAJ_TIPO === 'CH') {
        a += Number('' + this.banTrnBanCaja[i].TRNCAJDET_IMPORTE);
        a = Number('' + a);
        
      }
      if (this.banTrnBanCaja[i].TRNCAJ_TIPO === 'EF') {
        c += Number('' + this.banTrnBanCaja[i].TRNCAJDET_IMPORTE);
        c = Number('' + c);
        
      }
    }
    
    this.actualiza_importe(b, a, c);
    
  }
  
  actualiza_importe(valor, valorch, valoref) {
    this.banTrnbanSelect.IMPORTE_RES = valor.toFixed(this.confIniciales.getNumDecSist());
    this.banTrnbanSelect.CHEQUE = valorch.toFixed(this.confIniciales.getNumDecSist());
    this.banTrnbanSelect.EFECTIVO = valoref.toFixed(this.confIniciales.getNumDecSist());
    this.banTrnbanSelect.IMPORTE_TOTAL = valor.toFixed(this.confIniciales.getNumDecSist());
   // this.banTrnbanSelect.IMPORTE = valor.toFixed(this.confIniciales.getNumDecSist());
    this.banTrnbanSelect.IMPORTE_TOTAL = this.init.moneyValidation(this.banTrnbanSelect.IMPORTE_TOTAL);
  
  }
  
  valores(i) {
    if (this.banTrnBanCaja[i].CHECK_ITEM === true) {
      
      if (this.banTrnBanCaja[i].TRNCAJ_TIPO === 'CH') {
        this.banTrnBanCaja[i].TRNCAJDET_IMPORTE = Number(0).toFixed(this.confIniciales.getNumDecSist());
        this.banTrnBanCaja[i].TRNCAJDET_IMPORTE = Number(Number(this.banTrnBanCaja[i].TRNCAJ_IMPORTE)
          - Number(this.banTrnBanCaja[i].TRNCAJ_PAGADO)).toFixed(this.confIniciales.getNumDecSist());
      }
      
      if (this.banTrnBanCaja[i].TRNCAJ_TIPO === 'EF') {
        if (this.estado_tipo() === 'cancelacion') {
          this.banTrnBanCaja[i].TRNCAJDET_IMPORTE = Number(0).toFixed(this.confIniciales.getNumDecSist());
          this.banTrnBanCaja[i].TRNCAJDET_IMPORTE = Number(Number(this.banTrnBanCaja[i].TRNCAJ_IMPORTE)
            - Number(this.banTrnBanCaja[i].TRNCAJ_PAGADO)).toFixed(this.confIniciales.getNumDecSist());
        } else {
          this.banTrnBanCaja[i].TRNCAJDET_IMPORTE = Number(0).toFixed(this.confIniciales.getNumDecSist());
        }
      }
      
      
    }
    
    if (this.banTrnBanCaja[i].CHECK_ITEM === false) {
      this.banTrnBanCaja[i].TRNCAJDET_IMPORTE = Number(0).toFixed(this.confIniciales.getNumDecSist());
    }
    
   //  this.aggrid.refreshColumnDefs();
    // this.aggrid.refreshaggrid(this.bantrnbanEditService.banTrnBanCaja, this.defaultBanTrnBanCaj.objeto);
    
    this.suma();
  }
  
  estado_tipo(): string {
    
    if (this.boolAbono) {
      return 'abono';
    }
    
    if (this.boolCancelacion) {
      return 'cancelacion';
    }
    
    if (!this.boolAbono && !this.boolCancelacion) {
     // this.mensajeAlerta('error', 'Error', 'Seleccione abono o cancelacion');
    }
    
    return 'nada';
  }
  

  actualizarNumeroDepProm(strBanCodigo, strTrnBanNumero, strTrnBanTipo)  {
    return this.http.post(this.way + '/ban/movbancosedit/299df2afeb35d8', {
      elementos: {
        p_BAN_CODIGO: strBanCodigo,
        p_TRNBAN_NUMERO: strTrnBanNumero,
        p_TRNBAN_TIPO: strTrnBanTipo,
        P_COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  consultarBanMov(strCodCaja, strTrnCajTipo, strTrnCajNumero, asinro): Observable<any> {
    const strFiltro = ' COM_codigo = \'01\' ';
    const strCondicion = ' BAN_CODIGO=\'' + strCodCaja + '\'' +
      ' AND TRNBAN_TIPO=\'' + strTrnCajTipo + '\''
      + ' AND TRNBAN_NUMERO = \'' + strTrnCajNumero + '\'';
    
    return this.http.post(this.way + '/ban/movbancosedit/cc5f9eaf56abd800', {
      elementos: {
        p_opcion: 'R',
        p_filtro: strFiltro,
        p_condicion: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }
  

  
  actualizarMovimientoProm(codbanco, numero, tipo, item: BanTrnbanCaj) {
    return this.http.post(this.way + '/ban/movbancosedit/83aec93b7b73180', {
      elementos: {
        p_BAN_CODIGO: codbanco,
        p_TRNBAN_NUMERO: numero,
        p_TRNBAN_TIPO: tipo,
        p_TRNCAJ_TIPO: item.TRNCAJ_TIPO,
        p_TRNCAJ_NUMERO: item.TRNCAJ_NUMERO,
        p_CAJ_CODIGO: item.CAJ_CODIGO,
        p_TRNCAJDET_IMPORTE: item.TRNCAJDET_IMPORTE,
        p_TRNCAJ_SALDO: item.TRNCAJ_SALDO,
        p_TRNCAJ_PAGADO: item.TRNCAJ_PAGADO,
        p_TRNCAJDET_TIPO: item.TRNCAJ_TIPO,
        p_TRNCAJ_ORIGEN: item.TRNCAJ_ORIGEN,
        p_BANCLI_CODIGO: item.BANCLI_CODIGO,
        p_CLI_CODIGO: item.CLI_CODIGO,
        p_COM_CODIGO: '01',
        p_USU_IDENTIFICACION: this.usuario.identificacion,
        p_trncaj_docnro: item.TRNCAJ_DOCNRO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  
  ban_sp_actlzsaldotrncajxtrnbanProm(bancodigo, bantipo,  numero) {
    return this.http.post(this.way + '/ban/movbancosedit/533bc555b862a80', {
      elementos: {
        p_ban_codigo: bancodigo,
        p_trnban_tipo: bantipo,
        p_trnban_numero: numero,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  
  genera_asiento_trnProm(bancodigo, bantipo, numero, fecha, origen) {
    return this.http.post(this.way + '/ban/movbancosedit/1075d5c93afdc7000', {
      elementos: {
        p_BAN_CODIGO: bancodigo,
        p_TRNBAN_NUMERO: numero,
        p_TRNBAN_TIPO: bantipo,
        p_TRNBAN_FECHA: fecha,
        p_ORIGEN: origen,
        p_COM_CODIGO: '01',
        p_USU_IDENTIFICACION: this.usuario.identificacion,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  CargarTrnCajaDeposito(bancodigo, bantipo, numero): Observable<any> {
    return this.http.post(this.way + '/ban/movbancosedit/62b7dddd8a9b5c0', {
      elementos: {
        p_BAN_CODIGO: bancodigo,
        p_TRNBAN_NUMERO: numero,
        p_TRNBAN_TIPO: bantipo,
        p_COM_CODIGO: '01',
      },
    }, this.confIniciales.httpOptions());
  }
  
  getConfiguraciones(codigo): number {
    const valorConfig = this.hmpConfiguracion.find(element => element.CFG_CODIGO === codigo).CFG_VALOR1;
    return valorConfig;
  }
  
  obtenerClave(accion): Observable<any> {
  
    if (accion === 'eliminarOtroOrg') {
      return this.http.post<any>(this.way + '/ban/movbancosedit/c2faebbb6caac00', {
        elementos: {},
      }, this.confIniciales.httpOptions()
      );
    }
  }
   //GSRF
   getcuentconta(cuenta) {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/10776ca773bbc300000000', {
      elementos: {
        con_codigo: cuenta
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //GSRF
  //CDPJ
 consultarorigenasi(strNumComprobante,ban_codigo) {
  return this.http.post<any[]>(this.way + '/ban/movbancosedit/83baae4b1b94200', {
      
      elementos: {
        NumComprobanteC: strNumComprobante,
        p_BAN_CODIGO:ban_codigo
      }
  }, this.confIniciales.httpOptions()).toPromise();
};
//CDPJ
}
