//
// SACI WEB
// Rutina: Maestro de Transportistas
// Propietario: Wilson Ramos
// Modificado por: WR
// Fecha de creación: 22-11-2019
// Fecha de Modificación: 14-01-2020
//
import {Component, OnDestroy, OnInit} from '@angular/core';
import {VenmaetransportistasService} from '../venservicios/venmaetransportistas.service';
import {Venmaetransportistas} from '../veninterfaces/venmaetransportistas';
// @ts-ignore
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Component({
  selector: 'app-venmaetransportistas',
  templateUrl: './venmaetransportistas.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class VenmaetransportistasComponent implements OnInit, OnDestroy {
  transportistaSeleccionado: Venmaetransportistas;
  transportistaSeleccionadoAux: Venmaetransportistas;
  btnNuevo: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  btnGuardar: boolean;
  btnPrimero: boolean;
  btnAnterior: boolean;
  btnSiguiente: boolean;
  btnUltimo: boolean;
  inputNum: boolean;
  indice: number;
  opcion: string;
  permisosTransportista: SegMaePermiso;
  types: SelectItem[];
  types1: SelectItem[];//CDPJ
  codigoAnt: string;
  displayDialogBus2: boolean;
  arregloBus: any[];
  opcionDropDown: string;
  doingRegistro: boolean;
  
  constructor(private transportService: VenmaetransportistasService, private busquedaSer: InvbusquedaService,
              private message: MessageService, private errorService: ErroresBaseDatosService,
              private confirmationService: ConfirmationService, private permisosService: PermisosService,
              private auditoriagral: AuditoriagralService, private confInciales: ConfInicialesService,
              private init: NuevoComponentService) {
  }
  
  ngOnInit() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.btnPrimero = false;
    this.btnAnterior = false;
    this.btnSiguiente = false;
    this.btnUltimo = false;
    this.indice = 0;
    this.inputNum = true;
    this.permisosService.getListaPermisos('VEN', '1', '913').subscribe((permiso) => {
      this.permisosTransportista = permiso[0];
    });
    this.codigoAnt = '';
    this.types1 = [{label: 'RUC', value: 'RUC'},
      {label: 'Cédula', value: 'Cedula'}, {label: 'Pasaporte', value: 'Pasaporte'}];
    this.transportistaSeleccionado = {};
    this.transportistaSeleccionadoAux = {};
    this.transportService.getTransportistas().subscribe((res) => {
      this.transportService.venmaetransportistas = res;
    });
    this.opcionDropDown = '';
    this.doingRegistro = false;
  }
  
  ngOnDestroy(): void {
    this.confInciales.instanciaTransportista--;
  }
  
  manejarSenales(valor) {
    if (this.transportService.venmaetransportistas.length !== 0) {
      if (valor === 'Primero') {
        this.btnAnterior = true;
        this.btnPrimero = true;
        this.btnUltimo = false;
        this.btnSiguiente = false;
        this.btnRegresar = false;
        this.indice = 0;
        this.transportistaSeleccionado = this.transportService.venmaetransportistas[this.indice];
        this.transportistaSeleccionadoAux = this.transportService.venmaetransportistas[this.indice];
        this.codigoAnt = this.transportistaSeleccionado.TRANSPORT_CODIGO;
        
        this.doingRegistro = true;
      }
      if (valor === 'Anterior') {
        if (this.btnUltimo === true && this.btnSiguiente === true) {
          this.btnUltimo = false;
          this.btnSiguiente = false;
        }
        this.btnRegresar = false;
        this.indice--;
        this.transportistaSeleccionado = this.transportService.venmaetransportistas[this.indice];
        this.transportistaSeleccionadoAux = this.transportService.venmaetransportistas[this.indice];
        this.codigoAnt = this.transportistaSeleccionado.TRANSPORT_CODIGO;
        this.doingRegistro = true;
      }
      if (valor === 'Siguiente') {
        if (this.btnPrimero === true && this.btnAnterior === true) {
          this.btnPrimero = false;
          this.btnAnterior = false;
        }
        this.btnRegresar = false;
        this.indice++;
        this.transportistaSeleccionado = this.transportService.venmaetransportistas[this.indice];
        this.transportistaSeleccionadoAux = this.transportService.venmaetransportistas[this.indice];
        this.codigoAnt = this.transportistaSeleccionado.TRANSPORT_CODIGO;
        this.doingRegistro = true;
      }
      if (valor === 'Ultimo') {
        this.btnUltimo = true;
        this.btnSiguiente = true;
        this.btnPrimero = false;
        this.btnAnterior = false;
        this.btnRegresar = false;
        this.indice = this.transportService.venmaetransportistas.length - 1;
        this.transportistaSeleccionado = this.transportService.venmaetransportistas[this.indice];
        this.transportistaSeleccionadoAux = this.transportService.venmaetransportistas[this.indice];
        this.codigoAnt = this.transportistaSeleccionado.TRANSPORT_CODIGO;
        this.doingRegistro = true;
      }
      
      if (valor === 'Cancelar') {
        this.btnNuevo = false;
        this.btnGuardar = true;
        this.btnBorrar = false;
        this.btnRegresar = true;
        this.btnPrimero = false;
        this.btnAnterior = false;
        this.btnSiguiente = false;
        this.btnUltimo = false;
        if (this.codigoAnt !== '') {
          this.transportistaSeleccionado = {
            TRANSPORT_CODIGO: this.codigoAnt,
            COM_CODIGO: '01',
            TRANSPORT_RUC: this.transportistaSeleccionadoAux.TRANSPORT_RUC,
            TRANSPORT_NOMBRE: this.transportistaSeleccionadoAux.TRANSPORT_NOMBRE,
            TRANSPORT_TELEFONO1:
            this.transportistaSeleccionadoAux.TRANSPORT_TELEFONO1,
            TRANSPORT_TELEFONO2: this.transportistaSeleccionadoAux.TRANSPORT_TELEFONO2,
            TRANSPORT_DIRECCION: this.transportistaSeleccionadoAux.TRANSPORT_DIRECCION,
            TRANSPORT_CONTACTO: this.transportistaSeleccionadoAux.TRANSPORT_CONTACTO,
            TRANSPORT_AUTO:
            this.transportistaSeleccionadoAux.TRANSPORT_AUTO,
            TRANSPORT_PLACA: this.transportistaSeleccionadoAux.TRANSPORT_PLACA,
            BOD_CODIGO: this.transportistaSeleccionadoAux.BOD_CODIGO,
            TRANSPORT_TONELAJE: this.transportistaSeleccionadoAux.TRANSPORT_TONELAJE
          };
        } else if (this.codigoAnt === '') {
          this.doingRegistro = false;
          const codigo = this.transportService.venmaetransportistas[this.transportService.venmaetransportistas.length - 1].TRANSPORT_CODIGO;
          this.transportistaSeleccionado = {
            TRANSPORT_CODIGO: '', COM_CODIGO: '01', TRANSPORT_RUC: '', TRANSPORT_NOMBRE: '',
            TRANSPORT_TELEFONO1: '', TRANSPORT_TELEFONO2: '', TRANSPORT_DIRECCION: '',
            TRANSPORT_CONTACTO: '', TRANSPORT_AUTO: '', TRANSPORT_PLACA: '', BOD_CODIGO: '', TRANSPORT_TONELAJE: ''
          };
        }
      }
      
      if (valor === 'Borrar') {
        if (this.permisosTransportista.PERELIMACION === 1) {
          this.confirmarTransportista();
        } else if (this.permisosTransportista.PERELIMACION === 0) {
          this.message.add({
            key: 'trans',
            severity: 'error',
            summary: 'Eliminación de Transportista',
            detail: 'El usuario no tiene permisos de eliminación, acción denegada'
          });
        }
      }
    }
    
    if (valor === 'Nuevo') {
      if (this.permisosTransportista.PERINSERCION === 1) {
        this.btnNuevo = true;
        this.btnGuardar = false;
        this.btnBorrar = false;
        this.btnRegresar = false;
        this.btnPrimero = true;
        this.btnSiguiente = true;
        this.btnAnterior = true;
        this.btnUltimo = true;
        this.doingRegistro = true;
        let codigo = '0';
        if (this.transportService.venmaetransportistas.length !== 0) {
          codigo = this.transportService.venmaetransportistas[this.transportService.venmaetransportistas.length - 1].TRANSPORT_CODIGO;
        }
        this.transportistaSeleccionado = {
          TRANSPORT_CODIGO: String(Number(codigo) + 1), COM_CODIGO: '01', TRANSPORT_RUC: '', TRANSPORT_NOMBRE: '',
          TRANSPORT_TELEFONO1: '', TRANSPORT_TELEFONO2: '', TRANSPORT_DIRECCION: '',
          TRANSPORT_CONTACTO: '', TRANSPORT_AUTO: '', TRANSPORT_PLACA: '', BOD_CODIGO: '', TRANSPORT_TONELAJE: ''
        };
        this.codigoAnt = '';
        // this.codigoAnt = this.transportistaSeleccionado.TRANSPORT_CODIGO;
        document.getElementById('nombre').focus();
      } else if (this.permisosTransportista.PERINSERCION === 0) {
        this.message.add({
          key: 'trans',
          severity: 'error',
          summary: 'Inserción de Transportista',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    }
    
    if (valor === 'Guardar') {
      if (this.permisosTransportista.PERACTUALIZACION === 1) {
        this.transportService.erFindCodTransp(this.transportistaSeleccionado.TRANSPORT_CODIGO).subscribe(existe => {
          if (existe !== null) {
            this.transportService.actualizarTransportistas(this.transportistaSeleccionado).subscribe((res) => {
              this.transportService.actualizarTonelaje(this.transportistaSeleccionado).subscribe(() => {
              });
              this.message.add({
                key: 'trans',
                severity: 'success',
                summary: 'Actualización de Transportista',
                detail: 'Se ha actualizado el transportista'
              });
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
              this.btnPrimero = false;
              this.btnUltimo = true;
              this.btnAnterior = false;
              this.btnSiguiente = true;
              this.auditoriagral.registrarAuditoria('VEN_MAETRANSPORT', String(this.transportistaSeleccionado.TRANSPORT_CODIGO), 'A',
                '', '01', '', '', '', '').subscribe(() => {
              });
              this.transportService.getTransportistas().subscribe((res2) => {
                this.transportService.venmaetransportistas = res2;
              });
            }, error1 => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.message.add({
                key: 'trans',
                severity: 'error',
                summary: 'Actualización de Transportista',
                detail: mensaje
              });
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
            });
          } else if (existe === null) {
            this.transportService.guardarTransportistas(this.transportistaSeleccionado).subscribe(() => {
              this.transportService.actualizarTonelaje(this.transportistaSeleccionado).subscribe(() => {
              });
              this.message.add({
                key: 'trans',
                severity: 'success',
                summary: 'Inserción de Transportista',
                detail: 'Se ha insertado el transportista'
              });
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
              this.btnPrimero = false;
              this.btnUltimo = true;
              this.btnAnterior = false;
              this.btnSiguiente = true;
              this.auditoriagral.registrarAuditoria('VEN_MAETRANSPORT', String(this.transportistaSeleccionado.TRANSPORT_CODIGO), 'I',
                '', '01', '', '', '', '').subscribe(() => {
              });
              this.transportService.getTransportistas().subscribe((res) => {
                this.transportService.venmaetransportistas = res;
              });
            }, error1 => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.message.add({
                key: 'trans',
                severity: 'error',
                summary: 'Inserción de Transportista',
                detail: mensaje
              });
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
            });
          }
        });
      } else if (this.permisosTransportista.PERACTUALIZACION === 0) {
        this.message.add({
          key: 'trans',
          severity: 'error',
          summary: 'Actualización de Transportista',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
      }
    }
    
    if (valor === 'Buscar') {
      this.buscarTransportistas();
      this.doingRegistro = true;
      
    }
    
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  
  buscarTransportistas() {
    this.opcion = 'BUSCAR';
    this.types = [
      {label: 'CÓDIGO', value: 'TRANSPORT_CODIGO'},
      {label: 'NOMBRE', value: 'TRANSPORT_NOMBRE'},
      {label: 'RUC', value: 'TRANSPORT_RUC'},
      {label: 'TELÉFONO 1', value: 'TRANSPORT_TELEFONO1'},
      {label: 'TELÉFONO 2', value: 'TRANSPORT_TELEFONO2'},
      {label: 'DIRECCIÓN', value: 'TRANSPORT_DIRECCION'},
    ];
    this.busquedaSer.busquedaVenMaeTransport2().subscribe((res: any[]) => {
      this.arregloBus = res;
      this.displayDialogBus2 = true;
    });
  }
  
  confirmarTransportista() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el Trasnportista',
      header: 'Eliminar Transportista',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarTrans',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarTransportista();
      },
      reject: () => {
      }
    });
  }
  
  eliminarTransportista() {
    if (this.transportistaSeleccionado.TRANSPORT_CODIGO === '') {
      this.message.add({
        key: 'trans',
        severity: 'error',
        summary: 'Eliminación de Transportista',
        detail: 'No se ha seleccionado un transportista para eliminar'
      });
    } else if (this.transportistaSeleccionado.TRANSPORT_CODIGO !== '') {
      this.transportService.eliminarTransportista(this.transportistaSeleccionado).subscribe(() => {
        this.message.add({
          key: 'trans',
          severity: 'success',
          summary: 'Eliminación de Transportista',
          detail: 'Se ha eliminado el transportista'
        });
        this.doingRegistro = false;
        this.auditoriagral.registrarAuditoria('VEN_MAETRANSPORT', String(this.transportistaSeleccionado.TRANSPORT_CODIGO), 'E',
          '', '01', '', '', '', '').subscribe(() => {
        });
        this.btnNuevo = false;
        this.btnGuardar = true;
        this.btnBorrar = false;
        this.btnRegresar = true;
        this.btnPrimero = false;
        this.btnAnterior = false;
        this.btnUltimo = false;
        this.btnSiguiente = false;
        this.transportistaSeleccionado = {
          TRANSPORT_CODIGO: '', COM_CODIGO: '01', TRANSPORT_RUC: '', TRANSPORT_NOMBRE: '',
          TRANSPORT_TELEFONO1: '', TRANSPORT_TELEFONO2: '', TRANSPORT_DIRECCION: '',
          TRANSPORT_CONTACTO: '', TRANSPORT_AUTO: '', TRANSPORT_PLACA: '', BOD_CODIGO: '', TRANSPORT_TONELAJE: ''
        };
        this.transportService.getTransportistas().subscribe((res) => {
          this.transportService.venmaetransportistas = res;
        });
      }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.message.add({
          key: 'vend',
          severity: 'error',
          summary: 'Eliminación de Transportista',
          detail: mensaje
        });
        this.btnNuevo = false;
        this.btnGuardar = true;
        this.btnBorrar = false;
        this.btnRegresar = true;
      });
    }
  }
  
  manejarSeleccion(valor) {
    if (this.opcion === 'BUSCAR') {
      this.transportService.venmaetransportistas.map((trans) => {
        if (trans.TRANSPORT_CODIGO === valor.TRANSPORT_CODIGO) {
          this.indice = this.transportService.venmaetransportistas.indexOf(trans);
        }
      });
      this.transportistaSeleccionado = this.transportService.venmaetransportistas[this.indice];
      this.codigoAnt = this.transportistaSeleccionado.TRANSPORT_CODIGO;
      this.displayDialogBus2 = false;
    }
  }
  
  cambio() {
    if (this.doingRegistro === true) {
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnRegresar = false;
    }
  }
  
  cambiarFoco(evento) {
    if (evento.target.id === 'nombre') {
      document.getElementById('RUC').focus();
    } else if (evento.target.id === 'RUC') {
      document.getElementById('contacto').focus();
    } else if (evento.target.id === 'contacto') {
      document.getElementById('direccion').focus();
    } else if (evento.target.id === 'direccion') {
      document.getElementById('telefono').focus();
    } else if (evento.target.id === 'telefono') {
      document.getElementById('telefono2').focus();
    } else if (evento.target.id === 'telefono2') {
      document.getElementById('auto').focus();
    } else if (evento.target.id === 'auto') {
      document.getElementById('placa').focus();
    } else if (evento.target.id === 'placa') {
      document.getElementById('tonelaje').focus();
    }
  }
}
