import { Component, OnInit } from '@angular/core';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {SegcambioclaveService} from '../segservicios/segcambioclave.service';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {ConfirmationService, MessageService} from 'primeng';
import {formatDate} from '@angular/common';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';

@Component({
  selector: 'app-segcambioclave',
  templateUrl: './segcambioclave.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class SegcambioclaveComponent implements OnInit {
  usuario = '';
  fecha = '';
  hora = '';
  btnVisto = true;
  claveActual = '';
  nuevaClave = '';
  confirmClave = '';
  
  constructor(
    private segCambioClaveService: SegcambioclaveService,
    private init: NuevoComponentService,
    private errorService: ErroresBaseDatosService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private auditoriagral: AuditoriagralService,
  ) { }
  
  ngOnInit(): void {
    
    this.usuario = this.segCambioClaveService.usuario.identificacion;
    this.fecha = formatDate( new Date(), 'dd/MM/yyyy', 'en');
    const time = new Date();
    this.hora = time.toLocaleString('en-US');
    
  }
  
  manejarSenales(valor) {
    console.log(valor);
    switch (valor) {
      case 'Visto':
        this.verificarClaveActual();
        break;
      case 'Salir':
        this.init.tabs.splice(this.init.tabindex, 1);
        break;
      
    }
  }
  
  activarVisto(e) {
    this.btnVisto = false;
  }
  
  verificarClaveActual() {
    
    this.segCambioClaveService.verficarClave(this.claveActual).then(  usuarios => {
      if ( usuarios.length === 0 ) {
        this.mensajeAlerta('error', 'Error', 'Clave de usuario actual incorrecta, intentelo nuevamente');
      } else {
        console.log(usuarios);
        this.verificarNueva();
      }
    })
      .catch( e => {
      const mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    });
  }
  
  verificarNueva() {
    if ( (this.nuevaClave === this.confirmClave) && this.nuevaClave.length > 0 ) {
      this.segCambioClaveService.cambioclave( this.nuevaClave ).then( () => {
        this.mensajeAlerta('success', 'Información', '¡Cambio de clave exitoso!');
      } ).then( async () => {
        await this.auditoriagral.registrarAuditoria('SEG_CambioClave',
          `CLAVE ANTERIOR=  ${this.claveActual} /SEG`, 'A',
          '', '01', '', '', '', '').toPromise().then(() => {
          this.claveActual = '';
          this.nuevaClave = '';
          this.confirmClave = '';
          return;
        });
      } ).catch( e => {
        console.error(e);
        const mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      });
    } else {
      if ( this.nuevaClave === '' && this.confirmClave === '' ) {
        this.mensajeAlerta('error', 'Error', 'Campos vacíos: Debe ingresar una nueva clave y confirmarla...');
        
      } else {
        this.mensajeAlerta('error', 'Error', 'La nueva clave no es igual a la confirmación...');
        
      }
    }
  }
  
  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'segCambioClave',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
    
  }
  
}
