<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="segMAERLPARAM">
</p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="'segMAERLPARAMDialog'" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="btnLimpiar"
						 [botonBuscar]="btnBuscar" [botonBorrar]="btnBorrar"
						 (signal)="manejarSenales($event)" [barraBotonesDesplaz]="false" [barraBotones3]="true"
						 [barraBotonesAsiCon]="true" [barraBotones5]="false"></app-invmaebarra>
	</div>
</div>

<p-tabView [(activeIndex)]="index" (activeIndexChange)="inicializarBtn()">
	<p-tabPanel header="Generales">
		<div class="divgrups">
			<div class="p-grid" id="divGeneral">
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-2">
							<span class="text-center">Nombre</span>
						</div>
						<div class="p-col-2">
							<input [disabled]="true" type="text" id="rlcodigo"
								   [(ngModel)]="reporteSeleccionado.RL_CODIGO" pInputText style="width: 100%"/>
						</div>
						<div class="p-col-5">
							<input (input)="cambio()" type="text" [(ngModel)]="reporteSeleccionado.RL_NOMBRE" pInputText
								   style="width: 100%"/>
						</div>
					</div>

				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-2">
							<span class="text-center">Archivo</span>
						</div>
						<div class="p-col-6">
							<input (input)="cambio()" type="text" [(ngModel)]="reporteSeleccionado.RL_ARCHIVO"
								   pInputText style="width: 100%"/>
						</div>
						<div class="p-col-1">
							<!-- <p-button icon="pi pi-search" ></p-button>-->
						</div>
					</div>
				</div>

				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-2">
							<span class="text-center">Tipo</span>
						</div>
						<div class="p-col-2">
							<p-dropdown [options]="tipo" optionLabel="RL_TIPO" placeholder="{{RLTIPO}}"
										[(ngModel)]="reporteSeleccionado.RL_TIPO"></p-dropdown>
						</div>
					</div>
				</div>

				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-2">
							<span class="text-center">Procedencia</span>
						</div>
						<div class="p-col-2">
							<p-dropdown [options]="procedencia" optionLabel="RL_PROCEDENCIA"
										placeholder="{{RLPROCEDENCIA}}"
										[(ngModel)]="reporteSeleccionado.RL_PROCEDENCIA"></p-dropdown>
						</div>
					</div>
				</div>

				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-2">
							<span class="text-center">Grupo</span>
						</div>
						<div class="p-col-1">
							<input type="number" [disabled]="true" [(ngModel)]="reporteSeleccionado.OPC_CODIGO"
								   type="text" id="grupo" pInputText style="width: 100%"/>

						</div>
						<div class="p-col-1">
							<button style="background-image: url(../../assets/images/iconos/buscar.png)"
									(click)="busquedadatosgrupo()"></button>
						</div>
						<div class="p-col-3">
							<input [disabled]="true" type="text" [value]="grupo.OPCNOMBRE" pInputText
								   style="width: 100%"/>
						</div>
					</div>
				</div>

				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-2">
							<span class="text-center">Proceso</span>
						</div>
						<div class="p-col-3">
							<input (input)="cambio()" type="text" [(ngModel)]="reporteSeleccionado.RL_PROCESO"
								   pInputText style="width: 100%"/>
						</div>
					</div>
				</div>

				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-2">
							<span class="text-center">Objeto</span>
						</div>
						<div class="p-col-3">
							<input (input)="cambio()" type="text" [(ngModel)]="reporteSeleccionado.RL_OBJETO" pInputText
								   style="width: 100%"/>
						</div>
					</div>
				</div>

				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-2">
							<span class="text-center"># Fórmula</span>
						</div>
						<div class="p-col-3">
							<input (input)="cambio()" type="number" [(ngModel)]="reporteSeleccionado.RL_NROFORMULA"
								   pInputText style="width: 100%"/>
						</div>
						<div class="p-col-2">
							<span class="text-center">Localización</span>
						</div>
						<div class="p-col-3">
							<input (input)="cambio()" type="text" [(ngModel)]="reporteSeleccionado.RL_LOCALIZACION"
								   pInputText style="width: 100%">
						</div>
					</div>
				</div>

				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-2">
							<span class="text-center">Descripción</span>
						</div>
						<div class="p-col-6">
							<input (input)="cambio()" type="text" [(ngModel)]="reporteSeleccionado.RL_DESCRIPCION"
								   pInputText style="width: 100%"/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel id="tabParametro" header="Parametros">
		<div>
			<div>
				<input type="text" [disabled]="true" readonly="readonly" [(ngModel)]="reporteSeleccionado.RL_PROCESO"
					   pInputText style="width: 50%"/>
			</div>
			<app-aggridsaci [width]="" 
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="segManteRep"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefManteRep"
							[defaultColDef]="defaultColManteRep"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)=selectMaeRep($event)
							(cambios)="cambioModulo($event)"

			></app-aggridsaci>
			<!-- <div class="p-grid">
				<div class="p-col-12">
					<p-scrollPanel [style]="{width: '100%', height: '60vh'}">
						<div style="padding:1em;line-height:1.5;width:600px;">
							<p-table editMode="row" [value]="parametro" selectionMode="single"
									 [selection]="parametroSeleccionado" [scrollable]="false" [style]="{width:'100%'}">

								<ng-template pTemplate="colgroup" let-columns>
									<colgroup>
										<col style="width:300px">
										Variable
										<col style="width:200px">
										Para/Cr
										<col style="width:200px">
										Etiqueta
										<col style="width:200px">
										Tipo
										<col style="width:100px">
										Ubicacion
										<col style="width:100px">
										Tamaño
										<col style="width:100px">
										Maximo
										<col style="width:800px">
										Consulta
										<col style="width:100px">
										Condicion
										<col style="width:500px">
										Criterios
										<col style="width:200px">
										Tabla
									</colgroup>
								</ng-template>

								<ng-template pTemplate="header" let-columns>
									<tr>
										<th>Variable</th>
										<th>Para/Cr</th>
										<th>Etiqueta</th>
										<th>Tipo</th>
										<th>Ubicacion</th>
										<th>Tamaño</th>
										<th>Maximo</th>
										<th>Consulta</th>
										<th>Condicion</th>
										<th>Criterios</th>
										<th>Tabla</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-param let-rowData let-rowIndex="ri">
									<tr [pSelectableRow]="rowData" (click)="seleccionParametro(rowData)">
										<td pEditableColumn>
											<p-cellEditor>
												<ng-template pTemplate="input">
													<input (input)="cambio()" [(ngModel)]="param.RLP_PARAMETRO"
														   pInputText type="text">
												</ng-template>
												<ng-template pTemplate="output">
													{{param.RLP_PARAMETRO}}
												</ng-template>
											</p-cellEditor>
										</td>
												
										<td>
											<p-dropdown (onClick)="cambio()" [options]="tipovariable"
														optionLabel="RLP_TIPOVARIABLE"
														placeholder="{{param.RLP_TIPOVARIABLE}}"
														[(ngModel)]="param.RLP_TIPOVARIABLE"></p-dropdown>
										</td>

										<td pEditableColumn>
											<p-cellEditor>
												<ng-template pTemplate="input">
													<input (input)="cambio()" type="text" pInputText
														   [(ngModel)]="param.RLP_ETIQUETA">
												</ng-template>
												<ng-template pTemplate="output">
													{{param.RLP_ETIQUETA}}
												</ng-template>
											</p-cellEditor>
										</td>

										<td>
											<p-dropdown (onClick)="cambio()" [options]="tipoParametros"
														optionLabel="RLP_TIPO" placeholder="{{param.RLP_TIPO}}"
														[(ngModel)]="param.RLP_TIPO"></p-dropdown>
										</td>

										<td pEditableColumn>
											<p-cellEditor>
												<ng-template pTemplate="input">
													<input (input)="cambio()" type="number" pInputText
														   [(ngModel)]="param.RLP_UBICACION">
												</ng-template>
												<ng-template pTemplate="output">
													{{param.RLP_UBICACION}}
												</ng-template>
											</p-cellEditor>
										</td>

										<td pEditableColumn>
											<p-cellEditor>
												<ng-template pTemplate="input">
													<input (input)="cambio()" type="number" pInputText
														   [(ngModel)]="param.RLP_DIMENSION">
												</ng-template>
												<ng-template pTemplate="output">
													{{param.RLP_DIMENSION}}
												</ng-template>
											</p-cellEditor>
										</td>

										<td pEditableColumn>
											<p-cellEditor>
												<ng-template pTemplate="input">
													<input (input)="cambio()" type="number" pInputText
														   [(ngModel)]="param.RLP_MAXIMO">
												</ng-template>
												<ng-template pTemplate="output">
													{{param.RLP_MAXIMO}}
												</ng-template>
											</p-cellEditor>
										</td>

										<td pEditableColumn>
											<p-cellEditor>
												<ng-template pTemplate="input">
													<input (input)="cambio()" type="text" pInputText
														   [(ngModel)]="param.RLP_CONSULTA">
												</ng-template>
												<ng-template pTemplate="output">
													{{param.RLP_CONSULTA}}
												</ng-template>
											</p-cellEditor>
										</td>

										<td pEditableColumn>
											<p-cellEditor>
												<ng-template pTemplate="input">
													<input (input)="cambio()" type="text" pInputText
														   [(ngModel)]="param.RLP_CONDICION">
												</ng-template>
												<ng-template pTemplate="output">
													{{param.RLP_CONDICION}}
												</ng-template>
											</p-cellEditor>
										</td>

										<td pEditableColumn>
											<p-cellEditor>
												<ng-template pTemplate="input">
													<input (input)="cambio()" type="text" pInputText
														   [(ngModel)]="param.RLP_CRITERIOS">
												</ng-template>
												<ng-template pTemplate="output">
													{{param.RLP_CRITERIOS}}
												</ng-template>
											</p-cellEditor>
										</td>

										<td pEditableColumn>
											<p-cellEditor>
												<ng-template pTemplate="input">
													<input (input)="cambio()" type="text" pInputText
														   [(ngModel)]="param.RLP_TABLA">
												</ng-template>
												<ng-template pTemplate="output">
													{{param.RLP_TABLA}}
												</ng-template>
											</p-cellEditor>
										</td>
									</tr>
								</ng-template>
							</p-table>
						</div>
					</p-scrollPanel>
				</div>
			</div> -->

		</div>

	</p-tabPanel>
	<p-tabPanel id="tabParametro" header="BI">
		<div>
			<div>
				<input type="text" [disabled]="true" readonly="readonly" [(ngModel)]="reporteSeleccionado.RL_CODIGO"
					   pInputText style="width: 50%"/>
			</div>
			<app-aggridsaci [width]="" 
							[height]="largo"
							[enableSorting]="true"
							[enableFilter]="true"
							[rowData]="segManbi"
							[frameworkComponents]="frameworkComponents"
							[animateRows]="true"
							[rowSelection]="rowSelection"
							[columnDefs]="columnDefbi"
							[defaultColDef]="defaultColbi"
							[rowStyle]="rowStyle"
							[singleClickEdit]="false"
							[botones]="false"
							(selected)=selectbi($event)

			></app-aggridsaci>
		</div>
	</p-tabPanel>
</p-tabView>


<p-confirmDialog appendTo="body" header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-dialog header="Seleccione la opción" [(visible)]="displayDialogBus" [responsive]="true"
		  [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '90vw', height: '70vh'}">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloBus"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloBus" [tabla]="tabla" [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>
