<button style="background-image: url(../../../assets/images/iconos/salir.png); position: absolute; top: 0;left: 0; z-index: 3;"
			(click)="salir()"></button>	
		<div *ngIf="sacim === true" style="position: relative;">
			
			<p-galleria [(value)]="imagenes" [numVisible]="3" [showIndicators]="true" [showThumbnails]="false"
				[changeItemOnIndicatorHover]="true" [showIndicatorsOnItem]="true" [autoPlay]="true" [circular]="true"
				[transitionInterval]="10000"  >
				<ng-template pTemplate="item" let-item>
					<img src="../../assets/layout/images/{{item.image}}" [alt]="item.name" style=" height: calc(100vh - 120px)" width="100%">
				</ng-template>
			</p-galleria> 
			<img src="../../assets/layout/images/pie_construccion.png" style="position: absolute; bottom: 0;left: 0; width: 100%;" >
		</div>		
<img *ngIf="sacim === false" src="../../../assets/images/construccion.png" style="height: calc(100vh - 120px)" width="100%"/>
